import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import {
  TabsGroup,
  TabButton,
  TabsTheme,
  TABS_GROUP_HEADER_SELECTOR,
} from '@ardoq/tabs';
import { capitalize } from 'lodash';
import { Text } from '@ardoq/typography';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { SearchInput } from '@ardoq/forms';

const OverflowWrapper = styled(Stack)`
  overflow: hidden;
  ${TABS_GROUP_HEADER_SELECTOR} {
    padding: 0px;
    border: none;
  }
`;

const MaxWidthDiv = styled.div`
  width: 400px;
`;

type TabData = {
  readonly title: string;
  readonly titleContent?: ReactNode | string;
  readonly content: ReactNode | string;
};

type TabbedContentProps = {
  readonly tabs: TabData[];
  readonly description?: ReactNode | string;
  readonly initiallySelectedTab?: string;
  readonly onFilterChange?: (query: string) => void;
};

const TabbedContent = ({
  tabs,
  description,
  initiallySelectedTab,
  onFilterChange,
}: TabbedContentProps) => {
  const [firstTab] = tabs;
  const [selectedTab, selectTab] = useState<string>(
    initiallySelectedTab ?? firstTab?.title ?? null
  );
  const activeTab = tabs.find(tab => tab.title === selectedTab);
  return (
    <OverflowWrapper>
      <FlexBox
        width="full"
        paddingX="xxsmall"
        flexWrap={true}
        justify={'space-between'}
      >
        <MaxWidthDiv>
          <TabsGroup
            theme={TabsTheme.COLOR_FILL}
            defaultActiveTabId={selectedTab}
            onTabChange={tabId => selectTab(tabId as string)}
          >
            {tabs.map(tab => (
              <TabButton
                key={tab.title}
                label={tab.titleContent || capitalize(tab.title)}
                tabId={tab.title}
              ></TabButton>
            ))}
          </TabsGroup>
        </MaxWidthDiv>
        {onFilterChange && (
          <Box paddingY="small" paddingLeft="xsmall">
            <SearchInput
              placeholder="Search..."
              onValueChange={onFilterChange}
            />
          </Box>
        )}
      </FlexBox>
      {description && (
        <Box paddingY="small">
          <Text color="textSubtle">{description}</Text>
        </Box>
      )}
      {activeTab?.content}
    </OverflowWrapper>
  );
};

export default TabbedContent;
