import { Observable } from 'rxjs';
import {
  multiActionReducer,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { Connection } from '@ardoq/api-types/integrations';
import { IntegrationConnectionsState } from './types';
import {
  deleteConnectionFailure,
  resetSelectedConnectionIds,
  updateConnectionList,
  updateConnectionStatuses,
  updateSelectedConnectionsIds,
  updateSingleSelectedConnectionId,
  upsertConnectionFailure,
} from './actions';
import { resetIntegration } from '../activeIntegrations/actions';
import { buildInitialStreamState } from '../utils';
import { ActionCreatorParameter } from '../../utils/actionCreatorWithIntegrationId';

type AllConnectionsState = Record<IntegrationId, IntegrationConnectionsState>;

const createInitialConnectionsState = (): IntegrationConnectionsState => ({
  statuses: {
    upsert: { status: 'INIT' },
    delete: { status: 'INIT' },
    list: { status: 'INIT' },
  },
  connections: [],
  selectedConnectionIds: [],
});

const defaultState: AllConnectionsState = buildInitialStreamState(
  createInitialConnectionsState
);

const handleListConnection = (
  allConnectionsState: AllConnectionsState,
  {
    integrationId,
    connectionsState,
  }: ActionCreatorParameter<typeof updateConnectionList>
) => ({
  ...allConnectionsState,
  [integrationId]: {
    ...allConnectionsState[integrationId],
    ...connectionsState,
  },
});

const handleUpdateConnectionStatuses = (
  allConnectionsState: AllConnectionsState,
  {
    integrationId,
    statuses,
  }: ActionCreatorParameter<typeof updateConnectionStatuses>
) => ({
  ...allConnectionsState,
  [integrationId]: {
    ...allConnectionsState[integrationId],
    statuses: {
      ...allConnectionsState[integrationId].statuses,
      ...statuses,
    },
  },
});

const handleUpdateSingleSelectedConnection = (
  allConnectionsState: AllConnectionsState,
  {
    integrationId,
    selectedConnectionId,
  }: ActionCreatorParameter<typeof updateSingleSelectedConnectionId>
) => ({
  ...allConnectionsState,
  [integrationId]: {
    ...allConnectionsState[integrationId],
    selectedConnectionIds: selectedConnectionId ? [selectedConnectionId] : [],
  },
});

const handleUpdateSelectedConnections = (
  allConnectionsState: AllConnectionsState,
  {
    integrationId,
    selectedConnectionIds,
  }: ActionCreatorParameter<typeof updateSelectedConnectionsIds>
) => ({
  ...allConnectionsState,
  [integrationId]: {
    ...allConnectionsState[integrationId],
    selectedConnectionIds,
  },
});

const handleResetSelectedConnectionIds = (
  allConnectionsState: AllConnectionsState,
  { integrationId }: ActionCreatorParameter<typeof resetSelectedConnectionIds>
) => ({
  ...allConnectionsState,
  [integrationId]: {
    ...allConnectionsState[integrationId],
    selectedConnectionIds: [],
  },
});

const updateIntegrationConnectionsListReducer = reducer<
  AllConnectionsState,
  ActionCreatorParameter<typeof updateConnectionList>
>(updateConnectionList, handleListConnection);

const updateIntegrationConnectionsStatusesReducer = multiActionReducer<
  AllConnectionsState,
  ActionCreatorParameter<typeof updateConnectionStatuses>
>(
  [updateConnectionStatuses, upsertConnectionFailure, deleteConnectionFailure],
  handleUpdateConnectionStatuses
);

const updateSingleSelectedConnectionIdReducer = reducer<
  AllConnectionsState,
  ActionCreatorParameter<typeof updateSingleSelectedConnectionId>
>(updateSingleSelectedConnectionId, handleUpdateSingleSelectedConnection);

const updateSelectedConnectionsIdsReducer = reducer<
  AllConnectionsState,
  ActionCreatorParameter<typeof updateSelectedConnectionsIds>
>(updateSelectedConnectionsIds, handleUpdateSelectedConnections);

const resetSelectedConnectionIdsReducer = reducer<
  AllConnectionsState,
  ActionCreatorParameter<typeof resetSelectedConnectionIds>
>(resetSelectedConnectionIds, handleResetSelectedConnectionIds);

const handleResetIntegration = (
  allConnectionsState: AllConnectionsState,
  integrationId: IntegrationId
) => ({
  ...allConnectionsState,
  [integrationId]: {
    ...allConnectionsState[integrationId],
    selectedConnectionIds: [],
  },
});

const resetIntegrationReducer = reducer<AllConnectionsState, IntegrationId>(
  resetIntegration,
  handleResetIntegration
);

export const connections$ = persistentReducedStream(
  `integrationConnections$`,
  defaultState,
  [
    updateIntegrationConnectionsListReducer,
    updateIntegrationConnectionsStatusesReducer,
    updateSingleSelectedConnectionIdReducer,
    updateSelectedConnectionsIdsReducer,
    resetIntegrationReducer,
    resetSelectedConnectionIdsReducer,
  ]
);

const integrationToConnectionsStreams = new Map<
  IntegrationId,
  Observable<IntegrationConnectionsState>
>();

export const getConnectionsStream = <T = Connection>(
  integrationId: IntegrationId
) => {
  const connectionStream = integrationToConnectionsStreams.get(integrationId);

  if (connectionStream) {
    return connectionStream as Observable<IntegrationConnectionsState<T>>;
  }

  const stream$ = connections$.pipe(
    map(allConnectionsState => allConnectionsState[integrationId])
  );

  integrationToConnectionsStreams.set(integrationId, stream$);

  return stream$ as Observable<IntegrationConnectionsState<T>>;
};
