import { buildTraversalState, isError } from '../loadedState/buildState';
import { requestShowAppModule } from '../appContainer/actions';
import { AppModules } from '../appContainer/types';
import { registerLoadedState } from '../loadedState/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { ArdoqId, APIViewpointAttributes } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { setActiveMainTabLeft } from 'streams/views/mainContent/actions';
import { getSupportedTraversalViewIdOrDefault } from './getViewpointModeSupportedViews';
import { loadedState$ } from 'loadedState/loadedState$';
import { applyViewpointPerspectives } from './applyViewpointPerspectives';
import { enterViewpointMode } from 'streams/traversals/actions';
import { scopeDataLoaded } from './stagedLoadedDataAndState$';
import { loadedStateOperations } from '../loadedState/loadedStateOperations';

export const applyTraversalToComponentIds = async (
  viewpoint: APIViewpointAttributes,
  componentIds: ArdoqId[]
) => {
  // apply view style, perspectives and filters of only the first applied traversal
  const isFirstOpenedTraversal = loadedState$.state.length === 0;

  try {
    const traversalLoadedStatePrams =
      loadedStateOperations.getTraversalParamsFromViewpoint(
        viewpoint,
        componentIds
      );
    const loadedGraphOrError = await buildTraversalState(
      traversalLoadedStatePrams.data
    );
    if (isError(loadedGraphOrError)) {
      return;
    }

    dispatchAction(
      scopeDataLoaded({
        trackingLocation: 'traversal',
        scopeData: loadedGraphOrError.scopeData,
      })
    );
    dispatchAction(enterViewpointMode());
    dispatchAction(
      requestShowAppModule({
        selectedModule: AppModules.WORKSPACES,
      })
    );
    // TODO: use loadedStateOperations.attachLoadedGraph once is merged from
    // a separate PR
    const traversalLoadedState = {
      ...traversalLoadedStatePrams,
      ...loadedGraphOrError,
    };
    dispatchAction(registerLoadedState([traversalLoadedState]));

    if (isFirstOpenedTraversal) {
      applyViewpointPerspectives(viewpoint);
      dispatchAction(
        setActiveMainTabLeft({
          activeTabId: getSupportedTraversalViewIdOrDefault(
            viewpoint?.viewName
          ),
        })
      );
    }
  } catch (e) {
    logError(Error('Loading subgraph from traversal config failed'));
  }
};
