import { useEffect } from 'react';
import { GroupFieldLayout, RadioGroup } from '@ardoq/forms';
import {
  APIDiscoverViewpointAttributes,
  LayoutOrientation,
} from '@ardoq/api-types';
import { useController, useFormContext } from 'react-hook-form';

type LayoutOrientationSelectorProps = {
  defaultVisualization: 'simple-block-diagram' | 'dependency-map';
};

const orientationOptions = {
  'simple-block-diagram': [
    {
      label: 'Left to right',
      value: 'left-to-right',
    },
    {
      label: 'Right to left',
      value: 'right-to-left',
    },
    {
      label: 'Top to bottom',
      value: 'top-to-bottom',
    },
    {
      label: 'Bottom to top',
      value: 'bottom-to-top',
    },
  ],
  'dependency-map': [
    {
      label: 'Horizontal layout',
      value: 'left-to-right',
    },
    {
      label: 'Vertical layout',
      value: 'top-to-bottom',
    },
  ],
};

const LayoutOrientationSelector = ({
  defaultVisualization,
}: LayoutOrientationSelectorProps) => {
  const {
    field: { onChange },
  } = useController({ name: 'viewStyle.layoutOrientation' });
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const viewStyle = watch('viewStyle');
  const layoutOrientation =
    viewStyle.style === 'simple-block-diagram' ||
    viewStyle.style === 'dependency-map'
      ? viewStyle.layoutOrientation
      : 'relationship-view';

  useEffect(() => {
    if (
      defaultVisualization !== 'dependency-map' &&
      defaultVisualization !== 'simple-block-diagram'
    )
      return;

    const matchingOrientation = defaultVisualization
      ? orientationOptions[defaultVisualization]?.find(
          ({ value }) => value === layoutOrientation
        )
      : null;

    if (
      !layoutOrientation ||
      layoutOrientation !== matchingOrientation?.value
    ) {
      onChange('left-to-right');
    }
  }, [defaultVisualization, layoutOrientation, onChange]);

  return (
    <RadioGroup
      onValueChange={newLayoutOrientation =>
        onChange(newLayoutOrientation as LayoutOrientation)
      }
      label="Default orientation"
      groupFieldLayout={GroupFieldLayout.VERTICAL}
      value={layoutOrientation ?? 'left-to-right'}
      options={orientationOptions[defaultVisualization]}
    />
  );
};

export default LayoutOrientationSelector;
