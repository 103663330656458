import { ComponentSelection } from 'componentSelection/componentSelectionTypes';
import { NavigatorLayoutState } from '../types';
import { navigatorLayoutOperations } from './navigatorLayoutOperations';

const updateSelection = (
  state: NavigatorLayoutState,
  { treeSelection: selection, selectionStart }: ComponentSelection
): NavigatorLayoutState => {
  state.tree.syncSelection(selection);
  const newState = {
    ...state,
    selection,
    selectionSize: selection.length,
    selectionStart,
  };
  return navigatorLayoutOperations.treeChanged(newState, {});
};

export const navigatorSelectionOperations = {
  updateSelection,
};
