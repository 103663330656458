import { PermissionGroup } from '@ardoq/api-types';
import { trackPermissionGroupPageEvents } from '../utils';
import { PermissionGroupPageEvents } from '../types';
import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { InlineText, Text } from '@ardoq/typography';
import { permissionGroupsCommands } from '../commands';
import { useState } from 'react';
import { TextInput } from '@ardoq/forms';

export const openRenameGroupModal = (permissionGroup: PermissionGroup) => {
  trackPermissionGroupPageEvents(
    PermissionGroupPageEvents.CLICK_RENAME_GROUP_BUTTON
  );
  modal(
    closeModal => {
      const [newName, setNewName] = useState('');

      return (
        <ModalTemplate
          headerText="Rename group?"
          primaryButtonText="Save"
          isPrimaryButtonDisabled={!newName}
          onPrimaryButtonClick={() => {
            permissionGroupsCommands.renameGroup(permissionGroup, newName);
            closeModal(true);
          }}
          secondaryButtonText="Cancel"
          onSecondaryButtonClick={() => {
            trackPermissionGroupPageEvents(
              PermissionGroupPageEvents.CANCEL_RENAME_GROUP
            );
            closeModal(true);
          }}
          modalSize={ModalSize.S}
        >
          <ModalLayout>
            <Text variant="text2">
              You are renaming the group:{' '}
              <InlineText variant="text2Bold">
                {permissionGroup.name}
              </InlineText>
              . Updating the name won&apos;t affect its permissions or members.
            </Text>
            <TextInput
              label="New group name"
              placeholder={permissionGroup.name}
              value={newName}
              onValueChange={setNewName}
            ></TextInput>
          </ModalLayout>
        </ModalTemplate>
      );
    },
    {
      shouldCloseOnBackdropClick: false,
      shouldCloseOnEscapeKey: false,
    }
  );
};
