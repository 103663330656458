import { IconName } from '@ardoq/icons';
import { confirm } from '@ardoq/modal';
import { GraphComponent } from '@ardoq/yfiles';
import { EXPORT_FORMAT, exportGraphComponent } from '@ardoq/export';
import { ViewIds } from '@ardoq/api-types';
import { ButtonWithDropdown, DropdownOptionType } from '@ardoq/dropdown-menu';
import { trackExportingVisualization } from 'tracking/events/visualizations';
import { getStyleSheets } from 'tabview/getSharedExportFunctions';
import { ButtonType } from '@ardoq/button';
import { createMetamodelSlide } from 'presentationEditor/presentationChooser/actions';
import { dispatchAction } from '@ardoq/rxbeach';

interface ExportButtonProps {
  metamodelTitle: string;
  visualizationContainer: HTMLElement | null;
  graphComponent: GraphComponent | null;
  showConfirmationDialog?: boolean;
  isExporting: boolean;
  metamodelId: string;
  fullOrg?: boolean;
  hasNewJourneyFeature?: boolean;
}
const ExportButton = ({
  metamodelTitle,
  visualizationContainer,
  graphComponent,
  showConfirmationDialog,
  isExporting,
  metamodelId,
  fullOrg,
  hasNewJourneyFeature,
}: ExportButtonProps) => (
  <ButtonWithDropdown
    buttonType={hasNewJourneyFeature ? ButtonType.BRAND : ButtonType.GHOST}
    isDisabled={!visualizationContainer || !graphComponent || isExporting}
    options={[
      {
        label: 'Export to SVG',
        iconName: IconName.EXPORT,
        type: DropdownOptionType.BUTTON_OPTION,
        onClick: async () => {
          const confirmed = showConfirmationDialog
            ? await confirm({
                title: 'Continue SVG exporting',
                text: 'It could take more than 10 seconds to export a large metamodel. Do you want to continue?',
                confirmButtonTitle: 'Yes',
              })
            : true;
          if (!confirmed) return;
          exportGraphComponent({
            container: visualizationContainer,
            graphComponent,
            format: EXPORT_FORMAT.SVG,
            fileName: metamodelTitle,
            exportedViewMetadata: {
              name: ViewIds.SIMPLE_BLOCK_DIAGRAM,
              isViewpointMode: false,
            },
            trackingFunction: trackExportingVisualization,
            getStyleSheets,
          });
        },
      },
      {
        label: 'Add to presentation',
        iconName: IconName.PRESENTATION,
        type: DropdownOptionType.BUTTON_OPTION,
        onClick: () =>
          dispatchAction(
            createMetamodelSlide({
              name: metamodelTitle,
              fullOrg: Boolean(fullOrg),
              metamodelId,
            })
          ),
      },
    ]}
  >
    Export
  </ButtonWithDropdown>
);

export default ExportButton;
