import { reducer, streamReducer } from '@ardoq/rxbeach';
import { SubdivisionViewModelState } from 'subdivisionEditor/types';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import {
  ApiZonesFailurePayload,
  SubdivisionsStreamShape,
} from 'streams/subdivisions/types';
import { withLatestFrom } from 'rxjs';
import subdivisionNavigation$ from 'subdivisionEditor/navigation/subdivisionNavigation$';
import {
  SubdivisionEditorSteps,
  SubdivisionNavigationState,
} from 'subdivisionEditor/navigation/types';
import { subdivisionsOperations } from '@ardoq/subdivisions';
import { subdivisionEditorStepsOperations } from 'subdivisionEditor/subdivisionEditorStepsOperations';
import {
  createZoneSuccessfully,
  deleteZoneSuccessfully,
  saveSubdivisionDetailsChanges,
  saveSubdivisionDetailsFailure,
  saveSubdivisionDetailsSuccessfully,
} from 'streams/subdivisions/actions';
import { Subdivision, Zone } from '@ardoq/api-types';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';
import {
  setCollapsedStep,
  subdivisionEditorChangesAreSaved,
  touchStep,
  updateSubdivision,
} from './actions';
import {
  savePermissionsAndZones,
  savePermissionsAndZonesFailure,
} from 'subdivisionEditor/Steps/permissionsConfiguration/zones/zonesViewState$/actions';
import { componentSelectionStepReducers } from 'subdivisionEditor/Steps/SelectComponents/streams/reducers';
import bindWorkspaces$ from 'subdivisionEditor/Steps/BindWorkspaces/bindWorkspaces$';
import { BindWorkspacesState } from 'subdivisionEditor/Steps/BindWorkspaces/types';
import { zonesViewModel$ } from 'subdivisionEditor/Steps/permissionsConfiguration/zones/zonesViewModel$';
import { ZonesViewModelState } from 'subdivisionEditor/Steps/permissionsConfiguration/zones/types';

const { resetAfterSaveReducer, saveSubdivisionChangesReducer } =
  subdivisionEditorOperations;

const updateSubdivisionReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  payload: Partial<SubdivisionViewModelState['subdivision']>
): SubdivisionViewModelState => {
  const newState: SubdivisionViewModelState = {
    ...subdivisionEditorStepsOperations.setStepTouched(
      subdivisionEditorState,
      subdivisionEditorState.subdivisionEditorStep
    ),
    subdivision: subdivisionsOperations.updateSubdivisionProperties(
      subdivisionEditorState.subdivision,
      payload
    ),
  };

  return {
    ...newState,
    stepsErrors:
      subdivisionEditorStepsOperations.getAllStepValidationErrors(newState),
  };
};

const setSubdivisionEditorStepSubStepWithZoneReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  zone: Zone
): SubdivisionViewModelState => ({
  ...subdivisionEditorState,
  subdivisionEditorSubStep: zone._id,
});

const resetSubdivisionEditorStepSubStepReducer = (
  subdivisionEditorState: SubdivisionViewModelState
): SubdivisionViewModelState => ({
  ...subdivisionEditorState,
  subdivisionEditorSubStep: undefined,
});

/*
 * ---------------------------------------------------------------
 * ---------------------------------------------------------------
 *     Sync the viewModel Stream with the subdivisions stream
 * ---------------------------------------------------------------
 * ---------------------------------------------------------------
 */
const syncSubdivisionReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  [subdivisionsState, navigationState]: [
    SubdivisionsStreamShape,
    SubdivisionNavigationState,
  ]
): SubdivisionViewModelState => {
  // ---------------------------------------------------------------
  // ------------------    Creation Mode    ------------------------
  // ---------------------------------------------------------------
  // If we are in creation mode, we don't want to sync the viewModel state with the subdivisions stream
  if (subdivisionEditorOperations.isCreationMode(navigationState)) {
    return subdivisionEditorState;
  }

  // Get the new subdivision from the subdivisions stream, or an empty one if it doesn't exist
  const newSubdivision: Subdivision | undefined =
    subdivisionsState.subdivisions[navigationState.subdivisionId] ??
    subdivisionsOperations.getEmptySubdivision();

  // Check if the subdivision is missing from the subdivisions stream
  const missingSubdivision =
    !subdivisionsState.subdivisions[navigationState.subdivisionId];

  // ---------------------------------------------------------------
  // -----------------    After Creating    ------------------------
  // ---------------------------------------------------------------

  // ---------------------------------------------------------------
  // -----------     No Changes to the Subdivision     -------------
  // ---------------------------------------------------------------
  // If the viewModel subdivision in the state is the same as the one in the subdivisions stream, we don't want to sync it
  if (
    subdivisionsOperations.isPersistedSubdivision(
      subdivisionEditorState.subdivision
    ) &&
    !missingSubdivision &&
    navigationState.subdivisionId === subdivisionEditorState.subdivision._id
  ) {
    return subdivisionEditorState;
  }

  // ---------------------------------------------------------------
  // -------------    Set Updated Subdivision    -------------------
  // ---------------------------------------------------------------
  // If the viewModel subdivision in the state is different from the one in the subdivisions stream, we want to sync it
  return {
    ...subdivisionEditorState,
    missingSubdivision,
    subdivision: newSubdivision,
    componentsSelection: {
      ...subdivisionEditorState.componentsSelection,
      advancedSearchState: {
        ...subdivisionEditorState.componentsSelection.advancedSearchState,
        isEmpty:
          !subdivisionEditorOperations.hasAdvancedSearchQuery(newSubdivision),
      },
    },
  };
};

/*
 * ---------------------------------------------------------------
 * ---------------------------------------------------------------
 *      Sync the viewModel Stream with the navigation stream
 * ---------------------------------------------------------------
 * ---------------------------------------------------------------
 */
const syncSubdivisionNavigatorReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  [navigationState, subdivisionsState]: [
    SubdivisionNavigationState,
    SubdivisionsStreamShape,
  ]
): SubdivisionViewModelState => {
  // ---------------------------------------------------------------
  // ------------------    No Navigation    ------------------------
  // ---------------------------------------------------------------
  // If the viewModel state subdivisionId is the same as the navigation state subdivisionId, we don't want to sync it
  if (
    subdivisionEditorState.subdivisionId === navigationState.subdivisionId &&
    subdivisionEditorState.subdivisionEditorStep ===
      navigationState.subdivisionEditorStep &&
    subdivisionEditorState.subdivisionEditorSubStep ===
      navigationState.subdivisionEditorSubStep
  ) {
    return subdivisionEditorState;
  }

  // ---------------------------------------------------------------
  // ------------------     Step Changes       ---------------------
  // ---------------------------------------------------------------
  // If the viewModel state subdivisionId is the same as the navigation state subdivisionId, but the editor step is different, we want to sync it.
  if (
    subdivisionEditorState.subdivisionId === navigationState.subdivisionId &&
    (subdivisionEditorState.subdivisionEditorStep !==
      navigationState.subdivisionEditorStep ||
      subdivisionEditorState.subdivisionEditorSubStep !==
        navigationState.subdivisionEditorSubStep)
  ) {
    return {
      ...subdivisionEditorState,
      subdivisionEditorStep: navigationState.subdivisionEditorStep,
      subdivisionEditorSubStep: navigationState.subdivisionEditorSubStep,
    };
  }

  // ---------------------------------------------------------------
  // ------------------     Go To Create       ---------------------
  // ---------------------------------------------------------------
  // If we are in creation mode, we want to sync the viewModel state with the navigation state
  if (subdivisionEditorOperations.isCreationMode(navigationState)) {
    return {
      ...subdivisionEditorOperations.getInitialSubdivisionEditorState(),
      bindWorkspacesState: subdivisionEditorState.bindWorkspacesState,
      zonesState: subdivisionEditorState.zonesState,
      subdivisionId: 'new',
      subdivisionEditorStep: SubdivisionEditorSteps.DETAILS,
      subdivisionEditorSubStep: undefined,
      missingSubdivision: false,
    };
  }

  // If we are not in creation mode and we don't have a subdivision in the viewModel state, we want to sync it with the subdivisions stream
  const subdivision =
    subdivisionsState.subdivisions[navigationState.subdivisionId] ??
    subdivisionsOperations.getEmptySubdivision();
  // Check if the subdivision is missing from the subdivisions stream
  const missingSubdivision =
    !subdivisionsState.subdivisions[navigationState.subdivisionId];
  const initialState =
    subdivisionEditorOperations.getInitialSubdivisionEditorState();

  return {
    ...initialState,
    ...navigationState,
    subdivision: subdivision,
    missingSubdivision,
    bindWorkspacesState: subdivisionEditorState.bindWorkspacesState,
    zonesState: subdivisionEditorState.zonesState,
    componentsSelection: {
      ...initialState.componentsSelection,
      advancedSearchState: {
        ...initialState.componentsSelection.advancedSearchState,
        isEmpty:
          !subdivisionEditorOperations.hasAdvancedSearchQuery(subdivision),
      },
    },
  };
};

const saveSubdivisionSuccessfullyReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  payload: Subdivision
): SubdivisionViewModelState => {
  return {
    ...subdivisionEditorState,
    subdivision: payload,
  };
};

const saveSubdivisionFailureReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  payload: ApiZonesFailurePayload
): SubdivisionViewModelState => {
  return {
    ...subdivisionEditorState,
    isSubmitting: false,
    submittingError: payload.message,
  };
};

const syncBindWorkspaceReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  bindWorkspacesState: BindWorkspacesState
): SubdivisionViewModelState => {
  const newState = {
    ...subdivisionEditorState,
    bindWorkspacesState,
  };
  return {
    ...newState,
    stepsErrors:
      subdivisionEditorStepsOperations.getAllStepValidationErrors(newState),
  };
};

const syncZonesViewModelReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  zonesState: ZonesViewModelState
): SubdivisionViewModelState => {
  const newState = {
    ...subdivisionEditorState,
    zonesState,
  };

  return {
    ...newState,
    stepsErrors:
      subdivisionEditorStepsOperations.getAllStepValidationErrors(newState),
  };
};

export const reducers = [
  reducer(updateSubdivision, updateSubdivisionReducer),
  streamReducer(
    subdivisions$.pipe(withLatestFrom(subdivisionNavigation$)),
    syncSubdivisionReducer
  ),
  streamReducer(
    subdivisionNavigation$.pipe(withLatestFrom(subdivisions$)),
    syncSubdivisionNavigatorReducer
  ),
  streamReducer(bindWorkspaces$, syncBindWorkspaceReducer),
  streamReducer(zonesViewModel$, syncZonesViewModelReducer),
  reducer(saveSubdivisionDetailsChanges, saveSubdivisionChangesReducer),
  reducer(
    saveSubdivisionDetailsSuccessfully,
    saveSubdivisionSuccessfullyReducer
  ),
  reducer(savePermissionsAndZones, saveSubdivisionChangesReducer),
  reducer(
    savePermissionsAndZonesFailure,
    subdivisionEditorOperations.resetIsSubmittingReducer
  ),
  reducer(saveSubdivisionDetailsFailure, saveSubdivisionFailureReducer),
  reducer(touchStep, subdivisionEditorStepsOperations.setStepTouched),
  reducer(setCollapsedStep, subdivisionEditorStepsOperations.setCollapsedStep),
  reducer(subdivisionEditorChangesAreSaved, resetAfterSaveReducer),
  reducer(
    createZoneSuccessfully,
    setSubdivisionEditorStepSubStepWithZoneReducer
  ),
  reducer(deleteZoneSuccessfully, resetSubdivisionEditorStepSubStepReducer),
  ...componentSelectionStepReducers,
];
