import { persistentReducedStream } from '@ardoq/rxbeach';
import { IntegrationId, TabularMapping } from './types';

import { EMPTY, Observable, map } from 'rxjs';
import { reducers } from './reducers';
import { Maybe } from '@ardoq/common-helpers';
import { buildInitialStreamState } from '../utils';

type AllMappings = Record<IntegrationId, TabularMapping>;

const defaultState: AllMappings = buildInitialStreamState<TabularMapping>(
  () => ({})
);

/*
 * TabularMapping is in the scope of the Application Lifecycle.
 * Allows users to restart the mapping process even after leaving the integrations
 */
export const tabularMappings$ = persistentReducedStream(
  `tabularMappings$`,
  defaultState,
  reducers
);

const integrationToMappingStreams = new Map<
  IntegrationId,
  Observable<TabularMapping>
>();

export const getTabularMappingStream = (
  integrationId: Maybe<IntegrationId>
) => {
  if (!integrationId) {
    return EMPTY;
  }

  const mappingStream = integrationToMappingStreams.get(integrationId);

  if (mappingStream) {
    return mappingStream;
  }

  const stream$ = tabularMappings$.pipe(
    map(mappings => mappings[integrationId])
  );

  integrationToMappingStreams.set(integrationId, stream$);

  return stream$;
};
