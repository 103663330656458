import styled from 'styled-components';
import { s16 } from '@ardoq/design-tokens';
import { header4 } from '@ardoq/typography';
import { Complaints } from 'integrations/common/components/Complaints/Complaints';
import { MappingTable } from 'integrations/common/components/mappingTable/MappingTable';
import { DryRunSummaryFooter } from 'integrations/common/components/dryRunSummaryFooter/DryRunSummaryFooter';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import { TablePreview } from 'integrations/common/streams/tablePreviews/types';
import { NUM_DATA_ROWS } from 'integrations/common/streams/tablePreviews/constants';
import {
  ComplaintsIndices,
  DryRunStatus,
  TableComplaints,
} from 'integrations/common/streams/transferState/types';
import { IntegrationTermsDictionary } from 'integrations/common/streams/integrationTermsDictionary/types';
import { Maybe } from '@ardoq/common-helpers';
import { PreviewingRows } from 'integrations/common/components/previewingRows/PreviewingRows';
import { TableMappingErrors } from 'integrations/common/streams/tabularMappingErrors/types';
import { Box, FlexBox } from '@ardoq/layout';

import { ButtonType, IconButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import { useScroll } from './scrollHook';

const StepTitle = styled.div`
  ${header4};
`;

const ComplaintsContainer = styled(Box)`
  margin-bottom: ${s16};
`;

export const Table = ({
  tableTitle = '4. Configure columns',
  tableMapping,
  tablePreview,
  tableMappingErrors,
  tablesCount,
  selectedColumn,
  setSelectedColumn,
  rowComplaintsIndices,
  dryRunStatusesCounts,
  tableComplaints,
  integrationTermsDictionary,
  onStartColumnFilter,
}: {
  tableTitle?: string;
  tableMapping?: TableMappingMap;
  tablePreview?: TablePreview;
  tableMappingErrors?: TableMappingErrors;

  tablesCount: number;
  selectedColumn: Maybe<number>;
  setSelectedColumn: (index: Maybe<number>) => void;
  // dryRun related
  rowComplaintsIndices?: ComplaintsIndices;
  dryRunStatusesCounts?: Partial<Record<DryRunStatus, number>>;
  tableComplaints?: TableComplaints;
  integrationTermsDictionary: IntegrationTermsDictionary;
  onStartColumnFilter: ({
    columnName,
    columnIndex,
    sourceFieldName,
  }: {
    columnName: string;
    columnIndex: number;
    sourceFieldName: string;
  }) => void;
}) => {
  const { tableContainerRef, scrollLeft, scrollRight } = useScroll();
  return (
    <FlexBox flexDirection="column" gap="medium">
      <FlexBox align="end" paddingX="large" gap="medium">
        <FlexBox flexDirection="column" gap="medium" flex={1}>
          <Box>
            <StepTitle>{tableTitle}</StepTitle>
          </Box>
          {tableComplaints && (
            <ComplaintsContainer>
              <Complaints
                warnings={tableComplaints?.level?.warn}
                errors={tableComplaints?.level?.error}
                collapseThreshold={1}
                collapsiblePanels={true}
              />
            </ComplaintsContainer>
          )}
        </FlexBox>
        <FlexBox gap="small" align="center">
          {tablePreview && tablePreview?.rowCnt - 1 > NUM_DATA_ROWS && (
            <PreviewingRows rowCnt={tablePreview.rowCnt - 1}></PreviewingRows>
          )}
          <IconButton
            iconName={IconName.CHEVRON_LEFT}
            onClick={scrollLeft}
            buttonType={ButtonType.SECONDARY}
          ></IconButton>
          <IconButton
            iconName={IconName.CHEVRON_RIGHT}
            onClick={scrollRight}
            buttonType={ButtonType.SECONDARY}
          ></IconButton>
        </FlexBox>
      </FlexBox>

      <div ref={tableContainerRef}>
        <MappingTable
          tableMapping={tableMapping}
          tablePreview={tablePreview}
          tableMappingErrors={tableMappingErrors}
          rowComplaintsIndices={rowComplaintsIndices}
          tableComplaints={tableComplaints}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          onStartColumnFilter={onStartColumnFilter}
        />
      </div>

      {dryRunStatusesCounts && (
        <DryRunSummaryFooter
          dryRunSummaryType={integrationTermsDictionary.dryRunSummaryType}
          errorCount={dryRunStatusesCounts?.[DryRunStatus.ERROR] || 0}
          warningCount={dryRunStatusesCounts?.[DryRunStatus.WARNING] || 0}
          successCount={dryRunStatusesCounts?.[DryRunStatus.SUCCESS] || 0}
          tableCount={tablesCount}
        />
      )}
    </FlexBox>
  );
};
