import { useRef } from 'react';
import { ModalText } from '@ardoq/modal';
import { ErrorNotification } from '@ardoq/status-ui';
import SimpleGraphView from 'viewpointBuilder/components/SimpleGraph/SimpleGraphView';
import { createGraphInterface } from 'viewpointBuilder/components/SimpleGraph/GraphInterface';
import { SimpleGraphContainerWithGreyBackground } from 'viewpointBuilder/components/SimpleGraph/SimpleGraphViewContainer';
import { MetaInfoPanelProps, MetaInfoPanel } from './MetaInfoPanel';
import { ViewpointBuilderFooter } from 'viewpointBuilder/components/ViewpointBuilderFooter';
import { PrimaryButtonConfig } from 'viewpointBuilder/openViewpointBuilder/getPrimaryButtonConfig';
import { ResizablePageBody } from '@ardoq/page-layout';

type MetaInfoContentContentProps = MetaInfoPanelProps & {
  closeViewpointBuilder: () => void;
  primaryButtonConfig: PrimaryButtonConfig;
  label: string;
};

export const MetaInfoContent = ({
  state,
  commands,
  closeViewpointBuilder,
  generateViewpointDescription,
  primaryButtonConfig,
  label,
}: MetaInfoContentContentProps) => {
  const graphInterface = useRef(createGraphInterface());

  const { graphNodes, graphEdges, graphGroups, errorState } = state;

  return (
    <ResizablePageBody
      skipPadding
      footerContent={
        <ViewpointBuilderFooter
          closeViewpointBuilder={closeViewpointBuilder}
          primaryButtonConfig={primaryButtonConfig}
        />
      }
      rightContentInitialWidth={600}
      rightContent={
        <MetaInfoPanel
          state={state}
          commands={commands}
          generateViewpointDescription={generateViewpointDescription}
        />
      }
    >
      {errorState === 'NONE' ? (
        <SimpleGraphContainerWithGreyBackground>
          <SimpleGraphView
            viewModel={{ graphNodes, graphEdges, graphGroups }}
            graphInterface={graphInterface.current}
            label={label}
          />
        </SimpleGraphContainerWithGreyBackground>
      ) : (
        <ModalText>
          <ErrorNotification>{errorState /* TODO */}</ErrorNotification>
        </ModalText>
      )}
    </ResizablePageBody>
  );
};
