import { NewBadge } from '@ardoq/status-ui';
import {
  ComponentType,
  ConfigurationState,
} from 'integrations/signavio-exporter/streams/types';
import { SelectionKeys } from './types';
import React from 'react';
import { DictionaryCategoriesState } from 'integrations/signavio-exporter/streams/dictionaryCategories/types';
import { SelectOption } from '@ardoq/select';
import { Maybe } from '@ardoq/common-helpers';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { byLocaleField } from 'integrations/common/utils/common';

export function isFieldMappingValid(config: ConfigurationState) {
  return Object.values(config.componentTypes).every(
    ct =>
      ct.dictionaryCategory.label &&
      ct.mappedComponentUrl.label &&
      ct.mappedOid.label
  );
}
/**
 * Returns the selected option for a field
 */
export function getSelectedOption(
  componentType: ComponentType,
  fieldName: SelectionKeys
) {
  if (!componentType || !fieldName || !componentType[fieldName].label) {
    return;
  }
  const isNew = !componentType[fieldName].value;

  return {
    ...componentType[fieldName],
    rightContent: isNew ? React.createElement(NewBadge) : null,
  };
}
export function getCategoryOptions(
  dictionaryCategories: DictionaryCategoriesState
) {
  const existingCategories = Object.values(dictionaryCategories.categories)
    .filter(category => category.value)
    .sort(byLocaleField('label'))
    .map(category => ({
      label: category.label,
      value: category.value,
    }));

  const newCategories = Object.values(dictionaryCategories.categories)
    .filter(category => !category.value)
    .sort(byLocaleField('label'))
    .map(category => ({
      label: category.label,
      value: category.value,
      rightContent: React.createElement(NewBadge),
    }));

  return [
    { label: 'New dictionaries', options: newCategories },
    { label: 'Other dictionaries', options: existingCategories },
  ];
}

export function getCategoryAttributeOptions(
  dictionaryCategories: DictionaryCategoriesState,
  componentTypeValue: ComponentType
) {
  const allAttributes =
    [...dictionaryCategories.allAttributes]
      .sort(byLocaleField('label'))
      .map(attribute => {
        if (!attribute.value) {
          return {
            ...attribute,
            rightContent: React.createElement(NewBadge),
          };
        }
        return attribute;
      }) || [];

  const selectedAttributes = [
    componentTypeValue.mappedComponentUrl.label,
    componentTypeValue.mappedOid.label,
  ];
  const filteredAllAttributes = allAttributes.filter(
    attr => !selectedAttributes.includes(attr.label)
  );

  const newAttributes =
    filteredAllAttributes
      .filter(attribute => !attribute.value)
      .sort(byLocaleField('label'))
      .map(attribute => ({
        ...attribute,
        rightContent: React.createElement(NewBadge),
      })) || [];

  const categoryValue = componentTypeValue.dictionaryCategory.value;

  if (!categoryValue) {
    return [
      { label: 'New attributes', options: newAttributes },
      { label: 'All attributes', options: filteredAllAttributes },
    ];
  }

  const categoryAttributes =
    dictionaryCategories.categories[categoryValue]?.categoryAttributes || [];
  // Filter out the selected attributes since we can't allow duplicate mappings
  const filteredCategoryAttributes = categoryAttributes.filter(
    attr => !selectedAttributes.includes(attr.label)
  );

  return [
    { label: 'New attributes', options: newAttributes },
    {
      label: 'Category attributes',
      options: filteredCategoryAttributes,
    },
    {
      label: 'Attributes from all categories',
      options: filteredAllAttributes,
    },
  ];
}

function isGroupOption(
  option: SelectOption<Maybe<string>> | GroupBase<SelectOption<Maybe<string>>>
): option is GroupBase<SelectOption<Maybe<string>>> {
  return 'options' in option;
}

export function isValidCreatableValue(
  inputValue: string,
  options: OptionsOrGroups<
    SelectOption<Maybe<string>>,
    GroupBase<SelectOption<Maybe<string>>>
  >,
  otherMappedValues: Maybe<string>[]
) {
  return (
    Boolean(inputValue) &&
    !otherMappedValues.includes(inputValue) &&
    !options.some(option => {
      if (isGroupOption(option)) {
        return option.options.some(
          opt => opt.label.toLowerCase() === inputValue.toLowerCase()
        );
      }
      return option.label.toLowerCase() === inputValue.toLowerCase();
    })
  );
}
