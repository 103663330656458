import { dispatchAction } from '@ardoq/rxbeach';
import { setIsUpdatingAppState } from '../isUpdatingAppState$';
import { clearLoadedState } from '../loadedState/actions';
import {
  setIsLoading,
  setIsLoadingWithConfig,
} from '../components/GlobalPaneLoader/globalPaneLoader$';
import { isError, rebuildAndRegisterState } from '../loadedState/buildState';
import defaultState from '../views/defaultState';
import {
  renderMainContentView,
  setActiveMainTabLeft,
} from '../streams/views/mainContent/actions';
import { requestShowAppModule } from '../appContainer/actions';
import { AppModules } from '../appContainer/types';
import { updateViewSettings } from '@ardoq/view-settings';
import { logError } from '@ardoq/logging';
import { contextInterface } from 'modelInterface/contextInterface';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { NumericSortOrder } from '@ardoq/common-helpers';
import { groupingRuleInterface } from '../modelInterface/groupingRules/groupingRuleInterface';
import { APIBookmarkAttributes } from '@ardoq/api-types';
import { notifyPerspectiveApplied } from '../perspective/actions';
import { triggerFiltersChangedEvent } from '../streams/filters/FilterActions';

/* This is largely copied from loadVisualizationSlide, with some slides-specific stuff taken out. */
export const loadBookmark = async (bookmark: APIBookmarkAttributes) => {
  dispatchAction(setIsUpdatingAppState(true));
  dispatchAction(clearLoadedState());
  await contextInterface.closeAllWorkspaces();
  dispatchAction(setIsLoadingWithConfig({ title: 'Loading data...' }));

  const responseOrError = await rebuildAndRegisterState({
    loadedState: bookmark.loadedStates,
  });
  dispatchAction(setIsLoading(false));

  if (isError(responseOrError)) {
    logError(
      Error(`${responseOrError.error} - ${responseOrError.reason}`),
      'Failed to load bookmark'
    );
    // TODO CL somehow show error message
    return;
  }

  await contextInterface.loadWorkspaces({
    workspaceIds: bookmark.workspaceId ? [bookmark.workspaceId] : [],
    contextWorkspaceId: bookmark.workspaceId ? bookmark.workspaceId : undefined,
  });

  if (bookmark.componentId) {
    await contextInterface.setComponentById(bookmark.componentId);
  } else {
    await contextInterface.unsetComponent();
  }

  contextInterface.setSort({
    attr: bookmark.sortAttr ?? 'name',
    order: bookmark.sortOrder ?? NumericSortOrder.ASC,
  });

  dispatchAction(renderMainContentView());
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
  );

  const viewstate = {
    ...defaultState.get(bookmark.view),
    ...structuredClone(bookmark.viewstate),
  };
  groupingRuleInterface.applyNewRules(bookmark.groupBys || []);
  filterInterface.loadFilters(bookmark.filters.advancedFilters);

  setTimeout(() => {
    // this timeout is here to dispatch the view settings update after the saved view settings replay
    dispatchAction(
      updateViewSettings({
        viewId: bookmark.view,
        settings: viewstate,
        persistent: false,
      })
    );
  });

  dispatchAction(triggerFiltersChangedEvent());
  dispatchAction(notifyPerspectiveApplied());
  dispatchAction(setActiveMainTabLeft({ activeTabId: bookmark.view }));
  setTimeout(() => dispatchAction(setIsUpdatingAppState(false)), 50);
};
