import { editTraversalOperations } from 'viewpointBuilder/traversals/editTraversalOperations';
import {
  ErrorState,
  SetTraversalPayload,
  TraversalPreviewState,
} from './types';
import { getId } from 'viewpointBuilder/getId';
import { MetaModelLoadedState } from 'architectureModel/loadMetaModel';
import {
  isArdoqError,
  partialPayload,
  pipeReducers,
} from '@ardoq/common-helpers';
import { traversalInterface } from 'modelInterface/traversals/traversalInterface';
import { TraversalState } from 'streams/traversals/types';
import { updateSelectedGraph } from 'viewpointBuilder/traversals/updateSelectedGraph';
import { TraversalBuilderState } from 'viewpointBuilder/types';

const reduceSetTraversalId = (
  state: TraversalPreviewState,
  { traversalId }: SetTraversalPayload
): TraversalPreviewState => {
  const { metaModel } = state;
  const traversal = traversalId
    ? traversalInterface.getById(traversalId)
    : null;
  if (metaModel === null) {
    // we keep the traversalId so that we can display loader for preview, we will set the id once more when the metamodel is loaded
    return { ...state, traversalId };
  }

  if (traversalId === null)
    return {
      ...state,
      traversalId,
      graphEdges: new Map(),
      graphNodes: new Map(),
      traversal: null,
    };

  if (!traversal)
    return {
      ...state,
      traversalId,
      errorState: 'NO_TRAVERSAL',
      graphEdges: new Map(),
      graphNodes: new Map(),
      traversal: null,
    };

  const { paths, startType, pathCollapsingRules = [] } = traversal;
  const {
    graphEdges,
    graphNodes,
    referenceMap,
    startGraphNode: startNode,
    requiredNodeIds,
  } = editTraversalOperations.pathsToGraph({
    paths,
    startType,
    metaModel,
    getId,
    startNode: null,
    filters: null,
  });

  const collapsedGraphState: TraversalBuilderState = pipeReducers(
    {
      ...editTraversalOperations.getInitialTraversalBuilderViewState(),
      ...state,
      errorState: 'NONE',
      graphEdges,
      graphNodes,
      startNode,
      requiredNodeIds,
      selectedGraph: updateSelectedGraph(referenceMap),
    },
    partialPayload(
      editTraversalOperations.updatePathCollapsingRules,
      pathCollapsingRules
    ),
    editTraversalOperations.updateEdgesToBeRemovedOnCollapsing,
    state => {
      const { collapsedGraphEdges, collapsedGraphNodes } =
        editTraversalOperations.buildCollapsedGraph(state);
      return {
        ...state,
        graphEdges: collapsedGraphEdges,
        graphNodes: collapsedGraphNodes,
      };
    }
  );

  return {
    ...state,
    graphEdges: collapsedGraphState.graphEdges,
    graphNodes: collapsedGraphState.graphNodes,
    traversalId,
    traversal,
  };
};

const reduceSetMetamodel = (
  state: TraversalPreviewState,
  metaModelResult: MetaModelLoadedState
) => {
  if (isArdoqError(metaModelResult)) {
    const errorState: ErrorState = 'NO_META_MODEL';
    return { ...state, errorState };
  }

  const updatedState = {
    ...state,
    metaModel: metaModelResult,
    graphEdges: new Map(),
    graphNodes: new Map(),
    isMetaModelLoaded: true,
  };

  if (state.traversalId) {
    // We keep the traversalId so that we can display loader for preview. We need to update the graph when the metamodel is loaded.
    return reduceSetTraversalId(updatedState, {
      traversalId: state.traversalId,
    });
  }

  return updatedState;
};

const reduceSetTraversals = (
  state: TraversalPreviewState,
  traversals: TraversalState
) => ({
  ...state,
  traversals,
});

export const traversalPreviewOperations = {
  reduceSetTraversalId,
  reduceSetMetamodel,
  reduceSetTraversals,
};
