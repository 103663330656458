import {
  SubStringReplaceTransformation,
  Transformation,
} from '@ardoq/api-types/integrations';
import { TextInput } from '@ardoq/forms';
import { Paragraph } from '@ardoq/typography';
import { FlexBox, Stack } from '@ardoq/layout';

type SubStringReplaceTransformationProps = {
  transformation: SubStringReplaceTransformation;
  setTransformation: (newTransformation: Transformation) => void;
};

const SubStringReplaceTransformationSection = ({
  transformation,
  setTransformation,
}: SubStringReplaceTransformationProps) => {
  const onMatchChange = (value: string) => {
    setTransformation({
      ...transformation,
      config: {
        ...transformation.config,
        match: value,
      },
    });
  };

  const onReplacementChange = (value: string) => {
    setTransformation({
      ...transformation,
      config: {
        ...transformation.config,
        replacement: value,
      },
    });
  };

  return (
    <Stack gap="medium">
      <Paragraph variant="text2">
        This will replace all occurrences of the given text.
      </Paragraph>

      <FlexBox align="center" gap="medium" marginBottom="medium">
        <Paragraph variant="text1">Replace</Paragraph>
        <TextInput
          value={transformation.config.match}
          onValueChange={onMatchChange}
        />
        <Paragraph variant="text1">With</Paragraph>
        <TextInput
          value={transformation.config.replacement}
          onValueChange={onReplacementChange}
          placeholder="Empty string"
        />
      </FlexBox>
    </Stack>
  );
};

export default SubStringReplaceTransformationSection;
