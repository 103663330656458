import {
  createLabelFormatting,
  deleteLabelFormatting,
  reorderLabelFormatting,
  updateLabelFormattingAt,
  perspectivesEditor$,
  showReferenceTypeToggled,
} from './perspectivesEditor$';
import { dispatchAction } from '@ardoq/rxbeach';
import { combineLatest, map } from 'rxjs';
import { LabelsTab, getAddLabelButtonProps } from '@ardoq/perspectives-sidebar';
import activeView$ from 'streams/views/mainContent/activeView$';
import { ViewIds } from '@ardoq/api-types';
import { ComponentProps } from 'react';
import type { LabelFormattingInfo } from '@ardoq/data-model';
import { getComponentTypes, getReferenceTypes } from '@ardoq/graph';
import {
  getFieldOptionsByComponentTypes,
  getFieldOptionsByReferenceTypes,
  getLabelFormattingElementProps,
  getDisabledReferenceFormattingMessage,
  getRestrictedMultiLabelFormattingWarningProps,
} from './multiLabelUtils';

type GetMultiLabelFormattingPropsArgs = {
  showReferenceType: boolean;
  labelFormatting: LabelFormattingInfo[];
  mainViewId: ViewIds;
  componentIds?: Set<string>;
  referenceIds?: Set<string>;
};

const getMultiLabelFormattingProps = ({
  labelFormatting,
  showReferenceType,
  mainViewId,
  componentIds,
  referenceIds,
}: GetMultiLabelFormattingPropsArgs) => {
  const componentTypes = getComponentTypes([...(componentIds ?? [])]);
  const referenceTypes = getReferenceTypes([...(referenceIds ?? [])]);

  const componentFieldOptionsByTypes =
    getFieldOptionsByComponentTypes(componentTypes);
  const referenceFieldOptionsByTypes =
    getFieldOptionsByReferenceTypes(referenceTypes);

  const disableReferenceFormattingMessage =
    getDisabledReferenceFormattingMessage(mainViewId);

  const labelFormattingElementProps = getLabelFormattingElementProps(
    labelFormatting,
    componentFieldOptionsByTypes,
    referenceFieldOptionsByTypes,
    Boolean(disableReferenceFormattingMessage)
  );

  const addLabelButtonProps = getAddLabelButtonProps({
    onLabelFormattingAdded: onLabelFormattingAdded,
    hasNoReferences: !referenceTypes.length,
    disableReferenceFormattingMessage,
  });

  const restrictedMultiLabelFormattingWarningProps =
    getRestrictedMultiLabelFormattingWarningProps({
      labelFormatting,
      viewId: mainViewId,
    });

  return {
    labelFormatting,
    showReferenceType,
    labelFormattingElementProps,
    disableReferenceFormattingMessage,
    addLabelButtonProps,
    restrictedMultiLabelFormattingWarningProps,
    onLabelFormattingAdded,
    onLabelFormattingReordered,
    onLabelFormattingDeleted,
    onUpdateLabelFormattingAt,
    onShowReferenceTypeToggled,
  };
};

const labelsTab$ = combineLatest([perspectivesEditor$, activeView$]).pipe(
  map(([state, { mainViewId }]): ComponentProps<typeof LabelsTab> => {
    const { formattingTab, datasetRelatedData } = state;
    const { labelFormatting, showReferenceType } = formattingTab;

    return getMultiLabelFormattingProps({
      labelFormatting,
      showReferenceType,
      mainViewId,
      componentIds: datasetRelatedData?.componentIds,
      referenceIds: datasetRelatedData?.referenceIds,
    });
  })
);

const onLabelFormattingAdded = (labelFormatting: LabelFormattingInfo) =>
  dispatchAction(createLabelFormatting(labelFormatting));

const onLabelFormattingReordered = (
  reorderedLabelFormatting: LabelFormattingInfo[]
) => dispatchAction(reorderLabelFormatting(reorderedLabelFormatting));

const onLabelFormattingDeleted = (deletedLabelFormattingIndex: number) =>
  dispatchAction(deleteLabelFormatting(deletedLabelFormattingIndex));

const onUpdateLabelFormattingAt = (
  index: number,
  value: Partial<LabelFormattingInfo>
) => dispatchAction(updateLabelFormattingAt({ index, ...value }));

const onShowReferenceTypeToggled = (showReferenceType: boolean) =>
  dispatchAction(showReferenceTypeToggled(showReferenceType));

export default labelsTab$;
