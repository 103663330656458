import { BUTTON_SELECTOR, IconButton, GhostButton } from '@ardoq/button';
import { colors, radius, s32 } from '@ardoq/design-tokens';
import { ICON_SELECTOR, IconName } from '@ardoq/icons';
import { Link, header2, text2 } from '@ardoq/typography';
import styled from 'styled-components';
import bannerBackground from './bannerBackground.svg';
import play from './play.svg';
import newViewpointBuilderLoom from './newViewpointBuilderLoom.gif';
import viewpointsWidget$ from './viewpointsWidget$';
import { connect } from '@ardoq/rxbeach';
import { ViewpointsWidgetStreamShape } from './types';
import GetStartedWithViewpoints from 'traversals/TraversalOverview/GetStartedWithViewpoints';
import { FlexBox, Stack } from '@ardoq/layout';

const BANNER_HEIGHT = 240;

const BannerContainer = styled(FlexBox).attrs({ justify: 'space-between' })`
  overflow: hidden;
  border-radius: ${radius.r8};
  border: 1px solid ${colors.brand15};
  width: 100%;
  height: ${BANNER_HEIGHT}px;
  background-color: ${colors.brand15};
`;

const LeftBannerContainer = styled(Stack).attrs({
  gap: 'large',
  align: 'baseline',
})`
  width: 440px;
  padding: ${s32};
  ${BUTTON_SELECTOR} {
    background-color: ${colors.white};
  }
`;

const TitleContainer = styled.div`
  ${header2};
  color: ${colors.white};
`;

const DescriptionContainer = styled.div`
  ${text2};
  color: ${colors.grey80};
  max-width: 440px;
`;

const StyledIconButton = styled(IconButton)`
  ${ICON_SELECTOR} {
    color: ${colors.white};
  }
  align-self: flex-start;
`;

const Gif = styled.img`
  border-radius: ${radius.r8};
  border: none;
`;

const SquarePatternWrapper = styled(FlexBox).attrs({
  gap: 'xxlarge',
  width: 'full',
})`
  padding: ${s32};
  /* To ensure inline SVGs work in Vite, we need to wrap the URL
     in double quotes. Although this SVG is currently too large
     to be inlined, we still quote it in case the threshold
     changes in the future. */
  /* prettier-ignore */
  background: url("${bannerBackground}");
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 718px;
  height: 100%;
`;

const ColorWrapper = styled(FlexBox)`
  background-color: ${colors.grey95};
  height: ${BANNER_HEIGHT}px;
  border-radius: ${radius.r8};
`;

const StyledLink = styled(Link)`
  position: relative;
`;

const PlayImg = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const NoViewpointsBanner = ({
  isBannerDismissed,
  commands,
  canUserCreateTraversals,
}: ViewpointsWidgetStreamShape) => {
  return isBannerDismissed ? (
    <ColorWrapper>
      <GetStartedWithViewpoints
        createNewViewpoint={commands.createNewViewpoint}
        canUserCreateTraversals={canUserCreateTraversals}
        showVisual={false}
      />
    </ColorWrapper>
  ) : (
    <BannerContainer>
      <LeftBannerContainer>
        <Stack gap="medium">
          <TitleContainer>Visualize just the data you need</TitleContainer>
          <DescriptionContainer>
            Enjoy a faster way to see specific components and what they&apos;re
            linked to across multiple workspaces without having to open the
            entire workspace. Save your explorations as a viewpoint template to
            reuse in future.
          </DescriptionContainer>
        </Stack>
        <GhostButton
          onClick={() => {
            commands.createNewViewpoint();
            commands.dismissBanner();
          }}
        >
          Try it now
        </GhostButton>
      </LeftBannerContainer>
      <FlexBox justify="flex-end" flex={1}>
        <SquarePatternWrapper>
          <StyledLink
            target="_blank"
            href="https://ardoq-resources.s3.eu-west-1.amazonaws.com/public/create-viewpoint-tutorial.mp4"
            hideIcon
          >
            <PlayImg src={play} />
            <Gif
              height="180"
              src={newViewpointBuilderLoom}
              alt="instructional video on the new viewpoint builder"
            />
          </StyledLink>
          <StyledIconButton
            iconName={IconName.CLOSE}
            onClick={commands.dismissBanner}
          />
        </SquarePatternWrapper>
      </FlexBox>
    </BannerContainer>
  );
};

export default connect(NoViewpointsBanner, viewpointsWidget$);
