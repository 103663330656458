import { dispatchAction } from '@ardoq/rxbeach';
import { ResetInvalidChangeRequestPayload } from './types';
import { resetInvalidChangeRequest } from './actions';

export type ChangeApprovalCommands = {
  resetInvalidChangeRequest: ({
    surveyId,
    componentId,
  }: ResetInvalidChangeRequestPayload) => void;
};

export const changeApprovalCommands: ChangeApprovalCommands = {
  resetInvalidChangeRequest: ({ surveyId, componentId }) => {
    dispatchAction(resetInvalidChangeRequest({ surveyId, componentId }));
  },
};
