import {
  PayloadShowRelatedComponents,
  ShowRelatedComponentsShape,
} from './types';
import { showScenarioRelatedComponents } from './actions';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { setLoadedScenarioData } from 'scope/actions';
import { PayloadLoadedScenarioData } from '../scope/types';

const handleShowScenarioRelatedComponents = (
  _: ShowRelatedComponentsShape,
  { componentIds }: PayloadShowRelatedComponents
) => ({ componentIds });

const handleSetLoadedScenarioData = (
  { componentIds }: ShowRelatedComponentsShape,
  { scenarioData }: PayloadLoadedScenarioData
) => {
  const scopeComponentsSet = new Set(scenarioData?.scopeComponents);
  return {
    componentIds: componentIds.filter(id => scopeComponentsSet.has(id)),
  };
};

const defaultState: ShowRelatedComponentsShape = {
  componentIds: [],
};

export const scenarioRelatedComponents$ =
  persistentReducedStream<ShowRelatedComponentsShape>(
    'scenarioRelatedComponents$',
    defaultState,
    [
      reducer(
        showScenarioRelatedComponents,
        handleShowScenarioRelatedComponents
      ),
      reducer(setLoadedScenarioData, handleSetLoadedScenarioData),
    ]
  );
