import { ReactNode, FC } from 'react';
import styled, { css } from 'styled-components';
import { dispatchAction } from '@ardoq/rxbeach';
import { colors, spacing } from '@ardoq/design-tokens';
import {
  ChevronIcon,
  ChevronIconName,
  Icon,
  ICON_SELECTOR,
  IconSize,
} from '@ardoq/icons';
import { backToSlide } from './actions';
import Markdown from 'atomicComponents/Markdown';
import { BASE_TRANSITION } from 'consts';
import { BUTTON_SELECTOR } from '@ardoq/button';

export const ExpandCollapse = styled(Icon).attrs({
  iconName: ChevronIconName.DOWN,
})`
  z-index: 11;
  margin-top: ${spacing.s8};
  margin-right: ${spacing.s16};
  cursor: pointer;
  padding: ${spacing.s4};
`;

export const ExpandCollapsible = styled.div`
  opacity: 0.8;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: ${colors.grey25};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
`;

export const SLIDE_NAVIGATION_HEIGHT = 36;
export const SlideNavigation = styled.div<{ $isUsingPagination: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 36px;
  background-color: ${({ $isUsingPagination }) =>
    $isUsingPagination ? colors.surfaceDefault : colors.grey25};
  ${({ $isUsingPagination }) =>
    !$isUsingPagination &&
    css`
      span,
      ${BUTTON_SELECTOR}, ${ICON_SELECTOR} {
        color: ${colors.surfaceDefault};
      }
    `}
`;

const PresentationNavigator = styled.div<{ $isMinimized: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 35%;
  color: ${colors.surfaceDefault};
  z-index: 2;

  table {
    color: ${colors.grey25};
    thead {
      background-color: ${colors.grey90};
    }
    tbody {
      tr {
        background-color: ${colors.surfaceDefault};
        &:nth-of-type(odd) {
          background-color: ${colors.grey95};
        }
      }
    }
  }
  ${({ $isMinimized }) =>
    $isMinimized &&
    css`
      border: none;
      ${ExpandCollapsible} {
        min-height: 36px;
        padding: 0;
        background-color: transparent;
        position: absolute;
        bottom: 34px;
        right: 0;
      }
      ${ExpandCollapse} {
        background-color: ${colors.grey25};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        &:hover {
          background-color: ${colors.grey60};
        }
        transform: rotate(180deg);
      }
    `}
`;

// the Markdown component is pretty aggressive about giving us black text, so we have to style it here
export const SlideMarkdown = styled(Markdown)`
  && {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol,
    p {
      color: ${colors.white};
    }
  }
`;

export const SlideDescription = styled(SlideMarkdown)`
  overflow: auto;
  opacity: 1;
  z-index: 1;
  width: 100%;
  height: auto;
  padding: ${spacing.s8} ${spacing.s48_DEPRECATED} 0 ${spacing.s8};
  transition: ${BASE_TRANSITION};
`;

export const NodeDescription = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  opacity: 1;
  z-index: 1;
  width: auto;
  height: auto;
  transition: ${BASE_TRANSITION};
`;

export const NodeDescriptionContent = styled.div`
  margin: 0;
  overflow: auto;
  padding: 8px ${spacing.s48_DEPRECATED} 0 0;
`;

export const SlideNavigatorContainer: FC<{
  isMinimized: boolean;
  children?: ReactNode;
}> = ({ isMinimized, children }) => (
  <PresentationNavigator $isMinimized={isMinimized}>
    {children}
  </PresentationNavigator>
);

export const DescriptionContainer = styled.div`
  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
  display: flex;
  flex: 1;
  padding: ${spacing.s16} 0 ${spacing.s8} 0;
  margin-right: -54px;

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
  }
`;
const NodeNav = styled.div`
  margin-right: 32px;
  flex-shrink: 0;
  display: block;
  padding: ${spacing.s8} 0 0 ${spacing.s8};
`;
const NodeNavLink = styled.a`
  cursor: pointer;
  color: ${colors.white};
  &:hover {
    color: ${colors.white};
  }
`;
export const BackToSlide = () => (
  <NodeNav>
    <NodeNavLink onClick={() => dispatchAction(backToSlide())}>
      <ChevronIcon
        direction="left"
        iconSize={IconSize.SMALL}
        style={{ verticalAlign: 'middle' }}
      />
      Back to slide
    </NodeNavLink>
  </NodeNav>
);
