import { ReactNode, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { colors, s16, s4 } from '@ardoq/design-tokens';
import { Icon, IconName } from '@ardoq/icons';
import { typographyMixins } from '@ardoq/typography';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  max-width: 100%;
  z-index: 1;
`;

export const PaneWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
`;

type TopBarComponentsProps = {
  $isScenarioMode?: boolean;
  $isScenarioOutOfSync?: boolean;
};

const getTopBarWrapperColors = ({
  $isScenarioMode,
  $isScenarioOutOfSync,
}: TopBarComponentsProps) => {
  if ($isScenarioOutOfSync && $isScenarioMode) {
    return css`
      background-color: ${colors.yellow60};
    `;
  }
  if ($isScenarioMode) {
    return css`
      color: ${colors.grey15};
      background-color: ${colors.grey80};
    `;
  }
  return css`
    color: ${colors.white};
    background-color: ${colors.grey35};
  `;
};

export const TopBarWrapper = styled.div<TopBarComponentsProps>`
  ${props => getTopBarWrapperColors(props)}
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 48px;
  justify-content: space-between;
  z-index: 1;

  & > div {
    display: flex;
    height: 100%;
  }
`;

const activeButton = css`
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
`;

export const TopBarButton = styled.button<{
  $isActive?: boolean;
  $isScenarioMode?: boolean;
}>`
  color: ${colors.iconOnInvertedStrong};
  background-color: transparent;
  border: 0;
  transition: all 0.2s ease-out;
  opacity: 0.8;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  ${props => props.$isActive && activeButton}
  &:not(:disabled):hover {
    ${activeButton}
  }
  ${({ $isScenarioMode }) =>
    $isScenarioMode &&
    css`
      i {
        color: ${colors.grey15};
      }
    `}
  svg {
    position: absolute;
    width: 20px;
    fill: ${({ $isScenarioMode }) =>
      $isScenarioMode ? colors.grey15 : colors.white};
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export type TopBarButtonWithMenuProps = {
  isMenuOpened: boolean;
  onMenuOpen: VoidFunction;
  tooltip?: string;
  isScenarioMode?: boolean;
  iconName?: IconName;
  children?: ReactNode;
  isDisabled?: boolean;
};

export const TopBarButtonWithMenu = forwardRef<
  HTMLButtonElement,
  TopBarButtonWithMenuProps
>(
  (
    {
      tooltip,
      isScenarioMode,
      onMenuOpen,
      isMenuOpened,
      iconName,
      children,
      isDisabled,
    },
    ref
  ) => (
    <TopBarButton
      data-tooltip-text={tooltip}
      $isScenarioMode={isScenarioMode}
      $isActive={isMenuOpened}
      onClick={onMenuOpen}
      disabled={Boolean(isDisabled)}
      ref={ref}
    >
      {iconName && <Icon iconName={iconName} />}
      {children}
    </TopBarButton>
  )
);

export const TopBarTitle = styled.div<{
  $isScenarioMode?: boolean;
}>`
  color: ${({ $isScenarioMode }) =>
    $isScenarioMode ? colors.grey15 : colors.white};
  font-weight: normal;
  ${typographyMixins.text1}
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

// Keep the element in the DOM to be able to focus it.
const offscreenStyle = css`
  position: absolute;
  left: -10000px;
`;

export const FilterWrapper = styled.div<{ $isOffscreen: boolean }>`
  display: flex;
  align-items: center;
  color: ${colors.grey25};
  margin-right: ${s4};
  ${props => props.$isOffscreen && offscreenStyle}
`;

export const TopBarButtonContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const SidebarFooter = styled.div`
  border-top: 1px solid ${colors.grey90};
  padding: ${s16};
`;
