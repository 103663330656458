import { throttle } from 'lodash';
import { useEffect } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import { setDockedGridEditorStyle } from './actions';
import { CSSAbsolutePositionProps } from './types';

/**
 * Translates viewport coordinates to a position using CSS absolute position
 * properties.
 */
const translateDOMRectToAbsolutePosition = (
  rect: DOMRect
): CSSAbsolutePositionProps => {
  return {
    position: 'absolute',
    top: `${Math.floor(rect.top)}px`,
    left: `${Math.floor(rect.left)}px`,
    width: `${rect.width}px`,
    height: `${rect.height}px`,
  };
};

/**
 * Observes and communicates the absolute position coordinates of the
 * editorPane in order for other components to know its position.
 *
 * Specifically, this communicates where the Grid Editor should draw its
 * content for it to be visible in MainLayout.
 *
 * @param editorPane Element who's position is observed and broadcasted
 * @returns undefined
 */
export const useEditorPaneContentSizeObserver = (
  editorPane: HTMLDivElement | null
) => {
  useEffect(() => {
    if (!editorPane) {
      return;
    }

    const editorPaneObserver = new ResizeObserver(
      throttle(
        () => {
          const rect = editorPane.getBoundingClientRect();
          const editorPosition = translateDOMRectToAbsolutePosition(rect);
          dispatchAction(setDockedGridEditorStyle(editorPosition));
        },
        125,
        { trailing: true }
      )
    );

    editorPaneObserver.observe(editorPane);
    editorPaneObserver.observe(document.body);
    return () => {
      editorPaneObserver.disconnect();
    };
  }, [editorPane]);
};
