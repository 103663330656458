import { FolderIcon as FolderIconBase } from 'components/EntityBrowser/atoms';
import styled from 'styled-components';
import { colors, s4 } from '@ardoq/design-tokens';
import { getInsetFocusRingBorderCSS } from '@ardoq/style-helpers';
import { Icon, IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';

export const FolderIcon = styled(FolderIconBase)`
  height: 24px;
  margin-right: 15px;
`;

export const FolderName = styled.span`
  cursor: pointer;
`;

export const FolderButton = styled.button`
  line-height: 1.4;
  padding: ${s4};
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: 0;
  background: none;
  cursor: pointer;
  text-align: left;
  min-height: 36px;
  &:hover {
    background: ${colors.surfaceHover};
  }
  &:focus-visible {
    ${getInsetFocusRingBorderCSS()};
  }
`;

const RootLevelButton = styled.button<{ $isSelected: boolean }>`
  border: none;
  height: 36px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  background-color: ${props =>
    props.$isSelected ? colors.surfaceActive : 'transparent'};
  &:hover {
    background: ${colors.surfaceHover};
  }
  &:focus-visible {
    ${getInsetFocusRingBorderCSS()};
  }
`;

export const RootLevelRow = ({
  isSelected,
  onClick,
}: {
  isSelected: boolean;
  onClick: () => void;
}) => (
  <RootLevelButton $isSelected={isSelected} onClick={onClick}>
    <FlexBox align="center" gap="small">
      <Icon
        iconName={IconName.CHECK_MARK}
        color={isSelected ? colors.iconActionActive : colors.transparent0}
      />
      <span>No folder</span>
    </FlexBox>
  </RootLevelButton>
);
