import { reducer } from '@ardoq/rxbeach';
import { PerspectivesEditorState } from '../types';
import {
  clearPerspectiveEditor,
  notifyPerspectiveCleared,
} from 'perspective/actions';

import { AsyncBackgroundOperation } from '@ardoq/perspectives';
import { clearPerspectiveEditor as clearPerspectiveEditorFn } from './clearPerspectiveEditor';

export const getAsyncBackgroundOperationInitialState =
  (): AsyncBackgroundOperation => ({
    type: 'NONE',
  });

/**
 * This action is dispatched when user clicks 'Clear all' button in the Perspective Editor.
 */
const handleClearPerspectiveEditorAction = reducer<PerspectivesEditorState>(
  clearPerspectiveEditor,
  clearPerspectiveEditorFn
);

/**
 * This action is dispatched when user clicks 'Clear' option in the main app Perspectives dropdown.
 */
const handleNotifyPerspectiveCleared = reducer<PerspectivesEditorState>(
  notifyPerspectiveCleared,
  clearPerspectiveEditorFn
);

export const perspectiveEditorReducers = [
  handleClearPerspectiveEditorAction,
  handleNotifyPerspectiveCleared,
];
