import { debounce } from 'lodash';
import { Observable, shareReplay, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators';
import Filters from 'collections/filters';
import { Filter } from 'aqTypes';
import { backboneFilterToFilterAttributes } from 'collections/filterUtils';
import { FilterAttributes } from '@ardoq/api-types';

const globalFilterAttributesListener = (subscriber: Subscriber<Filter[]>) => {
  const emitNext = () => {
    subscriber.next(Filters.getAllFilters());
  };
  const onCollectionChange = debounce(emitNext, 16 * 3, {
    leading: false,
    trailing: true,
  });

  // Initialize collection
  emitNext();

  Filters.on('all', onCollectionChange);
  return () => {
    Filters.off('all', onCollectionChange);
  };
};

/**
 * An observable that provide non-formatting filters as FilterAttributes[] in order
 * to keep GridEditor2023 in sync with dynamic and global filters in ardoq-front.
 * It's designed to be used on the ardoq-front side of the PostMessageBridge.
 *
 * WARNING: Result references BB model attributes, use with caution!
 *
 * A few things to note:
 * - This stream is used on both sides of the PostMessageBridge, this means
 *   ardoq-front does not need to copy the attributes because the bridge will
 *   enforce this when sending the data.
 *
 * - We have to extract the attributes from the nested Filter models before
 *   sending it across the bridge.
 *
 * - "createStreamFromBackboneCollection" deep clones and requires us to
 *   list all the attributes we want to include. Neither is necessary here.
 *
 * - notifyFiltersChanged is not broad enough to keep the observable in-sync
 *   with the collection; At least 'remove' and 'reset' events are missing in
 *   comparison with Filters.on('all', ...). It is not clear whether they safe
 *   to add because there are so many listeners, as well as call-sites with
 *   manual opt-out flags.
 *
 * - It trusts that the event emitted by Filters.on('all') indicate that the
 *   collection has changed, and that there would be no point doing deep
 *   comparisons to avoid unnecessary emissions.
 *
 * For these reasons this custom listener was chosen instead.
 */
export const bridgeGlobalFilterAttributes$ = new Observable<Filter[]>(
  globalFilterAttributesListener
).pipe(
  map((filters): FilterAttributes[] =>
    filters.map(backboneFilterToFilterAttributes)
  ),
  tag('gridEditor2023/bridgeGlobalFilterAttributes$'),
  shareReplay({ bufferSize: 1, refCount: true })
);
