import layout from '../models/layout';
import { DropTargetView, NavigatorDropType } from '../utils/consts';
import { NavigatorLayoutState } from '../types';
import Tree from '../models/tree';
import { getDefaultSort } from 'getDefaultSort';
import { getNavigatorStubViewInterface } from '../getNavigatorStubViewInterface';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

const getDefaultState: () => NavigatorLayoutState = () => {
  const tree = new Tree();
  return {
    container: null,
    showFilteredComponents: false,
    dragStartPosition: { x: 0, y: 0 },
    dragPosition: { x: 0, y: 0 },
    dragTargetNode: null,
    dragTargetNodeCandidate: null,
    dropTargetLayoutBox: null,
    dropTargetView: DropTargetView.NONE,
    dropType: NavigatorDropType.DROP_TYPE_NONE,
    existingRefs: {
      outgoing: new Set(),
      incoming: new Set(),
    },
    hasLinkTarget: false,
    height: 0,
    highlightChangeRequests: 0,
    isDrag: false,
    isDragEndTransition: false,
    isDropTargetBefore: false,
    isDropTargetParent: false,
    isExternalStart: false,
    isLinking: false,
    isViewpointMode: false,
    layoutNodeSet: new Set(),
    linkSourceIds: null,
    linkSourceNode: null,
    linkSourceStartPosition: null,
    rootLayoutBox: layout(tree, 0, 0),
    scrollTop: 0,
    selection: [],
    selectionSize: 0,
    selectionStart: '',
    selectionStash: {
      selection: [],
      selectionStart: '',
    },
    setClassNames: null,
    refDirection: null,
    shouldShowRefDrawing: false,
    isNavigator2024: true,
    tree,
    sort: getDefaultSort(),
    navigatorViewInterface: getNavigatorStubViewInterface(),
    activeScenarioState: activeScenarioOperations.getDefaultState(),
    isInitialized: false,
    dataLoadingMessage: null,
    dataErrorMessage: null,
  };
};

export default getDefaultState;
