import { actionCreator } from '@ardoq/rxbeach';
import { SetPrivilegesPayload } from './types';
import { UpdatePrivilegesPayload } from '@ardoq/api-types';

export const fetchPrivileges = actionCreator('[privileges] FETCH_PRIVILEGES');

export const updateUserPrivileges = actionCreator<UpdatePrivilegesPayload>(
  '[privileges] UPDATE_USER_PRIVILEGES'
);

export const setPrivileges = actionCreator<SetPrivilegesPayload>(
  '[privileges] SET_PRIVILEGES'
);

export const notifyFailedToFetchPrivileges = actionCreator(
  '[privileges] NOTIFY_FAILED_TO_FETCH_PRIVILEGES'
);
