import { SubdivisionProps } from 'subdivisionEditor/types';
import { ComponentTypeFieldsGroup } from './ComponentTypeFieldsGroup';
import { NoFieldsToConfigure } from './NoFieldsToConfigure';

export const PermissionsConfigurationSubStep = (props: SubdivisionProps) => {
  const {
    zonesById,
    componentTypes,
    globalFieldsByName,
    componentTypeFieldsGroupSearch,
    commands,
  } = props.zonesState;
  const { subdivisionEditorSubStep, bindWorkspacesCommands } = props;

  const zone = subdivisionEditorSubStep
    ? zonesById[subdivisionEditorSubStep]
    : undefined;

  const hasWorkspaces = bindWorkspacesCommands.hasBoundWorkspaces(props);
  const hasComponentTypes = componentTypes.length > 0;

  if (
    hasWorkspaces &&
    !componentTypeFieldsGroupSearch.searchKey &&
    !hasComponentTypes
  )
    return (
      <NoFieldsToConfigure
        commands={commands}
        boundWorkspacesCount={bindWorkspacesCommands.countBoundWorkspaces(
          props
        )}
      />
    );

  return (
    <>
      {zone && (
        <ComponentTypeFieldsGroup
          zone={zone}
          componentTypes={componentTypes}
          globalFieldsByName={globalFieldsByName}
          componentTypeFieldsGroupSearch={componentTypeFieldsGroupSearch}
          commands={commands}
        />
      )}
    </>
  );
};
