import {
  DirectedTripleWithFilters,
  MetaModel,
  ViewpointBuilderFilters,
} from '@ardoq/api-types';
import { enhancePartialScopeData } from '@ardoq/renderers';
import { TraversalBuilderState } from '../types';
import { PARENT_REF_TYPE_NAME } from '../utils';
import { ReferenceDirection } from '@ardoq/api-types';
import { GraphEdge, GraphNode, GraphNodeWithMetaData } from '@ardoq/graph';
import { metaModelOperations } from 'architectureModel/metaModelOperations';
import { enhancedScopeDataOperations } from '../enhancedScopeData/enhancedScopeDataOperations';
import type {
  RawGraphEdge,
  RawGraphNode,
} from '../enhancedScopeData/enhancedScopeDataOperations';
import { pathToKey } from './pathToKey';
import { getTripleId } from './getTripleId';
import { logWarn } from '@ardoq/logging';
import { getIcon } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { currentDateTimeISO } from '@ardoq/date-time';

type PathsToGraphArgs = {
  startType: string;
  paths: DirectedTripleWithFilters[][];
  filters: ViewpointBuilderFilters | null;
  metaModel: MetaModel;
  getId: () => string;
  startNode: GraphNodeWithMetaData | null;
  instanceCounts?: TraversalBuilderState['instanceCounts'];
};

export type PathsToGraphArgsWithRawGraphNodesAndEdges = {
  startType: string;
  paths: DirectedTripleWithFilters[][];
  getId: () => string;
  rawGraphNodes: TraversalBuilderState['rawGraphNodes'];
  rawGraphEdges: TraversalBuilderState['rawGraphEdges'];
  startNode: GraphNodeWithMetaData | null;
  instanceCounts?: TraversalBuilderState['instanceCounts'];
};

type PathsToGraphResult = {
  graphEdges: TraversalBuilderState['graphEdges'];
  graphNodes: TraversalBuilderState['graphNodes'];
  referenceMap: TraversalBuilderState['selectedGraph']['referenceMap'];
  instanceCounts: TraversalBuilderState['instanceCounts'];
  filterIdToGraphNodeIdMap: Map<string, string>;
  error: string | null;
  requiredNodeIds: string[];
};

// The traversal selected by the user is a structurally a tree with the start
// type at the root. It is stored as a list of paths from the root to the leafs.
// To display a traversal the paths need to be transformed back to a graph of
// yFiles graph nodes and edges.
export const pathsToGraph = (
  data: PathsToGraphArgs | PathsToGraphArgsWithRawGraphNodesAndEdges
): PathsToGraphResult & { startGraphNode: GraphNodeWithMetaData } => {
  const {
    startType,
    paths,
    getId,
    startNode,
    instanceCounts = new Map(),
  } = data;
  const { rawGraphNodeList, rawGraphEdgeList } = getRawNodesAndEdges(data);

  if (!getRawGraphNodeWithName(startType, rawGraphNodeList)) {
    logWarn(Error(`Start type doesn't exist anymore`));

    const startGraphNode = getFallbackGraphNode({
      typeName: startType,
      isContext: true,
      graphNodeId: getId(),
    });

    return {
      graphEdges: new Map(),
      graphNodes: new Map([[startGraphNode.id, startGraphNode]]),
      referenceMap: new Map(),
      instanceCounts,
      filterIdToGraphNodeIdMap: getFilterIdToGraphNodeIdMap(
        paths,
        startGraphNode
      ),
      error: `Start type doesn't exist in the meta model`,
      startGraphNode,
      requiredNodeIds: [],
    };
  }

  const startGraphNode =
    startNode ??
    addGraphNodeWithTypeName({
      typeName: startType,
      isContext: true,
      rawGraphNodeList,
      graphNodeId: getId(),
    });

  const pathKeyToGraphNode = new Map<string, GraphNode>();

  const graphData = paths.reduce<PathsToGraphResult>(
    (
      {
        graphEdges,
        graphNodes,
        referenceMap,
        instanceCounts: currentInstanceCounts,
        filterIdToGraphNodeIdMap,
        error,
        requiredNodeIds,
      },
      path
    ) => {
      path.reduce<{
        tail: GraphNode;
        pathToHead: DirectedTripleWithFilters[];
        error: string | null;
      }>(
        (processedState, directedTriple) => {
          if (processedState.error) {
            return processedState;
          }
          const {
            tail,
            pathToHead: previousPathToHead,
            error,
          } = processedState;
          const pathToHead = [...previousPathToHead, directedTriple];
          const key = pathToKey(pathToHead);
          if (pathKeyToGraphNode.has(key)) {
            return {
              pathToHead,
              tail: pathKeyToGraphNode.get(key)!,
              error,
            };
          }

          const {
            sourceType,
            referenceType,
            targetType,
            direction,
            sourceFilter,
            targetFilter,
            referenceFilter,
            qualifier,
          } = directedTriple;
          const isOutgoing = direction === ReferenceDirection.OUTGOING;
          const typeName = isOutgoing ? targetType : sourceType;
          const nodeId = getId();
          if (qualifier === 'required') {
            requiredNodeIds.push(nodeId);
          }
          const graphNode = addGraphNodeWithTypeName({
            typeName,
            rawGraphNodeList,
            graphNodeId: nodeId,
          });
          graphNodes.set(graphNode.id, graphNode);
          pathKeyToGraphNode.set(key, graphNode);
          currentInstanceCounts.set(graphNode.id, {
            namedDirectedTriplePath: pathToHead,
            graphNodeId: graphNode.id,
            pathKey: key,
            label: graphNode.getLabel(),
          });

          if (isOutgoing && targetFilter) {
            filterIdToGraphNodeIdMap.set(targetFilter, graphNode.id);
          }

          if (!isOutgoing && sourceFilter) {
            filterIdToGraphNodeIdMap.set(sourceFilter, graphNode.id);
          }

          const [source, target] = isOutgoing
            ? [tail, graphNode]
            : [graphNode, tail];
          const { id: sourceId, modelId: sourceModelId } = source;
          const { id: targetId, modelId: targetModelId } = target;

          const rawGraphEdge = getRawGraphEdge({
            referenceTypeName: referenceType,
            sourceModelId,
            targetModelId,
            rawGraphEdgeList,
          });
          if (!rawGraphEdge) {
            logWarn(Error('Raw graph edge not found'));
            return {
              tail,
              pathToHead: previousPathToHead,
              error: 'Raw graph edge not found',
            };
          }
          const graphEdge = GraphEdge.createWithMetaData(
            rawGraphEdge.modelId,
            sourceId,
            targetId,
            graphNodes,
            getId(),
            rawGraphEdge.metaData
          );
          graphEdges.set(graphEdge.id, graphEdge);
          referenceMap.set(graphEdge.id, {
            sourceId,
            targetId,
            tripleId: getTripleId({
              sourceId: sourceModelId,
              targetId: targetModelId,
              referenceId: rawGraphEdge.modelId,
            }),
          });

          if (referenceFilter) {
            filterIdToGraphNodeIdMap.set(referenceFilter, graphEdge.id);
          }

          return {
            pathToHead,
            tail: graphNode,
            error,
          };
        },
        { tail: startGraphNode, pathToHead: [], error }
      );
      return {
        graphEdges,
        graphNodes,
        referenceMap,
        instanceCounts: currentInstanceCounts,
        filterIdToGraphNodeIdMap,
        error,
        requiredNodeIds,
      };
    },
    {
      graphEdges: new Map(),
      graphNodes: new Map([[startGraphNode.id, startGraphNode]]),
      referenceMap: new Map(),
      instanceCounts,
      filterIdToGraphNodeIdMap: getFilterIdToGraphNodeIdMap(
        paths,
        startGraphNode
      ),
      error: null,
      requiredNodeIds: [],
    }
  );
  return {
    ...graphData,
    startGraphNode,
  };
};

const getFilterIdToGraphNodeIdMap = (
  paths: DirectedTripleWithFilters[][],
  startGraphNode: GraphNode
) => {
  const filterIdToGraphNodeIdMap = new Map<string, string>();
  if (paths.length === 0) {
    return filterIdToGraphNodeIdMap;
  }

  const [[{ direction, sourceFilter, targetFilter }]] = paths;
  if (sourceFilter && direction === ReferenceDirection.OUTGOING) {
    filterIdToGraphNodeIdMap.set(sourceFilter, startGraphNode.id);
  }
  if (targetFilter && direction === ReferenceDirection.INCOMING) {
    filterIdToGraphNodeIdMap.set(targetFilter, startGraphNode.id);
  }
  return filterIdToGraphNodeIdMap;
};

const getFallbackGraphNode = ({
  typeName,
  isContext,
  graphNodeId,
}: Omit<CreateGraphNodeWithTypeNameArgs, 'rawGraphNodeList'>) =>
  GraphNode.createWithMetaData(
    `fallback-id-${graphNodeId}`,
    false,
    graphNodeId,
    {
      representationData: {
        color: colors.black,
        shadedColor: colors.black,
        lightenedColor: colors.black,
        contrastColor: colors.black,
        icon: getIcon(null),
        isImage: false,
        value: '',
      },
      color: colors.black,
      typeId: 'fallback-id',
      label: `Missing Type (${typeName})`,
      isContext,
      shouldDisplayClickOtherComponentsHint: false,
    }
  );

const isPathsToGraphArgsWithRawGraphNodesAndEdges = (
  data: PathsToGraphArgs | PathsToGraphArgsWithRawGraphNodesAndEdges
): data is PathsToGraphArgsWithRawGraphNodesAndEdges => {
  return Boolean(
    (data as PathsToGraphArgsWithRawGraphNodesAndEdges).rawGraphNodes &&
      (data as PathsToGraphArgsWithRawGraphNodesAndEdges).rawGraphEdges
  );
};
const getRawGraphNodeWithName = (
  typeName: string,
  rawGraphNodeList: RawGraphNode[]
) => rawGraphNodeList.find(({ label }) => label === typeName);
type CreateGraphNodeWithTypeNameArgs = {
  typeName: string;
  isContext?: boolean;
  rawGraphNodeList: RawGraphNode[];
  graphNodeId: string;
};
const addGraphNodeWithTypeName = ({
  typeName,
  isContext,
  rawGraphNodeList,
  graphNodeId,
}: CreateGraphNodeWithTypeNameArgs): GraphNodeWithMetaData => {
  const rawGraphNode = getRawGraphNodeWithName(typeName, rawGraphNodeList)!;
  return GraphNode.createWithMetaData(
    rawGraphNode.modelId,
    false,
    graphNodeId,
    {
      ...rawGraphNode.metaData,
      isContext,
    }
  );
};
type GetRawGraphEdgeArgs = {
  referenceTypeName: string;
  sourceModelId: string;
  targetModelId: string;
  rawGraphEdgeList: RawGraphEdge[];
};
const getRawGraphEdge = ({
  referenceTypeName,
  sourceModelId,
  targetModelId,
  rawGraphEdgeList,
}: GetRawGraphEdgeArgs) => {
  /**
   * The parent reference type is a virtual type, the edit stream converts it to the format the BE understands, but that is not the same as we use in the meta-model,
   * so the type name has to be converted back to the meat model format so that the according reducer in editTraversal$ can create the correct graph.
   */
  const fixedTypeName =
    referenceTypeName === 'ardoq_parent'
      ? PARENT_REF_TYPE_NAME
      : referenceTypeName;

  const rawGraphEdge = rawGraphEdgeList.find(
    ({ metaData: { representationData }, sourceId, targetId }) =>
      representationData?.displayLabel === fixedTypeName &&
      sourceId === sourceModelId &&
      targetId === targetModelId
  );

  return rawGraphEdge;
};

const getRawNodesAndEdges = (
  data: PathsToGraphArgs | PathsToGraphArgsWithRawGraphNodesAndEdges
) => {
  if (isPathsToGraphArgsWithRawGraphNodesAndEdges(data)) {
    return {
      rawGraphNodeList: Array.from(data.rawGraphNodes.values()),
      rawGraphEdgeList: Array.from(data.rawGraphEdges.values()),
    };
  }

  // We create the scope data here only to be able to use existing features
  // like getRepresentation data with the according component, so user is
  // not relevant here at all. But could probably be worth it to simplify
  // this code and use the meta model data directly instead.
  const scopeData = enhancePartialScopeData(
    metaModelOperations.metaModelToScopeData({
      metaModel: data.metaModel,
      isoDate: currentDateTimeISO(),
      userEmail: '',
      userId: '',
      userName: '',
    })
  );

  const rawGraphNodeList = Array.from(
    enhancedScopeDataOperations.createRawGraphNodes(scopeData).values()
  );

  const rawGraphEdgeList = Array.from(
    enhancedScopeDataOperations.createRawGraphEdges(scopeData).values()
  );

  return { rawGraphNodeList, rawGraphEdgeList };
};

export const pathToGraphOperations = {
  getRawNodesAndEdges,
  getRawGraphEdge,
  addGraphNodeWithTypeName,
  getRawGraphNodeWithName,
  getFallbackGraphNode,
};
