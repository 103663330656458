import {
  DiffMetaStates,
  DiffStateMeta,
  DisabledReason,
  MergeState,
  StepResolution,
  mergeStepLabelToDataMap,
} from './types';
import { MergeDirection } from './MergeDirection';
import {
  APIComponentType,
  APIDiffBranchToBranch,
  APIDiffData,
  APIEntityType,
  APIReferenceType,
  ScopeDataCollection,
  Verb,
} from '@ardoq/api-types';
import { isEmpty } from 'lodash';
import { MergeStepLabel } from './typesMergeStepLabel';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { camelCase, capitalize, groupBy, mapValues, pickBy } from 'lodash';
import { FieldType, fieldAttributesMap } from '@ardoq/renderers';
import {
  DataSource,
  DataSourceItem,
  MergeState as DiffMergeTableMergeState,
  FieldConflict,
  MergeConflict,
  StructuralConflict,
} from 'components/DiffMergeTable/types';

const filterModelStepHasOnlyUpdateSubstep = (
  mergeStepLabel: MergeStepLabel,
  verb: Verb
) => !(mergeStepLabel === MergeStepLabel.STRUCTURE && verb !== Verb.UPDATE);

const filterMergeForEntitiesExceptTags = (
  mergeStepLabel: MergeStepLabel,
  verb: Verb
) =>
  (mergeStepLabel === MergeStepLabel.TAGS && verb === Verb.MERGE) ||
  verb !== Verb.MERGE;

export type StateData = Omit<APIDiffBranchToBranch, 'permissions'> & {
  [APIEntityType.COMPONENT_TYPE]: APIDiffData<Partial<APIComponentType>>;
  [APIEntityType.REFERENCE_TYPE]: APIDiffData<Partial<APIReferenceType>>;
};

const getValidMergeStepLabels = () => Object.values(MergeStepLabel);

const getValidVerbs = (mergeStepLabel: MergeStepLabel) =>
  Object.values(Verb).filter(
    verb =>
      filterModelStepHasOnlyUpdateSubstep(mergeStepLabel, verb) &&
      filterMergeForEntitiesExceptTags(mergeStepLabel, verb)
  );

const getMeta = (
  stateData: StateData | undefined,
  mergeStepLabel: MergeStepLabel,
  mainStateKey:
    | Exclude<ScopeDataCollection, ScopeDataCollection.PERMISSIONS>
    | APIEntityType.COMPONENT_TYPE
    | APIEntityType.REFERENCE_TYPE,
  subStateKey: Verb
) => {
  const item = stateData?.[mainStateKey]?.[subStateKey];
  return {
    state: isEmpty(item) ? StepResolution.COMPLETE : StepResolution.UNFINISHED,
    disabledReason: DisabledReason.NONE,
    ids: new Set(Object.keys(item || {})),
  };
};

export const buildMergeStateDiff = (
  stateData: StateData | undefined,
  mergeDirection: MergeDirection,
  submittedSteps: Record<MergeStepLabel, Set<Verb>>
) => {
  const mergeStepLabels = getValidMergeStepLabels();
  const state = Object.fromEntries(
    mergeStepLabels.map(mergeStepLabel => [
      mergeStepLabel,
      Object.fromEntries(
        getValidVerbs(mergeStepLabel).map(verb => [
          verb,
          getMeta(
            stateData,
            mergeStepLabel,
            mergeStepLabelToDataMap[mergeStepLabel],
            verb
          ),
        ])
      ),
    ])
  );

  const stepLabelVerbPairArray = mergeStepLabels.flatMap(label =>
    getValidVerbs(label).map(verb => [label, verb] as [MergeStepLabel, Verb])
  );
  // Find the first conflict step to fix
  const [startLabel, startVerb] =
    stepLabelVerbPairArray.find(
      ([label, verb]) =>
        !submittedSteps[label]?.has(verb) &&
        Object.values(stateData?.[mergeStepLabelToDataMap[label]]?.[verb] ?? [])
          .length
    ) || [];
  return { state, startLabel, startVerb, submittedSteps };
};

type LabelSubStepsTuple = [MergeStepLabel, DiffMetaStates[MergeStepLabel]];
type VerbStateTuple = [Verb, DiffStateMeta];

/**
 * Traverses the merge state tree of main steps and substeps and creates a
 * new state with the disabled reason. There are two type of disabled reasons,
 * the one depending only on the current step and a propagated disabled reason,
 * like e.g. create component types, which blockes the other steps, meaning
 * that step has to be done before the user can move forward.
 */
export const updatedDisabledState = (state: MergeState): MergeState => {
  const { diffMetaStates, mergeDirection } = state;

  if (!diffMetaStates) {
    return state;
  }

  const diffMetaStatesUpdated = Object.fromEntries(
    Object.entries(diffMetaStates).reduce(
      ({ updatedState, propagatedReason }, [label, subSteps]) => {
        const { subStepsUpdated, propagatedReasonSubStep } = Object.entries(
          subSteps
        ).reduce(
          (
            { subStepsUpdated, propagatedReasonSubStep },
            [verb, diffStateMeta]
          ) =>
            updateSubStep({
              mergeDirection,
              label: label as MergeStepLabel,
              verb: verb as Verb,
              diffStateMeta: diffStateMeta!,
              subStepsUpdated,
              propagatedReasonSubStep,
            }),
          {
            subStepsUpdated: [] as VerbStateTuple[],
            propagatedReasonSubStep: propagatedReason,
          }
        );

        return {
          updatedState: [
            ...updatedState,
            [label, Object.fromEntries(subStepsUpdated)] as LabelSubStepsTuple,
          ],
          propagatedReason: propagatedReasonSubStep,
        };
      },
      {
        updatedState: [] as LabelSubStepsTuple[],
        propagatedReason: DisabledReason.NONE,
      }
    ).updatedState
  );
  return {
    ...state,
    diffMetaStates: diffMetaStatesUpdated as DiffMetaStates,
  };
};

type UpdateSubStep = {
  subStepsUpdated: VerbStateTuple[];
  propagatedReasonSubStep: DisabledReason;
  verb: Verb;
  diffStateMeta: DiffStateMeta;
  mergeDirection: MergeDirection;
  label: MergeStepLabel;
};

const updateSubStep = ({
  subStepsUpdated,
  propagatedReasonSubStep,
  verb,
  diffStateMeta,
  mergeDirection,
  label,
}: UpdateSubStep) => {
  const disabledReason = getDisabledReasonForStep(
    diffStateMeta,
    propagatedReasonSubStep
  );

  return {
    subStepsUpdated: [
      ...subStepsUpdated,
      [verb, { ...diffStateMeta!, disabledReason }] as VerbStateTuple,
    ],
    propagatedReasonSubStep: getPropagatedReason(
      propagatedReasonSubStep,
      mergeDirection,
      label as MergeStepLabel,
      verb as Verb,
      diffStateMeta?.ids.size ?? 0
    ),
  };
};

const getDisabledReasonForStep = (
  diffStateMeta: DiffStateMeta,
  propagatedReasonSubStep: DisabledReason
) =>
  diffStateMeta.ids.size === 0
    ? DisabledReason.NOTHING_TO_MERGE
    : propagatedReasonSubStep;

export const getPropagatedReason = (
  disabledReason: DisabledReason,
  mergeDirection: MergeDirection,
  label: MergeStepLabel,
  verb: Verb,
  size: number
) => {
  if (
    mergeDirection === MergeDirection.MAINLINE_TO_BRANCH &&
    verb === Verb.CREATE &&
    size > 0
  ) {
    if (label === MergeStepLabel.COMPONENT_TYPES)
      return DisabledReason.MISSING_COMPONENT_TYPE;
    if (label === MergeStepLabel.REFERENCE_TYPES)
      return DisabledReason.MISSING_REFERENCE_TYPE;
    if (label === MergeStepLabel.FIELDS) return DisabledReason.MISSING_FIELD;
  }
  return disabledReason;
};

export const shouldMapEntityTypeForFields = (
  entityType: APIEntityType,
  fieldName: string
) =>
  entityType === APIEntityType.FIELD &&
  (fieldAttributesMap.get(fieldName) === FieldType.REFERENCE_TYPE ||
    fieldAttributesMap.get(fieldName) === FieldType.COMPONENT_TYPE);

const entityUIStrings = new Map([
  [MergeStepLabel.COMPONENT_TYPES, 'component types'],
  [MergeStepLabel.REFERENCE_TYPES, 'reference types'],
  [MergeStepLabel.FIELDS, 'fields'],
  [MergeStepLabel.COMPONENTS, 'components'],
  [MergeStepLabel.REFERENCES, 'references'],
  [MergeStepLabel.TAGS, 'tags'],
]);

const verbUIStrings = new Map([
  [Verb.DELETE, 'delete'],
  [Verb.CREATE, 'create'],
  [Verb.UPDATE, 'update'],
  [Verb.MERGE, 'merge'],
]);

export const getDialogTitle = (
  mergeDirection: MergeDirection,
  label: MergeStepLabel | null,
  verb: Verb | null,
  hasRemainingChanges: boolean,
  loading: boolean
) => {
  if (loading) return 'Loading...';
  if (!hasRemainingChanges) return 'Nothing to merge';
  if (!label || !verb) return '';

  const branch =
    mergeDirection === MergeDirection.MAINLINE_TO_BRANCH
      ? 'scenario'
      : 'mainline';

  if (verb === Verb.DELETE || verb === Verb.CREATE) {
    return `Select the ${entityUIStrings.get(
      label
    )} you want to ${verbUIStrings.get(verb)} in your ${branch}`;
  }

  if (verb === Verb.UPDATE) {
    return `Review and select the properties you want to use in your ${branch}`;
  }

  return `Review and select the components and references you want to tag in your ${branch}`;
};

const knowledgeBaseLink = new Map([
  [
    `${MergeStepLabel.COMPONENT_TYPES}-${Verb.CREATE}`,
    KnowledgeBaseLink.MERGE_FLOW_COMPONENT_TYPES_CREATE,
  ],
  [
    `${MergeStepLabel.COMPONENT_TYPES}-${Verb.DELETE}`,
    KnowledgeBaseLink.MERGE_FLOW_COMPONENT_TYPES_DELETE,
  ],
  [
    `${MergeStepLabel.COMPONENT_TYPES}-${Verb.UPDATE}`,
    KnowledgeBaseLink.MERGE_FLOW_COMPONENT_TYPES_UPDATE,
  ],
  [
    `${MergeStepLabel.COMPONENTS}-${Verb.CREATE}`,
    KnowledgeBaseLink.MERGE_FLOW_COMPONENTS_CREATE,
  ],
  [
    `${MergeStepLabel.COMPONENTS}-${Verb.DELETE}`,
    KnowledgeBaseLink.MERGE_FLOW_COMPONENTS_DELETE,
  ],
  [
    `${MergeStepLabel.COMPONENTS}-${Verb.UPDATE}`,
    KnowledgeBaseLink.MERGE_FLOW_COMPONENTS_UPDATE,
  ],
  [
    `${MergeStepLabel.FIELDS}-${Verb.CREATE}`,
    KnowledgeBaseLink.MERGE_FLOW_FIELDS_CREATE,
  ],
  [
    `${MergeStepLabel.FIELDS}-${Verb.DELETE}`,
    KnowledgeBaseLink.MERGE_FLOW_FIELDS_DELETE,
  ],
  [
    `${MergeStepLabel.FIELDS}-${Verb.UPDATE}`,
    KnowledgeBaseLink.MERGE_FLOW_FIELDS_UPDATE,
  ],
  [
    `${MergeStepLabel.STRUCTURE}-${Verb.UPDATE}`,
    KnowledgeBaseLink.MERGE_FLOW_STRUCTURE_UPDATE,
  ],
  [
    `${MergeStepLabel.REFERENCES}-${Verb.CREATE}`,
    KnowledgeBaseLink.MERGE_FLOW_REFERENCE_CREATE,
  ],
  [
    `${MergeStepLabel.REFERENCES}-${Verb.DELETE}`,
    KnowledgeBaseLink.MERGE_FLOW_REFERENCE_DELETE,
  ],
  [
    `${MergeStepLabel.REFERENCES}-${Verb.UPDATE}`,
    KnowledgeBaseLink.MERGE_FLOW_REFERENCE_UPDATE,
  ],
  [
    `${MergeStepLabel.REFERENCE_TYPES}-${Verb.CREATE}`,
    KnowledgeBaseLink.MERGE_FLOW_REFERENCE_TYPES_CREATE,
  ],
  [
    `${MergeStepLabel.REFERENCE_TYPES}-${Verb.DELETE}`,
    KnowledgeBaseLink.MERGE_FLOW_REFERENCE_TYPES_DELETE,
  ],
  [
    `${MergeStepLabel.REFERENCE_TYPES}-${Verb.UPDATE}`,
    KnowledgeBaseLink.MERGE_FLOW_REFERENCE_TYPES_UPDATE,
  ],
  [
    `${MergeStepLabel.TAGS}-${Verb.CREATE}`,
    KnowledgeBaseLink.MERGE_FLOW_TAGS_CREATE,
  ],
  [
    `${MergeStepLabel.TAGS}-${Verb.DELETE}`,
    KnowledgeBaseLink.MERGE_FLOW_TAGS_DELETE,
  ],
  [
    `${MergeStepLabel.TAGS}-${Verb.MERGE}`,
    KnowledgeBaseLink.MERGE_FLOW_TAGS_MERGE,
  ],
  [
    `${MergeStepLabel.TAGS}-${Verb.UPDATE}`,
    KnowledgeBaseLink.MERGE_FLOW_TAGS_UPDATE,
  ],
]);

export const getKnowledgeLink = (
  label: MergeStepLabel | null,
  verb: Verb | null
) =>
  label && verb
    ? ((knowledgeBaseLink.get(`${label}-${verb}`) || '') as KnowledgeBaseLink)
    : KnowledgeBaseLink.MERGE_FLOW;

export const getButtonText = (
  label: MergeStepLabel | null,
  verb: Verb | null
) => {
  if (!label || !verb) return '';

  if (verb === Verb.DELETE || verb === Verb.CREATE) {
    return `${capitalize(
      verbUIStrings.get(verb)
    )} selected ${entityUIStrings.get(label)}`;
  }

  return `Apply selection`;
};

const getConflictType = (dataSourceRow: DataSourceItem) => {
  if (dataSourceRow.structuralConflict !== StructuralConflict.NONE) {
    return dataSourceRow.structuralConflict;
  }

  if (dataSourceRow.status === DiffMergeTableMergeState.NONE) {
    return MergeConflict.VALUE_MODIFIED_ON_SOURCE_AND_TARGET;
  }

  if (dataSourceRow.hasTypeConflict) {
    return 'typeConflict';
  }

  if (dataSourceRow.fieldConflict !== FieldConflict.NONE) {
    return dataSourceRow.fieldConflict;
  }

  return null;
};

export const getConflictCounts = (dataSource: DataSource) =>
  pickBy(
    mapValues(groupBy(dataSource, getConflictType), group => group.length),
    // drop the count of rows without conflicts
    (_v, k) => k !== 'null'
  );

export const getIssuesPerStepKey = (mainStep: MergeStepLabel, subStep: Verb) =>
  camelCase(`${mainStep} ${subStep}`);

export const getIssuesPerStepCount = (
  mergeState: MergeState
): { [key: string]: number } =>
  Object.entries(mergeState.diffMetaStates || {}).reduce(
    (stepAcc, [stepName, stepData]) => ({
      ...stepAcc,
      ...Object.entries(stepData).reduce(
        (substepAcc, [subStepName, subStepData]) => ({
          ...substepAcc,
          [getIssuesPerStepKey(
            stepName as MergeStepLabel,
            subStepName as Verb
          )]: subStepData?.ids.size || 0,
        }),
        {}
      ),
    }),
    {}
  );

export const getHasRemainingChanges = (
  diffMetaStates: DiffMetaStates | null,
  submittedSteps?: Record<MergeStepLabel, Set<Verb>>
) =>
  diffMetaStates
    ? Object.entries(diffMetaStates).some(
        ([mergeStepLabel, diffMetaStatesForMergeLabel]) =>
          Object.entries(diffMetaStatesForMergeLabel).some(
            ([verb, diffStateMeta]) =>
              diffStateMeta?.state === StepResolution.UNFINISHED &&
              !(
                submittedSteps &&
                submittedSteps[mergeStepLabel as MergeStepLabel]?.has(
                  verb as Verb
                )
              )
          )
      )
    : false;

export const isLastUnsubmittedStep = (
  mainStateStep: MergeStepLabel,
  subStateStep: Verb,
  submittedSteps: Record<MergeStepLabel, Set<Verb>>,
  diffMetaStates: DiffMetaStates | null
) => {
  const submittedStepsPlusCurrent = {
    ...submittedSteps,
    [mainStateStep]: new Set([
      ...(submittedSteps[mainStateStep] ? submittedSteps[mainStateStep] : []),
      subStateStep,
    ]),
  };
  return (
    diffMetaStates &&
    !getHasRemainingChanges(diffMetaStates, submittedStepsPlusCurrent)
  );
};
