import { dispatchAction } from '@ardoq/rxbeach';
import {
  APIViewpointAttributes,
  FilterInterfaceFilter,
  FormattingFilter,
} from '@ardoq/api-types';
import {
  applyFormatting,
  applyConditionalFormatting,
} from 'streams/perspectiveEditorData/applyFormatting';
import { mapToPerspectiveEditorFormattingRule } from 'streams/perspectiveEditorData/mapToPerspectiveEditorFormattingRule';
import { notifyPerspectiveApplied } from 'perspective/actions';
import { groupingRuleInterface } from 'modelInterface/groupingRules/groupingRuleInterface';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { PerspectivesFormatting } from '@ardoq/perspectives';
import { filterInterface } from '@ardoq/filter-interface';
import { loadedGraph$ } from './loadedGraph$';

export const applyViewpointPerspectives = (
  traversal: APIViewpointAttributes | null
) => {
  if (!traversal) {
    return;
  }

  groupingRuleInterface.applyNewRules(traversal.groupBys || []);

  const isConditionalFormatting = (
    filter: FilterInterfaceFilter | FormattingFilter
  ): filter is FormattingFilter => 'color' in filter;

  const { conditionalFormatting, labelFormatting } = (
    traversal?.conditionalFormatting || []
  ).reduce(
    (
      acc: {
        conditionalFormatting: FormattingFilter[];
        labelFormatting: FilterInterfaceFilter[];
      },
      filter
    ) => {
      if (isConditionalFormatting(filter)) {
        acc.conditionalFormatting.push(filter);
        return acc;
      }
      acc.labelFormatting.push(filter);
      return acc;
    },
    { conditionalFormatting: [], labelFormatting: [] }
  );

  const conditionalFormattingRules = conditionalFormatting
    .map(mapToPerspectiveEditorFormattingRule)
    .filter(ExcludeFalsy);

  if (loadedGraph$.state.isViewpointMode) {
    filterInterface.removeComponentLabelFormattingFilters(true);
    filterInterface.removeReferenceLabelFormattingFilters(true);
    labelFormatting.forEach(attrs =>
      filterInterface.createFilter(attrs, { shouldTriggerChangeEvent: false })
    );
    applyConditionalFormatting(conditionalFormattingRules);
    dispatchAction(notifyPerspectiveApplied());

    return;
  }

  const componentLabelFormat = labelFormatting.find(
    ({ affectComponent }) => affectComponent
  );
  const referenceLabelFormat = labelFormatting.find(
    ({ affectReference }) => affectReference
  );

  const formatting: PerspectivesFormatting = {
    conditionalFormattingRules,
    componentLabelFormattingValue: componentLabelFormat?.value,
    isComponentLabelTimeIncluded: componentLabelFormat?.includeTime,
    referenceLabelFormattingValue: referenceLabelFormat?.value,
    isReferenceLabelTimeIncluded: referenceLabelFormat?.includeTime,
  };

  applyFormatting(formatting);
  dispatchAction(notifyPerspectiveApplied());
};
