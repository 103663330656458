import React from 'react';
import {
  ButtonGroup,
  ButtonSize,
  IconButton,
  SecondaryButton,
} from '@ardoq/button';
import { DragIndicatorIcon, Icon, IconName, IconSize } from '@ardoq/icons';
import { Select } from '@ardoq/select';
import { FormSize, TextInput } from '@ardoq/forms';
import {
  ALL_FILTER_CONDITIONS,
  FILTER_LABEL,
  isConditionWithValue,
} from '../utils';
import {
  FilterCondition,
  FilterConditionForValue,
} from '@ardoq/api-types/integrations';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import styled from 'styled-components';
import { StatusType, Tag } from '@ardoq/status-ui';
import { colors, r8, s16 } from '@ardoq/design-tokens';
import { dispatchAction } from '@ardoq/rxbeach';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { Paragraph } from '@ardoq/typography';
import { EmptyFilterCondition } from '../types';
import { CollapsibleIsland } from '@ardoq/page-layout';
import { Box, FlexBox, Stack } from '@ardoq/layout';

type FilterSectionProps = {
  integrationId: IntegrationId;
  isExpanded: boolean;
  conditionsList: Array<FilterCondition | EmptyFilterCondition>;
  setConditionsList: (
    condition: Array<FilterCondition | EmptyFilterCondition>
  ) => void;
  defaultFilterCondition: EmptyFilterCondition;
  index: number;
};

export const FilterSection = ({
  isExpanded,
  integrationId,
  conditionsList,
  setConditionsList,
  defaultFilterCondition,
  index,
}: FilterSectionProps) => {
  const renderTitle = () => (
    <FlexBox>
      {index}. Filter data
      {conditionsList.length && (
        <Box marginLeft="small">
          <Tag statusType={StatusType.INFO}>
            {conditionsList.length === 1
              ? '1 rule'
              : `${conditionsList.length} rules`}
          </Tag>
        </Box>
      )}
    </FlexBox>
  );

  const onConditionSelect = (
    operator: FilterCondition['operator'],
    index: number
  ) => {
    const condition = ALL_FILTER_CONDITIONS.find(
      cond => cond.operator === operator
    );
    if (condition) {
      setConditionsList([
        ...conditionsList.slice(0, index),
        condition,
        ...conditionsList.slice(index + 1),
      ]);
    }
    dispatchAction(
      trackIntegrationEvent({ integrationId, name: 'ADDED_FILTER_OPTION' })
    );
  };

  const onConditionValueChange = (
    condition: FilterConditionForValue,
    index: number
  ) => {
    setConditionsList([
      ...conditionsList.slice(0, index),
      condition,
      ...conditionsList.slice(index + 1),
    ]);
  };

  const onConditionDelete = (index: number) => {
    setConditionsList(conditionsList.filter((_, idx) => idx !== index));
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'DELETED_TRANSFORMATIONS_FILTER_OPTION',
      })
    );
  };

  return (
    <CollapsibleIsland
      title={renderTitle()}
      isDefaultExpanded={isExpanded}
      toggleOnHeaderClick
      rightContent={
        <ButtonGroup>
          <IconButton
            iconName={IconName.DELETE}
            onClick={() => {
              setConditionsList([]);
              dispatchAction(
                trackIntegrationEvent({
                  integrationId,
                  name: 'CLEARED_COLUMN_FILTERS',
                })
              );
            }}
          />
        </ButtonGroup>
      }
    >
      <Stack gap="small">
        <Paragraph variant="text2" color="textDefault">
          Include rows which meet the following criteria
        </Paragraph>
        <Stack gap="small">
          {conditionsList.map((condition, index) => (
            <FlexBox key={index} align="center" gap="medium">
              {conditionsList.length > 1 && (
                <TagWrapper $visible={index !== 0}>And</TagWrapper>
              )}
              <Border
                gap="medium"
                flex={1}
                $showConnection={index !== conditionsList.length - 1}
                $showBorderTop={index === 0}
              >
                <DragIndicatorIcon size={IconSize.SMALL} />
                <Stack flex={1}>
                  <Select
                    placeholder="Select condition"
                    formSize={FormSize.FULL}
                    options={ALL_FILTER_CONDITIONS.map(({ operator }) => ({
                      label: FILTER_LABEL[operator],
                      value: operator,
                    }))}
                    value={condition.operator}
                    onValueChange={option =>
                      option && onConditionSelect(option, index)
                    }
                  />
                </Stack>
                {isConditionWithValue(condition) && (
                  <Stack flex={1}>
                    <TextInput
                      formSize={FormSize.FULL}
                      value={condition.value}
                      displayValue={condition.value}
                      onValueChange={value => {
                        onConditionValueChange({ ...condition, value }, index);
                      }}
                    />
                  </Stack>
                )}
                {conditionsList.length > 1 && (
                  // We could use the icon button here but that has its own padding and make ui bad
                  <IconButton
                    iconName={IconName.DELETE}
                    onClick={() => onConditionDelete(index)}
                  />
                )}
              </Border>
            </FlexBox>
          ))}
          <Box
            marginLeft={conditionsList.length > 1 ? 'xxlarge' : undefined}
            marginTop="small"
          >
            <SecondaryButton
              onClick={() => {
                setConditionsList([...conditionsList, defaultFilterCondition]);
              }}
              buttonSize={ButtonSize.SMALL}
            >
              <Icon iconName={IconName.ADD} iconSize={IconSize.MEDIUM} />
              Add rule
            </SecondaryButton>
          </Box>
        </Stack>
      </Stack>
    </CollapsibleIsland>
  );
};

const TagWrapper = styled(Tag)<{ $visible: boolean }>`
  z-index: 1;
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`;

const Border = styled(Stack)<{
  $showConnection?: boolean;
  $showBorderTop?: boolean;
}>`
  flex-direction: row;
  align-items: center;
  padding: ${s16};
  border: 1px dashed ${colors.grey80};
  border-radius: ${r8};
  position: relative;
  ${({ $showConnection, $showBorderTop }) =>
    $showConnection &&
    `
    &:before {
      position: absolute;
      left: -37px;
      width: 37px;
      top: 35px;
      border-radius: ${r8} 0px 0px ${r8};
      border-left: 2px dashed ${colors.blue60};
      border-bottom: 2px dashed ${colors.blue60};
      height: 88px;
      content: ' ';

      ${$showBorderTop && `border-top: 2px dashed ${colors.blue60};`}
    }
  `}
`;
