import { combineLatest, tap } from 'rxjs';
import auditLog$ from './auditLog$';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { debounce, isEmpty, isEqual, pick, sortBy } from 'lodash';
import {
  APIOrganizationUser,
  ArdoqId,
  PersonalSetting,
  AuditLogAction,
  AuditLogEntityType,
} from '@ardoq/api-types';
import {
  filtersToSearchAndFilterBarActiveFilters,
  formatStateForAPIRequest,
  getCurrentPageFromPageState,
  getRemoveFilter,
} from './utils';
import {
  actionRepresentation,
  AuditLogTableProps,
  NotificationAreaProps,
} from '@ardoq/audit-log';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  clearAllFilters,
  fetchAuditLogData,
  fetchNextPage,
  fetchPreviousPage,
  restoreComponentRequested,
  restoreReferenceRequested,
  setAreAllRowsExpanded,
  setAuditLogFilter,
  setAuditLogPageSize,
  setChangeTypeFilterGroups,
  setChangeTypeFilterSpecifics,
  setIsRowExpanded,
  toggleIsFilterSidebarOpen,
} from './actions';
import {
  AuditLog$State,
  AuditLogViewModelState,
  DateRange,
  FilterSectionProps,
} from './types';
import { FilterSections, LoadingState } from './consts';
import currentUser from '../models/currentUser';
import { UNSET_VALUE_LABEL } from '@ardoq/renderers';
import { SelectOption } from '@ardoq/select';
import {
  getLoadComponentSuggestions,
  getLoadComponentTypeNameSuggestions,
  getLoadFieldSuggestions,
  getLoadReferenceSuggestions,
  getLoadReferenceTypeNameSuggestions,
  getLoadWorkspaceSuggestions,
} from './suggestionAPIUtils';
import { FetchSuggestionFn } from '../search/AdvancedSearch/types';
import { hasFeature, Features } from '@ardoq/features';
import { timestampToDateTimeISO } from '@ardoq/date-time';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { isArdoqError } from '@ardoq/common-helpers';
import surveys$ from 'streams/surveys/surveys$';

const userToSelectOption = (asset: APIOrganizationUser) => ({
  value: asset._id,
  label: asset.name ?? asset.email ?? UNSET_VALUE_LABEL,
});

const loadOptionsDebounced = debounce(
  (
    query: string,
    callback: (options: Array<{ value: string; label: string }>) => void,
    requestFn: FetchSuggestionFn
  ) => {
    requestFn(query).then(response => {
      callback(response);
    });
  },
  300
);

export const getFilterProps = (
  auditLogState: AuditLog$State,
  usersById: Record<ArdoqId, APIOrganizationUser>,
  hasFullAuditLogHistoryFeature: boolean
): FilterSectionProps => {
  const { filters: selectedFilters } = auditLogState;
  return {
    name: selectedFilters.name,
    [FilterSections.WORKSPACES]: {
      selected: selectedFilters.workspaces,
      loadOptions: (query, callback) => {
        loadOptionsDebounced(
          query,
          callback,
          getLoadWorkspaceSuggestions(
            formatStateForAPIRequest({
              ...auditLogState,
              filters: {
                ...auditLogState.filters,
                // Sent filters get applied to suggestions to limit results
                // Don't filter suggestions by already chosen workspaces or date range
                // NOTE: In order to include all workspaces, we override the default
                //  'since' date of 30 days to beginning of the UNIX epoch.
                workspaces: [],
                dateRange: { before: null, since: timestampToDateTimeISO(0) },
              },
            })
          )
        );
      },
      onValueChange: (workspaces: SelectOption<ArdoqId>[]) =>
        dispatchAction(setAuditLogFilter({ workspaces })),
    },
    [FilterSections.USERS]: {
      selected: selectedFilters.users,
      options: sortBy(
        Object.values(usersById).map(userToSelectOption),
        ({ label }) => label.toLowerCase()
      ),
      onValueChange: (users: ArdoqId[]) =>
        dispatchAction(setAuditLogFilter({ users: users })),
    },
    [FilterSections.DATE_RANGE]: {
      selected: selectedFilters.dateRange,
      onValueChange: (dateRange: DateRange) =>
        dispatchAction(setAuditLogFilter({ dateRange })),
      hasFullAuditLogHistoryFeature,
    },
    [FilterSections.ACTIONS]: {
      selected: selectedFilters.actions,
      options: Object.values(actionRepresentation),
      onValueChange: (actions: AuditLogAction[]) =>
        dispatchAction(
          setAuditLogFilter({
            actions,
          })
        ),
    },
    [FilterSections.CHANGE_TYPE]: {
      selected: selectedFilters.entityTypes,
      onCheckboxValueChange: (entityTypes: Array<AuditLogEntityType>) =>
        dispatchAction(setChangeTypeFilterGroups(entityTypes)),
      onSelectValueChange: (
        specifics: Array<SelectOption<string> | string> | null,
        entityType: AuditLogEntityType
      ) =>
        dispatchAction(setChangeTypeFilterSpecifics({ entityType, specifics })),
      loadComponentOptions: (query, callback) => {
        loadOptionsDebounced(
          query,
          callback,
          getLoadComponentSuggestions(formatStateForAPIRequest(auditLogState))
        );
      },
      loadComponentTypeOptions: (query, callback) => {
        loadOptionsDebounced(
          query,
          callback,
          getLoadComponentTypeNameSuggestions(
            formatStateForAPIRequest(auditLogState)
          )
        );
      },
      loadReferenceOptions: (query, callback) => {
        loadOptionsDebounced(
          query,
          callback,
          getLoadReferenceSuggestions(formatStateForAPIRequest(auditLogState))
        );
      },
      loadReferenceTypeOptions: (query, callback) => {
        loadOptionsDebounced(
          query,
          callback,
          getLoadReferenceTypeNameSuggestions(
            formatStateForAPIRequest(auditLogState)
          )
        );
      },
      loadFieldOptions: (query, callback) => {
        loadOptionsDebounced(
          query,
          callback,
          getLoadFieldSuggestions(formatStateForAPIRequest(auditLogState))
        );
      },
      loadWorkspaceOptions: (query, callback) => {
        loadOptionsDebounced(
          query,
          callback,
          getLoadWorkspaceSuggestions(formatStateForAPIRequest(auditLogState))
        );
      },
    },
  };
};

const stateKeysWhichTriggerFetch: Array<keyof AuditLog$State> = [
  'lastRestoreResult',
];
const reactiveActions$ = auditLog$.pipe(
  distinctUntilChanged((a: AuditLog$State, b: AuditLog$State) =>
    isEqual(
      pick(a, stateKeysWhichTriggerFetch),
      pick(b, stateKeysWhichTriggerFetch)
    )
  ),
  tap(({ paginationState: { pageSize: size, currentPage: pageNumber } }) =>
    dispatchAction(fetchAuditLogData({ size, pageNumber }))
  )
);

const getAuditLogTableProps = ({
  data,
}: AuditLog$State): AuditLogTableProps => ({
  data,
  expandedRowIds: data
    .filter(({ meta }) => meta.isSelected)
    .map(({ rowId }) => rowId),
  actionRepresentation,
});

const getAuditLogPaginationProps = ({ paginationState }: AuditLog$State) => ({
  paginationState: {
    ...paginationState,
    currentPage: getCurrentPageFromPageState(paginationState.pageState),
  },
});

const getSearchAndFilterBarProps = (
  { filters: selectedFilters, isFilterSidebarOpen }: AuditLog$State,
  filterProps: FilterSectionProps
) => ({
  activeFilters: filtersToSearchAndFilterBarActiveFilters(filterProps),
  removeFilter: getRemoveFilter(selectedFilters),
  clearAllFilters: () => dispatchAction(clearAllFilters()),
  onSearch: (name: string) => dispatchAction(setAuditLogFilter({ name })),
  onFilterButtonClick: () =>
    dispatchAction(toggleIsFilterSidebarOpen(!isFilterSidebarOpen)),
});

const getNotificationAreaProps = (
  {
    showRefreshNotification,
    lastRestoreResult,
    paginationState,
  }: AuditLog$State,
  hasFullAuditLogHistoryFeature: boolean
): NotificationAreaProps => ({
  errorNotificationError: isArdoqError(lastRestoreResult)
    ? lastRestoreResult
    : null,
  showRefreshNotification,
  showLimitedHistoryNotification:
    !hasFullAuditLogHistoryFeature && !paginationState.hasNextPage,
});

const viewModel$ = combineLatest([
  surveys$,
  orgUsers$,
  auditLog$,
  reactiveActions$,
]).pipe(
  map(
    ([
      { byId: allSurveysById },
      { byId: allUsersById },
      auditLogState,
    ]): AuditLogViewModelState => {
      const { isFilterSidebarOpen, data, loadingState, apiErrors } =
        auditLogState;
      const hasFullAuditLogHistoryFeature = hasFeature(
        Features.FULL_AUDIT_LOG_HISTORY
      );

      const isAuditLogEmpty =
        loadingState === LoadingState.LOADED && isEmpty(data);

      const auditLogTableProps = getAuditLogTableProps(auditLogState);
      const auditLogPaginationProps = getAuditLogPaginationProps(auditLogState);
      const filterProps = getFilterProps(
        auditLogState,
        allUsersById,
        hasFullAuditLogHistoryFeature
      );
      const searchAndFilterBarProps = getSearchAndFilterBarProps(
        auditLogState,
        filterProps
      );
      const notificationAreaProps = getNotificationAreaProps(
        auditLogState,
        hasFullAuditLogHistoryFeature
      );

      return {
        data,
        isFilterSidebarOpen,
        searchAndFilterBarProps,
        loadingState,
        commands: {
          setIsRowExpanded: (rowId: string) => {
            dispatchAction(setIsRowExpanded(rowId));
          },
          fetchPreviousPage: () => {
            dispatchAction(fetchPreviousPage());
          },
          fetchNextPage: () => {
            dispatchAction(fetchNextPage());
          },
          setPageSize: (pageSize: number) =>
            dispatchAction(setAuditLogPageSize(pageSize)),
          setAreAllRowsExpanded: (isExpanded: boolean) =>
            dispatchAction(setAreAllRowsExpanded(isExpanded)),
          lookupUser: (userId: string) => allUsersById[userId],
          lookupSurveyName: (surveyId: string) =>
            allSurveysById[surveyId]?.name,
          fetchFirstPage: () => {
            dispatchAction(fetchAuditLogData(null));
          },
          requestRestoreComponent: (componentId: ArdoqId) =>
            dispatchAction(restoreComponentRequested(componentId)),
          requestRestoreReference: (referenceId: ArdoqId) =>
            dispatchAction(restoreReferenceRequested(referenceId)),
        },
        apiErrors,
        hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
        hasFullAuditLogHistoryFeature,
        isAuditLogEmpty,
        auditLogTableProps,
        auditLogPaginationProps,
        filterProps,
        notificationAreaProps,
      };
    }
  ),

  tap(() => {
    if (!currentUser.getPersonalSetting(PersonalSetting.HAS_SEEN_AUDIT_LOG)) {
      currentUser.setPersonalSetting(PersonalSetting.HAS_SEEN_AUDIT_LOG, true);
    }
  })
);

export default viewModel$;
