import { colors, s64 } from '@ardoq/design-tokens';
import { Header4, Text } from '@ardoq/typography';
import styled from 'styled-components';
import { Stack } from '@ardoq/layout';
import { EmptyStateToAddIllustration } from '@ardoq/status-ui';

const Wrapper = styled(Stack)`
  height: 100%;
  width: 100%;
  padding: ${s64} 0;
  background-color: ${colors.bgSubtle};
`;

const Content = styled(Stack)`
  width: 300px;
  text-align: center;
`;

const MediaContainer = styled.div`
  width: 110px;
`;

const EmptyTable = () => {
  return (
    <Wrapper align="center" justify="center">
      <Content gap="medium" align="center">
        <MediaContainer>
          <EmptyStateToAddIllustration />
        </MediaContainer>
        <Stack gap="small">
          <Header4 color="textDefault">No schedules yet</Header4>
          <Text variant="text2">
            You don’t have any schedules yet. Once created, they’ll appear here
            for tracking and management.
          </Text>
        </Stack>
      </Content>
    </Wrapper>
  );
};

export default EmptyTable;
