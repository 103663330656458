import { Label } from '@ardoq/forms';
import { useState } from 'react';
import { ModalLayout, ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { Select, SelectOption } from '@ardoq/select';
import {
  APIPresentationAssetAttributes,
  APISlideAttributes,
} from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { copySlideFromCurrentPresentation } from 'presentationEditor/actions';

type CopySlidesToPresentationProps = {
  slide: APISlideAttributes;
  presentations: APIPresentationAssetAttributes[];
  resolve: (resultValue: unknown) => void;
};

const CopySlidesToPresentation = ({
  slide,
  presentations,
  resolve,
}: CopySlidesToPresentationProps) => {
  const [selectedPresentation, setSelectedPresentation] =
    useState<SelectOption<string> | null>(null);
  const presentationOptions = presentations.map(presentation => ({
    label: presentation.name,
    value: presentation._id,
  }));

  const handleAddSlidesToPresentation = () => {
    if (!selectedPresentation) return;
    dispatchAction(
      copySlideFromCurrentPresentation({
        slide,
        presentationId: selectedPresentation.value as string,
      })
    );
  };

  return (
    <ModalTemplate
      modalSize={ModalSize.S}
      headerText="Copy this slide to another presentation"
      onCloseButtonClick={() => resolve(false)}
      primaryButtonText="Confirm copy"
      onPrimaryButtonClick={() => {
        handleAddSlidesToPresentation();
        resolve(false);
      }}
      isPrimaryButtonDisabled={!selectedPresentation}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={() => resolve(false)}
    >
      <ModalLayout>
        <Label>Which presentation do you want to copy to?</Label>
        <Select
          options={presentationOptions}
          onChange={option => setSelectedPresentation(option)}
          value={selectedPresentation}
        />
      </ModalLayout>
    </ModalTemplate>
  );
};

export const copySlideToPresentationModal = (
  slide: APISlideAttributes,
  presentations: APIPresentationAssetAttributes[]
) =>
  modal(resolve => (
    <CopySlidesToPresentation
      slide={slide}
      resolve={resolve}
      presentations={presentations}
    />
  ));
