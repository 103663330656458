import { connect } from '@ardoq/rxbeach';
import React, { Fragment } from 'react';
import { from, map, ObservableInput, switchMap } from 'rxjs';
import { MatchedLocation } from './location';
import { RouteComponent, RouteNode } from './routeNode';

const defaultContent = ({ children }: React.PropsWithChildren) => children;

export const resolveRouteContent = (
  activeNodes: RouteNode[]
): Promise<RouteComponent[]> =>
  Promise.all(
    activeNodes.map(({ content }) => (content ? content() : defaultContent))
  );

export const RouteView = ({
  activeNodes,
}: {
  activeNodes: RouteComponent[];
}) => {
  const body = activeNodes.reduceRight<React.ReactNode>((children, content) => {
    // @ts-expect-error React types does not support required children
    return React.createElement(content, {}, children);
  }, null);

  return React.createElement(Fragment, {}, body);
};

export const connectRouter = (
  location$: ObservableInput<MatchedLocation>
): React.ComponentType => {
  const resolvedRoutes$ = from(location$).pipe(
    map(location => location.activeRoutes),
    switchMap(resolveRouteContent),
    map(activeNodes => ({ activeNodes }))
  );

  return connect(RouteView, resolvedRoutes$);
};
