import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId, UseCaseStatus } from '@ardoq/api-types';

const addPrefix = (actionName: string): `[${string}] ${string}` =>
  `[useCases] ${actionName}`;

export const navigateToUseCases = actionCreator(
  addPrefix('navigateToUseCases')
);

export const getUseCases = actionCreator(addPrefix('GET_USE_CASES'));
export const requestLoadMetamodel = actionCreator<{
  id: ArdoqId;
  status: UseCaseStatus;
}>(addPrefix('requestLoadMetamodelPreview'));
