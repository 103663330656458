import { s16, s24, s8 } from '@ardoq/design-tokens';
import { Label } from '@ardoq/forms';
import styled from 'styled-components';
import {
  APIFieldAttributes,
  APIFieldType,
  SearchResultDoc,
  SearchResultResponse,
} from '@ardoq/api-types';
import { formatDateOnly, isDateFieldType } from '@ardoq/date-time';
import { truncate } from '@ardoq/common-helpers';
import { getCurrentLocale } from '@ardoq/locale';

const SubBlock = styled.div`
  padding-top: ${s24};
`;

const PreviewList = styled.ul`
  overflow: auto;
  list-style: none;
  margin: 0;
  margin-top: ${s16};
  padding: ${s16} 0 ${s16} 48px;
  max-height: 240px;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-left-width: ${s8};
  max-width: 520px;
`;

const Li = styled.li`
  line-height: 2.2;
`;

const getIdentifierValue = (value: any, type: APIFieldType) => {
  const locale = getCurrentLocale();

  if (type === APIFieldType.CHECKBOX) {
    return value ? 'Yes' : 'No';
  }
  if (value === null || value === undefined) {
    return 'No value';
  }
  if (isDateFieldType(type)) {
    return formatDateOnly(value, locale);
  }
  return truncate(value, 30);
};

const getPreviewLabel = (
  component: SearchResultDoc & { [x: string]: any },
  additionalIdentifyingField?: APIFieldAttributes
) => {
  if (!additionalIdentifyingField) {
    return component.name;
  }
  const identifierValue = getIdentifierValue(
    component[additionalIdentifyingField.name],
    additionalIdentifyingField.type
  );
  return `${component.name} [${additionalIdentifyingField.label}: ${identifierValue}]`;
};

type PreviewBlockProperties = {
  searchFailed: boolean;
  searchResults?: SearchResultResponse[];
  additionalIdentifyingField?: APIFieldAttributes;
};

const PreviewBlock = ({
  searchFailed,
  searchResults,
  additionalIdentifyingField,
}: PreviewBlockProperties) => {
  return searchFailed ? (
    <SubBlock>
      <Label>
        The query failed. Please make sure that your ruleset is valid.
      </Label>
    </SubBlock>
  ) : searchResults ? (
    <SubBlock>
      <Label>
        Preview of the {searchResults.length} first components found:
      </Label>
      {searchResults.length ? (
        <PreviewList data-test-id="preview-list">
          {searchResults.map(({ doc }) => (
            <Li key={doc._id}>
              {getPreviewLabel(doc, additionalIdentifyingField)}
            </Li>
          ))}
        </PreviewList>
      ) : null}
    </SubBlock>
  ) : null;
};

export default PreviewBlock;
