import {
  createTransferConfig,
  loadTransferConfig,
  saveConfiguration,
  setEmbeddedConfig,
  updateTransferConfig,
} from 'integrations/common/streams/transferConfigs/actions';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { catchError, filter, tap, withLatestFrom } from 'rxjs/operators';
import {
  activeIntegrations$,
  getActiveIntegrationStream,
} from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import {
  getSavableTransferConfig,
  isExcelConfig,
  isSavedTransferConfig,
} from 'integrations/common/streams/transferConfigs/utils';
import { TransferConfig } from '@ardoq/api-types/integrations';
import fp from 'lodash/fp';
import { tablePreviews$ } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { fields$ } from 'integrations/common/streams/fields/fields$';
import { confirm, ModalSize } from '@ardoq/modal';
import {
  setMapTablesBy,
  setTransferConfigId,
} from 'integrations/common/streams/activeIntegrations/actions';
import { applyTabularMapping } from 'integrations/common/streams/tabularMappings/actions';
import { logError } from '@ardoq/logging';
import { transferConfigToTabularMapping } from 'integrations/common/streams/tabularMappings/utils';
import { workspaces$ } from 'integrations/common/streams/workspaces/workspaces$';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { ImportRoute } from 'integrations/common/navigation/types';

const handleSaveIntegrationConfigurations = routine(
  ofType(saveConfiguration),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'excel-v3'),
  withLatestFrom(
    getActiveIntegrationStream('excel-v3'),
    getTabularMappingStream('excel-v3'),
    transferConfigs$
  ),
  tap(
    ([
      { integrationId, name, isNewConfig },
      activeIntegration,
      tabularMapping,
      transferConfigs,
    ]) => {
      const savableConfig: TransferConfig = {
        ...getSavableTransferConfig({
          transferConfigs,
          activeIntegration,
          tabularMapping,
        }),
        name,
      };

      const isCreation =
        isNewConfig && activeIntegration.selectedTransferConfigId;

      if (isSavedTransferConfig(savableConfig) && !isCreation) {
        return dispatchAction(
          updateTransferConfig({
            integrationId,
            config: savableConfig,
          })
        );
      }
      /*
       * saving a new config
       */
      return dispatchAction(
        createTransferConfig({
          integrationId,
          config: fp.omit(
            ['_id', '_version', 'lastUpdated'],
            savableConfig
          ) as TransferConfig,
        })
      );
    }
  )
);

const handleSetEmbeddedConfig = routine(
  ofType(setEmbeddedConfig),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'excel-v3'),
  withLatestFrom(
    activeIntegrations$,
    tablePreviews$,
    fields$,
    workspaces$,
    getTabularMappingStream('excel-v3')
  ),
  tap(
    ([
      { integrationId, config },
      activeIntegrations,
      tablePreviews,
      { all: allFields },
      { existing: allWorkspaces },
      currentTabularMapping,
    ]) => {
      const integrationName = activeIntegrations[integrationId].integrationName;
      const tabularMapping = transferConfigToTabularMapping({
        transferConfig: config,
        tablePreviews: tablePreviews[integrationId],
        allFields,
        allWorkspaces: allWorkspaces,
        mapTablesBy: 'name',
        mapColumnsBy: 'index',
      });

      confirm({
        title: 'Apply new configuration',
        subtitle: `Do you want to apply the embedded configuration in file ${integrationName} ?`,
        modalSize: ModalSize.XS,
        errorMessage: fp.isEmpty(currentTabularMapping)
          ? undefined
          : 'The current configuration will be overwritten.',
        confirmButtonTitle: 'Apply',
        cancelButtonTitle: 'Cancel',
        onConfirmAsync: () => {
          dispatchAction(
            setMapTablesBy({
              integrationId: 'excel-v3',
              mapTablesBy: 'name',
            })
          );
          dispatchAction(
            applyTabularMapping({ integrationId, mapping: tabularMapping })
          );
        },
      });
    }
  ),
  catchError((error, stream$) => {
    logError(error, 'Invalid embedded config', {
      tags: {
        team: 'integrations',
      },
    });
    return stream$;
  })
);

const handleLoadTransferConfig = routine(
  ofType(loadTransferConfig),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'excel-v3'),
  tap(({ integrationId, transferConfig: config }) => {
    if (!isExcelConfig(config)) {
      return;
    }

    dispatchAction(setTransferConfigId({ integrationId, id: config._id }));
    dispatchAction(
      navigateToPath({ integrationId, path: ImportRoute.SELECT_DATA })
    );
  })
);

export default [
  handleSaveIntegrationConfigurations,
  handleSetEmbeddedConfig,
  handleLoadTransferConfig,
];
