import { APIEntityType } from '@ardoq/api-types';
import { Branch } from 'components/DiffMergeTable/Branch';
import { EnhancedDiffContextData } from 'components/DiffMergeTable/EnhancedDiffContextDataType';
import { getEntityById } from '@ardoq/renderers';

export const getBranchWhereEntityExists = (
  entityType: APIEntityType,
  entityId: string,
  enhancedDiffScopeData: EnhancedDiffContextData,
  branch: Branch,
  parentEntityId?: string
) => {
  if (
    getEntityById(
      entityType,
      entityId,
      enhancedDiffScopeData[branch],
      parentEntityId
    )
  ) {
    return branch;
  } else if (
    getEntityById(
      entityType,
      entityId,
      enhancedDiffScopeData[Branch.BRANCH_OFF],
      parentEntityId
    )
  ) {
    return Branch.BRANCH_OFF;
  }
  const oppositeBranch =
    branch === Branch.SOURCE ? Branch.TARGET : Branch.SOURCE;
  if (
    getEntityById(
      entityType,
      entityId,
      enhancedDiffScopeData[oppositeBranch],
      parentEntityId
    )
  ) {
    return oppositeBranch;
  }
  return null;
};
