import {
  openHomeWithFilter,
  openProductboardPortal,
  rejectedNavigation,
  requestShowAppModule,
  showAppModule,
} from './actions';
import {
  collectRoutines,
  routine,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AppModules } from './types';
import { from } from 'rxjs';
import { closeScenario } from 'scope/actions';
import selectedModule$ from './selectedModule$';
import { maybeShowConfirmCloseScenario } from './maybeShowConfirmCloseScenario';
import { openHome } from '../components/AppMainSidebar/utils';
import { showOnlySpecificAssetTypes } from './DashboardContainer/expandedFolders$';
import { maybeConfirmDiscard } from 'streams/unsavedState/modal';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { getAssetFilter } from '../components/AssetsBrowser2024/FilterBar/filters';
import {
  clearFilter,
  setFilterActive,
} from '../components/AssetsBrowser2024/assetsBrowser2024Actions';
import { ssoApi } from '@ardoq/api';

const handleOpenProductboardPortal = routine(
  ofType(openProductboardPortal),
  switchMap(ssoApi.fetchProductPortalToken),
  tap(token => {
    const tab = window.open(
      `https://portal.productboard.com/cjkps8hu4ybuwz6h9kzrumxp?token=${token}`,
      '_blank'
    );
    tab?.focus();
  })
);

const isNavigatingAwayFromScenarioMode = (
  isScenarioMode: boolean,
  currentSelectedModule: AppModules,
  selectedModule: AppModules
) => {
  return (
    isScenarioMode &&
    currentSelectedModule === AppModules.WORKSPACES &&
    selectedModule !== AppModules.WORKSPACES
  );
};

const handleRequestShowAppModule = routine(
  ofType(requestShowAppModule),
  extractPayload(),
  withLatestFrom(selectedModule$, activeScenario$),
  switchMap(
    ([data, { selectedModule: currentSelectedModule }, { isScenarioMode }]) => {
      if (
        isNavigatingAwayFromScenarioMode(
          isScenarioMode,
          currentSelectedModule,
          data.selectedModule
        )
      ) {
        return from(maybeShowConfirmCloseScenario()).pipe(
          take(1),
          tap(confirmed => {
            if (confirmed) dispatchAction(closeScenario());
          }),
          map(confirmed => ({
            ...data,
            confirmed,
          }))
        );
      }
      return from(maybeConfirmDiscard()).pipe(
        map(confirmed => ({
          ...data,
          confirmed,
        }))
      );
    }
  ),
  tap(({ confirmed, ...payload }) => {
    if (!confirmed) {
      dispatchAction(rejectedNavigation(payload));
    } else {
      dispatchAction(showAppModule(payload));
    }
  })
);

const handleOpenHomeWithFilter = routine(
  ofType(openHomeWithFilter),
  extractPayload(),
  tap(assetTypes => {
    openHome();
    dispatchAction(
      clearFilter({ payload: undefined, appModule: AppModules.HOME })
    );
    assetTypes.map(assetType =>
      dispatchAction(
        setFilterActive({
          payload: getAssetFilter(assetType),
          appModule: AppModules.HOME,
        })
      )
    );
    dispatchAction(showOnlySpecificAssetTypes(assetTypes));
  })
);

export const appContainerRoutines = collectRoutines(
  handleOpenProductboardPortal,
  handleRequestShowAppModule,
  handleOpenHomeWithFilter
);
