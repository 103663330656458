import currentUser from 'models/currentUser';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { getCurrentLocale, localeCompareBase } from '@ardoq/locale';
import {
  allIntegrations,
  getIntegrationDisabledOptions,
} from './allIntegrations';
import { Integration, RootPages } from './types';
import IntegrationView from './integrationView';
import integrationViewState$ from './integrationViewState$';
import { Feature, Features, hasFeature } from '@ardoq/features';
import { map, withLatestFrom } from 'rxjs';
import Navbar from 'views/navbar/Navbar';
import MainToolbar from 'menus/topbar/MainToolbar';
import currentUser$ from 'streams/currentUser/currentUser$';
import { History } from './dashboard/pages/history';
import { setVisibleIntegration } from './actions';
import { Dashboard } from './dashboard/pages/dashboard';

const featureEnabled = (feature: Feature, isEnabledWhenUnset: boolean) => {
  // If an integration isEnabledWhenFeaturesUnset, then it is also considered
  // active when the feature toggle/s have never been touched.
  const savedState = currentUser.get('features');
  const neverBeenSet =
    feature.persistedId && savedState[feature.persistedId] === undefined;
  if (isEnabledWhenUnset && neverBeenSet) {
    return true;
  }
  return hasFeature(feature);
};

const requiredFeaturesActive = (integration: Integration) => {
  if (!integration.requiredFeatures?.length) {
    return true;
  }
  return integration.requiredFeatures.every(feature =>
    featureEnabled(feature, !!integration.isEnabledWhenFeaturesUnset)
  );
};

function GlobalNavigationContainer({
  enabled,
  children,
}: {
  enabled: boolean;
  children: React.ReactNode;
}) {
  return (
    <>
      {enabled && (
        <Navbar
          primaryContent="Import and integrations"
          secondaryContent="Overview"
          toolbarContent={<MainToolbar shouldUseNewJourneyVersion />}
        />
      )}
      {children}
    </>
  );
}

function isSelectedIntegrationTS(val: any): boolean {
  if (!val) {
    return false;
  }
  return 'typescriptImporter' in val;
}

const Integrations = ({
  selectedIntegrationId,
  hasNewJourneyFeature,
  activeIntegrations,
  path,
}: {
  selectedIntegrationId: string | null;
  hasNewJourneyFeature: boolean;
  activeIntegrations: Integration[];
  path: string;
}) => {
  if (path === RootPages.DASHBOARD) {
    return <Dashboard integrations={activeIntegrations} />;
  }
  if (path === RootPages.HISTORY) {
    return (
      <History
        integrations={activeIntegrations}
        goBack={() => {
          dispatchAction(
            setVisibleIntegration({ id: null, path: RootPages.DASHBOARD })
          );
        }}
      />
    );
  }
  const selectedIntegration =
    selectedIntegrationId &&
    activeIntegrations.find(({ id }) => id === selectedIntegrationId);

  return selectedIntegration ? (
    <GlobalNavigationContainer
      enabled={
        hasNewJourneyFeature && !isSelectedIntegrationTS(selectedIntegration)
      }
    >
      <IntegrationView integration={selectedIntegration} />
    </GlobalNavigationContainer>
  ) : (
    <Dashboard integrations={activeIntegrations} />
  );
};

export default connect(
  Integrations,
  integrationViewState$.pipe(
    withLatestFrom(currentUser$),
    map(([{ selectedIntegrationId, integrationPath }, currentUser]) => {
      const locale = getCurrentLocale();

      return {
        selectedIntegrationId,
        hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
        path: integrationPath || '',
        activeIntegrations: allIntegrations
          .filter(requiredFeaturesActive)
          .map(integration => ({
            ...integration,
            disableOptions: getIntegrationDisabledOptions(
              integration,
              currentUser
            ),
          }))
          .sort(
            (a, b) =>
              Number(Boolean(a.disableOptions?.isDisabled)) -
                Number(Boolean(b.disableOptions?.isDisabled)) ||
              localeCompareBase(a.name, b.name, locale)
          ),
      };
    })
  )
);
