import DeletedComponent from './DeletedComponent';
import ChevronDecorator from './ChevronDecorator';
import AqCollapsible from '../../atomicComponents/AqCollapsible/AqCollapsible';
import DeletedDescendants from './DeletedDescendants';
import { ClickableRow } from './atoms';
import { DeletedComponentProperties } from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';

const DeletedComponentWithCollapsible = ({
  component,
}: DeletedComponentProperties) => {
  return (
    <AqCollapsible
      defaultValue={false}
      renderTarget={({ toggle, isExpanded }) => (
        <ClickableRow DEPRECATED_onClick={toggle}>
          <ChevronDecorator isExpanded={isExpanded}>
            <DeletedComponent component={component} />
          </ChevronDecorator>
        </ClickableRow>
      )}
      renderContent={() => (
        <DeletedDescendants
          descendants={component.descendantNames.filter(ExcludeFalsy)}
        />
      )}
    />
  );
};

export default DeletedComponentWithCollapsible;
