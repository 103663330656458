import { dispatchAction } from '@ardoq/rxbeach';
import {
  setSelectedUsers,
  upsertUsers,
} from 'integrations/dashboard/streams/scheduleFailureNotification/actions';
import { scheduleFailureNotification$ } from 'integrations/dashboard/streams/scheduleFailureNotification/scheduleFailureNotification$';
import { map } from 'rxjs';

export const viewModel$ = scheduleFailureNotification$.pipe(
  map(sfnState => {
    const onConfirmClicked = async () => {
      dispatchAction(upsertUsers());
    };
    const onSelectedUsers = (users: string[]) => {
      dispatchAction(setSelectedUsers(users));
    };
    return {
      ...sfnState,
      onConfirmClicked,
      onSelectedUsers,
    };
  })
);
