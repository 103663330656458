import { ConfigurationState } from 'integrations/signavio-exporter/streams/types';

// Todo: Implement isDataSelectionValid function
export function isDataSelectionValid(config: ConfigurationState) {
  return (
    Boolean(config.workspace.id) &&
    Boolean(config.accountId) &&
    Object.values(config.componentTypes).length > 0 &&
    Object.values(config.componentTypes).every(ct => ct.isQueryValid)
  );
}
