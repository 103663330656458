import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { fetchAssetUsage, setAssetUsage, setAssetUsageStatus } from './actions';
import {
  filter,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { assetApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { setVisibleAsset } from 'assets/navigation/actions';
import { assetsUsage$ } from './usage$';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';

const handleSetVisibleAsset = routine(
  ofType(setVisibleAsset),
  extractPayload(),
  withLatestFrom(assetsUsage$),
  filter(
    ([{ assetId }, assetsUsage]) => assetsUsage[assetId]?.status !== 'loading'
  ),
  tap(([{ assetType, assetId }]) => {
    dispatchAction(fetchAssetUsage({ type: assetType, id: assetId }));
  })
);

const handleFetchAssetUsage = routine(
  ofType(fetchAssetUsage),
  extractPayload(),
  tap(({ id }) => {
    dispatchAction(setAssetUsageStatus({ id, status: 'loading' }));
  }),
  switchMap(({ id, type }) =>
    forkJoin({
      usage: from(assetApi.assetUsage({ id, type })).pipe(
        map(response => {
          if (isArdoqError(response)) {
            dispatchAction(setAssetUsageStatus({ id, status: 'error' }));
            return [];
          }
          return response;
        })
      ),
      id: of(id),
    })
  ),
  tap(({ usage, id }) => {
    dispatchAction(setAssetUsage({ id, usage }));
  })
);

const handleOpenAssets = routine(
  ofType(setVisibleAsset),
  extractPayload(),
  tap(() => {
    dispatchAction(requestShowAppModule({ selectedModule: AppModules.ASSETS }));
  })
);

export default [handleSetVisibleAsset, handleFetchAssetUsage, handleOpenAssets];
