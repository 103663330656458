import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const editReference = actionCreator<ArdoqId>(
  '[reference] REFERENCE_OPTIONS_EDIT_REFERENCE'
);

export const selectReference = actionCreator<ArdoqId>(
  '[reference] REFERENCE_OPTIONS_SELECT_REFERENCE'
);
