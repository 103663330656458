import { ErrorBoundary } from '@ardoq/error-boundary';
import { AqLayout } from '@ardoq/layout';
import ModuleContainer from 'appContainer/ModuleContainer';
import { logError } from '@ardoq/logging';
import DiffMergeTable from 'components/DiffMergeTable/DiffMergeTable';
import styled from 'styled-components';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { applyMerge, skipStep } from './actions';
import mergeState$ from './mergeState$';
import { DisabledReason, MergeState } from './types';
import DiffMergeSidebarNavigator, {
  getDisabledSubStepPopover,
} from 'components/DiffMergeSidebarNavigator/DiffMergeSidebarNavigator';
import buttonText$ from './buttonText$';
import { DontShowAgain } from 'models/dontShowAgainSettings';
import { showDialogWithRememberAction } from 'utils/showDialogWithRememberAction';
import { getHasRemainingChanges, isLastUnsubmittedStep } from './utils';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { MergeStepLabel } from 'scope/merge/typesMergeStepLabel';
import { Verb } from '@ardoq/api-types';
import { getPropagatedReason } from 'scope/merge/utils';
import { DoqType } from '@ardoq/doq';
import ScenarioMergeIntercomTourClickIds from 'components/DiffMergeTable/intercomTourClickIds';
import { combineLatest } from 'rxjs';
import { globalPaneLoader$ } from 'components/GlobalPaneLoader/globalPaneLoader$';
import { map } from 'rxjs/operators';
import { ButtonGroup, PrimaryButton, SecondaryButton } from '@ardoq/button';
import { colors, s16, s8 } from '@ardoq/design-tokens';

export const Footer = styled(ButtonGroup)`
  box-sizing: border-box;
  padding: ${s8} ${s16};
  justify-content: flex-end;
  display: flex;
  background-color: ${colors.grey80};
  width: 100%;
  height: 56px;
`;

const CustomSecondaryButton = styled(SecondaryButton)`
  &:hover {
    background-color: ${colors.grey95};
  }
`;

type SubmitButtonProps = {
  buttonText: string;
  loading: boolean;
  isDisabled: boolean;
};

export const MERGE_NAVIGATOR_MAX_WIDTH = '250px';

const SubmitButton = ({
  loading,
  buttonText,
  isDisabled,
}: SubmitButtonProps) => {
  if (!buttonText) return null;
  return (
    <PrimaryButton
      isDisabled={loading || isDisabled}
      onClick={async () => {
        const isConfirmed = await showDialogWithRememberAction({
          dontShowAgainSettingKey: DontShowAgain.APPLY_MERGE_INSTANTLY_DIALOG,
          title: 'Apply changes',
          subtitle: 'Apply changes immediately',
          doqType: DoqType.ALARM,
          text: [
            'The changes are merged immediately after each step in the merge flow.',
            'When applying the changes, this cannot be undone.',
          ],
          confirmButtonTitle: 'Apply',
        });

        if (isConfirmed) dispatchAction(applyMerge());
      }}
      data-click-id={ScenarioMergeIntercomTourClickIds.APPLY_BUTTON}
    >
      {buttonText}
    </PrimaryButton>
  );
};

const MAIN_STATE_STEPS_WITH_UNSKIPPABLE_SUB_STEPS = new Set([
  MergeStepLabel.COMPONENT_TYPES,
  MergeStepLabel.REFERENCE_TYPES,
  MergeStepLabel.FIELDS,
  null,
]);

const SkipButton = ({
  mergeDirection,
  mainStateStep,
  subStateStep,
  submittedSteps,
  diffMetaStates,
}: MergeState) => {
  if (!mainStateStep || !subStateStep) return null; // pleasing typescript
  const isDisabled =
    mergeDirection === MergeDirection.MAINLINE_TO_BRANCH &&
    subStateStep === Verb.CREATE &&
    MAIN_STATE_STEPS_WITH_UNSKIPPABLE_SUB_STEPS.has(mainStateStep);
  return (
    <CustomSecondaryButton
      onClick={() => {
        dispatchAction(skipStep({ mainStateStep, subStateStep }));
      }}
      data-click-id="skip-merge-step-footer"
      {...getDisabledSubStepPopover(
        getPropagatedReason(
          DisabledReason.NONE,
          mergeDirection,
          mainStateStep,
          subStateStep,
          1
        )
      )}
      isDisabled={isDisabled}
    >
      {isLastUnsubmittedStep(
        mainStateStep,
        subStateStep,
        submittedSteps,
        diffMetaStates
      )
        ? 'Exit'
        : 'Skip step'}
    </CustomSecondaryButton>
  );
};
const SubmitButtonConnected = connect(SubmitButton, buttonText$);

const ScenarioMergeContainer = (props: MergeState & { isLoading: boolean }) => {
  const hasRemainingChanges = getHasRemainingChanges(
    props.diffMetaStates,
    props.submittedSteps
  );
  const nothingToMerge =
    !props.isLoading && !hasRemainingChanges && !window.STORYBOOK_ENV;
  return (
    <ModuleContainer $overflow="hidden">
      <ErrorBoundary logError={logError}>
        <AqLayout
          fullWidth
          bodyContentStyle={{
            paddingTop: '32px',
            padding: '0px',
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            background: colors.white,
          }}
          bodyWrapperStyle={{ backgroundColor: colors.grey35 }}
          leftSidebarWrapperStyle={{
            backgroundColor: colors.grey35,
            maxWidth: MERGE_NAVIGATOR_MAX_WIDTH,
          }}
          renderLeftSidebar={() => <DiffMergeSidebarNavigator {...props} />}
        >
          <DiffMergeTable nothingToMerge={nothingToMerge} />
          {!nothingToMerge && (
            <Footer>
              <SkipButton {...props} />
              <SubmitButtonConnected loading={props.isLoading} />
            </Footer>
          )}
        </AqLayout>
      </ErrorBoundary>
    </ModuleContainer>
  );
};

export default connect(
  ScenarioMergeContainer,
  combineLatest([mergeState$, globalPaneLoader$]).pipe(
    map(([mergeState, { isLoading }]) => ({ ...mergeState, isLoading }))
  )
);
