import { LoadMetaModelAsScopedDataState } from './loadMetaModelTypes';
import { enhancedScopeDataOperations } from 'viewpointBuilder/enhancedScopeData/enhancedScopeDataOperations';
import { getId } from 'viewpointBuilder/getId';
import { GraphEdge, GraphEdgeWithMetaData, GraphNode } from '@ardoq/graph';
import { Field } from 'streams/fields/fields$';
import { StreamCollection } from 'streams/utils/streamUtils';
import { MetaModelLoadedState } from 'architectureModel/loadMetaModel';
import { metaModelOperations } from 'architectureModel/metaModelOperations';
import { Workspace } from '@ardoq/api-types';
import {
  ToggleIsSelectedGraphNodePayload,
  ToggleExpandableSectionPayload,
} from './actions';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { MetamodelViewState } from './types';
import { fieldOps } from '../../models/utils/fieldOps';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';

const getInitialState = (): MetamodelViewState => ({
  rawGraphEdges: new Map(),
  rawGraphNodes: new Map(),
  graphNodes: new Map(),
  graphEdges: new Map(),
  graphGroups: new Map(),
  selectedGraphNodeId: null,
  selectedComponentType: null,
  typeNameToWorkspaceMap: new Map(),
  componentTypeNameToFieldMap: new Map(),
  referenceTypeNameToFieldMap: new Map(),
  fieldMap: new Map(),
  expandedSection: {
    workspaces: true,
    fields: true,
  },
  workspaceMap: new Map(),
  selectedFields: null,
  selectedWorkspaces: null,
  shouldFitToContentOnLayout: true,
  isMetaModelLoaded: false,
});

const createMetamodel = (
  state: MetamodelViewState,
  loadMetaModelAsScopedDataState: LoadMetaModelAsScopedDataState
): MetamodelViewState => {
  if (loadMetaModelAsScopedDataState.status !== 'DATA_LOADED') return state;

  const { data } = loadMetaModelAsScopedDataState;

  const rawGraphNodes = enhancedScopeDataOperations.createRawGraphNodes(data);
  const rawGraphEdges = enhancedScopeDataOperations.createRawGraphEdges(data);

  const graphNodes = new Map<string, GraphNode>(
    Array.from(rawGraphNodes).map(([id, { modelId, metaData }]) => {
      // In the metamodel view each type should just appear once, so we can use
      // the rawGraphNodes id as the graphNode id.
      const graphNode = GraphNode.createWithMetaData(
        modelId,
        false,
        id,
        metaData
      );
      return [id, graphNode];
    })
  );

  const graphEdges = new Map<string, GraphEdgeWithMetaData>(
    Array.from(rawGraphEdges).map(
      ([_, { modelId, sourceId, targetId, metaData }]) => {
        const graphEdge = GraphEdge.createWithMetaData(
          modelId,
          sourceId,
          targetId,
          graphNodes,
          getId(),
          metaData
        );
        return [graphEdge.id, graphEdge];
      }
    )
  );

  return {
    ...state,
    rawGraphNodes,
    rawGraphEdges,
    graphNodes,
    graphEdges,
    isMetaModelLoaded: true,
  };
};

const createFieldMap = <State>(
  state: State,
  { byId }: StreamCollection<Field>
): State => {
  const fieldMap = new Map(
    Object.values(byId).map(field => {
      return [field.name, field];
    })
  );

  return {
    ...state,
    fieldMap,
  };
};

const createWorkspaceMap = (
  state: MetamodelViewState,
  { byId }: StreamCollection<Workspace>
): MetamodelViewState => {
  const workspaceMap = new Map(
    Object.values(byId).map(({ _id, name }) => [_id, name])
  );

  return {
    ...state,
    workspaceMap,
  };
};

const typeNameToWorkspaceAndFieldMaps = (
  state: MetamodelViewState,
  metaModelResult: MetaModelLoadedState
): MetamodelViewState => {
  if (isArdoqError(metaModelResult)) {
    return state;
  }
  return {
    ...state,
    ...metaModelOperations.typeNameToWorkspaceAndFieldMaps(metaModelResult),
  };
};

const toggleIsSelectedGraphNode = (
  state: MetamodelViewState,
  { graphNodeId }: ToggleIsSelectedGraphNodePayload
) => {
  const newGraphNodes = new Map(state.graphNodes);
  Array.from(newGraphNodes.values()).forEach(graphNode => {
    graphNode.setIsSelected(false);
  });

  const isNewNodeSelected = Boolean(
    graphNodeId && graphNodeId !== state.selectedGraphNodeId
  );
  if (isNewNodeSelected) {
    newGraphNodes.get(graphNodeId)!.setIsSelected(true);
  }

  const selectedComponentType = isNewNodeSelected
    ? state.rawGraphNodes.get(graphNodeId)!.metaData
    : null;

  return {
    ...state,
    graphNodes: newGraphNodes,
    selectedGraphNodeId: isNewNodeSelected ? graphNodeId : null,
    selectedComponentType,
    selectedFields: isNewNodeSelected
      ? getSelectedFields(state, graphNodeId)
      : null,
    selectedWorkspaces: isNewNodeSelected
      ? getSelectedWorkspaces(state, graphNodeId)
      : null,
    shouldFitToContentOnLayout: false,
  };
};

const START_DATE_FIELD_NAME_SUFFIX = '_start_date';
const END_DATE_FIELD_NAME_SUFFIX = '_end_date';

const getSelectedFields = (
  { componentTypeNameToFieldMap, fieldMap, graphNodes }: MetamodelViewState,
  graphNodeId: string
) => {
  const locale = getCurrentLocale();
  const name = graphNodes.get(graphNodeId)?.getLabel() ?? '';
  const fields = componentTypeNameToFieldMap.get(name) ?? [];

  const startDateCandidates = new Set(
    fields
      .filter(fieldName => fieldName.endsWith(END_DATE_FIELD_NAME_SUFFIX))
      .map(fieldName =>
        fieldName.replace(
          END_DATE_FIELD_NAME_SUFFIX,
          START_DATE_FIELD_NAME_SUFFIX
        )
      )
  );

  const dateRangeFields = fields.filter(fieldName =>
    startDateCandidates.has(fieldName)
  );
  const endDates = new Set(
    dateRangeFields.map(fieldName =>
      fieldName.replace(
        START_DATE_FIELD_NAME_SUFFIX,
        END_DATE_FIELD_NAME_SUFFIX
      )
    )
  );
  const dateRangeFieldsSet = new Set(dateRangeFields);
  return fields
    .filter(fieldName => !endDates.has(fieldName))
    .map(fieldName => {
      const isDateRangeField = dateRangeFieldsSet.has(fieldName);
      const field = fieldMap.get(fieldName);
      if (!field) return null;
      const { label } = field;
      const isCalculatedField = fieldOps.isCalculatedField(field);
      const type = isDateRangeField ? 'Date Range' : field.type;
      return {
        label: isDateRangeField ? label.replace('.Start', '') : label,
        type: isCalculatedField ? `${type} (Calculated Field)` : type,
      };
    })
    .filter(ExcludeFalsy)
    .sort((a, b) => localeCompare(a.label, b.label, locale));
};

const getSelectedWorkspaces = (
  { typeNameToWorkspaceMap, workspaceMap, graphNodes }: MetamodelViewState,
  graphNodeId: string
) => {
  const locale = getCurrentLocale();
  const name = graphNodes.get(graphNodeId)?.getLabel() ?? '';
  const workspaces = typeNameToWorkspaceMap.get(name) ?? [];

  return workspaces
    .map(workspaceId => workspaceMap.get(workspaceId) ?? null)
    .filter(ExcludeFalsy)
    .sort((a, b) => localeCompare(a, b, locale));
};

const toggleExpandableSection = (
  state: MetamodelViewState,
  { expandableId }: ToggleExpandableSectionPayload
) => {
  return {
    ...state,
    expandedSection: {
      ...state.expandedSection,
      [expandableId]: !state.expandedSection[expandableId],
    },
  };
};

export const metamodelViewOperations = {
  getInitialState,
  createMetamodel,
  createFieldMap,
  typeNameToWorkspaceAndFieldMaps,
  createWorkspaceMap,
  toggleIsSelectedGraphNode,
  toggleExpandableSection,
};
