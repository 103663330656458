import { MaybePersistedSurvey, SurveyValidation } from 'surveyAdmin/types';
import { Field, FieldsWrapper, Switch, TextInput } from '@ardoq/forms';
import { SmallTertiaryButton } from '@ardoq/button';
import { colors, s8 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { ArdoqId, isPersistedSurvey } from '@ardoq/api-types';
import { text1 } from '@ardoq/typography';
import surveyOrgAndUserDetails$ from './surveyOrgAndUserDetails$';
import { openNotificationsRecipientModal } from 'surveyAdmin/SurveyModals';
import { Select, createFilter } from '@ardoq/select';
import { getTimeZoneOptions, getUserTimeZone } from './timezoneHelpers';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { shouldDisplayErrorMessageForField } from 'surveyAdmin/surveyUtil';
import surveyEditor$ from './streams/surveyEditor$';
import { combineLatest, map } from 'rxjs';
import { setSurveyFieldHasBeenInteractedWith } from './streams/actions';
import { DefaultNotification } from '@ardoq/status-ui';
import { doesQuestionHaveDateField } from './utils';
import { Stack } from '@ardoq/layout';
import { trackEvent } from 'tracking/tracking';
import { usersOperations } from '@ardoq/core';
import { SurveyEditorSectionProps } from './SurveyEditor';
import { isConditionalQuestion } from 'surveyAdmin/questions/questionPredicates';
import { SurveyErrorName } from 'surveyAdmin/consts';
import { RichTextEditorTransition } from '@ardoq/rich-text-editor-2024';
import { validationHelpers } from 'surveyAdmin/surveyValidation';
import { OrgUsersStreamShape } from 'streams/orgUsers/types';

const Recipients = styled.ul`
  color: ${colors.grey60};
  padding: ${s8} 0 0 0;
  ${text1}
  max-width: 476px;
`;

const Count = styled.div`
  font-weight: 600;
`;

const Recipient = styled.li`
  list-style-type: none;
`;

const ModifyRecipientsButton = styled(SmallTertiaryButton)`
  align-self: flex-start;
`;

type OwnProps = SurveyEditorSectionProps & {
  surveyValidation: SurveyValidation | undefined;
  survey: MaybePersistedSurvey;
  shouldShowSurveyNameFieldErrorIfPresent: boolean;
  currentUserId: ArdoqId;
  currentUserEmail: string | null;
  users: OrgUsersStreamShape['users'];
};

const SurveyEditorDetailsSection = ({
  setSurveyAttributes,
  surveyValidation,
  surveyAttributes,
  survey,
  users,
  currentUserId,
  currentUserEmail,
  shouldShowSurveyNameFieldErrorIfPresent,
}: OwnProps) => {
  const {
    name,
    contactEmail,
    description,
    questions,
    surveyNotificationsEnabled,
    surveyNotificationsAudience,
    surveyNotificationsTimezone,
  } = surveyAttributes;

  const surveyHasDateField = questions.some(question => {
    if (isConditionalQuestion(question)) {
      // Reference questions are not supported in survey notifications

      return question.properties.questions?.some(dependentQuestion =>
        doesQuestionHaveDateField(dependentQuestion, surveyAttributes)
      );
    }
    return doesQuestionHaveDateField(question, surveyAttributes);
  });

  const admins = usersOperations.getOrgAdmins(users);

  const firstFiveAudienceMembers = surveyNotificationsAudience
    ?.sort(
      (a, b) =>
        Number(Boolean(b === currentUserId)) -
        Number(Boolean(a === currentUserId))
    )
    .slice(0, 5);

  const remainingLengthOfAudience =
    (surveyNotificationsAudience?.length || 0) - 5;
  const contactEmailPlaceholder = isPersistedSurvey(survey)
    ? survey.createdByEmail
    : (currentUserEmail ?? undefined);

  return (
    <>
      <FieldsWrapper>
        <TextInput
          label="Survey Name"
          onBlur={() =>
            dispatchAction(
              setSurveyFieldHasBeenInteractedWith({
                fieldKey: 'generalInformationSectionNameField',
              })
            )
          }
          value={name}
          onChange={event => setSurveyAttributes({ name: event.target.value })}
          helperText={'Required'}
          errorMessage={
            shouldShowSurveyNameFieldErrorIfPresent
              ? validationHelpers.getErrorMessageIfExists(
                  surveyValidation,
                  SurveyErrorName.NAME_INVALID
                )
              : undefined
          }
          dataTestId="name-input"
          popoverHelpContent={`Name your survey something understandable and familiar to your
            organization.`}
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <TextInput
          label="Contact email address"
          value={contactEmail}
          placeholder={contactEmailPlaceholder}
          onChange={event =>
            setSurveyAttributes({ contactEmail: event.target.value })
          }
          errorMessage={validationHelpers.getErrorMessageIfExists(
            surveyValidation,
            SurveyErrorName.CONTACT_EMAIL_INVALID
          )}
          dataTestId="email-input"
          popoverHelpContent={`The contact email should be the person in your organization that
            can help your colleagues fill out your survey. Per default it is
            the survey creator.`}
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <RichTextEditorTransition
          label="Description"
          popoverHelpContent={`This is where you are telling people why they are replying. The
              more relevant context you give the higher likelihood you will
              get meaningful contributions. You can add help text to
              individual questions later.`}
          content={description}
          onInputChange={description => setSurveyAttributes({ description })}
          dataTestId="text-section-input"
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <Stack gap="small">
          <Field
            label="Get notified about the responses"
            popoverHelpContent={
              <>
                <p>
                  Stay up to date by getting email notifications of new
                  responses to your survey.
                </p>
                <img
                  alt="image showing example digest"
                  src="/img/surveys/survey_digest.png"
                />
              </>
            }
          />
          <Switch
            name="surveyNotifications"
            label="A daily change summary will be sent to the following emails:"
            isChecked={surveyNotificationsEnabled ?? false}
            onChange={newValue => {
              trackEvent('Survey builder: toggled survey digest notification', {
                toggled: newValue,
              });
              const user = admins.find(
                admin => admin.email === (contactEmail || currentUserEmail)
              );
              const newAttributes = {
                surveyNotificationsEnabled: newValue,
                surveyNotificationsTimezone:
                  surveyNotificationsTimezone || getUserTimeZone(),
                surveyNotificationsAudience: newValue && user ? [user._id] : [],
              };
              setSurveyAttributes(newAttributes);
            }}
          />
        </Stack>
        {surveyNotificationsEnabled && (
          <>
            <Recipients>
              {surveyNotificationsAudience?.length &&
              firstFiveAudienceMembers?.length ? (
                <>
                  {firstFiveAudienceMembers.map((recipient, i) => {
                    const user = admins.find(admin => admin._id === recipient);
                    return (
                      <Recipient key={i}>
                        {user?.email}
                        {currentUserId === recipient ? ' (me)' : ''}
                      </Recipient>
                    );
                  })}
                  {surveyNotificationsAudience?.length > 5 ? (
                    <>
                      <div>...</div>
                      <Count>
                        and {remainingLengthOfAudience} more
                        {remainingLengthOfAudience > 1
                          ? ' recievers'
                          : ' reciever'}
                      </Count>
                    </>
                  ) : null}
                </>
              ) : (
                <DefaultNotification>
                  No receivers selected.
                </DefaultNotification>
              )}
            </Recipients>

            <ModifyRecipientsButton
              onClick={async () => {
                const updatedAudience = await openNotificationsRecipientModal(
                  admins,
                  surveyNotificationsAudience
                );
                if (updatedAudience)
                  setSurveyAttributes({
                    surveyNotificationsAudience: updatedAudience,
                  });
              }}
              dataTestId="survey-digest-audience-button"
            >
              Modify receivers
            </ModifyRecipientsButton>
            {surveyHasDateField ? (
              <Select
                dataTestId="survey-digest-timezone-select"
                label="Select timezone for the date and time changes in the change summary"
                helperText="Date and time changes will be displayed in this timezone if the change summary contains date fields."
                value={surveyNotificationsTimezone}
                onValueChange={value => {
                  if (value) {
                    setSurveyAttributes({ surveyNotificationsTimezone: value });
                  }
                }}
                filterOption={createFilter({
                  stringify(option) {
                    return option.label + option.data.description;
                  },
                })}
                options={getTimeZoneOptions()}
              />
            ) : null}
          </>
        )}
      </FieldsWrapper>
    </>
  );
};

export default connect(
  SurveyEditorDetailsSection,
  combineLatest([surveyOrgAndUserDetails$, surveyEditor$]).pipe(
    map(
      ([
        { currentUserEmail, currentUserId, users },
        { fieldHasBeenInteractedWith, surveyAttributes },
      ]) => ({
        currentUserEmail,
        currentUserId,
        users,
        shouldShowSurveyNameFieldErrorIfPresent:
          shouldDisplayErrorMessageForField(
            isPersistedSurvey(surveyAttributes),
            surveyAttributes.misc.generalInformationSectionStatus || 'initial',
            fieldHasBeenInteractedWith.generalInformationSectionNameField
          ),
      })
    )
  )
);
