import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { map, Observable } from 'rxjs';
import type { IntegrationId } from '../tabularMappings/types';
import { IntegrationJobPayload, IntegrationSchedule } from '@ardoq/api-types';
import {
  removeScheduleFromSchedulesList,
  setSchedulesAsyncStatus,
  updateSchedulesList,
} from './actions';
import { AsyncStatus } from 'integrations/common/types/api';
import { filterSchedulesByIntegrationId } from './utils';

export type SchedulesState<Job = IntegrationSchedule> = {
  asyncStatus: AsyncStatus;
  schedules: Job[];
};

const updateSchedulesAsyncStatus = (
  schedulesState: SchedulesState,
  asyncStatus: AsyncStatus
) => ({
  ...schedulesState,
  asyncStatus,
});

const setSchedulesList = (
  schedulesState: SchedulesState,
  schedules: IntegrationSchedule[]
): SchedulesState => ({
  asyncStatus: 'SUCCESS',
  schedules,
});

const removeSchedule = (
  schedulesState: SchedulesState,
  { _id }: IntegrationJobPayload
): SchedulesState => ({
  asyncStatus: 'SUCCESS',
  schedules: schedulesState.schedules.filter(schedule => schedule._id !== _id),
});

const handleSetSchedulesAsyncStatus = reducer<SchedulesState, AsyncStatus>(
  setSchedulesAsyncStatus,
  updateSchedulesAsyncStatus
);

const handleUpdateSchedulesList = reducer<
  SchedulesState,
  IntegrationSchedule[]
>(updateSchedulesList, setSchedulesList);

const handleRemoveSchedule = reducer<SchedulesState, IntegrationJobPayload>(
  removeScheduleFromSchedulesList,
  removeSchedule
);

const reducers = [
  handleUpdateSchedulesList,
  handleRemoveSchedule,
  handleSetSchedulesAsyncStatus,
];

const defaultState: SchedulesState = {
  asyncStatus: 'INIT',
  schedules: [],
};

export const schedules$ = persistentReducedStream(
  `schedules$`,
  defaultState,
  reducers
);

const integrationToSchedulesStreams = new Map<
  IntegrationId,
  Observable<SchedulesState>
>();

export const getSchedulesStream = <Job = IntegrationSchedule>(
  integrationId: IntegrationId
) => {
  const schedulesStream = integrationToSchedulesStreams.get(integrationId);

  if (schedulesStream) {
    return schedulesStream as Observable<SchedulesState<Job>>;
  }

  const stream$ = schedules$.pipe(
    map(schedulesState => ({
      ...schedulesState,
      schedules: filterSchedulesByIntegrationId(
        schedulesState.schedules,
        integrationId
      ),
    }))
  );

  integrationToSchedulesStreams.set(integrationId, stream$);

  return stream$ as Observable<SchedulesState<Job>>;
};
