import { combineLatest } from 'rxjs';
import { ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { DatasourceTable, FlyWheelTable } from '@ardoq/table';
import { ActionButtons, StyledIcon } from 'admin/bundles/BundleOverview/atoms';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { s32 } from '@ardoq/design-tokens';
import { EditTemplatesState, deleteTemplate } from 'streams/templates/routines';
import currentUser$ from 'streams/currentUser/currentUser$';
import { models$ } from 'streams/models/models$';
import { showEditTemplate } from './routines';
import { IconName } from '@ardoq/icons';

type EditTemplatesModalProps = EditTemplatesState & {
  onClose: VoidFunction;
};
const EditTemplatesTable = ({
  onClose,
  templates,
}: EditTemplatesModalProps) => (
  <ModalTemplate
    headerText="Edit templates"
    modalSize={ModalSize.M}
    primaryButtonText="Close"
    onPrimaryButtonClick={() => onClose()}
  >
    <div style={{ padding: s32 }}>
      <DatasourceTable
        components={FlyWheelTable}
        dataSource={templates}
        columns={[
          { title: 'Name', dataIndex: 'name' },
          {
            dataIndex: '_id',
            valueRender: (id, { common }) => {
              return (
                <ActionButtons>
                  <StyledIcon
                    iconName={IconName.EDIT}
                    tooltipText="Edit"
                    onClick={() => {
                      onClose();
                      dispatchAction(showEditTemplate({ id }));
                    }}
                  />
                  {!common && (
                    <StyledIcon
                      iconName={IconName.DELETE}
                      tooltipText="Delete"
                      onClick={() => {
                        dispatchAction(deleteTemplate({ id }));
                      }}
                    />
                  )}
                </ActionButtons>
              );
            },
          },
        ]}
      />
    </div>
  </ModalTemplate>
);

const viewProps$ = combineLatest([currentUser$, models$]).pipe(
  map(([currentUserState, models]) => {
    const organization = currentUserState.organization;
    return {
      organization,
      templates: Object.values(models.byId).filter(model => {
        const isArdoqCommon = organization?.label === 'ardoq-common';

        const isCustomTemplate = !model.common && model.useAsTemplate;

        return isArdoqCommon || isCustomTemplate;
      }),
    };
  })
);

const ConnectedEditTemplatesTable = connect(EditTemplatesTable, viewProps$);

export default () => {
  modal(resolve => (
    <ConnectedEditTemplatesTable onClose={() => resolve(false)} />
  ));
};
