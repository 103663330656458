import { colors } from '@ardoq/design-tokens';
import { FlexBox } from '@ardoq/layout';
import { text2 } from '@ardoq/typography';
import styled from 'styled-components';

export const TableContainer = styled.div`
  border: 1px solid ${colors.borderSubtle00};
  border-radius: 16px;
  overflow: hidden;
`;

export const FlexWithFontSize = styled(FlexBox)`
  ${text2};
`;
