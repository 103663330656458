import { colors, s4, s64, s8, shadowS } from '@ardoq/design-tokens';
import { ICON_SELECTOR } from '@ardoq/icons';
import { Space } from '@ardoq/style-helpers';
import styled, { css } from 'styled-components';
import { getFocusRingBorderCSS } from '@ardoq/style-helpers';

export const SidebarPositioner = styled.div<{ $hasNewJourneyFeature: boolean }>`
  position: relative;
  min-width: ${({ $hasNewJourneyFeature }) =>
    $hasNewJourneyFeature ? s64 : '56px'};
`;

export const Wrapper = styled(Space).attrs({
  $isVertical: true,
  $justify: 'space-between',
  $gap: 'none',
})<{ $hasNewJourneyFeature?: boolean; $isExpanded: boolean }>`
  position: absolute;
  @media print {
    display: none !important;
  }
  z-index: 11;
  height: 100%;
  ${({ $hasNewJourneyFeature, $isExpanded }) =>
    $hasNewJourneyFeature
      ? css`
          background-color: ${colors.surfaceDefault};
          box-shadow: ${$isExpanded ? shadowS : 'none'};
          width: ${$isExpanded ? '240px' : '100%'};
          transition: width 0.3s;
        `
      : css`
          width: 100%;
        `}
`;

export const MenuWrapper = styled(Space).attrs({
  $isVertical: true,
  $justify: 'space-between',
  $flex: 1,
})<{ $hasNewJourneyFeature?: boolean }>`
  width: 100%;
  ${({ $hasNewJourneyFeature }) =>
    $hasNewJourneyFeature
      ? css`
          transition: width 0.3s;
          background-color: ${colors.surfaceDefault};
          padding: 0 12px 12px 12px;
        `
      : css`
          background-color: #37475a;
        `};
`;

export const Section = styled(Space).attrs<{ $hasNewJourneyFeature: boolean }>({
  $isVertical: true,
  $gap: 's8',
  $paddingTop: 's16',
  $paddingBottom: 's16',
  $align: 'center',
})<{ $hasNewJourneyFeature?: boolean }>`
  ${({ $hasNewJourneyFeature }) =>
    $hasNewJourneyFeature
      ? css`
          background-color: ${colors.surfaceDefault};
        `
      : css`
          display: block;
          padding: 0;
        `}
`;

export const SectionButton = styled.button<{
  $isActive?: boolean;
  $isOpen?: boolean;
  $hasNewJourneyFeature?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  gap: 10px;

  width: 100%;
  border: none;
  background-color: inherit;

  &:focus-visible {
    ${getFocusRingBorderCSS()}
  }

  ${({ $hasNewJourneyFeature, $isActive, $isOpen }) =>
    $hasNewJourneyFeature
      ? css`
          color: ${colors.textSubtle};
          background-color: ${colors.surfaceDefault};
          border-radius: ${s8};
          height: 40px;
          overflow: hidden;
          > ${ICON_SELECTOR} {
            fill: ${colors.icon};
            color: ${colors.icon};
          }
          ${$isOpen &&
          css`
            background-color: ${colors.surfaceHover};
            overflow: visible;
          `}
          &:hover {
            background-color: ${colors.surfaceHover};
          }
          ${$isActive &&
          css`
            color: ${colors.textDefault};
            background-color: ${colors.surfaceSelected};
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              margin-top: -10px;
              left: -14px;
              width: ${s4};
              border-radius: 0 ${s4} ${s4} 0;
              height: 20px;
              background-color: ${colors.surfaceSelected};
            }
          `}
        `
      : css`
          justify-content: center;
          height: 56px;
          opacity: 0.5;
          color: #fff;

          /* eg workspaces icon */
          svg {
            width: 20px;
            height: 20px;
            fill: #fff;
          }
          &:hover {
            opacity: 1;
          }
          ${$isActive &&
          css`
            opacity: 1;
          `}
        `};
`;

export const IconWrapper = styled.div<{
  $isActive?: boolean;
  $hasNewJourneyFeature: boolean;
}>`
  display: flex;
  ${({ $hasNewJourneyFeature, $isActive }) => {
    if (!$hasNewJourneyFeature) return null;
    return $isActive
      ? css`
          ${ICON_SELECTOR} {
            fill: ${colors.iconActionBrand};
            color: ${colors.iconActionBrand};
          }
        `
      : css`
          ${ICON_SELECTOR} {
            fill: ${colors.icon};
            color: ${colors.icon};
          }
        `;
  }}
`;

export const ButtonTitle = styled.div`
  white-space: nowrap;
`;

export const MenuPositioner = styled.div`
  position: relative;
  width: 100%;
`;
