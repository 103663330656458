import {
  setMergeStep,
  toggleIsExpandMainStep,
} from 'components/DiffMergeTable/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { setStep, trackSetStep } from 'scope/merge/actions';
import { MergeStepLabel } from 'scope/merge/typesMergeStepLabel';
import { Verb } from '@ardoq/api-types';
import { getMergeStepEnum } from './utils';

export const handleMergeStepSelect = (
  mainStateStep: MergeStepLabel,
  subStateStep: Verb
) => {
  // TODO only one action
  const mergeStep = getMergeStepEnum(mainStateStep, subStateStep);
  dispatchAction(setMergeStep({ mergeStep }));
  dispatchAction(setStep({ mainStateStep, subStateStep }));
  dispatchAction(trackSetStep());
};

export const handleMergeStepExpandToggle = (mainStep: MergeStepLabel) => {
  dispatchAction(toggleIsExpandMainStep({ mainStep }));
};
