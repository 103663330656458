import { Form, Formik } from 'formik';
import { PrimaryButton } from '@ardoq/button';
import { FormValues, submit, validate } from './utils';
import CurrentUser from 'models/currentUser';
import { logError } from '@ardoq/logging';
import StatusMessage, { isStatus } from '../shared/StatusMessage';
import { FormWrapper, TextInput } from '@ardoq/forms';
import {
  IslandBody,
  IslandContainer,
  IslandFooter,
  IslandHeader,
} from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';

const DEFAULT_ERROR_MESSAGE = 'An error occurred while updating your email';

const EmailView = () => (
  <Formik<FormValues>
    initialValues={{ currentPassword: '', email: '' }}
    validate={validate}
    validateOnBlur={false}
    validateOnChange={false}
    onSubmit={(values, { setErrors, setSubmitting, setStatus }) =>
      submit(CurrentUser.id, values)
        .then(
          ({ success, message }) => {
            if (!success) {
              setErrors({ email: message });
              setStatus({ success, message: '' });
            } else
              setStatus({
                success: true,
                message: 'Check your inbox to confirm the email change',
              });
          },
          (error: Error) => {
            logError(error);
            setStatus({
              success: false,
              message: DEFAULT_ERROR_MESSAGE,
            });
          }
        )
        .finally(() => setSubmitting(false))
    }
  >
    {({
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      status,
    }) => (
      <Form onSubmit={handleSubmit}>
        <IslandContainer>
          <IslandHeader title="Change Email" />
          <IslandBody>
            <FormWrapper>
              <TextInput
                label="Current Password"
                type="password"
                name="currentPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.currentPassword as string}
              />
              <TextInput
                label="New email"
                type="text"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.email as string}
              />
            </FormWrapper>
          </IslandBody>
          <IslandFooter $justify="end">
            <FlexBox marginRight="large">
              <StatusMessage
                status={!isSubmitting && isStatus(status) && status}
              />
            </FlexBox>
            <PrimaryButton type="submit" isDisabled={isSubmitting}>
              Send Change Email Link
            </PrimaryButton>
          </IslandFooter>
        </IslandContainer>
      </Form>
    )}
  </Formik>
);

export default EmailView;
