import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  catchError,
  distinctUntilKeyChanged,
  map,
  mergeMap,
  toArray,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { omit } from 'lodash';
import {
  fetchTransferConfigs,
  fetchTransferConfigsSuccess,
  bulkDeleteConfigurations,
  deleteConfigurationFailure,
  deleteConfigurationSuccess,
  renameConfiguration,
  saveConfigurationsFailure,
  saveConfigurationsSuccess,
  fetchTransferConfigsFailure,
  cloneConfiguration,
  interposeSaveReminder,
  deleteConfigurations,
  createTransferConfig,
  updateTransferConfig,
  saveActiveConfiguration,
  saveConfiguration,
  setSelectedConfigIds,
} from './actions';
import { ModalSize, confirmDelete } from '@ardoq/modal';
import { tablePreviews$ } from '../tablePreviews/getTablePreviewsStream';
import {
  equivalentTabularMappings,
  transferConfigToTabularMapping,
} from '../tabularMappings/utils';
import { combineLatest, from, of } from 'rxjs';
import { initIntegration } from 'integrations/actions';
import { activeIntegrations$ } from '../activeIntegrations/activeIntegrations$';
import { tabularMappings$ } from '../tabularMappings/getTabularMappingStream';
import { transferConfigs$ } from './transferConfigs$';
import { formatApiTransferConfigs, getCurrentTransferConfig } from './utils';
import {
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';
import { setTransferConfigId } from 'integrations/common/streams/activeIntegrations/actions';
import { fields$ } from '../fields/fields$';
import { workspaces$ } from '../workspaces/workspaces$';
import { trackIntegrationEvent } from '../../tracking/actions';
import { startSaveModal } from 'integrations/common/modals/saveModal/SaveModal';
import { DeleteConfigModalText } from 'integrations/common/components/deleteConfigModalText/DeleteConfigModalText';
import { showSuccessToast } from 'streams/invitations/utils';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { ToastType, showToast } from '@ardoq/status-ui';
import { commonIntegrationApi } from '@ardoq/api';
import { INTEGRATIONS_WITH_TRANSFER_CONFIGS } from './constants';

const handleRetrieveTransferConfigs = routine(
  ofType(fetchTransferConfigs),
  switchMap(() =>
    retriableRequest(
      commonIntegrationApi.fetchTransferConfigs,
      'Unable to fetch TransferConfig'
    )
  ),
  map(response => formatApiTransferConfigs(response)),
  tap(configs => {
    dispatchAction(fetchTransferConfigsSuccess(configs));
  }),
  catchError(error => {
    dispatchAction(fetchTransferConfigsFailure());

    return handleApiError(error);
  })
);

const handleInitIntegration = routine(
  ofType(initIntegration),
  extractPayload(),
  distinctUntilKeyChanged('id'),
  tap(({ id }) => {
    if (INTEGRATIONS_WITH_TRANSFER_CONFIGS.includes(id)) {
      dispatchAction(fetchTransferConfigs());
    }
  })
);

const handleCreateTransferConfig = routine(
  ofType(createTransferConfig),
  extractPayload(),
  switchMap(({ integrationId, config, setAsSelectedTransferConfig = true }) => {
    return combineLatest([
      of(setAsSelectedTransferConfig),
      retriableRequest(
        () => commonIntegrationApi.createConfiguration(config),
        'Unable to create TransferConfig'
      ).pipe(map(response => ({ response, integrationId }))),
    ]);
  }),
  tap(([setAsSelectedTransferConfig, { response, integrationId }]) => {
    dispatchAction(
      saveConfigurationsSuccess({
        integrationId,
        id: response._id,
        setAsSelectedTransferConfig,
      })
    );
  }),
  catchError(error => {
    dispatchAction(
      saveConfigurationsFailure('Unable to save the configuration')
    );

    return handleApiError(error);
  })
);

const handleUpdateTransferConfig = routine(
  ofType(updateTransferConfig),
  extractPayload(),
  switchMap(({ integrationId, config }) => {
    return retriableRequest(
      () => commonIntegrationApi.updateConfiguration(config),
      'Unable to update TransferConfig'
    ).pipe(map(response => ({ response, integrationId })));
  }),
  tap(({ integrationId }) => {
    dispatchAction(
      saveConfigurationsSuccess({
        integrationId,
      })
    );
  }),
  catchError(error => {
    dispatchAction(
      saveConfigurationsFailure(
        error.statusCode === 409
          ? 'Unable to save the configuration. A new version exists.'
          : 'Unable to save the configuration'
      )
    );

    return handleApiError(error);
  })
);

const handleSaveConfigurationsSuccess = routine(
  ofType(saveConfigurationsSuccess),
  extractPayload(),
  tap(({ integrationId, id, setAsSelectedTransferConfig = true }) => {
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'SAVED_CONFIGURATION',
        metadata: {
          isNew: false,
        },
      })
    );
    if (id && setAsSelectedTransferConfig) {
      dispatchAction(setTransferConfigId({ integrationId, id }));
    }
    dispatchAction(fetchTransferConfigs());
  })
);

const handleBulkDeleteConfigurations = routine(
  ofType(bulkDeleteConfigurations),
  extractPayload(),
  tap(({ configs }) => {
    confirmDelete({
      title:
        configs.length > 1
          ? 'Delete the configurations permanently?'
          : 'Delete the configuration permanently?',
      modalSize: ModalSize.XS,
      cancelButtonTitle:
        configs.length > 1 ? 'Keep configurations' : 'Keep configuration',
      confirmButtonTitle:
        configs.length > 1 ? 'Delete configurations' : 'Delete configuration',
      shouldCloseOnBackdropClick: false,
      text: DeleteConfigModalText({
        configs,
      }),
      onConfirmAsync: () => {
        dispatchAction(
          deleteConfigurations({ ids: configs.map(({ _id }) => _id) })
        );
      },
    });
  })
);

const handleDeleteConfigurations = routine(
  ofType(deleteConfigurations),
  extractPayload(),
  switchMap(({ ids }) => {
    return from(ids).pipe(
      mergeMap(
        id =>
          retriableRequest(
            () => commonIntegrationApi.deleteConfiguration(id),
            'Unable to delete TransferConfig'
          ),
        6
      ),
      toArray()
    );
  }),
  tap(results => {
    const confirmMessage =
      results.length > 1 ? 'Configurations deleted' : 'Configuration deleted';
    showSuccessToast(confirmMessage);
    dispatchAction(deleteConfigurationSuccess());
    dispatchAction(fetchTransferConfigs());
    dispatchAction(setSelectedConfigIds([]));
  }),
  catchError(error => {
    dispatchAction(deleteConfigurationFailure());
    return handleApiError(error);
  })
);

const handleRenameConfiguration = routine(
  ofType(renameConfiguration),
  extractPayload(),
  tap(({ integrationId, name, config }) => {
    return dispatchAction(
      updateTransferConfig({
        integrationId,
        config: {
          ...config,
          name,
        },
      })
    );
  })
);

const handleCloneConfiguration = routine(
  ofType(cloneConfiguration),
  extractPayload(),
  tap(({ integrationId, name, config }) => {
    return dispatchAction(
      createTransferConfig({
        integrationId,
        config: {
          ...omit(config, ['_id', '_version', 'lastUpdated']),
          name,
        },
      })
    );
  })
);

const handleInterposeSaveReminder = routine(
  ofType(interposeSaveReminder),
  extractPayload(),
  withLatestFrom(
    activeIntegrations$,
    transferConfigs$,
    tabularMappings$,
    tablePreviews$,
    fields$,
    workspaces$
  ),
  tap(
    ([
      { integrationId, onDone },
      activeIntegrations,
      { configs },
      tabularMappings,
      tablePreviews,
      fields,
      workspaces,
    ]) => {
      const activeIntegration = activeIntegrations[integrationId];
      // if config is new or different from loaded one we display the save modal
      if (
        !equivalentTabularMappings(
          transferConfigToTabularMapping({
            transferConfig: getCurrentTransferConfig(
              configs,
              activeIntegration
            ),
            tablePreviews: tablePreviews[integrationId],
            allFields: fields.all,
            allWorkspaces: workspaces.existing,
            mapTablesBy:
              activeIntegrations[integrationId].integrationMappingParams
                .mapTablesBy,
            mapColumnsBy:
              activeIntegrations[integrationId].integrationMappingParams
                .mapColumnsBy,
          }),
          tabularMappings[integrationId]
        )
      ) {
        startSaveModal({
          integrationId,
          title: 'Unsaved configuration',
          isNewConfig: !activeIntegration.selectedTransferConfigId,
          onDone,
          modalTemplateOverrides: {
            secondaryButtonText: 'Discard',
            warningBlockMessage:
              "Changes will be lost if you don't save your configuration.",
          },
        });
      } else {
        onDone();
      }
    }
  )
);

const handleSaveActiveConfiguration = routine(
  ofType(saveActiveConfiguration),
  extractPayload(),
  withLatestFrom(activeIntegrations$, transferConfigs$),
  tap(([{ integrationId }, activeIntegrations, transferConfigs]) => {
    const selectedConfig = transferConfigs.configs.find(
      c => c._id === activeIntegrations[integrationId].selectedTransferConfigId
    );
    if (selectedConfig) {
      dispatchActionAndWaitForResponse(
        saveConfiguration({
          integrationId,
          name: selectedConfig.name,
          isNewConfig: false,
        }),
        saveConfigurationsSuccess,
        saveConfigurationsFailure
      ).then(resultAction => {
        if (resultAction.type === saveConfigurationsSuccess.type) {
          showToast('Configuration saved', ToastType.SUCCESS);
        }

        if (resultAction.type === saveConfigurationsFailure.type) {
          showToast('Failed to save configuration', ToastType.INFO);
        }
      });
    }
  })
);

export default [
  handleInitIntegration,
  handleRetrieveTransferConfigs,
  handleBulkDeleteConfigurations,
  handleDeleteConfigurations,
  handleRenameConfiguration,
  handleCloneConfiguration,
  handleInterposeSaveReminder,
  handleSaveConfigurationsSuccess,
  handleCreateTransferConfig,
  handleUpdateTransferConfig,
  handleSaveActiveConfiguration,
];
