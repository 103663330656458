import { FieldsWrapper } from '@ardoq/forms';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { setInvitationFormState } from 'streams/invitations/actions';
import inviteNewUser$, { InviteNewUserStreamShape } from './inviteNewUser$';
import PrivilegeEditor from 'privileges/PrivilegeEditor';
import { getInheritedPrivileges } from 'roles/utils';
import { UserRoleSelector } from '../roles/UserRoleSelector';
import DefaultRoleDescription from 'admin/accessControl/ManageUserRoles/DefaultRoleDescription';
import { FormState } from 'streams/invitations/types';
import { EmailsInputField } from './components/EmailsInputField';
import { inviteNewUserOperations } from './inviteNewUserOperations';
import { Island } from '@ardoq/page-layout';

const InviteNewUser = ({
  configurablePrivileges,
  roles,
  users,
  formState,
}: InviteNewUserStreamShape) => {
  const { emails, role, privileges, isValid } = formState;

  const inheritedPrivileges = getInheritedPrivileges(roles, role);

  const setInputValue = (partialState: Partial<Omit<FormState, 'isValid'>>) => {
    const partialNewState = { ...formState, ...partialState };
    dispatchAction(
      setInvitationFormState({
        ...partialNewState,
      })
    );
  };

  return (
    <>
      <Island title="Invite users">
        <EmailsInputField
          emails={emails}
          onEmailsChange={newEmails =>
            setInputValue({
              emails: newEmails,
            })
          }
          isValid={isValid}
        />
      </Island>
      <Island title="Assign role">
        <FieldsWrapper sideNote={<DefaultRoleDescription role={role} />}>
          <UserRoleSelector
            label=""
            currRole={role}
            users={users}
            onValueChange={roleLabel => {
              const role = roles.find(({ label }) => label === roleLabel);
              if (!role) return;
              setInputValue({
                role: roleLabel,
                privileges:
                  inviteNewUserOperations.filterConfigurablePrivilegeLabels(
                    configurablePrivileges,
                    role.privileges
                  ),
              });
            }}
          />
        </FieldsWrapper>
        {configurablePrivileges.length > 0 && (
          <PrivilegeEditor
            configurablePrivileges={configurablePrivileges}
            enabledPrivileges={privileges}
            inheritedPrivileges={inheritedPrivileges}
            role={role}
            onChange={privileges => setInputValue({ privileges })}
          />
        )}
      </Island>
    </>
  );
};

export default connect(InviteNewUser, inviteNewUser$);
