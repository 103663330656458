import { chooseScheduleStartDate } from 'broadcasts/actions';
import ScheduleDate from 'broadcasts/broadcastBuilder/broadcastSchedulerAndReminderForm/ScheduleDate';
import { dispatchAction } from '@ardoq/rxbeach';
import { currentDateTimeISO, isDateBeforeDay } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';

const getScheduleDateWarnings = (startDate: string | null) => {
  const locale = getCurrentLocale();

  if (!startDate) return 'Please provide a start date';
  if (isDateBeforeDay(startDate, currentDateTimeISO(), locale))
    return 'The start date is in the past. Update the start date before launching.';
};

type ScheduleStartDateProps = {
  readonly startDate: string | null;
  readonly minDate: string;
};

const ScheduleStartDate = ({ startDate, minDate }: ScheduleStartDateProps) => {
  return (
    <ScheduleDate
      label="Start date"
      helperText="The broadcast will be sent within an hour after the selected time."
      date={startDate}
      minDate={minDate}
      onChange={newDate =>
        dispatchAction(chooseScheduleStartDate(newDate?.toISOString() ?? null))
      }
      warningMessage={getScheduleDateWarnings(startDate)}
      useTimePicker
    />
  );
};

export default ScheduleStartDate;
