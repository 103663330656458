import {
  ArdoqId,
  GremlinSearchQuery,
  QueryBuilderQuery,
} from '@ardoq/api-types';
import FileSaver from 'file-saverjs';
import { advancedSearchApi, graphSearchApi, workspaceApi } from '@ardoq/api';
import { isArdoqError, Result } from '@ardoq/common-helpers';

type WorkspaceExportParams = {
  workspaceId: ArdoqId;
  filename: string;
};

type GremlinExportParams = {
  query: GremlinSearchQuery;
  getAllResults: boolean;
};

export default {
  componentSearch: function (
    query: QueryBuilderQuery
  ): Promise<Result<{ results: any[] }>> {
    return advancedSearchApi.search(query);
  },
  exportWorkspace: async function ({
    workspaceId,
    filename = 'search-export',
  }: WorkspaceExportParams) {
    const exportResult = await workspaceApi.export(workspaceId);
    if (isArdoqError(exportResult)) {
      return exportResult;
    }
    return await FileSaver(exportResult, `${filename}.xlsx`);
  },
  /**
   * @param {Object} runGremlinExport
   * @param {string} runGremlinExport.query Graph search query
   * @param {string=} runGremlinExport.filename Name of exported file
   * @param {boolean=} runGremlinExport.getAllResults API will by default limit results to 100 paginated results.
   *  Setting this to true will get all results (unless > 100,000). Use with caution!
   * @param {Object=} runGremlinExport.queryParams Optional query params to specify endpoint params like size and from
   */
  runGremlinExport: async function ({
    query,
    getAllResults,
  }: GremlinExportParams) {
    const exportResult = await graphSearchApi.graphSearchExport({
      query: query.trim(),
      queryParams: {
        ...(getAllResults ? graphSearchApi.allResults : {}),
      },
    });
    if (isArdoqError(exportResult)) {
      return exportResult;
    }

    return await FileSaver(exportResult, 'ardoq_graph_query_export.xlsx');
  },
};
