import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { navigateToSubdivision } from 'router/navigationActions';
import { switchMap, tap } from 'rxjs/operators';
import { getGroups } from './groups$/actions';
import { getZonePermissions } from './permissions$/actions';
import { getComponentTypes } from './componentTypes$/actions';
import {
  savePermissionsAndZones,
  savePermissionsAndZonesFailure,
  savePermissionsAndZonesSuccessfully,
} from './zonesViewState$/actions';
import { handleError, permissionZoneApi } from '@ardoq/api';
import { logError } from '@ardoq/logging';
import { Team } from '@ardoq/profiling';
import { ToastType, showToast } from '@ardoq/status-ui';
import { fetchAllZones } from 'streams/subdivisions/actions';

const handleDataPrepareRoutine = routine(
  ofType(navigateToSubdivision),
  extractPayload(),
  tap(() => {
    dispatchAction(getComponentTypes());
    dispatchAction(getGroups());
    dispatchAction(getZonePermissions());
  })
);

const handleSavePermissionsAndZonesRoutine = routine(
  ofType(savePermissionsAndZones),
  extractPayload(),
  switchMap(permissionZoneApi.updateZonesWithPermissions),
  handleError(error => {
    showToast('Failed to save group fields', ToastType.INFO);
    logError(error, 'Error in updating zones and permissions', {
      tags: { team: Team.APPSEC },
    });
    dispatchAction(savePermissionsAndZonesFailure());
  }),
  tap(response => {
    dispatchAction(savePermissionsAndZonesSuccessfully(response));
    dispatchAction(fetchAllZones());
    dispatchAction(getZonePermissions());
  })
);

export const zonesViewStateRoutines = collectRoutines(
  handleDataPrepareRoutine,
  handleSavePermissionsAndZonesRoutine
);
