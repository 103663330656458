import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { forkJoin, switchMap, tap } from 'rxjs';
import { showToast, ToastType } from '@ardoq/status-ui';
import {
  bulkSubmitSSOAttributeMapping,
  getAggregatedSSOMappings,
  getPreviewSSOMapping,
  getSSOAttributeMapping,
  setAggregatedSSOMappings,
  setPreviewSSOMapping,
  setSSOAttributeMapping,
} from './actions';
import { isPlural, isTempId } from './utils';
import { STRINGS } from './consts';
import { handleError, SSOMappingApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { map } from 'rxjs/operators';

const handleGetAggregatedSSOMappings = routine(
  ofType(getAggregatedSSOMappings),
  switchMap(SSOMappingApi.fetchAggregated),
  handleError(),
  map(setAggregatedSSOMappings),
  tap(dispatchAction)
);

const handleGetSSOAttributeMapping = routine(
  ofType(getSSOAttributeMapping),
  switchMap(SSOMappingApi.fetchAll),
  handleError(),
  map(setSSOAttributeMapping),
  tap(dispatchAction)
);

const handleBulkSubmitSSOAttributeMapping = routine(
  ofType(bulkSubmitSSOAttributeMapping),
  extractPayload(),
  switchMap(({ changes, deletions }) => {
    return forkJoin([
      ...changes.map(async ({ _id, ...body }) => {
        if (isTempId(_id)) {
          const response = await SSOMappingApi.create(body);
          if (isArdoqError(response)) {
            return response;
          }
          return { ...response, updated: false };
        }
        const response = await SSOMappingApi.update({ _id, ...body });
        if (isArdoqError(response)) {
          return response;
        }
        return { ...response, updated: true };
      }),
      ...deletions.map(_id => SSOMappingApi.delete(_id)),
    ]);
  }),
  tap(changes => {
    const { created, updated, deleted, error } = changes.reduce(
      (acc, item) => {
        const { created, updated, deleted, error } = acc;
        if (isArdoqError(item)) {
          return {
            created,
            updated,
            deleted,
            error: error + 1,
          };
        }
        return {
          created: created + Number(typeof item !== 'string' && !item.updated),
          updated: updated + Number(typeof item !== 'string' && item.updated),
          deleted: deleted + Number(typeof item === 'string'),
          error,
        };
      },
      { created: 0, updated: 0, deleted: 0, error: 0 }
    );

    if (created > 0)
      showToast(
        `${created} ${
          isPlural(created)
            ? STRINGS.TOAST.CREATED_SUCCESS_ALT
            : STRINGS.TOAST.CREATED_SUCCESS
        }`,
        ToastType.SUCCESS
      );
    if (updated > 0)
      showToast(
        `${updated} ${
          isPlural(updated)
            ? STRINGS.TOAST.UPDATED_SUCCESS_ALT
            : STRINGS.TOAST.UPDATED_SUCCESS
        }`,
        ToastType.SUCCESS
      );
    if (deleted > 0)
      showToast(
        `${deleted} ${
          isPlural(deleted)
            ? STRINGS.TOAST.DELETED_SUCCESS_ALT
            : STRINGS.TOAST.DELETED_SUCCESS
        }`,
        ToastType.SUCCESS
      );
    if (error > 0)
      showToast(`Failed to save ${error} mapping rules.`, ToastType.INFO);
    dispatchAction(getSSOAttributeMapping());
  })
);

const handleGetPreviewSSOMapping = routine(
  ofType(getPreviewSSOMapping),
  extractPayload(),
  switchMap(({ mappings, provider }) =>
    SSOMappingApi.postPreview({
      mappings: mappings.map(({ _id, ...rest }) => rest),
      provider,
    })
  ),
  handleError(),
  tap(response => {
    dispatchAction(setPreviewSSOMapping({ mappingPreview: response }));
  })
);

export default collectRoutines(
  handleGetAggregatedSSOMappings,
  handleGetSSOAttributeMapping,
  handleBulkSubmitSSOAttributeMapping,
  handleGetPreviewSSOMapping
);
