import { CheckboxGroup } from '@ardoq/forms';
import { ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { useState } from 'react';

export type NegativeFeedbackSubmissionModalResult = {
  content: string;
  selectedReasons: string[];
};

const feedbackOptions = [
  { label: 'Inaccurate', value: 'inaccurate' },
  { label: 'Out of date', value: 'out_of_date' },
  { label: 'Too long', value: 'too_long' },
  { label: 'Too short', value: 'too_short' },
  { label: 'Harmful or offensive', value: 'harmful_or_offensive' },
  { label: 'Not helpful', value: 'not_helpful' },
];

const NegativeFeedbackSubmissionModal = ({
  resolve,
}: {
  resolve: (payload: NegativeFeedbackSubmissionModalResult) => void;
}) => {
  const [content, setContent] = useState<string>('');
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  return (
    <ModalTemplate
      modalSize={ModalSize.S}
      headerText="Share your feedback"
      inputLabel="Is there anything else you would like to share?"
      primaryButtonText="Share feedback"
      text={
        <CheckboxGroup
          label="How the answer could be improved? Select all that apply."
          onValueChange={selections =>
            setSelectedReasons(selections as string[])
          }
          options={feedbackOptions}
        />
      }
      onPrimaryButtonClick={() => resolve({ content, selectedReasons })}
      inputValue={content}
      onInputValueChange={setContent}
    />
  );
};

export const launchNegativeFeedbackSubmissionModal = () =>
  modal<NegativeFeedbackSubmissionModalResult>(resolve => (
    <NegativeFeedbackSubmissionModal resolve={resolve} />
  ));
