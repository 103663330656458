import { AssetType } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { trackEvent } from 'tracking/tracking';

type HandleDeleteAsset = {
  assetType: AssetType;
  onDelete: () => Promise<boolean>;
  onDeleteConfirmed: VoidFunction;
  clearSelected?: VoidFunction;
};

export const handleDeleteAsset = async ({
  assetType,
  onDelete,
  onDeleteConfirmed,
  clearSelected,
}: HandleDeleteAsset) => {
  const approved = await onDelete();
  if (approved) {
    trackEvent('Assets Browser: confirmed delete asset', { assetType });
    try {
      onDeleteConfirmed();
      clearSelected?.();
    } catch (error) {
      logError(error as Error, `Deleting ${assetType} failed`);
      alert({
        title: `Could not delete ${assetType}`,
        subtitle: `An error occurred while deleting the ${assetType}`,
        text: 'The error has been reported, but please contact support if the error persists.',
      });
    }
  }
};
