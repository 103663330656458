import { isIntegrationId } from 'integrations/common/streams/activeIntegrations/utils';
import {
  UNIFIED_INTEGRATION_IDS,
  UNIFIED_INTEGRATION_IDS_WITH_PROGRESS,
} from './constants';
import { UnifiedIntegrationId } from './types';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';

export const isUnifiedIntegrationId = (
  id: string | null
): id is UnifiedIntegrationId =>
  isIntegrationId(id) &&
  UNIFIED_INTEGRATION_IDS.includes(id as UnifiedIntegrationId);

export function isUnifiedIntegrationPayload<T>(payload: {
  integrationId: IntegrationId;
}): payload is T & { integrationId: UnifiedIntegrationId } {
  return isUnifiedIntegrationId(payload.integrationId);
}

export const createUnifiedIntegrationRecord = <T>(
  callbackFn: (id: UnifiedIntegrationId) => T
) => {
  return Object.fromEntries(
    UNIFIED_INTEGRATION_IDS.map(id => [
      id,
      callbackFn(id as UnifiedIntegrationId),
    ])
  ) as Record<UnifiedIntegrationId, T>;
};

export const isIntegrationWithProgress = (
  integrationId?: IntegrationId
): boolean =>
  !!integrationId &&
  isUnifiedIntegrationId(integrationId) &&
  UNIFIED_INTEGRATION_IDS_WITH_PROGRESS.includes(integrationId);
