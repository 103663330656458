import { azureIntegrationApi, getErrorStatusCode } from '@ardoq/api';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import { AsyncOperations } from 'integrations/common/async/constants';
import { getAsyncRequestId } from 'integrations/common/async/utils';
import { ApiError } from 'integrations/common/utils/api';

export async function fetchResourcesSummary({
  connectionId,
  async,
  funnelId,
}: {
  connectionId: string;
  async: boolean;
  funnelId: string;
}) {
  const requestId = getAsyncRequestId({
    integrationId: 'azure-v3',
    operation: AsyncOperations.AZURE_FETCH_RESOURCES_SUMMARY,
    funnelId,
  });

  const resourceSummary = await azureIntegrationApi.fetchResourcesSummary({
    connectionId,
    async,
    requestId,
  });

  if (isArdoqError(resourceSummary)) {
    throw new ApiError(
      'Unable to fetch resource summary for azure',
      getErrorStatusCode(resourceSummary),
      getArdoqErrorMessage(resourceSummary)
    );
  }

  return resourceSummary;
}
