import {
  AsyncLabelLoaders,
  AsyncSuggestionsLoaders,
} from '@ardoq/query-builder';
import {
  getLoadUserSuggestionsAsync,
  getLoadTagSuggestionsAsync,
} from 'search/AdvancedSearch/fetchSuggestionsUtils';
import { StubWorkspace } from '@ardoq/data-model';
import { getLoadComponentOfGivenTypeAsync } from './getLoadComponentOfGivenTypeAsync';
import { workspaceApi, organizationApi } from '@ardoq/api';
import {
  caseInsensitiveStringIncludes,
  isArdoqError,
} from '@ardoq/common-helpers';

type GetViewpointBuilderSuggestionsLoadersOptions = {
  relatedWorkspaces: StubWorkspace[];
  organizationId: string | null;
  componentTypeName: string | null;
};

export const getViewpointBuilderSuggestionsLoaders = ({
  relatedWorkspaces,
  componentTypeName,
  organizationId,
}: GetViewpointBuilderSuggestionsLoadersOptions): {
  asyncSuggestionsLoaders: AsyncSuggestionsLoaders;
  asyncLabelLoaders: AsyncLabelLoaders;
} => ({
  asyncSuggestionsLoaders: {
    loadComponentSuggestionsAsync: getLoadComponentOfGivenTypeAsync(
      componentTypeName ?? ''
    ),
    loadUserSuggestionsAsync: getLoadUserSuggestionsAsync(),
    loadComponentSuggestionsIncludingCurrentComponentAsync: async () => {
      throw new Error(
        'Not implemented async suggestion loader for components with current component'
      );
    },
    loadComponentKeySuggestionsAsync: async () => {
      throw new Error(
        'Not implemented async suggestion loader for component keys'
      );
    },
    loadParentComponentSuggestionsAsync: async () => {
      throw new Error(
        'Not implemented async suggestion loader for parent components'
      );
    },
    loadWorkspaceSuggestionsAsync: async (typedPhrase: string) => {
      return relatedWorkspaces
        .filter(({ _id }) => _id !== undefined)
        .filter(({ name }) => caseInsensitiveStringIncludes(name, typedPhrase))
        .map(({ _id, name }) => ({ label: name, value: _id }));
    },
    loadTagSuggestionsAsync: getLoadTagSuggestionsAsync(),
    loadReferenceOIDSuggestionsAsync: async () => {
      throw new Error(
        'Not implemented async suggestion loader for reference OIDs'
      );
    },
    loadComponentTypeNameSuggestionsAsync: () => {
      throw new Error(
        'Not implemented async suggestion loader for component type names'
      );
    },
    loadReferenceTypeNameSuggestionsAsync: () => {
      throw new Error(
        'Not implemented async suggestion loader for reference type names'
      );
    },
  },
  asyncLabelLoaders: {
    getComponentKeyLabelAsync: async () => {
      throw new Error(
        'Not implemented async suggestion loader for component keys'
      );
    },
    getComponentLabelAsync: async () => {
      throw new Error(
        'Not implemented async suggestion loader for component labels'
      );
    },
    getWorkspaceLabelAsync: async (id: string) => {
      const workspace = relatedWorkspaces.find(({ _id }) => _id === id);
      if (workspace) {
        return workspace.name;
      }

      const result = await workspaceApi.fetch(id);
      if (isArdoqError(result)) {
        return `${id} (unknown name)`;
      }
      return result.name;
    },
    getUserLabelAsync: async (userId: string) => {
      const user = await organizationApi.fetchUserById(userId, organizationId);
      if (isArdoqError(user)) {
        return `${userId} (unknown name)`;
      }
      return `${user.name} (${user.email})`;
    },
    getReferenceLabelAsync: async () => {
      throw new Error(
        'Not implemented async suggestion loader for reference labels'
      );
    },
  },
});
