import { ITPediaImportRoutes, ITPediaRoutes } from '../types';
import { ConfigurationState } from '../streams/types';
import { isDataSelectionValid } from '../NewSync/ComponentSelection/utils';
import { isFieldMappingValid } from '../NewSync/FieldMapping/utils';
import { OverviewRoute } from 'integrations/common/navigation/types';

type Props = {
  config: ConfigurationState;
};
const PATH_AVAILABILITY: Record<ITPediaRoutes, (props: Props) => boolean> = {
  schedules: () => true,
  connections: () => true,
  configurations: () => false,
  'import/select-data': () => true,
  'field-mapping': ({ config }) => isDataSelectionValid(config),
  'summary-sync': ({ config }) =>
    isFieldMappingValid(
      config.componentTypes,
      config.workspaces.vulnerabilities.name
    ),
  success: () => true,
  failed: () => true,
};

const isValidPath = (path: string): path is ITPediaRoutes => {
  return (
    Object.values(ITPediaImportRoutes).includes(path as ITPediaImportRoutes) ||
    Object.values(OverviewRoute).includes(path as OverviewRoute)
  );
};

export const isITPediaOverviewRoute = (
  route: ITPediaRoutes
): route is OverviewRoute => {
  return Object.values<string>(OverviewRoute).includes(route);
};

export const isPathReady = (route: string, props: Props) => {
  if (!isValidPath(route)) {
    return false;
  }
  return PATH_AVAILABILITY[route](props);
};
