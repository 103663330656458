import { EMPTY, combineLatest, map, of, switchMap } from 'rxjs';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { fields$ } from 'integrations/common/streams/fields/fields$';
import { workspaces$ } from 'integrations/common/streams/workspaces/workspaces$';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import {
  isResourceCreationDisabled,
  isTypeSelectionDisabled,
} from 'integrations/common/streams/transferState/utils';
import { getTabularMappingConstraintsStream } from 'integrations/common/streams/tabularMappingConstraints/getTabularMappingConstraintsStream';
import { getTabularMappingErrorsStream } from 'integrations/common/streams/tabularMappingErrors/getTabularMappingErrorsStream';
import { APIFieldType } from '@ardoq/api-types';
import { Field } from 'integrations/common/streams/fields/types';
import { SelectOptionsOrGroups } from '@ardoq/select';
import {
  getFieldTypeIcon,
  getFieldTypeName,
} from 'integrations/common/pages/tabularConfigMapping/utils';
import { isFileFieldAvailable } from './utils';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { rootWorkspaceExists } from 'integrations/common/streams/tabularMappings/utils';

const byAvailability = (hasFileField: boolean) => (field: Field) => {
  return (
    field?.type !== APIFieldType.FILE ||
    (hasFileField && field?.type === APIFieldType.FILE)
  );
};

const FIELD_TYPES = [
  APIFieldType.TEXT,
  APIFieldType.FILE,
  APIFieldType.TEXT_AREA,
  APIFieldType.NUMBER,
  APIFieldType.DATE_ONLY,
  APIFieldType.DATE_TIME,
  APIFieldType.URL,
  APIFieldType.EMAIL,
  APIFieldType.CHECKBOX,
  APIFieldType.LIST,
  APIFieldType.SELECT_MULTIPLE_LIST,
];

const getFieldOptions = (
  hasFileField: boolean
): SelectOptionsOrGroups<string> =>
  (hasFileField
    ? FIELD_TYPES
    : FIELD_TYPES.filter(field => field !== APIFieldType.FILE)
  ).map(type => ({
    value: type,
    label: getFieldTypeName(type),
    leftIcon: getFieldTypeIcon(type),
  }));

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      currentTableId: getActiveIntegrationStream(integrationId).pipe(
        map(ci => ci.currentTableId)
      ),
      tabularMapping: getTabularMappingStream(integrationId),
      tablePreviews: getTablePreviewsStream(integrationId),
      fields: fields$,
      workspaces: workspaces$,
      transferState: getTransferStateStream(integrationId),
      tabularMappingConstraints:
        getTabularMappingConstraintsStream(integrationId),
      tabularMappingErrors: getTabularMappingErrorsStream(integrationId),
    });
  }),
  switchMap(
    ({
      integrationId,
      currentTableId,
      tabularMapping,
      tablePreviews,
      fields,
      workspaces,
      transferState,
      tabularMappingConstraints,
      tabularMappingErrors,
    }) => {
      if (!currentTableId) {
        return EMPTY;
      }

      const tableMapping = tabularMapping[currentTableId];
      const isHardDeleteEnabled =
        tableMapping && tableMapping.options?.deletionStrategy === 'delete';

      const hasFileField = isFileFieldAvailable(integrationId);

      const onFirstComponentTypeChange = (
        changedDefaultColumnType: boolean
      ) => {
        dispatchAction(
          trackIntegrationEvent({
            integrationId,
            name: 'USED_SUGGESTED_COMPONENT_TYPE',
            metadata: {
              changedDefaultColumnType,
              isNewWorkspace: !rootWorkspaceExists(tableMapping),
            },
          })
        );
      };

      return of({
        integrationId,
        tabularMapping,
        tableMappingType: tableMapping?.rowRepresentation,
        tablePreview: tablePreviews[currentTableId],
        tableMapping,
        fields: {
          used: fields.used.filter(byAvailability(hasFileField)),
          all: fields.all.filter(byAvailability(hasFileField)),
        },
        workspaces,
        isCreationDisabled: isResourceCreationDisabled(transferState),
        isHardDeleteEnabled,
        mappedColumnsConstraints: tabularMappingConstraints[currentTableId]
          ? tabularMappingConstraints[currentTableId].mappedColumns
          : {},
        tableMappingErrors: tabularMappingErrors[currentTableId] || {},
        fieldTypeOptions: getFieldOptions(hasFileField),
        isTypeSelectionDisabled: isTypeSelectionDisabled(transferState),
        onFirstComponentTypeChange,
      });
    }
  )
);
