import { Box } from '@ardoq/layout';
import { PageBody, PageTopbar, PageWrapper } from '@ardoq/page-layout';
import { DocumentSelector } from './DocumentSelector/DocumentSelector';
import ReferencesSelector from './steps/ReferencesSelector/ReferencesSelector';
import ComponentSelector from './steps/ComponentSelector/ComponentSelector';
import { HorizontalStepper, Step, StepState } from '@ardoq/steppers';
import { connect } from '@ardoq/rxbeach';
import { document$ } from './streams/document/document$';
import { LucidchartDocumentImportState } from './streams/document/types';
import { map } from 'rxjs';
import { WorkspacesSelector } from './steps/WorkspacesSelector/WorkspacesSelector';

export function LucidchartComponent({
  document,
}: {
  document: LucidchartDocumentImportState;
}) {
  return (
    <PageWrapper>
      <PageTopbar
        primaryContent="Lucid chart integration"
        secondaryContent="Import and integrations"
      />
      <PageBody
        leftContent={
          document.status === 'SUCCESS' && (
            <Box
              height="full"
              backgroundColor="bgDefault"
              borderColor="borderSubtle00"
            >
              {
                <HorizontalStepper>
                  <Step
                    heading="Workspaces"
                    state={StepState.ACTIVE}
                    isSelected
                  />
                  <Step heading="Components" state={StepState.ACTIVE} />
                  <Step heading="References" state={StepState.ACTIVE} />
                  <Step heading="Review" state={StepState.ACTIVE} />
                </HorizontalStepper>
              }
              <WorkspacesSelector />
              <ComponentSelector />
              <ReferencesSelector />
            </Box>
          )
        }
      >
        <DocumentSelector />
      </PageBody>
    </PageWrapper>
  );
}

export const Lucidchart = connect(
  LucidchartComponent,
  document$.pipe(map(document => ({ document })))
);
