import AudienceDisplay, {
  UserAudienceMember,
  UserGroupAudienceMember,
} from './AudienceDisplay';
import { PeopleIcon, PersonIcon } from '@ardoq/icons';
import {
  APIOrganizationUser,
  ArdoqId,
  PermissionGroup,
  SurveyApprovalUsersAndGroupsAudience,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { colors } from '@ardoq/design-tokens';

const getUserGroupsAudienceMembers = (
  audience: SurveyApprovalUsersAndGroupsAudience,
  groupsById: Record<ArdoqId, PermissionGroup>,
  usersById: Record<ArdoqId, APIOrganizationUser>
): UserGroupAudienceMember[] => {
  return audience.groupIds
    .map(id => {
      const group: PermissionGroup | undefined = groupsById[id];
      if (!group) return null;
      return {
        id,
        icon: <PeopleIcon color={colors.icon} />,
        name: group.name,
        email: undefined,
        users: group.users
          .map(userId => usersById[userId])
          .filter(ExcludeFalsy),
        editable: true,
        type: 'userGroup' as const,
        userGroup: id,
      };
    })
    .filter(ExcludeFalsy);
};

const getUserAudienceMembers = (
  audience: SurveyApprovalUsersAndGroupsAudience,
  usersById: Record<ArdoqId, APIOrganizationUser>
): UserAudienceMember[] => {
  return audience.userIds
    .map(id => {
      const user: APIOrganizationUser | undefined = usersById[id];
      if (!user) return null;
      return {
        id,
        icon: <PersonIcon color={colors.icon} />,
        name: user.name,
        email: user.email,
        users: [],
        editable: true,
        type: 'user' as const,
        user: id,
      };
    })
    .filter(ExcludeFalsy);
};

type UsersAndGroupsAudienceDisplayProps = {
  title: string;
  audience: SurveyApprovalUsersAndGroupsAudience;
  groupsById: Record<ArdoqId, PermissionGroup>;
  usersById: Record<string, APIOrganizationUser>;
  unselectUserGroup: (userGroupId: ArdoqId) => void;
  unselectUser: (userId: ArdoqId) => void;
};

const UsersAndGroupsAudienceDisplay = ({
  title,
  audience,
  groupsById,
  usersById,
  unselectUserGroup,
  unselectUser,
}: UsersAndGroupsAudienceDisplayProps) => {
  const userGroupAudienceMembers = getUserGroupsAudienceMembers(
    audience,
    groupsById,
    usersById
  );
  const userAudienceMembers = getUserAudienceMembers(audience, usersById);
  return (
    <AudienceDisplay
      title={title}
      audienceMembers={[...userGroupAudienceMembers, ...userAudienceMembers]}
      noAudienceText="No user or group selected yet"
      unselectAudienceMember={audienceMember => {
        if (audienceMember.type === 'userGroup') {
          unselectUserGroup(audienceMember.userGroup);
        }
        if (audienceMember.type === 'user') {
          unselectUser(audienceMember.user);
        }
      }}
    />
  );
};

export default UsersAndGroupsAudienceDisplay;
