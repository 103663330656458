import fp from 'lodash/fp';
import {
  ServiceNowTable,
  ServiceNowSelectionRequestPayload,
} from '@ardoq/api-types/integrations';
import { SelectionState, SelectionTable } from './types';
import { TablesFields } from '../tableFields/types';

export const isValidSelectionState = (
  selectionState: SelectionState,
  connectionsIds: string[]
) => {
  return Boolean(
    connectionsIds.length &&
      !fp.isEmpty(selectionState.tables) &&
      Object.keys(selectionState.tables).some(tableId =>
        isValidTableSelection(selectionState.tables[tableId])
      )
  );
};

const isValidTableSelection = (table: SelectionTable) => {
  return !fp.isEmpty(table.fields);
};

export const prepareSelectionRequest = ({
  tables,
  connectionId,
  name,
  serviceNowTables,
  tablesFields,
}: {
  tables: SelectionState['tables'];
  connectionId: string;
  name: string;
  serviceNowTables: ServiceNowTable[];
  tablesFields: TablesFields;
}) => {
  const request: ServiceNowSelectionRequestPayload = {
    connectionId,
    name,
    tables: fp.flow(
      fp.toPairs,
      fp.filter(([_, table]) => isValidTableSelection(table)),
      fp.map(([tableId, { fields, queryFilter }]) => ({
        name: tableId,
        sysparmQuery: queryFilter,
        fields: fp.flow(
          fp.toPairs,
          fp.sortBy(([id]) =>
            fp.get([tableId, 'fields', id, 'name'], tablesFields)
          ),
          fp.map(([name]) => ({ name }))
        )(fields),
      })),
      fp.sortBy(t => serviceNowTables.find(t2 => t2.name === t.name)?.label)
    )(tables),
  };

  return request;
};

export const removeUnavailableFieldsFromSelectedTables = (
  selectedTables: SelectionState['tables'],
  tablesFields: TablesFields
): SelectionState['tables'] => {
  return fp.reduce(
    (tables, [tableId, table]) => {
      const availableTableFields = fp.get([tableId, 'fields'], tablesFields);
      const filteredFields = fp.flow(
        fp.toPairs,
        fp.filter(([id, _]) => Boolean(fp.get(id, availableTableFields))),
        fp.fromPairs
      )(table.fields);

      return fp.set(
        tableId,
        Object.values(filteredFields).length
          ? {
              ...table,
              fields: filteredFields,
            }
          : {},
        tables
      );
    },
    {},
    fp.toPairs(selectedTables)
  );
};

export const areFetchedTablesInSync = ({
  selectionResponse,
  tables,
}: SelectionState): boolean => {
  if (!selectionResponse) {
    return false;
  }
  const fetchedTables = selectionResponse.tables;
  const selectedTables = fp.pickBy(table => !fp.isEmpty(table.fields), tables);
  const selectedTableIds = Object.keys(selectedTables).sort();
  const fetchedTableIds = fetchedTables.map(t => t.id).sort();
  if (!fp.isEqual(selectedTableIds, fetchedTableIds)) {
    return false;
  }
  return fp.every(([tableId, { fields }]) => {
    const selectedFieldIds = Object.keys(fields).sort();
    const fetchedTable = fetchedTables.find(t => t.id === tableId);
    if (!fetchedTable) {
      return false;
    }
    const fetchedFieldIds = fetchedTable.columns.map(c => c.id).sort();
    return fp.isEqual(selectedFieldIds, fetchedFieldIds);
  }, fp.toPairs(selectedTables));
};
