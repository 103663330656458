import {
  APIComponentTypeAttributes,
  APIReferenceTypeAttributes,
  ArdoqId,
  PersistedUseCase,
} from '@ardoq/api-types';
import { Locale, localeCompareNumericLowercase } from '@ardoq/locale';
import { isEmpty, omit, uniqBy } from 'lodash';
import {
  DataTooltipText,
  SelectableKeysValue,
  SetUseCaseManagementFieldValuePayload,
  UseCaseManagementStreamState,
} from './types';
import { componentOptionOperations } from '../../models/utils/componentOptionOperations';
import { referenceOptionOperations } from '../../models/utils/referenceOptionOperations';
import { apiObjectsToSelectOptions } from '../bundles/BundleEditor/utils';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { SelectOption } from '@ardoq/select';

const IS_PARENT_OF = 'Is Parent Of';

const uniqueAndSortByName = <T extends { name: string }>(
  array: T[],
  locale: Locale
): T[] => {
  return uniqBy(array, 'name').sort((a, b) =>
    localeCompareNumericLowercase(a.name, b.name, locale)
  );
};

export const getDashboardSelectorState = ({
  entityGroupId,
  keyMetricsDashboardsOptions,
}: {
  entityGroupId: ArdoqId;
  keyMetricsDashboardsOptions: SelectOption<string>[];
}) => {
  let popoverMessage = null;
  const hasEntityGroup = !isEmpty(entityGroupId);
  if (!hasEntityGroup) popoverMessage = 'Select entity group first';
  if (!keyMetricsDashboardsOptions.length)
    popoverMessage = `Entity group doesn't have any dashboards assigned. Please select different Entity group or update current one`;

  return {
    popoverMessage,
    isDisabled: !hasEntityGroup || !keyMetricsDashboardsOptions.length,
  };
};

export const getAddTriplesButtonState = ({
  entityGroupId,
  availableComponentTypes,
  availableReferenceTypes,
}: {
  entityGroupId: ArdoqId;
  availableComponentTypes: SelectOption<string>[];
  availableReferenceTypes: SelectOption<string>[];
}): { dataTooltipText: DataTooltipText; isDisabled: boolean } => {
  let dataTooltipText = null;
  const hasEntityGroup = !isEmpty(entityGroupId);
  if (!hasEntityGroup) {
    dataTooltipText = 'Select entity group first';
  }

  if (
    (!availableComponentTypes.length || !availableReferenceTypes.length) &&
    hasEntityGroup
  ) {
    dataTooltipText =
      "Selected Entity Group doesn't have Component or Reference types assigned";
  }
  return {
    dataTooltipText,
    isDisabled:
      !hasEntityGroup ||
      !availableComponentTypes.length ||
      !availableReferenceTypes.length,
  };
};

const componentTypesToSelectOption = (
  componentTypes: APIComponentTypeAttributes[],
  locale: Locale
): SelectOption<string>[] => {
  const uniqueAndSortedComponentTypes = uniqueAndSortByName(
    componentTypes,
    locale
  );
  return uniqueAndSortedComponentTypes.map(
    componentOptionOperations.componentTypeToNameOptionWithLabelAsValue
  );
};

const enhanceReferenceTypes = (
  referenceTypes: APIReferenceTypeAttributes[]
): APIReferenceTypeAttributes[] => [
  ...referenceTypes,
  { name: IS_PARENT_OF, _id: 'is_parent_of' } as APIReferenceTypeAttributes,
];

const referenceTypesToSelectOption = (
  referenceTypes: APIReferenceTypeAttributes[],
  locale: Locale
): SelectOption<string>[] => {
  const uniqueAndSortedReferenceTypes = uniqueAndSortByName(
    enhanceReferenceTypes(referenceTypes),
    locale
  );
  return uniqueAndSortedReferenceTypes.map(
    referenceOptionOperations.toSelectOptionByName
  );
};

export const isSelectOptionKey = (
  key: keyof UseCaseManagementStreamState
): key is keyof SelectableKeysValue => {
  const selectableKeys: Array<keyof UseCaseManagementStreamState> = [
    'keyMetricsDashboards',
    'componentTypes',
    'referenceTypes',
    'entityGroups',
  ];
  return selectableKeys.includes(key);
};

export const getSelectOptionsForKey = (
  { key, value }: SetUseCaseManagementFieldValuePayload,
  locale: Locale
): SelectOption<string>[] => {
  if (value === null) return [];

  if (key === 'componentTypes')
    return componentTypesToSelectOption(value, locale);
  if (key === 'referenceTypes')
    return referenceTypesToSelectOption(value, locale);
  if (key === 'entityGroups') return apiObjectsToSelectOptions(value);
  if (key === 'keyMetricsDashboards') return apiObjectsToSelectOptions(value);

  return [];
};

const getAllowedEntitiesFromEntityGroup = <T extends { _id: ArdoqId }>({
  allowedIds,
  entityData,
}: {
  allowedIds: string[] | undefined;
  entityData: T[];
}) => {
  const allowedEntityIds = allowedIds ?? [];
  return allowedEntityIds.reduce((acc: T[], id: ArdoqId) => {
    const targetReferenceType = entityData.find(en => en._id === id);
    if (targetReferenceType) {
      return [...acc, targetReferenceType];
    }
    return acc;
  }, []);
};

export const getSelectableOptionsFromEntityGroup = (
  entityGroupId: string,
  state: UseCaseManagementStreamState
) => {
  const targetEntityGroup = state.entityGroups.data.find(
    eg => eg._id === entityGroupId
  );

  const allowedDashboards = getAllowedEntitiesFromEntityGroup({
    allowedIds: targetEntityGroup?.dashboardIds,
    entityData: state.keyMetricsDashboards.data,
  });
  const componentTypesFromEntityGroup = getAllowedEntitiesFromEntityGroup({
    allowedIds: targetEntityGroup?.componentTypeIds,
    entityData: state.componentTypes.data,
  });
  const referenceTypesFromEntityGroup = getAllowedEntitiesFromEntityGroup({
    allowedIds: targetEntityGroup?.referenceTypeIds,
    entityData: state.referenceTypes.data,
  });

  return {
    dashboardsOptions: apiObjectsToSelectOptions(allowedDashboards),
    componentTypesOptions: componentTypesToSelectOption(
      componentTypesFromEntityGroup,
      state.userLocale
    ),
    referenceTypesOptions: referenceTypesToSelectOption(
      referenceTypesFromEntityGroup,
      state.userLocale
    ),
    componentTypesFromEntityGroup,
    referenceTypesFromEntityGroup,
  };
};

export const getSaveButtonEditorNotificationMessage = (
  hasTriplesError: boolean,
  hasDashboardError: boolean
): string[] | undefined => {
  if (hasTriplesError || hasDashboardError) {
    const errors = [];
    if (hasDashboardError)
      errors.push(
        'Selected dashboard was removed from Entity Group. Please select a different dashboard'
      );
    if (hasTriplesError)
      errors.push(
        'Some Triples contain errors. Please correct them before saving'
      );
    return errors;
  }
};

export const getEditorStateFromExistingUseCase = (
  useCase: PersistedUseCase
) => {
  const useCaseWithUpdatedName = { ...useCase, name: `${useCase.name} (copy)` };
  return omit(useCaseWithUpdatedName, ['_id']);
};

const getAllComponentTypesByName = (
  name: string,
  componentTypes: APIComponentTypeAttributes[]
) => componentTypes.filter(ct => ct.name === name);

const getReferenceTypesFromWorkspaceFoundByModelId = (modelId: ArdoqId) => {
  const workspaceId = workspaceInterface.getWorkspaceIdByModelId(modelId);
  if (workspaceId) {
    return workspaceInterface.getReferenceTypes(workspaceId);
  }
  return {};
};

const getComponentTypesFromWorkspaceFoundByModelId = (modelId: ArdoqId) => {
  const workspaceId = workspaceInterface.getWorkspaceIdByModelId(modelId);
  if (workspaceId) {
    return workspaceInterface.getDecomposedComponentTypes(workspaceId)
      .componentTypes;
  }
  return {};
};

type GetReferenceTypesByComponentTypeNameParams = {
  componentTypeName: string;
  componentTypes: APIComponentTypeAttributes[];
  referenceTypes: APIReferenceTypeAttributes[];
  locale: Locale;
};

type EntitiesByComponentModelIdAccumulator = {
  [modelId: string]:
    | ReturnType<typeof getReferenceTypesFromWorkspaceFoundByModelId>
    | ReturnType<typeof getComponentTypesFromWorkspaceFoundByModelId>;
};

export const getReferenceTypesByComponentTypeName = ({
  componentTypeName,
  componentTypes,
  referenceTypes,
  locale,
}: GetReferenceTypesByComponentTypeNameParams) => {
  const componentTypesWithMatchingName = getAllComponentTypesByName(
    componentTypeName,
    componentTypes
  );
  const referenceTypesByComponentModelId =
    componentTypesWithMatchingName.reduce(
      (acc: EntitiesByComponentModelIdAccumulator, curr) => {
        return {
          ...acc,
          [curr.modelId]: getReferenceTypesFromWorkspaceFoundByModelId(
            curr.modelId
          ),
        };
      },
      {}
    );

  const overlappingRefTypes = referenceTypes.filter(refType => {
    const modelWithThisReferenceType =
      referenceTypesByComponentModelId[refType.modelId];
    if (modelWithThisReferenceType) {
      return modelWithThisReferenceType[refType.id];
    }
    return false;
  });

  return getSelectOptionsForKey(
    { key: 'referenceTypes', value: overlappingRefTypes },
    locale
  );
};

type GetTargetComponentTypesByReferenceTypeParams = {
  componentTypeName: string;
  referenceTypeName: string;
  currentComponentTypesSelectOptions: SelectOption<string>[];
  componentTypes: APIComponentTypeAttributes[];
  locale: Locale;
};

export const getTargetComponentTypesByReferenceType = ({
  componentTypeName,
  referenceTypeName,
  currentComponentTypesSelectOptions,
  componentTypes,
  locale,
}: GetTargetComponentTypesByReferenceTypeParams) => {
  if (!referenceTypeName || referenceTypeName !== IS_PARENT_OF)
    return currentComponentTypesSelectOptions;

  const componentTypesWithMatchingName = getAllComponentTypesByName(
    componentTypeName,
    componentTypes
  );

  const componentTypesByComponentModelId =
    componentTypesWithMatchingName.reduce(
      (acc: EntitiesByComponentModelIdAccumulator, curr) => {
        return {
          ...acc,
          [curr.modelId]: getComponentTypesFromWorkspaceFoundByModelId(
            curr.modelId
          ),
        };
      },
      {}
    );

  const overlappingCompTypes = componentTypes.filter(compType => {
    const modelWithThisComponentType =
      componentTypesByComponentModelId[compType.modelId];
    if (modelWithThisComponentType) {
      return modelWithThisComponentType[compType.id];
    }
    return false;
  });

  return getSelectOptionsForKey(
    { key: 'componentTypes', value: overlappingCompTypes },
    locale
  );
};
