import { ArdoqId } from '@ardoq/api-types';
import { pluralize } from '@ardoq/common-helpers';
import { AdditionalContentHintText } from 'components/DiffMergeTable/atoms';
import { MergeStep } from 'components/DiffMergeTable/types';

export const HintText = ({
  mergeStep,
  componentIds,
  referenceIds,
}: {
  mergeStep: MergeStep;
  componentIds: ArdoqId[];
  referenceIds: ArdoqId[];
}) => {
  if (!(componentIds.length || referenceIds.length)) return null;
  const componentString = componentIds.length
    ? `${pluralize('component', componentIds.length)}`
    : '';
  const referenceString = referenceIds.length
    ? `${pluralize('reference', referenceIds.length)}`
    : '';

  switch (mergeStep) {
    case MergeStep.DELETE_FIELDS:
      return (
        <AdditionalContentHintText>
          You have edited this field on the following {componentString}
          {componentString && referenceString && ' and '}
          {referenceString}.<br />
          These changes will be lost when this field is deleted.
        </AdditionalContentHintText>
      );
    case MergeStep.DELETE_COMPONENTS:
      return (
        <AdditionalContentHintText>
          By deleting this component,
          {componentString ? ` ${componentIds.length} ${componentString}` : ''}
          {componentString && referenceString ? ' and' : ''}
          {referenceString
            ? ` ${referenceIds.length} ${referenceString}`
            : ''}{' '}
          will also be deleted
        </AdditionalContentHintText>
      );
    case MergeStep.DELETE_COMPONENT_TYPES:
      return (
        <AdditionalContentHintText>
          The following {componentString} use this type, and must either be
          deleted or changed to use a different type in order to delete this
          component type. Exit the merge workflow and make the necessary changes
          in the scenario to resolve the problem
        </AdditionalContentHintText>
      );
    case MergeStep.DELETE_REFERENCE_TYPES:
      return (
        <AdditionalContentHintText>
          The following {referenceString} use this type, and must either be
          deleted or changed to use a different type in order to delete this
          reference type. Exit the merge workflow and make the necessary changes
          in the scenario to resolve the problem
        </AdditionalContentHintText>
      );
    case MergeStep.DELETE_TAGS:
      return (
        <AdditionalContentHintText>
          By deleting this tag,{' '}
          {componentIds.length + referenceIds.length > 1 ? 'these ' : 'this '}
          {componentString ? `${componentString}` : ''}
          {componentString && referenceString ? ' and ' : ''}
          {referenceString ? `${referenceString}` : ''} will be untagged
        </AdditionalContentHintText>
      );
    default:
      return null;
  }
};
