import selectedModule$ from 'appContainer/selectedModule$';
import currentUser$ from 'streams/currentUser/currentUser$';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { syncProgress$ } from 'sync/syncManager';
import { isQuickSearchVisible$ } from './isQuickSearchVisible$';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { fieldOptions$ } from 'streams/fields/fieldOptions$';
import { openViewpointBuilderCommands } from 'viewpointBuilder/openViewpointBuilderCommands';

export const topbarStream$ = combineLatest({
  selectedModule: selectedModule$,
  currentUser: currentUser$,
  fieldOptions: fieldOptions$,
  syncProgress: syncProgress$,
  showQuickSearch: isQuickSearchVisible$,
  scenario: activeScenario$,
}).pipe(
  map(
    ({
      selectedModule: { selectedModule },
      currentUser,
      fieldOptions,
      syncProgress,
      showQuickSearch,
      scenario: { isScenarioMode },
    }) => {
      const { customFields, ardoqIdField, ardoqOidField } = fieldOptions;

      return {
        selectedModule,
        customFields: [ardoqIdField, ardoqOidField, ...customFields],
        isTrial: currentUser.organization?.['is-trial'],
        daysRemaining: currentUser.organization?.['days-remaining-trial'] || 0,
        syncProgress,
        currentUser,
        showQuickSearch,
        isScenarioMode,
        commands: openViewpointBuilderCommands, // create custom Topbar commands when needed
      };
    }
  )
);
