import { ViewPaneHeaderProps } from 'appLayout/ardoqStudio/types';
import { OptionsGroup } from '@ardoq/settings-bar';
import { ViewContext } from '@ardoq/view-settings';
import { connect } from '@ardoq/rxbeach';
import viewModifiers$ from './viewModifiers$';
import viewActionsOptions from './viewActionsOptions';
import { filter } from 'rxjs';
import { FlexBox } from '@ardoq/layout';

type ViewPaneHeaderLeftControlsProps = ViewPaneHeaderProps & {
  viewContext: ViewContext;
  viewProperties: Record<string, any>;
};

const ViewPaneHeaderLeftControls = ({
  mainViewId,
  viewProperties,
  viewContext,
}: ViewPaneHeaderLeftControlsProps) => (
  <FlexBox gap="xsmall" align="center">
    <OptionsGroup
      viewId={mainViewId}
      options={viewActionsOptions(mainViewId, viewProperties, viewContext)}
      isViewpointModeViewPaneHeader={true}
    />
  </FlexBox>
);
const ConnectedViewPaneHeaderLeftControls = connect(
  ViewPaneHeaderLeftControls,
  viewModifiers$.pipe(
    filter(
      ({ mainViewId, viewPropertiesViewId }) =>
        mainViewId === viewPropertiesViewId
    )
  )
);

export default ConnectedViewPaneHeaderLeftControls;
