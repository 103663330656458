import { ComponentInlineInfo } from './atoms';

type ComponentRelatedEntitiesCountProperties = { count: number; text: string };
const ComponentRelatedEntitiesCount = ({
  count,
  text,
}: ComponentRelatedEntitiesCountProperties) => {
  if (!count) return null;
  return (
    <ComponentInlineInfo>
      {count}&nbsp;{text}
    </ComponentInlineInfo>
  );
};

export default ComponentRelatedEntitiesCount;
