import { formattingCommands as formattingCommandsPerspectiveEditor } from 'perspective/perspectiveEditor/formattingCommands';
import { dispatchAction } from '@ardoq/rxbeach';
import * as formattingActions from './viewpointBuilderFormattingActions';

const getFormattingOptions = (
  payload: formattingActions.GetFormattingOptionsPayload
) => dispatchAction(formattingActions.getFormattingOptions(payload));

const setFormattingOptions = (
  payload: formattingActions.SetFormattingOptionsPayload
) => dispatchAction(formattingActions.setFormattingOptions(payload));

const setFormattingRules = (
  payload: formattingActions.SetFormattingRulesPayload
) => dispatchAction(formattingActions.setFormattingRules(payload));

const setLabelFormatting = (
  payload: formattingActions.SetLabelFormattingPayload
) => dispatchAction(formattingActions.setLabelFormatting(payload));

export const viewpointBuilderFormattingCommands = {
  ...formattingCommandsPerspectiveEditor,
  getFormattingOptions,
  setFormattingOptions,
  setFormattingRules,
  setLabelFormatting,
};

export type ViewpointBuilderFormattingCommands =
  typeof viewpointBuilderFormattingCommands;
