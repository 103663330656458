import {
  activeMultiselectOptionsCount,
  SettingsConfig,
  SettingsType,
} from '@ardoq/view-settings';
import {
  ViewOptionsSectionBody,
  ViewOptionsSectionContainer,
  ViewOptionsSectionHeader,
  ViewOptionsSectionTitle,
  ViewOptionsSectionTitleContainer,
} from './atoms';
import { connect } from '@ardoq/rxbeach';
import viewModifiers$ from '../viewModifiers$';
import { availableViews$ } from 'views/availableViews$';
import { combineLatest, map } from 'rxjs';
import { ViewIds } from '@ardoq/api-types';
import ViewSettingsRow from './ViewSettingsRow';
import {
  BlockDiagramViewProperties,
  ModernizedBlockDiagramViewProperties,
} from 'tabview/blockDiagram/types';
import { LayoutOrientation } from '@ardoq/yfiles';
import { RelationshipsViewProperties } from 'tabview/relationsD3View/hierarchical/types';
import { DependencyMapViewProps } from 'tabview/dependencyMap/types';
import {
  isTimelineSettingsControlsDependencies,
  TimelineDateRangePicker,
} from '@ardoq/timeline2024';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { IconName } from '@ardoq/icons';
import { DropdownOptionType, isDropdownOption } from '@ardoq/dropdown-menu';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { formatDateOnly } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { ExtractStreamShape } from 'tabview/types';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { Box } from '@ardoq/layout';

const LAYOUT_ORIENTATION_LABELS: Record<LayoutOrientation, string> = {
  [LayoutOrientation.LEFT_TO_RIGHT]: 'Left to right',
  [LayoutOrientation.TOP_TO_BOTTOM]: 'Top to bottom',
  [LayoutOrientation.RIGHT_TO_LEFT]: 'Right to left',
  [LayoutOrientation.BOTTOM_TO_TOP]: 'Bottom to top',
};

const displayValueGetters: Partial<
  Record<
    ViewIds,
    Record<string, (viewProperties: Record<string, any>) => string>
  >
> = {
  [ViewIds.BLOCK_DIAGRAM]: {
    layoutDirection: viewProperties =>
      LAYOUT_ORIENTATION_LABELS[
        (viewProperties as BlockDiagramViewProperties).viewSettings
          .layoutOrientation
      ],
    separateReferences: viewProperties =>
      (viewProperties as BlockDiagramViewProperties).viewSettings
        .separateReferences
        ? 'Separate'
        : 'Combine',
  },
  [ViewIds.MODERNIZED_BLOCK_DIAGRAM]: {
    layoutDirection: viewProperties =>
      LAYOUT_ORIENTATION_LABELS[
        (viewProperties as ModernizedBlockDiagramViewProperties).viewSettings
          .layoutOrientation
      ],
    separateReferences: viewProperties =>
      (viewProperties as ModernizedBlockDiagramViewProperties).viewSettings
        .separateReferences
        ? 'Separate'
        : 'Combine',
    componentStyle: viewProperties =>
      (viewProperties as ModernizedBlockDiagramViewProperties).viewSettings
        .componentStyle === 'shape'
        ? 'Shape'
        : 'Icon',
  },
  [ViewIds.DEPENDENCY_MAP_2]: {
    layoutDirection: viewProperties =>
      (viewProperties as DependencyMapViewProps).viewSettings.showVertical
        ? 'Vertical'
        : 'Horizontal',
  },
  [ViewIds.RELATIONSHIPS_3]: {
    bundleRelationships: viewProperties =>
      (viewProperties as RelationshipsViewProperties).viewSettings
        .bundleRelationships
        ? 'Combine'
        : 'Separate',
  },
  [ViewIds.TIMELINE]: {
    phasesSubLevel: viewProperties => {
      if (!isTimelineSettingsControlsDependencies(viewProperties)) {
        return '';
      }

      const { phaseFieldOptions } = viewProperties;

      return activeMultiselectOptionsCount(phaseFieldOptions);
    },
    milestonesSubLevel: viewProperties => {
      if (!isTimelineSettingsControlsDependencies(viewProperties)) {
        return '';
      }

      const { milestoneFieldOptions } = viewProperties;

      return activeMultiselectOptionsCount(milestoneFieldOptions);
    },
    dateRangeSelection: viewProperties => {
      if (!isTimelineSettingsControlsDependencies(viewProperties)) {
        return '';
      }

      const locale = getCurrentLocale();
      const [startDate, endDate] =
        viewProperties.viewSettings.domainRange || [];
      const formattedStartDate = formatDateOnly(startDate ?? null, locale);
      const formattedEndDate = formatDateOnly(endDate ?? null, locale);

      return `${formattedStartDate} - ${formattedEndDate}`;
    },
    gridPrecisionSelection: viewProperties => {
      if (!isTimelineSettingsControlsDependencies(viewProperties)) {
        return '';
      }

      const { gridPrecisionOptionsData } = viewProperties;

      const validOptions =
        gridPrecisionOptionsData.options.filter(isDropdownOption);

      const activeOption = validOptions.find(option => option.isActive);

      return activeOption?.label ?? '';
    },
  },
};

const getEnhancedViewModifiers = (
  viewId: ViewIds,
  viewModifiers: SettingsConfig[],
  viewProperties: Record<string, any>
): SettingsConfig[] => {
  if (
    !(
      viewId === ViewIds.TIMELINE &&
      isTimelineSettingsControlsDependencies(viewProperties)
    )
  ) {
    return viewModifiers;
  }

  const timelineViewSettings = viewProperties?.viewSettings || {};

  const {
    gridPrecisionOptionsData: {
      options: gridPrecisionOptions,
      value: gridPrecisionValue,
    },
    milestoneFieldOptions,
    phaseFieldOptions,
  } = viewProperties;

  return [
    {
      type: SettingsType.DROPDOWN,
      id: 'dateRangeSelection',
      label: 'Date range',
      iconName: IconName.CALENDAR,
      options: [
        {
          content: (
            <Box padding="medium">
              <TimelineDateRangePicker
                labelText="Date range"
                viewSettings={timelineViewSettings}
                onViewSettingsUpdate={onViewSettingsUpdate}
              />
            </Box>
          ),
          type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
        },
      ],
      isKeepOpen: true,
    },
    gridPrecisionOptions && {
      id: 'gridPrecisionSelection',
      label: 'Layout',
      iconName: IconName.CALENDAR,
      type: SettingsType.DROPDOWN,
      options: gridPrecisionOptions,
      currentValue: gridPrecisionValue,
      isKeepOpen: true,
    },

    phaseFieldOptions && {
      id: 'phasesSubLevel',
      label: 'Phases',
      iconName: IconName.FORMAT_LIST_BULLETED,
      type: SettingsType.DROPDOWN,
      options: phaseFieldOptions,
      isKeepOpen: true,
      dropdownSize: DropdownSize.M,
    },
    milestoneFieldOptions && {
      id: 'milestonesSubLevel',
      label: 'Milestones',
      iconName: IconName.RHOMBUS,
      type: SettingsType.DROPDOWN,
      options: milestoneFieldOptions,
      isKeepOpen: true,
      dropdownSize: DropdownSize.M,
    },
  ].filter(ExcludeFalsy);
};

const ViewOptionsViewSettings = ({
  mainViewId,
  viewModifiers,
  viewLabels,
  viewProperties,
}: ExtractStreamShape<typeof viewProperties$>) =>
  Boolean(viewModifiers.length) && (
    <ViewOptionsSectionContainer>
      <ViewOptionsSectionHeader>
        <ViewOptionsSectionTitleContainer>
          <ViewOptionsSectionTitle>{`${
            viewLabels.get(mainViewId) ?? 'Current'
          } Settings`}</ViewOptionsSectionTitle>
        </ViewOptionsSectionTitleContainer>
      </ViewOptionsSectionHeader>
      <ViewOptionsSectionBody>
        {viewModifiers.map(viewModifier => (
          <ViewSettingsRow
            key={viewModifier.id}
            viewId={mainViewId}
            viewModifier={viewModifier}
            displayValue={
              displayValueGetters[mainViewId]?.[viewModifier.id]?.(
                viewProperties
              ) ?? null
            }
          />
        ))}
      </ViewOptionsSectionBody>
    </ViewOptionsSectionContainer>
  );

const viewLabels$ = availableViews$.pipe(
  map(
    availableViews =>
      new Map(
        availableViews.views.map(({ id, name }) => [id, name || 'Current'])
      )
  )
);

const viewProperties$ = combineLatest([viewModifiers$, viewLabels$]).pipe(
  map(
    ([
      { mainViewId, viewModifiers, viewProperties, viewPropertiesViewId },
      viewLabels,
    ]) => ({
      mainViewId,
      viewProperties,
      viewLabels,
      viewModifiers: getEnhancedViewModifiers(
        viewPropertiesViewId,
        viewModifiers,
        viewProperties
      ),
    })
  )
);

const ConnectedViewOptionsViewSettings = connect(
  ViewOptionsViewSettings,
  viewProperties$
);
export default ConnectedViewOptionsViewSettings;
