import { useState } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import { modal, ModalLayout, ModalTemplate } from '@ardoq/modal';
import { RadioGroup } from '@ardoq/forms';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { setTransferDirection } from 'integrations/common/streams/transferState/actions';
import { serviceNowDictionary } from 'integrations/common/dictionary';

type LoadTransferConfigModalProps = {
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
};

const LoadTransferConfigModal = ({
  onSubmit,
  onCancel,
}: LoadTransferConfigModalProps) => {
  const [configType, setConfigType] = useState<TransferDirection>(
    TransferDirection.IMPORT
  );

  const handleSaveChanges = () => {
    dispatchAction(
      setTransferDirection({
        integrationId: 'servicenow-v3',
        transferDirection: configType,
      })
    );
    onSubmit();
  };

  return (
    <ModalTemplate
      headerText="Load configuration"
      primaryButtonText="Load configuration"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handleSaveChanges}
      onSecondaryButtonClick={onCancel}
    >
      <ModalLayout>
        <RadioGroup
          onValueChange={value => {
            setConfigType(value as TransferDirection);
          }}
          value={configType}
          label="The type of configuration is unknown. How would you like to load it?"
          options={[
            {
              label: 'Import to Ardoq',
              value: TransferDirection.IMPORT,
            },
            {
              label: `Export to ${serviceNowDictionary.name}`,
              value: TransferDirection.EXPORT,
            },
          ]}
        />
      </ModalLayout>
    </ModalTemplate>
  );
};

export const startLoadTransferConfigModal = ({
  onSubmit,
}: {
  onSubmit: () => void;
}) =>
  modal<boolean>(
    resolve => (
      <LoadTransferConfigModal
        onSubmit={() => {
          resolve(true);
          onSubmit();
        }}
        onCancel={() => resolve(false)}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
