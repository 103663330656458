import { useState } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import { createWorkspaceFromTemplate, toggleFavoriteTemplate } from './actions';
import { ViewIds } from '@ardoq/api-types';
import { BLANK_WORKSPACE_MODEL_NAME } from './consts';
import WorkspaceModelBrowser from 'components/WorkspaceModelBrowser/WorkspaceModelBrowser';
import { GhostButton } from '@ardoq/button';
import { EXCEL_IMPORTER } from './createFromTemplate$';
import {
  CardContent,
  CardTitle,
  ModelLabels,
  ModelPreviewSection,
  TemplatePreviewDescription,
  TemplatePreviewFooter,
  TemplatePreviewHeader,
  TemplatePreviewImage,
} from './atoms';
import { StarBorderIcon, StarIcon } from '@ardoq/icons';
import { s16, s24 } from '@ardoq/design-tokens';
import { FavoriteableModel } from './types';
import { Header4 } from '@ardoq/typography';
import { Box } from '@ardoq/layout';

/** Illustration SVG filenames match view ID values - but sometimes there are
 * multiple variations of the same view with a different view ID. This
 * accommodates this edge case. */
const viewIDToViewIDsUsingIllustration: Record<ViewIds | string, ViewIds[]> = {
  [ViewIds.PAGESVIEW]: [ViewIds.PAGESVIEW],
  [ViewIds.RELATIONSHIPS]: [ViewIds.NETWORK, ViewIds.RELATIONSHIPS_3],
  [ViewIds.SWIMLANE]: [ViewIds.SWIMLANE],
  [ViewIds.DEPMAP]: [ViewIds.DEPMAP, ViewIds.DEPENDENCY_MAP_2],
  [ViewIds.BLOCK_DIAGRAM]: [ViewIds.BLOCK_DIAGRAM],
  [ViewIds.BUBBLE]: [ViewIds.BUBBLE],
  [ViewIds.CAPABILITY_MAP]: [ViewIds.CAPABILITY_MAP],
  [ViewIds.TIMELINE]: [ViewIds.TIMELINE],
  reader: [ViewIds.TABLEVIEW],
};

const getIllustration = (startView: string | null, templateName: string) => {
  if (templateName === 'Excel upload') return 'excel.svg';
  if (!startView) return 'illustrations/relationships.svg';

  const knownIllustrationFilename =
    startView &&
    Object.entries(viewIDToViewIDsUsingIllustration).find(
      ([_, assignedViewIds]) =>
        (assignedViewIds as string[]).includes(startView)
    )?.[0];

  const templateNamesDefaultToFallback = [BLANK_WORKSPACE_MODEL_NAME];
  const useFallback =
    !knownIllustrationFilename ||
    templateNamesDefaultToFallback.includes(templateName);

  const fileName = useFallback ? 'fallback' : knownIllustrationFilename;
  return `illustrations/${fileName}.svg`;
};

type TemplatePreviewProps = FavoriteableModel & { onClose: VoidFunction };
const TemplatePreview = ({
  flexible,
  name,
  startView,
  description,
  favorite,
  root,
  _id,
  onClose,
}: TemplatePreviewProps) => {
  const illustration = getIllustration(startView, name);
  const slug = name.toLowerCase().replace(/\s/g, '_');
  const excelImport = _id === EXCEL_IMPORTER;
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [previewPosition, setPreviewPosition] = useState<number[]>([0, 0]);
  return (
    <>
      <CardContent>
        {!flexible && (
          <ModelLabels>
            <span data-tooltip-text="Must follow the model hierarchy.">
              Rigid
            </span>
          </ModelLabels>
        )}
        <TemplatePreviewHeader>
          <CardTitle>{name}</CardTitle>
        </TemplatePreviewHeader>
        <TemplatePreviewImage
          src={`/img/${illustration}`}
          onMouseEnter={() => setShowPreview(true)}
          onMouseLeave={() => setShowPreview(false)}
          onMouseMove={e => {
            const box = (e.target as HTMLElement).getBoundingClientRect();
            setPreviewPosition([
              e.pageX - box.x - 15,
              e.pageY - box.y + box.height - 15,
            ]);
          }}
        />
        {showPreview && !excelImport && (
          <ModelPreviewSection
            style={{
              left: previewPosition[0],
              top: previewPosition[1],
              pointerEvents: 'none',
            }}
          >
            <Box paddingLeft="medium" paddingTop="medium">
              <Header4>Model Preview</Header4>
            </Box>
            <WorkspaceModelBrowser
              style={{ margin: `${s16} ${s16} ${s24}`, lineHeight: '20px' }}
              root={root}
            />
          </ModelPreviewSection>
        )}
        <hr />

        <TemplatePreviewDescription>{description}</TemplatePreviewDescription>
      </CardContent>
      <TemplatePreviewFooter>
        {excelImport ? (
          <span />
        ) : (
          <GhostButton
            onClick={() =>
              dispatchAction(toggleFavoriteTemplate({ templateId: _id }))
            }
            title={favorite ? 'Remove from favorites' : 'Add to favorites'}
          >
            {favorite ? <StarIcon /> : <StarBorderIcon />}
          </GhostButton>
        )}

        <GhostButton
          data-template-id={_id}
          data-click-id={slug}
          onClick={() => {
            onClose();
            dispatchAction(createWorkspaceFromTemplate({ templateId: _id }));
          }}
        >
          Choose
        </GhostButton>
      </TemplatePreviewFooter>
    </>
  );
};
export default TemplatePreview;
