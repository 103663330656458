import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { combineLatest, switchMap } from 'rxjs';
import { getSelectDataStepState } from './utils';
import { WaitingOverlay } from 'integrations/common/components/overlays/WaitingOverlay';
import Flex from 'atomicComponents/Flex';
import { FlowHeader } from 'integrations/common/components/flowHeader/FlowHeader';
import { Step } from '@ardoq/steppers';
import TabularConfigMapping from 'integrations/common/pages/tabularConfigMapping/TabularConfigMapping';
import {
  TransferDirection,
  TransferState,
} from 'integrations/common/streams/transferState/types';
import { ReviewTest } from 'integrations/common/pages/reviewTest/ReviewTest';
import { ImportAndSchedule } from 'integrations/common/pages/importAndSchedule/ImportAndSchedule';
import { Overview } from 'integrations/common/containers/overview/Overview';
import { SelectData } from './pages/selectData/SelectData';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { selectionState$ } from 'integrations/azure/streams/selectionState/selectionState$';
import { SelectionState } from 'integrations/azure/streams/selectionState/types';
import {
  getConfigureStepState,
  getFlowTitle,
  getReviewStepState,
  getScheduleStepState,
  getScheduleStepTitle,
} from 'integrations/common/utils/step';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import {
  IntegrationId,
  TabularMapping,
} from 'integrations/common/streams/tabularMappings/types';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { startTransferConfigsModal } from 'integrations/common/modals/transferConfigsModal/TransferConfigsModal';
import { ensureCloudProvider } from 'integrations/cloudProviders/utils';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import { RegionsState } from 'integrations/cloudProviders/streams/regions/types';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { ResourceTypesState } from 'integrations/cloudProviders/streams/resourceTypes/types';
import { startSaveModal } from 'integrations/common/modals/saveModal/SaveModal';
import { Maybe } from '@ardoq/common-helpers';
import { saveActiveConfiguration } from 'integrations/common/streams/transferConfigs/actions';
import { TablePreviews } from 'integrations/common/streams/tablePreviews/types';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import {
  getSchedulesStream,
  SchedulesState,
} from 'integrations/common/streams/schedules/getSchedulesStream';
import {
  getScheduleStream,
  ScheduleState,
} from 'integrations/common/streams/schedule/schedule$';
import { TransferConfigsState } from 'integrations/common/streams/transferConfigs/types';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { OverlayState, overlay$ } from './streams/overlay$';
import { isStepAvailable } from './utils';
import { isValidSelectionState } from './streams/selectionState/utils';
import { resourceGroups$ } from './streams/resourceGroups/resourceGroups$';
import { ResourceGroupsState } from './streams/resourceGroups/types';
import {
  ImportRoute,
  OverviewRoute,
  RoutePath,
} from 'integrations/common/navigation/types';
import { navigateToPath } from 'integrations/common/navigation/actions';
import {
  isOverviewRoute,
  isPathReady,
} from 'integrations/common/navigation/utils';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';
import { Stepper } from 'integrations/common/components/stepper';
import { StickyFooter } from 'integrations/common/containers/stickyFooter/StickyFooter';
import { PageBody } from '@ardoq/page-layout';
import { FlowContainer } from 'integrations/common/components/flowContainer/FlowContainer';
import { loadSelection } from 'integrations/common/streams/selectionState/actions';
import { azureDictionary } from 'integrations/common/dictionary';

type AzureProps = {
  activeIntegration: ActiveIntegrationState;
  selectionState: SelectionState;
  connectionsState: IntegrationConnectionsState;
  tabularMapping: TabularMapping;
  transferState: TransferState;
  regionsState: RegionsState;
  resourceGroupsState: ResourceGroupsState;
  resourceTypesState: ResourceTypesState;
  tablePreviews: TablePreviews;
  schedule: ScheduleState;
  schedules: SchedulesState;
  transferConfigs: TransferConfigsState;
  overlay: OverlayState;
};

const handleSaveSelectedConfig = ({
  selectedTransferConfigId,
}: {
  selectedTransferConfigId: Maybe<string>;
}) => {
  if (!selectedTransferConfigId) {
    startSaveModal({
      integrationId: 'azure-v3',
      title: 'Save as a new version',
      isNewConfig: true,
    });

    return;
  }
  dispatchAction(saveActiveConfiguration({ integrationId: 'azure-v3' }));
};

const handleSaveAs = () => {
  startSaveModal({
    integrationId: 'azure-v3',
    title: 'Save as a new version',
    isNewConfig: true,
  });
};

const handleStepClickNavigation = (
  integrationId: IntegrationId,
  path: ImportRoute
) => {
  dispatchAction(
    navigateToPath({
      integrationId,
      path,
      source: 'stepper',
    })
  );
};

const handleRestart = () => {
  dispatchAction(resetIntegration('azure-v3'));
};

const handleStartTransferConfigsModal = () => {
  dispatchAction(
    trackIntegrationEvent({
      integrationId: 'azure-v3',
      name: 'CLICKED_MANAGE_CONFIGURATION',
    })
  );
  startTransferConfigsModal();
};

const Import = ({
  activeIntegration: {
    integrationId,
    integrationPath,
    selectedTransferConfigId,
  },
  selectionState,
  connectionsState: { selectedConnectionIds },
  tabularMapping,
  transferState,
  regionsState,
  resourceGroupsState,
  resourceTypesState,
  tablePreviews,
  schedule,
  schedules: { schedules },
  transferConfigs,
  overlay,
}: AzureProps) => {
  const isDisabledStep = (path: ImportRoute) =>
    !isPathReady(path, {
      tabularMapping,
      tablePreviews,
      transferState,
      integrationId: 'azure-v3',
    }) ||
    !isStepAvailable(path, {
      selectionState,
      tabularMapping,
      transferState,
      resourceTypesState,
      resourceGroupsState,
      regionsState,
    });

  const isDataSelectionValid = isValidSelectionState({
    selectionState,
    selectedConnectionIds,
    regionsState,
    resourceGroupsState,
    resourceTypesState,
  });
  const onDataSelectionNext = () =>
    dispatchAction(loadSelection(integrationId));

  return (
    <PageBody
      padding={0}
      backgroundColor="bgDefault"
      headerContent={
        <FlowHeader
          title={getFlowTitle({
            selectedTransferConfigId,
            transferConfigs: transferConfigs.configs,
            prefix: azureDictionary.name,
            loadedScheduleId: schedule.loadedScheduleId,
            schedules,
          })}
          onSave={() => handleSaveSelectedConfig({ selectedTransferConfigId })}
          onSaveAs={handleSaveAs}
          canRestart={true}
          isSavingDisabled={
            !isValidSelectionState({
              selectionState,
              selectedConnectionIds: selectedConnectionIds,
              regionsState,
              resourceGroupsState,
              resourceTypesState,
            })
          }
          onRestart={handleRestart}
          onStartTransferConfigsModal={handleStartTransferConfigsModal}
          transferDirection={TransferDirection.IMPORT}
          disabledConfigurationActions={Boolean(schedule.loadedScheduleId)}
        />
      }
      footerContent={
        <StickyFooter
          integrationName={azureDictionary.name}
          isDataSelectionValid={isDataSelectionValid}
          onDataSelectionNext={onDataSelectionNext}
        />
      }
    >
      <FlowContainer>
        {overlay.isLoading && (
          <WaitingOverlay>{overlay.message}</WaitingOverlay>
        )}
        <Flex $direction="column">
          <Stepper>
            <Step
              onPress={() =>
                handleStepClickNavigation(
                  integrationId,
                  ImportRoute.SELECT_DATA
                )
              }
              isSelected={integrationPath === ImportRoute.SELECT_DATA}
              isDisabled={isDisabledStep(ImportRoute.SELECT_DATA)}
              heading="Select Data"
              state={getSelectDataStepState({
                integrationPath,
                selectionState,
                selectedConnectionIds: selectedConnectionIds,
                regionsState,
                resourceGroupsState,
                resourceTypesState,
              })}
            />
            <Step
              onPress={() =>
                handleStepClickNavigation(integrationId, ImportRoute.CONFIGURE)
              }
              isSelected={integrationPath === ImportRoute.CONFIGURE}
              isDisabled={isDisabledStep(ImportRoute.CONFIGURE)}
              heading="Configure data"
              state={getConfigureStepState({
                integrationPath,
                tabularMapping,
                configureRoute: ImportRoute.CONFIGURE,
              })}
            />
            <Step
              onPress={() =>
                handleStepClickNavigation(integrationId, ImportRoute.REVIEW)
              }
              isSelected={integrationPath === ImportRoute.REVIEW}
              isDisabled={isDisabledStep(ImportRoute.REVIEW)}
              heading="Review import"
              state={getReviewStepState({ transferState })}
            />
            <Step
              onPress={() =>
                handleStepClickNavigation(
                  integrationId,
                  ImportRoute.IMPORT_AND_SCHEDULE
                )
              }
              isSelected={integrationPath === ImportRoute.IMPORT_AND_SCHEDULE}
              isDisabled={isDisabledStep(ImportRoute.IMPORT_AND_SCHEDULE)}
              heading={getScheduleStepTitle(schedule.scheduleStage)}
              state={getScheduleStepState({ transferState, schedule })}
            />
          </Stepper>
        </Flex>

        {integrationPath === ImportRoute.SELECT_DATA && <SelectData />}
        {integrationPath === ImportRoute.CONFIGURE && <TabularConfigMapping />}
        {integrationPath === ImportRoute.REVIEW && <ReviewTest />}
        {integrationPath === ImportRoute.IMPORT_AND_SCHEDULE && (
          <ImportAndSchedule />
        )}
      </FlowContainer>
    </PageBody>
  );
};

const Azure = (props: AzureProps) => {
  const integrationPath = props.activeIntegration.integrationPath as RoutePath;
  if (!integrationPath || isOverviewRoute(integrationPath)) {
    return (
      <Overview
        integrationPath={integrationPath}
        initialTab={OverviewRoute.CONNECTIONS}
      />
    );
  }

  return <Import {...props} />;
};

const viewModel$ = integrationId$.pipe(
  ensureCloudProvider(),
  switchMap(integrationId =>
    combineLatest({
      activeIntegration: getActiveIntegrationStream(integrationId),
      selectionState: selectionState$,
      connectionsState: getConnectionsStream(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
      transferState: getTransferStateStream(integrationId),
      regionsState: getRegionsStream(integrationId),
      resourceGroupsState: resourceGroups$,
      resourceTypesState: getResourceTypesStream(integrationId),
      tablePreviews: getTablePreviewsStream(integrationId),
      schedules: getSchedulesStream(integrationId),
      schedule: getScheduleStream(integrationId),
      transferConfigs: transferConfigs$,
      overlay: overlay$,
    })
  )
);

export default connect(Azure, viewModel$);
