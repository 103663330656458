import {
  isCreatedItemsLoadedState,
  isSearchLoadedState,
  isManualComponentSelection,
  LoadedState,
  ViewpointBuilderFilters,
  isAnyTraversalLoadedState,
} from '@ardoq/api-types';
import { pluralize } from '@ardoq/common-helpers';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { uniq, sumBy } from 'lodash';

const _getComponentSummary = (componentIds: string[]) => {
  if (componentIds.length === 0) return;
  const count = componentIds.length;
  const typeCount = uniq(
    componentIds.map(id => componentInterface.getTypeId(id))
  ).length;

  return `${count} ${pluralize('component', count)} of ${
    typeCount ?? 1
  } ${pluralize('type', typeCount)}`;
};

const _getReferenceSummary = (referenceIds: string[]) => {
  if (referenceIds.length === 0) return;
  const count = referenceIds.length;
  const typeCount = uniq(
    referenceIds.map(id => referenceInterface.getTypeId(id))
  ).length;

  return `${count} ${pluralize('reference', count)} of ${
    typeCount ?? 1
  } ${pluralize('type', typeCount)}`;
};

const _getFilterSummary = (filters: ViewpointBuilderFilters) => {
  const count = sumBy(
    Object.values(filters ?? {}),
    ({ rules: { length } }) => length
  );
  if (count === 0) return;
  return `${count} ${pluralize('filter', count)} applied`;
};

export const getViewpointNavigatorEntrySummary = (entry: LoadedState) => {
  if (isSearchLoadedState(entry)) {
    const componentIds = isManualComponentSelection(
      entry.data.componentSelection
    )
      ? entry.data.componentSelection.startSet
      : (entry.componentIdsAndReferences?.componentIds ?? []);
    return {
      componentSummary: _getComponentSummary(componentIds),
    };
  }
  if (isCreatedItemsLoadedState(entry)) {
    return {
      componentSummary: _getComponentSummary(entry.data.componentIds),
      referenceSummary: _getReferenceSummary(entry.data.referenceIds),
    };
  }
  if (isAnyTraversalLoadedState(entry)) {
    if (!entry.componentIdsAndReferences) {
      return {
        componentSummary: 'Getting summary...',
      };
    }
    if (
      !entry.componentIdsAndReferences.componentIds.length &&
      !entry.componentIdsAndReferences.references.length
    ) {
      return null;
    }
    return {
      componentSummary: _getComponentSummary(
        entry.componentIdsAndReferences.componentIds
      ),
      referenceSummary: _getReferenceSummary([
        ...entry.componentIdsAndReferences.references.map(r => r._id),
        ...entry.componentIdsAndReferences.parentChildReferences.map(
          r => r._id
        ),
      ]),
      filterSummary: _getFilterSummary(entry.data.filters),
    };
  }
  return {
    componentSummary: `Couldn't get summary`,
  };
};
