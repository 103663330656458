import { IconName } from '@ardoq/icons';
import { Tag, StatusType } from '@ardoq/status-ui';
import { pluralize } from '@ardoq/common-helpers';
import { ComponentWithPendingChanges } from '../types';
import { text2Bold } from '@ardoq/typography';
import styled from 'styled-components';

type StatusCellProps = {
  component: ComponentWithPendingChanges;
};

export const StatusCell = ({ component }: StatusCellProps) => {
  if (component.changeAction === 'no_change')
    return (
      <Tag
        label="Invalid change"
        leftIcon={{ name: IconName.DIFF_EMPTY }}
        statusType={StatusType.WARNING}
      />
    );
  if (component.changeAction === 'create')
    return (
      <Tag
        label="Created"
        iconName={IconName.CHECK_CIRCLE}
        statusType={StatusType.SUCCESS}
      />
    );
  if (component.changeAction === 'update')
    return (
      <Tag
        label="Updated"
        iconName={IconName.UPDATE}
        statusType={StatusType.INFO}
      />
    );
  return (
    <Tag
      label="Deleted"
      iconName={IconName.DELETE}
      statusType={StatusType.ERROR}
    />
  );
};

const Bold = styled.span`
  ${text2Bold}
`;

type ChangesCellProps = {
  component: ComponentWithPendingChanges;
};

export const ChangesCell = ({ component }: ChangesCellProps) => {
  if (
    component.changeAction === 'update' ||
    component.changeAction === 'no_change'
  ) {
    const count = component.numberOfChanges;
    return (
      <span>
        {count === 0 ? (
          'No changes'
        ) : (
          <>
            <Bold>{count}</Bold> {pluralize('change', count)}
          </>
        )}
      </span>
    );
  }
  return (
    <span>
      <Bold>1</Bold> component {component.changeAction}d
    </span>
  );
};
