import { colors } from '@ardoq/design-tokens';
import { ErrorNotification } from '@ardoq/status-ui';
import styled from 'styled-components';

interface Status {
  success: boolean;
  message: string;
}

const StatusBase = styled.div`
  padding: 8px 0;
`;

const StatusSuccess = styled(StatusBase)`
  color: ${colors.green50};
`;

export function isStatus(input: any): input is Status {
  return (
    input instanceof Object &&
    Object.prototype.hasOwnProperty.call(input, 'success') &&
    typeof input.success === 'boolean' &&
    Object.prototype.hasOwnProperty.call(input, 'message') &&
    typeof input.message === 'string'
  );
}

interface StatusMessageProps {
  status?: Status | false;
}
const StatusMessage = ({ status }: StatusMessageProps) => (
  <>
    {!status || !status.message ? null : status.success ? (
      <StatusSuccess>{status.message}</StatusSuccess>
    ) : (
      <ErrorNotification>{status.message}</ErrorNotification>
    )}
  </>
);

export default StatusMessage;
