import { logError } from '@ardoq/logging';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  addIPEntryToState,
  createIPEntry,
  deleteIPEntry,
  fetchIPEntries,
  removeIPEntryFromState,
  setFetchedIPEntries,
  updateEntryInState,
  updateIPEntry,
} from './actions';
import { parseIPAddress, setIPError, setIPSuccess } from '../utils';
import { trackStreamEvent } from 'tracking/tracking';
import { omit } from 'lodash';
import { allowlistApi, handleError } from '@ardoq/api';
import { ArdoqError, getArdoqErrorMessage } from '@ardoq/common-helpers';

const logAndSetIPError = () => (error: ArdoqError) => {
  logError(error);
  setIPError(getArdoqErrorMessage(error));
};

const handleFetch = routine(
  ofType(fetchIPEntries),
  switchMap(allowlistApi.fetchAll),
  handleError(logAndSetIPError()),
  tap(response => dispatchAction(setFetchedIPEntries(response)))
);

const handleCreate = routine(
  ofType(createIPEntry),
  extractPayload(),
  trackStreamEvent(({ whitelistType }) => ({
    eventName: 'Create new IP allowlist entry',
    metadata: { allowListType: whitelistType },
  })),
  map(payload => ({
    ...payload,
    ...omit(parseIPAddress(payload.address), 'maskSizeSource'),
  })),
  switchMap(allowlistApi.create),
  handleError(logAndSetIPError),
  tap(response => {
    dispatchAction(addIPEntryToState(response));
    setIPSuccess(`Added address "${response.address}" to allowlist.`);
  })
);

const handleDelete = routine(
  ofType(deleteIPEntry),
  extractPayload(),
  trackStreamEvent(() => ({ eventName: 'Delete IP allowlist entry' })),
  switchMap(allowlistApi.delete),
  handleError(logAndSetIPError),
  tap(id => dispatchAction(removeIPEntryFromState(id)))
);

const handleUpdate = routine(
  ofType(updateIPEntry),
  extractPayload(),
  trackStreamEvent(() => ({ eventName: 'Update IP allowlist entry' })),
  switchMap(allowlistApi.update),
  handleError(logAndSetIPError),
  tap(response => dispatchAction(updateEntryInState(response)))
);

export default collectRoutines(
  handleFetch,
  handleCreate,
  handleDelete,
  handleUpdate
);
