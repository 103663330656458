import { DragAndDropSectionProps } from '@ardoq/drag-and-drop';
import { LockIcon } from '@ardoq/icons';
import { Text, Link } from '@ardoq/typography';
import styled from 'styled-components';
import { PresentationDragAndDropCardWrapper, SlideContainer } from './atoms';
import { colors } from '@ardoq/design-tokens';
import { Box, Stack, FlexBox } from '@ardoq/layout';

const UnauthorizedSlideContainer = styled(SlideContainer)`
  background-color: ${colors.surfaceDefault};
  color: ${colors.textSubtle};
`;

const UnauthorizedSlide = ({ dragAndDropCardId }: DragAndDropSectionProps) => (
  <PresentationDragAndDropCardWrapper dragAndDropCardId={dragAndDropCardId}>
    <UnauthorizedSlideContainer>
      <Box padding="medium">
        <Stack gap="small">
          <FlexBox align="center" justify="space-between">
            <Text color="textSubtle" variant="text2">
              You don&apos;t have access to this slide.
            </Text>
            <LockIcon />
          </FlexBox>
          <Link
            onClick={() =>
              window.open(
                'https://help.ardoq.com/en/articles/44085-presentations-permissions',
                '_blank'
              )
            }
            typography="text2"
          >
            Learn more
          </Link>
        </Stack>
      </Box>
    </UnauthorizedSlideContainer>
  </PresentationDragAndDropCardWrapper>
);

export default UnauthorizedSlide;
