import { map } from 'rxjs';
import { document$ } from '../streams/document/document$';
import { dispatchAction } from '@ardoq/rxbeach';
import { selectDocument } from '../streams/document/actions';

export const viewModel$ = document$.pipe(
  map(document => {
    return {
      document,
      onSelectDocument: () => {
        dispatchAction(selectDocument({ attemptCount: 0 }));
      },
    };
  })
);
