import { isNumber } from 'lodash';
import { SelectOption } from '@ardoq/select';
import { isPerspectiveGroupingRuleWithWorkspace } from '@ardoq/perspectives-sidebar';
import {
  PerspectiveGroupingRule,
  PerspectivesGroupsOptions,
  GroupingRuleSelectOptions,
  ParentGroupingRule,
  ReferenceGroupingRule,
  FieldGroupingRule,
  ChildTypeGroupingRule,
  TagGroupingRule,
} from '@ardoq/perspectives';
import { ArdoqId, GroupType } from '@ardoq/api-types';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { fieldInterface } from '@ardoq/field-interface';
import { dateRangeOperations } from '@ardoq/date-range';
import tags$ from 'streams/tags/tags$';
import { exhaustiveCheck } from '@ardoq/common-helpers';

type ValidGroupOptionIds = {
  validWorkspaceIds: ArdoqId[];
  validComponentTypeIds: ArdoqId[];
  validReferenceTypeIds: number[];
  validFieldIds: ArdoqId[];
  validTagIds: ArdoqId[];
};

const getValidGroupOptionIds = (groupsOptions: PerspectivesGroupsOptions) => {
  const validWorkspaceIds = groupsOptions.workspaces.map(
    workspace => workspace.id
  );
  const validComponentTypeIds = Object.values(
    groupsOptions.componentTypesPerWorkspace
  )
    .flat()
    .map(componentType => componentType.id);

  const validReferenceTypeIds = Object.values(
    groupsOptions.referenceTypesPerWorkspace
  )
    .flat()
    .map(referenceType => referenceType.id);

  const validFieldIds = Object.values(groupsOptions.fieldsPerWorkspace)
    .flat()
    .map(field => field.id);

  const validTagIds = Object.values(groupsOptions.tagsPerWorkspace)
    .flat()
    .map(tag => tag.id);

  return {
    validWorkspaceIds,
    validComponentTypeIds,
    validReferenceTypeIds,
    validFieldIds,
    validTagIds,
  };
};

const isGroupingRuleBasedOnValidOptions = (
  groupingRule: PerspectiveGroupingRule,
  validGroupOptionIds: ValidGroupOptionIds
) => {
  const {
    validWorkspaceIds,
    validComponentTypeIds,
    validReferenceTypeIds,
    validFieldIds,
    validTagIds,
  } = validGroupOptionIds;

  switch (groupingRule.type) {
    case GroupType.WORKSPACE:
    case GroupType.COMPONENT:
    case GroupType.SUBDIVISION:
    case GroupType.PARENT_ALL:
      return true;

    case GroupType.REFERENCE:
      return hasValidWorkspaceIdAndTargetId(
        groupingRule,
        validWorkspaceIds,
        validReferenceTypeIds.map(id => id?.toString())
      );
    case GroupType.PARENT:
      return hasValidWorkspaceIdAndTargetId(
        groupingRule,
        validWorkspaceIds,
        validComponentTypeIds
      );
    case GroupType.TAG:
      return hasValidWorkspaceIdAndTargetId(
        groupingRule,
        validWorkspaceIds,
        validTagIds
      );
    case GroupType.FIELD:
      return hasValidWorkspaceIdAndTargetId(
        groupingRule,
        validWorkspaceIds,
        validFieldIds
      );
    case GroupType.CHILD:
      return hasValidWorkspaceIdAndTargetId(
        groupingRule,
        validWorkspaceIds,
        validComponentTypeIds
      );
    default:
      return exhaustiveCheck(groupingRule);
  }
};

const hasValidWorkspaceIdAndTargetId = (
  groupingRule:
    | ParentGroupingRule
    | ReferenceGroupingRule
    | FieldGroupingRule
    | ChildTypeGroupingRule
    | TagGroupingRule,
  validWorkspaceIds: ArdoqId[],
  validTargetIds: (ArdoqId | number)[]
) =>
  validWorkspaceIds.includes(groupingRule.workspaceId) &&
  (!groupingRule.targetId || validTargetIds.includes(groupingRule.targetId));

const getGroupingRulesBasedOnInvalidOptions = (
  groupingRules: PerspectiveGroupingRule[],
  groupsOptions: PerspectivesGroupsOptions
): PerspectiveGroupingRule[] => {
  const validGroupOptionIds = getValidGroupOptionIds(groupsOptions);
  return groupingRules.filter(
    groupingRule =>
      !isGroupingRuleBasedOnValidOptions(groupingRule, validGroupOptionIds)
  );
};

const buildOption = (value: string, label: string): SelectOption<string> => ({
  value,
  label,
  isDisabled: true,
});

export const getInvalidGroupingRuleSelectOptionsByRuleId = (
  groupingRules: PerspectiveGroupingRule[],
  groupingOptions: PerspectivesGroupsOptions
) => {
  const invalidGroupingRules = getGroupingRulesBasedOnInvalidOptions(
    groupingRules,
    groupingOptions
  );

  return invalidGroupingRules.reduce<Record<string, GroupingRuleSelectOptions>>(
    (options, rule) => {
      if (!isPerspectiveGroupingRuleWithWorkspace(rule)) {
        return options;
      }

      const workspaceName = workspaceInterface.getWorkspaceName(
        rule.workspaceId
      );
      if (!workspaceName) {
        return options;
      }

      const hasValidWorkspace = groupingOptions.workspaces.some(
        workspace => workspace.id === rule.workspaceId
      );

      const workspaceSelectOption = !hasValidWorkspace
        ? buildOption(rule.workspaceId, workspaceName)
        : undefined;

      if (workspaceSelectOption) {
        options[rule.id] = {
          workspaceSelectOption,
        };
      }

      const targetId = rule.targetId;

      if (!targetId) {
        return {
          ...options,
          [rule.id]: {
            workspaceSelectOption,
          },
        };
      }

      const addRuleTargetSelectOption = (label: string) => {
        options[rule.id] = {
          ...(options[rule.id] || {}),
          ruleTargetSelectOption: buildOption(targetId, label),
        };
      };

      switch (rule.type) {
        case GroupType.REFERENCE: {
          const modelId = workspaceInterface.getWorkspaceModelId(
            rule.workspaceId
          );
          const referenceType = Number(rule.targetId);
          const referenceTypeName =
            modelId && isNumber(referenceType)
              ? referenceInterface.getReferenceTypeName(modelId, referenceType)
              : null;

          if (referenceTypeName) {
            addRuleTargetSelectOption(referenceTypeName);
          }
          break;
        }
        case GroupType.PARENT: {
          const componentType = workspaceInterface.getTypeById(
            rule.workspaceId,
            targetId
          );
          if (componentType) {
            addRuleTargetSelectOption(componentType.name);
          }
          break;
        }
        case GroupType.TAG: {
          const tag = rule.targetId
            ? tags$.state.tagsById[rule.targetId]
            : null;
          if (tag) {
            addRuleTargetSelectOption(tag.name);
          }
          break;
        }
        case GroupType.FIELD: {
          const field = fieldInterface.getFieldData(rule.targetId);
          if (field) {
            addRuleTargetSelectOption(
              dateRangeOperations.dashFormatDateRangeFieldLabel(field.label)
            );
          }
          break;
        }
        default:
          exhaustiveCheck(rule);
      }

      return options;
    },
    {}
  );
};
