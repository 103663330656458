import {
  CreateServiceAccountAndTokenPayload,
  OrgAccessLevel,
} from '@ardoq/api-types';
import { PrimaryButton, SecondaryButton } from '@ardoq/button';
import { s16 } from '@ardoq/design-tokens';
import {
  FormSize,
  GroupFieldLayout,
  RadioGroup,
  RadioGroupItem,
  TextInput,
} from '@ardoq/forms';
import { ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { useState } from 'react';
import { RadioItemLabel } from 'roles/atoms';
import styled from 'styled-components';
import { Paragraph } from '@ardoq/typography';
import { WithPopover } from '@ardoq/popovers';
import { Box, FlexBox } from '@ardoq/layout';
import { serviceAccountsOperations } from './serviceAccountsOperations';

type ServiceAccountCreationProps = {
  roles: { label: string; value: OrgAccessLevel; key: OrgAccessLevel }[];
  onSubmit: (input: CreateServiceAccountAndTokenPayload) => void;
  onCancel: () => void;
};

const Footer = styled.div`
  display: flex;
  gap: ${s16};
`;

const ServiceAccountCreationModal = ({
  onCancel,
  onSubmit,
  roles,
}: ServiceAccountCreationProps) => {
  const [input, setInput] = useState({
    name: '',
    role: roles[0].value,
    tokenDescription: '',
  });
  return (
    <ModalTemplate
      headerText="Create new service account"
      modalSize={ModalSize.M}
      footerContent={
        <Footer>
          <SecondaryButton onClick={() => onCancel()}>Cancel</SecondaryButton>
          <WithPopover
            content={serviceAccountsOperations.getDisabledCreateServiceAccountButtonHintText(
              input.name,
              input.tokenDescription
            )}
          >
            <Box>
              <PrimaryButton
                onClick={() => onSubmit(input)}
                isDisabled={!input.name || !input.tokenDescription}
              >
                Create service account
              </PrimaryButton>
            </Box>
          </WithPopover>
        </Footer>
      }
    >
      <ModalLayout>
        <Paragraph variant="text2">
          Set up a new service account to securely manage API tokens.
        </Paragraph>
        <TextInput
          label="Service account name"
          value={input.name}
          formSize={FormSize.DEFAULT}
          onValueChange={value => setInput({ ...input, name: value })}
          placeholder="Untitled"
        />
        <FlexBox justify="space-between" align="start">
          <RadioGroup
            label="Service account role"
            value={input.role}
            onValueChange={value =>
              setInput({ ...input, role: value as OrgAccessLevel })
            }
            groupFieldLayout={GroupFieldLayout.VERTICAL}
          >
            {roles.map(({ key, value, label }) => (
              <RadioGroupItem key={key} value={value}>
                <RadioItemLabel label={label} />
              </RadioGroupItem>
            ))}
          </RadioGroup>
          <Box
            paddingX="large"
            paddingY="medium"
            borderRadius="r8"
            backgroundColor="bgSubtle"
            style={{ width: '335px' }}
          >
            <Paragraph color="textSubtle" variant="button">
              {serviceAccountsOperations.getLabelForRole(input.role)}:{' '}
              {serviceAccountsOperations.getRoleEmojiCode(input.role)}
            </Paragraph>
            <Paragraph variant="text2" color="textSubtle">
              {serviceAccountsOperations.getRoleHelperText(input.role)}
            </Paragraph>
          </Box>
        </FlexBox>
        <TextInput
          label="Token description"
          value={input.tokenDescription}
          formSize={FormSize.DEFAULT}
          onValueChange={value =>
            setInput({ ...input, tokenDescription: value })
          }
          placeholder="Untitled"
        />
      </ModalLayout>
    </ModalTemplate>
  );
};

export default ServiceAccountCreationModal;
