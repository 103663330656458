import {
  APIFieldAttributes,
  ArdoqId,
  PersistedFieldSubQuestion,
  UnpersistedFieldSubQuestion,
} from '@ardoq/api-types';
import {
  AllPossibleQuestions,
  SurveyQuestionValidations,
} from 'surveyAdmin/types';
import { Position } from '@ardoq/drag-and-drop';
import DependentQuestion from './DependentQuestion';
import { PermissionContext } from '@ardoq/access-control';

type DependentQuestionTypes =
  | PersistedFieldSubQuestion
  | UnpersistedFieldSubQuestion;

type DraggableDependentQuestionProps = {
  questionId?: string;
  index: number;
  question: DependentQuestionTypes;
  questions?: AllPossibleQuestions[];
  parentQuestionField: APIFieldAttributes;
  fields: APIFieldAttributes[];
  attributes: {
    label: string;
    attributeName: string;
  }[];
  workspaceId: ArdoqId | null;
  questionValidations?: SurveyQuestionValidations[];
  surveyComponentTypeId?: string;
  parentQuestionTitle: string;
  expandedQuestion?: string;
  dragAndDropCardId: string;
  dependentQuestions: DependentQuestionTypes[];
  parentQuestionIsReadonly?: boolean;
  permissionsContext: PermissionContext;
  surveyIsPermissionDivisionsAware: boolean;
  hasSurveyResponseApprovalsFeature: boolean;
  updateQuestion: (dependentQuestions: DependentQuestionTypes[]) => void;
  expandQuestion: (question?: DependentQuestionTypes) => void;
  handleOrderChange: (id: string, position: Position) => void;
};

const DraggableDependentQuestion = ({
  questionId,
  index,
  question,
  questions,
  parentQuestionField,
  fields,
  attributes,
  workspaceId,
  questionValidations,
  surveyComponentTypeId,
  parentQuestionTitle,
  parentQuestionIsReadonly,
  expandedQuestion,
  dependentQuestions,
  dragAndDropCardId,
  permissionsContext,
  surveyIsPermissionDivisionsAware,
  hasSurveyResponseApprovalsFeature,
  expandQuestion,
  handleOrderChange,
  updateQuestion,
}: DraggableDependentQuestionProps) => {
  const updateDependentQuestion = (updatedQuestion: DependentQuestionTypes) => {
    updateQuestion([
      ...dependentQuestions.slice(0, index),
      updatedQuestion,
      ...dependentQuestions.slice(index + 1),
    ]);
  };

  const removeQuestion = () => {
    updateQuestion([
      ...dependentQuestions.slice(0, index),
      ...dependentQuestions.slice(index + 1),
    ]);
    if (expandedQuestion === questionId) {
      expandQuestion(undefined);
    }
  };

  return (
    <DependentQuestion
      question={question}
      index={index}
      fields={fields}
      attributes={attributes}
      questions={[...(questions ?? []), ...dependentQuestions]}
      workspaceId={workspaceId}
      parentQuestionTitle={parentQuestionTitle}
      questionValidations={questionValidations}
      surveyComponentTypeId={surveyComponentTypeId}
      isExpanded={questionId === expandedQuestion}
      parentQuestionField={parentQuestionField}
      dragAndDropCardId={dragAndDropCardId}
      permissionsContext={permissionsContext}
      parentQuestionIsReadonly={parentQuestionIsReadonly}
      surveyIsPermissionDivisionsAware={surveyIsPermissionDivisionsAware}
      hasSurveyResponseApprovalsFeature={hasSurveyResponseApprovalsFeature}
      expandQuestion={expandQuestion}
      updateDependentQuestion={updateDependentQuestion}
      removeQuestion={removeQuestion}
      handleOrderChange={handleOrderChange}
    />
  );
};

export default DraggableDependentQuestion;
