import styled from 'styled-components';
import { StatusType, Tag } from '@ardoq/status-ui';
import { s8 } from '@ardoq/design-tokens';
import { Counts, ImportHistory } from '@ardoq/api-types/integrations';
import { IconName } from '@ardoq/icons';

const CellWrapper = styled.span`
  display: flex;
  gap: ${s8};
`;

type SummaryCellProps = {
  importHistory: ImportHistory;
  hasIcon?: boolean;
  onClick?: () => void;
};

const sumImportByType = (
  importHistory: ImportHistory,
  type: 'created' | 'deleted' | 'updated'
): number => {
  const counts: Counts = importHistory.summary[type] || {};
  return Object.values(counts).reduce((acc: number, i: number) => acc + i, 0);
};

const SummaryCell = ({
  importHistory,
  onClick,
  hasIcon = false,
}: SummaryCellProps) => {
  const createdCount = sumImportByType(importHistory, 'created');
  const deletedCount = sumImportByType(importHistory, 'deleted');
  const updatedCount = sumImportByType(importHistory, 'updated');

  return (
    <CellWrapper>
      <div data-tooltip-text="Created">
        <Tag
          statusType={StatusType.SUCCESS}
          leftIcon={hasIcon ? { name: IconName.CHECK_MARK } : undefined}
          onClick={onClick}
          label={hasIcon ? String(createdCount) : `Created: ${createdCount}`}
        />
      </div>
      <div data-tooltip-text="Deleted">
        <Tag
          statusType={StatusType.ERROR}
          label={hasIcon ? String(deletedCount) : `Deleted: ${deletedCount}`}
          leftIcon={hasIcon ? { name: IconName.DELETE } : undefined}
          onClick={onClick}
        />
      </div>
      <div data-tooltip-text="Updated">
        <Tag
          statusType={StatusType.INFO}
          label={hasIcon ? String(updatedCount) : `Updated: ${updatedCount}`}
          leftIcon={hasIcon ? { name: IconName.REFRESH } : undefined}
          onClick={onClick}
        />
      </div>
    </CellWrapper>
  );
};

export default SummaryCell;
