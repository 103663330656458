import { APIReferenceAttributes, ArdoqId, ArrowType } from '@ardoq/api-types';
import { Reference as ReferenceBackboneModel } from 'aqTypes';
import { referenceDescriptorsToGraphData } from './referenceDescriptorsToGraphData';
import { ReferenceLabelSource } from '@ardoq/data-model';
import { referenceLabelOperations } from '@ardoq/reference-operations';
import { fieldInterface } from '@ardoq/field-interface';
import { fieldUtils } from '@ardoq/scope-data';
import { formatUserField } from './formatUserField';
import { referenceInterface } from '@ardoq/reference-interface';
import { filterInterface } from '@ardoq/filter-interface';

const getReferenceType = (
  referenceId: ArdoqId
): {
  id: string;
  name: string;
} => {
  const refType = referenceInterface.getGlobalReferenceType(referenceId);
  if (refType) {
    return refType;
  }
  return { id: '', name: '' };
};

export const referenceListToGraphData = (
  referenceList: ReferenceBackboneModel[]
) => {
  const referenceDescriptors = referenceList.map(
    (reference: ReferenceBackboneModel) => {
      const referenceId = reference.id || reference.cid;
      const { id: referenceTypeId, name: referenceTypeName } =
        getReferenceType(referenceId);
      return {
        referenceId,
        referenceTypeId,
        referenceTypeName,
        sourceId: reference.get('source'),
        targetId: reference.get('target'),
      };
    }
  );
  return referenceDescriptorsToGraphData(referenceDescriptors);
};

export const getCurrentComponentLabelFormattingSetting = () =>
  filterInterface.getComponentLabelFilter().value;

const getCurrentReferenceLabelFilteringSetting = () =>
  filterInterface.getReferenceLabelFilter().value;

export const getCardinalityArrowTypes = () => [
  ArrowType.ONE,
  ArrowType.ONE_MANDATORY,
  ArrowType.MANY,
  ArrowType.ONE_OR_MANY,
  ArrowType.ZERO_OR_ONE,
  ArrowType.ZERO_OR_MANY,
];

export const getReferenceLabelFormattingFieldLabel = (): string | null => {
  const currentSetting = getCurrentReferenceLabelFilteringSetting();
  switch (currentSetting) {
    case ReferenceLabelSource.REFERENCE_TYPE:
    case ReferenceLabelSource.DISPLAY_TEXT:
    case ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE:
      return null;
    default: {
      const field = fieldInterface.getByName(currentSetting, {
        acrossWorkspaces: true,
      });

      return field ? (field.label ?? field.name) : null;
    }
  }
};

export const getReferenceLabelFormattingFieldValue = (
  referenceId: ArdoqId
): string | null => {
  if (!referenceInterface.isReference(referenceId)) {
    return null;
  }

  const activeRefLabelFilter = filterInterface.getReferenceLabelFilter();

  const currentSetting = getCurrentReferenceLabelFilteringSetting();
  const fieldValue = referenceInterface.getAttribute(
    referenceId,
    currentSetting || ''
  );

  const fieldAttributes = referenceInterface
    .getFields(referenceId)
    .find(field => field.name === currentSetting);
  const excludeTime = !activeRefLabelFilter.includeTime;

  if (!fieldAttributes) {
    return null;
  }

  return (
    fieldUtils.fieldAttributesToLabel({
      fieldAttributes,
      value: fieldValue,
      excludeTime,
      formatUserFieldFn: formatUserField,
    }) ??
    fieldValue ??
    null
  );
};

type GetReferenceLabelByFieldArgs = {
  referenceAttributes: APIReferenceAttributes;
  filterValue: string;
  excludeTime: boolean;
};
export const getReferenceLabelByField = ({
  referenceAttributes,
  filterValue,
  excludeTime,
}: GetReferenceLabelByFieldArgs) => {
  const field = fieldInterface.getByName(filterValue, {
    acrossWorkspaces: true,
    includeTemplateFields: true,
  });
  if (!field) {
    return '';
  }

  const { fieldLabel, fieldValue } =
    referenceLabelOperations.getFormattingByFieldInfo(
      referenceAttributes,
      field
    );

  const formattedFieldValue = fieldInterface.format({
    fieldId: field._id,
    fieldType: field.type,
    value: fieldValue,
    excludeTime,
  });

  return referenceLabelOperations.concatenateFieldValueLabelParts(
    fieldLabel,
    formattedFieldValue
  );
};
