import { FieldSelector as FieldSelectorComponent } from '@ardoq/integrations';
import { Resource } from 'integrations/unified/streams/resources/types';
import { useState } from 'react';
import { connect } from '@ardoq/rxbeach';
import { viewModel$ } from './viewModel';
import { SelectorField } from 'integrations/common/types/common';

const filterFields = (
  search: string,
  fields: SelectorField[]
): SelectorField[] => {
  const sanitizedSearch = search.toLowerCase().trim();
  const searchTerms = sanitizedSearch.split(/[\s_]+/);

  return fields.filter(f => {
    const label = f.label.toLowerCase();
    const name = f.name.toLowerCase();

    return searchTerms.every(
      term => label.includes(term) || name.includes(term)
    );
  });
};

export const FieldSelector = ({
  currentTable,
  isLoading,
  availableFields,
  selectedFields,
  onClickRemoveAll,
  onClickAddAll,
  onClickRemove,
  onClickAdd,
  maxHeight,
  queryButton,
}: {
  currentTable: Resource | null;
  isLoading: boolean;
  availableFields: SelectorField[] | null;
  selectedFields: SelectorField[];
  onClickRemoveAll: () => void;
  onClickAddAll: () => void;
  onClickRemove: (field: SelectorField) => void;
  onClickAdd: (field: SelectorField) => void;
  maxHeight?: number;
  queryButton: React.ReactNode;
}) => {
  const [search, setSearch] = useState('');

  const filteredSelectedFields = filterFields(search, selectedFields);
  const filteredAvailableFields =
    availableFields && filterFields(search, availableFields);

  return (
    <FieldSelectorComponent
      isLoading={isLoading}
      selectedFields={filteredSelectedFields}
      availableFields={filteredAvailableFields}
      tableName={currentTable?.displayText || null}
      search={search}
      onSearch={setSearch}
      onClickRemoveAll={onClickRemoveAll}
      onClickAddAll={onClickAddAll}
      onClickRemove={onClickRemove}
      onClickAdd={onClickAdd}
      total={(availableFields?.length || 0) + selectedFields.length}
      selectedTotal={selectedFields.length}
      queryButton={queryButton}
      maxHeight={maxHeight}
    />
  );
};

export default connect(FieldSelector, viewModel$);
