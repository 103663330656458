import { tap, withLatestFrom } from 'rxjs/operators';

import { PersonalSetting } from '@ardoq/api-types';
import {
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';

import currentUser from 'models/currentUser';
import { updatedInventoryColumns } from './actions';
import inventoryNavigation$ from './inventoryNavigation$';
import { interactiveTopRowRoutines } from './interactiveTopRow/interactiveTopRowRoutines';
import { inventoryOperations } from './inventoryOperations';

const handleSaveColumnSelection = routine(
  ofType(updatedInventoryColumns),
  extractPayload(),
  withLatestFrom(inventoryNavigation$),
  tap(([nextColumnSelection, navigationState]) => {
    if (
      !inventoryOperations.isWorkspaceInventoryNavigationState(navigationState)
    ) {
      return;
    }

    currentUser.setPersonalSetting(
      PersonalSetting.INVENTORY_RECENT_COLUMN_SELECTION_PER_WORKSPACE_OR_TYPE,
      {
        ...currentUser.getPersonalSetting(
          PersonalSetting.INVENTORY_RECENT_COLUMN_SELECTION_PER_WORKSPACE_OR_TYPE
        ),
        [navigationState.selectedWorkspaceIds.join(
          inventoryOperations.getAssetIdSeparatorForUserPreferences()
        )]: {
          columns: nextColumnSelection.columns,
        },
      }
    );
  })
);

export const inventoryRoutines = collectRoutines(
  handleSaveColumnSelection,
  ...interactiveTopRowRoutines
);
