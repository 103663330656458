import { combineLatest } from 'rxjs';
import {
  APIFieldAttributes,
  ArdoqId,
  ComponentTypeHierarchy,
} from '@ardoq/api-types';
import {
  action$,
  type ExtractPayload,
  reducer,
  reduceState,
  streamReducer,
} from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import type { ContextShape } from '@ardoq/data-model';
import {
  Permissions,
  isDisabled,
} from 'streams/currentUserPermissions/permissionInterface';
import { PersonalSetting } from '@ardoq/api-types';
import logMissingModel from 'models/logMissingModel';
import { models$, ModelsState } from 'streams/models/models$';
import { getConnectedWorkspaceIdsDeep } from 'streams/linkedWorkspaces$';
import {
  CalculatedFieldEventStatus,
  type CalculatedFieldEventMap,
  type CalculatedFieldEvent,
} from './types';
import {
  setCalculatedFieldEvent,
  setUserCalculatedFieldEventsAsNotified,
} from './actions';
import { getCalculatedFieldDoneEventsFromUser } from './utils';
import { fieldInterface } from '@ardoq/field-interface';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
  notifyFieldUpdated,
} from 'streams/fields/FieldActions';
import { fieldOps } from 'models/utils/fieldOps';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';
import { activeScenario$ } from '../../../streams/activeScenario/activeScenario$';
import { ScenarioModeState } from '../../../scope/types';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { Features, hasFeature } from '@ardoq/features';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

export interface WorkspaceOptionsState {
  hideModelPreview: boolean;
  showCreateTemplate: boolean;
  showEditModel: boolean;
  contextWorkspaceId: ArdoqId | undefined;
  contextWorkspaceName: string;
  isFlexibleModel: boolean;
  isWsWriter: boolean;
  isWsAdmin: boolean;
  disableEditWorkspace: boolean;
  disableExportExcel: boolean;
  disableEditModel: boolean;
  disableWorkspaceEditReferenceTypes: boolean;
  disableWorkspaceEditFields: boolean;
  disableModelCreateTemplate: boolean;
  hasRelatedWorkspaces: boolean;
  relatedWorkspaces: { id: string; name: string }[];
  hasCalculatedFields: boolean;
  hasNewMetamodelEditor: boolean;
  calculatedFields: APIFieldAttributes[];
  calculatedFieldEvents: CalculatedFieldEventMap;
  componentTypeHierarchy: ComponentTypeHierarchy;
  branchId?: ArdoqId | null;
}

const initialState = {
  contextWorkspaceId: undefined,
  contextWorkspaceName: '',
  hideModelPreview: false,
  isFlexibleModel: false,
  showCreateTemplate: true,
  showEditModel: false,
  isWsWriter: false,
  isWsAdmin: false,
  disableEditWorkspace: true,
  disableExportExcel: true,
  disableEditModel: true,
  disableWorkspaceEditReferenceTypes: true,
  disableWorkspaceEditFields: true,
  disableModelCreateTemplate: true,
  hasRelatedWorkspaces: false,
  relatedWorkspaces: [],
  hasCalculatedFields: false,
  hasNewMetamodelEditor: false,
  calculatedFields: [],
  calculatedFieldEvents: {},
  componentTypeHierarchy: {},
};

const contextReducer = (
  state: WorkspaceOptionsState,
  {
    context,
    models,
    permissionContext,
    activeScenarioState,
  }: {
    context: ContextShape;
    models: ModelsState;
    permissionContext: PermissionContext;
    activeScenarioState: ScenarioModeState;
  }
): WorkspaceOptionsState => {
  const contextWorkspaceId = context.workspaceId;
  if (!workspaceInterface.workspaceExists(contextWorkspaceId)) {
    return initialState;
  }
  const contextWorkspaceModel =
    models.byId[workspaceInterface.getWorkspaceModelId(contextWorkspaceId)!];
  if (!contextWorkspaceModel) {
    logMissingModel({
      id: contextWorkspaceId,
      rootWorkspace: contextWorkspaceId,
      modelTypeName: 'workspace',
    });
    return state;
  }

  const relatedWorkspaces = getConnectedWorkspaceIdsDeep(contextWorkspaceId)
    // In presentation mode we can still get workspace ids we don't have access
    // to.
    .filter(workspaceInterface.workspaceExists)
    .map(id => ({
      id: id,
      name: workspaceInterface.getWorkspaceName(id)!,
    }));
  const calculatedFields =
    fieldInterface.getCalculatedFieldsFromWorkspace(contextWorkspaceId);
  const disableModelCreateTemplate = contextWorkspaceModel
    ? isDisabled(Permissions.MODEL_CREATE_TEMPLATE, contextWorkspaceModel._id)
    : true;

  const hideIfDisabledRelatedWorkspaces = isDisabled(
    Permissions.WORKSPACE_RELATED_WORKSPACES
  );
  const hasQuickStartFeature = hasFeature(Features.QUICK_START);
  const hasDisableCreateTemplateFeature = hasFeature(
    Features.DISABLE_CREATE_TEMPLATE_FROM_WORKSPACE_METAMODEL
  );
  const hasNewMetamodelEditorFeature =
    hasFeature(Features.METAMODEL_EDITOR_ALPHA) ||
    hasFeature(Features.METAMODEL_EDITOR_BETA);
  const isWsAdmin = workspaceAccessControlInterface.canAdminWorkspace(
    permissionContext,
    contextWorkspaceId,
    activeScenarioState
  );
  return {
    contextWorkspaceId: context.workspaceId,
    contextWorkspaceName:
      workspaceInterface.getWorkspaceName(contextWorkspaceId)!,
    hasRelatedWorkspaces:
      !hideIfDisabledRelatedWorkspaces &&
      relatedWorkspaces &&
      !!relatedWorkspaces.length,
    relatedWorkspaces: relatedWorkspaces,
    isFlexibleModel: Boolean(contextWorkspaceModel?.flexible),
    isWsWriter: workspaceAccessControlInterface.canEditWorkspace(
      permissionContext,
      contextWorkspaceId,
      activeScenarioState
    ),
    isWsAdmin,
    showEditModel: isWsAdmin && !hasQuickStartFeature,
    showCreateTemplate: !hasDisableCreateTemplateFeature,
    hideModelPreview: Boolean(
      permissionContext.user?.settings[PersonalSetting.HIDE_MODEL_PREVIEW]
    ),
    hasCalculatedFields: calculatedFields.length !== 0,
    hasNewMetamodelEditor: hasNewMetamodelEditorFeature,
    calculatedFields: calculatedFields,
    calculatedFieldEvents: state.calculatedFieldEvents,
    disableEditWorkspace: isDisabled(Permissions.WORKSPACE_EDIT),
    disableEditModel: isDisabled(Permissions.MODEL_EDIT),
    disableWorkspaceEditFields: isDisabled(Permissions.WORKSPACE_EDIT_FIELDS),
    disableWorkspaceEditReferenceTypes: isDisabled(
      Permissions.WORKSPACE_EDIT_REFERENCE_TYPES
    ),
    disableModelCreateTemplate,
    disableExportExcel: isDisabled(Permissions.WORKSPACE_EXPORT_EXCEL),
    componentTypeHierarchy: contextWorkspaceModel.root,
    branchId: scenarioInterface.getScopeAndBranchId(
      activeScenarioOperations.getActiveScenarioId(activeScenarioState)
    ).branchId,
  };
};

const handleSetCalculatedFieldEvent = (
  state: WorkspaceOptionsState,
  calculatedFieldEvent: CalculatedFieldEvent
) => ({
  ...state,
  calculatedFieldEvents: {
    ...state.calculatedFieldEvents,
    [calculatedFieldEvent.fieldId]: calculatedFieldEvent,
  },
});

const handleSetUserCalculatedFieldEventsAsNotified = (
  state: WorkspaceOptionsState,
  userId: string
): WorkspaceOptionsState => ({
  ...state,
  calculatedFieldEvents: {
    ...state.calculatedFieldEvents,
    ...Object.fromEntries(
      Object.entries(
        getCalculatedFieldDoneEventsFromUser(
          state.calculatedFieldEvents,
          userId
        )
      ).map(([fieldId, event]) => [
        fieldId,
        { ...event, status: CalculatedFieldEventStatus.DONE_NOTIFIED },
      ])
    ),
  },
});

const handleNotifyFieldUpdated = (
  state: WorkspaceOptionsState,
  { field }: ExtractPayload<typeof notifyFieldUpdated>
) => {
  if (!fieldOps.isCalculatedField(field.attributes)) {
    return {
      ...state,
      calculatedFields: state.calculatedFields.filter(
        existingField => field.id !== existingField._id
      ),
    };
  }

  if (
    !state.calculatedFields.some(
      existingField => existingField._id === field.id
    )
  ) {
    return {
      ...state,
      calculatedFields: [
        ...state.calculatedFields,
        structuredClone(field.attributes),
      ],
    };
  }

  return {
    ...state,
    calculatedFields: state.calculatedFields.map(existingField => {
      if (field.id === existingField._id)
        return structuredClone(field.attributes);
      return existingField;
    }),
  };
};

const handleNotifyFieldAdded = (
  state: WorkspaceOptionsState,
  { field }: ExtractPayload<typeof notifyFieldAdded>
) => {
  if (!fieldOps.isCalculatedField(field.attributes)) return state;

  return {
    ...state,
    calculatedFields: [
      ...state.calculatedFields,
      structuredClone(field.attributes),
    ],
  };
};

const handleNotifyFieldRemoved = (
  state: WorkspaceOptionsState,
  { field }: ExtractPayload<typeof notifyFieldRemoved>
) => {
  if (!fieldOps.isCalculatedField(field.attributes)) return state;

  return {
    ...state,
    calculatedFields: state.calculatedFields.filter(
      existingField => field.id !== existingField._id
    ),
  };
};

const workspaceOptionContext$ = combineLatest({
  context: context$,
  permissionContext: currentUserPermissionContext$,
  models: models$,
  activeScenarioState: activeScenario$,
});

export const workspaceOptions$ = action$.pipe(
  reduceState('workspaceOptions$', initialState, [
    streamReducer(workspaceOptionContext$, contextReducer),
    reducer(setCalculatedFieldEvent, handleSetCalculatedFieldEvent),
    reducer(
      setUserCalculatedFieldEventsAsNotified,
      handleSetUserCalculatedFieldEventsAsNotified
    ),
    reducer(notifyFieldUpdated, handleNotifyFieldUpdated),
    reducer(notifyFieldAdded, handleNotifyFieldAdded),
    reducer(notifyFieldRemoved, handleNotifyFieldRemoved),
  ])
);
