import {
  navigateToDefaultViewpoints,
  navigateToNewViewpoints,
  navigateToViewpointForm,
  navigateToViewpoints,
} from 'router/navigationActions';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs';
import { viewpointsFetch } from 'viewpoints/actions';
import { trackOpenNewViewpointBuilder } from 'viewpoints/tracking';
import viewpointsNavigation$ from './viewpointsNavigation$';

const handleNavigateToViewpointForm = routine(
  ofType(
    navigateToViewpointForm,
    navigateToViewpoints,
    navigateToNewViewpoints,
    navigateToDefaultViewpoints
  ),
  extractPayload(),
  withLatestFrom(viewpointsNavigation$),
  tap(([, { viewpointId }]) => {
    dispatchAction(viewpointsFetch());
    trackOpenNewViewpointBuilder(viewpointId || undefined);
  })
);

export default collectRoutines(handleNavigateToViewpointForm);
