import { ExtractPayload } from '@ardoq/rxbeach';
import { isEqual } from 'lodash';
import {
  map,
  combineLatest,
  distinctUntilChanged,
  debounceTime,
  Observable,
} from 'rxjs';
import type { TargetPortalConfig } from '@ardoq/post-message-bridge';
import * as gridEditorActions from 'gridEditor2023/actions';
import type { GridEditorNotifyFiltersChangePayload } from 'gridEditor2023/actions';
import { GRID_EDITOR_POST_MESSAGE_BRIDGE_ID } from 'gridEditor2023/consts';
import gridEditor$ from 'gridEditor2023/gridEditor$';
import {
  GlobalTrackingMetadata,
  GridEditorFrame,
  GridEditorState,
} from 'gridEditor2023/types';
import { bridgeGlobalFilterAttributes$ } from 'gridEditor2023/observables/bridgeGlobalFilterAttributesObservable';
import * as orgUsersActions from 'streams/orgUsers/actions';
import dynamicFilterStates$ from 'streams/dynamicFilters/dynamicFilterStates$';
import { currentUserPermissions$ } from 'streams/currentUserPermissions/currentUserPermissions$';
import currentUser$ from 'streams/currentUser/currentUser$';
import { setCurrentUserPermissions } from 'streams/currentUserPermissions/actions';
import { gridEditorScopeData$ } from 'gridEditor2023/observables/gridEditorContext';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { syncGridEditorSubdivisions } from 'streams/subdivisions/actions';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { isViewpointMode$ } from '../../traversals/loadedGraph$';

const gridEditorGlobalTrackingMetadata$: Observable<GlobalTrackingMetadata> =
  isViewpointMode$.pipe(
    map(({ isViewpointMode }) => ({ isViewpointMode })),
    distinctUntilChanged(isEqual)
  );

type EditorStatePayload = ExtractPayload<
  typeof gridEditorActions.initGridEditorState
>;
const pickGridEditorState = (
  gridEditor: GridEditorState
): EditorStatePayload => {
  if (gridEditor.activeFrameId === GridEditorFrame.DOCKED) {
    return {
      activeFrameId: gridEditor.activeFrameId,
      activeViewType: gridEditor.activeViewType,
      dockedEditorPaneStyle: gridEditor.dockedEditorPaneStyle,
      dockedGridEditorVisibility: gridEditor.dockedGridEditorVisibility,
    };
  }

  return {
    activeFrameId: gridEditor.activeFrameId,
    activeViewType: gridEditor.activeViewType,
  };
};

/**
 * Slight optimization applied with debounce/distinctUntilChanged to prevent
 * double renders of the GridEditor due to individual updates to the two streams
 * when filters/perspectives are cleared.
 */
const gridEditorFilters$ = combineLatest({
  globalFilterAttributes: bridgeGlobalFilterAttributes$,
  dynamicFilterStates: dynamicFilterStates$,
}).pipe(
  map((data): GridEditorNotifyFiltersChangePayload => {
    return {
      filters: data.globalFilterAttributes,
      dynamicFilterStates: data.dynamicFilterStates,
    };
  }),
  debounceTime(64),
  distinctUntilChanged(isEqual)
);

// Configures `ardoq-front -> standalone` communication
export const gridEditorTargetPortalConfig: TargetPortalConfig = {
  id: GRID_EDITOR_POST_MESSAGE_BRIDGE_ID,
  type: 'TARGET_PORTAL',
  closePopoutOnDisconnect: true,
  actions: [
    gridEditorActions.hideGridEditor,
    gridEditorActions.toggleFullscreen,
    gridEditorActions.setDockedGridEditorStyle,
    gridEditorActions.showGridEditor,
    gridEditorActions.gridSetDisabledWorkspaces,
    gridEditorActions.gridTargetWorkspaceEnable,
    gridEditorActions.gridTargetWorkspaceDisable,
  ],
  streamSubscriptions: [
    {
      mode: 'first',
      stream: gridEditor$.pipe(map(pickGridEditorState)),
      action: gridEditorActions.initGridEditorState,
    },
    {
      mode: 'first',
      stream: orgUsers$.pipe(map(({ users }) => users)),
      action: orgUsersActions.fetchedOrgUsersSuccess,
    },
    {
      stream: gridEditorScopeData$,
      action: gridEditorActions.notifyScopeDataChange,
    },
    {
      stream: gridEditorFilters$,
      action: gridEditorActions.notifyGridEditorFiltersChanged,
    },
    {
      stream: currentUser$,
      action: gridEditorActions.notifyCurrentUserChange,
    },
    {
      stream: currentUserPermissions$,
      action: setCurrentUserPermissions,
    },
    {
      stream: subdivisions$,
      action: syncGridEditorSubdivisions,
    },
    {
      stream: gridEditorGlobalTrackingMetadata$,
      action: gridEditorActions.notifyGlobalTrackingMetadataChange,
    },
  ],
};
