import { ActionStream, action$, ofType } from '@ardoq/rxbeach';
import { combineLatest, merge } from 'rxjs';
import { GraphModelShape } from '@ardoq/data-model';
import { createStateStream } from 'streams/utils/stateStreamUtils';
import { loadVisualizationSlideSuccess } from 'presentation/viewPane/actions';
import { debounceTime, map, startWith } from 'rxjs/operators';
import {
  notifyReferencesAdded,
  notifyReferencesRemoved,
  notifyReferencesReset,
  notifyReferencesUpdated,
} from 'streams/references/ReferenceActions';
import { resetGraph } from './graphModelActions';
import { notifyWorkspaceAggregatedLoaded } from 'streams/workspaces/actions';
import { startAction } from 'actions/utils';
import References from 'collections/references';
import { referenceListToGraphData } from './util';
import { DEFAULT_DEBOUNCE_TIME } from './consts';
import { notifyModelChanged } from 'streams/models/actions';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { loadedGraphOperations } from 'traversals/loadedGraphOperations';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';

const reset = ({
  isViewpointMode,
  scopeReferenceIds,
}: LoadedGraphWithViewpointMode) => {
  const references = References.collection.toArray();
  if (isViewpointMode) {
    const visibleReferenceIds = new Set(scopeReferenceIds);
    return referenceListToGraphData(
      references.filter(reference => visibleReferenceIds.has(reference.id))
    );
  }
  return referenceListToGraphData(references);
};

const getStream = createStateStream<GraphModelShape>({
  debugName: 'graphModel$',
  isTemporary: true,
  createStream: (action$: ActionStream) =>
    merge(
      combineLatest({
        resetAction: action$.pipe(
          ofType(
            notifyReferencesAdded,
            notifyReferencesUpdated,
            notifyReferencesRemoved,
            notifyWorkspaceAggregatedLoaded,
            notifyReferencesReset,
            loadVisualizationSlideSuccess,
            notifyModelChanged
          )
        ),
        loadedGraph: loadedGraph$,
      }).pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        startWith({
          resetAction: startAction(),
          loadedGraph: loadedGraphOperations.getEmpty(),
        }),
        map(({ loadedGraph }) => reset(loadedGraph))
      ),
      action$.pipe(
        ofType(resetGraph),
        map(() => ({
          sourceMap: new Map(),
          targetMap: new Map(),
          referenceMap: new Map(),
          referenceTypes: [],
        }))
      )
    ),
});

export default getStream(action$);
