import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { ArdoqId } from '@ardoq/api-types';
import { EditingBundleWsShape, updateWsSettingsParams } from '../types';
import { Checkbox, TextInput } from '@ardoq/forms';
import { Paragraph } from '@ardoq/typography';
import { Box } from '@ardoq/layout';

type WorkspaceSettingsProps = {
  workspaceAttrs: EditingBundleWsShape;
  workspaceId: ArdoqId;
  workspaceName: string;
  removeWorkspace: () => void;
  updateWorkspaceSettings: (params: updateWsSettingsParams) => void;
};

const RemoveWorkspaceLink = styled.a`
  font-size: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const WorkspaceSettings = ({
  workspaceAttrs,
  workspaceId,
  workspaceName,
  removeWorkspace,
  updateWorkspaceSettings,
}: WorkspaceSettingsProps) => {
  const updateCurrentWsSetting = (
    updatedSettings: Partial<EditingBundleWsShape>,
    debounce = false
  ) => updateWorkspaceSettings({ workspaceId, updatedSettings, debounce });

  return (
    <>
      <Paragraph variant="text1Bold">{workspaceName}</Paragraph>
      <RemoveWorkspaceLink onClick={removeWorkspace}>
        Remove
      </RemoveWorkspaceLink>
      <Checkbox
        isChecked={workspaceAttrs.copyComponents}
        onChange={() =>
          updateCurrentWsSetting({
            copyComponents: !workspaceAttrs.copyComponents,
          })
        }
      >
        Include components
      </Checkbox>
      <Checkbox
        isChecked={workspaceAttrs.copyReferences}
        onChange={() =>
          updateCurrentWsSetting({
            copyReferences: !workspaceAttrs.copyReferences,
          })
        }
      >
        Include references
      </Checkbox>
      <Checkbox
        isChecked={workspaceAttrs.renameWorkspace}
        onChange={() =>
          updateCurrentWsSetting({
            renameWorkspace: !workspaceAttrs.renameWorkspace,
          })
        }
      >
        Rename when copied
      </Checkbox>
      {workspaceAttrs.renameWorkspace && (
        <Box marginY="small">
          <TextInput
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateCurrentWsSetting({ name: event.target.value }, true)
            }
            defaultValue={workspaceAttrs.name}
            placeholder="Enter new name for workspace"
          />
        </Box>
      )}
      <Checkbox
        isChecked={workspaceAttrs.chooseFolder}
        onChange={() =>
          updateCurrentWsSetting({ chooseFolder: !workspaceAttrs.chooseFolder })
        }
      >
        Specify target folder name
      </Checkbox>
      {workspaceAttrs.chooseFolder && (
        <Box marginY="small">
          <TextInput
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateCurrentWsSetting(
                { targetFolderName: event.target.value },
                true
              )
            }
            defaultValue={workspaceAttrs.targetFolderName}
            placeholder="Enter target folder name"
            label="Use / to specify a nested destination folder, e.g. Level 1/Level 2"
          />
        </Box>
      )}
    </>
  );
};

export default WorkspaceSettings;
