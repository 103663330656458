import {
  routine,
  dispatchAction,
  collectRoutines,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { trackStreamEvent } from 'tracking/tracking';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  removeUsersFromGroup,
  removeUsersFromGroupFailed,
  removeUsersFromGroupSuccess,
} from './actions';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import { api, groupApi, handleError } from '@ardoq/api';

const handleRemoveUsersFromGroup = routine(
  ofType(removeUsersFromGroup),
  extractPayload(),
  trackStreamEvent(({ permissionGroupId, userIds, force }) => ({
    eventName: APPSEC_EVENTS.REMOVED_USERS_FROM_GROUP,
    metadata: { groupId: permissionGroupId, usersCount: userIds.length },
    forced: force ?? false,
  })),
  map(({ permissionGroupId, userIds, force }) => ({
    groupId: permissionGroupId,
    userIds,
    force,
  })),
  switchMap(groupApi.removeUsers),
  handleError(error => {
    const errorMessage = api.logErrorIfNeededAndReturnMessage(
      error,
      'Failed to remove user from permission group'
    );
    dispatchAction(removeUsersFromGroupFailed(errorMessage));
  }),
  tap(() => {
    dispatchAction(removeUsersFromGroupSuccess());
  })
);

export const removeUsersRoutines = collectRoutines(handleRemoveUsersFromGroup);
