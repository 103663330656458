import styled from 'styled-components';
import { DOMAttributes, PropsWithChildren } from 'react';
import { colors, s8 } from '@ardoq/design-tokens';
import { typographyMixins } from '@ardoq/typography';

const SectionFormElement = styled.form`
  && .modern-checkbox {
    padding-left: 0;
  }

  && label {
    ${typographyMixins.caption}
    margin-bottom: 0;
    display: flex;
  }

  textarea {
    height: auto;
    min-height: 100px;
  }

  input[type='text'] {
    width: 100%;
  }

  input[type='checkbox'] {
    margin: 0 ${s8} 0 0;
    vertical-align: middle;
  }
  a .error {
    color: ${colors.red50};
  }

  p.error {
    margin-top: 10px;
  }
`;

type SectionFormProperties = PropsWithChildren<
  Pick<DOMAttributes<HTMLFormElement>, 'onSubmit'>
>;
const SectionForm = ({
  children,
  onSubmit = event => event.preventDefault(),
}: SectionFormProperties) => {
  return (
    <SectionFormElement className="modern-form" onSubmit={onSubmit}>
      {children}
    </SectionFormElement>
  );
};

export default SectionForm;
