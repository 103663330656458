import { APIToken } from '@ardoq/api-types';
import { CopyAndDisappearTokenState } from './copyAndDisappearToken$';

export const defaultCopyAndDisappearTokenState: CopyAndDisappearTokenState = {
  isCopied: false,
  isLoading: false,
};

const setIsLoading = (
  currentState: CopyAndDisappearTokenState
): CopyAndDisappearTokenState => {
  return {
    ...currentState,
    isLoading: true,
  };
};

const copyTokenReducer = (
  currentState: CopyAndDisappearTokenState
): CopyAndDisappearTokenState => {
  return {
    ...currentState,
    isCopied: true,
  };
};

const resetStateReducer = (): CopyAndDisappearTokenState => {
  return defaultCopyAndDisappearTokenState;
};

const setTokenReducer = (
  currentState: CopyAndDisappearTokenState,
  token: APIToken
): CopyAndDisappearTokenState => {
  return {
    ...currentState,
    token,
    isLoading: false,
    isCopied: false,
  };
};

const deleteTokenForServiceAccount = (
  currentState: CopyAndDisappearTokenState,
  serviceAccountId: string
): CopyAndDisappearTokenState => {
  return serviceAccountId === currentState.token?.['user-id']
    ? defaultCopyAndDisappearTokenState
    : currentState;
};

const deleteToken = (
  currentState: CopyAndDisappearTokenState,
  token: APIToken | string
): CopyAndDisappearTokenState => {
  if (typeof token === 'string') {
    return token === currentState.token?.token
      ? defaultCopyAndDisappearTokenState
      : currentState;
  }

  return token._id === currentState.token?._id
    ? defaultCopyAndDisappearTokenState
    : currentState;
};

export const copyAndDisappearTokenOperations = {
  setIsLoading,
  copyTokenReducer,
  resetStateReducer,
  setTokenReducer,
  deleteTokenForServiceAccount,
  deleteToken,
};
