import { APIToken } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

const DOMAIN = '[COPY_AND_DISAPPEAR_TOKEN]';

export const newTokenGeneratedAction = actionCreator<APIToken>(
  `${DOMAIN} NEW_TOKEN_GENERATED`
);

export const copyTokenAction = actionCreator(`${DOMAIN} COPY_TOKEN`);

export const resetTokenAction = actionCreator(`${DOMAIN} RESET_TOKEN`);

export const deleteTokenAction = actionCreator<string>(
  `${DOMAIN} DELETE_TOKEN`
);
