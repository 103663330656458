import { Features, hasFeature } from '@ardoq/features';
import type {
  GraphInterface,
  TraversalViewModel,
  TraversalViewSettings,
  TraverseOptions,
} from '@ardoq/graph';
import { CollectionView } from 'collections/consts';
import { LoadedScenarioData } from 'loadedScenarioData$';
import ReferenceTypeOptionCache from 'modelInterface/graph/ReferenceTypeOptionCache';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';
import { GlobalReferenceTypeId, GraphModelShape } from '@ardoq/data-model';
import {
  ArdoqId,
  GroupType,
  GroupingRule,
  ReferenceDirection,
} from '@ardoq/api-types';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { groupingRuleInterface } from 'modelInterface/groupingRules/groupingRuleInterface';
import { ShowRelatedComponentsShape } from 'scenarioRelated/types';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';

const referenceTypeOptionCache = new ReferenceTypeOptionCache();

const buildReferenceTypeMap = (graphModel: GraphModelShape) =>
  new Map(
    graphModel.referenceTypes.map(({ name, typeIds }) => [name, typeIds])
  );

const getReferenceTypeIdsFromName = (
  referenceTypeNames: string[],
  referenceTypes: Map<string, GlobalReferenceTypeId[]>
) =>
  new Set(
    referenceTypeNames.flatMap(referenceTypeName => {
      const referenceTypeIds = referenceTypes.get(referenceTypeName);
      return referenceTypeIds || [referenceTypeName];
    })
  );

interface GetTraverseOptionsArgs {
  viewSettings: TraversalViewSettings;
  referenceTypeMap: Map<string, string[]>;
}
const getTraverseOptions = ({
  viewSettings: {
    depthOfRelationship,
    incomingDegreesOfRelationship,
    outgoingDegreesOfRelationship,
    includeOutgoingReferenceTypeNames,
    includeIncomingReferenceTypeNames,
  },
  referenceTypeMap,
}: GetTraverseOptionsArgs) => ({
  maxDegreesIncoming: incomingDegreesOfRelationship,
  maxDegreesOutgoing: outgoingDegreesOfRelationship,
  maxDegrees: depthOfRelationship,
  isParentRelationAsReference: true,
  outgoingReferenceTypes: getReferenceTypeIdsFromName(
    includeOutgoingReferenceTypeNames,
    referenceTypeMap
  ),
  incomingReferenceTypes: getReferenceTypeIdsFromName(
    includeIncomingReferenceTypeNames,
    referenceTypeMap
  ),
  nodeCollectionView: isInScopeDiffMode()
    ? CollectionView.WITH_PLACEHOLDERS
    : CollectionView.DEFAULT_VIEW,
});
interface BuildTraversalViewModelArgs {
  graphInterface: GraphInterface;
  graphModel: GraphModelShape;
  startSet: ArdoqId[];
  traverseOptions: TraverseOptions;
}
export const buildTraversalViewModel = ({
  graphInterface,
  graphModel,
  startSet,
  traverseOptions,
}: BuildTraversalViewModelArgs): TraversalViewModel => {
  const { referenceTypes } = graphModel;
  const { traversedOutgoingReferenceTypes, traversedIncomingReferenceTypes } =
    referenceTypeOptionCache.getReferenceTypeOptions(
      graphInterface,
      graphModel,
      startSet,
      traverseOptions
    );
  return {
    referenceTypes,
    traversedOutgoingReferenceTypes,
    traversedIncomingReferenceTypes,
  };
};

interface GetTraversalConfigurationArgs {
  viewSettings: TraversalViewSettings;
  graphModel: GraphModelShape;
  scenarioRelatedComponents: ShowRelatedComponentsShape;
}
export const getTraversalConfiguration = ({
  viewSettings,
  graphModel,
  scenarioRelatedComponents,
}: GetTraversalConfigurationArgs) => {
  const referenceTypeMap = buildReferenceTypeMap(graphModel);

  const traverseOptions = getTraverseOptions({
    viewSettings,
    referenceTypeMap,
  });
  const sequentialTraverseOptions = hasFeature(Features.SEQUENTIAL_TRAVERSAL)
    ? viewSettings.additionalTraversals.map(additionalTraversal => ({
        maxDegreesIncoming: 0,
        maxDegreesOutgoing: 0,
        maxDegrees: additionalTraversal.depthOfRelationship,
        isParentRelationAsReference: true,
        outgoingReferenceTypes: getReferenceTypeIdsFromName(
          additionalTraversal.includeOutgoingReferenceTypeNames,
          referenceTypeMap
        ),
        incomingReferenceTypes: getReferenceTypeIdsFromName(
          additionalTraversal.includeIncomingReferenceTypeNames,
          referenceTypeMap
        ),
      }))
    : [];
  const { componentIds: scenarioRelatedComponentIds } =
    scenarioRelatedComponents;

  return {
    traverseOptions,
    sequentialTraverseOptions,
    scenarioRelatedComponentIds,
    referenceTypeMap,
  };
};

interface GetGroupingRulesArgs {
  loadedScenarioData: LoadedScenarioData;
  showScopeRelated: boolean;
  scenarioRelatedComponentIds: ArdoqId[];
}

export const getGroupingRules = ({
  loadedScenarioData,
  showScopeRelated,
  scenarioRelatedComponentIds,
}: GetGroupingRulesArgs) => {
  const scenarioGroup: GroupingRule | null =
    loadedScenarioData.scenarioData &&
    scenarioRelatedComponentIds.length > 0 &&
    showScopeRelated
      ? {
          label: `Scenario: ${scenarioInterface.getScenarioNameById(
            getActiveScenarioId()
          )}`,
          type: GroupType.ALL,
          direction: ReferenceDirection.UNSET,
          targetId: '',
          workspaceId: '',
        }
      : null;

  return scenarioGroup
    ? [scenarioGroup, ...groupingRuleInterface.getAll()]
    : groupingRuleInterface.getAll();
};
