import { ComponentSearchViewState, ComponentSearchState } from './types';
import { componentSearchOperations } from './componentSearchOperations';

export const initialSearchComponentViewState: ComponentSearchViewState = {
  selectedComponentIds: [],
  selectedComponents: [],
  componentName: '',
  loadedComponents: [],
  componentType: '',
  isApplyingTraversalDisabled: true,
  selectedStartComponentTypeNames: [],
  isLoading: false,
  hasSearchResults: false,
  selectedComponentTypeHasNoInstances: false,
  totalCount: 0,
  allComponentsSelected: false,
  startQuery: null,
  canSelectOnlyComponentType: false,
  componentTypes: [],
  advancedSearchState: null,
  selectionClearedOnSelectAllCount: null,
  selectedComponentsCount: 0,
  startSetFilter: null,
  isSelectAllDisabled: true,
};

export const toViewState = (
  state: ComponentSearchState
): ComponentSearchViewState => {
  const selectedComponentIds =
    componentSearchOperations.getSelectedComponentIds(state);

  const selectedComponentTypeHasNoInstances =
    !state.componentName &&
    Boolean(state.componentType) &&
    Boolean(state.hasSearchResults);

  const selectedComponentsNotInCurrentResults = state.selectedComponents.filter(
    ({ id }) => !state.loadedComponents.some(component => component.id === id)
  );
  const allComponentsSelected = state.startQuery !== null;
  return {
    selectedComponentIds,
    selectedComponentsCount: allComponentsSelected
      ? state.totalCount
      : selectedComponentIds.length,
    selectedComponents: state.selectedComponents,
    componentName: state.componentName,
    loadedComponents: [
      ...selectedComponentsNotInCurrentResults,
      ...state.loadedComponents,
    ],
    componentType: state.componentType,
    isApplyingTraversalDisabled:
      !componentSearchOperations.isAtLeastOneComponentSelectedOfOnlyOneComponentType(
        state
      ),
    selectedStartComponentTypeNames:
      componentSearchOperations.getSelectedComponentTypesNames(state),
    isLoading: state.isLoading ?? false,
    hasSearchResults: state.hasSearchResults ?? false,
    selectedComponentTypeHasNoInstances,
    totalCount: state.totalCount,
    allComponentsSelected,
    startQuery: state.startQuery,
    canSelectOnlyComponentType: state.canSelectOnlyComponentType,
    componentTypes: state.componentTypes,
    advancedSearchState: state.advancedSearchState,
    selectionClearedOnSelectAllCount: state.selectionClearedOnSelectAllCount,
    startSetFilter: state.startSetFilter,
    isSelectAllDisabled: state.loadedComponents.length === 0,
  };
};
