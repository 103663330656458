import { combineLatest } from 'rxjs';
import { ArdoqId } from '@ardoq/api-types';
import { action$, streamReducer, reduceState } from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import type { ContextShape } from '@ardoq/data-model';
import {
  Permissions,
  isDisabled,
} from 'streams/currentUserPermissions/permissionInterface';
import { referenceAccessControlOperation } from 'resourcePermissions/accessControlHelpers/reference';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { ScenarioModeState } from 'scope/types';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

export interface ReferenceModel {
  _id: ArdoqId;
  leftName: string;
  rightName: string;
  arrowDirection: string;
}

export interface ReferenceOptionsState {
  references: ReferenceModel[];
  hasWorkspaceWriteAccess: boolean;
  canEditReference: boolean;
  canEditReferencesTargetComponent: boolean;
  isViewVersionHistoryDisabled: boolean;
  contextReferenceId: ArdoqId | undefined;
  locked: boolean;
  canToggleLock: boolean;
}

const initialState = {
  references: [],
  hasWorkspaceWriteAccess: false,
  canEditReference: false,
  canEditReferencesTargetComponent: false,
  isViewVersionHistoryDisabled: true,
  contextReferenceId: undefined,
  locked: false,
  canToggleLock: false,
};

const getOtherStuff = (
  state: ReferenceOptionsState,
  contextState: ContextShape
) => ({
  ...state,
  hasWorkspaceWriteAccess: workspaceInterface.hasWriteAccess(
    contextState.workspaceId
  ),
  isViewVersionHistoryDisabled: isDisabled(Permissions.REFERENCE_VIEW_HISTORY),
});

const contextReducer = (
  state: ReferenceOptionsState,
  [contextState, permissionContext, activeScenarioState, subdivisionsContext]: [
    ContextShape,
    PermissionContext,
    ScenarioModeState,
    SubdivisionsContext,
  ]
) => {
  const newState = getOtherStuff(state, contextState);
  const referenceId = contextState.referenceId;
  const canEditScenario =
    !activeScenarioOperations.isInScenarioMode(activeScenarioState) ||
    scenarioAccessControlInterface.canEditActiveScenario(
      permissionContext,
      activeScenarioState
    );

  if (referenceId) {
    const reference = referenceInterface.getLiteAttributes(referenceId);
    if (reference) {
      return {
        ...newState,
        canEditReference:
          canEditScenario &&
          referenceAccessControlOperation.canEditReference({
            reference,
            permissionContext,
            subdivisionsContext,
          }),
        canEditReferencesTargetComponent:
          canEditScenario &&
          referenceAccessControlOperation.canEditComponentReferencesBySourceComponentId(
            reference.target
          ),
        references: [],
        contextReferenceId: referenceId,
        locked: Boolean(reference.lock),
        canToggleLock:
          referenceAccessControlOperation.canUnlock({
            permissionContext,
            reference,
          }) || !reference.lock,
      };
    }
  }
  const componentId = contextState.componentId;
  if (!componentId) {
    return { ...newState, references: [] };
  }
  const sourceRefs = referenceInterface
    .getReferencesBySourceId(componentId)
    .map(_id => {
      const sourceId = referenceInterface.getSourceComponentId(_id) ?? '';
      const targetId = referenceInterface.getTargetComponentId(_id) ?? '';
      return {
        _id,
        arrowDirection: 'right',
        leftName: componentInterface.getDisplayName(sourceId) ?? 'NAME MISSING',
        rightName:
          componentInterface.getDisplayName(targetId) ?? 'NAME MISSING',
      };
    });

  const targetRefs = referenceInterface
    .getReferencesByTargetId(componentId)
    .map(_id => {
      const sourceId = referenceInterface.getSourceComponentId(_id) ?? '';
      const targetId = referenceInterface.getTargetComponentId(_id) ?? '';
      return {
        _id,
        arrowDirection: 'left',
        leftName: componentInterface.getDisplayName(targetId) ?? 'NAME MISSING',
        rightName:
          componentInterface.getDisplayName(sourceId) ?? 'NAME MISSING',
      };
    });
  return {
    ...newState,
    canEditReference:
      canEditScenario &&
      referenceAccessControlOperation.canEditComponentReferencesBySourceComponentId(
        componentId
      ),
    references: [...sourceRefs, ...targetRefs],
    contextReferenceId: undefined,
  };
};

export const referenceOptions$ = action$.pipe(
  reduceState('referenceOptions$', initialState, [
    streamReducer(
      combineLatest([
        context$,
        currentUserPermissionContext$,
        activeScenario$,
        subdivisions$,
      ]),
      contextReducer
    ),
  ])
);
