import { GhostButton } from '@ardoq/button';
import { PaletteIcon } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import { DashboardModule } from '../types';
import { navigateToDashboardModule } from '../../router/navigationActions';

export const ColorThemesButton = () => {
  return (
    <GhostButton
      onClick={() => {
        dispatchAction(
          navigateToDashboardModule({
            dashboardModule: DashboardModule.COLOR_THEME_SETTINGS,
          })
        );
      }}
    >
      Color themes
      <PaletteIcon />
    </GhostButton>
  );
};
