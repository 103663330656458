import { AttributeValueInPreview, MappingPreviewTableProps } from './types';
import { DoqContainer, TableContainer, Unmapped } from './atoms';
import { STRINGS } from './consts';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { s16 } from '@ardoq/design-tokens';
import AttributeTypeTag from './AttributeTypeTag';
import { StatusType, Tag, TagGroup } from '@ardoq/status-ui';
import { Header4 } from '@ardoq/typography';
import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import { Stack } from '@ardoq/layout';

const MappingPreviewTable = ({
  isLoggedInWithSSO,
  mappingPreview,
}: MappingPreviewTableProps) => {
  if (!isLoggedInWithSSO) {
    return (
      <DoqContainer>
        <DoqWithSpeechBubble
          doqType={DoqType.ERROR}
          title={STRINGS.SSO_LOGIN_REQUIRED}
          message={STRINGS.SIGNIN_WITH_SSO_ALT}
          layout={DoqLayout.DIALOG}
        />
      </DoqContainer>
    );
  }

  if (mappingPreview.preview.length < 1) {
    return (
      <DoqContainer>
        <DoqWithSpeechBubble
          doqType={DoqType.WAITING}
          title={STRINGS.PREVIEW_TABLE.PLACEHOLDER_TITLE}
          message={STRINGS.PREVIEW_TABLE.PLACEHOLDER_MESSAGE}
          layout={DoqLayout.DIALOG}
        />
      </DoqContainer>
    );
  }

  return (
    <>
      <TableContainer>
        <DatasourceTable
          dataSource={mappingPreview.preview}
          columns={[
            {
              title: STRINGS.PREVIEW_TABLE.HEADER.ATTRIBUTE_NAME,
              dataIndex: 'attribute.name',
              cellStyle: {
                width: 'auto',
                padding: s16,
              },
              valueRender: ({ name, unmapped }) =>
                unmapped ? <Unmapped>{name}</Unmapped> : name,
            },
            {
              title: STRINGS.PREVIEW_TABLE.HEADER.ATTRIBUTE_TYPE,
              dataIndex: 'attribute.type',
              cellStyle: {
                width: '15%',
                padding: s16,
              },
              valueRender: type => <AttributeTypeTag type={type} />,
            },
            {
              title: STRINGS.PREVIEW_TABLE.HEADER.VALUE,
              dataIndex: 'attribute.values',
              cellStyle: {
                width: '25%',
                padding: s16,
              },
              valueRender: ({
                values,
                unmapped,
              }: {
                values: AttributeValueInPreview;
                unmapped: boolean;
              }) => {
                if (!values) return null;
                return values.map(value => {
                  if (value === null)
                    return (
                      <Unmapped key={STRINGS.UNMAPPED}>
                        {STRINGS.UNMAPPED}
                      </Unmapped>
                    );
                  if (unmapped) return <Unmapped key={value}>{value}</Unmapped>;
                  return <div key={value}>{value}</div>;
                });
              },
            },
            {
              title: STRINGS.PREVIEW_TABLE.HEADER.JOIN_GROUPS,
              dataIndex: 'joinGroups',
              valueRender: ({ joinGroups, unmapped, notApplicable }) => {
                if (unmapped) {
                  return <Unmapped>{STRINGS.UNMAPPED}</Unmapped>;
                }
                if (notApplicable) {
                  return (
                    <Tag
                      label={STRINGS.NOT_APPLICABLE_TO_USER}
                      statusType={StatusType.INFO}
                    />
                  );
                }
                return (
                  <TagGroup>
                    {joinGroups.map((group: string) => (
                      <Tag
                        key={group}
                        label={group}
                        statusType={StatusType.SUCCESS}
                      />
                    ))}
                  </TagGroup>
                );
              },
              cellStyle: {
                width: '25%',
                padding: s16,
              },
            },
          ]}
          components={newTableTheme}
          style={{ overflowY: 'unset' }}
        />
      </TableContainer>
      {mappingPreview?.leaveGroups.length > 0 && (
        <Stack gap="large">
          <Header4>{STRINGS.PREVIEW_TABLE.HEADER.LEAVE_GROUPS}</Header4>
          <TagGroup>
            {mappingPreview?.leaveGroups.map((group: string) => (
              <Tag key={group} label={group} statusType={StatusType.ERROR} />
            ))}
          </TagGroup>
        </Stack>
      )}
    </>
  );
};

export default MappingPreviewTable;
