import { createElement } from 'react';
import { zip } from 'rxjs';
import broadcast$ from 'broadcasts/broadcast$';
import broadcastsNavigation$ from './navigation/broadcastsNavigation$';
import content$ from './content$';
import PredefinedAudienceDialog from './broadcastBuilder/broadcastAudienceForm/dialogs/predefinedAudienceDialog/PredefinedAudienceDialog';
import GremlinAudienceDialog from './broadcastBuilder/broadcastAudienceForm/dialogs/gremlinAudienceDialog/GremlinAudienceDialog';
import EmailAudienceDialog from './broadcastBuilder/broadcastAudienceForm/dialogs/emailAudienceDialog/EmailAudienceDialog';
import WorkspaceAudienceDialog from './broadcastBuilder/broadcastAudienceForm/dialogs/workspaceAudienceDialog/WorkspaceAudienceDialog';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
  carry,
} from '@ardoq/rxbeach';
import {
  withLatestFrom,
  catchError,
  exhaustMap,
  filter,
  map,
  pluck,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  addOrUpdateEmailBroadcastAudience,
  addOrUpdateGremlinBroadcastAudience,
  addOrUpdatePredefinedBroadcastAudience,
  addOrUpdateWorkspaceBroadcastAudience,
  chooseBroadcastContentId,
  chooseBroadcastContentType,
  fetchBroadcastInstances,
  fetchPredefinedQueryOptions,
  fetchViewpointsForComponentTypeName,
  getBroadcastPreview,
  initBroadcasts,
  initFilterConditions,
  initiateCreatingNewBroadcastFromReport,
  initiateCreatingNewBroadcastFromSurvey,
  initiateSavingCurrentBroadcast,
  launchBroadcast,
  notifySavingBroadcastSucceeded,
  notifyUpdatingBroadcastSucceeded,
  openAudienceDialog,
  openAudiencePreviewDialog,
  openEmailAudienceDialog,
  openGremlinAudienceDialog,
  openPredefinedAudienceDialog,
  openPreviewBroadcastDialog,
  openWorkspaceAudienceDialog,
  setBroadcastPreview,
  setBroadcastPreviewStatus,
  showLaunchBroadcastDialog,
  showLaunchCurrentBroadcastDialog,
  unpublishBroadcast,
} from 'broadcasts/actions';
import {
  BroadcastPreviewFetchStatus,
  BroadcastSaveStatus,
} from 'broadcasts/types';
import {
  initiateNavigationToActiveBroadcastForm,
  initiateNavigationToEditBroadcastForm,
  initiateNavigationToNewBroadcastForm,
  navigateToActiveBroadcastForm,
  navigateToBroadcastOverview,
  navigateToEditBroadcastForm,
  navigateToNewBroadcastForm,
} from 'router/navigationActions';
import { modal } from '@ardoq/modal';
import {
  openExistingPredefinedAudienceDialog,
  openNewPredefinedAudienceDialog,
  setPredefinedReferenceTypeQueryOptions,
  setTableFilter as setPredefinedAudienceTableFilter,
} from './broadcastBuilder/broadcastAudienceForm/dialogs/predefinedAudienceDialog/actions';
import {
  findBroadcast,
  findEmailAudience,
  findGremlinAudience,
  findPredefinedAudience,
  findWorkspaceAudience,
  isPersistedBroadcast,
  isReportContent,
  isRunningBroadcast,
  toComponentMappedPreviewAudience,
} from './utils';
import {
  openExistingWorkspaceAudienceDialog,
  openNewWorkspaceAudienceDialog,
} from './broadcastBuilder/broadcastAudienceForm/dialogs/workspaceAudienceDialog/actions';
import currentBroadcast$ from './currentBroadcast$';
import { prompt } from '@ardoq/modal';
import AudiencePreviewDialog from './broadcastBuilder/broadcastAudienceForm/dialogs/audiencePreviewDialog/AudiencePreviewDialog';
import { fetchAudiencePreview } from './broadcastBuilder/audiencePreview/actions';
import {
  openExistingGremlinAudienceDialog,
  openNewGremlinAudienceDialog,
  setTableFilter as setGremlinAudienceTableFilter,
} from './broadcastBuilder/broadcastAudienceForm/dialogs/gremlinAudienceDialog/actions';
import {
  openExistingEmailAudienceDialog,
  openNewEmailAudienceDialog,
} from './broadcastBuilder/broadcastAudienceForm/dialogs/emailAudienceDialog/actions';
import { from, of } from 'rxjs';
import openBroadcastScheduledDialog from 'broadcasts/components/BroadcastScheduledDialog';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';
import { BroadcastsPane } from './navigation/types';
import {
  ensureCurrentBroadcastMayBeOverwritten$,
  ensureUserIsOkayWithBroadcastBeingUnpublished$,
  fetchBroadcastInstances$,
  fetchBroadcasts$,
  fetchViewpointsForComponentTypeName$,
  getAudiencePreview$,
  handleFetchBroadcastInstancesFailed,
  initiateSavingCurrentBroadcast$,
  openSummaryAndLaunchDialog,
  saveExistingBroadcast$,
  saveNewBroadcast$,
  syncCurrentBroadcastWithBroadcastList$,
} from './routineUtils';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import {
  initReportRules,
  selectAggregate,
  selectReportColumn,
} from './broadcastBuilder/broadcastContentForm/reportRulesSection/actions';
import reports$ from 'streams/reports/reports$';
import BroadcastPreviewModal from './components/BroadcastPreviewModal';
import {
  BroadcastContentType,
  BroadcastAudienceType,
  PredefinedQueryOption,
  WorkspaceAudienceData,
  BroadcastStatus,
} from '@ardoq/api-types';
import { broadcastApi, handleError } from '@ardoq/api';

const handleFetchBroadcasts = routine(
  ofType(navigateToBroadcastOverview),
  switchMap(fetchBroadcasts$),
  catchError((error, caught) => {
    logError(error, 'Failed to fetch broadcasts');
    return caught;
  })
);

const handleFetchBroadcastInstances = routine(
  ofType(fetchBroadcastInstances),
  extractPayload(),
  switchMap(fetchBroadcastInstances$),
  catchError((error, caught) => {
    handleFetchBroadcastInstancesFailed(error);
    return caught;
  })
);

const handleInitBroadcasts = routine(
  ofType(initBroadcasts),
  switchMap(fetchBroadcasts$),
  switchMap(syncCurrentBroadcastWithBroadcastList$),
  withLatestFrom(currentBroadcast$),
  map(([, currentBroadcast]) => currentBroadcast.content),
  tap(content => {
    if (isReportContent(content)) {
      const { aggregate, columnName, predicate, threshold } = content;
      dispatchAction(
        initReportRules({ columnName, aggregate, predicate, threshold })
      );
    } else {
      dispatchAction(initFilterConditions(content.filterConditions));
    }
  }),
  catchError((error, caught) => {
    logError(error, 'Failed to init broadcasts');
    return caught;
  })
);

const handleOpenAudiencePreviewDialog = routine(
  ofType(openAudiencePreviewDialog),
  withLatestFrom(currentBroadcast$),
  tap(([, currentBroadcast]) => {
    dispatchAction(fetchAudiencePreview(currentBroadcast));
  }),
  tap(([, currentBroadcast]) => {
    modal(resolve =>
      createElement(AudiencePreviewDialog, {
        resolve,
        broadcastContentType: currentBroadcast.content.contentType,
      })
    );
  })
);

const handleFetchPredefinedQueryOptions = routine(
  ofType(fetchPredefinedQueryOptions),
  withLatestFrom(content$),
  map(([, content]) => ({ ...content, filterConditions: [] })),
  switchMap(broadcastApi.getPredefinedQueryOptions),
  handleError(),
  tap(response => {
    dispatchAction(setPredefinedReferenceTypeQueryOptions(response.options));
  })
);

const handleInitiateCreatingNewBroadcastFromSurvey = routine(
  ofType(initiateCreatingNewBroadcastFromSurvey),
  extractPayload(),
  switchMap(surveyId =>
    ensureCurrentBroadcastMayBeOverwritten$().pipe(
      tap(() => {
        dispatchAction(navigateToNewBroadcastForm(null));
        dispatchAction(chooseBroadcastContentType(BroadcastContentType.SURVEY));
        dispatchAction(chooseBroadcastContentId(surveyId));
      })
    )
  )
);

const handleInitiateCreatingNewBroadcastFromReport = routine(
  ofType(initiateCreatingNewBroadcastFromReport),
  extractPayload(),
  withLatestFrom(reports$),
  switchMap(([reportId, { byId: reportsById }]) =>
    ensureCurrentBroadcastMayBeOverwritten$().pipe(
      tap(() => {
        const report = reportsById[reportId];
        dispatchAction(navigateToNewBroadcastForm(null));
        dispatchAction(chooseBroadcastContentType(BroadcastContentType.REPORT));
        dispatchAction(chooseBroadcastContentId(reportId));
        if (report?.selectedAggregate) {
          dispatchAction(selectAggregate(report.selectedAggregate));
        }
        const sortedColumn = report?.columns.find(({ sort }) => sort);
        if (sortedColumn) {
          dispatchAction(selectReportColumn(sortedColumn));
        }
      })
    )
  )
);

const handleOpenAudienceDialog = routine(
  ofType(openAudienceDialog),
  extractPayload(),
  tap((audienceType: BroadcastAudienceType) => {
    switch (audienceType) {
      case BroadcastAudienceType.PREDEFINED:
        return dispatchAction(openPredefinedAudienceDialog());
      case BroadcastAudienceType.GREMLIN:
        return dispatchAction(openGremlinAudienceDialog());
      case BroadcastAudienceType.EMAIL:
        return dispatchAction(openEmailAudienceDialog());
      case BroadcastAudienceType.WORKSPACE:
        return dispatchAction(openWorkspaceAudienceDialog());
    }
  })
);

const handleOpenPredefinedAudienceDialog = routine(
  ofType(openPredefinedAudienceDialog),
  withLatestFrom(currentBroadcast$),
  tap(async ([, currentBroadcast]) => {
    dispatchAction(fetchPredefinedQueryOptions());
    const { audiences } = currentBroadcast;
    const currentPredefinedAudience = findPredefinedAudience(audiences);
    if (currentPredefinedAudience) {
      const { options } = currentPredefinedAudience;
      dispatchAction(openExistingPredefinedAudienceDialog(options));
    } else {
      dispatchAction(openNewPredefinedAudienceDialog());
    }
    const options = await modal<PredefinedQueryOption[]>(
      resolve =>
        createElement(PredefinedAudienceDialog, {
          resolve,
          contentType: currentBroadcast.content.contentType,
        }),
      {
        onClose: () => {
          dispatchAction(setPredefinedAudienceTableFilter(''));
        },
      }
    );
    if (!options) return;
    dispatchAction(addOrUpdatePredefinedBroadcastAudience(options));
  })
);

const handleOpenGremlinAudienceDialog = routine(
  ofType(openGremlinAudienceDialog),
  withLatestFrom(currentBroadcast$),
  tap(async ([, currentBroadcast]) => {
    const { audiences } = currentBroadcast;
    const currentGremlinAudience = findGremlinAudience(audiences);
    dispatchAction(
      currentGremlinAudience
        ? openExistingGremlinAudienceDialog(currentGremlinAudience.query)
        : openNewGremlinAudienceDialog()
    );
    const query = await modal<string>(
      resolve =>
        createElement(GremlinAudienceDialog, {
          resolve,
          contentType: currentBroadcast.content.contentType,
        }),
      {
        onClose: () => {
          dispatchAction(setGremlinAudienceTableFilter(''));
        },
      }
    );
    if (!query) return;
    dispatchAction(addOrUpdateGremlinBroadcastAudience(query));
  })
);

const handleOpenEmailAudienceDialog = routine(
  ofType(openEmailAudienceDialog),
  withLatestFrom(currentBroadcast$),
  map(([, { audiences }]) => audiences),
  map(findEmailAudience),
  tap(async currentEmailAudience => {
    dispatchAction(
      currentEmailAudience
        ? openExistingEmailAudienceDialog(currentEmailAudience.emails)
        : openNewEmailAudienceDialog()
    );

    const emails = await modal<string[]>(resolve =>
      createElement(EmailAudienceDialog, { resolve })
    );
    if (!emails) return;
    dispatchAction(addOrUpdateEmailBroadcastAudience(emails));
  })
);

const handleOpenWorkspaceAudienceDialog = routine(
  ofType(openWorkspaceAudienceDialog),
  withLatestFrom(currentBroadcast$),
  tap(async ([, currentBroadcast]) => {
    const { audiences } = currentBroadcast;
    const currentWorkspaceAudience = findWorkspaceAudience(audiences);
    dispatchAction(
      currentWorkspaceAudience
        ? openExistingWorkspaceAudienceDialog(currentWorkspaceAudience)
        : openNewWorkspaceAudienceDialog()
    );
    const workspaceAudienceData = await modal<WorkspaceAudienceData>(resolve =>
      createElement(WorkspaceAudienceDialog, { resolve })
    );
    if (!workspaceAudienceData) return;
    dispatchAction(
      addOrUpdateWorkspaceBroadcastAudience(workspaceAudienceData)
    );
  })
);

const handleInitiateSavingCurrentBroadcast = routine(
  ofType(initiateSavingCurrentBroadcast),
  exhaustMap(initiateSavingCurrentBroadcast$),
  catchError((error, caught) => {
    logError(error, 'Failed to initiate saving current broadcasts');
    return caught;
  })
);

const handleShowLaunchBroadcastDialog = routine(
  ofType(showLaunchBroadcastDialog),
  extractPayload(),
  withLatestFrom(broadcast$),
  map(([broadcastId, { broadcasts }]) =>
    broadcasts.find(broadcast => broadcast._id === broadcastId)
  ),
  excludeNullAndUndefined(),
  exhaustMap(broadcast =>
    zip(of(broadcast), getAudiencePreview$(broadcast).pipe(handleError()))
  ),
  withLatestFrom(reports$),
  exhaustMap(([[broadcast, audiencePreview], { byId: reportsById }]) =>
    openSummaryAndLaunchDialog(
      broadcast,
      toComponentMappedPreviewAudience(audiencePreview),
      reportsById
    )
  )
);

const handleOpenPreviewBroadcastDialog = routine(
  ofType(openPreviewBroadcastDialog),
  withLatestFrom(broadcast$),
  map(broadcasts => broadcasts?.[1].currentBroadcast),
  excludeNullAndUndefined(),
  exhaustMap(broadcast => {
    if (isPersistedBroadcast(broadcast)) {
      return saveExistingBroadcast$(broadcast);
    }
    return from(
      prompt({
        title: 'Save broadcast',
        text: 'Give your broadcast a name before launching it',
        confirmButtonTitle: 'Save',
        textInputId: 'enter-broadcast-name',
        confirmButtonClickId: 'save-broadcast-dialog-box-before-launching',
      })
    ).pipe(
      filter(ExcludeFalsy),
      map(name => ({ ...broadcast, name })),
      exhaustMap(saveNewBroadcast$),
      tap(({ _id }) => dispatchAction(navigateToEditBroadcastForm(_id)))
    );
  }),
  exhaustMap(broadcast =>
    zip(of(broadcast), getAudiencePreview$(broadcast).pipe(handleError()))
  ),
  exhaustMap(([broadcast, audiencePreview]) => {
    return modal(resolve =>
      createElement(BroadcastPreviewModal, {
        resolve,
        broadcastId: broadcast._id,
        audiencePreview: toComponentMappedPreviewAudience(audiencePreview),
      })
    );
  })
);

const handleGetBroadcastPreview = routine(
  ofType(getBroadcastPreview),
  extractPayload(),
  switchMap(({ broadcastId, email }) => {
    dispatchAction(
      setBroadcastPreviewStatus(BroadcastPreviewFetchStatus.LOADING)
    );
    return broadcastApi.previewMail(broadcastId, email);
  }),
  handleError(error => {
    logError(error, 'Failed to show broadcast preview dialog');
    dispatchAction(
      setBroadcastPreviewStatus(BroadcastPreviewFetchStatus.ERROR)
    );
  }),
  tap(broadcastPreview => {
    dispatchAction(setBroadcastPreview(broadcastPreview));
  })
);

const handleShowLaunchCurrentBroadcastDialog = routine(
  ofType(showLaunchCurrentBroadcastDialog),
  withLatestFrom(broadcast$),
  pluck(1),
  filter(({ saveStatus }) => saveStatus !== BroadcastSaveStatus.SAVING),
  pluck('currentBroadcast'),
  excludeNullAndUndefined(),
  exhaustMap(broadcast => {
    if (isPersistedBroadcast(broadcast)) {
      return saveExistingBroadcast$(broadcast);
    }
    return from(
      prompt({
        title: 'Save broadcast',
        text: 'Give your broadcast a name before launching it',
        confirmButtonTitle: 'Save',
        textInputId: 'enter-broadcast-name',
        confirmButtonClickId: 'save-broadcast-dialog-box-before-launching',
      })
    ).pipe(
      filter(ExcludeFalsy),
      map(name => ({ ...broadcast, name })),
      exhaustMap(saveNewBroadcast$),
      tap(({ _id }) => dispatchAction(navigateToEditBroadcastForm(_id)))
    );
  }),
  carry(exhaustMap(broadcast => getAudiencePreview$(broadcast))),
  handleError(error => {
    logError(error, 'Failed to show launch current broadcast dialog');
  }),
  withLatestFrom(reports$),
  exhaustMap(([[broadcast, audiencePreview], { byId: reportsById }]) =>
    openSummaryAndLaunchDialog(
      broadcast,
      toComponentMappedPreviewAudience(audiencePreview),
      reportsById
    )
  )
);

const handleLaunchBroadcast = routine(
  ofType(launchBroadcast),
  extractPayload(),
  switchMap(broadcast => {
    return broadcastApi.setStatus(broadcast._id, BroadcastStatus.RUNNING);
  }),
  handleError(),
  tap(broadcast => {
    dispatchAction(notifyUpdatingBroadcastSucceeded(broadcast));
    dispatchAction(navigateToBroadcastOverview());
  }),
  tap(broadcast => {
    return openBroadcastScheduledDialog(broadcast);
  })
);

const handleNotifySavingBroadcastSucceeded = routine(
  ofType(notifySavingBroadcastSucceeded),
  extractPayload(),
  withLatestFrom(broadcast$, broadcastsNavigation$),
  tap(([broadcast, { currentBroadcast }, { broadcastsPane }]) => {
    if (isPersistedBroadcast(currentBroadcast)) return;
    if (broadcastsPane !== BroadcastsPane.NEW) return;
    dispatchAction(navigateToEditBroadcastForm(broadcast._id));
  })
);

const handleInitiateNavigationToNewBroadcastForm = routine(
  ofType(initiateNavigationToNewBroadcastForm),
  extractPayload(),
  tap(folderId => {
    ensureCurrentBroadcastMayBeOverwritten$();
    dispatchAction(navigateToNewBroadcastForm(folderId));
  })
);

const handleInitiateNavigationToEditBroadcastForm = routine(
  ofType(initiateNavigationToEditBroadcastForm),
  extractPayload(),
  switchMap(broadcastId =>
    ensureCurrentBroadcastMayBeOverwritten$().pipe(
      map(() => broadcastId),
      switchMap(ensureUserIsOkayWithBroadcastBeingUnpublished$),
      tap(() => dispatchAction(navigateToEditBroadcastForm(broadcastId)))
    )
  )
);

const handleNavigateToEditBroadcastForm = routine(
  ofType(navigateToEditBroadcastForm),
  extractPayload(),
  withLatestFrom(broadcast$),
  map(([broadcastId, { broadcasts }]) => {
    return findBroadcast(broadcasts, broadcastId);
  }),
  excludeNullAndUndefined(),
  tap(({ content }) => {
    if (isReportContent(content)) {
      const { aggregate, columnName, predicate, threshold } = content;
      dispatchAction(
        initReportRules({ columnName, aggregate, predicate, threshold })
      );
    } else {
      dispatchAction(initFilterConditions(content.filterConditions));
    }
  }),
  filter(isRunningBroadcast),
  tap(({ _id }) => dispatchAction(unpublishBroadcast(_id)))
);

const handleUnpublishBroadcast = routine(
  ofType(unpublishBroadcast),
  extractPayload(),
  switchMap(broadcastId =>
    broadcastApi.setStatus(broadcastId, BroadcastStatus.UNPUBLISHED)
  ),
  handleError(),
  tap(broadcast => dispatchAction(notifyUpdatingBroadcastSucceeded(broadcast)))
);

const handleInitiateNavigationToActiveBroadcastForm = routine(
  ofType(initiateNavigationToActiveBroadcastForm),
  withLatestFrom(currentBroadcast$),
  switchMap(([, currentBroadcast]) => {
    return isPersistedBroadcast(currentBroadcast)
      ? ensureUserIsOkayWithBroadcastBeingUnpublished$(currentBroadcast._id)
      : of(true);
  }),
  tap(() => dispatchAction(navigateToActiveBroadcastForm()))
);

const handleFetchViewpointsForComponentTypeName = routine(
  ofType(fetchViewpointsForComponentTypeName),
  extractPayload(),
  switchMap(fetchViewpointsForComponentTypeName$),
  catchError((error, caught) => {
    logError(error, 'Failed to fetch viewpoints in broadcast builder');
    return caught;
  })
);

export default collectRoutines(
  handleFetchBroadcasts,
  handleFetchBroadcastInstances,
  handleInitBroadcasts,
  handleFetchPredefinedQueryOptions,
  handleInitiateCreatingNewBroadcastFromSurvey,
  handleOpenAudienceDialog,
  handleOpenPredefinedAudienceDialog,
  handleOpenGremlinAudienceDialog,
  handleOpenEmailAudienceDialog,
  handleOpenWorkspaceAudienceDialog,
  handleOpenAudiencePreviewDialog,
  handleShowLaunchBroadcastDialog,
  handleShowLaunchCurrentBroadcastDialog,
  handleLaunchBroadcast,
  handleInitiateSavingCurrentBroadcast,
  handleNotifySavingBroadcastSucceeded,
  handleNavigateToEditBroadcastForm,
  handleUnpublishBroadcast,
  handleInitiateNavigationToNewBroadcastForm,
  handleInitiateNavigationToEditBroadcastForm,
  handleInitiateNavigationToActiveBroadcastForm,
  handleFetchViewpointsForComponentTypeName,
  handleInitiateCreatingNewBroadcastFromReport,
  handleOpenPreviewBroadcastDialog,
  handleGetBroadcastPreview
);
