import { setupCollapsed$ } from 'streams/setupCollapsed/setupCollapsed$';
import {
  HierarchicalDataSource,
  withHierarchical$,
} from 'components/VirtualWindow/streamBuilders/withHierarchical';
import { withSelection$ } from 'components/VirtualWindow/streamBuilders/withSelection$';
import { scenarioRelatedSelection$ } from './scenarioRelatedSelection$';
import { scenarioRelated$ } from './scenarioRelated$';
import { ByIdItem } from './types';
import { map } from 'rxjs/operators';
import { mainAppModuleSidebar$ } from '../../mainAppModuleSidebar$';
import { combineLatest } from 'rxjs';
import { setupFiltering$ } from 'components/VirtualWindow/streamBuilders/setupFiltering$';
import { withFiltering$ } from 'components/VirtualWindow/streamBuilders/withFiltering$';

const { collapsed$, toggleCollapse, setCollapsed } =
  setupCollapsed$('scenarioRelated');

const { filtered$, setFiltered } = setupFiltering$('scenarioRelated');

const filteredDataSource$ = withFiltering$(scenarioRelated$, filtered$);

const dataSource$ = withHierarchical$<ByIdItem>(
  filteredDataSource$,
  collapsed$
);

const scenarioRelatedDataSource$ = withSelection$<
  HierarchicalDataSource,
  ByIdItem
>(
  dataSource$,
  combineLatest([scenarioRelatedSelection$, mainAppModuleSidebar$]).pipe(
    map(([selection, { showScopeRelated }]) =>
      showScopeRelated ? selection : { byId: {} }
    )
  )
);

export { scenarioRelatedDataSource$, filtered$ };

export { toggleCollapse, setCollapsed, setFiltered };
