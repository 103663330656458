import { modal } from '@ardoq/modal';
import { openPresentationEditor } from 'presentationEditor/presentationUtil';
import PresentationsBrowserDialog from './PresentationsBrowserDialog';
import { trackOpenedPresentationSidebar } from 'presentation/tracking';

interface OpenPresentationsBrowserDialogConfig {
  readonly isOpeningPresentationEditorToAddSlideFromCurrentView: boolean;
}
type OpenPresentationsBrowserDialog = (
  openPresentationsBrowserDialogConfig?: OpenPresentationsBrowserDialogConfig
) => Promise<void>;

export const openPresentationsBrowserDialog: OpenPresentationsBrowserDialog =
  async config => {
    const presentationId = await modal<string>(resolve => (
      <PresentationsBrowserDialog resolve={resolve} />
    ));
    if (presentationId) {
      await openPresentationEditor({
        presentationId,
        isOpeningPresentationEditorToAddSlideFromCurrentView:
          config && config.isOpeningPresentationEditorToAddSlideFromCurrentView,
      });
      trackOpenedPresentationSidebar('Presentation browser', presentationId);
    }
  };
