import { FieldsRow, TextInput } from '@ardoq/forms';
import { DangerButton } from '@ardoq/button';

type PDFLinkEditorProps = {
  label: string;
  link: string;
  onLabelChange: (newLabel: string) => void;
  onLinkChange: (newLink: string) => void;
  onDelete: () => void;
};

const PDFLinkEditor = ({
  link,
  label,
  onLinkChange,
  onLabelChange,
  onDelete,
}: PDFLinkEditorProps) => {
  return (
    <FieldsRow>
      <TextInput label={'Label'} value={label} onValueChange={onLabelChange} />
      <TextInput label={'Link'} value={link} onValueChange={onLinkChange} />
      <DangerButton onClick={onDelete}>Delete</DangerButton>
    </FieldsRow>
  );
};

export default PDFLinkEditor;
