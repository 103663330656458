import { action$, ofType } from '@ardoq/rxbeach';
import { streamInitialized } from 'components/WorkspaceHierarchies/actions/navigatorActions';
import { selectSavedPerspective } from 'perspective/actions';
import { combineLatest, debounceTime, map } from 'rxjs';
import { context$ } from 'streams/context/context$';
import { applicationStarted } from 'streams/context/ContextActions';
import {
  notifyFiltersChanged,
  setActivePerspective,
} from 'streams/filters/FilterActions';
import { notifyModelChanged } from 'streams/models/actions';
import { notifyComponentsUpdated } from 'streams/components/ComponentActions';

export const updateTreeAction$ = combineLatest({
  action: action$.pipe(
    ofType(
      notifyModelChanged,
      selectSavedPerspective,
      applicationStarted,
      notifyFiltersChanged,
      setActivePerspective,
      streamInitialized,
      notifyComponentsUpdated
    )
  ),
  context: context$,
}).pipe(
  debounceTime(16),
  map(({ context }) => context)
);
