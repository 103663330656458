import { SearchInput } from '@ardoq/forms';
import { s16 } from '@ardoq/design-tokens';
import { Chip, ChipsList, ChipsSeparator } from '@ardoq/status-ui';
import styled from 'styled-components';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { Filter } from './filterBarTypes';
import assetsBrowser2024$ from '../assetsBrowser2024$';
import { AssetsBrowser2024StreamShape } from '../assetsBrowser2024Types';
import {
  clearFilter,
  setFilterActive,
  setFilterInactive,
  setSearchPhrase,
} from '../assetsBrowser2024Actions';
import { AppModules } from '../../../appContainer/types';
import { FlexBox, Stack } from '@ardoq/layout';
import { trackEvent } from 'tracking/tracking';

export const FILTER_BAR_HEIGHT = 116;

const filterIsActive = (filter: Filter, activeFilters: Filter[]) =>
  Boolean(
    activeFilters.find(activeFilter => activeFilter.label === filter.label)
  );

const ClearFilterButton = ({ appModule }: { appModule: AppModules }) => (
  <Chip
    data-click-id="clear-filters-chip"
    onClick={() =>
      dispatchAction(clearFilter({ payload: undefined, appModule }))
    }
  >
    Clear
  </Chip>
);

type FilterChipsListProps = {
  filters: Filter[];
  activeFilters: Filter[];
  appModule: AppModules;
};

const FilterChipsList = ({
  filters,
  activeFilters,
  appModule,
}: FilterChipsListProps) => {
  return (
    <ChipsList>
      <ClearFilterButton appModule={appModule} />
      <ChipsSeparator />
      {filters.map(filter => {
        const isFilterActive = filterIsActive(filter, activeFilters);
        return (
          <Chip
            data-click-id={`filter-${filter.label}-chip`}
            isActive={isFilterActive}
            onClick={() => {
              trackEvent('Assets Browser: toggled filter chip', {
                filter: filter.label,
                toggledOn: !isFilterActive,
                appModule,
              });
              if (isFilterActive) {
                dispatchAction(
                  setFilterInactive({
                    payload: filter,
                    appModule,
                  })
                );
                return;
              }
              dispatchAction(
                setFilterActive({
                  payload: filter,
                  appModule,
                })
              );
            }}
            iconName={filter.iconName}
            key={filter.label}
          >
            {filter.label}
          </Chip>
        );
      })}
    </ChipsList>
  );
};

const FilterBarContainer = styled(Stack)`
  gap: ${s16};
  width: 100%;
`;

type FilterBarProps = {
  appModule: AppModules;
  topRightContent?: React.ReactNode;
  filters: Filter[];
} & AssetsBrowser2024StreamShape;

const FilterBar = ({
  appModule,
  topRightContent,
  filters,
  appModuleStates,
}: FilterBarProps) => {
  const { searchPhrase, activeFilters } = appModuleStates[appModule];
  return (
    <FilterBarContainer>
      <FlexBox justify="space-between">
        <SearchInput
          value={searchPhrase}
          onValueChange={newSearchPhrase =>
            dispatchAction(
              setSearchPhrase({
                payload: newSearchPhrase,
                appModule,
              })
            )
          }
          onFocus={() =>
            trackEvent('Assets Browser: used search to filter', {
              appModule,
            })
          }
        />
        {topRightContent}
      </FlexBox>
      {filters.length > 0 && (
        <FilterChipsList
          filters={filters}
          activeFilters={activeFilters}
          appModule={appModule}
        />
      )}
    </FilterBarContainer>
  );
};

export default connect(FilterBar, assetsBrowser2024$);
