import {
  collectRoutines,
  routine,
  extractPayload,
  ofType,
  dispatchAction,
} from '@ardoq/rxbeach';
import {
  expandFolder,
  moveAsset,
  moveAssetWithoutDeselection,
  setIsUpdating,
  setPerPage,
  setSelectedAssets,
} from './assetsBrowser2024Actions';
import { updateAssetsBrowserPerPageSettings } from '../../streams/currentUser/actions';
import { tap } from 'rxjs';
import { moveAssetsToFolder } from '../../appContainer/DashboardContainer/utils';

const handlePerPageChange = routine(
  ofType(setPerPage),
  extractPayload(),
  tap(({ payload, appModule }) => {
    dispatchAction(
      updateAssetsBrowserPerPageSettings({ appModule, perPage: payload })
    );
  })
);

const handleMoveAsset = routine(
  ofType(moveAsset),
  extractPayload(),
  tap(async ({ payload, appModule }) => {
    dispatchAction(setIsUpdating(true));
    await moveAssetsToFolder(payload.assets, payload.folderId);
    dispatchAction(setSelectedAssets({ payload: [], appModule }));
    dispatchAction(setIsUpdating(false));
    if (payload.folderId !== null) {
      dispatchAction(expandFolder({ payload: payload.folderId, appModule }));
    }
  })
);

// Temporary while api does not allow direct in folder creation of assets
const handleMoveWithoutDeselecion = routine(
  ofType(moveAssetWithoutDeselection),
  extractPayload(),
  tap(async ({ payload, appModule }) => {
    dispatchAction(setIsUpdating(true));
    await moveAssetsToFolder(payload.assets, payload.folderId);
    dispatchAction(setIsUpdating(false));
    if (payload.folderId !== null) {
      dispatchAction(expandFolder({ payload: payload.folderId, appModule }));
    }
  })
);

export const assetsBrowser2024Routines = collectRoutines(
  handlePerPageChange,
  handleMoveAsset,
  handleMoveWithoutDeselecion
);
