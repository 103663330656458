import { actionCreator } from '@ardoq/rxbeach';
import {
  ArdoqId,
  APIDashboardColorTheme,
  UnpersistedEntity,
} from '@ardoq/api-types';

const addPrefix = (name: string): `[${string}] ${string}` =>
  `[dashboardColorTheme] ${name}`;

export const createDashboardColorTheme = actionCreator<
  UnpersistedEntity<APIDashboardColorTheme>
>(addPrefix('createDashboardColorTheme'));

export const getDashboardColorThemes = actionCreator(
  addPrefix('getDashboardColorThemes')
);

export const setDashboardColorThemes = actionCreator<APIDashboardColorTheme[]>(
  addPrefix('setDashboardColorThemes')
);

export const updateDashboardColorTheme = actionCreator<APIDashboardColorTheme>(
  addPrefix('updateColorTheme')
);

export const setIsDashboardColorThemesLoading = actionCreator<boolean>(
  addPrefix('setIsDashboardColorThemesLoading')
);

export const deleteDashboardColorTheme = actionCreator<APIDashboardColorTheme>(
  addPrefix('deleteColorTheme')
);

export const selectDashboardColorTheme = actionCreator<ArdoqId>(
  addPrefix('selectDashboardColorTheme')
);

export const saveDashboardColorThemeSelection = actionCreator(
  addPrefix('saveDashboardColorThemeSelection')
);
