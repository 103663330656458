import { NavItem } from '@ardoq/layout';
import { MigrationId } from './types';
import { dispatchAction } from '@ardoq/rxbeach';
import { setActiveMigration } from './actions';
import { trackClickedMigrationChecklistTab } from './tracking';

type MigrationViewTabProps = {
  title: string;
  migrationId: MigrationId;
  activeMigrationId: MigrationId;
};

const MigrationViewTab = ({
  title,
  migrationId,
  activeMigrationId,
}: MigrationViewTabProps) => (
  <NavItem
    $active={activeMigrationId === migrationId}
    onClick={() => {
      trackClickedMigrationChecklistTab(migrationId);
      dispatchAction(setActiveMigration(migrationId));
    }}
  >
    {title}
  </NavItem>
);

export default MigrationViewTab;
