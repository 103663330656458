import { BroadcastContentType } from '@ardoq/api-types';
import { Link } from '@ardoq/typography';

interface BroadcastAudienceDescriptionProps {
  readonly contentType?: BroadcastContentType;
}

const BroadcastAudienceDescription = ({
  contentType,
}: BroadcastAudienceDescriptionProps) => (
  <>
    Choose how to build your audiences:
    <ul style={{ paddingLeft: 16 }}>
      {contentType === BroadcastContentType.REPORT ? (
        <li>Enter emails or select from a people workspace</li>
      ) : (
        <>
          <li>
            For targeted audiences, use a predefined query or a Gremlin query to
            select people connected to the components in scope.{' '}
            {contentType === BroadcastContentType.SURVEY && (
              <>
                In Discover,{' '}
                <Link
                  hideIcon
                  typography="text2"
                  target="_blank"
                  href="https://help.ardoq.com/en/articles/108622-my-tasks-in-ardoq-discover#h_7635f3cc9c"
                >
                  a task
                </Link>{' '}
                will be generated for each component in the survey.
              </>
            )}
          </li>
          <li>
            For general audiences, enter emails or select from a people
            workspace.{' '}
            {contentType === BroadcastContentType.SURVEY &&
              'General audience members can only receive broadcast emails and the survey will not show up in Discover as tasks.'}
          </li>
        </>
      )}
    </ul>
  </>
);

export default BroadcastAudienceDescription;
