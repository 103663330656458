import { PartialConstraint, Validation } from '../../types';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { Select } from '@ardoq/select';
import { Icon, IconName } from '@ardoq/icons';
import { GhostButton } from '@ardoq/button';
import {
  getEndTypeOptions,
  getReferenceOptions,
  getReferenceTypeOptionValue,
  getStartTypeOptions,
  getTripleInTraversalOrder,
} from 'viewpoints/utils';
import styled from 'styled-components';
import { Triple } from '@ardoq/api-types';

const FormGroup = styled.div<{ $hasError?: boolean; $hasWarning?: boolean }>`
  padding: ${s16};
  margin-bottom: ${s16};
  border: 1px dashed
    ${props =>
      props.$hasError
        ? colors.red60
        : props.$hasWarning
          ? colors.yellow60
          : colors.grey80};
  border-radius: ${s8};
  background-color: ${colors.white};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${s8};
`;

const FlexRow = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

type ConstraintFormProps = {
  validate: (index: number) => Validation | undefined;
  hasError?: boolean;
  hasWarning?: boolean;
  canDelete: boolean;
  constraint: PartialConstraint;
  availableTriples: Triple[];
  onChange: (constraint: PartialConstraint) => void;
  onDelete: VoidFunction;
  isDisabled: boolean;
};

const ConstraintForm = ({
  validate,
  hasError,
  hasWarning,
  canDelete,
  constraint,
  availableTriples,
  onChange,
  onDelete,
  isDisabled,
}: ConstraintFormProps) => {
  const [
    currentStartTypeName,
    currentReferenceTypeName,
    currentTargetTypeName,
  ] = getTripleInTraversalOrder(constraint);

  const startTypeOptions = getStartTypeOptions(
    availableTriples,
    constraint,
    isDisabled
  );

  const endTypeOptions = getEndTypeOptions(
    availableTriples,
    constraint,
    isDisabled
  );

  const referenceTypeOptions = getReferenceOptions(
    availableTriples,
    constraint,
    isDisabled
  );
  return (
    <FormGroup $hasError={hasError} $hasWarning={hasWarning}>
      <Wrapper>
        <FlexRow>
          <Select
            dataTestId="component-type-select-1"
            hasError={validate(0)?.hasError}
            errorMessage={validate(0)?.errorMessage}
            hasWarning={validate(0)?.hasWarning}
            value={currentStartTypeName}
            options={startTypeOptions}
            onValueChange={value => {
              const selectedOption = startTypeOptions.find(
                option => option.value === value
              );
              if (!selectedOption) return;
              onChange(selectedOption.constraint);
            }}
            placeholder="Component type"
          />
        </FlexRow>
        <FlexRow>
          <Select
            dataTestId="reference-type-select"
            hasError={validate(1)?.hasError}
            errorMessage={validate(1)?.errorMessage}
            hasWarning={validate(1)?.hasWarning}
            value={getReferenceTypeOptionValue(
              currentReferenceTypeName,
              constraint.direction
            )}
            options={referenceTypeOptions}
            onValueChange={value => {
              const selectedOption = referenceTypeOptions.find(
                option => option.value === value
              );
              if (!selectedOption) return;
              onChange(selectedOption.constraint);
            }}
            isDisabled={currentStartTypeName === null}
            placeholder="Reference type"
          />
        </FlexRow>
        <FlexRow>
          <Select
            dataTestId="component-type-select-2"
            hasError={validate(2)?.hasError}
            errorMessage={validate(2)?.errorMessage}
            hasWarning={validate(2)?.hasWarning}
            value={currentTargetTypeName}
            options={endTypeOptions}
            onValueChange={value => {
              const selectedOption = endTypeOptions.find(
                option => option.value === value
              );
              if (!selectedOption) return;
              onChange(selectedOption.constraint);
            }}
            isDisabled={currentReferenceTypeName === null}
            placeholder="Component type"
          />
        </FlexRow>
        {canDelete && (
          <GhostButton
            data-tooltip-text="Delete triple"
            onClick={onDelete}
            dataTestId="delete-triple-button"
          >
            <Icon iconName={IconName.CLOSE} />
          </GhostButton>
        )}
      </Wrapper>
    </FormGroup>
  );
};

export default ConstraintForm;
