import {
  APIComponentAttributes,
  ArdoqId,
  DeletedOnMainlineData,
} from '@ardoq/api-types';
import { connect } from '@ardoq/rxbeach';
import { Column, DatasourceTable, FlyWheelTable } from '@ardoq/table';
import changeApprovalData$ from '../changeApprovalData$';
import { combineLatest, map } from 'rxjs';
import { formatDateTime } from '@ardoq/date-time';
import surveyAdmin$ from 'surveyAdmin/streams/surveyAdmin$';
import { getCurrentLocale } from '@ardoq/locale';
import { EnhancedScopeData } from '@ardoq/data-model';
import { ChangeApprovalCommands, changeApprovalCommands } from '../commands';
import { confirm } from '@ardoq/modal';
import { IconName } from '@ardoq/icons';
import { Tag, StatusType, InfoNotification } from '@ardoq/status-ui';
import {
  pluralize,
  pickCorrectPlural,
  PluralOperand,
} from '@ardoq/common-helpers';
import { trackEvent } from 'tracking/tracking';
import { InvalidChangeRequest } from '../types';
import { Island } from '@ardoq/page-layout';
import { Stack } from '@ardoq/layout';
import styled from 'styled-components';

const getReasonForInvalidChangeRequest = (
  invalidChangeRequest: InvalidChangeRequest,
  deletedOnMainlineIds: DeletedOnMainlineData | null
) => {
  if (deletedOnMainlineIds?.components.includes(invalidChangeRequest.component))
    return 'Deleted on mainline';
  if (invalidChangeRequest.missingRequiredData) return 'Missing required data';
  return 'Unknown';
};

const openInvalidChangeRequestModal = (
  invalidComponentData: APIComponentAttributes
) => {
  return confirm({
    title: 'Clear pending answers for this component?',
    text: (
      <Stack gap="small">
        <span>
          Are you sure you want to clear the pending answers for the component{' '}
          {`"${invalidComponentData?.name ?? 'Unknown'}"`}?
        </span>
        <span>
          <strong>This action cannot be undone</strong>.
        </span>
        <span>Any pending answers related to this component will be lost.</span>
      </Stack>
    ),
    isConfirmButtonDanger: true,
    confirmButtonTitle: 'Delete',
    cancelButtonTitle: 'Cancel',
  });
};

const UnstyledButton = styled.button`
  cursor: pointer;
  background-color: inherit;
  border: none;
  text-align: left;
  &:hover {
    text-decoration: underline;
  }
`;

type InvalidChangeRequestsTableProps = {
  surveyId: ArdoqId | null;
  invalidChangeRequests: InvalidChangeRequest[];
  masterData: EnhancedScopeData;
  branchData: EnhancedScopeData;
  commands: ChangeApprovalCommands;
  deletedOnMainlineIds: DeletedOnMainlineData | null;
};

const InvalidChangeRequestsTable = ({
  surveyId,
  invalidChangeRequests,
  masterData,
  branchData,
  commands,
  deletedOnMainlineIds,
}: InvalidChangeRequestsTableProps) => {
  if (!invalidChangeRequests.length) return null;
  const componentWord = pluralize('component', invalidChangeRequests.length);
  const commonColumns: Column<InvalidChangeRequest>[] = [
    {
      title: 'Component',
      cellStyle: {
        maxWidth: '300px',
      },
      valueRender: (_any: any, invalidChangeRequest) => {
        const relevantComponentData =
          masterData.componentsById[invalidChangeRequest.component] ??
          branchData.componentsById[invalidChangeRequest.component];
        return (
          <UnstyledButton
            onClick={async () => {
              const confirmDeleteInvalidData =
                await openInvalidChangeRequestModal(relevantComponentData);
              if (!confirmDeleteInvalidData || !surveyId) return;
              commands.resetInvalidChangeRequest({
                surveyId,
                componentId: invalidChangeRequest.component,
              });
              trackEvent(
                'Change approval: cleared pending answers for invalid component',
                { componentId: invalidChangeRequest.component }
              );
            }}
          >
            {relevantComponentData?.name ?? 'Unknown'}
          </UnstyledButton>
        );
      },
    },
    {
      title: 'Status',
      headerStyle: { width: 200 },
      valueRender: () => (
        <Tag
          label="Error"
          iconName={IconName.ERROR}
          statusType={StatusType.ERROR}
        />
      ),
    },
    {
      title: 'Last updated',
      headerStyle: { width: 200 },
      dataIndex: 'lastUpdated',
      valueRender: value => formatDateTime(value, getCurrentLocale()),
    },
    {
      title: 'Reason',
      headerStyle: { width: 200 },
      valueRender: (_any: any, invalidChangeRequest) =>
        getReasonForInvalidChangeRequest(
          invalidChangeRequest,
          deletedOnMainlineIds
        ),
    },
  ];
  return (
    <Island preventScroll>
      <InfoNotification>
        <div>
          The below {componentWord}{' '}
          {pickCorrectPlural(
            {
              [PluralOperand.ONE]: 'is',
              [PluralOperand.OTHER]: 'are',
            },
            invalidChangeRequests.length
          )}{' '}
          in an error state and could not be added to the change approval
          process.
        </div>
        <div>
          Please contact customer support if you would like us to investigate
          this further, or clear the pending answers for the {componentWord} by
          clicking on them.
        </div>
      </InfoNotification>
      <DatasourceTable
        dataSource={invalidChangeRequests}
        components={FlyWheelTable}
        columns={commonColumns}
        fixedHeader
      />
    </Island>
  );
};

const invalidChangeRequestsTable$ = combineLatest([
  surveyAdmin$,
  changeApprovalData$,
]).pipe(
  map(
    ([
      { surveyId },
      { masterData, branchData, invalidChangeRequests, deletedOnMainlineIds },
    ]) => {
      return {
        masterData,
        branchData,
        invalidChangeRequests,
        surveyId,
        commands: changeApprovalCommands,
        deletedOnMainlineIds,
      };
    }
  )
);

export default connect(InvalidChangeRequestsTable, invalidChangeRequestsTable$);
