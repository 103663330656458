import { IconName, ArdoqIconCategory } from '@ardoq/icons';

export const DEFAULT_NUMBER_OF_ROWS_PER_PAGE = 20;
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const DEFAULT_COMPONENT_REPRESENTATION_DATA = {
  isImage: false,
  value: '',
  icon: {
    category: ArdoqIconCategory.FontAwesome,
    id: IconName.SHOW_COMPONENT,
    label: 'Component Type',
  },
};
