import { filter, tap } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { Vpc } from '@ardoq/api-types/integrations';
import {
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getAsyncRequestId } from 'integrations/common/async/utils';
import { AsyncOperations } from 'integrations/common/async/constants';
import {
  selectConnection,
  updateSelectedConnectionsIds,
} from 'integrations/common/streams/connections/actions';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import {
  changeRegionsSelection,
  fetchRegions,
} from 'integrations/cloudProviders/streams/regions/actions';
import { fetchResourceTypes } from 'integrations/cloudProviders/streams/resourceTypes/actions';
import { vpcs$ } from './vpcs$';
import {
  changeVpcsSelection,
  fetchVpcsSuccess,
  getVpcs,
  setAsyncStatus,
  unselectUnavailableVpcs,
} from './actions';
import { fetchVpcs } from './apiUtils';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';

const handleUpdateSelectedConnections = routine(
  ofType(updateSelectedConnectionsIds),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'aws-v3'),
  tap(() => {
    dispatchAction(
      trackIntegrationEvent({
        integrationId: 'aws-v3',
        name: 'SELECTED_CONNECTION',
      })
    );
  })
);

const handleSelectFirstConnection = routine(
  ofType(selectConnection),
  extractPayload(),
  withLatestFrom(getConnectionsStream('aws-v3')),
  filter(
    ([{ integrationId }, { selectedConnectionIds }]) =>
      integrationId === 'aws-v3' && selectedConnectionIds.length === 0
  ),
  tap(([{ selectedConnectionId }]) => {
    if (!selectedConnectionId) {
      return;
    }

    dispatchAction(
      fetchRegions({
        integrationId: 'aws-v3',
        connectionId: selectedConnectionId,
      })
    );

    dispatchAction(
      fetchResourceTypes({
        integrationId: 'aws-v3',
      })
    );

    dispatchAction(
      trackIntegrationEvent({
        integrationId: 'aws-v3',
        name: 'SELECTED_CONNECTION',
      })
    );
  })
);

const handleChangeRegionsSelection = routine(
  ofType(changeRegionsSelection),
  extractPayload(),
  withLatestFrom(getConnectionsStream('aws-v3')),
  filter(([{ integrationId }]) => integrationId === 'aws-v3'),
  tap(([{ regions }, { selectedConnectionIds }]) => {
    regions.forEach(region => {
      selectedConnectionIds.forEach(connectionId => {
        dispatchAction(getVpcs({ connectionId, region }));
      });
    });
    dispatchAction(
      unselectUnavailableVpcs({ connectionIds: selectedConnectionIds, regions })
    );
  })
);

const handleSelectConnection = routine(
  ofType(updateSelectedConnectionsIds),
  extractPayload(),
  withLatestFrom(getRegionsStream('aws-v3')),
  filter(([{ integrationId }]) => integrationId === 'aws-v3'),
  tap(([{ selectedConnectionIds }, { selectedRegionIds }]) => {
    selectedConnectionIds.forEach(selectedConnectionId => {
      selectedRegionIds.forEach(region => {
        dispatchAction(
          getVpcs({
            connectionId: selectedConnectionId,
            region: region,
          })
        );
      });
    });
    dispatchAction(
      unselectUnavailableVpcs({
        connectionIds: selectedConnectionIds,
        regions: selectedRegionIds,
      })
    );
  })
);

const handleFetchVpcs = routine(
  ofType(getVpcs),
  extractPayload(),
  withLatestFrom(vpcs$, getActiveIntegrationStream('aws-v3')),
  filter(([{ connectionId, region }, { connectionsVpcs }]) => {
    return (
      !connectionsVpcs[connectionId] || !connectionsVpcs[connectionId][region]
    );
  }),
  mergeMap(([{ connectionId, region }, _, { trackingFunnelId }]) => {
    const requestId = getAsyncRequestId({
      integrationId: 'aws-v3',
      operation: AsyncOperations.AWS_FETCH_VPCS,
      funnelId: trackingFunnelId,
      resourceId: `${connectionId}|${region}`,
    });
    dispatchAction(
      setAsyncStatus({
        connectionId: connectionId,
        asyncStatus: 'LOADING',
        region: region,
      })
    );
    return retriableRequest(() =>
      fetchVpcs(connectionId, requestId, false, region)
    ).pipe(
      catchError(error => {
        dispatchAction(
          setAsyncStatus({
            connectionId,
            region,
            asyncStatus: 'FAILURE',
          })
        );
        return handleApiError(error);
      })
    );
  }),
  tap(({ async, connectionId, region, vpcs }) => {
    if (!async) {
      dispatchAction(
        fetchVpcsSuccess({
          connectionId,
          vpcs: vpcs,
          region,
        })
      );
    }
  })
);

const handleUnselectUnavailableVpcs = routine(
  ofType(unselectUnavailableVpcs),
  extractPayload(),
  withLatestFrom(vpcs$),
  tap(([{ connectionIds, regions }, { connectionsVpcs, selectedVpcIds }]) => {
    const newlySelectedVpcIds: string[] = connectionIds.reduce(
      (acc: string[], connectionId) => {
        return acc.concat(
          regions.flatMap(
            region =>
              connectionsVpcs[connectionId]?.[region]?.vpcs
                ?.filter(
                  (vpc: Vpc) =>
                    selectedVpcIds.includes(vpc.vpcId) &&
                    !acc.includes(vpc.vpcId)
                )
                .map(vpc => vpc.vpcId) || []
          )
        );
      },
      []
    );

    dispatchAction(
      changeVpcsSelection({
        selectedVpcIds: newlySelectedVpcIds,
      })
    );
  })
);

export default [
  handleSelectFirstConnection,
  handleUpdateSelectedConnections,
  handleFetchVpcs,
  handleChangeRegionsSelection,
  handleSelectConnection,
  handleUnselectUnavailableVpcs,
];
