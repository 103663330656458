import {
  FormWrapper,
  RadioGroup,
  RadioGroupValue,
  TextInput,
} from '@ardoq/forms';
import { useState } from 'react';
import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { dispatchAction } from '@ardoq/rxbeach';
import { dashboardCopied } from '../streams/dashboards/actions';
import {
  CopyModeOptions,
  CopyModeRadioGroupProps,
  CopyModalResult,
  CopyDialogProps,
} from './types';
import { lowerCase } from 'lodash';
import { trackEvent } from 'tracking/tracking';
import { AssetType } from '@ardoq/api-types';

const copyModeSelectOptions = [
  {
    label: 'Copy dashboard with all linked reports and surveys',
    value: CopyModeOptions.FULL,
  },
  {
    label: 'Copy dashboard only',
    value: CopyModeOptions.ONLY_DASHBOARD,
  },
];

const CopyModeRadioGroup = ({
  selectedMode,
  onChange,
}: CopyModeRadioGroupProps) => {
  const options: { label: string; value: CopyModeOptions }[] =
    copyModeSelectOptions;

  return (
    <RadioGroup
      options={options}
      onValueChange={onChange as (value: RadioGroupValue) => void}
      value={selectedMode}
    />
  );
};

/**
 * If shallow copy selected, the newName must be non-empty.
 */
const isValidShallowCopy = (isShallowCopy: boolean, newName: string): boolean =>
  isShallowCopy && Boolean(newName.trim());

/**
 * If deep copy selected, newName and targetFolderName must be non-empty. Or, allowEmptyInputs must be true.
 */
const isValidDeepCopy = (
  newName: string,
  targetFolderName: string,
  allowEmptyInputs?: boolean
): boolean =>
  allowEmptyInputs || Boolean(newName.trim() && targetFolderName.trim());

const isValidCopy = (
  isShallowCopy: boolean,
  newName: string,
  targetFolderName: string,
  allowEmptyInputs?: boolean
): boolean =>
  isValidShallowCopy(isShallowCopy, newName) ||
  isValidDeepCopy(newName, targetFolderName, allowEmptyInputs);

export const CopyDialog = ({
  name,
  firstEntityType,
  secondEntityType,
  isEmptyInputsAllowed: allowEmptyInputs,
  onConfirm,
  onCancel,
}: CopyDialogProps) => {
  const [newName, setNewName] = useState<string>(`${name} (copy)`);
  const [targetFolderName, setTargetFolderName] = useState<string>(name);
  const [isShallowCopy, setIsShallowCopy] = useState<boolean>(false);

  return (
    <ModalTemplate
      modalSize={ModalSize.S}
      headerText={`Copy "${name}" ${lowerCase(firstEntityType)}`}
      text={`Choose to copy just the dashboard, or include all linked reports and surveys for future editing.`}
      onPrimaryButtonClick={() => {
        onConfirm(isShallowCopy, newName, targetFolderName);
      }}
      onSecondaryButtonClick={onCancel}
      primaryButtonText="Copy dashboard"
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={
        !isValidCopy(isShallowCopy, newName, targetFolderName, allowEmptyInputs)
      }
    >
      <ModalLayout>
        <CopyModeRadioGroup
          selectedMode={
            isShallowCopy
              ? CopyModeOptions.ONLY_DASHBOARD
              : CopyModeOptions.FULL
          }
          onChange={value => {
            setIsShallowCopy(value === CopyModeOptions.ONLY_DASHBOARD);
          }}
        />
        <FormWrapper>
          <TextInput
            label={`New dashboard name`}
            value={newName}
            onValueChange={setNewName}
          />
          {!isShallowCopy ? (
            <TextInput
              label={`New ${lowerCase(secondEntityType)} name`}
              value={targetFolderName ?? name}
              onValueChange={setTargetFolderName}
            />
          ) : null}
        </FormWrapper>
      </ModalLayout>
    </ModalTemplate>
  );
};

export const confirmDashboardCopy = ({
  dashboardName,
  dashboardId,
  allowEmptyInputs,
}: {
  dashboardName: string;
  dashboardId: string;
  allowEmptyInputs?: boolean;
}) => {
  return modal<CopyModalResult>(resolve => {
    const onConfirm = (
      shallowCopy: boolean,
      newName: string,
      targetFolderName: string
    ) => {
      trackEvent(
        `Copy modal: confirmed copy asset${shallowCopy ? ' (shallow)' : ''}`,
        {
          assetType: AssetType.DASHBOARD,
        }
      );
      dispatchAction(
        dashboardCopied({
          dashboardId,
          dashboardName: newName,
          folderName: shallowCopy ? null : targetFolderName,
          isShallowCopy: shallowCopy,
        })
      );
      resolve({ newName, targetFolderName });
    };
    return (
      <CopyDialog
        name={dashboardName}
        firstEntityType={'Dashboard'}
        secondEntityType={'Folder'}
        isEmptyInputsAllowed={allowEmptyInputs}
        onConfirm={onConfirm}
        onCancel={() => {
          resolve(false);
        }}
      />
    );
  });
};
