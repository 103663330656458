import { PerspectivesEditorState } from '../types';
import { PerspectiveTab } from '@ardoq/perspectives-sidebar';

export const calculateRulesCountPerTab = (
  state: PerspectivesEditorState
): Record<PerspectiveTab, number> => {
  return {
    [PerspectiveTab.GROUPS]: state.groupsTab.groupingRules.length,
    [PerspectiveTab.LABELS]: state.formattingTab.labelFormatting.length,
    [PerspectiveTab.FORMATTING]:
      state.formattingTab.conditionalFormattingRules.length,
  };
};
