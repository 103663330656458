import { filter } from 'rxjs';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import {
  transferRun,
  importRun,
  exportRun,
} from 'integrations/common/streams/transferState/actions';
import { transferState$ } from 'integrations/common/streams/transferState/getTransferStateStream';
import { TransferDirection } from 'integrations/common/streams/transferState/types';

const handleTransferRun = routine(
  ofType(transferRun),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'excel-v3'),
  withLatestFrom(transferState$),
  map(([{ async, integrationId, dryRun }, transferState]) => ({
    async,
    integrationId,
    dryRun,
    transferState: transferState[integrationId],
  })),
  tap(({ async, integrationId, dryRun, transferState }) =>
    transferState.transferDirection === TransferDirection.IMPORT
      ? dispatchAction(importRun({ integrationId, async, dryRun }))
      : dispatchAction(exportRun({ integrationId, async, dryRun }))
  )
);

export default [handleTransferRun];
