import { ResponseDataCell } from '@ardoq/api-types/integrations';
import { TableDataRow } from './types';
import * as _ from 'lodash/fp';

export const getAdjustedRows = ({
  newRows,
  currentRows = [],
}: {
  newRows: TableDataRow[];
  currentRows?: TableDataRow[];
}) => {
  const adjustedRows: [number, ...string[]][] = _.flow(
    _.concat(currentRows),
    _.groupBy(_.head),
    _.map(_.last),
    _.sortBy(_.head)
  )(newRows);

  return adjustedRows;
};

export const convertRowValuesToString = (row: ResponseDataCell[]) => {
  return row.map((value, index) => {
    if (index === 0) {
      return value;
    }

    if (value === null) {
      return '';
    }

    if (_.isPlainObject(value)) {
      return JSON.stringify(value);
    }

    return String(value);
  }) as TableDataRow;
};

export const convertHeadersToString = (name: ResponseDataCell) => {
  return name === null ? '' : String(name);
};
