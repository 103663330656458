import {
  APIFieldType,
  BroadcastAggregate,
  ReportColumnType,
  ReportRuleOperator,
  ReportColumn,
} from '@ardoq/api-types';
import {
  broadcastAggregatesWithLabel,
  reportRuleOperatorLabels,
  ReportRulesSection$State,
} from './types';

export const isValidBroadcastReportColumn = (column: ReportColumn) =>
  column.type !== ReportColumnType.REFERENCE_TYPE_INCOMING &&
  column.type !== ReportColumnType.REFERENCE_TYPE_OUTGOING &&
  !column.isDateRangeColumn;

export const isValidAggregateForColumnType = (
  reportColumn: ReportColumn | undefined,
  aggregate: BroadcastAggregate
) =>
  reportColumn?.dataType === APIFieldType.NUMBER ||
  aggregate === BroadcastAggregate.COUNT_NOT_SET ||
  aggregate === BroadcastAggregate.COUNT ||
  aggregate === BroadcastAggregate.COUNT_SET;

export const getDefaultReportRulesState: () => ReportRulesSection$State =
  () => ({
    columnName: null,
    aggregate: null,
    predicate: null,
    threshold: null,
  });

export const getRulesAsText = (
  columnLabel: string,
  aggregate: BroadcastAggregate,
  predicate: ReportRuleOperator,
  threshold: number
) =>
  `The broadcast will be automatically sent to your audience when the ${broadcastAggregatesWithLabel[aggregate]} of ${columnLabel} is ${reportRuleOperatorLabels[predicate]} the threshold of ${threshold}`;
