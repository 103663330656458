import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId, UseCaseStatus } from '@ardoq/api-types';
import { UseCaseLandingPageTab } from './types';
import { ArdoqError } from '@ardoq/common-helpers';

export const addPrefix = (action: string): `[${string}] ${string}` =>
  `[useCaseLandingPage] ${action}`;

export const selectUseCase = actionCreator<ArdoqId>(
  addPrefix('SELECT_USE_CASE')
);

export const loadUseCase = actionCreator<ArdoqId>(
  addPrefix('LOAD_USE_CASE_BUNDLE')
);

export const loadUseCaseSuccess = actionCreator<ArdoqId>(
  addPrefix('LOAD_USE_CASE_BUNDLE_SUCCESS')
);

export const loadUseCaseFailed = actionCreator<ArdoqError>(
  addPrefix('LOAD_USE_CASE_BUNDLE_FAILED')
);

export const setActiveLandingPageTab = actionCreator<UseCaseLandingPageTab>(
  addPrefix('SET_ACTIVE_LANDING_PAGE_TAB')
);

export const getUseCase = actionCreator<{ id: ArdoqId; status: UseCaseStatus }>(
  addPrefix('GET_USE_CASE_PREVIEW_ASSETS')
);

export const setCompany = actionCreator<string>(addPrefix('SET_COMPANY'));
export const setDescription = actionCreator<string>(
  addPrefix('SET_DESCRIPTION')
);
export const setValueProposition = actionCreator<string>(
  addPrefix('SET_VALUE_PROPOSITION')
);

export const submitQuickStartModal = actionCreator<{
  showVP: boolean;
  company: string;
  description: string;
  valueProposition: string;
  closeModal: (success: boolean) => void;
}>(addPrefix('SUBMIT_QUICKSTART_MODAL'));

export const generateValueProposition = actionCreator<{
  company: string;
  description: string;
}>(addPrefix('GENERATE_VALUE_PROPOSITION'));
