import { Box } from '@ardoq/layout';

export const TabBox = ({
  children,
  skipPaddingLeft,
}: React.PropsWithChildren<{
  skipPaddingLeft?: boolean;
}>) => {
  return (
    <Box
      height="full"
      width="full"
      borderColor="borderSubtle00"
      borderRadius="r8"
      padding="medium"
      paddingLeft={skipPaddingLeft ? 'none' : 'medium'}
    >
      {children}
    </Box>
  );
};
