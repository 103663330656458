import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  navigateToDashboardModule,
  NavigateToDashboardModulePayload,
} from '../../router/navigationActions';
import { DashboardRoute } from '../../router/appRouterTypes';
import { DashboardModule } from 'dashboard/types';
import {
  setDashboardBranchId,
  SetDashboardBranchIdPayload,
} from 'dashboard/DashboardBuilder/actions';

const setDashboardModuleAndSelectedDashboardId = (
  state: DashboardRoute,
  { selectedDashboardId, dashboardModule }: NavigateToDashboardModulePayload
): DashboardRoute => ({
  ...state,
  selectedDashboardId: selectedDashboardId
    ? selectedDashboardId
    : state.selectedDashboardId,
  dashboardModule,
});

const setBranchId = (
  state: DashboardRoute,
  payload: SetDashboardBranchIdPayload
): DashboardRoute => ({
  ...state,
  branchId: payload.branchId,
});

const reducers = [
  reducer(navigateToDashboardModule, setDashboardModuleAndSelectedDashboardId),
  reducer(setDashboardBranchId, setBranchId),
];

const dashboardNavigation$ = persistentReducedStream(
  'dashboardNavigation$',
  {
    dashboardModule: DashboardModule.OVERVIEW,
    branchId: null,
  },
  reducers
);

export default dashboardNavigation$;
