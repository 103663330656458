import {
  ObservableState,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import { selectionToggle } from 'components/WorkspaceHierarchies/actions/navigatorActions';
import {
  componentTreeSelectionChanged,
  componentTreeSelectionStartSelected,
} from 'componentSelection/componentSelectionActions';
import { actionNamespaceComponentHierarchy } from 'componentHierarchies/consts';
import { ComponentSelection } from 'componentSelection/componentSelectionTypes';
import { componentSelectionOperations } from 'componentSelection/componentSelectionOperations';

export const componentHierarchySelection$: ObservableState<ComponentSelection> =
  persistentReducedStream(
    'componentHierarchySelection$',
    {
      graphSelection: [],
      treeSelection: [],
      selectionStart: '',
    },
    [
      reducer(
        componentTreeSelectionStartSelected,
        componentSelectionOperations.componentTreeSelectionStartSelected
      ),
      reducer(
        componentTreeSelectionChanged,
        componentSelectionOperations.componentTreeSelectionChanged
      ),
      reducer(
        selectionToggle,
        componentSelectionOperations.toggleTreeSelection
      ),
    ],
    { namespace: actionNamespaceComponentHierarchy }
  );
