import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { awsIntegrationApi } from '@ardoq/api';
import { switchMap, tap } from 'rxjs';
import { retriableRequest } from 'integrations/common/utils/api';
import {
  fetchAwsSettings,
  fetchAwsSettingsSuccess,
  setSettingsAsyncStatus,
} from './actions';

const handleFetchAwsSettingsList = routine(
  ofType(fetchAwsSettings),
  extractPayload(),
  switchMap(() => {
    dispatchAction(setSettingsAsyncStatus('LOADING'));
    return retriableRequest(() => awsIntegrationApi.fetchSettings());
  }),
  tap(response => {
    dispatchAction(fetchAwsSettingsSuccess({ settings: response }));
  })
);

export default [handleFetchAwsSettingsList];
