import {
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { reportBuilderOperations, ReportTemplate } from '@ardoq/report-builder';
import {
  APISearchAggregatesResponse,
  ArdoqId,
  Report,
  ZonesBySubdivisionsIds,
} from '@ardoq/api-types';
import {
  advancedSearchQueryChanged,
  AdvancedSearchQueryChangedPayload,
  changeWorkspacesWithNoAccess,
  finishedFetchingCustomFields,
  newReportPropertySelected,
  newWorkspaceSelected,
  newZoneSelected,
  reportBuilderSortingWasChanged,
  reportChangesWereDiscarded,
  reportHistoricalDataWasDiscarded,
  reportQueryWasValidated,
  reportSaveButtonWasClickedOnNewReport,
  reportSaveFailed,
  reportSearchFinished,
  reportSearchAggregatesWasTriggered,
  reportSearchAggregatesFinished,
  reportSearchWasTriggered,
  reportWasInvalidOnSaveButtonClick,
  reportWasSaved,
  sectionWasValidated,
  SectionWasValidatedPayload,
} from './actions';
import {
  InitializeReportBuilderForm$State,
  ReportBuilderFormState,
} from './types';
import initializeReport$ from './initializeReportBuilderForm$';
import {
  navigateToReportBuilder,
  NavigateToReportBuilderPayload,
} from '../../router/navigationActions';
import reportHasHistoricalData$ from './reportHasHistoricalData$';
import { omit } from 'lodash';
import { EnhancedSearchResponse, ReportSort } from '@ardoq/report-reader';
import {
  getArdoqErrorMessage,
  isArdoqError,
  Result,
} from '@ardoq/common-helpers';

const initializeReportForm = (
  state: ReportBuilderFormState,
  newReportBuilderFormState: InitializeReportBuilderForm$State
): ReportBuilderFormState => {
  if (
    state.persistedReport &&
    newReportBuilderFormState.persistedReport &&
    state.persistedReport._id === newReportBuilderFormState.persistedReport._id
  ) {
    return {
      ...state,
      ...newReportBuilderFormState,
      persistedReport: {
        ...newReportBuilderFormState.persistedReport,
        hasHistoricalData: state.persistedReport.hasHistoricalData, // Fetching this might finish before fetching the data in the initializeReportBuilder$
      },
      isInitialized: true,
    };
  }
  return {
    ...state,
    ...newReportBuilderFormState,
    searchResults: null,
    isInitialized: true,
  };
};

const setSimplePropertyOnReport = (
  state: ReportBuilderFormState,
  payload: Partial<ReportTemplate>
): ReportBuilderFormState => ({
  ...state,
  reportTemplate: { ...state.reportTemplate, ...payload },
});

const setPersistedReport = (
  state: ReportBuilderFormState,
  persistedReport: Report
): ReportBuilderFormState => ({
  ...state,
  isSubmitting: false,
  errorFromSave: null,
  persistedReport: { ...state.persistedReport, ...persistedReport },
  reportTemplate: {
    ...state.reportTemplate,
    columns: [...persistedReport.columns],
    updatedColumnNames: {}, // no unsaved columns names updates after report saved succcessfully (persistedReport)
  },
});

const setHasHistoricalDataOnPersistedReport = (
  state: ReportBuilderFormState,
  reportWithHistoricalData: Report
): ReportBuilderFormState => {
  if (!state.persistedReport) {
    return { ...state, persistedReport: reportWithHistoricalData };
  } else if (state.persistedReport._id === reportWithHistoricalData._id) {
    return {
      ...state,
      persistedReport: {
        ...state.persistedReport,
        hasHistoricalData: reportWithHistoricalData.hasHistoricalData,
      },
    };
  }
  return state;
};

const shouldReset = (
  state: ReportBuilderFormState,
  payload: NavigateToReportBuilderPayload
) =>
  // If we are navigating to a new report, we should reset the form before initializing it again. Avoids onChange triggering mistakenly in the advanced search query builder
  'reportId' in payload && state.persistedReport?._id !== payload.reportId;

const maybeResetState = (
  state: ReportBuilderFormState,
  payload: NavigateToReportBuilderPayload
) => (shouldReset(state, payload) ? getInitialState() : state);

const setIsSubmitting = (
  state: ReportBuilderFormState
): ReportBuilderFormState => ({
  ...state,
  isSubmitting: true,
});

const setHasHistoricalDataFalse = (
  state: ReportBuilderFormState
): ReportBuilderFormState => ({
  ...state,
  persistedReport: state.persistedReport
    ? { ...state.persistedReport, hasHistoricalData: false }
    : null,
});

const setErrorFromSave = (
  state: ReportBuilderFormState,
  errorFromSave: string
): ReportBuilderFormState => ({
  ...state,
  isSubmitting: false,
  errorFromSave,
});

const setShowErrorsOnInputs = (
  state: ReportBuilderFormState
): ReportBuilderFormState => ({
  ...state,
  showErrorsOnInputs: true,
});

const addSectionToArrayIfError = (
  { sectionsWithErrors, ...state }: ReportBuilderFormState,
  { section, hasError }: SectionWasValidatedPayload
): ReportBuilderFormState => ({
  ...state,
  sectionsWithErrors:
    hasError && !sectionsWithErrors.includes(section)
      ? [...sectionsWithErrors, section]
      : !hasError && sectionsWithErrors.includes(section)
        ? sectionsWithErrors.filter(s => s !== section)
        : sectionsWithErrors,
});

const reportSearchStarting = (
  state: ReportBuilderFormState
): ReportBuilderFormState => ({
  ...state,
  isSearchBeingExecuted: true,
  showErrorsForInvalidQuery: false,
});

const setShowErrorsForInvalidQuery = (
  state: ReportBuilderFormState,
  isValidQueryAndDataSelection: boolean
): ReportBuilderFormState => ({
  ...state,
  showErrorsForInvalidQuery: !isValidQueryAndDataSelection,
});

const setSortOnColumn = (
  state: ReportBuilderFormState,
  { columnKey, order }: ReportSort
): ReportBuilderFormState => ({
  ...state,
  reportTemplate: {
    ...state.reportTemplate,
    columns: columnKey
      ? state.reportTemplate.columns.map(col =>
          col.key === columnKey
            ? { ...col, sort: order }
            : col.sort
              ? omit(col, 'sort')
              : col
        )
      : state.reportTemplate.columns.map(col =>
          col.sort ? omit(col, 'sort') : col
        ),
  },
});

const validateAndSetAdvancedSearch = (
  state: ReportBuilderFormState,
  { isEmpty, isValid, query }: AdvancedSearchQueryChangedPayload
): ReportBuilderFormState => ({
  ...state,
  isValidAdvancedSearch: isEmpty || isValid,
  reportTemplate: {
    ...state.reportTemplate,
    advancedSearchQuery: query,
  },
});

const getDataUsedForSearch = (state: ReportBuilderFormState) => ({
  workspaceIds: state.reportTemplate.workspaceIds,
  gremlinQuery: state.reportTemplate.gremlinQuery,
  advancedSearchQuery: state.reportTemplate.advancedSearchQuery,
  datasource: state.reportTemplate.datasource,
  subdivisions: state.reportTemplate.subdivisions,
});

const setSearchResultsAndDataUsedForSearch = (
  state: ReportBuilderFormState,
  searchResults: Result<EnhancedSearchResponse>
): ReportBuilderFormState => {
  if (isArdoqError(searchResults)) {
    return {
      ...state,
      errorFromSearch: getArdoqErrorMessage(searchResults),
      searchResults: null,
      isSearchBeingExecuted: false,
      reportDataUsedForSearch: getDataUsedForSearch(state),
    };
  }

  const selectedColumns =
    reportBuilderOperations.getSelectedColumnsThatAreValidForTheSearchResult(
      state.reportTemplate,
      searchResults
    );
  return {
    ...state,
    errorFromSearch: null,
    searchResults,
    isSearchBeingExecuted: false,
    reportDataUsedForSearch: getDataUsedForSearch(state),
    reportTemplate: {
      ...state.reportTemplate,
      columns: selectedColumns,
      updatedColumnNames:
        reportBuilderOperations.getUpdatedColumnNamesThatAreValidForTheSelectedColumns(
          selectedColumns,
          state.reportTemplate.updatedColumnNames
        ),
    },
  };
};

const setSearchAggregatesStarting = (
  state: ReportBuilderFormState
): ReportBuilderFormState => ({
  ...state,
  isSearchAggregatesBeingExecuted: true,
  errorFromSearchAggregates: null,
});

const setSearchAggregatesResults = (
  state: ReportBuilderFormState,
  searchAggregatesResults: Result<APISearchAggregatesResponse>
): ReportBuilderFormState => {
  if (isArdoqError(searchAggregatesResults)) {
    return {
      ...state,
      errorFromSearchAggregates: getArdoqErrorMessage(searchAggregatesResults),
      searchAggregatesResults: null,
      isSearchAggregatesBeingExecuted: false,
    };
  }

  return {
    ...state,
    errorFromSearchAggregates: null,
    searchAggregatesResults,
    isSearchAggregatesBeingExecuted: false,
  };
};

const setWorkspaceIds = (
  state: ReportBuilderFormState,
  workspaceIds: ArdoqId[]
): ReportBuilderFormState => ({
  ...state,
  reportTemplate: { ...state.reportTemplate, workspaceIds },
  isFetchingCustomFields: true,
});

const setSubdivisions = (
  state: ReportBuilderFormState,
  subdivisions: ZonesBySubdivisionsIds
): ReportBuilderFormState => ({
  ...state,
  reportTemplate: { ...state.reportTemplate, subdivisions },
  isFetchingCustomFields: true,
});

const setIsFetchingCustomFields = (
  state: ReportBuilderFormState
): ReportBuilderFormState => ({
  ...state,
  isFetchingCustomFields: false,
});

const setWorkspacesWithNoAccess = (
  state: ReportBuilderFormState,
  workspaceIds: ArdoqId[]
): ReportBuilderFormState => ({
  ...state,
  selectedWorkspacesWithNoAccess: workspaceIds,
  hasWorkspacesWithNoAccess: Boolean(workspaceIds.length),
});

const getInitialState = (): ReportBuilderFormState => ({
  isInitialized: false,
  reportTemplate: reportBuilderOperations.getEmptyState(),
  persistedReport: null,
  isReportBuilderLoading: true,
  existingResourcePermissions: null,
  isSubmitting: false,
  errorFromSave: null,
  showErrorsOnInputs: false,
  sectionsWithErrors: [],
  isValidAdvancedSearch: true,
  isSearchBeingExecuted: false,
  isSearchAggregatesBeingExecuted: false,
  showErrorsForInvalidQuery: false,
  searchResults: null,
  searchAggregatesResults: null,
  errorFromSearch: null,
  errorFromSearchAggregates: null,
  reportDataUsedForSearch: null,
  isFetchingCustomFields: false,
  selectedWorkspacesWithNoAccess: [],
  hasWorkspacesWithNoAccess: false,
});

const reportBuilderForm$ = persistentReducedStream<ReportBuilderFormState>(
  'reportBuilderForm$',
  getInitialState(),
  [
    streamReducer(initializeReport$, initializeReportForm),
    streamReducer(
      reportHasHistoricalData$,
      setHasHistoricalDataOnPersistedReport
    ),
    reducer(reportHistoricalDataWasDiscarded, setHasHistoricalDataFalse),
    reducer(reportSaveButtonWasClickedOnNewReport, setIsSubmitting),
    reducer(newReportPropertySelected, setSimplePropertyOnReport),
    reducer(reportWasSaved, setPersistedReport),
    reducer(navigateToReportBuilder, maybeResetState),
    reducer(reportChangesWereDiscarded, getInitialState), // reset state
    reducer(reportSaveFailed, setErrorFromSave),
    reducer(reportWasInvalidOnSaveButtonClick, setShowErrorsOnInputs),
    reducer(sectionWasValidated, addSectionToArrayIfError),
    reducer(reportSearchWasTriggered, reportSearchStarting),
    reducer(reportQueryWasValidated, setShowErrorsForInvalidQuery),
    reducer(reportBuilderSortingWasChanged, setSortOnColumn),
    reducer(advancedSearchQueryChanged, validateAndSetAdvancedSearch),
    reducer(reportSearchFinished, setSearchResultsAndDataUsedForSearch),
    reducer(reportSearchAggregatesWasTriggered, setSearchAggregatesStarting),
    reducer(reportSearchAggregatesFinished, setSearchAggregatesResults),
    reducer(newZoneSelected, setSubdivisions),
    reducer(newWorkspaceSelected, setWorkspaceIds),
    reducer(finishedFetchingCustomFields, setIsFetchingCustomFields),
    reducer(changeWorkspacesWithNoAccess, setWorkspacesWithNoAccess),
  ]
);

export default reportBuilderForm$;
