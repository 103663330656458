import { colors, spacing } from '@ardoq/design-tokens';
import styled from 'styled-components';

export const ViewOptionsRow = styled.div`
  display: flex;
  padding: ${spacing.s16};
  align-items: center;
  gap: ${spacing.s8};
  align-self: stretch;
`;

export const ViewOptionsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding-top: ${spacing.s16};
`;

export const ViewOptionsSectionHeader = styled.div`
  display: flex;
  padding: 0 ${spacing.s16};
  align-items: center;
  gap: ${spacing.s8};
  align-self: stretch;
`;

export const ViewOptionsSectionTitleContainer = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
  gap: ${spacing.s8};
  flex: 1 0 0;
`;

export const ViewOptionsSectionTitle = styled.label`
  color: ${colors.grey50};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const ViewOptionsSectionBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const ViewOptionsSectionRowContainer = styled.div`
  display: flex;
  padding: 12px ${spacing.s16};
  align-items: center;
  gap: ${spacing.s8};
  align-self: stretch;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey95};
  }
`;

export const ViewOptionsSectionRowContent = styled.div`
  display: flex;
  height: 24px;
  padding: 0;
  align-items: center;
  gap: ${spacing.s8};
  flex: 1 0 0;
`;

export const ViewOptionsSectionRowLabel = styled.label`
  margin: 0;
  color: ${colors.grey15};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: inherit;
`;

export const ViewOptionsSectionRightControls = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  gap: 10px;
`;
