import { colors } from '@ardoq/design-tokens';
import styled, { css } from 'styled-components';
import { MarkdownViewer } from '@ardoq/markdown';
import { Match } from '@ardoq/api-types';

const Highlighted = styled.div<{ $isOldView?: boolean }>`
  span {
    ${({ $isOldView }) =>
      $isOldView
        ? css`
            color: ${colors.purple25};
            font-style: italic;
          `
        : css`
            color: ${colors.brand50};
            font-weight: 600;
          `};
  }
`;

type MatchRendererProps = {
  match?: Match;
  text: string;
  isOldView?: boolean;
};

export const MatchRenderer = ({
  text,
  match,
  isOldView,
}: MatchRendererProps) => {
  if (!match?.value.length)
    return <MarkdownViewer content={text} sanitationLevel="permissive" />;
  return <Highlighted $isOldView={isOldView}>{match.value}</Highlighted>;
};
