import {
  AllPossibleQuestions,
  SurveyQuestionError,
  SurveyQuestionValidations,
} from 'surveyAdmin/types';
import {
  APIFieldAttributes,
  ArdoqId,
  isEntity,
  PersistedAttributeQuestion,
  PersistedDependentQuestion,
  PersistedFieldQuestion,
  SurveyQuestionType,
  UnpersistedAttributeQuestion,
  UnpersistedDependentQuestion,
  UnpersistedFieldQuestion,
} from '@ardoq/api-types';
import DisplayConditionsQuestion from './DisplayConditionsQuestion';
import FieldQuestionConfigurator from '../FieldQuestionConfigurator';
import FieldSelect from '../FieldSelect';
import { FIELD_EXPLANATION } from '../../SurveyEditor/consts';
import {
  getQuestionId,
  getQuestionValidationMessageIfExists,
  isReadonlyField,
} from '../utils';
import { confirmDescriptionChangeModal } from 'surveyAdmin/SurveyModals';
import { isEqual, omit } from 'lodash';
import { FieldQuestionData } from '../types';
import { isDependentQuestion, isFieldQuestion } from '../questionPredicates';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { setSurveyQuestionFieldHasBeenInteractedWith } from 'surveyAdmin/SurveyEditor/streams/actions';
import { shouldDisplayErrorMessageForField } from 'surveyAdmin/surveyUtil';
import surveyEditor$ from 'surveyAdmin/SurveyEditor/streams/surveyEditor$';
import { SurveyEditorState } from 'surveyAdmin/SurveyEditor/streams/types';

type NonReferenceSubQuestion =
  | PersistedDependentQuestion<PersistedAttributeQuestion>
  | PersistedDependentQuestion<PersistedFieldQuestion>
  | UnpersistedDependentQuestion<UnpersistedAttributeQuestion>
  | UnpersistedDependentQuestion<UnpersistedFieldQuestion>;

const disallowedDependentAttributes = ['name', 'parent'];

type FieldConditionalQuestionProps = {
  question: NonReferenceSubQuestion;
  questions?: AllPossibleQuestions[];
  parentQuestionField: APIFieldAttributes;
  fields: APIFieldAttributes[];
  attributes: {
    label: string;
    attributeName: string;
  }[];
  workspaceId: ArdoqId | null;
  questionValidations: SurveyQuestionValidations;
  parentQuestionTitle: string;
  parentQuestionIsReadonly?: boolean;
  updateQuestion: (dependentQuestion: NonReferenceSubQuestion) => void;
  fieldHasBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith'];
};

const DependentFieldQuestion = ({
  question,
  parentQuestionField,
  parentQuestionTitle,
  fields,
  attributes,
  questions,
  workspaceId,
  questionValidations,
  parentQuestionIsReadonly,
  updateQuestion,
  fieldHasBeenInteractedWith,
}: FieldConditionalQuestionProps) => {
  const selectedField = isFieldQuestion(question)
    ? question.properties.fieldName
    : question.properties.attributeName;

  const handleUpdateFieldQuestion = async ({
    type,
    readonly,
    value,
    description,
    label,
  }: FieldQuestionData) => {
    const confirmDescriptionChange =
      description &&
      !isEqual(description, question.description) &&
      question.description
        ? Boolean(await confirmDescriptionChangeModal())
        : true;
    const updatedDescription =
      confirmDescriptionChange && description
        ? description
        : question.description;
    updateQuestion(
      type === SurveyQuestionType.ATTRIBUTE
        ? {
            ...question,
            type,
            readonly,
            label,
            description: updatedDescription,
            properties: {
              ...omit(question.properties, 'fieldName'),
              attributeName: value,
            },
          }
        : {
            ...question,
            type,
            readonly,
            label,
            description: updatedDescription,
            properties: {
              ...omit(question.properties, 'attributeName'),
              fieldName: value,
            },
          }
    );
  };
  const shouldShowDisplayConditionFieldSelectErrorIfPresent =
    shouldDisplayErrorMessageForField(
      isEntity(question),
      null,
      fieldHasBeenInteractedWith.questions[getQuestionId(question)]
        ?.dependentFieldQuestionDisplayConditionsField ||
        fieldHasBeenInteractedWith.questions[getQuestionId(question)]
          ?.questionViewHasBeenClosed
    );

  const shouldShowFieldSelectErrorIfPresent = shouldDisplayErrorMessageForField(
    isEntity(question),
    null,
    fieldHasBeenInteractedWith.questions[getQuestionId(question)]
      ?.dependentFieldQuestionFieldSelectField ||
      fieldHasBeenInteractedWith.questions[getQuestionId(question)]
        ?.questionViewHasBeenClosed
  );

  return (
    <>
      <DisplayConditionsQuestion
        onBlur={() => {
          dispatchAction(
            setSurveyQuestionFieldHasBeenInteractedWith({
              questionKey: getQuestionId(question),
              fieldKey: 'dependentFieldQuestionDisplayConditionsField',
            })
          );
        }}
        parentQuestionField={parentQuestionField}
        displayConditions={question.properties.displayConditions}
        updatedDisplayConditions={displayConditions =>
          updateQuestion({
            ...question,
            properties: { ...question.properties, displayConditions },
          } as NonReferenceSubQuestion)
        }
        parentQuestionTitle={parentQuestionTitle}
        errorMessage={
          shouldShowDisplayConditionFieldSelectErrorIfPresent
            ? getQuestionValidationMessageIfExists(
                questionValidations,
                SurveyQuestionError.DISPLAY_CONDITIONS_MISSING
              ) ||
              getQuestionValidationMessageIfExists(
                questionValidations,
                SurveyQuestionError.DISPLAY_CONDITIONS_INVALID
              )
            : undefined
        }
      />
      <FieldSelect
        question={question}
        fields={fields}
        attributes={attributes.filter(
          attribute =>
            !disallowedDependentAttributes.includes(attribute.attributeName)
        )}
        parentQuestionIsReadonly={parentQuestionIsReadonly}
        questions={questions}
        workspaceId={workspaceId}
        shouldShowErrorMessagesIfPresent={shouldShowFieldSelectErrorIfPresent}
        hintMessage="Required"
        questionValidations={questionValidations}
        updateFieldQuestion={handleUpdateFieldQuestion}
        popoverHelpContent={FIELD_EXPLANATION}
        onBlur={() => {
          dispatchAction(
            setSurveyQuestionFieldHasBeenInteractedWith({
              questionKey: getQuestionId(question),
              fieldKey: 'dependentFieldQuestionFieldSelectField',
            })
          );
        }}
      />
      {selectedField && (
        <FieldQuestionConfigurator
          parentQuestionIsReadonly={parentQuestionIsReadonly}
          fieldIsReadonly={isReadonlyField(selectedField, workspaceId)}
          question={question}
          updateQuestion={updatedQuestion => {
            if (!isDependentQuestion(updatedQuestion)) return;
            updateQuestion(updatedQuestion);
          }}
          questionValidations={questionValidations}
        />
      )}
    </>
  );
};

export default connect(DependentFieldQuestion, surveyEditor$);
