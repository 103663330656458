import { dispatchAction, connect } from '@ardoq/rxbeach';
import { hideRightPane } from 'appContainer/actions';
import { Icon, IconName } from '@ardoq/icons';
import { FabContainer } from '../atoms';
import { FabColor } from '../types';
import { classes } from '@ardoq/common-helpers';
import componentFab$ from './componentFab$';
import { activateComponentFab } from './actions';

export type ComponentFabProps = {
  readonly isActive: boolean;
  readonly isVisible: boolean;
};

const ComponentFab = ({ isActive, isVisible }: ComponentFabProps) => {
  if (!isVisible) {
    return null;
  }
  const handleClick = () => {
    if (isActive) {
      return dispatchAction(hideRightPane());
    }
    dispatchAction(activateComponentFab());
  };

  return (
    <FabContainer
      className={classes(isActive && 'active')}
      color={FabColor.BLUE}
      onClick={handleClick}
      role="button"
      aria-label={isActive ? 'Close sidebar' : 'Create a component'}
      data-tooltip-text={isActive ? 'Close sidebar' : 'Create a component'}
    >
      <div className="fab-main">
        <Icon iconName={IconName.CLOSE} />
      </div>
    </FabContainer>
  );
};

export default connect(ComponentFab, componentFab$);
