import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { initIntegration } from 'integrations/actions';
import { filter, tap } from 'rxjs';
import { fetchAwsSettings } from '../streams/settings/actions';

const handleInitIntegration = routine(
  ofType(initIntegration),
  extractPayload(),
  filter(({ id }) => id === 'aws-v3'),
  tap(() => {
    dispatchAction(fetchAwsSettings());
  })
);

export default [handleInitIntegration];
