import { trackEvent } from 'tracking/tracking';

export enum ACCESS_CONTROL_FAQ_EVENTS {
  OPEN = 'opened',
  CLOSE = 'closed',
  CLICKED_QUESTION = 'clicked question',
  CLICKED_LINK_UNDER_QUESTION = 'clicked link under question',
  CLICKED_SHARE_FEEDBACK = 'clicked share feedback',
  CLICKED_CONTACT_SUPPORT = 'clicked contact support',
}

export const trackAccessControlFAQEvent = <T extends object>(
  name: ACCESS_CONTROL_FAQ_EVENTS,
  metadata?: T
) => trackEvent(`Access Control FAQ0 ${name}`, metadata);
