import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { map, switchMap, tap } from 'rxjs/operators';
import { api, handleError, permissionApi } from '@ardoq/api';
import { userAssetsOverviewQueryOperations } from './userAssetsOverviewQueryOperations';
import { accessControlPageActions } from 'appContainer/AccessControlPage/actions';

const handleMakeRequest = routine(
  ofType(accessControlPageActions.requestUserAssets),
  extractPayload(),
  map(userAssetsOverviewQueryOperations.generateFetchQueryParams),
  switchMap(permissionApi.fetchUserPermissionsOverview),
  handleError(error => {
    const errorMessage = api.logErrorIfNeededAndReturnMessage(
      error,
      'Failed to fetch the user assets. Please try again.'
    );
    dispatchAction(
      accessControlPageActions.requestUserAssetsFailure(errorMessage)
    );
  }),
  map(accessControlPageActions.requestUserAssetsSuccess),
  tap(dispatchAction)
);

export const userAssetsOverviewRoutines = collectRoutines(handleMakeRequest);
