import { Icon } from '@ardoq/icons';
import { NewJourneyResult, OverflowTextWrapper, ResultName } from './atoms';
import { colors } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { TextOverflowEllipsis, WithPopover } from '@ardoq/popovers';
import { caption } from '@ardoq/typography';
import { QuickSearchResultItem } from './types';
import { MatchRenderer } from './MatchRenderer';
import { useRef } from 'react';

const ResultType = styled(TextOverflowEllipsis)`
  ${caption};
  color: ${colors.grey50};
`;

type ResultDescriptionProps = {
  result: QuickSearchResultItem;
  lineClamp: number;
  isOldView?: boolean;
};

export const ResultDescription = ({
  result,
  lineClamp,
  isOldView,
}: ResultDescriptionProps) => {
  const match = result.matches?.find(m => m.key === 'description');
  if (!result.description) return null;
  return (
    <OverflowTextWrapper
      data-test-id="search-result-description"
      lineClamp={lineClamp}
    >
      <MatchRenderer
        text={result.description}
        match={match}
        isOldView={isOldView}
      />
    </OverflowTextWrapper>
  );
};

type SearchResultHeaderProps = {
  result: QuickSearchResultItem;
  lineClamp: number;
  showPopover?: boolean;
};

export const SearchResultHeader = ({
  result,
  lineClamp,
  showPopover,
}: SearchResultHeaderProps) => {
  const compNameRef = useRef<HTMLDivElement>(null);
  const match = result.matches?.find(m => m.key === 'name');
  return (
    <ResultName data-test-id="search-result-header" ref={compNameRef}>
      {result.iconName && (
        <Icon iconName={result.iconName} style={{ color: colors.icon }} />
      )}
      {result.type === 'reference' ? (
        result.name
      ) : (
        <WithPopover content={() => (showPopover ? result.name : null)}>
          <OverflowTextWrapper $isBold lineClamp={lineClamp}>
            <MatchRenderer text={result.name} match={match} />
          </OverflowTextWrapper>
        </WithPopover>
      )}
    </ResultName>
  );
};

type NewResultItemProps = {
  result: QuickSearchResultItem;
};

export const NewResultItem = ({ result }: NewResultItemProps) => {
  return (
    <NewJourneyResult>
      <SearchResultHeader result={result} lineClamp={1} showPopover={false} />
      {result.typeName && <ResultType>{result.typeName}</ResultType>}
      <ResultDescription result={result} lineClamp={2} />
    </NewJourneyResult>
  );
};
