import {
  ArdoqId,
  AssetType,
  APIViewpointAttributes,
  LoadedState,
} from '@ardoq/api-types';
import {
  ComponentOverview,
  ComponentTypeAndHierarchy,
  NameAndIcon,
} from '@ardoq/component-overview';
import { ContextShape, EnhancedScopeData } from '@ardoq/data-model';
import { trackEvent } from 'tracking/tracking';
import { componentOverviewPage$ } from './componentOverviewPage$';
import { connect, derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import MainToolbar from 'menus/topbar/MainToolbar';
import Navbar from 'views/navbar/Navbar';
import { ComponentOverviewPageCommands, commands } from './commands';
import traversals$ from 'streams/traversals$';
import currentUser$, {
  getFavorites,
  getIsOrgWriter,
} from 'streams/currentUser/currentUser$';
import { Link } from '@ardoq/typography';
import { capitalize } from 'lodash';
import { BrandButton, SecondaryButton } from '@ardoq/button';
import { ErrorInfoDoq } from '@ardoq/dropzone';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { isPresentationMode } from 'appConfig';
import { componentOverviewPageOperations } from './componentOverviewPageOperations';
import { DoqLoader } from '@ardoq/snowflakes';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import { FlexBox, Stack } from '@ardoq/layout';
import { traversalStateOperations } from 'streams/traversals/traversalsStateOperations';
import { context$ } from 'streams/context/context$';
import { loadedState$ } from 'loadedState/loadedState$';
import { Icon, IconName } from '@ardoq/icons';
import { BoxWithMaxWidth } from 'appLayout/ardoqStudio/detailsDrawer/DetailsDrawer';

type TraversalStreamAttributesWithFavorite = APIViewpointAttributes & {
  featured: boolean;
};

type ComponentOverviewPageProps = {
  scopeData: EnhancedScopeData | null;
  componentId: string | null;
  viewpoints: TraversalStreamAttributesWithFavorite[];
  commands: ComponentOverviewPageCommands;
  canCreateViewpoints: boolean;
  hasError: boolean;
  context: ContextShape;
  loadedStates: LoadedState[];
};

const handleComponentClick = (componentId: ArdoqId) => {
  commands.navigateToComponent(componentId);
  trackEvent('Component overview: clicked on component', {
    componentId,
  });
};

const ComponentOverviewPage = ({
  scopeData,
  componentId,
  viewpoints,
  canCreateViewpoints,
  hasError,
  context,
  loadedStates,
}: ComponentOverviewPageProps) => {
  if (hasError) {
    return (
      <Stack justify="center" align="center" gap="medium" flex={1}>
        <ErrorInfoDoq
          message="We are sorry but information about this component could not be loaded."
          buttons={
            <SecondaryButton onClick={commands.navigateToHome}>
              Go back home
            </SecondaryButton>
          }
        />
      </Stack>
    );
  }
  const component = scopeData?.componentsById[componentId ?? ''];
  if (!scopeData || !component)
    return (
      <FlexBox align="center" width="full" height="full">
        <Stack justify="center" align="center" gap="medium" flex={1}>
          <DoqLoader />
        </Stack>
      </FlexBox>
    );

  const relevantViewpoints = viewpoints
    .filter(traversal => traversal.startType === component.type)
    .sort((traversalA, traversalB) => {
      return traversalA.featured === traversalB.featured
        ? 0
        : traversalA.featured
          ? -1
          : 1;
    });

  const handleViewpointClick = async (viewpointId: ArdoqId) => {
    const viewpoint = relevantViewpoints.find(({ _id }) => _id === viewpointId);
    if (!viewpoint) return;

    await commands.openViewpoint({
      component,
      viewpoint,
      context,
      loadedStates,
    });
    trackEvent('Component overview: opened viewpoint', {
      viewpointId,
    });
  };

  return (
    <PageWrapper>
      <Navbar
        primaryContent={
          <BoxWithMaxWidth maxWidth="800px">
            <NameAndIcon component={component} scopeData={scopeData} />
          </BoxWithMaxWidth>
        }
        secondaryContent={
          <ComponentTypeAndHierarchy
            component={component}
            scopeData={scopeData}
          />
        }
        primaryButton={
          <BrandButton
            onClick={() => commands.exploreData(component)}
            dataTestId="component-overview-explore-button"
          >
            <Icon iconName={IconName.NAVIGATE} />
            Explore data
          </BrandButton>
        }
        toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
      />
      <PageBody backgroundColor="surfaceDefault" skipPadding>
        {scopeData && componentId && (
          <ComponentOverview
            scopeData={scopeData}
            componentId={componentId}
            viewpoints={relevantViewpoints}
            onViewpointClick={handleViewpointClick}
            onComponentClick={handleComponentClick}
            trackEvent={trackEvent}
            showTopBar={false}
            additionalViewpointHelperText={
              canCreateViewpoints ? (
                <Link
                  onClick={async () =>
                    await commands.createViewpoint({
                      component,
                      context,
                      loadedStates,
                    })
                  }
                  hideIcon
                  underlineOnHover
                >
                  Create a viewpoint for {capitalize(component.type)}
                </Link>
              ) : null
            }
            isLoadingReferences={false} // loading state is handled elsewhere
          />
        )}
      </PageBody>
    </PageWrapper>
  );
};

const componentOverviewPageWithTraversals$ = derivedStream(
  'componentOverviewPageWithTraversals$',
  currentUser$,
  traversals$,
  componentOverviewPage$,
  subdivisions$,
  currentUserPermissionContext$,
  activeScenario$,
  context$,
  loadedState$
).pipe(
  map(
    ([
      currentUser,
      traversals,
      { componentId, scopeData, hasError },
      subdivisionsContext,
      permissionContext,
      { isScenarioMode },
      context,
      loadedStates,
    ]) => {
      const favoriteTraversalIds =
        getFavorites(currentUser)[AssetType.TRAVERSAL];
      const traversalsAsDataSource = traversalStateOperations
        .getAsList(traversals)
        .map(traversal => {
          return {
            ...traversal,
            featured: favoriteTraversalIds.includes(traversal._id),
          };
        });

      const scopeDataWithFilteredFields =
        componentId && scopeData?.componentsById[componentId]
          ? componentOverviewPageOperations.filterFieldsInScopeDataWithSubdivisionsAndModes(
              {
                scopeData,
                subdivisionsContext,
                permissionContext,
                component: scopeData.componentsById[componentId],
                isScenarioMode,
                isPresentationMode: isPresentationMode(),
              }
            )
          : scopeData;

      return {
        viewpoints: traversalsAsDataSource,
        componentId,
        scopeData: scopeDataWithFilteredFields,
        commands,
        canCreateViewpoints: getIsOrgWriter(currentUser),
        hasError,
        context,
        loadedStates,
      };
    }
  )
);

export default connect(
  ComponentOverviewPage,
  componentOverviewPageWithTraversals$
);
