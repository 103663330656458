import { DatasourceTable } from '@ardoq/table';
import { ChecklistDescription, ClickableTitle } from './atoms';
import { PresentationMetadata, ViewToMigrate } from './types';
import { Header1, Paragraph } from '@ardoq/typography';
import { Box, FlexBox, Stack } from '@ardoq/layout';

type MigrationChecklistContentProps = {
  activeMigration: ViewToMigrate;
  relevantMetadata?: PresentationMetadata[];
};

type TableValueRender = (value: string, metadata: PresentationMetadata) => any;

const PresentationMigrationChecklistContent = ({
  activeMigration,
  relevantMetadata,
}: MigrationChecklistContentProps) => (
  <>
    <ChecklistDescription />
    {relevantMetadata?.length ? (
      <DatasourceTable
        dataSource={relevantMetadata}
        columns={[
          {
            title: 'Presentation name',
            dataIndex: 'name',
            valueRender: ((name, { id }) => (
              <ClickableTitle presentationId={id} name={name} />
            )) as TableValueRender,
          },
          { title: 'Author', dataIndex: 'author' },
          {
            title: '# of slides',
            valueRender: ((_, { viewIdCount }) =>
              viewIdCount[activeMigration]) as TableValueRender,
          },
        ]}
      />
    ) : (
      <Box>
        <Stack gap="medium">
          <FlexBox justify="center">
            <Header1>All done!</Header1>
          </FlexBox>
          <FlexBox justify="center">
            <Paragraph>
              There are no more slides to be migrated for this view. Nice work!
            </Paragraph>
          </FlexBox>
        </Stack>
      </Box>
    )}
  </>
);

export default PresentationMigrationChecklistContent;
