import { SetWsIdPayload, WorkspacePreviewState } from './types';

export const getInitialState = (): WorkspacePreviewState => ({
  wsId: undefined,
});

export const setWsId = (
  state: WorkspacePreviewState,
  { wsId }: SetWsIdPayload
) => ({ ...state, wsId });
