import { CloudProviderIntegrationId } from 'integrations/cloudProviders/types';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { map, Observable } from 'rxjs';
import { RegionsState } from './types';
import { AsyncStatus } from 'integrations/common/types/api';
import {
  changeRegionsSelection,
  ChangeRegionsSelectionPayload,
  fetchRegionsFailure,
  fetchRegionsSuccessForCloudProvider,
  FetchRegionsSuccessPayload,
  resetRegions,
  updateAsyncStatus,
  UpdateAsyncStatusPayload,
  updateRegionList,
  UpdateRegionListPayload,
} from './actions';

type AllRegionsState = Record<CloudProviderIntegrationId, RegionsState>;

const initialState: RegionsState = {
  asyncStatus: 'INIT',
  selectedRegionIds: [],
  regions: [],
};

const defaultState: AllRegionsState = {
  'aws-v3': initialState,
  'azure-v3': initialState,
};

const setAsyncStatus =
  (asyncStatus: AsyncStatus) =>
  (
    state: AllRegionsState,
    { integrationId }: { integrationId: CloudProviderIntegrationId }
  ) => {
    return {
      ...state,
      [integrationId]: {
        ...state[integrationId],
        asyncStatus,
      },
    };
  };

const handleFetchRegionsFailure = reducer<
  AllRegionsState,
  { integrationId: CloudProviderIntegrationId }
>(fetchRegionsFailure, setAsyncStatus('FAILURE'));

const fetchRegionsSuccessReducer = (
  state: AllRegionsState,
  { integrationId, regions }: FetchRegionsSuccessPayload
): AllRegionsState => {
  return {
    ...state,
    [integrationId]: {
      ...state[integrationId],
      regions,
      asyncStatus: 'SUCCESS',
    },
  };
};
const handleFetchRegionsSuccess = reducer<
  AllRegionsState,
  FetchRegionsSuccessPayload
>(fetchRegionsSuccessForCloudProvider, fetchRegionsSuccessReducer);

const updateRegionListReducer = (
  state: AllRegionsState,
  { integrationId, regions }: UpdateRegionListPayload
) => {
  return {
    ...state,
    [integrationId]: {
      ...state[integrationId],
      regions,
    },
  };
};

const handleListRegions = reducer<AllRegionsState, UpdateRegionListPayload>(
  updateRegionList,
  updateRegionListReducer
);

const changeRegionsSelectionReducer = (
  state: AllRegionsState,
  { integrationId, regions }: ChangeRegionsSelectionPayload
) => ({
  ...state,
  [integrationId]: {
    ...state[integrationId],
    selectedRegionIds: regions,
  },
});
const handleChangeRegionsSelection = reducer<
  AllRegionsState,
  ChangeRegionsSelectionPayload
>(changeRegionsSelection, changeRegionsSelectionReducer);

const setAsyncStatusReducer = (
  state: AllRegionsState,
  payload: UpdateAsyncStatusPayload
) => {
  return setAsyncStatus(payload.asyncStatus)(state, {
    integrationId: payload.integrationId,
  });
};

const handleAsyncStatusUpdate = reducer<
  AllRegionsState,
  UpdateAsyncStatusPayload
>(updateAsyncStatus, setAsyncStatusReducer);

const resetRegionsReducer = (
  state: AllRegionsState,
  { integrationId }: { integrationId: CloudProviderIntegrationId }
) => ({
  ...state,
  [integrationId]: initialState,
});

const handleResetRegions = reducer<
  AllRegionsState,
  { integrationId: CloudProviderIntegrationId }
>(resetRegions, resetRegionsReducer);

const regions$ = persistentReducedStream(
  `integrationCloudProviderRegions$`,
  defaultState,
  [
    handleListRegions,
    handleFetchRegionsSuccess,
    handleFetchRegionsFailure,
    handleChangeRegionsSelection,
    handleAsyncStatusUpdate,
    handleResetRegions,
  ]
);

const cloudProviderToRegionStreams = new Map<
  IntegrationId,
  Observable<RegionsState>
>();

export const getRegionsStream = (integrationId: CloudProviderIntegrationId) => {
  const regionsStream = cloudProviderToRegionStreams.get(integrationId);

  if (regionsStream) {
    return regionsStream;
  }

  const stream$ = regions$.pipe(
    map(allRegionsState => allRegionsState[integrationId])
  );

  cloudProviderToRegionStreams.set(integrationId, stream$);

  return stream$;
};
