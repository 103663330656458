import { actionCreator } from '@ardoq/rxbeach';
import { UpdateSaveAsViewpointStatePayload } from './types';

export const updateSaveAsViewpointState =
  actionCreator<UpdateSaveAsViewpointStatePayload>(
    '[SaveAsViewpoint] updateSaveAsViewpointState'
  );
export const resetSaveAsViewpoint = actionCreator('[SaveAsViewpoint] reset');
export const setIsSavingViewpointSessionActive = actionCreator<boolean>(
  '[SaveAsViewpoint] isSavingViewpointSessionActive'
);
