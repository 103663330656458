import { BroadcastAudience } from '@ardoq/api-types';
import AudienceTagLabel from 'broadcasts/components/audienceTag/audienceTagLabel/AudienceTagLabel';
import { WithPopover } from '@ardoq/popovers';
import { StatusType, Tag } from '@ardoq/status-ui';
import { IconName } from '@ardoq/icons';

type AudienceTagProps = {
  audience: BroadcastAudience;
  resultCount: number | null;
  disabled?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
  warningMessage?: string | null;
  inReviewBroadcastDialog?: boolean;
};

const AudienceTag = ({
  disabled,
  onClick,
  onRemove,
  audience,
  resultCount,
  warningMessage,
  inReviewBroadcastDialog,
}: AudienceTagProps) => {
  const hasWarning = warningMessage && !resultCount;
  return (
    <WithPopover
      content={warningMessage && resultCount === 0 ? warningMessage : undefined}
    >
      <Tag
        isDisabled={disabled}
        onClick={!inReviewBroadcastDialog ? onClick : undefined}
        onCloseClick={!inReviewBroadcastDialog ? onRemove : undefined}
        statusType={hasWarning ? StatusType.WARNING : undefined}
        iconName={hasWarning ? IconName.WARNING : undefined}
      >
        <AudienceTagLabel audience={audience} resultCount={resultCount} />
      </Tag>
    </WithPopover>
  );
};

export default AudienceTag;
