import { Features, hasFeature } from '@ardoq/features';
import currentUser from 'models/currentUser';
import { SubdivisionOverviewTablePage } from 'admin/SubdivisionOverviewTablePage';
import PermissionGroups from 'admin/accessControl/PermissionGroups/PermissionGroups';
import ManageUserRoles from 'admin/accessControl/ManageUserRoles/ManageUserRoles';
import ManageAndInviteUsers from 'admin/manageOrganization/ManageAndInviteUsers';
import { TabButton, TabProps, TabsGroup } from '@ardoq/tabs';
import { AccessControlTabs } from 'admin/accessControl/navigation/types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import ManageSCIMToken from 'admin/orgMembers/SCIMManagement/ManageSCIMToken';
import ManageServiceAccounts from 'admin/serviceAccounts/ManageServiceAccounts';
import ManageIPAllowlist from 'admin/manageIPAllowlist/ManageIPAllowlist';
import { SSOAttributeMapping } from 'admin/ssoAttributeMapping';
import { PageBody, PageHeader } from '@ardoq/page-layout';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { navigateToAccessControlPage } from 'router/navigationActions';
import { trackEvent } from 'tracking/tracking';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import { organizationInterface } from 'modelInterface/organization/organizationInterface';
import { useEffectOnce } from '@ardoq/hooks';
import { useState } from 'react';
import styled from 'styled-components';
import { Stack } from '@ardoq/layout';
import { header2 } from '@ardoq/typography';
import { DoqLoadingAnimation, DoqSize, DoqType } from '@ardoq/doq';
import { StatusType } from '@ardoq/status-ui';
import { AccessControlPageProps } from './types';
import { accessControlPageViewModel$ } from './accessControlPage$';
import { map } from 'rxjs';
import { UserAssetsOverviewFilterSidebar } from 'admin/accessControl/UserProfilePage/UserRoleAndGroupsDetails/UserAssetsOverviewTab/UserAssetsOverviewFilterSidebar';

const LoadingContainer = styled(Stack)`
  width: 100%;
  height: inherit;
  ${header2}
`;

/**
 * @deprecated THis is a hack to keep the old access control page alive until we inform the users about the new one.
 */
const ConnectedManageAndInviteUsers = connect(
  ManageAndInviteUsers,
  accessControlPageViewModel$.pipe(
    map(state => ({ accessControlPageViewModel: state }))
  )
);

const getTabs = (args: {
  showManageUserRoles: boolean;
  showIPAllowlisting: boolean;
  showSCIM: boolean;
  showSSOAttributeMapping: boolean;
  subdivisionFeature: boolean;
}): (TabProps & { content: JSX.Element; statusType?: StatusType })[] =>
  [
    {
      label: 'Users',
      tabId: AccessControlTabs.MEMBERS,
      content: <ConnectedManageAndInviteUsers />,
      statusType: undefined,
    },
    args.showManageUserRoles && {
      label: 'Roles',
      tabId: AccessControlTabs.MANAGE_USER_ROLES,
      content: <ManageUserRoles />,
    },
    {
      label: 'Groups',
      tabId: AccessControlTabs.PERMISSION_GROUPS,
      content: <PermissionGroups />,
      statusType: undefined,
    },
    args.subdivisionFeature && {
      label: 'Divisions',
      tabId: AccessControlTabs.SUBDIVISIONS_OVERVIEW,
      content: <SubdivisionOverviewTablePage />,
    },
    args.showSSOAttributeMapping && {
      label: 'SSO attribute mapping',
      tabId: AccessControlTabs.SSO_ATTRIBUTE_MAPPING,
      content: <SSOAttributeMapping />,
    },
    args.showIPAllowlisting && {
      label: 'IP allowlisting',
      tabId: AccessControlTabs.IP_ALLOWLIST_MANAGEMENT,
      content: <ManageIPAllowlist />,
    },
    args.showSCIM && {
      label: 'SCIM token',
      tabId: AccessControlTabs.SCIM_TOKEN,
      content: <ManageSCIMToken />,
    },
    {
      label: 'Service accounts',
      tabId: AccessControlTabs.SERVICE_ACCOUNTS,
      content: <ManageServiceAccounts />,
      statusType: undefined,
    },
  ].filter(ExcludeFalsy);

export const OldAccessControlMainPage = (props: AccessControlPageProps) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffectOnce(() => {
    organizationInterface.fetchCurrentOrganization().then(() => {
      setIsLoading(false);
    });
  });
  const showManageUserRoles = props.configurablePrivileges.length > 0;
  const showIPAllowlisting = hasFeature(Features.IP_ALLOWLIST_MANAGEMENT);
  const showSCIM = hasFeature(Features.SCIM_PROVISIONING);
  const showSSOAttributeMapping = currentUser.isOrgAdmin();
  const subdivisionFeature = hasFeature(Features.PERMISSION_ZONES);

  const tabs = getTabs({
    showManageUserRoles,
    showIPAllowlisting,
    showSCIM,
    showSSOAttributeMapping,
    subdivisionFeature,
  });

  const selectedTab = tabs.find(
    ({ tabId }) => tabId === props.accessControlTab
  );

  return (
    <>
      {isLoading ? (
        <LoadingContainer justify="center" align="center">
          Retrieving data...
          <DoqLoadingAnimation
            delay={700}
            doqSize={DoqSize.MEDIUM}
            doqs={[
              DoqType.SIMPLE,
              DoqType.ALGORITHM,
              DoqType.ERROR,
              DoqType.SUCCESS,
              DoqType.COMPONENT_MERGE_EMPTY,
            ]}
          />
        </LoadingContainer>
      ) : (
        <>
          <PageHeader skipVerticalPadding>
            <TabsGroup
              activeTabId={props.accessControlTab}
              onTabChange={tabId => {
                trackEvent(APPSEC_EVENTS.CLICKED_MANAGE_ORGANIZATION_TAB, {
                  to: tabId,
                  from: props.accessControlTab,
                  namespace: 'access control',
                });
                dispatchAction(
                  navigateToAccessControlPage({
                    accessControlTab: tabId as AccessControlTabs,
                  })
                );
              }}
            >
              {tabs.map((tab, i) => (
                <TabButton
                  key={i}
                  tabId={tab.tabId}
                  label={tab.label}
                  statusType={tab.statusType}
                />
              ))}
            </TabsGroup>
          </PageHeader>
          {selectedTab && (
            <PageBody
              alignIslandsToLeft
              rightContent={
                props.userAssetsFilterSidebarState.isVisible ? (
                  <UserAssetsOverviewFilterSidebar
                    commands={props.commands}
                    userAssetsQuery={props.userAssetsQuery}
                    userAssetsFilterSidebarState={
                      props.userAssetsFilterSidebarState
                    }
                  />
                ) : undefined
              }
            >
              {selectedTab.content}
            </PageBody>
          )}
        </>
      )}
    </>
  );
};
