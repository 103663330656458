import {
  ArdoqError,
  getArdoqErrorMessage,
  getArdoqErrorTraceId,
} from '@ardoq/common-helpers';
import { alert } from '@ardoq/modal';
import { DoqType } from '@ardoq/doq';
import GenericErrorMessageWithSupportLink from '../../atomicComponents/GenericErrorMessageWithSupportLink';
import { logError } from '@ardoq/sentry';

const PaletteActionType = {
  CREATE: 'CREATE',
  SAVE: 'SAVE',
  DELETE: 'DELETE',
} as const;

type TPaletteActionType =
  (typeof PaletteActionType)[keyof typeof PaletteActionType];

const TextToErrorActionType: Record<
  TPaletteActionType,
  { title: string; subtitle: string }
> = {
  [PaletteActionType.CREATE]: {
    title: 'Create error',
    subtitle: 'An error occurred while creating Color Theme.',
  },
  [PaletteActionType.SAVE]: {
    title: 'Save error',
    subtitle: 'An error occurred while saving Color Theme.',
  },
  [PaletteActionType.DELETE]: {
    title: 'Delete error',
    subtitle: 'An error occurred while deleting Color Theme.',
  },
} as const;

const unknownErrorTitle = {
  title: 'Unexpected error',
  subtitle: 'Something went wrong',
};

const genericColorPaletteErrorAlert = (
  error: ArdoqError,
  errorType: TPaletteActionType
) =>
  alert({
    ...(TextToErrorActionType[errorType] ?? unknownErrorTitle),
    doqType: DoqType.ERROR,
    text: [
      getArdoqErrorMessage(error, 'Please try again'),
      `Trace ID: ${getArdoqErrorTraceId(error)}`,
      <GenericErrorMessageWithSupportLink key="message" />,
    ],
  });

const createColorThemeErrorHandler = (error: ArdoqError) => {
  logError(error, 'An error occurred on creating color theme', {
    traceId: getArdoqErrorTraceId(error),
  });
  genericColorPaletteErrorAlert(error, PaletteActionType.CREATE);
};

const updateColorThemeErrorHandler = (error: ArdoqError) => {
  logError(error, 'An error occurred on updating color theme', {
    traceId: getArdoqErrorTraceId(error),
  });
  genericColorPaletteErrorAlert(error, PaletteActionType.SAVE);
};

const deleteColorThemeErrorHandler = (error: ArdoqError) => {
  logError(error, 'An error occurred on deleting color theme', {
    traceId: getArdoqErrorTraceId(error),
  });
  genericColorPaletteErrorAlert(error, PaletteActionType.DELETE);
};

export const colorThemeErrorHandlers = {
  createColorThemeErrorHandler,
  updateColorThemeErrorHandler,
  deleteColorThemeErrorHandler,
};
