import {
  APIViewpointAttributes,
  ArrowType,
  DirectedTripleWithFilters,
  LineType,
  MetaModel,
  MetaModelComponentType,
  SurveyApprovalTraversalAudience,
  TripleDirection,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { getIcon } from '@ardoq/icons';
import { isEqual, last, uniq, uniqBy } from 'lodash';
import {
  getApproverFieldsByComponentTypeName,
  preselectContactEmailIfItExists,
} from 'surveyAdmin/SurveyEditor/utils';
import { FieldsByComponentTypeName } from 'surveyAdmin/types';
import {
  getShadedColor,
  getLightenedColor,
  ensureContrast,
  getDefaultCSSColor,
} from '@ardoq/color-helpers';
import { colorsAndRepresentations } from '@ardoq/scope-data';
import { NamedDirectedTriple } from 'viewpointBuilder/types';
import { PARENT_REF_TYPE_NAME } from 'viewpointBuilder/utils';
import { editTraversalOperations } from 'viewpointBuilder/traversals/editTraversalOperations';

export const isEmptyTraversal = (
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>
) => {
  return traversal.paths.every(path => path.length === 0);
};

export const withTraversalAudienceEmailFieldSelected = (
  traversalAudience: SurveyApprovalTraversalAudience,
  componentTypeName: string,
  fieldName: string
): SurveyApprovalTraversalAudience => {
  const userResolveFields = traversalAudience.userResolveFields;
  const entryExistsForField = userResolveFields.some(
    field => field.componentTypeName === componentTypeName
  );
  const updatedUserResolveFields = entryExistsForField
    ? userResolveFields.map(userResolveField => {
        return userResolveField.componentTypeName === componentTypeName
          ? { ...userResolveField, fieldName }
          : userResolveField;
      })
    : [...userResolveFields, { componentTypeName, fieldName }];
  return {
    ...traversalAudience,
    userResolveFields: updatedUserResolveFields,
  };
};

export const withPathRemoved = (
  traversalAudience: SurveyApprovalTraversalAudience,
  pathToRemove: DirectedTripleWithFilters[]
): SurveyApprovalTraversalAudience => {
  const pathToRemoveToBEFormat = pathToRemove.map(
    namedDirectedTripleToBackendFormat
  );
  const updatedTraversal = {
    ...traversalAudience.traversal,
    paths: traversalAudience.traversal.paths.filter(
      path => !isEqual(path, pathToRemoveToBEFormat)
    ),
  };
  const audienceComponentTypes = getAudienceComponentTypes(updatedTraversal);
  return {
    audienceType: 'traversal',
    traversal: updatedTraversal,
    userResolveFields: traversalAudience.userResolveFields.filter(
      ({ componentTypeName }) => {
        return audienceComponentTypes.includes(componentTypeName);
      }
    ),
  };
};

const getComponentRepresentationData = (
  metamodelComponentType: MetaModelComponentType | undefined
) => {
  if (!metamodelComponentType) {
    return colorsAndRepresentations.getFallbackComponentRepresentation();
  }

  const styleData = metamodelComponentType.style;

  if (styleData.image) {
    return {
      icon: getIcon(null),
      isImage: true,
      value: styleData.image,
    };
  }

  if (styleData.icon) {
    return {
      icon: getIcon(styleData.icon),
      isImage: false,
      value: styleData.icon,
    };
  }
  return colorsAndRepresentations.getFallbackComponentRepresentation();
};

const getColorPalette = (
  metamodelComponentType: MetaModelComponentType | undefined
) => {
  const color =
    metamodelComponentType?.style.color ??
    getDefaultCSSColor(metamodelComponentType?.style.level ?? 1);
  const shadedColor = getShadedColor(color);
  const lightenedColor = getLightenedColor(color);
  const contrastColor = ensureContrast(lightenedColor, color);
  return {
    color,
    shadedColor,
    lightenedColor,
    contrastColor,
  };
};

export const getComponentMetaData = (
  metamodel: MetaModel,
  componentTypeName: string
) => {
  const metamodelComponentType = metamodel.componentTypes.find(
    componentType => componentType.name === componentTypeName
  );
  const { color, ...otherColors } = getColorPalette(metamodelComponentType);
  return {
    representationData: {
      ...getComponentRepresentationData(metamodelComponentType),
      ...otherColors,
      color,
    },
    color,
    typeId: componentTypeName,
    label: componentTypeName,
  };
};

let count = 1;
export const getId = () => `graph-id-${count++}`;

export const getReferenceMetaData = (
  metamodel: MetaModel,
  referenceTypeName: string
) => {
  const metamodelReferenceTypeStyle = metamodel.referenceTypes.find(
    referenceType => referenceType.name === referenceTypeName
  )?.style;
  return {
    representationData: {
      color: metamodelReferenceTypeStyle?.color ?? 'black',
      displayLabel: referenceTypeName,
      lineBeginning:
        metamodelReferenceTypeStyle?.lineBeginning ?? ArrowType.NONE,
      line: metamodelReferenceTypeStyle?.line ?? LineType.SOLID,
      lineEnding: metamodelReferenceTypeStyle?.lineEnding ?? ArrowType.BOTH,
    },
  };
};

export const getAudienceComponentTypes = (
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>
) => {
  const endComponentTypes = traversal.paths
    .map(path => {
      const lastTriple = last(path);
      if (!lastTriple) return null;
      return lastTriple.direction === 'outgoing'
        ? lastTriple.targetType
        : lastTriple.sourceType;
    })
    .filter(ExcludeFalsy);
  return uniq(endComponentTypes);
};

const convertTraversalToBackendFormat = (
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>
) => {
  return {
    ...traversal,
    paths: traversal.paths.map(path =>
      path.map(namedDirectedTripleToBackendFormat)
    ),
  };
};

export const withUpdatedTraversal = (
  traversalAudience: SurveyApprovalTraversalAudience,
  updatedTraversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>,
  fieldsByComponentTypeName: FieldsByComponentTypeName
): SurveyApprovalTraversalAudience => {
  const audienceComponentTypes = getAudienceComponentTypes(updatedTraversal);
  const userResolveFieldsFromUpdatedTraversal = audienceComponentTypes
    .map(componentTypeName => {
      const approverFields = getApproverFieldsByComponentTypeName(
        fieldsByComponentTypeName,
        componentTypeName
      );
      const contactEmailFieldName =
        preselectContactEmailIfItExists(approverFields);
      if (!contactEmailFieldName) return;
      return { componentTypeName, fieldName: contactEmailFieldName };
    })
    .filter(ExcludeFalsy);
  const traversalToBackendFormat =
    convertTraversalToBackendFormat(updatedTraversal);
  return {
    audienceType: 'traversal',
    traversal: traversalToBackendFormat,
    userResolveFields: uniqBy(
      [
        ...traversalAudience.userResolveFields,
        ...userResolveFieldsFromUpdatedTraversal,
      ].filter(({ componentTypeName }) => {
        return audienceComponentTypes.includes(componentTypeName);
      }),
      'componentTypeName'
    ),
  };
};

export type DirectedTripleWithNodeIds = {
  sourceType: string;
  targetType: string;
  referenceType: string;
  direction: TripleDirection;
  sourceNodeId: string;
  targetNodeId: string;
  referenceEdgeId: string;
};

export const referenceTypeToFrontendFormat = (referenceType: string) => {
  return referenceType === 'ardoq_parent'
    ? PARENT_REF_TYPE_NAME
    : referenceType;
};

export const namedDirectedTripleToFrontendFormat = (
  triple: NamedDirectedTriple
) => {
  return {
    ...triple,
    referenceType: referenceTypeToFrontendFormat(triple.referenceType),
  };
};

const namedDirectedTripleToBackendFormat = (triple: NamedDirectedTriple) => ({
  ...triple,
  referenceType: editTraversalOperations.toBELabel(triple.referenceType),
});
