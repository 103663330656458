import { ResizeDirection } from './types';
import { useCallback } from 'react';
import { getResizeDownHandler } from './getResizeDownHandler';
import { Resizer, ResizerProps } from './atoms';

export const VerticalResizer = ({ style, isActive, getBox }: ResizerProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verticalMouseDownHandler = useCallback(
    getResizeDownHandler(getBox, ResizeDirection.VERTICAL),
    [getBox]
  );

  return (
    <Resizer
      style={style}
      $direction={ResizeDirection.VERTICAL}
      $isActive={isActive}
      onMouseDown={verticalMouseDownHandler}
    />
  );
};

export const HorizontalResizer = ({
  style,
  isActive,
  getBox,
}: ResizerProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const horizontalMouseDownHandler = useCallback(
    getResizeDownHandler(getBox, ResizeDirection.HORIZONTAL),
    [getBox]
  );

  return (
    <Resizer
      style={style}
      $direction={ResizeDirection.HORIZONTAL}
      $isActive={isActive}
      onMouseDown={horizontalMouseDownHandler}
    />
  );
};
