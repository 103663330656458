import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import assets$ from '../streams/assets/assets$';
import { combineFolders } from '../components/EntityBrowser/utils';
import { saveAsViewpoint$ } from '../appLayout/ardoqStudio/saveAsViewpoint/saveAsViewpoint$/saveAsViewpoint$';
import { ViewIds } from '@ardoq/api-types';
import { Row } from '../components/EntityBrowser/types';
import { AssetRow } from '../components/AssetsBrowser/types';
import { reducedStream, reducer } from '@ardoq/rxbeach';
import { ArdoqError, setStateProperty } from '@ardoq/common-helpers';
import {
  setBookmarkName as setBookmarkNameAction,
  setBookmarkFolder as setBookmarkFolderAction,
  setSaveBookmarkError as setSaveBookmarkErrorAction,
} from './actions';

type SaveAsBookmarkStreamShape = {
  bookmarkName: string;
  selectedFolderId: string | null;
  error: ArdoqError | null;
  isSaveBookmarkEnabled: boolean;
};

export type SaveAsBookmarkViewModelShape = SaveAsBookmarkStreamShape & {
  viewId: ViewIds;
  folderSelectDataSource: Row<AssetRow>[];
};

const setBookmarkName = (
  state: SaveAsBookmarkStreamShape,
  bookmarkName: string
) => ({
  ...state,
  bookmarkName,
  isSaveBookmarkEnabled: bookmarkName.length > 0,
});

const saveAsBookmark$ = reducedStream<SaveAsBookmarkStreamShape>(
  'saveAsBookmark$',
  {
    bookmarkName: '',
    selectedFolderId: null,
    error: null,
    isSaveBookmarkEnabled: false,
  },
  [
    reducer(setBookmarkNameAction, setBookmarkName),
    reducer(setBookmarkFolderAction, setStateProperty('selectedFolderId')),
    reducer(setSaveBookmarkErrorAction, setStateProperty('error')),
  ]
);

const saveAsBookmarkViewModel$ = combineLatest([
  assets$,
  saveAsViewpoint$,
  saveAsBookmark$,
]).pipe(
  map(
    ([
      { assetFoldersById },
      { mainViewId },
      { bookmarkName, selectedFolderId, error, isSaveBookmarkEnabled },
    ]): SaveAsBookmarkViewModelShape => {
      const dataSource = combineFolders(Object.values(assetFoldersById), []);
      return {
        folderSelectDataSource: dataSource,
        viewId: mainViewId,
        bookmarkName,
        error,
        selectedFolderId,
        isSaveBookmarkEnabled,
      };
    }
  )
);

export default saveAsBookmarkViewModel$;
