import { persistentReducedStream } from '@ardoq/rxbeach';
import { defaultState, ExternalDocumentState, reducers } from './reducers';

// Note: This implementation currently mixes route information (externalDocumentId, title)
// with operational data (lucid clientId) to capture the generic External Document state.
// This is Lucid specific, and should be changed when adding additional external document sources.
const externalDocument$ = persistentReducedStream<ExternalDocumentState>(
  'externalDocument$',
  defaultState,
  reducers
);

export default externalDocument$;
