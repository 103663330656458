import { ArdoqId } from '@ardoq/api-types';
import type { ComponentRepresentationDataWithColorShades } from '@ardoq/graph';

export enum DataOverviewTypes {
  COMPONENT_TYPE = 'component type',
  WORKSPACE = 'workspace',
}

export enum DataOverviewStatus {
  IS_LOADING = 'isLoading',
  IS_ERROR = 'isError',
  IS_IDLE = 'isIdle',
}

export type DataOverviewRow = {
  _id: ArdoqId;
  name: string;
  totalNumberOfComponents: number;
  lastUpdated: string;
};

export type RepresentationData = {
  representationData: ComponentRepresentationDataWithColorShades;
};

export type DataOverviewComponentTypeRow = DataOverviewRow &
  RepresentationData & {
    componentTypeIds: string[];
  };

export type DataOverviewWorkspaceRow = DataOverviewRow & {
  componentTypeIds: ArdoqId[];
};

export type DataOverviewState = {
  dataOverviewType: DataOverviewTypes;
  numberOfRowsPerPage: number;
  searchValue: string;
  status: DataOverviewStatus;
  selectedItems: ArdoqId[] | string[];
};
