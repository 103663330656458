import { space } from '@ardoq/design-tokens';
import { Label } from '@ardoq/forms';
import { Box } from '@ardoq/layout';
import { Text } from '@ardoq/typography';
import React from 'react';

export const LabeledDetail = ({
  label,
  maxWidth,
  children,
}: React.PropsWithChildren<{ label: string; maxWidth?: string }>) => {
  return (
    <Box
      style={{
        maxWidth,
        flex: 2,
        gap: space.small,
        display: 'flex',
        flexDirection: 'column',
        paddingRight: space.large,
      }}
    >
      <Label>{label}</Label>
      <Text variant="text1">{children}</Text>
    </Box>
  );
};
