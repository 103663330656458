import {
  FormattingTab,
  type FormattingTabProps,
} from '@ardoq/perspectives-sidebar';
import { connect } from '@ardoq/rxbeach';
import formattingTab$ from 'perspectiveSidebar/perspectiveEditor/formattingTab$';
import ViewOptionsPerspectivesPage from './ViewOptionsPerspectivesPage';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';

const ViewOptionsConditionalFormat = (props: FormattingTabProps) => (
  <ViewOptionsPerspectivesPage
    title="Conditional format"
    knowledgeBaseLink={KnowledgeBaseLink.CONDITIONAL_FORMATTING}
  >
    <FormattingTab {...props} />
  </ViewOptionsPerspectivesPage>
);

export default connect(ViewOptionsConditionalFormat, formattingTab$);
