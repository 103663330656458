import { BooleanOrAuto } from 'tabview/types';
import {
  BubbleChartGridMode,
  type BubbleChartViewSettings,
} from 'tabview/bubbleChart/types';
import { TIMELINES_GRID_PRECISION_AUTO_OPTION_VALUE } from '@ardoq/timeline';
import { LayoutOrientation } from '@ardoq/yfiles';
import { settingsBarConsts } from '@ardoq/settings-bar';
import { MAX_LIMIT_VALUES, SizeBasedOn } from 'views/treemapConsts';
import { ReferenceDirection, ViewIds } from '@ardoq/api-types';
import { CONTEXT_NODE_RADIUS } from '@ardoq/graph';
import {
  ProteanLayoutOptions,
  ProteanLayoutType,
  ProteanRenderingMode,
} from 'tabview/proteanDiagram/types';
import { WILDCARD } from '@ardoq/common-helpers';
import { GRID_INSET_SIZE } from 'tabview/proteanDiagram/view/consts';
import { NODE_WIDTH } from 'yfilesExtensions/styles/consts';
import { CommonDropdownOptions } from '@ardoq/global-consts';

export const PROTEAN_DEFAULT_LAYOUT_OPTIONS: ProteanLayoutOptions = {
  hierarchic: {
    orthogonalRouting: false,
    compactGroups: false,
    orientation: 'top-to-bottom',
    recursiveGroupLayering: true,
    sequenceConstraints: [],
    layerConstraints: [],
    separateReferences: false,
    directedEdges: false,
    minimumLayerDistance: 20,
    nodeToNodeDistance: 30,
    separateLayers: false,
    layoutRules: [],
  },
  organic: {
    nodeEdgeOverlapAvoided: false,
    compactnessFactor: 0.5,
  },
  circular: {
    edgeBundling: 0,
    layoutStyle: 'bcc-compact',
  },
  radial: {
    edgeBundling: 0,
    layeringStrategy: 'bfs',
  },
  orthogonal: {
    gridSpacing: 20,
    crossingReduction: true,
    edgeLengthReduction: true,
    optimizePerceivedBends: true,
    uniformPortAssignment: false,
  },
  spatialMap: { selectedFieldNameX: '', selectedFieldNameY: '' },
  swimlanes: { isVertical: false },
  cactus: {},
  tree: {},
  treeMap: {},
  tabular: {
    columnInsets: GRID_INSET_SIZE,
    rowInsets: GRID_INSET_SIZE,
    minColumnSize: NODE_WIDTH + GRID_INSET_SIZE * 2,
    minRowSize: NODE_WIDTH + GRID_INSET_SIZE * 2,
    rowConstraintsWithSource: [],
    columnConstraintsWithSource: [],
    rowSpans: [],
    columnSpans: [],
    layoutRules: [],
    separateReferences: false,
  },
  hierarchicInGrid: {
    columnInsets: GRID_INSET_SIZE,
    rowInsets: GRID_INSET_SIZE,
    minColumnSize: 0,
    minRowSize: 0,
    rowConstraintsWithSource: [],
    columnConstraintsWithSource: [],
    rowSpans: [],
    columnSpans: [],
    layoutRules: [],
    separateReferences: false,
  },
};

export const FIELDS_TO_RESET = {
  incomingDegreesOfRelationship: 'incomingDegreesOfRelationship',
  outgoingDegreesOfRelationship: 'outgoingDegreesOfRelationship',
  depthOfRelationship: 'depthOfRelationship',
  degreesOfChildhood: 'degreesOfChildhood',
  degreesOfParenthood: 'degreesOfParenthood',
};

const DEFAULT_TRAVERSAL_VIEW_SETTINGS = {
  [FIELDS_TO_RESET.incomingDegreesOfRelationship]: 0,
  [FIELDS_TO_RESET.outgoingDegreesOfRelationship]: 0,
  [FIELDS_TO_RESET.depthOfRelationship]: 1,
  showOnlyConnectedComponents: false,
  includeOutgoingReferenceTypeNames: [CommonDropdownOptions.ALL],
  includeIncomingReferenceTypeNames: [CommonDropdownOptions.ALL],
  additionalTraversals: [],
};

const defaultState = new Map<ViewIds, any>([
  [
    ViewIds.PAGESVIEW,
    {
      includeFields: [CommonDropdownOptions.ALL_EXCEPT_DESELECTED_BY_DEFAULT],
      includeIncomingReferenceTypes: [CommonDropdownOptions.ALL],
      includeOutgoingReferenceTypes: [CommonDropdownOptions.ALL],
      hideEmptyFields: false,
      expandDescription: false,
    },
  ],
  [
    ViewIds.TAGSCAPE,
    {
      excludeUnused: false,
      count: false,
      excludeComps: false,
      excludeRefs: false,
    },
  ],
  [
    ViewIds.TABLEVIEW,
    {
      visibleColumns: [],
      expandDescription: false,
      hideAllColumns: false,
      [settingsBarConsts.IS_LEGEND_ACTIVE]: false,
    },
  ],
  [
    ViewIds.COMPONENTTREE,
    {
      [settingsBarConsts.IS_LEGEND_ACTIVE]: false,
    },
  ],
  [ViewIds.DEPWHEEL, {}],
  [
    ViewIds.TREEMAP,
    {
      sizeBasedOn: SizeBasedOn.CHILD_COUNT,
      maxLimitValue: MAX_LIMIT_VALUES[MAX_LIMIT_VALUES.length - 1],
      [settingsBarConsts.IS_LEGEND_ACTIVE]: false, // new view
    },
  ],
  [
    ViewIds.BLOCK_DIAGRAM,
    {
      layoutOrientation: LayoutOrientation.LEFT_TO_RIGHT,
      [FIELDS_TO_RESET.incomingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.outgoingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.depthOfRelationship]: 1,
      showOnlyConnectedComponents: false,
      includeOutgoingReferenceTypeNames: [CommonDropdownOptions.ALL],
      includeIncomingReferenceTypeNames: [CommonDropdownOptions.ALL],
      additionalTraversals: [],
      activeDiffMode: 'scenario',
      collapsedGroupIds: [],
      sequenceConstraints: [],
      layerConstraints: [],
      separateReferences: false,
      useNewGrouping: true,
    },
  ],
  [
    ViewIds.METAMODEL,
    {
      layoutOrientation: LayoutOrientation.LEFT_TO_RIGHT,
      [FIELDS_TO_RESET.incomingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.outgoingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.depthOfRelationship]: 1,
      showOnlyConnectedComponents: false,
      includeOutgoingReferenceTypeNames: [CommonDropdownOptions.ALL],
      includeIncomingReferenceTypeNames: [CommonDropdownOptions.ALL],
      additionalTraversals: [],
      activeDiffMode: 'scenario',
      collapsedGroupIds: [],
      groupSequence: [],
      groupLayer: [],
      separateReferences: false,
      useNewGrouping: true,
    },
  ],
  [
    ViewIds.SWIMLANE,
    {
      [FIELDS_TO_RESET.incomingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.outgoingDegreesOfRelationship]: 99,
      orderAlphabetically: false,
      isVertical: false,
    },
  ],
  [
    ViewIds.DEPMATRIX,
    {
      referenceCreationType: {},
      referenceToWsId: undefined,
      showOnlyConnectedComponents: false,
    },
  ],
  [ViewIds.ROADMAP, { selectedFieldName: undefined }],
  [
    ViewIds.DEPENDENCY_MAP_2,
    {
      showVertical: false,
      colorByReference: false,
      [FIELDS_TO_RESET.incomingDegreesOfRelationship]: 1,
      [FIELDS_TO_RESET.outgoingDegreesOfRelationship]: 1,
      [FIELDS_TO_RESET.depthOfRelationship]: 0,
      includeOutgoingReferenceTypeNames: [
        CommonDropdownOptions.ALL_EXCEPT_PARENT_CHILD,
      ],
      includeIncomingReferenceTypeNames: [
        CommonDropdownOptions.ALL_EXCEPT_PARENT_CHILD,
      ],
      additionalTraversals: [],
      showOnlyConnectedComponents: false,
      treeDepth: Infinity,
      [FIELDS_TO_RESET.degreesOfParenthood]: 0,
      [FIELDS_TO_RESET.degreesOfChildhood]: 0,
      seenTraversalInfo: false,
      useNewGrouping: true,
    },
  ],
  [
    ViewIds.SPIDER,
    {
      aggregateChildValues: false,
      /** @type Record<string, boolean> */
      selectedFieldNames: {},
      sideways: false,
      [settingsBarConsts.IS_LEGEND_ACTIVE]: false,
      includeAllDescendants: false,
    },
  ],
  [
    ViewIds.REFERENCETABLE,
    {
      visibleColumns: [],
      expandDescription: false,
      hideAllColumns: false,
    },
  ],
  [
    ViewIds.CAPABILITY_MAP,
    {
      selectedFieldName: '',
      supportingValue: '',
      groupingTypeId: '',
      isLayoutDirectionAnyVertical: true,
      includeIncomingReferenceTypes: [],
      includeOutgoingReferenceTypes: [],
      treeDepth: Infinity,
    },
  ],
  [
    ViewIds.CIRCULAR_RELATIONSHIP_DIAGRAM,
    {
      [FIELDS_TO_RESET.incomingDegreesOfRelationship]: 1,
      [FIELDS_TO_RESET.outgoingDegreesOfRelationship]: 1,
      enableStyles: BooleanOrAuto.Auto,
      includeChildren: false,
      isLegendActive: false,
    },
  ],
  [
    ViewIds.BUBBLE,
    {
      selectedFieldNameX: '',
      selectedFieldNameY: '',
      selectedFieldNameRadius: '',
      background: BubbleChartGridMode.NONE,
      isLegendActive: false,
      customQuadrantSettings: {
        xMinimum: NaN,
        xMaximum: NaN,
        yMinimum: NaN,
        yMaximum: NaN,
        labels: ['one', 'two', 'three', 'four'],
      },
      bubbleAreaScaleFactor: 0.5,
      fieldSource: 'byComponent',
      includeAllDescendants: false,
      selectedReferenceTypeName: null,
      selectedReferenceDirection: ReferenceDirection.INCOMING,
      showBubblesWithZeroValue: true,
    } satisfies BubbleChartViewSettings,
  ],
  [
    ViewIds.TIMELINE,
    {
      isLegendActive: false,
      selectedMilestoneFields: [],
      selectedPhaseFields: [WILDCARD],
      useNewGrouping: true,
      domainRange: [],
      gridPrecision: TIMELINES_GRID_PRECISION_AUTO_OPTION_VALUE,
      showGroupedComponentsWithoutDateTimeFields: false,
      [FIELDS_TO_RESET.incomingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.outgoingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.depthOfRelationship]: 1,
    },
  ],
  [
    ViewIds.COMPONENT_MATRIX,
    {
      selectedFieldNamesX: [],
      selectedFieldNamesY: [],
      referenceTypeX: null,
      referenceTypeY: null,
      filterNulls: false,
      includeAllDescendants: false,
    },
  ],
  [
    ViewIds.NETWORK,
    {
      [FIELDS_TO_RESET.incomingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.outgoingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.depthOfRelationship]: 1,
      showOnlyConnectedComponents: false,
      includeOutgoingReferenceTypeNames: [CommonDropdownOptions.ALL],
      includeIncomingReferenceTypeNames: [CommonDropdownOptions.ALL],
      additionalTraversals: [],
      activeDiffMode: 'scenario',
      collapsedGroupIds: [],
      nodeDistance: CONTEXT_NODE_RADIUS,
      animate: true,
    },
  ],
  [
    ViewIds.ISOMETRIC_BLOCK_DIAGRAM,
    {
      layoutOrientation: LayoutOrientation.LEFT_TO_RIGHT,
      [FIELDS_TO_RESET.incomingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.outgoingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.depthOfRelationship]: 1,
      showOnlyConnectedComponents: false,
      includeOutgoingReferenceTypeNames: [CommonDropdownOptions.ALL],
      includeIncomingReferenceTypeNames: [CommonDropdownOptions.ALL],
      additionalTraversals: [],
      activeDiffMode: 'scenario',
      collapsedGroupIds: [],
      selectedFieldNameValue: '',
      separateReferences: false,
      sequenceConstraints: [],
      layerConstraints: [],
    },
  ],
  [
    ViewIds.RELATIONSHIPS_3,
    {
      [FIELDS_TO_RESET.incomingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.outgoingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.depthOfRelationship]: 1,
      showOnlyConnectedComponents: false,
      includeOutgoingReferenceTypeNames: [CommonDropdownOptions.ALL],
      includeIncomingReferenceTypeNames: [CommonDropdownOptions.ALL],
      additionalTraversals: [],
      activeDiffMode: 'scenario',
      collapsedGroupIds: [],
      bundleRelationships: true,
      highlightDisconnectedComponents: false,
    },
  ],
  [
    ViewIds.PROTEAN_DIAGRAM,
    {
      ...DEFAULT_TRAVERSAL_VIEW_SETTINGS,
      activeDiffMode: 'scenario',
      collapsedGroupIds: [],
      layoutType: ProteanLayoutType.Hierarchic,
      renderingMode: ProteanRenderingMode.SVG,
      layoutOptions: PROTEAN_DEFAULT_LAYOUT_OPTIONS,
    },
  ],
  [
    ViewIds.HIERARCHICAL_TREEMAP,
    {
      ...DEFAULT_TRAVERSAL_VIEW_SETTINGS,
      sizeByFieldName: null,
      tilingMethod: 'squarify',
      maxDepth: 0,
    },
  ],
  [
    ViewIds.HIERARCHICAL_PACKING,
    {
      ...DEFAULT_TRAVERSAL_VIEW_SETTINGS,
      sizeByFieldName: null,
      maxDepth: 0,
    },
  ],
  [
    ViewIds.SUNBURST,
    {
      ...DEFAULT_TRAVERSAL_VIEW_SETTINGS,
      sizeByFieldName: null,
      maxDepth: 0,
    },
  ],
  [
    ViewIds.HIERARCHIC_IN_GRID,
    {
      ...DEFAULT_TRAVERSAL_VIEW_SETTINGS,
      activeDiffMode: 'scenario',
      collapsedGroupIds: [],
      layoutType: ProteanLayoutType.HierarchicInGrid,
      renderingMode: ProteanRenderingMode.SVG,
      layoutOptions: PROTEAN_DEFAULT_LAYOUT_OPTIONS,
    },
  ],
  [ViewIds.BLOCKS, { ...DEFAULT_TRAVERSAL_VIEW_SETTINGS, layoutData: {} }],
  [
    ViewIds.MODERNIZED_BLOCK_DIAGRAM,
    {
      layoutOrientation: LayoutOrientation.LEFT_TO_RIGHT,
      [FIELDS_TO_RESET.incomingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.outgoingDegreesOfRelationship]: 0,
      [FIELDS_TO_RESET.depthOfRelationship]: 1,
      showOnlyConnectedComponents: false,
      includeOutgoingReferenceTypeNames: [CommonDropdownOptions.ALL],
      includeIncomingReferenceTypeNames: [CommonDropdownOptions.ALL],
      additionalTraversals: [],
      activeDiffMode: 'scenario',
      collapsedGroupIds: [],
      sequenceConstraints: [],
      layerConstraints: [],
      separateReferences: false,
      componentStyle: 'icon',
    },
  ],
  // #region discontinued views
  [ViewIds.SEQUENCE, {}],
  [ViewIds.REFERENCE_AGGREGATION_VIEW, {}],
  [ViewIds.PROCESSFLOW, {}],
  [ViewIds.HEATMAP, {}],
  [ViewIds.API_DOCUMENTATION, {}],
  [ViewIds.RELATIONSHIPS, {}],
  [ViewIds.DEPMAP, {}],
  [ViewIds.INTERACTIVE_BLOCKS, {}],
  [ViewIds.INTENTIONAL, {}],
  // #endregion
]);
export default defaultState;
