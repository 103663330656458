import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { filter } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  transferFailure,
  transferSuccess,
} from 'integrations/common/streams/transferState/actions';
import { setVisibleIntegration } from 'integrations/actions';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { navigateToPath } from 'integrations/common/navigation/actions';
import {
  ImportRoute,
  OverviewRoute,
} from 'integrations/common/navigation/types';

const handleSuccessImportResult = routine(
  ofType(transferSuccess),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'excel-v3'),
  tap(({ integrationId, dryRun }) => {
    if (dryRun) {
      dispatchAction(
        navigateToPath({ integrationId, path: ImportRoute.REVIEW })
      );
      return;
    }
    dispatchAction(
      navigateToPath({ integrationId, path: ImportRoute.IMPORT_AND_SCHEDULE })
    );
  })
);

const handleFailureImportResult = routine(
  ofType(transferFailure),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'excel-v3'),
  tap(({ integrationId }) => {
    dispatchAction(navigateToPath({ integrationId, path: ImportRoute.REVIEW }));
  })
);

const handleNavigateToPath = routine(
  ofType(navigateToPath),
  extractPayload(),
  tap(({ integrationId, path, source }) => {
    dispatchAction(
      setVisibleIntegration({
        id: integrationId,
        path: path,
        source: source,
      })
    );
  })
);

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  filter(integrationId => integrationId === 'excel-v3'),
  tap(integrationId => {
    dispatchAction(
      trackIntegrationEvent({ integrationId, name: 'RESTARTED_IMPORT' })
    );
    dispatchAction(
      setVisibleIntegration({
        id: integrationId,
        path: OverviewRoute.CONFIGURATIONS,
      })
    );
  })
);

export default [
  handleSuccessImportResult,
  handleFailureImportResult,
  handleNavigateToPath,
  handleResetIntegration,
];
