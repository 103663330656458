import styled from 'styled-components';
import { Icon, IconName } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { Space } from '@ardoq/style-helpers';
import { header4 } from '@ardoq/typography';
import { colors } from '@ardoq/design-tokens';

const TextLabel = styled.div`
  ${header4}
`;

export const TextLabelWithPopoverIcon = ({
  text,
  popoverContent,
}: {
  text: string;
  popoverContent: string;
}) => (
  <Space $align="center" $gap="s4">
    <TextLabel>{text}</TextLabel>
    <WithPopover content={popoverContent}>
      <Icon iconName={IconName.KNOWLEDGE_BASE} color={colors.grey50}></Icon>
    </WithPopover>
  </Space>
);
