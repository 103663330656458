import { trackEvent } from '../tracking/tracking';

export enum ViewpointNavigatorTrackingEventsNames {
  CLICKED_CONTEXT_COMPONENT_DROPDOWN = 'Navigator0 Datablock Used context switcher',
  CLICKED_DATASET_VISIBILITY_ICON = 'Navigator0 Datablock changed visibility',
  CLICKED_EDIT_DATASET_BUTTON = 'Navigator0 Datablock Edited dataset',
}

const trackDatasetVisibility = (isHidden: boolean) => {
  trackEvent(
    ViewpointNavigatorTrackingEventsNames.CLICKED_DATASET_VISIBILITY_ICON,
    {
      visible: isHidden ? 'on' : 'off',
    }
  );
};

export { trackDatasetVisibility };
