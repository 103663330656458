import { useState } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import { IconName } from '@ardoq/icons';
import IconClickable from 'atomicComponents/IconClickable';
import {
  deleteInvitation,
  resendInvitation,
} from 'streams/invitations/actions';
import { ButtonGroup } from '@ardoq/button';

type ActionIconProps = {
  iconName: IconName;
  tooltipText: string;
  onClick: () => any;
};
const ActionIcon = (props: ActionIconProps) => (
  <IconClickable
    containerStyle={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    {...props}
  />
);

type InvitationActionsProps = { invitationId: string };
export const InvitationActions = ({ invitationId }: InvitationActionsProps) => {
  const [hasResent, setHasResent] = useState(false);
  return (
    <ButtonGroup $alignToRight={true}>
      <ActionIcon
        iconName={hasResent ? IconName.CHECK_MARK : IconName.REFRESH}
        tooltipText="Resend invitation"
        onClick={() => {
          if (hasResent) return;
          dispatchAction(resendInvitation({ invitationId }));
          setHasResent(true);
        }}
      />
      <ActionIcon
        iconName={IconName.DELETE}
        tooltipText="Remove user"
        onClick={() => dispatchAction(deleteInvitation({ invitationId }))}
      />
    </ButtonGroup>
  );
};
