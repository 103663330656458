import {
  APICurrentUser,
  ArdoqId,
  OrgAccessLevel,
  PermissionAccessLevel,
  PermissionGroup,
  PermissionType,
  UserWithRoles,
} from '@ardoq/api-types';
import {
  getPermissionAccessLevelLabel,
  GroupOrUserSelectOption,
} from '@ardoq/manage-resource-permissions';
import { logError } from '@ardoq/logging';
import { ReportBuilderCommands, ReportTemplate } from '@ardoq/report-builder';
import { setReportValue } from './utils';

const defaultPermissionOptions = [
  PermissionAccessLevel.ADMIN,
  PermissionAccessLevel.READ,
  PermissionAccessLevel.EDIT,
].map(permission => ({
  value: permission,
  label: getPermissionAccessLevelLabel(permission),
}));

const getPermissionOptionsForUser = (user: UserWithRoles) => {
  if (user.orgRole === OrgAccessLevel.ADMIN) {
    return []; // Admins always have access, can't be changed.
  } else if (user.orgRole === OrgAccessLevel.WRITER) {
    return defaultPermissionOptions;
  }
  return [
    {
      value: PermissionAccessLevel.READ,
      label: getPermissionAccessLevelLabel(PermissionAccessLevel.READ),
    },
  ];
};

const isUserAsset = (
  asset: UserWithRoles | PermissionGroup
): asset is UserWithRoles =>
  typeof (asset as UserWithRoles).email !== 'undefined';

export const getPermissionsCommands = ({
  reportTemplate,
  userHasReportAdminAccess,
  currentUser,
}: {
  reportTemplate: ReportTemplate;
  currentUser: APICurrentUser;
  userHasReportAdminAccess: boolean;
}): Pick<
  ReportBuilderCommands,
  'revokePermission' | 'updatePermission' | 'setPermissionsForGroupOrUser'
> => ({
  setPermissionsForGroupOrUser: ({
    option: asset,
  }: GroupOrUserSelectOption) => {
    if (!currentUser.organization) {
      return logError(
        Error(
          'User tried to set permissions without being logged into an organization'
        )
      );
    }
    if (isUserAsset(asset)) {
      return setReportValue('userPermissions', [
        ...reportTemplate.userPermissions,
        {
          ...asset,
          permissions:
            asset.orgRole === OrgAccessLevel.ADMIN
              ? [PermissionAccessLevel.ADMIN]
              : [PermissionAccessLevel.READ],
          permissionOptions: getPermissionOptionsForUser(asset),
          type: PermissionType.USER,
          revokeable: asset.orgRole !== OrgAccessLevel.ADMIN,
        },
      ]);
    }
    setReportValue('groupPermissions', [
      ...reportTemplate.groupPermissions,
      {
        ...asset,
        revokeable: true,
        permissions: [PermissionAccessLevel.READ],
        permissionOptions: defaultPermissionOptions,
        type: PermissionType.GROUP,
        permissionRole: `${currentUser.organization.label}/${asset.label}`,
      },
    ]);
  },
  updatePermission: !userHasReportAdminAccess
    ? undefined
    : (
        type: PermissionType,
        value: PermissionAccessLevel | null,
        id?: ArdoqId | null,
        label?: string
      ) =>
        type === PermissionType.USER
          ? setReportValue(
              'userPermissions',
              reportTemplate.userPermissions.map(permission =>
                permission._id === id
                  ? {
                      ...permission,
                      permissions: value === null ? [] : [value],
                    }
                  : permission
              )
            )
          : setReportValue(
              'groupPermissions',
              reportTemplate.groupPermissions.map(permission =>
                permission.permissionRole === label
                  ? {
                      ...permission,
                      permissions: value === null ? [] : [value],
                    }
                  : permission
              )
            ),
  revokePermission: userHasReportAdminAccess
    ? (type: PermissionType, id?: ArdoqId | null, label?: string) => {
        if (type === PermissionType.USER) {
          setReportValue(
            'userPermissions',
            reportTemplate.userPermissions.filter(({ _id }) => _id !== id)
          );
        } else {
          setReportValue(
            'groupPermissions',
            reportTemplate.groupPermissions.filter(
              ({ label: _label, permissionRole }) =>
                _label !== label && permissionRole !== label
            )
          );
        }
      }
    : undefined,
});
