type DataSourceBaseRow = {
  _id: string;
  componentLabel: string;
};

export type DataSourceChildRow = DataSourceBaseRow & {
  personLabel: null;
  personSublabel: null;
  sourceLabels: [];
  audienceStartingPage: null;
  children: null;
  isChildRow: true;
  tableFilter?: string;
};

export enum AudienceTableTab {
  COMPONENT = 'component',
  AUDIENCE = 'audience',
}

export enum AudienceTableColumn {
  SORTABLE_COMPONENT_LABEL = 'sortableComponentLabel',
  COMPONENT_STATUS = 'componentStatus',
  AUDIENCE_STATUS = 'audienceStatus',
  COMPONENT_LABEL = 'componentLabel',
  PERSON_LABEL = 'personLabel',
  AUDIENCE_STARTING_PAGE_LABEL = 'audienceStartingPageLabel',
  SOURCE_LABELS = 'sourceLabels',
}

export enum ComponentStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
}

export enum AudienceStatus {
  MISSING_EMAIL = 'missingEmail',
  VALID = 'valid',
}
