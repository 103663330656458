import { SidebarMenu } from '@ardoq/sidebar-menu';
import { Icon, IconName } from '@ardoq/icons';
import { TraversalBuilderViewState } from '../types';
import { FullHeightSpace } from 'viewpointBuilder/components/atoms';
import { FlexBox, Stack } from '@ardoq/layout';
import { colors } from '@ardoq/design-tokens';
import { Header3, Text } from '@ardoq/typography';
import RequiredComponentsSelection from './RequiredComponentsSelection';
import { RequiredComponentsCommands } from './requiredComponentsCommands';
import ViewpointBuilderSidebarEmptyState from '../ViewpointBuilderSidebarEmptyState';

type RequiredComponentsTabSidebarProps = {
  state: TraversalBuilderViewState['requiredComponentsState'];
  commands: RequiredComponentsCommands;
};

const RequiredComponentsTabSidebar = ({
  state,
  commands,
}: RequiredComponentsTabSidebarProps) => {
  return (
    <SidebarMenu
      isPageBodyMenu
      isResizable
      isRightContent
      headerBody={
        <FlexBox align="center" gap="xsmall" padding="medium">
          <Icon iconName={IconName.SETTINGS} color={colors.grey60} />
          <Header3>Select required paths</Header3>
        </FlexBox>
      }
    >
      <FullHeightSpace>
        <FlexBox padding="medium">
          <Stack gap="medium" align="center">
            <Text>
              Select the paths that the components in your dataset must match.
              Components that don&quot;t match your selected paths will be
              excluded from the dataset.
            </Text>
            {state && state.items.length ? (
              <RequiredComponentsSelection state={state} commands={commands} />
            ) : (
              <ViewpointBuilderSidebarEmptyState
                title="No paths selected yet"
                description="Select a component type in the dataset metamodel. All required paths start from the context component type."
              />
            )}
          </Stack>
        </FlexBox>
      </FullHeightSpace>
    </SidebarMenu>
  );
};

export default RequiredComponentsTabSidebar;
