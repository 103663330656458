import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { dispatchAction } from '@ardoq/rxbeach';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { azureSubscriptions$ } from '../../streams/subscriptions/subscriptions$';
import { selectionState$ } from '../../streams/selectionState/selectionState$';
import { changeSubscriptions } from '../../streams/selectionState/actions';
import { ensureCloudProvider } from 'integrations/cloudProviders/utils';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';

export const viewModel$ = integrationId$.pipe(
  ensureCloudProvider(),
  switchMap(() => {
    return combineLatest({
      subscriptions: azureSubscriptions$.pipe(
        map(({ subscriptions }) => subscriptions)
      ),
      selectionState: selectionState$,
      connectionsState: getConnectionsStream('azure-v3'),
    });
  }),
  map(
    ({
      subscriptions,
      selectionState,
      connectionsState: { selectedConnectionIds },
    }) => {
      return {
        isDisabled: selectedConnectionIds.length === 0,
        selectedSubscriptionIds: selectionState.subscriptionIds,
        subscriptions,
        onSelect: (subscriptionIds: string[]) => {
          dispatchAction(
            changeSubscriptions({
              subscriptionIds,
              oldSubscriptionIds: selectionState.subscriptionIds,
            })
          );
        },
      };
    }
  )
);
