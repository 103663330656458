import TabbedContent from 'broadcasts/components/TabbedContent';
import GremlinAudienceByPersonTable from './GremlinAudienceByPersonTable';
import GremlinQueryResultsTable from './GremlinQueryResultsTable';
import gremlinAudienceDialog$ from '../gremlinAudienceDialog$';
import GremlinSearchError from 'search/Gremlin/GremlinResults/GremlinSearchError';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { GremlinAudienceDialogStreamShape } from '../types';
import { LazyLoadResults } from '@ardoq/pagination';
import { BroadcastContentType } from '@ardoq/api-types';
import { ModalLayout } from '@ardoq/modal';
import { setTableFilter } from '../actions';
import { Stack } from '@ardoq/layout';
import { spacing } from '@ardoq/design-tokens';
import { styled } from 'styled-components';

const OverflowWrapper = styled(Stack)`
  overflow: hidden;
  padding: ${spacing.s16} ${spacing.s24};
  height: 100%;
`;

enum TabbedGremlinAudienceTableTab {
  AUDIENCE_VIEW = 'audience view',
  GREMLIN_RESULTS = 'gremlin results',
}

type TabbedGremlinAudienceTableStreamedProps = Pick<
  GremlinAudienceDialogStreamShape,
  'hasGremlinSearchError' | 'gremlinSyntaxError' | 'tableFilter'
>;

type TabbedGremlinAudienceTableOwnProps = {
  readonly resultsId: string;
  readonly loadResults: LazyLoadResults;
  readonly contentType: BroadcastContentType;
};

const TabbedGremlinAudienceTable = ({
  hasGremlinSearchError,
  gremlinSyntaxError,
  resultsId,
  loadResults,
  contentType,
  tableFilter,
}: TabbedGremlinAudienceTableStreamedProps &
  TabbedGremlinAudienceTableOwnProps) => {
  if (hasGremlinSearchError) {
    return (
      <ModalLayout>
        <GremlinSearchError syntaxError={gremlinSyntaxError} />
      </ModalLayout>
    );
  }
  return (
    <OverflowWrapper>
      <TabbedContent
        onFilterChange={query => dispatchAction(setTableFilter(query))}
        tabs={[
          {
            title: TabbedGremlinAudienceTableTab.AUDIENCE_VIEW,
            content: <GremlinAudienceByPersonTable contentType={contentType} />,
          },
          {
            title: TabbedGremlinAudienceTableTab.GREMLIN_RESULTS,
            content: (
              <GremlinQueryResultsTable
                loadResults={loadResults}
                resultsId={resultsId}
                tableFilter={tableFilter}
              />
            ),
          },
        ]}
        initiallySelectedTab={TabbedGremlinAudienceTableTab.GREMLIN_RESULTS}
      />
    </OverflowWrapper>
  );
};

export default connect(TabbedGremlinAudienceTable, gremlinAudienceDialog$);
