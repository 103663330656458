import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId } from '@ardoq/api-types';

type CreateWorkspaceFromTemplate = {
  templateId: ArdoqId;
};

export const selectCategory = actionCreator<string>(
  '[workspacewizard] SELECET_CATEGORY'
);

export const createWorkspaceFromTemplate =
  actionCreator<CreateWorkspaceFromTemplate>(
    '[fromTemplate] CREATE_WORKSPACE_FROM_TEMPLATE'
  );

export const toggleFavoriteTemplate =
  actionCreator<CreateWorkspaceFromTemplate>(
    '[fromTemplate] SET_TEMPLATE_AS_FAVORITE'
  );

export const closeWorkspaceWizard = actionCreator(
  '[fromTemplate] CLOSE_WORKSPACE_WIZARD'
);
