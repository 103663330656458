import {
  APIFieldType,
  ArdoqId,
  SurveyQuestionType,
  UnpersistedSurveyQuestion,
} from '@ardoq/api-types';
import { ValidatorFunction } from 'aqTypes';
import {
  AllPossibleQuestions,
  SurveyQuestionValidations,
  SurveyValidation,
} from 'surveyAdmin/types';
import { Position } from '@ardoq/drag-and-drop';
import { PermissionContext } from '@ardoq/access-control';
import { SurveyEditorState } from 'surveyAdmin/SurveyEditor/streams/types';

export type FieldQuestionData = {
  type: SurveyQuestionType.FIELD | SurveyQuestionType.ATTRIBUTE;
  readonly: boolean;
  value: string;
  fieldType?: APIFieldType;
  description?: string;
  label: string;
};

type CommonQuestionProps = {
  isComponent: boolean;
  typeId?: string;
  workspaceId: ArdoqId | null;
  modelId?: ArdoqId;
  parentQuestionIsReadonly?: boolean;
  surveyId?: ArdoqId;
  surveyValidation?: SurveyValidation;
  validators?: Partial<Record<APIFieldType, ValidatorFunction>>;
  allowComponentCreation?: boolean;
  surveyIsPermissionDivisionsAware: boolean;
  permissionsContext: PermissionContext;
};

export type SortableQuestionProps = CommonQuestionProps & {
  question: AllPossibleQuestions;
  index: number;
  isExpanded: boolean;
  QuestionBuilder?: React.ComponentType<QuestionBuilderProps>;
  questionValidations?: SurveyQuestionValidations;
  questions?: AllPossibleQuestions[];
  dragAndDropCardId: string;
  hasSurveyResponseApprovalsFeature: boolean;
  updateQuestion: (updatedQuestion: AllPossibleQuestions) => void;
  removeQuestion: () => void;
  expandQuestion: () => void;
  handleOrderChange: (id: string, position: Position) => void;
  fieldHasBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith'];
};

export type SortableQuestionsProps = CommonQuestionProps & {
  questions?: AllPossibleQuestions[];
  QuestionBuilder?: React.ComponentType<QuestionBuilderProps>;
  surveyValidation?: SurveyValidation;
  subQuestionValidations?: SurveyQuestionValidations[];
  expandQuestion: (question: AllPossibleQuestions | undefined) => void;
  updateQuestions: (questions: AllPossibleQuestions[]) => void;
  expandedQuestion?: string;
  addQuestionLabel: string;
  addQuestionDescription: string;
  hasSurveyResponseApprovalsFeature: boolean;
  addQuestion: (question: UnpersistedSurveyQuestion) => void;
};

export type QuestionBuilderProps = CommonQuestionProps & {
  questions?: AllPossibleQuestions[];
  surveyValidation?: SurveyValidation;
  addQuestionLabel: string;
  addQuestionDescription: string;
  subQuestionValidations?: SurveyQuestionValidations[];
  hasSurveyResponseApprovalsFeature: boolean;
  updateQuestions: (questions: AllPossibleQuestions[]) => void;
};

export type AddQuestionProperties = {
  isComponent: boolean;
  addQuestionLabel: string;
  addQuestionDescription: string;
  questions: AllPossibleQuestions[];
  hasSurveyResponseApprovalsFeature: boolean;
  addQuestion: (question: UnpersistedSurveyQuestion) => void;
};

export enum SelectionMode {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}
