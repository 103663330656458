import { Checkbox, Label, Slider } from '@ardoq/forms';
import { PartialStep } from '../../types';
import { startSetEqualsEndSet } from '../../utils';
import { Description } from './atoms';
import { s16, s24, s32, s8, colors } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import { APIDiscoverViewpointAttributes } from '@ardoq/api-types';

type MaxRepetitionFormProps = {
  step: PartialStep;
  onChange: (step: PartialStep) => void;
  index: number;
  isDisabled: boolean;
};

const Wrapper = styled.div`
  padding: ${s16} ${s24};
  background-color: ${colors.white};
  border-radius: ${s8};
`;

const SliderWrapper = styled.div`
  width: 180px;
  margin-bottom: ${s32};
`;

const MaxRepetitionForm = ({
  step,
  onChange,
  index,
  isDisabled,
}: MaxRepetitionFormProps) => {
  const {
    field: { onChange: onDynamicGroupedTraversalsChange },
  } = useController({ name: 'dynamicGroupedTraversals' });
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const dynamicGroupedTraversals = watch('dynamicGroupedTraversals');

  const handleUpdateFlexbility = (
    isFlexible: boolean,
    maxRepetition: number
  ) => {
    if (maxRepetition === 0) {
      return onDynamicGroupedTraversalsChange([
        ...dynamicGroupedTraversals.slice(0, index),
        { ...step, flexible: isFlexible, maxRepetition },
        ...dynamicGroupedTraversals.slice(index + 1),
      ]);
    }
    const stepsWithResetFlexibility = [...dynamicGroupedTraversals].map(
      currentStep => ({
        ...currentStep,
        flexible: false,
      })
    );
    onDynamicGroupedTraversalsChange([
      ...stepsWithResetFlexibility.slice(0, index),
      { ...step, flexible: isFlexible, maxRepetition },
      ...stepsWithResetFlexibility.slice(index + 1),
    ]);
  };

  if (!startSetEqualsEndSet(step.constraints)) return null;
  return (
    <Wrapper>
      <Label>Choose maximum repetitions</Label>
      <Description>
        This step contains rules that have the same source and target component
        type. Please choose the maximum number of repetitions for these rules.
      </Description>
      <SliderWrapper>
        <Slider
          defaultValue={step.maxRepetition}
          maxValue={10}
          minValue={step.minRepetition}
          onChangeEnd={maxRepetition => {
            onChange({ ...step, maxRepetition });
            if (maxRepetition !== 0) return;
            handleUpdateFlexbility(false, maxRepetition);
          }}
          step={1}
          errorMessage={
            step.maxRepetition < step.minRepetition
              ? 'Max repetition cannot be lower than min repetition.'
              : step.maxRepetition > 10
                ? 'The max repetition cannot be greater than 10.'
                : undefined
          }
          isDisabled={isDisabled}
        />
      </SliderWrapper>
      <Checkbox
        isDisabled={isDisabled || step.maxRepetition === 0}
        isChecked={Boolean(step.flexible)}
        onChange={isFlexible =>
          handleUpdateFlexbility(isFlexible, step.maxRepetition)
        }
      >
        Enable Discover users to explore within maximum repetitions
      </Checkbox>
    </Wrapper>
  );
};

export default MaxRepetitionForm;
