import { GraphNode } from '@ardoq/graph';
import {
  EditablePathCollapsingRule,
  ViewStateEditablePathCollapsingRule,
  ViewStatePathCollapsingRule,
} from './types';
import { DirectedTriple } from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import {
  getNodeMetaDataFromGraphNodeId,
  getNodeMetaDataFromTypeName,
} from '../getNodeMetaData';
import { PathCollapsingRuleInternal } from 'viewpointBuilder/types';
import { collapsedPathToHash } from './collapsedPathToHash';

const getStartNodeFromPath = (
  path: DirectedTriple[],
  graphNodes: Map<string, GraphNode>
) => {
  if (!path.length) {
    return null;
  }
  const firstTriple = path[0];
  const startTypeFromPath =
    firstTriple.direction === 'outgoing'
      ? firstTriple.sourceType
      : firstTriple.targetType;
  return getNodeMetaDataFromTypeName(graphNodes, startTypeFromPath);
};

const getEndNodeFromPath = (
  path: DirectedTriple[],
  graphNodes: Map<string, GraphNode>
) => {
  if (!path.length) {
    return null;
  }
  const lastTriple = path[path.length - 1];
  const endTypeFromPath =
    lastTriple.direction === 'outgoing'
      ? lastTriple.targetType
      : lastTriple.sourceType;

  return getNodeMetaDataFromTypeName(graphNodes, endTypeFromPath);
};

export const transformCurrentEditableRule = (
  currentEditableRule: EditablePathCollapsingRule,
  graphNodes: Map<string, GraphNode>
): ViewStateEditablePathCollapsingRule => {
  const { path, startNodeId, displayText, errorMessage } = currentEditableRule;
  const startNode =
    getStartNodeFromPath(path, graphNodes) ??
    getNodeMetaDataFromGraphNodeId(graphNodes, startNodeId);
  const endNode = getEndNodeFromPath(path, graphNodes);
  return {
    ...currentEditableRule,
    startNode,
    endNode,
    isSavable: Boolean(path.length > 0 && displayText && !errorMessage),
  };
};

const getCollapsedPath = (
  path: DirectedTriple[],
  graphNodes: Map<string, GraphNode>
) =>
  path
    .map(triple => {
      const componentTypeMetaData = getNodeMetaDataFromTypeName(
        graphNodes,
        triple.direction === 'outgoing' ? triple.targetType : triple.sourceType
      );
      if (!componentTypeMetaData) {
        logError(Error('Could not find component type metadata for triple'));
        return null;
      }
      return {
        direction: triple.direction,
        referenceType:
          triple.referenceType === 'ardoq_parent'
            ? 'Is parent of'
            : triple.referenceType,
        componentTypeMetaData,
      };
    })
    .filter(ExcludeFalsy);

export const transformRules = (
  rules: PathCollapsingRuleInternal[],
  graphNodes: Map<string, GraphNode>
): ViewStatePathCollapsingRule[] =>
  rules.map(rule => {
    const { path, displayText, isExpanded, isActive } = rule;
    const hash = collapsedPathToHash(rule);
    return {
      hash,
      displayText,
      startNode: path ? getStartNodeFromPath(path, graphNodes) : null,
      endNode: path ? getEndNodeFromPath(path, graphNodes) : null,
      collapsedPath: path ? getCollapsedPath(path, graphNodes) : null,
      isExpanded,
      isActive,
    };
  });
