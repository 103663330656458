import { dispatchAction, ofType, routine } from '@ardoq/rxbeach';
import {
  fetchUsers,
  setSelectedUsers,
  setUpsertResult,
  setUsers,
  upsertUsers,
} from './actions';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { handleError, integrationNotificationApi } from '@ardoq/api';
import { scheduleFailureNotification$ } from './scheduleFailureNotification$';
import { byLocaleField } from 'integrations/common/utils/common';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { OrgAccessLevel } from '@ardoq/api-types';

const handleFetchUsers = routine(
  ofType(fetchUsers),
  switchMap(integrationNotificationApi.fetchUsers),
  handleError(({ error }) => {
    dispatchAction(setUsers({ status: 'FAILURE', message: error.message }));
  }),
  withLatestFrom(orgUsers$),
  tap(([resp, orgUsers]) => {
    const eligibleAdmins = orgUsers.users
      .filter(
        user => !user.serviceAccount && user.role === OrgAccessLevel.ADMIN
      )
      .map(user => user._id);

    dispatchAction(
      setUsers({
        status: 'SUCCESS',
        data: resp
          .filter(user => eligibleAdmins.includes(user.userId))
          .sort(byLocaleField('name')),
      })
    );
    dispatchAction(
      setSelectedUsers(
        resp.filter(user => user.isSubscribed).map(user => user.userId)
      )
    );
  })
);

const handleUpsertUsers = routine(
  ofType(upsertUsers),
  withLatestFrom(scheduleFailureNotification$),
  map(([, { users, selectedUsers }]) => {
    if (users.status !== 'SUCCESS') {
      return null;
    }
    return users.data.map(user => ({
      ...user,
      isSubscribed: selectedUsers.includes(user.userId),
    }));
  }),
  filter(selectedUsers => selectedUsers !== null),
  switchMap(integrationNotificationApi.postUser),
  handleError(({ error }) => {
    dispatchAction(setUsers({ status: 'FAILURE', message: error.message }));
  }),
  tap(selectedUsers => {
    dispatchAction(
      setUpsertResult({
        status: 'SUCCESS',
        data: selectedUsers,
      })
    );
  })
);

export const scheduleFailureNotificationRoutines = [
  handleFetchUsers,
  handleUpsertUsers,
];
