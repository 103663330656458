import { useMemo } from 'react';
import CollapsibleSection from 'viewpoints/CollapsibleSection';
import { QueryBuilderWithSuggestionsLoaders } from 'search/QueryBuilderWithSuggestionsLoaders';
import { SearchContext, getSearchContextRule } from '@ardoq/query-builder';
import {
  ArdoqId,
  BooleanOperator,
  QueryBuilderSubquery,
} from '@ardoq/api-types';
import styled from 'styled-components';
import { Select } from '@ardoq/select';
import { Label as LabelBase } from '@ardoq/forms';
import { colors, s16, s4, s8 } from '@ardoq/design-tokens';
import { normal16 } from '@ardoq/typography';
import { collectComponentFields, collectRelatedComponents } from './utils';
import { useEffectOnce } from '@ardoq/hooks';
import Components from 'collections/components';
import { composeViewpointComponentsFilterGroups } from './composeViewpointComponentsFilterGroups';
import { workspaceApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';

const Label = styled(LabelBase)`
  width: fit-content;
  display: block;
  > div {
    width: 80px;
    display: inline-block;
    margin: 0 ${s8};
  }
`;

const FakeInput = styled.div`
  border: 1px solid ${colors.grey80};
  border-radius: ${s4};
  ${normal16};
  padding: 6px ${s16};
  width: fit-content !important;
  margin-left: ${s4};
  margin-right: ${s4};
`;

const StyledSelect = styled(Select)`
  width: 80px;
  margin-left: ${s4};
  margin-right: ${s4};
`;

const setComponents = async (workspaceIds: ArdoqId[] | null) => {
  if (!workspaceIds?.length) return;
  const result = await workspaceApi.bulkLoadWorkspaceAggregated(workspaceIds);
  if (isArdoqError(result)) {
    logError(result);
    return;
  }
  const { components } = result;
  Components.collection.add(components, { silent: true, sort: false });
};

type ComponentsFilterProps = {
  workspaceIds: ArdoqId[] | null;
  componentQuery: QueryBuilderSubquery;
  componentTypeName: string;
  onComponentQueryDelete: (componentTypeName: string) => void;
  onComponentQueryChange: (subquery: QueryBuilderSubquery) => void;
};

const ComponentQueryBuilder = ({
  workspaceIds,
  componentQuery,
  componentTypeName,
  onComponentQueryDelete,
  onComponentQueryChange,
}: ComponentsFilterProps) => {
  useEffectOnce(() => {
    setComponents(workspaceIds);
  });

  const relatedFields = useMemo(() => {
    return collectComponentFields(componentQuery, workspaceIds);
  }, [componentQuery, workspaceIds]);

  const relatedComponents = useMemo(() => {
    return collectRelatedComponents(workspaceIds);
  }, [workspaceIds]);

  return (
    <CollapsibleSection
      title={componentTypeName}
      onDelete={() => onComponentQueryDelete(componentTypeName)}
      deleteTooltipText="Delete filter"
    >
      <Label>
        Search for <FakeInput>{componentTypeName}</FakeInput>
        that match{' '}
        <StyledSelect
          onChange={option => {
            if (!option) return;
            onComponentQueryChange({
              ...componentQuery,
              condition: option.value as BooleanOperator,
            });
          }}
          dataTestId="any-all-select"
          value={componentQuery.condition}
          options={[
            { label: 'all', value: BooleanOperator.AND },
            { label: 'any', value: BooleanOperator.OR },
          ]}
        />{' '}
        of the following rules:
      </Label>
      <QueryBuilderWithSuggestionsLoaders
        // The QueryBuilder component is not designed to be externally
        // controlled. Since we need a custom condition bar for the
        // viewpoint builder we need to force a rerender of QueryBuilder
        // when the condition changes.
        key={componentQuery.condition}
        maxSubqueryNestingLevel={0}
        onChange={({ query }) => {
          const rules = query.rules[1];
          onComponentQueryChange(rules);
        }}
        query={{
          condition: BooleanOperator.AND,
          rules: [
            getSearchContextRule(SearchContext.COMPONENT),
            componentQuery,
          ],
        }}
        getFilterGroups={() => {
          return composeViewpointComponentsFilterGroups({
            relatedFields,
            relatedComponents,
          });
        }}
        hideContainerStyles
        hideTopLevelConditionBar
      />
    </CollapsibleSection>
  );
};

export default ComponentQueryBuilder;
