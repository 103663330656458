import { getIn } from '../utils/collectionUtil';
import { ReactChild, Component } from 'react';
import Workspaces from 'collections/workspaces';
import searchService from 'search/searchAPIService';
import { AsyncSelect, SelectOption } from '@ardoq/select';
import { isUndefinedOrNull } from 'utils/collectionUtil';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { Workspace } from 'aqTypes';
import {
  ArdoqId,
  BooleanOperator,
  Operator,
  QueryBuilderRow,
  SearchResultResponse,
} from '@ardoq/api-types';
import workspace from 'models/workspace';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { Checkbox, FieldsWrapper } from '@ardoq/forms';

import { trackEvent } from 'tracking/tracking';
import { SurveyBuilderLocation } from './types';
import {
  SearchFieldNames,
  composeSearchQuery,
  composeSearchQueryFromSubQuery,
} from 'search/AdvancedSearch/utils';
import { logError } from '@ardoq/logging';

type NewComponentParentSelectorProps = {
  allowComponentCreation?: boolean;
  inReferenceQuestion?: boolean;
  newComponentParentId?: string | null;
  componentTypeName?: string | null;
  workspaceId?: string | null;
  description?: ReactChild;
  requiredNewComponentParentIsMissing?: boolean;
  selectedNewComponentParentDoesNotExist?: boolean;
  referenceParentIsMissing?: boolean;
  updateAllowComponentCreation: (value?: boolean) => void;
  updateNewComponentParentId: (value: ArdoqId | null) => void;
  surveyBuilderLocation: SurveyBuilderLocation;
  isDisabled?: boolean;
};

type NewComponentParentSelectorState = {
  selectedOption?: { value: string; label: string };
};

export default class NewComponentParentSelector extends Component<
  NewComponentParentSelectorProps,
  NewComponentParentSelectorState
> {
  workspace: Workspace | undefined;
  typeNameRules: QueryBuilderRow[];
  includeRoot: boolean;
  includeAll: boolean;
  constructor(props: NewComponentParentSelectorProps) {
    super(props);
    this.state = { selectedOption: undefined };
    this.getParentOptions = this.getParentOptions.bind(this);

    this.workspace = undefined;
    this.typeNameRules = [];
    this.includeRoot = true;
    this.includeAll = true;
    this.findEligibleComponentTypes();
  }

  componentDidMount() {
    this.setSelectedOption();
  }

  componentDidUpdate(prevProps: NewComponentParentSelectorProps) {
    if (prevProps.newComponentParentId !== this.props.newComponentParentId) {
      this.setSelectedOption();
    }
  }

  setSelectedOption() {
    if (!this.props.newComponentParentId) {
      this.setState({ selectedOption: { value: '', label: 'None' } });
      return;
    }
    searchService
      .componentSearch(
        composeSearchQuery({
          attributes: [
            {
              attribute: 'id',
              value: this.props.newComponentParentId,
            },
          ],
        })
      )
      .then(result => {
        if (isArdoqError(result)) {
          logError(result);
          return;
        }
        const { results } = result;
        if (!results.length) return;
        const {
          doc: { _id: value, name: label },
        } = results[0];
        this.setState({ selectedOption: { value, label } });
      });
  }

  findEligibleComponentTypes() {
    // Find the component types that could be the parent of
    // the selected component type name
    this.includeAll = true;
    const { componentTypeName, workspaceId } = this.props;
    this.workspace = Workspaces.collection.get(workspaceId ?? '');

    if (this.workspace && componentTypeName) {
      const model = this.workspace.getModel();
      if (model && !model.isFlexible()) {
        this.includeAll = false;
        const modelTypes = model.getAllTypes();
        const allowedComponentTypes = Object.keys(modelTypes).filter(typeId => {
          const type = modelTypes[typeId];
          return Object.keys(type.children).some(childTypeId => {
            const childType = modelTypes[childTypeId];
            return childType.name === componentTypeName;
          });
        });

        this.typeNameRules = allowedComponentTypes.map(typeId => {
          const type = modelTypes[typeId];
          return {
            id: 'typeName',
            field: 'typeName',
            type: 'string',
            input: 'text',
            operator: Operator.CONTAINS,
            value: type.name,
          };
        });

        const maybeType = Object.keys(modelTypes).find(
          typeId => modelTypes[typeId].name === componentTypeName
        );

        // check the level of the component type to know whether we allow the root (ws) as a parent
        const type = maybeType ? modelTypes[maybeType] : undefined;
        this.includeRoot = Boolean(type && type.level === 1);
      } else {
        // workspace or componentTypeName not selected
      }
    }
  }

  getParentOptions(
    input: string,
    callback: (options: SelectOption<string>[]) => void
  ) {
    const { newComponentParentId, workspaceId } = this.props;
    if (workspaceId) {
      if (
        !this.includeAll &&
        this.typeNameRules.length === 0 &&
        this.includeRoot
      ) {
        callback([{ value: '', label: 'None' }]);
      } else {
        let selectedItem: SearchResultResponse | null = null;
        if (input === '' && !isUndefinedOrNull(newComponentParentId)) {
          /* The following code is there because we cannot guarantee
            that the selected component is in the "initial search". This
            means we might end up with an empty selection even when a
            component is selected, since react-select can't find the name
            of the selected id.

            Hackily solved by doing a search on the id and then inserting
            the result into the other query.
            */
          searchService
            .componentSearch(
              composeSearchQuery({
                attributes: [
                  {
                    attribute: 'id',
                    value: newComponentParentId,
                  },
                ],
              })
            )
            .then(result => {
              if (isArdoqError(result)) {
                logError(result);
                callback([]);
                return;
              }
              selectedItem = result.results[0];
            });
        }

        const attributes = [
          {
            attribute: SearchFieldNames.ROOT_WORKSPACE,
            value: workspaceId,
          },
          input !== ''
            ? {
                attribute: SearchFieldNames.NAME_SUGGEST,
                value: input,
                operator: 'suggest' as Operator,
              }
            : null,
        ].filter(ExcludeFalsy);

        searchService
          .componentSearch(
            composeSearchQueryFromSubQuery({
              attributes,
              additionalConditions: this.typeNameRules.length
                ? {
                    condition: BooleanOperator.OR,
                    rules: this.typeNameRules,
                  }
                : null,
            })
          )
          .then(result => {
            if (isArdoqError(result)) {
              logError(result);
              callback([]);
              return;
            }
            const { results } = result;
            const locale = getCurrentLocale();

            if (
              selectedItem &&
              !results.some(result => result.doc._id === selectedItem?.doc._id)
            ) {
              results.push(selectedItem);
            }

            callback(
              [
                this.includeRoot && { value: '', label: 'None' },
                ...results
                  .map(result => ({
                    value: result.doc._id,
                    label: result.doc.name,
                  }))
                  .sort((a, b) => localeCompare(a.label, b.label, locale)),
              ].filter(ExcludeFalsy)
            );
          });
      }
    } else {
      callback([]);
    }
  }

  render() {
    const {
      allowComponentCreation,
      description,
      componentTypeName,
      workspaceId,
      inReferenceQuestion,
      requiredNewComponentParentIsMissing,
      selectedNewComponentParentDoesNotExist,
      referenceParentIsMissing,
      surveyBuilderLocation,
      isDisabled,
      updateAllowComponentCreation,
      updateNewComponentParentId,
    } = this.props;

    this.findEligibleComponentTypes();

    const typeHasNoParentAndModelIsRigid =
      this.typeNameRules && this.typeNameRules.length === 0 && !this.includeAll;
    const parentTypeName = getIn(this.typeNameRules, [0, 'value'], undefined);

    const hasError =
      requiredNewComponentParentIsMissing ||
      selectedNewComponentParentDoesNotExist ||
      referenceParentIsMissing;

    const missingParentErrorMessage = `Please select a parent component${
      parentTypeName ? ` of type  ${parentTypeName}` : ''
    }${!inReferenceQuestion ? ', or add a parent question.' : '.'}`;

    const selectedNewComponentParentDoesNotExistErrorMessage =
      'The selected parent component no longer exists. Please select another option.';

    const errorMessage =
      requiredNewComponentParentIsMissing || referenceParentIsMissing
        ? missingParentErrorMessage
        : selectedNewComponentParentDoesNotExistErrorMessage;

    return (
      <>
        <FieldsWrapper>
          <Checkbox
            label="Create components"
            isChecked={Boolean(allowComponentCreation)}
            hasError={hasError}
            onChange={() => {
              updateAllowComponentCreation(!allowComponentCreation);
              trackEvent('Survey builder: allow component creation', {
                from: surveyBuilderLocation,
                toggle: !allowComponentCreation,
              });
            }}
            popoverHelpContent={description}
            isDisabled={isDisabled}
          >
            Allow respondents to create components
          </Checkbox>
        </FieldsWrapper>
        {allowComponentCreation && (
          <FieldsWrapper>
            <AsyncSelect
              // Setting a unique key ensures that the Select generates new opts
              // every time the workspace and/or component settings are changed
              key={`${workspaceId}${componentTypeName}`}
              defaultOptions
              loadOptions={this.getParentOptions}
              isClearable={false}
              value={this.state.selectedOption}
              onValueChange={value =>
                value === ''
                  ? updateNewComponentParentId(null)
                  : updateNewComponentParentId(value as string | null)
              }
              isDisabled={
                !allowComponentCreation ||
                !workspace ||
                !componentTypeName ||
                typeHasNoParentAndModelIsRigid
              }
              isSearchable
              placeholder={
                selectedNewComponentParentDoesNotExist
                  ? 'Deleted component'
                  : 'None'
              }
              label="Select default parent of all created components"
              errorMessage={hasError ? errorMessage : undefined}
              dataTestId="new-component-parent-select"
            />
          </FieldsWrapper>
        )}
      </>
    );
  }
}
