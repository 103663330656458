import { TripleOptions } from 'viewpointBuilder/types';
import { ComponentDecoratorAsTag } from '@ardoq/decorators';

export type ComponentTypeProps = {
  representationData: TripleOptions['outgoing'][0]['metaDataComponent']['representationData'];
  label: string;
  isSelected?: boolean;
};

export const ComponentType = ({
  representationData,
  label,
  isSelected = false,
}: ComponentTypeProps) => {
  return (
    <ComponentDecoratorAsTag
      {...representationData}
      label={label}
      isSelected={isSelected}
    />
  );
};
