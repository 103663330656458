import { useState } from 'react';
import { Indentation } from 'components/EntityBrowser/atoms';
import { AssetRow } from 'components/AssetsBrowser/types';
import { FolderIcon, FolderName, RootLevelRow, FolderButton } from './atoms';
import {
  DatasourceTable,
  ExpandableTableProvider,
  FlyWheelTable,
  RowType,
} from '@ardoq/table';
import { withErrorBoundaries } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import { ArdoqId, SortOrder } from '@ardoq/api-types';
import { Row } from 'components/EntityBrowser/types';
import { sortDataSource } from 'components/EntityBrowser/utils';
import { colors } from '@ardoq/design-tokens';
import { Icon, IconName } from '@ardoq/icons';
import { Box } from '@ardoq/layout';

type FolderSelectProps = {
  selectedFolderId: null | ArdoqId | undefined;
  setSelectedFolderId: (id: null | ArdoqId) => void;
  dataSource: Row<AssetRow>[];
};

const FolderSelect = (props: FolderSelectProps) => {
  const { selectedFolderId, setSelectedFolderId } = props;
  const [expandedFoldersIds, setExpandedFoldersIds] = useState<string[]>([]);
  return (
    <div>
      <RootLevelRow
        isSelected={selectedFolderId === null}
        onClick={() => setSelectedFolderId(null)}
      />
      <ExpandableTableProvider
        idIndex="_id"
        dataSource={sortDataSource(props.dataSource, 'name', SortOrder.ASC)}
        setExpandedFoldersIds={setExpandedFoldersIds}
        expandedFoldersIds={expandedFoldersIds}
        render={({ dataSource, expandFolder }) => (
          <DatasourceTable
            hideHeader={true}
            components={FlyWheelTable}
            rowStyle={({ _id }) => ({
              backgroundColor:
                _id === selectedFolderId ? colors.surfaceActive : 'inherit',
            })}
            columns={[
              {
                dataIndex: 'name',
                valueRender: (name, { _id, rowType, meta }) => (
                  <FolderButton
                    tabIndex={rowType === RowType.EMPTY_FOLDER ? -1 : 0}
                    onClick={() => {
                      if (rowType !== RowType.EMPTY_FOLDER) {
                        setSelectedFolderId(_id);
                        expandFolder(_id);
                      }
                    }}
                  >
                    <Box display="inline-block" paddingRight="small">
                      <Icon
                        iconName={IconName.CHECK_MARK}
                        color={
                          selectedFolderId === _id
                            ? colors.iconActionActive
                            : colors.transparent0
                        }
                      />
                    </Box>
                    <Indentation level={meta.level} />
                    {rowType === RowType.EMPTY_FOLDER ? (
                      <i style={{ color: colors.grey80 }}>
                        This folder has no subfolders
                      </i>
                    ) : (
                      <>
                        <FolderIcon
                          isOpened={expandedFoldersIds.includes(_id)}
                        />
                        <FolderName>{name}</FolderName>
                      </>
                    )}
                  </FolderButton>
                ),
              },
            ]}
            dataSource={dataSource}
          />
        )}
      />
    </div>
  );
};

export default withErrorBoundaries({
  errorContextDescription: 'Error at <FolderSelect />',
  logError,
})(FolderSelect);
