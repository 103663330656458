import { ArdoqId } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { dispatchAction } from '@ardoq/rxbeach';
import { confirmMoveAssets } from '../../appContainer/DashboardContainer/confirmMoveAssets';
import {
  moveAssetsToFolder,
  updateNamePromise,
} from '../../appContainer/DashboardContainer/utils';
import { setPreviewId } from '../DocumentBrowser/actions';
import { Row } from '../EntityBrowser/types';
import { setIsUpdating, setRenameId } from './assetsBrowser2024Actions';
import { GetMenuOptionProps, getDefaultMenuOptions } from './menuOptions';
import { AssetsBrowser2024StreamShape } from './assetsBrowser2024Types';
import { AssetRow } from '../AssetsBrowser/types';
import { mapIdsToAssets } from './assetsBrowser2024Utils';
import { AppModules } from '../../appContainer/types';
import {
  assetsBrowser2024Commands,
  AssetsBrowser2024Commands,
} from './assetsBrowser2024Commands';
import { viewpointPreviewCommands } from 'components/TraversalPreview/viewpointPreviewCommands';
import { RowType } from '@ardoq/table';
import { isArdoqError } from '@ardoq/common-helpers';
import { trackEvent } from 'tracking/tracking';

export const onTableClick = (
  e: React.MouseEvent,
  row: Row<AssetRow> | null,
  openContextMenu: (e: React.MouseEvent, options: any) => void,
  getMenuOptionsProps: GetMenuOptionProps
) => {
  if (!row || row.rowType === RowType.EMPTY_FOLDER) {
    return;
  }
  e.preventDefault();
  const menuOptions = getDefaultMenuOptions(getMenuOptionsProps);
  if (e.type === 'contextmenu' && menuOptions.length) {
    setPreviewId(row._id);
    openContextMenu(e, {
      options: menuOptions,
      onClose: () => dispatchAction(setPreviewId(null)),
    });
  }
};

export const onAssetClick = async (
  row: AssetRow,
  commands: AssetsBrowser2024Commands
) => {
  if (row.rowType === RowType.WORKSPACE) {
    commands.openWorkspace(row._id);
    return;
  }
  if (row.rowType === RowType.MANAGED_WORKSPACE) {
    commands.openManagedWorkspace(row._id, row.name);
    return;
  }
  if (row.rowType === RowType.PRESENTATION) {
    commands.openPresentationEditor(row._id);
    return;
  } else if (row.rowType === RowType.SURVEY) {
    if (row.meta.permissions.canUpdate) {
      commands.openSurveyEditor(row._id);
    }
    return;
  }
  if (row.rowType === RowType.METAMODEL) {
    commands.openMetaModel(row._id);
    return;
  }
  if (row.rowType === RowType.SCENARIO) {
    commands.openScenario(row._id);
    return;
  }
  if (row.rowType === RowType.REPORT) {
    commands.openReport(row._id);
    return;
  }
  if (row.rowType === RowType.DASHBOARD) {
    commands.openDashboard(row._id);
    return;
  }
  if (row.rowType === RowType.TRAVERSAL) {
    commands.openTraversal(row._id);
    return;
  }
  if (row.rowType === RowType.BOOKMARK) {
    commands.openBookmark(row._id);
    return;
  }
  if (row.rowType === RowType.VIEWPOINT) {
    commands.openDiscoverViewpoint(row._id);
    return;
  }
  if (row.rowType === RowType.BROADCAST) {
    commands.openBroadcast(row._id, row.status);
    return;
  }

  row satisfies never;
};

export const onAssetPreviewClick = (row: AssetRow, appModule: AppModules) => {
  if (row.rowType === RowType.TRAVERSAL) {
    viewpointPreviewCommands.openTraversalPreview({
      traversalId: row._id,
      isFavorite: row.meta.favorite,
      trackingFn: () => trackEvent(`${appModule}: clicked preview viewpoint`),
    });
    return;
  }
};

export const onAssetOpenInViewpointModeClick = (row: AssetRow) => {
  if (row.rowType === RowType.WORKSPACE) {
    assetsBrowser2024Commands.openWorkspacesInViewpointMode(row._id);
  }
};

export const onAssetsMove = async (
  assetsIds: ArdoqId[],
  folderId: string | null,
  assetsById: AssetsBrowser2024StreamShape['assetsById']
) => {
  const isConfirmed = await confirmMoveAssets({
    assetsIds,
    folderId,
    assetsById,
  });
  if (!isConfirmed) return;

  dispatchAction(setIsUpdating(true));
  await moveAssetsToFolder(mapIdsToAssets(assetsIds, assetsById), folderId);
  dispatchAction(setIsUpdating(false));
};

export const confirmRename = async (
  newName: string,
  row: Row<AssetRow>,
  setHighlights: (ids: string[]) => void,
  appModule: AppModules
) => {
  dispatchAction(setIsUpdating(true));
  const result = updateNamePromise(newName, row);
  if (isArdoqError(result)) {
    logError(result, 'Renaming item failed');
    alert({
      title: 'Error',
      subtitle: 'Sorry, name update failed',
    });
  }
  if (row.rowType !== RowType.EMPTY_FOLDER) {
    setHighlights([row._id]);
  }
  dispatchAction(setRenameId({ payload: null, appModule }));
  dispatchAction(setIsUpdating(false));
};
