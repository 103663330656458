import { AppliedSettingsRow } from './AppliedSettingsRow';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { Space } from '@ardoq/style-helpers';
import { Stack, FlexBox } from '@ardoq/layout';
import { Tag } from '@ardoq/status-ui';
import { WithPopover } from '@ardoq/popovers';
import { colors } from '@ardoq/design-tokens';
import styled from 'styled-components';
import {
  RuleDescription,
  RuleDescriptionsByEntity,
  FormattingSetting,
} from './types';

const ColorSwatch = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;

type InfoBlockProps = {
  entityName: string;
  iconName: IconName;
  ruleDescriptions: RuleDescription[];
};
const InfoBlock = ({
  entityName,
  iconName,
  ruleDescriptions,
}: InfoBlockProps) => (
  <>
    <Space $gap="s4" $align="center">
      <Icon
        iconName={iconName}
        iconSize={IconSize.SMALL}
        color={colors.grey50}
        // Passing inline style for the Tag SVG icon
        style={{ color: colors.grey50 }}
      />
      {entityName}
    </Space>
    <FlexBox flexDirection="column" gap="small" paddingLeft="medium">
      {ruleDescriptions.map(({ color, filterDescription }) => (
        <Space $gap="s4" key={filterDescription} $align="center">
          <ColorSwatch style={{ backgroundColor: color }} />
          {filterDescription}
        </Space>
      ))}
    </FlexBox>
  </>
);

const PopoverContent = ({
  components,
  references,
  tags,
}: RuleDescriptionsByEntity) => (
  <Stack gap="small">
    {components && (
      <InfoBlock
        entityName="Component"
        iconName={IconName.COMPONENT}
        ruleDescriptions={components}
      />
    )}
    {references && (
      <InfoBlock
        entityName="Reference"
        iconName={IconName.REFERENCE}
        ruleDescriptions={references}
      />
    )}
    {tags && (
      <InfoBlock
        entityName="Tag"
        iconName={IconName.TAG}
        ruleDescriptions={tags}
      />
    )}
  </Stack>
);

type FormattingSettingsProps = {
  settings: FormattingSetting;
};
export const FormattingSettings = ({ settings }: FormattingSettingsProps) => {
  const { appliedRulesCount, ruleDescriptionsByEntity, onEditClick } = settings;

  const popoverContent = appliedRulesCount
    ? PopoverContent(ruleDescriptionsByEntity)
    : null;

  const tag = (
    <WithPopover content={() => popoverContent} key="FormattingTag">
      <Tag label={appliedRulesCount.toString()} />
    </WithPopover>
  );

  return (
    <AppliedSettingsRow
      label="Formats"
      tags={[tag]}
      icon={IconName.PALETTE}
      onEditClick={onEditClick}
      isDisabled={!appliedRulesCount}
    />
  );
};
