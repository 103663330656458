import { colors } from '@ardoq/design-tokens';
import { ComponentWithDropdown } from '@ardoq/dropdown-menu';
import styled from 'styled-components';
import {
  NavigatorTopBarStreamShape,
  SortOption,
} from '../NavigatorTopBar/types';
import { mapSortOptionToMenuOption } from '../NavigatorTopBar/NavigatorTopBarSortMenu';
import { viewpointSidebarCommands } from './viewpointSidebarCommands';
import { getScenarioDropdownOptions } from '../NavigatorTopBar/NavigatorTopBarScenarioMenu';
import {
  ViewpointSidebarScenariosButton,
  ViewpointSidebarSortButton,
} from './viewpointSidebarDropdownButtons';
import { FlexBox } from '@ardoq/layout';

type ViewpointSidebarSortAndScenariosProperties = Pick<
  NavigatorTopBarStreamShape,
  | 'scenariosEnabled'
  | 'selectedComponentIds'
  | 'viewComponentIds'
  | 'loadedGraph'
  | 'permissionContext'
> & {
  sortOptions?: SortOption[];
};

const ViewpointSidebarSortAndScenarios = ({
  scenariosEnabled,
  selectedComponentIds,
  viewComponentIds,
  sortOptions,
  loadedGraph,
  permissionContext,
}: ViewpointSidebarSortAndScenariosProperties) => {
  return (
    <FlexBox
      justify="space-between"
      align="center"
      paddingX="medium"
      paddingBottom="small"
    >
      {sortOptions ? (
        <ComponentWithDropdown
          Component={ViewpointSidebarSortButton}
          options={sortOptions.map(sortOption =>
            mapSortOptionToMenuOption(
              sortOption,
              viewpointSidebarCommands.setSorting
            )
          )}
        />
      ) : (
        <SortInfoSpan>Newest at top</SortInfoSpan>
      )}
      {scenariosEnabled && (
        <ComponentWithDropdown
          Component={ViewpointSidebarScenariosButton}
          options={getScenarioDropdownOptions({
            viewComponentIds,
            selectedComponentIds,
            loadedGraph,
            permissionContext,
          })}
        />
      )}
    </FlexBox>
  );
};

const SortInfoSpan = styled.span`
  color: ${colors.grey50};
`;

export default ViewpointSidebarSortAndScenarios;
