import { dispatchAction } from '@ardoq/rxbeach';
import {
  dataOverviewTypeSelected,
  numberOfRowsPerPageSelected,
  dataOverviewSearchValueChanged,
  dataOverviewStatusChanged,
  dataOverviewSelectedItemsCleared,
  dataOverviewSelectedItemToggled,
} from './actions';
import { DataOverviewTypes, DataOverviewStatus } from './types';
import { inventoryModuleWasSelected } from 'router/navigationActions';
import { InventoryModule } from 'inventory/consts';
import { ArdoqId } from '@ardoq/api-types';
import { inventoryTracking } from '../inventoryTracking';

export type DataOverviewCommands = {
  selectDataOverviewType: (dataOverviewType: DataOverviewTypes) => void;
  selectNumberOfRowsPerPage: (numberOfRowsPerPage: number) => void;
  setSearchValue: (searchValue: string) => void;
  setStatus: (status: DataOverviewStatus) => void;
  toggleSelectedItem: (itemId: ArdoqId) => void;
  clearSelectedItems: () => void;
  openItemsInInventory: (
    dataOverviewType: DataOverviewTypes,
    items: ArdoqId[] | string[]
  ) => void;
};

export const dataOverviewCommands: DataOverviewCommands = {
  selectDataOverviewType: dataOverviewType => {
    dispatchAction(dataOverviewSelectedItemsCleared());
    dispatchAction(dataOverviewTypeSelected(dataOverviewType));
    inventoryTracking.trackSelectTypeInDataOverview(dataOverviewType);
  },
  selectNumberOfRowsPerPage: numberOfRowsPerPage => {
    dispatchAction(numberOfRowsPerPageSelected(numberOfRowsPerPage));
  },
  setSearchValue: searchValue => {
    dispatchAction(dataOverviewSearchValueChanged(searchValue));
    if (searchValue === '') {
      // Setting search to empty string happens when a user clears the input
      inventoryTracking.trackClearInSearchInputInDataOverview();
    }
  },
  setStatus: status => {
    dispatchAction(dataOverviewStatusChanged(status));
  },
  toggleSelectedItem: itemId => {
    dispatchAction(dataOverviewSelectedItemToggled(itemId));
  },
  clearSelectedItems: () => {
    dispatchAction(dataOverviewSelectedItemsCleared());
  },
  openItemsInInventory: (dataOverviewType, selectedItems) => {
    dispatchAction(
      inventoryModuleWasSelected(
        dataOverviewType === DataOverviewTypes.WORKSPACE
          ? {
              inventoryModule: InventoryModule.BROWSE,
              selectedWorkspaceIds: selectedItems,
              dataSourceType: 'workspace',
            }
          : {
              inventoryModule: InventoryModule.BROWSE,
              componentTypeNames: selectedItems,
              dataSourceType: 'componentType',
            }
      )
    );
    inventoryTracking.trackOpenItemsInInventory({
      dataOverviewType,
      numberOfItems: selectedItems.length,
    });
  },
};
