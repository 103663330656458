import SplashScreen from './SplashScreen';
import { ArdoqLoaderProps } from './types';

const ArdoqLoader = ({
  isLoading = false,
  ...splashScreenProps
}: ArdoqLoaderProps) =>
  isLoading ? <SplashScreen {...splashScreenProps} /> : null;

export default ArdoqLoader;
