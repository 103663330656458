import { persistentReducedStream } from '@ardoq/rxbeach';
import { getDefaultState } from './utils';
import { reducers } from './reducers';

const workspaceAudienceDialog$ = persistentReducedStream(
  'workspaceAudienceDialog$',
  getDefaultState(),
  reducers
);

export default workspaceAudienceDialog$;
