import { actionCreator } from '@ardoq/rxbeach';
import type {
  LucidChartPickerTokenResponse,
  OAuthAuthorizeResponse,
  OAuthProvider,
} from '@ardoq/api-types/integrations';

type StartOAuthFlow = {
  provider: OAuthProvider;
  onCompleted: VoidFunction;
  operation: string;
};

export const startOAuthFlow = actionCreator<StartOAuthFlow>(
  '[OAuth] START_OAUTH_FLOW'
);

export const authorizeOAuthInfoSuccess = actionCreator<OAuthAuthorizeResponse>(
  '[OAuth] AUTHORIZE_OAUTH_INFO_SUCCESS'
);

export const completedOAuthFlow = actionCreator<OAuthProvider>(
  '[OAuth] COMPLETED_OAUTH_FLOW'
);

export const fetchDocumentPickerToken =
  actionCreator<LucidChartPickerTokenResponse>(
    '[OAuth] FETCH_DOCUMENT_PICKER_TOKEN'
  );
