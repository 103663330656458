import { dispatchAction } from '@ardoq/rxbeach';
import { updateFeatureValue } from './actions';
import {
  FeatureSettingsCommands,
  UpdateFeatureValueActionPayload,
} from './types';

const setFeatureValue = (data: UpdateFeatureValueActionPayload) => {
  dispatchAction(updateFeatureValue(data));
};

export const featureSettingsCommands: FeatureSettingsCommands = {
  setFeatureValue,
};
