import { awsIntegrationApi, getErrorStatusCode } from '@ardoq/api';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import { ApiError } from 'integrations/common/utils/api';

export async function fetchVpcs(
  connectionId: string,
  requestId: string,
  async: boolean = true,
  region: string
) {
  const vpcs = await awsIntegrationApi.fetchVpcs({
    connectionId,
    requestId,
    async,
    regions: [region],
  });

  if (isArdoqError(vpcs)) {
    throw new ApiError(
      `Unable to fetch vpcs for connection: ${connectionId}`,
      getErrorStatusCode(vpcs),
      getArdoqErrorMessage(vpcs)
    );
  }

  return { vpcs, async, connectionId, region };
}
