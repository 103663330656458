import { useEffect } from 'react';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import BodyHeader from 'atomicComponents/Layout/BodyHeader';
import { CodeEditor, EditorLanguage } from '@ardoq/code-editor';
import {
  deleteQuery,
  saveSearchQuery,
  updateSearchQuery,
} from 'search/actions';
import { SearchType } from '@ardoq/api-types';
import { SearchTabContainer } from 'search/QueryEditor/atoms';
import QueryActions from 'search/QueryEditor/QueryActions';
import {
  calculatedFieldQueryEditor$,
  QueryEditorNamespace,
  QueryEditorStateShape,
} from 'search/QueryEditor/queryEditor$';
import GremlinResults from '../Gremlin/GremlinResults/GremlinResults';
import CalculatedFieldOptions from './CalculatedFieldOptions';
import { calculatedFieldQueryResults$ } from 'search/Gremlin/gremlinResults$';
import calculatedFieldOptions$, {
  CalculatedFieldOptionsState,
} from 'streams/queries/calculatedFieldOptions/calculatedFieldOptions$';
import { CalculatedFieldOption } from 'streams/queries/calculatedFieldOptions/types';
import { GremlinSearchStateShape } from 'search/Gremlin/types';
import GremlinSearchError from 'search/Gremlin/GremlinResults/GremlinSearchError';
import { usePaginationGremlinSearch } from 'search/Gremlin/usePaginationGremlinSearch';
import GremlinSearchWarning from 'search/Gremlin/GremlinResults/GremlinSearchWarning';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { SecondaryButton } from '@ardoq/button';
import { getFieldIdsOfQuery } from 'streams/queries/calculatedFieldOptions/helpers';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { confirm } from '@ardoq/modal';
import { Box } from '@ardoq/layout';

const getShouldClose = () =>
  confirm({
    title: 'Unsaved changes',
    subtitle: 'Are you sure you want to close this window?',
    confirmButtonTitle: 'Yes',
    isConfirmButtonDanger: true,
  });

type ViewProps = QueryEditorStateShape &
  GremlinSearchStateShape & {
    calculatedFieldOptions: CalculatedFieldOption[];
    setIsEditMode?: (isEditMode: boolean) => void;
    setIsDirty?: (isDirty: boolean) => void;
    shouldHideBackButton: boolean;
    hasInvalidIds: boolean;
  };

const CalculatedSearch = ({
  isSearching,
  model,
  results,
  hasChanges,
  showRawResults,
  syntaxError,
  searchError,
  searchWarning,
  queryParams,
  selectedQueryId,
  calculatedFieldOptions,
  calculatedFieldSelectedOption,
  totalResults,
  setIsEditMode,
  setIsDirty,
  shouldHideBackButton,
  hasInvalidIds,
}: ViewProps) => {
  const { resultsId, loadPaginatedResults, doPaginatedSearch } =
    usePaginationGremlinSearch({
      model,
      queryParams,
      searchType: SearchType.CALCULATED_FIELD_QUERY,
    });

  useEffect(() => {
    setIsDirty?.(hasChanges);
  }, [hasChanges, setIsDirty]);

  const fieldId = selectedQueryId && getFieldIdsOfQuery(selectedQueryId)?.[0];
  const fieldLabel =
    (fieldId && fieldInterface.getAttributes(fieldId, ['label'])?.label) ?? '';
  return (
    <>
      <BodyHeader
        style={{ padding: '32px 32px 0' }}
        title={`Calculation for field: ${fieldLabel}`}
      >
        {!shouldHideBackButton && (
          <SecondaryButton
            onClick={async () => {
              if (!hasChanges || (await getShouldClose()))
                setIsEditMode?.(false);
            }}
          >
            Back to overview
          </SecondaryButton>
        )}
      </BodyHeader>
      <SearchTabContainer>
        <CalculatedFieldOptions
          calculatedFieldOptions={calculatedFieldOptions}
          queryParamsIds={(queryParams?.ids as string[]) ?? []}
          calculatedFieldSelectedOption={calculatedFieldSelectedOption}
        />
        <CodeEditor
          language={EditorLanguage.GROOVY}
          value={model.query as string}
          onChange={(query: string) => {
            setIsDirty?.(true);
            dispatchAction(
              updateSearchQuery({
                query,
              }),
              QueryEditorNamespace.CALCULATED_FIELD_QUERY
            );
          }}
          onCtrlCmdEnter={doPaginatedSearch}
        />
        <QueryActions
          searchButtonText="Test calculation"
          hasChanges={hasChanges}
          doSearch={doPaginatedSearch}
          applySearch={() => {
            setIsDirty?.(false);
            dispatchAction(
              saveSearchQuery({
                model,
                queryId: selectedQueryId,
              }),
              QueryEditorNamespace.CALCULATED_FIELD_QUERY
            );
          }}
          deleteQuery={() =>
            dispatchAction(
              deleteQuery({
                queryId: selectedQueryId!,
              }),
              QueryEditorNamespace.CALCULATED_FIELD_QUERY
            )
          }
          showSpinner={isSearching}
          isRedundantQuery={false}
          isDisabled={hasInvalidIds}
        />
        {hasInvalidIds && (
          <Box marginTop="xlarge">
            <GremlinSearchWarning
              searchWarning={
                'Make sure the field is applied to a component or a reference type'
              }
            />
          </Box>
        )}
        <Box marginTop="xlarge">
          {searchError && <GremlinSearchError syntaxError={syntaxError} />}
          {searchWarning && (
            <GremlinSearchWarning searchWarning={searchWarning} />
          )}
          {results && (
            <GremlinResults
              result={results}
              showRaw={showRawResults}
              totalResults={totalResults}
              resultsId={resultsId}
              loadResults={loadPaginatedResults}
            />
          )}
        </Box>
      </SearchTabContainer>
    </>
  );
};

const mapStreamsToViewProps = ([
  queryEditor,
  gremlinResults,
  calculatedFieldOptions,
]: [
  QueryEditorStateShape,
  GremlinSearchStateShape,
  CalculatedFieldOptionsState,
]): Omit<
  ViewProps,
  'setIsEditMode' | 'setIsDirty' | 'shouldHideBackButton'
> => ({
  ...queryEditor,
  ...gremlinResults,
  calculatedFieldOptions,
  hasInvalidIds: queryEditor.queryParams.ids?.length === 0,
  calculatedFieldSelectedOption:
    calculatedFieldOptions.length === 1
      ? calculatedFieldOptions[0].value
      : queryEditor.calculatedFieldSelectedOption,
});

export default connect(
  CalculatedSearch,
  combineLatest([
    calculatedFieldQueryEditor$,
    calculatedFieldQueryResults$,
    calculatedFieldOptions$,
  ]).pipe(map(mapStreamsToViewProps))
);
