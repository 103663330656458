import { DetailsColumn } from './atoms';
import { UserProfilePageProps } from '../../../types';
import { PrivilegesList } from './PrivilegesList';
import { privilegesOperations } from 'privileges/privilegesOperations';
import { LabeledDetail } from 'admin/accessControl/UserProfilePage/components/LabeledDetail';
import { FlexBox } from '@ardoq/layout';

export const UserRelatedPrivileges = ({
  userPrivileges,
}: UserProfilePageProps) => {
  const userBasedPrivileges =
    privilegesOperations.getUserBasedPrivileges(userPrivileges);
  return (
    <DetailsColumn $flexGrow={5.3}>
      <FlexBox width="full">
        <LabeledDetail label="User privileges">
          <PrivilegesList
            privileges={userBasedPrivileges}
            emptyStateText="No individual privileges assigned to this user."
            context="user-privileges"
          />
        </LabeledDetail>
      </FlexBox>
    </DetailsColumn>
  );
};
