import { s24 } from '@ardoq/design-tokens';
import { Box } from '@ardoq/layout';
import styled from 'styled-components';

type SurveyErrorOrWarningPopoverProps = {
  errorMessages: string[];
  warningMessages: string[];
};

const ListContainer = styled.ul`
  padding-inline-start: ${s24};
`;

export const SurveyErrorOrWarningPopover = ({
  errorMessages,
  warningMessages,
}: SurveyErrorOrWarningPopoverProps) =>
  errorMessages.length || warningMessages.length ? (
    <Box maxWidth="500px">
      {errorMessages.length > 0 && (
        <>
          <p>Errors found:</p>
          <ListContainer>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ListContainer>
        </>
      )}
      {warningMessages.length > 0 && (
        <>
          <p>Warnings found:</p>
          <ListContainer>
            {warningMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ListContainer>
        </>
      )}
    </Box>
  ) : null;
