import Expandable from '../../Expandable';
import FormattedIcon from '../../FormattedIcon';
import { AudienceStatus } from '../../types';
import { getIconName } from '../../utils';
import PersonRenderer from '../../audienceByPerson/cellRenderers/PersonRenderer';
import { FlexBox } from '@ardoq/layout';

type PersonCellProps = {
  isChildRow: boolean;
  isExpandable: boolean;
  isExpanded: boolean;
  toggleExpand: VoidFunction;
  personLabel: string | null;
  personSublabel: string | null;
  audienceStatus: AudienceStatus;
  highlight?: string;
};

type ExpandablePersonCellProps = Pick<
  PersonCellProps,
  'isExpanded' | 'toggleExpand' | 'personLabel'
>;

type UnexpandablePersonCellProps = Omit<
  PersonCellProps,
  'isExpandable' | 'isExpanded' | 'toggleExpand'
>;

const ExpandablePersonCell = ({
  isExpanded,
  toggleExpand,
  personLabel,
}: ExpandablePersonCellProps) => {
  return (
    <Expandable isExpanded={isExpanded} toggleExpand={toggleExpand}>
      {personLabel}
    </Expandable>
  );
};

const UnexpandablePersonCell = ({
  audienceStatus,
  personLabel,
  personSublabel,
  highlight,
}: UnexpandablePersonCellProps) => {
  return (
    <FlexBox gap="xsmall" align="center">
      <FormattedIcon iconName={getIconName(audienceStatus)} />
      <PersonRenderer
        personLabel={personLabel}
        personSublabel={personSublabel}
        highlight={highlight}
      />
    </FlexBox>
  );
};

const PersonCell = ({
  isChildRow,
  isExpandable,
  isExpanded,
  toggleExpand,
  personLabel,
  personSublabel,
  audienceStatus,
  highlight,
}: PersonCellProps) => {
  if (isExpandable) {
    return (
      <ExpandablePersonCell
        isExpanded={isExpanded}
        toggleExpand={toggleExpand}
        personLabel={personLabel}
      />
    );
  }
  return (
    <UnexpandablePersonCell
      isChildRow={isChildRow}
      audienceStatus={audienceStatus}
      personLabel={personLabel}
      personSublabel={personSublabel}
      highlight={highlight}
    />
  );
};

export default PersonCell;
