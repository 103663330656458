import { ComponentTypeRelatedFieldsAndTriples } from '@ardoq/api-types';
import { GhostButton } from '@ardoq/button';
import { colors } from '@ardoq/design-tokens';
import { AnimatedChevron } from '@ardoq/icons';
import { StatusType, Tag } from '@ardoq/status-ui';
import { caption, header3, text2 } from '@ardoq/typography';
import { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { TripleDecorator } from 'useCases/UseCaseSetup/TripleDecorator';
import { isJust } from '@ardoq/common-helpers';
import { MetaModelSidebarProps } from './types';
import { pluralize } from '@ardoq/common-helpers';
import { Box, FlexBox, Stack } from '@ardoq/layout';

const SidebarTitle = styled.span`
  ${header3}
`;

const SidebarSubtitle = styled.span`
  ${caption}
`;

const HeaderContainer = styled(Box)`
  position: sticky;
  top: 0;
  border-bottom: 1px solid ${colors.grey80};
`;

const Title = styled.div`
  ${text2}
  color: ${colors.grey35}
`;

const Summary = styled.span`
  ${text2}
  color: ${colors.grey35};
`;

const SidebarContainer = styled(Box)`
  overflow-y: scroll;
`;

const FullHeightStack = styled(Stack)`
  height: 100%;
`;

const SidebarHeader = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => (
  <HeaderContainer padding="medium">
    <Stack gap="medium">
      <SidebarTitle>{title}</SidebarTitle>
      <SidebarSubtitle>{subtitle}</SidebarSubtitle>
    </Stack>
  </HeaderContainer>
);

const SectionContainer = styled(Box)`
  border: 1px solid ${colors.grey90};
  border-radius: 6px;
  background: ${colors.grey95};
`;

const CollapsibleContainer = ({
  children,
  title,
  summaryWhenCollapsed,
}: PropsWithChildren<{ title: string; summaryWhenCollapsed: string }>) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <SectionContainer padding="medium">
      <FlexBox justify="space-between">
        <Title>{title.toUpperCase()}</Title>
        <GhostButton
          onClick={toggleExpand}
          data-tooltip-text={isExpanded ? 'Collapse' : 'Expand'}
        >
          <AnimatedChevron $isUp={isExpanded} />
        </GhostButton>
      </FlexBox>
      {isExpanded ? children : <Summary>{summaryWhenCollapsed}</Summary>}
    </SectionContainer>
  );
};

const ComponentTypeFieldsAndReferencesSection = ({
  componentTypeData,
}: {
  componentTypeData: ComponentTypeRelatedFieldsAndTriples;
}) => {
  const { label, fields, metamodelTriples } = componentTypeData;
  const fieldsSummary = fields.length
    ? `${fields.length} ${pluralize('field', fields.length)}`
    : null;

  const referencesSummary = metamodelTriples.length
    ? `${metamodelTriples.length} ${pluralize(
        'reference',
        metamodelTriples.length
      )}`
    : null;

  const summary = [fieldsSummary, referencesSummary].filter(isJust).join(', ');

  return (
    <CollapsibleContainer
      title={label.toUpperCase()}
      summaryWhenCollapsed={summary}
    >
      <Stack gap="large">
        {!isEmpty(fields) && (
          <Stack gap="small">
            <Title>{label} fields</Title>
            <FlexBox flexWrap gap="xsmall">
              {fields.map(field => (
                <Tag key={field} label={field} statusType={StatusType.INFO} />
              ))}
            </FlexBox>
          </Stack>
        )}

        {!isEmpty(metamodelTriples) && (
          <Stack gap="small">
            <Title>{label} references</Title>
            <Box>
              <Stack gap="medium">
                {metamodelTriples.map(tripleData => (
                  <TripleDecorator
                    key={tripleData.triple.join('-')}
                    {...tripleData}
                  />
                ))}
              </Stack>
            </Box>
          </Stack>
        )}
      </Stack>
    </CollapsibleContainer>
  );
};

export const MetaModelSidebar = ({ metaModelData }: MetaModelSidebarProps) => (
  <FullHeightStack>
    <SidebarHeader
      title="Fields and references"
      subtitle="These are the fields and references in this Metamodel."
    />

    <SidebarContainer padding="medium">
      <Stack gap="small">
        {metaModelData.map(componentTypeData => (
          <ComponentTypeFieldsAndReferencesSection
            key={componentTypeData.label}
            componentTypeData={componentTypeData}
          />
        ))}
      </Stack>
    </SidebarContainer>
  </FullHeightStack>
);
