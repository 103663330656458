import ReactDOMServer from 'react-dom/server';
import { ASSETS_AND_TEST_CONTAINER_ID } from '@ardoq/icons';
import { createSvgElement } from '@ardoq/dom-utils';

export const getSvgTestDocument = (() => {
  let testDocument: SVGElement | null = null;
  return () => {
    if (!testDocument) {
      const svg = createSvgElement('svg');
      const container = document.getElementById(ASSETS_AND_TEST_CONTAINER_ID);
      container?.appendChild(svg);
      testDocument = svg;
    }
    return testDocument;
  };
})();

export const svgToDataUri = (element: React.ReactElement) => {
  const markup = ReactDOMServer.renderToString(element);
  const encodedMarkup = encodeURIComponent(markup);
  return `"data:image/svg+xml,${encodedMarkup}"`;
};
