import {
  APIComponentTypeAttributes,
  APIDashboardAttributes,
  APIReferenceTypeAttributes,
  ArdoqId,
  PDFLink,
  PersistedEntityGroupShape,
  PersistedUseCase,
  Triple,
} from '@ardoq/api-types';
import { Locale } from '@ardoq/locale';
import { SelectOption } from '@ardoq/select';

export interface UseCaseManagementStreamState {
  selectedTab: UseCaseTabs;
  useCasesData: PersistedUseCase[];
  entityGroups: {
    data: PersistedEntityGroupShape[];
    selectOptions: SelectOption<string>[];
  };
  keyMetricsDashboards: {
    data: APIDashboardAttributes[];
    selectOptions: SelectOption<string>[];
  };
  componentTypes: {
    data: APIComponentTypeAttributes[];
    selectOptions: SelectOption<string>[];
    componentTypesFromEntityGroup: APIComponentTypeAttributes[];
  };
  referenceTypes: {
    data: APIReferenceTypeAttributes[];
    selectOptions: SelectOption<string>[];
    referenceTypesFromEntityGroup: APIReferenceTypeAttributes[];
  };
  error: string | null;
  isLoading: boolean;
  userLocale: Locale;
}

export type UseCaseOverviewProps = {
  useCases: PersistedUseCase[];
  isLoading: boolean;
  error: string | null;
  onReloadClick: () => void;
  onCreateClick: () => void;
};

export interface UseCaseManagementViewModel {
  sidebarProps: UseCaseManagementSidebarProps;
  overviewProps: UseCaseOverviewProps;
  editorProps: UseCaseEditorProps;
  isEditorVisible: boolean;
}

export interface UseCaseEditorStreamState {
  _id?: ArdoqId;
  name: string;
  description?: string;
  iteration: string;
  category?: string;
  businessOutcomes: string;
  metamodelTriples: Triple[];
  entityGroupId: ArdoqId;
  pdfLinks: PDFLink[];
  keyMetricsDashboardId: ArdoqId;
  errors?: string[];
  initialStateToCompare: ComparableEditorState;
}

export enum UseCaseTabs {
  MANAGE = 'manage',
  CREATE = 'create',
  EDIT = 'edit',
}

export type UpdateTriplePartPayload = {
  index: number;
  value: string | null;
};

export type UpdatePdfLinkPayload = {
  index: number;
  updatedLink: Partial<PDFLink>;
};

export type UseCaseDataSourceItem = PersistedUseCase & {
  handleDelete: VoidFunction;
  handleEdit: VoidFunction;
  handleCreateFromExistingUseCase: VoidFunction;
};

export type ComparableEditorState =
  | Omit<UseCaseEditorStreamState, 'errors' | 'initialStateToCompare'>
  | undefined;

export type UseCaseEditorProps = {
  isLoading: boolean;
  hasChanges: boolean;
  editorState: UseCaseEditorStreamState;
  editorErrors: {
    triplesErrors: TripleErrorObject[];
    hasTriplesError: boolean;
    hasDashboardError: boolean;
    errorNotifications?: string[];
  };
  editorCommands: {
    changeName: (value: GenericUseCaseFieldValue) => void;
    changeVersion: (value: GenericUseCaseFieldValue) => void;
    changeCategory: (value: GenericUseCaseFieldValue) => void;
    changeDescription: (value: GenericUseCaseFieldValue) => void;
    changeBusinessOutcomes: (value: GenericUseCaseFieldValue) => void;
    changeKeyMetricsDashboard: (value: GenericUseCaseFieldValue) => void;
    changeEntityGroup: (value: GenericUseCaseFieldValue) => void;

    changePdfLinkLabel: (
      index: number
    ) => (value: GenericUseCaseFieldValue) => void;
    changePdfLinkUrl: (
      index: number
    ) => (value: GenericUseCaseFieldValue) => void;
    deletePdfLink: (index: number) => () => void;
    addPdfLink: () => void;

    updateTripleSourceComponentType: (
      index: number
    ) => (value: UpdateTriplePartPayload['value']) => void;
    updateTripleReferenceType: (
      index: number
    ) => (value: UpdateTriplePartPayload['value']) => void;
    updateTripleTargetComponentType: (
      index: number
    ) => (value: UpdateTriplePartPayload['value']) => void;

    deleteTriple: (index: number) => () => void;
    addTriple: () => void;

    saveUseCase: () => void;
  };
  formState: {
    fields: {
      keyMetricsDashboards: {
        isDisabled: boolean;
        popoverContent: string | null;
        errorMessage?: string | undefined;
      };
    };
    buttons: {
      addTriples: {
        dataTooltipText: DataTooltipText;
        isDisabled: boolean;
      };
      saveButton: {
        isDisabled: boolean;
      };
    };
  };
  availableEntityGroups: SelectOption<string>[];
  availableKeyMetricsDashboards: SelectOption<string>[];

  triplesSelectOptions: TripleSelectOptions[];
};

export type DataTooltipText = string | null | undefined;

export type SetUseCaseFieldValuePayload = {
  key: keyof UseCaseEditorStreamState;
  value: string | null;
};

export type RequestEntityGroupChangePayload = {
  newEntityGroupId: GenericUseCaseFieldValue;
  currentEntityGroupId: string | undefined;
};

type GenericUseCaseFieldValue = string | null;

export type SetUseCaseManagementFieldValuePayload = {
  [K in keyof UseCaseManagementStreamState]: {
    key: K;
    value: K extends keyof SelectableKeysValue
      ? SelectableKeysValue[K]
      : UseCaseManagementStreamState[K];
  };
}[keyof UseCaseManagementStreamState];

export type SelectableKeysValue = {
  componentTypes: APIComponentTypeAttributes[];
  referenceTypes: APIReferenceTypeAttributes[];
  entityGroups: PersistedEntityGroupShape[];
  keyMetricsDashboards: APIDashboardAttributes[];
};

export type UseCaseManagementSidebarProps = {
  selectedTab: UseCaseTabs;
  onManageClick: () => void;
  onCreateClick: () => void;
};

export type TripleErrorObject = {
  hasDeletedSourceComponentType: boolean;
  hasDeletedReferenceType: boolean;
  hasDeletedTargetComponentType: boolean;
  hasReferenceOutOfSourceComponentTypeWorkspace: boolean;
  hasTargetComponentTypeOutOfSourceComponentTypeWorkspace: boolean;
};

export type TripleSelectOptions = {
  sourceComponentTypeOptions: SelectOption<string>[];
  referenceTypeOptions: SelectOption<string>[];
  targetComponentTypeOptions: SelectOption<string>[];
};
