import { UserAssetPermission } from '@ardoq/api-types';
import { accessControlPageOperations } from './accessControlPageOperations';
import { accessControlPageResourcesOperations } from './accessControlPageResourcesOperations';
import { AccessControlPageResources, AccessControlPageStream } from './types';
import { userAssetsOverviewOperations } from 'admin/accessControl/UserProfilePage/userAssetsOverview/userAssetsOverviewOperations';
import { userAssetsOverviewQueryOperations } from 'admin/accessControl/UserProfilePage/userAssetsOverview/userAssetsOverviewQueryOperations';
import { UserAssetsOverviewQuery } from 'admin/accessControl/UserProfilePage/userAssetsOverview/userAssetsOverviewTypes';
import { userAssetsFilterSidebarStateOperations } from 'admin/accessControl/UserProfilePage/UserRoleAndGroupsDetails/UserAssetsOverviewTab/assetsOverviewFilterSidebarOperations';

const emptyState = (): AccessControlPageStream => ({
  resources: accessControlPageResourcesOperations.emptyState(),
  viewModel: accessControlPageOperations.emptyState(),
});

const updateOrganization = (
  state: AccessControlPageStream,
  organization: AccessControlPageResources['organization']
): AccessControlPageStream => {
  const resources = accessControlPageResourcesOperations.updateResources(
    state.resources,
    { organization }
  );
  const viewModel = accessControlPageOperations.handleOrganizationChange(
    state.viewModel,
    organization
  );
  return {
    ...state,
    resources,
    viewModel,
  };
};

const updateNavigationState = (
  state: AccessControlPageStream,
  navigationState: AccessControlPageResources['navigationState']
): AccessControlPageStream => {
  const resources = accessControlPageResourcesOperations.updateResources(
    state.resources,
    { navigationState }
  );
  const viewModel = accessControlPageOperations.handleNavigationChange(
    state.viewModel,
    navigationState
  );
  return {
    ...state,
    resources,
    viewModel: accessControlPageOperations.handleNavigateToUserPage(
      viewModel,
      resources
    ),
  };
};

const updatePrivileges = (
  state: AccessControlPageStream,
  privileges: AccessControlPageResources['privileges']
): AccessControlPageStream => {
  const resources = accessControlPageResourcesOperations.updateResources(
    state.resources,
    { privileges }
  );
  const viewModel = accessControlPageOperations.handlePrivilegesChange(
    state.viewModel,
    privileges
  );
  return {
    ...state,
    resources,
    viewModel: accessControlPageOperations.handleNavigateToUserPage(
      viewModel,
      resources
    ),
  };
};

const updateUserPageViewModel = (
  state: AccessControlPageStream,
  combinedResources: {
    orgUsers: AccessControlPageResources['orgUsers'];
    rolesShape: AccessControlPageResources['rolesShape'];
    permissionGroup: AccessControlPageResources['permissionGroup'];
    subdivisions: AccessControlPageResources['subdivisions'];
    ipAllowlist: AccessControlPageResources['ipAllowlist'];
    ssoAttributeMapping: AccessControlPageResources['ssoAttributeMapping'];
    serviceAccounts: AccessControlPageResources['serviceAccounts'];
  }
): AccessControlPageStream => {
  const resources = accessControlPageResourcesOperations.updateResources(
    state.resources,
    combinedResources
  );
  const viewModel = accessControlPageOperations.handleNavigateToUserPage(
    state.viewModel,
    resources
  );
  return {
    ...state,
    resources,
    viewModel,
  };
};

const requestUserAssetsSuccess = (
  state: AccessControlPageStream,
  userAssets: UserAssetPermission[]
): AccessControlPageStream => {
  const userAssetsOverview = userAssetsOverviewOperations.requestSuccess(
    state.viewModel.userAssetsOverview,
    userAssets
  );
  const locallyFilteredUserAssets = userAssetsOverviewOperations.filterLocally(
    userAssetsOverview,
    state.viewModel.userAssetsQuery
  );
  return {
    ...state,
    viewModel: {
      ...state.viewModel,
      userAssetsOverview,
      locallyFilteredUserAssets,
    },
  };
};

const requestUserAssetsFailure = (
  state: AccessControlPageStream,
  error: string
): AccessControlPageStream => {
  return {
    ...state,
    viewModel: {
      ...state.viewModel,
      userAssetsOverview: userAssetsOverviewOperations.requestFailure(
        state.viewModel.userAssetsOverview,
        error
      ),
    },
  };
};

const handleOpenUserAssetsFilterSidebar = (
  state: AccessControlPageStream
): AccessControlPageStream => {
  return {
    ...state,
    viewModel: {
      ...state.viewModel,
      userAssetsFilterSidebarState:
        userAssetsFilterSidebarStateOperations.setVisibility(
          state.viewModel.userAssetsFilterSidebarState,
          { visible: true, userAssetsQuery: state.viewModel.userAssetsQuery }
        ),
    },
  };
};

const handleCloseUserAssetsFilterSidebar = (
  state: AccessControlPageStream
): AccessControlPageStream => {
  return {
    ...state,
    viewModel: {
      ...state.viewModel,
      userAssetsFilterSidebarState:
        userAssetsFilterSidebarStateOperations.setVisibility(
          state.viewModel.userAssetsFilterSidebarState,
          { visible: false }
        ),
    },
  };
};

const loadingUserAssets = (
  state: AccessControlPageStream
): AccessControlPageStream => {
  return {
    ...state,
    viewModel: {
      ...state.viewModel,
      userAssetsOverview: userAssetsOverviewOperations.makingARequest(
        state.viewModel.userAssetsOverview
      ),
    },
  };
};

const updateUserAssetsQuery = (
  state: AccessControlPageStream,
  query: Partial<UserAssetsOverviewQuery>
): AccessControlPageStream => {
  const userAssetsQuery =
    userAssetsOverviewQueryOperations.updateUserAssetsQuery(
      state.viewModel.userAssetsQuery,
      query
    );
  const locallyFilteredUserAssets = userAssetsOverviewOperations.filterLocally(
    state.viewModel.userAssetsOverview,
    userAssetsQuery
  );
  return {
    ...state,
    viewModel: {
      ...state.viewModel,
      userAssetsQuery,
      locallyFilteredUserAssets,
      userAssetsFilterSidebarState: {
        ...state.viewModel.userAssetsFilterSidebarState,
        hasActiveFilters:
          userAssetsOverviewQueryOperations.isNotEmpty(userAssetsQuery),
      },
    },
  };
};

export const accessControlPageStreamOperations = {
  emptyState,
  updateNavigationState,
  updateOrganization,
  updatePrivileges,
  updateUserPageViewModel,
  requestUserAssetsSuccess,
  requestUserAssetsFailure,
  handleOpenUserAssetsFilterSidebar,
  handleCloseUserAssetsFilterSidebar,
  loadingUserAssets,
  updateUserAssetsQuery,
};
