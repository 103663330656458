import { actionCreator } from '@ardoq/rxbeach';

export const addReferenceType = actionCreator(
  '[appModelStateEdit/manageReferenceTypes] ADD_REFERENCE_TYPE'
);

export const editReferenceType = actionCreator<number>(
  '[appModelStateEdit/manageReferenceTypes] EDIT_REFERENCE_TYPE'
);

export const confirmDeleteReferenceType = actionCreator<number>(
  '[appModelStateEdit/manageReferenceTypes] CONFIRM_DELETE_REFERENCE_TYPE'
);
