import ModuleContainer from './ModuleContainer';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import { connect } from '@ardoq/rxbeach';
import externalDocument$ from 'externalDocuments/externalDocument$';
import ExternalDocumentViewContainer from 'externalDocuments/ExternalDocumentViewContainer';
import { ExternalDocumentState } from 'externalDocuments/reducers';

const ExternalDocumentContainer = (state: ExternalDocumentState) => (
  <ModuleContainer $overflow="hidden">
    <ErrorBoundary logError={logError}>
      {state.externalDocumentId && state.clientId && (
        <ExternalDocumentViewContainer
          documentId={state.externalDocumentId}
          title={state.externalDocumentTitle || 'External Document'}
          clientId={state.clientId}
        />
      )}
    </ErrorBoundary>
  </ModuleContainer>
);

export default connect(ExternalDocumentContainer, externalDocument$);
