import { TopBarButton } from '../atoms';
import { Icon, IconName } from '@ardoq/icons';

type NavigatorTopBarFilterButtonProps = {
  showComponents: boolean;
  toggleShowComponents: () => void;
  isScenarioMode: boolean;
};

const NavigatorTopBarFilterButton = ({
  showComponents,
  toggleShowComponents,
  isScenarioMode,
}: NavigatorTopBarFilterButtonProps) => {
  return (
    <TopBarButton
      $isScenarioMode={isScenarioMode}
      onClick={toggleShowComponents}
      data-tooltip-text={
        showComponents ? 'Hide filtered components' : 'Show filtered components'
      }
    >
      <Icon
        iconName={
          showComponents
            ? IconName.FIBER_MANUAL_RECORD
            : IconName.RADIO_BUTTON_UNCHECKED
        }
      />
    </TopBarButton>
  );
};

export default NavigatorTopBarFilterButton;
