import {
  resetIntegration,
  setIntegrationName,
} from 'integrations/common/streams/activeIntegrations/actions';
import {
  selectConnection,
  updateConnectionStatuses,
  resetSelectedConnectionIds,
  updateSingleSelectedConnectionId,
} from 'integrations/common/streams/connections/actions';
import {
  connections$,
  getConnectionsStream,
} from 'integrations/common/streams/connections/connections$';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { handleApiError } from 'integrations/common/utils/api';
import { INTEGRATION_ID_TO_PROVIDER_ID } from 'integrations/unified/constants';
import {
  isUnifiedIntegrationId,
  isUnifiedIntegrationPayload,
} from 'integrations/unified/utils';
import fp from 'lodash/fp';
import {
  action$,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  EMPTY,
  catchError,
  combineLatest,
  filter,
  firstValueFrom,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  ensureResource,
  ensureResources,
  getResource,
  getResourceFailure,
  getResourceSuccess,
  getResources,
  getResourcesFailure,
  getResourcesSuccess,
  resetIntegration as resetIntegrationResources,
  selectResource,
  selectResourceField,
  setSelectedResources,
  setUnavailableResources,
} from './actions';
import * as api from './api';
import { getResourcesStream, resources$ } from './resources$';
import { splitByAvailibility } from './utils';
import { ActionCreatorParameter } from 'integrations/common/utils/actionCreatorWithIntegrationId';

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  tap(id => {
    if (isUnifiedIntegrationId(id)) {
      dispatchAction(
        resetIntegrationResources({
          integrationId: id,
        })
      );
    }
  })
);

const handleGetResources = routine(
  ofType(getResources),
  extractPayload(),
  withLatestFrom(connections$),
  switchMap(([{ integrationId }, connections]) => {
    const connectionId = connections[integrationId]?.selectedConnectionIds[0];
    if (connectionId) {
      return combineLatest({
        resources: from(
          api.getResources({
            connectionId,
            providerId: INTEGRATION_ID_TO_PROVIDER_ID[integrationId],
          })
        ).pipe(
          catchError(error => {
            dispatchAction(
              getResourcesFailure({
                integrationId: error.extraData.integrationId,
                message: "We're unable to fetch the tables.",
              })
            );
            return handleApiError(error);
          })
        ),
        integrationId: of(integrationId),
      });
    }
    return EMPTY;
  }),

  tap(({ resources, integrationId }) => {
    dispatchAction(getResourcesSuccess({ resources, integrationId }));
  })
);

const handleEnsureResources = routine(
  ofType(ensureResources),
  extractPayload(),
  tap(({ integrationId }) => {
    dispatchAction(getResources({ integrationId }));
  })
);

const handleGetResource = routine(
  ofType(getResource),
  extractPayload(),
  withLatestFrom(connections$),
  mergeMap(([{ integrationId, resourceId }, connections]) => {
    const connectionId = connections[integrationId]?.selectedConnectionIds[0];
    if (connectionId) {
      return combineLatest({
        resource: from(
          api.getResource({
            providerId: INTEGRATION_ID_TO_PROVIDER_ID[integrationId],
            resourceId,
            connectionId,
          })
        ).pipe(
          catchError(error => {
            dispatchAction(
              getResourceFailure({
                integrationId: error.extraData.integrationId,
                resourceId: error.extraData.resourceId,
                message: "We're unable to fetch table fields.",
              })
            );
            return handleApiError(error);
          })
        ),
        integrationId: of(integrationId),
        resourceId: of(resourceId),
      });
    }
    return EMPTY;
  }),

  tap(payload => {
    dispatchAction(getResourceSuccess(payload));
  })
);

const handleEnsureResource = routine(
  ofType(ensureResource),
  extractPayload(),
  withLatestFrom(resources$),
  tap(([{ integrationId, resourceId }, resources]) => {
    if (
      !['LOADING', 'SUCCESS'].includes(
        resources[integrationId]?.requests.getResource[resourceId]?.status
      )
    ) {
      dispatchAction(getResource({ integrationId, resourceId }));
    }
  })
);

const handleSelectResource = routine(
  ofType(selectResource),
  extractPayload(),
  tap(({ integrationId, resourceId }) => {
    dispatchAction(ensureResource({ integrationId, resourceId }));
  })
);

const handleSelectConnection = routine(
  ofType(selectConnection),
  extractPayload(),
  filter(
    isUnifiedIntegrationPayload<ActionCreatorParameter<typeof selectConnection>>
  ),
  withLatestFrom(connections$),
  switchMap(
    ([{ selectedConnectionId, integrationId }, instanceToConnections]) => {
      // if the connection is already selected, do nothing
      if (
        instanceToConnections[integrationId].selectedConnectionIds[0] ===
        selectedConnectionId
      ) {
        return EMPTY;
      }

      const {
        connections,
        statuses: {
          list: { status },
        },
      } = instanceToConnections[integrationId];
      // waiting for available connections to be fetched
      return combineLatest([
        of(integrationId),
        of(selectedConnectionId),
        status === 'SUCCESS'
          ? of(connections)
          : firstValueFrom(
              action$.pipe(
                ofType(updateConnectionStatuses),
                extractPayload(),
                filter(
                  ({ integrationId: id, statuses }) =>
                    integrationId === id &&
                    (statuses.list?.status === 'SUCCESS' ||
                      statuses.list?.status === 'FAILURE')
                ),
                withLatestFrom(getConnectionsStream(integrationId)),
                map(([_, { connections = [] }]) => connections)
              )
            ),
      ]);
    }
  ),
  tap(([integrationId, selectedConnectionId, connections]) => {
    const sourceConnection = connections.find(
      ({ _id }) => _id === selectedConnectionId
    );

    if (!sourceConnection) {
      // TODO: notify customer that the connection from configuration no longer exists
      dispatchAction(resetSelectedConnectionIds({ integrationId }));
      return;
    }
    dispatchAction(
      setIntegrationName({
        integrationId,
        name: sourceConnection.name,
      })
    );
    dispatchAction(
      updateSingleSelectedConnectionId({ integrationId, selectedConnectionId })
    );
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'SELECTED_CONNECTION',
      })
    );
  })
);

const handleConnectionChange = routine(
  ofType(updateSingleSelectedConnectionId),
  extractPayload(),
  filter(
    isUnifiedIntegrationPayload<
      ActionCreatorParameter<typeof updateSingleSelectedConnectionId>
    >
  ),
  withLatestFrom(resources$),
  tap(([{ integrationId }]) => {
    dispatchAction(ensureResources({ integrationId }));
  }),
  switchMap(([{ integrationId }, resources]) => {
    const integrationResources = resources[integrationId];
    // if tables are not fetched yet, wait for the success action
    return firstValueFrom(
      action$.pipe(
        ofType(getResourcesSuccess, getResourcesFailure),
        switchMap(() =>
          of([integrationId, integrationResources.selectedResources] as const)
        )
      )
    );
  }),

  // fetching selected table fields
  switchMap(([integrationId, resources]) => {
    const selectedResourceIds = Object.keys(resources);
    selectedResourceIds.forEach(resourceId => {
      dispatchAction(getResource({ integrationId, resourceId }));
    });

    // waiting for all tableFields requests to succeed
    return firstValueFrom(
      getResourcesStream(integrationId).pipe(
        withLatestFrom(of(integrationId)),
        filter(([{ requests }]) => {
          return fp.every(
            resourceId =>
              requests.getResource[resourceId]?.status !== 'LOADING',
            selectedResourceIds
          );
        })
      )
    );
  }),

  // when tables fields are fetched successfully, update the selection
  tap(([resources, integrationId]) => {
    const { selectedResources, unavailableResources } = splitByAvailibility(
      resources.selectedResources,
      resources.resources
    );
    dispatchAction(
      setSelectedResources({ resources: selectedResources, integrationId })
    );
    dispatchAction(
      setUnavailableResources({
        resources: unavailableResources,
        integrationId,
      })
    );
  })
);

const trackSelectResourceField = routine(
  ofType(selectResourceField),
  extractPayload(),
  tap(({ integrationId }) => {
    dispatchAction(
      trackIntegrationEvent({ integrationId, name: 'SELECTED_SOURCE_FIELD' })
    );
  })
);

export default [
  handleResetIntegration,
  handleGetResources,
  handleEnsureResources,
  handleGetResource,
  handleSelectResource,
  handleEnsureResource,
  handleSelectConnection,
  handleConnectionChange,
  trackSelectResourceField,
];
