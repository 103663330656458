import { s32 } from '@ardoq/design-tokens';
import { Space } from '@ardoq/style-helpers';
import styled from 'styled-components';

export const StepContainer = styled(Space).attrs({
  $align: 'center',
  $flex: 1,
})`
  overflow: auto;
  padding: ${s32};
  > div {
    width: 100%;
  }
`;
