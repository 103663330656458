import { colors, r8, s16, shadowM, zIndex } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { RightPane } from 'views/mainApp/rightPane/rightPane';
import { VIEW_PANE_BAR_HEIGHT_WITH_PADDING } from './ardoqStudio/viewPaneHeader/consts';
import {
  HorizontalResizeableContainerWrapper,
  ResizableHorizontal,
} from '@ardoq/resizable';

const widthOfSidePanelHeaderButtons = 368;

const ResizableSideBarEditorContainer = styled.div<{
  $isRightEditorPaneOpen: boolean;
}>`
  /* The side bar editor must always be in the DOM to work properly, no matter
  if it's displayed or not. */
  display: ${props => (props.$isRightEditorPaneOpen ? 'block' : 'none')};
  position: absolute;
  top: ${VIEW_PANE_BAR_HEIGHT_WITH_PADDING}px;
  left: ${s16};
  right: ${s16};
  bottom: ${s16};
  pointer-events: none;
  z-index: ${zIndex.VIEW_LEGEND + 1};
`;

const RightPaneContainer = styled.div`
  height: 100%;
  background-color: ${colors.white};
  border-radius: ${r8};
  box-shadow: ${shadowM};
  overflow: auto;
  pointer-events: all;
`;

export const ResizableSidebarEditorAsOverlay = ({
  isRightEditorPaneOpen,
}: {
  isRightEditorPaneOpen: boolean;
}) => (
  <ResizableSideBarEditorContainer
    $isRightEditorPaneOpen={isRightEditorPaneOpen}
  >
    <ResizableHorizontal>
      <HorizontalResizeableContainerWrapper />
      <HorizontalResizeableContainerWrapper
        initialWidth={widthOfSidePanelHeaderButtons}
      >
        <RightPaneContainer>
          <RightPane isViewpointMode={true} />
        </RightPaneContainer>
      </HorizontalResizeableContainerWrapper>
    </ResizableHorizontal>
  </ResizableSideBarEditorContainer>
);
