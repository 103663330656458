import { SearchInput } from '@ardoq/forms';
import { connect } from '@ardoq/rxbeach';
import dataOverviewViewModel$ from './dataOverviewViewModel$';
import Navbar from 'views/navbar/Navbar';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import {
  DataOverviewTypes,
  DataOverviewWorkspaceRow,
  DataOverviewComponentTypeRow,
} from './types';
import DataOverviewSwitch from './DataOverviewSwitch';
import { DataOverviewCommands } from './dataOverviewCommands';
import { Locale } from '@ardoq/locale';
import ComponentTypesTable from './ComponentTypesTable';
import WorkspacesTable from './WorkspacesTable';
import { DoqWithSpeechBubble, DoqType, DoqLayout } from '@ardoq/doq';
import { FlexBox, Box } from '@ardoq/layout';
import { ArdoqId } from '@ardoq/api-types';
import { Island } from '@ardoq/page-layout';
import { NewBadge } from '@ardoq/status-ui';
import { inventoryTracking } from '../inventoryTracking';

export type DataOverviewProps = (
  | {
      datasource: DataOverviewWorkspaceRow[];
      dataOverviewType: DataOverviewTypes.WORKSPACE;
    }
  | {
      datasource: DataOverviewComponentTypeRow[];
      dataOverviewType: DataOverviewTypes.COMPONENT_TYPE;
    }
) & {
  locale: Locale;
  dataOverviewCommands: DataOverviewCommands;
  numberOfRowsPerPage: number;
  isSearchActive: boolean;
  isLoading: boolean;
  isError: boolean;
  searchValue: string;
  selectedItems: ArdoqId[] | string[];
};

const DataOverview = ({
  locale,
  datasource,
  dataOverviewType,
  dataOverviewCommands,
  numberOfRowsPerPage,
  isSearchActive,
  isLoading,
  isError,
  searchValue,
  selectedItems,
}: DataOverviewProps) => (
  <PageWrapper>
    <Navbar
      primaryContent={
        <FlexBox gap="small">
          Data
          <NewBadge />
        </FlexBox>
      }
      secondaryContent="Overview"
    />
    <PageBody backgroundColor="surfaceDefault" skipPadding>
      <FlexBox
        flexDirection="column"
        gap="medium"
        height="full"
        paddingLeft="large"
        paddingRight="large"
        paddingBottom="large"
      >
        <FlexBox
          padding="small"
          borderRadius="medium"
          justify="space-between"
          width="full"
          align="center"
          borderColor="borderAction"
        >
          <Box paddingX="xsmall">
            <SearchInput
              isDisabled={isError}
              onValueChange={dataOverviewCommands.setSearchValue}
              value={searchValue}
              onFocus={() => {
                inventoryTracking.trackFocusOnSearchInputInDataOverview(
                  dataOverviewType
                );
              }}
            />
          </Box>
          <DataOverviewSwitch
            datasource={datasource}
            dataOverviewType={dataOverviewType}
            dataOverviewCommands={dataOverviewCommands}
            isError={isError}
            selectedItems={selectedItems}
          />
        </FlexBox>
        {isError && (
          <Box height="full">
            <Island maxWidth="100%" bodySpacing="none" fullHeight>
              <FlexBox
                justify="center"
                align="center"
                height="full"
                flexGrow={1}
              >
                <DoqWithSpeechBubble
                  layout={DoqLayout.DIALOG}
                  doqType={DoqType.ERROR}
                  message="Something went wrong and your data couldn't be fetched. Please try again or contact customer support."
                  title="Oops! Something went wrong"
                />
              </FlexBox>
            </Island>
          </Box>
        )}
        {dataOverviewType === DataOverviewTypes.COMPONENT_TYPE && !isError && (
          <ComponentTypesTable
            datasource={datasource}
            locale={locale}
            dataOverviewCommands={dataOverviewCommands}
            numberOfRowsPerPage={numberOfRowsPerPage}
            isSearchActive={isSearchActive}
            isLoading={isLoading}
            selectedItems={selectedItems}
          />
        )}
        {dataOverviewType === DataOverviewTypes.WORKSPACE && !isError && (
          <WorkspacesTable
            datasource={datasource}
            locale={locale}
            dataOverviewCommands={dataOverviewCommands}
            numberOfRowsPerPage={numberOfRowsPerPage}
            isSearchActive={isSearchActive}
            isLoading={isLoading}
            selectedItems={selectedItems}
          />
        )}
      </FlexBox>
    </PageBody>
  </PageWrapper>
);

export default connect(DataOverview, dataOverviewViewModel$);
