import { useEffect } from 'react';
import {
  Indicator,
  List,
  NotificationCardProps,
  notificationOperations,
} from '@ardoq/in-app-notifications';
import inAppNotifications$ from 'streams/inAppNotifications/inAppNotifications$';
import {
  ChecksCounter,
  InAppNotificationsState,
  NotificationsFilterType,
} from 'streams/inAppNotifications/types';
import {
  closeInAppNotificationsList,
  getInAppNotificationsList,
  toggleCanDisplayToast,
  toggleInAppNotificationsList,
  toggleShowMore,
} from 'streams/inAppNotifications/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { colors, s16, s32 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { map, takeWhile } from 'rxjs';
import { ToastType, showToast } from '@ardoq/status-ui';
import { PluralOperand, pickCorrectPlural } from '@ardoq/common-helpers';
import { trackEvent } from 'tracking/tracking';
import { lazyConnect } from 'streams/utils/streamUtils';

const InAppNotificationsContainer = styled.div`
  position: relative;
`;

const NotificationsListContainer = styled.div`
  position: absolute;
  z-index: 100;
  right: ${s16};
  top: calc(${s32} + ${s16});
  background-color: ${colors.white};
  color: ${colors.black};
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
`;

type InAppNotificationsProps = {
  unreadNotificationsCount: number;
  showFooter: boolean;
  hasUnread: boolean;
};

const InAppNotifications = ({
  showList,
  notifications,
  filters,
  isPartial,
  unreadNotificationsCount,
  showFooter,
  checksCounter,
  isLoading,
  hasUnread,
}: InAppNotificationsState & InAppNotificationsProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        dispatchAction(closeInAppNotificationsList());
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <InAppNotificationsContainer>
      <Indicator
        isActive={showList}
        hasUnread={hasUnread}
        onClick={() => {
          dispatchAction(getInAppNotificationsList());
          dispatchAction(toggleInAppNotificationsList());
          trackEvent('Topbar: clicked notifications');
        }}
      />
      {showList && (
        <>
          <Backdrop
            onClick={() => dispatchAction(toggleInAppNotificationsList())}
          />
          <NotificationsListContainer>
            <List
              notifications={notifications}
              filters={Object.values(filters)}
              footer={
                showFooter
                  ? {
                      label: `Show ${isPartial ? 'more' : 'less'}`,
                      handler: () => dispatchAction(toggleShowMore()),
                    }
                  : undefined
              }
              customCounter={unreadNotificationsCount}
              isLoading={isLoading && checksCounter === ChecksCounter.ZERO}
              isRefreshing={isLoading && checksCounter !== ChecksCounter.ZERO}
            />
          </NotificationsListContainer>
        </>
      )}
    </InAppNotificationsContainer>
  );
};

const filtersFuncs: Record<
  NotificationsFilterType,
  (n: NotificationCardProps) => boolean
> = {
  [NotificationsFilterType.ALL]: () => true,
  [NotificationsFilterType.UNREAD]: ({ isUnread }) => isUnread,
};

const decoratedInAppNotifications$ = inAppNotifications$.pipe(
  map(({ notifications, filteringBy, isPartial, ...state }) => {
    const filteredNotifications = notifications
      .sort(notificationOperations.sortNewestFirst)
      .filter(filtersFuncs[filteringBy]);
    const firstNotifications = isPartial
      ? filteredNotifications.slice(0, 4)
      : filteredNotifications;

    const unreadNotificationsCount = notifications.filter(
      ({ isUnread }) => isUnread
    ).length;

    return {
      ...state,
      filteringBy,
      isPartial,
      notifications: firstNotifications,
      unreadNotificationsCount,
      showFooter: filteredNotifications.length > 4,
      hasUnread: unreadNotificationsCount > 0,
    };
  })
);

decoratedInAppNotifications$
  .pipe(
    takeWhile(({ checksCounter }) => checksCounter <= ChecksCounter.ONE, true)
  )
  .subscribe(
    ({
      canDisplayToast,
      hasUnread,
      showList,
      unreadNotificationsCount,
      checksCounter,
    }) => {
      if (
        canDisplayToast &&
        hasUnread &&
        checksCounter === ChecksCounter.ONE &&
        !showList
      ) {
        showToast(
          pickCorrectPlural(
            {
              [PluralOperand.ONE]: 'You have 1 unread notification',
              [PluralOperand.OTHER]: `You have ${unreadNotificationsCount} unread notifications`,
            },
            unreadNotificationsCount
          ),
          ToastType.INFO
        );
        dispatchAction(toggleCanDisplayToast(false));
      }
    }
  );

export default lazyConnect(
  InAppNotifications,
  decoratedInAppNotifications$,
  getInAppNotificationsList
);
