import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
  withoutNamespace,
} from '@ardoq/rxbeach';
import { createSearchQuery } from 'search/actions';
import { tap } from 'rxjs/operators';
import { selectSearchPane } from './actions';
import { queryEditorNamespaceToSearchPaneMap } from './types';
import { QueryEditorNamespace } from 'search/QueryEditor/queryEditor$';

const handleCreateSearchQuery = routine(
  ofType(createSearchQuery),
  // Prevent double actions for dynamic search, which dispatches two
  // `createSearchQuery` actions (one for the main query, and one for the
  // parameter query)
  withoutNamespace(QueryEditorNamespace.PARAMETER_QUERY),
  tap(({ meta: { namespace } }) => {
    if (namespace === undefined) return;

    const searchPane = queryEditorNamespaceToSearchPaneMap.get(namespace);
    if (searchPane !== undefined) {
      dispatchAction(selectSearchPane({ searchPane }));
    }
  })
);

export const searchPaneRoutines = collectRoutines(handleCreateSearchQuery);
