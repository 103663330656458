import { PDFLink, PersistedUseCase, Triple } from '@ardoq/api-types';
import {
  validateAllObject,
  validateAs,
  ValidationDescriptor,
} from '@ardoq/validation';

const tripleIndexToMissingValueName: Record<number, string> = {
  0: 'source component type',
  1: 'reference type',
  2: 'target component type',
};

const getMetamodelTriplesValidators = (triples: Triple[]) =>
  triples.reduce((validators, triple, tripleNumber) => {
    const currentTripleValidators = triple.reduce(
      (acc, componentOrReferenceType, idx) => ({
        ...acc,
        [`triple-${tripleNumber}-${idx}`]: {
          value: componentOrReferenceType,
          validationSteps: [
            validateAs.requiredString({
              message: `Metamodel triple #${tripleNumber + 1} is missing ${
                tripleIndexToMissingValueName[idx]
              }`,
            }),
          ],
        },
      }),
      {}
    );
    return { ...validators, ...currentTripleValidators };
  }, {});

const getPdfLinksUrlsValidators = (links: PDFLink[]) =>
  links
    .map(pdf => pdf.link)
    .reduce(
      (acc, link, index) => ({
        ...acc,
        [`pdf-link-${link}-${index}`]: {
          value: link,
          validationSteps: [
            validateAs.requiredString({
              message: `PDF Link #${index + 1} is missing file link`,
            }),
            validateAs.isValidUrl({
              message: `PDF Link #${
                index + 1
              } should have a valid File link URL`,
            }),
          ],
        },
      }),
      {}
    );

const getPdfLinksLabelsValidators = (links: PDFLink[]) =>
  links
    .map(pdf => pdf.label)
    .reduce(
      (acc, link, index) => ({
        ...acc,
        [`pdf-label-${link}-${index}`]: {
          value: link,
          validationSteps: [
            validateAs.requiredString({
              message: `PDF Link #${index + 1} is missing label`,
            }),
          ],
        },
      }),
      {}
    );

type GetUseCaseEditorErrorsParams = {
  useCase: PersistedUseCase;
  availableUseCases: PersistedUseCase[];
};

const getUseCaseValidatorDescriptors = (
  useCase: PersistedUseCase,
  uniqueUseCasesNames: string[]
): Record<string, ValidationDescriptor> => ({
  name: {
    value: useCase.name,
    validationSteps: [
      validateAs.requiredString({ message: 'Name is required' }),
      validateAs.notIncludedIn({
        array: uniqueUseCasesNames,
        message: 'Use case name already exists.',
      }),
    ],
  },
  iteration: {
    value: useCase.iteration,
    validationSteps: [
      validateAs.requiredString({ message: 'Version is required' }),
    ],
  },
  businessOutcomes: {
    value: useCase.businessOutcomes,
    validationSteps: [
      validateAs.requiredString({ message: 'Business outcomes is required' }),
    ],
  },
  entityGroupId: {
    value: useCase.entityGroupId,
    validationSteps: [
      validateAs.requiredString({ message: 'Select an entity group' }),
    ],
  },
  keyMetricsDashboardId: {
    value: useCase.keyMetricsDashboardId,
    validationSteps: [
      validateAs.requiredString({
        message: 'Select a key metrics dashboard',
      }),
    ],
  },
  pdfLinks: {
    value: useCase.pdfLinks,
    validationSteps: [
      validateAs.minLength({
        length: 1,
        message: 'You need to have at least one PDF link',
      }),
    ],
  },
  ...getPdfLinksLabelsValidators(useCase.pdfLinks),
  ...getPdfLinksUrlsValidators(useCase.pdfLinks),
  ...getMetamodelTriplesValidators(useCase.metamodelTriples),
});

export const getUseCaseEditorFormErrors = ({
  useCase,
  availableUseCases,
}: GetUseCaseEditorErrorsParams) => {
  const uniqueUseCasesNames = (
    useCase._id
      ? availableUseCases.filter(({ _id }) => _id !== useCase._id)
      : availableUseCases
  ).map(uc => uc.name);

  const descriptors = getUseCaseValidatorDescriptors(
    useCase,
    uniqueUseCasesNames
  );

  const validationResult = validateAllObject(descriptors);

  return {
    isValid: validationResult.isValid,
    errorMsgs: Object.values(
      !validationResult.isValid ? validationResult.errorMessages : {}
    ).map(errorsArray => errorsArray[0]),
  };
};
