import { PrimaryButton } from '@ardoq/button';
import { FlexBox } from '@ardoq/layout';

type GremlinAudienceSearchButtonProps = {
  readonly onClick: VoidFunction;
  readonly isSearching: boolean;
};

const GremlinAudienceSearchButton = ({
  onClick,
  isSearching,
}: GremlinAudienceSearchButtonProps) => (
  <FlexBox align="center" gap="small">
    <PrimaryButton onClick={onClick} isLoading={isSearching}>
      Test calculation
    </PrimaryButton>
  </FlexBox>
);

export default GremlinAudienceSearchButton;
