import type { RelationshipsNode } from '../types';
import {
  CollapsibleGraphGroup,
  GraphGroup,
  graphItemLabel,
  getLabelWithFormattingForViewsNotSupportingMultilabeling,
} from '@ardoq/graph';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { SYNTHETIC_NODE_DEFAULTS } from './consts';
import { NODE_RADIUS } from '../consts';
import { WILDCARD } from '@ardoq/common-helpers';
import { context$ } from 'streams/context/context$';
import { CreateRelationshipsNode } from 'tabview/relationshipDiagrams/types';
import { ROOT_LEAF_GROUP_ID } from '../consts';
import { componentInterface } from '@ardoq/component-interface';
import { APIEntityType } from '@ardoq/api-types';
import { loadedGraph$ } from 'traversals/loadedGraph$';

const createNode =
  (
    collapsedGroupIds: Set<string>
  ): CreateRelationshipsNode<RelationshipsNode> =>
  (graphNode, parent, children, representationData) => {
    if (graphNode === 'rootLeafGroup') {
      return {
        ...SYNTHETIC_NODE_DEFAULTS,
        children: null,
        parent: null,
        id: ROOT_LEAF_GROUP_ID,
      } satisfies RelationshipsNode;
    }
    if (graphNode === 'root') {
      return {
        ...SYNTHETIC_NODE_DEFAULTS,
        id: 'root',
        parent: null,
        children: null,
      } satisfies RelationshipsNode;
    }
    const { id, modelId } = graphNode;

    const isComponent =
      modelId && componentInterface.isComponent(graphNode.modelId);

    const label = loadedGraph$.state.isViewpointMode
      ? getLabelWithFormattingForViewsNotSupportingMultilabeling({
          modelId,
          entityLabel: isComponent
            ? (componentInterface.getDisplayName(modelId) ?? '')
            : graphItemLabel(graphNode),
          entityType: APIEntityType.COMPONENT,
        })
      : graphNode instanceof GraphGroup
        ? graphNode.getLabel()
        : graphNode.getNameWithFieldLabelAndValue() || '';

    const node: RelationshipsNode = {
      ...SYNTHETIC_NODE_DEFAULTS,
      r: children ? SYNTHETIC_NODE_DEFAULTS.r : NODE_RADIUS,
      children: null,
      className: graphNode.getCSS({ useAsBackgroundStyle: true }),
      label,
      subLabel:
        graphNode instanceof GraphGroup ? (graphNode.subLabel ?? null) : null,
      parent,
      representationData,
      modelId: modelId,
      open: !collapsedGroupIds.has(WILDCARD) && !collapsedGroupIds.has(id),
      links: null,
      isSynthetic: false,
      id,
      descendantCount:
        (graphNode as CollapsibleGraphGroup).descendantCount ?? 0,
      isContext: Boolean(
        context$.state.componentId && modelId === context$.state.componentId
      ),
      matchingFilterIds: modelId
        ? (filterInterface
            .getColorFiltersForComponent(modelId)
            ?.map(filter => filter.cid) ?? null)
        : null,
    };
    return node;
  };
export default createNode;
