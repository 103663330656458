import { Model, Workspace } from 'aqTypes';
import {
  APIFieldAttributes,
  APIFieldType,
  APISurveyAttributes,
  APITagAttributes,
  ArdoqId,
  BaseReferenceQuestionProperties,
  CheckboxDisplayCondition,
  ListDisplayCondition,
  PartiallyPersistedConditionalQuestion,
  PartiallyPersistedReferenceQuestion,
  PersistedSurveyQuestion,
  SurveyQuestionType,
  SurveyQuestionValidator,
  UnpersistedSurveyQuestion,
  UnsetDisplayCondition,
  ValidDisplayConditions,
} from '@ardoq/api-types';
import {
  AllPossibleQuestions,
  MaybePersistedSurvey,
  SurveyQuestionError,
  SurveyQuestionValidation,
  SurveyQuestionValidations,
  SurveyQuestionWarning,
  SurveyTypeOption,
} from '../types';
import Workspaces from 'collections/workspaces';
import Fields from 'collections/fields';
import {
  currentUserHasNoAccessToWorkspace,
  currentUserHasNoEditAccessToWorkspace,
  getComponentTypeAndModelId,
} from '../SurveyEditor/utils';
import { getReferenceData } from '../referenceUtil';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { colors } from '@ardoq/design-tokens';
import { isNumber } from 'lodash';
import { logError } from '@ardoq/logging';
import searchService from 'search/searchAPIService';
import { dateRangeOperations } from '@ardoq/date-range';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { emailValidator, numberValidator } from 'scopeData/editors/validators';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { composeSearchQuery } from 'search/AdvancedSearch/utils';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { modelInterface } from 'modelInterface/models/modelInterface';
import { PermissionContext } from '@ardoq/access-control';
import { markdownToTextPreview } from '@ardoq/markdown';
import {
  isReferenceQuestion,
  isConditionalQuestion,
  isAttributeQuestion,
  isTagQuestion,
  isFieldQuestion,
  isHiddenFieldQuestion,
  isDependentQuestion,
} from './questionPredicates';
import {
  QuestionInteractionState,
  SurveyEditorState,
} from 'surveyAdmin/SurveyEditor/streams/types';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

export const removeComponentCreationFromQuestions = (
  questions: AllPossibleQuestions[]
): AllPossibleQuestions[] =>
  questions.map(question => {
    if (isReferenceQuestion(question)) {
      return {
        ...question,
        properties: {
          ...question.properties,
          allowComponentCreation: false,
        },
      };
    }
    if (isConditionalQuestion(question)) {
      return {
        ...question,
        properties: {
          ...question.properties,
          questions: removeComponentCreationFromQuestions(
            question.properties.questions ?? []
          ),
        },
      };
    }
    return question;
  }) as AllPossibleQuestions[];

const getTagsOfWorkspace = (
  workspaceId: ArdoqId,
  tags: APITagAttributes[] | null
): APITagAttributes[] => {
  if (!tags) return [];

  return tags.filter(tag => tag.rootWorkspace === workspaceId);
};

export const checkIfSelectedNewComponentParentDoesNotExist = async (
  surveyProperties: APISurveyAttributes | BaseReferenceQuestionProperties
) => {
  const { allowComponentCreation, newComponentParentId } = surveyProperties;
  if (!newComponentParentId || !allowComponentCreation) {
    return false;
  }
  const query = composeSearchQuery({
    attributes: [
      {
        attribute: 'id',
        value: newComponentParentId,
      },
    ],
  });
  const result = await searchService.componentSearch(query);

  if (isArdoqError(result)) {
    logError(result);
    return true;
  }
  const { results } = result;
  return !results.length;
};

export const isReadonlyField = (
  fieldName: string,
  workspaceId: string | null
): boolean => {
  return (
    (workspaceId &&
      fieldInterface.isExternallyManagedWithinWorkspace(
        fieldName,
        workspaceId
      )) ||
    isCalculatedField(fieldName) ||
    fieldName === 'component-key'
  );
};

const isCalculatedField = (fieldName: string) => {
  const field = fieldInterface.getByName(fieldName, {
    acrossWorkspaces: true,
    includeTemplateFields: false,
  });

  if (!field) return false;

  return Boolean(field.calculatedFieldSettings);
};

const componentTypeIsHierarchyRoot = (
  model: Model,
  componentTypeName: string
) => {
  // If model is not flexible and parent is not set, then the selected component type better be the top level type!!
  return Object.values(modelInterface.getTypeHierarchy(model)).find(
    entity => entity.name === componentTypeName
  );
};

const questionIsRedundant = (
  surveyQuestionWrapper: MaybePersistedSurvey,
  question: AllPossibleQuestions
): SurveyQuestionError | undefined => {
  if (!surveyQuestionWrapper.workspace) return;
  const workspace = Workspaces.collection.get(surveyQuestionWrapper.workspace);
  // It's too early to decide if parent is missing if component type and workspace is not yet selected
  if (!workspace || !surveyQuestionWrapper.componentTypeName) {
    return;
  }
  const model = workspace.getModel()!;
  if (model.isFlexible()) {
    return;
  }
  if (
    componentTypeIsHierarchyRoot(
      model,
      surveyQuestionWrapper.componentTypeName
    ) &&
    isAttributeQuestion(question) &&
    question.properties.attributeName === 'parent'
  ) {
    return SurveyQuestionError.PARENT_NOT_ALLOWED;
  }
};

export const fieldList = (
  isComponent: boolean,
  typeId?: string,
  modelId?: string
) => {
  if (!typeId || !modelId) return [];
  return dateRangeOperations
    .mergeDateTimeFieldsToDateRangeFields(
      (isComponent
        ? Fields.collection.getByComponentType(typeId, modelId)
        : Fields.collection.getByReferenceType(typeId, modelId)
      ).map(field => field.toJSON())
    )
    .fields.map(field =>
      dateRangeOperations.isDateRangeField(field)
        ? {
            ...field,
            name: `${field.dateTimeFields.start.name},${field.dateTimeFields.end.name}`,
          }
        : field
    );
};

export const hasQuestionValidationOfSeverity = (
  validations: SurveyQuestionValidations | undefined,
  severity: SurveyQuestionValidation['severity']
): boolean => {
  if (!validations) return false;
  return (
    validations.questionValidations.some(
      validation => validation.severity === severity
    ) ||
    (validations.subQuestionValidations &&
      validations.subQuestionValidations.some(validations =>
        hasQuestionValidationOfSeverity(validations, severity)
      )) ||
    false
  );
};

export const hasComponentType = (
  componentTypeName?: string | null,
  selectedTypeId?: string
) => {
  if (!selectedTypeId && !componentTypeName)
    return SurveyQuestionError.COMPONENT_TYPE_MISSING;
  if (!selectedTypeId && componentTypeName)
    return SurveyQuestionError.SELECTED_COMPONENT_TYPE_DOES_NOT_EXIST;
  if (componentTypeName) return;
  return SurveyQuestionError.COMPONENT_TYPE_MISSING;
};

const getComponentTypeId = (
  workspaceId: ArdoqId,
  componentTypeName: string
) => {
  return workspaceInterface.getNameToComponentTypeId(
    [workspaceId],
    componentTypeName
  )[workspaceId];
};

export const getComponentTypeFields = (
  workspace: Workspace,
  componentTypeName: string
) => {
  const modelId = workspaceInterface.getWorkspaceModelId(workspace.id);
  if (!modelId) return [];
  const componentTypeId = getComponentTypeId(workspace.id, componentTypeName);
  if (typeof componentTypeId !== 'string') return [];
  return fieldInterface.getFieldsByComponentType(componentTypeId, modelId);
};

const referenceQuestionHasComponentType = (q: AllPossibleQuestions) => {
  if (!isReferenceQuestion(q)) return;
  const { componentTypeName } = q.properties;
  const { selectedTypeId } = getComponentTypeAndModelId(
    q.properties.workspaceId,
    componentTypeName
  );

  return hasComponentType(componentTypeName, selectedTypeId);
};

const referenceQuestionHasWorkspace = (q: AllPossibleQuestions) => {
  if (!isReferenceQuestion(q)) return;
  const { selectedModelId } = getComponentTypeAndModelId(
    q.properties.workspaceId,
    q.properties.componentTypeName
  );
  if (selectedModelId) return;
  const { workspaceId } = q.properties;
  if (workspaceId) return;
  return SurveyQuestionError.WORKSPACE_MISSING;
};

const tagQuestionIsMissingTags = (
  q: AllPossibleQuestions,
  surveyAttributes: MaybePersistedSurvey,
  tags: APITagAttributes[] | null
) => {
  if (
    !isTagQuestion(q) ||
    !surveyAttributes.workspace ||
    getTagsOfWorkspace(surveyAttributes.workspace, tags).length
  )
    return;
  return SurveyQuestionError.WORKSPACE_TAGS_MISSING;
};

const referenceQuestionTypeError = (
  permissionsContext: PermissionContext,
  q: AllPossibleQuestions,
  referenceTypeId?: string | number
) => {
  if (!isReferenceQuestion(q)) return;
  const workspaceId = q.properties.workspaceId;
  const referenceAndWorkspaceSelected =
    q.properties.referenceTypeName && workspaceId;
  if (!referenceAndWorkspaceSelected)
    return SurveyQuestionError.REFERENCE_TYPE_MISSING;

  const hasNoAccessToReferenceWorkspace = currentUserHasNoAccessToWorkspace(
    permissionsContext,
    workspaceId
  );

  const hasNoEditAccesstoReferenceWorkspace =
    currentUserHasNoEditAccessToWorkspace(permissionsContext, workspaceId);

  if (hasNoAccessToReferenceWorkspace)
    return SurveyQuestionWarning.NO_READ_ACCESS_TO_SELECTED_REFERENCE_TYPE;

  if (hasNoEditAccesstoReferenceWorkspace)
    return SurveyQuestionWarning.NO_EDIT_ACCESS_TO_SELECTED_REFERENCE_TYPE;

  if (referenceTypeId === undefined)
    return SurveyQuestionError.SELECTED_REFERENCE_TYPE_DOES_NOT_EXIST;
};

const referenceQuestionParentIsNeededOrInvalid = async (
  surveyQuestion: AllPossibleQuestions
) => {
  if (!isReferenceQuestion(surveyQuestion)) return;
  if (
    surveyQuestion.properties.allowComponentCreation &&
    surveyQuestion.properties.workspaceId &&
    surveyQuestion.properties.componentTypeName
  ) {
    const workspace = Workspaces.collection.get(
      surveyQuestion.properties.workspaceId
    );
    if (!workspace) return;
    const model = workspace.getModel()!;
    if (surveyQuestion.properties.newComponentParentId) {
      const missingComponent =
        await checkIfSelectedNewComponentParentDoesNotExist(
          surveyQuestion.properties
        );

      if (missingComponent) return SurveyQuestionError.REFERENCE_PARENT_INVALID;
    }
    if (
      !model.isFlexible() &&
      !surveyQuestion.properties.newComponentParentId &&
      !componentTypeIsHierarchyRoot(
        model,
        surveyQuestion.properties.componentTypeName
      )
    )
      return SurveyQuestionError.REFERENCE_PARENT_MISSING;
  }
};

const questionHasInvalidRangeRules = (surveyQuestion: AllPossibleQuestions) => {
  if (isReferenceQuestion(surveyQuestion) || isTagQuestion(surveyQuestion)) {
    const validators = surveyQuestion.validators || [];

    const rangeValidator: SurveyQuestionValidator | undefined =
      validators.find(isRangeValidator);
    if (!rangeValidator) return;
    if (
      isNumber(rangeValidator?.max) &&
      isNumber(rangeValidator?.min) &&
      rangeValidator.max < rangeValidator.min
    )
      return SurveyQuestionError.RANGE_RULES_INVALID;
  }
};

const referenceQuestionHasPreventCreationAndDeletionithoutSubQuestions = (
  surveyQuestion: AllPossibleQuestions
) => {
  if (isReferenceQuestion(surveyQuestion)) {
    const hasSubQuestions =
      surveyQuestion.properties.questions &&
      surveyQuestion.properties.questions.some(
        q =>
          q.type === SurveyQuestionType.FIELD ||
          q.type === SurveyQuestionType.ATTRIBUTE
      );

    if (
      !hasSubQuestions &&
      surveyQuestion.properties.preventReferenceModification
    )
      return SurveyQuestionError.PREVENTING_REFERENCE_MODIFICATION_REQUIRES_SUBQUESTIONS;
  }
};

const referenceQuestionHasCreateMultipleWithoutSubQuestions = (
  surveyQuestion: AllPossibleQuestions
) => {
  if (isReferenceQuestion(surveyQuestion)) {
    const hasSubQuestions =
      surveyQuestion.properties.questions &&
      surveyQuestion.properties.questions.some(
        q =>
          q.type === SurveyQuestionType.FIELD ||
          q.type === SurveyQuestionType.ATTRIBUTE
      );

    if (!hasSubQuestions && surveyQuestion.properties.allowMultipleReferences)
      return SurveyQuestionError.MULTIPLE_REFERENCES_REQUIRES_SUBQUESTIONS;
  }
};

const referenceQuestionInvalidFieldIdentifier = (
  surveyQuestion: AllPossibleQuestions
) => {
  if (!isReferenceQuestion(surveyQuestion)) return;
  const identifyingField = surveyQuestion.properties.additionalIdentifier;
  if (!identifyingField) return;

  const componentTypeName = surveyQuestion.properties.componentTypeName;
  const selectedWorkspace = surveyQuestion.properties.workspaceId
    ? Workspaces.collection.get(surveyQuestion.properties.workspaceId)
    : undefined;

  if (!selectedWorkspace || !componentTypeName) return;
  const componentTypeFields = getComponentTypeFields(
    selectedWorkspace,
    componentTypeName
  );

  const foundField =
    identifyingField &&
    componentTypeFields.find(field => field.name === identifyingField);

  if (!foundField)
    return SurveyQuestionError.IDENTIFYING_FIELD_MISSING_OR_INVALID;
};

const attributes = [
  {
    label: 'Name',
    attributeName: 'name',
    isComponent: true,
  },
  {
    label: 'Parent',
    attributeName: 'parent',
    isComponent: true,
  },
  {
    label: 'Description',
    attributeName: 'description',
    isComponent: true,
    isReference: true,
  },
  {
    label: 'Display Text',
    attributeName: 'displayText',
    isReference: true,
  },
  {
    label: 'Ardoq ID',
    attributeName: 'component-key',
    isComponent: true,
  },
];

export const componentAttributes = attributes.filter(
  ({ isComponent }) => isComponent
);

export const referenceAttributes = attributes.filter(
  ({ isReference }) => isReference
);

const attributeIsUnavailable = (
  question: AllPossibleQuestions,
  isReferenceQuestion: boolean
) => {
  if (!isAttributeQuestion(question)) return;
  const availableAttributes = isReferenceQuestion
    ? referenceAttributes
    : componentAttributes;

  const foundAttribute = availableAttributes.find(
    attribute => attribute.attributeName === question.properties.attributeName
  );

  if (!foundAttribute) return SurveyQuestionError.FIELD_NOT_AVAILABLE;
};

const fieldIsUnavailable = (
  fields: APIFieldAttributes[],
  question: AllPossibleQuestions
) => {
  if (isFieldQuestion(question) || isHiddenFieldQuestion(question)) {
    const foundField =
      question.properties.fieldName &&
      fields.find(field => field.name === question.properties.fieldName);

    if (question.properties.fieldName && !foundField) {
      return SurveyQuestionError.FIELD_NOT_AVAILABLE;
    }
  }
};

const fieldNotSelected = (question: AllPossibleQuestions) => {
  if (
    (isFieldQuestion(question) || isHiddenFieldQuestion(question)) &&
    !question.properties.fieldName
  ) {
    return SurveyQuestionError.FIELD_NOT_SELECTED;
  }
  if (isAttributeQuestion(question) && !question.properties.attributeName) {
    return SurveyQuestionError.FIELD_NOT_SELECTED;
  }
};

export const isUnsetCondition = (
  condition: ValidDisplayConditions
): condition is UnsetDisplayCondition =>
  condition && (condition as UnsetDisplayCondition).type === 'unset';

const isUnsetType = (condition: ValidDisplayConditions) =>
  condition.type === 'unset';

const isCheckboxType = (
  condition: ValidDisplayConditions
): condition is CheckboxDisplayCondition =>
  !isUnsetCondition(condition) &&
  (condition.value === true || condition.value === false);

const isListType = (
  condition: ValidDisplayConditions
): condition is ListDisplayCondition =>
  !isUnsetCondition(condition) && typeof condition.value === 'string';

const matchesAnAllowedValue = (
  condition: ValidDisplayConditions,
  allowedValues: string[]
) => isListType(condition) && allowedValues.includes(condition.value);

const validateDisplayConditions = (
  displayConditions: ValidDisplayConditions[],
  fields: APIFieldAttributes[],
  parentQuestionName?: string
) => {
  const field = fields.find(field => field.name === parentQuestionName);
  if (!field?.type) return SurveyQuestionError.FIELD_NOT_SELECTED;

  switch (field.type) {
    case APIFieldType.LIST: {
      const allowedValues = fieldInterface.getAllowedValues(field._id);
      if (!allowedValues?.length) return false;
      return displayConditions.every(
        condition =>
          isUnsetType(condition) ||
          matchesAnAllowedValue(condition, allowedValues)
      );
    }
    case APIFieldType.CHECKBOX:
      return displayConditions.every(
        condition => isUnsetType(condition) || isCheckboxType(condition)
      );
    default:
      return false;
  }
};

const getHiddenFieldAnswerError = (
  question: AllPossibleQuestions,
  fields: APIFieldAttributes[]
) => {
  if (question.type !== SurveyQuestionType.HIDDEN_FIELD) {
    return undefined;
  }
  const fieldType = fields.find(
    field => field.name === question.properties.fieldName
  )?.type;
  const resetValue = question.properties.resetValue;
  switch (fieldType) {
    case APIFieldType.LIST:
      if (resetValue === undefined) {
        return SurveyQuestionError.INVALID_HIDDEN_ANSWER_VALUE;
      }
      break;
    case APIFieldType.SELECT_MULTIPLE_LIST:
      if (resetValue === undefined || resetValue.length === 0) {
        return SurveyQuestionError.INVALID_HIDDEN_ANSWER_VALUE;
      }
      break;
    case APIFieldType.TEXT:
    case APIFieldType.TEXT_AREA:
    case APIFieldType.URL:
      if (resetValue === undefined || resetValue === '') {
        return SurveyQuestionError.INVALID_HIDDEN_ANSWER_VALUE;
      }
      break;
    case APIFieldType.NUMBER:
      if (isNaN(resetValue) || numberValidator(resetValue)) {
        return SurveyQuestionError.INVALID_HIDDEN_ANSWER_VALUE;
      }
      break;
    case APIFieldType.EMAIL:
      if (resetValue === undefined || emailValidator(resetValue)) {
        return SurveyQuestionError.INVALID_HIDDEN_ANSWER_VALUE;
      }
      break;
    // Missing cases are CHECKBOX, DATE_TIME, USER and DATE_RANGE
    // CHECKBOX, DATE_TIME and USER are never invalid
    // undefined DATE_TIME or USER leads to automatic value(current time/user)
    // Date range is not currently supported for hidden fields
    default:
      return;
  }
};

const dependentQuestionHasInvalidDisplayConditions = (
  question: AllPossibleQuestions,
  fields: APIFieldAttributes[],
  parentQuestion?: AllPossibleQuestions
) => {
  if (!isDependentQuestion(question) || !isFieldQuestion(parentQuestion))
    return;
  if (
    !isReferenceQuestion(question) &&
    !isAttributeQuestion(question) &&
    !isFieldQuestion(question)
  ) {
    return;
  }

  if (!question.properties.displayConditions?.length)
    return SurveyQuestionError.DISPLAY_CONDITIONS_MISSING;

  const parentQuestionName = parentQuestion.properties.fieldName;
  const areDisplayConditionsValid = validateDisplayConditions(
    question.properties.displayConditions,
    fields,
    parentQuestionName
  );

  if (!areDisplayConditionsValid)
    return SurveyQuestionError.DISPLAY_CONDITIONS_INVALID;
};

const getAppropriateWorksaceIdAndComponentTypeName = ({
  question,
  survey,
  subQuestionIsReferenceQuestion,
}: {
  question?: AllPossibleQuestions;
  survey: MaybePersistedSurvey;
  subQuestionIsReferenceQuestion: boolean;
}) => {
  if (subQuestionIsReferenceQuestion && isReferenceQuestion(question)) {
    if (question.properties.outgoing) {
      return {
        workspaceId: survey.workspace,
        componentTypeName: survey.componentTypeName,
      };
    }
    return {
      workspaceId: question.properties.workspaceId,
      componentTypeName: question.properties.componentTypeName,
    };
  }
  return {
    workspaceId: survey.workspace,
    componentTypeName: survey.componentTypeName,
  };
};

export const getQuestionValidations = async ({
  question,
  survey,
  tags,
  permissionsContext,
  subQuestionIsReferenceQuestion = false,
}: {
  question?: AllPossibleQuestions;
  survey: MaybePersistedSurvey;
  tags: APITagAttributes[] | null;
  permissionsContext: PermissionContext;
  subQuestionIsReferenceQuestion?: boolean;
}): Promise<SurveyQuestionValidations[]> => {
  const { workspaceId, componentTypeName } =
    getAppropriateWorksaceIdAndComponentTypeName({
      question,
      survey,
      subQuestionIsReferenceQuestion,
    });
  const { selectedTypeId, selectedModelId } = getComponentTypeAndModelId(
    workspaceId,
    componentTypeName
  );

  const referenceData = isReferenceQuestion(question)
    ? getReferenceData({
        surveyWorkspaceId: survey.workspace,
        surveyComponentTypeId: selectedTypeId,
        outgoing: question.properties.outgoing,
        workspaceId: question.properties.workspaceId,
        componentTypeName: question.properties.componentTypeName,
        referenceTypeName: question.properties.referenceTypeName ?? undefined,
      })
    : undefined;

  const fields = selectedTypeId
    ? fieldList(
        !subQuestionIsReferenceQuestion,
        !subQuestionIsReferenceQuestion
          ? selectedTypeId
          : String(referenceData?.referenceTypeId),
        selectedModelId
      )
    : [];

  const questionToErrors = async (
    q: AllPossibleQuestions,
    surveyAttributes: MaybePersistedSurvey,
    tags: APITagAttributes[] | null,
    permissionsContext: PermissionContext
  ): Promise<SurveyQuestionValidation[]> => {
    const { selectedTypeId } =
      isReferenceQuestion(q) && q.properties.outgoing
        ? getComponentTypeAndModelId(
            q.properties.workspaceId,
            q.properties.componentTypeName
          )
        : getComponentTypeAndModelId(
            surveyAttributes.workspace,
            surveyAttributes.componentTypeName
          );

    const referenceData = isReferenceQuestion(q)
      ? getReferenceData({
          surveyWorkspaceId: surveyAttributes.workspace,
          surveyComponentTypeId: selectedTypeId,
          outgoing: q.properties.outgoing,
          workspaceId: q.properties.workspaceId,
          componentTypeName: q.properties.componentTypeName,
          referenceTypeName: q.properties.referenceTypeName ?? undefined,
        })
      : undefined;

    const getReferenceQuestionValidations = async () => {
      const referenceQuestionValidations = [
        referenceQuestionHasWorkspace(q),
        referenceQuestionHasComponentType(q),
        referenceQuestionTypeError(
          permissionsContext,
          q,
          referenceData?.referenceTypeId
        ),
        await referenceQuestionParentIsNeededOrInvalid(q),
        referenceQuestionInvalidFieldIdentifier(q),
        referenceQuestionHasCreateMultipleWithoutSubQuestions(q),
        referenceQuestionHasPreventCreationAndDeletionithoutSubQuestions(q),
      ]
        .map(maybeSurveyQuestionValidation)
        .filter(ExcludeFalsy);

      if (
        referenceQuestionValidations.find(
          validation =>
            validation.message === SurveyQuestionError.WORKSPACE_MISSING ||
            validation.message ===
              SurveyQuestionWarning.NO_READ_ACCESS_TO_SELECTED_REFERENCE_TYPE
        )
      ) {
        // Drop component type & Reference type errors if workspace is missing.
        return referenceQuestionValidations.filter(
          v =>
            ![
              SurveyQuestionError.COMPONENT_TYPE_MISSING,
              SurveyQuestionError.SELECTED_COMPONENT_TYPE_DOES_NOT_EXIST,
              SurveyQuestionError.SELECTED_REFERENCE_TYPE_DOES_NOT_EXIST,
              SurveyQuestionError.REFERENCE_TYPE_MISSING,
            ].includes(v.message as SurveyQuestionError)
        );
      }
      return referenceQuestionValidations;
    };

    return [
      fieldIsUnavailable(fields, q),
      attributeIsUnavailable(q, isReferenceQuestion(question)),
      fieldNotSelected(q),
      getHiddenFieldAnswerError(q, fields),
      dependentQuestionHasInvalidDisplayConditions(q, fields, question),
      tagQuestionIsMissingTags(q, surveyAttributes, tags),
      questionHasInvalidRangeRules(q),
      survey.workspace ? questionIsRedundant(survey, q) : undefined,
    ]
      .map(maybeSurveyQuestionValidation)
      .concat(await getReferenceQuestionValidations())
      .filter(ExcludeFalsy);
  };

  const questions =
    isReferenceQuestion(question) || isConditionalQuestion(question)
      ? question.properties.questions
      : (survey.questions as AllPossibleQuestions[]);
  try {
    return await Promise.all(
      (questions || []).map(async q => {
        const subQuestionValidations =
          isReferenceQuestion(q) || isConditionalQuestion(q)
            ? await getQuestionValidations({
                question: q,
                survey,
                tags,
                permissionsContext,
                subQuestionIsReferenceQuestion: isReferenceQuestion(q),
              })
            : undefined;

        return {
          questionValidations: await questionToErrors(
            q,
            survey,
            tags,
            permissionsContext
          ),
          subQuestionValidations,
        };
      })
    );
  } catch (error) {
    logError(error as Error);
    return [];
  }
};

const maybeSurveyQuestionValidation = (
  message?: SurveyQuestionError | SurveyQuestionWarning
): SurveyQuestionValidation | undefined =>
  message
    ? {
        message,
        severity: message in SurveyQuestionWarning ? 'warning' : 'error',
      }
    : undefined;

export const isRangeValidator = (
  validator: SurveyQuestionValidator
): validator is SurveyQuestionValidator => validator.type === 'range';

export const isRequiredValidator = (
  validator: SurveyQuestionValidator
): validator is SurveyQuestionValidator => validator.type === 'required';

const isDuplicateFieldQuestion = (
  type: SurveyQuestionType,
  name: string,
  questions?: AllPossibleQuestions[]
): boolean => {
  return Boolean(
    questions?.find(existingQuestion => {
      if (
        existingQuestion.type === type &&
        isAttributeQuestion(existingQuestion)
      ) {
        return name === existingQuestion.properties.attributeName;
      }
      if (
        (isFieldQuestion(existingQuestion) ||
          isHiddenFieldQuestion(existingQuestion)) &&
        type === SurveyQuestionType.FIELD
      ) {
        return name === existingQuestion.properties.fieldName;
      }
      if (isConditionalQuestion(existingQuestion)) {
        return isDuplicateFieldQuestion(
          type,
          name,
          existingQuestion.properties.questions
        );
      }
    })
  );
};

export const isFieldSelectedOrNotDuplicated = (
  option: SurveyTypeOption,
  questions?: AllPossibleQuestions[],
  selectedField?: string
) =>
  option.value === selectedField ||
  !isDuplicateFieldQuestion(option.type, option.value, questions);

export const getSortedFields = (
  fields: APIFieldAttributes[],
  questions?: AllPossibleQuestions[],
  selectedField?: string
): SurveyTypeOption[] => {
  const locale = getCurrentLocale();

  return [
    ...fields
      .filter(field => fieldInterface.isFieldAllowedInSurvey(field._id))
      .map(field => ({
        label: field.label,
        value: field.name,
        type: SurveyQuestionType.FIELD as const,
        fieldType: field.type,
        description:
          markdownToTextPreview(field.description ?? '') ?? undefined,
      })),
  ]
    .filter(opt =>
      isFieldSelectedOrNotDuplicated(opt, questions, selectedField)
    )
    .sort((a, b) => localeCompare(a.label, b.label, locale));
};

export const questionValidationsHasMessage = (
  validations: SurveyQuestionValidations | undefined,
  message: SurveyQuestionError | SurveyQuestionWarning
): boolean => {
  return (
    validations?.questionValidations.some(
      validation => validation.message === message
    ) || false
  );
};

export const getQuestionValidationMessageIfExists = (
  validations: SurveyQuestionValidations | undefined,
  message: SurveyQuestionError | SurveyQuestionWarning
): SurveyQuestionError | SurveyQuestionWarning | undefined => {
  return validations?.questionValidations.find(
    validation => validation.message === message
  )?.message;
};

export const getFieldQuestionErrors = (
  question: PersistedSurveyQuestion | UnpersistedSurveyQuestion,
  questionValidations: SurveyQuestionValidations,
  options: SurveyTypeOption[],
  selectedField?: string
) => {
  const errors = [];

  if (options.length === 0 && !selectedField) {
    errors.push('All of the available fields are already added to this survey');
  }

  if (
    questionValidationsHasMessage(
      questionValidations,
      SurveyQuestionError.FIELD_NOT_AVAILABLE
    )
  ) {
    if (isFieldQuestion(question) || isHiddenFieldQuestion(question)) {
      errors.push(
        `The selected field (${question.properties.fieldName}) is not available`
      );
    }
    if (isAttributeQuestion(question)) {
      errors.push(
        `The selected attribute (${question.properties.attributeName}) is not available`
      );
    }
  }

  if (
    questionValidationsHasMessage(
      questionValidations,
      SurveyQuestionError.PARENT_NOT_ALLOWED
    )
  ) {
    errors.push(`The selected component type cannot have a parent`);
  }

  if (
    questionValidationsHasMessage(
      questionValidations,
      SurveyQuestionError.FIELD_NOT_SELECTED
    )
  ) {
    errors.push(`This field is required`);
  }

  return errors.length ? errors.join(', ') : undefined;
};

export const getHiddenFieldAnswerErrors = (
  questionValidations: SurveyQuestionValidations,
  fieldLabel?: string,
  fieldType?: APIFieldType
) => {
  return questionValidationsHasMessage(
    questionValidations,
    SurveyQuestionError.INVALID_HIDDEN_ANSWER_VALUE
  )
    ? `Invalid value for ${fieldLabel ?? 'hidden field'} (${
        fieldType ?? 'unknown'
      }).`
    : undefined;
};

export const getQuestionId = (question: AllPossibleQuestions) => {
  return '_id' in question ? question._id : question.key;
};

export const getBarColor = (questionType: SurveyQuestionType) => {
  switch (questionType) {
    case SurveyQuestionType.ATTRIBUTE:
    case SurveyQuestionType.FIELD:
      return colors.blue50;
    case SurveyQuestionType.REFERENCE:
      return colors.brand50;
    case SurveyQuestionType.HIDDEN_FIELD:
      return colors.grey80;
    case SurveyQuestionType.TEXT:
      return colors.grey50;
    case SurveyQuestionType.TAG:
      return colors.purple50;
    default:
      return colors.blue50;
  }
};
export const getSubtitle = (question: AllPossibleQuestions) => {
  let subtitle;
  if (
    question.type === SurveyQuestionType.FIELD ||
    question.type === SurveyQuestionType.ATTRIBUTE
  ) {
    subtitle = 'Field question';
  } else if (question.type === SurveyQuestionType.REFERENCE) {
    subtitle = 'Reference question';
  } else if (question.type === SurveyQuestionType.TEXT) {
    subtitle = 'Text section';
  } else if (question.type === SurveyQuestionType.HIDDEN_FIELD) {
    subtitle = 'Hidden field';
  } else if (question.type === SurveyQuestionType.TAG) {
    subtitle = 'Tag question';
  }
  if (
    isFieldQuestion(question) ||
    isAttributeQuestion(question) ||
    isHiddenFieldQuestion(question)
  ) {
    const name = isAttributeQuestion(question)
      ? question.properties.attributeName
      : question.properties.fieldName;
    if (name) {
      if (dateRangeOperations.fieldNameIsPartOfDateRangeField(name)) {
        subtitle += ` (${dateRangeOperations.extractDateRangeFieldName(name)})`;
      } else {
        subtitle += ` (${name})`;
      }
    }
  } else if (
    isReferenceQuestion(question) &&
    question.properties.componentTypeName &&
    !!question.properties.workspaceId &&
    workspaceAccessControlInterface.canAccessWorkspace(
      currentUserInterface.getPermissionContext(),
      question.properties.workspaceId
    )
  ) {
    subtitle += ` (${question.properties.outgoing ? 'to' : 'from'} '${
      question.properties.componentTypeName
    }')`;
  }
  return subtitle;
};

export const hasAISuggestions = (
  hasReferenceSuggestionsFeature: boolean,
  aiSuggestionsEnabled: boolean | undefined
) => hasReferenceSuggestionsFeature && aiSuggestionsEnabled === true;

export const questionHasBeenInteractedWith = (
  fieldHasBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith'],
  question: AllPossibleQuestions
): boolean => {
  return Object.values(
    fieldHasBeenInteractedWith.questions[getQuestionId(question)] ?? {}
  ).some(val => val);
};

export const questionHasBeenClosed = (
  fieldHasBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith'],
  question: AllPossibleQuestions
): boolean => {
  return (
    fieldHasBeenInteractedWith.questions[getQuestionId(question)]
      ?.questionViewHasBeenClosed ?? false
  );
};

export const getQuestionQuestionFieldInteractionStatesAllInteracted = (
  questions: (
    | UnpersistedSurveyQuestion
    | PersistedSurveyQuestion
    | PartiallyPersistedConditionalQuestion
    | PartiallyPersistedReferenceQuestion
  )[]
): Record<string, QuestionInteractionState> => {
  const allQuestionIds = questions.flatMap(q => {
    const questionId = getQuestionId(q);
    return isConditionalQuestion(q)
      ? [questionId, ...q.properties.questions.map(getQuestionId)]
      : [questionId];
  });

  return allQuestionIds.reduce<Record<string, QuestionInteractionState>>(
    (acc, id) => {
      acc[id] = { questionViewHasBeenClosed: true };
      return acc;
    },
    {}
  );
};
