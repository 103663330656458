import { solidGrey1 } from '@ardoq/design-tokens';
import { Box } from '@ardoq/layout';
import { FC, ReactNode } from 'react';

export const WrappedSubdivisionComponentsTable: FC<{
  isWrapped: boolean;
  children?: ReactNode;
}> = ({ isWrapped, children }) =>
  isWrapped ? (
    <Box
      padding="xxsmall"
      borderRadius="r8"
      style={{ border: `${solidGrey1}` }}
    >
      {children}
    </Box>
  ) : (
    <>{children}</>
  );

export const WrappedSearchInputForSubdivisionComponentsTable: FC<{
  isWrapped: boolean;
  children?: ReactNode;
}> = ({ isWrapped, children }) =>
  isWrapped ? (
    <Box padding="medium" style={{ borderBottom: `${solidGrey1}` }}>
      {children}
    </Box>
  ) : (
    <Box paddingY="medium">{children}</Box>
  );

export const WrappedSubdivisionSummaryInformation: FC<{
  isWrapped: boolean;
  children?: ReactNode;
}> = ({ isWrapped, children }) =>
  isWrapped ? (
    <Box
      padding="medium"
      backgroundColor="bgSubtle"
      borderRadius="r8"
      marginTop="small"
    >
      {children}
    </Box>
  ) : (
    <>{children}</>
  );
