type StateToMainAppModulePropsArgs = {
  isViewpointMode: boolean;
  hasNewCoreJourneyFeature: boolean;
  visualizationShown: boolean;
};
export const stateToMainAppModuleProps = ({
  isViewpointMode,
  hasNewCoreJourneyFeature,
  visualizationShown,
}: StateToMainAppModulePropsArgs) => ({
  shouldShowNavBar:
    isViewpointMode && hasNewCoreJourneyFeature && !visualizationShown, // don't show navbar when a view is open.
});
