import { trackEvent } from 'tracking/tracking';
import { QueryBuilderSubquery } from '@ardoq/api-types';
import { getDefaultFieldsOperatorsAndTargetEntityTypesCountFromQuery } from './AdvancedSearch/utils';
import { filterOutEmptyObjectsOnObject } from '../utils/objectUtils';

interface TrackSearchQueried {
  isStoredQuery: boolean;
}

// Tracked when a user executes an advanced search query
export const trackQueriedAdvancedSearch = (metadata: TrackSearchQueried) =>
  trackEvent('Executed advanced search query', metadata, true);

export enum GremlinSearchSource {
  DB_WIDGET_EXPLORE = 'dashboard widget explore',
  EXPORT_EXCEL_RESULTS = 'export excel results',
  CALC_SEARCH_TEST = 'calculated search test',
  PARAMETER_SEARCH_TEST = 'parameter search test',
  DYNAMIC_SEARCH_TEST = 'dynamic search test',
  GREMLIN_QUERY_EDITOR = 'gremlin query editor',
  STORED_GREMLIN_QUERY = 'stored gremlin query',
  METAMODEL = 'metamodel',
  BROADCASTS = 'broadcasts',
}
type TrackQueriedGremlinMetadata = { searchSource: GremlinSearchSource };
/** Tracked when a user executes a gremlin query (not when paginated results are loaded) */
export const trackQueriedGremlinSearch = (
  metadata: TrackQueriedGremlinMetadata
) => trackEvent('Executed gremlin search query', metadata, true);

type TrackGremlinPaginationRequest = { startIndex: number };
/** Tracked when user selects a page in GremlinResults that isn't yet downloaded from the API,
 * prompting a new API call.
 */
export const trackGremlinPaginationRequest = (
  metadata: TrackGremlinPaginationRequest
) => trackEvent('Requested more paginated gremlin results', metadata);

const prependKeyToFieldsOperatorsAndTargetEntityTypes = ([prefix, usageCount]: [
  string,
  Record<string, number>,
]) =>
  Object.entries(usageCount).map(([fieldOperatorOrTargetEntityType, count]) => [
    `${prefix}_${fieldOperatorOrTargetEntityType}`,
    count,
  ]);

export const trackFieldsOperatorsAndTargetEntityTypesUsedInAdvancedSearchQuery =
  (query: QueryBuilderSubquery) => {
    const fieldsOperatorsTargetEntityTypes = filterOutEmptyObjectsOnObject(
      getDefaultFieldsOperatorsAndTargetEntityTypesCountFromQuery(query)
    );
    if (Object.keys(fieldsOperatorsTargetEntityTypes).length) {
      const trackingPayload = Object.fromEntries(
        Object.entries(fieldsOperatorsTargetEntityTypes).flatMap(
          prependKeyToFieldsOperatorsAndTargetEntityTypes
        )
      );
      trackEvent(
        'Fields and operators used in advanced search query',
        trackingPayload
      );
    }
  };
