import { useController } from 'react-hook-form';
import { TextArea } from '@ardoq/forms';
import { getMaxLengthWarnings } from './utils';

const MAX_DESCRIPTION_LENGTH = 150;

const DescriptionInput = () => {
  const {
    field: { onChange, value },
  } = useController({ name: 'description' });
  return (
    <TextArea
      onChange={onChange}
      value={value}
      label="Describe the contents of your Viewpoint"
      {...getMaxLengthWarnings(
        value,
        MAX_DESCRIPTION_LENGTH,
        'Long names may be truncated.'
      )}
      placeholder="Add description here..."
    />
  );
};

export default DescriptionInput;
