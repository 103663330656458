import {
  DashboardBuilderChartWidget,
  DashboardBuilderHeaderWidget,
  EditModeDashboardAttributes,
  NAME_FIELD,
  widgetOperations,
} from '@ardoq/dashboard';
import {
  APIFieldType,
  APIWidgetAttributes,
  ArdoqId,
  ConditionalFormattingLimit,
  DataSourceField,
  NumberWidgetConfiguration,
  NumberWidgetTrend,
  NumberWidgetTrendConfiguration,
  NumberWidgetTrendRangeType,
  RollingDateRange,
  SortOrder,
  TrendColorScheme,
  WidgetConfiguration,
  WidgetDataSourceEntity,
  WidgetDataSourceTypes,
  WidgetTypes,
} from '@ardoq/api-types';
import {
  filterQueryToApiReportFilterQuery,
  isAPIFieldTypeSortable,
} from '@ardoq/report-reader';
import { isEqual, pick } from 'lodash';
import { colors } from '@ardoq/design-tokens';
import { v4 as uuidv4 } from 'uuid';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';
import { IconName } from '@ardoq/icons';
import { StatusType, Tag } from '@ardoq/status-ui';
import { DashboardBuilder$State } from './DashboardBuilder$';
import { isCustomGremlinDatasourceValidForPieChart } from './widgetTypeValidation';
import { SURVEY_VALID_INVALID_FIELD_NAME } from './types';

const hasRequiredDataForPreview = (
  widget: Pick<
    DashboardBuilderChartWidget,
    'viewType' | 'datasource' | 'fields'
  >
): widget is Pick<
  APIWidgetAttributes,
  'viewType' | 'datasource' | 'fields'
> => {
  const { viewType, datasource, fields } = widget;
  return Boolean(
    viewType &&
      datasource?.sourceId &&
      !(viewType === WidgetTypes.TABLE && !fields.length) &&
      !(
        datasource?.sourceType === WidgetDataSourceTypes.SURVEY &&
        !fields.length
      )
  );
};

const getWidgetTypeSpecificAttributes = (
  widgetAttributes: DashboardBuilderChartWidget,
  availableFieldsForDataSource: DataSourceField[],
  selectedFields: DataSourceField[]
) => {
  if (widgetOperations.isPieChartData(widgetAttributes)) {
    const isCustomGremlinMapWidget = isCustomGremlinDatasourceValidForPieChart({
      datasource: widgetAttributes.datasource,
      selectedFields,
      availableFieldsForDataSource,
    });
    return {
      showTotal: widgetAttributes.showTotal,
      orderSelection: widgetAttributes.orderSelection,
      ...(isCustomGremlinMapWidget
        ? { fields: [NAME_FIELD, selectedFields[0].name] }
        : {}),
    };
  } else if (widgetOperations.isLineChartData(widgetAttributes)) {
    return {
      period: widgetAttributes.period,
      hasDynamicYAxis: widgetAttributes.hasDynamicYAxis,
      isAbbreviated: widgetAttributes.isAbbreviated,
    };
  } else if (widgetOperations.isStackedBarChartData(widgetAttributes)) {
    return {
      numberSlices: widgetAttributes.numberSlices,
      period: widgetAttributes.period,
      orderSelection: widgetAttributes.orderSelection,
    };
  } else if (widgetOperations.isNumberWidgetData(widgetAttributes)) {
    return {
      limits: widgetAttributes.limits?.map(({ name, above, color }) => ({
        name,
        above,
        color,
      })),
      resultLabel: widgetAttributes.resultLabel,
      isAbbreviated: widgetAttributes.isAbbreviated,
      trend: widgetAttributes.trend
        ? pick(widgetAttributes.trend, ['rangeType', 'rolling', 'colorScheme'])
        : undefined,
    };
  } else if (widgetOperations.isTableWidgetData(widgetAttributes)) {
    const sortableFields = selectedFields.filter(isSortableColumInTableWidget);

    const fallBackField = sortableFields.length
      ? sortableFields[sortableFields.length - 1]
      : undefined;
    return {
      sort: widgetAttributes.sort ?? fallBackField?.name,
      order:
        widgetAttributes.order ??
        (fallBackField?.type === APIFieldType.NUMBER
          ? SortOrder.DESC
          : SortOrder.ASC),
    };
  } else if (widgetOperations.isBarChartData(widgetAttributes)) {
    return {
      colorSelection: widgetAttributes.colorSelection,
      primaryColor: widgetAttributes.primaryColor,
      secondaryColor: widgetAttributes.secondaryColor,
      orderSelection: widgetAttributes.orderSelection,
    };
  }
  return {};
};

export const formatHeaderWidgetForSave = (
  widgetAttributes: DashboardBuilderHeaderWidget
) => ({
  ...(!widgetAttributes.isUnsaved ? { _id: widgetAttributes._id } : {}),
  viewType: widgetAttributes.viewType,
  content: widgetAttributes.content,
  layout: {
    x: widgetAttributes.layout.x,
    y: widgetAttributes.layout.y,
    w: widgetAttributes.layout.w,
    h: widgetAttributes.layout.h,
    i: widgetAttributes.layout.i,
  },
});

export const formatChartWidgetForSave = (
  widgetAttributes: DashboardBuilderChartWidget,
  availableFieldsForDataSource: DataSourceField[]
): WidgetConfiguration => {
  const layout = pick(widgetAttributes.layout, ['x', 'y', 'w', 'h', 'i']);
  const selectedFields: DataSourceField[] =
    widgetAttributes.fields
      ?.map(field =>
        availableFieldsForDataSource.find(
          availableField => availableField.name === field
        )
      )
      .filter(ExcludeFalsy) ?? [];

  const { _id, name, viewType, datasource } = widgetAttributes;

  const commonAttributes = {
    _id: widgetAttributes.isUnsaved ? undefined : _id,
    layout,
    name,
    viewType,
    fields: selectedFields.flatMap(field =>
      isDateRangeFieldType(field.type)
        ? [
            dateRangeOperations.toStartDateName(field.name),
            dateRangeOperations.toEndDateName(field.name),
          ]
        : field.name
    ),
    numberSlices: widgetOperations.hasConfigurableSlices(widgetAttributes)
      ? widgetAttributes.numberSlices
      : undefined,
    datasource,
    aggregate:
      widgetAttributes.viewType === WidgetTypes.PIE_CHART ||
      widgetAttributes.viewType === WidgetTypes.BAR_CHART ||
      widgetAttributes.viewType === WidgetTypes.STACKED_BAR_CHART
        ? undefined
        : widgetAttributes.aggregate,
    filters: widgetAttributes.filters
      ? filterQueryToApiReportFilterQuery(widgetAttributes.filters)
      : undefined,
  };

  return {
    ...commonAttributes,
    ...getWidgetTypeSpecificAttributes(
      widgetAttributes,
      availableFieldsForDataSource,
      selectedFields
    ),
  } as WidgetConfiguration;
};

export const createConditionalFormattingLimit =
  (): ConditionalFormattingLimit => ({
    above: 0,
    color: colors.black,
    name: '',
    id: uuidv4(),
  });

export const isDashboardNameValid = (dashboardName: string | undefined) =>
  dashboardName && dashboardName?.trim().length > 0;

/**
 * Validate dashboard
 */
export const isDashboardValid = (dashboard: EditModeDashboardAttributes) =>
  Boolean(
    isDashboardNameValid(dashboard.name) &&
      dashboard.widgets.every(
        w =>
          (widgetOperations.isChartWidgetData(w) &&
            widgetOperations.isValidChartWidgetData(w)) ||
          (widgetOperations.isHeaderWidgetData(w) &&
            widgetOperations.isValidHeaderWidgetData(w))
      )
  );

export const getAvailableFieldsForDataSource = (
  availableDataSources: WidgetDataSourceEntity[],
  selectedDataSource?: APIWidgetAttributes['datasource']
) =>
  availableDataSources.find(
    dataSource => selectedDataSource?.sourceId === dataSource.sourceId
  )?.availableFields ?? [];

export const hasAvailableDataSource = (
  availableDataSources: WidgetDataSourceEntity[],
  resourceId?: ArdoqId
) =>
  Boolean(
    resourceId &&
      availableDataSources.find(
        dataSource => resourceId === dataSource.sourceId
      )?.availableFields
  );

export const getWidgetWarnings = (
  widgets: EditModeDashboardAttributes['widgets']
) => widgets.filter(w => w.warning).map(({ warning }) => warning);

export const getWidgetValidationErrors = (
  widgets: EditModeDashboardAttributes['widgets']
) =>
  widgets
    .filter(w => w.validationError)
    .map(({ validationError }) => validationError);

export const getValidationErrorsFromWidget = (
  widgets: EditModeDashboardAttributes['widgets'],
  widgetId: string
) =>
  widgets
    .filter(w => w._id === widgetId)
    .map(({ validationError }) => validationError);

export function isSubset<T>(subset: T[], parent: T[]): boolean {
  return subset.every(el => parent.includes(el));
}

export const getSelectedFields = (
  selectedWidget: DashboardBuilderChartWidget,
  availableFields: DataSourceField[]
): DataSourceField[] =>
  selectedWidget.fields
    .map(field => availableFields.find(f => field === f.name))
    .filter(ExcludeFalsy)
    .filter(
      f =>
        !(
          selectedWidget.viewType !== WidgetTypes.TABLE &&
          (f.referenceTypeIncoming || f.referenceTypeOutgoing)
        )
    );

export const isSortableColumInTableWidget = ({
  field,
  custom,
  type,
  referenceTypeIncoming,
  referenceTypeOutgoing,
}: DataSourceField) =>
  Boolean(
    (field || custom) &&
      isAPIFieldTypeSortable(type) &&
      !(referenceTypeIncoming || referenceTypeOutgoing)
  );

export const getSortOrder = (
  selectedColumn: string | undefined,
  selectedFields: DataSourceField[]
) =>
  selectedFields.find(field => field.name === selectedColumn)?.type ===
  APIFieldType.NUMBER
    ? SortOrder.DESC
    : SortOrder.ASC;

export const MISSING_FIELD_FROM_DATA_SOURCE = 'MissingFieldFromDataSource';
export const dataSourceFieldToSelectOption = ({
  label,
  name,
  type,
  referenceTypeOutgoing,
  referenceTypeIncoming,
}: DataSourceField) => ({
  label: `${label ?? name}${type ? ` (${type})` : ''}`,
  value: name,
  rightIconName: referenceTypeOutgoing
    ? IconName.ARROW_FORWARD
    : referenceTypeIncoming
      ? IconName.ARROW_BACK
      : undefined,
  rightContent:
    name === MISSING_FIELD_FROM_DATA_SOURCE ? (
      <Tag
        statusType={StatusType.ERROR}
        label="Deleted"
        data-tooltip-text="This field has been deleted from the report.
    Clear it from your chart to save this dashboard."
      />
    ) : null,
});
/**
 * Goal of this function is to check that an attribute is different between the old widget state
 * and the new widget state in a way that we should fetch a new preview
 */
export const shouldGetPreview = (
  newWidgetState:
    | DashboardBuilderChartWidget
    | DashboardBuilderHeaderWidget
    | undefined,
  oldWidgetState:
    | DashboardBuilderChartWidget
    | DashboardBuilderHeaderWidget
    | undefined
): newWidgetState is DashboardBuilderChartWidget => {
  if (
    !(
      newWidgetState &&
      widgetOperations.isChartWidgetData(newWidgetState) &&
      hasRequiredDataForPreview(newWidgetState) &&
      oldWidgetState &&
      widgetOperations.isChartWidgetData(oldWidgetState)
    )
  ) {
    return false;
  }

  const isWidgetTypeChanged =
    newWidgetState.viewType !== oldWidgetState.viewType;

  const isDataSourceChanged =
    newWidgetState.datasource.sourceId !== oldWidgetState.datasource?.sourceId;

  const isAggregateChanged =
    newWidgetState.aggregate !== oldWidgetState.aggregate;

  const isLineChartPeriodChanged =
    widgetOperations.isTimeLineData(newWidgetState) &&
    widgetOperations.isTimeLineData(oldWidgetState) &&
    newWidgetState.period !== oldWidgetState.period;

  const shouldFetchMoreTableData =
    widgetOperations.isTableWidgetData(newWidgetState) &&
    newWidgetState.layout.h !== oldWidgetState.layout.h;

  const isTableSortOrOrderChanged =
    widgetOperations.isTableWidgetData(newWidgetState) &&
    widgetOperations.isTableWidgetData(oldWidgetState) &&
    (newWidgetState.sort !== oldWidgetState.sort ||
      newWidgetState.order !== oldWidgetState.order);

  const isChartOrderSelectionChanged =
    widgetOperations.hasConfigurableSlices(oldWidgetState) &&
    widgetOperations.hasConfigurableSlices(newWidgetState) &&
    newWidgetState.orderSelection !== oldWidgetState.orderSelection;

  const isTrendPercentageChanged =
    widgetOperations.isNumberWidgetData(oldWidgetState) &&
    widgetOperations.isNumberWidgetData(newWidgetState) &&
    !isEqual(
      pick(newWidgetState.trend, TREND_PROPS_THAT_SHOULD_TRIGGER_PREVIEW),
      pick(oldWidgetState.trend, TREND_PROPS_THAT_SHOULD_TRIGGER_PREVIEW)
    );

  return Boolean(
    isWidgetTypeChanged ||
      shouldFetchMoreTableData ||
      !isEqual(newWidgetState.fields, oldWidgetState.fields) ||
      !isEqual(newWidgetState.filters, oldWidgetState.filters) ||
      isDataSourceChanged ||
      isAggregateChanged ||
      isLineChartPeriodChanged ||
      isTableSortOrOrderChanged ||
      isChartOrderSelectionChanged ||
      isTrendPercentageChanged
  );
};

const getSelectedWidget = (state: DashboardBuilder$State) =>
  state.widgets.find(widget => widget._id === state.selectedWidgetId);
/**
 *   Only use when we know there is a selected chart widget.
 *   Throws and error if there is no selected widget or if the selected widget is a header widget
 */
export const getSelectedChartWidget = (
  state: DashboardBuilder$State
): DashboardBuilderChartWidget => {
  const selectedWidget = getSelectedWidget(state);
  if (!selectedWidget || widgetOperations.isHeaderWidgetData(selectedWidget)) {
    // With this here we don't need to cast the return value of the function.
    throw new Error(
      !selectedWidget
        ? 'getSelectedChartWidget was called when no widget was selected'
        : 'getSelectedChartWidget was called when a header widget was selected'
    );
  }
  return selectedWidget;
};

export const DEFAULT_TREND_CONFIGURATION: NumberWidgetTrendConfiguration &
  RollingDateRange = {
  rangeType: NumberWidgetTrendRangeType.ROLLING,
  rolling: { value: 7, unit: 'day' },
  colorScheme: TrendColorScheme.CLASSIC,
} as const;

export const getNewNumberWidgetConfiguration = (
  trend: boolean | NumberWidgetTrend | NumberWidgetTrendConfiguration
): NumberWidgetTrend | NumberWidgetTrendConfiguration | undefined => {
  if (trend === true) return DEFAULT_TREND_CONFIGURATION;
  if (trend === false) return undefined;

  return trend;
};

const TREND_PROPS_THAT_SHOULD_TRIGGER_PREVIEW: Array<
  keyof (NumberWidgetTrend & RollingDateRange)
> = ['rangeType', 'rolling'] as const;

export const getNewNumberWidgetTrend = (
  selectedWidget: NumberWidgetConfiguration
): NumberWidgetTrendConfiguration | undefined => {
  if (
    selectedWidget.datasource.sourceType === WidgetDataSourceTypes.SURVEY &&
    !selectedWidget.fields.includes(SURVEY_VALID_INVALID_FIELD_NAME) // survey-based widget trends can only work with valid/invalid field)
  ) {
    return undefined;
  }

  return selectedWidget.trend;
};
