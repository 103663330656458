import { reducer } from '@ardoq/rxbeach';
import { PerspectivesEditorState } from '../types';
import { notifyPerspectiveApplied } from 'perspective/actions';

const applyPerspective = (state: PerspectivesEditorState) => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    isAppliedFormattingIncluded: false,
  },
  groupsTab: {
    ...state.groupsTab,
    isAppliedGroupingIncluded: false,
  },
});

export const perspectiveAppliedReducer = reducer<PerspectivesEditorState>(
  notifyPerspectiveApplied,
  applyPerspective
);
