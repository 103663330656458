import { FC, useState } from 'react';
import BodyHeader from 'atomicComponents/Layout/BodyHeader';
import { formatDateTime } from '@ardoq/date-time';
import { FlyWheelTable, SelectableDatasourceTable } from '@ardoq/table';
import { Pagination, PaginationController } from '@ardoq/pagination';
import {
  SearchOverviewProps,
  SearchOverviewStoredQueryModel,
} from 'search/types';
import { dispatchAction } from '@ardoq/rxbeach';
import OverviewButton from './OverviewButton';
import { SearchPaneToLabel } from 'search/SearchTabContainer/types';
import NameCell from './NameCell';
import { getKebabMenuOptions, search } from './utils';
import { notifySearchOverviewPageNumberChange } from 'search/SearchTabContainer/actions';
import { SearchInput } from '@ardoq/forms';
import { s16, s32 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { SortOrder } from '@ardoq/api-types';
import { getCurrentLocale } from '@ardoq/locale';
import { contextInterface } from 'modelInterface/contextInterface';

const SearchInputWrapper = styled.div`
  margin: ${s32} ${s32} ${s16};
`;

export const SearchOverview: FC<SearchOverviewProps> = ({
  storedQueries,
  overviewType,
  searchOverviewPage,
  renderEmptyTableTitle = 'No reports found',
  canCreateGraphFilter,
}) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [selected, setSelected] = useState<string[]>([]);
  const selectedQueries = storedQueries.filter(({ _id }) =>
    selected.includes(_id)
  );
  return (
    <>
      <BodyHeader
        style={{ padding: `${s32} ${s32} 0` }}
        title={SearchPaneToLabel[overviewType]}
      >
        <OverviewButton
          overviewType={overviewType}
          canCreateGraphFilter={canCreateGraphFilter}
        />
      </BodyHeader>
      <SearchInputWrapper>
        <SearchInput value={searchPhrase} onValueChange={setSearchPhrase} />
      </SearchInputWrapper>
      <PaginationController
        dataSource={search(storedQueries, searchPhrase)}
        startPageNumber={searchOverviewPage}
        perPage={100}
        defaultSortById="name"
        defaultSortOrder={SortOrder.ASC}
        onPageNumberChange={searchOverviewPage =>
          dispatchAction(
            notifySearchOverviewPageNumberChange({
              searchOverviewType: overviewType,
              searchOverviewPage,
            })
          )
        }
        render={({
          currentPageNumber,
          perPage,
          totalResults,
          onPageSelect,
          currentPageDataSource,
          sortBy,
          sortById,
          sortOrder,
        }) => (
          <>
            <SelectableDatasourceTable
              fixedHeader
              selected={selected}
              setSelected={setSelected}
              style={{ padding: '0 32px' }}
              dataSource={currentPageDataSource}
              components={FlyWheelTable}
              intercomTarget="search overview table"
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'name',
                  valueRender: (
                    name: string,
                    storedQuery: SearchOverviewStoredQueryModel
                  ) => (
                    <NameCell
                      menuOptions={getKebabMenuOptions(
                        storedQuery,
                        contextInterface.getCurrentContext(),
                        selectedQueries,
                        () => setSelected([])
                      )}
                      storedQuery={storedQuery}
                    />
                  ),
                  onHeaderClick: () => sortBy('name'),
                  sortOrder: sortById === 'name' && sortOrder,
                },
                {
                  title: 'Last updated',
                  dataIndex: 'lastUpdated',
                  headerStyle: { width: 170 },
                  onHeaderClick: () => sortBy('lastUpdated'),
                  valueRender: value =>
                    formatDateTime(value, getCurrentLocale()),
                  sortOrder: sortById === 'lastUpdated' && sortOrder,
                },
                {
                  title: 'Type',
                  dataIndex: 'typeLabel',
                  headerStyle: { width: 200 },
                  onHeaderClick: () => sortBy('typeLabel'),
                  sortOrder: sortById === 'typeLabel' && sortOrder,
                },
              ]}
              renderEmptyTable={{ title: renderEmptyTableTitle }}
            />
            {(totalResults > perPage || currentPageDataSource.length === 0) && (
              <Pagination
                style={{ justifyContent: 'center', padding: '20px' }}
                currentPageNumber={currentPageNumber}
                perPage={perPage}
                totalResults={totalResults}
                onPageSelect={onPageSelect}
              />
            )}
          </>
        )}
      />
    </>
  );
};
