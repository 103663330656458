import { first } from 'lodash/fp';
import { orgUsersInterface } from 'modelInterface/orgUsers/orgUsersInterface';

export const sendEmailToAdmin = () => {
  const firstAdmin = first(orgUsersInterface.getOrgAdmins());

  const adminEmail = window.encodeURI(
    firstAdmin ? firstAdmin.email : 'support@ardoq.com'
  );
  window.location.href = `mailto:${adminEmail}`;
};
