import { popoverRegistry } from '@ardoq/popovers';
import {
  TEXT_OVERFLOW_POPOVER_ID,
  textOverflowPopoverRenderer,
} from '@ardoq/renderers';
import { diffMergeComponentAncestorsPopoverRenderer } from './ComponentsAncestorsPopover';
import { diffMergeComponentModelPopoverRenderer } from './ComponentsModelPopover';
import { diffMergeEntitiesListPopoverRenderer } from './EntitiesListPopover';
import {
  DIFF_MERGE_COMPONENT_HIERARCHY_POPOVER,
  DIFF_MERGE_COMPONENT_MODEL_POPOVER,
  DIFF_MERGE_ENTITIES_LIST_POPOVER,
  DIFF_MERGE_MISSING_PARENT_POPOVER,
  DIFF_MERGE_WORKSPACE_POPOVER,
} from './utils';
import { diffMergeWorkspacePopoverRenderer } from './WorkspacePopover';
import { diffMergeMissingParentPopoverRenderer } from 'components/DiffMergeTablePopover/MissingParentPopover';

export const registerDiffMergePopovers = () => {
  popoverRegistry.set(TEXT_OVERFLOW_POPOVER_ID, textOverflowPopoverRenderer);
  popoverRegistry.set(
    DIFF_MERGE_COMPONENT_MODEL_POPOVER,
    diffMergeComponentModelPopoverRenderer
  );
  popoverRegistry.set(
    DIFF_MERGE_COMPONENT_HIERARCHY_POPOVER,
    diffMergeComponentAncestorsPopoverRenderer
  );
  popoverRegistry.set(
    DIFF_MERGE_WORKSPACE_POPOVER,
    diffMergeWorkspacePopoverRenderer
  );
  popoverRegistry.set(
    DIFF_MERGE_ENTITIES_LIST_POPOVER,
    diffMergeEntitiesListPopoverRenderer
  );
  popoverRegistry.set(
    DIFF_MERGE_MISSING_PARENT_POPOVER,
    diffMergeMissingParentPopoverRenderer
  );
};
