import { colors } from '@ardoq/design-tokens';
import { Features } from '@ardoq/features';
import { AppModules } from 'appContainer/types';

const ICON_COLOR = colors.brand50;

export const FEATURE_KEY_SURVEYS = 'Surveys';
export const FEATURE_KEY_PRESENTATIONS = 'Presentations';

export const featureMappings = {
  [FEATURE_KEY_SURVEYS]: Features.SURVEYS,
  [FEATURE_KEY_PRESENTATIONS]: Features.PRESENTATIONS,
};

export const featureData = {
  [FEATURE_KEY_SURVEYS]: {
    iconName: 'icon_ardoq_surveys',
    iconColor: ICON_COLOR,
    title: 'Surveys',
    description: `
      Crowdsource information gathering and collaborate without the need for
      software training for your colleagues.
    `,
    appModule: AppModules.SURVEY_ADMIN,
  },
  [FEATURE_KEY_PRESENTATIONS]: {
    iconName: 'icon_ardoq_presentations',
    iconColor: ICON_COLOR,
    title: 'Presentations',
    description: `
      Compose stories that evolve with your data, and share them with the organization.
    `,
    appModule: AppModules.PRESENTATIONS,
  },
};
