import { connect, dispatchAction } from '@ardoq/rxbeach';
import currentOrganization$ from '../../streams/organizations/currentOrganization$';
import { APIOrganizationAttributes } from '@ardoq/api-types';
import { TextArea } from '@ardoq/forms';
import { useState } from 'react';
import { PrimaryButton } from '@ardoq/button';
import { updateOrganizationRequested } from '../../streams/organizations/actions';
import { Stack } from '@ardoq/layout';
import { PopoverPlacement, WithPopover } from '@ardoq/popovers';
import { WithPointerEvents } from '@ardoq/style-helpers';
import styled from 'styled-components';

const DEFAULT_BLOCKED_MESSAGE =
  'Your access to this platform has been restricted. Please contact your administrator if you believe this is a mistake.';
const SAVE_DISABLED_POPOVER_MESSAGE = 'Edit the message to enable saving.';

const AllowlistBlockedMessageForm = (attributes: APIOrganizationAttributes) => {
  const [message, setMessage] = useState(
    attributes.settings.ipAllowlistDeniedAccessMessage
  );

  const submit = () =>
    dispatchAction(
      updateOrganizationRequested({
        ...attributes,
        settings: {
          ...attributes.settings,
          ipAllowlistDeniedAccessMessage: message,
        },
      })
    );

  const isSubmitDisabled =
    message === attributes.settings.ipAllowlistDeniedAccessMessage;

  return (
    <FormContainer>
      <Stack gap="xlarge">
        <TextArea
          label="Message"
          value={message}
          placeholder={DEFAULT_BLOCKED_MESSAGE}
          onValueChange={setMessage}
          fixedHeight
        />
        <ButtonContainer>
          <WithPopover
            content={isSubmitDisabled && SAVE_DISABLED_POPOVER_MESSAGE}
            preferredPlacement={PopoverPlacement.TOP}
          >
            <WithPointerEvents>
              <PrimaryButton isDisabled={isSubmitDisabled} onClick={submit}>
                Save message
              </PrimaryButton>
            </WithPointerEvents>
          </WithPopover>
        </ButtonContainer>
      </Stack>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 660px;
`;

const ButtonContainer = styled.div`
  align-self: start;
`;

export default connect(AllowlistBlockedMessageForm, currentOrganization$);
