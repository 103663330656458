import {
  formatDateOnly,
  formatDateISO,
  formatDateTime,
  parseLocaleDateString,
} from '@ardoq/date-time';
import { DateTimePicker } from '@ardoq/date-time-picker';
import { GroupFieldLayout, RadioGroup } from '@ardoq/forms';
import { getCurrentLocale } from '@ardoq/locale';
import { useState } from 'react';
import { SelectionMode } from './types';
import { APIFieldType } from '@ardoq/api-types';
import { Stack } from '@ardoq/layout';

type DateTimeHiddenFieldProps = {
  value: any;
  type: APIFieldType;
  onChange: (value: any) => void;
  errorMessage?: string;
  onBlur?: () => void;
};

export const HiddenFieldDateTime = ({
  value,
  type,
  onChange,
  errorMessage,
  onBlur = () => null,
}: DateTimeHiddenFieldProps) => {
  const [selectedMode, setSelectedMode] = useState<SelectionMode>(
    value ? SelectionMode.MANUAL : SelectionMode.AUTOMATIC
  );

  const isDateOnly = type === APIFieldType.DATE_ONLY;

  const onModeChange = (mode: SelectionMode) => {
    if (mode === SelectionMode.AUTOMATIC) onChange(undefined);
    setSelectedMode(mode);
  };

  return (
    <Stack gap="small">
      <RadioGroup
        value={selectedMode}
        onValueChange={mode => {
          onModeChange(mode as SelectionMode);
        }}
        groupFieldLayout={GroupFieldLayout.HORIZONTAL}
        options={[
          {
            label: 'Automatic',
            value: SelectionMode.AUTOMATIC,
            helperText: 'Set to when the survey was last submitted',
          },
          {
            label: 'Set date',
            value: SelectionMode.MANUAL,
          },
        ]}
      />
      {selectedMode === SelectionMode.MANUAL && (
        <DateTimePicker
          customCloseHandlers={new Set([onBlur])}
          date={parseLocaleDateString(value, getCurrentLocale())}
          onChange={(newDate: Date | null) =>
            onChange(
              isDateOnly
                ? newDate && formatDateISO(newDate)
                : newDate && newDate.toISOString()
            )
          }
          config={{
            formatter: isDateOnly ? formatDateOnly : formatDateTime,
            locale: getCurrentLocale(),
            useTimePicker: !isDateOnly,
          }}
          errorMessage={errorMessage}
          warningMessage={value ? undefined : 'Please select a date'}
        />
      )}
    </Stack>
  );
};
