import { CSSProperties, forwardRef } from 'react';
import { TopBarButtonWithMenu, type TopBarButtonWithMenuProps } from '../atoms';
import { dispatchAction } from '@ardoq/rxbeach';
import { initMerge } from 'scope/merge/actions';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { Icon, IconName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import {
  ComponentWithDropdown,
  type ComponentWithDropdownComponentProps,
} from '@ardoq/dropdown-menu';
import { currentUserAccessControlInterface } from 'resourcePermissions/accessControlHelpers/currentUser';
import { IconBadge, StatusType } from '@ardoq/status-ui';
const ICON_NAME = IconName.SCENARIO_MERGE;
const ICON_STYLE: CSSProperties = { fill: colors.grey15 };

type ScenarioMergeButtonProps = {
  isScenarioInSync: boolean;
};
const ScenarioMergeButton = ({
  isScenarioInSync,
}: ScenarioMergeButtonProps) => (
  <ComponentWithDropdown
    Component={forwardRef<HTMLButtonElement, TopBarButtonWithMenuProps>(
      (props, ref) => (
        <TopBarButtonWithMenu
          {...props}
          isDisabled={!currentUserAccessControlInterface.canCurrentUserWrite()} // disable merge button for reader users
          ref={ref}
        />
      )
    )}
    componentProps={
      {
        tooltip: 'Open merge workflow',
        children: isScenarioInSync ? (
          <Icon iconName={ICON_NAME} style={ICON_STYLE} />
        ) : (
          <IconBadge statusType={StatusType.ERROR} iconName={ICON_NAME} />
        ),
      } satisfies ComponentWithDropdownComponentProps<TopBarButtonWithMenuProps>
    }
    options={[
      {
        clickId: 'topbar-mainline-to-branch',
        label: 'From Mainline to Scenario',
        isDisabled: false,
        onClick: () =>
          dispatchAction(initMerge(MergeDirection.MAINLINE_TO_BRANCH)),
      },
      {
        clickId: 'topbar-branch-to-mainline',
        label: 'From Scenario to Mainline',
        isDisabled: !isScenarioInSync,
        onClick: () =>
          dispatchAction(initMerge(MergeDirection.BRANCH_TO_MAINLINE)),
      },
    ]}
  />
);

export default ScenarioMergeButton;
