import { dispatchAction } from '@ardoq/rxbeach';
import { setInvitationsState } from './actions';
import { ToastType, showToast } from '@ardoq/status-ui';
import { pluralize } from '@ardoq/common-helpers';

export const setInvitationsError = (message: string) =>
  dispatchAction(setInvitationsState({ alert: { message, type: 'error' } }));

export const clearInvitationsAlert = () =>
  dispatchAction(setInvitationsState({ alert: null }));

export const showSuccessToast = (message: string) =>
  showToast(message, ToastType.SUCCESS);

export const getInvitationSuccessMessage = (length: number) =>
  `${length} ${pluralize('invitation', length)} sent`;
