import { HintText } from 'components/DiffMergeTable/DeletedEntityHintText';
import {
  AdditionalContentWrapper,
  EntityRepresentationWrapper,
} from 'components/DiffMergeTable/atoms';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import ExpandButton from 'tabview/pagesView/ExpandButton';
import { RefObject, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { s16, s64, s8 } from '@ardoq/design-tokens';
import { fontMixins } from '@ardoq/typography';
import { MergeStep } from 'components/DiffMergeTable/types';
import {
  EnhancedScopeDataWithBranchName,
  EntityRepresentation,
  Graphics,
} from '@ardoq/renderers';

const ADDITIONAL_CONTENT_MAX_HEIGHT = 355; // real max height is 416, but subtracting padding and margins it's this numbers
const commonPaddingRules = css`
  padding-left: ${s64};
  margin-bottom: ${s8};
`;

const EntityHeader = styled.div`
  ${commonPaddingRules}
  ${fontMixins.semibold16};
`;

const ExpandButtonWithPadding = styled(ExpandButton)`
  ${commonPaddingRules};
  margin-top: ${s8};
`;

const EntityListContainer = styled.div`
  margin-top: ${s16};
`;

const getOverflowIndex = (
  entityListRef: RefObject<HTMLDivElement>,
  additionalContentTop: number
) => {
  const entityListBottom =
    entityListRef.current?.getBoundingClientRect().bottom;
  if (
    entityListBottom &&
    entityListBottom - additionalContentTop > ADDITIONAL_CONTENT_MAX_HEIGHT
  ) {
    return (
      Array.from(entityListRef.current!.children).findIndex(
        child =>
          child.getBoundingClientRect().bottom - additionalContentTop >=
          ADDITIONAL_CONTENT_MAX_HEIGHT
      ) - 1
    );
  }
  return -1;
};

const ExpandableEntityList = ({
  componentIds,
  referenceIds,
  enhancedScopeData,
  graphics,
  mergeStep,
}: {
  componentIds: ArdoqId[];
  referenceIds: ArdoqId[];
  enhancedScopeData: EnhancedScopeDataWithBranchName;
  graphics: Graphics;
  mergeStep: MergeStep;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [numberOfComponentsToShow, setNumberOfComponentsToShow] = useState(
    componentIds.length
  );
  const [numberOfReferencesToShow, setNumberOfReferencesToShow] = useState(
    referenceIds.length
  );

  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const componentListWrapperRef = useRef<HTMLDivElement>(null);
  const referenceListWrapperRef = useRef<HTMLDivElement>(null);

  const isComponentListOverflowing =
    numberOfComponentsToShow < componentIds.length;
  const isReferenceListOverflowing =
    numberOfReferencesToShow < referenceIds.length;

  const visibleComponentIds =
    isExpanded || numberOfComponentsToShow === componentIds.length
      ? componentIds
      : componentIds.slice(0, numberOfComponentsToShow);

  const visibleReferenceIds =
    isComponentListOverflowing && !isExpanded
      ? []
      : isExpanded || numberOfReferencesToShow === referenceIds.length
        ? referenceIds
        : referenceIds.slice(0, numberOfReferencesToShow);

  useEffect(() => {
    const additionalContentTop =
      contentWrapperRef.current?.getBoundingClientRect().top;
    if (componentIds.length && additionalContentTop) {
      const componentListOverflowIndex = getOverflowIndex(
        componentListWrapperRef,
        additionalContentTop
      );
      if (componentListOverflowIndex > -1) {
        setNumberOfComponentsToShow(componentListOverflowIndex);
        return; // No need to calculate reference overflow if we already know componentList is overflowing
      }
    }
    if (referenceIds.length && additionalContentTop) {
      const referenceListOverflowIndex = getOverflowIndex(
        referenceListWrapperRef,
        additionalContentTop
      );
      if (referenceListOverflowIndex > -1) {
        setNumberOfReferencesToShow(referenceListOverflowIndex);
      }
    }
  }, [componentIds, referenceIds]);

  return (
    <AdditionalContentWrapper ref={contentWrapperRef}>
      <HintText
        mergeStep={mergeStep}
        componentIds={componentIds}
        referenceIds={referenceIds}
      />
      {Boolean(visibleComponentIds.length) && (
        <EntityListContainer ref={componentListWrapperRef}>
          {Boolean(referenceIds.length) && (
            <EntityHeader>Components</EntityHeader>
          )}
          {visibleComponentIds.map(componentId => (
            <EntityRepresentationWrapper key={componentId}>
              <EntityRepresentation
                entityType={APIEntityType.COMPONENT}
                entityId={componentId}
                enhancedScopeData={enhancedScopeData}
                graphics={graphics}
                shouldHaveWrapper
              />
            </EntityRepresentationWrapper>
          ))}
        </EntityListContainer>
      )}
      {Boolean(visibleReferenceIds.length) && (
        <EntityListContainer ref={referenceListWrapperRef}>
          {Boolean(componentIds.length) && (
            <EntityHeader>References</EntityHeader>
          )}
          {visibleReferenceIds.map(referenceId => (
            <EntityRepresentationWrapper key={referenceId}>
              <EntityRepresentation
                entityType={APIEntityType.REFERENCE}
                entityId={referenceId}
                enhancedScopeData={enhancedScopeData}
                graphics={graphics}
              />
            </EntityRepresentationWrapper>
          ))}
        </EntityListContainer>
      )}
      {(isComponentListOverflowing || isReferenceListOverflowing) && (
        <ExpandButtonWithPadding
          marginTop={false}
          isExpanded={isExpanded}
          expand={() => setIsExpanded(!isExpanded)}
        />
      )}
    </AdditionalContentWrapper>
  );
};

export default ExpandableEntityList;
