import {
  FullHeightFlexBox,
  MetamodelGraphContainer,
  SimpleGraphContainerWithWhiteBackground,
} from '../../atoms';
import SimpleGraphView from 'viewpointBuilder/components/SimpleGraph/SimpleGraphView';
import { useRef } from 'react';
import { createGraphInterface } from 'viewpointBuilder/components/SimpleGraph/GraphInterface';
import { SimpleViewModel } from 'viewpointBuilder/types';

export const MetamodelStep = ({
  viewModel,
}: {
  viewModel: SimpleViewModel;
}) => {
  const graphInterface = useRef(createGraphInterface());

  return (
    <FullHeightFlexBox flex={1} paddingRight="medium">
      <MetamodelGraphContainer>
        <SimpleGraphContainerWithWhiteBackground>
          <SimpleGraphView
            viewModel={viewModel}
            graphInterface={graphInterface.current}
            shouldFitToContentOnLayout
          />
        </SimpleGraphContainerWithWhiteBackground>
      </MetamodelGraphContainer>
    </FullHeightFlexBox>
  );
};
