import { ErrorNotification, WarningNotification } from '@ardoq/status-ui';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getResourcesStream } from 'integrations/unified/streams/resources/resources$';
import { connect } from '@ardoq/rxbeach';
import { combineLatest, filter, of, switchMap } from 'rxjs';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { getDataLoadingStream } from 'integrations/unified/streams/dataLoading/dataLoading$';
import { getRequestProblem } from 'integrations/unified/streams/resources/utils';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { AutoFocus } from 'integrations/common/components/AutoFocus';

const ProblemNotification = ({
  level,
  message,
}: {
  level: 'warning' | 'error' | 'ok';
  message: string;
}) => {
  switch (level) {
    case 'warning':
      return (
        <AutoFocus>
          <WarningNotification>{message}</WarningNotification>
        </AutoFocus>
      );
    case 'error':
      return (
        <AutoFocus>
          <ErrorNotification>{message}</ErrorNotification>
        </AutoFocus>
      );
    default:
      return null;
  }
};

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId => {
    return combineLatest({
      resources: getResourcesStream(integrationId),
      dataLoading: getDataLoadingStream(integrationId),
      activeIntegration: getActiveIntegrationStream(integrationId),
      connectionsState: getConnectionsStream(integrationId),
    });
  }),
  switchMap(
    ({ dataLoading, resources, activeIntegration, connectionsState }) => {
      const resourceProblem = getRequestProblem(
        resources,
        activeIntegration,
        connectionsState
      );
      if (resourceProblem) {
        return of(resourceProblem);
      }
      if (dataLoading.status === 'FAILURE') {
        return of(dataLoading);
      }
      return of({
        level: 'ok' as const,
        message: '',
      });
    }
  )
);

export const FetchProblem = connect(ProblemNotification, viewModel$);
