import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { rightPaneCreated } from './actions';
import { returnPayload } from '@ardoq/common-helpers';

export const rightPaneRef$ =
  persistentReducedStream<React.RefObject<HTMLDivElement> | null>(
    'rightPaneRef$',
    null,
    [reducer(rightPaneCreated, returnPayload)]
  );
