import { Notification } from '@ardoq/status-ui';
import { s16 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { DefaultForComponentTypeName } from '@ardoq/api-types';

const NotificationWrapper = styled.div`
  margin-bottom: ${s16};
`;

const makeReadableArray = (arr: string[]) => {
  if (arr.length === 1) return <b>{arr[0]}</b>;
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return (
    <span>
      <b>{firsts.join(', ')}</b>
      {' and '}
      <b>{last}</b>
    </span>
  );
};

type ViewpointDefaultNotificationProps = {
  defaultForComponentTypeNames: DefaultForComponentTypeName[];
};

const ViewpointDefaultNotification = ({
  defaultForComponentTypeNames,
}: ViewpointDefaultNotificationProps) => {
  let detailProps = {};

  if (defaultForComponentTypeNames.length > 4) {
    detailProps = {
      details: (
        <ul>
          {defaultForComponentTypeNames.map(t => (
            <li key={t.componentTypeName + t.workspaceId}>
              {t.componentTypeName}
            </li>
          ))}
        </ul>
      ),
      detailsLabel: 'Component type details',
    };
  }

  return (
    <NotificationWrapper>
      <Notification info {...detailProps}>
        <div>
          This is the default viewpoint for{' '}
          {defaultForComponentTypeNames.length > 4
            ? defaultForComponentTypeNames.length
            : makeReadableArray(
                defaultForComponentTypeNames.map(t => t.componentTypeName)
              )}{' '}
          component types. <br />
          If you modify the data selection or model they could default to
          Default Overview. <br />
          You&apos;ll be asked to confirm this in this case.
        </div>
      </Notification>
    </NotificationWrapper>
  );
};

export default ViewpointDefaultNotification;
