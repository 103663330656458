import content$ from 'broadcasts/content$';
import predefinedAudienceDialog$ from './predefinedAudienceDialog$';
import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
} from '@ardoq/rxbeach';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getAudiencePreview$ } from 'broadcasts/routineUtils';
import { logError } from '@ardoq/logging';
import {
  fetchPredefinedAudiencePreview,
  notifyFailedToFetchPredefinedAudiencePreview,
  openExistingPredefinedAudienceDialog,
  setPredefinedAudiencePreview,
} from './actions';
import { getPredefinedAudience } from './utils';
import { handleError } from '@ardoq/api';

const handleFetchPredefinedAudiencePreview = routine(
  ofType(fetchPredefinedAudiencePreview),
  withLatestFrom(predefinedAudienceDialog$, content$),
  map(([, { selectedQueryOptions }, content]) => ({
    content,
    audiences: [getPredefinedAudience(selectedQueryOptions)],
  })),
  switchMap(getAudiencePreview$),
  handleError(error => {
    logError(error, 'Failed to fetch broadcast audience preview');
    dispatchAction(notifyFailedToFetchPredefinedAudiencePreview());
  }),
  tap(response => dispatchAction(setPredefinedAudiencePreview(response)))
);

const handleOpenExistingPredefinedAudienceDialog = routine(
  ofType(openExistingPredefinedAudienceDialog),
  tap(() => dispatchAction(fetchPredefinedAudiencePreview()))
);

export default collectRoutines(
  handleFetchPredefinedAudiencePreview,
  handleOpenExistingPredefinedAudienceDialog
);
