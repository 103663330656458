import { Citation } from '../types';
import { Link, Paragraph } from '@ardoq/typography';
import { Box, Stack } from '@ardoq/layout';

type CitationArticleLinksProps = {
  citations: Map<string, Citation>;
};
const CitationArticleLinks = ({ citations }: CitationArticleLinksProps) =>
  citations.size ? (
    <Box>
      <Paragraph variant="caption" color="textSubtle">
        Related articles
      </Paragraph>
      <Stack gap="xxsmall">
        {Array.from(citations.values()).map(({ title, url }) => (
          <Link typography="text2" href={url} target="_blank" key={title}>
            {title}
          </Link>
        ))}
      </Stack>
    </Box>
  ) : null;

export default CitationArticleLinks;
