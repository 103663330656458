import { ButtonType } from '@ardoq/button';
import { ArdoqId } from '@ardoq/api-types';
import currentUser$, {
  CurrentUserOrganization,
  CurrentUserState,
} from 'streams/currentUser/currentUser$';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import { connect } from '@ardoq/rxbeach';
import { smartCompare } from '@ardoq/pagination';
import { logError } from '@ardoq/logging';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { ButtonSize, SmallGhostButton } from '@ardoq/button';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { SwitchOrgButtonWrapper } from './atoms';

const setOrganization = (label: string) => {
  document.location.href = `/api/switchOrg?org=${label}`;
};

const getOptions = (
  organizations: CurrentUserOrganization[],
  currentOrgId: ArdoqId | null
) =>
  organizations
    .filter(({ _id }) => _id !== currentOrgId)
    .sort(smartCompare)
    .map(({ name, label }) => ({
      label: name,
      onClick: () => setOrganization(label),
    }));

type ViewProps = CurrentUserState;

const SwitchOrganizationMenu = ({ organization, organizations }: ViewProps) => {
  if (organization === null) {
    logError(
      Error(
        'Tried to render switch organization menu for user not logged into an organization'
      ),
      null,
      { numberOfOrganizationsUserIsMemberOf: organizations.length }
    );
  }

  const hasSingleOrg = organizations.length === 1 && organization !== null;

  return (
    <SwitchOrgButtonWrapper
      $isNotClickable={hasSingleOrg}
      $isScenarioMode={isScenarioMode()}
    >
      {hasSingleOrg ? (
        <SmallGhostButton overflowMethod="ellipsis">
          {organization.name}
        </SmallGhostButton>
      ) : (
        <ButtonWithDropdown
          overflowMethod="ellipsis"
          buttonSize={ButtonSize.SMALL}
          minDropdownSize={DropdownSize.S}
          maxDropdownSize={DropdownSize.M}
          options={getOptions(organizations, organization?._id ?? null)}
          tooltipText="Switch organization"
          buttonType={ButtonType.GHOST}
        >
          {organization?.name ?? 'Unknown organization'}
        </ButtonWithDropdown>
      )}
    </SwitchOrgButtonWrapper>
  );
};

export default connect(SwitchOrganizationMenu, currentUser$);
