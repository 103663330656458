import { TopBarButton } from '../../atoms';
import { Icon, IconName } from '@ardoq/icons';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  hideScopeRelatedNavigator,
  showScopeRelatedNavigator,
} from '../../actions';
import view$, { ViewProps } from './view$';
import { withPlainTextPopover } from '@ardoq/popovers';

const getTooltipText = (showScopeRelated: boolean) =>
  showScopeRelated
    ? 'Hide scenario related components'
    : 'Show scenario related components';

const getPopoverText = ({
  isDiffModeActive,
  isCurrentViewSupportingDiffMode,
  isCurrentScenarioInSync,
  isAnyRelatedComponentSelected,
}: {
  isDiffModeActive: boolean;
  isCurrentViewSupportingDiffMode: boolean;
  isCurrentScenarioInSync: boolean;
  isAnyRelatedComponentSelected: boolean;
}) => {
  if (!isCurrentScenarioInSync || isDiffModeActive) return null;
  if (!isCurrentViewSupportingDiffMode)
    return 'Switch to Block Diagram to show related components in view';
  if (!isAnyRelatedComponentSelected)
    return 'Select a related component to show it in view';
  return null;
};

const ShowHideRelatedComponentsTopBarButton = ({
  disabled,
  isDiffModeActive,
  showScopeRelated,
  isCurrentViewSupportingDiffMode,
  isCurrentScenarioInSync,
  isAnyRelatedComponentSelected,
}: ViewProps) => (
  <TopBarButton
    $isScenarioMode
    disabled={disabled}
    data-tooltip-text={!disabled ? getTooltipText(showScopeRelated) : null}
    onClick={() =>
      !disabled &&
      dispatchAction(
        showScopeRelated
          ? hideScopeRelatedNavigator()
          : showScopeRelatedNavigator()
      )
    }
    {...withPlainTextPopover(
      getPopoverText({
        isDiffModeActive,
        isCurrentViewSupportingDiffMode,
        isCurrentScenarioInSync,
        isAnyRelatedComponentSelected,
      })
    )}
  >
    <Icon
      iconName={
        showScopeRelated ? IconName.SHOW_COMPONENT : IconName.HIDE_COMPONENT
      }
    />
  </TopBarButton>
);

export default connect(ShowHideRelatedComponentsTopBarButton, view$);
