import { PersistedUseCase } from '@ardoq/api-types';
import { PrimaryButton } from '@ardoq/button';
import ManageUseCaseTable from './UseCaseList';
import { LoadingContainer } from 'admin/bundles/BundleOverview/atoms';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  createUseCaseFromExisting,
  deleteUseCase,
  editUseCase,
} from '../actions';
import { UseCaseDataSourceItem, UseCaseOverviewProps } from '../types';
import { Header1, Paragraph } from '@ardoq/typography';
import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { isEmpty } from 'lodash';
import { Box, FlexBox, Stack } from '@ardoq/layout';

const Loading = () => (
  <LoadingContainer>
    <ArdoqLoaderComponent
      size={LoaderSize.MEDIUM}
      loaderType="spinner"
      loaderColor={LoaderColor.LIGHT}
    />
  </LoadingContainer>
);

const Error = ({ error, onClick }: { error: string; onClick: () => void }) => (
  <Box marginTop="xxlarge" paddingTop="xxlarge">
    <Stack gap="medium">
      <FlexBox justify="center">
        <Header1>Error</Header1>
      </FlexBox>
      <FlexBox justify="center">
        <Paragraph variant="text1">{error}</Paragraph>
      </FlexBox>
      <FlexBox justify="center">
        <PrimaryButton onClick={onClick}>Reload</PrimaryButton>
      </FlexBox>
    </Stack>
  </Box>
);

const Empty = ({ onClick }: { onClick: () => void }) => (
  <Box marginTop="xxlarge" paddingTop="xxlarge">
    <Stack gap="medium">
      <FlexBox justify="center">
        <Paragraph variant="text1">No use cases exist.</Paragraph>
      </FlexBox>
      <FlexBox justify="center">
        <PrimaryButton onClick={onClick}>Create use case</PrimaryButton>
      </FlexBox>
    </Stack>
  </Box>
);

const useCaseToDataSourceItem = (
  useCase: PersistedUseCase
): UseCaseDataSourceItem => {
  return {
    ...useCase,
    handleDelete: () =>
      dispatchAction(deleteUseCase({ id: useCase._id, name: useCase.name })),
    handleEdit: () => dispatchAction(editUseCase(useCase._id)),
    handleCreateFromExistingUseCase: () =>
      dispatchAction(createUseCaseFromExisting(useCase._id)),
  };
};

const UseCaseOverview = ({
  useCases,
  isLoading,
  error,
  onReloadClick,
  onCreateClick,
}: UseCaseOverviewProps) => {
  if (isLoading) return <Loading />;
  if (error) return <Error onClick={onReloadClick} error={error} />;
  if (isEmpty(useCases)) return <Empty onClick={onCreateClick} />;

  const useCasesDataSource = useCases.map(useCaseToDataSourceItem);
  return <ManageUseCaseTable dataSource={useCasesDataSource} />;
};

export default UseCaseOverview;
