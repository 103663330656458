import { PageWrapper } from '@ardoq/page-layout';
import { DeclarativeRouter } from 'router/declarative/declarativeRouter';
import { LocationIn } from 'router/declarative/location';
import {
  IndexRouteContent,
  PathRouteContent,
  RouteNode,
} from 'router/declarative/routeNode';

const routes = {
  path: '/organization-metamodel',
  content: async () => PageWrapper,
  children: [
    {
      path: '/triples',
      content: async () => (await import('./triples/TriplesPage')).TriplesPage,
    },
    {
      path: '/workspaces',
      children: [
        {
          path: { index: true },
          content: async (): IndexRouteContent =>
            (await import('./workspaces/WorkspacesPage')).WorkspacesPage,
        },
        {
          path: '/:workspaceId',
          content: async (): PathRouteContent =>
            (await import('./metamodel/MetamodelLayout')).MetamodelLayout,
          children: [
            {
              path: '/component-types',
              content: async (): PathRouteContent =>
                (await import('./metamodel/componentTypes/ComponentTypesPanel'))
                  .ComponentTypesPanel,
              children: [
                {
                  path: '/:metamodelEntityId',
                  content: async (): PathRouteContent =>
                    (
                      await import(
                        './metamodel/componentTypes/sidebar/ComponentTypeSidebar'
                      )
                    ).ComponentTypeSidebar,
                },
              ],
            },
            {
              path: '/reference-types',
              content: async (): PathRouteContent =>
                (await import('./metamodel/referenceTypes/ReferenceTypesPanel'))
                  .ReferenceTypesPanel,
              children: [
                {
                  path: '/:metamodelEntityId',
                  content: async (): PathRouteContent =>
                    (
                      await import(
                        './metamodel/referenceTypes/sidebar/ReferenceTypeSidebar'
                      )
                    ).ReferenceTypeSidebar,
                },
              ],
            },
            {
              path: '/fields',
              content: async (): PathRouteContent =>
                (await import('./metamodel/fields/FieldsPanel')).FieldsPanel,
              children: [
                {
                  path: '/:metamodelEntityId',
                  content: async (): PathRouteContent =>
                    (await import('./metamodel/fields/sidebar/FieldSidebar'))
                      .FieldSidebar,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
} as const satisfies RouteNode;

export const location$ = new DeclarativeRouter(routes);
export const navigateTo = location$.depart.bind(location$);
export const pathTo = location$.pathTo.bind(location$);
export type Location = LocationIn<typeof routes>;
