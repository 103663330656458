import { actionCreator } from '@ardoq/rxbeach';
import { UnsavedState, UnsavedStateOwner } from './types';

export const setUnsavedState =
  actionCreator<Partial<UnsavedState>>('[UnsavedState] set');

export const discardState = actionCreator('[UnsavedState] discard');

export const resetToDefaultState = actionCreator<UnsavedStateOwner>(
  '[UnsavedState] reset to default state'
);

export const resetUnsavedState = actionCreator('[UnsavedState] reset');
