import { api, handleError, presentationApi } from '@ardoq/api';
import {
  APIPresentationAssetAttributes,
  PresentationReadPermissions,
  PresentationWritePermissions,
  UnpersistedEntity,
} from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { from, tap } from 'rxjs';
import { notifyPresentationCreatedByUser } from './actions';

const enhancePresentationAttributesWithDefaults = (
  attrs: Partial<APIPresentationAssetAttributes>
): UnpersistedEntity<APIPresentationAssetAttributes> => {
  return {
    name: 'My Presentation',
    description: '',
    label: '',
    lockedContext: false,
    readAccess: PresentationReadPermissions.WS,
    writeAccess: PresentationWritePermissions.ME,
    slides: [],
    authorizedSlides: [],
    invisible: false,
    ...attrs,
  } as UnpersistedEntity<APIPresentationAssetAttributes>;
};

export const createPresentation$ = (
  presentationAttrs: Partial<APIPresentationAssetAttributes>
) => {
  return from(
    presentationApi.create(
      enhancePresentationAttributesWithDefaults(presentationAttrs)
    )
  ).pipe(
    handleError(api.logErrorIfNeeded),
    tap(presentation => {
      dispatchAction(notifyPresentationCreatedByUser(presentation));
    })
  );
};
