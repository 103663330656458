import { useCallback, useRef } from 'react';

export function useScroll() {
  const parentRef = useRef<HTMLDivElement | null>(null);

  const tableContainerRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      const parentElement = node.querySelector('table')?.parentElement;
      if (!parentElement) {
        return;
      }
      parentRef.current = parentElement as HTMLDivElement;
    }
  }, []);

  const scrollLeft = () => {
    if (!parentRef.current) {
      return;
    }
    parentRef.current.scrollBy({
      left: -parentRef.current.clientWidth / 2,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    if (!parentRef.current) {
      return;
    }
    parentRef.current.scrollBy({
      left: parentRef.current.clientWidth / 2,
      behavior: 'smooth',
    });
  };

  return {
    tableContainerRef,
    scrollLeft,
    scrollRight,
  };
}
