import { NavigatorTopBarStreamShape } from './NavigatorTopBar/types';
import { derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import navigatorTopBar$ from './NavigatorTopBar/navigatorTopBar$';
import { loadedState$ } from 'loadedState/loadedState$';
import { viewpointNavigatorInterface } from 'viewpointNavigator/viewpointNavigatorInterface';
import { ViewpointNavigatorListItem } from 'viewpointNavigator/types';
import viewpointSidebarTabs$ from './ViewpointSidebar/viewpointSidebarTabs$';
import { metaModelAsScopeData$ } from 'viewpointBuilder/metaModel/loadMetaModelAsScopeData$';
import { LoadMetaModelAsScopedDataState } from 'viewpointBuilder/metaModel/loadMetaModelTypes';
import { LoadedState, MetaModelComponentType } from '@ardoq/api-types';
import { LoadedStateCommands, loadedStateCommands } from './commands';
import {
  ViewpointNavigatorCommands,
  viewpointNavigatorCommands,
} from 'viewpointNavigator/viewpointNavigatorCommands';

const transformLoadedState = (
  loadedStates: LoadedState[],
  componentTypes: MetaModelComponentType[]
): ViewpointNavigatorListItem[] => {
  return loadedStates
    .map(entry => {
      return viewpointNavigatorInterface.prepareViewpointNavigatorListItem(
        entry,
        componentTypes
      );
    })
    .reverse(); // Reverse to show newest on top
};

type SidebarCommands = LoadedStateCommands & ViewpointNavigatorCommands;

type SidebarStreamShape = {
  isViewpointMode: boolean;
  navigatorTopBar: NavigatorTopBarStreamShape;
  configPanelItems: ViewpointNavigatorListItem[];
  activeTab: string;
  commands: SidebarCommands;
};

const viewpointNavigatorAndLoadedStateCommands: SidebarCommands = {
  ...loadedStateCommands,
  ...viewpointNavigatorCommands,
};

const toSidebarViewState = ([
  navigatorTopBar,
  loadedStates,
  { activeTab },
  metaModelAsScopeData,
]: [
  NavigatorTopBarStreamShape,
  LoadedState[],
  { activeTab: string },
  LoadMetaModelAsScopedDataState,
]): SidebarStreamShape => {
  const configPanelItems =
    metaModelAsScopeData.status === 'DATA_LOADED'
      ? transformLoadedState(loadedStates, metaModelAsScopeData.componentTypes)
      : [];

  return {
    isViewpointMode: navigatorTopBar.loadedGraph.isViewpointMode,
    navigatorTopBar,
    activeTab,
    commands: viewpointNavigatorAndLoadedStateCommands,
    configPanelItems,
  };
};

const sideBar$ = derivedStream(
  'sideBar$',
  navigatorTopBar$,
  loadedState$,
  viewpointSidebarTabs$,
  metaModelAsScopeData$
).pipe(map(state => toSidebarViewState(state)));

export default sideBar$;
