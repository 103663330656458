import { IntegrationSchedule } from '@ardoq/api-types';
import { FlexBox, Stack } from '@ardoq/layout';
import SchedulesStatus from 'integrations/dashboard/widgets/schedules/schedulesStatus/SchedulesStatus';
import { RecentIntegrationsWidget } from 'integrations/dashboard/widgets/RecentIntegrationsWidget';
import {
  ScheduleCommands,
  SchedulesTable,
} from 'integrations/dashboard/widgets/schedules/schedulesTable/SchedulesTable';
import SchedulesApiConnector from 'integrations/dashboard/widgets/schedules/SchedulesApiConnector';
import { Integration } from 'integrations/types';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { TransferSummaryWidget } from 'integrations/dashboard/widgets/TransferSummaryWidget';
import AvailableApisWidget from '../../widgets/AvailableApisWidget';
import styled from 'styled-components';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';
import RequestIntegrationWidget from 'integrations/dashboard/widgets/RequestIntegrationWidget';

const LeftColumn = styled(Stack)`
  width: 825px;
`;

const RightColumn = styled(Stack)`
  max-width: 435px;
`;

type OverviewProps = {
  recentIntegrationsIds: IntegrationId[];
  integrations: Integration[];
  onIntegrationClick: (integration: Integration) => void;
  scheduleCommands: ScheduleCommands;
  onViewHistory: () => void;
  importsCount: number;
  exportsCount: number;
  allConnections: Record<IntegrationId, IntegrationConnectionsState>;
  alertsEnabled: boolean;
};

const Overview = ({
  recentIntegrationsIds,
  integrations,
  onIntegrationClick,
  scheduleCommands,
  onViewHistory,
  importsCount,
  exportsCount,
  allConnections,
  alertsEnabled,
}: OverviewProps) => {
  return (
    <SchedulesApiConnector>
      {({
        schedules,
        isLoadingSchedules,
      }: {
        schedules: IntegrationSchedule[];
        isLoadingSchedules: boolean;
      }) => (
        <FlexBox gap="medium">
          <LeftColumn gap="medium">
            <FlexBox gap="medium">
              <SchedulesStatus schedules={schedules} />
              <TransferSummaryWidget
                importsCount={importsCount}
                exportsCount={exportsCount}
                onViewHistory={onViewHistory}
              />
            </FlexBox>
            <SchedulesTable
              schedules={schedules}
              isLoadingSchedules={isLoadingSchedules}
              scheduleCommands={scheduleCommands}
              allConnections={allConnections}
              alertsEnabled={alertsEnabled}
            />
          </LeftColumn>
          <RightColumn gap="medium">
            <RecentIntegrationsWidget
              recentIntegrationsIds={recentIntegrationsIds}
              integrations={integrations}
              onIntegrationSelect={onIntegrationClick}
            />
            <AvailableApisWidget
              integrations={integrations}
              onIntegrationClick={onIntegrationClick}
            />
            <RequestIntegrationWidget isVertical={true} />
          </RightColumn>
        </FlexBox>
      )}
    </SchedulesApiConnector>
  );
};

export default Overview;
