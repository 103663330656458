import Expandable from '../../Expandable';
import FormattedIcon from '../../FormattedIcon';
import { getIconName } from '../../utils';
import { ComponentStatus } from '../../types';
import { Box, FlexBox } from '@ardoq/layout';
import { HighlightedText } from '@ardoq/common-helpers';

type ComponentCellProps = {
  isExpandable: boolean;
  isExpanded: boolean;
  toggleExpand: VoidFunction;
  componentLabel: string;
  componentStatus: ComponentStatus;
  highlight?: string;
};

const ComponentCell = ({
  isExpandable,
  isExpanded,
  toggleExpand,
  componentLabel,
  componentStatus,
  highlight,
}: ComponentCellProps) => {
  return (
    <div>
      {isExpandable ? (
        <Expandable isExpanded={isExpanded} toggleExpand={toggleExpand}>
          {componentLabel}
        </Expandable>
      ) : (
        <FlexBox gap="xsmall" align="center">
          <FormattedIcon iconName={getIconName(componentStatus)} />
          <Box>
            <HighlightedText text={componentLabel} highlight={highlight} />
          </Box>
        </FlexBox>
      )}
    </div>
  );
};

export default ComponentCell;
