import { SidebarMenu } from '@ardoq/sidebar-menu';
import { Icon, IconName } from '@ardoq/icons';
import { TraversalBuilderViewState } from '../types';
import { FullHeightSpace } from 'viewpointBuilder/components/atoms';
import { FlexBox } from '@ardoq/layout';
import { colors } from '@ardoq/design-tokens';
import { Header3 } from '@ardoq/typography';
import { CollapsePathsCommands } from './collapsePathsCommands';
import CollapsePathsItems from './CollapsePathsItems';
import ViewpointBuilderSidebarEmptyState from '../ViewpointBuilderSidebarEmptyState';
import { PrimaryButton } from '@ardoq/button';

type CollapsePathsSidebarProps = {
  state: TraversalBuilderViewState;
  commands: CollapsePathsCommands;
};

const CollapsePathsSidebar = ({
  state: { pathCollapsingViewState },
  commands,
}: CollapsePathsSidebarProps) => {
  return (
    <SidebarMenu
      isPageBodyMenu
      isResizable
      isRightContent
      headerBody={
        <FlexBox align="center" gap="xsmall" padding="medium">
          <Icon iconName={IconName.COLLAPSE_ALL} color={colors.grey60} />
          <Header3>Collapse paths</Header3>
        </FlexBox>
      }
    >
      <FullHeightSpace>
        {pathCollapsingViewState.rules.length ? (
          <CollapsePathsItems
            pathCollapsingViewState={pathCollapsingViewState}
            commands={commands}
          />
        ) : (
          <ViewpointBuilderSidebarEmptyState
            title="No paths collapsed yet"
            description="Select 2 indirectly connected component types and hide all the data between them."
          >
            <PrimaryButton onClick={commands.createPathCollapsingRule}>
              <Icon iconName={IconName.ADD_CIRCLE} /> Collapse a path
            </PrimaryButton>
          </ViewpointBuilderSidebarEmptyState>
        )}
      </FullHeightSpace>
    </SidebarMenu>
  );
};

export default CollapsePathsSidebar;
