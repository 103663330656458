import { OverviewRoute } from './types';
import { Connections as MicrosoftEntraIdConnections } from 'integrations/microsoftEntraId/containers/connections/Connections';
import { Connections } from 'integrations/common/containers/connections/Connections';
import { Schedules } from 'integrations/common/containers/schedules/Schedules';
import { TransferConfigs } from 'integrations/common/containers/transferConfigs/TransferConfigs';
import { IntegrationTab } from 'integrations/common/types/common';
import { isIntegrationWithConnections } from 'integrations/common/streams/connections/utils';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import currentUser from 'models/currentUser';
import { isIntegrationWithTransferConfigs } from '../streams/transferConfigs/utils';
import { isIntegrationWithSchedules } from '../streams/schedules/utils';

const getConnectionsContainer = (integrationId?: IntegrationId) => {
  switch (integrationId) {
    case 'microsoft-entra-id':
      return MicrosoftEntraIdConnections;
    default:
      return Connections;
  }
};

const tabs: IntegrationTab<OverviewRoute>[] = [
  {
    label: 'Schedules',
    path: OverviewRoute.SCHEDULES,
    component: _ => Schedules,
    isAccessible: (integrationId: IntegrationId) =>
      isIntegrationWithSchedules(integrationId) && currentUser.isOrgAdmin(),
  },
  {
    label: 'Configurations',
    path: OverviewRoute.CONFIGURATIONS,
    component: _ => TransferConfigs,
    isAccessible: isIntegrationWithTransferConfigs,
  },
  {
    label: 'Connections',
    path: OverviewRoute.CONNECTIONS,
    component: integrationId => getConnectionsContainer(integrationId),
    isAccessible: (integrationId: IntegrationId) =>
      isIntegrationWithConnections(integrationId),
  },
];

export const getAllowedTabs = ({
  hasConnections = true,
  integrationId,
  allowedOverviewRoutes = [
    OverviewRoute.SCHEDULES,
    OverviewRoute.CONFIGURATIONS,
    OverviewRoute.CONNECTIONS,
  ],
}: {
  hasConnections?: boolean;
  integrationId: IntegrationId;
  allowedOverviewRoutes?: OverviewRoute[];
}) =>
  tabs
    .filter(
      ({ isAccessible, path }) =>
        isAccessible(integrationId) && allowedOverviewRoutes.includes(path)
    )
    .map(tab => {
      switch (tab.path) {
        case OverviewRoute.SCHEDULES:
        case OverviewRoute.CONFIGURATIONS:
          return {
            ...tab,
            isDisabled: !isIntegrationWithConnections(integrationId)
              ? false
              : !hasConnections,
          };
        case OverviewRoute.CONNECTIONS:
        default:
          return tab;
      }
    });

export const isAllowedTab = (
  path: string,
  integrationId: IntegrationId,
  allowedOverviewRoutes?: OverviewRoute[]
) => {
  const tab = tabs
    .filter(({ path }) =>
      (allowedOverviewRoutes || Object.values(OverviewRoute)).includes(path)
    )
    .find(tab => tab.path === path);
  return tab?.isAccessible ? tab.isAccessible(integrationId) : false;
};
