import { colors, s16, s24, s8 } from '@ardoq/design-tokens';
import {
  DragAndDropCardWrapper,
  DragAndDropSectionProps,
} from '@ardoq/drag-and-drop';
import styled from 'styled-components';
import {
  AvailableTriples,
  PartialStep,
  Validation,
  ValidationMap,
} from '../../types';
import StepForm from './StepForm';
import { Stack } from '@ardoq/layout';
import {
  ErrorNotification,
  InfoNotification,
  WarningNotification,
} from '@ardoq/status-ui';

type StepProps = {
  validation?: Validation;
  step: PartialStep;
  availableTriples: AvailableTriples;
  validationMap: ValidationMap;
  index: number;
  steps: PartialStep[];
  setValue: (steps: PartialStep[]) => void;
  handleConstraintValidate: (
    stepIndex: number
  ) => (constraintIndex: number) => Validation | undefined;
  isDisabled: boolean;
};

const StepDragAndDropCardWrapper = styled(DragAndDropCardWrapper)`
  background-color: ${colors.grey95};
  border-radius: ${s8};
  padding: ${s24} ${s16};
`;

const Step = ({
  validation,
  step,
  availableTriples,
  validationMap,
  index,
  steps,
  dragAndDropCardId,
  setValue,
  handleConstraintValidate,
  isDisabled,
}: StepProps & DragAndDropSectionProps) => {
  return (
    <Stack data-test-id="DGT-step" gap="medium">
      {validation?.errorMessage && (
        <ErrorNotification>{validation.errorMessage}</ErrorNotification>
      )}
      {validation?.infoMessage && (
        <InfoNotification>{validation.infoMessage}</InfoNotification>
      )}
      {validation?.warningMessage && (
        <WarningNotification>{validation.warningMessage}</WarningNotification>
      )}
      <StepDragAndDropCardWrapper
        dragAndDropCardId={dragAndDropCardId}
        dragButtonAlwaysVisible
        dragButtonPosition="left"
      >
        <StepForm
          tripleValidationMap={validationMap.tripleValidationMap}
          canDelete={steps.length > 1}
          validate={handleConstraintValidate(index)}
          index={index}
          step={step}
          onChange={currentStep => {
            setValue([
              ...steps.slice(0, index),
              currentStep,
              ...steps.slice(index + 1),
            ]);
          }}
          availableTriples={availableTriples.data}
          onDelete={() => {
            setValue([...steps.slice(0, index), ...steps.slice(index + 1)]);
          }}
          isDisabled={isDisabled}
        />
      </StepDragAndDropCardWrapper>
    </Stack>
  );
};

export default Step;
