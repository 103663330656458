import { TextInput, TextArea, Label } from '@ardoq/forms';
import { IconName } from '@ardoq/icons';
import { FlexBox, Stack } from '@ardoq/layout';
import { Header5, Paragraph } from '@ardoq/typography';
import { APIPresentationAssetAttributes } from '@ardoq/api-types';
import { useState } from 'react';
import { Section } from './atoms';
import { escapeHTML } from '@ardoq/html';
import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import currentUser$ from 'streams/currentUser/currentUser$';
import { map } from 'rxjs';
import { ButtonSize, IconButton } from '@ardoq/button';

const CopyWrapper = styled(FlexBox)<{ $urlCopied: boolean }>`
  span {
    opacity: ${props => (props.$urlCopied ? '1' : '0')};
    transition: opacity 200ms ease-in-out;
  }
`;

const BreakWord = styled(Paragraph)`
  overflow-wrap: break-word;
`;

type PresentationDetailsProps = {
  presentation?: APIPresentationAssetAttributes;
  name: string;
  description: string;
  canEdit: boolean;
  organizationLabel: string;
  updateName: (value: string) => void;
  updateDescription: (value: string) => void;
};

const PresentationDetails = ({
  presentation,
  name,
  description,
  canEdit,
  organizationLabel,
  updateName,
  updateDescription,
}: PresentationDetailsProps) => {
  const [urlCopied, setUrlCopied] = useState(false);

  const handleCopyUrl = (presentationUrl: string) => {
    setUrlCopied(true);
    navigator.clipboard.writeText(presentationUrl);
    setTimeout(() => {
      setUrlCopied(false);
    }, 1000);
  };
  const presentationUrl = presentation
    ? `${window.location.protocol}//${
        window.location.host
      }/presentation/${escapeHTML(organizationLabel)}/${presentation._id}`
    : null;

  const nameIsEmpty = Boolean(!name);
  return (
    <>
      <FlexBox margin="medium" marginBottom="small">
        <Header5>Properties</Header5>
      </FlexBox>
      <Section padding="medium" marginBottom="medium">
        <Stack gap="medium">
          <TextInput
            label="Name"
            isDisabled={!canEdit}
            hasError={nameIsEmpty}
            errorMessage={nameIsEmpty ? 'Required field' : undefined}
            autoFocus
            value={name}
            onValueChange={updateName}
          />
          <TextArea
            fixedHeight={true}
            label="Description"
            isDisabled={!canEdit}
            value={description}
            onValueChange={updateDescription}
          />
          {presentation && presentationUrl && (
            <>
              <Stack>
                <Label>Author</Label>
                {presentation.createdByEmail}
              </Stack>
              <Stack>
                <FlexBox align="center" gap="xsmall">
                  <Label width="fit-content">Url </Label>
                  <CopyWrapper
                    $urlCopied={urlCopied}
                    gap="xsmall"
                    align="center"
                  >
                    <IconButton
                      onClick={() => handleCopyUrl(presentationUrl)}
                      data-tooltip-text="Click to copy presentation URL"
                      iconName={IconName.COPY}
                      buttonSize={ButtonSize.SMALL}
                    />
                    <span>Copied!</span>
                  </CopyWrapper>
                </FlexBox>
                <BreakWord>{presentationUrl}</BreakWord>
              </Stack>
            </>
          )}
        </Stack>
      </Section>
    </>
  );
};

export default connect(
  PresentationDetails,
  currentUser$.pipe(
    map(currentUser => {
      return {
        organizationLabel: currentUser.organization.label,
      };
    })
  )
);
