interface SvgIconProps {
  readonly name: string;
  readonly color: string;
  readonly width?: string;
  readonly height?: string;
  readonly className?: string;
}
const SvgIcon = ({ name, color, width, height, className }: SvgIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${width || '24'} ${height || '24'}`}
      className={className}
      style={{ fill: color }}
    >
      <use xlinkHref={`/img/svg-icons/${name}.svg#${name}`} />
    </svg>
  );
};

export default SvgIcon;
