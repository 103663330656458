import { AccessControlPageProps } from 'appContainer/AccessControlPage/types';
import { UserProfilePageEvents, UserProfilePageProps } from '../../types';
import { UserAssetsOverviewState } from '../../userAssetsOverview/userAssetsOverviewTypes';
import { useState } from 'react';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import {
  EmptyState,
  EmptyStateNoResultIllustration,
  Tag,
} from '@ardoq/status-ui';
import { PaginationBar, PaginationController } from '@ardoq/pagination';
import { Column, DatasourceTable, newTableTheme } from '@ardoq/table';
import { UserAssetPermission } from '@ardoq/api-types';
import { trackUserProfilePageEvents } from '../../utils';
import { userAssetPermissionOperations } from './userAssetPermissionOperations';
import { IconDecorator } from '@ardoq/decorators';
import styled from 'styled-components';
import { ellipsisCSS } from '@ardoq/style-helpers';
import { Breadcrumbs2 as Breadcrumbs } from '@ardoq/dropdown-menu-ui';
import { IconButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';

const Ellipsis = styled(Box)`
  ${ellipsisCSS}
`;

type UserAssetsOverviewContentProps = {
  userAssetsOverview: UserAssetsOverviewState;
  commands: UserProfilePageProps['commands'];
  userAssetsFilterSidebarState: UserProfilePageProps['userAssetsFilterSidebarState'];
  userAssetsQuery: AccessControlPageProps['userAssetsQuery'];
  locallyFilteredUserAssets: AccessControlPageProps['locallyFilteredUserAssets'];
};

const getColumns = (
  userAssetsQuery: AccessControlPageProps['userAssetsQuery'],
  commands: UserProfilePageProps['commands']
): Column<UserAssetPermission>[] => [
  {
    title: 'Asset name',
    dataIndex: 'anemicEntity',
    cellStyle: { width: '50%' },
    valueRender: (_, userAssetPermission) => {
      const breadcrumbs =
        userAssetPermissionOperations.getBreadcrumbData(userAssetPermission);
      const { label, iconName } =
        userAssetPermissionOperations.getAssetOption(userAssetPermission);
      return (
        <Stack gap="none" align="start" justify="start">
          <IconDecorator iconName={iconName} iconTooltipText={label}>
            <Ellipsis>
              {userAssetPermissionOperations.getAssetName(userAssetPermission)}
            </Ellipsis>
          </IconDecorator>
          <Box paddingBottom="small" paddingLeft="xsmall" width="full">
            {breadcrumbs.length > 0 && (
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            )}
          </Box>
        </Stack>
      );
    },
  },
  {
    title: 'Access',
    dataIndex: 'maxPermissionLevel',
    cellStyle: {
      paddingRight: '0px',
      // To make it take as less space as possible
      width: '0px',
    },
    valueRender: (_, userAssetPermission) => {
      const { label, iconName } =
        userAssetPermissionOperations.getMaxPermissionLevelOption(
          userAssetPermission
        );
      return (
        <Tag
          leftIcon={{
            name: iconName,
          }}
          label={label}
          style={{ whiteSpace: 'nowrap' }}
        />
      );
    },
  },
  {
    title: '',
    valueRender: (_, userAssetPermission) => (
      <IconButton
        iconName={IconName.EDIT}
        data-tooltip-text="Edit asset access"
        onClick={() => {
          commands.editAssetsAccess(userAssetsQuery, [userAssetPermission]);
        }}
      />
    ),
  },
];

export const UserAssetsOverviewTabContent = ({
  locallyFilteredUserAssets,
  userAssetsOverview,
  userAssetsQuery,
  commands,
}: UserAssetsOverviewContentProps) => {
  const [perPage, setPerPage] = useState(20);
  const { loading } = userAssetsOverview;
  if (loading && locallyFilteredUserAssets.length === 0) {
    return (
      <FlexBox
        justify="center"
        align="center"
        height="full"
        flex={1}
        flexDirection="column"
      >
        <ArdoqLoaderComponent
          loaderType="logo"
          loaderColor={LoaderColor.BRAND}
          size={LoaderSize.LARGE}
        />
      </FlexBox>
    );
  }
  if (!loading && locallyFilteredUserAssets.length === 0) {
    return (
      <EmptyState
        description="There are no assets."
        layout="vertical"
        media={<EmptyStateNoResultIllustration />}
        title="No assets found"
      ></EmptyState>
    );
  }
  return (
    <PaginationController
      dataSource={locallyFilteredUserAssets}
      perPage={perPage}
      render={({
        currentPageDataSource,
        onPageSelect,
        currentPageNumber,
        totalResults,
      }) => (
        <>
          <DatasourceTable<UserAssetPermission>
            components={newTableTheme}
            dataSource={currentPageDataSource}
            columns={getColumns(userAssetsQuery, commands)}
          />
          <PaginationBar
            pageSizeOptions={[10, 20, 50, 100]}
            currentPageNumber={currentPageNumber}
            perPage={perPage}
            totalResults={totalResults}
            onChange={({
              currentPageNumber: newPageNumber,
              perPage: newPerPage,
            }) => {
              setPerPage(newPerPage);
              onPageSelect(newPageNumber);
              trackUserProfilePageEvents(
                UserProfilePageEvents.CLICKED_PAGINATION_CONTROL,
                {
                  perPage: newPerPage,
                  pageNumber: newPageNumber,
                }
              );
            }}
          />
        </>
      )}
    />
  );
};
