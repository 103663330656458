import { ViewIds, ViewpointSavedViewSettings } from '@ardoq/api-types';
import { pick } from 'lodash';
import {
  LAYOUT_SETTINGS_BY_VIEW_ID,
  PERSISTED_VIEW_ACTIONS_SETTINGS_BY_VIEW_ID,
} from '../viewpointViewSettings';

export const getViewSettingsToSave = (
  viewSettings: Record<string, any>,
  viewId: ViewIds
): ViewpointSavedViewSettings | null => {
  const layoutSettingsToSave = LAYOUT_SETTINGS_BY_VIEW_ID.get(viewId) ?? [];
  const viewActionsSettingsToSave =
    PERSISTED_VIEW_ACTIONS_SETTINGS_BY_VIEW_ID.get(viewId) ?? [];
  const settingsToSave = layoutSettingsToSave.concat(viewActionsSettingsToSave);
  return settingsToSave
    ? { [viewId]: pick(viewSettings, settingsToSave) }
    : null;
};
