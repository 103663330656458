import { useState } from 'react';
import {
  DatasourceTable,
  newTableTheme,
  useSelectableTable,
} from '@ardoq/table';
import { PrivilegeLabel } from '@ardoq/api-types';
import { PaginationBar, PaginationController } from '@ardoq/pagination';
import { s32 } from '@ardoq/design-tokens';
import {
  DualUserManagementModeInfo,
  OnlyExternalUserManagementInfo,
} from 'admin/orgMembers/atoms';
import { FilterSection } from './FilterSection';
import { OrgMembersViewStreamShape } from 'admin/orgMembers/orgMembersView$';
import {
  getFilteredUsers,
  toSelectableDataSource,
  trackOrgMembersView,
} from './utils';
import { getUsersTableColumns } from './columnConfig';
import { Features, hasFeature } from '@ardoq/features';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { InfoNotification } from '@ardoq/status-ui';
import { useSelectedIds } from './useSelectedIds';
import { TrackOrgMembersViewUserEvents } from './types';
import { debounce } from 'lodash';
import { AccessControlTabs } from 'admin/accessControl/navigation/types';
import { commands } from 'appContainer/AccessControlPage/commands';

const trackSearchUserEvent = debounce(
  () => {
    trackOrgMembersView(TrackOrgMembersViewUserEvents.SEARCH_USERS);
  },
  3000,
  { leading: true, trailing: false }
);

export const OrgMembersView = ({
  users,
  usersById,
  hasInternalUserManagement,
  hasInternalAndExternalUserManagement,
  privilegesByUser,
  configurablePrivileges,
  currentUserCanConfigurePrivileges,
  roles,
  roleTitleByLabel,
  hasConfigurablePrivileges,
}: OrgMembersViewStreamShape) => {
  const [privilegeFilters, setPrivilegeFilters] = useState<PrivilegeLabel[]>(
    []
  );
  const [roleFilters, setRoleFilters] = useState<string[]>([]);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [perPage, setPerPage] = useState(30);

  const filteredUsers = getFilteredUsers(
    users,
    privilegesByUser,
    privilegeFilters,
    roleFilters,
    searchPhrase
  );

  const showSCIM = hasFeature(Features.SCIM_PROVISIONING);

  const [selectedIds, setSelectedIds] = useSelectedIds(usersById, []);
  const { rowStyle, checkboxColumnConfig } = useSelectableTable({
    selectedIds,
    setSelectedIds: (ids: string[]) => {
      setSelectedIds(ids);
      trackOrgMembersView(TrackOrgMembersViewUserEvents.SELECTED_USERS, {
        selectedUsersCount: ids.length,
      });
    },
    selectableIds: filteredUsers.map(({ _id }) => _id),
  });
  const selectedUsers = selectedIds
    .map(id => usersById[id])
    .filter(ExcludeFalsy);

  return (
    <>
      {hasInternalAndExternalUserManagement && <DualUserManagementModeInfo />}
      {!hasInternalUserManagement && <OnlyExternalUserManagementInfo />}
      <FilterSection
        configurablePrivileges={configurablePrivileges}
        roles={roles}
        privilegeFilters={privilegeFilters}
        roleFilters={roleFilters}
        searchPhrase={searchPhrase}
        trackOnClick={() =>
          trackOrgMembersView(TrackOrgMembersViewUserEvents.OPENED_FILTER_MENU)
        }
        onPrivilegeFilterChange={filter => {
          trackOrgMembersView(
            TrackOrgMembersViewUserEvents.TOGGLED_PRIVILEGE_FILTER,
            { selected: filter }
          );
          setPrivilegeFilters(filter);
        }}
        onRoleFilterChange={filter => {
          trackOrgMembersView(
            TrackOrgMembersViewUserEvents.TOGGLED_ROLE_FILTER,
            { selected: filter }
          );
          setRoleFilters(filter);
        }}
        onSearchPhraseChange={filter => {
          trackSearchUserEvent();
          setSearchPhrase(filter);
        }}
      />
      <PaginationController
        dataSource={filteredUsers}
        perPage={perPage}
        render={({
          currentPageDataSource,
          sortBy,
          sortById,
          sortOrder,
          onPageSelect,
          currentPageNumber,
          totalResults,
        }) => {
          const pageIncludesAllSelectedUsers = selectedIds.every(id =>
            currentPageDataSource.some(user => user._id === id)
          );
          return (
            <>
              {!pageIncludesAllSelectedUsers && (
                <InfoNotification>
                  There are users selected that are not visible on the page
                </InfoNotification>
              )}
              <DatasourceTable
                style={{
                  paddingBottom: s32,
                }}
                dataSource={toSelectableDataSource(currentPageDataSource)}
                components={newTableTheme}
                rowStyle={rowData => ({
                  ...rowStyle(rowData),
                  cursor: 'pointer',
                })}
                onRowClick={row => {
                  commands.goToUserProfilePage(
                    row._id,
                    AccessControlTabs.MEMBERS
                  );
                }}
                columns={[
                  checkboxColumnConfig,
                  ...getUsersTableColumns(
                    hasConfigurablePrivileges,
                    currentUserCanConfigurePrivileges,
                    hasInternalUserManagement,
                    showSCIM,
                    selectedUsers,
                    roleTitleByLabel,
                    sortBy,
                    sortById,
                    sortOrder
                  ),
                ]}
              />

              <PaginationBar
                currentPageNumber={currentPageNumber}
                perPage={perPage}
                totalResults={totalResults}
                onChange={({
                  currentPageNumber: newPageNumber,
                  perPage: newPerPage,
                }) => {
                  setPerPage(newPerPage);
                  onPageSelect(newPageNumber);
                }}
              />
            </>
          );
        }}
      />
    </>
  );
};
