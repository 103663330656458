import { action$, ofType } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import type { PerspectivesFormattingOptions } from '@ardoq/perspectives-sidebar';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { editorOpened, notifyPerspectiveApplied } from 'perspective/actions';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
  notifyFieldUpdated,
} from 'streams/fields/FieldActions';
import { context$ } from '../context/context$';
import { ArdoqId } from '@ardoq/api-types';
import { ComponentLabelSource, ReferenceLabelSource } from '@ardoq/data-model';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { perspectiveInterface } from 'modelInterface/perspectives/perspectiveInterface';
import { mapToPerspectiveEditorFormattingRule } from './mapToPerspectiveEditorFormattingRule';
import {
  notifyFilterColorChanged,
  notifyFiltersChanged,
} from '../filters/FilterActions';
import { uniq, uniqBy } from 'lodash';
import {
  getAppliedComponentFormattingLabelData,
  getAppliedReferenceFormattingLabelData,
  getReferenceFormattingCustomLabels,
  getComponentFormattingCustomLabels,
  getFormattingCustomLabelsByFields,
} from './getFormattingOptionsUtils';
import { toCustomLabelFormattingOption } from './getFormattingOptionsUtils';
import {
  ViewpointOptionsSourceData,
  viewpointOptionsSourceData$,
} from './viewpointOptionsSourceData$';
import { getIsShowReferenceTypeApplied } from '@ardoq/graph';

const getAppliedConditionalFormattingRules = () => {
  return filterInterface
    .getFormattingFilters()
    .map(mapToPerspectiveEditorFormattingRule)
    .filter(ExcludeFalsy);
};

const getFieldNameFromLabelFormatting = (
  labelFormattingValue: string,
  defaultLabelFormattingValues: string[]
) => {
  if (
    !labelFormattingValue ||
    defaultLabelFormattingValues.includes(labelFormattingValue)
  ) {
    return null;
  }
  return fieldInterface.getByName(labelFormattingValue, {
    acrossWorkspaces: true,
    includeTemplateFields: true,
  });
};

const getFormattingOptionsIncludingSavedPerspectiveEntriesFromClosedWorkspaces =
  (workspacesIds: ArdoqId[]): PerspectivesFormattingOptions => {
    const {
      referenceFormattingCustomLabels:
        referenceFormattingCustomLabelsOfOpenedWorkspaces,
      componentFormattingCustomLabels:
        componentFormattingCustomLabelsOfOpenedWorkspaces,
      appliedComponentFormattingLabel,
      appliedReferenceFormattingLabel,
      isAppliedComponentLabelTimeIncluded,
      isAppliedReferenceLabelTimeIncluded,
      appliedConditionalFormattingRules,
    } = getFormattingOptionsOfOpenedWorkspaces(workspacesIds);

    const componentFieldNameFromAppliedLabelFormatting =
      getFieldNameFromLabelFormatting(
        appliedComponentFormattingLabel,
        Object.values(ComponentLabelSource)
      );
    const componentLabelFormattingOptionsWithCustomFieldName = [
      componentFieldNameFromAppliedLabelFormatting,
    ]
      .filter(ExcludeFalsy)
      .map(toCustomLabelFormattingOption);

    const referenceFieldNameFromAppliedLabelFormatting =
      getFieldNameFromLabelFormatting(
        appliedReferenceFormattingLabel,
        Object.values(ReferenceLabelSource)
      );
    const referenceLabelFormattingOptionsWithCustomFieldName = [
      referenceFieldNameFromAppliedLabelFormatting,
    ]
      .filter(ExcludeFalsy)
      .map(toCustomLabelFormattingOption);

    const componentFormattingCustomLabels = uniqBy(
      [
        ...componentFormattingCustomLabelsOfOpenedWorkspaces,
        ...componentLabelFormattingOptionsWithCustomFieldName,
      ],
      'value'
    );
    const referenceFormattingCustomLabels = uniqBy(
      [
        ...referenceFormattingCustomLabelsOfOpenedWorkspaces,
        ...referenceLabelFormattingOptionsWithCustomFieldName,
      ],
      'value'
    );

    return {
      appliedConditionalFormattingRules,
      appliedComponentFormattingLabel,
      appliedReferenceFormattingLabel,
      isAppliedComponentLabelTimeIncluded,
      isAppliedReferenceLabelTimeIncluded,
      referenceFormattingCustomLabels,
      componentFormattingCustomLabels,
      appliedLabelFormatting: filterInterface.getLabelFormatting(),
      appliedShowReferenceType: getIsShowReferenceTypeApplied(
        filterInterface.getAllReferenceLabelFilters()
      ),
    };
  };

const getFormattingOptionsOfOpenedWorkspaces = (
  loadedAndConnectedWorkspacesIds: ArdoqId[]
): PerspectivesFormattingOptions => ({
  appliedConditionalFormattingRules: getAppliedConditionalFormattingRules(),
  referenceFormattingCustomLabels: getReferenceFormattingCustomLabels(
    loadedAndConnectedWorkspacesIds
  ),
  componentFormattingCustomLabels: getComponentFormattingCustomLabels(
    loadedAndConnectedWorkspacesIds
  ),
  ...getAppliedComponentFormattingLabelData(),
  ...getAppliedReferenceFormattingLabelData(),
  appliedLabelFormatting: filterInterface.getLabelFormatting(),
  appliedShowReferenceType: getIsShowReferenceTypeApplied(
    filterInterface.getAllReferenceLabelFilters()
  ),
});

const filterChangedOrEditorOpened$ = action$.pipe(
  ofType(
    notifyFieldAdded,
    notifyFieldRemoved,
    notifyFieldUpdated,
    editorOpened,
    notifyFilterColorChanged,
    notifyFiltersChanged,
    notifyPerspectiveApplied
  )
);

const getViewpointFormattingOptions = (
  optionsData: ViewpointOptionsSourceData
): PerspectivesFormattingOptions => {
  const { availableComponentFields, availableReferenceFields } = optionsData;

  return {
    appliedConditionalFormattingRules: getAppliedConditionalFormattingRules(),
    referenceFormattingCustomLabels: getFormattingCustomLabelsByFields(
      availableReferenceFields
    ),
    componentFormattingCustomLabels: getFormattingCustomLabelsByFields(
      availableComponentFields
    ),
    ...getAppliedComponentFormattingLabelData(),
    ...getAppliedReferenceFormattingLabelData(),
    appliedLabelFormatting: filterInterface.getLabelFormatting(),
    appliedShowReferenceType: getIsShowReferenceTypeApplied(
      filterInterface.getAllReferenceLabelFilters()
    ),
  };
};

export const getFormattingOptions = (
  workspacesIds: ArdoqId[]
): PerspectivesFormattingOptions => {
  if (perspectiveInterface.isSavedPerspectiveSelected()) {
    return getFormattingOptionsIncludingSavedPerspectiveEntriesFromClosedWorkspaces(
      workspacesIds
    );
  }

  return getFormattingOptionsOfOpenedWorkspaces(workspacesIds);
};

export const perspectiveEditorFormattingOptions$ = combineLatest({
  context: context$,
  viewpointOptionsSourceData: viewpointOptionsSourceData$,
  filterChangedOrEditorOpened$,
}).pipe(
  map(
    ({
      context: { workspacesIds, connectedWorkspaceIds },
      viewpointOptionsSourceData,
    }): PerspectivesFormattingOptions => {
      if (viewpointOptionsSourceData) {
        return getViewpointFormattingOptions(viewpointOptionsSourceData);
      }

      return getFormattingOptions(
        uniq([...workspacesIds, ...connectedWorkspaceIds])
      );
    }
  )
);
