import { persistentReducedStream } from '@ardoq/rxbeach';
import { defaultState, reducers } from './reducers';

const changeApprovalData$ = persistentReducedStream(
  'changeApprovalData$',
  defaultState,
  reducers
);

export default changeApprovalData$;
