import { alert } from '@ardoq/modal';

export default (errorMessage?: string) =>
  alert({
    title: 'Could not save changes',
    subtitle: 'An error occurred while saving changes',
    text:
      errorMessage ??
      `We are sorry about the inconvenience and have been notified. If the error persists, please contact our support.`,
  });
