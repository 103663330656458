import { useEffect, useRef } from 'react';
import { AssetType } from '@ardoq/api-types';
import { ButtonGroup, PrimaryButton } from '@ardoq/button';
import { openDashboard } from '../../components/AppMainSidebar/utils';
import { CreateNewButton, PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import DashboardModuleContainer from '../DashboardModuleContainer';
import { trackEvent } from 'tracking/tracking';
import { DashboardTrackingEventsNames } from '@ardoq/dashboard';
import { DashboardModule } from '../types';
import currentUser from '../../models/currentUser';
import { popoverRegistry } from '@ardoq/popovers';
import { dashboardAccessControlInterface } from 'resourcePermissions/accessControlHelpers/dashboards';
import { hasFeature, Features } from '@ardoq/features';
import AssetsBrowser2024 from 'components/AssetsBrowser2024/AssetsBrowser2024';
import { AppModules } from 'appContainer/types';
import {
  favoriteFilter,
  getAssetFilter,
  getCreatedByMeFilter,
} from 'components/AssetsBrowser2024/FilterBar/filters';
import { ColorThemesButton } from './ColorThemesButton';
import { PermissionContext } from '@ardoq/access-control';
import { PageBody } from '@ardoq/page-layout';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { useResizeObserver } from '@ardoq/hooks';

const POPOVER_ID = 'create-new-disabled';

const DashboardOverview = ({
  permissionContext,
}: {
  permissionContext: PermissionContext;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const size = useResizeObserver(ref);

  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);
  useEffect(() => {
    popoverRegistry.set(POPOVER_ID, () => (
      <div>
        Your current role doesn’t have permission to create a new Dashboard.
        <br />
        Contact your admin to request permission.
      </div>
    ));

    return () => {
      popoverRegistry.delete(POPOVER_ID);
    };
  }, []);

  const handleCreateNewDashboard = () => {
    trackEvent(
      DashboardTrackingEventsNames.CLICKED_ON_CREATE_NEW_IN_DASHBOARD_OVERVIEW
    );
    openDashboard({ dashboardModule: DashboardModule.BUILDER });
  };
  const canCreateDashboard =
    dashboardAccessControlInterface.canCreateDashboard(permissionContext);
  const currentUserId = permissionContext.user?._id;
  const oldButtons = (
    <ButtonGroup>
      {currentUser.isOrgAdmin() && <ColorThemesButton />}
      <PrimaryButton
        onClick={handleCreateNewDashboard}
        isDisabled={!canCreateDashboard}
        popoverId={!canCreateDashboard ? POPOVER_ID : undefined}
      >
        Create new
      </PrimaryButton>
    </ButtonGroup>
  );

  const newButtons = (
    <ButtonGroup>
      {currentUser.isOrgAdmin() && <ColorThemesButton />}
      <CreateNewButton
        onClick={handleCreateNewDashboard}
        isDisabled={!canCreateDashboard}
        popoverId={!canCreateDashboard ? POPOVER_ID : undefined}
      />
    </ButtonGroup>
  );
  return (
    <DashboardModuleContainer
      ref={ref}
      title="Dashboards"
      secondaryText="Overview"
      hasNewJourneyFeature={hasNewJourneyFeature}
      HeaderRightContent={hasNewJourneyFeature ? newButtons : oldButtons}
      dashboardModule={DashboardModule.OVERVIEW}
    >
      <PageBody backgroundColor="surfaceDefault">
        <AssetsBrowser2024
          appModule={AppModules.DASHBOARDS}
          alwaysActiveFilters={[getAssetFilter(AssetType.DASHBOARD)]}
          filters={[
            favoriteFilter,
            currentUserId ? getCreatedByMeFilter(currentUserId) : null,
          ].filter(ExcludeFalsy)}
          scrollHeight={size.height ? size.height - PAGE_TOPBAR_HEIGHT : 0}
          useFolders
        />
      </PageBody>
    </DashboardModuleContainer>
  );
};

export default DashboardOverview;
