import { ViewpointBuilderFooter } from 'viewpointBuilder/components/ViewpointBuilderFooter';
import { ComponentSearchViewState } from 'viewpointBuilder/selectContextTab/types';
import { ComponentsSearchCommands } from 'viewpointBuilder/selectContextTab/commands';
import { PrimaryButtonConfig } from 'viewpointBuilder/openViewpointBuilder/getPrimaryButtonConfig';
import { ContextConfig } from 'viewpointBuilder/openViewpointBuilder/getContextConfig';
import SelectContextTabSearch from './SelectContextTabSearch';
import ComponentSearchResults from './ComponentSearchResults';
import { Island, PageBody } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';

type SelectContextTabContentProps = {
  state: ComponentSearchViewState;
  commands: ComponentsSearchCommands;
  closeViewpointBuilder: () => void;
  primaryButtonConfig: PrimaryButtonConfig;
  contextConfig: ContextConfig;
  viewpointName: string | null;
};

export const SelectContextTabContent = ({
  state,
  commands,
  closeViewpointBuilder,
  primaryButtonConfig,
  contextConfig,
  viewpointName,
}: SelectContextTabContentProps) => {
  return (
    <PageBody
      footerContent={
        <ViewpointBuilderFooter
          closeViewpointBuilder={closeViewpointBuilder}
          primaryButtonConfig={primaryButtonConfig}
        />
      }
    >
      <SelectContextTabSearch
        viewpointName={viewpointName || ''}
        state={state}
        contextConfig={contextConfig}
        commands={commands}
      />
      <FlexBox flex={1}>
        <Island fullHeight bodySpacing="none">
          <ComponentSearchResults state={state} commands={commands} />
        </Island>
      </FlexBox>
    </PageBody>
  );
};
