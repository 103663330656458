import { TextInput } from '@ardoq/forms';
import { validateAs, validate } from '@ardoq/validation';
import styled from 'styled-components';

const WideInput = styled(TextInput)`
  width: 100%;
`;

type URLInputOptionProps = {
  label?: string;
  urlProtocol: string;
  value: string;
  onChange: (val: string) => void;
};
export function URLInputWithPrefix({
  label,
  urlProtocol,
  value,
  onChange,
}: URLInputOptionProps) {
  const validateResult = validate({
    value,
    validationSteps: [validateAs.isValidUrl()],
  });
  const errorMessage =
    value && !validateResult.isValid ? validateResult.errorMessages[0] : '';

  return (
    <WideInput
      label={label}
      prefix={urlProtocol}
      value={value.replace(urlProtocol, '')}
      onValueChange={(val: string) => onChange(`${urlProtocol}${val}`)}
      errorMessage={errorMessage}
    />
  );
}
