import { Component } from 'react';
import privileges from 'admin/privileges';
import { isEqual } from 'lodash';
import SurveyEditorLayout from './LayoutElements/SurveyEditorLayout';
import FormattedDate from 'atomicComponents/FormattedDate';
import { SurveyEditorHeaderErrorNotification } from './atoms';
import {
  SearchFieldNames,
  composeSearchQuery,
} from 'search/AdvancedSearch/utils';
import { confirm } from '@ardoq/modal';
import {
  cloneSurveyAttributes,
  getComponentTypeAndModelId,
  getHasPendingApprovals,
  getSurveyTrackingData,
  getSurveyWithoutSurveySectionStatuses,
  getUnpublishConfirmationDialog,
  isAdminOrWriter,
  stripKeysFromQuestions,
} from './utils';
import SurveyEditorDetailsSection from './SurveyEditorDetailsSection';
import SurveyEditorResponseFeedback from './SurveyEditorResponseFeedback';
import SurveyEditorDiscover from './SurveyEditorDiscover';
import SurveyEditorWorkspaceAndComponentsSection from './SurveyEditorWorkspaceAndComponentsSection';
import SurveyEditorSurveySection from './SurveyEditorSurveySection';
import SurveyEditorResultDisplayOptionsSection from './SurveyEditorResultDisplayOptionsSection';
import SurveyEditorResultFilteringSection from './SurveyEditorResultFilteringSection';
import { ValidatorFunction } from 'aqTypes';
import {
  APIFieldType,
  APISurveyAttributes,
  APITagAttributes,
  ArdoqId,
  BooleanOperator,
  PrivilegeLabel,
  SurveyFilterSetupAttributes,
  SurveyMiscAttributes,
  QueryBuilderRule,
  QueryBuilderSubquery,
  isPersistedSurvey,
  APISurveyPendingApprovalsSummary,
  APIOrganizationUser,
  PermissionGroup,
} from '@ardoq/api-types';
import SurveyEditorHeader from './LayoutElements/SurveyEditorHeaderRight';
import { getSurveyErrorsAndWarnings } from 'surveyAdmin/surveyUtil';
import currentUser from 'models/currentUser';
import SurveyEditorHeaderLeft from './LayoutElements/SurveyEditorHeaderLeft';
import { trackEvent } from 'tracking/tracking';
import { getNotEnoughWorkspacePermissionsText } from './consts';
import { emailValidator, numberValidator } from 'scopeData/editors/validators';
import { MaybePersistedSurvey, SurveyValidation } from 'surveyAdmin/types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import surveys$ from 'streams/surveys/surveys$';
import {
  saveSurvey,
  saveNewSurveyInFolder,
  saveNewSurvey,
} from 'surveyAdmin/streams/actions';
import { trackOpenExistingSurveyInSurveysApp } from 'surveyAdmin/tracking';
import tags$ from 'streams/tags/tags$';
import { combineLatest, map } from 'rxjs';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';
import { hasFeature, Features } from '@ardoq/features';
import SurveyEditorNavBar from './LayoutElements/SurveyEditorNavbar';
import { ErrorNotification, InfoNotification } from '@ardoq/status-ui';
import { parseDate } from '@ardoq/date-time';
import { PageHeader, PageWrapper } from '@ardoq/page-layout';
import { getIsOrgAdmin } from '@ardoq/common-helpers';
import currentUser$ from 'streams/currentUser/currentUser$';
import SurveyEditorChangeApprovalSection from './SurveyEditorChangeApprovalSection';
import surveyAdmin$ from 'surveyAdmin/streams/surveyAdmin$';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import {
  setFilterSetupSurveyAttribute,
  setMiscSurveyAttribute,
  setSurveyAttributes,
  setValidationErrors,
  setValidSections,
  updateSurveyAttributes,
} from './streams/actions';
import { SurveyEditorSectionKey } from './streams/types';
import surveyEditor$ from './streams/surveyEditor$';
import { SurveyErrorName } from 'surveyAdmin/consts';
import { validationHelpers } from 'surveyAdmin/surveyValidation';

const { ROOT_WORKSPACE, TYPE_NAME } = SearchFieldNames;

const getRelatedSurveys = (
  surveys: APISurveyAttributes[],
  surveyAttributes: MaybePersistedSurvey
) => {
  const currentSurveyId = isPersistedSurvey(surveyAttributes)
    ? surveyAttributes._id
    : null;
  return (
    surveys?.filter(
      survey =>
        survey._id !== currentSurveyId &&
        survey.componentTypeName === surveyAttributes.componentTypeName &&
        survey.workspace === surveyAttributes.workspace
    ) ?? []
  );
};

const shouldDiscardChanges = async () =>
  await confirm({
    title: 'Discard unsaved changes',
    subtitle:
      'You have unsaved changes that will be lost. Are you sure you want to proceed?',
    confirmButtonTitle: 'Yes, discard my changes',
  });

const isAdvancedSearchQuery = (
  rule: QueryBuilderSubquery | QueryBuilderRule
): rule is QueryBuilderSubquery =>
  Object.prototype.hasOwnProperty.call(rule, 'condition');

const getSaveButtonText = (
  isSaving: boolean,
  isChangesSaved: boolean,
  hasChanges: boolean
) => {
  let saveButtonText = 'Save changes';
  if (isSaving) {
    saveButtonText = 'Saving...';
  } else if (isChangesSaved && !hasChanges) {
    saveButtonText = 'Changes saved';
  }
  return saveButtonText;
};

const getStatusMessage = (lastUpdated?: string, errorMessage?: string) => {
  if (errorMessage) {
    return <ErrorNotification>{errorMessage}</ErrorNotification>;
  } else if (lastUpdated) {
    return (
      <InfoNotification>
        Last updated{'\u00A0'}
        <FormattedDate date={parseDate(lastUpdated)} />
      </InfoNotification>
    );
  }
};

const setMiscAttribute = <AttributeName extends keyof SurveyMiscAttributes>(
  attributeName: AttributeName,
  value: SurveyMiscAttributes[AttributeName]
) => {
  dispatchAction(
    setMiscSurveyAttribute({
      key: attributeName,
      value,
    })
  );
};

const setFilterSetupAttributes = (
  updatedFilterSetup: SurveyFilterSetupAttributes
) => {
  dispatchAction(setFilterSetupSurveyAttribute(updatedFilterSetup));
};

const handleOpenSurvey = (survey: MaybePersistedSurvey) => {
  if (!isPersistedSurvey(survey)) return;
  trackOpenExistingSurveyInSurveysApp(survey._id, 'surveyBuilder');
  window.open(`/surveys/survey/${survey._id}`, '_blank');
};

export type SurveyEditorSectionProps = {
  surveyAttributes: MaybePersistedSurvey;
  setSurveyAttributes: (newAttributes: Partial<MaybePersistedSurvey>) => void;
};

export type WithSetMiscAttributes = {
  setMiscAttribute: <AttributeName extends keyof SurveyMiscAttributes>(
    attributeName: AttributeName,
    value: SurveyMiscAttributes[AttributeName]
  ) => void;
};

type ConnectedProps = {
  surveys: APISurveyAttributes[];
  changesSaved: boolean;
  isSaving: boolean;
  surveysWithPendingApprovals: APISurveyPendingApprovalsSummary;
  tags: APITagAttributes[] | null;
  permissionsContext: PermissionContext;
  hasNewJourneyFeature: boolean;
  isOrgAdmin: boolean;
  adminOrWriterUsers: APIOrganizationUser[];
  groupsById: Record<ArdoqId, PermissionGroup>;
  surveyValidation?: SurveyValidation;
  surveyAttributes: MaybePersistedSurvey;
  validSections: SurveyEditorSectionKey[];
  selectedSectionIndex: number;
};

type SurveyEditorProperties = {
  survey: MaybePersistedSurvey;
  organizationLabel: string;
  returnToOverview: () => void;
  folderId: ArdoqId | null;
} & ConnectedProps;
interface SurveyEditorState {
  formFeedback?: string;
  showAdvancedOptions: boolean;
  advancedSearchRules?: QueryBuilderSubquery | QueryBuilderRule;
  advancedSearchCondition: BooleanOperator;
}
class SurveyEditor extends Component<
  SurveyEditorProperties,
  SurveyEditorState
> {
  hasDiscoverEnabled: boolean;
  hasSurveyResponseApprovalsFeature: boolean;
  private _validators: Partial<Record<APIFieldType, ValidatorFunction>>;
  constructor(props: SurveyEditorProperties) {
    super(props);

    const advancedSearchRules =
      this.props.survey.componentSearch &&
      this.props.survey.componentSearch.rules &&
      this.props.survey.componentSearch.rules.find(isAdvancedSearchQuery);
    const advancedSearchCondition =
      (advancedSearchRules && advancedSearchRules.condition) ||
      BooleanOperator.AND;
    const showAdvancedOptions = Boolean(advancedSearchRules);

    this.state = {
      showAdvancedOptions,
      advancedSearchRules,
      advancedSearchCondition,
      formFeedback: undefined,
    };
    this._validators = {
      Number: numberValidator,
      Email: emailValidator,
    };
    this.hasDiscoverEnabled = privileges.hasPrivilege(
      PrivilegeLabel.ACCESS_DISCOVER
    );
    this.hasSurveyResponseApprovalsFeature = hasFeature(
      Features.SURVEYS_CHANGE_APPROVAL_V2
    );
    this.saveSurvey = this.saveSurvey.bind(this);
  }

  promptAndTogglePublishSurvey = async (hasPendingApproval: boolean) => {
    const surveyValidation = await this.getValidation();
    const hasError = validationHelpers.hasError(surveyValidation);

    if (!this.props.surveyAttributes.published && !hasError) {
      this.togglePublishSurvey();
      return;
    }

    const unpublishConfirmed = await getUnpublishConfirmationDialog(
      this.props.survey,
      hasError,
      hasPendingApproval
    );
    if (!unpublishConfirmed) return;

    return this.togglePublishSurvey();
  };

  togglePublishSurvey = () => {
    const survey = this.props.survey;
    const isSettingToLive = !this.props.surveyAttributes.published === true;

    if (isSettingToLive) {
      trackEvent(
        'Survey live',
        getSurveyTrackingData(this.props.surveyAttributes)
      );
    }
    if (survey) {
      dispatchAction(
        updateSurveyAttributes({
          published: !this.props.surveyAttributes.published,
        })
      );
    }
  };

  showDiscardModal = async () => {
    if (!this.hasChanges() || (await shouldDiscardChanges())) {
      this.props.returnToOverview();
    }
  };

  componentDidMount() {
    this.getValidation();
    dispatchAction(
      setValidSections({
        discoverEnabled: this.hasDiscoverEnabled,
        // we need to check for all 3 here as we want the section to show in all cases
        canSeeChangeApprovalStep:
          this.hasSurveyResponseApprovalsFeature ||
          hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL) ||
          hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL_EXPIRED),
      })
    );
  }

  componentDidUpdate(prevProps: SurveyEditorProperties) {
    const versionIsUpdated =
      isPersistedSurvey(this.props.survey) &&
      isPersistedSurvey(prevProps.survey) &&
      this.props.survey._version !== prevProps.survey._version;
    const surveyPublishedStatusChanged =
      this.props.surveyAttributes.published !==
      prevProps.surveyAttributes.published;

    if (!versionIsUpdated && surveyPublishedStatusChanged) {
      this.saveSurvey({ shouldTrack: true });
    }
    if (versionIsUpdated) {
      dispatchAction(
        setSurveyAttributes(cloneSurveyAttributes(this.props.survey))
      );
    }
    if (
      prevProps.surveyAttributes !== this.props.surveyAttributes ||
      this.props.tags !== prevProps.tags
    ) {
      this.getValidation();
    }
  }

  getSavableSurveyAttributes() {
    const hasAdvancedSearchRules =
      this.state.showAdvancedOptions &&
      this.state.advancedSearchRules &&
      (this.state.advancedSearchRules as QueryBuilderSubquery).rules.length;

    const surveyAttributes = {
      ...this.props.surveyAttributes,
      componentSearch: composeSearchQuery({
        attributes: [
          {
            attribute: ROOT_WORKSPACE,
            value: this.props.surveyAttributes.workspace,
          },
          {
            attribute: TYPE_NAME,
            value: this.props.surveyAttributes.componentTypeName || null,
          },
        ],
        additionalConditions: hasAdvancedSearchRules
          ? (this.state.advancedSearchRules as QueryBuilderRule)
          : null,
      }),
      questions: stripKeysFromQuestions(this.props.surveyAttributes.questions),
    };

    const createdByOrContact =
      this.props.surveyAttributes.contactEmail ??
      (isPersistedSurvey(this.props.surveyAttributes)
        ? this.props.surveyAttributes.createdByEmail
        : undefined);

    surveyAttributes.contactEmail =
      createdByOrContact ?? currentUser.getEmail();

    return surveyAttributes;
  }

  hasChanges() {
    const current = this.getSavableSurveyAttributes();
    const persistedSurvey = getSurveyWithoutSurveySectionStatuses(
      this.props.survey
    );

    const currentSurvey = getSurveyWithoutSurveySectionStatuses(current);
    return !isEqual(currentSurvey, persistedSurvey);
  }

  async getValidation() {
    const surveyValidation = await getSurveyErrorsAndWarnings(
      this.props.surveyAttributes,
      this.props.tags,
      this.props.permissionsContext,
      this.props.adminOrWriterUsers,
      this.props.groupsById
    );
    dispatchAction(setValidationErrors(surveyValidation));
    return surveyValidation;
  }

  async saveSurvey({ shouldTrack = true }: { shouldTrack: boolean }) {
    const surveyValidation = await this.getValidation();
    const hasError = validationHelpers.hasError(surveyValidation);
    const nameInvalid = validationHelpers.containsErrorId(
      surveyValidation,
      SurveyErrorName.NAME_INVALID
    );

    if (nameInvalid) return;

    if (hasError && this.props.surveyAttributes.published) {
      const hasPendingApproval = getHasPendingApprovals(
        this.props.survey,
        this.props.surveysWithPendingApprovals
      );

      return this.promptAndTogglePublishSurvey(hasPendingApproval);
    }

    const survey = this.getSavableSurveyAttributes();
    const isNewSurveyInFolder = this.props.folderId !== null;
    if (isPersistedSurvey(survey)) {
      dispatchAction(saveSurvey(survey));
    } else if (isNewSurveyInFolder) {
      dispatchAction(
        saveNewSurveyInFolder({ survey, folderId: this.props.folderId })
      );
    } else {
      dispatchAction(saveNewSurvey(survey));
    }

    if (shouldTrack) trackEvent('Survey saved', getSurveyTrackingData(survey));
  }

  render() {
    const { formFeedback } = this.state;
    const {
      isSaving,
      changesSaved,
      surveys,
      isOrgAdmin,
      surveyValidation,
      surveyAttributes,
    } = this.props;
    const hasError = validationHelpers.hasError(surveyValidation);
    const { selectedModelId, selectedTypeId } = getComponentTypeAndModelId(
      surveyAttributes.workspace,
      surveyAttributes.componentTypeName
    );
    const hasChanged = this.hasChanges();
    const hasPendingApproval = getHasPendingApprovals(
      this.props.survey,
      this.props.surveysWithPendingApprovals
    );
    const selectedSectionKey =
      this.props.validSections[this.props.selectedSectionIndex];
    const surveyId = isPersistedSurvey(this.props.survey)
      ? this.props.survey._id
      : undefined;

    const showSurveyResponseApprovalsSection =
      selectedSectionKey === 'changeApprovalSection' &&
      (this.hasSurveyResponseApprovalsFeature ||
        hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL));
    return (
      <PageWrapper>
        {this.props.hasNewJourneyFeature ? (
          <SurveyEditorNavBar
            surveyName={surveyAttributes.name}
            canSave={
              hasChanged &&
              !validationHelpers.containsAnyErrorId(surveyValidation, [
                SurveyErrorName.NAME_INVALID,
                SurveyErrorName.NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE,
              ]) &&
              !isSaving
            }
            saveButtonText={getSaveButtonText(
              isSaving,
              changesSaved,
              hasChanged
            )}
            onBack={this.showDiscardModal}
            onSave={() => this.saveSurvey({ shouldTrack: true })}
            canPublish={isPersistedSurvey(this.props.survey)}
            hasError={hasError}
            isPublished={surveyAttributes.published}
            onOpenSurvey={() => handleOpenSurvey(this.props.survey)}
            notEnoughSelectedWorkspacePermission={validationHelpers.containsErrorId(
              surveyValidation,
              SurveyErrorName.NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE
            )}
            onPublish={() =>
              this.promptAndTogglePublishSurvey(hasPendingApproval)
            }
            surveyId={surveyId}
          />
        ) : (
          <PageHeader
            title="Survey builder"
            leftContent={
              <SurveyEditorHeaderLeft
                canSave={
                  hasChanged &&
                  !validationHelpers.containsAnyErrorId(surveyValidation, [
                    SurveyErrorName.NAME_INVALID,
                    SurveyErrorName.NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE,
                  ])
                }
                saveButtonText={getSaveButtonText(
                  isSaving,
                  changesSaved,
                  hasChanged
                )}
                onBack={this.showDiscardModal}
                onSave={() => this.saveSurvey({ shouldTrack: true })}
                status={getStatusMessage(
                  isPersistedSurvey(this.props.survey)
                    ? this.props.survey.lastUpdated
                    : undefined,
                  formFeedback ||
                    validationHelpers.getHiddenFieldsErrorMessages(
                      surveyValidation
                    )
                )}
              />
            }
            rightContent={
              <SurveyEditorHeader
                canPublish={isPersistedSurvey(this.props.survey)}
                hasError={hasError}
                isPublished={surveyAttributes.published}
                onOpenSurvey={() => handleOpenSurvey(this.props.survey)}
                notEnoughSelectedWorkspacePermission={validationHelpers.containsErrorId(
                  surveyValidation,
                  SurveyErrorName.NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE
                )}
                onPublish={() =>
                  this.promptAndTogglePublishSurvey(hasPendingApproval)
                }
                surveyId={surveyId}
              />
            }
          />
        )}
        <SurveyEditorLayout
          header={
            surveyAttributes.workspace &&
            validationHelpers.containsErrorId(
              surveyValidation,
              SurveyErrorName.NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE
            ) && (
              <SurveyEditorHeaderErrorNotification>
                {getNotEnoughWorkspacePermissionsText('Administrator')}
              </SurveyEditorHeaderErrorNotification>
            )
          }
          isOrgAdmin={isOrgAdmin}
        >
          {selectedSectionKey === 'detailsSection' && (
            <SurveyEditorDetailsSection
              setSurveyAttributes={attributes =>
                dispatchAction(updateSurveyAttributes(attributes))
              }
              surveyValidation={surveyValidation}
              surveyAttributes={surveyAttributes}
              survey={this.props.survey}
            />
          )}
          {selectedSectionKey === 'workspaceAndComponentsSection' && (
            <SurveyEditorWorkspaceAndComponentsSection
              surveyAttributes={surveyAttributes}
              surveyValidation={surveyValidation}
              setSurveyAttributes={attributes =>
                dispatchAction(updateSurveyAttributes(attributes))
              }
              updateParentState={this.setState.bind(this)}
              setMiscAttribute={setMiscAttribute}
              advancedSearchRules={
                {
                  ...this.state.advancedSearchRules,
                  condition: this.state.advancedSearchCondition,
                } as QueryBuilderSubquery
              }
              advancedSearchCondition={this.state.advancedSearchCondition}
              showAdvancedOptions={this.state.showAdvancedOptions}
              isOrgAdmin={isOrgAdmin}
            />
          )}
          {selectedSectionKey === 'surveySection' && (
            <SurveyEditorSurveySection
              setSurveyAttributes={attributes =>
                dispatchAction(updateSurveyAttributes(attributes))
              }
              surveyAttributes={surveyAttributes}
              surveyValidation={surveyValidation}
              selectedTypeId={selectedTypeId}
              selectedModelId={selectedModelId}
              validators={this._validators}
              permissionsContext={this.props.permissionsContext}
              hasSurveyResponseApprovalsFeature={
                this.hasSurveyResponseApprovalsFeature
              }
            />
          )}
          {showSurveyResponseApprovalsSection && (
            <SurveyEditorChangeApprovalSection
              surveyAttributes={surveyAttributes}
              setSurveyAttributes={attributes =>
                dispatchAction(updateSurveyAttributes(attributes))
              }
              hasPendingApproval={hasPendingApproval}
              isOrgAdmin={isOrgAdmin}
            />
          )}
          {selectedSectionKey === 'responseFeedback' &&
            this.hasDiscoverEnabled && (
              <SurveyEditorResponseFeedback
                setSurveyAttributes={attributes =>
                  dispatchAction(updateSurveyAttributes(attributes))
                }
                surveyAttributes={surveyAttributes}
              />
            )}
          {selectedSectionKey === 'resultDisplayOptionsSection' && (
            <SurveyEditorResultDisplayOptionsSection
              surveyAttributes={surveyAttributes}
              setMiscAttribute={setMiscAttribute}
              setSurveyAttributes={attributes =>
                dispatchAction(updateSurveyAttributes(attributes))
              }
            />
          )}
          {selectedSectionKey === 'resultFilteringSection' && (
            <SurveyEditorResultFilteringSection
              surveyAttributes={surveyAttributes}
              setFilterSetupAttributes={setFilterSetupAttributes}
              surveyValidation={surveyValidation}
              selectedTypeId={selectedTypeId}
              selectedModelId={selectedModelId}
              updateCustomHeaderFields={customHeaderFields =>
                dispatchAction(updateSurveyAttributes({ customHeaderFields }))
              }
              setSurveyAttributes={attributes =>
                dispatchAction(updateSurveyAttributes(attributes))
              }
            />
          )}
          {selectedSectionKey === 'discover' && this.hasDiscoverEnabled && (
            <SurveyEditorDiscover
              setSurveyAttributes={attributes =>
                dispatchAction(updateSurveyAttributes(attributes))
              }
              surveyAttributes={surveyAttributes}
              relatedSurveys={getRelatedSurveys(surveys, surveyAttributes)}
              previousSurveyAttributes={this.props.survey}
              pendingSave={
                hasChanged &&
                !validationHelpers.containsErrorId(
                  surveyValidation,
                  SurveyErrorName.NAME_INVALID
                )
              }
              shouldDiscardChanges={shouldDiscardChanges}
              otherSectionsHaveErrors={hasError}
            />
          )}
        </SurveyEditorLayout>
      </PageWrapper>
    );
  }
}

export default connect(
  SurveyEditor,
  combineLatest([
    surveys$,
    tags$,
    currentUserPermissionContext$,
    currentUser$,
    surveyAdmin$,
    surveyEditor$,
    orgUsers$,
  ]).pipe(
    map(
      ([
        surveys,
        { tags },
        permissionsContext,
        currentUser,
        { changesSaved, isSaving, surveysWithPendingApprovals },
        {
          surveyValidation,
          surveyAttributes,
          validSections,
          selectedSectionIndex,
        },
        { users },
      ]) => {
        return {
          surveys: surveys.list,
          changesSaved,
          isSaving,
          tags,
          permissionsContext,
          hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
          surveysWithPendingApprovals,
          isOrgAdmin: getIsOrgAdmin(currentUser),
          surveyValidation,
          surveyAttributes,
          validSections,
          selectedSectionIndex,
          adminOrWriterUsers: users.filter(isAdminOrWriter),
        };
      }
    )
  )
);
