import { actionCreator } from '@ardoq/rxbeach';
import {
  ArdoqId,
  CreatedItemsLoadedState,
  LoadedState,
  LoadedStateHash,
  PathCollapsingRule,
} from '@ardoq/api-types';

export const registerLoadedState = actionCreator<LoadedState[]>(
  '[loadedState] REGISTER_LOADED_STATE'
);

export const clearLoadedState = actionCreator(
  '[loadedState] CLEAR_LOADED_STATE'
);

export type SetTraversalIdPayload = {
  id: ArdoqId;
  loadedStateHash: LoadedStateHash;
};

export const setTraversalId = actionCreator<SetTraversalIdPayload>(
  '[loadedState] SET_TRAVERSAL_ID'
);

export type ToggleLoadedStateVisibilityPayload = {
  loadedStateHash: LoadedStateHash;
};

export const toggleLoadedStateVisibility =
  actionCreator<ToggleLoadedStateVisibilityPayload>(
    '[loadedState] SET_IS_HIDDEN'
  );

export const deleteLoadedState = actionCreator<string>(
  '[loadedState] DELETE_LOADED_STATE'
);

export type LoadedStateUpdatedPayload = {
  existingHash: string;
  loadedState: LoadedState;
};

/**
 * A loadedState's hash is based on its params, so when the params are modified
 * the hash changes and the loadedState has to be replaced atomically.
 */
export const loadedStateUpdated = actionCreator<LoadedStateUpdatedPayload>(
  '[loadedState] LOADED_STATE_UPDATED'
);

type SetTraversalContextComponentPayload = {
  loadedStateHash: LoadedStateHash;
  componentId: ArdoqId;
};

export const setTraversalContextComponent =
  actionCreator<SetTraversalContextComponentPayload>(
    '[loadedState] SET_CONTEXT_COMPONENT'
  );

export type ToggleCollapsedPathActivePayload = {
  rule: PathCollapsingRule;
  loadedStateHash: LoadedStateHash;
};

export const toggleCollapsedPathActive =
  actionCreator<ToggleCollapsedPathActivePayload>(
    '[loadedState] TOGGLE_COLLAPSED_PATH_ACTIVE'
  );

export const itemsCreated = actionCreator<CreatedItemsLoadedState>(
  '[loadedState] ITEMS_CREATED'
);

export type CreatedItemsProcessedPayload = {
  componentIds: ArdoqId[];
  referenceIds: ArdoqId[];
};

export const createdItemsProcessed =
  actionCreator<CreatedItemsProcessedPayload>(
    '[loadedState] ITEMS_CREATED_PROCESSED'
  );
