import { EmptyContent } from './EmptyContent';
import columnConfig from 'broadcasts/broadcastBuilder/broadcastContentForm/componentPreview/columnConfig';
import { Label } from '@ardoq/forms';
import { colors, s8 } from '@ardoq/design-tokens';
import { DatasourceTable } from '@ardoq/table';
import { BroadcastComponent } from '@ardoq/api-types';
import { Text } from '@ardoq/typography';
import { OverflowWrapper } from 'broadcasts/components/atoms';

type ComponentPreviewDialogContentProps = {
  readonly components: BroadcastComponent[];
  readonly total: number;
  readonly isLoading: boolean;
};

export const ComponentPreviewDialogContent = ({
  components,
  total,
  isLoading,
}: ComponentPreviewDialogContentProps) => {
  return (
    <OverflowWrapper gap="medium">
      <Label>Total: {total}</Label>
      <DatasourceTable
        style={{ margin: `${s8} 0`, border: `1px solid ${colors.grey80}` }}
        loading={isLoading}
        fixedHeader
        dataSource={components}
        columns={columnConfig}
        renderEmptyTable={() => (isLoading ? null : <EmptyContent />)}
      />
      <Text>This list automatically updates based on your filter rules.</Text>
    </OverflowWrapper>
  );
};
