import { SidebarWrapper } from '../atoms';
import NavigatorRoot from 'navigator2024/NavigatorRoot';
import ViewpointNavigator from 'viewpointNavigator/ViewpointNavigator';
import { TabButton, TabsGroup, TabsTheme } from '@ardoq/tabs';
import ViewpointSidebarFooter from './ViewpointSidebarFooter';
import { LoadedStateCommands } from '../commands';
import ViewpointSidebarSortAndScenarios from './ViewpointSidebarSortAndScenarios';
import ViewpointNavigatorFilter from './ViewpointNavigatorFilter';
import { Space } from '@ardoq/style-helpers';
import { IconName } from '@ardoq/icons';
import styled from 'styled-components';
import { setActiveTab } from './viewpointSidebarTabs$';
import { dispatchAction } from '@ardoq/rxbeach';
import { NavigatorTopBarStreamShape } from '../NavigatorTopBar/types';
import { ViewpointNavigatorListItem } from 'viewpointNavigator/types';
import { trackEvent } from 'tracking/tracking';
import { ViewpointNavigatorCommands } from 'viewpointNavigator/viewpointNavigatorCommands';
import { FlexBox } from '@ardoq/layout';
import { Features, hasFeature } from '@ardoq/features';
import SidebarInventory from './SidebarInventory';

type ViewpointSidebarProps = {
  commands: LoadedStateCommands & ViewpointNavigatorCommands;
  activeTab: string;
  navigatorTopBarProps: NavigatorTopBarStreamShape;
  configPanelItems: ViewpointNavigatorListItem[];
};

const ViewpointSidebar = ({
  commands,
  activeTab,
  navigatorTopBarProps,
  configPanelItems,
}: ViewpointSidebarProps) => {
  return (
    <SidebarWrapper>
      {hasFeature(Features.CANVAS_PROTOTYPE) ? (
        <SidebarInventory />
      ) : (
        <>
          <FlexBox padding="small">
            <TabsGroup
              activeTabId={activeTab}
              onTabChange={tabId => {
                trackEvent(`Navigator0 Clicked ${tabId} tab`);
                dispatchAction(setActiveTab(tabId));
              }}
              theme={TabsTheme.COLOR_FILL}
            >
              <TabButton
                label="Datasets"
                tabId="datasets"
                iconName={IconName.CUBE}
              />
              <TabButton
                label="Components"
                tabId="components"
                iconName={IconName.COMPONENT}
              />
            </TabsGroup>
          </FlexBox>
          <ScrollableConfigPanelWrapper $flex={1} $isVertical $gap="none">
            {activeTab === 'datasets' && (
              <>
                <ViewpointSidebarSortAndScenarios
                  {...navigatorTopBarProps}
                  sortOptions={undefined} // sortOptions are in navigatorTopBarProps, but not used in this tab
                />
                <ViewpointNavigator
                  configPanelItems={configPanelItems}
                  viewpointNavigatorCommands={commands}
                />
              </>
            )}
            {activeTab === 'components' && (
              <>
                <ViewpointNavigatorFilter
                  filterState={navigatorTopBarProps.filterState}
                />
                <ViewpointSidebarSortAndScenarios
                  {...navigatorTopBarProps}
                  sortOptions={navigatorTopBarProps.sortOptions}
                />
                <NavigatorRoot />
              </>
            )}
          </ScrollableConfigPanelWrapper>

          <ViewpointSidebarFooter commands={commands} />
        </>
      )}
    </SidebarWrapper>
  );
};

const ScrollableConfigPanelWrapper = styled(Space)`
  overflow-y: auto;
`;

export default ViewpointSidebar;
