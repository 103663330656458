import BroadcastAudienceDialogFooter from '../BroadcastAudienceDialogFooter';
import styled from 'styled-components';
import workspaceAudienceDialog$ from './workspaceAudienceDialog$';
import GenericErrorContent from '../GenericErrorContent';
import { ModalContent, ModalTemplate } from '@ardoq/modal';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { WorkspaceOption } from './types';
import { choosePeopleWorkspace } from './actions';
import {
  ArdoqId,
  BroadcastPerson,
  WorkspaceAudienceData,
} from '@ardoq/api-types';
import WorkspaceAudienceTable from './WorkspaceAudienceTable';
import { Select as SelectBase } from '@ardoq/select';
import { s8 } from '@ardoq/design-tokens';
import { WarningNotification } from '@ardoq/status-ui';
import { Header3, Text } from '@ardoq/typography';
import { Box, Stack } from '@ardoq/layout';
import { BROADCAST_AUDIENCE_DIALOG_MODAL_SIZE } from '../dialogConsts';

const StackWithOverflow = styled(Stack)`
  overflow-y: auto;
`;

type WorkspaceAudienceDialogOwnProps = ModalContent<WorkspaceAudienceData>;

type WorkspaceAudienceDialogStreamedProps = {
  peopleWorkspaceOptions: WorkspaceOption[];
  workspaceId: ArdoqId | null;
  limitedToPersonIds: ArdoqId[];
  peopleInSelectedWorkspace: BroadcastPerson[];
  formIsValid: boolean;
  isUpdatingExistingAudience: boolean;
  failedToFetchPeopleWorkspaceOptions: boolean;
  failedToFetchPeopleInSelectedWorkspace: boolean;
  warningMessage: string | null;
};

const Select = styled(SelectBase)`
  max-width: 500px;
  margin: ${s8} 0 ${s8} 2px;
`;

const WorkspaceAudienceDialog = ({
  peopleWorkspaceOptions,
  workspaceId,
  limitedToPersonIds,
  formIsValid,
  isUpdatingExistingAudience,
  failedToFetchPeopleWorkspaceOptions,
  failedToFetchPeopleInSelectedWorkspace,
  warningMessage,
  resolve,
}: WorkspaceAudienceDialogOwnProps & WorkspaceAudienceDialogStreamedProps) => {
  const closeDialog = () => resolve(false);
  const addToAudienceList = async () => {
    if (!formIsValid) return;
    resolve({ workspaceId, limitedToPersonIds } as WorkspaceAudienceData);
  };

  const hasError =
    failedToFetchPeopleWorkspaceOptions ||
    failedToFetchPeopleInSelectedWorkspace;

  const placeholder = peopleWorkspaceOptions.length
    ? 'Select a people workspace'
    : 'No people workspaces found';

  return (
    <ModalTemplate
      modalSize={BROADCAST_AUDIENCE_DIALOG_MODAL_SIZE}
      headerText="Add Audience by People Workspace in Ardoq"
      onCloseButtonClick={closeDialog}
      footerContent={
        <BroadcastAudienceDialogFooter
          isUpdatingExistingAudience={isUpdatingExistingAudience}
          audienceCount={limitedToPersonIds.length}
          onCancel={closeDialog}
          onSubmit={addToAudienceList}
          formIsValid={formIsValid}
          hasError={hasError}
        />
      }
    >
      {hasError ? (
        <GenericErrorContent />
      ) : (
        <StackWithOverflow>
          <Box paddingX="large" paddingTop="medium">
            <Stack gap="small">
              <Header3>Select a people workspace in Ardoq</Header3>
              <Text>
                Build your audience directly from an Ardoq people workspace
              </Text>
              <Select
                placeholder={placeholder}
                options={peopleWorkspaceOptions}
                value={workspaceId}
                onValueChange={value =>
                  dispatchAction(choosePeopleWorkspace(value as ArdoqId))
                }
              />
              {warningMessage && (
                <WarningNotification>{warningMessage}</WarningNotification>
              )}
            </Stack>
          </Box>
          <WorkspaceAudienceTable />
        </StackWithOverflow>
      )}
    </ModalTemplate>
  );
};

export default connect(WorkspaceAudienceDialog, workspaceAudienceDialog$);
