import { formatDateTime } from '@ardoq/date-time';
import { map } from 'rxjs/operators';
import { FlyWheelTable } from '@ardoq/table';
import { DatasourceTable } from '@ardoq/table';
import { ClickableName } from './atoms';
import { PaginationController } from '@ardoq/pagination';
import presentations$ from 'streams/presentations/presentations$';
import { connect } from '@ardoq/rxbeach';
import { getCurrentLocale } from '@ardoq/locale';
import { APIPresentationAssetAttributes } from '@ardoq/api-types';
import { MatchGroups, findMatchGroups } from '@ardoq/common-helpers';
import { MatchGroupsRenderer } from '@ardoq/renderers';

type PresentationsBrowserProps = {
  presentations: APIPresentationAssetAttributes[];
  searchPhrase: string;
  loading?: boolean;
  scrollableSectionHeight?: number | string;
  openPresentation: (presentationId: string) => void;
};

type PresentationWithMeta = APIPresentationAssetAttributes & {
  meta?: { matchGroups: MatchGroups };
};

const PresentationsBrowser = ({
  presentations,
  searchPhrase,
  scrollableSectionHeight,
  loading,
  openPresentation,
}: PresentationsBrowserProps) => {
  const locale = getCurrentLocale();

  const filteredPresentations: PresentationWithMeta[] = searchPhrase
    ? presentations.reduce((acc: PresentationWithMeta[], item) => {
        const matchGroups = findMatchGroups(item.name, searchPhrase, locale);

        if (matchGroups.match) acc.push({ ...item, meta: { matchGroups } });

        return acc;
      }, [])
    : presentations;

  return (
    <PaginationController
      dataSource={filteredPresentations}
      // just so all presentations are loaded at once, without pagination
      perPage={1000}
      render={({ currentPageDataSource, sortById, sortOrder, sortBy }) => (
        <DatasourceTable
          loading={loading}
          fixedHeader
          scrollableSectionHeight={scrollableSectionHeight}
          components={FlyWheelTable}
          intercomTarget="presentations browser"
          renderEmptyTable={
            searchPhrase
              ? {
                  title: 'No results found',
                  subtitle: `There is no presentation name matching "${searchPhrase}"`,
                }
              : undefined
          }
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              onHeaderClick: () => sortBy('name'),
              sortOrder: sortById === 'name' && sortOrder,
              headerStyle: { paddingLeft: 67 },
              cellStyle: { paddingLeft: 67 },
              valueRender: (name, { _id, meta }) => (
                <ClickableName onClick={() => openPresentation(_id)}>
                  <MatchGroupsRenderer
                    matchGroups={meta?.matchGroups}
                    defaultValue={name}
                  />
                </ClickableName>
              ),
            },
            {
              title: 'Last modified',
              dataIndex: 'lastUpdated',
              onHeaderClick: () => sortBy('lastUpdated'),
              sortOrder: sortById === 'lastUpdated' && sortOrder,
              valueRender: value => formatDateTime(value, locale),
              headerStyle: { width: 300 },
            },
            {
              title: 'Modified by',
              dataIndex: 'lastModifiedByName',
              onHeaderClick: () => sortBy('lastModifiedByName'),
              sortOrder: sortById === 'lastModifiedByName' && sortOrder,
              headerStyle: { width: 300 },
              valueRender: value => (value !== 'Unknown' ? value : ''),
            },
          ]}
          dataSource={currentPageDataSource}
        />
      )}
    />
  );
};

export default connect(
  PresentationsBrowser,
  presentations$.pipe(
    map(presentationsState => ({
      presentations: presentationsState.list,
    }))
  )
);
