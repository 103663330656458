import {
  reportBuilderOperations,
  ReportBuilderQuerySectionProps,
  ReportBuilderSectionErrorProps,
  ReportDataUsedForSearch,
  ReportTemplate,
} from '@ardoq/report-builder';
import { APIFieldAttributes, DataSourceType } from '@ardoq/api-types';
import { isEqual, pick } from 'lodash';
import { getQueryBuilderLoaders } from 'search/QueryBuilderWithSuggestionsLoaders';
import {
  composeAdvancedSearchFilterGroups,
  QueryBuilderFilterDefinitionGroup,
  SearchContext,
} from '@ardoq/query-builder';
import { Maybe } from '@ardoq/common-helpers';
import { EnhancedSearchResponse } from '@ardoq/report-reader';

const getDataSourceSelectOptions = (canCreateGraphFilter: boolean) => [
  {
    label: 'Advanced search',
    'data-click-id': `report-builder-datasource-${DataSourceType.ADVANCED_SEARCH}`,
    value: DataSourceType.ADVANCED_SEARCH,
  },
  {
    label: 'Gremlin Graph Search',
    value: DataSourceType.GREMLIN_SEARCH,
    'data-click-id': `report-builder-datasource-${DataSourceType.GREMLIN_SEARCH}`,
    isDisabled: !canCreateGraphFilter,
  },
];

const getGetFilterGroups =
  (customFields: APIFieldAttributes[]) =>
  ({
    searchContext,
  }: {
    searchContext: SearchContext;
  }): QueryBuilderFilterDefinitionGroup[] =>
    composeAdvancedSearchFilterGroups(searchContext, customFields, false);

type GetQuerySectionPropsArgs = {
  canCreateGraphFilter: boolean;
  isSearchBeingExecuted: boolean;
  searchResults: Maybe<EnhancedSearchResponse>;
  reportTemplate: ReportTemplate;
  isValidAdvancedSearch: boolean;
  reportDataUsedForSearch: Maybe<ReportDataUsedForSearch>;
  errorFromSearch: Maybe<string>;
  hasSubdivisionsFeature: boolean;
  customFields: APIFieldAttributes[];
  customFieldsError: Maybe<string>;
  isFetchingCustomFields: boolean;
  hasWorkspacesWithNoAccess: boolean;
} & ReportBuilderSectionErrorProps;

export const getQuerySectionProps = ({
  reportTemplate,
  canCreateGraphFilter,
  isSearchBeingExecuted,
  searchResults,
  isValidAdvancedSearch,
  reportDataUsedForSearch,
  errorFromSearch,
  showErrorsForInvalidQuery,
  showErrorsOnInputs,
  hasSubdivisionsFeature,
  customFields,
  customFieldsError,
  isFetchingCustomFields,
  hasWorkspacesWithNoAccess,
}: GetQuerySectionPropsArgs): ReportBuilderQuerySectionProps => {
  const hasReportDataChangedSinceLastSearch = Boolean(
    reportDataUsedForSearch &&
      !isEqual(
        pick(reportTemplate, [
          'workspaceIds',
          'gremlinQuery',
          'advancedSearchQuery',
          'datasource',
          'subdivisions',
        ]),
        reportDataUsedForSearch
      )
  );

  const hasError =
    !reportTemplate.datasource ||
    !(reportTemplate.advancedSearchQuery || reportTemplate.gremlinQuery) ||
    (reportBuilderOperations.isAdvancedSearchBased(reportTemplate) &&
      !isValidAdvancedSearch);

  const isAdvancedSearchUsed =
    reportBuilderOperations.isAdvancedSearchBased(reportTemplate);

  return {
    selectedWorkspaceIds: reportTemplate.workspaceIds ?? [],
    selectedSubdivisionIds: Object.keys(reportTemplate.subdivisions),
    dataSourceSelectOptions: getDataSourceSelectOptions(canCreateGraphFilter),
    isSearchBeingExecuted,
    selectedDataSource: reportTemplate.datasource,
    totalNumberOfRowsInSearchResults: searchResults?.totalNumberOfRows ?? null,
    hasError,
    isPreviewSearchResultsButtonDisabled:
      !searchResults?.totalNumberOfRows || hasReportDataChangedSinceLastSearch,
    errorFromSearch,
    shouldShowErrorsOnQueryBuilder:
      !isValidAdvancedSearch &&
      (showErrorsOnInputs || showErrorsForInvalidQuery),
    getFilterGroups: getGetFilterGroups(customFields),
    customFieldsError,
    customFields,
    isFetchingCustomFields,
    ...(isAdvancedSearchUsed && !hasWorkspacesWithNoAccess
      ? {
          shouldShowQueryBuilder: true,
          shouldShowGremlinCodeEditor: false,
          query: reportTemplate.advancedSearchQuery,
          suggestionLoaders: getQueryBuilderLoaders(
            reportTemplate.workspaceIds,
            hasSubdivisionsFeature ? reportTemplate.subdivisions : undefined
          ),
        }
      : {
          shouldShowQueryBuilder: false,
          shouldShowGremlinCodeEditor:
            !isAdvancedSearchUsed && canCreateGraphFilter,
          query: reportTemplate.gremlinQuery,
          suggestionLoaders: null,
        }),
  };
};
