import {
  ChevronIconWrapper,
  HeaderWrapper,
  MainStepItem,
  StepsBody,
  StepsGroupTitle,
  StepsGroupWrapper,
  SubStepCount,
  SubStepItem,
  Wrapper,
} from './atoms';
import { DisabledReason, MergeState } from 'scope/merge/types';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { ChevronIcon, Icon, IconName } from '@ardoq/icons';
import { mapDiffMetaStatesToSteps } from './utils';
import { last } from 'lodash';
import StepSvg from './StepSvg';
import StepsSvgDefinitions from './StepsSvgDefinitions';
import {
  handleMergeStepExpandToggle,
  handleMergeStepSelect,
} from './actionWrappers';
import { Step, StepType } from './types';
import { withPlainTextPopover } from '@ardoq/popovers';
import ScenarioMergeIntercomTourClickIds from 'components/DiffMergeTable/intercomTourClickIds';

const LoadingDiffMergeSidebarNavigator = () => <Wrapper />;

const Header = ({ mergeDirection }: { mergeDirection: MergeDirection }) => {
  const [from, to] =
    mergeDirection === MergeDirection.BRANCH_TO_MAINLINE
      ? ['Scenario', 'Mainline']
      : ['Mainline', 'Scenario'];
  return (
    <HeaderWrapper
      data-click-id={ScenarioMergeIntercomTourClickIds.MERGE_DIRECTION}
    >
      {from}
      <Icon iconName={IconName.ARROW_FORWARD} />
      {to}
    </HeaderWrapper>
  );
};

const disabledReasonsUiStrings = {
  [DisabledReason.MISSING_COMPONENT_TYPE]:
    'Missing component types must be created first',
  [DisabledReason.MISSING_REFERENCE_TYPE]:
    'Missing reference types must be created first',
  [DisabledReason.MISSING_FIELD]: 'Missing fields must be created first',
};

export const getDisabledSubStepPopover = (disabledReason: DisabledReason) => {
  if (
    disabledReason === DisabledReason.NONE ||
    disabledReason === DisabledReason.NOTHING_TO_MERGE
  ) {
    return {};
  }
  return withPlainTextPopover(disabledReasonsUiStrings[disabledReason]);
};

const DiffMergeSidebarStep = ({
  step,
  isLast,
}: {
  step: Step;
  isLast: boolean;
}) => (
  <StepsBody>
    <MainStepItem
      $isSelected={step.isSelected}
      $isExpanded={step.isExpanded}
      $isLast={isLast}
      onClick={() => handleMergeStepExpandToggle(step.name)}
    >
      {step.name}
      <ChevronIconWrapper>
        <ChevronIcon direction={step.isExpanded ? 'up' : 'down'} />
      </ChevronIconWrapper>
    </MainStepItem>
    {step.isExpanded &&
      step.subSteps.map(subStep => (
        <SubStepItem
          key={step.name + subStep.name}
          $isSelected={subStep.isSelected}
          onClick={
            subStep.isDisabled
              ? undefined
              : () => handleMergeStepSelect(step.name, subStep.name)
          }
          $isLast={!isLast && subStep === last(step.subSteps)}
          $isDisabled={subStep.isDisabled}
          {...getDisabledSubStepPopover(subStep.disabledReason)}
        >
          <span>{subStep.name}</span>
          <SubStepCount>
            <Icon
              iconName={
                subStep.isSubmitted ? IconName.CHECK_MARK : IconName.NONE
              }
            />
            <span>{subStep.entityCount}</span>
          </SubStepCount>
        </SubStepItem>
      ))}
    <StepSvg step={step} isLast={isLast} />
  </StepsBody>
);

const DiffMergeSidebarNavigator = ({
  mergeDirection,
  diffMetaStates,
  mainStateStep,
  subStateStep,
  submittedSteps,
  expandedMainSteps,
}: MergeState) => {
  if (!diffMetaStates) return <LoadingDiffMergeSidebarNavigator />;
  const metamodelSteps = mapDiffMetaStatesToSteps(
    diffMetaStates,
    mainStateStep,
    subStateStep,
    expandedMainSteps,
    StepType.METAMODEL,
    submittedSteps
  );

  const modelSteps = mapDiffMetaStatesToSteps(
    diffMetaStates,
    mainStateStep,
    subStateStep,
    expandedMainSteps,
    StepType.MODEL,
    submittedSteps
  );

  return (
    <>
      <Wrapper data-click-id={ScenarioMergeIntercomTourClickIds.NAVIGATOR}>
        <Header mergeDirection={mergeDirection} />
        {Boolean(metamodelSteps.length) && (
          <>
            <StepsGroupTitle>Metamodel</StepsGroupTitle>
            <StepsGroupWrapper>
              {metamodelSteps.map((step: Step, stepIndex) => (
                <DiffMergeSidebarStep
                  key={step.name}
                  step={step}
                  isLast={stepIndex === metamodelSteps.length - 1}
                />
              ))}
            </StepsGroupWrapper>
          </>
        )}
        {Boolean(modelSteps.length) && (
          <>
            <StepsGroupTitle>Data</StepsGroupTitle>
            <StepsGroupWrapper>
              {modelSteps.map((step: Step, stepIndex) => (
                <DiffMergeSidebarStep
                  key={step.name}
                  step={step}
                  isLast={stepIndex === modelSteps.length - 1}
                />
              ))}
            </StepsGroupWrapper>
          </>
        )}
        <StepsSvgDefinitions />
      </Wrapper>
    </>
  );
};

export default DiffMergeSidebarNavigator;
