import { map } from 'rxjs/operators';
import {
  emptyCollectionStream,
  toCollectionStream,
} from 'streams/utils/streamUtils';
import { ArdoqId, ResourceType, APIBookmarkAttributes } from '@ardoq/api-types';
import {
  reducedStream,
  streamReducer,
  toPersistentStream,
} from '@ardoq/rxbeach';
import { fetchAllReducer, websocketReducer } from 'streams/crud/reducers';
import { toResourceTypeStream, websocket$ } from 'sync/websocket$';
import { returnPayload } from '@ardoq/common-helpers';

export const bookmarksNamespace = 'bookmarks';

const handleBookmarkRemoved = (
  state: APIBookmarkAttributes[],
  id: ArdoqId
): APIBookmarkAttributes[] => {
  return state.filter(bookmark => bookmark._id !== id);
};

const handleBookmarkUpdated = (
  state: APIBookmarkAttributes[],
  bookmark: APIBookmarkAttributes
): APIBookmarkAttributes[] => {
  return state.map(existingBookmark =>
    existingBookmark._id === bookmark._id ? bookmark : existingBookmark
  );
};

const handleBookmarkAdded = (
  state: APIBookmarkAttributes[],
  bookmark: APIBookmarkAttributes
): APIBookmarkAttributes[] => [...state, bookmark];

const bookmarksList$ = reducedStream<APIBookmarkAttributes[]>(
  'bookmarksList$',
  [],
  [
    fetchAllReducer(returnPayload<APIBookmarkAttributes[]>),
    streamReducer(
      toResourceTypeStream<APIBookmarkAttributes>(
        websocket$,
        ResourceType.BOOKMARK
      ),
      websocketReducer({
        create: handleBookmarkAdded,
        update: handleBookmarkUpdated,
        delete: handleBookmarkRemoved,
      })
    ),
  ],
  { namespace: bookmarksNamespace }
);

export default toPersistentStream(
  'bookmarks$',
  bookmarksList$.pipe(map(toCollectionStream)),
  emptyCollectionStream()
);
