import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import currentUser$ from 'streams/currentUser/currentUser$';
import groups$ from 'subdivisionEditor/Steps/permissionsConfiguration/zones/groups$/groups$';
import zonesViewState$ from './zonesViewState$/zonesViewState$';
import { ZonesViewModelState } from './types';
import { zoneCommands } from './commands';
import permissions$ from './permissions$/permissions$';
import componentTypes$ from './componentTypes$/componentTypes$';
import { componentTypesOperations } from './componentTypes$/componentTypesOperations';
import { zonesOperations } from './operations';
import bindWorkspaces$ from 'subdivisionEditor/Steps/BindWorkspaces/bindWorkspaces$';
import { globalFields$ } from 'streams/fields/globalFields$';
import {
  APIResourcePermissionAttributes,
  ArdoqId,
  MetaModelComponentType,
} from '@ardoq/api-types';
import workspaces$ from 'streams/workspaces/workspaces$';
import subdivisionNavigation$ from 'subdivisionEditor/navigation/subdivisionNavigation$';
import { ENTIRE_ORGANIZATION_GROUP } from './groups$/types';
import { SearchByTypes } from './components/ComponentTypeFieldsGroupSearch';

export const zonesViewModel$: Observable<ZonesViewModelState> = combineLatest([
  componentTypes$,
  subdivisions$,
  groups$,
  permissions$,
  bindWorkspaces$,
  zonesViewState$,
  globalFields$,
  currentUser$,
  workspaces$,
  subdivisionNavigation$,
]).pipe(
  map(
    ([
      componentTypesState,
      subdivisionsStreamState,
      groupsState,
      permissionsState,
      bindWorkspacesState,
      zonesViewState,
      globalFieldsByName,
      currentUserState,
    ]) => {
      const isLoading =
        componentTypesState.isLoading &&
        subdivisionsStreamState.isFetching &&
        permissionsState.isLoading &&
        groupsState.isLoading;

      const { sortedZonesIds } = zonesViewState;

      const selectedAndSavedWorkspaceIds: ArdoqId[] = [
        ...bindWorkspacesState.workspacesBoundToSubdivisionIds,
        ...bindWorkspacesState.selectedWorkspaces,
      ];
      const { componentTypeFieldsGroupSearch } = zonesViewState;
      const componentTypes: MetaModelComponentType[] =
        componentTypesState.componentTypes
          .filter(componentTypesOperations.hasComponentTypeField)
          .filter(componentType =>
            zonesOperations.isComponentTypeUsedByOneOfWorkspaces(
              componentType,
              selectedAndSavedWorkspaceIds
            )
          )
          .filter(componentType => {
            if (
              componentTypeFieldsGroupSearch.searchBy !==
              SearchByTypes.COMPONENT_TYPE
            ) {
              return true;
            }
            return zonesOperations.componentTypeMatchesSearchKey(
              componentType,
              componentTypeFieldsGroupSearch.searchKey
            );
          });

      const groups = [ENTIRE_ORGANIZATION_GROUP, ...groupsState.groups];

      const permissionsByZoneId: Record<
        ArdoqId,
        APIResourcePermissionAttributes
      > = {};
      sortedZonesIds.forEach(
        zoneId =>
          (permissionsByZoneId[zoneId] =
            zonesViewState.permissionsByZoneId[zoneId])
      );

      return {
        isLoading,
        componentTypes,
        componentTypeFieldsGroupSearch:
          zonesViewState.componentTypeFieldsGroupSearch,
        subdivisionsContext: subdivisionsStreamState,
        allGroups: groups,
        permissionsByZoneId,
        zonesById: zonesViewState.zonesById,
        globalFieldsByName,
        currentUser: currentUserState,
        editingZoneNames: zonesViewState.editingZoneNames,
        commands: zoneCommands,
      };
    }
  )
);
