import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { PageTopbarProps } from '@ardoq/page-layout';
import { BetaBadge } from '@ardoq/status-ui';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import TrialDaysRemaining from 'menus/topbar/TrialDaysRemaining';
import { ComponentProps } from 'react';
import { PageTopbar } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';

const NavbarTrialDaysRemainingContainer = styled.div`
  flex: 1 1 auto;
`;
const NavbarTrialDaysRemaining = (
  props: ComponentProps<typeof TrialDaysRemaining>
) => (
  <NavbarTrialDaysRemainingContainer>
    <TrialDaysRemaining {...props} />
  </NavbarTrialDaysRemainingContainer>
);

const Nav = styled.nav`
  display: flex;
  align-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;
  color: ${colors.bgDefault};
`;

type NavbarProps = PageTopbarProps & {
  showBetaBadge?: boolean;
  isTrial?: boolean;
  daysRemaining?: number;
};

const Navbar = ({
  primaryContent,
  primaryButton,
  secondaryContent,
  secondaryButton,
  toolbarContent,
  showBetaBadge = false,
  isTrial = false,
  daysRemaining = Infinity,
}: NavbarProps) => {
  return (
    <Nav>
      <PageTopbar
        primaryContent={
          showBetaBadge ? (
            <FlexBox gap="small" align="center">
              {primaryContent}
              <BetaBadge
                knowledgeBaseUrl={KnowledgeBaseLink.EASE_OF_USE_HOMEPAGE}
                shareFeedbackUrl={'https://www.surveymonkey.com/r/9WZR3XR'}
              />
            </FlexBox>
          ) : (
            primaryContent
          )
        }
        primaryButton={primaryButton}
        secondaryContent={secondaryContent}
        secondaryButton={secondaryButton}
        toolbarContent={toolbarContent}
        middleContent={
          isTrial ? (
            <NavbarTrialDaysRemaining daysRemaining={daysRemaining} />
          ) : undefined
        }
      />
    </Nav>
  );
};

export default Navbar;
