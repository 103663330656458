import { useState } from 'react';
import { getWorkspaceTemplateCategories } from 'collections/modelCategories';
import { createViewOptions } from 'models/utils/viewUtils';
import { ModalLayout, ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { Checkbox, TextArea, TextInput } from '@ardoq/forms';
import { Multiselect, Select, SelectOptionsOrGroups } from '@ardoq/select';
import { dispatchAction } from '@ardoq/rxbeach';
import { APIModelAttributes, ViewIds, MutableTemplate } from '@ardoq/api-types';
import { createTemplate, updateTemplate } from 'streams/templates/routines';
import { availableViews$ } from 'views/availableViews$';

interface TemplateEditorProps {
  initialState: MutableTemplate;
  headerText: string;
  primaryButtonText: string;
  onClose: VoidFunction;
  onSubmit: (f: MutableTemplate) => void;
  availableDefaultViews: ViewIds[];
}

const getTemplateCategoryOptions = (): SelectOptionsOrGroups<string> =>
  getWorkspaceTemplateCategories().map(({ label, categories }) => ({
    label: label ?? undefined,
    options: categories.map(({ name, icon }) => ({
      label: name,
      value: name,
      iconName: icon,
    })),
  }));

const TemplateEditor = ({
  initialState,
  headerText,
  primaryButtonText,
  onClose,
  onSubmit,
  availableDefaultViews,
}: TemplateEditorProps) => {
  const [formState, onFormChange] = useState<MutableTemplate>(initialState);
  return (
    <ModalTemplate
      modalSize={ModalSize.S}
      headerText={headerText}
      primaryButtonText={primaryButtonText}
      isPrimaryButtonDisabled={!formState.name}
      onPrimaryButtonClick={() => {
        onSubmit(formState);
      }}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={() => onClose()}
    >
      <ModalLayout>
        <TextInput
          label="Name"
          value={formState.name}
          onValueChange={name => onFormChange({ ...formState, name })}
        />
        <Checkbox
          isChecked={formState.flexible}
          onChange={flexible => onFormChange({ ...formState, flexible })}
        >
          Flexible Hierarchical Structure
        </Checkbox>
        <Select
          label="Category"
          value={formState.category}
          onValueChange={category =>
            onFormChange({ ...formState, category: category as string })
          }
          options={getTemplateCategoryOptions()}
        />
        <Select
          label="Start View"
          value={formState.startView}
          onValueChange={startView => {
            onFormChange({
              ...formState,
              startView: startView as ViewIds,
            });
          }}
          isClearable={true}
          options={createViewOptions().map(({ val: value, label }) => ({
            value,
            label,
          }))}
        />
        <Multiselect
          label="Default Views"
          value={formState.defaultViews}
          onValueChange={defaultViews =>
            onFormChange({
              ...formState,
              defaultViews: defaultViews as ViewIds[],
            })
          }
          options={createViewOptions(availableDefaultViews).map(
            ({ val: value, label }) => ({
              value,
              label,
            })
          )}
        />
        <TextArea
          label="Description"
          value={formState.description || ''}
          onValueChange={description =>
            onFormChange({ ...formState, description })
          }
        />
      </ModalLayout>
    </ModalTemplate>
  );
};

const availableDefaultViews = () =>
  availableViews$.state.ids.filter(option => option !== ViewIds.PAGESVIEW);

export const createTemplateFromModel = (
  model: APIModelAttributes,
  defaultViews: ViewIds[]
) => {
  const initialState = {
    name: '',
    flexible: false,
    category: model.category,
    startView: null,
    defaultViews: defaultViews,
    description: '',
  };
  modal(resolve => (
    <TemplateEditor
      initialState={initialState}
      primaryButtonText="Create"
      headerText="Create Template From Current Metamodel"
      availableDefaultViews={availableDefaultViews()}
      onSubmit={(formState: MutableTemplate) => {
        dispatchAction(
          createTemplate({
            model: model._id,
            ...formState,
          })
        );
        resolve(false);
      }}
      onClose={() => resolve(false)}
    />
  ));
};

export const showEditTemplateDialog = (template: APIModelAttributes) => {
  const initialState = {
    name: template.name,
    flexible: template.flexible,
    category: template.category,
    startView: template.startView,
    defaultViews: template.defaultViews,
    description: template.description,
  };
  modal(resolve => (
    <TemplateEditor
      initialState={initialState}
      headerText={`Edit template '${template.name}'`}
      primaryButtonText="Change"
      availableDefaultViews={availableDefaultViews()}
      onClose={() => resolve(false)}
      onSubmit={(formState: MutableTemplate) => {
        dispatchAction(
          updateTemplate({
            _id: template._id,
            ...formState,
          })
        );
        resolve(false);
      }}
    />
  ));
};
