import {
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import {
  loadUseCase,
  loadUseCaseFailed,
  loadUseCaseSuccess,
  selectUseCase,
  setActiveLandingPageTab,
} from './actions';
import {
  APIMetaModelPreview,
  ArdoqId,
  PersistedUseCase,
  UseCaseSummary,
} from '@ardoq/api-types';
import { UseCaseLandingPageState, UseCaseLandingPageTab } from './types';
import useCases$ from 'useCases/useCases$';
import { ArdoqError, Maybe, Result } from '@ardoq/common-helpers';
import { metaModelPreview$ } from 'useCases/UseCaseSetup/loadMetamodelPreview$';
import { useCaseOperations } from 'useCases/useCaseOperations';
import { useCase$ } from './useCase$';

const setSelectedUseCase = (
  state: UseCaseLandingPageState,
  selectedUseCaseId: Maybe<ArdoqId>
) => ({
  ...state,
  selectedUseCaseId,
  selectedUseCase:
    state.useCases.find(uc => uc._id === selectedUseCaseId) ?? null,
});

const setIsLoadingUseCase = (state: UseCaseLandingPageState) => ({
  ...state,
  isLoadingUseCase: true,
});

const setErrorMessageAndLoadingState = (
  state: UseCaseLandingPageState,
  error: ArdoqError
): UseCaseLandingPageState => ({
  ...state,
  loadUseCaseErrorProps: { error },
  isLoadingUseCase: false,
});

const removeErrorMessageAndSetLoadingState = (
  state: UseCaseLandingPageState
): UseCaseLandingPageState => ({
  ...state,
  loadUseCaseErrorProps: null,
  isLoadingUseCase: false,
});

const removeErrorMessageAndSelectNewId = (
  state: UseCaseLandingPageState,
  newUseCaseId: ArdoqId
) =>
  setSelectedUseCase(removeErrorMessageAndSetLoadingState(state), newUseCaseId);

const setMetaModelPreview = (
  state: UseCaseLandingPageState,
  metaModelPreview: Result<APIMetaModelPreview> | null
): UseCaseLandingPageState => ({
  ...state,
  selectedUseCaseMetaModelPreview: metaModelPreview,
});

const setUseCases = (
  state: UseCaseLandingPageState,
  useCases: UseCaseSummary[]
): UseCaseLandingPageState => {
  const selectedUseCase = useCaseOperations.getSelectedUseCaseOrDefault(
    useCases,
    state.selectedUseCaseId
  );
  return {
    ...state,
    useCases,
    selectedUseCase,
    selectedUseCaseId: selectedUseCase?._id ?? state.selectedUseCaseId,
  };
};

const setActiveTab = (
  state: UseCaseLandingPageState,
  activeTab: UseCaseLandingPageTab
): UseCaseLandingPageState => ({
  ...state,
  activeTab,
});

const setUseCase = (
  state: UseCaseLandingPageState,
  useCasePreview: PersistedUseCase | null
): UseCaseLandingPageState => ({
  ...state,
  useCasePreview,
});

const defaultState: UseCaseLandingPageState = {
  selectedUseCaseMetaModelPreview: null,
  isLoadingUseCase: false,
  loadUseCaseErrorProps: null,
  useCases: [],
  selectedUseCaseId: null,
  selectedUseCase: null,
  activeTab: UseCaseLandingPageTab.METAMODEL,
  useCasePreview: null,
};

const useCaseLandingPage$ = persistentReducedStream(
  'useCaseLandingPage$',
  defaultState,
  [
    streamReducer(useCases$, setUseCases),
    streamReducer(metaModelPreview$, setMetaModelPreview),
    streamReducer(useCase$, setUseCase),
    reducer(selectUseCase, setSelectedUseCase),
    reducer(loadUseCaseSuccess, setSelectedUseCase),
    reducer(loadUseCase, setIsLoadingUseCase),
    reducer(loadUseCaseFailed, setErrorMessageAndLoadingState),
    reducer(loadUseCaseSuccess, removeErrorMessageAndSelectNewId),
    reducer(setActiveLandingPageTab, setActiveTab),
  ]
);

export default useCaseLandingPage$;
