import audiencePreview$ from 'broadcasts/broadcastBuilder/audiencePreview/audiencePreview$';
import { AudienceTagsStreamShape } from './types';
import {
  getDefaultState,
  toAudienceCountByType,
  toAudienceWarningByType,
} from './utils';
import { streamReducer } from '@ardoq/rxbeach';
import {
  AudiencePreviewStatus,
  AudiencePreviewStreamShape,
} from 'broadcasts/broadcastBuilder/audiencePreview/types';

const audiencePreviewReducer = (
  state: AudienceTagsStreamShape,
  { audiencePreview, status }: AudiencePreviewStreamShape
) => {
  switch (status) {
    case AudiencePreviewStatus.LOADING:
      return {
        ...state,
        status,
      };
    case AudiencePreviewStatus.IDLE:
    case AudiencePreviewStatus.ERROR:
    case AudiencePreviewStatus.INCOMPLETE_CONTENT_SELECTION:
      return {
        ...state,
        audienceCountByType: getDefaultState().audienceCountByType,
        audienceWarningByType: getDefaultState().audienceWarningByType,
        status,
      };
    case AudiencePreviewStatus.SUCCESS:
      return {
        ...state,
        audienceCountByType: toAudienceCountByType(audiencePreview),
        audienceWarningByType: toAudienceWarningByType(audiencePreview),
        status,
      };
  }
};

export const reducers = [
  streamReducer(audiencePreview$, audiencePreviewReducer),
];
