import { Route, RouterLocation } from 'router/StreamRouter';
import { AppRouterState } from 'router/appRouterTypes';
import { dispatchAction } from '@ardoq/rxbeach';
import { from } from 'rxjs';
import { AppModules } from 'appContainer/types';
import { UserSettingsRoute, UserSettingsTabs } from './types';
import { PAGE_TITLES } from './consts';
import UserSettingsView$ from './UserSettingsView$';
import { navigateToUserSettings } from './actions';

const isEnumValue = <T extends ArrayLike<unknown>>(enumObject: T) => {
  const options = Object.values(enumObject);
  return (value: T): boolean => options.includes(value);
};

const isValidUserSettingsTab = isEnumValue<UserSettingsTabs>(
  UserSettingsTabs as unknown as UserSettingsTabs
);

const doesLocationMatch = ({ path }: RouterLocation) =>
  /\/account-preferences\/?.*?/.test(path) || /\/profile\/?.*?/.test(path);

const locationToRouterState = ({ path }: RouterLocation) => {
  const appModule = AppModules.USER_SETTINGS;
  const parts = path.split('/');
  const userSettingsSelectedTab = parts[2] as UserSettingsTabs;

  return {
    appModule,
    userSettingsSelectedTab: isValidUserSettingsTab(userSettingsSelectedTab)
      ? userSettingsSelectedTab
      : UserSettingsTabs.PROFILE,
  };
};

const doesRouterStateMatch = ({ appModule }: AppRouterState) =>
  appModule === AppModules.USER_SETTINGS;

const routerStateToLocation = ({
  userSettingsSelectedTab,
}: UserSettingsRoute) => {
  const path = `/${AppModules.USER_SETTINGS}/${userSettingsSelectedTab}`;
  return {
    path,
    title:
      PAGE_TITLES[
        isValidUserSettingsTab(userSettingsSelectedTab)
          ? userSettingsSelectedTab
          : UserSettingsTabs.PROFILE
      ],
  };
};

const setApplicationStateFromRoute = ({
  userSettingsSelectedTab,
}: UserSettingsRoute) => {
  dispatchAction(navigateToUserSettings(userSettingsSelectedTab));
};

const getPartialRouterStateStream = () => from(UserSettingsView$);

export default new Route<AppRouterState, UserSettingsRoute>({
  doesLocationMatch,
  locationToRouterState,
  doesRouterStateMatch,
  routerStateToLocation,
  setApplicationStateFromRoute,
  getPartialRouterStateStream,
});
