import {
  getSearchContextRule,
  queryBuilderOperations,
  SearchContext,
} from '@ardoq/query-builder';
import { AdHocSearchReport } from '@ardoq/report-builder';
import { isEmpty, keyBy, sortBy } from 'lodash';
import {
  APIReportAttributes,
  BooleanOperator,
  DataSourceType,
  InventoryColumnState,
  PersonalSetting,
  QueryBuilderSubquery,
  ReportColumn,
  SortOrder,
} from '@ardoq/api-types';
import {
  ComponentTypeInventoryDatasourceSelection,
  InventoryColumnDefinitionWithSelectionState,
  InventoryDatasourceSelection,
  ReportInventoryDatasourceSelection,
  WorkspaceInventoryDatasourceSelection,
} from '@ardoq/inventory';
import { CurrentUserState } from 'streams/currentUser/currentUser$';
import { InventoryColumnDefinitionWithOptionList } from './types';
import { Maybe } from '@ardoq/common-helpers';
import {
  DataOverviewInventoryNavigationState,
  InventoryNavigationState,
  ReportInventoryNavigationState,
  WorkspaceInventoryNavigationState,
} from './inventoryNavigation$';

const getColumnSelectionFromUserSettings = (
  currentUserState: CurrentUserState,
  dataSource:
    | WorkspaceInventoryDatasourceSelection
    | ComponentTypeInventoryDatasourceSelection
): InventoryColumnState[] => {
  if (isWorkspaceInventoryDatasourceSelection(dataSource)) {
    return (
      currentUserState.settings[
        PersonalSetting.INVENTORY_RECENT_COLUMN_SELECTION_PER_WORKSPACE_OR_TYPE
      ]?.[
        dataSource.selectedWorkspaceIds.join(
          getAssetIdSeparatorForUserPreferences()
        )
      ]?.columns ?? []
    );
  }
  if (isComponentTypeInventoryDatasourceSelection(dataSource)) {
    return (
      currentUserState.settings[
        PersonalSetting.INVENTORY_RECENT_COLUMN_SELECTION_PER_WORKSPACE_OR_TYPE
      ]?.[
        dataSource.componentTypeNames.join(
          getAssetIdSeparatorForUserPreferences()
        )
      ]?.columns ?? []
    );
  }
  return [];
};

const addIsVisibleSortAndPinnedToColumn = <
  T extends ReportColumn | InventoryColumnDefinitionWithOptionList,
>(
  column: T
): T & { isVisible: boolean; pinned: null; sort: Maybe<SortOrder> } => ({
  ...column,
  isVisible: true,
  pinned: null,
  sort: column.sort ?? null,
});

const isWorkspaceInventoryNavigationState = (
  navigationState: InventoryNavigationState
): navigationState is WorkspaceInventoryNavigationState =>
  navigationState.dataSourceType === 'workspace';

const isNoneInventoryNavigationState = (
  navigationState: InventoryNavigationState
): navigationState is DataOverviewInventoryNavigationState =>
  navigationState.dataSourceType === 'none';

const isReportInventoryNavigationState = (
  navigationState: InventoryNavigationState
): navigationState is ReportInventoryNavigationState =>
  navigationState.dataSourceType === 'report';

const isWorkspaceInventoryDatasourceSelection = (
  datasourceSelection: InventoryDatasourceSelection
): datasourceSelection is WorkspaceInventoryDatasourceSelection =>
  datasourceSelection.dataSourceType === 'workspace';

const isReportInventoryDatasourceSelection = (
  datasourceSelection: InventoryDatasourceSelection
): datasourceSelection is ReportInventoryDatasourceSelection =>
  datasourceSelection.dataSourceType === 'report';

const isComponentTypeInventoryDatasourceSelection = (
  datasourceSelection: InventoryDatasourceSelection
): datasourceSelection is ComponentTypeInventoryDatasourceSelection =>
  datasourceSelection.dataSourceType === 'componentType';

const getColumnsBasedOnDatasourceSelection = (
  datasource:
    | WorkspaceInventoryDatasourceSelection
    | ReportInventoryDatasourceSelection
    | ComponentTypeInventoryDatasourceSelection,
  currentUser: CurrentUserState
) => {
  if (
    isWorkspaceInventoryDatasourceSelection(datasource) ||
    isComponentTypeInventoryDatasourceSelection(datasource)
  ) {
    return getColumnSelectionFromUserSettings(currentUser, datasource);
  }
  return datasource.report.columns.map(addIsVisibleSortAndPinnedToColumn);
};

const applyColumnSelection = ({
  allAvailableColumns,
  currentUser,
  datasource,
}: {
  allAvailableColumns: InventoryColumnDefinitionWithOptionList[];
  currentUser: CurrentUserState;
  datasource:
    | ReportInventoryDatasourceSelection
    | WorkspaceInventoryDatasourceSelection
    | ComponentTypeInventoryDatasourceSelection;
}): InventoryColumnDefinitionWithSelectionState[] => {
  const columnSelection = getColumnsBasedOnDatasourceSelection(
    datasource,
    currentUser
  );

  if (isEmpty(columnSelection)) {
    return moveNameColumnToFirstPosition(allAvailableColumns).map(
      addIsVisibleSortAndPinnedToColumn
    );
  }

  return mapAllAvailableColumnsToColumnDefinitions(
    allAvailableColumns,
    columnSelection,
    {
      shouldDefaultVisibleToTrue:
        isWorkspaceInventoryDatasourceSelection(datasource) ||
        isComponentTypeInventoryDatasourceSelection(datasource),
    }
  );
};

const moveNameColumnToFirstPosition = (
  columns: InventoryColumnDefinitionWithOptionList[]
): InventoryColumnDefinitionWithOptionList[] => {
  const nameColumn = columns.find(column => column.key === 'name');
  if (!nameColumn) {
    return columns;
  }
  return [
    nameColumn,
    ...columns.filter(column => column.key !== nameColumn.key),
  ];
};

const mapAllAvailableColumnsToColumnDefinitions = (
  allAvailableColumns: InventoryColumnDefinitionWithOptionList[],
  previousColumnSelection: InventoryColumnState[],
  { shouldDefaultVisibleToTrue }: { shouldDefaultVisibleToTrue: boolean }
) => {
  const allAvailableColumnsWithPinnedAndHiddenColumns = allAvailableColumns.map(
    column => {
      const columnStateFromPreviousSelection = previousColumnSelection.find(
        previousColumn => previousColumn.key === column.key
      );
      return {
        ...column,
        pinned: columnStateFromPreviousSelection?.pinned ?? null,
        isVisible:
          columnStateFromPreviousSelection?.isVisible ??
          shouldDefaultVisibleToTrue,
        sort: columnStateFromPreviousSelection?.sort ?? null,
      };
    }
  );

  const columnKeyOrder = previousColumnSelection.map(column => column.key);
  return sortBy(allAvailableColumnsWithPinnedAndHiddenColumns, column => {
    const index = columnKeyOrder.indexOf(column.key);
    return index === -1 ? Number.MAX_SAFE_INTEGER : index; // place non-found elements at the end
  });
};

const getAssetIdSeparatorForUserPreferences = () => ',';

const getSubqueryFromDatasource = ({
  report: { query },
}: {
  report: APIReportAttributes;
}): QueryBuilderSubquery =>
  query && typeof query !== 'string'
    ? query?.rules[1] // This is getting just the subquery part of the query. [0] is the search context rule, just defining the asset we are querying for. For inventory, it's always a component
    : { condition: BooleanOperator.AND, rules: [] }; // TODO: improve type for report to avoid having to pass this

const getSubqueryFromComponentTypeNames = (
  componentTypeNames: string[]
): QueryBuilderSubquery => {
  return {
    condition: BooleanOperator.OR,
    rules: componentTypeNames.map(componentTypeName =>
      queryBuilderOperations.getComponentTypeQueryRule(componentTypeName)
    ),
  };
};

const getWorkspaceIdsFromDatasource = (
  datasource: InventoryDatasourceSelection
) => {
  switch (datasource.dataSourceType) {
    case 'workspace':
      return datasource.selectedWorkspaceIds;
    case 'report':
      return datasource.report.workspaceIds ?? [];
    case 'componentType':
      return datasource.workspaceIds;
    case 'none':
      return [];
    default:
      datasource satisfies never;
      return [];
  }
};

const getSubqueryFromDatasourceAndParams = (
  datasource: InventoryDatasourceSelection,
  subqueryFromAgGrid: QueryBuilderSubquery
): QueryBuilderSubquery => {
  switch (datasource.dataSourceType) {
    case 'componentType':
      return {
        condition: BooleanOperator.AND,
        rules: [
          inventoryOperations.getSubqueryFromComponentTypeNames(
            datasource.componentTypeNames
          ),
          subqueryFromAgGrid,
        ],
      };
    case 'workspace':
      return subqueryFromAgGrid;
    case 'report':
      return inventoryOperations.getSubqueryFromDatasource(datasource);
    case 'none':
      return subqueryFromAgGrid;
    default:
      datasource satisfies never;
      return subqueryFromAgGrid;
  }
};

const createAdHocSearchReportFromFilter = (
  datasource: InventoryDatasourceSelection,
  inventoryColumnFiltersAsSubquery: QueryBuilderSubquery,
  allAvailableColumns: InventoryColumnDefinitionWithOptionList[],
  inventoryColumns: InventoryColumnState[]
): AdHocSearchReport => {
  const subquery = inventoryOperations.getSubqueryFromDatasourceAndParams(
    datasource,
    inventoryColumnFiltersAsSubquery
  );

  const allColumns = keyBy(allAvailableColumns, 'key');

  const selectedColumns = inventoryColumns
    .filter(column => column.isVisible)
    .map(inventoryColumn => ({
      ...allColumns[inventoryColumn.key],
      sort: inventoryColumn.sort,
    }));

  return {
    datasource: DataSourceType.ADVANCED_SEARCH,
    columns: selectedColumns,
    query: queryBuilderOperations.filterOutEmptyRulesFromQuery({
      condition: BooleanOperator.AND,
      rules: [getSearchContextRule(SearchContext.COMPONENT), subquery],
    }),
    workspaceIds: inventoryOperations.getWorkspaceIdsFromDatasource(datasource),
  };
};

export const inventoryOperations = {
  applyColumnSelection,
  isWorkspaceInventoryNavigationState,
  isReportInventoryNavigationState,
  isNoneInventoryNavigationState,
  getSubqueryFromDatasource,
  getSubqueryFromComponentTypeNames,
  isWorkspaceInventoryDatasourceSelection,
  isReportInventoryDatasourceSelection,
  isComponentTypeInventoryDatasourceSelection,
  getAssetIdSeparatorForUserPreferences,
  getWorkspaceIdsFromDatasource,
  getSubqueryFromDatasourceAndParams,
  createAdHocSearchReportFromFilter,
};
