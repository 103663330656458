import ModuleContainer from './ModuleContainer';
import SearchContainer from 'search/SearchTabContainer/SearchContainer';

const AdvancedSearchContainer = () => (
  <ModuleContainer $overflow="hidden">
    <SearchContainer />
  </ModuleContainer>
);

export default AdvancedSearchContainer;
