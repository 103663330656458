import { ErrorNotification, NotificationDetails } from '@ardoq/status-ui';
import { Link } from '@ardoq/typography';

type ViewInferenceErrorProps = {
  error: string;
};

export const ViewInferenceError = ({ error }: ViewInferenceErrorProps) => (
  <ErrorNotification>
    Unable to add dataset. Try again or contact{' '}
    <Link href="mailto:support@ardoq.com" typography="text2">
      Support
    </Link>{' '}
    if the issue continues.
    <NotificationDetails>{error}</NotificationDetails>
  </ErrorNotification>
);
