import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId, MetamodelBase, PersistedMetamodel } from '@ardoq/api-types';

export const setMetamodels = actionCreator<PersistedMetamodel[]>(
  '[metamodels] SET_METAMODELS'
);

export const addMetamodel = actionCreator<PersistedMetamodel>(
  '[metamodels] ADD_METAMODEL'
);

export const updateMetamodel = actionCreator<PersistedMetamodel>(
  '[metamodels] UPDATE_METAMODEL'
);

export const removeMetamodel = actionCreator<ArdoqId>(
  '[metamodels] REMOVE_METAMODEL'
);

// For routines
export const apiFetchMetamodels = actionCreator(
  '[metamodels] API_FETCH_METAMODELS'
);

export const apiFetchMetamodel = actionCreator<ArdoqId>(
  '[metamodels] API_FETCH_METAMODEL'
);

export const apiCreateMetamodel = actionCreator<MetamodelBase>(
  '[metamodels] API_CREATE_METAMODEL'
);

export const apiUpdateMetamodel = actionCreator<{
  metamodelId: ArdoqId;
  metamodelDefinition: MetamodelBase;
}>('[metamodels] API_UPDATE_METAMODEL');

export const apiDeleteMetamodel = actionCreator<ArdoqId>(
  '[metamodels] API_DELETE_METAMODEL'
);
