import styled from 'styled-components';
import { SelectableDatasourceTable } from '@ardoq/table';
import { BasicTable } from '@ardoq/table';
import { colors } from '@ardoq/design-tokens';

const Table = styled(BasicTable.Table)`
  border: none;
`;

const FixedHeightSelectableDatasourceTable = styled(
  SelectableDatasourceTable
).attrs({ components: { Table } })`
  border: 1px solid ${colors.grey80};
  height: 100vh;
`;

export default FixedHeightSelectableDatasourceTable;
