import { EntityWrapper, Info } from './atoms';
import { dispatchAction } from '@ardoq/rxbeach';
import { restoreReference } from '../reference.actions';
import { getButtonText, isRestored } from '../utils';
import RestoreError from './RestoreError';
import { SmallSecondaryButton } from '@ardoq/button';
import { DeletedReference as DeletedReferenceType } from 'restoreDeleted/types';
import { TextOverflow } from '@ardoq/popovers';
import { Label } from '@ardoq/forms';
import { DeletedReferenceProperties } from './types';
import { FlexBox } from '@ardoq/layout';

const handleRestore = (reference: DeletedReferenceType) => () =>
  dispatchAction(restoreReference({ reference }));

const DeletedReference = ({ reference }: DeletedReferenceProperties) => {
  return (
    <EntityWrapper>
      <Info>
        <Label width={'450px'}>
          <TextOverflow>{reference.name}</TextOverflow>
        </Label>
      </Info>
      <FlexBox align="center">
        <RestoreError isVisible={Boolean(reference.hasError)} />
        <SmallSecondaryButton
          onClick={handleRestore(reference)}
          isDisabled={isRestored(reference)}
        >
          {getButtonText(isRestored(reference))}
        </SmallSecondaryButton>
      </FlexBox>
    </EntityWrapper>
  );
};

export default DeletedReference;
