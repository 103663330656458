import {
  SSOMappingAction,
  SSOMappingCondition,
  MappingRule,
} from '@ardoq/api-types';

const isValidString = (input: string | null | undefined) => !!input;

const isValidAction = (action: string | null | undefined) =>
  isValidString(action) &&
  Object.values(SSOMappingAction).includes(action as SSOMappingAction);

export const isValidGroupId = isValidString;

export const isValidClauseValue = isValidString;

export const isValidClauseAttribute = isValidString;

export const isValidClauseCondition = (condition: string | null | undefined) =>
  isValidString(condition) &&
  Object.values(SSOMappingCondition).includes(condition as SSOMappingCondition);

export const isValidClauseProvider = isValidString;

const validatorsMap: Record<
  string,
  (value: string | SSOMappingAction | null | undefined) => boolean
> = {
  action: isValidAction,
  groupId: isValidGroupId,
  value: isValidClauseValue,
  attribute: isValidClauseAttribute,
  condition: isValidClauseCondition,
  provider: isValidClauseProvider,
};

const validatePair = ([key, value]: [
  string,
  string | SSOMappingAction | null | undefined,
]) => validatorsMap[key](value);

export const areValidChanges = (mappingRules: MappingRule[]) =>
  mappingRules.every(
    ({ action, clause }) =>
      Object.entries(action).every(validatePair) &&
      Object.entries(clause).every(validatePair)
  );
