import { reducedStream, reducer } from '@ardoq/rxbeach';
import * as actions from './actions';
import { SetSearchTraversalInitialStatePayload } from './actions';
import { APIViewpointAttributes } from '@ardoq/api-types';

export type SearchTraversalState = {
  selectedTraversalIds: string[];
  searchPhrase: string;
  shouldDisplayOnlyTraversalsOfCurrentUser: boolean;
  currentUserId: string;
  traversals: APIViewpointAttributes[];
};

export const initialState: SearchTraversalState = {
  selectedTraversalIds: [],
  searchPhrase: '',
  shouldDisplayOnlyTraversalsOfCurrentUser: false,
  currentUserId: '',
  traversals: [],
};

const setInitialStateReducer = (
  _state: SearchTraversalState,
  payload: SetSearchTraversalInitialStatePayload
) => {
  return {
    ...initialState,
    ...payload,
  };
};

const setSelectedViewpointIdsReducer = (
  state: SearchTraversalState,
  selectedTraversalIds: string[]
) => {
  return {
    ...state,
    selectedTraversalIds,
  };
};

const toggleSelectionReducer = (
  state: SearchTraversalState,
  toggledViewpointId: string
) => {
  const nextSelectedTraversalIds = state.selectedTraversalIds.includes(
    toggledViewpointId
  )
    ? state.selectedTraversalIds.filter(id => id !== toggledViewpointId)
    : [...state.selectedTraversalIds, toggledViewpointId];

  return {
    ...state,
    selectedTraversalIds: nextSelectedTraversalIds,
  };
};

const setSearchPhraseReducer = (
  state: SearchTraversalState,
  searchPhrase: string
) => {
  return {
    ...state,
    searchPhrase,
  };
};

const toggleShouldDisplayOnlyViewpointsOfCurrentUserReducer = (
  state: SearchTraversalState
) => {
  return {
    ...state,
    shouldDisplayOnlyTraversalsOfCurrentUser:
      !state.shouldDisplayOnlyTraversalsOfCurrentUser,
  };
};

export const searchTraversal$ = reducedStream(
  'searchTraversal$',
  initialState,
  [
    reducer(actions.setSearchTraversalInitialState, setInitialStateReducer),
    reducer(actions.setSelectedViewpointIds, setSelectedViewpointIdsReducer),
    reducer(actions.toggleSelection, toggleSelectionReducer),
    reducer(actions.setSearchPhrase, setSearchPhraseReducer),
    reducer(
      actions.toggleShouldDisplayOnlyViewpointsOfCurrentUser,
      toggleShouldDisplayOnlyViewpointsOfCurrentUserReducer
    ),
  ]
);
