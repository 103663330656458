import {
  APIEntityType,
  ResourceType,
  VersionedEntity,
  isObject,
  isVersionedEntity,
} from '@ardoq/api-types';
import { ArdoqEvent, ClientRequest, UserEvent } from './types';

const getEntityType = (
  event: ArdoqEvent<unknown>
): APIEntityType | 'unknown' => {
  try {
    return (event.data as any).ardoq['entity-type'] || 'unknown';
  } catch (e) {
    return 'unknown';
  }
};

const getEntityId = (event: ArdoqEvent<unknown>): APIEntityType | null => {
  try {
    return (event.data as any)._id || null;
  } catch (e) {
    return null;
  }
};

const isOfResourceType = (
  event: ArdoqEvent<unknown>,
  resourceType: ResourceType
): boolean => event['resource-type'] === resourceType;

const isTransactionEvent = (event: ArdoqEvent<unknown>) => {
  return 'transactionEvent' in event && event.transactionEvent;
};

/**
 * Due to weak types we validate the minimum requirement for an event
 */
const eventHasVersionedResource = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<VersionedEntity> => {
  return isObject(event.data) && isVersionedEntity(event.data);
};

/**
 * To begin with this naming is inaccurate:
 * - workspaces are not "branch aware"
 * - there are surely more collections that are "branch aware"
 * However, this code has been working for 3 years by the time it was documented
 * so at this point that's all we can say. It works.
 */
const branchAwareCollections = new Set([
  ResourceType.COMPONENT,
  ResourceType.FIELD,
  ResourceType.MODEL,
  ResourceType.REFERENCE,
  ResourceType.TAG,
  ResourceType.WORKSPACE,
]);

/**
 * Determine if an event is concerned a "branch aware" collection.
 * NOTE: It would make more sense to check if `branchId` is defined, however
 * it is unclear how this would affect the functionality, so it's best to keep
 * it as-is until we know.
 */
const isEventOnBranchAwareCollection = (event: ArdoqEvent<unknown>) => {
  const resourceType = event['resource-type'];
  return branchAwareCollections.has(resourceType);
};

const getClientRequestId = (event: UserEvent<ClientRequest<unknown>>) =>
  event.data.clientRequestId;

export const ardoqEventOperations = {
  eventHasVersionedResource,
  getEntityType,
  getEntityId,
  getClientRequestId,
  isEventOnBranchAwareCollection,
  isTransactionEvent,
  isOfResourceType,
};
