import { actionCreator } from '@ardoq/rxbeach';
import {
  OpenViewInferenceWindowPayload,
  ClientRequestIdAndStartSet,
  TraversalSuggestion,
} from './types';
import { APIInferTraversalRequest } from '@ardoq/api';

const DOMAIN = '[traversals view inference]';

export const openViewInferenceWindow =
  actionCreator<OpenViewInferenceWindowPayload>(`${DOMAIN} OPEN_WINDOW`);

export const closeViewInferenceWindow = actionCreator(`${DOMAIN} CLOSE_WINDOW`);
export const setViewInferenceInput = actionCreator<string>(
  `${DOMAIN} SET_INPUT`
);
export const sendViewInference = actionCreator<APIInferTraversalRequest>(
  `${DOMAIN} SEND_VIEW_INFERENCE`
);
export const notifyViewInferenceSuccess = actionCreator(
  `${DOMAIN} NOTIFY_VIEW_INFERENCE_SUCCESS`
);
export const notifyViewInferenceWarning = actionCreator<string>(
  `${DOMAIN} NOTIFY_VIEW_INFERENCE_WARNING`
);
export const notifyViewInferenceError = actionCreator<string>(
  `${DOMAIN} NOTIFY_VIEW_INFERENCE_ERROR`
);

export const sendSuggestTraversal = actionCreator<ClientRequestIdAndStartSet>(
  `${DOMAIN} SEND_SUGGEST_TRAVERSAL`
);

export const notifySuggestTraversalSuccess = actionCreator<
  TraversalSuggestion[]
>(`${DOMAIN} NOTIFY_SUGGEST_TRAVERSAL_SUCCESS`);
export const notifySuggestTraversalError = actionCreator<string>(
  `${DOMAIN} NOTIFY_SUGGEST_TRAVERSAL_ERROR`
);
export const notifySuggestTraversalWarning = actionCreator<string>(
  `${DOMAIN} NOTIFY_SUGGEST_TRAVERSAL_WARNING`
);

export const applySuggestion = actionCreator<TraversalSuggestion>(
  `${DOMAIN} APPLY_TRAVERSAL_SUGGESTION`
);
