import { AnimatedChevron } from '@ardoq/icons';
import { GhostButton } from '@ardoq/button';
import styled from 'styled-components';
import { Header5 } from '@ardoq/typography';
import { FlexBox } from '@ardoq/layout';

type ToggleTriplesIslandHeaderProps = {
  label: string;
  onToggle: () => void;
  isExpanded: boolean;
};

export const CustomClosableIslandHeader = ({
  label,
  onToggle,
  isExpanded,
}: ToggleTriplesIslandHeaderProps) => {
  return (
    <PointerOnHoverFlexBox
      align="center"
      justify="space-between"
      paddingX="medium"
      paddingY="small"
      DEPRECATED_onClick={onToggle}
    >
      <Header5 color="textModerate">{label}</Header5>
      <GhostButton data-tooltip-text={isExpanded ? 'Collapse' : 'Expand'}>
        <AnimatedChevron $isUp={isExpanded} />
      </GhostButton>
    </PointerOnHoverFlexBox>
  );
};

const PointerOnHoverFlexBox = styled(FlexBox)`
  &:hover {
    cursor: pointer;
  }
`;
