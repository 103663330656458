import { APIResourcePermissionAttributes } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

const DOMAIN = '[PERMISSION/PERMISSION_ZONE]';

export const getZonePermissions = actionCreator(`${DOMAIN} getZonePermissions`);

export const getZonePermissionsFinished = actionCreator<
  APIResourcePermissionAttributes[] | null
>(`${DOMAIN} getZonePermissionsFinished`);

export const updateZonePermission =
  actionCreator<APIResourcePermissionAttributes>(
    `${DOMAIN} updateZonePermission`
  );

export const updateZonePermissionFinished =
  actionCreator<APIResourcePermissionAttributes | null>(
    `${DOMAIN} updateZonePermissionFinished`
  );
