import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { performExport, upsertSchedule } from './actions';
import {
  catchError,
  filter,
  from,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { configState$ } from '../configurationState/configurationState$';
import { navigateToPath } from 'integrations/common/navigation/actions';
import {
  NewExportRoutes,
  OverviewRoute,
} from 'integrations/common/navigation/types';
import { jobApi, signavioExporterApi } from '@ardoq/api';
import { apiBodyToConfig, configToApiBody, renderProgressLabel } from './utils';
import { handleApiError } from 'integrations/common/utils/api';
import {
  resetConfig,
  updateConfigState,
  updateConfigResults,
} from '../configurationState/actions';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import { fetchSchedulesList } from 'integrations/common/streams/schedules/actions';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { fetchDictionaryResource } from '../dictionaryCategories/actions';
import {
  ensureCorrectAsyncRequest,
  getAsyncRequestId,
  parseAsyncProgressResponsePayload,
  parseAsyncResponsePayload,
} from 'integrations/common/async/utils';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { AsyncOperations } from 'integrations/common/async/constants';
import { userEvent } from 'sync/actions';
import { SignavioExporterDiff } from '@ardoq/api-types/integrations';
import { startProgressModal } from 'integrations/common/modals/progressModal/ProgressModal';
import { setFetchProgress } from 'integrations/common/streams/fetchProgress/actions';

const handleExport = routine(
  ofType(performExport),
  extractPayload(),
  withLatestFrom(configState$, activeIntegrations$),
  switchMap(([{ dryRun }, config, activeIntegrations]) => {
    if (!dryRun) {
      startProgressModal({
        integrationId: 'signavio-exporter',
        totalCount: Object.keys(config.componentTypes).length,
        progressLabel: 'component types',
        renderProgressLabel,
      });
    }
    dispatchAction(
      updateConfigResults({
        diff: {
          status: 'LOADING',
        },
      })
    );
    const funnelId = activeIntegrations['signavio-exporter'].trackingFunnelId;
    return from(
      signavioExporterApi.postExport(
        configToApiBody(config),
        getAsyncRequestId({
          integrationId: 'signavio-exporter',
          funnelId,
          operation: dryRun
            ? AsyncOperations.EXPORT_DRY_RUN
            : AsyncOperations.EXPORT,
        }),
        dryRun
      )
    );
  }),
  tap(response => {
    if (isArdoqError(response)) {
      dispatchAction(
        updateConfigResults({
          diff: {
            status: 'FAILURE',
            message: getArdoqErrorMessage(response),
          },
        })
      );

      return handleApiError(response.error);
    }
  }),
  catchError(error => {
    // Should not happen in general since postExport should return error as ArdoqError
    const message =
      'Something unexpectedly went wrong. Please contact support if the problem continues.';
    dispatchAction(
      updateConfigResults({
        diff: {
          status: 'FAILURE',
          message,
        },
      })
    );
    return handleApiError(error);
  })
);

const handleExportResponse = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncResponsePayload<SignavioExporterDiff>),
  withLatestFrom(activeIntegrations$),
  ensureCorrectAsyncRequest([
    AsyncOperations.EXPORT,
    AsyncOperations.EXPORT_DRY_RUN,
  ]),
  tap(([response]) => {
    if (response?.status === 'error') {
      dispatchAction(
        updateConfigResults({
          diff: {
            status: 'FAILURE',
            message:
              response.data.message ||
              'Something unexpectedly went wrong. Please contact support if the problem continues.',
          },
        })
      );
      dispatchAction(
        navigateToPath({
          integrationId: 'signavio-exporter',
          path: NewExportRoutes.REVIEW_EXPORT,
        })
      );
      dispatchAction(
        setFetchProgress({
          integrationId: 'signavio-exporter',
          status: 'FAILURE',
        })
      );
      return;
    }
    if (response?.status !== 'success') {
      dispatchAction(
        setFetchProgress({
          integrationId: 'signavio-exporter',
          status: 'FAILURE',
        })
      );
      return;
    }
    dispatchAction(
      updateConfigResults({
        diff: {
          status: 'SUCCESS',
          data: response.data,
        },
      })
    );
    if (response.operation === AsyncOperations.EXPORT_DRY_RUN) {
      dispatchAction(
        navigateToPath({
          integrationId: 'signavio-exporter',
          path: NewExportRoutes.REVIEW_EXPORT,
        })
      );
    } else {
      if (response.data.updatedPayload) {
        dispatchAction(
          updateConfigState(
            apiBodyToConfig(
              response.data.updatedPayload._id,
              response.data.updatedPayload,
              {
                diff: { status: 'SUCCESS', data: response.data },
              }
            )
          )
        );
        dispatchAction(fetchDictionaryResource(true));
        dispatchAction(
          setFetchProgress({
            integrationId: 'signavio-exporter',
            status: 'SUCCESS',
          })
        );
      }
      dispatchAction(
        navigateToPath({
          integrationId: 'signavio-exporter',
          path: NewExportRoutes.EXPORT_AND_SCHEDULE,
        })
      );
    }
  })
);

const handleExportAsyncProgress = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncProgressResponsePayload),
  withLatestFrom(activeIntegrations$),
  filter(([response]) => response?.integrationId === 'signavio-exporter'),
  ensureCorrectAsyncRequest(AsyncOperations.EXPORT),
  tap(([response]) => {
    if (!response) return;
    dispatchAction(
      setFetchProgress({
        integrationId: 'signavio-exporter',
        progress: response.progress,
        status: 'LOADING',
      })
    );
  })
);

const handleUpsertSchedule = routine(
  ofType(upsertSchedule),
  withLatestFrom(configState$, getSchedulesStream('signavio-exporter')),
  switchMap(([, config, schedules]) => {
    dispatchAction(
      updateConfigState({
        scheduleStatus: 'LOADING',
      })
    );
    if (config._id) {
      const existingSchedule = schedules.schedules.find(
        schedule => schedule._id === config._id
      );
      if (
        !existingSchedule ||
        existingSchedule.jobOptions.type !== 'signavio-export'
      ) {
        throw new Error("Couldn't find existing schedule");
      }
      return from(
        jobApi.updateSchedule({
          ...existingSchedule,
          jobOptions: {
            ...existingSchedule.jobOptions,
            ...configToApiBody(config),
          },
        })
      );
    }
    return from(
      signavioExporterApi.postScheduleExport(configToApiBody(config))
    );
  }),
  tap(() => {
    dispatchAction(resetConfig());
    dispatchAction(fetchSchedulesList());
    dispatchAction(
      navigateToPath({
        integrationId: 'signavio-exporter',
        path: OverviewRoute.SCHEDULES,
      })
    );
  }),
  catchError(error => {
    const message =
      error.extraData?.readableMessage ||
      "Something went wrong, and we're unable to create a new connection. Please try connecting again. Contact support if the problem continues.";
    dispatchAction(
      updateConfigResults({
        diff: {
          status: 'FAILURE',
          message,
        },
      })
    );

    dispatchAction(
      navigateToPath({
        path: NewExportRoutes.EXPORT_AND_SCHEDULE,
        integrationId: 'signavio-exporter',
      })
    );
    return handleApiError(error);
  })
);

export default [
  handleExport,
  handleUpsertSchedule,
  handleExportResponse,
  handleExportAsyncProgress,
];
