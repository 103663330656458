import { reducer } from '@ardoq/rxbeach';
import { SurveyEditorSectionKey, SurveyEditorState } from './types';
import {
  resetSurveyFieldHasBeenInteractedWith,
  setSurveyFieldHasBeenInteractedWith,
  setSurveyQuestionFieldHasBeenInteractedWith,
  setFilterSetupSurveyAttribute,
  setHasInvalidatedSurveyChangeApproval,
  setMiscSurveyAttribute,
  setSectionStatus,
  SetSectionStatusPayload,
  setSurveyAttributes,
  setValidationErrors,
  setValidSections,
  SetValidSectionsPayload,
  setAllExistingQuestionsInteractedWith,
  sectionSelected,
} from './actions';
import { MaybePersistedSurvey, SurveyValidation } from 'surveyAdmin/types';
import {
  SurveyFilterSetupAttributes,
  SurveyMiscAttributes,
} from '@ardoq/api-types';
import { getMiscStatusKey } from '../LayoutElements/utils';
import {
  getQuestionId,
  getQuestionQuestionFieldInteractionStatesAllInteracted,
} from 'surveyAdmin/questions/utils';
import { isConditionalQuestion } from 'surveyAdmin/questions/questionPredicates';
import { isReferenceFilterEnabled } from '../utils';

const handleSetValidSections = (
  state: SurveyEditorState,
  capabilities: SetValidSectionsPayload
): SurveyEditorState => ({
  ...state,
  validSections: [
    'detailsSection',
    'workspaceAndComponentsSection',
    'surveySection',
    ...(capabilities.canSeeChangeApprovalStep
      ? (['changeApprovalSection'] as SurveyEditorSectionKey[])
      : []),
    ...(capabilities.discoverEnabled
      ? (['responseFeedback'] as SurveyEditorSectionKey[])
      : []),
    'resultDisplayOptionsSection',
    'resultFilteringSection',
    ...(capabilities.discoverEnabled
      ? (['discover'] as SurveyEditorSectionKey[])
      : []),
  ],
});

const handleSetValidationErrors = (
  state: SurveyEditorState,
  surveyValidation: SurveyValidation
): SurveyEditorState => ({
  ...state,
  surveyValidation,
});

const handleSetSurveyAttributes = (
  state: SurveyEditorState,
  surveyAttributes: MaybePersistedSurvey
): SurveyEditorState => {
  return {
    ...state,
    surveyAttributes,
    shouldShowReferenceResultFilteringFieldErrors: isReferenceFilterEnabled(
      state.surveyAttributes.filterSetup
    ),
  };
};

const handleSetMiscSurveyAttribute = (
  state: SurveyEditorState,
  payload: {
    key: keyof SurveyMiscAttributes;
    value: SurveyMiscAttributes[keyof SurveyMiscAttributes];
  }
): SurveyEditorState => {
  return {
    ...state,
    surveyAttributes: {
      ...state.surveyAttributes,
      misc: {
        ...state.surveyAttributes.misc,
        [payload.key]: payload.value,
      },
    },
  };
};

const handleSetFilterSetupSurveyAttribute = (
  state: SurveyEditorState,
  updatedFilterSetup: SurveyFilterSetupAttributes
): SurveyEditorState => {
  const shouldResetFilterSetupErrorTrackers =
    !isReferenceFilterEnabled(updatedFilterSetup);
  return {
    ...state,
    surveyAttributes: {
      ...state.surveyAttributes,
      filterSetup: {
        ...state.surveyAttributes.filterSetup,
        ...updatedFilterSetup,
      },
    },
    shouldShowReferenceResultFilteringFieldErrors:
      shouldResetFilterSetupErrorTrackers
        ? false
        : state.shouldShowReferenceResultFilteringFieldErrors,
    fieldHasBeenInteractedWith: shouldResetFilterSetupErrorTrackers
      ? {
          ...state.fieldHasBeenInteractedWith,
          resultDisplaySectionReferenceDirectionSelectField: false,
          resultDisplaySectionReferenceTypeSelectField: false,
          resultDisplaySectionComponentTypeSelectField: false,
          resultDisplaySectionWorskpaceSelectField: false,
        }
      : state.fieldHasBeenInteractedWith,
  };
};

const handleSetSectionStatus = (
  state: SurveyEditorState,
  payload: SetSectionStatusPayload
): SurveyEditorState => {
  return {
    ...state,
    surveyAttributes: {
      ...state.surveyAttributes,
      misc: {
        ...state.surveyAttributes.misc,
        [getMiscStatusKey(payload.key)]: payload.status,
      },
    },
  };
};

const handleSetSurveyFieldHasBeenInteractedWith = (
  state: SurveyEditorState,
  payload: { fieldKey: keyof SurveyEditorState['fieldHasBeenInteractedWith'] }
): SurveyEditorState => {
  return {
    ...state,
    fieldHasBeenInteractedWith: {
      ...state.fieldHasBeenInteractedWith,
      [payload.fieldKey]: true,
    },
  };
};

const handleSetSurveyQuestionFieldHasBeenInteractedWith = (
  state: SurveyEditorState,
  payload: {
    questionKey: string;
    fieldKey: keyof SurveyEditorState['fieldHasBeenInteractedWith']['questions'][number];
  }
): SurveyEditorState => {
  return {
    ...state,
    fieldHasBeenInteractedWith: {
      ...state.fieldHasBeenInteractedWith,
      questions: {
        ...state.fieldHasBeenInteractedWith.questions,
        [payload.questionKey]: {
          ...state.fieldHasBeenInteractedWith.questions[payload.questionKey],
          [payload.fieldKey]: true,
        },
      },
    },
  };
};

const handleSetAllExistingQuestionsInteractedWith = (
  state: SurveyEditorState
): SurveyEditorState => {
  const allQuestionIds = state.surveyAttributes.questions.flatMap(q => {
    const questionId = getQuestionId(q);
    return isConditionalQuestion(q)
      ? [questionId, ...q.properties.questions.map(getQuestionId)]
      : [questionId];
  });
  const newQuestions = allQuestionIds.reduce(
    (acc, id) => {
      acc[id] = {
        questionViewHasBeenClosed: true,
      };
      return acc;
    },
    {} as Record<string, { questionViewHasBeenClosed: boolean }>
  );
  return {
    ...state,
    fieldHasBeenInteractedWith: {
      ...state.fieldHasBeenInteractedWith,
      questions: newQuestions,
    },
  };
};

const handleResetSurveyFieldHasBeenInteractedWith = (
  state: SurveyEditorState
): SurveyEditorState => {
  return {
    ...state,
    fieldHasBeenInteractedWith: {
      generalInformationSectionNameField: false,
      resultDisplaySectionWorskpaceSelectField: false,
      resultDisplaySectionComponentTypeSelectField: false,
      resultDisplaySectionReferenceDirectionSelectField: false,
      resultDisplaySectionReferenceTypeSelectField: false,
      workspaceAndComponentSectionWorkspaceSelectField: false,
      workspaceAndComponentSectionComponentTypeSelectField: false,
      questions: {},
    },
  };
};

const handleSetHasInvalidatedSurveyChangeApproval = (
  state: SurveyEditorState,
  hasInvalidatedSurveyChangeApproval: boolean
): SurveyEditorState => {
  return {
    ...state,
    hasInvalidatedSurveyChangeApproval,
  };
};

const handleSectionSelected = (
  state: SurveyEditorState,
  selectedSectionIndex: number
): SurveyEditorState => {
  const previousSectionKey = state.validSections[state.selectedSectionIndex];
  const newSectionKey = state.validSections[selectedSectionIndex];
  const previousSectionStatusForNewSection =
    state.surveyAttributes.misc[getMiscStatusKey(newSectionKey)];
  const navigatedAwayFromResultFiltering =
    previousSectionKey === 'resultFilteringSection';
  const navigatedAwayFromSurveySection = previousSectionKey === 'surveySection';
  return {
    ...state,
    selectedSectionIndex,
    surveyAttributes: {
      ...state.surveyAttributes,
      misc: {
        ...state.surveyAttributes.misc,
        [getMiscStatusKey(previousSectionKey)]: 'left',
        [getMiscStatusKey(state.validSections[selectedSectionIndex])]:
          previousSectionStatusForNewSection !== 'initial' ? 'left' : 'entered',
      },
    },
    shouldShowReferenceResultFilteringFieldErrors:
      navigatedAwayFromResultFiltering
        ? isReferenceFilterEnabled(state.surveyAttributes.filterSetup)
        : state.shouldShowReferenceResultFilteringFieldErrors,
    fieldHasBeenInteractedWith: {
      ...state.fieldHasBeenInteractedWith,
      ...(!navigatedAwayFromSurveySection && {
        questions: getQuestionQuestionFieldInteractionStatesAllInteracted(
          state.surveyAttributes.questions
        ),
      }),
    },
  };
};

export const reducers = [
  reducer(setValidSections, handleSetValidSections),
  reducer(setValidationErrors, handleSetValidationErrors),
  reducer(setSurveyAttributes, handleSetSurveyAttributes),
  reducer(setMiscSurveyAttribute, handleSetMiscSurveyAttribute),
  reducer(setFilterSetupSurveyAttribute, handleSetFilterSetupSurveyAttribute),
  reducer(setSectionStatus, handleSetSectionStatus),
  reducer(
    setAllExistingQuestionsInteractedWith,
    handleSetAllExistingQuestionsInteractedWith
  ),
  reducer(
    setSurveyFieldHasBeenInteractedWith,
    handleSetSurveyFieldHasBeenInteractedWith
  ),
  reducer(
    setSurveyQuestionFieldHasBeenInteractedWith,
    handleSetSurveyQuestionFieldHasBeenInteractedWith
  ),
  reducer(
    resetSurveyFieldHasBeenInteractedWith,
    handleResetSurveyFieldHasBeenInteractedWith
  ),
  reducer(
    setHasInvalidatedSurveyChangeApproval,
    handleSetHasInvalidatedSurveyChangeApproval
  ),
  reducer(sectionSelected, handleSectionSelected),
];
