import { CloudProviderIntegrationId } from 'integrations/cloudProviders/types';
import { cloudProviderApi, getErrorStatusCode } from '@ardoq/api';
import { ApiError } from 'integrations/common/utils/api';
import { INTEGRATION_ID_TO_CLOUD_PROVIDER } from 'integrations/cloudProviders/constants';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';

export async function fetchRegionsList({
  integrationId,
  connectionId,
}: {
  integrationId: CloudProviderIntegrationId;
  connectionId: string;
}) {
  const regions = await cloudProviderApi.fetchRegions(
    {
      authId: connectionId,
      authMethod: 'saved',
    },
    INTEGRATION_ID_TO_CLOUD_PROVIDER[integrationId]
  );

  if (isArdoqError(regions)) {
    throw new ApiError(
      `Unable to fetch regions list for the cloud provider: aws`,
      getErrorStatusCode(regions),
      getArdoqErrorMessage(regions)
    );
  }

  return regions;
}
