import {
  filter,
  fromEvent,
  map,
  merge,
  Subject,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { DOCUMENT_PICKER_ORIGIN } from './const';
import { DocumentPickerActionType, DocumetPickerAction } from './types';
import { showToast, ToastType } from '@ardoq/status-ui';

const TIMEOUT_MS = 1000 * 60 * 5;

const documetPickerActions$ = new Subject<DocumetPickerAction>();

const closedDocumentPicker$ = documetPickerActions$.pipe(
  filter(action => action.type === DocumentPickerActionType.CLOSED)
);

export const closeDocumentPicker = () => {
  documetPickerActions$.next({ type: DocumentPickerActionType.CLOSED });
};

export const createLucidDocumentPickerStream = ({
  onExpired,
}: {
  onExpired: VoidFunction;
}) => {
  const sessionExpired$ = timer(TIMEOUT_MS).pipe(
    tap(() => {
      onExpired();
      showToast('Lucidchart document picker session expired', ToastType.INFO);
    })
  );

  return fromEvent<MessageEvent>(window, 'message').pipe(
    filter(event => event.origin === DOCUMENT_PICKER_ORIGIN),
    map(event => event.data),
    takeUntil(merge(closedDocumentPicker$, sessionExpired$))
  );
};
