export const HEIGHT_WORKSPACE_ITEM = 36;
export const HEIGHT_COMPONENT_ITEM = 30;
export const HORIZONTAL_INDENT = 27;
export const DEFAULT_SCROLL_TOP_MARGIN = 100;

export enum NavigatorDropType {
  DROP_TYPE_NONE = '',
  DROP_TYPE_NO_DROP = 'no-drop',
  DROP_TYPE_MOVE = 'drop-move',
  DROP_TYPE_COPY = 'drop-copy',
}

export const START_DRAG_THRESHOLD = 12;

export const PARENT_DROP_TARGET_HEIGHT = 6;

export const SCROLL_DELTA_TRIGGER = 12;
export const MAX_SCROLL_DELTA = 64;

export enum DropTargetView {
  NONE = 0,
  NAVIGATOR = 1,
  TAGSCAPE = 2,
}

export enum NodeModelTypes {
  NONE = '',
  COMPONENT = 'component',
  WORKSPACE = 'workspace',
  SCENARIO = 'scenario',
}

export const CSS_CLASS_IGNORE_LINK_TARGET = 'is-ignore-link-target';

export const COMPONENT_ORDER_STEP = 5000;
export const DOM_NODE_SELECTOR_NAVIGATOR = '#navigator-content [data-id]';
export const SHIFT_X = 24;
export const SHIFT_Y = 24;
export const TRANSITION_DURATION = 500;
