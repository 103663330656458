import {
  APISurveyAttributes,
  APIDiscoverViewpointAttributes,
  ViewpointSurveyDetail,
} from '@ardoq/api-types';
import { useController, useFormContext } from 'react-hook-form';
import { getSelectedComponentTypeNames } from 'viewpoints/utils';
import SurveyDetailForm from './SurveyDetailForm';
import { Box, Stack } from '@ardoq/layout';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { uniq } from 'lodash';

const SurveyDetailsForm = ({
  relatedSurveys,
}: {
  relatedSurveys: APISurveyAttributes[];
}) => {
  const {
    field: { onChange },
  } = useController({ name: 'surveyDetails' });

  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const [dynamicGroupedTraversals, surveyDetails = []] = watch([
    'dynamicGroupedTraversals',
    'surveyDetails',
  ]);

  const componentTypeOptions = getSelectedComponentTypeNames(
    dynamicGroupedTraversals
  );

  const updateSurveyDetails = (
    surveyDetail: ViewpointSurveyDetail,
    index: number
  ) => {
    onChange([
      ...surveyDetails.slice(0, index),
      surveyDetail,
      ...surveyDetails.slice(index + 1),
    ]);
  };

  const nonPrioSurveys = relatedSurveys?.filter(
    survey => !survey.discoverPriority
  );

  const newSurveyEntries = componentTypeOptions
    .map(componentType => {
      const componentTypeSurveys = nonPrioSurveys?.filter(
        survey => survey.componentTypeName === componentType
      );

      const existingEntry = surveyDetails.find(
        entry => entry.componentTypeName === componentType
      );

      return {
        componentTypeName: componentType,
        surveys: uniq([
          ...(existingEntry?.surveys ?? []),
          ...componentTypeSurveys.map(survey => survey._id),
        ]),
      };
    })
    .filter(ExcludeFalsy);

  return componentTypeOptions.length ? (
    <>
      <Box marginBottom="xlarge">
        <Stack gap="medium">
          {newSurveyEntries.map((surveyDetail, index) => {
            return (
              <SurveyDetailForm
                key={index}
                surveyDetail={surveyDetail}
                onSurveyDetailChange={selectedSurveyDetail => {
                  updateSurveyDetails(selectedSurveyDetail, index);
                }}
                relatedSurveys={relatedSurveys}
              />
            );
          })}
        </Stack>
      </Box>
    </>
  ) : null;
};

export default SurveyDetailsForm;
