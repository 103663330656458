import { resetIntegration } from '../activeIntegrations/actions';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  resetTabularMappingErrors,
  setTableMappingErrors,
  setTabularMappingErrors,
} from './actions';
import {
  ApplyColumnMappingPayload,
  RemoveColumnMappingsPayload,
  applyColumnMapping,
  applyTabularMapping,
  removeColumnMappings,
} from '../tabularMappings/actions';
import { validateTableMapping, validateTabularMapping } from './utils';
import { isResourceCreationDisabled } from '../transferState/utils';
import { getTransferStateStream } from '../transferState/getTransferStateStream';
import { of } from 'rxjs';
import { getTabularMappingStream } from '../tabularMappings/getTabularMappingStream';
import { integrationId$ } from '../integrationId/integrationId$';

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  tap(integrationId => {
    dispatchAction(resetTabularMappingErrors({ integrationId }));
  })
);

const handleApplyTabularMapping = routine(
  ofType(applyTabularMapping),
  extractPayload(),
  switchMap(({ integrationId, mapping }) =>
    of(integrationId).pipe(
      withLatestFrom(of(mapping), getTransferStateStream(integrationId))
    )
  ),
  tap(([integrationId, mapping, transferState]) => {
    dispatchAction(
      setTabularMappingErrors({
        integrationId,
        errors: validateTabularMapping(
          mapping,
          isResourceCreationDisabled(transferState)
        ),
      })
    );
  })
);

const handleApplyColumnMapping = routine(
  ofType<ApplyColumnMappingPayload | RemoveColumnMappingsPayload>(
    applyColumnMapping,
    removeColumnMappings
  ),
  extractPayload(),
  withLatestFrom(integrationId$),
  switchMap(([{ tableId }, integrationId]) =>
    of(integrationId).pipe(
      withLatestFrom(
        of(tableId),
        getTransferStateStream(integrationId),
        getTabularMappingStream(integrationId)
      )
    )
  ),
  tap(([integrationId, tableId, transferState, tabularMapping]) => {
    dispatchAction(
      setTableMappingErrors({
        integrationId,
        tableId,
        errors: validateTableMapping(
          tabularMapping[tableId].mappedColumns,
          isResourceCreationDisabled(transferState)
        ),
      })
    );
  })
);

export default [
  handleResetIntegration,
  handleApplyTabularMapping,
  handleApplyColumnMapping,
];
