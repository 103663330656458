import { ComponentOverviewPageState } from './componentOverviewPage$';
import { EnhancedScopeData } from '@ardoq/data-model';
import { ArdoqId } from '@ardoq/api-types';
import {
  ComponentCanDoInModesArgs,
  componentAccessControlOperation,
} from 'resourcePermissions/accessControlHelpers/component';

const setComponentOverviewData = (
  state: ComponentOverviewPageState,
  scopeData: EnhancedScopeData
): ComponentOverviewPageState => {
  return { ...state, scopeData, hasError: false };
};

const setComponentOverviewId = (
  state: ComponentOverviewPageState,
  componentId: ArdoqId | null
): ComponentOverviewPageState => {
  return { ...state, componentId, hasError: false };
};

const setComponentOverviewPageError = (
  state: ComponentOverviewPageState
): ComponentOverviewPageState => {
  return { ...state, hasError: true };
};

type FilterFieldsInScopeDataWithSubdivisionsAndModesArgs = Omit<
  ComponentCanDoInModesArgs,
  'fieldId'
> & { scopeData: EnhancedScopeData };

const filterFieldsInScopeDataWithSubdivisionsAndModes = ({
  scopeData,
  subdivisionsContext,
  permissionContext,
  component,
  isPresentationMode,
  isScenarioMode,
}: FilterFieldsInScopeDataWithSubdivisionsAndModesArgs) => {
  const filteredFields = scopeData.fields.filter(
    field =>
      !componentAccessControlOperation.hasNoAccessToFieldInAnyModes({
        subdivisionsContext,
        permissionContext,
        fieldId: field._id,
        isScenarioMode,
        isPresentationMode,
        component,
      })
  );
  const filteredFieldsByid = filteredFields.reduce(
    (acc, cur) => ({ ...acc, [cur._id]: cur }),
    {}
  );
  return {
    ...scopeData,
    fields: filteredFields,
    fieldsById: filteredFieldsByid,
  };
};

export const componentOverviewPageOperations = {
  setComponentOverviewData,
  setComponentOverviewId,
  setComponentOverviewPageError,
  filterFieldsInScopeDataWithSubdivisionsAndModes,
};
