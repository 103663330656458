import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { AppRouterState, SurveyAdminRoute } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { map } from 'rxjs/operators';
import surveyAdmin$ from 'surveyAdmin/streams/surveyAdmin$';
import { SurveyAdminMode } from './types';
import { navigateToSurveyAdmin } from 'router/navigationActions';

const surveyAdminRoute = new Route<AppRouterState, SurveyAdminRoute>({
  doesLocationMatch: ({ path }) => /\/survey-admin\/?.*?/.test(path),
  locationToRouterState: ({ path, searchParams }) => {
    const parts = path.split('/');
    const afterSurveyAdmin = parts[2];
    const editId = parts[3];
    if (afterSurveyAdmin === 'new') {
      return {
        appModule: AppModules.SURVEY_ADMIN,
        surveyMode: SurveyAdminMode.NEW_SURVEY,
        surveyId: null,
      };
    } else if (afterSurveyAdmin === 'edit') {
      return {
        appModule: AppModules.SURVEY_ADMIN,
        surveyMode: SurveyAdminMode.EDIT_SURVEY,
        surveyId: editId,
      };
    } else if (afterSurveyAdmin === 'response-approval') {
      return {
        appModule: AppModules.SURVEY_ADMIN,
        surveyMode: SurveyAdminMode.CHANGE_APPROVAL,
        surveyId: editId,
      };
    }
    const surveyOverviewSearchParams = {
      token: searchParams?.get('token'),
      updateType: searchParams?.get('type'),
      ref: searchParams?.get('ref'),
      surveyId: searchParams?.get('surveyId'),
    };
    if (
      Object.values(surveyOverviewSearchParams).every(
        searchParam => !searchParam
      )
    )
      return {
        appModule: AppModules.SURVEY_ADMIN,
        surveyMode: SurveyAdminMode.OVERVIEW,
        surveyId: null,
      };
    return {
      appModule: AppModules.SURVEY_ADMIN,
      surveyMode: SurveyAdminMode.OVERVIEW,
      surveyId: null,
      surveyOverviewSearchParams,
    };
  },
  doesRouterStateMatch: ({ appModule }) =>
    appModule === AppModules.SURVEY_ADMIN,
  routerStateToLocation: ({
    surveyId,
    surveyMode,
    surveyOverviewSearchParams,
  }) => {
    if (surveyMode === SurveyAdminMode.EDIT_SURVEY) {
      return {
        path: `/survey-admin/edit/${surveyId}`,
        title: 'Edit Survey',
      };
    } else if (surveyMode === SurveyAdminMode.NEW_SURVEY) {
      return { path: `/survey-admin/new/`, title: 'New Survey' };
    } else if (surveyMode === SurveyAdminMode.CHANGE_APPROVAL) {
      return {
        path: `/survey-admin/response-approval/${surveyId}`,
        title: 'Response Approval',
      };
    }

    const searchParams = new URLSearchParams();
    if (surveyOverviewSearchParams?.surveyId) {
      searchParams.set('surveyId', surveyOverviewSearchParams.surveyId);
    }
    if (surveyOverviewSearchParams?.token) {
      searchParams.set('token', surveyOverviewSearchParams.token);
    }
    if (surveyOverviewSearchParams?.ref) {
      searchParams.set('ref', surveyOverviewSearchParams.ref);
    }
    if (surveyOverviewSearchParams?.updateType) {
      searchParams.set('type', surveyOverviewSearchParams.updateType);
    }

    return {
      path: `/survey-admin/`,
      title: 'Surveys',
      searchParams,
    };
  },
  setApplicationStateFromRoute: ({
    surveyId,
    surveyMode,
    surveyOverviewSearchParams,
  }) => {
    dispatchAction(
      navigateToSurveyAdmin({
        surveyMode,
        surveyId,
        surveyOverviewSearchParams,
      })
    );
  },
  getPartialRouterStateStream: () =>
    surveyAdmin$.pipe(
      map(({ mode, surveyId, surveyOverviewSearchParams }) => {
        return {
          surveyMode: mode,
          surveyId,
          surveyOverviewSearchParams,
        };
      })
    ),
});

export default surveyAdminRoute;
