import { useState } from 'react';
import { colors, spacing } from '@ardoq/design-tokens';
import { TextInput } from '@ardoq/forms';
import { IconName } from '@ardoq/icons';
import { ModalSize, ModalTemplate } from '@ardoq/modal';
import {
  PAGINATION_BAR_HEIGHT,
  Pagination,
  PaginationController,
} from '@ardoq/pagination';
import {
  DatasourceTable,
  ExpandableTableProvider,
  newTableTheme,
} from '@ardoq/table';
import styled from 'styled-components';
import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import { Tab, TabsGroup } from '@ardoq/tabs';
import { APISurveyResponseLog, UpdateType } from '@ardoq/api-types';
import {
  getColumns,
  isCreatedResponse,
  isDeletedResponse,
  isUpdatedResponse,
} from './responseLogColumnConfig';
import { getCurrentLocale, localeIncludesLowercase } from '@ardoq/locale';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { ResponseLogStreamShape } from './types';
import { Row } from './ChangeRowTable';
import responseLog$ from './responseLog$';
import { fetchResponseLogData } from './actions';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';
import { trackEvent } from 'tracking/tracking';
import { DoqLoader } from '@ardoq/snowflakes';
import { isEqual } from 'lodash';
import { InfoNotification } from '@ardoq/status-ui';
import { Box, Stack } from '@ardoq/layout';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyContent = ({
  searchPhrase,
  updateType,
  responseLogErrorMessage,
}: {
  searchPhrase: string;
  updateType: UpdateType;
  responseLogErrorMessage: string | null;
}) => {
  const presentTenseUpdateType = updateType.substring(0, updateType.length - 1);
  return (
    <Container>
      <DoqWithSpeechBubble
        doqType={DoqType.MISSING_ORG}
        layout={DoqLayout.VERTICAL}
        title={
          responseLogErrorMessage ? 'Something went wrong' : 'No results found'
        }
        message={
          responseLogErrorMessage
            ? responseLogErrorMessage
            : searchPhrase
              ? `There are no ${presentTenseUpdateType} responses containing the term "${searchPhrase}"`
              : `There are no ${presentTenseUpdateType} responses logged for this survey yet, please check back again later`
        }
      />
    </Container>
  );
};

const StyledTextInput = styled(TextInput)`
  width: 180px;
`;

const RowWithHover = styled.tr`
  background: ${colors.white};
  &:hover {
    background: ${colors.grey95};
  }
`;

const ThBody = styled.div``;

const satisfiesSearchFilter = (
  responseRow: APISurveyResponseLog,
  searchPhrase: string
) => {
  const locale = getCurrentLocale();
  const fieldToMatch = isUpdatedResponse(responseRow)
    ? responseRow.component.name
    : responseRow.name;

  return localeIncludesLowercase(fieldToMatch, searchPhrase, locale);
};

const sortBasedOnResponseType = (
  a: APISurveyResponseLog,
  b: APISurveyResponseLog
) => {
  if (isUpdatedResponse(a) && isUpdatedResponse(b)) {
    return a.lastUpdated > b.lastUpdated ? -1 : 1;
  }
  if (isCreatedResponse(a) && isCreatedResponse(b)) {
    return a.created > b.created ? -1 : 1;
  }
  if (isDeletedResponse(a) && isDeletedResponse(b)) {
    return a.deleted > b.deleted ? -1 : 1;
  }
  return 0;
};

const toRow = (response: APISurveyResponseLog, index: number) => {
  if (!isUpdatedResponse(response)) return response;
  return {
    ...response,
    name: isDateRangeFieldType(response.type)
      ? dateRangeOperations.dashFormatDateRangeFieldLabel(response.name)
      : response.name,
    children: [
      {
        id: `${index}`,
        ...response,
      },
    ],
    id: `${index}`,
  };
};

const containsARealChange = (response: APISurveyResponseLog) => {
  if (!isUpdatedResponse(response)) return true;
  // this suggests that the component was newly created.
  // we don't want to show this as an updated value in this case
  if (response.name === 'name' && response.previousVal === null) return false;
  // a response with no change should not be shown
  if (isEqual(response.previousVal, response.currentVal)) return false;
  return true;
};

// ModalSize.M max height is 71vh
const MODAL_HEIGHT = window.innerHeight * 0.71;
const TAB_HEIGHT = 57;
const MODAL_HEADER_HEIGHT = 56;
const WRAPPER_PADDING = 24 * 2;

type ResponseLogModalProps = {
  surveyName: string;
  initialUpdateType: UpdateType;
  closeResponseLogModal: () => void;
};

const ResponseLogModal = ({
  surveyName,
  initialUpdateType,
  responseLogSurveyId,
  responseLogData,
  responseLogIsLoading,
  responseLogErrorMessage,
  closeResponseLogModal,
}: ResponseLogModalProps & ResponseLogStreamShape) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [currentTabId, setCurrentTabId] =
    useState<UpdateType>(initialUpdateType);

  const handleTabChange = (newTabId: UpdateType) => {
    if (currentTabId === newTabId) return;
    setCurrentTabId(newTabId);
    trackEvent(`Survey response log: view ${newTabId}`);
    if (!responseLogSurveyId) return;
    dispatchAction(
      fetchResponseLogData({
        surveyId: responseLogSurveyId,
        updateType: newTabId,
      })
    );
  };

  const filteredResponseLog =
    responseLogData?.filter(responseRow =>
      satisfiesSearchFilter(responseRow, searchPhrase)
    ) ?? [];

  const preppedRows = filteredResponseLog
    .filter(containsARealChange)
    .map((response, index) => toRow(response, index))
    .sort((a, b) => sortBasedOnResponseType(a, b));

  const scrollableSectionHeight =
    MODAL_HEIGHT -
    (MODAL_HEADER_HEIGHT +
      TAB_HEIGHT +
      PAGINATION_BAR_HEIGHT +
      WRAPPER_PADDING);

  return (
    <ModalTemplate
      modalSize={ModalSize.M}
      headerText={`Response log for ${surveyName}`}
      onCloseButtonClick={closeResponseLogModal}
      fixedHeight
    >
      <TabsGroup
        onTabChange={tabId => handleTabChange(tabId as UpdateType)}
        activeTabId={currentTabId}
        rightContent={
          <StyledTextInput
            leftIconName={IconName.SEARCH}
            onValueChange={setSearchPhrase}
            placeholder="Search..."
            value={searchPhrase}
            onFocus={() =>
              trackEvent(`Survey response log: used search to filter`)
            }
          />
        }
      >
        <Tab tabId={UpdateType.UPDATED} label="Updates" />
        <Tab tabId={UpdateType.CREATED} label="New" />
        <Tab tabId={UpdateType.DELETED} label="Deleted" />
      </TabsGroup>
      <Box paddingX="medium">
        <Stack gap="medium">
          <InfoNotification>
            This table only shows changes to components within the survey, and
            not references or referenced components
          </InfoNotification>
          {responseLogIsLoading ? (
            <DoqLoader />
          ) : (
            <PaginationController
              dataSource={preppedRows}
              perPage={10}
              render={({
                currentPageDataSource,
                sortBy,
                sortById,
                sortOrder,
                onPageSelect,
                currentPageNumber,
                perPage,
              }) => (
                <>
                  <ExpandableTableProvider
                    idIndex="id"
                    dataSource={currentPageDataSource}
                    render={({
                      dataSource,
                      expandedFoldersIds: expandedRowIds,
                      expandFolder: expandRow,
                    }) => (
                      <DatasourceTable
                        isFixedLayout
                        dataSource={dataSource}
                        components={{ ...newTableTheme, ThBody }}
                        columns={getColumns(
                          currentTabId,
                          expandedRowIds,
                          expandRow,
                          sortBy,
                          sortById,
                          sortOrder
                        )}
                        renderEmptyTable={() => (
                          <EmptyContent
                            searchPhrase={searchPhrase}
                            updateType={currentTabId}
                            responseLogErrorMessage={responseLogErrorMessage}
                          />
                        )}
                        getRowComponent={surveyResponse => {
                          const { level } = surveyResponse.meta;
                          return level === 0 ? RowWithHover : Row;
                        }}
                        scrollableSectionHeight={scrollableSectionHeight}
                        fixedHeader
                      />
                    )}
                  />
                  {filteredResponseLog.length > perPage && (
                    <Pagination
                      style={{
                        justifyContent: 'center',
                        paddingTop: spacing.s16,
                      }}
                      currentPageNumber={currentPageNumber}
                      perPage={perPage}
                      totalResults={filteredResponseLog.length}
                      onPageSelect={onPageSelect}
                    />
                  )}
                </>
              )}
            />
          )}
        </Stack>
      </Box>
    </ModalTemplate>
  );
};

export default connect(ResponseLogModal, responseLog$);
