import { PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import HomeNavbar from '../appContainer/DashboardContainer/HomeNavbar';
import ViewpointsWidget from './viewpointsWidget/ViewpointsWidget';
import AssetsBrowser2024 from '../components/AssetsBrowser2024/AssetsBrowser2024';
import CreateNewButtonWithDropdown from './CreateNewButtonWithDropdown';
import { AssetsBrowser2024StreamShape } from '../components/AssetsBrowser2024/assetsBrowser2024Types';
import { isWorkspace, trackAndOpenWorkspaces } from './homePageUtils';
import { GhostButton } from '@ardoq/button';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { confirmOpenManagedWorkspaces } from '../externallyManaged/confirmOpenManagedWorkspaces/confirmOpenManagedWorkspaces';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { connect } from '@ardoq/rxbeach';
import {
  favoriteFilter,
  getAssetFilter,
} from '../components/AssetsBrowser2024/FilterBar/filters';
import { useWindowSize } from '@ardoq/hooks';
import styled from 'styled-components';
import { AppModules } from '../appContainer/types';
import { Header3 } from '@ardoq/typography';
import homePage$ from './homePage$';
import { AssetType } from '@ardoq/api-types';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import { Box, FlexBox, Stack } from '@ardoq/layout';

const MarginAutoFlexBox = styled(FlexBox)`
  margin-left: auto;
`;

const OpenSelectedWorkspacesButton = ({
  selectedWorkspacesIds,
}: {
  selectedWorkspacesIds: string[];
}) => (
  <GhostButton
    isDisabled={!selectedWorkspacesIds.length}
    overflowMethod="ellipsis"
    onClick={async () => {
      const externallyManagesWorkspaceNames = selectedWorkspacesIds
        .filter(workspaceInterface.isExternallyManaged)
        .map(workspaceInterface.getWorkspaceName)
        .filter(ExcludeFalsy);
      if (externallyManagesWorkspaceNames.length) {
        const confirmed = await confirmOpenManagedWorkspaces({
          workspaceNames: externallyManagesWorkspaceNames,
        });
        if (!confirmed) return;
      }
      trackAndOpenWorkspaces(selectedWorkspacesIds);
    }}
  >
    Open selected
  </GhostButton>
);

const HEADER_HEIGHT = 45;

export type HomePageConnectedProps = Pick<
  AssetsBrowser2024StreamShape,
  'features' | 'permissions' | 'assetsById' | 'commands' | 'appModuleStates'
> & {
  assetTypesInHomePage: AssetType[];
};

const HomePage = ({
  features,
  permissions,
  assetsById,
  appModuleStates: {
    [AppModules.HOME]: { selectedAssets },
  },
  commands,
  assetTypesInHomePage,
}: HomePageConnectedProps) => {
  const size = useWindowSize();
  const filters = [favoriteFilter, ...assetTypesInHomePage.map(getAssetFilter)];

  return (
    <PageWrapper>
      <HomeNavbar />
      <PageBody backgroundColor="surfaceDefault" skipPadding alignIslandsToLeft>
        <Box paddingX={'large'}>
          <Stack gap="large">
            <ViewpointsWidget />
            <Header3>Asset library</Header3>
            <AssetsBrowser2024
              appModule={AppModules.HOME}
              alwaysActiveFilters={[]}
              filters={filters}
              scrollHeight={size.height - (HEADER_HEIGHT + PAGE_TOPBAR_HEIGHT)}
              topRightContent={
                <MarginAutoFlexBox>
                  <OpenSelectedWorkspacesButton
                    selectedWorkspacesIds={selectedAssets.filter(assetId =>
                      isWorkspace(assetId, assetsById)
                    )}
                  />
                  <CreateNewButtonWithDropdown
                    commands={commands}
                    features={features}
                    permissions={permissions}
                  />
                </MarginAutoFlexBox>
              }
            />
          </Stack>
        </Box>
      </PageBody>
    </PageWrapper>
  );
};

export default connect(HomePage, homePage$);
