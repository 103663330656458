import { usePopover } from '@ardoq/popovers';
import { Step, StepProps } from '@ardoq/steppers';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { isReviewStepAvailable } from './utils';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { getReviewStepState } from 'integrations/common/utils/step';
import { getDataLoadingStream } from 'integrations/unified/streams/dataLoading/dataLoading$';
import { getResourcesStream } from 'integrations/unified/streams/resources/resources$';
import { ImportRoute } from 'integrations/common/navigation/types';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { FlexBox } from '@ardoq/layout';

const STEP_PATH = ImportRoute.REVIEW;

const handleStepClick = (integrationId: IntegrationId) => () => {
  dispatchAction(
    navigateToPath({
      integrationId,
      path: STEP_PATH,
      source: 'stepper',
    })
  );
};

const getReviewStepPopoverText = (_props: any) => {
  return 'Please run the tests again.';
};

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId => {
    return combineLatest({
      activeIntegration: getActiveIntegrationStream(integrationId),
      transferState: getTransferStateStream(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
      dataLoading: getDataLoadingStream(integrationId),
      resources: getResourcesStream(integrationId),
    });
  }),
  map(
    ({
      activeIntegration,
      transferState,
      tabularMapping,
      dataLoading,
      resources,
    }) => {
      const { integrationPath, integrationId } = activeIntegration;
      return {
        isDisabled: !isReviewStepAvailable({
          dataLoading,
          selectedResources: resources.selectedResources,
          transferState,
          tabularMapping,
        }),
        onPress: handleStepClick(integrationId),
        isSelected: integrationPath === STEP_PATH,
        heading: 'Review import',
        state: getReviewStepState({ transferState }),
      };
    }
  )
);

const ReviewStepComponent = ({
  onPress,
  isDisabled,
  isSelected,
  heading,
  state,
}: StepProps) => {
  const popoverText = getReviewStepPopoverText(state);
  const popoverProps = usePopover(() => popoverText);

  return (
    <FlexBox flex={1} {...(isDisabled && popoverText ? popoverProps : {})}>
      <Step
        onPress={onPress}
        isDisabled={isDisabled}
        isSelected={isSelected}
        heading={heading}
        state={state}
        stepNumber={3}
      />
    </FlexBox>
  );
};

export const ReviewStep = connect(ReviewStepComponent, viewModel$);
