import { ModalSize, ModalTemplate } from '@ardoq/modal';
import { useState } from 'react';
import { colors, s16, s32 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import PaginatedUserTable from './PaginatedUserTable';
import {
  APIOrganizationUser,
  ArdoqId,
  PermissionGroup,
} from '@ardoq/api-types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  addUsersToGroup,
  fetchPermissionGroups,
} from 'admin/accessControl/PermissionGroups/streams/actions';
import { useEffectOnce } from '@ardoq/hooks';
import { RadioGroup, RadioGroupItem } from '@ardoq/forms';
import {
  EmptyState,
  EmptyStateNoResultIllustration,
  ToastType,
  showToast,
} from '@ardoq/status-ui';
import { Island } from '@ardoq/page-layout';
import manageUserRolesViewModel$ from '../../accessControl/ManageUserRoles/streams/manageUserRolesViewModel$';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { PrimaryButton } from '@ardoq/button';
import { FlexBox } from '@ardoq/layout';
import { navigateToAccessControlPage } from 'router/navigationActions';
import { AccessControlTabs } from 'admin/accessControl/navigation/types';
import { trackOrgMembersView } from '../utils';
import { TrackOrgMembersViewUserEvents } from '../types';

const Wrapper = styled.div`
  background-color: ${colors.grey95};
  padding: ${s32};
  display: flex;
  flex-direction: column;
  gap: ${s16};
`;

type AddToGroupDialogProps = {
  closeDialog: () => void;
  selectedUsers: APIOrganizationUser[];
  permissionGroups: PermissionGroup[];
  addToGroupError: string | null;
};

const AddToGroupDialog = ({
  closeDialog,
  selectedUsers,
  permissionGroups,
  addToGroupError,
}: AddToGroupDialogProps) => {
  const [selectedGroup, setSelectedGroup] = useState<ArdoqId | undefined>(
    undefined
  );

  const addUserstoSelectedGroup = () => {
    if (!selectedGroup) return;
    const userIds: ArdoqId[] = selectedUsers.map(user => user._id);
    dispatchAction(
      addUsersToGroup({ permissionGroupId: selectedGroup, userIds: userIds })
    );
    if (!addToGroupError) {
      showToast('Group updated successfully', ToastType.SUCCESS);
    }
    closeDialog();
  };

  useEffectOnce(() => dispatchAction(fetchPermissionGroups()));

  return (
    <ModalTemplate
      headerText="Add to group"
      modalSize={ModalSize.M}
      onCloseButtonClick={closeDialog}
      onPrimaryButtonClick={addUserstoSelectedGroup}
      onSecondaryButtonClick={closeDialog}
      primaryButtonClickId="save-group-click-id"
      primaryButtonText="Save"
      secondaryButtonClickId="cancel-group-click-id"
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={!selectedGroup}
    >
      <Wrapper>
        <Island title="Select group">
          {permissionGroups.length > 0 ? (
            <RadioGroup
              value={selectedGroup}
              onValueChange={value => setSelectedGroup(value as string)}
            >
              {permissionGroups
                .sort((a, b) =>
                  localeCompare(a.name, b.name, getCurrentLocale())
                )
                .map((group, index) => (
                  <RadioGroupItem key={index} value={group._id}>
                    {group.name}
                  </RadioGroupItem>
                ))}
            </RadioGroup>
          ) : (
            <FlexBox justify="center">
              <EmptyState
                description={`There are no groups for this organization.`}
                title="No groups"
                media={<EmptyStateNoResultIllustration />}
                layout="horizontal"
                size="small"
              >
                <PrimaryButton
                  onClick={() => {
                    trackOrgMembersView(
                      TrackOrgMembersViewUserEvents.CLICKED_NAVIGATE_TO_PERMISSION_GROUPS_PAGE
                    );
                    dispatchAction(
                      navigateToAccessControlPage({
                        accessControlTab: AccessControlTabs.PERMISSION_GROUPS,
                      })
                    );
                    closeDialog();
                  }}
                >
                  Go to the groups page
                </PrimaryButton>
              </EmptyState>
            </FlexBox>
          )}
        </Island>
        <PaginatedUserTable selectedUsers={selectedUsers} />
      </Wrapper>
    </ModalTemplate>
  );
};

export default connect(AddToGroupDialog, manageUserRolesViewModel$);
