import ProfileForm from './ProfileForm';
import CurrentUser from 'models/currentUser';
import { PersonalSetting } from '@ardoq/api-types';
import EmailView from './EmailView/EmailView';
import PasswordView from './PasswordView/PasswordView';
import TokenView from './TokenView/TokenView';
import UserSettingsView$ from './UserSettingsView$';
import { UserSettingsRoute, UserSettingsTabs } from './types';
import ModuleContainer from 'appContainer/ModuleContainer';
import { TabButton, TabsGroup } from '@ardoq/tabs';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { navigateToUserSettings } from './actions';
import { trackEvent } from 'tracking/tracking';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import currentUser$ from 'streams/currentUser/currentUser$';
import { map, withLatestFrom } from 'rxjs';
import { get } from 'lodash';
import { CurrentUserState } from 'streams/currentUser/currentUser$';
import { TabBody } from './atoms';
import { Features, hasFeature } from '@ardoq/features';
import MainToolbar from 'menus/topbar/MainToolbar';
import Navbar from 'views/navbar/Navbar';
import { PageHeader, PageWrapper } from '@ardoq/page-layout';

type UserSettingsViewProps = UserSettingsRoute & {
  showChangeEmailAndPassword: boolean;
  hasNewJourneyFeature: boolean;
};

const UserSettingsView = ({
  userSettingsSelectedTab,
  showChangeEmailAndPassword,
  hasNewJourneyFeature,
}: UserSettingsViewProps) => {
  const tabs = [
    {
      label: 'Profile',
      tabId: UserSettingsTabs.PROFILE,
      content: (
        <ProfileForm
          name={CurrentUser.get('name')}
          email={CurrentUser.get('email')}
          organization={CurrentUser.getPersonalSetting(
            PersonalSetting.ORGANIZATION
          )}
          save={async ({ locale, name, organization }) => {
            CurrentUser.set('name', name);
            CurrentUser.setPersonalSettings([
              [PersonalSetting.ORGANIZATION, organization],
              [PersonalSetting.LOCALE, locale.code],
            ]);
            await CurrentUser.save();
          }}
        />
      ),
    },
    ...(showChangeEmailAndPassword
      ? [
          {
            label: 'Change e-mail',
            tabId: UserSettingsTabs.EMAIL,
            content: <EmailView />,
          },
        ]
      : []),
    {
      label: 'API tokens',
      tabId: UserSettingsTabs.API,
      content: <TokenView />,
      statusType: undefined,
    },
    ...(showChangeEmailAndPassword
      ? [
          {
            label: 'Password',
            tabId: UserSettingsTabs.PASSWORD,
            content: <PasswordView />,
          },
        ]
      : []),
  ];

  return (
    <ModuleContainer $overflow="hidden">
      <PageWrapper>
        {hasNewJourneyFeature ? (
          <Navbar
            primaryContent="Account Settings"
            secondaryContent="Preferences"
            toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
          />
        ) : (
          <PageHeader title="Account Settings" />
        )}
        <TabsGroup
          activeTabId={userSettingsSelectedTab}
          onTabChange={tabId => {
            trackEvent(APPSEC_EVENTS.CLICKED_USER_ACCOUNT_SETTINGS_TAB, {
              to: tabId,
              from: userSettingsSelectedTab,
              namespace: 'user account settings',
            });
            dispatchAction(navigateToUserSettings(tabId as UserSettingsTabs));
          }}
        >
          {tabs.map((tab, i) => (
            <TabButton
              key={i}
              tabId={tab.tabId}
              label={tab.label}
              statusType={tab.statusType}
            >
              <TabBody>{tab.content}</TabBody>
            </TabButton>
          ))}
        </TabsGroup>
      </PageWrapper>
    </ModuleContainer>
  );
};

function hasPlainLogin(currentUser: CurrentUserState): boolean {
  return get(
    currentUser,
    'organization.custom-domain.login-options.plain',
    true
  );
}

export default connect(
  UserSettingsView,
  UserSettingsView$.pipe(
    withLatestFrom(currentUser$),
    map(
      ([userSettingsView, currentUser]): UserSettingsViewProps => ({
        userSettingsSelectedTab: userSettingsView.userSettingsSelectedTab,
        showChangeEmailAndPassword: hasPlainLogin(currentUser),
        hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
      })
    )
  )
);
