import { Step, StepState } from '@ardoq/steppers';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { Maybe } from '@ardoq/common-helpers';
import { getResourcesStream } from 'integrations/unified/streams/resources/resources$';
import { IntegrationResources } from 'integrations/unified/streams/resources/types';
import { isValidResourcesSelection } from 'integrations/unified/streams/resources/utils';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { ImportRoute } from 'integrations/common/navigation/types';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';

const STEP_PATH = ImportRoute.SELECT_DATA;

const handleStepClick = (integrationId: IntegrationId) => () => {
  dispatchAction(
    navigateToPath({
      integrationId,
      path: STEP_PATH,
      source: 'stepper',
    })
  );
};

const getSelectDataStepState = ({
  selectedResources,
}: {
  integrationPath: Maybe<string>;
  selectedResources: IntegrationResources['selectedResources'];
}) => {
  if (isValidResourcesSelection(selectedResources)) {
    return StepState.DONE;
  }

  return StepState.ACTIVE;
};

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId => {
    return combineLatest({
      activeIntegration: getActiveIntegrationStream(integrationId),
      selectedResources: getResourcesStream(integrationId).pipe(
        map(({ selectedResources }) => selectedResources)
      ),
    });
  }),
  map(({ activeIntegration, selectedResources }) => {
    const { integrationPath, integrationId } = activeIntegration;
    return {
      onPress: handleStepClick(integrationId),
      isSelected: integrationPath === STEP_PATH,
      heading: 'Select data',
      state: getSelectDataStepState({ integrationPath, selectedResources }),
      stepNumber: 1,
    };
  })
);

export const SelectDataStep = connect(Step, viewModel$);
