import { useState } from 'react';
import { ModalLayout, ModalTemplate, modal } from '@ardoq/modal';
import styled from 'styled-components';
import { colors, r8, s16 } from '@ardoq/design-tokens';
import { Select } from '@ardoq/select';
import { Space } from '@ardoq/style-helpers';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { Tag } from '@ardoq/status-ui';
import { FormSize, TextInput } from '@ardoq/forms';
import { IconButton } from '@ardoq/button';
import { CloseIcon, ConditionalIcon, IconName, IconSize } from '@ardoq/icons';
import {
  ALL_FILTER_CONDITIONS,
  EmptyFilterCondition,
  FILTER_LABEL,
  isConditionWithValue,
  isFilterCondition,
} from './utils';
import {
  FilterCondition,
  FilterConditionForValue,
} from '@ardoq/api-types/integrations';
import {
  IntegrationId,
  TableMappingMap,
} from 'integrations/common/streams/tabularMappings/types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { setColumnFilter } from 'integrations/common/streams/tabularMappings/actions';
import { viewModel$ } from './viewModel$';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { Header2 } from '@ardoq/typography';

type FilterModalProps = {
  columnFilters: TableMappingMap['columnFilters'];
  columnName: string;
  columnIndex: number;
  onApplyFilter: (filters: FilterCondition[], columnIndex: number) => void;
  onClearFilter: VoidFunction;
  onClose: () => void;
};

const DEFAULT_FILTER_CONDITION: EmptyFilterCondition = {
  operator: null,
};

function FilterModalComponent({
  columnFilters,
  columnName,
  columnIndex,
  onClose,
  onApplyFilter,
  onClearFilter,
}: FilterModalProps) {
  const initFilters = columnFilters[columnIndex]?.length
    ? columnFilters[columnIndex]
    : [DEFAULT_FILTER_CONDITION];

  const [conditionsList, setConditionsList] =
    useState<Array<FilterCondition | EmptyFilterCondition>>(initFilters);

  const onConditionSelect = (
    operator: FilterCondition['operator'],
    index: number
  ) => {
    const condition = ALL_FILTER_CONDITIONS.find(
      cond => cond.operator === operator
    );
    if (condition) {
      setConditionsList([
        ...conditionsList.slice(0, index),
        condition,
        ...conditionsList.slice(index + 1),
      ]);
    }
  };

  const onConditionValueChange = (
    condition: FilterConditionForValue,
    index: number
  ) => {
    setConditionsList([
      ...conditionsList.slice(0, index),
      condition,
      ...conditionsList.slice(index + 1),
    ]);
  };

  const onConditionDelete = (index: number) => {
    setConditionsList(conditionsList.filter((_, idx) => idx !== index));
  };

  const conditions = conditionsList.filter(isFilterCondition);

  return (
    <ModalTemplate
      headerText="Apply filter"
      modalSize={3}
      onCloseButtonClick={onClose}
      onPrimaryButtonClick={() => onApplyFilter(conditions, columnIndex)}
      onSecondaryButtonClick={onClearFilter}
      primaryButtonClickId="apply-filter-click"
      primaryButtonText="Apply"
      secondaryButtonClickId="cancel-filter-click"
      secondaryButtonText="Clear"
      isPrimaryButtonDisabled={
        !conditions.length ||
        conditions.some(
          condition =>
            isConditionWithValue(condition) && !condition.value.trim()
        )
      }
    >
      <ModalLayout>
        <Header2>Column: {columnName}</Header2>
        <p>Include rows which meet the following criteria</p>
        <Stack gap="medium">
          {conditionsList.map((condition, index) => (
            <Space key={index} $align="center">
              {conditionsList.length > 1 && (
                <TagWrapper $visible={index !== 0}>And</TagWrapper>
              )}
              <Border
                $flex={1}
                $align="center"
                $showConnection={index !== conditionsList.length - 1}
                $showBorderTop={index === 0}
              >
                <ConditionalIcon size={IconSize.SMALL} />
                <FlexBox gap="medium" flex={1}>
                  <Select
                    placeholder="Select condition"
                    formSize={FormSize.FULL}
                    options={ALL_FILTER_CONDITIONS.map(({ operator }) => ({
                      label: FILTER_LABEL[operator],
                      value: operator,
                    }))}
                    value={condition.operator}
                    onValueChange={option =>
                      option && onConditionSelect(option, index)
                    }
                  />
                </FlexBox>
                {isConditionWithValue(condition) && (
                  <FlexBox gap="medium" flex={1}>
                    <TextInput
                      formSize={FormSize.FULL}
                      value={condition.value}
                      displayValue={condition.value}
                      onValueChange={value => {
                        onConditionValueChange({ ...condition, value }, index);
                      }}
                    />
                  </FlexBox>
                )}
                {conditionsList.length > 1 && (
                  // We could use the icon button here but that has its own padding and make ui bad
                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => onConditionDelete(index)}
                  >
                    And
                  </CloseIcon>
                )}
              </Border>
            </Space>
          ))}
        </Stack>
        <IconButton
          onClick={() => {
            setConditionsList([...conditionsList, DEFAULT_FILTER_CONDITION]);
          }}
          iconName={IconName.ADD}
        />
      </ModalLayout>
    </ModalTemplate>
  );
}

const FilterModal = connect(FilterModalComponent, viewModel$);

export const startFilterModal = ({
  columnName,
  columnIndex,
  integrationId,
}: Pick<FilterModalProps, 'columnName' | 'columnIndex'> & {
  integrationId: IntegrationId;
}) => {
  dispatchAction(
    trackIntegrationEvent({ integrationId, name: 'OPENED_COLUMN_FILTER_MODAL' })
  );
  modal(
    resolve => (
      <FilterModal
        columnName={columnName}
        columnIndex={columnIndex}
        onApplyFilter={filters => {
          dispatchAction(
            trackIntegrationEvent({
              integrationId,
              name: 'APPLIED_COLUMN_FILTERS',
            })
          );
          dispatchAction(
            setColumnFilter({ integrationId, filters, columnIndex })
          );
          resolve(filters);
        }}
        onClearFilter={() => {
          dispatchAction(
            trackIntegrationEvent({
              integrationId,
              name: 'CLEARED_COLUMN_FILTERS',
            })
          );
          dispatchAction(
            setColumnFilter({ integrationId, filters: [], columnIndex })
          );
          resolve([]);
        }}
        onClose={() => resolve([])}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
};

const TagWrapper = styled(Tag)<{ $visible: boolean }>`
  z-index: 1;
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`;

const Border = styled(Space)<{
  $showConnection?: boolean;
  $showBorderTop?: boolean;
}>`
  padding: ${s16};
  border: 1px dashed ${colors.grey80};
  border-radius: ${r8};
  position: relative;
  ${({ $showConnection, $showBorderTop }) =>
    $showConnection &&
    `
    &:before {
      position: absolute;
      left: -37px;
      width: 37px;
      top: 35px;
      border-radius: ${r8} 0px 0px ${r8};
      border-left: 2px dashed ${colors.blue60};
      border-bottom: 2px dashed ${colors.blue60};
      height: 88px;
      content: ' ';

      ${$showBorderTop && `border-top: 2px dashed ${colors.blue60};`}
    }
  `}
`;
