import { APISurveyAttributes, SurveySectionStatus } from '@ardoq/api-types';
import { SurveyEditorSectionKey } from '../streams/types';
import { SurveySectionId } from 'surveyAdmin/consts';
import { validationHelpers } from 'surveyAdmin/surveyValidation';
import { MaybePersistedSurvey, SurveyValidation } from 'surveyAdmin/types';
import { Features, hasFeature } from '@ardoq/features';

const sectionStatusMap: Record<
  SurveyEditorSectionKey,
  (surveyAttributes: MaybePersistedSurvey) => SurveySectionStatus
> = {
  detailsSection: surveyAttributes =>
    surveyAttributes.misc.generalInformationSectionStatus ?? 'left',
  workspaceAndComponentsSection: surveyAttributes =>
    surveyAttributes.misc.defineTheDataSectionStatus ?? 'left',
  changeApprovalSection: surveyAttributes =>
    surveyAttributes.misc.changeApprovalSectionStatus ?? 'left',
  surveySection: surveyAttributes =>
    surveyAttributes.misc.questionsSectionStatus ?? 'left',
  responseFeedback: surveyAttributes =>
    surveyAttributes.misc.feedbackMessageSectionStatus ?? 'left',
  resultDisplayOptionsSection: surveyAttributes =>
    surveyAttributes.misc.landingPageSectionStatus ?? 'left',
  resultFilteringSection: surveyAttributes =>
    surveyAttributes.misc.resultsDisplaySectionStatus ?? 'left',
  discover: surveyAttributes =>
    surveyAttributes.misc.ardoqDiscoverSectionStatus ?? 'left',
};

export const getMiscStatusKey = (
  key: SurveyEditorSectionKey
): keyof APISurveyAttributes['misc'] => {
  switch (key) {
    case 'detailsSection':
      return 'generalInformationSectionStatus';
    case 'workspaceAndComponentsSection':
      return 'defineTheDataSectionStatus';
    case 'changeApprovalSection':
      return 'changeApprovalSectionStatus';
    case 'surveySection':
      return 'questionsSectionStatus';
    case 'responseFeedback':
      return 'feedbackMessageSectionStatus';
    case 'resultDisplayOptionsSection':
      return 'landingPageSectionStatus';
    case 'resultFilteringSection':
      return 'resultsDisplaySectionStatus';
    case 'discover':
      return 'ardoqDiscoverSectionStatus';
  }
};

const sectionErrorMap: Record<
  SurveyEditorSectionKey,
  (surveyValidation?: SurveyValidation) => boolean
> = {
  detailsSection: surveyValidation =>
    validationHelpers.sectionHasError(
      surveyValidation,
      SurveySectionId.GENERAL_INFORMATION
    ),
  workspaceAndComponentsSection: surveyValidation =>
    validationHelpers.sectionHasError(
      surveyValidation,
      SurveySectionId.DEFINE_THE_DATA
    ),
  changeApprovalSection: surveyValidation =>
    validationHelpers.sectionHasError(
      surveyValidation,
      SurveySectionId.CHANGE_APPROVAL
    ),
  surveySection: surveyValidation =>
    validationHelpers.sectionHasError(
      surveyValidation,
      SurveySectionId.QUESTIONS
    ),
  resultFilteringSection: surveyValidation =>
    validationHelpers.sectionHasError(
      surveyValidation,
      SurveySectionId.RESULTS_DISPLAY
    ),
  responseFeedback: () => false,
  resultDisplayOptionsSection: () => false,
  discover: () => false,
};

const sectionWarningMap: Record<
  SurveyEditorSectionKey,
  (surveyValidation?: SurveyValidation) => boolean
> = {
  detailsSection: surveyValidation =>
    validationHelpers.sectionHasWarning(
      surveyValidation,
      SurveySectionId.GENERAL_INFORMATION
    ),
  workspaceAndComponentsSection: surveyValidation =>
    validationHelpers.sectionHasWarning(
      surveyValidation,
      SurveySectionId.DEFINE_THE_DATA
    ),
  changeApprovalSection: surveyValidation =>
    validationHelpers.sectionHasWarning(
      surveyValidation,
      SurveySectionId.CHANGE_APPROVAL
    ),
  surveySection: surveyValidation =>
    validationHelpers.sectionHasWarning(
      surveyValidation,
      SurveySectionId.QUESTIONS
    ),
  resultFilteringSection: surveyValidation =>
    validationHelpers.sectionHasWarning(
      surveyValidation,
      SurveySectionId.RESULTS_DISPLAY
    ),
  responseFeedback: () => false,
  resultDisplayOptionsSection: () => false,
  discover: () => false,
};

export const getSectionStatus = (
  key: SurveyEditorSectionKey,
  surveyAttributes: MaybePersistedSurvey
): SurveySectionStatus => {
  return sectionStatusMap[key](surveyAttributes);
};

export const getSectionHasError = (
  key: SurveyEditorSectionKey,
  surveyValidation?: SurveyValidation
): boolean => {
  return sectionErrorMap[key](surveyValidation);
};

export const getSectionHasWarning = (
  key: SurveyEditorSectionKey,
  surveyValidation?: SurveyValidation
): boolean => {
  return sectionWarningMap[key](surveyValidation);
};

export const getSectionIsDisabled = (key: SurveyEditorSectionKey): boolean => {
  return (
    key === 'changeApprovalSection' &&
    hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL_EXPIRED)
  );
};

export const getSectionSubHeading = (
  key: SurveyEditorSectionKey,
  isDisabled: boolean
): string | undefined => {
  if (key === 'changeApprovalSection' && isDisabled) {
    return 'Trial period has ended';
  }
};
