import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId } from '@ardoq/api-types';
import { EnhancedScopeData } from '@ardoq/data-model';

export const setComponentOverviewId = actionCreator<ArdoqId>(
  '[componentOverviewPage] SET_COMPONENT_OVERVIEW_ID'
);

export const getComponentOverviewPageData = actionCreator<ArdoqId>(
  '[componentOverviewPage] GET_COMPONENT_OVERVIEW_DATA'
);

export const setComponentOverviewData = actionCreator<EnhancedScopeData>(
  '[componentOverviewPage] SET_COMPONENT_OVERVIEW_DATA'
);

export const setComponentOverviewPageError = actionCreator(
  '[componentOverviewPage] SET_COMPONENT_OVERVIEW_PAGE_ERROR'
);
