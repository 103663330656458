import { colors } from '@ardoq/design-tokens';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { forwardRef } from 'react';
import { ViewpointNavigatorIconButton } from 'viewpointNavigator/atoms';

export const ViewpointSidebarSortButton = forwardRef<
  HTMLButtonElement,
  { onMenuOpen: () => void; isMenuOpened: boolean }
>(({ onMenuOpen, isMenuOpened }, ref) => (
  <ViewpointNavigatorIconButton
    data-tooltip-text="Sort"
    onClick={onMenuOpen}
    $isActive={isMenuOpened}
    ref={ref}
  >
    <Icon
      iconName={IconName.SORT}
      iconSize={IconSize.MEDIUM}
      color={colors.grey50}
    />
  </ViewpointNavigatorIconButton>
));

export const ViewpointSidebarScenariosButton = forwardRef<
  HTMLButtonElement,
  { onMenuOpen: () => void; isMenuOpened: boolean }
>(({ onMenuOpen, isMenuOpened }, ref) => (
  <ViewpointNavigatorIconButton
    data-tooltip-text="Scenario"
    onClick={onMenuOpen}
    $isActive={isMenuOpened}
    ref={ref}
  >
    <Icon
      iconName={IconName.SCENARIO}
      iconSize={IconSize.MEDIUM}
      style={{ color: colors.grey50 }}
    />
  </ViewpointNavigatorIconButton>
));
