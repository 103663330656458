import {
  ArdoqId,
  APIPresentationAssetAttributes,
  ViewIds,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { DoqType } from '@ardoq/doq';
import { trackClickedKBArticleLink } from 'admin/migrationChecklist/tracking';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { slideInterface } from 'modelInterface/presentations/slideInterface';
import { renderMainPresentationView } from 'presentation/actions';
import { selectSlide } from 'presentation/slideNavigator/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import getMetaInfo from 'views/metaInfo';
import { ViewModifier } from 'viewDeprecation/types';
import {
  DISCONTINUED_VIEW_SETTINGS,
  getLeftMenuConfig,
} from 'viewDeprecation/consts';
import { updatePresentation } from 'streams/presentations/actions';
import { slideNamespace } from 'streams/slides/actions';
import { viewSettingsConsts } from '@ardoq/view-settings';
import { deleteRequested } from 'streams/crud/actions';

export const presentationSlidesViewInfo = (
  presentation: APIPresentationAssetAttributes
) =>
  (presentation.slides ?? [])
    ?.map(slideId => {
      const viewId = slideInterface.getViewId(slideId);

      if (!viewId) {
        return null;
      }
      const viewMetaInfo = getMetaInfo().get(viewId);
      if (!viewMetaInfo) {
        return null;
      }
      const { viewStatus, killDate } = viewMetaInfo;

      return viewStatus
        ? { slideId, viewStatus, killDate: killDate ?? null, viewId }
        : null;
    })
    .filter(ExcludeFalsy);

export const getDeprecatedSlidesInfo = (
  presentation: APIPresentationAssetAttributes
) =>
  presentationSlidesViewInfo(presentation)
    ?.filter(
      ({ viewStatus }) =>
        viewStatus === MetaInfoViewStatus.SOON_TO_BE_DISCONTINUED ||
        viewStatus === MetaInfoViewStatus.DISCONTINUED
    )
    .map(({ slideId, viewStatus, killDate, viewId }) => {
      const slideInfo = slideInterface.getSlideInfo(slideId);
      if (!slideInfo) {
        return null;
      }

      return {
        viewStatus,
        killDate,
        slideId,
        viewId,
        ...slideInfo,
      };
    })
    .filter(ExcludeFalsy) || [];

export const getDoqTypeForView = (viewId?: ViewIds) => {
  switch (viewId) {
    case ViewIds.RELATIONSHIPS:
      return DoqType.RELATIONSHIPS_GONE;
    case ViewIds.DEPMAP:
      return DoqType.DEPENDENCY_MAP_GONE;
    default:
      return DoqType.PAGE_GONE;
  }
};

export const openSlideMigrationKBArticle = () => {
  trackClickedKBArticleLink();
  window.open(KnowledgeBaseLink.MIGRATE_YOUR_DATA_TO_NEW_VIEWS, '_blank');
};

export const doDeleteSlide = (
  slideId: ArdoqId,
  presentation: APIPresentationAssetAttributes
) => {
  const allSlideIDs = presentation.slides ?? [];
  let nextSlideIndex = allSlideIDs.indexOf(slideId) + 1;

  // if it was the last slide and it was deleted, show the previous slide
  if (nextSlideIndex >= allSlideIDs.length) nextSlideIndex -= 2;
  const nextSlideID = allSlideIDs[nextSlideIndex];

  // delete the slide
  dispatchAction(
    updatePresentation({
      ...presentation,
      slides: presentation.slides.filter(value => {
        return value !== slideId;
      }),
    })
  );
  dispatchAction(deleteRequested(slideNamespace, slideId));

  // rerender the view
  dispatchAction(selectSlide(nextSlideID));
  dispatchAction(renderMainPresentationView());
};

export const isSlideViewDiscontinued = (slideId: ArdoqId) => {
  const metaInfo = getMetaInfo();
  const viewId = slideInterface.getViewId(slideId);

  if (!viewId) {
    return null;
  }
  const viewMetaInfo = metaInfo.get(viewId);
  if (!viewMetaInfo) {
    return null;
  }
  return viewMetaInfo.viewStatus === MetaInfoViewStatus.DISCONTINUED;
};

type IsViewSettingDiscontinuedArgs = {
  viewId: ViewIds;
  settingId: string;
};

const isViewSettingDiscontinued = ({
  viewId,
  settingId,
}: IsViewSettingDiscontinuedArgs) =>
  !!DISCONTINUED_VIEW_SETTINGS.get(viewId)?.includes(settingId);

export const getViewModifierValuesForSlide = (
  viewId: ViewIds,
  viewState: Record<keyof typeof viewSettingsConsts, string>
) => {
  const viewModifiers: ViewModifier[] = [];
  // the leftMenuConfig is not too generic, adjust as the need arises
  const leftMenuConfig = getLeftMenuConfig(viewId, viewState);

  for (const entry of Object.entries(viewState)) {
    // we need to account for diverse structure of the view setting objects
    // this may vary across views, adjust as needed
    for (const settingsConfig of leftMenuConfig) {
      const settingValue = entry[0] === 'straightLines' ? !entry[1] : entry[1];

      if (settingValue) {
        // design: exclude falsy
        if (settingsConfig.id === entry[0]) {
          viewModifiers.push({
            valueLabel: `${settingsConfig.label} = ${String(settingValue)}`,
            isDiscontinued: isViewSettingDiscontinued({
              viewId,
              settingId: settingsConfig.id,
            }),
          });
        } else if (
          settingsConfig.id.includes('slider-') &&
          settingsConfig.options?.length !== 0
        ) {
          const settingsConfigOption = settingsConfig.options?.find(
            option => option.name === entry[0]
          );

          if (settingsConfigOption) {
            viewModifiers.push({
              valueLabel: `${settingsConfigOption.label} = ${String(
                settingValue
              )}`,
              isDiscontinued: isViewSettingDiscontinued({
                viewId,
                settingId: settingsConfigOption.name,
              }),
            });
          }
        }
      }
    }
  }

  return viewModifiers;
};
