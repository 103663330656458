import { combineLatest, map } from 'rxjs';
import activeView$ from 'streams/views/mainContent/activeView$';
import { availableViews$ } from 'views/availableViews$';

const viewPaneHeader$ = combineLatest([activeView$, availableViews$]).pipe(
  map(([{ mainViewId }, { views }]) => ({
    mainViewId,
    views,
  }))
);
export default viewPaneHeader$;
