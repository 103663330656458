import { ExportRequestPayload, ExportResponse } from './types';
import { TransferResponse } from 'integrations/common/streams/transferState/types';
import fp from 'lodash/fp';
import { addColumnIds } from './utils';

const API_URL = '/api/integrations/servicenow';

export class ExportError extends Error {
  constructor(
    public status: 'ExportDryRunError' | 'ExportError',
    public message: string
  ) {
    super();
  }
}

export const exportRun = async ({
  connectionId,
  payload,
}: {
  connectionId: string;
  payload: ExportRequestPayload;
}) => {
  const url = `${API_URL}/connection/${connectionId}/export`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...fp.update('tabularMapping', addColumnIds, payload),
        sinceTime: null,
      }),
    });
    if (response.status !== 201) {
      throw new ExportError('ExportDryRunError', response.statusText);
    }

    const responseData: ExportResponse = await response.json();
    const formatted: TransferResponse = {
      ...fp.omit('remoteChanges', responseData),
      ...responseData.remoteChanges,
      newFields: null,
      sourceId: 'export',
    };
    return { ...formatted, async: false };
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw error;
    }

    throw new Error(String(error));
  }
};
