import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { AppRouterState } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { navigateToAnalyticsOverview } from 'router/navigationActions';
import { EMPTY } from 'rxjs';

const analyticsRoute = new Route<AppRouterState, void>({
  doesLocationMatch: ({ path }) => new RegExp(`/analytics/?`).test(path),
  locationToRouterState: () => {
    return {
      appModule: AppModules.ANALYTICS,
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.ANALYTICS,
  routerStateToLocation: () => {
    return {
      path: '/analytics',
      title: 'Analytics Overview',
    };
  },
  setApplicationStateFromRoute: () => {
    dispatchAction(navigateToAnalyticsOverview());
  },
  getPartialRouterStateStream: () => EMPTY,
});

export default analyticsRoute;
