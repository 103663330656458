import type { BroadcastComponent } from '@ardoq/api-types';
import { HAS_MORE_ID } from 'broadcasts/consts';
import type { ComponentPreviewDialogStreamShape } from './types';

export const getDefaultState = (): ComponentPreviewDialogStreamShape => ({
  isLoading: true,
  hasError: false,
  components: [],
  total: 0,
});

export const withHasMoreSpecialItem = (
  components: BroadcastComponent[],
  total: number
) => {
  return [
    ...components,
    {
      _id: HAS_MORE_ID,
      name: `Showing the first ${components.length} of ${total} results.`,
    },
  ];
};
