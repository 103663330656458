import { RelatedContextWrapper } from './atoms';

const OutOfSyncRelatedView = () => (
  <RelatedContextWrapper>
    <p>
      The metamodel of the scenario is incompatible with the metamodel in the
      mainline.
    </p>
    <p>
      To get the metamodels back to a compatible state, please use the scenario
      merge functionality.
    </p>
  </RelatedContextWrapper>
);

export default OutOfSyncRelatedView;
