import { derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import fabVisibility$ from '../fabVisibility$';

const toReferenceFabStream = ([isVisible, { options }]: [
  boolean,
  AppModelStateEditStreamShape,
]) => {
  return {
    isActive: options.type === GetContentOptionsType.CREATE_REFERENCE,
    isVisible,
  };
};

const referenceFab$ = derivedStream(
  'referenceFab$',
  fabVisibility$,
  appModelStateEdit$
).pipe(map(toReferenceFabStream));

export default referenceFab$;
