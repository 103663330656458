import { useState } from 'react';
import { DatasourceItem, Folder, Row, RowItem } from './types';
import { Selected } from 'aqTypes';
import {
  ArdoqId,
  AssetType,
  SortOrder,
  Asset,
  AssetFolder as StreamFolder,
  Workspace as StreamWorkspace,
  APIPresentationAssetAttributes,
  ResourceType,
  PermissionAccessLevel,
} from '@ardoq/api-types';
import { get, uniq, without } from 'lodash';
import { findRangeSelectIds, GenericRow, RowType } from '@ardoq/table';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import * as selectAllUtils from './SelectAllCheckbox';
import {
  AssetRow as AssetRow,
  SurveyRow,
} from 'components/AssetsBrowser/types';
import { smartSort } from '@ardoq/pagination';
import { findMatchGroups } from '@ardoq/common-helpers';
import { Locale } from '@ardoq/locale';
import { logError } from '@ardoq/logging';
import { SendRequestAccessBody } from '@ardoq/api';

export const includesAll = selectAllUtils.includesAll;
export const includesAny = selectAllUtils.includesAny;

type ById<T> = { [key: string]: T };
type WithMeta<T> = T & { meta: { [key: string]: any } };
type AssetStream =
  | StreamWorkspace
  | APIPresentationAssetAttributes
  | StreamFolder;
type GroupedFolders = ById<StreamFolder[]>;

const createByIdHash = (list: any[]) =>
  Object.fromEntries(list.map(item => [item._id, item]));

const groupObjects = (
  list: any[],
  pathOfPropToGroupBy: string,
  mapFn = (x: any) => x
) => {
  const itemsById = createByIdHash(list);
  return Object.values<AssetStreamOrAsset>(itemsById).reduce(
    (acc: any, item: any) => {
      const mapId = get(item, pathOfPropToGroupBy) || 'ROOT';
      const mappedItem = mapFn(item);
      acc[mapId] = acc[mapId] ? [...acc[mapId], mappedItem] : [mappedItem];
      return acc;
    },
    { ROOT: [] }
  );
};

const createFolderTree = (
  currentFolder: WithMeta<StreamFolder>,
  foldersHash: GroupedFolders,
  rowGroups: ById<AssetStreamOrAsset[]>[]
) => {
  const currentFolderId = currentFolder._id;
  const subfolders = foldersHash[currentFolderId] || [];
  const subRowGroups = rowGroups.flatMap(
    rowGroup => rowGroup[currentFolderId] || []
  );
  const subfoldersWithTree: StreamFolder[] = subfolders.map(subfolder =>
    createFolderTree(subfolder, foldersHash, rowGroups)
  );
  const children = [...subfoldersWithTree, ...subRowGroups.flat()];

  return {
    ...currentFolder,
    children: children.length
      ? children
      : [
          {
            rowType: RowType.EMPTY_FOLDER,
            meta: {
              folderId: currentFolder._id,
              path: currentFolder.meta.path,
              level: currentFolder.meta.level,
            },
            contentByType: currentFolder.contentByType,
          },
        ],
  };
};

export const getRowTypeByAssetType = (
  assetType: AssetType,
  assetId: ArdoqId
): RowType => {
  if (assetType === AssetType.FOLDER) return RowType.FOLDER;
  if (assetType === AssetType.WORKSPACE) {
    return workspaceInterface.isExternallyManaged(assetId)
      ? RowType.MANAGED_WORKSPACE
      : RowType.WORKSPACE;
  }
  if (assetType === AssetType.PRESENTATION) return RowType.PRESENTATION;
  if (assetType === AssetType.METAMODEL) return RowType.METAMODEL;
  if (assetType === AssetType.SURVEY) return RowType.SURVEY;
  if (assetType === AssetType.SCENARIO) return RowType.SCENARIO;
  if (assetType === AssetType.REPORT) return RowType.REPORT;
  if (assetType === AssetType.DASHBOARD) return RowType.DASHBOARD;
  if (assetType === AssetType.TRAVERSAL) return RowType.TRAVERSAL;
  if (assetType === AssetType.BOOKMARK) return RowType.BOOKMARK;
  if (assetType === AssetType.VIEWPOINT) return RowType.VIEWPOINT;
  if (assetType === AssetType.BROADCAST) return RowType.BROADCAST;

  return RowType.EMPTY_FOLDER;
};

type AssetStreamOrAsset = AssetStream | Asset;

const assetGroupToRowGroup = <T = AssetStreamOrAsset>(
  assetGroup: T[]
): ById<WithMeta<T>[]> =>
  groupObjects(assetGroup, 'meta.folderId', asset => {
    return {
      ...asset,
      rowType: getRowTypeByAssetType(asset.meta.assetType, asset._id),
      meta: asset.meta || {},
    };
  });

const assetsToRowGroup = <T = AssetStreamOrAsset>(
  assets: T[]
): ById<WithMeta<T>[]> =>
  groupObjects(assets, 'meta.folderId', asset => {
    return {
      ...asset,
      rowType: getRowTypeByAssetType(asset.meta.assetType, asset._id),
      meta: asset.meta || {},
    };
  });

export const combineFolders = (
  folders: StreamFolder[],
  assetsGroups: AssetStreamOrAsset[][]
) => {
  const rowGroups = assetsGroups.map(assetGroupToRowGroup);
  const groupedFolders = assetGroupToRowGroup<StreamFolder>(folders);
  const rootfoldersWithTree = (groupedFolders.ROOT || []).map(subfolder =>
    createFolderTree(subfolder, groupedFolders, rowGroups)
  );

  return [
    ...rootfoldersWithTree,
    ...rowGroups.flatMap(rowGroup => rowGroup.ROOT || []),
  ] as unknown as Row<AssetRow>[];
};

const removeMatchGroups = <T extends GenericRow>(dataSource: T[]) =>
  dataSource.map(row =>
    row?.meta?.matchGroups
      ? { ...row, meta: { ...row.meta, matchGroups: undefined } }
      : row
  );

export const combineFoldersFromAssetArray = (
  folders: StreamFolder[],
  assets: Asset[]
) => {
  const rowGroups = assetsToRowGroup(assets);
  const groupedFolders = assetGroupToRowGroup<StreamFolder>(folders);
  const rootfoldersWithTree = (groupedFolders.ROOT || []).map(subfolder =>
    createFolderTree(subfolder, groupedFolders, [rowGroups])
  );

  const rootAssets = rowGroups.ROOT || [];
  return [...rootfoldersWithTree, ...rootAssets] as Row<AssetRow>[];
};

const addDontExpandOnSearch = (row: GenericRow): GenericRow => {
  if (row.rowType === RowType.FOLDER) {
    return {
      ...row,
      meta: { ...row.meta, dontExpandOnSearch: true },
    };
  }
  return row;
};

const mergeMatchedAndUnmatchedChildren = (
  matched: GenericRow[],
  unmatched: GenericRow[]
): GenericRow[] => {
  const matchedIds = matched.map(row => row._id);
  return [
    ...matched,
    ...removeMatchGroups(unmatched)
      .filter(row => !matchedIds.includes(row._id))
      .map(addDontExpandOnSearch),
  ];
};

const applyMatchGroups = <T extends GenericRow>(
  dataSource: T[],
  searchPhrase: string,
  locale: Locale
) =>
  dataSource.reduce((acc: T[], row) => {
    if (!row.name) return acc;
    const matchGroups = findMatchGroups(row.name, searchPhrase, locale);
    const matchedChildren = applyMatchGroups(
      row.children ?? [],
      searchPhrase,
      locale
    );
    const rowWithMatchGroups = {
      ...row,
      meta: { ...row.meta, matchGroups },
    };

    if (matchGroups.match) {
      if (row.rowType === RowType.FOLDER) {
        rowWithMatchGroups.children = mergeMatchedAndUnmatchedChildren(
          matchedChildren,
          row.children || []
        );
      }
      acc.push(rowWithMatchGroups);
    } else if (matchedChildren.length) {
      rowWithMatchGroups.children = matchedChildren;
      acc.push(rowWithMatchGroups);
    }

    return acc;
  }, []);

export const searchDatasourceByName = <T extends GenericRow>(
  dataSource: T[],
  searchPhrase: string,
  locale: Locale
) => {
  if (!searchPhrase) return removeMatchGroups(dataSource);

  return applyMatchGroups(dataSource, searchPhrase, locale);
};

const getNextSortOrder = (
  sortOrder: SortOrder,
  skipDefaultSortOrder?: boolean
): SortOrder => {
  if (sortOrder === SortOrder.DEFAULT) return SortOrder.ASC;
  if (sortOrder === SortOrder.ASC) return SortOrder.DESC;
  // if DESC
  return skipDefaultSortOrder ? SortOrder.ASC : SortOrder.DEFAULT;
};

type UseSortingProps = {
  sortOrder?: SortOrder;
  sortByField?: string | null;
  skipDefaultSortOrder?: boolean;
  onSortChange?: (sortOrder: SortOrder, sortByField: string | null) => void;
};

export const useSorting = (
  props: UseSortingProps = {}
): [SortOrder, string | null, (sortByField: string) => void] => {
  const onSortChange = props.onSortChange || (() => {});
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    props.sortOrder || SortOrder.DEFAULT
  );
  const [sortByField, setSortByField] = useState<string | null>(
    props.sortByField || null
  );

  const setSortByFieldFn = (newSortByField: string) => {
    if (sortByField === newSortByField) {
      const newSortOrder = getNextSortOrder(
        sortOrder,
        props.skipDefaultSortOrder
      );
      setSortOrder(newSortOrder);
      if (newSortOrder === SortOrder.DEFAULT) setSortByField(null);
      onSortChange(
        newSortOrder,
        newSortOrder === SortOrder.DEFAULT ? null : newSortByField
      );
    } else {
      setSortOrder(SortOrder.ASC);
      setSortByField(newSortByField);
      onSortChange(SortOrder.ASC, newSortByField);
    }
  };

  return [sortOrder, sortByField, setSortByFieldFn];
};

const toggle = (item: string, source: string[]) =>
  source.includes(item) ? without(source, item) : [...source, item];

const isAsset = (row: Row<any>): row is AssetRow =>
  [
    RowType.WORKSPACE,
    RowType.TRAVERSAL,
    RowType.MANAGED_WORKSPACE,
    RowType.PRESENTATION,
    RowType.METAMODEL,
    RowType.SURVEY,
    RowType.SCENARIO,
    RowType.REPORT,
    RowType.DASHBOARD,
    RowType.DOCUMENT,
    RowType.IMAGE,
    RowType.UNKOWN_FILE,
    RowType.TRAVERSAL,
    RowType.VIEWPOINT,
    RowType.BROADCAST,
    RowType.BOOKMARK,
  ].includes(row.rowType);

export const isAssetFolder = <T extends RowItem>(
  row: Row<T>
): row is Folder<T> =>
  row.rowType === RowType.FOLDER && row.meta.assetType === AssetType.FOLDER;

export const isFolder = <T extends RowItem>(
  row: Row<T>
): row is T & DatasourceItem<RowType.FOLDER> => row.rowType === RowType.FOLDER;

export const isNotFolder = <T extends RowItem>(row: Row<T>): row is T =>
  ![RowType.FOLDER, RowType.EMPTY_FOLDER].includes(row.rowType);

export const extractAssetsIdsList = (dataSource: any[]): string[] =>
  dataSource.reduce((acc: string[], item) => {
    if (isAsset(item)) {
      acc.push(item._id);
    } else if (isFolder(item)) {
      return [...acc, ...extractAssetsIdsList(item.children || [])];
    }
    return acc;
  }, []);

const getAssetIdsFromAssetFolder = (folder: Folder<RowItem>): string[] => {
  if (!folder.nestedContent) {
    logError(Error('Missing nestedContent in getAssetIdsFromAssetFolder'));
    return [];
  }
  return folder.nestedContent
    .filter(item => item.type !== AssetType.FOLDER)
    .map(asset => asset._id);
};

export const getSelectRowsFn =
  (
    setSelected: (selected: string[]) => void,
    selected: string[],
    dataSource: any[]
  ) =>
  (row: RowItem | Folder<RowItem>, rangeSelect = false) => {
    if (rangeSelect) {
      const dataSourceIds = extractAssetsIdsList(dataSource);
      const rowIds = extractAssetsIdsList([row]);
      const rangeIds = rowIds.flatMap(id =>
        findRangeSelectIds(id, selected, dataSourceIds)
      );
      setSelected(uniq(rangeIds));
    } else if (isAsset(row)) {
      setSelected(toggle(row._id, selected));
    } else if (isFolder(row)) {
      const assetsIds = extractAssetsIdsList([row]);
      const updatedSelectedAssets = includesAny(selected, assetsIds)
        ? without(selected, ...assetsIds)
        : uniq([...selected, ...assetsIds]);
      setSelected(updatedSelectedAssets);
    }
  };

const extractFoldersAndAssets = (dataSource: any[]) =>
  dataSource.reduce(
    ([folders, assets]: any[], dataSourceRow: any) => {
      if (isFolder(dataSourceRow)) {
        folders.push(dataSourceRow);
      } else {
        assets.push(dataSourceRow);
      }

      return [folders, assets];
    },
    [[], []]
  );

const sortFolders = (
  folders: Folder<RowItem>[],
  sortByField: string | null,
  sortOrder: SortOrder
) =>
  smartSort(folders, sortByField, sortOrder).map(dataSourceRow => {
    if (dataSourceRow.children)
      return {
        ...dataSourceRow,
        children: sortDataSource(
          dataSourceRow.children,
          sortByField,
          sortOrder
        ),
      };
    return dataSourceRow;
  });

export const sortDataSource = (
  dataSource: any[],
  sortByField: string | null,
  sortOrder: SortOrder
): any[] => {
  // prevent trimming empty folders rows
  if (
    dataSource.length === 1 &&
    dataSource[0].rowType === RowType.EMPTY_FOLDER
  ) {
    return dataSource;
  }
  const [folders, assets] = extractFoldersAndAssets(dataSource);

  return [
    ...sortFolders(folders, sortByField, sortOrder),
    ...smartSort(assets, sortByField, sortOrder),
  ];
};

export const findIsSelected = (
  row: RowItem | Folder<RowItem>,
  selectedAssets: string[]
): Selected => {
  if (isAsset(row)) {
    return selectedAssets.includes(row._id)
      ? Selected.SELECTED
      : Selected.NOT_SELECTED;
  }

  if (isFolder(row)) {
    const assetIds = isAssetFolder(row)
      ? getAssetIdsFromAssetFolder(row)
      : extractAssetsIdsList([row]);
    if (includesAll(selectedAssets, assetIds)) return Selected.SELECTED;
    if (includesAny(selectedAssets, assetIds)) return Selected.MIXED;
    return Selected.NOT_SELECTED;
  }
  return Selected.NOT_SELECTED;
};

export const getSelectableIdsFromDataSource = (dataSource: any[]): string[] => {
  return dataSource.flatMap(dataSourceRow => {
    if (isAsset(dataSourceRow)) {
      return dataSourceRow._id;
    } else if (isFolder(dataSourceRow)) {
      return getSelectableIdsFromDataSource(dataSourceRow.children || []);
    }
    return [];
  });
};

export const getRequestSurveyWorkspaceAccessBody = (
  survey: SurveyRow,
  workspaceId: ArdoqId,
  user: { name?: string | null; email?: string | null }
): SendRequestAccessBody => ({
  explanation: `${user.name ?? 'The user'} ${user.email ? `(${user.email})` : ''} administers "${survey.name}" but lacks admin access for the selected workspace.`,
  requests: [
    {
      resourceId: workspaceId,
      resourceType: ResourceType.WORKSPACE,
      access: { permission: PermissionAccessLevel.ADMIN },
    },
  ],
});
