import { hasPrivilege } from '@ardoq/privileges';
import {
  Permissions,
  hasPermission,
} from 'streams/currentUserPermissions/permissionInterface';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import {
  APIDiscoverViewpointAttributes,
  ArdoqId,
  PrivilegeLabel,
} from '@ardoq/api-types';
import { viewpointInterface } from 'modelInterface/viewpoints/viewpointInterface';
import { currentUserAccessControlInterface } from './currentUser';

const canCreateViewpoint = (): boolean =>
  hasPrivilege(PrivilegeLabel.CREATE_VIEWPOINT) &&
  hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER);

const canSetDefaults = (): boolean =>
  currentUserInterface.currentUserIsOrgAdmin();

const canAdminViewpoint = (viewpointId?: ArdoqId): boolean =>
  currentUserAccessControlInterface.canCurrentUserWrite() &&
  viewpointInterface.viewpointExists(viewpointId) &&
  (hasPrivilege(PrivilegeLabel.GLOBAL_DATA_ACCESS) ||
    hasPermission(Permissions.VIEWPOINT_ADMIN, viewpointId));

const canEditViewpoint = (viewpointId?: ArdoqId): boolean =>
  currentUserAccessControlInterface.canCurrentUserWrite() &&
  viewpointInterface.viewpointExists(viewpointId) &&
  (hasPrivilege(PrivilegeLabel.GLOBAL_DATA_ACCESS) ||
    hasPermission(Permissions.VIEWPOINT_EDIT, viewpointId) ||
    canAdminViewpoint(viewpointId));

const canReadViewpoint = (viewpointId?: ArdoqId) =>
  viewpointInterface.viewpointExists(viewpointId) &&
  (hasPrivilege(PrivilegeLabel.GLOBAL_DATA_ACCESS) ||
    hasPermission(Permissions.VIEWPOINT_READ, viewpointId) ||
    canEditViewpoint(viewpointId));

const canCopyViewpoint = (): boolean =>
  canCreateViewpoint() && hasPrivilege(PrivilegeLabel.GLOBAL_DATA_ACCESS);

const getDiscoverViewpointOverViewHidden = (
  viewpoints?: APIDiscoverViewpointAttributes[] | null
) => {
  const hasViewpoints = viewpoints && viewpoints.length;
  const hasSomeViewpointEditAccess =
    hasViewpoints &&
    viewpoints.some(viewpoint => canEditViewpoint(viewpoint._id));

  const isCanCreateViewpoint = canCreateViewpoint();
  return !isCanCreateViewpoint && !hasSomeViewpointEditAccess;
};

export const viewpointAccessControlInterface = {
  canCreateViewpoint,
  canSetDefaults,
  canAdminViewpoint,
  canEditViewpoint,
  canReadViewpoint,
  canCopyViewpoint,
  getDiscoverViewpointOverViewHidden,
};
