import { TopBarButtonWithMenu, TopBarButtonWithMenuProps } from '../atoms';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { ArdoqId } from '@ardoq/api-types';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { pluralize } from '@ardoq/common-helpers';
import {
  trackClickStartAddToScenario,
  trackClickStartCreatingNewScenario,
} from 'scope/tracking';
import {
  ComponentWithDropdown,
  ComponentWithDropdownComponentProps,
} from '@ardoq/dropdown-menu';
import { IconName } from '@ardoq/icons';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';
import { PermissionContext } from '@ardoq/access-control';
import { contextInterface } from 'modelInterface/contextInterface';
import { dispatchAction } from '@ardoq/rxbeach';
import { openAddToScenarioDialog } from 'scope/actions';

type ScenarioMenuProps = {
  selectedComponentIds: ArdoqId[];
  viewComponentIds: ArdoqId[];
  loadedGraph?: LoadedGraphWithViewpointMode;
  permissionContext: PermissionContext;
};

export const getScenarioDropdownOptions = ({
  viewComponentIds,
  selectedComponentIds,
  loadedGraph,
  permissionContext,
}: ScenarioMenuProps) => {
  const createFromViewEnabled =
    !getActiveScenarioId() && viewComponentIds.length > 0;
  const createFromSelectionEnabled =
    !getActiveScenarioId() && selectedComponentIds.length > 0;
  return [
    ...(scenarioAccessControlInterface.canCreateScenario(permissionContext)
      ? [
          {
            label: `Create scenario from view ${
              createFromViewEnabled
                ? `(${viewComponentIds.length} ${pluralize(
                    'component',
                    viewComponentIds.length
                  )})`
                : ''
            }`,
            isDisabled: !createFromViewEnabled,
            onClick: () => {
              dispatchAction(
                openAddToScenarioDialog({
                  context: contextInterface.getCurrentContext(),
                  componentIds: viewComponentIds,
                  loadedGraph,
                })
              );
              trackClickStartCreatingNewScenario('topbarView');
            },
          },
          {
            label: `Create scenario from selection ${
              createFromSelectionEnabled
                ? `(${selectedComponentIds.length} ${pluralize(
                    'component',
                    selectedComponentIds.length
                  )})`
                : ''
            }`,
            isDisabled: !createFromSelectionEnabled,
            onClick: () => {
              dispatchAction(
                openAddToScenarioDialog({
                  context: contextInterface.getCurrentContext(),
                  componentIds: selectedComponentIds,
                  loadedGraph,
                })
              );
              trackClickStartCreatingNewScenario('topbarSelection');
            },
          },
        ]
      : []),
    {
      label: `Add selection to scenario ${
        createFromSelectionEnabled
          ? `(${selectedComponentIds.length} ${pluralize(
              'component',
              selectedComponentIds.length
            )})`
          : ''
      }`,
      isDisabled: !createFromSelectionEnabled,
      onClick: () => {
        dispatchAction(
          openAddToScenarioDialog({
            context: contextInterface.getCurrentContext(),
            componentIds: selectedComponentIds,
            addToExistingScenario: true,
            loadedGraph,
          })
        );
        trackClickStartAddToScenario('topbarSelection');
      },
    },
  ].filter(ExcludeFalsy);
};

const NavigatorTopBarScenarioMenu = (props: ScenarioMenuProps) => {
  const componentProps: ComponentWithDropdownComponentProps<TopBarButtonWithMenuProps> =
    {
      tooltip: 'Scenario',
      iconName: IconName.SCENARIO,
    };
  return (
    <ComponentWithDropdown
      Component={TopBarButtonWithMenu}
      componentProps={componentProps}
      options={getScenarioDropdownOptions(props)}
    />
  );
};

export default NavigatorTopBarScenarioMenu;
