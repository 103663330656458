import { StepContainer } from '../components/StepContainer';
import { SummaryMatrix } from '@ardoq/integrations';
import { PrimaryButton, SecondaryButton } from '@ardoq/button';
import { viewModel$ } from './viewModel$';
import { connect } from '@ardoq/rxbeach';
import { ConfigurationState } from 'integrations/signavio-exporter/streams/types';
import { DoqWithSpeechBubble } from '@ardoq/doq';
import { diffToSummary } from './utils';
import { Island, IslandFooter } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';

const columns = {
  title: 'Assets',
  otherColumns: ['To create', 'To update'],
};

type ReviewImportComponentProps = {
  diff: ConfigurationState['results']['diff'];
  onNext: () => void;
  onBack: () => void;
};

function ReviewImportComponent({
  diff,
  onNext,
  onBack,
}: ReviewImportComponentProps) {
  return (
    <StepContainer $isVertical>
      {diff.status === 'FAILURE' && (
        <Island>
          <DoqWithSpeechBubble
            buttonLabel="Contact us"
            doqType={3}
            message={diff.message}
            title="Something went wrong"
          />
        </Island>
      )}
      {diff.status === 'SUCCESS' && (
        <SummaryMatrix
          columns={columns}
          data={diffToSummary(diff.data)}
          footer={
            <IslandFooter $justify="end">
              <FlexBox gap="medium">
                <SecondaryButton onClick={onBack}>Back</SecondaryButton>
                <PrimaryButton onClick={onNext}>
                  Next: Export and schedule
                </PrimaryButton>
              </FlexBox>
            </IslandFooter>
          }
        />
      )}
    </StepContainer>
  );
}

export const ReviewExport = connect(ReviewImportComponent, viewModel$);
