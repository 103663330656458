import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { debounceTime, tap, withLatestFrom } from 'rxjs/operators';
import { componentSearch$ } from './componentSearch$';
import { prototypeInterface } from 'modelInterface/prototype/prototypeInterface';
import {
  applyFiltersForContextSwitcher,
  editComponentSearch,
  openComponents,
  removeUnselectedLoadedComponents,
  saveEditedLoadedStateSearch,
  setComponentName,
  setComponentTypeName,
  setHasSearchResults,
  setIsLoading,
  setLoadedComponents,
} from './actions';
import { loadedState$ } from 'loadedState/loadedState$';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import {
  buildSearchState,
  isError,
  TraversalErrorReason,
} from 'loadedState/buildState';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { loadedStateUpdated, registerLoadedState } from 'loadedState/actions';
import { logError } from '@ardoq/logging';
import { Features, hasFeature } from '@ardoq/features';
import { openViewpointBuilder } from 'viewpointBuilder/openViewpointBuilder/openViewpointBuilder';
import { setActiveMainTabLeft } from 'streams/views/mainContent/actions';
import {
  isManualComponentSelection,
  isQueryBuilderRule,
  isSearchLoadedState,
  LoadedStateType,
  QueryBuilderQuery,
  QueryBuilderRule,
  QueryBuilderSubquery,
} from '@ardoq/api-types';
import currentViewId$ from 'streams/currentViewId$';
import { getSupportedTraversalViewIdOrDefault } from 'traversals/getViewpointModeSupportedViews';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { ComponentSearchState } from './types';
import { componentSearchInterface } from './componentSearchInterface';
import { enterViewpointMode } from 'streams/traversals/actions';
import { resetAllSearchState } from '../../search/actions';
import { scopeDataLoaded } from '../../traversals/stagedLoadedDataAndState$';
import {
  setIsLoadingWithConfig,
  setIsLoading as setLOaderIsLoading,
} from 'components/GlobalPaneLoader/globalPaneLoader$';
import { consolidateOperatorsForComponentSelection } from '../consolidateLegacyFilterOperators';

const handleTraversalSearch = routine(
  ofType(
    setComponentName,
    setComponentTypeName,
    resetAllSearchState,
    applyFiltersForContextSwitcher
  ),
  debounceTime(300),
  withLatestFrom(componentSearch$),
  tap(async ([, componentSearchState]) => {
    if (
      componentSearchState.componentName === '' &&
      componentSearchState.componentType === ''
    ) {
      // when user clears inputs, we want to keep displaying only selected components
      dispatchAction(removeUnselectedLoadedComponents());
      dispatchAction(setHasSearchResults(false));
      return;
    }
    dispatchAction(setIsLoading(true));
    const result = await loadComponents(componentSearchState);
    if (isArdoqError(result)) {
      logError(result);
      return;
    }
    dispatchAction(setLoadedComponents(result));
    dispatchAction(setIsLoading(false));
    dispatchAction(setHasSearchResults(true));
  })
);

const loadComponents = async ({
  componentName,
  componentType,
  startSetFilter,
}: ComponentSearchState) => {
  const result = await prototypeInterface.loadComponentsFromAdvancedSearch(
    componentName,
    componentType,
    startSetFilter
  );
  if (isArdoqError(result)) {
    return result;
  }
  const { results: loadedComponents, totalCount } = result;

  return {
    loadedComponents,
    totalCount,
  };
};

const extractRules = (query: QueryBuilderSubquery): QueryBuilderRule[] =>
  query.rules
    .flatMap(ruleOrQuery =>
      isQueryBuilderRule(ruleOrQuery) ? ruleOrQuery : extractRules(ruleOrQuery)
    )
    .filter(isQueryBuilderRule);

// This is a temporary solution to get the start type and search term from the query
// of the block to be edited
// TODO CL this needs fixing when AdvancedSearch is added to the viewpoint builder
export const getStartTypeAndTermFromQuery = (query: QueryBuilderQuery) => {
  const queryRules = extractRules(query);
  const startType = queryRules.find(rule => rule.field === 'typeName');
  const startSearchTerm = queryRules.find(
    rule => rule.field === 'name' && rule.operator === 'contains_substring'
  );
  return {
    type: startType?.value?.toString(),
    term: startSearchTerm?.value?.toString(),
  };
};

const handleEditComponentSearchBlock = routine(
  ofType(editComponentSearch),
  extractPayload(),
  withLatestFrom(loadedState$),
  tap(([loadedStateHash, loadedState]) => {
    const editedLoadedState = loadedStateOperations.getLoadedStateWithHash(
      loadedState,
      loadedStateHash
    );
    if (
      !editedLoadedState ||
      !isSearchLoadedState(editedLoadedState) ||
      !hasFeature(Features.SUPPORT_LARGE_DATASETS)
    ) {
      return;
    }

    if (isManualComponentSelection(editedLoadedState.data.componentSelection)) {
      const blockComponents =
        editedLoadedState.data.componentSelection.startSet;
      const loadedComponents = blockComponents
        .map(componentSearchInterface.buildSearchDataFromComponentId)
        .filter(ExcludeFalsy);

      return openViewpointBuilder({
        activeTab: 'SELECT_CONTEXT_COMPONENT_INSTANCES_TAB',
        context: 'editSearch',
        initialConfiguration: {
          loadedComponents,
          componentSelection: editedLoadedState.data.componentSelection,
          loadedStateHash,
        },
      });
    }

    const startQuery = editedLoadedState.data.componentSelection.startQuery;
    const { type, term } = getStartTypeAndTermFromQuery(startQuery);
    const startType = type;
    const startSearchTerm = term;

    return openViewpointBuilder({
      activeTab: 'SELECT_CONTEXT_COMPONENT_INSTANCES_TAB',
      context: 'editSearch',
      initialConfiguration: {
        loadedStateHash,
        startType,
        startSearchTerm,
        componentSelection: consolidateOperatorsForComponentSelection(
          editedLoadedState.data.componentSelection
        ),
      },
    });
  })
);

const openSelectedComponents = routine(
  ofType(openComponents),
  extractPayload(),
  withLatestFrom(currentViewId$, isViewpointMode$),
  tap(
    async ([
      { componentSelection, viewId: payloadViewId },
      currentViewId,
      { isViewpointMode },
    ]) => {
      dispatchAction(
        setIsLoadingWithConfig({
          title: 'Loading components...',
        })
      );
      const searchParams =
        loadedStateOperations.searchParamsFromSelection(componentSelection);
      const loadedGraphOrError = await buildSearchState(searchParams);

      if (isError(loadedGraphOrError)) {
        if (loadedGraphOrError.reason !== TraversalErrorReason.USER_CANCELLED) {
          logError(
            new Error('Failed to open components'),
            loadedGraphOrError.error
          );
        }
        dispatchAction(setLOaderIsLoading(false));
        return;
      }

      dispatchAction(
        scopeDataLoaded({
          trackingLocation: 'scopifiedSearch',
          scopeData: loadedGraphOrError.scopeData,
        })
      );
      const activeTabId = getSupportedTraversalViewIdOrDefault(
        payloadViewId
          ? payloadViewId // payloadViewId is used when opening components directly from the inventory to get to a specific view
          : isViewpointMode
            ? // to prevent changing the View when adding datasets
              currentViewId
            : undefined
      );

      dispatchAction(enterViewpointMode());
      dispatchAction(
        setActiveMainTabLeft({
          activeTabId,
        })
      );

      dispatchAction(
        requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
      );
      dispatchAction(
        registerLoadedState([
          {
            type: LoadedStateType.SEARCH,
            isHidden: false,
            componentIdsAndReferences:
              loadedGraphOrError.componentIdsAndReferences,
            scopeData: loadedGraphOrError.scopeData,
            data: {
              componentSelection,
            },
          },
        ])
      );
      dispatchAction(setLOaderIsLoading(false));
    }
  )
);

const handleUpdateComponentSearchBlock = routine(
  ofType(saveEditedLoadedStateSearch),
  extractPayload(),
  tap(async ({ loadedStateHash, componentSelection }) => {
    const searchParams =
      loadedStateOperations.searchParamsFromSelection(componentSelection);
    const loadedGraphOrError = await buildSearchState(searchParams);
    if (isError(loadedGraphOrError)) {
      if (loadedGraphOrError.reason !== TraversalErrorReason.USER_CANCELLED) {
        logError(
          new Error('Failed to open components'),
          loadedGraphOrError.error
        );
      }
      return;
    }
    dispatchAction(
      scopeDataLoaded({
        trackingLocation: 'viewpointNavigator',
        scopeData: loadedGraphOrError.scopeData,
      })
    );
    dispatchAction(enterViewpointMode());

    const loadedState = loadedStateOperations.attachLoadedGraph(
      searchParams,
      loadedGraphOrError
    );

    dispatchAction(
      loadedStateUpdated({ loadedState, existingHash: loadedStateHash })
    );
  })
);

export const componentSearchRoutines = collectRoutines(
  handleTraversalSearch,
  handleEditComponentSearchBlock,
  openSelectedComponents,
  handleUpdateComponentSearchBlock
);
