import { colors, r16, s8, s16 } from '@ardoq/design-tokens';
import { Space } from '@ardoq/style-helpers';
import { text2, header4, header3, text1 } from '@ardoq/typography';
import styled from 'styled-components';

import { dispatchAction } from '@ardoq/rxbeach';
import { trackITPediaEvent } from 'integrations/ITPedia/streams/tracking/actions';
import { WithPopover } from '@ardoq/popovers';
import { InfoIcon } from '@ardoq/icons';
import { CreatableSelectProps } from '@ardoq/select';
import { CollapsibleIsland } from '@ardoq/page-layout';
import { FlexBox, Stack } from '@ardoq/layout';

type LifecycleFormParams = {
  children: [
    React.ReactElement<CreatableSelectProps<string>>,
    React.ReactElement<CreatableSelectProps<string>>,
    React.ReactElement<CreatableSelectProps<string>>,
  ];
};

export function LifecycleForm({
  children: [LiveSelector, SupportSelector, ExtendedSupportSelector],
}: LifecycleFormParams) {
  return (
    <>
      <Header3>2. Map lifecycle fields</Header3>
      <CollapsibleIsland
        subtitleWidth="medium"
        title="Mapping fields: best practices"
        isDefaultExpanded
        onToggle={opened =>
          dispatchAction(
            trackITPediaEvent({
              name: 'TOGGLED',
              metadata: {
                action: opened ? 'opened' : 'closed',
                title: 'Mapping fields: best practices',
              },
            })
          )
        }
      >
        <FlexBox gap="medium">
          <Stack flex={1}>
            <Text1>
              Some lifecycle data from IT-Pedia can be used to map only one type
              of date range fields in Ardoq.
            </Text1>
            <Text1>
              Other lifecycle data from IT-Pedia can be used to map several date
              range fields in Ardoq. For example, IT-Pedia&apos;s Start of life
              data can be used to map the Start date of the &apos;Live&apos; and
              &apos;Support&apos; date range fields in Ardoq.
            </Text1>
            <Text1>
              Therefore, if for example Start of life lifecycle data is missing,
              we can use Release date information to map the correct date range
              field in Ardoq.
            </Text1>
          </Stack>
          <FlexBox flex={2} justify="center" gap="medium">
            <FieldMappingVisualsImg />
          </FlexBox>
        </FlexBox>
      </CollapsibleIsland>
      <FormContainer>
        <div>&nbsp;</div>
        <Header4>IT-Pedia lifecycle</Header4>
        <div>&nbsp;</div>
        <FlexBox align="center" gap="medium">
          <Header4>Ardoq fields</Header4>
          <WithPopover content="Date range field:  Date range fields are a special field type in Ardoq, which are presented as a distinct field, but at times must be handled as two Date time fields">
            <InfoIcon color={colors.grey50} />
          </WithPopover>
        </FlexBox>
        <GridDivider />
        <Text1>Map</Text1>
        <DateRangeLabel
          title="Live dates"
          startText="Start date: Start of life/ release date"
          endText="End date: End of sale"
        />
        <Text1>to</Text1>
        {LiveSelector}
        <Text1>Map</Text1>
        <DateRangeLabel
          title="Support dates "
          startText="Start date: Start of life/ release date"
          endText="End date: End of support"
        />
        <Text1>to</Text1>
        <FormField>{SupportSelector}</FormField>
        <Text1>Map</Text1>
        <DateRangeLabel
          title="Extended support dates"
          startText="Start date: End of support"
          endText="End date: End of extended support"
        />
        <Text1>to</Text1>
        {ExtendedSupportSelector}
      </FormContainer>
    </>
  );
}

const DateRangeLabel = ({
  title,
  startText,
  endText,
}: {
  title: string;
  startText: string;
  endText: string;
}) => (
  <DateRangeLabelContainer>
    <Header4>{title}</Header4>
    <Text2>{startText}</Text2>
    <Text2>{endText}</Text2>
  </DateRangeLabelContainer>
);
const DateRangeLabelContainer = styled(Space).attrs({
  $isVertical: true,
  $gap: 'none',
})`
  background-color: ${colors.grey95};
  border-radius: ${r16};
  width: 300px;
  padding: ${s8} ${s16};
`;
const FormContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 300px min-content 300px;
  text-overflow: nowrap;
  align-items: center;
  gap: ${s16};
`;

const Text2 = styled.div`
  ${text2};
`;

const Text1 = styled.div`
  ${text1};
`;
const Header4 = styled.p<{ $bottomMargin?: boolean }>`
  ${header4};
  ${({ $bottomMargin }) => (!$bottomMargin ? 'margin-bottom: 0px' : '')}
`;

const Header3 = styled.p<{ $bottomMargin?: boolean }>`
  ${header3};
  ${({ $bottomMargin }) => (!$bottomMargin ? 'margin-bottom: 0px' : '')}
`;

const FormField = styled(Space).attrs({ $isVertical: true, $gap: 's4' })`
  width: 300px;
`;

// This might looks like a bad thing but it's the cleanest way to align with the design
const FieldMappingVisualsImg = styled.img.attrs({
  src: '/img/it-pedia/field_mapping_visuals.png',
})`
  max-width: 590px;
  width: 100%;
`;

const GridDivider = styled.hr`
  grid-column: span 4;
  width: 100%;
  border: 0;
  border-top: 1px solid ${colors.borderSubtle00};
  margin: 0;
`;
