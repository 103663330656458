import { Checkbox } from '@ardoq/forms';
import {
  Icon,
  IconName,
  IconSize,
  VisibilityIcon,
  VisibilityOffIcon,
} from '@ardoq/icons';
import { Label, ListItem, IconWrapper, CountWrapper } from './atoms';
import { ComponentType, ComponentTypeProps } from './ComponentType';
import { TripleOptionsProps } from './TripleOptionsList';
import { TextOverflow, WithPopover } from '@ardoq/popovers';
import { TripleOptions } from 'viewpointBuilder/types';
import { TogglerStyle } from './TriplesToggler';
import { colors } from '@ardoq/design-tokens';
import { GhostButton } from '@ardoq/button';
import { Tag } from '@ardoq/status-ui';
import { FlexBox } from '@ardoq/layout';

type OptionProps = Omit<TripleOptions['outgoing'][0], 'countKey'> & {
  // TODO (chriskr): investigate non-nullable here
  metaDataComponentSelected: TripleOptions['selectedNode']['metaDataComponent'];
  direction: TripleOptionsProps['direction'];
  toggleOption: TripleOptionsProps['toggleOption'];
  togglerStyle: TogglerStyle;
  isDisabledWholeRow: boolean;
};

type OptionTogglerProps = {
  togglingDisabledExplanation?: string;
  isSelected: boolean;
  isDisabled: boolean;
  togglerStyle: TogglerStyle;
  onToggle: () => void;
};

const OptionToggler = ({
  isSelected,
  isDisabled,
  togglerStyle,
  onToggle,
  togglingDisabledExplanation,
}: OptionTogglerProps) => {
  switch (togglerStyle) {
    case 'visibility':
      return (
        <WithPopover content={togglingDisabledExplanation}>
          <span>
            <GhostButton
              isDisabled={isDisabled}
              onClick={event => {
                event.stopPropagation();
                onToggle();
              }}
            >
              {isSelected ? (
                <VisibilityIcon color={colors.iconActionBrand} />
              ) : (
                <VisibilityOffIcon color={colors.iconOnInverted} />
              )}
            </GhostButton>
          </span>
        </WithPopover>
      );

    case 'checkbox':
      return (
        <WithPopover content={togglingDisabledExplanation}>
          <span>
            <Checkbox
              isChecked={isSelected}
              isDisabled={isDisabled}
              onChange={onToggle}
              stopPropagation={true}
            />
          </span>
        </WithPopover>
      );

    default:
      togglerStyle satisfies never;
      return null;
  }
};

export const OutgoingTripleOption = ({
  tripleId,
  metaDataComponent,
  metaDataReference,
  isSelected,
  isDisabled,
  direction,
  namedDirectedTriple,
  toggleOption,
  metaDataComponentSelected: {
    representationData: representationDataSelectedNode,
    label: labelSelectedNode,
  },
  instanceCounts,
  togglerStyle,
  togglingDisabledExplanation,
  isDisabledWholeRow,
}: OptionProps) => {
  const { representationData, label } = metaDataComponent;
  return (
    <ListItem
      data-test-id={`outgoing-triple-option-${namedDirectedTriple.sourceType}-${namedDirectedTriple.referenceType}-${namedDirectedTriple.targetType}`}
      $isClickable={!isDisabled}
      $isDisabled={isDisabledWholeRow && isDisabled}
      key={tripleId}
      onClick={event => {
        event.preventDefault();

        if (!isDisabled) {
          toggleOption(tripleId, direction, namedDirectedTriple, !isSelected);
        }
      }}
    >
      <WithPopover content={togglingDisabledExplanation}>
        <FlexBox align="center" paddingLeft="small">
          <OptionToggler
            togglingDisabledExplanation={togglingDisabledExplanation}
            isDisabled={isDisabled}
            togglerStyle={togglerStyle}
            isSelected={isSelected}
            onToggle={() => {
              toggleOption(
                tripleId,
                direction,
                namedDirectedTriple,
                !isSelected
              );
            }}
          />
        </FlexBox>

        <FlexBox gap="xsmall" align="center" flex={1}>
          <ComponentTypeWithoutLabel
            representationData={representationDataSelectedNode}
            isSelected={true}
            label={labelSelectedNode}
          />
          <Icon iconName={IconName.ARROW_FORWARD} iconSize={IconSize.MEDIUM} />
          <Label>
            <TextOverflow>
              {metaDataReference.representationData?.displayLabel}
            </TextOverflow>
          </Label>
          <Icon iconName={IconName.ARROW_FORWARD} iconSize={IconSize.MEDIUM} />
          <ComponentType
            representationData={representationData}
            label={label}
          />
        </FlexBox>

        <FlexBox align="center" paddingRight="small">
          {instanceCounts !== null ? (
            <Tag>
              <CountWrapper>{instanceCounts}</CountWrapper>
            </Tag>
          ) : null}
        </FlexBox>
      </WithPopover>
    </ListItem>
  );
};

export const IncomingTripleOption = ({
  tripleId,
  metaDataComponent,
  metaDataReference,
  isSelected,
  isDisabled,
  direction,
  toggleOption,
  metaDataComponentSelected: {
    representationData: representationDataSelectedNode,
    label: labelSelectedNode,
  },
  namedDirectedTriple,
  instanceCounts,
  togglerStyle,
  togglingDisabledExplanation,
  isDisabledWholeRow,
}: OptionProps) => {
  const { representationData, label } = metaDataComponent;
  return (
    <ListItem
      $isClickable={!isDisabled}
      $isDisabled={isDisabledWholeRow && isDisabled}
      key={tripleId}
      data-test-id={`incoming-triple-option-${namedDirectedTriple.sourceType}-${namedDirectedTriple.referenceType}-${namedDirectedTriple.targetType}`}
      onClick={event => {
        event.preventDefault();
        if (!isDisabled) {
          toggleOption(tripleId, direction, namedDirectedTriple, !isSelected);
        }
      }}
    >
      <WithPopover content={togglingDisabledExplanation}>
        <FlexBox align="center" paddingLeft="small">
          <OptionToggler
            togglingDisabledExplanation={togglingDisabledExplanation}
            isDisabled={isDisabled}
            togglerStyle={togglerStyle}
            isSelected={isSelected}
            onToggle={() =>
              toggleOption(
                tripleId,
                direction,
                namedDirectedTriple,
                !isSelected
              )
            }
          />
        </FlexBox>

        <FlexBox gap="xsmall" align="center" flex={1}>
          <ComponentType
            representationData={representationData}
            label={label}
          />
          <Icon iconName={IconName.ARROW_FORWARD} iconSize={IconSize.MEDIUM} />
          <Label>
            <TextOverflow>
              {metaDataReference.representationData?.displayLabel}
            </TextOverflow>
          </Label>
          <Icon iconName={IconName.ARROW_FORWARD} iconSize={IconSize.MEDIUM} />
          <ComponentTypeWithoutLabel
            representationData={representationDataSelectedNode}
            isSelected={true}
            label={labelSelectedNode}
          />
        </FlexBox>

        <FlexBox align="center" paddingRight="small">
          {instanceCounts !== null ? (
            <Tag>
              <CountWrapper>{instanceCounts}</CountWrapper>
            </Tag>
          ) : null}
        </FlexBox>
      </WithPopover>
    </ListItem>
  );
};

const ComponentTypeWithoutLabel = ({
  representationData,
  isSelected,
  label,
}: ComponentTypeProps) => {
  if (representationData.value === 'none') {
    return (
      <WithPopover content={label}>
        <IconWrapper
          $backgroundColor={representationData.lightenedColor}
          $borderColor={representationData.contrastColor}
        >
          <Icon
            iconSize={IconSize.SMALL}
            iconName={IconName.COMPONENT}
            color={representationData.contrastColor}
          />
        </IconWrapper>
      </WithPopover>
    );
  }

  return (
    <ComponentType
      label=""
      representationData={representationData}
      isSelected={isSelected}
    />
  );
};
