import { IconName } from '@ardoq/icons';
import { MouseEvent, MouseEventHandler } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import { combineLatest, map } from 'rxjs';
import { requestPopoutGridEditor, showGridEditor } from '../actions';
import { ToolbarIconButtonProps } from '../atoms';
import gridEditor$ from '../gridEditor$';
import { gridEditorStateOperations } from '../gridEditorStateOperations';
import { GridEditorState } from '../types';

export type BottomBarViewProps = {
  isActive: boolean;
  onContainerClick: (event: MouseEvent) => void;
  bottomBarButtonIcons: ToolbarIconButtonProps[];
};

/**
 * Returns a JSON representation of the buttons to display
 */
const getBottomBarButtonIcons = (props: {
  openPopoutEditor: MouseEventHandler;
  openDockedEditor: MouseEventHandler;
}): ToolbarIconButtonProps[] => {
  return [
    {
      id: 'open-popout-editor',
      iconName: IconName.OPEN_IN_NEW,
      onClick: props.openPopoutEditor,
    },
    {
      id: 'open-docked-editor',
      iconName: IconName.CHEVRON_UP,
      onClick: props.openDockedEditor,
    },
  ];
};

const isBottomBarActive = (state: GridEditorState) => {
  if (gridEditorStateOperations.isPopoutGridEditorState(state)) {
    return true;
  }
  return !gridEditorStateOperations.isDockedGridEditorOpen(state);
};

const openPopoutEditor = (event: MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
  dispatchAction(requestPopoutGridEditor());
};

const openDockedEditor = (event: MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
  dispatchAction(showGridEditor());
};

type ToBottomBarViewModelArgs = {
  gridEditor: GridEditorState;
};
const toBottomBarViewModel = ({
  gridEditor,
}: ToBottomBarViewModelArgs): BottomBarViewProps => {
  const isActive = isBottomBarActive(gridEditor);
  const isPopoutGridEditorOpen =
    gridEditorStateOperations.isPopoutGridEditorOpen(gridEditor);

  const openCurrentGridEditor = isPopoutGridEditorOpen
    ? openPopoutEditor
    : openDockedEditor;

  const bottomBarButtonIcons = getBottomBarButtonIcons({
    openPopoutEditor,
    openDockedEditor: openCurrentGridEditor,
  });

  return {
    bottomBarButtonIcons,
    isActive,
    onContainerClick: openCurrentGridEditor,
  };
};

export const bottomBarViewModel$ = combineLatest({
  gridEditor: gridEditor$,
}).pipe(map(toBottomBarViewModel));
