import { Box } from '@ardoq/layout';
import { ErrorNotification } from '@ardoq/status-ui';

type SendMessageErrorProps = {
  error: string;
};

export const SendMessageError = ({ error }: SendMessageErrorProps) => (
  <Box padding="small">
    <ErrorNotification>{error}</ErrorNotification>
  </Box>
);
