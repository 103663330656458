import { colors } from '@ardoq/design-tokens';
import { WarningIcon } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';

type EmailCellProps = {
  email: string | null;
};

const EmailCell = ({ email }: EmailCellProps) => {
  if (email) return <>{email}</>;
  return (
    <FlexBox align="end" gap="xsmall">
      <WarningIcon color={colors.iconWarning} />
      <i>Missing email</i>
    </FlexBox>
  );
};

export default EmailCell;
