import {
  ButtonGroup,
  DangerButton,
  PrimaryButton,
  SecondaryButton,
} from '@ardoq/button';
import { SearchSpinner } from './atoms';
import { s16 } from '@ardoq/design-tokens';

interface QueryActionsProps {
  searchButtonText: string;
  showSpinner: boolean;
  hasChanges?: boolean;
  isRedundantQuery?: boolean;
  doSearch: () => void;
  applySearch?: () => void;
  deleteQuery?: () => void;
  actionMenuClickId?: string;
  isDisabled?: boolean;
}

export default function QueryActions({
  searchButtonText,
  showSpinner,
  hasChanges,
  isRedundantQuery = false,
  doSearch,
  applySearch,
  deleteQuery,
  actionMenuClickId,
  isDisabled,
}: QueryActionsProps) {
  return (
    <ButtonGroup style={{ marginTop: s16 }}>
      {!isRedundantQuery && (
        <>
          <PrimaryButton onClick={doSearch} isDisabled={isDisabled}>
            {searchButtonText}
          </PrimaryButton>
          {applySearch && (
            <SecondaryButton
              isDisabled={!hasChanges || isDisabled}
              onClick={applySearch}
              data-click-id={actionMenuClickId}
            >
              Apply Calculation
            </SecondaryButton>
          )}
        </>
      )}
      {isRedundantQuery && deleteQuery && (
        <DangerButton onClick={deleteQuery}>Delete Calculation</DangerButton>
      )}
      {showSpinner && <SearchSpinner />}
    </ButtonGroup>
  );
}
