import { Features, hasFeature } from '@ardoq/features';
import { AppModules } from 'appContainer/types';
import { trackClickedToolbarButton } from 'tracking/events/clicks';
import { openPresentationsBrowserDialog } from 'components/presentationsBrowserDialog/utils';
import { dispatchAction } from '@ardoq/rxbeach';
import { togglePresentationEditor } from 'appContainer/actions';
import { trackOpenedPresentationSidebar } from 'presentation/tracking';
import { createPresentationAndOpenInEditor } from 'streams/presentations/actions';
import { ArdoqId } from '@ardoq/api-types';

type ShowPresentationEditorArgs = {
  contextPresentationId?: ArdoqId;
  selectedModule: AppModules;
  shouldShow: boolean;
  hasPresentations: boolean;
};
export const showPresentationEditor = async ({
  contextPresentationId,
  selectedModule,
  shouldShow,
  hasPresentations,
}: ShowPresentationEditorArgs) => {
  trackClickedToolbarButton('Presentation editor');
  if (hasFeature(Features.PRESENTATIONS)) {
    const isOpeningPresentationEditorToAddSlideFromCurrentView =
      selectedModule === AppModules.DASHBOARDS ||
      selectedModule === AppModules.METAMODEL ||
      selectedModule === AppModules.WORKSPACES;

    if (shouldShow || !contextPresentationId) {
      trackOpenedPresentationSidebar(
        'Presentation browser',
        contextPresentationId
      );
    }

    if (!contextPresentationId) {
      if (hasPresentations) {
        return openPresentationsBrowserDialog({
          isOpeningPresentationEditorToAddSlideFromCurrentView,
        });
      }
      dispatchAction(createPresentationAndOpenInEditor({}));
      return;
    }

    dispatchAction(togglePresentationEditor({ shouldShow }));
  }
};
