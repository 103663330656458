import { Observable, combineLatest, map, switchMap } from 'rxjs';
import fp from 'lodash/fp';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import {
  isSuccessTransfer,
  tablesComplaintsToTablesStatuses,
} from 'integrations/common/streams/transferState/utils';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { TableStatus } from './types';
import { isValidTableMappingMap } from './utils';
import { integrationId$ } from '../integrationId/integrationId$';

export const tableStatuses$: Observable<Record<string, TableStatus>> =
  integrationId$.pipe(
    switchMap(integrationId => {
      return combineLatest([
        getTransferStateStream(integrationId),
        getTabularMappingStream(integrationId),
      ]).pipe(
        map(([transferState, tabularMapping]) => {
          if (!isSuccessTransfer(transferState)) {
            return fp.mapValues(
              tableMapping =>
                isValidTableMappingMap(tableMapping) ? 'Modified' : 'Default',
              tabularMapping
            );
          }

          const dryRunStatuses = tablesComplaintsToTablesStatuses(
            transferState.tablesComplaints
          );

          return Object.fromEntries(
            Object.entries(tabularMapping).map(([tableId, tableMapping]) => {
              const dryRunStatus = dryRunStatuses[tableId] ?? 'Default';

              const hasModified =
                isValidTableMappingMap(tableMapping) &&
                !fp.isEqual(
                  transferState.tabularMapping[tableId],
                  tableMapping
                );

              const status: TableStatus = hasModified
                ? 'Modified'
                : dryRunStatus;
              return [tableId, status];
            })
          );
        })
      );
    })
  );
