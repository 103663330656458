import { GRAPH_EDGE_ID, GRAPH_NODE_ID } from './consts';

export const getToggleGraphNodeSelection =
  (commands: {
    toggleIsSelectedGraphNode: (graphNodeId: string) => void;
    // Selecting edges is only used in the filters tab - so the command is optional here
    toggleIsSelectedGraphEdge?: (graphEdgeId: string) => void;
  }) =>
  (event: React.MouseEvent) => {
    const graphNodeId = getGraphNodeIdFromEventTarget(event);

    if (graphNodeId) {
      commands.toggleIsSelectedGraphNode(graphNodeId);
    } else {
      const graphEdgeId = getGraphEdgeIdFromEventTarget(event);

      if (graphEdgeId) {
        commands.toggleIsSelectedGraphEdge?.(graphEdgeId);
      }
    }
  };

export const getGraphNodeOnHover = (commands: {
  onGraphNodeHover: (graphNodeId: string | null) => void;
}) => {
  let lastHoveredNode: string | null = null;
  return (event: React.MouseEvent) => {
    const graphNodeId = getGraphNodeIdFromEventTarget(event);
    if (graphNodeId !== lastHoveredNode) {
      lastHoveredNode = graphNodeId;
      commands.onGraphNodeHover(graphNodeId);
    }
  };
};

const getGraphNodeIdFromEventTarget = (event: React.MouseEvent) => {
  const eventTarget = event.target;
  if (!(eventTarget instanceof Element)) return null;

  const target = eventTarget.closest<SVGElement>(`[${GRAPH_NODE_ID}]`);

  return target?.dataset?.graphNodeId ?? null;
};

const getGraphEdgeIdFromEventTarget = (event: React.MouseEvent) => {
  const eventTarget = event.target;
  if (!(eventTarget instanceof Element)) return null;

  const target = eventTarget.closest<SVGElement>(`[${GRAPH_EDGE_ID}]`);

  return target?.dataset?.graphEdgeId ?? null;
};
