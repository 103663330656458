import styled from 'styled-components';

export const Body = styled.div`
  height: 1500px;
  max-height: 80vh;
  overflow: hidden;
  & > div {
    height: 100%;
  }
`;
