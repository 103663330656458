import { getInitials } from '@ardoq/common-helpers';
import { formatDistanceToNow, parseDate } from '@ardoq/date-time';
import { TextOverflow } from '@ardoq/popovers';
import { MarginAutoFlexBox, CircularWrapper } from './homePageAtoms';
import { Text } from '@ardoq/typography';
import { Stack } from '@ardoq/layout';
import styled from 'styled-components';
import { s32 } from '@ardoq/design-tokens';

// S32 here is the gap in MarginAutoFlexBox PLUS the circular wrapper width
const FullWidthStack = styled(Stack)`
  width: calc(100% - ${s32});
`;

type UserMetaRepresentationProps = {
  lastModifiedByName: string | null;
  lastUpdated: string | null;
  hideBorder?: boolean;
};

const UserMetaRepresentation = ({
  lastModifiedByName,
  lastUpdated,
  hideBorder,
}: UserMetaRepresentationProps) => {
  if (!lastModifiedByName || !lastUpdated) return null;
  return (
    <MarginAutoFlexBox $hideBorder={hideBorder}>
      <CircularWrapper>{getInitials(lastModifiedByName)}</CircularWrapper>
      <FullWidthStack>
        <TextOverflow lineClamp={1}>
          <Text variant="caption" color="textModerate">
            {lastModifiedByName}
          </Text>
        </TextOverflow>
        <TextOverflow lineClamp={1}>
          <Text variant="caption" color="textSubtle">
            updated {formatDistanceToNow(parseDate(lastUpdated))} ago
          </Text>
        </TextOverflow>
      </FullWidthStack>
    </MarginAutoFlexBox>
  );
};

export default UserMetaRepresentation;
