import { CenteredViewText } from './atoms';
import { FlexBox } from '@ardoq/layout';

const EmptyRestoreView = () => (
  <FlexBox justify="center" flex={2}>
    <CenteredViewText>
      No components or references to restore at this time.
    </CenteredViewText>
  </FlexBox>
);

export default EmptyRestoreView;
