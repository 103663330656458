import { ModalTemplate, ModalSize, ModalLayout } from '@ardoq/modal';
import { useEffect, useRef, useState } from 'react';
import { combineLatest, map } from 'rxjs';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  ArdoqId,
  AudiencePreview,
  AudiencePreviewRow,
  BroadcastPreview,
} from '@ardoq/api-types';
import { BroadcastPreviewFetchStatus } from 'broadcasts/types';
import {
  getBroadcastPreview,
  setBroadcastPreviewStatus,
} from 'broadcasts/actions';
import broadcast$ from 'broadcasts/broadcast$';
import { ErrorNotification } from '@ardoq/status-ui';
import { Select } from '@ardoq/select';
import styled from 'styled-components';
import { Label } from '@ardoq/forms';
import { trackEvent } from '../../tracking/tracking';
import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { Stack } from '@ardoq/layout';

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

type PreviewIFrameProps = {
  broadcastId: ArdoqId;
  email?: string | null;
  broadcastPreview: BroadcastPreview | null;
  broadcastPreviewFetchStatus: BroadcastPreviewFetchStatus;
};

const PreviewIFrame = ({
  broadcastId,
  email,
  broadcastPreview,
  broadcastPreviewFetchStatus,
}: PreviewIFrameProps) => {
  useEffect(() => {
    if (!email)
      return dispatchAction(
        setBroadcastPreviewStatus(
          BroadcastPreviewFetchStatus.NO_AUDIENCE_WITH_EMAIL
        )
      );
    dispatchAction(getBroadcastPreview({ broadcastId, email }));
  }, [broadcastId, email]);

  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [height, setHeight] = useState(0);
  const onLoad = () => {
    setHeight(
      (iframeRef.current?.contentWindow?.document.body.scrollHeight ?? 0) + 10
    );
  };
  return broadcastPreviewFetchStatus === BroadcastPreviewFetchStatus.LOADING ||
    broadcastPreviewFetchStatus === BroadcastPreviewFetchStatus.IDLE ? (
    <LoadingWrapper>
      <ArdoqLoaderComponent
        loaderColor={LoaderColor.LIGHT}
        loaderType="spinner"
        size={LoaderSize.MEDIUM}
      />
    </LoadingWrapper>
  ) : broadcastPreviewFetchStatus === BroadcastPreviewFetchStatus.SUCCESS &&
    broadcastPreview ? (
    <Stack gap="small">
      <div>To: {broadcastPreview.to}</div>
      <div>From: {broadcastPreview.from}</div>
      <div>Subject line: {broadcastPreview.subject}</div>
      <iframe
        ref={iframeRef}
        onLoad={onLoad}
        srcDoc={broadcastPreview.body}
        height={height}
        width="100%"
        style={{ pointerEvents: 'none' }}
      />
    </Stack>
  ) : (
    <ErrorNotification>{broadcastPreviewFetchStatus}</ErrorNotification>
  );
};

const getModalWording = (broadcastId: ArdoqId | null, hasAudience: boolean) => {
  if (hasAudience && broadcastId) return {};
  if (!broadcastId && hasAudience)
    return {
      subtitle: 'Unsaved changes',
      text: (
        <ErrorNotification>
          Please save your broadcast and try again.
        </ErrorNotification>
      ),
    };
  if (!hasAudience && broadcastId)
    return {
      subtitle: 'No audience found.',
      text: (
        <ErrorNotification>
          Please make sure your audience is configured correctly and then try
          again.
        </ErrorNotification>
      ),
    };
  return {
    subtitle: 'Something went wrong.',
    text: (
      <ErrorNotification>
        Your broadcast previews could not be generated at this time. Please
        check your broadcast for any errors. If the issues persists, please
        contact customer support.
      </ErrorNotification>
    ),
  };
};

type BroadcastPreviewModalProps = {
  broadcastId: ArdoqId | null;
  audiencePreview: AudiencePreview;
  broadcastPreview: BroadcastPreview | null;
  broadcastPreviewFetchStatus: BroadcastPreviewFetchStatus;
  resolve: (resultValue: false) => void;
};

const BroadcastPreviewModal = ({
  broadcastId,
  audiencePreview,
  broadcastPreview,
  broadcastPreviewFetchStatus,
  resolve,
}: BroadcastPreviewModalProps) => {
  const audienceWithEmail = audiencePreview.filter(
    audienceMember => audienceMember.person.email
  );
  const [selectedAudience, setSelectedAudience] = useState<
    AudiencePreviewRow | undefined
  >(audienceWithEmail[0]);
  const hasAudience = Boolean(audiencePreview.length);
  return (
    <ModalTemplate
      modalSize={ModalSize.M}
      fixedHeight
      primaryButtonText={'Close'}
      onPrimaryButtonClick={() => resolve(false)}
      headerText="Preview"
      {...getModalWording(broadcastId, hasAudience)}
    >
      {hasAudience && broadcastId && (
        <ModalLayout>
          <Label>Audience member:</Label>
          <Select
            onValueChange={val => {
              trackEvent('broadcast-preview-audience-select-value-change');
              setSelectedAudience(
                audiencePreview.find(
                  audienceMember => audienceMember.person.email === val
                )
              );
            }}
            onFocus={() =>
              trackEvent('broadcast-preview-audience-select-focus')
            }
            value={selectedAudience?.person.email}
            options={[
              {
                options: audienceWithEmail.map(audienceMember => {
                  const label =
                    audienceMember.person.name ??
                    audienceMember.person.email ??
                    'Unknown'; // Should never happen
                  return {
                    label: label,
                    value: audienceMember.person.email,
                  };
                }),
              },
              {
                label: 'Audience without an email address',
                options: audiencePreview
                  .filter(audienceMember => !audienceMember.person.email)
                  .map(audienceMember => {
                    return {
                      label: audienceMember.person.name ?? 'Unknown',
                      value: null,
                      isDisabled: true,
                    };
                  }),
              },
            ]}
            placeholder={'Select an audience member...'}
          />
          <PreviewIFrame
            email={selectedAudience?.person.email}
            broadcastId={broadcastId}
            broadcastPreview={broadcastPreview}
            broadcastPreviewFetchStatus={broadcastPreviewFetchStatus}
          />
        </ModalLayout>
      )}
    </ModalTemplate>
  );
};

export default connect(
  BroadcastPreviewModal,
  combineLatest([broadcast$]).pipe(
    map(([{ broadcastPreview, broadcastPreviewFetchStatus }]) => ({
      broadcastPreviewFetchStatus,
      broadcastPreview,
    }))
  )
);
