import React, { useState } from 'react';
import { StatusType, Tag } from '@ardoq/status-ui';
import { ButtonGroup, IconButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import styled from 'styled-components';
import { CollapsibleIsland } from '@ardoq/page-layout';
import { Select, SelectOption } from '@ardoq/select';
import {
  FailureStrategy,
  Transformation,
  TransformationOperation,
} from '@ardoq/api-types/integrations';
import { Header4 } from '@ardoq/typography';
import { GroupFieldLayout, RadioGroup, TextInput } from '@ardoq/forms';
import { getOperationOptionValue, getTransformationData } from '../utils';
import { dispatchAction } from '@ardoq/rxbeach';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import {
  FAILURE_OPTIONS,
  INITIAL_TRANSFORMATION_CONFIG,
  OPERATION_OPTIONS,
} from './constants';
import { EmptyTransformation, ValidationErrors } from '../types';
import { Box, FlexBox, Stack } from '@ardoq/layout';

type TransformSectionProps = {
  integrationId: IntegrationId;
  index: number | null;
  expanded: boolean;
  defaultColumn: SelectOption<string>;
  availableColumns: SelectOption<string>[];
  transformation: Transformation | EmptyTransformation;
  setTransformation: (newTransformation: Transformation) => void;
  removeTransformation: () => void;
};

export const TransformSection = ({
  integrationId,
  index,
  expanded,
  defaultColumn,
  availableColumns,
  transformation,
  setTransformation,
  removeTransformation,
}: TransformSectionProps) => {
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
    dictionary: {},
    defaultValue: false,
    path: false,
  });
  const headerTitle =
    index !== null ? `${index + 1}. Transform data` : 'Transform data';

  const [label, setLabel] = useState<string | undefined>(
    getTransformationData({
      transformation,
      integrationId,
      setTransformation,
      validationErrors,
      setValidationErrors,
    })?.label
  );

  const renderTitle = () =>
    transformation.operation ? (
      <FlexBox>
        {headerTitle}
        {label && (
          <Box marginLeft="small">
            <Tag statusType={StatusType.INFO}>{label}</Tag>
          </Box>
        )}
      </FlexBox>
    ) : (
      headerTitle
    );

  const onOperationSelect = (option: SelectOption<TransformationOperation>) => {
    const transformationData = getTransformationData(
      {
        transformation,
        integrationId,
        setTransformation,
        validationErrors,
        setValidationErrors,
        setLabel,
      },
      { defaultColumn, availableColumns }
    );

    setTransformation({
      operation: option.value,
      config: {
        ...INITIAL_TRANSFORMATION_CONFIG[option.value],
        failure: transformation.config.failure,
      },
    });
    setLabel(option.label);
    if (transformationData && transformationData.trackingEvent) {
      dispatchAction(
        trackIntegrationEvent({
          integrationId,
          name: transformationData.trackingEvent,
        })
      );
    }
  };

  const onFailureStrategySelect = (strategy: FailureStrategy) => {
    const transformationData = getTransformationData(
      {
        transformation,
        integrationId,
        setTransformation,
        validationErrors,
        setValidationErrors,
        setLabel,
      },
      { failureStrategy: strategy }
    );

    if (transformationData && transformationData.failureStrategyState) {
      setTransformation(transformationData.failureStrategyState);

      if (strategy === 'use_existing') {
        setValidationErrors(prevState => ({
          ...prevState,
          defaultValue: false,
        }));
      }
    }
  };

  const onDefaultValueChange = (value: string) => {
    const transformationData = getTransformationData(
      {
        transformation,
        integrationId,
        setTransformation,
        validationErrors,
        setValidationErrors,
        setLabel,
      },
      { defaultValue: value }
    );

    if (transformationData && transformationData.defaultValueState) {
      setTransformation(transformationData.defaultValueState);
      setValidationErrors(prevState => ({
        ...prevState,
        defaultValue: !value,
      }));
    }
  };

  const renderTransformation = () => {
    const transformationData = getTransformationData(
      {
        transformation,
        integrationId,
        setTransformation,
        validationErrors,
        setValidationErrors,
        setLabel,
      },
      { defaultColumn, availableColumns }
    );

    return transformationData ? transformationData.component : null;
  };

  return (
    <CollapsibleIsland
      title={renderTitle()}
      isDefaultExpanded={expanded}
      toggleOnHeaderClick
      rightContent={
        <ButtonGroup>
          <IconButton
            iconName={IconName.DELETE}
            onClick={removeTransformation}
          />
        </ButtonGroup>
      }
    >
      <Stack gap="medium">
        <ContentWrapper gap="small">
          <Select
            label="Select type of transformation"
            placeholder="Select"
            options={OPERATION_OPTIONS}
            value={getOperationOptionValue(
              transformation.operation as TransformationOperation
            )}
            onChange={option => option && onOperationSelect(option)}
          />
        </ContentWrapper>
        {transformation.operation && (
          <Stack gap="small">
            <Header4>Configure transformation rules</Header4>
            {renderTransformation()}
            <ContentWrapper gap="small">
              <Header4>{`If the transformation doesn't return a value`}</Header4>
              <RadioGroup
                value={transformation.config.failure.strategy}
                groupFieldLayout={GroupFieldLayout.VERTICAL}
                onChange={value =>
                  onFailureStrategySelect(value as FailureStrategy)
                }
                options={FAILURE_OPTIONS}
              />
              {transformation.config.failure.strategy === 'use_default' && (
                <TextInput
                  autoFocus
                  value={transformation.config.failure.default}
                  onValueChange={onDefaultValueChange}
                  placeholder="Specify default value"
                  onBlur={() =>
                    setValidationErrors(prevState => ({
                      ...prevState,
                      defaultValue: !transformation.config.failure.default,
                    }))
                  }
                  errorMessage={
                    validationErrors.defaultValue
                      ? 'This field is required.'
                      : undefined
                  }
                />
              )}
            </ContentWrapper>
          </Stack>
        )}
      </Stack>
    </CollapsibleIsland>
  );
};

const ContentWrapper = styled(Stack)`
  width: 410px;
`;
