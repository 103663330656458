import { Tag, TagGroup } from '@ardoq/status-ui';

type SourceCellProps = {
  sourceLabels: string[];
};
const SourceCell = ({ sourceLabels }: SourceCellProps) => (
  <TagGroup>
    {sourceLabels.map(sourceLabel => (
      <Tag key={sourceLabel} label={sourceLabel} />
    ))}
  </TagGroup>
);

export default SourceCell;
