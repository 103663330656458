import { Select, SelectOption } from '@ardoq/select';
import React from 'react';
import {
  ConcatColumnTransformation,
  Transformation,
} from '@ardoq/api-types/integrations';
import { TextInput } from '@ardoq/forms';
import { FlexBox, Stack } from '@ardoq/layout';
import styled from 'styled-components';
import { Paragraph } from '@ardoq/typography';

const StyledSelect = styled(Select)`
  width: 280px;
`;

const StyledInput = styled(TextInput)`
  width: 180px;
`;

type ConcatenateTransformationProps = {
  transformation: ConcatColumnTransformation;
  setTransformation: (newTransformation: Transformation) => void;
  setLabel: React.Dispatch<React.SetStateAction<string | undefined>>;
  defaultColumn: SelectOption<string>;
  availableColumns: SelectOption<string>[];
};

const ConcatenateColumnTransformationSection = ({
  transformation,
  setTransformation,
  setLabel,
  defaultColumn,
  availableColumns,
}: ConcatenateTransformationProps) => {
  const onSelectColumn = (value: string) => {
    setTransformation({
      ...transformation,
      config: {
        ...transformation.config,
        additionalFields: [value],
      },
    });
    setLabel(
      `Joining column: ${
        availableColumns.find(column => column.value === value)?.label
      }`
    );
  };

  const onChangeJoinStr = (value: string) => {
    setTransformation({
      ...transformation,
      config: {
        ...transformation.config,
        joinStr: value,
      },
    });
  };
  return (
    <Stack gap="medium">
      <Paragraph variant="text2">
        Select another column to join with the first. You can also enter an
        optional separator, like a space or comma, to place between the values.
      </Paragraph>
      <FlexBox marginBottom="medium" gap="medium">
        <StyledSelect defaultValue={defaultColumn} isDisabled />
        <StyledInput
          value={transformation.config.joinStr}
          onValueChange={onChangeJoinStr}
          placeholder="Type join string"
        />
        <StyledSelect
          placeholder="Select column"
          options={availableColumns}
          onValueChange={value => onSelectColumn(value as string)}
          value={transformation.config.additionalFields[0]}
          closeMenuOnSelect={true}
          isClearable
        />
      </FlexBox>
    </Stack>
  );
};

export default ConcatenateColumnTransformationSection;
