import { AllProgress, Progress } from './types';
import { ActionCreatorParameter } from 'integrations/common/utils/actionCreatorWithIntegrationId';
import { resetFetchProgress, setFetchProgress } from './actions';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { EMPTY, map, Observable } from 'rxjs';
import { buildInitialStreamState } from '../utils';
import { IntegrationId } from '../tabularMappings/types';
import { Maybe } from '@ardoq/common-helpers';

const initialInstanceState: Progress = {
  status: 'INIT',
  progress: {
    action: null,
    started: null,
    completed: null,
    total: null,
  },
};

const defaultState: AllProgress = buildInitialStreamState<Progress>(
  () => initialInstanceState
);

const setFetchProgressReducerFn = (
  state: AllProgress,
  {
    progress,
    status,
    integrationId,
  }: ActionCreatorParameter<typeof setFetchProgress>
): AllProgress => ({
  ...state,
  [integrationId]: {
    ...state[integrationId],
    progress: progress ?? state[integrationId]?.progress,
    status,
  },
});

const resetFetchProgressFailureReducerFn = (
  state: AllProgress,
  { integrationId }: ActionCreatorParameter<typeof resetFetchProgress>
): AllProgress => ({
  ...state,
  [integrationId]: initialInstanceState,
});

const reducers = [
  reducer<AllProgress, ActionCreatorParameter<typeof setFetchProgress>>(
    setFetchProgress,
    setFetchProgressReducerFn
  ),
  reducer<AllProgress, ActionCreatorParameter<typeof resetFetchProgress>>(
    resetFetchProgress,
    resetFetchProgressFailureReducerFn
  ),
];

export const fetchProgress$ = persistentReducedStream(
  `fetchProgress$`,
  defaultState,
  reducers
);

const integrationToFetchProgressStream = new Map<
  IntegrationId,
  Observable<Progress>
>();

export const getFetchProgressStream = (integrationId: Maybe<IntegrationId>) => {
  if (!integrationId) {
    return EMPTY;
  }

  const fetchProgressStream =
    integrationToFetchProgressStream.get(integrationId);

  if (fetchProgressStream) {
    return fetchProgressStream;
  }

  const stream$ = fetchProgress$.pipe(
    map(fetchProgress => fetchProgress[integrationId])
  );

  integrationToFetchProgressStream.set(integrationId, stream$);

  return stream$;
};
