import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { filter, of, switchMap, tap, withLatestFrom } from 'rxjs';
import {
  loadSchedule,
  LoadSchedulePayload,
  setLoadedScheduleId,
  upsertSchedule,
  UpsertSchedulePayload,
} from 'integrations/common/streams/schedule/actions';
import {
  createSchedule,
  updateSchedule,
} from 'integrations/common/streams/schedules/actions';
import { tabularMappings$ } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import {
  connections$,
  getConnectionsStream,
} from 'integrations/common/streams/connections/connections$';
import { getCurrentTransferConfig } from 'integrations/common/streams/transferConfigs/utils';
import { tabularMappingIntoTransferConfig } from 'integrations/common/streams/tabularMappings/utils';
import fp from 'lodash/fp';
import { showToast, ToastType } from '@ardoq/status-ui';
import {
  getSchedulesStream,
  schedules$,
} from 'integrations/common/streams/schedules/getSchedulesStream';
import { isUnifiedSchedule } from 'integrations/common/streams/schedules/utils';
import { schedule$ } from 'integrations/common/streams/schedule/schedule$';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { isUnifiedIntegrationPayload } from '../../utils';
import { resources$ } from '../resources/resources$';
import { selectedResourcesToSourceConfigResources } from '../resources/utils';
import { applySourceConfig } from '../config/actions';
import { startMissingConnectionModal } from '../../overview/account/MissingAccountModal';
import { INTEGRATION_ID_TO_SCHEDULE_TYPE } from '../../constants';
import { ImportRoute } from 'integrations/common/navigation/types';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { UnifiedSourceConfig } from '@ardoq/api-types/integrations';

const handleUpsertSchedule = routine(
  ofType(upsertSchedule),
  extractPayload(),
  filter(isUnifiedIntegrationPayload<UpsertSchedulePayload>),
  withLatestFrom(
    tabularMappings$,
    transferConfigs$,
    activeIntegrations$,
    schedules$,
    connections$,
    resources$,
    schedule$
  ),
  tap(
    ([
      { formData, integrationId },
      tabularMappings,
      transferConfigs,
      activeIntegrations,
      schedules,
      connectionsStates,
      integrationResources,
      scheduleStates,
    ]) => {
      const activeIntegration = activeIntegrations[integrationId];
      const tabularMapping = tabularMappings[integrationId];
      const connectionsState = connectionsStates[integrationId];
      const resources = integrationResources[integrationId];
      const scheduleState = scheduleStates[integrationId];

      const currentTransferConfig = getCurrentTransferConfig(
        transferConfigs.configs,
        activeIntegration
      );

      const configName =
        currentTransferConfig.name || activeIntegration.integrationName;

      const config = tabularMappingIntoTransferConfig(
        tabularMapping,
        currentTransferConfig
      );

      if (!connectionsState.selectedConnectionIds.length) {
        showToast(`No connection`, ToastType.INFO);
        return;
      }

      dispatchAction(
        trackIntegrationEvent({
          integrationId,
          name: 'SELECTED_SCHEDULE_INTERVAL',
          metadata: {
            source: 'import-step',
            interval: formData.policy.type,
            day:
              formData.policy.type === 'weekly'
                ? formData.policy.weekDay
                : null,
            managesWorkspace: formData.managesWorkspace ? 'on' : 'off',
          },
        })
      );

      const sourceConfig = {
        connectionId: connectionsState.selectedConnectionIds[0],
        resources: selectedResourcesToSourceConfigResources(
          resources.selectedResources
        ),
      };

      const scheduleConfig = {
        ...config,
        sourceConfig,
        name: configName,
      };

      if (scheduleState.loadedScheduleId) {
        const loadedSchedule = schedules.schedules.find(
          s => s._id === scheduleState.loadedScheduleId
        );
        if (loadedSchedule) {
          dispatchAction(
            updateSchedule({
              integrationId,
              schedule: fp.flow(
                fp.set('schedulingPolicy', formData.policy),
                fp.update('jobOptions', options => ({
                  ...options,
                  name: formData.name,
                  managesWorkspace: formData.managesWorkspace,
                  sourceConfig,
                  tabularMapping: scheduleConfig,
                }))
              )(loadedSchedule),
            })
          );
        }
      } else {
        dispatchAction(
          createSchedule({
            integrationId,
            schedule: {
              schedulingPolicy: formData.policy,
              jobOptions: {
                type: INTEGRATION_ID_TO_SCHEDULE_TYPE[integrationId],
                name: formData.name,
                managesWorkspace: formData.managesWorkspace,
                tabularMapping: scheduleConfig,
                sourceId: null,
                sourceConfig,
              },
            },
          })
        );
      }
    }
  )
);

const handleLoadSchedule = routine(
  ofType(loadSchedule),
  extractPayload(),
  filter(isUnifiedIntegrationPayload<LoadSchedulePayload>),
  switchMap(({ integrationId, scheduleId }) =>
    of({ integrationId, scheduleId }).pipe(
      withLatestFrom(
        getSchedulesStream(integrationId),
        getConnectionsStream(integrationId)
      )
    )
  ),
  tap(([{ integrationId, scheduleId }, { schedules }, { connections }]) => {
    const schedule = schedules.find(s => scheduleId === s._id);

    if (!schedule || !isUnifiedSchedule(schedule)) {
      return;
    }

    const handleLoadSchedule = (
      scheduleId: string,
      sourceConfig: UnifiedSourceConfig
    ) => {
      dispatchAction(
        setLoadedScheduleId({
          scheduleId,
          integrationId,
        })
      );
      dispatchAction(
        navigateToPath({ integrationId, path: ImportRoute.SELECT_DATA })
      );
      dispatchAction(applySourceConfig({ sourceConfig, integrationId }));
    };

    const connection = connections.find(
      conn => conn._id === schedule.jobOptions.sourceConfig.connectionId
    );

    if (!connection) {
      return startMissingConnectionModal({
        onCancel: () => null,
        onSubmit: connection =>
          handleLoadSchedule(schedule._id, {
            ...schedule.jobOptions.sourceConfig,
            connectionId: connection._id,
          }),
      });
    }
    handleLoadSchedule(schedule._id, schedule.jobOptions.sourceConfig);
  })
);

export default [handleUpsertSchedule, handleLoadSchedule];
