import { APIFieldAttributes } from '@ardoq/api-types';
import Fields from 'collections/fields';

/**
 * Not in `*-interface` modules because:
 * - either it's using `toJSON` across the entire collection which might
 *   encourage code with potential performance problems
 * - or are too specific to be useful in a general interface
 */

const getAllFields = (): APIFieldAttributes[] =>
  Fields.collection.map(field => field.toJSON());

export const adminBundleEditorBackboneInterface = {
  getAllFields,
};
