import fp from 'lodash/fp';
import { RegionsState } from 'integrations/cloudProviders/streams/regions/types';
import { ResourceTypesState } from 'integrations/cloudProviders/streams/resourceTypes/types';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';
import { areArraysEqual } from 'integrations/common/utils/common';
import { SelectionState } from './types';

export const isValidSelectionState = ({
  connectionsState,
  regionsState,
  resourceTypesState,
}: {
  connectionsState: IntegrationConnectionsState;
  regionsState: RegionsState;
  resourceTypesState: ResourceTypesState;
}) => {
  return Boolean(
    fp.every(fp.size, [
      connectionsState.selectedConnectionIds,
      resourceTypesState.selectedResourceTypeIds,
      regionsState.selectedRegionIds,
    ])
  );
};

export const areFetchedResourcesInSync = (
  { selectionRequest }: SelectionState,
  { selectedResourceTypeIds }: ResourceTypesState,
  { selectedRegionIds }: RegionsState
): boolean => {
  if (!selectionRequest) {
    return false;
  }

  const fetchedResourceTypes = selectionRequest.providerParams.resourceTypes;
  const fetchedRegions = selectionRequest.providerParams.regions;

  return (
    areArraysEqual(selectedResourceTypeIds, fetchedResourceTypes) &&
    areArraysEqual(selectedRegionIds, fetchedRegions)
  );
};
