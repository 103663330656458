import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorContent = () => {
  return (
    <Container>
      <DoqWithSpeechBubble
        doqType={DoqType.ERROR}
        layout={DoqLayout.DIALOG}
        title="Sorry, an error ocurred..."
        message="We are sorry about the inconvenience and have been notified. If the error persists, please contact our support."
      />
    </Container>
  );
};
