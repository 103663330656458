import styled from 'styled-components';
import content$ from 'broadcasts/content$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { Checkbox } from '@ardoq/forms';
import { map } from 'rxjs/operators';
import { getFilterConditionByType } from 'broadcasts/utils';
import { s8 } from '@ardoq/design-tokens';
import {
  toggleSurveyCompleteCondition,
  toggleSurveyIncompleteCondition,
} from './actions';
import {
  BroadcastSurveyContent,
  BroadcastFilterConditionType,
} from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';

type SurveyCompletenessConditionsProps = {
  readonly hasSurveyCompleteCondition: boolean;
  readonly hasSurveyIncompleteCondition: boolean;
};

const Wrapper = styled.div`
  margin-top: ${s8};
`;

const SurveyCompletenessConditions = ({
  hasSurveyCompleteCondition,
  hasSurveyIncompleteCondition,
}: SurveyCompletenessConditionsProps) => {
  return (
    <>
      {hasFeature(Features.SURVEY_IS_COMPLETE_BROADCAST_FILTER) && (
        <Wrapper>
          <Checkbox
            isChecked={hasSurveyCompleteCondition}
            onChange={() => dispatchAction(toggleSurveyCompleteCondition())}
          >
            Survey is complete
          </Checkbox>
        </Wrapper>
      )}
      <Wrapper>
        <Checkbox
          isChecked={hasSurveyIncompleteCondition}
          onChange={() => dispatchAction(toggleSurveyIncompleteCondition())}
        >
          Survey is incomplete
        </Checkbox>
      </Wrapper>
    </>
  );
};

const toSurveyCompleteConditionProps = ({
  filterConditions,
}: BroadcastSurveyContent) => {
  const hasSurveyCompleteCondition = Boolean(
    getFilterConditionByType(
      filterConditions,
      BroadcastFilterConditionType.SURVEY_COMPLETE
    )
  );
  const hasSurveyIncompleteCondition = Boolean(
    getFilterConditionByType(
      filterConditions,
      BroadcastFilterConditionType.SURVEY_INCOMPLETE
    )
  );
  return { hasSurveyCompleteCondition, hasSurveyIncompleteCondition };
};

export default connect(
  SurveyCompletenessConditions,
  content$.pipe(
    map(surveyContent =>
      toSurveyCompleteConditionProps(surveyContent as BroadcastSurveyContent)
    )
  )
);
