import { uniq } from 'lodash';
import {
  PathCollapsingRuleInternal,
  TraversalBuilderState,
} from 'viewpointBuilder/types';
import { ShortestPath } from './collapsePathsTab/getShortestPath';
import { EditablePathCollapsingRule } from './collapsePathsTab/types';
import { collapsedPathToHash } from './collapsePathsTab/collapsedPathToHash';
import { GraphEdgeWithMetaData } from '@ardoq/graph';

type UpdatePathHighlightingArgs = {
  shortestPath: ShortestPath | null;
  highlightedPathId: string;
  status?: 'error' | 'default';
  isFadedOut?: boolean;
  clearSelectedNodes?: boolean;
  currentEditableRule?: EditablePathCollapsingRule | null;
  hasError?: boolean;
};

const updatePathHighlighting = (
  state: TraversalBuilderState,
  {
    shortestPath,
    highlightedPathId,
    status = 'default',
    isFadedOut = false,
    clearSelectedNodes = false,
    currentEditableRule = state.pathCollapsing.currentEditableRule,
    hasError = false,
  }: UpdatePathHighlightingArgs
) => {
  const updatedState = resetPathHighlightingForCollapsingRules(state);
  const { pathByReferenceIds } = shortestPath ?? { pathByReferenceIds: [] };
  const { graphEdges, graphNodes, highlightedPaths } = updatedState;
  const newGraphEdges = new Map(graphEdges);
  const newGraphNodes = new Map(graphNodes);

  if (clearSelectedNodes && highlightedPaths[highlightedPathId]) {
    highlightedPaths[highlightedPathId].forEach(({ componentIds }) => {
      componentIds.forEach(id => {
        const node = newGraphNodes.get(id);
        node?.setIsSelected(false);
      });
    });
  }

  newGraphEdges.forEach(edge => {
    edge.setOpacity(isFadedOut ? 0.5 : 1);
  });
  newGraphNodes.forEach(node => {
    node.setOpacity(isFadedOut ? 0.5 : 1);
  });

  pathByReferenceIds.forEach(id => {
    const edge = newGraphEdges.get(id);
    if (!edge) return;
    edge.setIsInSelectedPath(true);
    edge.setOpacity(1);
    edge.setHasCollapsingRuleError(hasError);
  });
  const nodesInPathIds = uniq(
    pathByReferenceIds.flatMap(id => {
      const edge = graphEdges.get(id);
      if (!edge) return [];
      const { source, target } = edge;
      return [source, target];
    })
  );
  nodesInPathIds.forEach(id => {
    const node = newGraphNodes.get(id);
    if (!node) return;
    node.setIsInSelectedPath(true);
    node.setOpacity(1);
  });

  const newHighlightedPaths = {
    ...highlightedPaths,
    [highlightedPathId]: [
      {
        referenceIds: pathByReferenceIds,
        componentIds: nodesInPathIds,
        status,
      },
    ],
  };

  return {
    ...state,
    graphEdges: newGraphEdges,
    graphNodes: newGraphNodes,
    highlightedPaths: newHighlightedPaths,
    pathCollapsing: {
      ...state.pathCollapsing,
      currentEditableRule,
    },
  };
};

const resetPathHighlightingForCollapsingRules = (
  state: TraversalBuilderState
): TraversalBuilderState => {
  const {
    graphEdges,
    graphNodes,
    pathCollapsing: { rules },
  } = state;
  const newGraphEdges = new Map(graphEdges);
  const newGraphNodes = new Map(graphNodes);
  const newHighlightedPaths = Object.fromEntries(
    rules
      .filter(({ isActive, isExpanded }) => isActive && isExpanded)
      .map(rule => {
        const hash = collapsedPathToHash(rule);
        const paths = getHighlightPaths(rule, graphEdges);
        return [hash, paths];
      })
  );
  const allHighlightedReferenceIds = new Set(
    Object.values(newHighlightedPaths).flatMap(paths =>
      paths.flatMap(({ referenceIds }) => referenceIds)
    )
  );
  const allHighlightedComponentIds = new Set(
    Object.values(newHighlightedPaths).flatMap(paths =>
      paths.flatMap(({ componentIds }) => componentIds)
    )
  );
  Array.from(newGraphEdges.values()).forEach(edge => {
    edge.setIsInSelectedPath(allHighlightedReferenceIds.has(edge.id));
    edge.setHasCollapsingRuleError(false);
    edge.setOpacity(1);
  });
  Array.from(newGraphNodes.values()).forEach(node => {
    node.setIsInSelectedPath(allHighlightedComponentIds.has(node.id));
    node.setOpacity(1);
  });
  return {
    ...state,
    graphEdges: newGraphEdges,
    graphNodes: newGraphNodes,
    highlightedPaths: newHighlightedPaths,
  };
};

const getHighlightPaths = (
  rule: PathCollapsingRuleInternal,
  graphEdges: Map<string, GraphEdgeWithMetaData>
) =>
  rule.virtualEdgeStates.map(({ collapsedEdges }) =>
    collapsedEdges.reduce<{
      referenceIds: string[];
      componentIds: string[];
      status: 'error' | 'default';
    }>(
      (highlightedPath, referenceId) => {
        highlightedPath.referenceIds.push(referenceId);
        const edge = graphEdges.get(referenceId);
        if (!edge) return highlightedPath;
        highlightedPath.componentIds.push(edge.source);
        highlightedPath.componentIds.push(edge.target);
        return highlightedPath;
      },
      { referenceIds: [], componentIds: [], status: 'default' }
    )
  );

export const viewpointBuilderGraphOperations = {
  updatePathHighlighting,
  resetPathHighlightingForCollapsingRules,
};
