import { ArdoqId } from '@ardoq/api-types';
import { filterClass } from '@ardoq/color-helpers';
import { GetCssClassNamesOption } from '@ardoq/data-model';
import { hasFeature } from '@ardoq/features';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { NavigatorViewInterface } from 'components/WorkspaceHierarchies/types';
import { map, Observable } from 'rxjs';
import { subdivisionsInterface } from 'streams/subdivisions/subdivisionInterface';
import { copyComponents } from 'copyComponent/copyComponentService';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';

const navigatorViewInterface: NavigatorViewInterface = {
  isComponentInFlexibleModel: (componentId: ArdoqId) =>
    componentInterface.belongsToFlexibleModel(componentId),
  isFlexibleWorkspace: (workspaceId: ArdoqId) =>
    workspaceInterface.isFlexibleWorkspace(workspaceId),
  canComponentHaveChildren: (componentId: ArdoqId) =>
    componentInterface.canComponentHaveChildren(componentId),
  getComponentLock: (componentId: ArdoqId) =>
    Boolean(componentInterface.getAttribute(componentId, 'lock')),
  getComponentTypeId: (componentId: ArdoqId) =>
    componentInterface.getTypeId(componentId),
  getCSSFilterColor: (componentId: ArdoqId) => {
    const color = componentInterface.getComponentFilterColor(componentId);
    if (!color) {
      return '';
    }
    return filterClass(color) ?? '';
  },
  getComponentRepresentationData: (componentId: ArdoqId) =>
    componentInterface.getRepresentationData(componentId),
  getSortedComponentChildrenIds: (componentId: ArdoqId) =>
    componentInterface.getChildren(componentId),
  getUnsortedComponentChildrenIds: (componentId: ArdoqId) =>
    componentInterface.getChildren(componentId, /* ignoreSort= */ true),
  isIncludedInContextByFilter: (componentId: ArdoqId) =>
    componentInterface.isIncludedInContextByFilter(componentId),
  hasComponentWriteAccess: (componentId: ArdoqId) =>
    componentInterface.hasWriteAccess(componentId),
  isScenarioContextComponent: (componentId: ArdoqId) =>
    componentInterface.isScenarioContextComponent(componentId),
  getVisualDiffType: (componentId: ArdoqId) =>
    componentInterface.getVisualDiffType(componentId),
  isWorkspaceEmpty: (workspaceId: ArdoqId) =>
    componentInterface.isWorkspaceEmpty(workspaceId),
  getWorkspaceRootComponents: (workspaceId: ArdoqId) =>
    componentInterface.getRootComponents(workspaceId),
  isWorkspace: (workspaceId: ArdoqId) =>
    workspaceInterface.isWorkspace(workspaceId),
  hasWorkspaceWriteAccess: (workspaceId: ArdoqId) =>
    workspaceInterface.hasWriteAccess(workspaceId),
  getRootWorkspaceIdOfComponent: (componentId: ArdoqId) =>
    componentInterface.getWorkspaceId(componentId),
  getComponentName: (componentId: ArdoqId) =>
    componentInterface.getDisplayName(componentId),
  getComponentOrder: (componentId: ArdoqId) =>
    componentInterface.getAttribute(componentId, '_order'),
  getWorkspaceName: (workspaceId: ArdoqId) =>
    workspaceInterface.getAttribute(workspaceId, 'name'),
  getScenarioName: (scenarioId: ArdoqId) =>
    scenarioInterface.getScenarioNameById(scenarioId) ?? null,
  getComponentCssClassNames: (
    componentId: ArdoqId,
    options?: GetCssClassNamesOption
  ) => componentInterface.getCssClassNames(componentId, options),
  getComponentDisplayColorAsSVGAttributes: (
    componentId: ArdoqId,
    options: GetCssClassNamesOption
  ) =>
    componentInterface.getComponentDisplayColorAsSVGAttributes(
      componentId,
      options
    ),
  isComponentExternallyManaged: (componentId: ArdoqId) =>
    componentInterface.isExternallyManaged(componentId),
  isComponentExternallyMissing: (componentId: ArdoqId) =>
    componentInterface.isExternallyMissing(componentId),
  currentUserIsOrgAdmin: () => currentUserInterface.currentUserIsOrgAdmin(),
  isWorkspaceExternallyManaged: (workspaceId: ArdoqId) =>
    workspaceInterface.isExternallyManaged(workspaceId),
  getComponentSubdivisionMembership: (componentId: ArdoqId) =>
    componentInterface.getSubdivisionMembership(componentId),
  getSubdivisionById: (subdivisionId: ArdoqId) =>
    subdivisionsInterface.getSubdivisionById(subdivisionId),
  getLinkedSourceComponents: (componentId: ArdoqId) =>
    componentInterface.getSources(componentId),
  getLinkedTargetComponents: (componentId: ArdoqId) =>
    componentInterface.getTargets(componentId),
  hasFeature,
  getWorkspaceBoundSubdivisionsIds: (workspaceId: ArdoqId) =>
    workspaceInterface.getAttribute(workspaceId, 'subdivisionMembership') ?? [],
  isComponent: (componentId: ArdoqId) =>
    componentInterface.isComponent(componentId),
  removeZonesFromComponent: (componentId: ArdoqId) =>
    componentInterface.removeZones(componentId),
  copyZones: (sourceComponentId: ArdoqId, targetComponentId: ArdoqId) =>
    componentInterface.copyZones(sourceComponentId, targetComponentId),
  getComponentData: (componentId: ArdoqId) =>
    componentInterface.getComponentData(componentId),
  copyComponents,
};

export const navigatorViewInterface$: Observable<NavigatorViewInterface> =
  currentUserPermissionContext$.pipe(
    map(permissionContext => ({
      ...navigatorViewInterface,
      hasWorkspaceWriteAccess: (workspaceId: ArdoqId) => {
        return workspaceAccessControlInterface.canEditWorkspace(
          permissionContext,
          workspaceId,
          null
        );
      },
    }))
  );
