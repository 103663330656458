import { Button, ButtonType } from '@ardoq/button';
import { showAccessControlFAQ } from './AccessControlFAQ';
import {
  ACCESS_CONTROL_FAQ_EVENTS,
  trackAccessControlFAQEvent,
} from './accessControlFAQTrackings';
import { Icon, IconName } from '@ardoq/icons';

export const NeedHelpButton = () => {
  return (
    <Button
      buttonType={ButtonType.SECONDARY}
      onClick={() => {
        showAccessControlFAQ();
        trackAccessControlFAQEvent(ACCESS_CONTROL_FAQ_EVENTS.OPEN);
      }}
    >
      <Icon iconName={IconName.HELP}></Icon>
      Need help?
    </Button>
  );
};
