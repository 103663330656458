import { ArdoqId } from '@ardoq/api-types';
import { hasFeature, Features } from '@ardoq/features';
import { dispatchAction } from '@ardoq/rxbeach';
import { fetchPermissionGroups } from 'admin/accessControl/PermissionGroups/streams/actions';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { fetchTags } from 'streams/tags/actions';
import { fetchPendingApprovalsSummary } from 'surveyAdmin/streams/actions';
import { selectSurveyMode, selectSurveyId } from './actions';
import { SurveyAdminMode } from './types';

export const openSurveyAdminView = (tab: SurveyAdminMode.OVERVIEW) => {
  if (hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2)) {
    dispatchAction(fetchPendingApprovalsSummary());
  }
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.SURVEY_ADMIN })
  );
  dispatchAction(selectSurveyMode({ mode: tab }));
};

type EditSurveyRequest = {
  surveyId: ArdoqId;
  newSurveyWasSaved?: boolean;
};

export const requestEditSurvey = ({
  surveyId,
  newSurveyWasSaved,
}: EditSurveyRequest) => {
  dispatchAction(fetchTags());
  if (hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2)) {
    dispatchAction(fetchPendingApprovalsSummary());
    dispatchAction(fetchPermissionGroups());
  }
  dispatchAction(
    selectSurveyId({
      surveyId,
      keepEditorIndex: newSurveyWasSaved,
    })
  );
  dispatchAction(selectSurveyMode({ mode: SurveyAdminMode.EDIT_SURVEY }));
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.SURVEY_ADMIN })
  );
};
