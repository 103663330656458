import privileges$ from 'privileges/privileges$';
import roles$ from 'roles/roles$';
import { Observable, map } from 'rxjs';
import { derivedStream } from '@ardoq/rxbeach';
import { ManageUsersStreamShape, manageUsers$ } from './manageUsers$';
import { RolesStreamShape } from 'roles/types';
import { PrivilegesStreamShape } from 'privileges/types';
import {
  APIOrganizationAttributes,
  APIOrganizationUser,
  APIRoleAttributes,
  ArdoqId,
  OrgAccessLevel,
  Privilege,
  PrivilegesByUser,
} from '@ardoq/api-types';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { OrgUsersStreamShape } from 'streams/orgUsers/types';
import currentOrganization$ from 'streams/organizations/currentOrganization$';

export type OrgMembersViewStreamShape = ManageUsersStreamShape & {
  organization: APIOrganizationAttributes;
  users: APIOrganizationUser[];
  usersById: Partial<Record<ArdoqId, APIOrganizationUser>>;
  configurablePrivileges: Privilege[];
  privilegesByUser: PrivilegesByUser;
  currentUserCanConfigurePrivileges: boolean;
  roles: APIRoleAttributes[];
  roleTitleByLabel: Partial<Record<OrgAccessLevel, string>>;
  hasConfigurablePrivileges: boolean;
};

const toOrgMembersViewStream = ([
  organization,
  { users, byId: usersById },
  { roles },
  manageUsersState,
  privileges,
]: [
  APIOrganizationAttributes,
  OrgUsersStreamShape,
  RolesStreamShape,
  ManageUsersStreamShape,
  PrivilegesStreamShape,
]) => {
  const roleTitleByLabel = Object.fromEntries(
    roles.map(role => [role.label, role.title])
  );
  return {
    organization,
    users,
    usersById,
    ...manageUsersState,
    ...privileges,
    roles,
    roleTitleByLabel,
    hasConfigurablePrivileges: privileges.configurablePrivileges.length > 0,
  };
};

const orgMembersView$: Observable<OrgMembersViewStreamShape> = derivedStream(
  'orgMembersView$',
  currentOrganization$,
  orgUsers$,
  roles$,
  manageUsers$,
  privileges$
).pipe(map(toOrgMembersViewStream));

export default orgMembersView$;
