import { actionCreator } from '@ardoq/rxbeach';
import { Position } from 'components/WorkspaceHierarchies/types';
import { ReferenceDirection } from '@ardoq/graph';

export type StartCreateReferencePayload = {
  event: React.MouseEvent<Element, MouseEvent> | Event | null;
  linkSourceIds: string[];
  linkSourceNodeId?: string;
  startPosition?: Position;
  refDirection?: ReferenceDirection;
  isStartInNavigator?: boolean;
};

export const startCreateReferences = actionCreator<StartCreateReferencePayload>(
  '[createReferenceActions] START_CREATE_REFERENCE'
);

export const createReferencesMouseMove = actionCreator<MouseEvent>(
  '[createReferenceActions] CREATE_REFERENCE_MOUSE_MOVE'
);

export const createReferencesClick = actionCreator<MouseEvent>(
  '[createReferenceActions] CREATE_REFERENCE_CLICK'
);

export const createReferencesKeydown = actionCreator<KeyboardEvent>(
  '[createReferenceActions] CREATE_REFERENCE_KEYDOWN'
);

export type CreateReferenceSourceChangedPayload = {
  startPosition: Position;
  hasLinkTarget: boolean;
  shouldShowRefDrawing: boolean;
  navigatorContainerBox: DOMRect;
};

export const createReferenceSourceChanged =
  actionCreator<CreateReferenceSourceChangedPayload>(
    '[createReferenceActions] CREATE_REFERENCE_SOURCE_CHANGED'
  );

export const startedCreatingReferences = actionCreator<AbortSignal>(
  '[createReferenceActions] STARTED_CREATING_REFERENCES'
);
