import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { setOrgTab } from './actions';
import { ManageOrganizationRoute, OrgTabs } from './types';

const defaultState: ManageOrganizationRoute = {
  manageOrgTab: OrgTabs.SETTINGS,
};

const handleNavigateToManageOrganization = (
  currentState: ManageOrganizationRoute,
  manageOrgTab: OrgTabs
) => ({
  ...currentState,
  manageOrgTab,
});

const manageOrganizationNavigation$ = persistentReducedStream(
  'manageOrganizationNavigation$',
  defaultState,
  [reducer(setOrgTab, handleNavigateToManageOrganization)]
);

export default manageOrganizationNavigation$;
