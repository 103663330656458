import { connect } from '@ardoq/rxbeach';
import { IPAllowlistingStreamShape, IPAllowlistingTab } from './types';
import AllowlistEntryForm from './AllowlistEntryForm';
import AllowlistEntryList from './AllowlistEntryList';
import AllowlistBlockedMessageForm from './AllowlistBlockedMessageForm';
import { AqLayout, Box, Stack } from '@ardoq/layout';
import {
  AllowlistStatus,
  IPAlert,
  IPTab,
  IPTabDescription,
  IPTabHeader,
} from './atoms';
import { ipTabMetadata } from './consts';
import ipAllowlist$ from './ipAllowlist$';
import { Island } from '@ardoq/page-layout';
import { Features, hasFeature } from '@ardoq/features';
import { Header2, Text } from '@ardoq/typography';

type ActiveTabProps = Pick<IPAllowlistingStreamShape, 'activeTab'>;

const getTabProps = ({ activeTab }: ActiveTabProps) =>
  Object.entries(ipTabMetadata).map(([id, { tabLabel }]) => ({
    id: id as IPAllowlistingTab,
    label: tabLabel,
    isActive: activeTab === id,
  }));

const Content = ({ activeTab }: ActiveTabProps) => {
  switch (activeTab) {
    case 'CREATE':
      return <AllowlistEntryForm />;
    case 'MANAGE':
      return <AllowlistEntryList />;
    case 'STATUS':
      return <AllowlistStatus />;
    case 'BLOCKED_MESSAGE':
      return <AllowlistBlockedMessageForm />;
    default:
      return <>Missing content for tab {activeTab}</>;
  }
};

const ManageIPAllowlist = ({ alert, activeTab }: IPAllowlistingStreamShape) => {
  return (
    <Island bodySpacing="none">
      <AqLayout
        headerBody={
          // HACK - Rafaa - Temporary until we can Merge the new Navigation pattern.
          !hasFeature(Features.PERMISSION_ZONES_INTERNAL) && (
            <Box paddingTop="small" paddingBottom="small">
              <Stack gap="xsmall" align="flex-start" justify="flex-start">
                <Header2 color="textModerate">IP allowlisting</Header2>
                <Text color="textSubtle">
                  Control access by allowing only specific IP addresses to enter
                  Ardoq.
                </Text>
              </Stack>
            </Box>
          )
        }
        renderLeftSidebar={() => (
          <>
            {getTabProps({ activeTab }).map(props => (
              <IPTab {...props} key={props.id} />
            ))}
          </>
        )}
      >
        <IPTabHeader activeTab={activeTab} />
        <IPTabDescription activeTab={activeTab} />
        <IPAlert alert={alert} />
        <Content activeTab={activeTab} />
      </AqLayout>
    </Island>
  );
};

export default connect(ManageIPAllowlist, ipAllowlist$);
