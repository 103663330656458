import { FieldsRow } from '@ardoq/forms';
import { DangerButton } from '@ardoq/button';
import { Select, SelectOption } from '@ardoq/select';
import { Triple } from '@ardoq/api-types';
import styled from 'styled-components';
import { TripleErrorObject } from '../types';

type MetamodelTripleEditorProps = {
  triple: Triple;
  sourceComponentTypeOptions: SelectOption<string>[];
  targetComponentTypeOptions: SelectOption<string>[];
  referenceTypeOptions: SelectOption<string>[];
  onSourceComponentTypeChange: (sourceComponentType: string | null) => void;
  onReferenceTypeChange: (referenceType: string | null) => void;
  onTargetComponentTypeChange: (targetComponentType: string | null) => void;
  onDelete: () => void;
  errors: TripleErrorObject;
};

const AlignedFieldsRow = styled(FieldsRow)`
  align-items: flex-start;
`;

const AlignedDeleteButton = styled(DangerButton)`
  align-self: center;
`;

const getSourceComponentTypeErrorMessage = (
  errors: MetamodelTripleEditorProps['errors'],
  sourceComponentTypeName: string
) => {
  return errors.hasDeletedSourceComponentType
    ? `"${sourceComponentTypeName}" component type was removed from Entity Group`
    : undefined;
};

const getTargetComponentTypeErrorMessage = (
  errors: MetamodelTripleEditorProps['errors'],
  sourceComponentTypeName: string
) => {
  if (errors.hasDeletedTargetComponentType)
    return `"${sourceComponentTypeName}" component type was removed from Entity Group`;
  if (errors.hasTargetComponentTypeOutOfSourceComponentTypeWorkspace)
    return `"${sourceComponentTypeName}" component type is missing in Source Component Type Workspace`;
  return undefined;
};

const getReferenceTypeErrorMessage = (
  errors: MetamodelTripleEditorProps['errors'],
  referenceTypeName: string
) => {
  if (errors.hasDeletedReferenceType)
    return `"${referenceTypeName}" reference type was removed from Entity Group`;
  if (errors.hasReferenceOutOfSourceComponentTypeWorkspace)
    return `"${referenceTypeName}" reference type is missing in Source Component Type Workspace`;
  return undefined;
};

const MetamodelTripleEditor = ({
  triple,
  referenceTypeOptions,
  sourceComponentTypeOptions,
  targetComponentTypeOptions,
  onSourceComponentTypeChange,
  onReferenceTypeChange,
  onTargetComponentTypeChange,
  onDelete,
  errors,
}: MetamodelTripleEditorProps) => {
  const [sourceComponentType, referenceType, targetComponentType] = triple;
  return (
    <AlignedFieldsRow>
      <Select<string>
        options={sourceComponentTypeOptions}
        label="Source Component type"
        value={sourceComponentType ?? ''}
        onValueChange={onSourceComponentTypeChange}
        errorMessage={getSourceComponentTypeErrorMessage(
          errors,
          sourceComponentType
        )}
      />
      <Select<string>
        options={referenceTypeOptions}
        label="Reference type"
        value={referenceType ?? ''}
        onValueChange={onReferenceTypeChange}
        isDisabled={!sourceComponentType}
        popoverContent={
          !sourceComponentType ? 'Select Source component type first' : null
        }
        errorMessage={getReferenceTypeErrorMessage(errors, referenceType)}
      />
      <Select<string>
        options={targetComponentTypeOptions}
        label="Target Component type"
        value={targetComponentType ?? ''}
        onValueChange={onTargetComponentTypeChange}
        isDisabled={!referenceType}
        popoverContent={!referenceType ? 'Select Reference type first' : null}
        errorMessage={getTargetComponentTypeErrorMessage(
          errors,
          targetComponentType
        )}
      />
      <AlignedDeleteButton onClick={onDelete}>Delete</AlignedDeleteButton>
    </AlignedFieldsRow>
  );
};

export default MetamodelTripleEditor;
