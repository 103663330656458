import { actionCreator } from '@ardoq/rxbeach';
import { ConfigurationState, ComponentType } from '../types';

const NAMESPACE = '[integrations] SIGNAVIO_EXPORTER_CONFIGURATION';

export const updateComponentTypesKeys = actionCreator<string[]>(
  `${NAMESPACE}_UPDATE_COMPONENT_TYPES_KEYS`
);

export type UpdateComponentTypePropsPayload = {
  componentType: string;
  props: Partial<ComponentType>;
};

export const updateComponentTypeProps =
  actionCreator<UpdateComponentTypePropsPayload>(
    `${NAMESPACE}_UPDATE_COMPONENT_TYPE_PROPS`
  );

export const fetchQueryResults = actionCreator<{
  componentType: keyof ConfigurationState['componentTypes'];
}>(`${NAMESPACE}_FETCH_QUERY_RESULTS`);

export const resetConfig = actionCreator(`${NAMESPACE}_RESET_CONFIG`);

type UpdateConfigPayload = Partial<ConfigurationState>;
export const updateConfigState = actionCreator<UpdateConfigPayload>(
  `${NAMESPACE}_UPDATE_CONFIG_PROP`
);

export type UpdateConfigResultsPayload = Partial<ConfigurationState['results']>;
export const updateConfigResults = actionCreator<UpdateConfigResultsPayload>(
  `${NAMESPACE}_UPDATE_CONFIG_RESULTS`
);
