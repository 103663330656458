import {
  SubdivisionsContext,
  subdivisionsOperations,
} from '@ardoq/subdivisions';
import { membershipOperations, MembershipsState } from './membershipOperations';
import { ManageMembershipTableData, RelevantRootComponent } from './types';
import { ArdoqId } from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';

const createRow = ({
  component,
  membershipsState,
  subdivisionsContext,
}: {
  component: RelevantRootComponent;
  membershipsState: MembershipsState;
  subdivisionsContext: SubdivisionsContext;
}): ManageMembershipTableData => ({
  _id: component._id,
  component,
  memberships: membershipOperations
    .previewComponentMemberships(membershipsState, component._id)
    .map((subdivisionId: ArdoqId) => {
      return subdivisionsOperations.getSubdivisionById(
        subdivisionsContext,
        subdivisionId
      );
    })
    .filter(ExcludeFalsy),
});

const createTableData = ({
  components,
  membershipsState,
  subdivisionsContext,
}: {
  components: RelevantRootComponent[];
  membershipsState: MembershipsState;
  subdivisionsContext: SubdivisionsContext;
}): ManageMembershipTableData[] =>
  components.map(component =>
    createRow({
      component,
      membershipsState,
      subdivisionsContext,
    })
  );

const isLastRow = (
  tableData: ManageMembershipTableData[],
  tableRowData: ManageMembershipTableData
): boolean | null => {
  if (tableData.length === 0) return null;
  const componentId = getRowId(tableRowData);
  return tableData[tableData.length - 1]._id === componentId;
};

const getRowId = (tableData: ManageMembershipTableData): ArdoqId => {
  return tableData._id;
};

export const manageMembershipTableDataOperations = {
  createRow,
  createTableData,
  isLastRow,
  getRowId,
};
