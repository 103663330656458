import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { Texts } from './types';
import { ScheduleState } from 'integrations/common/streams/schedule/schedule$';

type ScheduleStage = ScheduleState['scheduleStage'];

export function getTransferSuccessTexts(params: {
  transferDirection: TransferDirection;
  scheduleStage: ScheduleStage;
  integrationName: string;
  affectedWorkspaces: string[];
  onOpenWorkspaces: () => void;
  onOpenIntegration: () => void;
  onLinkClicked?: () => void;
}) {
  const {
    transferDirection,
    scheduleStage,
    integrationName,
    onOpenWorkspaces,
    onOpenIntegration,
    onLinkClicked,
  } = params;
  const transferSuccessTexts: Record<
    TransferDirection,
    Record<ScheduleStage, Texts>
  > = {
    import: {
      noScheduleAllowed: {
        title: 'The data is imported!',
        message: 'Open workspace to reload Ardoq and see your new imports.',
        buttonProps: {
          primary: {
            text: 'Open workspaces',
            onClick: onOpenWorkspaces,
          },
          secondary: {
            text: '',
          },
        },
      },
      toBeScheduled: {
        title: 'The data is imported!',
        message:
          'Continue to create a schedule of the import. Or open workspace to see your data.',
        buttonProps: {
          primary: {
            text: 'Open workspace',
            onClick: onOpenWorkspaces,
          },
          secondary: {
            text: '',
          },
        },
      },
      scheduled: {
        title: 'The import is scheduled!',
        message:
          'Open workspace to reload Ardoq and see your new imports. Or close the importer to go back to what you were working on.',
        buttonProps: {
          primary: {
            text: 'Open workspace',
            onClick: onOpenWorkspaces,
          },
          secondary: {
            text: `Back to ${integrationName}`,
            onClick: onOpenIntegration,
          },
        },
      },
    },
    export: {
      noScheduleAllowed: {
        title: 'Your data is successfully exported!',
        message: `Open ${integrationName} and see your new exports. Or close the exporter to go back to what you were working on.`,
        buttonProps: {
          primary: {
            text: `Open ${integrationName}`,
            onClick: onLinkClicked,
            isLink: true,
          },
          secondary: {
            text: '',
          },
        },
      },
      toBeScheduled: {
        title: 'Your data is successfully exported!',
        message: 'Continue to create a schedule of the export.',
        buttonProps: {
          primary: {
            text: `Open ${integrationName}`,
            onClick: onLinkClicked,
            isLink: true,
          },
          secondary: {
            text: '',
          },
        },
      },
      scheduled: {
        title: 'The export is scheduled!',
        message: `Open ${integrationName} and see your new exports. Or close the exporter to go back to what you were working on.`,
        buttonProps: {
          primary: {
            text: `Open ${integrationName}`,
            onClick: onLinkClicked,
            isLink: true,
          },
          secondary: {
            text: `Back to ${integrationName}`,
            onClick: onOpenIntegration,
          },
        },
      },
    },
  };
  return transferSuccessTexts[transferDirection][scheduleStage];
}
