import { ButtonWithDropdown, DropdownItem } from '@ardoq/dropdown-menu';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { ButtonGroup, ButtonType, SecondaryButton } from '@ardoq/button';
import { CopyURLButton, ReportReaderLocation } from '@ardoq/report-reader';
import { ArdoqId } from '@ardoq/api-types';
import { EditIcon } from '@ardoq/icons';
import { ToolbarDivider } from '@ardoq/page-layout';

export type ExportReportButtonProps = {
  options: DropdownItem[];
};
export const ExportReportButton = (props: ExportReportButtonProps) => (
  <ButtonWithDropdown
    buttonType={ButtonType.BRAND}
    data-tooltip-text="Export"
    alignMenuTo="left"
    dropdownSize={DropdownSize.AUTO}
    {...props}
  >
    Export to
  </ButtonWithDropdown>
);

export type MoreMenuButtonProps = {
  options: DropdownItem[];
};
export const MoreMenuButton = (props: MoreMenuButtonProps) => (
  <ButtonWithDropdown
    buttonType={ButtonType.SECONDARY}
    dataTestId="report-reader-more-button"
    dropdownSize={DropdownSize.AUTO}
    {...props}
  >
    More
  </ButtonWithDropdown>
);

export type ToolbarButtonProps = {
  copyUrlButtonProps: {
    reportId: ArdoqId;
    trackCopyReportURLButton: (location: ReportReaderLocation) => void;
  };
  editButtonProps: {
    isDisabled: boolean;
    onClick: VoidFunction;
  };
};

export const ToolbarButtons = (props: ToolbarButtonProps) => (
  <ButtonGroup>
    <CopyURLButton hasCopyArdoqURLOption {...props.copyUrlButtonProps} />
    <SecondaryButton {...props.editButtonProps}>
      <EditIcon />
      Edit
    </SecondaryButton>
    <ToolbarDivider />
  </ButtonGroup>
);

export type NavBarButtonProps = {
  exportButtonProps: ExportReportButtonProps;
  moreMenuButtonProps: MoreMenuButtonProps;
  toolbarButtonProps: ToolbarButtonProps;
};
