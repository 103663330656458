import styled from 'styled-components';
import { Space } from '@ardoq/style-helpers';
import { header2, text1 } from '@ardoq/typography';
import { colors } from '@ardoq/design-tokens';

export const SubTitle = styled(Space)`
  ${header2};
`;

export const PrivacyContainer = styled(Space).attrs({ $gap: 's8' })`
  ${text1};
  color: ${colors.grey50};
`;

export const SelectorContainer = styled.div`
  max-width: 540px;
`;

export const SelectContainer = styled(Space).attrs({
  $gap: 's8',
  $isVertical: true,
})`
  max-width: 540px;
`;
