import { dispatchAction } from '@ardoq/rxbeach';
import { NavigatorLayoutState, Position } from '../types';
import { distinctUntilChanged, filter, map, Observable, tap } from 'rxjs';
import { logError } from '@ardoq/logging';
import { NodeModel } from '../models/types';
import { HEIGHT_COMPONENT_ITEM } from '../utils/consts';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { isEqual } from 'lodash';
import { createReferenceSourceChanged } from 'createReferences2024/createReferencesActions';

type NavigatorLayoutStateHasWithLinkSource = Omit<
  NavigatorLayoutState,
  'container | linkSourceNode'
> & {
  container: HTMLElement;
  linkSourceNode: NodeModel;
};

const isNavigatorLayoutStateHasWithLinkSource = (
  state: NavigatorLayoutState
): state is NavigatorLayoutStateHasWithLinkSource =>
  Boolean(
    state.container && state.linkSourceNode && state.linkSourceStartPosition
  );

export const getUpdateReferencesCreationRoutine = (
  navigator$: Observable<NavigatorLayoutState>
) =>
  navigator$.pipe(
    filter(isNavigatorLayoutStateHasWithLinkSource),
    map(
      ({ rootLayoutBox, container, linkSourceNode, shouldShowRefDrawing }) => {
        const navigatorContainerBox = container.getBoundingClientRect();
        const startLayoutBox = rootLayoutBox.layoutMap
          .get(linkSourceNode.id)
          ?.toViewportCoordinates(container, navigatorContainerBox);
        if (!startLayoutBox) {
          logError(Error('No layout box for link source node'));
          return null;
        }
        const startPosition = {
          x:
            navigatorContainerBox.left +
            navigatorContainerBox.width -
            HEIGHT_COMPONENT_ITEM / 2,
          y: startLayoutBox.top + HEIGHT_COMPONENT_ITEM / 2,
        };
        return {
          startPosition,
          shouldShowRefDrawing,
          navigatorContainerBox: container.getBoundingClientRect(),
        };
      }
    ),
    filter<{
      startPosition: Position;
      shouldShowRefDrawing: boolean;
      navigatorContainerBox: DOMRect;
    } | null>(ExcludeFalsy),
    distinctUntilChanged(isEqual),
    tap(state => dispatchAction(createReferenceSourceChanged(state)))
  );
