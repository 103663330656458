import { PersonalSetting } from '@ardoq/api-types';
import { ConfirmResult, ModalSize } from '@ardoq/modal';
import { confirm } from '@ardoq/modal';
import currentUser from 'models/currentUser';

export const confirmCloseUnsavedViewpoint = async () => {
  const isSuppressConfirmDialog = currentUser.getPersonalSetting(
    PersonalSetting.DONT_SHOW_REMOVE_DATASET_CONFIRMATION
  );
  if (isSuppressConfirmDialog) return true;

  const { rememberAction, confirmed } = (await confirm({
    title: 'Close dataset?',
    text: (
      <>
        <p>You can hide this dataset instead of closing it.</p>
        <p>
          Remember to save it as Viewpoint or to a presentation before it is
          closed.
        </p>
      </>
    ),
    confirmButtonTitle: 'Close dataset',
    confirmButtonClickId: 'close-dataset-modal-confirm',
    confirmButtonDataTestId: 'close-dataset-modal-confirm',
    cancelButtonTitle: 'Cancel',
    cancelButtonClickId: 'close-dataset-modal-cancel',
    cancelButtonDataTestId: 'close-dataset-modal-cancel',
    showRememberActionCheckbox: true,
    rememberActionCustomCheckboxText: `Don't show this again`,
    modalSize: ModalSize.S,
  })) as ConfirmResult;

  if (rememberAction) {
    currentUser.setPersonalSetting(
      PersonalSetting.DONT_SHOW_REMOVE_DATASET_CONFIRMATION,
      true
    );
  }

  return confirmed;
};
