import { ModalSize } from '@ardoq/modal';
import { IconDecorator } from '@ardoq/decorators';
import { IconName } from '@ardoq/icons';
import { confirm } from '@ardoq/modal';
import styled from 'styled-components';
import { text1 } from '@ardoq/typography';
import { WithPopover } from '@ardoq/popovers';
import { ArdoqId } from '@ardoq/api-types';
import { FlexBox, Stack } from '@ardoq/layout';

const FooterTextBox = styled(FlexBox).attrs({
  paddingLeft: 'xlarge',
  paddingRight: 'xlarge',
  paddingBottom: 'medium',
})`
  ${text1}
`;

const confirmLoadUseCase = (
  content: Array<{
    label: string;
    iconName?: IconName;
    count: number;
    names: Array<{ _id: ArdoqId; name: string }>;
  }>
) =>
  confirm({
    modalSize: ModalSize.S,
    title: 'Add solution material?',
    text: `Ardoq solutions come with pre-made assets that help you get started and gain value faster.
    The assets below will be added to your organization within the Solution Materials folder.`,
    confirmButtonTitle: 'Add solution materials',
    body: (
      <>
        <FlexBox
          gap="medium"
          paddingLeft="xlarge"
          paddingRight="xlarge"
          paddingTop="medium"
          paddingBottom="medium"
          flexWrap
        >
          {content.map(({ label, iconName, count, names }) => (
            <IconDecorator iconName={iconName} key={label}>
              <WithPopover
                content={
                  names.length
                    ? () => (
                        <Stack>
                          {names.map(({ name }) => (
                            <IconDecorator iconName={iconName} key={name}>
                              {name}
                            </IconDecorator>
                          ))}
                        </Stack>
                      )
                    : undefined
                }
              >
                {label}
              </WithPopover>{' '}
              ({count})
            </IconDecorator>
          ))}
        </FlexBox>
        <FooterTextBox>
          Our solutions guide provides in-depth approaches on how to use these
          assets for achieving your unique business needs.
        </FooterTextBox>
      </>
    ),
  });

export default confirmLoadUseCase;
