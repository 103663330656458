import { getLightenedColor, getShadedColor } from '@ardoq/color-helpers';
import { ComponentSearchData } from 'viewpointBuilder/selectContextTab/types';

const prepareComponentFromSearchData = (component: ComponentSearchData) => {
  const componentColor = component.representationData?.color;

  const lightenedColor = getLightenedColor(componentColor);
  const shadedColor = getShadedColor(componentColor);

  const representationData = {
    ...component.representationData,
    value: component.representationData?.value ?? '',
    shadedColor,
    lightenedColor,
    color: componentColor,
  };

  return {
    id: component.id,
    entityType: component.entityType,
    label: component.name,
    representationData,
  };
};

export const searchTraversalOperations = {
  prepareComponentFromSearchData,
};
