import { ReactNode } from 'react';
import { PrimaryButton, SecondaryButton } from '@ardoq/button';
import {
  AsFileUpload,
  DragOverInfoDoq,
  Dropzone,
  InitialInfoDoq,
  InvalidInfoDoq,
  ProcessingDropzone,
  ProcessingInfoDoq,
  SuccessInfoDoq,
} from '@ardoq/dropzone';
import { noop } from 'lodash';
import { FlexBox } from '@ardoq/layout';
import { UploadInfo } from '../streams/upload/types';
import { startErrorModal } from 'integrations/common/modals/errorModal/ErrorModal';

const UploadButton = ({
  isDisabled = false,
  onFiles = noop,
  children,
}: {
  isDisabled?: boolean;
  onFiles?: (files: File[]) => void;
  children?: ReactNode;
}) => {
  return (
    <AsFileUpload isDisabled={isDisabled} onFiles={onFiles}>
      <PrimaryButton isDisabled={isDisabled}>{children}</PrimaryButton>
    </AsFileUpload>
  );
};

function UploadButtons({
  uploadInfo,
  isDisabled = false,
  onFiles,
  onSecondaryButtonClick,
}: {
  uploadInfo: UploadInfo;
  isDisabled?: boolean;
  onFiles?: (files: File[]) => void;
  onSecondaryButtonClick?: VoidFunction;
}) {
  return (
    <FlexBox gap="medium">
      {uploadInfo.uploadButtonText && (
        <UploadButton onFiles={onFiles} isDisabled={isDisabled}>
          {uploadInfo.uploadButtonText}
        </UploadButton>
      )}
      {uploadInfo.secondaryButtonText && (
        <SecondaryButton
          isDisabled={isDisabled}
          onClick={onSecondaryButtonClick}
        >
          {uploadInfo.secondaryButtonText}
        </SecondaryButton>
      )}
    </FlexBox>
  );
}

type FileDropzoneParams = {
  uploadInfo: UploadInfo;
  onUpload: (data: File) => void;
};

export function FileDropzone({ uploadInfo, onUpload }: FileDropzoneParams) {
  // This function will be moved in future to a stream.
  const onFiles = async (files: File[]) => {
    onUpload(files[0]);
  };

  return (
    <>
      {uploadInfo.status === 'Processing' && (
        <ProcessingDropzone>
          <ProcessingInfoDoq
            title={uploadInfo.title}
            message={uploadInfo.message}
            buttons={<UploadButtons uploadInfo={uploadInfo} isDisabled />}
          />
        </ProcessingDropzone>
      )}
      {uploadInfo.status !== 'Processing' && (
        <Dropzone
          dragOverChildren={
            <DragOverInfoDoq
              buttons={<UploadButtons uploadInfo={uploadInfo} isDisabled />}
            />
          }
          onFiles={onFiles}
        >
          {uploadInfo.status === 'Initial' && (
            <InitialInfoDoq
              title={uploadInfo.title}
              message={uploadInfo.message}
              buttons={
                <UploadButtons uploadInfo={uploadInfo} onFiles={onFiles} />
              }
            />
          )}
          {uploadInfo.status === 'Success' && (
            <SuccessInfoDoq
              title={uploadInfo.title}
              message={uploadInfo.message}
              buttons={
                <UploadButtons uploadInfo={uploadInfo} onFiles={onFiles} />
              }
            />
          )}
          {(uploadInfo.status === 'InvalidFile' ||
            uploadInfo.status === 'Error') && (
            <InvalidInfoDoq
              title={uploadInfo.title}
              message={uploadInfo.message}
              buttons={
                <UploadButtons
                  uploadInfo={uploadInfo}
                  onFiles={onFiles}
                  onSecondaryButtonClick={() =>
                    startErrorModal({
                      title: 'Error',
                      errorMassage: uploadInfo.errorMessage || '',
                    })
                  }
                />
              }
            />
          )}
        </Dropzone>
      )}
    </>
  );
}
