import { InventoryColumnState } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqError, Maybe } from '@ardoq/common-helpers';
import { UpdateComponentFieldValueParam } from '@ardoq/inventory';

export type ComponentFieldValueWasUpdatedPayload = {
  error: ArdoqError;
  updatePayload: UpdateComponentFieldValueParam;
};

export const componentFieldValueWasUpdated = actionCreator<
  Maybe<ComponentFieldValueWasUpdatedPayload>
>(`[inventory] COMPONENT_FIELD_VALUE_WAS_UPDATED`);

export type UpdatedInventoryColumnsPayload = {
  columns: InventoryColumnState[];
};
export const updatedInventoryColumns =
  actionCreator<UpdatedInventoryColumnsPayload>(
    `[inventory] UPDATED_INVENTORY_COLUMNS`
  );
