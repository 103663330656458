import { LabeledDetail } from '../../components/LabeledDetail';
import { StatusType, Tag } from '@ardoq/status-ui';

import { APIOrganizationUser } from '@ardoq/api-types';

type StatusSectionProps = {
  user: APIOrganizationUser;
};
export const StatusSection = ({ user }: StatusSectionProps) => {
  return (
    <LabeledDetail label="Status">
      {user.active ? (
        <Tag statusType={StatusType.SUCCESS}>Active</Tag>
      ) : (
        <Tag statusType={StatusType.WARNING}>Inactive</Tag>
      )}
    </LabeledDetail>
  );
};
