import { dispatchAction } from '@ardoq/rxbeach';
import { chooseBroadcastReminder } from 'broadcasts/actions';
import { Select } from '@ardoq/select';
import { Field } from '@ardoq/forms';

type ScheduleReminderProps = {
  readonly reminderInDays: number | null;
};

const reminderOptions = [
  { value: null, label: 'No reminder' },
  { value: 1, label: '1 day after first schedule' },
  { value: 3, label: '3 days after first schedule' },
  { value: 7, label: '7 days after first schedule' },
  { value: 14, label: '14 days after first schedule' },
];

const ScheduleReminder = ({ reminderInDays }: ScheduleReminderProps) => {
  return (
    <Field
      label="Reminder"
      helperText="If the broadcast is for a survey the reminder will be sent only to people who haven't submitted the survey."
    >
      <Select
        options={reminderOptions}
        value={
          reminderOptions.find(
            ({ value: inNumberOfDays }) => inNumberOfDays === reminderInDays
          ) ?? null
        }
        onChange={option => {
          if (!option) return;
          dispatchAction(
            chooseBroadcastReminder(option.value as number | null)
          );
        }}
        dataTestId="reminder-select"
      />
    </Field>
  );
};

export default ScheduleReminder;
