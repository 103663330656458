import { Tag } from '@ardoq/status-ui';
import { AppliedSettingsRow } from './AppliedSettingsRow';
import {
  ComponentRepresentation,
  ComponentRepresentationProps,
} from '@ardoq/renderers';
import { IconName, IconSize } from '@ardoq/icons';
import { TextOverflow, WithPopover } from '@ardoq/popovers';
import { Space } from '@ardoq/style-helpers';
import { ContextComponentTypeSettings } from './types';
import styled from 'styled-components';
import { normal14 } from '@ardoq/typography';
import { useState } from 'react';
import { RepresentationData } from '@ardoq/data-model';
import { tagStyles } from './atoms';

const StyledComponentRepresentation = styled(ComponentRepresentation)`
  margin-left: 0;
`;

const StyledSpace = styled(Space)`
  max-width: 120px;
  height: 18px;
  align-items: center;
  ${normal14};
  ${tagStyles};
  padding: 0;
`;

type ComponentTagProps = {
  contextComponentType: string;
  representationData: RepresentationData | ComponentRepresentationProps;
};

const ComponentTag = ({
  contextComponentType,
  representationData,
}: ComponentTagProps) => {
  const [isOverflowed, setIsOverflowed] = useState(false);

  const popoverContent = (
    <Space $gap="s8" $align="center" style={{ maxWidth: '270px' }}>
      {representationData.icon && (
        <StyledComponentRepresentation
          iconSize={IconSize.SMALL}
          {...representationData}
        />
      )}
      {contextComponentType}
    </Space>
  );

  return (
    <Tag>
      <WithPopover content={() => (isOverflowed ? popoverContent : null)}>
        <StyledSpace $gap="s4">
          {representationData.icon && (
            <StyledComponentRepresentation
              iconSize={IconSize.SMALL}
              {...representationData}
              // Ignore icon color
              style={undefined}
            />
          )}
          <TextOverflow renderIn={null} onEllipsisChange={setIsOverflowed}>
            {contextComponentType}
          </TextOverflow>
        </StyledSpace>
      </WithPopover>
    </Tag>
  );
};

type Props = {
  settings: ContextComponentTypeSettings;
};
export const ContextComponentType = ({ settings }: Props) => {
  const { contextComponentType, representationData } = settings;

  if (!contextComponentType || !representationData) {
    return null;
  }

  const tag = (
    <ComponentTag
      key="ContextComponentType"
      contextComponentType={contextComponentType}
      representationData={representationData}
    />
  );

  return (
    <AppliedSettingsRow
      label="Context component type"
      tags={[tag]}
      icon={IconName.API}
      isDisabled={!contextComponentType}
    />
  );
};
