import { ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { PrimaryButton, SecondaryButton } from '@ardoq/button';
import {
  createPermissionGroupSuccess,
  createPermissionGroupFailed,
  createPermissionGroup,
  setFormFieldValue,
  formValidationFailed,
} from './actions';
import createPermissionGroupViewModel$ from './createPermissionGroupViewModel$';
import { FlexBox } from '@ardoq/layout';
import type { CreatePermissionGroup$State } from './types';
import CreatePermissionGroupForm from './CreatePermissionGroupForm';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { createPermissionGroupOperations } from './createPermissionGroupOperations';
import { noop } from 'lodash';
import { PermissionGroupPageEvents } from '../../types';

type CreatePermissionGroupModalProps = CreatePermissionGroup$State & {
  closeModal: () => void;
  existingPermissionGroupNames: string[];
  trackUserEvent?: <T extends object>(
    event: PermissionGroupPageEvents,
    meta?: T
  ) => void;
};

const CreatePermissionGroupModal = ({
  closeModal,
  apiErrorMessage,
  isSubmitting,
  formValues,
  formErrors,
  displayFieldErrors,
  existingPermissionGroupNames,
  trackUserEvent = noop,
}: CreatePermissionGroupModalProps) => {
  const formValidation = createPermissionGroupOperations.validateForm(
    formValues,
    existingPermissionGroupNames
  );

  const handleSubmit = async () => {
    if (isSubmitting) return;

    if (!formValidation.isValid) {
      dispatchAction(
        formValidationFailed({
          name: formValidation.errorMessages.name,
          users: formValidation.errorMessages.users,
        })
      );
      return;
    }

    const result = await dispatchActionAndWaitForResponse(
      createPermissionGroup(formValues),
      createPermissionGroupSuccess,
      createPermissionGroupFailed
    );
    trackUserEvent(PermissionGroupPageEvents.CREATE_NEW_GROUP, {
      success: result.type === createPermissionGroupSuccess.type,
      usersCount: formValues.users.length,
    });
    if (result.type === createPermissionGroupSuccess.type) {
      closeModal();
    }
  };

  return (
    <ModalTemplate
      headerText="Create new permission group?"
      footerContent={
        <FlexBox gap="medium">
          <SecondaryButton
            onClick={() => {
              trackUserEvent(
                PermissionGroupPageEvents.CLOSE_CREATE_GROUP_MODAL
              );
              closeModal();
            }}
          >
            Close
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSubmit}
            isLoading={isSubmitting}
            isDisabled={isSubmitting || !formValidation.isValid}
          >
            Create
          </PrimaryButton>
        </FlexBox>
      }
      modalSize={ModalSize.S}
      onCloseButtonClick={() => {
        trackUserEvent(PermissionGroupPageEvents.CLOSE_CREATE_GROUP_MODAL);
        closeModal();
      }}
    >
      <ModalLayout>
        <CreatePermissionGroupForm
          apiErrorMessage={apiErrorMessage}
          displayFieldErrors={displayFieldErrors}
          formErrors={formErrors}
          formValues={formValues}
          onChange={value => dispatchAction(setFormFieldValue(value))}
          onSubmit={handleSubmit}
          trackUserEvent={trackUserEvent}
        />
      </ModalLayout>
    </ModalTemplate>
  );
};

export default connect(
  CreatePermissionGroupModal,
  createPermissionGroupViewModel$
);
