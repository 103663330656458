import { LEFT_SIDEBAR_WIDTH } from '@ardoq/sidebar-menu';
import { Box, FlexBox } from '@ardoq/layout';
import { PropsWithChildren } from 'react';
import { WidthAdjustedStack } from './atoms';
import ChangeApprovalSidebar from './ChangeApprovalSidebar';

export const WrapperWithSidebar = ({ children }: PropsWithChildren) => {
  return (
    <FlexBox gap="medium" width="full" flex={1}>
      <ChangeApprovalSidebar />
      <WidthAdjustedStack $width={`calc(100% - ${LEFT_SIDEBAR_WIDTH}px)`}>
        <Box padding="medium">{children}</Box>
      </WidthAdjustedStack>
    </FlexBox>
  );
};
