import { DateDecorator, IconDecorator } from '@ardoq/decorators';
import { IconName } from '@ardoq/icons';
import { SortByFn, SortById } from '@ardoq/pagination';
import { CellPlaceholder, Column } from '@ardoq/table';
import {
  APIOrganizationUser,
  ArdoqId,
  OrgAccessLevel,
  SortOrder,
} from '@ardoq/api-types';
import { StatusType, Tag } from '@ardoq/status-ui';
import { EditMemberIcon } from './atoms';
import { trackOrgMembersView } from './utils';
import { TrackOrgMembersViewUserEvents } from './types';
import { Link } from '@ardoq/typography';
import { capitalize, ExcludeFalsy } from '@ardoq/common-helpers';
import { TextOverflow } from '@ardoq/popovers';
import { colors } from '@ardoq/design-tokens';

const StatusTag = ({ isActive }: { isActive: boolean }) => (
  <Tag
    iconName={isActive ? IconName.CHECK_MARK : IconName.INFO}
    statusType={isActive ? StatusType.SUCCESS : StatusType.INFO}
    label={isActive ? 'Activated' : 'Pending'}
  />
);

const getNameColumn = (): Column<APIOrganizationUser> => ({
  title: 'Name',
  dataIndex: 'name',
  valueRender: (name: string) => {
    return (
      <TextOverflow lineClamp={2}>
        <Link
          style={{ width: '100%', display: 'block', lineHeight: '1.4' }}
          hideIcon
          underlineOnHover
          type="secondary"
          typography="text1"
          onClick={() => {}}
        >
          {name}
        </Link>
      </TextOverflow>
    );
  },
});

const getEmailColumn = (): Column<APIOrganizationUser> => ({
  title: 'Email',
  dataIndex: 'email',
  valueRender: (email: string) => {
    return <IconDecorator iconName={IconName.MAIL}>{email}</IconDecorator>;
  },
});

const getUserRoleColumn = (
  roleTitleByLabel: Partial<Record<OrgAccessLevel, string>>
): Column<APIOrganizationUser> => ({
  title: 'User Role',
  dataIndex: 'role',
  valueRender: (roleLabel: OrgAccessLevel) =>
    capitalize(roleTitleByLabel[roleLabel] ?? roleLabel),
});

const getSessionsColumn = (): Column<APIOrganizationUser> => ({
  title: 'Sessions',
  dataIndex: 'sessions',
});

const getLastLoggedInColumn = (): Column<APIOrganizationUser> => ({
  title: 'Last logged in',
  dataIndex: 'last-login',
  valueRender: (lastLogin: string) => {
    return lastLogin ? (
      <DateDecorator format="DATETIME" date={lastLogin} />
    ) : (
      <CellPlaceholder placeholder="Not Available"></CellPlaceholder>
    );
  },
});

const getStatusColumn = (): Column<APIOrganizationUser> => ({
  title: 'Status',
  dataIndex: 'active',
  valueRender: (active: boolean) => {
    return <StatusTag isActive={active} />;
  },
});

const getEditButtonColumn = (
  hasConfigurablePrivileges: boolean,
  currentUserCanConfigurePrivileges: boolean,
  hasInternalUserManagement: boolean,
  selectedUsers: APIOrganizationUser[]
): Column<APIOrganizationUser> | false => {
  const isAnythingConfigurable =
    hasInternalUserManagement || hasConfigurablePrivileges;
  return (
    currentUserCanConfigurePrivileges &&
    isAnythingConfigurable && {
      dataIndex: '_id',
      headerStyle: {
        position: 'sticky',
        right: 0,
        backgroundColor: colors.bgDefault,
      },
      cellStyle: {
        position: 'sticky',
        right: 0,
        backgroundColor: colors.bgDefault,
      },
      valueRender: (_userId: ArdoqId, user: APIOrganizationUser) => (
        <EditMemberIcon
          hasConfigurablePrivileges={hasConfigurablePrivileges}
          user={user}
          selectedUsers={selectedUsers}
          hasInternalUserManagement={hasInternalUserManagement}
        />
      ),
    }
  );
};

export const getUsersTableColumns = <T,>(
  hasConfigurablePrivileges: boolean,
  currentUserCanConfigurePrivileges: boolean,
  hasInternalUserManagement: boolean | null,
  showSCIM: boolean,
  selectedUsers: APIOrganizationUser[],
  roleTitleByLabel: Partial<Record<OrgAccessLevel, string>>,
  sortBy: SortByFn<T>,
  sortById: SortById,
  sortOrder: SortOrder
): Column<APIOrganizationUser>[] =>
  [
    getNameColumn(),
    getEmailColumn(),
    getUserRoleColumn(roleTitleByLabel),
    getSessionsColumn(),
    getLastLoggedInColumn(),
    showSCIM && getStatusColumn(),
    getEditButtonColumn(
      hasConfigurablePrivileges,
      currentUserCanConfigurePrivileges,
      Boolean(hasInternalUserManagement),
      selectedUsers
    ),
  ]
    .filter(ExcludeFalsy)
    .map(({ dataIndex, headerStyle, ...rest }) => ({
      onHeaderClick: () => {
        trackOrgMembersView(TrackOrgMembersViewUserEvents.SORT_USERS, {
          sortBy: dataIndex,
        });
        sortBy(dataIndex!);
      },
      sortOrder: sortById === dataIndex && sortOrder,
      dataIndex,
      headerStyle: {
        ...headerStyle,
        whiteSpace: 'nowrap',
      },
      ...rest,
    }));
