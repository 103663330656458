import {
  APIOrganizationUser,
  APIRoleAttributes,
  PermissionGroup,
  Privilege,
} from '@ardoq/api-types';
import { AccessControlPageProps } from 'appContainer/AccessControlPage/types';
import { UserProfileTabs } from '../navigation/types';

export type UserProfilePageShape = {
  user: APIOrganizationUser;
  userPrivileges: Privilege[];
  userGroups: PermissionGroup[];
  userRole: APIRoleAttributes | null;
  activeTabId: UserProfileTabs;
};

export type UserProfilePageProps = UserProfilePageShape & {
  commands: AccessControlPageProps['commands'];
  userAssetsFilterSidebarState: AccessControlPageProps['userAssetsFilterSidebarState'];
  userAssetsOverview: AccessControlPageProps['userAssetsOverview'];
  userAssetsQuery: AccessControlPageProps['userAssetsQuery'];
  locallyFilteredUserAssets: AccessControlPageProps['locallyFilteredUserAssets'];
};

export enum UserProfilePageEvents {
  CLICKED_USER_PROFILE_TAB = 'Clicked User Profile Tab',
  CLICKED_PERMISSION_GROUP = 'Clicked on permission group',
  CLICKED_USER_ROLE = 'Clicked on user role',
  OPENED_USER_PROFILE_PAGE = 'Opened User Profile Page',
  OPENED_ADD_TO_GROUP_MODAL = 'Opened Add To Group Modal',
  CHANGED_FILTER_BY_ASSET_NAME_OR_FOLDER = 'Changed filter by asset name or folder',
  CLICKED_ASSET_TYPE_CHIP = 'Clicked asset type chip',
  CLICKED_PERMISSION_LEVEL_CHIP = 'Clicked permission level chip',
  CLICKED_CLEAR_ALL_FILTERS_CHIP = 'Clicked clear all filters chip',
  CLICKED_CLOSE_ASSET_FILTER_SIDEBAR = 'Clicked close asset filter sidebar',
  CLICKED_OPEN_ASSET_FILTER_SIDEBAR = 'Clicked open asset filter sidebar',
  CLICKED_ASSET_TYPE_CHECKBOX = 'Clicked asset type checkbox',
  CLICKED_PERMISSION_LEVEL_MULTISELECT = 'Clicked permission level multiselect',
  CLICKED_PAGINATION_CONTROL = 'Clicked pagination control',
}
