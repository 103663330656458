import styled from 'styled-components';
import { ChildrenWrapper, IconWrapper, TextWrapper } from './atoms';
import { getColor } from 'utils/typeAppearance';
import { ComponentRepresentation } from '@ardoq/renderers';
import { getComponentTypeRepresentationDataFromComponentType } from 'componentRepresentationData';
import { APIComponentType, ArdoqId } from '@ardoq/api-types';
import { colors } from '@ardoq/design-tokens';
import { ensureContrast } from '@ardoq/color-helpers';
import '@ardoq/design-tokens';

const NODE_BODY_BACKGROUND = colors.grey95;
const NodeBody = styled.div`
  display: inline-flex;
  background-color: ${NODE_BODY_BACKGROUND};
  padding: 5px 8px;
  border-radius: 8px;
  min-width: 200px;
  border: 1px solid ${colors.grey80};
  margin-top: -1px;
  word-break: break-all;
  height: 32px;
`;

type WorkspaceModelNodeProps = {
  componentType: APIComponentType;
  highlightedComponentTypeId?: ArdoqId;
};

const getHighlightColor = (isHighlighted: boolean) =>
  isHighlighted ? colors.yellow60 : colors.grey60;

const getNodeStyle = (
  componentType: APIComponentType,
  highlightSelected: boolean,
  isHighlighted: boolean
) => ({
  color: ensureContrast(
    NODE_BODY_BACKGROUND,
    (highlightSelected
      ? getHighlightColor(isHighlighted)
      : getColor(componentType)) || colors.grey25
  ),
});

const WorkspaceModelNode = ({
  componentType,
  highlightedComponentTypeId,
}: WorkspaceModelNodeProps) => {
  const componentRepresentationData =
    getComponentTypeRepresentationDataFromComponentType(componentType);
  const highlightSelected = Boolean(highlightedComponentTypeId);
  const isHighlighted = highlightedComponentTypeId === componentType.id;
  const componentTypeChildren = Object.values(componentType.children);

  return (
    <>
      <NodeBody
        role="listitem"
        aria-label={componentType.name}
        style={getNodeStyle(componentType, highlightSelected, isHighlighted)}
      >
        {componentRepresentationData && (
          <IconWrapper>
            <ComponentRepresentation
              isImage={componentRepresentationData.isImage}
              value={componentRepresentationData.value}
              icon={componentRepresentationData.icon}
            />
          </IconWrapper>
        )}
        <TextWrapper
          data-test-id="metamodel-component-type-name"
          data-tooltip-text={
            componentType.name.length >= 35 ? componentType.name : ''
          }
        >
          {componentType.name}
        </TextWrapper>
      </NodeBody>
      <ChildrenWrapper>
        {componentTypeChildren.map(childComponentType => (
          <WorkspaceModelNode
            key={childComponentType.id}
            componentType={childComponentType}
            highlightedComponentTypeId={highlightedComponentTypeId}
          />
        ))}
      </ChildrenWrapper>
    </>
  );
};

export default WorkspaceModelNode;
