import { ModalSize, ModalTemplate, modal } from '@ardoq/modal';

type ErrorModalComponentProps = {
  title: string;
  errorMassage: string;
};

export const startErrorModal = ({
  title,
  errorMassage,
}: ErrorModalComponentProps) =>
  modal<boolean>(
    () => (
      <ModalTemplate
        headerText={title}
        errorBlockMessage={errorMassage}
        modalSize={ModalSize.S}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
    }
  );
