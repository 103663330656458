import { ButtonGroup, IconButton, GhostButton } from '@ardoq/button';
import { AnimatedChevron, IconName } from '@ardoq/icons';
import { Position } from '@ardoq/drag-and-drop';
import { trackEvent } from 'tracking/tracking';

type SurveyIslandRightContentProps = {
  dragAndDropCardId: string;
  isExpanded: boolean;
  handleOrderChange: (id: string, position: Position) => void;
  setisExpanded: (isExpanded: boolean) => void;
  removeQuestion: () => void;
};

const SurveyIslandRightContent = ({
  dragAndDropCardId,
  isExpanded,
  handleOrderChange,
  setisExpanded,
  removeQuestion,
}: SurveyIslandRightContentProps) => (
  <ButtonGroup>
    <IconButton
      iconName={IconName.ARROW_UPWARD}
      onClick={e => {
        e.stopPropagation();
        handleOrderChange(dragAndDropCardId, 'above');
        trackEvent('Survey builder: move question up');
      }}
      dataTestId="move-up-button"
      data-tooltip-text={'Move up'}
    />
    <IconButton
      iconName={IconName.ARROW_DOWNWARD}
      onClick={e => {
        e.stopPropagation();
        handleOrderChange(dragAndDropCardId, 'below');
        trackEvent('Survey builder: move question down');
      }}
      dataTestId="move-down-button"
      data-tooltip-text={'Move down'}
    />
    <IconButton
      iconName={IconName.CLOSE}
      onClick={e => {
        e.stopPropagation();
        removeQuestion();
        trackEvent('Survey builder: remove question');
      }}
      dataTestId="remove-button"
      data-tooltip-text={'Remove question'}
    />
    <GhostButton
      onClick={e => {
        e.stopPropagation();
        setisExpanded(!isExpanded);
        trackEvent('Survey builder: toggle expand question', {
          toggle: !isExpanded,
        });
      }}
      data-tooltip-text={isExpanded ? 'Collapse' : 'Expand'}
      dataTestId="expand-collapse-button"
    >
      <AnimatedChevron $isUp={isExpanded} />
    </GhostButton>
  </ButtonGroup>
);

export default SurveyIslandRightContent;
