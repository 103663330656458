import { APIPresentationAssetAttributes } from '@ardoq/api-types';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  DeprecationPhasesInPresentationScope,
  OverallPresentationViewInfo,
  PresentationSlideViewInfo,
  SlidePermisionsInPresentationScope,
} from './types';
import { getDeprecatedSlidesInfo } from './util';
import { dateFromTimestamp } from '@ardoq/date-time';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { presentationAccessControlOperations } from 'resourcePermissions/accessControlHelpers/presentations';

export const getMinimumSlideKillDateForPresentation = (
  deprecatedSlides: PresentationSlideViewInfo[]
) => {
  const killDates = deprecatedSlides
    .map(({ killDate, viewStatus }) =>
      viewStatus !== MetaInfoViewStatus.DISCONTINUED ? killDate : null
    )
    .filter(ExcludeFalsy);

  const minimumKillDate = killDates.length
    ? dateFromTimestamp(Math.min(...killDates.map(date => date.getTime())))
    : null;

  return minimumKillDate;
};

type getOveralDeprecationPhaseForPresentationArgs = {
  activePresentation: APIPresentationAssetAttributes;
  deprecatedSlidesInfo?: PresentationSlideViewInfo[];
};
const getOverallPermissionsForPresentation = ({
  activePresentation,
  deprecatedSlidesInfo,
}: getOveralDeprecationPhaseForPresentationArgs) => {
  const { FULL_PERMISSIONS, LIMITED_PERMISSIONS, MIXED_PERMISSIONS } =
    SlidePermisionsInPresentationScope;

  const canEditAllDeprecatedSlides = deprecatedSlidesInfo?.every(
    slideInfo => slideInfo.hasFullAccess
  );

  const canEditPresentation =
    presentationAccessControlOperations.canEditPresentation(
      currentUserInterface.getPermissionContext(),
      activePresentation
    );

  if (deprecatedSlidesInfo?.length) {
    if (canEditPresentation && canEditAllDeprecatedSlides) {
      return FULL_PERMISSIONS;
    } else if (canEditPresentation && !canEditAllDeprecatedSlides) {
      return MIXED_PERMISSIONS;
    }

    return LIMITED_PERMISSIONS;
  }
};

const getDeprecationPhaseCountForSlides = (
  deprecatedSlidesInfo?: PresentationSlideViewInfo[]
) => {
  const result = {
    discontinued: 0,
    soonToBeDiscontinued: 0,
  };
  deprecatedSlidesInfo?.forEach(slideIfo => {
    if (slideIfo.viewStatus === MetaInfoViewStatus.DISCONTINUED) {
      result.discontinued++;
    } else if (
      slideIfo.viewStatus === MetaInfoViewStatus.SOON_TO_BE_DISCONTINUED
    ) {
      result.soonToBeDiscontinued++;
    }
  });
  return result;
};

const getOveralDeprecationPhaseForPresentation = (
  deprecatedSlidesInfo: PresentationSlideViewInfo[]
) => {
  const { DISCONTINUED_ONLY, SOON_TO_BE_DISCONTINUED_ONLY, MIXED_PHASES } =
    DeprecationPhasesInPresentationScope;

  const deprecationPhasesCount =
    getDeprecationPhaseCountForSlides(deprecatedSlidesInfo);

  if (deprecatedSlidesInfo.length) {
    if (deprecationPhasesCount.discontinued === deprecatedSlidesInfo.length) {
      return DISCONTINUED_ONLY;
    } else if (
      deprecationPhasesCount.soonToBeDiscontinued ===
      deprecatedSlidesInfo.length
    ) {
      return SOON_TO_BE_DISCONTINUED_ONLY;
    }
    return MIXED_PHASES;
  }
};

export const getOverallPresentationViewInfo = (
  activePresentation: APIPresentationAssetAttributes
) => {
  const deprecatedSlidesInfo = getDeprecatedSlidesInfo(activePresentation);

  const result = {
    presentation: activePresentation,
    overallDeprecationPhase:
      getOveralDeprecationPhaseForPresentation(deprecatedSlidesInfo),
    overallPermissionsLevel: getOverallPermissionsForPresentation({
      deprecatedSlidesInfo,
      activePresentation,
    }),
  };
  return result;
};

type GetPhaseMessageTextArgs = {
  overallPresentationViewInfo?: OverallPresentationViewInfo;
  isKilldated: boolean;
};

export const getPhaseMessageText = ({
  overallPresentationViewInfo,
  isKilldated,
}: GetPhaseMessageTextArgs) => {
  // isKilldated tells us whther or not a discontinuation countdown has been set for any of the slides in this presentation
  if (
    overallPresentationViewInfo?.overallDeprecationPhase ===
    DeprecationPhasesInPresentationScope.SOON_TO_BE_DISCONTINUED_ONLY
  ) {
    // if killDate is set and the overall phase is SOON_TO_BE_DISCONTINUED_ONLY, do not display the phase message
    return isKilldated
      ? ''
      : `This presentation contains views that will soon be discontinued${
          overallPresentationViewInfo.overallPermissionsLevel ===
          SlidePermisionsInPresentationScope.MIXED_PERMISSIONS
            ? ' and you don’t have access to migrate them all.'
            : ''
        }.`;
  } else if (
    overallPresentationViewInfo?.overallDeprecationPhase ===
    DeprecationPhasesInPresentationScope.DISCONTINUED_ONLY
  ) {
    return `This presentation contains discontinued views${
      overallPresentationViewInfo.overallPermissionsLevel ===
      SlidePermisionsInPresentationScope.MIXED_PERMISSIONS
        ? ' and you don’t have access to migrate them all.'
        : ''
    }.`;
  } else if (
    overallPresentationViewInfo?.overallDeprecationPhase ===
    DeprecationPhasesInPresentationScope.MIXED_PHASES
  ) {
    return isKilldated
      ? 'Some views are discontinued and other are soon to be discontinued'
      : 'This presentation contains views that are discontinued and soon to be discontinued.';
  }
};
