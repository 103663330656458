import { actionCreator } from '@ardoq/rxbeach';
import type {
  DockedGridEditorState,
  GlobalTrackingMetadata,
  PopoutGridEditorState,
} from './types';
import {
  APIComponentAttributes,
  APICurrentUser,
  ArdoqId,
  FilterAttributes,
} from '@ardoq/api-types';
import { ContextShape } from '@ardoq/data-model';
import type { DynamicFilterStates } from 'streams/dynamicFilters/types';
import type { TableDimensionsState } from './observables/tableDimensions';
import { ScopeDataDependenciesWithScopeData } from './types';

export interface PayloadDropdownOpenerSource {
  readonly src: number;
}

export interface PayloadWorkspaceId {
  readonly workspaceId: ArdoqId;
}

export interface PayloadSetDisabledWorkspaces {
  readonly disabledWorkspaces: Set<ArdoqId>;
}

export const gridEditorResetState = actionCreator(
  '[gridEditor2023] RESET_STATE'
);

/**
 * While the standalone Grid Editor app is unmounted (think popout) an instance
 * of gridEditor$ runs in ardoq-front. This action synchronizes these two states
 * so that the new gridEditor$ is caught up with the changes.
 */
export const initGridEditorState = actionCreator<InitGridEditorPayload>(
  '[gridEditor2023] INIT_GRID_EDITOR_STATE'
);

type InitDockedGridEditorPayload = {
  activeFrameId: DockedGridEditorState['activeFrameId'];
  activeViewType: DockedGridEditorState['activeViewType'];
  dockedEditorPaneStyle: DockedGridEditorState['dockedEditorPaneStyle'];
  dockedGridEditorVisibility: DockedGridEditorState['dockedGridEditorVisibility'];
};
type InitPopoutGridEditorPayload = {
  activeFrameId: PopoutGridEditorState['activeFrameId'];
  activeViewType: PopoutGridEditorState['activeViewType'];
};

export type InitGridEditorPayload =
  | InitDockedGridEditorPayload
  | InitPopoutGridEditorPayload;

/**
 * Used to send current user and permissions to the GridEditor as it does not
 * belong with the rest of context.
 */
export const notifyCurrentUserChange = actionCreator<APICurrentUser>(
  '[gridEditor2023] CURRENT_USER_CHANGE'
);

export const notifyContextChange = actionCreator<ContextShape>(
  '[gridEditor2023] CONTEXT_CHANGE'
);

export const notifyGlobalTrackingMetadataChange =
  actionCreator<GlobalTrackingMetadata>(
    '[gridEditor2023] GLOBAL_TRACKING_METADATA_CHANGE'
  );

/**
 * Dispatched when scope data changes, and carries the underlying data that
 * defines scope data.
 */
export const notifyScopeDataChange =
  actionCreator<ScopeDataDependenciesWithScopeData>(
    '[gridEditor2023] SCOPE_DATA_CHANGE'
  );

/**
 * Dispatched when scope data is loaded into backbone and sync streams
 */
export const notifyScopeDataLoaded =
  actionCreator<ScopeDataDependenciesWithScopeData>(
    '[gridEditor2023] SCOPE_DATA_LOADED'
  );
export interface GridEditorNotifyFiltersChangePayload {
  dynamicFilterStates: DynamicFilterStates;
  filters: FilterAttributes[];
}
/**
 * Dispatched from ardoq-front when filters are changed
 */
export const notifyGridEditorFiltersChanged =
  actionCreator<GridEditorNotifyFiltersChangePayload>(
    '[gridEditor2023] FILTERS_CHANGE'
  );
/**
 * Dispatched when filters are loaded into backbone and sync streams
 * in the standalone GridEditor
 */
export const notifyGridEditorFiltersLoaded =
  actionCreator<GridEditorNotifyFiltersChangePayload>(
    '[gridEditor2023] FILTERS_LOADED'
  );

export const showGridEditor = actionCreator(
  '[gridEditor2023] SHOW_GRID_EDITOR'
);

export const hideGridEditor = actionCreator(
  '[gridEditor2023] HIDE_GRID_EDITOR'
);

export const requestPopoutGridEditor = actionCreator(
  '[gridEditor2023] REQUEST_POPOUT'
);

export const notifyPopoutGridEditorOpened = actionCreator(
  '[gridEditor2023] NOTIFY_POPOUT_OPENED'
);

export const notifyOpenPopoutGridEditorFailed = actionCreator(
  '[gridEditor2023] NOTIFY_OPEN_POPOUT_FAILED'
);

export const requestClosePopoutGridEditor = actionCreator(
  '[gridEditor2023] REQUEST_CLOSE_POPOUT'
);

export const notifyGridEditorFirstPaintFinished = actionCreator(
  '[gridEditor2023] FIRST_PAINT'
);

export const gridEditorTableLoaded = actionCreator(
  '[gridEditor2023] GRID_EDITOR_TABLE_LOADED'
);

export const activateDropdownState = actionCreator<PayloadDropdownOpenerSource>(
  '[gridEditor2023] ACTIVATE_DROPDOWN_STATE'
);

export const activateSelectFieldDropdown = actionCreator(
  '[gridEditor2023] ACTIVATE_SELECT_FIELD_DROPDOWN'
);

export const deactivateDropdownState = actionCreator(
  '[gridEditor2023] DEACTIVATE_DROPDOWN_STATE'
);

/* Set the "focus" (eg. z-index) of the docked Grid Editor's iframe */
export const setDockedGridEditorFocus = actionCreator<
  DockedGridEditorState['dockedGridEditorFocus']
>('[gridEditor2023] SET_DOCKED_GRID_EDITOR_FOCUS');

export const setDockedGridEditorStyle = actionCreator<
  DockedGridEditorState['dockedEditorPaneStyle']
>('[gridEditor] DOCKED_EDITOR_STYLE');

export const toggleFullscreen = actionCreator(
  '[gridEditor2023] TOGGLE_FULLSCREEN'
);

export const setActiveViewTypeToComponent = actionCreator(
  '[gridEditor2023] SET_ACTIVE_VIEW_TYPE_COMPONENT'
);

export const setActiveViewTypeToReference = actionCreator(
  '[gridEditor2023] SET_ACTIVE_VIEW_TYPE_REFERENCE'
);

/**
 * Used to load components into the GridEditor's componentHierarchy state
 */
export const notifyGridEditorCreatedComponents = actionCreator<
  APIComponentAttributes[]
>('[gridEditor2023] NOTIFY_GRID_EDITOR_CREATED_COMPONENTS');

export const forceRebuildComponentHierarchy = actionCreator(
  '[gridEditor2023] FORCE_REBUILD_COMPONENT_HIERARCHY'
);

export const toggleShowAllComponents = actionCreator(
  '[gridEditor2023] EDITOR_CONTEXT_MANAGER_SET_IS_SHOW_ALL_COMPONENTS'
);

export const gridEditorFetchOrgWorkspaces = actionCreator(
  '[gridEditor2023] FETCH_ORG_WORKSPACES'
);

export const gridEditorLoadWorkspace = actionCreator<PayloadWorkspaceId>(
  '[gridEditor2023] LOAD_WORKSPACE'
);

export const gridTargetWorkspaceEnable = actionCreator<PayloadWorkspaceId>(
  '[gridEditor2023] GRID_TARGET_WORKSPACE_ENABLE'
);

export const gridTargetWorkspaceDisable = actionCreator<PayloadWorkspaceId>(
  '[gridEditor2023] GRID_TARGET_WORKSPACE_DISABLE'
);

export const gridSetDisabledWorkspaces =
  actionCreator<PayloadSetDisabledWorkspaces>(
    '[gridEditor2023] GRID_SET_DISABLED_WORKSPACES'
  );

/**
 * Initialize hidden/disabled fields in the Grid Editor.
 * This is a separate action than "set" because it should not try to persist the
 * change to user-settings.
 */
export const gridEditorInitDisabledFields =
  actionCreator<DisabledFieldsPayload>(
    '[gridEditor2023] GRID_INIT_DISABLED_FIELDS'
  );

export type DisabledFieldsPayload = { disabledFields: Set<string> };

/**
 * Enable (show) or disable (hide) fields.
 * Use when you want to keep existing disabled fields
 */
export const gridFieldBatchUpdate = actionCreator<BatchUpdateFieldsPayload>(
  '[gridEditor2023] GRID_FIELD_BATCH_UPDATE'
);

export type BatchUpdateFieldsPayload = {
  enable?: string[];
  disable?: string[];
};

/**
 * Enable (show) all displayable fields
 */
export const gridEnableAllFields = actionCreator(
  '[gridEditor2023] GRID_ENABLE_ALL_FIELDS'
);

/**
 * Sort rows by column (eg. field value)
 */
export const requestColumnSort = actionCreator<SetColumnSortPayload>(
  '[gridEditor2023] REQUEST_COLUMN_SORT'
);

export type SetColumnSortPayload = { fieldName: string };

export type SetTableDimensionsPayload = {
  fieldWidths: TableDimensionsState['fieldWidths'];
  rowCount: TableDimensionsState['rowCount'];
  tableWidth: TableDimensionsState['tableWidth'];
};
export const setTableDimensions = actionCreator<SetTableDimensionsPayload>(
  '[gridEditor2023] SET_TABLE_DIMENSIONS'
);

export const setScrollLeft = actionCreator<number>(
  '[gridEditor2023] SET_SCROLL_LEFT'
);

/**
 * Only added for testing purposes
 */
export const resetTableDimensions = actionCreator(
  '[gridEditor2023] RESET_TABLE_DIMENSIONS'
);
