import {
  APIReferenceType,
  ArdoqId,
  ReferenceFilterProperties,
} from '@ardoq/api-types';
import { SelectOption } from '@ardoq/select';
import { Model, Workspace } from 'aqTypes';
import Workspaces from 'collections/workspaces';

const getReferenceTypeOptions = (
  referenceTypes: Record<string | number, APIReferenceType>
): SelectOption<string>[] => {
  return Object.keys(referenceTypes).map(refTypeId => {
    const refType: APIReferenceType | undefined = referenceTypes[refTypeId];
    return {
      label: refType?.name ?? '',
      value: refType?.name ?? '',
    };
  });
};

const getReferenceType = (
  referenceTypeName: string | null | undefined,
  referenceTypes: Record<string | number, APIReferenceType>
) => {
  const referenceType = Object.values(referenceTypes).find(
    refType => refType.name === referenceTypeName
  );
  return referenceType;
};

const getSourceTypeName = (
  outgoing: boolean | undefined,
  componentTypeName: string | null | undefined,
  referenceWorkspaceModel: Model | null | undefined,
  surveyComponentTypeId?: string
) => {
  if (!outgoing) return componentTypeName ?? undefined;
  const sourceType = surveyComponentTypeId
    ? referenceWorkspaceModel?.getAllTypes()[surveyComponentTypeId]
    : undefined;
  return sourceType?.name;
};

const getTargetTypeName = (
  outgoing: boolean | undefined,
  componentTypeName: string | null | undefined,
  targetWorkspaceId: string | null | undefined,
  surveyComponentTypeId?: string
) => {
  if (outgoing) return componentTypeName ?? undefined;
  const targetWorkspace = targetWorkspaceId
    ? Workspaces.collection.get(targetWorkspaceId)
    : undefined;

  const targetWorkspaceModel = targetWorkspace
    ? targetWorkspace.getModel()
    : null;

  const targetType = surveyComponentTypeId
    ? targetWorkspaceModel?.getAllTypes()[surveyComponentTypeId]
    : undefined;

  return targetType?.name;
};

type ReferenceData = {
  /**
   * In this case both `label` and `value` are the type name of the reference.
   */
  referenceTypeOptions: SelectOption<string>[];
  referenceWorkspace: Workspace | undefined;
  referenceTypeId: number | undefined;
  referenceTypeName: string | undefined;
  sourceTypeName: string | undefined;
  targetTypeName: string | undefined;
};

type GetReferenceDataProps = {
  surveyWorkspaceId: ArdoqId | null;
  surveyComponentTypeId?: string;
} & ReferenceFilterProperties;

export const getReferenceData = ({
  surveyWorkspaceId,
  surveyComponentTypeId,
  outgoing,
  workspaceId,
  componentTypeName,
  referenceTypeName,
}: GetReferenceDataProps): ReferenceData => {
  const [referenceWorkspaceId, targetWorkspaceId] = outgoing
    ? [surveyWorkspaceId, workspaceId]
    : [workspaceId, surveyWorkspaceId];

  const referenceWorkspace = referenceWorkspaceId
    ? Workspaces.collection.get(referenceWorkspaceId)
    : undefined;

  const referenceWorkspaceModel = referenceWorkspace
    ? referenceWorkspace.getModel()
    : null;

  const referenceTypes = referenceWorkspaceModel?.getReferenceTypes() ?? {};
  const referenceType = getReferenceType(referenceTypeName, referenceTypes);
  return {
    referenceTypeOptions: getReferenceTypeOptions(referenceTypes),
    referenceWorkspace,
    referenceTypeId: referenceType?.id,
    referenceTypeName: referenceType?.name,
    sourceTypeName: getSourceTypeName(
      outgoing,
      componentTypeName,
      referenceWorkspaceModel,
      surveyComponentTypeId
    ),
    targetTypeName: getTargetTypeName(
      outgoing,
      componentTypeName,
      targetWorkspaceId,
      surveyComponentTypeId
    ),
  };
};
