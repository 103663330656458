import { POPOVER_ID_ATTR } from '@ardoq/popovers';
import { ArdoqId } from '@ardoq/api-types';

export const DIFF_MERGE_COMPONENT_MODEL_POPOVER =
  'diff-merge-component-model-popover';

export const DIFF_MERGE_COMPONENT_HIERARCHY_POPOVER =
  'diff-merge-component-hierarchy-popover';

export const DIFF_MERGE_WORKSPACE_POPOVER = 'diff-merge-workspace-popover';

export const DIFF_MERGE_ENTITIES_LIST_POPOVER =
  'diff-merge-entities-list-popover';

export const DIFF_MERGE_MISSING_PARENT_POPOVER =
  'diff-merge-missing-parent-popover';

export const withMissingParentPopover = (entityId: ArdoqId) => ({
  [POPOVER_ID_ATTR]: DIFF_MERGE_MISSING_PARENT_POPOVER,
  'data-diff-merge-popover-entity-id': entityId,
});
