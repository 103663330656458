import { TertiaryButton } from '@ardoq/button';
import { Label } from '@ardoq/forms';
import { FlexBox } from '@ardoq/layout';
import { showDrawer } from '@ardoq/snowflakes';
import AudienceTraversalDrawer from './AudienceTraversalDrawer/AudienceTraversalDrawer';
import { APIViewpointAttributes, MetaModel } from '@ardoq/api-types';
import { isEmptyTraversal } from './AudienceTraversalDrawer/traversalAudienceHelpers';
import ComponentTypeTag from './ComponentTypeTag';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  resetAudienceDrawerData,
  updateTraversal,
} from './AudienceTraversalDrawer/actions';

type TraversalAudienceDisplayProps = {
  componentTypeName: string;
  metamodel: MetaModel;
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>;
  onTraversalChange: (
    traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>
  ) => void;
};

const TraversalAudienceSelector = ({
  componentTypeName,
  metamodel,
  traversal,
  onTraversalChange,
}: TraversalAudienceDisplayProps) => {
  return (
    <div>
      <FlexBox justify="space-between">
        <FlexBox gap="xsmall" align="center">
          <Label width="default">Select advanced relationship for</Label>
          <ComponentTypeTag
            componentTypeName={componentTypeName}
            metamodel={metamodel}
          />
        </FlexBox>
        <TertiaryButton
          onClick={() => {
            showDrawer({
              stackPageName: 'AudienceTraversalDrawer',
              renderDrawer: ({ handleCloserDrawer }) => (
                <AudienceTraversalDrawer
                  onConfirmClick={traversal => {
                    dispatchAction(updateTraversal(traversal));
                    onTraversalChange(traversal);
                    handleCloserDrawer();
                  }}
                  onCancelClick={() => {
                    handleCloserDrawer();
                    dispatchAction(resetAudienceDrawerData(traversal));
                  }}
                />
              ),
              shouldCloseOnEscapeKey: true,
            });
          }}
        >
          {isEmptyTraversal(traversal)
            ? 'Select relationship'
            : 'Edit relationship'}
        </TertiaryButton>
      </FlexBox>
    </div>
  );
};

export default TraversalAudienceSelector;
