import { combineLatest, map, of, switchMap } from 'rxjs';
import { dispatchAction } from '@ardoq/rxbeach';
import { CloudProviderResourceType } from '@ardoq/api-types/integrations';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { changeResourceTypesSelection } from 'integrations/cloudProviders/streams/resourceTypes/actions';
import { SelectorField } from 'integrations/common/types/common';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import {
  ensureCloudProvider,
  resourceTypesToFields,
} from 'integrations/cloudProviders/utils';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import fp from 'lodash/fp';

export const viewModel$ = integrationId$.pipe(
  ensureCloudProvider(),
  switchMap(integrationId =>
    combineLatest({
      integrationId: of(integrationId),
      resourceTypesState: getResourceTypesStream(integrationId),
      regionsState: getRegionsStream(integrationId),
    })
  ),
  map(({ resourceTypesState, integrationId, regionsState }) => {
    const selectedResourceTypes = fp.orderBy<CloudProviderResourceType>(
      'name',
      'asc'
    )(
      resourceTypesState.resourceTypes.filter(rt =>
        resourceTypesState.selectedResourceTypeIds.includes(rt.id)
      )
    );

    const availableFields = fp.orderBy<CloudProviderResourceType>(
      'name',
      'asc'
    )(
      resourceTypesState.resourceTypes.filter(
        rt => !resourceTypesState.selectedResourceTypeIds.includes(rt.id)
      )
    );

    const onClickAdd = (field: SelectorField) => {
      dispatchAction(
        changeResourceTypesSelection({
          integrationId,
          resourceTypes: [
            ...selectedResourceTypes.map(rt => rt.id),
            field.name,
          ],
        })
      );
    };

    const onClickRemove = (field: SelectorField) => {
      dispatchAction(
        changeResourceTypesSelection({
          integrationId,
          resourceTypes: selectedResourceTypes
            .map(rt => rt.id)
            .filter(id => id !== field.name),
        })
      );
    };

    const onClickRemoveAll = () => {
      dispatchAction(
        changeResourceTypesSelection({
          integrationId,
          resourceTypes: [],
        })
      );
    };

    const isLoading = resourceTypesState.asyncStatus === 'LOADING';
    const isDisabled = regionsState.selectedRegionIds.length === 0;

    return {
      selectedFields: resourceTypesToFields(selectedResourceTypes),
      availableFields: resourceTypesToFields(availableFields),
      isLoading,
      isDisabled,
      currentTable: {
        name: 'resourceGroups',
        canCreate: true,
        canDelete: true,
        canRead: true,
        canWrite: true,
        label: 'Resource Groups',
        id: 'resourceGroups',
      },
      onClickAdd,
      onClickRemove,
      onClickRemoveAll,
    };
  })
);
