import { APIEntityType, APIOrganizationUser, ArdoqId } from '@ardoq/api-types';
import { SwitchPosition, TriStateSwitch } from '@ardoq/snowflakes';
import {
  ComponentRenderer,
  FieldType,
  ReferenceRenderer,
  RenderOption,
  RenderOptions,
} from '@ardoq/renderers';
import { ChangeStatusDecorator } from './ChangeStatusDecorator';
import { EnhancedScopeData } from '@ardoq/data-model';
import { ApprovalStatus, ChangeApprovalRowData } from '../types';

const renderOptions: RenderOptions = {
  definitions: {
    [RenderOption.MARKDOWN]: {
      renderMarkdownInline: true,
    },
  },
  types: {
    [FieldType.TEXT_AREA]: new Set([RenderOption.MARKDOWN]),
  },
};

type ApprovalSwitchProps = {
  rowData: ChangeApprovalRowData;
  status: ApprovalStatus;
  handleApprovedDataForSpecificField: (
    switchPosition: SwitchPosition,
    rowData: ChangeApprovalRowData
  ) => void;
};

export const ApprovalSwitch = ({
  rowData,
  status,
  handleApprovedDataForSpecificField,
}: ApprovalSwitchProps) => {
  return (
    <TriStateSwitch
      dataTestId="approval-switch"
      name={`${rowData.changeApprovalEntityId}-${rowData.whatChanged}`}
      value={status}
      onChange={switchPosition =>
        handleApprovedDataForSpecificField(switchPosition, rowData)
      }
    />
  );
};

type ChangedToRendererProps = {
  rowData: ChangeApprovalRowData;
  status: ApprovalStatus;
  branchData: EnhancedScopeData;
  users: Record<ArdoqId, APIOrganizationUser>;
};

export const ChangedToRenderer = ({
  rowData,
  status,
  branchData,
  users,
}: ChangedToRendererProps) => {
  return (
    <ChangeStatusDecorator status={status}>
      {rowData.entityType === APIEntityType.REFERENCE ? (
        <ReferenceRenderer
          entityId={rowData.changeApprovalEntityId}
          fieldName={rowData.whatChanged}
          enhancedScopeData={branchData}
          users={users}
          options={renderOptions}
        />
      ) : (
        <ComponentRenderer
          entityId={rowData.changeApprovalEntityId}
          fieldName={rowData.whatChanged}
          enhancedScopeData={branchData}
          users={users}
          options={renderOptions}
        />
      )}
    </ChangeStatusDecorator>
  );
};

type ChangedFromRendererProps = {
  rowData: ChangeApprovalRowData;
  masterData: EnhancedScopeData;
  users: Record<ArdoqId, APIOrganizationUser>;
};

export const ChangedFromRenderer = ({
  rowData,
  masterData,
  users,
}: ChangedFromRendererProps) => {
  return (
    <ChangeStatusDecorator>
      {rowData.entityType === APIEntityType.REFERENCE ? (
        <ReferenceRenderer
          entityId={rowData.changeApprovalEntityId}
          fieldName={rowData.whatChanged}
          enhancedScopeData={masterData}
          users={users}
          options={renderOptions}
        />
      ) : (
        <ComponentRenderer
          entityId={rowData.changeApprovalEntityId}
          fieldName={rowData.whatChanged}
          enhancedScopeData={masterData}
          users={users}
          options={renderOptions}
        />
      )}
    </ChangeStatusDecorator>
  );
};
