import { AppModules } from 'appContainer/types';
import { AppRouterState, ViewDigestRoute } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { empty } from 'rxjs';
import { APP_ROUTER_DEFAULT_LOCATION } from './appRouterUtils';
import { dispatchAction } from '@ardoq/rxbeach';
import { viewWorkspaceDigest } from 'streams/workspaceDigest/actions';

const DEFAULT_DIGEST_DAYS = 5;

const viewDigestRoute = new Route<AppRouterState, ViewDigestRoute>({
  doesLocationMatch: ({ path }) =>
    /dashboard\/digest\/workspace\/([\da-z+]+)(?:\/sinceDaysAgo\/(\d+))?/.test(
      path
    ),
  locationToRouterState: ({ path }) => {
    const parts = path.split('/');
    const workspaceId = parts[4];
    const days = parseInt(parts[6], 10) || DEFAULT_DIGEST_DAYS;
    return {
      selectedModule: AppModules.HOME,
      showViewDigest: { workspaceId, days },
    };
  },
  doesRouterStateMatch: ({ showViewDigest }) => showViewDigest !== false,
  routerStateToLocation: ({ showViewDigest }) => {
    if (showViewDigest) {
      const { workspaceId, days } = showViewDigest;
      return {
        path: `/dashboard/digest/workspace/${workspaceId}/sinceDaysAgo/${days}`,
        title: 'View Digest',
      };
    }
    return APP_ROUTER_DEFAULT_LOCATION;
  },
  setApplicationStateFromRoute: ({ showViewDigest }) => {
    if (showViewDigest) {
      const { workspaceId, days } = showViewDigest;
      dispatchAction(viewWorkspaceDigest({ workspaceId, days }));
    }
  },
  // Whether the view digest is open is currently not exposed as a state
  // stream, so we can't update the URL when the view digest is opened
  getPartialRouterStateStream: () => empty(),
});

export default viewDigestRoute;
