import { Position } from 'components/WorkspaceHierarchies/types';
import { distinctUntilChanged, map } from 'rxjs';
import { isEqual } from 'lodash';
import { createReferences$ } from './createReferences$';
import { geometry } from '@ardoq/math';
import { colors } from '@ardoq/design-tokens';

export type LinkSvgOverlayArgs = {
  linkPosition: Position;
  linkSourceStartPosition: Position;
  style: {
    left: string;
    top: string;
    width: string;
    height: string;
  };
  distance: number;
  color: string;
  isDirectionDown: boolean;
  svgHeight: number;
  svgWidth: number;
  shouldShow: boolean;
  navigatorContainerBox: DOMRect;
  hasLinkTarget: boolean;
};

const linkSvgOverlay$ = createReferences$.pipe(
  map(
    ({
      linkPosition,
      linkSourceStartPosition,
      hasLinkTarget,
      shouldShowRefDrawing,
      navigatorContainerBox,
    }) => ({
      linkPosition,
      linkSourceStartPosition,
      hasLinkTarget,
      navigatorContainerBox,
      shouldShowRefDrawing,
    })
  ),
  distinctUntilChanged(isEqual),
  map(
    ({
      linkPosition,
      linkSourceStartPosition,
      hasLinkTarget,
      navigatorContainerBox,
      shouldShowRefDrawing,
    }) => {
      const svgHeight = window.innerHeight;
      const svgWidth = window.innerWidth;
      const style = {
        left: '0',
        top: '0',
        width: `${svgWidth}px`,
        height: `${svgHeight}px`,
      };

      const distance = Math.pow(
        geometry.distanceTwoArgs(
          [linkSourceStartPosition.x, linkSourceStartPosition.y],
          [linkPosition.x, linkPosition.y]
        ),
        1.15
      );

      const color = hasLinkTarget ? colors.brand50 : colors.grey80;

      const isDirectionDown = linkPosition.y > linkSourceStartPosition.y;
      return {
        linkPosition,
        linkSourceStartPosition,
        style,
        distance,
        color,
        isDirectionDown,
        svgHeight,
        svgWidth,
        shouldShow: shouldShowRefDrawing,
        navigatorContainerBox,
        hasLinkTarget,
      };
    }
  )
);

export default linkSvgOverlay$;
