enum ScenarioMergeIntercomTourClickIds {
  HEADER_TEXT = 'ScenarioMergeInterComTourClickIds/HEADER_TEXT',
  KNOWLEDGE_BASE_LINK = 'ScenarioMergeInterComTourClickIds/KNOWLEDGE_BASE_LINK',
  EXIT_ICON = 'ScenarioMergeInterComTourClickIds/EXIT_ICON',
  MERGE_DIRECTION = 'ScenarioMergeInterComTourClickIds/MERGE_DIRECTION',
  NAVIGATOR = 'ScenarioMergeInterComTourClickIds/NAVIGATOR',
  APPLY_BUTTON = 'apply-all-changes-you-have-selected-footer',
  INFO_ICON = 'ScenarioMergeInterComTourClickIds/INFO_ICON',
  RADIO_BUTTON = 'ScenarioMergeInterComTourClickIds/RADIO_BUTTON',
  ENTITY_LABEL = 'ScenarioMergeInterComTourClickIds/ENTITY_LABEL',
  ORIGINAL_VALUE = 'ScenarioMergeInterComTourClickIds/ORIGINAL_VALUE',
  STATUS_LABEL = 'ScenarioMergeInterComTourClickIds/STATUS_LABEL',
  RESET_ICON = 'ScenarioMergeInterComTourClickIds/RESET_ICON',
}

const DATA_INDEX_TO_APPLY_INTERCOM_ID = 2; // When want the steps to point to the first actual row. Row 0 is header, row 1 is section header
export const shouldSetIntercomId = (index: number | undefined) =>
  index === DATA_INDEX_TO_APPLY_INTERCOM_ID;

export default ScenarioMergeIntercomTourClickIds;
