import { colors, s16, s24 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { APISurveyAttributes } from '@ardoq/api-types';
import { Checkbox, TextInput } from '@ardoq/forms';
import { Link, normal14 } from '@ardoq/typography';
import CurrentUser from 'models/currentUser';
import { ConfirmResult } from '@ardoq/modal';
import { trackEvent } from 'tracking/tracking';
import { useEffectOnce } from '@ardoq/hooks';
import { SurveyEditorSectionProps } from './SurveyEditor';
import { MaybePersistedSurvey } from 'surveyAdmin/types';

const MAX_LABEL_LENGTH = 30;

const enableDiscoverAttributeName = 'discoverEnabled';
const discoverPriorityAttributeName = 'discoverPriority';
const discoverLabelAttributeName = 'discoverLabel';

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${s24};
  width: 50%;
`;

const TipText = styled.p`
  ${normal14};
  font-size: 12px;
  color: ${colors.grey50};
  margin: 0;
  margin-top: -${s16};
  white-space: pre-line;
`;

type SurveyEditorDiscoverProps = SurveyEditorSectionProps & {
  previousSurveyAttributes: MaybePersistedSurvey;
  relatedSurveys: APISurveyAttributes[];
  pendingSave: boolean;
  shouldDiscardChanges: () => Promise<false | ConfirmResult>;
  otherSectionsHaveErrors: boolean;
};

export default function SurveyEditorDiscover(props: SurveyEditorDiscoverProps) {
  const {
    surveyAttributes,
    setSurveyAttributes,
    relatedSurveys,
    previousSurveyAttributes,
    pendingSave,
    shouldDiscardChanges,
    otherSectionsHaveErrors,
  } = props;

  const otherPrioritySurvey = relatedSurveys.find(
    survey => survey[discoverPriorityAttributeName]
  );

  const labelLength =
    surveyAttributes?.[discoverLabelAttributeName]?.length ?? 0;
  const warningMessage =
    previousSurveyAttributes[enableDiscoverAttributeName] &&
    !surveyAttributes[discoverLabelAttributeName]
      ? 'Make sure your label is not empty'
      : labelLength > MAX_LABEL_LENGTH
        ? `Make sure your label is no more than ${MAX_LABEL_LENGTH} characters long`
        : '';

  const handleNavToPrioritySurvey = async () => {
    if (!otherPrioritySurvey) return;

    if (!pendingSave || (await shouldDiscardChanges())) {
      const baseUrl =
        CurrentUser.organization?.['custom-domain']?.['base-url'] ?? '';
      window.location.href = `${baseUrl}/app/survey-admin/edit/${otherPrioritySurvey._id}`;
    }
  };

  const isAddedToDiscover = surveyAttributes[enableDiscoverAttributeName];

  useEffectOnce(() => {
    if (isAddedToDiscover !== undefined) return;
    setSurveyAttributes({ [discoverPriorityAttributeName]: false });
  });

  const isPrioritySurvey = surveyAttributes[discoverPriorityAttributeName];
  const surveyLabel = surveyAttributes[discoverLabelAttributeName];

  return (
    <FieldWrapper>
      <Checkbox
        isChecked={Boolean(isAddedToDiscover)}
        onChange={() => {
          let newAttributes: Partial<APISurveyAttributes> = {
            [enableDiscoverAttributeName]: !isAddedToDiscover,
          };
          if (!isAddedToDiscover === false) {
            newAttributes = {
              ...newAttributes,
              [discoverPriorityAttributeName]: false,
            };
          }
          setSurveyAttributes(newAttributes);
          trackEvent('Survey builder: toggle add to Discover', {
            toggle: !isAddedToDiscover,
          });
        }}
        hasWarning={otherSectionsHaveErrors && isAddedToDiscover}
        warningMessage={
          otherSectionsHaveErrors && isAddedToDiscover
            ? 'Resolve the validation errors to proceed with Discover configuration'
            : ''
        }
      >
        Add survey to Ardoq Discover
      </Checkbox>

      {isAddedToDiscover && (
        <>
          <Checkbox
            isChecked={Boolean(isPrioritySurvey)}
            onChange={() => {
              setSurveyAttributes({
                [discoverPriorityAttributeName]: !isPrioritySurvey,
              });
            }}
            isDisabled={Boolean(otherPrioritySurvey) || otherSectionsHaveErrors}
          >
            Set as priority survey
          </Checkbox>

          {otherPrioritySurvey && (
            <TipText>
              {otherPrioritySurvey.name} is set as the priority survey.{'\n'}
              To change the priority survey, go to the{' '}
              <Link
                style={{ fontSize: 'inherit' }}
                hideIcon
                onClick={handleNavToPrioritySurvey}
              >
                priority survey builder
              </Link>{' '}
              and remove it as the priority survey.
            </TipText>
          )}

          <TextInput
            label="Survey Label"
            value={surveyLabel}
            onValueChange={value =>
              setSurveyAttributes({
                [discoverLabelAttributeName]: value,
              })
            }
            isDisabled={otherSectionsHaveErrors}
            warningMessage={warningMessage}
            placeholder="Create a specific call to action"
            hintMessage={
              !warningMessage
                ? `${surveyLabel?.length ?? 0}/${MAX_LABEL_LENGTH}`
                : ''
            }
            dataTestId="survey-label-input"
          />

          <TextInput
            label="Default Button Label"
            value="Submit an update"
            isReadOnly
            isDisabled={otherSectionsHaveErrors}
          />
        </>
      )}
    </FieldWrapper>
  );
}
