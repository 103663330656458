import { viewpointBuilderNavigationOperations } from 'viewpointBuilder/viewpointBuilderNavigation/viewpointBuilderNavigationOperations';
import { initialSearchComponentViewState } from 'viewpointBuilder/selectContextTab/toViewState';
import {
  ViewpointBuilderState,
  ViewpointBuilderViewState,
} from 'viewpointBuilder/types';
import { metamodelViewOperations } from 'viewpointBuilder/metaModel/metamodelViewOperations';
import { getInitialMetaInfoState } from '../viewpointMetaInfo/viewpointMetaInfoOperations';
import { editTraversalOperations } from 'viewpointBuilder/traversals/editTraversalOperations';
import { viewpointGroupingRulesOperations } from 'viewpointBuilder/addGroupingRulesTab/viewpointGroupingRulesOperations';
import { viewpointBuilderFormattingOperations } from 'viewpointBuilder/formattingTabs/viewpointBuilderFormattingOperations';
import { getPrimaryButtonConfig } from './getPrimaryButtonConfig';

export const getInitialViewpointBuilderState =
  (): ViewpointBuilderViewState => {
    const viewpointBuilderState: ViewpointBuilderState = {
      viewpointBuilderNavigationState:
        viewpointBuilderNavigationOperations.getInitialViewModel(),
      searchComponentsState: initialSearchComponentViewState,
      metamodelViewState: metamodelViewOperations.getInitialState(),
      editTraversalsState:
        editTraversalOperations.getInitialTraversalBuilderViewState(),
      metaInfoState: getInitialMetaInfoState(),
      groupingsState: viewpointGroupingRulesOperations.getInitialState(),
      formattingState:
        viewpointBuilderFormattingOperations.getInitialViewState(),
    };
    return {
      ...viewpointBuilderState,
      primaryButtonConfig: getPrimaryButtonConfig(viewpointBuilderState),
    };
  };
