import { actionCreator } from '@ardoq/rxbeach';
import { ServiceNowLegacyConfig } from '@ardoq/api-types/integrations';

export type SetLegacyConfigsPayload = {
  configs: ServiceNowLegacyConfig[];
};

export const setLegacyConfigs = actionCreator<SetLegacyConfigsPayload>(
  `[integrations] SERVICENOW SET_LEGACY_CONFIGS`
);

export const fetchLegacyConfigs = actionCreator(
  `[integrations] SERVICENOW FETCH_LEGACY_CONFIGS`
);
