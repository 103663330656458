import styled from 'styled-components';
import { SplashScreenProps } from './types';
import { colors, s16 } from '@ardoq/design-tokens';
import { fontMixins } from '@ardoq/typography';
import AnimatedArdoqLogo from './AnimatedArdoqLogo';
import loaderImageUrl from './ardoqLoaderImage.gif';

// z-index value is a bit excessive, review once https://ardoqcom.atlassian.net/browse/ARD-8684 is done

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div<{ $color: string }>`
  ${fontMixins.normal22};
  color: ${props => props.$color};
`;

const Spinner = styled.img.attrs({
  src: loaderImageUrl,
})`
  width: 100px;
`;

const SplashScreen = ({
  showSpinner = true,
  title,
  spinnerOffsetX = 0,
  spinnerOffsetY = 0,
  color = colors.grey15,
  useSvg = false,
}: SplashScreenProps) => {
  const styleObj = {
    marginLeft: spinnerOffsetX,
    marginTop: spinnerOffsetY,
    marginBottom: title ? s16 : 0,
  };
  return (
    <Wrapper>
      {showSpinner ? (
        useSvg ? (
          <AnimatedArdoqLogo styling={styleObj} />
        ) : (
          <Spinner style={styleObj} />
        )
      ) : null}
      {title && <Title $color={color}>{title}</Title>}
    </Wrapper>
  );
};

export default SplashScreen;
