import { combineLatest, map } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { dispatchAction } from '@ardoq/rxbeach';
import { ServiceNowField } from '@ardoq/api-types/integrations';
import _ from 'lodash/fp';
import { selectionState$ } from '../../streams/selectionState/selectionState$';
import { tablesFields$ } from '../../streams/tableFields/tableFields$';
import { serviceNowTables$ } from '../../streams/tables/tables$';
import {
  selectField,
  selectFields,
  setQueryFilter,
  unselectField,
  unselectFields,
} from '../../streams/selectionState/actions';
import fp from 'lodash/fp';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';

export const viewModel$ = combineLatest({
  selectionState: selectionState$,
  tablesFields: tablesFields$,
  currentTable: selectionState$.pipe(
    withLatestFrom(serviceNowTables$),
    map(
      ([{ currentTableId }, { tables }]) =>
        tables.find(({ name }) => name === currentTableId) || null
    )
  ),
  connectionsState: getConnectionsStream('servicenow-v3'),
}).pipe(
  map(({ selectionState, tablesFields, currentTable, connectionsState }) => {
    const base = {
      currentTable,
      selectedFields: [],
      availableFields: connectionsState.selectedConnectionIds.length
        ? []
        : null,
      isLoading: false,
      queryFilter: null,
      onClickRemove: () => {},
      onClickAdd: () => {},
      onClickAddAll: () => {},
      onClickRemoveAll: () => {},
      onApplyQueryFilter: () => {},
    };

    if (currentTable && tablesFields[currentTable.name]) {
      const [selectedFields, availableFields] = _.partition(
        (item: ServiceNowField) =>
          Object.keys(
            selectionState.tables[currentTable.name]?.fields || {}
          ).includes(item.name),
        fp.sortBy(
          f => f.label,
          Object.values(tablesFields[currentTable.name].fields)
        )
      );

      return {
        ...base,
        selectedFields,
        availableFields,
        queryFilter: selectionState.tables[currentTable.name]?.queryFilter,
        isLoading: tablesFields[currentTable.name]?.requestStatus === 'LOADING',
        onClickRemoveAll: () => {
          dispatchAction(unselectFields(currentTable.name));
        },
        onClickAddAll: () => {
          dispatchAction(
            selectFields({
              tableId: currentTable.name,
              ids: availableFields?.map(({ name }) => name) || [],
            })
          );
        },
        onClickAdd: (field: ServiceNowField) => {
          dispatchAction(
            selectField({ tableId: currentTable.name, id: field.name })
          );
        },
        onClickRemove: (field: ServiceNowField) => {
          dispatchAction(
            unselectField({ tableId: currentTable.name, id: field.name })
          );
        },
        onApplyQueryFilter: (queryFilter: string) => {
          dispatchAction(
            setQueryFilter({ tableId: currentTable.name, queryFilter })
          );
        },
      };
    }

    return base;
  })
);
