import { RowType } from '@ardoq/table';
import EntityBrowserActionsMenu from '../../components/EntityBrowser/EntityBrowserActionsMenu';
import { DropdownOption } from '@ardoq/dropdown-menu';
import { trackEvent } from 'tracking/tracking';

type AssetsBrowserActionsMenuProps = {
  rowType: RowType;
  menuOptions: DropdownOption[];
};

const AssetsBrowserActionsMenu = ({
  menuOptions,
  rowType,
}: AssetsBrowserActionsMenuProps) =>
  rowType !== RowType.EMPTY_FOLDER && menuOptions.length ? (
    <EntityBrowserActionsMenu
      menuOptions={menuOptions}
      onTrackClick={() =>
        trackEvent('Assets Manager: click on menu cell', {
          rowType,
        })
      }
    />
  ) : null;

export default AssetsBrowserActionsMenu;
