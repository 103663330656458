import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors, radius, s16, s8 } from '@ardoq/design-tokens';
import {
  PaginationBar,
  PaginationController,
  smartSort,
} from '@ardoq/pagination';
import {
  BasicTable,
  DatasourceTable,
  HeaderCell,
  useSortableTable,
} from '@ardoq/table';
import { SortOrder } from '@ardoq/api-types';
import { DataSourceEmptyTable } from '@ardoq/integrations';
import { StatusType, Tag } from '@ardoq/status-ui';
import { formatDateTime } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { header4, text1 } from '@ardoq/typography';
import { ImportHistory } from '@ardoq/api-types/integrations';
import DataHistoryApiConnector from '../importsHistoryApiConnector/ImportsHistoryApiConnector';
import { SourceIcon } from '../sourceIcon/SourceIcon';
import SummaryCell from '../summaryCell/SummaryCell';
import {
  IntegrationIdBySourceTypes,
  integrationNamesBySourceTypes,
} from 'integrations/common/streams/activeIntegrations/sourceTypes';
import { HeaderDataHistory } from './HeaderDataHistory';
import { startImportDetailsModal } from 'integrations/common/modals/importDetails/ImportDetailsModal';
import { trackEvent } from 'tracking/tracking';
import { EVENT_NAMES } from 'integrations/common/tracking/events';
import { SourceType } from '@ardoq/api-types/integrations';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0 ${s16};
`;

const PaginationBarContainer = styled.div`
  padding: 12px 24px 9px 24px;
`;

type DataHistoryProps = {
  goBack: () => void;
};

type RenderPropsProps = {
  imports: ImportHistory[];
  isLoadingImports: boolean;
};

type StatusIconCellProps = {
  importHistory: ImportHistory;
};

type SourceIconCellProps = {
  integrationSource: string;
};

const getConfigPrefix = (entityType: string) => {
  switch (entityType) {
    case 'import_history':
      return 'Import';
    case 'export_history':
      return 'Export';
    default:
      return '';
  }
};

const StatusIconCell = ({ importHistory }: StatusIconCellProps) => {
  if (!importHistory?.result) {
    return;
  }

  const isError = importHistory.result.status === 'error';
  const isWarn = importHistory.result.status === 'warn';
  return (
    <Tag
      statusType={
        isError
          ? StatusType.ERROR
          : isWarn
            ? StatusType.WARNING
            : StatusType.SUCCESS
      }
      label={isError ? 'Failed' : isWarn ? 'Warning' : 'Successful'}
    />
  );
};

const SourceIconCell = ({ integrationSource }: SourceIconCellProps) => {
  return (
    <IconWrapper>
      <SourceIcon size="24px" integrationSource={integrationSource} />
    </IconWrapper>
  );
};

export type FiltersIds =
  | 'integrationSource'
  | 'result.status'
  | 'lastModifiedByName';

export type Filters = Record<FiltersIds, Record<string, boolean>>;

const defaultFilters: Filters = {
  integrationSource: {},
  'result.status': {},
  lastModifiedByName: {},
};

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 0;
`;
const Thead = styled.thead`
  background-color: ${colors.grey90};
  border-bottom: 1px solid ${colors.borderSubtle00};
`;
const BodyTr = styled.tr<{ $isSelected?: boolean }>`
  height: 40px;
  &:hover {
    background-color: ${colors.grey95};
  }
  ${props =>
    props.$isSelected &&
    css`
      background-color: ${colors.grey95};
    `}
`;

const Th = styled(BasicTable.Th)`
  padding: 0;
  text-transform: none;
  white-space: nowrap;
  color: ${colors.textDefault};
  ${header4}
`;

const Td = styled.td`
  padding: 0;
  border-bottom: 1px solid ${colors.borderSubtle00};
  ${text1}
`;

const DataHistory = ({
  goBack,
  imports,
  isLoadingImports,
}: DataHistoryProps & RenderPropsProps) => {
  const {
    sortOrder,
    sortBy: sortById,
    getOnSortByClick,
  } = useSortableTable({
    initialSortBy: 'created',
    initialSortOrder: SortOrder.DESC,
  });

  const [perPage, setPerPage] = useState(15);
  const [filters, setFilters] = useState<Filters>(defaultFilters);

  const handleAllClick = (id: FiltersIds, value: boolean) => {
    const updatedValues = Object.keys(filters[id] || {}).reduce(
      (acc, key) => {
        acc[key] = value;
        return acc;
      },
      {} as Record<string, boolean>
    );

    setFilters({
      ...filters,
      [id]: updatedValues,
    });
  };

  const handleOnClick = (id: FiltersIds, value: boolean, label: string) => {
    setFilters({
      ...filters,
      [id]: {
        ...(filters[id] || {}),
        [label]: value,
      },
    });
  };

  const clearFilters = () => {
    const resetFilters = Object.fromEntries(
      Object.entries(filters).map(([filterId, innerObj]) => [
        filterId,
        Object.keys(innerObj).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        ),
      ])
    ) as Filters;

    setFilters(resetFilters);
  };

  // get function like _.get
  const get = (key: FiltersIds, obj: ImportHistory) =>
    key.split('.').reduce((accumulator: any, key: string) => {
      return accumulator?.[key];
    }, obj);

  useEffect(() => {
    const initFilters = imports.reduce(
      (filters: Filters, importInstance) =>
        (Object.keys(defaultFilters) as FiltersIds[]).reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              ...acc[key],
              [get(key, importInstance)]: true,
            },
          }),
          filters
        ),
      defaultFilters
    );

    setFilters(initFilters);
  }, [imports]);

  const filteredImports = imports.filter(importInstance =>
    (Object.keys(filters) as FiltersIds[]).every(
      key =>
        Object.keys(filters[key]).length === 0 ||
        filters[key][get(key, importInstance)]
    )
  );

  const sortedDataSource = smartSort(filteredImports, sortById, sortOrder);
  return (
    <PaginationController
      dataSource={sortedDataSource}
      perPage={perPage}
      render={({
        currentPageDataSource,
        currentPageNumber,
        perPage,
        totalResults,
        onPageSelect,
      }) => (
        <>
          <DatasourceTable
            fixedHeader
            style={{
              margin: '0 24px',
              borderRadius: radius.r16,
              border: `1px solid ${colors.borderSubtle00}`,
            }}
            loading={isLoadingImports}
            dataSource={currentPageDataSource}
            components={{ Table, Thead, BodyTr, Th, Td }}
            rowStyle={() => ({
              cursor: 'pointer',
            })}
            onRowClick={(importHistory: ImportHistory) => {
              const integrationId =
                importHistory.config &&
                importHistory.config.sources &&
                IntegrationIdBySourceTypes[importHistory.config.sources[0]];
              trackEvent(EVENT_NAMES.CLICKED_DATA_HISTORY_ENTRY, {
                integration: integrationId,
                type: getConfigPrefix(importHistory.ardoq.entityType),
                status: importHistory.result?.status,
              });
              startImportDetailsModal(importHistory);
            }}
            columns={[
              {
                headerStyle: {
                  width: 24,
                  paddingRight: s16,
                  paddingLeft: s16,
                  whiteSpace: 'nowrap',
                },
                dataIndex: 'integrationSource',
                valueRender: (value: string) => (
                  <SourceIconCell integrationSource={value} />
                ),
              },
              {
                title: 'Integration',
                headerStyle: {
                  width: 70,
                  whiteSpace: 'nowrap',
                },
                cellStyle: {
                  paddingRight: s8,
                  color: colors.grey15,
                  lineHeight: '20px',
                },
                dataIndex: 'integrationSource',
                valueRender: (value: SourceType) => (
                  <div>{integrationNamesBySourceTypes[value]}</div>
                ),
                headerRender: ({ title }: HeaderCell) => (
                  <HeaderDataHistory
                    title={title}
                    columnId={'integrationSource'}
                    columnHaveFilters={true}
                    friendlyNamesMap={integrationNamesBySourceTypes}
                    filters={filters}
                    handleAllClick={handleAllClick}
                    handleOnClick={handleOnClick}
                    sortOrder={sortOrder}
                    sortById={sortById}
                    getOnSortByClick={getOnSortByClick}
                  />
                ),
              },
              {
                title: 'Schedule name',
                headerStyle: { width: 300 },
                dataIndex: 'sourceName',
                headerRender: ({ title }: HeaderCell) => (
                  <HeaderDataHistory title={title} columnId={'sourceName'} />
                ),
              },
              {
                title: 'Type',
                headerStyle: { width: 300 },
                cellStyle: { color: colors.grey15, lineHeight: '20px' },
                dataIndex: 'ardoq.entityType',
                valueRender: (_a, importHistory) => (
                  <>{getConfigPrefix(importHistory.ardoq.entityType)}</>
                ),
                headerRender: ({ title }: HeaderCell) => (
                  <HeaderDataHistory
                    title={title}
                    columnId={'ardoq.entityType'}
                  />
                ),
              },
              {
                title: 'Created by',
                dataIndex: 'lastModifiedByName',
                headerStyle: { width: 190 },
                cellStyle: { color: colors.grey15, lineHeight: '20px' },
                headerRender: ({ title }: HeaderCell) => (
                  <HeaderDataHistory
                    title={title}
                    columnId={'lastModifiedByName'}
                    columnHaveFilters={true}
                    columnHaveSearch={true}
                    filters={filters}
                    handleAllClick={handleAllClick}
                    handleOnClick={handleOnClick}
                    sortOrder={sortOrder}
                    sortById={sortById}
                    getOnSortByClick={getOnSortByClick}
                  />
                ),
              },
              {
                title: 'Date',
                dataIndex: 'created',
                headerStyle: { width: 200 },
                cellStyle: { color: colors.grey15, lineHeight: '20px' },
                valueRender: value => formatDateTime(value, getCurrentLocale()),
                headerRender: ({ title }: HeaderCell) => (
                  <HeaderDataHistory title={title} columnId={'created'} />
                ),
              },
              {
                title: 'Status',
                headerStyle: { width: 70 },
                dataIndex: 'result',
                valueRender: (_a, importHistory: ImportHistory) => (
                  <StatusIconCell importHistory={importHistory} />
                ),
                headerRender: ({ title }: HeaderCell) => (
                  <HeaderDataHistory
                    title={title}
                    columnId={'result.status'}
                    columnHaveFilters={true}
                    friendlyNamesMap={{
                      error: 'Failed',
                      warn: 'Warning',
                      success: 'Successful',
                    }}
                    filters={filters}
                    handleAllClick={handleAllClick}
                    handleOnClick={handleOnClick}
                    sortOrder={sortOrder}
                    sortById={sortById}
                    getOnSortByClick={getOnSortByClick}
                  />
                ),
              },
              {
                title: 'Summary',
                dataIndex: 'sourceName',
                headerStyle: { width: 200 },
                valueRender: (_a, importHistory) => (
                  <SummaryCell importHistory={importHistory} hasIcon={true} />
                ),
              },
            ]}
            renderEmptyTable={() =>
              !isLoadingImports ? (
                imports.length > 0 ? (
                  <DataSourceEmptyTable
                    title="You don't have any imports or exports that match the filter conditions. "
                    message="Clear filters to see your imports or exports ."
                    button={{
                      text: 'Clear filters',
                      onClick: clearFilters,
                    }}
                  ></DataSourceEmptyTable>
                ) : (
                  <DataSourceEmptyTable
                    title="You don't have any imports or exports yet"
                    message="Select an integration to start importing or exporting data."
                    button={{
                      text: 'Go to integrations',
                      onClick: goBack,
                    }}
                  ></DataSourceEmptyTable>
                )
              ) : null
            }
          />
          {perPage < totalResults ? (
            <PaginationBarContainer>
              <PaginationBar
                currentPageNumber={currentPageNumber}
                perPage={perPage}
                totalResults={totalResults}
                onChange={({ currentPageNumber, perPage }) => {
                  setPerPage(perPage);
                  onPageSelect(currentPageNumber);
                }}
              />
            </PaginationBarContainer>
          ) : (
            <div style={{ marginBottom: 10 }} />
          )}
        </>
      )}
    />
  );
};

export default (props: DataHistoryProps) => (
  <DataHistoryApiConnector>
    {({ imports, isLoadingImports }: RenderPropsProps) => (
      <DataHistory
        imports={imports}
        isLoadingImports={isLoadingImports}
        {...props}
      />
    )}
  </DataHistoryApiConnector>
);
