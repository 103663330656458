import { colors, s16 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { caption } from '@ardoq/typography';

export const Description = styled.p`
  ${caption};
  color: ${colors.grey50};
  margin: 3px 0 ${s16} 0;
  width: 448px;
`;
