import { Switch } from '@ardoq/forms';
import { Header2 } from '@ardoq/typography';
import { ContextConfig } from 'viewpointBuilder/openViewpointBuilder/getContextConfig';
import FilterByNameAndTypeCriteria from './FilterByNameAndTypeCriteria';
import { ComponentsSearchCommands } from 'viewpointBuilder/selectContextTab/commands';
import { ComponentSearchViewState } from 'viewpointBuilder/selectContextTab/types';
import AdvancedSearchQueryBuilder from 'search/AdvancedSearch/AdvancedSearchQueryBuilder';
import { ViewpointModalHeader } from 'viewpointBuilder/components/ViewpointModalHeader';
import { Island } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';

type SelectContextTabHeaderProps = {
  state: ComponentSearchViewState;
  contextConfig: ContextConfig;
  commands: ComponentsSearchCommands;
  viewpointName: string;
};
const SelectContextTabSearch = ({
  state,
  contextConfig,
  commands,
  viewpointName,
}: SelectContextTabHeaderProps) => {
  return (
    <Island>
      {contextConfig.isNavigationPanelHidden ? (
        <ViewpointModalHeader
          title="Select context component(s)"
          subtitle={
            <>
              Open the selected context component(s) with &quot;
              <strong>{viewpointName}</strong>&quot; Viewpoint
            </>
          }
        />
      ) : (
        <FlexBox align="center" gap="medium" marginBottom="medium">
          <Header2>Component search</Header2>
          <Switch
            isChecked={state.advancedSearchState !== null}
            onChange={() =>
              commands.setIsAdvancedSearch(!state.advancedSearchState)
            }
            name="advanced-search-switch"
            aria-label="advanced-search-switch"
            label="Advanced search"
          />
        </FlexBox>
      )}
      {state.advancedSearchState !== null ? (
        <AdvancedSearchQueryBuilder
          contextSelectIsReadOnly
          hideContainerStyles
          onChange={({ isValid, isEmpty, query }) => {
            commands.viewpointBuilderAdvancedSearchQueryUpdated({
              isValid,
              isEmpty,
              query,
            });
          }}
        />
      ) : (
        <FilterByNameAndTypeCriteria
          {...contextConfig}
          componentTypeSelectionDisabledReason="The context component type is set in the saved viewpoint."
          componentTypes={state.componentTypes}
          setComponentName={commands.setComponentName}
          componentName={state.componentName}
          selectedComponentType={state.componentType}
          setSelectedComponentType={commands.setComponentTypeName}
          setIsAdvancedSearch={commands.setIsAdvancedSearch}
        />
      )}
    </Island>
  );
};

export default SelectContextTabSearch;
