import { Maybe } from '@ardoq/common-helpers';
import { actionCreator } from '@ardoq/rxbeach';
import { ITPediaRoutes } from '../types';

const NAMESPACE = '[integrations it-pedia] NAVIGATION';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const navigateToPath = actionCreator<Maybe<ITPediaRoutes>>(
  getActionName('NAVIGATE_TO_PATH')
);
