import styled from 'styled-components';
import { Header4, Header5, Link, text2 } from '@ardoq/typography';
import { FlexBox, Stack } from '@ardoq/layout';
import {
  timeFastMs,
  r8,
  shadowS,
  colors,
  space,
  lines,
  r4,
} from '@ardoq/design-tokens';
import { useContext } from 'react';
import { WorkspaceSelectorContext } from './context';
import { TextOverflow } from '@ardoq/popovers';

const WorkspaceDetailsContainer = styled(Stack)`
  position: absolute;
  left: 100%;
  top: 0;
  width: 369px;
  min-height: 300px;
  height: 100%;
  border: ${lines.solidLightGrey1};
  border-top-right-radius: ${r8};
  border-bottom-right-radius: ${r8};
  box-shadow: ${shadowS};
  background-color: ${colors.bgDefault};
  overflow-y: auto;

  animation: fadeInAnimation ${timeFastMs} ease-in-out;
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const TypesContainer = styled(Stack)`
  padding: ${space.medium};
  border-top: ${lines.solidLightGrey1};
  gap: ${space.small};
  overflow-y: auto;
  max-height: 100%;
`;

const Paragraph = styled(Header5)`
  color: #818181;
`;

const Text = styled.div`
  ${text2};
  color: ${colors.textSubtle};
`;

const TypeContainer = styled(FlexBox)`
  min-height: 22px;
  flex-shrink: 0;
  gap: ${space.xsmall};
`;

const Type = styled.div`
  ${text2};
  border: ${lines.solidLightGrey1};
  border-radius: ${r4};
  padding: ${space.xxsmall} ${space.small};
  white-space: nowrap;
  background-color: ${colors.surfaceDefaultSubtle};
`;

export const WorkspaceDetails = () => {
  const wsInfo = useContext(WorkspaceSelectorContext);

  if (!wsInfo) return null;

  return (
    <WorkspaceDetailsContainer>
      <FlexBox flexDirection="column" gap="small" padding="medium">
        <Header4>{wsInfo.name}</Header4>
        <TextOverflow renderIn="tooltip" lineClamp={2}>
          <Text>{wsInfo.description}</Text>
        </TextOverflow>

        <Link
          href={`app/view/pagesView/workspace/${wsInfo.id}`}
          target="_blank"
        >
          Open Workspace
        </Link>
      </FlexBox>
      <TypesContainer>
        <Paragraph>Component types</Paragraph>
        <TypeContainer flexWrap>
          {wsInfo.componentTypes.length > 0
            ? wsInfo.componentTypes.map((type, i) => (
                <Type key={`component-type${i}`}>{type}</Type>
              ))
            : 'No components.'}
        </TypeContainer>

        <Paragraph>Reference types</Paragraph>
        <TypeContainer flexWrap>
          {wsInfo.referenceTypes.length > 0
            ? wsInfo.referenceTypes.map((type, i) => (
                <Type key={`reference-type${i}`}>{type}</Type>
              ))
            : 'No components.'}
        </TypeContainer>
      </TypesContainer>
    </WorkspaceDetailsContainer>
  );
};
