import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { ensureCloudProvider } from 'integrations/cloudProviders/utils';
import { combineLatest, map, switchMap } from 'rxjs';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { TransferState } from 'integrations/common/streams/transferState/types';
import { ImportRoute } from 'integrations/common/navigation/types';
import { isPathReady } from 'integrations/common/navigation/utils';
import {
  IntegrationId,
  TabularMapping,
} from 'integrations/common/streams/tabularMappings/types';
import { TablePreviews } from 'integrations/common/streams/tablePreviews/types';
import { getSelectDataStepState, isStepAvailable } from './utils';
import { SelectionState } from './streams/selectionState/types';
import { RegionsState } from 'integrations/cloudProviders/streams/regions/types';
import { ResourceTypesState } from 'integrations/cloudProviders/streams/resourceTypes/types';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { selectionState$ } from './streams/selectionState/selectionState$';
import {
  getConfigureStepState,
  getReviewStepState,
  getScheduleStepState,
  getScheduleStepTitle,
} from 'integrations/common/utils/step';
import {
  ScheduleState,
  getScheduleStream,
} from 'integrations/common/streams/schedule/schedule$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToPath } from 'integrations/common/navigation/actions';
import {
  getSchedulesStream,
  SchedulesState,
} from 'integrations/common/streams/schedules/getSchedulesStream';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { TransferConfigsState } from 'integrations/common/streams/transferConfigs/types';
import { StepDefinition } from './types';
import { isValidSelectionState } from './streams/selectionState/utils';
import { OverlayState, overlay$ } from './streams/overlay$';

type ViewModelProps = {
  activeIntegration: ActiveIntegrationState;
  connectionsState: IntegrationConnectionsState;
  regionsState: RegionsState;
  resourceTypesState: ResourceTypesState;
  schedule: ScheduleState;
  selectionState: SelectionState;
  tablePreviews: TablePreviews;
  tabularMapping: TabularMapping;
  transferState: TransferState;
  overlay: OverlayState;
  schedules: SchedulesState;
  transferConfigs: TransferConfigsState;
};

const handleStepClickNavigation = (
  integrationId: IntegrationId,
  path: ImportRoute
) => {
  dispatchAction(
    navigateToPath({
      integrationId,
      path,
      source: 'stepper',
    })
  );
};

const buildModel = ({
  activeIntegration,
  connectionsState,
  regionsState,
  resourceTypesState,
  schedule,
  selectionState,
  tablePreviews,
  tabularMapping,
  transferState,
  overlay,
  schedules,
  transferConfigs,
}: ViewModelProps) => {
  const { integrationPath, integrationId } = activeIntegration;
  const isDisabledStep = (path: ImportRoute) =>
    !isPathReady(path, {
      tabularMapping,
      tablePreviews,
      transferState,
      integrationId: 'aws-v3',
    }) ||
    !isStepAvailable(path, {
      selectionState,
      tabularMapping,
      transferState,
      regionsState,
      resourceTypesState,
    });

  const isValidSelection = isValidSelectionState({
    connectionsState,
    regionsState,
    resourceTypesState,
  });

  const steps: StepDefinition[] = [
    {
      step: ImportRoute.SELECT_DATA,
      label: 'Select Data',
      onPress: () =>
        handleStepClickNavigation(integrationId, ImportRoute.SELECT_DATA),
      state: getSelectDataStepState({
        integrationPath,
        connectionsState,
        regionsState,
        resourceTypesState,
      }),
      isSelected: integrationPath === ImportRoute.SELECT_DATA,
      isDisabled: isDisabledStep(ImportRoute.SELECT_DATA),
    },
    {
      step: ImportRoute.CONFIGURE,
      label: 'Configure data',
      onPress: () =>
        handleStepClickNavigation(integrationId, ImportRoute.CONFIGURE),
      state: getConfigureStepState({
        integrationPath,
        tabularMapping,
        configureRoute: ImportRoute.CONFIGURE,
      }),
      isSelected: integrationPath === ImportRoute.CONFIGURE,
      isDisabled: isDisabledStep(ImportRoute.CONFIGURE),
    },
    {
      step: ImportRoute.REVIEW,
      label: 'Review import',
      onPress: () =>
        handleStepClickNavigation(integrationId, ImportRoute.REVIEW),
      state: getReviewStepState({ transferState }),
      isSelected: integrationPath === ImportRoute.REVIEW,
      isDisabled: isDisabledStep(ImportRoute.REVIEW),
    },
    {
      step: ImportRoute.IMPORT_AND_SCHEDULE,
      label: getScheduleStepTitle(schedule.scheduleStage),
      onPress: () =>
        handleStepClickNavigation(
          integrationId,
          ImportRoute.IMPORT_AND_SCHEDULE
        ),
      state: getScheduleStepState({ transferState, schedule }),
      isSelected: integrationPath === ImportRoute.IMPORT_AND_SCHEDULE,
      isDisabled: isDisabledStep(ImportRoute.IMPORT_AND_SCHEDULE),
    },
  ];

  return {
    activeIntegration,
    steps,
    schedule,
    isSavingDisabled: !isValidSelection,
    overlay,
    schedules,
    transferConfigs,
  };
};

export const viewModel$ = integrationId$.pipe(
  ensureCloudProvider(),
  switchMap(integrationId =>
    combineLatest({
      activeIntegration: getActiveIntegrationStream(integrationId),
      connectionsState: getConnectionsStream(integrationId),
      regionsState: getRegionsStream(integrationId),
      resourceTypesState: getResourceTypesStream(integrationId),
      schedule: getScheduleStream(integrationId),
      selectionState: selectionState$,
      tablePreviews: getTablePreviewsStream(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
      transferState: getTransferStateStream(integrationId),
      overlay: overlay$,
      schedules: getSchedulesStream(integrationId),
      transferConfigs: transferConfigs$,
    })
  ),
  map(buildModel)
);
