import { useState } from 'react';
import { APIOrganizationUser, ArdoqId } from '@ardoq/api-types';
import { s16 } from '@ardoq/design-tokens';
import { Switch, TextInput } from '@ardoq/forms';
import { IconName } from '@ardoq/icons';
import { ModalSize, ModalTemplate } from '@ardoq/modal';
import { Pagination, PaginationController } from '@ardoq/pagination';
import { FlyWheelTable, SelectableDatasourceTable } from '@ardoq/table';
import styled from 'styled-components';
import { satisfiesSearchFilter } from 'admin/orgMembers/utils';
import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import { Island, PageBody } from '@ardoq/page-layout';

const StyledSelectableDatasourceTable = styled(SelectableDatasourceTable)`
  /* the height the table becomes when you have no results */
  min-height: 396px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyContent = ({ searchPhrase }: { searchPhrase: string }) => (
  <Container>
    <DoqWithSpeechBubble
      doqType={DoqType.MISSING_ORG}
      layout={DoqLayout.DIALOG}
      title="No results found"
      message={`There is no admin name matching '${searchPhrase}'`}
    />
  </Container>
);

const StyledTextInput = styled(TextInput)`
  width: 350px;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type NotificationRecipientsModalProps = {
  surveyNotificationsAudience: ArdoqId[];
  admins: APIOrganizationUser[];
  resolve: (resultValue: false | ArdoqId[]) => void;
};

const NotificationRecipientsModal = ({
  surveyNotificationsAudience = [],
  admins,
  resolve,
}: NotificationRecipientsModalProps) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [isOnlyShowSelectedToggled, setIsOnlyShowSelectedToggled] =
    useState(false);

  const [newestSurveyNotificationsAudience, updateSurveyNotificationsAudience] =
    useState(surveyNotificationsAudience);

  const satisfiesSelectedToggle = (user: APIOrganizationUser) => {
    if (!isOnlyShowSelectedToggled) return true;
    return newestSurveyNotificationsAudience.includes(user._id);
  };

  const filteredAdmins = admins.filter(
    user =>
      satisfiesSearchFilter(user, searchPhrase) && satisfiesSelectedToggle(user)
  );

  const clearUpdatedAudience = () => {
    updateSurveyNotificationsAudience([]);
  };

  return (
    <ModalTemplate
      modalSize={ModalSize.M}
      headerText="Send to Other Audience"
      onCloseButtonClick={() => resolve(false)}
      primaryButtonText="Add"
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={() => {
        resolve(false);
        clearUpdatedAudience();
      }}
      onPrimaryButtonClick={() => {
        resolve(newestSurveyNotificationsAudience);
      }}
    >
      <PageBody>
        <Island
          title="Select admin audience"
          subtitle="Only admins are currently available."
        >
          <FilterWrapper>
            <StyledTextInput
              leftIconName={IconName.SEARCH}
              onValueChange={setSearchPhrase}
              placeholder="Search users..."
              value={searchPhrase}
            />
            <Switch
              name="toggle-selected-emails"
              isChecked={isOnlyShowSelectedToggled}
              onChange={setIsOnlyShowSelectedToggled}
              label="Show selected audience"
            />
          </FilterWrapper>
          <PaginationController
            dataSource={filteredAdmins}
            perPage={9}
            render={({
              currentPageDataSource,
              sortBy,
              sortById,
              sortOrder,
              onPageSelect,
              currentPageNumber,
            }) => (
              <>
                <StyledSelectableDatasourceTable
                  dataSource={currentPageDataSource}
                  components={FlyWheelTable}
                  selected={newestSurveyNotificationsAudience}
                  setSelected={updateSurveyNotificationsAudience}
                  renderEmptyTable={() => (
                    <EmptyContent searchPhrase={searchPhrase} />
                  )}
                  columns={[
                    {
                      title: 'Email',
                      dataIndex: 'email',
                      onHeaderClick: () => sortBy('email'),
                    },
                  ]
                    .filter(Boolean)
                    .map(({ dataIndex, ...rest }) => ({
                      sortOrder: sortById === dataIndex && sortOrder,
                      dataIndex,
                      ...rest,
                    }))}
                />
                <Pagination
                  style={{ justifyContent: 'center', padding: s16 }}
                  currentPageNumber={currentPageNumber}
                  perPage={9}
                  totalResults={filteredAdmins.length}
                  onPageSelect={onPageSelect}
                />
              </>
            )}
          />
        </Island>
      </PageBody>
    </ModalTemplate>
  );
};

export default NotificationRecipientsModal;
