import { EMPTY, combineLatest, map, of, switchMap } from 'rxjs';
import { dispatchAction } from '@ardoq/rxbeach';
import { confirm, ModalSize } from '@ardoq/modal';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import {
  isConfigurableTable,
  removeTableMappingOption,
} from 'integrations/common/streams/tabularMappings/utils';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { DATA_SYNC_STRATEGY_TEXTS } from 'integrations/common/pages/tabularConfigMapping/constants';
import { DataSyncStrategy } from '@ardoq/api-types/integrations';
import { applyTableMapping } from 'integrations/common/streams/tabularMappings/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import {
  getHardDeletePopoverText,
  getSyncTagsText,
  isHardDeleteDisabled,
} from './utils';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      currentTableId: getActiveIntegrationStream(integrationId).pipe(
        map(ci => ci.currentTableId)
      ),
      tabularMapping: getTabularMappingStream(integrationId),
      integrationTermsDictionary:
        getIntegrationTermsDictionaryStream(integrationId),
    });
  }),
  switchMap(
    ({
      integrationId,
      tabularMapping,
      currentTableId,
      integrationTermsDictionary,
    }) => {
      if (!currentTableId) return EMPTY;

      const tableMapping = tabularMapping[currentTableId];
      const syncOption = tableMapping?.syncOption || '';
      const deleteSettingsEnabled = !!tableMapping?.options?.deletionStrategy;

      const onDeleteSetting = (enabled: boolean) => {
        if (enabled) {
          dispatchAction(
            applyTableMapping({
              integrationId,
              tableId: currentTableId,
              tableMapping: {
                options: {
                  deletionStrategy: 'delete',
                },
              },
            })
          );
        } else {
          dispatchAction(
            applyTableMapping({
              integrationId,
              tableId: currentTableId,
              tableMapping: {
                options: removeTableMappingOption(
                  'deletionStrategy',
                  tableMapping?.options
                ),
              },
            })
          );
        }
      };

      return of({
        syncOption,
        additionalHardDeleteWarningText:
          integrationTermsDictionary.additionalHardDeleteWarningText,
        isHardDeleteDisabled: isHardDeleteDisabled(tableMapping),
        hardDeletePopoverText: getHardDeletePopoverText(
          isHardDeleteDisabled(tableMapping),
          syncOption
        ),
        syncTags: getSyncTagsText(deleteSettingsEnabled, syncOption),
        deleteSettingsEnabled,
        configurationAllowed: isConfigurableTable(tableMapping),
        hasRowRepresentation: !!tableMapping?.rowRepresentation,
        dataSyncStrategyOptions: Object.entries(DATA_SYNC_STRATEGY_TEXTS).map(
          ([value, label]) => ({
            label,
            value,
            isDisabled:
              value === DataSyncStrategy.CREATE_ONLY && deleteSettingsEnabled,
          })
        ),
        onToggle: (isExpanded: boolean) => {
          dispatchAction(
            trackIntegrationEvent({
              integrationId,
              name: 'TOGGLED_DATA_SYNC_STRATEGY_SECTION',
              metadata: { action: isExpanded ? 'expand' : 'collapse' },
            })
          );
        },
        onDeleteSettingsChecked: async (checked: boolean) => {
          if (!checked) {
            onDeleteSetting(checked);

            dispatchAction(
              trackIntegrationEvent({
                integrationId,
                name: 'SELECT_DATA_SYNC_STRATEGY_HARD_DELETE_OPTION',
                metadata: { action: 'unselected' },
              })
            );
            return;
          }

          const result = await confirm({
            title: 'Allow automatically deleting missing assets?',
            text: 'When activated, this feature ensures data accuracy in Ardoq by automatically deleting any missing assets from the imported data in the table.',
            text2: 'The assets will be permanently deleted.',
            modalSize: ModalSize.M,
            confirmButtonTitle: 'Allow deleting',
            cancelButtonTitle: "Don't allow",
          });
          onDeleteSetting(!!result);

          if (result) {
            dispatchAction(
              trackIntegrationEvent({
                integrationId,
                name: 'SELECT_DATA_SYNC_STRATEGY_HARD_DELETE_OPTION',
                metadata: {
                  action: 'selected',
                },
              })
            );
          }
        },
        onDataSyncStrategySelect: (dataSyncStrategy: DataSyncStrategy) => {
          dispatchAction(
            applyTableMapping({
              integrationId,
              tableId: currentTableId,
              tableMapping: {
                syncOption: dataSyncStrategy,
              },
            })
          );
          dispatchAction(
            trackIntegrationEvent({
              integrationId,
              name: 'SELECT_DATA_SYNC_STRATEGY_OPTION',
              metadata: {
                option: dataSyncStrategy,
              },
            })
          );
        },
      });
    }
  )
);
