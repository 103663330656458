import ViewpointNavigatorItem from './ViewpointNavigatorItem';
import { ViewpointNavigatorList } from './atoms';
import { ViewpointNavigatorListItem } from './types';
import { ViewpointNavigatorCommands } from './viewpointNavigatorCommands';

type Props = {
  configPanelItems: ViewpointNavigatorListItem[];
  viewpointNavigatorCommands: ViewpointNavigatorCommands;
};

const ViewpointNavigator = ({
  configPanelItems,
  viewpointNavigatorCommands,
}: Props) => {
  return (
    <ViewpointNavigatorList>
      {configPanelItems.map((entry, blockIndex) => {
        return (
          <ViewpointNavigatorItem
            itemsCount={configPanelItems.length}
            {...entry}
            key={entry.key}
            viewpointNavigatorCommands={viewpointNavigatorCommands}
            blockIndex={blockIndex}
          />
        );
      })}
    </ViewpointNavigatorList>
  );
};

export default ViewpointNavigator;
