import { CreateRelationshipsLink } from '../../../relationshipDiagrams/types';
import type { RelationshipsNode, RelationshipsLink } from '../types';
import {
  PARENT_CHILD_LINE_TYPE,
  processSimilarLinks,
  getLabelWithFormattingForViewsNotSupportingMultilabeling,
} from '@ardoq/graph';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { referenceInterface } from '@ardoq/reference-interface';
import { lineTypeDashArrays } from 'tabview/canvasRendering/consts';
import { APIEntityType } from '@ardoq/api-types';
import { loadedGraph$ } from 'traversals/loadedGraph$';

const createLink = (): CreateRelationshipsLink<
  RelationshipsLink,
  RelationshipsNode
> => {
  const linksBySourceAndTarget = new Map<string, RelationshipsLink[]>();
  return (graphEdge, nodeMap, referenceType) => {
    const { modelId } = graphEdge;
    const dashArray =
      lineTypeDashArrays[referenceType?.line ?? PARENT_CHILD_LINE_TYPE];
    const link: RelationshipsLink = {
      source: nodeMap.get(graphEdge.source)!,
      target: nodeMap.get(graphEdge.target)!,
      lineBeginning: graphEdge.getLineBeginning(),
      lineEnding: graphEdge.getLineEnding(),
      modelId,
      dashArray,
      similarLinksIndex: 0,
      similarLinksCount: 1,
      globalTypeId: referenceInterface.getGlobalTypeId(modelId),
      label: loadedGraph$.state.isViewpointMode
        ? getLabelWithFormattingForViewsNotSupportingMultilabeling({
            modelId,
            entityLabel: graphEdge.getLabel(),
            entityType: APIEntityType.REFERENCE,
          })
        : referenceInterface.getDisplayLabel(modelId),
      matchingFilterIds: modelId
        ? (filterInterface
            .getColorFiltersForReference(modelId)
            ?.map(filter => filter.cid) ?? null)
        : null,
    };

    processSimilarLinks(link, linksBySourceAndTarget);
    return link;
  };
};
export default createLink;
