import { Subdivision } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { SubdivisionEditorSteps } from 'subdivisionEditor/navigation/types';

const DOMAIN = '[subdivisionEditorViewModel$]';

export const updateSubdivision = actionCreator<Partial<Subdivision>>(
  `${DOMAIN} UPDATE_SUBDIVISION_PROPERTIES`
);

export const touchStep = actionCreator<SubdivisionEditorSteps>(
  `${DOMAIN} TOUCH_STEP`
);

export const setCollapsedStep = actionCreator<{
  step: SubdivisionEditorSteps;
  collapsed: boolean;
}>(`${DOMAIN} COLLAPSE_STEP`);

export const subdivisionEditorChangesAreSaved = actionCreator<{
  silent: boolean;
}>(`${DOMAIN} CHANGES_ARE_SAVED`);

export const deletingSubdivision = actionCreator<Subdivision>(
  `${DOMAIN} DELETING_SUBDIVISION`
);

export const resetStepStates = actionCreator(`${DOMAIN} RESET_STEP_STATES`);
