import { actionCreator } from '@ardoq/rxbeach';
import {
  SelectSubdivisionEditorStepActionPayload,
  SubdivisionNavigationState,
} from './types';

const DOMAIN = '[subdivision navigation]';

export const selectSubdivision = actionCreator<SubdivisionNavigationState>(
  `${DOMAIN} SELECT_SUBDIVISION`
);

export const selectSubdivisionEditorStep =
  actionCreator<SelectSubdivisionEditorStepActionPayload>(
    `${DOMAIN} SELECT_SUBDIVISION_EDITOR_STEP`
  );
