import { SelectedViewOption, SelectedViewState } from './types';

const getDefaultState = (): SelectedViewState => ({
  selectedView: null,
});

const setSelectedView = (
  _: SelectedViewState,
  selectedView: SelectedViewOption
): SelectedViewState => ({
  selectedView,
});

export const selectedViewOperations = {
  setSelectedView,
  getDefaultState,
};
