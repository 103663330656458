/**
 * @deprecated use truncate from lodash
 */
export const truncate = (value: string, length = 200) =>
  value.length > length ? `${value.substring(0, length)}...` : value;

/**
 * @deprecated use capitalize from lodash
 */
export const capitalize = (str: string) =>
  str.slice(0, 1).toUpperCase() + str.slice(1);

export const snakeCaseToHumanReadable = (word: string) =>
  word.split('-').join(' ');
