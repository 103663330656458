import { reducer } from '@ardoq/rxbeach';
import { GroupsTabState, PerspectivesEditorState } from '../../types';
import {
  UpdateGroupingRulePayload,
  createGroupingRule as createGroupingRuleAction,
  deleteGroupingRule as deleteGroupingRuleAction,
  reorderGroupingRules as reorderGroupingRulesAction,
  updateGroupingRule as updateGroupingRuleAction,
} from '../actions';
import {
  PerspectiveGroupingRule,
  PerspectivesGroupsOptions,
  addNewGroupingRule,
  deleteGroupingRule,
  updateGroupingRules,
} from '@ardoq/perspectives';
import { notifyGroupingOptionsChanged } from '../../../actions';
import { removeGroupingRulesBasedOnInvalidOptions } from '../removeGroupingRulesBasedOnInvalidOptions';

export const getGroupsTabInitialState: () => GroupsTabState = () => ({
  groupingRules: [],
  isAppliedGroupingIncluded: false,
  groupOptions: {
    appliedGroupingRules: [],
    workspaces: [],
    componentTypesPerWorkspace: {},
    referenceTypesPerWorkspace: {},
    fieldsPerWorkspace: {},
    tagsPerWorkspace: {},
    shouldShowSubdivisionsOption: false,
  },
});

export const clearGroupingTabState = (
  state: PerspectivesEditorState
): GroupsTabState => ({
  ...state.groupsTab,
  groupingRules: [],
});

const handleCreateGroupingRule = (
  state: PerspectivesEditorState,
  newGroupingRule: PerspectiveGroupingRule
) => ({
  ...state,
  groupsTab: {
    ...state.groupsTab,
    groupingRules: addNewGroupingRule(
      state.groupsTab.groupingRules,
      newGroupingRule
    ),
  },
});

const handleUpdateGroupingRule = (
  state: PerspectivesEditorState,
  { updatedGroupingRuleId, updatedGroupingRule }: UpdateGroupingRulePayload
) => ({
  ...state,
  groupsTab: {
    ...state.groupsTab,
    groupingRules: updateGroupingRules(
      state.groupsTab.groupingRules,
      updatedGroupingRuleId,
      updatedGroupingRule
    ),
  },
});

const handleReorderGroupingRules = (
  state: PerspectivesEditorState,
  reorderedGroupingRules: PerspectiveGroupingRule[]
) => ({
  ...state,
  groupsTab: {
    ...state.groupsTab,
    groupingRules: reorderedGroupingRules,
  },
});

const deleteGroupingRuleActionReducer = (
  state: PerspectivesEditorState,
  deletedGroupingRuleId: string
) => ({
  ...state,
  groupsTab: {
    ...state.groupsTab,
    groupingRules: deleteGroupingRule(
      state.groupsTab.groupingRules,
      deletedGroupingRuleId
    ),
  },
});
const handleDeleteGroupingRule = reducer(
  deleteGroupingRuleAction,
  deleteGroupingRuleActionReducer
);

const getNextGroupingRules = (
  state: PerspectivesEditorState,
  groupsOptions: PerspectivesGroupsOptions
) => {
  if (!state.groupsTab.isAppliedGroupingIncluded) {
    // apply grouping rules that are already applied in the main app on perspective editor init
    return groupsOptions.appliedGroupingRules;
  }

  return removeGroupingRulesBasedOnInvalidOptions(
    state.groupsTab.groupingRules,
    groupsOptions
  );
};

/**
 * notifyGroupingOptionsChange is emitted by the postMessageBridge and informs that the groupingOptions have changed.
 */
const handleNotifyGroupingOptionsChange = (
  state: PerspectivesEditorState,
  groupsOptions: PerspectivesGroupsOptions
) => {
  const nextGroupingRules = getNextGroupingRules(state, groupsOptions);

  return {
    ...state,
    groupsTab: {
      ...state.groupsTab,
      isAppliedGroupingIncluded: true,
      groupingRules: nextGroupingRules,
      groupOptions: groupsOptions,
    },
  };
};

export const groupTabReducers = [
  reducer(notifyGroupingOptionsChanged, handleNotifyGroupingOptionsChange),
  reducer(createGroupingRuleAction, handleCreateGroupingRule),
  reducer(updateGroupingRuleAction, handleUpdateGroupingRule),
  reducer(reorderGroupingRulesAction, handleReorderGroupingRules),
  handleDeleteGroupingRule,
];
