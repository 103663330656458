import { FormSize, TextInput } from '@ardoq/forms';
import { ZoneCommands } from '../types';
import { IconName } from '@ardoq/icons';
import { useState } from 'react';
import { DropdownMenu, DropdownSize } from '@ardoq/dropdown-menu-ui';
import { Box, Stack } from '@ardoq/layout';
import { TabButton, TabsGroup, TabsTheme } from '@ardoq/tabs';
import { debounce } from 'lodash';

const COMPONENT_TYPE_FIELDS_GROUP_SEARCH_HTML_ID =
  'component-type-field-group-search';

export enum SearchByTypes {
  COMPONENT_TYPE = 'component-type',
  FIELD = 'field',
}

export const ComponentTypeFieldsGroupSearch = ({
  searchKey,
  searchBy,
  commands,
  isDisabled,
}: {
  searchKey: string;
  searchBy: SearchByTypes;
  commands: ZoneCommands;
  isDisabled?: boolean;
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleInputBlur = debounce(() => {
    if (
      null ===
      document.activeElement?.closest(
        `#${COMPONENT_TYPE_FIELDS_GROUP_SEARCH_HTML_ID}`
      )
    ) {
      setIsActive(false);
    }
  });

  return (
    <Box
      id={COMPONENT_TYPE_FIELDS_GROUP_SEARCH_HTML_ID}
      onFocus={() => setIsActive(true)}
      onBlur={handleInputBlur}
    >
      <Stack>
        <TextInput
          value={searchKey}
          onValueChange={commands.changeComponentTypeFieldsGroupSearchKey}
          formSize={FormSize.DEFAULT}
          leftIconName={IconName.SEARCH}
          placeholder="Search for component type or field"
          autoComplete="off"
          onClear={() => commands.changeComponentTypeFieldsGroupSearchKey('')}
          isDisabled={isDisabled}
        />
        {isActive && (
          <Box position="relative">
            <Box position="absolute" style={{ zIndex: 10, top: 4 }}>
              <DropdownMenu dropdownSize={DropdownSize.M}>
                <Box padding="small">
                  <TabsGroup
                    theme={TabsTheme.COLOR_FILL}
                    activeTabId={searchBy}
                    onTabChange={tabId =>
                      commands.changeComponentTypeFieldsGroupSearchBy(
                        tabId as SearchByTypes
                      )
                    }
                  >
                    <TabButton
                      label="Component type"
                      tabId={SearchByTypes.COMPONENT_TYPE}
                    ></TabButton>
                    <TabButton
                      label="Field"
                      tabId={SearchByTypes.FIELD}
                    ></TabButton>
                  </TabsGroup>
                </Box>
              </DropdownMenu>
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
