import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { header3 } from '@ardoq/typography';

const HeaderWrapper = styled.div`
  padding: 32px;
  display: flex;
  justify-content: space-between;

  p {
    padding: 0;
    margin: 10px 0 0;
  }
`;

const Title = styled.h3`
  text-transform: none;
  color: ${colors.grey15};
  margin: 0;
  ${header3}
`;

interface BodyHeaderProps {
  title?: string;
  description?: string | ReactNode;
  children?: ReactNode;
  style?: CSSProperties;
}
const BodyHeader = ({ description, style, ...props }: BodyHeaderProps) => (
  <HeaderWrapper style={style}>
    <div style={{ flex: 1 }}>
      <Title>{props.title}</Title>
      {description && <p>{description}</p>}
    </div>
    <div
      style={{
        flexShrink: 0,
        marginLeft: '20px',
      }}
    >
      {props.children}
    </div>
  </HeaderWrapper>
);

export default BodyHeader;
