import { trackEvent } from '../../tracking/tracking';
import {
  ClientRequestIdAndStartSet,
  InferredTraversalEvent,
  OpenViewInferenceWindowPayload,
  SuggestTraversalEvent,
} from './types';
import { APIInferTraversalRequest } from '@ardoq/api';
import { ClientRequest, UserEvent } from '../../sync/types';
import { ardoqEventOperations } from '../../sync/ardoqEventOperations';
import { currentDate } from '@ardoq/date-time';

enum ViewInferenceTrackingEventNames {
  OPENED_INFER_TRAVERSAL_WINDOW = 'Viewpoint inference opened infer traversal window',
  FETCHED_TRAVERSAL_SUGGESTIONS = 'Viewpoint inference fetched traversal suggestions',
  TRAVERSAL_SUGGESTIONS_TIMED_OUT = 'Viewpoint inference traversal suggestions timed out',
  APPLIED_TRAVERSAL_SUGGESTION = 'Viewpoint inference applied traversal suggestion',
  FETCHED_INFER_TRAVERSAL = 'Viewpoint inference fetched infer traversal',
  INFER_TRAVERSAL_TIMED_OUT = 'Viewpoint inference infer traversal timed out',
}

export const trackOpenedInferTraversalWindow = ({
  componentIds,
}: OpenViewInferenceWindowPayload) =>
  trackEvent(ViewInferenceTrackingEventNames.OPENED_INFER_TRAVERSAL_WINDOW, {
    numberOfSelectedComponents: componentIds.length,
  });

export const trackSuggestTraversalTimeout = () =>
  trackEvent(ViewInferenceTrackingEventNames.TRAVERSAL_SUGGESTIONS_TIMED_OUT);

export const trackInferTraversalTimeout = () =>
  trackEvent(ViewInferenceTrackingEventNames.INFER_TRAVERSAL_TIMED_OUT);

// use clientRequestId as key to measure how long it takes to get suggestions
const startTimeForSuggestAndInferExecution: Record<string, Date> = {};
export const saveSuggestTraversalStartTime = ({
  clientRequestId,
}: ClientRequestIdAndStartSet) => {
  startTimeForSuggestAndInferExecution[clientRequestId] = currentDate();
};

const startTimeAndInputForInferTraversal: Record<
  string,
  { startTime: Date; input: string }
> = {};

export const saveInferTraversalStartTimeAndInput = ({
  clientRequestId,
  input,
}: APIInferTraversalRequest) => {
  startTimeAndInputForInferTraversal[clientRequestId] = {
    startTime: currentDate(),
    input: input,
  };
};

export const trackTraversalSuggestions = (
  suggestEvent: UserEvent<ClientRequest<SuggestTraversalEvent>>
) => {
  const end = currentDate();
  const clientRequestId = ardoqEventOperations.getClientRequestId(suggestEvent);
  if (!clientRequestId) {
    return;
  }
  const startTime = startTimeForSuggestAndInferExecution[clientRequestId];
  if (!startTime) {
    return;
  }

  trackEvent(ViewInferenceTrackingEventNames.FETCHED_TRAVERSAL_SUGGESTIONS, {
    durationInMilliseconds: end.valueOf() - startTime.valueOf(),
    numberOfSuggestions: suggestEvent.data.suggestions.length,
  });
};

export const trackInferTraversal = (
  inferEvent: UserEvent<ClientRequest<InferredTraversalEvent>>
) => {
  const end = currentDate();
  const clientRequestId = ardoqEventOperations.getClientRequestId(inferEvent);
  if (!clientRequestId) {
    return;
  }
  const { startTime, input } =
    startTimeAndInputForInferTraversal[clientRequestId];
  if (!startTime) {
    return;
  }

  const paths = inferEvent.data?.traversal?.paths;

  if (!Array.isArray(paths) || paths.length === 0) {
    return;
  }

  trackEvent(ViewInferenceTrackingEventNames.FETCHED_INFER_TRAVERSAL, {
    durationInMilliseconds: end.valueOf() - startTime.valueOf(),
    numberOfPaths: inferEvent.data.traversal.paths.length,
    inputLength: input.length,
    numberOfWordsInInput: input.split(' ').length,
  });
};

export const trackAppliedTraversalSuggestion = () =>
  trackEvent(ViewInferenceTrackingEventNames.APPLIED_TRAVERSAL_SUGGESTION);
