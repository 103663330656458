import { TableMappingType } from '@ardoq/api-types/integrations';
import { TabularMappingsDefaultState } from 'integrations/common/streams/tabularMappingsDefault/types';
import fp from 'lodash/fp';

export const DEFAULT_TABULAR_MAPPING: TabularMappingsDefaultState = {
  user: {
    defaultRowRepresentation: TableMappingType.COMPONENTS,
    defaultColumnMappings: {
      displayName: {
        columnType: 'component',
        componentType: 'Person',
        hierarchyLevel: 0,
        index: -1,
        sourceFieldName: 'displayName',
      },
      mail: {
        columnType: 'field',
        fieldType: 'Email',
        fieldName: 'contact_email',
        fieldLabel: 'Contact Email',
        index: -1,
        sourceFieldName: 'mail',
      },
    },
  },
};

export const DEFAULT_TABLE_FIELDS: Record<string, string[]> = fp.flow(
  fp.entries,
  fp.map(([key, value]) => [key, fp.keys(value.defaultColumnMappings)]),
  fp.fromPairs
)(DEFAULT_TABULAR_MAPPING);
