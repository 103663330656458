import { reducer } from '@ardoq/rxbeach';
import { setFilter } from './actions';
import { SummaryAndLaunchDialogState } from './types';
import {
  showLaunchBroadcastDialog,
  showLaunchCurrentBroadcastDialog,
} from 'broadcasts/actions';

const handleSetFilter = (
  state: SummaryAndLaunchDialogState,
  filter: string
): SummaryAndLaunchDialogState => ({
  ...state,
  filter,
});

const handleShowLaunchBroadcastDialog = (
  state: SummaryAndLaunchDialogState
): SummaryAndLaunchDialogState => ({
  ...state,
  filter: '',
});

const handleShowLaunchCurrentBroadcastDialog = (
  state: SummaryAndLaunchDialogState
): SummaryAndLaunchDialogState => ({
  ...state,
  filter: '',
});

export const reducers = [
  reducer(setFilter, handleSetFilter),
  reducer(showLaunchBroadcastDialog, handleShowLaunchBroadcastDialog),
  reducer(
    showLaunchCurrentBroadcastDialog,
    handleShowLaunchCurrentBroadcastDialog
  ),
];
