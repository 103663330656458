import * as React from 'react';
import EntityBrowser, {
  EntityBrowserProps,
} from 'components/EntityBrowser/EntityBrowser';
import {
  ColumnsOptions,
  Folder,
  Row,
  RowItem,
} from 'components/EntityBrowser/types';
import { Column } from '@ardoq/table';
import {
  getSelectRowsFn,
  searchDatasourceByName,
  sortDataSource,
  useSorting,
} from 'components/EntityBrowser/utils';
import { withErrorBoundaries } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import { AssetRow } from 'components/AssetsBrowser/types';
import { ColumnsProps, getDefaultAssetsBrowserColumns } from './columns';
import { SortOrder, SortParams } from '@ardoq/api-types';
import { getCurrentLocale } from '@ardoq/locale';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { connect } from '@ardoq/rxbeach';
import { PermissionContext } from '@ardoq/access-control';
import { map } from 'rxjs';

export type AssetsBrowserProps = {
  selected: string[];
  loading?: boolean;
  skipExpandOnSearch?: boolean;
  initialSortParams?: SortParams;
  onSortParamsChange?: (sortParams: SortParams) => void;
  setSelected: (selected: string[]) => void;
  dataSource: { [key: string]: any }[];
  scrollableSectionHeight?: number | string;
  searchPhrase?: string;
  onAssetClick?: (row: AssetRow) => void;
  onAssetPreviewClick?: (row: AssetRow) => void;
  onAssetOpenInViewpointModeClick?: (row: AssetRow) => void;
  renderHeader?: () => JSX.Element | null;
  components?: Record<string, React.ComponentType>;
  dragDropProps?: EntityBrowserProps<RowItem>['dragDropProps'];
  folderIds?: string[];
  renameId?: string;
  setExpandedFoldersIds?: (ids: string[]) => void;
  expandedFoldersIds?: string[];
  renameCancel?: () => void;
  renameConfirm?: (newName: string, row: Row<AssetRow>) => void;
  onTableClick?: (
    e: React.MouseEvent<HTMLTableElement, MouseEvent>,
    trElement: Row<AssetRow> | null
  ) => void;
  tableHeight?: number | null;
  getCustomColumns?: (
    columnProps: ColumnsProps
  ) => (
    options: ColumnsOptions<AssetRow>
  ) => Column<AssetRow | Folder<AssetRow>>[];
  emptyState?: JSX.Element;
  skipDefaultSortOrder?: boolean;
  style?: React.CSSProperties;
};

export type AssetBrowserStateProps = {
  permissionContext: PermissionContext;
};

const AssetsBrowser = ({
  dataSource,
  selected,
  setSelected,
  expandedFoldersIds,
  setExpandedFoldersIds,
  renameId,
  renameCancel,
  renameConfirm,
  initialSortParams,
  onSortParamsChange = () => {},
  searchPhrase,
  folderIds,
  onAssetClick,
  onAssetPreviewClick,
  onAssetOpenInViewpointModeClick,
  getCustomColumns,
  skipDefaultSortOrder = true,
  permissionContext,
  ...baseProps
}: AssetsBrowserProps & AssetBrowserStateProps) => {
  const [sortOrder, sortByField, setSortByField] = useSorting({
    ...(initialSortParams || {
      sortOrder: SortOrder.ASC,
      sortByField: 'name',
    }),
    skipDefaultSortOrder,
    onSortChange: (newSortOrder, newSortByField) =>
      onSortParamsChange({
        sortOrder: newSortOrder,
        sortByField: newSortByField,
      }),
  });

  const locale = getCurrentLocale();

  const filteredDataSource = searchDatasourceByName(
    dataSource,
    searchPhrase || '',
    locale
  );

  const sortedAndFilteredDataSource = sortDataSource(
    filteredDataSource,
    sortByField,
    sortOrder
  );

  const selectRows = getSelectRowsFn(
    setSelected,
    selected,
    sortedAndFilteredDataSource
  );

  const columnsProps = {
    selected,
    setSelected,
    dataSource: filteredDataSource,
    folderIds,
    setExpandedFoldersIds,
    sortByField,
    sortOrder,
    setSortByField,
    selectRows,
    expandedFoldersIds,
    renameId,
    renameConfirm,
    renameCancel,
    permissionContext,
  };

  const getColumns =
    getCustomColumns?.(columnsProps) ??
    getDefaultAssetsBrowserColumns(columnsProps);

  return (
    <EntityBrowser
      {...baseProps}
      onRowClick={onAssetClick}
      onRowPreviewClick={onAssetPreviewClick}
      onOpenInViewpointModeClick={onAssetOpenInViewpointModeClick}
      searchPhrase={searchPhrase}
      expandedFoldersIds={expandedFoldersIds}
      setExpandedFoldersIds={setExpandedFoldersIds}
      selectRows={selectRows}
      selected={selected}
      getColumns={getColumns}
      dataSource={sortedAndFilteredDataSource}
    />
  );
};

export default connect(
  withErrorBoundaries({
    errorContextDescription: 'Error at <AssetsBrowser />',
    logError,
  })(AssetsBrowser),
  currentUserPermissionContext$.pipe(
    map(permissionContext => ({ permissionContext }))
  )
);
