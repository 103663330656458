import { EntityItem, Section } from './atoms';
import DeletedComponentWithCollapsible from './DeletedComponentWithCollapsible';
import DeletedReferenceWithIndentation from './DeletedReferenceWithIndentation';
import DeletedComponentWithIndentation from './DeletedComponentWithIndentation';
import {
  DeletedComponentProperties,
  DeletedEntitiesProperties,
  DeletedReferenceProperties,
} from './types';
import { DeletedComponent, DeletedReference } from 'restoreDeleted/types';

const hasChildComponents = (entity: DeletedComponent) =>
  entity.descendants.length;

const getContentComponent = (
  entityType: 'component' | 'reference',
  entity: DeletedComponent | DeletedReference
) => {
  if (entityType === 'reference') return DeletedReferenceWithIndentation;
  if (
    entityType === 'component' &&
    hasChildComponents(entity as DeletedComponent)
  ) {
    return DeletedComponentWithCollapsible;
  }
  return DeletedComponentWithIndentation;
};

const DeletedEntitiesSection = (props: DeletedEntitiesProperties) => {
  const { entityType, entities } = props;
  return (
    <Section>
      <div>
        {entities.map((entity, index) => {
          const ContentComponent = getContentComponent(entityType, entity);
          const childProps = { [entityType]: entity } as
            | DeletedComponentProperties
            | DeletedReferenceProperties;
          return (
            <EntityItem key={index}>
              {/* @ts-expect-error original JSX code doesn't work well with TypeScript, but this is okay */}
              <ContentComponent {...childProps} />
            </EntityItem>
          );
        })}
      </div>
    </Section>
  );
};

export default DeletedEntitiesSection;
