import { persistentReducedStream } from '@ardoq/rxbeach';
import reducers, { defaultState } from './reducers';
import { UnsavedState } from './types';

const unsavedState$ = persistentReducedStream<UnsavedState>(
  'unsavedState',
  defaultState,
  reducers
);

export default unsavedState$;
