import { dispatchAction } from '@ardoq/rxbeach';
import { selectSubdivisionEditorStep } from 'subdivisionEditor/navigation/actions';
import {
  SubdivisionEditorSteps,
  SubdivisionEditorSubStep,
} from 'subdivisionEditor/navigation/types';
import { subdivisionEditorStepsOperations } from 'subdivisionEditor/subdivisionEditorStepsOperations';
import { SubdivisionViewModelState } from 'subdivisionEditor/types';
import {
  deletingSubdivision,
  setCollapsedStep,
  subdivisionEditorChangesAreSaved,
  updateSubdivision,
} from './actions';
import { ArdoqId, Subdivision } from '@ardoq/api-types';
import {
  openCreateSubdivision,
  openSubdivisionEditor,
} from 'components/AppMainSidebar/utils';
import {
  DIVISIONS_USER_EVENTS,
  trackDivisionEditorUserEvent,
} from 'subdivisionEditor/trackingUtils';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';
import { debounce } from 'lodash';
import { navigateToAccessControlPage } from 'router/navigationActions';
import { AccessControlTabs } from 'admin/accessControl/navigation/types';
import { renderSubdivisionSummaryInDrawer } from 'subdivisionEditor/SubdivisionOverview/subdivisionOverviewTableUtils';
import { fetchSubdivisionSummary } from 'subdivisionEditor/SubdivisionOverview/subdivisionSummary$/actions';

const nextStep = async (subdivisionEditorState: SubdivisionViewModelState) => {
  // Check if the next step button has custom logic
  const onClick = subdivisionEditorStepsOperations.getOnNextButtonClicked(
    subdivisionEditorState
  );
  const nextStep = subdivisionEditorStepsOperations.getNextStep(
    subdivisionEditorState,
    subdivisionEditorState.subdivisionEditorStep
  );
  const currentStepIsLastStep = subdivisionEditorStepsOperations.isLastStep(
    subdivisionEditorState
  );
  trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.CLICKED_NEXT_STEP, {
    previousStep: subdivisionEditorState.subdivisionEditorStep,
    nextStep:
      nextStep?.step ?? (currentStepIsLastStep ? 'overview' : undefined),
  });
  if (onClick && !(await onClick(subdivisionEditorState))) {
    return;
  }
  if (currentStepIsLastStep) {
    return goToSubdivisionOverview();
  }

  if (!nextStep) {
    return;
  }
  setStep(subdivisionEditorState, nextStep.step);
};

const previousStep = async (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  // Check if the previous step button has custom logic
  const onClick = subdivisionEditorStepsOperations.getOnPreviousButtonClicked(
    subdivisionEditorState
  );
  if (onClick && !(await onClick(subdivisionEditorState))) {
    return;
  }
  const pStep = subdivisionEditorStepsOperations.getPreviousStep(
    subdivisionEditorState,
    subdivisionEditorState.subdivisionEditorStep
  );
  if (!pStep) {
    return;
  }
  setStep(subdivisionEditorState, pStep.step);
};

const getGroupsInDivisionCount = (
  subdivisionEditorState: SubdivisionViewModelState
): number => {
  return new Set(
    Object.values(
      subdivisionEditorState.zonesState.permissionsByZoneId
    ).flatMap(({ groups }) => groups.map(({ name }) => name))
  ).size;
};

const trackSavingEvent = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  if (subdivisionEditorOperations.isCreationMode(subdivisionEditorState)) {
    trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.CREATED_NEW_DIVISION);
  } else {
    trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.SAVE_CHANGES, {
      groupsInDivisionCount: getGroupsInDivisionCount(subdivisionEditorState),
      fieldConfigCount: Object.keys(subdivisionEditorState.zonesState.zonesById)
        .length,
    });
  }
};

const saveChanges = (subdivisionEditorState: SubdivisionViewModelState) => {
  trackSavingEvent(subdivisionEditorState);

  const savings = subdivisionEditorOperations
    .getTouchedSteps(subdivisionEditorState)
    .map(step => {
      const stepConfig = subdivisionEditorStepsOperations.getStepConfigs(
        subdivisionEditorState,
        step
      );

      if (stepConfig?.onSave) {
        return stepConfig.onSave(subdivisionEditorState);
      }
      return Promise.resolve(true);
    });
  return Promise.all(savings).then(results => {
    if (results.every(result => result))
      dispatchAction(subdivisionEditorChangesAreSaved({ silent: false }));
  });
};

const setStep = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps,
  subStep?: SubdivisionEditorSubStep
) => {
  trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.SELECTED_EDITOR_STEP, {
    previousStep: subdivisionEditorState.subdivisionEditorStep,
    previousSubStep: subdivisionEditorState.subdivisionEditorSubStep,
    newStep: step,
    newSubStep: subStep,
  });
  return (
    !subdivisionEditorState.isSubmitting &&
    dispatchAction(
      selectSubdivisionEditorStep({
        subdivisionEditorStep: step,
        subdivisionEditorSubStep: subStep,
      })
    )
  );
};

const trackDetailsPageEvent = debounce(
  (
    subdivisionEditorState: SubdivisionViewModelState,
    event: DIVISIONS_USER_EVENTS
  ) => {
    trackDivisionEditorUserEvent(event, {
      isCreateNewDivision: subdivisionEditorOperations.isCreationMode(
        subdivisionEditorState
      ),
    });
  },
  3000,
  { leading: true, trailing: false }
);

const updateTitle = (
  subdivisionEditorState: SubdivisionViewModelState,
  name: string
) => {
  trackDetailsPageEvent(
    subdivisionEditorState,
    DIVISIONS_USER_EVENTS.CHANGED_DIVISION_NAME
  );
  dispatchAction(updateSubdivision({ name }));
};

const updateDescription = (
  subdivisionEditorState: SubdivisionViewModelState,
  description: string
) => {
  trackDetailsPageEvent(
    subdivisionEditorState,
    DIVISIONS_USER_EVENTS.CHANGED_DIVISION_DESCRIPTION
  );
  dispatchAction(updateSubdivision({ description }));
};

const updateColor = (
  subdivisionEditorState: SubdivisionViewModelState,
  color: string
) => {
  trackDetailsPageEvent(
    subdivisionEditorState,
    DIVISIONS_USER_EVENTS.CHANGED_DIVISION_COLOR
  );
  dispatchAction(updateSubdivision({ style: { color } }));
};

const removeSubdivision = (subdivision: Subdivision) => {
  trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.OPEN_DELETE_DIVISION);
  dispatchAction(deletingSubdivision(subdivision));
};

const createSubdivision = () => {
  trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.OPEN_EDITOR, {
    isCreateNewDivision: true,
  });
  openCreateSubdivision();
};

const goToSubdivision = (subdivisionId: ArdoqId) => {
  trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.OPEN_EDITOR, {
    isCreateNewDivision: false,
  });
  openSubdivisionEditor(subdivisionId);
};

const goToSubdivisionOverview = () => {
  dispatchAction(
    navigateToAccessControlPage({
      accessControlTab: AccessControlTabs.SUBDIVISIONS_OVERVIEW,
    })
  );
};

const setStepExpanded = (step: SubdivisionEditorSteps, expanded: boolean) => {
  dispatchAction(setCollapsedStep({ step, collapsed: !expanded }));
};

const viewSubdivisionSummary = (subdivision: Subdivision) => {
  renderSubdivisionSummaryInDrawer(subdivision);
  dispatchAction(fetchSubdivisionSummary(subdivision._id));
};

export const subdivisionEditorCommands = {
  nextStep,
  previousStep,
  setStep,
  updateTitle,
  updateDescription,
  updateColor,
  saveChanges,
  removeSubdivision,
  createSubdivision,
  goToSubdivision,
  goToSubdivisionOverview,
  setStepExpanded,
  viewSubdivisionSummary,
};
