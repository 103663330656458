import { Direction } from 'viewpointBuilder/types';
import { logError } from '@ardoq/logging';
import {
  BooleanOperator,
  CreatedItemsLoadedStateParams,
  DirectedTripleWithFilters,
  HexIndex,
  LoadedStateParams,
  SearchLoadedStateParams,
  TraversalLoadedStateParams,
  StartContextSelectionType,
  LoadedStateType,
  Operator,
  Path,
  QueryBuilderQuery,
  QueryBuilderRow,
  QueryBuilderRuleValue,
  StartQueryTraversalParams,
  StartSetTraversalParams,
  ViewpointBuilderFilters,
  TraversalPathMatchingType,
} from '@ardoq/api-types';
import { defaultKeys } from './defaultKeys';
import { FILTER_ID_PREFIX } from 'viewpointBuilder/getId';
import {
  BASE,
  CLOSE_AND_RULES,
  CLOSE_OR_RULES,
  FILTER_SEPARATOR,
  ID_SEPARATOR,
  OPEN_AND_RULES,
  OPEN_OR_RULES,
  PATH_SEPARATOR,
  QUERY_PROPERTIES_COUNT,
  SEARCH_SEPARATOR,
  STATE_SEPARATOR,
  TOKEN_SEPARATOR,
  TRAVERSAL_SEPARATOR,
} from '../consts';

const STATIC_TRIPLE_PROPERTIES_COUNT = 4;

const loadedTypesArray: LoadedStateType[] = [
  LoadedStateType.SEARCH,
  LoadedStateType.TRAVERSAL,
];

const decodeType = (type: number) => loadedTypesArray[type];

const addFilterPrefix = (filterId?: string) => `${FILTER_ID_PREFIX}${filterId}`;

export const decodeTraversal = (
  encodedTraversal: string,
  hexIndexToToken: Record<string, string | undefined>
): TraversalLoadedStateParams => {
  const [
    isHidden,
    startContextType,
    traversalId,
    encodedStartSet,
    encodedStartQuery,
    encodedPaths,
    encodedFilters,
  ] = encodedTraversal.split(TRAVERSAL_SEPARATOR);

  const startSet = encodedStartSet.split(ID_SEPARATOR);
  let startQuery;
  try {
    startQuery = encodedStartQuery
      ? decodeSearchQuery(encodedStartQuery, hexIndexToToken)
      : undefined;
  } catch (e) {
    logError(Error('Parsing of loadedState.startQuery failed'));
  }
  const tokens = Object.values(hexIndexToToken);

  const directionIndex: Record<string, Direction> = {
    [getPaddedKey(0, tokens)]: 'outgoing',
    [getPaddedKey(1, tokens)]: 'incoming',
  };

  const paths = decodePaths(
    encodedPaths,
    tokens,
    hexIndexToToken,
    directionIndex
  );
  const filters = decodeFilters(encodedFilters, tokens, hexIndexToToken);

  const data = startQuery
    ? ({
        componentSelection: {
          startQuery,
          startContextSelectionType:
            Number(startContextType) ===
            StartContextSelectionType.BASIC_SEARCH_SELECT_ALL
              ? StartContextSelectionType.BASIC_SEARCH_SELECT_ALL
              : StartContextSelectionType.ADVANCED_SEARCH_SELECT_ALL,
        },
        paths,
        filters,
        pathMatching: TraversalPathMatchingType.LOOSE,
        pathCollapsingRules: [],
      } satisfies StartQueryTraversalParams)
    : ({
        componentSelection: {
          startSet,
          startContextSelectionType: StartContextSelectionType.MANUAL_SELECTION,
        },
        paths,
        filters,
        pathMatching: TraversalPathMatchingType.LOOSE,
        pathCollapsingRules: [],
      } satisfies StartSetTraversalParams);
  return {
    traversalId,
    isHidden: !!Number(isHidden),
    type: LoadedStateType.TRAVERSAL,
    data,
  };
};

const getTokenIndex = (tokens: string[]) =>
  Object.fromEntries(
    tokens.map((token, index) => [
      getPaddedKey(index, tokens),
      token === 'undefined' ? undefined : token,
    ])
  );

const decodePaths = (
  encodedPaths: string,
  tokens: (string | undefined)[],
  hexIndexToToken: Record<HexIndex, string | undefined>,
  directionIndex: Record<string, Direction>
): Path[] => {
  const encodedPathToHexIndexes = getEncodedPathToHexIndexes(tokens);
  return encodedPaths
    .split(PATH_SEPARATOR)
    .map(path => encodedPathToHexIndexes(path))
    .map(pathAsHexIndexes =>
      pathAsHexIndexes.map(
        ([
          setFilterIdsAsDigit,
          directionAsHexIndex,
          sourceTypeAsHexIndex,
          referenceTypeAsHexIndex,
          targetTypeAsHexIndex,
          ...filterIds
        ]) => {
          return {
            direction: directionIndex[directionAsHexIndex],
            sourceType: hexIndexToToken[sourceTypeAsHexIndex]!,
            referenceType: hexIndexToToken[referenceTypeAsHexIndex]!,
            targetType: hexIndexToToken[targetTypeAsHexIndex]!,
            ...getFilterIdsAsDictionary(
              hexIndexToToken,
              filterIds,
              setFilterIdsAsDigit
            ),
          } satisfies DirectedTripleWithFilters;
        }
      )
    );
};

const decodeFilters = (
  encodedFilters: string,
  tokens: (string | undefined)[],
  hexIndexToToken: Record<HexIndex, string | undefined>
): ViewpointBuilderFilters => {
  if (encodedFilters === '') return {};

  const decodeFilter = getDecodeFilter(tokens);
  return Object.fromEntries(
    encodedFilters.split(FILTER_SEPARATOR).map(encodedFilter => {
      const { filterId, condition, rulesAsHexIndexes } =
        decodeFilter(encodedFilter);
      const rules = rulesAsHexIndexes.map(
        ([
          idAsHexIndex,
          fieldAsHexIndex,
          inputAsHexIndex,
          typeAsHexIndex,
          valueAsHexIndex,
          operatorAsHexIndex,
        ]) => {
          return {
            id: hexIndexToToken[idAsHexIndex]!,
            field: hexIndexToToken[fieldAsHexIndex]!,
            input: hexIndexToToken[inputAsHexIndex]!,
            type: hexIndexToToken[typeAsHexIndex]!,
            value: decodeToFilterValue(
              hexIndexToToken[typeAsHexIndex],
              hexIndexToToken[valueAsHexIndex]
            ) as string | number | boolean | null,
            operator: hexIndexToToken[operatorAsHexIndex] as Operator,
          };
        }
      );
      return [
        addFilterPrefix(hexIndexToToken[filterId]),
        {
          filterId: addFilterPrefix(hexIndexToToken[filterId]),
          condition: hexIndexToToken[condition] as BooleanOperator,
          rules,
        },
      ];
    })
  );
};

const getDecodeFilter = (tokens: (string | undefined)[]) => {
  const tokenLength = getMaxSizeFromTokens(tokens);
  const regExpIdConditionRules = new RegExp(
    `^(.{${tokenLength}})(.{${tokenLength}})(.*)$`
  );
  const regExpRules = new RegExp(`.{${tokenLength * 6}}`, 'g');
  const regExpRuleProps = new RegExp(`.{${tokenLength}}`, 'g');
  const mapper = (
    encodedFilter: string
  ): {
    filterId: string;
    condition: string;
    rulesAsHexIndexes: string[][];
  } => {
    const [, filterId, condition, allRules] = encodedFilter.match(
      regExpIdConditionRules
    )!;
    const rules = allRules.match(regExpRules)!;
    return {
      filterId,
      condition,
      rulesAsHexIndexes: rules.map(rule => rule.match(regExpRuleProps)!),
    };
  };
  return mapper;
};

const decodeToFilterValue = (
  type?: string,
  value?: string
): QueryBuilderRuleValue | undefined => {
  if (!(typeof type === 'string' && typeof value === 'string')) {
    logError(
      Error('Decoding of filter value failed: missing values in token map')
    );
    return value;
  }
  if (value === 'null') return null;
  if (value === 'undefined') return undefined;

  switch (type) {
    case 'number':
      return Number.parseInt(value, 10);
    case 'boolean':
      return value === 'true';

    default:
      return value;
  }
};

export const decodeSearch = (
  encodedSearch: string,
  hexIndexToToken: Record<string, string | undefined>
): SearchLoadedStateParams | CreatedItemsLoadedStateParams => {
  const [
    isHidden,
    startContextType,
    isCreatedItemsLoadedState,
    componentIds,
    referenceIds,
    query,
  ] = encodedSearch.split(SEARCH_SEPARATOR);
  if (Number(isCreatedItemsLoadedState)) {
    return {
      type: LoadedStateType.CREATED_ITEMS,
      isHidden: !!Number(isHidden),
      data: {
        componentIds: componentIds ? componentIds.split(ID_SEPARATOR) : [],
        referenceIds: referenceIds ? referenceIds.split(ID_SEPARATOR) : [], // avoid array with empty string
      },
    };
  }

  return {
    type: LoadedStateType.SEARCH,
    isHidden: !!Number(isHidden),
    data: {
      componentSelection: query
        ? {
            startQuery: decodeSearchQuery(query, hexIndexToToken),
            startContextSelectionType:
              Number(startContextType) ===
              StartContextSelectionType.BASIC_SEARCH_SELECT_ALL
                ? StartContextSelectionType.BASIC_SEARCH_SELECT_ALL
                : StartContextSelectionType.ADVANCED_SEARCH_SELECT_ALL,
          }
        : {
            startContextSelectionType:
              StartContextSelectionType.MANUAL_SELECTION,
            startSet: componentIds ? componentIds.split(ID_SEPARATOR) : [],
          },
    },
  };
};

export const decodeSearchQuery = (
  encodedRules: string,
  hexIndexToToken: Record<string, string | undefined>
): QueryBuilderQuery => {
  const tokens = Object.values(hexIndexToToken);
  const maxSize = getMaxSizeFromTokens(tokens);

  const splitter = new RegExp(`(${'.'.repeat(maxSize)})`, 'g');
  const toKeys = (str: string) => str.match(splitter)!;

  return decodeRules(
    encodedRules,
    hexIndexToToken,
    maxSize,
    toKeys
  )[0] as QueryBuilderQuery; // we know that the first rule is the QueryBuilderQuery
};

const decodeRules = (
  encodedRules: string,
  hexIndexToToken: Record<string, string | undefined>,
  maxSize: number,
  toKeys: (str: string) => string[]
): QueryBuilderRow[] => {
  const parsedRules = [];
  let processedEncodedRules = encodedRules;
  while (processedEncodedRules.length > 0) {
    if (
      processedEncodedRules.startsWith(OPEN_AND_RULES) ||
      processedEncodedRules.startsWith(OPEN_OR_RULES)
    ) {
      const openingChar = processedEncodedRules[0];
      const closingChar =
        openingChar === OPEN_AND_RULES ? CLOSE_AND_RULES : CLOSE_OR_RULES;
      const closingIndex = getClosingIndex(
        processedEncodedRules,
        openingChar,
        closingChar
      );
      if (closingIndex < 0) throw Error('Decoding search query rule faild');
      const rules = decodeRules(
        processedEncodedRules.slice(1, closingIndex),
        hexIndexToToken,
        maxSize,
        toKeys
      );
      parsedRules.push({
        condition:
          openingChar === OPEN_AND_RULES
            ? BooleanOperator.AND
            : BooleanOperator.OR,
        rules,
      });
      processedEncodedRules = processedEncodedRules.slice(closingIndex + 1);
    } else {
      const keys = toKeys(
        processedEncodedRules.slice(0, maxSize * QUERY_PROPERTIES_COUNT)
      );

      const [id, type, input, field, operator, value] = keys.map(
        key => hexIndexToToken[key]
      );

      if (
        id !== undefined &&
        type !== undefined &&
        input !== undefined &&
        field !== undefined &&
        operator !== undefined &&
        value !== undefined
      ) {
        parsedRules.push({
          id,
          type,
          input,
          field,
          operator: operator as Operator,
          value: decodeToFilterValue(type, value) ?? null,
        });
      } else {
        logError(
          Error(
            'Decoding of search query rule failed with undefined properties'
          )
        );
      }
      processedEncodedRules = processedEncodedRules.slice(
        maxSize * QUERY_PROPERTIES_COUNT
      );
    }
  }
  return parsedRules;
};

const getClosingIndex = (
  encodedRules: string,
  openingChar: string,
  closingChar: string
) => {
  let count = 0;
  let index = -1;
  for (const char of encodedRules) {
    index++;
    if (char === openingChar) count++;
    if (char === closingChar) count--;
    if (count === 0) return index;
  }
  return -1;
};

const getPaddedKey = (() => {
  const maxSizes: Record<number, number> = {};

  return (n: number, tokens: (string | undefined)[]) => {
    let maxSize = maxSizes[tokens.length - 1];
    if (!maxSize) {
      maxSizes[tokens.length - 1] = getMaxSizeFromTokens(tokens);
      maxSize = maxSizes[tokens.length - 1];
    }
    return n.toString(BASE).padStart(maxSize, '0');
  };
})();

const getMaxSizeFromTokens = (tokens: (string | undefined)[]) =>
  (tokens.length - 1).toString(BASE).length;

const getEncodedPathToHexIndexes = (tokens: (string | undefined)[]) => {
  const maxHexIndexSize = getMaxSizeFromTokens(tokens);
  const splitter = new RegExp(`.{${maxHexIndexSize}}`, 'g');
  const encodedPathsToHexIndexes = (
    encodedPaths: string,
    hexIndexes: string[][] = []
  ): string[][] => {
    if (encodedPaths.length === 0) return hexIndexes;

    const setFilterIdsAsDigit = encodedPaths[0];
    const tripleLength =
      maxHexIndexSize *
      (STATIC_TRIPLE_PROPERTIES_COUNT +
        getSetFilterIdsCount(Number.parseInt(setFilterIdsAsDigit, BASE)));
    const tripleAsHexIndexes = encodedPaths
      .slice(1, 1 + tripleLength)
      .match(splitter)!;

    return encodedPathsToHexIndexes(encodedPaths.slice(1 + tripleLength), [
      ...hexIndexes,
      [setFilterIdsAsDigit, ...tripleAsHexIndexes],
    ]);
  };
  return encodedPathsToHexIndexes;
};

const getFilterIdsAsDictionary = (
  hexIndexToToken: Record<string, string | undefined>,
  filters: string[],
  setFilterIdsAsDigit: string
) => {
  const [first, second, third] = filters;
  switch (setFilterIdsAsDigit) {
    // '000'.
    case '0':
      return {};
    // '001'.
    case '1':
      return { referenceFilter: addFilterPrefix(hexIndexToToken[first]) };
    // '010'.
    case '2':
      return { targetFilter: addFilterPrefix(hexIndexToToken[first]) };
    // '011'.
    case '3':
      return {
        targetFilter: addFilterPrefix(hexIndexToToken[first]),
        referenceFilter: addFilterPrefix(hexIndexToToken[second]),
      };
    // '100'.
    case '4':
      return { sourceFilter: addFilterPrefix(hexIndexToToken[first]) };
    // '101'.
    case '5':
      return {
        sourceFilter: addFilterPrefix(hexIndexToToken[first]),
        referenceFilter: addFilterPrefix(hexIndexToToken[second]),
      };
    // '110'.
    case '6':
      return {
        sourceFilter: addFilterPrefix(hexIndexToToken[first]),
        targetFilter: addFilterPrefix(hexIndexToToken[second]),
      };
    // '111'.
    case '7':
      return {
        sourceFilter: addFilterPrefix(hexIndexToToken[first]),
        targetFilter: addFilterPrefix(hexIndexToToken[second]),
        referenceFilter: addFilterPrefix(hexIndexToToken[third]),
      };

    default:
      logError(
        Error('Decoding of filter ids failed with invalid setFilterIdsAsDigit')
      );
      return {};
  }
};

const getSetFilterIdsCount = (setFilterIdsAsDigit: number): number =>
  (setFilterIdsAsDigit & 1) +
  ((setFilterIdsAsDigit >> 1) & 1) +
  ((setFilterIdsAsDigit >> 2) & 1);

const getTokenIndexFromEncodedTokens = (encodedTokens: string) => {
  const decodedTokens = encodedTokens
    .split(TOKEN_SEPARATOR)
    .map(token => decodeURIComponent(token));

  const tokens = [...defaultKeys, ...decodedTokens];

  return getTokenIndex(tokens);
};

export const decodeLoadedStateV2 = (
  encodedLoadedState: string
): LoadedStateParams[] => {
  if (encodedLoadedState === '') return [];

  try {
    const parts = encodedLoadedState.split(STATE_SEPARATOR);
    const encodedTokens = parts.pop()!;
    const hexIndexToToken = getTokenIndexFromEncodedTokens(encodedTokens);

    return parts.map(state => {
      const type = decodeType(Number.parseInt(state[0], 10));
      const encodedState = state.slice(1);

      if (type === LoadedStateType.SEARCH)
        return decodeSearch(encodedState, hexIndexToToken);

      if (type === LoadedStateType.TRAVERSAL)
        return decodeTraversal(encodedState, hexIndexToToken);

      throw Error(`Can't decode loaded state with type ${type}`);
    });
  } catch (e) {
    logError(Error('Decoding loaded state failed'));
    return [];
  }
};
