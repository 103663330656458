import React from 'react';
import { ColorBar } from '@ardoq/page-layout';
import { Icon, ICON_SELECTOR, IconName } from '@ardoq/icons';
import { ButtonGroup, IconButton } from '@ardoq/button';
import { ColorBarBigButton } from '@ardoq/snowflakes';
import styled from 'styled-components';
import { header4, text2 } from '@ardoq/typography';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { IslandBody, IslandContainer } from '@ardoq/page-layout';

type ButtonsConfig = {
  label: string;
  onClick: () => void;
  dataTestId: string;
  color: string;
  isDisabled?: boolean;
  icon?: IconName;
};

type NewTransformSectionProps = {
  title: string;
  subtitle: string;
  showButtons: boolean;
  buttonsConfig: ButtonsConfig[];
  onAddButtonClick: () => void;
  onCloseButtonClick: () => void;
};

export const AddTransformButtons = ({
  title,
  subtitle,
  showButtons,
  buttonsConfig,
  onAddButtonClick,
  onCloseButtonClick,
}: NewTransformSectionProps) => {
  if (!showButtons) {
    return (
      <IslandContainerWithHover onClick={onAddButtonClick}>
        <IslandBody $spacing="medium">
          <Flex>
            <StyledIconButton
              iconName={IconName.ADD}
              onClick={onAddButtonClick}
            />
            <TextSection>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
            </TextSection>
          </Flex>
        </IslandBody>
      </IslandContainerWithHover>
    );
  }

  return (
    <IslandContainer>
      <IslandBody $spacing="medium">
        <Flex>
          <ButtonGroup>
            <StyledIconButton
              iconName={IconName.CLOSE}
              data-tooltip-text="Cancel"
              dataTestId="cancel-button"
              onClick={onCloseButtonClick}
            />
            {buttonsConfig.map((button, i) => {
              const BigButton = button.icon ? IconBigButton : ColorBarBigButton;
              return (
                <BigButton
                  key={i}
                  onClick={button.onClick}
                  dataTestId={button.dataTestId}
                  isDisabled={button?.isDisabled}
                >
                  {button.icon ? (
                    <Icon iconName={button.icon} style={{ margin: 0 }} />
                  ) : (
                    <ColorBar $barColor={button.color} />
                  )}
                  {button.label}
                </BigButton>
              );
            })}
          </ButtonGroup>
        </Flex>
      </IslandBody>
    </IslandContainer>
  );
};

const IslandContainerWithHover = styled(IslandContainer)`
  :hover {
    cursor: pointer;
    background-color: ${colors.grey95};
    border-radius: 16px;
  }
`;

const Title = styled.div`
  ${header4};
  display: flex;
  align-items: center;
`;

const Subtitle = styled.div`
  ${text2};
  color: ${colors.grey35};
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

const StyledIconButton = styled(IconButton)`
  ${ICON_SELECTOR} {
    color: ${colors.blue50};
  }
`;

const Flex = styled.div<{ $height?: number }>`
  display: flex;
  gap: ${s16};
  align-items: center;
  height: 72px;
`;

const IconBigButton = styled(ColorBarBigButton)`
  gap: ${s8};
`;
