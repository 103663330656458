import { Chip, ChipsList, ChipsSeparator } from '@ardoq/status-ui';
import { AssetType } from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';
import { SelectableAssetType } from './types';
import { ExpandedFoldersFiltersByType } from './expandedFolders$';
import { trackEvent } from 'tracking/tracking';

type SelectAssetTypeBarProps = {
  scenariosEnabled?: boolean;
  toggleFilter: (type: SelectableAssetType | null) => void;
  filters: ExpandedFoldersFiltersByType;
  toggleShowFavoritesOnly: () => void;
  showFavoritesOnly: boolean;
  presentationsEnabled: boolean;
  surveysEnabled: boolean;
  traversalsEnabled: boolean;
  bookmarksEnabled: boolean;
};

const SelectAssetTypeBar = ({
  scenariosEnabled,
  toggleFilter,
  filters,
  toggleShowFavoritesOnly,
  showFavoritesOnly,
  presentationsEnabled,
  surveysEnabled,
  traversalsEnabled,
  bookmarksEnabled,
}: SelectAssetTypeBarProps) => {
  return (
    <ChipsList>
      <Chip
        data-click-id="clear-filters-chip"
        onClick={() => toggleFilter(null)}
      >
        Clear
      </Chip>
      <ChipsSeparator />
      {traversalsEnabled && (
        <Chip
          data-click-id="filter-traversal-chip"
          isActive={filters[AssetType.TRAVERSAL]}
          onClick={() => toggleFilter(AssetType.TRAVERSAL)}
          iconName={IconName.ROCKET}
        >
          Traversals
        </Chip>
      )}
      <Chip
        data-click-id="filter-workspaces-chip"
        isActive={filters[AssetType.WORKSPACE]}
        onClick={() => toggleFilter(AssetType.WORKSPACE)}
        iconName={IconName.WORKSPACE}
      >
        Workspaces
      </Chip>
      {presentationsEnabled && (
        <Chip
          data-click-id="filter-presentations-chip"
          isActive={filters[AssetType.PRESENTATION]}
          onClick={() => toggleFilter(AssetType.PRESENTATION)}
          iconName={IconName.PRESENTATION}
        >
          Presentations
        </Chip>
      )}
      {bookmarksEnabled && (
        <Chip
          data-click-id="filter-bookmarks-chip"
          isActive={filters[AssetType.BOOKMARK]}
          onClick={() => toggleFilter(AssetType.BOOKMARK)}
          iconName={IconName.BOOKMARK}
        >
          Bookmarks
        </Chip>
      )}
      {surveysEnabled && (
        <Chip
          isActive={filters[AssetType.SURVEY]}
          onClick={() => {
            toggleFilter(AssetType.SURVEY);
            trackEvent('Assets Manager: clicked filter surveys chip');
          }}
          iconName={IconName.SURVEYS}
        >
          Surveys
        </Chip>
      )}
      <Chip
        data-click-id="filter-metamodels-chip"
        isActive={filters[AssetType.METAMODEL]}
        onClick={() => toggleFilter(AssetType.METAMODEL)}
        iconName={IconName.METAMODEL}
      >
        Metamodels
      </Chip>
      {scenariosEnabled && (
        <Chip
          data-click-id="filter-scenarios-chip"
          isActive={filters[AssetType.SCENARIO]}
          onClick={() => toggleFilter(AssetType.SCENARIO)}
          iconName={IconName.SCENARIO}
        >
          Scenarios
        </Chip>
      )}
      <Chip
        data-click-id="filter-reports-chip"
        isActive={filters[AssetType.REPORT]}
        onClick={() => toggleFilter(AssetType.REPORT)}
        iconName={IconName.DESCRIPTION}
      >
        Reports
      </Chip>
      <Chip
        data-click-id="filter-dashboards-chip"
        isActive={filters[AssetType.DASHBOARD]}
        onClick={() => toggleFilter(AssetType.DASHBOARD)}
        iconName={IconName.PIE_CHART_FILLED}
      >
        Dashboards
      </Chip>
      <ChipsSeparator />
      <Chip
        data-click-id="filter-favorites-chip"
        onClick={toggleShowFavoritesOnly}
        isActive={showFavoritesOnly}
        iconName={showFavoritesOnly ? IconName.STAR : IconName.STAR_BORDER}
      >
        Favorites
      </Chip>
    </ChipsList>
  );
};

export default SelectAssetTypeBar;
