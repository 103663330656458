import { Header } from '../atoms';
import scimManagement$, {
  ScimManagementState,
  checkIfTokenExists,
  resetScimState,
} from './scimManagement$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { useEffectOnce } from '@ardoq/hooks';
import {
  GenerateNewButton,
  NewTokenNotification,
  RevokeTokenButton,
  ScimButtonContainer,
  ScimError,
  ScimKBLink,
  ScimManagementSection,
  ScimManagementWrapper,
} from './atoms';
import { Island } from '@ardoq/page-layout';
import { Features, hasFeature } from '@ardoq/features';

const ManageSCIMToken = ({ hasToken }: ScimManagementState) => {
  useEffectOnce(() => {
    dispatchAction(checkIfTokenExists());
    return () => {
      dispatchAction(resetScimState());
    };
  });

  // HACK - Rafaa - Temporary until we can Merge the new Navigation pattern.
  const header = hasFeature(Features.PERMISSION_ZONES_INTERNAL)
    ? {}
    : {
        title: 'SCIM token',
        subtitle:
          'Generate a new SCIM token to manage users; it replaces the old one and must be kept secure.',
      };

  return (
    <Island {...header}>
      <ScimManagementWrapper>
        <ScimError />

        <ScimManagementSection>
          <NewTokenNotification />
          <p>
            Click the button below to generate a new authentication token to use
            for your SCIM integration. Each new token will replace the old one,
            which will no longer work. Anyone obtaining this token can manage
            users in this organization, so you should keep it secret.
          </p>
          <p>
            If you don’t know what SCIM is, or if this feature could be
            beneficial for you,{' '}
            <ScimKBLink>visit our knowledge base article.</ScimKBLink>
          </p>
          <ScimButtonContainer>
            <GenerateNewButton />
          </ScimButtonContainer>
        </ScimManagementSection>

        {hasToken && (
          <ScimManagementSection>
            <Header>Revoke existing token</Header>
            <p>
              There is an active SCIM token for this organzation. Click the
              button below to revoke it.
            </p>
            <ScimButtonContainer>
              <RevokeTokenButton />
            </ScimButtonContainer>
          </ScimManagementSection>
        )}
      </ScimManagementWrapper>
    </Island>
  );
};

export default connect(ManageSCIMToken, scimManagement$);
