import { GhostButton } from '@ardoq/button';
import { dispatchAction } from '@ardoq/rxbeach';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { ReturnLocation } from './types';
import * as gridEditor2023Actions from 'gridEditor2023/actions';

const dispatchShowGridEditor = () => {
  dispatchAction(gridEditor2023Actions.showGridEditor());
};

interface ReturnToButtonProp {
  onClick: () => void;
  text: string;
}

const ReturnToButtonBase = ({ text, onClick }: ReturnToButtonProp) => (
  <GhostButton onClick={onClick}>{text}</GhostButton>
);

const ReturnToFieldEditorButton = () => (
  <ReturnToButtonBase
    onClick={() =>
      dispatchAction(
        requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
      )
    }
    text="Return to field editor"
  />
);

const ReturnToWidgetResultsButton = () => (
  <ReturnToButtonBase
    onClick={() =>
      dispatchAction(
        requestShowAppModule({ selectedModule: AppModules.DASHBOARDS })
      )
    }
    text="Return to widget editor"
  />
);

const ReturnToGridEditorButton = () => (
  <ReturnToButtonBase
    onClick={() => {
      dispatchAction(
        requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
      );
      dispatchShowGridEditor();
    }}
    text="Return to grid editor"
  />
);

const ReturnButton = ({ returnLocation }: { returnLocation: ReturnLocation }) =>
  (returnLocation === ReturnLocation.FIELD_EDITOR && (
    <ReturnToFieldEditorButton />
  )) ||
  (returnLocation === ReturnLocation.WIDGET_RESULT && (
    <ReturnToWidgetResultsButton />
  )) ||
  (returnLocation === ReturnLocation.GRID_EDITOR && (
    <ReturnToGridEditorButton />
  )) ||
  null;

export default ReturnButton;
