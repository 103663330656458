import { reducer } from '@ardoq/rxbeach';
import { resetUnsavedState, setUnsavedState } from './actions';
import { UnsavedState, UnsavedStateOwner } from './types';

export const defaultState = {
  [UnsavedStateOwner.SSO_MAPPING]: false,
  [UnsavedStateOwner.SUBDIVISION_EDITOR]: false,
};

const handleSetUnsavedState = (
  state: UnsavedState,
  unsavedState: Partial<UnsavedState>
) => {
  return {
    ...state,
    ...unsavedState,
  };
};

const handleResetUnsavedState = () => defaultState;

export default [
  reducer(setUnsavedState, handleSetUnsavedState),
  reducer(resetUnsavedState, handleResetUnsavedState),
];
