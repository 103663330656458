import * as React from 'react';
import styled from 'styled-components';
import { s32 } from '@ardoq/design-tokens';
import { testId } from 'integrations/common/testUtils/testId';
import { Error } from 'integrations/common/containers/transfer/error/Error';
import { Overview } from 'integrations/common/containers/transfer/overview/Overview';
import {
  PageWrapperWithPadding,
  PageContainer,
} from 'integrations/common/pages/layout';
import { EMPTY, of, switchMap } from 'rxjs';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { TransferState } from 'integrations/common/streams/transferState/types';
import { connect } from '@ardoq/rxbeach';
import { Island } from '@ardoq/page-layout';

enum ReviewTestStatus {
  ERROR = 'error',
  OVERVIEW = 'overview',
}

type ReviewTestComponentProps = {
  reviewTestStatus: ReviewTestStatus;
  DryRunError?: React.ComponentType;
};

const ReviewTestComponent = ({
  reviewTestStatus,
  DryRunError,
}: ReviewTestComponentProps) => {
  return (
    <PageContainer>
      <PageWrapperWithPadding>
        <ReviewTestContainer {...testId('review-test-container')}>
          {reviewTestStatus === ReviewTestStatus.ERROR && <Error />}
          {reviewTestStatus === ReviewTestStatus.OVERVIEW && (
            <Island maxWidth="100%">
              <Overview />
              {DryRunError && <DryRunError />}
            </Island>
          )}
        </ReviewTestContainer>
      </PageWrapperWithPadding>
    </PageContainer>
  );
};

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => getTransferStateStream(integrationId)),
  switchMap((transferState: TransferState) => {
    if (transferState.requestStatus === 'FAILURE') {
      return of({ reviewTestStatus: ReviewTestStatus.ERROR });
    }
    if (transferState.requestStatus === 'SUCCESS') {
      return of({
        reviewTestStatus: ReviewTestStatus.OVERVIEW,
      });
    }

    return EMPTY;
  })
);

const ReviewTestContainer = styled.div`
  padding-top: ${s32};
  padding-bottom: ${s32};
  width: 100%;
`;

export const ReviewTest = connect(ReviewTestComponent, viewModel$);
