import { TimeSinceLastUpdate } from '@ardoq/api-types';

export const PEOPLE_WORKSPACE_ID_QUERY = `g.V().hasLabel('Person').values('rootWorkspace').dedup()`;
export const DEFAULT_TIME_SINCE_LAST_UPDATE = TimeSinceLastUpdate.THREE_MONTHS;

export const SURVEY_READONLY_PLACEHOLDER =
  '\n\n{color:rgb(58, 5, 58)/}\\{surveyLinks\\}{color}';

export const MESSAGE_READONLY_PLACEHOLDER =
  '\n\n{color:rgb(58, 5, 58)/}\\{componentList\\}{color}';

export const REPORT_READONLY_PLACEHOLDER =
  '\n\n{color:rgb(58, 5, 58)/}\\{linkToReport\\}{color}';

export const DEFAULT_SURVEY_BROADCAST_MESSAGE = {
  subject: 'Invitation to Ardoq Survey(s)',
  body: '# {color:rgb(58, 5, 58)/}Please Contribute to our Ardoq Survey{color}\n\nHi \\{audienceName\\},\n\nYou are invited to update information for \\{componentCount\\} \\{componentType\\}\\(s\\) in our \\{surveyName\\}.\n\nClick on the link\\(s\\) below to go directly to the survey.',
};

export const DEFAULT_MESSAGE_BROADCAST_MESSAGE = {
  subject: 'Message about {componentType}',
  body: '# {color:rgb(58, 5, 58)/}\\{componentType\\} Alert{color}\n\nHi \\{audienceName\\},\n\nThis message is to let you know that the \\{componentType\\}\\(s\\) below have \\(specify your filter condition here\\).',
};

export const DEFAULT_REPORT_BROADCAST_MESSAGE = {
  subject:
    'Alert about {reportName}: {Column} {Aggregate} of {Value} is {Condition} {Threshold}',
  body: '# {color:rgb(58, 5, 58)/}Alert about \\{reportName\\}{color}\n\nHi \\{audienceName\\},\n\nThis is to notify you that a column in the report \\{reportName\\} has reached the set threshold.\n\nThe **\\{Aggregate\\}** of **\\{Column\\}** is **\\{Value\\}**, which is **\\{Condition\\}** the threshold of **\\{Threshold\\}**.\n\n You can click the link below to see the details of the report.',
};

export const BROADCAST_BUILDER_NAMESPACE = 'broadcast builder';

export const DEFAULT_GREMLIN_AUDIENCE_DIALOG_QUERY = `g.V().hasId(ids).both().hasLabel('Person').path()`;

export const MAX_VISIBLE_QUERY_OPTIONS = 6;

export const HAS_MORE_ID = 'has-more';
