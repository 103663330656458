import { ArdoqId } from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';

export enum SurveyTrackingEventsNames {
  CLICKED_ON_NEW_SURVEY_IN_SURVEY_OVERVIEW = 'clicked_on_create_survey_in_survey_overview',
  CLICKED_ON_EDIT_ORGANIZATION_SETTINGS_IN_SURVEY_OVERVIEW = 'clicked_on_edit_organization_settings_in_survey_overview',
  CLICKED_ON_BACK_TO_OVERVIEW_IN_SURVEY_EDITOR = 'clicked_on_back_to_overview_in_survey_editor',
  OPENED_EXISTING_SURVEY = 'opened_existing_survey',
  OPENED_NEW_SURVEY_EDITOR = 'opened_new_survey_editor',
  OPENED_SURVEY_IN_SURVEYS_APP = 'opened_existing_survey_in_surveys_app',
  CLICKED_ON_HIERARCHY_ELEMENT_IN_SURVEY_EDITOR = 'clicked_on_hierarchy_element_in_survey_editor',
  CLICKED_ON_NAVIGATIONAL_BUTTON_IN_SURVEY_EDITOR = 'clicked_on_navigational_button_in_survey_editor',
}

export const trackOpenExistingSurvey = (resourceId?: ArdoqId, from?: string) =>
  trackEvent(SurveyTrackingEventsNames.OPENED_EXISTING_SURVEY, {
    resourceId,
    from,
  });

export const trackOpenExistingSurveyInSurveysApp = (
  resourceId?: ArdoqId,
  from?: string
) =>
  trackEvent(SurveyTrackingEventsNames.OPENED_SURVEY_IN_SURVEYS_APP, {
    resourceId,
    from,
  });

export const trackOpenNewSurveyEditor = () =>
  trackEvent(SurveyTrackingEventsNames.OPENED_NEW_SURVEY_EDITOR);

export const trackOpenChangeApprovalPage = (from?: string) => {
  trackEvent('Survey overview: Clicked on navigate to change approval page', {
    from,
  });
};
