import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { CSSProperties } from 'react';
import { MetaInfo, MetaInfoViewStatus } from 'streams/views/mainContent/types';

export type GridEditorOpenState = { isOpen: boolean; isPopoutOpen: boolean };

export type BaseAppLayoutState = {
  isGridEditorOpen: boolean;
  isGridEditorPoppedOut: boolean;
  isBottomBarVisible: boolean;
  isEditorPanelVisible: boolean;
  editorPaneStyle: CSSProperties;
  contentBarStyle: CSSProperties;
  editorHeight: number;
  resizeDirection: ResizeDirection | null;
  resizeStart: number;
  resizeStartDelta: number;
  resizeDelta: number;
  minResizeDelta: number;
  maxResizeDelta: number;
  resizeStyle: CSSProperties;
  container: HTMLDivElement | null;
  isSplitPane: boolean;
  leftPaneWidth: number;
  isRightEditorPaneOpen: boolean;
  rightEditorPaneWidth: number;
  isDetailsDrawerOpen: boolean;
  isViewpointMode: boolean;
};

export type AppLayoutState = BaseAppLayoutState & {
  isVerticalResizing: boolean;
  isHorizontalResizing: boolean;
  isEditorPanelVisible: boolean;
  isBottomBarVisible: boolean;
};

export type MainAppLayoutProps = {
  contentBarStyle: CSSProperties;
  editorPaneStyle: CSSProperties;
  horizontalResizeStyle: CSSProperties;
  isBottomBarVisible: boolean;
  isEditorPanelVisible: boolean;
  isHorizontalResizing: boolean;
  isRightEditorPaneOpen: boolean;
  isSplitPane: boolean;
  isVerticalResizing: boolean;
  leftPaneStyle: CSSProperties;
  rightEditorPaneStyle: CSSProperties;
  rightPaneStyle: CSSProperties;
  verticalResizeStyle: CSSProperties;
  rightEditorPaneWidth: number;
  editorHeight: number;
  isDetailsDrawerOpen: boolean;
  isViewpointMode: boolean;
};

export enum ResizeDirection {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export type GetBox = () => DOMRect | null;

export type Tab = {
  id: ViewIds;
  name: string;
  label: string;
  tabId: ViewIds;
  viewStatus: MetaInfoViewStatus;
};

export type ActiveTabsAndTabOptions = {
  tabs: Tab[];
  activeTabIdLeft: ViewIds;
  activeTabIdRight: ViewIds;
  previousActiveTabIdLeft: ViewIds;
  activeWorkspaceId?: ArdoqId;
  needsToBeSaved?: boolean;
  metaInfoList: MetaInfo[];
};

export type TabOptions = {
  tabs: Tab[];
  activeTabId: ViewIds;
  showManageViews: boolean;
};
