import { actionCreator } from '@ardoq/rxbeach';
import { AccountsState } from '../types';
import { ITPediaConnection } from '@ardoq/api-types/integrations';

const NAMESPACE = '[integrations] IT_PEDIA';

export const upsertAccount = actionCreator<ITPediaConnection>(
  `${NAMESPACE}_CREATE_ACCOUNT`
);

export const deleteAccount = actionCreator<ITPediaConnection>(
  `${NAMESPACE}_DELETE_ACCOUNT`
);

export const updateAccountState = actionCreator<Partial<AccountsState>>(
  `${NAMESPACE}_UPDATE_ACCOUNTS_STATE`
);
