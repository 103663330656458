import { dispatchAction, connect } from '@ardoq/rxbeach';
import { IconName } from '@ardoq/icons';
import componentFab$ from './componentFab$';
import { activateComponentFab } from './actions';
import { ButtonType, IconButton } from '@ardoq/button';
import styled from 'styled-components';
import { s16 } from '@ardoq/design-tokens';
import { ComponentFabProps } from './ComponentFab';

const ComponentFabViewpointContainer = styled.div`
  position: absolute;
  right: ${s16};
  bottom: ${s16};
  z-index: 1;
`;

const handleClick = () => {
  dispatchAction(activateComponentFab());
};

const ComponentViewpointFab = ({ isActive, isVisible }: ComponentFabProps) => {
  if (!isVisible || isActive) {
    return null;
  }

  return (
    <ComponentFabViewpointContainer data-tooltip-text="Create a component">
      <IconButton
        iconName={IconName.ADD}
        onClick={handleClick}
        buttonType={ButtonType.SECONDARY}
      />
    </ComponentFabViewpointContainer>
  );
};

export default connect(ComponentViewpointFab, componentFab$);
