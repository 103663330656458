import { s32 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { PageContainer, PageWrapperWithPadding } from '../layout';
import { Space } from '@ardoq/style-helpers';
import { DeleteInfoNotification } from 'integrations/common/containers/importAndSchedule/infoNotification';
import { TransferSuccess } from 'integrations/common/containers/importAndSchedule/transferSuccess';
import { ScheduleEditorContainer } from 'integrations/common/containers/importAndSchedule/scheduleEditor';
import { TransferErrorContainer } from 'integrations/common/containers/importAndSchedule/transferError';
import { ImportNotification } from 'integrations/common/containers/importAndSchedule/importNotification';

export function ImportAndSchedule() {
  return (
    <PageContainer>
      <PageWrapperWithPadding>
        <TransferAndScheduleContainer>
          <ImportNotification />
          <DeleteInfoNotification />
          <TransferErrorContainer />
          <TransferSuccess />
          <ScheduleEditorContainer />
        </TransferAndScheduleContainer>
      </PageWrapperWithPadding>
    </PageContainer>
  );
}

const TransferAndScheduleContainer = styled(Space).attrs({
  $isVertical: true,
  $gap: 's32',
})`
  padding: ${s32} 0;
  width: 100%;
`;
