import { ButtonGroup, PrimaryButton, SecondaryButton } from '@ardoq/button';
import styled from 'styled-components';
import { FlexBox } from '@ardoq/layout';
import { header1, header3 } from '@ardoq/typography';
import { DoqFace, DoqSize, DoqType } from '@ardoq/doq';
import { testId } from 'integrations/common/testUtils/testId';
import { IslandBody, IslandContainer, IslandFooter } from '@ardoq/page-layout';

const Title = styled.div`
  ${header1}
`;

const Subtitle = styled.div`
  ${header3};
  white-space: pre-line;
`;

type TransferErrorProps = {
  handleMessageSupport: VoidFunction;
  handleOpenReport: VoidFunction;
  transferDirection: 'import' | 'export';
};

export const TransferError = ({
  handleMessageSupport,
  handleOpenReport,
  transferDirection,
}: TransferErrorProps) => {
  return (
    <>
      <IslandContainer $maxWidth="100%" {...testId('transfer-error')}>
        <IslandBody>
          <Title>There was a technical issue.</Title>
          <Subtitle>
            Message support and we&apos;ll get this fixed for you{'\n'}
            or view the {transferDirection} report to check for issues.
          </Subtitle>
          <FlexBox gap="medium">
            <DoqFace doqSize={DoqSize.SMALL} doqType={DoqType.ERROR} />
          </FlexBox>
        </IslandBody>
        <IslandFooter $justify="end">
          <ButtonGroup>
            <SecondaryButton onClick={handleOpenReport}>
              {`View ${transferDirection} report`}
            </SecondaryButton>
            <PrimaryButton onClick={handleMessageSupport}>
              Message support
            </PrimaryButton>
          </ButtonGroup>
        </IslandFooter>
      </IslandContainer>
    </>
  );
};
