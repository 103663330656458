import { actionCreator } from '@ardoq/rxbeach';
import { ViewpointBuilderTab } from './types';
import { ViewpointBuilderContext } from 'viewpointBuilder/types';
import { ComponentTypeProps } from '../components/ComponentType';

export type SelectTabPayload = { tab: ViewpointBuilderTab };

export const selectTab = actionCreator<SelectTabPayload>(
  '[viewpointBuilderNavigation] Select tab'
);

export type SelectViewpointBuilderContextPayload = {
  context: ViewpointBuilderContext;
};

export const selectViewpointBuilderContext =
  actionCreator<SelectViewpointBuilderContextPayload>(
    '[viewpointBuilderNavigation] Select viewpoint builder context'
  );

export type SetSelectedComponentTypeAndCountPayload = {
  selectedComponentTypes: string[];
  selectedComponentCount: number;
};

export const setSelectedComponentTypeAndCount =
  actionCreator<SetSelectedComponentTypeAndCountPayload>(
    '[viewpointBuilderNavigation] Set component type and count'
  );

export type SetGraphCountsAndComponentTypeRepresentationPayload = {
  filtersCount: number;
  edgesCount: number;
  nodesCount: number;
  selectedComponentTypeRepresentation: ComponentTypeProps | null;
};

export const setGraphCountsAndComponentTypeRepresentation =
  actionCreator<SetGraphCountsAndComponentTypeRepresentationPayload>(
    '[viewpointBuilderNavigation] Set graph counts and component type representation'
  );

export type SetFormattingRulesCount = {
  conditionalFormattingRulesCount: number;
  labelFormattingRulesCount: number;
};

export const setFormattingRulesCount = actionCreator<SetFormattingRulesCount>(
  '[viewpointBuilderNavigation] Set formatting rules count'
);

export type SetGroupingRulesCount = {
  groupingRulesCount: number;
};

export const setGroupingRulesCount = actionCreator<SetGroupingRulesCount>(
  '[viewpointBuilderNavigation] Set grouping rules count'
);

export type SetCollapsedPathsCount = {
  collapsedPathsCount: number;
};

export const setCollapsedPathsCount = actionCreator<SetCollapsedPathsCount>(
  '[viewpointBuilderNavigation] Set collapsed paths count'
);

export type SetRequiredComponentsCount = {
  requiredComponentsCount: number;
};

export const setRequiredComponentsCount =
  actionCreator<SetRequiredComponentsCount>(
    '[viewpointBuilderNavigation] Set required components count'
  );

export type SetSavedViewpointDetails = {
  viewpointName: string | null;
};

export const setSavedViewpointDetails = actionCreator<SetSavedViewpointDetails>(
  '[viewpointBuilderNavigation] Set saved viewpoint details'
);

export const resetViewpointBuilderNavigation = actionCreator(
  '[viewpointBuilderNavigation] Reset'
);

export const collapsedPathTabUnselected = actionCreator(
  '[viewpointBuilderNavigation] COLLAPSED_PATH_TAB_UNSELECTED'
);
