import {
  APISurveyAttributes,
  APISurveyPendingApprovalsSummary,
  Asset,
  AssetType,
  SurveyAsset,
  UpdateType,
} from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { trackEvent } from 'tracking/tracking';
import { openResponseLogModal } from 'surveyAdmin/SurveyModals';
import { fetchResponseLogData } from './ResponseLog/actions';
import { Filter } from 'components/AssetsBrowser2024/FilterBar/filterBarTypes';

export const handleOpenResponseLog = (
  survey: APISurveyAttributes,
  updateType = UpdateType.UPDATED
) => {
  trackEvent('Survey response log: open modal');
  dispatchAction(
    fetchResponseLogData({
      surveyId: survey._id,
      updateType,
    })
  );
  openResponseLogModal(survey.name, updateType);
};

const isSurveyAsset = (asset: Asset): asset is SurveyAsset => {
  return asset.meta.assetType === AssetType.SURVEY;
};

export const approvalOffFilter: Filter = {
  label: 'Approval off',
  filterFn: asset => isSurveyAsset(asset) && !asset.approvalFlow?.enabled,
  filterRule: 'OR',
};

export const approvalOnFilter: Filter = {
  label: 'Approval on',
  filterFn: asset =>
    isSurveyAsset(asset) && Boolean(asset.approvalFlow?.enabled),
  filterRule: 'OR',
};

export const getPendingChangesFilter = (
  surveysWithPendingApprovals: APISurveyPendingApprovalsSummary
): Filter => ({
  label: 'Pending changes',
  filterFn: asset =>
    isSurveyAsset(asset) &&
    Boolean(asset.approvalFlow?.enabled) &&
    (surveysWithPendingApprovals[asset._id]?.total ?? 0) > 0,
  filterRule: 'OR',
});
