import styled from 'styled-components';
import ComponentOptions from 'menus/optionsMenu/component/ComponentOptions';
import ReferenceOptions from 'menus/optionsMenu/reference/ReferenceOptions';
import WorkspaceOptions from 'menus/optionsMenu/workspace/WorkspaceOptions';
import {
  TABS_GROUP_HEADER_SELECTOR,
  Tab,
  TabsGroup,
  TabsTheme,
} from '@ardoq/tabs';
import { OptionsMenuState, optionsMenu$, switchTab } from './optionsMenu$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { colors } from '@ardoq/design-tokens';
import { trackTabClicked } from '../tracking';

const Wrapper = styled.div`
  width: 0;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  ${TABS_GROUP_HEADER_SELECTOR} {
    height: inherit;
    & > div:first-child {
      border-bottom: 1px solid ${colors.grey80};
    }
  }
`;

const OptionsMenu = ({ tabId }: OptionsMenuState) => {
  return (
    <Wrapper>
      <TabsGroup
        theme={TabsTheme.LIGHT_WITH_UNDERLINE}
        onTabChange={tabId => {
          trackTabClicked(tabId);
          dispatchAction(switchTab(tabId));
        }}
        activeTabId={tabId}
        overflowType="chevrons"
      >
        <Tab tabId="workspace" label="Workspace">
          <WorkspaceOptions />
        </Tab>
        <Tab tabId="component" label="Component">
          <ComponentOptions />
        </Tab>
        <Tab tabId="reference" label="Reference">
          <ReferenceOptions />
        </Tab>
      </TabsGroup>
    </Wrapper>
  );
};

export default connect(OptionsMenu, optionsMenu$);
