import { ButtonGroup, PrimaryButton, GhostButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { PrimaryButtonConfig } from 'viewpointBuilder/openViewpointBuilder/getPrimaryButtonConfig';
import { WithPopover } from '@ardoq/popovers';
import { viewpointBuilderTracking } from '../viewpointBuilderTracking';
import { PageBodyFooter } from '@ardoq/page-layout';

type ViewpointBuilderFooterProps = {
  closeViewpointBuilder: () => void;
  primaryButtonConfig?: PrimaryButtonConfig;
};

export const ViewpointBuilderFooter = ({
  closeViewpointBuilder,
  primaryButtonConfig,
}: ViewpointBuilderFooterProps) => {
  return (
    <PageBodyFooter>
      <ButtonGroup>
        <GhostButton
          onClick={() => {
            viewpointBuilderTracking.trackCloseViewpointBuilder();
            closeViewpointBuilder();
          }}
        >
          <Icon iconName={IconName.EXIT_TO_APP} />
          Cancel & exit
        </GhostButton>
        {primaryButtonConfig && (
          <WithPopover
            content={primaryButtonConfig.popoverContentForDisabledItem}
          >
            <div>
              <PrimaryButton
                dataTestId="viewpoint-builder-primary-cta"
                onClick={() =>
                  primaryButtonConfig.primaryAction(closeViewpointBuilder)
                }
                isDisabled={primaryButtonConfig.isDisabled}
              >
                {primaryButtonConfig.primaryButtonLabel}
                <Icon iconName={primaryButtonConfig.iconName} />
              </PrimaryButton>
            </div>
          </WithPopover>
        )}
      </ButtonGroup>
    </PageBodyFooter>
  );
};
