import React from 'react';
import { modal } from '@ardoq/modal';
import {
  AwsConnection,
  AwsUpsertConnectionPayload,
} from '@ardoq/api-types/integrations';
import { ConnectionsAsyncOperationsState } from 'integrations/common/streams/connections/types';
import { awsSettings$ } from '../streams/settings/settings$';
import { combineLatest, map, switchMap } from 'rxjs';
import { connect } from '@ardoq/rxbeach';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  listConnections,
  upsertConnection,
  upsertConnectionFailure,
} from 'integrations/common/streams/connections/actions';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { find } from 'lodash/fp';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { UpsertConnection } from '../components/upsertConnection/UpsertConnection';
import { SettingsState } from '../streams/settings/types';
import { ArdoqId } from '@ardoq/api-types';

type UpsertConnectionsModalParams = {
  connectionId?: ArdoqId;
  connections: AwsConnection[];
  settingsState: SettingsState;
  statuses: ConnectionsAsyncOperationsState;
  onClose: () => void;
};

const onSubmit = async (connection: AwsUpsertConnectionPayload) => {
  const action = await dispatchActionAndWaitForResponse(
    upsertConnection({ integrationId: 'aws-v3', connection }),
    listConnections,
    upsertConnectionFailure
  );
  return action.type === listConnections.type;
};

const UpsertConnectionModalComponent = ({
  connectionId,
  connections,
  settingsState,
  statuses,
  onClose,
}: UpsertConnectionsModalParams) => {
  const existingConnection = find(
    ({ _id }) => connectionId === _id,
    connections
  );

  return (
    <UpsertConnection
      existingConnection={existingConnection}
      settingsState={settingsState}
      upsertStatus={statuses.upsert}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest([
      getConnectionsStream<AwsConnection>(integrationId),
      awsSettings$,
    ]).pipe(
      map(([{ connections, statuses }, { ...settingsState }]) => ({
        statuses,
        connections,
        settingsState,
      }))
    )
  )
);

const UpsertConnectionModal = connect(
  UpsertConnectionModalComponent,
  viewModel$
);

export const startUpsertConnectionModal = (connectionId?: string) =>
  modal<boolean>(
    resolve => (
      <UpsertConnectionModal
        connectionId={connectionId}
        onClose={() => resolve(false)}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
