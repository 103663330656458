import { APIReferenceType, ArdoqId } from '@ardoq/api-types';
import type { RelationshipDiagramGraphEdgeType } from '@ardoq/graph';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import { GlobalReferenceTypeId } from '@ardoq/data-model';

interface LinksToReferenceTypesReducerState {
  referenceModelTypes: Map<GlobalReferenceTypeId, APIReferenceType>;
  referenceTypes: Map<ArdoqId, APIReferenceType>;
}

const graphEdgesToReferenceTypes = (
  links: RelationshipDiagramGraphEdgeType[]
) =>
  links.reduce(
    (state: LinksToReferenceTypesReducerState, graphEdge) => {
      const modelId = graphEdge.modelId;
      const referenceTypeId = referenceInterface.getGlobalTypeId(modelId);
      if (!referenceTypeId) {
        return state;
      }
      const knownReferenceType = state.referenceModelTypes.get(referenceTypeId);
      const referenceType =
        knownReferenceType ??
        referenceInterface.getModelType(graphEdge.modelId);
      if (!referenceType) {
        return state;
      }
      if (!knownReferenceType) {
        state.referenceModelTypes.set(referenceTypeId, referenceType);
      }
      state.referenceTypes.set(modelId, referenceType);
      return state;
    },
    { referenceModelTypes: new Map(), referenceTypes: new Map() }
  );

export default graphEdgesToReferenceTypes;
