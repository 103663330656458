import { action$, reduceState, reducer } from '@ardoq/rxbeach';
import { selectedViewOperations } from './operations';
import { setSelectedView, resetSelectedView } from './actions';
import { SelectedViewOption, SelectedViewState } from './types';

export const selectedView$ = action$.pipe<SelectedViewState>(
  reduceState<{
    selectedView: SelectedViewOption;
  }>(
    'viewpointBuilder2.0/selectView$',
    selectedViewOperations.getDefaultState(),
    [
      reducer(setSelectedView, selectedViewOperations.setSelectedView),
      reducer(resetSelectedView, selectedViewOperations.getDefaultState),
    ]
  )
);
