import type { ComponentBackboneModel } from 'aqTypes';
import { APIFieldAttributes } from '@ardoq/api-types';
import Backbone from 'backbone';
import {
  dateRangeOperations,
  DateRangeFieldAttributes,
} from '@ardoq/date-range';

export const saveBackboneModelAsync = (
  model: Backbone.Model,
  props: { [key: string]: any }
): Promise<null | Backbone.Model> =>
  new Promise(resolve => {
    model.save(props, {
      success: () => resolve(model),
      error: () => resolve(null),
      wait: true,
    });
  });

export const destroyBackboneModelAsync = (model: Backbone.Model) =>
  new Promise(resolve => {
    model.destroy({
      success: () => resolve(model),
      error: () => resolve(null),
      wait: true,
    });
  });

const dateRangeFieldValues = (
  field: DateRangeFieldAttributes,
  model: Backbone.Model
) => ({
  start: model.get(field.dateTimeFields.start.name),
  end: model.get(field.dateTimeFields.end.name),
});

export const fieldValueToPresent = (
  field: APIFieldAttributes,
  model: Backbone.Model
) =>
  dateRangeOperations.isDateRangeField(field)
    ? dateRangeFieldValues(field, model)
    : model.get(field.name);

/**
 * @deprecated please use getComponentAncestryIds and avoid coupling your implementation with backbone models.
 * @returns an array with the given component at the end, preceded by its parent, its parent's parent, et cetera
 */
export const getComponentAncestry = (
  component?: ComponentBackboneModel | null
): ComponentBackboneModel[] =>
  component ? [...getComponentAncestry(component?.getParent()), component] : [];
