import FormattingRow from './FormattingRow';
import {
  APIDiscoverViewpointAttributes,
  FilterInfoOperator,
  FilterInterfaceFilter,
  FilterTypes,
  FormattingFilter,
} from '@ardoq/api-types';
import { FieldError, useFormContext } from 'react-hook-form';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { Icon, IconName } from '@ardoq/icons';
import { ErrorNotification } from '@ardoq/status-ui';
import Table from '../Table';
import { Stack } from '@ardoq/layout';

const baseFormat = {
  affectComponent: false,
  affectReference: false,
  isNegative: false,
  type: FilterTypes.ATTRIBUTE,
  color: '#FF9900',
  comparator: FilterInfoOperator.EQUALS,
  name: '',
  value: '',
};

export const isConditionalFormatting = (
  formatting: FormattingFilter | FilterInterfaceFilter
): formatting is FormattingFilter =>
  formatting && formatting.type === 'attribute';

const formattingHeaders = [
  { label: 'Type' },
  { label: 'Attribute' },
  { label: 'Condition' },
  { label: 'Value' },
];

type ConditionalFormattingProps = {
  onChange: (value: (FormattingFilter | FilterInterfaceFilter)[]) => void;
  error?: FieldError;
};

const ConditionalFormatting = ({
  onChange,
  error,
}: ConditionalFormattingProps) => {
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const [allFormatting, workspaceIds] = watch([
    'conditionalFormatting',
    'workspaceIds',
  ]);

  const conditionalFormatting =
    allFormatting?.filter(isConditionalFormatting) ?? [];

  const handleUpdateFormatting = (
    formatting: (FormattingFilter | FilterInterfaceFilter)[]
  ) => {
    onChange([
      ...(formatting ?? []),
      ...(allFormatting?.filter(format => !isConditionalFormatting(format)) ??
        []),
    ]);
  };

  const conditionalFormattingOptions = [
    {
      label: 'By Component',
      onClick: () => {
        handleUpdateFormatting([
          ...(conditionalFormatting ?? []),
          { ...baseFormat, affectComponent: true },
        ]);
      },
    },
    {
      label: 'By Reference',
      onClick: () => {
        handleUpdateFormatting([
          ...(conditionalFormatting ?? []),
          { ...baseFormat, affectReference: true },
        ]);
      },
    },
  ];

  return (
    <Stack gap="medium" align="flex-start">
      <ButtonWithDropdown
        options={conditionalFormattingOptions}
        dataTestId="add-formatting-button"
      >
        <Icon iconName={IconName.ADD} />
        Add conditional formatting
      </ButtonWithDropdown>
      {error && <ErrorNotification>{error.message}</ErrorNotification>}
      {conditionalFormatting.length ? (
        <Table tableHeaders={formattingHeaders}>
          {conditionalFormatting.map((format, index) => (
            <FormattingRow
              format={format}
              workspaceIds={workspaceIds}
              key={index}
              updateFormatting={updatedFormatting =>
                handleUpdateFormatting([
                  ...conditionalFormatting.slice(0, index),
                  updatedFormatting,
                  ...conditionalFormatting.slice(index + 1),
                ])
              }
              removeFormatting={() => {
                handleUpdateFormatting(
                  conditionalFormatting.filter((_, i) => i !== index)
                );
              }}
            />
          ))}
        </Table>
      ) : null}
    </Stack>
  );
};

export default ConditionalFormatting;
