import { QueryBuilderQuery } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import {
  QueryResults,
  ComponentType,
  ConfigurationStatuses,
  ConfigurationState,
} from '../types';
import {
  ITPediaSchedulePayload,
  IntegrationWorkspace,
  MatchingValue,
} from '@ardoq/api-types/integrations';

const NAMESPACE = '[integrations] IT_PEDIA';

export const updateAccountId = actionCreator<
  Pick<ConfigurationState, 'accountId'>
>(`${NAMESPACE}_UPDATE_ACCOUNT_ID`);

export const updateName = actionCreator<Pick<ConfigurationState, 'name'>>(
  `${NAMESPACE}_UPDATE_NAME`
);

export const updateApplyFilters = actionCreator<
  Pick<ConfigurationState, 'applyFilters'>
>(`${NAMESPACE}_UPDATE_APPLY_FILTERS`);

export const updateProductWorkspace = actionCreator<IntegrationWorkspace>(
  `${NAMESPACE}_UPDATE_PRODUCT_WORKSPACE`
);

export const updateVulnerabilitiesWorkspace =
  actionCreator<IntegrationWorkspace>(
    `${NAMESPACE}_UPDATE_VULNERABILITIES_WORKSPACE`
  );

export const updateComponentTypesKeys = actionCreator<string[]>(
  `${NAMESPACE}_UPDATE_COMPONENT_TYPES_KEYS`
);

export const updateComponentsQuery = actionCreator<{
  componentType: string;
  query: QueryBuilderQuery;
  isValid: boolean;
}>(`${NAMESPACE}_UPDATE_COMPONENT_QUERY`);

export const updateComponentsQueryResults = actionCreator<{
  componentType: string;
  results: QueryResults;
}>(`${NAMESPACE}_UPDATE_COMPONENTS_QUERY_RESULTS`);

export const updateManufacturer = actionCreator<{
  componentType: string;
  manufacturer?: MatchingValue;
}>(`${NAMESPACE}_UPDATE_MANUFACTURER`);

export const updateProduct = actionCreator<{
  componentType: string;
  product?: MatchingValue;
}>(`${NAMESPACE}_UPDATE_PRODUCT`);

export const updateVersion = actionCreator<{
  componentType: string;
  version?: MatchingValue;
}>(`${NAMESPACE}_UPDATE_VERSION`);

export const updateFields = actionCreator<{
  componentType: string;
  fields: Partial<ComponentType['fields']>;
}>(`${NAMESPACE}_UPDATE_FIELDS`);

export const updateStatuses = actionCreator<Partial<ConfigurationStatuses>>(
  `${NAMESPACE}_UPDATE_STATUSES`
);

export const upsertEnrichment = actionCreator<
  ITPediaSchedulePayload['jobOptions']
>(`${NAMESPACE}_POST_CREATE_ENRICHMENT`);

export const fetchQueryResults = actionCreator<{
  componentType: string;
  query: QueryBuilderQuery;
}>(`${NAMESPACE}_FETCH_QUERY_RESULTS`);

export const updateConfigurationState = actionCreator<ConfigurationState>(
  `${NAMESPACE}_UPDATE_CONFIG`
);

export const setupConfigurationForUpdate = actionCreator<ConfigurationState>(
  `${NAMESPACE}_MATCH_EXISTING_LABELS`
);

export const rename = actionCreator<ITPediaSchedulePayload['jobOptions']>(
  `${NAMESPACE}_RENAME`
);

export const resetConfig = actionCreator(`${NAMESPACE}_RESET_CONFIG`);
