import { ButtonType } from '@ardoq/button';
import { useState } from 'react';
import {
  ButtonWithDropdown,
  DropdownItem,
  DropdownOptionType,
} from '@ardoq/dropdown-menu';
import { Checkbox, SearchInput } from '@ardoq/forms';
import { FilterListIcon } from '@ardoq/icons';
import styled from 'styled-components';
import { s8 } from '@ardoq/design-tokens';
import _ from 'lodash/fp';

type Filters = Record<FiltersIds, Record<string, boolean>>;
type FiltersIds = 'integrationSource' | 'result.status' | 'lastModifiedByName';

export const filterValues = <T extends Record<string, boolean>>(
  search: string,
  fields: T
) => {
  const sanitizedSearch = search.toLowerCase().trim();
  const searchTerms = sanitizedSearch.split(/[\s_]+/);

  const filteredEntries = Object.entries(fields).filter(t => {
    const label = t[0].toLowerCase();
    return searchTerms.every(term => label.includes(term));
  });

  return Object.fromEntries(filteredEntries);
};

const handleStopPropagationClick = (event: React.MouseEvent<HTMLElement>) => {
  event.stopPropagation();
};

const StyledSearchInput = styled(SearchInput).attrs({
  onClick: handleStopPropagationClick,
})`
  width: 283px;
`;

const DropdownOptionContent = styled.div.attrs({
  onClick: handleStopPropagationClick,
})`
  padding: ${s8};
`;

export const FilterHistory = ({
  values,
  id,
  handleAllClick,
  handleOnClick,
  haveSearch = false,
  friendlyNamesMap = {},
}: {
  values: Filters;
  id: FiltersIds;
  handleAllClick: (id: FiltersIds, value: boolean) => void;
  handleOnClick: (id: FiltersIds, value: boolean, label: string) => void;
  haveSearch?: boolean;
  friendlyNamesMap?: Record<string, string>;
}) => {
  const [searchInput, setSearchInput] = useState('');

  const filteredValues = haveSearch
    ? filterValues(searchInput, _.get(id, values))
    : _.get(id, values);

  return (
    <div onClick={handleStopPropagationClick}>
      <ButtonWithDropdown
        alignMenuTo="right"
        headerContent={
          haveSearch && (
            <StyledSearchInput
              placeholder={'Search for user name'}
              onValueChange={setSearchInput}
              value={searchInput}
              debounceTime={500}
            />
          )
        }
        options={
          Object.keys(filteredValues).length > 0
            ? [
                ...(Object.keys(filteredValues).length > 1
                  ? [
                      {
                        content: (
                          <DropdownOptionContent key={`filtered-values-all`}>
                            <Checkbox
                              isChecked={Object.values(filteredValues).every(
                                Boolean
                              )}
                              onChange={value => handleAllClick(id, value)}
                            >
                              ALL
                            </Checkbox>
                          </DropdownOptionContent>
                        ),
                        type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
                      } as Partial<DropdownItem>,
                    ]
                  : []),
                ...Object.entries(filteredValues).map(([label, isChecked]) => ({
                  content: (
                    <DropdownOptionContent key={`filtered-values-${label}`}>
                      <Checkbox
                        isChecked={isChecked || false}
                        onChange={value => handleOnClick(id, value, label)}
                      >
                        {friendlyNamesMap[label] || label}
                      </Checkbox>
                    </DropdownOptionContent>
                  ),
                  type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
                })),
              ]
            : [
                {
                  label: 'No Results Found',
                  type: DropdownOptionType.MESSAGE,
                },
              ]
        }
        hideChevron
        buttonType={ButtonType.GHOST}
      >
        <FilterListIcon />
      </ButtonWithDropdown>
    </div>
  );
};
