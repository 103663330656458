import { dispatchAction } from '@ardoq/rxbeach';
import {
  closePresentation,
  openPresentationBuilder,
  previewPresentationInEditor,
  setPresentationInListMode,
} from './actions';
import { OpenPresentationPayload } from './types';
import {
  trackCreateNewPresentation,
  trackOpenedPresentationSidebar,
} from 'presentation/tracking';
import { openPresentationEditor } from 'presentationEditor/presentationUtil';
import { ArdoqId } from '@ardoq/api-types';

export type PresentationOverviewCommands = {
  setPresentationInListMode: (isToggled: boolean) => void;
  previewPresentationInEditor: ({
    presentationId,
  }: OpenPresentationPayload) => void;
  openPresentationBuilder: () => void;
  closePresentation: () => void;
  openPresentationInEditor: (
    presentationId: ArdoqId,
    editingSlideId?: ArdoqId
  ) => void;
};

export const presentationOverviewCommands: PresentationOverviewCommands = {
  setPresentationInListMode: isToggled =>
    dispatchAction(setPresentationInListMode(isToggled)),
  previewPresentationInEditor: ({ presentationId }) =>
    dispatchAction(
      previewPresentationInEditor({
        presentationId,
      })
    ),
  openPresentationBuilder: () => {
    trackCreateNewPresentation();
    dispatchAction(openPresentationBuilder());
  },
  closePresentation: () => dispatchAction(closePresentation()),
  openPresentationInEditor: (presentationId, editingSlideId) => {
    openPresentationEditor({
      presentationId,
      editingSlideId,
    });
    trackOpenedPresentationSidebar('Presentation view', presentationId);
  },
};
