import { useEffect } from 'react';
import { ErrorNotification } from '@ardoq/status-ui';
import InviteNewUser from './InviteNewUser';
import PendingInvitation from './pendingInvitations/pendingInvitations';
import { clearInvitationsAlert } from 'streams/invitations/utils';
import { ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { PrimaryButton, SecondaryButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import inviteNewUser$, { InviteNewUserStreamShape } from './inviteNewUser$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { submitInvitationForm } from 'streams/invitations/actions';

type OwnProps = { closeDialog: () => void };

const UserInvitations = ({
  alert,
  closeDialog,
  formState: { isValid },
}: InviteNewUserStreamShape & OwnProps) => {
  useEffect(() => {
    return () => {
      clearInvitationsAlert();
    };
  }, []);

  const submit = () => {
    dispatchAction(submitInvitationForm());
  };

  return (
    <ModalTemplate
      headerText="Invite users"
      modalSize={ModalSize.M}
      footerContent={
        <>
          <SecondaryButton onClick={closeDialog}>Cancel</SecondaryButton>
          <PrimaryButton
            dataTestId="send-user-invitation"
            onClick={submit}
            isDisabled={!isValid}
          >
            <Icon iconName={IconName.MAIL} />
            Send invitation
          </PrimaryButton>
        </>
      }
    >
      <ModalLayout>
        {alert?.type === 'error' ? (
          <ErrorNotification>{alert.message}</ErrorNotification>
        ) : null}
        <InviteNewUser />
        <PendingInvitation />
      </ModalLayout>
    </ModalTemplate>
  );
};

export default connect(UserInvitations, inviteNewUser$);
