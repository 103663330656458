import { Component } from 'react';
import {
  action$,
  dispatchAction,
  connect,
  extractPayload,
  ofType,
  derivedStream,
} from '@ardoq/rxbeach';
import { SlidesList, SlidesListProperties } from '../components/SlidesList';
import {
  deleteSlideInPresentationEditor,
  replaceSlideWithCurrentContext,
  selectSlideForEditing,
} from 'presentationEditor/actions';
import Context from 'context';
import {
  ArdoqId,
  APIPresentationAssetAttributes,
  PresentationReadPermissions,
  APISlideAttributes,
  isVisualizationSlide,
  ViewIds,
} from '@ardoq/api-types';
import { map, Subscription } from 'rxjs';
import { resourcePermissionsModelInterface } from 'resourcePermissions/resourcePermissionsModelInterface';
import { UnauthorizedSlideType } from '../types';
import { isUnauthorizedSlide } from '../util';
import activeView$, {
  ActiveViewState,
} from 'streams/views/mainContent/activeView$';
import SuppressDialogContent from 'dialog/SuppressDialogContent';
import { ModalSize, confirm } from '@ardoq/modal';
import { debounce, isEqual } from 'lodash';
import { DragAndDropContextState } from '@ardoq/drag-and-drop';
import { setSelectedSlideId } from 'streams/context/ContextActions';
import { selectedSlide$ } from 'streams/selectedSlide/selectedSlide$';
import {
  selectAndLoadSlide,
  loadSelectedSlideIfNotAlreadyLoaded,
} from '../actions';
import { FullHeightStack, SidebarContentFooter } from '../components/atoms';
import { PrimaryButton } from '@ardoq/button';
import { saveSlide, slideNamespace } from 'streams/slides/actions';
import { updatePresentation } from 'streams/presentations/actions';
import { Features, hasFeature } from '@ardoq/features';
import { subdivisionsInterface } from 'streams/subdivisions/subdivisionInterface';
import { deleteRequested } from 'streams/crud/actions';

const SUPPRESS_TEXT = "Don't show me this message again in this session";

const SESSION_KEY_PRESENTATIONS = {
  REPLACE_SLIDE: '[presentations] REPLACE_SLIDE',
  DELETE: '[presentations] DELETE',
  DISCARD_CHANGES: '[presentations] DISCARD_CHANGES',
};
type DialogType = 'REPLACE_SLIDE' | 'DELETE' | 'DISCARD_CHANGES';

const confirmSlideReplacementModal = async ({
  showPermissionWarning,
}: {
  showPermissionWarning: boolean;
}) =>
  await confirm({
    title: 'Replace slide contents',
    modalSize: ModalSize.S,
    subtitle:
      "Do you want to replace this slide with the current view? This cannot be undone. The slide's current name and description will remain untouched.",
    text: showPermissionWarning && (
      <p>
        <i>
          Some users may lose access to this slide.{' '}
          <a
            href="https://help.ardoq.com/en/articles/44085-presentations-permissions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more in our knowledge base.
          </a>
        </i>
      </p>
    ),
    text2: (
      <SuppressDialogContent
        suppressText={SUPPRESS_TEXT}
        setSuppressionPreference={(shouldSuppress: boolean) =>
          setSuppressionPreference('REPLACE_SLIDE', shouldSuppress)
        }
      />
    ),
    confirmButtonTitle: 'Replace',
  });

const arrayMove = <T,>(array: T[], fromIndex: number, toIndex: number): T[] => {
  const arrayCopy = [...array];
  arrayCopy.splice(toIndex, 0, arrayCopy.splice(fromIndex, 1)[0]);
  return arrayCopy;
};

const setSuppressionPreference = (
  dialogType: DialogType,
  shouldSuppress: boolean
) => {
  window.sessionStorage.setItem(
    SESSION_KEY_PRESENTATIONS[dialogType],
    `${shouldSuppress}`
  );
};

const saveAttributes = (slide: APISlideAttributes, attributes: SlideEdit) => {
  dispatchAction(saveSlide({ ...slide, ...attributes }));
};

type SlidesContainerProps = {
  presentation: APIPresentationAssetAttributes;
  slides: (APISlideAttributes | UnauthorizedSlideType)[];
  canEdit: boolean;
  viewIsSupported?: boolean;
  onSlidesButtonClick: (presentationId: ArdoqId) => void;
  editingSlideId?: ArdoqId;
  onEditClick?: (presentationId: ArdoqId, editingSlideId?: ArdoqId) => void;
  isPresentationPreview?: boolean;
  selectedSlideId: ArdoqId | null;
};

type SlideEdit = { name?: string; description?: string };

interface SlidesContainerState {
  editingSlideId: ArdoqId | null;
  slideAttributesBeforeEditing?: SlideEdit;
  editsToCurrentSlide?: SlideEdit;
  isNewSlide: boolean;
}

class SlidesContainer extends Component<
  SlidesContainerProps & ActiveViewState,
  SlidesContainerState
> {
  subscription: Subscription | null = null;
  hasTriedToSyncSlides = false;
  state: SlidesContainerState = {
    editingSlideId: null,
    slideAttributesBeforeEditing: undefined,
    editsToCurrentSlide: undefined,
    isNewSlide: false,
  };

  debouncedSaveAttributes = debounce((slide: APISlideAttributes) => {
    if (this.state.editsToCurrentSlide) {
      saveAttributes(slide, this.state.editsToCurrentSlide);
    }
  }, 500);

  setEditingSlide = (editingSlideId?: string) => {
    if (editingSlideId) {
      const slideToOpen = this.props.slides.find(
        slide => !isUnauthorizedSlide(slide) && slide._id === editingSlideId
      ) as APISlideAttributes;
      this.setState({
        editingSlideId,
        editsToCurrentSlide: {
          name: slideToOpen.name,
          description: slideToOpen.description,
        },
      });
      this.onSlideClick(slideToOpen);
    }
  };

  onSortEnd = (
    newOrder: string[],
    { draggedCardId }: DragAndDropContextState
  ) => {
    if (!draggedCardId) return;

    const oldIndex = Number(draggedCardId);
    const newIndex = newOrder.indexOf(draggedCardId);

    if (oldIndex !== newIndex) {
      const newSlides = arrayMove(
        this.props.presentation.slides,
        oldIndex,
        newIndex
      );
      dispatchAction(
        updatePresentation({
          ...this.props.presentation,
          slides: newSlides,
        })
      );
    }
  };

  onSlideClick = async (slide: APISlideAttributes) => {
    const isEditing = this.state.editingSlideId === slide._id;
    if (isEditing) return;
    if (this.props.isPresentationPreview) {
      this.props.onEditClick?.(this.props.presentation._id, slide._id);
    }

    this.saveUnsavedChanges();
    this.setState({
      editingSlideId: null,
    });
    dispatchAction(selectAndLoadSlide(slide._id));
  };

  doDeleteSlide = (slide: APISlideAttributes) => {
    const newSlides = this.props.presentation.slides.filter(value => {
      return value !== slide._id;
    });
    dispatchAction(
      updatePresentation({
        ...this.props.presentation,
        slides: newSlides,
      })
    );
    dispatchAction(deleteSlideInPresentationEditor(slide._id));
    dispatchAction(deleteRequested(slideNamespace, slide._id));

    const isSelectedSlide = slide._id === this.props.selectedSlideId;
    if (isSelectedSlide) {
      dispatchAction(setSelectedSlideId({ selectedSlideId: null }));
    }

    this.setState({
      editingSlideId: null,
      editsToCurrentSlide: undefined,
      isNewSlide: false,
    });
  };

  onEditClick = (clickedSlide: APISlideAttributes) => {
    const clickedEditButton = this.state.editingSlideId !== clickedSlide._id;
    if (clickedEditButton) {
      this.saveUnsavedChanges();
      this.setState({
        slideAttributesBeforeEditing: {
          name: clickedSlide.name,
          description: clickedSlide.description,
        },
        editsToCurrentSlide: {
          name: clickedSlide.name,
          description: clickedSlide.description,
        },
        editingSlideId: clickedSlide._id,
        isNewSlide: false,
      });
    } else {
      // clicked save button
      this.setState({ editingSlideId: null });
    }
  };

  onSlideEditedAttributes = (
    value: string,
    attributeName: 'name' | 'description'
  ) => {
    this.setState({
      editsToCurrentSlide: {
        ...this.state.editsToCurrentSlide,
        [attributeName]: value,
      },
    });
  };

  onSaveChangesClick = () => {
    this.saveUnsavedChanges();
    this.setState({ editingSlideId: null });
  };

  onDiscardChangesClick = async (slide: APISlideAttributes) => {
    const suppressDialog =
      window.sessionStorage.getItem(
        SESSION_KEY_PRESENTATIONS.DISCARD_CHANGES
      ) === 'true';

    const slideIsEdited = !isEqual(
      this.state.editsToCurrentSlide,
      this.state.slideAttributesBeforeEditing
    );

    const discardChanges = () => {
      if (this.state.slideAttributesBeforeEditing && !this.state.isNewSlide) {
        this.setState({
          slideAttributesBeforeEditing: undefined,
          editsToCurrentSlide: undefined,
          editingSlideId: null,
          isNewSlide: false,
        });
        return;
      }
      this.doDeleteSlide(slide);
    };

    if (suppressDialog || !slideIsEdited) {
      discardChanges();
    } else if (
      await confirm({
        title: 'Discard changes',
        subtitle:
          'Are you sure you want to discard your changes? This cannot be undone.',
        text: (
          <SuppressDialogContent
            suppressText={SUPPRESS_TEXT}
            setSuppressionPreference={(shouldSuppress: boolean) =>
              setSuppressionPreference('DISCARD_CHANGES', shouldSuppress)
            }
          />
        ),
        isConfirmButtonDanger: true,
        confirmButtonTitle: 'Discard',
      })
    ) {
      discardChanges();
    }
  };

  onReplaceSlideClick = async (slide: APISlideAttributes) => {
    const suppressDialog =
      window.sessionStorage.getItem(SESSION_KEY_PRESENTATIONS.REPLACE_SLIDE) ===
      'true';

    const replaceSlide = () => {
      dispatchAction(replaceSlideWithCurrentContext(slide));
    };

    if (suppressDialog) {
      replaceSlide();
      return;
    }

    const presentationRequiresWorkspaceAccess =
      this.props.presentation.readAccess === PresentationReadPermissions.WS;

    if (
      hasFeature(Features.PERMISSION_ZONES_INTERNAL) &&
      isVisualizationSlide(slide) &&
      slide.subdivisionIds !== undefined
    ) {
      // If the slides subdivisionIds are being replaced with different ones, some people may lose access
      const showPermissionWarning =
        presentationRequiresWorkspaceAccess &&
        !isEqual(
          subdivisionsInterface.getSubdivisionsStreamState().sortedIds,
          slide.subdivisionIds
        );
      const confirmSlideReplacement = await confirmSlideReplacementModal({
        showPermissionWarning,
      });
      if (confirmSlideReplacement) replaceSlide();
      return;
    }

    // Check default permissions of all open workspaces that will be in new slide
    // If any don't have any default permissions, alert that some users may lose access
    const newSlideWorkspacePerms = await Promise.all(
      Context.workspaces().map(ws =>
        resourcePermissionsModelInterface.getDefaultWorkspacePermissions(ws)
      )
    );
    const showPermissionWarning =
      presentationRequiresWorkspaceAccess &&
      newSlideWorkspacePerms.some(permission => !permission);

    const confirmSlideReplacement = await confirmSlideReplacementModal({
      showPermissionWarning,
    });

    if (confirmSlideReplacement) replaceSlide();
  };

  onDeleteClick = async (slide: APISlideAttributes) => {
    const suppressDialog =
      window.sessionStorage.getItem(SESSION_KEY_PRESENTATIONS.DELETE) ===
      'true';
    if (suppressDialog) {
      this.doDeleteSlide(slide);
    } else if (
      await confirm({
        title: 'Delete slide',
        subtitle:
          'Are you sure you want to delete this slide? This cannot be undone.',
        text: (
          <SuppressDialogContent
            suppressText={SUPPRESS_TEXT}
            setSuppressionPreference={(shouldSuppress: boolean) =>
              setSuppressionPreference('DELETE', shouldSuppress)
            }
          />
        ),
        confirmButtonTitle: 'Delete',
        isConfirmButtonDanger: true,
        modalSize: ModalSize.S,
      })
    ) {
      this.doDeleteSlide(slide);
    }
  };
  handleSelectSlideForEditing = ({
    _id,
    name,
    description,
  }: APISlideAttributes) => {
    this.saveUnsavedChanges();
    this.setState(() => ({
      editingSlideId: _id,
      slideAttributesBeforeEditing: {
        name,
        description,
      },
      editsToCurrentSlide: {
        name,
        description,
      },
      isNewSlide: true,
    }));
    dispatchAction(setSelectedSlideId({ selectedSlideId: _id }));
  };

  componentDidMount() {
    this.setEditingSlide(this.props.editingSlideId);
    this.subscription = action$
      .pipe(ofType(selectSlideForEditing), extractPayload())
      .subscribe(this.handleSelectSlideForEditing);

    dispatchAction(loadSelectedSlideIfNotAlreadyLoaded());
  }

  componentDidUpdate(prevProps: Readonly<SlidesContainerProps>) {
    if (prevProps.editingSlideId !== this.props.editingSlideId) {
      this.setEditingSlide(this.props.editingSlideId);
    }
  }

  componentWillUnmount() {
    if (!this.subscription) {
      return;
    }
    this.subscription.unsubscribe();
    this.subscription = null;
  }

  saveUnsavedChanges() {
    const editingSlide = this.props.slides.find(
      (slide): slide is APISlideAttributes =>
        !isUnauthorizedSlide(slide) && slide._id === this.state.editingSlideId
    );

    if (editingSlide && this.state.editsToCurrentSlide) {
      saveAttributes(editingSlide, this.state.editsToCurrentSlide);
      this.setState({ editsToCurrentSlide: undefined });
    }
  }

  render() {
    const slidesListProps = makeSlidesListProps(this);
    return (
      <SlidesContainerX
        currentPresentationId={this.props.presentation._id}
        canEdit={this.props.canEdit}
        isPresentationPreview={this.props.isPresentationPreview}
        onSlidesButtonClick={this.props.onSlidesButtonClick}
        viewIsSupported={this.props.viewIsSupported}
        secondaryViewId={this.props.secondaryViewId}
        slidesListProps={slidesListProps}
      />
    );
  }
}

const makeSlidesListProps = (container: SlidesContainer) => ({
  slides: container.props.slides,
  canEdit: container.props.canEdit,
  onSortEnd: container.onSortEnd,
  onSlideClick: container.onSlideClick,
  onEditClick: container.onEditClick,
  onDeleteClick: container.onDeleteClick,
  selectedSlideId: container.props.selectedSlideId,
  editingSlideId: container.state.editingSlideId,
  onReplaceSlideClick: container.onReplaceSlideClick,
  onSlideEditedAttributes: container.onSlideEditedAttributes,
  onDiscardChangesClick: container.onDiscardChangesClick,
  onSaveChangesClick: container.onSaveChangesClick,
  currentPresentationId: container.props.presentation._id,
  editsToCurrentSlide: container.state.editsToCurrentSlide,
  viewIsSupported: container.props.viewIsSupported,
});

type SlidesContainerXProps = {
  canEdit: boolean;
  isPresentationPreview?: boolean;
  onSlidesButtonClick: (presentationId: ArdoqId) => void;
  viewIsSupported?: boolean;
  currentPresentationId: ArdoqId;
  secondaryViewId: ViewIds | null;
  slidesListProps: SlidesListProperties;
};

const SlidesContainerX = ({
  canEdit,
  isPresentationPreview,
  onSlidesButtonClick,
  viewIsSupported,
  currentPresentationId,
  secondaryViewId,

  slidesListProps,
}: SlidesContainerXProps) => (
  <FullHeightStack>
    <SlidesList {...slidesListProps} />
    {canEdit && (
      <SidebarContentFooter>
        <PrimaryButton
          isDisabled={!isPresentationPreview && !viewIsSupported}
          onClick={() => onSlidesButtonClick(currentPresentationId)}
          data-intercom-target="add view as slide"
        >
          {isPresentationPreview
            ? 'Open presentation'
            : viewIsSupported
              ? `Add ${secondaryViewId ? 'main' : 'this'} view as a slide`
              : 'View is not supported in presentations'}
        </PrimaryButton>
      </SidebarContentFooter>
    )}
  </FullHeightStack>
);

const viewModel$ = derivedStream(
  'slidesContainer$',
  activeView$,
  selectedSlide$
).pipe(
  map(([{ secondaryViewId, mainViewId }, { selectedSlideId }]) => {
    return {
      secondaryViewId,
      mainViewId,
      selectedSlideId,
    };
  })
);

export default connect(SlidesContainer, viewModel$);
