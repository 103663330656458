import { useEffect, useState } from 'react';
import {
  APIEntityType,
  APIModelAttributes,
  APIWorkspaceAttributes,
  ArdoqId,
  ComponentTypeHierarchy,
  HTTPStatusCode,
} from '@ardoq/api-types';
import { MetamodelEditorDialog } from '@ardoq/metamodel-editor';
import { modal, syncConflictAlert } from '@ardoq/modal';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { documentArchiveInterface } from 'modelInterface/documentArchiveInterface';
import { logError } from '@ardoq/logging';
import HandelErrorMessage from './dialogs/HandelErrorMessage';
import workspaceModeEditor$ from './streams/workspaceModeEditor$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  getUsedTypeIds,
  saveModalEditorChangesError,
  saveModalEditorChangesSuccess,
  saveModelTypeChanges,
  UsedTypeIdsPayload,
} from './streams/actions';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import failedToSave from './dialogs/failedToSaveDialog';
import { noop } from 'lodash';
import { trackEvent } from 'tracking/tracking';
import { isCreatingWorkspaceFromTemplate } from 'workspaceWizard/fromTemplate/isCreatingWsFromTemplate$';
import { showRightPane } from 'appContainer/actions';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';

interface WorkspaceModelEditorProps {
  wsModelAttributes: APIModelAttributes;
  onChangeWsModelAttributes?: (wsModelAttributes: APIModelAttributes) => void;
  workspace: APIWorkspaceAttributes;
  onClose: () => void;
}

type ConnectedWorkspaceModelEditorProps = WorkspaceModelEditorProps &
  UsedTypeIdsPayload;
const WorkspaceModelEditor = ({
  wsModelAttributes,
  onChangeWsModelAttributes = noop,
  workspace,
  onClose,
  usedTypeIds,
}: ConnectedWorkspaceModelEditorProps) => {
  const [preselectedMetamodelId, setPreSelectedMetamodelId] = useState<
    ArdoqId | undefined
  >(undefined);

  const handleOnSave = async ({
    metamodel,
    resumedSelectedMetamodelId,
    isFlexible,
  }: {
    metamodel: ComponentTypeHierarchy;
    resumedSelectedMetamodelId?: ArdoqId;
    isFlexible: boolean;
  }) =>
    await dispatchActionAndWaitForResponse(
      saveModelTypeChanges({
        model: {
          ...wsModelAttributes,
          root: metamodel,
          flexible: isFlexible,
        },
        workspaceId: workspace._id,
      }),
      saveModalEditorChangesSuccess,
      saveModalEditorChangesError
    ).then((action: any) => {
      if (action.type === saveModalEditorChangesSuccess.type) {
        onChangeWsModelAttributes(action.payload.newModel);
        setPreSelectedMetamodelId(resumedSelectedMetamodelId);
        return;
      }
      const { error } = action.payload;
      if (error?.status === HTTPStatusCode.CONFLICT) {
        syncConflictAlert({
          entityType: APIEntityType.COMPONENT_TYPE,
          entityId: wsModelAttributes._id,
          trackEvent: params =>
            trackEvent('Entity sync error detected', params),
        });
        return;
      }
      failedToSave(error.userMessage);
      logError(new Error(error?.responseJSON?.message));
    });

  return (
    <MetamodelEditorDialog
      key={wsModelAttributes._version}
      preselectedMetamodelId={preselectedMetamodelId}
      workspaceName={workspace.name}
      metamodel={wsModelAttributes.root}
      isFlexible={wsModelAttributes.flexible}
      usedMetamodelTypeIds={usedTypeIds}
      onSave={handleOnSave}
      onClose={onClose}
      imageOptions={documentArchiveInterface
        .getDocumentArchiveImages({
          wsId: workspace._id,
        })
        .map(({ filename, uri }) => ({
          label: filename,
          value: uri,
        }))}
    />
  );
};

const ConnectedWorkspaceModelEditor = connect<
  ConnectedWorkspaceModelEditorProps,
  UsedTypeIdsPayload
>(WorkspaceModelEditor, workspaceModeEditor$);

export const openWorkspaceModelEditor = ({
  workspaceId,
}: {
  workspaceId: ArdoqId;
}) =>
  modal(resolve => {
    const ws = workspaceInterface.getWorkspaceData(workspaceId);
    const [wsModelAttributes, setWsModelAttributes] = useState(
      workspaceInterface.getModelData(workspaceId)
    );
    useEffect(() => {
      if (workspaceId) {
        dispatchAction(
          getUsedTypeIds({
            workspaceId,
          })
        );
      }
    }, []);

    const handleOnClose = () => {
      resolve(false);
      if (isCreatingWorkspaceFromTemplate()) {
        dispatchAction(
          showRightPane({
            type: GetContentOptionsType.WORKSPACE_PROPERTIES,
            workspaceId,
          })
        );
      } else {
        dispatchAction(
          showRightPane({
            type: GetContentOptionsType.MENU,
            tabId: 'optionsMenuWorkspaceOptions',
          })
        );
      }
    };

    if (!ws) {
      return (
        <HandelErrorMessage
          name="workspace"
          workspaceId={workspaceId}
          onClose={() => resolve(false)}
        />
      );
    }

    if (!wsModelAttributes) {
      logError(Error(`Cannot find metamodel.`), null, {
        workspaceId,
      });
      return (
        <HandelErrorMessage
          name={`metamodel for ${ws.name}`}
          workspaceId={workspaceId}
          onClose={() => resolve(false)}
        />
      );
    }

    return (
      <ConnectedWorkspaceModelEditor
        wsModelAttributes={wsModelAttributes}
        onChangeWsModelAttributes={setWsModelAttributes}
        workspace={ws}
        onClose={handleOnClose}
      />
    );
  });
