import styled from 'styled-components';
import fp from 'lodash/fp';
import { Icon, IconName } from '@ardoq/icons';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { Paragraph } from '@ardoq/typography';
import { CreatableSelect, Select } from '@ardoq/select';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { NewBadge } from '@ardoq/status-ui';
import { Maybe } from '@ardoq/common-helpers';
import { Field as FieldType } from 'integrations/common/streams/fields/types';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import {
  MissingComponentsStrategy,
  ReferenceMapping,
  RootReferenceMapping,
  TargetReferenceMapping,
} from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import {
  getReferenceIfComponentIsMissingName,
  isReferenceFormatDisabled,
} from 'integrations/common/pages/tabularConfigMapping/utils';
import {
  MappedColumnsErrors,
  SidebarSelectorsIds,
} from 'integrations/common/streams/tabularMappingErrors/types';
import { FlexBox, Stack } from '@ardoq/layout';
import { PopoverPlacement, WithPopover } from '@ardoq/popovers';

const getReferenceComponentIsMissingOptions = (
  columnMapping:
    | ReferenceMapping
    | RootReferenceMapping
    | TargetReferenceMapping
): Array<{
  value: MissingComponentsStrategy;
  label: string;
}> =>
  [
    MissingComponentsStrategy.CREATE,
    columnMapping.columnType === 'reference'
      ? MissingComponentsStrategy.WARN
      : MissingComponentsStrategy.EXCLUDE,
    MissingComponentsStrategy.ERROR,
  ].map(strategy => ({
    value: strategy,
    label: getReferenceIfComponentIsMissingName(strategy),
    isDisabled: isReferenceFormatDisabled(
      columnMapping.referenceFormat,
      strategy
    ),
    popoverContent: isReferenceFormatDisabled(
      columnMapping.referenceFormat,
      strategy
    )
      ? 'Unavailable when Custom ID or Ardoq OID formats are selected.'
      : '',
    rightContent: isReferenceFormatDisabled(
      columnMapping.referenceFormat,
      strategy
    ) ? (
      <Icon iconName={IconName.WARNING} />
    ) : null,
  }));

type ReferenceIfComponentIsMissingProps = {
  tableMapping: TableMappingMap;
  columnMapping:
    | ReferenceMapping
    | RootReferenceMapping
    | TargetReferenceMapping;
  usedFields: FieldType[];
  allFields: FieldType[];
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
  columnName: string;
  isCreationDisabled: boolean;
  workspaceId?: Maybe<string>;
  isSource: boolean;
  mappedColumnsErrors?: MappedColumnsErrors;
};

export const ReferenceMissingComponentsStrategy = ({
  columnMapping,
  onMapping,
  workspaceId,
  isSource,
  mappedColumnsErrors = {},
  isCreationDisabled,
}: ReferenceIfComponentIsMissingProps) => {
  const compTypeOptions = fp.flow(
    (workspaceId?: Maybe<string>) =>
      workspaceId ? workspaceInterface.getComponentTypes(workspaceId) : [],
    fp.map(({ name }) => ({ label: name, value: name }))
  )(workspaceId);

  const Selector = isCreationDisabled ? Select : CreatableSelect;

  return (
    <>
      <ComponentTypeContainer>
        <ComponentTypeLabelContainer gap="small">
          <FlexBox align="center" gap="xsmall">
            <Paragraph variant="text1Bold">
              If {isSource ? 'source' : 'target'} component is missing
            </Paragraph>
            <WithPopover
              content="In the Review import step, you can view the number of new target
                components this import creates."
              preferredPlacement={PopoverPlacement.TOP}
            >
              <Icon iconName={IconName.INFO} color={colors.grey50} />
            </WithPopover>
          </FlexBox>
        </ComponentTypeLabelContainer>
        <Select
          dataTestId="tabularMapping-reference-if-component-is-missing--select"
          value={columnMapping.missingComponents}
          options={getReferenceComponentIsMissingOptions(columnMapping)}
          onValueChange={value => {
            if (value) {
              onMapping({
                ...columnMapping,
                missingComponents: value,
              });
            }
          }}
        />
      </ComponentTypeContainer>
      {columnMapping?.missingComponents ===
        MissingComponentsStrategy.CREATE && (
        <ComponentTypeContainer gap="medium">
          <Selector
            dataTestId="tabularMapping-reference-target-component-type--select"
            placeholder="Type and select"
            label={`${isSource ? 'Source' : 'Target'} component type`}
            value={
              columnMapping.componentType
                ? {
                    label: columnMapping.componentType,
                    value: columnMapping.componentType,
                    rightContent: !compTypeOptions
                      .map(({ value }) => value)
                      .includes(columnMapping.componentType) ? (
                      <NewBadge />
                    ) : null,
                  }
                : undefined
            }
            options={compTypeOptions}
            onValueChange={value => {
              if (value) {
                onMapping({
                  ...columnMapping,
                  componentType: value,
                });
              }
            }}
            hasError={Boolean(
              mappedColumnsErrors[
                SidebarSelectorsIds.COMPONENT_TYPE_IN_REFERENCE
              ]
            )}
            errorMessage={
              mappedColumnsErrors[
                SidebarSelectorsIds.COMPONENT_TYPE_IN_REFERENCE
              ] || undefined
            }
          />
          <Paragraph variant="caption">Review import to see changes.</Paragraph>
        </ComponentTypeContainer>
      )}
    </>
  );
};

const ComponentTypeContainer = styled(Stack)`
  margin: 0 ${s16};
`;

const ComponentTypeLabelContainer = styled(Stack)`
  margin-bottom: ${s8};
`;
