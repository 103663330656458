import type { APIReferenceAttributes } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { DeletedComponent } from './types';

// routine actions
interface GetDeletedEntitiesByWorkspaceIdPayload {
  readonly workspaceId: string;
}
export const getDeletedEntitiesByWorkspaceId =
  actionCreator<GetDeletedEntitiesByWorkspaceIdPayload>(
    '[restoreDeleted] GET_DELETED_ENTITIES_BY_WORKSPACE_ID'
  );

interface GetDeletedEntitiesByWorkspaceIdSuccessPayload {
  readonly components: DeletedComponent[];
  readonly references: APIReferenceAttributes[];
}
export const getDeletedEntitiesByWorkspaceIdSuccess =
  actionCreator<GetDeletedEntitiesByWorkspaceIdSuccessPayload>(
    '[restoreDeleted] GET_DELETED_ENTITIES_BY_WORKSPACE_ID_SUCCESS'
  );

interface GetDeletedEntitiesByWorkspaceIdErrorPayload {
  readonly retryAction: () => void;
}
export const getDeletedEntitiesByWorkspaceIdError =
  actionCreator<GetDeletedEntitiesByWorkspaceIdErrorPayload>(
    '[restoreDeleted] GET_DELETED_ENTITIES_BY_WORKSPACE_ID_ERROR'
  );

export const loadingEntitiesStart = actionCreator(
  '[restoreDeleted] LOADING_ENTITIES_START'
);
