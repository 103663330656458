import { UserAssetPermission } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { UserAssetsOverviewQuery } from 'admin/accessControl/UserProfilePage/userAssetsOverview/userAssetsOverviewTypes';

const NAMESPACE = '[AccessControlPage]';

const openUserAssetsFilterSidebar = actionCreator(
  `${NAMESPACE} open user assets filter sidebar`
);

const closeUserAssetsFilterSidebar = actionCreator(
  `${NAMESPACE} close user assets filter sidebar`
);

const requestUserAssets = actionCreator<UserAssetsOverviewQuery>(
  `${NAMESPACE} request user assets`
);

const requestUserAssetsSuccess = actionCreator<UserAssetPermission[]>(
  `${NAMESPACE} request user assets success`
);

const requestUserAssetsFailure = actionCreator<string>(
  `${NAMESPACE} request user assets failure`
);

const userAssetsFilterChanged = actionCreator<Partial<UserAssetsOverviewQuery>>(
  `${NAMESPACE} user assets filter changed`
);

export const accessControlPageActions = {
  openUserAssetsFilterSidebar,
  closeUserAssetsFilterSidebar,
  requestUserAssets,
  requestUserAssetsSuccess,
  requestUserAssetsFailure,
  userAssetsFilterChanged,
};
