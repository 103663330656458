import Context from 'context';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  APIFieldType,
  APIReferenceType,
  APISurveyAttributes,
  APISurveyPendingApprovals,
  APITagAttributes,
  ArdoqId,
  BooleanOperator,
  isPersistedSurvey,
  SurveyQuestionType,
  SurveyResultFilteringType,
  UnpersistedEntity,
  APIOrganizationUser,
  SurveyFilterSetupAttributes,
  UnpersistedSurveyQuestion,
  SurveySectionStatus,
  PermissionGroup,
} from '@ardoq/api-types';
import { MaybePersistedSurvey, SurveyValidation } from 'surveyAdmin/types';
import { FieldBackboneModel, ValidatorFunction } from 'aqTypes';
import Fields from 'collections/fields';
import Workspaces from 'collections/workspaces';
import {
  checkIfSelectedNewComponentParentDoesNotExist,
  getQuestionValidations,
  hasComponentType,
  hasQuestionValidationOfSeverity,
  isReadonlyField,
} from './questions/utils';
import {
  checkIfSurveyConflictsWithChangeApprovalScope,
  currentUserHasNoAdminAccessToWorkspace,
  getComponentTypeAndModelId,
  getFallbackUserAudience,
} from './SurveyEditor/utils';
import { logError } from '@ardoq/logging';
import { addPermissionForResource } from 'streams/currentUserPermissions/actions';
import { resourcePermissionsModelInterface } from 'resourcePermissions/resourcePermissionsModelInterface';
import { emailValidator, numberValidator } from 'scopeData/editors/validators';
import { getChangeApprovalCompatibillityErrorMessage } from './SurveyEditor/consts';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { currentUserInterface } from '../modelInterface/currentUser/currentUserInterface';
import {
  composeSearchQuery,
  SearchFieldNames,
} from 'search/AdvancedSearch/utils';
import References from 'collections/references';
import { saveNewSurvey } from './streams/actions';
import { surveyAccessControlInterface } from 'resourcePermissions/accessControlHelpers/surveys';
import { dateRangeOperations } from '@ardoq/date-range';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { PermissionContext } from '@ardoq/access-control';
import { isEmpty, isEqual, uniqueId } from 'lodash';
import { modelInterface } from 'modelInterface/models/modelInterface';
import { fieldInterface } from '../modelInterface/fields/fieldInterface';
import { Features, hasFeature } from '@ardoq/features';
import {
  ERROR_MESSAGES,
  SurveyErrorName,
  SurveyWarningName,
  WARNING_MESSAGES,
} from './consts';
import { isHiddenFieldQuestion } from './questions/questionPredicates';
import {
  getUsersAndGroupsAudience,
  hasUserAndGroupsAudience,
} from './ChangeApproval/utils';

const invalidatingAttributes = [
  'questions',
  'workspace',
  'componentTypeName',
  'allowComponentCreation',
  'allowComponentDeletion',
];

export const checkIfInvalidatedSurveyChangeApproval = (
  surveysWithPendingApprovals: Partial<
    Record<string, APISurveyPendingApprovals>
  >,
  survey: APISurveyAttributes,
  newAttributes: Partial<APISurveyAttributes>
) => {
  const surveyHasPendingApprovals = Boolean(
    surveysWithPendingApprovals[survey._id]?.total
  );
  if (!survey.approvalFlow?.enabled || !surveyHasPendingApprovals) return false;

  const newAttributeKeys = Object.keys(newAttributes) as Array<
    keyof APISurveyAttributes
  >;

  return newAttributeKeys.some(
    attributeName =>
      invalidatingAttributes.includes(attributeName) &&
      !isEqual(survey[attributeName], newAttributes?.[attributeName])
  );
};

const generateFieldQuestions = (
  fields: FieldBackboneModel[] = [],
  workspaceId: string | null
) => {
  return dateRangeOperations
    .mergeDateTimeFieldsToDateRangeFields(fields.map(field => field.toJSON()))
    .fields.filter(field => fieldInterface.isFieldAllowedInSurvey(field._id))
    .map(field => {
      const fieldName = dateRangeOperations.isDateRangeField(field)
        ? `${field.dateTimeFields.start.name},${field.dateTimeFields.end.name}`
        : field.name;

      return {
        type: SurveyQuestionType.FIELD,
        label: `${field.label}?`,
        description: field.description ?? '',
        properties: { fieldName },
        readonly: workspaceId ? isReadonlyField(fieldName, workspaceId) : false,
      };
    });
};

const generateReferenceQuestions = (componentId: ArdoqId) => {
  const references = References.collection.filter(ref =>
    [ref.getSourceId(), ref.getTargetId()].includes(componentId)
  );

  return references
    .filter((reference, index, all) => {
      const isOutgoing = reference.get('source') === componentId;
      const firstMatch = all.find(r => {
        const matchingReferenceType = r.getType() === reference.getType();
        const matchingWorkspaces =
          r.get('rootWorkspace') === reference.get('rootWorkspace') &&
          r.get('targetWorkspace') === reference.get('targetWorkspace');
        const matchingDirection = isOutgoing
          ? r.get('source') === componentId
          : r.get('target') === componentId;
        const matchingComponentTypes =
          r.getSource().getComponentTypeName() ===
            reference.getSource().getComponentTypeName() &&
          r.getTarget().getComponentTypeName() ===
            reference.getTarget().getComponentTypeName();

        return (
          matchingReferenceType &&
          matchingWorkspaces &&
          matchingDirection &&
          matchingComponentTypes
        );
      });
      return all.indexOf(firstMatch!) === index;
    })
    .map(reference => {
      const outgoing = reference.get('source') === componentId;
      const otherEnd = outgoing ? reference.getTarget() : reference.getSource();
      const otherEndComponentTypeName =
        otherEnd && otherEnd.getComponentTypeName();
      const referenceTypeName = (reference.getRefType() as APIReferenceType)
        .name;

      return {
        type: SurveyQuestionType.REFERENCE,
        label: `Has "${referenceTypeName}" connection ${
          outgoing ? 'to' : 'from'
        } ${otherEndComponentTypeName}?`,
        description: '',
        properties: {
          componentTypeName: otherEndComponentTypeName,
          outgoing,
          questions: [],
          referenceTypeName,
          workspaceId: reference.get(
            outgoing ? 'targetWorkspace' : 'rootWorkspace'
          ),
        },
      };
    });
};

export const emptyFilterSetup: SurveyFilterSetupAttributes = {
  properties: {},
  enabled: false,
  type: SurveyResultFilteringType.HIERARCHY,
  label: 'Select to filter:',
};

export const emptySurvey = (): UnpersistedEntity<APISurveyAttributes> => ({
  name: '',
  description: '',
  published: false,
  contactEmail: undefined,
  workspace: null,
  componentTypeName: '',
  componentSearch: {
    rules: [],
    condition: BooleanOperator.AND,
  },
  componentTypeIds: null,
  responseFeedbackOnSurveySubmit: null,
  customHeaderFields: [
    {
      name: 'last-updated',
      label: 'Last Updated',
    },
    {
      name: 'surveyComponentStatus',
      label: 'Task Status',
    },
  ],
  questions: [
    {
      type: SurveyQuestionType.ATTRIBUTE,
      label: 'Name?',
      description: '',
      properties: { attributeName: 'name' },
      readonly: false,
      key: uniqueId(),
    },
  ],
  allowComponentCreation: true,
  allowComponentDeletion: false,
  misc: {
    generalInformationSectionStatus: 'initial',
    defineTheDataSectionStatus: 'initial',
    questionsSectionStatus: 'initial',
    feedbackMessageSectionStatus: 'initial',
    landingPageSectionStatus: 'initial',
    resultsDisplaySectionStatus: 'initial',
    ardoqDiscoverSectionStatus: 'initial',
    changeApprovalSectionStatus: 'initial',
    overviewButtonText: '',
    newCompButtonText: '',
    disableLandingPage: false,
  },
  filterSetup: emptyFilterSetup,
  responseFeedbackMessage: '',
  surveyNotificationsEnabled: null,
});

export const createFromSelectedComponent = () => {
  const contextComponent = Context.component();
  if (contextComponent) {
    const componentTypeName = contextComponent.getComponentTypeName();

    const workspaceId = contextComponent.getWorkspace()?.getId();
    if (!workspaceId) {
      return logError(
        Error(
          `Failed to create survey from selected component ${contextComponent.id}, the workspace could not be found`
        )
      );
    }
    const questions = [
      {
        type: SurveyQuestionType.ATTRIBUTE,
        label: `What is the name of the ${componentTypeName.toLowerCase()}?`,
        description: '',
        properties: { attributeName: 'name' },
        readonly: workspaceId ? isReadonlyField('name', workspaceId) : false,
      },
      ...generateFieldQuestions(
        contextComponent
          .getFields()
          .filter(field => field.getType() !== APIFieldType.USER),
        workspaceId
      ),
      ...generateReferenceQuestions(contextComponent.id),
    ];

    const workspace = contextComponent.get('rootWorkspace');

    const newSurvey: UnpersistedEntity<APISurveyAttributes> = {
      ...emptySurvey(),
      name: `Autogenerated survey for ${componentTypeName}`,
      description: `Survey for collecting information about ${componentTypeName}.`,
      workspace,
      newComponentParentId: contextComponent.getParent()?.id,
      componentSearch: composeSearchQuery({
        attributes: [
          {
            attribute: SearchFieldNames.ROOT_WORKSPACE,
            value: workspace,
          },
          {
            attribute: SearchFieldNames.TYPE_NAME,
            value: componentTypeName,
          },
        ],
      }),
      componentTypeName,
      questions: questions as UnpersistedSurveyQuestion[],
    };

    dispatchAction(saveNewSurvey(newSurvey));
  }
};

export const getAndPushToStreamPermissionsForSurvey = (
  surveyId: ArdoqId,
  organizationLabel: string | null
) => {
  return resourcePermissionsModelInterface
    .fetchPermissionsForASurvey(surveyId, organizationLabel)
    .then(surveyModel => {
      dispatchAction(
        addPermissionForResource({
          permission: surveyModel.attributes,
          currentUser: currentUserInterface.getCurrentUserAttributesClone(),
        })
      );
      return surveyModel;
    });
};

const getFields = (survey: MaybePersistedSurvey) => {
  const { selectedModelId, selectedTypeId } = getComponentTypeAndModelId(
    survey.workspace,
    survey.componentTypeName
  );
  return Fields.collection.getByComponentType(selectedTypeId!, selectedModelId);
};

const getHiddenFieldsError = (survey: MaybePersistedSurvey) => {
  const fields = getFields(survey);
  const someFieldHasErrors = survey.questions.some(question => {
    if (isHiddenFieldQuestion(question)) {
      const field = fields.find(
        field => field.name() === question.properties.fieldName
      );

      const validators: Partial<Record<APIFieldType, ValidatorFunction>> = {
        [APIFieldType.NUMBER]: numberValidator,
        [APIFieldType.EMAIL]: emailValidator,
      };

      if (field) {
        const validator = validators[field.getType()];
        return validator ? validator(question.properties.resetValue) : false;
      }
      return 'Please select a field';
    }
    return false;
  });
  return someFieldHasErrors ? 'Some of the hidden fields are invalid' : '';
};

const isRequiredNewComponentParentMissing = (survey: MaybePersistedSurvey) => {
  if (!survey.workspace) return false;
  const workspace = Workspaces.collection.get(survey.workspace);
  // It's too early to decide if parent is missing if component type and workspace is not yet selected
  if (!workspace || !survey.componentTypeName) {
    return false;
  }
  const model = workspace.getModel()!;
  if (model.isFlexible()) {
    return false;
  }

  // If model is not flexible and parent is not set, then the selected component type better be the top level type!!
  const foundRootComponentType = Object.values(
    modelInterface.getTypeHierarchy(model)
  ).find(entity => entity.name === survey.componentTypeName);
  const setParentIdOrHasQuestion =
    survey.newComponentParentId ||
    survey.questions.find(
      question =>
        question.type === SurveyQuestionType.ATTRIBUTE &&
        question.properties.attributeName === 'parent'
    );
  return (
    !foundRootComponentType &&
    survey.allowComponentCreation &&
    !setParentIdOrHasQuestion
  );
};

const isMissingNameQuestion = (survey: MaybePersistedSurvey) => {
  return (
    survey.allowComponentCreation &&
    !survey.questions.find(
      question =>
        question.type === SurveyQuestionType.ATTRIBUTE &&
        question.properties.attributeName === 'name'
    )
  );
};

const isMissingResultFilteringFields = (survey: MaybePersistedSurvey) => {
  const { enabled, type, properties } = survey.filterSetup ?? {};
  if (!enabled || type !== SurveyResultFilteringType.REFERENCE) return false;
  if (!properties) return true;
  const hasMissingWorkspaceId = !properties.workspaceId;
  const hasMissingComponentTypeName = !properties.componentTypeName;
  const hasMissingReferenceDirection = properties.outgoing === undefined;
  const hasMissingReferenceTypeName = !properties.referenceTypeName;
  return (
    hasMissingWorkspaceId ||
    hasMissingComponentTypeName ||
    hasMissingReferenceDirection ||
    hasMissingReferenceTypeName
  );
};

const checkHasChangeApprovalCompatibilityError = (
  survey: MaybePersistedSurvey
) => {
  const isChangeApprovalOn = survey.approvalFlow?.enabled;
  if (!isChangeApprovalOn) return false;

  return checkIfSurveyConflictsWithChangeApprovalScope(survey);
};

const checkIfMissingFallbackUser = (
  survey: MaybePersistedSurvey,
  adminOrWriterUsers: APIOrganizationUser[]
) => {
  const isChangeApprovalOn = survey.approvalFlow?.enabled;
  if (!isChangeApprovalOn) return false;
  if (!hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2)) return false;

  const fallbackUserAudience = getFallbackUserAudience(survey.approvalFlow);
  return !fallbackUserAudience.audiencesIds.some(userId =>
    adminOrWriterUsers.some(user => user._id === userId)
  );
};

const checkIfEmptyUserGroup = (
  survey: MaybePersistedSurvey,
  groupsById: Record<ArdoqId, PermissionGroup>
) => {
  const isChangeApprovalOn = survey.approvalFlow?.enabled;
  if (!isChangeApprovalOn || isEmpty(groupsById)) return false;
  if (!hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2)) return false;

  const usersAndGroupAudience = getUsersAndGroupsAudience(
    survey.approvalFlow?.selectors ?? []
  );

  if (!hasUserAndGroupsAudience(usersAndGroupAudience)) return;
  return usersAndGroupAudience.groupIds.some(
    userGroupId => !groupsById?.[userGroupId].users.length
  );
};

const hasAccessToSelectedResultFilteringWorkspace = (
  survey: MaybePersistedSurvey,
  permissionsContext: PermissionContext
) => {
  const { enabled, type, properties } = survey.filterSetup ?? {};

  if (
    !enabled ||
    !properties?.workspaceId ||
    type !== SurveyResultFilteringType.REFERENCE
  )
    return true;
  return workspaceAccessControlInterface.canAccessWorkspace(
    permissionsContext,
    properties?.workspaceId
  );
};

export const isSelectedResultFilteringComponentTypeInvalid = (
  survey: MaybePersistedSurvey
) => {
  const { enabled, type, properties } = survey.filterSetup ?? {};
  if (!enabled || type !== SurveyResultFilteringType.REFERENCE || !properties)
    return false;
  if (!workspaceInterface.workspaceExists(properties.workspaceId)) {
    // We do not consider it an error with the survey if the user cannot access the component type
    return false;
  }
  const { selectedTypeId } = getComponentTypeAndModelId(
    properties.workspaceId,
    properties.componentTypeName
  );
  return (
    properties.componentTypeName &&
    Boolean(hasComponentType(properties.componentTypeName, selectedTypeId))
  );
};

const isSurveyComponentTypeInvalid = (
  userHasAdminAccessToSurveyWorkspace: boolean,
  survey: MaybePersistedSurvey
) => {
  const { selectedTypeId } = getComponentTypeAndModelId(
    survey.workspace,
    survey.componentTypeName
  );
  return (
    userHasAdminAccessToSurveyWorkspace &&
    Boolean(hasComponentType(survey.componentTypeName, selectedTypeId))
  );
};

export const getSurveyErrorsAndWarnings = async (
  survey: MaybePersistedSurvey,
  tags: APITagAttributes[] | null,
  permissionsContext: PermissionContext,
  adminOrWriterUsers: APIOrganizationUser[],
  groupsById: Record<ArdoqId, PermissionGroup>
): Promise<SurveyValidation> => {
  const surveyId = isPersistedSurvey(survey) ? survey._id : '';
  const misc = survey.misc;
  const errorMessagesMap = new Map<SurveyErrorName, string>();
  const warningMessagesMap = new Map<SurveyWarningName, string>();

  const userCanEditSurvey =
    isPersistedSurvey(survey) &&
    (!survey.workspace ||
      workspaceAccessControlInterface.canAdminWorkspace(
        permissionsContext,
        survey.workspace,
        null
      )) &&
    surveyAccessControlInterface.canEditSurvey(survey._id);

  const userCanCreateAndIsCreatingSurvey =
    !isPersistedSurvey(survey) &&
    surveyAccessControlInterface.canCreateSurvey();

  if (!userCanEditSurvey && !userCanCreateAndIsCreatingSurvey) {
    return {
      _id: surveyId,
      misc,
      errorMessagesMap,
      warningMessagesMap,
      questionValidations: [],
    };
  }

  const hiddenFieldsError = getHiddenFieldsError(survey);
  if (hiddenFieldsError) {
    errorMessagesMap.set(SurveyErrorName.HIDDEN_FIELDS, hiddenFieldsError);
  }

  if (!survey.name?.trim()) {
    errorMessagesMap.set(
      SurveyErrorName.NAME_INVALID,
      ERROR_MESSAGES.SURVEY_NAME_ERROR
    );
  }

  if (survey.contactEmail && !survey.contactEmail.match(/.+@.+/)) {
    errorMessagesMap.set(
      SurveyErrorName.CONTACT_EMAIL_INVALID,
      ERROR_MESSAGES.CONTACT_EMAIL_ERROR
    );
  }

  const userHasAdminAccessToSurveyWorkspace =
    !currentUserHasNoAdminAccessToWorkspace(
      permissionsContext,
      survey.workspace
    );

  if (!survey.workspace) {
    errorMessagesMap.set(
      SurveyErrorName.WORKSPACE_INVALID,
      ERROR_MESSAGES.NO_WORKSPACE_ERROR
    );
  } else if (!userHasAdminAccessToSurveyWorkspace) {
    errorMessagesMap.set(
      SurveyErrorName.NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE,
      ERROR_MESSAGES.NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE
    );
  } else if (!workspaceInterface.workspaceExists(survey.workspace)) {
    errorMessagesMap.set(
      SurveyErrorName.WORKSPACE_INVALID,
      ERROR_MESSAGES.NO_WORKSPACE_ERROR
    );
  }

  const componentTypeInvalid = isSurveyComponentTypeInvalid(
    userHasAdminAccessToSurveyWorkspace,
    survey
  );

  if (componentTypeInvalid) {
    errorMessagesMap.set(
      SurveyErrorName.COMPONENT_TYPE_INVALID,
      ERROR_MESSAGES.COMPONENT_TYPE_MISSING_OR_INVALID
    );
  }

  if (isRequiredNewComponentParentMissing(survey)) {
    errorMessagesMap.set(
      SurveyErrorName.REQUIRED_NEW_COMPONENT_PARENT_IS_MISSING,
      ERROR_MESSAGES.NO_PARENT_SELECTED_FOR_NEW_COMPONENTS
    );
  }

  const selectedNewComponentParentDoesNotExist =
    await checkIfSelectedNewComponentParentDoesNotExist(survey);

  if (selectedNewComponentParentDoesNotExist) {
    errorMessagesMap.set(
      SurveyErrorName.SELECTED_NEW_COMPONENT_PARENT_DOES_NOT_EXIST,
      ERROR_MESSAGES.THE_SELECTED_PARENT_FOR_NEW_COMPONENTS_DOES_NOT_EXIST
    );
  }

  if (
    !hasAccessToSelectedResultFilteringWorkspace(survey, permissionsContext)
  ) {
    warningMessagesMap.set(
      SurveyWarningName.NO_ACCESS_TO_SELECTED_RESULT_FILTERING_WORKSPACE,
      WARNING_MESSAGES.NO_ACCESS_TO_SELECTED_RESULT_FILTERING_WORKSPACE
    );
  }

  if (isSelectedResultFilteringComponentTypeInvalid(survey)) {
    errorMessagesMap.set(
      SurveyErrorName.SELECTED_RESULT_FILTERING_COMPONENT_TYPE_INVALID,
      ERROR_MESSAGES.SELECTED_RESULT_FILTERING_COMPONENT_TYPE_INVALID
    );
  }

  if (isMissingResultFilteringFields(survey)) {
    errorMessagesMap.set(
      SurveyErrorName.MISSING_RESULT_FILTERING_FIELDS,
      ERROR_MESSAGES.MISSING_RESULT_FILTERING_FIELDS
    );
  }

  if (isMissingNameQuestion(survey)) {
    errorMessagesMap.set(
      SurveyErrorName.MISSING_NAME_QUESTION,
      ERROR_MESSAGES.MISSING_NAME_QUESTION
    );
  }

  const questionValidations = await getQuestionValidations({
    survey,
    tags,
    permissionsContext,
  });
  const questionErrorsExist = questionValidations.some(validations =>
    hasQuestionValidationOfSeverity(validations, 'error')
  );
  const questionWarningsExist = questionValidations.some(validations =>
    hasQuestionValidationOfSeverity(validations, 'warning')
  );

  if (questionErrorsExist) {
    errorMessagesMap.set(
      SurveyErrorName.QUESTION_ERRORS_EXIST,
      ERROR_MESSAGES.QUESTION_ERRORS_EXIST
    );
  }

  if (questionWarningsExist) {
    warningMessagesMap.set(
      SurveyWarningName.QUESTION_WARNINGS_EXIST,
      WARNING_MESSAGES.QUESTION_WARNINGS_EXIST
    );
  }

  if (checkHasChangeApprovalCompatibilityError(survey)) {
    errorMessagesMap.set(
      SurveyErrorName.CHANGE_APPROVAL_COMPATIBILITY,
      getChangeApprovalCompatibillityErrorMessage(
        hasFeature(Features.PERMISSION_ZONES_INTERNAL)
      )
    );
  }

  if (checkIfMissingFallbackUser(survey, adminOrWriterUsers)) {
    errorMessagesMap.set(
      SurveyErrorName.CHANGE_APPROVAL_FALLBACK_USER,
      ERROR_MESSAGES.CHANGE_APPROVAL_FALLBACK_USER_ERROR_MESSAGE
    );
  }

  if (checkIfEmptyUserGroup(survey, groupsById)) {
    warningMessagesMap.set(
      SurveyWarningName.CHANGE_APPROVAL_EMPTY_USER_GROUP,
      WARNING_MESSAGES.CHANGE_APPROVAL_EMPTY_USER_GROUP_MESSAGE
    );
  }

  return {
    _id: surveyId,
    misc,
    errorMessagesMap,
    warningMessagesMap,
    questionValidations,
  };
};

export const canAccessWorkspace = (
  workspaceId?: ArdoqId | null,
  workspaceDoesNotExistError?: string,
  workspaceAccessError?: string
) => {
  if (
    workspaceId &&
    workspaceAccessControlInterface.canAccessWorkspace(
      currentUserInterface.getPermissionContext(),
      workspaceId
    )
  )
    return true;
  return workspaceId && !workspaceDoesNotExistError && !workspaceAccessError;
};

export const shouldDisplayErrorMessageForField = (
  entityIsPersisted: boolean,
  sectionStatus: SurveySectionStatus | null,
  fieldHasLostFocus: boolean | undefined
) => {
  return (
    entityIsPersisted || sectionStatus === 'left' || fieldHasLostFocus || false
  );
};
