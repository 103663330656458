import styled from 'styled-components';

export const ResizerStylesProvider = styled.div`
  /* react-split-pane by default is adding overflow: hidden to the .SplitPane
   this is problematic because some of the elements - eg context menus -
   cant be rendered outside the pane - therefore I overwrite the overflow rule
   here */
  .SplitPane {
    overflow: initial !important;
  }

  .Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    box-sizing: border-box;
    background-clip: padding-box;
  }

  .Resizer:hover {
    z-index: 5;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
    flex-shrink: 0;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    pointer-events: none;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }

  .Pane1 {
    display: flex;
  }
`;
