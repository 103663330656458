import styled, { css } from 'styled-components';

type ProgressBarProps = {
  readonly $percentage: number;
};

const emptyColor = '#bfd7d5';
const filledColor = '#418B82';

const ProgressBar = styled.div<ProgressBarProps>`
  height: 4px;
  border: none;
  ${({ $percentage }) => css`
    background: linear-gradient(
      to right,
      ${filledColor} 0% ${$percentage}%,
      ${emptyColor} ${$percentage}%
    );
  `}
`;

export default ProgressBar;
