import { ButtonGroup, PrimaryButton } from '@ardoq/button';
import { SearchContext } from '@ardoq/query-builder';
import { subdivisionsOperations } from '@ardoq/subdivisions';
import { AdvancedSearchQueryBuilder } from 'search/AdvancedSearch/AdvancedSearchQueryBuilder';
import { SubdivisionProps } from 'subdivisionEditor/types';
import { Label } from '@ardoq/forms';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';
import { defaultState } from 'search/AdvancedSearch/consts';
import { BooleanOperator } from '@ardoq/api-types';

export const AdvancedSearchBuilder = (props: SubdivisionProps) => {
  const {
    subdivision,
    selectComponentsCommands,
    componentsSelection: {
      advancedSearchState,
      showResults,
      searchError,
      isFetchingStagedComponents,
    },
  } = props;

  const { isValid, isEmpty, ruleErrorMessages } = advancedSearchState;

  const queryBuilderRules =
    subdivisionsOperations.getSubdivisionSearchQuery(subdivision);
  const isDisabled = !subdivisionEditorOperations.isAdvancedSearchMode(props);
  const hasBoundWorkspaces =
    subdivisionEditorOperations.getAllSelectedWorkspacesToBindToSubdivision(
      props
    ).length;
  return (
    <>
      <Label isDisabled={isDisabled}>Search for components</Label>
      <AdvancedSearchQueryBuilder
        showResults={showResults}
        isExporting={false}
        results={[]}
        total={0}
        selectedFieldNames={[]}
        selectedPage={1}
        isDisabled={isDisabled}
        maxSubqueryNestingLevel={0}
        holdInvalidRules={true}
        ruleErrorMessages={ruleErrorMessages}
        selectedFilterType={SearchContext.COMPONENT}
        contextSelectIsReadOnly={true}
        includeContextSelect={false}
        allowedSearchContexts={[SearchContext.COMPONENT]}
        defaultSearchContext={SearchContext.COMPONENT}
        queryBuilderRules={queryBuilderRules}
        showErrors={shouldShowErrors(props)}
        fields={defaultState.fields}
        searchError={searchError}
        contextWorkspaceIds={subdivisionEditorOperations.getAllSelectedWorkspacesToBindToSubdivision(
          props
        )}
        onChange={payload =>
          selectComponentsCommands.handleAdvancedSearchChange(payload)
        }
        actions={{}}
        selectedCondition={queryBuilderRules?.condition ?? BooleanOperator.AND}
      />
      <ButtonGroup>
        <PrimaryButton
          isLoading={isFetchingStagedComponents}
          onClick={() => selectComponentsCommands.handleSearchClick(props)}
          isDisabled={
            !isValid ||
            isEmpty ||
            !queryBuilderRules ||
            isDisabled ||
            !hasBoundWorkspaces
          }
        >
          Search
        </PrimaryButton>
      </ButtonGroup>
    </>
  );
};

const shouldShowErrors = (props: SubdivisionProps) => {
  return !!props.stepsErrors['components-selection'].advancedSearchQueries;
};
