import { TextInput } from '@ardoq/forms';
import { Zone } from '@ardoq/api-types';
import { ZoneCommands } from '../types';

export const ComponentTypeFieldsGroupTitle = ({
  isEditingTitle,
  zone,
  commands,
  errorMessage,
}: {
  isEditingTitle: boolean;
  zone: Zone;
  commands: ZoneCommands;
  errorMessage?: string;
}) => {
  return (
    <>
      {isEditingTitle ? (
        <TextInput
          autoFocus
          label="New field group name"
          value={zone.name}
          onValueChange={newName => commands.changeZoneName({ newName, zone })}
          onBlur={() =>
            commands.finishEditingZoneName(!!errorMessage, zone._id)
          }
          errorMessage={errorMessage}
        />
      ) : (
        `Field group - ${zone.name}`
      )}
    </>
  );
};
