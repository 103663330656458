import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs/operators';
import globalFields$ from 'globalFields/globalFields$';
import { context$ } from 'streams/context/context$';
import { splitDateRangeFieldIntoDateTimeFields } from 'appModelStateEdit/propertiesEditor/fieldUtils';
import { createFieldFromAttributes } from 'appModelStateEdit/persistenceUtils';
import {
  addFieldByName,
  addFieldNewToWorkspace,
  createFieldByLabel,
} from 'appModelStateEdit/propertiesEditor/addField/addFieldToWorkspace/actions';
import { ADD_FIELD_NEW_TO_WORKSPACE_ATTRIBUTES } from 'appModelStateEdit/consts';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { showRightPane } from 'appContainer/actions';
import { OLD_DEFAULT_FIELD_ORDER } from 'models/consts';
import { setFormError } from 'appModelStateEdit/propertiesEditor/actions';
import { getErrorMessages } from 'authentication/errorUtil';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

const handleAddFieldByName = routine(
  ofType(addFieldByName),
  extractPayload(),
  withLatestFrom(globalFields$, context$),
  tap(([fieldName, { globalFields }, { workspaceId }]) => {
    const modelId = workspaceInterface.getWorkspaceModelId(workspaceId);
    if (!modelId) {
      return;
    }
    const field = globalFields.find(
      currentField => currentField.name === fieldName
    );
    if (!field) {
      return;
    }
    dispatchAction(
      addFieldNewToWorkspace({
        ...field,
        _order: OLD_DEFAULT_FIELD_ORDER,
        model: modelId,
        global: false,
        globalref: false,
        componentType: [],
        referenceType: [],
      })
    );
  })
);

const handleAddFieldNewToWorkspace = routine(
  ofType(addFieldNewToWorkspace),
  extractPayload(),
  tap(async field => {
    const fieldsToCreate = [field].flatMap(
      splitDateRangeFieldIntoDateTimeFields
    );
    try {
      const createdFields = await Promise.all(
        fieldsToCreate.map(
          createFieldFromAttributes(ADD_FIELD_NEW_TO_WORKSPACE_ATTRIBUTES)
        )
      );
      dispatchAction(
        showRightPane({
          type: GetContentOptionsType.FIELD_EDITOR,
          field: { id: createdFields[0]._id },
        })
      );
    } catch (err) {
      const errorMessage = getErrorMessages[err as string] ?? err;
      dispatchAction(setFormError(errorMessage));
    }
  })
);

const handleCreateFieldByLabel = routine(
  ofType(createFieldByLabel),
  extractPayload(),
  tap(label => {
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.CREATE_FIELD,
        label,
      })
    );
  })
);

export default collectRoutines(
  handleAddFieldByName,
  handleAddFieldNewToWorkspace,
  handleCreateFieldByLabel
);
