import React from 'react';
import { PopoverContentType } from '@ardoq/popovers';
import { LabelWithPopoverIcon } from 'integrations/common/components/LabelWithPopoverIcon/LabelWithPopoverIcon';
import { Connection } from '@ardoq/api-types/integrations';
import { ConnectionsSelector } from './connectionSelector/ConnectionsSelector';
import {
  PrivacyContainer,
  SelectContainer,
  SelectorContainer,
  SubTitle,
} from './atoms';

type SelectConnectionsProps = {
  connections: Connection[];
  selectedConnectionIds: string[];
  onSelect: (connectionIds: string[]) => void;
  selectConnectionHelperText: string;
  selectConnectionPlaceHolder: string;
  popoverContent?: PopoverContentType;
  isMultiSelect?: boolean;
};

export const SelectConnections = ({
  connections,
  selectedConnectionIds,
  onSelect,
  selectConnectionHelperText,
  selectConnectionPlaceHolder,
  popoverContent,
  isMultiSelect = false,
}: SelectConnectionsProps) => {
  return (
    <SelectContainer>
      <LabelWithPopoverIcon popoverContent={popoverContent}>
        <SubTitle>1. Select connection</SubTitle>
      </LabelWithPopoverIcon>
      <PrivacyContainer>{selectConnectionHelperText}</PrivacyContainer>
      <SelectorContainer>
        <ConnectionsSelector
          connections={connections}
          selectedConnectionIds={selectedConnectionIds}
          onSelect={onSelect}
          isMultiSelect={isMultiSelect}
          selectConnectionPlaceHolder={selectConnectionPlaceHolder}
        />
      </SelectorContainer>
    </SelectContainer>
  );
};
