import { GraphNode } from '@ardoq/graph';
import { MetadataComponent } from './types';
import { getRepresentationDataWithColorVariants } from './components/getRepresentationDataWithColorVariants';

const nodeToMetaDataComponent = (
  node?: GraphNode
): MetadataComponent | null => {
  if (!node?.metaData?.representationData) {
    return null;
  }
  return {
    typeId: node.getTypeId(),
    color: node.getColor() ?? '',
    label: node.getLabel(),
    representationData: getRepresentationDataWithColorVariants(
      node.metaData.representationData
    ),
  };
};

export const getNodeMetaDataFromGraphNodeId = (
  graphNodes: Map<string, GraphNode>,
  id: string | null
): MetadataComponent | null => {
  const node = graphNodes.get(id ?? '');
  return nodeToMetaDataComponent(node);
};

export const getNodeMetaDataFromTypeName = (
  graphNodes: Map<string, GraphNode>,
  typeName: string
): MetadataComponent | null => {
  const node = Array.from(graphNodes.values()).find(
    node => node.getLabel() === typeName
  );
  return nodeToMetaDataComponent(node);
};
