import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RichTextReader } from '@ardoq/rich-text-editor';
import { colors, s16, s24 } from '@ardoq/design-tokens';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  updateHideLogo,
  updateMessageBody,
  updateMessageReplyTo,
  updateMessageSender,
  updateMessageSubject,
} from 'broadcasts/actions';
import {
  MESSAGE_READONLY_PLACEHOLDER,
  REPORT_READONLY_PLACEHOLDER,
  SURVEY_READONLY_PLACEHOLDER,
} from 'broadcasts/consts';
import composeMessagesForm$, {
  ComposeMessagesFormStreamShape,
} from './composeMessagesForm$';
import { TertiaryButton } from '@ardoq/button';
import LogoChooser from './LogoChooser';
import { popoverRegistry, WithPopover } from '@ardoq/popovers';
import { get2024EditorConfig, getEditorConfig, POPOVER_ID } from './utils';
import {
  APIOrganizationAttributes,
  BroadcastAudience,
  BroadcastContentType,
  BroadcastMessage,
  BroadcastValidation,
} from '@ardoq/api-types';
import SenderPopoverContent from './SenderPopoverContent';
import {
  Field,
  FormSize,
  FormWrapper,
  Label,
  RadioGroup,
  Switch,
  TextInput,
} from '@ardoq/forms';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { isTargetedSource } from 'broadcasts/utils';
import { Island } from '@ardoq/page-layout';
import { PresentationModalOption } from './AddPresentationDialog';
import { RichTextEditorTransition } from '@ardoq/rich-text-editor-2024';
import { Stack } from '@ardoq/layout';

type ComposeMessagesFormProps = {
  readonly lastModifiedByEmail: string | null;
  readonly message: BroadcastMessage;
  readonly contentType: BroadcastContentType;
  readonly richTextEditorResetKey: number;
  readonly org: APIOrganizationAttributes | null;
  readonly audiences: BroadcastAudience[];
} & ComposeMessagesFormStreamShape &
  BroadcastValidation;

type MessageInputProps = {
  readonly value: string;
  readonly contentType: BroadcastContentType;
  readonly richTextEditorResetKey: number;
  readonly useCompanyLogo: boolean;
  readonly logo: string | undefined;
  readonly presentationOptions: PresentationModalOption[];
  readonly hideLogo: boolean;
};

const PreviewContainer = styled.div`
  border: 1px solid ${colors.grey80};
  padding: ${s24};
`;

const MessageInputContainer = styled.div`
  flex: 1;
  max-width: 820px;
`;

const BroadcastLogo = styled.img`
  margin: 0 auto ${s24} auto;
  display: block;
  height: 40px;
`;

const MessageWrapper = styled.div`
  margin-bottom: ${s16};
`;

const MessageInput = ({
  value,
  contentType,
  richTextEditorResetKey,
  useCompanyLogo,
  logo,
  presentationOptions,
  hideLogo,
}: MessageInputProps) => {
  const [isEditable, setEditable] = useState(false);

  const readonlyPlaceholder =
    contentType === BroadcastContentType.MESSAGE
      ? MESSAGE_READONLY_PLACEHOLDER
      : contentType === BroadcastContentType.REPORT
        ? REPORT_READONLY_PLACEHOLDER
        : SURVEY_READONLY_PLACEHOLDER;

  return (
    <>
      <MessageWrapper>
        {isEditable ? (
          <RichTextEditorTransition
            // Force reset internal state to initialContent on content type change
            // and when opening a new broadcast form
            key={richTextEditorResetKey}
            content={value}
            config={getEditorConfig(contentType, presentationOptions)}
            toolbarExtensions={get2024EditorConfig(
              contentType,
              presentationOptions
            )}
            onInputChange={newValue =>
              dispatchAction(updateMessageBody(newValue))
            }
          />
        ) : (
          <PreviewContainer>
            {!hideLogo && (
              <BroadcastLogo
                src={useCompanyLogo && logo ? logo : '/img/ardoq_web.png'}
                alt="broadcast header logo"
              />
            )}
            <RichTextReader
              // Include readonly placeholder which is always added to the message by the backend
              content={value + readonlyPlaceholder}
            />
          </PreviewContainer>
        )}
      </MessageWrapper>
      <TertiaryButton
        onClick={() => setEditable(!isEditable)}
        data-click-id="edit-broadcast-message"
        dataTestId="edit-broadcast-message"
      >
        {isEditable ? 'Save' : 'Edit'}
      </TertiaryButton>
    </>
  );
};

const IncorrectPermissionsPopoverContainer = styled.p`
  max-width: 208px;
`;

const InfoIcon = styled(Icon).attrs({
  iconName: IconName.INFO,
  iconSize: IconSize.SMALL,
})`
  cursor: help;
  margin-left: 4px;
`;

const ComposeMessagesForm = ({
  lastModifiedByEmail,
  message,
  contentType,
  richTextEditorResetKey,
  presentationOptions,
  errors,
  warnings,
  org,
  currentUserEmail,
  audiences,
}: ComposeMessagesFormProps) => {
  useEffect(() => {
    const IncorrectPermissionsPopover = () => (
      <IncorrectPermissionsPopoverContainer>
        This presentation&apos;s permissions might not match the
        broadcast&apos;s audiences.
      </IncorrectPermissionsPopoverContainer>
    );

    popoverRegistry.set(POPOVER_ID, IncorrectPermissionsPopover);

    return function cleanup() {
      popoverRegistry.delete(POPOVER_ID);
    };
  }, []);

  const hasValidTasksAudience =
    contentType === BroadcastContentType.SURVEY &&
    audiences.some(audience => isTargetedSource(audience.audienceType));

  const hideLogo = message?.hideLogo ?? false;
  return (
    <Island
      data-intercom-target="broadcast-message"
      title="4. Compose message"
      subtitle="Tell your audience what this broadcast is about. Give them relevant context to help them understand what to do and why it matters. You can edit the invitation template and the placeholders will be replaced when the email is sent out."
    >
      <FormWrapper $gap="s32" $formSize={FormSize.AUTO}>
        <FormWrapper $gap="s16" $formSize={FormSize.DEFAULT}>
          <Field label="Subject">
            <TextInput
              placeholder="Subject"
              onChange={e =>
                dispatchAction(updateMessageSubject(e.target.value))
              }
              value={message.subject}
              dataTestId="subject-input"
              helperText={
                hasValidTasksAudience
                  ? "This will also be shown in the task generated in the 'My Tasks' page in Discover"
                  : undefined
              }
            />
          </Field>
          <Field>
            <Label>
              Sender
              <WithPopover
                content={() => <SenderPopoverContent sender={message.sender} />}
              >
                <InfoIcon />
              </WithPopover>
            </Label>
            <TextInput
              warningMessage={
                warnings?.SENDER_UNICODE ?? warnings?.SENDER_MALFORMED
              }
              errorMessage={errors?.SENDER_INVALID}
              placeholder="Sender"
              onChange={e =>
                dispatchAction(updateMessageSender(e.target.value))
              }
              value={message.sender}
              dataTestId="sender-input"
            />
          </Field>
        </FormWrapper>
        <Field label="Reply to">
          <RadioGroup
            onValueChange={value =>
              dispatchAction(updateMessageReplyTo(Boolean(value)))
            }
            options={[
              {
                label: <span>{lastModifiedByEmail ?? currentUserEmail}</span>,
                value: 0,
              },
              {
                label: <span>No reply-to address</span>,
                value: 1,
              },
            ]}
            value={message.replyToArdoq ? 1 : 0}
          />
        </Field>
        <Field label="Message">
          <Stack gap="small">
            <Switch
              label="Hide logo"
              name="hideLogo"
              isChecked={hideLogo}
              onChange={val => dispatchAction(updateHideLogo(val))}
            />
            {!message?.hideLogo && org && (
              <LogoChooser
                org={org}
                useCompanyLogo={Boolean(message.companyLogo)}
              />
            )}
            <MessageInputContainer>
              <MessageInput
                hideLogo={hideLogo}
                logo={org?.settings.logo}
                useCompanyLogo={Boolean(message.companyLogo)}
                value={message.body}
                contentType={contentType}
                presentationOptions={presentationOptions}
                richTextEditorResetKey={richTextEditorResetKey}
              />
            </MessageInputContainer>
          </Stack>
        </Field>
      </FormWrapper>
    </Island>
  );
};

export default connect(ComposeMessagesForm, composeMessagesForm$);
