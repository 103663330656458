import FieldEditor from './FieldEditor';
import {
  APIFieldAttributes,
  APIFieldType,
  isEntity,
  PersistedHiddenQuestion,
  UnpersistedHiddenQuestion,
} from '@ardoq/api-types';
import { Label } from '@ardoq/forms';
import { ValidatorFunction } from 'aqTypes';
import FieldSelect from './FieldSelect';
import { AllPossibleQuestions, SurveyQuestionValidations } from '../types';
import {
  getHiddenFieldAnswerErrors,
  getQuestionId,
  isReadonlyField,
} from './utils';
import { shouldDisplayErrorMessageForField } from 'surveyAdmin/surveyUtil';
import surveyEditor$ from 'surveyAdmin/SurveyEditor/streams/surveyEditor$';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { setSurveyQuestionFieldHasBeenInteractedWith } from 'surveyAdmin/SurveyEditor/streams/actions';
import { SurveyEditorState } from 'surveyAdmin/SurveyEditor/streams/types';
import styled from 'styled-components';
import { Space } from '@ardoq/style-helpers';
import { isDateRangeFieldType } from '@ardoq/date-range';

const StyledSpace = styled(Space)`
  max-width: 520px;
`;

interface HiddenFieldProperties {
  question: UnpersistedHiddenQuestion | PersistedHiddenQuestion;
  questions: AllPossibleQuestions[] | undefined;
  fields: APIFieldAttributes[];
  workspaceId: string | null;
  validators: Record<APIFieldType, ValidatorFunction>;
  updateQuestion: (
    question: UnpersistedHiddenQuestion | PersistedHiddenQuestion
  ) => void;
  questionValidations?: SurveyQuestionValidations;
  fieldHasBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith'];
}
const HiddenField = ({
  question,
  questions,
  fields,
  workspaceId,
  updateQuestion,
  questionValidations = { questionValidations: [] },
  fieldHasBeenInteractedWith,
}: HiddenFieldProperties) => {
  const filteredFields = fields.filter(
    field =>
      !isDateRangeFieldType(field.type) &&
      !isReadonlyField(field.name, workspaceId)
  );
  const currentValue = question.properties.fieldName;
  const selectedField = fields.find(field => field.name === currentValue);
  const resetValue = question.properties.resetValue;
  const error = getHiddenFieldAnswerErrors(
    questionValidations,
    selectedField?.label,
    selectedField?.type
  );

  const handleFieldUpdate = (fieldName: any, fieldType?: APIFieldType) => {
    const previousFieldType = selectedField?.type;
    updateQuestion({
      ...question,
      properties: {
        ...question.properties,
        fieldName,
        resetValue:
          fieldType === previousFieldType &&
          fieldType !== APIFieldType.LIST &&
          fieldType !== APIFieldType.SELECT_MULTIPLE_LIST
            ? resetValue
            : undefined,
      },
    });
  };

  const questionHasBeenClosed =
    fieldHasBeenInteractedWith.questions[getQuestionId(question)]
      ?.questionViewHasBeenClosed;

  const shouldShowFieldSelectFieldErrorIfPresent =
    shouldDisplayErrorMessageForField(
      isEntity(question),
      null,
      fieldHasBeenInteractedWith.questions[getQuestionId(question)]
        ?.questionsSectionHiddenFieldFieldSelectField || questionHasBeenClosed
    );

  const shouldShowValueSelectFieldErrorIfPresent =
    shouldDisplayErrorMessageForField(
      isEntity(question),
      null,
      fieldHasBeenInteractedWith.questions[getQuestionId(question)]
        ?.questionsSectionHiddenFieldValueSelectField || questionHasBeenClosed
    );

  return (
    <>
      <FieldSelect
        question={question}
        questions={questions}
        fields={filteredFields}
        attributes={[]}
        workspaceId={workspaceId}
        questionValidations={questionValidations}
        updateFieldQuestion={fieldData =>
          handleFieldUpdate(fieldData.value, fieldData.fieldType)
        }
        popoverHelpContent={`This can be used as a review value for the survey administrator to
          search by after the respondents are finished, or for similar purposes.
          The value will be set each time the respondent submits a response on a component or reference.`}
        onBlur={() =>
          dispatchAction(
            setSurveyQuestionFieldHasBeenInteractedWith({
              questionKey: getQuestionId(question),
              fieldKey: 'questionsSectionHiddenFieldFieldSelectField',
            })
          )
        }
        shouldShowErrorMessagesIfPresent={
          shouldShowFieldSelectFieldErrorIfPresent
        }
        hintMessage="Required"
      />
      {selectedField && (
        <StyledSpace $isVertical $gap="s4">
          <Label>Value</Label>
          <FieldEditor
            onBlur={() =>
              dispatchAction(
                setSurveyQuestionFieldHasBeenInteractedWith({
                  questionKey: getQuestionId(question),
                  fieldKey: 'questionsSectionHiddenFieldValueSelectField',
                })
              )
            }
            hintMessage="Required"
            field={selectedField}
            value={resetValue}
            onChange={value =>
              updateQuestion({
                ...question,
                properties: {
                  ...question.properties,
                  fieldName: currentValue,
                  resetValue: value,
                },
              })
            }
            errorMessage={
              shouldShowValueSelectFieldErrorIfPresent ? error : undefined
            }
          />
        </StyledSpace>
      )}
    </>
  );
};
export default connect(HiddenField, surveyEditor$);
