import { map, mergeMap, tap } from 'rxjs/operators';
import { groupBy } from 'lodash';
import { AssetPayload, AssetType, RenameRequest } from '@ardoq/api-types';
import { deleteAssets, renameAsset } from './actions';
import {
  collectRoutines,
  routine,
  dispatchAction,
  extractPayload,
  ofType,
  withNamespace,
} from '@ardoq/rxbeach';
import { logError } from '@ardoq/logging';
import { alert } from '@ardoq/modal';
import { removeMetamodel } from 'streams/metamodels/metamodelActions';
import { bulkDeleteDashboard } from '../dashboards/actions';
import { viewpointDeleteSuccess } from '../../viewpoints/actions';
import { assetApi, handleError } from '@ardoq/api';
import { notifyDeletingBroadcastSucceeded } from '../../broadcasts/actions';
import { Result } from '@ardoq/common-helpers';

export const renameRoutine = <T>(
  namespace: string,
  renameFn: (payload: RenameRequest) => Promise<Result<T>>
) =>
  routine(
    withNamespace(namespace),
    ofType(renameAsset),
    extractPayload(),
    mergeMap(renameFn),
    handleError()
  );

const handleDeletedAssets = (assets: AssetPayload[]) => {
  const groupedAssets = groupBy(assets, ({ type }) => type);

  // groupedDeletedItems[AssetType.METAMODEL]
  (groupedAssets[AssetType.METAMODEL] || []).forEach(({ _id }) =>
    dispatchAction(removeMetamodel(_id))
  );

  const deletedDashboards = (groupedAssets[AssetType.DASHBOARD] || []).map(
    ({ _id }) => _id
  );
  dispatchAction(bulkDeleteDashboard(deletedDashboards));

  (groupedAssets[AssetType.VIEWPOINT] || []).map(({ _id }) =>
    dispatchAction(viewpointDeleteSuccess(_id))
  );

  (groupedAssets[AssetType.BROADCAST] || []).map(({ _id }) =>
    dispatchAction(notifyDeletingBroadcastSucceeded(_id))
  );
};

const handleDeleteAssets = routine(
  ofType(deleteAssets),
  extractPayload(),
  mergeMap(({ assets }) => assetApi.deleteAssets(assets)),
  handleError(error => {
    logError(error, 'Group assets deletion failed');
    alert({
      title: 'Error',
      subtitle: 'Sorry, assets deletion failed',
    });
  }),
  map(response =>
    response.map(({ _id, ardoq }) => ({
      _id,
      type: ardoq['entity-type'],
    }))
  ),
  tap(handleDeletedAssets)
);

export const assetsRoutines = collectRoutines(handleDeleteAssets);
