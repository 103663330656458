import { Citation } from './types';
import ReactDOMServer from 'react-dom/server';
import { ChatMessageCitation } from './components/ChatMessageCitation';

const regexToMatchAdjacentTokens = /(\[doc\d+])(?=\[doc\d+])/g;
const addSuperScriptCommasBetweenTokens = (messageHtml: string) =>
  messageHtml.replace(
    regexToMatchAdjacentTokens,
    `$1${ReactDOMServer.renderToString(<sup>,</sup>)}`
  );

export const replaceCitationTokensInMessageWithLinks = (
  messageHtml: string,
  citations: Map<string, Citation>
) =>
  Array.from(citations.entries()).reduce(
    (message, [citationToken, citation], index) =>
      message.replaceAll(
        citationToken,
        ReactDOMServer.renderToString(
          <ChatMessageCitation label={`${index + 1}`} href={citation.url} />
        )
      ),
    addSuperScriptCommasBetweenTokens(messageHtml)
  );
