import {
  APIDiscoverViewpointAttributes,
  GroupingCollapseState,
  ViewpointDependencyMapViewStyle,
  ViewpointSimpleBlockDiagramViewStyle,
  ViewpointViewStyle,
} from '@ardoq/api-types';
import styled from 'styled-components';
import { s24 } from '@ardoq/design-tokens';
import LayoutOrientationSelector from './LayoutOrientationSelector';
import TimelineFieldSelector from './TimelineFieldSelector';
import { Select } from '@ardoq/select';
import {
  UseControllerProps,
  useController,
  useFormContext,
} from 'react-hook-form';
import TimelineDateRangeSelector from './TimelineDateRangeSelector';
import { readTimelineGridPrecisionViewSetting } from '@ardoq/timeline';

const Wrapper = styled.div`
  display: grid;
  gap: ${s24};
`;

const defaultVisualizationOptions = [
  {
    label: 'Network',
    value: 'relationship-view',
  },
  { label: 'Dependency Map', value: 'dependency-map' },
  { label: 'Simple Block Diagram', value: 'simple-block-diagram' },
  {
    label: 'Timeline',
    value: 'timeline',
  },
];

const ViewStyleConfiguration = (props: UseControllerProps) => {
  const {
    field: { onChange },
  } = useController(props);
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const [viewStyle, workspaceIds] = watch(['viewStyle', 'workspaceIds']);

  const defaultVisualization = viewStyle.style;

  const handleSelectViewStyle = (value: ViewpointViewStyle['style']) => {
    if (value === 'relationship-view') return onChange({ style: value });
    if (value === 'timeline') {
      // since the viewpoint resets on this value change, we can supply the default option
      const gridPrecision = readTimelineGridPrecisionViewSetting();
      return onChange({
        style: value,
        fields: [],
        gridPrecision,
        defaultGroupingCollapseState: GroupingCollapseState.EXPAND_ALL,
      });
    }
    if (value === 'dependency-map') {
      const { layoutOrientation } =
        viewStyle as ViewpointDependencyMapViewStyle;
      return onChange({
        style: value,
        layoutOrientation: layoutOrientation ?? 'left-to-right',
      });
    }
    const { layoutOrientation } =
      viewStyle as ViewpointSimpleBlockDiagramViewStyle;
    onChange({
      style: value,
      layoutOrientation: layoutOrientation ?? 'left-to-right',
      defaultGroupingCollapseState: GroupingCollapseState.EXPAND_ALL,
    });
  };
  return (
    <Wrapper>
      <Select
        value={defaultVisualization}
        options={defaultVisualizationOptions}
        onValueChange={value =>
          handleSelectViewStyle(value as ViewpointViewStyle['style'])
        }
        dataTestId="visualization-select"
      />
      {(defaultVisualization === 'dependency-map' ||
        defaultVisualization === 'simple-block-diagram') && (
        <LayoutOrientationSelector
          defaultVisualization={defaultVisualization}
        />
      )}
      {defaultVisualization === 'timeline' && (
        <>
          <TimelineFieldSelector workspaceIds={workspaceIds} />
          <TimelineDateRangeSelector />
        </>
      )}
    </Wrapper>
  );
};

export default ViewStyleConfiguration;
