import { useRef } from 'react';
import { EditTraversalCommands } from './editTraversalCommands';
import { viewpointBuilderEventListeners } from 'viewpointBuilder/viewpointBuilderEventListeners';
import { ViewpointBuilderFooter } from 'viewpointBuilder/components/ViewpointBuilderFooter';
import SimpleGraphView from 'viewpointBuilder/components/SimpleGraph/SimpleGraphView';
import { createGraphInterface } from 'viewpointBuilder/components/SimpleGraph/GraphInterface';
import { ModalText } from '@ardoq/modal';
import { ErrorNotification } from '@ardoq/status-ui';
import { EditTraversalOptionsPanel } from 'viewpointBuilder/components/EditTraversalOptionsPanel';
import { SimpleGraphContainerWithGreyBackground } from 'viewpointBuilder/components/SimpleGraph/SimpleGraphViewContainer';
import { PrimaryButtonConfig } from 'viewpointBuilder/openViewpointBuilder/getPrimaryButtonConfig';
import { WithMetamodelLoader } from '../components/WithMetamodelLoader';
import { TraversalBuilderViewState } from '../types';
import { ResizablePageBody } from '@ardoq/page-layout';
import { FloatingClickOtherComponentsHint } from './FloatingClickOtherComponentsHint';

type BuildSubgraphContentProps = {
  state: TraversalBuilderViewState;
  commands: EditTraversalCommands;
  primaryButtonConfig: PrimaryButtonConfig;
  onCancel: VoidFunction;
  label?: string;
};

export const BuildSubgraphContent = ({
  state: {
    graphNodes,
    graphEdges,
    graphGroups,
    errorState,
    hasTripleOptions,
    filteredTripleOptions,
    selectedGraphNodeId,
    triplesSortAndFiltersState,
    isMetaModelLoaded,
    shouldShowDeleteCurrentNodeButton,
    shouldIncludeClickOtherComponentTypeNodesNotification,
  },
  commands,
  primaryButtonConfig,
  onCancel,
  label = 'Dataset metamodel',
}: BuildSubgraphContentProps) => {
  const graphInterface = useRef(createGraphInterface());
  return (
    <WithMetamodelLoader
      isMetaModelLoaded={isMetaModelLoaded}
      closeViewpointBuilder={onCancel}
    >
      <ResizablePageBody
        skipPadding
        footerContent={
          <ViewpointBuilderFooter
            closeViewpointBuilder={onCancel}
            primaryButtonConfig={primaryButtonConfig}
          />
        }
        rightContentInitialWidth={600}
        rightContent={
          <EditTraversalOptionsPanel
            selectedGraphNodeId={selectedGraphNodeId}
            tripleOptions={filteredTripleOptions}
            hasTripleOptions={hasTripleOptions}
            commands={commands}
            triplesSortAndFiltersState={triplesSortAndFiltersState}
            shouldShowDeleteCurrentNodeButton={
              shouldShowDeleteCurrentNodeButton
            }
          />
        }
      >
        {errorState === 'NONE' ? (
          <SimpleGraphContainerWithGreyBackground
            onClick={
              viewpointBuilderEventListeners.toggleGraphNodeSelectionInTraversalEdit
            }
          >
            {shouldIncludeClickOtherComponentTypeNodesNotification && (
              <FloatingClickOtherComponentsHint
                onClickNeverShowAgainHints={
                  commands.skipClickOtherComponentTypeNodesHints
                }
              />
            )}
            <SimpleGraphView
              viewModel={{ graphNodes, graphEdges, graphGroups }}
              graphInterface={graphInterface.current}
              label={label}
            />
          </SimpleGraphContainerWithGreyBackground>
        ) : (
          <ModalText>
            <ErrorNotification>{'TODO'}</ErrorNotification>
          </ModalText>
        )}
      </ResizablePageBody>
    </WithMetamodelLoader>
  );
};
