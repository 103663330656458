import { dispatchAction } from '@ardoq/rxbeach';
import * as actions from './actions';
import { ExpandableSectionId } from './types';
import { MetamodelViewCommands } from './types';

const toggleIsSelectedGraphNode = (graphNodeId: string) => {
  dispatchAction(actions.toggleIsSelectedGraphNode({ graphNodeId }));
};

const toggleExpandableSection = (expandableId: ExpandableSectionId) =>
  dispatchAction(actions.toggleExpandableSection({ expandableId }));

export const metamodelViewCommands: MetamodelViewCommands = {
  toggleIsSelectedGraphNode,
  toggleExpandableSection,
};
