import { FieldsWrapper } from '@ardoq/forms';
import { CollapsibleIsland } from '@ardoq/page-layout';
import { SearchableUsers } from '@ardoq/table';
import { getUserAndGroupOptions } from 'resourcePermissions/utils';
import FallbackUsersAudienceDisplay from './FallbackUsersAudienceDisplay';
import {
  SurveyApprovalFlow,
  APISurveyAttributes,
  ArdoqId,
  APIOrganizationUser,
  OrgAccessLevel,
  SurveyApprovalFallbackAudience,
} from '@ardoq/api-types';
import { connect, derivedStream } from '@ardoq/rxbeach';
import permissionGroup$ from 'admin/accessControl/PermissionGroups/streams/permissionGroups$';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { map } from 'rxjs';
import {
  getFallbackUserAudience,
  isAdminOrWriter,
} from 'surveyAdmin/SurveyEditor/utils';
import { ErrorNotification } from '@ardoq/status-ui';

const updateFallbackAudience = (
  audience: SurveyApprovalFallbackAudience,
  newAudience: ArdoqId[]
): SurveyApprovalFallbackAudience => {
  return { ...audience, audiencesIds: newAudience };
};

const validateFallbackUsers = (
  fallbackUsers: ArdoqId[],
  adminOrWriterUsers: APIOrganizationUser[]
) =>
  fallbackUsers.filter(id => adminOrWriterUsers.find(user => user._id === id));

const isReaderOrContributor = (user: APIOrganizationUser) =>
  user.role === OrgAccessLevel.READER ||
  user.role === OrgAccessLevel.CONTRIBUTOR;

type FallbackUserSelectionProps = {
  surveyCreatorId: ArdoqId;
  approvalFlow: SurveyApprovalFlow;
  readerOrContributorUsers: APIOrganizationUser[];
  adminOrWriterUsers: APIOrganizationUser[];
  errorMessage?: string;
  setSurveyAttributes: (newAttributes: Partial<APISurveyAttributes>) => void;
};

const FallbackUserSelection = ({
  surveyCreatorId,
  approvalFlow,
  readerOrContributorUsers,
  adminOrWriterUsers,
  errorMessage,
  setSurveyAttributes,
}: FallbackUserSelectionProps) => {
  const fallbackUserAudience = getFallbackUserAudience(approvalFlow);
  return (
    <CollapsibleIsland
      title="Select backup approver"
      subtitle="These users will be notified to review survey responses if the main approvers can’t be contacted"
      isDefaultExpanded={Boolean(errorMessage)}
      errorMessage={errorMessage ? 'Error' : undefined}
    >
      <FieldsWrapper>
        <SearchableUsers
          placeholder="Search and select a user"
          getUsersOptions={getUserAndGroupOptions(
            [],
            [...readerOrContributorUsers.map(user => user._id)],
            false
          )}
          onSelect={option => {
            setSurveyAttributes({
              approvalFlow: {
                ...approvalFlow,
                fallbackAudience: updateFallbackAudience(fallbackUserAudience, [
                  ...validateFallbackUsers(
                    fallbackUserAudience.audiencesIds,
                    adminOrWriterUsers
                  ),
                  option.value,
                ]),
              },
            });
          }}
          showSelectedValueInInputField={false}
        />
      </FieldsWrapper>
      <FallbackUsersAudienceDisplay
        title="Backup approver"
        audience={fallbackUserAudience}
        adminOrWriterUsers={adminOrWriterUsers}
        surveyCreatorId={surveyCreatorId}
        unselectFallbackUser={userId => {
          setSurveyAttributes({
            approvalFlow: {
              ...approvalFlow,
              fallbackAudience: updateFallbackAudience(
                fallbackUserAudience,
                fallbackUserAudience.audiencesIds.filter(id => id !== userId)
              ),
            },
          });
        }}
      />
      {errorMessage && <ErrorNotification>{errorMessage}</ErrorNotification>}
    </CollapsibleIsland>
  );
};

export default connect(
  FallbackUserSelection,
  derivedStream(
    'fallBackUserSelectionStream$',
    orgUsers$,
    permissionGroup$
  ).pipe(
    map(([{ users }, { groupsById }]) => ({
      adminOrWriterUsers: users.filter(isAdminOrWriter),
      readerOrContributorUsers: users.filter(isReaderOrContributor),
      groupsById,
    }))
  )
);
