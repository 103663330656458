import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import {
  selectResourceField,
  selectResourceFields,
  unselectResourceField,
  unselectResourceFields,
} from 'integrations/unified/streams/resources/actions';
import { getResourcesStream } from 'integrations/unified/streams/resources/resources$';
import {
  Field,
  Query,
  Resource,
} from 'integrations/unified/streams/resources/types';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import fp from 'lodash/fp';
import { dispatchAction } from '@ardoq/rxbeach';
import { DefaultQueryFilterButton } from '@ardoq/integrations';
import { combineLatest, filter, map, of, switchMap } from 'rxjs';
import { SelectorField } from 'integrations/common/types/common';
import { startQueryModal } from './QueryModal';
import { QueryDropdownButton } from './QueryDropdownButton';

const formatFields = (fields: Field[]): SelectorField[] => {
  return fields.map(field => ({
    name: field.id,
    label: field.displayText,
  }));
};

const isQueryFilterApplied = (
  queryFilter: Query | null | undefined
): boolean => {
  return (
    // MultiSelectQuery
    // StringQuery
    (queryFilter &&
      typeof queryFilter === 'object' &&
      !fp.isEmpty(queryFilter)) || // ObjectQuery
    (typeof queryFilter === 'string' && queryFilter.length > 0) ||
    (Array.isArray(queryFilter) && queryFilter.length > 0)
  );
};

const getQueryButton = (
  supportsQuery: boolean,
  currentResource: Resource | null,
  currentQuery?: Query | null,
  queryType?: Resource['queryDefinition']['type']
) => {
  if (supportsQuery) {
    const isDisabled = !currentResource;
    const isActive = isQueryFilterApplied(currentQuery);
    const commonProps = { isDisabled, isActive };

    switch (queryType) {
      case 'multi-select':
        return <QueryDropdownButton {...commonProps} options={[]} />;
      default:
        return (
          <DefaultQueryFilterButton
            {...commonProps}
            onClick={startQueryModal}
          />
        );
    }
  }
};

export const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId =>
    combineLatest({
      integrationId: of(integrationId),
      resources: getResourcesStream(integrationId),
      connections: getConnectionsStream(integrationId),
    })
  ),
  map(({ integrationId, resources, connections }) => {
    const availableResources = resources.resources;
    const focusedResource = resources.focusedResource;
    const currentResource = focusedResource
      ? availableResources[focusedResource]
      : null;
    const currentQuery =
      focusedResource && resources.selectedResources[focusedResource]?.query;
    const currentResourceSupportsQuery = Boolean(
      currentResource?.queryDefinition
    );
    const queryType = currentResource?.queryDefinition?.type;

    const base = {
      currentTable: currentResource,
      selectedFields: [],
      availableFields: connections.selectedConnectionIds.length ? [] : null,
      isLoading: false,
      queryFilter: null,
      onClickRemove: () => {},
      onClickAdd: () => {},
      onClickAddAll: () => {},
      onClickRemoveAll: () => {},
      supportsQuery: currentResourceSupportsQuery,
      queryType,
      queryButton: getQueryButton(
        currentResourceSupportsQuery,
        currentResource,
        currentQuery,
        queryType
      ),
    };

    if (focusedResource && currentResource) {
      const [selectedFields, availableFields] = fp.partition(
        (item: SelectorField) =>
          Object.keys(
            resources.selectedResources[focusedResource]?.fields || {}
          ).includes(item.name),
        formatFields(currentResource.fields.definitions)
      );

      return {
        ...base,
        selectedFields,
        availableFields,
        queryFilter: currentQuery,
        isLoading:
          resources.requests.getResource[focusedResource]?.status === 'LOADING',
        onClickRemoveAll: () => {
          dispatchAction(
            unselectResourceFields({
              integrationId,
              resourceId: focusedResource,
            })
          );
        },
        onClickAddAll: () => {
          dispatchAction(
            selectResourceFields({
              integrationId,
              resourceId: focusedResource,
              ids: availableFields?.map(({ name }) => name) || [],
            })
          );
        },
        onClickAdd: (field: SelectorField) => {
          dispatchAction(
            selectResourceField({
              integrationId,
              resourceId: focusedResource,
              id: field.name,
            })
          );
        },
        onClickRemove: (field: SelectorField) => {
          dispatchAction(
            unselectResourceField({
              integrationId,
              resourceId: focusedResource,
              id: field.name,
            })
          );
        },
      };
    }

    return base;
  })
);
