import {
  DataSource,
  DataSourceItem,
  EntityMergeViewModel,
} from 'components/EntityMerge/types';
import {
  ExtractPayload,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import {
  APIEntityType,
  ArdoqId,
  AllSupportedEntityTypes,
} from '@ardoq/api-types';
import {
  setEntityMergeData,
  setEntityMergeIsLoading,
  setEntityMergeState,
} from 'components/EntityMerge/actions';
import { logError } from '@ardoq/logging';
import { options } from 'scope/merge/routines';
import getEntitiesData from './getEntitiesData';

const handleSetMergeData = (
  state: EntityMergeViewModel,
  {
    entityType,
    entityIds,
    ...restPayload
  }: ExtractPayload<typeof setEntityMergeData>
): EntityMergeViewModel => ({
  ...state,
  entityType,
  ...getEntitiesData(entityIds, entityType),
  isLoading: false,
  ...restPayload,
});

const updateChildren = (
  dataSourceItem: DataSourceItem,
  dataSource: DataSource,
  entityId: ArdoqId,
  entityIdsWithInvalidParent: Set<ArdoqId>
) =>
  dataSourceItem.children?.forEach(childIndex => {
    const child = dataSource[childIndex];
    if (
      !(
        child.fieldName === 'parent' && entityIdsWithInvalidParent.has(entityId)
      )
    ) {
      Object.assign(dataSource, {
        [childIndex]: { ...child, status: entityId },
      });
    }
    updateChildren(child, dataSource, entityId, entityIdsWithInvalidParent);
  });

const updateParent = (
  dataSourceItem: DataSourceItem,
  dataSource: DataSource,
  entityId: ArdoqId
) => {
  if (dataSourceItem.parent === null) return;
  const parent = dataSource[dataSourceItem.parent];
  if (
    parent.children!.every(
      siblingIndex => dataSource[siblingIndex].status === entityId
    )
  ) {
    Object.assign(dataSource, {
      [parent.index]: { ...parent, status: entityId },
    });
  } else if (parent.status) {
    Object.assign(dataSource, {
      [parent.index]: { ...parent, status: null },
    });
  }

  updateParent(parent, dataSource, entityId);
};

const getNewDataSource = (
  dataSource: DataSource,
  index: number,
  entityId: ArdoqId,
  entityIdsWithInvalidParent: Set<ArdoqId>
) => {
  const rowSource = dataSource[index];
  if (!rowSource) {
    logError(Error('index out of range in handleSetEntityMergeState'));
    return dataSource;
  }

  const newDataSource = Object.assign([], dataSource, {
    [index]: { ...dataSource[index], status: entityId },
  });

  updateChildren(
    rowSource,
    newDataSource,
    entityId,
    entityIdsWithInvalidParent
  );
  updateParent(rowSource, newDataSource, entityId);
  return newDataSource;
};

const handleSetMergeState = (
  state: EntityMergeViewModel,
  payload: ExtractPayload<typeof setEntityMergeState>
) => ({
  ...state,
  dataSource: getNewDataSource(
    state.dataSource,
    payload.index,
    payload.id,
    state.entitiesWithInvalidParent
  ),
});

const handleSetIsLoading = (
  state: EntityMergeViewModel,
  isLoading: boolean
) => ({ ...state, isLoading });

const defaultState = {
  entities: [],
  dataSource: [],
  entityType: APIEntityType.COMPONENT as AllSupportedEntityTypes,
  enhancedScopeData: null,
  graphics: null,
  users: null,
  options,
  isLoading: true,
  entitiesWithInvalidParent: new Set<ArdoqId>(),
};

const entityMergeTable$ = persistentReducedStream<EntityMergeViewModel>(
  'entityMergeTable$',
  defaultState,
  [
    reducer(setEntityMergeIsLoading, handleSetIsLoading),
    reducer(setEntityMergeData, handleSetMergeData),
    reducer(setEntityMergeState, handleSetMergeState),
  ]
);

export default entityMergeTable$;
