import { actionCreator } from '@ardoq/rxbeach';
import { DictionaryCategoriesState } from './types';

const NAMESPACE = '[integrations] SIGNAVIO_EXPORTER_DICTIONARY_CATEGORTY';

export const updateDictionaryCategories =
  actionCreator<DictionaryCategoriesState>(
    `${NAMESPACE}_UPDATE_DICTIONARY_CATEGORY`
  );

// boolean -> force fetch
export const fetchDictionaryResource = actionCreator<boolean | void>(
  `${NAMESPACE}_FETCH_DICTIONARY_RESOURCE`
);
export type UpdateDictionaryPropsPayload = {
  category: string;
  props: Partial<DictionaryCategoriesState['categories'][string]>;
};

export const updateDictionaryProps =
  actionCreator<UpdateDictionaryPropsPayload>(
    `${NAMESPACE}_UPDATE_DICTIONARY_ATTRIBUTES`
  );

export const addNewAttribute = actionCreator<string>(
  `${NAMESPACE}_ADD_NEW_ATTRIBUTE`
);
