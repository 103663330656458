import { FieldsWrapper, FormWrapper, TextInput } from '@ardoq/forms';
import styled from 'styled-components';
import { ErrorNotification } from '@ardoq/status-ui';
import type {
  CreatePermissionGroupFormState,
  SetFormFieldValuePayload,
} from './types';
import { debounce, first, noop } from 'lodash';
import UsersMultiSelect from './UsersMultiSelect';
import { PermissionGroupPageEvents } from '../../types';
import { useMemo } from 'react';

const HiddenButton = styled.button`
  display: none;
`;

type CreatePermissionGroupFormProps = {
  onSubmit: () => void;
  onChange: (payload: SetFormFieldValuePayload) => void;
  apiErrorMessage: string | null;
  formValues: CreatePermissionGroupFormState;
  formErrors: {
    [FieldName in keyof CreatePermissionGroupFormState]: string[];
  };
  displayFieldErrors: boolean;
  trackUserEvent?: <T extends object>(
    event: PermissionGroupPageEvents,
    meta?: T
  ) => void;
};

const CreatePermissionGroupForm = ({
  onSubmit,
  apiErrorMessage,
  onChange,
  formValues,
  formErrors,
  displayFieldErrors,
  trackUserEvent = noop,
}: CreatePermissionGroupFormProps) => {
  const debouncedTracking = useMemo(
    () => debounce(trackUserEvent, 3000, { leading: true, trailing: false }),
    [trackUserEvent]
  );
  return (
    <FormWrapper>
      <form
        onSubmit={event => {
          event.preventDefault();
          onSubmit();
        }}
      >
        <FieldsWrapper>
          <TextInput
            value={formValues.name}
            onValueChange={newValue => {
              debouncedTracking(
                PermissionGroupPageEvents.ENTERED_NEW_GROUP_NAME
              );
              onChange({ field: 'name', value: newValue });
            }}
            label="Name"
            placeholder="Untitled"
            errorMessage={
              displayFieldErrors ? first(formErrors.name) : undefined
            }
            autoFocus
          />
          <UsersMultiSelect
            value={formValues.users}
            onValueChange={users => {
              trackUserEvent(
                PermissionGroupPageEvents.SELECTED_USERS_DURING_CREATION,
                {
                  selectionCount: users.length,
                }
              );
              onChange({ field: 'users', value: users });
            }}
            label="Members"
            isOptional
            placeholder="Type and select users"
            errorMessage={
              displayFieldErrors ? first(formErrors.users) : undefined
            }
          />
          {apiErrorMessage && (
            <ErrorNotification>{apiErrorMessage}</ErrorNotification>
          )}
        </FieldsWrapper>
        {/* This submit button allows the form to be submitted by pressing enter inside the inputs */}
        <HiddenButton type="submit" />
      </form>
    </FormWrapper>
  );
};

export default CreatePermissionGroupForm;
