import {
  AudiencePreview,
  AudiencePreviewRow,
  BroadcastAudienceType,
} from '@ardoq/api-types';
import { AudienceTagsStreamShape } from './types';
import { AudiencePreviewStatus } from 'broadcasts/broadcastBuilder/audiencePreview/types';
import {
  getWarningMessageFromAudiencePreview,
  hasSourceOfType,
} from 'broadcasts/utils';

export const getDefaultState = (): AudienceTagsStreamShape => ({
  audienceCountByType: {
    [BroadcastAudienceType.EMAIL]: null,
    [BroadcastAudienceType.GREMLIN]: null,
    [BroadcastAudienceType.PREDEFINED]: null,
    [BroadcastAudienceType.WORKSPACE]: null,
  },
  audienceWarningByType: {
    [BroadcastAudienceType.EMAIL]: null,
    [BroadcastAudienceType.GREMLIN]: null,
    [BroadcastAudienceType.PREDEFINED]: null,
    [BroadcastAudienceType.WORKSPACE]: null,
  },
  status: AudiencePreviewStatus.IDLE,
});

const audiencePreviewRowIsOfType =
  (audienceType: BroadcastAudienceType) =>
  ({ sources }: AudiencePreviewRow) =>
    sources.includes(audienceType);

const getAudienceCount = (
  audiencePreview: AudiencePreview,
  audienceType: BroadcastAudienceType
) => audiencePreview.filter(audiencePreviewRowIsOfType(audienceType)).length;

const getAudienceCountByTypeEntry =
  (audiencePreview: AudiencePreview) =>
  (audienceType: BroadcastAudienceType) => [
    audienceType,
    getAudienceCount(audiencePreview, audienceType),
  ];

export const toAudienceCountByType = (audiencePreview: AudiencePreview) => {
  const audienceCountByTypeEntries = [
    BroadcastAudienceType.EMAIL,
    BroadcastAudienceType.GREMLIN,
    BroadcastAudienceType.PREDEFINED,
    BroadcastAudienceType.WORKSPACE,
  ].map(getAudienceCountByTypeEntry(audiencePreview));
  return Object.fromEntries(audienceCountByTypeEntries);
};

const getAudienceWarningByTypeEntry =
  (audiencePreview: AudiencePreview) =>
  (audienceType: BroadcastAudienceType) => [
    audienceType,
    getWarningMessageFromAudiencePreview(
      audiencePreview.filter(hasSourceOfType(audienceType))
    ),
  ];

export const toAudienceWarningByType = (audiencePreview: AudiencePreview) => {
  const audienceWarningByTypeEntries = [
    BroadcastAudienceType.EMAIL,
    BroadcastAudienceType.GREMLIN,
    BroadcastAudienceType.PREDEFINED,
    BroadcastAudienceType.WORKSPACE,
  ].map(getAudienceWarningByTypeEntry(audiencePreview));
  return Object.fromEntries(audienceWarningByTypeEntries);
};
