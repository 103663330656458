import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const setPresentationInListMode = actionCreator<boolean>(
  '[presentations 2024] SET_PRESENTATION_IN_LIST_MODE'
);

export const previewPresentationInEditor = actionCreator<{
  presentationId: ArdoqId | 'NEW';
}>('[presentations 2024] PREVIEW_PRESENTATION_IN_EDITOR');

export const openPresentationBuilder = actionCreator(
  '[presentations 2024] OPEN_PRESENTATION_BUILDER'
);

export const closePresentation = actionCreator(
  '[presentations 2024] CLOSE_PRESENTATION'
);
