import {
  ExtractPayload,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import { MergeState, StepResolution } from './types';
import { MergeDirection } from './MergeDirection';
import {
  initMerge,
  setMergeStateDiff,
  setStep,
  setSubmittedStep,
} from './actions';
import { Verb } from '@ardoq/api-types';
import { toggleIsExpandMainStep } from 'components/DiffMergeTable/actions';
import { without } from 'lodash';
import { MergeStepLabel } from './typesMergeStepLabel';
import { updatedDisabledState } from './utils';
import { getIsStepSubmitted } from 'components/DiffMergeSidebarNavigator/utils';
import { PayloadToggleIsExpandedMainStep } from 'components/DiffMergeTable/types';

const defaultState: MergeState = {
  mergeDirection: MergeDirection.MAINLINE_TO_BRANCH,
  diffMetaStates: null,
  mainStateStep: null,
  subStateStep: null,
  expandedMainSteps: [],
  submittedSteps: {} as Record<MergeStepLabel, Set<Verb>>,
};

const handleInitMerge = (_: MergeState, mergeDirection: MergeDirection) => ({
  ...defaultState,
  mergeDirection,
});

const handleSetSubmittedStep = (
  state: MergeState,
  { submittedStep }: ExtractPayload<typeof setSubmittedStep>
) => ({
  ...state,
  submittedSteps: buildSubmittedSteps(state.submittedSteps, submittedStep),
});

const buildSubmittedSteps = (
  submittedSteps: Record<MergeStepLabel, Set<Verb>>,
  {
    mainStateStep,
    subStateStep,
  }: { mainStateStep: MergeStepLabel; subStateStep: Verb }
) => ({
  ...submittedSteps,
  [mainStateStep]: new Set([
    ...(submittedSteps[mainStateStep] || []),
    subStateStep,
  ]),
});

const handleSetStep = (
  state: MergeState,
  { mainStateStep, subStateStep }: ExtractPayload<typeof setStep>
): MergeState => {
  const expandedMainSteps = [
    ...state.expandedMainSteps.filter(
      expandedMainStep =>
        expandedMainStep !== mainStateStep &&
        Object.entries(state.diffMetaStates?.[expandedMainStep] ?? {}).some(
          ([subStateName, subStep]) =>
            subStep?.state !== StepResolution.COMPLETE &&
            !getIsStepSubmitted(
              expandedMainStep,
              subStateName as Verb,
              state.submittedSteps
            )
        )
    ),
    mainStateStep,
  ];
  return {
    ...state,
    expandedMainSteps,
    mainStateStep,
    subStateStep,
  };
};

const handleSetMergeStateDiff = (state: MergeState, diffMetaStates: any) =>
  updatedDisabledState({
    ...state,
    diffMetaStates,
  });

const handleToggleIsExpandedMainStep = (
  { expandedMainSteps, ...state }: MergeState,
  { mainStep }: PayloadToggleIsExpandedMainStep
) => ({
  ...state,
  expandedMainSteps: expandedMainSteps.includes(mainStep)
    ? without(expandedMainSteps, mainStep)
    : [...expandedMainSteps, mainStep],
});

const reducers = [
  reducer(initMerge, handleInitMerge),
  reducer(setSubmittedStep, handleSetSubmittedStep),
  reducer(setStep, handleSetStep),
  reducer(setMergeStateDiff, handleSetMergeStateDiff),
  reducer(toggleIsExpandMainStep, handleToggleIsExpandedMainStep),
];

const mergeState$ = persistentReducedStream(
  'mergeState$',
  defaultState,
  reducers
);

export default mergeState$;
