import { reducer } from '@ardoq/rxbeach';
import { PerspectivesEditorState } from '../types';
import { setIsUpdatingAppState } from '../../../isUpdatingAppState$';
import { getAppliedFormattingState } from './getAppliedFormattingState';

const editorStateToAppliedRules = (
  state: PerspectivesEditorState,
  isUpdatingAppState: boolean
) => {
  // presentation slide selected and updating app state started
  if (isUpdatingAppState) {
    return {
      ...state,
      shouldResetEditorStateToAppliedRules: true,
    };
  }

  const {
    shouldResetEditorStateToAppliedRules,
    formattingTab: { formattingOptions },
    groupsTab: { groupOptions },
    ...rest
  } = state;

  // updating app state finished
  return {
    ...rest,
    formattingTab: shouldResetEditorStateToAppliedRules
      ? {
          ...state.formattingTab,
          ...getAppliedFormattingState(formattingOptions),
        }
      : state.formattingTab,
    groupsTab: shouldResetEditorStateToAppliedRules
      ? {
          ...state.groupsTab,
          groupingRules: groupOptions.appliedGroupingRules,
        }
      : state.groupsTab,
  };
};

export const resetEditorStateToAppliedRulesReducer = reducer<
  PerspectivesEditorState,
  boolean
>(setIsUpdatingAppState, editorStateToAppliedRules);
