import { CSSProperties } from 'react';
import styled from 'styled-components';
import ContextBarComponent from './ContextBarComponent';
import { ContextGraphItem, ContextGraphItemType } from './types';
import { action$, connect, ofType } from '@ardoq/rxbeach';
import { notifyComponentsUpdated } from 'streams/components/ComponentActions';
import { notifyReferencesUpdated } from 'streams/references/ReferenceActions';
import { context$ } from 'streams/context/context$';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import { ensureContrast } from '@ardoq/color-helpers';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { ArdoqId } from '@ardoq/api-types';
import { EMPTY_CONTEXT_SHAPE } from 'streams/context/ContextShape';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';

const Container = styled.div`
  @media print {
    display: none !important;
  }
  border-top: 1px solid ${colors.grey80};
  padding: ${s8};
  display: flex;
  width: 100%;
  position: absolute;

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    &:not(.separator) li {
      display: flex;
      align-items: center;
      max-width: 200px;
      line-height: 30px;

      &:not(:first-child) {
        &:before {
          cursor: auto;
          content: '▸';
          color: ${colors.grey60};
          margin: 0 ${s8};
        }
      }
    }
  }
  ul.separator {
    margin: 0 ${s16};
    align-items: center;
    .reference-separator .fa {
      font-weight: bold;
      font-size: 18px;
      top: 2px;
      position: relative;
    }
  }
`;

const buildComponentGraph = (
  workspaceId: ArdoqId | null,
  componentId: ArdoqId | null
) => {
  const contextGraph: ContextGraphItem[] = [];
  if (workspaceId) {
    contextGraph.push({
      id: workspaceId,
      name: workspaceInterface.getWorkspaceName(workspaceId) ?? '',
      fill: colors.grey25,
      type: ContextGraphItemType.WORKSPACE,
    });
  }

  if (componentId) {
    componentInterface
      .getComponentAncestryIds(componentId)
      .forEach(currentComponentId => {
        const componentColors = getComponentCssColors(currentComponentId, {
          useAsBackgroundStyle: false,
        });

        const fill = componentColors?.fill
          ? ensureContrast(colors.grey95, componentColors.fill)
          : 'black';
        contextGraph.push({
          id: currentComponentId,
          name: componentInterface.getDisplayName(currentComponentId) ?? '',
          fill,
          type: ContextGraphItemType.COMPONENT,
        });
      });
  }
  return contextGraph;
};

interface ContextBarProps {
  contextGraph: ContextGraphItem[][];
  style: CSSProperties;
}

const ContextBar = ({ contextGraph, style }: ContextBarProps) => (
  <Container style={style} data-context-menu={OBJECT_CONTEXT_MENU_NAME}>
    <ContextBarComponent contextGraph={contextGraph} />
  </Container>
);

export default connect(
  ContextBar,
  combineLatest([
    context$,
    action$.pipe(
      ofType(notifyComponentsUpdated, notifyReferencesUpdated),
      startWith(undefined)
    ),
  ]).pipe(
    map(([context]) => context),
    startWith(EMPTY_CONTEXT_SHAPE),
    map(({ referenceId, componentId, workspaceId }) => {
      if (referenceId && referenceInterface.isReference(referenceId)) {
        const sourceComponentId =
          referenceInterface.getSourceComponentId(referenceId);
        const targetComponentId =
          referenceInterface.getTargetComponentId(referenceId);
        const [sourceWorkspaceId, targetWorkspaceId] = [
          sourceComponentId,
          targetComponentId,
        ].map(componentId =>
          componentId ? componentInterface.getWorkspaceId(componentId) : null
        );
        return [
          buildComponentGraph(sourceWorkspaceId, sourceComponentId),
          buildComponentGraph(targetWorkspaceId, targetComponentId),
        ];
      }
      return [buildComponentGraph(workspaceId, componentId)];
    }),
    map(contextGraph => ({ contextGraph }))
  )
);
