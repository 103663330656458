import {
  getDeleteTagMenuItem,
  getDropdownDivider,
  getEditTagMenuItem,
  getFilterTagMenuItem,
} from './menuItems';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { TrackedContextMenus, getTrackingFunction } from './tracking';
import {
  ContextMenuData,
  GetContextMenuOptionsArguments,
} from '@ardoq/context-menu';
import { ArdoqId } from '@ardoq/api-types';
import { ContextMenuTestIds } from './consts';

export const getTagMenu = ({
  tagId,
  target,
}: {
  tagId: ArdoqId;
} & GetContextMenuOptionsArguments): ContextMenuData => {
  const menuItemTagTrackingFunction = getTrackingFunction(
    target,
    TrackedContextMenus.TAG_CONTEXT_MENU
  );

  return {
    options: [
      getEditTagMenuItem(tagId, menuItemTagTrackingFunction),
      getDeleteTagMenuItem(tagId, menuItemTagTrackingFunction),
      getDropdownDivider(),
      getFilterTagMenuItem(tagId, menuItemTagTrackingFunction),
    ].filter(ExcludeFalsy),
    testId: ContextMenuTestIds.TAG,
  };
};
