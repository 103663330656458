import { ArdoqId } from '@ardoq/api-types';
import { EnhancedScopeData } from '@ardoq/data-model';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  setComponentOverviewData,
  setComponentOverviewId,
  setComponentOverviewPageError,
} from './actions';
import { componentOverviewPageOperations } from './componentOverviewPageOperations';

export type ComponentOverviewPageState = {
  scopeData: EnhancedScopeData | null;
  componentId: ArdoqId | null;
  hasError: boolean;
};

const defaultState: ComponentOverviewPageState = {
  scopeData: null,
  componentId: null,
  hasError: false,
};

export const componentOverviewPage$ =
  persistentReducedStream<ComponentOverviewPageState>(
    'componentOverviewPage$',
    defaultState,
    [
      reducer(
        setComponentOverviewData,
        componentOverviewPageOperations.setComponentOverviewData
      ),
      reducer(
        setComponentOverviewId,
        componentOverviewPageOperations.setComponentOverviewId
      ),
      reducer(
        setComponentOverviewPageError,
        componentOverviewPageOperations.setComponentOverviewPageError
      ),
    ]
  );
