import {
  APIInferTraversalRequest,
  APISuggestTraversalRequest,
} from '@ardoq/api';
import {
  ClientRequestIdAndStartSet,
  InferredTraversalEvent,
  InferredTraversalWarningEvent,
  OpenViewInferenceWindowPayload,
  SuggestTraversalEvent,
  TraversalSuggestion,
  ViewInferenceState,
} from './types';
import {
  ArdoqEvent,
  ClientRequest,
  isWebSocketInferred,
  isWebSocketSuggest,
  isWebSocketWarning,
  MessageEvent,
  UserEvent,
} from 'sync/types';
import { ardoqEventOperations } from 'sync/ardoqEventOperations';
import {
  APIParentChildReferenceAttributes,
  APIReferenceAttributes,
  LoadedState,
  ResourceType,
} from '@ardoq/api-types';
import { uniq } from 'lodash';
import { ExecuteViewpointPayload } from 'viewpointBuilder/actions';

const VIEW_INFERENCE_WINDOW_MAX_HEIGHT_IN_PX = 585; // this is the max height of the view inference window if 3 suggestions have loaded

const handleOpenWindow = (
  state: ViewInferenceState,
  { componentIds, initialPosition: { x, y } }: OpenViewInferenceWindowPayload
): ViewInferenceState => {
  const initialYPosition = Math.min(
    window.innerHeight - VIEW_INFERENCE_WINDOW_MAX_HEIGHT_IN_PX,
    y
  );
  return {
    ...state,
    isOpen: true,
    componentIds: componentIds,
    input: '',
    sendPrompt: {
      status: 'READY',
    },
    initialPosition: new DOMPoint(x, initialYPosition),
  };
};

const handleCloseWindow = (state: ViewInferenceState): ViewInferenceState => ({
  ...state,
  isOpen: false,
  sendPrompt: {
    status: 'READY',
  },
  suggest: {
    status: 'IDLE',
  },
});

const setInput = (
  state: ViewInferenceState,
  value: string
): ViewInferenceState => ({
  ...state,
  input: value,
});

const handleSendViewInference = (
  state: ViewInferenceState,
  payload: APIInferTraversalRequest
): ViewInferenceState => ({
  ...state,
  sendPrompt: {
    status: 'LOADING',
    clientRequestId: payload.clientRequestId,
  },
});

const handleViewInferenceSuccess = (
  state: ViewInferenceState
): ViewInferenceState => ({
  ...state,
  isOpen: false,
  input: '',
  sendPrompt: {
    status: 'READY',
  },
});

const handleViewInferenceError = (
  state: ViewInferenceState,
  error: string
): ViewInferenceState => ({
  ...state,
  sendPrompt: {
    status: 'ERROR',
    error: error,
  },
});

const handleViewInferenceWarning = (
  state: ViewInferenceState,
  warning: string
): ViewInferenceState => ({
  ...state,
  sendPrompt: {
    status: 'WARNING',
    warning: warning,
  },
});

const setSendPromptWarning = (
  state: ViewInferenceState,
  event: UserEvent<ClientRequest<InferredTraversalWarningEvent>>
): ViewInferenceState => {
  if (
    viewInferenceOperations.isActiveSendPromptRequest(
      state,
      ardoqEventOperations.getClientRequestId(event)
    )
  ) {
    return {
      ...state,
      sendPrompt: {
        status: 'WARNING',
        warning: event.data.message,
      },
    };
  }

  return { ...state };
};

const handleClientRequestErrorEvent = (
  state: ViewInferenceState,
  event: UserEvent<ClientRequest<MessageEvent>>
): ViewInferenceState => {
  if (
    isActiveSendPromptRequest(
      state,
      ardoqEventOperations.getClientRequestId(event)
    )
  ) {
    return {
      ...state,
      sendPrompt: {
        status: 'ERROR',
        error: event.data.message,
      },
    };
  }
  if (
    isActiveSuggestRequest(
      state,
      ardoqEventOperations.getClientRequestId(event)
    )
  ) {
    return {
      ...state,
      suggest: {
        status: 'ERROR',
        error: event.data.message,
      },
    };
  }
  return state;
};

const handleSendSuggestTraversal = (
  state: ViewInferenceState,
  payload: ClientRequestIdAndStartSet
): ViewInferenceState => ({
  ...state,
  suggest: {
    status: 'LOADING',
    clientRequestId: payload.clientRequestId,
  },
});

const handleSuggestTraversalSuccess = (
  state: ViewInferenceState,
  suggestions: TraversalSuggestion[]
): ViewInferenceState => ({
  ...state,
  suggest: {
    status: 'DONE',
    suggestions: suggestions,
  },
});

const setSuggestWarning = (
  state: ViewInferenceState,
  warning: string
): ViewInferenceState => ({
  ...state,
  suggest: {
    status: 'WARNING',
    warning: warning,
  },
});

const handleSuggestTraversalError = (
  state: ViewInferenceState,
  error: string
): ViewInferenceState => ({
  ...state,
  suggest: {
    status: 'ERROR',
    error: error,
  },
});

const handleApplySuggestion = (
  state: ViewInferenceState
): ViewInferenceState => ({
  ...state,
  isOpen: false,
});

const validateInput = (value: string) => {
  if (!value) return false;

  const valueWithoutNewLines = value.replaceAll('\n', '');
  if (!valueWithoutNewLines) return false;

  return true;
};

const getInferWarning = (result: ExecuteViewpointPayload) => {
  const pathsIsEmpty = result.paths.length === 0;
  if (pathsIsEmpty)
    return 'No relationships found based on your prompt. Please try again.';
  return null;
};

const getSuggestWarning = (suggestions: TraversalSuggestion[]) => {
  if (suggestions.length === 0)
    return 'No results. Please try another input or select one of the suggestions.';
  return null;
};

const isInferredTraversalEvent = (
  event: ArdoqEvent<unknown>
): event is UserEvent<ClientRequest<InferredTraversalEvent>> =>
  isWebSocketInferred(event) &&
  ardoqEventOperations.isOfResourceType(event, ResourceType.TRAVERSAL);

const isInferredTraversalWarningEvent = (
  event: ArdoqEvent<unknown>
): event is UserEvent<ClientRequest<InferredTraversalWarningEvent>> =>
  isWebSocketWarning(event) &&
  ardoqEventOperations.isOfResourceType(event, ResourceType.TRAVERSAL);

const isSuggestTraversalEvent = (
  event: ArdoqEvent<unknown>
): event is UserEvent<ClientRequest<SuggestTraversalEvent>> =>
  isWebSocketSuggest(event) &&
  ardoqEventOperations.isOfResourceType(event, ResourceType.TRAVERSAL);

const isActiveSendPromptRequest = (
  state: ViewInferenceState,
  clientRequestId: string | undefined
) =>
  state.sendPrompt.status === 'LOADING' &&
  state.sendPrompt.clientRequestId === clientRequestId;

const isActiveSuggestRequest = (
  state: ViewInferenceState,
  clientRequestId: string | undefined
) =>
  state.suggest.status === 'LOADING' &&
  state.suggest.clientRequestId === clientRequestId;

const isVisibleLoadedStateWithComponentsAndReferences = (
  loadedState: LoadedState
): loadedState is Required<LoadedState> =>
  !loadedState.isHidden && Boolean(loadedState.componentIdsAndReferences);

const getReferenceIdsFromLoadedState = (
  loadedStates: LoadedState[]
): Pick<
  APISuggestTraversalRequest,
  'referenceIds' | 'parentChildReferenceIds'
> => {
  const { references, parentChildReferences } = loadedStates
    .filter(isVisibleLoadedStateWithComponentsAndReferences)
    .reduce<{
      references: APIReferenceAttributes[];
      parentChildReferences: APIParentChildReferenceAttributes[];
    }>(
      (acc, { componentIdsAndReferences }) => ({
        references: [
          ...componentIdsAndReferences.references,
          ...acc.references,
        ],
        parentChildReferences: [
          ...componentIdsAndReferences.parentChildReferences,
          ...acc.parentChildReferences,
        ],
      }),
      {
        references: [],
        parentChildReferences: [],
      }
    );

  return {
    referenceIds: uniq(references.map(({ _id }) => _id)),
    parentChildReferenceIds: uniq(parentChildReferences.map(({ _id }) => _id)),
  };
};

export const viewInferenceOperations = {
  handleOpenWindow,
  handleCloseWindow,
  setInput,
  handleSendViewInference,
  handleViewInferenceSuccess,
  handleViewInferenceError,
  handleViewInferenceWarning,
  handleViewInferenceWarningMessage: setSendPromptWarning,
  handleClientRequestErrorEvent,
  handleSendSuggestTraversal,
  handleSuggestTraversalSuccess,
  setSuggestWarning,
  handleSuggestTraversalError,
  handleApplySuggestion,
  validateInput,
  getInferWarning,
  getSuggestWarning,
  isInferredTraversalEvent,
  isInferredTraversalWarningEvent,
  isSuggestTraversalEvent,
  isActiveSendPromptRequest,
  isActiveSuggestRequest,
  getReferenceIdsFromLoadedState,
};
