import {
  ContainerCreatedPayload,
  ShowFilteredComponentsChangedPayload,
  TreeChangedPayload,
} from '../actions/navigatorActions';
import layout from '../models/layout';
import { setClassNames } from '../utils/utils';
import type { NavigatorLayoutState } from '../types';

const containerCreated = (
  state: NavigatorLayoutState,
  { container }: ContainerCreatedPayload
): NavigatorLayoutState => {
  return {
    ...state,
    container,
  };
};

const containerHeightChanged = (
  state: NavigatorLayoutState
): NavigatorLayoutState => {
  if (!state.container || state.container.offsetHeight === state.height) {
    return state;
  }

  const height = state.container.offsetHeight;
  const scrollTop = state.container.scrollTop;
  const { dragTargetNode, linkSourceNode, existingRefs } = state;
  return {
    ...state,
    height,
    scrollTop,
    rootLayoutBox: layout(
      state.tree,
      height,
      scrollTop,
      state.layoutNodeSet,
      setClassNames({ dragTargetNode, linkSourceNode, existingRefs }),
      state.showFilteredComponents
    ),
  };
};

const resetScroll = (state: NavigatorLayoutState): NavigatorLayoutState =>
  changeScrollTop(state, 0);

const containerScrolled = (
  state: NavigatorLayoutState
): NavigatorLayoutState => {
  if (!state.container) {
    return state;
  }
  const scrollTop = state.container.scrollTop;
  return changeScrollTop(state, scrollTop);
};

const changeScrollTop = (state: NavigatorLayoutState, scrollTop: number) => {
  const {
    dragTargetNode,
    linkSourceNode,
    existingRefs,
    shouldShowRefDrawing,
    linkSourceIds,
    hasLinkTarget,
  } = state;
  const multiselect = linkSourceIds ? linkSourceIds.length > 1 : false;
  return {
    ...state,
    scrollTop,
    rootLayoutBox: layout(
      state.tree,
      state.height,
      scrollTop,
      state.layoutNodeSet,
      setClassNames({
        dragTargetNode,
        linkSourceNode,
        existingRefs,
        shouldShowRefDrawing,
        hasLinkTarget,
        multiselect,
      }),
      state.showFilteredComponents
    ),
  };
};

const showFilteredComponentsChanged = (
  state: NavigatorLayoutState,
  payload: ShowFilteredComponentsChangedPayload
): NavigatorLayoutState => {
  const {
    dragTargetNode,
    linkSourceNode,
    existingRefs,
    shouldShowRefDrawing,
    linkSourceIds,
    hasLinkTarget,
  } = state;
  const multiselect = linkSourceIds ? linkSourceIds.length > 1 : false;
  return {
    ...state,
    showFilteredComponents: payload.showFilteredComponents,
    rootLayoutBox: layout(
      state.tree,
      state.height,
      state.scrollTop,
      state.layoutNodeSet,
      setClassNames({
        dragTargetNode,
        linkSourceNode,
        existingRefs,
        shouldShowRefDrawing,
        hasLinkTarget,
        multiselect,
      }),
      payload.showFilteredComponents
    ),
  };
};

const treeChanged = (
  state: NavigatorLayoutState,
  { reloadNodeIds, removedIds }: TreeChangedPayload
): NavigatorLayoutState => {
  const { highlightChangeRequests } = state;
  const { dragTargetNode, linkSourceNode, existingRefs } = state;
  if (removedIds) {
    removedIds.forEach(id => state.tree.removeNode(id));
  }
  (reloadNodeIds ?? []).forEach(reloadNodeId => {
    state.tree.getNode(reloadNodeId)?.reload();
  });
  return {
    ...state,
    highlightChangeRequests,
    rootLayoutBox: layout(
      state.tree,
      state.height,
      state.scrollTop,
      state.layoutNodeSet,
      setClassNames({ dragTargetNode, linkSourceNode, existingRefs }),
      state.showFilteredComponents
    ),
  };
};

const clearTree = (state: NavigatorLayoutState): NavigatorLayoutState => ({
  ...state,
  rootLayoutBox: layout(state.tree, 0, 0),
});

const setIsViewpointMode = (
  state: NavigatorLayoutState,
  { isViewpointMode }: { isViewpointMode: boolean }
): NavigatorLayoutState => ({
  ...state,
  isViewpointMode,
});

const treeChangedNoPayload = (state: NavigatorLayoutState) =>
  treeChanged(state, {});

export const navigatorLayoutOperations = {
  containerCreated,
  containerHeightChanged,
  containerScrolled,
  resetScroll,
  showFilteredComponentsChanged,
  treeChanged,
  clearTree,
  setIsViewpointMode,
  treeChangedNoPayload,
};
