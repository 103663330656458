import { ReactNode, useMemo } from 'react';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import type { Workspace as OrgWorkspace } from '@ardoq/api-types';
import { CreatableSelect, Select } from '@ardoq/select';
import { NewBadge } from '@ardoq/status-ui';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { GroupFieldLayout, RadioGroup, TextInput } from '@ardoq/forms';
import * as fp from 'lodash/fp';
import { ReferenceFormat } from './ReferenceFormat';
import { Field as FieldType } from 'integrations/common/streams/fields/types';
import { DEFAULT_REFERENCE_TYPE } from 'integrations/common/streams/references/constants';
import { getReferenceDirectionName } from 'integrations/common/pages/tabularConfigMapping/utils';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { byLocaleField } from 'integrations/common/utils/common';
import { ReferenceMissingComponentsStrategy } from './ReferenceMissingComponentsStrategy';
import {
  IntegrationReferenceDirection,
  ReferenceMapping,
  IntegrationWorkspace,
} from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import {
  MappedColumnsErrors,
  SidebarSelectorsIds,
} from 'integrations/common/streams/tabularMappingErrors/types';

type ReferenceProps = {
  tableMapping: TableMappingMap;
  selectedColumn: number;
  referenceMapping: ReferenceMapping;
  usedWorkspaces: IntegrationWorkspace[];
  existingWorkspace: OrgWorkspace[];
  usedFields: FieldType[];
  allFields: FieldType[];
  newReferenceTypes: string[];
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
  columnName: string;
  isCreationDisabled: boolean;
  mappedColumnsErrors: MappedColumnsErrors;
};

const REFERENCE_DIRECTIONS: Array<{
  value: IntegrationReferenceDirection;
  label: ReactNode;
}> = [
  IntegrationReferenceDirection.OUTGOING,
  IntegrationReferenceDirection.INCOMING,
].map(direction => ({
  value: direction,
  label: (
    <>
      <Icon
        style={{ color: colors.grey50 }}
        iconSize={IconSize.REGULAR}
        iconName={
          direction === IntegrationReferenceDirection.INCOMING
            ? IconName.ARROW_BACK
            : IconName.ARROW_FORWARD
        }
      />
      {getReferenceDirectionName(direction)}
    </>
  ),
}));

export const Reference = ({
  tableMapping,
  referenceMapping,
  usedWorkspaces,
  existingWorkspace,
  newReferenceTypes,
  onMapping,
  usedFields,
  allFields,
  columnName,
  isCreationDisabled,
  mappedColumnsErrors,
}: ReferenceProps) => {
  const isDirectible = referenceMapping.referenceDirection;
  const isIncomingReference =
    referenceMapping.referenceDirection ===
    IntegrationReferenceDirection.INCOMING;

  const workspace = isIncomingReference
    ? referenceMapping?.targetWorkspace
    : tableMapping.rootWorkspace;

  const workspaceReferenceTypes = workspace?.id
    ? workspaceInterface.getReferenceTypes(workspace.id) || {}
    : {};

  const newWorkspaceReferenceTypes =
    Object.values(workspaceReferenceTypes).length === 0
      ? [DEFAULT_REFERENCE_TYPE, ...newReferenceTypes]
      : newReferenceTypes;

  const usedWorkspaceOptions = usedWorkspaces.map(w => ({
    value: w.id || w.name,
    label: w.name,
  }));

  // getting remaining workspaces substracting whats already used
  const existingWorkspaceOptions = useMemo(
    () =>
      existingWorkspace
        .map(w => ({
          value: w._id,
          label: w.name,
        }))
        .sort(byLocaleField('label')),
    [existingWorkspace]
  );
  const usedWorkspaceOptionsMap = fp.keyBy('value', usedWorkspaceOptions);
  const unusedExistingWorkspaces = fp.filter(
    item => !usedWorkspaceOptionsMap[item.value],
    existingWorkspaceOptions
  );

  const SelectComponent = isCreationDisabled ? Select : CreatableSelect;

  return (
    <>
      {isDirectible && (
        <RadioGroup
          label="Column represents"
          value={
            referenceMapping.referenceDirection ||
            IntegrationReferenceDirection.OUTGOING
          }
          groupFieldLayout={GroupFieldLayout.VERTICAL}
          onChange={value => {
            if (!value) {
              return;
            }
            onMapping({
              ...referenceMapping,
              referenceDirection: value as IntegrationReferenceDirection,
            });
          }}
          options={REFERENCE_DIRECTIONS}
        />
      )}
      <SelectComponent
        dataTestId="tabularMapping-reference-target-workspace--select"
        autoFocus
        label={isIncomingReference ? 'Source workspace' : 'Target workspace'}
        value={
          referenceMapping.targetWorkspace?.id ||
          referenceMapping.targetWorkspace?.name
        }
        options={[
          {
            options: usedWorkspaces.map(w => ({
              value: w.id || w.name,
              label: w.name,
              rightContent: !w.id ? <NewBadge /> : null,
            })),
            label: 'Used in import',
          },
          {
            options: unusedExistingWorkspaces,
            label: 'Other workspaces',
          },
        ]}
        onCreateOption={value => {
          onMapping({
            ...referenceMapping,
            targetWorkspace: {
              id: null,
              name: value,
            },
          });
        }}
        onValueChange={value => {
          if (!value) return;
          const selectedWorkspace = existingWorkspaceOptions.find(
            w => w.value === value
          );

          if (!selectedWorkspace) {
            onMapping({
              ...referenceMapping,
              targetWorkspace: {
                id: null,
                name: value,
              },
            });
            return;
          }

          onMapping({
            ...referenceMapping,
            targetWorkspace: {
              id: selectedWorkspace.value,
              name: selectedWorkspace.label,
            },
          });
        }}
      />
      <SelectComponent
        dataTestId="tabularMapping-reference-type--select"
        label="Reference type"
        value={referenceMapping.referenceType}
        options={[
          {
            options: newWorkspaceReferenceTypes.map(type => ({
              value: type,
              label: type,
              rightContent: <NewBadge />,
            })),
            label: 'New in import',
          },
          {
            options: Object.values(workspaceReferenceTypes).map(type => ({
              label: type.name,
              value: type.name,
            })),
            label: 'Existing',
          },
        ]}
        onCreateOption={value => {
          onMapping({
            ...referenceMapping,
            referenceType: value,
          });
        }}
        onValueChange={value => {
          if (value) {
            onMapping({
              ...referenceMapping,
              referenceType: value,
            });
          }
        }}
      />

      <ReferenceMissingComponentsStrategy
        tableMapping={tableMapping}
        columnMapping={referenceMapping}
        usedFields={usedFields}
        allFields={allFields}
        onMapping={onMapping}
        columnName={columnName}
        isCreationDisabled={isCreationDisabled}
        isSource={
          referenceMapping.referenceDirection ===
          IntegrationReferenceDirection.INCOMING
        }
        workspaceId={referenceMapping.targetWorkspace?.id}
        mappedColumnsErrors={mappedColumnsErrors}
      />

      <ReferenceFormat
        tableMapping={tableMapping}
        columnMapping={referenceMapping}
        usedFields={usedFields}
        allFields={allFields}
        onMapping={onMapping}
        columnName={columnName}
        isCreationDisabled={isCreationDisabled}
        missingComponentsStrategy={referenceMapping.missingComponents}
        mappedColumnsErrors={mappedColumnsErrors}
      />

      <TextInput
        dataTestId="tabularMapping-reference-item-separator--input"
        label="Item separator"
        value={referenceMapping.referenceItemSeparator}
        errorMessage={
          mappedColumnsErrors[SidebarSelectorsIds.ITEM_SEPARATOR] || undefined
        }
        displayValue={referenceMapping.referenceItemSeparator}
        onValueChange={value => {
          onMapping({
            ...referenceMapping,
            referenceItemSeparator: value,
          });
        }}
      />
    </>
  );
};
