import { ReferenceTypePreview } from '@ardoq/renderers';
import { Component } from 'react';
import {
  clearReferenceTypeFilters,
  getFilterIncludedTypeNames,
  setIncludedReferenceTypes,
} from './referenceFilterRuleManager';
import {
  QuickPerspectiveType,
  trackToggledQuickPerspective,
} from 'quickPerspectives/tracking';
import { QuickPerspectivesContext } from './types';
import { Label } from './atoms';
import QuickPerspectivesLegendItem from './QuickPerspectivesLegendItem';
import { connect } from '@ardoq/rxbeach';
import quickPerspectivesContext$ from 'quickPerspectives/quickPerspectivesContext$';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import filterRules$ from 'quickPerspectives/filterRules$';
import { FiltersAsQueryBuilderQueries } from '@ardoq/filter-interface';

interface ReferenceLegendProperties {
  quickPerspectivesContext: QuickPerspectivesContext;
  filterRules: FiltersAsQueryBuilderQueries;
}
class ReferenceLegend extends Component<ReferenceLegendProperties> {
  constructor(props: ReferenceLegendProperties) {
    super(props);
  }

  handleReferenceSelectedChange(
    referenceTypeName: string,
    isSelected: boolean
  ) {
    let includedTypeNames = getFilterIncludedTypeNames(
      this.props.filterRules.referenceRules
    );
    if (isSelected) {
      includedTypeNames = includedTypeNames.filter(
        item => item !== referenceTypeName
      );
    } else {
      includedTypeNames.push(referenceTypeName);
    }
    trackToggledQuickPerspective({
      type: QuickPerspectiveType.REFERENCE_FILTER,
      isEnabling: !isSelected,
    });
    setIncludedReferenceTypes(includedTypeNames);
  }

  render() {
    const allReferenceTypes =
      this.props.quickPerspectivesContext.referenceTypes || [];
    const includedTypeNames = getFilterIncludedTypeNames(
      this.props.filterRules.referenceRules
    );
    const outsideTypeNames = includedTypeNames.filter(
      typeName => !allReferenceTypes.find(rt => typeName === rt.name)
    );

    return (
      <div>
        <QuickPerspectivesLegendItem
          isImplicitlySelected={includedTypeNames.length === 0}
          isChecked={includedTypeNames.length === 0}
          isDisabled={includedTypeNames.length === 0}
          onChange={clearReferenceTypeFilters}
        >
          <span className="ref-spacing" />
          <span>All</span>
        </QuickPerspectivesLegendItem>

        {allReferenceTypes.map(referenceType => {
          const isSelected = includedTypeNames.includes(referenceType.name);
          return (
            <QuickPerspectivesLegendItem
              key={referenceType.name}
              isChecked={isSelected}
              onChange={() =>
                this.handleReferenceSelectedChange(
                  referenceType.name,
                  isSelected
                )
              }
            >
              <ReferenceTypePreview width={65} height={24} {...referenceType} />
              <Label
                data-tooltip-text={
                  referenceType.name.length >= 16 ? referenceType.name : ''
                }
              >
                {referenceType.name}
              </Label>
            </QuickPerspectivesLegendItem>
          );
        })}

        <div>
          {outsideTypeNames.map(typeName => (
            <QuickPerspectivesLegendItem
              key={typeName}
              isOutsideScope={true}
              isChecked={true}
              onChange={() =>
                this.handleReferenceSelectedChange(typeName, true)
              }
            >
              {typeName}
            </QuickPerspectivesLegendItem>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(
  ReferenceLegend,
  combineLatest([quickPerspectivesContext$, filterRules$]).pipe(
    map(([quickPerspectivesContext, filterRules]) => ({
      quickPerspectivesContext,
      filterRules,
    }))
  )
);
