import { PopoverRenderer } from '@ardoq/popovers';
import ChangePopoverContent from './ChangePopoverContent';
import { MODEL_ID_ATTRIBUTE, MODEL_ID_SELECTOR } from 'consts';
import { componentInterface } from 'modelInterface/components/componentInterface';

export const CHANGED_POPOVER_ID = 'changed-popover';

export const changedPopoverRenderer: PopoverRenderer = target => {
  const element = target?.closest(
    `.component-item[data-id], ${MODEL_ID_SELECTOR}`
  );

  if (!(element instanceof HTMLElement || element instanceof SVGElement)) {
    return null;
  }
  const id =
    componentInterface.getIdFromCid(element.dataset.id || '') ||
    element.getAttribute(MODEL_ID_ATTRIBUTE);
  if (!id) {
    return null;
  }

  return <ChangePopoverContent entityId={id} />;
};
