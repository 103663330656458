import {
  getBarColor,
  getSubtitle,
  hasQuestionValidationOfSeverity,
  questionHasBeenClosed,
} from '../utils';
import DependentFieldQuestion from './DependentFieldQuestion';
import DependentReferenceQuestion, {
  DependentReferenceQuestionTypes,
} from './DependentReferenceQuestion';
import {
  APIFieldAttributes,
  ArdoqId,
  isEntity,
  PersistedAttributeQuestion,
  PersistedDependentQuestion,
  PersistedFieldQuestion,
  PersistedFieldSubQuestion,
  UnpersistedAttributeQuestion,
  UnpersistedDependentQuestion,
  UnpersistedFieldQuestion,
  UnpersistedFieldSubQuestion,
} from '@ardoq/api-types';
import {
  AllPossibleQuestions,
  SurveyQuestionValidations,
} from 'surveyAdmin/types';
import { Position } from '@ardoq/drag-and-drop';
import SurveyIslandRightContent from '../SurveyIslandRightContent';
import { ClickableIslandHeader } from '@ardoq/snowflakes';
import { PermissionContext } from '@ardoq/access-control';
import { IslandBody, IslandContainer } from '@ardoq/page-layout';
import { isReferenceQuestion } from '../questionPredicates';
import surveyEditor$ from 'surveyAdmin/SurveyEditor/streams/surveyEditor$';
import { connect } from '@ardoq/rxbeach';
import { SurveyEditorState } from 'surveyAdmin/SurveyEditor/streams/types';

type DependentQuestionTypes =
  | PersistedFieldSubQuestion
  | UnpersistedFieldSubQuestion;

type DependentQuestionProps = {
  index: number;
  question: DependentQuestionTypes;
  questions?: AllPossibleQuestions[];
  parentQuestionField: APIFieldAttributes;
  fields: APIFieldAttributes[];
  attributes: {
    label: string;
    attributeName: string;
  }[];
  workspaceId: ArdoqId | null;
  questionValidations?: SurveyQuestionValidations[];
  surveyComponentTypeId?: string;
  dragAndDropCardId: string;
  parentQuestionTitle: string;
  parentQuestionIsReadonly?: boolean;
  isExpanded: boolean;
  permissionsContext: PermissionContext;
  surveyIsPermissionDivisionsAware: boolean;
  hasSurveyResponseApprovalsFeature: boolean;
  expandQuestion: () => void;
  updateDependentQuestion: (dependentQuestion: DependentQuestionTypes) => void;
  removeQuestion: () => void;
  handleOrderChange: (id: string, position: Position) => void;
  fieldHasBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith'];
};

const DependentQuestion = ({
  index,
  question,
  parentQuestionField,
  fields,
  attributes,
  questions,
  workspaceId,
  questionValidations,
  surveyComponentTypeId,
  dragAndDropCardId,
  parentQuestionTitle,
  parentQuestionIsReadonly,
  isExpanded,
  permissionsContext,
  surveyIsPermissionDivisionsAware,
  hasSurveyResponseApprovalsFeature,
  expandQuestion,
  updateDependentQuestion,
  removeQuestion,
  handleOrderChange,
  fieldHasBeenInteractedWith,
}: DependentQuestionProps) => {
  const relevantQuestionValidations = questionValidations?.[index] ?? {
    questionValidations: [],
  };

  const updateDependentReferenceQuestion = (
    dependentQuestion: DependentReferenceQuestionTypes
  ) => {
    updateDependentQuestion(dependentQuestion);
  };
  const updateDependentFieldQuestion = (
    dependentQuestion:
      | PersistedDependentQuestion<PersistedAttributeQuestion>
      | PersistedDependentQuestion<PersistedFieldQuestion>
      | UnpersistedDependentQuestion<UnpersistedAttributeQuestion>
      | UnpersistedDependentQuestion<UnpersistedFieldQuestion>
  ) => {
    updateDependentQuestion(dependentQuestion);
  };

  const isPersistedOrHasBeenClosed =
    isEntity(question) ||
    questionHasBeenClosed(fieldHasBeenInteractedWith, question);

  const hasErrors = hasQuestionValidationOfSeverity(
    relevantQuestionValidations,
    'error'
  );

  const hasWarnings = hasQuestionValidationOfSeverity(
    relevantQuestionValidations,
    'warning'
  );
  return (
    <IslandContainer>
      <ClickableIslandHeader
        barColor={getBarColor(question.type)}
        title={question.label || `Conditional ${getSubtitle(question)}`}
        subtitle={`Conditional ${getSubtitle(question)}`}
        errorMessage={
          isPersistedOrHasBeenClosed
            ? hasErrors
              ? 'Error'
              : undefined
            : undefined
        }
        warningMessage={
          isPersistedOrHasBeenClosed
            ? hasWarnings
              ? 'Warning'
              : undefined
            : undefined
        }
        dragAndDropCardId={dragAndDropCardId}
        onClick={expandQuestion}
        rightContent={
          <SurveyIslandRightContent
            dragAndDropCardId={dragAndDropCardId}
            isExpanded={isExpanded}
            handleOrderChange={handleOrderChange}
            setisExpanded={expandQuestion}
            removeQuestion={removeQuestion}
          />
        }
      />
      {isExpanded && (
        <IslandBody>
          {isReferenceQuestion(question) ? (
            <DependentReferenceQuestion
              surveyWorkspaceId={workspaceId}
              surveyComponentTypeId={surveyComponentTypeId}
              question={question}
              questionValidations={relevantQuestionValidations}
              updateQuestion={updateDependentReferenceQuestion}
              parentQuestionField={parentQuestionField}
              parentQuestionTitle={parentQuestionTitle}
              permissionsContext={permissionsContext}
              parentQuestionIsReadonly={parentQuestionIsReadonly}
              surveyIsPermissionDivisionsAware={
                surveyIsPermissionDivisionsAware
              }
              hasSurveyResponseApprovalsFeature={
                hasSurveyResponseApprovalsFeature
              }
            />
          ) : (
            <DependentFieldQuestion
              parentQuestionField={parentQuestionField}
              questions={questions}
              question={question}
              fields={fields}
              attributes={attributes}
              questionValidations={relevantQuestionValidations}
              updateQuestion={updateDependentFieldQuestion}
              workspaceId={workspaceId}
              parentQuestionTitle={parentQuestionTitle}
              parentQuestionIsReadonly={parentQuestionIsReadonly}
            />
          )}
        </IslandBody>
      )}
    </IslandContainer>
  );
};

export default connect(DependentQuestion, surveyEditor$);
