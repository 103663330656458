import { connect, dispatchAction } from '@ardoq/rxbeach';
import {
  APIComponentAttributes,
  APIEntityType,
  ArdoqId,
  ChangeRequestAction,
} from '@ardoq/api-types';
import { map } from 'rxjs';
import changeApprovalData$ from './changeApprovalData$';
import UpdateTable, { getApprovalStatus } from './PageElements/UpdateTable';
import CreateOrDeleteComponentTable from './PageElements/CreateOrDeleteComponentTable';
import { SwitchPosition } from '@ardoq/snowflakes';
import { trackEvent } from 'tracking/tracking';
import {
  resetDataForComponentField,
  setApprovedDataForComponentField,
} from './actions';
import {
  ChangeApprovalRowData,
  ComponentValidation,
  RecordOfComponents,
} from './types';
import { isEmpty } from 'lodash';
import { WrapperWithSidebar } from './PageElements/WrapperWithSidebar';
import { NoComponentFoundDoqy } from './PageElements/NoComponentFoundDoqy';

const getValueSetInApprovedChanges = (
  approvedComponentChanges: RecordOfComponents,
  componentId: ArdoqId,
  whatChanged: string,
  componentValidationObject: ComponentValidation
) => {
  if (isEmpty(componentValidationObject)) {
    return approvedComponentChanges?.[componentId]?.[whatChanged] ?? null;
  }
  return approvedComponentChanges?.[componentId]?.[whatChanged];
};

type ComponentDisplayProps = {
  componentId: ArdoqId;
  masterComponent: APIComponentAttributes | undefined;
  branchComponent: APIComponentAttributes | undefined;
  changeAction: ChangeRequestAction;
  sourceOfTruthComponent: APIComponentAttributes | undefined;
  changedFields: Partial<APIComponentAttributes>;
  approvedComponentChanges: RecordOfComponents;
  componentValidationObject: ComponentValidation;
  handleApproveAll: (
    componentId: ArdoqId,
    chosenComponent: APIComponentAttributes
  ) => void;
  handleRejectAll: (
    componentId: ArdoqId,
    chosenComponent: APIComponentAttributes | undefined
  ) => void;
};

const ComponentDisplay = ({
  componentId,
  branchComponent,
  masterComponent,
  sourceOfTruthComponent,
  changeAction,
  changedFields,
  approvedComponentChanges,
  componentValidationObject,
  handleApproveAll,
  handleRejectAll,
}: ComponentDisplayProps) => {
  if (!sourceOfTruthComponent) {
    return <NoComponentFoundDoqy />;
  }

  if (changeAction === 'update' && masterComponent && branchComponent) {
    const handleApprovedDataForSpecificField = (
      switchPosition: SwitchPosition,
      {
        whatChanged,
        changeApprovalEntityId,
        changedFrom,
        changedTo,
      }: ChangeApprovalRowData
    ) => {
      trackEvent(`Survey change approval: set row to ${switchPosition}`);
      if (switchPosition === 'pending') {
        dispatchAction(
          resetDataForComponentField({
            whatChanged,
            masterComponent,
            changedFields: Object.keys(changedFields),
            changeApprovalEntityId,
          })
        );
      } else {
        dispatchAction(
          setApprovedDataForComponentField({
            whatChanged,
            masterComponent,
            changedFields: Object.keys(changedFields),
            chosenValue:
              switchPosition === 'approved' ? changedTo : changedFrom,
            changeApprovalEntityId,
          })
        );
      }
    };

    const dataSource = Object.keys(changedFields).map(
      (field: keyof APIComponentAttributes) => {
        const changedTo = branchComponent[field] ?? null;
        const whatChanged = field;
        const valueSetInApprovedChanges = getValueSetInApprovedChanges(
          approvedComponentChanges,
          componentId,
          whatChanged,
          componentValidationObject
        );
        return {
          componentId,
          changeApprovalEntityId: componentId,
          whatChanged,
          changedFrom: masterComponent[field] ?? null,
          changedTo,
          entityType: APIEntityType.COMPONENT,
          status: getApprovalStatus(valueSetInApprovedChanges, changedTo),
        };
      }
    );
    return (
      <WrapperWithSidebar>
        <UpdateTable
          dataSource={dataSource}
          handleApprovedDataForSpecificField={
            handleApprovedDataForSpecificField
          }
        />
      </WrapperWithSidebar>
    );
  }
  return (
    <WrapperWithSidebar>
      <CreateOrDeleteComponentTable
        onApproveAll={() =>
          handleApproveAll(componentId, sourceOfTruthComponent)
        }
        onRejectAll={() =>
          handleRejectAll(
            componentId,
            changeAction === 'create' ? masterComponent : branchComponent
          )
        }
      />
    </WrapperWithSidebar>
  );
};

const componentDisplayData$ = changeApprovalData$.pipe(
  map(
    ({
      componentId,
      masterData,
      branchData,
      changeAction,
      changedFieldsByComponentId,
      approvedComponentChanges,
      componentValidationObject,
    }) => {
      const masterComponent = masterData.componentsById[componentId];
      const branchComponent = branchData.componentsById[componentId];
      return {
        componentId,
        branchComponent,
        masterComponent,
        changeAction,
        sourceOfTruthComponent:
          changeAction === 'create' ? branchComponent : masterComponent,
        changedFields: changedFieldsByComponentId?.[componentId] ?? {},
        approvedComponentChanges,
        componentValidationObject: componentValidationObject[componentId],
      };
    }
  )
);

export default connect(ComponentDisplay, componentDisplayData$);
