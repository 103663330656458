import {
  ColumnsProps,
  getLastModifiedByColumn,
  getLastModifiedDateColumn,
  getNameColumnIncludingSelectAllCheckbox,
} from '../components/AssetsBrowser/columns';
import {
  ColumnsOptions,
  Folder,
  SortColumnOptions,
} from '../components/EntityBrowser/types';
import { AssetRow, ReportRow } from 'components/AssetsBrowser/types';
import SortHeader from '../components/EntityBrowser/SortHeader';
import { DataSourceType } from '@ardoq/api-types';
import { HeaderCell, RowType } from '@ardoq/table';
import AssetsBrowserActionsMenu from '../appContainer/DashboardContainer/AssetsBrowserActionsMenu';
import { DropdownOption } from '@ardoq/dropdown-menu';
import { Icon, IconName } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { colors } from '@ardoq/design-tokens';
import { formatDateOnly, formatInTimeZone, parseDate } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { isReportRow } from './utils';
import { FlexBox } from '@ardoq/layout';

const getErrorIcon = ({ error }: ReportRow) => {
  if (!error) return null;
  const locale = getCurrentLocale();
  return (
    <FlexBox align={'center'}>
      <WithPopover
        content={`The report contains an error. Please open the report to review the issue.\n\nDate: ${formatDateOnly(
          error.firstSeen,
          locale
        )}\nTime: ${formatInTimeZone(
          parseDate(error.firstSeen),
          'UTC',
          'HH:MM:ss'
        )} UTC\nError key: ${error.errorKey}${
          error.display ? `\nDetails: ${error.display}` : ''
        } `}
      >
        <Icon iconName={IconName.ERROR} color={colors.red50} />
      </WithPopover>
    </FlexBox>
  );
};

export const getDataSourceColumn = (props: SortColumnOptions) => ({
  title: 'Data source',
  dataIndex: 'datasource',
  headerStyle: {
    width: 300,
  },
  headerRender: ({ title, dataIndex = 'datasource' }: HeaderCell) => (
    <SortHeader
      dataIndex={dataIndex}
      title={title}
      dataClickId="datasource"
      sortByField={props.sortByField}
      sortOrder={props.sortOrder}
      onSortChanged={props.setSortByField}
    />
  ),
  valueRender: (value: string, row: AssetRow | Folder<AssetRow>) => {
    if (!isReportRow(row)) return null;
    return value === DataSourceType.ADVANCED_SEARCH
      ? 'Advanced search'
      : value === DataSourceType.GREMLIN_SEARCH
        ? 'Gremlin graph search'
        : 'Survey';
  },
});

export const getReportErrorColumn = (props: SortColumnOptions) => ({
  dataIndex: 'error.firstSeen',
  headerStyle: {
    width: 30,
  },
  headerRender: ({ dataIndex = 'error.firstSeen' }: HeaderCell) => (
    <SortHeader
      onSortChanged={props.setSortByField}
      sortOrder={props.sortOrder}
      dataClickId="error.firstSeen"
      title={''}
      sortByField={props.sortByField}
      dataIndex={dataIndex}
      iconProps={{ iconName: IconName.ERROR }}
      tooltipText={'Report status'}
    />
  ),
  valueRender: (_name: string, row: AssetRow | Folder<AssetRow>) => {
    if (!isReportRow(row)) return null;
    return getErrorIcon(row);
  },
});

const getMenuOptionsColumn = ({
  getMenuOptions,
}: {
  getMenuOptions: (report: ReportRow) => DropdownOption[];
}) => ({
  headerStyle: {
    width: 45,
  },
  valueRender: (_name: string, row: AssetRow | Folder<AssetRow>) => {
    const menuOptions = getMenuOptions(row as ReportRow);
    return menuOptions.length ? (
      <AssetsBrowserActionsMenu
        rowType={RowType.REPORT}
        menuOptions={menuOptions}
      />
    ) : null;
  },
});

export const getReportOverviewColumns =
  (
    columnsProps: ColumnsProps & {
      getMenuOptions: (report: ReportRow) => DropdownOption[];
    }
  ) =>
  (columnsOptions: ColumnsOptions<AssetRow>) => {
    return [
      getNameColumnIncludingSelectAllCheckbox({
        ...columnsProps,
        ...columnsOptions,
      }),
      getDataSourceColumn(columnsProps),
      getLastModifiedDateColumn(columnsProps),
      getLastModifiedByColumn(columnsProps),
      getMenuOptionsColumn(columnsProps),
      getReportErrorColumn(columnsProps),
    ];
  };
