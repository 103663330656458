import { RefObject, useEffect, useRef, useState } from 'react';
import {
  APIPresentationAssetAttributes,
  APISlideAttributes,
  ArdoqId,
  isVisualizationSlide,
} from '@ardoq/api-types';
import { PresentationDragAndDropCardWrapper } from './atoms';
import getMetaInfo from 'views/metaInfo';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import { slideInterface } from 'modelInterface/presentations/slideInterface';
import { getViewModifierValuesForSlide } from 'viewDeprecation/util';
import { DragAndDropSectionProps } from '@ardoq/drag-and-drop';
import { deprecatedViewsSuccessors } from 'viewDeprecation/restrictedViews';
import SavedViewModifiers from 'components/presentationSidebar/components/SavedViewModifiers';
import { connect, derivedStream } from '@ardoq/rxbeach';
import presentations$ from 'streams/presentations/presentations$';
import { map } from 'rxjs';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';
import { presentationOperations } from 'streams/presentations/presentationOperations';
import { presentationAccessControlOperations } from 'resourcePermissions/accessControlHelpers/presentations';
import { PresentationsStreamShape } from 'streams/presentations/types';
import { SlideHandler } from './types';
import { SlideEdit } from './SlideEdit';
import { SlideView } from './SlideView';

type SlideViewArgs = {
  slide: APISlideAttributes;
  selected: boolean;
  editing: boolean;
  canEdit: boolean;
  isPresentationPreview?: boolean;
  viewIsSupported?: boolean;
  currentPresentationId: ArdoqId;
  editsToCurrentSlide?: Partial<Record<'name' | 'description', string>>;
  presentations: APIPresentationAssetAttributes[];
  onSlideClick: SlideHandler;
  onEditClick: SlideHandler;
  onDeleteClick: SlideHandler;
  onReplaceSlideClick: SlideHandler;
  onSlideEditedAttributes: (
    value: string,
    attributeName: 'name' | 'description'
  ) => void;
  onDiscardChangesClick: SlideHandler;
  onSaveChangesClick: (slide?: APISlideAttributes) => void;
} & DragAndDropSectionProps;

export const MAX_HEIGHT = 120;

export const getScrollHeight = (ref: RefObject<HTMLDivElement>) =>
  ref.current?.scrollHeight ?? 0;

const Slide = ({
  slide,
  selected = false,
  editing = false,
  canEdit = false,
  isPresentationPreview = false,
  viewIsSupported,
  presentations,
  dragAndDropCardId,
  editsToCurrentSlide,
  onSlideClick,
  onEditClick,
  onDeleteClick,
  onReplaceSlideClick,
  onSlideEditedAttributes,
  onDiscardChangesClick,
  onSaveChangesClick,
}: SlideViewArgs) => {
  const [hasDescriptionToggle, setHasDescriptionToggle] = useState(false);
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
  const [dropdownIsopen, setDropdownIsOpen] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (editing || !descriptionRef.current) {
      return;
    }
    const isLongDescription = getScrollHeight(descriptionRef) > MAX_HEIGHT;
    if (!isLongDescription) {
      setDescriptionExpanded(false);
    }
    setHasDescriptionToggle(isLongDescription);
    // without the description as a dependency, this calculates out of sync (ARD-26417)
  }, [editing, descriptionRef, slide.description]);

  const imageSource = slideInterface.getThumbnailSrc(slide._id);

  const viewId = slideInterface.getViewId(slide._id);
  const viewStatus = viewId && getMetaInfo().get(viewId)?.viewStatus;
  const isDiscontinued = viewStatus === MetaInfoViewStatus.DISCONTINUED;
  const showViewModifiers =
    viewId &&
    isDiscontinued &&
    canEdit &&
    deprecatedViewsSuccessors.has(viewId);

  const hasFullAccess = slideInterface.determineFullAccess(slide._id);

  return (
    <PresentationDragAndDropCardWrapper
      dragAndDropCardId={dragAndDropCardId}
      $selected={selected}
    >
      <>
        {editing && (
          <SlideEdit
            slide={slide}
            onSlideEditedAttributes={onSlideEditedAttributes}
            editsToCurrentSlide={editsToCurrentSlide}
            onSaveChangesClick={onSaveChangesClick}
            onDiscardChangesClick={onDiscardChangesClick}
          />
        )}
        {!editing && (
          <SlideView
            dropdownIsopen={dropdownIsopen}
            slide={slide}
            onSlideClick={onSlideClick}
            canEdit={canEdit}
            viewId={viewId}
            viewStatus={viewStatus}
            isDiscontinued={isDiscontinued}
            imageSource={imageSource}
            descriptionRef={descriptionRef}
            hasDescriptionToggle={hasDescriptionToggle}
            isDescriptionExpanded={isDescriptionExpanded}
            setDescriptionExpanded={setDescriptionExpanded}
            isPresentationPreview={isPresentationPreview}
            viewIsSupported={viewIsSupported}
            hasFullAccess={hasFullAccess}
            presentations={presentations}
            onEditClick={onEditClick}
            onReplaceSlideClick={onReplaceSlideClick}
            onDeleteClick={onDeleteClick}
            setDropdownIsOpen={setDropdownIsOpen}
          />
        )}
        {isVisualizationSlide(slide) && showViewModifiers && (
          <SavedViewModifiers
            viewModifiers={getViewModifierValuesForSlide(
              slide.view,
              slide.viewstate
            )}
          />
        )}
      </>
    </PresentationDragAndDropCardWrapper>
  );
};

const toViewModel = ([presentationsStream, permissionContext]: [
  PresentationsStreamShape,
  PermissionContext,
]) => {
  return {
    presentations: presentationOperations
      .getPresentationsList(presentationsStream)
      .filter(presentation =>
        presentationAccessControlOperations.canEditPresentation(
          permissionContext,
          presentation
        )
      ),
  };
};

const viewModel$ = derivedStream(
  'viewModel$',
  presentations$,
  currentUserPermissionContext$
).pipe(map(toViewModel));

export default connect(Slide, viewModel$);
