import { Maybe } from '@ardoq/common-helpers';
import { TablePreview } from 'integrations/common/streams/tablePreviews/types';
import { Dictionary } from 'integrations/common/types/common';
import {
  Complaint,
  ComplaintLevel,
  TransferConfig,
  TransferEffectSummaries,
  SourceConfig,
} from '@ardoq/api-types/integrations';
import {
  IntegrationId,
  TableId,
  TabularMapping,
} from 'integrations/common/streams/tabularMappings/types';

export enum TransferDirection {
  IMPORT = 'import',
  EXPORT = 'export',
}

export type Totals = TransferEffectSummaries & {
  validation: Complaint[];
};

export type TableSummary = TransferEffectSummaries & {
  name: string;
  id: TablePreview['id'];
  validation: Complaint[];
};

export type AffectedWorkspace = {
  name: string;
  id: string;
};

export type TransferResponse = {
  dryRun: boolean;
  async?: boolean;
  totals: Totals;
  newFields: Maybe<string[]>;
  sourceId: string;
  tables: TableSummary[];
  affectedWorkspaces: AffectedWorkspace[];
  message?: string; // on warn and error cases
};

export enum DryRunStatus {
  SUCCESS = 'Success',
  WARNING = 'Warning',
  ERROR = 'Error',
}

export type ComplaintsIndices = {
  errors: number[];
  warnings: number[];
  byPriority: number[];
};

export type ComplaintsByLevel = Partial<Record<ComplaintLevel, Complaint[]>>;

export type TableComplaints = {
  level?: ComplaintsByLevel;
  row: Dictionary<{
    level: ComplaintsByLevel;
    cellLevel: ComplaintsByLevel;
    column: Dictionary<{ level: ComplaintsByLevel }>;
  }>;
  column: Dictionary<{
    level: ComplaintsByLevel;
    cellLevel: ComplaintsByLevel;
    row: Dictionary<{ level: ComplaintsByLevel }>;
  }>;
};

export type TransferRequestPayload = Pick<
  TransferConfig,
  'name' | 'sources' | 'options' | 'tables'
>;

// transferState

export type InfoMessage = {
  infoMessage: Maybe<string>;
};

export type RunParams = {
  dryRun: boolean;
  async: boolean;
  sourceConfig?: SourceConfig;
};

export type WithTabularMapping = {
  tabularMapping: TabularMapping;
};

type WithTransferDirection = {
  transferDirection: TransferDirection;
};

type WithIsSelectionDataFetched = {
  isSelectionDataFetched: boolean;
};

type StateBase = RunParams &
  WithTransferDirection &
  InfoMessage &
  WithIsSelectionDataFetched;

export type SuccessState = StateBase &
  WithTabularMapping & {
    requestStatus: 'SUCCESS';
    response: TransferResponse;
    // TODO this is derived data, it should be removed from here once our streams become less chatty
    tablesComplaints: Record<TableId, TableComplaints>;
    selectedWorkspaces: string[];
  };

export type FailureState = StateBase &
  WithTabularMapping & {
    requestStatus: 'FAILURE';
    errorMessage: string;
  };

export type LoadingState = StateBase &
  WithTabularMapping & {
    requestStatus: 'LOADING';
  };

export type InitialState = StateBase & {
  requestStatus: 'INIT';
};

export type TransferState =
  | SuccessState
  | FailureState
  | LoadingState
  | InitialState;

export type InstanceToTransferState = Record<IntegrationId, TransferState>;
