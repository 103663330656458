import { SearchOverview } from './SearchOverview';
import storedQueries$ from 'streams/queries/storedQueries$';
import { connect } from '@ardoq/rxbeach';
import { SearchType } from '@ardoq/api-types';
import { map, withLatestFrom } from 'rxjs/operators';
import { SearchPane } from 'search/SearchTabContainer/types';
import searchTab$ from 'search/SearchTabContainer/searchTab$';

export default connect(
  SearchOverview,
  storedQueries$.pipe(
    withLatestFrom(searchTab$),
    map(([{ storedQueries }, { searchOverviewPageDict }]) => ({
      renderEmptyTableTitle: 'No graph filters found',
      storedQueries: storedQueries
        .filter(({ type }) => type === SearchType.DYNAMIC_FILTER_QUERY)
        .map(query => ({
          ...query,
          hasDeleteOption: true,
          hasCopyOption: false,
          typeLabel: `${
            query.parameterQueryId ? 'Parameterized' : 'Plain'
          } graph filter`,
        })),
      overviewType: SearchPane.DYNAMIC_FILTER_OVERVIEW,
      searchOverviewPage:
        searchOverviewPageDict[SearchPane.DYNAMIC_FILTER_OVERVIEW],
    }))
  )
);
