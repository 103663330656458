import { APIStartChatResponse } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { ChatOptions, DecoratedSendMessageRequest, Feedback } from './types';

const DOMAIN = '[bestPracticeAssistant]';

export const openChatWindow = actionCreator<ChatOptions>(
  `${DOMAIN} openChatWindow`
);

export const closeChatWindow = actionCreator(`${DOMAIN} closeChatWindow`);
export const minimizeChatWindow = actionCreator<{
  silenceEvent?: boolean;
}>(`${DOMAIN} minimizeChatWindow`);

export const initializeNewChatSuccess = actionCreator<APIStartChatResponse>(
  `${DOMAIN} initializeNewChatSuccess`
);
export const initializeNewChatFailed = actionCreator<string>(
  `${DOMAIN} initializeNewChatFailed`
);

export const sendMessage = actionCreator<DecoratedSendMessageRequest>(
  `${DOMAIN} sendMessage`
);
export const sendMessageDone = actionCreator(`${DOMAIN} sendMessageDone`);
export const sendMessageFailed = actionCreator<string>(
  `${DOMAIN} sendMessageFailed`
);

export const dequeueMessages = actionCreator(`${DOMAIN} dequeueMessages`);

export const setInput = actionCreator<string>(`${DOMAIN} setInput`);

export const expandChatWindow = actionCreator(`${DOMAIN} expandChatWindow`);

export const initializeNewChat = actionCreator<ChatOptions>(
  `${DOMAIN} initializeNewChat`
);

export const submitFeedback = actionCreator<Feedback>(
  `${DOMAIN} submitFeedback`
);

export const saveSubmittedFeedback = actionCreator<{
  messageIndex: number;
  feedback: Feedback;
}>(`${DOMAIN} saveSubmittedFeedback`);

export const dismissBanner = actionCreator(`${DOMAIN} dismissBanner`);

export const passToIntercom = actionCreator(`${DOMAIN} passToIntercom`);

export const closeConnection = actionCreator(`${DOMAIN} closeConnection`);

export const setAutoScrollToBottom = actionCreator<boolean>(
  `${DOMAIN} setAutoScrollToBottom`
);

export const setCurrentScrollTop = actionCreator<number>(
  `${DOMAIN} setCurrentScrollTop`
);

export const scrollToBottom = actionCreator(`${DOMAIN} scrollToBottom`);

export const chatTimedOut = actionCreator(`${DOMAIN} chatTimedOut`);

export const badFeedbackComment = actionCreator(`${DOMAIN} badFeedbackComment`);
