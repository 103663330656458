import { Multiselect } from '@ardoq/select';
import { byLocaleField } from 'integrations/common/utils/common';

type SubscriptionsSelectorProps = {
  subscriptions: any[];
  onSelect: (ids: string[]) => void;
  selectedSubscriptionIds: string[];
  isDisabled: boolean;
};
export const SubscriptionsSelector = ({
  selectedSubscriptionIds,
  subscriptions,
  onSelect,
  isDisabled,
}: SubscriptionsSelectorProps) => {
  const options = subscriptions
    .map(subscription => ({
      label: subscription.subscriptionName,
      value: subscription.subscriptionId,
    }))
    .sort(byLocaleField('label'));

  return (
    <Multiselect
      isDisabled={isDisabled}
      options={options}
      placeholder="Type and select a subscription"
      helperText="Subscriptions are logical containers for resource groups. Select at least one subscription to continue."
      onValueChange={ids => onSelect(ids || [])}
      value={selectedSubscriptionIds}
      showSelectAllFooter
    />
  );
};
