import { FlexBox, Stack } from '@ardoq/layout';
import styled from 'styled-components';
import { Label } from '@ardoq/forms';
import { ComponentType } from '../components/ComponentType';
import { MetadataComponent } from '../types';

type PathCollapsingNodeSelectorProps = {
  selectedNode: MetadataComponent | null;
  label: string;
  isDisabled?: boolean;
};

const PathCollapsingNodeSelector = ({
  selectedNode,
  label,
  isDisabled = false,
}: PathCollapsingNodeSelectorProps) => (
  <Container $isDisabled={isDisabled}>
    <Stack gap="small">
      <Label>{label}</Label>
      <BoxWithMinHeight
        borderRadius="r4"
        borderColor="borderSubtle00"
        paddingLeft="medium"
        paddingRight="xsmall"
        paddingY="xsmall"
        align="center"
      >
        <FlexBox justify="space-between" align="center">
          <div>
            {selectedNode ? (
              <ComponentType {...selectedNode} />
            ) : (
              'Select in the dataset metamodel'
            )}
          </div>
        </FlexBox>
      </BoxWithMinHeight>
    </Stack>
  </Container>
);

const BoxWithMinHeight = styled(FlexBox)`
  min-height: 40px;
`;

const Container = styled.div<{ $isDisabled: boolean }>`
  opacity: ${props => (props.$isDisabled ? 0.3 : 1)};
`;

export default PathCollapsingNodeSelector;
