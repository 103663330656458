import { initialState, SearchTraversalState } from './searchTraversal$';
import { APIViewpointAttributes } from '@ardoq/api-types';

export type SearchTraversalViewState = SearchTraversalState & {
  isStartTraversalDisabled: boolean;
  dataSourceFilteredByCreatedBy: APIViewpointAttributes[];
  allViewpointsCount: number;
};

export const initialSearchTraversalViewState: SearchTraversalViewState = {
  ...initialState,
  isStartTraversalDisabled: true,
  dataSourceFilteredByCreatedBy: [],
  allViewpointsCount: 0,
};

export const getViewModel = (
  state: SearchTraversalState
): SearchTraversalViewState => {
  const dataSourceFilteredByCreatedBy =
    state.shouldDisplayOnlyTraversalsOfCurrentUser
      ? state.traversals.filter(
          traversal => traversal.createdBy === state.currentUserId
        )
      : state.traversals;

  return {
    ...state,
    allViewpointsCount: state.traversals.length,
    isStartTraversalDisabled: state.selectedTraversalIds.length !== 1,
    dataSourceFilteredByCreatedBy,
  };
};
