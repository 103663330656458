import { radius, colors } from '@ardoq/design-tokens';
import { FlexBox } from '@ardoq/layout';
import styled from 'styled-components';

export const FlowContainer = styled(FlexBox).attrs({
  flexDirection: 'column',
  gap: 'none',
  marginRight: 'medium',
  height: 'full',
})`
  border-top-left-radius: ${radius.r8};
  border-top-right-radius: ${radius.r8};
  border-left: 1px solid ${colors.borderSubtle00};
  border-right: 1px solid ${colors.borderSubtle00};
  overflow: hidden;
  background-color: ${colors.surfaceStatusNeutral};
`;
