import { initializeYFilesLicense } from '../../../transpiledLibs/yfiles/license/license';
import * as encodingUtils from '@ardoq/html';
import {
  ClickEventArgs,
  ClickInputMode,
  EdgeStyleDecorationInstaller,
  GraphComponent,
  GraphItemTypes,
  GraphViewerInputMode,
  ILabelOwner,
  IModelItem,
  Insets,
  LayoutData,
  NodeStyleDecorationInstaller,
  Point,
  PolylineEdgeStyle,
  PortAdjustmentPolicy,
  QueryItemToolTipEventArgs,
  ShapeNodeShape,
  ShapeNodeStyle,
  Stroke,
  StyleDecorationZoomPolicy,
  TimeSpan,
  ILabel,
} from '@ardoq/yfiles';
import { installHoverMode } from 'yfilesExtensions/view/highlightControls';
import { installHoverMode as installModernizedHoverMode } from 'yfilesExtensions/view/modernized/highlightControls';
import { NodeSelectionStyleRenderer } from 'yfilesExtensions/styles/NodeSelectionStyleRenderer';
import { OnLayoutGraphArgs } from './onLayoutGraph';
import { DiffType } from '@ardoq/data-model';
import { EdgeSelectionStyleRenderer } from 'yfilesExtensions/styles/EdgeSelectionStyleRenderer';
import runWebWorkerLayout from './runWebWorkerLayout';
import { notifyViewLoading } from 'tabview/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { ViewIds } from '@ardoq/api-types';
import isCurrentlyHoveredImageBroken$ from './isCurrentlyHoveredImageBroken$';
import { dataModelId } from './graphComponentUtil';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import {
  CollapsibleGraphGroup,
  formatOtherLabel,
  GraphEdge,
  GraphGroup,
  GraphItem,
  ItemLabels,
} from '@ardoq/graph';
import { Edge } from 'graph/edge';
import {
  BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  BLOCK_DIAGRAM_OTHER_LABEL_FONT,
  BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE,
  MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE,
} from 'yfilesExtensions/styles/consts';
import {
  BLOCK_DIAGRAM_GROUP_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_FONT,
} from 'tabview/blockDiagram/view/consts';
import { pick } from 'lodash';
import { transparentize } from 'polished';
import { ensureContrast } from '@ardoq/color-helpers';
import { colors } from '@ardoq/design-tokens';
import { Node } from 'graph/node';
import { measureGroupSubLabel } from 'tabview/blockDiagram/view/utils';
import {
  getTextHeightWithinBlock,
  LEGACY_FORMATTING_VALUE_HEIGHT_NODE,
  measureExpandedGroupNodeLabelWidth,
  measureExpandedGroupNodeLabelWidthModernized,
} from 'tabview/blockDiagram/view/yFilesExtensions/labels/legacyLabelUtils';
import { getMaxGroupLabelHeight } from 'tabview/blockDiagram/view/yFilesExtensions/GroupBoundsCalculator';

initializeYFilesLicense();

const getLegacyLabelsAsData = (graphItem: GraphItem): ItemLabels => {
  const modelId = dataModelId(graphItem);

  const mainLabel = componentInterface.isComponent(modelId)
    ? componentInterface.getNameWithFieldLabelAndValue(modelId)
    : graphItem.getLabel();

  return {
    mainLabel,
    subLabel: graphItem instanceof GraphGroup ? graphItem.subLabel : undefined,
  };
};

const getTooltipText = (label: ILabel | null, isModern: boolean) => {
  const labelOwner = label?.owner;
  if (!labelOwner) {
    return null;
  }

  const graphItem = labelOwner.tag as GraphItem;

  const isGroup = graphItem instanceof CollapsibleGraphGroup;
  const isCollapsed = isGroup && graphItem.collapsed;
  const isEdge = graphItem instanceof Edge || graphItem instanceof GraphEdge;

  const mainLabelFont = isEdge
    ? isModern
      ? MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT
      : BLOCK_DIAGRAM_EDGE_LABEL_FONT
    : isGroup && !isCollapsed
      ? isModern
        ? MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_FONT
        : BLOCK_DIAGRAM_GROUP_LABEL_FONT
      : isModern
        ? MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT
        : BLOCK_DIAGRAM_NODE_LABEL_FONT;

  const itemLabels =
    graphItem.getItemLabels?.() ?? getLegacyLabelsAsData(graphItem);

  if (!itemLabels) {
    return null;
  }

  const subLabelText = itemLabels.subLabel ? `\n\n${itemLabels.subLabel}` : '';

  const {
    mainLabel,
    legacyLabelParts,
    legacyTruncatedFieldValueAndLabelForNode,
  } = itemLabels;

  const otherLabelsTexts = (itemLabels.otherLabels || []).map(
    otherLabel => `[${formatOtherLabel(otherLabel)}]`
  );

  const legacyFormattingText = legacyLabelParts?.fieldValue
    ? `[${legacyLabelParts.fieldLabel}: ${legacyLabelParts.fieldValue}]`
    : null;

  const legacyFormattingSpace = legacyFormattingText
    ? LEGACY_FORMATTING_VALUE_HEIGHT_NODE
    : 0;

  const maxNodeLabelSize = isModern
    ? MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize
    : BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize;

  const maxLabelHeight = isGroup
    ? getMaxGroupLabelHeight(Boolean(legacyFormattingText), isModern)
    : maxNodeLabelSize.height - legacyFormattingSpace;

  const isMainLabelTruncated = mainLabel
    ? getTextHeightWithinBlock(mainLabel, mainLabelFont, isModern) >
      maxLabelHeight
    : false;

  const isSublabelTruncated =
    // that's not really correct. The grop width is dynamic.
    measureGroupSubLabel(subLabelText) > label.layout.width;

  const isOtherLabelTruncated = otherLabelsTexts.some(
    text =>
      getTextHeightWithinBlock(
        text,
        isModern
          ? MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT
          : BLOCK_DIAGRAM_OTHER_LABEL_FONT,
        isModern
      ) >
      (isModern
        ? MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE.height
        : BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE.height)
  );

  const isLegacyFormattingTruncated =
    legacyFormattingText &&
    (isGroup
      ? (isModern
          ? measureExpandedGroupNodeLabelWidthModernized(legacyFormattingText)
          : measureExpandedGroupNodeLabelWidth(legacyFormattingText)) >
        label.layout.width
      : legacyFormattingText !== legacyTruncatedFieldValueAndLabelForNode);

  if (
    isMainLabelTruncated ||
    isSublabelTruncated ||
    isOtherLabelTruncated ||
    isLegacyFormattingTruncated
  ) {
    return `${[mainLabel, ...otherLabelsTexts, legacyFormattingText].filter(Boolean).join('\n')}${subLabelText}`;
  }
  return null;
};

const tooltipListener =
  (isModern: boolean) =>
  (src: any, e: QueryItemToolTipEventArgs<IModelItem>) => {
    const label =
      e.item instanceof ILabel ? e.item : e instanceof ILabel ? e : null;
    const labelOwner = e.item instanceof ILabel ? e.item.owner : e.item;
    if (!(labelOwner instanceof ILabelOwner)) {
      return;
    }

    const modelId = labelOwner.tag && dataModelId(labelOwner.tag);
    const diffType = componentInterface.isComponent(modelId)
      ? componentInterface.getVisualDiffType(modelId)
      : referenceInterface.isReference(modelId)
        ? referenceInterface.getVisualDiffType(modelId)
        : DiffType.NONE;
    if (!labelOwner || diffType === DiffType.CHANGED) {
      return;
    }

    if (labelOwner.labels.some(text => Boolean(text))) {
      const toolTipText = isCurrentlyHoveredImageBroken$.state
        .isCurrentlyHoveredImageBroken
        ? null
        : getTooltipText(label, isModern);
      e.toolTip = toolTipText ? encodingUtils.escapeHTML(toolTipText) : null;
    }
  };

const createInputMode = (
  graphComponent: GraphComponent,
  useHoverDecorator: boolean,
  isModern: boolean
) => {
  const result = new GraphViewerInputMode({
    itemHoverInputMode: isModern
      ? installModernizedHoverMode(graphComponent)
      : installHoverMode(graphComponent, useHoverDecorator),
    toolTipItems:
      GraphItemTypes.NODE | GraphItemTypes.EDGE | GraphItemTypes.LABEL,
  });
  result.clickInputMode = new ArdoqGraphClickInputMode(
    pick(result.clickInputMode!, [
      'priority',
      'exclusive',
      'enabled',
      'doubleClickPolicy',
      'validClickHitTestable',
      'requestMutexOnClick',
      'validClickHitCursor',
      'activeButtons',
    ])
  );
  result.addQueryItemToolTipListener(tooltipListener(isModern));
  result.mouseHoverInputMode.toolTipLocationOffset = new Point(10, 10);
  result.mouseHoverInputMode.duration = TimeSpan.fromMilliseconds(999999); // continue showing the tooltip "forever" or until the user moves the mouse away
  result.mouseHoverInputMode.delay = TimeSpan.ZERO;
  return result;
};
const SELECTION_COLOR = '#7fffdc50';
/** although nodes and edges will sometimes _not_ be on a white background, they may in fact cross several different background colors for a single edge or node highlight. so, using white as a contrast color will have to suffice. */
const CONTRAST_BASE = colors.white;
const getHighlightColor = (graphItem: GraphItem | Node | Edge) => {
  let isComponent,
    isReference = false;
  if (graphItem instanceof GraphItem) {
    isComponent = graphItem.isComponent();
    isReference = !isComponent && graphItem.isReference();
  } else if (graphItem instanceof Node) {
    isComponent = graphItem.isComponent();
  } else if (graphItem instanceof Edge) {
    isReference = graphItem.isReference();
  }

  if (!isComponent && !isReference) {
    return SELECTION_COLOR;
  }

  const modelId = dataModelId(graphItem);

  if (isComponent) {
    const { fill } = componentInterface.getComponentDisplayColorAsSVGAttributes(
      modelId,
      {
        useAsBackgroundStyle: false,
      }
    );
    if (fill) {
      return transparentize(0.7, ensureContrast(CONTRAST_BASE, fill));
    }
  }
  if (isReference) {
    const stroke = referenceInterface.getReferenceCssColors(modelId, {
      useAsBackgroundStyle: false,
    })?.stroke;
    if (stroke) {
      return transparentize(0.7, ensureContrast(CONTRAST_BASE, stroke));
    }
  }

  return SELECTION_COLOR;
};

export const applySelectionDecorators = (
  graphComponent: GraphComponent,
  nodeMargin = 25,
  edgeThickness = 10,
  isModern = false
) => {
  const graphDecorator = graphComponent.graph.decorator;

  graphDecorator.nodeDecorator.selectionDecorator.setFactory(
    node =>
      new NodeStyleDecorationInstaller({
        nodeStyle: new ShapeNodeStyle({
          renderer: new NodeSelectionStyleRenderer(),
          fill: isModern ? 'transparent' : getHighlightColor(node.tag),
          stroke: isModern ? new Stroke(colors.grey15, 1.2) : 'transparent',
          shape: isModern
            ? ShapeNodeShape.ROUND_RECTANGLE
            : ShapeNodeShape.ELLIPSE,
        }),
        margins: isModern ? 0 : nodeMargin,
        zoomPolicy: StyleDecorationZoomPolicy.WORLD_COORDINATES,
      })
  );

  graphDecorator.edgeDecorator.selectionDecorator.setFactory(
    edge =>
      new EdgeStyleDecorationInstaller({
        edgeStyle: new PolylineEdgeStyle({
          renderer: new EdgeSelectionStyleRenderer(),
          stroke: new Stroke(getHighlightColor(edge.tag), edgeThickness),
        }),
        zoomPolicy: StyleDecorationZoomPolicy.WORLD_COORDINATES,
      })
  );
};

export const createGraphComponent = (
  container: HTMLDivElement,
  useHoverDecorator: boolean,
  isModern: boolean
) => {
  const graphComponent = new GraphComponent(container);
  graphComponent.inputMode = createInputMode(
    graphComponent,
    useHoverDecorator,
    isModern
  );
  graphComponent.minimumZoom = 0.1;

  return graphComponent;
};

export const selectableItems: GraphItemTypes =
  GraphItemTypes.NODE | GraphItemTypes.EDGE;
export const focusableItems = GraphItemTypes.NONE;
interface RunLayoutArgs extends OnLayoutGraphArgs {
  layoutData: LayoutData;
  onLayoutGraph: (args: OnLayoutGraphArgs) => void;
  duration?: TimeSpan;
  animateViewport?: boolean;
  updateContentRect?: boolean;
  targetBoundsInsets?: Insets;
  portAdjustmentPolicy?: PortAdjustmentPolicy;
  viewId: ViewIds;
  viewInstanceId: string;
}
export const runLayout = ({
  graphComponent,
  layoutDescriptor,
  layoutData,
  onLayoutGraph,
  hasGraphUpdate,
  previousLayoutDescriptor,
  duration,
  animateViewport,
  updateContentRect,
  targetBoundsInsets,
  portAdjustmentPolicy,
  viewId,
  viewInstanceId,
}: RunLayoutArgs) => {
  return new Promise<void>(resolve => {
    runWebWorkerLayout({
      graphComponent,
      layoutDescriptor,
      layoutData,
      duration,
      animateViewport,
      updateContentRect,
      targetBoundsInsets,
      portAdjustmentPolicy,
      viewId,
      viewInstanceId,
    }).then(() => {
      onLayoutGraph({
        graphComponent,
        hasGraphUpdate,
        layoutDescriptor,
        previousLayoutDescriptor,
      });
      setTimeout(() =>
        dispatchAction(notifyViewLoading({ viewInstanceId, isBusy: false }))
      );
      resolve();
    });
  });
};

export const GRAPH_SKIP_CLICK_CLASSNAME = 'leftClickHandled';
const GRAPH_SKIP_CLICK_SELECTOR = `.${GRAPH_SKIP_CLICK_CLASSNAME}`;
export class ArdoqGraphClickInputMode extends ClickInputMode {
  constructor(options?: ConstructorParameters<typeof ClickInputMode>[0]) {
    // annoyingly, yFiles will throw a warning if you call super(undefined) here.
    if (options) {
      super(options);
    } else {
      super();
    }
  }
  onClicked(e: ClickEventArgs) {
    if (
      e.originalEvent?.target instanceof SVGElement &&
      e.originalEvent.target.closest(GRAPH_SKIP_CLICK_SELECTOR)
    ) {
      return;
    }
    super.onClicked(e);
  }
  onLeftClicked(e: ClickEventArgs) {
    if (
      e.originalEvent?.target instanceof SVGElement &&
      e.originalEvent.target.closest(GRAPH_SKIP_CLICK_SELECTOR)
    ) {
      return;
    }
    super.onLeftClicked(e);
  }
}
