import { Form, Formik } from 'formik';
import { PrimaryButton } from '@ardoq/button';
import { FormValues, submit, validate } from './utils';
import CurrentUser from 'models/currentUser';
import { logError } from '@ardoq/logging';
import StatusMessage, { isStatus } from '../shared/StatusMessage';
import { FormWrapper, TextInput } from '@ardoq/forms';
import {
  IslandBody,
  IslandContainer,
  IslandFooter,
  IslandHeader,
} from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';

const DEFAULT_ERROR_MESSAGE = 'An error occurred while updating your password';

const PasswordView = () => (
  <Formik<FormValues>
    initialValues={{ current: '', password: '', confirm: '' }}
    validate={validate}
    validateOnBlur={false}
    validateOnChange={false}
    onSubmit={(values, { setErrors, setSubmitting, setStatus }) =>
      submit(CurrentUser.id, values)
        .then(
          ({ isMatch }) => {
            if (!isMatch) {
              setErrors({ current: 'Wrong password' });
              setStatus({ success: false, message: '' });
            } else
              setStatus({
                success: true,
                message: 'Password update successful',
              });
          },
          (error: Error) => {
            logError(error);
            setStatus({
              success: false,
              message: DEFAULT_ERROR_MESSAGE,
            });
          }
        )
        .finally(() => setSubmitting(false))
    }
  >
    {({
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      status,
    }) => (
      <Form onSubmit={handleSubmit}>
        <IslandContainer>
          <IslandHeader title="Change Password" />
          <IslandBody>
            <FormWrapper>
              <TextInput
                label="Current Password"
                type="password"
                name="current"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.current as string}
              />
              <TextInput
                label="New Password"
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.password as string}
              />
              <TextInput
                label="Confirm Password"
                type="password"
                name="confirm"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.confirm as string}
              />
            </FormWrapper>
          </IslandBody>
          <IslandFooter $justify="end">
            <FlexBox marginRight="large">
              <StatusMessage
                status={!isSubmitting && isStatus(status) && status}
              />
            </FlexBox>
            <PrimaryButton type="submit" isDisabled={isSubmitting}>
              Save
            </PrimaryButton>
          </IslandFooter>
        </IslandContainer>
      </Form>
    )}
  </Formik>
);

export default PasswordView;
