import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoWorkspaceSelectedContent = () => (
  <Container>
    <DoqWithSpeechBubble
      doqType={DoqType.BROADCAST}
      layout={DoqLayout.DIALOG}
      title="Nothing here yet..."
      message="Results based on your selection will be shown here."
    />
  </Container>
);

export default NoWorkspaceSelectedContent;
