import { PredefinedQueryOption } from '@ardoq/api-types';
import { getQueryRepresentation } from 'broadcasts/utils';

type PredefinedQueriesProps = {
  queryOptions: PredefinedQueryOption[];
};

const PredefinedQueries = ({ queryOptions }: PredefinedQueriesProps) => (
  <>{queryOptions.map(getQueryRepresentation).join(', ')}</>
);

export default PredefinedQueries;
