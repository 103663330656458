import {
  ArdoqId,
  BooleanOperator,
  Operator,
  QueryBuilderQuery,
  QueryBuilderRow,
  QueryBuilderRule,
} from '@ardoq/api-types';
import { ExecuteAdvancedSearchContext } from 'search/AdvancedSearch/types';

export const addOnlyInWorkspacesQuery = (
  payload: ExecuteAdvancedSearchContext,
  workspaceIds: ArdoqId[]
): ExecuteAdvancedSearchContext => {
  return {
    ...payload,
    query: {
      ...payload.query,
      rules: [
        payload.query.rules[0],
        {
          condition: BooleanOperator.AND,
          rules: [
            payload.query.rules[1],
            {
              condition: BooleanOperator.OR,
              rules: workspaceIds.map(_id => {
                return {
                  field: 'rootWorkspace',
                  id: 'rootWorkspace',
                  input: 'text',
                  operator: Operator.EQUAL,
                  type: 'string',
                  value: _id,
                } satisfies QueryBuilderRow;
              }),
            } satisfies QueryBuilderRow,
          ],
        },
      ],
    } as QueryBuilderQuery,
  };
};

export const addOnlyRootComponentsQuery = (
  payload: ExecuteAdvancedSearchContext
): ExecuteAdvancedSearchContext => ({
  ...payload,
  query: {
    ...payload.query,
    rules: [
      payload.query.rules[0],
      {
        condition: BooleanOperator.AND,
        rules: [
          payload.query.rules[1],
          {
            field: 'parent',
            id: 'parent',
            input: 'text',
            operator: Operator.IS_EMPTY,
            type: 'string',
            value: null,
          } satisfies QueryBuilderRule,
        ],
      },
    ],
  } as QueryBuilderQuery,
});
