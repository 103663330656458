import { StepState } from '@ardoq/steppers';
import { TransferState } from 'integrations/common/streams/transferState/types';
import { Maybe } from '@ardoq/common-helpers';
import {
  isDryRunOutOfDate,
  isSuccessTransfer,
  successStateToDryRunStatus,
} from 'integrations/common/streams/transferState/utils';
import {
  SavedTransferConfig,
  SelectionResponseTable,
} from '@ardoq/api-types/integrations';
import { TabularMapping } from 'integrations/common/streams/tabularMappings/types';
import { ImportRoute } from 'integrations/common/navigation/types';
import { UploadInfo } from './streams/upload/types';
import { excelDictionary } from 'integrations/common/dictionary';

type Props = {
  tabularMapping: TabularMapping;
  transferState: TransferState;
  uploadStatus: UploadInfo['status'];
};

export const getFilteredSheetTables = (tables: SelectionResponseTable[]) =>
  tables.filter(({ name }) => name !== '!Read First!');

const isConfigureStepAvailable = ({ uploadStatus }: Props) => {
  return uploadStatus === 'Success';
};

const isReviewStepAvailable = (props: Props) => {
  return (
    isConfigureStepAvailable(props) &&
    !isDryRunOutOfDate(props.transferState, props.tabularMapping)
  );
};

const isTransferStepAvailable = (props: Props) => {
  return isReviewStepAvailable(props) && isSuccessTransfer(props.transferState);
};

const STEP_AVAILABILITY: Record<ImportRoute, (props: Props) => boolean> = {
  [ImportRoute.SELECT_DATA]: () => true,
  [ImportRoute.CONFIGURE]: isConfigureStepAvailable,
  [ImportRoute.REVIEW]: isReviewStepAvailable,
  [ImportRoute.IMPORT_AND_SCHEDULE]: isTransferStepAvailable,
};

export const isStepAvailable = (path: ImportRoute, props: Props): boolean => {
  return STEP_AVAILABILITY[path](props);
};

export const getImporterTitle = ({
  filename,
  selectedTransferConfigId,
  transferConfigs,
}: {
  filename?: string;
  selectedTransferConfigId: Maybe<string>;
  transferConfigs: SavedTransferConfig[];
}) => {
  const importerName = `${excelDictionary.name} importer`;

  const transferConfigName = transferConfigs.find(
    ({ _id }) => _id === selectedTransferConfigId
  )?.name;

  if (transferConfigName) {
    return `${importerName} - ${transferConfigName}`;
  }
  if (filename) {
    return `${importerName} - ${filename}`;
  }

  return `${importerName} - untitled`;
};

export const getReviewStepState = ({
  transferState,
}: {
  transferState: TransferState;
}): StepState => {
  if (transferState.requestStatus === 'FAILURE') {
    return StepState.ERROR;
  }

  if (transferState.requestStatus === 'SUCCESS') {
    const status = successStateToDryRunStatus(transferState);
    return status === 'Error' ? StepState.ERROR : StepState.DONE;
  }

  return StepState.ACTIVE;
};

export const getReviewStepPopoverText = (state: StepState) =>
  state === StepState.ERROR
    ? 'Fix the errors in your configuration, then test and review it to continue.'
    : state === StepState.DONE
      ? 'Please trigger the test again to review the up to date results.'
      : null;
