import { AppModules } from 'appContainer/types';
import { AppRouterState } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { map } from 'rxjs';
import { assetsViewState$ } from './viewState$';
import { dispatchAction } from '@ardoq/rxbeach';
import { requestShowAppModule } from 'appContainer/actions';
import { setVisibleAsset } from './actions';
import { AssetsViewState } from '../types';
import { isSupportedAssetType } from 'assets/utils';
import { capitalize } from 'lodash';

export const assetsRoute = new Route<AppRouterState, AssetsViewState>({
  doesLocationMatch: ({ path }) => new RegExp(`/assets/?`).test(path),
  locationToRouterState: ({ path }) => {
    /*
    Path format:    /assets/<asset-type>/<asset-id>
    */

    const parts = path.split('/');
    const assetType = isSupportedAssetType(parts[2]) ? parts[2] : null;

    return {
      appModule: AppModules.ASSETS,
      assetType,
      assetId: parts[3] ?? null,
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.ASSETS,
  routerStateToLocation: ({ assetType, assetId }) => {
    return {
      path: `/${AppModules.ASSETS}/${assetType}/${assetId}`,
      title: assetType
        ? `${capitalize(assetType)} - Overview`
        : 'Assets Overview',
    };
  },
  setApplicationStateFromRoute: ({ assetType, assetId }) => {
    if (!isSupportedAssetType(assetType) || !assetId) {
      dispatchAction(requestShowAppModule({ selectedModule: AppModules.HOME }));
      return;
    }

    dispatchAction(setVisibleAsset({ assetType, assetId }));
    dispatchAction(requestShowAppModule({ selectedModule: AppModules.ASSETS }));
  },
  getPartialRouterStateStream: () =>
    assetsViewState$.pipe(
      map(({ assetType, assetId }) => ({
        assetType,
        assetId,
      }))
    ),
});
