import { FlexBox } from '@ardoq/layout';
import { Maybe } from '@ardoq/common-helpers';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { SelectContainer, SelectorContainer, SubTitle } from './atoms';
import { SecondaryButton } from '@ardoq/button';
import styled from 'styled-components';
import { text2 } from '@ardoq/typography';
import { colors, s32 } from '@ardoq/design-tokens';
import { PopoverContentType, PopoverPlacement } from '@ardoq/popovers';
import { LabelWithPopoverIcon } from 'integrations/common/components/LabelWithPopoverIcon/LabelWithPopoverIcon';
import { ConfigsSelector } from '../configSelector/ConfigSelector';
import { Space } from '@ardoq/style-helpers';

type SelectConnectionsProps = {
  transferConfigs: SavedTransferConfig[];
  selectedConfigId: Maybe<SavedTransferConfig['_id']>;
  popoverContent?: PopoverContentType;
  selectConfigHelperText: string;
  onSelect: (id: string | null) => void;
  onClearConfiguration: () => void;
};

export const SelectConfigs = ({
  transferConfigs,
  selectedConfigId,
  onSelect,
  onClearConfiguration,
  popoverContent,
  selectConfigHelperText,
}: SelectConnectionsProps) => {
  return (
    <Container>
      <SubTitle>Select data</SubTitle>
      <SelectContainer>
        <LabelWithPopoverIcon
          popoverContent={popoverContent}
          preferredPlacement={PopoverPlacement.TOP}
        >
          Use a saved configuration <GreyedOut>(optional)</GreyedOut>
        </LabelWithPopoverIcon>
        <SelectHint>{selectConfigHelperText}</SelectHint>
        <FlexBox gap="medium">
          <GrowingContainer>
            <SelectorContainer>
              <ConfigsSelector
                configs={transferConfigs}
                selectedConfigId={selectedConfigId}
                onSelect={onSelect}
                placeholder="Type and select saved configurations"
                isOptional={true}
              />
            </SelectorContainer>
          </GrowingContainer>
          <SecondaryButton
            isDisabled={!selectedConfigId}
            onClick={onClearConfiguration}
          >
            Clear configurations
          </SecondaryButton>
        </FlexBox>
      </SelectContainer>
    </Container>
  );
};

const GrowingContainer = styled.div`
  flex-grow: 2;
`;

const SelectHint = styled.div`
  ${text2};
  color: ${colors.grey50};
`;

const GreyedOut = styled.span`
  color: ${colors.grey50};
`;

const Container = styled(Space).attrs({ $gap: 's16', $isVertical: true })`
  background-color: white;
  width: 100%;
  padding: ${s32};
`;
