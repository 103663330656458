import { APIFieldAttributes, ArdoqId } from '@ardoq/api-types';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { tagInterface } from '@ardoq/tag-interface';
import { fieldInterface } from '@ardoq/field-interface';
import {
  DatasetRelatedData,
  DatasetRelatedGroupingOptions,
  PerspectivesEditorState,
  PerspectivesReducerFn,
} from '../types';
import { streamReducer } from '@ardoq/rxbeach';
import { getAggregatedDateRangeByStartFieldName } from '@ardoq/perspectives';
import { isComponentField as checkIsComponentField } from 'viewpoints/formSections/utils';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';

const getEmptyOptions = (): DatasetRelatedGroupingOptions => ({
  referenceTypeIds: new Set<string>(),
  componentTypeIds: new Set<string>(),
  componentFieldIds: new Set<string>(),
  tagIds: new Set<string>(),
});

const addComponentDetails = (datasetRelatedData: DatasetRelatedData) => {
  const {
    groupingOptionsByWorkspaceId,
    componentIds,
    componentTypeIds,
    componentTypeNames,
  } = datasetRelatedData;

  componentIds.forEach(componentId => {
    const componentData = componentInterface.getComponentData(componentId);
    if (!componentData) {
      return;
    }

    const workspaceId = componentData.rootWorkspace;
    const groupOptions =
      groupingOptionsByWorkspaceId[workspaceId] || getEmptyOptions();

    const componentType = componentInterface.getType(componentId);
    if (componentType) {
      componentTypeIds.add(componentType.id);
      componentTypeNames.add(componentType.name);
      groupOptions.componentTypeIds.add(componentType.id);
    }

    groupingOptionsByWorkspaceId[workspaceId] = groupOptions;
  });
};

const addReferenceDetails = (datasetRelatedData: DatasetRelatedData) => {
  const { groupingOptionsByWorkspaceId, referenceIds, referenceTypeNames } =
    datasetRelatedData;

  referenceIds.forEach(referenceId => {
    const workspaceId = referenceInterface.getRootWorkspaceId(referenceId);
    if (!workspaceId) {
      return;
    }

    const groupOptions =
      groupingOptionsByWorkspaceId[workspaceId] || getEmptyOptions();

    const referenceType = referenceInterface.getModelType(referenceId);

    if (referenceType) {
      groupOptions.referenceTypeIds.add(referenceType.id.toString());
      referenceTypeNames.add(referenceType.name);
    }

    groupingOptionsByWorkspaceId[workspaceId] = groupOptions;
  });
};

const isFieldRelatedToDataset = (
  field: APIFieldAttributes,
  isComponentField: boolean,
  groupOptions: DatasetRelatedGroupingOptions
) => {
  const entityTypeIds = isComponentField
    ? groupOptions.componentTypeIds
    : groupOptions.referenceTypeIds;
  const isGlobal = isComponentField ? field.global : field.globalref;

  return (
    field[isComponentField ? 'componentType' : 'referenceType'].some(type =>
      entityTypeIds.has(type)
    ) || isGlobal
  );
};

const addFieldsDetails = (datasetRelatedData: DatasetRelatedData) => {
  Object.keys(datasetRelatedData.groupingOptionsByWorkspaceId).forEach(
    workspaceId => {
      const workspaceFields =
        fieldInterface.getAllFieldsOfWorkspace(workspaceId);

      if (!workspaceFields?.length) {
        return;
      }

      workspaceFields.forEach(field => {
        const isComponentField = checkIsComponentField(field);

        if (
          !isFieldRelatedToDataset(
            field,
            isComponentField,
            datasetRelatedData.groupingOptionsByWorkspaceId[workspaceId]
          )
        ) {
          return;
        }

        const groupOptions =
          datasetRelatedData.groupingOptionsByWorkspaceId[workspaceId];
        const { name, _id } = field;

        const entityFieldIds = isComponentField
          ? groupOptions.componentFieldIds
          : undefined;

        const entityFieldNames = isComponentField
          ? datasetRelatedData.componentFieldNames
          : datasetRelatedData.referenceFieldNames;

        entityFieldNames.add(name);
        entityFieldIds?.add(_id);

        const aggregatedDateRangeByStartFieldName =
          getAggregatedDateRangeByStartFieldName(name);

        if (aggregatedDateRangeByStartFieldName) {
          entityFieldNames.add(aggregatedDateRangeByStartFieldName);
        }
      });
    }
  );
};

const addTagIds = (datasetRelatedData: DatasetRelatedData) =>
  Object.keys(datasetRelatedData.groupingOptionsByWorkspaceId).forEach(
    workspaceId => {
      const tags = tagInterface.getWorkspaceTags(workspaceId);
      tags.forEach(({ _id }) => {
        datasetRelatedData.tagIds.add(_id);
        datasetRelatedData.groupingOptionsByWorkspaceId[workspaceId].tagIds.add(
          _id
        );
      });
    }
  );

export const getLoadedStateDataDetails = (
  traversalAndSearchState: LoadedGraphWithViewpointMode
): DatasetRelatedData => {
  const datasetRelatedData: DatasetRelatedData = {
    groupingOptionsByWorkspaceId: {},
    tagIds: new Set<ArdoqId>(),
    componentIds: new Set<ArdoqId>(),
    referenceIds: new Set<ArdoqId>(),
    componentTypeIds: new Set<string>(),
    componentTypeNames: new Set<string>(),
    referenceTypeNames: new Set<string>(),
    componentFieldNames: new Set<string>(),
    referenceFieldNames: new Set<string>(),
  };

  const { scopeComponentIds, scopeReferenceIds } = traversalAndSearchState;
  scopeComponentIds.forEach(id => datasetRelatedData.componentIds.add(id));
  scopeReferenceIds.forEach(id => datasetRelatedData.referenceIds.add(id));
  addComponentDetails(datasetRelatedData);
  addReferenceDetails(datasetRelatedData);
  addFieldsDetails(datasetRelatedData);
  addTagIds(datasetRelatedData);

  return datasetRelatedData;
};

const openedDatasetChangeReducerFn: PerspectivesReducerFn<
  LoadedGraphWithViewpointMode
> = (state, traversalAndSearchState) => ({
  ...state,
  datasetRelatedData: getLoadedStateDataDetails(traversalAndSearchState),
});

export const handleDatasetChangeReducer = streamReducer<
  PerspectivesEditorState,
  LoadedGraphWithViewpointMode
>(loadedGraph$, openedDatasetChangeReducerFn);
