import { pluralize } from '@ardoq/common-helpers';
import {
  ConfirmResult,
  ModalLayout,
  ModalSize,
  ModalSubtitle,
  ModalText,
} from '@ardoq/modal';
import { confirm } from '@ardoq/modal';
import { Asset } from '@ardoq/api-types';
import RowIcon from 'components/EntityBrowser/RowIcon';
import { NameCellWrapper } from 'components/EntityBrowser/atoms';
import { getRowTypeByAssetType } from 'components/EntityBrowser/utils';

const SESSION_KEY_MOVE_ASSETS = '[home page] MOVE_ASSETS';

type ConfirmMoveAssetsArgs = {
  assetsIds: string[];
  folderId: string | null;
  assetsById: Record<string, Asset>;
};

const ConfirmMoveAssetsContent = ({
  assetsIds,
  folderId,
  assetsById,
}: ConfirmMoveAssetsArgs) => {
  const targetName =
    folderId === null
      ? 'to the top level'
      : `into the folder ${assetsById[folderId].name}`;
  const text = `Move the following ${pluralize(
    'asset',
    assetsIds.length
  )} ${targetName}?`;

  return (
    <ModalLayout>
      <ModalSubtitle>{text}</ModalSubtitle>
      <ModalText>
        {assetsIds.map(id => {
          const asset = assetsById[id];
          const rowType = getRowTypeByAssetType(asset.meta.assetType, id);
          return (
            <NameCellWrapper key={id}>
              <RowIcon rowType={rowType} />
              <div>{asset.name}</div>
            </NameCellWrapper>
          );
        })}
      </ModalText>
    </ModalLayout>
  );
};

export const confirmMoveAssets = async ({
  assetsIds,
  folderId,
  assetsById,
}: ConfirmMoveAssetsArgs) => {
  const isSuppressConfirmDialog =
    window.sessionStorage.getItem(SESSION_KEY_MOVE_ASSETS) === 'true';

  if (isSuppressConfirmDialog) return true;

  const { rememberAction, confirmed } = (await confirm({
    title: `Move ${pluralize('asset', assetsIds.length)}`,
    body: (
      <ConfirmMoveAssetsContent
        assetsIds={assetsIds}
        assetsById={assetsById}
        folderId={folderId}
      />
    ),
    confirmButtonTitle: 'Move',
    showRememberActionCheckbox: true,
    rememberActionCustomCheckboxText: `Don't ask again in this session.`,
    modalSize: ModalSize.S,
  })) as ConfirmResult;

  if (rememberAction) {
    window.sessionStorage.setItem(SESSION_KEY_MOVE_ASSETS, 'true');
  }

  return confirmed;
};
