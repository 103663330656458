import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { FlexBox } from '@ardoq/layout';
import { LoadDataNotificationProps } from 'components/WorkspaceHierarchies/components/PropTypes';
import styled from 'styled-components';

export const LoadDataNotification = ({
  loadingScopeDataNotification,
  loaderColor = LoaderColor.BRAND,
}: LoadDataNotificationProps) => (
  <DisplayDelayed>
    <FlexBox justify="center" align="center" padding="xlarge">
      <ArdoqLoaderComponent
        loaderColor={loaderColor}
        size={LoaderSize.MEDIUM}
        loaderText={loadingScopeDataNotification}
      />
    </FlexBox>
  </DisplayDelayed>
);

const DisplayDelayed = styled.div`
  opacity: 1;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-name: fadeIn;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
