import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  resetScheduleState,
  setAlreadyExistingSchedule,
  setUpsertedSchedule,
  setLoadedScheduleId,
  SetScheduleFormValuesPayload,
  setScheduleFormValues,
} from './actions';
import { ScheduleFormData } from '@ardoq/api-types';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { Maybe } from '@ardoq/common-helpers';
import { EMPTY, Observable, map, withLatestFrom } from 'rxjs';
import { buildInitialStreamState } from '../utils';
import fp from 'lodash/fp';
import { isIntegrationWithSchedules } from '../schedules/utils';
import currentUser$ from 'streams/currentUser/currentUser$';
import { permissionsOperations } from '@ardoq/access-control';

export type AllScheduleState = Record<IntegrationId, ScheduleState>;

export type ScheduleFormValues = ScheduleFormData & {
  errorMessage: string;
};

export type ScheduleState = {
  loadedScheduleId: string | null;
  scheduleStage: 'scheduled' | 'toBeScheduled' | 'noScheduleAllowed';
  alreadyExistingSchedule: boolean;
  scheduleForm: ScheduleFormValues;
};

export const initialState: AllScheduleState =
  buildInitialStreamState<ScheduleState>(() => ({
    loadedScheduleId: null,
    scheduleStage: 'toBeScheduled',
    alreadyExistingSchedule: false,
    scheduleForm: {
      name: '',
      policy: { type: 'nightly' },
      managesWorkspace: false,
      errorMessage: '',
    },
  }));

const setScheduleFormValuesReducer = (
  state: AllScheduleState,
  { integrationId, scheduleFormValues }: SetScheduleFormValuesPayload
) => {
  return fp.set(
    [integrationId, 'scheduleForm'],
    fp.merge(state[integrationId].scheduleForm || {}, scheduleFormValues),
    state
  );
};

const handleSetScheduleFormValues = reducer<
  AllScheduleState,
  SetScheduleFormValuesPayload
>(setScheduleFormValues, setScheduleFormValuesReducer);

const resetScheduleStateReducer = (
  state: AllScheduleState,
  { integrationId }: Parameters<typeof resetScheduleState>[0]
) => ({
  ...state,
  [integrationId]: initialState[integrationId],
});

const handleRestScheduleState = reducer<
  AllScheduleState,
  Parameters<typeof resetScheduleState>[0]
>(resetScheduleState, resetScheduleStateReducer);

const setLoadedScheduleIdReducer = (
  state: AllScheduleState,
  { scheduleId, integrationId }: Parameters<typeof setLoadedScheduleId>[0]
) => ({
  ...state,
  [integrationId]: { ...state[integrationId], loadedScheduleId: scheduleId },
});

const handleSetLoadedScheduleId = reducer<
  AllScheduleState,
  Parameters<typeof setLoadedScheduleId>[0]
>(setLoadedScheduleId, setLoadedScheduleIdReducer);

const setUpsertedScheduleReducer = (
  state: AllScheduleState,
  { integrationId, scheduleStage }: Parameters<typeof setUpsertedSchedule>[0]
) => ({
  ...state,
  [integrationId]: { ...state[integrationId], scheduleStage },
});

const handleSetUpsertedSchedule = reducer<
  AllScheduleState,
  Parameters<typeof setUpsertedSchedule>[0]
>(setUpsertedSchedule, setUpsertedScheduleReducer);

const setAlreadyExistingScheduleReducer = (
  state: AllScheduleState,
  { isExists, integrationId }: Parameters<typeof setAlreadyExistingSchedule>[0]
) => ({
  ...state,
  [integrationId]: {
    ...state[integrationId],
    alreadyExistingSchedule: isExists,
  },
});

const handleSetAlreadyExistingSchedule = reducer<
  AllScheduleState,
  Parameters<typeof setAlreadyExistingSchedule>[0]
>(setAlreadyExistingSchedule, setAlreadyExistingScheduleReducer);

const reducers = [
  handleSetLoadedScheduleId,
  handleSetUpsertedSchedule,
  handleRestScheduleState,
  handleSetAlreadyExistingSchedule,
  handleSetScheduleFormValues,
];

export const schedule$ = persistentReducedStream(
  `integrationsSchedule$`,
  initialState,
  reducers
);

const integrationToMappingStreams = new Map<
  IntegrationId,
  Observable<ScheduleState>
>();

export const getScheduleStream = (integrationId: Maybe<IntegrationId>) => {
  if (!integrationId) {
    return EMPTY;
  }

  const mappingStream = integrationToMappingStreams.get(integrationId);

  if (mappingStream) {
    return mappingStream;
  }

  const stream$ = schedule$.pipe(
    withLatestFrom(currentUser$),
    map(([schedule, currentUser]) => {
      const isAdmin = permissionsOperations.isOrgAdmin(currentUser);
      const scheduleStage =
        !isIntegrationWithSchedules(integrationId) || !isAdmin
          ? 'noScheduleAllowed'
          : schedule[integrationId].scheduleStage;
      return {
        ...schedule[integrationId],
        scheduleStage,
      };
    })
  );

  integrationToMappingStreams.set(integrationId, stream$);

  return stream$;
};
