import { reducer } from '@ardoq/rxbeach';
import { EmailAudienceDialogStreamShape } from './types';
import { areEmailsValid, toEmailArray } from './utils';
import {
  openExistingEmailAudienceDialog,
  openNewEmailAudienceDialog,
  updateEmails,
} from './actions';

const handleOpenExistingEmailAudienceDialog = (
  state: EmailAudienceDialogStreamShape,
  emails: string[]
) => ({
  ...state,
  emails,
  isEditingExistingAudience: true,
  formIsValid: areEmailsValid(emails),
});

const handleOpenNewEmailAudienceDialog = (
  state: EmailAudienceDialogStreamShape
): EmailAudienceDialogStreamShape => ({
  ...state,
  emails: [],
  isEditingExistingAudience: false,
  formIsValid: false,
});
const handleUpdateEmails = (
  state: EmailAudienceDialogStreamShape,
  emailInput: string
) => {
  const emails = toEmailArray(emailInput);
  return { ...state, emails, formIsValid: areEmailsValid(emails) };
};

export const reducers = [
  reducer(
    openExistingEmailAudienceDialog,
    handleOpenExistingEmailAudienceDialog
  ),
  reducer(openNewEmailAudienceDialog, handleOpenNewEmailAudienceDialog),
  reducer(updateEmails, handleUpdateEmails),
];
