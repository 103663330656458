import { actionCreator } from '@ardoq/rxbeach';
import type {
  CreatePermissionGroupFormState,
  SetFormFieldValuePayload,
} from './types';
import type { ArdoqId } from '@ardoq/api-types';

const DOMAIN = '[permissionGroups]';

export const createPermissionGroup =
  actionCreator<CreatePermissionGroupFormState>(
    `${DOMAIN} CREATE_PERMISSION_GROUP`
  );

export const createPermissionGroupSuccess = actionCreator<ArdoqId>(
  `${DOMAIN} CREATE_PERMISSION_GROUP_SUCCESS`
);

export const createPermissionGroupFailed = actionCreator<string>(
  `${DOMAIN} CREATE_PERMISSION_GROUP_FAILED`
);

export const setFormFieldValue = actionCreator<SetFormFieldValuePayload>(
  `${DOMAIN} CREATE_PERMISSION_GROUP_SET_FORM_FIELD_VALUE`
);

export const formValidationFailed = actionCreator<
  Record<keyof CreatePermissionGroupFormState, string[]>
>(`${DOMAIN} CREATE_PERMISSION_GROUP_FORM_VALIDATION_FAILED`);
