import { ActionButtons, StyledIcon } from './atoms';
import { getCurrentLocale } from '@ardoq/locale';
import { ArdoqId, PersistedBundleShape } from '@ardoq/api-types';
import { DatasourceTable } from '@ardoq/table';
import { IconName } from '@ardoq/icons';
import { formatDateOnly } from '@ardoq/date-time';

type BundleListProps = {
  bundles: (PersistedBundleShape & {
    handleDelete: (id: ArdoqId) => Promise<void>;
    handleCopy: (id: ArdoqId) => void;
    handleEdit: (id: ArdoqId) => void;
  })[];
};

const BundleList = ({ bundles }: BundleListProps) => {
  return (
    <DatasourceTable
      // Quick fix to keep comparability with prior implementation
      // After TS update on DatasourceTable we can't wrap DatasourceTable
      // like styled(DatasourceTable)``
      style={{ marginTop: 25 }}
      dataSource={bundles}
      columns={[
        { title: 'Bundle name', dataIndex: 'name' },
        { title: 'Created by', dataIndex: 'createdByEmail' },
        {
          title: 'Last updated',
          dataIndex: 'lastUpdated',
          valueRender: value => formatDateOnly(value, getCurrentLocale()),
        },
        {
          title: 'Actions',
          dataIndex: '_id',
          valueRender: (value, dataSourceRow) => {
            const onDeleteClick = () => dataSourceRow.handleDelete(value);
            const onCopyClick = () => dataSourceRow.handleCopy(value);
            const onEditClick = () => dataSourceRow.handleEdit(value);
            return (
              <ActionButtons>
                <StyledIcon
                  tooltipText="Delete"
                  onClick={onDeleteClick}
                  iconName={IconName.DELETE}
                />
                <StyledIcon
                  tooltipText="Copy to org"
                  onClick={onCopyClick}
                  iconName={IconName.FILE_COPY}
                />
                <StyledIcon
                  tooltipText="Edit"
                  onClick={onEditClick}
                  iconName={IconName.EDIT}
                />
              </ActionButtons>
            );
          },
        },
      ]}
    />
  );
};

export default BundleList;
