import {
  PerspectivesConditionalFormatting,
  ConditionalFormattingProps,
} from '@ardoq/perspectives';
import { ViewpointBuilderFooter } from 'viewpointBuilder/components/ViewpointBuilderFooter';
import { PrimaryButtonConfig } from 'viewpointBuilder/openViewpointBuilder/getPrimaryButtonConfig';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { Island, PageBody } from '@ardoq/page-layout';
import { Box } from '@ardoq/layout';

type FormattingContentProps = {
  closeViewpointBuilder: () => void;
  state: ConditionalFormattingProps;
  primaryButtonConfig: PrimaryButtonConfig;
  // Commands are part of the state
};

export const ConditionalFormattingContent = ({
  state,
  closeViewpointBuilder,
  primaryButtonConfig,
}: FormattingContentProps) => {
  return (
    <PageBody
      footerContent={
        <ViewpointBuilderFooter
          closeViewpointBuilder={closeViewpointBuilder}
          primaryButtonConfig={primaryButtonConfig}
        />
      }
    >
      <Box padding="xlarge">
        <Island
          title="Conditional formatting"
          subtitle="Make patterns and trends more apparent with automated color formatting based on rules you set here."
          knowledgeBaseLink={KnowledgeBaseLink.CONDITIONAL_FORMATTING}
        >
          <PerspectivesConditionalFormatting {...state} />
        </Island>
      </Box>
    </PageBody>
  );
};
