import { ImmutableCodeEditor } from '@ardoq/code-editor';
import { updateCalculatedFieldValue } from 'search/actions';
import { truncate } from 'utils/stringUtils';
import { CalculatedFieldOption } from 'streams/queries/calculatedFieldOptions/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { QueryEditorNamespace } from 'search/QueryEditor/queryEditor$';
import { Paragraph } from '@ardoq/typography';
import { Select } from '@ardoq/select';
import { FormWrapper } from '@ardoq/forms';
import { Stack } from '@ardoq/layout';

interface CalculatedFieldOptionsProps {
  calculatedFieldOptions: CalculatedFieldOption[];
  queryParamsIds: string[];
  calculatedFieldSelectedOption: string | null;
}

const deriveCalculatedFieldProps = ({
  calculatedFieldOptions,
  queryParamsIds,
  calculatedFieldSelectedOption,
}: CalculatedFieldOptionsProps): {
  idsString: string;
  workspaceName: string;
  numberOfIds: number;
} => {
  if (
    !calculatedFieldOptions ||
    !queryParamsIds ||
    !calculatedFieldSelectedOption
  ) {
    return { idsString: '', workspaceName: '', numberOfIds: 0 };
  }
  const workspace = calculatedFieldOptions.find(
    ({ value }) => value === calculatedFieldSelectedOption
  );
  const workspaceName = workspace ? workspace.label : '';
  const idsString = queryParamsIds
    ? truncate(queryParamsIds.map(id => `"${id}"`).join(', '), 60)
    : '';
  const numberOfIds = queryParamsIds.length;
  return { idsString, workspaceName, numberOfIds };
};

const CalculatedFieldOptions = ({
  calculatedFieldOptions,
  queryParamsIds,
  calculatedFieldSelectedOption,
}: CalculatedFieldOptionsProps) => {
  const { idsString, workspaceName, numberOfIds } = deriveCalculatedFieldProps({
    calculatedFieldOptions,
    queryParamsIds,
    calculatedFieldSelectedOption,
  });
  const hasSingleOption = calculatedFieldOptions.length === 1;
  return (
    <>
      <Stack gap="small">
        <Paragraph variant="text2Bold">Use ids from workspace</Paragraph>
        <FormWrapper>
          <Select
            isDisabled={hasSingleOption}
            popoverContent={
              hasSingleOption ? 'This field is only used in 1 workspace' : null
            }
            options={calculatedFieldOptions}
            value={calculatedFieldSelectedOption}
            onValueChange={(selectedValue: string | null) => {
              if (!selectedValue) return;

              const selectedOption = calculatedFieldOptions.find(
                ({ value }) => value === selectedValue
              );
              const ids = selectedOption ? selectedOption.ids : [];
              return dispatchAction(
                updateCalculatedFieldValue({
                  ids,
                  calculatedFieldSelectedOption: selectedValue,
                }),
                QueryEditorNamespace.CALCULATED_FIELD_QUERY
              );
            }}
          />
        </FormWrapper>
        <ImmutableCodeEditor
          value={`ids = [${idsString}] // Using ${numberOfIds} IDs from Workspace ${workspaceName}`}
        />
      </Stack>
    </>
  );
};

export default CalculatedFieldOptions;
