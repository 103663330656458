import { actionCreator } from '@ardoq/rxbeach';
import { ResourceGroup } from '@ardoq/api-types/integrations';

const NAMESPACE = '[integrations azure] Resource Groups';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export type FetchResourceGroupsSuccessPayload = {
  resourceGroups: ResourceGroup[];
};

export const fetchResourceGroupsSuccess =
  actionCreator<FetchResourceGroupsSuccessPayload>(
    getActionName('FETCH_RESOURCE_GROUPS_SUCCESS')
  );

export const fetchResourceGroupsFailure = actionCreator(
  getActionName('FETCH_RESOURCE_GROUPS_FAILURE')
);

export type UpdateResourceGroupsPayload = {
  resourceGroups: ResourceGroup[];
};

export const updateResourceGroups = actionCreator<UpdateResourceGroupsPayload>(
  getActionName('UPDATE_RESOURCE_GROUPS')
);

export type UpdateSelectedResourceGroupNamesPayload = {
  names: string[];
};

export const updateSelectedResourceGroupNames =
  actionCreator<UpdateSelectedResourceGroupNamesPayload>(
    getActionName('UPDATE_SELECTED_RESOURCE_NAMES')
  );

export const resetResourceGroups = actionCreator(
  getActionName('RESET_RESOURCE_GROUPS')
);
