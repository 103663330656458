import { SecondaryButton } from '@ardoq/button';
import styled from 'styled-components';
import { s16 } from '@ardoq/design-tokens';
import { showSavedViewModifiersDialog } from 'viewDeprecation/components/SavedSlideSettingsDialog';
import { ViewModifier } from 'viewDeprecation/types';

const SavedViewModifiersButtonContainer = styled.div`
  margin: ${s16} 0;
`;

type SavedViewModifiersProps = {
  viewModifiers: ViewModifier[];
};

const SavedViewModifiers = ({ viewModifiers }: SavedViewModifiersProps) =>
  viewModifiers.length !== 0 ? (
    <SavedViewModifiersButtonContainer>
      <SecondaryButton
        onClick={() => showSavedViewModifiersDialog(viewModifiers)}
      >
        Saved view modifiers
      </SecondaryButton>
    </SavedViewModifiersButtonContainer>
  ) : (
    <div>There are no view modifiers saved in this slide.</div>
  );

export default SavedViewModifiers;
