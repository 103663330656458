import { RefObject } from 'react';
import { connect } from '@ardoq/rxbeach';
import styled from 'styled-components';
import { TablesSelection, TablesSelectionBaseTable } from '@ardoq/integrations';
import { ServiceNowTable } from '@ardoq/api-types/integrations';
import { SelectionState } from '../../streams/selectionState/types';
import { viewModel$ } from './viewModel$';
import { AsyncStatus } from 'integrations/common/types/api';
import fp from 'lodash/fp';
export const TableSelector = ({
  tables = [],
  selectionState,
  tablesStatus,
  onSelectTable,
  onUnselectTable,
  selectionRef,
}: {
  tables: ServiceNowTable[];
  selectionState: SelectionState;
  tablesStatus: AsyncStatus;
  onSelectTable: (table: TablesSelectionBaseTable) => void;
  onUnselectTable: (table: TablesSelectionBaseTable) => void;
  selectionRef: RefObject<HTMLDivElement>;
}) => {
  const datasource = fp
    .sortBy(t => t.label, tables)
    .map(table => ({
      canRead: table.canRead,
      canWrite: table.canWrite,
      canDelete: table.canDelete,
      canCreate: table.canCreate,
      label: table.label,
      id: table.name,
      name: table.name,
    }));
  return (
    <TablesSelectionContainer>
      <TablesSelection
        dataSource={datasource}
        onSelectTable={onSelectTable}
        onUnselectTable={onUnselectTable}
        selectedRowId={selectionState.currentTableId}
        selectedTables={selectionState.tables}
        disabled={tablesStatus === 'INIT' || tablesStatus === 'LOADING'}
        selectionRef={selectionRef}
      />
    </TablesSelectionContainer>
  );
};

const TablesSelectionContainer = styled.div`
  flex: 1;
  min-width: 400px;
`;

export default connect(TableSelector, viewModel$);
