import { distinctUntilChanged, map, Observable } from 'rxjs';
import currentUser$ from 'streams/currentUser/currentUser$';
import { ShowFilteredComponentsChangedPayload } from 'components/WorkspaceHierarchies/actions/navigatorActions';
import { isEqual } from 'lodash';

export const showFilteredComponentsSetting$: Observable<ShowFilteredComponentsChangedPayload> =
  currentUser$.pipe(
    map(({ settings }) => ({
      showFilteredComponents: Boolean(settings.generic_showFilteredComponents),
    })),
    distinctUntilChanged(isEqual)
  );
