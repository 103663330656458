import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { ComponentTypesState } from './types';
import { getComponentTypes, getComponentTypesFinished } from './actions';
import { componentTypesOperations } from './componentTypesOperations';
import { MetaModelComponentType } from '@ardoq/api-types';

const defaultState: ComponentTypesState = {
  componentTypes: [],
  isLoading: false,
};

const reducers = [
  reducer<ComponentTypesState>(
    getComponentTypes,
    componentTypesOperations.setLoadingState
  ),
  reducer<ComponentTypesState, MetaModelComponentType[]>(
    getComponentTypesFinished,
    componentTypesOperations.setComponentTypesAfterLoading
  ),
];

const componentTypes$ = persistentReducedStream(
  'componentTypes$',
  defaultState,
  reducers
);

export default componentTypes$;
