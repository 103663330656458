import {
  routine,
  dispatchAction,
  ofType,
  collectRoutines,
  extractPayload,
} from '@ardoq/rxbeach';
import {
  getComponentOverviewPageData,
  setComponentOverviewData,
  setComponentOverviewPageError,
} from './actions';
import { exhaustMap, map, tap } from 'rxjs';
import { api, traversalApi, componentApi } from '@ardoq/api';
import { enhanceScopeData } from '@ardoq/renderers';
import { getTraversalWithStartSetAndWildcardTripleOfType } from 'appLayout/ardoqStudio/utils';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';

const handleGetComponentOverviewPageData = routine(
  ofType(getComponentOverviewPageData),
  extractPayload(),
  exhaustMap(componentApi.fetch),
  map(api.logErrorIfNeeded),
  exhaustMap(async componentApiResponse => {
    if (!componentApiResponse) {
      dispatchAction(setComponentOverviewPageError());
      return;
    }

    return getTraversalWithStartSetAndWildcardTripleOfType(
      [componentApiResponse._id],
      componentApiResponse
    );
  }),
  excludeNullAndUndefined(),
  exhaustMap(reqArgs => traversalApi.loadTraversal(reqArgs)),
  map(api.logErrorIfNeeded),
  tap(traversalResponse => {
    if (!traversalResponse) {
      dispatchAction(setComponentOverviewPageError());
      return;
    }

    dispatchAction(
      setComponentOverviewData(enhanceScopeData(traversalResponse.scopeData))
    );
  })
);

export const componentOverviewPageRoutines = collectRoutines(
  handleGetComponentOverviewPageData
);
