import type {
  BasicModel,
  ComponentBackboneModel,
  FieldBackboneModel,
  Reference as ReferenceBackboneModel,
} from 'aqTypes';
import type {
  APIFieldType,
  APIReferenceType,
  APIScopeData,
  APIWorkspaceAttributes,
  ArdoqId,
  FilterAttributes,
} from '@ardoq/api-types';
import { ContextSort } from '@ardoq/common-helpers';
import {
  ComponentTypeWithoutChildren,
  ContextShape,
  DynamicFilterState,
} from '@ardoq/data-model';
import { ScenarioModeState } from 'scope/types';
import { PermissionContext } from '@ardoq/access-control';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { Locale } from '@ardoq/locale';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';

export type ComponentSelectionConfig = {
  dynamicFilterStates: DynamicFilterState[];
  globalFilterAttributes: FilterAttributes[];
  isShowAllComponents: boolean;
  selectedComponentId: ArdoqId;
  sort: ContextSort;
  /**
   * The workspace id is an implicit dependency, it needs to trigger
   * recalculation when it changes.
   */
  workspaceId: ArdoqId;
  // TODO: Traversals can change without modifying the fields above, so we need
  // a mechanism for it to force reselection of the components.
};

export type GridEditorComponents = ComponentBackboneModel[];

export type VoidFn = () => void;

export enum VIEW_TYPES {
  COMPONENT = 'component',
  REFERENCE = 'reference',
}

export type ComponentAttributes = Record<string, unknown>;

export type WorkspaceIndex = Record<
  ArdoqId,
  APIWorkspaceAttributes | undefined
>;

export type CSSAbsolutePositionProps = {
  position: 'absolute';
  top: string;
  left: string;
  width: string;
  height: string;
};
type GridEditorPaneStyle =
  | CSSAbsolutePositionProps
  | { display: Extract<React.CSSProperties['display'], 'none'> };

type CurrentDropdownOpenerSource = number | null;

export type AnyTrackingMetadata = Record<string, unknown>;
export type GlobalTrackingMetadata = Record<string, unknown>;

export type ComponentHierarchy = {
  /**
   * Index of componentId -> componentIds[]
   * Contains ALL workspace components
   * WARN: Do not rely on Object.keys() from this index, may be outdated
   */
  childIndex: Record<string, string[] | undefined> & { root: string[] };

  /**
   * Index of componentId -> parentComponentId | 'root'
   * WARN: Do not rely on Object.keys() from this index, may be outdated
   */
  parentIndex: Record<ArdoqId, ArdoqId | 'root' | undefined>;

  /**
   * Index of componentId -> BackboneComponentModel
   * Contains ALL workspace components, and will by definition have models for
   * all ids in childIndex.
   */
  componentMap: Map<string, ComponentBackboneModel>;
  /**
   * All workspace components. An optimization to avoid iterating over all
   * components when "show all components" is selected
   */
  components: ComponentBackboneModel[];
};

export type GridEditorProps = {
  componentSelectionConfig: ComponentSelectionConfig;
  context: ContextShape;
  gridEditorModel: GridEditorState;
  gridEditorFields: GridEditorFields;
  gridEditorComponents: GridEditorComponents;
  activeScenarioState: ScenarioModeState;
  scopeData: APIScopeData;
  locale: Locale;
  workspaceComponentsModel: ComponentHierarchy;
  subdivisionsContext: SubdivisionsContext;
  permissionContext: PermissionContext;
  globalTrackingMetadata: GlobalTrackingMetadata;
};

export type GridEditorSharedState = {
  trackingClickNamespace: string;

  /**
   * Add/edit field inline in Grid Editor
   */
  fieldEditor: { editor: 'add-existing-field' } | { editor: 'create-field' };
  columnSort?: ContextSort;

  readonly isGridEditorTableLoaded: boolean;
  readonly activeViewType: VIEW_TYPES;
  readonly isShowAllComponents: boolean;
  readonly disabledWorkspaces: Set<ArdoqId>;
  readonly disabledFields: Set<string>;
  readonly currentDropdownOpenerSource: CurrentDropdownOpenerSource;
};

/**
 * GridEditorFrame
 *
 * Used to represent the 3 "frames" or "runtimes" the grid editor state may run:
 * - DOCKED: Code is running in an iframe nested in ardoq-front
 * - POPOUT: Code is running in a separate window to ardoq-front
 * - ARDOQ_FRONT_BRIDGE: Code is running in ardoq-front, aka. "coordinator"
 *
 * The key to understand this is that DOCKED and POPOUT are UI states dictating
 * where the Grid Editor should be visible.
 * - `activeFrameId: DOCKED` visible as a docked pane inside ardoq-front.
 * - `activeFrameId: POPOUT` visible as a popout
 *
 * Either UI state communicate with the "coordinator" which is ardoq-front,
 * which is represented by ARDOQ_FRONT_BRIDGE. Its role is to manage the two
 * frames:
 * - opening/closing the DOCKED and POPOUT editors
 * - sync state when going between one activeFrameId to the other
 * - create UI space and set the z-index position of the DOCKED editor
 * - communicate user context such as workspaceId and componentId as the
 *   user interacts with ardoq-front.
 */
export enum GridEditorFrame {
  DOCKED = 'docked',
  POPOUT = 'popout',
  ARDOQ_FRONT_BRIDGE = 'ardoq-front-bridge',
}
export enum DockedGridEditorVisibility {
  CLOSED = 'closed',
  EXPANDED = 'expanded',
  FULLSCREEN = 'fullscreen',
}
export enum DockedGridEditorFocus {
  FOREGROUND = 'foreground',
  BACKGROUND = 'background',
}

type BasePopoutGridEditorState = GridEditorSharedState & {
  /**
   * Controls where the Grid Editor is visible to the user, and can only take
   * one of *two* values:
   * - DOCKED
   * - POPOUT
   *
   * Note that:
   * - if frameId === DOCKED it means activeFrameId === DOCKED
   * - if frameId === POPOUT it means activeFrameId === POPOUT
   * It is ONLY frameId === ARDOQ_FRONT_BRIDGE that frameId !== activeFrameId
   */
  activeFrameId: GridEditorFrame.POPOUT;
};

type BaseDockedGridEditorState = GridEditorSharedState &
  Readonly<{
    /**
     * Controls where the Grid Editor is visible to the user, and can only take
     * one of *two* values:
     * - DOCKED
     * - POPOUT
     *
     * Note that:
     * - if frameId === DOCKED it means activeFrameId === DOCKED
     * - if frameId === POPOUT it means activeFrameId === POPOUT
     * It is ONLY frameId === ARDOQ_FRONT_BRIDGE that frameId !== activeFrameId
     */
    activeFrameId: GridEditorFrame.DOCKED;

    /**
     * Tracks how the docked editor is visible inside the main app UI.
     * - CLOSED
     * - EXPANDED
     * - FULLSCREEN
     */
    dockedGridEditorVisibility: DockedGridEditorVisibility;

    /**
     * Controls the z-index of the docked editor's iframe.
     * - BACKGROUND
     * - FOREGROUND
     *
     * See DockedGridEditorLayoutContainer for more context.
     */
    dockedGridEditorFocus: DockedGridEditorFocus;

    /**
     * Controls how the DockedGridEditor is sized and positioned
     *
     * Since `dockedPaneFocus` determines whether the transparent iframe is shown
     * above or below the rest of the UI, `dockedEditorPaneStyle` controls a sub-
     * container of the iframe to absolutely position it inside the iframe.
     *
     * Based on `dockedVisibility` this iframe sub-container positions itself
     * differently to show the Grid Editor:
     * - `fullscreen`: Utilize the entire width and height of the iframe
     * - `expanded`: Using absolute positioning place and size the content so
     *   that it is visible through a "hole" in the rest of the UI, making it
     *   visible even when "backgrounded".
     * - `closed`: Does not matter.
     */
    dockedEditorPaneStyle: GridEditorPaneStyle;
  }>;

export type DockedGridEditorState =
  | (BaseDockedGridEditorState & {
      frameId: GridEditorFrame.DOCKED;
    })
  | (BaseDockedGridEditorState & {
      frameId: GridEditorFrame.ARDOQ_FRONT_BRIDGE;
    });

export type PopoutGridEditorState =
  | (BasePopoutGridEditorState & {
      frameId: GridEditorFrame.POPOUT;
    })
  | (BasePopoutGridEditorState & {
      frameId: GridEditorFrame.ARDOQ_FRONT_BRIDGE;
    });

export type GridEditorState = DockedGridEditorState | PopoutGridEditorState;

export type GridEditorSeparator = { isSeparator: true; label?: string };

export type GridEditorDisplayableField = Readonly<{
  /**
   * Controls whether the field is displayed
   */
  checked: boolean;
  description?: string;
  name: string;
  label: string;
  isSeparator?: false;
  fieldType?: GridEditorFieldType | APIFieldType;
  field?: FieldBackboneModel;
}>;

export type GridEditorField = GridEditorDisplayableField | GridEditorSeparator;

export interface GridColumnSetting {
  name: string;
  data: (row: any, value: any) => any;
  source: any;
  type: any;
  validator: any;
  width?: number;
  readOnly?: boolean;
  sortFunction?: SortFunction;
  /**
   * String used to display tooltip when hovering column headers
   * Use "" if no tooltip text.
   * */
  tooltipText: string;
}
export interface GridEditorPosition {
  col: number;
  row: number;
}
export interface GridEditorCoordinates {
  startRow: number;
  startCol: number;
  endRow: number;
  endCol: number;
}
export interface HotHook {
  hook: string;
  callback: (...args: any[]) => void;
}
interface GridEditorCellSetting {
  readOnly?: boolean;
  className?: string;
  renderer?: any;
  valid?: boolean;
  name: string;
  label: string;
  isSeparator?: boolean;
  field?: FieldBackboneModel;
  /**
   * String used to display tooltip when hovering cells
   * Use "" if no tooltip text.
   * */
  tooltipText: string;
}
export type GetCellSettings = (
  row: number,
  column: number
) => GridEditorCellSetting | unknown;
export enum FieldState {
  NOT_APPLICABLE,
  LOCKED,
  READ_ONLY,
  READ_WRITE,
  CALCULATED,
  EXTERNALLY_MANAGED,
}
export interface ArdoqReadWriteWrapper {
  isLastParent: boolean;
  isChild: boolean;
  getId(): ArdoqId;
  getProperty(fieldName: string): any;
  setProperty(fieldName: string, value: any): void;
  getComponent(): ComponentBackboneModel | null;
  getFieldState(fieldName: string): FieldState;
  getTooltip(fieldName: string): string | null;
  isValid(fieldName: string): boolean;
  setIsChild(component: ComponentBackboneModel | null): void;
  destroy(): void;
  _setMembers(list: [string, any][]): void;
  _getPropertyDefault(object: BasicModel, fieldName: string): any;
  _setPropertyDefault(
    object: ComponentBackboneModel | ReferenceBackboneModel,
    fieldName: string,
    value: any
  ): void;
}
export type GridColumnSettingsWithHeaders = {
  colHeaders: string[];
  columns: GridColumnSetting[];
};

export interface ArdoqBaseContext {
  getCellSettings: GetCellSettings;
  handleSourceTargetFlipped(reference: ReferenceBackboneModel): void;
  activate(): void;
  deactivate(): void;
  getColumnSettings(
    activeFields: GridEditorDisplayableField[]
  ): GridColumnSettingsWithHeaders;
  hasComponentInContext(component: ComponentBackboneModel): boolean;
  handleRemoveRows(start: GridEditorPosition, end: GridEditorPosition): void;
  setFieldValuesToDefault(
    start: GridEditorPosition,
    end: GridEditorPosition
  ): void;
  setFieldValuesToNull(
    start: GridEditorPosition,
    end: GridEditorPosition
  ): void;
  getTableData(currentData?: ArdoqReadWriteWrapper[]): ArdoqReadWriteWrapper[];
  getDataSchema(): () => ArdoqReadWriteWrapper;
  canHaveSpareRow(): boolean;
  getWrapperAtRow(rowIndex: number): ArdoqReadWriteWrapper | null;
}

export type CreateReferenceAttributes = {
  source?: ComponentBackboneModel | null;
  target?: ComponentBackboneModel | null;
  type?: number | string | null;
  displayText?: string;
  order?: number;
  description?: string;
};
export enum MenuEntryType {
  ACTION = 1,
  CHECKBOX = 2,
  SEPARATOR = 3,
}

export type HandsontableChanges = any[][];

export interface HandsontableRange {
  from: {
    row: number;
    col: number;
  };
}

export interface MenuEntry {
  label: string;
  name: string;
  type?: MenuEntryType;
  checked?: boolean;
  className?: string;
  isSelected?: boolean;
  noHighlight?: boolean;
  action?: VoidFunction;
}

export enum GridEditorFieldType {
  ARDOQ_OID = 'ArdoqOID',
  CREATED_AT = 'ArdoqCreatedAt',
  UPDATED_AT = 'ArdoqUpdatedAt',
  COMPONENT_NAME = 'ArdoqComponentName',
  PARENT_COMPONENT = 'ArdoqParentComponent',
  COMPONENT_TYPE = 'ArdoqComponentType',
  TAGS = 'ArdoqTags',
  LOCKED_STATE = 'ArdoqLockedState',
  SOURCE_COMPONENT_NAME = 'ArdoqSrcComponentName',
  SOURCE_COMPONENT_TYPE = 'ArdoqSrcComponentType',
  SOURCE_COMPONENT_PARENT = 'ArdoqSrcComponentParent',
  REFERENCE_TYPE = 'ArdoqReferenceType',
  TARGET_COMPONENT_NAME = 'ArdoqTargetComponentName',
  TARGET_COMPONENT_TYPE = 'ArdoqTargetComponentType',
  TARGET_COMPONENT_PARENT = 'ArdoqTargetComponentParent',
}

export type GetNameAtRow = (rowIndex: number) => string | null;
export type SortFunction = (
  sortOrder?: boolean
) => (
  [rowIndexA, valueA]: [number, any],
  [rowIndexB, valueB]: [number, any]
) => number;

export enum TableHeaderRowViewMode {
  SELECT_WORKSPACE = 1,
  LOAD_WORKSPACE = 2,
}

export type DropdownKeyboardHandlerState = {
  cursor: number;
};

export enum DropdownOpenerSource {
  SRC_EDITOR_TOOLBAR_SELECT_FIELD,
  SRC_WORKSPACE_SELECTOR,
  SRC_TOGGLE_FULLSCREEN,
  SRC_DELETE_CONFIRMATION,
  SRC_EDIT_CONFIRMATION,
  SRC_EDITOR_TOOLBAR_ADD_FIELD,
}

type ActiveField = GridEditorDisplayableField & { checked: boolean };

export type GridEditorFields = {
  fields: (GridEditorSeparator | ActiveField)[];
  typeIds: string[];
  activeViewTypes: Map<
    string,
    APIReferenceType | ComponentTypeWithoutChildren | null
  >;
  displayableFields: GridEditorDisplayableField[];
  fieldsByType: Map<string, Set<string>>;
  activeFields: ActiveField[];
  nameFieldMap: Map<string, FieldBackboneModel>;
  nameFieldTypeMap: Map<string, APIFieldType>;
};

/**
 * A data structure that allow us to detect if changes in the app's context
 * has affected scopeData without having to calculate all of scope data again.
 */
export type ScopeDataDependencies = {
  context: ContextShape;
  activeScenarioState: ScenarioModeState;
  loadedGraph: LoadedGraphWithViewpointMode;
};

export type ScopeDataDependenciesWithScopeData = ScopeDataDependencies & {
  scopeData?: APIScopeData;
};
