import { ViewIds } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import { exhaustiveCheck } from '@ardoq/common-helpers';
import { isViewWithoutFullyMultiLabelsSupport } from './traversals/getViewpointModeSupportedViews';

export const getRestrictedMultilabelFormattingWarningMessage = (
  isViewSelectorWarning: boolean,
  viewId: ViewIds
) => {
  if (!isViewWithoutFullyMultiLabelsSupport(viewId)) {
    return undefined;
  }

  switch (viewId) {
    case ViewIds.BLOCKS:
      if (isViewSelectorWarning) {
        return 'Only the first field for each reference type will show in this view style.';
      }

      return hasFeature(Features.INTERACTIVE_BLOCKS_IN_ARDOQ_STUDIO)
        ? 'Interactive Blocks view style only shows the first field for each reference type.'
        : undefined;
    case ViewIds.RELATIONSHIPS_3:
      return 'Relationships view style only shows the first field for each component and reference type.';
    case ViewIds.TIMELINE:
      return isViewSelectorWarning
        ? 'Reference labels are not shown in this view style.'
        : 'Reference labels are not shown in Timeline view.';
    default:
      return exhaustiveCheck(viewId);
  }
};
