import { useEffect, useState } from 'react';
import { modal } from '@ardoq/modal';
import ViewpointBuilder from 'viewpointBuilder/ViewpointBuilder';
import {
  OpenViewpointBuilderArgs,
  ViewpointBuilderViewState,
} from 'viewpointBuilder/types';
import { viewpointBuilderCommands } from 'viewpointBuilder/viewpointBuilderCommands';
import { getContextConfig } from './getContextConfig';
import { getInitialViewpointBuilderState } from './getInitialViewpointBuilderState';
import { subscribeToViewpointBuilderStreamAndRoutines } from './subscribeToViewpointBuilderStreamAndRoutines';
import { dispatchAction } from '@ardoq/rxbeach';
import { viewpointBuilderOpened } from './viewpointBuilderOpened$';

export const openViewpointBuilder = ({
  activeTab = 'SELECT_CONTEXT_COMPONENT_INSTANCES_TAB',
  context = 'editSubgraph',
  initialConfiguration,
  folderId,
}: OpenViewpointBuilderArgs) => {
  return modal(closeModal => {
    dispatchAction(viewpointBuilderOpened());

    // TODO AM: try to make a custom hook from useState and useEffect
    const [state, setState] = useState<ViewpointBuilderViewState>(
      getInitialViewpointBuilderState
    );

    useEffect(() => {
      const unsubscribeStreamAndRoutinesAndCleanUp =
        subscribeToViewpointBuilderStreamAndRoutines({
          activeTab,
          context,
          initialConfiguration,
          folderId: folderId ?? null,
          viewpointBuilderCommands,
          setState,
        });
      return unsubscribeStreamAndRoutinesAndCleanUp;
    }, [setState]);

    return (
      <ViewpointBuilder
        closeViewpointBuilder={() => closeModal(false)}
        state={state}
        commands={viewpointBuilderCommands}
        contextConfig={getContextConfig(context)}
      />
    );
  });
};
