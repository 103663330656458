import { actionCreator } from '@ardoq/rxbeach';
import { FormattingRule, PerspectiveTab } from '@ardoq/perspectives-sidebar';
import { PerspectiveGroupingRule } from '@ardoq/perspectives';
import type { LabelFormattingInfo } from '@ardoq/data-model';

export const setActiveTab = actionCreator<PerspectiveTab>(
  '[perspectiveEditorSidebar] SET_ACTIVE_TAB'
);

// this action is used only for tests
export const resetPerspectiveEditorState = actionCreator(
  '[perspectiveEditorSidebar] RESET_STATE'
);

/**
 * GROUP TAB
 */
export const reorderGroupingRules = actionCreator<PerspectiveGroupingRule[]>(
  '[perspectiveEditorSidebar] REORDERED_GROUPING_RULES'
);

export const deleteGroupingRule = actionCreator<string>(
  '[perspectiveEditorSidebar] DELETE_GROUPING_RULE'
);

export type UpdateGroupingRulePayload = {
  updatedGroupingRuleId: string;
  updatedGroupingRule: Partial<PerspectiveGroupingRule>;
};
export const updateGroupingRule = actionCreator<UpdateGroupingRulePayload>(
  '[perspectiveEditorSidebar] UPDATE_GROUPING_RULE'
);

export const createGroupingRule = actionCreator<PerspectiveGroupingRule>(
  '[perspectiveEditorSidebar] CREATE_GROUPING_RULE'
);

/**
 * FORMATTING TAB
 */

export const createLabelFormatting = actionCreator<LabelFormattingInfo>(
  '[perspectiveEditorSidebar] CREATE_LABEL_FORMATTING'
);
export const reorderLabelFormatting = actionCreator<LabelFormattingInfo[]>(
  '[perspectivesEditorSidebar] REORDER_LABEL_FORMATTING'
);
export const deleteLabelFormatting = actionCreator<number>(
  '[perspectivesEditorSidebar] DELETE_LABEL_FORMATTING'
);
type UpdateLabelFormattingAtArgs = {
  index: number;
} & Partial<LabelFormattingInfo>;
export const updateLabelFormattingAt =
  actionCreator<UpdateLabelFormattingAtArgs>(
    '[perspectiveEditorSidebar] UPDATE_LABEL_FORMATTING_AT'
  );
export const deleteFormattingRule = actionCreator<string>(
  '[perspectiveEditorSidebar] DELETE_FORMATTING_RULE'
);

export const reorderFormattingRules = actionCreator<string[]>(
  '[perspectiveEditorSidebar] REORDER_FORMATTING_RULES'
);

export type UpdateFormattingRulePayload = {
  updatedFormattingRuleId: string;
  updatedFormattingRule: Partial<FormattingRule>;
};
export const updateFormattingRule = actionCreator<UpdateFormattingRulePayload>(
  '[perspectiveEditorSidebar] UPDATE_FORMATTING_RULE'
);

export const createFormattingRule = actionCreator<FormattingRule>(
  '[perspectiveEditorSidebar] CREATE_FORMATTING_RULE'
);

export const showReferenceTypeToggled = actionCreator<boolean>(
  '[perspectiveEditorSidebar] SHOW_REFERENCE_TYPE_TOGGLED'
);
