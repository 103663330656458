import { action$, extractPayload, ofType } from '@ardoq/rxbeach';
import { NodeModel } from '../models/types';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { NavigatorConfiguration, NavigatorLayoutState } from '../types';
import { contextMenuClicked } from '../actions/navigatorActions';
import { targetManager } from '../targetManager';
import {
  COMPONENT_ITEM_CLASS_NAME,
  WORKSPACE_ITEM_CLASS_NAME,
  SCENARIO_ITEM_CLASS_NAME,
} from '../consts';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';
export const getNavigatorContextMenuRoutine = (
  navigator$: Observable<NavigatorLayoutState>,
  navigatorConfiguration: NavigatorConfiguration
) =>
  action$.pipe(
    ofType(contextMenuClicked),
    extractPayload(),
    withLatestFrom(navigator$),
    tap(
      ([event, { tree, isViewpointMode, selection, activeScenarioState }]) => {
        const { eventHandlers } = navigatorConfiguration;
        event.preventDefault();
        const { target, className } = targetManager.getTarget(
          event,
          'contextmenu'
        );
        const node = target ? tree.getNodeByDomNode(target) : null;
        if (!node) {
          return;
        }

        if (className === COMPONENT_ITEM_CLASS_NAME) {
          return createContextMenuForComponents(
            event,
            node,
            selection,
            isViewpointMode,
            eventHandlers,
            tree.getNextSelectionCandidate
          );
        }

        if (className === WORKSPACE_ITEM_CLASS_NAME) {
          return createContextMenuForWorkspace(
            event,
            node,
            isViewpointMode,
            activeScenarioOperations.getActiveScenarioId(activeScenarioState),
            eventHandlers
          );
        }
        if (className === SCENARIO_ITEM_CLASS_NAME) {
          return createContextMenuForScenario(
            event,
            activeScenarioOperations.getActiveScenarioId(activeScenarioState),
            eventHandlers
          );
        }
      }
    )
  );

const createContextMenuForComponents = (
  event: MouseEvent,
  node: NodeModel,
  selection: string[],
  isViewpointMode: boolean,
  eventHandlers: NavigatorConfiguration['eventHandlers'],
  getNextTreeSelectionCandidate: () => string | null
) => {
  const multiselectIds = getMultiselectForContextMenu(node, selection);
  const selectedComponentId = [node.id];
  const componentIds = multiselectIds || selectedComponentId || [];
  eventHandlers.onComponentContextMenu(
    event,
    componentIds,
    isViewpointMode,
    node.id,
    getNextTreeSelectionCandidate
  );
};

const createContextMenuForWorkspace = (
  event: MouseEvent,
  node: NodeModel,
  isViewpointMode: boolean,
  scenarioId: string | null,
  eventHandlers: NavigatorConfiguration['eventHandlers']
) =>
  eventHandlers.onWorkspaceContextmenu(
    event,
    [node.id],
    scenarioId,
    isViewpointMode
  );

const getMultiselectForContextMenu = (
  targetNode: NodeModel | null,
  selection: string[]
) => {
  if (selection.length === 0) {
    return null;
  }
  const actionInSelection =
    targetNode && selection.some(id => id === targetNode.id);
  return actionInSelection ? selection : null;
};

const createContextMenuForScenario = (
  event: MouseEvent,
  scenarioId: string | null,
  eventHandlers: NavigatorConfiguration['eventHandlers']
) => eventHandlers.onScenarioContextmenu(event, scenarioId);
