import { APIFieldAttributes, APIFieldType } from '@ardoq/api-types';
import { Checkbox, NumberInput, TextInput } from '@ardoq/forms';
import { Multiselect, Select, SelectOption } from '@ardoq/select';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { HiddenFieldDateTime } from './HiddenFieldDateTime';
import { HiddenFieldUser } from './HiddenFieldUser';
import { RichTextEditorTransition } from '@ardoq/rich-text-editor-2024';

type FieldEditorProperties = {
  field: APIFieldAttributes;
  value: any;
  onChange: (value: any) => void;
  errorMessage?: string;
  onBlur?: () => void;
  hintMessage?: string;
};

export default function FieldEditor({
  field,
  value = '',
  onChange,
  errorMessage,
  onBlur = () => null,
  hintMessage,
}: FieldEditorProperties) {
  const type = field.type;
  const commonProps = {
    value,
    errorMessage,
  };

  switch (type) {
    case APIFieldType.LIST: {
      const options = fieldInterface
        .getAllowedValues(field._id)
        .map(label => ({ value: label, label }));
      return (
        <Select
          {...commonProps}
          value={options.find(({ value: optionVal }) => value === optionVal)}
          onValueChange={value => onChange(value)}
          options={options}
          isClearable={false}
          dataTestId="list-select"
          onBlur={onBlur}
          hintMessage={hintMessage}
        />
      );
    }

    case APIFieldType.NUMBER: {
      return (
        <NumberInput
          {...commonProps}
          placeholder="Number"
          onValueChange={number => onChange(number)}
          dataTestId="number-input"
          onBlur={onBlur}
          helperText={hintMessage}
        />
      );
    }

    case APIFieldType.DATE_ONLY:
    case APIFieldType.DATE_TIME: {
      return (
        <HiddenFieldDateTime
          {...commonProps}
          type={type}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
    }

    case APIFieldType.CHECKBOX: {
      return (
        <Checkbox
          isChecked={Boolean(value)}
          onChange={() => {
            onChange(!value);
            onBlur();
          }}
          errorMessage={errorMessage}
        />
      );
    }

    case APIFieldType.SELECT_MULTIPLE_LIST: {
      const options = fieldInterface
        .getAllowedValues(field._id)
        .map(label => ({ value: label, label }));
      const selectMultiOnChange = (
        selectedOptions: SelectOption<string>[] | null
      ) =>
        selectedOptions &&
        onChange(selectedOptions.map(option => option.value));
      return (
        <Multiselect
          {...commonProps}
          value={value || []}
          onChange={selectMultiOnChange}
          options={options}
          isClearable={false}
          isMulti
          backspaceRemovesValue
          placeholder="Select options..."
          closeMenuOnSelect={false}
          dataTestId="multiple-list-select"
          onBlur={onBlur}
          hintMessage={hintMessage}
        />
      );
    }

    case APIFieldType.TEXT_AREA: {
      return (
        <RichTextEditorTransition
          content={value || ''}
          onInputChange={onChange}
          dataTestId="rich-text-editor"
          onBlur={onBlur}
        />
      );
    }

    case APIFieldType.USER: {
      return (
        <HiddenFieldUser
          {...commonProps}
          onChange={onChange}
          onBlur={onBlur}
          hintMessage={hintMessage}
        />
      );
    }

    default: {
      return (
        <TextInput
          {...commonProps}
          placeholder={type}
          onChange={event => onChange(event.target.value)}
          dataTestId="text-input"
          onBlur={onBlur}
          helperText={hintMessage}
        />
      );
    }
  }
}
