import { reducer } from '@ardoq/rxbeach';
import { setFilter } from './actions';
import { ProgressDetailsState } from './types';
import { fetchBroadcastInstances } from 'broadcasts/actions';

const handleSetFilter = (
  state: ProgressDetailsState,
  filter: string
): ProgressDetailsState => ({
  ...state,
  filter,
});

const handleFetchBroadcastInstances = (
  state: ProgressDetailsState
): ProgressDetailsState => ({
  ...state,
  filter: '',
});

export const reducers = [
  reducer(setFilter, handleSetFilter),
  reducer(fetchBroadcastInstances, handleFetchBroadcastInstances),
];
