import {
  APIResourcePermissionAttributes,
  ArdoqId,
  Zone,
  ZoneConfiguredComponentType,
} from '@ardoq/api-types';
import {
  AddNewZoneGroupPermissionActionPayload,
  ChangeZoneGroupPermissionActionPayload,
  DeleteZoneGroupPermissionActionPayload,
  UpdateFieldsInComponentTypeInZoneActionPayload,
  ZonesViewState,
} from './types';
import { subdivisionsOperations } from '@ardoq/subdivisions';
import { SubdivisionsStreamShape } from 'streams/subdivisions/types';
import { SubdivisionNavigationState } from 'subdivisionEditor/navigation/types';
import { SelectAllFieldsPayload } from './actions';
import { zonesOperations } from '../operations';
import { SearchByTypes } from '../components/ComponentTypeFieldsGroupSearch';

const initializePermissionsByZoneIdState = (
  currentState: ZonesViewState,
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>
) => ({
  ...currentState,
  permissionsByZoneId: {
    ...permissionsByZoneId,
    ...currentState.permissionsByZoneId,
  },
});

const updatePermissionLevelsForGroupInZonePermission = (
  currentState: ZonesViewState,
  {
    permissionLevels,
    groupName,
    zonePermission,
  }: ChangeZoneGroupPermissionActionPayload
) => ({
  ...currentState,
  permissionsByZoneId: {
    ...currentState.permissionsByZoneId,
    [zonePermission.resource]: {
      ...zonePermission,
      groups: zonePermission.groups.map(group =>
        group.name === groupName
          ? { ...group, permissions: permissionLevels }
          : group
      ),
    },
  },
});

const removeGroupFromZonePermission = (
  currentState: ZonesViewState,
  { groupName, zonePermission }: DeleteZoneGroupPermissionActionPayload
) => ({
  ...currentState,
  permissionsByZoneId: {
    ...currentState.permissionsByZoneId,
    [zonePermission.resource]: {
      ...zonePermission,
      groups: zonePermission.groups.filter(group => group.name !== groupName),
    },
  },
});

const addNewGroupToZonePermission = (
  currentState: ZonesViewState,
  { permission, zonePermission }: AddNewZoneGroupPermissionActionPayload
) => ({
  ...currentState,
  permissionsByZoneId: {
    ...currentState.permissionsByZoneId,
    [zonePermission.resource]: {
      ...zonePermission,
      groups: [...zonePermission.groups, permission],
    },
  },
});

const initializeZonesInSubdivision = (
  currentState: ZonesViewState,
  [subdivisionsState, { subdivisionId }]: [
    SubdivisionsStreamShape,
    SubdivisionNavigationState,
  ]
) => {
  const zonesList = subdivisionsOperations.getZonesInSubdivision(
    subdivisionsState,
    subdivisionId
  );

  const zonesById = zonesList.reduce(
    (acc, zone) => ({
      ...acc,
      [zone._id]: currentState.zonesById[zone._id] || zone,
    }),
    {}
  );
  return {
    ...currentState,
    zonesById: {
      ...zonesById,
    },
    sortedZonesIds: zonesList.map(zone => zone._id),
  };
};

const updateComponentTypeFieldsGroupSearchKey = (
  currentState: ZonesViewState,
  newSearchKey: string
) => ({
  ...currentState,
  componentTypeFieldsGroupSearch: {
    ...currentState.componentTypeFieldsGroupSearch,
    searchKey: newSearchKey,
  },
});

const updateComponentTypeFieldsGroupSearchBy = (
  currentState: ZonesViewState,
  newSearchBy: SearchByTypes
): ZonesViewState => ({
  ...currentState,
  componentTypeFieldsGroupSearch: {
    ...currentState.componentTypeFieldsGroupSearch,
    searchBy: newSearchBy,
  },
});

const updateFieldsInComponentTypeInZone = (
  currentState: ZonesViewState,
  {
    zone,
    componentTypeName,
    enabledFields,
    allFields,
    shownFields,
  }: UpdateFieldsInComponentTypeInZoneActionPayload
) => {
  const newConfiguredComponentType = {
    componentTypeName,
    fields: allFields.map(field => {
      const isShown = shownFields.some(f => f.name === field.name);

      return {
        name: field.name,
        enabled: isShown ? enabledFields.includes(field.name) : field.enabled,
      };
    }),
  };

  const configuredComponentTypes = zone.configuredComponentTypes.filter(
    c => c.componentTypeName !== componentTypeName
  );
  configuredComponentTypes.push(newConfiguredComponentType);

  return {
    ...currentState,
    zonesById: {
      ...currentState.zonesById,
      [zone._id]: {
        ...zone,
        configuredComponentTypes,
      },
    },
  };
};

const setAllChosenFields = (
  currentState: ZonesViewState,
  {
    zone,
    selectedComponentTypes,
    componentTypeFieldsGroupSearch,
  }: SelectAllFieldsPayload,
  isSelect: boolean
) => {
  const componentTypesWithUpdatedFields: ZoneConfiguredComponentType[] =
    selectedComponentTypes.map(({ name, fieldNames }) => {
      const configuredComponentType = zone.configuredComponentTypes.find(
        ct => ct.componentTypeName === name
      );
      const fieldsToChange = fieldNames.filter(fieldName =>
        zonesOperations.fieldMatchesSearch(
          fieldName,
          componentTypeFieldsGroupSearch
        )
      );
      const updatedFields = (
        configuredComponentType?.fields.filter(
          field => !fieldsToChange.includes(field.name)
        ) || []
      ).concat(
        fieldsToChange.map(fieldName => ({
          name: fieldName,
          enabled: isSelect,
        }))
      );

      return {
        componentTypeName: name,
        fields: updatedFields,
      } satisfies ZoneConfiguredComponentType;
    });

  const updatedComponentTypes = zone.configuredComponentTypes
    .filter(
      ct =>
        !componentTypesWithUpdatedFields.some(
          x => x.componentTypeName === ct.componentTypeName
        )
    )
    .concat(componentTypesWithUpdatedFields);

  return {
    ...currentState,
    zonesById: {
      ...currentState.zonesById,
      [zone._id]: {
        ...zone,
        configuredComponentTypes: updatedComponentTypes,
      },
    },
  };
};

const selectAllFieldsReducer = (
  currentState: ZonesViewState,
  props: SelectAllFieldsPayload
): ZonesViewState => {
  return setAllChosenFields(currentState, props, true);
};

const deselectAllFieldsReducer = (
  currentState: ZonesViewState,
  props: SelectAllFieldsPayload
): ZonesViewState => {
  return setAllChosenFields(currentState, props, false);
};

const changeZoneName = (
  currentState: ZonesViewState,
  { newName, zone }: { newName: string; zone: Zone }
): ZonesViewState => {
  const zoneWithNewName = {
    ...zone,
    name: newName,
  };

  return {
    ...currentState,
    zonesById: {
      ...currentState.zonesById,
      [zone._id]: zoneWithNewName,
    },
  };
};

const setEditingZoneName = (
  currentState: ZonesViewState,
  zoneId: ArdoqId
): ZonesViewState => {
  return {
    ...currentState,
    editingZoneNames: {
      ...currentState.editingZoneNames,
      [zoneId]: true,
    },
  };
};

const resetEditingZoneName = (
  currentState: ZonesViewState,
  zoneId: ArdoqId
): ZonesViewState => {
  return {
    ...currentState,
    editingZoneNames: {
      ...currentState.editingZoneNames,
      [zoneId]: false,
    },
  };
};

export const zonesViewStateOperations = {
  initializePermissionsByZoneIdState,
  updatePermissionLevelsForGroupInZonePermission,
  removeGroupFromZonePermission,
  addNewGroupToZonePermission,
  initializeZonesInSubdivision,
  updateComponentTypeFieldsGroupSearchKey,
  updateComponentTypeFieldsGroupSearchBy,
  updateFieldsInComponentTypeInZone,
  selectAllFieldsReducer,
  deselectAllFieldsReducer,
  changeZoneName,
  setEditingZoneName,
  resetEditingZoneName,
};
