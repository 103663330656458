import { RowType } from '@ardoq/table';
import { AssetType, APIViewpointAttributes } from '@ardoq/api-types';
import { TraversalRow } from 'components/AssetsBrowser/types';
import { traversalAccessControlInterface } from 'resourcePermissions/accessControlHelpers/traversals';
import { PermissionContext } from '@ardoq/access-control';

const toTraversalRepresentationInOverview = (
  traversal: APIViewpointAttributes,
  favoriteTraversalIds: string[],
  permissionContext: PermissionContext
): TraversalRow => {
  return {
    ...traversal,
    viewName: traversal.viewName,
    rowType: RowType.TRAVERSAL,
    meta: {
      favorite: favoriteTraversalIds.includes(traversal._id),
      assetType: AssetType.TRAVERSAL,
      folderId: null,
      level: 0,
      permissions: {
        canManagePermissions: traversalAccessControlInterface.canAdminTraversal(
          permissionContext,
          traversal._id
        ),
        canUpdate: traversalAccessControlInterface.canEditTraversal(
          permissionContext,
          traversal._id
        ),
        canDelete: traversalAccessControlInterface.canEditTraversal(
          permissionContext,
          traversal._id
        ),
        canCopy:
          traversalAccessControlInterface.canCreateTraversal(permissionContext),
      },
    }, // needed by the EntityBrowser
  };
};

export const traversalOperations = {
  toTraversalRepresentationInOverview,
};
