import { actionCreator } from '@ardoq/rxbeach';

export const showFeatureHighlight = actionCreator<{
  readonly referenceElementSelector: string;
  readonly tooltipText: string;
}>('[featureHighlight] SHOW_FEATURE_HIGHLIGHT');

export const updatePosition = actionCreator<{
  readonly referenceBox: ClientRect | DOMRect;
  readonly tooltipBox: ClientRect | DOMRect;
}>('[featureHighlight] UPDATE_POSITION');

export const resetPosition = actionCreator('[featureHighlight] RESET_POSITION');

export const hideFeatureHighlight = actionCreator(
  '[featureHighlight] HIDE_FEATURE_HIGHLIGHT'
);
