import { defaultState, reducers } from './reducers';
import { persistentReducedStream } from '@ardoq/rxbeach';

const broadcastsNavigation$ = persistentReducedStream(
  'broadcastsNavigation$',
  defaultState,
  reducers
);

export default broadcastsNavigation$;
