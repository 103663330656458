import { Icon, IconName, IconSize } from '@ardoq/icons';
import { SubdivisionsPopoverContainer } from './Atoms';
import { colors } from '@ardoq/design-tokens';
import { WithPopover, closePopover } from '@ardoq/popovers';
import { ButtonSize, SecondaryButton } from '@ardoq/button';
import { dispatchAction } from '@ardoq/rxbeach';
import { showManageSubdivisionMembershipModal } from 'subdivisionEditor/manageMembershipModal/actions';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { Box, FlexBox } from '@ardoq/layout';

export const WithSubdivisionPopover = ({ id }: { id: string }) => (
  <WithPopover
    content={() => (
      <SubdivisionsPopoverContainer gap="medium">
        <Box>{SUBDIVISIONS_STRINGS.MISSING_SUBDIVISION_WARNING}</Box>
        <FlexBox justify="flex-end">
          <SecondaryButton
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              closePopover();
              dispatchAction(showManageSubdivisionMembershipModal(id));
            }}
          >
            Manage permissions
          </SecondaryButton>
        </FlexBox>
      </SubdivisionsPopoverContainer>
    )}
  >
    <Icon
      iconName={IconName.INFO}
      color={colors.blue80}
      iconSize={IconSize.SMALL}
    />
  </WithPopover>
);
