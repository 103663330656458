import { useState } from 'react';
import { connect } from '@ardoq/rxbeach';
import {
  ExpandableFieldSelector as FieldSelectorComponent,
  TablesSelectionTablePayload,
} from '@ardoq/integrations';
import { viewModel$ } from './viewModel';
import { SelectorField } from 'integrations/common/types/common';

const texts = {
  noAvailableTable:
    'Select at least one resource group to view the available resource types.',
  subtitle: '',
  searchPlaceholder: 'Search for resource types',
  noTableTitle: 'No table selected',
  noTableSubtitle: 'Select a table to view the fields',
  labelColumnTitle: 'Name',
  idColumnTitle: '',
  availableTitle: 'Available resource types',
  selectedTitle: 'My selection',
  searchHelperText:
    'Available resource types include those supported by Ardoq and those in the selected resource groups. Selected resource types will be displayed as tables in the Configure Data step.',
};

const filterFields = (
  search: string,
  fields: SelectorField[]
): SelectorField[] => {
  const sanitizedSearch = search.toLowerCase().trim();
  const searchTerms = sanitizedSearch.split(/[\s_]+/);

  return fields.filter(f => {
    const name = f.name.toLowerCase();
    const label = f.label.toLowerCase();

    return searchTerms.every(
      term => name.includes(term) || label.includes(term)
    );
  });
};

export const ResourceTypeSelector = ({
  currentTable,
  availableFields,
  selectedFields,
  onClickRemoveAll,
  onClickAddAll,
  onClickRemove,
  onClickAdd,
}: {
  currentTable: TablesSelectionTablePayload | null;
  availableFields: SelectorField[] | null;
  selectedFields: SelectorField[];
  onClickRemoveAll: () => void;
  onClickAddAll: () => void;
  onClickRemove: (field: SelectorField) => void;
  onClickAdd: (field: SelectorField) => void;
}) => {
  const [search, setSearch] = useState('');

  const filteredSelectedFields = filterFields(search, selectedFields);
  const filteredAvailableFields =
    availableFields && filterFields(search, availableFields);

  return (
    <>
      <FieldSelectorComponent
        isLoading={false}
        selectedFields={filteredSelectedFields}
        availableFields={filteredAvailableFields}
        tableName={currentTable?.label || null}
        search={search}
        onSearch={setSearch}
        onClickRemoveAll={onClickRemoveAll}
        onClickAddAll={onClickAddAll}
        onClickRemove={onClickRemove}
        onClickAdd={onClickAdd}
        selectedTotal={selectedFields.length}
        texts={texts}
        isHideable={false}
      />
    </>
  );
};

export default connect(ResourceTypeSelector, viewModel$);
