import { FilterAttributes, ViewIds } from '@ardoq/api-types';
import Context from '../../context';
import Filters from '../../collections/filters';
import { loadedState$ } from '../../loadedState/loadedState$';
import { groupingRuleInterface } from '../../modelInterface/groupingRules/groupingRuleInterface';
import { omit } from 'lodash';
import viewInterface from '../views/mainContent/viewInterface';
import { loadedStateOperations } from '../../loadedState/loadedStateOperations';

const getFilters = () =>
  Filters.filter(f => f !== Filters.workspaceFilter).map<FilterAttributes>(
    ({ attributes }) => omit(attributes, 'tag')
  );

export const createBookmarkFromCurrentView = (
  viewId: ViewIds,
  name: string
) => {
  const contextWorkspaceId = Context.activeWorkspaceId()!;
  const contextComponentId = Context.componentId();
  const mainView = viewInterface.getViewById(viewId)!;
  const viewstate = mainView.getViewstate ? mainView.getViewstate() : {};
  const { attr: sortAttr, order: sortOrder } = Context.getSort();
  return {
    view: viewId,
    description: '',
    name,
    isInVisualDiffMode: false,
    viewstate,
    sortAttr,
    sortOrder,
    scenarioId: null,
    workspaceId: contextWorkspaceId,
    componentId: contextComponentId,
    groupBys: groupingRuleInterface.getActiveGroupingAttributes(),
    filters: {
      advancedFilters: getFilters(),
      workspaceFilter: null,
    },
    loadedStates: loadedState$.state.map(
      loadedStateOperations.getLoadedStateParamsFromLoadedState
    ),
  };
};
