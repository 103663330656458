import { DoqFace, DoqSize, DoqType } from '@ardoq/doq';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';

const ThumbnailWrapper = styled.div`
  height: inherit;
  position: relative;
  width: inherit;
`;

const Thumbnail = styled.div`
  position: absolute;
  overflow: hidden;
  background-color: ${colors.surfaceDefault};
  width: 160px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.borderSubtle00};
  &:first-child {
    top: 7%;
    right: 5%;
  }
  &:nth-child(2) {
    top: 5%;
    left: 20%;
  }
  &:nth-child(3) {
    top: 3%;
    left: 5%;
  }
`;

const Image = styled.img`
  height: 110px;
  text-indent: -9999px;
`;

type PresentationCardThumbnailProps = {
  thumbnails: { doq?: DoqType; image?: string }[];
};

export const PresentationCardThumbnail = ({
  thumbnails,
}: PresentationCardThumbnailProps) => (
  <ThumbnailWrapper>
    {thumbnails.map(({ doq, image }, index) => (
      <Thumbnail key={index}>
        {doq ? (
          <DoqFace doqSize={DoqSize.EXTRA_SMALL} doqType={doq} />
        ) : (
          <Image src={image} />
        )}
      </Thumbnail>
    ))}
  </ThumbnailWrapper>
);
