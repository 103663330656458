import { colors } from '@ardoq/design-tokens';
import styled, { css } from 'styled-components';

export const Label = styled.span<{
  $isInContext?: boolean;
  $borderingType?: boolean;
  $isDisabled?: boolean;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  &&& {
    padding-right: 0;
  }
  ${({ $isInContext }) =>
    $isInContext
      ? css`
          &&& {
            font-weight: bold;
          }
        `
      : undefined}

  ${({ $borderingType, $isInContext }) =>
    $borderingType === false && !$isInContext
      ? css`
          color: ${colors.textSubtle};
        `
      : undefined}

  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          color: ${colors.textDisabled};
        `
      : undefined}
`;
