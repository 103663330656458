import { BroadcastSchedule, BroadcastContentType } from '@ardoq/api-types';
import ScheduleRecurrenceSelector from './ScheduleRecurrenceSelector';
import ScheduleReminder from './ScheduleReminder';
import ScheduleInterval from './ScheduleInterval';
import ScheduleStartDate from './ScheduleStartDate';
import ScheduleEndDate from 'broadcasts/broadcastBuilder/broadcastSchedulerAndReminderForm/endDate/ScheduleEndDate';
import { isRepeatingSchedule } from 'broadcasts/utils';
import { FormWrapper, Label, Switch } from '@ardoq/forms';
import { currentDateTimeISO } from '@ardoq/date-time';
import { Island } from '@ardoq/page-layout';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateSendOnWeekends } from 'broadcasts/actions';
import { Stack } from '@ardoq/layout';

type BroadcastSchedulerAndReminderFormProps = {
  readonly schedule: BroadcastSchedule;
  readonly contentType: BroadcastContentType;
};

const BroadcastSchedulerAndReminderForm = ({
  schedule,
  contentType,
}: BroadcastSchedulerAndReminderFormProps) => {
  return (
    <Island
      data-intercom-target="broadcast-schedule"
      title="5. Select schedule & reminder"
      subtitle={
        contentType === BroadcastContentType.REPORT
          ? 'Choose how often your broadcast will be sent out. For repeating broadcasts, reports will be recalculated at every interval.'
          : 'Decide whether to send a one-off or repeating broadcast. To send a repeating broadcast, select an interval and an end date (optional). If you have set a filter or used a query to build an audience, components in scope and the audience will be recalculated each time the broadcast is run.'
      }
    >
      <FormWrapper $gap="s32">
        <FormWrapper $gap="s16">
          <ScheduleRecurrenceSelector isRepeating={schedule.repeating} />
          <ScheduleStartDate
            startDate={schedule.startDate}
            minDate={currentDateTimeISO()}
          />
          {isRepeatingSchedule(schedule) && (
            <ScheduleInterval
              interval={schedule.interval}
              contentType={contentType}
            />
          )}
          {isRepeatingSchedule(schedule) && (
            <ScheduleEndDate
              endDate={schedule.endDate ?? null}
              minDate={schedule.startDate ?? currentDateTimeISO()}
            />
          )}
        </FormWrapper>
        <ScheduleReminder reminderInDays={schedule.reminderInDays} />
        <Stack gap="small">
          <Label>Weekends</Label>
          <Switch
            label="Send on weekends?"
            name="sendOnWeekends"
            isChecked={Boolean(schedule.sendOnWeekends)}
            onChange={sendOnWeekends =>
              dispatchAction(updateSendOnWeekends(sendOnWeekends))
            }
            helperText="If enabled, broadcasts and reminders will be sent on weekends. Otherwise they will be sent on the next applicable weekday, according to your schedule."
          />
        </Stack>
      </FormWrapper>
    </Island>
  );
};

export default BroadcastSchedulerAndReminderForm;
