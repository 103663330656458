import { dispatchAction, reducer } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import { logError } from '@ardoq/logging';
import {
  RelationshipDiagramCollapseGroupPayload,
  ViewIdPayload,
  relationshipDiagramCollapseAllGroups,
  relationshipDiagramExpandAllGroups,
  relationshipDiagramToggleCollapseGroup,
} from '../actions';
import type {
  GraphItem,
  RelationshipDiagramViewModelStreamState,
} from '@ardoq/graph';
import buildViewModel from './buildViewModel';
import { isDescendant } from 'modelInterface/graph/utils';
import { WILDCARD } from '@ardoq/common-helpers';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import { NotifyViewLoadingPayload, notifyViewLoading } from '../../actions';
import { ViewIds } from '@ardoq/api-types';

interface ProcessAddedNodesReducerState {
  add: string[];
  update: string[];
  expandedStateChangingGroupId: string;
  byId: Map<string, GraphItem>;
}
const processAddedNodes = (
  state: ProcessAddedNodesReducerState,
  nodeId: string
) => {
  const isNewItem =
    nodeId !== state.expandedStateChangingGroupId &&
    ((state.expandedStateChangingGroupId === WILDCARD &&
      state.byId.get(nodeId)!.parent) ||
      isDescendant({
        parentId: state.expandedStateChangingGroupId,
        item: state.byId.get(nodeId)!,
      }));
  if (isNewItem) {
    state.add.push(nodeId);
  } else {
    state.update.push(nodeId);
  }
  return state;
};
type RebuildViewModelArgs = {
  state: RelationshipDiagramViewModelStreamState;
  collapsedGroupIds: string[];
  expandedStateChangingGroupId: string;
};
const rebuildViewModel = ({
  state,
  collapsedGroupIds,
  expandedStateChangingGroupId,
}: RebuildViewModelArgs) => {
  const viewModel = buildViewModel({
    ...state.streamState,
    viewSettings: { ...state.viewSettings, collapsedGroupIds },
    expandedStateChangingGroupId,
    shouldCollapseGraph: true,
    loadedGraph: state.loadedGraph,
    viewId: state.viewId,
  });
  const { nodes, groups } = viewModel;

  const { add: addedNodes, update: updatedNodes } = nodes.add.reduce(
    processAddedNodes,
    {
      add: [],
      update: nodes.update,
      byId: nodes.byId,
      expandedStateChangingGroupId,
    }
  );
  const { add: addedGroups, update: updatedGroups } = groups.add.reduce(
    processAddedNodes,
    {
      add: [],
      update: groups.update,
      byId: groups.byId,
      expandedStateChangingGroupId,
    }
  );
  return {
    ...viewModel,
    nodes: { ...nodes, add: addedNodes, update: updatedNodes },
    groups: {
      ...groups,
      add: addedGroups,
      update: updatedGroups,
    },
    expandedStateChangingGroupId,
  };
};

export const isViewBusy = <
  TState extends RelationshipDiagramViewModelStreamState,
>() => {
  const isViewBusyReducer = (
    state: TState,
    { isBusy }: NotifyViewLoadingPayload
  ): TState => ({
    ...state,
    streamState: { ...state.streamState, isBusy, skipRender: true },
  });
  return reducer(notifyViewLoading, isViewBusyReducer);
};

const COLLAPSED_GROUPS_LIMIT = 2000;
export const toggleCollapseGroup = <
  TState extends RelationshipDiagramViewModelStreamState,
>() => {
  const toggleCollapseGroupReducer = (
    state: TState,
    { viewId, groupId }: RelationshipDiagramCollapseGroupPayload,
    namespace?: string
  ) => {
    const isBusy = viewId === ViewIds.BLOCK_DIAGRAM && state.streamState.isBusy;

    if (namespace !== viewId || isBusy) {
      return {
        ...state,
        streamState: { ...state.streamState, skipRender: true },
      };
    }
    const {
      viewModel: { groups },
      streamState: { rawGraph },
    } = state;
    const group = groups.byId.get(groupId);
    if (!group) {
      logError(Error('Cannot find group after toggling expansion.'), null, {
        groupId,
        groupIds: Array.from(groups.byId.keys()),
        isScenarioMode: isScenarioMode(),
      });
      return {
        ...state,
        streamState: { ...state.streamState, skipRender: false },
      };
    }
    group.collapsed = !group.collapsed;

    const previousCollapsedGroupIds =
      state.viewSettings.collapsedGroupIds.includes(WILDCARD) &&
      !group.collapsed
        ? Array.from(rawGraph.groupMap.keys())
        : state.viewSettings.collapsedGroupIds;
    const collapsedGroupIds = group.collapsed
      ? [...previousCollapsedGroupIds, group.id]
      : previousCollapsedGroupIds.filter(id => id !== group.id);
    const { rawGraph: newRawGraph, ...viewModel } = rebuildViewModel({
      state,
      collapsedGroupIds,
      expandedStateChangingGroupId: group.id,
    });
    const newState = {
      ...state,
      viewSettings: { ...state.viewSettings, collapsedGroupIds },
      viewModel,
      streamState: { ...state.streamState, rawGraph: newRawGraph },
    };

    if (
      newState.viewSettings.collapsedGroupIds.length > COLLAPSED_GROUPS_LIMIT
    ) {
      logError(
        Error(
          'Number of CollapsedGroupIds is exceeding the number stored in view settings'
        )
      );
    }

    dispatchAction(
      updateViewSettings({
        viewId,
        settings: {
          collapsedGroupIds: newState.viewSettings.collapsedGroupIds.slice(
            -COLLAPSED_GROUPS_LIMIT
          ), // only take the COLLAPSED_GROUPS_LIMIT, to prevent the viewSettings from bloating infinitely,
        },
        persistent: true,
      })
    );
    return {
      ...newState,
      streamState: { ...newState.streamState, skipRender: false },
    };
  };
  return reducer(
    relationshipDiagramToggleCollapseGroup,
    toggleCollapseGroupReducer
  );
};
export const collapseAllGroups = <
  TState extends RelationshipDiagramViewModelStreamState,
>() => {
  const collapseAllGroupsReducer = (
    state: TState,
    { viewId }: ViewIdPayload,
    namespace?: string
  ) => {
    if (namespace !== viewId) {
      return {
        ...state,
        streamState: { ...state.streamState, skipRender: true },
      };
    }
    const { viewSettings } = state;
    const collapsedGroupIds = [WILDCARD];
    dispatchAction(
      updateViewSettings({
        viewId,
        settings: { collapsedGroupIds: [WILDCARD] },
        persistent: true,
      })
    );
    return {
      ...state,
      viewSettings: { ...viewSettings, collapsedGroupIds },
      viewModel: rebuildViewModel({
        state,
        collapsedGroupIds,
        expandedStateChangingGroupId: WILDCARD,
      }),
      streamState: { ...state.streamState, skipRender: false },
    };
  };
  return reducer(
    relationshipDiagramCollapseAllGroups,
    collapseAllGroupsReducer
  );
};
export const expandAllGroups = <
  TState extends RelationshipDiagramViewModelStreamState,
>() => {
  const expandAllGroupsReducer = (
    state: TState,
    { viewId }: ViewIdPayload,
    namespace?: string
  ) => {
    if (namespace !== viewId) {
      return {
        ...state,
        streamState: { ...state.streamState, skipRender: true },
      };
    }
    const { viewSettings } = state;
    const collapsedGroupIds: string[] = [];
    dispatchAction(
      updateViewSettings({
        viewId,
        settings: { collapsedGroupIds },
        persistent: true,
      })
    );
    return {
      ...state,
      viewSettings: { ...viewSettings, collapsedGroupIds },
      viewModel: rebuildViewModel({
        state,
        collapsedGroupIds,
        expandedStateChangingGroupId: WILDCARD,
      }),
      streamState: { ...state.streamState, skipRender: false },
    };
  };
  return reducer(relationshipDiagramExpandAllGroups, expandAllGroupsReducer);
};
