import {
  CodeEditor,
  EditorLanguage,
  ImmutableCodeEditor,
} from '@ardoq/code-editor';

type GremlinAudienceEditorProps = {
  readonly immutableFirstLineOfQuery: string;
  readonly value: string;
  readonly onChange?: (value: string) => void;
  readonly onCtrlCmdEnter?: VoidFunction;
};

const GremlinAudienceEditor = ({
  immutableFirstLineOfQuery,
  value,
  onChange,
  onCtrlCmdEnter,
}: GremlinAudienceEditorProps) => {
  return (
    <div>
      <ImmutableCodeEditor value={immutableFirstLineOfQuery} />
      <CodeEditor
        minLines={10}
        maxLines={10}
        language={EditorLanguage.GROOVY}
        value={value}
        onChange={onChange}
        onCtrlCmdEnter={onCtrlCmdEnter}
      />
    </div>
  );
};

export default GremlinAudienceEditor;
