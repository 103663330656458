import React from 'react';
import { Link } from '@ardoq/typography';
import { azureDictionary } from 'integrations/common/dictionary';

export const selectConnectionPopoverContent = () => (
  <div>
    Using a connection based on Application ID
    <br /> and Client Secret will ensure a more secure
    <br />
    connection.
    <br />
    <br /> Read more about {azureDictionary.name}{' '}
    <Link
      href="https://www.ardoq.com/privacy"
      target="_blank"
      hideIcon
      typography="text2"
    >
      best practices.
    </Link>
  </div>
);

export const selectConfigPopoverContent = `It's only possible to reuse configurations saved from the new ${azureDictionary.name} integration.`;
