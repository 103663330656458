import {
  DataSourceItem,
  DiffTableRowType,
  FieldConflict,
  MergeState,
  MergeStep,
  MergeTableSectionType,
  Path,
  StructuralConflict,
} from './types';
import {
  APIComponentAttributes,
  APIComponentType,
  APIEntityType,
  APIModelAttributes,
  APIReferenceAttributes,
  APIReferenceType,
  ArdoqId,
  ScopeDataCollection,
  Verb,
  APIResetData,
} from '@ardoq/api-types';
import { EnhancedDiffScopeData } from './enhanceDiffContextData';
import { getVerbFromMergeStep } from 'components/DiffMergeSidebarNavigator/utils';
import { DataSourceGroup } from 'scope/merge/types';
import { cloneDeep, get, set, last } from 'lodash';
import { getStatusFromChildren } from './getStatusFromChildren';
import { getInitialMergeState } from './getInitialMergeState';
import { logError } from '@ardoq/logging';
import { Branch } from 'components/DiffMergeTable/Branch';
import { MergeDirection } from 'scope/merge/MergeDirection';
import {
  getHeader,
  getIndexer,
  getSkipSectionHeader,
} from './utils/toDataSourceHelpers';
import {
  getSectionHeader,
  groupEntityIdsBySections,
} from './utils/sectionHeaderUtils';
import {
  DiffMergeComponentTypeHierarchy,
  hasMergePermission,
  recursivelySortHierarchy,
} from 'components/DiffMergeTable/utils';
import {
  getEntityModelAndTypeID,
  getModelTypeDictionaryKey,
} from '@ardoq/renderers';
import type { EnhancedScopeData } from '@ardoq/data-model';

export const toDataSourceComponentAndReferenceTypes = (
  enhancedDiffContextData: EnhancedDiffScopeData,
  mergeStep: MergeStep,
  mergeDirection: MergeDirection
) => {
  const verb = getVerbFromMergeStep(mergeStep) as
    | Verb.UPDATE
    | Verb.CREATE
    | Verb.DELETE;
  const basePath = [ScopeDataCollection.MODELS, verb] as Path;
  const entityType = getEntityTypeFromMergeStep(mergeStep);
  const entities = enhancedDiffContextData.diffTargetToSource[entityType][verb];

  return verb === Verb.UPDATE
    ? toDataSourceComponentOrReferenceTypeUpdate(
        entityType,
        verb,
        basePath,
        enhancedDiffContextData,
        entities,
        mergeDirection
      )
    : toDataSourceComponentOrReferenceTypeCreateOrDelete(
        entityType,
        verb,
        basePath,
        enhancedDiffContextData,
        entities,
        mergeDirection
      );
};

const toDataSourceComponentOrReferenceTypeCreateOrDelete = (
  entityType: APIEntityType,
  verb: Verb,
  basePath: Path,
  enhancedDiffContextData: EnhancedDiffScopeData,
  entities:
    | Record<string, Partial<Omit<APIComponentType, 'children'>>>
    | Record<string, Partial<APIReferenceType>>,
  mergeDirection: MergeDirection
) => {
  if (!entities) return [];
  const sectionGroups = groupEntityIdsBySections({
    entityUniqueIds: Object.keys(entities),
    entityType,
    enhancedDiffContextData,
  });

  const getIndex = getIndexer();
  const rootIndex = getIndex();
  const rootChildren: number[] = [];

  const entityRows = Object.entries(sectionGroups).flatMap(
    ([_sectionType, sectionEntityIds]) => {
      if (sectionEntityIds.length === 0) return [];
      // a bit ugly type casting
      const sectionType: MergeTableSectionType =
        _sectionType as unknown as MergeTableSectionType;
      const skipSectionHeader = getSkipSectionHeader(verb, sectionType);
      const sectionChildren: number[] = [];
      const sectionHeaderIndex = skipSectionHeader ? -1 : getIndex();
      if (!skipSectionHeader) rootChildren.push(sectionHeaderIndex);

      const sectionRows = (
        entityType === APIEntityType.COMPONENT_TYPE
          ? sortComponentTypesByHierarchy(
              sectionEntityIds,
              enhancedDiffContextData[
                verb === Verb.CREATE ? Branch.SOURCE : Branch.TARGET
              ]
            )
          : sectionEntityIds
      ).map(uniqueTypeId => {
        const entityRowIndex = getIndex();
        sectionChildren.push(entityRowIndex);

        const hasWritePermission = hasMergePermission(
          uniqueTypeId,
          entityType,
          enhancedDiffContextData
        );

        return getSubHeaderForTypes({
          entityType,
          verb,
          uniqueTypeId,
          basePath,
          enhancedDiffContextData,
          parentIndex: skipSectionHeader ? rootIndex : sectionHeaderIndex,
          entityIndex: entityRowIndex,
          mergeDirection,
          sectionType,
          hasWritePermission,
        });
      });

      if (skipSectionHeader) rootChildren.push(...sectionChildren);
      return [
        ...(sectionType !== MergeTableSectionType.DEFAULT &&
        !skipSectionHeader &&
        sectionChildren.length
          ? [
              getSectionHeader({
                sectionType,
                entityType,
                index: sectionHeaderIndex,
                children: sectionChildren,
                enhancedDiffContextData,
                basePath,
                parent: rootIndex,
                status: getStatusFromChildren(
                  sectionRows,
                  sectionRows![0].status
                ),
                isDisabled: sectionRows.every(row => row.isDisabled),
              }),
            ]
          : []),
        ...sectionRows,
      ];
    }
  );

  if (!entityRows.length) return [];

  return [
    getHeader({
      entityType,
      basePath,
      enhancedDiffContextData,
      rootIndex,
      rootChildren,
      isDisabled: entityRows.every(row => row.isDisabled),
      status: entityRows.every(
        entityRow => entityRow.status === entityRows[0].status
      )
        ? entityRows[0].status
        : MergeState.PARTIAL,
    }),
    ...entityRows,
  ];
};

const toDataSourceComponentOrReferenceTypeUpdate = (
  entityType: APIEntityType,
  verb: Verb,
  basePath: Path,
  enhancedDiffContextData: EnhancedDiffScopeData,
  entities:
    | Record<string, Partial<Omit<APIComponentType, 'children'>>>
    | Record<string, Partial<APIReferenceType>>,
  mergeDirection: MergeDirection
) => {
  if (!entities) return [];

  const getIndex = getIndexer();
  const rootIndex = getIndex();
  const rootChildren: number[] = [];

  const dataSource = [
    getHeader({
      entityType,
      basePath,
      enhancedDiffContextData,
      rootIndex,
      rootChildren,
    }),
    ...Object.entries(entities).flatMap(
      ([uniqueTypeId, updates]: [
        string,
        APIComponentType | APIReferenceType,
      ]) => {
        const entityIndex = getIndex();
        rootChildren.push(entityIndex);
        const parentChildren: number[] = [];

        const hasWritePermission = hasMergePermission(
          uniqueTypeId,
          entityType,
          enhancedDiffContextData
        );

        return [
          getSubHeaderForTypes({
            entityType,
            verb,
            uniqueTypeId,
            basePath,
            enhancedDiffContextData,
            parentIndex: rootIndex,
            entityIndex,
            mergeDirection,
            children: parentChildren,
            hasWritePermission,
          }),
          ...Object.keys(updates).map(fieldName => {
            const index = getIndex();
            parentChildren.push(index);
            const [parentEntityId, entityId] =
              getEntityAndEntityParentId(uniqueTypeId);
            const { path } = getMetaData(
              enhancedDiffContextData.sourceMetaData,
              entityType,
              uniqueTypeId
            );

            return {
              rowType: DiffTableRowType.PROPERTY_ROW,
              entityId,
              parentEntityId,
              entityType,
              fieldName,
              enhancedDiffContextData,
              path: [...basePath, ...path, entityId, fieldName] as Path,
              index,
              parent: entityIndex,
              children: [] as number[],
              status: getInitialMergeState({
                entityId,
                entityType,
                fieldName,
                enhancedDiffContextData,
                parentEntityId,
                hasWritePermission,
              }),
              structuralConflict: StructuralConflict.NONE,
              fieldConflict: FieldConflict.NONE,
              hasWritePermission,
              isDisabled: !hasWritePermission,
            };
          }),
        ];
      }
    ),
  ];

  dataSource.forEach(entry => {
    if (entry.children!.length === 0) {
      return;
    }
    const children = entry.children!.map(childIndex => dataSource[childIndex]);
    entry.status = getStatusFromChildren(
      children,
      dataSource[entry.children![0]].status
    );
  });

  return dataSource;
};

const getChildComponentTypeIds = (
  enhancedDiffContextData: EnhancedDiffScopeData,
  componentTypeId: ArdoqId,
  modelId: ArdoqId
) =>
  Object.values(enhancedDiffContextData.targetMetaData.componentTypes)
    .filter(
      componentType =>
        componentType.path.includes(componentTypeId) &&
        componentType.modelId === modelId
    )
    .map(({ id }) => id);

export const getComponentIdsAffectedByComponentTypeDeletion = (
  enhancedDiffContextData: EnhancedDiffScopeData,
  uniqueTypeId: string
) => {
  if (!enhancedDiffContextData.targetMetaData.componentTypes[uniqueTypeId]) {
    // componentType doesn't exist on target, no components are affected
    return [];
  }
  const { modelId, id } =
    enhancedDiffContextData.targetMetaData.componentTypes[uniqueTypeId];
  const componentTypeAndChildrenIds = new Set([
    id,
    ...getChildComponentTypeIds(enhancedDiffContextData, id, modelId),
  ]);

  return enhancedDiffContextData[Branch.TARGET].components
    .filter((component: APIComponentAttributes) =>
      componentTypeAndChildrenIds.has(component.typeId)
    )
    .map(({ _id }) => _id);
};

export const isComponentTypeUsedInTarget = (
  enhancedDiffContextData: EnhancedDiffScopeData,
  uniqueTypeId: string
) =>
  Boolean(
    getComponentIdsAffectedByComponentTypeDeletion(
      enhancedDiffContextData,
      uniqueTypeId
    ).length
  );

export const getReferencesByReferenceTypeOnTarget = (
  enhancedDiffContextData: EnhancedDiffScopeData,
  uniqueTypeId: string
) =>
  enhancedDiffContextData[Branch.TARGET].references
    .filter((reference: APIReferenceAttributes) => {
      const { model, typeId } = getEntityModelAndTypeID(
        APIEntityType.REFERENCE,
        reference._id,
        enhancedDiffContextData[Branch.TARGET]
      );
      return (
        model &&
        typeId &&
        uniqueTypeId === getModelTypeDictionaryKey(model, typeId)
      );
    })
    .map(({ _id }) => _id);

export const isReferenceTypeUsedInTarget = (
  enhancedDiffContextData: EnhancedDiffScopeData,
  uniqueTypeId: string
) =>
  Boolean(
    getReferencesByReferenceTypeOnTarget(enhancedDiffContextData, uniqueTypeId)
      .length
  );

const isEntityTypeUsedInTarget = (
  entityType: APIEntityType,
  enhancedDiffContextData: EnhancedDiffScopeData,
  uniqueTypeId: string
) =>
  entityType === APIEntityType.COMPONENT_TYPE
    ? isComponentTypeUsedInTarget(enhancedDiffContextData, uniqueTypeId)
    : isReferenceTypeUsedInTarget(enhancedDiffContextData, uniqueTypeId);

const getEntityAndEntityParentId = (uniqueTypeId: string) =>
  uniqueTypeId.split('-');

interface GetPreselectionStatusArgs {
  entityType: APIEntityType;
  verb: Verb;
  enhancedDiffContextData: EnhancedDiffScopeData;
  uniqueTypeId: ArdoqId;
  sectionType?: MergeTableSectionType;
  hasWritePermission: boolean;
}

const getPreselectionStatus = ({
  entityType,
  verb,
  enhancedDiffContextData,
  uniqueTypeId,
  sectionType,
  hasWritePermission,
}: GetPreselectionStatusArgs) => {
  if (
    hasWritePermission &&
    ((verb === Verb.CREATE &&
      (!sectionType ||
        sectionType === MergeTableSectionType.CREATED_IN_MAINLINE ||
        sectionType === MergeTableSectionType.CREATED_IN_BRANCH)) ||
      (verb === Verb.DELETE &&
        !isEntityTypeUsedInTarget(
          entityType,
          enhancedDiffContextData,
          uniqueTypeId
        ) &&
        (!sectionType ||
          sectionType === MergeTableSectionType.DELETED_IN_BRANCH ||
          sectionType === MergeTableSectionType.DELETED_IN_MAINLINE)))
  )
    return MergeState.SOURCE;
  return MergeState.NONE;
};

const shouldBeDisabled = (
  entityType: APIEntityType,
  verb: Verb,
  enhancedDiffContextData: EnhancedDiffScopeData,
  uniqueTypeId: ArdoqId,
  mergeDirection: MergeDirection,
  hasWritePermission: boolean
) =>
  (mergeDirection === MergeDirection.MAINLINE_TO_BRANCH &&
    verb === Verb.CREATE) ||
  (verb === Verb.DELETE &&
    isEntityTypeUsedInTarget(
      entityType,
      enhancedDiffContextData,
      uniqueTypeId
    )) ||
  !hasWritePermission;

interface GetSubHeaderForTypesArgs {
  entityType: APIEntityType;
  verb: Verb;
  uniqueTypeId: ArdoqId;
  basePath: Path;
  enhancedDiffContextData: EnhancedDiffScopeData;
  parentIndex: number;
  entityIndex: number;
  mergeDirection: MergeDirection;
  children?: number[];
  sectionType?: MergeTableSectionType;
  hasWritePermission: boolean;
}

const getSubHeaderForTypes = ({
  entityType,
  verb,
  uniqueTypeId,
  basePath,
  enhancedDiffContextData,
  parentIndex,
  entityIndex,
  mergeDirection,
  children,
  sectionType,
  hasWritePermission,
}: GetSubHeaderForTypesArgs): DataSourceItem => {
  const [parentEntityId, entityId] = getEntityAndEntityParentId(uniqueTypeId);
  const metaData = verb === Verb.CREATE ? 'sourceMetaData' : 'targetMetaData';
  const { path } = getMetaData(
    enhancedDiffContextData[metaData],
    entityType,
    uniqueTypeId
  );
  return {
    rowType: DiffTableRowType.SUB_HEADER_ROW,
    entityId,
    parentEntityId,
    entityType,
    fieldName: 'name',
    enhancedDiffContextData,
    path: [...basePath, ...path, entityId],
    index: entityIndex,
    parent: parentIndex,
    status: getPreselectionStatus({
      entityType,
      verb,
      enhancedDiffContextData,
      uniqueTypeId,
      sectionType,
      hasWritePermission,
    }),
    children,
    isExpanded: false,
    structuralConflict: StructuralConflict.NONE,
    isDisabled: shouldBeDisabled(
      entityType,
      verb,
      enhancedDiffContextData,
      uniqueTypeId,
      mergeDirection,
      hasWritePermission
    ),
    fieldConflict: FieldConflict.NONE,
    isInUseOnTarget:
      entityType === APIEntityType.COMPONENT_TYPE
        ? isComponentTypeUsedInTarget(
            enhancedDiffContextData,
            getModelTypeDictionaryKey(parentEntityId, entityId)
          )
        : isReferenceTypeUsedInTarget(
            enhancedDiffContextData,
            getModelTypeDictionaryKey(parentEntityId, entityId)
          ),
    hasWritePermission,
  };
};

const getMetaData = (
  metaData: EnhancedDiffScopeData['sourceMetaData'],
  entityType: APIEntityType,
  id: string
) => {
  const types =
    entityType === APIEntityType.COMPONENT_TYPE
      ? 'componentTypes'
      : 'referenceTypes';

  return metaData[types][id];
};

export const isComponentOrReferenceTypeMergeStep = (mergeStep: MergeStep) =>
  componentTypeMergeSteps.has(mergeStep) ||
  referenceTypeMergeSteps.has(mergeStep);

const componentTypeMergeSteps = new Set([
  MergeStep.CREATE_COMPONENT_TYPES,
  MergeStep.DELETE_COMPONENT_TYPES,
  MergeStep.UPDATE_COMPONENT_TYPES,
]);

const referenceTypeMergeSteps = new Set([
  MergeStep.CREATE_REFERENCE_TYPES,
  MergeStep.DELETE_REFERENCE_TYPES,
  MergeStep.UPDATE_REFERENCE_TYPES,
]);

const getEntityTypeFromMergeStep = (mergeStep: MergeStep) =>
  componentTypeMergeSteps.has(mergeStep)
    ? APIEntityType.COMPONENT_TYPE
    : APIEntityType.REFERENCE_TYPE;

export const getKeyAndPayloadForComponentAndReferenceTypes = (
  dataSourceGroup: DataSourceGroup
): [ScopeDataCollection.MODELS, APIResetData<APIModelAttributes>] => {
  const models = Object.entries(dataSourceGroup).reduce(
    (models, [verb, record]) =>
      Object.entries(record).reduce((models, [modelId, dataSourceItems]) => {
        if (verb === Verb.CREATE) {
          return handleCreateTypeEntity(dataSourceItems, models, modelId);
        }

        if (verb === Verb.UPDATE) {
          return handleUpdateTypeEntity(dataSourceItems, models, modelId);
        }

        if (verb === Verb.DELETE) {
          return handleDeleteTypeEntity(dataSourceItems, models, modelId);
        }

        logError(Error(`Wrong verb`), null, { verb });

        return models;
      }, models),
    {} as Record<ArdoqId, APIModelAttributes>
  );
  return [
    ScopeDataCollection.MODELS,
    {
      [Verb.CREATE]: [],
      [Verb.UPDATE]: Object.values(models),
      [Verb.DELETE]: [],
    },
  ];
};

const handleDeleteTypeEntity = (
  dataSourceItems: DataSourceItem[],
  models: Record<ArdoqId, APIModelAttributes>,
  modelId: ArdoqId
) =>
  dataSourceItems
    .sort((a, b) => b.path.length - a.path.length)
    .reduce((models, dataSourceItem) => {
      const { enhancedDiffContextData, path } = dataSourceItem;
      const model = getModel(models, modelId, enhancedDiffContextData);
      try {
        delete get(model, path.slice(2, -1))[last(path)!];
      } catch (e) {
        logError(Error('Failed to delete component type'), null, { path });
      }
      return models;
    }, models);

const handleCreateTypeEntity = (
  dataSourceItems: DataSourceItem[],
  models: Record<ArdoqId, APIModelAttributes>,
  modelId: ArdoqId
) =>
  dataSourceItems
    .sort((a, b) => a.path.length - b.path.length)
    .reduce((models, dataSourceItem) => {
      const { enhancedDiffContextData, entityId, path, entityType } =
        dataSourceItem;
      const model = getModel(models, modelId, enhancedDiffContextData);
      const typesById =
        entityType === APIEntityType.COMPONENT_TYPE
          ? 'componentTypesById'
          : 'referenceTypesById';
      set(
        model,
        path.slice(2),
        cloneDeep(
          enhancedDiffContextData[Branch.SOURCE].typesByModelId[modelId][
            typesById
          ][entityId]
        )
      );
      return models;
    }, models);

const handleUpdateTypeEntity = (
  dataSourceItems: DataSourceItem[],
  models: Record<ArdoqId, APIModelAttributes>,
  modelId: ArdoqId
) =>
  dataSourceItems
    .filter(
      ({ rowType, status }) =>
        rowType === DiffTableRowType.PROPERTY_ROW &&
        status === MergeState.SOURCE
    )
    .reduce((models, dataSourceItem) => {
      const { enhancedDiffContextData, path } = dataSourceItem;
      const model = getModel(models, modelId, enhancedDiffContextData);
      set(
        model,
        path.slice(2),
        get(
          enhancedDiffContextData[Branch.SOURCE].modelsById[modelId],
          path.slice(2)
        )
      );
      return models;
    }, models);

const getModel = (
  models: Record<ArdoqId, APIModelAttributes>,
  modelId: ArdoqId,
  enhancedDiffContextData: EnhancedDiffScopeData
) => {
  if (models[modelId] === undefined) {
    models[modelId] = cloneDeep(
      enhancedDiffContextData[Branch.TARGET].modelsById[modelId]
    );
  }
  return models[modelId];
};

const sortComponentTypesByHierarchy = (
  uniqueIds: ArdoqId[],
  enhancedScopeData: EnhancedScopeData
) => {
  const componentTypeIdSet = new Set(
    uniqueIds.map((uniqueId: ArdoqId) => {
      const [, typeId] = getEntityAndEntityParentId(uniqueId);
      return typeId;
    })
  );
  const componentTypeHierarchy =
    uniqueIds.reduce<DiffMergeComponentTypeHierarchy>((hierarchy, uniqueId) => {
      const [modelId, typeId] = getEntityAndEntityParentId(uniqueId);
      const componentType =
        enhancedScopeData.typesByModelId[modelId].componentTypesById[typeId];

      if (!hierarchy[typeId]) {
        hierarchy[typeId] = { uniqueId, parent: null };
      }
      hierarchy[typeId].children = Object.keys(componentType.children).filter(
        childId => componentTypeIdSet.has(childId)
      );

      hierarchy[typeId].children!.forEach(childId => {
        if (hierarchy[childId]) {
          hierarchy[childId].parent = typeId;
        } else {
          hierarchy[childId] = {
            uniqueId: `${modelId}-${childId}`,
            parent: typeId,
            children: [],
          };
        }
      });
      return hierarchy;
    }, {});

  return Object.entries(componentTypeHierarchy)
    .filter(([, { parent }]) => !parent)
    .flatMap(([typeId]) =>
      recursivelySortHierarchy(
        typeId,
        componentTypeHierarchy,
        APIEntityType.COMPONENT_TYPE
      )
    );
};
