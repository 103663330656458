let count = 1;

export const getId = () => `graph-id-${count++}`;

export const FILTER_ID_PREFIX = 'filter-id-';

let localFilterCount = 1;
export const generateLocalFilterId = () =>
  `${FILTER_ID_PREFIX}${localFilterCount++}`;
/**
 * Reset the count of the graph node id.
 * This function is implemented mainly for testing purposes.
 * Tests cannot depend on the order of test execution or if we run all of them or just subset.
 */
export const resetGraphNodeIdGenerators = () => {
  count = 1;
  localFilterCount = 1;
};
