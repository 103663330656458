import { CloudProviderConfig } from '@ardoq/api-types/integrations';
import { actionCreatorWithIntegrationId } from '../../common/utils/actionCreatorWithIntegrationId';

const NAMESPACE = '[integrations cloud-providers] CONFIG';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const applySourceConfig =
  actionCreatorWithIntegrationId<CloudProviderConfig>(
    getActionName('APPLY_SOURCE_CONFIG')
  );
