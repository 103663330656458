import styled from 'styled-components';
import { colors, s4, s8 } from '@ardoq/design-tokens';

export const RigidMarker = styled.span`
  float: right;
  color: ${colors.white};
  font-size: 12px;
  font-weight: normal;
  margin-bottom: ${s8};
  padding-right: ${s4};
  padding-left: ${s4};
  padding-top: 3px;
  padding-bottom: 2px;
  border-radius: ${s4};
  background-color: ${colors.blue50};
`;
