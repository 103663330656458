import { ReactNode } from 'react';
import { DoqLoadingAnimation, DoqType } from '@ardoq/doq';
import styled from 'styled-components';
import { s24, colors } from '@ardoq/design-tokens';

export const WaitingOverlay = ({ children }: { children: ReactNode }) => (
  <>
    <Backdrop />
    <Animation>
      <DoqLoadingAnimation
        doqs={[
          DoqType.SIMPLE,
          DoqType.ALGORITHM,
          DoqType.CHOOSE_ORG,
          DoqType.COMPONENT_MERGE_EMPTY,
        ]}
        delay={500}
      />
      <Content>{children}</Content>
    </Animation>
  </>
);

const Content = styled.div`
  text-align: center;
`;

const Backdrop = styled.div`
  height: 100%;
  width: 100%;
  opacity: 0.8;
  position: absolute;
  z-index: 10;
  background-color: ${colors.grey95};
`;

const Animation = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${s24};
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 11;
`;
