import {
  ArdoqId,
  Privilege,
  PrivilegeLabel,
  PrivilegesByUser,
} from '@ardoq/api-types';

type UserIdsByPrivileges = {
  [key in PrivilegeLabel]?: ArdoqId[];
};

export type PrivilegesStreamShape = {
  status: 'idle' | 'loading' | 'success' | 'revalidating' | 'error';
  configurablePrivileges: Privilege[];
  privilegesByUser: PrivilegesByUser;
  userIdsByPrivilege: UserIdsByPrivileges;
  currentUserCanConfigurePrivileges: boolean;
};

export type SetPrivilegesPayload = {
  configurablePrivileges: Privilege[];
  privilegesByUser: PrivilegesByUser;
  currentUserId: ArdoqId;
};

export enum PrivilegeAction {
  ASSIGN = 'ASSIGN',
  REMOVE = 'REMOVE',
}

export enum WarningMessage {
  CANNOT_ASSIGN = 'CANNOT_ASSIGN',
  CANNOT_REVOKE = 'CANNOT_REVOKE',
}
