import { Multiselect, Select } from '@ardoq/select';
import { byLocaleField } from 'integrations/common/utils/common';
import { Connection } from '@ardoq/api-types/integrations';

type ConnectionSelectorProps = {
  connections: Connection[];
  onSelect: (ids: string[]) => void;
  selectedConnectionIds: string[];
  isMultiSelect: boolean;
  selectConnectionPlaceHolder: string;
};
export const ConnectionsSelector = ({
  selectedConnectionIds,
  connections,
  onSelect,
  isMultiSelect,
  selectConnectionPlaceHolder,
}: ConnectionSelectorProps) => {
  const options = connections
    .map(connection => ({
      label: connection.name,
      value: connection._id,
    }))
    .sort(byLocaleField('label'));

  return isMultiSelect ? (
    <Multiselect
      options={options}
      placeholder={selectConnectionPlaceHolder}
      onValueChange={selectedConnectionIds =>
        onSelect(selectedConnectionIds || [])
      }
      value={selectedConnectionIds}
    />
  ) : (
    <Select
      options={options}
      placeholder={selectConnectionPlaceHolder}
      onValueChange={selectedConnectionId =>
        onSelect(selectedConnectionId ? [selectedConnectionId] : [])
      }
      value={selectedConnectionIds.length ? selectedConnectionIds[0] : null}
    />
  );
};
