import { ColumnsOptions, Row } from '../../components/EntityBrowser/types';
import { AssetRow } from '../../components/AssetsBrowser/types';
import AssetsBrowserActionsMenu from './AssetsBrowserActionsMenu';
import {
  ColumnsProps,
  getFavoritesColumn,
  getLastModifiedByColumn,
  getLastModifiedDateColumn,
  getNameColumnIncludingSelectAllCheckbox,
} from '../../components/AssetsBrowser/columns';
import { s32, s8 } from '@ardoq/design-tokens';
import { DropdownOption } from '@ardoq/dropdown-menu';

export const getMenuOptionsColumn = (
  getMenuOptions: (row: Row<AssetRow>) => DropdownOption[]
) => ({
  headerStyle: { width: 45 },
  valueRender: (_name: any, row: Row<AssetRow>) => (
    <AssetsBrowserActionsMenu
      rowType={row.rowType}
      menuOptions={getMenuOptions(row)}
    />
  ),
});

export const getDashboardContainerRowsColumns =
  (
    columnsProps: ColumnsProps & {
      getMenuOptions: (row: Row<AssetRow>) => DropdownOption[];
    }
  ) =>
  (columnsOptions: ColumnsOptions<AssetRow>) => {
    return [
      {
        ...getNameColumnIncludingSelectAllCheckbox({
          ...columnsProps,
          ...columnsOptions,
        }),
        headerStyle: {
          paddingLeft: s32,
        },
        cellStyle: {
          paddingLeft: s32,
          paddingRight: s8,
          maxWidth: `400px`, // if there is more space on the page, the name column takes it anyway. However, it does not take more than 400px if there is not enough space and names are overflowing
        },
      },
      getLastModifiedDateColumn(columnsProps),
      getLastModifiedByColumn(columnsProps),
      getMenuOptionsColumn(columnsProps.getMenuOptions),
      getFavoritesColumn(),
    ];
  };
