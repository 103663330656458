import { PropsWithChildren, useRef } from 'react';
import styled from 'styled-components';
import SubLevelPageHeader from '../SubLevelPageHeader';
import { usePerspectiveEditorPortalSubscription } from 'perspectiveSidebar/perspectiveEditor/perspectivesEditor$/usePerspectiveEditorPortalSubscription';
import { ViewOptionsPerspectivesHeaderProperties } from './types';
import { useOnClickOutside } from '@ardoq/hooks';
import { closeViewSidePanel } from 'views/mainApp/viewSidePanel/viewSidePanel$';
import { SIDEBAR_MENU_SELECTOR } from '@ardoq/sidebar-menu';
import { connect } from '@ardoq/rxbeach';
import { ExtractStreamShape } from 'tabview/types';
import { PerspectivesFooter } from '@ardoq/perspectives-sidebar';
import { viewOptionsPerspectivesPage } from './viewOptionsPerspectivesPage$';

const ViewOptionsPerspectivesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${SIDEBAR_MENU_SELECTOR} {
    border: none;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex: 1;
`;

type ViewOptionsPerspectivesPageProps = PropsWithChildren<
  ViewOptionsPerspectivesHeaderProperties &
    ExtractStreamShape<typeof viewOptionsPerspectivesPage>
>;

const ViewOptionsPerspectivesPage = ({
  title,
  children,
  knowledgeBaseLink,
  shareFeedbackUrl,
  isBeta,
  onApply,
  onClose,
  onClearAll,
}: ViewOptionsPerspectivesPageProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, closeViewSidePanel);
  usePerspectiveEditorPortalSubscription();

  return (
    <ViewOptionsPerspectivesContainer ref={containerRef}>
      <SubLevelPageHeader
        title={title}
        knowledgeBaseLink={knowledgeBaseLink}
        shareFeedbackUrl={shareFeedbackUrl}
        isBeta={isBeta}
      />
      <ScrollContainer>{children}</ScrollContainer>
      <PerspectivesFooter
        onApply={onApply}
        onClose={onClose}
        onClearAll={onClearAll}
      />
    </ViewOptionsPerspectivesContainer>
  );
};

export default connect(
  ViewOptionsPerspectivesPage,
  viewOptionsPerspectivesPage
);
