import { trackEvent } from 'tracking/tracking';
import migrationChecklist$ from './migrationChecklist$';
import { MigrationId } from './types';

const getActiveViewMetadata = () => ({
  activeView: migrationChecklist$.state.activeMigrationId,
});

export const trackPresentationLinkClicked = () =>
  trackEvent(
    'Clicked presentation link in view migration checklist',
    getActiveViewMetadata()
  );

export const trackOpenedMigrationChecklist = () =>
  trackEvent('Opened view migration checklist');

export const trackClickedMigrationChecklistTab = (
  clickedViewTab: MigrationId
) =>
  trackEvent('Clicked view tab in view migration checklist', {
    clickedViewTab,
  });

export const trackClickedKBArticleLink = () =>
  trackEvent(
    'Clicked view migration knowledge base link',
    getActiveViewMetadata()
  );
