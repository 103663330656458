import { editTraversalOperations } from './editTraversalOperations';
import { TraversalBuilderState, TraversalBuilderViewState } from '../types';
import { viewpointBuilderFiltersOperations } from './viewpointBuilderFiltersOperations';
import { getViewpointBuilderSuggestionsLoaders } from '../addFiltersTab/getViewpointBuilderSuggestionsLoaders';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { collapsePathsOperations } from '../collapsePathsTab/collapsePathsOperations';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { requiredComponentsOperations } from '../requiredComponentsTab/requiredComponentsOperations';

export const traversalEditStateToTraversalViewState = (
  state: TraversalBuilderState
): TraversalBuilderViewState => {
  const relatedWorkspaceIds =
    viewpointBuilderFiltersOperations.getWorkspaceIdsForSelectedComponentType(
      state
    );
  const relatedWorkspaces =
    workspaceInterface.getStubWorkspaces(relatedWorkspaceIds);

  const asyncLoaders = getViewpointBuilderSuggestionsLoaders({
    organizationId: currentUserInterface.getCurrentOrgId(),
    relatedWorkspaces,
    componentTypeName:
      editTraversalOperations.getSelectedNodeComponentType(state) ?? null,
  });

  const { collapsedGraphEdges, collapsedGraphNodes } =
    editTraversalOperations.buildCollapsedGraph(state);

  return {
    ...state,
    graphEdges: collapsedGraphEdges,
    graphNodes: collapsedGraphNodes,
    triplesSortAndFiltersState: {
      ...state.triplesSortAndFiltersState,
      ...getOutgoingAndIncomingHiddenTriplesCount(state),
    },
    filtersDefinitions:
      viewpointBuilderFiltersOperations.getFiltersDefinitions(state),
    asyncLoaders,
    stateAsSavableAttributes:
      editTraversalOperations.getTraversalAsSavableAttributes(state),
    perspectivesOptionsArgs:
      editTraversalOperations.getWorkspaceIdsAndComponentAndReferenceTypeNamesFromTraversals(
        state
      ),
    pathCollapsingViewState:
      collapsePathsOperations.getPathCollapsingViewState(state),
    shouldShowDeleteCurrentNodeButton:
      state.selectedGraphNodeId !== null &&
      state.selectedGraphNodeId !== state.startNode?.id,
    requiredComponentsState:
      requiredComponentsOperations.getRequiredComponentsViewState(state),
    shouldIncludeClickOtherComponentTypeNodesNotification:
      state.graphNodes.size > 1 &&
      state.shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings,
  };
};

const getOutgoingAndIncomingHiddenTriplesCount = (
  state: TraversalBuilderState
) => {
  if (!state.triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts) {
    return {
      incomingTriplesHiddenCount: 0,
      outgoingTriplesHiddenCount: 0,
    };
  }

  const allMatchingFilteredOptionsIncomingCount =
    editTraversalOperations.filterOptions(
      state.tripleOptions.incoming,
      state.triplesSortAndFiltersState.filterTerm,
      false
    ).length;
  const allMatchingFilteredOptionsOutgoingCount =
    editTraversalOperations.filterOptions(
      state.tripleOptions.outgoing,
      state.triplesSortAndFiltersState.filterTerm,
      false
    ).length;

  const outgoingTriplesHiddenCount =
    allMatchingFilteredOptionsOutgoingCount -
    state.filteredTripleOptions.outgoing.length;

  const incomingTriplesHiddenCount =
    allMatchingFilteredOptionsIncomingCount -
    state.filteredTripleOptions.incoming.length;

  return {
    incomingTriplesHiddenCount,
    outgoingTriplesHiddenCount,
  };
};
