import styled from 'styled-components';
import { useState } from 'react';
import { Color, s16, s32 } from '@ardoq/design-tokens';
import { normal14, semibold16 } from '@ardoq/typography';
import { PrimaryButton, GhostButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import { Label, TextInput } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalSize,
} from '@ardoq/modal';
import { caseInsensitiveStringIncludes } from '@ardoq/common-helpers';

const Header = styled.p`
  ${semibold16}
`;

const Wrapper = styled.div`
  ${normal14}
`;

const ContentWrapper = styled.div`
  padding: ${s32};
  overflow: visible;
`;

const Url = styled.p`
  opacity: 0.5;
  clear: both;
  padding: ${s16} 0;
`;

const RowWrapper = styled.div`
  height: 58px;
  margin-bottom: ${s32};
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export type PresentationModalOption = {
  label: string;
  value: string;
  url: string;
  readAccess: string;
  icon?: IconName;
  iconColor?: Color;
  popoverId?: string;
};

export type AddPresentationDialogProps = {
  options: PresentationModalOption[];
  url: string;
  linkText: string;
  onClose: () => void;
  onInsert: (args: { newLinkText: string; url: string }) => void;
};

const AddPresentationDialog = ({
  options,
  url,
  linkText,
  onClose,
  onInsert,
}: AddPresentationDialogProps) => {
  const [newLinkText, setNewLinkText] = useState(linkText || '');
  const [selectedPresentation, setSelectedPresentation] = useState(null);
  const [newUrl, setNewUrl] = useState(url || '');

  const handleInsert = () => {
    onInsert({
      newLinkText,
      url: newUrl,
    });
  };

  return (
    <ModalContainer modalSize={ModalSize.M} fixedHeight>
      <ModalHeader title="Insert Presentation" onCloseButtonClick={onClose} />
      <ModalBody>
        <ContentWrapper>
          <Wrapper>
            <Header>Select a presentation to link into the message.</Header>
            <p>
              Check your presentation’s permissions to make sure it will be
              accessible to your broadcast audience.
            </p>
          </Wrapper>

          <RowWrapper>
            <Label>Presentation</Label>
            <Select
              placeholder="Type to search"
              options={options}
              value={selectedPresentation}
              onChange={(selectedOption: any) => {
                setSelectedPresentation(selectedOption);
                setNewLinkText(selectedOption.label);
                setNewUrl(selectedOption.url);
              }}
              filterOption={(
                { data: { label = '' } }: { data: { label: string } },
                searchPhrase = ''
              ) => caseInsensitiveStringIncludes(label, searchPhrase)}
            />
          </RowWrapper>

          <RowWrapper>
            <Label>Presentation URL</Label>
            <Url>
              {newUrl.length
                ? newUrl
                : 'Presentation URL will be displayed here'}
            </Url>
          </RowWrapper>

          <RowWrapper>
            <Label>Link text</Label>
            <TextInput
              value={newLinkText}
              placeholder="Add link text"
              onChange={e => setNewLinkText(e.target.value)}
              errorMessage={
                !newLinkText.length && selectedPresentation
                  ? 'You must add some text to represent the link'
                  : undefined
              }
            />
          </RowWrapper>
        </ContentWrapper>
      </ModalBody>
      <ModalFooter>
        <GhostButton onClick={onClose}>Cancel</GhostButton>
        <PrimaryButton
          onClick={handleInsert}
          isDisabled={!newLinkText.length || !selectedPresentation}
        >
          Insert
        </PrimaryButton>
      </ModalFooter>
    </ModalContainer>
  );
};

export default AddPresentationDialog;
