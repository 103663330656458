import { Icon, IconName } from '@ardoq/icons';

export const getReferenceOptionLeftContent = (
  direction: string,
  typeName: string
) => {
  const iconName =
    direction === 'outgoing' ? IconName.ARROW_FORWARD : IconName.ARROW_BACK;
  return {
    leftContent: (
      <>
        <Icon iconName={iconName} />
        {typeName}
        <Icon iconName={iconName} />
      </>
    ),
  };
};
