import { mailAdminForAccess } from './actions';
import { trackLandingPageContactAdminClick } from './tracking';
import { sendEmailToAdmin } from 'utils/email';
import {
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap } from 'rxjs/operators';

const handleMailAdminForAccess = routine(
  ofType(mailAdminForAccess),
  extractPayload(),
  tap(({ page }) => {
    sendEmailToAdmin();
    trackLandingPageContactAdminClick(page);
  })
);

export const landingPageRoutines = collectRoutines(handleMailAdminForAccess);
