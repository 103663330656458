import { APIComponentAttributes } from '@ardoq/api-types';
import { ContextSort, ExcludeFalsy } from '@ardoq/common-helpers';
import { NavigatorLayoutState, NavigatorViewInterface } from '../types';
import { ExpandCollapseToggleClickedPayload } from '../actions/navigatorActions';
import { ContextShape } from '@ardoq/data-model';
import { partition } from 'lodash';
import { expandNode } from '../models/tree';
import { navigatorLayoutOperations } from './navigatorLayoutOperations';
import { ScenarioModeState } from 'scope/types';

/**
 * These are not standard reducers. The associated stream uses a tree to model
 * the workspace hierarchies. That tree maintains internal mutable state.
 * The main reason for that is performance concerns with immutable data
 * with a lot of nodes. The tree is meant to deal with up to some
 * 100 000 components. Investigating the feasibility of using immutable data
 * structures for this purpose is a potential future task.
 */

const syncSelectedContext = (
  tree: NavigatorLayoutState['tree'],
  { componentId, workspaceId, scenarioId }: ContextShape
) => {
  if (tree.selectedContext) {
    tree.selectedContext.isSelectedContext = false;
    tree.selectedContext = null;
  }

  const selectedContextId = componentId || scenarioId || workspaceId;
  if (selectedContextId && tree.hasNode(selectedContextId)) {
    tree.selectedContext = tree.getNode(selectedContextId);
    tree.selectedContext.isSelectedContext = true;
    if (tree.onlyContextNodeIsSelected()) {
      tree.selectedContext.isSelected = false;
    }
  }

  tree.activeWorkspace =
    workspaceId && tree.hasNode(workspaceId) ? tree.getNode(workspaceId) : null;
};

const cleanupSelectionWithoutStoreUpdate = (
  tree: NavigatorLayoutState['tree'],
  selection: NavigatorLayoutState['selection']
) => {
  tree.syncSelection(selection.filter(id => tree.getNode(id)));
};

const resyncTree = (
  state: NavigatorLayoutState,
  context: ContextShape
): NavigatorLayoutState => {
  state.tree.syncRootNodes(context.workspacesIds);
  syncSelectedContext(state.tree, context);
  cleanupSelectionWithoutStoreUpdate(state.tree, state.selection);
  if (state.tree.selectedContext) {
    expandNode(state.tree.selectedContext);
  }
  return navigatorLayoutOperations.treeChanged(state, {});
};

const reloadTree = (state: NavigatorLayoutState): NavigatorLayoutState => {
  state.tree.reload();
  return navigatorLayoutOperations.treeChanged(state, {});
};

const handleComponentsUpdate = (
  state: NavigatorLayoutState,
  components: APIComponentAttributes[]
): NavigatorLayoutState => {
  // Only handle updates of nodes which are in the tree.
  const updatedNodes = components.filter(comp => state.tree.hasNode(comp._id));
  // if the node in the tree has a different parent, reload the root node.
  const [nodesWithChangedParents, nodesWithSameParents] = partition(
    updatedNodes,
    ({ parent, rootWorkspace, _id }) => {
      const treeParentId = parent || rootWorkspace;
      const node = state.tree.getNode(_id);
      return node?.parent?.id !== treeParentId;
    }
  );
  const rootNodeIdsToReload = nodesWithChangedParents.map(
    ({ _id }) => state.tree.getNode(_id).getRootNode().id
  );
  const nodeIdsToReload = nodesWithSameParents
    // Reloading the parent to ensure that a potential order change
    // by e.g. editing the name is handled properly.
    .map(({ _id }) => state.tree.getNode(_id).parent?.id)
    .filter(ExcludeFalsy)
    .filter(id => !rootNodeIdsToReload.includes(id));

  return navigatorLayoutOperations.treeChanged(state, {
    reloadNodeIds: [...rootNodeIdsToReload, ...nodeIdsToReload],
    removedIds: [],
  });
};

// Ensure that we clear the tree on loading or closing a scenario
// as soon as possible so that no state update can happen
// with an outdated tree. More context in
// https://ardoqcom.atlassian.net/browse/ARD-17588.
const handleSetScenarioId = (
  state: NavigatorLayoutState
): NavigatorLayoutState => {
  state.tree.setIsExpired();
  state.tree.clearFilter();
  return navigatorLayoutOperations.clearTree(state);
};

const setFilterTerm = (
  state: NavigatorLayoutState,
  filterTerm: string
): NavigatorLayoutState => {
  if (filterTerm) {
    state.tree.setFilter(filterTerm);
  } else {
    state.tree.clearFilter();
  }
  return navigatorLayoutOperations.treeChanged(state, {});
};

const setTreeExpired = (state: NavigatorLayoutState): NavigatorLayoutState => {
  state.tree.setIsExpired();
  return state;
};

const setSort = (
  state: NavigatorLayoutState,
  sort: ContextSort
): NavigatorLayoutState => ({ ...state, sort });

const toggleExpandCollapsed = (
  state: NavigatorLayoutState,
  { nodeId, isDeep }: ExpandCollapseToggleClickedPayload
): NavigatorLayoutState => {
  const node = state.tree.getNode(nodeId);
  node.toggleCollapsed({ deep: isDeep });
  return navigatorLayoutOperations.treeChanged(state, {});
};

/**
 * The navigatorViewInterface ia an abstraction layer to read the data.
 * It makes the component data agnostic so that the component can be used both
 * with BB models or with pure scope data.
 * In the case of scope data that interface will be the main update driver of
 * the external updates.
 * The reducers will be use case specific, meaning using the component with
 * scope data will require different reducer compositions.
 */
const setNavigatorViewInterface = (
  state: NavigatorLayoutState,
  navigatorViewInterface: NavigatorViewInterface
): NavigatorLayoutState => {
  state.tree.navigatorViewInterface = navigatorViewInterface;
  return {
    ...state,
    navigatorViewInterface,
  };
};

const setScenarioState = (
  state: NavigatorLayoutState,
  activeScenarioState: ScenarioModeState
): NavigatorLayoutState => {
  state.tree.activeScenarioState = activeScenarioState;
  return { ...state, activeScenarioState };
};

const handleStreamIsInitialized = (
  state: NavigatorLayoutState
): NavigatorLayoutState => ({
  ...state,
  isInitialized: true,
});

export const navigatorTreeOperations = {
  resyncTree,
  reloadTree,
  handleComponentsUpdate,
  handleSetScenarioId,
  setFilterTerm,
  setTreeExpired,
  setSort,
  toggleExpandCollapsed,
  setNavigatorViewInterface,
  setScenarioState,
  handleStreamIsInitialized,
};
