import {
  action$,
  dispatchAction,
  extractPayload,
  ObservableState,
  ofType,
  withNamespace,
} from '@ardoq/rxbeach';
import { NavigatorConfiguration, NavigatorLayoutState } from '../types';
import {
  clickedInNavigator,
  contextMenuClicked,
  expandCollapseToggleClicked,
  selectionEnd,
  selectionToggle,
} from '../actions/navigatorActions';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { NodeModel } from '../models/types';
import Tree from '../models/tree';
import WorkspaceNode from '../models/WorkspaceNode';
import ComponentNode from '../models/ComponentNode';
import ScenarioNode from '../models/ScenarioNode';
import {
  targetManager,
  EXPANDER_CLASS_NAME,
  LINK_CLASS_NAME,
  CLOSE_WORKSPACE_CLASS_NAME,
  CLOSE_SCENARIO_CLASS_NAME,
  CONTEXT_MENU_CLASS_NAME,
} from '../targetManager';
import { catchErrorLogWithMessageAndContinue } from 'streams/utils/streamOperators';
import * as createReferencesActions from 'createReferences2024/createReferencesActions';
import { CreateReferencesState } from 'createReferences2024/createReferencesTypes';
import { getDOMNodeInNavigatorWithDataId } from '../getDOMNodeInNavigatorWithDataId';
import { isMac } from '@ardoq/common-helpers';
import {
  COMPONENT_ITEM_CLASS_NAME,
  SCENARIO_ITEM_CLASS_NAME,
  WORKSPACE_ITEM_CLASS_NAME,
} from '../consts';
import React from 'react';
import { setContextMenuState } from 'contextMenus/contextMenuState$';
import { IconName } from '@ardoq/icons';
import { DropdownItem, DropdownOptionType } from '@ardoq/dropdown-menu';
import { ReferenceDirection } from '@ardoq/graph';
import { identity } from 'lodash';
import { StartCreateReferencePayload } from 'createReferences2024/createReferencesActions';

export const getNavigatorClickRoutine = (
  navigator$: Observable<NavigatorLayoutState>,
  createReferences$: ObservableState<CreateReferencesState>,
  navigatorConfiguration: NavigatorConfiguration
) =>
  action$.pipe(
    navigatorConfiguration.actionNamespace
      ? withNamespace(navigatorConfiguration.actionNamespace)
      : identity,
    ofType(clickedInNavigator),
    extractPayload(),
    withLatestFrom(navigator$, createReferences$),
    tap(async ([event, { isDrag, tree }, { isLinking }]) => {
      const { eventHandlers, actionNamespace } = navigatorConfiguration;
      const { target, className } = targetManager.getTarget(event);
      const nodeId = target ? getDOMNodeInNavigatorWithDataId(target) : null;
      const node = nodeId ? tree.getNode(nodeId) : null;
      if (!(target && className && nodeId && node) || isLinking || isDrag) {
        return;
      }

      if (className === EXPANDER_CLASS_NAME) {
        return toggleNode(nodeId, actionNamespace);
      }

      if (className === LINK_CLASS_NAME) {
        return startCreateReferences(event, node, tree);
      }

      if (
        [
          WORKSPACE_ITEM_CLASS_NAME,
          COMPONENT_ITEM_CLASS_NAME,
          SCENARIO_ITEM_CLASS_NAME,
        ].includes(className)
      ) {
        return changeSelectionOrOnItemClick(
          event,
          node,
          eventHandlers,
          actionNamespace
        );
      }

      if (className === CLOSE_WORKSPACE_CLASS_NAME) {
        return closeWorkspace(event, node, eventHandlers);
      }

      if (className === CLOSE_SCENARIO_CLASS_NAME) {
        return closeScenario(eventHandlers);
      }

      if (className === CONTEXT_MENU_CLASS_NAME) {
        return showContextMenu(event);
      }
    }),
    catchErrorLogWithMessageAndContinue('Error in navigator click handler')
  );

const toggleNode = (nodeId: string, actionNamespace?: string) =>
  dispatchAction(expandCollapseToggleClicked({ nodeId }), actionNamespace);

const startCreateReferences = (
  event: React.MouseEvent,
  node: NodeModel,
  tree: Tree
) => {
  const multiselect = getMultiselect(node, tree);
  const hasWriteAccess = multiselect
    ? multiselect.every(node => node.hasWriteAccess)
    : node.hasWriteAccess;
  if (!hasWriteAccess) {
    return;
  }
  const linkSourceIds = (multiselect ? multiselect : [node]).map(
    ({ id }) => id
  );

  const startCreateReferencesArgs = {
    event,
    linkSourceIds,
    linkSourceNodeId: node.id,
    isStartInNavigator: true,
  };

  if (linkSourceIds.length > 1) {
    // If there is a multi selection, show the menu to select the reference
    // direction for the components. Starting the reference creation will be
    // handled in the menu item click.
    dispatchAction(
      setContextMenuState({
        items: getSelectReferenceDirectionMenu(startCreateReferencesArgs),
        position: { left: event.clientX, top: event.clientY },
        testId: 'reference-direction-selection',
      })
    );
  } else {
    // Start directly reference creation as outgoing reference.
    dispatchAction(
      createReferencesActions.startCreateReferences({
        ...startCreateReferencesArgs,
        refDirection: ReferenceDirection.OUTGOING,
      })
    );
  }
};

const getSelectReferenceDirectionMenu = (
  startCreateReferencesArgs: StartCreateReferencePayload
): DropdownItem[] => [
  {
    type: DropdownOptionType.HEADER,
    label: `Create multiple references`,
  },
  {
    type: DropdownOptionType.OPTION,
    label: `Outgoing (from many to one)`,
    iconName: IconName.ARROW_FORWARD,
    onClick: () =>
      dispatchAction(
        createReferencesActions.startCreateReferences({
          ...startCreateReferencesArgs,
          refDirection: ReferenceDirection.OUTGOING,
        })
      ),
  },
  {
    type: DropdownOptionType.OPTION,
    label: `Incoming (from one to many)`,
    iconName: IconName.ARROW_BACK,
    onClick: () =>
      dispatchAction(
        createReferencesActions.startCreateReferences({
          ...startCreateReferencesArgs,
          refDirection: ReferenceDirection.INCOMING,
        })
      ),
  },
];

const changeSelectionOrOnItemClick = (
  event: React.MouseEvent,
  node: NodeModel,
  eventHandlers: NavigatorConfiguration['eventHandlers'],
  actionNamespace?: string
) => {
  if (isMac() ? event.metaKey : event.ctrlKey) {
    dispatchAction(selectionToggle({ nodeId: node.id }), actionNamespace);
    return;
  }
  if (event.shiftKey) {
    dispatchAction(selectionEnd({ nodeId: node.id }), actionNamespace);
    return;
  }
  return setContext(node, eventHandlers);
};

const closeWorkspace = (
  event: React.MouseEvent,
  node: NodeModel,
  eventHandlers: NavigatorConfiguration['eventHandlers']
) => {
  event.nativeEvent.stopImmediatePropagation();
  if (eventHandlers.onWorkspaceCloseClick) {
    eventHandlers.onWorkspaceCloseClick(node.id);
  }
};

const closeScenario = (
  eventHandlers: NavigatorConfiguration['eventHandlers']
) => eventHandlers.onScenarioCloseClick();

const showContextMenu = (event: React.MouseEvent) =>
  dispatchAction(contextMenuClicked(event.nativeEvent));

const getMultiselect = (targetNode: NodeModel, tree: Tree) => {
  const { navigatorViewInterface } = tree;
  const selection = tree
    .getSelection()
    .filter(node => navigatorViewInterface.isComponent(node.id));
  if (!selection.length) {
    return null;
  }
  const targetComponentId = targetNode.id;
  const actionInSelection = selection.some(
    node => node.id === targetComponentId
  );
  return selection.length > 1 && actionInSelection ? selection : null;
};

const setContext = (
  node: NodeModel,
  eventHandlers: NavigatorConfiguration['eventHandlers']
) => {
  if (node instanceof WorkspaceNode) {
    eventHandlers.onWorkspaceClick(node.id);
  } else if (node instanceof ComponentNode) {
    eventHandlers.onComponentClick(node.id);
  } else if (node instanceof ScenarioNode) {
    eventHandlers.onScenarioClick(node.id);
  }
};
