import { mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  addMetamodel,
  apiCreateMetamodel,
  apiDeleteMetamodel,
  apiFetchMetamodel,
  apiFetchMetamodels,
  apiUpdateMetamodel,
  removeMetamodel,
  setMetamodels,
  updateMetamodel,
} from './metamodelActions';
import { MetamodelPane } from 'metamodel/navigation/types';
import {
  selectMetamodel,
  selectMetamodelPane,
} from 'metamodel/navigation/actions';
import { fieldInterface } from '@ardoq/field-interface';
import { dateRangeOperations } from '@ardoq/date-range';
import { notifyWorkspacesDeleted } from '../workspaces/actions';
import { requestShowAppModule } from '../../appContainer/actions';
import selectedModule$ from '../../appContainer/selectedModule$';
import { AppModules } from '../../appContainer/types';
import metamodelNavigation$ from '../../metamodel/navigation/metamodelNavigation$';
import metamodels$ from './metamodels$';
import { handleError, metamodelApi } from '@ardoq/api';
import { from } from 'rxjs';

const handleCreateMetamodel = routine(
  ofType(apiCreateMetamodel),
  extractPayload(),
  mergeMap(metamodelDefinition => metamodelApi.create(metamodelDefinition)),
  handleError(),
  tap(metamodel => {
    dispatchAction(addMetamodel(metamodel));
    dispatchAction(selectMetamodel(metamodel._id));
    dispatchAction(selectMetamodelPane({ pane: MetamodelPane.SELECTED }));
  })
  // TODO - handle errors?)
);

const handleFetchMetamodel = routine(
  ofType(apiFetchMetamodel),
  extractPayload(),
  mergeMap(metamodelId => metamodelApi.fetch(metamodelId)),
  handleError(),
  tap(metamodel =>
    dispatchAction(
      updateMetamodel(
        dateRangeOperations.mergeDateRangeFieldsOnMetamodel(
          metamodel,
          fieldInterface.getAllFieldsOfWorkspace
        )
      )
    )
  )
  // TODO - handle errors?
);

const handleUpdateMetamodel = routine(
  ofType(apiUpdateMetamodel),
  extractPayload(),
  mergeMap(({ metamodelId, metamodelDefinition }) =>
    metamodelApi.update(metamodelId, metamodelDefinition)
  ),
  handleError(),
  tap(metamodel => {
    dispatchAction(updateMetamodel(metamodel));
  })
  // TODO - handle errors?
);

const handleDeleteMetamodel = routine(
  ofType(apiDeleteMetamodel),
  extractPayload(),
  mergeMap(metamodelId =>
    from(metamodelApi.delete(metamodelId)).pipe(
      tap(() => dispatchAction(removeMetamodel(metamodelId)))
    )
  )
  // TODO - handle errors?
);

const handleFetchMetamodels = routine(
  ofType(apiFetchMetamodels),
  mergeMap(() => metamodelApi.fetchAll()),
  handleError(),
  tap(metamodels => {
    dispatchAction(setMetamodels(metamodels));
  })
  // TODO - handle errors?
);

const fetchMetamodelWhenOpened = routine(
  ofType(selectMetamodel),
  extractPayload(),
  tap(metamodelId => {
    if (metamodelId) {
      dispatchAction(apiFetchMetamodel(metamodelId));
    }
  })
);

/**
 * Update metamodels affected by the workspace removal
 */
const updateMetamodelsOnWorkspaceDelete = routine(
  ofType(notifyWorkspacesDeleted),
  extractPayload(),
  withLatestFrom(metamodels$, metamodelNavigation$, selectedModule$),
  tap(([payload, metamodels, metamodelNavigation, selectedModule]) => {
    const { workspaceIds: deletedWorkspaceIds } = payload;

    metamodels.forEach(({ workspaceIds, _id }) => {
      const remainingWorkspaceIds = workspaceIds.filter(
        workspaceId => !deletedWorkspaceIds.includes(workspaceId)
      );

      const isNotAffected =
        remainingWorkspaceIds.length === workspaceIds.length;

      if (isNotAffected) {
        return;
      }

      if (!remainingWorkspaceIds.length) {
        if (
          metamodelNavigation.pane === MetamodelPane.SELECTED &&
          selectedModule.selectedModule === AppModules.METAMODEL &&
          metamodelNavigation.metamodelId === _id
        ) {
          // go to Metamodel Overview page if user is on metamodel view page and the metamodel will be deleted
          dispatchAction(selectMetamodelPane({ pane: MetamodelPane.LIST }));
          dispatchAction(
            requestShowAppModule({ selectedModule: AppModules.METAMODEL })
          );
        }

        dispatchAction(removeMetamodel(_id));
        return;
      }

      dispatchAction(apiFetchMetamodel(_id));
    });
  })
);

export const metamodelRoutines = collectRoutines(
  handleCreateMetamodel,
  handleDeleteMetamodel,
  handleFetchMetamodel,
  handleUpdateMetamodel,
  handleFetchMetamodels,
  fetchMetamodelWhenOpened,
  updateMetamodelsOnWorkspaceDelete
);
