import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { CalculatedFieldEvent } from './types';

export const viewWorkspaceHistory = actionCreator<{
  id: ArdoqId;
  name: string;
}>('[workspace] WORKSPACE_OPTIONS_VIEW_WORKSPACE_HISTORY');

export const editWorkspace = actionCreator<ArdoqId>(
  '[workspace] WORKSPACE_OPTIONS_EDIT_WORKSPACE'
);

export const restoreDeleted = actionCreator<ArdoqId>(
  '[workspace] WORKSPACE_OPTIONS_RESTORE_DELETED_WORKSPACE'
);

export const setPermissions = actionCreator<ArdoqId>(
  '[workspace] WORKSPACE_OPTIONS_SET_PERMISSIONS'
);

export const exportWorkspaceToExcel = actionCreator<{
  workspaceId: ArdoqId;
  filename: string;
}>('[workspace] WORKSPACE_OPTIONS_EXPORT_WORKSPACE_TO_EXCEL');

export const editWorkspaceModel = actionCreator<ArdoqId>(
  '[workspace] WORKSPACE_OPTIONS_EDIT_WORKSPACE_MODEL'
);

export const manageFields = actionCreator<ArdoqId>(
  '[workspace] WORKSPACE_OPTIONS_MANAGE_FIELDS'
);

export const manageReferenceTypes = actionCreator<ArdoqId>(
  '[workspace] WORKSPACE_OPTIONS_MANAGE_REFERENCE_TYPES'
);

export const createTemplate = actionCreator<ArdoqId>(
  '[workspace] WORKSPACE_OPTIONS_CREATE_TEMPLATE'
);

export const calculatedFieldEventReceived = actionCreator<CalculatedFieldEvent>(
  '[workspace] WORKSPACE_OPTIONS_CALCULATED_FIELD_EVENT_RECEIVED'
);

export const setCalculatedFieldEvent = actionCreator<CalculatedFieldEvent>(
  '[workspace] WORKSPACE_OPTIONS_SET_CALCULATED_FIELD_EVENT'
);

export const setUserCalculatedFieldEventsAsNotified = actionCreator<ArdoqId>(
  '[workspace] WORKSPACE_OPTIONS_RESET_CALCULATED_FIELD_EVENTS_USER'
);
