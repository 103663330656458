import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
  extractPayload,
} from '@ardoq/rxbeach';
import {
  accountDeleted,
  createServiceAccount,
  createServiceAccountFailed,
  deleteAccount,
  getServiceAccounts,
  receivedAPIServiceAccounts,
  regenerateToken,
  regenerateTokenFailed,
  serviceAccountCreated,
  setError,
  tokenRegenerated,
} from './actions';
import { switchMap, tap } from 'rxjs/operators';
import { handleError, serviceAccountApi, tokenApi } from '@ardoq/api';
import { getArdoqErrorMessage } from '@ardoq/common-helpers';
import { newTokenGeneratedAction } from 'admin/user/TokenView/CopyAndDisappearToken/actions';
import { showToast, ToastType } from '@ardoq/status-ui';
import { fetchOrgUsers } from 'streams/orgUsers/actions';

const handleGetServiceAccounts = routine(
  ofType(getServiceAccounts),
  switchMap(serviceAccountApi.fetchServiceAccounts),
  handleError(error =>
    dispatchAction(
      setError(
        getArdoqErrorMessage(
          error,
          'An error occurred while retrieving your service accounts'
        )
      )
    )
  ),
  tap(data => dispatchAction(receivedAPIServiceAccounts(data)))
);

const handleCreateServiceAccount = routine(
  ofType(createServiceAccount),
  extractPayload(),
  switchMap(serviceAccountApi.createServiceAccountAndToken),
  handleError(() => {
    dispatchAction(createServiceAccountFailed());
    showToast(
      'An error occurred while creating your service account',
      ToastType.INFO
    );
  }),
  tap(data => dispatchAction(serviceAccountCreated(data))),
  tap(serviceAccount =>
    dispatchAction(newTokenGeneratedAction(serviceAccount.token))
  ),
  tap(() => dispatchAction(fetchOrgUsers()))
);

const handleRegenerateToken = routine(
  ofType(regenerateToken),
  extractPayload(),
  switchMap(tokenApi.update),
  handleError(() => {
    dispatchAction(regenerateTokenFailed());
    showToast(
      'An error occurred while regenerating your token',
      ToastType.INFO
    );
  }),
  tap(data => dispatchAction(tokenRegenerated(data))),
  tap(token => dispatchAction(newTokenGeneratedAction(token)))
);

const handleDeleteAccount = routine(
  ofType(deleteAccount),
  extractPayload(),
  switchMap(serviceAccountApi.deleteServiceAccount),
  handleError(() =>
    showToast(
      'An error occurred while deleting your service account',
      ToastType.INFO
    )
  ),
  tap(data => dispatchAction(accountDeleted(data)))
);

export default collectRoutines(
  handleGetServiceAccounts,
  handleCreateServiceAccount,
  handleRegenerateToken,
  handleDeleteAccount
);
