import { useState } from 'react';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { Drawer, showDrawer } from '@ardoq/snowflakes';
import { IntegrationSchedule } from '@ardoq/api-types';
import { Button, ButtonType, GhostButton } from '@ardoq/button';
import { popStackPageByName } from '@ardoq/stack-page-manager';
import { Link, Paragraph } from '@ardoq/typography';
import { getCurrentLocale } from '@ardoq/locale';
import { formatDateTime, parseDate } from '@ardoq/date-time';
import { Tab, TabsGroup } from '@ardoq/tabs';
import { Complaint, IntegrationWorkspace } from '@ardoq/api-types/integrations';
import {
  ErrorNotification,
  StatusType,
  WarningNotification,
} from '@ardoq/status-ui';
import { colors, s24, s8 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { SourceIcon } from 'integrations/common/components/sourceIcon/SourceIcon';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import {
  getComplaints,
  getUnknownError,
} from 'integrations/common/streams/transferState/utils';
import { getSourceName } from '../schedulesTable/SchedulesTable';
import { getScheduleCommands } from 'integrations/dashboard/commands';
import {
  isIntegrationWithSchedules,
  isITPediaSchedule,
  isJobOptionsWithWorkspace,
} from 'integrations/common/streams/schedules/utils';
import { ITPediaDetails } from 'integrations/common/components/expandedSchedule/ITPediaDetails';
import { noop } from 'underscore';
import { IconName } from '@ardoq/icons';
import { isJobWithTabularMapping } from 'integrations/common/utils/scheduleApi';
import { ExpandedRowMapping } from 'integrations/common/components/expandedRowMapping/ExpandedRowMapping';

type SchedulesDrawerProps = {
  schedule: IntegrationSchedule;
  integrationId: IntegrationId;
  onFixButtonClick: () => void;
  onWorkspaceClick?: ((workspace: IntegrationWorkspace) => void) | null;
  connectionName: string | null;
};

type TabType = 'errors' | 'warnings';

const TabBody = styled(Stack)`
  width: 100%;
  padding: ${s8} 0 ${s24} 0;
  border-bottom: 1px solid ${colors.borderAction};
`;

const countMessages = (messages: Complaint[]) => {
  return messages.reduce<Record<string, number>>((acc, item) => {
    const message = item.message;
    acc[message] = (acc[message] || 0) + 1;
    return acc;
  }, {});
};

const SchedulesDrawer = ({
  schedule,
  integrationId,
  onFixButtonClick,
  onWorkspaceClick,
  connectionName,
}: SchedulesDrawerProps) => {
  const [tab, setTab] = useState<TabType>('errors');

  const complaints = getComplaints(
    schedule.meta.lastResult?.summary?.validation
  );
  const { status, message } = schedule.meta.lastResult || {};

  const errors = complaints.errors.length
    ? complaints.errors
    : status === 'error'
      ? getUnknownError(message)
      : [];

  const errorMessages = countMessages(errors);
  const warningMessages = countMessages(complaints.warnings);

  const jobOptions = schedule.jobOptions;

  return (
    <Drawer
      title={schedule.jobOptions.name}
      drawerSize="large"
      icon={
        <SourceIcon
          integrationSource={getSourceName(schedule.jobOptions)}
          size="24px"
        />
      }
      controls={
        <GhostButton
          onClick={() => popStackPageByName('ScheduleIntegrationDrawer')}
        >
          Close
        </GhostButton>
      }
    >
      <Stack gap="small">
        <FlexBox justify="space-between" paddingBottom="small">
          <Stack gap="small">
            <Paragraph variant="text1Bold" color="textSubtle">
              First scheduled import:
            </Paragraph>
            <Paragraph variant="text1Bold" color="textDefault">
              <span
                data-tooltip-text={parseDate(
                  schedule.meta.firstCompleted || ''
                ).toString()}
              >
                {`${formatDateTime(
                  schedule.meta.firstCompleted || '',
                  getCurrentLocale()
                )}`}
              </span>
            </Paragraph>
          </Stack>
          <Stack gap="small">
            <Paragraph variant="text1Bold" color="textSubtle">
              Interval:
            </Paragraph>
            <Paragraph variant="text1Bold" color="textDefault">
              {schedule.schedulingPolicy.type === 'weekly'
                ? `Every ${schedule.schedulingPolicy.weekDay}`
                : 'Daily'}
            </Paragraph>
          </Stack>
          <Stack gap="small">
            <Paragraph variant="text1Bold" color="textSubtle">
              Upcoming import:
            </Paragraph>
            <Paragraph variant="text1Bold" color="textDefault">
              <span
                data-tooltip-text={parseDate(
                  schedule.meta.nextStart
                ).toString()}
              >
                {`${formatDateTime(
                  schedule.meta.nextStart,
                  getCurrentLocale()
                )}`}
              </span>
            </Paragraph>
          </Stack>
          {connectionName && (
            <Stack gap="small">
              <Paragraph variant="text1Bold" color="textSubtle">
                Connection:
              </Paragraph>
              <Link
                href={`/app/integrations/${integrationId}/connections`}
                target="_blank"
              >
                {connectionName}
              </Link>
            </Stack>
          )}
        </FlexBox>
        <Box padding="medium">
          <TabsGroup
            activeTabId={tab}
            onTabChange={tabId => setTab(tabId as TabType)}
            rightContent={
              <Button
                onClick={onFixButtonClick}
                buttonType={ButtonType.BRAND}
                isDisabled={
                  !errors.length || !isIntegrationWithSchedules(integrationId)
                }
              >
                Edit schedule
              </Button>
            }
          >
            <Tab
              label="Errors"
              tabId="errors"
              key="errors"
              tag={String(Object.keys(errorMessages).length)}
              tagStatusType={StatusType.DEFAULT}
            >
              <TabBody gap="medium" justify="center">
                {errors.length ? (
                  Object.entries(errorMessages).map(([message, count]) => (
                    <ErrorNotification key={message}>
                      {count}x {message}
                    </ErrorNotification>
                  ))
                ) : (
                  <Paragraph
                    variant="text1Bold"
                    color="textSubtle"
                    align="center"
                  >
                    There are no errors in this schedule.
                  </Paragraph>
                )}
              </TabBody>
            </Tab>
            <Tab
              label="Warnings"
              tabId="warnings"
              key="warnings"
              tag={String(Object.keys(warningMessages).length)}
              tagStatusType={StatusType.DEFAULT}
            >
              <TabBody gap="medium" justify="center">
                {complaints.warnings.length ? (
                  Object.entries(warningMessages).map(([message, count]) => (
                    <WarningNotification key={message}>
                      {count}x {message}
                    </WarningNotification>
                  ))
                ) : (
                  <Paragraph
                    variant="text1Bold"
                    color="textSubtle"
                    align="center"
                  >
                    There are no warnings in this schedule.
                  </Paragraph>
                )}
              </TabBody>
            </Tab>
          </TabsGroup>
          <Box padding="large" backgroundColor="bgSubtle">
            {isJobOptionsWithWorkspace(jobOptions) && (
              <FlexBox justify="space-between">
                <Paragraph variant="text1">
                  Workspace:{' '}
                  {jobOptions.workspace.id &&
                  workspaceInterface.workspaceExists(jobOptions.workspace.id) &&
                  onWorkspaceClick ? (
                    <Link
                      iconName={IconName.WORKSPACE}
                      type="primary"
                      typography="text1"
                      onClick={() => onWorkspaceClick(jobOptions.workspace)}
                    >
                      {jobOptions.workspace.name}
                    </Link>
                  ) : (
                    jobOptions.workspace.name
                  )}
                </Paragraph>
              </FlexBox>
            )}

            {isJobWithTabularMapping(jobOptions) && (
              // todo: rename ExpandedRowMapping to something more generic
              <ExpandedRowMapping
                dataSource={jobOptions.tabularMapping.tables.map(table => ({
                  name: table.name,
                  id: table.id,
                  rowRepresentation: table.rowRepresentation,
                  workspace: table.rootWorkspace,
                  isWorkspaceAvailable: workspaceInterface.workspaceExists(
                    table.rootWorkspace.id
                  ),
                }))}
                onWorkspaceClick={onWorkspaceClick || noop}
              />
            )}

            {isITPediaSchedule(schedule) && (
              <ITPediaDetails
                schedule={schedule}
                onWorkspaceClick={onWorkspaceClick || noop}
              />
            )}
          </Box>
        </Box>
      </Stack>
    </Drawer>
  );
};

export const showSchedulesDrawer = ({
  schedule,
  integrationId,
  connectionName,
}: Pick<
  SchedulesDrawerProps,
  'schedule' | 'integrationId' | 'connectionName'
>) => {
  const scheduleCommands = getScheduleCommands();
  return showDrawer({
    shouldCloseOnEscapeKey: true,
    shouldCloseOnBackdropClick: true,
    stackPageName: 'ScheduleIntegrationDrawer',
    renderDrawer: ({ handleCloserDrawer }) => (
      <SchedulesDrawer
        schedule={schedule}
        onWorkspaceClick={
          scheduleCommands.onWorkspaceClick
            ? (workspace: IntegrationWorkspace) => {
                if (scheduleCommands.onWorkspaceClick) {
                  scheduleCommands.onWorkspaceClick(workspace);
                  handleCloserDrawer();
                }
              }
            : undefined
        }
        integrationId={integrationId}
        connectionName={connectionName}
        onFixButtonClick={() => {
          scheduleCommands.onEditSchedule(integrationId, schedule._id);
          handleCloserDrawer();
        }}
      />
    ),
  });
};
