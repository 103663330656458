import { returnPayload } from '@ardoq/common-helpers';
import {
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';

// types
export enum PopoverManagerContext {
  DEFAULT,
  MODAL,
}

type PopoverManagerContextState = {
  currentContext: PopoverManagerContext;
};

// actions
export const setPopoverManagerContext =
  actionCreator<PopoverManagerContextState>(
    '[popoverManagerContext$] SET_POPOVER_MANAGER_CONTEXT'
  );

// default state
const defaultState: PopoverManagerContextState = {
  currentContext: PopoverManagerContext.DEFAULT,
};

// reducers

const reducers = [
  reducer(setPopoverManagerContext, returnPayload<PopoverManagerContextState>),
];

const popoverManagerContext$ =
  persistentReducedStream<PopoverManagerContextState>(
    'popoverManagerContext$',
    defaultState,
    reducers
  );

export default popoverManagerContext$;
