import { APIComponentAttributes } from '@ardoq/api-types';
import { DatasourceTable, newTableTheme, useSortableTable } from '@ardoq/table';
import {
  getSubdivisionComponentsTableColumns,
  getSubdivisionComponentsTableDataSource,
} from './subdivisionComponentsTableUtils';
import {
  PaginationBar,
  PaginationController,
  smartSort,
} from '@ardoq/pagination';
import { Box } from '@ardoq/layout';
import { FormSize, SearchInput } from '@ardoq/forms';
import React, { useState } from 'react';
import {
  WrappedSearchInputForSubdivisionComponentsTable,
  WrappedSubdivisionComponentsTable,
} from './atoms';
import { SubdivisionSummaryViewModelCommands } from './subdivisionSummaryViewModel$/commands';
import { subdivisionSummaryViewModelOperations } from './subdivisionSummaryViewModel$/operations';

export const SubdivisionComponentsTable = ({
  components,
  searchKey,
  isInDrawer = true,
  commands,
}: {
  components: APIComponentAttributes[];
  searchKey: string;
  isInDrawer?: boolean;
  commands: SubdivisionSummaryViewModelCommands;
}) => {
  const [perPage, setPerPage] = useState(10);

  const { sortBy, sortOrder, getSortableColumnProps } = useSortableTable({
    initialSortBy: 'name',
  });
  const sortedDataSource = smartSort(
    getSubdivisionComponentsTableDataSource(
      components.filter(component =>
        subdivisionSummaryViewModelOperations.componentMatchesSearchKey(
          component,
          searchKey
        )
      )
    ),
    sortBy,
    sortOrder
  );

  return (
    <PaginationController
      dataSource={sortedDataSource}
      perPage={perPage}
      render={({
        currentPageDataSource,
        currentPageNumber,
        perPage,
        totalResults,
        onPageSelect,
      }) => (
        <Box backgroundColor="bgDefault" borderRadius="r8">
          <WrappedSearchInputForSubdivisionComponentsTable
            isWrapped={isInDrawer}
          >
            <SearchInput
              value={searchKey}
              placeholder="Search component name"
              formSize={FormSize.DEFAULT}
              onValueChange={commands.updateSearchKey}
            />
          </WrappedSearchInputForSubdivisionComponentsTable>
          <Box padding={isInDrawer ? 'medium' : undefined}>
            <WrappedSubdivisionComponentsTable isWrapped={isInDrawer}>
              <DatasourceTable
                dataSource={currentPageDataSource}
                components={newTableTheme}
                columns={getSubdivisionComponentsTableColumns(
                  getSortableColumnProps
                )}
                renderEmptyTable={{ title: 'No results' }}
              />
            </WrappedSubdivisionComponentsTable>
            {perPage < totalResults && (
              <PaginationBar
                style={{ justifyContent: 'center', padding: '20px' }}
                currentPageNumber={currentPageNumber}
                perPage={perPage}
                totalResults={totalResults}
                onChange={({
                  currentPageNumber: newPageNumber,
                  perPage: newPerPage,
                }) => {
                  setPerPage(newPerPage);
                  onPageSelect(newPageNumber);
                }}
              />
            )}
          </Box>
        </Box>
      )}
    />
  );
};
