import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  updateDictionaryProps,
  updateDictionaryCategories,
  UpdateDictionaryPropsPayload,
  addNewAttribute,
} from './actions';
import { DictionaryCategoriesState } from './types';

const defaultState: DictionaryCategoriesState = {
  status: 'INIT',
  categories: {},
  allAttributes: [],
};

function updateDictionaryCategoryReducer(
  _: DictionaryCategoriesState,
  payload: DictionaryCategoriesState
): DictionaryCategoriesState {
  return payload;
}

function updateDictionaryPropsReducer(
  state: DictionaryCategoriesState,
  payload: UpdateDictionaryPropsPayload
): DictionaryCategoriesState {
  return {
    ...state,
    categories: {
      ...state.categories,
      [payload.category]: {
        ...state.categories[payload.category],
        ...payload.props,
      },
    },
  };
}

function addNewAttributeReducer(
  state: DictionaryCategoriesState,
  label: string
): DictionaryCategoriesState {
  return {
    ...state,
    allAttributes: [...state.allAttributes, { value: null, label }],
  };
}

export const dictionaryCategories$ = persistentReducedStream(
  `signavioExporterDictionaryCategories$`,
  defaultState,
  [
    reducer(updateDictionaryCategories, updateDictionaryCategoryReducer),
    reducer(updateDictionaryProps, updateDictionaryPropsReducer),
    reducer(addNewAttribute, addNewAttributeReducer),
  ]
);
