import {
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import {
  type PayloadPresentationChanged,
  type PayloadSetLoadedSlideId,
  type PayloadSetSlideId,
  notifyPresentationChanged,
  setLoadedSlideId,
  setSelectedSlideId,
} from 'streams/context/ContextActions';
import selectedModule$ from '../../appContainer/selectedModule$';
import {
  AppModules,
  type PayloadShowAppModule,
} from '../../appContainer/types';

type SelectedSlideState = {
  selectedSlideId: string | null;
  loadedSlideId: string | null;
};

const initialState = { selectedSlideId: null, loadedSlideId: null };

const handleSetSelectedSlideId = (
  state: SelectedSlideState,
  { selectedSlideId }: PayloadSetSlideId
) => ({ ...state, selectedSlideId });

const handleSetLoadedSlideId = (
  state: SelectedSlideState,
  { loadedSlideId }: PayloadSetLoadedSlideId
) => ({
  ...state,
  loadedSlideId,
});

const handleSelectedModuleChange = (
  state: SelectedSlideState,
  { selectedModule }: PayloadShowAppModule
) => {
  if (
    [
      AppModules.HOME,
      AppModules.TRAVERSALS,
      AppModules.USER_SETTINGS,
      AppModules.MANAGE_ORGANIZATION,
    ].includes(selectedModule)
  ) {
    return initialState;
  }
  return state;
};

const handleNotifyPresentationChanged = (
  state: SelectedSlideState,
  { presentation }: PayloadPresentationChanged
) => (presentation ? state : initialState);
export const selectedSlide$ = persistentReducedStream<SelectedSlideState>(
  'selectedSlide$',
  { selectedSlideId: null, loadedSlideId: null },
  [
    reducer(setLoadedSlideId, handleSetLoadedSlideId),
    reducer(setSelectedSlideId, handleSetSelectedSlideId),
    reducer(notifyPresentationChanged, handleNotifyPresentationChanged),
    streamReducer(selectedModule$, handleSelectedModuleChange),
  ]
);
