import { FlexBox } from '@ardoq/layout';
import { modal, ModalSize, ModalTemplate } from '@ardoq/modal';
import { Multiselect } from '@ardoq/select';
import { Paragraph } from '@ardoq/typography';
import { ArdoqLogoLoaderIcon } from '@ardoq/icons';
import { ErrorNotification } from '@ardoq/status-ui';
import { colors } from '@ardoq/design-tokens';
import { viewModel$ } from './viewModel';
import { ScheduleFailureNotificationState } from 'integrations/dashboard/streams/scheduleFailureNotification/types';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import {
  fetchUsers,
  setUpsertResult,
} from 'integrations/dashboard/streams/scheduleFailureNotification/actions';
type AlertsModalProps = ScheduleFailureNotificationState & {
  onClose: () => void;
  onSelectedUsers: (users: string[]) => void;
  onConfirmClicked: () => void;
};

export function AlertsModalComponent({
  users,
  selectedUsers,
  upserResult,
  onSelectedUsers,
  onConfirmClicked,
  onClose,
}: AlertsModalProps) {
  if (upserResult.status === 'SUCCESS') {
    onClose();
  }

  return (
    <ModalTemplate
      modalSize={ModalSize.L}
      headerText="Set alerts"
      primaryButtonText="Confirm"
      onPrimaryButtonClick={onConfirmClicked}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onClose}
    >
      <FlexBox padding="medium" flexDirection="column" gap="medium">
        <Paragraph>
          Ardoq will send an email notification to the selected users in case
          there are any errors in the scheduled imports or exports.
        </Paragraph>
        {users.status === 'LOADING' && (
          <FlexBox
            flexDirection="column"
            align="center"
            justify="center"
            flex={1}
          >
            <ArdoqLogoLoaderIcon color={colors.brand50} />
          </FlexBox>
        )}
        {users.status === 'FAILURE' && (
          <ErrorNotification>{users.message}</ErrorNotification>
        )}
        {upserResult.status === 'FAILURE' && (
          <ErrorNotification>Failed to save users</ErrorNotification>
        )}
        {users.status === 'SUCCESS' && (
          <Multiselect
            value={selectedUsers}
            options={users.data.map(user => ({
              label: `${user.name} (${user.email})`,
              value: user.userId,
            }))}
            onValueChange={values => values && onSelectedUsers(values)}
          />
        )}
      </FlexBox>
    </ModalTemplate>
  );
}

const AlertsModal = connect(AlertsModalComponent, viewModel$);

export function showSetAlertsModal() {
  dispatchAction(fetchUsers());
  return modal(
    resolve => (
      <AlertsModal
        onClose={() => {
          dispatchAction(setUpsertResult({ status: 'INIT' }));
          resolve(false);
        }}
      />
    ),
    { shouldCloseOnBackdropClick: false }
  );
}
