import DashboardModuleContainer from '../DashboardModuleContainer';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { navigateToDashboardModule } from '../../router/navigationActions';
import { DashboardModule } from '../types';
import {
  ButtonGroup,
  PrimaryButton,
  SecondaryButton,
  GhostButton,
} from '@ardoq/button';
import { ISLAND_DEFAULT_MAX_WIDTH } from '@ardoq/page-layout';
import { Space } from '@ardoq/style-helpers';
import { Stack } from '@ardoq/layout';
import { RadioGroup } from '@ardoq/forms';
import { AddIcon, ChevronLeftIcon, IconName } from '@ardoq/icons';
import { launchCustomColorThemeModal } from './CustomColorThemeModal';
import ColorThemeOption from './ColorThemeOption';
import { DashboardLoadingOverlay } from '@ardoq/dashboard';

import dashboardColorThemes$, {
  DEFAULT_DASHBOARD_COLOR_THEME,
  DashboardColorThemes$State,
} from './dashboardColorThemes$';
import {
  createDashboardColorTheme,
  deleteDashboardColorTheme,
  getDashboardColorThemes,
  saveDashboardColorThemeSelection,
  selectDashboardColorTheme,
  updateDashboardColorTheme,
} from './actions';
import { useEffectOnce } from '@ardoq/hooks';
import { confirmDelete } from '@ardoq/modal';
import IconClickable from '../../atomicComponents/IconClickable';
import { colors, s24 } from '@ardoq/design-tokens';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { InfoNotification } from '@ardoq/status-ui';
import { Link } from '@ardoq/typography';
import { map } from 'rxjs';
import { hasFeature, Features } from '@ardoq/features';
import { Island, PageBody } from '@ardoq/page-layout';

type DashboardColorThemeSettingsProps = DashboardColorThemes$State & {
  hasNewJourneyFeature: boolean;
};
const DashboardColorThemeSettings = ({
  dashboardColorThemes,
  isLoading,
  savedSelectedId,
  hasNewJourneyFeature,
}: DashboardColorThemeSettingsProps) => {
  useEffectOnce(() => {
    dispatchAction(getDashboardColorThemes());
  });

  return (
    <DashboardModuleContainer
      title="Dashboard Color Themes"
      secondaryText="Overview"
      hasNewJourneyFeature={hasNewJourneyFeature}
      dashboardModule={DashboardModule.COLOR_THEME_SETTINGS}
      HeaderLeftContent={
        <IconClickable
          color={colors.grey50}
          hoverColor={colors.grey35}
          iconName={IconName.KNOWLEDGE_BASE}
          tooltipText="Knowledge base"
          onClick={() => {
            window.open(
              KnowledgeBaseLink.DASHBOARD_COLOR_THEMES_SETTINGS,
              '_blank noopener'
            );
          }}
          containerStyle={{
            height: s24,
          }}
        />
      }
      HeaderRightContent={
        <GhostButton
          onClick={() => {
            dispatchAction(
              navigateToDashboardModule({
                dashboardModule: DashboardModule.OVERVIEW,
              })
            );
          }}
        >
          <ChevronLeftIcon />
          Back to Dashboards
        </GhostButton>
      }
    >
      <PageBody>
        {isLoading ? (
          <DashboardLoadingOverlay message="loading color themes" />
        ) : null}
        <InfoNotification maxWidth={ISLAND_DEFAULT_MAX_WIDTH}>
          The selected color theme will be applied to the auto assigned data
          colors on all dashboards within your organization. Please note that
          manually assigned data colors will remain the same and can only be
          changed on the dashboard builder.
        </InfoNotification>
        <Island
          title="Color themes"
          subtitle={
            <>
              Select a color theme for charts in all dashboards within your
              organization. Learn how to choose qualitative theme colors in our{' '}
              <Link
                href="https://help.ardoq.com/en/articles/74996-creating-custom-color-themes-for-powerful-dashboard-data-visualization"
                typography="text2"
                hideIcon
                target="_blank"
              >
                knowledge base article.
              </Link>
            </>
          }
          footerContent={
            <ButtonGroup>
              <PrimaryButton
                isDisabled={
                  savedSelectedId ===
                  (dashboardColorThemes.find(({ isSelected }) => isSelected)
                    ?._id ?? DEFAULT_DASHBOARD_COLOR_THEME._id)
                }
                onClick={() =>
                  dispatchAction(saveDashboardColorThemeSelection())
                }
              >
                Save
              </PrimaryButton>
            </ButtonGroup>
          }
          footerJustify="end"
        >
          <Stack gap="large">
            <RadioGroup
              value={
                dashboardColorThemes.find(({ isSelected }) => isSelected)
                  ?._id ?? DEFAULT_DASHBOARD_COLOR_THEME._id
              }
              onValueChange={selectedId => {
                dispatchAction(selectDashboardColorTheme(selectedId as string));
              }}
            >
              <Space $gap="s32" $align="start" $flexWrap>
                <ColorThemeOption
                  isDefaultTheme={true}
                  colorTheme={DEFAULT_DASHBOARD_COLOR_THEME}
                />
                {dashboardColorThemes.map(colorTheme => (
                  <ColorThemeOption
                    isDefaultTheme={false}
                    key={colorTheme._id}
                    colorTheme={colorTheme}
                    editTheme={async () => {
                      const editedColorTheme =
                        await launchCustomColorThemeModal(colorTheme);
                      if (editedColorTheme) {
                        dispatchAction(
                          updateDashboardColorTheme({
                            ...colorTheme,
                            ...editedColorTheme,
                          })
                        );
                      }
                    }}
                    deleteTheme={async () => {
                      const isConfirmed = await confirmDelete({
                        title: 'Delete color theme?',
                        text: `The "${colorTheme.label}" theme will be permanently deleted from your dashboard color themes and this cannot be undone. The default theme will be applied on charts using this theme.
                        Do you want to continue with this?
                        `,
                      });
                      if (isConfirmed)
                        dispatchAction(deleteDashboardColorTheme(colorTheme));
                    }}
                  />
                ))}
              </Space>
            </RadioGroup>
            <ButtonGroup>
              <SecondaryButton
                onClick={async () => {
                  const newColorTheme = await launchCustomColorThemeModal();
                  if (newColorTheme) {
                    dispatchAction(createDashboardColorTheme(newColorTheme));
                  }
                }}
              >
                <AddIcon /> Add new color theme
              </SecondaryButton>
            </ButtonGroup>
          </Stack>
        </Island>
      </PageBody>
    </DashboardModuleContainer>
  );
};

export default connect(
  DashboardColorThemeSettings,
  dashboardColorThemes$.pipe(
    map(dashboardColorThemesData => {
      return {
        ...dashboardColorThemesData,
        hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
      };
    })
  )
);
