import {
  LoadedState,
  Path,
  isTraversalLoadedState,
  ArdoqId,
} from '@ardoq/api-types';
import { GraphModelShape } from '@ardoq/data-model';

type GetPathsFromStartSetToSelectedComponentsArgs = {
  loadedStates: LoadedState[];
  selectedComponentId: ArdoqId;
  graphModel: GraphModelShape;
  getComponentTypeName: (id: string) => string | null;
  getReferenceTypeName: (id: string) => string | null;
};

/**
 * Retrieves all DirectedTriplePaths from the start set to the selected
 * component.
 * Multiple paths may exist between the start set and the selected component,
 * and this method returns all such paths.
 * Note: Filters are not considered when evaluating the paths. The method
 * operates on the resulting graph for the given traversal configuration of the
 * loadedStates, meaning reevaluating the filters would most likely have no
 * effect. This method is not useful for obtaining paths in any arbitrary graph.
 * If future requirements necessitate filter consideration, they can be
 * incorporated later.
 */
export const getPathsFromStartSetToSelectedComponents = ({
  loadedStates,
  selectedComponentId,
  graphModel,
  getComponentTypeName,
  getReferenceTypeName,
}: GetPathsFromStartSetToSelectedComponentsArgs): Path[] => {
  return loadedStates
    .filter(state => isTraversalLoadedState(state))
    .flatMap(loadedState => {
      const {
        data: { paths },
        componentIdsAndReferences,
      } = loadedState;

      if (!componentIdsAndReferences) {
        return [];
      }

      const { startSetResult } = componentIdsAndReferences;

      return getPathsForLoadedState({
        paths,
        startSetResult,
        selectedComponentId,
        graphModel,
        getComponentTypeName,
        getReferenceTypeName,
      });
    });
};

type GetPathsForLoadedState = {
  paths: Path[];
  startSetResult: ArdoqId[];
  selectedComponentId: ArdoqId;
  graphModel: GraphModelShape;
  getComponentTypeName: (id: string) => string | null;
  getReferenceTypeName: (id: string) => string | null;
};

const getPathsForLoadedState = ({
  paths,
  startSetResult,
  selectedComponentId,
  graphModel,
  getComponentTypeName,
  getReferenceTypeName,
}: GetPathsForLoadedState): Path[] => {
  if (startSetResult.includes(selectedComponentId) || paths.length === 0) {
    return [];
  }
  const firstTriple = paths[0][0];
  // All paths start with the same source type
  const startType =
    firstTriple.direction === 'outgoing'
      ? firstTriple.sourceType
      : firstTriple.targetType;
  const startSet = startSetResult.filter(
    id => getComponentTypeName(id) === startType
  );

  return paths.flatMap(path =>
    getPathsFromPath({
      path,
      startSet,
      selectedComponentId,
      graphModel,
      getComponentTypeName,
      getReferenceTypeName,
    })
  );
};

type GetPathsFromPathArgs = {
  path: Path;
  startSet: ArdoqId[];
  selectedComponentId: ArdoqId;
  graphModel: GraphModelShape;
  getComponentTypeName: (id: string) => string | null;
  getReferenceTypeName: (id: string) => string | null;
};

const getPathsFromPath = ({
  path,
  startSet,
  selectedComponentId,
  graphModel,
  getComponentTypeName,
  getReferenceTypeName,
}: GetPathsFromPathArgs): Path[] => {
  const { matchingPaths } = path.reduce<{
    currentStartSet: ArdoqId[];
    matchingPaths: Path[];
  }>(
    (
      { currentStartSet, matchingPaths },
      { direction, sourceType, targetType, referenceType },
      index
    ) => {
      const nextType = direction === 'outgoing' ? targetType : sourceType;
      const connectedComponents =
        direction === 'outgoing'
          ? currentStartSet.flatMap(id => graphModel.sourceMap.get(id) ?? [])
          : currentStartSet.flatMap(id => graphModel.targetMap.get(id) ?? []);
      const nextComponentIds = connectedComponents
        .filter(
          ({ componentId, referenceId }) =>
            getReferenceTypeName(referenceId) === referenceType &&
            getComponentTypeName(componentId) === nextType
        )
        .map(({ componentId }) => componentId);

      if (nextComponentIds.includes(selectedComponentId)) {
        matchingPaths.push(path.slice(0, index + 1));
      }
      return {
        currentStartSet: nextComponentIds,
        matchingPaths,
      };
    },
    { currentStartSet: startSet, matchingPaths: [] }
  );
  return matchingPaths;
};
