import { dispatchAction } from '@ardoq/rxbeach';
import { subdivisionsOperations } from '@ardoq/subdivisions';
import { SubdivisionViewModelState } from 'subdivisionEditor/types';
import { fetchStagedComponents } from './streams/actions';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';

export const searchAdvancedSearch = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  const queryBuilderRules = subdivisionsOperations.getSubdivisionSearchQuery(
    subdivisionEditorState.subdivision
  );
  if (
    !queryBuilderRules ||
    !subdivisionEditorOperations.isAdvancedSearchMode(subdivisionEditorState)
  ) {
    return;
  }
  dispatchAction(fetchStagedComponents(subdivisionEditorState));
};
