import { ErrorBoundary } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import ModuleContainer from 'appContainer/ModuleContainer';
import PresentationsOverview from './PresentationsOverview';
import PresentationOrganizer from 'presentationOrganizer/PresentationOrganizer';
import { connect } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import presentationsOverview$ from './presentationsOverview$';

type PresentationsContainerProps = {
  isInListMode: boolean;
};

const PresentationsContainer = ({
  isInListMode,
}: PresentationsContainerProps) => (
  <ModuleContainer $overflow="hidden">
    <ErrorBoundary logError={logError}>
      {isInListMode ? <PresentationsOverview /> : <PresentationOrganizer />}
    </ErrorBoundary>
  </ModuleContainer>
);

export default connect(
  PresentationsContainer,
  presentationsOverview$.pipe(
    map(({ isInListMode }) => {
      return {
        isInListMode,
      };
    })
  )
);
