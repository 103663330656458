import Workspaces from 'collections/workspaces';
import { filterNamesToEncodingIds } from 'models/filterEnums';
import { BackboneFilterAttributes, Filter } from 'aqTypes';
import {
  APIFieldType,
  BooleanOperator,
  FilterInfoOperator,
  FilterInterfaceFilter,
  QueryBuilderRule,
  FiltersBooleanRuleDataExtension,
  FiltersBooleanRuleDateRangeData,
  Operator,
  FilterTypes,
  QueryBuilderRuleValue,
  QueryBuilderSubquery,
  QueryBuilderRow,
  FiltersBooleanRuleLegacy,
  FilterAttributes,
  filterAttributesIsSubquery,
  isQueryBuilderSubquery,
} from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import {
  CommonDefaultFilterKeys,
  ComponentDefaultFilterKeys,
  ReferenceDefaultFilterKeys,
  SpecialFilterKeys,
} from '@ardoq/filter-interface';
import { fieldInterface } from '@ardoq/field-interface';

const backboneFilterValue = (filter: Filter): string => filter.get('value');
const filterAttributesValue = (filter: FilterAttributes) =>
  filter.value as string;

// We need to handle a filter that is both a backbone model with nested filters
// and a filter that isn't an initiated backbone model because
// it might be on a slide that we haven't loaded yet
// This is bad, but necessary due to filters collection

/**
 * @param {Filter | FilterAttributes} filter
 * @returns {string[]}
 */
export const getWorkspaceIdsInWorkspaceFilter = (
  filter: Filter | FilterAttributes
) => {
  if (!filter) {
    return [];
  } else if ('get' in filter) {
    return (filter.get('rules') as Filter[]).map(backboneFilterValue);
  }
  return filter.rules!.map(filterAttributesValue);
};

export const excludeMissingWorkspaceIds = (
  workspaceFilter: FilterAttributes
) => {
  return {
    ...workspaceFilter,
    rules:
      workspaceFilter.rules?.filter(rule =>
        Workspaces.collection.get(rule.value as string | number)
      ) || [],
  };
};

const createFilterForDateRangeFilterType = (
  name: string,
  operatorMappedFromComparator: Operator,
  value: QueryBuilderRuleValue
): QueryBuilderRule => {
  // Merge two filters into one
  return {
    type: 'date',
    input: 'text',
    id: name,
    field: name,
    operator: operatorMappedFromComparator,
    value: value as string | number, // those types are inconsistent across different Filter representations
  };
};

const createFilterForNullValueAndEqualsComparator = (
  name: string,
  isNegative: boolean
): QueryBuilderRule => {
  return {
    id: name,
    field: name,
    type: FilterTypes.ATTRIBUTE,
    input: 'text',
    operator: isNegative ? Operator.IS_NOT_EMPTY : Operator.IS_EMPTY,
    value: null,
  };
};

const createFilterForTagType = (
  operatorMappedFromComparator: Operator,
  value: QueryBuilderRuleValue
): QueryBuilderRule => {
  return {
    id: SpecialFilterKeys.TAGS,
    field: SpecialFilterKeys.TAGS,
    type: 'string',
    input: 'text',
    operator: operatorMappedFromComparator,
    value: value as string | number, // those types are inconsistent across different Filter representations
  };
};

const createFilterRuleWithCalculatedTypeAndInput = (
  name: string,
  comparator: string | undefined,
  operator: Operator,
  value: QueryBuilderRuleValue
): QueryBuilderRule => {
  const { type, input } = Object.prototype.hasOwnProperty.call(
    filterNamesToEncodingIds,
    name
  )
    ? getTypeAndInputFromName(name, comparator ?? '')!
    : getTypeAndInputFromField(name);

  return {
    id: name,
    field: name,
    type,
    input,
    operator,
    value: value as string | number, // those types are inconsistent across different Filter representations
  };
};

/**
 * This function is not recursive, as FilterInterfaceFilter does not have nested rules.
 */
const filterToQueryBuilderRule = (
  filter: FilterInterfaceFilter
): QueryBuilderRule => {
  const { name, value, isNegative } = filter;
  const operatorMappedFromComparator = getOperatorFromComparator(
    filter.comparator ?? '',
    isNegative
  );

  if (filter.type === FilterTypes.DATE_RANGE) {
    return createFilterForDateRangeFilterType(
      name,
      operatorMappedFromComparator,
      value
    );
  }

  if (filter.comparator === FilterInfoOperator.EQUALS && value === null) {
    return createFilterForNullValueAndEqualsComparator(name, isNegative);
  }

  if (filter.type === FilterTypes.TAG) {
    return createFilterForTagType(operatorMappedFromComparator, value);
  }

  return createFilterRuleWithCalculatedTypeAndInput(
    name,
    filter.comparator,
    operatorMappedFromComparator,
    value
  );
};

const backboneFilterToQueryBuilderRow = (
  filter: Filter
): FiltersBooleanRuleLegacy | QueryBuilderSubquery => {
  if (filter.get('type') === FilterTypes.DATE_RANGE) {
    const { name, value, comparator, isNegative } = filter.attributes;
    const operatorMappedFromComparator = getOperatorFromComparator(
      comparator,
      isNegative
    );
    return {
      ...createFilterForDateRangeFilterType(
        name,
        operatorMappedFromComparator,
        value
      ),
      data: { cid: filter.cid },
    };
  }
  const rules = filter.attributes.rules;
  if (rules) {
    return {
      condition: filter.attributes.condition,
      rules: rules.map(backboneFilterToQueryBuilderRow),
    };
  }
  const name: string = filter.attributes.name;
  const isNegative: boolean = filter.attributes.isNegative;

  const { value, comparator } = filter.attributes;
  const operatorMappedFromComparator = getOperatorFromComparator(
    comparator,
    isNegative
  );

  if (comparator === FilterInfoOperator.EQUALS && value === null) {
    return {
      ...createFilterForNullValueAndEqualsComparator(name, isNegative),
      data: { cid: filter.cid },
    };
  }

  if (filter.attributes.type === FilterTypes.TAG) {
    return createFilterForTagType(operatorMappedFromComparator, value);
  }

  return {
    ...createFilterRuleWithCalculatedTypeAndInput(
      name,
      comparator,
      operatorMappedFromComparator,
      value
    ),
    data: { cid: filter.cid },
  };
};

/**
 * Parses FilterAttributes into QueryBuilderRow.
 * It assumes the input attributes are not conditional formatting nor label
 * formatting.
 */
const filterAttributesToQueryBuilderRow = (
  filter: FilterAttributes
): QueryBuilderRow => {
  if (filterAttributesIsSubquery(filter)) {
    return {
      condition: filter.condition,
      rules: filter.rules.map(filterAttributesToQueryBuilderRow),
    };
  }

  /**
   * The only case when name is undefined happens for nested rules that have only condition and rules properties.
   * As we have an early return for this case above, we're sure that name is not optional.
   */
  const name = filter.name as string;
  const isNegative = filter.isNegative ?? false;

  // Type-cast because the kind of FilterAttributes that are involved in
  // boolean rules will have a value property, but FilterAttributes is too
  // generic to enforce this.
  const value = filter.value as QueryBuilderRuleValue;

  const { comparator } = filter;

  const operatorOrMappedComparator = getOperatorFromComparator(
    comparator ?? '',
    isNegative
  );

  if (filter.type === FilterTypes.DATE_RANGE) {
    // Merge two filters into one
    return createFilterForDateRangeFilterType(
      name,
      operatorOrMappedComparator,
      value
    );
  }

  if (comparator === FilterInfoOperator.EQUALS && value === null) {
    return createFilterForNullValueAndEqualsComparator(name, isNegative);
  }

  if (filter.type === FilterTypes.TAG) {
    return createFilterForTagType(operatorOrMappedComparator, value);
  }

  return createFilterRuleWithCalculatedTypeAndInput(
    name,
    filter.comparator,
    operatorOrMappedComparator,
    value
  );
};

const getTypeAndInputFromField = (fieldName: string) => {
  const field = fieldInterface.getByName(fieldName, { acrossWorkspaces: true });

  return getTypeAndInputFromFieldType(field ? field.type : null);
};

const getTypeAndInputFromFieldType = (fieldType: APIFieldType | null) => {
  switch (fieldType) {
    case APIFieldType.LIST:
    case APIFieldType.SELECT_MULTIPLE_LIST:
      return {
        type: 'string',
        input: 'select',
      };

    case APIFieldType.DATE_ONLY:
    case APIFieldType.DATE_TIME:
      return {
        type: 'date',
        input: 'text',
      };

    case APIFieldType.NUMBER:
      return {
        type: 'double',
        input: 'number',
      };

    case APIFieldType.CHECKBOX:
      return {
        type: 'boolean',
        input: 'select',
      };

    default:
      return {
        type: 'string',
        input: 'text',
      };
  }
};

const getOperatorFromComparator = (
  comparator: string,
  isNegative: boolean
): Operator => {
  switch (comparator) {
    case 'idMatch':
    case 'tag':
      return isNegative ? Operator.NOT_EQUAL : Operator.EQUAL;

    case 'dateMatch':
      return Operator.EQUAL;

    case 'dateEarlier':
      return Operator.LESS;

    case 'dateLater':
      return Operator.GREATER;

    case 'boolean':
      return Operator.EQUAL;

    case Operator.CONTAINS:
      return isNegative ? Operator.NOT_CONTAINS : Operator.CONTAINS;

    case 'equals':
      return isNegative ? Operator.NOT_EQUAL : Operator.EQUAL;

    case 'lessThan':
      return isNegative ? Operator.GREATER_OR_EQUAL : Operator.LESS;

    case 'greaterThan':
      return isNegative ? Operator.LESS_OR_EQUAL : Operator.GREATER;
  }

  // In practice the return value of this function is always casted as it is
  // assumed this will be a filter representing a QueryBuilderRow.
  // TODO: sseppola After 2024-08-21 if this log never appears this can be
  // removed and throw an error instead.
  logError(new Error('Cannot map comparator to operator'), null, {
    comparator,
    isNegative,
  });
  return undefined as unknown as Operator;
};

const getTypeAndInputFromName = (name: string, comparator?: string) => {
  if (comparator === 'boolean') {
    return {
      type: 'boolean',
      input: 'select',
    };
  }

  switch (name) {
    case CommonDefaultFilterKeys.ID:
    case ComponentDefaultFilterKeys.VERSION:
    case CommonDefaultFilterKeys.CREATED_BY:
    case CommonDefaultFilterKeys.DESCRIPTION:
    case ReferenceDefaultFilterKeys.DISPLAY_TEXT:
    case ComponentDefaultFilterKeys.NAME:
    case ComponentDefaultFilterKeys.PARENT:
    case CommonDefaultFilterKeys.ROOT_WORKSPACE:
    case ReferenceDefaultFilterKeys.SOURCE:
    case CommonDefaultFilterKeys.TAG:
    case ReferenceDefaultFilterKeys.TARGET:
    case ReferenceDefaultFilterKeys.TARGET_WORKSPACE:
    case SpecialFilterKeys.COMPONENT_HAS_REFERENCE_FROM:
    case SpecialFilterKeys.COMPONENT_HAS_REFERENCE_TO:
    case CommonDefaultFilterKeys.TYPE:
      return {
        type: 'string',
        input: 'text',
      };

    case CommonDefaultFilterKeys.LAST_UPDATED:
    case CommonDefaultFilterKeys.CREATED:
      return {
        type: 'date',
        input: 'text',
      };

    case ComponentDefaultFilterKeys.INCOMING_REF_COUNT:
    case ComponentDefaultFilterKeys.OUTGOING_REF_COUNT:
      return {
        type: 'double',
        input: 'number',
      };
  }
};

// Note: We Pick the relevant fields from QueryBuilderRule because in
// practice it is used like this.
const convertJqToArdoq = (
  rule: Pick<QueryBuilderRule, 'operator' | 'id' | 'value' | 'type'>
): FilterAttributes | undefined => {
  if (
    rule.operator === Operator.IS_EMPTY ||
    rule.operator === Operator.IS_NOT_EMPTY
  ) {
    return {
      comparator: FilterInfoOperator.EQUALS,
      value: null,
      type: FilterTypes.ATTRIBUTE,
      isNegative: rule.operator === Operator.IS_NOT_EMPTY,
    };
  }

  switch (rule.id) {
    case CommonDefaultFilterKeys.TYPE:
    case CommonDefaultFilterKeys.ID:
    case ComponentDefaultFilterKeys.PARENT:
    case ReferenceDefaultFilterKeys.TARGET:
    case ReferenceDefaultFilterKeys.SOURCE:
    case ReferenceDefaultFilterKeys.TARGET_WORKSPACE:
    case CommonDefaultFilterKeys.ROOT_WORKSPACE:
      return {
        comparator: FilterInfoOperator.ID_MATCH,
        type: FilterTypes.ATTRIBUTE,
        isNegative: rule.operator === Operator.NOT_EQUAL,
      };

    case SpecialFilterKeys.TAGS:
      return {
        type: FilterTypes.TAG,
        isNegative: rule.operator === Operator.NOT_EQUAL,
      };

    default:
      switch (rule.type) {
        case 'date':
          switch (rule.operator) {
            case Operator.EQUAL:
              return {
                comparator: FilterInfoOperator.DATE_MATCH,
                isNegative: false,
                type: FilterTypes.ATTRIBUTE,
              };

            case Operator.LESS:
              return {
                comparator: FilterInfoOperator.DATE_EARLIER,
                isNegative: false,
                type: FilterTypes.ATTRIBUTE,
              };

            case Operator.GREATER:
              return {
                comparator: FilterInfoOperator.DATE_LATER,
                isNegative: false,
                type: FilterTypes.ATTRIBUTE,
              };
            case Operator.CONTAINS:
              return {
                comparator: FilterInfoOperator.CONTAINS,
                isNegative: false,
                type: FilterTypes.DATE_RANGE,
              };
            case Operator.NOT_CONTAINS:
              return {
                comparator: FilterInfoOperator.CONTAINS,
                isNegative: true,
                type: FilterTypes.DATE_RANGE,
              };
            default:
              logError(
                new Error(`Unsupported operator rule for type date`),
                null,
                { ruleType: rule.type, operator: rule.operator }
              );
          }
          break;

        case 'boolean':
          return {
            comparator: FilterInfoOperator.BOOLEAN,
            isNegative: false,
            type: FilterTypes.ATTRIBUTE,
          };

        default:
        // pass
      }

      switch (rule.operator) {
        case Operator.CONTAINS:
          return {
            comparator: FilterInfoOperator.CONTAINS,
            isNegative: false,
            type: FilterTypes.ATTRIBUTE,
          };

        case Operator.NOT_CONTAINS:
          return {
            comparator: FilterInfoOperator.CONTAINS,
            isNegative: true,
            type: FilterTypes.ATTRIBUTE,
          };

        case Operator.EQUAL:
          return {
            comparator: FilterInfoOperator.EQUALS,
            isNegative: false,
            type: FilterTypes.ATTRIBUTE,
          };

        case Operator.NOT_EQUAL:
          return {
            comparator: FilterInfoOperator.EQUALS,
            isNegative: true,
            type: FilterTypes.ATTRIBUTE,
          };

        case Operator.LESS:
          return {
            comparator: FilterInfoOperator.LESS_THAN,
            isNegative: false,
            type: FilterTypes.ATTRIBUTE,
          };

        case Operator.LESS_OR_EQUAL:
          return {
            comparator: FilterInfoOperator.GREATER_THAN,
            isNegative: true,
            type: FilterTypes.ATTRIBUTE,
          };

        case Operator.GREATER:
          return {
            comparator: FilterInfoOperator.GREATER_THAN,
            isNegative: false,
            type: FilterTypes.ATTRIBUTE,
          };

        case Operator.GREATER_OR_EQUAL:
          return {
            comparator: FilterInfoOperator.LESS_THAN,
            isNegative: true,
            type: FilterTypes.ATTRIBUTE,
          };
      }
  }
};

/**
 * @deprecated suspected dead-code
 */
export type DateRangeRule = QueryBuilderRule & {
  data: FiltersBooleanRuleDateRangeData;
};

// TODO: sseppola This predicate protects a bunch of suspected dead code.
// If this does not show up in our logs by 2024-08-07 we should remove it
// along with the code it protects.
/**
 * @deprecated suspected dead-code
 */
export const isDateRangeRule = (
  rule: FiltersBooleanRuleDataExtension
): rule is DateRangeRule => {
  const foundDateRangeRule = Boolean(rule.data && 'isDateRange' in rule.data);
  if (foundDateRangeRule) {
    logError(new Error('Found zombie date range rule'), null, rule);
  }
  return foundDateRangeRule;
};

const toFilterAttributes =
  (affectComponent: boolean) => (rule: QueryBuilderRow) => {
    if (isQueryBuilderSubquery(rule)) {
      return booleanSubqueryToFilterAttributes(rule, affectComponent)!;
    }
    if (isDateRangeRule(rule as FiltersBooleanRuleLegacy)) {
      return createDateRangeFilter(rule as DateRangeRule, affectComponent);
    }
    return {
      affectComponent,
      affectReference: !affectComponent,
      name:
        rule.id === SpecialFilterKeys.TAGS ? (rule.value as string) : rule.id,
      value: rule.value,
      ...convertJqToArdoq(rule),
    };
  };

const booleanSubqueryToFilterAttributes = (
  rule: QueryBuilderSubquery,
  affectComponent = true
): FilterAttributes | null =>
  rule
    ? {
        condition: rule.condition as BooleanOperator,
        affectComponent: affectComponent,
        affectReference: !affectComponent,
        type: FilterTypes.ATTRIBUTE,
        rules: rule.rules!.map(toFilterAttributes(affectComponent)),
      }
    : null;

/**
 * @deprecated suspected dead-code
 */
const createDateRangeContainsFilter = (
  { data, value, type, operator, id }: DateRangeRule,
  affectComponent: boolean
): FilterAttributes => {
  const { dateRangeStartFieldName, dateRangeEndFieldName } = data;
  const isNegative = operator === Operator.NOT_CONTAINS;

  return {
    condition: isNegative ? BooleanOperator.OR : BooleanOperator.AND,
    affectComponent,
    affectReference: !affectComponent,
    name: id,
    value,
    rules: [
      {
        value,
        affectComponent,
        isNegative,
        name: dateRangeStartFieldName,
        comparator: FilterInfoOperator.DATE_EARLIER,
        type: FilterTypes.ATTRIBUTE,
      },
      {
        value,
        affectComponent,
        isNegative,
        name: dateRangeEndFieldName,
        comparator: FilterInfoOperator.DATE_LATER,
        type: FilterTypes.ATTRIBUTE,
      },
    ],
    // At this point id and type could be anything, but we know that
    // operator is one of: Operator.CONTAINS | Operator.NOT_CONTAINS
    ...convertJqToArdoq({ type, operator, id, value }),
  };
};

/**
 * @deprecated suspected dead-code
 */
const createDateRangeBeforeOrAfterFilter = (
  rule: DateRangeRule,
  affectComponent: boolean
): FilterAttributes => {
  const { data, value, id, operator } = rule;
  const { dateRangeStartFieldName, dateRangeEndFieldName } = data;
  return {
    condition: BooleanOperator.AND,
    affectComponent,
    affectReference: !affectComponent,
    name: id,
    comparator:
      operator === Operator.LESS
        ? FilterInfoOperator.DATE_EARLIER
        : FilterInfoOperator.DATE_LATER,
    type: FilterTypes.DATE_RANGE,
    value,
    rules: [
      {
        name:
          operator === Operator.LESS
            ? dateRangeEndFieldName
            : dateRangeStartFieldName,
        affectComponent,
        value,
        ...convertJqToArdoq(rule),
      },
    ],
  };
};
/**
 * @deprecated suspected dead-code
 */
const createDateRangeFilter = (
  rule: DateRangeRule,
  affectComponent: boolean
): FilterAttributes =>
  rule.operator === Operator.CONTAINS || rule.operator === Operator.NOT_CONTAINS
    ? createDateRangeContainsFilter(rule, affectComponent)
    : createDateRangeBeforeOrAfterFilter(rule, affectComponent);

/**
 * Extract nested attributes from nested Filter model.
 *
 * WARNING: This function retains a reference to the attributes of the BB model,
 * which means it can change magically, and mutating it can mess with how the
 * BB model works on it.
 * In this case we are sending it over the Post Message Bridge, which uses
 * structuredClone to create a deep copy.
 */
const backboneFilterToFilterAttributes = (model: Filter): FilterAttributes => {
  // Taking attributes from a BackboneFilter we risk having nested rules
  // of type BackboneFilters
  const bbAttributes = model.attributes as BackboneFilterAttributes;
  if (bbAttributes.rules) {
    return {
      ...bbAttributes,
      rules: bbAttributes.rules.map(backboneFilterToFilterAttributes),
    };
  }

  // Safe-type cast: Omit<BackboneFilterAttributes, 'rules'> satisfies FilterAttributes
  return bbAttributes as FilterAttributes;
};

export {
  booleanSubqueryToFilterAttributes,
  filterToQueryBuilderRule,
  backboneFilterToQueryBuilderRow,
  backboneFilterToFilterAttributes,
  filterAttributesToQueryBuilderRow,
};
