import { trackEvent } from '../tracking/tracking';
import { FilterState, SetAuditLogFilterPayload } from './types';
import { isEmpty, isEqual } from 'lodash';
import { emptyFilterState } from './consts';

export enum AuditLogTracking {
  CLICKED_AUDIT_LOG_MENU_ITEM = 'clicked_audit_log_menu_item',
  FILTER = 'audit_log_filter',
  NAME_SEARCH = 'audit_log_name_search',
  SET_PAGE_SIZE = 'audit_log_set_page_size',
  SWITCH_PAGE = 'audit_log_switch_page',
  CHANGE_TYPE_FILTER = 'audit_log_change_type_filter',
  ENTRY_POINT = 'audit_log_entry_point',
}

export const trackFilterUsage = (filters: SetAuditLogFilterPayload) => {
  const enabledFilters = Object.fromEntries(
    Object.entries(filters)
      .map(([key, value]) => [
        key,
        !isEqual(value, emptyFilterState[key as keyof FilterState]),
      ])
      .filter(([, value]) => value)
  );
  if (!isEmpty(enabledFilters))
    trackEvent(AuditLogTracking.FILTER, enabledFilters);
};

export const trackAuditLogEntryPoint = (entryPoint: string) => {
  trackEvent(AuditLogTracking.ENTRY_POINT, { entryPoint });
};
