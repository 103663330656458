import { dispatchAction } from '@ardoq/rxbeach';
import {
  addRequiredComponent,
  graphNodeHovered,
  removeRequiredComponent,
} from './requiredComponentsActions';
import { TraversalPathMatchingType } from '@ardoq/api-types';
import { setPathMatchingType } from '../traversals/editTraversalActions';

export type RequiredComponentsCommands = {
  toggleIsSelectedGraphNode: (nodeId: string) => void;
  removeRequiredComponent: (nodeId: string) => void;
  setPathMatching: (pathMatching: TraversalPathMatchingType) => void;
  onGraphNodeHover: (nodeId: string | null) => void;
  highlightPath: (nodeId: string | null) => void;
};

export const requiredComponentsCommands: RequiredComponentsCommands = {
  toggleIsSelectedGraphNode: (nodeId: string) => {
    dispatchAction(addRequiredComponent(nodeId));
  },
  removeRequiredComponent: (nodeId: string) => {
    dispatchAction(removeRequiredComponent(nodeId));
  },
  setPathMatching: (pathMatching: TraversalPathMatchingType) => {
    dispatchAction(setPathMatchingType(pathMatching));
  },
  onGraphNodeHover: (nodeId: string | null) => {
    dispatchAction(graphNodeHovered(nodeId));
  },
  highlightPath: (nodeId: string | null) => {
    dispatchAction(graphNodeHovered(nodeId));
  },
};
