import { actionCreator, reducer, action$, reduceState } from '@ardoq/rxbeach';
import { Observable } from 'rxjs';
import { ArdoqId, AssetType, SortOrder, SortParams } from '@ardoq/api-types';

export type ExpandedFoldersFiltersByType = {
  [AssetType.WORKSPACE]: boolean;
  [AssetType.PRESENTATION]: boolean;
  [AssetType.SURVEY]: boolean;
  [AssetType.METAMODEL]: boolean;
  [AssetType.SCENARIO]: boolean;
  [AssetType.REPORT]: boolean;
  [AssetType.DASHBOARD]: boolean;
  [AssetType.TRAVERSAL]: boolean;
  [AssetType.BOOKMARK]: boolean;
};

export type ExpandedFoldersState = {
  expandedFoldersIds: ArdoqId[];
  expandedFoldersSearchPhrase: string;
  expandedFoldersSortParams: SortParams;
  expandedFoldersFiltersByType: ExpandedFoldersFiltersByType;
  expandedFoldersShowFavoritesOnly: boolean;
};

type PayloadUpdateExpandedFoldersIds = {
  foldersIds: string[];
};

type PayloadUpdateExpandedFoldersSearchPhrase = {
  searchPhrase: string;
};

type PayloadUpdateExpandedFoldersSortParams = {
  sortParams: SortParams;
};

type PayloadUpdateExpandedFoldersFiltersByType = {
  filtersByType: ExpandedFoldersFiltersByType | null;
};

type PayloadUpdateExpandedFoldersShowFavoritesOnly = {
  showFavoritesOnly: boolean;
};

// Actions creators
export const updateExpandedFoldersIds =
  actionCreator<PayloadUpdateExpandedFoldersIds>(
    '[expandedFolders$] UPDATE_EXPANDED_FOLDERS_IDS'
  );

export const updateExpandedFoldersSearchPhrase =
  actionCreator<PayloadUpdateExpandedFoldersSearchPhrase>(
    '[expandedFolders$] UPDATE_EXPANDED_FOLDERS_SEARCH_PHRASE'
  );

export const showOnlySpecificAssetTypes = actionCreator<AssetType[]>(
  '[expandedFolders$] SHOW_ONLY_ONE_ASSET_TYPE'
);

export const updateExpandedFoldersSortParams =
  actionCreator<PayloadUpdateExpandedFoldersSortParams>(
    '[expandedFolders$] UPDATE_EXPANDED_FOLDERS_SORT_PARAMS'
  );

export const updateExpandedFoldersFiltersByType =
  actionCreator<PayloadUpdateExpandedFoldersFiltersByType>(
    '[expandedFolders$] UPDATE_EXPANDED_FOLDERS_FILTERS_BY_TYPE'
  );

export const updateExpandedFoldersShowFavoritesOnly =
  actionCreator<PayloadUpdateExpandedFoldersShowFavoritesOnly>(
    '[expandedFolders$] UPDATE_EXPANDED_FOLDERS_SHOW_FAVORITES_ONLY'
  );

// Reducers
const updateExpandedFoldersIdsReducer = (
  currentState: ExpandedFoldersState,
  { foldersIds }: PayloadUpdateExpandedFoldersIds
) => ({
  ...currentState,
  expandedFoldersIds: foldersIds,
});

const updateExpandedFoldersSearchPhraseReducer = (
  currentState: ExpandedFoldersState,
  { searchPhrase }: PayloadUpdateExpandedFoldersSearchPhrase
) => ({
  ...currentState,
  expandedFoldersSearchPhrase: searchPhrase,
});

const updateExpandedFoldersSortParamsReducer = (
  currentState: ExpandedFoldersState,
  { sortParams }: PayloadUpdateExpandedFoldersSortParams
) => ({
  ...currentState,
  expandedFoldersSortParams: sortParams,
});

const updateExpandedFoldersFiltersByTypeReducer = (
  currentState: ExpandedFoldersState,
  { filtersByType }: PayloadUpdateExpandedFoldersFiltersByType
) => ({
  ...currentState,
  expandedFoldersFiltersByType:
    filtersByType ?? defaultState.expandedFoldersFiltersByType,
});

const updateExpandedFoldersShowFavoritesOnlyReducer = (
  currentState: ExpandedFoldersState,
  { showFavoritesOnly }: PayloadUpdateExpandedFoldersShowFavoritesOnly
) => ({
  ...currentState,
  expandedFoldersShowFavoritesOnly: showFavoritesOnly,
});

const showOnlySpecificAssetTypesReducer = (
  currentState: ExpandedFoldersState,
  assetTypes: AssetType[]
) => ({
  ...currentState,
  expandedFoldersFiltersByType: {
    ...defaultState.expandedFoldersFiltersByType,
    ...(Object.fromEntries(
      assetTypes.map(assetType => [assetType, true])
    ) as ExpandedFoldersFiltersByType),
  },
});

const defaultState: ExpandedFoldersState = {
  expandedFoldersShowFavoritesOnly: false,
  expandedFoldersIds: [],
  expandedFoldersSearchPhrase: '',
  expandedFoldersFiltersByType: {
    [AssetType.WORKSPACE]: false,
    [AssetType.PRESENTATION]: false,
    [AssetType.SURVEY]: false,
    [AssetType.METAMODEL]: false,
    [AssetType.SCENARIO]: false,
    [AssetType.REPORT]: false,
    [AssetType.DASHBOARD]: false,
    [AssetType.TRAVERSAL]: false,
    [AssetType.BOOKMARK]: false,
  },
  expandedFoldersSortParams: {
    sortOrder: SortOrder.ASC,
    sortByField: 'name',
  },
};

const expandedFolders$: Observable<ExpandedFoldersState> = action$.pipe(
  reduceState('expandedFolders$', defaultState, [
    reducer(updateExpandedFoldersIds, updateExpandedFoldersIdsReducer),
    reducer(
      updateExpandedFoldersSearchPhrase,
      updateExpandedFoldersSearchPhraseReducer
    ),
    reducer(
      updateExpandedFoldersSortParams,
      updateExpandedFoldersSortParamsReducer
    ),
    reducer(
      updateExpandedFoldersFiltersByType,
      updateExpandedFoldersFiltersByTypeReducer
    ),
    reducer(
      updateExpandedFoldersShowFavoritesOnly,
      updateExpandedFoldersShowFavoritesOnlyReducer
    ),
    reducer(showOnlySpecificAssetTypes, showOnlySpecificAssetTypesReducer),
  ])
);

export default expandedFolders$;
