import { useEffect } from 'react';
import DetailEntryForm from './DetailEntryForm';
import {
  APIDiscoverViewpointAttributes,
  ComponentTypeDetailEntry,
  ReferenceTypeDetailEntry,
  ViewpointDetailEntry,
} from '@ardoq/api-types';
import { s16, s24 } from '@ardoq/design-tokens';
import {
  getSelectedComponentTypeNames,
  getSelectedReferenceTypeNames,
} from 'viewpoints/utils';
import { header3 } from '@ardoq/typography';
import styled from 'styled-components';
import { Label, Switch } from '@ardoq/forms';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { useController, useFormContext } from 'react-hook-form';

const Header = styled.h3`
  ${header3};
  margin-bottom: ${s24};
`;

const SubHeader = styled.h4`
  ${header3};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${s16};
  margin-bottom: 40px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

const isComponentTypeDetailEntry = (
  detailEntry: ViewpointDetailEntry
): detailEntry is ComponentTypeDetailEntry =>
  'componentTypeName' in detailEntry;

const isReferenceTypeDetailEntry = (
  detailEntry: ViewpointDetailEntry
): detailEntry is ReferenceTypeDetailEntry =>
  'referenceTypeName' in detailEntry;

const DetailEntriesForm = () => {
  const {
    field: { onChange },
  } = useController({ name: 'detailEntries' });
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const [workspaceIds, dynamicGroupedTraversals, detailEntries = []] = watch([
    'workspaceIds',
    'dynamicGroupedTraversals',
    'detailEntries',
  ]);

  const componentTypeOptions = getSelectedComponentTypeNames(
    dynamicGroupedTraversals
  );

  const componentTypeDetailEntries = detailEntries.filter(
    isComponentTypeDetailEntry
  );

  const nonParentReferenceTypeOptions = getSelectedReferenceTypeNames(
    dynamicGroupedTraversals
  ).filter(refType => refType !== 'ardoq_parent');

  const referenceTypeDetailEntries = detailEntries.filter(
    isReferenceTypeDetailEntry
  );

  useEffect(() => {
    const correctComponentTypeDetailEntries =
      componentTypeDetailEntries.length === componentTypeOptions.length &&
      componentTypeDetailEntries.every(detailEntry =>
        componentTypeOptions.includes(detailEntry.componentTypeName)
      );

    const correctReferenceTypeDetailEntries =
      referenceTypeDetailEntries.length ===
        nonParentReferenceTypeOptions.length &&
      referenceTypeDetailEntries.every(detailEntry =>
        nonParentReferenceTypeOptions.includes(detailEntry.referenceTypeName)
      );

    if (
      !correctComponentTypeDetailEntries ||
      !correctReferenceTypeDetailEntries
    ) {
      const newComponentTypeDetailEntries = componentTypeOptions
        .map(componentType => {
          const existingDetailEntry = componentTypeDetailEntries.find(
            detailEntry => detailEntry.componentTypeName === componentType
          );
          if (existingDetailEntry) return existingDetailEntry;

          return { componentTypeName: componentType, scope: 'none' };
        })
        .filter(ExcludeFalsy);

      const newReferenceTypeDetailEntries = nonParentReferenceTypeOptions
        .map(referenceType => {
          const existingDetailEntry = referenceTypeDetailEntries.find(
            detailEntry => detailEntry.referenceTypeName === referenceType
          );
          if (existingDetailEntry) return existingDetailEntry;

          return { referenceTypeName: referenceType, scope: 'none' };
        })
        .filter(ExcludeFalsy);

      onChange([
        ...newComponentTypeDetailEntries,
        ...newReferenceTypeDetailEntries,
      ]);
    }
  }, [
    componentTypeDetailEntries,
    componentTypeOptions,
    onChange,
    referenceTypeDetailEntries,
    nonParentReferenceTypeOptions,
  ]);

  const updateDetailEntries = (
    nextComponentTypeDetailEntries: ComponentTypeDetailEntry[],
    nextReferenceTypeDetailEntries: ReferenceTypeDetailEntry[]
  ) => {
    onChange([
      ...nextComponentTypeDetailEntries,
      ...nextReferenceTypeDetailEntries,
    ]);
  };

  return componentTypeOptions.length ? (
    <>
      <Header>Select and arrange fields in your preferred order</Header>
      {componentTypeDetailEntries.length ? (
        <>
          <SubHeader>Component Types</SubHeader>
          <Wrapper>
            {componentTypeDetailEntries.map((detailEntry, index) => (
              <DetailEntryForm
                key={index}
                entityName={detailEntry.componentTypeName}
                detailEntry={detailEntry}
                workspaceIds={workspaceIds}
                onDetailEntryChange={selectedDetailEntry => {
                  const updatedCompTypeDetailEntries = [
                    ...componentTypeDetailEntries.slice(0, index),
                    selectedDetailEntry as ComponentTypeDetailEntry,
                    ...componentTypeDetailEntries.slice(index + 1),
                  ];
                  updateDetailEntries(
                    updatedCompTypeDetailEntries,
                    referenceTypeDetailEntries
                  );
                }}
                detailEntryType="component"
              />
            ))}
          </Wrapper>
        </>
      ) : null}
      {referenceTypeDetailEntries.length ? (
        <>
          <SubHeader>Reference Types</SubHeader>
          <Wrapper>
            {referenceTypeDetailEntries.map((detailEntry, index) => (
              <DetailEntryForm
                key={index}
                entityName={detailEntry.referenceTypeName}
                detailEntry={detailEntry}
                workspaceIds={workspaceIds}
                onDetailEntryChange={selectedDetailEntry => {
                  const updatedRefTypeDetailEntries = [
                    ...referenceTypeDetailEntries.slice(0, index),
                    selectedDetailEntry as ReferenceTypeDetailEntry,
                    ...referenceTypeDetailEntries.slice(index + 1),
                  ];
                  updateDetailEntries(
                    componentTypeDetailEntries,
                    updatedRefTypeDetailEntries
                  );
                }}
                detailEntryType="reference"
              />
            ))}
          </Wrapper>
        </>
      ) : null}
      <Row>
        <Label>Field Descriptions</Label>
        <Switch
          isChecked={detailEntries.some(
            detailEntry => detailEntry.useFieldDescriptions
          )}
          name="field-descriptions"
          onChange={isChecked => {
            const updatedDetailEntries = detailEntries.map(detailEntry => ({
              ...detailEntry,
              useFieldDescriptions: isChecked,
            }));
            onChange(updatedDetailEntries);
          }}
          label="Display field descriptions in the sidebar"
        />
      </Row>
    </>
  ) : null;
};

export default DetailEntriesForm;
