import { DoqWithSpeechBubble } from '@ardoq/doq';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { dispatchAction } from '@ardoq/rxbeach';
import { closeMergeFlow } from 'scope/merge/actions';
import { EmptyPageWrapper } from './atoms';

const NothingToMerge = ({
  mergeDirection,
}: {
  mergeDirection: MergeDirection;
}) => {
  const [sourceName, targetName] =
    mergeDirection === MergeDirection.BRANCH_TO_MAINLINE
      ? ['Scenario', 'Mainline']
      : ['Mainline', 'Scenario'];

  return (
    <EmptyPageWrapper>
      <DoqWithSpeechBubble
        title="You have nothing to merge"
        message={`Your ${sourceName} is up to date with the changes in the ${targetName}, so there's nothing to merge.`}
        buttonLabel="Go back to Scenario"
        onButtonClick={() => {
          dispatchAction(closeMergeFlow({ shouldMoveBranchOffPoint: false }));
        }}
      />
    </EmptyPageWrapper>
  );
};

export default NothingToMerge;
