import { confirm } from '@ardoq/modal';

const confirmUnpublishAndEditBroadcast = (hasReminders: boolean) => {
  const reminderExplanation = hasReminders
    ? ' Unsent reminders for previous runs of this broadcast will still work.'
    : '';

  return confirm({
    title: 'Edit broadcast',
    text: `Editing the broadcast will change its status to "unpublished".${reminderExplanation}`,
  });
};

export default confirmUnpublishAndEditBroadcast;
