import { createRef, Component } from 'react';
import styled from 'styled-components';
import Details from './Details';
import SlidesContainer from './SlidesContainer';
import { colors } from '@ardoq/design-tokens';
import DeprecatedSlidesNotification from 'viewDeprecation/components/DeprecatedSlidesNotification';
import {
  getMinimumSlideKillDateForPresentation,
  getOverallPresentationViewInfo,
} from 'viewDeprecation/DepreciatedSlideNotificationUtil';
import { getDeprecatedSlidesInfo } from 'viewDeprecation/util';
import { startPresentation } from 'presentation/utils';
import { Subscription } from 'rxjs';
import {
  clearSubscriptions,
  subscribeToAction,
} from 'streams/utils/streamUtils';
import {
  deleteSlideInPresentationEditor,
  replaceSlideWithCurrentContext,
} from 'presentationEditor/actions';
import { PresentationSlideViewInfo } from 'viewDeprecation/types';
import { trackClickedPlayPresentation } from '../../../presentation/tracking';
import { FullHeightStack } from '../components/atoms';
import { Header3 } from '@ardoq/typography';
import { ButtonType, IconButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import { TextOverflow } from '@ardoq/popovers';
import {
  ArdoqId,
  APIPresentationAssetAttributes,
  APISlideAttributes,
} from '@ardoq/api-types';
import { FlexBox } from '@ardoq/layout';
import { TabsGroup, Tab, TabsTheme } from '@ardoq/tabs';
import { presentationAccessControlOperations } from 'resourcePermissions/accessControlHelpers/presentations';
import { PermissionContext } from '@ardoq/access-control';
import { trackEvent } from 'tracking/tracking';
import { UnauthorizedSlideType } from '../types';
import { ExcludeFalsy } from '@ardoq/common-helpers';

enum Tabs {
  DETAILS = 'Details',
  SLIDES = 'Slides',
}

interface PresentationSidebarState {
  activeTab: Tabs;
  height: number;
  deprecatedSlides: PresentationSlideViewInfo[];
}

const SidebarHeader = styled.div`
  background-color: ${colors.white};
`;

const UNAUTHORIZED_SLIDE = { unauthorized: true };

export const validSlides = (
  presentation: APIPresentationAssetAttributes,
  slidesById: Record<ArdoqId, APISlideAttributes>
) => {
  const newSlideIds = presentation.slides;
  const authorizedSlides = presentation.authorizedSlides ?? [];

  const slides = newSlideIds
    .map(slideId => {
      const retrievedSlide = slidesById[slideId];
      const slideAuthorized = authorizedSlides.includes(slideId);
      return slideAuthorized ? retrievedSlide : UNAUTHORIZED_SLIDE;
    })
    .filter(ExcludeFalsy);
  return slides;
};

interface ActivePresentationProps {
  viewIsSupported?: boolean;
  presentation: APIPresentationAssetAttributes;
  slides: (APISlideAttributes | UnauthorizedSlideType)[];
  onSlidesButtonClick: (presentationId: ArdoqId) => void;
  editingSlideId?: string;
  onEditClick?: (presentationId: ArdoqId, editingSlideId?: ArdoqId) => void;
  onCloseClick: React.MouseEventHandler;
  isPresentationPreview?: boolean;
  permissionContext: PermissionContext;
  canCurrentUserWrite: boolean;
  currentUserIsOrgAdmin: boolean;
}

export class ActivePresentation extends Component<
  ActivePresentationProps,
  PresentationSidebarState
> {
  private sidebarRef = createRef<HTMLDivElement>();
  private resizeListener?: () => void;
  subscriptions: Subscription[] = [];

  constructor(props: ActivePresentationProps) {
    super(props);

    this.state = {
      activeTab: Tabs.SLIDES,
      height: 0,
      deprecatedSlides: this.getDeprecatedSlidesInfo(),
    };

    this.setTab = this.setTab.bind(this);
  }

  setTab(tab: Tabs) {
    trackEvent('Presentation Sidebar: changed tab', { tab });
    this.setState({
      activeTab: tab,
    });
  }

  getDeprecatedSlidesInfo = () =>
    getDeprecatedSlidesInfo(this.props.presentation);

  setDeprecatedSlidesInfoState = (
    deprecatedSlides: PresentationSlideViewInfo[]
  ) => this.setState({ deprecatedSlides });

  updateDeprecatedSlidesInfo = () =>
    this.setDeprecatedSlidesInfoState(this.getDeprecatedSlidesInfo());

  componentDidMount() {
    this.resizeListener = () => {
      const sidebar = this.sidebarRef.current;
      if (sidebar) {
        const offsetTop = sidebar.getBoundingClientRect().top;
        this.setState({
          height: window.innerHeight - offsetTop,
        });
      }
    };
    window.addEventListener('resize', this.resizeListener);
    this.resizeListener();
    this.subscriptions = [
      subscribeToAction(
        deleteSlideInPresentationEditor,
        this.updateDeprecatedSlidesInfo
      ),
      subscribeToAction(
        replaceSlideWithCurrentContext,
        this.updateDeprecatedSlidesInfo
      ),
    ];
  }

  componentWillUnmount() {
    this.subscriptions = clearSubscriptions(this.subscriptions);
    if (!this.resizeListener) {
      return;
    }
    window.removeEventListener('resize', this.resizeListener);
  }

  render() {
    const {
      permissionContext,
      presentation,
      slides,
      onCloseClick,
      viewIsSupported,
      onSlidesButtonClick,
      editingSlideId,
      onEditClick,
      isPresentationPreview,
      canCurrentUserWrite,
      currentUserIsOrgAdmin,
    } = this.props;

    const canEdit = presentationAccessControlOperations.canEditPresentation(
      permissionContext,
      presentation
    );
    const canEditAccessRights =
      presentationAccessControlOperations.canCurrentUserEditAccessRights(
        permissionContext,
        presentation,
        canCurrentUserWrite,
        currentUserIsOrgAdmin
      );

    const showDeprecatedSlidesNotification = Boolean(
      this.state.deprecatedSlides.length
    );

    return (
      <FullHeightStack>
        <SidebarHeader>
          <FlexBox
            justify="space-between"
            align="center"
            padding="small"
            gap="small"
          >
            <IconButton
              buttonType={ButtonType.BRAND}
              iconName={IconName.PRESENTATION}
              onClick={() => {
                startPresentation(presentation._id);
                trackClickedPlayPresentation(
                  'Presentation sidebar',
                  presentation._id
                );
              }}
              isDisabled={!presentation.slides.length}
            />
            <TextOverflow lineClamp={2}>
              <Header3>{presentation.name}</Header3>
            </TextOverflow>
            <IconButton iconName={IconName.CLOSE} onClick={onCloseClick} />
          </FlexBox>
          {showDeprecatedSlidesNotification && (
            <DeprecatedSlidesNotification
              canEditPresentation={canEdit}
              deprecatedSlides={getDeprecatedSlidesInfo(presentation)}
              minimumKillDate={getMinimumSlideKillDateForPresentation(
                this.state.deprecatedSlides
              )}
              overallPresentationViewInfo={getOverallPresentationViewInfo(
                presentation
              )}
            />
          )}
          <FlexBox padding="small">
            <TabsGroup
              activeTabId={this.state.activeTab}
              onTabChange={tabId => this.setTab(tabId as Tabs)}
              theme={TabsTheme.COLOR_FILL}
            >
              <Tab label="Slides" tabId={Tabs.SLIDES} />
              <Tab label="Details" tabId={Tabs.DETAILS} />
            </TabsGroup>
          </FlexBox>
        </SidebarHeader>
        {this.state.activeTab === Tabs.SLIDES && (
          <SlidesContainer
            presentation={presentation}
            slides={slides}
            canEdit={canEdit}
            viewIsSupported={viewIsSupported}
            onSlidesButtonClick={onSlidesButtonClick}
            editingSlideId={editingSlideId}
            onEditClick={onEditClick}
            isPresentationPreview={isPresentationPreview}
          />
        )}
        {this.state.activeTab === Tabs.DETAILS && (
          <Details
            presentation={presentation}
            setTab={this.setTab}
            canEdit={canEdit}
            canEditAccessRights={canEditAccessRights}
          />
        )}
      </FullHeightStack>
    );
  }
}

export default ActivePresentation;
