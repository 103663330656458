import { actionCreator } from '@ardoq/rxbeach';
import { IntegrationJobPayload, IntegrationSchedule } from '@ardoq/api-types';
import { AsyncStatus } from 'integrations/common/types/api';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';

const NAMESPACE = '[integrations] SCHEDULES';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const fetchSchedulesList = actionCreator(
  getActionName('FETCH_SCHEDULES_LIST')
);

export const updateSchedulesList = actionCreator<IntegrationSchedule[]>(
  getActionName('UPDATE_SCHEDULES_LIST')
);

export const setSchedulesAsyncStatus = actionCreator<AsyncStatus>(
  getActionName('SET_SCHEDULES_ASYNC_STATUS')
);

type UpdateSchedulePayload = {
  schedule: IntegrationJobPayload;
  integrationId: IntegrationId;
};
export const updateSchedule = actionCreator<UpdateSchedulePayload>(
  getActionName('UPDATE_SCHEDULE')
);

export const pauseSchedule = actionCreator<string>(
  getActionName('PAUSE_SCHEDULE')
);

type CreateSchedulePayload = {
  schedule: Omit<IntegrationJobPayload, '_id'>;
  integrationId: IntegrationId;
};

export const updateScheduleSuccess = actionCreator<CreateSchedulePayload>(
  getActionName('UPDATE_SCHEDULE_SUCCESS')
);

export const updateScheduleFailure = actionCreator<IntegrationJobPayload>(
  getActionName('UPDATE_SCHEDULE_FAILURE')
);

export const createSchedule = actionCreator<CreateSchedulePayload>(
  getActionName('CREATE_SCHEDULE')
);

export const createScheduleSuccess = actionCreator<CreateSchedulePayload>(
  getActionName('CREATE_SCHEDULE_SUCCESS')
);

export const createScheduleFailure = actionCreator<CreateSchedulePayload>(
  getActionName('CREATE_SCHEDULE_FAILURE')
);

export const deleteSchedule = actionCreator<IntegrationJobPayload>(
  getActionName('DELETE_SCHEDULE')
);

export const removeScheduleFromSchedulesList =
  actionCreator<IntegrationJobPayload>(
    getActionName('REMOVE_SCHEDULE_FROM_SCHEDULES_LIST')
  );

export const runSchedule = actionCreator<string>(getActionName('RUN_SCHEDULE'));

export const scheduleAlreadyExists = actionCreator<CreateSchedulePayload>(
  getActionName('SCHEDULE_ALREADY_EXISTS')
);
