import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import content$ from 'broadcasts/content$';
import { map } from 'rxjs/operators';
import { BroadcastContent } from '@ardoq/api-types';
import { isMessageContent } from 'broadcasts/utils';
import { surveyInterface } from 'modelInterface/surveys/surveyInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { colors, s16, s32, s8 } from '@ardoq/design-tokens';
import { Label } from '@ardoq/forms';

type CurrentContentConditionsProps = {
  readonly workspace?: string | null;
  readonly componentType?: string | null;
};

const Wrapper = styled.div`
  background-color: #f7f8fa;
  color: ${colors.grey15};
  padding: ${s16} ${s32};
`;

const Row = styled.div`
  display: flex;
  margin-bottom: ${s8};
  align-items: center;
  gap: ${s8};
`;

const SubLabel = styled.div`
  width: 137px;
`;

const CurrentContentConditions = ({
  workspace,
  componentType,
}: CurrentContentConditionsProps) => {
  return (
    <Wrapper>
      <Label>Current conditions</Label>
      <Row>
        <SubLabel>Workspace</SubLabel>
        {workspace}
      </Row>
      <Row>
        <SubLabel>Component type</SubLabel>
        {componentType}
      </Row>
    </Wrapper>
  );
};

const toCurrentContentConditionsProps = (content: BroadcastContent) => {
  if (isMessageContent(content)) {
    const workspaceId = content.workspaceId!;
    const componentTypeId = content.componentType!;
    return {
      workspace: workspaceInterface.getWorkspaceName(workspaceId),
      componentType: workspaceInterface.getTypeById(
        workspaceId,
        componentTypeId
      )?.name,
    };
  }
  const workspaceId = surveyInterface.getWorkspace(content.contentId);
  return {
    componentType: surveyInterface.getComponentTypeName(content.contentId),
    workspace: workspaceId
      ? workspaceInterface.getWorkspaceName(workspaceId)
      : null,
  };
};

export default connect(
  CurrentContentConditions,
  content$.pipe(map(toCurrentContentConditionsProps))
);
