import AudienceDisplay, { FallbackAudienceMember } from './AudienceDisplay';
import { PersonIcon } from '@ardoq/icons';
import {
  APIOrganizationUser,
  ArdoqId,
  SurveyApprovalFallbackAudience,
} from '@ardoq/api-types';
import { colors } from '@ardoq/design-tokens';

const getFallbackAudienceMembers = (
  audience: SurveyApprovalFallbackAudience,
  adminOrWriterUsers: APIOrganizationUser[]
): FallbackAudienceMember[] => {
  const matchingAdminOrWriterUsers = adminOrWriterUsers.filter(user =>
    audience.audiencesIds?.includes(user._id)
  );

  return matchingAdminOrWriterUsers.map(user => ({
    id: user._id,
    icon: <PersonIcon color={colors.icon} />,
    name: user.name,
    email: user.email,
    users: [],
    editable: matchingAdminOrWriterUsers.length > 1,
    type: 'fallbackUser',
    fallbackUser: user._id,
  }));
};

type FallbackAudienceDisplayProps = {
  title: string;
  audience: SurveyApprovalFallbackAudience;
  adminOrWriterUsers: APIOrganizationUser[];
  surveyCreatorId: ArdoqId;
  unselectFallbackUser: (userId: ArdoqId) => void;
};

const FallbackAudienceDisplay = ({
  title,
  audience,
  adminOrWriterUsers,
  surveyCreatorId,
  unselectFallbackUser,
}: FallbackAudienceDisplayProps) => {
  const audienceMembers = getFallbackAudienceMembers(
    audience,
    adminOrWriterUsers
  );
  const isSurveyCreatorInAudience =
    audience.audiencesIds?.includes(surveyCreatorId);
  return (
    <AudienceDisplay
      title={title}
      audienceMembers={audienceMembers}
      noAudienceText="No backup approvers selected"
      unselectAudienceMember={audienceMember => {
        if (audienceMember.type !== 'fallbackUser') return;
        unselectFallbackUser(audienceMember.fallbackUser);
      }}
      nonEditableText={isSurveyCreatorInAudience ? 'Default' : 'Required'}
    />
  );
};

export default FallbackAudienceDisplay;
