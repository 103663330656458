import { colors, r8 } from '@ardoq/design-tokens';
import styled, { css, CSSProperties } from 'styled-components';
import { SIDEBAR_MENU_SELECTOR } from '@ardoq/sidebar-menu';
import { ColorBar } from '@ardoq/page-layout';
import { FlexBox, Stack } from '@ardoq/layout';

export const WidthAdjustedStack = styled(Stack)<{
  $width?: CSSProperties['width'];
}>`
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
  border: 1px solid ${colors.borderSubtle00};
  border-radius: ${r8};
  ${SIDEBAR_MENU_SELECTOR} {
    border: none;
  }
`;

export const StyledColorBar = styled(ColorBar)`
  margin: 0;
`;

export const FullWidthFlexBox = styled(FlexBox).attrs({
  gap: 'medium',
  padding: 'medium',
})`
  width: 100%;
  border-top: 1px solid ${colors.grey80};
  border-bottom: 1px solid ${colors.grey80};
`;

export const Row = styled.tr`
  background: ${colors.white};
`;

export const RowWithHover = styled.tr`
  background: ${colors.white};
  &:hover {
    background: ${colors.grey95};
  }
`;
