import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { combineLatest, EMPTY, filter, of, switchMap } from 'rxjs';
import { CheckboxTree, Field, PresetIcon } from '@ardoq/forms';
import { IconName, IconSize } from '@ardoq/icons';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { colors, s8 } from '@ardoq/design-tokens';
import { getResourcesStream } from 'integrations/unified/streams/resources/resources$';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import {
  MultiSelectQuery,
  Query,
  Resource,
} from 'integrations/unified/streams/resources/types';
import { setResourceQuery } from 'integrations/unified/streams/resources/actions';
import { ButtonWithDropdown, DropdownOptionType } from '@ardoq/dropdown-menu';
import { Option } from '@ardoq/forms';
import { ButtonType } from '@ardoq/button';
import { Stack } from '@ardoq/layout';

const QueryFilterButton = styled(ButtonWithDropdown)`
  position: relative;
`;

const ApplyMark = styled.div`
  position: absolute;
  right: 6px;
  top: 2px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${colors.green50};
`;

const OptionsContainer = styled(Stack)`
  margin: ${s8};
`;

type QueryButtonProps = {
  query: Query;
  parentInheritSelection?: boolean;
  queryDefinition: Resource['queryDefinition'];
  isActive?: boolean;
  isDisabled?: boolean;
  options: Option[];
  onApplyQuery: (query: Query) => void;
};

type OptionsProps = {
  options: QueryButtonProps['options'];
  queryDefinition: Resource['queryDefinition'];
  query: Query;
  parentInheritSelection?: QueryButtonProps['parentInheritSelection'];
  onApplyQuery: QueryButtonProps['onApplyQuery'];
};

const getSelectedOptionNames = (option: Option): string[] => {
  const current = option.isChecked ? [option.name] : [];
  const children = option.options
    ? option.options.flatMap(getSelectedOptionNames)
    : [];
  return [...current, ...children];
};

const updateOptions = (
  options: Option[],
  namesToCheck: string[],
  value: boolean
): Option[] => {
  return options.map(option => ({
    ...option,
    isChecked: namesToCheck.includes(option.name) ? value : option.isChecked,
    options: option.options
      ? updateOptions(option.options, namesToCheck, value)
      : option.options,
  }));
};

const OptionsTree = ({
  options,
  parentInheritSelection = true,
  query,
  queryDefinition,
  onApplyQuery,
}: OptionsProps) => {
  const [controlledOptions, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    setOptions(updateOptions(options, query as MultiSelectQuery, true));
  }, [query, options]);

  return (
    <OptionsContainer>
      <Field
        label={queryDefinition.label}
        popoverHelpContent={queryDefinition.description}
      >
        {controlledOptions && (
          <CheckboxTree
            options={controlledOptions}
            onChange={(name, checked, updatedOptions) => {
              const selectedOptions = parentInheritSelection
                ? updatedOptions
                : updateOptions(controlledOptions, [name], checked);

              setOptions(selectedOptions);
              onApplyQuery(selectedOptions.flatMap(getSelectedOptionNames));
            }}
          />
        )}
        {controlledOptions.length <= 0 && (
          <i style={{ color: colors.grey80 }}>No options available</i>
        )}
      </Field>
    </OptionsContainer>
  );
};

const QueryDropdownButtonComponent = ({
  isActive,
  isDisabled,
  parentInheritSelection,
  queryDefinition,
  query,
  onApplyQuery,
}: QueryButtonProps) => {
  return (
    <QueryFilterButton
      buttonType={ButtonType.SECONDARY}
      isDisabled={isDisabled}
      isKeepOpen={true}
      hideChevron
      options={[
        {
          type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
          content: (
            <OptionsTree
              key="query-filter-options"
              queryDefinition={queryDefinition}
              parentInheritSelection={parentInheritSelection}
              query={query}
              options={queryDefinition.possibleValues || []}
              onApplyQuery={onApplyQuery}
            />
          ),
        },
      ]}
    >
      {queryDefinition.buttonLabel || 'Query'}
      <PresetIcon
        iconSize={IconSize.SMALL}
        color={colors.grey50}
        iconName={IconName.FILTER_LIST}
      />
      {isActive && <ApplyMark />}
    </QueryFilterButton>
  );
};

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId =>
    combineLatest([of(integrationId), getResourcesStream(integrationId)])
  ),
  switchMap(
    ([integrationId, { focusedResource, resources, selectedResources }]) => {
      if (!focusedResource) {
        return EMPTY;
      }
      const { queryDefinition, displayText } = resources[focusedResource];
      const query = selectedResources[focusedResource].query || [];
      return of({
        resourceName: displayText,
        queryDefinition,
        query,
        // TODO should be configurable either on BE or unified importer config on FE
        parentInheritSelection: false,
        onApplyQuery: (query: Query) => {
          dispatchAction(
            setResourceQuery({
              integrationId,
              resourceId: focusedResource,
              query,
            })
          );
        },
      });
    }
  )
);

export const QueryDropdownButton = connect(
  QueryDropdownButtonComponent,
  viewModel$
);
