import { APIScopeData, ArdoqId } from '@ardoq/api-types';
import { setStateProperty } from '@ardoq/common-helpers';
import {
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import { TrackingLocation } from 'tracking/types';

export type StagedLoadedDataAndState = {
  /** TODO(sseppola) loadedGraph$ contains scopeData, this can be phased out */
  scopeDataList: APIScopeData[];
  activeWorkspaceId: string | null;
  workspaceOrder: ArdoqId[] | null;
  trackingLocation: TrackingLocation | null;
};

export type ScopeDataLoadedPayload = {
  scopeData: APIScopeData;
  trackingLocation?: TrackingLocation;
};

export const scopeDataLoaded = actionCreator<ScopeDataLoadedPayload>(
  '[StagedLoadedDataAndState] SCOPE_DATA_LOADED'
);

const reduceScopeDataLoaded = (
  state: StagedLoadedDataAndState,
  { scopeData, trackingLocation }: ScopeDataLoadedPayload
): StagedLoadedDataAndState => ({
  ...state,
  scopeDataList: [...state.scopeDataList, scopeData],
  trackingLocation: trackingLocation ?? state.trackingLocation,
});

export const loadedDataProcessed = actionCreator(
  '[StagedLoadedDataAndState] LOADED_DATA_PROCESSED'
);

const reduceLoadedDataProcessed = (): StagedLoadedDataAndState => ({
  scopeDataList: [],
  activeWorkspaceId: null,
  workspaceOrder: null,
  trackingLocation: null,
});

export const activeWorkspaceRegistered = actionCreator<ArdoqId>(
  '[StagedLoadedDataAndState] ACTIVE_WORKSPACE_REGISTERED'
);

export const workspaceOrderRegistered = actionCreator<ArdoqId[]>(
  '[StagedLoadedDataAndState] WORKSPACE_ORDER_REGISTERED'
);

/**
 * This stream stages loaded scope data, the active workspace and the workspace
 * order.
 * The purpose of this stream is to make it possible to update the application
 * data at one place.
 * In the main application, data can be incrementally loaded through traversals
 * or searches, executed in any sequence. Each data chunk is visualized as a
 * block within the viewpoint navigation panel. The data format is always scope
 * data. Each block can be modified by altering the search criteria or editing
 * the traversal configuration. The process for loading a data chunk involves:
 *   1. Data loading via buildState methods, which fetch scope data as defined,
 *      along with scope components (the components meant to be displayed).
 *      At this point we don't know which components and references should be
 *      removed from the loaded application data, that can only be calculated
 *      after all blocks of the loadedData streams have been updated. So we push
 *      this scope data to the staging stream so that we can update, that is
 *      add and remove components and references, in one go after the loaded
 *      state stream has been updated. The scope components, request arguments,
 *      and loaded references are forwarded accordingly to the loaded state
 *      stream.
 *   2. The relevant block in the loaded state is updated to reflect the newly
 *      loaded data.
 *   3. Now a routine can calculate all visible components and references
 *      based on the loaded state stream, update the application state by
 *      processing the staged data, and removes components and references that
 *      are no longer meant to be displayed. The staged data stream is cleared
 *      at this point.
 *   4. Finally, the updated state, including visible components and references,
 *      is reflected in the loadedGraph stream, which orchestrates the
 *      visibility across all views.
 */

export const stagedLoadedDataAndState$ =
  persistentReducedStream<StagedLoadedDataAndState>(
    'scopeDataStaged$',
    {
      scopeDataList: [],
      activeWorkspaceId: null,
      workspaceOrder: null,
      trackingLocation: null,
    },
    [
      reducer(scopeDataLoaded, reduceScopeDataLoaded),
      reducer(loadedDataProcessed, reduceLoadedDataProcessed),
      reducer(activeWorkspaceRegistered, setStateProperty('activeWorkspaceId')),
      reducer(workspaceOrderRegistered, setStateProperty('workspaceOrder')),
    ]
  );
