import { useRef, useState } from 'react';
import { AqLayout } from '@ardoq/layout';
import { PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import styled from 'styled-components';
import { PersistedMetamodel } from '@ardoq/api-types';
import { debouncedCombineLatest } from 'streams/utils/streamUtils';
import metamodels$ from 'streams/metamodels/metamodels$';
import { goToMetamodelList } from './navigationUtils';
import metamodelNavigation$ from './navigation/metamodelNavigation$';
import { MetamodelPane } from './navigation/types';
import { map } from 'rxjs/operators';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  apiCreateMetamodel,
  apiUpdateMetamodel,
} from 'streams/metamodels/metamodelActions';
import AssetsBrowser from 'components/AssetsBrowser/AssetsBrowser';
import workspaceAssets$ from 'components/AssetsBrowser/workspaceAssets$';
import {
  trackClickBackToOverview,
  trackClickFinishCreatingNewMetamodel,
} from './tracking';
import { selectMetamodelPane } from './navigation/actions';
import { BrandButton, GhostButton } from '@ardoq/button';
import { s32, s8 } from '@ardoq/design-tokens';
import { SearchInput, TextInput } from '@ardoq/forms';
import { Space } from '@ardoq/style-helpers';
import { Stack } from '@ardoq/layout';
import { Header4 } from '@ardoq/typography';
import { hasFeature, Features } from '@ardoq/features';
import Navbar from 'views/navbar/Navbar';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import { useResizeObserver } from '@ardoq/hooks';

const SizedTextInput = styled(TextInput)`
  width: 614px;
`;

const getHeaderText = (hasExisting: boolean) => {
  if (hasExisting) return 'Edit metamodel';
  return 'Create new metamodel';
};

const createMetamodel = (
  name: string,
  workspaceIds: string[],
  folder?: string
) => {
  const metamodelDefinition = { name, folder: folder || null, workspaceIds };
  dispatchAction(apiCreateMetamodel(metamodelDefinition));
};

const updateMetamodel = (
  name: string,
  workspaceIds: string[],
  metamodel: PersistedMetamodel
) => {
  const metamodelDefinition = {
    name,
    workspaceIds,
  };
  dispatchAction(
    apiUpdateMetamodel({
      metamodelId: metamodel._id,
      metamodelDefinition,
    })
  );
  dispatchAction(selectMetamodelPane({ pane: MetamodelPane.LIST }));
};

interface EditMetamodelProps {
  metamodel: PersistedMetamodel;
  assetsBrowserDataSource: any[];
  hasNewJourneyFeature: boolean;
}

const EditMetamodel = ({
  metamodel,
  assetsBrowserDataSource,
  hasNewJourneyFeature,
}: EditMetamodelProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const size = useResizeObserver(ref);
  const defaultName = metamodel ? metamodel.name : '';
  const [name, setName] = useState(defaultName);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState(
    metamodel ? metamodel.workspaceIds : ([] as string[])
  );
  const [searchPhrase, setSearchPhrase] = useState('');

  const handleCreateOrSaveMetamodel = () => {
    if (metamodel) {
      updateMetamodel(name || 'My metamodel', selectedWorkspaces, metamodel);
    } else {
      trackClickFinishCreatingNewMetamodel();
      createMetamodel(name || 'My metamodel', selectedWorkspaces);
    }
  };

  const handleBackToOverview = () => {
    goToMetamodelList();
    trackClickBackToOverview();
  };

  const pageContent = (
    <Stack gap="medium">
      <SizedTextInput
        placeholder="My metamodel"
        value={name}
        onValueChange={setName}
        data-intercom-target="new metamodel name"
      />
      <Space $align="center" $justify="space-between">
        <Header4>Select workspaces</Header4>
        <SearchInput
          value={searchPhrase || ''}
          onValueChange={setSearchPhrase}
        />
      </Space>
      <AssetsBrowser
        tableHeight={
          (size.height || 0) -
          (175 + (hasNewJourneyFeature ? PAGE_TOPBAR_HEIGHT : 0))
        }
        dataSource={assetsBrowserDataSource}
        searchPhrase={searchPhrase}
        selected={selectedWorkspaces}
        setSelected={setSelectedWorkspaces}
      />
    </Stack>
  );

  return hasNewJourneyFeature ? (
    <PageWrapper ref={ref}>
      <Navbar
        primaryContent={getHeaderText(Boolean(metamodel))}
        primaryButton={
          <BrandButton
            isDisabled={!selectedWorkspaces.length}
            onClick={handleCreateOrSaveMetamodel}
          >
            {metamodel ? 'Save' : 'Create'}
          </BrandButton>
        }
        secondaryButton={
          <GhostButton onClick={handleBackToOverview}>
            Back to overview
          </GhostButton>
        }
        secondaryContent="Builder"
      />
      <PageBody backgroundColor="surfaceDefault">{pageContent}</PageBody>
    </PageWrapper>
  ) : (
    <AqLayout
      title={getHeaderText(Boolean(metamodel))}
      ref={ref}
      renderHeaderButtons={() => {
        return (
          <>
            <GhostButton onClick={handleBackToOverview}>
              Back to overview
            </GhostButton>
            <GhostButton
              isDisabled={!selectedWorkspaces.length}
              onClick={handleCreateOrSaveMetamodel}
            >
              {metamodel ? 'Save' : 'Create'}
            </GhostButton>
          </>
        );
      }}
      bodyContentStyle={{ height: '100%', padding: `${s8} ${s32}` }}
    >
      {pageContent}
    </AqLayout>
  );
};

const viewModel$ = debouncedCombineLatest([
  metamodels$,
  metamodelNavigation$,
  workspaceAssets$,
]).pipe(
  map(([models, { metamodelId }, { dataSource }]) => {
    const metamodel = models.find(({ _id }) => _id === metamodelId);
    return {
      assetsBrowserDataSource: dataSource,
      metamodel,
      hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
    };
  })
);

export default connect(EditMetamodel, viewModel$);
