import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { SelectionState } from './types';
import fp from 'lodash/fp';
import { SelectionResponse } from '@ardoq/api-types/integrations';
import {
  selectTable,
  unselectTable,
  selectField,
  unselectField,
  setSelectionAsyncStatus,
  resetSelection,
  applyTables,
  SelectFieldsPayload,
  SelectFieldPayload,
  selectFields,
  unselectFields,
  unselectTables,
  fetchSelectionSuccess,
  setImportProfilesPhotos,
  setFetchErrorMessage,
  setHasRequestedPhotos,
} from './actions';
import { AsyncStatus } from 'integrations/common/types/api';
import { Maybe } from '@ardoq/common-helpers';

const initialState: SelectionState = {
  asyncStatus: 'INIT',
  tables: {},
  unavailableTables: {},
  currentTableId: 'user',
  hasRequestedPhotos: null,
  selectionResponse: null,
  importProfilesPhotos: false,
  fetchErrorMessage: null,
};

const resetSelectionReducer = () => initialState;

// tables

const applyTablesReducer = (
  state: SelectionState,
  tables: SelectionState['tables']
): SelectionState => ({ ...state, tables });

const setFetchErrorMessageReducer = (
  state: SelectionState,
  fetchErrorMessage: string | null
) => ({
  ...state,
  fetchErrorMessage,
});

const selectTableReducer = (
  state: SelectionState,
  tableId: string
): SelectionState => {
  return {
    ...state,
    currentTableId: tableId,
  };
};

const unselectTableReducer = (state: SelectionState, tableId: string) =>
  applyTablesReducer(
    {
      ...state,
      currentTableId: null,
    },
    fp.unset([tableId], state.tables)
  );

const unselectTablesReducer = (state: SelectionState) =>
  fp.set('tables', {}, state);

// fields

const selectFieldReducer = (
  state: SelectionState,
  { tableId, id }: { tableId: string; id: string }
): SelectionState => {
  const isNewTable = !state.tables[tableId];

  return applyTablesReducer(
    state,
    fp.set(
      [tableId, 'fields', id],
      true,
      isNewTable
        ? fp.set([tableId, 'queryFilter'], null, state.tables)
        : state.tables
    )
  );
};

const unselectFieldReducer = (
  state: SelectionState,
  { tableId, id }: SelectFieldPayload
) => {
  const isLastField =
    fp.toPairs(state.tables[tableId].fields || {}).length <= 1;
  if (isLastField) {
    return fp.unset(['tables', tableId], state);
  }
  return fp.unset(['tables', tableId, 'fields', id], state);
};

const unselectFieldsReducer = (
  state: SelectionState,
  { tableId, ids }: SelectFieldsPayload
) => {
  const fields = fp.omit(
    ids,
    fp.getOr({}, ['tables', tableId, 'fields'], state)
  );

  return applyTablesReducer(
    state,
    fp.set([tableId, 'fields'], fields, state.tables)
  );
};

const selectFieldsReducer = (
  state: SelectionState,
  { tableId, ids }: SelectFieldsPayload
) =>
  fp.reduce(
    (state, id) => selectFieldReducer(state, { tableId, id }),
    state,
    ids
  );

// fetching

const fetchSelectionSuccessReducer = (
  state: SelectionState,
  selectionResponse: SelectionResponse
): SelectionState => {
  return {
    ...state,
    selectionResponse: selectionResponse,
    asyncStatus: 'SUCCESS',
  };
};

const setSelectionAsyncStatusReducer = (
  state: SelectionState,
  asyncStatus: AsyncStatus
) => ({ ...state, asyncStatus });

const setImportProfilesPhotosReducer = (
  state: SelectionState,
  importProfilesPhotos: boolean
) => ({ ...state, importProfilesPhotos });

const setSelectionRequestReducer = (
  state: SelectionState,
  hasRequestedPhotos: Maybe<boolean>
): SelectionState => {
  return {
    ...state,
    hasRequestedPhotos,
  };
};

const reducers = [
  reducer(applyTables, applyTablesReducer),
  reducer(selectTable, selectTableReducer),
  reducer(unselectTable, unselectTableReducer),
  reducer(unselectTables, unselectTablesReducer),
  reducer(selectField, selectFieldReducer),
  reducer(selectFields, selectFieldsReducer),
  reducer(unselectField, unselectFieldReducer),
  reducer(unselectFields, unselectFieldsReducer),
  reducer(fetchSelectionSuccess, fetchSelectionSuccessReducer),
  reducer(setSelectionAsyncStatus, setSelectionAsyncStatusReducer),
  reducer(resetSelection, resetSelectionReducer),
  reducer(setImportProfilesPhotos, setImportProfilesPhotosReducer),
  reducer(setFetchErrorMessage, setFetchErrorMessageReducer),
  reducer(setHasRequestedPhotos, setSelectionRequestReducer),
];

export const selectionState$ = persistentReducedStream(
  `microsoftEntraIdSelection$`,
  initialState,
  reducers
);
