import {
  APIComponentAttributes,
  APIComponentType,
  APIModelAttributes,
  APIPresentationAttributes,
  ArdoqId,
  EnhancedPresentation,
} from '@ardoq/api-types';
import * as encodingUtils from '@ardoq/html';
import { toByIdDictionary } from '@ardoq/common-helpers';
import CurrentUser from 'models/currentUser';
import { CurrentUserState } from 'streams/currentUser/currentUser$';

export const isOnlyContributor = (
  user: CurrentUserState,
  organizationLabel: string
) => {
  const orgRoles = user.roles.filter(
    role => role.split('/')[0] === organizationLabel
  );
  return orgRoles.every(role => role === `${organizationLabel}/contributor`);
};

export const extractPresentationDetailsFromUrl = (path: string) => {
  const match = path.match('/presentation/([^/]+)/([^/]+)');

  if (match === null) {
    return {
      org: null,
      presentationId: null,
    };
  } else if (match[2] === 'slide') {
    return {
      org: null,
      presentationId: match[1],
    };
  }
  return {
    org: match[1],
    presentationId: match[2],
  };
};

export const getPresentationWindowFeatures = () =>
  'menubar=no,location=no,resizable=yes,scrollbars=no,status=no';

export const getPresentationURL = (presentationId: ArdoqId) =>
  [
    '/presentation',
    encodingUtils.escapeHTML(CurrentUser.getOrganization().label),
    presentationId,
  ].join('/');

export const startPresentation = (presentationId: ArdoqId) => {
  const windowName = 'presentation';
  window.open(
    getPresentationURL(presentationId),
    windowName,
    getPresentationWindowFeatures()
  );
};

export const enhancePresentation = (
  presentation: APIPresentationAttributes
): EnhancedPresentation => {
  return {
    ...presentation,
    componentsById: toByIdDictionary(presentation.components),
  };
};

export const addOrgParamToImagePath = (
  imagePath: string,
  organization: string
) => {
  const imageUrl = new URL(imagePath, window.location.origin);
  if (imageUrl.searchParams.has('org')) return imagePath;
  imageUrl.searchParams.set('org', organization);
  return imageUrl.toString();
};

export const addOrgParamToComponentImagePath = (
  component: APIComponentAttributes,
  organization: string
) => {
  if (!component.image) return component;
  return {
    ...component,
    image: addOrgParamToImagePath(component.image, organization),
  };
};

export const addOrgParamToComponentTypeImagePaths = (
  componentType: APIComponentType,
  organization: string
): APIComponentType => {
  return {
    ...componentType,
    image: componentType.image
      ? addOrgParamToImagePath(componentType.image, organization)
      : componentType.image,
    children: addOrgParamToComponentTypesImagePaths(
      componentType.children,
      organization
    ),
  };
};

const addOrgParamToComponentTypesImagePaths = (
  componentTypes: Record<ArdoqId, APIComponentType>,
  organization: string
): Record<ArdoqId, APIComponentType> => {
  return Object.fromEntries(
    Object.entries(componentTypes).map(([typeId, type]) => {
      if (!type.image) return [typeId, type];
      return [typeId, addOrgParamToComponentTypeImagePaths(type, organization)];
    })
  );
};

export const addOrgParamToModelImagePaths = (
  model: APIModelAttributes,
  organization: string
): APIModelAttributes => {
  return {
    ...model,
    root: addOrgParamToComponentTypesImagePaths(model.root, organization),
  };
};

// For public presentations in organisation without a custom domain, the backend
// has no way of knowing which organization is requesting the image. This function
// transforms the image path to always contain the org parameter.
export const addOrgParamToPresentationImagePaths = (
  presentation: APIPresentationAttributes,
  organization: string | null
) => {
  if (!organization) return presentation;
  return {
    ...presentation,
    components: presentation.components.map(component =>
      addOrgParamToComponentImagePath(component, organization)
    ),
    models: presentation.models.map(model =>
      addOrgParamToModelImagePaths(model, organization)
    ),
  };
};
