import React from 'react';
import styled from 'styled-components';
import { caption, header3, header4, text1 } from '@ardoq/typography';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { Space } from '@ardoq/style-helpers';
import ViewContainer from 'tabview/ViewContainer';
import dottedBackground from '../viewpointBuilder/components/dotted-bg.svg';
import { StatusType, Tag } from '@ardoq/status-ui';
import { IconName } from '@ardoq/icons';
import { Box, FlexBox, Stack } from '@ardoq/layout';

export const SectionHeader = styled.div`
  ${header3}
`;

export const SectionSubtitle = styled.div`
  ${text1};
  color: ${colors.grey35};
`;

export const ComponentTypeCount = styled(Space).attrs({
  $align: 'center',
  $gap: 's4',
})`
  ${text1};
  color: ${colors.grey35};
`;

export const MetamodelGraphContainer = styled(Stack).attrs({
  gap: 'small',
  justify: 'center',
})`
  width: 100%;
  height: 100%;
  background: ${colors.white};
`;

export const MetamodelSidebarMenuHeader = styled.div`
  ${header4};
  color: ${colors.grey50};
`;

export const FullHeightFlexBox = styled(FlexBox)`
  height: 100%;
`;

export const MetamodelIslandGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'metamodel metamodel sidebar';
  max-height: 100%;
  overflow: hidden;
  border-radius: ${s8};
  border: 1px solid ${colors.grey80};
`;

export const MetamodelPreviewContainer = styled.div`
  grid-area: metamodel;
`;

export const MetamodelPreviewErrorContainer = styled.div`
  grid-column: 2 / 3;
`;

export const MetamodelSidebarContainer = styled.div`
  grid-area: sidebar;
  overflow-y: hidden;
  border-left: 1px solid ${colors.grey80};
`;

export const SimpleGraphContainerWithWhiteBackground = styled(ViewContainer)`
  height: 100%;
  margin: ${s16}; /* creates more pleasant dotted-background effect */
  background-color: ${colors.white};
  /* To ensure inline SVGs work in Vite, we need to wrap the URL in double quotes. */
  /* prettier-ignore */
  background-image: url("${dottedBackground}");
  background-size: 20px 20px;
  background-repeat: repeat;
`;

export const Caption = styled(Box)`
  ${caption}
`;

export const UpdateTag = () => (
  <Tag label="Update" iconName={IconName.REFRESH} statusType={StatusType.NEW} />
);

const AddedStyledTag = styled(Tag)`
  border-radius: 100px;
  background: ${colors.blue95};
  border-color: ${colors.blue80};
  font-weight: 600;
`;

export const AddedTag = () => (
  <AddedStyledTag
    label="Added"
    iconName={IconName.CHECK_CIRCLE}
    statusType={StatusType.INFO}
  />
);

export const ReferenceFieldsText = styled(Box)`
  ${caption};
  align-self: center;
`;
