import { DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToPath } from 'integrations/ITPedia/navigation/actions';
import { trackITPediaEvent } from 'integrations/ITPedia/streams/tracking/actions';
import { OverviewRoute } from 'integrations/common/navigation/types';
import { Box, FlexBox } from '@ardoq/layout';
import { Island } from '@ardoq/page-layout';
import { fetchSchedulesList } from 'integrations/common/streams/schedules/actions';
import { listConnections } from 'integrations/common/streams/connections/actions';
export function Success() {
  return (
    <Box margin="medium">
      <Island minWidth="100%">
        <FlexBox align="center" justify="center">
          <DoqWithSpeechBubble
            doqType={DoqType.IMPORT_SUCCESS}
            title="Sync scheduled successfully!"
            message="The sync starts today. Stay updated on the progress and access matching and enrichment details on the overview page."
            buttonLabel="Back to overview"
            onButtonClick={() => {
              dispatchAction(
                trackITPediaEvent({
                  name: 'CLICKED_BUTTON',
                  metadata: {
                    name: 'Back to overview page on success',
                  },
                })
              );
              dispatchAction(listConnections({ integrationId: 'it-pedia' }));
              dispatchAction(fetchSchedulesList());
              dispatchAction(navigateToPath(OverviewRoute.SCHEDULES));
            }}
          />
        </FlexBox>
      </Island>
    </Box>
  );
}
