import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { filter, from, map, switchMap, tap, withLatestFrom } from 'rxjs';
import {
  setInfoMessage,
  transferSuccess,
} from 'integrations/common/streams/transferState/actions';
import { microsoftGraphIntegrationApi } from '@ardoq/api';
import { getSavableTransferConfig } from 'integrations/common/streams/transferConfigs/utils';
import {
  activeIntegrations$,
  getActiveIntegrationStream,
} from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import {
  getAsyncRequestId,
  parseAsyncResponsePayload,
} from 'integrations/common/async/utils';
import { AsyncOperations } from 'integrations/common/async/constants';
import { selectionState$ } from '../selectionState/selectionState$';
import { SelectionState } from '../selectionState/types';
import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { TabularMapping } from 'integrations/common/streams/tabularMappings/types';
import { TransferConfigsState } from 'integrations/common/streams/transferConfigs/types';
import { showToast, ToastType } from '@ardoq/status-ui';
import { userEvent } from 'sync/actions';
import { ImportProfilePicturesResponse } from '../../types';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { sumBy } from 'lodash';
import { ActionCreatorParameter } from 'integrations/common/utils/actionCreatorWithIntegrationId';

const shouldImportPictures = (
  values: [
    ActionCreatorParameter<typeof transferSuccess>,
    SelectionState,
    ActiveIntegrationState,
    TabularMapping,
    TransferConfigsState,
  ]
): values is [
  ActionCreatorParameter<typeof transferSuccess>,
  SelectionState,
  // ensuring the sourceId is present with a typeguard
  ActiveIntegrationState & { sourceId: string },
  TabularMapping,
  TransferConfigsState,
] => {
  const [
    { integrationId, dryRun },
    { importProfilesPhotos },
    activeIntegration,
  ] = values;
  return (
    integrationId === 'microsoft-entra-id' &&
    dryRun === false &&
    importProfilesPhotos === true &&
    Boolean(activeIntegration.sourceId)
  );
};

const handleImportProfilePictures = routine(
  ofType(transferSuccess),
  extractPayload(),
  withLatestFrom(
    selectionState$,
    getActiveIntegrationStream('microsoft-entra-id'),
    getTabularMappingStream('microsoft-entra-id'),
    transferConfigs$
  ),
  filter(shouldImportPictures),
  map(
    ([
      _payload,
      _selectionState,
      activeIntegration,
      tabularMapping,
      transferConfigs,
    ]) => ({
      async: true,
      requestId: getAsyncRequestId({
        integrationId: 'microsoft-entra-id',
        operation: AsyncOperations.MICROSOFT_ENTRA_ID_IMPORT_PICTURES,
        funnelId: activeIntegration.trackingFunnelId,
      }),
      sourceId: activeIntegration.sourceId,
      transferConfig: getSavableTransferConfig({
        activeIntegration,
        tabularMapping,
        transferConfigs,
      }),
    })
  ),
  switchMap(payload => {
    dispatchAction(
      setInfoMessage({
        integrationId: 'microsoft-entra-id',
        infoMessage:
          "Profile photos are importing and it may take some time. Once imported, they'll be stored in the Document Archive and you'll be notified.",
      })
    );
    dispatchAction(
      trackIntegrationEvent({
        integrationId: 'microsoft-entra-id',
        name: 'TRIGGERED_IMPORT_PROFILE_PHOTOS',
      })
    );

    return from(microsoftGraphIntegrationApi.importPhotos(payload));
  })
);

const handleImportProfilePicturesResponse = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncResponsePayload<ImportProfilePicturesResponse>),
  withLatestFrom(activeIntegrations$),
  filter(
    ([response]) =>
      response?.operation === AsyncOperations.MICROSOFT_ENTRA_ID_IMPORT_PICTURES
  ),
  tap(([response]) => {
    if (!response) return;

    if (response.status !== 'success') {
      showToast('Profile photos import failed!', ToastType.INFO);

      dispatchAction(
        trackIntegrationEvent({
          integrationId: 'microsoft-entra-id',
          name: 'FAILURE_IMPORT_PROFILE_PHOTOS',
        })
      );

      return;
    }

    const totalAttachments = sumBy(
      response.data.tables,
      ({
        created: { attachments: createdAttachments },
        updated: { attachments: updatedAttachments },
      }) => createdAttachments + updatedAttachments
    );

    showToast(
      `${totalAttachments} profile photos imported. Refresh Ardoq to view the photos.`,
      ToastType.SUCCESS
    );

    dispatchAction(
      trackIntegrationEvent({
        integrationId: 'microsoft-entra-id',
        name: 'SUCCESS_IMPORT_PROFILE_PHOTOS',
      })
    );
  })
);

export default [
  handleImportProfilePictures,
  handleImportProfilePicturesResponse,
];
