import { ComponentWithPendingChanges } from '../types';
import { getCurrentLocale, localeIncludesLowercase } from '@ardoq/locale';

export const filterByChips = (
  componentWithPendingChanges: ComponentWithPendingChanges,
  filterChips: string[]
) => {
  if (filterChips.length === 0) return true;
  if (filterChips.includes(componentWithPendingChanges.changeAction))
    return true;
  return false;
};

export const search = (
  components: ComponentWithPendingChanges[],
  searchPhrase: string
) => {
  const locale = getCurrentLocale();

  if (!searchPhrase) return components;

  return components.filter(component =>
    localeIncludesLowercase(component.name, searchPhrase, locale)
  );
};
