import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  switchMap,
  catchError,
  tap,
  from,
  withLatestFrom,
  of,
  forkJoin,
  mergeMap,
  filter,
} from 'rxjs';
import {
  upsertEnrichment,
  fetchQueryResults,
  resetConfig,
  updateComponentsQueryResults,
  updateStatuses,
  updateConfigurationState,
  setupConfigurationForUpdate,
} from './actions';
import {
  postAdvanceQuery,
  upsertEnrichmentRequest,
} from '../../api/enrichments';
import { navigateToPath } from '../../navigation/actions';
import { ITPediaImportRoutes } from '../../types';
import { handleApiError } from 'integrations/common/utils/api';
import { configurationState$ } from './configurationState$';
import { clone } from 'lodash/fp';
import { fieldInterface } from '@ardoq/field-interface';
import { setVisibleIntegration } from 'integrations/actions';
import { QueryBuilderQuery } from '@ardoq/api-types';
import { getArdoqErrorMessage } from '@ardoq/common-helpers';

const handleUpsertEnrichment = routine(
  ofType(upsertEnrichment),
  switchMap(({ payload }) => {
    dispatchAction(
      updateStatuses({
        upsert: { code: 'Loading' },
      })
    );
    return from(upsertEnrichmentRequest(payload));
  }),
  tap(() => {
    dispatchAction(
      updateStatuses({
        upsert: { code: 'Success' },
      })
    );
    dispatchAction(resetConfig());
    dispatchAction(navigateToPath(ITPediaImportRoutes.SUCCESS));
  }),
  catchError(error => {
    dispatchAction(
      updateStatuses({
        upsert: {
          code: 'Failed',
          message: getArdoqErrorMessage(error),
        },
      })
    );
    dispatchAction(navigateToPath(ITPediaImportRoutes.FAILED));
    return handleApiError(error);
  })
);

const handleFetchQueryResults = routine(
  ofType(fetchQueryResults),
  withLatestFrom(configurationState$),
  // We can't use switch map because we eventually need to
  // call advance search for all the components for summary
  mergeMap(([{ payload }, config]) => {
    dispatchAction(
      updateComponentsQueryResults({
        componentType: payload.componentType,
        results: {
          status: 'Loading',
          total: 0,
          components: [],
        },
      })
    );
    return forkJoin({
      componentType: of(payload.componentType),
      response: from(
        postAdvanceQuery(
          payload.query,
          config.workspaces.products.id,
          payload.componentType
        )
      ),
    });
  }),
  tap(({ componentType, response }) => {
    if (response.results) {
      dispatchAction(
        updateComponentsQueryResults({
          componentType,
          results: response.results,
        })
      );
    }
    if (response.error) {
      dispatchAction(
        updateComponentsQueryResults({
          componentType,
          results: {
            status: 'Failed',
            message: response.error.message,
            total: 0,
            components: [],
          },
        })
      );
      return handleApiError(response.error);
    }
  }),
  catchError(error => {
    return handleApiError(error);
  })
);

const handleSetupConfigurationForUpdate = routine(
  ofType(setupConfigurationForUpdate),
  extractPayload(),
  tap(config => {
    const fields = fieldInterface.getAllFieldsOfWorkspace(
      config.workspaces.products.id || ''
    );
    const updatedConfig = clone(config);
    fields.forEach(field => {
      Object.entries(config.componentTypes).forEach(
        ([componentTypeName, componentType]) => {
          switch (field.label) {
            case componentType.fields.live.label:
              updatedConfig.componentTypes[componentTypeName].fields.live.name =
                updatedConfig.componentTypes[componentTypeName].fields.live
                  .name ?? field.name;
              break;
            case componentType.fields.support.label:
              updatedConfig.componentTypes[
                componentTypeName
              ].fields.support.name =
                updatedConfig.componentTypes[componentTypeName].fields.support
                  .name ?? field.name;
              break;
            case componentType.fields.extendedSupport.label:
              updatedConfig.componentTypes[
                componentTypeName
              ].fields.extendedSupport.name =
                updatedConfig.componentTypes[componentTypeName].fields
                  .extendedSupport.name ?? field.name;
              break;
            case componentType.fields.productId.label:
              updatedConfig.componentTypes[
                componentTypeName
              ].fields.productId.name =
                updatedConfig.componentTypes[componentTypeName].fields.productId
                  .name ?? field.name;
              break;
            case componentType.fields.url.label:
              updatedConfig.componentTypes[componentTypeName].fields.url.name =
                updatedConfig.componentTypes[componentTypeName].fields.url
                  .name ?? field.name;
              break;
            default:
          }
        }
      );
    });
    dispatchAction(updateConfigurationState(updatedConfig));
  })
);

const handleFetchComponentsForSummarySync = routine(
  ofType(setVisibleIntegration),
  extractPayload(),
  filter(({ id }) => id === 'it-pedia'),
  withLatestFrom(configurationState$),
  tap(([{ path }, config]) => {
    if (path === ITPediaImportRoutes.SUMMARY_SYNC) {
      // When user is done configuring we need to fetch the components to show on the last page
      Object.entries(config.componentTypes).forEach(
        ([componentType, value]) => {
          dispatchAction(
            fetchQueryResults({
              componentType,
              query: value.componentsQuery as QueryBuilderQuery,
            })
          );
        }
      );
    }
  })
);

export default [
  handleUpsertEnrichment,
  handleFetchQueryResults,
  handleSetupConfigurationForUpdate,
  handleFetchComponentsForSummarySync,
];
