import {
  routine,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  upsertConnectionInitiate,
  updateConnectionStatuses,
} from 'integrations/common/streams/connections/actions';
import { startUpsertConnectionModal } from 'integrations/unified/overview/account/UpsertAccountModal';
import { isUnifiedIntegrationPayload } from 'integrations/unified/utils';
import { filter, tap } from 'rxjs';
import { ActionCreatorParameter } from 'integrations/common/utils/actionCreatorWithIntegrationId';

const handleUpsertConnectionInitiate = routine(
  ofType(upsertConnectionInitiate),
  extractPayload(),
  filter(
    isUnifiedIntegrationPayload<
      ActionCreatorParameter<typeof upsertConnectionInitiate>
    >
  ),
  tap(({ integrationId, connection }) => {
    startUpsertConnectionModal(connection?._id).then(() => {
      dispatchAction(
        updateConnectionStatuses({
          integrationId,
          statuses: { upsert: { status: 'INIT' } },
        })
      );
    });
  })
);

export default [handleUpsertConnectionInitiate];
