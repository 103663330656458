import { TabularMappingsDefaultState } from './types';
import { actionCreatorWithIntegrationId } from 'integrations/common/utils/actionCreatorWithIntegrationId';

const NAMESPACE = '[integrations] DEFAULT_TABULAR_MAPPING';

export type setTabularMappingsDefaultPayload = {
  tabularMappingsDefault: TabularMappingsDefaultState;
};

export const enableTabularMappingsDefault = actionCreatorWithIntegrationId(
  `${NAMESPACE}_ENABLE_TABULAR_MAPPING_DEFAULT`
);

export const setTabularMappingsDefault =
  actionCreatorWithIntegrationId<setTabularMappingsDefaultPayload>(
    `${NAMESPACE}_SET_TABULAR_MAPPING_DEFAULT`
  );

export const applyTabularMappingsDefault = actionCreatorWithIntegrationId(
  `${NAMESPACE}_APPLY_TABULAR_MAPPING_DEFAULT`
);

export const resetTabularMappingsDefault = actionCreatorWithIntegrationId(
  `${NAMESPACE}_RESET_TABULAR_MAPPING_DEFAULT`
);
