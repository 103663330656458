import { CellWithDropdown, Column } from '@ardoq/table';
import { PermissionGroupPageEvents, PermissionGroupsProps } from '../types';
import { InlineText, Link, Text } from '@ardoq/typography';
import { openRenameGroupModal } from '../components/RenameGroupModal';
import { trackPermissionGroupPageEvents } from '../utils';
import { confirmDelete, ModalSize } from '@ardoq/modal';
import { FlexBox } from '@ardoq/layout';
import { PeopleIcon } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { ExcludeFalsy, getInitials, pluralize } from '@ardoq/common-helpers';
import { PermissionGroup } from '@ardoq/api-types';
import { UserCircularWrapper } from './components/UserCircularWrapper';
import { Tag } from '@ardoq/status-ui';
import { TextOverflow } from '@ardoq/popovers';

export const getGroupActions = (
  props: PermissionGroupsProps,
  group: PermissionGroup
) => [
  {
    label: 'Add users',
    onClick: () =>
      props.commands.addUsersToGroupModal(props.groupsById[group._id]),
  },
  {
    label: 'Rename group',
    onClick: () => openRenameGroupModal(props.groupsById[group._id]),
  },
  {
    label: 'Delete group',
    onClick: () => {
      trackPermissionGroupPageEvents(
        PermissionGroupPageEvents.CLICK_DELETE_GROUP_BUTTON
      );
      confirmDelete({
        title: 'Delete group?',
        text: (
          <Text variant="text2">
            You are about to permanently delete the group{' '}
            <InlineText variant="text2Bold">{group.name}</InlineText>.
          </Text>
        ),
        text2:
          "This action can't be undone, and may result in group members losing access to certain assets.",
        confirmButtonTitle: 'Delete',
        onConfirmAsync: () => props.commands.removeGroup(group._id),
        cancelButtonTitle: 'Cancel',
        modalSize: ModalSize.S,
      });
    },
  },
];

const getNameColumn = (): Column<
  PermissionGroupsProps['permissionGroups'][0]
> => ({
  title: 'Name',
  dataIndex: 'name',
  cellStyle: { width: '50%' },
  valueRender: (name: string) => {
    return (
      <FlexBox gap="small" align="center">
        <PeopleIcon color={colors.icon} />
        <TextOverflow lineClamp={2} style={{ flexGrow: 1 }}>
          <Link
            hideIcon
            style={{
              flexGrow: 1,
              // We needed to increase it a tiny bit to make the underline appear.
              // Something happens when we use lineClamp which makes the underline not appear.
              lineHeight: '1.35',
            }}
            underlineOnHover
            type="secondary"
            typography="text1"
            onClick={() => {}}
          >
            {name}
          </Link>
        </TextOverflow>
      </FlexBox>
    );
  },
});

const getMembersColumn = (): Column<
  PermissionGroupsProps['permissionGroups'][0]
> => ({
  title: 'Members',
  valueRender: (_, group) => {
    const others = group.users.slice(3).length;
    return (
      <FlexBox gap="xsmall" align="center">
        <Text color="textDefault">{group.users.length}</Text>
        <FlexBox gap="none" align="center">
          {group.users.slice(0, 3).map(user => (
            <UserCircularWrapper data-tooltip-text={user.name} key={user._id}>
              {getInitials(user.name)}
            </UserCircularWrapper>
          ))}
          {!!others && (
            <UserCircularWrapper
              data-tooltip-text={`+${others} ${pluralize('user', others)}`}
            >
              {`+${others}`}
            </UserCircularWrapper>
          )}
        </FlexBox>
      </FlexBox>
    );
  },
});

const getActionsColumn = (
  props: PermissionGroupsProps
): Column<PermissionGroupsProps['permissionGroups'][0]> => ({
  cellStyle: { width: '0' },
  valueRender: (_, group) => (
    <CellWithDropdown
      options={getGroupActions(props, props.groupsById[group._id])}
    ></CellWithDropdown>
  ),
});

export const getPermissionGroupColumns = (
  props: PermissionGroupsProps,
  hasSSOAttributeMapping: boolean
): Column<PermissionGroupsProps['permissionGroups'][0]>[] =>
  [
    getNameColumn(),
    getMembersColumn(),
    hasSSOAttributeMapping && {
      title: 'SSO rules',
      dataIndex: 'ssoMappingRuleCount',
      valueRender: (ruleCount: number) =>
        ruleCount ? (
          <Tag label={`${ruleCount} ${pluralize('rule', ruleCount)}`} />
        ) : (
          <Text variant="text1Italic" color="textSubtle">
            None
          </Text>
        ),
    },
    getActionsColumn(props),
  ].filter(ExcludeFalsy);
