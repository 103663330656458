import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import { RootReferenceMapping } from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { Field } from 'integrations/common/streams/fields/types';
import { ReferenceFormat } from './ReferenceFormat';
import { CreatableSelect, Select } from '@ardoq/select';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { DEFAULT_REFERENCE_TYPE } from 'integrations/common/streams/references/constants';
import { NewBadge } from '@ardoq/status-ui';
import { hasReferenceTypeColumn } from 'integrations/common/streams/tabularMappings/utils';
import { ReferenceMissingComponentsStrategy } from './ReferenceMissingComponentsStrategy';
import { MappedColumnsErrors } from '../../streams/tabularMappingErrors/types';

type SourceReferenceProps = {
  tableMapping: TableMappingMap;
  columnMapping: RootReferenceMapping;
  usedFields: Field[];
  allFields: Field[];
  columnName: string;
  newReferenceTypes: string[];
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
  isCreationDisabled: boolean;
  mappedColumnsErrors: MappedColumnsErrors;
};

export const SourceReference = ({
  tableMapping,
  columnMapping,
  usedFields,
  allFields,
  columnName,
  newReferenceTypes,
  onMapping,
  isCreationDisabled,
  mappedColumnsErrors,
}: SourceReferenceProps) => {
  const workspaceReferenceTypes = tableMapping.rootWorkspace?.id
    ? workspaceInterface.getReferenceTypes(tableMapping.rootWorkspace.id) || {}
    : {};

  const newWorkspaceReferenceTypes =
    Object.values(workspaceReferenceTypes).length === 0
      ? [DEFAULT_REFERENCE_TYPE, ...newReferenceTypes]
      : newReferenceTypes;

  const containsReferenceTypeColumn = hasReferenceTypeColumn(tableMapping);

  const ReferenceTypeSelector = isCreationDisabled ? Select : CreatableSelect;

  return (
    <>
      <ReferenceFormat
        tableMapping={tableMapping}
        columnMapping={columnMapping}
        usedFields={usedFields}
        allFields={allFields}
        onMapping={onMapping}
        columnName={columnName}
        isCreationDisabled={isCreationDisabled}
        mappedColumnsErrors={mappedColumnsErrors}
      />

      <ReferenceTypeSelector
        dataTestId="tabularMapping-reference-reference-type--select"
        label="Reference Type"
        value={columnMapping.referenceType ?? DEFAULT_REFERENCE_TYPE}
        placeholder="Select or create"
        isDisabled={containsReferenceTypeColumn}
        options={[
          {
            options: newWorkspaceReferenceTypes.map(type => ({
              value: type,
              label: type,
              rightContent: <NewBadge />,
            })),
            label: 'New in import',
          },
          {
            options: Object.values(workspaceReferenceTypes).map(type => ({
              label: type.name,
              value: type.name,
            })),
            label: 'Existing',
          },
        ]}
        onValueChange={value => {
          if (!value) return;
          onMapping({ referenceType: value });
        }}
        onCreateOption={value => {
          onMapping({
            referenceType: value,
          });
        }}
      />

      <ReferenceMissingComponentsStrategy
        tableMapping={tableMapping}
        columnMapping={columnMapping}
        usedFields={usedFields}
        allFields={allFields}
        onMapping={onMapping}
        columnName={columnName}
        isCreationDisabled={isCreationDisabled}
        isSource={true}
        workspaceId={tableMapping.rootWorkspace?.id}
      />
    </>
  );
};
