import { TableMappingType } from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { Checkbox } from '@ardoq/forms';

type ExcludeDuplicateIdsCheckboxProps = {
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
  isDuplicatesExcluded: boolean;
  rowRepresentation: TableMappingType;
};

export const ExcludeDuplicateIdsCheckbox = ({
  onMapping,
  isDuplicatesExcluded,
  rowRepresentation,
}: ExcludeDuplicateIdsCheckboxProps) => {
  return (
    <Checkbox
      isChecked={isDuplicatesExcluded}
      onChange={isActive => {
        onMapping({
          duplicates: isActive ? 'warn' : 'error',
        });
      }}
    >
      Exclude duplicates of{' '}
      {rowRepresentation === TableMappingType.COMPONENTS
        ? 'components'
        : 'references'}{' '}
      with same ID
    </Checkbox>
  );
};
