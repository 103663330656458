import { useEffect, useRef, useState } from 'react';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalSize,
} from '@ardoq/modal';
import { ButtonGroup, PrimaryButton, GhostButton } from '@ardoq/button';
import { withOnFirstSubscription } from 'streams/utils/streamUtils';
import { dispatchAction } from '@ardoq/rxbeach';
import { searchTraversal$ } from './searchTraversal$';
import {
  ApplySavedTraversalComponent,
  setSearchTraversalInitialState,
} from './actions';
import { APIViewpointAttributes, ArdoqId } from '@ardoq/api-types';
import TraversalFilters from '../TraversalOverview/TraversalFilters';
import AssetsBrowser from 'components/AssetsBrowser/AssetsBrowser';
import {
  getCreatedByColumn,
  getDateCreatedColumn,
  getVisualizationColumn,
} from '../TraversalOverview/columns';
import { getLastModifiedDateColumn } from 'components/AssetsBrowser/columns';
import styled from 'styled-components';
import { colors, s16, s32, s8 } from '@ardoq/design-tokens';
import {
  getViewModel,
  initialSearchTraversalViewState,
  SearchTraversalViewState,
} from './getViewModel';
import { SearchViewpointCommands } from './searchViewpointCommands';
import { getNameColumnWithRadioButtons } from './columns';
import SelectedComponentsSummary from './SelectedComponentsSummary';
import { pluralize } from '@ardoq/common-helpers';
import { newTableTheme } from '@ardoq/table';
import NoTraversals from './emptyStates';
import { ViewpointModalHeader } from 'viewpointBuilder/components/ViewpointModalHeader';
import { FlexBox } from '@ardoq/layout';
import { traversalInterface } from 'modelInterface/traversals/traversalInterface';
import { trackEvent } from 'tracking/tracking';

type SearchTraversalForContextComponentsModalProps = {
  close: () => void;
  applyTraversal: (traversal: APIViewpointAttributes) => void;
  traversals: APIViewpointAttributes[];
  currentUserId: ArdoqId;
  commands: SearchViewpointCommands;
  components: ApplySavedTraversalComponent[];
};

const SearchTraversalForContextComponentsModal = ({
  close,
  applyTraversal,
  currentUserId,
  commands,
  components,
  ...props
}: SearchTraversalForContextComponentsModalProps) => {
  const filterAndResultsRef = useRef<HTMLDivElement>(null);
  // calculate height for table. Container height - filter heights (150)
  const tableHeight =
    (filterAndResultsRef.current?.getBoundingClientRect().height ?? 150) - 150;

  const [
    {
      selectedTraversalIds,
      shouldDisplayOnlyTraversalsOfCurrentUser,
      searchPhrase,
      isStartTraversalDisabled,
      dataSourceFilteredByCreatedBy,
      allViewpointsCount,
    },
    setState,
  ] = useState<SearchTraversalViewState>(initialSearchTraversalViewState);

  useEffect(() => {
    // Manual connection with an initialization callback to allow
    // inspection of the state on this top level.
    const subscription = withOnFirstSubscription(searchTraversal$, () =>
      dispatchAction(
        setSearchTraversalInitialState({
          currentUserId,
          traversals: props.traversals,
        })
      )
    ).subscribe(state => setState(getViewModel(state)));

    return () => subscription.unsubscribe();
  }, [currentUserId, props.traversals]);

  const applyTraversalWithSelectedComponentIds = async (id?: string) => {
    if (!isStartTraversalDisabled || id) {
      const traversal = traversalInterface.getById(
        id ?? selectedTraversalIds[0]
      );
      applyTraversal(traversal!);
      close();
    }
  };

  const selectedComponentsType = components[0].entityType;

  return (
    <ModalContainer modalSize={ModalSize.COVER} fixedHeight>
      <HeaderWrapper>
        <ViewpointModalHeader
          title="Select viewpoint template to apply"
          subtitle={<SelectedComponentsSummary components={components} />}
        />
      </HeaderWrapper>
      <ModalBody>
        <BodyWrapper>
          <FilterAndResultsWrapper ref={filterAndResultsRef}>
            {allViewpointsCount > 0 && (
              <Hint>
                {allViewpointsCount} viewpoints available for &quot;
                {selectedComponentsType}&quot;
              </Hint>
            )}
            <TraversalFilters
              searchLabel="Search viewpoint name"
              searchPhrase={searchPhrase}
              searchPlaceholder="Search for a viewpoint"
              setSearchPhrase={commands.setSearchPhrase}
              shouldDisplayOnlyTraversalsOfCurrentUser={
                shouldDisplayOnlyTraversalsOfCurrentUser
              }
              toggleShouldDisplayOnlyTraversalsOfCurrentUser={
                commands.toggleShouldDisplayOnlyViewpointsOfCurrentUser
              }
            />

            {/* works as a spacer here */}
            <FlexBox paddingY="xsmall" />

            <AssetsBrowser
              setSelected={traversalIds =>
                commands.setSelectedViewpointIds(traversalIds)
              }
              tableHeight={tableHeight}
              onAssetClick={asset => {
                commands.toggleSelection(asset._id);
              }}
              onAssetPreviewClick={assetRow => {
                commands.openTraversalPreview({
                  traversalId: assetRow._id,
                  isFavorite: assetRow.meta.favorite,
                  openViewpoint: id => {
                    applyTraversalWithSelectedComponentIds(id);
                  },
                  trackingFn: () =>
                    trackEvent(
                      'Search traversal modal: clicked preview viewpoint'
                    ),
                });
              }}
              getCustomColumns={columnsProps => columnsOptions => [
                {
                  ...getNameColumnWithRadioButtons({
                    ...columnsProps,
                    ...columnsOptions,
                  }),
                  cellStyle: {
                    paddingRight: s8,
                    maxWidth: `400px`, // if there is more space on the page, the name column takes it anyway. However, it does not take more than 400px if there is not enough space and names are overflowing
                  },
                },
                getVisualizationColumn(columnsProps),
                getDateCreatedColumn(columnsProps),
                getLastModifiedDateColumn(columnsProps),
                getCreatedByColumn(columnsProps),
              ]}
              searchPhrase={searchPhrase}
              selected={selectedTraversalIds}
              dataSource={dataSourceFilteredByCreatedBy}
              components={newTableTheme}
              emptyState={
                <NoTraversals
                  term={searchPhrase}
                  selectedType={selectedComponentsType}
                  traversalsForTypeCount={props.traversals.length}
                  close={close}
                  commands={commands}
                />
              }
            />
          </FilterAndResultsWrapper>
        </BodyWrapper>
      </ModalBody>
      <ModalFooter>
        <div />
        <ButtonGroup>
          <GhostButton onClick={close}>Cancel</GhostButton>
          <PrimaryButton
            onClick={() => applyTraversalWithSelectedComponentIds()}
            isDisabled={isStartTraversalDisabled}
          >
            {`Apply to ${components.length} ${pluralize(
              'component',
              components.length
            )}`}
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </ModalContainer>
  );
};

const HeaderWrapper = styled.div`
  padding: ${s32};
  border-bottom: 1px solid ${colors.grey80};
  background-color: ${colors.white};
`;

const BodyWrapper = styled.div`
  display: flex;
  gap: ${s16};
  flex-direction: column;
  min-height: 100%;
  padding-top: ${s16};
`;

const FilterAndResultsWrapper = styled.div`
  padding: 0 ${s32};
  display: flex;
  gap: ${s8};
  flex-flow: column;
  min-height: 100%;
`;

const Hint = styled.p`
  margin: 0;
  color: ${colors.grey50};
`;

export default SearchTraversalForContextComponentsModal;
