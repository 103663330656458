import { reducedStream, reducer } from '@ardoq/rxbeach';
import {
  removeUsersFromGroup,
  removeUsersFromGroupFailed,
  removeUsersFromGroupConfirmationModalOpened,
  removeUsersFromGroupSuccess,
} from './actions';
import { removeUsersOperations } from './removeUsersOperations';

export type RemoveUsersViewModel$State = {
  isSubmitting: boolean;
  apiErrorMessage: string | null;
};

const defaultState: RemoveUsersViewModel$State = {
  isSubmitting: false,
  apiErrorMessage: null,
};

const removeUsersViewModel$ = reducedStream<RemoveUsersViewModel$State>(
  'removeUsersFromPermissionGroup',
  defaultState,
  [
    reducer(
      removeUsersFromGroup,
      removeUsersOperations.handleRemoveUserFromGroup
    ),
    reducer(
      removeUsersFromGroupSuccess,
      removeUsersOperations.handleRemoveUserFromGroupSuccess
    ),
    reducer(
      removeUsersFromGroupFailed,
      removeUsersOperations.handleRemoveUserFromGroupFailed
    ),
    reducer(
      removeUsersFromGroupConfirmationModalOpened,
      removeUsersOperations.resetRemoveUserApiErrorMessage
    ),
  ]
);

export default removeUsersViewModel$;
