import { useEffect, useState } from 'react';
import { Select } from '@ardoq/select';
import { CheckboxGroup } from '@ardoq/forms';
import { Link } from '@ardoq/typography';
import {
  ModalLayout,
  ModalSize,
  ModalSubtitle,
  ModalTemplate,
  ModalText,
} from '@ardoq/modal';
import { logError } from '@ardoq/logging';

enum ConsentType {
  ON_BOARD = 'onboardingEmails',
  PROD_EMAIL = 'productEmails',
  NEWS_EMAIL = 'newsletterEmails',
}

const consentTextHash: Record<ConsentType, string> = {
  [ConsentType.ON_BOARD]: 'Personalized quick tips and up-skill resources',
  [ConsentType.PROD_EMAIL]:
    'Updates about the newest features and capabilities',
  [ConsentType.NEWS_EMAIL]: 'Inspiration, customer stories and best practices',
};

export enum Frequency {
  NEVER = 'none',
  DAILY = 'daily',
  WEEKLY = 'weekly',
}

const frequencyToLabelLookup: Record<Frequency, string> = {
  [Frequency.NEVER]: 'Never',
  [Frequency.DAILY]: 'Daily',
  [Frequency.WEEKLY]: 'Weekly',
};

const frequencyOptions = Object.values(Frequency).map(value => ({
  label: frequencyToLabelLookup[value],
  value,
}));

export const consentTypeSelection: Record<ConsentType, boolean> = {
  [ConsentType.ON_BOARD]: false,
  [ConsentType.PROD_EMAIL]: false,
  [ConsentType.NEWS_EMAIL]: false,
};

export type FormValues = {
  emailPreferences: Record<ConsentType, boolean>;
  frequency: Frequency;
};

type ConsentManagementModalProps = {
  isLoading: boolean;
  onSaveEmailPreferences: (formValues: FormValues) => Promise<void>;
  onCloseModal: () => void;
  error: boolean;
  getIsOnPremise: () => boolean;
} & FormValues;

export const ConsentManagementModal = ({
  frequency,
  emailPreferences,
  onSaveEmailPreferences,
  onCloseModal,
  error,
  isLoading,
  getIsOnPremise,
}: ConsentManagementModalProps) => {
  const [frequencyValue, updateFrequencyValue] = useState<Frequency>(frequency);
  const [currentEmailPreferences, updateEmailPreferences] =
    useState<Record<ConsentType, boolean>>(emailPreferences);
  const [isInProgress, setInProgress] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(error);
  useEffect(() => {
    updateEmailPreferences(emailPreferences);
    setHasError(error);
  }, [emailPreferences, error]);

  if (hasError) {
    return (
      <ModalTemplate
        isInProgress={isInProgress}
        headerText="Email preferences"
        errorBlockMessage="Failed to load, please contact support if the problem persists."
        secondaryButtonText="Close"
        onSecondaryButtonClick={onCloseModal}
      />
    );
  }

  return (
    <ModalTemplate
      isLoading={isLoading}
      modalSize={ModalSize.S}
      headerText="Email preferences"
      primaryButtonText="Save preferences"
      secondaryButtonText="Cancel"
      onCloseButtonClick={onCloseModal}
      onSecondaryButtonClick={onCloseModal}
      isInProgress={isInProgress}
      onPrimaryButtonClick={async () => {
        setInProgress(true);
        try {
          await onSaveEmailPreferences({
            emailPreferences: currentEmailPreferences,
            frequency: frequencyValue,
          });
        } catch (e) {
          setHasError(true);
          logError(new Error(e as string));
        }
        setInProgress(false);
      }}
    >
      <ModalLayout>
        <ModalSubtitle>
          What type of emails would help you the most?
        </ModalSubtitle>
        {!getIsOnPremise() && (
          <>
            <ModalText>
              You can change these preferences at any time in your Ardoq user
              settings.
            </ModalText>
            <ModalText>
              Your data privacy comes first:{' '}
              <Link
                target="_blank"
                href="https://www.ardoq.com/privacy"
                typography="text2"
              >
                Privacy Notice
              </Link>
            </ModalText>
            <CheckboxGroup
              label="Receive emails for"
              value={Object.entries(currentEmailPreferences)
                .filter(([, value]) => value)
                .map(([key]) => key)}
              onChange={consentArr => {
                updateEmailPreferences(
                  Object.fromEntries(
                    Object.values(ConsentType).map(value => [
                      value,
                      consentArr.includes(value),
                    ])
                  ) as Record<ConsentType, boolean>
                );
              }}
              options={Object.keys(currentEmailPreferences).map(key => ({
                value: key,
                label: consentTextHash[key as ConsentType],
              }))}
            />
          </>
        )}
        <ModalSubtitle>Workspace change digest</ModalSubtitle>
        <ModalText>
          Your tailored summary of relevant changes in your workspaces, in a
          bitesize email.
        </ModalText>
        <Select
          isClearable={false}
          value={frequencyOptions.find(({ value }) => value === frequencyValue)}
          label="How often you'd like to receive your digest email"
          options={frequencyOptions}
          onValueChange={value => updateFrequencyValue(value as Frequency)}
        />
      </ModalLayout>
    </ModalTemplate>
  );
};
