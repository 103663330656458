import { useState } from 'react';
import styled, { css } from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import { PaginationBar, PaginationController } from '@ardoq/pagination';
import { Space } from '@ardoq/style-helpers';
import {
  FieldSelector as FieldSelectorComponent,
  TablesSelectionTablePayload,
} from '@ardoq/integrations';
import { SelectorField } from 'integrations/common/types/common';
import { viewModel$ } from './viewModel';
import { availableTitlePopoverContent } from './atoms';

const ResourceTypeSelectorContainer = styled.div<{ $isDisabled: boolean }>`
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
    `}
`;

const PaginationControllerContainer = styled(Space).attrs({
  $isVertical: true,
  $gap: 's8',
})``;

const texts = {
  searchHelperText:
    'This list consists of resource types Ardoq supports and resource types in your connection',
  noAvailableTable: 'No table available yet',
  subtitle: `4. Select resource types`,
  searchPlaceholder: 'Search for resource types',
  noTableTitle: 'No resource groups selected',
  noTableSubtitle:
    'Select at least one resource group to view the related resource types',
  labelColumnTitle: 'Resource type name ',
  idColumnTitle: '',
  selectedTitle: 'Selected resource types',
  availableTitle: 'Available resource types',
  selectedCount: 'selected',
};

const popovers = {
  availableTitlePopover: availableTitlePopoverContent,
};

const filterFields = (
  search: string,
  fields: SelectorField[]
): SelectorField[] => {
  const sanitizedSearch = search.toLowerCase().trim();
  const searchTerms = sanitizedSearch.split(/[\s_]+/);

  return fields.filter(f => {
    const name = f.name.toLowerCase();
    const label = f.label.toLowerCase();

    return searchTerms.every(
      term => name.includes(term) || label.includes(term)
    );
  });
};

export const ResourceTypeSelector = ({
  currentTable,
  isLoading,
  isDisabled,
  availableFields,
  selectedFields,
  onClickRemoveAll,
  onClickRemove,
  onClickAdd,
  maxHeight,
}: {
  currentTable: TablesSelectionTablePayload | null;
  isLoading: boolean;
  isDisabled: boolean;
  availableFields: SelectorField[] | null;
  selectedFields: SelectorField[];
  onClickRemoveAll: () => void;
  onClickRemove: (field: SelectorField) => void;
  onClickAdd: (field: SelectorField) => void;
  maxHeight?: number;
}) => {
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(20);

  const filteredSelectedFields = filterFields(search, selectedFields);
  const filteredAvailableFields = availableFields
    ? filterFields(search, availableFields)
    : [];

  return (
    <ResourceTypeSelectorContainer $isDisabled={isDisabled}>
      <PaginationController
        dataSource={filteredAvailableFields}
        perPage={perPage}
        render={({
          currentPageDataSource,
          currentPageNumber,
          onPageSelect,
        }) => (
          <PaginationControllerContainer>
            <FieldSelectorComponent
              isLoading={isLoading}
              selectedFields={filteredSelectedFields}
              availableFields={currentPageDataSource}
              tableName={currentTable?.label || null}
              search={search}
              onSearch={setSearch}
              onClickRemoveAll={onClickRemoveAll}
              onClickAddAll={null}
              onClickRemove={onClickRemove}
              onClickAdd={onClickAdd}
              total={(availableFields?.length || 0) + selectedFields.length}
              selectedTotal={selectedFields.length}
              maxHeight={maxHeight}
              texts={texts}
              popovers={popovers}
              visibleNameColumn={false}
              hideTotalCount
            />
            <PaginationBar
              currentPageNumber={currentPageNumber}
              perPage={perPage}
              totalResults={filteredAvailableFields.length}
              onChange={({ currentPageNumber, perPage }) => {
                setPerPage(perPage);
                onPageSelect(currentPageNumber);
              }}
            />
          </PaginationControllerContainer>
        )}
      />
    </ResourceTypeSelectorContainer>
  );
};

export default connect(ResourceTypeSelector, viewModel$);
