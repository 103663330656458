import { filterInterface } from 'modelInterface/filters/filterInterface';
import { ComponentLabelSource, ReferenceLabelSource } from '@ardoq/data-model';
import { ArdoqId, APIFieldAttributes } from '@ardoq/api-types';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';
import { sortBy, uniqBy } from 'lodash';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { Field } from '../fields/fields$';
import type { PerspectivesFormattingOptions } from '@ardoq/perspectives-sidebar';

export const getAppliedReferenceFormattingLabelData = (): Pick<
  PerspectivesFormattingOptions,
  'appliedReferenceFormattingLabel' | 'isAppliedReferenceLabelTimeIncluded'
> => {
  const referenceLabelFilter = filterInterface.getReferenceLabelFilter();
  return {
    appliedReferenceFormattingLabel:
      referenceLabelFilter.value ??
      ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE,
    isAppliedReferenceLabelTimeIncluded:
      referenceLabelFilter.includeTime ?? false,
  };
};

export const getAppliedComponentFormattingLabelData = (): Pick<
  PerspectivesFormattingOptions,
  'appliedComponentFormattingLabel' | 'isAppliedComponentLabelTimeIncluded'
> => {
  const componentLabelFormattingFields =
    filterInterface.getComponentLabelFormattingFields();
  const [firstComponentLabelFormattingField] = componentLabelFormattingFields;
  const appliedComponentFormattingLabel =
    firstComponentLabelFormattingField ?? ComponentLabelSource.NAME;

  return {
    appliedComponentFormattingLabel,
    isAppliedComponentLabelTimeIncluded:
      filterInterface.getComponentLabelFilter().includeTime ?? false,
  };
};
const getFormattingCustomLabel = (
  loadedAndConnectedWorkspacesIds: ArdoqId[],
  fieldsFilter: (field: APIFieldAttributes) => boolean
) => {
  const fields = loadedAndConnectedWorkspacesIds.flatMap(workspaceId =>
    fieldInterface.getAllFieldsOfWorkspace(workspaceId).filter(fieldsFilter)
  );
  return getFormattingCustomLabelsByFields(fields);
};

export const getFormattingCustomLabelsByFields = (
  fields: APIFieldAttributes[] | Field[]
) =>
  sortBy(
    uniqBy(
      dateRangeOperations.mergeDateTimeFieldsToDateRangeFields(
        fields as APIFieldAttributes[]
      ).fields,
      'name'
    ),
    'name'
  ).flatMap(field =>
    isDateRangeFieldType(field.type)
      ? dateRangeOperations.splitDateRangeFieldIntoStartAndEndSelectOptions(
          field
        )
      : toCustomLabelFormattingOption(field)
  );

export const getReferenceFormattingCustomLabels = (
  loadedAndConnectedWorkspacesIds: ArdoqId[]
) =>
  getFormattingCustomLabel(loadedAndConnectedWorkspacesIds, field =>
    Boolean(field.globalref || field.referenceType.length)
  );

export const getComponentFormattingCustomLabels = (
  loadedAndConnectedWorkspacesIds: ArdoqId[]
) =>
  getFormattingCustomLabel(loadedAndConnectedWorkspacesIds, field =>
    Boolean(field.global || field.componentType.length)
  );

export const toCustomLabelFormattingOption = (
  field: NameAndLabelAttributes
) => ({
  value: field.name,
  label: field.label,
});

type NameAndLabelAttributes = {
  name: string;
  label: string;
};
