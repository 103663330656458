import {
  SelectSubdivisionEditorStepActionPayload,
  SubdivisionEditorSteps,
  SubdivisionNavigationState,
} from './types';

const setSubdivisionIdState = (
  currentState: SubdivisionNavigationState,
  {
    subdivisionEditorStep,
    subdivisionId,
    subdivisionEditorSubStep,
  }: SubdivisionNavigationState
): SubdivisionNavigationState => {
  const validStep = isValidSubdivisionEditorStep(subdivisionEditorStep);
  return {
    ...currentState,
    subdivisionId,
    subdivisionEditorStep: validStep
      ? subdivisionEditorStep
      : SubdivisionEditorSteps.DETAILS,
    subdivisionEditorSubStep,
  };
};

const selectSubdivisionEditorStep = (
  currentState: SubdivisionNavigationState,
  {
    subdivisionEditorStep,
    subdivisionEditorSubStep,
  }: SelectSubdivisionEditorStepActionPayload
): SubdivisionNavigationState => {
  const validStep = isValidSubdivisionEditorStep(subdivisionEditorStep);
  return {
    ...currentState,
    subdivisionEditorStep: validStep
      ? subdivisionEditorStep
      : SubdivisionEditorSteps.DETAILS,
    subdivisionEditorSubStep,
  };
};

const isValidSubdivisionEditorStep = (step: string) => {
  if (!step) {
    return false;
  }
  return Object.values(SubdivisionEditorSteps).includes(
    step as SubdivisionEditorSteps
  );
};

export const subdivisionNavigationOperations = {
  setSubdivisionIdState,
  selectSubdivisionEditorStep,
  isValidSubdivisionEditorStep,
};
