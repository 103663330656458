import {
  ensureContrast,
  getLightenedColor,
  getShadedColor,
} from '@ardoq/color-helpers';
import { classes } from '@ardoq/common-helpers';
import { colors } from '@ardoq/design-tokens';
import { NO_EXPORT_CLASS_NAME } from '@ardoq/global-consts';
import { ComponentRepresentationData } from '@ardoq/graph';
import { ArdoqSvgImageElement, Icon, IconName, IconSize } from '@ardoq/icons';
import { ComponentRepresentation } from '@ardoq/renderers';
import { getFocusRingBorderCSS } from '@ardoq/style-helpers';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { SVGAttributes } from 'react';
import styled from 'styled-components';
import { CONTEXT_HIGHLIGHT_PADDING } from '../../../yfilesExtensions/styles/consts';
import { GRAPH_NODE_ID } from '../../consts';
import { useLayoutEffect } from 'react';

const ContextHighlight = styled.ellipse`
  stroke-width: 2px;
  stroke: ${colors.blue60};
  fill: ${colors.blue60};
  fill-opacity: 0.32;
`;

/**
 * Using button to make it accessible for keyboard users and screen readers.
 */
const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(100% - 6px); /* Extra acrobatics for focus ring */
  width: calc(100% - 6px);
  transform: translate(3px, 3px);
  border-radius: 50%;

  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    scale: 1.15;
  }

  &:focus-visible {
    ${getFocusRingBorderCSS()};
  }
`;

const PulsingBlob = styled.div`
  position: absolute;
  border-radius: 50%;
  height: 100%;
  width: 100%;

  box-shadow: 0 0 0 0 ${colors.blue50};
  transform: scale(0.85);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      box-shadow: 0 0 0 0 ${colors.blue50};
    }

    70% {
      transform: scale(0.85);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.8);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

const GroupWithTransition = styled.g`
  transition: opacity 0.5s;
`;

interface ArdoqNodeProperties {
  representationData?: ComponentRepresentationData | null;
  color: string;
  imageHref?: string;
  isContext: boolean;
  isSelectedRelated: boolean;
  width: number;
  height: number;
  tooltipAttributes?: Record<string, string>;
  contextHighlightPadding: typeof CONTEXT_HIGHLIGHT_PADDING | 0;
  graphId: string;
  isSelected?: boolean;
  hasFilters?: boolean;
  selectionRadiusDiff?: number;
  selectionStrokeWidth?: number;
  opacity?: number;
  label: string | undefined;
  shouldDisplayClickOtherComponentsHint: boolean;
}
// TODO AM it would be great to have it in a package and write visual tests for it
const ArdoqNode = ({
  representationData,
  imageHref,
  isContext,
  isSelectedRelated,
  width,
  height,
  tooltipAttributes = {},
  contextHighlightPadding,
  color,
  graphId,
  isSelected,
  selectionRadiusDiff = 0,
  selectionStrokeWidth = 0,
  hasFilters = false,
  opacity = 1,
  label = '',
  shouldDisplayClickOtherComponentsHint = false,
  ...rest
}: ArdoqNodeProperties & SVGAttributes<SVGGElement>) => {
  const shadedColor = getShadedColor(color);
  const lightenedColor = getLightenedColor(color);
  const contrastColor = ensureContrast(lightenedColor, color);

  /**
   * This effect is used to restart the pulsing animation.
   */
  useLayoutEffect(() => {
    if (shouldDisplayClickOtherComponentsHint) {
      const elements =
        document.querySelectorAll<HTMLDivElement>('.pulsing-blob');
      elements.forEach(element => {
        element.style.animation = 'none';
        setTimeout(() => {
          element.style.animation = ''; // Restores animation from CSS
        });
      });
    }
  }, [shouldDisplayClickOtherComponentsHint]);

  return (
    <>
      {isContext && (
        <ContextHighlight
          className={NO_EXPORT_CLASS_NAME}
          cx={width / 2}
          cy={height / 2}
          rx={width / 2}
          ry={height / 2}
        />
      )}
      {isSelectedRelated && (
        <ellipse
          className={classes(
            'yfiles-scenario-related-highlight',
            NO_EXPORT_CLASS_NAME
          )}
          cx={width / 2}
          cy={height / 2}
          rx={width / 2}
          ry={height / 2}
        />
      )}
      <GroupWithTransition
        {...{
          ...rest,
          [GRAPH_NODE_ID]: graphId,
        }}
        opacity={opacity}
      >
        {representationData && (
          <>
            {isSelected && (
              <circle
                cx={width / 2}
                cy={width / 2}
                r={
                  (isContext ? width - 2 * contextHighlightPadding : width) /
                    2 -
                  selectionStrokeWidth / 2
                }
                fill="transparent"
                strokeDasharray="10"
                strokeWidth="4"
                stroke={colors.blue60}
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from={`0 ${width / 2} ${width / 2}`}
                  to={`360 ${width / 2} ${width / 2}`}
                  dur="20s"
                  repeatCount="indefinite"
                />
              </circle>
            )}
            <circle
              cx={width / 2}
              cy={width / 2}
              r={
                (isContext ? width - 2 * contextHighlightPadding : width) / 2 -
                (selectionRadiusDiff + selectionStrokeWidth / 2)
              }
              fill={lightenedColor}
              strokeWidth="1"
              stroke={shadedColor}
            />
            <foreignObject
              y={isContext ? contextHighlightPadding : 0}
              x={isContext ? contextHighlightPadding : 0}
              height={isContext ? height - 2 * contextHighlightPadding : height}
              width={isContext ? width - 2 * contextHighlightPadding : width}
              style={{ overflow: 'visible' }} // for the pulsing shadow
            >
              {hasFilters && (
                <FilterIcon
                  parentRadius={
                    isContext ? width - 2 * contextHighlightPadding : width
                  }
                  padding={selectionRadiusDiff + selectionStrokeWidth}
                />
              )}
              {isSelected || !shouldDisplayClickOtherComponentsHint ? null : (
                <PulsingBlob className="pulsing-blob" />
              )}
              <IconButton>
                <ComponentRepresentation
                  isImage={representationData.isImage ?? false}
                  value={representationData.value ?? null}
                  icon={representationData.icon}
                  iconSize={40 as IconSize}
                  style={{
                    fontSize: 40,
                    color: contrastColor,
                  }}
                />
                <VisuallyHidden>{label}</VisuallyHidden>
              </IconButton>
            </foreignObject>
          </>
        )}
        {imageHref && (
          <ArdoqSvgImageElement
            href={imageHref}
            y={isContext ? contextHighlightPadding : 0}
            x={isContext ? contextHighlightPadding : 0}
            height={isContext ? height - 2 * contextHighlightPadding : height}
            width={isContext ? width - 2 * contextHighlightPadding : width}
            {...tooltipAttributes}
          />
        )}
      </GroupWithTransition>
    </>
  );
};

const FilterIcon = ({
  parentRadius,
  padding,
}: {
  parentRadius: number;
  padding: number;
}) => {
  const iconWrapperRadius = 12;
  const xCoordinate = 0.7071 * (parentRadius + padding) - iconWrapperRadius; // 0.707 is the sin of 45 degrees
  const yCoordinate =
    parentRadius - 0.7071 * (parentRadius + padding) - iconWrapperRadius;

  return (
    <div
      style={{
        position: 'absolute',
        left: xCoordinate,
        top: yCoordinate,
        height: iconWrapperRadius * 2,
        width: iconWrapperRadius * 2,
        borderRadius: '50%',
        backgroundColor: colors.brand15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Icon
        iconName={IconName.FILTER_LIST}
        color={colors.white}
        iconSize={IconSize.MEDIUM}
      />
    </div>
  );
};

export default ArdoqNode;
