import * as profiling from '@ardoq/profiling';
import { CollectionView } from 'collections/consts';
import { logError } from '@ardoq/logging';
import { Features, hasFeature } from '@ardoq/features';
import {
  type TraversalAndGroupingResult,
  isGraphModelValid,
  getTraversalAndGroupingResult,
  getEmptyTraversalAndGroupingResult,
  BuildGraphForWorkspaceModeParams,
} from '@ardoq/graph';
import { getTraversalResultForWorkspaceMode } from './getTraversalResultForWorkspaceMode';

// Main util to build a specific graph for a view.
export const buildGraph: (
  params: BuildGraphForWorkspaceModeParams
) => TraversalAndGroupingResult = ({
  graphInterface,
  startSet,
  graph,
  traverseOptions = {
    maxDegreesIncoming: 0,
    maxDegreesOutgoing: 0,
    maxDegrees: 0,
    isParentRelationAsReference: false,
    outgoingReferenceTypes: null,
    incomingReferenceTypes: null,
    nodeCollectionView: CollectionView.DEFAULT_VIEW,
  },
  groupingRules = [],
  viewId,
  sequentialTraverseOptions = [],
  includeOnlyConnectedComponents = false,
  useNewGrouping = false,
}) => {
  const transaction = profiling.startTransaction(
    'buildGraph',
    500,
    profiling.Team.CORE
  );

  if (!isGraphModelValid(graphInterface, graph)) {
    // typically this means the graphModel$ has not updated itself with the latest state of the References collection... but a viewModel stream is rebuilding from some other update.
    logError(Error('Invalid graph model.'), null, {
      viewId,
      referenceCollectionSize: graphInterface.getReferenceCollectionSize(),
      referenceMapSize: graph.referenceMap.size,
    });
    return getEmptyTraversalAndGroupingResult();
  }

  const { componentIds, traversedReferenceIds, parentReferenceIds } =
    getTraversalResultForWorkspaceMode({
      hasAddAllReferencesAfterTraversalFeature: hasFeature(
        Features.ADD_ALL_REFERENCES_AFTER_TRAVERSAL
      ),
      graphInterface,
      startSet,
      graph,
      traverseOptions,
      sequentialTraverseOptions,
      includeOnlyConnectedComponents,
    });

  const traversalAndGroupingResult = getTraversalAndGroupingResult({
    graphInterface,
    graph,
    componentIds,
    traversedReferenceIds,
    parentReferenceIds,
    groupingRules,
    isParentRelationAsReference: traverseOptions.isParentRelationAsReference,
    useNewGrouping,
  });

  const { maxDegrees, maxDegreesIncoming, maxDegreesOutgoing } =
    traverseOptions;
  profiling.endTransaction(transaction, {
    viewId,
    metadata: {
      groupCount: traversalAndGroupingResult.groupMap.size,
      maxDegrees,
      maxDegreesIncoming,
      maxDegreesOutgoing,
      startSetCount: startSet.length,
      referenceCount: graph.referenceMap.size,
      sourceCount: graph.sourceMap.size,
      targetCount: graph.targetMap.size,
    },
  });

  return traversalAndGroupingResult;
};
