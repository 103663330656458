import { InteropObservable, ReplaySubject, Subscribable } from 'rxjs';
import { RouteNode } from './routeNode';
import {
  EmptyParams,
  isUnmatchedLocation,
  MatchedLocationIn,
  ParamsAt,
  RouteIn,
} from './location';
import {
  interpolatePath,
  matchLocation,
  matchPath,
} from './declarativeRouterLogic';

export class DeclarativeRouter<RouteTree extends RouteNode>
  implements InteropObservable<MatchedLocationIn<RouteTree>>
{
  public readonly routeTree: RouteTree;
  private subject: ReplaySubject<MatchedLocationIn<RouteTree>>;
  public pipe: ReplaySubject<MatchedLocationIn<RouteTree>>['pipe'];

  constructor(routeTree: RouteTree) {
    this.routeTree = routeTree;
    this.subject = new ReplaySubject<MatchedLocationIn<RouteTree>>(1);

    this.pipe = this.subject.pipe.bind(this.subject);
  }

  [Symbol.observable](): Subscribable<MatchedLocationIn<RouteTree>> {
    return this.subject;
  }

  arrive(path: string): false | MatchedLocationIn<RouteTree> {
    const parsed = matchPath(path, this.routeTree);
    if (isUnmatchedLocation(parsed)) {
      return false;
    }

    this.subject.next(parsed);
    return parsed;
  }

  pathTo<Route extends RouteIn<RouteTree>>(
    route: EmptyParams extends ParamsAt<Route> ? Route : never
  ): string;
  pathTo<Route extends RouteIn<RouteTree>>(
    route: Route,
    params: ParamsAt<Route>
  ): string;
  pathTo(route: string, params: Record<string, unknown> = {}): string {
    const relativePath = interpolatePath({ route, params });
    return `/app${relativePath}`;
  }

  depart<Route extends RouteIn<RouteTree>>(
    route: EmptyParams extends ParamsAt<Route> ? Route : never
  ): boolean;
  depart<Route extends RouteIn<RouteTree>>(
    route: Route,
    params: ParamsAt<Route>
  ): boolean;
  depart(route: string, params: Record<string, unknown> = {}): boolean {
    const matched = matchLocation<RouteTree>({ route, params }, this.routeTree);
    if (isUnmatchedLocation(matched)) {
      return false;
    }

    this.subject.next(matched);
    return true;
  }
}
