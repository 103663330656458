import { Column, DatasourceTable, newTableTheme } from '@ardoq/table';
import { ComponentDecoratorAsIcon } from '@ardoq/decorators';
import { DataOverviewComponentTypeRow, DataOverviewTypes } from './types';
import { formatDateTime } from '@ardoq/date-time';
import { getNumberFormat } from '@ardoq/locale';
import { Locale } from '@ardoq/locale';
import { FlexBox, Box } from '@ardoq/layout';
import { PaginationBar, PaginationController } from '@ardoq/pagination';
import { Island } from '@ardoq/page-layout';
import { DataOverviewCommands } from './dataOverviewCommands';
import { DEFAULT_PAGE_SIZE_OPTIONS } from './consts';
import { DoqWithSpeechBubble, DoqType, DoqLayout } from '@ardoq/doq';
import { Checkbox } from '@ardoq/forms';
import { SortOrder } from '@ardoq/api-types';
import { Link } from '@ardoq/typography';
import { s24 } from '@ardoq/design-tokens';
import { inventoryTracking } from '../inventoryTracking';

const getColumns = (
  locale: Locale,
  dataOverviewCommands: DataOverviewCommands,
  selectedItems: string[]
): Column<DataOverviewComponentTypeRow>[] => [
  {
    title: '',
    dataIndex: 'id',
    headerStyle: {
      width: '0',
      paddingRight: '0',
      paddingTop: s24,
      paddingBottom: s24,
    },
    cellStyle: { width: '0', paddingRight: '0' },
    valueRender: (_dataIndex: string, row: DataOverviewComponentTypeRow) => (
      <Checkbox
        onChange={value => {
          dataOverviewCommands.toggleSelectedItem(row.name);
          inventoryTracking.trackToggleCheckboxForItemInDataOverview({
            dataOverviewType: DataOverviewTypes.COMPONENT_TYPE,
            value,
          });
        }}
        stopPropagation
        isChecked={selectedItems.includes(row.name)}
      />
    ),
  },
  {
    title: 'Component type',
    dataIndex: 'name',
    valueRender: (_dataIndex: string, row: DataOverviewComponentTypeRow) => (
      <Link
        hideIcon
        underlineOnHover
        type="primary"
        typography="text1"
        title={row.name}
        onClick={() => {
          dataOverviewCommands.openItemsInInventory(
            DataOverviewTypes.COMPONENT_TYPE,
            [row.name]
          );
          inventoryTracking.trackClickOnItemNameInDataOverview(
            DataOverviewTypes.COMPONENT_TYPE
          );
        }}
      >
        <FlexBox align="center" gap="small">
          <ComponentDecoratorAsIcon {...row.representationData} />
          {row.name}
        </FlexBox>
      </Link>
    ),
  },
  {
    title: 'Total number of components',
    valueRender: (_dataIndex: string, row: DataOverviewComponentTypeRow) =>
      getNumberFormat({}, locale).format(row.totalNumberOfComponents),
  },
  {
    title: 'Last updated',
    valueRender: (_dataIndex: string, row: DataOverviewComponentTypeRow) =>
      formatDateTime(row.lastUpdated, locale),
  },
];

export type ComponentTypesTableProps = {
  datasource: DataOverviewComponentTypeRow[];
  locale: Locale;
  dataOverviewCommands: DataOverviewCommands;
  numberOfRowsPerPage: number;
  isSearchActive: boolean;
  isLoading: boolean;
  selectedItems: string[];
};

const ComponentTypesTable = ({
  locale,
  datasource,
  dataOverviewCommands,
  numberOfRowsPerPage,
  isSearchActive,
  isLoading,
  selectedItems,
}: ComponentTypesTableProps) => {
  if (!isLoading && isSearchActive && datasource.length === 0) {
    return (
      <Box height="full">
        <Island maxWidth="100%" bodySpacing="none" fullHeight>
          <FlexBox justify="center" align="center" height="full" flexGrow={1}>
            <DoqWithSpeechBubble
              layout={DoqLayout.DIALOG}
              doqType={DoqType.MISSING_ORG}
              message="There are no component types that match your search. Try searching for a different name."
              title="No component types found"
            />
          </FlexBox>
        </Island>
      </Box>
    );
  }

  if (!isLoading && datasource.length === 0) {
    return (
      <Box height="full">
        <Island maxWidth="100%" bodySpacing="none" fullHeight>
          <FlexBox justify="center" align="center" height="full" flexGrow={1}>
            <DoqWithSpeechBubble
              layout={DoqLayout.DIALOG}
              doqType={DoqType.EMPTY_BOX}
              message="There are no component types."
              title="No component types found"
            />
          </FlexBox>
        </Island>
      </Box>
    );
  }

  return (
    <PaginationController
      defaultSortOrder={SortOrder.ASC}
      defaultSortById="name"
      dataSource={datasource}
      perPage={numberOfRowsPerPage}
      render={({
        currentPageDataSource,
        onPageSelect,
        currentPageNumber,
        totalResults,
      }) => (
        <Island
          preventScroll
          maxWidth="100%"
          bodySpacing="none"
          footerContent={
            <PaginationBar
              pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
              currentPageNumber={currentPageNumber}
              perPage={numberOfRowsPerPage}
              totalResults={totalResults}
              onChange={({
                currentPageNumber: newPageNumber,
                perPage: newPerPage,
              }) => {
                dataOverviewCommands.selectNumberOfRowsPerPage(newPerPage);
                onPageSelect(newPageNumber);
              }}
            />
          }
        >
          <DatasourceTable
            fixedHeader
            scrollableSectionHeight="100%"
            loading={isLoading}
            dataSource={currentPageDataSource}
            components={newTableTheme}
            columns={getColumns(locale, dataOverviewCommands, selectedItems)}
          />
        </Island>
      )}
    />
  );
};

export default ComponentTypesTable;
