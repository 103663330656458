import { css } from 'styled-components';
import {
  normal14,
  normal14Italic,
  normal16,
  semibold16,
} from '@ardoq/typography';

export const pageViewLargeTemp = css`
  ${normal14};
  font-size: 32px;
  line-height: 48px;
`;

export const PAGE_VIEW_MEDIUM_TEMP_FONT_SIZE = '16px';
export const PAGE_VIEW_MEDIUM_TEMP_LINE_HEIGHT = '24px';
export const pageViewMediumTemp = css`
  ${normal14};
  font-size: ${PAGE_VIEW_MEDIUM_TEMP_FONT_SIZE};
  line-height: ${PAGE_VIEW_MEDIUM_TEMP_LINE_HEIGHT};
`;
export const smallItalicTemp = css`
  ${normal14Italic}
`;

export const mediumItalicTemp = css`
  ${normal14Italic}
  font-size: 16px;
  line-height: 24px;
`;

export const pageViewSmallTemp = css`
  ${normal14};
  font-size: 14px;
  line-height: 22px;
`;

export const pageViewExtraSmallTemp = css`
  ${normal14};
  font-size: 12px;
  line-height: 20px;
`;

export const scenarioMergeSidebarHeaderTemp = css`
  ${normal16}
  text-transform: uppercase;
`;

export const scenarioMergeSidebarStepNameTemp = css`
  ${normal14}
  line-height: 24px;
  text-transform: capitalize;
`;

export const scenarioMergeSidebarStepNameActiveTemp = css`
  ${semibold16}
  text-transform: capitalize;
`;
