import { createRef, Component } from 'react';
import { ArdoqGraphComponent } from 'viewpointBuilder/components/SimpleGraph/ArdoqGraphComponentClone';
import { DiffMode, ViewIds } from '@ardoq/api-types';
import {
  GraphComponent,
  GraphViewerInputMode,
  ScrollBarVisibility,
} from '@ardoq/yfiles';
import CollapsibleGroupsYGraphBuilder from './CollapsibleGroupsYGraphBuilderClone';
import onLayoutGraph from '../../../tabview/blockDiagram/view/yFilesExtensions/onLayoutGraph';
import configureLayout from './configureLayoutClone';
import { getStyle } from 'viewpointBuilder/components/SimpleGraph/styleLoaderClone';
import ArdoqNodeStyle from './ArdoqNodeStyleClone';
import { SimpleGraphViewProps } from '../../types';
import styled from 'styled-components';
import { s32, colors } from '@ardoq/design-tokens';
import { fontMixins } from '@ardoq/typography';
import { SecondaryButton } from '@ardoq/button';
import { Icon } from '@ardoq/icons';
import { TopRightCornerButtonContainer } from '../atoms';
import { LABEL_BACKGROUND_CLASS } from './ArdoqLabelStyleClone';
import type { BlockDiagramViewSettings } from 'tabview/blockDiagram/types';

const VIEW_ID = ViewIds.METAMODEL;

const ViewLabel = styled.div`
  ${fontMixins.semibold16};
  margin-top: ${s32};
  margin-bottom: -${s32};
`;

const configureRendering = (graphComponent: GraphComponent) => {
  const inputMode = graphComponent.inputMode as GraphViewerInputMode;
  inputMode.navigationInputMode.enabled = false;
  graphComponent.maximumZoom = 1.6;
  graphComponent.minimumZoom = 0.01;
  graphComponent.mouseWheelZoomFactor = 1.1;
  graphComponent.horizontalScrollBarPolicy = ScrollBarVisibility.NEVER;
  graphComponent.verticalScrollBarPolicy = ScrollBarVisibility.NEVER;
};

const defaultViewSettings = {
  layoutOrientation: 1,
  incomingDegreesOfRelationship: 0,
  outgoingDegreesOfRelationship: 0,
  depthOfRelationship: 6,
  showOnlyConnectedComponents: false,
  includeOutgoingReferenceTypeNames: ['@@all@@'],
  includeIncomingReferenceTypeNames: ['@@all@@'],
  additionalTraversals: [],
  activeDiffMode: DiffMode.SCENARIO,
  collapsedGroupIds: [],
  sequenceConstraints: [],
  layerConstraints: [],
  separateReferences: false,
  useNewGrouping: false,
  isLegendActive: false,
  onViewSettingsUpdate: () => {},
} satisfies BlockDiagramViewSettings;

class SimpleGraphView extends Component<SimpleGraphViewProps> {
  ardoqGraphComponentRef = createRef<ArdoqGraphComponent>();

  private graphBuilder: CollapsibleGroupsYGraphBuilder | null = null;
  constructor(props: SimpleGraphViewProps) {
    super(props);
  }
  buildGraph = (graphComponent: GraphComponent, clearEdges: boolean) => {
    const {
      viewModel: { graphNodes, graphEdges },
    } = this.props;

    this.graphBuilder =
      this.graphBuilder ||
      new CollapsibleGroupsYGraphBuilder(
        graphComponent.graph,
        getStyle,
        style => (style as ArdoqNodeStyle).size
      );
    if (clearEdges) {
      this.graphBuilder.clearEdges();
    }

    const nodes = Array.from(graphNodes.values());
    const edges = Array.from(graphEdges.values());

    const isEmpty = !nodes.length && !edges.length;
    const hasLayoutUpdate = this.graphBuilder.hasLayoutUpdate({
      nodes,
      edges,
    });

    this.graphBuilder.nodesSource = nodes;
    this.graphBuilder.edgesSource = edges;
    this.graphBuilder.updateGraph();

    graphComponent.selection.clear();

    return { isAboveLimit: false, isEmpty, canBypass: true, hasLayoutUpdate };
  };

  getContainer = () =>
    this.ardoqGraphComponentRef.current?.graphComponentContainer.current ??
    null;

  getGraphComponent = () =>
    this.ardoqGraphComponentRef.current?.graphComponent ?? null;

  protected getViewModel() {
    return this.props.viewModel;
  }

  componentDidUpdate() {
    if (!this.getGraphComponent()) {
      this.graphBuilder = null;
    }
  }

  render() {
    const {
      props: {
        viewSettings = defaultViewSettings,
        viewModel,
        viewInstanceId = 'traversalDialog',
        clearEdges = false,
        label,
        graphInterface,
        topRightCornerButton,
        hasZoomControls,
        isThumbnailView,
        shouldFitToContentOnLayout,
        hasHoverDecorator = false,
      },
    } = this;

    return (
      <StyleWrapper className={`tab-pane ${VIEW_ID}Tab active`}>
        {label && <ViewLabel>{label}</ViewLabel>}
        <ArdoqGraphComponent
          viewId={VIEW_ID}
          viewInstanceId={viewInstanceId}
          ref={this.ardoqGraphComponentRef}
          viewModel={viewModel}
          isLegendActive={viewSettings.isLegendActive}
          buildGraph={graphComponent =>
            this.buildGraph(graphComponent, clearEdges)
          }
          enableStyles={true}
          configureLayout={graphComponent =>
            configureLayout(
              graphComponent,
              viewModel,
              viewSettings,
              shouldFitToContentOnLayout
            )
          }
          configureRendering={configureRendering}
          onLayoutGraph={onLayoutGraph(null)}
          useHoverDecorator={false}
          graphInterface={graphInterface}
          hasZoomControls={hasZoomControls}
          isThumbnailView={isThumbnailView}
          hasHoverDecorator={hasHoverDecorator}
        />
        {topRightCornerButton && (
          <TopRightCornerButtonContainer>
            <SecondaryButton onClick={topRightCornerButton.onClick}>
              {topRightCornerButton.iconName && (
                <Icon iconName={topRightCornerButton.iconName} />
              )}
              {topRightCornerButton.label}
            </SecondaryButton>
          </TopRightCornerButtonContainer>
        )}
      </StyleWrapper>
    );
  }
}

export const SIMPLE_GRAPH_BACKGROUND_COLOR_CSS_VARIABLE = '--background-color';

const StyleWrapper = styled.div`
  .${LABEL_BACKGROUND_CLASS} {
    fill: var(${SIMPLE_GRAPH_BACKGROUND_COLOR_CSS_VARIABLE}, ${colors.white});
  }
`;

export default SimpleGraphView;
