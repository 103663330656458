import { APIFieldAttributes } from '@ardoq/api-types';
import { getDateRangeType } from '@ardoq/date-range';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import {
  ConfigurationState,
  ExistingField,
} from 'integrations/ITPedia/streams/types';

export function findDateRangePairs(fields: APIFieldAttributes[]) {
  const pairs: Record<string, APIFieldAttributes> = {};
  const prefixes: string[] = [];
  const postfixes: string[] = [];

  for (const field of fields) {
    if (field.name.endsWith('_start_date')) {
      const name = field.name.replace(/_start_date$/, '');
      prefixes.push(name);
      if (postfixes.includes(name)) {
        pairs[name] = {
          ...field,
          name,
          label: field.label.replace(/\.StartDate$/, ''),
          type: getDateRangeType(field.type),
        };
      }
    }
    if (field.name.endsWith('_end_date')) {
      const name = field.name.replace(/_end_date$/, '');
      postfixes.push(name);
      if (prefixes.includes(name)) {
        pairs[name] = {
          ...field,
          name,
          label: field.label.replace(/\.EndDate$/, ''),
          type: getDateRangeType(field.type),
        };
      }
    }
  }
  return Object.values(pairs)
    .map(pair => ({
      label: pair.label,
      value: pair.name,
    }))
    .sort((a, b) => (a.value > b.value ? 1 : -1));
}

export function isFieldMappingValid(
  componentTypes: ConfigurationState['componentTypes'],
  vulnerabilitiesWorkspace: string
) {
  if (Object.values(componentTypes).length === 0) return false;
  return (
    Boolean(vulnerabilitiesWorkspace) &&
    Object.values(componentTypes).every(
      componentType =>
        !!componentType.fields.live.label &&
        !!componentType.fields.extendedSupport.label &&
        !!componentType.fields.support.label &&
        !!componentType.fields.productId.label &&
        !!componentType.fields.url.label
    )
  );
}

export function mapFieldsToSelectOptions<T>(
  fields: { name: T; label: string }[]
) {
  const locale = getCurrentLocale();

  return fields
    .map(field => ({
      label: field.label,
      value: field.name,
    }))
    .sort((a, b) => localeCompare(a.label, b.label, locale));
}

export function filterCalculatedFields(fields: ExistingField[]) {
  return fields.filter(field => !field.isCalulatedField);
}
