import { ArdoqId, ModelUsage } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { modelInterface } from 'modelInterface/models/modelInterface';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { switchMap, tap } from 'rxjs';
import { openWorkspaceModelEditor } from '../WorkspaceModelEditor';
import { modelUsageToDict } from '../utils';
import {
  excludeWorkspaceFromScenario,
  getUsedTypeIds,
  saveModalEditorChangesError,
  saveModalEditorChangesSuccess,
  saveModelTypeChanges,
  setUsedTypeIds,
  showWorkspaceModelEditor,
} from './actions';
import { trackWorkspaceModelChanges } from 'workspaceModelEditor/tracking';
import { openScenario } from 'scope/actions';
import { handleError, modelApi, scenarioApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';

const handleShowWorkspaceModelEditor = routine(
  ofType(showWorkspaceModelEditor),
  extractPayload(),
  tap(openWorkspaceModelEditor)
);

const handleSaveWorkspaceModelEditor = routine(
  ofType(saveModelTypeChanges),
  extractPayload(),
  tap(async ({ model, workspaceId }) => {
    const currentModel = workspaceInterface.getModelData(workspaceId);
    if (currentModel) trackWorkspaceModelChanges(currentModel, model);
    const newModel = await modelInterface.saveModelData(workspaceId, model);
    if (isArdoqError(newModel)) {
      dispatchAction(saveModalEditorChangesError({ error: newModel }));
    }
    dispatchAction(saveModalEditorChangesSuccess({ newModel }));
  })
);

const handleGetUsedTypeIds = routine(
  ofType(getUsedTypeIds),
  extractPayload(),
  tap(({ workspaceId }) => {
    const wsModelAttributes = workspaceInterface.getModelData(workspaceId);
    if (!wsModelAttributes) {
      return;
    }
    modelApi.getUsage(wsModelAttributes._id).then(response => {
      if (isArdoqError(response)) {
        logError(
          response,
          `Failed to get usage for model ${wsModelAttributes._id}`
        );
        return;
      }
      const usage: ModelUsage['page-types'] = response['page-types'];
      const usageDict = modelUsageToDict(usage);

      const usedIds: ArdoqId[] = usage
        .filter(({ id: typeId }) =>
          modelInterface.isModelTypeUsed(
            typeId,
            wsModelAttributes.root,
            usageDict
          )
        )
        .map(({ id }) => id);

      dispatchAction(setUsedTypeIds({ usedTypeIds: new Set(usedIds) }));
    });
  })
);

const handleExcludeWorkspaceFromScenario = routine(
  ofType(excludeWorkspaceFromScenario),
  extractPayload(),
  switchMap(scenarioApi.removeWorkspace),
  // TODO[ARD-22935]: Maybe we need some error handling here?
  handleError(),
  tap(scenarioId => {
    dispatchAction(openScenario({ scenarioId, trackingClickSource: 'reload' }));
  })
);

export default collectRoutines(
  handleShowWorkspaceModelEditor,
  handleGetUsedTypeIds,
  handleSaveWorkspaceModelEditor,
  handleExcludeWorkspaceFromScenario
);
