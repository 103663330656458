import { useLayoutEffect, useRef, useState } from 'react';
import { ChevronIcon, IconSize, OpenInNewIcon } from '@ardoq/icons';
import { Level, MenuItemProps } from './types';
import {
  EllipsisSpan,
  FullWidthSpace,
  MenuHoverPadding,
  MenuItemButton,
  MenuTitle,
} from './atoms';
import { MenuPositioner } from '../AppMainSidebar/atoms';
import { MenuWrapper } from './atoms';
import { trackClickedLeftSidebarMenu } from 'tracking/events/clicks';
import {
  StatusType,
  BadgeDot,
  BadgeDotSize,
  BetaBadge,
  NewBadge,
} from '@ardoq/status-ui';
import { hasFeature, Features } from '@ardoq/features';
import { Space } from '@ardoq/style-helpers';
import { debounce } from 'lodash';

const nextLevel = (level?: Level): Level =>
  level === Level.ODD ? Level.EVEN : Level.ODD;

const EmptyWrapper = (props: any) => props.children;

const MenuItem = ({
  hidden,
  title,
  level,
  dataClickId,
  disabled,
  submenu,
  name,
  isNewFeature,
  isBetaFeature,
  hasNewChanges,
  hasSpaceBelow,
  isExternalLink,
  onClick,
  clearHover,
}: MenuItemProps & { hasSpaceBelow: boolean; clearHover: VoidFunction }) => {
  const [isHovered, setIsHovered] = useState(false);
  const debouncedHandleMouseEnter = debounce(() => setIsHovered(true), 200);
  const debouncedHandleMouseLeave = debounce(() => setIsHovered(false), 200);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!onClick) return;
    onClick();
    clearHover();
  };

  const handleOnMouseEnter = () => {
    debouncedHandleMouseEnter();
    debouncedHandleMouseLeave.cancel();
  };

  const handleOnMouseLeave = () => {
    debouncedHandleMouseLeave();
    debouncedHandleMouseEnter.cancel();
  };

  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);

  if (hidden) return null;
  if (title)
    return (
      <MenuTitle
        $hasOnClick={Boolean(onClick)}
        onClick={handleClick}
        $hasNewJourneyFeature={hasNewJourneyFeature}
      >
        <Space $flex={1} $align="center">
          {title}
        </Space>
      </MenuTitle>
    );
  const menuLevel = level || Level.ODD;
  const Wrapper = submenu ? MenuPositioner : EmptyWrapper;
  const isSubmenuActive = submenu && isHovered;
  return (
    <Wrapper
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onFocus={handleOnMouseEnter}
      onBlur={() => {
        handleOnMouseLeave();
      }}
    >
      <MenuItemButton
        {...(dataClickId ? { ['data-click-id']: dataClickId } : {})}
        $isActive={isSubmenuActive}
        $isDisabled={disabled}
        disabled={disabled}
        onClick={handleClick}
        $hasNewJourneyFeature={hasNewJourneyFeature}
      >
        <FullWidthSpace>
          <EllipsisSpan>{name}</EllipsisSpan>
          {isNewFeature && <NewBadge />}
          {isBetaFeature && <BetaBadge />}
          {hasNewChanges && (
            <BadgeDot size={BadgeDotSize.SMALL} color={StatusType.NEW} />
          )}
          {submenu && <ChevronIcon direction="right" />}
          {isExternalLink && (
            <OpenInNewIcon
              iconSize={
                hasNewJourneyFeature ? IconSize.MEDIUM : IconSize.REGULAR
              }
            />
          )}
        </FullWidthSpace>
      </MenuItemButton>
      {isSubmenuActive && (
        <MenuHoverPadding
          $hasSpaceBelow={hasSpaceBelow}
          $hasNewJourneyFeature={hasFeature(Features.NEW_CORE_JOURNEY)}
        >
          <MenuWrapper
            $hasSpaceBelow={hasSpaceBelow}
            $hasNewJourneyFeature={hasFeature(Features.NEW_CORE_JOURNEY)}
          >
            {(submenu || []).map((submenuItem, index) => (
              <MenuItem
                key={index}
                {...submenuItem}
                level={nextLevel(menuLevel)}
                hasSpaceBelow={hasSpaceBelow}
                clearHover={clearHover}
              />
            ))}
          </MenuWrapper>
        </MenuHoverPadding>
      )}
    </Wrapper>
  );
};

type AppMainSidebarMenuProps = {
  menuItems: MenuItemProps[];
  clearHover: VoidFunction;
};

const createTrackedOnClick = (
  onClick: (() => void) | undefined,
  trackingLabel?: string
) => {
  if (!onClick) return undefined;
  if (!trackingLabel) return onClick;
  return () => {
    trackClickedLeftSidebarMenu(trackingLabel);
    onClick();
  };
};

const decorateMenuItems = (menuItems: MenuItemProps[]): MenuItemProps[] => {
  return menuItems.map(menuItem => ({
    ...menuItem,
    onClick: createTrackedOnClick(menuItem.onClick, menuItem.trackingLabel),
    submenu: menuItem.submenu ? decorateMenuItems(menuItem.submenu) : undefined,
  }));
};

const AppMainSidebarMenu = ({
  menuItems,
  clearHover,
}: AppMainSidebarMenuProps) => {
  const [hasSpaceBelow, setHasSpaceBelow] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const top = ref.current?.getBoundingClientRect().top || 0;
    const viewPortHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    setHasSpaceBelow((top || 0) < viewPortHeight / 2);
  }, []);

  return (
    <MenuHoverPadding
      ref={ref}
      $hasSpaceBelow={hasSpaceBelow}
      $hasNewJourneyFeature={hasFeature(Features.NEW_CORE_JOURNEY)}
    >
      <MenuWrapper
        $hasSpaceBelow={hasSpaceBelow}
        $hasNewJourneyFeature={hasFeature(Features.NEW_CORE_JOURNEY)}
      >
        {decorateMenuItems(menuItems).map((menuItem, index) => (
          <MenuItem
            key={index}
            {...menuItem}
            hasSpaceBelow={hasSpaceBelow}
            clearHover={clearHover}
          />
        ))}
      </MenuWrapper>
    </MenuHoverPadding>
  );
};
export default AppMainSidebarMenu;
