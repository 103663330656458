import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { dispatchAction } from '@ardoq/rxbeach';
import fp from 'lodash/fp';
import { SelectionState } from 'integrations/azure/streams/selectionState/types';
import { RegionsState } from 'integrations/cloudProviders/streams/regions/types';
import { ResourceTypesState } from 'integrations/cloudProviders/streams/resourceTypes/types';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { AzureConnection } from '@ardoq/api-types/integrations';
import { selectionState$ } from '../../streams/selectionState/selectionState$';
import { unselectCloudProviderParams } from '../../streams/selectionState/actions';
import { isValidSelectionState } from 'integrations/azure/streams/selectionState/utils';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import { ensureCloudProvider } from 'integrations/cloudProviders/utils';
import { clearTabularMapping } from 'integrations/common/streams/tabularMappings/actions';
import { setTransferConfigId } from 'integrations/common/streams/activeIntegrations/actions';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { applySourceConfig } from 'integrations/cloudProviders/config/actions';
import { changeRegionsSelection } from 'integrations/cloudProviders/streams/regions/actions';
import { selectConnection } from 'integrations/common/streams/connections/actions';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import {
  selectConfigPopoverContent,
  selectConnectionPopoverContent,
} from './atoms';
import { resourceGroups$ } from 'integrations/azure/streams/resourceGroups/resourceGroups$';
import { ResourceGroupsState } from 'integrations/azure/streams/resourceGroups/types';
import { getTabularMappingStream } from '../../../common/streams/tabularMappings/getTabularMappingStream';
import { getIsUserAgreeToOverrideMapping } from 'integrations/common/streams/transferConfigs/utils';
import { loadSelection } from 'integrations/common/streams/selectionState/actions';

const getNextButtonPopover = (
  selectedConnectionId: string,
  selectedRegionIds: RegionsState['selectedRegionIds'],
  subscriptionIds: SelectionState['subscriptionIds'],
  selectedResourceGroupNames: ResourceGroupsState['selectedResourceGroupNames'],
  selectedResourceTypeIds: ResourceTypesState['selectedResourceTypeIds']
) => {
  const optionsToSelect = fp.flow(
    fp.pickBy(t => t),
    fp.keys
  )({
    connection: !selectedConnectionId,
    region: selectedRegionIds.length === 0,
    subscription: subscriptionIds.length === 0,
    'resource group': selectedResourceGroupNames.length === 0,
    resource: selectedResourceTypeIds.length === 0,
  });

  if (optionsToSelect.length > 1) {
    const resourceMessage = `${optionsToSelect
      .slice(0, -1)
      .join(', ')} and ${optionsToSelect.slice(-1)}`;
    return `Please select at least one ${resourceMessage} in order to continue.`;
  }

  if (optionsToSelect.length === 1) {
    return `Please select at least one ${optionsToSelect[0]} in order to continue.`;
  }

  return null;
};

export const viewModel$ = integrationId$.pipe(
  ensureCloudProvider(),
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      transferConfigs: getTransferConfigsStream(integrationId).pipe(
        map(({ configs }) => configs)
      ),
      connections: getConnectionsStream<AzureConnection>(integrationId),
      selectedTransferConfigId: getActiveIntegrationStream(integrationId).pipe(
        map(ci => ci.selectedTransferConfigId),
        distinctUntilChanged()
      ),
      selectionState: selectionState$,
      regionsState: getRegionsStream(integrationId),
      resourceGroupsState: resourceGroups$,
      resourceTypesState: getResourceTypesStream(integrationId),
      integrationTermsDictionary:
        getIntegrationTermsDictionaryStream(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
    });
  }),
  map(
    ({
      integrationId,
      connections: { connections, selectedConnectionIds, statuses },
      transferConfigs,
      selectedTransferConfigId,
      selectionState,
      regionsState,
      resourceGroupsState,
      resourceTypesState,
      integrationTermsDictionary: {
        selectConnectionHelperText,
        selectConfigHelperText,
        selectConnectionPlaceHolder,
        selectRegionsLabel,
        selectRegionsHelperText,
      },
      tabularMapping,
    }) => ({
      connections,
      selectedConnectionIds: selectedConnectionIds,
      regions: regionsState.regions,
      selectedRegionIds: regionsState.selectedRegionIds,
      onRegionsSelect: (regions: string[]) => {
        dispatchAction(changeRegionsSelection({ integrationId, regions }));
      },
      transferConfigs,
      selectedTransferConfigId,
      nextButtonPopover: getNextButtonPopover(
        selectedConnectionIds[0],
        regionsState.selectedRegionIds,
        selectionState.subscriptionIds,
        resourceGroupsState.selectedResourceGroupNames,
        resourceTypesState.selectedResourceTypeIds
      ),
      selectConnectionHelperText,
      selectConfigHelperText,
      selectConnectionPlaceHolder,
      selectRegionsLabel,
      selectRegionsHelperText,
      selectConnectionPopoverContent: selectConnectionPopoverContent,
      selectConfigPopoverContent: selectConfigPopoverContent,
      errorMessage: selectionState.fetchErrorMessage || statuses.list.message,
      onConnectionSelect: (connectionIds: string[]) => {
        if (!connectionIds.length) {
          return;
        }

        dispatchAction(
          selectConnection({
            selectedConnectionId: connectionIds[0],
            integrationId,
          })
        );
      },
      isValidSelection: isValidSelectionState({
        selectionState,
        selectedConnectionIds,
        regionsState,
        resourceGroupsState,
        resourceTypesState,
      }),
      onTransferConfigSelect: async (
        configId: SavedTransferConfig['_id'] | null
      ) => {
        if (selectedTransferConfigId === configId) {
          return;
        }

        const transferConfig = transferConfigs.find(m => m._id === configId);

        if (!transferConfig) {
          return;
        }

        const isUserAgreeToOverrideMapping =
          await getIsUserAgreeToOverrideMapping(tabularMapping);

        if (!isUserAgreeToOverrideMapping) {
          return;
        }

        dispatchAction(clearTabularMapping({ integrationId }));
        dispatchAction(setTransferConfigId({ integrationId, id: configId }));

        if (!transferConfig.sourceConfig) {
          return;
        }

        dispatchAction(
          applySourceConfig({
            ...fp.update(
              'accountIds',
              cid =>
                selectedConnectionIds.length ? selectedConnectionIds : cid,
              transferConfig.sourceConfig
            ),
            integrationId,
          })
        );
      },
      onClearConfiguration: () => {
        dispatchAction(unselectCloudProviderParams());
        dispatchAction(clearTabularMapping({ integrationId: 'azure-v3' }));
        dispatchAction(
          setTransferConfigId({ integrationId: 'azure-v3', id: null })
        );
      },
      onFetchData: () => {
        dispatchAction(loadSelection(integrationId));
      },
    })
  )
);
