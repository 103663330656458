import {
  RoundNumberTransformation,
  Transformation,
} from '@ardoq/api-types/integrations';
import { NumberInput } from '@ardoq/forms';
import { Paragraph } from '@ardoq/typography';
import { FlexBox, Stack } from '@ardoq/layout';

type NumberPrecisionTransformationProps = {
  transformation: RoundNumberTransformation;
  setTransformation: (newTransformation: Transformation) => void;
};

const RoundNumberTransformationSection = ({
  transformation,
  setTransformation,
}: NumberPrecisionTransformationProps) => {
  const exampleNumber = 1.5678;

  // Custom formatter to remove the trailing zeros if precision exceeds actual number of decimals
  const formatNumber = (num: number, precision: number) =>
    parseFloat(num.toFixed(precision)).toString();

  const onChangePrecision = (value: number) => {
    setTransformation({
      ...transformation,
      config: {
        ...transformation.config,
        precision: value,
      },
    });
  };

  return (
    <FlexBox gap="medium">
      <FlexBox justify="center" padding="small">
        <Paragraph variant="text1">Precision</Paragraph>
      </FlexBox>
      <FlexBox align="start">
        <Stack gap="small">
          <NumberInput
            value={transformation.config.precision}
            allowBlank={false}
            min={0}
            onValueChange={onChangePrecision}
          />
          <Paragraph variant="caption" color="textSubtle">
            Example: {exampleNumber} &#x2192;{' '}
            {formatNumber(exampleNumber, transformation.config.precision)}
          </Paragraph>
        </Stack>
      </FlexBox>
    </FlexBox>
  );
};

export default RoundNumberTransformationSection;
