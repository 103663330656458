import { actionCreator } from '@ardoq/rxbeach';
import { UseCaseTabs } from './types';

export const selectUseCaseTab = actionCreator<UseCaseTabs>(
  '[useCase] SELECT_USE_CASE_TAB'
);

export const navigateToUseCaseTab = actionCreator<{
  tab: UseCaseTabs;
  skipCheck?: boolean;
}>('[useCase] NAVIGATE_TO_USE_CASE_TAB');

export const editUseCase = actionCreator<string>('[useCase] EDIT_USE_CASE');

export const fetchUseCasesData = actionCreator(
  '[useCase] FETCH_USE_CASES_DATA'
);

export const setIsLoading = actionCreator<boolean>('[useCase] SET_IS_LOADING');

export const deleteUseCase = actionCreator<{ id: string; name: string }>(
  '[useCase] DELETE_USE_CASE'
);

export const fetchComponentTypes = actionCreator(
  '[useCase] FETCH_COMPONENT_TYPES'
);

export const fetchReferenceTypes = actionCreator(
  '[useCase] FETCH_REFERENCE_TYPES'
);

export const fetchEntityGroups = actionCreator('[useCase] FETCH_ENTITY_GROUPS');

export const fetchKeyMetricsDashboards = actionCreator(
  '[useCase] FETCH_KEY_METRICS_DASHBOARDS'
);

export const setUseCasesError = actionCreator<string>('[useCase] SET_ERROR');

export const createUseCaseFromExisting = actionCreator<string>(
  '[useCase] CREATE_USE_CASE_FROM_EXISTING'
);
