import {
  OpenViewpointBuilderCommands,
  openViewpointBuilderCommands,
} from 'viewpointBuilder/openViewpointBuilderCommands';
import {
  assetsBrowser2024Commands,
  AssetsBrowser2024Commands,
} from 'components/AssetsBrowser2024/assetsBrowser2024Commands';

export type DashboardAssetBrowserCommands = Pick<
  OpenViewpointBuilderCommands,
  | 'openViewpointBuilderToCreateViewpoint'
  | 'openViewpointBuilderToSelectStartComponentsForViewpoint'
> &
  Pick<
    AssetsBrowser2024Commands,
    'openBookmark' | 'openDiscoverViewpoint' | 'openBroadcast'
  >;

export const dashboardAssetBrowserCommands: DashboardAssetBrowserCommands = {
  openViewpointBuilderToSelectStartComponentsForViewpoint:
    openViewpointBuilderCommands.openViewpointBuilderToSelectStartComponentsForViewpoint,
  openViewpointBuilderToCreateViewpoint:
    openViewpointBuilderCommands.openViewpointBuilderToCreateViewpoint,
  openBookmark: assetsBrowser2024Commands.openBookmark,
  openDiscoverViewpoint: assetsBrowser2024Commands.openDiscoverViewpoint,
  openBroadcast: assetsBrowser2024Commands.openBroadcast,
};
