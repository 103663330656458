import { actionCreator } from '@ardoq/rxbeach';
import { TableId } from 'integrations/common/streams/tabularMappings/types';
import {
  SelectionResponse,
  ServiceNowConfig,
} from '@ardoq/api-types/integrations';
import { AsyncStatus } from 'integrations/common/types/api';
import { SelectionState } from './types';
import { Maybe } from '@ardoq/common-helpers';

const NAMESPACE = '[integrations servicenow-v3] SELECTION';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const resetSelection = actionCreator(getActionName('RESET_SELECTION'));

// tables

export const applyTables = actionCreator<SelectionState['tables']>(
  getActionName('APPLY_TABLES')
);

export const applyUnavailableTables = actionCreator<
  SelectionState['unavailableTables']
>(getActionName('APPLY_UNAVAILABLE_TABLES'));

export const selectTable = actionCreator<TableId>(
  getActionName('SELECT_TABLE')
);

export const unselectUnavailableTables = actionCreator(
  getActionName('REMOVE_UNAVAILABLE_TABLES')
);

export const unselectTable = actionCreator<TableId>(
  getActionName('UNSELECT_TABLE')
);

export const unselectTables = actionCreator(getActionName('UNSELECT_TABLES'));

// fields

export type SelectFieldPayload = { tableId: TableId; id: string };

export const selectField = actionCreator<SelectFieldPayload>(
  getActionName('SELECT_FIELD')
);

export const unselectField = actionCreator<SelectFieldPayload>(
  getActionName('UNSELECT_FIELD')
);

export const unselectFields = actionCreator<TableId>(
  getActionName('UNSELECT_FIELDS')
);

export type SelectFieldsPayload = {
  tableId: TableId;
  ids: string[];
};

export const selectFields = actionCreator<SelectFieldsPayload>(
  getActionName('SELECT_ALL_TABLE_FIELDS')
);

export const unselectUnavailableFields = actionCreator(
  getActionName('REMOVE_UNAVAILABLE_FIELDS')
);

export type SetQueryFilterPayload = {
  tableId: TableId;
  queryFilter: string | null;
};

export const setQueryFilter = actionCreator<SetQueryFilterPayload>(
  getActionName('SET_QUERY_FILTER')
);

// applying selection

export const applySourceConfig = actionCreator<ServiceNowConfig>(
  getActionName('APPLY_SOURCE_CONFIG')
);

// fetching

export const fetchSelectedTablesFields = actionCreator(
  getActionName('FETCH_SELECTED_TABLES_FIELDS')
);

export const setSelectionAsyncStatus = actionCreator<AsyncStatus>(
  getActionName('SET_SELECTION_ASYNC_STATUS')
);

export const fetchSelectionSuccess = actionCreator<SelectionResponse>(
  getActionName('FETCH_SELECTION_SUCCESS')
);

export const setFetchSelectionError = actionCreator<Maybe<string>>(
  getActionName('SET_FETCH_SELECTION_ERROR')
);
