import { ToolbarDivider } from '@ardoq/page-layout';
import Navbar from 'views/navbar/Navbar';
import EditableBroadcastName from './EditableBroadcastName';
import broadcast$ from 'broadcasts/broadcast$';
import { BroadcastStreamShape, BroadcastSaveStatus } from 'broadcasts/types';
import {
  doesCurrentBroadcastHaveUnsavedProgress,
  isReportContent,
} from 'broadcasts/utils';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { distinctUntilChanged, map } from 'rxjs';
import { isEqual } from 'lodash';
import {
  IconButton,
  BrandButton,
  SecondaryButton,
  GhostButton,
} from '@ardoq/button';
import { trackClickedOnGoToBroadcastOverviewInBroadcastBuilder } from 'broadcasts/tracking';
import { navigateToBroadcastOverview } from 'router/navigationActions';
import {
  initiateSavingCurrentBroadcast,
  openPreviewBroadcastDialog,
  showLaunchCurrentBroadcastDialog,
} from 'broadcasts/actions';
import { BroadcastIcon, ChevronLeftIcon, IconName } from '@ardoq/icons';
import { trackEvent } from 'tracking/tracking';
import { Space } from '@ardoq/style-helpers';

type BroadcastNavbarProps = {
  hasUnsavedProgress: boolean;
  isSaving: boolean;
  isReportBroadcast: boolean;
};

const BroadcastNavbar = ({
  hasUnsavedProgress,
  isSaving,
  isReportBroadcast,
}: BroadcastNavbarProps) => {
  return (
    <Navbar
      primaryContent={<EditableBroadcastName />}
      primaryButton={
        <BrandButton
          isDisabled={isSaving}
          dataTestId="launch-button"
          onClick={() => dispatchAction(showLaunchCurrentBroadcastDialog())}
        >
          Launch broadcast
          <BroadcastIcon />
        </BrandButton>
      }
      secondaryContent="Broadcast builder"
      secondaryButton={
        <SecondaryButton
          isDisabled={!hasUnsavedProgress}
          onClick={() => dispatchAction(initiateSavingCurrentBroadcast())}
          dataTestId="save-button"
        >
          Save changes
        </SecondaryButton>
      }
      toolbarContent={
        <Space $justify="flex-end" $align="center">
          <IconButton
            iconName={IconName.VISIBILITY}
            onClick={() => {
              trackEvent('open-broadcast-preview-dialog');
              dispatchAction(openPreviewBroadcastDialog());
            }}
            dataTestId="open-broadcast-preview-dialog"
            isDisabled={isReportBroadcast}
            data-tooltip-text="Preview"
          />
          <ToolbarDivider />
          <GhostButton
            onClick={() => {
              trackClickedOnGoToBroadcastOverviewInBroadcastBuilder();
              dispatchAction(navigateToBroadcastOverview());
            }}
            dataTestId="broadcast-overview-button"
          >
            <ChevronLeftIcon />
            Back to Broadcasts
          </GhostButton>
          <ToolbarDivider />
        </Space>
      }
    />
  );
};

const toProps = (state: BroadcastStreamShape) => ({
  hasUnsavedProgress: doesCurrentBroadcastHaveUnsavedProgress(state),
  isSaving: state.saveStatus === BroadcastSaveStatus.SAVING,
  isReportBroadcast:
    state.currentBroadcast && isReportContent(state.currentBroadcast.content),
});

export default connect(
  BroadcastNavbar,
  broadcast$.pipe(map(toProps), distinctUntilChanged(isEqual))
);
