import { ApiState } from '@ardoq/manage-resource-permissions';
import {
  ReportBuilderNavBarCommands,
  ReportBuilderNavBarProps,
} from '../NavBarButtons';

type GetNavBarPropsArgs = {
  hasUnsavedProgress: boolean;
  isSubmitting: boolean;
  hasSubdivisionsFeature: boolean;
  permissionsApiState: ApiState;
  userHasReportAdminAccess: boolean;
  reportName: string;
};

export const getNavBarProps = ({
  hasUnsavedProgress,
  isSubmitting,
  hasSubdivisionsFeature,
  permissionsApiState,
  userHasReportAdminAccess,
  reportName,
  commands,
}: GetNavBarPropsArgs & {
  commands: ReportBuilderNavBarCommands;
}): ReportBuilderNavBarProps => ({
  reportName,
  saveReportButtonProps: {
    isDisabled: isSubmitting || !hasUnsavedProgress,
    onClick: commands.saveReport,
  },
  exitEditingButtonProps: {
    onClick: commands.exitEditing,
    hasUnsavedProgress,
  },
  toolbarButtonProps: {
    shareButtonProps:
      hasSubdivisionsFeature && userHasReportAdminAccess
        ? {
            isVisible: true,
            isDisabled: isSubmitting || hasUnsavedProgress,
            isLoading: permissionsApiState === ApiState.LOADING,
            onClick: commands.shareReport,
          }
        : {
            isVisible: false,
          },
    knowledgeBaseButtonProps: {
      onClick: commands.openKBArticle,
    },
  },
});
