import {
  APISurveyAttributes,
  APISurveyPendingApprovalsSummary,
  AssetType,
  UpdateType,
} from '@ardoq/api-types';
import {
  confirmSurveyDigestUnsubscribe,
  fetchSurveyValidation,
} from 'surveyAdmin/streams/actions';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { useEffectOnce } from '@ardoq/hooks';
import surveyOrgAndUserDetails$, {
  SurveyOrgAndUserStreamProps,
} from 'surveyAdmin/SurveyEditor/surveyOrgAndUserDetails$';
import { trackEvent } from 'tracking/tracking';
import {
  approvalOffFilter,
  approvalOnFilter,
  getPendingChangesFilter,
  handleOpenResponseLog,
} from './overviewHelpers';
import { SurveyAdminMode } from 'surveyAdmin/navigation/types';
import EmptyState from 'components/AssetsBrowser2024/EmptyState';
import { emptyTableProps } from './consts';
import AssetsBrowser2024 from 'components/AssetsBrowser2024/AssetsBrowser2024';
import { AppModules } from 'appContainer/types';
import {
  favoriteFilter,
  getAssetFilter,
  getCreatedByMeFilter,
} from 'components/AssetsBrowser2024/FilterBar/filters';
import { getLastUpdatedColumn } from 'components/AssetsBrowser2024/assetsBrowser2024Columns';
import {
  getApprovalColumn,
  getContactEmailColumn,
  getValidationColumn,
  getStatusColumn,
} from './columns';
import { DoqLoader } from '@ardoq/snowflakes';

type OverviewTableProps = {
  surveys: APISurveyAttributes[];
  surveysWithPendingApprovals: APISurveyPendingApprovalsSummary;
  height: number;
};

type ConnectedProps = SurveyOrgAndUserStreamProps;

const OverviewTable = ({
  surveys = [],
  currentUserId,
  orgLabel,
  surveyOverviewSearchParams,
  surveysWithPendingApprovals,
  surveyValidation,
  mode,
  isLoadingPendingApprovals,
  isLoadingSurveyValidation,
  hasSurveyResponseApprovalsFeature,
  height,
  canCreateSurvey,
}: OverviewTableProps & ConnectedProps) => {
  useEffectOnce(() => {
    dispatchAction(fetchSurveyValidation());

    if (!surveyOverviewSearchParams) return;
    const token = surveyOverviewSearchParams.token;
    const updateType = surveyOverviewSearchParams.updateType as UpdateType;
    const relevantSurvey = surveys.find(
      survey => survey._id === surveyOverviewSearchParams.surveyId
    );

    const isSurveyDigestUnsubscribeRequest =
      token &&
      relevantSurvey &&
      relevantSurvey.surveyNotificationsAudience?.includes(currentUserId);

    const isSurveyResponseLogRequest =
      surveyOverviewSearchParams.updateType &&
      Object.values(UpdateType).includes(updateType) &&
      relevantSurvey;

    const hasSurveyDigestRef =
      surveyOverviewSearchParams.ref === 'survey-digest';

    if (isSurveyDigestUnsubscribeRequest) {
      dispatchAction(
        confirmSurveyDigestUnsubscribe({
          surveyId: relevantSurvey._id,
          surveyName: relevantSurvey.name ?? '',
          token,
          orgLabel,
        })
      );
      if (hasSurveyDigestRef)
        trackEvent(
          'Survey digest unsubscribe: triggered via survey digest email'
        );
    }

    if (isSurveyResponseLogRequest) {
      handleOpenResponseLog(relevantSurvey, updateType);
      if (hasSurveyDigestRef)
        trackEvent('Survey response log: triggered via survey digest email');
    }
  });

  if (mode !== SurveyAdminMode.OVERVIEW) return null;

  const surveyResponseApprovalFilterChips = hasSurveyResponseApprovalsFeature
    ? [
        approvalOffFilter,
        approvalOnFilter,
        getPendingChangesFilter(surveysWithPendingApprovals),
      ]
    : [];

  return isLoadingPendingApprovals || isLoadingSurveyValidation ? (
    <DoqLoader />
  ) : surveys.length ? (
    <AssetsBrowser2024
      appModule={AppModules.SURVEY_ADMIN}
      alwaysActiveFilters={[getAssetFilter(AssetType.SURVEY)]}
      filters={[
        favoriteFilter,
        getCreatedByMeFilter(currentUserId),
        ...surveyResponseApprovalFilterChips,
      ]}
      scrollHeight={height}
      getCustomColumns={columnsProps =>
        [
          getValidationColumn(surveyValidation),
          getStatusColumn(columnsProps),
          getContactEmailColumn(columnsProps),
          getLastUpdatedColumn(columnsProps),
          hasSurveyResponseApprovalsFeature &&
            getApprovalColumn(surveysWithPendingApprovals),
        ].filter(ExcludeFalsy)
      }
    />
  ) : (
    <EmptyState
      {...emptyTableProps.noSurveys}
      text={canCreateSurvey ? 'Create a new survey to get started.' : ''}
    />
  );
};

export default connect(OverviewTable, surveyOrgAndUserDetails$);
