import React from 'react';
import { MetamodelViewCommands } from './types';
import { MetamodelViewState } from './types';
import {
  DoqContainer,
  EnforceContentWidth,
  FieldType,
  FullHeightSpace,
  List,
  SidebarHeader,
  WorkspaceAndFieldItem,
} from '../components/atoms';
import { CustomClosableIslandHeader } from 'viewpointBuilder/components/CustomClosableIslandHeader';
import { SidebarMenu } from '@ardoq/sidebar-menu';
import { IconName } from '@ardoq/icons';
import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { ControlledCollapsibleIsland } from '@ardoq/page-layout';
import { Box, Stack } from '@ardoq/layout';

type MetamodelSidePanelProps = {
  state: MetamodelViewState;
  commands: MetamodelViewCommands;
};
export const MetamodelSidePanel = ({
  state: {
    expandedSection,
    selectedFields,
    selectedWorkspaces,
    selectedComponentType,
  },
  commands,
}: MetamodelSidePanelProps) => {
  if (!selectedComponentType) {
    return (
      <FullHeightSpace>
        <DoqContainer>
          <ResponsiveDoqWithSpeechBubble
            doqType={DoqType.SIMPLE}
            title="Select a component type"
            message="Select a component type to see workspaces and fields it is used in."
          />
        </DoqContainer>
      </FullHeightSpace>
    );
  }
  return (
    <SidebarMenu
      isPageBodyMenu
      isResizable
      isRightContent
      headerBody={
        <SidebarHeader
          type="component"
          selectedType={selectedComponentType}
          headerText={'Workspaces and fields for'}
          iconName={IconName.METAMODEL}
        />
      }
    >
      <EnforceContentWidth>
        <Box padding="medium">
          <Stack gap="medium">
            <ControlledCollapsibleIsland
              minWidth="300"
              bodySpacing="medium"
              isExpanded={expandedSection.workspaces}
              onToggle={() => commands.toggleExpandableSection('workspaces')}
              header={
                <CustomClosableIslandHeader
                  label="Workspaces"
                  isExpanded={expandedSection.workspaces}
                  onToggle={() =>
                    commands.toggleExpandableSection('workspaces')
                  }
                />
              }
            >
              {selectedWorkspaces && (
                <List>
                  {selectedWorkspaces.map((name, index) => (
                    <WorkspaceAndFieldItem key={index} $isClickable={false}>
                      {name}
                    </WorkspaceAndFieldItem>
                  ))}
                </List>
              )}
            </ControlledCollapsibleIsland>

            <ControlledCollapsibleIsland
              minWidth="300"
              bodySpacing="medium"
              isExpanded={expandedSection.fields}
              onToggle={() => commands.toggleExpandableSection('fields')}
              header={
                <CustomClosableIslandHeader
                  label="Fields"
                  isExpanded={expandedSection.fields}
                  onToggle={() => commands.toggleExpandableSection('fields')}
                />
              }
            >
              {selectedFields && (
                <List>
                  {selectedFields.map(({ label, type }, index) => (
                    <WorkspaceAndFieldItem key={index} $isClickable={false}>
                      <span>{label}</span>
                      <FieldType>{type}</FieldType>
                    </WorkspaceAndFieldItem>
                  ))}
                </List>
              )}
            </ControlledCollapsibleIsland>
          </Stack>
        </Box>
      </EnforceContentWidth>
    </SidebarMenu>
  );
};
