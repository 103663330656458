import { Subdivision } from '@ardoq/api-types';
import { CreateNewButton } from '@ardoq/page-layout';
import Navbar from 'views/navbar/Navbar';
import { SubdivisionCommands } from '../types';
import { PrimaryButton } from '@ardoq/button';
import { SubdivisionOverviewTable } from './SubdivisionOverviewTable';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { Island, PageHeader, PageBody, PageWrapper } from '@ardoq/page-layout';

export type SubdivisionOverviewProps = {
  subdivisions: Subdivision[];
  newCoreJourney: boolean;
  commands: SubdivisionCommands;
};

export const SubdivisionOverview = ({
  subdivisions,
  newCoreJourney,
  commands,
}: SubdivisionOverviewProps) => (
  <PageWrapper>
    {newCoreJourney ? (
      <Navbar
        primaryContent="Permissions"
        primaryButton={
          <CreateNewButton onClick={commands.createSubdivision}>
            {SUBDIVISIONS_STRINGS.SUBDIVISIONS_EDITOR.CREATE_NEW}
          </CreateNewButton>
        }
        secondaryContent="Overview"
      />
    ) : (
      <PageHeader
        title="Permissions"
        rightContent={
          <PrimaryButton onClick={commands.createSubdivision}>
            Create new permission
          </PrimaryButton>
        }
      />
    )}
    <PageBody>
      <Island>
        <SubdivisionOverviewTable
          subdivisions={subdivisions}
          commands={commands}
        />
      </Island>
    </PageBody>
  </PageWrapper>
);
