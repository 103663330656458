import { CreateServiceAccountAndTokenPayload } from '@ardoq/api-types';
import { modal } from '@ardoq/modal';
import ServiceAccountCreationModal from 'admin/serviceAccounts/ServiceAccountCreationModal';
import {
  SERVICE_ACCOUNTS_EVENTS,
  trackServiceAccountsEvent,
} from 'admin/serviceAccounts/serviceAccountsTrackingUtils';
import { AccessControlPageProps } from '../types';
import { AddIcon } from '@ardoq/icons';
import { BrandButton } from '@ardoq/button';

const openCreateServiceAccountModal = (props: AccessControlPageProps) => {
  trackServiceAccountsEvent(SERVICE_ACCOUNTS_EVENTS.CLICKED_CREATE_NEW_BUTTON);
  modal(closeModal => (
    <ServiceAccountCreationModal
      onCancel={() => closeModal(true)}
      onSubmit={(input: CreateServiceAccountAndTokenPayload) => {
        closeModal(true);
        props.commands.createToken(input);
      }}
      roles={(props.resources.rolesShape?.roles || []).map(role => ({
        label: role.title,
        value: role.label,
        key: role.label,
      }))}
    />
  ));
};

export const CreateServiceAccountButton = (props: AccessControlPageProps) => {
  return (
    <BrandButton onClick={() => openCreateServiceAccountModal(props)}>
      Create new
      <AddIcon />
    </BrandButton>
  );
};
