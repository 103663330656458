import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  fetchPreviewRowsSuccess,
  setFetchRowsStatus,
  setTableDataRows,
  setTablePreviews,
  resetTablesRows,
} from './actions';
import { Observable, map } from 'rxjs';
import { TablePreviews } from './types';
import type { IntegrationId } from '../tabularMappings/types'; // for now
import { merge } from 'lodash/fp';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { getAdjustedRows } from './utils';
import { buildInitialStreamState } from '../utils';
import { ActionCreatorParameter } from '../../utils/actionCreatorWithIntegrationId';

export type AllTablePreviews = Record<IntegrationId, TablePreviews>;

const setTablePreviewsReducer = (
  currentAllConfigs: AllTablePreviews,
  {
    integrationId,
    tablePreviews,
  }: ActionCreatorParameter<typeof setTablePreviews>
) => {
  const initTablePreviews: TablePreviews = tablePreviews.reduce(
    (acc, table) => {
      return {
        ...acc,
        [table.id]: {
          ...table,
          previewRows: {
            header: [],
            rows: [],
            fetchRowsStatus: 'INIT',
            fetchProblemRowsStatus: 'INIT',
          },
        },
      };
    },
    {}
  );

  return {
    ...currentAllConfigs,
    [integrationId]: initTablePreviews,
  };
};
const handleSetTables = reducer<
  AllTablePreviews,
  ActionCreatorParameter<typeof setTablePreviews>
>(setTablePreviews, setTablePreviewsReducer);

const setTableDataRowsReducer = (
  allTables: AllTablePreviews,
  {
    integrationId,
    rows,
    tableId,
  }: ActionCreatorParameter<typeof setTableDataRows>
) => {
  const currentTable = allTables[integrationId][tableId];

  if (!currentTable) {
    return allTables;
  }

  const adjustedRows = getAdjustedRows({
    currentRows: currentTable.previewRows?.rows,
    newRows: rows,
  });

  const tablePreviews: TablePreviews = {
    ...allTables[integrationId],
    [tableId]: {
      ...currentTable,
      previewRows: {
        header: currentTable.previewRows?.header ?? [],
        rows: adjustedRows,
        fetchRowsStatus: 'SUCCESS',
        fetchProblemRowsStatus:
          currentTable?.previewRows?.fetchProblemRowsStatus,
      },
    },
  };

  return {
    ...allTables,
    [integrationId]: tablePreviews,
  };
};
const handleSetTableRows = reducer<
  AllTablePreviews,
  ActionCreatorParameter<typeof setTableDataRows>
>(setTableDataRows, setTableDataRowsReducer);

const setFetchRowsStatusReducer = (
  currentAllConfigs: AllTablePreviews,
  {
    integrationId,
    tableId,
    fetchRowsStatus,
    fetchProblemRowsStatus,
  }: ActionCreatorParameter<typeof setFetchRowsStatus>
) => {
  const source = {
    ...(fetchRowsStatus && {
      fetchRowsStatus: fetchRowsStatus,
    }),
    ...(fetchProblemRowsStatus && {
      fetchProblemRowsStatus: fetchProblemRowsStatus,
    }),
  };

  const currentTable = currentAllConfigs[integrationId][tableId] ?? {};

  const tablePreviews: TablePreviews = {
    ...currentAllConfigs[integrationId],
    [tableId]: {
      ...currentTable,
      previewRows: merge(currentTable.previewRows, source),
    },
  };

  return {
    ...currentAllConfigs,
    [integrationId]: tablePreviews,
  };
};
const handleSetFetchRowsStatus = reducer<
  AllTablePreviews,
  ActionCreatorParameter<typeof setFetchRowsStatus>
>(setFetchRowsStatus, setFetchRowsStatusReducer);

const fetchPreviewRowsSuccessReducer = (
  currentAllConfigs: AllTablePreviews,
  {
    integrationId,
    tableId,
    rows,
    header,
  }: ActionCreatorParameter<typeof fetchPreviewRowsSuccess>
) => {
  const currentTable = currentAllConfigs[integrationId][tableId] ?? {};

  const adjustedRows = getAdjustedRows({
    currentRows: currentTable.previewRows?.rows,
    newRows: rows,
  });

  const tablePreviews: TablePreviews = {
    ...currentAllConfigs[integrationId],
    [tableId]: {
      ...currentTable,
      previewRows: {
        header,
        rows: adjustedRows,
        fetchRowsStatus: 'SUCCESS',
        fetchProblemRowsStatus:
          currentTable.previewRows?.fetchProblemRowsStatus,
      },
    },
  };

  return {
    ...currentAllConfigs,
    [integrationId]: tablePreviews,
  };
};
const handleFetchPreviewRowsSuccess = reducer<
  AllTablePreviews,
  ActionCreatorParameter<typeof fetchPreviewRowsSuccess>
>(fetchPreviewRowsSuccess, fetchPreviewRowsSuccessReducer);

const resetIntegrationReducer = (
  configs: AllTablePreviews,
  integrationId: IntegrationId
) => ({
  ...configs,
  [integrationId]: {},
});
const handleResetIntegration = reducer<AllTablePreviews, IntegrationId>(
  resetIntegration,
  resetIntegrationReducer
);

const resetTablesRowsReducer = (
  currentAllConfigs: AllTablePreviews,
  { integrationId }: ActionCreatorParameter<typeof resetTablesRows>
) => {
  const tablePreviews: TablePreviews = Object.values(
    currentAllConfigs[integrationId]
  ).reduce((acc, table) => {
    if (table.previewRows) {
      return {
        ...acc,
        [table.id]: {
          ...table,
          previewRows: {
            ...table.previewRows,
            rows: [],
            fetchRowsStatus: 'INIT',
          },
        },
      };
    }

    return {
      ...acc,
      [table.id]: table,
    };
  }, {});

  return {
    ...currentAllConfigs,
    [integrationId]: tablePreviews,
  };
};
const handleResetTablesRows = reducer<
  AllTablePreviews,
  ActionCreatorParameter<typeof resetTablesRows>
>(resetTablesRows, resetTablesRowsReducer);

const reducers = [
  handleSetTables,
  handleFetchPreviewRowsSuccess,
  handleSetTableRows,
  handleSetFetchRowsStatus,
  handleResetIntegration,
  handleResetTablesRows,
];

const defaultState: AllTablePreviews = buildInitialStreamState<TablePreviews>(
  () => ({})
);

export const tablePreviews$ = persistentReducedStream(
  `tablePreviews$`,
  defaultState,
  reducers
);

const integrationToTablesStreams = new Map<
  IntegrationId,
  Observable<TablePreviews>
>();

export const getTablePreviewsStream = (integrationId: IntegrationId) => {
  const tableStream = integrationToTablesStreams.get(integrationId);

  if (tableStream) {
    return tableStream;
  }

  const stream$ = tablePreviews$.pipe(map(tables => tables[integrationId]));

  integrationToTablesStreams.set(integrationId, stream$);

  return stream$;
};
