import { Button, ButtonSize, ButtonType, GhostButton } from '@ardoq/button';
import { s16, s8 } from '@ardoq/design-tokens';
import { AnimatedChevron } from '@ardoq/icons';
import { StatusType, Tag } from '@ardoq/status-ui';
import { Space } from '@ardoq/style-helpers';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { header3 } from '@ardoq/typography';
import { TABLE_MAPPING_DISPLAY_TEXTS } from 'integrations/common/pages/tabularConfigMapping/constants';
import { DryRunStatus } from 'integrations/common/streams/transferState/types';
import { TableId } from 'integrations/common/streams/tabularMappings/types';
import {
  TableMappingType,
  IntegrationWorkspace,
  Complaint,
  TransferEffectSummaries,
} from '@ardoq/api-types/integrations';
import { Maybe } from '@ardoq/common-helpers';
import _ from 'lodash/fp';
import { useState } from 'react';
import styled from 'styled-components';
import { ErrorPanel, WarningPanel } from '../Complaints/Complaints';
import { EffectPanel } from './EffectPanel';

type UnMappedTable = {
  _id: string;
  name: string;
};

type MappedTable = UnMappedTable & {
  _id: string;
  name: string;
  status: DryRunStatus;
  rowRepresentation: TableMappingType;
  rootWorkspace: IntegrationWorkspace;
  targetWorkspace?: IntegrationWorkspace;
  errors: Complaint[];
  warnings: Complaint[];
  effects: TransferEffectSummaries;
};

export type TableData = UnMappedTable | MappedTable;

export type StatusTableProps = {
  tables: TableData[];
  onConfigure: (id: TableId) => void;
  tableHeader: string;
};

export const StatusTable = ({
  tables,
  onConfigure,
  tableHeader,
}: StatusTableProps) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  return (
    <TableContainer $isVertical={true}>
      <TableHeader>{tableHeader}</TableHeader>
      <DatasourceTable
        components={newTableTheme}
        dataSource={tables}
        columns={[
          { title: 'Name', dataIndex: 'name' },
          {
            title: 'Mapped to',
            dataIndex: 'rowRepresentation',
            valueRender: (rowRepresentation?: TableMappingType) =>
              rowRepresentation ? (
                <div>{TABLE_MAPPING_DISPLAY_TEXTS[rowRepresentation]}</div>
              ) : (
                <div style={{ opacity: 0.2 }}>Not Mapped</div>
              ),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            valueRender: DryRunStatusTag,
          },
          {
            headerStyle: {
              width: 250,
            },
            valueRender: (
              _any,
              { _id, status }: { _id: string; status?: DryRunStatus }
            ) => {
              if (!status) {
                return <></>;
              }
              return (
                <GhostButton
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    setExpandedRows(_.xor(expandedRows, [_id]));
                  }}
                >
                  Show Details
                  <AnimatedChevron $isUp={expandedRows.includes(_id)} />
                </GhostButton>
              );
            },
          },
          {
            headerStyle: {
              width: 250,
            },
            cellStyle: {
              textAlign: 'right',
            },
            valueRender: (
              _any,
              { status, _id }: { _id: string; status?: DryRunStatus }
            ) => (
              <Button
                buttonSize={ButtonSize.SMALL}
                buttonType={ButtonType.SECONDARY}
                onClick={() => onConfigure(_id)}
              >
                {status === DryRunStatus.ERROR
                  ? 'Reconfigure data'
                  : 'Configure'}
              </Button>
            ),
          },
        ]}
        expandedRowIds={expandedRows}
        getExpandedRow={(tableData: TableData) => {
          if (isMappedTable(tableData)) {
            const { rowRepresentation, rootWorkspace, targetWorkspace } =
              tableData;
            return (
              <DetailsContainer>
                <EffectPanel
                  mapping={{
                    rowRepresentation,
                    rootWorkspace,
                    targetWorkspace,
                  }}
                  transferEffects={tableData.effects}
                />
                <WarningPanel
                  warnings={tableData.warnings}
                  isCollapsible={false}
                />
                <ErrorPanel errors={tableData.errors} isCollapsible={false} />
              </DetailsContainer>
            );
          }
          return <></>;
        }}
      ></DatasourceTable>
    </TableContainer>
  );
};

const DryRunStatusTag = (status: Maybe<DryRunStatus>) => {
  if (!status) {
    return <></>;
  }
  const [statusType, statusLabel] =
    status === DryRunStatus.SUCCESS
      ? [StatusType.SUCCESS, 'Complete']
      : status === DryRunStatus.WARNING
        ? [StatusType.WARNING, 'Warning']
        : [StatusType.ERROR, 'Error'];
  return <Tag statusType={statusType} label={statusLabel} />;
};

const TableHeader = styled.div`
  ${header3};
  margin-bottom: ${s8};
`;

const TableContainer = styled(Space)`
  padding: ${s16};
  margin-top: ${s16};
`;

const DetailsContainer = styled(Space)`
  padding: ${s8};
  > * {
    flex: 1;
  }
`;

const isMappedTable = (tableData: TableData): tableData is MappedTable => {
  return Boolean('status' in tableData);
};
