import { Maybe } from '@ardoq/common-helpers';
import { TabularMapping } from '../tabularMappings/types';
import {
  isIncomingReferenceColumnMapping,
  isReferenceColumnMapping,
  isRootReferenceColumnMapping,
} from '../tabularMappings/utils';
import { ColumnMapping } from '../transferConfigs/types';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { uniq } from 'lodash/fp';
import { DEFAULT_REFERENCE_TYPE } from './constants';

const getReferenceTypeNames = (workspaceId?: Maybe<string>) => {
  if (!workspaceId) return [DEFAULT_REFERENCE_TYPE];

  const referenceTypes =
    workspaceInterface.getReferenceTypes(workspaceId) ?? {};

  return Object.values(referenceTypes).map(({ name }) => name);
};

export const extractNewReferenceTypes = (
  mappingConfig: TabularMapping,
  selectedColumn: ColumnMapping
) => {
  const newReferenceTypes: Array<string> = Object.values(mappingConfig)
    .map(config => {
      const workspaceId = isIncomingReferenceColumnMapping(selectedColumn)
        ? selectedColumn.targetWorkspace?.id
        : config.rootWorkspace?.id;

      const currentWorkspaceReferenceTypes = getReferenceTypeNames(workspaceId);

      return Object.values(config.mappedColumns)
        .map((mapping: ColumnMapping) => {
          if (
            isReferenceColumnMapping(mapping) ||
            isRootReferenceColumnMapping(mapping)
          ) {
            return mapping.referenceType;
          }

          return null;
        })
        .filter(ExcludeFalsy)
        .filter(type => !currentWorkspaceReferenceTypes.includes(type));
    })
    .flat()
    .sort();

  return uniq(newReferenceTypes);
};
