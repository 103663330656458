import invitations$ from 'streams/invitations/invitations$';
import privileges$ from 'privileges/privileges$';
import roles$ from 'roles/roles$';
import { derivedStream } from '@ardoq/rxbeach';
import { Observable, map } from 'rxjs';
import { InvitationsStreamShape } from 'streams/invitations/types';
import { PrivilegesStreamShape } from 'privileges/types';
import { RolesStreamShape } from 'roles/types';
import invitationForm$, {
  InvitationFormShape,
} from '../streams/invitations/invitationForm$';
import { APIOrganizationUser } from '@ardoq/api-types';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';

export type InviteNewUserStreamShape = InvitationsStreamShape &
  Pick<PrivilegesStreamShape, 'configurablePrivileges'> &
  Pick<RolesStreamShape, 'roles'> & { users: APIOrganizationUser[] } & Pick<
    InvitationFormShape,
    'formState'
  >;

const inviteNewUser$: Observable<InviteNewUserStreamShape> = derivedStream(
  'inviteNewUser$',
  invitations$,
  privileges$,
  roles$,
  invitationForm$,
  orgUsers$
).pipe(
  map(
    ([
      { invitations, alert },
      { configurablePrivileges },
      { roles },
      { formState },
      { users },
    ]) => ({
      invitations,
      alert,
      configurablePrivileges,
      roles,
      formState,
      users,
    })
  )
);

export default inviteNewUser$;
