import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  isActionOfType,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  changeWorkspacesWithNoAccess,
  newReportPropertySelected,
  newWorkspaceSelected,
  reportBuilderSortingWasChanged,
  reportHistoricalDataWasDiscarded,
  reportSaveButtonWasClickedOnExistingReport,
  reportSaveButtonWasClickedOnNewReport,
  reportSaveFailed,
  reportSearchFinished,
  reportSearchAggregatesWasTriggered,
  reportSearchAggregatesFinished,
  reportSearchWasTriggered,
  reportWasSaved,
  resetAllColumnsNames,
} from './actions';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import reportBuilderForm$ from './reportBuilderForm$';
import { reportApi } from '@ardoq/api';
import {
  reportBuilderOperations,
  ReportTemplate,
  UpdatedColumnNames,
  RESETED_COLUMN_CUSTOM_NAME,
} from '@ardoq/report-builder';
import {
  reportBuilderSearch,
  reportBuilderSearchAggregates,
  reportTemplateToApiReport,
  setPermissions,
  showReportHasBeenSavedToast,
  trackSavedReport,
  updatePermissions,
} from '../utils';
import {
  getArdoqErrorMessage,
  isArdoqError,
  Maybe,
} from '@ardoq/common-helpers';
import { APIResourcePermissionAttributes, Report } from '@ardoq/api-types';
import { openReportInBuilder } from '../../components/AppMainSidebar/utils';
import {
  isColumnWithCustomLabel,
  ReportEventLocations,
  trackQueryResults,
} from '@ardoq/report-reader';
import { trackEvent } from 'tracking/tracking';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { reportAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/report';
import currentUserPermissionContext$ from '../../streams/currentUserPermissions/currentUserPermissionContext$';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { reportNamespace } from 'streams/reports/actions';
import {
  fetchAllFailed,
  fetchAllRequested,
  fetchAllSucceeded,
} from 'streams/crud/actions';
import { intersection, isEqual } from 'lodash';

const saveReport = routine(
  ofType(reportSaveButtonWasClickedOnNewReport),
  withLatestFrom(reportBuilderForm$),
  map(([_, { reportTemplate }]) => reportTemplate),
  filter(Boolean), // Not really necessary, if there's no reportTemplate, the save button will be disabled
  switchMap(async reportTemplate => {
    const persistedReport = await reportApi.create(
      reportTemplateToApiReport(reportTemplate)
    );
    if (isArdoqError(persistedReport)) {
      dispatchAction(reportSaveFailed(getArdoqErrorMessage(persistedReport)));
      return null;
    }
    return {
      reportTemplate,
      persistedReport: reportBuilderOperations.mergeDateRangeFieldsInReport(
        persistedReport,
        fieldInterface.isDateRangeField
      ),
    };
  }),
  filter(Boolean),
  tap(({ persistedReport }) => dispatchAction(reportWasSaved(persistedReport))),
  switchMap(async ({ reportTemplate, persistedReport }) => {
    const permissions = await setPermissions(
      reportTemplate,
      persistedReport._id
    );
    if (isArdoqError(permissions)) {
      dispatchAction(reportSaveFailed(getArdoqErrorMessage(permissions)));
      return null;
    }
    return persistedReport;
  }),
  filter(Boolean),
  tap(persistedReport => trackSavedReport(true, persistedReport)),
  tap(showReportHasBeenSavedToast),
  tap(() =>
    dispatchActionAndWaitForResponse(
      fetchAllRequested(reportNamespace),
      fetchAllSucceeded,
      fetchAllFailed,
      reportNamespace
    )
  ),
  tap(persistedReport => openReportInBuilder({ reportId: persistedReport._id })) // This is to set the routing correctly on the new report id
);

type UpdateReportAndCarryPermissionsArgs = {
  reportTemplate: ReportTemplate;
  persistedReport: Report;
  shouldKeepHistoricalData: boolean;
  hasSubdivisionsFeature: boolean;
  existingResourcePermissions: Maybe<APIResourcePermissionAttributes>;
};

const updateReportAndCarryData = async ({
  reportTemplate,
  persistedReport,
  shouldKeepHistoricalData,
  existingResourcePermissions,
  hasSubdivisionsFeature,
}: UpdateReportAndCarryPermissionsArgs) => {
  const updatedReport = await reportApi.update(
    {
      ...reportTemplateToApiReport(reportTemplate),
      queryId: persistedReport.queryId,
      _id: persistedReport._id,
      _version: persistedReport._version,
    },
    shouldKeepHistoricalData
  );
  if (isArdoqError(updatedReport)) {
    dispatchAction(reportSaveFailed(getArdoqErrorMessage(updatedReport)));
    return null;
  }
  return {
    updatedReport: reportBuilderOperations.mergeDateRangeFieldsInReport(
      updatedReport,
      fieldInterface.isDateRangeField
    ),
    existingResourcePermissions,
    reportTemplate,
    shouldKeepHistoricalData,
    hasSubdivisionsFeature,
  };
};

const updateReport = routine(
  ofType(reportSaveButtonWasClickedOnExistingReport),
  extractPayload(),
  withLatestFrom(reportBuilderForm$),
  map(
    ([
      { shouldKeepHistoricalData, hasSubdivisionsFeature },
      { reportTemplate, persistedReport, existingResourcePermissions },
    ]) =>
      !(persistedReport && reportTemplate) // this shouldn't be possible. Only for typescript
        ? null
        : {
            existingResourcePermissions,
            reportTemplate,
            persistedReport,
            shouldKeepHistoricalData,
            hasSubdivisionsFeature,
          }
  ),
  filter(Boolean),
  switchMap(updateReportAndCarryData),
  filter(Boolean),
  withLatestFrom(currentUserPermissionContext$),
  tap(
    ([
      {
        existingResourcePermissions,
        reportTemplate,
        updatedReport,
        hasSubdivisionsFeature,
      },
      permissionContext,
    ]) => {
      if (
        !hasSubdivisionsFeature &&
        existingResourcePermissions &&
        reportAccessControlInterface.canAdminReport({
          permissionContext,
          reportId: updatedReport._id,
        })
      ) {
        updatePermissions(
          existingResourcePermissions,
          reportTemplate.userPermissions,
          reportTemplate.groupPermissions
        );
      }
    }
  ),
  map(([updatePayload]) => updatePayload),
  tap(({ updatedReport }) => trackSavedReport(false, updatedReport)),
  tap(({ updatedReport }) => {
    dispatchAction(reportWasSaved(updatedReport));
  }),
  tap(showReportHasBeenSavedToast),
  tap(({ shouldKeepHistoricalData }) => {
    if (!shouldKeepHistoricalData) {
      dispatchAction(reportHistoricalDataWasDiscarded());
    }
  })
);

const reportSearch = routine(
  ofType(reportSearchWasTriggered, reportBuilderSortingWasChanged),
  map(action => ({
    ...action.payload,
    // only trigger aggregates search when reportSearchWasTriggered
    shouldTriggerSearchAggregates: isActionOfType(
      reportSearchWasTriggered,
      action
    ),
  })),
  withLatestFrom(reportBuilderForm$),
  map(
    ([
      { columnKey, order, shouldTriggerSearchAggregates },
      { reportTemplate, persistedReport },
    ]) => ({
      ...reportBuilderOperations.toSearchArgs({
        reportTemplate,
        columnKey,
        order,
        reportId: persistedReport?._id,
      }),
      shouldTriggerSearchAggregates,
    })
  ),
  switchMap(
    async ({
      reportTemplate,
      reportId,
      shouldTriggerSearchAggregates,
      ...searchArgs
    }) => {
      // only existing report has reportId, this is optional
      const response = await reportBuilderSearch(searchArgs, reportId);
      return {
        response,
        reportTemplate,
        reportId,
        shouldTriggerSearchAggregates,
      };
    }
  ),
  tap(
    ({ response, reportTemplate, reportId, shouldTriggerSearchAggregates }) => {
      dispatchAction(reportSearchFinished(response));

      if (!isArdoqError(response)) {
        trackQueryResults(
          trackEvent,
          reportTemplate.datasource,
          ReportEventLocations.REPORT_BUILDER,
          response.totalNumberOfRows
        );

        // only trigger aggregates search if the search was successful
        if (shouldTriggerSearchAggregates) {
          dispatchAction(
            reportSearchAggregatesWasTriggered({
              reportTemplate,
              reportId,
            })
          );
        }
      }
    }
  )
);

const reportSearchAggregates = routine(
  ofType(reportSearchAggregatesWasTriggered),
  extractPayload(),
  map(({ reportTemplate, reportId }) =>
    reportBuilderOperations.toSearchAggregatesArgs({
      reportTemplate,
      reportId,
    })
  ),
  switchMap(
    async ({ reportId, workspaceIds, query, datasource, subdivisions }) =>
      await reportBuilderSearchAggregates(
        { workspaceIds, query, datasource, subdivisions },
        reportId
      )
  ),
  tap(response => {
    dispatchAction(reportSearchAggregatesFinished(response));
  })
);

const updateWorkspacesWithNoAccess = routine(
  ofType(newWorkspaceSelected),
  extractPayload(),
  withLatestFrom(reportBuilderForm$),
  map(([workspaces, { selectedWorkspacesWithNoAccess, reportTemplate }]) => ({
    newWorkspacesWithNoAccess: intersection(
      workspaces,
      selectedWorkspacesWithNoAccess
    ),
    reportTemplate,
    currentWorkspacesWithNoAccess: selectedWorkspacesWithNoAccess,
  })),
  tap(
    ({
      newWorkspacesWithNoAccess,
      reportTemplate,
      currentWorkspacesWithNoAccess,
    }) => {
      dispatchAction(changeWorkspacesWithNoAccess(newWorkspacesWithNoAccess));
      if (
        !newWorkspacesWithNoAccess.length &&
        !isEqual(newWorkspacesWithNoAccess, currentWorkspacesWithNoAccess)
      ) {
        const sort = reportBuilderOperations.getSortKeyAndOrder(reportTemplate);
        dispatchAction(reportSearchWasTriggered(sort));
      }
    }
  )
);

const resetAllReportColumnsNames = routine(
  ofType(resetAllColumnsNames),
  withLatestFrom(reportBuilderForm$),
  tap(([, { reportTemplate }]) => {
    const newUpdatedColumnNames =
      reportTemplate.columns.reduce<UpdatedColumnNames>((acc, column) => {
        if (isColumnWithCustomLabel(column)) {
          acc[reportBuilderOperations.getUpdatedColumnNameKey(column)] =
            RESETED_COLUMN_CUSTOM_NAME;
        }
        return acc;
      }, {});
    dispatchAction(
      newReportPropertySelected({
        updatedColumnNames: newUpdatedColumnNames,
      })
    );
  })
);

export default collectRoutines(
  resetAllReportColumnsNames,
  reportSearch,
  reportSearchAggregates,
  saveReport,
  updateReport,
  updateWorkspacesWithNoAccess
);
