import { ReactNode } from 'react';
import { APIFieldAttributes, ArdoqId } from '@ardoq/api-types';
import { confirmDelete } from '@ardoq/modal';
import { pluralize } from '@ardoq/common-helpers';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';
import { isArdoqError } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';

const { deleteField, getCombinedWorkspaceUsages } = fieldInterface;

// Moved from src/js/ardoq/views/mainApp/rightPane/editors/util.tsx
// Exported only because it came with a test
export const getEntitiesCountLabel = (
  componentCount = 0,
  referenceCount = 0,
  surveyCount = 0
): string | null => {
  if (componentCount + referenceCount + surveyCount === 0) return null;
  const pairs = [
    componentCount > 0 && [
      componentCount,
      pluralize('component', componentCount),
    ],
    referenceCount > 0 && [
      referenceCount,
      pluralize('reference', referenceCount),
    ],
    surveyCount > 0 && [surveyCount, pluralize('survey', surveyCount)],
  ].filter(Boolean) as [string, number][];

  const [lastCount, lastValName] = pairs.splice(-1, 1)[0];
  return [
    pairs.map(pair => pair.join(' ')).join(', '),
    `${lastCount} ${lastValName}`,
  ]
    .filter(Boolean)
    .join(' and ');
};

const getDeleteFieldsMessage = async (
  fieldIds: ArdoqId[]
): Promise<ReactNode> => {
  const workspaceUsage = await getCombinedWorkspaceUsages(fieldIds);
  if (isArdoqError(workspaceUsage)) {
    logError(workspaceUsage);
    return 'An error occurred while fetching usage information.';
  }
  const countLabel = getEntitiesCountLabel(
    workspaceUsage.componentCount,
    workspaceUsage.referenceCount,
    workspaceUsage.surveyCount
  );

  return (
    <>
      {countLabel && (
        <p>
          This {pluralize('field', fieldIds.length)} is used by {countLabel}.
        </p>
      )}
      <p>
        Deleting it will remove the field and clear the value from the
        components in this workspace.
      </p>
      <p>
        <strong>Are you sure?</strong>
      </p>
    </>
  );
};

const deleteFields = (fieldIds: ArdoqId[]) => {
  return Promise.all(
    fieldIds.map(fieldId => {
      filterInterface.removeFormattingFiltersByField(fieldId);
      return deleteField(fieldId);
    })
  );
};

const getDateRangeFieldIds = (fieldId: ArdoqId) => {
  const startField = fieldInterface.get(fieldId);

  if (startField) {
    const endField = fieldInterface.getByName(
      `${dateRangeOperations.toEndDateName(
        dateRangeOperations.extractDateRangeFieldName(startField.name)
      )}`
    );

    if (endField) {
      return [startField._id, endField._id];
    }
  }

  return [];
};

export const deleteFieldWithConfirmation = async (
  field: APIFieldAttributes
) => {
  const fieldIds = isDateRangeFieldType(field.type)
    ? getDateRangeFieldIds(field._id)
    : [field._id];
  const shouldDeleteField = await confirmDelete({
    title: `Delete "${field.label}"`,
    text: await getDeleteFieldsMessage(fieldIds),
    confirmButtonClickId: 'confirm-delete-field',
  });
  if (shouldDeleteField) await deleteFields(fieldIds);
};
