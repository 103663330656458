import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  color: ${colors.grey35};
  a {
    color: ${colors.grey35};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ScenarioModePopover = () => (
  <Layout>
    <div>Search is currently not supported in Scenario mode.</div>
    <br />
    <a href={KnowledgeBaseLink.SCENARIOS} target="_blank noopener noreferrer">
      Check what functionality is available in Scenario mode in this knowledge
      base article.
    </a>
  </Layout>
);
