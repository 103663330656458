import { PerspectivesEditorState } from '../types';
import { clearGroupingTabState } from './groupings/groupsTabReducers';
import { clearFormattingTabState } from './formattingTabReducers';
import { clearFilterTabState } from './filtersTabReducers';

export const clearPerspectiveEditor = (
  state: PerspectivesEditorState
): PerspectivesEditorState => ({
  ...state,
  groupsTab: clearGroupingTabState(state),
  formattingTab: clearFormattingTabState(state),
  filtersTabPlainDataParams: clearFilterTabState(state),
  savedPerspectives: {
    ...state.savedPerspectives,
    selectedSavedPerspectiveState: null,
  },
});
