import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import { getDefaultState } from './utils';

const audiencePreview$ = persistentReducedStream(
  'audiencePreview$',
  getDefaultState(),
  reducers
);

export default audiencePreview$;
