import { defaultState, reducers } from './reducers';
import { persistentReducedStream } from '@ardoq/rxbeach';

const presentationsOverview$ = persistentReducedStream(
  'presentationsOverview$',
  defaultState,
  reducers
);

export default presentationsOverview$;
