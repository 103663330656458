import { PaneWrapper } from '../atoms';
import ScenarioRelatedTopBar from './ScenarioRelatedTopBar';
import ScenarioRelatedNavigator from './navigator/ScenarioRelatedNavigator';
import { scenarioIsInSync$ } from 'scenarioIsInSync$';
import { connect } from '@ardoq/rxbeach';

interface ScenarioRelatedPaneProps {
  toggle: () => void;
  isOpened: boolean;
  isDiffModeActive: boolean;
  isFiltering: boolean;
  isInSync: boolean;
}

const ScenarioRelatedPane = ({
  toggle,
  isOpened,
  isDiffModeActive,
  isFiltering,
  isInSync,
}: ScenarioRelatedPaneProps) => (
  <PaneWrapper data-intercom-target="relatedComponentsPanel">
    <ScenarioRelatedTopBar
      toggle={toggle}
      isOpened={isOpened}
      isDiffModeActive={isDiffModeActive}
      isFiltering={isFiltering}
      isInSync={isInSync}
    />
    {isOpened && <ScenarioRelatedNavigator isInSync={isInSync} />}
  </PaneWrapper>
);

export default connect(ScenarioRelatedPane, scenarioIsInSync$);
