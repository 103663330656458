import {
  collectRoutines,
  routine,
  ofType,
  dispatchAction,
} from '@ardoq/rxbeach';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import {
  discardState,
  resetToDefaultState,
  resetUnsavedState,
} from './actions';
import unsavedState$ from './unsavedState$';
import { UnsavedStateOwner } from './types';

const handleDiscardState = routine(
  ofType(discardState),
  withLatestFrom(unsavedState$),
  map(([, unsavedState]) => {
    return Object.entries(unsavedState).reduce<UnsavedStateOwner[]>(
      (acc, [stateOwner, isUnsaved]) => {
        if (!isUnsaved) return acc;
        return [...acc, stateOwner as UnsavedStateOwner];
      },
      []
    );
  }),
  tap(affectedOwners => {
    affectedOwners.forEach(owner => {
      dispatchAction(resetToDefaultState(owner));
    });
    dispatchAction(resetUnsavedState());
  })
);

export default collectRoutines(handleDiscardState);
