import * as profiling from '@ardoq/profiling';
import { isEqual } from 'lodash';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
} from 'rxjs';
import { tag } from 'rxjs-spy/operators';
import { context$, makeContext } from 'streams/context/context$';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { buildScopeDataForGridEditor } from 'appModelStateEdit/buildScopeData';
import gridEditor$ from '../gridEditor$';
import { gridEditorStateOperations } from '../gridEditorStateOperations';
import { ContextShape } from '@ardoq/data-model';
import { APIScopeData } from '@ardoq/api-types';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';
import { loadedGraphOperations } from '../../traversals/loadedGraphOperations';
import { ScopeDataDependencies } from 'gridEditor2023/types';

const getScopeDataFromDependencies = (
  currentScopeDataDeps: ScopeDataDependencies
): APIScopeData => {
  const { context } = currentScopeDataDeps;

  const transaction = profiling.startTransaction(
    'GridEditor buildScopeData',
    500,
    profiling.Team.CORE
  );

  const workspaceIdsInScope = getScopeDataWorkspaceIds(context);
  const scopeData = buildScopeDataForGridEditor(workspaceIdsInScope);

  profiling.endTransaction(transaction, {
    viewId: 'gridEditor2023',
    metadata: {
      components: scopeData.components.length,
      fields: scopeData.fields.length,
      references: scopeData.references.length,
      workspaces: scopeData.workspaces.length,
    },
  });

  return scopeData;
};

const initialState = {
  context: makeContext(),
  activeScenarioState: { scenarioId: null, isScenarioMode: false },
  loadedGraph: loadedGraphOperations.getEmpty(),
};

/**
 * This stream is responsible for two things:
 * - sending scopeData to the Grid Editor so it has the data it needs.
 * - sending context and scenarioId when they change so the Grid Editor can
 *   reflect the user's selection. Eg. selecting a component in the Navigator
 *   from a loaded workspace.
 *
 * It is rather complex because you could quickly run into a "diamond problem" of
 * observables where two observables depend on the same source and update the
 * same resource. The key to avoid this is to use `pairwise` to compare the
 * previous and current state to accurately determine what has changed and what
 * we need to emit.
 *
 * There are 3 key events to handle:
 * 1. The Grid Editor starts => emit all context and scopeData.
 * 2. App context changes, but scopeData is not affected
 * 3. App context changes, and affects scopeData
 */

// TODO Sindre: look at type for scopeDataDependencies$
const scopeDataDependencies$ = combineLatest({
  context: context$,
  activeScenarioState: activeScenario$,
  // This stream exposes the relevant data in Ardoq Studio.
  loadedGraph: loadedGraph$,
}).pipe(
  // These streams often emit without changes, deep comparison avoids performing
  // expensive work downstream in building scope data.
  distinctUntilChanged(isEqual)
);

const shouldNotifyStandaloneGridEditor$ = gridEditor$.pipe(
  map(
    gridEditor =>
      gridEditorStateOperations.isArdoqFrontRuntime(gridEditor) &&
      gridEditorStateOperations.isAnyGridEditorOpen(gridEditor)
  ),
  distinctUntilChanged()
);

export const gridEditorScopeData$ = combineLatest({
  shouldNotifyStandaloneGridEditor: shouldNotifyStandaloneGridEditor$,
  scopeDataDependencies: scopeDataDependencies$,
}).pipe(
  filter(
    ({ shouldNotifyStandaloneGridEditor }) => shouldNotifyStandaloneGridEditor
  ),
  debounceTime(16),
  map(({ scopeDataDependencies }) => scopeDataDependencies),
  startWith(initialState),
  pairwise(),
  map(([prev, scopeDataDependencies]) => {
    const { context, activeScenarioState } = scopeDataDependencies;

    // 1. Initialization: emit scopeData with context
    if (prev === initialState) {
      const scopeData = getScopeDataFromDependencies(scopeDataDependencies);
      return { context, activeScenarioState, scopeData };
    }

    // 2. Context changed, but did not affect scopeData
    if (isScopeDataDependenciesEqual(prev, scopeDataDependencies)) {
      return { context, activeScenarioState, scopeData: undefined };
    }

    // 3. Context changed and affected scopeData
    const scopeData = getScopeDataFromDependencies(scopeDataDependencies);
    return { context, activeScenarioState, scopeData };
  }),
  shareReplay({ bufferSize: 1, refCount: true }),
  tag('gridEditor2023/gridEditorScopeData$')
);

function isScopeDataDependenciesEqual(
  prev: ScopeDataDependencies,
  current: ScopeDataDependencies
) {
  // TODO Sindre: Reconsider this when working on ARD-25509
  if (current.loadedGraph.isViewpointMode) {
    const prevScopeDataDeps = pickViewpointModeScopeDataDependencies(prev);
    const currentScopeDataDeps =
      pickViewpointModeScopeDataDependencies(current);
    return isEqual(prevScopeDataDeps, currentScopeDataDeps);
  }
  const prevScopeDataDeps = pickScopeDataDependencies(prev);
  const currentScopeDataDeps = pickScopeDataDependencies(current);
  return isEqual(prevScopeDataDeps, currentScopeDataDeps);
}

function pickViewpointModeScopeDataDependencies(data: ScopeDataDependencies) {
  const {
    scopeComponentIds,
    scopeReferenceIds,
    isViewpointMode,
    hierarchyDefinition,
  } = data.loadedGraph;
  return {
    scopeComponentIds,
    scopeReferenceIds,
    isViewpointMode,
    hierarchyDefinition,
  };
}

/**
 * Pick the properties that indicate whether or not scopeData has changed.
 *
 * Note that we can ignore modelId because scopeData is only affected if the
 * modelId changes to a newly selected workspace, in which case the workspacesIds
 * would include the new workspaceId.
 */
function pickScopeDataDependencies(data: ScopeDataDependencies) {
  const { context, activeScenarioState } = data;
  return {
    connectedWorkspaceIds: context.connectedWorkspaceIds,
    scenarioId:
      activeScenarioOperations.getActiveScenarioId(activeScenarioState) ??
      undefined,
    workspacesIds: context.workspacesIds, // includes workspaceId
  };
}

const getScopeDataWorkspaceIds = ({
  workspacesIds = [],
  connectedWorkspaceIds,
}: ContextShape) => {
  const workspaceIdsSet = new Set([
    ...workspacesIds, // loaded workspaces, inc workspaceId
    ...connectedWorkspaceIds, // workspaces connected by references
  ]);
  return Array.from(workspaceIdsSet);
};
