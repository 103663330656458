import { Maybe } from '@ardoq/common-helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateFields } from 'integrations/ITPedia/streams/configurationState/actions';
import { configurationState$ } from 'integrations/ITPedia/streams/configurationState/configurationState$';
import { getFields } from 'integrations/ITPedia/streams/fields/fields$';
import { ComponentType } from 'integrations/ITPedia/streams/types';
import { map, switchMap } from 'rxjs';
import { ChangedField } from './types';
import { getUpdatedField } from './utils';

export const viewModel$ = configurationState$.pipe(
  switchMap(config => {
    return getFields(config.workspaces.products.id || '');
  }),
  map(fields => {
    const onChange = (
      componentType: string,
      fieldName: keyof ComponentType['fields'],
      field: Maybe<ChangedField>
    ) => {
      if (!field) {
        return;
      }
      const updatedField = getUpdatedField(
        fields,
        field,
        componentType,
        fieldName
      );
      dispatchAction(updateFields(updatedField));
    };
    return {
      onChange,
      ...fields,
    };
  })
);
