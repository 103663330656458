import { ComponentType, useState } from 'react';
import { calculateVirtualWindowParams } from './utils';
import { ByIdItem, DataSourceItem } from './types';
import { VirtualWindowItem } from './types';

interface VirtualWindowProps {
  byId: Record<string, ByIdItem>;
  dataSource: DataSourceItem[];
  height: number;
  rowHeight: number;
  children: ComponentType<VirtualWindowItem<any, any>>;
}

/*
 * Renders only what is actually visual within the window
 * The item will get injected with these props, see the type VirtualWindowItem:
 * - data, props from dataSource item and from byId record
 * - dataSource, the full list of data
 * - height, the rowHeight specified on the VirtualWindow
 */
const VirtualWindow = ({
  byId,
  dataSource,
  height,
  rowHeight,
  children: Item,
}: VirtualWindowProps) => {
  const [scrollTop, setScrolltop] = useState(0);
  const { innerTop, innerHeight, partialDataSource } =
    calculateVirtualWindowParams({
      dataSource,
      rowHeight,
      scrollTop,
      height,
      prerender: true,
    });

  return (
    <div
      onScroll={e => setScrolltop(e.currentTarget.scrollTop)}
      style={{
        overflowY: 'auto',
        width: '100%',
        height,
      }}
    >
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          top: innerTop,
          height: innerHeight,
        }}
      >
        {(partialDataSource || []).map(props => (
          <Item
            key={props.id}
            id={props.id}
            dataSource={dataSource}
            data={{ ...byId?.[props.id], ...props }}
            height={rowHeight}
          />
        ))}
      </div>
    </div>
  );
};

export default VirtualWindow;
