import { Checkbox, FormSize, TextInput } from '@ardoq/forms';
import { IconName, WorkspaceIcon } from '@ardoq/icons';
import { SubdivisionProps } from 'subdivisionEditor/types';
import styled from 'styled-components';
import { text1 } from '@ardoq/typography';
import { Breadcrumbs } from '@ardoq/dropdown-menu-ui';
import { DecoratedWorkspace } from './types';
import { InfoNotification } from '@ardoq/status-ui';
import { Box, FlexBox, Stack } from '@ardoq/layout';

const PrimaryText = styled.div`
  ${text1};
`;

const WorkspaceRow = ({
  workspace,
  onCheckBoxChange,
  selected,
  isBoundToSubdivision,
}: {
  workspace: DecoratedWorkspace;
  selected: boolean;
  isBoundToSubdivision: boolean;
  onCheckBoxChange: (workspaceId: string, isSelected: boolean) => void;
}) => {
  return (
    <FlexBox padding="small" gap="small" align="center" flex={1}>
      <Checkbox
        isChecked={isBoundToSubdivision || selected}
        isDisabled={isBoundToSubdivision}
        onChange={() => {
          onCheckBoxChange(workspace._id, selected);
        }}
      />
      <WorkspaceIcon />
      <Stack gap="xsmall" flex={1}>
        <PrimaryText>{workspace.name}</PrimaryText>
        {workspace.dirPath.length > 0 && (
          <Box width="full">
            <Breadcrumbs
              breadcrumbs={(workspace.dirPath || []).map(r => ({
                text: r,
                icon: IconName.FOLDER,
              }))}
            ></Breadcrumbs>
          </Box>
        )}
      </Stack>
    </FlexBox>
  );
};

const BindWorkspaces = ({
  bindWorkspacesState,
  bindWorkspacesCommands,
}: SubdivisionProps) => {
  const { searchValue, workspaces, workspacesBoundToSubdivisionIds } =
    bindWorkspacesState;
  return (
    <Stack gap="medium">
      <InfoNotification>
        <div>
          To add specific components, open a workspace and add them individually
          after creating the subdivision.
        </div>
        <div>
          <strong>Coming soon: </strong>
          Add specific components directly from this page.
        </div>
      </InfoNotification>
      <FlexBox paddingBottom="medium">
        <TextInput
          label="Filter workspaces"
          leftIconName={IconName.SEARCH}
          onClear={() => {
            bindWorkspacesCommands.clearSearchValue();
          }}
          onValueChange={bindWorkspacesCommands.searchValueChanged}
          formSize={FormSize.DEFAULT}
          value={searchValue}
        />
      </FlexBox>
      <Stack gap="medium">
        {workspaces.map(workspace => (
          <WorkspaceRow
            key={workspace._id}
            workspace={workspace}
            selected={bindWorkspacesState.selectedWorkspaces.includes(
              workspace._id
            )}
            isBoundToSubdivision={workspacesBoundToSubdivisionIds.includes(
              workspace._id
            )}
            onCheckBoxChange={bindWorkspacesCommands.toggleWorkspaceSelection}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default BindWorkspaces;
