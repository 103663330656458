import { openCopyComponentModal } from 'dialog/utilsDialog';
import { Fragment } from 'react';
import { showSupport } from 'utils/support';
import { ArdoqError, getArdoqErrorMessage } from '@ardoq/common-helpers';

const ErrorDetails = ({ message }: { message: string }) => (
  <p>{`An unexpected error
    occurred, ${message}`}</p>
);
const RefreshMessage = () => (
  <p>
    Please try to refresh your browser and try again or{' '}
    <a
      href="#"
      onClick={e => {
        showSupport();
        e.preventDefault();
      }}
    >
      contact support
    </a>{' '}
    if the problem persists.
  </p>
);

export const handleError = (response: ArdoqError) => {
  openCopyComponentModal(
    "Couldn't copy component",
    <Fragment>
      <ErrorDetails message={getArdoqErrorMessage(response)} />
      <RefreshMessage />
    </Fragment>
  );
};
