import styled from 'styled-components';
import DynamicFilterCheckbox, {
  shouldBeChecked,
} from './DynamicFilterCheckbox';
import DynamicFilterLegendEntryParameterization from './DynamicFilterLegendEntryParameterization';
import { DynamicFilterState, ParameterOption } from '@ardoq/data-model';

const Wrapper = styled.div`
  min-height: 30px;
`;

interface DynamicFilterLegendEntryProps {
  id: string;
  name: string;
  supportedParams: string[];
  filterState: DynamicFilterState;
  parameterQueryId: string | null;
  parameterOptions: ParameterOption[];
  toggleDynamicFilterActive: (
    filterId: string,
    previouslyChecked: boolean
  ) => void;
  selectDynamicFilterOption: (
    filterId: string,
    paramName: string,
    paramValue: string
  ) => void;
  fetchDynamicFilterOptions: (parameterQueryId: string) => void;
  isDisabled?: boolean;
}

const DynamicFilterLegendEntry = ({
  id,
  name,
  supportedParams,
  filterState,
  parameterQueryId,
  parameterOptions,
  toggleDynamicFilterActive,
  selectDynamicFilterOption,
  fetchDynamicFilterOptions,
  isDisabled,
}: DynamicFilterLegendEntryProps) => {
  const isParameterized = Boolean(supportedParams.length) && parameterQueryId;

  return (
    <Wrapper>
      <DynamicFilterCheckbox
        state={filterState.state}
        error={filterState.error}
        name="dynamic-filter-check"
        label={name}
        isDisabled={isDisabled}
        onChange={() =>
          toggleDynamicFilterActive(id, shouldBeChecked(filterState.state))
        }
      >
        {isParameterized && (
          <DynamicFilterLegendEntryParameterization
            id={id}
            supportedParams={supportedParams}
            filterState={filterState}
            parameterQueryId={parameterQueryId}
            parameterOptions={parameterOptions}
            selectDynamicFilterOption={selectDynamicFilterOption}
            fetchDynamicFilterOptions={fetchDynamicFilterOptions}
          />
        )}
      </DynamicFilterCheckbox>
    </Wrapper>
  );
};

export default DynamicFilterLegendEntry;
