import { dispatchAction } from '@ardoq/rxbeach';
import {
  SetLoadedComponentsPayload,
  setComponentName,
  setComponentTypeName,
  setLoadedComponents,
  setAllComponentsSelected,
  toggleSingleComponentSelection,
  setStartQuery,
  setIsAdvancedSearch,
  UpdateViewpointBuilderAdvancedSearchQueryPayload,
  updateViewpointBuilderAdvancedSearchQuery,
  setStartSetFilter,
  setSelectedComponents,
} from './actions';
import { QueryBuilderQuery, QueryBuilderSubquery } from '@ardoq/api-types';
import { resetAllSearchState } from 'search/actions';
import { SelectedComponent } from './types';

export type ComponentsSearchCommands = {
  setComponentName: (componentName: string) => void;
  setComponentTypeName: (componentType: string) => void;
  setAllComponentsSelected: (selected: boolean) => void;
  setTraversalStartQuery: (query: QueryBuilderQuery | null) => void;
  toggleSingleComponentSelection: (componentId: string) => void;
  setLoadedComponents: (payload: SetLoadedComponentsPayload) => void;
  setSelectedComponents: (payload: SelectedComponent[]) => void;
  setIsAdvancedSearch: (isAdvancedSearch: boolean) => void;
  viewpointBuilderAdvancedSearchQueryUpdated: (
    payload: UpdateViewpointBuilderAdvancedSearchQueryPayload
  ) => void;
  setStartSetFilter: (filter: QueryBuilderSubquery | null) => void;
};
export const componentsSearchCommands: ComponentsSearchCommands = {
  setComponentName: (componentName: string) =>
    dispatchAction(setComponentName(componentName)),
  setComponentTypeName: (componentType: string) =>
    dispatchAction(setComponentTypeName(componentType)),
  setAllComponentsSelected: (selected: boolean) =>
    dispatchAction(setAllComponentsSelected(selected)),
  toggleSingleComponentSelection: (componentId: string) =>
    dispatchAction(toggleSingleComponentSelection(componentId)),
  setLoadedComponents: (payload: SetLoadedComponentsPayload) =>
    dispatchAction(setLoadedComponents(payload)),
  setSelectedComponents: (selectedComponents: SelectedComponent[]) =>
    dispatchAction(setSelectedComponents(selectedComponents)),
  setTraversalStartQuery: (query: QueryBuilderQuery | null) =>
    dispatchAction(setStartQuery(query)),
  setStartSetFilter: (filter: QueryBuilderSubquery | null) =>
    dispatchAction(setStartSetFilter(filter)),
  setIsAdvancedSearch: (isAdvancedSearch: boolean) => {
    // resetAllSearchState clears both regular search and advanced search
    dispatchAction(resetAllSearchState());
    dispatchAction(setIsAdvancedSearch(isAdvancedSearch));
  },
  viewpointBuilderAdvancedSearchQueryUpdated: (
    payload: UpdateViewpointBuilderAdvancedSearchQueryPayload
  ) => {
    dispatchAction(updateViewpointBuilderAdvancedSearchQuery(payload));
  },
};
