import { reducedStream } from '@ardoq/rxbeach';
import { combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import {
  SubdivisionProps,
  SubdivisionViewModelState,
} from 'subdivisionEditor/types';
import { reducers } from './reducers';
import { subdivisionEditorCommands } from './commands';

import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { SubdivisionsStreamShape } from 'streams/subdivisions/types';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import {
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';
import { Features, hasFeature } from '@ardoq/features';
import { subdivisionsOperations } from '@ardoq/subdivisions';

import {
  AdvancedSearchFieldsHashState,
  advancedSearchFieldsHash$,
} from 'streams/fields/advancedSearchFields$';
import { selectComponentsCommands } from 'subdivisionEditor/Steps/SelectComponents/selectComponentsCommands';
import { bindWorkspacesCommands } from 'subdivisionEditor/Steps/BindWorkspaces/commands';

const _subdivisionEditorViewModel$ = reducedStream<SubdivisionViewModelState>(
  'subdivisionEditorViewModel$',
  subdivisionEditorOperations.getInitialSubdivisionEditorState(),
  reducers
);

export const subdivisionEditorViewModel$ = combineLatest([
  _subdivisionEditorViewModel$,
  subdivisions$,

  currentUserPermissionContext$,
  advancedSearchFieldsHash$,
]).pipe(
  map<
    [
      SubdivisionViewModelState,
      SubdivisionsStreamShape,
      PermissionContext,
      AdvancedSearchFieldsHashState,
    ],
    SubdivisionProps
  >(([viewModel, subdivisionsState, permissionContext, fieldsHash]) => {
    const subdivisionEditorSubStep = viewModel.subdivisionEditorSubStep;

    return {
      ...viewModel,
      componentsSelection: {
        ...viewModel.componentsSelection,
        advancedSearchState: {
          ...viewModel.componentsSelection.advancedSearchState,
          ruleErrorMessages:
            subdivisionEditorOperations.getAdvancedSearchRuleErrorMessages(
              viewModel,
              fieldsHash
            ),
        },
      },
      subdivisionEditorSubStep,
      subdivisions: subdivisionsState.subdivisions,
      hasNewCoreJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
      isFetchingSubdivisions: subdivisionsState.isFetching,
      commands: subdivisionEditorCommands,
      selectComponentsCommands: selectComponentsCommands,
      bindWorkspacesCommands: bindWorkspacesCommands,
      isOrgAdmin:
        !!permissionContext.user &&
        permissionsOperations.isOrgAdmin(permissionContext.user),
    };
  })
);

export const subdivisionEditorSubdivisionChanged$ =
  subdivisionEditorViewModel$.pipe(
    filter(data =>
      subdivisionsOperations.isPersistedSubdivision(data.subdivision)
    ),
    distinctUntilChanged((prev, next) => {
      return (
        prev.subdivisionId === next.subdivisionId &&
        subdivisionsOperations.isPersistedSubdivision(prev.subdivision) &&
        subdivisionsOperations.isPersistedSubdivision(next.subdivision) &&
        prev.subdivision._id === next.subdivision._id
      );
    })
  );
