import {
  ReportBuilderDataSelectionSectionProps,
  reportBuilderOperations,
  ReportBuilderSectionErrorProps,
  ReportTemplate,
} from '@ardoq/report-builder';
import { ArdoqId, Report, Workspace, Zone } from '@ardoq/api-types';
import { Maybe } from '@ardoq/common-helpers';
import { SubdivisionsStreamShape } from 'streams/subdivisions/types';
import {
  subdivisionsOperations,
  subdivisionsZonesOperations,
} from '@ardoq/subdivisions';
import { PermissionContext } from '@ardoq/access-control';
import { CircleIcon } from '@ardoq/icons';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { SelectOption } from '@ardoq/select';
import { StatusType } from '@ardoq/status-ui';

const ERROR_MESSAGE_MISSING_WORKSPACE_FOR_MIGRATED_REPORT =
  'Select workspaces for migrated reports';
const ERROR_MESSAGE_MISSING_WORKSPACE_FOR_NEW_REPORT =
  'Select workspaces to build your report';
const ERROR_MESSAGE_MISSING_WORKSPACE_FOR_QUERY = `Select workspaces in order to search`;
const ERROR_MESSAGE_MISSING_SUBDIVISIONS_FOR_NEW_REPORT =
  'Select divisions or workspaces to build your report';
const ERROR_MESSAGE_MISSING_SUBDIVISIONS_FOR_QUERY = `Select divisions or workspaces in order to search`;
const ERROR_MESSAGE_MISSING_ACCESS_TO_WORKSPACES = `You don't have access to one of workspaces used in report. Please remove them to continue`;

const getHasUserSelectedAllAvailableData = (
  selectedZonesIds: ArdoqId[],
  selectedWorkspaceIds: ArdoqId[],
  availableZones: Zone[],
  availableWorkspaceIds: ArdoqId[]
) => {
  const selectedZoneIdsSet = new Set(selectedZonesIds);
  const selectedWorkspaceIdsSet = new Set(selectedWorkspaceIds);
  return (
    availableZones.every(zone => selectedZoneIdsSet.has(zone._id)) &&
    availableWorkspaceIds.every(workspaceId =>
      selectedWorkspaceIdsSet.has(workspaceId)
    )
  );
};

export const getDataSelectionSectionProps = ({
  reportTemplate,
  workspacesById,
  persistedReport,
  showErrorsForInvalidQuery,
  showErrorsOnInputs,
  subdivisionsContext,
  hasSubdivisionsFeature,
  permissionContext,
  isNewFeatureNotificationVisible,
  selectedWorkspacesWithNoAccess,
  hasWorkspacesWithNoAccess,
}: {
  reportTemplate: ReportTemplate;
  persistedReport: Maybe<Report>;
  workspacesById: Record<ArdoqId, Workspace>;
  subdivisionsContext: SubdivisionsStreamShape;
  hasSubdivisionsFeature: boolean;
  permissionContext: PermissionContext;
  isNewFeatureNotificationVisible: boolean;
  selectedWorkspacesWithNoAccess: ArdoqId[];
  hasWorkspacesWithNoAccess: boolean;
} & ReportBuilderSectionErrorProps): ReportBuilderDataSelectionSectionProps => {
  const hasError =
    (!reportBuilderOperations.isValidDataSelection(reportTemplate) &&
      (showErrorsOnInputs || showErrorsForInvalidQuery)) ||
    hasWorkspacesWithNoAccess;

  const showErrorForMissingWorkspace =
    !reportBuilderOperations.isWorkspacesValid(reportTemplate) && hasError;

  const showErrorForMissingSubdivision =
    !reportBuilderOperations.isSubdivisionsValid(reportTemplate) && hasError;

  const workspaceErrorMessage = hasWorkspacesWithNoAccess
    ? ERROR_MESSAGE_MISSING_ACCESS_TO_WORKSPACES
    : showErrorForMissingWorkspace
      ? persistedReport?.createdDuringMigration
        ? ERROR_MESSAGE_MISSING_WORKSPACE_FOR_MIGRATED_REPORT
        : showErrorsForInvalidQuery
          ? ERROR_MESSAGE_MISSING_WORKSPACE_FOR_QUERY
          : ERROR_MESSAGE_MISSING_WORKSPACE_FOR_NEW_REPORT
      : undefined;

  const subdivisionsErrorMessage =
    showErrorForMissingSubdivision && !hasWorkspacesWithNoAccess
      ? showErrorsForInvalidQuery
        ? ERROR_MESSAGE_MISSING_SUBDIVISIONS_FOR_QUERY
        : ERROR_MESSAGE_MISSING_SUBDIVISIONS_FOR_NEW_REPORT
      : undefined;

  const availableZones = subdivisionsZonesOperations.getZonesFromPermissions(
    subdivisionsContext,
    permissionContext
  );

  const subdivisionsOptions = availableZones.map(zone => {
    const sub = subdivisionsOperations.getSubdivisionById(
      subdivisionsContext,
      zone.subId
    );
    const color = zone.style?.color || sub?.style?.color;
    return {
      value: zone._id,
      label: `${sub?.name ?? ''} • [${zone.name}]`,
      color,
      leftContent: <CircleIcon color={color} />,
    };
  });

  const workspaceOptions = Object.entries(workspacesById)
    .filter(([id, _workspace]) =>
      workspaceAccessControlInterface.canAccessWorkspace(permissionContext, id)
    )
    .map<SelectOption<ArdoqId>>(([id, workspace]) => ({
      value: id,
      label: workspace.name,
    }))
    .concat(
      selectedWorkspacesWithNoAccess.map(wsId => ({
        value: wsId,
        label: 'Missing access to workspace',
        statusType: StatusType.ERROR,
      }))
    );

  const selectedZoneIds =
    reportBuilderOperations.getZoneIdsFromReportSubdivisions(reportTemplate);

  return {
    isGremlinSearchReport:
      reportBuilderOperations.isGremlinSearchBased(reportTemplate),
    hasError,
    workspaceErrorMessage,
    subdivisionsErrorMessage,
    showErrorsForInvalidQuery,
    selectedWorkspaceIds: reportTemplate.workspaceIds,
    workspaceOptions,
    selectedZoneIds,
    subdivisionsOptions,
    hasSubdivisionsFeature,
    hasSelectedAllData: getHasUserSelectedAllAvailableData(
      selectedZoneIds,
      reportTemplate.workspaceIds,
      availableZones,
      workspaceOptions.map(({ value }) => value)
    ),
    isNewFeatureNotificationVisible,
    hasWorkspacesWithNoAccess,
  };
};
