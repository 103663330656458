import { ExternalDocumentTargetType } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { RequiredAuth } from './types';
import { LucidSlideState } from 'presentation/viewPane/lucidSlide$';

type OpenDocumentPicker = RequiredAuth & {
  componentId: string;
};

export const openDocumentPicker = actionCreator<OpenDocumentPicker>(
  '[Lucidchart] OPEN_DOCUMENT_PICKER'
);

type ExportToLucidChartPayload = RequiredAuth & {
  isLegendActive: boolean;
};

export const exportToLucidchart = actionCreator<ExportToLucidChartPayload>(
  '[Lucidchart] EXPORT_TO_LUCIDCHART'
);

type OpenEmbedViewer = RequiredAuth & {
  documentId: string;
  title: string;
};

export const openEmbedViewer = actionCreator<OpenEmbedViewer>(
  '[Lucidchart] OPEN_EMBED_VIEWER'
);

export const setLucidSlideState = actionCreator<LucidSlideState>(
  '[Lucidchart] SET_LUCID_SLIDE_STATE'
);

type LinkDocument = {
  documentId: string;
  targetId: string;
  targetType: ExternalDocumentTargetType;
  onComplete?: VoidFunction;
};

export const linkDocument = actionCreator<LinkDocument>(
  '[Lucidchart] LINK_DOCUMENT'
);
