import { ButtonType } from '@ardoq/button';
import predefinedAudienceDialog$ from './predefinedAudienceDialog$';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { Checkbox } from '@ardoq/forms';
import { CheckboxIconName } from '@ardoq/icons';
import { togglePredefinedAudienceQueryCheckbox } from './actions';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { PredefinedQueryOption } from '@ardoq/api-types';
import { MAX_VISIBLE_QUERY_OPTIONS } from 'broadcasts/consts';
import { optionsIncludeOption } from './utils';
import { colors } from '@ardoq/design-tokens';
import { FlexBox } from '@ardoq/layout';

type PredefinedAudienceQueryCheckboxSectionProps = {
  queryOptions: PredefinedQueryOption[];
  selectedQueryOptions: PredefinedQueryOption[];
};

const PredefinedAudienceQueryCheckboxSection = ({
  queryOptions,
  selectedQueryOptions,
}: PredefinedAudienceQueryCheckboxSectionProps) => {
  const visibleQueryOptions = queryOptions.slice(0, MAX_VISIBLE_QUERY_OPTIONS);
  const moreQueryOptions = queryOptions.slice(MAX_VISIBLE_QUERY_OPTIONS);
  return (
    <FlexBox>
      <FlexBox gap="medium" flexWrap>
        {visibleQueryOptions.map(option => (
          <Checkbox
            key={option.value}
            isChecked={optionsIncludeOption(selectedQueryOptions, option)}
            onChange={() => {
              dispatchAction(togglePredefinedAudienceQueryCheckbox(option));
            }}
          >
            {option.label}
          </Checkbox>
        ))}
      </FlexBox>
      {moreQueryOptions.length > 0 && (
        <ButtonWithDropdown
          disablePortal
          iconStyle={{ color: colors.grey25 }}
          isKeepOpen
          options={moreQueryOptions.map(option => ({
            iconName: optionsIncludeOption(selectedQueryOptions, option)
              ? CheckboxIconName.CHECKED
              : CheckboxIconName.UNCHECKED,
            label: option.label,
            onClick: () => {
              dispatchAction(togglePredefinedAudienceQueryCheckbox(option));
            },
          }))}
          buttonType={ButtonType.GHOST}
        >
          More
        </ButtonWithDropdown>
      )}
    </FlexBox>
  );
};

export default connect(
  PredefinedAudienceQueryCheckboxSection,
  predefinedAudienceDialog$
);
