import { SecondaryButton, GhostButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { SidebarFooter } from '../atoms';
import { Features, hasFeature } from '@ardoq/features';
import { LoadedStateCommands } from '../commands';
import { Stack } from '@ardoq/layout';

type ViewpointSidebarFooterProps = {
  commands: LoadedStateCommands;
};
const ViewpointSidebarFooter = ({ commands }: ViewpointSidebarFooterProps) => {
  return (
    <SidebarFooter>
      <Stack gap="small">
        {hasFeature(Features.SUPPORT_LARGE_DATASETS) ? (
          <SecondaryButton onClick={() => commands.openMoreData()} isFlexible>
            <Icon iconName={IconName.ADD} />
            Open more data
          </SecondaryButton>
        ) : null}

        <GhostButton isFlexible onClick={() => commands.closeAllDatasets()}>
          <Icon iconName={IconName.CLOSE} />
          Close all data
        </GhostButton>
      </Stack>
    </SidebarFooter>
  );
};

export default ViewpointSidebarFooter;
