import { useLayoutEffect } from 'react';
import MaxRepetitionForm from './MaxRepetitionForm';
import {
  ButtonGroup,
  ButtonSize,
  IconButton,
  GhostButton,
} from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { createEmptyConstraint } from 'viewpoints/utils';
import { PartialStep, TripleValidationMap, Validation } from '../../types';
import { s16, s32 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import Constraint from './Constraint';
import { header4 } from '@ardoq/typography';
import { Triple } from '@ardoq/api-types';

const Header = styled.div`
  display: flex;
  gap: ${s16};
  margin: 0 0 ${s32} 10px;
`;

const Label = styled.label`
  ${header4};
  flex-grow: 1;
  margin: 0;
`;

type StepFormProps = {
  tripleValidationMap: TripleValidationMap;
  validate: (index: number) => Validation | undefined;
  canDelete: boolean;
  index: number;
  step: PartialStep;
  onChange: (step: PartialStep) => void;
  availableTriples: Triple[];
  onDelete: VoidFunction;
  isDisabled: boolean;
};

const StepForm = ({
  tripleValidationMap,
  canDelete,
  validate,
  index,
  step,
  onChange,
  availableTriples,
  onDelete,
  isDisabled,
}: StepFormProps) => {
  useLayoutEffect(() => {
    if (!step.constraints.length) {
      onDelete();
    }
  }, [onDelete, step.constraints]);

  const handleTripleValidate =
    (constraintIndex: number) => (tripleIndex: number) =>
      tripleValidationMap.get([index, constraintIndex, tripleIndex].join());

  return (
    <>
      <Header>
        <Label>Step {index + 1}</Label>
        <IconButton
          dataTestId="delete-step-button"
          onClick={onDelete}
          buttonSize={ButtonSize.SMALL}
          iconName={IconName.CLOSE}
          data-tooltip-text="Delete step"
          isDisabled={isDisabled}
        />
      </Header>
      {step.constraints.map((constraint, constraintIndex) => {
        const validation = validate(constraintIndex);
        return (
          <Constraint
            key={constraintIndex}
            index={constraintIndex}
            validation={validation}
            canDelete={canDelete}
            availableTriples={availableTriples}
            step={step}
            constraint={constraint}
            onChange={onChange}
            handleTripleValidate={handleTripleValidate}
            isDisabled={isDisabled}
          />
        );
      })}
      <MaxRepetitionForm
        step={step}
        onChange={onChange}
        index={index}
        isDisabled={isDisabled}
      />
      <ButtonGroup style={{ marginTop: s32 }}>
        <GhostButton
          dataTestId="add-constraint-button"
          onClick={() =>
            onChange({
              ...step,
              constraints: [
                ...step.constraints,
                createEmptyConstraint('triple', availableTriples, step),
              ],
            })
          }
          isDisabled={isDisabled}
        >
          <Icon iconName={IconName.ADD} />
          Add triple rules
        </GhostButton>
      </ButtonGroup>
    </>
  );
};

export default StepForm;
