import { GeneralPath, INode, Rect } from '@ardoq/yfiles';
import { GraphNode } from '@ardoq/graph';
import getNodeBounds from 'yfilesExtensions/getNodeBounds';
import {
  CONTEXT_HIGHLIGHT_PADDING,
  CONTEXT_HIGHLIGHT_STROKE,
} from 'yfilesExtensions/styles/consts';
import { NODE_LABEL_FONT } from './consts';
import { isContextNode } from '../../utils';
import getLabelPreferredSize from './getLabelPreferredSize';

/**
 * return the outline of the node within its bounds.
 * this is the point at which the edge will touch the node.
 * the PortAdjustmentPolicy of the graph layout dictates this behavior.
 */
const getNodeOutline = (node: INode) => {
  const isContext = isContextNode(node);
  const nodeBounds = getNodeBounds(node, isContext);
  const result = new GeneralPath(2);
  result.appendRectangle(nodeBounds, false);
  const labelText = (node.tag as GraphNode).getLabelWithCount();
  const { width: labelWidth, height: labelHeight } = getLabelPreferredSize(
    labelText,
    NODE_LABEL_FONT
  );
  const labelRight = nodeBounds.center.x + labelWidth / 2;
  const labelLeft = nodeBounds.center.x - labelWidth / 2;
  const labelVerticalOffset = isContext ? CONTEXT_HIGHLIGHT_PADDING : 0;
  const labelBottomInset = CONTEXT_HIGHLIGHT_STROKE;
  const labelTop = nodeBounds.bottomLeft.y + labelVerticalOffset;
  const labelBounds = new Rect(
    labelLeft,
    labelTop,
    labelRight - labelLeft,
    labelHeight + labelBottomInset
  );
  result.appendRectangle(labelBounds, false);
  return result;
};
export default getNodeOutline;
