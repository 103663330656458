export class TargetManager {
  private _targetClassNames: Map<string, string[]>;
  private _targetQueries: Map<string, string>;
  constructor(config: Record<string, string[]>) {
    this._targetClassNames = new Map();
    this._targetQueries = new Map();
    Object.keys(config).forEach(eventType => {
      const classNames = config[eventType];
      this._targetClassNames.set(eventType, classNames);
      this._targetQueries.set(
        eventType,
        classNames.map(className => `.${className}, #${className}`).join(', ')
      );
    });
  }

  getTarget(event: Event | React.SyntheticEvent, eventType = event.type) {
    const targetQuery = this._targetQueries.get(eventType);
    if (!targetQuery) return {};
    const targetElement = event.target as Element;
    const target =
      targetElement.closest && targetElement.closest<HTMLElement>(targetQuery);
    if (!target) return {};
    const targetClassNames = this._targetClassNames.get(eventType);
    const className = targetClassNames?.find(
      targetClassName =>
        target.classList.contains(targetClassName) ||
        targetClassName === target.id
    );
    return { target, className };
  }
}
