import { SubdivisionProps } from 'subdivisionEditor/types';
import { WorkspaceSelector } from './components/WorkspaceSelector';
import { FormWrapper } from '@ardoq/forms';
import { AddingComponentsModeSelector } from './components/AddingComponentsModeSelector';
import { SearchResultCount } from './components/SearchResultCount';
import { AdvancedSearchBuilder } from './components/AdvancedSearchBuilder';
import { AdvancedSearchResultView } from './components/AdvancedSearchResultView';
import { Stack } from '@ardoq/layout';

export const SelectComponentsPage = (props: SubdivisionProps) => {
  return (
    <>
      <FormWrapper>
        <WorkspaceSelector {...props} />
      </FormWrapper>
      <AddingComponentsModeSelector {...props}></AddingComponentsModeSelector>
      <Stack gap="medium">
        <AdvancedSearchBuilder {...props} />
        <SearchResultCount {...props} />
        <AdvancedSearchResultView {...props} />
      </Stack>
    </>
  );
};
