import { dispatchAction } from '@ardoq/rxbeach';
import {
  setSelectedView as setSelectedViewAction,
  resetSelectedView as resetSelectedViewAction,
} from './actions';
import { SelectedViewOption } from './types';

const setSelectedView = (viewId: SelectedViewOption) => {
  dispatchAction(setSelectedViewAction(viewId));
};
const resetSelectedView = () => {
  dispatchAction(resetSelectedViewAction());
};

export const selectedViewCommands = {
  setSelectedView,
  resetSelectedView,
};

export type SelectedViewCommands = typeof selectedViewCommands;
