import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId, AssetPayload, Entity } from '@ardoq/api-types';

/** Used for CRUD routines */
export const workspaceFolderNamespace = 'workspacefolder';

export type MoveAssetsPayload = {
  folderId: ArdoqId | null;
  movedAssets: AssetPayload[];
};

export const moveAssets = actionCreator<MoveAssetsPayload>(
  '[assetFolders] MOVE_ASSETS'
);

export const moveAssetsSuccess = actionCreator(
  '[assetFolders] MOVE_ASSETS_SUCCESS'
);

export const moveAssetsError = actionCreator(
  '[assetFolders] MOVE_ASSETS_ERROR'
);

type CreatePayload = {
  name: string;
};

export const createAssetFolder = actionCreator<CreatePayload>(
  '[assetFolders] CREATE'
);

export const createAssetFolderSucceeded = actionCreator<Entity>(
  '[assetFolders] CREATE_SUCCEEDED'
);

export const createAssetFolderFailed = actionCreator(
  '[assetFolders] CREATE_FAILED'
);
