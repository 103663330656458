import { actionCreator } from '@ardoq/rxbeach';
import { DeletedComponent } from './types';

interface RestoreComponent {
  readonly component: DeletedComponent;
}
export const restoreComponent = actionCreator<RestoreComponent>(
  '[component] RESTORE_COMPONENT'
);

interface RestoreComponentSuccess {
  readonly component: DeletedComponent;
}
export const restoreComponentSuccess = actionCreator<RestoreComponentSuccess>(
  '[component] RESTORE_COMPONENT_SUCCESS'
);

interface RestoreComponentError {
  readonly component: DeletedComponent;
}
export const restoreComponentError = actionCreator<RestoreComponentError>(
  '[component] RESTORE_COMPONENT_ERROR'
);
