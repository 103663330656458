import { SidebarMenu, SidebarMenuCard } from '@ardoq/sidebar-menu';
import manageReferenceTypes$ from 'appModelStateEdit/manageReferenceTypes/manageReferenceTypes$';
import { ManageReferenceTypesStreamShape } from 'appModelStateEdit/manageReferenceTypes/types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  addReferenceType,
  confirmDeleteReferenceType,
  editReferenceType,
} from 'appModelStateEdit/manageReferenceTypes/actions';
import { ReferenceTypePreview as ReferenceTypePreviewSVG } from '@ardoq/renderers';
import { hideRightPane } from 'appContainer/actions';

const ManageReferenceTypes = ({
  canManageReferenceTypes,
  referenceTypeCards,
}: ManageReferenceTypesStreamShape) => {
  return (
    <SidebarMenu
      headerTitle="Manage reference types"
      onCloseClick={() => dispatchAction(hideRightPane())}
      {...(canManageReferenceTypes
        ? {
            primaryButtonLabel: 'Add reference type',
            onPrimaryButtonClick: () => dispatchAction(addReferenceType()),
            primaryButtonClickId: 'sidebar-action-add-reference-type',
          }
        : {})}
    >
      {referenceTypeCards.map(referenceTypeCard => {
        return (
          <SidebarMenuCard
            key={referenceTypeCard.referenceTypeId}
            name={referenceTypeCard.referenceTypeName}
            graphicContent={
              <ReferenceTypePreviewSVG
                width={65}
                height={24}
                line={referenceTypeCard.referenceTypeData.line}
                color={referenceTypeCard.referenceTypeData.color}
                lineBeginning={
                  referenceTypeCard.referenceTypeData.lineBeginning
                }
                lineEnding={referenceTypeCard.referenceTypeData.lineEnding}
              />
            }
            {...(canManageReferenceTypes
              ? {
                  onEditClick: () =>
                    dispatchAction(
                      editReferenceType(referenceTypeCard.referenceTypeId)
                    ),
                }
              : {})}
            {...(canManageReferenceTypes &&
            !referenceTypeCard.isReferenceTypeInUse
              ? {
                  onDeleteClick: () =>
                    dispatchAction(
                      confirmDeleteReferenceType(
                        referenceTypeCard.referenceTypeId
                      )
                    ),
                }
              : {})}
          />
        );
      })}
    </SidebarMenu>
  );
};

export default connect(ManageReferenceTypes, manageReferenceTypes$);
