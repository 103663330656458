import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import {
  TransferDirection,
  TransferState,
} from 'integrations/common/streams/transferState/types';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import {
  IntegrationId,
  TableId,
  TabularMapping,
} from 'integrations/common/streams/tabularMappings/types';
import { combineLatest, EMPTY, Observable, of, switchMap } from 'rxjs';
import { parseResponseTotal, statusTableData } from './utils';
import {
  isSuccessTransfer,
  successStateToDryRunStatus,
} from 'integrations/common/streams/transferState/utils';
import {
  Overview as OverviewComponent,
  OverviewProps,
} from 'integrations/common/components/transfer/Overview';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { TablePreviews } from 'integrations/common/streams/tablePreviews/types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { IntegrationTermsDictionary } from 'integrations/common/streams/integrationTermsDictionary/types';
import { navigateToConfigure } from 'integrations/common/navigation/actions';
import { DryRunAdditionalPopoverContent } from './atoms';

const onConfigureTable = (integrationId: IntegrationId) => (tableId: TableId) =>
  dispatchAction(navigateToConfigure({ integrationId, tableId }));

const buildModel = ({
  integrationId,
  transferState,
  tablePreviews,
  tabularMappings,
  integrationTermsDictionary,
}: {
  integrationId: IntegrationId;
  transferState: TransferState;
  tablePreviews: TablePreviews;
  tabularMappings: TabularMapping;
  integrationTermsDictionary: IntegrationTermsDictionary;
}): Observable<OverviewProps> => {
  const deleteMatrixEnabled = Object.values(tabularMappings).some(
    mapping => !!mapping.options?.deletionStrategy
  );
  if (isSuccessTransfer(transferState)) {
    const parseMatricesData = parseResponseTotal(transferState.response.totals);
    const transferDirectionString =
      transferState.transferDirection === TransferDirection.IMPORT
        ? 'import'
        : 'export';
    return of({
      status: successStateToDryRunStatus(transferState),
      totals: parseMatricesData.totals,
      preview: parseMatricesData.preview,
      onConfigure: onConfigureTable(integrationId),
      tables: statusTableData({
        dryRunResponse: transferState.response,
        tablePreviews,
        tabularMappings,
      }),
      tableHeader: integrationTermsDictionary.tableHeader,
      dryRunAdditionalMessage:
        integrationTermsDictionary.dryRunAdditionalMessage,
      dryRunAdditionalPopoverContent:
        integrationTermsDictionary.dryRunAdditionalMessage
          ? DryRunAdditionalPopoverContent
          : undefined,
      dryRunSummaryWarning:
        integrationTermsDictionary.dryRunSummaryWarning[
          transferDirectionString
        ],
      dryRunSummaryError:
        integrationTermsDictionary.dryRunSummaryError[transferDirectionString],
      deleteMatrixEnabled,
      transferDirection: transferDirectionString,
    });
  }
  return EMPTY;
};

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest({
      integrationId: of(integrationId),
      transferState: getTransferStateStream(integrationId),
      tablePreviews: getTablePreviewsStream(integrationId),
      tabularMappings: getTabularMappingStream(integrationId),
      integrationTermsDictionary:
        getIntegrationTermsDictionaryStream(integrationId),
    })
  ),
  switchMap(buildModel)
);

export const Overview = connect(OverviewComponent, viewModel$);
