import { APIComponentAttributes, ArdoqId } from '@ardoq/api-types';
import { colors } from '@ardoq/design-tokens';
import { Column, newTableTheme, SelectableDatasourceTable } from '@ardoq/table';
import { SubdivisionProps } from 'subdivisionEditor/types';
import { getCurrentLocale } from '@ardoq/locale';
import { formatDateTime } from '@ardoq/date-time';
import styled from 'styled-components';

const TableContainer = styled.div``;

const getColumns = (
  props: SubdivisionProps
): Column<APIComponentAttributes>[] => {
  const locale = getCurrentLocale();

  return [
    {
      dataIndex: 'name',
      title: 'Name',
    },
    {
      dataIndex: 'type',
      title: 'Type',
    },
    {
      dataIndex: 'rootWorkspace',
      title: 'Workspace',
      valueRender: rootWorkspace => {
        return props.bindWorkspacesState.workspaces.find(
          workspace => workspace._id === rootWorkspace
        )?.name;
      },
    },
    {
      dataIndex: 'last-updated',
      title: 'Last Updated',
      valueRender: lastUpdated => formatDateTime(lastUpdated, locale),
    },
    {
      dataIndex: 'created',
      title: 'Created date',
      valueRender: created => formatDateTime(created, locale),
    },
  ];
};

type ComponentsToAddResultsTableProps = SubdivisionProps & {
  components: APIComponentAttributes[];
  selectedSearchResults: ArdoqId[];
  disabledComponents?: ArdoqId[];
  setSelected?: (selected: ArdoqId[]) => void;
};

export const ComponentsResultTable = (
  props: ComponentsToAddResultsTableProps
) => {
  const { selectedSearchResults, components, setSelected } = props;
  return (
    <TableContainer>
      <SelectableDatasourceTable
        rowStyle={(rowData: any) =>
          selectedSearchResults.includes(rowData._id)
            ? { background: colors.blue95 }
            : {}
        }
        fixedHeader
        onRowClick={rowData =>
          setSelected?.(
            selectedSearchResults.includes(rowData._id)
              ? selectedSearchResults.filter(id => id !== rowData._id)
              : [...selectedSearchResults, rowData._id]
          )
        }
        scrollableSectionHeight={'600px'}
        idIndex="_id"
        dataSource={components}
        selected={selectedSearchResults}
        setSelected={setSelected}
        columns={getColumns(props)}
        components={newTableTheme}
      />
    </TableContainer>
  );
};
