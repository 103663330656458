import {
  ApiCountInstancesRequest,
  APICountInstancesResponse,
  APIViewpointAttributes,
  ArdoqId,
  DirectedTripleWithFilters,
  MetaModel,
  ReferenceDirection,
  SurveyApprovalAudiences,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import {
  NamedDirectedTriple,
  TriplesSortAndFiltersState,
} from 'viewpointBuilder/types';

export const updateTraversal = actionCreator<
  Pick<APIViewpointAttributes, 'paths' | 'filters'>
>('[audienceTraversalDrawer] UPDATE_TRAVERSAL');

export const selectGraphNode = actionCreator<string>(
  '[audienceTraversalDrawer] UPDATE_SELECTED_GRAPH_NODE_ID'
);

export const fetchInstanceCounts = actionCreator<ApiCountInstancesRequest>(
  '[audienceTraversalDrawer] FETCH_INSTANCE_COUNTS'
);

export const setInstanceCounts = actionCreator<APICountInstancesResponse>(
  '[audienceTraversalDrawer] SET_INSTANCE_COUNTS'
);

export const setFetchInstanceCountsError = actionCreator(
  '[audienceTraversalDrawer] SET_FETCH_INSTANCE_COUNTS_ERROR'
);

export const setTriplesSortAndFiltersState =
  actionCreator<TriplesSortAndFiltersState>(
    '[audienceTraversalDrawer] SET_TRIPLES_SORT_AND_FILTERS_STATE'
  );

export const failedToLoadMetamodel = actionCreator(
  '[audienceTraversalDrawer] FAILED_TO_LOAD_METAMODEL'
);

export const setMetamodelAndTraversalStartQueryData = actionCreator<{
  surveyComponentTypeName: string;
  workspaceId: ArdoqId | null;
  approvalAudience: SurveyApprovalAudiences;
  metamodel: MetaModel;
}>('[audienceTraversalDrawer] SET_METAMODEL_AND_TRAVERSAL_START_QUERY_DATA');

export const resetAudienceDrawerData = actionCreator<
  Pick<APIViewpointAttributes, 'paths' | 'filters'>
>('[audienceTraversalDrawer] RESET_AUDIENCE_DRAWER_DATA');

export const addTriple = actionCreator<{
  direction: ReferenceDirection;
  namedDirectedTriple: NamedDirectedTriple;
}>('[audienceTraversalDrawer] ADD_TRIPLE');

export const removeTriple = actionCreator<{
  namedDirectedTriple: NamedDirectedTriple;
}>('[audienceTraversalDrawer] REMOVE_TRIPLE');

export const removePath = actionCreator<DirectedTripleWithFilters[]>(
  '[audienceTraversalDrawer] REMOVE_PATH'
);
