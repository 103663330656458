import {
  AccessControlGroupDetailsRoute,
  AccessControlGroupsRoute,
  AccessControlMemberRoute,
  AccessControlRoute,
  AccessControlTabs,
  AccessControlUserProfileRoute,
} from './types';

const isMembersOverviewPage = (
  props: AccessControlRoute
): props is AccessControlMemberRoute =>
  props.accessControlTab === AccessControlTabs.MEMBERS;

const isUserProfilePage = (
  navigationState: AccessControlRoute
): navigationState is AccessControlUserProfileRoute =>
  navigationState.accessControlTab === AccessControlTabs.MEMBERS &&
  !!navigationState.userId;

const isGroupsOverviewPage = (
  props: AccessControlRoute
): props is AccessControlGroupsRoute =>
  props.accessControlTab === AccessControlTabs.PERMISSION_GROUPS;

const isGroupDetailsPage = (
  navigationState: AccessControlRoute
): navigationState is AccessControlGroupDetailsRoute =>
  navigationState.accessControlTab === AccessControlTabs.PERMISSION_GROUPS &&
  !!navigationState.groupId;

export const accessControlNavigationOperations = {
  isMembersOverviewPage,
  isUserProfilePage,
  isGroupsOverviewPage,
  isGroupDetailsPage,
};
