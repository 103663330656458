import { AppModules } from 'appContainer/types';
import { AppRouterState, ScenarioRoute } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { dispatchAction } from '@ardoq/rxbeach';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import activeView$ from 'streams/views/mainContent/activeView$';
import { context$ } from 'streams/context/context$';
import {
  ContextSort,
  NumericSortOrder,
  SortAttribute,
} from '@ardoq/common-helpers';
import activeFilter$ from 'streams/filters/activeFilter$';
import { loadScenarioModuleRoute } from './navigationActions';
import { ViewIds } from '@ardoq/api-types';

const ROUTE_REGEXP =
  /\/view\/(?<combinedViewId>[a-z\dA-Z_\-+]+)\/scenario\/(?<scenarioId>[\da-z+]+)\??(.+)*/;

enum QueryParamKeys {
  Filters = 'filters',
  PerspectiveId = 'ps_id',
  ComponentId = 'componentId',
  ReferenceId = 'referenceId',
  PresentationId = 'presentationId',
  SortAscending = 'sort_ASC',
  SortDescending = 'sort_DESC',
}

const scenarioRoute = new Route<AppRouterState, ScenarioRoute>({
  doesLocationMatch: ({ path }) => ROUTE_REGEXP.test(path),

  locationToRouterState: ({ path, searchParams }) => {
    const match = ROUTE_REGEXP.exec(path);
    const { combinedViewId, scenarioId } = (match || {}).groups || {};

    let perspectiveId: null | string = null;
    let activeFiltersQueryString = '';
    let componentId: null | string = null;
    let referenceId: null | string = null;
    let presentationId: null | string = null;

    const [mainViewId, secondaryViewId] = combinedViewId.split('+') as [
      ViewIds,
      ViewIds | undefined,
    ];

    const sort: ContextSort = {
      attr: SortAttribute.ORDER,
      order: NumericSortOrder.ASC,
    };

    if (searchParams) {
      // sort
      const ascendingSort = searchParams.get(QueryParamKeys.SortAscending);
      const descendingSort = searchParams.get(QueryParamKeys.SortDescending);
      if (ascendingSort) {
        sort.attr = ascendingSort;
      } else if (descendingSort) {
        sort.order = NumericSortOrder.DESC;
        sort.attr = descendingSort;
      }

      perspectiveId = searchParams.get(QueryParamKeys.PerspectiveId) || null;
      activeFiltersQueryString = searchParams.get(QueryParamKeys.Filters) || '';

      componentId = searchParams.get(QueryParamKeys.ComponentId) || null;
      referenceId = searchParams.get(QueryParamKeys.ReferenceId) || null;
      presentationId = searchParams.get(QueryParamKeys.PresentationId) || null;
    }

    return {
      appModule: AppModules.WORKSPACES,
      mainViewId,
      secondaryViewId: secondaryViewId || null,
      scenarioId,
      componentId,
      referenceId,
      presentationId,
      sort,
      perspectiveId,
      activeFiltersQueryString,
    };
  },

  doesRouterStateMatch: ({ scenarioId }) => Boolean(scenarioId),

  routerStateToLocation: ({
    mainViewId,
    secondaryViewId,
    sort,
    scenarioId,
    perspectiveId,
    componentId,
    referenceId,
    presentationId,
    activeFiltersQueryString,
  }) => {
    const searchParams = new URLSearchParams();

    // componentId
    if (componentId) {
      searchParams.set(QueryParamKeys.ComponentId, componentId);
    }

    // referenceId
    if (referenceId) {
      searchParams.set(QueryParamKeys.ReferenceId, referenceId);
    }

    // presentationId
    if (presentationId) {
      searchParams.set(QueryParamKeys.PresentationId, presentationId);
    }

    // perspectiveId
    if (perspectiveId) {
      searchParams.set(QueryParamKeys.PerspectiveId, perspectiveId);
    } else if (activeFiltersQueryString) {
      searchParams.set(
        QueryParamKeys.Filters,
        // Decode to prevent double encoding
        // since the query string is already URL encoded
        // This should be cleaned up once the backbone router is removed
        decodeURI(activeFiltersQueryString)
      );
    }

    // sort order
    if (sort) {
      const querySortOrder =
        sort.order === NumericSortOrder.ASC
          ? QueryParamKeys.SortAscending
          : QueryParamKeys.SortDescending;
      searchParams.set(querySortOrder, sort.attr);
    }

    // viewIds
    const combinedViewId = [mainViewId, secondaryViewId]
      .filter(Boolean)
      .join('+');

    return {
      title: 'Scenario',
      path: `/view/${combinedViewId}/scenario/${scenarioId}`,
      searchParams,
      search: searchParams.toString(),
    };
  },

  setApplicationStateFromRoute: ({
    mainViewId,
    secondaryViewId,
    scenarioId,
    perspectiveId,
    componentId,
    referenceId,
    presentationId,
    activeFiltersQueryString,
    sort,
  }) =>
    dispatchAction(
      loadScenarioModuleRoute({
        mainViewId,
        secondaryViewId,
        scenarioId,
        componentId,
        referenceId,
        presentationId,
        perspectiveId,
        activeFiltersQueryString,
        sort,
      })
    ),

  getPartialRouterStateStream: () => {
    return combineLatest([
      context$,
      activeScenario$,
      activeView$,
      activeFilter$,
    ]).pipe(
      map(
        ([
          { sort, presentationId, componentId, referenceId },
          { scenarioId },
          { mainViewId, secondaryViewId },
          activeFilterState,
        ]) => {
          const {
            selectedPerspectiveQueryString,
            selectedPerspectiveId,
            activeFiltersQueryString,
          } = activeFilterState;

          return {
            mainViewId,
            secondaryViewId,
            scenarioId: scenarioId || '',
            componentId: componentId || null,
            presentationId: presentationId || null,
            referenceId,
            activeFiltersQueryString,
            perspectiveId:
              selectedPerspectiveQueryString === activeFiltersQueryString
                ? selectedPerspectiveId
                : null,
            sort,
          };
        }
      )
    );
  },
});

export default scenarioRoute;
