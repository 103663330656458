import { IconDecorator } from '@ardoq/decorators';
import { Features, hasFeature } from '@ardoq/features';
import { CheckboxGroup, CheckboxGroupItem } from '@ardoq/forms';
import { IconName } from '@ardoq/icons';
import { Multiselect } from '@ardoq/select';
import { SidebarMenu, SidebarMenuSection } from '@ardoq/sidebar-menu';
import { AccessControlPageProps } from 'appContainer/AccessControlPage/types';
import { userAssetsFilterSidebarStateOperations } from './assetsOverviewFilterSidebarOperations';

type AssetsOverviewFilterSidebarProps = {
  commands: AccessControlPageProps['commands'];
  userAssetsQuery: AccessControlPageProps['userAssetsQuery'];
  userAssetsFilterSidebarState: AccessControlPageProps['userAssetsFilterSidebarState'];
};

export const UserAssetsOverviewFilterSidebar = ({
  commands,
  userAssetsQuery,
  userAssetsFilterSidebarState,
}: AssetsOverviewFilterSidebarProps) => (
  <SidebarMenu
    headerTitle="Filter"
    headerLeftIconName={IconName.FILTER_LIST}
    onCloseClick={commands.closeUserAssetsFilterSidebar}
    isPageBodyMenu
    isRightContent
    headerTitleTestId={'Filters'}
  >
    <SidebarMenuSection title="Asset type">
      <CheckboxGroup
        value={userAssetsQuery.assetTypes}
        onValueChange={values =>
          commands.assetTypeCheckboxGroupChanged(userAssetsQuery, values)
        }
      >
        {userAssetsFilterSidebarStateOperations
          .mapAssetTypesToOptions(userAssetsFilterSidebarState)
          .map(({ value, iconName, label }) => (
            <CheckboxGroupItem value={value} key={value}>
              <IconDecorator iconName={iconName}>{label}</IconDecorator>
            </CheckboxGroupItem>
          ))}
      </CheckboxGroup>
    </SidebarMenuSection>
    {
      /* Filtering by access won't be released with the first iteration */
      hasFeature(Features.PERMISSION_ZONES_INTERNAL) && (
        <SidebarMenuSection title="Access">
          <Multiselect
            label="Filter by access level"
            onChange={values =>
              commands.accessLevelMultiselectChanged(userAssetsQuery, values)
            }
            options={userAssetsFilterSidebarState.permissionAccessLevelOptions}
            value={userAssetsQuery.permissionLevels}
          />
        </SidebarMenuSection>
      )
    }
  </SidebarMenu>
);
