import { ReactNode } from 'react';
import { PrimaryButton, GhostButton } from '@ardoq/button';
import { SurveyEditorHeaderActions, SurveyEditorHeaderInfo } from '../atoms';
import { trackEvent } from 'tracking/tracking';
import { SurveyTrackingEventsNames } from 'surveyAdmin/tracking';
import { FlexBox } from '@ardoq/layout';

type SurveyEditorHeaderLeftProps = {
  canSave: boolean;
  onBack: () => void;
  onSave: () => void;
  saveButtonText: string;
  status: ReactNode;
};

const SurveyEditorHeaderLeft = ({
  canSave,
  onBack,
  onSave,
  saveButtonText,
  status,
}: SurveyEditorHeaderLeftProps) => {
  return (
    <FlexBox flex={1} align="center" gap="small" paddingLeft="small">
      <SurveyEditorHeaderInfo>{status}</SurveyEditorHeaderInfo>
      <SurveyEditorHeaderActions>
        <GhostButton
          onClick={() => {
            trackEvent(
              SurveyTrackingEventsNames.CLICKED_ON_BACK_TO_OVERVIEW_IN_SURVEY_EDITOR
            );
            onBack();
          }}
          dataTestId="overview-button"
        >
          Back to overview
        </GhostButton>
        <PrimaryButton
          isDisabled={!canSave}
          onClick={onSave}
          dataTestId="save-button"
        >
          {saveButtonText}
        </PrimaryButton>
      </SurveyEditorHeaderActions>
    </FlexBox>
  );
};
export default SurveyEditorHeaderLeft;
