import { connect, dispatchAction } from '@ardoq/rxbeach';
import { DashboardModule } from './types';
import DashboardBuilder from './DashboardBuilder/DashboardBuilder';
import { ArdoqId } from '@ardoq/api-types';
import dashboardNavigation$ from './navigation/dashboardNavigation$';
import DashboardReader from './DashboardReader/DashboardReader';
import DashboardOverview from './DashboardOverview/DashboardOverview';
import DashboardColorThemeSettings from './DashboardColorThemeSettings/DashboardColorThemeSettings';
import UsageMetricsDashboard from './UsageMetrics/UsageMetricsDashboard';
import { combineLatest, map } from 'rxjs';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';
import scenarios$ from 'streams/scenarios/scenarios$';
import {
  buildScenarioOptions,
  ScenarioSelection,
} from 'utils/searchBranchSelection';
import { setDashboardBranchId } from './DashboardBuilder/actions';

type DashboardContainerProps = {
  dashboardModule: DashboardModule;
  selectedDashboardId?: ArdoqId;
  permissionContext: PermissionContext;
  scenarioSelection: ScenarioSelection;
};

const DashboardContainer = ({
  dashboardModule,
  permissionContext,
  selectedDashboardId,
  scenarioSelection,
}: DashboardContainerProps) =>
  dashboardModule === DashboardModule.BUILDER ? (
    <DashboardBuilder
      selectedDashboardId={selectedDashboardId}
      permissionContext={permissionContext}
    />
  ) : dashboardModule === DashboardModule.READER && selectedDashboardId ? (
    <DashboardReader
      permissionContext={permissionContext}
      selectedDashboardId={selectedDashboardId}
      scenarioSelection={scenarioSelection}
    />
  ) : dashboardModule === DashboardModule.COLOR_THEME_SETTINGS ? (
    <DashboardColorThemeSettings />
  ) : dashboardModule === DashboardModule.USAGE_METRICS ? (
    <UsageMetricsDashboard />
  ) : (
    <DashboardOverview permissionContext={permissionContext} />
  );

export default connect(
  DashboardContainer,
  combineLatest([
    dashboardNavigation$,
    currentUserPermissionContext$,
    scenarios$,
  ]).pipe(
    map(([dashboardNavigation, permissionContext, scenarios]) => ({
      ...dashboardNavigation,
      scenarioSelection: buildScenarioOptions(
        scenarios,
        dashboardNavigation.branchId,
        branchId => {
          if (dashboardNavigation.selectedDashboardId) {
            dispatchAction(
              setDashboardBranchId({
                branchId,
                dashboardId: dashboardNavigation.selectedDashboardId,
              })
            );
          }
        }
      ),
      permissionContext,
    }))
  )
);
