import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  expandChatWindow,
  initializeNewChat,
  openChatWindow,
  closeChatWindow,
  minimizeChatWindow,
  sendMessage,
  sendMessageFailed,
  initializeNewChatFailed,
  initializeNewChatSuccess,
  sendMessageDone,
  submitFeedback,
  passToIntercom,
  closeConnection,
  setCurrentScrollTop,
  setAutoScrollToBottom,
  scrollToBottom,
  badFeedbackComment,
  dequeueMessages,
  chatTimedOut,
} from './actions';
import { bestPracticeAssistantApi, handleError } from '@ardoq/api';
import { delay, filter, switchMap, tap, withLatestFrom, map } from 'rxjs';
import {
  bestPracticeAssistant$,
  BestPracticeAssistantState,
} from './bestPracticeAssistant$';
import { trackEvent } from 'tracking/tracking';
import { BestPracticeAssistantTrackingEvents } from './trackingEvents';
import { showSupport } from 'utils/support';
import { CHAT_MESSAGES_BODY_SCROLLABLE_ELEMENT_ID } from './components/ChatMessagesBody';
import { currentChatMessageOperations } from './currentChatMessageOperations';
import { bestPracticeAssistantOperations } from './bestPracticeAssistantOperations';
import { ToastType, showToast } from '@ardoq/status-ui';
import {
  launchNegativeFeedbackSubmissionModal,
  NegativeFeedbackSubmissionModalResult,
} from './components/NegativeFeedbackSubmissionModal';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';

const AUTO_SCROLL_DELAY = 150; // Delay in ms between data update and scroll performing (to allow changes to reach the document)

const handleInitializeNewChat = routine(
  ofType(initializeNewChat),
  extractPayload(),
  tap(() => {
    trackEvent(BestPracticeAssistantTrackingEvents.INITIALIZED_NEW_CHAT, {});
  }),
  switchMap(bestPracticeAssistantApi.startChat),
  handleError(error =>
    dispatchAction(initializeNewChatFailed(getArdoqErrorMessage(error)))
  ),
  tap(result => {
    dispatchAction(initializeNewChatSuccess(result));
  })
);

const handleSendMessage = routine(
  ofType(sendMessage),
  extractPayload(),
  tap(() => {
    trackEvent(BestPracticeAssistantTrackingEvents.QUESTION_SUBMITTED, {});
  }),
  switchMap(bestPracticeAssistantApi.sendMessage),
  handleError(error =>
    dispatchAction(sendMessageFailed(getArdoqErrorMessage(error)))
  )
);

const handleMessageChunkReceived = routine(
  ofType(dequeueMessages),
  delay(AUTO_SCROLL_DELAY),
  withLatestFrom(bestPracticeAssistant$),
  filter(([_, { autoScrollToBottom }]) => autoScrollToBottom),
  tap(() => dispatchAction(scrollToBottom()))
);

const handleScrollToBottomOnSendMessage = routine(
  ofType(sendMessage),
  delay(AUTO_SCROLL_DELAY),
  tap(() => dispatchAction(scrollToBottom()))
);

const handleMinimizedChatWindow = routine(
  ofType(minimizeChatWindow),
  extractPayload(),
  tap(({ silenceEvent }) => {
    if (silenceEvent) return;
    trackEvent(BestPracticeAssistantTrackingEvents.MINIMIZED_CHAT_WINDOW, {});
  })
);

const handleCloseChatWindow = routine(
  ofType(closeChatWindow),
  tap(() => {
    trackEvent(BestPracticeAssistantTrackingEvents.CLOSED_CHAT_WINDOW, {});
    dispatchAction(closeConnection());
  })
);

const handleOpenChatWindow = routine(
  ofType(openChatWindow),
  extractPayload(),
  withLatestFrom(bestPracticeAssistant$),
  tap(([options, { mode }]) => {
    if (mode === 'closed') {
      dispatchAction(initializeNewChat(options));
    }
    if (mode === 'minimized') {
      dispatchAction(expandChatWindow());
    }
  })
);

const handleSendMessageDone = routine(
  ofType(sendMessageDone),
  tap(() => {
    trackEvent(BestPracticeAssistantTrackingEvents.GOT_SUCCESSFUL_RESPONSE, {});
  })
);

const handleExpandChatWindow = routine(
  ofType(expandChatWindow),
  tap(() => {
    trackEvent(BestPracticeAssistantTrackingEvents.MAXIMIZED_CHAT_WINDOW, {});
  })
);

const handleSubmitFeedback = routine(
  ofType(submitFeedback),
  extractPayload(),
  withLatestFrom(bestPracticeAssistant$),
  tap(([feedback, { threadId }]) => {
    const trackingEvent =
      feedback === 'good'
        ? BestPracticeAssistantTrackingEvents.SUBMITTED_POSITIVE_FEEDBACK
        : BestPracticeAssistantTrackingEvents.SUBMITTED_NEGATIVE_FEEDBACK;
    trackEvent(trackingEvent, { threadId });
    if (feedback === 'bad') dispatchAction(badFeedbackComment());
  })
);

const handleBadFeedbackComment = routine(
  ofType(badFeedbackComment),
  switchMap(launchNegativeFeedbackSubmissionModal),
  filter(
    (modalResponse): modalResponse is NegativeFeedbackSubmissionModalResult =>
      Boolean(
        modalResponse &&
          (modalResponse.content || modalResponse.selectedReasons.length > 0)
      )
  ),
  withLatestFrom(bestPracticeAssistant$),
  filter(
    (
      modalResponseAndBestPracticeStreamState
    ): modalResponseAndBestPracticeStreamState is [
      NegativeFeedbackSubmissionModalResult,
      BestPracticeAssistantState & { threadId: string },
    ] => Boolean(modalResponseAndBestPracticeStreamState[1].threadId)
  ),
  map(([{ content, selectedReasons }, { threadId, messages }]) => ({
    threadId,
    content: {
      userFeedback: bestPracticeAssistantOperations.sanitizeFeedbackContent(
        `${selectedReasons.join(', ')} | ${content}`
      ),
    },
    sequenceNumber: messages.length,
  })),
  switchMap(bestPracticeAssistantApi.submitFeedback),
  tap(response => {
    if (isArdoqError(response))
      return showToast('Failed to submit feedback', ToastType.INFO);
    showToast('Thank you for you feedback', ToastType.SUCCESS);
  })
);

const handlePassToIntercom = routine(
  ofType(passToIntercom),
  withLatestFrom(bestPracticeAssistant$),
  tap(([_, { threadId }]) => {
    dispatchAction(minimizeChatWindow({ silenceEvent: true }));
    showSupport({ chat: true });
    trackEvent(BestPracticeAssistantTrackingEvents.PASSED_TO_INTERCOM, {
      threadId,
    });
  })
);

const handleAutoScrollOnScrollTopChanged = routine(
  ofType(setCurrentScrollTop),
  withLatestFrom(bestPracticeAssistant$),
  tap(([{ payload }, { currentScrollTop, autoScrollToBottom }]) => {
    const scrollingUp = payload < currentScrollTop;
    if (scrollingUp) {
      dispatchAction(setAutoScrollToBottom(false));
      return;
    }

    if (autoScrollToBottom) return;

    const element = document.getElementById(
      CHAT_MESSAGES_BODY_SCROLLABLE_ELEMENT_ID
    );
    if (!element) return;

    const distanceToBottom =
      element.scrollHeight - element.offsetHeight - element.scrollTop;

    if (distanceToBottom < 100) {
      dispatchAction(setAutoScrollToBottom(true));
    }
  })
);

const handleScrollToBottom = routine(
  ofType(scrollToBottom),
  tap(() => {
    const element = document.getElementById(
      CHAT_MESSAGES_BODY_SCROLLABLE_ELEMENT_ID
    );
    if (!element) return;

    element.scroll({
      top: element.scrollHeight,
      behavior: 'smooth',
    });
  })
);

const pullMessages = routine(
  ofType(dequeueMessages),
  withLatestFrom(bestPracticeAssistant$),
  tap(([_, { currentChatMessage }]) => {
    if (!currentChatMessage) return;
    if (
      !currentChatMessageOperations.hasMoreMessagesToDequeue(currentChatMessage)
    ) {
      dispatchAction(sendMessageDone());
    } else if (
      currentChatMessageOperations.hasChatTimedOut(currentChatMessage)
    ) {
      dispatchAction(chatTimedOut());
      trackEvent(BestPracticeAssistantTrackingEvents.CHAT_TIMED_OUT);
    } else {
      setTimeout(
        () => dispatchAction(dequeueMessages()),
        currentChatMessageOperations.getDelay(currentChatMessage)
      ); // wait for delay milliseconds before pulling next messages
    }
  })
);

export const bestPracticeAssistantRoutines = collectRoutines(
  handleInitializeNewChat,
  handleOpenChatWindow,
  handleSendMessage,
  handleMinimizedChatWindow,
  handleCloseChatWindow,
  handleSendMessageDone,
  handleExpandChatWindow,
  handleSubmitFeedback,
  handlePassToIntercom,
  handleAutoScrollOnScrollTopChanged,
  handleScrollToBottom,
  handleScrollToBottomOnSendMessage,
  handleMessageChunkReceived,
  pullMessages,
  handleMessageChunkReceived,
  handleBadFeedbackComment
);
