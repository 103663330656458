import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { ConfigurationState } from '../types';
import {
  updateComponentTypesKeys,
  resetConfig,
  updateConfigState,
  UpdateComponentTypePropsPayload,
  updateComponentTypeProps,
  UpdateConfigResultsPayload,
  updateConfigResults,
} from './actions';
import { zipObject, merge, pick } from 'lodash/fp';
import { defaultComponentType, defaultState } from './utils';

const updateComponentTypesKeysReducer = (
  state: ConfigurationState,
  payload: string[]
) => {
  const filteredObj = pick(payload, state.componentTypes);
  const synchronizedObj = merge(
    zipObject(payload, Array(payload.length).fill(defaultComponentType)),
    filteredObj
  );

  return {
    ...state,
    componentTypes: synchronizedObj,
  };
};

const updateComponentTypePropsReducer = (
  state: ConfigurationState,
  payload: UpdateComponentTypePropsPayload
) => {
  const componentType =
    state.componentTypes[payload.componentType] || defaultComponentType;
  return {
    ...state,
    componentTypes: {
      ...state.componentTypes,
      [payload.componentType]: {
        ...componentType,
        ...payload.props,
      },
    },
  };
};

const resetConfigReducer = () => {
  return defaultState;
};

const updateConfigReducer = (
  state: ConfigurationState,
  payload: Partial<ConfigurationState>
) => {
  return { ...state, ...payload };
};

const updateConfiguStatusesReducer = (
  state: ConfigurationState,
  payload: UpdateConfigResultsPayload
) => {
  return { ...state, results: { ...state.results, ...payload } };
};

export const configState$ = persistentReducedStream(
  `signavioExporterConfigurationState$`,
  defaultState,
  [
    reducer(updateComponentTypesKeys, updateComponentTypesKeysReducer),
    reducer(resetConfig, resetConfigReducer),
    reducer(updateConfigState, updateConfigReducer),
    reducer(updateComponentTypeProps, updateComponentTypePropsReducer),
    reducer(updateConfigResults, updateConfiguStatusesReducer),
  ]
);
