import { debounceTime, filter, map, withLatestFrom } from 'rxjs/operators';
import subdivisionCreationContext$ from './subdivisionCreationContext$';
import { context$ } from 'streams/context/context$';
import { subdivisionCreationContextOperations } from './subdivisionCreationContextOperations';
import currentUser$ from 'streams/currentUser/currentUser$';
import workspaces$ from 'streams/workspaces/workspaces$';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  setDifferentSubdivisionCreationContext,
  submitUpdateToTheServer,
} from './actions';

const UPDATE_SERVER_DEBOUNCE_TIME = 800;

const selectedDifferentSubdivisionCreationContext$ = context$.pipe(
  withLatestFrom(subdivisionCreationContext$),
  filter(([context, subdivisionCreationContext]) =>
    subdivisionCreationContextOperations.workspaceHasChanged(
      subdivisionCreationContext,
      context
    )
  ),
  withLatestFrom(
    currentUser$,
    workspaces$,
    subdivisions$,
    currentUserPermissionContext$
  ),
  map(([[context], currentUser, workspaces, subdivisions, permissions]) => {
    const { workspaceId } = context;
    if (!workspaceId) return null;
    const workspace = workspaces.byId[workspaceId];
    if (!workspace) return null;
    return subdivisionCreationContextOperations.createState({
      currentUser,
      subdivisions,
      permissions,
      workspace,
    });
  })
);

const subdivisionCreationContextUpdated$ = subdivisionCreationContext$.pipe(
  debounceTime(UPDATE_SERVER_DEBOUNCE_TIME),
  filter(subdivisionCreationContextOperations.mustUpdateServer)
);

export const initSubdivisionCreationContextEventListeners = () => {
  selectedDifferentSubdivisionCreationContext$.subscribe(state => {
    if (state) dispatchAction(setDifferentSubdivisionCreationContext(state));
  });
  subdivisionCreationContextUpdated$.subscribe(state => {
    dispatchAction(submitUpdateToTheServer(state));
  });
};
