import { actionCreator } from '@ardoq/rxbeach';
import { ComponentSearchData, SelectedComponent } from './types';
import {
  ComponentSelection,
  LoadedStateHash,
  QueryBuilderQuery,
  QueryBuilderSubquery,
  ViewIds,
} from '@ardoq/api-types';

export const setComponentName = actionCreator<string>(
  '[componentSearch] SET_COMPONENT_NAME'
);

export const setComponentTypeName = actionCreator<string>(
  '[componentSearch] SET_COMPONENT_TYPE_NAME'
);

export const setAllComponentsSelected = actionCreator<boolean>(
  '[componentSearch] SET_ALL_COMPONENTS_SELECTED'
);

export type ApplyFiltersForContextSwitcherPayload = {
  componentTypeName: string;
  startSetFilter: QueryBuilderSubquery | null;
};

export const applyFiltersForContextSwitcher =
  actionCreator<ApplyFiltersForContextSwitcherPayload>(
    '[componentSearch] APPLY_FILTERS_FOR_CONTEXT_SWITCHER'
  );

export type OpenComponentsPayload = {
  componentSelection: ComponentSelection;
  viewId?: ViewIds;
};

export const openComponents = actionCreator<OpenComponentsPayload>(
  '[componentSearch] OPEN_COMPONENTS'
);

export const toggleSingleComponentSelection = actionCreator<string>(
  '[componentSearch] TOGGLE_SINGLE_COMPONENT_SELECTION'
);

export const removeUnselectedLoadedComponents = actionCreator(
  '[componentSearch] REMOVE_UNSELECTED_LOADED_COMPONENTS'
);

export type SetLoadedComponentsPayload = {
  loadedComponents: ComponentSearchData[];
  totalCount: number;
};

export const setLoadedComponents = actionCreator<SetLoadedComponentsPayload>(
  '[componentSearch] SET_LOADED_COMPONENTS'
);

export const setSelectedComponents = actionCreator<SelectedComponent[]>(
  '[componentSearch] SET_SELECTED_COMPONENTS'
);

export const setIsLoading = actionCreator<boolean>(
  '[componentSearch] SET_IS_LOADING'
);

export const setHasSearchResults = actionCreator<boolean>(
  '[componentSearch] SET_HAS_SEARCH_RESULTS'
);

export const setTotalCount = actionCreator<number>(
  '[componentSearch] SET_TOTAL_COUNT'
);

export const setStartQuery = actionCreator<QueryBuilderQuery | null>(
  '[componentSearch] SET_START_QUERY'
);

export const editComponentSearch = actionCreator<LoadedStateHash>(
  '[traversalDialog] OPEN_EDIT_COMPONENT_SEARCH_DIALOG'
);

export type SaveEditedLoadedStateSearchPayload = {
  loadedStateHash: LoadedStateHash;
  componentSelection: ComponentSelection;
};

export const saveEditedLoadedStateSearch =
  actionCreator<SaveEditedLoadedStateSearchPayload>(
    '[componentSearch] SAVE_EDITED_LOADED_STATE_SEARCH'
  );

export const setIsAdvancedSearch = actionCreator<boolean>(
  '[componentSearch] SET_IS_ADVANCED_SEARCH'
);

export type UpdateViewpointBuilderAdvancedSearchQueryPayload = {
  isEmpty: boolean;
  isValid: boolean;
  query: QueryBuilderQuery | null;
};

export const updateViewpointBuilderAdvancedSearchQuery =
  actionCreator<UpdateViewpointBuilderAdvancedSearchQueryPayload>(
    '[componentSearch] UPDATE_ADVANCED_SEARCH'
  );

export const setStartSetFilter = actionCreator<QueryBuilderSubquery | null>(
  '[componentSearch] SET_START_SET_FILTER'
);
