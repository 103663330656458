import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { SelectionState } from './types';
import fp from 'lodash/fp';
import { SelectionResponse } from '@ardoq/api-types/integrations';
import {
  selectTable,
  unselectTable,
  selectField,
  unselectField,
  setSelectionAsyncStatus,
  resetSelection,
  applyTables,
  SelectFieldsPayload,
  SelectFieldPayload,
  selectFields,
  unselectFields,
  unselectTables,
  setQueryFilter,
  SetQueryFilterPayload,
  applyUnavailableTables,
  fetchSelectionSuccess,
  setFetchSelectionError,
} from './actions';
import { TableId } from 'integrations/common/streams/tabularMappings/types';
import { AsyncStatus } from 'integrations/common/types/api';
import { Maybe } from '@ardoq/common-helpers';

const initialState: SelectionState = {
  asyncStatus: 'INIT',
  tables: {},
  unavailableTables: {},
  currentTableId: null,
  selectionResponse: null,
  errorMessage: null,
};

const resetSelectionReducer = () => initialState;
const handleResetSelectionState = reducer<SelectionState, void>(
  resetSelection,
  resetSelectionReducer
);

// tables

const applyTablesReducer = (
  state: SelectionState,
  tables: SelectionState['tables']
): SelectionState => ({ ...state, tables });

const handleApplyTables = reducer<SelectionState, SelectionState['tables']>(
  applyTables,
  applyTablesReducer
);

const applyUnavailableTablesReducer = (
  state: SelectionState,
  unavailableTables: SelectionState['unavailableTables']
) => ({
  ...state,
  unavailableTables: unavailableTables,
});
const handleApplyUnavailableTables = reducer<
  SelectionState,
  SelectionState['unavailableTables']
>(applyUnavailableTables, applyUnavailableTablesReducer);

const selectTableReducer = (
  state: SelectionState,
  tableId: string
): SelectionState => {
  return {
    ...state,
    currentTableId: tableId,
  };
};

const handleSelectTable = reducer<SelectionState, TableId>(
  selectTable,
  selectTableReducer
);

const unselectTableReducer = (state: SelectionState, tableId: string) =>
  applyTablesReducer(
    {
      ...state,
      currentTableId: null,
    },
    fp.unset([tableId], state.tables)
  );
const handleUnselectTable = reducer<SelectionState, TableId>(
  unselectTable,
  unselectTableReducer
);

const unselectTablesReducer = (state: SelectionState) =>
  fp.set('tables', {}, state);
const handleUnselectTables = reducer<SelectionState>(
  unselectTables,
  unselectTablesReducer
);

// fields

const selectFieldReducer = (
  state: SelectionState,
  { tableId, id }: { tableId: string; id: string }
): SelectionState => {
  const isNewTable = !state.tables[tableId];

  return applyTablesReducer(
    state,
    fp.set(
      [tableId, 'fields', id],
      true,
      isNewTable
        ? fp.set([tableId, 'queryFilter'], null, state.tables)
        : state.tables
    )
  );
};

const handleSelectField = reducer<SelectionState, SelectFieldPayload>(
  selectField,
  selectFieldReducer
);

const unselectFieldReducer = (
  state: SelectionState,
  { tableId, id }: SelectFieldPayload
) => {
  const isLastField =
    fp.toPairs(state.tables[tableId].fields || {}).length <= 1;
  if (isLastField) {
    return fp.unset(['tables', tableId], state);
  }
  return fp.unset(['tables', tableId, 'fields', id], state);
};

const handleUnselectField = reducer<SelectionState, SelectFieldPayload>(
  unselectField,
  unselectFieldReducer
);

const unselectFieldsReducer = (state: SelectionState, tableId: TableId) =>
  applyTablesReducer(state, fp.set([tableId, 'fields'], {}, state.tables));
const handleUnselectFields = reducer<SelectionState, TableId>(
  unselectFields,
  unselectFieldsReducer
);

const selectFieldsReducer = (
  state: SelectionState,
  { tableId, ids }: SelectFieldsPayload
) =>
  fp.reduce(
    (state, id) => selectFieldReducer(state, { tableId, id }),
    state,
    ids
  );
const handleSelectFields = reducer<SelectionState, SelectFieldsPayload>(
  selectFields,
  selectFieldsReducer
);

const setQueryFilterReducer = (
  state: SelectionState,
  { tableId, queryFilter }: SetQueryFilterPayload
) =>
  applyTablesReducer(
    state,
    fp.set([tableId, 'queryFilter'], queryFilter, state.tables)
  );
const handleSetQueryFilter = reducer<SelectionState, SetQueryFilterPayload>(
  setQueryFilter,
  setQueryFilterReducer
);

// fetching

const fetchSelectionSuccessReducer = (
  state: SelectionState,
  selectionResponse: SelectionResponse
): SelectionState => {
  return {
    ...state,
    selectionResponse: selectionResponse,
    asyncStatus: 'SUCCESS',
  };
};

const handleFetchSelectionSuccess = reducer<SelectionState, SelectionResponse>(
  fetchSelectionSuccess,
  fetchSelectionSuccessReducer
);

const setSelectionAsyncStatusReducer = (
  state: SelectionState,
  asyncStatus: AsyncStatus
) => ({ ...state, asyncStatus });
const handleSetSelectionAsyncStatus = reducer<SelectionState, AsyncStatus>(
  setSelectionAsyncStatus,
  setSelectionAsyncStatusReducer
);

const setSelectionErrorReducer = (
  state: SelectionState,
  errorMessage: Maybe<string>
) => ({ ...state, errorMessage });

const reducers = [
  handleApplyTables,
  handleApplyUnavailableTables,
  handleSelectTable,
  handleUnselectTable,
  handleUnselectTables,
  handleSelectField,
  handleSelectFields,
  handleUnselectField,
  handleUnselectFields,
  handleSetQueryFilter,
  handleFetchSelectionSuccess,
  handleSetSelectionAsyncStatus,
  handleResetSelectionState,
  reducer<SelectionState, Maybe<string>>(
    setFetchSelectionError,
    setSelectionErrorReducer
  ),
];

export const selectionState$ = persistentReducedStream(
  `serviceNowSelection$`,
  initialState,
  reducers
);
