import { APIComponentAttributesLite, ArdoqId } from '@ardoq/api-types';
import { getDefaultCSSColor } from '@ardoq/color-helpers';
import { DEFAULT_COLOR } from '@ardoq/renderers';
import { componentInterface } from '@ardoq/component-interface';
import { subdivisionAccessControlInterface } from 'resourcePermissions/accessControlHelpers/subdivisions';
import { PermissionContext } from '@ardoq/access-control';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { IconData, RelevantRootComponent } from './types';
import { RepresentationData } from '@ardoq/data-model';

const getColor = (componentId: ArdoqId): string => {
  const color = componentInterface.getComponentColor(componentId);
  if (color) return color;
  const type = componentInterface.getType(componentId);
  if (type?.color) return type.color;
  return type ? getDefaultCSSColor(type.level) : DEFAULT_COLOR;
};

const getIconData = ({
  componentId,
  ...component
}: { componentId: ArdoqId } & RepresentationData): IconData => {
  const color = getColor(componentId);
  if (component.isImage) return { color, imageSrc: component.value! };
  return {
    color,
    iconId: component.icon?.id ?? 'none',
    shapeId: component.shapeName ?? undefined,
  };
};

export const getRelevantComponentData = (
  componentAttributes: APIComponentAttributesLite,
  {
    permissionContext,
    subdivisionsContext,
  }: {
    permissionContext: PermissionContext;
    subdivisionsContext: SubdivisionsContext;
  }
): RelevantRootComponent => {
  const editableSubdivisionsIds =
    componentAttributes.subdivisionMembership.filter(subdvisionId =>
      subdivisionAccessControlInterface.canEditSubdivision(
        permissionContext,
        subdivisionsContext,
        subdvisionId
      )
    );

  return {
    ...componentAttributes,
    name:
      componentInterface.getDisplayName(componentAttributes._id) ??
      'NAME MISSING',
    subdivisionMembership: editableSubdivisionsIds,
    iconData: getIconData({
      componentId: componentAttributes._id,
      ...(componentInterface.getRepresentationData(componentAttributes._id) ||
        ({} as RepresentationData)),
    }),
  };
};
