import {
  UpdateViewpointBuilderAdvancedSearchQueryPayload,
  SetLoadedComponentsPayload,
  ApplyFiltersForContextSwitcherPayload,
} from './actions';
import {
  ComponentSearchState,
  SelectedComponent,
  ViewpointBuilderAdvancedSearchState,
} from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { uniq } from 'lodash';
import { QueryBuilderQuery } from '@ardoq/api-types';
import { SelectViewpointBuilderContextPayload } from 'viewpointBuilder/viewpointBuilderNavigation/actions';
import { LoadMetaModelAsScopedDataState } from 'viewpointBuilder/metaModel/loadMetaModelTypes';
import { advancedSearchComponentNameAndTypeAndStartSetQuery } from '@ardoq/query-builder';

const areAllComponentsSelected = (state: ComponentSearchState) =>
  state.startQuery !== null;

const clearComponentSearchState = (
  state: ComponentSearchState
): ComponentSearchState => {
  return {
    ...state, // keeps componentTypes
    componentName: '',
    componentType: '',
    loadedComponents: [],
    selectedComponents: [],
    advancedSearchState: null,
    startQuery: null,
    isLoading: false,
    hasSearchResults: false,
    totalCount: 0,
    canSelectOnlyComponentType: false,
    startSetFilter: null,
    selectionClearedOnSelectAllCount: null,
  };
};

const setComponentName = (
  state: ComponentSearchState,
  componentName: string
) => {
  return {
    ...state,
    componentName,
    startQuery: null,
  };
};

const setComponentTypeName = (
  state: ComponentSearchState,
  componentTypeName: string
) => {
  return {
    ...state,
    componentType: componentTypeName,
    startQuery: null,
  };
};

const setLoadedComponents = (
  state: ComponentSearchState,
  { loadedComponents, totalCount }: SetLoadedComponentsPayload
) => {
  return {
    ...state,
    loadedComponents,
    // clear out any previously seleceted components that has been unselected
    selectedComponents: state.selectedComponents.filter(
      ({ isSelected }) => isSelected
    ),
    totalCount,
    selectionClearedOnSelectAllCount: null,
  };
};

const setSelectedComponents = (
  state: ComponentSearchState,
  selectedComponents: SelectedComponent[]
): ComponentSearchState => {
  return {
    ...state,
    selectedComponents,
  };
};

const toggleSingleComponentSelection = (
  state: ComponentSearchState,
  componentId: string
): ComponentSearchState => {
  if (areAllComponentsSelected(state)) {
    return state;
  }
  const isComponentPreviouslySelected = state.selectedComponents.some(
    ({ id }) => id === componentId
  );
  if (isComponentPreviouslySelected) {
    return {
      ...state,
      selectedComponents: state.selectedComponents.map(component => {
        if (component.id === componentId) {
          return {
            ...component,
            isSelected: !component.isSelected,
          };
        }
        return component;
      }),
    };
  }
  const componentToAdd = state.loadedComponents.find(
    ({ id }) => id === componentId
  );
  return {
    ...state,
    selectedComponents: [
      ...state.selectedComponents,
      { ...componentToAdd!, isSelected: true },
    ],
  };
};

const removeUnselectedLoadedComponents = (state: ComponentSearchState) => {
  return {
    ...state,
    loadedComponents: [],
    selectedComponents: state.selectedComponents.filter(
      ({ isSelected }) => isSelected
    ),
  };
};

const setIsLoading = (state: ComponentSearchState, isLoading: boolean) => ({
  ...state,
  isLoading,
});

const setHasSearchResults = (
  state: ComponentSearchState,
  hasSearchResults: boolean
) => ({
  ...state,
  hasSearchResults,
});

const getSelectedComponentIds = (state: ComponentSearchState) =>
  state.selectedComponents
    .filter(({ isSelected }) => isSelected)
    .map(({ id }) => id);

const getSelectedComponentTypesNames = (state: ComponentSearchState) => {
  const selectedComponents = areAllComponentsSelected(state)
    ? state.loadedComponents
    : state.selectedComponents.filter(({ isSelected }) => isSelected);
  return uniq(
    selectedComponents.map(({ entityType }) => entityType).filter(ExcludeFalsy)
  );
};

const isAtLeastOneComponentSelectedOfOnlyOneComponentType = (
  state: ComponentSearchState
) => {
  const selectedComponentsCount =
    componentSearchOperations.getSelectedComponentIds(state).length;
  const selectedComponentTypesCount =
    componentSearchOperations.getSelectedComponentTypesNames(state).length;
  return (
    selectedComponentTypesCount === 1 ||
    selectedComponentsCount > 0 ||
    areAllComponentsSelected(state)
  );
};

const setTotalCount = (state: ComponentSearchState, totalCount: number) => ({
  ...state,
  totalCount,
});

const setAllComponentsSelected = (
  state: ComponentSearchState,
  allComponentsSelected: boolean
): ComponentSearchState => {
  const selectedComponentsCount = state.selectedComponents.filter(
    ({ isSelected }) => isSelected
  ).length;

  const selectionClearedOnSelectAllCount =
    allComponentsSelected && selectedComponentsCount > 0
      ? selectedComponentsCount
      : null;

  let startQuery: QueryBuilderQuery | null = null;
  if (allComponentsSelected) {
    startQuery =
      state.advancedSearchState?.status === 'valid'
        ? state.advancedSearchState.query
        : advancedSearchComponentNameAndTypeAndStartSetQuery(
            state.componentName,
            state.componentType,
            state.startSetFilter
          );
  }

  return {
    ...state,
    startQuery,
    selectedComponents: [],
    selectionClearedOnSelectAllCount,
  };
};

const setStartQuery = (
  state: ComponentSearchState,
  startQuery: QueryBuilderQuery | null
) => ({
  ...state,
  startQuery,
});

const setCanSelectOnlyComponentType = (
  state: ComponentSearchState,
  { context }: SelectViewpointBuilderContextPayload
) => {
  return {
    ...state,
    canSelectOnlyComponentType: context === 'createViewpoint',
  };
};

const processMetaModel = (
  state: ComponentSearchState,
  loadMetaModelAsScopedDataState: LoadMetaModelAsScopedDataState
) => {
  if (loadMetaModelAsScopedDataState.status !== 'DATA_LOADED') return state;
  const { componentTypes } = loadMetaModelAsScopedDataState;

  return { ...state, componentTypes };
};

const setIsAdvancedSearch = (
  state: ComponentSearchState,
  isAdvancedSearch: boolean
): ComponentSearchState => ({
  ...state,
  advancedSearchState: isAdvancedSearch ? { status: 'empty' } : null,
});

const updateViewpointBuilderAdvancedSearchQuery = (
  state: ComponentSearchState,
  { isEmpty, isValid, query }: UpdateViewpointBuilderAdvancedSearchQueryPayload
): ComponentSearchState => {
  let advancedSearchState: ViewpointBuilderAdvancedSearchState = {
    status: 'invalid',
  };

  if (isEmpty) {
    advancedSearchState = {
      status: 'empty',
    };
  } else if (isValid && query) {
    advancedSearchState = {
      status: 'valid',
      query,
    };
  }

  return {
    ...state,
    loadedComponents: isEmpty ? [] : state.loadedComponents,
    advancedSearchState,
    startQuery: null,
  };
};

const applyFiltersForContextSwitcher = (
  state: ComponentSearchState,
  { startSetFilter, componentTypeName }: ApplyFiltersForContextSwitcherPayload
): ComponentSearchState => {
  return {
    ...state,
    componentName: '',
    componentType: componentTypeName,
    startSetFilter,
  };
};

export const componentSearchOperations = {
  clearComponentSearchState,
  setComponentName,
  setComponentTypeName,
  setLoadedComponents,
  setSelectedComponents,
  toggleSingleComponentSelection,
  removeUnselectedLoadedComponents,
  setIsLoading,
  setHasSearchResults,
  getSelectedComponentIds,
  getSelectedComponentTypesNames,
  isAtLeastOneComponentSelectedOfOnlyOneComponentType,
  setTotalCount,
  setAllComponentsSelected,
  setStartQuery,
  setCanSelectOnlyComponentType,
  processMetaModel,
  setIsAdvancedSearch,
  updateViewpointBuilderAdvancedSearchQuery,
  applyFiltersForContextSwitcher,
};
