import {
  perspectiveEditorFormattingOptions$,
  perspectiveEditorGroupingOptions$,
} from 'streams/perspectiveEditorData';
import { combineLatest, map } from 'rxjs';
import { dispatchAction } from '@ardoq/rxbeach';
import { showViewSidePanel } from 'views/mainApp/viewSidePanel/actions';
import { ViewSidePanelContentIds } from 'views/mainApp/viewSidePanel/types';
import { AppliedSettingsData } from '../appliedSettings/types';
import { loadedState$ } from 'loadedState/loadedState$';
import {
  getAppliedLabelFormatting,
  getAppliedConditionalFormatting,
  getAppliedGroupingRuleDescriptions,
  getContextComponentTypeSettings,
  getComponentsAndReferencesTypeSettings,
  getFilterSettings,
  getViewpointData,
} from './utils';
import { noop } from 'lodash';
import { metaModelAsScopeData$ } from 'viewpointBuilder/metaModel/loadMetaModelAsScopeData$';

export const getDefaultAppliedSettingsData = (): AppliedSettingsData => ({
  contextComponentTypeSettings: {},
  labelSettings: {
    onEditClick: noop,
    customLabelsCount: 0,
    componentFormattingLabel: undefined,
    referenceFormattingLabel: undefined,
  },
  formattingSettings: {
    onEditClick: noop,
    appliedRulesCount: 0,
    ruleDescriptionsByEntity: {},
  },
  groupingSettings: {
    onEditClick: noop,
    appliedGroupingRuleDescriptions: [],
  },
  componentAndReferenceTypesSettings: {
    onEditClick: noop,
    componentTypesCount: 0,
    referenceTypesCount: 0,
  },
  filterSettings: {
    onEditClick: noop,
    appliedFiltersCount: 0,
  },
  viewpointData: null,
});

const onLabelsRowEditClick = () => {
  dispatchAction(
    showViewSidePanel(ViewSidePanelContentIds.PERSPECTIVES_LABELS)
  );
};

const onFormattingRowEditClick = () => {
  dispatchAction(
    showViewSidePanel(ViewSidePanelContentIds.PERSPECTIVES_CONDITIONAL_FORMAT)
  );
};

const onGroupingRowEditClick = () => {
  dispatchAction(
    showViewSidePanel(ViewSidePanelContentIds.PERSPECTIVES_GROUPS)
  );
};

export const appliedSettingsData$ = combineLatest([
  perspectiveEditorFormattingOptions$,
  perspectiveEditorGroupingOptions$,
  loadedState$,
  metaModelAsScopeData$,
]).pipe(
  map(
    ([
      { appliedLabelFormatting, appliedShowReferenceType },
      groupOptions,
      loadedStates,
      metaModelAsScopeData,
    ]): AppliedSettingsData => {
      const componentTypes =
        metaModelAsScopeData.status === 'DATA_LOADED'
          ? metaModelAsScopeData.componentTypes
          : [];

      const appliedLabelSettings = getAppliedLabelFormatting({
        appliedLabelFormatting,
        appliedShowReferenceType,
      });
      return {
        componentAndReferenceTypesSettings:
          getComponentsAndReferencesTypeSettings(loadedStates),
        contextComponentTypeSettings: getContextComponentTypeSettings(
          loadedStates,
          componentTypes
        ),
        filterSettings: getFilterSettings(loadedStates),
        labelSettings: {
          onEditClick: onLabelsRowEditClick,
          ...appliedLabelSettings,
        },
        formattingSettings: {
          onEditClick: onFormattingRowEditClick,
          ...getAppliedConditionalFormatting(),
        },
        groupingSettings: {
          onEditClick: onGroupingRowEditClick,
          appliedGroupingRuleDescriptions:
            getAppliedGroupingRuleDescriptions(groupOptions),
        },
        viewpointData: getViewpointData(loadedStates),
      };
    }
  )
);
