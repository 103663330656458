import { Field as FieldType } from 'integrations/common/streams/fields/types';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { CustomIdMapping as CustomIdMappingType } from '@ardoq/api-types/integrations';
import { CustomIdFieldSelector } from './CustomIdFieldSelector';
import { ExcludeDuplicateIdsCheckbox } from './ExcludeDuplicateIdsChecbox';

type CustomIdProps = {
  tableMapping: TableMappingMap;
  customIdMapping: CustomIdMappingType;
  usedFields: FieldType[];
  allFields: FieldType[];
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
  isCreationDisabled: boolean;
};

export const CustomId = ({
  tableMapping,
  customIdMapping,
  usedFields,
  allFields,
  onMapping,
  isCreationDisabled,
}: CustomIdProps) => {
  return (
    <>
      <ExcludeDuplicateIdsCheckbox
        isDuplicatesExcluded={customIdMapping.duplicates === 'warn'}
        onMapping={onMapping}
        rowRepresentation={tableMapping.rowRepresentation}
      />
      <CustomIdFieldSelector
        usedFields={usedFields}
        allFields={allFields}
        tableMapping={tableMapping}
        value={customIdMapping.fieldName || customIdMapping.fieldLabel}
        onMapping={onMapping}
        isCreationDisabled={isCreationDisabled}
      />
    </>
  );
};
