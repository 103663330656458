import {
  APIOrganizationAttributes,
  PermissionAccessLevel,
} from '@ardoq/api-types';
import { organizationInterface } from 'modelInterface/organization/organizationInterface';
import { logError } from '@ardoq/logging';
import { trackClickedToChangeOrgLogo } from 'tracking/events/clicks';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import { trackEvent } from 'tracking/tracking';

type FormikValues = {
  organizationName: APIOrganizationAttributes['name'];
  settings: {
    defaultWsPermissions:
      | APIOrganizationAttributes['settings']['defaultWsPermissions']
      | '';
    logo?: APIOrganizationAttributes['settings']['logo'];
    // Within Formik model, emergencyDataSecurityContacts is a comma separated string of email addresses,
    //  but we send and receive it from the API as an array of email addresses
    emergencyDataSecurityContacts?: string;
  };
  hasConsentedToViewData: boolean;
};

export const mapOrgToFormikInitialValues = (
  org: APIOrganizationAttributes
): FormikValues => ({
  organizationName: org.name,
  settings: {
    ...org.settings,
    emergencyDataSecurityContacts:
      org.settings.emergencyDataSecurityContacts?.join(', ') ?? '',
  },
  hasConsentedToViewData: org.hasConsentedToViewData === true,
});

export const getSubmitOrgSettings =
  (org: APIOrganizationAttributes) =>
  async (
    values: FormikValues,
    {
      setStatus,
      setSubmitting,
    }: {
      setStatus: (status: string) => void;
      setSubmitting: (isSubmitting: boolean) => void;
    }
  ) => {
    setSubmitting(true);
    setStatus('saving...');
    trackEvent('Organization Settings: updated settings');
    const defaultWsPermissions: PermissionAccessLevel | null =
      values.settings.defaultWsPermissions === ''
        ? null
        : values.settings.defaultWsPermissions;
    const emergencyDataSecurityContacts: string[] =
      values.settings.emergencyDataSecurityContacts
        ?.split(',')
        .map(email => email.trim()) ?? [];
    const logoHasChanged = org.settings.logo !== values.settings.logo;
    if (logoHasChanged) {
      trackClickedToChangeOrgLogo(
        'Organization Settings - manage organizations'
      );
    }

    const newOrg: APIOrganizationAttributes = {
      ...org,
      name: values.organizationName,
      settings: {
        ...values.settings,
        defaultWsPermissions,
        emergencyDataSecurityContacts,
      },
      hasConsentedToViewData: values.hasConsentedToViewData,
    };

    const result = await organizationInterface.saveOrganization(newOrg);
    if (isArdoqError(result)) {
      logError(new Error('Org.save() failed'), null, {
        orgId: org._id,
        data: newOrg,
      });
      setStatus(`Save failed: ${getArdoqErrorMessage(result)}`);
      return;
    }
    setStatus('Successfully updated.');
    setSubmitting(false);
  };
