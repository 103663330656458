import { noop } from 'lodash';

interface IGraph {
  fitContent: VoidFunction;
}
const defaultGraphInterface: IGraph = {
  fitContent: noop,
};

export class GraphInterface implements IGraph {
  fitContent() {}

  registerInterface(graphInterface: IGraph) {
    Object.assign(this, graphInterface);
  }

  clearInterface() {
    Object.assign(this, defaultGraphInterface);
  }
}

export const createGraphInterface = () => new GraphInterface();
