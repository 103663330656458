export const DOCUMENT_PICKER_URL = 'https://lucid.app/documents/picker';
export const DOCUMENT_PICKER_ORIGIN = 'https://lucid.app';
export const LUCID_CHART_URL = 'https://lucid.app/lucidchart';
export const EMBED_VIEWER_URL = 'https://lucid.app/embeds/link';

export const LUCIDCHART_INVALID_SESSION = 'lucidchart_session_invalid';

export const MAX_AUTH_ATTEMPTS = 3;

export const ANALYTIC_EVENTS = {
  OPENED_DOCUMENT_PICKER: 'Lucidchart0 opened document picker',
  SELECTED_DOCUMENT: 'Lucidchart0 selected document',
  CANCELLED_DOCUMENT_PICKER: 'Lucidchart0 cancelled document picker',
  OPENED_EMBED_VIEWER: 'Lucidchart0 opened embed viewer',
  SUCCESS_LINK_DOCUMENT: 'Lucidchart0 linked document successfully',
  FAILED_LINK_DOCUMENT: 'Lucidchart0 failed to link document',
  STARTED_EXPORT_LUCIDCHART_DOCUMENT: 'Lucidchart0 started to export document',
  FAILED_EXPORT_LUCIDCHART_DOCUMENT: 'Lucidchart0 failed to export document',
  SUCCESS_EXPORT_LUCIDCHART_DOCUMENT:
    'Lucidchart0 exported document successfully',
} as const;

export enum EXPORT_LUCIDCHART_DOCUMENT_WARNINGS {
  UNSUPPORTED_FILE_TYPE = 'unsupported-file-type',
  DOWNLOAD_FAILED = 'download-failed',
}
