import {
  APIOrganizationUser,
  APIRoleAttributes,
  ArdoqId,
  OrgAccessLevel,
  Privilege,
  PrivilegeLabel,
  PrivilegesByUser,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { difference, isEqual } from 'lodash';
import { _userHasPrivilege } from './reducerUtils';
import { PrivilegeAction, PrivilegesStreamShape } from './types';
import { ChangedUserIdsByPrivileges } from 'roles/confirmRoleChangesDialog';

export const getNoninheritedPrivileges = (
  privilegesByUser: PrivilegesByUser,
  userId: ArdoqId
) => {
  const userPrivileges = privilegesByUser[userId] ?? [];
  return userPrivileges
    .filter(({ inheritedFromRole }) => !inheritedFromRole)
    .map(({ label }) => label);
};

export const userHasPrivilege = _userHasPrivilege;

export const arePrivilegesEqual = (
  privilegesA: string[],
  privilegesB: string[]
) => {
  return isEqual(new Set(privilegesA), new Set(privilegesB));
};

export const getNumberOfUsersWithPrivilege = (
  privilegesByUser: PrivilegesByUser,
  privilegeLabel: PrivilegeLabel
) => {
  return Object.values(privilegesByUser)
    .filter(ExcludeFalsy)
    .filter(privileges =>
      privileges.some(({ label }) => label === privilegeLabel)
    ).length;
};

export const privilegeIsRestrictedFromRole = (
  privilegeLabel: PrivilegeLabel,
  role: OrgAccessLevel,
  configurablePrivileges: Privilege[]
): boolean => {
  const privilege = configurablePrivileges.find(
    priv => priv.label === privilegeLabel
  );

  return Boolean(
    privilege &&
      privilege['restricted-roles'] &&
      privilege['restricted-roles'].includes(role)
  );
};

const groupUsersByPrivileges =
  (privilegeRecord: PrivilegesStreamShape['userIdsByPrivilege']) =>
  ([userId, privileges]: [ArdoqId, Privilege[] | undefined]) => {
    privileges?.forEach(({ label }) => {
      if (!privilegeRecord[label]) {
        privilegeRecord[label] = [userId];
      } else {
        privilegeRecord[label].push(userId);
      }
    });
  };

export const getUsersIdsByPrivilege = (
  privilegesByUser: PrivilegesByUser
): PrivilegesStreamShape['userIdsByPrivilege'] => {
  const privilegeRecord: PrivilegesStreamShape['userIdsByPrivilege'] = {};
  Object.entries(privilegesByUser).forEach(
    groupUsersByPrivileges(privilegeRecord)
  );
  return privilegeRecord;
};

const getNewPrivilegeUsers = ({
  currentUsersWithPrivilegeAccess = [],
  isEnabledPrivilegesForRole = false,
  userWithTheRole = [],
}: {
  currentUsersWithPrivilegeAccess?: ArdoqId[];
  userWithTheRole?: APIOrganizationUser[];
  isEnabledPrivilegesForRole?: boolean;
}): ArdoqId[] | undefined => {
  let newUsers: ArdoqId[] | undefined;
  if (!isEnabledPrivilegesForRole) {
    newUsers =
      difference(
        userWithTheRole.map(user => user._id),
        currentUsersWithPrivilegeAccess
      ) || [];
  }
  return newUsers;
};

const getUserByRole = (
  users: APIOrganizationUser[] = [],
  role?: OrgAccessLevel
) => {
  return !role ? [] : users.filter(user => user.role === role);
};

export const getPrivilegeDescription = (
  privileges: Privilege[],
  privilegeLabel: PrivilegeLabel
) =>
  privileges.find(privilege => privilege.label === privilegeLabel)?.description;

const getPrivilegeChanges = ({
  privilegeLabel,
  newRole,
  previousRole,
  orgMembers,
  privilegesByUser,
  userIdsByPrivilege,
}: Pick<PrivilegesStreamShape, 'privilegesByUser' | 'userIdsByPrivilege'> & {
  privilegeLabel: PrivilegeLabel;
  newRole: APIRoleAttributes;
  previousRole: APIRoleAttributes;
  orgMembers: APIOrganizationUser[];
}) => {
  const currentUsersWhoHaveAccess = userIdsByPrivilege?.[privilegeLabel];
  const existsInNewRolePrivileges = newRole.privileges.some(
    p => p === privilegeLabel
  );
  const existsInOldRolePrivileges = previousRole.privileges.some(
    p => p === privilegeLabel
  );
  const isNotChanged =
    (existsInNewRolePrivileges && existsInOldRolePrivileges) ||
    !(existsInNewRolePrivileges || existsInOldRolePrivileges);

  if (isNotChanged) {
    return undefined;
  }

  if (existsInNewRolePrivileges) {
    const newUsers = getNewPrivilegeUsers({
      currentUsersWithPrivilegeAccess: currentUsersWhoHaveAccess,
      userWithTheRole: getUserByRole(orgMembers, newRole.label),
      isEnabledPrivilegesForRole: previousRole.privileges.some(
        enabledPrivilege => privilegeLabel === enabledPrivilege
      ),
    });
    return { users: newUsers, action: PrivilegeAction.ASSIGN };
  }

  const revokedUsers = getUserByRole(orgMembers, newRole.label)?.filter(
    ({ _id: userId }) =>
      privilegesByUser[userId]?.find(
        privilege =>
          privilege.label === privilegeLabel && privilege.inheritedFromRole
      )
  );
  return { users: revokedUsers, action: PrivilegeAction.REMOVE };
};

export const getUserByPrivilegesChanges = ({
  configurablePrivileges,
  ...rest
}: PrivilegesStreamShape & {
  orgMembers: APIOrganizationUser[];
  newRole: APIRoleAttributes;
  previousRole: APIRoleAttributes;
}): ChangedUserIdsByPrivileges =>
  Object.fromEntries(
    configurablePrivileges.map(({ label }) => [
      label,
      getPrivilegeChanges({
        privilegeLabel: label,
        ...rest,
      }),
    ])
  );
