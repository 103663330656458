import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { startUpsertConnectionModal } from '../../modals/UpserConnectionModal';
import {
  updateConnectionStatuses,
  upsertConnectionInitiate,
} from 'integrations/common/streams/connections/actions';
import { filter, tap } from 'rxjs/operators';

const handleUpsertConnectionModal = routine(
  ofType(upsertConnectionInitiate),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'aws-v3'),
  tap(({ integrationId, connection }) => {
    startUpsertConnectionModal(connection?._id).then(() => {
      dispatchAction(
        updateConnectionStatuses({
          integrationId: integrationId,
          statuses: { upsert: { status: 'INIT' } },
        })
      );
    });
  })
);

export default [handleUpsertConnectionModal];
