import {
  MetaModelComponentType,
  Zone,
  ZoneConfiguredComponentType,
  ZoneConfiguredComponentTypeField,
} from '@ardoq/api-types';
import { ZoneComponentTypesFieldsCounter } from './ZoneComponentTypesFieldsCounter';
import { MultiSelectCheckbox } from '@ardoq/select';
import { zonesOperations } from '../operations';
import { ComponentDecorator } from '@ardoq/decorators';
import { ZoneCommands } from '../types';
import { GlobalFieldsByName } from 'streams/fields/globalFields$';
import { FormSize } from '@ardoq/forms';
import { Box, Stack } from '@ardoq/layout';
import { ComponentTypeFieldsGroupSearchParams } from '../zonesViewState$/types';

export const getComponentTypesTableDataSource = (
  componentTypes: MetaModelComponentType[],
  zoneConfiguredComponentType: ZoneConfiguredComponentType[],
  componentTypeFieldsGroupSearch: ComponentTypeFieldsGroupSearchParams
) => {
  return componentTypes.map(componentType => {
    const configuredComponentType = zoneConfiguredComponentType.find(
      t => t.componentTypeName === componentType.name
    );
    const fields = componentType.fieldNames.map(field => ({
      name: field,
      enabled:
        configuredComponentType?.fields.find(f => f.name === field)?.enabled ??
        true,
    }));

    const filteredFields = fields.filter(fieldName =>
      zonesOperations.fieldMatchesSearch(
        fieldName.name,
        componentTypeFieldsGroupSearch
      )
    );

    return {
      componentTypeName: componentType.name,
      shownFields: filteredFields,
      allFields: fields,
      typeStyle: componentType.style,
    };
  });
};

export const getComponentTypesTableColumns = ({
  zone,
  globalFieldsByName,
  commands,
}: {
  zone: Zone;
  globalFieldsByName: GlobalFieldsByName;
  commands: ZoneCommands;
}) => [
  {
    title: 'Component type',
    valueRender: (
      _: ZoneConfiguredComponentTypeField[],
      {
        componentTypeName,
        typeStyle,
      }: {
        componentTypeName: string;
        typeStyle: MetaModelComponentType['style'];
      }
    ) => (
      <ComponentDecorator
        label={componentTypeName}
        iconId={typeStyle?.icon as string}
        color={typeStyle?.color as string}
        imageSrc={typeStyle?.image as string}
      />
    ),
  },
  {
    dataIndex: 'shownFields',
    title: 'Fields',
    valueRender: (
      shownFields: ZoneConfiguredComponentTypeField[],
      {
        componentTypeName,
        allFields,
      }: {
        componentTypeName: string;
        allFields: ZoneConfiguredComponentTypeField[];
      }
    ) => (
      <Box paddingY="small">
        <Stack gap="xxsmall" align="end">
          <ZoneComponentTypesFieldsCounter
            enabledFields={allFields.filter(f => f.enabled).length}
            totalFields={allFields.length}
          />
          <MultiSelectCheckbox
            isClearable={false}
            formSize={FormSize.FULL}
            options={zonesOperations.mapComponentTypeFieldsToGroupedSelectOptions(
              shownFields,
              globalFieldsByName
            )}
            value={shownFields
              .filter(field => field.enabled)
              .map(field => field.name)}
            onValueChange={selectedFields =>
              selectedFields &&
              commands.changeFieldsConfigInComponentTypeInZone({
                zone,
                componentTypeName,
                enabledFields: selectedFields,
                allFields,
                shownFields,
              })
            }
          />
        </Stack>
      </Box>
    ),
  },
];
