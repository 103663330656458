import { switchMap } from 'rxjs/operators';
import {
  actionCreator,
  collectRoutines,
  dispatchAction,
  persistentReducedStream,
  reducer,
  routine,
  extractPayload,
  ofType,
  streamReducer,
} from '@ardoq/rxbeach';
import { logError } from '@ardoq/logging';
import currentUser$, {
  CurrentUserState,
} from 'streams/currentUser/currentUser$';
import workspaces$ from 'streams/workspaces/workspaces$';
import { alert } from '@ardoq/modal';
import {
  getArdoqErrorMessage,
  isArdoqError,
  pluralize,
} from '@ardoq/common-helpers';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { combineLatest } from 'rxjs';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { ExtractStreamShape } from 'tabview/types';
import { workspaceApi } from '@ardoq/api';

export interface CopyWsStreamState {
  organizations: { label: string; name: string }[];
  workspaces: { id: string; name: string }[];
  success: boolean;
  error?: string;
}

export type TargetOrg = {
  label: string;
  value: string;
};

type CopyRequest = {
  selectedWsIds: Set<string>;
  targetOrg: TargetOrg;
};

export const dialogTitle = 'Copy workspaces to organization';

export const copyWorkspacesToOrg = actionCreator<CopyRequest>(
  '[copy to org] DO_COPY'
);

export const copySuccess = actionCreator('[copy to org] COPY_SUCCESS');

export const copyError = actionCreator<string>('[copy to org] COPY_FAIL');

export const resetCopyWs = actionCreator('[copy to org] RESET');

const handleCopyWorkspaces = routine(
  ofType(copyWorkspacesToOrg),
  extractPayload(),
  switchMap(async ({ selectedWsIds, targetOrg }) => {
    const response = await workspaceApi.copyToOrg(targetOrg.value, [
      ...selectedWsIds,
    ]);
    if (isArdoqError(response)) {
      dispatchAction(copyError(getArdoqErrorMessage(response)));
      logError(response, 'Copy workspaces to organization failed');
      return;
    }
    dispatchAction(copySuccess());
    dispatchAction(resetCopyWs());
    const copiedWorkspacesCount = response.data.workspaces.length;
    alert({
      title: dialogTitle,
      text: `Successfully copied ${copiedWorkspacesCount} ${pluralize(
        'workspace',
        copiedWorkspacesCount
      )}
      to ${targetOrg.label}`,
    });
  })
);

const startState: CopyWsStreamState = {
  organizations: [],
  workspaces: [],
  success: false,
};

const copySuccessReducer = (state: CopyWsStreamState) => ({
  ...state,
  success: true,
});

const resetReducer = (state: CopyWsStreamState): CopyWsStreamState => ({
  ...state,
  success: false,
  error: undefined,
});

const copyErrorReducer = (
  state: CopyWsStreamState,
  error: string
): CopyWsStreamState => ({
  ...state,
  error,
});

const workspacesAndPermissionContext$ = combineLatest([
  workspaces$,
  currentUserPermissionContext$,
]);
const workspacesReducer = (
  state: CopyWsStreamState,
  [workspaces, permissionContext]: ExtractStreamShape<
    typeof workspacesAndPermissionContext$
  >
) => ({
  ...state,
  workspaces: Object.values(workspaces.byId)
    .filter(({ _id }) =>
      workspaceAccessControlInterface.canCopyWorkspace(permissionContext, _id)
    )
    .map(({ _id: id, name }) => ({
      id,
      name,
    })),
});

const currentUserReducer = (
  state: CopyWsStreamState,
  user: CurrentUserState
): CopyWsStreamState => ({
  ...state,
  organizations:
    // e2e tests for presentations are failing in this place,
    // it's probably when we test presentation user is not loggedIn
    // tests were still passing on old ardoq-front repo due to
    // outdated jest, but on monorepo we are running new jest
    // which is catching the error. We should check if the error
    // is happening on production presntation
    user.organizations?.filter(
      (org: { label: any; role: string }) =>
        org.label !== user.organization?.label &&
        ['admin', 'writer'].includes(org.role)
    ) || [],
});

export const copyws$ = persistentReducedStream('copyws$', startState, [
  streamReducer(currentUser$, currentUserReducer),
  streamReducer(workspacesAndPermissionContext$, workspacesReducer),
  reducer(copySuccess, copySuccessReducer),
  reducer(copyError, copyErrorReducer),
  reducer(resetCopyWs, resetReducer),
]);

export default collectRoutines(handleCopyWorkspaces);
