import {
  collectRoutines,
  dispatchAction,
  routine,
  carry,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  fetchWidgetDataSources,
  saveDashboard,
  setAvailableDataSources,
  setLoadingDataSources,
  setDashboardApiError,
  setDashboardLoadingState,
  setValidationErrors,
  setWidgetError,
  setWidgetPreview,
  getWidgetPreview,
  setDashboardWarnings,
} from './actions';
import { filter, switchMap, tap } from 'rxjs';
import { LoadingState } from './types';
import { sortBy } from 'lodash';
import { trackEvent } from '../../tracking/tracking';
import {
  DashboardTrackingEventsNames,
  widgetOperations,
} from '@ardoq/dashboard';
import { formatChartWidgetForSave, isDashboardValid } from './utils';
import {
  APITableWidgetAttributes,
  DataSourceField,
  ResourceType,
} from '@ardoq/api-types';
import {
  dateRangeOperations,
  getDateRangeType,
  isDateRangeFieldType,
} from '@ardoq/date-range';
import {
  ExcludeFalsy,
  getArdoqErrorMessage,
  getArdoqErrorTraceId,
  isArdoqError,
} from '@ardoq/common-helpers';
import { map, withLatestFrom } from 'rxjs/operators';
import dashboardBuilder$ from './DashboardBuilder$';
import reports$ from 'streams/reports/reports$';
import { prepareDataForSave } from './saveDashboardUtils';
import currentUser$ from 'streams/currentUser/currentUser$';
import { addPermissionForResource } from '../../streams/currentUserPermissions/actions';
import { showToast, ToastType } from '@ardoq/status-ui';
import { navigateToDashboardModule } from '../../router/navigationActions';
import { DashboardModule } from '../types';
import {
  api,
  dashboardApi,
  datasourceApi,
  getErrorStatusCode,
  handleError,
  permissionApi,
} from '@ardoq/api';
import { logError } from '@ardoq/logging';

const mergeDateRangeFieldsForDataSource = (fields: DataSourceField[]) => {
  const dateRangePairs = fields.reduce<
    Record<string, { start?: DataSourceField; end?: DataSourceField }>
  >((pairs, field) => {
    if (dateRangeOperations.isPartOfDateRangeField(field)) {
      const dateRangeFieldName = dateRangeOperations.extractDateRangeFieldName(
        field.name
      );
      return {
        ...pairs,
        [dateRangeFieldName]: {
          ...pairs[dateRangeFieldName],
          [dateRangeOperations.fieldNameIsDateRangeStartField(field.name)
            ? 'start'
            : 'end']: field,
        },
      };
    }
    return pairs;
  }, {});

  return fields
    .map(field => {
      if (!dateRangeOperations.isPartOfDateRangeField(field)) return field;
      const dateRangeFieldName = dateRangeOperations.extractDateRangeFieldName(
        field.name
      );
      const { start, end } = dateRangePairs[dateRangeFieldName] ?? {};
      if (
        start &&
        end &&
        dateRangeOperations.fieldNameIsDateRangeStartField(field.name)
      ) {
        return {
          ...field,
          type: getDateRangeType(field.type),
          name: dateRangeFieldName,
          label: dateRangeOperations.extractDateRangeFieldLabel(field.label),
        };
      }
      return null;
    })
    .filter(ExcludeFalsy);
};

const handleFetchWidgetDataSources = routine(
  ofType(fetchWidgetDataSources),
  tap(() => dispatchAction(setLoadingDataSources(true))),
  switchMap(() => datasourceApi.fetchAll()),
  handleError(error => {
    trackEvent(DashboardTrackingEventsNames.FETCH_WIDGET_DATA_SOURCES_ERROR);
    api.logErrorIfNeeded(error);

    dispatchAction(setLoadingDataSources(false));

    // If there's more info available, show it
    dispatchAction(
      setDashboardApiError({
        apiError: {
          message: getArdoqErrorMessage(error),
          traceId: getArdoqErrorTraceId(error),
          code: getErrorStatusCode(error),
        },
        apiLoadingState: LoadingState.FETCH_DATA_SOURCES_ERROR,
      })
    );
  }),
  withLatestFrom(reports$),
  tap(([availableDataSources, reports]) => {
    const dataSourcesWithMergedDateRangeFields = availableDataSources.map(
      dataSource => {
        const sourceReport = reports.byId[dataSource.sourceId];
        if (sourceReport) {
          // add error to available datasources from corresponding report
          dataSource.error = sourceReport.error;
        }
        if (!dataSource.availableFields) return dataSource;
        const fieldWithDateRangesMerged = mergeDateRangeFieldsForDataSource(
          dataSource.availableFields
        )
          .filter(
            ({ field, custom, referenceTypeOutgoing, referenceTypeIncoming }) =>
              field || custom || referenceTypeOutgoing || referenceTypeIncoming
          )
          .sort((fieldA, fieldB) =>
            (fieldA.label || fieldA.name) > (fieldB.label || fieldA.name)
              ? 1
              : -1
          );
        return {
          ...dataSource,
          availableFields: fieldWithDateRangesMerged,
        };
      }
    );

    dispatchAction(
      setAvailableDataSources(
        sortBy(dataSourcesWithMergedDateRangeFields, 'name')
      )
    );

    dispatchAction(setLoadingDataSources(false));

    // Dashboard warnings depend on data sources to be loaded
    dispatchAction(setDashboardWarnings());
  })
);

const mergeDateRangeFieldsInPreviewResponse = (
  response: APITableWidgetAttributes,
  selectedFields: DataSourceField[]
) => {
  if (
    !selectedFields.length ||
    selectedFields.every(field => !isDateRangeFieldType(field.type)) ||
    !response.scopeData
  )
    return response;
  const mergedTableFields = response.tableFields.reduce<
    APITableWidgetAttributes['tableFields']
  >((mergedFields, tableField) => {
    if (
      !dateRangeOperations.fieldNameIsPartOfDateRangeField(tableField.name) ||
      !selectedFields.find(
        field =>
          field.name ===
          dateRangeOperations.extractDateRangeFieldName(tableField.name)
      )
    )
      return [...mergedFields, tableField];
    else if (
      dateRangeOperations.fieldNameIsDateRangeStartField(tableField.name)
    ) {
      return [
        ...mergedFields,
        {
          ...tableField,
          label: dateRangeOperations.extractDateRangeFieldLabel(
            tableField.label
          ),
          name: dateRangeOperations.extractDateRangeFieldName(tableField.name),
        },
      ];
    }
    return mergedFields;
  }, []);
  const scopeDataWithMergedFields =
    dateRangeOperations.getMergedDateRangeFieldForScopeData(response.scopeData);
  const mergedResults =
    response.data?.map(entity =>
      dateRangeOperations.mergeDateRangeFieldsForEntity(
        entity,
        scopeDataWithMergedFields.dateRangeFieldMap
      )
    ) ?? null;
  return {
    ...response,
    results: mergedResults,
    tableFields: mergedTableFields,
    scopeData: scopeDataWithMergedFields,
  };
};

// Builder only
const handleGetWidgetPreview = routine(
  ofType(getWidgetPreview),
  extractPayload(),
  carry(
    switchMap(({ widgetData, availableFields }) =>
      dashboardApi.widgetPreview({
        ...formatChartWidgetForSave(widgetData, availableFields),
        _id: widgetData._id, // formatChartWidgetForSave removes id for unsaved widgets, but for the preview we need id in case there are errors
      })
    )
  ),
  tap(([{ widgetData, availableFields }, response]) => {
    if (isArdoqError(response)) {
      trackEvent(DashboardTrackingEventsNames.WIDGET_PREVIEW_ERROR);
      api.logErrorIfNeeded(response);
      dispatchAction(
        setWidgetError({
          _id: widgetData._id,
          error: getArdoqErrorMessage(response),
        })
      );
      return;
    }
    if (!response) return;
    const selectedFields = availableFields.filter(field =>
      widgetData.fields.includes(field.name)
    );
    dispatchAction(
      setWidgetPreview({
        ...(widgetOperations.isReadTableWidgetData(response)
          ? mergeDateRangeFieldsInPreviewResponse(response, selectedFields)
          : response),
        _id: widgetData._id,
      })
    );
  })
);

// Builder only
const handleSaveDashboard = routine(
  ofType(saveDashboard),
  extractPayload(),
  carry(
    map(dashboard => {
      const isValid = isDashboardValid(dashboard);
      if (!isValid) {
        dispatchAction(setValidationErrors());
        dispatchAction(setDashboardLoadingState(null));
      }
      return isValid;
    })
  ),
  filter(([_, isValid]) => isValid),
  withLatestFrom(dashboardBuilder$),
  switchMap(([[dashboard, _isValid], { availableDataSources }]) => {
    dispatchAction(setDashboardLoadingState(LoadingState.SAVING));
    const data = prepareDataForSave(dashboard, availableDataSources);
    if (dashboard._id) {
      return dashboardApi.update(dashboard._id, data);
    }
    return dashboardApi.create(data);
  }),
  handleError(error => {
    trackEvent(DashboardTrackingEventsNames.DASHBOARD_ERROR);
    api.logErrorIfNeeded(error);
    dispatchAction(
      setDashboardApiError({
        apiError: {
          message: getArdoqErrorMessage(error),
          traceId: getArdoqErrorTraceId(error),
          code: getErrorStatusCode(error),
        },
        apiLoadingState: LoadingState.SAVING_ERROR,
      })
    );
  }),
  carry(
    switchMap(response =>
      permissionApi.getResourcePermission({
        resourceType: ResourceType.DASHBOARD,
        resourceId: response._id,
      })
    )
  ),
  withLatestFrom(currentUser$),
  tap(([[response, permission], currentUser]) => {
    if (isArdoqError(permission)) {
      logError(permission);
      return;
    }

    dispatchAction(addPermissionForResource({ permission, currentUser }));
    showToast('Your dashboard has been saved', ToastType.SUCCESS);
    dispatchAction(
      navigateToDashboardModule({
        selectedDashboardId: response._id,
        dashboardModule: DashboardModule.BUILDER,
        loadFromCache: false,
      })
    );
  })
);

export default collectRoutines(
  handleFetchWidgetDataSources,
  handleSaveDashboard,
  handleGetWidgetPreview
);
