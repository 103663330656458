import { Box, FlexBox } from '@ardoq/layout';
import { CreateNewButton, Island } from '@ardoq/page-layout';
import { EmptyState, EmptyStateNoResultIllustration } from '@ardoq/status-ui';
import { Icon, IconName } from '@ardoq/icons';
import { PermissionGroupsProps } from '../../types';

export const NoGroups = (props: PermissionGroupsProps) => {
  const { commands, permissionGroups } = props;
  return (
    <Island fullHeight>
      <Box padding="xxlarge" height="full">
        <FlexBox justify="center" align="center" height="full">
          <EmptyState
            title="Get started with groups"
            size="large"
            description="Groups let you manage permissions and share assets with ease. Create your first group to start organizing users and simplifying collaboration."
            media={<EmptyStateNoResultIllustration />}
          >
            <CreateNewButton
              onClick={() => commands.showCreateGroupModal(permissionGroups)}
            >
              Create group
              <Icon iconName={IconName.ADD} />
            </CreateNewButton>
          </EmptyState>
        </FlexBox>
      </Box>
    </Island>
  );
};
