import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import { SurveyAdminStreamState } from './types';
import { SurveyAdminMode } from 'surveyAdmin/navigation/types';

export const defaultState: SurveyAdminStreamState = {
  mode: SurveyAdminMode.OVERVIEW,
  surveyId: null,
  folderId: null,
  surveyOverviewSearchParams: undefined,
  isSaving: false,
  changesSaved: false,
  surveysWithPendingApprovals: {},
  surveyValidation: {},
  pendingApprovalFetchingStatus: 'idle',
  surveyValidationFetchingStatus: 'idle',
  surveySettings: {
    isSidebarOpen: false,
    organizationLogo: undefined,
    isOrgLogoValid: true,
    hasChanges: false,
    status: 'idle',
  },
};

const surveyAdmin$ = persistentReducedStream(
  'surveyAdmin$',
  defaultState,
  reducers
);

export default surveyAdmin$;
