import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import {
  selectResource,
  unselectResource,
} from 'integrations/unified/streams/resources/actions';
import { getResourcesStream } from 'integrations/unified/streams/resources/resources$';
import { Resource } from 'integrations/unified/streams/resources/types';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { dispatchAction } from '@ardoq/rxbeach';
import { combineLatest, map, of, switchMap, filter } from 'rxjs';
import {
  TablesSelectionBaseTable,
  TablesSelectionTablePayload,
} from '@ardoq/integrations';

const formatTables = (resources: Resource[]): TablesSelectionTablePayload[] => {
  return resources.map(resource => ({
    label: resource.displayText,
    name: resource.id,
    id: resource.id,
    canRead: true,
    canWrite: false,
    canDelete: false,
    canCreate: false,
  }));
};

export const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      resources: getResourcesStream(integrationId),
    });
  }),
  map(({ integrationId, resources }) => {
    return {
      tables: formatTables(Object.values(resources.resources)),
      selectedTable: resources.focusedResource,
      selectedTables: resources.selectedResources,
      getTablesRequestStatus: resources.requests.getResources.status,
      onSelectTable: (table: TablesSelectionBaseTable) => {
        dispatchAction(selectResource({ resourceId: table.id, integrationId }));
      },
      onUnselectTable: (table: TablesSelectionBaseTable) => {
        dispatchAction(
          unselectResource({ resourceId: table.id, integrationId })
        );
      },
    };
  })
);
