import { Link, LinkProps, Paragraph } from '@ardoq/typography';
import { Box, Stack } from '@ardoq/layout';

type RelevantLinksProps = {
  links: LinkProps[];
};

const RelevantLinks = ({ links }: RelevantLinksProps) => {
  if (links.length === 0) {
    return null;
  }

  return (
    <Box>
      <Paragraph variant="caption" color="textSubtle">
        Related links
      </Paragraph>
      <Stack gap="xxsmall">
        {links.map(({ title, onClick }) => (
          <Link typography="text2" onClick={onClick} key={title}>
            {title}
          </Link>
        ))}
      </Stack>
    </Box>
  );
};

export default RelevantLinks;
