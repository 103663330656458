import { SetDrawerDataPayload } from './actions';
import { DetailsDrawerState } from './detailsDrawer$';

const setDrawerData = (
  _state: DetailsDrawerState,
  { scopeData, componentId }: SetDrawerDataPayload
): DetailsDrawerState => {
  return { scopeData, componentId };
};

export const detailsDrawerOperations = {
  setDrawerData,
};
