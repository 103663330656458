import {
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  rejectedNavigation,
  requestShowAppModule,
  showAppModule,
} from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { setContextToEntity } from './actions';
import { tap } from 'rxjs/operators';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { contextInterface } from 'modelInterface/contextInterface';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';

const handleSetContextToEntity = routine(
  ofType(setContextToEntity),
  extractPayload(),
  tap(async ({ id, workspaceId }) => {
    const navigation = await dispatchActionAndWaitForResponse(
      requestShowAppModule({ selectedModule: AppModules.WORKSPACES }),
      showAppModule,
      rejectedNavigation
    );
    if (navigation.type === rejectedNavigation.type) {
      return;
    }
    await contextInterface.loadWorkspaces({
      contextWorkspaceId: workspaceId,
      workspaceIds: [],
      resetComponentContext: false,
      trackingLocation: 'search',
    });

    if (componentInterface.isComponent(id))
      contextInterface.setComponentById(id);
    if (referenceInterface.isReference(id))
      contextInterface.setReferenceById(id);
    if (workspaceInterface.workspaceExists(id)) {
      contextInterface.unsetComponent();
      contextInterface.unsetReference();
    }
  })
);

export const searchUtilRoutines = collectRoutines(handleSetContextToEntity);
