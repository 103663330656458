import { showAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { logError } from '@ardoq/logging';
import { openPresentationEditor } from 'presentationEditor/presentationUtil';
import { dispatchAction } from '@ardoq/rxbeach';
import { SalesDemoStep, StepDetails } from './types';
import presentations$ from 'streams/presentations/presentations$';

const { INTRO, STEP_2 } = SalesDemoStep;

const findPresentationIdByName = (name: string) => {
  const presentation = presentations$.state.list.find(
    presentation => presentation.name === name
  );
  return presentation ? presentation._id : null;
};

/** Name of the presentation that should be loaded for step 1. Error logged if not found in org. */
const STEP_1_PRESENTATION_NAME = 'Ardoq Product Introduction';

/** Each array el represents one Intercom tour that sales can initiate from AppMainSidebar menu */
export const SALES_DEMO_TOUR_STEPS = [INTRO, STEP_2];

export const TOUR_STEP_DETAILS: Record<SalesDemoStep, StepDetails> = {
  [INTRO]: {
    menuLabel: 'Start tour',
    tourId: 168190,
    trackingLabel: 'start-sales-demo-tour',
    preTourAction: () => {
      const presentationId = findPresentationIdByName(STEP_1_PRESENTATION_NAME);
      if (!presentationId)
        logError(
          new Error(
            `Can't find presentation with name "${STEP_1_PRESENTATION_NAME}"`
          )
        );
      if (presentationId) openPresentationEditor({ presentationId });
    },
  },
  [STEP_2]: {
    menuLabel: 'Continue tour',
    trackingLabel: 'continue-sales-demo-tour',
    tourId: 168247,
    preTourAction: () =>
      dispatchAction(showAppModule({ selectedModule: AppModules.HOME })),
  },
};
