import { TooltipManager } from '@ardoq/tooltip';
import AppModuleController from './AppModuleController';
import { ConnectedAppMainSidebar } from 'components/AppMainSidebar/AppMainSidebar';
import { GridEditorPortalTarget as GridEditor2023PortalTarget } from 'gridEditor2023/DockedGridEditorLayoutContainer';
import FeatureHighlightManager from 'featureHighlight/FeatureHighlightManager';
import GlobalContextMenus from 'contextMenus/GlobalContextMenus';
import { FullscreenManager } from '@ardoq/fullscreen-manager';
import PopoverManagerWithContext from 'components/PopoverManagerWithContext/PopoverManagerWithContext';
import { PopoverManagerContext } from 'components/PopoverManagerWithContext/popoverManagerContext$';
import { StackPageManager } from '@ardoq/stack-page-manager';
import { ToastManager } from '@ardoq/status-ui';
import { Features, hasFeature } from '@ardoq/features';
import styled, { StyleSheetManager } from 'styled-components';
import BestPracticeAssistantManager from 'bestPracticeAssistant/BestPracticeAssistantManager';
import ViewInferenceManager from 'traversals/viewInference/ViewInferenceManager';
import { AppContainerGlobalStyles } from './AppContainerGlobalStyles';
import NavBar from 'views/NavBar';

const AppContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

function AppContainer() {
  return (
    <StyleSheetManager>
      <NavBar />
      {/* The id appContentContainer is used by e2e tests */}
      <AppContentContainer id="appContentContainer">
        <AppContainerGlobalStyles />
        <ConnectedAppMainSidebar />
        <AppModuleController />
        <FullscreenManager isActiveInFullscreen={false}>
          <StackPageManager />
          <ToastManager />
          <PopoverManagerWithContext context={PopoverManagerContext.DEFAULT} />
          <TooltipManager />
        </FullscreenManager>
        <GlobalContextMenus />
        <FeatureHighlightManager />

        <GridEditor2023PortalTarget />

        {hasFeature(Features.AZURE_OPENAI_BEST_PRACTICE_ASSISTANT) && (
          <BestPracticeAssistantManager />
        )}

        {hasFeature(Features.AZURE_OPENAI_VIEW_INFERENCE) && (
          <ViewInferenceManager />
        )}
      </AppContentContainer>
    </StyleSheetManager>
  );
}

export default AppContainer;
