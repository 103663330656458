import { dispatchAction } from '@ardoq/rxbeach';
import { RadioGroupValue } from '@ardoq/forms';
import { SubdivisionEditorSteps } from '../../navigation/types';
import {
  touchStep,
  updateSubdivision,
} from '../../subdivisionEditorViewModel$/actions';
import {
  SubdivisionViewModelState,
  ValidationErrors,
} from 'subdivisionEditor/types';
import { bindWorkspacesOperations } from '../BindWorkspaces/bindWorkspacesOperations';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';
import { bindWorkspacesCommands } from '../BindWorkspaces/commands';
import {
  ArdoqId,
  isSubdivisionComponentAssignmentsMode,
  SubdivisionComponentAssignmentsMode,
} from '@ardoq/api-types';
import { dispatchActionAndWaitForResponse } from 'actions/utils';

import {
  removeComponentsFromSubdivision as removeComponentsFromSubdivisionAction,
  removeComponentsFromSubdivisionFailure,
  removeComponentsFromSubdivisionSuccess,
} from 'streams/subdivisions/actions';
import {
  addComponentsToSubdivisionSuccess,
  addComponentsToSubdivisionFailure,
  addComponentsToSubdivisionAction,
  advancedSearchChange,
  setStagedComponentToBeRemoved,
  setSelectedComponentsToAdd,
} from './streams/actions';
import { subdivisionsOperations } from '@ardoq/subdivisions';
import { AdvancedSearchOnChangePayload } from 'search/AdvancedSearch/AdvancedSearchQueryBuilder';
import { searchAdvancedSearch } from './utils';
import { commands as detailsCommands } from '../DetailsPage/commands';
import {
  DIVISIONS_USER_EVENTS,
  trackDivisionEditorUserEvent,
} from '../../trackingUtils';
import { debounce } from 'lodash';

const setComponentSelectionMode = (selectionMode: RadioGroupValue) => {
  const isValidMode =
    !selectionMode || !isSubdivisionComponentAssignmentsMode(selectionMode);
  const payload = isValidMode
    ? SubdivisionComponentAssignmentsMode.MANUAL
    : selectionMode;

  trackDivisionEditorUserEvent(
    DIVISIONS_USER_EVENTS.COMPONENTS_SELECTION_MODE,
    {
      mode: payload,
    }
  );
  dispatchAction(
    updateSubdivision({
      componentAssignmentsMode: payload,
    })
  );
};

const isConfigured = (
  subdivisionEditorState: SubdivisionViewModelState
): boolean =>
  bindWorkspacesOperations.hasDataConfigured(
    subdivisionEditorState.bindWorkspacesState
  ) &&
  (!subdivisionEditorState.componentsSelection.advancedSearchState.isEmpty ||
    !subdivisionEditorOperations.isAdvancedSearchMode(subdivisionEditorState));

const stepValidation = (
  subdivisionEditorState: SubdivisionViewModelState
): ValidationErrors => {
  let errors: ValidationErrors = {};
  const selectedWorkspaces =
    subdivisionEditorOperations.getAllSelectedWorkspacesToBindToSubdivision(
      subdivisionEditorState
    );
  if (selectedWorkspaces.length === 0) {
    errors = { ...errors, selectedWorkspaces: 'Workspace is required' };
  }
  const isAddComponentsBySearch =
    subdivisionEditorOperations.isAdvancedSearchMode(subdivisionEditorState);
  const { isEmpty, isValid } =
    subdivisionEditorState.componentsSelection.advancedSearchState;
  if (
    isAddComponentsBySearch &&
    !(isAddComponentsBySearch && isValid && !isEmpty)
  ) {
    errors = {
      ...errors,
      advancedSearchQueries: 'Please configure advanced search',
    };
  }
  return errors;
};

const willAllSubdivisionPropertiesBeSavedInDetailsStep = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  return subdivisionEditorState.touchedSteps[SubdivisionEditorSteps.DETAILS];
};

const onSave = (
  subdivisionEditorState: SubdivisionViewModelState
): Promise<boolean> =>
  Promise.all([
    willAllSubdivisionPropertiesBeSavedInDetailsStep(subdivisionEditorState)
      ? Promise.resolve(true)
      : detailsCommands.saveDetails(subdivisionEditorState),
    bindWorkspacesCommands.saveWorkspaceBindings(subdivisionEditorState),
    addComponentsToSubdivision(subdivisionEditorState),
    removeComponentsFromSubdivision(subdivisionEditorState),
  ]).then(
    ([details, workspaceBinding, addComponents, removeComponents]) =>
      details && workspaceBinding && addComponents && removeComponents
  );

const addComponentsToSubdivision = async (
  subdivisionEditorState: SubdivisionViewModelState
): Promise<boolean> => {
  const response = await dispatchActionAndWaitForResponse(
    addComponentsToSubdivisionAction(subdivisionEditorState),
    addComponentsToSubdivisionSuccess,
    addComponentsToSubdivisionFailure
  );
  return response.type === addComponentsToSubdivisionSuccess.type;
};
const removeComponentsFromSubdivision = async (
  subdivisionEditorState: SubdivisionViewModelState
): Promise<boolean> => {
  const {
    subdivision,
    componentsSelection: { selectedComponentsToBeRemoved },
  } = subdivisionEditorState;

  if (!subdivisionsOperations.isPersistedSubdivision(subdivision)) {
    return false;
  }

  const response = await dispatchActionAndWaitForResponse(
    removeComponentsFromSubdivisionAction({
      [subdivision._id]: selectedComponentsToBeRemoved,
    }),
    removeComponentsFromSubdivisionSuccess,
    removeComponentsFromSubdivisionFailure
  );
  return response.type === removeComponentsFromSubdivisionSuccess.type;
};

const debouncedAdvancedSearchChangeTracking = debounce(
  () => {
    trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.ADVANCED_SEARCH_EDITED);
  },
  3000,
  { leading: true, trailing: false }
);

const handleAdvancedSearchChange = (payload: AdvancedSearchOnChangePayload) => {
  debouncedAdvancedSearchChangeTracking();
  dispatchAction(advancedSearchChange(payload));
};

const handleSearchClick = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.ADVANCED_SEARCH_SEARCHED);
  searchAdvancedSearch(subdivisionEditorState);
};

const setSelectedSearchResultsToRemove = (
  subdivisionEditorState: SubdivisionViewModelState,
  selectedIds: ArdoqId[]
) => {
  trackDivisionEditorUserEvent(
    DIVISIONS_USER_EVENTS.SELECTED_COMPONENTS_TO_REMOVE_FROM_DIVISION,
    {
      previousSelectionCount:
        subdivisionEditorState.componentsSelection.selectedComponentsToBeRemoved
          .length,
      newSelectionCount: selectedIds.length,
      totalComponentsCount:
        subdivisionEditorState.componentsSelection.componentsToRemove.length,
    }
  );
  dispatchAction(
    setStagedComponentToBeRemoved({
      componentIds: selectedIds,
    })
  );

  dispatchAction(touchStep(SubdivisionEditorSteps.COMPONENTS_SELECTION));
};

const setSelectedSearchResults = (
  subdivisionEditorState: SubdivisionViewModelState,
  selectedIds: ArdoqId[]
) => {
  trackDivisionEditorUserEvent(
    DIVISIONS_USER_EVENTS.SELECTED_COMPONENTS_TO_ADD_TO_DIVISION,
    {
      previousSelectionCount:
        subdivisionEditorState.componentsSelection.selectedComponentsToBeAdded
          .length,
      newSelectionCount: selectedIds.length,
      totalComponentsCount:
        subdivisionEditorState.componentsSelection.componentsToAdd.length,
    }
  );
  dispatchAction(
    setSelectedComponentsToAdd({
      selectedIds,
    })
  );

  dispatchAction(touchStep(SubdivisionEditorSteps.COMPONENTS_SELECTION));
};

const onResultTabChanged = (tabId: string) => {
  trackDivisionEditorUserEvent(
    DIVISIONS_USER_EVENTS.CHANGED_SEARCH_RESULTS_TAB,
    {
      tabId,
    }
  );
};

export const selectComponentsCommands = {
  setComponentSelectionMode,
  isConfigured,
  stepValidation,
  onSave,
  addComponentsToSubdivision,
  removeComponentsFromSubdivision,
  handleAdvancedSearchChange,
  handleSearchClick,
  setSelectedSearchResultsToRemove,
  setSelectedSearchResults,
  onResultTabChanged,
} as const;

export type SelectComponentsCommands = typeof selectComponentsCommands;
