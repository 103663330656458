import { useState } from 'react';
import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import styled from 'styled-components';
import { colors, s16 } from '@ardoq/design-tokens';
import { TextArea } from '@ardoq/forms';
import { Link, text1 } from '@ardoq/typography';
import {
  Query,
  Resource,
  StringQuery,
} from 'integrations/unified/streams/resources/types';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { combineLatest, EMPTY, filter, of, switchMap } from 'rxjs';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { getResourcesStream } from 'integrations/unified/streams/resources/resources$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { setResourceQuery } from 'integrations/unified/streams/resources/actions';

const Text1 = styled.div`
  ${text1};
`;

const Links = styled.ul`
  margin-top: ${s16};
`;

type QueryModalProps = {
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  onApplyQuery: (query: Query) => void;
  resourceName: Resource['displayText'];
  query: Query;
  queryDefinition: Resource['queryDefinition'];
};

const QueryModalComponent = ({
  onSubmit,
  onCancel,
  onApplyQuery,
  resourceName,
  query,
  queryDefinition,
}: QueryModalProps) => {
  const [queryValue, setQueryValue] = useState<Query>(query);

  return (
    <ModalTemplate
      modalSize={ModalSize.M}
      headerText={`Query resource “${resourceName}”`}
      primaryButtonText="Apply"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={() => {
        onApplyQuery(queryValue);
        onSubmit();
      }}
      onSecondaryButtonClick={onCancel}
    >
      <ModalLayout>
        <Text1>
          {queryDefinition.description}
          <br />
          <Links>
            {queryDefinition.links.map((link, idx) => (
              <li key={idx}>
                <Link
                  target="_blank"
                  href={link.href}
                  color={colors.grey15}
                  hideIcon={true}
                >
                  {link.displayText}
                </Link>
              </li>
            ))}
          </Links>
        </Text1>
        <TextArea
          value={queryValue as StringQuery}
          onValueChange={setQueryValue}
        />
      </ModalLayout>
    </ModalTemplate>
  );
};

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId =>
    combineLatest([of(integrationId), getResourcesStream(integrationId)])
  ),
  switchMap(
    ([integrationId, { focusedResource, resources, selectedResources }]) => {
      if (!focusedResource) {
        return EMPTY;
      }
      const { queryDefinition, displayText } = resources[focusedResource];
      const query = selectedResources[focusedResource].query || '';
      return of({
        resourceName: displayText,
        queryDefinition,
        query,
        onApplyQuery: (query: Query) => {
          dispatchAction(
            setResourceQuery({
              integrationId,
              resourceId: focusedResource,
              query,
            })
          );
        },
      });
    }
  )
);

const QueryModal = connect(QueryModalComponent, viewModel$);

export const startQueryModal = () =>
  modal<boolean>(
    resolve => (
      <QueryModal
        onSubmit={() => resolve(true)}
        onCancel={() => resolve(false)}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
