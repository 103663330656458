import { StatusType, Tag } from '@ardoq/status-ui';
import { IconName } from '@ardoq/icons';

import { STRINGS } from './consts';
import { PreviewAttributeType } from './types';

const ATTRIBUTE_TYPE: Record<string, string> =
  STRINGS.PREVIEW_TABLE.ATTRIBUTE_TYPES;

const getLabel = (type: PreviewAttributeType) => {
  const key = type.toUpperCase();
  return ATTRIBUTE_TYPE[key];
};

const AttributeTypeTag = ({ type }: { type: PreviewAttributeType }) => {
  if (
    type === PreviewAttributeType.UNKNOWN ||
    type === PreviewAttributeType.NULL
  )
    return (
      <Tag
        statusType={StatusType.WARNING}
        iconName={IconName.WARNING}
        label={getLabel(type)}
      />
    );
  return <Tag statusType={StatusType.DEFAULT} label={getLabel(type)} />;
};

export default AttributeTypeTag;
