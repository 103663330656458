import { connect } from '@ardoq/rxbeach';
import { GlobalPaneLoaderProps, globalPaneLoader$ } from './globalPaneLoader$';
import { LoadingOverlay } from '@ardoq/layout';

// 9997 - this loader
// 9998 - APM Wizard
// 9999 - StackPageManager
const Z_INDEX = 9997;

const GlobalPaneLoader = ({
  title,
  isLoading,
  showSpinner,
  spinnerOffsetX,
  spinnerOffsetY,
}: GlobalPaneLoaderProps) =>
  isLoading ? (
    <LoadingOverlay
      style={{
        zIndex: Z_INDEX,
        // NOTE: This works only if offset (X or Y) has positive value
        paddingLeft: spinnerOffsetX,
        paddingTop: spinnerOffsetY,
      }}
      hideSpinner={!showSpinner}
      message={title}
    />
  ) : null;

export default connect(GlobalPaneLoader, globalPaneLoader$);
