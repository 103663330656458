import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { filter, tap, withLatestFrom } from 'rxjs';
import {
  loadSchedule,
  setLoadedScheduleId,
  upsertSchedule,
} from 'integrations/common/streams/schedule/actions';
import {
  createSchedule,
  updateSchedule,
} from '../../../common/streams/schedules/actions';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { selectionState$ } from '../selectionState/selectionState$';
import { getCurrentTransferConfig } from 'integrations/common/streams/transferConfigs/utils';
import {
  removeIndexOutOfBoundFilters,
  tabularMappingIntoTransferConfig,
} from 'integrations/common/streams/tabularMappings/utils';
import { ScheduleType } from '@ardoq/api-types';
import { prepareSelectionRequest } from '../selectionState/utils';
import fp from 'lodash/fp';
import { showToast, ToastType } from '@ardoq/status-ui';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { isServiceNowSchedule } from 'integrations/common/streams/schedules/utils';
import { applySourceConfig } from '../selectionState/actions';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { getScheduleStream } from 'integrations/common/streams/schedule/schedule$';
import { startMissingConnectionModal } from 'integrations/common/modals/missingConnectionModal/MissingConnectionModal';
import { serviceNowTables$ } from '../tables/tables$';
import { tablesFields$ } from '../tableFields/tableFields$';
import { setTransferDirection } from 'integrations/common/streams/transferState/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { SelectionState } from '../selectionState/types';
import { navigateToSelectData } from 'integrations/common/navigation/actions';
import { ServiceNowConfig } from '@ardoq/api-types/integrations';

const handleUpsertSchedule = routine(
  ofType(upsertSchedule),
  filter(({ payload }) => payload.integrationId === 'servicenow-v3'),
  extractPayload(),
  withLatestFrom(
    getTabularMappingStream('servicenow-v3'),
    getTransferConfigsStream('servicenow-v3'),
    getActiveIntegrationStream('servicenow-v3'),
    getSchedulesStream('servicenow-v3'),
    getTransferStateStream('servicenow-v3'),
    getTablePreviewsStream('servicenow-v3'),
    getConnectionsStream('servicenow-v3'),
    selectionState$,
    getScheduleStream('servicenow-v3'),
    serviceNowTables$,
    tablesFields$
  ),
  tap(
    ([
      { formData },
      tabularMapping,
      transferConfigs,
      activeIntegration,
      schedules,
      transferState,
      tablePreviews,
      connectionsState,
      selectionState,
      scheduleState,
      serviceNowTables,
      tablesFields,
    ]) => {
      const currentTransferConfig = getCurrentTransferConfig(
        transferConfigs.configs,
        activeIntegration
      );

      const configName =
        currentTransferConfig.name || activeIntegration.integrationName;

      const config = tabularMappingIntoTransferConfig(
        removeIndexOutOfBoundFilters(tabularMapping, tablePreviews),
        currentTransferConfig
      );

      if (!connectionsState.selectedConnectionIds.length) {
        showToast(`No connection`, ToastType.INFO);
        return;
      }

      dispatchAction(
        trackIntegrationEvent({
          integrationId: 'servicenow-v3',
          name: 'SELECTED_SCHEDULE_INTERVAL',
          metadata: {
            source: 'import-step',
            interval: formData.policy.type,
            day:
              formData.policy.type === 'weekly'
                ? formData.policy.weekDay
                : null,
            managesWorkspace: formData.managesWorkspace ? 'on' : 'off',
          },
        })
      );
      const adjustedTableSelection: SelectionState['tables'] = fp.reduce(
        (ret, [tableId, { sourceFieldNames }]) => ({
          ...ret,
          [tableId]: {
            fields: fp.fromPairs(fp.map(c => [c, true], sourceFieldNames)),
            queryFilter: selectionState.tables[tableId]?.queryFilter,
          },
        }),
        {},
        fp.toPairs(tablePreviews)
      );

      const scheduleSelection = prepareSelectionRequest({
        tables: adjustedTableSelection,
        connectionId: connectionsState.selectedConnectionIds[0],
        name: formData.name,
        serviceNowTables: serviceNowTables.tables,
        tablesFields,
      });

      const scheduleConfig = {
        ...config,
        sourceConfig: scheduleSelection,
        name: configName,
      };

      if (scheduleState.loadedScheduleId) {
        const loadedSchedule = schedules.schedules.find(
          s => s._id === scheduleState.loadedScheduleId
        );
        if (loadedSchedule) {
          dispatchAction(
            updateSchedule({
              integrationId: 'servicenow-v3',
              schedule: fp.flow(
                fp.set('schedulingPolicy', formData.policy),
                fp.update('jobOptions', options => ({
                  ...options,
                  name: formData.name,
                  managesWorkspace: formData.managesWorkspace,
                  servicenowConfig: scheduleSelection,
                  tabularMapping: scheduleConfig,
                }))
              )(loadedSchedule),
            })
          );
        }
      } else {
        dispatchAction(
          createSchedule({
            integrationId: 'servicenow-v3',
            schedule: {
              schedulingPolicy: formData.policy,
              jobOptions: {
                type:
                  transferState.transferDirection === TransferDirection.IMPORT
                    ? ScheduleType.SERVICENOW_IMPORT
                    : ScheduleType.SERVICENOW_EXPORT,
                name: formData.name,
                managesWorkspace: formData.managesWorkspace,
                tabularMapping: scheduleConfig,
                sourceId: null,
                servicenowConfig: scheduleSelection,
              },
            },
          })
        );
      }
    }
  )
);

const handleLoadSchedule = routine(
  ofType(loadSchedule),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'servicenow-v3'),
  withLatestFrom(
    getSchedulesStream('servicenow-v3'),
    getConnectionsStream('servicenow-v3')
  ),
  tap(([{ integrationId, scheduleId }, { schedules }, { connections }]) => {
    const schedule = schedules.find(s => scheduleId === s._id);

    if (!schedule || !isServiceNowSchedule(schedule)) {
      return;
    }

    if (schedule.jobOptions.type === ScheduleType.SERVICENOW_IMPORT) {
      dispatchAction(
        setTransferDirection({
          integrationId,
          transferDirection: TransferDirection.IMPORT,
        })
      );
    }

    if (schedule.jobOptions.type === ScheduleType.SERVICENOW_EXPORT) {
      dispatchAction(
        setTransferDirection({
          integrationId,
          transferDirection: TransferDirection.EXPORT,
        })
      );
    }

    const handleLoadSchedule = (
      scheduleId: string,
      config: ServiceNowConfig
    ) => {
      dispatchAction(
        setLoadedScheduleId({
          scheduleId,
          integrationId,
        })
      );
      dispatchAction(navigateToSelectData({ integrationId }));
      dispatchAction(applySourceConfig(config));
    };

    const connection = connections.find(
      conn => conn._id === schedule.jobOptions.servicenowConfig.connectionId
    );

    if (!connection) {
      return startMissingConnectionModal({
        onCancel: () => null,
        onSubmit: connection =>
          handleLoadSchedule(schedule._id, {
            ...schedule.jobOptions.servicenowConfig,
            connectionId: connection._id,
          }),
      });
    }
    handleLoadSchedule(schedule._id, schedule.jobOptions.servicenowConfig);
  })
);

export default [handleUpsertSchedule, handleLoadSchedule];
