import { FieldsWrapper } from '@ardoq/forms';
import { Header3, Text } from '@ardoq/typography';
import RelationshipsAudienceDisplay from './RelationshipsAudienceDisplay';
import UsersAndGroupsAudienceDisplay from './UsersAndGroupsAudienceDisplay';
import AudienceRadioGroup from './AudienceRadioGroup';
import RelationshipSelector from './RelationshipSelector';
import UserAndGroupSelector from './UserAndGroupSelector';
import { FieldsByComponentTypeName } from 'surveyAdmin/types';
import {
  SurveyApprovalFlow,
  APISurveyAttributes,
  ArdoqId,
  PermissionGroup,
  SurveyApprovalRelationship,
  MetaModel,
  APIOrganizationUser,
  SurveyApprovalAudienceType,
} from '@ardoq/api-types';
import {
  getDefaultAudience,
  getRelationshipsAudience,
  getTraversalAudience,
  defaultRelationshipsAudience,
  defaultTraversalAudience,
  withRelationshipsAudienceEmailFieldSelected,
  withRelationshipRemoved,
  withRelationshipAdded,
  getUsersAndGroupsAudience,
  hasUserAndGroupsAudience,
  defaultUsersAndGroupsAudience,
  withAudienceIdAdded,
  withAudienceIdRemoved,
  withUserAndGroupsAudienceUpdated,
} from '../utils';
import { useState } from 'react';
import { confirm } from '@ardoq/modal';
import { Stack } from '@ardoq/layout';
import { exhaustiveCheck } from '@ardoq/common-helpers';
import TraversalAudienceDisplay from './TraversalAudienceDisplay';
import TraversalAudienceSelector from './TraversalAudienceSelector';
import {
  withPathRemoved,
  withTraversalAudienceEmailFieldSelected,
  withUpdatedTraversal,
} from './AudienceTraversalDrawer/traversalAudienceHelpers';
import { DefaultNotification } from '@ardoq/status-ui';
import { DoqLoader } from '@ardoq/snowflakes';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  removePath,
  resetAudienceDrawerData,
} from './AudienceTraversalDrawer/actions';

const shouldResetAudience = async () =>
  await confirm({
    title: 'Reset current audience',
    text: (
      <Stack gap="small">
        You can only select one audience type. Switching to the other will clear
        your current audience.
        <Text variant="text2Bold">Do you wish to proceed?</Text>
      </Stack>
    ),
    confirmButtonTitle: 'Yes, reset my audience',
  });

type PrimaryApproverSelectionProps = {
  approvalFlow: SurveyApprovalFlow;
  groupsById: Record<ArdoqId, PermissionGroup>;
  usersById: Record<ArdoqId, APIOrganizationUser>;
  fieldsByComponentTypeName: FieldsByComponentTypeName;
  selectableRelationships: SurveyApprovalRelationship[];
  componentTypeName: string | undefined;
  workspaceId: ArdoqId | null;
  metamodel: MetaModel;
  isLoadingMetamodel: boolean;
  setSurveyAttributes: (newAttributes: Partial<APISurveyAttributes>) => void;
};

const PrimaryApproverSelection = ({
  approvalFlow,
  groupsById,
  usersById,
  fieldsByComponentTypeName,
  selectableRelationships,
  componentTypeName,
  workspaceId,
  metamodel,
  isLoadingMetamodel,
  setSurveyAttributes,
}: PrimaryApproverSelectionProps) => {
  const audience = approvalFlow.selectors ?? getDefaultAudience();
  const usersAndGroupsAudience = getUsersAndGroupsAudience(audience);
  const relationshipAudience = getRelationshipsAudience(audience);
  const traversalAudience = getTraversalAudience(audience);

  const [selectedAudienceType, setSelectedAudience] =
    useState<SurveyApprovalAudienceType>(audience[0].audienceType);

  const handleSelectedAudienceChange = async (
    value: SurveyApprovalAudienceType
  ) => {
    if (value === selectedAudienceType) return;
    const audienceEmpty = !(selectedAudienceType === 'usersAndGroups'
      ? hasUserAndGroupsAudience(usersAndGroupsAudience)
      : selectedAudienceType === 'relationships'
        ? relationshipAudience.relationships.length
        : traversalAudience.traversal?.paths.length);

    const confirmReset = audienceEmpty || (await shouldResetAudience());
    if (!confirmReset) return;
    setSelectedAudience(value);
    dispatchAction(resetAudienceDrawerData({ paths: [] }));
    switch (value) {
      case 'usersAndGroups':
        setSurveyAttributes({
          approvalFlow: {
            ...approvalFlow,
            selectors: [defaultUsersAndGroupsAudience],
          },
        });
        return;
      case 'relationships':
        setSurveyAttributes({
          approvalFlow: {
            ...approvalFlow,
            selectors: [defaultRelationshipsAudience],
          },
        });
        return;
      case 'traversal':
        setSurveyAttributes({
          approvalFlow: {
            ...approvalFlow,
            selectors: [defaultTraversalAudience],
          },
        });
        return;
      default:
        exhaustiveCheck(value);
    }
  };

  const handleUpdateGroupAudience = (newAudience: ArdoqId[]) => {
    setSurveyAttributes({
      approvalFlow: {
        ...approvalFlow,
        selectors: [
          withUserAndGroupsAudienceUpdated(
            usersAndGroupsAudience,
            newAudience,
            'groupIds'
          ),
        ],
      },
    });
  };

  const handleUpdateUserAudience = (newAudience: ArdoqId[]) => {
    setSurveyAttributes({
      approvalFlow: {
        ...approvalFlow,
        selectors: [
          withUserAndGroupsAudienceUpdated(
            usersAndGroupsAudience,
            newAudience,
            'userIds'
          ),
        ],
      },
    });
  };
  return (
    <>
      <Header3>Select main approver</Header3>
      <AudienceRadioGroup
        selectedAudienceType={selectedAudienceType}
        handleSelectedAudienceChange={handleSelectedAudienceChange}
      />
      {!componentTypeName || !workspaceId ? (
        <DefaultNotification>
          Please select a component type in the &apos;Define the data&apos;
          section of the survey builder to continue.
        </DefaultNotification>
      ) : (
        <FieldsWrapper>
          {selectedAudienceType === 'usersAndGroups' ? (
            <>
              <UserAndGroupSelector
                selectableGroups={Object.values(groupsById)}
                selectableUsers={Object.values(usersById)}
                selectedAudience={usersAndGroupsAudience}
                selectGroup={groupId => {
                  handleUpdateGroupAudience(
                    withAudienceIdAdded(
                      usersAndGroupsAudience.groupIds,
                      groupId
                    )
                  );
                }}
                unselectGroup={groupId => {
                  handleUpdateGroupAudience(
                    withAudienceIdRemoved(
                      usersAndGroupsAudience.groupIds,
                      groupId
                    )
                  );
                }}
                selectUser={userId => {
                  handleUpdateUserAudience(
                    withAudienceIdAdded(usersAndGroupsAudience.userIds, userId)
                  );
                }}
                unselectUser={userId => {
                  handleUpdateUserAudience(
                    withAudienceIdRemoved(
                      usersAndGroupsAudience.userIds,
                      userId
                    )
                  );
                }}
              />
              <UsersAndGroupsAudienceDisplay
                title="Main approver"
                audience={usersAndGroupsAudience}
                groupsById={groupsById}
                usersById={usersById}
                unselectUserGroup={groupId => {
                  handleUpdateGroupAudience(
                    withAudienceIdRemoved(
                      usersAndGroupsAudience.groupIds,
                      groupId
                    )
                  );
                }}
                unselectUser={userId => {
                  handleUpdateUserAudience(
                    withAudienceIdRemoved(
                      usersAndGroupsAudience.userIds,
                      userId
                    )
                  );
                }}
              />
            </>
          ) : selectedAudienceType === 'relationships' ? (
            isLoadingMetamodel ? (
              <DoqLoader />
            ) : (
              <>
                <RelationshipSelector
                  metamodel={metamodel}
                  surveyComponentTypeName={componentTypeName}
                  selectedRelationships={relationshipAudience.relationships}
                  selectableRelationships={selectableRelationships}
                  selectRelationship={relationship => {
                    setSurveyAttributes({
                      approvalFlow: {
                        ...approvalFlow,
                        selectors: [
                          withRelationshipAdded(
                            relationshipAudience,
                            relationship
                          ),
                        ],
                      },
                    });
                  }}
                  unselectRelationship={relationship => {
                    setSurveyAttributes({
                      approvalFlow: {
                        ...approvalFlow,
                        selectors: [
                          withRelationshipRemoved(
                            relationshipAudience,
                            relationship
                          ),
                        ],
                      },
                    });
                  }}
                />
                <RelationshipsAudienceDisplay
                  fieldsByComponentTypeName={fieldsByComponentTypeName}
                  surveyComponentTypeName={componentTypeName}
                  audience={relationshipAudience}
                  metamodel={metamodel}
                  selectEmailField={(relationship, emailField) => {
                    setSurveyAttributes({
                      approvalFlow: {
                        ...approvalFlow,
                        selectors: [
                          withRelationshipsAudienceEmailFieldSelected(
                            relationshipAudience,
                            relationship,
                            emailField
                          ),
                        ],
                      },
                    });
                  }}
                  unselectRelationship={relationship => {
                    setSurveyAttributes({
                      approvalFlow: {
                        ...approvalFlow,
                        selectors: [
                          withRelationshipRemoved(
                            relationshipAudience,
                            relationship
                          ),
                        ],
                      },
                    });
                  }}
                />
              </>
            )
          ) : isLoadingMetamodel ? (
            <DoqLoader />
          ) : (
            <>
              <TraversalAudienceSelector
                componentTypeName={componentTypeName}
                metamodel={metamodel}
                traversal={traversalAudience.traversal}
                onTraversalChange={updatedTraversal => {
                  setSurveyAttributes({
                    approvalFlow: {
                      ...approvalFlow,
                      selectors: [
                        withUpdatedTraversal(
                          traversalAudience,
                          updatedTraversal,
                          fieldsByComponentTypeName
                        ),
                      ],
                    },
                  });
                }}
              />
              <TraversalAudienceDisplay
                fieldsByComponentTypeName={fieldsByComponentTypeName}
                audience={traversalAudience}
                metamodel={metamodel}
                selectEmailField={(componentTypeName, emailField) => {
                  setSurveyAttributes({
                    approvalFlow: {
                      ...approvalFlow,
                      selectors: [
                        withTraversalAudienceEmailFieldSelected(
                          traversalAudience,
                          componentTypeName,
                          emailField
                        ),
                      ],
                    },
                  });
                }}
                unselectPath={path => {
                  dispatchAction(removePath(path));
                  setSurveyAttributes({
                    approvalFlow: {
                      ...approvalFlow,
                      selectors: [withPathRemoved(traversalAudience, path)],
                    },
                  });
                }}
              />
            </>
          )}
        </FieldsWrapper>
      )}
    </>
  );
};

export default PrimaryApproverSelection;
