import { useEffect } from 'react';
import { PopoverManager } from '@ardoq/popovers';
import popoverManagerContext$, {
  PopoverManagerContext,
  setPopoverManagerContext as setPopoverManagerContextAction,
} from './popoverManagerContext$';
import { dispatchAction, connect } from '@ardoq/rxbeach';

type OwnProps = {
  context: PopoverManagerContext;
};
type StreamProps = {
  currentContext: PopoverManagerContext;
};

const handleSetPopoverManagerContext = (
  currentContext: PopoverManagerContext
) => dispatchAction(setPopoverManagerContextAction({ currentContext }));

const useSetPopoverManagerContext = (currentContext: PopoverManagerContext) => {
  useEffect(() => {
    handleSetPopoverManagerContext(currentContext);
    return () => {
      handleSetPopoverManagerContext(PopoverManagerContext.DEFAULT);
    };
  }, [currentContext]);
};

const PopoverManagerWithContext = ({
  currentContext,
  context,
}: StreamProps & OwnProps) => {
  useSetPopoverManagerContext(context);
  return <>{currentContext === context && <PopoverManager />}</>;
};

export default connect(PopoverManagerWithContext, popoverManagerContext$);
