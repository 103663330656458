import { entityGroupApi } from '@ardoq/api';
import { EntityGroupShape } from '@ardoq/api-types';
import { isArdoqError } from '@ardoq/common-helpers';
import { Features, hasFeature } from '@ardoq/features';

type EntityGroupEditorValidator = {
  isValid: boolean;
  errorMsg: string;
};
const getEntityGroupNameValidator = async (
  entityGroupName: string | undefined,
  isEditing: boolean
): Promise<EntityGroupEditorValidator> => {
  if (isEditing) return { isValid: true, errorMsg: '' };

  const existingEntityGroups = await entityGroupApi.fetchAll();
  if (isArdoqError(existingEntityGroups)) {
    return {
      isValid: false,
      errorMsg: 'Error fetching existing entity groups.',
    };
  }

  const isUniqueName = existingEntityGroups.every(
    ({ name }) => name !== entityGroupName
  );
  if (!isUniqueName)
    return {
      isValid: false,
      errorMsg: 'Entity group name already exists.',
    };
  return { isValid: true, errorMsg: '' };
};

type GetEntityGroupEditorErrorsParams = {
  entityGroupAttrs: Partial<EntityGroupShape>;
  isEditing: boolean;
};
export const getEntityGroupEditorFormErrors = async ({
  entityGroupAttrs,
  isEditing,
}: GetEntityGroupEditorErrorsParams) => {
  const {
    name: entityGroupName,
    componentTypeIds = [],
    referenceTypeIds = [],
    fieldIds = [],
    surveyIds = [],
    presentationIds = [],
    perspectiveIds = [],
    dashboardIds = [],
    tabularMappingIds = [],
    servicenowConfigIds = [],
    broadcastIds = [],
    storedQueryIds = [],
    reportIds = [],
    traversalIds = [],
  } = entityGroupAttrs;
  const name = entityGroupName?.trim();
  const hasBroadcasts = hasFeature(Features.BROADCASTS);
  const hasTraversals = hasFeature(Features.SUPPORT_LARGE_DATASETS);

  const hasEntities = Boolean(
    componentTypeIds.length ||
      referenceTypeIds.length ||
      fieldIds.length ||
      surveyIds.length ||
      presentationIds.length ||
      perspectiveIds.length ||
      dashboardIds.length ||
      tabularMappingIds.length ||
      servicenowConfigIds.length ||
      storedQueryIds.length ||
      reportIds.length ||
      (hasBroadcasts && broadcastIds.length) ||
      (hasTraversals && traversalIds.length)
  );

  const validators: EntityGroupEditorValidator[] = [
    {
      isValid: hasEntities,
      errorMsg: `At least one of component types, reference types, fields, workspaces,
        surveys, presentations, perspectives, dashboards, ${
          hasBroadcasts ? 'broadcasts, ' : ''
        }${
          hasTraversals ? 'traversals, ' : ''
        }or import configuration must be included in the entity group.`,
    },
    {
      isValid: Boolean(name),
      errorMsg: 'Entity group must have a name.',
    },
    await getEntityGroupNameValidator(name, isEditing),
  ];

  return {
    isValid: validators.every(({ isValid }) => isValid),
    errorMsgs: validators
      .filter(({ isValid }) => !isValid)
      .map(({ errorMsg }) => errorMsg),
  };
};
