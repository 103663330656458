import ParentDropTargetIndicator from './ParentDropTargetIndicator';
import {
  isComponentItemProps,
  isScenarioItemProps,
  isWorkspaceItemProps,
  NavigatorContentProps,
} from './PropTypes';
import ComponentItem from './ComponentItem';
import WorkspaceItem from './WorkspaceItem';
import ScenarioItem from './ScenarioItem';
import { LayoutBoxesContainer } from './Atoms';

const NavigatorContent = ({
  renderProps,
  navigatorContentStyle,
  parentDropTargetIndicatorStyle,
  layoutBoxesContainerStyle,
}: NavigatorContentProps) => (
  <div
    id="navigator-content"
    style={navigatorContentStyle}
    role="tree"
    aria-label="Navigator"
  >
    <LayoutBoxesContainer style={layoutBoxesContainerStyle}>
      {renderProps.map(candidate => {
        if (isComponentItemProps(candidate)) {
          return <ComponentItem {...candidate} key={candidate.nodeId} />;
        }
        if (isWorkspaceItemProps(candidate)) {
          return <WorkspaceItem {...candidate} key={candidate.nodeId} />;
        }
        if (isScenarioItemProps(candidate)) {
          return <ScenarioItem {...candidate} key={candidate.nodeId} />;
        }
        return null;
      })}
    </LayoutBoxesContainer>
    {parentDropTargetIndicatorStyle && (
      <ParentDropTargetIndicator
        parentDropTargetIndicatorStyle={parentDropTargetIndicatorStyle}
      />
    )}
  </div>
);

export default NavigatorContent;
