const AggregatedVisualDiffState = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 192" width="32px">
    {/*
      The aggregated diff state of addded, removed and chaged
      is stored in three bit flags. This is the table to convert the
      different diff states to the y position of a sprite.

      no change             0b000   0                   0
      removed               0b001   1   (1 * 24 + 12)  36
      added                 0b010   2   (2 * 24 + 12)  60
      changed               0b100   4   (4 * 24 + 12) 108
      added-removed         0b011   3   (3 * 24 + 12)  84
      changed-removed       0b101   5   (5 * 24 + 12) 132
      changed-added         0b110   6   (6 * 24 + 12) 156
      changed-added-removed 0b111   7   (7 * 24 + 12) 180
    */}
    {/* removed */}
    <circle cx="16" cy="36" r="4" fill="rgb(248, 90, 29)" />
    {/* added */}
    <circle cx="16" cy="60" r="4" fill="rgb(100, 206, 45)" />
    {/* changed */}
    <circle cx="16" cy="108" r="4" fill="rgb(47, 189, 251)" />
    {/* added-removed */}
    <circle cx="11" cy="84" r="4" fill="rgb(100, 206, 45)" />
    <circle cx="21" cy="84" r="4" fill="rgb(248, 90, 29)" />
    {/* changed-removed */}
    <circle cx="11" cy="132" r="4" fill="rgb(47, 189, 251)" />
    <circle cx="21" cy="132" r="4" fill="rgb(248, 90, 29)" />
    {/* changed-added */}
    <circle cx="11" cy="156" r="4" fill="rgb(47, 189, 251)" />
    <circle cx="21" cy="156" r="4" fill="rgb(100, 206, 45)" />
    {/* changed-added-removed */}
    <circle cx="6" cy="180" r="4" fill="rgb(47, 189, 251)" />
    <circle cx="16" cy="180" r="4" fill="rgb(100, 206, 45)" />
    <circle cx="26" cy="180" r="4" fill="rgb(248, 90, 29)" />
  </svg>
);

export default AggregatedVisualDiffState;
