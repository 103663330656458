import { ArdoqId, AssetType } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { Status, SupportedAssetTypes } from 'assets/types';

export type FetchAssetsUsage = {
  type: SupportedAssetTypes;
  id: string;
};
export const fetchAssetUsage = actionCreator<FetchAssetsUsage>(
  '[assets usage] FETCH_ASSET_USAGE'
);

export type SetAssetUsage = {
  id: ArdoqId;
  usage: Array<{ _id: ArdoqId; type: AssetType }>;
};
export const setAssetUsage = actionCreator<SetAssetUsage>(
  '[assets usage] SET_ASSET_USAGE'
);

export type SetAssetUsageStatus = {
  id: ArdoqId;
  status: Status;
};
export const setAssetUsageStatus = actionCreator<SetAssetUsageStatus>(
  '[assets usage] SET_ASSET_USAGE_STATUS'
);
