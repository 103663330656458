import Filters from 'collections/filters';
import {
  BooleanOperator,
  Operator,
  QueryBuilderSubquery,
} from '@ardoq/api-types';
import { findQuickPerspectivesTypeEqualitySubquery } from '@ardoq/filter-interface';
import { clearQucikPerspectiveTypeFilters } from 'quickPerspectives/utils';

function createReferenceTypeRule(
  ruleRoot: QueryBuilderSubquery
): QueryBuilderSubquery {
  const referenceTypeRule = {
    type: 'attribute',
    condition: BooleanOperator.OR,
    rules: [],
  };
  ruleRoot.rules.push(referenceTypeRule);
  return referenceTypeRule;
}

export function getFilterIncludedTypeNames(ruleRoot: QueryBuilderSubquery) {
  if (!ruleRoot) {
    return [];
  }
  const referenceTypeRules =
    findQuickPerspectivesTypeEqualitySubquery(ruleRoot);
  if (!referenceTypeRules || !referenceTypeRules.rules) {
    return [];
  }
  return referenceTypeRules.rules.map(rule => rule.value);
}

export function clearReferenceTypeFilters() {
  const ruleRoot = Filters.getFiltersAsQueryBuilderQueries().referenceRules;

  const newRoot = clearQucikPerspectiveTypeFilters(ruleRoot);

  Filters.setFiltersFromQueryBuilderQueries({
    referenceRules: newRoot,
    shouldTriggerChangeEvent: true,
  });
}

export function ensureReferenceTypeIncluded(
  referenceTypeName: string,
  referenceRules: QueryBuilderSubquery
) {
  const includedTypeNames = getFilterIncludedTypeNames(referenceRules);
  if (!includedTypeNames.includes(referenceTypeName)) {
    includedTypeNames.push(referenceTypeName);
    setIncludedReferenceTypes(includedTypeNames);
  }
}

export function setIncludedReferenceTypes(referenceTypeNames: string[]) {
  if (!referenceTypeNames || referenceTypeNames.length === 0) {
    clearReferenceTypeFilters();
    return;
  }

  const ruleRoot = Filters.getFiltersAsQueryBuilderQueries().referenceRules;
  const referenceTypeRule =
    findQuickPerspectivesTypeEqualitySubquery(ruleRoot) ||
    createReferenceTypeRule(ruleRoot);

  referenceTypeRule.rules = [];
  referenceTypeNames.forEach(referenceTypeName => {
    referenceTypeRule.rules.push({
      id: 'type',
      field: 'type',
      input: 'text',
      operator: Operator.EQUAL,
      type: 'string',
      value: referenceTypeName,
    });
  });

  Filters.setFiltersFromQueryBuilderQueries({
    referenceRules: ruleRoot,
    shouldTriggerChangeEvent: true,
  });
}
