import { EVENT_NAMES } from 'integrations/common/tracking/events';
import {
  FailureStrategy,
  Transformation,
  TransformationOperation,
} from '@ardoq/api-types/integrations';
import { SelectOption } from '@ardoq/select';
import React, { ReactNode } from 'react';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { ValidationErrors } from '../types';
import {
  isNonEmptyString,
  isNonEmptyStringIncludingSpaces,
  isNonNegativeNumber,
  isNumber,
  isString,
  validateDictionary,
} from './utils';
import {
  ArithmeticExpressionTransformationSection,
  AsDateTransformationSection,
  AsNumberTransformationSection,
  ConcatenateColumnTransformationSection,
  DictionaryLookupTransformationSection,
  JsonPathTransformationSection,
  RoundNumberTransformationSection,
  SubStringReplaceTransformationSection,
} from './transformations';

export const OPERATION_OPTIONS: SelectOption<TransformationOperation>[] = [
  {
    value:
      TransformationOperation.MULTIPLY ||
      TransformationOperation.DIVIDE ||
      TransformationOperation.ADD ||
      TransformationOperation.SUBTRACT,
    label: 'Arithmetic expression',
  },
  {
    value: TransformationOperation.AS_DATE,
    label: 'Convert to date',
  },
  {
    value: TransformationOperation.AS_DATETIME,
    label: 'Convert to date time',
  },
  {
    value: TransformationOperation.AS_NUMBER,
    label: 'Convert to number',
  },
  {
    value: TransformationOperation.JSON_PATH,
    label: 'Extract value in nested structure',
  },
  {
    value: TransformationOperation.CONCAT,
    label: 'Join columns',
  },
  {
    value: TransformationOperation.DICTIONARY_LOOKUP,
    label: 'Lookup and replace',
  },
  {
    value: TransformationOperation.SUBSTRING_REPLACE,
    label: 'Replace in text',
  },
  {
    value: TransformationOperation.ROUND_NUMBER,
    label: 'Round number',
  },
];

export const FAILURE_OPTIONS = [
  {
    value: 'use_existing',
    label: 'Keep the existing value',
  },
  {
    value: 'use_default',
    label: 'Replace with a default value',
  },
];

export const INITIAL_TRANSFORMATION_CONFIG: Record<
  TransformationOperation,
  any
> = {
  [TransformationOperation.DICTIONARY_LOOKUP]: {
    dictionary: [{ key: '', value: '' }],
  },
  [TransformationOperation.JSON_PATH]: {
    path: '',
  },
  [TransformationOperation.ROUND_NUMBER]: {
    precision: 2,
  },
  [TransformationOperation.SUBSTRING_REPLACE]: {
    match: '',
    replacement: '',
    isRegex: false,
  },
  [TransformationOperation.MULTIPLY]: {
    constant: 1,
  },
  [TransformationOperation.AS_NUMBER]: {},
  [TransformationOperation.ADD]: {},
  [TransformationOperation.DIVIDE]: {},
  [TransformationOperation.SUBTRACT]: {},
  [TransformationOperation.AS_DATE]: {
    formatString: '',
  },
  [TransformationOperation.AS_DATETIME]: {
    formatString: '',
  },
  [TransformationOperation.CONCAT]: {
    additionalFields: [],
    joinStr: '',
  },
};

export type TransformationFailureHandler<T extends TransformationOperation> = (
  transformation: Extract<Transformation, { operation: T }>,
  strategy: FailureStrategy
) => Transformation;

const createFailureHandler =
  <T extends TransformationOperation>(): TransformationFailureHandler<T> =>
  (transformation, strategy) => ({
    ...transformation,
    config: {
      ...transformation.config,
      failure: { ...transformation.config.failure, strategy },
    },
  });

export type TransformationDefaultValueHandler<
  T extends TransformationOperation,
> = (
  transformation: Extract<Transformation, { operation: T }>,
  value: string
) => Transformation;

const createDefaultValueHandler =
  <T extends TransformationOperation>(): TransformationDefaultValueHandler<T> =>
  (transformation, value) => ({
    ...transformation,
    config: {
      ...transformation.config,
      failure: { ...transformation.config.failure, default: value },
    },
  });

export type TransformationHandler<T extends TransformationOperation> = (
  {
    transformation,
    integrationId,
    setTransformation,
    validationErrors,
    setValidationErrors,
    setLabel,
  }: {
    transformation: Extract<Transformation, { operation: T }>;
    integrationId: IntegrationId;
    setTransformation: (transformation: Transformation) => void;
    validationErrors: ValidationErrors;
    setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
    setLabel: React.Dispatch<React.SetStateAction<string | undefined>>;
  },
  defaultValue: string,
  failureStrategy: FailureStrategy,
  defaultColumn: SelectOption<string>,
  availableColumns: SelectOption<string>[]
) => {
  label: string;
  defaultValueState: Transformation;
  failureStrategyState: Transformation;
  trackingEvent: keyof typeof EVENT_NAMES;
  component: ReactNode;
  isValid: boolean;
};

export const transformationComponents: {
  [K in TransformationOperation]?: TransformationHandler<K>;
} = {
  [TransformationOperation.DICTIONARY_LOOKUP]: (
    {
      transformation,
      integrationId,
      setTransformation,
      validationErrors,
      setValidationErrors,
    },
    defaultValue,
    failureStrategy
  ) => ({
    label: 'Lookup and replace',
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'SELECTED_DICTIONARY_LOOKUP_TRANSFORMATION',
    component: (
      <DictionaryLookupTransformationSection
        integrationId={integrationId}
        transformation={transformation}
        setTransformation={setTransformation}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      />
    ),
    isValid: validateDictionary(transformation.config.dictionary),
  }),
  [TransformationOperation.JSON_PATH]: (
    {
      transformation,
      setTransformation,
      validationErrors,
      setValidationErrors,
      setLabel,
    },
    defaultValue,
    failureStrategy
  ) => ({
    label: `Extract: ${transformation.config.path}`,
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'SELECTED_JSON_PATH_TRANSFORMATION',
    component: (
      <JsonPathTransformationSection
        transformation={transformation}
        setTransformation={setTransformation}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
        setLabel={setLabel}
      />
    ),
    isValid: isNonEmptyString(transformation.config.path),
  }),
  [TransformationOperation.ROUND_NUMBER]: (
    { transformation, setTransformation },
    defaultValue,
    failureStrategy
  ) => ({
    label: 'Round number',
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'ROUND_NUMBER_TRANSFORMATION',
    component: (
      <RoundNumberTransformationSection
        transformation={transformation}
        setTransformation={setTransformation}
      />
    ),
    isValid: isNonNegativeNumber(transformation.config.precision),
  }),
  [TransformationOperation.AS_NUMBER]: (
    { transformation },
    defaultValue,
    failureStrategy
  ) => ({
    label: 'Convert to number',
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'AS_NUMBER_TRANSFORMATION',
    component: <AsNumberTransformationSection />,
    isValid: true,
  }),
  [TransformationOperation.SUBSTRING_REPLACE]: (
    { transformation, setTransformation },
    defaultValue,
    failureStrategy
  ) => ({
    label: 'Replace in text',
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'SUBSTRING_REPLACE_TRANSFORMATION',
    component: (
      <SubStringReplaceTransformationSection
        transformation={transformation}
        setTransformation={setTransformation}
      />
    ),
    isValid:
      isString(transformation.config.replacement) &&
      isNonEmptyStringIncludingSpaces(transformation.config.match),
  }),
  [TransformationOperation.ADD]: (
    { transformation, setTransformation, setLabel },
    defaultValue,
    failureStrategy
  ) => ({
    label: `Arithmetic expression: ${transformation.operation}`,
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'ARITHMETIC_EXPRESSION_TRANSFORMATION',
    component: (
      <ArithmeticExpressionTransformationSection
        transformation={transformation}
        setTransformation={setTransformation}
        setLabel={setLabel}
      />
    ),
    isValid: isNumber(transformation.config.constant),
  }),
  [TransformationOperation.MULTIPLY]: (
    { transformation, setTransformation, setLabel },
    defaultValue,
    failureStrategy
  ) => ({
    label: `Arithmetic expression: ${transformation.operation}`,
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'ARITHMETIC_EXPRESSION_TRANSFORMATION',
    component: (
      <ArithmeticExpressionTransformationSection
        transformation={transformation}
        setTransformation={setTransformation}
        setLabel={setLabel}
      />
    ),
    isValid: isNumber(transformation.config.constant),
  }),
  [TransformationOperation.DIVIDE]: (
    { transformation, setTransformation, setLabel },
    defaultValue,
    failureStrategy
  ) => ({
    label: `Arithmetic expression: ${transformation.operation}`,
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'ARITHMETIC_EXPRESSION_TRANSFORMATION',
    component: (
      <ArithmeticExpressionTransformationSection
        transformation={transformation}
        setTransformation={setTransformation}
        setLabel={setLabel}
        isNonCommutative={true}
      />
    ),
    isValid:
      isNumber(transformation.config.constant) &&
      isNonEmptyString(transformation.config.constantPosition),
  }),
  [TransformationOperation.SUBTRACT]: (
    { transformation, setTransformation, setLabel },
    defaultValue,
    failureStrategy
  ) => ({
    label: `Arithmetic expression: ${transformation.operation}`,
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'ARITHMETIC_EXPRESSION_TRANSFORMATION',
    component: (
      <ArithmeticExpressionTransformationSection
        transformation={transformation}
        setTransformation={setTransformation}
        setLabel={setLabel}
        isNonCommutative={true}
      />
    ),
    isValid:
      isNumber(transformation.config.constant) &&
      isNonEmptyString(transformation.config.constantPosition),
  }),
  [TransformationOperation.AS_DATE]: (
    { transformation, setTransformation },
    defaultValue,
    failureStrategy
  ) => ({
    label: `Convert to date`,
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'AS_DATE_TRANSFORMATION',
    component: (
      <AsDateTransformationSection
        transformation={transformation}
        setTransformation={setTransformation}
        exampleFormat="dd MMM yyyy"
        exampleInput="12 Sep 2024"
      />
    ),
    isValid: isNonEmptyString(transformation.config.formatString),
  }),
  [TransformationOperation.AS_DATETIME]: (
    { transformation, setTransformation },
    defaultValue,
    failureStrategy
  ) => ({
    label: `Convert to date time`,
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'AS_DATETIME_TRANSFORMATION',
    component: (
      <AsDateTransformationSection
        transformation={transformation}
        setTransformation={setTransformation}
        exampleFormat="dd MMM yyyy HH:mm:ss"
        exampleInput="12 Sep 2024 14:34:56"
      />
    ),
    isValid: isNonEmptyString(transformation.config.formatString),
  }),
  [TransformationOperation.CONCAT]: (
    { transformation, setTransformation, setLabel },
    defaultValue,
    failureStrategy,
    defaultColumn,
    availableColumns
  ) => ({
    label: `Joining column: ${transformation.config.additionalFields.join(
      ','
    )}`,
    defaultValueState: createDefaultValueHandler()(
      transformation,
      defaultValue
    ),
    failureStrategyState: createFailureHandler()(
      transformation,
      failureStrategy
    ),
    trackingEvent: 'CONCAT_TRANSFORMATION',
    component: (
      <ConcatenateColumnTransformationSection
        transformation={transformation}
        setTransformation={setTransformation}
        setLabel={setLabel}
        defaultColumn={defaultColumn}
        availableColumns={availableColumns}
      />
    ),
    isValid: transformation.config.additionalFields.every(isNonEmptyString),
  }),
};
