import { FormWrapper, RadioGroup, RadioOption, TextInput } from '@ardoq/forms';
import { omit } from 'lodash';
import { useState } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import { getMaskSizeIfValidIP, isValidIP, parseIPAddress } from './utils';
import { allowlistTypeOptionMetadata, maskSizeHelpText } from './consts';
import { typographyMixins } from '@ardoq/typography';
import { useKeydown } from '@ardoq/hooks';
import styled from 'styled-components';
import { PrimaryButton } from '@ardoq/button';
import { s32 } from '@ardoq/design-tokens';
import { createIPEntry, updateIPEntry } from './ipAllowlist$/actions';
import { ContactSupportSnippet } from './atoms';
import { ModalLayout, ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import {
  IPAllowlistEntry,
  PersistedIPAllowlistEntry,
  allIPAllowlistTypes,
} from '@ardoq/api-types';

type FormState = Omit<IPAllowlistEntry, 'maskSize'> & {
  maskSize: number | null;
};

const SubmitButton = styled(PrimaryButton)`
  margin-top: ${s32};
`;

const TypeOptionDescription = styled.div`
  ${typographyMixins.caption}
`;

const isFormStateValid = (state: FormState): state is IPAllowlistEntry =>
  isValidIP(state.address) && Boolean(state.maskSize);

const allowlistTypeOptions = allIPAllowlistTypes.map<RadioOption>(type => {
  const { label, description } = allowlistTypeOptionMetadata[type];
  return {
    value: type,
    label: (
      <div>
        <div>{label}</div>
        <TypeOptionDescription>{description}</TypeOptionDescription>
      </div>
    ),
  };
});

const defaultFormState: FormState = {
  address: '',
  description: '',
  whitelistType: 'any',
  maskSize: null,
};

const hasFormStateChanged = (f1: FormState, f2: FormState) =>
  f1.address !== f2.address ||
  f1.description !== f2.description ||
  f1.whitelistType !== f2.whitelistType;

type EntryFormProps = {
  isEditingExisting?: boolean;
  existingEntry?: PersistedIPAllowlistEntry;
  onSubmit?: () => any;
};

const AllowlistEntryForm = ({
  isEditingExisting,
  existingEntry,
  onSubmit,
}: EntryFormProps) => {
  const initState: FormState = existingEntry || defaultFormState;
  const [formState, setFormState] = useState<FormState>(initState);
  useKeydown(e => e.key === 'Enter' && submit());

  const { address, description, maskSize, whitelistType } = formState;

  const isFormValid = isFormStateValid(formState);

  const canSubmitForm =
    isFormValid && hasFormStateChanged(initState, formState);

  const setInputValue = (partialState: Partial<FormState>) =>
    setFormState({ ...formState, ...partialState });

  const setIPAddress = (addr: string) =>
    setInputValue({ address: addr, maskSize: getMaskSizeIfValidIP(addr) });

  const resetForm = () =>
    setInputValue(omit(defaultFormState, 'allowlistType'));

  const saveChanges = () =>
    canSubmitForm &&
    existingEntry &&
    dispatchAction(
      updateIPEntry({
        ...existingEntry,
        ...formState,
        address: parseIPAddress(address).address,
      })
    );

  const createNew = () =>
    canSubmitForm && dispatchAction(createIPEntry(formState));

  const submit = () => {
    if (!canSubmitForm) return;
    if (isEditingExisting) {
      saveChanges();
    } else {
      createNew();
    }
    resetForm();
    onSubmit?.();
  };

  return (
    <FormWrapper>
      <TextInput
        label="IP address"
        value={address}
        onValueChange={setIPAddress}
        helperText="IPv4 and CIDR formats accepted"
      />

      <TextInput
        label="Mask size"
        value={maskSize ?? ' '}
        isDisabled
        helperText={
          isFormValid
            ? maskSizeHelpText[parseIPAddress(address).maskSizeSource]
            : 'Enter valid IP address'
        }
      />

      <TextInput
        label="Description"
        value={description}
        onValueChange={value => setInputValue({ description: value })}
        helperText="Add an optional description to give context to the allowlist entry"
      />

      <RadioGroup
        label="What traffic should be allowed?"
        options={allowlistTypeOptions}
        value={whitelistType}
        // @ts-expect-error incorrect typing
        onValueChange={(allowListType: AllowlistType) => {
          setInputValue({ whitelistType: allowListType });
        }}
      />

      <SubmitButton isDisabled={!canSubmitForm} onClick={submit}>
        {isEditingExisting ? 'Save changes' : 'Save IP address'}
      </SubmitButton>
      <p>
        <i>
          To enforce configured IP addresses, save configured IP addresses
          first. Next, <ContactSupportSnippet typography="text2" />. This helps
          us prevent accidental lockouts.
        </i>
      </p>
    </FormWrapper>
  );
};

export const editAllowlistEntryInModal = (entry: PersistedIPAllowlistEntry) =>
  modal(closeModal => (
    <ModalTemplate
      modalSize={ModalSize.M}
      headerText="Edit entry"
      onCloseButtonClick={() => closeModal(true)}
    >
      <ModalLayout>
        <AllowlistEntryForm
          isEditingExisting
          existingEntry={entry}
          onSubmit={() => closeModal(true)}
        />
      </ModalLayout>
    </ModalTemplate>
  ));

export default AllowlistEntryForm;
