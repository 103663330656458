import Components from 'collections/components';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap } from 'rxjs/operators';

import { editComponent, editComponentStyle, selectComponent } from './actions';
import Context from 'context';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { showRightPane } from 'appContainer/actions';

const handleEditComponent = routine(
  ofType(editComponent),
  extractPayload(),
  tap(id => {
    const component = Components.collection.get(id);
    if (component) {
      dispatchAction(
        showRightPane({
          type: GetContentOptionsType.COMPONENT_PROPERTIES,
          componentIds: [id],
        })
      );
    }
  })
);

const handleEditComponentStyle = routine(
  ofType(editComponentStyle),
  extractPayload(),
  tap(id => {
    const component = Components.collection.get(id);
    if (component) {
      dispatchAction(
        showRightPane({
          type: GetContentOptionsType.COMPONENT_STYLE,
          componentIds: [id],
        })
      );
    }
  })
);

const handleSelectComponent = routine(
  ofType(selectComponent),
  extractPayload(),
  tap(id => {
    const component = Components.collection.get(id);
    if (component) {
      Context.setComponent(component);
    }
  })
);

export default collectRoutines(
  handleEditComponent,
  handleEditComponentStyle,
  handleSelectComponent
);
