import { AvailableRelatedData, PerspectivesRelatedData } from '../types';

export const emptyPerspectivesRelatedData: PerspectivesRelatedData = {
  organizationId: null,
  currentWorkspaceId: null,
  relatedFieldsByName: {},
  relatedComponents: [],
  relatedTags: [],
  relatedWorkspaces: [],
  referenceTypeNames: [],
  relatedReferences: [],
  connectedWorkspaceComponentFields: [],
  connectedWorkspaceReferenceFields: [],
  componentTypeNames: [],
  isScenarioMode: false,
};

export const emptyAvailableRelatedData: AvailableRelatedData = {
  availableWorkspaceComponentFields: [],
  availableWorkspaceReferenceFields: [],
  availableWorkspaceTags: [],
  componentFieldsFilteredByWorkspaceFilter: {},
  referenceFieldsFilteredByWorkspaceFilter: {},
  tagsFilteredByWorkspaceFilter: {},
};
