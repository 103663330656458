import { ArdoqId } from '@ardoq/api-types';
import {
  ensureContrast,
  getLightenedColor,
  getShadedColor,
} from '@ardoq/color-helpers';
import { componentInterface } from '@ardoq/component-interface';
import { getIcon } from '@ardoq/icons';

// default return of getLightenedColor and getShadedColor, used according to the code logic in this module
const COMPONENT_FALLBACK_COLOR = '#ffffff';

const getEnhancedRepresentationData = (id: ArdoqId) => {
  const representationData = componentInterface.getRepresentationData(id);
  const componentColor = componentInterface.getComponentColor(id);
  const lightenedColor = componentColor
    ? getLightenedColor(componentColor)
    : COMPONENT_FALLBACK_COLOR;
  const shadedColor = componentColor
    ? getShadedColor(componentColor)
    : COMPONENT_FALLBACK_COLOR;
  return {
    isImage: representationData?.isImage ?? false,
    value: representationData?.value ?? '',
    icon: representationData?.icon ?? getIcon(null),
    color: componentColor || COMPONENT_FALLBACK_COLOR,
    shadedColor,
    contrastColor: componentColor
      ? ensureContrast(lightenedColor, componentColor)
      : COMPONENT_FALLBACK_COLOR,
    lightenedColor,
  };
};

const prepareComponentFromId = (id: ArdoqId) => {
  const name = componentInterface.getDisplayName(id) ?? '';
  const entityType = componentInterface.getType(id)?.name ?? '';
  return {
    id,
    entityType,
    label: name,
    representationData: getEnhancedRepresentationData(id),
  };
};

export const searchTraversalInterface = {
  prepareComponentFromId,
  getEnhancedRepresentationData,
};
