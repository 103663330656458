import {
  exportRun,
  importRun,
  transferRun,
} from 'integrations/common/streams/transferState/actions';
import {
  dispatchAction,
  routine,
  ofType,
  extractPayload,
} from '@ardoq/rxbeach';
import { filter, map, tap, withLatestFrom } from 'rxjs';
import { TransferDirection } from '../../common/streams/transferState/types';
import { connections$ } from '../../common/streams/connections/connections$';
import { transferState$ } from '../../common/streams/transferState/getTransferStateStream';
import { isUnifiedIntegrationId } from '../utils';
import { resources$ } from '../streams/resources/resources$';
import { selectedResourcesToSourceConfigResources } from '../streams/resources/utils';
import { UnifiedIntegrationId } from '../types';

const handleTransferRun = routine(
  ofType(transferRun),
  extractPayload(),
  filter(({ integrationId }) => isUnifiedIntegrationId(integrationId)),
  withLatestFrom(transferState$, resources$, connections$),
  map(
    ([
      { async, integrationId, dryRun },
      transferState,
      resources,
      connections,
    ]) => {
      const sourceConfigResources = selectedResourcesToSourceConfigResources(
        resources[integrationId as UnifiedIntegrationId].selectedResources
      );

      const sourceConfig = {
        connectionId: connections[integrationId].selectedConnectionIds[0],
        resources: sourceConfigResources,
      };

      return {
        async,
        integrationId,
        dryRun,
        transferState: transferState[integrationId],
        sourceConfig,
      };
    }
  ),
  tap(({ transferState, ...runParams }) => {
    const action =
      transferState.transferDirection === TransferDirection.IMPORT
        ? importRun
        : exportRun;
    dispatchAction(action(runParams));
  })
);

export default [handleTransferRun];
