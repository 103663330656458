import styled, { css } from 'styled-components';
import { colors, s16, s24, s32, s4, s8 } from '@ardoq/design-tokens';
import { caption, header4 } from '@ardoq/typography';
import { disabledCSS } from '@ardoq/style-helpers';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${s24} 0 ${s32} 0;
`;
export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${s8};
  padding: 0 ${s16};
`;
export const SectionTitle = styled.div`
  ${header4};
  color: ${colors.grey15};
`;
export const SectionDescription = styled.div`
  ${caption};
  color: ${colors.grey50};
`;
export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${s8};
  padding: ${s16};
  border-bottom: 1px solid ${colors.grey90};
`;
export const RowLeftContentContainer = styled.div<{
  $isDisabled?: boolean;
}>`
  display: flex;
  align-items: center;
  flex: 1;
  gap: ${s8};
  ${({ $isDisabled }) => $isDisabled && disabledCSS}
`;
export const RowLabel = styled.span`
  ${header4};
  color: ${colors.grey50};
`;
export const TagsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${s4};
  flex: 1;
`;
export const tagStyles = css`
  padding: 2px ${s8};
  gap: ${s4};

  &&&& > div,
  .ardoq-icon {
    margin: 0;
  }
`;
