import {
  isTraversalBuilderStateWithCurrentEditableRule,
  TraversalBuilderState,
  TraversalBuilderStateWithCurrentEditableRule,
} from '../types';
import {
  createEmptyPathCollapsingRule,
  defaultCollapsedPathReferenceStyle,
} from './createEmptyPathCollapsingRule';
import { groupBy, identity, isEqual, omit, pick } from 'lodash';
import { EditablePathCollapsingRule } from './types';
import {
  transformCurrentEditableRule,
  transformRules,
} from './collapsePathsViewStateTransformations';
import { collapsedPathToHash } from './collapsedPathToHash';
import {
  DirectedTripleWithFiltersWithGraphIds,
  PathCollapsingRule,
  PathCollapsingRuleNonNullablePath,
} from '@ardoq/api-types';
import { getShortestPath, ShortestPath } from './getShortestPath';
import { GraphEdge, GraphNode } from '@ardoq/graph';
import { logError } from '@ardoq/logging';
import { viewpointBuilderGraphOperations } from '../viewpointBuilderGraphOperations';
import { namedDirectedTripleToBackendFormat } from '../traversals/namedDirectedTripleToBackendFormat';
import {
  CollapsingRuleIsActiveToggledPayload,
  CollapsingRuleToggledPayload,
} from './collapsePathsActions';
import { editTraversalOperations } from 'viewpointBuilder/traversals/editTraversalOperations';
import { partialPayload, pipeReducers } from '@ardoq/common-helpers';
import {
  getMatchingPaths,
  validateMatchesAgainstOverlaps,
} from 'traversals/pathAndGraphUtils';
import { CollapsingRuleErrorMessage } from './CollapsingRuleErrorMessage';

const setPathCollapsingRules = (
  state: TraversalBuilderState,
  rules: PathCollapsingRule[]
): TraversalBuilderState => {
  return pipeReducers(
    {
      ...state,
      initialPathCollapsingRules: rules,
    },
    partialPayload(processCollapsingRules, rules),
    viewpointBuilderGraphOperations.resetPathHighlightingForCollapsingRules,
    editTraversalOperations.updateEdgesToBeRemovedOnCollapsing
  );
};

const createPathCollapsingRule = (
  state: TraversalBuilderState
): TraversalBuilderState => {
  const newRule = editTraversalOperations.toInternalPathCollapsingRule(
    state,
    createEmptyPathCollapsingRule()
  );
  const hash = collapsedPathToHash(newRule);
  return {
    ...state,
    pathCollapsing: {
      ...state.pathCollapsing,
      currentEditableRule: {
        ...structuredClone(newRule),
        currentRuleHash: hash,
        startNodeId: null,
        errorMessage: null,
      },
      rules: [...state.pathCollapsing.rules, newRule],
    },
    highlightedPaths: {
      ...state.highlightedPaths,
      [hash]: [],
    },
  };
};

const savePathCollapsingRule = (
  state: TraversalBuilderState,
  editableRule: EditablePathCollapsingRule
): TraversalBuilderState => {
  const newBaseRule = getNewRule(editableRule);
  if (!newBaseRule) {
    logError(Error('Expected newBaseRule to exist'));
    return state;
  }
  const isExpanded = true;
  const includePathIndexInEqualityCheck = false;
  const newRule = editTraversalOperations.toInternalPathCollapsingRule(
    state,
    newBaseRule,
    isExpanded,
    includePathIndexInEqualityCheck
  );

  const hash = collapsedPathToHash(newRule);
  const newHighlightedPaths = {
    ...state.highlightedPaths,
    [hash]: state.highlightedPaths[editableRule.currentRuleHash] ?? [],
  };
  return pipeReducers(
    {
      ...state,
      highlightedPaths: omit(newHighlightedPaths, editableRule.currentRuleHash),
      pathCollapsing: {
        ...state.pathCollapsing,
        rules: state.pathCollapsing.rules.map(rule =>
          collapsedPathToHash(rule) === editableRule.currentRuleHash
            ? newRule
            : rule
        ),
        currentEditableRule: null,
      },
    },
    viewpointBuilderGraphOperations.resetPathHighlightingForCollapsingRules,
    editTraversalOperations.updateEdgesToBeRemovedOnCollapsing
  );
};

const getNewRule = (
  editableRule: EditablePathCollapsingRule
): PathCollapsingRuleNonNullablePath | null => {
  const newRule = pick(editableRule, [
    'path',
    'displayText',
    'referenceStyle',
    'isActive',
  ]);
  if (!newRule.path) {
    return null;
  }
  newRule.path = newRule.path.map(triple => ({
    ...triple,
    referenceType: namedDirectedTripleToBackendFormat(triple).referenceType,
  }));
  return newRule as PathCollapsingRuleNonNullablePath;
};

const deletePathCollapsingRule = (
  state: TraversalBuilderState,
  hash: string
): TraversalBuilderState => {
  const newState = viewpointBuilderGraphOperations.updatePathHighlighting(
    state,
    {
      shortestPath: null,
      highlightedPathId: hash,
      clearSelectedNodes: true,
    }
  );
  const { highlightedPaths, pathCollapsing } = newState;
  const newHighlightedPaths = omit(highlightedPaths, hash);
  return {
    ...newState,
    highlightedPaths: newHighlightedPaths,
    pathCollapsing: {
      ...pathCollapsing,
      rules: pathCollapsing.rules.filter(
        rule => collapsedPathToHash(rule) !== hash
      ),
    },
  };
};

const editPathCollapsingRule = (
  state: TraversalBuilderState,
  hash: string
): TraversalBuilderState => {
  const rule = getRuleByHash(state, hash);
  if (!rule) {
    return state;
  }
  return {
    ...state,
    pathCollapsing: {
      ...state.pathCollapsing,
      currentEditableRule: {
        ...structuredClone(rule),
        currentRuleHash: collapsedPathToHash(rule),
        startNodeId: null,
        errorMessage: null,
      },
    },
  };
};

const cancelEditPathCollapsingRule = (
  state: TraversalBuilderState,
  editableRule: EditablePathCollapsingRule
): TraversalBuilderState => {
  const currentRuleBeingEdited = getRuleByHash(
    state,
    editableRule.currentRuleHash
  );

  const newState = viewpointBuilderGraphOperations.updatePathHighlighting(
    state,
    {
      shortestPath: null,
      highlightedPathId: editableRule.currentRuleHash,
    }
  );
  clearAllSelectedNodes(newState.graphNodes);

  if (!currentRuleBeingEdited?.path.length) {
    return viewpointBuilderGraphOperations.resetPathHighlightingForCollapsingRules(
      {
        ...newState,
        pathCollapsing: {
          ...newState.pathCollapsing,
          rules: newState.pathCollapsing.rules.filter(
            rule => collapsedPathToHash(rule) !== editableRule.currentRuleHash
          ),
          currentEditableRule: null,
        },
      }
    );
  }
  return viewpointBuilderGraphOperations.resetPathHighlightingForCollapsingRules(
    {
      ...newState,
      pathCollapsing: {
        ...newState.pathCollapsing,
        currentEditableRule: null,
      },
    }
  );
};

const setDisplayName = (
  state: TraversalBuilderState,
  displayText: string
): TraversalBuilderState => {
  // currentEditableRule is never undefined here, but TS doesn't know that
  if (!state.pathCollapsing.currentEditableRule) {
    return state;
  }

  return {
    ...state,
    pathCollapsing: {
      ...state.pathCollapsing,
      currentEditableRule: {
        ...state.pathCollapsing.currentEditableRule,
        displayText,
      },
    },
  };
};

const isSelectingStartNode = (
  state: TraversalBuilderState
): state is TraversalBuilderStateWithCurrentEditableRule => {
  const isEditableRule = isTraversalBuilderStateWithCurrentEditableRule(state);
  if (!isEditableRule) {
    return false;
  }
  const {
    pathCollapsing: { currentEditableRule },
  } = state;
  return Boolean(
    !currentEditableRule.startNodeId && currentEditableRule.path.length === 0
  );
};

const shouldIgnoreNodeSelection = (
  { pathCollapsing }: TraversalBuilderState,
  nodeId: string | null
) =>
  !pathCollapsing.currentEditableRule ||
  pathCollapsing.currentEditableRule.startNodeId === nodeId ||
  pathCollapsing.currentEditableRule.path.length !== 0;

const getErrorMessage = (
  state: TraversalBuilderState,
  shortestPath: ShortestPath | null
) => {
  if (!shortestPath || !stateIsTraversalBuilderStateWithStartNode(state)) {
    return null;
  }

  if (shortestPath.path.length < 2) {
    return CollapsingRuleErrorMessage.pathMustHideAtLeastOneComponent;
  }

  const collapsedComponentIds = getCollapsedNodeIds(
    state.graphEdges,
    shortestPath
  );

  if (collapsedComponentIds.has(state.startNode.id)) {
    return CollapsingRuleErrorMessage.collapsingPathHidesStartNode(
      state.startNode.getLabel()
    );
  }

  if (
    !(
      shortestPath.startNodeId === state.startNode.id ||
      shortestPath.endNodeId === state.startNode.id
    )
  ) {
    const errorMessage = getErrorMessageForDividingPath(state, shortestPath);
    if (errorMessage) {
      return errorMessage;
    }
  }

  const overlappingError = getErrorMessageForOverlappingPath(
    state,
    shortestPath
  );

  if (overlappingError) {
    return overlappingError;
  }

  const overlappingWithItselfError = getErrorMessageForOverlappingWithItself(
    state,
    shortestPath
  );

  if (overlappingWithItselfError) {
    return overlappingWithItselfError;
  }

  return null;
};

// A collapsing path cannot overlap with itself, e.g. a collapsing path of
// A -> A -> A could match in a traversal path of A -> A -> A -> A, but
// it would be unclear which part should be collapsed.
const getErrorMessageForOverlappingWithItself = (
  state: TraversalBuilderState,
  shortestPath: ShortestPath
) => {
  const rule = {
    displayText: '',
    referenceStyle: defaultCollapsedPathReferenceStyle,
    isActive: true,
    path: shortestPath.path.map(triple => ({
      ...triple,
      referenceType: namedDirectedTripleToBackendFormat(triple).referenceType,
    })),
  };
  const pathsWithGraphIds = editTraversalOperations.getTraversals(
    state,
    null,
    true
  ) as DirectedTripleWithFiltersWithGraphIds[][];
  const includePathIndexInEqualityCheck = true;
  const withValidation = false;
  const matches = getMatchingPaths(
    pathsWithGraphIds,
    rule,
    includePathIndexInEqualityCheck,
    withValidation
  );
  const groupedByPathIndexes = groupBy(matches, 'pathIndex');
  const matchesGroupedByPathIndexes = Object.values(groupedByPathIndexes).map(
    rules => rules.map(({ match }) => match)
  );
  if (!matchesGroupedByPathIndexes.every(validateMatchesAgainstOverlaps)) {
    return CollapsingRuleErrorMessage.overlappingWithItself;
  }
  return null;
};

// A new collapsing path cannot partially overlap with an existing collapsing
// path (with different start and end nodes).
const getErrorMessageForOverlappingPath = (
  state: TraversalBuilderState,
  shortestPath: ShortestPath
) => {
  const {
    pathCollapsing: { rules },
  } = state;
  const edgeIdsOfShortestPath = new Set(shortestPath.pathByReferenceIds);
  const allCollapsedPaths = rules.flatMap(({ virtualEdgeStates }) =>
    virtualEdgeStates.map(({ collapsedEdges }) => collapsedEdges)
  );

  return allCollapsedPaths.reduce<string | null>((error, collapsedEdges) => {
    if (error) {
      return error;
    }
    // We can work with sets here. Edge ids are uniq in the graph, that
    // means a given set represents a well defined path in the graph.
    const intersection = edgeIdsOfShortestPath.intersection(
      new Set(collapsedEdges)
    );
    if (intersection.size === 0) {
      return null;
    }
    if (intersection.size === edgeIdsOfShortestPath.size) {
      return CollapsingRuleErrorMessage.subpathOfExistingRule;
    }
    if (intersection.size === collapsedEdges.length) {
      return CollapsingRuleErrorMessage.superpathOfExistingPath;
    }
    // If the overlap is partial but starts on both paths at the start,
    // it's a valid overlap. That's basically a path which branches of a
    // collapsing path.
    if (
      isEqual(
        shortestPath.pathByReferenceIds.slice(0, intersection.size),
        collapsedEdges.slice(0, intersection.size)
      )
    ) {
      return null;
    }
    return CollapsingRuleErrorMessage.partiallyOverlappingPath;
  }, null);
};

type TraversalBuilderStateWithStartNode = TraversalBuilderState & {
  startNode: GraphNode;
};

const stateIsTraversalBuilderStateWithStartNode = (
  state: TraversalBuilderState
): state is TraversalBuilderStateWithStartNode => {
  return Boolean(state.startNode);
};

const getErrorMessageForDividingPath = (
  state: TraversalBuilderStateWithStartNode,
  shortestPath: ShortestPath
) => {
  const shortestPathStartNodeContext = getShortestPath(
    state,
    shortestPath.startNodeId,
    state.startNode.id
  );
  const shortestPathEndNodeContext = getShortestPath(
    state,
    shortestPath.endNodeId,
    state.startNode.id
  );
  if (!shortestPathStartNodeContext || !shortestPathEndNodeContext) {
    logError(
      Error(
        'Expected shortestPathStartNodeContext and shortestPathEndNodeContext to exist'
      )
    );
    return "Unexpected error. Please try again or contact Ardoq's support";
  }

  // The shorter of both paths must be contained in the other path, otherwise
  // the selected path could divide the graph in two disconnected graphs.
  if (
    shortestPathStartNodeContext.path.length <
    shortestPathEndNodeContext.path.length
  ) {
    const collapsedComponentIds = getCollapsedNodeIds(
      state.graphEdges,
      shortestPathEndNodeContext
    );
    if (!collapsedComponentIds.has(shortestPath.startNodeId)) {
      return CollapsingRuleErrorMessage.dividingGraph;
    }
  } else {
    const collapsedComponentIds = getCollapsedNodeIds(
      state.graphEdges,
      shortestPathStartNodeContext
    );
    if (!collapsedComponentIds.has(shortestPath.endNodeId)) {
      return CollapsingRuleErrorMessage.dividingGraph;
    }
  }
  return null;
};

const getCollapsedNodeIds = (
  graphEdges: Map<string, GraphEdge>,
  { startNodeId, endNodeId, pathByReferenceIds }: ShortestPath
) => {
  const collapsedComponentIds = new Set<string>(
    pathByReferenceIds.flatMap(id => {
      const edge = graphEdges.get(id);
      if (!edge) return [];
      const { source, target } = edge;
      return [source, target];
    })
  );
  collapsedComponentIds.delete(startNodeId);
  collapsedComponentIds.delete(endNodeId);

  return collapsedComponentIds;
};

const clearAllSelectedNodes = (graphNodes: Map<string, GraphNode>) =>
  Array.from(graphNodes.values()).forEach(graphNode => {
    graphNode.setIsSelected(false);
  });

const nodeSelected = (
  state: TraversalBuilderState,
  nodeId: string
): TraversalBuilderState => {
  if (shouldIgnoreNodeSelection(state, nodeId)) {
    return state;
  }
  if (isSelectingStartNode(state)) {
    const newGraphNodes = new Map(state.graphNodes);
    clearAllSelectedNodes(newGraphNodes);
    const node = newGraphNodes.get(nodeId);
    if (node) {
      node.setIsSelected(true);
    } else {
      logError(Error('Expected node to exist'));
      return state;
    }
    return {
      ...state,
      pathCollapsing: {
        ...state.pathCollapsing,
        currentEditableRule: {
          ...state.pathCollapsing.currentEditableRule,
          startNodeId: nodeId,
        },
      },
    };
  }

  const { currentEditableRule } = state.pathCollapsing;

  if (!currentEditableRule) {
    logError(Error('Expected currentEditableRule to exist'));
    return state;
  }

  const shortestPath = getShortestPath(
    state,
    currentEditableRule.startNodeId!,
    nodeId
  );

  if (!shortestPath) {
    return state;
  }

  const errorMessage = getErrorMessage(state, shortestPath);
  const { path } = shortestPath;

  const newCurrentEditableRule = {
    ...currentEditableRule,
    errorMessage,
    path,
  };
  const newState = viewpointBuilderGraphOperations.updatePathHighlighting(
    state,
    {
      shortestPath,
      highlightedPathId: currentEditableRule.currentRuleHash,
      currentEditableRule: newCurrentEditableRule,
      hasError: Boolean(errorMessage),
    }
  );
  newState.graphNodes.get(nodeId)!.setIsSelected(true);
  return newState;
};

const getPathCollapsingViewState = (state: TraversalBuilderState) => {
  const { pathCollapsing, graphNodes } = state;

  return {
    currentEditableRule: pathCollapsing.currentEditableRule
      ? transformCurrentEditableRule(
          pathCollapsing.currentEditableRule,
          graphNodes
        )
      : null,
    rules: transformRules(pathCollapsing.rules, graphNodes),
  };
};

const clearCollapsedPathNodes = (
  state: TraversalBuilderState
): TraversalBuilderState => {
  if (!state.pathCollapsing.currentEditableRule) {
    return state;
  }
  const newState = viewpointBuilderGraphOperations.updatePathHighlighting(
    state,
    {
      shortestPath: null,
      highlightedPathId:
        state.pathCollapsing.currentEditableRule.currentRuleHash,
    }
  );
  clearAllSelectedNodes(newState.graphNodes);
  return {
    ...newState,
    pathCollapsing: {
      ...state.pathCollapsing,
      currentEditableRule: {
        ...state.pathCollapsing.currentEditableRule,
        path: [],
        startNodeId: null,
      },
    },
  };
};

const getRuleByHash = (state: TraversalBuilderState, hash: string) =>
  state.pathCollapsing.rules.find(rule => collapsedPathToHash(rule) === hash);

const onGraphNodeHover = (
  state: TraversalBuilderState,
  nodeId: string | null
): TraversalBuilderState => {
  if (shouldIgnoreNodeSelection(state, nodeId) || isSelectingStartNode(state)) {
    return state;
  }

  const { currentEditableRule } = state.pathCollapsing;

  if (!currentEditableRule) {
    logError(Error('Expected currentEditableRule to exist'));
    return state;
  }

  const shortestPath = nodeId
    ? getShortestPath(state, currentEditableRule.startNodeId!, nodeId)
    : null;

  const errorMessage = getErrorMessage(state, shortestPath);

  const newCurrentEditableRule = {
    ...currentEditableRule,
    errorMessage,
  };

  return viewpointBuilderGraphOperations.updatePathHighlighting(state, {
    shortestPath,
    highlightedPathId: currentEditableRule.currentRuleHash,
    isFadedOut: Boolean(shortestPath),
    currentEditableRule: newCurrentEditableRule,
    hasError: Boolean(errorMessage),
  });
};

const collapsingRuleToggled = (
  state: TraversalBuilderState,
  { hash, isExpanded }: CollapsingRuleToggledPayload
): TraversalBuilderState => {
  const rule = getRuleByHash(state, hash);
  if (!rule) {
    return state;
  }
  return pipeReducers(
    {
      ...state,
      pathCollapsing: {
        ...state.pathCollapsing,
        rules: state.pathCollapsing.rules.map(r =>
          collapsedPathToHash(r) === hash ? { ...r, isExpanded } : r
        ),
      },
    },
    viewpointBuilderGraphOperations.resetPathHighlightingForCollapsingRules,
    editTraversalOperations.updateEdgesToBeRemovedOnCollapsing
  );
};

const onCollapsedPathTabUnselected = (
  state: TraversalBuilderState
): TraversalBuilderState => {
  const reducer = state.selectedGraphNodeId
    ? partialPayload(
        editTraversalOperations.toggleIsSelectedGraphNodeAndUpdateTripleOptions,
        {
          graphNodeId: state.selectedGraphNodeId,
          isForceSelected: true,
        }
      )
    : partialPayload(identity<TraversalBuilderState>);

  return pipeReducers(
    {
      ...state,
      pathCollapsing: {
        ...state.pathCollapsing,
        rules: state.pathCollapsing.rules.map(rule => ({
          ...rule,
          isExpanded: false,
        })),
      },
    },
    reducer,
    viewpointBuilderGraphOperations.resetPathHighlightingForCollapsingRules,
    editTraversalOperations.updateEdgesToBeRemovedOnCollapsing
  );
};

const toggleCollapsingRuleIsActive = (
  state: TraversalBuilderState,
  { hash, isActive }: CollapsingRuleIsActiveToggledPayload
) => {
  const rule = getRuleByHash(state, hash);
  if (!rule) {
    return state;
  }
  return pipeReducers(
    {
      ...state,
      pathCollapsing: {
        ...state.pathCollapsing,
        rules: state.pathCollapsing.rules.map(r =>
          collapsedPathToHash(r) === hash ? { ...r, isActive } : r
        ),
      },
    },
    processCollapsingRules,
    viewpointBuilderGraphOperations.resetPathHighlightingForCollapsingRules,
    editTraversalOperations.updateEdgesToBeRemovedOnCollapsing
  );
};

const processCollapsingRules = (
  state: TraversalBuilderState,
  rules: PathCollapsingRule[] = state.pathCollapsing.rules
): TraversalBuilderState => ({
  ...state,
  pathCollapsing: {
    ...state.pathCollapsing,
    rules: rules.map(rule =>
      editTraversalOperations.toInternalPathCollapsingRule(state, rule)
    ),
  },
});

export const collapsePathsOperations = {
  cancelEditPathCollapsingRule,
  clearCollapsedPathNodes,
  collapsingRuleToggled,
  createPathCollapsingRule,
  deletePathCollapsingRule,
  editPathCollapsingRule,
  getPathCollapsingViewState,
  nodeSelected,
  onCollapsedPathTabUnselected,
  onGraphNodeHover,
  savePathCollapsingRule,
  setDisplayName,
  setPathCollapsingRules,
  toggleCollapsingRuleIsActive,
};
