import { persistentReducedStream } from '@ardoq/rxbeach';
import { getDefaultState } from './utils';
import { reducers } from './reducers';
import { combineLatest, map } from 'rxjs';
import { graphSearchAccessControlHelpers } from 'resourcePermissions/accessControlHelpers/graphSearch';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';

const audienceTags$ = persistentReducedStream(
  'audienceTags$',
  getDefaultState(),
  reducers
);

export default combineLatest([
  audienceTags$,
  currentUserPermissionContext$,
]).pipe(
  map(([audienceTags, permissionContext]) => ({
    ...audienceTags,
    canRunGremlinQueries:
      graphSearchAccessControlHelpers.canCreateGraphFilter(permissionContext),
  }))
);
