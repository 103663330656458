import { useId } from 'react';
import { colors, s16 } from '@ardoq/design-tokens';
import { Select } from '@ardoq/select';
import { GhostButton } from '@ardoq/button';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { EditFilterCommands } from './viewpointBuilderFiltersCommands';
import {
  TraversalBuilderViewState,
  ViewpointBuilderFilter,
  ViewpointBuilderFiltersDefinitions,
} from '../types';
import { text1 } from '@ardoq/typography';
import styled from 'styled-components';
import {
  SelectOperator,
  getDefaultOperator,
  RuleValueInput,
} from '@ardoq/query-builder';
import { SidePanelSection } from 'viewpointBuilder/components/atoms';

type FilterProps = {
  commands: EditFilterCommands;
  filter: ViewpointBuilderFilter;
  availableFilterGroupedOptions: ViewpointBuilderFiltersDefinitions['componentFilterTypeOptions'];
  asyncLoaders: TraversalBuilderViewState['asyncLoaders'];
};

export const ViewpointBuilderFilterComponent = ({
  filter,
  availableFilterGroupedOptions,
  commands,
  asyncLoaders,
}: FilterProps) => {
  const markupId = useId();

  return (
    <FilterWrapper>
      <Grid>
        <label htmlFor={`${markupId}-select-type`}>Type</label>
        <Select
          inputId={`${markupId}-select-type`}
          value={filter.filterBy}
          options={availableFilterGroupedOptions}
          onValueChange={value => {
            if (value === filter.filterBy || !value) {
              return;
            }
            commands.updateFilter({
              graphNodeId: filter.graphNodeId,
              nonPersistentId: filter.nonPersistentId,
              partialFilter: { filterBy: value },
            });
          }}
        />

        <label htmlFor={`${markupId}-select-operator`}>Operator</label>
        <SelectOperator
          inputId={`${markupId}-select-operator`}
          selectedOperator={
            filter.operator || getDefaultOperator(filter.filterDefinition)
          }
          operators={filter.filterDefinition.operators}
          onValueChange={value =>
            commands.updateFilter({
              graphNodeId: filter.graphNodeId,
              nonPersistentId: filter.nonPersistentId,
              partialFilter: { operator: value },
            })
          }
          filterType={filter.filterDefinition.filterType}
        />

        <label htmlFor={`${markupId}-value`}>Value</label>
        <RuleValueInput
          key={filter.filterBy}
          operator={filter.operator}
          asyncSuggestionsLoaders={asyncLoaders.asyncSuggestionsLoaders}
          asyncLabelLoaders={asyncLoaders.asyncLabelLoaders}
          filterType={filter.filterDefinition.filterType}
          filter={filter.filterDefinition}
          value={filter.value}
          onValueChange={value => {
            commands.updateFilter({
              graphNodeId: filter.graphNodeId,
              nonPersistentId: filter.nonPersistentId,
              partialFilter: { value },
            });
          }}
        />
      </Grid>
      <GhostButton
        onClick={() =>
          commands.deleteFilter({
            graphNodeId: filter.graphNodeId,
            nonPersistentId: filter.nonPersistentId,
          })
        }
      >
        <Icon
          iconName={IconName.CLOSE}
          color={colors.grey50}
          iconSize={IconSize.MEDIUM}
        />
      </GhostButton>
    </FilterWrapper>
  );
};

const Grid = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: 10ch 1fr; /* label is fixed, we want the select value to take entire free space */
  gap: 10px;
  ${text1};
  color: ${colors.grey35};
  align-items: baseline;
`;

const FilterWrapper = styled(SidePanelSection)`
  padding: ${s16};
`;
