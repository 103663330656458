import { ArdoqId } from '@ardoq/api-types';
import {
  PerspectivesGroupsFields,
  PerspectivesGroupsTags,
} from '@ardoq/perspectives';

type GroupOptions = Record<
  ArdoqId,
  PerspectivesGroupsTags[] | PerspectivesGroupsFields[]
>;

type FetchedOptionType = {
  id: ArdoqId;
  label: string;
  workspaceId: ArdoqId;
};

export const mapFetchedOptionsToGroupOptions = (
  fetchedOptions: FetchedOptionType[]
) => {
  const groupOptions: GroupOptions = {};
  for (const { id, label: name, workspaceId } of fetchedOptions) {
    const tagsOrFields = groupOptions[workspaceId] ?? [];
    groupOptions[workspaceId] = [...tagsOrFields, { id, name }];
  }
  return groupOptions;
};
