import styled, { css } from 'styled-components';

const FormRow = styled('div')<{ $hidden?: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 12px 0;
  ${props =>
    props.$hidden
      ? css`
          display: none;
        `
      : ''}
`;

export default FormRow;
