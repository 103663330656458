import { actionCreator } from '@ardoq/rxbeach';
import {
  PayloadAdvancedSearchError,
  PayloadAdvancedSearchSetBranch,
  PayloadExportAdvancedSearch,
  PayloadQueryAdvancedSearch,
  PayloadQueryAdvancedSearchSuccess,
  PayloadSelectCondition,
  PayloadSelectFields,
  PayloadSelectFilterType,
  PayloadSelectPage,
  PayloadSetSort,
  PayloadUpdateAdvancedSearch,
} from './types';

export const selectCondition = actionCreator<PayloadSelectCondition>(
  '[AdvancedSearch] SELECT_CONDITION'
);

export const selectFilterType = actionCreator<PayloadSelectFilterType>(
  '[AdvancedSearch] SELECT_FILTER_TYPE'
);

export const selectPage = actionCreator<PayloadSelectPage>(
  '[AdvancedSearch] SELECT_PAGE'
);

export const setSort = actionCreator<PayloadSetSort>(
  '[AdvancedSearch] SET_SORT'
);

export const selectFields = actionCreator<PayloadSelectFields>(
  '[AdvancedSearch] SELECT_FIELDS'
);

export const queryAdvancedSearch = actionCreator<PayloadQueryAdvancedSearch>(
  '[AdvancedSearch] QUERY_ADVANCED_SEARCH'
);

export const queryAdvancedSearchSuccess =
  actionCreator<PayloadQueryAdvancedSearchSuccess>(
    '[AdvancedSearch] QUERY_ADVANCED_SEARCH_SUCCESS'
  );

export const updateAdvancedSearch = actionCreator<PayloadUpdateAdvancedSearch>(
  '[AdvancedSearch] UPDATE_ADVANCED_SEARCH'
);

export const exportAdvancedSearch = actionCreator<PayloadExportAdvancedSearch>(
  '[AdvancedSearch] EXPORT_ADVANCED_SEARCH'
);

export const exportAdvancedSearchSuccess = actionCreator(
  '[AdvancedSearch] EXPORT_ADVANCED_SEARCH_SUCCESS'
);

export const advancedSearchError = actionCreator<PayloadAdvancedSearchError>(
  '[AdvancedSearch] ADVANCED_SEARCH_ERROR'
);

export const setAdvancedSearchBranch =
  actionCreator<PayloadAdvancedSearchSetBranch>('[AdvancedSearch] SET_BRANCH');
