import { logError } from '@ardoq/logging';
import { NO_ACESS_TO_RESOURCE_MESSAGE } from 'consts';
import { api, tokenApi } from '@ardoq/api';
import { pluralize } from '@ardoq/common-helpers';
import { tokenTabViewModelOperations } from './tokenTabViewModelOperations';
import {
  CreateUpdateTokenResult,
  DeleteTokenResult,
  GetTokensResult,
} from './types';
import { APIToken, ArdoqId } from '@ardoq/api-types';
import { isArdoqError, ArdoqError } from '@ardoq/common-helpers';

/**
 * This function takes a `FetchError` and returns an error message
 * @param error the network error to process for display
 * @param knownErrors the list of expected errors with assorted display
 * @param defaultMessage what to display in case of an unexpected error
 */
function processErrors(error: ArdoqError, defaultMessage: string): string {
  logError(error);

  if (api.isForbidden(error)) {
    return NO_ACESS_TO_RESOURCE_MESSAGE;
  }

  return defaultMessage;
}

export function getTokenExpirationMessage(token: APIToken, now: Date): string {
  if (tokenTabViewModelOperations.isExpired(token, now)) {
    return `Expired`;
  }

  if (tokenTabViewModelOperations.isExpiringSoon(token, now)) {
    const daysUntilExpiration =
      tokenTabViewModelOperations.getdaysUntilExpiration(token, now);
    return `Expires in ${daysUntilExpiration} ${pluralize(
      'day',
      daysUntilExpiration
    )}`;
  }
  if (tokenTabViewModelOperations.isMoreThanSixtyDays(token, now)) {
    return `Your token is more than 60 days old.
      We recommend that you regenerate it!`;
  }
  return '';
}

export const getAllTokensAPI = async (): Promise<GetTokensResult> => {
  const tokens = await tokenApi.fetchAll();
  if (isArdoqError(tokens)) {
    const defaultMessage = 'An error occurred while retrieving your tokens';
    const message = processErrors(tokens, defaultMessage);
    return { status: 'error' as const, message };
  }
  return { status: 'success' as const, tokens };
};

export const getAllOrgTokensAPI = async (): Promise<GetTokensResult> => {
  const tokens = await tokenApi.fetchAllOrg();
  if (isArdoqError(tokens)) {
    const defaultMessage = 'An error occurred while retrieving your tokens';
    const message = processErrors(tokens, defaultMessage);
    return { status: 'error' as const, message };
  }
  return { status: 'success' as const, tokens };
};

export const createTokenAPI = async (
  tokenDescription: string
): Promise<CreateUpdateTokenResult> => {
  const requestPayload = { type: 'api', description: tokenDescription };
  const token = await tokenApi.create(requestPayload);
  if (isArdoqError(token)) {
    const defaultMessage = 'An error occurred while creating your token';
    const message = processErrors(token, defaultMessage);
    return { status: 'error' as const, message };
  }
  return { status: 'success' as const, token };
};

export const updateTokenAPI = async (
  payload: APIToken
): Promise<CreateUpdateTokenResult> => {
  const token = await tokenApi.update(payload);
  if (isArdoqError(token)) {
    const defaultMessage = 'An error occurred while updating your token';
    const message = processErrors(token, defaultMessage);
    return { status: 'error' as const, message };
  }
  return { status: 'success' as const, token };
};

export async function deleteTokenAPI(id: ArdoqId): Promise<DeleteTokenResult> {
  const defaultMessage = 'An error occurred while deleting your token';
  const result = await tokenApi.delete(id);
  if (isArdoqError(result)) {
    return { status: 'error' as const, message: defaultMessage };
  }
  return { status: 'success' as const };
}
