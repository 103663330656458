import { ButtonSize, IconButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import { useEffect, useState } from 'react';

export const DarkModeToggle = () => {
  const [isDarkmode, setIsDarkmode] = useState(() => {
    const storedDarkMode = window.localStorage.getItem('darkMode');
    return storedDarkMode === 'true';
  });

  useEffect(() => {
    window.localStorage.setItem('darkMode', isDarkmode ? 'true' : 'false');
    if (isDarkmode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkmode]);

  const toggleDarkMode = () => {
    setIsDarkmode(prevMode => !prevMode);
  };
  return (
    <IconButton
      onClick={toggleDarkMode}
      iconName={isDarkmode ? IconName.SUN : IconName.MOON}
      buttonSize={ButtonSize.SMALL}
    />
  );
};
