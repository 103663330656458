import { dispatchAction } from '@ardoq/rxbeach';
import { updateComponentTypeProps } from 'integrations/signavio-exporter/streams/configurationState/actions';
import { configState$ } from 'integrations/signavio-exporter/streams/configurationState/configurationState$';
import { dictionaryCategories$ } from 'integrations/signavio-exporter/streams/dictionaryCategories/dictionaryCategories$';
import { SelectableField } from 'integrations/signavio-exporter/streams/types';
import { combineLatest, map } from 'rxjs';
import { Maybe } from '@ardoq/common-helpers';
import { SelectionKeys } from './types';
import { isFieldMappingValid } from './utils';
import { performExport } from 'integrations/signavio-exporter/streams/api/actions';
import {
  addNewAttribute,
  updateDictionaryProps,
} from 'integrations/signavio-exporter/streams/dictionaryCategories/actions';

const onNext = () => {
  dispatchAction(performExport({ dryRun: true }));
};

function onFieldChange(
  componentType: string,
  fieldName: SelectionKeys,
  value: Maybe<SelectableField> | string
) {
  if (!value) return;

  const isNewValue = typeof value === 'string';
  const payload = {
    [fieldName]: isNewValue ? { label: value, value: null } : value,
  };

  dispatchAction(updateComponentTypeProps({ componentType, props: payload }));

  if (isNewValue) {
    if (fieldName === 'dictionaryCategory') {
      dispatchAction(
        updateDictionaryProps({ category: value, props: payload[fieldName] })
      );
    } else {
      dispatchAction(addNewAttribute(value));
    }
  }

  if (fieldName === 'dictionaryCategory') {
    // Reset the mappedOid and mappedComponentUrl when dictionary category is changed
    dispatchAction(
      updateComponentTypeProps({
        componentType,
        props: {
          mappedOid: { value: null, label: '' },
          mappedComponentUrl: { value: null, label: '' },
        },
      })
    );
  }
}

export const viewModel$ = combineLatest([
  configState$,
  dictionaryCategories$,
]).pipe(
  map(([configurationState, dictionaryCategories]) => {
    const fieldMappingIsValid = isFieldMappingValid(configurationState);
    return {
      fieldMappingIsValid,
      configurationState,
      dictionaryCategories,
      onFieldChange,
      onNext,
    };
  })
);
