import { action$, dispatchAction, ofType } from '@ardoq/rxbeach';
import { Observable, switchMap } from 'rxjs';
import { api, useCaseApi } from '@ardoq/api';
import { map, startWith, tap } from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators';
import { getUseCases } from './actions';
import { withOnFirstSubscription } from 'streams/utils/streamUtils';
import { UseCaseSummary } from '@ardoq/api-types';
import { loadUseCaseSuccess } from './UseCaseLandingPage/actions';

const defaultValue: UseCaseSummary[] = [];

const useCases$: Observable<UseCaseSummary[]> = withOnFirstSubscription(
  action$.pipe(
    ofType(getUseCases, loadUseCaseSuccess), // after loading a use case, the id changes. So we should refetch
    switchMap(useCaseApi.fetchAvailable),
    tap(response => api.logErrorIfNeeded(response)),
    map(response => api.defaultValueIfError(response, defaultValue)),
    startWith(defaultValue),
    tag('useCases$')
  ),
  () => dispatchAction(getUseCases())
);
export default useCases$;
