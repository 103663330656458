import { PermissionGroup } from '@ardoq/api-types';
import { GroupsState } from './types';

const setLoadingState = (currentState: GroupsState) => ({
  ...currentState,
  isLoading: true,
});

const resetLoadingState = (currentState: GroupsState) => ({
  ...currentState,
  isLoading: false,
});

const setGroupsState = (
  currentState: GroupsState,
  groups: PermissionGroup[]
) => ({
  ...currentState,
  groups,
});

const setGroupsStateAfterLoading = (
  currentState: GroupsState,
  groups: PermissionGroup[]
) => {
  const updatedState = resetLoadingState(currentState);
  return setGroupsState(updatedState, groups);
};

export const groupsOperations = {
  setLoadingState,
  resetLoadingState,
  setGroupsState,
  setGroupsStateAfterLoading,
};
