import { GhostButton } from '@ardoq/button';
import { colors } from '@ardoq/design-tokens';
import { Box, Stack } from '@ardoq/layout';
import { Header3, Header4, Text } from '@ardoq/typography';
import styled from 'styled-components';
import { showSupport } from 'utils/support';
import expiredTrialBackground from './expiredTrialBackground.png';
import { trackEvent } from 'tracking/tracking';

export const TrialButton = styled(GhostButton)`
  color: ${colors.textOnInvertedStrong};
  background-color: ${colors.pink60};
  &:hover {
    background-color: ${colors.pink50};
  }
  &:active {
    background-color: ${colors.pink50};
  }
  &:disabled {
    color: ${colors.textOnInvertedStrong};
    background-color: ${colors.pink80};
  }
`;

export const TrialWrapper = styled(Box)`
  border: 1px solid ${colors.pink60};
  background: url(${expiredTrialBackground}),
    linear-gradient(
      69deg,
      ${colors.surfaceDefault} 46.39%,
      ${colors.pink95} 86.11%
    );
  background-size: cover;
`;

export const SurveyResponseApprovalTrialExpiredInfo = () => {
  return (
    <TrialWrapper
      paddingX="large"
      paddingY="medium"
      margin="medium"
      borderRadius="medium"
    >
      <Stack gap="medium">
        <Stack gap="xsmall">
          <Header3>Response approvals</Header3>
          <Header4 color="textSubtle">Trial period has ended</Header4>
          <Text variant="caption" color="textSubtle">
            Want to continue maintaining data accuracy with this feature?
          </Text>
        </Stack>
        <div>
          <TrialButton
            onClick={() => {
              trackEvent('Survey change approval: clicked expired trial CTA');
              showSupport({
                chat: true,
                prePopulateMessage:
                  "My survey response approval trial has ended, and I'd like to request permanent access. How can I get this enabled for my organization?",
              });
            }}
          >
            Chat with us
          </TrialButton>
        </div>
      </Stack>
    </TrialWrapper>
  );
};
