import { forkJoin } from 'rxjs';
import {
  debounceTime,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
  withNamespace,
} from '@ardoq/rxbeach';
import { recalculateCalculatedField } from './actions';
import {
  QueryEditorNamespace,
  calculatedFieldQueryEditor$,
} from 'search/QueryEditor/queryEditor$';
import { getFieldIdsOfQuery } from 'streams/queries/calculatedFieldOptions/helpers';
import {
  queryGremlinSearchSuccess,
  queryGremlinSearchWarning,
} from 'search/Gremlin/actions';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { APIFieldType, SearchType } from '@ardoq/api-types';
import { saveSearchQuery } from 'search/actions';
import { pluralize } from '@ardoq/common-helpers';
import { isDateStringValid } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { fieldApi } from '@ardoq/api';

const validateCalculatedTypeMap: Map<APIFieldType, (value: any) => boolean> =
  new Map([
    [APIFieldType.NUMBER, (value: any) => typeof value === 'number'],
    [APIFieldType.CHECKBOX, (value: any) => typeof value === 'boolean'],
    [
      APIFieldType.DATE_ONLY,
      (value: any) => isDateStringValid(value, getCurrentLocale()),
    ],
    [
      APIFieldType.DATE_TIME,
      (value: any) => isDateStringValid(value, getCurrentLocale()),
    ],
    [APIFieldType.TEXT, () => true],
    [APIFieldType.LIST, () => true],
    [APIFieldType.URL, (value: any) => typeof value === 'string'],
    [APIFieldType.EMAIL, (value: any) => typeof value === 'string'],
  ]);

const validateCalculatedFieldResults = routine(
  withNamespace(SearchType.CALCULATED_FIELD_QUERY),
  ofType(queryGremlinSearchSuccess),
  extractPayload(),
  withLatestFrom(calculatedFieldQueryEditor$),
  map(
    ([
      { results, totalResults },
      {
        queryParams: { ids },
        selectedQueryId,
      },
    ]) => {
      if (ids && totalResults !== ids.length) {
        return `Size of results (${totalResults}) does not match the number (${ids.length}) of components or references in the workspace.`;
      }
      if (results.length) {
        const fieldId = getFieldIdsOfQuery(selectedQueryId!)[0];
        const field = fieldInterface.getFieldData(fieldId!);
        if (field) {
          if (results[0].value === undefined || results[0].id === undefined) {
            return 'A calculated fields needs the attributes "id" and "value" to exists.';
          }
          const resultType = results[0].value;
          if (!validateCalculatedTypeMap.get(field.type)!(resultType)) {
            return `The type of the result (${typeof resultType}) does not match the type of the field (${
              field.type
            }). This might result in undesired formatting or no value at all.`;
          }
          if (!field.defaultValue && field.type === APIFieldType.LIST)
            return 'You have not selected any options for the calculated list. These options are what the field is allowed to show. Please return to the field editor and add some options.';
          if (!field.defaultValue) return null;
          if (typeof field.defaultValue !== 'string') return null;
          const options = field.defaultValue.split(',');
          const invalidResults = results.filter(
            ({ value }) => !options.includes(value.toString())
          );
          if (invalidResults.length) {
            const invalidResultsString = `${[
              ...new Set(invalidResults.map(e => e.value)),
            ]
              .slice(0, 4)
              .join('", "')}`;
            const optionsString = options.join('", "');
            return `Some of the results do not match the options for the calculated field. "${invalidResultsString}" is not valid. The options are: "${optionsString}". Found ${
              invalidResults.length
            } ${pluralize(
              'occurrence',
              invalidResults.length
            )} which are not valid.`;
          }
        }
      }
      return null;
    }
  ),
  tap((searchWarning: string | null) => {
    if (!searchWarning) return;
    dispatchAction(
      queryGremlinSearchWarning({
        searchWarning,
      }),
      SearchType.CALCULATED_FIELD_QUERY
    );
  })
);

const handleRecalculateCalculatedField = routine(
  ofType(recalculateCalculatedField),
  extractPayload(),
  map(({ calculatedFieldQueryId, fieldId, branchId }) => ({
    fieldIds: fieldId ? [fieldId] : getFieldIdsOfQuery(calculatedFieldQueryId!),
    branchId: branchId,
  })),
  debounceTime(1000),
  switchMap(({ fieldIds, branchId }) =>
    forkJoin(fieldIds.map(fieldId => fieldApi.recalculate(fieldId, branchId)))
  )
);

const recalculateAndTestOnSave = routine(
  withNamespace(QueryEditorNamespace.CALCULATED_FIELD_QUERY),
  ofType(saveSearchQuery),
  extractPayload(),
  tap(({ queryId }) => {
    dispatchAction(
      recalculateCalculatedField({
        calculatedFieldQueryId: queryId!,
      })
    );
  })
);

export const calculatedSearchRoutines = collectRoutines(
  validateCalculatedFieldResults,
  handleRecalculateCalculatedField,
  recalculateAndTestOnSave
);
