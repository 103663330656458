import {
  ImportRoute,
  OverviewRoute,
} from 'integrations/common/navigation/types';

export enum ITPediaImportRoutes {
  DATA_SELECTION = ImportRoute.SELECT_DATA,
  FIELD_MAPPING = 'field-mapping',
  SUMMARY_SYNC = 'summary-sync',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export type ITPediaRoutes = OverviewRoute | ITPediaImportRoutes;
