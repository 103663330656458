import { modal, alert } from '@ardoq/modal';
import RestoreDeletedView from './components/RestoreDeletedView';
import { ArdoqId } from '@ardoq/api-types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

export const restoreDeletedView = (id: ArdoqId) => {
  const workspaceName = workspaceInterface.getWorkspaceName(id);
  if (workspaceName) {
    modal<boolean>(
      resolve => (
        <RestoreDeletedView
          workspaceName={workspaceName}
          closeModal={() => resolve(false)}
        />
      ),
      { shouldCloseOnBackdropClick: false, shouldCloseOnEscapeKey: false }
    );
  } else {
    alert({
      title: 'Workspace not found',
      text: `The workspace with id ${id} could not be found`,
    });
  }
};
