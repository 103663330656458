import { ReportReader, ReportReaderProps } from '@ardoq/report-reader';
import { connect } from '@ardoq/rxbeach';
import Navbar from 'views/navbar/Navbar';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import {
  ExportReportButton,
  MoreMenuButton,
  NavBarButtonProps,
  ToolbarButtons,
} from './NavBarButtons';
import { reportReaderViewModel$ } from './reportReaderViewModel$';

export type ReportReaderContainerProps = {
  navBarButtonProps: NavBarButtonProps;
  reportName: string;
  reportReaderProps: ReportReaderProps;
};

const ReportReaderContainer = ({
  navBarButtonProps,
  reportReaderProps,
  reportName,
}: ReportReaderContainerProps) => (
  <PageWrapper>
    <Navbar
      primaryContent={reportName}
      secondaryContent="Report reader"
      primaryButton={
        <ExportReportButton {...navBarButtonProps.exportButtonProps} />
      }
      secondaryButton={
        <MoreMenuButton {...navBarButtonProps.moreMenuButtonProps} />
      }
      toolbarContent={
        <ToolbarButtons {...navBarButtonProps.toolbarButtonProps} />
      }
    />
    <PageBody skipPadding>
      <ReportReader {...reportReaderProps} />
    </PageBody>
  </PageWrapper>
);

export default connect(ReportReaderContainer, reportReaderViewModel$);
