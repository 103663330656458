import { APIComponentAttributes } from '@ardoq/api-types';
import { EnhancedScopeData } from '@ardoq/data-model';
import {
  getComponentRepresentationData,
  getComponentColor,
  ComponentRepresentation,
} from '@ardoq/renderers';
import { FlexBox } from '@ardoq/layout';
import { Text } from '@ardoq/typography';

const ComponentNameAndIcon = ({
  scopeData,
  component,
}: {
  scopeData: EnhancedScopeData;
  component?: APIComponentAttributes;
}) => {
  if (!component) return null;
  const componentRepresentationData = getComponentRepresentationData(
    component._id,
    scopeData
  );
  const color = getComponentColor(component._id, scopeData);
  return (
    <FlexBox gap="xxsmall" align="center">
      {componentRepresentationData && color && (
        <ComponentRepresentation
          {...componentRepresentationData}
          style={{ color }}
        />
      )}
      <Text variant="text1">{component.name}</Text>
    </FlexBox>
  );
};

export default ComponentNameAndIcon;
