import styled from 'styled-components';
import { colors, s8, s64, s4, s16 } from '@ardoq/design-tokens';
import { header4 } from '@ardoq/typography';
import { disabledCSS } from '@ardoq/style-helpers';
import { Stack } from '@ardoq/layout';

export const ViewpointNavigatorList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 ${s16} ${s16};
  overflow: auto;
  display: flex;
  flex-flow: column;
  gap: ${s8};
`;

export const ViewpointNavigatorItemHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ViewpointNavigatorItemLabel = styled.h4`
  ${header4};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin: 0;
`;

export const ViewpointNavigatorItemBody = styled(Stack)<{ $isHidden: boolean }>`
  padding: ${s8};
  width: calc(100% - ${s64});
  ${({ $isHidden }) => $isHidden && disabledCSS}
`;

export const ViewpointNavigatorItemVisibilityButton = styled.button`
  border: none;
  background-color: ${colors.grey90};
  border-radius: ${s8} 0 0 ${s8};
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${colors.grey80};
  }
`;

export const ViewpointNavigatorIconButton = styled.button<{
  $isHidden?: boolean;
  $isActive?: boolean;
}>`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  padding: ${s4};
  border-radius: ${s4};
  cursor: ${({ $isHidden }) => ($isHidden ? 'default' : 'pointer')};
  &:hover {
    background-color: #00000008; /* black with low opacity to make it visible on both white and grey backgrounds */
  }
  ${({ $isActive }) => $isActive && `background-color: #00000012;`}

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
`;
