import { DataSourceItem, MergeTableSectionType } from './types';
import { SectionHeaderLabel } from './atoms';
import { APIEntityType } from '@ardoq/api-types';

const APIEntityTypeMap = new Map([
  [APIEntityType.COMPONENT, 'components'],
  [APIEntityType.COMPONENT_TYPE, 'component types'],
  [APIEntityType.REFERENCE, 'references'],
  [APIEntityType.REFERENCE_TYPE, 'reference types'],
  [APIEntityType.FIELD, 'fields'],
  [APIEntityType.TAG, 'tags'],
]);

const getSectionHeaderLabel = (
  sectionType: MergeTableSectionType,
  entityType: APIEntityType
) => {
  const entityTypeName = APIEntityTypeMap.has(entityType)
    ? APIEntityTypeMap.get(entityType)
    : '';
  if (sectionType === MergeTableSectionType.DELETED_IN_BRANCH)
    return `Recreate ${entityTypeName} deleted in scenario`;
  if (sectionType === MergeTableSectionType.DELETED_IN_MAINLINE)
    return `Recreate ${entityTypeName} deleted in mainline`;
  if (sectionType === MergeTableSectionType.CREATED_IN_BRANCH)
    return `Delete ${entityTypeName} created in scenario`;
  return null;
};

const sectionHeaderRenderer = ({ sectionType, entityType }: DataSourceItem) => {
  if (!sectionType) return null;
  const label = getSectionHeaderLabel(sectionType, entityType);
  return label ? <SectionHeaderLabel>{label}</SectionHeaderLabel> : null;
};

export default sectionHeaderRenderer;
