import { ImportHistory } from '@ardoq/api-types/integrations';
import { Group, Integration } from 'integrations/types';

export const getTransferCount = (imports: ImportHistory[]) =>
  imports.reduce(
    (acc, val) => {
      if (val.ardoq.entityType.includes('import')) return [acc[0] + 1, acc[1]];
      else if (val.ardoq.entityType.includes('export'))
        return [acc[0], acc[1] + 1];
      return acc;
    },
    [0, 0]
  );

export const getApiGuides = (integrations: Integration[]) =>
  integrations
    .filter(integration => integration.group === Group.GUIDES)
    .sort(
      (integrationA, integrationB) =>
        Number(integrationA.disableOptions?.isDisabled) -
        Number(integrationB.disableOptions?.isDisabled)
    );
