import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { catchError, filter, from, switchMap, tap } from 'rxjs';
import { deleteAccount, updateAccountState, upsertAccount } from './actions';
import {
  deleteAccountRequest,
  upsertAccountRequest,
} from 'integrations/ITPedia/api/accounts';
import { showToast, ToastType } from '@ardoq/status-ui';
import { handleApiError } from 'integrations/common/utils/api';
import {
  listConnections,
  upsertConnectionInitiate,
} from 'integrations/common/streams/connections/actions';
import { startNewAccountDialog } from 'integrations/ITPedia/Overview/Accounts/NewAccountDialog';

const handleUpsertAccount = routine(
  ofType(upsertAccount),
  switchMap(({ payload }) => {
    dispatchAction(updateAccountState({ upsertStatus: 'Loading' }));
    return from(upsertAccountRequest(payload));
  }),
  tap(() => {
    dispatchAction(updateAccountState({ upsertStatus: 'Success' }));
    dispatchAction(listConnections({ integrationId: 'it-pedia' }));
  }),
  catchError(error => {
    dispatchAction(
      updateAccountState({
        upsertStatus: 'Failed',
        upsertMessage: error,
      })
    );
    return handleApiError(error);
  })
);

const handleDeleteAccount = routine(
  ofType(deleteAccount),
  switchMap(({ payload }) => {
    return from(deleteAccountRequest(payload));
  }),
  tap(({ name }) => {
    showToast(`Deleted ${name}`, ToastType.SUCCESS);
    dispatchAction(listConnections({ integrationId: 'it-pedia' }));
  }),
  catchError(error => {
    showToast(`Failed to delete, Error: ${error}`, ToastType.INFO);
    return handleApiError(error);
  })
);

const handleUpsertConnectionInitiate = routine(
  ofType(upsertConnectionInitiate),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'it-pedia'),
  tap(({ connection }) => {
    startNewAccountDialog(connection);
  })
);

export default [
  handleUpsertAccount,
  handleDeleteAccount,
  handleUpsertConnectionInitiate,
];
