import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  deleteReference,
  reverseReference,
  toggleLockReference,
} from 'streams/references/ReferenceActions';
import { editReference, selectReference } from './actions';
import {
  ReferenceModel,
  ReferenceOptionsState,
  referenceOptions$,
} from './referenceOptions$';
import {
  SidebarMenu,
  SidebarMenuMenu,
  SidebarMenuSection,
} from '@ardoq/sidebar-menu';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { APIEntityType } from '@ardoq/api-types';
import { Icon, IconName } from '@ardoq/icons';
import { Features, hasFeature } from '@ardoq/features';
import { trackAuditLogEntryPoint } from 'auditLog/tracking';
import { navigateToAuditLog } from 'router/navigationActions';
import { getNameFromIdAndCollection } from 'auditLog/utils';

type ReferenceLinkProps = {
  readonly reference: ReferenceModel;
};
const ReferenceLink = ({ reference }: ReferenceLinkProps) => (
  <>
    <span>{reference.leftName}</span>
    {reference.arrowDirection === 'right' ? (
      <Icon
        iconName={IconName.ARROW_RIGHT_ALT}
        style={{ verticalAlign: 'middle', lineHeight: '14px' }}
      />
    ) : (
      <Icon
        iconName={IconName.ARROW_RIGHT_ALT}
        style={{
          verticalAlign: 'middle',
          lineHeight: '14px',
          rotate: '180deg',
        }}
      />
    )}
    <span>{reference.rightName}</span>
  </>
);

const getNoAccessMessage = () =>
  `You must have edit access in the reference source ${
    hasFeature(Features.PERMISSION_ZONES) ? 'component' : 'workspace'
  } to edit a reference.`;

const ReferenceOptions = ({
  hasWorkspaceWriteAccess,
  canEditReference,
  canEditReferencesTargetComponent,
  isViewVersionHistoryDisabled,
  references,
  contextReferenceId,
  locked,
  canToggleLock,
}: ReferenceOptionsState) => {
  if (!hasWorkspaceWriteAccess && !canEditReference) {
    return <SidebarMenu emptyContentMessage={getNoAccessMessage()} />;
  }
  if (!contextReferenceId && !references.length) {
    return (
      <SidebarMenu emptyContentMessage="In order for references to appear here, please select a component." />
    );
  }
  if (!contextReferenceId) {
    return (
      <SidebarMenu>
        <SidebarMenuSection
          title="Please select a reference"
          isCollapsible={false}
        >
          <SidebarMenuMenu
            options={references.map(reference => {
              return {
                label: <ReferenceLink reference={reference} />,
                onClick: () => dispatchAction(selectReference(reference._id)),
                dataClickId: `select-reference-${reference._id}-menu-item`,
              };
            })}
          />
        </SidebarMenuSection>
      </SidebarMenu>
    );
  }
  return (
    <SidebarMenu>
      <SidebarMenuSection title="Edit reference" isCollapsible={false}>
        <SidebarMenuMenu
          options={[
            {
              label: 'Edit reference properties',
              onClick: () => dispatchAction(editReference(contextReferenceId)),
              isDisabled: locked,
              dataClickId: 'edit-reference-properties-menu-item',
            },
            {
              label: 'Delete reference',
              onClick: () =>
                dispatchAction(deleteReference(contextReferenceId)),
              isDisabled: locked,
              dataClickId: 'delete-reference-menu-item',
            },
            canEditReferencesTargetComponent && {
              label: 'Reverse reference',
              onClick: () =>
                dispatchAction(reverseReference(contextReferenceId)),
              isDisabled: locked,
              dataClickId: 'reverse-reference-menu-item',
            },
            canToggleLock && {
              label: locked ? 'Unlock reference' : 'Lock reference',
              onClick: () =>
                dispatchAction(toggleLockReference(contextReferenceId)),
              dataClickId: 'toggle-lock-reference-menu-item',
            },
          ].filter(ExcludeFalsy)}
        />
      </SidebarMenuSection>
      <SidebarMenuSection title="Actions" isCollapsible={false}>
        <SidebarMenuMenu
          options={[
            {
              label: 'View reference history',
              onClick: () => {
                trackAuditLogEntryPoint('reference history');
                dispatchAction(
                  navigateToAuditLog({
                    entities: [
                      {
                        id: contextReferenceId,
                        name:
                          getNameFromIdAndCollection(
                            contextReferenceId,
                            APIEntityType.REFERENCE
                          ) ?? 'Missing name', // getNameFromIdAndCollection can potentially return null if the entity is not loaded in the backbone collection
                      },
                    ],
                    entityType: APIEntityType.REFERENCE,
                  })
                );
              },
              isDisabled: isViewVersionHistoryDisabled,
              dataClickId: 'view-reference-history-menu-item',
            },
          ]}
        />
      </SidebarMenuSection>
    </SidebarMenu>
  );
};

export default connect(ReferenceOptions, referenceOptions$);
