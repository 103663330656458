import {
  AudiencePreview,
  AudienceStartingPage,
  InstanceProgress,
  SortOrder,
} from '@ardoq/api-types';
import { Column, ExpandableRowData } from '@ardoq/table';
import { AudienceDataSourceItem, getAudienceByPersonDataSource } from './utils';
import { SortByFn, SortById } from '@ardoq/pagination';
import {
  ExpandRow,
  ExpandableTableProps,
} from 'broadcasts/components/expandableTable/types';
import ExpandableTable from 'broadcasts/components/expandableTable/ExpandableTable';

type AudienceTableByPersonProps = {
  audiencePreview: AudiencePreview;
  audienceStartingPage?: AudienceStartingPage;
  progress?: InstanceProgress;
  tableFilter?: string;
  getColumnConfig: (
    expandedRowIds: string[],
    expandRow: ExpandRow,
    sortByFn: SortByFn,
    sortById: SortById,
    sortOrder: SortOrder
  ) => Column<ExpandableRowData<AudienceDataSourceItem>>[];
  defaultSortById: string;
} & Pick<
  ExpandableTableProps<AudienceDataSourceItem>,
  'loading' | 'renderEmptyTable'
>;

const AudienceTableByPerson = ({
  audiencePreview,
  progress = {},
  getColumnConfig,
  loading,
  audienceStartingPage,
  renderEmptyTable,
  defaultSortById,
  tableFilter = '',
}: AudienceTableByPersonProps) => {
  const dataSource = getAudienceByPersonDataSource(
    audiencePreview,
    audienceStartingPage,
    tableFilter,
    progress
  );
  return (
    <ExpandableTable
      dataSource={dataSource}
      // @ts-expect-error TODO: Fix it, issue found when better TS notation for DatasourceTable was added
      getColumnConfig={getColumnConfig}
      loading={loading}
      renderEmptyTable={renderEmptyTable}
      defaultSortById={defaultSortById}
      sortNumbersAfterStrings={true}
      showPaginationBar={true}
      scrollableSectionHeight={'100vh'}
      highlight={tableFilter}
    />
  );
};

export default AudienceTableByPerson;
