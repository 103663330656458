import { forwardRef } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import { Field, TextInput } from '@ardoq/forms';
import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { setDashboardDescription, setDashboardName } from '../actions';
import DashboardBuilderSidebar from './DashboardBuilderSidebar';
import { isDashboardNameValid } from '../utils';
import { RichTextEditorTransition } from '@ardoq/rich-text-editor-2024';

interface DashboardNameSidebarProps {
  name?: string;
  description?: string;
  hasValidationError: boolean;
}

const DashboardNameSidebar = forwardRef<
  HTMLFormElement,
  React.PropsWithChildren<DashboardNameSidebarProps>
>(({ name, description, hasValidationError }, ref) => (
  <DashboardBuilderSidebar title="Edit dashboard name" ref={ref}>
    <SidebarMenuSection
      title="Dashboard name and description"
      isCollapsible={false}
    >
      <TextInput
        autoFocus
        label="Give your dashboard a name"
        value={name}
        placeholder="Add dashboard name here..."
        onValueChange={value => dispatchAction(setDashboardName(value))}
        errorMessage={
          hasValidationError && !isDashboardNameValid(name)
            ? 'Dashboard must have a name.'
            : undefined
        }
        dataTestId="dashboard-name-input"
      />

      <Field label="Describe your dashboard" isOptional>
        <RichTextEditorTransition
          placeholder="What insights and data is in this dashboard?"
          content={description ?? ''}
          onInputChange={value =>
            dispatchAction(setDashboardDescription(value))
          }
        />
      </Field>
    </SidebarMenuSection>
  </DashboardBuilderSidebar>
));

export default DashboardNameSidebar;
