import { useEffect, useState } from 'react';
import restoreDeleted$ from '../restoreDeleted$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { Wrapper } from './atoms';
import { anyRestored } from '../utils';
import MainSection from './MainSection';
import { getDeletedEntitiesByWorkspaceId } from 'restoreDeleted/actions';
import Context from 'context';
import { combineLatest } from 'rxjs';
import { RestoreDeletedViewModel } from 'restoreDeleted/types';
import { RestoreDeletedItems } from 'dialog/utilsDialog';

interface RestoreDeletedViewProperties {
  viewModel: RestoreDeletedViewModel;
  closeModal: () => void;
  workspaceName: string;
}
const RestoreDeletedView = ({
  viewModel,
  closeModal,
  workspaceName,
}: RestoreDeletedViewProperties) => {
  const [doRefresh, setRefresh] = useState<boolean>(false);
  useEffect(() => {
    const workspaceId = Context.activeWorkspaceId();
    if (!workspaceId) return;
    dispatchAction(
      getDeletedEntitiesByWorkspaceId({
        workspaceId,
      })
    );
  }, []);
  const { components, references } = viewModel;
  useEffect(() => {
    setRefresh(anyRestored(components, references));
  }, [components, references]);
  const handleClick = () => {
    return doRefresh ? window.location.reload() : closeModal();
  };

  return (
    <RestoreDeletedItems
      headerText={`Restore from ${workspaceName}`}
      onCloseButtonClick={handleClick}
      primaryButtonText={doRefresh ? 'Refresh' : 'Close'}
      onPrimaryButtonClick={handleClick}
      text={
        <Wrapper>
          <MainSection {...viewModel} />
        </Wrapper>
      }
    ></RestoreDeletedItems>
  );
};

export default connect(
  RestoreDeletedView,
  combineLatest({
    viewModel: restoreDeleted$,
  })
);
