import { combineLatest } from 'rxjs';
import scenarios$ from 'streams/scenarios/scenarios$';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { map } from 'rxjs/operators';

export const currentScenario$ = combineLatest([
  activeScenario$,
  scenarios$,
]).pipe(
  map(([{ scenarioId }, scenarios]) =>
    scenarioId ? { ...scenarios.byId[scenarioId] } : null
  )
);
