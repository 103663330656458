import { ArdoqId } from '@ardoq/api-types';

type ContextGraphItemBase = {
  id: ArdoqId;
  name: string;
};
type WorkspaceContextGraphItem = ContextGraphItemBase & {
  fill: string;
  type: ContextGraphItemType.WORKSPACE;
};
type ComponentContextGraphItem = ContextGraphItemBase & {
  fill?: string;
  type: ContextGraphItemType.COMPONENT;
};

export enum ContextGraphItemType {
  WORKSPACE,
  COMPONENT,
}
export type ContextGraphItem =
  | WorkspaceContextGraphItem
  | ComponentContextGraphItem;
