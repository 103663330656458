import { Maybe } from '@ardoq/common-helpers';
import { SelectionState } from './streams/selectionState/types';
import { StepState } from '@ardoq/steppers';
import {
  areFetchedResourcesInSync,
  isValidSelectionState,
} from './streams/selectionState/utils';
import { RegionsState } from 'integrations/cloudProviders/streams/regions/types';
import { ResourceTypesState } from 'integrations/cloudProviders/streams/resourceTypes/types';
import { SubscriptionsState } from './streams/subscriptions/types';
import { AsyncStatus } from 'integrations/common/types/api';
import { TabularMapping } from 'integrations/common/streams/tabularMappings/types';
import { TransferState } from 'integrations/common/streams/transferState/types';
import {
  isDryRunOutOfDate,
  isSuccessTransfer,
} from 'integrations/common/streams/transferState/utils';
import { ResourceGroupsState } from './streams/resourceGroups/types';
import { CloudProviderConfig } from '@ardoq/api-types/integrations';
import { ImportRoute } from 'integrations/common/navigation/types';

export const getSelectDataStepState = ({
  selectionState,
  selectedConnectionIds,
  regionsState,
  resourceGroupsState,
  resourceTypesState,
}: {
  integrationPath: Maybe<string>;
  selectionState: SelectionState;
  selectedConnectionIds: string[];
  regionsState: RegionsState;
  resourceGroupsState: ResourceGroupsState;
  resourceTypesState: ResourceTypesState;
}) => {
  if (
    isValidSelectionState({
      selectionState,
      selectedConnectionIds,
      regionsState,
      resourceGroupsState,
      resourceTypesState,
    })
  ) {
    return StepState.DONE;
  }

  return StepState.ACTIVE;
};

export const getLoadingMessage = ({
  resourceTypes,
  selectionState,
  subscriptions,
  resourceGroups,
  transferState,
  regionState,
  connectionsState,
}: {
  resourceTypes: ResourceTypesState;
  selectionState: SelectionState;
  subscriptions: SubscriptionsState;
  resourceGroups: ResourceGroupsState;
  transferState: { asyncStatus: AsyncStatus };
  regionState: RegionsState;
  connectionsState: { asyncStatus: AsyncStatus };
}) => {
  if (transferState.asyncStatus === 'LOADING') {
    return 'Reviewing the import...';
  }

  if (resourceTypes.asyncStatus === 'LOADING') {
    return 'Loading resource types...';
  }

  if (selectionState.asyncStatus === 'LOADING') {
    return 'Fetching selected data...';
  }

  if (subscriptions.asyncStatus === 'LOADING') {
    return 'Fetching subscriptions...';
  }

  if (resourceGroups.asyncStatus === 'LOADING') {
    return 'Fetching resource groups...';
  }

  if (regionState.asyncStatus === 'LOADING') {
    return 'Fetching regions...';
  }

  if (connectionsState.asyncStatus === 'LOADING') {
    return 'Fetching connections...';
  }

  return 'Loading';
};

type Props = {
  tabularMapping: TabularMapping;
  transferState: TransferState;
  selectionState: SelectionState;
  resourceTypesState: ResourceTypesState;
  resourceGroupsState: ResourceGroupsState;
  regionsState: RegionsState;
};

const isConfigureStepAvailable = ({
  selectionState,
  resourceTypesState,
  resourceGroupsState,
  regionsState,
}: Props) => {
  return areFetchedResourcesInSync(
    selectionState,
    resourceTypesState,
    resourceGroupsState,
    regionsState
  );
};

const isReviewStepAvailable = (props: Props) => {
  return (
    isConfigureStepAvailable(props) &&
    !isDryRunOutOfDate(props.transferState, props.tabularMapping)
  );
};

const isTransferStepAvailable = (props: Props) => {
  return isReviewStepAvailable(props) && isSuccessTransfer(props.transferState);
};

const STEP_AVAILABILITY: Record<ImportRoute, (props: Props) => boolean> = {
  [ImportRoute.SELECT_DATA]: () => true,
  [ImportRoute.CONFIGURE]: isConfigureStepAvailable,
  [ImportRoute.REVIEW]: isReviewStepAvailable,
  [ImportRoute.IMPORT_AND_SCHEDULE]: isTransferStepAvailable,
};

export const isStepAvailable = (path: ImportRoute, props: Props): boolean => {
  return STEP_AVAILABILITY[path](props);
};

export const createProviderParams = ({
  subscriptions,
  resourceTypes,
  regions,
  resourceGroups,
}: {
  subscriptions?: string[];
  resourceTypes: string[];
  regions: string[];
  resourceGroups: string[];
}): CloudProviderConfig['providerParams'] => {
  return {
    subscriptions,
    resourceTypes,
    regions,
    // Backend currently expects vpcs for the providerParams
    vpcs: resourceGroups,
  };
};

export const getSourceConfig = ({
  accountIds,
  subscriptions,
  resourceTypes,
  regions,
  resourceGroups,
}: {
  accountIds: string[];
  subscriptions?: string[];
  resourceTypes: string[];
  regions: string[];
  resourceGroups: string[];
}) =>
  accountIds.length
    ? {
        accountIds,
        providerParams: createProviderParams({
          subscriptions: subscriptions,
          resourceTypes: resourceTypes,
          regions: regions,
          resourceGroups: resourceGroups,
        }),
      }
    : null;
