import { SidebarLayout } from '@ardoq/sidebar';
import { Stack } from '@ardoq/layout';
import { colors, space } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { text2 } from '@ardoq/typography';
import { NavigationMenuItem } from './types';

import { NavigationSidebarTree } from './components/NavigationSidebarTree';
import {
  SidebarMenu,
  SIDEBAR_MENU_MENU_ITEM_SELECTOR,
  SIDEBAR_MENU_SECTION_SELECTOR,
} from '@ardoq/sidebar-menu';
import { useMemo } from 'react';

const WrapperStackBox = styled(Stack)`
  background-color: ${colors.surfaceDefault};
  height: 100%;
  padding: ${`${space.medium} 0 ${space.medium} ${space.small}`};
  ${SIDEBAR_MENU_MENU_ITEM_SELECTOR} {
    ${text2};
  }
  ${SIDEBAR_MENU_SECTION_SELECTOR} {
    border-bottom: none;
    margin-top: 0;
    padding: 0;
  }
`;

export type NavigationSidebarProps = {
  options: NavigationMenuItem[];
  selectedItemId?: string;
  onItemClick?: (item: NavigationMenuItem) => void;
  closeable?: boolean;
};

export const NavigationSidebar = ({
  options,
  onItemClick,
  selectedItemId,
  closeable = false,
}: NavigationSidebarProps) => {
  const Container = useMemo(() => {
    return closeable ? SidebarLayout : SidebarMenu;
  }, [closeable]);
  return (
    <Container isOpen isRightContent isResizable width="320px">
      <WrapperStackBox>
        {options.map(option => (
          <NavigationSidebarTree
            key={option.label}
            item={option}
            selectedItemId={selectedItemId}
            onItemClick={onItemClick}
          ></NavigationSidebarTree>
        ))}
      </WrapperStackBox>
    </Container>
  );
};
