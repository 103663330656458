import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId } from '@ardoq/api-types';
import { FiltersAsQueryBuilderQueries } from '@ardoq/filter-interface';

interface FilterChangePayload {
  readonly filters: FiltersAsQueryBuilderQueries;
  readonly queryString: string;
}

export const notifyFiltersChanged = actionCreator<FilterChangePayload>(
  '[FilterActions] NOTIFY_FILTERS_CHANGED'
);
/** Triggered when color filters are changed */
export const notifyFilterColorChanged = actionCreator(
  '[FilterActions] NOTIFY_FILTER_COLOR_CHANGED'
);
export const triggerFiltersChangedEvent = actionCreator(
  '[FilterActions] TRIGGER_FILTERS_CHANGED_EVENT'
);

export const setActivePerspective = actionCreator<{
  id: ArdoqId | null;
  queryString: string;
}>('[FilterActions] SET_ACTIVE_PERSPECTIVE');

export const notifyPerspectivesDestroyed = actionCreator<{
  perspectiveIds: ArdoqId[];
}>('[FilterActions] NOTIFY_PERSPECTIVE_DESTROYED');

export const notifyFilterContextChanged = actionCreator(
  '[FilterActions] NOTIFY_FILTER_CONTEXT_CHANGED'
);

export const notifyPerspectivesProcessedChangedWorkspace = actionCreator(
  '[Perspectives] NOTIFY_PERSPECTIVES_PROCESSED_CHANGED_WORKSPACE'
);
