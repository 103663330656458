import { debounce } from 'lodash';
import CurrentUser from 'models/currentUser';
import { SectionExpandedSettings, SectionSettingKey } from './types';

/** Setting names can not contain spaces -- it will break the app */
const getSettingName = (settingKey: SectionSettingKey) =>
  `quickPerspectives${settingKey.replace(' ', '_')}Expanded`;

const setExpandedSetting = (
  settingKey: SectionSettingKey,
  settingVal: boolean
) => CurrentUser.setPersonalSetting(getSettingName(settingKey), settingVal);

export const getDebouncedSetExpandedSetting = (settingKey: SectionSettingKey) =>
  debounce(settingVal => setExpandedSetting(settingKey, settingVal), 2000);

export const getExpandedSetting = (settingKey: SectionSettingKey) => {
  const currentSetting = CurrentUser.getPersonalSetting(
    getSettingName(settingKey)
  );
  // Default to true if setting is undefined
  return currentSetting !== false;
};

export const getMultiExpandedSettings = (...settingKeys: SectionSettingKey[]) =>
  settingKeys.reduce<SectionExpandedSettings>(
    (acc, settingKey) => ({
      ...acc,
      [settingKey]: getExpandedSetting(settingKey),
    }),
    {} as SectionExpandedSettings
  );
