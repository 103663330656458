import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { switchMap, tap } from 'rxjs';
import { deletingSubdivision } from 'subdivisionEditor/subdivisionEditorViewModel$/actions';
import { handleError, subdivisionApi } from '@ardoq/api';
import { showToast, ToastType } from '@ardoq/status-ui';
import { showDeleteSubdivisionModal } from './deleteSubdivisionModal';
import {
  fetchSimplifiedSubdivisionSummary,
  fetchSimplifiedSubdivisionSummaryFailed,
  fetchSimplifiedSubdivisionSummarySuccess,
  fetchSubdivisionSummary,
  fetchSubdivisionSummaryFailed,
  fetchSubdivisionSummarySuccess,
} from './subdivisionSummary$/actions';

const fetchSubdivisionSummaryRoutine = routine(
  ofType(fetchSubdivisionSummary),
  extractPayload(),
  switchMap(subdivisionId => subdivisionApi.fetchSummary(subdivisionId)),
  handleError(() => {
    showToast(
      'Error happened whiling fetching data, please try again later.',
      ToastType.INFO
    );
    dispatchAction(
      fetchSubdivisionSummaryFailed('Error happened while fetching data')
    );
  }),
  tap(subdivisionSummary => {
    dispatchAction(fetchSubdivisionSummarySuccess(subdivisionSummary));
  })
);

const deletingSubdivisionRoutine = routine(
  ofType(deletingSubdivision),
  extractPayload(),
  tap(showDeleteSubdivisionModal),
  tap(subdivision =>
    dispatchAction(fetchSimplifiedSubdivisionSummary(subdivision._id))
  ),
  switchMap(subdivision =>
    subdivisionApi.fetchSimplifiedSummary(subdivision._id)
  ),
  handleError(() => {
    showToast(
      'Error happened whiling fetching data, please try again later.',
      ToastType.INFO
    );
    dispatchAction(
      fetchSimplifiedSubdivisionSummaryFailed(
        'Failed to fetch simplified subdivision summary'
      )
    );
  }),
  tap(subdivisionSummary => {
    dispatchAction(
      fetchSimplifiedSubdivisionSummarySuccess(subdivisionSummary)
    );
  })
);

const subdivisionOverviewRoutines = collectRoutines(
  fetchSubdivisionSummaryRoutine,
  deletingSubdivisionRoutine
);

export default subdivisionOverviewRoutines;
