import { AssetType, PrivilegeLabel } from '@ardoq/api-types';
import { getIconNameForAssetType, IconName } from '@ardoq/icons';

export const PRIVILEGES_ICONS: Record<PrivilegeLabel, IconName> = {
  [PrivilegeLabel.CREATE_SCENARIO]: getIconNameForAssetType(AssetType.SCENARIO),
  [PrivilegeLabel.ACCESS_DISCOVER]: IconName.FILE_OUTLINED,
  [PrivilegeLabel.CONFIGURE_PRIVILEGES]: IconName.FILE_OUTLINED,
  [PrivilegeLabel.CREATE_SURVEY]: getIconNameForAssetType(AssetType.SURVEY),
  [PrivilegeLabel.CREATE_DASHBOARD]: getIconNameForAssetType(
    AssetType.DASHBOARD
  ),
  [PrivilegeLabel.CREATE_REPORT]: getIconNameForAssetType(AssetType.REPORT),
  [PrivilegeLabel.GLOBAL_DATA_ACCESS]: IconName.FILE_OUTLINED,
  [PrivilegeLabel.CREATE_VIEWPOINT]: getIconNameForAssetType(
    AssetType.VIEWPOINT
  ),
  [PrivilegeLabel.CREATE_WORKSPACE]: getIconNameForAssetType(
    AssetType.WORKSPACE
  ),
  [PrivilegeLabel.CREATE_GREMLIN_QUERIES]: IconName.DATABASE_SEARCH,
  [PrivilegeLabel.READ_GROUPS]: IconName.PEOPLE,
  [PrivilegeLabel.MANAGE_GROUPS]: IconName.PEOPLE,
  [PrivilegeLabel.CREATE_API_TOKEN]: IconName.API,
};
