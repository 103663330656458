import { ArdoqId, APIScenarioAttributes } from '@ardoq/api-types';
import scenarios$ from 'streams/scenarios/scenarios$';

const getScenarioById = (id: ArdoqId | null): APIScenarioAttributes | null =>
  id ? scenarios$.state.byId[id] : null;

const getScenarioNameById = (id: ArdoqId | null): string | undefined =>
  getScenarioById(id)?.name;

const exists = (id: ArdoqId | null): boolean => Boolean(getScenarioById(id));

const getScopeAndBranchId = (scenarioId: ArdoqId | null | undefined) => {
  const { scopeId, branchId } = (scenarioId && getScenarioById(scenarioId)) || {
    scopeId: null,
    branchId: null,
  };
  return { scopeId, branchId };
};

export const scenarioInterface = {
  getScenarioById,
  getScopeAndBranchId,
  getScenarioNameById,
  exists,
};
