import Fields from 'collections/fields';
import Workspaces from 'collections/workspaces';
import Perspectives from 'collections/perspectives';
import CurrentUser from 'models/currentUser';
import { Features, hasFeature } from '@ardoq/features';
import { setLocaleFromUserSettings } from 'utils/localeUtils';
import { dispatchActionAndWaitForResponse } from './actions/utils';
import {
  apiFetchCurrentUserPermissions,
  failedFetchCurrentUserPermissions,
  fetchedCurrentUserPermissions,
} from './streams/currentUserPermissions/actions';
import {
  fetchAllDashboardsRequested,
  fetchAllDashboardsSucceeded,
  fetchAllDashboardsFailed,
} from './streams/dashboards/actions';
import { reportNamespace } from 'streams/reports/actions';
import { scenarioNamespace } from 'streams/scenarios/actions';
import { workspaceFolderNamespace } from 'streams/assetFolders/actions';
import {
  fetchAllModels,
  fetchAllModelsFailed,
  fetchAllModelsSucceeded,
} from './streams/models/actions';
import { slideNamespace } from './streams/slides/actions';
import {
  fetchedOrgUsersSuccess,
  fetchOrgUsers,
  fetchOrgUsersError,
} from 'streams/orgUsers/actions';
import {
  fetchAllFailed,
  fetchAllRequested,
  fetchAllSucceeded,
} from 'streams/crud/actions';
import { traversalNamespace } from 'streams/traversals/actions';
import { presentationsNamespace } from 'streams/presentations/presentations$';
import { surveysNamespace } from 'streams/surveys/surveys$';
import { bookmarksNamespace } from 'streams/bookmarks/bookmarks$';

export const loadApplicationData = () => {
  return Promise.all([
    CurrentUser.fetch().then(() => {
      setLocaleFromUserSettings();
      CurrentUser.on('sync', setLocaleFromUserSettings);
    }),
    Fields.collection.fetch({ remove: false }),
    Perspectives.fetch(),
    Workspaces.collection.fetch(),
  ]).then(function () {
    CurrentUser.loaded = true;
    CurrentUser.trigger('sync');
    CurrentUser.trigger('loaded');
  });
};

// It can't be in loadApplicationData because permissions and dashboards are only in streams (no Backbone collection).
// So it needs to be after starting the routines and streams
export const loadStreamBasedApplicationData = () =>
  Promise.all([
    hasFeature(Features.SCENARIOS_BETA)
      ? dispatchActionAndWaitForResponse(
          fetchAllRequested(scenarioNamespace),
          fetchAllSucceeded,
          fetchAllFailed,
          scenarioNamespace
        )
      : Promise.resolve([]),

    dispatchActionAndWaitForResponse(
      fetchAllRequested(workspaceFolderNamespace),
      fetchAllSucceeded,
      fetchAllFailed,
      workspaceFolderNamespace
    ),
    dispatchActionAndWaitForResponse(
      fetchAllDashboardsRequested(),
      fetchAllDashboardsSucceeded,
      fetchAllDashboardsFailed
    ),
    dispatchActionAndWaitForResponse(
      fetchAllModels(),
      fetchAllModelsSucceeded,
      fetchAllModelsFailed
    ),
    dispatchActionAndWaitForResponse(
      apiFetchCurrentUserPermissions(),
      fetchedCurrentUserPermissions,
      failedFetchCurrentUserPermissions
    ),
    dispatchActionAndWaitForResponse(
      fetchAllRequested(reportNamespace),
      fetchAllSucceeded,
      fetchAllFailed,
      reportNamespace
    ),
    hasFeature(Features.SUPPORT_LARGE_DATASETS)
      ? dispatchActionAndWaitForResponse(
          fetchAllRequested(traversalNamespace),
          fetchAllSucceeded,
          fetchAllFailed,
          traversalNamespace
        ).then(response => response.payload)
      : Promise.resolve(),
    dispatchActionAndWaitForResponse(
      fetchAllRequested(slideNamespace),
      fetchAllSucceeded,
      fetchAllFailed,
      slideNamespace
    ),
    dispatchActionAndWaitForResponse(
      fetchOrgUsers(),
      fetchedOrgUsersSuccess,
      fetchOrgUsersError
    ),
    hasFeature(Features.PRESENTATIONS)
      ? dispatchActionAndWaitForResponse(
          fetchAllRequested(presentationsNamespace),
          fetchAllSucceeded,
          fetchAllFailed,
          presentationsNamespace
        )
      : Promise.resolve(),
    hasFeature(Features.SURVEYS)
      ? dispatchActionAndWaitForResponse(
          fetchAllRequested(surveysNamespace),
          fetchAllSucceeded,
          fetchAllFailed,
          surveysNamespace
        )
      : Promise.resolve(),
    hasFeature(Features.BOOKMARKS)
      ? dispatchActionAndWaitForResponse(
          fetchAllRequested(bookmarksNamespace),
          fetchAllSucceeded,
          fetchAllFailed,
          bookmarksNamespace
        )
      : Promise.resolve(),
  ]);
