import { Switch } from '@ardoq/forms';
import { APIDiscoverViewpointAttributes } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { setViewpointPublished } from 'viewpoints/actions';
import { useFormContext } from 'react-hook-form';

type PublishSwitchProps = {
  isDisabled?: boolean;
};

const PublishSwitch = ({ isDisabled }: PublishSwitchProps) => {
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const [published, _id] = watch(['published', '_id']);
  return (
    <Switch
      label="LIVE"
      name="published"
      isChecked={Boolean(published)}
      onChange={selectedValue => {
        if (!_id) return;
        dispatchAction(
          setViewpointPublished({ viewpointId: _id, published: selectedValue })
        );
      }}
      isDisabled={isDisabled}
    />
  );
};

export default PublishSwitch;
