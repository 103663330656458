import {
  Button,
  ButtonGroup,
  ButtonType,
  SecondaryButton,
  GhostButton,
} from '@ardoq/button';
import { ChevronLeftIcon, SaveIcon, VisibilityIcon } from '@ardoq/icons';
import { DashboardTrackingEventsNames } from '@ardoq/dashboard';
import { trackEvent } from '../../tracking/tracking';
import { LoadingState } from './types';

interface HeaderButtonsProps {
  viewDashboard: VoidFunction;
  saveDashboard: VoidFunction;
  hasDashboardId: boolean;
  goToDashboardOverview: VoidFunction;
  hasUnsavedProgress: boolean;
  loadingState: LoadingState | null;
  hasNewJourneyFeature: boolean;
}

const HeaderButtons = ({
  viewDashboard,
  hasDashboardId,
  saveDashboard,
  goToDashboardOverview,
  hasUnsavedProgress,
  loadingState,
  hasNewJourneyFeature,
}: HeaderButtonsProps) => {
  const isSaving = loadingState === LoadingState.SAVING;
  const hasError = loadingState === LoadingState.LOADING_ERROR;
  return (
    <ButtonGroup $alignToRight>
      <GhostButton
        dataTestId="go-to-dashboard-overview-button"
        onClick={() => {
          trackEvent(
            DashboardTrackingEventsNames.CLICKED_ON_GO_TO_DASHBOARD_OVERVIEW_DASHBOARD_BUILDER
          );
          goToDashboardOverview();
        }}
      >
        <ChevronLeftIcon />
        Back to Dashboards
      </GhostButton>
      <SecondaryButton
        isDisabled={hasUnsavedProgress || !hasDashboardId || isSaving}
        type="button"
        onClick={viewDashboard}
        data-click-id="dashboard-builder-view-button"
      >
        View
        <VisibilityIcon />
      </SecondaryButton>
      <Button
        dataTestId="save-dashboard-button"
        isDisabled={hasError || !hasUnsavedProgress}
        buttonType={
          hasNewJourneyFeature ? ButtonType.BRAND : ButtonType.PRIMARY
        }
        onClick={() => {
          trackEvent(
            DashboardTrackingEventsNames.CLICKED_ON_SAVE_IN_DASHBOARD_BUILDER
          );
          saveDashboard();
        }}
        isLoading={isSaving}
      >
        Save changes <SaveIcon />
      </Button>
    </ButtonGroup>
  );
};

export default HeaderButtons;
