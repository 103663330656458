import {
  collectRoutines,
  routine,
  extractPayload,
  ofType,
  dispatchAction,
} from '@ardoq/rxbeach';
import {
  debounceTime,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  setResults,
  setSearchParams,
  performSearch,
  prepareResults,
} from './actions';
import {
  composeQuickSearchTrackingMetaData,
  trackQuickSearch,
} from './tracking';
import { prepareQuickSearchSearchParams } from './utils';
import quickSearch$ from './quickSearch$';
import { api, searchApi } from '@ardoq/api';

const handleSetSearchParams = routine(
  ofType(setSearchParams),
  debounceTime(300),
  extractPayload(),
  tap(({ query }) => {
    if (!query.length) {
      dispatchAction(setResults({ total: 0, results: null }));
      return;
    }
    dispatchAction(performSearch(query));
  })
);

const handlePerformSearch = routine(
  ofType(performSearch),
  extractPayload(),
  withLatestFrom(quickSearch$),
  switchMap(([query, { options }]) => {
    const { types, workspaceIds, customFieldName } =
      prepareQuickSearchSearchParams(options);
    return searchApi.customSearch(query, {
      types,
      workspaceIds,
      customFieldName,
      from: 0,
      size: 250,
    });
  }),
  map(api.logErrorIfNeeded),
  tap(response => dispatchAction(prepareResults(response)))
);

const handlePrepareResults = routine(
  ofType(prepareResults),
  extractPayload(),
  withLatestFrom(quickSearch$),
  map(([response, { options }]) => {
    if (!response) return;
    const { total, results } = response;
    const trackingMetaData = composeQuickSearchTrackingMetaData(total, options);
    trackQuickSearch(trackingMetaData);
    dispatchAction(
      setResults({
        total,
        results,
      })
    );
  })
);

export default collectRoutines(
  handleSetSearchParams,
  handlePerformSearch,
  handlePrepareResults
);
