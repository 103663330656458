import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { updateCalculatedFieldOptions } from './actions';
import type { CalculatedFieldOption, CalculatedFieldOptions } from './types';

export type CalculatedFieldOptionsState = CalculatedFieldOption[];
const defaultState = [] as CalculatedFieldOptionsState;

const handleUpdateParameterOptions = (
  _: CalculatedFieldOptionsState,
  { calculatedFieldOptions }: Readonly<CalculatedFieldOptions>
) => calculatedFieldOptions;

const reducers = [
  reducer(updateCalculatedFieldOptions, handleUpdateParameterOptions),
];

const calculatedFieldOptions$ = persistentReducedStream(
  'calculatedFieldOptions$',
  defaultState,
  reducers
);

export default calculatedFieldOptions$;
