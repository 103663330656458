import { Label } from '@ardoq/forms';
import { SortableMultiselect } from '@ardoq/select';
import { colors, s16 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { TextOverflow } from '@ardoq/popovers';
import {
  APISurveyAttributes,
  ArdoqId,
  ViewpointSurveyDetail,
} from '@ardoq/api-types';
import { normal14 } from '@ardoq/typography';

type SurveyDetailFormProps = {
  surveyDetail: ViewpointSurveyDetail;
  onSurveyDetailChange: (surveyDetail: ViewpointSurveyDetail) => void;
  relatedSurveys?: APISurveyAttributes[];
};

const StyledSortableMultiselect = styled(SortableMultiselect)`
  width: 448px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${s16};
`;

const Description = styled.p`
  ${normal14};
  color: ${colors.grey50};
  max-width: 448px;
`;

const SurveyDetailForm = ({
  surveyDetail,
  onSurveyDetailChange,
  relatedSurveys,
}: SurveyDetailFormProps) => {
  const options = relatedSurveys
    ?.filter(
      survey =>
        survey.componentTypeName === surveyDetail.componentTypeName &&
        !survey.discoverPriority
    )
    .map(survey => ({
      label: survey.name,
      value: survey._id,
    }));

  const prioritySurvey = relatedSurveys?.find(
    survey =>
      survey.componentTypeName === surveyDetail.componentTypeName &&
      survey.discoverPriority
  );

  const noSurveys = !options?.length && !prioritySurvey;

  const optionIds = surveyDetail.surveys;
  const selectedSurveys = options?.sort((a, b) => {
    const aIndex = optionIds.indexOf(a.value);
    const bIndex = optionIds.indexOf(b.value);

    if (bIndex === -1) return -1;
    if (aIndex === -1) return 1;
    return aIndex - bIndex;
  });

  return (
    <div data-test-id="survey-details">
      <Label width={'450px'}>
        <TextOverflow>{surveyDetail.componentTypeName}</TextOverflow>
      </Label>
      {prioritySurvey && (
        <Description>The priority survey is {prioritySurvey?.name}</Description>
      )}
      <Row>
        <StyledSortableMultiselect
          isDeletable={false}
          options={options}
          value={selectedSurveys}
          onChange={selectedOptions => {
            if (!selectedOptions) return;
            onSurveyDetailChange({
              componentTypeName: surveyDetail.componentTypeName,
              surveys: selectedOptions.map(option => option.value) as ArdoqId[],
            });
          }}
          isSearchable={false}
          placeholder="There are no non-priority Discover surveys..."
          openMenuOnClick={false}
          isClearable={false}
          isDisabled={!options?.length}
          helperText={
            noSurveys
              ? 'There are no surveys for this component type'
              : undefined
          }
          backspaceRemovesValue={false}
          dataTestId="survey-sortable-select"
        />
      </Row>
    </div>
  );
};

export default SurveyDetailForm;
