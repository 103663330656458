import { validateAllObject, validateAs } from '@ardoq/validation';
import type {
  CreatePermissionGroup$State,
  CreatePermissionGroupFormState,
  SetFormFieldValuePayload,
} from './types';

const handleValidationError = (
  state: CreatePermissionGroup$State,
  validationErrors: Record<keyof CreatePermissionGroupFormState, string[]>
): CreatePermissionGroup$State => ({
  ...state,
  formErrors: validationErrors,
  apiErrorMessage: null,
  displayFieldErrors: true,
});

const handleCreate = (
  state: CreatePermissionGroup$State
): CreatePermissionGroup$State => ({
  ...state,
  isSubmitting: true,
  apiErrorMessage: null,
});

const handleApiError = (
  state: CreatePermissionGroup$State,
  apiErrorMessage: string
): CreatePermissionGroup$State => ({
  ...state,
  apiErrorMessage: apiErrorMessage,
  isSubmitting: false,
});

const handleCreateSuccess = (
  state: CreatePermissionGroup$State
): CreatePermissionGroup$State => ({
  ...state,
  isSubmitting: false,
});

const handleSetFormValue = (
  state: CreatePermissionGroup$State,
  { field, value }: SetFormFieldValuePayload
): CreatePermissionGroup$State => ({
  ...state,
  formValues: {
    ...state.formValues,
    [field]: value,
  },
});

const validateForm = (
  values: CreatePermissionGroupFormState,
  existingPermissionGroupNames: string[] = []
) => {
  return validateAllObject({
    name: {
      value: values.name,
      validationSteps: [
        {
          ...validateAs.requiredString(),
          message: 'The name field is required.',
        },
        {
          ...validateAs.notIncludedIn({ array: existingPermissionGroupNames }),
          message: 'A permission group with this name already exists.',
        },
      ],
    },
  });
};

export const createPermissionGroupOperations = {
  handleCreate,
  handleValidationError,
  handleApiError,
  handleCreateSuccess,
  validateForm,
  handleSetFormValue,
};
