import { batchApi } from '@ardoq/api';
import { ArdoqId } from '@ardoq/api-types';
import {
  isArdoqError,
  ExcludeFalsy,
  ContextSort,
  NumericSortOrder,
} from '@ardoq/common-helpers';
import { hasFeature, Features } from '@ardoq/features';
import { logError } from '@ardoq/logging';
import { uniq } from 'lodash';
import { getDragTargetNodes } from './getDragTargetIds';
import Tree from './models/tree';
import { NodeModel } from './models/types';
import { LayoutBoxModel, NavigatorViewInterface } from './types';
import {
  NavigatorDropType,
  NodeModelTypes,
  COMPONENT_ORDER_STEP,
} from './utils/consts';
import { getDropTarget } from './utils/getDropTarget';
import { alert } from '@ardoq/modal';
import { DoqType } from '@ardoq/doq';

type HandleDropInNavigatorArgs = {
  dragTargetNode: NodeModel | null;
  dropTargetLayoutBox: LayoutBoxModel | null;
  isDropTargetParent: boolean;
  isDropTargetBefore: boolean;
  dropType: NavigatorDropType;
  selection: string[];
  isSortedByOrder: boolean;
  isAborted: boolean;
  tree: Tree;
  sort: ContextSort;
  navigatorViewInterface: NavigatorViewInterface;
};

export const handleDropInNavigator = async ({
  dragTargetNode,
  dropTargetLayoutBox,
  isDropTargetParent,
  isDropTargetBefore,
  dropType,
  selection,
  isSortedByOrder,
  isAborted,
  tree,
  sort,
  navigatorViewInterface,
}: HandleDropInNavigatorArgs) => {
  const dropNode = getDropTarget(
    dropTargetLayoutBox,
    isDropTargetParent,
    isSortedByOrder
  );
  const canBeDropped =
    !isAborted &&
    dragTargetNode &&
    dropNode &&
    dropNode.hasWriteAccess &&
    dropNode.type !== NodeModelTypes.SCENARIO;
  if (!canBeDropped || dropType === NavigatorDropType.DROP_TYPE_NO_DROP) {
    return { isHandled: false, changedIds: [] };
  }

  const index = getDropIndex(
    dropTargetLayoutBox,
    isDropTargetParent,
    isDropTargetBefore
  );
  const dragTargetNodes = getDragTargetNodes(selection, dragTargetNode, tree);

  if (dropType === NavigatorDropType.DROP_TYPE_COPY) {
    return copyDroppedNodes(dropNode, dragTargetNodes, navigatorViewInterface);
  }

  if (dropType === NavigatorDropType.DROP_TYPE_MOVE) {
    return moveNodes(
      dragTargetNodes,
      dropNode,
      sort,
      index,
      navigatorViewInterface
    );
  }

  return { isHandled: false, changedIds: [] };
};

const copyDroppedNodes = (
  dropNode: NodeModel,
  dragTargetNodes: NodeModel[],
  navigatorViewInterface: NavigatorViewInterface
) => {
  const dropRootNode = dropNode.getRootNode();
  const targetWorkspace = dropRootNode.id;
  const parent = dropRootNode === dropNode ? undefined : dropNode.id;
  const componentIds = dragTargetNodes.map(dragTargetNode => dragTargetNode.id);
  navigatorViewInterface.copyComponents({
    targetWorkspace,
    parent,
    componentIds,
  });

  return { isHandled: true, changedIds: [] };
};

const moveNodes = async (
  dragTargetNodes: NodeModel[],
  dropNode: NodeModel,
  sort: ContextSort,
  index: number,
  navigatorViewInterface: NavigatorViewInterface
) => {
  const updates = getUpdatesOfMovedNodes(
    dragTargetNodes,
    dropNode,
    sort,
    index
  );

  const result = await saveUpdates(updates, navigatorViewInterface);
  if (isArdoqError(result)) {
    alert({
      title: 'Moving components failed',
      text: `We couldn't save the update on the server. Please try again or contact your administrator.`,
      doqType: DoqType.ERROR,
    });
    logError(Error('Moving components failed'));
    return { isHandled: false, changedIds: [] };
  }

  if (hasFeature(Features.PERMISSION_ZONES)) {
    const isMovedToRootLevel =
      dropNode.getName() === dropNode.getRootNode().getName();
    dragTargetNodes.forEach(node => {
      if (isMovedToRootLevel) {
        navigatorViewInterface.removeZonesFromComponent(node.id);
      } else {
        navigatorViewInterface.copyZones(dropNode.id, node.id);
      }
    });
  }
  return {
    isHandled: true,
    changedIds: uniq(Object.keys(result.components.updated ?? {})),
  };
};

const getUpdatesOfMovedNodes = (
  nodes: NodeModel[],
  newParent: NodeModel,
  sort: ContextSort,
  index = -1
) => {
  const updates: { id: ArdoqId; _order?: number; parent?: ArdoqId | null }[] =
    [];
  if (nodes.length === 0) {
    return [];
  }

  const workspaceId = newParent.getWorkspaceId();
  if (!nodes.every(node => node.getWorkspaceId() === workspaceId)) {
    logError(Error('Nodes can only be moved in the same workspace'));
    return [];
  }

  const nodeParent = nodes[0].parent;

  if (!nodes.every(node => node.parent === nodeParent)) {
    logError(Error('Moved nodes must all have the same parent'));
    return [];
  }

  const shouldSetOrder = index > -1;
  const children = newParent.getChildren();
  const startOrder = getStartOrder(shouldSetOrder, children, index);
  const direction = sort.order === NumericSortOrder.ASC ? 1 : -1;
  const delta = getDelta(
    shouldSetOrder,
    direction,
    startOrder,
    nodes.length,
    children,
    index
  );
  let endOrder = startOrder + nodes.length * delta;

  nodes.forEach((node, index) =>
    updates.push(
      node.getUpdateParentAndOrder(newParent, startOrder + index * delta)
    )
  );

  // Update the order of the siblings if needed.
  if (shouldSetOrder) {
    // See https://github.com/ardoq/ardoq-front/issues/4093
    // for more context.
    for (let i = index; i < children.length; i++) {
      const child = children[i];
      if (nodes.includes(child)) {
        continue;
      }
      if (direction === 1) {
        if (child.getOrder() <= endOrder) {
          updates.push({ id: child.id, _order: endOrder++ });
        } else {
          break;
        }
      } else if (child.getOrder() >= endOrder) {
        updates.push({ id: child.id, _order: endOrder-- });
      } else {
        break;
      }
    }
  }
  return updates;
};

const saveUpdates = async (
  updates: { id: ArdoqId; _order?: number; parent?: ArdoqId | null }[],
  navigatorViewInterface: NavigatorViewInterface
) => {
  const result = await batchApi.execute({
    options: {
      includeEntities: true,
    },
    components: {
      update: updates
        .map(({ id, _order, parent }) => {
          const componentData = navigatorViewInterface.getComponentData(id);
          if (!componentData) {
            logError(Error('Component not found in saveUpdates '));
            return null;
          }

          return {
            ...componentData,
            _order: _order ?? componentData._order,
            parent: parent === undefined ? componentData.parent : parent,
          };
        })
        .filter(ExcludeFalsy),
    },
  });
  return result;
};

const getStartOrder = (
  setOrder: boolean,
  children: NodeModel[],
  index: number
): number => {
  if (setOrder && children[index]) {
    return children[index].getOrder();
  }
  if (children.length > 0) {
    return children[children.length - 1].getOrder() + COMPONENT_ORDER_STEP;
  }
  return COMPONENT_ORDER_STEP;
};

const getDelta = (
  setOrder: boolean,
  direction: number,
  startOrder: number,
  nodesLength: number,
  children: NodeModel[],
  index: number
) => {
  if (setOrder) {
    if (children[index + 1]) {
      const delta = Math.abs(children[index + 1].getOrder() - startOrder);
      return (
        (delta > nodesLength ? Math.floor(delta / nodesLength) : 1) * direction
      );
    }
  }
  return COMPONENT_ORDER_STEP;
};

const getDropIndex = (
  dropTargetLayoutBox: LayoutBoxModel | null,
  isDropTargetParent: boolean,
  isDropTargetBefore: boolean
) => {
  if (!dropTargetLayoutBox || !isDropTargetParent) {
    return -1;
  }
  const { node } = dropTargetLayoutBox;
  const index = node.parent!.getChildren().indexOf(node);
  return index + (isDropTargetBefore ? 0 : 1);
};
