import {
  APIComponentAttributes,
  APIEntityType,
  APIReferenceAttributes,
  APIScopeData,
  ArdoqId,
  ScopeDataCollection,
} from '@ardoq/api-types';
import { scopeDataOperations } from '@ardoq/scope-data';
import { identity, uniq } from 'lodash';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { dateRangeOperations } from '@ardoq/date-range';
import { getCollectionForEntityType } from 'collectionInterface/utils';
import { Attributes } from 'collectionInterface/types';
import { documentArchiveInterface } from '../modelInterface/documentArchiveInterface';

/**
 * Structured clone that only takes one argument.
 * Reasoning: structuredClone is a binary function (two args), but to cleanly
 * map() it over a collection we it to be unary because Array.prototype.map()
 * calls the callbackFn with 3 arguments.
 */
const unaryStructuredClone = <T>(arg: T): T => {
  return structuredClone(arg);
};

/**
 * Custom getEntity function in order to accommodate for special needs when
 * building scopeData that does not adhere to "best practice" in general.
 */
const getEntity = <T extends APIEntityType>(
  entityType: T,
  id: ArdoqId
): Attributes<T> | null => {
  const entity = getCollectionForEntityType(entityType).get(id);
  return entity?.attributes ?? null;
};

/**
 * Custom getWorkspaceEntities function in order to accommodate for special needs
 * when building scopeData that does not adhere to "best practice" in general.
 */
const getWorkspaceEntities = <T extends APIEntityType>(
  entityType: T,
  workspaceIds: Set<ArdoqId>
): Attributes<T>[] => {
  return getCollectionForEntityType(entityType)
    .filter(model => {
      const rootWorkspaceId = model.get('rootWorkspace');
      return workspaceIds.has(rootWorkspaceId);
    })
    .map(entity => entity.attributes);
};

const getWorkspace = (workspaceId: ArdoqId) => {
  return getEntity(APIEntityType.WORKSPACE, workspaceId);
};

const getModel = (modelId: ArdoqId) => {
  return getEntity(APIEntityType.MODEL, modelId);
};

const getReference = (referenceId: ArdoqId) => {
  return getEntity(APIEntityType.REFERENCE, referenceId);
};

const getWorkspaceTags = (workspaceIds: Set<ArdoqId>) => {
  return getWorkspaceEntities(APIEntityType.TAG, workspaceIds);
};

const getWorkspaceFields = (modelIds: Set<ArdoqId>) => {
  return getCollectionForEntityType(APIEntityType.FIELD)
    .filter(field => modelIds.has(field.get('model')))
    .map(field => field.attributes);
};

const getWorkspaceComponents = (workspaceIds: Set<ArdoqId>) => {
  return getWorkspaceEntities(APIEntityType.COMPONENT, workspaceIds);
};

const getWorkspaceReferences = (workspaceIds: Set<ArdoqId>) => {
  return getWorkspaceEntities(APIEntityType.REFERENCE, workspaceIds);
};

type BuildCommonScopeDataConfig = {
  /** Always set this to true unless cloning is handled in a different way */
  clone: boolean;
};

const buildCommonScopeData = (
  inputWorkspaceIds: ArdoqId[],
  { clone }: BuildCommonScopeDataConfig
): APIScopeData => {
  const transform = clone ? unaryStructuredClone : identity;

  const workspaces = inputWorkspaceIds
    .map(getWorkspace)
    .map(transform)
    .filter(ExcludeFalsy);

  const workspaceAndModelIds = new Set(
    workspaces.flatMap(({ _id, componentModel }) => [_id, componentModel])
  );

  return {
    ...scopeDataOperations.getEmpty(),
    [ScopeDataCollection.MODELS]: workspaces
      .map(({ componentModel }) => transform(getModel(componentModel)))
      .filter(ExcludeFalsy),
    [ScopeDataCollection.TAGS]: uniq(getWorkspaceTags(workspaceAndModelIds))
      .map(transform)
      .filter(ExcludeFalsy),
    [ScopeDataCollection.FIELDS]:
      getWorkspaceFields(workspaceAndModelIds).map(transform),
    attachments: documentArchiveInterface
      .getWorkspacesAndOrgAttachments(workspaceAndModelIds)
      .map(transform),
    workspaces: workspaces,
    dateRangeFieldMap: new Map(),
    scopeComponents: [],
  };
};

type EntityScopeData = {
  [ScopeDataCollection.COMPONENTS]: APIComponentAttributes[];
  [ScopeDataCollection.REFERENCES]: APIReferenceAttributes[];
};

type EntityScopeDataConfig = {
  /** Always set this to true unless cloning is handled in a different way */
  clone: boolean;
  entityType: APIEntityType | undefined;
  entityIDs: ArdoqId[];
};

const buildEntityScopeData = (
  inputWorkspaceIds: ArdoqId[],
  { entityType, entityIDs, clone }: EntityScopeDataConfig
): EntityScopeData => {
  const transform = clone ? unaryStructuredClone : identity;
  const workspaceIds = new Set(inputWorkspaceIds);

  if (entityType === APIEntityType.COMPONENT) {
    return {
      [ScopeDataCollection.COMPONENTS]:
        getWorkspaceComponents(workspaceIds).map(transform),
      [ScopeDataCollection.REFERENCES]: [],
    };
  }
  if (entityType === APIEntityType.REFERENCE) {
    return {
      [ScopeDataCollection.COMPONENTS]:
        getWorkspaceComponents(workspaceIds).map(transform),
      [ScopeDataCollection.REFERENCES]: entityIDs
        .map(getReference)
        .map(transform)
        .filter(ExcludeFalsy),
    };
  }
  if (
    entityType === APIEntityType.FIELD ||
    entityType === APIEntityType.REFERENCE_TYPE
  ) {
    return {
      [ScopeDataCollection.COMPONENTS]: [],
      [ScopeDataCollection.REFERENCES]: [],
    };
  }
  return {
    [ScopeDataCollection.COMPONENTS]:
      getWorkspaceComponents(workspaceIds).map(transform),
    [ScopeDataCollection.REFERENCES]:
      getWorkspaceReferences(workspaceIds).map(transform),
  };
};

/**
 * Builds scopeData from backbone interfaces.
 * This structure is deep copied and safe to use in state.
 */
export const buildScopeDataForWorkspaces = (
  workspaceIds: ArdoqId[],
  entityType: APIEntityType,
  entityIDs?: ArdoqId[]
): APIScopeData => {
  const scopeData = {
    ...buildCommonScopeData(workspaceIds, { clone: true }),
    ...buildEntityScopeData(workspaceIds, {
      clone: true,
      entityType,
      entityIDs: entityIDs || [],
    }),
  };
  return dateRangeOperations.getMergedDateRangeFieldForScopeData(scopeData);
};

/**
 * Separate function needed for grid editor because:
 * 1. postMessage Bridge clones the scopeData when sent
 * 2. The GridEditor merges the date range fields itself
 */
export const buildScopeDataForGridEditor = (
  workspaceIds: ArdoqId[]
): APIScopeData => {
  return {
    ...buildCommonScopeData(workspaceIds, { clone: false }),
    ...buildEntityScopeData(workspaceIds, {
      clone: false,
      entityType: undefined,
      entityIDs: [],
    }),
  };
};
