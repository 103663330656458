import { FeatureSettingsState } from './types';

const setIsUpdating = (state: FeatureSettingsState): FeatureSettingsState => ({
  ...state,
  isUpdating: true,
});

const resetIsUpdating = (
  state: FeatureSettingsState
): FeatureSettingsState => ({
  ...state,
  isUpdating: false,
});

export const featureSettingsOperations = {
  setIsUpdating,
  resetIsUpdating,
};
