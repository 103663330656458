import { action$, derivedStream, reduceState } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import {
  ruleErrorToMessage,
  validateAdvancedSearchQuery,
} from 'search/AdvancedSearch/utils';
import { map } from 'rxjs/operators';
import {
  AdvancedSearchFieldsHashState,
  advancedSearchFieldsHash$,
} from 'streams/fields/advancedSearchFields$';
import { defaultState } from './consts';
import {
  AdvancedSearchStateShape,
  BranchedAdvancedSearchStateShape,
} from './types';

export const advancedSearchState$ = action$.pipe(
  reduceState('advancedSearch$', defaultState, reducers)
);

export type AdvancedSearchShape = AdvancedSearchStateShape & {
  ruleErrorMessages: string[];
};

export type BranchedAdvancedSearchShape = BranchedAdvancedSearchStateShape & {
  ruleErrorMessages: string[];
};

const mapState = ([advancedSearchState, fieldsHash]: [
  BranchedAdvancedSearchStateShape,
  AdvancedSearchFieldsHashState,
]): BranchedAdvancedSearchShape => {
  const ruleErrors = advancedSearchState.queryBuilderRules
    ? validateAdvancedSearchQuery(advancedSearchState.queryBuilderRules)
    : [];

  const ruleErrorMessages = ruleErrors.map(ruleError =>
    ruleErrorToMessage(ruleError, fieldsHash)
  );

  return { ...advancedSearchState, ruleErrorMessages };
};

const advancedSearch$ = derivedStream(
  'advancedSearch-withFields$',
  advancedSearchState$,
  advancedSearchFieldsHash$
).pipe(map(mapState));

export default advancedSearch$;
