import { map, switchMap } from 'rxjs/operators';
import { AppModules } from 'appContainer/types';
import { dispatchAction } from '@ardoq/rxbeach';
import searchTab$ from './SearchTabContainer/searchTab$';
import {
  QueryEditor$,
  advancedSearchEditor$,
  calculatedFieldQueryEditor$,
  dynamicFilterQueryEditor$,
  gremlinSearchEditor$,
} from './QueryEditor/queryEditor$';
import { SearchPane, SearchPaneToLabel } from './SearchTabContainer/types';
import { Route } from 'router/StreamRouter';
import { AppRouterState, SearchRoute } from 'router/appRouterTypes';
import { of } from 'rxjs';
import { navigateToSearch } from 'router/navigationActions';
import { isSearchPane, isSearchPaneWithBackend } from './typeGuards';

const queryEditor$FromSearchPane = new Map<SearchPane, QueryEditor$>([
  [SearchPane.ADVANCED_SEARCH, advancedSearchEditor$],
  [SearchPane.GREMLIN, gremlinSearchEditor$],
  [SearchPane.CALCULATED_FIELD_SEARCH, calculatedFieldQueryEditor$],
  [SearchPane.DYNAMIC_FILTER_SEARCH, dynamicFilterQueryEditor$],
]);

const searchRoute = new Route<AppRouterState, SearchRoute>({
  doesLocationMatch: ({ path }) =>
    /\/search\/?/.test(path) ||
    /\/search\/(.+)\/?/.test(path) ||
    /\/search\/(.+)\/(.+)\/?/.test(path),
  locationToRouterState: ({ path }) => {
    const parts = path.split('/');
    const selectedPane = isSearchPane(parts[2])
      ? parts[2]
      : SearchPane.ADVANCED_SEARCH;

    // Only include search id in router state if the search pane in the URL
    // supports selecting a search id (it's a search pane with backend)
    const searchId = isSearchPaneWithBackend(parts[2]) ? parts[3] : null;
    return {
      appModule: AppModules.SEARCH,
      searchPane: selectedPane,
      searchId,
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.SEARCH,
  routerStateToLocation: ({ searchPane, searchId }) => {
    let path = `/search/${searchPane}`;
    if (searchId) {
      path += `/${searchId}`;
    }
    return { path, title: SearchPaneToLabel[searchPane] };
  },
  setApplicationStateFromRoute: ({ searchPane, searchId }) => {
    dispatchAction(
      navigateToSearch({
        searchPane,
        searchId,
      })
    );
  },
  getPartialRouterStateStream: () =>
    searchTab$.pipe(
      switchMap(({ selectedPane }) => {
        const queryEditor$ = queryEditor$FromSearchPane.get(selectedPane);
        if (queryEditor$) {
          return queryEditor$.pipe(
            map(({ selectedQueryId }) => {
              return {
                searchPane: selectedPane,
                searchId: selectedQueryId,
              };
            })
          );
        } else if (Object.values(SearchPane).includes(selectedPane)) {
          return of({
            searchPane: selectedPane,
            searchId: null,
          });
        }
        return of({
          searchPane: SearchPane.ADVANCED_SEARCH,
          searchId: null,
        });
      })
    ),
});

export default searchRoute;
