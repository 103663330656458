import { dispatchAction } from '@ardoq/rxbeach';
import * as actions from './actions';
import { ViewpointBuilderContext } from 'viewpointBuilder/types';
import { ViewpointBuilderTab } from './types';
import { viewpointBuilderTracking } from '../viewpointBuilderTracking';

export type ViewpointBuilderNavigationCommands = {
  selectTab: (tab: ViewpointBuilderTab) => void;
  selectViewpointBuilderContext: (context: ViewpointBuilderContext) => void;
};

const selectTab = (tab: ViewpointBuilderTab) => {
  dispatchAction(actions.selectTab({ tab }));
  viewpointBuilderTracking.trackClickNavigationTab(tab);
};

const selectViewpointBuilderContext = (context: ViewpointBuilderContext) => {
  dispatchAction(actions.selectViewpointBuilderContext({ context }));
};

export const viewpointBuilderNavigationCommands: ViewpointBuilderNavigationCommands =
  {
    selectTab,
    selectViewpointBuilderContext,
  };
