import styled from 'styled-components';
import { spacing } from '@ardoq/design-tokens';
import { Stack } from '@ardoq/layout';

export const OverflowWrapper = styled(Stack)`
  overflow: hidden;
  padding: ${spacing.s16} ${spacing.s24};
  height: calc(100% - ${spacing.s24});
`;

export const AudiencePreviewColumn = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
