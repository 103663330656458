import { useEffect } from 'react';
import TokensTable from './TokensTable';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { getOrgTokens, getUserTokens } from './actions';
import { modal } from '@ardoq/modal';
import { APIToken } from '@ardoq/api-types';
import {
  CreateNewButton,
  Island,
  IslandBody,
  IslandContainer,
  IslandHeader,
} from '@ardoq/page-layout';
import { TokenCreationModal } from './TokenCreationModal';
import {
  API_TOKENS_EVENTS,
  trackAPITokensEvent,
} from './apiTokensTrackingUtils';
import { TokenViewCommands } from './commands';
import CopyAndDisappearToken from './CopyAndDisappearToken/CopyAndDisappearToken';
import { FlexBox, Stack } from '@ardoq/layout';
import {
  EmptyState,
  EmptyStateNoResultIllustration,
  Tag,
} from '@ardoq/status-ui';
import { PrimaryButton } from '@ardoq/button';
import { AddIcon, IconName } from '@ardoq/icons';
import { tokenTabViewProps$ } from './tokenView$';
import { ErrorMessage } from '@ardoq/error-boundary';
import { DoqLoadingAnimation, DoqSize, DoqType } from '@ardoq/doq';
import { Text } from '@ardoq/typography';
import { TokenTypes } from './types';
import { Tab, TabsGroup } from '@ardoq/tabs';
import { IconDecorator } from '@ardoq/decorators';
import { FormSize, SearchInput } from '@ardoq/forms';
import { WithPopover } from '@ardoq/popovers';

const NO_PRIVILEGE_MESSAGE =
  "You don't have the privilege to create new API tokens.";

export type TokenTabViewProps = {
  isLoading: boolean;
  activeTokensTab: TokenTypes;
  canCreateToken: boolean;
  isOrgAdmin: boolean;
  errorMessage: string;
  searchedMyTokens: APIToken[];
  isMyTokensEmpty: boolean;
  searchedOrgTokens: APIToken[];
  isOrgTokensEmpty: boolean;
  searchKey: string;
  commands: TokenViewCommands;
};

const TokenView = ({
  isLoading,
  activeTokensTab,
  canCreateToken,
  isOrgAdmin,
  searchedMyTokens,
  isMyTokensEmpty,
  searchedOrgTokens,
  isOrgTokensEmpty,
  searchKey,
  errorMessage,
  commands,
}: TokenTabViewProps) => {
  useEffect(() => {
    if (isOrgAdmin) {
      dispatchAction(getOrgTokens());
    } else {
      dispatchAction(getUserTokens());
    }
  }, [isOrgAdmin]);

  const openCreateTokenModal = () => {
    trackAPITokensEvent(API_TOKENS_EVENTS.CLICKED_CREATE_NEW_BUTTON);
    modal(closeModal => (
      <TokenCreationModal
        onCancel={() => closeModal(true)}
        onSubmit={tokenName => {
          closeModal(true);
          commands.createAPIToken(tokenName);
        }}
      />
    ));
  };

  if (errorMessage) {
    return <ErrorMessage />;
  }

  if (isLoading) {
    return (
      <Island>
        <Stack align="center" justify="center" gap="large">
          <Text variant="text1Bold">Loading API tokens...</Text>
          <DoqLoadingAnimation
            delay={700}
            doqSize={DoqSize.MEDIUM}
            doqs={[
              DoqType.SIMPLE,
              DoqType.ALGORITHM,
              DoqType.ERROR,
              DoqType.SUCCESS,
              DoqType.COMPONENT_MERGE_EMPTY,
            ]}
          />
        </Stack>
      </Island>
    );
  }

  const Table = () => (
    <TokensTable
      tokens={
        activeTokensTab === TokenTypes.MY_TOKENS
          ? searchedMyTokens
          : searchedOrgTokens
      }
      commands={commands}
      isOrgTokens={activeTokensTab === TokenTypes.ORG_TOKENS}
    />
  );

  const MyTokensEmptyState = () => (
    <FlexBox justify="center">
      <EmptyState
        title="No tokens created yet"
        description="API tokens enable secure integration with Ardoq. Once created, you can view and manage them here."
        media={<EmptyStateNoResultIllustration />}
      >
        <WithPopover
          content={!canCreateToken ? NO_PRIVILEGE_MESSAGE : undefined}
        >
          <div>
            <PrimaryButton
              onClick={openCreateTokenModal}
              isDisabled={!canCreateToken}
            >
              Create token
              <AddIcon />
            </PrimaryButton>
          </div>
        </WithPopover>
      </EmptyState>
    </FlexBox>
  );

  return (
    <IslandContainer>
      <IslandHeader
        title="API tokens"
        subtitle="Manage your organization's API tokens for secure and streamlined integrations. Tokens expire 60 days after their last use."
        rightContent={
          !isMyTokensEmpty && (
            <WithPopover
              content={!canCreateToken ? NO_PRIVILEGE_MESSAGE : undefined}
            >
              <div>
                <CreateNewButton
                  onClick={openCreateTokenModal}
                  isDisabled={!canCreateToken}
                >
                  Create new
                </CreateNewButton>
              </div>
            </WithPopover>
          )
        }
      />
      <IslandBody>
        <Stack gap="xlarge">
          <CopyAndDisappearToken />
          <Stack gap="xlarge">
            {isMyTokensEmpty && isOrgTokensEmpty ? (
              <MyTokensEmptyState />
            ) : (
              <>
                <SearchInput
                  value={searchKey}
                  placeholder="Search token name"
                  formSize={FormSize.DEFAULT}
                  onValueChange={commands.updateSearchKey}
                />
                {isOrgAdmin ? (
                  <TabsGroup
                    activeTabId={activeTokensTab}
                    onTabChange={tabId =>
                      commands.switchTokensTab(tabId as TokenTypes)
                    }
                  >
                    <Tab
                      tabId={TokenTypes.MY_TOKENS}
                      label={
                        <IconDecorator iconName={IconName.KEY}>
                          <FlexBox gap="xsmall" align="center">
                            My tokens
                            <Tag>{searchedMyTokens.length}</Tag>
                          </FlexBox>
                        </IconDecorator>
                      }
                    >
                      {isMyTokensEmpty ? <MyTokensEmptyState /> : <Table />}
                    </Tab>
                    <Tab
                      tabId={TokenTypes.ORG_TOKENS}
                      label={
                        <IconDecorator iconName={IconName.KEY}>
                          <FlexBox gap="xsmall" align="center">
                            Organization tokens
                            <Tag>{searchedOrgTokens.length}</Tag>
                          </FlexBox>
                        </IconDecorator>
                      }
                    >
                      {isOrgTokensEmpty ? (
                        <FlexBox justify="center">
                          <EmptyState
                            title="No organization tokens have been created yet."
                            description="API tokens created by other admins for your organization will appear here, ready for viewing and management."
                            media={<EmptyStateNoResultIllustration />}
                          />
                        </FlexBox>
                      ) : (
                        <Table />
                      )}
                    </Tab>
                  </TabsGroup>
                ) : (
                  <Table />
                )}
              </>
            )}
          </Stack>
        </Stack>
      </IslandBody>
    </IslandContainer>
  );
};

export default connect(TokenView, tokenTabViewProps$);
