import {
  APIComponentAttributes,
  APIReferenceAttributes,
  APISurveyApprovedChanges,
  APISurveyApprovedComponentChanges,
  APISurveyChangeRequest,
  ArdoqId,
  SurveyApprovalAudiences,
  SurveyApprovalAudienceType,
  SurveyApprovalRelationship,
  SurveyApprovalRelationshipsAudience,
  SurveyApprovalTraversalAudience,
  SurveyApprovalUsersAndGroupsAudience,
} from '@ardoq/api-types';
import { flatten, isEmpty, isEqual, omit, pick } from 'lodash';
import {
  ApprovalStatus,
  ChangedFieldsByComponentId,
  ComponentValidation,
  PartialReferenceChanges,
  RecordOfComponents,
  RecordOfReferences,
  ReferenceChanges,
  ValidationObject,
} from './types';
import { EnhancedScopeData } from '@ardoq/data-model';
import { DIFF_TYPE } from '@ardoq/global-consts';
import { ExcludeFalsy, toByIdDictionary } from '@ardoq/common-helpers';
import { uniq } from 'lodash';
import { editTraversalOperations } from 'viewpointBuilder/traversals/editTraversalOperations';

export const componentIsComplete = (
  componentValidationObject: ComponentValidation
) => {
  const referenceChanges = componentValidationObject.references ?? {};
  const fieldChanges = componentValidationObject.fields;
  return (
    (isEmpty(referenceChanges) && isEmpty(fieldChanges)) ||
    (Object.values(referenceChanges).every(pendingChange => {
      return pendingChange === false;
    }) &&
      Object.values(fieldChanges).every(
        pendingChange => pendingChange === false
      ))
  );
};

export const validationObjectHasChanges = (
  componentValidationObject: ComponentValidation
) => {
  const referenceChanges = componentValidationObject?.references ?? {};
  const fieldChanges = componentValidationObject.fields;
  return (
    (isEmpty(referenceChanges) && isEmpty(fieldChanges)) ||
    Object.values(referenceChanges).some(pendingChange => {
      return pendingChange === false;
    }) ||
    Object.values(fieldChanges).some(pendingChange => {
      return pendingChange === false;
    })
  );
};

export const createValidationObject = (
  changedFieldsByComponentId: ChangedFieldsByComponentId,
  changedReferencesByComponentId: Record<ArdoqId, PartialReferenceChanges>
): ValidationObject => {
  return Object.entries(changedFieldsByComponentId).reduce(
    (acc, [componentId, fieldChanges]) => {
      const areReferenceChanges =
        changedReferencesByComponentId[componentId] ?? {};
      return {
        ...acc,
        [componentId]: {
          fields: {
            ...fieldChanges,
          },
          references: flatten(Object.values(areReferenceChanges)).reduce(
            (acc, reference) => {
              return {
                ...acc,
                [reference._id]: true,
              };
            },
            {}
          ),
        },
      };
    },
    {}
  );
};

export const createChangedFieldsByComponentId = (
  changeRequests: APISurveyChangeRequest[],
  changedFieldsByComponentId: ChangedFieldsByComponentId
): ChangedFieldsByComponentId =>
  changeRequests.reduce((acc, changeRequest) => {
    const componentId = changeRequest.component;
    if (changeRequest.action !== 'update')
      return {
        ...acc,
        [componentId]: { all: true },
      };
    return {
      ...acc,
      [componentId]: changedFieldsByComponentId[componentId],
    };
  }, {});

export const getApprovalStatus = (
  valueSetInApprovedChanges: unknown,
  changedTo: unknown
): ApprovalStatus => {
  return valueSetInApprovedChanges === undefined
    ? 'pending'
    : isEqual(valueSetInApprovedChanges, changedTo)
      ? 'approved'
      : 'rejected';
};

const getCorrectReferenceDataSourceForCreate = (
  action: 'approve' | 'reject',
  branchData: EnhancedScopeData,
  masterData: EnhancedScopeData,
  references: APIReferenceAttributes[] | undefined
): Record<ArdoqId, APIReferenceAttributes> => {
  if (!references) return {};
  return action === 'approve'
    ? pick(
        branchData.referencesById,
        references.map(ref => ref._id)
      )
    : pick(
        masterData.referencesById,
        references.map(ref => ref._id)
      );
};

const getCorrectReferenceDataSourceForUpdate = (
  action: 'approve' | 'reject',
  branchData: EnhancedScopeData,
  masterData: EnhancedScopeData,
  references: APIReferenceAttributes[] | undefined
): Partial<Record<ArdoqId, APIReferenceAttributes>> => {
  if (!references) return {};
  return action === 'approve'
    ? toByIdDictionary(
        references.map(ref => ({
          ...branchData.referencesById[ref._id],
          _version: masterData.referencesById[ref._id]._version,
        }))
      )
    : pick(
        masterData.referencesById,
        references.map(ref => ref._id)
      );
};

const getCorrectReferenceDataSourceForDelete = (
  action: 'approve' | 'reject',
  masterData: EnhancedScopeData,
  references: APIReferenceAttributes[] | undefined
): Partial<Record<ArdoqId, APIReferenceAttributes>> => {
  if (!references) return {};
  return action === 'approve'
    ? pick(
        masterData.referencesById,
        references.map(ref => ref._id)
      )
    : {};
};

export const getChosenReferences = (
  action: 'approve' | 'reject',
  relevantChangedReferences: ReferenceChanges,
  branchData: EnhancedScopeData,
  masterData: EnhancedScopeData
): Partial<Record<ArdoqId, APIReferenceAttributes>> => {
  const created = getCorrectReferenceDataSourceForCreate(
    action,
    branchData,
    masterData,
    relevantChangedReferences.create
  );
  const deleted = getCorrectReferenceDataSourceForDelete(
    action,
    masterData,
    relevantChangedReferences.delete
  );
  const updated = getCorrectReferenceDataSourceForUpdate(
    action,
    branchData,
    masterData,
    relevantChangedReferences.update
  );

  return { ...created, ...deleted, ...updated };
};

export const getApprovedChangesForComponent = (
  changeRequests: APISurveyChangeRequest[],
  approvedComponentChanges: RecordOfComponents,
  componentId: ArdoqId
) => {
  const completedComponent = approvedComponentChanges[componentId] as
    | APIComponentAttributes
    | undefined;

  const relevantChangeRequest = changeRequests.find(
    changeRequest => changeRequest.component === componentId
  );

  if (!relevantChangeRequest) return {};

  if (relevantChangeRequest.action === 'create') {
    return { create: [completedComponent].filter(ExcludeFalsy) };
  }
  if (relevantChangeRequest.action === 'delete') {
    return { delete: [completedComponent?._id].filter(ExcludeFalsy) };
  }
  return {
    update: [omit(completedComponent, DIFF_TYPE) as APIComponentAttributes],
  };
};

const getReferencesApprovedForCreation = (
  approvedReferenceChanges: RecordOfReferences,
  createRequests?: APIReferenceAttributes[]
): APIReferenceAttributes[] => {
  return Object.entries(approvedReferenceChanges).reduce(
    (acc, [referenceId, reference]) => {
      if (
        reference &&
        createRequests?.find(
          requestReference => requestReference._id === referenceId
        )
      ) {
        return [...acc, reference as APIReferenceAttributes];
      }
      return acc;
    },
    [] as APIReferenceAttributes[]
  );
};

export const potentiallyUpdateComponentChanges = (
  componentId: ArdoqId,
  masterData: EnhancedScopeData,
  branchData: EnhancedScopeData,
  referencesApprovedForCreation: APIReferenceAttributes[],
  componentCreateChanges?: APIComponentAttributes[]
): APIComponentAttributes[] | undefined => {
  if (!referencesApprovedForCreation.length) return componentCreateChanges;
  return referencesApprovedForCreation.reduce((acc, reference) => {
    const referencedComponentId =
      reference.source === componentId ? reference.target : reference.source;
    const referencedComponentExists =
      referencedComponentId && masterData.componentsById[referencedComponentId];
    const referencedComponentIsAlreadyDueToBeCreated = acc?.find(
      referenceToBeCreated => referenceToBeCreated._id === referencedComponentId
    );
    if (
      referencedComponentExists ||
      referencedComponentIsAlreadyDueToBeCreated
    ) {
      return acc;
    }
    const referencedComponent =
      branchData.componentsById[referencedComponentId];
    return [...(acc ?? []), referencedComponent];
  }, componentCreateChanges);
};

const getApprovedReferences = (
  approvedReferenceChanges: RecordOfReferences,
  updateRequests: APIReferenceAttributes[] | undefined
) => {
  return Object.entries(approvedReferenceChanges).reduce(
    (acc, [referenceId, reference]) => {
      if (
        reference &&
        updateRequests?.find(
          requestReference => requestReference._id === referenceId
        )
      ) {
        return [...acc, reference as APIReferenceAttributes];
      }
      return acc;
    },
    [] as APIReferenceAttributes[]
  );
};

const getReferencesApprovedForDeletion = (
  approvedReferenceChanges: RecordOfReferences,
  deleteRequests: APIReferenceAttributes[] | undefined
): ArdoqId[] => {
  return Object.entries(approvedReferenceChanges).reduce(
    (acc, [referenceId, reference]) => {
      if (
        reference &&
        deleteRequests?.find(
          requestReference => requestReference._id === referenceId
        )
      ) {
        return [...acc, reference._id as ArdoqId];
      }
      return acc;
    },
    [] as ArdoqId[]
  ) as ArdoqId[];
};

export const getApprovedChangesForReferences = (
  componentId: ArdoqId,
  approvedReferenceChanges: RecordOfReferences,
  referenceChanges: ReferenceChanges,
  masterData: EnhancedScopeData,
  branchData: EnhancedScopeData,
  componentChanges: APISurveyApprovedComponentChanges
): APISurveyApprovedChanges => {
  const referencesApprovedForCreation = getReferencesApprovedForCreation(
    approvedReferenceChanges,
    referenceChanges.create
  );
  return {
    references: {
      create: referencesApprovedForCreation,
      update: getApprovedReferences(
        approvedReferenceChanges,
        referenceChanges.update
      ),
      delete: getReferencesApprovedForDeletion(
        approvedReferenceChanges,
        referenceChanges.delete
      ),
    },
    components: {
      ...componentChanges,
      create: potentiallyUpdateComponentChanges(
        componentId,
        masterData,
        branchData,
        referencesApprovedForCreation,
        componentChanges?.create
      ),
    },
  };
};

export const getRelationshipIdentifier = (
  relationship: SurveyApprovalRelationship
) => {
  return `${relationship.referenceTypeName} ${relationship.outgoing ? '->' : '<-'} ${relationship.componentTypeName}`;
};

export const isRelationshipSelected = (
  selectedRelationships: SurveyApprovalRelationship[],
  relationship: SurveyApprovalRelationship
) => {
  return selectedRelationships.some(selectedRelationship => {
    return (
      getRelationshipIdentifier(selectedRelationship) ===
      getRelationshipIdentifier(relationship)
    );
  });
};

export const replaceRelationship = (
  relationships: SurveyApprovalRelationship[],
  relationshipToReplace: SurveyApprovalRelationship,
  newRelationship: SurveyApprovalRelationship
) => {
  return relationships.map(relationship => {
    return getRelationshipIdentifier(relationship) ===
      getRelationshipIdentifier(relationshipToReplace)
      ? newRelationship
      : relationship;
  });
};

export const defaultUsersAndGroupsAudience: SurveyApprovalUsersAndGroupsAudience =
  {
    audienceType: 'usersAndGroups',
    groupIds: [],
    userIds: [],
  };

export const defaultRelationshipsAudience: SurveyApprovalRelationshipsAudience =
  {
    audienceType: 'relationships',
    relationships: [],
  };

export const defaultTraversalAudience: SurveyApprovalTraversalAudience = {
  audienceType: 'traversal',
  userResolveFields: [],
  traversal: { paths: [] },
};

export const getDefaultAudience =
  (): SurveyApprovalUsersAndGroupsAudience[] => {
    return [defaultUsersAndGroupsAudience];
  };

export const getUsersAndGroupsAudience = (
  selectors: SurveyApprovalAudiences
): SurveyApprovalUsersAndGroupsAudience => {
  return (
    selectors.find(selector => selector.audienceType === 'usersAndGroups') ??
    defaultUsersAndGroupsAudience
  );
};

export const getRelationshipsAudience = (
  selectors: SurveyApprovalAudiences
): SurveyApprovalRelationshipsAudience => {
  return (
    selectors.find(selector => selector.audienceType === 'relationships') ??
    defaultRelationshipsAudience
  );
};

export const getTraversalAudience = (
  selectors: SurveyApprovalAudiences
): SurveyApprovalTraversalAudience => {
  return (
    selectors.find(selector => selector.audienceType === 'traversal') ??
    defaultTraversalAudience
  );
};

export const isOutgoingRelationship = (
  relationship: SurveyApprovalRelationship
) => {
  return relationship.outgoing;
};

export const isIncomingRelationship = (
  relationship: SurveyApprovalRelationship
) => {
  return !relationship.outgoing;
};

export const withUserAndGroupsAudienceUpdated = (
  usersAndGroupsAudience: SurveyApprovalUsersAndGroupsAudience,
  audienceIds: ArdoqId[],
  audienceType: 'groupIds' | 'userIds'
): SurveyApprovalUsersAndGroupsAudience => {
  return {
    ...usersAndGroupsAudience,
    [audienceType]: audienceIds,
  };
};

export const withAudienceIdAdded = (
  audienceIds: ArdoqId[],
  audienceId: ArdoqId
): ArdoqId[] => {
  return uniq([...audienceIds, audienceId]);
};

export const withAudienceIdRemoved = (
  audienceIds: ArdoqId[],
  audienceId: ArdoqId
): ArdoqId[] => {
  return audienceIds.filter(
    existingAudienceId => existingAudienceId !== audienceId
  );
};

export const withRelationshipsAudienceEmailFieldSelected = (
  relationshipsAudience: SurveyApprovalRelationshipsAudience,
  relationship: SurveyApprovalRelationship,
  emailField: string
): SurveyApprovalRelationshipsAudience => {
  return {
    ...relationshipsAudience,
    relationships: replaceRelationship(
      relationshipsAudience.relationships,
      relationship,
      { ...relationship, emailField }
    ),
  };
};

export const withRelationshipAdded = (
  relationshipsAudience: SurveyApprovalRelationshipsAudience,
  relationship: SurveyApprovalRelationship
): SurveyApprovalRelationshipsAudience => {
  const relationships = relationshipsAudience.relationships;
  const relationshipToAdd = {
    ...relationship,
    referenceTypeName: editTraversalOperations.toBELabel(
      relationship.referenceTypeName
    ),
  };
  if (isRelationshipSelected(relationships, relationshipToAdd)) {
    return relationshipsAudience;
  }
  return {
    ...relationshipsAudience,
    relationships: [...relationships, relationshipToAdd],
  };
};

export const withRelationshipRemoved = (
  relationshipsAudience: SurveyApprovalRelationshipsAudience,
  relationship: SurveyApprovalRelationship
): SurveyApprovalRelationshipsAudience => {
  const relationshipToExclude = {
    ...relationship,
    referenceTypeName: editTraversalOperations.toBELabel(
      relationship.referenceTypeName
    ),
  };
  return {
    ...relationshipsAudience,
    relationships: relationshipsAudience.relationships.filter(
      relationship => !isEqual(relationship, relationshipToExclude)
    ),
  };
};

export const isAudienceType = (
  value: unknown
): value is SurveyApprovalAudienceType => {
  return (
    value === 'usersAndGroups' ||
    value === 'relationships' ||
    value === 'traversal'
  );
};

export const hasUserAndGroupsAudience = (
  usersAndGroupsAudience: SurveyApprovalUsersAndGroupsAudience
) => {
  return (
    usersAndGroupsAudience.groupIds.length ||
    usersAndGroupsAudience.userIds.length
  );
};
