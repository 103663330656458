import { APIOrganizationUser } from '@ardoq/api-types';
import { LabeledDetail } from '../../components/LabeledDetail';
import { userOperations } from '@ardoq/core';
import { DecoratorPlaceholder } from '@ardoq/decorators';

type SessionsCountProps = { user: APIOrganizationUser };

export const SessionsCount = ({ user }: SessionsCountProps) => {
  return (
    <LabeledDetail label="Sessions">
      {userOperations.isServiceAccountUser(user) ? (
        <DecoratorPlaceholder>Not applicable</DecoratorPlaceholder>
      ) : (
        user.sessions
      )}
    </LabeledDetail>
  );
};
