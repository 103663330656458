import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isEqual } from 'lodash';
import {
  fetchProblemRows,
  fetchPreviewRows,
  fetchPreviewRowsSuccess,
  setFetchRowsStatus,
  setTableDataRows,
  setTablePreviews,
} from './actions';
import { EMPTY, forkJoin, of } from 'rxjs';
import { byIndex } from 'integrations/common/utils/common';
import { setCurrentTableId } from 'integrations/common/streams/activeIntegrations/actions';
import {
  getTablePreviewsStream,
  tablePreviews$,
} from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { transferState$ } from 'integrations/common/streams/transferState/getTransferStateStream';
import {
  getRowIndicesWithProblems,
  isSuccessTransfer,
} from 'integrations/common/streams/transferState/utils';
import { NUM_DATA_ROWS, ROW_INDEX_FIELD } from './constants';
import _, { first } from 'lodash/fp';
import { TableDataRow } from './types';
import {
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { convertHeadersToString, convertRowValuesToString } from './utils';
import { fields$ } from 'integrations/common/streams/fields/fields$';
import { workspaces$ } from 'integrations/common/streams/workspaces/workspaces$';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { getScheduleStream } from 'integrations/common/streams/schedule/schedule$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getCurrentTransferConfig } from 'integrations/common/streams/transferConfigs/utils';
import {
  adjustTabularMapping,
  tabularMappingIntoTransferConfig,
  transferConfigToTabularMapping,
} from 'integrations/common/streams/tabularMappings/utils';
import {
  applyTabularMapping,
  applyRequiredTableMapping,
} from 'integrations/common/streams/tabularMappings/actions';
import { navigateToConfigure } from 'integrations/common/navigation/actions';
import { commonIntegrationApi } from '@ardoq/api';
import { applyTabularMappingsDefault } from '../tabularMappingsDefault/actions';
import { isJobWithTabularMapping } from 'integrations/common/utils/scheduleApi';

const NUM_ROWS = 1 + NUM_DATA_ROWS; // header + 5 rows

const handleFetchPreviewRows = routine(
  ofType(fetchPreviewRows),
  extractPayload(),
  withLatestFrom(tablePreviews$),
  switchMap(([{ integrationId, sourceId, tableId }, tablePreviews]) => {
    const fetchingRowStatus =
      tablePreviews[integrationId][tableId]?.previewRows?.fetchRowsStatus;

    // fetch preview rows only if it hasn't already been fetched
    if (fetchingRowStatus !== 'INIT') {
      return EMPTY;
    }
    dispatchAction(
      setFetchRowsStatus({ integrationId, tableId, fetchRowsStatus: 'LOADING' })
    );

    return forkJoin({
      response: retriableRequest(
        () =>
          commonIntegrationApi.fetchPreviewRows({
            sourceId,
            tableId,
            startRow: 0,
            rowCount: NUM_ROWS,
          }),
        'Unable to fetch preview rows'
      ).pipe(
        catchError(error => {
          dispatchAction(
            setFetchRowsStatus({
              integrationId,
              tableId,
              fetchRowsStatus: 'FAILURE',
            })
          );
          return handleApiError(error);
        })
      ),
      tableId: of(tableId),
      integrationId: of(integrationId),
    });
  }),
  tap(({ response: { rows }, integrationId, tableId }) => {
    const safeRows = rows || []; // rows can be null from the response

    const [header = [], ...data] = safeRows;

    // we are keeping track of the indices
    const headerWithIndex = [ROW_INDEX_FIELD, ...header].map(
      convertHeadersToString
    );
    const rowsWithIndex: TableDataRow[] = data
      .map((data, idx) => {
        return [idx + 1, ...data] as TableDataRow; // idx + 1 since we extract the header
      })
      .map(convertRowValuesToString);
    dispatchAction(
      fetchPreviewRowsSuccess({
        header: headerWithIndex,
        rows: rowsWithIndex,
        integrationId,
        tableId,
      })
    );
  })
);

const handleFetchProblemRows = routine(
  ofType(fetchProblemRows),
  extractPayload(),
  withLatestFrom(tablePreviews$, transferState$),
  switchMap(
    ([{ integrationId, sourceId, tableId }, tablePreviews, transferState]) => {
      const importResult = transferState[integrationId];
      if (!isSuccessTransfer(importResult)) {
        return EMPTY;
      }

      const currentRows =
        tablePreviews[integrationId][tableId]?.previewRows?.rows || [];

      const currentRowIndices = currentRows.map(([idx]) => idx).sort();

      const rowIndicesWithProblems = getRowIndicesWithProblems(
        importResult.tablesComplaints[tableId]
      ).byPriority;

      const existingRowIndicesWithProblems = _.intersection(
        rowIndicesWithProblems,
        currentRowIndices
      );

      const missingRowIndices = _.pipe(
        _.difference(rowIndicesWithProblems),
        _.take(
          Math.max(NUM_DATA_ROWS - existingRowIndicesWithProblems.length, 0)
        ) // we only fetch maximum of NUM_DATA_ROWS
      )(currentRowIndices);

      dispatchAction(
        setFetchRowsStatus({
          integrationId,
          tableId,
          fetchProblemRowsStatus: missingRowIndices.length
            ? 'LOADING'
            : 'SUCCESS',
        })
      );

      return forkJoin(
        missingRowIndices.map(rowIndex =>
          retriableRequest(
            () =>
              commonIntegrationApi.fetchPreviewRows({
                sourceId,
                tableId,
                startRow: rowIndex,
                rowCount: 1, // we only fetch a single row
              }),
            'Unable to fetch preview rows'
          ).pipe(
            map(({ rows }) => {
              return {
                row: rows ? first(rows) : null,
                rowIndex,
              };
            })
          )
        )
      ).pipe(
        tap(result => {
          const problemRows: TableDataRow[] = result
            .map(({ row, rowIndex }) => {
              if (!row) return null;
              return [rowIndex, ...row];
            })
            .filter(ExcludeFalsy)
            .map(convertRowValuesToString);

          dispatchAction(
            setTableDataRows({ integrationId, tableId, rows: problemRows })
          );
          dispatchAction(
            setFetchRowsStatus({
              integrationId,
              tableId,
              fetchProblemRowsStatus: 'SUCCESS',
            })
          );
        }),
        catchError(error => {
          dispatchAction(
            setFetchRowsStatus({
              integrationId,
              tableId,
              fetchProblemRowsStatus: 'FAILURE',
            })
          );
          return handleApiError(error);
        })
      );
    }
  )
);

const handleSetTables = routine(
  ofType(setTablePreviews),
  extractPayload(),
  tap(({ integrationId, tablePreviews }) => {
    const initialTable = tablePreviews
      .map(({ id, index }) => ({ id, index }))
      .sort(byIndex)[0];
    if (initialTable) {
      dispatchAction(setCurrentTableId({ id: initialTable.id, integrationId }));
    }
  })
);

const handleSetTableForIntegrations = routine(
  ofType(setTablePreviews),
  extractPayload(),
  switchMap(({ integrationId }) =>
    of(integrationId).pipe(
      withLatestFrom(
        getTablePreviewsStream(integrationId),
        fields$,
        workspaces$,
        getSchedulesStream(integrationId),
        getScheduleStream(integrationId),
        getTabularMappingStream(integrationId),
        transferConfigs$,
        getActiveIntegrationStream(integrationId)
      )
    )
  ),
  tap(
    ([
      integrationId,
      tablePreviews,
      fields,
      workspaces,
      schedules,
      { loadedScheduleId },
      currentTabularMapping,
      transferConfigs,
      activeIntegration,
    ]) => {
      let currentTransferConfig;

      if (loadedScheduleId && !activeIntegration.selectedTransferConfigId) {
        const schedule = schedules.schedules.find(
          s => loadedScheduleId === s._id
        );
        if (!schedule) {
          return;
        }
        if (!isJobWithTabularMapping(schedule.jobOptions)) {
          return;
        }
        currentTransferConfig = schedule?.jobOptions.tabularMapping;
      } else {
        currentTransferConfig = getCurrentTransferConfig(
          transferConfigs.configs,
          activeIntegration
        );
      }

      if (!currentTransferConfig) {
        return;
      }

      // if the mappings are already applied, we don't want to overwrite them
      if (!isEqual(currentTabularMapping, {})) {
        currentTransferConfig = tabularMappingIntoTransferConfig(
          currentTabularMapping,
          currentTransferConfig
        );
      }

      const tabularMapping = transferConfigToTabularMapping({
        transferConfig: currentTransferConfig,
        tablePreviews,
        allFields: fields.all,
        allWorkspaces: workspaces.existing,
        mapTablesBy: activeIntegration.integrationMappingParams.mapTablesBy,
        mapColumnsBy: activeIntegration.integrationMappingParams.mapColumnsBy,
      });

      dispatchAction(
        applyTabularMapping({
          integrationId,
          mapping: adjustTabularMapping({ tabularMapping, tablePreviews }),
        })
      );
      dispatchAction(applyTabularMappingsDefault({ integrationId }));
      dispatchAction(applyRequiredTableMapping({ integrationId }));
      dispatchAction(navigateToConfigure({ integrationId, tableId: null }));
    }
  )
);

export default [
  handleFetchPreviewRows,
  handleFetchProblemRows,
  handleSetTables,
  handleSetTableForIntegrations,
];
