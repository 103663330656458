import { SidebarMenu, SidebarMenuCard } from '@ardoq/sidebar-menu';
import { DragAndDropSectionsProvider } from '@ardoq/drag-and-drop';
import manageFields$ from 'appModelStateEdit/manageFields/manageFields$';
import { ManageFieldsStreamShape } from 'appModelStateEdit/manageFields/types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  addField,
  deleteField,
  editField,
  modifyFieldOrder,
  setFilter,
} from 'appModelStateEdit/manageFields/actions';
import { hideRightPane } from 'appContainer/actions';
import ExternallyManagedIndicatorWithPopover from 'externallyManaged/ExternallyManagedIndicatorWithPopover';
import { snakeCase } from 'lodash';
import { ExpandableFilter } from './ExpandableFilter';

const ManageFields = ({
  canAddFields,
  filterTerm,
  fieldCards,
}: ManageFieldsStreamShape) => {
  return (
    <SidebarMenu
      headerTitle="Manage fields"
      headerTitleTestId="manage-fields-header-title"
      headerRightContent={
        <ExpandableFilter
          filterTerm={filterTerm ?? ''}
          onFilterTermChange={term => {
            dispatchAction(setFilter(term));
          }}
        />
      }
      onCloseClick={() => dispatchAction(hideRightPane())}
      {...(canAddFields
        ? {
            primaryButtonLabel: 'Add field',
            onPrimaryButtonClick: () => dispatchAction(addField()),
            primaryButtonClickId: 'sidebar-action-add-field',
          }
        : {})}
    >
      <DragAndDropSectionsProvider
        onDrop={orderedFieldIds =>
          dispatchAction(modifyFieldOrder(orderedFieldIds))
        }
      >
        {fieldCards.map(fieldCard => {
          const isExternallyManaged =
            fieldCard.isExternallyManaged &&
            fieldCard.externallyManagedWorkspaceId;
          return (
            <SidebarMenuCard
              key={fieldCard.fieldId}
              name={fieldCard.fieldLabel}
              dataTestId={`manage-fields-card--${snakeCase(
                fieldCard.fieldName
              )}`}
              propsList={fieldCard.fieldData}
              dragAndDropCardId={fieldCard.fieldId}
              {...(fieldCard.isEditable
                ? {
                    onDeleteClick: () =>
                      dispatchAction(deleteField(fieldCard.fieldId)),
                    onEditClick: () =>
                      dispatchAction(editField(fieldCard.fieldId)),
                  }
                : {})}
              {...(isExternallyManaged
                ? {
                    footerContent: (
                      <ExternallyManagedIndicatorWithPopover
                        workspaceId={fieldCard.externallyManagedWorkspaceId}
                      />
                    ),
                  }
                : {})}
            />
          );
        })}
      </DragAndDropSectionsProvider>
    </SidebarMenu>
  );
};

export default connect(ManageFields, manageFields$);
