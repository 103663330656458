import getDefaultState from '../streams/defaultState';
import * as navigatorActions from '../actions/navigatorActions';
import { navigatorLayoutOperations } from '../reducers/navigatorLayoutOperations';
import { createReferencesOperations } from '../reducers/createReferencesOperations';
import { reducedStream, reducer, streamReducer } from '@ardoq/rxbeach';
import { ExternalReducers, NavigatorLayoutState } from '../types';
import { distinctUntilChanged, Observable, shareReplay } from 'rxjs';
import { navigatorTreeOperations } from '../reducers/navigatorTreeOperations';
import { navigatorTreeDragAndDropOperations } from '../reducers/navigatorTreeDragAndDropOperations';
import { expandCollapseToggleClicked } from '../actions/navigatorActions';
import { getFilterTermStream } from './filterTerm$';

export const getNavigatorStream = (
  externalReducers: ExternalReducers,
  namespace?: string
): Observable<NavigatorLayoutState> =>
  reducedStream<NavigatorLayoutState>(
    'navigator$',
    getDefaultState(),
    [
      reducer(
        navigatorActions.containerCreated,
        navigatorLayoutOperations.containerCreated
      ),
      reducer(
        navigatorActions.containerHeightChanged,
        navigatorLayoutOperations.containerHeightChanged
      ),
      reducer(
        navigatorActions.containerScrolled,
        navigatorLayoutOperations.containerScrolled
      ),
      reducer(
        navigatorActions.resetScroll,
        navigatorLayoutOperations.resetScroll
      ),
      // TOD where is this used?
      reducer(navigatorActions.clearTree, navigatorLayoutOperations.clearTree),
      streamReducer(
        getFilterTermStream(namespace),
        navigatorTreeOperations.setFilterTerm
      ),
      reducer(
        expandCollapseToggleClicked,
        navigatorTreeOperations.toggleExpandCollapsed
      ),
      reducer(
        navigatorActions.dragStart,
        navigatorTreeDragAndDropOperations.startDrag
      ),
      reducer(
        navigatorActions.dragUpdate,
        navigatorTreeDragAndDropOperations.updateDrag
      ),
      reducer(
        navigatorActions.finalizeDragEnd,
        navigatorTreeDragAndDropOperations.finalizeDragEnd
      ),
      reducer(
        navigatorActions.dragEndTransitionEnd,
        navigatorTreeDragAndDropOperations.endDragEndTransition
      ),
      reducer(
        navigatorActions.clearHighlight,
        navigatorTreeDragAndDropOperations.clearHighlight
      ),
      reducer(
        navigatorActions.setLinkSources,
        createReferencesOperations.handleSetLinkSource
      ),
      reducer(
        navigatorActions.linkUpdate,
        createReferencesOperations.handleLinkUpdate
      ),
      reducer(
        navigatorActions.setLinkTarget,
        createReferencesOperations.handleSetLinkTarget
      ),
      reducer(
        navigatorActions.streamInitialized,
        navigatorTreeOperations.handleStreamIsInitialized
      ),
      ...externalReducers,
    ],
    { namespace }
  ).pipe(
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
