import { unescapeHTML } from '@ardoq/html';
import { Icon, IconSize } from '@ardoq/icons';
import { ResultType, ResultContainer, Pointer, Divider } from './atoms';
import { QuickSearchResultItem } from './types';
import { Text } from '@ardoq/typography';
import { MatchRenderer } from './MatchRenderer';
import { ResultDescription } from './searchResultComponents';

const OldResultItem = ({ result }: { result: QuickSearchResultItem }) => {
  const {
    name,
    iconName,
    path,
    fieldValue,
    componentKey,
    typeName,
    displayText,
    matches,
    type,
  } = result;
  const nameMatch = matches?.find(m => m.key === 'name');
  const fieldValueMatch = matches?.find(m => m.key === 'fieldValue');
  return (
    <>
      <ResultType>
        {iconName && <Icon iconName={iconName} iconSize={IconSize.SMALL} />}
      </ResultType>
      <ResultContainer>
        <Text variant="text2">
          {componentKey}
          {nameMatch && type !== 'reference' ? (
            <MatchRenderer isOldView text={name} match={nameMatch} />
          ) : (
            name
          )}
        </Text>
        {fieldValue && (
          <Text variant="caption">
            Field value:
            <MatchRenderer
              isOldView
              text={fieldValue}
              match={fieldValueMatch}
            />
          </Text>
        )}
        {typeName && <Text variant="caption">Type: {typeName}</Text>}
        {displayText && (
          <Text variant="caption">Display text: {displayText}</Text>
        )}
        <ResultDescription result={result} lineClamp={3} isOldView />
        {path && (
          <Text variant="caption">
            {path.map((item, i) => (
              <span key={i}>
                {i !== 0 && <Pointer>{' > '}</Pointer>}
                {unescapeHTML(item)}
              </span>
            ))}
          </Text>
        )}
      </ResultContainer>
      <Divider />
    </>
  );
};

export default OldResultItem;
