import { ExcludeFalsy } from '@ardoq/common-helpers';
import Tree from './models/tree';
import { NodeModel } from './models/types';

export const getDragTargetNodes = (
  selection: string[],
  dragTargetNode: NodeModel,
  tree: Tree
) =>
  selection.length > 1
    ? selection.map(id => tree.getNode(id)).filter(ExcludeFalsy)
    : [dragTargetNode];
export const getDragTargetIds = (
  selection: string[],
  dragTargetNode: NodeModel | null
) => {
  return new Set([...selection, dragTargetNode?.id].filter(ExcludeFalsy));
};
