/**
 * This module is responsible for encoding and decoding the loaded states of the app.
 * In viewpoint mode, each dataset is represented by a LoadedState object.
 *
 * As these are encoded and stored in the URL, we need to ensure backwards compatibility
 * by making sure encoding and decoding is versioned correctly.
 *
 * When making changes to the encoding/decoding, don't change existing versions -
 * instead:
 * - create a new version by copying the latest existing versions folder
 * - make changes and update tests for the new version
 * - add the new version to SUPPORTED_VERSIONS in versions.ts
 * - import the new decode function in this file and add it to the decoderVersionMap
 * - change the import for the encoder to the new version in this file  (This is what switches over to use the new version)
 * - update the if-statement checking version for tracking use of old versions (in this file)
 * - consider if it might be time to remove support for old versions
 */

import { logError } from '@ardoq/logging';
import { decodeLoadedStateV1 } from './encodeDecode.v1/decode';
import { decodeLoadedStateV2 } from './encodeDecode.v2/decode';
import { decodeLoadedStateV3 } from './encodeDecode.v3/decode';
import { decodeLoadedStateV4 } from './encodeDecode.v4/decode';
import { decodeLoadedStateV5 } from './encodeDecode.v5/decode';
import { decodeLoadedStateV6 } from './encodeDecode.v6/decode';
import { decodeLoadedStateV7 } from './encodeDecode.v7/decode'; // not in use yet, added for v7 tests to run
import { encodeLoadedStateV7 as currentVersionEncoder } from './encodeDecode.v7/encode';
import { STATE_SEPARATOR } from './consts';
import { isSupportedVersion, SupportedVersions } from './versions';
import { LoadedStateParams } from '@ardoq/api-types';
import { loadedStateTracking } from './loadedStateTracking';

// enforce that all supported versions have a decoder mapping
type DecoderVersionMap = Record<
  SupportedVersions,
  (encodedLoadedState: string) => LoadedStateParams[]
>;

const decoderVersionMap: DecoderVersionMap = {
  v1: decodeLoadedStateV1,
  v2: decodeLoadedStateV2,
  v3: decodeLoadedStateV3,
  v4: decodeLoadedStateV4,
  v5: decodeLoadedStateV5,
  v6: decodeLoadedStateV6,
  v7: decodeLoadedStateV7,
};

// regex to split on first instance of STATE_SEPARATOR to extract version from encoded state
const reSplitVersion = new RegExp(String.raw`(?<=^v\d+)${STATE_SEPARATOR}`);

const splitVersionAndState = (
  encodedStates: string
): { version: string; state: string } => {
  const [version, state] = encodedStates.split(reSplitVersion);
  return { version, state };
};

export const decodeLoadedState = (
  encodedStates: string
): LoadedStateParams[] => {
  if (encodedStates === '') return [];
  const { version, state } = splitVersionAndState(encodedStates);
  try {
    if (!isSupportedVersion(version)) {
      logError(Error('Unhandled version in loaded state'));
      return [];
    }
    if (version !== 'v7') {
      loadedStateTracking.trackOldVersion(version);
    }
    // Execute mapped decoder
    const decoder = decoderVersionMap[version];
    return decoder(state);
  } catch (e) {
    logError(Error('Decoding loaded state failed'));
    return [];
  }
};

export const encodeLoadedState = (states: LoadedStateParams[]): string => {
  if (states.length === 0) return '';
  try {
    // Always encode with the current version
    return currentVersionEncoder(states);
  } catch (e) {
    logError(Error('Encoding loaded state failed'));
    return '';
  }
};
