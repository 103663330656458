import { capitalize } from '@ardoq/common-helpers';
import { SideNote } from '@ardoq/forms';
import { FlexBox, Stack } from '@ardoq/layout';
import { Link, Text } from '@ardoq/typography';
import DefaultRoleDescription from 'admin/accessControl/ManageUserRoles/DefaultRoleDescription';
import { DetailsColumn } from './atoms';
import { UserProfilePageProps } from 'admin/accessControl/UserProfilePage/types';
import { LabeledDetail } from 'admin/accessControl/UserProfilePage/components/LabeledDetail';

export const UserRoleDetails = ({
  userRole,
  user,
  commands,
}: Pick<UserProfilePageProps, 'userRole' | 'user' | 'commands'>) => {
  return (
    <DetailsColumn $flexGrow={1.6}>
      <FlexBox width="full">
        <LabeledDetail label="User role">
          <Stack gap="large">
            <Link
              hideIcon
              type="secondary"
              onClick={() => userRole && commands.onRoleClick(userRole)}
            >
              <Text variant="text1">
                {userRole?.title || capitalize(user.role)}
              </Text>
            </Link>
            <SideNote>
              <DefaultRoleDescription role={user.role} />
            </SideNote>
          </Stack>
        </LabeledDetail>
      </FlexBox>
    </DetailsColumn>
  );
};
