import fp from 'lodash/fp';
import {
  IntegrationId,
  TabularMapping,
} from 'integrations/common/streams/tabularMappings/types';
import { TablePreviews } from 'integrations/common/streams/tablePreviews/types';
import { TransferState } from 'integrations/common/streams/transferState/types';
import {
  ExportRoute,
  ImportRoute,
  OverviewRoute,
  RoutePath,
  NewExportRoutes,
} from './types';
import { isAllowedTab } from './tabs';
import { Features, hasFeature } from '@ardoq/features';
import { SourceType } from '@ardoq/api-types/integrations';
import { setVisibleIntegration } from 'integrations/actions';
import {
  action$,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { IntegrationIdBySourceTypes } from 'integrations/common/streams/activeIntegrations/sourceTypes';
import { filter, firstValueFrom, map } from 'rxjs';
import { updateConnectionList } from '../streams/connections/actions';

type IsPathReadyProps = {
  tabularMapping: TabularMapping;
  tablePreviews: TablePreviews;
  transferState: TransferState;
  allowedOverviewRoutes?: OverviewRoute[];
  integrationId: IntegrationId;
};

const isConfigureStepReady = ({ tablePreviews }: IsPathReadyProps) =>
  fp.toPairs(tablePreviews).length > 0;

const isReviewStepReady = (props: IsPathReadyProps) => {
  const { transferState } = props;
  return transferState.requestStatus !== 'INIT' && isConfigureStepReady(props);
};

const isFinalStepReady = (props: IsPathReadyProps) => {
  const { transferState } = props;
  return isReviewStepReady(props) && !transferState.dryRun;
};

const PATH_AVAILABILITY: Record<
  RoutePath,
  (props: IsPathReadyProps) => boolean
> = {
  [OverviewRoute.SCHEDULES]: props =>
    isAllowedTab(
      OverviewRoute.SCHEDULES,
      props.integrationId,
      props.allowedOverviewRoutes
    ),
  [OverviewRoute.CONFIGURATIONS]: props =>
    isAllowedTab(
      OverviewRoute.CONFIGURATIONS,
      props.integrationId,
      props.allowedOverviewRoutes
    ),
  [OverviewRoute.CONNECTIONS]: props =>
    isAllowedTab(
      OverviewRoute.CONNECTIONS,
      props.integrationId,
      props.allowedOverviewRoutes
    ),
  [ImportRoute.SELECT_DATA]: () => true,
  [ExportRoute.SELECT_DATA]: props =>
    EXPORT_AVAILABILITY[props.integrationId]?.() ?? false,
  [ImportRoute.CONFIGURE]: isConfigureStepReady,
  [ExportRoute.CONFIGURE]: isConfigureStepReady,
  [ImportRoute.REVIEW]: isReviewStepReady,
  [ExportRoute.REVIEW]: isReviewStepReady,
  [ImportRoute.IMPORT_AND_SCHEDULE]: isFinalStepReady,
  [ExportRoute.EXPORT]: isFinalStepReady,
  // Todo: Implement the the route validation for the Signavio Exporter
  [NewExportRoutes.DATA_SELECTION]: () => true,
  [NewExportRoutes.FIELD_MAPPING]: () => true,
  [NewExportRoutes.REVIEW_EXPORT]: () => true,
  [NewExportRoutes.SUCCESS]: () => true,
  [NewExportRoutes.FAILED]: () => true,
  [NewExportRoutes.EXPORT_AND_SCHEDULE]: () => true,
};

const isValidPath = (path: string): path is RoutePath => {
  return (
    Object.values(OverviewRoute).includes(path as OverviewRoute) ||
    Object.values(ImportRoute).includes(path as ImportRoute) ||
    Object.values(ExportRoute).includes(path as ExportRoute) ||
    Object.values(NewExportRoutes).includes(path as NewExportRoutes)
  );
};

export const isPathReady = (route: string, props: IsPathReadyProps) => {
  if (!isValidPath(route)) {
    return false;
  }
  return PATH_AVAILABILITY[route](props);
};

export const isOverviewRoute = (route: RoutePath): route is OverviewRoute => {
  return Object.values<string>(OverviewRoute).includes(route);
};

export const isImportRoute = (route: RoutePath): route is ImportRoute => {
  return Object.values<string>(ImportRoute).includes(route);
};

export const isExportRoute = (route: RoutePath): route is ExportRoute => {
  return Object.values<string>(ExportRoute).includes(route);
};

export const EXPORT_AVAILABILITY: Partial<
  Record<IntegrationId, () => boolean>
> = {
  'servicenow-v3': () => hasFeature(Features.SERVICENOW_EXPORT_ENABLE),
  'signavio-exporter': () => true,
};

export const navigateToIntegrationBySource = (source: SourceType) => {
  dispatchAction(
    setVisibleIntegration({
      id: IntegrationIdBySourceTypes[source][0],
    })
  );
};

export function waitUntilConnectionsListAvailable(
  integrationId: IntegrationId
) {
  return firstValueFrom(
    action$.pipe(
      ofType(updateConnectionList),
      filter(action => action.payload.integrationId === integrationId),
      extractPayload(),
      map(payload => {
        return payload.connectionsState.connections || [];
      })
    )
  );
}
