import { trackEvent } from 'tracking/tracking';
import activeView$ from 'streams/views/mainContent/activeView$';

export enum QuickPerspectiveType {
  WORKSPACE_FILTER = 'workspace filter',
  COMPONENT_FILTER = 'component filter',
  REFERENCE_FILTER = 'reference filter',
  DYNAMIC_FILTER = 'dynamic filter',
}

// Tracks the use and type of quick perspectives
export const trackToggledQuickPerspective = (metadata: {
  type: QuickPerspectiveType;
  isEnabling: boolean;
}) =>
  trackEvent('Toggled quick perspective filter', {
    activeView: activeView$.state.mainViewId,
    ...metadata,
  });
