import { StatusType, Tag } from '@ardoq/status-ui';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import {
  openCopyPresentationModal,
  openDeletePresentationMenu,
} from 'presentationEditor/presentationUtil';
import {
  getDoqTypeForView,
  presentationSlidesViewInfo,
} from '../../viewDeprecation/util';
import { PresentationCardThumbnail } from './PresentationCardThumbnails';
import EntityBrowserActionsMenu from 'components/EntityBrowser/EntityBrowserActionsMenu';
import { trackEvent } from 'tracking/tracking';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { ButtonType } from '@ardoq/button';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { IconName } from '@ardoq/icons';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import {
  ArdoqId,
  AssetType,
  Meta,
  APIPresentationAssetAttributes,
} from '@ardoq/api-types';
import { Link } from '@ardoq/typography';
import { Card } from '@ardoq/card';
import { Box, Stack } from '@ardoq/layout';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { presentationAccessControlOperations } from 'resourcePermissions/accessControlHelpers/presentations';
import { presentationOperations } from 'streams/presentations/presentationOperations';
import { map } from 'rxjs';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { connect } from '@ardoq/rxbeach';
import { PermissionContext } from '@ardoq/access-control';
import { slideInterface } from 'modelInterface/presentations/slideInterface';
import { isViewDiscontinued } from 'viewDeprecation/restrictedViews';

const FixedCardSize = styled.div`
  width: 280px;
  height: 341px;
`;

type PresentationCardProperties = {
  presentation: APIPresentationAssetAttributes & Meta<AssetType.PRESENTATION>;
  permissionContext: PermissionContext;
  onCardClick: (presentationId: ArdoqId) => void;
  onStarClick: (presentationId: ArdoqId) => void;
  onPlayButtonClick: (presentationId: ArdoqId) => void;
  onEditButtonClick: (presentationId: ArdoqId) => void;
};

const getSlideThumbnail = (
  presentation: APIPresentationAssetAttributes,
  slideIndex: number
) => {
  const slideIds = presentation.slides;

  if (!slideIds?.[slideIndex]) return { image: '' };

  const slideId = slideIds[slideIndex];

  if (!slideInterface.exists(slideId)) return { image: '' };

  const viewId = slideInterface.getViewId(slideId);

  return isViewDiscontinued(viewId)
    ? { doqType: getDoqTypeForView(viewId) }
    : { image: slideInterface.getThumbnailSrc(slideId) ?? '' };
};

const PresentationCard = ({
  presentation,
  permissionContext,
  onCardClick,
  onStarClick,
  onPlayButtonClick,
  onEditButtonClick,
}: PresentationCardProperties) => {
  const userCanEditPres =
    presentationAccessControlOperations.canEditPresentation(
      currentUserInterface.getPermissionContext(),
      presentation
    );
  const presHasSlides = presentationOperations.hasSlides(presentation);
  const isPublic = presentationAccessControlOperations.isPublic(presentation);

  const slidesViewInfo = presentationSlidesViewInfo(presentation);
  const slidesViewStatus =
    slidesViewInfo &&
    new Set(slidesViewInfo.map(({ viewStatus }) => viewStatus));

  const hasDiscontinuedSlides =
    slidesViewStatus && slidesViewStatus.has(MetaInfoViewStatus.DISCONTINUED);
  const deprecatedSlidesLabel = !slidesViewStatus
    ? null
    : hasDiscontinuedSlides
      ? 'Some views are discontinued'
      : slidesViewStatus.has(MetaInfoViewStatus.SOON_TO_BE_DISCONTINUED)
        ? 'Some views to be soon discontinued'
        : null;

  const hasDeprecatedSlides = Boolean(deprecatedSlidesLabel);
  const isFavorite = presentation.meta.favorite;

  const options = [
    presentationAccessControlOperations.canCurrentUserOpenInApp(
      permissionContext,
      presentation
    ) && {
      label: userCanEditPres ? 'Edit' : 'Open',
      testId: 'edit-or-open-presentation',
      type: DropdownOptionType.OPTION,
      onClick: () => onEditButtonClick(presentation._id),
    },
    presentationAccessControlOperations.canCopyPresentation(
      permissionContext,
      presentation
    ) && {
      label: 'Copy',
      testId: 'copy-presentation',
      type: DropdownOptionType.OPTION,
      onClick: () => openCopyPresentationModal(presentation),
    },
    {
      label: isFavorite ? 'Unfavorite' : 'Favorite',
      testId: 'favorite-presentation',
      type: DropdownOptionType.OPTION,
      onClick: () => onStarClick(presentation._id),
    },
    userCanEditPres && {
      label: 'Delete',
      testId: 'delete-presentation',
      type: DropdownOptionType.BUTTON_OPTION,
      buttonType: ButtonType.DANGER,
      onClick: () => openDeletePresentationMenu(presentation),
    },
  ].filter(ExcludeFalsy);
  const thumbnails = [2, 1, 0].map(index =>
    getSlideThumbnail(presentation, index)
  );
  return (
    <FixedCardSize>
      <Card
        height="full"
        onClick={() => onCardClick(presentation._id)}
        tagLabel={isPublic ? 'Public' : undefined}
        tagType={isPublic ? StatusType.INFO : undefined}
        captionIcon={isFavorite ? IconName.STAR : undefined}
        captionIconColor={colors.iconWarning}
        caption="Presentation"
        title={presentation.name}
        titleLineClamp={2}
        media={<PresentationCardThumbnail thumbnails={thumbnails} />}
        description={presentation.description}
        headerRightContent={
          <EntityBrowserActionsMenu
            menuOptions={options}
            onTrackClick={() => {
              trackEvent('Presentations: clicked on card options');
            }}
          />
        }
      >
        <Box padding="medium" paddingTop="none">
          <Stack gap="medium">
            {hasDeprecatedSlides && deprecatedSlidesLabel && (
              <Tag
                statusType={
                  hasDiscontinuedSlides ? StatusType.ERROR : StatusType.WARNING
                }
                label={deprecatedSlidesLabel}
              />
            )}
            {presHasSlides && (
              <Link
                onClick={e => {
                  e.stopPropagation();
                  onPlayButtonClick(presentation._id);
                }}
                iconName={IconName.PRESENTATION}
              >
                Play
              </Link>
            )}
          </Stack>
        </Box>
      </Card>
    </FixedCardSize>
  );
};

export default connect(
  PresentationCard,
  currentUserPermissionContext$.pipe(
    map(permissionContext => {
      return {
        permissionContext,
      };
    })
  )
);
