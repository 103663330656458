import { trackEvent } from 'tracking/tracking';
import { ViewIds } from '@ardoq/api-types';
import { LAYOUT_SETTINGS_BY_VIEW_ID } from 'appLayout/ardoqStudio/viewpointViewSettings';

export const trackViewSettingsChange = (payload: {
  viewId: ViewIds;
  changedSettings: [string, any][];
}) => {
  const { viewId, changedSettings } = payload;

  const [viewSettingName] = changedSettings[0];

  if (
    (IGNORED_SETTINGS_BY_VIEW_ID.get(viewId) || []).includes(viewSettingName)
  ) {
    return;
  }

  trackEvent('View setting changed', payload);
};

export const IGNORED_SETTINGS_BY_VIEW_ID = new Map<ViewIds, string[]>([
  [ViewIds.BLOCKS, ['layoutData']],
]);

type TrackViewSettingsChangeWithCategoryArgs = {
  viewId: ViewIds;
  changedSetting: [string, any];
};
export const trackViewSettingChangeWithCategory = ({
  viewId,
  changedSetting,
}: TrackViewSettingsChangeWithCategoryArgs) => {
  const [viewSettingName, viewSettingValue] = changedSetting;

  if (
    (IGNORED_SETTINGS_BY_VIEW_ID.get(viewId) || []).includes(viewSettingName)
  ) {
    return;
  }

  const settingsCategory = LAYOUT_SETTINGS_BY_VIEW_ID.get(viewId)?.includes(
    viewSettingName
  )
    ? 'layoutSetting'
    : 'layoutAction';

  trackEvent('Changed view style setting', {
    viewId,
    viewSettingName,
    viewSettingValue,
    settingsCategory,
  });
};
