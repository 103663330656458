import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
  ActionCreator,
} from '@ardoq/rxbeach';
import {
  combineLatestWith,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
import {
  advancedSearchError,
  exportAdvancedSearch,
  exportAdvancedSearchSuccess,
  queryAdvancedSearch,
  queryAdvancedSearchSuccess,
  selectCondition,
  selectFilterType,
  selectPage,
  setSort,
  updateAdvancedSearch,
} from './actions';
import advancedSearch$, {
  BranchedAdvancedSearchShape,
} from './advancedSearch$';
import { getCombinedRules } from '../AdvancedSearch/utils';
import FileSaver from 'file-saverjs';
import {
  AdvancedSearchError,
  AdvancedSearchShape,
  AdvancedSearchStateShape,
  ExecuteAdvancedSearchContext,
  PayloadQueryAdvancedSearch,
  PayloadSelectPage,
  PayloadSetSort,
} from './types';
import {
  triggerSearchLoading,
  triggerSearchLoadingDone,
  updateSearchQuery,
} from 'search/actions';
import {
  QueryEditorNamespace,
  advancedSearchEditor$,
} from 'search/QueryEditor/queryEditor$';
import {
  trackFieldsOperatorsAndTargetEntityTypesUsedInAdvancedSearchQuery,
  trackQueriedAdvancedSearch,
} from 'search/tracking';
import { dateRangeOperations } from '@ardoq/date-range';
import { isEqual } from 'lodash';
import {
  ReportEventLocations,
  trackExportAdHocSearchToExcel,
  trackQueryResults,
} from '@ardoq/report-reader';
import { trackEvent } from '../../tracking/tracking';
import { fieldInterface } from '../../modelInterface/fields/fieldInterface';
import { ExcludeFalsy, isArdoqError, Result } from '@ardoq/common-helpers';
import { SearchParams, advancedSearchApi } from '@ardoq/api';
import {
  AdvancedSearchResultField,
  SearchFieldsResult,
  SearchResult,
  UnknownDocumentType,
  DataSourceType,
} from '@ardoq/api-types';

const handleQueryAdvancedSearchSuccess = routine(
  ofType(queryAdvancedSearchSuccess),
  withLatestFrom(advancedSearchEditor$),
  tap(([, { selectedQueryId }]) =>
    trackQueriedAdvancedSearch({
      isStoredQuery: Boolean(selectedQueryId),
    })
  )
);

export const toAdvancedSearchQuery = (
  { queryBuilderRules, from, sortOrder, sortBy }: PayloadQueryAdvancedSearch,
  {
    selectedFilterType,
    branchId,
  }: {
    selectedFilterType: AdvancedSearchStateShape['selectedFilterType'];
    branchId?: BranchedAdvancedSearchShape['branchId'];
  }
): ExecuteAdvancedSearchContext => {
  return {
    searchParams: {
      from,
      sortOrder,
      sortBy,
      size: 100,
      branchId,
    } satisfies SearchParams,
    query: getCombinedRules({
      queryBuilderRules,
      selectedFilterType,
    }),
  };
};

export const dateRangeify = (fields: AdvancedSearchResultField[]) => {
  const properFields = fields
    .map(field => fieldInterface.getFieldData(field._id))
    .filter(ExcludeFalsy);
  return dateRangeOperations.foo(properFields);
};

export const handleSearchResponse = (
  {
    searchResponse,
    searchFieldsResponse,
  }: {
    searchResponse: Result<SearchResult<UnknownDocumentType>>;
    searchFieldsResponse: Result<SearchFieldsResult>;
  },
  doTrack: boolean
) => {
  if (isArdoqError(searchResponse) || isArdoqError(searchFieldsResponse)) {
    dispatchAction(
      advancedSearchError({
        searchError: AdvancedSearchError.QUERY,
      })
    );
    return;
  }
  if (doTrack) {
    trackQueryResults(
      trackEvent,
      DataSourceType.ADVANCED_SEARCH,
      ReportEventLocations.AD_HOC_ADVANCED_SEARCH,
      searchResponse.total
    );
  }
  dispatchAction(
    queryAdvancedSearchSuccess({
      ...searchResponse,
      fields: dateRangeify(searchFieldsResponse.fields),
    })
  );
};

export const executeSearch = ({
  searchParams,
  query,
}: ExecuteAdvancedSearchContext) =>
  forkJoin({
    searchResponse: advancedSearchApi.search<UnknownDocumentType>(
      query,
      searchParams
    ),
    searchFieldsResponse: advancedSearchApi.fieldSearch(query, searchParams),
  });

export const searchStart = (ns: QueryEditorNamespace) =>
  dispatchAction(triggerSearchLoading(), ns);

export const searchDone = (ns: QueryEditorNamespace) =>
  dispatchAction(triggerSearchLoadingDone(), ns);

const handleQueryAdvancedSearch = routine(
  ofType(queryAdvancedSearch),
  extractPayload(),
  tap(() => searchStart(QueryEditorNamespace.ADVANCED_SEARCH)),
  withLatestFrom(advancedSearch$),
  map(([payload, advancedSearch]) =>
    toAdvancedSearchQuery(payload, advancedSearch)
  ),
  tap(({ query }) =>
    trackFieldsOperatorsAndTargetEntityTypesUsedInAdvancedSearchQuery(query)
  ),
  switchMap(it => executeSearch(it)),
  tap(response => handleSearchResponse(response, true)),
  tap(() => searchDone(QueryEditorNamespace.ADVANCED_SEARCH))
);

export const handleSetSort = (
  stream: Observable<AdvancedSearchShape>,
  action: ActionCreator<PayloadSetSort>
) =>
  routine(
    ofType(action),
    extractPayload(),
    withLatestFrom(stream),
    tap(([{ sortOrder, sortBy }, { queryBuilderRules }]) => {
      dispatchAction(
        queryAdvancedSearch({
          queryBuilderRules,
          sortBy,
          sortOrder,
        })
      );
    })
  );

export const handleSelectPage = (
  stream: Observable<AdvancedSearchShape>,
  action: ActionCreator<PayloadSelectPage>
) =>
  routine(
    ofType(action),
    extractPayload(),
    withLatestFrom(stream),
    tap(([{ pageNumber }, { queryBuilderRules, sortBy, sortOrder }]) => {
      dispatchAction(
        queryAdvancedSearch({
          queryBuilderRules,
          sortBy,
          sortOrder,
          from: (pageNumber - 1) * 100,
        })
      );
    })
  );

const handleExportAdvancedSearch = routine(
  ofType(exportAdvancedSearch),
  extractPayload(),
  switchMap(({ queryBuilderRules, filename, branchId }) =>
    forkJoin({
      response: advancedSearchApi.exportSearch(queryBuilderRules, {
        size: 10000,
        branchId,
      }),
      filename: of(filename),
    })
  ),
  tap(({ response, filename }) => {
    if (isArdoqError(response)) {
      dispatchAction(
        advancedSearchError({
          searchError: AdvancedSearchError.EXPORT,
        })
      );
      return;
    }
    trackExportAdHocSearchToExcel(trackEvent, DataSourceType.ADVANCED_SEARCH);
    FileSaver(response, `${filename}.xlsx`);
    dispatchAction(exportAdvancedSearchSuccess());
  })
);

const handleChangedSearch = routine(
  ofType(updateAdvancedSearch, selectCondition, selectFilterType),
  // FIXME
  // The following is a work around for doing a routine on a state stream.
  // combineLatestWith will make the rest of the routine react to any changes to
  // the advancedSearch$ in addition to the actions. distinctUntilChanged with
  // a deep comparator (isEqual from lodash) after picking only the interesting
  // parts of the state, should ensure we only trigger updates when we want to.
  combineLatestWith(advancedSearch$),
  map(([, { queryBuilderRules, selectedFilterType }]) => ({
    queryBuilderRules,
    selectedFilterType,
  })),
  distinctUntilChanged(isEqual),
  map(getCombinedRules),
  tap(query =>
    dispatchAction(
      updateSearchQuery({
        query,
      }),
      QueryEditorNamespace.ADVANCED_SEARCH
    )
  )
);

export const advancedSearchRoutines = collectRoutines(
  handleQueryAdvancedSearchSuccess,
  handleQueryAdvancedSearch,
  handleSetSort(advancedSearch$, setSort),
  handleSelectPage(advancedSearch$, selectPage),
  handleExportAdvancedSearch,
  handleChangedSearch
);
