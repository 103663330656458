import { tap, withLatestFrom } from 'rxjs/operators';
import { showRightPane } from 'appContainer/actions';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
} from '@ardoq/rxbeach';
import { activateComponentFab } from './actions';
import { context$ } from 'streams/context/context$';
import { trackBigCircleCreateButton } from './tracking';

const handleActivateComponentFab = routine(
  ofType(activateComponentFab),
  withLatestFrom(context$),
  tap(([, { componentId: parentId, workspaceId }]) => {
    trackBigCircleCreateButton();
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.CREATE_COMPONENT,
        parentId,
        workspaceId,
      })
    );
  })
);

export default collectRoutines(handleActivateComponentFab);
