import { find, get, uniq } from 'lodash';
import { derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { SelectOption } from '@ardoq/select';
import SSOAttributeMapping$ from './SSOAttributeMapping$';
import {
  Claims,
  ConditionOptionByAttribute,
  ConditionOptionsByType,
  PreviewAttributeType,
  RulesById,
} from './types';
import {
  inferAttributeType,
  loginConfigToSsoOptions,
  permissionGroupsToGroupOptions,
} from './utils';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { MappingRule } from '@ardoq/api-types';
import { areValidChanges } from './validators';
import { commands } from './commands';

const getChanges = (currentMapping: RulesById, initialMapping: MappingRule[]) =>
  Object.values(currentMapping).reduce<RulesById>((acc, curr) => {
    if (find(initialMapping, curr)) return acc;
    return { ...acc, [curr._id]: curr };
  }, {});

const getDeletions = (
  currentMapping: RulesById,
  initialMapping: MappingRule[]
) => {
  const currentMappingIds = Object.keys(currentMapping);
  return initialMapping
    .map(({ _id }) => {
      if (!currentMappingIds.includes(_id)) return _id;
      return;
    })
    .filter(ExcludeFalsy);
};

const getConditionOptionsByAttribute = (
  currentClaims: Claims,
  conditionOptions: ConditionOptionsByType
) => {
  if (!conditionOptions) return {};
  return Object.entries(currentClaims).reduce<ConditionOptionByAttribute>(
    (acc, [attributeName, attributeValue]) => ({
      ...acc,
      [attributeName]: get(
        conditionOptions,
        inferAttributeType(attributeValue),
        conditionOptions[PreviewAttributeType.UNKNOWN]
      ),
    }),
    {}
  );
};

const getAttributeSelectOptions = (
  currentClaims: Claims,
  initialMapping: MappingRule[]
): SelectOption<string>[] => {
  const locale = getCurrentLocale();
  const attributesFromClaims = Object.keys(currentClaims);
  const attributesFromMapping = initialMapping.map(
    ({ clause }) => clause.attribute
  );

  return uniq([...attributesFromClaims, ...attributesFromMapping])
    .map<SelectOption<string>>(claim => ({
      value: claim,
      label: claim,
    }))
    .sort((a, b) => localeCompare(a.label, b.label, locale));
};

export default derivedStream('SSOMapping', SSOAttributeMapping$).pipe(
  map(
    ([
      {
        initialMapping,
        currentMapping,
        showValidationErrors,
        mappingPreview,
        currentClaims,
        conditionOptions,
        lastUpdated,
        isLoggedInWithSSO,
        loginOptions,
        allowMultipleSsoOptionsInMappings,
        isLoading,
        ssoSelectedForPreview,
        groups,
      },
    ]) => {
      const changes = getChanges(currentMapping, initialMapping);
      const deletions = getDeletions(currentMapping, initialMapping);
      const disableSave =
        Object.values(changes).length === 0 && deletions.length === 0;

      const isValidChanges = areValidChanges(Object.values(changes));

      return {
        currentMapping,
        changes,
        isValidChanges,
        disableSave,
        showValidationErrors,
        attributeTypeOptions: getAttributeSelectOptions(
          currentClaims,
          initialMapping
        ),
        groupOptions: permissionGroupsToGroupOptions(groups),
        conditionOptionsByAttribute: getConditionOptionsByAttribute(
          currentClaims,
          conditionOptions
        ),
        lastUpdated,
        ssoOptions: loginConfigToSsoOptions(loginOptions),
        numberOfConfiguredSso: loginOptions.filter(option => option.enabled)
          .length,
        allowMultipleSsoOptionsInMappings,
        noGroupsConfigured: groups.length === 0,
        mappingPreview,
        mappedGroupsCount: Object.values(currentMapping).reduce(
          (acc, curr) => {
            const { groupId } = curr.action;
            const count = acc[groupId] ?? 0;
            return {
              ...acc,
              [groupId]: count + 1,
            };
          },
          {} as Record<string, number>
        ),
        deletions,
        isLoggedInWithSSO,
        enablePreviewButton:
          isLoggedInWithSSO &&
          Object.keys(currentMapping).length > 0 &&
          (!allowMultipleSsoOptionsInMappings ||
            Boolean(ssoSelectedForPreview)),
        isLoading,
        ssoSelectedForPreview,
        commands,
      };
    }
  )
);
