import styled from 'styled-components';
import {
  SurveyQuestionError,
  SurveyQuestionValidations,
} from 'surveyAdmin/types';
import { s4, s8 } from '@ardoq/design-tokens';
import { FormSize, FormWrapper, Label, NumberInput } from '@ardoq/forms';
import {
  PartiallyPersistedReferenceQuestion,
  PersistedReferenceQuestion,
  PersistedTagQuestion,
  SurveyQuestionValidator,
  UnpersistedReferenceQuestion,
  UnpersistedTagQuestion,
} from '@ardoq/api-types';
import {
  getQuestionValidationMessageIfExists,
  isRangeValidator,
} from './utils';
import { ErrorNotification, WarningNotification } from '@ardoq/status-ui';
import { Stack } from '@ardoq/layout';

const DEFAULT_RANGE_VALIDATOR: SurveyQuestionValidator = {
  type: 'range',
  min: 0,
  max: null,
};

const SubFormGroup = styled.div`
  display: flex;
  gap: ${s8};
  align-items: self-end;
  margin-bottom: ${s4};
`;

type QuestionTypesWithRangeValidation =
  | PersistedReferenceQuestion
  | UnpersistedReferenceQuestion
  | PartiallyPersistedReferenceQuestion
  | UnpersistedTagQuestion
  | PersistedTagQuestion;

type RangeValidationProperties = {
  question: QuestionTypesWithRangeValidation;
  labelText: string;
  questionValidations?: SurveyQuestionValidations;
  updateValidators: (validators: SurveyQuestionValidator[]) => void;
};

export const RangeValidation = ({
  question,
  labelText,
  questionValidations,
  updateValidators,
}: RangeValidationProperties) => {
  const rangeValidator = (question.validators &&
    question.validators.find(isRangeValidator)) || {
    ...DEFAULT_RANGE_VALIDATOR,
  };
  const updateRangeValidator = ({
    min = rangeValidator.min,
    max = rangeValidator.max,
  }) => {
    const validators = question.validators || [];
    updateValidators([
      ...validators.filter((v: SurveyQuestionValidator) => v.type !== 'range'),
      { type: 'range', min, max },
    ]);
  };

  const invalidRangeMessage = getQuestionValidationMessageIfExists(
    questionValidations,
    SurveyQuestionError.RANGE_RULES_INVALID
  );
  const redundantRangeMessage =
    rangeValidator.min === 0 && rangeValidator.max === 0
      ? 'Min and max have both been set to 0. Please set the question to read-only instead, or alter these values.'
      : null;
  return (
    <FormWrapper $formSize={FormSize.DEFAULT}>
      <Stack gap="small">
        <Label>{labelText}</Label>
        <SubFormGroup>
          <NumberInput
            label="Min"
            value={rangeValidator.min}
            onValueChange={number => {
              if (number < 0) return;
              updateRangeValidator({
                min: isNaN(number) ? 0 : number,
              });
            }}
            min={0}
            dataTestId="min-input"
          />
          <NumberInput
            label="Max"
            min={0}
            value={rangeValidator.max ?? undefined}
            onValueChange={number => {
              if (number < 0) return;
              updateRangeValidator({
                max: isNaN(number) ? null : number,
              });
            }}
            dataTestId="max-input"
          />
        </SubFormGroup>
        {invalidRangeMessage && (
          <ErrorNotification>{invalidRangeMessage}</ErrorNotification>
        )}
        {redundantRangeMessage && (
          <WarningNotification>{redundantRangeMessage}</WarningNotification>
        )}
      </Stack>
    </FormWrapper>
  );
};
