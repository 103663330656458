import { openViewpointBuilder } from './openViewpointBuilder/openViewpointBuilder';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  openViewpoint,
  openViewpointBuilderAndCloseLoadedViewpoints,
  openViewpointBuilderWithoutClosingLoadedViewpoints,
} from './actions';

export type OpenViewpointBuilderCommands = {
  openViewpointBuilderToCreateViewpoint: (folderId?: string | null) => void;
  openViewpointBuilderToExploreDataAndCloseLoadedStates: () => void;
  openViewpointBuilderWithoutClosingLoadedStates: () => void;
  openViewpointBuilderToSelectStartComponentsForViewpoint: (
    viewpointId: string
  ) => void;
};

/**
 * This commands set is meant to use in all the places where we want to open the viewpoint builder with different contexts.
 */
export const openViewpointBuilderCommands: OpenViewpointBuilderCommands = {
  openViewpointBuilderToCreateViewpoint: folderId => {
    openViewpointBuilder({
      context: 'createViewpoint',
      activeTab: 'SELECT_CONTEXT_COMPONENT_TYPE_TAB',
      folderId,
    });
  },

  openViewpointBuilderToSelectStartComponentsForViewpoint(viewpointId: string) {
    dispatchAction(openViewpoint({ viewpointId }));
  },

  openViewpointBuilderToExploreDataAndCloseLoadedStates: () => {
    dispatchAction(openViewpointBuilderAndCloseLoadedViewpoints());
  },

  openViewpointBuilderWithoutClosingLoadedStates: () => {
    dispatchAction(openViewpointBuilderWithoutClosingLoadedViewpoints());
  },
};
