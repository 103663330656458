import { reducer } from '@ardoq/rxbeach';
import { AudiencePreviewStatus, AudiencePreviewStreamShape } from './types';
import {
  fetchAudiencePreview,
  fetchAudiencePreviewError,
  notifyIncompleteContentSelection,
  setAudiencePreview,
} from './actions';
import { APIAudiencePreviewResponse } from '@ardoq/api-types';
import { toComponentMappedPreviewAudience } from 'broadcasts/utils';

const notifyIncompleteContentSelectionReducer = (
  state: AudiencePreviewStreamShape
): AudiencePreviewStreamShape => ({
  ...state,
  audiencePreview: [],
  status: AudiencePreviewStatus.INCOMPLETE_CONTENT_SELECTION,
});

const fetchAudiencePreviewReducer = (state: AudiencePreviewStreamShape) => ({
  ...state,
  status: AudiencePreviewStatus.LOADING,
});

const fetchAudiencePreviewErrorReducer = (
  state: AudiencePreviewStreamShape
): AudiencePreviewStreamShape => ({
  ...state,
  audiencePreview: [],
  status: AudiencePreviewStatus.ERROR,
});

const setAudiencePreviewReducer = (
  state: AudiencePreviewStreamShape,
  apiAudiencePreview: APIAudiencePreviewResponse
): AudiencePreviewStreamShape => ({
  ...state,
  audiencePreview: toComponentMappedPreviewAudience(apiAudiencePreview),
  status: AudiencePreviewStatus.SUCCESS,
});

export const reducers = [
  reducer(
    notifyIncompleteContentSelection,
    notifyIncompleteContentSelectionReducer
  ),
  reducer(fetchAudiencePreview, fetchAudiencePreviewReducer),
  reducer(fetchAudiencePreviewError, fetchAudiencePreviewErrorReducer),
  reducer(setAudiencePreview, setAudiencePreviewReducer),
];
