import {
  APIComponentAttributes,
  APISurveyAttributes,
  ArdoqId,
  ChangeRequestAction,
  SortOrder,
} from '@ardoq/api-types';
import { s16 } from '@ardoq/design-tokens';
import { confirm } from '@ardoq/modal';
import {
  PaginationController,
  smartSort,
  PaginationBar,
  SortByFn,
} from '@ardoq/pagination';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { Column, DatasourceTable, FlyWheelTable } from '@ardoq/table';
import changeApprovalData$ from '../changeApprovalData$';
import { combineLatest, map } from 'rxjs';
import {
  ComponentWithPendingChanges,
  RecordOfComponents,
  ReferenceChanges,
} from '../types';
import {
  setActiveComponentId,
  setApprovalFocus,
  setChangeRequestAction,
  setPerPage,
  setSortBy,
} from '../actions';
import { formatDateTime } from '@ardoq/date-time';
import { search, filterByChips } from './utils';
import EmptyState from 'components/AssetsBrowser2024/EmptyState';
import { emptyTableProps } from 'surveyAdmin/SurveyOverview/consts';
import surveys$ from 'streams/surveys/surveys$';
import surveyAdmin$ from 'surveyAdmin/streams/surveyAdmin$';
import { ChangesCell, StatusCell } from './TableCellRenderers';
import { getCurrentLocale } from '@ardoq/locale';
import { trackEvent } from 'tracking/tracking';
import { showDrawer } from '@ardoq/snowflakes';
import ApprovalDrawer from '../ApprovalDrawer';
import { changeApprovalCommands } from '../commands';
import { Stack } from '@ardoq/layout';
import styled from 'styled-components';
import { UserDecorator } from '@ardoq/decorators';

const UnderlineOnHover = styled.div`
  &:hover {
    text-decoration: underline;
  }
`;

const openApprovalDrawer = (survey: APISurveyAttributes) => {
  return showDrawer({
    stackPageName: 'changeApprovalDrawer',
    renderDrawer: props => (
      <ApprovalDrawer handleClose={props.handleCloserDrawer} survey={survey} />
    ),
  });
};

const openEmptyChangeRequestModal = (
  component: ComponentWithPendingChanges
) => {
  return confirm({
    title: 'The change is now invalid',
    text: (
      <Stack gap="small">
        <span>
          <b>{`${component?.name ?? 'Unknown'}`}</b> has been updated after this
          change was submitted. The change is now invalid and can´t be approved
          or rejected.
        </span>
      </Stack>
    ),
    isConfirmButtonDanger: false,
    confirmButtonTitle: 'Discard change',
    cancelButtonTitle: 'Back',
  });
};

const handleCloseEmptyChangeRequest = async (
  surveyId: string,
  component: ComponentWithPendingChanges
) => {
  const confirmation = await openEmptyChangeRequestModal(component);
  if (!confirmation) return;
  changeApprovalCommands.resetInvalidChangeRequest({
    surveyId,
    componentId: component._id,
  });
  trackEvent(
    'Change approval: cleared pending answers for empty change request',
    { componentId: component._id }
  );
};

type ComponentOverviewTableProps = {
  changedFieldsByComponentId: RecordOfComponents;
  componentsWithPendingChanges: ComponentWithPendingChanges[];
  sortById: string;
  sortOrder: SortOrder;
  perPage: number;
  searchPhrase: string;
  filterChips: string[];
  survey: APISurveyAttributes;
  changedReferencesByComponentId: Record<ArdoqId, ReferenceChanges>;
};

const ComponentOverviewTable = ({
  changedFieldsByComponentId,
  componentsWithPendingChanges,
  sortById,
  sortOrder,
  perPage,
  searchPhrase,
  filterChips,
  survey,
  changedReferencesByComponentId,
}: ComponentOverviewTableProps) => {
  const dataSource = search(componentsWithPendingChanges, searchPhrase).filter(
    componentWithPendingChanges =>
      filterByChips(componentWithPendingChanges, filterChips)
  );

  const handleSortBy = <T,>(sortById: string, sortBy: SortByFn<T>) => {
    trackEvent('Survey change approval: used sort', { sortById });
    dispatchAction(setSortBy(sortById));
    sortBy(sortById);
  };

  const handleStartApproval = (
    component: APIComponentAttributes,
    changeAction: ChangeRequestAction
  ) => {
    const includesComponentUpdates = Boolean(
      changedFieldsByComponentId[component._id]
    );
    const referencesChangeActions = Object.keys(
      changedReferencesByComponentId[component._id] ?? {}
    ) as ChangeRequestAction[];
    dispatchAction(
      setApprovalFocus(includesComponentUpdates ? 'component' : 'reference')
    );
    dispatchAction(setActiveComponentId(component._id));
    dispatchAction(
      setChangeRequestAction(
        includesComponentUpdates ? changeAction : referencesChangeActions[0]
      )
    );
    trackEvent('Survey change approval: opened approval modal');
    openApprovalDrawer(survey);
  };

  const handleCellClick = (component: ComponentWithPendingChanges) => {
    // sadly can't put this on the row, as the userCell has its own click event
    if (!component.changeAction) return;
    if (component.changeAction === 'no_change') {
      handleCloseEmptyChangeRequest(survey._id, component);
      return;
    }
    handleStartApproval(component, component.changeAction);
  };
  return (
    <PaginationController
      dataSource={smartSort(dataSource, sortById, sortOrder)}
      defaultSortById={sortById ?? undefined}
      defaultSortOrder={sortOrder}
      perPage={perPage}
      render={({
        currentPageDataSource,
        sortById,
        sortOrder,
        currentPageNumber,
        perPage,
        totalResults,
        sortBy,
        onPageSelect,
      }) => {
        const commonColumns: Column<ComponentWithPendingChanges>[] = [
          {
            title: 'Component',
            dataIndex: 'name',
            cellStyle: {
              maxWidth: '300px',
            },
            onHeaderClick: () => handleSortBy('name', sortBy),
            sortOrder: sortById === 'name' && sortOrder,
            onCellClick: handleCellClick,
            valueRender: (_any: any, component) => {
              return (
                component.changeAction && (
                  <UnderlineOnHover>{component.name}</UnderlineOnHover>
                )
              );
            },
          },
          {
            title: 'Status',
            onHeaderClick: () => handleSortBy('changeAction', sortBy),
            sortOrder: sortById === 'changeAction' && sortOrder,
            headerStyle: { width: 200 },
            dataIndex: 'changeAction',
            onCellClick: handleCellClick,
            valueRender: (_any: any, component) => {
              return <StatusCell component={component} />;
            },
          },
          {
            title: 'Last submitted by',
            onHeaderClick: () => handleSortBy('lastModifiedByName', sortBy),
            sortOrder: sortById === 'lastModifiedByName' && sortOrder,
            headerStyle: { width: 200 },
            dataIndex: 'lastModifiedByName',
            valueRender: (_any: any, component) => (
              <UserDecorator
                name={component.lastModifiedByName}
                email={component.lastModifiedByEmail}
              />
            ),
          },
          {
            title: 'Last updated',
            onHeaderClick: () => handleSortBy('lastUpdated', sortBy),
            sortOrder: sortById === 'lastUpdated' && sortOrder,
            headerStyle: { width: 200 },
            dataIndex: 'lastUpdated',
            onCellClick: handleCellClick,
            valueRender: value => formatDateTime(value, getCurrentLocale()),
          },
          {
            title: 'Changes to approve',
            onHeaderClick: () => handleSortBy('numberOfChanges', sortBy),
            sortOrder: sortById === 'numberOfChanges' && sortOrder,
            dataIndex: 'numberOfChanges',
            headerStyle: { width: 200 },
            onCellClick: handleCellClick,
            valueRender: (_any: any, component) => {
              return <ChangesCell component={component} />;
            },
          },
        ];

        const emptyTableRender =
          searchPhrase || filterChips.length
            ? () => <EmptyState {...emptyTableProps.noMatchFound} />
            : undefined;
        return (
          <>
            <DatasourceTable
              dataSource={currentPageDataSource}
              components={FlyWheelTable}
              renderEmptyTable={emptyTableRender}
              columns={commonColumns}
              fixedHeader
              rowStyle={() => ({ cursor: 'pointer' })}
            />
            <PaginationBar
              style={{
                justifyContent: 'center',
                paddingBottom: s16,
              }}
              currentPageNumber={currentPageNumber}
              perPage={perPage}
              totalResults={totalResults}
              onChange={({ currentPageNumber, perPage }) => {
                dispatchAction(setPerPage(perPage));
                onPageSelect(currentPageNumber);
              }}
              showPerPageSelector
            />
          </>
        );
      }}
    />
  );
};

const combinedData$ = combineLatest([
  changeApprovalData$,
  surveys$,
  surveyAdmin$,
]).pipe(
  map(
    ([
      {
        componentsWithPendingChanges,
        sortById,
        sortOrder,
        perPage,
        searchPhrase,
        filterChips,
        changedFieldsByComponentId,
        changedReferencesByComponentId,
      },
      { byId },
      { surveyId },
    ]) => {
      return {
        componentsWithPendingChanges,
        sortById,
        sortOrder,
        perPage,
        searchPhrase,
        filterChips,
        survey: byId[surveyId ?? ''],
        changedFieldsByComponentId,
        changedReferencesByComponentId,
      };
    }
  )
);

export default connect(ComponentOverviewTable, combinedData$);
