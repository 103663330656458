import { QueryResults } from '../streams/types';
import { omit } from 'lodash/fp';
import {
  addComponentTypeToQuery,
  addWorkspaceRuleToQuery,
  queryRespToResults,
} from './utils';
import { isArdoqError, Maybe } from '@ardoq/common-helpers';
import {
  advancedSearchApi,
  integrationsJobApi,
  getErrorStatusCode,
  jobApi,
} from '@ardoq/api';
import { ApiError } from 'integrations/common/utils/api';
import { Named, QueryBuilderQuery, ScheduleType } from '@ardoq/api-types';
import {
  ITPediaSchedulePayload,
  ITPediaSchedule,
} from '@ardoq/api-types/integrations';

export async function fetchEnrichmentList() {
  const jobs = await integrationsJobApi.fetchAll();
  if (isArdoqError(jobs)) {
    return jobs;
  }

  const enrichments = jobs.filter(
    (job): job is ITPediaSchedule =>
      job.jobOptions.type === ScheduleType.ITPEDIA_ENRICHMENT
  );

  return enrichments;
}

export async function upsertEnrichmentRequest(
  jobOptions: ITPediaSchedulePayload['jobOptions']
) {
  const { _id } = jobOptions;
  const body = omit(['_id', 'type'], jobOptions);
  const resp = await (_id
    ? jobApi.updateSchedule({
        jobOptions: { ...body, _id, type: ScheduleType.ITPEDIA_ENRICHMENT },
        _id,
        // Todo: Update the jobOptions to accept the schedule to include schedule policy too
        schedulingPolicy: {
          type: 'nightly',
        },
      })
    : jobApi.createSchedule({
        jobOptions: {
          ...body,
          type: ScheduleType.ITPEDIA_ENRICHMENT,
          _id: null,
        },
        // To make the job runs immediately
        runNow: true,
        schedulingPolicy: {
          type: 'nightly',
        },
      }));
  if (isArdoqError(resp)) {
    throw resp;
  }
  return resp;
}

export async function postAdvanceQuery(
  query: QueryBuilderQuery,
  workspace: Maybe<string>,
  componentTypeName: string
): Promise<
  { results: QueryResults; error: null } | { results: null; error: ApiError }
> {
  const body = addComponentTypeToQuery(
    componentTypeName,
    addWorkspaceRuleToQuery(workspace, query) as QueryBuilderQuery
  );
  const response = await advancedSearchApi.search<Named>(body, {
    size: 500,
  });
  if (isArdoqError(response)) {
    const error = isArdoqError(response) ? response.error : response;
    return {
      results: null,
      error: new ApiError(
        'Failed to fetch components',
        getErrorStatusCode(response),
        typeof error.cause === 'string' ? error.cause : '',
        { stack: error.stack || '' }
      ),
    };
  }

  return { results: queryRespToResults(response, 'Success'), error: null };
}
