import { PropsWithChildren } from 'react';
import { ModalSize, info } from '@ardoq/modal';
import { SecondaryButton } from '@ardoq/button';
import { CopyIcon } from '@ardoq/icons';
import { IconName } from '@ardoq/icons';
import { popoverRegistry } from '@ardoq/popovers';
import { DISCONTINUED_SETTING_POPOVER_TEXT } from 'viewDeprecation/consts';
import {
  CantMigrateSlidesPopover,
  CustomDialogSubtitleSection,
  DiscontinuedSettingContainer,
  DiscontinuedSettingPopoverDiv,
  SavedSettingDialogcontentBody,
  SavedViewModifiersRowsContainer,
  StyledIcon,
  ValueRowContainer,
  WHY_CANT_I_MIGRATE_SLIDES_POPOVER_ID,
} from 'viewDeprecation/atoms';
import { ViewModifier } from 'viewDeprecation/types';

popoverRegistry.set(WHY_CANT_I_MIGRATE_SLIDES_POPOVER_ID, () => (
  <CantMigrateSlidesPopover />
));

export const showSavedViewModifiersDialog = (viewModifiers: ViewModifier[]) => {
  info({
    title: 'Saved view modifiers',
    modalSize: ModalSize.S,
    body: SavedViewModifiersDialogContentBody(viewModifiers),
  });
};

const SavedViewModifiersDialogContentBody = (viewModifiers: ViewModifier[]) => (
  <>
    <CustomDialogSubtitleSection>
      Some view modifiers have also been discontinued, so your data may look
      different in the new view.
      <br />
      Here is a list of your original view modifiers that you can copy-paste for
      your own reference.
    </CustomDialogSubtitleSection>
    <SavedSettingDialogcontentBody>
      <SecondaryButton
        onClick={() => copyViewModifiersToClipboard(viewModifiers)}
      >
        Copy as text
        <CopyIcon />
      </SecondaryButton>
      <SavedViewModifiersRowsContainer>
        {viewModifiers.map(valueRowData =>
          SavedViewModifiersRow({ ...valueRowData })
        )}
      </SavedViewModifiersRowsContainer>
    </SavedSettingDialogcontentBody>
  </>
);

type SavedViewModifiersTableValueRowArgs = {
  valueLabel: string;
  isDiscontinued: boolean;
};

const SavedViewModifiersRow = ({
  valueLabel,
  isDiscontinued,
}: SavedViewModifiersTableValueRowArgs) =>
  isDiscontinued ? (
    <DiscontinuedSetting key={valueLabel}>{valueLabel}</DiscontinuedSetting>
  ) : (
    <ValueRowContainer key={valueLabel}>{valueLabel}</ValueRowContainer>
  );

const POPOVER_ID = 'this-view-setting-is-disontinued';

popoverRegistry.set(POPOVER_ID, () => (
  <DiscontinuedSettingPopoverDiv>
    {DISCONTINUED_SETTING_POPOVER_TEXT}
  </DiscontinuedSettingPopoverDiv>
));

const DiscontinuedSetting = (props: PropsWithChildren<unknown>) => (
  <DiscontinuedSettingContainer>
    {props.children}
    <StyledIcon
      iconName={IconName.WARNING}
      data-ardoq-popover-id={POPOVER_ID}
    />
  </DiscontinuedSettingContainer>
);

const copyViewModifiersToClipboard = (viewModifiers: ViewModifier[]) => {
  navigator.clipboard.writeText(
    viewModifiers
      .map(
        (valueRow, index) =>
          `${index > 0 ? ';' : ''} ${valueRow.valueLabel}${
            valueRow.isDiscontinued ? ' (discontinued)' : ''
          }${index === viewModifiers.length - 1 ? '; ' : ''}`
      )
      .join('')
  );
};
