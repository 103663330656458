import { InstanceComponent } from '@ardoq/api-types';
import { uniqBy } from 'lodash';
import { AudienceStatus, ComponentStatus } from './types';
import { IconName } from '@ardoq/icons';

export const getUniqueComponents = (components?: InstanceComponent[]) => {
  return uniqBy(components, '_id');
};

export const getUniqueComponentCount = (components?: InstanceComponent[]) => {
  return getUniqueComponents(components).length;
};

export const getIconName = (status: ComponentStatus | AudienceStatus) => {
  switch (status) {
    case ComponentStatus.COMPLETE:
      return IconName.CHECK_MARK;
    case AudienceStatus.MISSING_EMAIL:
      return IconName.WARNING;
    default:
      return null;
  }
};
