import { ComponentDoc } from 'modelInterface/prototype/prototypeInterface';
import {
  BooleanOperator,
  Operator,
  QueryBuilderQuery,
  SearchContext,
  SearchResultDoc,
} from '@ardoq/api-types';
import { advancedSearchApi, api } from '@ardoq/api';

type QueryResult = { label: string; value: string }[];

type SearchResultDocumentMapper = ({
  doc,
}: {
  doc: SearchResultDoc & ComponentDoc;
}) => {
  label: string;
  value: string;
};

const getComponentSuggestionLoader =
  (
    workspaceIds: string[],
    componentTypeNames: string[],
    mapper: SearchResultDocumentMapper
  ) =>
  async (query: string): Promise<QueryResult> => {
    const requestBody: QueryBuilderQuery = {
      condition: BooleanOperator.AND,
      rules: [
        {
          id: 'type',
          field: 'type',
          type: 'string',
          input: 'select',
          operator: Operator.EQUAL,
          value: SearchContext.COMPONENT,
        },
        {
          condition: BooleanOperator.AND,
          rules: [
            {
              id: 'name',
              field: 'name',
              input: 'text',
              type: 'string',
              operator: Operator.CONTAINS_SUBSTRING,
              value: query,
            },
            ...getTraversalScope(workspaceIds, componentTypeNames),
          ],
        },
      ],
    };

    const { results } = api.logErrorIfNeeded(
      await advancedSearchApi.search<SearchResultDoc & ComponentDoc>(
        requestBody,
        { size: 500 }
      )
    ) || { results: [] };

    return results.map(mapper);
  };

const getComponentParentSuggestionLoader =
  (workspaceIds: string[], componentTypeNames: string[]) =>
  async (query: string): Promise<QueryResult> => {
    const requestBody: QueryBuilderQuery = {
      condition: BooleanOperator.AND,
      rules: [
        {
          id: 'type',
          field: 'type',
          type: 'string',
          input: 'select',
          operator: Operator.EQUAL,
          value: SearchContext.COMPONENT,
        },
        {
          condition: BooleanOperator.AND,
          rules: [
            {
              field: 'child-count',
              id: 'child-count',
              input: 'number',
              operator: Operator.GREATER,
              type: 'number',
              value: 0,
            },
            {
              id: 'name',
              field: 'name',
              input: 'text',
              type: 'string',
              operator: Operator.CONTAINS_SUBSTRING,
              value: query,
            },
            ...getTraversalScope(workspaceIds, componentTypeNames),
          ],
        },
      ],
    };

    const { results } = api.logErrorIfNeeded(
      await advancedSearchApi.search<SearchResultDoc & ComponentDoc>(
        requestBody,
        { size: 500 }
      )
    ) || { results: [] };

    return results.map(({ doc: { name, _id } }) => ({
      label: name,
      value: _id,
    }));
  };

const getTraversalScope = (
  workspaceIds: string[],
  componentTypeNames: string[]
) => [
  {
    condition: BooleanOperator.OR,
    rules: workspaceIds.map(rootWorkspace => ({
      id: 'rootWorkspace',
      field: 'rootWorkspace',
      input: 'text',
      type: 'string',
      operator: Operator.EQUAL,
      value: rootWorkspace,
    })),
  },
  {
    condition: BooleanOperator.OR,
    rules: componentTypeNames.map(typeName => ({
      id: 'typeName',
      field: 'typeName',
      input: 'text',
      type: 'string',
      operator: Operator.EQUAL,
      value: typeName,
    })),
  },
];

export const getViewpointComponentSuggestionLoaders = (
  workspaceIds: string[],
  componentTypeNames: string[]
) => ({
  loadComponentSuggestionsAsync: getComponentSuggestionLoader(
    workspaceIds,
    componentTypeNames,
    ({ doc: { name } }) => ({
      label: name,
      value: name,
    })
  ),
  loadParentComponentSuggestionsAsync: getComponentParentSuggestionLoader(
    workspaceIds,
    componentTypeNames
  ),
  loadComponentSuggestionsIncludingCurrentComponentAsync:
    getComponentSuggestionLoader(
      workspaceIds,
      componentTypeNames,
      ({ doc: { name, _id } }) => ({
        label: name,
        value: _id,
        description: _id,
      })
    ),
});
