import { switchMap } from 'rxjs/operators';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { combineLatest } from 'rxjs';
import {
  getSelectDataStepState,
  isWaitingOverlayDisplayed,
  getWaitingOverlayMessage,
  getExportFinalStepState,
  isStepAvailable,
} from './utils';
import { WaitingOverlay } from 'integrations/common/components/overlays/WaitingOverlay';
import Flex from 'atomicComponents/Flex';
import { FlowHeader } from 'integrations/common/components/flowHeader/FlowHeader';
import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { TransferConfigsState } from 'integrations/common/streams/transferConfigs/types';
import { TabularMapping } from 'integrations/common/streams/tabularMappings/types';
import { TransferState } from 'integrations/common/streams/transferState/types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { startTransferConfigsModal } from 'integrations/common/modals/transferConfigsModal/TransferConfigsModal';
import { Step, StepProps } from '@ardoq/steppers';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { usePopover } from '@ardoq/popovers';
import {
  getFlowTitle,
  getReviewStepPopoverText,
  getReviewStepState,
  getScheduleStepState,
  getScheduleStepTitle,
} from 'integrations/common/utils/step';
import { SelectData } from './pages/selectData/SelectData';
import TabularConfigMapping from 'integrations/common/pages/tabularConfigMapping/TabularConfigMapping';
import { selectionState$ } from './streams/selectionState/selectionState$';
import { SelectionState } from './streams/selectionState/types';
import { getConfigureStepState } from 'integrations/common/utils/step';
import {
  ScheduleState,
  getScheduleStream,
} from 'integrations/common/streams/schedule/schedule$';
import {
  ServiceNowTablesState,
  serviceNowTables$,
} from './streams/tables/tables$';
import { tablesFields$ } from './streams/tableFields/tableFields$';
import { TablesFields } from './streams/tableFields/types';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { TablePreviews } from 'integrations/common/streams/tablePreviews/types';
import { saveActiveConfiguration } from 'integrations/common/streams/transferConfigs/actions';
import { startSaveModal } from 'integrations/common/modals/saveModal/SaveModal';
import {
  getSchedulesStream,
  SchedulesState,
} from 'integrations/common/streams/schedules/getSchedulesStream';
import { ReviewTest } from 'integrations/common/pages/reviewTest/ReviewTest';
import { Maybe } from '@ardoq/common-helpers';
import { isValidSelectionState } from 'integrations/service-now/streams/selectionState/utils';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';
import { ServiceNowConnection } from '@ardoq/api-types/integrations';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { ImportAndSchedule } from 'integrations/common/pages/importAndSchedule/ImportAndSchedule';
import { Overview } from 'integrations/common/containers/overview/Overview';
import {
  ExportRoute,
  ImportRoute,
  OverviewRoute,
  RoutePath,
} from 'integrations/common/navigation/types';
import {
  isExportRoute,
  isImportRoute,
  isOverviewRoute,
  isPathReady,
} from 'integrations/common/navigation/utils';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { Stepper } from 'integrations/common/components/stepper';
import { StickyFooter } from 'integrations/common/containers/stickyFooter/StickyFooter';
import { PageBody } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';
import { FlowContainer } from 'integrations/common/components/flowContainer/FlowContainer';
import { loadSelection } from 'integrations/common/streams/selectionState/actions';
import { serviceNowDictionary } from 'integrations/common/dictionary';

// main component

type ServiceNowProps = {
  activeIntegration: ActiveIntegrationState;
  transferConfigs: TransferConfigsState;
  tabularMapping: TabularMapping;
  transferState: TransferState;
  selectionState: SelectionState;
  schedule: ScheduleState;
  schedules: SchedulesState;
  tables: ServiceNowTablesState;
  tablesFields: TablesFields;
  tablePreviews: TablePreviews;
  connectionsState: IntegrationConnectionsState<ServiceNowConnection>;
};

function ServiceNow(props: ServiceNowProps) {
  const integrationPath = props.activeIntegration.integrationPath as RoutePath;
  if (!integrationPath || isOverviewRoute(integrationPath)) {
    return (
      <>
        <Overview
          integrationPath={integrationPath}
          initialTab={OverviewRoute.CONNECTIONS}
        />
      </>
    );
  }
  if (isImportRoute(integrationPath)) {
    return (
      <>
        <Import {...props} />
      </>
    );
  }
  if (isExportRoute(integrationPath)) {
    return (
      <>
        <Export {...props} />
      </>
    );
  }

  return null;
}

// model and connection

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest({
      activeIntegration: getActiveIntegrationStream(integrationId),
      transferConfigs: transferConfigs$,
      tabularMapping: getTabularMappingStream(integrationId),
      transferState: getTransferStateStream(integrationId),
      selectionState: selectionState$,
      schedule: getScheduleStream(integrationId),
      schedules: getSchedulesStream(integrationId),
      tables: serviceNowTables$,
      tablesFields: tablesFields$,
      tablePreviews: getTablePreviewsStream(integrationId),
      connectionsState:
        getConnectionsStream<ServiceNowConnection>(integrationId),
    })
  )
);

export default connect(ServiceNow, viewModel$);

// import and export flows

// below Import and Export are very similar, we will see later if factoring them is worth the effort.

const handleStepClickNavigation = (path: RoutePath) => {
  dispatchAction(
    navigateToPath({
      integrationId: 'servicenow-v3',
      path,
      source: 'stepper',
    })
  );
};

const handleRestart = () => {
  dispatchAction(resetIntegration('servicenow-v3'));
};

const handleStartTransferConfigsModal = () => {
  dispatchAction(
    trackIntegrationEvent({
      integrationId: 'servicenow-v3',
      name: 'CLICKED_MANAGE_CONFIGURATION',
    })
  );
  startTransferConfigsModal();
};

const handleSaveSelectedConfig = ({
  selectedTransferConfigId,
}: {
  selectedTransferConfigId: Maybe<string>;
}) => {
  if (!selectedTransferConfigId) {
    startSaveModal({
      integrationId: 'servicenow-v3',
      title: 'Save as a new version',
      isNewConfig: true,
    });

    return;
  }
  dispatchAction(saveActiveConfiguration({ integrationId: 'servicenow-v3' }));
};

const onDataSelectionNext = () => {
  dispatchAction(loadSelection('servicenow-v3'));
};

const handleSaveAs = () => {
  startSaveModal({
    integrationId: 'servicenow-v3',
    title: 'Save as a new version',
    isNewConfig: true,
  });
};

const Import = ({
  activeIntegration: { integrationPath, selectedTransferConfigId },
  transferConfigs,
  tabularMapping,
  transferState,
  selectionState,
  schedule,
  schedules: { schedules },
  tables,
  tablePreviews,
  connectionsState,
}: ServiceNowProps) => {
  const isDisabledStep = (path: ImportRoute | ExportRoute) =>
    !isPathReady(path, {
      tabularMapping,
      tablePreviews,
      transferState,
      integrationId: 'servicenow-v3',
    }) ||
    !isStepAvailable(path, {
      selectionState,
      tabularMapping,
      transferState,
    });

  return (
    <PageBody
      padding={0}
      backgroundColor="bgDefault"
      headerContent={
        <FlowHeader
          title={getFlowTitle({
            selectedTransferConfigId,
            transferConfigs: transferConfigs.configs,
            prefix: `${serviceNowDictionary.name} importer`,
            loadedScheduleId: schedule.loadedScheduleId,
            schedules,
          })}
          onSave={() => handleSaveSelectedConfig({ selectedTransferConfigId })}
          onSaveAs={handleSaveAs}
          canRestart={true} // allowing users go back to the overview page
          isSavingDisabled={
            !isValidSelectionState(
              selectionState,
              connectionsState.selectedConnectionIds
            )
          }
          onRestart={handleRestart}
          onStartTransferConfigsModal={handleStartTransferConfigsModal}
          transferDirection={transferState.transferDirection}
          disabledConfigurationActions={Boolean(schedule.loadedScheduleId)}
        />
      }
      footerContent={
        <StickyFooter
          integrationName={serviceNowDictionary.name}
          isDataSelectionValid={isValidSelectionState(
            selectionState,
            connectionsState.selectedConnectionIds
          )}
          onDataSelectionNext={onDataSelectionNext}
        />
      }
    >
      <FlowContainer>
        {isWaitingOverlayDisplayed({
          transferState,
          tables,
          selectionState,
          connectionsState,
        }) && (
          <WaitingOverlay>
            {getWaitingOverlayMessage({
              transferState,
              tables,
              selectionState,
              connectionsState,
            })}
          </WaitingOverlay>
        )}
        <Flex $direction="column">
          <Stepper>
            <Step
              onPress={() => handleStepClickNavigation(ImportRoute.SELECT_DATA)}
              isSelected={integrationPath === ImportRoute.SELECT_DATA}
              heading="Select data"
              state={getSelectDataStepState({
                integrationPath,
                selectionState,
                connectionIds: connectionsState.selectedConnectionIds,
              })}
            />
            <Step
              onPress={() => handleStepClickNavigation(ImportRoute.CONFIGURE)}
              isSelected={integrationPath === ImportRoute.CONFIGURE}
              isDisabled={isDisabledStep(ImportRoute.CONFIGURE)}
              heading="Configure data"
              state={getConfigureStepState({
                integrationPath,
                tabularMapping,
                configureRoute: ImportRoute.CONFIGURE,
              })}
            />
            <ReviewStep
              onPress={() => handleStepClickNavigation(ImportRoute.REVIEW)}
              isDisabled={isDisabledStep(ImportRoute.REVIEW)}
              isSelected={integrationPath === ImportRoute.REVIEW}
              heading="Review import"
              state={getReviewStepState({ transferState })}
            />
            <Step
              onPress={() =>
                handleStepClickNavigation(ImportRoute.IMPORT_AND_SCHEDULE)
              }
              isSelected={integrationPath === ImportRoute.IMPORT_AND_SCHEDULE}
              isDisabled={isDisabledStep(ImportRoute.IMPORT_AND_SCHEDULE)}
              heading={getScheduleStepTitle(schedule.scheduleStage)}
              state={getScheduleStepState({ transferState, schedule })}
            />
          </Stepper>
        </Flex>

        {integrationPath === ImportRoute.SELECT_DATA && <SelectData />}
        {integrationPath === ImportRoute.CONFIGURE && <TabularConfigMapping />}
        {integrationPath === ImportRoute.REVIEW && <ReviewTest />}
        {integrationPath === ImportRoute.IMPORT_AND_SCHEDULE && (
          <ImportAndSchedule />
        )}
      </FlowContainer>
    </PageBody>
  );
};

const ReviewStep = ({
  onPress,
  isDisabled,
  isSelected,
  heading,
  state,
}: StepProps) => {
  const popoverText = getReviewStepPopoverText(state);
  const popoverProps = usePopover(() => popoverText);

  return (
    <FlexBox flex={1} {...(isDisabled && popoverText ? popoverProps : {})}>
      <Step
        onPress={onPress}
        isDisabled={isDisabled}
        isSelected={isSelected}
        heading={heading}
        state={state}
        stepNumber={3}
      />
    </FlexBox>
  );
};

const Export = ({
  activeIntegration: { integrationPath, selectedTransferConfigId },
  transferConfigs,
  tabularMapping,
  transferState,
  selectionState,
  schedule,
  schedules: { schedules },
  tables,
  tablePreviews,
  connectionsState,
}: ServiceNowProps) => {
  const isDisabledStep = (path: RoutePath) =>
    !isPathReady(path, {
      tabularMapping,
      tablePreviews,
      transferState,
      integrationId: 'servicenow-v3',
    });

  return (
    <PageBody
      padding={0}
      backgroundColor="bgDefault"
      headerContent={
        <FlowHeader
          title={getFlowTitle({
            selectedTransferConfigId,
            transferConfigs: transferConfigs.configs,
            prefix: `${serviceNowDictionary.name} exporter`,
            loadedScheduleId: schedule.loadedScheduleId,
            schedules,
          })}
          canRestart={true} // allowing users go back to the overview page
          isSavingDisabled={
            !isValidSelectionState(
              selectionState,
              connectionsState.selectedConnectionIds
            )
          }
          onSave={() => handleSaveSelectedConfig({ selectedTransferConfigId })}
          onSaveAs={handleSaveAs}
          onRestart={handleRestart}
          onStartTransferConfigsModal={handleStartTransferConfigsModal}
          disabledConfigurationActions={Boolean(schedule.loadedScheduleId)} // TODO does it make sense
          transferDirection={transferState.transferDirection}
        />
      }
      footerContent={
        <StickyFooter
          integrationName={serviceNowDictionary.name}
          isDataSelectionValid={isValidSelectionState(
            selectionState,
            connectionsState.selectedConnectionIds
          )}
          onDataSelectionNext={onDataSelectionNext}
        />
      }
    >
      <FlowContainer>
        {isWaitingOverlayDisplayed({
          transferState,
          tables,
          selectionState,
          connectionsState,
        }) && (
          <WaitingOverlay>
            {getWaitingOverlayMessage({
              transferState,
              tables,
              selectionState,
              connectionsState,
            })}
          </WaitingOverlay>
        )}
        <Flex $direction="column">
          <Stepper>
            <Step
              onPress={() => handleStepClickNavigation(ExportRoute.SELECT_DATA)}
              isSelected={integrationPath === ExportRoute.SELECT_DATA}
              heading="Select data"
              state={getSelectDataStepState({
                integrationPath,
                selectionState,
                connectionIds: connectionsState.selectedConnectionIds,
              })}
            />
            <Step
              onPress={() => handleStepClickNavigation(ExportRoute.CONFIGURE)}
              isSelected={integrationPath === ExportRoute.CONFIGURE}
              isDisabled={isDisabledStep(ExportRoute.CONFIGURE)}
              heading="Configure data"
              state={getConfigureStepState({
                integrationPath,
                tabularMapping,
                configureRoute: ExportRoute.CONFIGURE,
              })}
            />
            <ReviewStep
              onPress={() => handleStepClickNavigation(ExportRoute.REVIEW)}
              isDisabled={isDisabledStep(ExportRoute.REVIEW)}
              isSelected={integrationPath === ExportRoute.REVIEW}
              heading="Review export"
              state={getReviewStepState({ transferState })}
            />
            <Step
              onPress={() => handleStepClickNavigation(ExportRoute.EXPORT)}
              isSelected={integrationPath === ExportRoute.EXPORT}
              isDisabled={isDisabledStep(ExportRoute.EXPORT)}
              heading="Export and schedule"
              state={getExportFinalStepState({ transferState })}
            />
          </Stepper>
        </Flex>

        {integrationPath === ExportRoute.SELECT_DATA && <SelectData />}
        {integrationPath === ExportRoute.CONFIGURE && <TabularConfigMapping />}
        {integrationPath === ExportRoute.REVIEW && <ReviewTest />}
        {integrationPath === ExportRoute.EXPORT && <ImportAndSchedule />}
      </FlowContainer>
    </PageBody>
  );
};
