import {
  SidebarMenuSection,
  SidebarMenuSubsectionHeader,
} from '@ardoq/sidebar-menu';
import {
  DashboardTrackingEventsNames,
  EditModeDashboardAttributes,
} from '@ardoq/dashboard';
import { ColorPickerSwatchInput } from '@ardoq/color-picker';
import { Field, TextInput } from '@ardoq/forms';
import { Space } from '@ardoq/style-helpers';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  removeManuallyAssignedColorForDataPoint,
  setColorForDataPoint,
} from '../../actions';
import {
  HexColor,
  APIDashboardColorTheme,
  UnpersistedEntity,
} from '@ardoq/api-types';
import { InfoNotification } from '@ardoq/status-ui';
import { CloseIcon } from '@ardoq/icons';
import { GhostButton } from '@ardoq/button';
import { trackEvent } from 'tracking/tracking';
import dashboardColorThemes$, {
  DEFAULT_DASHBOARD_COLOR_THEME,
} from 'dashboard/DashboardColorThemeSettings/dashboardColorThemes$';

const COLOR_USED_IN_MULTIPLE_WIDGETS_WARNING =
  'Changing the color of chart data affects all charts using the same data label.';

type FieldsNamesLabels = Record<string, string>;

type ColorSectionProps = {
  colors: EditModeDashboardAttributes['settings']['colors'];
  dashboardColorThemes: APIDashboardColorTheme[];
  fieldsNamesLabels: FieldsNamesLabels;
};

type ColorSubSectionProps = {
  colors: EditModeDashboardAttributes['settings']['colors'];
  isClearable?: boolean;
  selectedColorTheme: UnpersistedEntity<APIDashboardColorTheme>;
  fieldsNamesLabels: FieldsNamesLabels;
};

const ColorSubSection = ({
  colors,
  isClearable,
  selectedColorTheme,
  fieldsNamesLabels,
}: ColorSubSectionProps) => (
  <>
    {colors.map(({ dataPoint, color }) => (
      <Field key={dataPoint}>
        <Space $gap="s8" $align="center">
          <ColorPickerSwatchInput
            dataTestId="dashboard-data-color-button"
            palettes={[selectedColorTheme]}
            value={color}
            trackingFunctions={{
              trackSelectedColorFromColorPicker: () =>
                trackEvent(
                  DashboardTrackingEventsNames.SELECTED_COLOR_WITH_COLORPICKER_IN_DASHBOARD_BUILDER
                ),
              trackSelectedColorFromHexInput: () =>
                trackEvent(
                  DashboardTrackingEventsNames.SELECTED_COLOR_WITH_HEX_IN_DASHBOARD_BUILDER
                ),
              trackSelectedColorFromPalette: () =>
                trackEvent(
                  DashboardTrackingEventsNames.SELECTED_COLOR_FROM_PALETTE_IN_DASHBOARD_BUILDER
                ),
            }}
            onValueChange={newColor => {
              dispatchAction(
                setColorForDataPoint({ dataPoint, color: newColor as HexColor })
              );
            }}
          />
          <TextInput
            value={fieldsNamesLabels[dataPoint] ?? dataPoint}
            isReadOnly
          />
          {isClearable && (
            <GhostButton
              onClick={() => {
                dispatchAction(
                  removeManuallyAssignedColorForDataPoint(dataPoint)
                );
              }}
            >
              <CloseIcon />
            </GhostButton>
          )}
        </Space>
      </Field>
    ))}
  </>
);

const ColorSection = ({
  colors,
  dashboardColorThemes,
  fieldsNamesLabels,
}: ColorSectionProps) => {
  const hasColorThatIsUsedInMultipleWidgets = colors.some(
    ({ widgetsUsingThis }) => widgetsUsingThis.length > 1
  );

  const autoAssignedColors = colors.filter(
    ({ isManuallyAssigned }) => !isManuallyAssigned
  );
  const manuallyAssignedColors = colors.filter(
    ({ isManuallyAssigned }) => isManuallyAssigned
  );
  const selectedColorTheme =
    dashboardColorThemes.find(({ isSelected }) => isSelected) ??
    DEFAULT_DASHBOARD_COLOR_THEME;

  return (
    <SidebarMenuSection title="Data colors" isCollapsed>
      {hasColorThatIsUsedInMultipleWidgets && (
        <Field>
          <InfoNotification>
            {COLOR_USED_IN_MULTIPLE_WIDGETS_WARNING}
          </InfoNotification>
        </Field>
      )}
      {Object.keys(autoAssignedColors).length ? (
        <>
          <SidebarMenuSubsectionHeader title="Auto assigned" />
          <ColorSubSection
            colors={autoAssignedColors}
            selectedColorTheme={selectedColorTheme}
            fieldsNamesLabels={fieldsNamesLabels}
          />
        </>
      ) : null}
      {Object.keys(manuallyAssignedColors).length ? (
        <>
          <SidebarMenuSubsectionHeader title="Manually assigned" />
          <ColorSubSection
            colors={manuallyAssignedColors}
            isClearable
            selectedColorTheme={selectedColorTheme}
            fieldsNamesLabels={fieldsNamesLabels}
          />
        </>
      ) : null}
    </SidebarMenuSection>
  );
};

export default connect(ColorSection, dashboardColorThemes$);
