import { reducer } from '@ardoq/rxbeach';
import type {
  WorkspaceAudienceDialogStreamShape,
  WorkspaceOption,
} from './types';
import { getWarningMessageFromPeople } from 'broadcasts/utils';
import {
  choosePeopleWorkspace,
  fetchPeopleInSelectedWorkspace,
  fetchPeopleWorkspaceOptions,
  notifyFailedToFetchPeopleInSelectedWorkspace,
  notifyFailedToFetchPeopleWorkspaceOptions,
  openExistingWorkspaceAudienceDialog,
  openNewWorkspaceAudienceDialog,
  setLimitedToPersonIds,
  setPeopleInSelectedWorkspace,
  setPeopleWorkspaceOptions,
} from './actions';
import type {
  ArdoqId,
  BroadcastPerson,
  WorkspaceAudienceData,
} from '@ardoq/api-types';

const handleFetchPeopleWorkspaceOptions = (
  state: WorkspaceAudienceDialogStreamShape
) => ({
  ...state,
  isFetchingPeopleWorkspaceOptions: true,
  failedToFetchPeopleWorkspaceOptions: false,
});

const handleNotifyFailedToFetchPeopleWorkspaceOptions = (
  state: WorkspaceAudienceDialogStreamShape
): WorkspaceAudienceDialogStreamShape => ({
  ...state,
  peopleWorkspaceOptions: [],
  isFetchingPeopleWorkspaceOptions: false,
  failedToFetchPeopleWorkspaceOptions: true,
});
const handleSetPeopleWorkspaceOptions = (
  state: WorkspaceAudienceDialogStreamShape,
  peopleWorkspaceOptions: WorkspaceOption[]
) => ({
  ...state,
  peopleWorkspaceOptions,
  isFetchingPeopleWorkspaceOptions: false,
  failedToFetchPeopleWorkspaceOptions: false,
});

const handleChoosePeopleWorkspace = (
  state: WorkspaceAudienceDialogStreamShape,
  workspaceId: ArdoqId
) => {
  if (workspaceId === state.workspaceId) return state;
  return {
    ...state,
    workspaceId,
    limitedToPersonIds: [],
    formIsValid: false,
  };
};

const handleOpenExistingWorkspaceAudienceDialog = (
  state: WorkspaceAudienceDialogStreamShape,
  { workspaceId, limitedToPersonIds }: WorkspaceAudienceData
): WorkspaceAudienceDialogStreamShape => ({
  ...state,
  workspaceId,
  limitedToPersonIds,
  isUpdatingExistingAudience: true,
  formIsValid: limitedToPersonIds.length > 0,
});

const handleOpenNewWorkspaceAudienceDialog = (
  state: WorkspaceAudienceDialogStreamShape
): WorkspaceAudienceDialogStreamShape => ({
  ...state,
  workspaceId: null,
  peopleInSelectedWorkspace: [],
  limitedToPersonIds: [],
  isUpdatingExistingAudience: false,
  formIsValid: false,
  failedToFetchPeopleInSelectedWorkspace: false,
  warningMessage: null,
});
const handleSetLimitedToPersonIds = (
  state: WorkspaceAudienceDialogStreamShape,
  limitedToPersonIds: ArdoqId[]
) => ({
  ...state,
  limitedToPersonIds,
  formIsValid: limitedToPersonIds.length > 0,
  warningMessage: getWarningMessageFromPeople(
    state.peopleInSelectedWorkspace,
    limitedToPersonIds
  ),
});

const handleFetchPeopleInSelectedWorkspace = (
  state: WorkspaceAudienceDialogStreamShape
) => ({
  ...state,
  isFetchingPeopleInSelectedWorkspace: true,
  failedToFetchPeopleInSelectedWorkspace: false,
});

const handleSetPeopleInSelectedWorkspace = (
  state: WorkspaceAudienceDialogStreamShape,
  peopleInSelectedWorkspace: BroadcastPerson[]
) => ({
  ...state,
  peopleInSelectedWorkspace,
  isFetchingPeopleInSelectedWorkspace: false,
  failedToFetchPeopleInSelectedWorkspace: false,
  warningMessage: getWarningMessageFromPeople(
    peopleInSelectedWorkspace,
    state.limitedToPersonIds
  ),
});

const handleNotifyFailedToFetchPeopleInSelectedWorkspace = (
  state: WorkspaceAudienceDialogStreamShape
): WorkspaceAudienceDialogStreamShape => ({
  ...state,
  peopleInSelectedWorkspace: [],
  isFetchingPeopleInSelectedWorkspace: false,
  failedToFetchPeopleInSelectedWorkspace: true,
  warningMessage: null,
});
export const reducers = [
  reducer(fetchPeopleWorkspaceOptions, handleFetchPeopleWorkspaceOptions),
  reducer(
    notifyFailedToFetchPeopleWorkspaceOptions,
    handleNotifyFailedToFetchPeopleWorkspaceOptions
  ),
  reducer(setPeopleWorkspaceOptions, handleSetPeopleWorkspaceOptions),
  reducer(choosePeopleWorkspace, handleChoosePeopleWorkspace),
  reducer(
    openExistingWorkspaceAudienceDialog,
    handleOpenExistingWorkspaceAudienceDialog
  ),
  reducer(openNewWorkspaceAudienceDialog, handleOpenNewWorkspaceAudienceDialog),
  reducer(setLimitedToPersonIds, handleSetLimitedToPersonIds),
  reducer(fetchPeopleInSelectedWorkspace, handleFetchPeopleInSelectedWorkspace),
  reducer(
    notifyFailedToFetchPeopleInSelectedWorkspace,
    handleNotifyFailedToFetchPeopleInSelectedWorkspace
  ),
  reducer(setPeopleInSelectedWorkspace, handleSetPeopleInSelectedWorkspace),
];
