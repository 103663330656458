import { colors } from '@ardoq/design-tokens';
import styled from 'styled-components';

const Error = styled.span`
  color: ${colors.red60};
  padding-right: 5px;
`;
type RestoreErrorProperties = { isVisible: boolean };
const RestoreError = ({ isVisible }: RestoreErrorProperties) => {
  if (!isVisible) return null;
  return (
    <Error>
      <i className="material-icons-round">warning</i>
    </Error>
  );
};

export default RestoreError;
