import { Column } from '@ardoq/table';
import { PersistedInvitation } from '@ardoq/api-types';

type GetRenderedDatasourceColumnByIdProps = {
  valueRender: (invitationId: string) => React.ReactNode;
} & Omit<Column<PersistedInvitation>, 'dataIndex' | 'valueRender'>;

export const getRenderedDatasourceColumnById = (
  props: GetRenderedDatasourceColumnByIdProps
): Column<PersistedInvitation> => ({ dataIndex: '_id', ...props });
