import { action$, extractPayload, ofType } from '@ardoq/rxbeach';
import { map, switchMap, tap } from 'rxjs';
import { api, useCaseApi } from '@ardoq/api';
import { tag } from 'rxjs-spy/operators';
import { getUseCase } from './actions';
import { UseCaseStatus } from '@ardoq/api-types';

export const useCase$ = action$.pipe(
  ofType(getUseCase),
  extractPayload(),
  switchMap(({ id, status }) =>
    status === UseCaseStatus.PENDING
      ? useCaseApi.fetchUseCasePreviewContent(id)
      : useCaseApi.fetchUseCaseContent(id)
  ),
  tap(response => api.logErrorIfNeeded(response)),
  map(response => api.defaultValueIfError(response, null)),
  tag('useCase')
);
