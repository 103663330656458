import { SidebarMenu } from '@ardoq/sidebar-menu';
import { GhostButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import {
  AddFilterCommands,
  ChangeConditionCommands,
  EditFilterCommands,
} from './viewpointBuilderFiltersCommands';
import { TraversalBuilderViewState } from '../types';
import { ViewpointBuilderFilters } from './ViewpointBuilderFilters';
import { DoqContainer } from '../components/atoms';
import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { FullHeightSpace } from 'viewpointBuilder/components/atoms';
import { SidebarHeader } from 'viewpointBuilder/components/atoms';

type AddFiltersSidebarProps = {
  commands: AddFilterCommands & ChangeConditionCommands & EditFilterCommands;
  state: TraversalBuilderViewState;
};

export const AddFiltersSidebar = ({
  state,
  commands,
}: AddFiltersSidebarProps) => {
  const selectedId = state.selectedGraphNodeId ?? state.selectedGraphEdgeId;

  if (!selectedId) {
    return (
      <FullHeightSpace>
        <DoqContainer>
          <ResponsiveDoqWithSpeechBubble
            doqType={DoqType.SIMPLE}
            title="Start with a component type"
            message="Select a component or reference type on the canvas on the left to configure filters"
          />
        </DoqContainer>
      </FullHeightSpace>
    );
  }

  const isEdgeSelected = Boolean(state.selectedGraphEdgeId);
  const filterOfSelectedNode = state.filters[selectedId];
  const isAnyFilterApplied = filterOfSelectedNode?.filters.length > 0;

  return (
    <SidebarMenu
      isPageBodyMenu
      isResizable
      isRightContent
      headerBody={
        <SidebarHeader
          type={isEdgeSelected ? 'reference' : 'component'}
          // @ts-expect-error passing potentially undefined here, even though it's not permitted
          selectedType={
            isEdgeSelected
              ? state.tripleOptions.selectedEdge?.metaDataComponent
              : state.tripleOptions.selectedNode.metaDataComponent
          }
          headerText="Add filters to"
          iconName={IconName.FILTER_LIST}
        />
      }
    >
      {isAnyFilterApplied ? (
        <ViewpointBuilderFilters
          availableFilterGroupedOptions={
            state.filtersDefinitions.componentFilterTypeOptions
          }
          asyncLoaders={state.asyncLoaders}
          commands={commands}
          filtersWithCondition={filterOfSelectedNode}
          selectedGraphNodeId={selectedId}
        />
      ) : (
        <NoFiltersApplied
          commands={commands}
          selectedGraphNodeId={selectedId}
        />
      )}
    </SidebarMenu>
  );
};

type NoFiltersAppliedProps = {
  commands: AddFilterCommands;
  selectedGraphNodeId: string;
};

const NoFiltersApplied = ({
  commands,
  selectedGraphNodeId,
}: NoFiltersAppliedProps) => {
  return (
    <FullHeightSpace $isVertical $align="center" $justify="center" $flex={1}>
      <span>No filters added yet</span>
      <GhostButton
        onClick={() => commands.addDefaultFilter(selectedGraphNodeId)}
      >
        <Icon iconName={IconName.ADD_CIRCLE} />
        Add a filter
      </GhostButton>
    </FullHeightSpace>
  );
};
