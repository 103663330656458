import SimpleGraphView from '../../viewpointBuilder/components/SimpleGraph/SimpleGraphView';
import { SimpleGraphContainerWithGreyBackground } from '../../viewpointBuilder/components/SimpleGraph/SimpleGraphViewContainer';
import { SimpleViewModel } from '../../viewpointBuilder/types';
import styled from 'styled-components';
import { Box } from '@ardoq/layout';

const Container = styled(Box)`
  width: 100%;
  height: 160px;
  flex-shrink: 0;
  pointer-events: none;
`;

const ViewpointThumbnail = ({
  graphNodes,
  graphEdges,
}: Pick<SimpleViewModel, 'graphNodes' | 'graphEdges'>) => {
  return (
    <Container>
      <SimpleGraphContainerWithGreyBackground $margin={0}>
        <SimpleGraphView
          viewModel={{ graphNodes, graphEdges, graphGroups: new Map() }}
          hasZoomControls={false}
          isThumbnailView
          shouldFitToContentOnLayout
        />
      </SimpleGraphContainerWithGreyBackground>
    </Container>
  );
};

export default ViewpointThumbnail;
