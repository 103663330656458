import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const selectAndLoadSlide = actionCreator<ArdoqId>(
  '[presentationSidebar] SELECT_AND_LOAD_SLIDE'
);

export const loadSelectedSlideIfNotAlreadyLoaded = actionCreator(
  '[presentationSidebar] LOAD_SELECTED_SLIDE_IF_NOT_ALREADY_LOADED'
);
