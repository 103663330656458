import { ArdoqId, PersistedIPAllowlistEntry } from '@ardoq/api-types';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  addIPEntryToState,
  removeIPEntryFromState,
  setFetchedIPEntries,
  setIPAllowlistAlert,
  setIPAllowlistTab,
  updateEntryInState,
} from './actions';
import { IPAllowlistingStreamShape, IPAllowlistingTab } from '../types';

type Reducer<Payload> = (
  state: IPAllowlistingStreamShape,
  payload: Payload
) => IPAllowlistingStreamShape;

const setEntries: Reducer<PersistedIPAllowlistEntry[]> = (state, entries) => ({
  ...state,
  entries,
});

const addIPEntry: Reducer<PersistedIPAllowlistEntry> = (state, entry) => ({
  ...state,
  entries: state.entries.concat(entry),
});

const removeIPEntry: Reducer<ArdoqId> = (state, id) => ({
  ...state,
  entries: state.entries.filter(entry => entry._id !== id),
  alert: null,
});

const setError: Reducer<IPAllowlistingStreamShape['alert']> = (
  state,
  alert
) => ({ ...state, alert });

const updateIPEntry: Reducer<PersistedIPAllowlistEntry> = (
  state,
  updatedEntry
) => ({
  ...state,
  entries: state.entries.map(entry =>
    entry._id === updatedEntry._id ? updatedEntry : entry
  ),
  alert: null,
});

const setTab: Reducer<IPAllowlistingTab> = (state, activeTab) => ({
  ...state,
  activeTab,
  alert: null,
});

export default persistentReducedStream(
  'ipAllowlist$',
  { entries: [], alert: null, activeTab: 'MANAGE' },
  [
    reducer(setFetchedIPEntries, setEntries),
    reducer(addIPEntryToState, addIPEntry),
    reducer(removeIPEntryFromState, removeIPEntry),
    reducer(setIPAllowlistAlert, setError),
    reducer(updateEntryInState, updateIPEntry),
    reducer(setIPAllowlistTab, setTab),
  ]
);
