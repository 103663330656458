import {
  PresentationAsset,
  AssetType,
  Asset,
  PresentationReadPermissions,
} from '@ardoq/api-types';
import { Filter } from 'components/AssetsBrowser2024/FilterBar/filterBarTypes';

const isPresentationAsset = (asset: Asset): asset is PresentationAsset => {
  return asset.meta.assetType === AssetType.PRESENTATION;
};

export const publicFilter: Filter = {
  label: 'Public access',
  filterFn: asset =>
    isPresentationAsset(asset) &&
    asset.readAccess === PresentationReadPermissions.ALL,
  filterRule: 'OR',
};

export const contributorFilter: Filter = {
  label: 'Contributor access',
  filterFn: asset =>
    isPresentationAsset(asset) &&
    asset.readAccess === PresentationReadPermissions.CONTRIBUTOR,
  filterRule: 'OR',
};

export const onlyMeFilter: Filter = {
  label: 'Only me',
  filterFn: asset =>
    isPresentationAsset(asset) &&
    asset.readAccess === PresentationReadPermissions.ME,
  filterRule: 'OR',
};

export const workspaceFilter: Filter = {
  label: "Viewer's permissions access",
  filterFn: asset =>
    isPresentationAsset(asset) &&
    asset.readAccess === PresentationReadPermissions.WS,
  filterRule: 'OR',
};
