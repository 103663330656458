import { DoqType, LilInfoDoq } from '@ardoq/doq';
import { Box, Stack } from '@ardoq/layout';

export const NoComponentFoundDoqy = () => {
  return (
    <Box padding="large">
      <Stack justify="center" align="center" gap="medium">
        <LilInfoDoq
          doqType={DoqType.ERROR}
          title="We are sorry but information about this component could not be loaded."
        />
      </Stack>
    </Box>
  );
};
