import { useCallback, useEffect, useState } from 'react';
import { confirmDelete } from '@ardoq/modal';
import { logError } from '@ardoq/logging';
import { ArdoqId, PersistedBundleShape } from '@ardoq/api-types';
import ManageBundleTable from './BundleList';
import { LoadingContainer } from './atoms';
import { BundleOverviewProps } from '../types';
import CopyBundle from './CopyBundle';
import { PrimaryButton } from '@ardoq/button';
import { Header1, Paragraph } from '@ardoq/typography';
import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { isArdoqError } from '@ardoq/common-helpers';
import { bundleApi } from '@ardoq/api';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';

/**
 * Helper function that returns true if the array of orgBundles is empty.
 * @param orgBundles List of OrgBundles
 */
const noBundlesIn = (orgBundles: Array<PersistedBundleShape>) => {
  if (orgBundles.length < 1) return true;
  return false;
};

/**
 * Helper function that finds a given OrgBundle by id and returns the name of that bundle.
 * @param orgBundles List of OrgBundles
 * @param id of an OrgBundle
 */
const findName = (orgBundles: Array<PersistedBundleShape>, id?: ArdoqId) => {
  if (!orgBundles) return 'Name does not exist';
  if (noBundlesIn(orgBundles)) return 'Name does not exist';
  const orgBundle = orgBundles.find(bundle => bundle._id === id);
  if (!orgBundle) return 'Name does not exist';
  return orgBundle.name;
};

const Loading = () => (
  <LoadingContainer>
    <ArdoqLoaderComponent
      loaderColor={LoaderColor.LIGHT}
      loaderType="spinner"
      size={LoaderSize.MEDIUM}
    />
  </LoadingContainer>
);

const Error = ({ onClick }: { onClick: () => void }) => (
  <Box marginTop="xxlarge" paddingTop="xxlarge">
    <Stack gap="medium">
      <FlexBox justify="center">
        <Header1>Error loading bundles</Header1>
      </FlexBox>
      <FlexBox justify="center">
        <Paragraph>There was an error loading bundles for this org.</Paragraph>
      </FlexBox>
      <FlexBox justify="center">
        <PrimaryButton onClick={onClick}>Retry</PrimaryButton>
      </FlexBox>
    </Stack>
  </Box>
);

const Empty = ({ onClick }: { onClick: () => void }) => (
  <Box marginTop="xxlarge" paddingTop="xxlarge">
    <Stack gap="medium">
      <FlexBox justify="center">
        <Paragraph variant="text1Bold">
          No bundles have been created in this org.
        </Paragraph>
      </FlexBox>
      <FlexBox justify="center">
        <PrimaryButton onClick={onClick}>Create bundle</PrimaryButton>
      </FlexBox>
    </Stack>
  </Box>
);

const confirmDeleteBundle = (name: string) => {
  return confirmDelete({
    title: 'Delete bundle?',
    text: (
      <>
        <p>You are about to delete the bundle &quot;{name}&quot;.</p>
        <p>This action can not be undone.</p>
      </>
    ),
  });
};

const BundleOverview = ({
  getOrgBundles,
  editBundle,
  createBundle,
}: BundleOverviewProps) => {
  const locale = getCurrentLocale();
  const [orgBundlesWithActions, setOrgBundlesWithActions] = useState<
    Array<
      PersistedBundleShape & {
        handleDelete: (id: ArdoqId) => Promise<void>;
        handleCopy: (id: ArdoqId) => void;
        handleEdit: (id: ArdoqId) => void;
      }
    >
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [copyingBundleWithId, setCopyingBundleWithId] = useState<ArdoqId>();

  const fetchOrgBundles = useCallback(async () => {
    setHasError(false);
    setIsLoading(true);
    const newOrgBundles = await getOrgBundles();

    if (isArdoqError(newOrgBundles)) {
      logError(newOrgBundles, 'Error fetching bundles');
      setHasError(true);
      setIsLoading(false);
      return;
    }

    const handleDelete = async (id: ArdoqId) => {
      const confirmed = await confirmDeleteBundle(findName(newOrgBundles, id));
      if (!confirmed) return;
      setIsLoading(true);
      const deletedResponse = await bundleApi.delete(id);
      if (isArdoqError(deletedResponse)) {
        logError(deletedResponse, 'Error deleting bundle');
        setHasError(true);
        setIsLoading(false);
      }
      await fetchOrgBundles();
      setIsLoading(false);
    };

    const handleCopy = (id: ArdoqId) => {
      setCopyingBundleWithId(id);
    };

    const handleEdit = (id: ArdoqId) => {
      editBundle(id);
    };

    const bundlesWithActions = newOrgBundles
      .map((orgBundle: PersistedBundleShape) => {
        return { ...orgBundle, handleDelete, handleCopy, handleEdit };
      })
      .sort((a, b) => localeCompare(a.name, b.name, locale));

    setOrgBundlesWithActions(bundlesWithActions);
    setIsLoading(false);
  }, [getOrgBundles, editBundle, locale]);

  useEffect(() => {
    fetchOrgBundles();
  }, [fetchOrgBundles]);

  if (isLoading) return <Loading />;
  if (hasError) return <Error onClick={fetchOrgBundles} />;
  if (noBundlesIn(orgBundlesWithActions))
    return <Empty onClick={createBundle} />;
  return (
    <>
      {copyingBundleWithId ? (
        <CopyBundle
          goBack={() => setCopyingBundleWithId(undefined)}
          bundleId={copyingBundleWithId}
          bundleName={findName(orgBundlesWithActions, copyingBundleWithId)}
        />
      ) : (
        <ManageBundleTable bundles={orgBundlesWithActions} />
      )}
    </>
  );
};

export default BundleOverview;
