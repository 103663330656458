import { AppModules } from 'appContainer/types';
import { Route } from 'router/StreamRouter';
import { AppRouterState } from 'router/appRouterTypes';
import { navigateToAuditLog } from 'router/navigationActions';
import { dispatchAction } from '@ardoq/rxbeach';
import { EMPTY } from 'rxjs';

export const auditLogRoute = new Route<AppRouterState, void>({
  doesLocationMatch: ({ path }) => new RegExp(`\\/audit-log/?`).test(path),
  locationToRouterState: () => ({
    appModule: AppModules.AUDIT_LOG,
  }),
  doesRouterStateMatch: routerState =>
    routerState.appModule === AppModules.AUDIT_LOG,
  routerStateToLocation: () => ({
    path: '/audit-log',
    title: 'Audit log',
  }),
  setApplicationStateFromRoute: () => {
    dispatchAction(navigateToAuditLog(null));
  },
  getPartialRouterStateStream: () => EMPTY,
});
