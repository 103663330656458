import styled from 'styled-components';
import { FlexBox, Stack } from '@ardoq/layout';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { Header4 } from '@ardoq/typography';
import { Integration } from 'integrations/types';
import ApiGuideBox from '../components/ApiGuideBox';
import React from 'react';
import { getApiGuides } from '../utils';

const Container = styled(Stack)`
  color: ${colors.textDefault};
  padding: ${s16};
  border: 1px solid ${colors.borderAction};
  background-color: ${colors.surfaceDefault};
  border-radius: ${s8};
`;

type AvailableApisWidgetProps = {
  integrations: Integration[];
  onIntegrationClick: (integration: Integration) => void;
};

const AvailableApisWidget = ({
  integrations,
  onIntegrationClick,
}: AvailableApisWidgetProps) => {
  return (
    <Container gap="medium">
      <Header4>More ways to connect</Header4>
      <FlexBox justify="flex-start" flexWrap={true} gap="medium">
        {getApiGuides(integrations).map(integration => (
          <ApiGuideBox
            key={integration.id}
            integration={integration}
            onClick={integration =>
              !integration.disableOptions?.isDisabled &&
              onIntegrationClick(integration)
            }
            isSmall
          />
        ))}
      </FlexBox>
    </Container>
  );
};

export default AvailableApisWidget;
