import { actionCreator } from '@ardoq/rxbeach';
import { APIResourcePermissionAttributes } from '@ardoq/api-types';
import {
  BasicResource,
  SingleResourcePermission,
} from '@ardoq/manage-resource-permissions';
import { ApiPutResourcePermissionPayload } from './types';
import { ActorPermission } from '@ardoq/manage-resource-permissions';

const namespace = 'RESOURCE_PERMISSION';

export type ResourcePermissionMetaPayload = {
  resources: SingleResourcePermission[];
  headerText?: React.ReactNode;
  originPage:
    | 'user-assets-overview'
    | 'assets-manager'
    | 'workspace'
    | 'scenario'
    | 'review-access-request';
};

type SaveResourcePermissionsPayload = {
  permissionsEditorState: ActorPermission[];
  resources: BasicResource[];
};

export const openResourcePermissionDialog =
  actionCreator<ResourcePermissionMetaPayload>(
    `[permissions] OPEN_DIALOG_${namespace}`
  );

export const resourcePermissionDialogOpened = actionCreator(
  `[permissions] OPENED_DIALOG_${namespace}`
);

export const resourcePermissionDialogClosed = actionCreator<{
  isSaved: boolean;
}>(`[permissions] CLOSED_DIALOG_${namespace}`);

export const apiPutResourcePermission =
  actionCreator<ApiPutResourcePermissionPayload>(
    `[permissions] API_PUT_${namespace}`
  );

export const saveResourcePermissions =
  actionCreator<SaveResourcePermissionsPayload>(
    `[permissions] SAVE_${namespace}`
  );

export const setResourcePermissions = actionCreator<
  APIResourcePermissionAttributes[]
>(`[permissions] SET_${namespace}`);

export const resourcePermissionError = actionCreator<string>(
  `[permissions] ERROR_${namespace}`
);
