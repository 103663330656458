import TabbedAudienceTable from 'broadcasts/components/audienceTable/tabbedAudienceTable/TabbedAudienceTable';
import { AudiencePreview } from '@ardoq/api-types';
import BroadcastDetails, {
  BroadcastDetailsProps,
} from 'broadcasts/components/BroadcastDetails';
import { AudienceTableColumn } from '../audienceTable/types';
import { ModalSize, ModalTemplate } from '@ardoq/modal';
import { Header3, Text } from '@ardoq/typography';
import { AudiencePreviewColumn, OverflowWrapper } from '../atoms';
import { Box, FlexBox } from '@ardoq/layout';
import summaryAndLaunchDialog$ from './stream/summaryAndLaunchDialog$';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { SummaryAndLaunchDialogState } from './stream/types';
import { setFilter } from './stream/actions';

type SummaryAndLaunchDialogProps = SummaryAndLaunchDialogState &
  BroadcastDetailsProps & {
    audiencePreview: AudiencePreview;
    confirmButtonText?: string;
    cancelButtonText: string;
    resolve: (resultValue: unknown) => void;
    onConfirm: () => void;
  };

const SummaryAndLaunchDialog = ({
  audiencePreview,
  confirmButtonText,
  cancelButtonText,
  resolve,
  onConfirm,
  contentType,
  filter,
  ...rest
}: SummaryAndLaunchDialogProps) => {
  return (
    <ModalTemplate
      headerText="Review broadcast"
      modalSize={ModalSize.L}
      onSecondaryButtonClick={() => resolve(false)}
      onPrimaryButtonClick={onConfirm}
      secondaryButtonText={cancelButtonText}
      primaryButtonText={confirmButtonText}
      fixedHeight
    >
      <FlexBox height="full">
        <div>
          <Box paddingTop="medium" paddingX="large">
            <Header3>Broadcast summary</Header3>
          </Box>
          <BroadcastDetails contentType={contentType} {...rest} />
        </div>
        <AudiencePreviewColumn>
          <Box paddingTop="medium">
            <Text>
              This list automatically updates based on your filter rules.
            </Text>
          </Box>
          <OverflowWrapper>
            <TabbedAudienceTable
              audiencePreview={audiencePreview}
              defaultAudienceSortById={AudienceTableColumn.PERSON_LABEL}
              defaultComponentSortById={AudienceTableColumn.COMPONENT_LABEL}
              contentType={contentType}
              tableFilter={filter}
              onTableFilterChange={query => dispatchAction(setFilter(query))}
            />
          </OverflowWrapper>
        </AudiencePreviewColumn>
      </FlexBox>
    </ModalTemplate>
  );
};

export default connect(SummaryAndLaunchDialog, summaryAndLaunchDialog$);
