import { ExcludeFalsy } from '@ardoq/common-helpers';
import fp from 'lodash/fp';
import { ResourceType } from 'integrations/cloudProviders/streams/resourceTypes/types';
import {
  CloudProviderRegion,
  ResourceGroup,
  ResourceTypesSummary,
  Subscription,
} from '@ardoq/api-types/integrations';

const extractTypes = (items: { type?: string }[]) =>
  items.map(({ type }) => type?.toLowerCase()).filter(ExcludeFalsy);

export const getUnsupportedResourceTypes = ({
  resourceTypes,
  resourceTypesSummary,
}: {
  resourceTypes: ResourceType[];
  resourceTypesSummary: ResourceTypesSummary[];
}) => {
  const supportedResourceTypes = extractTypes(resourceTypes);
  const availableResourceTypes = extractTypes(resourceTypesSummary);

  return fp.difference(availableResourceTypes, supportedResourceTypes);
};

export const getGroupedResources = (
  resourcesSummary: ResourceTypesSummary[]
): ResourceGroup[] =>
  fp.flow(
    fp.map((resource: ResourceTypesSummary) => ({
      id: `/subscriptions/${resource.subscriptionId}/resourceGroups/${resource.resourceGroup}`,
      name: resource.resourceGroup,
      type: 'Microsoft.Resources/resourceGroups',
      location: resource.location,
      subscriptionId: resource.subscriptionId,
    })),
    fp.uniqBy(item => `${item.subscriptionId}:${item.name}`)
  )(resourcesSummary);

export const getSubscriptions = (
  resourcesSummary: ResourceTypesSummary[]
): Subscription[] =>
  fp.flow(
    fp.map(({ subscriptionId, subscriptionName }) => ({
      subscriptionId,
      subscriptionName,
    })),
    fp.uniqBy('subscriptionId'),
    fp.sortBy('subscriptionName')
  )(resourcesSummary);

export const getRegions = (
  resourcesSummary: ResourceTypesSummary[]
): CloudProviderRegion[] =>
  fp.flow(
    fp.map(({ location, subscriptionId }) => ({
      id: location,
      displayName: location,
      subscriptionId,
      regionName: location,
      description: location,
    })),
    fp.uniqBy('id'),
    fp.sortBy('displayName')
  )(resourcesSummary);
