import { IconName } from '@ardoq/icons';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { addComponentsToScenario } from './actionWrappers';
import { scenarioRelatedSelection$ } from './scenarioRelatedSelection$';
import { pluralize } from '@ardoq/common-helpers';
import { mainNode } from './consts';
import { trackClickStartAddToScenario } from 'scope/tracking';
import { ArdoqId } from '@ardoq/api-types';
import { getScopeComponents } from 'loadedScenarioData';
import { DropdownItem, DropdownOptionType } from '@ardoq/dropdown-menu';
import {
  ContextMenuListener,
  GetContextMenuData,
  getDataContextId,
} from '@ardoq/context-menu';

const getContextMenuData: GetContextMenuData = ({ target }) => {
  const { byId } = scenarioRelatedSelection$.state;
  let selectedComponents = Object.entries(byId)
    .filter(([id, value]) => value && id !== mainNode._id)
    .map(([id]) => id);

  // Check if the target id is in the selected list (if not use it instead of the selection list)
  const dataContextId = getDataContextId('data-component-id')(target);

  if (dataContextId) {
    if (!selectedComponents.find(id => id === dataContextId)) {
      selectedComponents = [dataContextId];
    }
  } else if (selectedComponents.length === 0) {
    return { options: null };
  }

  const menu: DropdownItem[] = [
    {
      name: `add-component`,
      label: `Include ${selectedComponents.length} ${pluralize(
        'component',
        selectedComponents.length
      )}`,
      iconName: IconName.ADD,
      onClick: () => {
        addComponentsToScenario(selectedComponents);
        trackClickStartAddToScenario('relatedNavigator');
      },
      type: DropdownOptionType.BUTTON_OPTION,
    },
  ].filter(ExcludeFalsy);

  return { options: menu.length > 0 ? menu : null };
};

export const scenarioRelatedContextMenuName = 'scenario-related-context-menu';

export const scenarioRelatedContextMenu: ContextMenuListener = {
  getContextMenuData,
};

export const allComponentsInScopeAreSelected = (componentsIds: ArdoqId[]) =>
  getScopeComponents().every(componentId =>
    componentsIds.includes(componentId)
  );
