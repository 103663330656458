import { PDFLink, Triple } from '@ardoq/api-types';
import {
  ComparableEditorState,
  TripleErrorObject,
  TripleSelectOptions,
  UseCaseEditorStreamState,
} from '../types';
import { isEqual, omit } from 'lodash';
import { SelectOption } from '@ardoq/select';

const getEmptyPdfLink = (): PDFLink => ({ label: '', link: '' });

const getEmptyTriple = (): Triple => ['', '', ''];

const getDefaultUseCaseEditorState = (): UseCaseEditorStreamState => ({
  name: '',
  description: '',
  businessOutcomes: '',
  iteration: '1.0',
  category: '',
  metamodelTriples: [],
  entityGroupId: '',
  pdfLinks: [getEmptyPdfLink()],
  keyMetricsDashboardId: '',
  initialStateToCompare: undefined,
});

const transformEditorStreamStateToComparableFormat = (
  editorState: Partial<UseCaseEditorStreamState> | undefined
): ComparableEditorState => {
  return omit(editorState, [
    'initialStateToCompare',
    'errors',
  ]) as ComparableEditorState;
};

const getUseCaseEditorHasChanges = (
  editorState: UseCaseEditorStreamState
): boolean => {
  const currentStateToCompare =
    transformEditorStreamStateToComparableFormat(editorState);
  const initialStateToCompare = editorState?._id
    ? transformEditorStreamStateToComparableFormat(
        editorState.initialStateToCompare
      )
    : transformEditorStreamStateToComparableFormat(
        getDefaultUseCaseEditorState()
      );

  return !isEqual(currentStateToCompare, initialStateToCompare);
};

const getAllEntitiesNames = <T extends { value: string }>(entityArray: T[]) =>
  new Set(entityArray.map(entity => entity.value));

const getTriplesErrors = (
  componentTypesSelectOptions: SelectOption<string>[],
  referenceTypesSelectOptions: SelectOption<string>[],
  triples: Triple[],
  triplesSelectOptions: TripleSelectOptions[]
): TripleErrorObject[] => {
  const componentTypeNames = getAllEntitiesNames(componentTypesSelectOptions);
  const referenceTypeNames = getAllEntitiesNames(referenceTypesSelectOptions);
  return triples.map(
    ([sourceComponentType, referenceType, targetComponentType], idx) => {
      const tripleSelectOptions = triplesSelectOptions[idx];
      const localReferenceTypeNames = getAllEntitiesNames(
        tripleSelectOptions.referenceTypeOptions
      );
      const localTargetComponentTypeNames = getAllEntitiesNames(
        tripleSelectOptions.targetComponentTypeOptions
      );
      const hasDeletedSourceComponentType =
        !!sourceComponentType && !componentTypeNames.has(sourceComponentType);
      const hasDeletedReferenceType =
        !!referenceType && !referenceTypeNames.has(referenceType);
      const hasDeletedTargetComponentType =
        !!targetComponentType && !componentTypeNames.has(targetComponentType);

      const hasReferenceOutOfSourceComponentTypeWorkspace =
        !!referenceType && !localReferenceTypeNames.has(referenceType);

      const hasTargetComponentTypeOutOfSourceComponentTypeWorkspace =
        !!targetComponentType &&
        !localTargetComponentTypeNames.has(targetComponentType);

      return {
        hasDeletedSourceComponentType,
        hasDeletedReferenceType,
        hasDeletedTargetComponentType,
        hasReferenceOutOfSourceComponentTypeWorkspace,
        hasTargetComponentTypeOutOfSourceComponentTypeWorkspace,
      };
    }
  );
};

const getDashboardError = (
  keyMetricsDashboardId: string,
  dashboardSelectOptions: SelectOption<string>[]
) => {
  if (!keyMetricsDashboardId) return false;
  const dashboardNames = getAllEntitiesNames(dashboardSelectOptions);
  return !dashboardNames.has(keyMetricsDashboardId);
};

export const useCaseEditorOperations = {
  getEmptyTriple,
  getEmptyPdfLink,
  getDefaultUseCaseEditorState,
  transformEditorStreamStateToComparableFormat,
  getUseCaseEditorHasChanges,
  getTriplesErrors,
  getDashboardError,
};
