import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
  extractPayload,
} from '@ardoq/rxbeach';
import { exhaustMap, tap } from 'rxjs/operators';
import {
  createPresentationAndOpenInEditor,
  createPresentationAndOpenInPreview,
  updatePresentation,
} from './actions';
import { previewPresentationInEditor } from 'presentation/presentations2024/actions';
import { openPresentationEditor } from 'presentationEditor/presentationUtil';
import { handleError, presentationApi } from '@ardoq/api';
import { renameRoutine } from 'streams/assets/routines';
import { createPresentation$ } from './routineUtils';
import { presentationsNamespace } from './presentations$';
import { handleDelete, handleFetchAll } from 'streams/crud/routines';

const handleUpdatePresentation = routine(
  ofType(updatePresentation),
  extractPayload(),
  exhaustMap(presentationApi.update),
  // Ideally, we should dispatch an updateFailed so that the
  // optimisticPresentation$ could reset
  handleError()
);

const handleCreatePresentationAndOpenInPreview = routine(
  ofType(createPresentationAndOpenInPreview),
  extractPayload(),
  exhaustMap(createPresentation$),
  tap(presentation => {
    dispatchAction(
      previewPresentationInEditor({
        presentationId: presentation._id,
      })
    );
  })
);

const handleCreatePresentationAndOpenInEditor = routine(
  ofType(createPresentationAndOpenInEditor),
  extractPayload(),
  exhaustMap(createPresentation$),
  tap(presentation => {
    openPresentationEditor({
      presentationId: presentation._id,
    });
  })
);

export const presentationRoutines = collectRoutines(
  handleUpdatePresentation,
  handleCreatePresentationAndOpenInPreview,
  handleCreatePresentationAndOpenInEditor,
  renameRoutine(presentationsNamespace, presentationApi.rename),
  handleDelete(presentationsNamespace, presentationApi.delete),
  handleFetchAll(presentationsNamespace, presentationApi.fetchAll)
);
