import { getFetchProgressStream } from 'integrations/common/streams/fetchProgress/fetchProgress$';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { map, switchMap } from 'rxjs';

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => getFetchProgressStream(integrationId)),
  map(fetchProgress => ({
    ...fetchProgress,
  }))
);
