import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import { colors, s16 } from '@ardoq/design-tokens';
import { Link, LinkProps } from '@ardoq/typography';

export const LinkWrapper = styled.div`
  padding: 0 ${s16} 0 ${s16};
  white-space: nowrap;
`;

type ExternalLinkProps = {
  URL: string;
  target?: string;
  windowFeatures?: string;
  trackClick?: VoidFunction;
};

export const ExternalLink = ({
  URL,
  children,
  target = '_blank',
  windowFeatures,
  trackClick,
}: PropsWithChildren<ExternalLinkProps>) => (
  <LinkWrapper>
    <Link
      underlineOnHover
      data-click-id="asset-manager-play-button-click"
      target="_blank"
      onClick={() => {
        trackClick?.();
        window.open(URL, target, windowFeatures);
      }}
      href={URL}
      color={colors.grey15}
    >
      {children}
    </Link>
  </LinkWrapper>
);

export const InternalLink = ({
  onClick,
  iconName,
  children,
}: PropsWithChildren<Pick<LinkProps, 'onClick' | 'iconName'>>) => (
  <LinkWrapper>
    <Link
      onClick={onClick}
      color={colors.grey15}
      underlineOnHover
      iconName={iconName}
    >
      {children}
    </Link>
  </LinkWrapper>
);
