import { ArdoqId, Entity } from '@ardoq/api-types';
import { ArdoqError } from '@ardoq/common-helpers';
import { namespacedActionCreator } from '@ardoq/rxbeach';

export const fetchAllRequested = namespacedActionCreator('[crud] FETCH_ALL');

export const fetchAllSucceeded = namespacedActionCreator<Entity[]>(
  '[crud] FETCH_ALL_SUCCEEDED'
);

export const fetchAllFailed = namespacedActionCreator<ArdoqError>(
  '[crud] FETCH_ALL_FAILED'
);

export const deleteRequested =
  namespacedActionCreator<ArdoqId>('[crud] REMOVE');
