import SortHeader from 'components/EntityBrowser/SortHeader';
import { WithPopover } from '@ardoq/popovers';
import { SurveyErrorOrWarningPopover } from './SurveyErrorOrWarningPopover';
import { Folder } from 'components/EntityBrowser/types';
import { ColumnsProps } from 'components/AssetsBrowser/columns';
import { ErrorIcon, IconName, WarningIcon } from '@ardoq/icons';
import { colors, s8 } from '@ardoq/design-tokens';
import { AssetRow, SurveyRow } from 'components/AssetsBrowser/types';
import { StatusType, Tag } from '@ardoq/status-ui';
import { CellPlaceholder, HeaderCell, RowType } from '@ardoq/table';
import { IconDecorator } from '@ardoq/decorators';
import { APISurveyPendingApprovalsSummary, ArdoqId } from '@ardoq/api-types';
import { SurveyValidation } from 'surveyAdmin/types';
import { validationHelpers } from 'surveyAdmin/surveyValidation';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToChangeApprovalPage } from 'surveyAdmin/navigation/actions';
import { trackOpenChangeApprovalPage } from 'surveyAdmin/tracking';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { FlexBox } from '@ardoq/layout';

const getIcon = (hasErrors: boolean, hasWarnings: boolean) => {
  if (hasErrors) {
    return <ErrorIcon style={{ margin: s8 }} color={colors.iconError} />;
  }
  if (hasWarnings) {
    return <WarningIcon style={{ margin: s8 }} color={colors.iconWarning} />;
  }
};

export const getValidationColumn = (
  surveyValidationObject: Record<string, SurveyValidation>
) => {
  return {
    valueRender: (_any: any, row: AssetRow | Folder<AssetRow>) => {
      if (row.rowType !== RowType.SURVEY) return <></>;
      const surveyRow = row as SurveyRow;
      const validation = surveyValidationObject[surveyRow._id];
      const hasErrors = validationHelpers.hasError(validation);
      const hasWarnings = validationHelpers.hasWarning(validation);
      const hasErrorsOrWarnings = hasErrors || hasWarnings;
      const icon = getIcon(hasErrors, hasWarnings);
      return (
        validation &&
        hasErrorsOrWarnings && (
          <WithPopover
            content={() => (
              <SurveyErrorOrWarningPopover
                errorMessages={validationHelpers.getOverviewErrorMessages(
                  validation
                )}
                warningMessages={validationHelpers.getOverviewWarningMessages(
                  validation
                )}
              />
            )}
          >
            <FlexBox align="center">{icon}</FlexBox>
          </WithPopover>
        )
      );
    },
  };
};

export const getSurveyStatusRender = (isPublished: boolean) => {
  return isPublished ? (
    <Tag
      label="Live"
      iconName={IconName.CHECK_MARK}
      statusType={StatusType.SUCCESS}
    />
  ) : (
    <Tag label="Draft" iconName={IconName.EDIT} statusType={StatusType.INFO} />
  );
};

export const getStatusColumn = (columnsProps: ColumnsProps) => {
  return {
    title: 'Status',
    dataIndex: 'published',
    headerStyle: { minWidth: 50 },
    headerRender: ({ title, dataIndex = 'published' }: HeaderCell) => (
      <SortHeader
        dataIndex={dataIndex}
        title={title}
        dataClickId="published"
        sortByField={columnsProps.sortByField}
        sortOrder={columnsProps.sortOrder}
        onSortChanged={columnsProps.setSortByField}
      />
    ),
    valueRender: (_any: any, row: AssetRow | Folder<AssetRow>) => {
      if (row.rowType !== RowType.SURVEY) return <></>;
      const survey = row;
      return getSurveyStatusRender(survey.published);
    },
  };
};

export const getContactEmailRender = (contactEmail: string | undefined) => {
  if (!contactEmail) return <CellPlaceholder placeholder="No contact email" />;
  return <IconDecorator iconName={IconName.MAIL}>{contactEmail}</IconDecorator>;
};

export const getContactEmailColumn = (columnsProps: ColumnsProps) => {
  return {
    title: 'Contact email',
    dataIndex: 'contactEmail',
    headerStyle: { minWidth: 300 },
    headerRender: ({ title, dataIndex = 'contactEmail' }: HeaderCell) => (
      <SortHeader
        dataIndex={dataIndex}
        title={title}
        dataClickId="contactEmail"
        sortByField={columnsProps.sortByField}
        sortOrder={columnsProps.sortOrder}
        onSortChanged={columnsProps.setSortByField}
      />
    ),
    valueRender: (_any: any, row: AssetRow | Folder<AssetRow>) => {
      if (row.rowType !== RowType.SURVEY) return <></>;
      const survey = row;
      return getContactEmailRender(survey.contactEmail);
    },
  };
};

export const getApprovalColumn = (
  surveysWithPendingApprovals: APISurveyPendingApprovalsSummary
) => {
  const openSurveyApprovalPage = (surveyId: ArdoqId) => {
    trackOpenChangeApprovalPage('Pending changes tag');
    dispatchAction(navigateToChangeApprovalPage(surveyId));
  };
  return {
    title: 'Approval',
    dataIndex: 'approvalFlow',
    headerStyle: { minWidth: 175 },
    valueRender: (_any: any, row: AssetRow | Folder<AssetRow>) => {
      if (row.rowType !== RowType.SURVEY) return <></>;
      const survey = row;
      const hasPendingChanges = Boolean(
        surveysWithPendingApprovals[survey._id]?.total
      );
      if (!survey.approvalFlow?.enabled)
        return <Tag leftIcon={{ name: IconName.CLOSE }} label="Approval off" />;
      if (hasPendingChanges)
        return (
          <Tag
            leftIcon={{ name: IconName.CHECK_MARK }}
            label="Pending changes"
            statusType={StatusType.NEW}
            onClick={
              currentUserInterface.currentUserIsOrgAdmin()
                ? () => openSurveyApprovalPage(survey._id)
                : undefined
            }
          />
        );
      return (
        <Tag
          leftIcon={{ name: IconName.NOT_INTERESTED }}
          label="No pending changes"
          statusType={StatusType.INFO}
        />
      );
    },
  };
};
