import { DataSyncStrategy as DataSyncStrategyType } from '@ardoq/api-types/integrations';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';

export const isHardDeleteDisabled = (tableMapping?: TableMappingMap) =>
  Object.values(tableMapping?.mappedColumns || []).some(
    columnMapping =>
      columnMapping.columnType === 'reference-type' ||
      columnMapping.columnType === 'component-type'
  ) || tableMapping?.syncOption === DataSyncStrategyType.CREATE_ONLY;

export const getSyncTagsText = (
  deleteSettingsEnabled: boolean,
  syncOption: string
) => {
  const tagsText = [];

  if (syncOption === DataSyncStrategyType.UPDATE_ONLY) {
    tagsText.push('Update only');
  }

  if (syncOption === DataSyncStrategyType.CREATE_ONLY) {
    tagsText.push('Create only');
  }

  if (deleteSettingsEnabled) {
    tagsText.push('Delete missing assets');
  }

  return tagsText;
};

export const getHardDeletePopoverText = (
  isHardDeleteDisabled: boolean,
  syncOption: string
): string | undefined => {
  if (syncOption === DataSyncStrategyType.CREATE_ONLY) {
    return 'When “Create only” is selected, “Delete missing assets” is deactivated.';
  }

  if (isHardDeleteDisabled) {
    return 'When “Type” is mapped as a column, “Delete missing assets” is deactivated.';
  }
};
