import SortableQuestion from './SortableQuestion';
import AddQuestion from './AddQuestion';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { SortableQuestionsProps } from './types';
import {
  DragAndDropContextState,
  DragAndDropSectionsProvider,
  Position,
  arrayMove,
} from '@ardoq/drag-and-drop';
import { getQuestionId } from './utils';
import { omit } from 'lodash';
import { ErrorNotification } from '@ardoq/status-ui';
import { SurveyErrorName } from 'surveyAdmin/consts';
import { validationHelpers } from 'surveyAdmin/surveyValidation';
import { isConditionalQuestion } from './questionPredicates';
import { AllPossibleQuestions } from 'surveyAdmin/types';

const SortableQuestions = ({
  validators,
  parentQuestionIsReadonly,
  QuestionBuilder,
  surveyValidation,
  subQuestionValidations,
  typeId,
  modelId,
  workspaceId,
  isComponent,
  questions = [],
  expandedQuestion,
  addQuestionLabel,
  addQuestionDescription,
  surveyId,
  permissionsContext,
  allowComponentCreation,
  surveyIsPermissionDivisionsAware,
  hasSurveyResponseApprovalsFeature,
  updateQuestions,
  expandQuestion,
  addQuestion,
}: SortableQuestionsProps) => {
  const missingQuestions = [
    validationHelpers.containsErrorId(
      surveyValidation,
      SurveyErrorName.MISSING_NAME_QUESTION
    ) && 'Name',
    validationHelpers.containsErrorId(
      surveyValidation,
      SurveyErrorName.REQUIRED_NEW_COMPONENT_PARENT_IS_MISSING
    ) && 'Parent',
  ].filter(ExcludeFalsy);

  const onSortEnd = (
    newOrder: string[],
    { draggedCardId }: DragAndDropContextState
  ) => {
    if (!questions?.length || !draggedCardId) return;
    const oldIndex = questions.findIndex(
      question => getQuestionId(question) === draggedCardId
    );
    const newIndex = newOrder.indexOf(draggedCardId);

    const updatedOrder = arrayMove(questions, oldIndex, newIndex);
    updateQuestions(updatedOrder);
  };

  const handleOrderChange = (id: string | null, position: Position | null) => {
    if (!questions?.length || !id) return;
    const currentIndex = questions.findIndex(
      question => getQuestionId(question) === id
    );
    if (currentIndex === 0 && position === 'above') return;

    const newOrder = arrayMove(
      questions,
      currentIndex,
      position === 'above' ? currentIndex - 1 : currentIndex + 1
    );
    updateQuestions(newOrder);
  };
  return (
    <>
      {missingQuestions.length ? (
        <ErrorNotification>
          {`Add questions: ${missingQuestions.join(', ')}`}
        </ErrorNotification>
      ) : null}
      <DragAndDropSectionsProvider onDrop={onSortEnd}>
        {questions.map((question, index) => {
          const updateQuestion = (updatedQuestion: AllPossibleQuestions) => {
            if (
              isConditionalQuestion(updatedQuestion) &&
              !updatedQuestion.properties.questions
            ) {
              return updateQuestions([
                ...questions.slice(0, index),
                omit(updatedQuestion, 'hasConditionals'),
                ...questions.slice(index + 1),
              ]);
            }

            updateQuestions([
              ...questions.slice(0, index),
              updatedQuestion,
              ...questions.slice(index + 1),
            ]);
          };

          const questionId = getQuestionId(question);

          const removeQuestion = () => {
            updateQuestions([
              ...questions.slice(0, index),
              ...questions.slice(index + 1),
            ]);
            if (expandedQuestion === questionId) {
              expandQuestion(undefined);
            }
          };

          return (
            <SortableQuestion
              key={questionId}
              index={index}
              dragAndDropCardId={questionId}
              isExpanded={questionId === expandedQuestion}
              question={question}
              questions={questions}
              questionValidations={
                surveyValidation?.questionValidations?.[index] ??
                subQuestionValidations?.[index]
              }
              surveyValidation={surveyValidation}
              surveyId={surveyId}
              isComponent={isComponent}
              QuestionBuilder={QuestionBuilder}
              typeId={typeId}
              modelId={modelId}
              workspaceId={workspaceId}
              validators={validators}
              parentQuestionIsReadonly={parentQuestionIsReadonly}
              permissionsContext={permissionsContext}
              allowComponentCreation={allowComponentCreation}
              surveyIsPermissionDivisionsAware={
                surveyIsPermissionDivisionsAware
              }
              hasSurveyResponseApprovalsFeature={
                hasSurveyResponseApprovalsFeature
              }
              handleOrderChange={handleOrderChange}
              updateQuestion={updateQuestion}
              removeQuestion={removeQuestion}
              expandQuestion={() => expandQuestion(question)}
            />
          );
        })}
      </DragAndDropSectionsProvider>
      <AddQuestion
        addQuestion={addQuestion}
        isComponent={isComponent}
        addQuestionLabel={addQuestionLabel}
        addQuestionDescription={addQuestionDescription}
        questions={questions}
        hasSurveyResponseApprovalsFeature={hasSurveyResponseApprovalsFeature}
      />
    </>
  );
};

export default SortableQuestions;
