import { forwardRef } from 'react';
import DashboardBuilderSidebar from './DashboardBuilderSidebar';
import {
  DashboardBuilderChartWidget,
  EditModeDashboardAttributes,
  widgetOperations,
} from '@ardoq/dashboard';
import DataSelectionSection from './sections/DataSelectionSection';
import { useEffectOnce } from '@ardoq/hooks';
import { dispatchAction } from '@ardoq/rxbeach';
import { fetchWidgetDataSources } from '../actions';
import DataPresentationSection from './sections/DataPresentationSection';
import ColorSection from './sections/ColorSection';
import ConditionalFormattingSection from './sections/ConditionalFormattingSection';
import { getAvailableFieldsForDataSource } from '../utils';
import BarChartColorSelectionSection from './sections/BarChartColorSelectionSection';
import BarChartSingleColorSection from './sections/BarChartSingleColorSection';
import {
  APILineChartAttributes,
  BarChartColorOptions,
  WidgetDataSourceEntity,
} from '@ardoq/api-types';

interface WidgetSidebarProps {
  selectedWidget: DashboardBuilderChartWidget;
  availableDataSources: WidgetDataSourceEntity[];
  loadingDataSources: boolean;
  colors: EditModeDashboardAttributes['settings']['colors'];
  hasSourceFieldError: boolean;
  isWidgetRecentlyCreated?: boolean;
}

const WidgetSidebar = forwardRef<HTMLFormElement, WidgetSidebarProps>(
  (
    {
      selectedWidget,
      availableDataSources,
      loadingDataSources,
      colors,
      hasSourceFieldError,
      isWidgetRecentlyCreated,
    },
    ref
  ) => {
    useEffectOnce(() => {
      dispatchAction(fetchWidgetDataSources());
    });
    const { datasource, name, data } = selectedWidget;
    const availableFieldsForDataSource = getAvailableFieldsForDataSource(
      availableDataSources,
      datasource
    );

    const title = isWidgetRecentlyCreated
      ? 'Add new chart'
      : `Edit ${name && `"${name}"`} chart`;

    const mappedFieldNamesToLabels: Record<string, string> =
      widgetOperations.isReadLineChartData(selectedWidget) &&
      !widgetOperations.isEmptyWidgetData(selectedWidget)
        ? (data as APILineChartAttributes['data']).reduce(
            (acc, field) => ({
              ...acc,
              [field.name]: field.label ?? field.name,
            }),
            {}
          )
        : {};

    return (
      <DashboardBuilderSidebar title={title} ref={ref}>
        <DataSelectionSection
          availableDataSources={availableDataSources}
          loadingDataSources={loadingDataSources}
          hasSourceFieldError={hasSourceFieldError}
          selectedWidget={selectedWidget}
        />
        <DataPresentationSection
          availableFieldsForDataSource={availableFieldsForDataSource}
          selectedWidget={selectedWidget}
        />
        {widgetOperations.isBarChartData(selectedWidget) && (
          <BarChartColorSelectionSection
            colorSelection={selectedWidget.colorSelection}
          />
        )}
        {widgetOperations.isBarChartData(selectedWidget) &&
          (selectedWidget.colorSelection ===
            BarChartColorOptions.SINGLE_COLOR ||
            !selectedWidget.colorSelection) && (
            <BarChartSingleColorSection
              primaryColor={selectedWidget.primaryColor}
              secondaryColor={selectedWidget.secondaryColor}
            />
          )}
        {widgetOperations.isColorableWidget(selectedWidget) && (
          <ColorSection
            colors={colors}
            fieldsNamesLabels={mappedFieldNamesToLabels}
          />
        )}
        {widgetOperations.isNumberWidgetData(selectedWidget) && (
          <ConditionalFormattingSection limits={selectedWidget.limits} />
        )}
      </DashboardBuilderSidebar>
    );
  }
);
export default WidgetSidebar;
