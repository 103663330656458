import { APIFieldType } from '@ardoq/api-types';
import { SurveyEditorSectionKey } from './streams/types';

export const SURVEY_SECTION_TITLES = {
  detailsSection: 'General information',
  workspaceAndComponentsSection: 'Define the data',
  changeApprovalSection: 'Response approval',
  surveySection: 'Questions',
  responseFeedback: 'Feedback message',
  resultDisplayOptionsSection: 'Landing page',
  resultFilteringSection: 'Results display',
  discover: 'Ardoq Discover',
};

export const SURVEY_SECTION_SUBTITLES = {
  detailsSection: 'Start by filling out the information about the survey.',
  workspaceAndComponentsSection:
    'The survey will be based on the workspace and component type you select.',
  changeApprovalSection:
    "Review the responses submitted on this survey before it becomes a part of your organisation's data. This feature is not available for surveys with a tag question",
  responseFeedback:
    "Create a custom feedback message that's automatically sent to respondents upon submission.",
  resultDisplayOptionsSection:
    'If enabled, respondents will be taken to the landing page when they open the survey.',
  resultFilteringSection:
    'Customize how the results are displayed in the results overview.',
  discover: 'Configure Ardoq Discover settings for this survey.',
};

export const SURVEY_SECTION_STEPTITLES = {
  changeApprovalSection: 'Response approval',
};

export const SECTIONS_WITH_OWN_RENDERER: Set<SurveyEditorSectionKey> = new Set([
  'changeApprovalSection',
  'surveySection',
]);

export const getNotEnoughWorkspacePermissionsText = (
  permissionLabel: string
) => `You do not have "${permissionLabel}" access on the workspace this survey
    has been created on. You can view the survey details, but you will
    be unable to save any changes.`;

export const YOU_DO_NOT_HAVE_EDIT_ACCESS_TO_WORKSPACE =
  'You do not have "Edit" access on the selected workspace.';
export const YOU_DO_NOT_HAVE_READ_ACCESS_TO_WORKSPACE =
  'You do not have "Read" access on the selected workspace.';
export const FIELD_EXPLANATION = `Not finding the field you're looking for? 
Perhaps you need to make it applicable to this component
 type or add it altogether to this workspace model.`;

export const QUESTION_HELP_TEXT_EXPLANATION = `Add context to guide respondents in understanding and responding to the question. 
This text will be displayed below the question title.`;

export const APPROVER_FIELD_TYPES = new Set([
  APIFieldType.EMAIL,
  APIFieldType.USER,
]);

export const getChangeApprovalCompatibillityErrorMessage = (
  hasPermissionZones: boolean
) =>
  `Your survey does not support Response Approvals. Please remove the tag question, ${hasPermissionZones ? 'turn off the permission zones awareness feature,' : ''} or turn off this feature.`;
