import { APIToken, OrgAccessLevel } from '@ardoq/api-types';
import { APIServiceAccount } from '@ardoq/api-types';
import { ROLE_TEXT_MAPPING } from '../../utils/permissionUtil';
import { ServiceAccountState } from './serviceAccounts$';
import { tokenTabViewModelOperations } from 'admin/user/TokenView/tokenTabViewModelOperations';
import { formatDateTime } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';

const setIsLoading = (state: ServiceAccountState): ServiceAccountState => {
  return {
    ...state,
    isLoading: true,
  };
};

const setServiceAccounts = (
  state: ServiceAccountState,
  serviceAccounts: APIServiceAccount[]
): ServiceAccountState => {
  return {
    ...state,
    isLoading: false,
    serviceAccounts,
    errorMessage: null,
  };
};

const addServiceAccount = (
  state: ServiceAccountState,
  account: APIServiceAccount
): ServiceAccountState => {
  return {
    ...state,
    serviceAccounts: [...state.serviceAccounts, account],
    errorMessage: null,
  };
};

const replaceServiceAccountToken = (
  state: ServiceAccountState,
  token: APIToken
): ServiceAccountState => {
  return {
    ...state,
    serviceAccounts: state.serviceAccounts.map(account =>
      account.user._id === token['user-id']
        ? { ...account, token: token }
        : account
    ),
    errorMessage: null,
  };
};

const deleteServiceAccount = (
  state: ServiceAccountState,
  accountId: string
): ServiceAccountState => {
  return {
    ...state,
    serviceAccounts: state.serviceAccounts.filter(
      ({ user: { _id } }) => _id !== accountId
    ),
    errorMessage: null,
  };
};

const setErrorMessage = (
  state: ServiceAccountState,
  errorMessage: string | null
) => {
  return {
    ...state,
    isLoading: false,
    errorMessage,
  };
};

const updateSearchKey = (state: ServiceAccountState, searchKey: string) => {
  return {
    ...state,
    searchKey,
  };
};

const roles = [
  OrgAccessLevel.ADMIN,
  OrgAccessLevel.WRITER,
  OrgAccessLevel.READER,
];

const getLabelForRole = (role: OrgAccessLevel) => {
  return ROLE_TEXT_MAPPING[role];
};

const getRolesRadioItems = () => {
  return roles.map(role => ({
    label: getLabelForRole(role),
    value: role,
    key: role,
  }));
};

const getDisabledCreateServiceAccountButtonHintText = (
  accountName: string,
  tokenDescription: string
) => {
  if (!accountName && !tokenDescription) {
    return 'Please give the service account and token a name to continue.';
  } else if (!accountName) {
    return 'Please give the service account a name to continue.';
  } else if (!tokenDescription) {
    return 'Please give the token a name to continue.';
  }
  return undefined;
};

const ROLE_HELP_TEXT_MAPPING = {
  [OrgAccessLevel.ADMIN]:
    'Can set up and configure all Ardoq features and has access to all workspaces.',
  [OrgAccessLevel.CONTRIBUTOR]:
    'Can access distributed Presentations and Surveys - And Viewpoints and Reports in Discover.',
  [OrgAccessLevel.WRITER]:
    'Can be granted Read, Write, or Admin access to designated workspaces.',
  [OrgAccessLevel.READER]:
    'Can only view content in Ardoq. Even if the user is added to a group with Write access.',
};

const getRoleHelperText = (role: OrgAccessLevel) => {
  return ROLE_HELP_TEXT_MAPPING[role];
};

const ROLE_EMOJI_MAPPING = {
  [OrgAccessLevel.ADMIN]: '👑',
  [OrgAccessLevel.CONTRIBUTOR]: '🙋',
  [OrgAccessLevel.WRITER]: '✍',
  [OrgAccessLevel.READER]: '👁',
};

const getRoleEmojiCode = (role: OrgAccessLevel) => {
  return ROLE_EMOJI_MAPPING[role];
};

const getTokenStatusHintText = (token: APIToken, now: Date) => {
  const expirationDate = formatDateTime(
    token.tokenExpirationDate,
    getCurrentLocale()
  );
  return tokenTabViewModelOperations.isExpired(token, now)
    ? `This service account token expired on ${expirationDate}. Regenerate the token to restore access.`
    : `This service account token will expire on ${expirationDate}.`;
};

export const serviceAccountsOperations = {
  setIsLoading,
  setServiceAccounts,
  addServiceAccount,
  replaceServiceAccountToken,
  deleteServiceAccount,
  setErrorMessage,
  getLabelForRole,
  getRolesRadioItems,
  updateSearchKey,
  getDisabledCreateServiceAccountButtonHintText,
  getRoleHelperText,
  getRoleEmojiCode,
  getTokenStatusHintText,
};
