import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { dispatchAction } from '@ardoq/rxbeach';
import { clearPerspectiveEditor } from 'perspective/actions';
import { hideViewSidePanel } from 'views/mainApp/viewSidePanel/actions';
import { ViewSidePanelContentIds } from 'views/mainApp/viewSidePanel/types';
import { perspectivesEditor$ } from 'perspectiveSidebar/perspectiveEditor/perspectivesEditor$';
import { viewSidePanel$ } from 'views/mainApp/viewSidePanel/viewSidePanel$';
import applyPerspectiveState from 'perspectiveSidebar/perspectiveEditor/applyPerspectiveState';
import {
  trackViewOptionsApply,
  trackMultiLabelFormattingApply,
} from '../../tracking';
import { getLabelFormatingRulesWithFieldsCountByType } from 'utils/getLabelFormatingRulesWithFieldsCountByType';

export const viewOptionsPerspectivesPage = combineLatest([
  perspectivesEditor$,
  viewSidePanel$,
]).pipe(
  map(([perspectiveEditorState, viewSidePanel]) => {
    const onApply = () => {
      applyPerspectiveState(perspectiveEditorState);
      trackViewOptionsApply(perspectiveEditorState.activeViewId);

      if (
        viewSidePanel.contentId === ViewSidePanelContentIds.PERSPECTIVES_LABELS
      ) {
        const { referenceLabelsCount, componentLabelsCount } =
          getLabelFormatingRulesWithFieldsCountByType(
            perspectiveEditorState.formattingTab.labelFormatting
          );
        trackMultiLabelFormattingApply({
          componentLabelsCount,
          referenceLabelsCount,
          viewStyle: perspectiveEditorState.activeViewId,
        });
      }
    };

    const onClose = () => dispatchAction(hideViewSidePanel());

    const onClearAll = () => {
      dispatchAction(clearPerspectiveEditor());
      trackMultiLabelFormattingApply({
        componentLabelsCount: 0,
        referenceLabelsCount: 0,
        viewStyle: perspectiveEditorState.activeViewId,
      });
    };

    return {
      onApply,
      onClose,
      onClearAll,
    };
  })
);
