import {
  TransferSuccessComponent,
  TransferSuccessProps,
} from 'integrations/common/components/transferSuccess/TransferSuccess';
import {
  TransferDirection,
  TransferState,
} from 'integrations/common/streams/transferState/types';
import { connect } from '@ardoq/rxbeach';
import { Texts } from './types';

import { viewModel$ } from './viewModel$';
import { getTransferSuccessTexts } from './utils';
import { ScheduleState } from 'integrations/common/streams/schedule/schedule$';

type TransferSuccessWrapperProps = {
  transferDirection: TransferDirection;
  scheduleStage: ScheduleState['scheduleStage'];
  integrationName: string;
  transferStatus: TransferState['requestStatus'];
  affectedWorkspaces: TransferSuccessProps['affectedWorkspaces'];
  selectedWorkspaces: string[];
  onOpenWorkspaces: () => void;
  onOpenIntegration: () => void;
  onLinkClicked?: () => void;
  onSelectedWorkspacesChanged: (selectedWorkspaces: string[]) => void;
};

function TransferSuccessWrapper({
  transferDirection,
  scheduleStage,
  integrationName,
  transferStatus,
  affectedWorkspaces,
  selectedWorkspaces,
  onOpenWorkspaces,
  onOpenIntegration,
  onLinkClicked,
  onSelectedWorkspacesChanged: setSelectedWorkspaces,
}: TransferSuccessWrapperProps) {
  if (transferStatus !== 'SUCCESS') {
    return <></>;
  }
  const texts: Texts = getTransferSuccessTexts({
    transferDirection,
    scheduleStage,
    integrationName,
    affectedWorkspaces: affectedWorkspaces.map(ws => ws.id),
    onOpenWorkspaces,
    onOpenIntegration,
    onLinkClicked,
  });
  return (
    <TransferSuccessComponent
      title={texts.title}
      message={texts.message}
      affectedWorkspaces={affectedWorkspaces}
      selectedWorkspaces={selectedWorkspaces}
      onWorkspacesSelected={setSelectedWorkspaces}
    ></TransferSuccessComponent>
  );
}

export const TransferSuccess = connect(TransferSuccessWrapper, viewModel$);
