import { MappedColumnsConstraints } from './types';

export const isColumnMappingTypeDisabled = ({
  mappedColumnsConstraints,
  columnName,
}: {
  mappedColumnsConstraints?: MappedColumnsConstraints;
  columnName: string;
}) => {
  if (!mappedColumnsConstraints) return false;

  return mappedColumnsConstraints[columnName]
    ? mappedColumnsConstraints[columnName].disabledColumnType
    : false;
};
