import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  createSchedule,
  createScheduleFailure,
  createScheduleSuccess,
  deleteSchedule,
  fetchSchedulesList,
  pauseSchedule,
  removeScheduleFromSchedulesList,
  runSchedule,
  scheduleAlreadyExists,
  setSchedulesAsyncStatus,
  updateSchedule,
  updateScheduleFailure,
  updateSchedulesList,
  updateScheduleSuccess,
} from './actions';
import { catchError, forkJoin, from, map, of, switchMap, tap } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import { getErrorStatusCode, jobApi } from '@ardoq/api';
import { ApiError, handleApiError } from 'integrations/common/utils/api';
import { showSuccessToast } from 'streams/invitations/utils';
import { initIntegration } from 'integrations/actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { isIntegrationWithSchedules } from './utils';
import currentUser$ from '../../../../streams/currentUser/currentUser$';
import { permissionsOperations } from '@ardoq/access-control';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import { showToast } from '@ardoq/status-ui';

const handleFetchSchedulesListOnFirstOpen = routine(
  ofType(initIntegration),
  extractPayload(),
  filter(({ id }) => isIntegrationWithSchedules(id as IntegrationId)),
  withLatestFrom(currentUser$),
  tap(([_, currentUser]) => {
    if (permissionsOperations.isOrgAdmin(currentUser)) {
      dispatchAction(fetchSchedulesList());
    }
  })
);

const handleUpdateSchedule = routine(
  ofType(updateSchedule),
  extractPayload(),
  switchMap(({ integrationId, schedule }) => {
    dispatchAction(setSchedulesAsyncStatus('LOADING'));
    return forkJoin([
      from(jobApi.updateSchedule(schedule)).pipe(
        map(response => {
          if (isArdoqError(response)) {
            throw new ApiError(
              `Unable to update schedules`,
              getErrorStatusCode(response),
              getArdoqErrorMessage(response)
            );
          }
          return response;
        }),
        catchError(error => {
          dispatchAction(updateScheduleFailure(schedule));
          dispatchAction(setSchedulesAsyncStatus('FAILURE'));
          showToast('Failed to update schedule');
          return handleApiError(error);
        })
      ),
      of(integrationId),
    ]);
  }),
  tap(([schedule, integrationId]) => {
    dispatchAction(updateScheduleSuccess({ integrationId, schedule }));
    dispatchAction(fetchSchedulesList());
    showSuccessToast('Schedule updated');
  })
);

const handleCreateSchedule = routine(
  ofType(createSchedule),
  extractPayload(),
  switchMap(({ integrationId, schedule }) => {
    dispatchAction(setSchedulesAsyncStatus('LOADING'));
    return forkJoin([
      of(integrationId),
      from(jobApi.createSchedule(schedule)).pipe(
        map(response => {
          if (isArdoqError(response)) {
            throw new ApiError(
              `Unable to create schedule`,
              getErrorStatusCode(response),
              getArdoqErrorMessage(response)
            );
          }
          return response;
        }),
        catchError(error => {
          dispatchAction(createScheduleFailure({ integrationId, schedule }));
          dispatchAction(setSchedulesAsyncStatus('FAILURE'));
          if (error.statusCode === 409) {
            dispatchAction(scheduleAlreadyExists({ schedule, integrationId }));
          }
          return handleApiError(error);
        })
      ),
    ]);
  }),
  tap(([integrationId, schedule]) => {
    dispatchAction(createScheduleSuccess({ integrationId, schedule }));
    dispatchAction(fetchSchedulesList());
  })
);

const handleFetchSchedulesList = routine(
  ofType(fetchSchedulesList),
  switchMap(() => {
    dispatchAction(setSchedulesAsyncStatus('LOADING'));
    return from(jobApi.fetchSchedulesList()).pipe(
      map(response => {
        if (isArdoqError(response)) {
          throw new ApiError(
            `Unable to fetch schedules`,
            getErrorStatusCode(response),
            getArdoqErrorMessage(response)
          );
        }
        return response;
      }),
      catchError(error => {
        dispatchAction(setSchedulesAsyncStatus('FAILURE'));
        return handleApiError(error);
      })
    );
  }),
  tap(schedules => {
    dispatchAction(updateSchedulesList(schedules));
  }),
  catchError((_, stream$) => {
    // TODO: throw an error instead of returning the stream. Currently, we don't throw the error because this breaks the stream and stops the parallel routines.
    return stream$;
  })
);

const handlePauseSchedule = routine(
  ofType(pauseSchedule),
  extractPayload(),
  switchMap(scheduleId => {
    dispatchAction(setSchedulesAsyncStatus('LOADING'));
    return from(jobApi.pauseSchedule(scheduleId)).pipe(
      catchError(error => {
        dispatchAction(setSchedulesAsyncStatus('FAILURE'));
        showToast('Failed to pause schedule');
        return handleApiError(error);
      })
    );
  }),
  tap(() => {
    showSuccessToast('Schedule paused.');
    dispatchAction(fetchSchedulesList());
  })
);

const handleDeleteSchedule = routine(
  ofType(deleteSchedule),
  extractPayload(),
  switchMap(schedule => {
    dispatchAction(setSchedulesAsyncStatus('LOADING'));
    return from(jobApi.deleteSchedule(schedule)).pipe(
      map(response => {
        // TODO Handle error in another way when response is empty
        if (isArdoqError(response)) {
          throw new ApiError(
            `Unable to delete schedule`,
            getErrorStatusCode(response),
            getArdoqErrorMessage(response)
          );
        }
        return response;
      }),
      catchError(error => {
        dispatchAction(setSchedulesAsyncStatus('FAILURE'));
        showToast('Failed to delete schedule');
        return handleApiError(error);
      })
    );
  }),
  tap(schedule => {
    showSuccessToast('Schedule deleted.');
    dispatchAction(removeScheduleFromSchedulesList(schedule));
  })
);

const handleRunSchedule = routine(
  ofType(runSchedule),
  extractPayload(),
  switchMap(scheduleId => {
    dispatchAction(setSchedulesAsyncStatus('LOADING'));
    return from(jobApi.runSchedule(scheduleId)).pipe(
      catchError(error => {
        dispatchAction(setSchedulesAsyncStatus('FAILURE'));
        showToast('Failed to run schedule');
        return handleApiError(error);
      })
    );
  }),
  tap(() => {
    showSuccessToast('Schedule launched');
    dispatchAction(fetchSchedulesList());
  })
);

export default [
  handleFetchSchedulesListOnFirstOpen,
  handleUpdateSchedule,
  handleFetchSchedulesList,
  handleDeleteSchedule,
  handleCreateSchedule,
  handleRunSchedule,
  handlePauseSchedule,
];
