import { ValueGraphSearchResponse } from 'broadcasts/types';
import { WorkspacesState } from 'streams/workspaces/workspaces$';
import {
  APIAudiencePreviewResponse,
  ArdoqId,
  BroadcastAudienceType,
  WorkspaceAudience,
} from '@ardoq/api-types';
import { WorkspaceAudienceDialogStreamShape } from './types';

export const getDefaultState = (): WorkspaceAudienceDialogStreamShape => ({
  peopleWorkspaceOptions: [],
  workspaceId: null,
  peopleInSelectedWorkspace: [],
  limitedToPersonIds: [],
  isUpdatingExistingAudience: false,
  formIsValid: false,
  isFetchingPeopleWorkspaceOptions: false,
  failedToFetchPeopleWorkspaceOptions: false,
  isFetchingPeopleInSelectedWorkspace: false,
  failedToFetchPeopleInSelectedWorkspace: false,
  warningMessage: null,
});

const toWorkspaceOptions = ({ byId: workspaceMap }: WorkspacesState) => {
  return Object.entries(workspaceMap).map(([workspaceId, { name }]) => ({
    value: workspaceId,
    label: name,
  }));
};

export const toPeopleWorkspaceOptions = ([response, workspacesState]: [
  response: ValueGraphSearchResponse,
  WorkspacesState,
]) => {
  const peopleWorkspaceIds = response.result;
  return toWorkspaceOptions(workspacesState).filter(({ value: workspaceId }) =>
    peopleWorkspaceIds.includes(workspaceId)
  );
};

export const getWorkspaceAudience = (
  workspaceId: ArdoqId
): WorkspaceAudience => ({
  audienceType: BroadcastAudienceType.WORKSPACE,
  workspaceId,
  limitedToPersonIds: [],
});

export const getPeopleFromAudiencePreviewResponse = (
  audiencePreview: APIAudiencePreviewResponse
) => {
  return audiencePreview.audience.map(({ person }) => person);
};
