import React from 'react';
import { Category, Group, Integration } from 'integrations/types';
import MainToolbar from 'menus/topbar/MainToolbar';
import { ButtonType } from '@ardoq/button';
import { PageTopbar } from '@ardoq/page-layout';
import {
  ButtonWithDropdown,
  DropdownItem,
  DropdownOptionType,
} from '@ardoq/dropdown-menu';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { setTransferDirection } from 'integrations/common/streams/transferState/actions';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { enableTabularMappingsDefault } from 'integrations/common/streams/tabularMappingsDefault/actions';
import { ExportRoute, ImportRoute } from 'integrations/common/navigation/types';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { EXPORT_AVAILABILITY } from 'integrations/common/navigation/utils';
import { Icon } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import styled from 'styled-components';

type HeaderProps = {
  integrations: Integration[];
  primaryContent: string;
  secondaryContent: string;
  secondaryButton?: React.ReactNode;
};

const handleTransfer = (
  integrationId: IntegrationId,
  direction: TransferDirection,
  route: ImportRoute | ExportRoute
) => {
  dispatchAction(
    setTransferDirection({ integrationId, transferDirection: direction })
  );
  if (direction === TransferDirection.IMPORT) {
    dispatchAction(enableTabularMappingsDefault({ integrationId }));
  }
  dispatchAction(navigateToPath({ integrationId, path: route }));
};

const IntegrationIcon = styled.img<{ $isDisabled: boolean }>`
  width: 22px;
  height: 22px;
  object-fit: contain;
  ${({ $isDisabled }) =>
    $isDisabled
      ? `
        filter: grayscale(100%);
        opacity: 0.5;
      `
      : ''}
`;

export const Header = ({
  integrations,
  primaryContent,
  secondaryContent,
  secondaryButton,
}: HeaderProps) => {
  const createIntegrationList = (
    category: Category,
    direction: TransferDirection,
    route: ImportRoute | ExportRoute
  ): Partial<DropdownItem>[] =>
    integrations
      .filter(
        integration =>
          (integration.categories.includes(category) &&
            integration.group === Group.INTEGRATIONS) ||
          (category === Category.EXPORTER &&
            (EXPORT_AVAILABILITY[integration.id as IntegrationId]?.() ?? false))
      )
      .map(integration => {
        const isDisabled = Boolean(integration.disableOptions?.isDisabled);
        const disabledReasonIcon = integration.disableOptions?.reason ? (
          <Icon
            iconName={integration.disableOptions.reason.icon}
            data-tooltip-text={integration.disableOptions.reason.hint}
            color={colors.iconOnInverted}
          />
        ) : null;

        return {
          label: (
            <div style={isDisabled ? { opacity: 0.5 } : {}}>
              {integration.name}
            </div>
          ),
          rightContent: disabledReasonIcon,
          leftContent: (
            <IntegrationIcon
              src={integration.logoUrl}
              alt={`${integration.name} logo`}
              $isDisabled={isDisabled}
            />
          ),
          ...(!isDisabled && {
            onClick: () =>
              handleTransfer(integration.id as IntegrationId, direction, route),
          }),
        };
      });

  const createSection = (
    label: string,
    category: Category,
    direction: TransferDirection,
    route: ImportRoute | ExportRoute
  ) => [
    { label, type: DropdownOptionType.HEADER },
    { type: DropdownOptionType.DIVIDER },
    ...createIntegrationList(category, direction, route),
  ];

  const options: Partial<DropdownItem>[] = [
    ...createSection(
      'Import',
      Category.IMPORTER,
      TransferDirection.IMPORT,
      ImportRoute.SELECT_DATA
    ),
    ...createSection(
      'Export',
      Category.EXPORTER,
      TransferDirection.EXPORT,
      ExportRoute.SELECT_DATA
    ),
  ];

  return (
    <PageTopbar
      primaryContent={primaryContent}
      secondaryContent={secondaryContent}
      toolbarContent={<MainToolbar shouldUseNewJourneyVersion />}
      primaryButton={
        <ButtonWithDropdown buttonType={ButtonType.BRAND} options={options}>
          Create new
        </ButtonWithDropdown>
      }
      secondaryButton={secondaryButton}
    />
  );
};
