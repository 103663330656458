import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { AppRouterState, MetamodelRoute } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { map } from 'rxjs/operators';
import { MetamodelPane } from './types';
import metamodelNavigation$ from './metamodelNavigation$';
import { navigateToMetamodel } from 'router/navigationActions';

enum RouteParts {
  NEW = 'new',
  EDIT = 'edit',
}

const metamodelRoute = new Route<AppRouterState, MetamodelRoute>({
  doesLocationMatch: ({ path }) => /\/metamodel\/?.*?/.test(path),
  locationToRouterState: ({ path }) => {
    const parts = path.split('/');
    const afterMetamodel = parts[2];
    const editId = parts[3];
    if (afterMetamodel === RouteParts.NEW) {
      return {
        appModule: AppModules.METAMODEL,
        metamodelPane: MetamodelPane.EDIT,
        metamodelId: undefined,
      };
    } else if (afterMetamodel === RouteParts.EDIT) {
      return {
        appModule: AppModules.METAMODEL,
        metamodelPane: MetamodelPane.EDIT,
        metamodelId: editId,
      };
    } else if (afterMetamodel) {
      return {
        appModule: AppModules.METAMODEL,
        metamodelPane: MetamodelPane.SELECTED,
        metamodelId: afterMetamodel,
      };
    }
    return {
      appModule: AppModules.METAMODEL,
      metamodelPane: MetamodelPane.LIST,
      metamodelId: undefined,
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.METAMODEL,
  routerStateToLocation: ({ metamodelPane, metamodelId }) => {
    if (metamodelPane === MetamodelPane.EDIT) {
      if (metamodelId) {
        return {
          path: `/metamodel/${RouteParts.EDIT}/${metamodelId}`,
          title: 'Edit Metamodel',
        };
      }
      return { path: `/metamodel/${RouteParts.NEW}`, title: 'New Metamodel' };
    } else if (metamodelPane === MetamodelPane.SELECTED) {
      return { path: `/metamodel/${metamodelId}`, title: 'Metamodel' };
    }
    return { path: '/metamodel/', title: 'Metamodels' };
  },
  setApplicationStateFromRoute: ({ metamodelId, metamodelPane }) => {
    dispatchAction(
      navigateToMetamodel({
        metamodelId,
        metamodelPane,
      })
    );
  },
  getPartialRouterStateStream: () =>
    metamodelNavigation$.pipe(
      map(({ pane, metamodelId }) => {
        return {
          metamodelPane: pane,
          metamodelId,
        };
      })
    ),
});

export default metamodelRoute;
