import { dispatchAction } from '@ardoq/rxbeach';
import {
  saveSubdivisionMemberships,
  changeTablePage as changeTablePageAction,
  applyAddSelection as applyAddSelectionAction,
  applyRemoveSelection as applyRemoveSelectionAction,
  addSubdivisionToAddSelection as addSubdivisionToAddSelectionAction,
  removeSubdivisionFromAddSelection as removeSubdivisionFromAddSelectionAction,
  clearAddSelection as clearAddSelectionAction,
  addSubdivisionToRemoveSelection as addSubdivisionToRemoveSelectionAction,
  removeSubdivisionFromRemoveSelection as removeSubdivisionFromRemoveSelectionAction,
  clearRemoveSelection as clearRemoveSelectionAction,
  applySelectionChanges as applySelectionChangesAction,
  setSelectComponentRows as setSelectComponentRowsAction,
} from './actions';
import {
  DecoratedSubdivision,
  ManageMembershipModalCommands,
  SelectionChanges,
  ManageMembershipModalState,
} from './types';
import { ArdoqId } from '@ardoq/api-types';
import { MembershipsState } from './membershipOperations';
import {
  DIVISIONS_USER_EVENTS,
  trackDivisionMembershipUserEvent,
} from 'subdivisionEditor/trackingUtils';
import { manageMembershipModalOperations } from './manageMembershipModalOperations';

const saveMembershipChanges = (membershipsState: MembershipsState) => {
  trackDivisionMembershipUserEvent(
    DIVISIONS_USER_EVENTS.SAVED_COMPONENTS_DIVISION_MEMBERSHIPS,
    {
      divisionsToAddToCount: Object.keys(membershipsState.toAssign).length,
      divisionsToRemoveFromCount: Object.keys(membershipsState.toRevoke).length,
    }
  );
  dispatchAction(saveSubdivisionMemberships(membershipsState));
};

const changeCurrentPage = (page: number) => {
  dispatchAction(changeTablePageAction(page));
};

const addSubdivisionToAddSelection = (subdivision: DecoratedSubdivision) =>
  dispatchAction(addSubdivisionToAddSelectionAction(subdivision));

const removeSubdivisionFromAddSelection = (subdivision: DecoratedSubdivision) =>
  dispatchAction(removeSubdivisionFromAddSelectionAction(subdivision));

const clearAddSelection = () => dispatchAction(clearAddSelectionAction());

const applyAddSelection = (state: ManageMembershipModalState) => {
  trackDivisionMembershipUserEvent(
    DIVISIONS_USER_EVENTS.SELECTED_DIVISIONS_TO_ADD_TO_COMPONENTS,
    {
      selectedSubdivisions: state.currentAddSelection.length,
      totalDivisionsCount:
        manageMembershipModalOperations.getActiveDivisions(state).length,
      selectedComponentsCount: state.selectedComponentRows.length,
    }
  );
  dispatchAction(applyAddSelectionAction());
};

const addSubdivisionToRemoveSelection = (subdivision: DecoratedSubdivision) =>
  dispatchAction(addSubdivisionToRemoveSelectionAction(subdivision));

const removeSubdivisionFromRemoveSelection = (
  subdivision: DecoratedSubdivision
) => dispatchAction(removeSubdivisionFromRemoveSelectionAction(subdivision));

const clearRemoveSelection = () => dispatchAction(clearRemoveSelectionAction());

const applyRemoveSelection = (state: ManageMembershipModalState) => {
  trackDivisionMembershipUserEvent(
    DIVISIONS_USER_EVENTS.SELECTED_DIVISIONS_TO_REMOVE_FROM_COMPONENTS,
    {
      selectedSubdivisions: state.currentRemoveSelection.length,
      totalDivisionsCount:
        manageMembershipModalOperations.getActiveDivisions(state).length,
      selectedComponentsCount: state.selectedComponentRows.length,
    }
  );
  dispatchAction(applyRemoveSelectionAction());
};

const applySelectionChanges = (selectionChanges: SelectionChanges) =>
  dispatchAction(applySelectionChangesAction(selectionChanges));

const setSelectComponentRows = (selectedComponentRows: ArdoqId[]) => {
  trackDivisionMembershipUserEvent(
    DIVISIONS_USER_EVENTS.SELECTED_COMPONENTS_TO_CHANGE_MEMBERSHIPS
  );
  dispatchAction(setSelectComponentRowsAction(selectedComponentRows));
};

export const commands = {
  saveMembershipChanges,
  changeCurrentPage,
  addSubdivisionToAddSelection,
  removeSubdivisionFromAddSelection,
  clearAddSelection,
  applyAddSelection,
  addSubdivisionToRemoveSelection,
  removeSubdivisionFromRemoveSelection,
  clearRemoveSelection,
  applyRemoveSelection,
  applySelectionChanges,
  setSelectComponentRows,
} satisfies ManageMembershipModalCommands;
