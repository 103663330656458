import { HeaderCell, RowType } from '@ardoq/table';
import { ColumnsProps } from 'components/AssetsBrowser/columns';
import { AssetRow } from 'components/AssetsBrowser/types';
import SortHeader from 'components/EntityBrowser/SortHeader';
import { Folder } from 'components/EntityBrowser/types';
import {
  PRES_READ_PERMISSION_LABELS,
  AccessPermLabels,
} from 'presentationEditor/consts';

export const getReadAccessColumn = (columnsProps: ColumnsProps) => {
  return {
    title: 'Read Access',
    dataIndex: 'readAccess',
    headerStyle: { minWidth: 300 },
    headerRender: ({ title, dataIndex = 'readAccess' }: HeaderCell) => (
      <SortHeader
        dataIndex={dataIndex}
        title={title}
        dataClickId="readAccess"
        sortByField={columnsProps.sortByField}
        sortOrder={columnsProps.sortOrder}
        onSortChanged={columnsProps.setSortByField}
      />
    ),
    valueRender: (_any: any, row: AssetRow | Folder<AssetRow>) => {
      if (row.rowType !== RowType.PRESENTATION) return <></>;
      return PRES_READ_PERMISSION_LABELS[
        row.readAccess as keyof AccessPermLabels
      ];
    },
  };
};
