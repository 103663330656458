import { partition } from 'lodash';
import { RelationshipsNode } from '../types';
import { SYNTHETIC_NODE_DEFAULTS } from './consts';

const isConnected = (node: RelationshipsNode) =>
  node.children?.length || node.links?.length;

const reparentNode = (parent: RelationshipsNode, child: RelationshipsNode) =>
  (child.parent = parent);
const groupDisconnectedChildren = (
  allDisconnectedChildren: RelationshipsNode[],
  node: RelationshipsNode
) => {
  if (!node.children?.length) {
    return allDisconnectedChildren;
  }
  const [connectedChildren, disconnectedChildren] = partition(
    node.children,
    isConnected
  );
  if (disconnectedChildren.length) {
    allDisconnectedChildren.push(...disconnectedChildren);
  }
  if (disconnectedChildren.length && connectedChildren.length) {
    const parentOfDisconnectedChildren: RelationshipsNode = {
      ...SYNTHETIC_NODE_DEFAULTS,
      id: `${node.id}-disconnected-children`,
      parent: node,
      children: disconnectedChildren,
    };
    const parentOfConnectedChildren: RelationshipsNode = {
      ...SYNTHETIC_NODE_DEFAULTS,
      id: `${node.id}-connected-children`,
      parent: node,
      children: connectedChildren,
    };
    node.children = [parentOfConnectedChildren, parentOfDisconnectedChildren];
    connectedChildren.forEach(connectedChild =>
      reparentNode(parentOfConnectedChildren, connectedChild)
    );
    disconnectedChildren.forEach(disconnectedChild => {
      reparentNode(parentOfDisconnectedChildren, disconnectedChild);
      /**
       * Prepended node id is needed to ensure relayouting after reparenting from disconnected to connected and vice versa.
       * If you need to remove it, implement tree comparison (https://github.com/ardoq/ardoq-packages/pull/4167)
       */
      disconnectedChild.id = `disconnected_children_${disconnectedChild.id}`;
    });
    connectedChildren.reduce(
      groupDisconnectedChildren,
      allDisconnectedChildren
    );
  } else {
    node.children.reduce(groupDisconnectedChildren, allDisconnectedChildren);
  }
  return allDisconnectedChildren;
};
export default groupDisconnectedChildren;
