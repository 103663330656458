import { FC, useState } from 'react';
import { combineFolders } from 'components/EntityBrowser/utils';
import FolderSelect from './FolderSelect';
import assets$ from 'streams/assets/assets$';
import { connect } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { ArdoqId } from '@ardoq/api-types';
import { Row } from 'components/EntityBrowser/types';
import { AssetRow } from 'components/AssetsBrowser/types';
import { ModalSize, ModalTemplate } from '@ardoq/modal';

type StreamProps = {
  dataSource: Row<AssetRow>[];
  folderIds: ArdoqId[];
};

type ViewProps = {
  closeHandler: (selectedFolderId?: ArdoqId | null) => void;
};

const FolderSelectDialog: FC<ViewProps & StreamProps> = ({
  closeHandler,
  dataSource,
}) => {
  const [selectedFolderId, setSelectedFolderId] = useState<ArdoqId | null>(
    null
  );
  return (
    <ModalTemplate
      primaryButtonText="Move"
      secondaryButtonText="Close"
      onPrimaryButtonClick={() => closeHandler(selectedFolderId)}
      onSecondaryButtonClick={() => closeHandler()}
      headerText="Move to..."
      modalSize={ModalSize.S}
      isPrimaryButtonDisabled={selectedFolderId === undefined}
    >
      <FolderSelect
        dataSource={dataSource}
        selectedFolderId={selectedFolderId}
        setSelectedFolderId={setSelectedFolderId}
      />
    </ModalTemplate>
  );
};

export default connect(
  FolderSelectDialog,
  assets$.pipe(
    map(({ assetFoldersById }) => {
      const dataSource = combineFolders(Object.values(assetFoldersById), []);
      return { dataSource, folderIds: Object.keys(assetFoldersById) };
    })
  )
);
