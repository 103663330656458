import { Stack } from '@ardoq/layout';
import { Paragraph, Text } from '@ardoq/typography';

const Terms = () => (
  <Stack gap="medium">
    <Paragraph>
      The{' '}
      <a
        href="/resources/Ardoq Subscription Terms Global.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Ardoq terms of service
      </a>{' '}
      (&quot;End User ToS&quot;) issued by ARDOQ AS (&quot;Ardoq&quot;) govern
      your access to the Ardoq SaaS services (the &quot;Services&quot;)
      available to you if you or your company has engaged Ardoq for a free trial
      or evaluation of the Services (a &quot;Free Trial User&quot;). By clicking
      &quot;Accept&quot; you agree to be bound by the End User ToS and the
      contents hereof. If you are using the Service on behalf of a company or
      organization, by accepting you represent that you have the authority to
      bind them to the End User ToS and the terms hereof.
    </Paragraph>
    <Stack gap="xsmall">
      <Text variant="text2Bold">Free Trial Disclaimer.</Text>
      <Paragraph>
        If you are a Free Trial User, Ardoq grants you a limited, non-exclusive,
        non-transferable, non-sublicensable right during the Term to remotely
        access and use the Services only as provided by Ardoq and only for the
        purpose of testing and evaluation. <br />
        THE SERVICES FOR FREE TRIAL USERS ARE PROVIDED BY ARDOQ &quot;AS
        IS&quot; WITHOUT WARRANTY OF ANY KIND, AND ARDOQ DISCLAIMS ALL
        WARRANTIES, INDEMNITIES, SUPPORT AND ALL LIABILITIES FOR THE SERVICES
        ARE EXCLUDED TO THE MAXIMUM AMOUNT ALLOWED BY LAW. USE OF THE SERVICES
        AND RELIANCE THEREON IS AT THE FREE TRIAL USER&apos;S SOLE RISK. Ardoq
        may terminate the Free Trial User&apos;s access to the Services at any
        time. Free Trial User&apos;s access will be terminated automatically at
        the end of the trial period.
      </Paragraph>
    </Stack>
    <Stack gap="xsmall">
      <Text variant="text2Bold">Other agreements.</Text>
      <Paragraph>
        If your company has purchased a commercial Ardoq subscription or has
        entered into a separate agreement with Ardoq for the Services and you
        are an authorized user (an &quot;Authorized User&quot;), the separate
        agreement entered into by the company you work for shall apply in place
        of the above terms and the End User ToS.
      </Paragraph>
    </Stack>
    <Stack gap="xsmall">
      <Text variant="text2Bold">Privacy</Text>
      <Paragraph>
        Your privacy is important to Ardoq, and Ardoq will treat any and all
        such information in accordance with its privacy statement, available at{' '}
        <a href="https://ardoq.com/privacy/" target="_blank" rel="noreferrer">
          ardoq.com/privacy
        </a>{' '}
        (&quot;Privacy Statement&quot;).
      </Paragraph>
    </Stack>
  </Stack>
);
export default Terms;
