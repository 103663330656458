import _ from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import { ModalLayout, ModalTemplate } from '@ardoq/modal';
import { FormWrapper, TextInput, RadioGroup, TextArea } from '@ardoq/forms';
import { ErrorNotification } from '@ardoq/status-ui';
import { ConnectionsAsyncState } from 'integrations/common/streams/connections/types';
import {
  ServiceNowAuthenticationType,
  ServiceNowConnection,
  ServiceNowOauthClientCredentials,
} from '@ardoq/api-types/integrations';
import { isInvalidConnection } from '../utils';
import { Link, header4, text1 } from '@ardoq/typography';
import { URLInputOption } from 'integrations/common/components/urlInputOption/URLInputOption';
import { URLInputWithPrefix } from '../../common/components/urlInputWithPrefix/URLInputWithPrefix';
import { serviceNowDictionary } from 'integrations/common/dictionary';
import { AutoFocus } from 'integrations/common/components/AutoFocus';

const Form = styled(FormWrapper)`
  width: 100%;
`;

const FormHeader = styled.div`
  ${header4}
`;

const FormText = styled.div`
  ${text1}
`;

type ServiceNowOauthClientCredentialsGeneric = Omit<
  ServiceNowOauthClientCredentials,
  'authenticationType'
>;

type ExtendedServiceNowConnection =
  | ServiceNowConnection
  | (ServiceNowOauthClientCredentialsGeneric & {
      authenticationType: 'oauth-client-credentials-generic';
      authUrl: string;
      scope: string;
    });

type NewConnectionDialogParams = {
  upsert: ConnectionsAsyncState;
  existingConnection?: ServiceNowConnection;
  isOauthEnabled: boolean;
  onCancel: () => void;
  onSubmit: (connection: ServiceNowConnection) => Promise<boolean>;
};

const defaultConnection = {
  _id: '',
  name: '',
  baseUrl: '',
  user: '',
  username: '',
  password: '',
  clientId: '',
  clientSecret: '',
  kid: '',
  privateKeyPem: '',
  authUrl: '',
  scope: '',
};

type KeysForType<
  TAuthenticationType extends
    ExtendedServiceNowConnection['authenticationType'],
> = Extract<
  ExtendedServiceNowConnection,
  { authenticationType: TAuthenticationType }
>;

const defaultConnectionKeys: {
  [K in ExtendedServiceNowConnection['authenticationType']]: (keyof KeysForType<K>)[];
} = {
  'basic-auth': ['_id', 'name', 'baseUrl', 'username', 'password'],
  'oauth-jwt-bearer': [
    '_id',
    'name',
    'baseUrl',
    'clientId',
    'clientSecret',
    'kid',
    'privateKeyPem',
    'user',
  ],
  'oauth-password-bearer': [
    '_id',
    'name',
    'baseUrl',
    'clientId',
    'clientSecret',
    'username',
    'password',
  ],
  'oauth-client-credentials': [
    '_id',
    'name',
    'baseUrl',
    'clientId',
    'clientSecret',
  ],
  'oauth-client-credentials-generic': [
    '_id',
    'name',
    'baseUrl',
    'clientId',
    'clientSecret',
    'authUrl',
    'scope',
  ],
};

const getExtendedServiceNowConnectionType = (
  connection?: ServiceNowConnection
) => {
  if (!connection?.authenticationType) {
    return 'basic-auth';
  }

  if (
    connection.authenticationType === 'oauth-client-credentials' &&
    connection.authUrl &&
    connection.scope
  ) {
    return 'oauth-client-credentials-generic';
  }

  return connection.authenticationType;
};

const getServiceNowAuthType = (
  extendedAuthType: ExtendedServiceNowConnection['authenticationType']
) => {
  if (extendedAuthType === 'oauth-client-credentials-generic') {
    return 'oauth-client-credentials';
  }

  return extendedAuthType;
};

const getConnection = (
  formData: typeof defaultConnection,
  authType: ExtendedServiceNowConnection['authenticationType']
) => {
  return _.set(
    _.pick(formData, defaultConnectionKeys[authType]),
    'authenticationType',
    getServiceNowAuthType(authType)
  ) as ServiceNowConnection;
};

export function NewConnectionDialog({
  existingConnection,
  upsert,
  isOauthEnabled = false,
  onCancel,
  onSubmit,
}: NewConnectionDialogParams) {
  const [authType, setAuthType] = useState<
    ExtendedServiceNowConnection['authenticationType']
  >(getExtendedServiceNowConnectionType(existingConnection));

  const [connectionInput, setConnectionInput] = useState({
    ...defaultConnection,
    ...existingConnection,
  });

  const connectionToUpsert = getConnection(connectionInput, authType);

  const onPrimaryButtonClick = async () => {
    const isSuccess = await onSubmit(connectionToUpsert);

    if (isSuccess) {
      onCancel();
    }
  };

  const isConnectionAlreadyExist = !!connectionInput._id;

  return (
    <ModalTemplate
      modalSize={2}
      headerText={`${
        isConnectionAlreadyExist ? 'Edit connection' : 'Create new connection'
      }`}
      secondaryButtonText="Cancel"
      isInProgress={upsert.status === 'LOADING'}
      onSecondaryButtonClick={onCancel}
      primaryButtonText={`${
        isConnectionAlreadyExist ? 'Save changes' : 'Create connection'
      }`}
      onPrimaryButtonClick={onPrimaryButtonClick}
      isPrimaryButtonDisabled={isInvalidConnection(connectionToUpsert)}
    >
      <ModalLayout>
        {upsert.status === 'FAILURE' && upsert.message && (
          <AutoFocus>
            <ErrorNotification>{upsert.message}</ErrorNotification>
          </AutoFocus>
        )}
        <Form>
          <FormHeader>
            Enter your {serviceNowDictionary.name} account details to establish
            a secure connection with Ardoq. The information will be kept
            private.
          </FormHeader>
          <FormText>
            Your data is securely handled:{' '}
            <Link href="https://www.ardoq.com/privacy" target="_blank">
              Privacy Notice
            </Link>
          </FormText>
          <TextInput
            value={connectionInput.name}
            onValueChange={val =>
              setConnectionInput({ ...connectionInput, name: val })
            }
            name="connnection-name"
            label="Connection name"
            helperText="Give the connection a descriptive name to locate it easily in the
          overview page."
          />
          {isOauthEnabled && (
            <RadioGroup
              value={authType}
              label="Authentication type"
              options={[
                {
                  label: 'Basic authentication',
                  value: 'basic-auth',
                },
                {
                  label: 'OAuth 2.0 JWT',
                  value: 'oauth-jwt-bearer',
                },
                {
                  label: 'OAuth 2.0 Password',
                  value: 'oauth-password-bearer',
                },
                {
                  label: `OAuth 2.0 Client Credentials (${serviceNowDictionary.name})`,
                  value: 'oauth-client-credentials',
                },
                {
                  label: 'OAuth 2.0 Client Credentials (generic)',
                  value: 'oauth-client-credentials-generic',
                },
              ]}
              onValueChange={value =>
                setAuthType(value as ServiceNowAuthenticationType)
              }
            />
          )}
          <URLInputOption
            urlProtocol="https://"
            baseUrl={connectionInput.baseUrl}
            standardDomain=".service-now.com"
            onChange={baseUrl =>
              setConnectionInput({ ...connectionInput, baseUrl })
            }
          />
          {authType === 'basic-auth' && (
            <>
              <TextInput
                name="oauth-basic-username"
                value={connectionInput.username}
                onValueChange={val =>
                  setConnectionInput({ ...connectionInput, username: val })
                }
                label={`${serviceNowDictionary.name} username`}
                helperText={
                  !isOauthEnabled
                    ? `Enter your login username for ${serviceNowDictionary.name}.`
                    : undefined
                }
              />
              <TextInput
                name="oauth-basic-password"
                type="password"
                value={connectionInput.password}
                onValueChange={val =>
                  setConnectionInput({ ...connectionInput, password: val })
                }
                helperText={
                  !isOauthEnabled
                    ? `Enter the password you use to login to ${serviceNowDictionary.name}.`
                    : undefined
                }
                label={`${serviceNowDictionary.name} password`}
              />
            </>
          )}
          {authType !== 'basic-auth' && (
            <>
              <TextInput
                label="Client ID"
                name="oauth-client-id"
                value={connectionInput.clientId}
                onValueChange={val =>
                  setConnectionInput({ ...connectionInput, clientId: val })
                }
              />
              <TextInput
                label="Client Secret"
                name="oauth-client-secret"
                type="password"
                value={connectionInput.clientSecret}
                onValueChange={val =>
                  setConnectionInput({ ...connectionInput, clientSecret: val })
                }
              />

              {authType === 'oauth-jwt-bearer' && (
                <>
                  <TextInput
                    label="KID"
                    name="oauthjwt-kid"
                    value={connectionInput.kid}
                    onValueChange={val =>
                      setConnectionInput({ ...connectionInput, kid: val })
                    }
                  />
                  <TextArea
                    label="Private key (PEM format)"
                    fixedHeight={true}
                    value={connectionInput.privateKeyPem}
                    onValueChange={val =>
                      setConnectionInput({
                        ...connectionInput,
                        privateKeyPem: val,
                      })
                    }
                  />
                  <TextInput
                    label="Username"
                    name="oauthjwt-username"
                    value={connectionInput.user}
                    onValueChange={val =>
                      setConnectionInput({
                        ...connectionInput,
                        user: val,
                      })
                    }
                  />
                </>
              )}
              {authType === 'oauth-password-bearer' && (
                <>
                  <TextInput
                    label="Username"
                    name="oauth-password-username"
                    value={connectionInput.username}
                    onValueChange={val =>
                      setConnectionInput({
                        ...connectionInput,
                        username: val,
                      })
                    }
                  />
                  <TextInput
                    label="Password"
                    type="password"
                    name="oauth-password-password"
                    value={connectionInput.password}
                    onValueChange={val =>
                      setConnectionInput({
                        ...connectionInput,
                        password: val,
                      })
                    }
                  />
                </>
              )}

              {authType === 'oauth-client-credentials-generic' && (
                <>
                  <URLInputWithPrefix
                    label="Token URL"
                    urlProtocol="https://"
                    value={connectionInput.authUrl}
                    onChange={authUrl =>
                      setConnectionInput({
                        ...connectionInput,
                        authUrl,
                      })
                    }
                  />
                  <TextInput
                    label="Scope"
                    name="oauth-generic-scope"
                    value={connectionInput.scope}
                    onValueChange={scope =>
                      setConnectionInput({
                        ...connectionInput,
                        scope,
                      })
                    }
                  />
                </>
              )}
            </>
          )}
        </Form>
      </ModalLayout>
    </ModalTemplate>
  );
}
