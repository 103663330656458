import { DocumentPickerIframe } from 'integrations/lucidchart/DocumentPickerDrawer';
import { createEmbedViewerUrl } from 'integrations/lucidchart/utils';
import styled from 'styled-components';

const ViewContainer = styled.div`
  position: relative;
  height: 100%;
  overflow-x: hidden;
`;

const IframeContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: white;
`;

interface ExternalDocumentViewProps {
  documentId: string;
  clientId: string;
  onError?: () => void;
}

const ExternalDocumentView = ({
  documentId,
  clientId,
  onError,
}: ExternalDocumentViewProps) => {
  const iframeSource = createEmbedViewerUrl({
    documentId: documentId,
    clientId: clientId,
  });

  return (
    <ViewContainer>
      <IframeContainer>
        <DocumentPickerIframe src={iframeSource} onError={onError} />
      </IframeContainer>
    </ViewContainer>
  );
};

export default ExternalDocumentView;
