import { modal } from '@ardoq/modal';
import { find } from 'lodash/fp';
import { ArdoqId } from '@ardoq/api-types';
import { connect } from '@ardoq/rxbeach';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import {
  AzureConnection,
  AzureUpsertConnectionPayload,
} from '@ardoq/api-types/integrations';
import { ConnectionsAsyncOperationsState } from 'integrations/common/streams/connections/types';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  listConnections,
  upsertConnection,
  upsertConnectionFailure,
} from 'integrations/common/streams/connections/actions';
import { UpsertConnection } from '../components/upserConnection/UpsertConnection';

type UpsertConnectionsModalParams = {
  connectionId?: ArdoqId;
  connections: AzureConnection[];
  statuses: ConnectionsAsyncOperationsState;
  onClose: () => void;
};

const onSubmit = async (connection: AzureUpsertConnectionPayload) => {
  const action = await dispatchActionAndWaitForResponse(
    upsertConnection({ integrationId: 'azure-v3', connection }),
    listConnections,
    upsertConnectionFailure
  );
  return action.type === listConnections.type;
};

const UpsertConnectionsModalComponent = ({
  connectionId,
  connections,
  statuses,
  onClose,
}: UpsertConnectionsModalParams) => {
  const existingConnection = find(
    ({ _id }) => connectionId === _id,
    connections
  );

  return (
    <UpsertConnection
      existingConnection={existingConnection}
      upsertStatus={statuses.upsert}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

const UpsertConnectionModal = connect(
  UpsertConnectionsModalComponent,
  getConnectionsStream<AzureConnection>('azure-v3')
);

export const startUpsertConnectionModal = (connectionId?: string) =>
  modal(
    close => (
      <UpsertConnectionModal
        connectionId={connectionId}
        onClose={() => close(null)}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
