import { selectConnection } from 'integrations/common/streams/connections/actions';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { SelectConnections } from 'integrations/common/components/selectors/SelectConnections';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { getResourcesStream } from 'integrations/unified/streams/resources/resources$';
import { resetResourceQuery } from 'integrations/unified/streams/resources/actions';

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      connections: getConnectionsStream(integrationId),
      resources: getResourcesStream(integrationId),
      integrationTermsDictionary:
        getIntegrationTermsDictionaryStream(integrationId),
    });
  }),
  map(
    ({
      integrationId,
      connections: { connections, selectedConnectionIds },
      resources: { focusedResource },
      integrationTermsDictionary: {
        selectConnectionHelperText,
        selectConnectionPlaceHolder,
      },
    }) => ({
      connections,
      selectedConnectionIds: selectedConnectionIds,
      selectConnectionHelperText,
      selectConnectionPlaceHolder,
      onSelect: (connectionIds: string[]) => {
        if (!connectionIds.length) {
          return;
        }
        dispatchAction(
          selectConnection({
            integrationId,
            selectedConnectionId: connectionIds[0],
          })
        );
        if (focusedResource) {
          dispatchAction(
            resetResourceQuery({
              integrationId,
              resourceId: focusedResource,
            })
          );
        }
      },
    })
  )
);

export const ConnectionSelector = connect(SelectConnections, viewModel$);
