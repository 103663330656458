import { Box } from '@ardoq/layout';
import { Text } from '@ardoq/typography';
import { UserProfilePageProps } from 'admin/accessControl/UserProfilePage/types';
import { PrivilegeDecorator } from 'privileges/components/PrivilegeDecorator';

type PrivilegesListProps = {
  privileges: UserProfilePageProps['userPrivileges'];
  emptyStateText?: string;
  /**
   * Used to make unique keys for the list
   */
  context: string;
};
export const PrivilegesList = ({
  privileges,
  emptyStateText = 'No privileges to show',
  context,
}: PrivilegesListProps) => {
  if (!privileges.length) {
    return <Text variant="text1Italic">{emptyStateText}</Text>;
  }
  return (
    <Box>
      {privileges.map(privilege => (
        <PrivilegeDecorator
          key={`${context}-${privilege.label}`}
          privilege={privilege}
        />
      ))}
    </Box>
  );
};
