import { BooleanOperator } from '@ardoq/api-types';
import { BranchedAdvancedSearchStateShape } from './types';
import { SearchContext } from '@ardoq/query-builder';

export const defaultQueryBuilderRules = {
  condition: BooleanOperator.AND,
  rules: [],
};

export const defaultState: BranchedAdvancedSearchStateShape = {
  queryBuilderRules: defaultQueryBuilderRules,
  selectedFilterType: SearchContext.COMPONENT,
  selectedCondition: defaultQueryBuilderRules.condition,
  results: [],
  total: 0,
  sortOrder: undefined,
  sortBy: undefined,
  selectedPage: 1,
  selectedFieldNames: [],
  fields: [],
  searchError: null,
  isExporting: false,
  showResults: false,
  branchId: null,
};
