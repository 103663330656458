import CreatableList from 'scopeData/editors/CreatableList';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { CreatableListEditorProps } from 'scopeData/editors/types';
import {
  addFieldByName,
  createFieldByLabel,
} from 'appModelStateEdit/propertiesEditor/addField/addFieldToWorkspace/actions';
import addFieldToWorkspace$ from 'appModelStateEdit/propertiesEditor/addField/addFieldToWorkspace/addFieldToWorkspace$';
import { fieldLabelCreationValidator } from 'scopeData/editors/validators';
import { INVALID_FIELD_NAME_MESSAGE } from 'models/consts';

type CombinedAddFieldProps = Pick<
  CreatableListEditorProps,
  'options' | 'isDisabled' | 'disabledMessage' | 'errorMessages'
>;

const AddFieldToWorkspace = connect(
  ({
    options,
    isDisabled,
    disabledMessage,
    errorMessages,
  }: CombinedAddFieldProps) => (
    <CreatableList
      label="Select existing or create a new one"
      value={null}
      options={options}
      creationValidator={fieldLabelCreationValidator}
      onValueChange={fieldName => {
        dispatchAction(addFieldByName(fieldName as string));
      }}
      onCreateOption={fieldLabel => {
        dispatchAction(createFieldByLabel(fieldLabel));
      }}
      invalidOptionMessage={INVALID_FIELD_NAME_MESSAGE}
      isDisabled={isDisabled}
      disabledMessage={disabledMessage}
      dataClickId="editor-input-add-field-to-workspace"
      errorMessages={errorMessages}
    />
  ),
  addFieldToWorkspace$
);

export default AddFieldToWorkspace;
