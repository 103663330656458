import { ReferenceDirection } from '@ardoq/graph';
import { TraversalBuilderViewState, TripleOptions } from '../types';
import { EnforceContentWidth } from './atoms';
import { TripleOptionsList } from './TripleOptionsList';
import { EditTraversalCommands } from 'viewpointBuilder/traversals/editTraversalCommands';
import { FilterRow } from './FilterRow';
import {
  ArdoqLoaderComponent,
  LoaderColor,
} from '@ardoq/ardoq-loader-component';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { TriplesSectionHeader } from './TriplesSectionHeader';
import { pluralizeWithCount } from '@ardoq/common-helpers';
import { Link, Text } from '@ardoq/typography';

export type TogglerStyle = 'visibility' | 'checkbox';

type TriplesTogglerProps = {
  tripleOptions: TripleOptions;
  commands: EditTraversalCommands;
  triplesSortAndFiltersState: TraversalBuilderViewState['triplesSortAndFiltersState'];
  hasTripleOptions: TraversalBuilderViewState['hasTripleOptions'];
  togglerStyle: TogglerStyle;
  isDisabledWholeRow: boolean;
};

export const TriplesToggler = ({
  tripleOptions,
  commands,
  triplesSortAndFiltersState,
  hasTripleOptions,
  togglerStyle,
  isDisabledWholeRow,
}: TriplesTogglerProps) => {
  if (tripleOptions.optionCountLoadingState === 'loading') {
    return (
      <FlexBox
        justify="center"
        align="center"
        padding="xlarge"
        height="full"
        width="full"
      >
        <ArdoqLoaderComponent
          loaderColor={LoaderColor.BRAND}
          size={50}
          loaderText={'Loading reference options'}
        />
      </FlexBox>
    );
  }

  return (
    <EnforceContentWidth>
      <Box padding="medium">
        <Stack gap="large">
          <FilterRow
            commands={commands}
            triplesSortAndFiltersState={triplesSortAndFiltersState}
            isDisabled={
              !hasTripleOptions.incomingReferences &&
              !hasTripleOptions.outgoingReferences
            }
          />

          <>
            <TriplesSectionHeader
              label="Outgoing"
              count={tripleOptions.outgoing.length}
            />
            <TripleOptionsList
              options={tripleOptions.outgoing}
              hasTripleOptions={hasTripleOptions.outgoingReferences}
              direction={ReferenceDirection.OUTGOING}
              toggleOption={commands.toggleTripleOption}
              selectedComponent={tripleOptions.selectedNode}
              togglerStyle={togglerStyle}
              isDisabledWholeRow={isDisabledWholeRow}
            />
            <HiddenTriplesHint
              hiddenTriplesCount={
                triplesSortAndFiltersState.outgoingTriplesHiddenCount
              }
              onShowAllClicked={
                commands.toggleShowOnlyOptionsWithInstanceCounts
              }
            />
          </>

          <>
            <TriplesSectionHeader
              label="Incoming"
              count={tripleOptions.incoming.length}
            />
            <TripleOptionsList
              togglerStyle={togglerStyle}
              options={tripleOptions.incoming}
              hasTripleOptions={hasTripleOptions.incomingReferences}
              direction={ReferenceDirection.INCOMING}
              toggleOption={commands.toggleTripleOption}
              selectedComponent={tripleOptions.selectedNode}
              isDisabledWholeRow={isDisabledWholeRow}
            />
            <HiddenTriplesHint
              hiddenTriplesCount={
                triplesSortAndFiltersState.incomingTriplesHiddenCount
              }
              onShowAllClicked={
                commands.toggleShowOnlyOptionsWithInstanceCounts
              }
            />
          </>
        </Stack>
      </Box>
    </EnforceContentWidth>
  );
};

type HiddenTriplesHintProps = {
  hiddenTriplesCount: number;
  onShowAllClicked: () => void;
};

const HiddenTriplesHint = ({
  hiddenTriplesCount,
  onShowAllClicked,
}: HiddenTriplesHintProps) => {
  if (hiddenTriplesCount === 0) {
    return null;
  }

  return (
    <FlexBox gap="small" justify="center">
      <Text color="textSubtle" variant="text2">
        {pluralizeWithCount('reference', hiddenTriplesCount)} with zero
        instances hidden
      </Text>
      <Link
        typography={'text2Bold'}
        hideIcon={true}
        underlineOnHover={true}
        onClick={onShowAllClicked}
      >
        Show
      </Link>
    </FlexBox>
  );
};
