import { PermissionGroup } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

const DOMAIN = '[Groups]';

export const getGroups = actionCreator(`${DOMAIN} getGroups`);

export const getGroupsFinished = actionCreator<PermissionGroup[]>(
  `${DOMAIN} getGroupsFinished`
);
