import { APICurrentUser, PrivilegeLabel } from '@ardoq/api-types';
import styled from 'styled-components';
import { currentUserHasPrivilege } from '@ardoq/privileges';
import { Link, fontMixins, header1 } from '@ardoq/typography';
import { ReactNode } from 'react';
import { colors, radius, spacing } from '@ardoq/design-tokens';
import { LoadingScreenLogo } from '@ardoq/layout';

interface AccessErrorProps {
  user: APICurrentUser;
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  color: ${colors.white};
  background-color: ${colors.brand15};
  display: grid;
  z-index: 1050;
  grid-template-rows: min-content 1fr;
  align-items: start;
`;

const MessageBox = styled.div`
  padding: ${spacing.s32};
  background-color: ${colors.white};
  width: 533px;
  border-radius: ${radius.r16};
  place-self: center;
  margin-top: -80px;
`;

const Header = styled.h1`
  ${header1};
  margin: 0;
  color: ${colors.black};
  margin-bottom: ${spacing.s16};
`;

const Body = styled.div`
  ${fontMixins.normal16}
  color: ${colors.grey50};
  display: grid;
  gap: ${spacing.s16};
  & > p {
    margin: 0;
  }
`;

type AccessErrorWrapperProps = {
  header: string;
  children: ReactNode;
  user?: APICurrentUser;
};

const AccessErrorWrapper = ({
  header,
  children,
  user,
}: AccessErrorWrapperProps) => {
  return (
    <Wrapper>
      <LoadingScreenLogo
        style={{ position: 'relative', placeSelf: 'center' }}
      />
      <MessageBox>
        <Header>{header}</Header>
        <Body>
          {children}
          {user && (
            <p>
              You are currently signed in as <b>{user.email}</b>.{' '}
              <Link hideIcon href="/logout">
                Log out
              </Link>{' '}
              to sign in as a different user.
            </p>
          )}
        </Body>
      </MessageBox>
    </Wrapper>
  );
};

export const AccessError = ({ user }: AccessErrorProps) => {
  const isDiscoverUser = currentUserHasPrivilege(
    user,
    PrivilegeLabel.ACCESS_DISCOVER
  );
  return (
    <AccessErrorWrapper header="No access" user={user}>
      <p>
        Sorry, it looks like you don’t have access to core Ardoq. Worry not,
        though!
      </p>
      <p>
        {isDiscoverUser && (
          <>
            Looking for real-time contextual insights?
            <br />
            Access{' '}
            <Link hideIcon href="/discover">
              Ardoq Discover
            </Link>
            <br />
          </>
        )}
        Would you like to contribute your knowledge?
        <br />
        Access{' '}
        <Link hideIcon href="/surveys">
          Ardoq Surveys
        </Link>
      </p>
      <p>
        If you still need access to core Ardoq, please contact your
        administrator.
      </p>
    </AccessErrorWrapper>
  );
};

export const AccessErrorPresentation = ({ user }: AccessErrorProps) => {
  return (
    <AccessErrorWrapper header="No access to presentation" user={user}>
      <p>
        Sorry, it looks like you don&apos;t have access to this presentation. If
        you need access to the presentation, please contact your administrator.
      </p>
    </AccessErrorWrapper>
  );
};

export const NoOrgAccessErrorPresentation = () => {
  return (
    <AccessErrorWrapper header="No access to organization">
      <p>
        You don&apos;t have access to this organization. If you think you
        should, please contact the organization administrator.
      </p>
      <p>
        Alternatively, you can also contact{' '}
        <Link hideIcon href="mailto:support@ardoq.com">
          support@ardoq.com
        </Link>
        .
      </p>
      <p>
        <Link hideIcon href="https://app.ardoq.com">
          Take me back
        </Link>{' '}
        or{' '}
        <Link hideIcon href="/logout">
          log out
        </Link>
      </p>
    </AccessErrorWrapper>
  );
};

export const PresentationNotFound = () => {
  return (
    <AccessErrorWrapper header="Presentation not found">
      <p>Sorry, this presentation could not be found.</p>
    </AccessErrorWrapper>
  );
};
