import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { formatDateISO, parseDate } from '@ardoq/date-time';
import { IconName } from '@ardoq/icons';
import { PaginationBarProps } from '@ardoq/pagination';
import { dispatchAction } from '@ardoq/rxbeach';
import { openWorkspaces } from 'appContainer/DashboardContainer/utils';
import { setActiveMainTabLeft } from 'streams/views/mainContent/actions';
import {
  cancelDeletion,
  clearSelectedCheckboxes,
  closeActionsMenu,
  deleteSelectedWorkspaces,
  openActionsMenu,
  requestDeletion,
  searchQueryChange,
  selectAllWorkspaces,
  setGremlinForIds,
  setPage,
  shiftClickRow,
  sortOrderChange,
  toggleCheckbox,
  toggleTitleCheckbox,
} from './actions';
import {
  DeleteModalProps,
  WorkspaceOverviewManagementHeaderProps,
  WorkspaceOverviewManagementProps,
  WorkspaceOverviewManagementRowProps,
  WorkspaceOverviewManagementRowState,
  WorkspaceOverviewManagementState,
  WorkspaceOverviewManagementTableProps,
} from './types';
import { filterRows, getCurrentPage, getTableSelection } from './utils';

const navigateToTabView = (id: ArdoqId) => {
  dispatchAction(setActiveMainTabLeft({ activeTabId: ViewIds.PAGESVIEW }));
  openWorkspaces([id]);
};

export const toWorkspaceOverviewManagementProps = (
  state: WorkspaceOverviewManagementState
): WorkspaceOverviewManagementProps => {
  const currentRows = getCurrentPage(state);
  return {
    header: toHeaderProps(
      currentRows.every(row => row.selected),
      state.rows.length,
      state.searchQuery,
      state.rows.filter(row => row.selected)
    ),
    table: toTableProps(state),
    pagination: toPaginationProps(
      filterRows(state.rows, state.searchQuery).length,
      state.rowsPerPage,
      state.currentPageNumber
    ),
    deleteModal: toDeleteModalProps(state.rows),
  };
};

const toHeaderProps = (
  isAllSelected: boolean,
  totalNumberOfWorkspaces: number,
  searchQuery: string,
  selectedRows: WorkspaceOverviewManagementRowState[]
): WorkspaceOverviewManagementHeaderProps => {
  const areAnyGremlinEnabled = selectedRows.some(
    row => row.selected && row.gremlinEnabled
  );
  const workspaceIds = selectedRows.map(row => row.id);
  return {
    searchPlaceholder: 'Search for workspaces',
    showSelectAllWorkspaces:
      isAllSelected && totalNumberOfWorkspaces > selectedRows.length,
    totalNumberOfWorkspaces,
    searchQuery,
    numberOfSelectedWorkspaces: selectedRows.length,
    areAnyGremlinEnabled,
    selectAllWorkspaces: {
      text: `Select all ${totalNumberOfWorkspaces} workspaces`,
      onClick: () => dispatchAction(selectAllWorkspaces()),
    },
    labels: {
      gremlin: 'Gremlin',
      delete: `Delete ${selectedRows.length > 1 ? 'workspaces' : 'workspace'}`,
      cancel: 'Cancel',
    },
    onToggleAll: () =>
      dispatchAction(
        setGremlinForIds({
          workspaceIds,
          gremlinEnabled: !areAnyGremlinEnabled,
        })
      ),
    onMultiDelete: () => dispatchAction(requestDeletion(workspaceIds)),
    onClearSelection: () => dispatchAction(clearSelectedCheckboxes()),
    onSearchQueryChange: (newSearchQuery: string) =>
      dispatchAction(searchQueryChange(newSearchQuery)),
  };
};

const toTableProps = (
  state: WorkspaceOverviewManagementState
): WorkspaceOverviewManagementTableProps => {
  const currentRows = getCurrentPage(state);
  return {
    ...state,
    selection: getTableSelection(currentRows),
    columns: {
      name: {
        ...state.columns.name,
        onSortOrderChange: () => dispatchAction(sortOrderChange('name')),
      },
      componentCount: {
        ...state.columns.componentCount,
        onSortOrderChange: () =>
          dispatchAction(sortOrderChange('componentCount')),
      },
      referenceCount: {
        ...state.columns.referenceCount,
        onSortOrderChange: () =>
          dispatchAction(sortOrderChange('referenceCount')),
      },
      lastUpdatedAt: {
        ...state.columns.lastUpdatedAt,
        onSortOrderChange: () =>
          dispatchAction(sortOrderChange('lastUpdatedAt')),
      },
      gremlinEnabled: {
        ...state.columns.gremlinEnabled,
        onSortOrderChange: () =>
          dispatchAction(sortOrderChange('gremlinEnabled')),
      },
    },
    rows: toRowProps(currentRows),
    onToggleAllChange: () => dispatchAction(toggleTitleCheckbox()),
  };
};

const toRowProps = (
  rows: WorkspaceOverviewManagementRowState[]
): WorkspaceOverviewManagementRowProps[] => {
  return rows.map(row => {
    const unsortedRows = {
      ...row,
      lastUpdatedAt: row.lastUpdatedAt
        ? formatDateISO(parseDate(row.lastUpdatedAt))
        : '',
      actions: toRowActionsProps(row.id, row.isActionsOpen),
      onShiftClick: () => dispatchAction(shiftClickRow(row.id)),
      onNameClick: () => navigateToTabView(row.id),
      onSelectedChange: () => dispatchAction(toggleCheckbox(row.id)),
      onGremlinEnabledChange: () =>
        dispatchAction(
          setGremlinForIds({
            workspaceIds: [row.id],
            gremlinEnabled: !row.gremlinEnabled,
          })
        ),
    };
    return unsortedRows;
  });
};

const toRowActionsProps = (rowId: ArdoqId, isActionsOpen: boolean) => ({
  options: [
    {
      label: 'Open in pages view',
      iconName: IconName.OPEN_IN_NEW,
      onClick: () => navigateToTabView(rowId),
    },
    {
      label: 'Delete',
      iconName: IconName.DELETE,
      onClick: () => dispatchAction(requestDeletion([rowId])),
    },
  ],
  isOpen: isActionsOpen,
  onMenuOpen: () => dispatchAction(openActionsMenu(rowId)),
  onMenuClose: () => dispatchAction(closeActionsMenu(rowId)),
});

const toPaginationProps = (
  numberOfRows: number,
  rowsPerPage: number,
  currentPageNumber: number
): PaginationBarProps => {
  return {
    totalResults: numberOfRows,
    currentPageNumber: currentPageNumber,
    perPage: rowsPerPage,
    onChange: (selectedPage: {
      currentPageNumber: number;
      perPage: number;
    }) => {
      dispatchAction(
        setPage({
          pageNumber: selectedPage.currentPageNumber,
          pageSize: selectedPage.perPage,
        })
      );
    },
  };
};

const toDeleteModalProps = (
  rows: WorkspaceOverviewManagementRowState[]
): DeleteModalProps => {
  const deleteRequested = rows.filter(row => row.deleteRequested);
  if (deleteRequested.length === 0) {
    return {
      title: 'Delete workspaces',
      message: '',
      isOpen: false,
      onCancelClick: () => dispatchAction(cancelDeletion()),
      onConfirmClick: () => {},
    };
  }
  if (deleteRequested.length === 1) {
    return {
      title: 'Delete workspace',
      message: `Are you sure you want to delete the workspace "${deleteRequested[0].name}"?`,
      isOpen: true,
      onConfirmClick: () =>
        dispatchAction(deleteSelectedWorkspaces([deleteRequested[0].id])),
      onCancelClick: () => dispatchAction(cancelDeletion()),
    };
  }
  return {
    title: 'Delete workspaces',
    message: `Are you sure you want to delete the ${deleteRequested.length} selected workspaces?`,
    isOpen: true,
    onConfirmClick: () =>
      dispatchAction(
        deleteSelectedWorkspaces(deleteRequested.map(row => row.id))
      ),
    onCancelClick: () => dispatchAction(cancelDeletion()),
  };
};
