import { ArdoqEvent, ClientRequest, EventType, UserEvent } from '../sync/types';
import { ardoqEventOperations } from '../sync/ardoqEventOperations';
import { ResourceType } from '@ardoq/api-types';
import {
  ChatMessageContentEvent,
  ChatMessageDoneEvent,
  ChatMessageErrorEvent,
} from './types';

const isWebSocketChatMessage = (
  event: ArdoqEvent<unknown>
): event is UserEvent<ClientRequest<unknown>> =>
  ardoqEventOperations.isOfResourceType(event, ResourceType.CHAT);

export const isChatMessageContentEvent = (
  event: ArdoqEvent<unknown>
): event is UserEvent<ClientRequest<ChatMessageContentEvent>> =>
  isWebSocketChatMessage(event) &&
  event['event-type'] === EventType.MESSAGE_CONTENT;

export const isChatMessageDoneEvent = (
  event: ArdoqEvent<unknown>
): event is UserEvent<ClientRequest<ChatMessageDoneEvent>> =>
  isWebSocketChatMessage(event) &&
  event['event-type'] === EventType.MESSAGE_DONE;

export const isChatMessageErrorEvent = (
  event: ArdoqEvent<unknown>
): event is UserEvent<ClientRequest<ChatMessageErrorEvent>> =>
  isWebSocketChatMessage(event) &&
  event['event-type'] === EventType.MESSAGE_ERROR;
