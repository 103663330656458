import { action$, connect, subscribeRoutine } from '@ardoq/rxbeach';
import { SubdivisionProps } from './types';
import { Step, SubStep, VerticalStepper } from '@ardoq/steppers';
import { subdivisionEditorViewModel$ } from './subdivisionEditorViewModel$/subdivisionEditorViewModel$';
import { subdivisionEditorStepsOperations } from './subdivisionEditorStepsOperations';
import { SubdivisionEditorFooter } from './SubdivisionEditorFooter';
import { useEffect, useMemo } from 'react';
import { colors, s16 } from '@ardoq/design-tokens';
import { UnauthorizedAccessPage } from '@ardoq/manage-resource-permissions';
import { SubdivisionOverview } from './SubdivisionOverview/SubdivisionOverview';
import { SubdivisionPageHeader } from './SubdivisionPageHeader';

import {
  componentsToBeAddedStreamRoutine,
  componentsToAddRoutines,
} from './Steps/SelectComponents/streams/routines';
import {
  Island,
  PageBody,
  PageBodyFooter,
  PageWrapper,
} from '@ardoq/page-layout';
import { Box } from '@ardoq/layout';

const SubdivisionEditor = (props: SubdivisionProps) => {
  const {
    subdivisionEditorStep,
    subdivisionEditorSubStep,
    commands,
    missingSubdivision,
    isOrgAdmin,
    subdivisions,
    hasNewCoreJourneyFeature,
  } = props;

  useEffect(() => {
    const routines = subscribeRoutine(action$, componentsToAddRoutines);
    const streamRoutines = componentsToBeAddedStreamRoutine.subscribe();
    return () => {
      routines.unsubscribe();
      streamRoutines.unsubscribe();
    };
  }, []);

  const subdivisionConfig = subdivisionEditorStepsOperations.getStepConfigs(
    props,
    subdivisionEditorStep,
    subdivisionEditorSubStep
  );

  const Page = useMemo(() => {
    return subdivisionConfig?.component;
  }, [subdivisionConfig]);

  if (!isOrgAdmin) {
    return (
      <PageBody>
        <UnauthorizedAccessPage message="You can't access this page. Only admins can edit subdivisions" />
      </PageBody>
    );
  }

  if (missingSubdivision || !subdivisionConfig || !Page) {
    return (
      <SubdivisionOverview
        subdivisions={Object.values(subdivisions)}
        newCoreJourney={hasNewCoreJourneyFeature}
        commands={commands}
      />
    );
  }

  return (
    <PageWrapper>
      <SubdivisionPageHeader {...props} />
      <PageBody
        alignIslandsToLeft
        padding={s16}
        leftContent={
          <Box style={{ background: colors.bgDefault }}>
            <VerticalStepper>
              {subdivisionEditorStepsOperations
                .getSteps(props)
                .map(({ step, stepperHeading, subSteps }) => {
                  return (
                    <Step
                      isDisabled={subdivisionEditorStepsOperations.stepIsDisabled(
                        props,
                        step
                      )}
                      key={step}
                      expanded={subdivisionEditorStepsOperations.stepIsExpanded(
                        props,
                        step
                      )}
                      onExpand={expanded =>
                        commands.setStepExpanded(step, expanded)
                      }
                      onPress={() => commands.setStep(props, step)}
                      isSelected={
                        subdivisionEditorStep === step &&
                        !subdivisionEditorSubStep
                      }
                      heading={stepperHeading}
                      subheading={subdivisionEditorStepsOperations.getStepStateText(
                        props,
                        step
                      )}
                      state={subdivisionEditorStepsOperations.getStepState(
                        props,
                        step
                      )}
                    >
                      {subdivisionEditorStepsOperations
                        .getSubSteps(subSteps, props)
                        ?.map(subStep => (
                          <SubStep
                            key={subStep.step}
                            heading={subdivisionEditorStepsOperations.getSubStepHeadingForDisplay(
                              subStep
                            )}
                            state={subdivisionEditorStepsOperations.getStepState(
                              props,
                              step,
                              subStep.step
                            )}
                            onPress={() =>
                              commands.setStep(props, step, subStep.step)
                            }
                            isSelected={
                              subdivisionEditorSubStep === subStep.step
                            }
                          ></SubStep>
                        ))}
                    </Step>
                  );
                })}
            </VerticalStepper>
          </Box>
        }
        footerContent={
          <PageBodyFooter>
            <SubdivisionEditorFooter {...props}></SubdivisionEditorFooter>
          </PageBodyFooter>
        }
      >
        <Island
          title={subdivisionConfig.title}
          subtitle={subdivisionEditorStepsOperations.getStepSubtitle(props)}
          rightContent={subdivisionConfig.headerRightContent}
          maxWidth={subdivisionConfig.maxWidth}
          subtitleWidth="large"
        >
          <Page {...props}></Page>
        </Island>
      </PageBody>
    </PageWrapper>
  );
};

export default connect(SubdivisionEditor, subdivisionEditorViewModel$);
