import { GhostButton } from '@ardoq/button';
import { dispatchAction } from '@ardoq/rxbeach';
import { selectSearchPane } from './SearchTabContainer/actions';
import { SearchPane } from './SearchTabContainer/types';

const GoToOverviewButton = ({ searchPane }: { searchPane: SearchPane }) => (
  <GhostButton onClick={() => dispatchAction(selectSearchPane({ searchPane }))}>
    Back to overview
  </GhostButton>
);

export default GoToOverviewButton;
