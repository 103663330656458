import { actionCreator } from '@ardoq/rxbeach';
import {
  BroadcastAudience,
  BroadcastContent,
  APIAudiencePreviewResponse,
} from '@ardoq/api-types';

export const fetchAudiencePreview = actionCreator<{
  audiences: BroadcastAudience[];
  content: BroadcastContent;
}>('[audiencePreview] FETCH_AUDIENCE_PREVIEW');

export const fetchAudiencePreviewError = actionCreator(
  '[audiencePreview] FETCH_AUDIENCE_PREVIEW_ERROR'
);

export const setAudiencePreview = actionCreator<APIAudiencePreviewResponse>(
  '[audiencePreview] SET_AUDIENCE_PREVIEW'
);

export const notifyIncompleteContentSelection = actionCreator(
  '[audiencePreview] NOTIFY_INCOMPLETE_CONTENT_SELECTION'
);
