import { MouseEvent, RefAttributes, ForwardRefExoticComponent } from 'react';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { combineLatest, map } from 'rxjs';
import { IconName } from '@ardoq/icons';
import {
  ComponentWithDropdown,
  DropdownItem,
  DropdownOptionType,
} from '@ardoq/dropdown-menu';
import { DropdownMenuOption, DropdownSize } from '@ardoq/dropdown-menu-ui';
import { Stack } from '@ardoq/layout';
import Perspectives from 'collections/perspectives';
import GroupBys from 'collections/groupByCollection';
import dynamicFilters from 'filters/dynamicFilters';
import {
  PerspectiveResult,
  getPerspectivesData,
} from 'quickPerspectives/utils';
import perspectiveWindowManager from 'perspective/perspectiveEditor/perspectiveWindowManager';
import Filters from 'collections/filters';
import { clearPerspectiveEditor } from '../perspective/actions';
import activeFilter$ from 'streams/filters/activeFilter$';
import {
  PerspectiveMenuButton,
  PerspectiveMenuButtonProps,
} from './PerspectiveMenuButton';
import { UnsavedChangesTag } from './Atoms';
import loadPerspective from 'collections/helpers/loadPerspective';
import resetPerspectives from 'collections/helpers/resetPerspectives';

type MenuButtonProps = {
  onClick: (event: MouseEvent) => void;
  iconName: IconName;
  label: string;
};

/**
 * Describes the buttons that should be displayed based on current state
 */
export const getPerspectivesMenuButtons = (
  props: Pick<
    PerspectivesMenuProps,
    | 'actions'
    | 'hasSelectedPerspective'
    | 'isClearEnabled'
    | 'isSaveEnabled'
    | 'userHasPermissionToSavePerspective'
  >
): MenuButtonProps[] => {
  const {
    actions,
    hasSelectedPerspective,
    isClearEnabled,
    isSaveEnabled,
    userHasPermissionToSavePerspective,
  } = props;

  return [
    {
      label: 'Manage',
      iconName: IconName.SETTINGS,
      onClick: actions.openPerspectiveEditor,
      visible: true,
    },
    {
      label: `Save ${hasSelectedPerspective ? 'Changes' : 'Current'}`,
      iconName: IconName.SAVE,
      onClick: actions.openPerspectiveEditor,
      visible: isSaveEnabled && userHasPermissionToSavePerspective,
    },
    {
      label: 'Clear',
      iconName: IconName.CLOSE,
      onClick: actions.clearPerspectives,
      visible: isClearEnabled,
    },
  ].filter(button => button.visible);
};

/**
 * Transform a perspective to a DropdownItem
 */
export const perspectiveToDropdownItem = <
  T extends { name: string; isSelected: boolean; isCurrentlyModified: boolean },
>(
  perspective: T,
  actions: {
    onSelectPerspective: (perspective: T) => void;
  }
): DropdownItem => {
  return {
    label: perspective.name,
    isActive: perspective.isSelected,
    type: DropdownOptionType.OPTION,
    onClick: () => actions.onSelectPerspective(perspective),
    truncateLabel: true,
    children: perspective.isCurrentlyModified ? (
      <UnsavedChangesTag />
    ) : undefined,
  };
};

type PerspectivesMenuActions = {
  clearPerspectives: VoidFunction;
  resetPerspectives: VoidFunction;
  loadPerspective: (perspectiveId: string) => void;
  openPerspectiveEditor: (event: MouseEvent) => void;
  onSelectPerspective: (perspective: PerspectiveResult) => void;
};

export interface PerspectivesMenuProps {
  badgeCount: number;
  isScenarioMode: boolean;
  userHasPermissionToSavePerspective: boolean;
  isSaveEnabled: boolean;
  isClearEnabled: boolean;
  hasSelectedPerspective: boolean;
  dropdownOptions: DropdownItem[];
  actions: PerspectivesMenuActions;
  buttonComponent?: ForwardRefExoticComponent<
    PerspectiveMenuButtonProps & RefAttributes<HTMLButtonElement>
  >;
}

const PerspectivesMenu = (props: PerspectivesMenuProps) => {
  const { badgeCount, isScenarioMode, dropdownOptions } = props;
  const buttons = getPerspectivesMenuButtons(props);

  return (
    <ComponentWithDropdown
      options={dropdownOptions}
      autoFocusActiveOption={true}
      alignMenuTo="left"
      dropdownSize={DropdownSize.M}
      Component={props.buttonComponent ?? PerspectiveMenuButton}
      componentProps={{ isScenarioMode, badgeCount }}
      footerContent={
        <Stack gap="xsmall">
          {buttons.map((button, i) => (
            <DropdownMenuOption
              key={i}
              label={button.label}
              onClick={button.onClick}
              leftIconName={button.iconName}
            />
          ))}
        </Stack>
      }
    />
  );
};

const viewModel$ = combineLatest({
  // due to the impurities of the map function this stream is crucial to trigger
  // re-render when something relevant changes.
  activeFilters: activeFilter$,
}).pipe(
  map(({ activeFilters }) => {
    const clearPerspectives = () => {
      dispatchAction(clearPerspectiveEditor());
    };
    const loadPerspectiveId = (perspectiveId: string) => {
      loadPerspective(Perspectives.get(perspectiveId));
    };
    const openPerspectiveEditor = (event: MouseEvent) => {
      perspectiveWindowManager.open({
        event,
        focusPerspectiveName: false,
      });
    };
    const onSelectPerspective = (perspective: PerspectiveResult) => {
      if (perspective.isSelected) {
        clearPerspectives();
      } else {
        loadPerspectiveId(perspective.id);
      }
    };

    const perspectives = getPerspectivesData();
    const badgeCount =
      Filters.getTotalFilterCount() +
      GroupBys.length +
      dynamicFilters.dynamicFilters.length;

    const hasFilters = Filters.length > 0;
    const hasGroupBys = GroupBys.length > 0;
    const hasDynamicFilters = dynamicFilters.dynamicFilters.length > 0;
    const isCurrentPerspectiveModified = Perspectives.isCurrentSetModified();

    const hasSelectedPerspective = Boolean(activeFilters.selectedPerspectiveId);

    const hasFiltersOrGroupings =
      hasFilters || hasGroupBys || hasDynamicFilters;
    const isClearEnabled = hasFiltersOrGroupings;
    const isSaveEnabled = Boolean(
      (hasFilters || hasGroupBys) &&
        (isCurrentPerspectiveModified || !hasSelectedPerspective)
    );

    const actions = {
      clearPerspectives,
      resetPerspectives,
      loadPerspective: loadPerspectiveId,
      openPerspectiveEditor,
      onSelectPerspective,
    };

    const dropdownOptions = [
      { type: DropdownOptionType.HEADER, label: 'Perspectives' },
      ...perspectives.map(perspective => {
        return perspectiveToDropdownItem(perspective, actions);
      }),
    ];

    return {
      actions,
      badgeCount,
      dropdownOptions,
      hasSelectedPerspective,
      isSaveEnabled,
      isClearEnabled,
    };
  })
);

const ConnectedPerspectivesMenu = connect(PerspectivesMenu, viewModel$);
export default ConnectedPerspectivesMenu;
