import { colors } from '@ardoq/design-tokens';
import { Icon, IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';

const { MANAGE_MEMBERSHIP_MODAL } = SUBDIVISIONS_STRINGS;

export const SubdivisionsColumnHeader = () => (
  <FlexBox gap="xsmall" align="center">
    <Icon iconName={IconName.SSO} color={colors.grey50} />
    <span>{MANAGE_MEMBERSHIP_MODAL.TABLE.SUBDIVISIONS_COLUMN}</span>
  </FlexBox>
);
