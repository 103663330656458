import {
  isAnyTraversalLoadedState,
  LoadedState,
  TraversalLoadedState,
} from '@ardoq/api-types';

export const isSingleTraversalState = (
  loadedStates: LoadedState[]
): loadedStates is [TraversalLoadedState] =>
  loadedStates.length === 1 && isAnyTraversalLoadedState(loadedStates[0]);
