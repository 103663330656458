import { actionCreator } from '@ardoq/rxbeach';
import {
  APIDashboardAttributes,
  ArdoqId,
  RenameRequest,
} from '@ardoq/api-types';
import { ApiDashboardsFailurePayload, CopyDashboardPayload } from './types';

const namespacedActionCreator = <Payload = void>(name: string) =>
  actionCreator<Payload>(`[DASHBOARDS] ${name}`);

export const fetchAllDashboardsRequested = namespacedActionCreator<void>(
  'FETCH_ALL_DASHBOARDS_REQUESTED'
);

export const fetchAllDashboardsSucceeded = namespacedActionCreator<
  APIDashboardAttributes[]
>('FETCH_ALL_DASHBOARDS_SUCCEEDED');

export const fetchAllDashboardsFailed =
  namespacedActionCreator<ApiDashboardsFailurePayload>(
    'FETCH_ALL_DASHBOARDS_FAILED'
  );

export const dashboardDeleted =
  namespacedActionCreator<ArdoqId>('DASHBOARD_DELETED');

export const dashboardDeletedSuccessfully = namespacedActionCreator<ArdoqId>(
  'DASHBOARD_DELETED_SUCCESSFULLY'
);

export const bulkDeleteDashboard = namespacedActionCreator<ArdoqId[]>(
  'BULK_DELETE_DASHBOARD'
);

export const dashboardCopied =
  namespacedActionCreator<CopyDashboardPayload>('DASHBOARD_COPIED');

export const dashboardRenamed =
  namespacedActionCreator<RenameRequest>('DASHBOARD_RENAMED');
