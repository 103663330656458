import {
  ADD_TO_PRESENTATION,
  ExcludeFalsy,
  isArdoqError,
} from '@ardoq/common-helpers';
import {
  APIScenarioAttributes,
  ArdoqId,
  AssetType,
  OrgAccessLevel,
  PersistedMetamodel,
  PrivilegeLabel,
  ResourceType,
} from '@ardoq/api-types';
import assets$ from 'streams/assets/assets$';
import { dispatchAction } from '@ardoq/rxbeach';
import { scenarioNamespace } from 'streams/scenarios/actions';
import { DeleteType } from 'components/DeleteAssetModal/types';
import { Row } from 'components/EntityBrowser/types';
import {
  AssetRow,
  BookmarkRow,
  DashboardRow,
  ManagedWorkspaceRow,
  MetamodelRow,
  PresentationRow,
  ReportRow,
  ScenarioRow,
  SurveyRow,
  TraversalRow,
  ViewpointRow,
  WorkspaceRow,
} from 'components/AssetsBrowser/types';
import {
  openCopyPresentationModal,
  openDeletePresentationMenu,
} from 'presentationEditor/presentationUtil';
import { editMetamodel } from 'metamodel/utils';
import { confirmDeleteAsset } from 'components/DeleteAssetModal/DeleteAssetModal';
import workspaces from 'collections/workspaces';
import { deleteAssets } from '../utils';
import { openResourcePermissionDialog } from 'resourcePermissions/actions';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { logError } from '@ardoq/logging';
import { viewWorkspaceDigest } from 'streams/workspaceDigest/actions';
import { copyModal, openCopiedEntityModal } from 'copyComponent/copyModal';
import { confirmDashboardCopy } from 'copyComponent/copyDashboardModal';
import { copyWsModal, CopyWsModalResult } from 'copyWs/copyWsModal';
import { openWorkspace } from 'utils/workspace';
import { handleOpenResponseLog } from 'surveyAdmin/SurveyOverview/overviewHelpers';
import { apiFetchCurrentUserPermissions } from 'streams/currentUserPermissions/actions';
import {
  openDashboard,
  openReportInBuilder,
  openReportInReader,
} from 'components/AppMainSidebar/utils';
import {
  createDashboardSlide,
  createReportSlide,
} from 'presentationEditor/presentationChooser/actions';
import { ADD_TO_OPEN_PRESENTATION } from 'consts';
import { DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import { context$ } from 'streams/context/context$';
import {
  COPY_ARDOQ_REPORT_LABEL,
  COPY_DISCOVER_REPORT_LABEL,
  copyReportInArdoqURL,
  copyReportInDiscoverURL,
  ReportEventLocations,
  ReportTrackingEvents,
  trackCopyReportURL,
} from '@ardoq/report-reader';
import { firstValueFrom } from 'rxjs';
import { trackEvent } from '../../../tracking/tracking';
import { IconName } from '@ardoq/icons';
import { showToast, ToastType } from '@ardoq/status-ui';
import {
  DashboardEventLocations,
  getTrackCopyDashboardURLPayload,
} from '@ardoq/dashboard';
import { getFolderMenuOptions } from './folderMenuOptions';
import type {
  BookmarkMenuFnOptions,
  DashboardMenuFnOptions,
  MenuFnOptions,
  MetamodelMenuFnOptions,
  MultipleSelectionMenuOptions,
  PresentationMenuFnOptions,
  ReportMenuFnOptions,
  ScenarioMenuFnOptions,
  SurveyMenuFnOptions,
  TraversalMenuFnOptions,
  ViewpointMenuFnOptions,
  WorkspaceMenuFnOptions,
} from './types';
import { Features, hasFeature } from '@ardoq/features';
import { trackOpenChangeApprovalPage } from 'surveyAdmin/tracking';
import { navigateToChangeApprovalPage } from 'surveyAdmin/navigation/actions';
import { navigateToDashboardModule } from 'router/navigationActions';
import { openDeleteMultipleAssetsDialog } from './openDeleteMultipleAssetsDialog';
import { DashboardModule } from 'dashboard/types';
import { initiateCreatingNewBroadcastFromReport } from 'broadcasts/actions';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { dashboardAccessControlInterface } from 'resourcePermissions/accessControlHelpers/dashboards';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { surveyAccessControlInterface } from 'resourcePermissions/accessControlHelpers/surveys';
import { dashboardDeleted } from 'streams/dashboards/actions';
import { viewpointInterface } from '../../../modelInterface/viewpoints/viewpointInterface';
import {
  notifyCopyingViewpointSucceeded,
  viewpointDelete,
} from '../../../viewpoints/actions';
import {
  metamodelApi,
  reportApi,
  surveyApi,
  traversalApi,
  viewpointApi,
  workspaceApi,
} from '@ardoq/api';
import { shareReportSelected } from 'report/actions';
import { getBroadcastMenuOptions } from 'broadcasts/broadcastOverview/cellRenderers/getBroadcastMenuOptions';
import { RowType } from '@ardoq/table';
import { ButtonType } from '@ardoq/button';
import {
  assetOverviewMenuOption,
  copyMenuOption,
  deleteMenuOption,
  dividerMenuOption,
  editMenuOption,
  managePermissionsMenuOption,
  moveToMenuOption,
  openMenuOption,
  renameMenuOption,
} from './sharedMenuOptions';
import {
  apiDeleteMetamodel,
  apiFetchMetamodels,
} from 'streams/metamodels/metamodelActions';
import { exportWorkspaceToExcel } from 'menus/optionsMenu/workspace/actions';
import { reportNamespace } from 'streams/reports/actions';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  fetchAllModels,
  fetchAllModelsFailed,
  fetchAllModelsSucceeded,
} from 'streams/models/actions';
import { editViewpoint, traversalNamespace } from 'streams/traversals/actions';
import {
  deleteRequested,
  fetchAllFailed,
  fetchAllRequested,
  fetchAllSucceeded,
} from 'streams/crud/actions';
import { createReportUserEventTracking } from '@ardoq/report-builder';
import { setVisibleAsset } from 'assets/navigation/actions';
import { EVENT_NAMES as INTEGRATIONS_EVENT_NAMES } from 'integrations/common/tracking/events';
import { SupportedAssetTypes } from 'assets/types';
import { surveysNamespace } from 'streams/surveys/surveys$';
import { bookmarksNamespace } from 'streams/bookmarks/bookmarks$';
import { copyScenario } from 'services/scenarioApi';
import { hasPrivilege } from '@ardoq/privileges';
import { assetsBrowser2024Commands } from 'components/AssetsBrowser2024/assetsBrowser2024Commands';
import { omit } from 'lodash';
import { surveyInterface } from 'modelInterface/surveys/surveyInterface';
import { hideRightPane } from 'appContainer/actions';
import { handleDeleteAsset } from './handleDeleteAsset';
import { assetTypeToResourceType } from 'resourcePermissions/utils';

export const openCopyWorkspaceMenu = async (id: string, name: string) => {
  const modalResult: boolean | CopyWsModalResult = await copyWsModal(name);
  if (!modalResult) {
    return;
  }

  const response = await workspaceApi.copy(
    id,
    modalResult.newName,
    modalResult.copyComponents,
    modalResult.copyReferences,
    modalResult.copyCrossWorkspaceReferences
  );

  if (isArdoqError(response)) {
    logError(response);
    return;
  }

  const fetchModelsPromise = dispatchActionAndWaitForResponse(
    fetchAllModels(),
    fetchAllModelsSucceeded,
    fetchAllModelsFailed
  );

  await Promise.all([workspaces.collection.fetch(), fetchModelsPromise]);
  dispatchAction(apiFetchCurrentUserPermissions());
  const newWorkspace = response.data.workspaces[0];

  openCopiedEntityModal({
    entityType: 'workspace',
    copyName: modalResult.newName,
    copyId: newWorkspace.id,
    openCopy: () => {
      openWorkspace(newWorkspace.id, {
        trackingLocation: 'copyWorkspaceDialog',
      });
    },
  });
};

const openDeleteWorkspaceMenu = (
  workspaceRow: WorkspaceRow | ManagedWorkspaceRow,
  clearSelected: VoidFunction
) => {
  return handleDeleteAsset({
    assetType: AssetType.WORKSPACE,
    onDelete: () =>
      confirmDeleteAsset({
        deleteType: DeleteType.WORKSPACE,
        name: workspaceRow.name,
        id: workspaceRow._id,
        affectedSurveys: surveyInterface.getSurveysByWorkspaceId(
          workspaceRow._id
        ),
      }),
    onDeleteConfirmed: async () => {
      dispatchAction(hideRightPane());
      await workspaceApi.deleteWorkspaces([workspaceRow._id]);
    },
    clearSelected,
  });
};

const getWorkspaceMenuOptions = (
  workspaceRow: WorkspaceRow | ManagedWorkspaceRow,
  {
    rowPermissions,
    onRenameSelect,
    clearSelected,
    handleMoveToModal,
  }: WorkspaceMenuFnOptions
): DropdownOption[] => {
  return [
    assetOverviewMenuOption({
      asset: workspaceRow,
      onClick: () => {
        trackEvent(INTEGRATIONS_EVENT_NAMES.OPENED_ASSETS_OVERVIEW, {
          from: 'asset manager',
          assetType: 'workspace' as SupportedAssetTypes,
        });
        dispatchAction(
          setVisibleAsset({
            assetId: workspaceRow._id,
            assetType: 'workspace',
          })
        );
      },
    }),
    dividerMenuOption(),
    renameMenuOption({
      asset: workspaceRow,
      hasPermission: rowPermissions.canAdmin,
      onRenameSelect,
    }),
    moveToMenuOption({
      asset: workspaceRow,
      hasPermission: rowPermissions.canUpdate,
      handleMoveToModal,
    }),
    {
      label: 'View changes',
      name: 'changes',
      type: DropdownOptionType.OPTION,
      onClick: () =>
        dispatchAction(
          viewWorkspaceDigest({ workspaceId: workspaceRow._id, days: 7 })
        ),
    },
    {
      label: 'Export',
      name: 'export',
      type: DropdownOptionType.OPTION,
      onClick: () =>
        dispatchAction(
          exportWorkspaceToExcel({
            workspaceId: workspaceRow._id,
            filename: workspaceRow.name,
          })
        ),
    },
    managePermissionsMenuOption({
      asset: workspaceRow,
      hasPermission: rowPermissions.canManagePermissions,
      onClick: () => {
        dispatchAction(
          openResourcePermissionDialog({
            resources: [
              {
                resourceId: workspaceRow._id,
                resourceName:
                  workspaceInterface.getWorkspaceName(workspaceRow._id) ?? '',
                resourceType: ResourceType.WORKSPACE,
              },
            ],
            originPage: 'assets-manager',
          })
        );
      },
    }),
    copyMenuOption({
      assetType: AssetType.WORKSPACE,
      hasPermission: rowPermissions.canCopy,
      onCopy: () => openCopyWorkspaceMenu(workspaceRow._id, workspaceRow.name),
    }),
    dividerMenuOption(rowPermissions.canDelete),
    deleteMenuOption({
      asset: workspaceRow,
      hasPermission: rowPermissions.canDelete,
      onClick: () => openDeleteWorkspaceMenu(workspaceRow, clearSelected),
    }),
  ].filter(ExcludeFalsy);
};

const getPresentationMenuOptions = (
  presentationRow: PresentationRow,
  {
    onRenameSelect,
    clearSelected,
    rowPermissions,
    handleMoveToModal,
  }: PresentationMenuFnOptions
): DropdownOption[] => {
  return [
    renameMenuOption({
      asset: presentationRow,
      hasPermission: rowPermissions.canUpdate,
      onRenameSelect,
    }),
    editMenuOption({
      asset: presentationRow,
      hasPermission: rowPermissions.canUpdate,
      onEdit: () =>
        assetsBrowser2024Commands.openPresentationEditor(presentationRow._id),
    }),
    moveToMenuOption({
      asset: presentationRow,
      hasPermission: rowPermissions.canUpdate,
      handleMoveToModal,
    }),
    copyMenuOption({
      assetType: AssetType.PRESENTATION,
      hasPermission: rowPermissions.canCopy,
      onCopy: () => openCopyPresentationModal(presentationRow),
    }),
    dividerMenuOption(rowPermissions.canDelete),
    deleteMenuOption({
      asset: presentationRow,
      hasPermission: rowPermissions.canDelete,
      onClick: () => openDeletePresentationMenu(presentationRow, clearSelected),
    }),
  ].filter(ExcludeFalsy);
};

const getMultipleSelectionMenuOptions = (
  _rowId: string,
  {
    selected,
    selectedAssets,
    selectedFolders,
    clearSelected,
    selectionPermissions,
    handleMoveToModal,
  }: MultipleSelectionMenuOptions
) => {
  const selectedAll = [...selected, ...selectedFolders];
  return [
    handleMoveToModal && {
      label: `Move to (${selected.length})`,
      type: DropdownOptionType.OPTION,
      isDisabled: !selectionPermissions.canUpdate,
      onClick: () => {
        handleMoveToModal(selectedAssets);
        trackEvent('Assets Browser: clicked move multiple assets', {
          selectedAssets: selectedAssets.length,
        });
      },
    },
    selectionPermissions.canManagePermissions && {
      label: `Set Permissions (${selected.length})`,
      type: DropdownOptionType.OPTION,
      onClick: () => {
        dispatchAction(
          openResourcePermissionDialog({
            resources: selectedAssets.map(
              ({ name, _id, meta: { assetType } }) => {
                const rType = assetTypeToResourceType({ assetType });
                return {
                  resourceId: _id,
                  resourceName: name,
                  resourceType: rType,
                };
              }
            ),
            originPage: 'assets-manager',
          })
        );
        trackEvent(
          'Assets Browser: clicked set permission on multiple assets',
          {
            selectedAssets: selectedAssets.length,
          }
        );
      },
    },
    {
      label: `Delete selected (${selectedAll.length})`,
      type: DropdownOptionType.BUTTON_OPTION,
      buttonType: ButtonType.DANGER,
      isDisabled: !selectionPermissions.canDelete,
      onClick: async () => {
        const assets = await firstValueFrom(assets$);
        const selectedWorkspaces = selected
          .map(id => assets.workspaceAssetsById[id])
          .filter(ExcludeFalsy);
        const metamodels = selected
          .map(id => assets.metamodelsById[id])
          .filter(ExcludeFalsy);
        const surveys = selected
          .map(id => assets.surveysById[id])
          .filter(ExcludeFalsy);
        const presentations = selected
          .map(id => assets.presentationsById[id])
          .filter(ExcludeFalsy);
        const folders = selectedFolders
          .map(id => assets.assetFoldersById[id])
          .filter(ExcludeFalsy);
        const scenarios = selected
          .map(id => assets.scenariosById[id])
          .filter(ExcludeFalsy);
        const reports = selected
          .map(id => assets.reportsById[id])
          .filter(ExcludeFalsy);
        const dashboards = selected
          .map(id => assets.dashboardsById[id])
          .filter(ExcludeFalsy);
        const traversals = selected
          .map(id => assets.traversalsById[id])
          .filter(ExcludeFalsy);
        const viewpoints = selected
          .map(id => assets.viewpointsById[id])
          .filter(ExcludeFalsy);
        const broadcasts = selected
          .map(id => assets.broadcastsById[id])
          .filter(ExcludeFalsy);
        const bookmarks = selected
          .map(id => assets.bookmarksById[id])
          .filter(ExcludeFalsy);
        trackEvent('Assets Browser: clicked delete multiple assets', {
          selectedAssets: selectedAssets.length,
        });
        const approved = await openDeleteMultipleAssetsDialog({
          workspaces: selectedWorkspaces,
          surveys,
          presentations,
          metamodels,
          folders,
          scenarios,
          reports,
          dashboards,
          traversals,
          viewpoints,
          broadcasts,
          bookmarks,
        });

        if (approved) {
          deleteAssets([
            ...selectedWorkspaces,
            ...surveys,
            ...presentations,
            ...metamodels,
            ...scenarios,
            ...folders,
            ...reports,
            ...dashboards,
            ...traversals,
            ...viewpoints,
            ...broadcasts,
            ...bookmarks,
          ]);
          trackEvent('Assets Browser: confirmed delete multiple assets', {
            selectedAssets: selectedAssets.length,
          });
          clearSelected();
        }
      },
    },
  ].filter(ExcludeFalsy);
};

const openCopyMetamodelMenu = (metamodel: PersistedMetamodel) => {
  return copyModal({
    entityName: metamodel.name,
    entityType: AssetType.METAMODEL,
    copyEntity: async newName => {
      const copiedMetamodel = await metamodelApi.copy(metamodel, newName);
      if (isArdoqError(copiedMetamodel)) {
        // TODO[ARD-22935]: Perhaps there should be some error handling here
        logError(copiedMetamodel);
        return { _id: 'error', name: 'error' };
      }
      const { _id, name } = copiedMetamodel;
      dispatchAction(apiFetchMetamodels());
      dispatchAction(apiFetchCurrentUserPermissions());
      return { _id, name };
    },
    openCopy: copyId => assetsBrowser2024Commands.openMetaModel(copyId),
  });
};

const openDeleteMetamodelMenu = (
  metamodel: PersistedMetamodel,
  clearSelected: VoidFunction
) => {
  return handleDeleteAsset({
    assetType: AssetType.METAMODEL,
    onDelete: () =>
      confirmDeleteAsset({
        deleteType: DeleteType.METAMODEL,
        name: metamodel.name,
      }),
    onDeleteConfirmed: () => dispatchAction(apiDeleteMetamodel(metamodel._id)),
    clearSelected,
  });
};

const getMetamodelMenuOptions = (
  metamodel: MetamodelRow,
  {
    onRenameSelect,
    rowPermissions,
    handleMoveToModal,
    clearSelected,
  }: MetamodelMenuFnOptions
): DropdownOption[] => {
  return [
    renameMenuOption({
      asset: metamodel,
      hasPermission: rowPermissions.canUpdate && !metamodel.wholeOrg,
      onRenameSelect,
    }),
    editMenuOption({
      asset: metamodel,
      hasPermission: rowPermissions.canUpdate && !metamodel.wholeOrg,
      onEdit: () =>
        editMetamodel({
          metamodelId: metamodel._id,
          trackingLocation: 'assetsManager',
        }),
    }),
    moveToMenuOption({
      asset: metamodel,
      hasPermission: rowPermissions.canUpdate,
      handleMoveToModal,
    }),
    copyMenuOption({
      assetType: AssetType.METAMODEL,
      hasPermission: rowPermissions.canCopy && !metamodel.wholeOrg,
      onCopy: () => openCopyMetamodelMenu(metamodel),
    }),
    dividerMenuOption(rowPermissions.canDelete),
    deleteMenuOption({
      asset: metamodel,
      hasPermission: rowPermissions.canDelete,
      onClick: () => openDeleteMetamodelMenu(metamodel, clearSelected),
    }),
  ].filter(ExcludeFalsy);
};

const openCopyReportMenu = (reportRow: ReportRow) => {
  return copyModal({
    entityName: reportRow.name,
    entityType: AssetType.REPORT,
    copyEntity: async newName => {
      const copiedReport = await reportApi.copy(reportRow._id, newName);

      if (isArdoqError(copiedReport)) {
        logError(copiedReport);
        return { _id: 'error', name: 'error' };
      }
      const { _id, name } = copiedReport;
      await dispatchActionAndWaitForResponse(
        fetchAllRequested(reportNamespace),
        fetchAllSucceeded,
        fetchAllFailed,
        reportNamespace
      );
      dispatchAction(apiFetchCurrentUserPermissions());
      return { _id, name };
    },
    openCopy: copyId => assetsBrowser2024Commands.openReport(copyId),
  });
};

const openDeleteReportMenu = (
  reportRow: ReportRow,
  clearSelected: VoidFunction
) => {
  return handleDeleteAsset({
    assetType: AssetType.REPORT,
    onDelete: () =>
      confirmDeleteAsset({
        deleteType: DeleteType.REPORT,
        name: reportRow.name,
        id: reportRow._id,
      }),
    onDeleteConfirmed: () =>
      dispatchAction(deleteRequested(reportNamespace, reportRow._id)),
    clearSelected,
  });
};

const getReportMenuOptions = (
  reportRow: ReportRow,
  {
    handleMoveToModal,
    rowPermissions,
    clearSelected,
    onRenameSelect,
  }: ReportMenuFnOptions,
  isReportOverview?: boolean
): DropdownOption[] => {
  const trackUserEvent = createReportUserEventTracking(
    ReportEventLocations.ASSET_MANAGER,
    trackEvent
  );
  const trackingContext = isReportOverview
    ? ReportEventLocations.ASSET_MANAGER
    : ReportEventLocations.REPORT_OVERVIEW;
  const isReaderUser =
    currentUserInterface.getOrgCurrentUserRole() === OrgAccessLevel.READER;
  return [
    openMenuOption({
      asset: reportRow,
      onClick: () => {
        openReportInReader({ reportId: reportRow._id });
      },
    }),
    editMenuOption({
      asset: reportRow,
      hasPermission: rowPermissions.canUpdate,
      onEdit: () => openReportInBuilder({ reportId: reportRow._id }),
    }),
    hasFeature(Features.PERMISSION_ZONES) && rowPermissions.canManagePermissions
      ? {
          label: 'Share',
          type: DropdownOptionType.OPTION,
          onClick: () => {
            trackUserEvent(ReportTrackingEvents.CLICKED_OPEN_SHARE_MODAL, {
              from: trackingContext,
            });
            dispatchAction(shareReportSelected(reportRow._id));
          },
        }
      : managePermissionsMenuOption({
          asset: reportRow,
          hasPermission: rowPermissions.canManagePermissions,
          onClick: () => {
            dispatchAction(
              openResourcePermissionDialog({
                resources: [
                  {
                    resourceId: reportRow._id,
                    resourceName: reportRow.name,
                    resourceType: ResourceType.REPORT,
                  },
                ],
                originPage: 'assets-manager',
              })
            );
          },
        }),
    !isReaderUser && {
      label: context$.state.presentationId
        ? ADD_TO_OPEN_PRESENTATION
        : ADD_TO_PRESENTATION,
      type: DropdownOptionType.OPTION,
      onClick: () => {
        trackUserEvent(ReportTrackingEvents.CLICKED_ON_ADD_TO_PRESENTATION, {
          from: trackingContext,
        });
        dispatchAction(
          createReportSlide({
            reportId: reportRow._id,
            reportName: reportRow.name,
          })
        );
      },
    },
    moveToMenuOption({
      asset: reportRow,
      hasPermission: rowPermissions.canUpdate,
      handleMoveToModal,
    }),
    renameMenuOption({
      asset: reportRow,
      hasPermission: rowPermissions.canUpdate,
      onRenameSelect,
    }),
    copyMenuOption({
      assetType: AssetType.REPORT,
      onCopy: () => openCopyReportMenu(reportRow),
      hasPermission: rowPermissions.canCopy,
    }),
    currentUserInterface.currentUserIsOrgAdmin() && {
      label: 'Create broadcast',
      type: DropdownOptionType.OPTION,
      onClick: () => {
        trackUserEvent(ReportTrackingEvents.CREATE_BROADCAST_FROM_REPORT, {
          from: trackingContext,
        });
        dispatchAction(initiateCreatingNewBroadcastFromReport(reportRow._id));
      },
    },
    {
      label: COPY_ARDOQ_REPORT_LABEL,
      leftIconName: IconName.COPY,
      type: DropdownOptionType.OPTION,
      onClick: () => {
        copyReportInArdoqURL(reportRow._id);
        trackCopyReportURL(
          trackEvent,
          isReportOverview
            ? ReportEventLocations.REPORT_OVERVIEW
            : ReportEventLocations.ASSET_MANAGER,
          ReportEventLocations.ARDOQ
        );
      },
    },
    {
      label: COPY_DISCOVER_REPORT_LABEL,
      leftIconName: IconName.COPY,
      type: DropdownOptionType.OPTION,
      onClick: () => {
        copyReportInDiscoverURL(reportRow._id);
        trackCopyReportURL(
          trackEvent,
          isReportOverview
            ? ReportEventLocations.REPORT_OVERVIEW
            : ReportEventLocations.ASSET_MANAGER,
          ReportEventLocations.DISCOVER
        );
      },
    },
    dividerMenuOption(rowPermissions.canDelete),
    deleteMenuOption({
      asset: reportRow,
      hasPermission: rowPermissions.canDelete,
      onClick: () => openDeleteReportMenu(reportRow, clearSelected),
    }),
  ].filter(ExcludeFalsy);
};

const openCopyTraversalMenu = (traversalRow: TraversalRow) => {
  return copyModal({
    entityName: traversalRow.name,
    entityType: AssetType.TRAVERSAL,
    copyEntity: async newName => {
      const copiedTraversal = await traversalApi.copy(
        omit(traversalRow, ['meta', 'rowType']),
        newName
      );

      if (isArdoqError(copiedTraversal)) {
        logError(copiedTraversal);
        return { _id: 'error', name: 'error' };
      }
      const { _id, name } = copiedTraversal;
      return { _id, name };
    },
    openCopy: copyId => assetsBrowser2024Commands.openTraversal(copyId),
  });
};

const openDeleteTraversalMenu = (
  traversalRow: TraversalRow,
  clearSelected: VoidFunction
) => {
  return handleDeleteAsset({
    assetType: AssetType.TRAVERSAL,
    onDelete: () =>
      confirmDeleteAsset({
        deleteType: DeleteType.TRAVERSAL,
        name: traversalRow.name,
        id: traversalRow._id,
      }),
    onDeleteConfirmed: () =>
      dispatchAction(deleteRequested(traversalNamespace, traversalRow._id)),
    clearSelected,
  });
};

export const getTraversalMenuOptions = (
  traversal: TraversalRow,
  {
    handleMoveToModal,
    clearSelected,
    onRenameSelect,
    location,
    rowPermissions,
  }: TraversalMenuFnOptions
): DropdownOption[] => {
  if (
    !rowPermissions.canUpdate &&
    !rowPermissions.canCopy &&
    !rowPermissions.canDelete
  ) {
    return [];
  }

  return [
    managePermissionsMenuOption({
      asset: traversal,
      hasPermission: rowPermissions.canManagePermissions,
      location,
      onClick: () => {
        dispatchAction(
          openResourcePermissionDialog({
            headerText: `Manage Viewpoint Permissions for "${traversal.name}"`, // because they're called traversals in code
            resources: [
              {
                resourceId: traversal._id,
                resourceName: traversal.name,
                resourceType: ResourceType.TRAVERSAL,
              },
            ],
            originPage: 'assets-manager',
          })
        );
      },
    }),
    renameMenuOption({
      asset: traversal,
      hasPermission: rowPermissions.canUpdate,
      location,
      onRenameSelect,
    }),
    editMenuOption({
      asset: traversal,
      hasPermission: rowPermissions.canUpdate,
      location,
      onEdit: () => dispatchAction(editViewpoint(traversal._id)),
    }),
    copyMenuOption({
      assetType: AssetType.TRAVERSAL,
      hasPermission: rowPermissions.canCopy,
      location,
      onCopy: () => openCopyTraversalMenu(traversal),
    }),
    moveToMenuOption({
      asset: traversal,
      hasPermission: rowPermissions.canUpdate,
      location,
      handleMoveToModal,
    }),
    dividerMenuOption(rowPermissions.canDelete),
    deleteMenuOption({
      asset: traversal,
      hasPermission: rowPermissions.canDelete,
      location,
      onClick: () => openDeleteTraversalMenu(traversal, clearSelected),
    }),
  ].filter(ExcludeFalsy);
};

const openDeleteDashboardMenu = (
  dashboardRow: DashboardRow,
  clearSelected: VoidFunction
) => {
  return handleDeleteAsset({
    assetType: AssetType.DASHBOARD,
    onDelete: () =>
      confirmDeleteAsset({
        deleteType: DeleteType.DASHBOARD,
        name: dashboardRow.name,
      }),
    onDeleteConfirmed: () => dispatchAction(dashboardDeleted(dashboardRow._id)),
    clearSelected,
  });
};

const getDashboardMenuOptions = (
  dashboardRow: DashboardRow,
  { handleMoveToModal, clearSelected, onRenameSelect }: DashboardMenuFnOptions,
  menuLocation = DashboardEventLocations.ASSET_MANAGER
): DropdownOption[] => {
  return [
    openMenuOption({
      asset: dashboardRow,
      onClick: () => {
        openDashboard({ dashboardId: dashboardRow._id });
      },
    }),
    editMenuOption({
      asset: dashboardRow,
      hasPermission: dashboardRow.meta.permissions.canUpdate,
      onEdit: () =>
        dispatchAction(
          navigateToDashboardModule({
            selectedDashboardId: dashboardRow._id,
            dashboardModule: DashboardModule.BUILDER,
            loadFromCache: true,
          })
        ),
    }),
    managePermissionsMenuOption({
      asset: dashboardRow,
      hasPermission: dashboardRow.meta.permissions.canManagePermissions,
      onClick: () => {
        dispatchAction(
          openResourcePermissionDialog({
            resources: [
              {
                resourceId: dashboardRow._id,
                resourceName: dashboardRow.name,
                resourceType: ResourceType.DASHBOARD,
              },
            ],
            originPage: 'assets-manager',
          })
        );
      },
    }),
    dashboardRow.meta.permissions.canUpdate && {
      label: context$.state.presentationId
        ? ADD_TO_OPEN_PRESENTATION
        : ADD_TO_PRESENTATION,
      type: DropdownOptionType.OPTION,
      onClick: () =>
        dispatchAction(
          createDashboardSlide({
            dashboardId: dashboardRow._id,
            dashboardName: dashboardRow.name,
          })
        ),
    },
    renameMenuOption({
      asset: dashboardRow,
      hasPermission: dashboardRow.meta.permissions.canUpdate,
      onRenameSelect,
    }),
    moveToMenuOption({
      asset: dashboardRow,
      hasPermission: dashboardRow.meta.permissions.canUpdate,
      handleMoveToModal,
    }),
    copyMenuOption({
      assetType: AssetType.DASHBOARD,
      hasPermission: dashboardAccessControlInterface.canCopyDashboard(),
      onCopy: () =>
        confirmDashboardCopy({
          dashboardName: dashboardRow.name,
          dashboardId: dashboardRow._id,
        }),
    }),
    dashboardRow.meta.permissions.canRead && {
      label: 'Copy Discover dashboard URL',
      type: DropdownOptionType.OPTION,
      leftIconName: IconName.COPY,
      onClick: () => {
        trackEvent(...getTrackCopyDashboardURLPayload(menuLocation));
        navigator.clipboard.writeText(
          `${window.location.origin}/discover/dashboard/${dashboardRow._id}`
        );
        showToast('Link copied.', ToastType.SUCCESS);
      },
    },
    dividerMenuOption(dashboardRow.meta.permissions.canUpdate),
    deleteMenuOption({
      asset: dashboardRow,
      hasPermission: dashboardRow.meta.permissions.canUpdate,
      onClick: () => openDeleteDashboardMenu(dashboardRow, clearSelected),
    }),
  ].filter(ExcludeFalsy);
};

const openCopySurveyMenu = (surveyRow: SurveyRow) => {
  return copyModal({
    entityName: surveyRow.name,
    entityType: AssetType.SURVEY,
    copyEntity: async newName => {
      const copiedSurvey = await surveyApi.copy(surveyRow, newName);

      if (isArdoqError(copiedSurvey)) {
        logError(copiedSurvey);
        return { _id: 'error', name: 'error' };
      }
      const { _id, name } = copiedSurvey;
      dispatchAction(apiFetchCurrentUserPermissions());
      return { _id, name };
    },
    openCopy: copyId => assetsBrowser2024Commands.openSurveyEditor(copyId),
  });
};

const openDeleteSurveyMenu = (
  surveyRow: SurveyRow,
  clearSelected: VoidFunction
) => {
  return handleDeleteAsset({
    assetType: AssetType.SURVEY,
    onDelete: () =>
      confirmDeleteAsset({
        deleteType: DeleteType.SURVEY,
        name: surveyRow.name,
      }),
    onDeleteConfirmed: () =>
      dispatchAction(deleteRequested(surveysNamespace, surveyRow._id)),
    clearSelected,
  });
};

const getSurveyMenuOptions = (
  survey: SurveyRow,
  {
    onRenameSelect,
    rowPermissions,
    handleMoveToModal,
    surveysWithPendingApprovals,
    clearSelected,
  }: SurveyMenuFnOptions
): DropdownOption[] => {
  return [
    hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2) &&
      currentUserInterface.currentUserIsOrgAdmin() &&
      surveysWithPendingApprovals[survey._id] && {
        label: 'Response approval',
        onClick: () => {
          trackOpenChangeApprovalPage('Survey dropdown menu');
          dispatchAction(navigateToChangeApprovalPage(survey._id));
        },
        type: DropdownOptionType.OPTION,
        isDisabled: !surveysWithPendingApprovals[survey._id]?.total,
      },
    managePermissionsMenuOption({
      asset: survey,
      hasPermission: rowPermissions.canManagePermissions,
      onClick: () => {
        dispatchAction(
          openResourcePermissionDialog({
            resources: [
              {
                resourceId: survey._id,
                resourceName: survey.name,
                resourceType: ResourceType.SURVEY,
              },
            ],
            originPage: 'assets-manager',
          })
        );
      },
    }),
    currentUserInterface.currentUserIsOrgAdmin() && {
      label: 'Response log',
      onClick: () => handleOpenResponseLog(survey),
      type: DropdownOptionType.OPTION,
    },
    renameMenuOption({
      asset: survey,
      hasPermission: rowPermissions.canUpdate,
      onRenameSelect,
    }),
    moveToMenuOption({
      asset: survey,
      hasPermission: rowPermissions.canUpdate,
      handleMoveToModal,
    }),
    copyMenuOption({
      assetType: AssetType.SURVEY,
      hasPermission: surveyAccessControlInterface.canCopySurvey(survey._id),
      onCopy: () => openCopySurveyMenu(survey),
    }),
    dividerMenuOption(rowPermissions.canDelete),
    deleteMenuOption({
      asset: survey,
      hasPermission: rowPermissions.canDelete,
      onClick: () => openDeleteSurveyMenu(survey, clearSelected),
    }),
  ].filter(ExcludeFalsy);
};

type OpenCopyScenario = {
  scenario: APIScenarioAttributes;
  openCopy: (copyId: ArdoqId) => void;
};

export const openCopyScenarioMenu = ({
  scenario,
  openCopy,
}: OpenCopyScenario) => {
  return copyModal({
    entityName: scenario.name,
    entityType: AssetType.SCENARIO,
    copyEntity: async newName => {
      const copiedScenario = await copyScenario({
        scenarioId: scenario._id,
        scenarioCopyName: newName,
      });

      if (isArdoqError(copiedScenario)) {
        logError(copiedScenario);
        return { _id: 'error', name: 'error' };
      }
      const { _id, name } = copiedScenario;
      return { _id, name };
    },
    openCopy,
  });
};

const openDeleteScenarioMenu = (
  scenarioRow: ScenarioRow,
  clearSelected: VoidFunction
) => {
  return handleDeleteAsset({
    assetType: AssetType.SCENARIO,
    onDelete: () =>
      confirmDeleteAsset({
        deleteType: DeleteType.SCENARIO,
        name: scenarioRow.name,
        id: scenarioRow._id,
      }),
    onDeleteConfirmed: () =>
      dispatchAction(deleteRequested(scenarioNamespace, scenarioRow._id)),
    clearSelected,
  });
};

const getScenarioMenuOptions = (
  scenario: ScenarioRow,
  {
    onRenameSelect,
    rowPermissions,
    handleMoveToModal,
    clearSelected,
  }: ScenarioMenuFnOptions
): DropdownOption[] => {
  return [
    managePermissionsMenuOption({
      asset: scenario,
      hasPermission: rowPermissions.canManagePermissions,
      onClick: () => {
        dispatchAction(
          openResourcePermissionDialog({
            resources: [
              {
                resourceId: scenario._id,
                resourceName: scenario.name,
                resourceType: ResourceType.SCENARIO,
              },
            ],
            originPage: 'assets-manager',
          })
        );
      },
    }),
    renameMenuOption({
      asset: scenario,
      hasPermission: rowPermissions.canUpdate,
      onRenameSelect,
    }),
    copyMenuOption({
      assetType: AssetType.SCENARIO,
      hasPermission: scenarioAccessControlInterface.canCreateScenario(
        currentUserInterface.getPermissionContext()
      ),
      onCopy: () =>
        openCopyScenarioMenu({
          scenario,
          openCopy: copyId => assetsBrowser2024Commands.openScenario(copyId),
        }),
    }),
    moveToMenuOption({
      asset: scenario,
      hasPermission: rowPermissions.canUpdate,
      handleMoveToModal,
    }),
    dividerMenuOption(rowPermissions.canDelete),
    deleteMenuOption({
      asset: scenario,
      hasPermission: rowPermissions.canDelete,
      onClick: () => openDeleteScenarioMenu(scenario, clearSelected),
    }),
  ].filter(ExcludeFalsy);
};

export const getReportOverviewMenuOptions = (
  rowData: ReportRow,
  options: ReportMenuFnOptions
) => {
  if (options.selected.includes(rowData._id) && options.selected.length > 1) {
    return getMultipleSelectionMenuOptions(rowData._id, {
      ...options,
      selectedFolders: [],
    });
  }
  return getReportMenuOptions(rowData, options, true);
};

const openCopyViewpointMenu = (viewpointRow: ViewpointRow) => {
  return copyModal({
    entityName: viewpointRow.name,
    entityType: AssetType.VIEWPOINT,
    copyEntity: async newName => {
      const copiedViewpoint = await viewpointApi.copy(
        omit(viewpointRow, ['meta', 'rowType']),
        newName
      );
      if (isArdoqError(copiedViewpoint)) {
        logError(copiedViewpoint);
        return { _id: 'error', name: 'error' };
      }
      dispatchAction(notifyCopyingViewpointSucceeded(copiedViewpoint));
      const { _id, name } = copiedViewpoint;
      return { _id, name };
    },
    openCopy: copyId => assetsBrowser2024Commands.openDiscoverViewpoint(copyId),
  });
};

const openDeleteViewpointMenu = (
  viewpointRow: ViewpointRow,
  clearSelected: VoidFunction
) => {
  return handleDeleteAsset({
    assetType: AssetType.VIEWPOINT,
    onDelete: () =>
      confirmDeleteAsset({
        deleteType: DeleteType.VIEWPOINT,
        name: viewpointRow.name,
        affectedBroadcasts: viewpointInterface.runningBroadcastsUsingViewpoint(
          viewpointRow._id
        ),
      }),
    onDeleteConfirmed: () => dispatchAction(viewpointDelete(viewpointRow)),
    clearSelected,
  });
};

const getViewpointsMenuOptions = (
  viewpoint: ViewpointRow,
  {
    handleMoveToModal,
    clearSelected,
    rowPermissions,
    onRenameSelect,
  }: ViewpointMenuFnOptions
): DropdownOption[] => {
  if (
    !rowPermissions.canUpdate &&
    !rowPermissions.canCopy &&
    !rowPermissions.canDelete
  ) {
    return [];
  }

  return [
    managePermissionsMenuOption({
      asset: viewpoint,
      hasPermission: rowPermissions.canManagePermissions,
      onClick: () => {
        dispatchAction(
          openResourcePermissionDialog({
            resources: [
              {
                resourceId: viewpoint._id,
                resourceName: viewpoint.name,
                resourceType: ResourceType.VIEWPOINT,
              },
            ],
            originPage: 'assets-manager',
          })
        );
      },
    }),
    renameMenuOption({
      asset: viewpoint,
      hasPermission: rowPermissions.canUpdate,
      onRenameSelect,
    }),
    editMenuOption({
      asset: viewpoint,
      hasPermission: rowPermissions.canUpdate,
      onEdit: () =>
        assetsBrowser2024Commands.openDiscoverViewpoint(viewpoint._id),
    }),
    copyMenuOption({
      assetType: AssetType.VIEWPOINT,
      hasPermission:
        rowPermissions.canCopy && hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER),
      onCopy: () => openCopyViewpointMenu(viewpoint),
    }),
    moveToMenuOption({
      asset: viewpoint,
      hasPermission: rowPermissions.canUpdate,
      handleMoveToModal,
    }),
    dividerMenuOption(rowPermissions.canDelete),
    deleteMenuOption({
      asset: viewpoint,
      hasPermission: rowPermissions.canDelete,
      onClick: () => openDeleteViewpointMenu(viewpoint, clearSelected),
    }),
  ].filter(ExcludeFalsy);
};

const openDeleteBookmarkMenu = (
  bookmark: BookmarkRow,
  clearSelected: VoidFunction
) => {
  return handleDeleteAsset({
    assetType: AssetType.BOOKMARK,
    onDelete: () =>
      confirmDeleteAsset({
        deleteType: DeleteType.BOOKMARK,
        name: bookmark.name,
        id: bookmark._id,
      }),
    onDeleteConfirmed: () =>
      dispatchAction(deleteRequested(bookmarksNamespace, bookmark._id)),
    clearSelected,
  });
};

const getBookmarkMenuOptions = (
  bookmark: BookmarkRow,
  { onRenameSelect, handleMoveToModal, clearSelected }: BookmarkMenuFnOptions
): DropdownOption[] => {
  return [
    renameMenuOption({
      asset: bookmark,
      hasPermission: true, // TODO CL is `true` fine here, as bookmarks will always be personal with no sharing?
      onRenameSelect,
    }),
    moveToMenuOption({
      asset: bookmark,
      hasPermission: true,
      handleMoveToModal,
    }),
    dividerMenuOption(),
    deleteMenuOption({
      asset: bookmark,
      hasPermission: true,
      onClick: () => openDeleteBookmarkMenu(bookmark, clearSelected),
    }),
  ].filter(ExcludeFalsy);
};

export const getMenuOptions = (
  rowData: Row<AssetRow>,
  options: MenuFnOptions
): DropdownOption[] => {
  if (rowData.rowType === RowType.EMPTY_FOLDER) return [];
  const isSelected =
    options.selected.includes(rowData._id) ||
    options.selectedFolders.includes(rowData._id);
  const hasSelectedMultiple =
    options.selected.length + options.selectedFolders.length > 1;

  if (isSelected && hasSelectedMultiple)
    return getMultipleSelectionMenuOptions(rowData._id, options);
  if (
    rowData.rowType === RowType.WORKSPACE ||
    rowData.rowType === RowType.MANAGED_WORKSPACE
  )
    return getWorkspaceMenuOptions(rowData, options);
  if (rowData.rowType === RowType.FOLDER)
    return getFolderMenuOptions(rowData, options);
  if (rowData.rowType === RowType.PRESENTATION)
    return getPresentationMenuOptions(rowData, options);
  if (rowData.rowType === RowType.SURVEY)
    return getSurveyMenuOptions(rowData, options);
  if (rowData.rowType === RowType.SCENARIO)
    return getScenarioMenuOptions(rowData, options);
  if (rowData.rowType === RowType.METAMODEL)
    return getMetamodelMenuOptions(rowData, options);
  if (rowData.rowType === RowType.DASHBOARD)
    return getDashboardMenuOptions(rowData, options);
  if (rowData.rowType === RowType.REPORT)
    return getReportMenuOptions(rowData, options);
  if (rowData.rowType === RowType.TRAVERSAL) {
    return getTraversalMenuOptions(rowData, options);
  }
  if (rowData.rowType === RowType.VIEWPOINT) {
    return getViewpointsMenuOptions(rowData, options);
  }
  if (rowData.rowType === RowType.BROADCAST) {
    return getBroadcastMenuOptions(rowData, options);
  }
  if (rowData.rowType === RowType.BOOKMARK) {
    return getBookmarkMenuOptions(rowData, options);
  }
  return [];
};
