import type { SelectOption } from '@ardoq/select';
import {
  ArdoqId,
  LoginOptionWithEnabledFlag,
  MappingRule,
  PermissionGroup,
  SSOMappingCondition,
} from '@ardoq/api-types';
import { SSOAttributeMappingCommands } from './commands';

export type SSOAttributeMappingState = {
  initialMapping: MappingRule[];
  currentMapping: RulesById;
  showValidationErrors: boolean;
  mappingPreview: {
    preview: Preview[];
    leaveGroups: string[];
  };
  currentClaims: Claims;
  groups: PermissionGroup[];
  attributeTypeOptions: SelectOption<string>[];
  conditionOptions: ConditionOptionsByType;
  lastUpdated: Date | null;
  isLoggedInWithSSO: boolean;
  loginOptions: LoginOptionWithEnabledFlag[];
  allowMultipleSsoOptionsInMappings: boolean;
  isLoading: boolean;
  ssoSelectedForPreview: SsoOption | null;
};

export type MappingRulesTableProps = {
  currentMapping: RulesById;
  attributeTypeOptions: SelectOption<string>[];
  conditionOptionsByAttribute: ConditionOptionByAttribute;
  groupOptions: SelectOption<ArdoqId>[];
  ssoOptions: SsoOption[];
  allowMultipleSsoOptionsInMappings: boolean;
  showValidationErrors: boolean;
  mappedGroupsCount: Record<string, number>;
  changes: RulesById;
  commands: SSOAttributeMappingCommands;
};

export type MappingPreviewTableProps = {
  isLoggedInWithSSO: boolean;
  mappingPreview: {
    preview: Preview[];
    leaveGroups: string[];
  };
};

export type GriddedTableRowProps = {
  $hideBorder?: boolean;
  $showSsoProviderColumn?: boolean;
};

export type RulesById = Record<ArdoqId, MappingRule>;

export type AttributeValue = string | string[] | number | null | undefined;

export type Claims = Record<string, AttributeValue>;

export enum PreviewAttributeType {
  ARRAY = 'array',
  STRING = 'string',
  NULL = 'null',
  UNKNOWN = 'unknown',
}

export type AttributeValueInPreview = string[] | number[] | null | undefined;

type PreviewAttribute = {
  name: { name: string; unmapped: boolean };
  type: PreviewAttributeType;
  values: { values: AttributeValueInPreview; unmapped: boolean };
};

type Preview = {
  attribute: PreviewAttribute;
  joinGroups: {
    joinGroups: string[];
    unmapped: boolean;
    notApplicable: boolean;
  };
};

export type SSOAttributeMappingProps = {
  currentMapping: RulesById;
  changes: RulesById;
  isValidChanges: boolean;
  disableSave: boolean;
  lastUpdated: Date | null;
  ssoOptions: SsoOption[];
  numberOfConfiguredSso: number;
  allowMultipleSsoOptionsInMappings: boolean;
  noGroupsConfigured: boolean;
  mappingPreview: {
    preview: Preview[];
    leaveGroups: string[];
  };
  deletions: ArdoqId[];
  isLoggedInWithSSO: boolean;
  enablePreviewButton: boolean;
  isLoading: boolean;
  ssoSelectedForPreview: SsoOption | null;
  commands: SSOAttributeMappingCommands;
};

export type CurrentMappingUpdate = {
  path: string;
  value: string | number;
};

export type ConditionOption = SelectOption<SSOMappingCondition>;

export type ConditionOptionsByType = Record<
  PreviewAttributeType,
  ConditionOption[]
>;

export type ConditionOptionByAttribute = Record<string, ConditionOption[]>;

export type GetGroupName = (groupId: string) => string;

export type SsoOption = SelectOption<string>;
