import enhancedScopeDiffData$ from 'scope/enhancedScopeDiffData$';
import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import { combineLatest } from 'rxjs';
import {
  APIDiffBranchToBranch,
  APIEntityType,
  APIOrganizationUser,
  ArdoqId,
} from '@ardoq/api-types';
import { BasicTable } from '@ardoq/table';
import {
  EnhancedScopeDataWithBranchName,
  FieldType,
  RenderOption,
  RenderOptions,
  Renderer,
  graphics,
} from '@ardoq/renderers';
import { map } from 'rxjs/operators';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';

const renderOptions: RenderOptions = {
  definitions: {
    [RenderOption.TEXT_ELLIPSIS]: {
      maxLength: 180,
    },
  },
  types: {
    [FieldType.DESCRIPTION]: new Set([RenderOption.TEXT_ELLIPSIS]),
  },
};

const { Table, Td, Tbody, Th, Thead } = BasicTable;

const StyledTh = styled(Th)`
  background: silver;

  &:hover {
    background: silver;
  }
`;

type ChangedPopoverContentProps = {
  entityId: ArdoqId;
  main: EnhancedScopeDataWithBranchName | null;
  branch: EnhancedScopeDataWithBranchName | null;
  branchOff: EnhancedScopeDataWithBranchName | null;
  diffData: APIDiffBranchToBranch | null;
  users: Record<ArdoqId, APIOrganizationUser>;
};

const ChangedPopoverContent = ({
  entityId,
  main,
  branch,
  branchOff,
  diffData,
  users,
}: ChangedPopoverContentProps) => {
  if (!(diffData && main && branch && branchOff)) {
    return null;
  }

  const [collection, entityType, headerFieldName] =
    componentInterface.isComponent(entityId)
      ? [diffData.components, APIEntityType.COMPONENT, 'name']
      : [diffData.references, APIEntityType.REFERENCE, 'type'];

  const diff = collection.update?.[entityId];

  if (!diff) {
    return null;
  }

  return (
    <div>
      <Table>
        <Thead>
          <tr>
            <StyledTh colSpan={4}>
              <Renderer
                entityType={entityType}
                entityId={entityId}
                fieldName={headerFieldName}
                enhancedScopeData={branch}
                users={users}
                graphics={graphics}
                options={renderOptions}
              />
            </StyledTh>
          </tr>
        </Thead>
        <Tbody>
          <tr>
            <Td></Td>
            <Td>Original value</Td>
            <Td>Mainline value</Td>
            <Td>Scenario value</Td>
          </tr>
          {Object.keys(diff).map(fieldName => (
            <tr key={fieldName}>
              <Td>{fieldName}</Td>
              <Td>
                <Renderer
                  entityType={entityType}
                  entityId={entityId}
                  fieldName={fieldName}
                  enhancedScopeData={branchOff}
                  users={users}
                  graphics={graphics}
                  options={renderOptions}
                />
              </Td>
              <Td>
                <Renderer
                  entityType={entityType}
                  entityId={entityId}
                  fieldName={fieldName}
                  enhancedScopeData={main}
                  users={users}
                  graphics={graphics}
                  options={renderOptions}
                />
              </Td>
              <Td>
                <Renderer
                  entityType={entityType}
                  entityId={entityId}
                  fieldName={fieldName}
                  enhancedScopeData={branch}
                  users={users}
                  graphics={graphics}
                  options={renderOptions}
                />
              </Td>
            </tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

const ChangePopoverStream$ = combineLatest([
  orgUsers$,
  enhancedScopeDiffData$,
]).pipe(
  map(([{ byId: users }, { diffData, main, branch, branchOff }]) => ({
    users,
    diffData,
    main,
    branch,
    branchOff,
  }))
);

export default connect(ChangedPopoverContent, ChangePopoverStream$);
