import type { ContextShape } from '@ardoq/data-model';
import { NumericSortOrder } from '@ardoq/common-helpers';

export const EMPTY_CONTEXT_SHAPE: ContextShape = {
  referenceId: '',
  componentId: '',
  workspaceId: '',
  modelId: '',
  scenarioId: '',
  presentationId: '',
  workspacesIds: [],
  isExploreMode: false,
  sort: { attr: '', order: NumericSortOrder.ASC },
  connectedWorkspaceIds: [],
};
