import { actionCreator } from '@ardoq/rxbeach';
import { UpdateFeatureValueActionPayload } from './types';

const DOMAIN = '[FEATURE_SETTINGS]';

export const updateFeatureValue =
  actionCreator<UpdateFeatureValueActionPayload>(
    `${DOMAIN} UPDATE_FEATURE_VALUE`
  );

export const updateFeatureValueSuccess = actionCreator(
  `${DOMAIN} UPDATE_FEATURE_VALUE_SUCCESS`
);
export const updateFeatureValueFailed = actionCreator(
  `${DOMAIN} UPDATE_FEATURE_VALUE_FAILED`
);
