import PresentationCard from './PresentationCard';
import {
  ArdoqId,
  AssetType,
  Meta,
  APIPresentationAssetAttributes,
} from '@ardoq/api-types';
import styled from 'styled-components';
import { FILTER_BAR_HEIGHT } from 'components/AssetsBrowser2024/FilterBar/FilterBar';
import { FlexBox } from '@ardoq/layout';

const SetHeightFlexBox = styled(FlexBox)`
  height: calc(100% - ${FILTER_BAR_HEIGHT}px);
  overflow-y: auto;
`;

interface PresentationGridProperties {
  presentations: (APIPresentationAssetAttributes &
    Meta<AssetType.PRESENTATION>)[];
  onStarClick: (presentationId: ArdoqId) => void;
  onCardClick: (presentationId: ArdoqId) => void;
  onPlayButtonClick: (presentationId: ArdoqId) => void;
  onEditButtonClick: (presentationId: ArdoqId) => void;
}
const PresentationGrid = ({
  presentations,
  onStarClick,
  onCardClick,
  onPlayButtonClick,
  onEditButtonClick,
}: PresentationGridProperties) => {
  return (
    <SetHeightFlexBox gap="large" flexWrap padding="large">
      {presentations.map(presentation => (
        <PresentationCard
          key={presentation._id}
          presentation={presentation}
          onStarClick={onStarClick}
          onCardClick={onCardClick}
          onPlayButtonClick={onPlayButtonClick}
          onEditButtonClick={onEditButtonClick}
        />
      ))}
    </SetHeightFlexBox>
  );
};

export default PresentationGrid;
