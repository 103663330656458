import { DoqType, DoqWithSideContent } from '@ardoq/doq';
import { ViewpointBuilderAdvancedSearchState } from 'viewpointBuilder/selectContextTab/types';
import { FlexBox } from '@ardoq/layout';

type EmptyStateProps = {
  hasResults: boolean;
  advancedSearchState: ViewpointBuilderAdvancedSearchState;
};

export const AdvancedSearchEmptyStates = ({
  hasResults,
  advancedSearchState,
}: EmptyStateProps) => {
  return advancedSearchState ? (
    <FlexBox justify="center" align="center" flex={1}>
      {advancedSearchState.status === 'invalid' && <InvalidQuery />}
      {advancedSearchState.status === 'empty' && <NoQuery />}
      {advancedSearchState.status === 'valid' && !hasResults && <NoResult />}
    </FlexBox>
  ) : null;
};

const NoQuery = () => {
  return (
    <DoqWithSideContent
      doqType={DoqType.WAITING}
      title="Find components with advanced search"
    >
      <p>
        Add rules to find specific components for your dataset. The rules can be
        based on different component properties like who created it or its field
        values.
      </p>
    </DoqWithSideContent>
  );
};

const InvalidQuery = () => {
  return (
    <DoqWithSideContent
      doqType={DoqType.MISSING_ORG}
      title="There is an incomplete rule or subquery"
    >
      <p>
        A rule or subquery you added isn’t completely filled. Check and fill all
        the fields to search for components.
      </p>
    </DoqWithSideContent>
  );
};

const NoResult = () => {
  return (
    <DoqWithSideContent doqType={DoqType.EMPTY_BOX} title="No results found">
      <p>
        There is no component that matches the rules you added. Try a different
        search or adjust your rules.
      </p>
    </DoqWithSideContent>
  );
};
