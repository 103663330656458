import { Icon, IconName, IconSize } from '@ardoq/icons';
import styled from 'styled-components';
import { AppliedSettingsRow } from './AppliedSettingsRow';
import { Tag } from '@ardoq/status-ui';
import { ComponentAndReferenceTypesSettings } from './types';
import { WithPopover } from '@ardoq/popovers';
import { colors } from '@ardoq/design-tokens';
import { Space } from '@ardoq/style-helpers';
import { pluralize } from '@ardoq/common-helpers';
import { tagStyles } from './atoms';

const TagWrapper = styled(Tag)`
  ${tagStyles}
`;

type Entity = 'component' | 'reference';

const EntityTag = ({
  entity,
  count,
  iconName,
}: {
  entity: Entity;
  count: number;
  iconName: IconName;
}) => {
  const PopoverContent = () => (
    <Space $align="center" $gap="s4">
      <Icon
        iconName={iconName}
        iconSize={IconSize.SMALL}
        color={colors.grey50}
      />
      <div>
        {count} {entity} {pluralize('type', count)}
      </div>
    </Space>
  );

  return (
    <WithPopover key={entity} content={PopoverContent}>
      <TagWrapper iconName={iconName} label={count.toString()} key={entity} />
    </WithPopover>
  );
};

type Props = {
  settings: ComponentAndReferenceTypesSettings;
};
export const ComponentAndReferenceTypes = ({ settings }: Props) => {
  const { componentTypesCount, referenceTypesCount, onEditClick } = settings;

  const tags = [
    EntityTag({
      entity: 'component',
      count: componentTypesCount,
      iconName: IconName.COMPONENT,
    }),
    EntityTag({
      entity: 'reference',
      count: referenceTypesCount,
      iconName: IconName.REFERENCE,
    }),
  ];

  return (
    <AppliedSettingsRow
      label="Component and reference types"
      tags={tags}
      icon={IconName.ACCOUNT_TREE}
      onEditClick={onEditClick}
    />
  );
};
