import {
  APIOrganizationAttributes,
  PermissionAccessLevel,
} from '@ardoq/api-types';
import { resourcePermissionsModelInterface } from 'resourcePermissions/resourcePermissionsModelInterface';
import { Form, Formik } from 'formik';
import {
  getSubmitOrgSettings,
  mapOrgToFormikInitialValues,
} from 'admin/orgSettings/formikUtils';
import { PrimaryButton } from '@ardoq/button';
import { Checkbox, Label, TextInput } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import { s24 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { FooterWrapper } from '../manageOrganization/atoms';
import { Features, hasFeature } from '@ardoq/features';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import { trackEvent } from 'tracking/tracking';
import FileSaver from 'file-saverjs';
import { permissionApi } from '@ardoq/api';
import { setError } from '../accessControl/PermissionGroups/streams/actions';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import { Island } from '@ardoq/page-layout';
import { orgSettingsViewModel$ } from './orgSettingsViewModel$';

const Wrapper = styled.div`
  gap: ${s24};
  display: grid;
`;

const FormRow = styled.div`
  width: 448px;
`;

const StatusWrapper = styled.div`
  display: block;
  margin-top: ${s24};
`;

const exportPermissionsToReadableExcel = async () => {
  const exportResult = await permissionApi.exportPermissions();
  if (isArdoqError(exportResult)) {
    dispatchAction(setError(getArdoqErrorMessage(exportResult)));
    return;
  }

  FileSaver(
    new Blob([exportResult], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
    `permission_groups.xlsx`
  );
};

export type OrgSettingsProps = {
  organization: APIOrganizationAttributes;
};

const OrgSettings = ({ organization }: OrgSettingsProps) => {
  const wsPermissionOptions = resourcePermissionsModelInterface
    .getDefaultWorkspacePermissionOptions()
    .map(({ text, value }) => ({
      value: value ?? '',
      label: text,
    }));
  return (
    <Formik
      initialValues={mapOrgToFormikInitialValues(organization)}
      onSubmit={getSubmitOrgSettings(organization)}
    >
      {({
        initialValues,
        handleChange,
        values,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        status,
      }) => (
        <>
          <Island
            title="Settings"
            subtitle="Manage details about your organization."
            footerContent={
              <FooterWrapper>
                <PrimaryButton
                  onClick={() => handleSubmit()}
                  type="submit"
                  isDisabled={isSubmitting || !values.organizationName}
                >
                  Update settings
                </PrimaryButton>
              </FooterWrapper>
            }
          >
            <Form onSubmit={handleSubmit}>
              <Wrapper>
                <FormRow>
                  <Label>Id</Label>
                  <TextInput
                    id="id"
                    name="id"
                    value={organization._id}
                    isReadOnly
                  />
                </FormRow>
                <FormRow>
                  <Label>Label</Label>
                  <TextInput
                    id="label"
                    name="label"
                    isReadOnly
                    value={organization.label}
                  />
                </FormRow>
                <FormRow>
                  <Label>Database</Label>
                  <TextInput
                    id="database"
                    name="database"
                    isReadOnly
                    value={organization.db.name}
                  />
                </FormRow>
                <FormRow>
                  <Label>Name</Label>
                  <TextInput
                    id="organizationName"
                    name="organizationName"
                    onChange={handleChange}
                    errorMessage={
                      !values.organizationName
                        ? 'An organization name is required'
                        : undefined
                    }
                    value={values.organizationName}
                  />
                </FormRow>
                <FormRow>
                  <Label>Add your organization&apos;s logo</Label>
                  <TextInput
                    id="logo"
                    name="settings.logo"
                    onChange={handleChange}
                    placeholder="URL to image"
                    warningMessage={
                      initialValues.settings.logo &&
                      initialValues.settings.logo !== values.settings.logo
                        ? 'Your changes will impact the surveys and broadcasts using the logo, as well as Ardoq Discover'
                        : undefined
                    }
                    value={values.settings.logo}
                    helperText="Your logo will be shown in survey headers, broadcast messages and in Ardoq Discover.
              Tip: 80px by 250px with a transparent background works best."
                  />
                </FormRow>
                <FormRow>
                  <Label>Emergency data security contacts</Label>
                  <TextInput
                    id="emergencyDataSecurityContacts"
                    name="settings.emergencyDataSecurityContacts"
                    onChange={handleChange}
                    helperText="List multiple emails separated by commas"
                    value={values.settings.emergencyDataSecurityContacts}
                  />
                </FormRow>
                <FormRow>
                  <Label>Default permissions for new workspaces</Label>
                  <Select
                    id="defaultWsPermissions"
                    options={wsPermissionOptions}
                    name="settings.defaultWsPermissions"
                    isClearable={false}
                    value={values.settings.defaultWsPermissions ?? ''}
                    onValueChange={value =>
                      setFieldValue(
                        'settings.defaultWsPermissions',
                        value as PermissionAccessLevel
                      )
                    }
                  />
                </FormRow>
                <FormRow>
                  <Checkbox
                    name="hasConsentedToViewData"
                    isChecked={values.hasConsentedToViewData}
                    onChange={value =>
                      setFieldValue('hasConsentedToViewData', value)
                    }
                  >
                    Grant permission for engineering team to access database
                  </Checkbox>
                </FormRow>
                <StatusWrapper>{status}</StatusWrapper>
              </Wrapper>
            </Form>
          </Island>
          {hasFeature(Features.EXPORT_PERMISSIONS_TO_EXCEL) && (
            <Island
              title="Export permissions configuration to Excel"
              footerContent={
                <FooterWrapper>
                  <PrimaryButton
                    onClick={() => {
                      exportPermissionsToReadableExcel();
                      trackEvent(
                        APPSEC_EVENTS.CLICKED_EXPORT_PERMISSIONS_TO_EXCEL,
                        {
                          from: 'manage groups page',
                        }
                      );
                    }}
                  >
                    Export Excel
                  </PrimaryButton>
                </FooterWrapper>
              }
            >
              Get a detailed overview of your organization&apos;s permissions
              configuration by exporting it as a .xlsx file.
            </Island>
          )}
        </>
      )}
    </Formik>
  );
};

export default connect(OrgSettings, orgSettingsViewModel$);
