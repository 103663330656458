import { ArdoqId, ZonesBySubdivisionsIds } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { FetchSuggestionFn, GetSuggestionsParams } from './types';
import { suggestionApi, SuggestionApiSuggestionTypes } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { IconName } from '@ardoq/icons';

const mapString = (result: string) => ({ value: result, label: result });

const getSuggestionsFetch =
  <T>(
    urlSegment: SuggestionApiSuggestionTypes,
    mapFn: (result: T) => { value: string; label: string },
    workspaceIds?: ArdoqId[] | null,
    subdivisions?: ZonesBySubdivisionsIds
  ): FetchSuggestionFn =>
  async (name: string) => {
    const results = subdivisions
      ? await suggestionApi.getReportSuggestionQuery<T>(
          urlSegment,
          name,
          workspaceIds,
          subdivisions
        )
      : await suggestionApi.getQuery<T>(urlSegment, name, workspaceIds);
    if (isArdoqError(results)) {
      logError(results, 'Loading advanced search suggestions failed');
      return [];
    }
    return results.map(mapFn);
  };

export const getLoadComponentSuggestionsAsync = (
  params?: GetSuggestionsParams
) =>
  getSuggestionsFetch(
    'component',
    ({
      name,
      id,
      workspaceName,
    }: {
      name: string;
      id: string;
      workspaceName?: string;
    }) => ({
      label: name,
      value: id,
      breadcrumbs: workspaceName
        ? [{ text: workspaceName, icon: IconName.WORKSPACE }]
        : undefined,
    }),
    params?.workspaceIds,
    params?.subdivisions
  );

export const getLoadWorkspaceSuggestionsAsync = (
  params?: GetSuggestionsParams
) =>
  getSuggestionsFetch(
    'workspace',
    ({ name, id }: { name: string; id: string }) => ({
      label: name,
      value: id,
    }),
    params?.workspaceIds,
    params?.subdivisions
  );

export const getLoadUserSuggestionsAsync = (params?: GetSuggestionsParams) =>
  getSuggestionsFetch(
    'users',
    ({ name, id, email }: { name: string; id: string; email: string }) => ({
      label: name,
      description: email,
      value: id,
    }),
    params?.workspaceIds,
    params?.subdivisions
  );

export const getLoadTagSuggestionsAsync = (params?: GetSuggestionsParams) =>
  getSuggestionsFetch(
    'tag',
    mapString,
    params?.workspaceIds,
    params?.subdivisions
  );

export const getLoadComponentTypeNameSuggestionsAsync = (
  params?: GetSuggestionsParams
) =>
  getSuggestionsFetch(
    'componentName',
    mapString,
    params?.workspaceIds,
    params?.subdivisions
  );

export const getLoadReferenceTypeNameSuggestionsAsync = (
  params?: GetSuggestionsParams
) =>
  getSuggestionsFetch(
    'referenceName',
    mapString,
    params?.workspaceIds,
    params?.subdivisions
  );
