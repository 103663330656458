import { connect, dispatchAction } from '@ardoq/rxbeach';
import assetsBrowser2024$ from './assetsBrowser2024$';
import { AssetsBrowser2024StreamShape } from './assetsBrowser2024Types';
import styled from 'styled-components';
import { ContextMenuProvider } from '@ardoq/dropdown-menu';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { Folder, Row } from 'components/EntityBrowser/types';
import AssetsBrowserWithDndRows from '../../appContainer/DashboardContainer/AssetsBrowserWithDndRows';
import { AssetRow } from '../AssetsBrowser/types';
import {
  setCurrentPageNumber,
  setExpandedFoldersIds,
  setPerPage,
  setRenameId,
  setSelectedAssets,
  updateSorting,
  updateDataSource,
} from './assetsBrowser2024Actions';
import { ArdoqId } from '@ardoq/api-types';
import {
  highlightStatusMapProps,
  withHighlightStatusMap,
} from '../../utils/withHighlightStatusMap';
import FilterBar, { FILTER_BAR_HEIGHT } from './FilterBar/FilterBar';
import {
  PAGINATION_BAR_HEIGHT,
  PaginationBar,
  PaginationController,
  smartSort,
} from '@ardoq/pagination';
import { LoadingIndicator } from '../../appContainer/DashboardContainer/atoms';
import { ColumnsProps } from '../AssetsBrowser/columns';
import { getDefaultMenuOptions } from './menuOptions';
import {
  confirmRename,
  onAssetClick,
  onAssetPreviewClick,
  onAssetOpenInViewpointModeClick,
  onAssetsMove,
  onTableClick,
} from './tableFunctionality';
import {
  getAssetsBrowser2024CustomColumns,
  getAssetsBrowser2024DefaultColumns,
} from './assetsBrowser2024Columns';
import { colors } from '@ardoq/design-tokens';
import { Filter } from './FilterBar/filterBarTypes';
import { useLayoutEffect } from 'react';
import { AppModules } from '../../appContainer/types';
import { usePrevious } from '@ardoq/hooks';
import { Column } from '@ardoq/table';
import { trackEvent } from 'tracking/tracking';
import { Box, Stack } from '@ardoq/layout';

const TableContainer = styled.div`
  border: 2px solid ${colors.grey95};
  border-radius: 16px;
  overflow: hidden;
`;

const areFiltersEqual = (a: Filter[], b: Filter[]) =>
  a.length === b.length &&
  a.every((filter, index) => filter.label === b[index].label);

type AssetsBrowser2024Props = {
  /**
   * The module that the assets are displayed in. Used for keeping track of the state of the various elements of AssetsBrowser2024.
   */
  appModule: AppModules;
  /**
   * Used for deciding what kind of content that you want shown. For example if you put a survey filter here, only surveys will be shown.
   */
  alwaysActiveFilters: Filter[];
  /**
   * The filters that you want the user to be able to toggle on and off.
   */
  filters: Filter[];
  /**
   * The height at which the table begins to be scrollable.
   */
  scrollHeight?: number;
  /**
   * The content shown to the right of the search bar. Typically a button or a dropdown.
   */
  topRightContent?: React.ReactNode;
  /**
   * If true, does not show folders and disables drag and drop functionality.
   */
  useFolders?: boolean;
  /**
   * A function that given ColumnProps returns the columns that you want to show in the table.
   * These columns will be added in between the name column and menu column.
   * If not provided, the default columns will be shown.
   *
   * NOTE: If you want updates in the relevant stream state of your custom columns dataIndex field to also
   * result in the updated rows being re-rendered immediately, you must add the relevant datafield
   * to the memo check in apps/ardoq-front/src/js/ardoq/appContainer/DashboardContainer/BodyTr.tsx.
   */
  getCustomColumns?: (
    columnsProps: ColumnsProps
  ) => Column<AssetRow | Folder<AssetRow>>[];
};

const AssetsBrowser2024 = ({
  alwaysActiveFilters,
  scrollHeight = 600,
  topRightContent,
  filters,
  useFolders = true,
  getCustomColumns,
  assetsById,
  permissions,
  assetsState,
  appModuleStates,
  commands,
  features,
  setHighlights,
  highlightStatusMap,
  appModule,
  isUpdating,
  surveysWithPendingApprovals,
}: AssetsBrowser2024Props &
  AssetsBrowser2024StreamShape &
  highlightStatusMapProps) => {
  const previousAlwaysActiveFilters = usePrevious(alwaysActiveFilters);
  const {
    dataSource,
    searchPhrase,
    selectedAssets,
    expandedFoldersIds,
    sortByField,
    sortOrder,
    currentPageNumber,
    perPage,
    renameId,
  } = appModuleStates[appModule];

  useLayoutEffect(() => {
    if (
      previousAlwaysActiveFilters &&
      areFiltersEqual(alwaysActiveFilters, previousAlwaysActiveFilters)
    ) {
      return;
    }
    dispatchAction(
      updateDataSource({
        payload: { alwaysActiveFilters, useFolders },
        appModule,
      })
    );
  }, [alwaysActiveFilters, previousAlwaysActiveFilters, appModule, useFolders]);

  const disableDragAndDrop =
    !useFolders || !permissions.currentUserIsOrgWriter || !!renameId;

  const folderIds = Object.keys(assetsState.assetFoldersById);

  const getMenuOptions = (row: Row<AssetRow>) =>
    getDefaultMenuOptions({
      row,
      dataSource,
      selectedAssetIds: selectedAssets,
      assetsById,
      expandedFoldersIds,
      setHighlights,
      appModule,
      createAssetOptions: {
        commands,
        features,
        permissions,
      },
      surveysWithPendingApprovals,
    });

  const getCustomAndMandatoryColumns = getCustomColumns
    ? (columnProps: ColumnsProps) =>
        getAssetsBrowser2024CustomColumns(
          columnProps,
          getCustomColumns,
          getMenuOptions
        )
    : undefined;

  const getDefaultColumns = (columnProps: ColumnsProps) =>
    getAssetsBrowser2024DefaultColumns({
      ...columnProps,
      getMenuOptions,
    });

  const smartSortedDataSource = smartSort(dataSource, sortByField, sortOrder);

  return (
    <Stack gap="medium">
      {isUpdating && <LoadingIndicator loading />}
      <FilterBar
        filters={filters}
        topRightContent={topRightContent}
        appModule={appModule}
      />
      <PaginationController
        dataSource={smartSortedDataSource}
        defaultSortById={sortByField || undefined}
        defaultSortOrder={sortOrder}
        startPageNumber={currentPageNumber}
        perPage={perPage}
        render={({
          currentPageDataSource,
          currentPageNumber: controllerPageNumber,
          perPage,
          totalResults,
          onPageSelect,
          sortBy,
        }) => {
          return (
            <Stack gap="small">
              <TableContainer>
                <ContextMenuProvider dropdownSize={DropdownSize.S}>
                  {({ openContextMenu }) => (
                    <AssetsBrowserWithDndRows<AssetRow>
                      dataSource={currentPageDataSource}
                      onTableClick={(e, row) =>
                        onTableClick(e, row, openContextMenu, {
                          row,
                          dataSource,
                          selectedAssetIds: selectedAssets,
                          assetsById,
                          expandedFoldersIds,
                          setHighlights,
                          appModule,
                          createAssetOptions: {
                            commands,
                            features,
                            permissions,
                          },
                          surveysWithPendingApprovals,
                        })
                      }
                      tableHeight={
                        scrollHeight -
                        (FILTER_BAR_HEIGHT + PAGINATION_BAR_HEIGHT)
                      }
                      // Simplification
                      // Check: https://docs.google.com/spreadsheets/d/1VF4WXDlHEZpksxdW4PzUQ8nb055t7wrmbl4OtdIX_Yk/edit#gid=1065175734
                      // if user is not at least writer then any dragging won't be possible
                      // so there is no need to check individually each row
                      dndDisabled={disableDragAndDrop}
                      folderIds={useFolders ? folderIds : []}
                      expandedFoldersIds={expandedFoldersIds}
                      setHighlights={setHighlights}
                      highlightStatusMap={highlightStatusMap}
                      setExpandedFoldersIds={(expandedFolderIds: ArdoqId[]) => {
                        const closedFolders = !expandedFolderIds.length;
                        trackEvent(
                          `Assets Browser: ${closedFolders ? 'closed' : 'expanded'} folders`,
                          {
                            appModule,
                          }
                        );
                        dispatchAction(
                          setExpandedFoldersIds({
                            payload: expandedFolderIds,
                            appModule,
                          })
                        );
                      }}
                      initialSortParams={{
                        sortByField,
                        sortOrder,
                      }}
                      onSortParamsChange={({ sortByField, sortOrder }) => {
                        trackEvent('Assets Browser: used sort', {
                          appModule,
                          sortByField,
                          sortOrder,
                        });
                        sortBy(sortByField);
                        dispatchAction(
                          updateSorting({ payload: sortByField, appModule })
                        );
                      }}
                      searchPhrase={searchPhrase}
                      selected={selectedAssets}
                      setSelected={selectedAssets => {
                        trackEvent(`Assets Browser: selected assets`, {
                          numberOfAssets: selectedAssets.length,
                          appModule,
                        });
                        dispatchAction(
                          setSelectedAssets({
                            payload: selectedAssets,
                            appModule,
                          })
                        );
                      }}
                      onAssetClick={row => onAssetClick(row, commands)}
                      onAssetPreviewClick={row =>
                        onAssetPreviewClick(row, appModule)
                      }
                      onAssetOpenInViewpointModeClick={
                        onAssetOpenInViewpointModeClick
                      }
                      onAssetsMove={(workspaceIds, folderId) =>
                        onAssetsMove(workspaceIds, folderId, assetsById)
                      }
                      renameId={renameId}
                      renameConfirm={(newName, row) =>
                        confirmRename(newName, row, setHighlights, appModule)
                      }
                      renameCancel={() =>
                        dispatchAction(
                          setRenameId({
                            payload: null,
                            appModule,
                          })
                        )
                      }
                      getCustomColumns={
                        getCustomAndMandatoryColumns ?? getDefaultColumns
                      }
                      skipDefaultSortOrder={false}
                      style={{
                        maxHeight:
                          scrollHeight -
                          (FILTER_BAR_HEIGHT + PAGINATION_BAR_HEIGHT),
                      }}
                    />
                  )}
                </ContextMenuProvider>
              </TableContainer>
              <Box paddingBottom="medium">
                <PaginationBar
                  currentPageNumber={controllerPageNumber}
                  perPage={perPage}
                  totalResults={totalResults}
                  onChange={({ currentPageNumber, perPage }) => {
                    dispatchAction(
                      setPerPage({
                        payload: perPage,
                        appModule,
                      })
                    );
                    dispatchAction(
                      setCurrentPageNumber({
                        payload: currentPageNumber,
                        appModule,
                      })
                    );
                    onPageSelect(currentPageNumber);
                  }}
                  pageSizeOptions={[15, 25, 50, 100]}
                />
              </Box>
            </Stack>
          );
        }}
      />
    </Stack>
  );
};

export default withHighlightStatusMap<AssetsBrowser2024Props>(
  connect(AssetsBrowser2024, assetsBrowser2024$)
);
