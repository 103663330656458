type TrackingMetadata = Record<
  string,
  string | number | boolean | null | undefined
>;
export type TrackingAction = {
  name: keyof typeof EVENT_NAMES;
  metadata?: TrackingMetadata;
};

export const EVENT_NAMES = {
  NAVIGATED_TO_PATH: 'Navigated to it-pedia integration path',
  CHANGED_OVERVIEW_TAB: 'Changed tab on the it-pedia overview page',
  STARTED_NEW_IMPORT: 'Started new it-pedia import',
  CLICKED_BUTTON: 'Clicked button in it-pedia',
  OPENED_LINK: 'Opened link on it-pedia ',
  SELECTED_COMPONENT_TYPES: 'Selected component types it-pedia overview',
  ENABLED_FILTERS: 'Enabled the it-pedia filters',
  APPLIED_FILTERS: 'Clicked apply filter on it-pedia overview to see counts',
  SELECTED_FIELDS: 'Selected the field in it-pedia from the drop down',
  CREATED_FIELDS: 'Created the field in it-pedia using the drop down',
  TOGGLED: 'Toggled the collpasable section in it-pedia',
  RESTARTED_IMPORT: 'Restarted the it-pedia import',
  STARTED_CREATING_NEW_SYNC: 'Started creating a new sync in ip-pedia',
  SUCEEDED_SYNC_CREATION: 'Succeeded in creating it-pedia sync',
  FAILED_SYNC_CREATION: 'Failed to create it-pedia sync',
};

export type FunnelIds = {
  overviewId: string;
  importId: string;
};
