import { colors } from '@ardoq/design-tokens';
import styled, { css } from 'styled-components';
import { ResizeDirection } from './types';
import { CSSProperties } from 'react';
import { BOTTOM_BAR_BORDER_TOP, RESIZER_MARGIN } from './consts';

export const MainContainer = styled.div`
  height: 100%;
  position: relative;
`;

export const ContextBarOuterContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: ${BOTTOM_BAR_BORDER_TOP}px;
`;

export type ResizerProps = {
  style: CSSProperties;
  isActive: boolean;
  getBox: () => DOMRect | null;
};

export const ResizeBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
`;

export const Resizer = styled.div<{
  $direction: ResizeDirection;
  $isActive: boolean;
}>`
  position: absolute;
  z-index: 2001;
  ${props =>
    props.$direction === ResizeDirection.HORIZONTAL
      ? css`
          width: 8px;
          top: 0;
          cursor: ew-resize;
          margin: 0 -${RESIZER_MARGIN}px;
        `
      : css`
          cursor: ns-resize;
          width: 100%;
          height: 8px;
          margin: -${RESIZER_MARGIN}px 0;
        `}
  background-color: ${colors.grey50};
  opacity: 0;
  transition: opacity 0.3s 0.2s;
  &:hover {
    opacity: 0.5;
    transition: opacity 0.3s 0;
  }
  ${props =>
    props.$isActive &&
    css`
      opacity: 0.5;
    `}
`;

export const RightEditorContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  border-left: 1px solid rgba(0 0 0 / 12%);
  max-width: 100%;

  & .right-pane.open {
    box-shadow: none;
  }
`;
