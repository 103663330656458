import { Column, DatasourceTable, newTableTheme } from '@ardoq/table';
import styled from 'styled-components';
import { LoadingOverlay, Box, FlexBox, Stack } from '@ardoq/layout';
import { SecondaryButton } from '@ardoq/button';
import { openReportInBuilder } from '../../components/AppMainSidebar/utils';
import { ReportDatasourceMigrationData } from '@ardoq/api-types';
import { Header1, Paragraph } from '@ardoq/typography';

export const REPORT_DATASOURCE_MIGRATION_ID = 'report-datasource-migration';

const LoadingAnimation = styled(LoadingOverlay)`
  position: relative;
`;
const List = styled.ul`
  padding: 0;
`;

const columns: Column<ReportDatasourceMigrationData>[] = [
  { title: 'Report name', dataIndex: 'name', headerStyle: { width: 300 } },
  { title: 'Author', dataIndex: 'createdBy' },
  { title: 'Reason for manual review', dataIndex: 'reason' },
  {
    title: 'Query part',
    dataIndex: 'invalidQueries',
    valueRender: (invalidQueries: string[]) => (
      <List>
        {invalidQueries.map((queryString, i) => (
          <li key={i}>{queryString}</li>
        ))}
      </List>
    ),
  },
  {
    title: 'Edit report',
    dataIndex: '_id',
    valueRender: reportId => (
      <SecondaryButton onClick={() => openReportInBuilder({ reportId })}>
        Edit
      </SecondaryButton>
    ),
  },
];

type ReportDatasourceChecklistContentProps = {
  data: ReportDatasourceMigrationData[] | null;
};
const ReportDatasourceChecklistContent = ({
  data,
}: ReportDatasourceChecklistContentProps) => {
  return data === null ? (
    <LoadingAnimation message="Loading..." />
  ) : data.length ? (
    <DatasourceTable
      isFixedLayout
      dataSource={data}
      columns={columns}
      components={newTableTheme}
    />
  ) : (
    <Box>
      <Stack gap="medium">
        <FlexBox justify="center">
          <Header1>All done!</Header1>
        </FlexBox>
        <FlexBox justify="center">
          <Paragraph>
            There are no more reports to be migrated. Nice work!
          </Paragraph>
        </FlexBox>
      </Stack>
    </Box>
  );
};

export default ReportDatasourceChecklistContent;
