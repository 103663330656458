import { colors, s24 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { FlexBox } from '@ardoq/layout';

export const CircularWrapper = styled.div`
  color: ${colors.icon};
  height: ${s24};
  width: ${s24};
  border-radius: 50%;
  background: ${colors.borderSubtle00};
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 13px;
`;

export const MarginAutoFlexBox = styled(FlexBox).attrs({
  align: 'center',
  width: 'full',
  gap: 'small',
  padding: 'medium',
})<{ $hideBorder?: boolean }>`
  margin-top: auto;
  border-top: ${({ $hideBorder }) =>
    $hideBorder ? 'none' : `1px solid ${colors.borderSubtle00}`};
`;
