import { TextInput } from '@ardoq/forms';
import { Select, SelectOption, SelectOptionsOrGroups } from '@ardoq/select';

type MatchFormRowParams = {
  value?: string;
  isOptional?: boolean;
  itPediaFieldName: string;
  onChange: (value: SelectOption<string> | null) => void;
  options: SelectOptionsOrGroups<string>;
};

export const MatchFormRow = ({
  value,
  isOptional = false,
  itPediaFieldName,
  options,
  onChange,
}: MatchFormRowParams) => {
  return (
    <>
      <div>Use</div>
      <Select
        isClearable={isOptional}
        placeholder={`Select a field ${isOptional ? '(optional)' : ''}`}
        value={value}
        options={options}
        onChange={field => onChange(field || null)}
      />
      <div>to identify</div>
      <TextInput value={itPediaFieldName} isReadOnly />
    </>
  );
};
