import { switchMap, tap } from 'rxjs/operators';
import {
  actionCreator,
  collectRoutines,
  dispatchAction,
  persistentReducedStream,
  reducer,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { RegisterWebhookPayload, Webhook } from '@ardoq/api-types';
import { handleError, webhookApi } from '@ardoq/api';
import { getArdoqErrorMessage } from '@ardoq/common-helpers';

export enum Tabs {
  MANAGE = 'manage',
  CREATE = 'create',
}

type RegistrationError = string;

export const initWebhooks = () => dispatchAction(fetchWebhooks());

export const selectTab = actionCreator<Tabs>('[webhooks] SELECT_WEBHOOK_TAB');

const resetWebhookError = actionCreator('[webhooks] RESET WEBHOOK ERROR');

export const registerWebhook = actionCreator<RegisterWebhookPayload>(
  '[webhook] REGISTER_WEBHOOK'
);

type DeleteWebhookPayload = string;
export const deleteWebhook = actionCreator<DeleteWebhookPayload>(
  '[webhook] DELETE_WEBHOOK'
);

const fetchWebhooks = actionCreator('[webhooks] FETCH_WEBHOOKS');

const webhooksFetched = actionCreator<Webhook[]>('[webhooks] WEBHOOKS_FETCHED');

const registrationFailed = actionCreator<RegistrationError>(
  '[webhook] REGISTRATION_FAILED'
);

const handleFetchWebhooks = routine(
  ofType(fetchWebhooks),
  switchMap(() => webhookApi.fetchAll()),
  handleError(),
  tap(response => {
    dispatchAction(webhooksFetched(response));
  })
);

const handleRegisterWebhook = routine(
  ofType(registerWebhook),
  extractPayload(),
  switchMap(body => webhookApi.register(body)),
  handleError(response => {
    dispatchAction(registrationFailed(getArdoqErrorMessage(response)));
  }),
  tap(() => {
    dispatchAction(fetchWebhooks());
    dispatchAction(selectTab(Tabs.MANAGE));
  })
);

const handleDeleteWebhook = routine(
  ofType(deleteWebhook),
  extractPayload(),
  switchMap(id => webhookApi.delete(id)),
  tap(() => dispatchAction(fetchWebhooks()))
);

export interface WebhookStreamState {
  webhooks: Webhook[];
  registrationError: RegistrationError | null;
  selectedTab: Tabs;
}

const startState: WebhookStreamState = {
  webhooks: [],
  registrationError: null,
  selectedTab: Tabs.MANAGE,
};
const handleWebhooksFetched = (
  state: WebhookStreamState,
  webhooks: Webhook[]
): WebhookStreamState => ({
  ...state,
  webhooks,
  registrationError: null,
});
const webhooksReducer = reducer(webhooksFetched, handleWebhooksFetched);
const handleSelectTab = (state: WebhookStreamState, selectedTab: Tabs) => ({
  ...state,
  selectedTab,
});
const tabsReducer = reducer(selectTab, handleSelectTab);
const handleRegistrationFailed = (
  state: WebhookStreamState,
  registrationError: string
): WebhookStreamState => ({
  ...state,
  registrationError,
});
const registrationErrorReducer = reducer(
  registrationFailed,
  handleRegistrationFailed
);
const handleResetWebhookError = (
  state: WebhookStreamState
): WebhookStreamState => ({
  ...state,
  registrationError: null,
});
const resetWebhookErrorReducer = reducer(
  resetWebhookError,
  handleResetWebhookError
);

export const webhook$ = persistentReducedStream('webhook$', startState, [
  webhooksReducer,
  registrationErrorReducer,
  resetWebhookErrorReducer,
  tabsReducer,
]);

export default collectRoutines(
  handleFetchWebhooks,
  handleRegisterWebhook,
  handleDeleteWebhook
);
