import { ViewpointNavigatorEntrySummaryProps } from './types';
import { colors } from '@ardoq/design-tokens';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import styled from 'styled-components';
import { caption } from '@ardoq/typography';
import { Box, FlexBox, Stack } from '@ardoq/layout';

const SummaryItemLabel = styled.span`
  ${caption};
  color: ${colors.grey35};
`;

const SummaryItem = ({
  content,
  icon,
}: {
  content: string;
  icon: IconName;
}) => (
  <FlexBox gap="xsmall" align="center">
    <Icon iconName={icon} color={colors.blue80} iconSize={IconSize.SMALL} />
    <SummaryItemLabel>{content}</SummaryItemLabel>
  </FlexBox>
);

const ViewpointNavigatorEntrySummary = ({
  componentSummary,
  referenceSummary,
  filterSummary,
}: ViewpointNavigatorEntrySummaryProps) => {
  return (
    <Box backgroundColor="surfaceInfo" padding="small" borderRadius="r4">
      <Stack gap="xsmall">
        {componentSummary && (
          <SummaryItem content={componentSummary} icon={IconName.COMPONENT} />
        )}
        {referenceSummary && (
          <SummaryItem content={referenceSummary} icon={IconName.REFERENCE} />
        )}
        {filterSummary && (
          <SummaryItem content={filterSummary} icon={IconName.FILTER_LIST} />
        )}
      </Stack>
    </Box>
  );
};

export default ViewpointNavigatorEntrySummary;
