import { APIToken } from '@ardoq/api-types';
import { TokenViewCommands } from './commands';
import { currentDate, formatDateTime } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { tokenTabViewModelOperations } from './tokenTabViewModelOperations';
import { IconDecorator } from '@ardoq/decorators';
import { IconName } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { Box } from '@ardoq/layout';
import { TokenStatus } from './TokenStatus';
import { DropdownItem } from '@ardoq/dropdown-menu';
import { CellWithDropdown } from '@ardoq/table';
import { truncate } from 'lodash';
import { ExcludeFalsy } from '@ardoq/common-helpers';

const NAME_TRUNCATE_LENGTH = 20;

export const getTokensTableDataSource = ({
  tokens,
  commands,
  isOrgTokens,
}: {
  tokens: APIToken[];
  commands: TokenViewCommands;
  isOrgTokens: boolean;
}) => {
  return tokens.map(token => {
    const isServiceAccountToken =
      tokenTabViewModelOperations.isServiceAccountToken(token);

    return {
      tokenName: token.description,
      createdBy: token['user-name'],
      lastUsed: token['last-used']
        ? formatDateTime(token['last-used'], getCurrentLocale())
        : 'Never used',
      tokenStatus: {
        isActive: !tokenTabViewModelOperations.isExpired(token, currentDate()),
        token,
      },
      dropdownOptions: [
        !isOrgTokens && {
          label: 'Regenerate token',
          onClick: () => {
            commands.regenerateAPIToken(token);
          },
        },
        {
          label: 'Delete',
          isDisabled: isServiceAccountToken,
          description: isServiceAccountToken
            ? 'This token belongs to a service account. Go to "Service Accounts" in Organization Settings to manage service accounts.'
            : undefined,
          onClick: () => {
            commands.deleteAPIToken(token);
          },
        },
      ].filter(ExcludeFalsy),
    };
  });
};

export const getTokensTableColumns = () => {
  return [
    {
      title: 'Token name',
      dataIndex: 'tokenName',
      cellStyle: { width: '250px' },
      valueRender: (tokenName: string) => {
        const isTruncated = tokenName.length > NAME_TRUNCATE_LENGTH;
        return (
          <WithPopover content={isTruncated && tokenName}>
            <IconDecorator iconName={IconName.KEY}>
              {truncate(tokenName, { length: NAME_TRUNCATE_LENGTH })}
            </IconDecorator>
          </WithPopover>
        );
      },
    },
    { title: 'Created by', dataIndex: 'createdBy' },
    { title: 'Last used', dataIndex: 'lastUsed' },
    {
      title: 'Token status',
      dataIndex: 'tokenStatus',
      valueRender: ({
        isActive,
        token,
      }: {
        isActive: boolean;
        token: APIToken;
      }) => (
        <WithPopover
          content={tokenTabViewModelOperations.getTokenStatusHintText(
            token,
            currentDate()
          )}
        >
          <Box>
            <TokenStatus isActive={isActive} />
          </Box>
        </WithPopover>
      ),
    },
    {
      dataIndex: 'dropdownOptions',
      valueRender: (dropdownOptions: Partial<DropdownItem>[]) => (
        <CellWithDropdown options={dropdownOptions} />
      ),
    },
  ];
};
