import {
  action$,
  actionCreator,
  reducer,
  reduceState,
  streamReducer,
} from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import type { ContextShape } from '@ardoq/data-model';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { combineLatest } from 'rxjs';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { componentInterface } from '@ardoq/component-interface';
import { referenceAccessControlOperation } from 'resourcePermissions/accessControlHelpers/reference';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { referenceInterface } from '@ardoq/reference-interface';
import { ScenarioModeState } from 'scope/types';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';

export const switchTab = actionCreator<string>('[OPTIONS_MENU] switchTab');

export type OptionsMenuState = {
  tabId: string;
};

const initialState = { tabId: '' };

const mapContextToOptionMenuState = (
  state: OptionsMenuState,
  [contextState, permissionContext, subdivisionsContext, activeScenarioState]: [
    ContextShape,
    PermissionContext,
    SubdivisionsContext,
    ScenarioModeState,
  ]
) => {
  if (state.tabId) return state;
  const { componentId, workspaceId, referenceId } = contextState;
  const canEditWorkspace = workspaceAccessControlInterface.canEditWorkspace(
    permissionContext,
    workspaceId,
    activeScenarioState
  );
  if (referenceId) {
    if (canEditWorkspace) {
      return { ...state, tabId: 'reference' };
    }
    const reference = referenceInterface.getLiteAttributes(referenceId);
    const canEditReference =
      !!reference &&
      referenceAccessControlOperation.canEditReference({
        permissionContext,
        reference,
        subdivisionsContext,
      });
    const tabId = canEditReference ? 'reference' : 'workspace';
    return { ...state, tabId };
  }

  if (componentId) {
    if (canEditWorkspace) {
      return { ...state, tabId: 'component' };
    }
    const componentData = componentInterface.getComponentData(componentId);
    if (!componentData) {
      return { ...state, tabId: 'workspace' };
    }
    const canEditComponentProperties =
      componentAccessControlOperation.canEditComponent({
        permissionContext,
        component: componentData,
        subdivisionsContext,
      });
    if (canEditComponentProperties) {
      return { ...state, tabId: 'component' };
    }
  }

  return { ...state, tabId: 'workspace' };
};

const contextReducer = streamReducer<
  OptionsMenuState,
  [ContextShape, PermissionContext, SubdivisionsContext, ScenarioModeState]
>(
  combineLatest([
    context$,
    currentUserPermissionContext$,
    subdivisions$,
    activeScenario$,
  ]),
  mapContextToOptionMenuState
);

const switchTabReducer = (state: OptionsMenuState, tabId: string) => ({
  ...state,
  tabId,
});
const actionReducer = reducer<OptionsMenuState, string>(
  switchTab,
  switchTabReducer
);

export const optionsMenu$ = action$.pipe(
  reduceState('optionsMenu$', initialState, [contextReducer, actionReducer])
);
