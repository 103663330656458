import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { catchError, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import { userEvent } from 'sync/actions';
import { ServiceNowTable } from '@ardoq/api-types/integrations';
import {
  ensureCorrectAsyncRequest,
  getAsyncRequestId,
  parseAsyncResponsePayload,
} from 'integrations/common/async/utils';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import {
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';
import { AsyncOperations } from 'integrations/common/async/constants';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { initTables } from '../tableFields/actions';
import {
  fetchServiceNowTables,
  fetchServiceNowTablesFailure,
  fetchServiceNowTablesSuccess,
  resetState,
} from './actions';
import { serviceNowTables$ } from './tables$';
import { servicenowIntegrationApi } from '@ardoq/api';

const handleFetchTableList = routine(
  ofType(fetchServiceNowTables),
  extractPayload(),
  withLatestFrom(activeIntegrations$, serviceNowTables$),
  map(([{ connectionId, integrationId }, activeIntegrations, { isAsync }]) => ({
    connectionId,
    isAsync,
    requestId: getAsyncRequestId({
      integrationId,
      operation: AsyncOperations.SERVICENOW_FETCH_TABLES,
      funnelId: activeIntegrations[integrationId].trackingFunnelId,
    }),
  })),
  switchMap(({ connectionId, isAsync, requestId }) => {
    return forkJoin({
      async: of(isAsync),
      tables: retriableRequest(
        () =>
          servicenowIntegrationApi.fetchTableList({
            connectionId,
            requestId,
            async: isAsync,
          }),
        'Unable to fetch ServiceNow Table list'
      ),
    });
  }),
  tap(({ async, tables }) => {
    if (!async) {
      dispatchAction(fetchServiceNowTablesSuccess({ tables }));
      dispatchAction(initTables(tables.map(table => table.name)));
    }
  }),
  catchError(error => {
    dispatchAction(fetchServiceNowTablesFailure());
    return handleApiError(error);
  })
);

const handleFetchTableListResponse = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncResponsePayload<ServiceNowTable[]>),
  withLatestFrom(activeIntegrations$),
  ensureCorrectAsyncRequest(AsyncOperations.SERVICENOW_FETCH_TABLES),
  tap(([response]) => {
    if (!response) return;

    if (response.status !== 'success') {
      dispatchAction(fetchServiceNowTablesFailure());
      return;
    }

    dispatchAction(
      fetchServiceNowTablesSuccess({
        tables: response.data,
      })
    );
    dispatchAction(initTables(response.data.map(table => table.name)));
  })
);

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  filter((integrationId: IntegrationId) => integrationId === 'servicenow-v3'),
  tap(() => {
    dispatchAction(resetState());
  })
);

export default [
  handleFetchTableList,
  handleFetchTableListResponse,
  handleResetIntegration,
];
