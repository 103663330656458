import { ArdoqId, AssetType } from '@ardoq/api-types';
import { confirmDeleteAsset } from 'components/DeleteAssetModal/DeleteAssetModal';
import { DeleteType } from 'components/DeleteAssetModal/types';

const toItem = <T>({ _id: id, name }: any, type: T) => ({ id, name, type });

type DeleteAssetsArgs = {
  _id: ArdoqId;
  name: string;
};

type OpenDeleteMultipleAssetsDialog = {
  workspaces?: DeleteAssetsArgs[];
  presentations?: DeleteAssetsArgs[];
  surveys?: DeleteAssetsArgs[];
  metamodels?: DeleteAssetsArgs[];
  folders?: DeleteAssetsArgs[];
  scenarios?: DeleteAssetsArgs[];
  reports?: DeleteAssetsArgs[];
  dashboards?: DeleteAssetsArgs[];
  viewpoints?: DeleteAssetsArgs[];
  traversals?: DeleteAssetsArgs[];
  bookmarks?: DeleteAssetsArgs[];
  broadcasts?: DeleteAssetsArgs[];
};

export const openDeleteMultipleAssetsDialog = async ({
  workspaces = [],
  presentations = [],
  surveys = [],
  metamodels = [],
  folders = [],
  scenarios = [],
  reports = [],
  dashboards = [],
  viewpoints = [],
  traversals = [],
  bookmarks = [],
  broadcasts = [],
}: OpenDeleteMultipleAssetsDialog) => {
  const approved = await confirmDeleteAsset({
    deleteType: DeleteType.MULTIPLE,
    assets: {
      folders: folders.map(item => toItem(item, AssetType.FOLDER)),
      workspaces: workspaces.map(item => toItem(item, AssetType.WORKSPACE)),
      surveys: surveys.map(item => toItem(item, AssetType.SURVEY)),
      metamodels: metamodels.map(item => toItem(item, AssetType.METAMODEL)),
      scenarios: scenarios.map(item => toItem(item, AssetType.SCENARIO)),
      presentations: presentations.map(item =>
        toItem(item, AssetType.PRESENTATION)
      ),
      reports: reports.map(item => toItem(item, AssetType.REPORT)),
      dashboards: dashboards.map(item => toItem(item, AssetType.DASHBOARD)),
      viewpoints: viewpoints.map(item => toItem(item, AssetType.VIEWPOINT)),
      traversals: traversals.map(item => toItem(item, AssetType.TRAVERSAL)),
      bookmarks: bookmarks.map(item => toItem(item, AssetType.BOOKMARK)),
      broadcasts: broadcasts.map(item => toItem(item, AssetType.BROADCAST)),
    },
    inputDataTestId: 'delete-multiple-assets-input',
  });
  return approved;
};
