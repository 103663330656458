import { ArdoqId } from '@ardoq/api-types';
import {
  UserAssetsOverviewQuery,
  UpdateUserAssetsOverviewQueryOptions,
} from './userAssetsOverviewTypes';
import { omit, without } from 'lodash';
import { optionsHelpers } from '../UserRoleAndGroupsDetails/UserAssetsOverviewTab/optionsHelpers';

const getEmpty = (): UserAssetsOverviewQuery => {
  return {
    userId: '',
    assetTypes: [],
    permissionLevels: [],
    searchString: '',
  };
};

const makeQuery = (userId: ArdoqId): UserAssetsOverviewQuery => {
  return {
    ...getEmpty(),
    userId,
  };
};

const generateFetchQueryParams = (
  query: UserAssetsOverviewQuery
): {
  userId: ArdoqId;
  queryParams: {
    assetTypes?: string[];
    permissionLevels?: string[];
  };
} => {
  const { userId, ...rest } = query;
  return {
    userId,
    queryParams: omit(rest, ['searchString']),
  };
};

const updateUserAssetsQuery = (
  state: UserAssetsOverviewQuery,
  update: Partial<UserAssetsOverviewQuery>
): UserAssetsOverviewQuery => {
  return {
    ...state,
    ...update,
  };
};

const isNotEmpty = (query: UserAssetsOverviewQuery): boolean => {
  return query.assetTypes.length + (query.permissionLevels?.length || 0) > 0;
};

const hasUserId = (query: UserAssetsOverviewQuery): boolean => {
  return query.userId !== '';
};

const getSearchString = (query: UserAssetsOverviewQuery): string => {
  return query.searchString;
};

const updateUserAssetsOverviewQuery = <T>(
  query: UserAssetsOverviewQuery,
  options: UpdateUserAssetsOverviewQueryOptions<T>
): UserAssetsOverviewQuery => {
  const { key, excludeList, includeList, newList } = options;
  const currentValues = query[key] as T[];
  let valuesToSet: T[] = [];
  if (includeList) {
    valuesToSet = Array.from(new Set([...currentValues, ...includeList]));
  }
  if (excludeList) {
    valuesToSet = without(currentValues, ...excludeList);
  }
  if (newList) {
    valuesToSet = newList;
  }
  return {
    ...query,
    [key]: valuesToSet,
  };
};

const getAssetTypesOptions = (query: UserAssetsOverviewQuery) => {
  return query.assetTypes.map(optionsHelpers.assetTypeToOption);
};

const getPermissionLevelsOptions = (query: UserAssetsOverviewQuery) => {
  return query.permissionLevels.map(optionsHelpers.permissionLevelToOption);
};

export const userAssetsOverviewQueryOperations = {
  getEmpty,
  makeQuery,
  generateFetchQueryParams,
  updateUserAssetsQuery,
  isNotEmpty,
  hasUserId,
  getSearchString,
  updateUserAssetsOverviewQuery,
  getAssetTypesOptions,
  getPermissionLevelsOptions,
};
