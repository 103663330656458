import styled from 'styled-components';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';

const WorkspaceIcon = styled(Icon).attrs({
  iconName: IconName.WORKSPACE,
  iconSize: IconSize.SMALL,
})`
  margin-right: 6px;
  color: ${colors.grey35};
`;

export default WorkspaceIcon;
