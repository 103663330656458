import styled from 'styled-components';
import { Select } from '@ardoq/select';
import { Step } from 'viewpoints/types';
import { colors, s16 } from '@ardoq/design-tokens';
import {
  entryPointIsAmbiguous,
  getStepsThatIncludeComponentTypeName,
} from 'viewpoints/utils';
import { text1 } from '@ardoq/typography';
import { EntryPoint } from '@ardoq/api-types';

type EntryPointFormProps = {
  entryPoint: EntryPoint;
  steps: Step[];
  onChange: (entryPoint: EntryPoint) => void;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${s16};
`;

const Column = styled.div`
  width: 200px;
  color: ${colors.grey15};
  ${text1}
`;

const EntryPointForm = ({
  entryPoint,
  steps,
  onChange,
}: EntryPointFormProps) => {
  if (!entryPointIsAmbiguous(steps, entryPoint)) return null;
  const componentTypeName = entryPoint[0];
  const stepsThatIncludeComponentTypeName =
    getStepsThatIncludeComponentTypeName(steps, componentTypeName);
  return (
    <Container key={componentTypeName}>
      <Column>{componentTypeName}</Column>
      <Column>
        <Select
          value={entryPoint[1]}
          options={stepsThatIncludeComponentTypeName.map(step => {
            const stepIndex = steps.indexOf(step);
            const stepLabel = `Step ${stepIndex + 1}`;
            return { label: stepLabel, value: step.id };
          })}
          onValueChange={stepId => {
            onChange([componentTypeName, stepId as string]);
          }}
        />
      </Column>
    </Container>
  );
};

export default EntryPointForm;
