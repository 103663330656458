import { Link } from '@ardoq/typography';

export const DryRunAdditionalPopoverContent = () => (
  <div style={{ maxWidth: 220, wordBreak: 'break-all' }}>
    <div>What is document archive?</div>
    <Link
      href="https://help.ardoq.com/en/articles/44182-manage-files-with-document-archive"
      target="_blank"
      hideIcon
      typography="text2"
    >
      https://help.ardoq.com/en/articles/44182-manage-files-with-document-archive
    </Link>
  </div>
);
