import { colors, r8, s16 } from '@ardoq/design-tokens';
import { Switch } from '@ardoq/forms';
import { WithPopover } from '@ardoq/popovers';
import { BetaBadge, TrialBadge } from '@ardoq/status-ui';
import { Text } from '@ardoq/typography';
import styled from 'styled-components';
import { Box, FlexBox, Stack } from '@ardoq/layout';

const FeatureToggleContainer = styled.div`
  padding: ${s16};
  &:hover {
    background-color: ${colors.grey95};
  }
`;

const SwitchContainer = styled.div`
  align-self: center;
`;

const FeatureImage = styled.img`
  width: 150px;
  border: 1px solid ${colors.grey80};
  border-radius: ${r8};
`;

export const FeatureToggle = ({
  imageSrc,
  name,
  description,
  isChecked,
  isDisabled = false,
  isBeta = false,
  isTrial = false,
  helperText,
  onChange = () => {},
}: {
  imageSrc: string;
  name: string;
  description?: string | React.ReactNode;
  isChecked: boolean;
  isDisabled?: boolean;
  isBeta?: boolean;
  isTrial?: boolean;
  helperText?: string;
  onChange?: (value: boolean) => void;
}) => (
  <FeatureToggleContainer>
    <FlexBox align="flex-start" justify="space-between" gap="medium">
      <FeatureImage src={imageSrc} />
      <Stack flex={1} gap="small">
        {isBeta || isTrial ? (
          <FlexBox gap="medium" align="center">
            <Text variant="text1Bold">{name}</Text>
            {isBeta && <BetaBadge />}
            {isTrial && <TrialBadge />}
          </FlexBox>
        ) : (
          <Text variant="text1Bold">{name}</Text>
        )}
        <Box maxWidth="65%">
          <Text variant="text1" color="textSubtle">
            {description}
          </Text>
        </Box>
      </Stack>
      <WithPopover content={helperText}>
        <SwitchContainer>
          <Switch
            isChecked={isChecked}
            onChange={onChange}
            name={name}
            isDisabled={isDisabled}
          />
        </SwitchContainer>
      </WithPopover>
    </FlexBox>
  </FeatureToggleContainer>
);
