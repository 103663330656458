import type { APIReferenceType } from '@ardoq/api-types';
import type { ReferenceTypeRepresentationData } from '@ardoq/graph';

export const getReferenceTypeRepresentationDataFromReferenceType = (
  referenceType: APIReferenceType,
  displayLabel: string
): ReferenceTypeRepresentationData => {
  return {
    color: referenceType.color,
    line: referenceType.line,
    lineBeginning: referenceType.lineBeginning,
    lineEnding: referenceType.lineEnding,
    displayLabel: displayLabel,
  };
};
