import {
  NewExportRoutes,
  RoutePath,
} from 'integrations/common/navigation/types';
import { ConfigurationState } from '../streams/types';
import { isReviewExportSuccessful } from '../pages/NewExport/ReviewExport/utils';
import { isExportSucceeded } from '../pages/NewExport/ExportAndSchedule/utils';
import { Maybe } from '@ardoq/common-helpers';
import { isDataSelectionValid } from '../pages/NewExport/ComponentSelection/utils';

const isNewExportRoute = (path: RoutePath): path is NewExportRoutes => {
  return Object.values(NewExportRoutes).includes(path as NewExportRoutes);
};

export function getRouteFromString(path: RoutePath) {
  return isNewExportRoute(path) ? path : NewExportRoutes.DATA_SELECTION;
}

export function isPathReady(path: Maybe<string>, config: ConfigurationState) {
  switch (path) {
    case NewExportRoutes.DATA_SELECTION:
      return true;
    case NewExportRoutes.FIELD_MAPPING:
      return isDataSelectionValid(config);
    case NewExportRoutes.REVIEW_EXPORT:
      return isReviewExportSuccessful(config);
    case NewExportRoutes.EXPORT_AND_SCHEDULE:
      return isExportSucceeded(config);
    default:
      return true;
  }
}
