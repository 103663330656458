import { APIOrganizationUser, OrgAccessLevel } from '@ardoq/api-types';

const getInvitationRoles = (): OrgAccessLevel[] => [
  OrgAccessLevel.ADMIN,
  OrgAccessLevel.WRITER,
  OrgAccessLevel.READER,
  OrgAccessLevel.CONTRIBUTOR,
];

export const ROLE_TEXT_MAPPING: Record<OrgAccessLevel, string> = {
  [OrgAccessLevel.ADMIN]: 'Admin',
  [OrgAccessLevel.WRITER]: 'Writer',
  [OrgAccessLevel.READER]: 'Reader',
  [OrgAccessLevel.CONTRIBUTOR]: 'Contributor',
};

const getRoleText = (role: OrgAccessLevel, mapping = ROLE_TEXT_MAPPING) => {
  return mapping[role];
};

interface GetOptionsArgs {
  roles: OrgAccessLevel[];
  currentRole: OrgAccessLevel;
  users: APIOrganizationUser[];
}
const getOptions = ({ roles, currentRole, users }: GetOptionsArgs) => {
  const options = roles.map(role => ({
    value: role,
    label: getRoleText(role),
    isSelected: role === currentRole,
    count: users.filter(user => user.role === role).length,
  }));

  return options;
};

// Exports

export const getInvitationOptions = (
  currentRole: OrgAccessLevel,
  users: APIOrganizationUser[] = []
) => getOptions({ roles: getInvitationRoles(), currentRole, users });
