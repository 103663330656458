import { reducer, RegisteredReducer } from '@ardoq/rxbeach';

import { resetAllSearchState } from 'search/actions';
import advancedSearchOperations from 'search/AdvancedSearch/advancedSearchOperations';

import {
  advancedSearchError,
  queryAdvancedSearch,
  queryAdvancedSearchSuccess,
  selectCondition,
  selectFields,
  selectFilterType,
  selectPage,
  updateAdvancedSearch,
} from './actions';
import { AdvancedSearchStateShape } from 'search/AdvancedSearch/types';

export const reducers: RegisteredReducer<AdvancedSearchStateShape>[] = [
  reducer(
    updateAdvancedSearch,
    advancedSearchOperations.updateAdvancedSearchReducer
  ),
  reducer(
    selectCondition,
    advancedSearchOperations.selectConditionReducer<AdvancedSearchStateShape>
  ),
  reducer(selectFilterType, advancedSearchOperations.selectFilterTypeReducer),
  reducer(
    queryAdvancedSearchSuccess,
    advancedSearchOperations.queryAdvancedSearchSuccessReducer
  ),
  reducer(
    advancedSearchError,
    advancedSearchOperations.advancedSearchErrorReducer
  ),
  reducer(
    queryAdvancedSearch,
    advancedSearchOperations.queryAdvancedSearchReducer<AdvancedSearchStateShape>
  ),
  reducer(
    resetAllSearchState,
    advancedSearchOperations.resetAllSearchStateReducer
  ),
  reducer(selectPage, advancedSearchOperations.selectPageReducer),
  reducer(selectFields, advancedSearchOperations.selectFieldsReducer),
];
