import { CSSProperties, FunctionComponent } from 'react';
import styled from 'styled-components';
import SectionHeader from './sectionHeader';
import Collapsible from 'atomicComponents/Collapsible/Collapsible';

const SectionInner = styled.div`
  padding: 0 16px;
  margin-bottom: 20px;
`;

const FormSection = styled.div`
  margin-bottom: 0;
`;

interface Props {
  title: string;
  children?: any;
  collapsible?: boolean;
  isExpanded?: boolean;
  registerSectionRefHandler?: (
    title: string
  ) => (ref: HTMLDivElement | null) => void;
  showHeader?: boolean;
  onToggleExpand: (isExpanded: boolean) => void;
  onClick?: () => void;
  innerStyle?: CSSProperties;
}

const Section: FunctionComponent<Props> = ({
  title,
  children,
  collapsible = false,
  isExpanded,
  registerSectionRefHandler,
  showHeader = true,
  onToggleExpand,
  innerStyle = {},
}) => {
  return collapsible ? (
    <FormSection ref={registerSectionRefHandler?.(title)}>
      <Collapsible
        Trigger={({ isExpanded }) => (
          <SectionHeader
            title={title}
            isExpanded={isExpanded}
            collapsible={true}
          />
        )}
        isExpanded={isExpanded ?? true}
        onToggleExpand={onToggleExpand}
      >
        <SectionInner style={innerStyle}>{children}</SectionInner>
      </Collapsible>
    </FormSection>
  ) : (
    <FormSection>
      {showHeader && <SectionHeader title={title} />}
      <SectionInner style={innerStyle}>{children}</SectionInner>
    </FormSection>
  );
};

export default Section;
