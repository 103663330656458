import { routine, extractPayload, ofType } from '@ardoq/rxbeach';
import { trackITPediaEvent } from './actions';
import { of, switchMap, tap, withLatestFrom } from 'rxjs';
import { trackEvent } from 'tracking/tracking';
import { EVENT_NAMES } from './types';
import integrationViewState$ from 'integrations/integrationViewState$';
import { getRouteFromString } from 'integrations/ITPedia/utils';
import { funnelIds$ } from './funnelIds$';
import { OverviewRoute } from 'integrations/common/navigation/types';

const handleTrackITPediaEvent = routine(
  ofType(trackITPediaEvent),
  extractPayload(),
  withLatestFrom(integrationViewState$, funnelIds$),
  switchMap(([payload, { integrationPath }, funnelIds]) => {
    const route = getRouteFromString(integrationPath);
    const funnelId =
      route === OverviewRoute.SCHEDULES || route === OverviewRoute.CONNECTIONS
        ? funnelIds.overviewId
        : funnelIds.importId;
    return of({
      ...payload,
      funnelId: funnelId,
    });
  }),
  tap(payload => {
    trackEvent(EVENT_NAMES[payload.name], {
      ...(payload.metadata || {}),
      integrationId: 'it-pedia',
      funnelId: payload.funnelId,
    });
  })
);

export default [handleTrackITPediaEvent];
