import { GhostButton } from '@ardoq/button';
import { Step } from '@ardoq/steppers';
import { ITPediaImportRoutes, ITPediaRoutes } from '../types';
import { ComponentSelection } from './ComponentSelection/ComponentSelection';
import { FieldMapping } from './FieldMapping/FieldMapping';
import { SummarySync } from './SummarySync/SummarySync';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { navigateToPath } from '../navigation/actions';

import { Success } from './SummarySync/Success';
import { Failed } from './SummarySync/Failed';
import { resetConfig } from '../streams/configurationState/actions';
import { confirm } from '@ardoq/modal';
import { setFunnelId, trackITPediaEvent } from '../streams/tracking/actions';
import { v4 as uuidv4 } from 'uuid';
import { viewModel$ } from './viewModel$';
import { StepStatuses } from './types';
import { Stepper } from 'integrations/common/components/stepper';
import { Features, hasFeature } from '@ardoq/features';
import { PageHeader, PageWrapper, PageTopbar } from '@ardoq/page-layout';
import { OverviewRoute } from 'integrations/common/navigation/types';
import { fetchSchedulesList } from 'integrations/common/streams/schedules/actions';
import { listConnections } from 'integrations/common/streams/connections/actions';

type NewSyncProps = {
  route: ITPediaRoutes;
  stepStatuses: StepStatuses;
};

function NewSyncComponent({ route, stepStatuses }: NewSyncProps) {
  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);
  const onResetClick = async () => {
    const confirmation = await confirm({
      title: `Restart import?`,
      subtitle: `Restarting the import will lose all progress.`,
      confirmButtonTitle: 'Restart import',
    });
    if (confirmation && confirmation.confirmed) {
      dispatchAction(trackITPediaEvent({ name: 'RESTARTED_IMPORT' }));
      dispatchAction(setFunnelId({ importId: uuidv4() }));
      dispatchAction(resetConfig());
      dispatchAction(listConnections({ integrationId: 'it-pedia' }));
      dispatchAction(fetchSchedulesList());
      dispatchAction(navigateToPath(OverviewRoute.SCHEDULES));
    }
  };
  return (
    <>
      <PageWrapper>
        {hasNewJourneyFeature ? (
          <PageTopbar
            primaryContent="IT-Pedia integration"
            secondaryContent="Import and integrations"
            secondaryButton={
              <GhostButton onClick={onResetClick}>Restart</GhostButton>
            }
          />
        ) : (
          <PageHeader
            title="IT-Pedia integration"
            rightContent={
              <GhostButton onClick={onResetClick}>Restart</GhostButton>
            }
          />
        )}
        <Stepper>
          <Step
            onPress={() =>
              dispatchAction(navigateToPath(ITPediaImportRoutes.DATA_SELECTION))
            }
            isSelected={stepStatuses.dataSelection.selected}
            state={stepStatuses.dataSelection.state}
            heading="Select components"
          />
          <Step
            isDisabled={!stepStatuses.fieldMapping.enabled}
            onPress={() =>
              dispatchAction(navigateToPath(ITPediaImportRoutes.FIELD_MAPPING))
            }
            isSelected={stepStatuses.fieldMapping.selected}
            state={stepStatuses.fieldMapping.state}
            heading="Map fields"
          />
          <Step
            isDisabled={!stepStatuses.summarySync.enabled}
            onPress={() =>
              dispatchAction(navigateToPath(ITPediaImportRoutes.SUMMARY_SYNC))
            }
            isSelected={stepStatuses.summarySync.selected}
            state={stepStatuses.summarySync.state}
            heading="Review and sync"
          />
        </Stepper>
        {route === ITPediaImportRoutes.DATA_SELECTION && <ComponentSelection />}
        {route === ITPediaImportRoutes.FIELD_MAPPING && <FieldMapping />}
        {route === ITPediaImportRoutes.SUMMARY_SYNC && <SummarySync />}
        {route === ITPediaImportRoutes.SUCCESS && <Success />}
        {route === ITPediaImportRoutes.FAILED && <Failed />}
      </PageWrapper>
    </>
  );
}

export const NewSync = connect(NewSyncComponent, viewModel$);
