import { LoadedScenarioData, loadedScenarioData$ } from 'loadedScenarioData$';

let currentLoadedScenarioData: LoadedScenarioData = {
  scenarioData: null,
  scenarioRelatedGraph: null,
  contextComponents: new Set(),
};

loadedScenarioData$.subscribe(
  scenarioData => (currentLoadedScenarioData = scenarioData)
);

export const getScopeComponents = () =>
  currentLoadedScenarioData.scenarioData?.scopeComponents ?? [];

export const getLoadedScenarioData = () =>
  currentLoadedScenarioData.scenarioData;

export const getScenarioRelatedGraph = () =>
  currentLoadedScenarioData.scenarioRelatedGraph;
