import { connect } from '@ardoq/rxbeach';
import { Drawer } from '@ardoq/snowflakes';
import {
  EnhancedSearchResponse,
  ReportFilterCommands,
  ReportFilterSidebar,
  SearchResultsTable,
  SortFilterPagination,
} from '@ardoq/report-reader';
import { reportFilterDrawerViewModel$ } from './reportFilterDrawerViewModel$';
import { getCurrentLocale } from '@ardoq/locale';
import { BrandButton, SecondaryButton } from '@ardoq/button';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import { Header3, Link } from '@ardoq/typography';
import { StatusType, Tag } from '@ardoq/status-ui';
import { FlexBox, LoadingOverlay, Stack } from '@ardoq/layout';
import { getHasComponentReadPermission } from 'report/ReportReader/utils';
import styled from 'styled-components';
import { colors, s32 } from '@ardoq/design-tokens';
import { PermissionContext } from '@ardoq/access-control';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { ArdoqError, Maybe } from '@ardoq/common-helpers';
import { ReportColumn } from '@ardoq/api-types';
import { trackEvent } from '../../../tracking/tracking';
import { DashboardTrackingEventsNames } from '@ardoq/dashboard';

export type ReportFilterDrawerProps = {
  isSearchBeingExecuted: boolean;
  permissionContext: PermissionContext;
  subdivisionsContext: SubdivisionsContext;
  apiError: Maybe<ArdoqError>;
  searchResults: Maybe<EnhancedSearchResponse>;
  filterableColumns: ReportColumn[];
  commands: ReportFilterCommands;
  onSave: VoidFunction;
  sortFilterPagination: SortFilterPagination;
};

const ReportPreviewContainer = styled(Stack)`
  border-top: 1px solid ${colors.borderSubtle00};
  background-color: ${colors.surfaceDefault};
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow-y: hidden;
  padding: 0 ${s32} ${s32};
`;

const ReportFilterDrawerControls = ({
  onSave,
  handleCloseDrawer,
}: {
  onSave: VoidFunction;
  handleCloseDrawer: VoidFunction;
}) => {
  return (
    <>
      <SecondaryButton
        onClick={() => {
          trackEvent(
            DashboardTrackingEventsNames.CLOSED_WIDGET_FILTER_WITHOUT_SAVING
          );
          handleCloseDrawer();
        }}
      >
        Cancel
      </SecondaryButton>
      <BrandButton
        onClick={() => {
          onSave();
          handleCloseDrawer();
        }}
      >
        Apply filters
      </BrandButton>
    </>
  );
};

export const ReportFilterDrawer = ({
  commands,
  filterableColumns,
  handleCloseDrawer,
  isSearchBeingExecuted,
  onSave,
  sortFilterPagination: { sort, filterQuery, pagination, page },
  searchResults,
  permissionContext,
  subdivisionsContext,
}: ReportFilterDrawerProps & {
  handleCloseDrawer: VoidFunction;
}) => {
  const hasComponentReadPermission = searchResults?.scopeData
    ? getHasComponentReadPermission(
        searchResults.scopeData,
        permissionContext,
        subdivisionsContext
      )
    : undefined;

  return (
    <Drawer
      controls={
        <ReportFilterDrawerControls
          onSave={onSave}
          handleCloseDrawer={handleCloseDrawer}
        />
      }
      drawerSize="large"
      orientation="horizontal"
      paddingX="none"
      paddingBottom="none"
      subtitle="Customize the data used for the chart without affecting the original report."
      title="Filter data from report for chart"
    >
      <PageWrapper>
        <PageBody
          alignIslandsToLeft
          skipPadding
          preventScroll
          rightContent={
            <ReportFilterSidebar
              dataSource={searchResults?.datasource}
              enhancedScopeData={searchResults?.scopeData ?? null}
              filterableColumns={filterableColumns}
              filterQuery={filterQuery}
              commands={commands}
              headerRightContent={
                <Link
                  hideIcon
                  type="primary"
                  typography="text1"
                  onClick={commands.clearAllFilters}
                >
                  Clear all
                </Link>
              }
            />
          }
        >
          <ReportPreviewContainer>
            <FlexBox justify="space-between" align="center" paddingY="medium">
              <Header3>{searchResults?.name ?? 'Unknown report'}</Header3>
              <Tag label="Report preview" statusType={StatusType.INFO} />
            </FlexBox>
            {!searchResults && isSearchBeingExecuted && (
              <LoadingOverlay message="Loading report" />
            )}
            {searchResults && (
              <SearchResultsTable
                searchResults={searchResults}
                builderSelectedAggregate={searchResults.selectedAggregate}
                isInteractive
                hasComponentReadPermission={hasComponentReadPermission}
                currentUserLocale={getCurrentLocale()}
                users={
                  searchResults?.scopeData?.users.reduce(
                    (acc, { _id, name, email }) => ({
                      ...acc,
                      [_id]: { _id, name, email },
                    }),
                    {}
                  ) ?? {}
                }
                showAggregate={false}
                isSearchBeingExecuted={isSearchBeingExecuted}
                sort={sort}
                setSort={commands.setSort}
                pageState={[page, commands.setPage]}
                paginationState={[pagination, commands.setPagination]}
              />
            )}
          </ReportPreviewContainer>
        </PageBody>
      </PageWrapper>
    </Drawer>
  );
};

export default connect(ReportFilterDrawer, reportFilterDrawerViewModel$);
