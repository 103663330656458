import { get } from 'lodash';
import { UnifiedConnection } from '@ardoq/api-types/integrations';
import { ConnectionFieldDefinition } from 'integrations/unified/types';
import { set } from 'lodash/fp';

const emptyInputValidationMessage = (label: string) =>
  `${label} cannot be empty`;

const commonConnectionFields: ConnectionFieldDefinition[] = [
  { keyPath: ['name'], label: 'Name', required: true, type: 'text' },
  // TODO this field should also be configurable per integration
  { keyPath: ['baseUrl'], label: 'Base URL', required: true, type: 'text' },
];

export function validateNewAccountInput(
  fieldDefinitions: ConnectionFieldDefinition[],
  input: UnifiedConnection
) {
  const emptyInput = [...commonConnectionFields, ...fieldDefinitions]
    .filter(field => field.required)
    .find(field => !get(input, field.keyPath));

  return {
    isValid: !emptyInput,
    message: emptyInput ? emptyInputValidationMessage(emptyInput.label) : '',
  };
}

export function getHiddenFieldValues(fields: ConnectionFieldDefinition[]) {
  return fields
    .filter(field => field.type === 'hidden')
    .reduce((result, field) => set(field.keyPath, field.value, result), {
      providerParams: {},
    });
}
