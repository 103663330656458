import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { closePane, splitPane } from './actions';
import { returnFalse, returnTrue } from '@ardoq/common-helpers';

export const isSplitPane$ = persistentReducedStream<boolean>(
  'isSplitPane$',
  false,
  [
    reducer<boolean>(splitPane, returnTrue),
    reducer<boolean>(closePane, returnFalse),
  ]
);
