import { dispatchAction } from '@ardoq/rxbeach';
import { componentHierarchyConfiguration } from 'componentHierarchies/componentHierarchyConfiguration';
import * as mainAppModuleSidebarActions from '../actions';
import { closeAllOpenData } from 'componentHierarchies/actions';

const setFilterTerm = (term: string) =>
  dispatchAction(
    mainAppModuleSidebarActions.setFilterTerm(term),
    componentHierarchyConfiguration.actionNamespace
  );

const closeAll = () => dispatchAction(closeAllOpenData());

export type SidebarInventoryCommands = {
  closeAll: () => void;
  setFilterTerm: (term: string) => void;
};

export const sidebarInventoryCommands: SidebarInventoryCommands = {
  setFilterTerm,
  closeAll,
};
