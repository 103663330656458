import { actionCreator } from '@ardoq/rxbeach';
import { NodeModel } from '../models/types';
import { Position } from '../types';
import { ArdoqId, ReferenceDirection } from '@ardoq/api-types';

export const clearHighlight = actionCreator(
  '[navigatorActions] CLEAR_HIGHLIGHT'
);

export type ContainerCreatedPayload = {
  readonly container: HTMLElement;
};
export const containerCreated = actionCreator<ContainerCreatedPayload>(
  '[navigatorActions] CONTAINER_CREATED'
);

export const containerHeightChanged = actionCreator(
  '[navigatorActions] CONTAINER_HEIGHT_CHANGED'
);

// TODO: This action is not used in the codebase
export const clearTree = actionCreator('[navigatorActions] CLEAR_TREE');

export const containerScrolled = actionCreator(
  '[navigatorActions] CONTAINER_SCROLLED'
);
// TODO where is this used?
export const resetScroll = actionCreator('[navigatorActions] RESET_SCROLL');

export const dragEndTransitionEnd = actionCreator(
  '[navigatorActions] DRAG_END_TRANSITION_END'
);

export type DragEnd = {
  readonly event: MouseEvent | KeyboardEvent;
  isAborted: boolean;
};
export const dragEnd = actionCreator<DragEnd>('[navigatorActions] DRAG_END');

export type FinalizeDragEndPayload = {
  readonly isAborted: boolean;
  readonly isHandled: boolean;
  readonly position: Position;
  readonly changedIds: string[];
};

export const finalizeDragEnd = actionCreator<FinalizeDragEndPayload>(
  '[navigatorActions] FINALIZE_DRAG_END'
);

export type DragStart = {
  readonly dragTargetNode: NodeModel;
  readonly dragStartPosition: Position;
};

export const dragStart = actionCreator<DragStart>(
  '[navigatorActions] DRAG_START'
);

export type DragUpdate = {
  readonly event: MouseEvent;
};
export const dragUpdate = actionCreator<DragUpdate>(
  '[navigatorActions] DRAG_UPDATE'
);

export type LinkUpdatePayload = {
  readonly position: Position;
  readonly linkTarget: Element | null;
};

export const linkUpdate = actionCreator<LinkUpdatePayload>(
  '[navigatorActions] LINK_UPDATE'
);

export type SelectionEnd = {
  readonly nodeId: string;
};
export const selectionEnd = actionCreator<SelectionEnd>(
  '[navigatorActions] SELECTION_END'
);

export type SelectionToggle = {
  nodeId: string;
};
export const selectionToggle = actionCreator<SelectionToggle>(
  '[navigatorActions] SELECTION_TOGGLE'
);

export type SetLinkSourcePayload = {
  readonly linkSourceNodeId: string | null;
  readonly linkSourceIds: ArdoqId[];
  readonly startPosition: Position;
  readonly isStartInNavigator: boolean;
  readonly refDirection: ReferenceDirection;
  readonly abortController: AbortController;
};

export const setLinkSources = actionCreator<SetLinkSourcePayload>(
  '[navigatorActions] SET_LINK_SOURCE'
);

export type SetLinkTargetPayload = {
  // TODO fix this
  targetId: string | null;
};
export const setLinkTarget = actionCreator<SetLinkTargetPayload>(
  '[navigatorActions] SET_LINK_TARGET'
);
export type TreeChangedPayload = {
  reloadNodeIds?: string[];
  removedIds?: string[];
};
// TODO: This action is not used in the codebase
export const treeChanged = actionCreator<TreeChangedPayload>(
  '[navigatorActions] TREE_CHANGED'
);

export type ShowFilteredComponentsChangedPayload = {
  showFilteredComponents: boolean;
};
export const showFilteredComponentsChanged =
  actionCreator<ShowFilteredComponentsChangedPayload>(
    '[navigatorActions] SHOW_FILTERED_COMPONENTS_CHANGED'
  );

interface TreeSelectionChangedPayload {
  selection: NodeModel[];
}
export const treeSelectionChanged = actionCreator<TreeSelectionChangedPayload>(
  '[navigatorActions] TREE_SELECTION_CHANGED'
);

export type IsViewPointModePayload = {
  isViewpointMode: boolean;
};

export const setIsViewpointMode = actionCreator<IsViewPointModePayload>(
  '[navigatorActions] SET_IS_VIEWPOINT_MODE'
);

export type DraggableElementClickedPayload = {
  event: React.MouseEvent<HTMLDivElement, MouseEvent>;
  target?: HTMLElement;
};
export const draggableElementClicked =
  actionCreator<DraggableElementClickedPayload>(
    '[navigatorActions] DRAGGABLE_ELEMENT_CLICKED'
  );

export const clickedInNavigator = actionCreator<React.MouseEvent>(
  '[navigatorActions] CLICKED_IN_NAVIGATOR'
);

export type ExpandCollapseToggleClickedPayload = {
  nodeId: string;
  isDeep?: boolean;
};

export const expandCollapseToggleClicked =
  actionCreator<ExpandCollapseToggleClickedPayload>(
    '[navigatorActions] TOGGLE_EXPAND'
  );

export const contextMenuClicked = actionCreator<MouseEvent>(
  '[navigatorActions] CONTEXT_MENU_CLICKED'
);
export const afterDragStart = actionCreator<{
  ids: Set<string>;
  entityType?: string;
}>('[dragAndDrop] AFTER_DRAG_START');

export const beforeDragEnd = actionCreator<{
  ids: Set<string>;
  targetDropId: string | null;
  dropContext: string | null;
  entityType?: string;
}>('[dragAndDrop] BEFORE_DRAG_END');

export const streamInitialized = actionCreator(
  '[navigatorActions] STREAM_IS_INITIALIZED'
);
