import { colors, s16, s4, s8 } from '@ardoq/design-tokens';
import { FlexBox, Stack } from '@ardoq/layout';
import { header3, Header4, Link, text2 } from '@ardoq/typography';
import styled from 'styled-components';

const CardContainer = styled(Stack)`
  position: relative;
  min-width: 254px;
  height: 209px;
  color: ${colors.textDefault};

  border: 1px solid ${colors.borderAction};
  border-radius: ${s8};
  padding: ${s16};
  background-color: ${colors.surfaceDefault};
`;

const Total = styled.div`
  font-size: 36px;
  font-weight: 600;
`;

const DetailItem = styled(FlexBox).attrs({ justify: 'space-between' })`
  ${text2};
  color: ${colors.textSubtle};
`;
const Details = styled(Stack)`
  margin-top: auto;

  ${DetailItem}:first-child {
    border-bottom: 1px solid ${colors.borderAction};
    margin-bottom: ${s4};
    padding-bottom: ${s4};
  }
`;

const Value = styled.span`
  ${header3};
  color: ${colors.textDefault};
`;

interface TransferSummaryWidgetProps {
  importsCount: number;
  exportsCount: number;
  onViewHistory: VoidFunction;
}

export const TransferSummaryWidget = ({
  importsCount,
  exportsCount,
  onViewHistory,
}: TransferSummaryWidgetProps) => {
  return (
    <CardContainer>
      <Stack>
        <FlexBox justify="space-between" align="center">
          <Header4>Data history</Header4>{' '}
          <Link
            hideIcon
            underlineOnHover
            color={colors.textAction}
            typography="caption"
            onClick={onViewHistory}
          >
            View history
          </Link>
        </FlexBox>
        <Total>{importsCount + exportsCount}</Total>
      </Stack>
      <Details>
        <DetailItem>
          Imports <Value>{importsCount}</Value>
        </DetailItem>
        <DetailItem>
          Exports <Value>{exportsCount}</Value>
        </DetailItem>
      </Details>
    </CardContainer>
  );
};
