import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  createTemplate,
  editWorkspace,
  editWorkspaceModel,
  exportWorkspaceToExcel,
  manageFields,
  manageReferenceTypes,
  setCalculatedFieldEvent,
  setPermissions,
  viewWorkspaceHistory,
  setUserCalculatedFieldEventsAsNotified,
  calculatedFieldEventReceived,
} from './actions';
import { openResourcePermissionDialog } from 'resourcePermissions/actions';
import { APIEntityType, ResourceType } from '@ardoq/api-types';
import searchAPIService from 'search/searchAPIService';
import { createTemplateFromModel } from 'editors/template/templateEditor';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { hideRightPane, showRightPane } from 'appContainer/actions';
import { showWorkspaceModelEditor } from 'workspaceModelEditor/streams/actions';
import { workspaceOptions$ } from './workspaceOptions$';
import { getCalculatedFieldDoneEventToastMessage } from './utils';
import currentUser$ from 'streams/currentUser/currentUser$';
import { CalculatedFieldEventStatus } from './types';
import { ToastType, showToast } from '@ardoq/status-ui';
import { navigateToAuditLog } from 'router/navigationActions';
import { trackAuditLogEntryPoint } from '../../../auditLog/tracking';
import { handleError } from '@ardoq/api';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

const handleViewWorkspaceHistory = routine(
  ofType(viewWorkspaceHistory),
  extractPayload(),
  tap(workspace => {
    trackAuditLogEntryPoint('workspace history');
    dispatchAction(
      navigateToAuditLog({
        entityType: APIEntityType.WORKSPACE,
        entities: [workspace],
      })
    );
  })
);

const handleEditWorkspace = routine(
  ofType(editWorkspace),
  extractPayload(),
  tap(workspaceId => {
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.WORKSPACE_PROPERTIES,
        workspaceId,
      })
    );
  })
);

const handleSetPermissions = routine(
  ofType(setPermissions),
  extractPayload(),
  tap(resourceId => {
    dispatchAction(
      openResourcePermissionDialog({
        resources: [
          {
            resourceId,
            resourceName: workspaceInterface.getWorkspaceName(resourceId)!,
            resourceType: ResourceType.WORKSPACE,
          },
        ],
        originPage: 'workspace',
      })
    );
  })
);

const handleExportWorkspaceToExcel = routine(
  ofType(exportWorkspaceToExcel),
  extractPayload(),
  switchMap(searchAPIService.exportWorkspace),
  handleError()
);

const handleEditWorkspaceModel = routine(
  ofType(editWorkspaceModel),
  extractPayload(),
  tap(workspaceId => {
    dispatchAction(hideRightPane());
    dispatchAction(showWorkspaceModelEditor({ workspaceId }));
  })
);

const handleManageFields = routine(
  ofType(manageFields),
  extractPayload(),
  tap(() => {
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.MANAGE_FIELDS,
      })
    );
  })
);

const handleManageReferenceTypes = routine(
  ofType(manageReferenceTypes),
  extractPayload(),
  tap(() => {
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.MANAGE_REFERENCE_TYPES,
      })
    );
  })
);

const handleCreateTemplate = routine(
  ofType(createTemplate),
  extractPayload(),
  tap(id => {
    createTemplateFromModel(
      workspaceInterface.getModelData(id)!,
      workspaceInterface.getAttribute(id, 'views')!
    );
  })
);

const handleCalculatedFieldEventReceived = routine(
  ofType(calculatedFieldEventReceived),
  extractPayload(),
  // Update state
  tap(calculatedFieldEvent => {
    dispatchAction(setCalculatedFieldEvent(calculatedFieldEvent));
  }),
  withLatestFrom(currentUser$, workspaceOptions$),
  // Handle notification
  tap(
    ([
      calculatedFieldEvent,
      { _id: currentUserId },
      { calculatedFields, calculatedFieldEvents },
    ]) => {
      const { fieldId, status, userId } = calculatedFieldEvent;
      if (
        status !== CalculatedFieldEventStatus.DONE ||
        currentUserId !== userId
      ) {
        return;
      }

      const toastMessage = getCalculatedFieldDoneEventToastMessage(
        currentUserId,
        fieldId,
        { ...calculatedFieldEvents, [fieldId]: calculatedFieldEvent },
        calculatedFields
      );

      if (toastMessage) {
        showToast(toastMessage, ToastType.SUCCESS);
        dispatchAction(setUserCalculatedFieldEventsAsNotified(currentUserId));
      }
    }
  )
);

export default collectRoutines(
  handleEditWorkspace,
  handleEditWorkspaceModel,
  handleExportWorkspaceToExcel,
  handleViewWorkspaceHistory,
  handleSetPermissions,
  handleManageFields,
  handleManageReferenceTypes,
  handleCreateTemplate,
  handleCalculatedFieldEventReceived
);
