import { CreateNewButton, PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import { Icon, IconName } from '@ardoq/icons';
import {
  APISurveyPendingApprovalsSummary,
  APISurveyAttributes,
} from '@ardoq/api-types';
import { PrimaryButton, GhostButton } from '@ardoq/button';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { trackEvent } from 'tracking/tracking';
import { SurveyTrackingEventsNames } from 'surveyAdmin/tracking';
import OverviewTable from './OverviewTable';
import { surveyAccessControlInterface } from 'resourcePermissions/accessControlHelpers/surveys';
import SurveySettingsSidebar from './SurveySettingsSidebar';
import { dispatchAction } from '@ardoq/rxbeach';
import { toggleSurveySettingsSidebar } from 'surveyAdmin/streams/actions';
import { hasFeature, Features } from '@ardoq/features';
import MainToolbar from 'menus/topbar/MainToolbar';
import Navbar from 'views/navbar/Navbar';
import { useWindowSize } from '@ardoq/hooks';
import { PageHeader, PageBody, PageWrapper } from '@ardoq/page-layout';

const filterByPermissions = (survey: APISurveyAttributes) => {
  return (
    surveyAccessControlInterface.canAdminSurvey(survey._id) ||
    surveyAccessControlInterface.canEditSurvey(survey._id) ||
    (survey.published &&
      surveyAccessControlInterface.canSubmitSurvey(survey._id))
  );
};

type SurveyOverviewProps = {
  surveys: APISurveyAttributes[];
  surveysWithPendingApprovals: APISurveyPendingApprovalsSummary;
  showSurveySettingsSidebar: boolean;
  addSurvey: () => void;
};

const SurveyOverview = ({
  surveys = [],
  addSurvey,
  surveysWithPendingApprovals,
  showSurveySettingsSidebar,
}: SurveyOverviewProps) => {
  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);

  const filteredSurveys = surveys.filter(survey => filterByPermissions(survey));
  const windowHeight = useWindowSize().height;

  // Needed to prevent a scrollbar on the overview page
  const PIXELS_ABOVE_ASSETS_BROWSER = hasNewJourneyFeature
    ? PAGE_TOPBAR_HEIGHT
    : 105;
  const PAGE_BODY_PADDING = 64;

  return (
    <PageWrapper>
      {hasNewJourneyFeature ? (
        <Navbar
          primaryContent="Surveys"
          primaryButton={
            surveyAccessControlInterface.canCreateSurvey() && (
              <CreateNewButton
                onClick={() => {
                  trackEvent('Topbar: clicked on create new survey');
                  addSurvey();
                }}
                data-intercom-target="new survey"
                dataTestId="new-survey-button"
              />
            )
          }
          secondaryContent="Overview"
          secondaryButton={
            currentUserInterface.currentUserIsOrgAdmin() && (
              <GhostButton
                onClick={() => {
                  trackEvent('Topbar: clicked on edit organization settings');
                  dispatchAction(toggleSurveySettingsSidebar());
                }}
                data-intercom-target="survey org settings"
                dataTestId="org-settings-button"
                data-tooltip-text="Settings"
              >
                <Icon iconName={IconName.SETTINGS} />
                Settings
              </GhostButton>
            )
          }
          toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
        />
      ) : (
        <PageHeader
          title="Surveys"
          rightContent={
            <>
              {currentUserInterface.currentUserIsOrgAdmin() && (
                <GhostButton
                  onClick={() => {
                    trackEvent(
                      SurveyTrackingEventsNames.CLICKED_ON_EDIT_ORGANIZATION_SETTINGS_IN_SURVEY_OVERVIEW
                    );
                    dispatchAction(toggleSurveySettingsSidebar());
                  }}
                  data-intercom-target="survey org settings"
                  dataTestId="org-settings-button"
                  data-tooltip-text="Settings"
                >
                  <Icon iconName={IconName.SETTINGS} />
                  Settings
                </GhostButton>
              )}
              {surveyAccessControlInterface.canCreateSurvey() && (
                <PrimaryButton
                  onClick={() => {
                    trackEvent('Topbar: clicked on create new survey');
                    addSurvey();
                  }}
                  data-intercom-target="new survey"
                  dataTestId="new-survey-button"
                >
                  Create new
                </PrimaryButton>
              )}
            </>
          }
        />
      )}
      <PageBody
        alignIslandsToLeft
        preventScroll
        rightContent={showSurveySettingsSidebar && <SurveySettingsSidebar />}
        backgroundColor={hasNewJourneyFeature ? 'surfaceDefault' : undefined}
      >
        <OverviewTable
          surveys={filteredSurveys}
          surveysWithPendingApprovals={surveysWithPendingApprovals}
          height={
            windowHeight - PIXELS_ABOVE_ASSETS_BROWSER - PAGE_BODY_PADDING
          }
        />
      </PageBody>
    </PageWrapper>
  );
};

export default SurveyOverview;
