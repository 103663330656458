import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  fetchResourcesSummarySuccess,
  FetchResourcesSummarySuccessPayload,
  setAsyncStatus,
  SetAsyncStatusPayload,
} from './actions';
import { ResourcesSummaryState } from './types';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';

const defaultState: ResourcesSummaryState = {
  asyncStatus: 'INIT',
  resourcesSummary: [],
};

const handleSetAsyncStatus = (
  state: ResourcesSummaryState,
  { asyncStatus }: SetAsyncStatusPayload
) => ({ ...state, asyncStatus });

const handleSetAsyncStatusReducer = reducer<
  ResourcesSummaryState,
  SetAsyncStatusPayload
>(setAsyncStatus, handleSetAsyncStatus);

const handleFetchResourcesSummarySuccess = (
  state: ResourcesSummaryState,
  { resourcesSummary }: FetchResourcesSummarySuccessPayload
): ResourcesSummaryState => ({
  ...state,
  asyncStatus: 'SUCCESS',
  resourcesSummary,
});

const handleFetchResourcesSummarySuccessReducer = reducer<
  ResourcesSummaryState,
  FetchResourcesSummarySuccessPayload
>(fetchResourcesSummarySuccess, handleFetchResourcesSummarySuccess);

const handleResetIntegration = () => defaultState;

const handleResetIntegrationReducer = reducer<
  ResourcesSummaryState,
  IntegrationId
>(resetIntegration, handleResetIntegration);

export const resourcesSummary$ = persistentReducedStream(
  `integrationAzureResourcesSummary$`,
  defaultState,
  [
    handleSetAsyncStatusReducer,
    handleFetchResourcesSummarySuccessReducer,
    handleResetIntegrationReducer,
  ]
);
