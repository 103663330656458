import { actionCreator } from '@ardoq/rxbeach';
import { BroadcastContent, ComponentPreviewResponse } from '@ardoq/api-types';

export const openComponentPreviewDialog = actionCreator<BroadcastContent>(
  '[componentPreview] OPEN_COMPONENT_PREVIEW_DIALOG'
);

export const fetchPreviewComponents = actionCreator<BroadcastContent>(
  '[componentPreview] FETCH_PREVIEW_COMPONENTS'
);

export const fetchPreviewComponentsError = actionCreator(
  '[componentPreview] FETCH_PREVIEW_COMPONENTS_ERROR'
);

export const setPreviewComponentsAndTotal =
  actionCreator<ComponentPreviewResponse>(
    '[componentPreview] SET_PREVIEW_COMPONENTS_AND_TOTAL'
  );
