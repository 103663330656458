import { ButtonType } from '@ardoq/button';
import { useRef, useState } from 'react';
import { AqLayout, FlexBox } from '@ardoq/layout';
import { PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import { alert } from '@ardoq/modal';
import AssetsBrowserWithDndRows from './AssetsBrowserWithDndRows';
import dashboardContainer$ from './dashboardContainer$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { goToMetamodel } from 'metamodel/navigationUtils';
import {
  ExpandedFoldersFiltersByType,
  updateExpandedFoldersFiltersByType,
  updateExpandedFoldersIds,
  updateExpandedFoldersSearchPhrase,
  updateExpandedFoldersShowFavoritesOnly,
  updateExpandedFoldersSortParams,
} from './expandedFolders$';
import { DashboardFiltersBar, LoadingIndicator, StatusMessage } from './atoms';
import {
  Highlight,
  withHighlightStatusMap,
} from 'utils/withHighlightStatusMap';
import { searchDatasourceByName } from 'components/EntityBrowser/utils';
import { collectExpandedIdsFromDataSource, RowType } from '@ardoq/table';
import { Row } from 'components/EntityBrowser/types';
import { AssetRow } from 'components/AssetsBrowser/types';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import {
  APISurveyPendingApprovalsSummary,
  ArdoqId,
  AssetType,
  SortParams,
  Asset,
} from '@ardoq/api-types';
import { openNewEditor as openMetamodelCreator } from 'metamodel/utils';
import { openScenario } from 'scope/actions';
import {
  findSelectedFolders,
  getPermissionsForSelection,
  moveAssetsToFolder,
  openCreateFolderModal,
  openNewBroadcast,
  openPresentationCreator,
  openSurveyCreator,
  openViewpointCreator,
  openWorkspaceWizard,
  openWorkspaces,
  showTableRowById,
  updateNamePromise,
} from './utils';
import { getMenuOptions as getMenuOptionsUtil } from './getMenuOptions';
import { trackOpenSelectedWorkspaces } from 'components/assetsBrowserDialog/tracking';
import { logError } from '@ardoq/logging';
import SelectAssetTypeBar from './SelectAssetTypeBar';
import { SelectableAssetType } from './types';
import { openPresentationEditor } from 'presentationEditor/presentationUtil';
import { openMoveToModal } from 'components/FolderSelectDialog/utils';
import { uniq } from 'lodash';
import { confirmOpenManagedWorkspaces } from 'externallyManaged/confirmOpenManagedWorkspaces/confirmOpenManagedWorkspaces';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import {
  hasPermission,
  Permissions,
} from 'streams/currentUserPermissions/permissionInterface';
import { GhostButton } from '@ardoq/button';
import {
  openCreateReport,
  openDashboard,
  openReportInReader,
} from 'components/AppMainSidebar/utils';
import { ButtonWithDropdown, ContextMenuProvider } from '@ardoq/dropdown-menu';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { SearchInput } from '@ardoq/forms';
import { ReportEventLocations } from '@ardoq/report-reader';
import { trackOpenedPresentationSidebar } from 'presentation/tracking';
import { confirmMoveAssets } from './confirmMoveAssets';
import { getDashboardContainerRowsColumns } from './columns';
import { trackEvent } from 'tracking/tracking';
import { DashboardAssetBrowserCommands } from './commands';
import { dashboardAccessControlInterface } from 'resourcePermissions/accessControlHelpers/dashboards';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { trackOpenExistingSurvey } from 'surveyAdmin/tracking';
import { navigateToSurveyForm } from 'surveyAdmin/navigation/actions';
import { PermissionContext } from '@ardoq/access-control';
import { DashboardModule } from 'dashboard/types';
import { colors } from '@ardoq/design-tokens';
import { mapIdsToAssets } from '../../components/AssetsBrowser2024/assetsBrowser2024Utils';
import { Locale } from '@ardoq/locale';
import { useResizeObserver } from '@ardoq/hooks';

export type DashboardContainerProps = {
  currentUserIsOrgWriter: boolean;
  presentationsEnabled: boolean;
  surveysEnabled: boolean;
  traversalsEnabled: boolean;
  bookmarksEnabled: boolean;
  canCreateSurvey: boolean;
  broadcastsEnabled: boolean;
  discoverEnabled: boolean;
  canCreateViewpoint: boolean;
  dataSource: Row<AssetRow>[];
  setHighlights: (ids: string[]) => void;
  highlightStatusMap: { [id: string]: Highlight };
  folderIds?: string[];
  expandedFoldersIds: string[];
  expandedFoldersSearchPhrase: string;
  expandedFoldersSortParams: SortParams;
  expandedFoldersFiltersByType: ExpandedFoldersFiltersByType;
  expandedFoldersShowFavoritesOnly: boolean;
  scenariosEnabled: boolean;
  assetsById: Record<string, Asset>;
  commands: DashboardAssetBrowserCommands;
  permissionContext: PermissionContext;
  hasNewJourneyFeature: boolean;
  assetsBrowser2024Datasource: Row<AssetRow>[];
  surveysWithPendingApprovals: APISurveyPendingApprovalsSummary;
  locale: Locale;
};

const setFiltersByType = (
  type: SelectableAssetType | null,
  currentFiltersByType: ExpandedFoldersFiltersByType,
  clearSelected: () => void
) => {
  clearSelected();
  dispatchAction(
    updateExpandedFoldersFiltersByType({
      filtersByType: type
        ? {
            ...currentFiltersByType,
            [type]: !currentFiltersByType[type],
          }
        : null,
    })
  );
};

const trackAndOpenWorkspaces = (selectedWorkspacesIds: string[]) => {
  openWorkspaces(selectedWorkspacesIds, {
    trackingLocation: 'assetsManager',
  });
  trackOpenSelectedWorkspaces({ numWorkspaces: selectedWorkspacesIds.length });
};

const DashboardContainer = ({
  dataSource,
  currentUserIsOrgWriter,
  setHighlights,
  highlightStatusMap,
  folderIds,
  expandedFoldersIds,
  expandedFoldersSearchPhrase,
  expandedFoldersSortParams,
  expandedFoldersFiltersByType,
  expandedFoldersShowFavoritesOnly,
  scenariosEnabled,
  assetsById,
  presentationsEnabled,
  surveysEnabled,
  surveysWithPendingApprovals,
  traversalsEnabled,
  bookmarksEnabled,
  canCreateSurvey,
  broadcastsEnabled,
  discoverEnabled,
  canCreateViewpoint,
  commands,
  permissionContext,
  hasNewJourneyFeature,
  locale,
}: DashboardContainerProps) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [renameId, setRenameId] = useState<ArdoqId | null>(null);
  const [previewId, setPreviewId] = useState<ArdoqId | null>(null);
  const [selected, setSelected] = useState<ArdoqId[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const size = useResizeObserver(ref);

  const clearSelected = () => setSelected([]);
  const selectedWorkspacesIds = selected.filter(assetId => {
    const asset = assetsById[assetId];
    return asset?.meta.assetType === AssetType.WORKSPACE;
  });
  const hasSelectedWorkspaces = !!selectedWorkspacesIds.length;
  const setExpandedFoldersIds = (foldersIds: ArdoqId[]) => {
    dispatchAction(updateExpandedFoldersIds({ foldersIds }));
  };

  const setExpandedFoldersSearchPhrase = (searchPhrase = '') => {
    dispatchAction(updateExpandedFoldersSearchPhrase({ searchPhrase }));
    setExpandedFoldersIds(
      collectExpandedIdsFromDataSource(
        searchDatasourceByName(dataSource, searchPhrase, locale),
        searchPhrase
      )
    );
  };

  const selectionPermissions = getPermissionsForSelection(selected, assetsById);
  const selectedFolders = findSelectedFolders(selected, dataSource);
  const getMenuOptions = (row: Row<AssetRow>) => {
    // just to make TS happy
    if (row.rowType === RowType.EMPTY_FOLDER) return [];
    return getMenuOptionsUtil(row, {
      selected,
      selectedAssets: mapIdsToAssets(selected, assetsById),
      selectedFolders,
      selectionPermissions,
      rowPermissions: row.meta.permissions || {},
      surveysWithPendingApprovals,
      onRenameSelect: setRenameId,
      clearSelected,
      handleMoveToModal: async assets => {
        const selectedFolderId = await openMoveToModal();
        if (selectedFolderId === undefined || selectedFolderId === false)
          return;

        const isConfirmed = await confirmMoveAssets({
          assetsIds: selected,
          folderId: selectedFolderId,
          assetsById,
        });
        if (!isConfirmed) return;

        setIsUpdating(true);
        clearSelected();
        await moveAssetsToFolder(assets, selectedFolderId);

        setIsUpdating(false);
        setExpandedFoldersIds(
          uniq([...expandedFoldersIds, selectedFolderId].filter(ExcludeFalsy))
        );
        setHighlights(
          [selectedFolderId, ...assets.map(({ _id }) => _id)].filter(
            ExcludeFalsy
          )
        );
      },
    });
  };

  return (
    <FlexBox
      flexDirection="column"
      width="full"
      ref={ref}
      style={{ background: colors.white }}
    >
      <AqLayout
        title="Home"
        bodyContentStyle={{
          padding: 0,
          height: '100%',
          position: 'relative',
        }}
        renderHeaderButtons={() => (
          <>
            <StatusMessage $disabled={!selected.length}>
              Selected {selected.length}{' '}
              {selected.length === 1 ? 'asset' : 'assets'}
            </StatusMessage>
            <GhostButton
              isDisabled={!hasSelectedWorkspaces}
              onClick={async () => {
                if (
                  selectedWorkspacesIds.some(
                    workspaceInterface.isExternallyManaged
                  )
                ) {
                  const confirmed = await confirmOpenManagedWorkspaces({
                    workspaceNames: selectedWorkspacesIds
                      .filter(workspaceInterface.isExternallyManaged)
                      .map(workspaceInterface.getWorkspaceName)
                      .filter(ExcludeFalsy),
                  });
                  if (!confirmed) return;
                }
                trackAndOpenWorkspaces(selectedWorkspacesIds);
              }}
            >
              Open selected
            </GhostButton>
            <ButtonWithDropdown
              dataTestId="create-new-dropdown"
              isDisabled={!currentUserIsOrgWriter}
              dropdownSize={DropdownSize.S}
              alignMenuTo="right"
              onClick={() => trackEvent('open-create-dropdown')}
              options={[
                {
                  label: 'Folder',
                  testId: 'open-create-folder-modal',
                  onClick: async () => {
                    trackEvent('open-create-folder-modal');
                    const entity = await openCreateFolderModal();
                    if (!entity) return;
                    setHighlights([entity._id]);
                    showTableRowById(entity._id);
                  },
                },
                workspaceAccessControlInterface.canCreateWorkspace(
                  permissionContext
                ) && {
                  label: 'Workspace',
                  testId: 'open-create-workspace-wizard',
                  onClick: () => {
                    trackEvent('open-create-workspace-wizard');
                    openWorkspaceWizard();
                  },
                },
                presentationsEnabled && {
                  label: 'Presentation',
                  testId: 'open-presentation-creator',
                  onClick: () => {
                    trackEvent('open-presentation-creator');
                    openPresentationCreator();
                  },
                },
                hasPermission(Permissions.METAMODELS_CREATE) && {
                  label: 'Metamodel',
                  testId: 'open-metamodel-creator',
                  onClick: () => {
                    trackEvent('open-metamodel-creator');
                    openMetamodelCreator({
                      trackingLocation: 'assetsManager',
                    });
                  },
                },
                surveysEnabled &&
                  canCreateSurvey && {
                    label: 'Survey',
                    testId: 'open-survey-creator',
                    onClick: () => {
                      trackEvent('open-survey-creator');
                      openSurveyCreator(null);
                    },
                  },
                discoverEnabled &&
                  canCreateViewpoint && {
                    label: 'Viewpoint',
                    testId: 'open-viewpoint-creator',
                    onClick: () => {
                      trackEvent('open-viewpoint-creator');
                      openViewpointCreator();
                    },
                  },
                broadcastsEnabled && {
                  label: 'Broadcast',
                  testId: 'open-new-broadcast',
                  onClick: () => {
                    trackEvent('open-new-broadcast');
                    openNewBroadcast(null);
                  },
                },
                hasPermission(Permissions.REPORTS_CREATE) && {
                  label: 'Report',
                  testId: 'open-new-report',
                  onClick: () => {
                    trackEvent('open-new-report');
                    openCreateReport(ReportEventLocations.ASSET_MANAGER);
                  },
                },
                dashboardAccessControlInterface.canCreateDashboard(
                  permissionContext
                ) && {
                  label: 'Dashboard',
                  testId: 'open-new-dashboard',
                  onClick: () => {
                    trackEvent('open-new-dashboard');
                    openDashboard({
                      dashboardModule: DashboardModule.BUILDER,
                    });
                  },
                },
                traversalsEnabled && {
                  label: 'Traversal',
                  testId: 'open-traversal-creator',
                  onClick: () => {
                    trackEvent('open-traversal-creator');
                    commands.openViewpointBuilderToCreateViewpoint();
                  },
                },
              ].filter(ExcludeFalsy)}
              buttonType={ButtonType.GHOST}
            >
              Create new
            </ButtonWithDropdown>
          </>
        )}
        fullWidth={true}
      >
        {isUpdating && <LoadingIndicator loading />}
        <DashboardFiltersBar>
          <SelectAssetTypeBar
            traversalsEnabled={traversalsEnabled}
            presentationsEnabled={presentationsEnabled}
            surveysEnabled={surveysEnabled}
            scenariosEnabled={scenariosEnabled}
            toggleFilter={(type: SelectableAssetType | null) =>
              setFiltersByType(
                type,
                expandedFoldersFiltersByType,
                clearSelected
              )
            }
            filters={expandedFoldersFiltersByType}
            toggleShowFavoritesOnly={() => {
              dispatchAction(
                updateExpandedFoldersShowFavoritesOnly({
                  showFavoritesOnly: !expandedFoldersShowFavoritesOnly,
                })
              );
            }}
            showFavoritesOnly={expandedFoldersShowFavoritesOnly}
            bookmarksEnabled={bookmarksEnabled}
          />
          <SearchInput
            value={expandedFoldersSearchPhrase}
            onValueChange={setExpandedFoldersSearchPhrase}
            onFocus={() => trackEvent('Assets Manager: used type to filter')}
          />
        </DashboardFiltersBar>
        <ContextMenuProvider dropdownSize={DropdownSize.S}>
          {({ openContextMenu }) => (
            <AssetsBrowserWithDndRows
              dataSource={dataSource}
              onTableClick={(e, row) => {
                if (!row || row.rowType === RowType.EMPTY_FOLDER) {
                  return;
                }
                e.preventDefault();
                const menuOptions = getMenuOptions(row);
                if (e.type === 'contextmenu' && menuOptions.length) {
                  setPreviewId(row._id);
                  openContextMenu(e, {
                    options: menuOptions,
                    onClose: () => setPreviewId(null),
                  });
                }
              }}
              getCustomColumns={columnProps =>
                getDashboardContainerRowsColumns({
                  ...columnProps,
                  getMenuOptions,
                })
              }
              tableHeight={
                (size.height || 0) -
                (130 + (hasNewJourneyFeature ? PAGE_TOPBAR_HEIGHT : 0))
              }
              previewId={previewId}
              // Simplification
              // Check: https://docs.google.com/spreadsheets/d/1VF4WXDlHEZpksxdW4PzUQ8nb055t7wrmbl4OtdIX_Yk/edit#gid=1065175734
              // if user is not at least writer then any dragging won't be possible
              // so there is no need to check individually each row
              dndDisabled={!currentUserIsOrgWriter || !!renameId}
              folderIds={folderIds}
              expandedFoldersIds={expandedFoldersIds}
              setHighlights={setHighlights}
              highlightStatusMap={highlightStatusMap}
              setExpandedFoldersIds={setExpandedFoldersIds}
              initialSortParams={expandedFoldersSortParams}
              onSortParamsChange={sortParams =>
                dispatchAction(updateExpandedFoldersSortParams({ sortParams }))
              }
              searchPhrase={expandedFoldersSearchPhrase}
              selected={selected}
              setSelected={setSelected}
              onAssetClick={async (row: AssetRow) => {
                if (row.rowType === RowType.WORKSPACE) {
                  openWorkspaces([row._id], {
                    trackingLocation: 'assetsManager',
                  });
                  return;
                }
                if (row.rowType === RowType.MANAGED_WORKSPACE) {
                  const confirmed = await confirmOpenManagedWorkspaces({
                    workspaceNames: [row.name],
                  });
                  if (confirmed) {
                    openWorkspaces([row._id], {
                      trackingLocation: 'assetsManager',
                    });
                  }
                  return;
                }
                if (row.rowType === RowType.PRESENTATION) {
                  openPresentationEditor({ presentationId: row._id });
                  trackOpenedPresentationSidebar('Asset manager', row._id);
                  return;
                } else if (row.rowType === RowType.SURVEY) {
                  if (row.meta.permissions.canUpdate) {
                    dispatchAction(navigateToSurveyForm(row._id));
                    trackOpenExistingSurvey(row._id, 'assetsManager');
                  }
                  return;
                }
                if (row.rowType === RowType.METAMODEL) {
                  goToMetamodel(row._id);
                  return;
                }
                if (row.rowType === RowType.SCENARIO) {
                  dispatchAction(
                    openScenario({
                      scenarioId: row._id,
                      trackingClickSource: 'assetManager',
                    })
                  );
                  return;
                }
                if (row.rowType === RowType.REPORT) {
                  openReportInReader({
                    reportId: row._id,
                  });
                  return;
                }
                if (row.rowType === RowType.DASHBOARD) {
                  openDashboard({
                    dashboardId: row._id,
                  });
                  return;
                }
                if (row.rowType === RowType.TRAVERSAL) {
                  await commands.openViewpointBuilderToSelectStartComponentsForViewpoint(
                    row._id
                  );
                  return;
                }
                if (row.rowType === RowType.BOOKMARK) {
                  await commands.openBookmark(row._id);
                  return;
                }
                if (row.rowType === RowType.VIEWPOINT) {
                  await commands.openDiscoverViewpoint(row._id);
                  return;
                }
                if (row.rowType === RowType.BROADCAST) {
                  await commands.openBroadcast(row._id, row.status);
                  return;
                }

                row satisfies never;
              }}
              onAssetsMove={async (assetsIds, folderId) => {
                const isConfirmed = await confirmMoveAssets({
                  assetsIds,
                  folderId,
                  assetsById,
                });
                if (!isConfirmed) return;

                setIsUpdating(true);
                await moveAssetsToFolder(
                  mapIdsToAssets(assetsIds, assetsById),
                  folderId
                );
                setIsUpdating(false);
              }}
              renameId={renameId}
              renameConfirm={async (newName: string, row: Row<AssetRow>) => {
                setIsUpdating(true);
                const result = await updateNamePromise(newName, row);
                if (isArdoqError(result)) {
                  logError(result, 'Renaming item failed');
                  await alert({
                    title: 'Error',
                    subtitle: 'Sorry, name update failed',
                  });
                }
                // @ts-expect-error _id doesn't exist on EmptyFolder
                setHighlights([row._id]);
                setRenameId(null);
                setIsUpdating(false);
              }}
              renameCancel={() => setRenameId(null)}
            />
          )}
        </ContextMenuProvider>
      </AqLayout>
    </FlexBox>
  );
};

export default withHighlightStatusMap<DashboardContainerProps>(
  connect(DashboardContainer, dashboardContainer$)
);
