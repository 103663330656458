import { AdditionalContentProps } from 'components/DiffMergeTable/types';
import {
  FieldLabelRenderer,
  Renderer,
  getEntityById,
  getEntityModelAndTypeID,
  getModelTypeDictionaryKey,
  hasComponentField,
  hasReferenceField,
  ignoredProperties,
  transposeFieldNameIfNeeded,
} from '@ardoq/renderers';
import styled from 'styled-components';
import {
  APIComponentAttributes,
  APIComponentType,
  APIEntityType,
  APIFieldAttributes,
  APIModelAttributes,
  APIReferenceAttributes,
  APIReferenceType,
  APITagAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import { pageViewMediumTemp } from 'app/designTokens/fonts';
import { fontMixins } from '@ardoq/typography';
import type { EnhancedScopeData } from '@ardoq/data-model';

const TableWithoutBorderSpace = styled.table`
  border-spacing: 0;
  tr:hover {
    [data-ardoq-popover-id] {
      display: inline-flex;
    }
  }
`;

const Td = styled.td`
  ${pageViewMediumTemp};
  padding: 0 8px;
  min-width: 300px;
`;

const TdBold = styled.td`
  ${fontMixins.semibold16}
  padding: 0 8px;
  min-width: 300px;
`;

const getId = (
  entity:
    | APIComponentAttributes
    | APIReferenceAttributes
    | APIFieldAttributes
    | APIComponentType
    | APIReferenceType
    | APITagAttributes
    | APIModelAttributes
) =>
  (
    entity as
      | APIComponentAttributes
      | APIReferenceAttributes
      | APIFieldAttributes
      | APITagAttributes
      | APIModelAttributes
  )._id || (entity as APIComponentType | APIReferenceType).id;

const isFieldNameBlackListed = (entityType: APIEntityType, fieldName: string) =>
  ignoredProperties[entityType].has(fieldName) ||
  (entityType === APIEntityType.FIELD &&
    (fieldName === 'global' || fieldName === 'globalref'));

const getFieldNameFilter =
  (
    entityId: ArdoqId,
    entityType: APIEntityType,
    enhancedScopeData: EnhancedScopeData
  ) =>
  (fieldName: string) => {
    const isFieldNameNotBlackListed = !isFieldNameBlackListed(
      entityType,
      fieldName
    );
    if (entityType === APIEntityType.COMPONENT) {
      return (
        isFieldNameNotBlackListed &&
        hasComponentField(entityId, fieldName, enhancedScopeData)
      );
    } else if (entityType === APIEntityType.REFERENCE) {
      return (
        isFieldNameNotBlackListed &&
        hasReferenceField(entityId, fieldName, enhancedScopeData)
      );
    }
    return isFieldNameNotBlackListed;
  };

const COMPONENT_DEFAULT_FIELDS = [
  'description',
  'name',
  'parent',
  'typeId',
  'rootWorkspace',
];

const REFERENCE_DEFAULT_FIELDS = [
  'description',
  'type',
  'source',
  'sourceCardinality',
  'target',
  'targetCardinality',
  'rootWorkspace',
  'targetWorkspace',
  'displayText',
];

const defaultFieldsFirst = (fieldNames: string[], defaultFields: string[]) => [
  ...defaultFields,
  ...fieldNames.filter(fieldName => !defaultFields.includes(fieldName)),
];

const sortAndFilterFieldNames = (
  allFieldNames: string[],
  entityId: ArdoqId,
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeData
) => {
  const fieldNames = allFieldNames.filter(
    getFieldNameFilter(entityId, entityType, enhancedScopeData)
  );
  if (entityType === APIEntityType.REFERENCE) {
    return defaultFieldsFirst(fieldNames, REFERENCE_DEFAULT_FIELDS);
  }
  if (entityType === APIEntityType.COMPONENT) {
    return defaultFieldsFirst(fieldNames, COMPONENT_DEFAULT_FIELDS);
  }
  return fieldNames;
};

const collectFieldNames = (
  entity: APIComponentAttributes | APIReferenceAttributes,
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeData
) => {
  if (
    entityType !== APIEntityType.COMPONENT &&
    entityType !== APIEntityType.REFERENCE
  ) {
    return Object.keys(entity);
  }

  const { model, typeId } = getEntityModelAndTypeID(
    entityType,
    entity._id ?? entity.id,
    enhancedScopeData
  );
  if (!model || !typeId) return [];
  const { fieldsByModelIdAndTypeId } = enhancedScopeData;
  const dictionaryKey = getModelTypeDictionaryKey(model, typeId);
  return Object.keys(fieldsByModelIdAndTypeId[dictionaryKey] || {});
};

const EntityPropertyValueList = ({
  entityType,
  dataSourceItem,
  branch,
  users,
  options,
  graphics,
}: AdditionalContentProps) => {
  const enhancedScopeData = dataSourceItem.enhancedDiffContextData[branch];
  const entity = getEntityById(
    entityType,
    dataSourceItem.entityId,
    enhancedScopeData,
    dataSourceItem.parentEntityId
  );
  if (!entity) return null;
  const customFieldNames = collectFieldNames(
    entity as APIComponentAttributes | APIReferenceAttributes,
    entityType,
    enhancedScopeData
  );
  return (
    <TableWithoutBorderSpace>
      <tbody>
        {sortAndFilterFieldNames(
          customFieldNames,
          dataSourceItem.entityId,
          entityType,
          enhancedScopeData
        ).map(fieldName => {
          const transposedFieldName = transposeFieldNameIfNeeded({
            fieldName,
            enhancedScopeData,
            entityType,
            entityId: dataSourceItem.entityId,
          });
          return (
            <tr key={fieldName}>
              <TdBold>
                <FieldLabelRenderer
                  fieldName={transposedFieldName}
                  enhancedScopeData={enhancedScopeData}
                  entityType={entityType}
                />
              </TdBold>
              <Td>
                <Renderer
                  entityType={entityType}
                  entityId={getId(entity)}
                  parentEntityId={dataSourceItem.parentEntityId}
                  fieldName={fieldName}
                  enhancedScopeData={enhancedScopeData}
                  users={users}
                  options={options}
                  graphics={graphics}
                />
              </Td>
            </tr>
          );
        })}
      </tbody>
    </TableWithoutBorderSpace>
  );
};

export default EntityPropertyValueList;
