import {
  PermissionGroup,
  SSOMappingAction,
  AddToGroupAction,
  SSOAttributeMappingPreviewAPIResponse,
} from '@ardoq/api-types';
import { AttributeValue, Claims, GetGroupName, RulesById } from './types';
import { inferAttributeType } from './utils';

const pairUpAttributeNamesAndMatchedGroups = (
  mappingPreview: SSOAttributeMappingPreviewAPIResponse,
  getGroupName: GetGroupName
) => {
  const currentPreviewPairs = mappingPreview['matching-mappings'].reduce<
    Record<string, string[]>
  >((acc, { clause, action }) => {
    return {
      ...acc,
      [clause.attribute]: [
        ...(acc[clause.attribute] || []),
        getGroupName(action.groupId),
      ],
    };
  }, {});
  return (attributeName: string): string[] =>
    currentPreviewPairs[attributeName] || [];
};

const maybeConvertToArray = (value: AttributeValue) =>
  (Array.isArray(value) ? value : [value]) as string[] | number[];

const getIsUnmapped = (currentMapping: RulesById) => {
  const mappedAttributes = Object.values(currentMapping).map(
    ({ clause }) => clause.attribute
  );
  return (attributeName: string) => !mappedAttributes.includes(attributeName);
};

const pairUpGroupIdsAndNames = (
  groupsById: Record<string, PermissionGroup>
): GetGroupName => {
  const groupsNamesById = Object.entries(groupsById).reduce<
    Record<string, string>
  >((acc, [id, { name }]) => ({ [id]: name, ...acc }), {});
  return (groupId: string) => groupsNamesById[groupId];
};

const getLeaveGroupsNames = (
  mappingPreview: SSOAttributeMappingPreviewAPIResponse,
  getGroupName: GetGroupName
) =>
  mappingPreview[SSOMappingAction.ADD_TO_GROUP][AddToGroupAction.LEAVE].map(
    getGroupName
  );

export const previewMapping = (
  currentMapping: RulesById,
  currentClaims: Claims,
  groupsById: Record<string, PermissionGroup>,
  mappingPreview: SSOAttributeMappingPreviewAPIResponse
) => {
  const getGroupName = pairUpGroupIdsAndNames(groupsById);
  const isUnmapped = getIsUnmapped(currentMapping);
  const getMatchingGroupsNames = pairUpAttributeNamesAndMatchedGroups(
    mappingPreview,
    getGroupName
  );

  return {
    preview: Object.entries(currentClaims).map(
      ([claimAttributeName, claimAttributeValue]: [string, AttributeValue]) => {
        const unmapped = isUnmapped(claimAttributeName);
        const joinGroups = getMatchingGroupsNames(claimAttributeName);

        return {
          attribute: {
            name: { name: claimAttributeName, unmapped },
            type: inferAttributeType(claimAttributeValue),
            values: {
              values: maybeConvertToArray(claimAttributeValue),
              unmapped,
            },
          },
          joinGroups: {
            joinGroups,
            notApplicable: joinGroups.length === 0,
            unmapped,
          },
        };
      }
    ),
    leaveGroups: getLeaveGroupsNames(mappingPreview, getGroupName),
  };
};
