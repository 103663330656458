import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import { capitalize } from 'utils/stringUtils';
import { dispatchAction } from '@ardoq/rxbeach';
import { applyEntityMerge } from 'components/EntityMerge/actions';
import { APIEntityType, AllSupportedEntityTypes } from '@ardoq/api-types';
import styled from 'styled-components';

const DoqWithSpeechBubbleContainer = styled.div`
  height: 90%;
  display: flex;
  margin: auto;
  align-items: center;
`;

const COMPONENT_P = (
  <p>
    All selected components have the same properties, field values, and style.
    Thus there are no additional values to select.
  </p>
);

const REFERENCE_P = (
  <p>
    All selected references have the same properties and field values. Thus
    there are no additional values to select.
  </p>
);

type EmptyPageDoqProps = { entityType: AllSupportedEntityTypes };
const EmptyPageDoq = ({ entityType }: EmptyPageDoqProps) => {
  const entityPronoun =
    entityType === APIEntityType.COMPONENT ? 'components' : 'references';

  return (
    <DoqWithSpeechBubbleContainer>
      <DoqWithSpeechBubble
        doqType={DoqType.COMPONENT_MERGE_EMPTY}
        layout={DoqLayout.PAGE}
        title={`The ${entityPronoun} are identical`}
        message={
          <>
            {entityType === APIEntityType.COMPONENT ? COMPONENT_P : REFERENCE_P}
            <br />
            <p>
              Click the button below to merge your identical {entityPronoun}{' '}
              into one. To exit, click the &apos;x&apos; at the top right corner
              of your screen
            </p>
          </>
        }
        buttonLabel={`Merge ${capitalize(entityPronoun)}`}
        onButtonClick={() => dispatchAction(applyEntityMerge(entityType))}
      />
    </DoqWithSpeechBubbleContainer>
  );
};

export default EmptyPageDoq;
