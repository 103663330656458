import { connect } from '@ardoq/rxbeach';
import { ViewInferenceCommands, ViewInferenceState } from './types';
import viewInference$ from './viewInference$';
import { ViewInferenceWindow } from './components/ViewInferenceWindow';
import { ViewInferenceHeader } from './components/ViewInferenceHeader';
import { ViewInferenceError } from './components/ViewInferenceError';
import { ChatFooter } from 'bestPracticeAssistant/components';
import { viewInferenceOperations } from './viewInferenceOperations';
import { ViewInferenceWarning } from './components/ViewInferenceWarning';
import { v4 as uuidv4 } from 'uuid';
import { map } from 'rxjs';
import { viewInferenceCommands } from './viewInferenceCommands';
import { Suggestions } from './components/Suggestions';
import { SuggestionsLoading } from './components/SuggestionsLoading';
import { SuggestionsError } from './components/SuggestionsError';
import { SuggestionsWarning } from './components/SuggestionsWarning';
import styled from 'styled-components';
import { Draggable } from '@ardoq/draggable';
import { Box, Stack } from '@ardoq/layout';

const DraggableStyled = styled(Draggable)`
  z-index: 100;
`;

const Footer = styled(ChatFooter)`
  border: 0;
  padding-top: 0;
`;

type ViewInferenceManagerProps = ViewInferenceState & {
  commands: ViewInferenceCommands;
};

const ViewInferenceManager = ({
  isOpen,
  input,
  componentIds,
  commands,
  sendPrompt,
  suggest,
  initialPosition,
}: ViewInferenceManagerProps) => {
  if (!isOpen) return null;

  const isSending = sendPrompt.status === 'LOADING';
  const showSuggestions = !isSending && suggest.status === 'DONE';
  const showSuggestionsLoading = !isSending && suggest.status === 'LOADING';
  const showFooter = !isSending && suggest.status !== 'WARNING';

  return (
    <DraggableStyled initialPosition={initialPosition} hasExplicitHandle>
      <ViewInferenceWindow>
        <ViewInferenceHeader
          onClose={commands.closeWindow}
          isLoading={isSending}
        />
        {showSuggestions && (
          <Suggestions
            applySuggestion={commands.applySuggestion}
            suggestions={suggest.suggestions}
          />
        )}
        {!isSending && (
          <Box padding="medium">
            <Stack gap="small">
              {showSuggestionsLoading && <SuggestionsLoading />}
              {suggest.status === 'ERROR' && (
                <SuggestionsError error={suggest.error} />
              )}
              {suggest.status === 'WARNING' && (
                <SuggestionsWarning warning={suggest.warning} />
              )}
              {sendPrompt.status === 'ERROR' && (
                <ViewInferenceError error={sendPrompt.error} />
              )}
              {sendPrompt.status === 'WARNING' && (
                <ViewInferenceWarning warning={sendPrompt.warning} />
              )}
            </Stack>
          </Box>
        )}

        {showFooter && (
          <Footer
            input={input}
            onInputChange={commands.setInput}
            sendMessage={() =>
              viewInferenceOperations.validateInput(input) &&
              commands.send({
                input,
                startSet: componentIds,
                clientRequestId: uuidv4(),
              })
            }
            sendMessageIsLoading={isSending}
          />
        )}
      </ViewInferenceWindow>
    </DraggableStyled>
  );
};

export default connect(
  ViewInferenceManager,
  viewInference$.pipe(
    map(viewInferenceState => ({
      ...viewInferenceState,
      commands: viewInferenceCommands,
    }))
  )
);
