import { ArrowType, LineType, PathCollapsingRule } from '@ardoq/api-types';
import { colors } from '@ardoq/design-tokens';

export const createEmptyPathCollapsingRule = (): PathCollapsingRule => ({
  path: [],
  displayText: '',
  isActive: true,
  referenceStyle: defaultCollapsedPathReferenceStyle,
});

export const defaultCollapsedPathReferenceStyle = {
  color: colors.black,
  line: LineType.SOLID,
  lineEnding: ArrowType.BOTH,
  lineBeginning: ArrowType.NONE,
  svgStyle: null,
};
