import { Icon, IconName } from '@ardoq/icons';
import { NavigationMenuGroup } from '../NavigationSidebar/types';
import { AccessControlTabs } from 'admin/accessControl/navigation/types';
import { AccessControlMenuSingleOption } from './types';
import { AccessControlPageProps, AccessControlPageStream } from '../types';
import ManageAndInviteUsers from 'admin/manageOrganization/ManageAndInviteUsers';
import { InviteUserActionButton } from '../components/InviteUserActionButton';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import ManageUserRoles from 'admin/accessControl/ManageUserRoles/ManageUserRoles';
import ManageServiceAccounts from 'admin/serviceAccounts/ManageServiceAccounts';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { CreateNewButton } from '@ardoq/page-layout';
import { CreateServiceAccountButton } from '../components/CreateServiceAccountButton';
import PermissionGroups from 'admin/accessControl/PermissionGroups/PermissionGroups';

export const getUsersManagementMenuOption = (
  args: {
    showManageUserRoles: boolean;
    showIPAllowlisting: boolean;
    showSCIM: boolean;
    showSSOAttributeMapping: boolean;
    subdivisionFeature: boolean;
  },
  resources: AccessControlPageStream['resources']
): NavigationMenuGroup => ({
  label: 'User management',
  iconName: IconName.PEOPLE,
  items: [
    {
      label: 'Users',
      id: AccessControlTabs.MEMBERS,
      rightText: resources.orgUsers?.users.length.toString(),
      content: (props: AccessControlPageProps) => (
        <ManageAndInviteUsers accessControlPageViewModel={props} />
      ),
      actionButton: InviteUserActionButton,
    } satisfies AccessControlMenuSingleOption,
    {
      label: 'Groups',
      id: AccessControlTabs.PERMISSION_GROUPS,
      content: () => <PermissionGroups />,
      knowledgeBaseLink: KnowledgeBaseLink.PERMISSIONS_GROUPS,
      description:
        'Create and manage permission groups within your organisation.',
      rightText:
        resources.permissionGroup &&
        Object.keys(resources.permissionGroup.groupsById).length.toString(),
      actionButton: ({ commands, resources: { permissionGroup } }) => (
        <CreateNewButton
          onClick={() =>
            commands.showCreateGroupModal(
              (permissionGroup && Object.values(permissionGroup?.groupsById)) ??
                []
            )
          }
        >
          Create new
          <Icon iconName={IconName.ADD_CIRCLE} />
        </CreateNewButton>
      ),
    } satisfies AccessControlMenuSingleOption,
    args.showManageUserRoles &&
      ({
        label: 'Roles',
        id: AccessControlTabs.MANAGE_USER_ROLES,
        content: () => <ManageUserRoles />,
        description: 'Customize default privileges for user roles.',
        knowledgeBaseLink: KnowledgeBaseLink.USER_PRIVILEGES,
        rightText: resources.rolesShape?.roles.length.toString(),
      } satisfies AccessControlMenuSingleOption),
    {
      label: 'Service accounts',
      id: AccessControlTabs.SERVICE_ACCOUNTS,
      content: () => <ManageServiceAccounts />,
      actionButton: CreateServiceAccountButton,
      description:
        'Service account is a dedicated user for owning API tokens, enabling secure API integrations without using employee accounts. Tokens expire 60 days after their last use.',
      knowledgeBaseLink: KnowledgeBaseLink.SERVICE_ACCOUNT,
      rightText: resources.serviceAccounts?.serviceAccounts.length.toString(),
    } satisfies AccessControlMenuSingleOption,
  ].filter(ExcludeFalsy),
});
