import { colors, radius, s32, shadowM } from '@ardoq/design-tokens';
import styled from 'styled-components';

export const ChatWindow = styled.div`
  width: 400px;
  height: 600px;
  background-color: ${colors.white};
  box-shadow: ${shadowM};
  border-radius: ${radius.r4};
  position: fixed;
  right: ${s32};
  bottom: ${s32};
  z-index: 2100;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
