import {
  PerspectiveGroupingRule,
  PerspectivesGroupsOptions,
  addNewGroupingRule,
  deleteGroupingRule as deleteGroupingRule_,
  updateGroupingRules,
} from '@ardoq/perspectives';
import { UpdateGroupingRulePayload } from 'perspective/perspectiveEditor/perspectivesEditor$';
import { getGroupsTabInitialState } from 'perspective/perspectiveEditor/perspectivesEditor$/groupings/groupsTabReducers';
import { GroupingRulesState } from './groupingRulesTypes';
import { SetGroupingRulesPayload } from './viewpointGroupingRulesActions';

const clearGroupingTabState = (
  state: GroupingRulesState
): GroupingRulesState => ({
  ...state,
  groupingRules: [],
});

const createGroupingRule = (
  state: GroupingRulesState,
  newGroupingRule: PerspectiveGroupingRule
) => ({
  ...state,
  groupingRules: addNewGroupingRule(state.groupingRules, newGroupingRule),
});

const updateGroupingRule = (
  state: GroupingRulesState,
  { updatedGroupingRuleId, updatedGroupingRule }: UpdateGroupingRulePayload
) => ({
  ...state,
  groupingRules: updateGroupingRules(
    state.groupingRules,
    updatedGroupingRuleId,
    updatedGroupingRule
  ),
});

const reorderGroupingRules = (
  state: GroupingRulesState,
  reorderedGroupingRules: PerspectiveGroupingRule[]
) => ({
  ...state,
  groupingRules: reorderedGroupingRules,
});

const deleteGroupingRule = (
  state: GroupingRulesState,
  deletedGroupingRuleId: string
) => ({
  ...state,
  groupingRules: deleteGroupingRule_(
    state.groupingRules,
    deletedGroupingRuleId
  ),
});

const getInitialState = (): GroupingRulesState => ({
  ...getGroupsTabInitialState(),
  groupingOptions: {
    appliedGroupingRules: [],
    workspaces: [],
    referenceTypesPerWorkspace: {},
    componentTypesPerWorkspace: {},
    fieldsPerWorkspace: {},
    tagsPerWorkspace: {},
    shouldShowSubdivisionsOption: false,
  },
});

const setGroupingOptions = (
  state: GroupingRulesState,
  groupingOptions: PerspectivesGroupsOptions
) => ({
  ...state,
  groupingOptions,
});

const setGroupingRules = (
  state: GroupingRulesState,
  { groupingRules }: SetGroupingRulesPayload
) => ({
  ...state,
  groupingRules,
});

const getGroupingRulesCount = (state: GroupingRulesState): number => {
  return state.groupingRules.length;
};

export const viewpointGroupingRulesOperations = {
  clearGroupingTabState,
  createGroupingRule,
  updateGroupingRule,
  reorderGroupingRules,
  deleteGroupingRule,
  getInitialState,
  setGroupingOptions,
  setGroupingRules,
  getGroupingRulesCount,
};
