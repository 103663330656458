import {
  draggableElementClicked,
  clickedInNavigator,
  containerScrolled,
  dragEndTransitionEnd,
  expandCollapseToggleClicked,
  contextMenuClicked,
} from './actions/navigatorActions';
import { dispatchAction } from '@ardoq/rxbeach';
import React from 'react';
import { targetManager, EXPANDER_CLASS_NAME } from './targetManager';
import { getDOMNodeInNavigatorWithDataId } from './getDOMNodeInNavigatorWithDataId';
import { DRAG_TARGET_CLASS_NAME } from './consts';
import { NavigatorEventListeners } from './types';
import { memoize } from 'lodash';

export const getEventListeners = memoize(
  (actionNamespace?: string): NavigatorEventListeners => ({
    onClick: async (event: React.MouseEvent) =>
      dispatchAction(clickedInNavigator(event), actionNamespace),
    onContextMenu: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const { target, className } = targetManager.getTarget(event, 'click');
      if (!target) {
        return;
      }
      const id = getDOMNodeInNavigatorWithDataId(target);
      if (className === EXPANDER_CLASS_NAME) {
        event.stopPropagation();
        event.preventDefault();
        if (id) {
          dispatchAction(
            expandCollapseToggleClicked({ nodeId: id, isDeep: true }),
            actionNamespace
          );
        }
        return;
      }
      dispatchAction(contextMenuClicked(event.nativeEvent), actionNamespace);
    },
    onMouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const { target, className } = targetManager.getTarget(event);
      if (target && className === DRAG_TARGET_CLASS_NAME) {
        dispatchAction(
          draggableElementClicked({ event, target }),
          actionNamespace
        );
      }
    },
    onScroll: () => dispatchAction(containerScrolled(), actionNamespace),
    onTransitionEnd: () =>
      dispatchAction(dragEndTransitionEnd(), actionNamespace),
  })
);
