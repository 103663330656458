import { EMPTY, filter, tap, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import fp from 'lodash/fp';
import { SelectionResponse } from '@ardoq/api-types/integrations';
import { userEvent } from 'sync/actions';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import {
  ensureCorrectAsyncRequest,
  getAsyncRequestId,
  parseAsyncResponsePayload,
} from 'integrations/common/async/utils';
import { AsyncOperations } from 'integrations/common/async/constants';
import {
  fetchSelection,
  fetchSelectionError,
  fetchSelectionSuccess,
  loadSelection,
} from 'integrations/common/streams/selectionState/actions';
import {
  changeRegionsSelection,
  fetchRegionsSuccess,
  fetchRegionsSuccessForCloudProvider,
} from 'integrations/cloudProviders/streams/regions/actions';
import { changeResourceTypesSelection } from 'integrations/cloudProviders/streams/resourceTypes/actions';
import {
  applyCloudProviderParams,
  fetchSelectionSuccess as fetchAwsSelectionSuccess,
  setFetchErrorMessage,
  setSelectionAsyncStatus,
  setSelectionRequest,
  unselectCloudProviderParams,
} from './actions';
import { changeVpcsSelection } from '../vpcs/actions';
import { vpcs$ } from '../vpcs/vpcs$';
import { applySourceConfig } from 'integrations/cloudProviders/config/actions';
import {
  selectConnection,
  updateSelectedConnectionsIds,
} from 'integrations/common/streams/connections/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';

const handleFetchSelection = routine(
  ofType(loadSelection),
  extractPayload(),
  filter(integrationId => integrationId === 'aws-v3'),
  withLatestFrom(
    activeIntegrations$,
    vpcs$,
    getConnectionsStream('aws-v3'),
    getRegionsStream('aws-v3'),
    getResourceTypesStream('aws-v3')
  ),
  tap(
    ([
      integrationId,
      activeIntegrations,
      { selectedVpcIds },
      { selectedConnectionIds, connections },
      { selectedRegionIds },
      { selectedResourceTypeIds },
    ]) => {
      const selectedConnections = connections.filter(({ _id }) =>
        selectedConnectionIds.includes(_id)
      );

      if (!selectedConnections.length) {
        return EMPTY;
      }

      const funnelId = activeIntegrations[integrationId].trackingFunnelId;
      const selectionRequestPayload = {
        accountIds: selectedConnections.map(({ _id }) => _id),
        providerParams: {
          regions: selectedRegionIds,
          resourceTypes: selectedResourceTypeIds,
          vpcs: selectedVpcIds,
        },
      };
      dispatchAction(setSelectionAsyncStatus('LOADING'));
      dispatchAction(setFetchErrorMessage(null));
      dispatchAction(setSelectionRequest(selectionRequestPayload));
      dispatchAction(
        trackIntegrationEvent({
          integrationId: 'aws-v3',
          name: 'TRIGGERED_SOURCE_DATA_FETCH',
          metadata: {
            regions: selectionRequestPayload.providerParams.regions,
            resourceTypes: selectionRequestPayload.providerParams.resourceTypes,
            numberOfVpcs: selectionRequestPayload.providerParams.vpcs.length,
            numberOfConnections: selectionRequestPayload.accountIds.length,
          },
        })
      );
      const requestId = getAsyncRequestId({
        integrationId,
        operation: AsyncOperations.AWS_FETCH_DATA,
        funnelId,
      });

      dispatchAction(
        fetchSelection({
          requestId,
          integrationId: 'aws-v3',
          payload: selectionRequestPayload,
        })
      );
    }
  )
);

const handleFetchSelectionResponse = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncResponsePayload<SelectionResponse>),
  withLatestFrom(activeIntegrations$),
  ensureCorrectAsyncRequest(AsyncOperations.AWS_FETCH_DATA),
  tap(([response]) => {
    if (!response) return;

    if (response.status !== 'success') {
      dispatchAction(setSelectionAsyncStatus('FAILURE'));

      if (response.data.message) {
        dispatchAction(setFetchErrorMessage(response.data.message));
      }

      return;
    }

    dispatchAction(setFetchErrorMessage(null));
    dispatchAction(
      fetchSelectionSuccess({
        response: response.data,
        integrationId: 'aws-v3',
      })
    );
  })
);

const handleFetchSelectionSuccess = routine(
  ofType(fetchSelectionSuccess),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'aws-v3'),
  tap(({ response }) => {
    dispatchAction(fetchAwsSelectionSuccess(response));
  })
);

const handleFetchSelectionError = routine(
  ofType(fetchSelectionError),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'aws-v3'),
  tap(() => {
    dispatchAction(setSelectionAsyncStatus('FAILURE'));
    dispatchAction(setFetchErrorMessage('Failed to fetch data'));
  })
);

const handleFetchRegionsSuccess = routine(
  ofType(fetchRegionsSuccess),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'aws-v3'),
  tap(({ regions }) => {
    dispatchAction(
      fetchRegionsSuccessForCloudProvider({
        integrationId: 'aws-v3',
        regions: fp.sortBy('regionName', fp.uniqBy('regionName', regions)),
      })
    );
  })
);

const handleApplySourceConfig = routine(
  ofType(applySourceConfig),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'aws-v3'),
  tap(sourceConfig => {
    dispatchAction(unselectCloudProviderParams());
    sourceConfig.accountIds.forEach(accountId => {
      dispatchAction(
        selectConnection({
          integrationId: 'aws-v3',
          selectedConnectionId: accountId,
        })
      );
    });
    dispatchAction(
      updateSelectedConnectionsIds({
        integrationId: 'aws-v3',
        selectedConnectionIds: sourceConfig.accountIds,
      })
    );
    dispatchAction(applyCloudProviderParams(sourceConfig.providerParams));
  })
);

const handleApplyCloudProviderParams = routine(
  ofType(applyCloudProviderParams),
  extractPayload(),
  tap(providerParams => {
    dispatchAction(
      changeVpcsSelection({
        selectedVpcIds: providerParams.vpcs,
      })
    );

    dispatchAction(
      changeRegionsSelection({
        integrationId: 'aws-v3',
        regions: providerParams.regions,
      })
    );
    dispatchAction(
      changeResourceTypesSelection({
        integrationId: 'aws-v3',
        resourceTypes: providerParams.resourceTypes,
      })
    );
  })
);

const handleUnselectCloudProviderParams = routine(
  ofType(unselectCloudProviderParams),
  extractPayload(),
  tap(() => {
    dispatchAction(changeVpcsSelection({ selectedVpcIds: [] }));

    dispatchAction(
      changeRegionsSelection({
        integrationId: 'aws-v3',
        regions: [],
      })
    );
    dispatchAction(
      changeResourceTypesSelection({
        integrationId: 'aws-v3',
        resourceTypes: [],
      })
    );
  })
);

export default [
  handleFetchSelection,
  handleFetchSelectionResponse,
  handleFetchSelectionSuccess,
  handleFetchRegionsSuccess,
  handleApplySourceConfig,
  handleApplyCloudProviderParams,
  handleUnselectCloudProviderParams,
  handleFetchSelectionError,
];
