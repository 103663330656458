import {
  APIDiscoverViewpointAttributes,
  APIWorkspaceAttributes,
  ArdoqId,
  NonPersistedDiscoverViewpoint,
  Triple,
  TypeRelationshipsResult,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import {
  ViewpointsFetchErrorPayload,
  ViewpointsFetchSuccessPayload,
} from './types';

export const viewpointsFetch = actionCreator('[viewpoints] FETCH_VIEWPOINTS');
export const viewpointsFetchSuccess =
  actionCreator<ViewpointsFetchSuccessPayload>(
    '[viewpoints] FETCH_VIEWPOINTS_SUCCESS'
  );

export const viewpointFetch = actionCreator<ArdoqId>(
  '[viewpoints] FETCH_VIEWPOINT'
);
export const viewpointFetchSuccess =
  actionCreator<APIDiscoverViewpointAttributes>(
    '[viewpoints] FETCH_VIEWPOINT_SUCCESS'
  );

export const viewpointsFetchError = actionCreator<ViewpointsFetchErrorPayload>(
  '[viewpoints] FETCH_VIEWPOINTS_ERROR'
);

export const viewpointSave = actionCreator<
  APIDiscoverViewpointAttributes | NonPersistedDiscoverViewpoint
>('[viewpoints] SAVE_VIEWPOINT');

export const viewpointSaveSuccess =
  actionCreator<APIDiscoverViewpointAttributes>(
    '[viewpoints] SAVE_VIEWPOINT_SUCCESS'
  );

export const viewpointSaveError = actionCreator(
  '[viewpoints] SAVE_VIEWPOINT_ERROR'
);

export const viewpointDelete = actionCreator<APIDiscoverViewpointAttributes>(
  '[viewpoints] DELETE_VIEWPOINT'
);

export const viewpointDeleteSuccess = actionCreator<ArdoqId>(
  '[viewpoints] DELETE_VIEWPOINT_SUCCESS'
);

export const fetchAvailableTriples = actionCreator<ArdoqId[]>(
  '[viewpoints] FETCH_AVAILABLE_TRIPLES'
);

export const fetchAvailableTriplesSuccess = actionCreator<Triple[]>(
  '[viewpoints] FETCH_AVAILABLE_TRIPLES_SUCCESS'
);

export const fetchAvailableTriplesError = actionCreator(
  '[viewpoints] FETCH_AVAILABLE_TRIPLES_ERROR'
);

export const notifyCopyingViewpointSucceeded =
  actionCreator<APIDiscoverViewpointAttributes>(
    '[viewpoints] NOTIFY_COPYING_VIEWPOINT_SUCCEEDED'
  );

export const setViewpointPublished = actionCreator<{
  viewpointId: ArdoqId;
  published: boolean;
}>('[viewpoints] SET_VIEWPOINT_PUBLISHED');

export const fetchTypeRelationships = actionCreator<ArdoqId[]>(
  '[viewpoints] FETCH_TYPE_RELATIONSHIPS'
);

export const fetchTypeRelationshipsSuccess =
  actionCreator<TypeRelationshipsResult>(
    '[viewpoints] FETCH_TYPE_RELATIONSHIPS_SUCCESS'
  );

export const fetchRestrictedWorkspaces = actionCreator(
  '[viewpoints] FETCH_RESTRICTED_WORKSPACES'
);

export const populateRestrictedWorkspaces = actionCreator<
  APIWorkspaceAttributes[]
>('[viewpoints] POPULATE_RESTRICTED_WORKSPACES');

export const updateDefaultViewpoint = actionCreator<{
  componentTypeName: string;
  workspaceId: ArdoqId;
  viewpointId?: ArdoqId;
}>('[viewpoints] UPDATE_DEFAULT_VIEWPOINT');

export const toggleViewpointLegacyMode = actionCreator(
  '[viewpoints] TOGGLE_VIEWPOINT_LEGACY_MODE'
);

export const setViewpointLegacyMode = actionCreator<boolean>(
  '[viewpoints] SET_VIEWPOINT_LEGACY_MODE'
);

type RenameViewpointPayload = {
  viewpoint: APIDiscoverViewpointAttributes;
  name: string;
};

export const renameViewpoint = actionCreator<RenameViewpointPayload>(
  '[viewpoints] RENAME_VIEWPOINT'
);
