import {
  dispatchAction,
  collectRoutines,
  routine,
  apply,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  restoreReference,
  restoreReferenceError,
  restoreReferenceSuccess,
} from './reference.actions';
import { mergeMap, tap } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { handleError, restoreApi } from '@ardoq/api';

const handleRestoreReference = routine(
  ofType(restoreReference),
  extractPayload(),
  apply(({ reference }) =>
    pipe(
      mergeMap(() =>
        restoreApi.reference({
          workspace: reference.workspaceId,
          reference: reference._id,
        })
      ),
      handleError(() => dispatchAction(restoreReferenceError({ reference }))),
      tap(() => dispatchAction(restoreReferenceSuccess({ reference })))
    )
  )
);

export const restoreReferenceRoutines = collectRoutines(handleRestoreReference);
