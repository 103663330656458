import { Checkbox } from '@ardoq/forms';
import { FilterInterfaceFilter } from '@ardoq/api-types';
import { PopoverPlacement, WithPopover } from '@ardoq/popovers';

type IncludeTimeCheckboxProps = {
  isVisible: boolean;
  labelFormatting: FilterInterfaceFilter;
  handleSetLabelFormatting: (format: FilterInterfaceFilter) => void;
};

export const IncludeTimeCheckbox = ({
  isVisible,
  labelFormatting,
  handleSetLabelFormatting,
}: IncludeTimeCheckboxProps) => {
  if (!isVisible) {
    return null;
  }

  const handleIncludeTimeCheckboxChange = (includeTime: boolean) =>
    handleSetLabelFormatting({
      ...labelFormatting,
      includeTime,
    });

  return (
    <WithPopover
      content="Display hours and minutes in the label"
      preferredPlacement={PopoverPlacement.TOP}
    >
      <Checkbox
        name="checked"
        isChecked={!!labelFormatting.includeTime}
        onChange={handleIncludeTimeCheckboxChange}
      >
        Include time
      </Checkbox>
    </WithPopover>
  );
};
