import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  getZonePermissions,
  getZonePermissionsFinished,
  updateZonePermission,
  updateZonePermissionFinished,
} from './actions';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ResourceType } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { ToastType, showToast } from '@ardoq/status-ui';
import { createZoneSuccessfully } from 'streams/subdivisions/actions';
import { handleError, permissionApi } from '@ardoq/api';

const handleGetZonePermissions = routine(
  ofType(getZonePermissions),
  switchMap(() => permissionApi.fetchPermissionsForAllZones()),
  handleError(error => {
    showToast('Loading permissions failed', ToastType.INFO);
    logError(error, 'Failed to load permissions');
  }),
  tap(permission => dispatchAction(getZonePermissionsFinished(permission)))
);

const handleUpdateZonePermission = routine(
  ofType(updateZonePermission),
  extractPayload(),
  filter(permission => Boolean(permission)),
  switchMap(permission =>
    permissionApi.updateResourcePermission({
      resourceType: ResourceType.PERMISSION_ZONE,
      resourceId: permission.resource,
      permission,
    })
  ),
  handleError(error => {
    showToast('Saving permissions failed', ToastType.INFO);
    logError(error, 'Failed to save permissions');
  }),
  tap(permission => dispatchAction(updateZonePermissionFinished(permission)))
);

const handleCreateZoneSuccess = routine(
  ofType(createZoneSuccessfully),
  tap(() => dispatchAction(getZonePermissions()))
);

export const permissionByZoneRoutines = collectRoutines(
  handleGetZonePermissions,
  handleUpdateZonePermission,
  handleCreateZoneSuccess
);
