import {
  routine,
  dispatchAction,
  ofType,
  extractPayload,
} from '@ardoq/rxbeach';
import { filter, tap, withLatestFrom } from 'rxjs';
import { setupConfigurationForUpdate } from '../configurationState/actions';
import { loadSchedule } from 'integrations/common/streams/schedule/actions';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { isITPediaSchedule } from 'integrations/common/streams/schedules/utils';
import { navigateToPath } from 'integrations/ITPedia/navigation/actions';
import { ITPediaImportRoutes } from 'integrations/ITPedia/types';
import { enrichmentToConfigurationState } from 'integrations/ITPedia/utils';

const handleLoadSchedule = routine(
  ofType(loadSchedule),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'it-pedia'),
  withLatestFrom(getSchedulesStream('it-pedia')),
  tap(([{ scheduleId }, schedules]) => {
    const schedule = schedules.schedules.find(s => scheduleId === s._id);

    if (!schedule || !isITPediaSchedule(schedule)) {
      return;
    }

    dispatchAction(
      setupConfigurationForUpdate(enrichmentToConfigurationState(schedule))
    );
    dispatchAction(navigateToPath(ITPediaImportRoutes.DATA_SELECTION));
  })
);

export default [handleLoadSchedule];
