import {
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';

export type ViewpointBuilderOpenedState = {
  isOpened: boolean;
};

const defaultState: ViewpointBuilderOpenedState = {
  isOpened: false,
};

const viewpointBuilderOpenedReducer = () => ({ isOpened: true });
const viewpointBuilderClosedReducer = () => ({ isOpened: false });

export const viewpointBuilderOpened = actionCreator(
  '[viewpointBuilder] VIEWPOINT_BUILDER_OPENED'
);
export const viewpointBuilderClosed = actionCreator(
  '[viewpointBuilder] VIEWPOINT_BUILDER_ClOSED'
);

export const viewpointBuilderOpened$ =
  persistentReducedStream<ViewpointBuilderOpenedState>(
    'viewpointBuilderOpened$',
    defaultState,
    [
      reducer(viewpointBuilderOpened, viewpointBuilderOpenedReducer),
      reducer(viewpointBuilderClosed, viewpointBuilderClosedReducer),
    ]
  );
