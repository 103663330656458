import { WithUnifiedIntegrationId } from 'integrations/unified/types';
import { actionCreator } from '@ardoq/rxbeach';
import {
  DataLoadingPayload,
  SelectionResponse,
} from '@ardoq/api-types/integrations';

const NAMESPACE = '[integrations unified] LOAD_DATA';

export const resetIntegration = actionCreator<WithUnifiedIntegrationId>(
  `${NAMESPACE}_RESET_INTEGRATION`
);

type LoadDataInitPayload = WithUnifiedIntegrationId & {
  payload: DataLoadingPayload;
};

export const loadDataInit = actionCreator<LoadDataInitPayload>(
  `${NAMESPACE}_LOAD_DATA_INIT`
);

type LoadDataSuccessPayload = WithUnifiedIntegrationId & {
  response: SelectionResponse;
};

export const loadDataSuccess = actionCreator<LoadDataSuccessPayload>(
  `${NAMESPACE}_LOAD_DATA_SUCCESS`
);

type LoadDataFailurePayload = WithUnifiedIntegrationId & {
  message: string;
  level: 'warning' | 'error';
};

export const loadDataFailure = actionCreator<LoadDataFailurePayload>(
  `${NAMESPACE}_LOAD_DATA_FAILURE`
);
