import {
  APIFieldType,
  DataSourceField,
  WidgetDatasource,
  WidgetDataSourceTypes,
  WidgetTypes,
} from '@ardoq/api-types';
import { SURVEY_VALID_INVALID_FIELD_NAME } from './types';

type WidgetTypeValidationAttributes = {
  datasource?: WidgetDatasource;
  selectedFields: DataSourceField[];
  availableFieldsForDataSource?: DataSourceField[];
};

const VALID_FIELDS_FOR_PIE_AND_STACKED_BAR_AND_BAR_CHART = new Set([
  APIFieldType.TEXT,
  APIFieldType.LIST,
  APIFieldType.CHECKBOX,
  APIFieldType.USER,
]);

type WidgetTypeValidationCriteria = (
  widgetData: WidgetTypeValidationAttributes
) => boolean;

export const isCustomGremlinDatasourceValidForPieChart: WidgetTypeValidationCriteria =
  ({ datasource, selectedFields, availableFieldsForDataSource }) =>
    Boolean(
      datasource?.sourceType === WidgetDataSourceTypes.REPORT &&
        selectedFields.length &&
        selectedFields.some(
          ({ type, custom }) => type === APIFieldType.NUMBER && custom
        ) &&
        availableFieldsForDataSource?.find(field => field.name === 'name')
    );

const isReportDataSource: WidgetTypeValidationCriteria = ({ datasource }) =>
  datasource?.sourceType === WidgetDataSourceTypes.REPORT;
const hasLessThanTwoSelectedFields: WidgetTypeValidationCriteria = ({
  selectedFields,
}) => selectedFields.length < 2;

const hasNoReferenceFieldsSelected: WidgetTypeValidationCriteria = ({
  selectedFields,
}) =>
  !selectedFields.some(
    field => field.referenceTypeIncoming || field.referenceTypeOutgoing
  );

const hasNoIdFieldSelected: WidgetTypeValidationCriteria = ({
  selectedFields,
}) =>
  !selectedFields.some(
    field => field.name === '_id' || field.name === 'component-key'
  );

const selectedFieldTypeIsValidForBarChartAndStackedBarChart: WidgetTypeValidationCriteria =
  ({ selectedFields }) =>
    Boolean(
      selectedFields.length &&
        selectedFields.every(field =>
          VALID_FIELDS_FOR_PIE_AND_STACKED_BAR_AND_BAR_CHART.has(field.type)
        )
    );

const selectedFieldTypeIsValidForPieChart: WidgetTypeValidationCriteria =
  widgetData =>
    (hasLessThanTwoSelectedFields(widgetData) &&
      selectedFieldTypeIsValidForBarChartAndStackedBarChart(widgetData)) ||
    isCustomGremlinDatasourceValidForPieChart(widgetData);

const isReportDatasourceOrSurveyWithValidationFieldSelected: WidgetTypeValidationCriteria =
  widgetData =>
    isReportDataSource(widgetData) ||
    widgetData.selectedFields[0]?.name === SURVEY_VALID_INVALID_FIELD_NAME;

const widgetTypeValidationCriteria: Record<
  WidgetTypes,
  WidgetTypeValidationCriteria[]
> = {
  [WidgetTypes.TABLE]: [],
  [WidgetTypes.LINE_CHART]: [isReportDataSource, hasNoReferenceFieldsSelected],
  [WidgetTypes.NUMBER]: [
    hasLessThanTwoSelectedFields,
    hasNoReferenceFieldsSelected,
  ],
  [WidgetTypes.PIE_CHART]: [
    hasNoReferenceFieldsSelected,
    hasNoIdFieldSelected,
    selectedFieldTypeIsValidForPieChart,
  ],
  [WidgetTypes.BAR_CHART]: [
    hasLessThanTwoSelectedFields,
    hasNoReferenceFieldsSelected,
    hasNoIdFieldSelected,
    selectedFieldTypeIsValidForBarChartAndStackedBarChart,
  ],
  [WidgetTypes.STACKED_BAR_CHART]: [
    hasLessThanTwoSelectedFields,
    hasNoReferenceFieldsSelected,
    hasNoIdFieldSelected,
    selectedFieldTypeIsValidForBarChartAndStackedBarChart,
    isReportDatasourceOrSurveyWithValidationFieldSelected,
  ],
  [WidgetTypes.HEADER]: [],
};
export const isWidgetTypeValidForSelectedFieldsAndDatasource = ({
  widgetType,
  ...validationAttributes
}: WidgetTypeValidationAttributes & { widgetType: WidgetTypes }) =>
  widgetType !== WidgetTypes.HEADER &&
  widgetTypeValidationCriteria[widgetType].every(
    validationCriteriaForWidgetType =>
      validationCriteriaForWidgetType(validationAttributes)
  );

const MULTIPLE_SELECTED_FIELDS_POPOVER =
  'Only table and line chart can be used when more than 1 field is selected.';

const widgetTypeSpecificPopoverContent = {
  [WidgetTypes.LINE_CHART]: `Line chart can't be used with surveys or reference fields`,
  [WidgetTypes.NUMBER]: `Number chart can't be used with reference fields`,
  [WidgetTypes.PIE_CHART]:
    'Pie chart can only be used with these field types: text, list, checkbox and user.',
  [WidgetTypes.BAR_CHART]:
    'Bar chart can only be used with these field types: text, list, checkbox and user.',
  [WidgetTypes.STACKED_BAR_CHART]:
    'Stacked bar chart can only be used with these report field types: text, list, checkbox, user and this survey field type: Show valid/invalid.',
  [WidgetTypes.TABLE]: undefined, // table never needs a popover,
  [WidgetTypes.HEADER]: undefined, // header never needs a popover,
};

export const getIsWidgetTypeDisabledAndPopoverContent = ({
  widgetType,
  ...validationAttributes
}: WidgetTypeValidationAttributes & {
  widgetType: WidgetTypes;
}) => {
  const validationCriteriaThatFails = widgetTypeValidationCriteria[
    widgetType
  ].find(
    validationCriteriaForWidgetType =>
      !validationCriteriaForWidgetType(validationAttributes)
  );
  if (validationCriteriaThatFails) {
    return {
      isDisabled: true,
      popoverContent:
        validationCriteriaThatFails === hasLessThanTwoSelectedFields
          ? MULTIPLE_SELECTED_FIELDS_POPOVER
          : widgetTypeSpecificPopoverContent[widgetType],
    };
  }
  return { isDisabled: false };
};
