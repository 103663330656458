import { reducer } from '@ardoq/rxbeach';
import {
  closePresentation,
  openPresentationBuilder,
  previewPresentationInEditor,
  setPresentationInListMode,
} from './actions';
import {
  OpenPresentationPayload,
  PresentationOverviewStreamShape,
} from './types';
import { ArdoqId } from '@ardoq/api-types';
import {
  createPresentationAndOpenInPreview,
  notifyPresentationDeleted,
} from 'streams/presentations/actions';

export const defaultState: PresentationOverviewStreamShape = {
  isInListMode: false,
  isSidebarOpen: false,
  currentPresentationId: undefined,
  isSavingNewPresentation: false,
};

const handleSetPresentationInListMode = (
  state: PresentationOverviewStreamShape,
  isInListMode: boolean
): PresentationOverviewStreamShape => {
  return {
    ...state,
    isInListMode,
  };
};

const handlePreviewPresentationInEditor = (
  state: PresentationOverviewStreamShape,
  { presentationId }: OpenPresentationPayload
): PresentationOverviewStreamShape => {
  return {
    ...state,
    currentPresentationId: presentationId,
    isSidebarOpen: true,
    isSavingNewPresentation: false,
  };
};

const handleOpenPresentationBuilder = (
  state: PresentationOverviewStreamShape
): PresentationOverviewStreamShape => {
  return {
    ...state,
    currentPresentationId: 'NEW',
    isSidebarOpen: true,
    isSavingNewPresentation: false,
  };
};

const handleClosePresentation = (
  state: PresentationOverviewStreamShape
): PresentationOverviewStreamShape => {
  return {
    ...state,
    currentPresentationId: undefined,
    isSidebarOpen: false,
    isSavingNewPresentation: false,
  };
};

const handleMaybeCloseSidebar = (
  state: PresentationOverviewStreamShape,
  presentationId: ArdoqId
): PresentationOverviewStreamShape => {
  if (
    state.currentPresentationId === 'NEW' ||
    presentationId !== state.currentPresentationId
  )
    return state;
  return {
    ...state,
    currentPresentationId: undefined,
    isSidebarOpen: false,
    isSavingNewPresentation: false,
  };
};

const handleCreatingPresentation = (
  state: PresentationOverviewStreamShape
): PresentationOverviewStreamShape => {
  return {
    ...state,
    isSavingNewPresentation: true,
  };
};

export const reducers = [
  reducer(setPresentationInListMode, handleSetPresentationInListMode),
  reducer(previewPresentationInEditor, handlePreviewPresentationInEditor),
  reducer(openPresentationBuilder, handleOpenPresentationBuilder),
  reducer(closePresentation, handleClosePresentation),
  reducer(notifyPresentationDeleted, handleMaybeCloseSidebar),
  reducer(createPresentationAndOpenInPreview, handleCreatingPresentation),
];
