import { reducer, streamReducer } from '@ardoq/rxbeach';
import {
  AppModuleState,
  AssetsBrowser2024ActionPayload,
  AssetsBrowser2024StreamShape,
} from './assetsBrowser2024Types';
import {
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';
import currentUser$, {
  CurrentUserState,
  getIsOrgWriter,
} from '../../streams/currentUser/currentUser$';
import { currentUserHasPrivilege } from '@ardoq/privileges';
import { ArdoqId, PrivilegeLabel, SortOrder, Asset } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import currentUserPermissionContext$ from '../../streams/currentUserPermissions/currentUserPermissionContext$';
import {
  setPerPage,
  setIsUpdating,
  setRenameId,
  setSelectedAssets,
  updateSorting,
  setCurrentPageNumber,
  clearFilter,
  setFilterActive,
  setFilterInactive,
  setSearchPhrase,
  setExpandedFoldersIds,
  updateDataSource,
  UpdateDataSourcePayload,
  expandFolder,
} from './assetsBrowser2024Actions';
import assets$, {
  AssetsStreamState,
  emptyState as emptyAssetsState,
} from '../../streams/assets/assets$';
import { Filter } from './FilterBar/filterBarTypes';
import { collectExpandedIdsFromDataSource } from '@ardoq/table';
import { findSortOrder } from '@ardoq/common-helpers';
import { AppModules } from '../../appContainer/types';
import { getFilteredDatasource } from './assetsBrowser2024Utils';
import { assetsBrowser2024Commands } from './assetsBrowser2024Commands';
import { ARDOQ_DEFAULT_LOCALE, Locale } from '@ardoq/locale';
import { locale$ } from 'streams/locale$';
import { dashboardAccessControlInterface } from 'resourcePermissions/accessControlHelpers/dashboards';
import { SurveyAdminStreamState } from 'surveyAdmin/streams/types';
import surveyAdmin$ from 'surveyAdmin/streams/surveyAdmin$';

const defaultAppModuleState: AppModuleState = {
  dataSource: [],
  selectedAssets: [],
  renameId: null,
  searchPhrase: '',
  activeFilters: [],
  alwaysActiveFilters: [],
  expandedFoldersIds: [],
  sortByField: 'name',
  sortOrder: SortOrder.ASC,
  currentPageNumber: 1,
  perPage: 15,
  useFolders: true,
};

export const defaultState: AssetsBrowser2024StreamShape = {
  assetsById: {},
  features: {
    traversalsEnabled: false,
    scenariosEnabled: false,
    bookmarksEnabled: false,
    presentationsEnabled: false,
    surveysEnabled: false,
    broadcastsEnabled: false,
    discoverEnabled: false,
  },
  permissions: {
    currentUserIsOrgWriter: false,
    permissionContext: permissionsOperations.createEmptyPermissionContext(),
    canCreateViewpoint: false,
    canCreateSurvey: false,
    canCreateDashboard: false,
  },
  assetsState: emptyAssetsState,
  commands: assetsBrowser2024Commands,
  appModuleStates: Object.keys(AppModules).reduce(
    (acc, key) => {
      acc[AppModules[key as keyof typeof AppModules]] = defaultAppModuleState;
      return acc;
    },
    {} as Record<AppModules, AppModuleState>
  ),
  surveysWithPendingApprovals: {},
  locale: ARDOQ_DEFAULT_LOCALE,
  isUpdating: false,
};

const assignNewValueToCurrentAppModuleState = (
  state: AssetsBrowser2024StreamShape,
  newAppModuleState: Partial<AppModuleState>,
  appModule: AppModules
): AssetsBrowser2024StreamShape => {
  const appModuleState = state.appModuleStates[appModule];
  return {
    ...state,
    appModuleStates: {
      ...state.appModuleStates,
      [appModule]: {
        ...appModuleState,
        ...newAppModuleState,
      },
    },
  };
};

const getUpdatedPerPageBasedOnUserSettings = (
  state: AssetsBrowser2024StreamShape,
  currentUser: CurrentUserState
): Record<AppModules, AppModuleState> => {
  return Object.entries(state.appModuleStates).reduce(
    (acc, [appModule, appModuleState]) => {
      const perPage =
        currentUser.settings?.assetsBrowserPerPage?.[appModule] ??
        appModuleState.perPage;
      acc[appModule as AppModules] = {
        ...appModuleState,
        perPage,
      };
      return acc;
    },
    {} as Record<AppModules, AppModuleState>
  );
};

const handleCurrentUserChange = (
  state: AssetsBrowser2024StreamShape,
  currentUser: CurrentUserState
): AssetsBrowser2024StreamShape => {
  return {
    ...state,
    permissions: {
      ...state.permissions,
      currentUserIsOrgWriter: getIsOrgWriter(currentUser),
      canCreateViewpoint: currentUserHasPrivilege(
        currentUser,
        PrivilegeLabel.CREATE_VIEWPOINT
      ),
      canCreateSurvey: currentUserHasPrivilege(
        currentUser,
        PrivilegeLabel.CREATE_SURVEY
      ),
      canCreateDashboard: dashboardAccessControlInterface.canCreateDashboard(
        state.permissions.permissionContext
      ),
    },
    features: {
      ...state.features,
      traversalsEnabled: hasFeature(Features.SUPPORT_LARGE_DATASETS),
      scenariosEnabled: hasFeature(Features.SCENARIOS_BETA),
      bookmarksEnabled: hasFeature(Features.BOOKMARKS),
      presentationsEnabled: hasFeature(Features.PRESENTATIONS),
      surveysEnabled: hasFeature(Features.SURVEYS),
      broadcastsEnabled: hasFeature(Features.BROADCASTS),
      discoverEnabled: currentUserHasPrivilege(
        currentUser,
        PrivilegeLabel.ACCESS_DISCOVER
      ),
    },
    appModuleStates: getUpdatedPerPageBasedOnUserSettings(state, currentUser),
  };
};

const handleSetExpandedFoldersIds = (
  state: AssetsBrowser2024StreamShape,
  {
    payload: expandedFoldersIds,
    appModule,
  }: AssetsBrowser2024ActionPayload<string[]>
): AssetsBrowser2024StreamShape => {
  return assignNewValueToCurrentAppModuleState(
    state,
    {
      expandedFoldersIds,
    },
    appModule
  );
};

const handleAssetsChange = (
  state: AssetsBrowser2024StreamShape,
  assetsState: AssetsStreamState
): AssetsBrowser2024StreamShape => {
  const assetsById: Record<string, Asset> = {
    ...assetsState.assetFoldersById,
    ...assetsState.metamodelsById,
    ...assetsState.presentationsById,
    ...assetsState.surveysById,
    ...assetsState.workspaceAssetsById,
    ...assetsState.scenariosById,
    ...assetsState.reportsById,
    ...assetsState.dashboardsById,
    ...assetsState.traversalsById,
    ...assetsState.bookmarksById,
    ...assetsState.viewpointsById,
    ...assetsState.broadcastsById,
  };

  const newAppModuleStates = Object.entries(state.appModuleStates).reduce(
    (acc, [appModule, appModuleState]) => {
      const dataSource = getFilteredDatasource(
        assetsState,
        appModuleState.searchPhrase,
        appModuleState.activeFilters,
        appModuleState.alwaysActiveFilters,
        appModuleState.useFolders,
        state.locale
      );
      acc[appModule as AppModules] = {
        ...appModuleState,
        dataSource,
      };
      return acc;
    },
    {} as Record<AppModules, AppModuleState>
  );

  return {
    ...state,
    appModuleStates: newAppModuleStates,
    assetsById,
    assetsState,
  };
};

const handleSurveysChange = (
  state: AssetsBrowser2024StreamShape,
  { surveysWithPendingApprovals }: SurveyAdminStreamState
) => {
  return {
    ...state,
    surveysWithPendingApprovals,
  };
};

const handleSetSearchPhrase = (
  state: AssetsBrowser2024StreamShape,
  { payload: searchPhrase, appModule }: AssetsBrowser2024ActionPayload<string>
): AssetsBrowser2024StreamShape => {
  const { activeFilters, alwaysActiveFilters, useFolders } =
    state.appModuleStates[appModule];
  const dataSource = getFilteredDatasource(
    state.assetsState,
    searchPhrase,
    activeFilters,
    alwaysActiveFilters,
    useFolders,
    state.locale
  );
  const expandedFoldersIds = collectExpandedIdsFromDataSource(
    dataSource,
    searchPhrase
  );

  return assignNewValueToCurrentAppModuleState(
    state,
    {
      searchPhrase,
      expandedFoldersIds,
      dataSource,
      currentPageNumber: 1,
    },
    appModule
  );
};

const handleSetFilterActive = (
  state: AssetsBrowser2024StreamShape,
  { payload: filter, appModule }: AssetsBrowser2024ActionPayload<Filter>
): AssetsBrowser2024StreamShape => {
  const { activeFilters, alwaysActiveFilters, searchPhrase, useFolders } =
    state.appModuleStates[appModule];
  const newActiveFilters = [filter, ...activeFilters];
  const dataSource = getFilteredDatasource(
    state.assetsState,
    searchPhrase,
    newActiveFilters,
    alwaysActiveFilters,
    useFolders,
    state.locale
  );
  return assignNewValueToCurrentAppModuleState(
    state,
    {
      activeFilters: newActiveFilters,
      dataSource,
      currentPageNumber: 1,
    },
    appModule
  );
};

const handleSetFilterInactive = (
  state: AssetsBrowser2024StreamShape,
  { payload: filter, appModule }: AssetsBrowser2024ActionPayload<Filter>
): AssetsBrowser2024StreamShape => {
  const { activeFilters, alwaysActiveFilters, searchPhrase, useFolders } =
    state.appModuleStates[appModule];
  const newActiveFilters = activeFilters.filter(
    activeFilter => activeFilter.label !== filter.label
  );
  const dataSource = getFilteredDatasource(
    state.assetsState,
    searchPhrase,
    newActiveFilters,
    alwaysActiveFilters,
    useFolders,
    state.locale
  );
  return assignNewValueToCurrentAppModuleState(
    state,
    {
      activeFilters: newActiveFilters,
      dataSource,
      currentPageNumber: 1,
    },
    appModule
  );
};

const handleClearFilter = (
  state: AssetsBrowser2024StreamShape,
  { appModule }: AssetsBrowser2024ActionPayload<undefined>
): AssetsBrowser2024StreamShape => {
  const newActiveFilters: Filter[] = [];
  const { alwaysActiveFilters, searchPhrase, useFolders } =
    state.appModuleStates[appModule];
  const dataSource = getFilteredDatasource(
    state.assetsState,
    searchPhrase,
    newActiveFilters,
    alwaysActiveFilters,
    useFolders,
    state.locale
  );
  return assignNewValueToCurrentAppModuleState(
    state,
    {
      activeFilters: newActiveFilters,
      dataSource,
      currentPageNumber: 1,
    },
    appModule
  );
};

const handlePermissionContextChange = (
  state: AssetsBrowser2024StreamShape,
  permissionContext: PermissionContext
): AssetsBrowser2024StreamShape => {
  return {
    ...state,
    permissions: {
      ...state.permissions,
      permissionContext,
    },
  };
};

const handleSetSelectedAssets = (
  state: AssetsBrowser2024StreamShape,
  {
    payload: selectedAssets,
    appModule,
  }: AssetsBrowser2024ActionPayload<ArdoqId[]>
): AssetsBrowser2024StreamShape => {
  return assignNewValueToCurrentAppModuleState(
    state,
    {
      selectedAssets,
    },
    appModule
  );
};

const handleSetRenameId = (
  state: AssetsBrowser2024StreamShape,
  {
    payload: renameId,
    appModule,
  }: AssetsBrowser2024ActionPayload<ArdoqId | null>
): AssetsBrowser2024StreamShape => {
  return assignNewValueToCurrentAppModuleState(
    state,
    {
      renameId,
    },
    appModule
  );
};

const handleSetCurrentPageNumber = (
  state: AssetsBrowser2024StreamShape,
  {
    payload: currentPageNumber,
    appModule,
  }: AssetsBrowser2024ActionPayload<number>
): AssetsBrowser2024StreamShape => {
  return assignNewValueToCurrentAppModuleState(
    state,
    {
      currentPageNumber,
    },
    appModule
  );
};

const handleSetPerPage = (
  state: AssetsBrowser2024StreamShape,
  { payload: perPage, appModule }: AssetsBrowser2024ActionPayload<number>
): AssetsBrowser2024StreamShape => {
  return assignNewValueToCurrentAppModuleState(
    state,
    {
      perPage,
    },
    appModule
  );
};

const handleSetIsUpdating = (
  state: AssetsBrowser2024StreamShape,
  isUpdating: boolean
): AssetsBrowser2024StreamShape => {
  return {
    ...state,
    isUpdating,
  };
};

const handleUpdateSorting = (
  state: AssetsBrowser2024StreamShape,
  {
    payload: sortByField,
    appModule,
  }: AssetsBrowser2024ActionPayload<string | null>
): AssetsBrowser2024StreamShape => {
  const currentAppModuleState = state.appModuleStates[appModule];
  return assignNewValueToCurrentAppModuleState(
    state,
    {
      sortByField,
      sortOrder: findSortOrder(
        currentAppModuleState.sortOrder,
        currentAppModuleState.sortByField,
        sortByField
      ),
    },
    appModule
  );
};

const handleUpdateDataSource = (
  state: AssetsBrowser2024StreamShape,
  {
    payload: { alwaysActiveFilters, useFolders },
    appModule,
  }: AssetsBrowser2024ActionPayload<UpdateDataSourcePayload>
): AssetsBrowser2024StreamShape => {
  const appModuleState = state.appModuleStates[appModule];
  const dataSource = getFilteredDatasource(
    state.assetsState,
    appModuleState.searchPhrase,
    appModuleState.activeFilters,
    alwaysActiveFilters,
    useFolders,
    state.locale
  );
  return assignNewValueToCurrentAppModuleState(
    state,
    {
      dataSource,
      alwaysActiveFilters,
      useFolders,
    },
    appModule
  );
};

const setLocaleReducer = (
  state: AssetsBrowser2024StreamShape,
  locale: Locale
) => ({ ...state, locale });

const handleExpandFolder = (
  state: AssetsBrowser2024StreamShape,
  { payload: folderId, appModule }: AssetsBrowser2024ActionPayload<string>
): AssetsBrowser2024StreamShape => {
  const expandedFoldersIds = [
    ...state.appModuleStates[appModule].expandedFoldersIds,
  ];
  const newExpandedFoldersIds = [...expandedFoldersIds, folderId];
  return assignNewValueToCurrentAppModuleState(
    state,
    {
      expandedFoldersIds: newExpandedFoldersIds,
    },
    appModule
  );
};

const reducers = [
  streamReducer(locale$, setLocaleReducer),
  streamReducer(currentUser$, handleCurrentUserChange),
  streamReducer(assets$, handleAssetsChange),
  streamReducer(surveyAdmin$, handleSurveysChange),
  streamReducer(currentUserPermissionContext$, handlePermissionContextChange),
  reducer(setExpandedFoldersIds, handleSetExpandedFoldersIds),
  reducer(setSearchPhrase, handleSetSearchPhrase),
  reducer(setFilterActive, handleSetFilterActive),
  reducer(setFilterInactive, handleSetFilterInactive),
  reducer(clearFilter, handleClearFilter),
  reducer(setSelectedAssets, handleSetSelectedAssets),
  reducer(setRenameId, handleSetRenameId),
  reducer(setCurrentPageNumber, handleSetCurrentPageNumber),
  reducer(setPerPage, handleSetPerPage),
  reducer(setIsUpdating, handleSetIsUpdating),
  reducer(updateSorting, handleUpdateSorting),
  reducer(updateDataSource, handleUpdateDataSource),
  reducer(expandFolder, handleExpandFolder),
];

export default reducers;
