import styled from 'styled-components';
import { info } from '@ardoq/modal';
import { SvgImage, SvgImageName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { currentYear } from '@ardoq/date-time';
import { APP_VERSION } from 'appConfig';
import { Text } from '@ardoq/typography';
import { Stack } from '@ardoq/layout';

const LogoImageWrapper = styled.div`
  color: ${colors.brand50};
  text-align: center;
  & > svg {
    width: 100px;
  }
`;

const LinkList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const Link = ({ href, text }: { href: string; text: string }) => (
  <li>
    <a href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  </li>
);

const openAboutBox = () => {
  const versionAPI = window.API_VERSION;

  info({
    title: 'About',
    text: (
      <>
        <LogoImageWrapper>
          <SvgImage svgImageName={SvgImageName.ARDOQ_LOGO_WITH_NAME} />
        </LogoImageWrapper>
        <Text align="center">
          <Stack gap="medium">
            <Text>2013-{currentYear()} © Ardoq AS, All Rights Reserved.</Text>
            <LinkList>
              <Link href="https://ardoq.com/about-ardoq" text="About Ardoq" />
              <Link href="https://ardoq.com/schedule-demo" text="Contact us" />
            </LinkList>
            <LinkList>
              <Link
                href="/resources/Ardoq Subscription Terms Global.pdf"
                text="Terms and Policies"
              />
              <Link href="https://ardoq.com/privacy" text="Privacy Notice" />
            </LinkList>
            <Stack gap="xsmall">
              <Text>Version: {APP_VERSION}</Text>
              <Text>API Version: {versionAPI}</Text>
            </Stack>
          </Stack>
        </Text>
      </>
    ),
  });
};

export default openAboutBox;
