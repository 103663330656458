import { colors, s16, s24 } from '@ardoq/design-tokens';
import { TextInput } from '@ardoq/forms';
import { InfoIcon } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { CreatableSelectProps } from '@ardoq/select';
import { header3, header4, text1 } from '@ardoq/typography';

import styled from 'styled-components';
import React from 'react';
import { FlexBox } from '@ardoq/layout';

type ProductFieldsFormParams = {
  children: [
    React.ReactElement<CreatableSelectProps<string>>,
    React.ReactElement<CreatableSelectProps<string>>,
  ];
};

export function ProductFieldsForm({
  children: [IDSelector, UrlSelector],
}: ProductFieldsFormParams) {
  return (
    <>
      <Header3>1. Map tech product fields</Header3>
      <FlexBox justify="space-between" align="center" flex={1} gap="small">
        <FormContainer>
          <div>&nbsp;</div>
          <Header4>
            IT-Pedia tech product fields
            <WithPopover
              content={() => (
                <TechProductPopoverContent>
                  Tech product fields:
                  <ul>
                    <li>Product ID is the Identifier used in IT-Pedia</li>
                    <li>
                      Product URL is the link to the product details page,
                      easily jump to the product details page
                    </li>
                  </ul>
                </TechProductPopoverContent>
              )}
            >
              <InfoIcon color={colors.grey50} />
            </WithPopover>
          </Header4>
          <div>&nbsp;</div>
          <Header4>Ardoq fields</Header4>
          <GridDivider />
          <Text1>Map</Text1>
          <TextInput value="IT-Pedia product ID" isReadOnly />

          <Text1>to</Text1>
          {IDSelector}

          <Text1>Map</Text1>
          <TextInput value="IT-Pedia product URL" isReadOnly />

          <Text1>to</Text1>
          {UrlSelector}
        </FormContainer>
      </FlexBox>
    </>
  );
}

const Header3 = styled.div`
  ${header3};
`;

const Text1 = styled.div`
  ${text1};
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 300px min-content 300px;
  text-overflow: nowrap;
  align-items: center;
  gap: ${s16};
`;

const GridDivider = styled.hr`
  grid-column: span 4;
  width: 100%;
  border: 0;
  border-top: 1px solid ${colors.borderSubtle00};
  margin: 0;
`;

const Header4 = styled(FlexBox).attrs({ gap: 'medium' })`
  ${header4};
`;

const TechProductPopoverContent = styled.div`
  max-width: 272px;
  ul {
    padding-left: ${s24};
  }
`;
