import Backbone from 'backbone';
import BaseBackboneModel from 'BaseBackboneModel';
import { getApiUrl } from 'backboneExtensions';
import {
  ArdoqId,
  GroupResourcePermission,
  ResourceType,
} from '@ardoq/api-types';

type InitResourcePermissionAttributes = {
  resource: ArdoqId;
  'resource-type': ResourceType;
};

/**
 * This model represents the permissions of a resource.
 * It contains all of the user and group permissions to the resource.
 *
 * If you are wondering about the current user permissions for a resource,
 * see the currentUserPermissions interface
 */
class ResourcePermissions extends BaseBackboneModel({
  idAttribute: 'resource',
}) {
  constructor(attributes: InitResourcePermissionAttributes) {
    super({
      permissions: [],
      groups: [],
      ...attributes,
    });
  }
  urlRoot = () =>
    `${getApiUrl(Backbone.Model)}/api/permission/${this.get('resource-type')}`;

  getResourceType() {
    return this.get('resource-type') as ResourceType;
  }

  getGroups() {
    return this.get('groups') as GroupResourcePermission[];
  }
}

export default ResourcePermissions;
