import { DirectedTriple, ReferenceDirection } from '@ardoq/api-types';
import { List } from './atoms';
import { OutgoingTripleOption, IncomingTripleOption } from './TripleOption';
import { InfoNotification } from '@ardoq/status-ui';
import { TripleOptions } from 'viewpointBuilder/types';
import { TogglerStyle } from './TriplesToggler';

export type TripleOptionsProps = {
  options: TripleOptions['outgoing'];
  hasTripleOptions: boolean;
  direction: ReferenceDirection;
  selection?: Record<string, boolean>;
  toggleOption: (
    tripleId: string,
    direction: ReferenceDirection,
    namedDirectedTriple: DirectedTriple,
    isSelected: boolean
  ) => void;
  selectedComponent: TripleOptions['selectedNode'];
  togglerStyle: TogglerStyle;
  isDisabledWholeRow: boolean;
};

export const TripleOptionsList = ({
  options,
  direction,
  toggleOption,
  selectedComponent: { metaDataComponent: metaDataComponentSelected },
  hasTripleOptions,
  togglerStyle,
  isDisabledWholeRow,
}: TripleOptionsProps) => {
  const isOutgoing = direction === ReferenceDirection.OUTGOING;
  if (!hasTripleOptions) {
    return (
      <InfoNotification>
        {`There are no ${
          isOutgoing ? 'outgoing' : 'incoming'
        } references for the selected component type`}
      </InfoNotification>
    );
  }

  return (
    <List>
      {options.map(
        ({
          tripleId,
          metaDataComponent,
          metaDataReference,
          isSelected,
          namedDirectedTriple,
          instanceCounts,
          isDisabled,
          togglingDisabledExplanation,
        }) =>
          isOutgoing ? (
            <OutgoingTripleOption
              togglerStyle={togglerStyle}
              isDisabledWholeRow={isDisabledWholeRow}
              key={tripleId}
              tripleId={tripleId}
              metaDataComponent={metaDataComponent}
              metaDataReference={metaDataReference}
              isSelected={isSelected}
              isDisabled={isDisabled}
              togglingDisabledExplanation={togglingDisabledExplanation}
              direction={direction}
              toggleOption={toggleOption}
              metaDataComponentSelected={metaDataComponentSelected}
              namedDirectedTriple={namedDirectedTriple}
              instanceCounts={instanceCounts}
            />
          ) : (
            <IncomingTripleOption
              togglerStyle={togglerStyle}
              isDisabledWholeRow={isDisabledWholeRow}
              key={tripleId}
              tripleId={tripleId}
              metaDataComponent={metaDataComponent}
              metaDataReference={metaDataReference}
              isSelected={isSelected}
              isDisabled={isDisabled}
              togglingDisabledExplanation={togglingDisabledExplanation}
              direction={direction}
              toggleOption={toggleOption}
              metaDataComponentSelected={metaDataComponentSelected}
              namedDirectedTriple={namedDirectedTriple}
              instanceCounts={instanceCounts}
            />
          )
      )}
    </List>
  );
};
