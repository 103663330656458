import { CreatableSelect, CreatableSelectProps } from '@ardoq/select';
import React, { useMemo } from 'react';
import { getDropdownWsInfoComponents } from './components';
import { ArdoqId } from '@ardoq/api-types';
import { WorkspaceSelectorContext, WsInfo } from './context';

type ExtendedWorkspaceSelectorComponentProps<TValue> = {
  wsInfo: WsInfo | null;
  setActiveWsId: (wsId: ArdoqId | undefined) => void;
  isCreationDisabled?: boolean;
} & CreatableSelectProps<TValue>;

export const ExtendedWorkspaceSelectorComponent = <TValue,>({
  wsInfo,
  setActiveWsId,
  isCreationDisabled = true,
  onMenuClose = () => {},
  ...rest
}: ExtendedWorkspaceSelectorComponentProps<TValue>) => {
  const memoizedComponents = useMemo(
    () => getDropdownWsInfoComponents<TValue>({ setActiveWsId }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <WorkspaceSelectorContext.Provider value={wsInfo}>
      <CreatableSelect
        {...rest}
        components={memoizedComponents}
        onMenuClose={() => {
          onMenuClose();
          // Reset active workspace id
          setActiveWsId(undefined);
        }}
        isValidNewOption={option => !!option && !isCreationDisabled}
      />
    </WorkspaceSelectorContext.Provider>
  );
};
