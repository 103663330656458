import styled from 'styled-components';
import IconClickable from 'atomicComponents/IconClickable';
import { TreeRowContent } from 'atomicComponents/TreeRowContent';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { pseudoTopBottomBorder } from 'components/WorkspaceHierarchies/utils/utils';
import { colors } from '@ardoq/design-tokens';

const PADDING_LEFT_MULTIPLIER = 20;

export const AddIcon = styled(IconClickable)`
  opacity: 0;
`;

export const RowWrapper = styled.div<{
  $depth: number;
  $selected: boolean;
  $height: number;
  $isMultiSelect: boolean;
  $showSelectedBorder: boolean;
}>`
  cursor: pointer;
  display: flex;
  user-select: none;
  padding-left: ${props => `${props.$depth * PADDING_LEFT_MULTIPLIER}px`};
  height: ${props => `${props.$height}px`};
  background-color: ${props =>
    props.$selected
      ? props.$isMultiSelect
        ? 'hsla(210, 100%, 50%, 0.3)'
        : colors.white
      : 'transparent'};
  font-weight: ${props => (props.$selected ? 'bold' : 'normal')};
  border-left: 6px solid transparent;
  ${pseudoTopBottomBorder};
  box-sizing: border-box;
  flex-direction: row;
  width: 100%;
  &:hover {
    border-left-color: black;
  }
  &:hover ${AddIcon} {
    opacity: 1;
  }
  ${props =>
    props.$selected && !props.$isMultiSelect && 'background-size: 100% 1px'};
  ${props =>
    props.$selected &&
    props.$showSelectedBorder &&
    `border-left-color: ${colors.purple60};`}
`;

export const RowContent = styled(TreeRowContent)<{ $ghosted: boolean }>`
  opacity: ${props => (props.$ghosted ? 0.5 : 1)};
`;

const RowLabel = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 24px;
`;

export const ComponentLabel = RowLabel;

export const WorkspaceLabel = styled(RowLabel)`
  font-size: 16px;
`;

export const Representation = styled.div`
  line-height: inherit;
  margin-right: 6px;
  text-align: center;
  width: 18px;
`;

export const MasterIcon = styled(Icon).attrs({
  iconName: IconName.MASTER,
  iconSize: IconSize.SMALL,
})`
  margin-right: 6px;
  color: ${colors.grey35};
  fill: ${colors.grey35};
`;

export const RelatedContextWrapper = styled.div`
  width: 100%;
  padding: 16px;
  font-size: 14px;
`;
