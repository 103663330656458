import { actionCreator } from '@ardoq/rxbeach';
import { Subscription, SubscriptionsState } from './types';

const NAMESPACE = '[integrations azure-v3] SUBSCRIPTION';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export type FetchAzureSubscriptionsSuccessPayload = {
  subscriptions: Subscription[];
};

export const fetchAzureSubscriptionsSuccess =
  actionCreator<FetchAzureSubscriptionsSuccessPayload>(
    getActionName('FETCH_AZURE_SUBSCRIPTIONS_SUCCESS')
  );

export const fetchAzureSubscriptionsFailure = actionCreator(
  getActionName('FETCH_AZURE_SUBSCRIPTIONS_FAILURE')
);

export const updateSubscriptionList = actionCreator<
  Partial<SubscriptionsState>
>(`${NAMESPACE}_UPDATE_SUBSCRIPTIONS_LIST`);

export const resetAzureSubscriptions = actionCreator(
  getActionName('RESET_SUBSCRIPTIONS')
);
