import { APIOrganizationUser } from '@ardoq/api-types';
import { s8, colors, s4 } from '@ardoq/design-tokens';
import { Icon, IconName } from '@ardoq/icons';
import { SwitchPosition } from '@ardoq/snowflakes';
import { DatasourceTable, FlyWheelTable } from '@ardoq/table';
import { ApprovalStatus, ChangeApprovalRowData } from '../types';
import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import { EnhancedScopeData } from '@ardoq/data-model';
import { combineLatest, map } from 'rxjs';
import changeApprovalData$ from '../changeApprovalData$';
import { getFieldLabel } from './utils';
import { isEqual } from 'lodash';
import {
  ApprovalSwitch,
  ChangedFromRenderer,
  ChangedToRenderer,
} from './TableCellRenderers';
import { DoqType } from '@ardoq/doq';
import EmptyState from 'components/AssetsBrowser2024/EmptyState';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { localeCompareNumericLowercase, getDefaultLocale } from '@ardoq/locale';

export const getApprovalStatus = (
  valueSetInApprovedChanges: unknown,
  changedTo: unknown
): ApprovalStatus => {
  return valueSetInApprovedChanges === undefined
    ? 'pending'
    : isEqual(valueSetInApprovedChanges, changedTo)
      ? 'approved'
      : 'rejected';
};

const Tr = styled.tr`
  height: 40px;
`;

const Table = styled(FlyWheelTable.Table)`
  border-collapse: separate;
  border-spacing: 0 ${s4};
`;

const basicCellStyling = {
  width: '32%',
  maxWidth: 300,
  height: 40,
  transition: 'all 0.3s ease-in-out',
  paddingTop: s8,
};

const headerStyle = {
  borderBottom: `1px solid ${colors.grey90}`,
  paddingBottom: s8,
};

type UpdateTableProps = {
  masterData: EnhancedScopeData;
  branchData: EnhancedScopeData;
  users: Record<string, APIOrganizationUser>;
  dataSource: ChangeApprovalRowData[];
  handleApprovedDataForSpecificField: (
    switchPosition: SwitchPosition,
    rowData: ChangeApprovalRowData
  ) => void;
};

const UpdateTable = ({
  masterData,
  branchData,
  users,
  dataSource,
  handleApprovedDataForSpecificField,
}: UpdateTableProps) => {
  const sortedDataSource = dataSource.sort((a, b) =>
    localeCompareNumericLowercase(
      a.whatChanged,
      b.whatChanged,
      getDefaultLocale()
    )
  );
  return (
    <DatasourceTable
      style={{ width: '100%' }}
      columns={[
        {
          title: 'What changed',
          headerStyle,
          dataIndex: 'whatChanged',
          cellStyle: { width: '25%', paddingTop: s8 },
          valueRender: (_any: any, rowData: ChangeApprovalRowData) => {
            return getFieldLabel(rowData.whatChanged, masterData);
          },
        },
        {
          title: 'Changed from',
          headerStyle,
          dataIndex: 'changedFrom',
          cellStyle: basicCellStyling,
          valueRender: (_any: any, rowData: ChangeApprovalRowData) => {
            return (
              <ChangedFromRenderer
                rowData={rowData}
                masterData={masterData}
                users={users}
              />
            );
          },
        },
        {
          headerStyle,
          cellStyle: {
            width: 40,
            paddingLeft: s8,
            verticalAlign: 'bottom',
            paddingTop: s8,
          },
          valueRender: () => {
            return <Icon iconName={IconName.ARROW_FORWARD} />;
          },
        },
        {
          title: 'Changed to',
          headerStyle,
          dataIndex: 'changedTo',
          cellStyle: basicCellStyling,
          valueRender: (_any: any, rowData: ChangeApprovalRowData) => {
            return (
              <ChangedToRenderer
                rowData={rowData}
                branchData={branchData}
                users={users}
                status={rowData.status}
              />
            );
          },
        },
        {
          headerStyle,
          cellStyle: { width: 72, paddingLeft: s8, paddingTop: s8 },
          valueRender: (_any: any, rowData: ChangeApprovalRowData) => {
            return (
              <ApprovalSwitch
                rowData={rowData}
                status={rowData.status}
                handleApprovedDataForSpecificField={
                  handleApprovedDataForSpecificField
                }
              />
            );
          },
        },
      ]}
      components={{ ...FlyWheelTable, BodyTr: Tr, Table }}
      dataSource={sortedDataSource}
      renderEmptyTable={() => (
        <EmptyState
          doqType={DoqType.ERROR}
          title="Something went wrong!"
          text="We couldn't find any changes. Please try again or contact customer support."
        />
      )}
    />
  );
};

const combinedData$ = combineLatest([changeApprovalData$, orgUsers$]).pipe(
  map(([{ masterData, branchData, changeAction }, { byId: users }]) => {
    return {
      masterData,
      branchData,
      changeAction,
      users,
    };
  })
);

export default connect(UpdateTable, combinedData$);
