import { s24 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { RichTextEditor } from '@ardoq/rich-text-editor';
import { Checkbox, Label } from '@ardoq/forms';
import { trackEvent } from 'tracking/tracking';
import { SurveyEditorSectionProps } from './SurveyEditor';

const onSurveySubmitAttributeName = 'responseFeedbackOnSurveySubmit';
const messageAttributeName = 'responseFeedbackMessage';

const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${s24};
`;

export default function SurveyEditorResponseFeedback({
  surveyAttributes,
  setSurveyAttributes,
}: SurveyEditorSectionProps) {
  return (
    <EventsContainer>
      <Checkbox
        isChecked={Boolean(surveyAttributes[onSurveySubmitAttributeName])}
        onChange={() => {
          setSurveyAttributes({
            [onSurveySubmitAttributeName]:
              !surveyAttributes[onSurveySubmitAttributeName],
          });
          trackEvent('Survey builder: toggle show response feedback', {
            toggle: !surveyAttributes[onSurveySubmitAttributeName],
          });
        }}
      >
        On Survey Submit
      </Checkbox>

      <div>
        <Label>Feedback Message</Label>
        <RichTextEditor
          content={surveyAttributes[messageAttributeName] ?? ''}
          onInputChange={value => {
            setSurveyAttributes({ [messageAttributeName]: value });
          }}
          onBlur={() => trackEvent('Survey builder: edit response feedback')}
        />
      </div>
    </EventsContainer>
  );
}
