import { modal } from '@ardoq/modal';
import AssetsBrowserDialogContent from './AssetsBrowserDialogContent';
import workspaceAssets$ from 'components/AssetsBrowser/workspaceAssets$';
import {
  openWorkspaces,
  openWorkspacesInViewpointMode,
} from 'appContainer/DashboardContainer/utils';
import { openAssetsBrowser } from './actions';
import {
  collectRoutines,
  routine,
  connect,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs/operators';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { Features, hasFeature } from '@ardoq/features';

const ConnectedAssetsBrowserDialogContent = connect(
  AssetsBrowserDialogContent,
  workspaceAssets$
);

const handleOpenAssetsBrowser = routine(
  ofType(openAssetsBrowser),
  extractPayload(),
  withLatestFrom([isViewpointMode$]),
  tap(async ([openWorkspaceBrowserPayload, isViewpointMode]) => {
    const selectedIds = await modal<string[]>(resolve => (
      <ConnectedAssetsBrowserDialogContent resolve={resolve} />
    ));
    if (!selectedIds || !selectedIds.length) return;

    const options =
      openWorkspaceBrowserPayload &&
      openWorkspaceBrowserPayload.forPresentationId
        ? { forPresentationId: openWorkspaceBrowserPayload.forPresentationId }
        : { keepCurrentContext: true };

    if (isViewpointMode && hasFeature(Features.CANVAS_PROTOTYPE)) {
      openWorkspacesInViewpointMode(selectedIds, {
        trackingLocation: 'assetsManagerModal',
        ...options,
      });
      return;
    }

    openWorkspaces(selectedIds, {
      trackingLocation: 'assetsManagerModal',
      ...options,
    });
  })
);

export const assetsBrowserRoutines = collectRoutines(handleOpenAssetsBrowser);
