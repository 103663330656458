import { useEffect, useState } from 'react';
import { ModalLayout, ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { Select } from '@ardoq/select';
import {
  CheckboxGroup,
  CheckboxGroupItem,
  CheckboxGroupValue,
  FormSize,
} from '@ardoq/forms';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  CopyWsStreamState,
  TargetOrg,
  copyError,
  copySuccess,
  copyWorkspacesToOrg,
  copyws$,
  dialogTitle,
  resetCopyWs,
} from './copyws$';
import { WarningNotification } from '@ardoq/status-ui';
import { onFirstAction, subscribeToAction } from 'streams/utils/streamUtils';
import styled from 'styled-components';

type CopyWsToOrgModalProps = CopyWsStreamState & {
  onClose: VoidFunction;
};

const CopyWsToOrgModal = ({
  onClose,
  organizations,
  workspaces,
  error,
}: CopyWsToOrgModalProps) => {
  const [targetOrg, setTargetOrg] = useState<TargetOrg>();
  const [selectedWsIds, setSelectedWsIds] = useState(new Set<string>());
  const [isInProgress, setInProgress] = useState<boolean>(false);
  useEffect(() => {
    const subscription = onFirstAction(copySuccess, onClose);
    return () => subscription.unsubscribe();
  });
  return (
    <ModalTemplate
      modalSize={ModalSize.M}
      headerText={dialogTitle}
      isPrimaryButtonDisabled={!targetOrg?.value || selectedWsIds.size === 0}
      primaryButtonText="Copy"
      onPrimaryButtonClick={() => {
        setInProgress(true);
        dispatchAction(
          copyWorkspacesToOrg({ targetOrg: targetOrg!, selectedWsIds })
        );
        subscribeToAction(copyError, () => {
          setInProgress(false);
        });
      }}
      isInProgress={isInProgress}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={() => {
        dispatchAction(resetCopyWs());
        onClose();
      }}
    >
      <ModalLayout>
        {error && <WarningNotification> {error} </WarningNotification>}
        <SelectWrapper>
          <Select
            value={targetOrg}
            label="Organizations"
            options={organizations.map(({ name, label }) => ({
              label: name,
              value: label,
            }))}
            onChange={selectedValue => setTargetOrg(selectedValue as TargetOrg)}
          />
        </SelectWrapper>
        <CheckboxGroup
          label="Workspaces"
          onValueChange={(selectedIds: CheckboxGroupValue) => {
            setSelectedWsIds(new Set([...selectedIds.map(c => c.toString())]));
          }}
        >
          {workspaces.map(({ id, name }) => (
            <CheckboxGroupItem key={id} value={id}>
              {name}
            </CheckboxGroupItem>
          ))}
        </CheckboxGroup>
      </ModalLayout>
    </ModalTemplate>
  );
};
const ConnectedCopyWsToOrgModal = connect(CopyWsToOrgModal, copyws$);

const SelectWrapper = styled.div`
  max-width: ${FormSize.DEFAULT};
`;

export const copyWsToOrgModal = () => {
  modal(
    resolve => <ConnectedCopyWsToOrgModal onClose={() => resolve(false)} />,
    {
      shouldCloseOnBackdropClick: false,
      shouldCloseOnEscapeKey: false,
    }
  );
};
