import {
  exportRun,
  importRun,
  startTransfer,
  transferFailure,
  transferRun,
  transferSuccess,
} from 'integrations/common/streams/transferState/actions';
import {
  dispatchAction,
  routine,
  ofType,
  extractPayload,
} from '@ardoq/rxbeach';
import {
  catchError,
  EMPTY,
  filter,
  from,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { tabularMappings$ } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getCurrentTransferConfig } from 'integrations/common/streams/transferConfigs/utils';
import { tabularMappingIntoTransferConfig } from 'integrations/common/streams/tabularMappings/utils';
import * as api from './api';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { selectionState$ } from '../streams/selectionState/selectionState$';
import { getSourceConfig } from '../utils';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';

const handleExport = routine(
  ofType(exportRun),
  extractPayload(),
  withLatestFrom(
    activeIntegrations$,
    tabularMappings$,
    transferConfigs$,
    getConnectionsStream('servicenow-v3')
  ),
  switchMap(
    ([
      { integrationId, async, dryRun },
      activeIntegrations,
      tabularMappings,
      transferConfigs,
      { selectedConnectionIds },
    ]) => {
      const activeIntegration = activeIntegrations[integrationId];
      const tabularMapping = tabularMappings[integrationId];
      const transferConfig = getCurrentTransferConfig(
        transferConfigs.configs,
        activeIntegration
      );

      const connectionId = selectedConnectionIds[0];

      if (tabularMapping && connectionId) {
        dispatchAction(
          startTransfer({ integrationId, tabularMapping, async, dryRun })
        );
        const config = tabularMappingIntoTransferConfig(
          tabularMapping,
          transferConfig
        );
        return of({
          connectionId,
          integrationId,
          payload: {
            dryRun,
            tabularMapping: {
              ...config,
              name: config.name || activeIntegration.integrationName,
            },
          },
        });
      }

      return EMPTY;
    }
  ),
  switchMap(({ connectionId, integrationId, payload }) => {
    return from(api.exportRun({ payload, connectionId })).pipe(
      catchError(error => {
        dispatchAction(
          transferFailure({
            dryRun: payload.dryRun,
            integrationId,
            errorMessage: error.message,
          })
        );
        return EMPTY;
      }),
      map(response => ({ integrationId, response, payload }))
    );
  }),
  tap(({ response, integrationId, payload }) => {
    dispatchAction(
      transferSuccess({
        integrationId,
        response,
        dryRun: payload.dryRun,
        transferDirection: TransferDirection.EXPORT,
      })
    );
  })
);

const handleTransferRun = routine(
  ofType(transferRun),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'servicenow-v3'),
  withLatestFrom(
    getTransferStateStream('servicenow-v3'),
    selectionState$,
    getConnectionsStream('servicenow-v3')
  ),
  map(
    ([
      { async, integrationId, dryRun },
      transferState,
      selectionState,
      connectionsState,
    ]) => {
      const sourceConfig = getSourceConfig(selectionState, connectionsState);

      return {
        async,
        integrationId,
        dryRun,
        transferState,
        ...(sourceConfig ? { sourceConfig } : {}),
      };
    }
  ),
  tap(({ transferState, ...runParams }) => {
    const action =
      transferState.transferDirection === TransferDirection.IMPORT
        ? importRun
        : exportRun;
    dispatchAction(action(runParams));
  })
);

export default [handleExport, handleTransferRun];
