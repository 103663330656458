import { dispatchAction } from '@ardoq/rxbeach';
import { showScenarioRelatedComponents } from 'scenarioRelated/actions';
import { Observable, combineLatest } from 'rxjs';
import { SelectionState } from 'components/VirtualWindow/streamBuilders/setupSelection$';
import { tap } from 'rxjs/operators';
import { withLatestFrom } from 'rxjs/operators';
import { scenarioRelated$ } from './scenarioRelated$';
import { getDescendantsAsFlatTree } from 'components/VirtualWindow/traverse';
import { filtered$ } from './scenarioRelatedDataSource$';

const multiSelectionAction = (
  componentIds: string[],
  isConnectedComponent: (id: string) => boolean
) => {
  dispatchAction(
    showScenarioRelatedComponents({
      componentIds: componentIds.filter(isConnectedComponent),
    })
  );
};

const singleSelectionAction = (
  selectedIds: string[],
  flattenTree: Record<string, string[]>,
  current: string,
  isConnectedComponent: (id: string) => boolean
) => {
  const getChildren = (id: string) => flattenTree[id] || [];
  const ids = [...new Set([current, ...selectedIds])];
  const descendantIds = Object.keys(getDescendantsAsFlatTree(getChildren, ids));
  dispatchAction(
    showScenarioRelatedComponents({
      componentIds: [...new Set([...descendantIds, ...selectedIds])].filter(
        isConnectedComponent
      ),
    })
  );
};

/*
 * Notify the view to show the selected components in the related navigator
 */
export const showRelatedComponentsRoutine = (
  selectionStream$: Observable<SelectionState>
) =>
  combineLatest([selectionStream$, filtered$]).pipe(
    withLatestFrom(scenarioRelated$),
    tap(
      ([
        [{ current, byId }, filtered],
        { flattenTree, connectedComponentsSet },
      ]) => {
        const filteredSet = new Set(filtered);
        const isConnectedComponent = (id: string) =>
          connectedComponentsSet.has(id) && (!filtered || filteredSet.has(id));

        const selectedIds = Object.entries(byId)
          .filter(([, value]) => Boolean(value))
          .map(([id]) => id);

        if (current && selectedIds.length) {
          if (selectedIds.length > 1) {
            multiSelectionAction(selectedIds, isConnectedComponent);
          } else {
            singleSelectionAction(
              selectedIds,
              flattenTree,
              current,
              isConnectedComponent
            );
          }
        } else {
          dispatchAction(
            showScenarioRelatedComponents({
              componentIds: selectedIds.filter(isConnectedComponent),
            })
          );
        }
      }
    )
  );
