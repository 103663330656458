import { ActionStream, action$, extractPayload, ofType } from '@ardoq/rxbeach';
import { map, startWith } from 'rxjs/operators';
import { createStateStream } from './utils/stateStreamUtils';
import { viewChanged } from './views/mainContent/actions';

const currentViewId$ = createStateStream({
  debugName: 'currentViewId$',
  isTemporary: false,
  createStream: (action$: ActionStream) =>
    action$.pipe(
      ofType(viewChanged),
      extractPayload(),
      map(({ viewId }) => viewId),
      startWith(undefined)
    ),
})(action$);

export default currentViewId$;
