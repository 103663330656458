import { AvailableTriples } from 'viewpoints/types';
import DynamicGroupedTraversalsForm from './DynamicGroupedTraversalsForm';
import EntryPointsForm from './EntryPointsForm';
import styled from 'styled-components';
import { s24 } from '@ardoq/design-tokens';
import { Features, hasFeature } from '@ardoq/features';
import { Label, Switch } from '@ardoq/forms';
import { useController } from 'react-hook-form';
import { Description } from './atoms';
import { trackEvent } from 'tracking/tracking';

const AllowExtraDataForCommunitySidebar = () => {
  const {
    field: { onChange, value },
  } = useController({
    name: 'includeConnectedPeople',
  });
  return (
    <div>
      <Label>Connected people</Label>
      <Switch
        onChange={isChecked => {
          onChange(isChecked);
          trackEvent('Toggled community sidebar switch in viewpoint builder', {
            toggle: isChecked,
          });
        }}
        name="includeConnectedPeople"
        isChecked={value ?? false}
        label="Show people directly connected to all components of this viewpoint"
      />
      <Description>
        Once activated, this feature will display all people components that are
        one step away from the viewpoint components, even if they are not
        included in the data rules above. People components defined by the data
        rules will still be visible in the viewpoint, even if this feature is
        turned off.
      </Description>
    </div>
  );
};

type ViewpointModelProps = {
  availableTriples: AvailableTriples;
  isDisabled: boolean;
  hasNoSubmittableChanges: boolean;
};

const Wrapper = styled.div`
  display: grid;
  gap: ${s24};
`;

const ViewpointModel = ({
  availableTriples,
  isDisabled,
  hasNoSubmittableChanges,
}: ViewpointModelProps) => {
  return (
    <Wrapper>
      <DynamicGroupedTraversalsForm
        availableTriples={availableTriples}
        isDisabled={isDisabled}
        hasNoSubmittableChanges={hasNoSubmittableChanges}
      />
      <EntryPointsForm />
      {!hasFeature(Features.DISABLE_CONNECTED_PEOPLE) && (
        <AllowExtraDataForCommunitySidebar />
      )}
    </Wrapper>
  );
};

export default ViewpointModel;
