import workspaces$ from 'streams/workspaces/workspaces$';
import content$ from 'broadcasts/content$';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getAudiencePreview$ } from 'broadcasts/routineUtils';
import { logError } from '@ardoq/logging';
import {
  getPeopleFromAudiencePreviewResponse,
  getWorkspaceAudience,
  toPeopleWorkspaceOptions,
} from './utils';
import {
  choosePeopleWorkspace,
  fetchPeopleInSelectedWorkspace,
  fetchPeopleWorkspaceOptions,
  notifyFailedToFetchPeopleInSelectedWorkspace,
  notifyFailedToFetchPeopleWorkspaceOptions,
  openExistingWorkspaceAudienceDialog,
  setPeopleInSelectedWorkspace,
  setPeopleWorkspaceOptions,
} from './actions';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';
import { graphSearchApi, handleError } from '@ardoq/api';
import { PEOPLE_WORKSPACE_ID_QUERY } from 'broadcasts/consts';

const handleOpenExistingWorkspaceAudienceDialog = routine(
  ofType(openExistingWorkspaceAudienceDialog),
  extractPayload(),
  tap(currentWorkspaceAudience => {
    const { workspaceId } = currentWorkspaceAudience;
    dispatchAction(fetchPeopleInSelectedWorkspace(workspaceId));
  })
);

const handleFetchPeopleWorkspaceOptions = routine(
  ofType(fetchPeopleWorkspaceOptions),
  switchMap(() =>
    graphSearchApi.graphSearch<string[]>({
      query: PEOPLE_WORKSPACE_ID_QUERY,
    })
  ),
  handleError(error => {
    logError(error, 'Failed to get people workspace ids from graph');
    dispatchAction(notifyFailedToFetchPeopleWorkspaceOptions());
  }),
  withLatestFrom(workspaces$),
  map(toPeopleWorkspaceOptions),
  tap(peopleWorkspaceOptions => {
    dispatchAction(setPeopleWorkspaceOptions(peopleWorkspaceOptions));
  })
);

const handleChoosePeopleWorkspace = routine(
  ofType(choosePeopleWorkspace),
  extractPayload(),
  tap(workspaceId => {
    dispatchAction(fetchPeopleInSelectedWorkspace(workspaceId));
  })
);

const handleFetchPeopleInSelectedWorkspace = routine(
  ofType(fetchPeopleInSelectedWorkspace),
  extractPayload(),
  withLatestFrom(content$),
  map(([workspaceId, content]) => ({
    content,
    audiences: [getWorkspaceAudience(workspaceId)],
  })),
  excludeNullAndUndefined(),
  switchMap(getAudiencePreview$),
  handleError(error => {
    logError(error, 'Failed to fetch workspace audience preview');
    dispatchAction(notifyFailedToFetchPeopleInSelectedWorkspace());
  }),
  map(getPeopleFromAudiencePreviewResponse),
  tap(people => dispatchAction(setPeopleInSelectedWorkspace(people)))
);

export default collectRoutines(
  handleOpenExistingWorkspaceAudienceDialog,
  handleFetchPeopleWorkspaceOptions,
  handleChoosePeopleWorkspace,
  handleFetchPeopleInSelectedWorkspace
);
