export enum AsyncOperations {
  IMPORT_DRY_RUN = 'import-dry-run',
  IMPORT = 'import',
  SERVICENOW_FETCH_TABLES = 'servicenow-fetch-tables',
  SERVICENOW_SELECTION = 'servicenow-selection',
  AZURE_FETCH_DATA = 'azure-fetch-data',
  AZURE_FETCH_RESOURCES_SUMMARY = 'azure-fetch-resources-summary',
  UNIFIED_FETCH_DATA = 'unified-fetch-data',
  AWS_FETCH_VPCS = 'aws-fetch-vpcs',
  AWS_FETCH_DATA = 'aws-fetch-data',
  MICROSOFT_ENTRA_ID_FETCH_DATA = 'microsoft-entra-id-fetch-data',
  MICROSOFT_ENTRA_ID_IMPORT_PICTURES = 'microsoft-entra-id-import-pictures',
  EXPORT_DRY_RUN = 'export-dry-run',
  EXPORT = 'export',
}
