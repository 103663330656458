import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { AppRouterState } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { map } from 'rxjs/operators';
import { ComponentOverviewPageRoute } from './types';
import { componentOverviewPage$ } from './componentOverviewPage$';
import { navigateToComponentOverviewPage } from 'router/navigationActions';

const componentOverviewPageRoute = new Route<
  AppRouterState,
  ComponentOverviewPageRoute
>({
  doesLocationMatch: ({ path }) =>
    new RegExp(`/component-overview/?`).test(path),
  locationToRouterState: ({ path }) => {
    const parts = path.split('/');
    const componentOverviewId = parts[2];
    return {
      appModule: AppModules.COMPONENT_OVERVIEW_PAGE,
      componentOverviewId,
    };
  },
  doesRouterStateMatch: ({ appModule }) =>
    appModule === AppModules.COMPONENT_OVERVIEW_PAGE,
  routerStateToLocation: ({ componentOverviewId }) => {
    return {
      path: `/component-overview/${componentOverviewId}`,
      title: 'Component Overview',
    };
  },
  setApplicationStateFromRoute: ({ componentOverviewId }) => {
    if (componentOverviewId) {
      dispatchAction(navigateToComponentOverviewPage(componentOverviewId));
    }
  },
  getPartialRouterStateStream: () =>
    componentOverviewPage$.pipe(
      map(({ componentId }) => ({
        componentOverviewId: componentId,
      }))
    ),
});

export default componentOverviewPageRoute;
