import styled, { css } from 'styled-components';
import {
  colors,
  r16,
  r4,
  r8,
  s16,
  s24,
  s4,
  s8,
  shadowL,
} from '@ardoq/design-tokens';
import { header4, text2, text1 } from '@ardoq/typography';
import { Space } from '@ardoq/style-helpers';
import { getFocusRingBorderCSS } from '@ardoq/style-helpers';

const menuItemCommon = css`
  height: 56px;
  padding: 0 15px 0 18px;
  display: flex;
  align-items: center;
  background-color: ${colors.grey35};
  color: #fff;
`;

const newMenuItemCommon = css`
  height: 40px;
  border-radius: ${r8};
  padding: 0px ${s8};
  background-color: ${colors.surfaceDefault};
`;

export const FullWidthSpace = styled(Space).attrs({
  $flex: 1,
  $align: 'center',
  $justify: 'space-between',
})`
  width: 100%;
`;

export const MenuTitle = styled.button<{
  $hasOnClick: boolean;
  $hasNewJourneyFeature?: boolean;
}>`
  ${header4};
  position: relative;
  width: 100%;
  border: none;

  ${({ $hasOnClick }) =>
    $hasOnClick &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${colors.grey60};
      }
    `}

  ${({ $hasNewJourneyFeature, $hasOnClick }) =>
    $hasNewJourneyFeature
      ? css`
          ${newMenuItemCommon}
          display: flex;
          align-items: center;
          color: ${colors.textDefault};
          ${$hasOnClick &&
          `&:hover {
            background-color: ${colors.surfaceHover};
          }`}
        `
      : css`
          ${menuItemCommon};
        `}
`;

export const MenuItemButton = styled.button<{
  $isActive?: boolean;
  $isDisabled?: boolean;
  $light?: boolean;
  $hasNewJourneyFeature?: boolean;
}>`
  ${menuItemCommon};
  cursor: pointer;
  display: flex;
  ${text1};
  gap: ${s4};
  &:hover {
    background-color: ${colors.grey60};
  }
  &:focus-visible {
    ${getFocusRingBorderCSS()}
  }

  border: none;
  width: 100%;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      pointer-events: none;
      color: rgba(255, 255, 255, 0.5);
    `}

  ${({ $light }) =>
    $light &&
    css`
      background-color: ${colors.grey60};
      &:hover {
        background-color: ${colors.grey35};
      }
    `}

  ${({ $hasNewJourneyFeature, $isDisabled }) =>
    $hasNewJourneyFeature &&
    css`
      ${newMenuItemCommon}
      color: ${colors.textModerate};
      ${text2};
      opacity: ${$isDisabled ? '32%' : '1'};
      &:hover {
        background-color: ${colors.surfaceHover};
      }
    `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${colors.surfaceHover};
    `}
`;

export const EllipsisSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuHoverPadding = styled.div<{
  $hasNewJourneyFeature: boolean;
  $hasSpaceBelow: boolean;
}>`
  position: absolute;
  left: ${({ $hasNewJourneyFeature }) =>
    $hasNewJourneyFeature ? `calc(100% - ${s4})` : '100%'};

  ${({ $hasNewJourneyFeature, $hasSpaceBelow }) =>
    $hasSpaceBelow
      ? css`
          top: ${$hasNewJourneyFeature ? `-${s24}` : '0'};
        `
      : css`
          bottom: ${$hasNewJourneyFeature ? `-${s24}` : '0'};
        `}

  ${({ $hasNewJourneyFeature }) =>
    $hasNewJourneyFeature &&
    css`
      padding: ${s16} ${s16} ${s16} ${s8};
    `}
`;

export const MenuWrapper = styled.div<{
  $hasNewJourneyFeature: boolean;
  $hasSpaceBelow: boolean;
}>`
  display: ${({ $hasNewJourneyFeature }) =>
    $hasNewJourneyFeature ? 'flex' : 'block'};
  width: 270px;

  ${({ $hasNewJourneyFeature, $hasSpaceBelow }) =>
    $hasNewJourneyFeature &&
    css`
      width: 240px;
      border-radius: ${$hasSpaceBelow
        ? `${r4} ${r16} ${r16} ${r16}`
        : `${r16} ${r16} ${r16} ${r4}`};
      box-shadow: ${shadowL};
      background-color: ${colors.surfaceDefault};
      padding: ${s8};
      flex-direction: column;
      gap: ${s8};
    `}
`;
