import type { DashboardApiError } from '@ardoq/dashboard';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  persistMetricsDashboardData,
  setMetricsDashboardApiError,
} from './actions';
import type { APIDashboardAttributes } from '@ardoq/api-types';
import { currentDate } from '@ardoq/date-time';

export type MetricsDashboard$State = {
  dashboardData: (APIDashboardAttributes & { _time: Date }) | null;
  apiError?: DashboardApiError;
};

const handleSetMetricsDashboard = (
  state: MetricsDashboard$State,
  loadedDashboard: APIDashboardAttributes
): MetricsDashboard$State => ({
  ...state,
  dashboardData: { ...loadedDashboard, _time: currentDate() },
});

const handleSetApiError = (
  state: MetricsDashboard$State,
  apiError: DashboardApiError
): MetricsDashboard$State => ({
  ...state,
  apiError,
});

const metricsDashboard$ = persistentReducedStream<MetricsDashboard$State>(
  'metricsDashboard$',
  { dashboardData: null },
  [
    reducer(persistMetricsDashboardData, handleSetMetricsDashboard),
    reducer(setMetricsDashboardApiError, handleSetApiError),
  ]
);

export default metricsDashboard$;
