import React from 'react';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { Paragraph } from '@ardoq/typography';
import { Stack } from '@ardoq/layout';
import { ProgressDetails } from 'integrations/common/streams/fetchProgress/types';

type ProgressBarProps = {
  progress: ProgressDetails;
  totalCount: number;
  progressLabel: string;
  renderProgressLabel?: (
    completed: number,
    total: number,
    progressLabel: string
  ) => string;
};

export const ProgressBar = ({
  progress,
  totalCount,
  progressLabel,
  renderProgressLabel,
}: ProgressBarProps) => {
  const completed = progress.completed ?? 0;
  const total = progress.total ?? totalCount;

  const progressPercentage = Math.min((completed / total) * 100, 100);

  return (
    <Stack gap="small" align="center">
      <Bar>
        <ProgressLine progressPercentage={progressPercentage} />
      </Bar>
      <Paragraph variant="caption" color="textModerate">
        {renderProgressLabel
          ? renderProgressLabel(completed, totalCount, progressLabel)
          : `${completed}/${totalCount} ${progressLabel}`}
      </Paragraph>
    </Stack>
  );
};

const Bar = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 8px;
  border: 1px solid ${colors.grey90};
  position: relative;
  background: repeating-linear-gradient(
      -45deg,
      ${colors.grey90} 0 15px,
      #0000 0 20px
    )
    left/200% 100%;
  animation: l3 4s infinite linear;

  @keyframes l3 {
    100% {
      background-position: right;
    }
  }
`;

const ProgressLine = styled.div<{ progressPercentage: number }>`
  width: ${({ progressPercentage }) => progressPercentage}%;
  height: 100%;
  background-color: ${colors.blue50};
  border-radius: 8px;
  transition: width 0.5s ease;
`;
