import {
  APIResourcePermissionAttributes,
  ArdoqId,
  GroupResourcePermission,
  MetaModelComponentType,
  PermissionAccessLevel,
  PermissionGroup,
  PermissionOrgGroup,
  Zone,
  ZoneConfiguredComponentTypeField,
} from '@ardoq/api-types';
import { PermissionSelectValueType, ZonesViewModelState } from './types';
import { CurrentUserState } from 'streams/currentUser/currentUser$';
import { partition } from 'lodash';
import {
  createGroupRole,
  getGroupLabel,
} from '@ardoq/manage-resource-permissions';
import { GlobalFieldsByName } from 'streams/fields/globalFields$';
import {
  ALL_ORGANIZATION_MEMBERS_GROUP,
  ENTIRE_ORGANIZATION_GROUP,
} from './groups$/types';
import { zonePermissionsOperations } from './permissions$/zonePermissionsOperations';
import { fieldGroupNameOperations, ZonesById } from '@ardoq/subdivisions';
import { getCurrentLocale, localeIncludesLowercase } from '@ardoq/locale';
import { parseDate } from '@ardoq/date-time';
import {
  SubdivisionViewModelState,
  ValidationErrors,
} from 'subdivisionEditor/types';
import { IconName } from '@ardoq/icons';
import { ComponentTypeFieldsGroupSearchParams } from './zonesViewState$/types';
import { SearchByTypes } from './components/ComponentTypeFieldsGroupSearch';

const mapPermissionLevelsToSelectOptionValue = (
  permissionLevel: PermissionAccessLevel[]
) =>
  permissionLevel?.includes(PermissionAccessLevel.EDIT)
    ? PermissionSelectValueType.EDIT
    : PermissionSelectValueType.READ;

const mapSelectOptionValueToPermissionLevels = (
  selectValue: PermissionSelectValueType | null
) =>
  selectValue === PermissionSelectValueType.EDIT
    ? [PermissionAccessLevel.EDIT, PermissionAccessLevel.READ]
    : [PermissionAccessLevel.READ];

const generateGroupResourcePermissionItem = ({
  currentUser,
  permissions,
  label,
  displayName,
}: {
  currentUser: CurrentUserState;
  label: string;
  displayName: string;
  permissions: PermissionAccessLevel[];
}): GroupResourcePermission => {
  const groupRole = createGroupRole({
    first: currentUser.organization.label,
    second: label,
  });
  return {
    name: groupRole,
    displayName: getGroupLabel(groupRole) || displayName,
    permissions,
  };
};

const mapComponentTypeFieldsToGroupedSelectOptions = (
  fields: ZoneConfiguredComponentTypeField[],
  globalFieldsByName: GlobalFieldsByName
) => {
  const [selectedOptions, nonSelectedOptions] = partition(
    fields,
    field => field.enabled
  ).map(group =>
    group.map(field => ({
      label: globalFieldsByName[field.name]?.label || field.name,
      value: field.name,
    }))
  );
  return [...selectedOptions, ...nonSelectedOptions];
};

const getFieldsCounterText = (enabledFields: number, totalFields: number) => {
  if (enabledFields === 0) {
    return 'No accessible fields';
  } else if (enabledFields === totalFields) {
    return 'All fields';
  }
  return `${enabledFields} of ${totalFields} fields`;
};

const componentTypeMatchesSearchKey = (
  componentType: MetaModelComponentType,
  searchKey: string
) => {
  const locale = getCurrentLocale();

  if (!searchKey) return true;

  return localeIncludesLowercase(componentType.name, searchKey, locale);
};

const fieldMatchesSearch = (
  item: string,
  { searchBy, searchKey }: ComponentTypeFieldsGroupSearchParams
) => {
  if (!searchKey || searchBy !== SearchByTypes.FIELD) return true;

  return localeIncludesLowercase(item, searchKey, getCurrentLocale());
};

const isComponentTypeUsedByOneOfWorkspaces = (
  componentType: MetaModelComponentType,
  workspaces: ArdoqId[]
) => componentType.usedInWorkspaceIds.some(id => workspaces.includes(id));

const compareZonesByCreatedTime = (zoneA: Zone, zoneB: Zone) => {
  const zoneACreatedTime = parseDate(zoneA.created);
  const zoneBCreatedTime = parseDate(zoneB.created);
  return zoneACreatedTime.getTime() - zoneBCreatedTime.getTime();
};

const hasDataConfigured = (state: ZonesViewModelState) => {
  const zones = Object.values(state.zonesById);
  const hasPermissionConfigured = zones.some(
    zone => state.permissionsByZoneId?.[zone._id]?.groups.length > 0
  );
  const hasFieldAccessConfigured = zones.some(
    zone => zone.configuredComponentTypes.length > 0
  );
  return hasPermissionConfigured || hasFieldAccessConfigured;
};

const isAllOrganizationMembersGroup = (name: PermissionOrgGroup) => {
  return (
    zonePermissionsOperations.getPermissionGroupNameWithoutOrganization(
      name
    ) === ALL_ORGANIZATION_MEMBERS_GROUP.label
  );
};

const isEntireOrganizationMembersGroup = (name: PermissionOrgGroup) => {
  return (
    zonePermissionsOperations.getPermissionGroupNameWithoutOrganization(
      name
    ) === ENTIRE_ORGANIZATION_GROUP.label
  );
};

const isGroupPermissionConfiguredWithZone = (
  groupName: string,
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>,
  zone: Zone
) => {
  return permissionsByZoneId[zone._id]?.groups.some(
    group =>
      zonePermissionsOperations.getPermissionGroupNameWithoutOrganization(
        group.name
      ) ===
      zonePermissionsOperations.getPermissionGroupNameWithoutOrganization(
        groupName
      )
  );
};

const groupCanBeAssignedToZones = (
  group: PermissionGroup,
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>,
  zonesById: ZonesById
) => {
  return Object.values(zonesById).some(
    zone =>
      !isGroupPermissionConfiguredWithZone(
        group.label,
        permissionsByZoneId,
        zone
      )
  );
};

const mapUserGroupsToSelectOptions = (
  groups: PermissionGroup[],
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>,
  zonesById: ZonesById
) => {
  const mappedOptions = groups.map(group => {
    const isDisabled = !zonesOperations.groupCanBeAssignedToZones(
      group,
      permissionsByZoneId,
      zonesById
    );
    return {
      value: group.label,
      label: group.name,
      iconName: zonesOperations.getGroupIcon(group.label),
      isDisabled,
      description: isDisabled
        ? 'Already assigned to all available field groups'
        : undefined,
    };
  });

  const [specialOptions, regularOptions] = partition(
    mappedOptions,
    option =>
      zonePermissionsOperations.getPermissionGroupNameWithoutOrganization(
        option.value
      ) === ENTIRE_ORGANIZATION_GROUP.label
  );

  return [{ options: specialOptions }, { options: regularOptions }];
};

const getAGroupPermissionNotIncludesThisGroup = (
  groupName: string,
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>
) => {
  return Object.values(permissionsByZoneId).find(
    permission =>
      !permission.groups.some(
        group =>
          zonePermissionsOperations.getPermissionGroupNameWithoutOrganization(
            group.name
          ) === groupName
      )
  );
};

const canDeleteZone = (zone: Zone, zonesById: ZonesById) => {
  const sortedZones = Object.values(zonesById).sort((a, b) =>
    compareZonesByCreatedTime(a, b)
  );
  return zone._id !== sortedZones[0]?._id;
};

const zoneValidation = (zone: Zone) => {
  let errors: ValidationErrors = {};
  const nameIsValid = fieldGroupNameOperations.isValid(zone.name);
  if (!nameIsValid) {
    errors = {
      ...errors,
      zoneName: `Name must be more than 1 character and less than ${fieldGroupNameOperations.ALLOWED_MAX_LENGTH} characters.`,
    };
  }

  return errors;
};

const stepValidation = (args: SubdivisionViewModelState) => {
  let errors: ValidationErrors = {};
  Object.values(args.zonesState.zonesById).forEach(zone => {
    errors = {
      ...errors,
      ...zoneValidation(zone),
    };
  });
  return errors;
};

const getGroupIcon = (groupName: string) => {
  return zonePermissionsOperations.getPermissionGroupNameWithoutOrganization(
    groupName
  ) === ENTIRE_ORGANIZATION_GROUP.label
    ? IconName.ORGANIZATION
    : IconName.PEOPLE;
};

export const zonesOperations = {
  mapPermissionLevelsToSelectOptionValue,
  mapSelectOptionValueToPermissionLevels,
  generateGroupResourcePermissionItem,
  mapComponentTypeFieldsToGroupedSelectOptions,
  getFieldsCounterText,
  componentTypeMatchesSearchKey,
  fieldMatchesSearch,
  isComponentTypeUsedByOneOfWorkspaces,
  compareZonesByCreatedTime,
  hasDataConfigured,
  isAllOrganizationMembersGroup,
  isEntireOrganizationMembersGroup,
  isGroupPermissionConfiguredWithZone,
  groupCanBeAssignedToZones,
  mapUserGroupsToSelectOptions,
  getAGroupPermissionNotIncludesThisGroup,
  canDeleteZone,
  zoneValidation,
  stepValidation,
  getGroupIcon,
};
