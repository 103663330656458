import { Component } from 'react';
import styled from 'styled-components';
import { PrimaryButton, GhostButton } from '@ardoq/button';
import { Link } from '@ardoq/typography';
import { LoadingWrapper } from '@ardoq/status-ui';
import { LabeledValue } from 'aqTypes';
import {
  ArdoqId,
  CopyBundleBody,
  PermissionAccessLevel,
  TargetOrgMembersPermissions,
} from '@ardoq/api-types';
import { Description, ErrorBox, SuccessBox } from '../atoms';
import { getOrganizationOptions } from '../utils';
import { getMissingDependencyErrors } from '../validationUtils';
import { trackCopyGlobalAttachmentsOption } from '../tracking';
import { Checkbox } from '@ardoq/forms';
import {
  getArdoqErrorMessage,
  isArdoqError,
  openUrlInNewTab,
} from '@ardoq/common-helpers';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { Select } from '@ardoq/select';
import { bundleApi } from '@ardoq/api';
import { nextBundleCopyResult } from './async';
import { Features, hasFeature } from '@ardoq/features';
import { websocket$ } from 'sync/websocket$';

type CopyBundleProps = {
  goBack: () => void;
  bundleId?: ArdoqId;
  bundleName: string;
};
type CopyBundleState = {
  errorMsgs: string[];
  targetOrgLabel?: string;
  targetOrgName?: string;
  targetOrgMembersPermissions: TargetOrgMembersPermissions;
  copySuccessful: boolean;
  isCopying: boolean;
  targetOrgOptions: LabeledValue<string>[];
  copyGlobalAttachments: boolean;
  isAsync: boolean;
};

const CopyBundleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  max-width: 800px;
`;
const CopyBundleDropdownContainer = styled.div`
  flex-grow: 1;
  margin-right: 20px;
`;
const StatusContainer = styled.div`
  margin-top: 25px;
`;

const SizedPrimaryButton = styled(PrimaryButton)`
  width: min-content;
`;

type OrgPermissionOption = LabeledValue<TargetOrgMembersPermissions>;
const targetOrgMembersPermissionsOptions: OrgPermissionOption[] = [
  { label: 'Read', value: PermissionAccessLevel.READ },
  { label: 'Edit', value: PermissionAccessLevel.EDIT },
];

class CopyBundle extends Component<CopyBundleProps, CopyBundleState> {
  state: CopyBundleState = {
    errorMsgs: [],
    targetOrgLabel: undefined,
    targetOrgName: undefined,
    targetOrgMembersPermissions: PermissionAccessLevel.EDIT,
    copySuccessful: false,
    isCopying: false,
    targetOrgOptions: [],
    copyGlobalAttachments: true,
    isAsync: false,
  };

  clearAlertsAndSetState = (partialState: Partial<CopyBundleState>) =>
    this.setState({
      errorMsgs: [],
      copySuccessful: false,
      ...partialState,
    } as CopyBundleState);

  setTargetOrgLabel = (option: LabeledValue<string> | null) => {
    if (!option) return;
    this.clearAlertsAndSetState({
      targetOrgLabel: option.value,
      targetOrgName: option.label,
    });
  };

  setMemberPermissions = (option: OrgPermissionOption | null) => {
    if (!option) return;
    this.clearAlertsAndSetState({ targetOrgMembersPermissions: option.value });
  };

  validateBundle = async (): Promise<boolean> => {
    const { targetOrgLabel } = this.state;
    if (!targetOrgLabel) return false;
    if (!this.props.bundleId) return false;

    const validateResponse = await bundleApi.validate(this.props.bundleId);
    if (isArdoqError(validateResponse)) {
      const errorMessage = getArdoqErrorMessage(validateResponse);
      this.setState({ errorMsgs: [errorMessage] });
      return false;
    }
    const validateErrors = getMissingDependencyErrors(
      validateResponse.missingDependencyIds
    );
    if (validateErrors.length) {
      const humanReadableError =
        "The errors below indicate that you've added something (e.g. a Presentation) that " +
        'requires other entities (like Workspaces) that are not included in the bundle';
      this.setState({ errorMsgs: [humanReadableError, ...validateErrors] });
      return false;
    }
    return true;
  };

  initiateBundleCopying = async () => {
    this.setState({ copySuccessful: false, isCopying: true });
    const {
      targetOrgLabel,
      targetOrgMembersPermissions,
      copyGlobalAttachments,
    } = this.state;
    const validateSuccessful = Boolean(await this.validateBundle());
    if (!(targetOrgLabel && validateSuccessful)) {
      this.setState({ isCopying: false });
      return;
    }
    if (!this.props.bundleId) {
      return false;
    }

    trackCopyGlobalAttachmentsOption(copyGlobalAttachments);

    const bundleId = this.props.bundleId;
    const copyRequest: CopyBundleBody = {
      targetOrg: targetOrgLabel,
      dryRun: false,
      branch: false,
      copyGlobalAttachments,
      ...(targetOrgMembersPermissions && { targetOrgMembersPermissions }),
    };

    const result = this.state.isAsync
      ? await bundleApi.copyAsync(bundleId, copyRequest)
      : await bundleApi.copy(bundleId, copyRequest);
    if (isArdoqError(result)) {
      this.setState({
        errorMsgs: [getArdoqErrorMessage(result)],
        isCopying: false,
      });
      return;
    }
    if (this.state.isAsync) {
      const asyncResult = await nextBundleCopyResult(websocket$);
      this.setState({
        copySuccessful: asyncResult.successful,
        isCopying: false,
        errorMsgs: asyncResult.errorMessage ? [asyncResult.errorMessage] : [],
      });
    } else {
      this.setState({ copySuccessful: true, isCopying: false });
    }
  };

  componentDidMount() {
    this.setState({ targetOrgOptions: getOrganizationOptions() });
  }

  render() {
    const {
      errorMsgs,
      targetOrgLabel,
      copySuccessful,
      isCopying,
      targetOrgMembersPermissions,
      targetOrgOptions,
      targetOrgName,
      copyGlobalAttachments,
      isAsync,
    } = this.state;
    const { bundleName, goBack } = this.props;
    const targetOrgPlaceholder = targetOrgOptions.length
      ? 'Select a target organization'
      : 'No eligible organizations found';

    return (
      <CopyBundleContainer>
        <GhostButton onClick={goBack}>⬅ Overview</GhostButton>
        <p style={{ marginTop: 16, marginBottom: 32 }}>
          You are copying the bundle <b>{bundleName}</b>.
        </p>
        <p>{`Select a target organization from the dropdown below. The entire contents of the bundle will be copied into ${
          targetOrgName ? targetOrgName : 'that organization'
        }, possibly merging with existing workspaces.`}</p>
        <LoadingWrapper
          loading={isCopying}
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}
        >
          <div style={{ display: 'flex' }}>
            <CopyBundleDropdownContainer style={{ minWidth: '50%' }}>
              <Select
                label="Target organization"
                value={this.state.targetOrgLabel}
                placeholder={targetOrgPlaceholder}
                options={targetOrgOptions}
                isDisabled={!targetOrgOptions.length || isCopying}
                onChange={this.setTargetOrgLabel}
              />
              <Description>
                You must be an admin in the target organization in order to copy
                a bundle to it.
              </Description>
            </CopyBundleDropdownContainer>
            <CopyBundleDropdownContainer>
              <Select
                label="Target org minimum permissions"
                value={targetOrgMembersPermissions}
                options={targetOrgMembersPermissionsOptions}
                isDisabled={isCopying}
                onChange={this.setMemberPermissions}
              />
              <Description>
                Set the minimum permissions for copied entities in target org.
                This will give all users in the org at least this access level
                to the copied entities. You as the copier will always have full
                edit rights to these entities.
              </Description>
            </CopyBundleDropdownContainer>
          </div>
          <CopyBundleDropdownContainer style={{ maxWidth: '50%' }}>
            <Checkbox
              name="copGlobalAssets"
              isChecked={copyGlobalAttachments}
              onChange={() =>
                this.setState({
                  copyGlobalAttachments: !copyGlobalAttachments,
                })
              }
            >
              Copy global document archive content
            </Checkbox>
            <p style={{ margin: '10px 0px 10px 30px', fontStyle: 'italic' }}>
              The global document archive contains documents that are accessible
              by anyone in the organization.
            </p>
            <Link
              style={{ marginLeft: 30, fontSize: '14px' }}
              onClick={() =>
                openUrlInNewTab(KnowledgeBaseLink.DOCUMENT_ARCHIVE)
              }
            >
              Learn more about the document archive
            </Link>
          </CopyBundleDropdownContainer>
          {hasFeature(Features.BUNDLE_COPY_ASYNC) ? (
            <CopyBundleDropdownContainer
              style={{ maxWidth: '50%', paddingTop: '20px' }}
            >
              <Checkbox
                name="async"
                isChecked={isAsync}
                onChange={() =>
                  this.setState({
                    isAsync: !isAsync,
                  })
                }
              >
                Permit longer running copy
              </Checkbox>
              <p style={{ margin: '10px 0px 10px 30px', fontStyle: 'italic' }}>
                To be used if you have a lot of data to copy to the target
                organization, and you are noticing that regular copy takes a
                long time before displaying a 504 error.
              </p>
            </CopyBundleDropdownContainer>
          ) : null}
        </LoadingWrapper>
        <SizedPrimaryButton
          isDisabled={Boolean(!targetOrgLabel || errorMsgs.length || isCopying)}
          onClick={this.initiateBundleCopying}
        >
          {isCopying ? 'Copying...' : 'Copy'}
        </SizedPrimaryButton>
        {Boolean(errorMsgs.length) && (
          <StatusContainer>
            <ErrorBox errorMsgs={errorMsgs} />
          </StatusContainer>
        )}
        {Boolean(copySuccessful) && (
          <StatusContainer>
            <SuccessBox>
              Successfully copied bundle &quot;{bundleName}&quot;.
            </SuccessBox>
            <a
              href={`/api/switchOrg?org=${targetOrgLabel}`}
            >{`Go to organization ${targetOrgName}`}</a>
          </StatusContainer>
        )}
      </CopyBundleContainer>
    );
  }
}

export default CopyBundle;
