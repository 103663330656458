import { reducer } from '@ardoq/rxbeach';
import {
  notifyFetchingTagsSucceeded,
  notifyFetchingTagsFailed,
  fetchTags,
} from './actions';
import { toByIdDictionary } from '@ardoq/common-helpers';
import { APITagAttributes } from '@ardoq/api-types';
import { TagsStreamShape } from './types';

export const defaultState: TagsStreamShape = {
  fetchingStatus: 'idle',
  tagsById: {},
  tags: [],
};

const handleTagsIsFetching = (state: TagsStreamShape): TagsStreamShape => {
  return {
    ...state,
    fetchingStatus: 'loading',
  };
};

const handleTagsFetchSuccess = (
  { ...state }: TagsStreamShape,
  tags: APITagAttributes[]
): TagsStreamShape => {
  return {
    ...state,
    fetchingStatus: 'success',
    tags,
    tagsById: toByIdDictionary(tags),
  };
};
const handleTagsFetchError = (state: TagsStreamShape): TagsStreamShape => {
  return {
    ...state,
    fetchingStatus: 'error',
  };
};

export const reducers = [
  reducer(fetchTags, handleTagsIsFetching),
  reducer(notifyFetchingTagsSucceeded, handleTagsFetchSuccess),
  reducer(notifyFetchingTagsFailed, handleTagsFetchError),
];
