import Node from './node';
import { HEIGHT_WORKSPACE_ITEM, NodeModelTypes } from '../utils/consts';
import { NodeModel } from './types';
import type Tree from './tree';
import ComponentNode from './ComponentNode';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

class WorkspaceNode extends Node {
  private _id: string;
  private _children: NodeModel[] = [];

  constructor(tree: Tree, id: string, parent: NodeModel | null = null) {
    super(tree, parent);
    this._id = id;
    this.updateChildren();
  }

  override get id() {
    return this._id;
  }

  override get hasWriteAccess() {
    return this.tree.navigatorViewInterface.hasWorkspaceWriteAccess(this.id);
  }

  override get type() {
    return NodeModelTypes.WORKSPACE;
  }

  override get showIsActive(): boolean {
    if (
      !(this.tree && this.tree.selectedContext) ||
      this.tree.selectedContext instanceof ComponentNode
    ) {
      return false;
    }

    return this.tree.activeWorkspace === this;
  }

  override get isContextNode() {
    return activeScenarioOperations.isInScenarioMode(
      this.tree.activeScenarioState
    );
  }

  override isFlexibleModel() {
    return (
      this.tree?.navigatorViewInterface.isFlexibleWorkspace(this.id) ?? false
    );
  }

  getChildren() {
    return this._children;
  }

  override getItemHeight() {
    return HEIGHT_WORKSPACE_ITEM;
  }

  hasChildren() {
    return !this.tree.navigatorViewInterface.isWorkspaceEmpty(this.id);
  }

  override getWorkspaceId() {
    return this.id;
  }

  override getOrder() {
    return 0;
  }

  override getName() {
    return this.tree.navigatorViewInterface.getWorkspaceName(this.id) ?? '';
  }

  updateChildren() {
    const tree = this.tree;
    const children = tree.navigatorViewInterface
      .getWorkspaceRootComponents(this.id)
      .map(componentId => tree.getOrCreateNode(tree, componentId, this));

    this._children = children;
  }

  override isIncludedInContextByFilter() {
    return this.tree ? this.tree.isIncludedInFilterTerm(this.getName()) : true;
  }
}

export default WorkspaceNode;
