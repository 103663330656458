import { dispatchAction } from '@ardoq/rxbeach';
import {
  changeSearchKey,
  createToken,
  deleteToken,
  regenerateToken,
  switchTab,
} from './actions';
import {
  API_TOKENS_EVENTS,
  trackAPITokensEvent,
} from './apiTokensTrackingUtils';
import { APIToken } from '@ardoq/api-types';
import { confirm, ModalSize } from '@ardoq/modal';
import { TokenTypes } from './types';

const createAPIToken = (tokenName: string) => {
  dispatchAction(createToken(tokenName));
  trackAPITokensEvent(API_TOKENS_EVENTS.CREATED_NEW_TOKEN);
};

const regenerateAPIToken = async (token: APIToken) => {
  trackAPITokensEvent(API_TOKENS_EVENTS.CLICKED_REGENERATE_TOKEN_MENU_ITEM);
  const confirmed = await confirm({
    title: `Regenerate ${token.description} token?`,
    text: 'If you continue the current token will be invalidated, and a new one will be generated. Make sure to copy the new token as it will only be shown once.',
    confirmButtonTitle: 'Regenerate token',
    cancelButtonTitle: 'Close',
    modalSize: ModalSize.S,
  });
  if (confirmed) {
    trackAPITokensEvent(API_TOKENS_EVENTS.REGENERATED_TOKEN);
    dispatchAction(
      regenerateToken({
        ...token,
      })
    );
  }
};

const deleteAPIToken = async (token: APIToken) => {
  trackAPITokensEvent(API_TOKENS_EVENTS.CLICKED_DELETE_TOKEN_MENU_ITEM);
  const confirmed = await confirm({
    title: `Delete ${token.description} permanently?`,
    text: 'Once deleted, it cannot be recovered, and any integrations using this token will stop working.',
    confirmButtonTitle: `Delete ${token.description}`,
    cancelButtonTitle: 'Keep',
    isConfirmButtonDanger: true,
    modalSize: ModalSize.S,
  });
  if (confirmed) {
    trackAPITokensEvent(API_TOKENS_EVENTS.DELETED_TOKEN);
    dispatchAction(deleteToken(token));
  }
};

const updateSearchKey = (searchKey: string) => {
  dispatchAction(changeSearchKey(searchKey));
};

const switchTokensTab = (tab: TokenTypes) => {
  dispatchAction(switchTab(tab));
};

export const tokenViewCommands = {
  createAPIToken,
  regenerateAPIToken,
  deleteAPIToken,
  updateSearchKey,
  switchTokensTab,
};

export type TokenViewCommands = typeof tokenViewCommands;
