import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { of, tap } from 'rxjs';
import { SchedulingPolicy } from '@ardoq/api-types';
import {
  resetScheduleState,
  setAlreadyExistingSchedule,
  setScheduleFormValues,
  setUpsertedSchedule,
} from 'integrations/common/streams/schedule/actions';
import {
  createScheduleSuccess,
  scheduleAlreadyExists,
  updateScheduleSuccess,
} from 'integrations/common/streams/schedules/actions';
import { startTransfer } from 'integrations/common/streams/transferState/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { getTransferConfigsStream } from '../transferConfigs/transferConfigs$';
import { getCurrentTransferConfig } from '../transferConfigs/utils';
import { getActiveIntegrationStream } from '../activeIntegrations/activeIntegrations$';
import { getScheduleStream } from './schedule$';
import { getSchedulesStream } from '../schedules/getSchedulesStream';

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  tap(integrationId => {
    dispatchAction(resetScheduleState({ integrationId }));
  })
);

const handleCreateSchedule = routine(
  ofType(createScheduleSuccess),
  extractPayload(),
  tap(({ integrationId, schedule }) => {
    dispatchAction(
      setUpsertedSchedule({ scheduleStage: 'scheduled', integrationId })
    );
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'ACTIVATED_SCHEDULE',
        metadata: {
          interval: schedule.schedulingPolicy.type,
          day:
            schedule.schedulingPolicy.type === 'weekly'
              ? schedule.schedulingPolicy.weekDay
              : null,
        },
      })
    );
  })
);

const handleUpdateSchedule = routine(
  ofType(updateScheduleSuccess),
  extractPayload(),
  tap(({ integrationId }) =>
    dispatchAction(
      setUpsertedSchedule({ scheduleStage: 'scheduled', integrationId })
    )
  )
);

const handleScheduleAlreadyExists = routine(
  ofType(scheduleAlreadyExists),
  extractPayload(),
  tap(({ integrationId }) => {
    dispatchAction(
      setAlreadyExistingSchedule({
        isExists: true,
        integrationId,
      })
    );
  })
);

const handleScheduleStateOnTransfer = routine(
  ofType(startTransfer),
  extractPayload(),
  switchMap(({ integrationId }) =>
    of(integrationId).pipe(
      withLatestFrom(
        getTransferConfigsStream(integrationId),
        getActiveIntegrationStream(integrationId),
        getScheduleStream(integrationId),
        getSchedulesStream(integrationId)
      )
    )
  ),
  tap(
    ([
      integrationId,
      { configs },
      activeIntegration,
      scheduleState,
      schedules,
      ,
    ]) => {
      const currentTransferConfig = getCurrentTransferConfig(
        configs,
        activeIntegration
      );

      const loadedSchedule =
        scheduleState.loadedScheduleId &&
        schedules.schedules.find(s => s._id === scheduleState.loadedScheduleId);

      const scheduleFormValues = loadedSchedule
        ? {
            name: loadedSchedule.jobOptions.name,
            policy: loadedSchedule.schedulingPolicy,
            managesWorkspace:
              'managesWorkspace' in loadedSchedule.jobOptions
                ? loadedSchedule.jobOptions.managesWorkspace
                : false,
            errorMessage: '',
          }
        : {
            name:
              currentTransferConfig.name || activeIntegration.integrationName,
            policy: { type: 'nightly' } satisfies SchedulingPolicy,
            managesWorkspace: false,
            errorMessage: '',
          };

      dispatchAction(
        setScheduleFormValues({
          scheduleFormValues,
          integrationId,
        })
      );
      dispatchAction(
        setAlreadyExistingSchedule({
          isExists: false,
          integrationId,
        })
      );
    }
  )
);

export default [
  handleResetIntegration,
  handleUpdateSchedule,
  handleCreateSchedule,
  handleScheduleAlreadyExists,
  handleScheduleStateOnTransfer,
];
