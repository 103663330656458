import { Box, FlexBox, Stack } from '@ardoq/layout';
import PathCollapsingRuleItem from './PathCollapsingRuleItem';
import { SmallGhostButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { TraversalBuilderViewState } from '../types';
import { CollapsePathsCommands } from './collapsePathsCommands';
import EditPathCollapsingRule from './EditPathCollapsingRule';

type CollapsePathsItemsProps = {
  pathCollapsingViewState: TraversalBuilderViewState['pathCollapsingViewState'];
  commands: CollapsePathsCommands;
};

const CollapsePathsItems = ({
  commands,
  pathCollapsingViewState: { rules, currentEditableRule },
}: CollapsePathsItemsProps) => {
  return (
    <Box padding="medium" className="wrapper" width="full">
      <Stack as="ul" align="stretch" gap="medium">
        {rules.map(rule => {
          if (currentEditableRule?.currentRuleHash === rule.hash) {
            return (
              <EditPathCollapsingRule
                key={rule.hash}
                rule={currentEditableRule}
                commands={commands}
              />
            );
          }
          return (
            <PathCollapsingRuleItem
              key={rule.hash}
              rule={rule}
              commands={commands}
              isEditingAnotherRule={Boolean(currentEditableRule)}
            />
          );
        })}
      </Stack>
      {!currentEditableRule && (
        <FlexBox justify="center">
          <SmallGhostButton onClick={commands.createPathCollapsingRule}>
            <Icon iconName={IconName.ADD_CIRCLE} /> Collapse a path
          </SmallGhostButton>
        </FlexBox>
      )}
    </Box>
  );
};

export default CollapsePathsItems;
