import { reducedStream, reducer } from '@ardoq/rxbeach';
import { serviceAccountsOperations } from './serviceAccountsOperations';
import {
  accountDeleted,
  getServiceAccounts,
  receivedAPIServiceAccounts,
  serviceAccountCreated,
  setError,
  tokenRegenerated,
  updateSearchKey,
} from './actions';
import { APIServiceAccount } from '@ardoq/api-types';

export type ServiceAccountState = {
  isLoading: boolean;
  serviceAccounts: APIServiceAccount[];
  errorMessage: string | null;
  searchKey: string;
};

const defaultState: ServiceAccountState = {
  isLoading: false,
  serviceAccounts: [],
  errorMessage: null,
  searchKey: '',
};

export const serviceAccounts$ = reducedStream(
  'serviceAccounts$',
  defaultState,
  [
    reducer(getServiceAccounts, serviceAccountsOperations.setIsLoading),
    reducer(
      receivedAPIServiceAccounts,
      serviceAccountsOperations.setServiceAccounts
    ),
    reducer(serviceAccountCreated, serviceAccountsOperations.addServiceAccount),
    reducer(
      tokenRegenerated,
      serviceAccountsOperations.replaceServiceAccountToken
    ),
    reducer(accountDeleted, serviceAccountsOperations.deleteServiceAccount),
    reducer(setError, serviceAccountsOperations.setErrorMessage),
    reducer(updateSearchKey, serviceAccountsOperations.updateSearchKey),
  ]
);
