import { reducedStream, reducer } from '@ardoq/rxbeach';
import {
  userTokenCreated,
  receivedAPIUserTokens,
  userTokenRegenerated,
  userTokenDeleted,
  setError,
  receivedAPIOrgTokens,
  getUserTokens,
  getOrgTokens,
  changeSearchKey,
  switchTab,
} from './actions';
import { tokenTabViewModelOperations } from './tokenTabViewModelOperations';
import { TokenTabViewModel, TokenTypes } from './types';

const defaultState: TokenTabViewModel = {
  isLoading: false,
  activeTokensTab: TokenTypes.MY_TOKENS,
  tokens: [],
  searchKey: '',
  errorMessage: '',
};

export const tokenTabViewModel$ = reducedStream(
  'tokenTabViewModel$',
  defaultState,
  [
    reducer(changeSearchKey, tokenTabViewModelOperations.updateSearchKey),
    reducer(switchTab, tokenTabViewModelOperations.switchTab),
    reducer(getUserTokens, tokenTabViewModelOperations.setIsLoading),
    reducer(getOrgTokens, tokenTabViewModelOperations.setIsLoading),
    reducer(userTokenCreated, tokenTabViewModelOperations.addNewUserToken),
    reducer(receivedAPIUserTokens, tokenTabViewModelOperations.setTokens),
    reducer(receivedAPIOrgTokens, tokenTabViewModelOperations.setTokens),
    reducer(userTokenRegenerated, tokenTabViewModelOperations.replaceUserToken),
    reducer(userTokenDeleted, tokenTabViewModelOperations.removeUserToken),
    reducer(setError, tokenTabViewModelOperations.setErrorMessage),
  ]
);
