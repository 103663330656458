import {
  dispatchAction,
  reducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import {
  sendMessage,
  sendMessageFailed,
  sendMessageDone,
  setInput,
  initializeNewChatFailed,
  initializeNewChatSuccess,
  closeChatWindow,
  minimizeChatWindow,
  initializeNewChat,
  expandChatWindow,
  saveSubmittedFeedback,
  dismissBanner,
  setAutoScrollToBottom,
  setCurrentScrollTop,
  dequeueMessages,
  chatTimedOut,
} from './actions';
import { bestPracticeAssistantOperations } from './bestPracticeAssistantOperations';
import { ChatMessage, ChatType, Citation, Feedback } from './types';
import { websocket$ } from 'sync/websocket$';
import { filter } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  isChatMessageContentEvent,
  isChatMessageDoneEvent,
  isChatMessageErrorEvent,
} from './isChatMessage';

export type CurrentChatMessage = {
  clientRequestId: string;
  messageQueue: string[];
  isDone: boolean;
  lastMessageRead: Date | null;
  citations: Map<string, Citation>;
};

export type BestPracticeAssistantState = {
  mode: 'closed' | 'open' | 'minimized';
  threadId: string | null;
  startChatIsLoading: boolean;
  startChatError: string | null;
  sendMessageIsLoading: boolean;
  sendMessageError: string | null;
  currentChatMessage: null | CurrentChatMessage;
  input: string;
  messages: ChatMessage[];
  submittedFeedbacksByMessageIndex: Record<string, Feedback>;
  showBanner: boolean;
  autoScrollToBottom: boolean;
  currentScrollTop: number;
  hasChatTimedOut: boolean;
  type: ChatType;
};

const defaultState: BestPracticeAssistantState = {
  mode: 'closed',
  threadId: null,
  startChatIsLoading: false,
  startChatError: null,
  sendMessageIsLoading: false,
  sendMessageError: null,
  currentChatMessage: null,
  input: '',
  messages: [],
  submittedFeedbacksByMessageIndex: {},
  showBanner: true,
  autoScrollToBottom: true,
  currentScrollTop: 0,
  hasChatTimedOut: false,
  type: 'default',
};

const websocketChatMessageContent$ = websocket$.pipe(
  filter(isChatMessageContentEvent),
  tap(() => {
    setTimeout(() => dispatchAction(dequeueMessages()));
  })
);

const websocketChatMessageDone$ = websocket$.pipe(
  filter(isChatMessageDoneEvent)
);

const websocketChatMessageError$ = websocket$.pipe(
  filter(isChatMessageErrorEvent)
);

export const bestPracticeAssistant$ = reducedStream(
  'bestPracticeAssistant',
  defaultState,
  [
    reducer(
      expandChatWindow,
      bestPracticeAssistantOperations.handleExpandChatWindow
    ),
    reducer(
      closeChatWindow,
      bestPracticeAssistantOperations.handleCloseChatWindow
    ),
    reducer(
      minimizeChatWindow,
      bestPracticeAssistantOperations.handleMinimizeChatWindow
    ),
    reducer(
      initializeNewChat,
      bestPracticeAssistantOperations.handleInitializeNewChat
    ),
    reducer(
      initializeNewChatSuccess,
      bestPracticeAssistantOperations.handleStartChatSuccess
    ),
    reducer(
      initializeNewChatFailed,
      bestPracticeAssistantOperations.handleStartChatFailed
    ),
    reducer(sendMessage, bestPracticeAssistantOperations.handleSendMessage),
    reducer(
      sendMessageDone,
      bestPracticeAssistantOperations.handleSendMessageDone
    ),
    reducer(
      sendMessageFailed,
      bestPracticeAssistantOperations.handleSendMessageFailed
    ),
    reducer(setInput, bestPracticeAssistantOperations.handleSetInput),
    reducer(
      saveSubmittedFeedback,
      bestPracticeAssistantOperations.saveSubmittedFeedbackReducer
    ),
    reducer(dismissBanner, bestPracticeAssistantOperations.handleDismissBanner),
    reducer(
      setAutoScrollToBottom,
      bestPracticeAssistantOperations.setAutoScrollToBottom
    ),
    reducer(
      setCurrentScrollTop,
      bestPracticeAssistantOperations.setCurrentScrollTop
    ),
    streamReducer(
      websocketChatMessageContent$,
      bestPracticeAssistantOperations.setMessageQueueFromWebsocket
    ),
    streamReducer(
      websocketChatMessageDone$,
      bestPracticeAssistantOperations.setIsCurrentChatMessageDone
    ),
    streamReducer(
      websocketChatMessageError$,
      bestPracticeAssistantOperations.setSendMessageError
    ),
    reducer(dequeueMessages, bestPracticeAssistantOperations.dequeMessages),
    reducer(chatTimedOut, bestPracticeAssistantOperations.setChatTimedOut),
  ]
);
