import { Paragraph } from '@ardoq/typography';
import { FlexBox } from '@ardoq/layout';

const AsNumberTransformationSection = () => (
  <FlexBox marginBottom="large">
    <Paragraph variant="text1Italic" color="textSubtle">
      No configuration needed
    </Paragraph>
  </FlexBox>
);

export default AsNumberTransformationSection;
