import { WarningNotification } from '@ardoq/status-ui';

type ViewInferenceWarningProps = {
  warning: string;
};

export const ViewInferenceWarning = ({
  warning,
}: ViewInferenceWarningProps) => (
  <WarningNotification>{warning}</WarningNotification>
);
