import {
  Field,
  FormSize,
  FormWrapper,
  Label,
  RadioGroup,
  RadioOption,
} from '@ardoq/forms';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  setAudienceStartingPage,
  setAudienceStartingPageViewpoint,
} from 'broadcasts/actions';
import {
  isGeneralSource,
  isMessageContent,
  isSurveyContent,
  isTargetedSource,
} from 'broadcasts/utils';
import ViewpointSelect from './ViewpointSelect';
import {
  APISurveyAttributes,
  ArdoqId,
  AudienceStartingPage,
  BroadcastAudience,
  BroadcastContent,
  BroadcastContentType,
  BroadcastSurveyContent,
  BroadcastValidation,
  PrivilegeLabel,
} from '@ardoq/api-types';
import {
  GeneralAudiencePopoverContent,
  TargetedMessageAudiencePopoverContent,
  TargetedSurveyAudiencePopoverContent,
} from './PopoverContent';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import surveys$, { SurveysById } from 'streams/surveys/surveys$';
import styled from 'styled-components';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { hasPrivilege } from '@ardoq/privileges';
import { Features, hasFeature } from '@ardoq/features';
import { Island } from '@ardoq/page-layout';
import { map } from 'rxjs';

const InfoIcon = styled(Icon).attrs({
  iconName: IconName.INFO,
  iconSize: IconSize.SMALL,
})`
  cursor: help;
  margin-left: 4px;
`;

const defaultSurveyOptions = [
  {
    label:
      'Link to searchable overview page of all relevant surveys (for sharing many surveys)',
    value: AudienceStartingPage.SURVEY_OVERVIEW,
  },
  {
    label:
      'List of direct links to each survey (for sharing short list of surveys)',
    value: AudienceStartingPage.SURVEY_COMPONENT,
  },
];

const getMyTasksOption = (hasDiscoverAccess: boolean) => ({
  label: "Link to the 'My Tasks' page in Discover",
  value: AudienceStartingPage.MY_TASKS,
  isDisabled: !hasDiscoverAccess,
  helperText: !hasDiscoverAccess
    ? "If you'd like to enable this option, please reach out to your customer success manager."
    : undefined,
});

const getDiscoverSurveyOption = (
  content: BroadcastSurveyContent,
  surveysById: SurveysById,
  isDiscoverDisabled: boolean
) => ({
  label: 'List of direct links to each survey with dynamic visuals in Discover',
  value: AudienceStartingPage.DISCOVER_SURVEY,
  isDisabled: !isDiscoverSurvey(content, surveysById),
  helperText: isDiscoverDisabled
    ? 'To utilize Discover in this broadcast, the chosen survey must be added to Discover. This can be done in the Discover tab of the Survey builder.'
    : undefined,
});

const defaultMessageOptions = [
  {
    label: 'Plain component list text',
    value: AudienceStartingPage.PLAIN,
  },
];

const discoverMessageOption = {
  label: 'List of direct links with dynamic visuals in Discover',
  value: AudienceStartingPage.DISCOVER_COMPONENT,
};

function getOptions(
  content: BroadcastContent,
  surveysById: SurveysById,
  isDiscoverDisabled: boolean,
  hasDiscoverAccess: boolean
): RadioOption[] {
  switch (content.contentType) {
    case BroadcastContentType.MESSAGE:
      return hasDiscoverAccess
        ? [...defaultMessageOptions, discoverMessageOption]
        : defaultMessageOptions;
    case BroadcastContentType.SURVEY:
      return hasDiscoverAccess
        ? [
            ...defaultSurveyOptions,
            getMyTasksOption(hasDiscoverAccess),
            getDiscoverSurveyOption(content, surveysById, isDiscoverDisabled),
          ]
        : [...defaultSurveyOptions, getMyTasksOption(hasDiscoverAccess)];
    case BroadcastContentType.REPORT:
      // : TODO report links
      return defaultMessageOptions;
  }
}

const isDiscoverSurvey = (
  content: BroadcastSurveyContent,
  surveysById: Record<ArdoqId, APISurveyAttributes>
) => {
  return surveysById[content.contentId ?? '']?.discoverEnabled;
};

function shouldShowViewpointSelect(
  content: BroadcastContent,
  surveysById: SurveysById,
  hasDiscoverAccess: boolean,
  audienceStartingPage?: AudienceStartingPage
) {
  if (hasFeature(Features.COMPONENT_LANDING_PAGE)) return false;
  return (
    hasDiscoverAccess &&
    // Message content
    ((audienceStartingPage === AudienceStartingPage.DISCOVER_COMPONENT &&
      isMessageContent(content) &&
      content.componentType) ||
      // Survey content
      (audienceStartingPage === AudienceStartingPage.DISCOVER_SURVEY &&
        isSurveyContent(content) &&
        isDiscoverSurvey(content, surveysById)))
  );
}

function getComponentTypeName(
  content: BroadcastContent,
  surveysById: Record<ArdoqId, APISurveyAttributes>
) {
  if (
    isMessageContent(content) &&
    content.workspaceId &&
    content.componentType
  ) {
    return workspaceInterface.getTypeById(
      content.workspaceId,
      content.componentType
    )?.name;
  } else if (!isMessageContent(content)) {
    return surveysById[content.contentId ?? '']?.componentTypeName;
  }
}

type ManageLandingPageFormProps = {
  content: BroadcastContent;
  audienceStartingPage?: AudienceStartingPage;
  audienceStartingPageViewpoint?: ArdoqId | null;
  audiences: BroadcastAudience[];
  surveysById: SurveysById;
} & BroadcastValidation;

const ManageLandingPageForm = ({
  content,
  audienceStartingPage,
  audienceStartingPageViewpoint,
  surveysById,
  audiences,
}: ManageLandingPageFormProps) => {
  const componentTypeName = getComponentTypeName(content, surveysById);
  const isDiscoverDisabled =
    isSurveyContent(content) && !isDiscoverSurvey(content, surveysById);
  const isOnlyTargetedAudience = audiences.every(audience =>
    isTargetedSource(audience.audienceType)
  );
  const isOnlyGeneralAudience = audiences.every(audience =>
    isGeneralSource(audience.audienceType)
  );

  const hasDiscoverAccess = hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER);

  return (
    <Island
      data-intercom-target="broadcast-sharing"
      title={`3. How the ${
        content.contentType === BroadcastContentType.REPORT
          ? 'report'
          : 'component list'
      } will be shared with your audience`}
    >
      <FormWrapper $gap="s32" $formSize={FormSize.AUTO}>
        {content.contentType === BroadcastContentType.REPORT ? (
          <RadioGroup
            value={audienceStartingPage}
            label="The audience will receive"
            onValueChange={value => {
              dispatchAction(
                setAudienceStartingPage(value as AudienceStartingPage)
              );
            }}
            defaultValue={AudienceStartingPage.REPORT_DISCOVER}
            options={[
              {
                label: 'Link to the report in Ardoq',
                value: AudienceStartingPage.REPORT_ARDOQ,
              },
              {
                label: 'Link to the report in Discover',
                value: AudienceStartingPage.REPORT_DISCOVER,
              },
            ]}
          />
        ) : (
          <>
            <Field>
              {content.contentType === BroadcastContentType.SURVEY ? (
                <Label isDisabled={isOnlyGeneralAudience}>
                  For your targeted audience
                  <WithPopover content={TargetedSurveyAudiencePopoverContent}>
                    <InfoIcon />
                  </WithPopover>
                </Label>
              ) : (
                <Label>
                  Format for list of components shared
                  <WithPopover content={TargetedMessageAudiencePopoverContent}>
                    <InfoIcon />
                  </WithPopover>
                </Label>
              )}
              <RadioGroup
                onValueChange={value => {
                  dispatchAction(
                    setAudienceStartingPage(value as AudienceStartingPage)
                  );
                }}
                isDisabled={
                  isOnlyGeneralAudience &&
                  content.contentType === BroadcastContentType.SURVEY
                }
                options={getOptions(
                  content,
                  surveysById,
                  isDiscoverDisabled,
                  hasDiscoverAccess
                )}
                value={audienceStartingPage}
              />
            </Field>
            {shouldShowViewpointSelect(
              content,
              surveysById,
              hasDiscoverAccess,
              audienceStartingPage
            ) &&
              componentTypeName && (
                <Field label="Select component viewpoint">
                  <ViewpointSelect
                    selectedViewpoint={audienceStartingPageViewpoint}
                    onChange={viewpoint =>
                      dispatchAction(
                        setAudienceStartingPageViewpoint(viewpoint)
                      )
                    }
                    componentTypeName={componentTypeName}
                  />
                </Field>
              )}
            {content.contentType === BroadcastContentType.SURVEY && (
              <Field>
                <Label isDisabled={isOnlyTargetedAudience}>
                  For your general audience
                  <WithPopover content={GeneralAudiencePopoverContent}>
                    <InfoIcon />
                  </WithPopover>
                </Label>
                <RadioGroup
                  isDisabled={isOnlyTargetedAudience}
                  value="default"
                  options={[
                    {
                      label:
                        'Link to searchable overview page of all relevant surveys (for sharing many surveys)',
                      value: 'default',
                    },
                  ]}
                />
              </Field>
            )}
          </>
        )}
      </FormWrapper>
    </Island>
  );
};

export default connect(
  ManageLandingPageForm,
  surveys$.pipe(map(({ byId }) => ({ surveysById: byId })))
);
