import { ArdoqId } from '@ardoq/api-types';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { tagInterface } from '@ardoq/tag-interface';
import { uniq } from 'lodash';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

export const collectRelatedComponents = () =>
  componentInterface.getAllPartial(['_id', 'name', 'type', 'component-key']);

export const collectRelatedReferences = () =>
  referenceInterface.getStubReferencesWithTypeName();

export const collectRelatedTags = () =>
  tagInterface.getAllPartial(['_id', 'name', 'rootWorkspace']);

export const collectComponentTypeNamesFromWorkspaces = (
  workspaceIds: ArdoqId[]
): string[] => {
  const componentTypeNames = workspaceIds.flatMap(workspaceId =>
    workspaceInterface.getComponentTypes(workspaceId).map(({ name }) => name)
  );
  return uniq(componentTypeNames);
};

export const collectReferenceTypeNamesFromWorkspaces = (
  workspaceIds: ArdoqId[]
): string[] => {
  const referenceTypeNames = workspaceIds.flatMap(workspaceId =>
    Object.values(workspaceInterface.getReferenceTypes(workspaceId) ?? {}).map(
      type => type.name
    )
  );
  return uniq(referenceTypeNames);
};
