import { persistentReducedStream } from '@ardoq/rxbeach';
import reducers, { defaultState } from './reducers';
import { SearchType } from '@ardoq/api-types';

const createGremlinResults$ = (namespace: SearchType) => {
  const gremlinResults$ = persistentReducedStream(
    `gremlinResults$-${namespace}`,
    defaultState,
    reducers,
    { namespace }
  );

  return gremlinResults$;
};

export const gremlinQueryResults$ = createGremlinResults$(SearchType.QUERY);
export const parameterQueryResults$ = createGremlinResults$(
  SearchType.PARAMETER_QUERY
);
export const metamodelQueryResults$ = createGremlinResults$(
  SearchType.METAMODEL_QUERY
);
export const dynamicFilterQueryResults$ = createGremlinResults$(
  SearchType.DYNAMIC_FILTER_QUERY
);
export const calculatedFieldQueryResults$ = createGremlinResults$(
  SearchType.CALCULATED_FIELD_QUERY
);
export const broadcastAudienceQuery$ = createGremlinResults$(
  SearchType.BROADCAST_AUDIENCE_QUERY
);
