import { combineLatest, map } from 'rxjs';
import {
  isAnyTraversalLoadedState,
  LoadedState,
  ViewIds,
} from '@ardoq/api-types';
import { loadedState$ } from 'loadedState/loadedState$';
import { isViewEligibleForNewAssets } from 'viewDeprecation/restrictedViews';
import activeView$ from './views/mainContent/activeView$';

const buildSaveAsViewpointButtonState = (
  loadedState: LoadedState[],
  currentViewId: ViewIds
) => {
  const hasSavableDataset = loadedState.some(isAnyTraversalLoadedState);
  const canSaveViewpoint = hasSavableDataset && loadedState.length === 1;

  const isViewConform = isViewEligibleForNewAssets(currentViewId);

  const disabledDescription = !isViewConform
    ? 'You cannot save this view as a viewpoint. Please try switching to a different view.'
    : canSaveViewpoint
      ? undefined
      : hasSavableDataset
        ? "You can't save as Viewpoint with more than 1 dataset opened. Please close some datasets first."
        : 'You can only save as Viewpoint when you open a dataset with references added.';

  return {
    isDisabled: !canSaveViewpoint || !isViewConform,
    disabledDescription,
  };
};

export default combineLatest([loadedState$, activeView$]).pipe(
  map(([loadedState, { mainViewId }]) =>
    buildSaveAsViewpointButtonState(loadedState, mainViewId)
  )
);
