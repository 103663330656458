import { LabelFormattingInfo } from '@ardoq/data-model';

export const getLabelFormatingRulesWithFieldsCountByType = (
  labelFormatting: LabelFormattingInfo[]
) => {
  const { referenceLabelKeysSet, componentLabelKeysSet } =
    labelFormatting.reduce(
      (acc, labelFormatting) => {
        const { fields, type, showFieldName, typeName } = labelFormatting;

        if (!fields.length) {
          return acc;
        }

        const currentSetName =
          type === 'component'
            ? 'componentLabelKeysSet'
            : 'referenceLabelKeysSet';

        for (const fieldName of labelFormatting.fields) {
          const key = `${type}-${typeName ?? 'allTypes'}-${showFieldName}-${fieldName}`;

          acc[currentSetName].add(key);
        }

        return acc;
      },
      { referenceLabelKeysSet: new Set(), componentLabelKeysSet: new Set() }
    );

  return {
    referenceLabelsCount: referenceLabelKeysSet.size,
    componentLabelsCount: componentLabelKeysSet.size,
  };
};
