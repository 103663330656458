import { APIViewpointAttributes } from '@ardoq/api-types';
import { isPresentationMode } from 'appConfig';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';

export const applyViewpointViewSettings = (
  traversal: APIViewpointAttributes | null
) => {
  const viewSettings = traversal?.viewSettings?.[traversal.viewName];

  if (!viewSettings) {
    return;
  }

  const isPersistent = !isPresentationMode();
  onViewSettingsUpdate(traversal.viewName, viewSettings, isPersistent);
};
