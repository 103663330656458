import { distinctUntilChanged, map, scan } from 'rxjs/operators';
import { isEqual, keyBy, pickBy } from 'lodash';
import workspaces$ from 'streams/workspaces/workspaces$';
import assetFolders$ from 'streams/assetFolders/assetFolders$';
import presentations$ from 'streams/presentations/presentations$';
import { enhanceWithMeta } from './utils';
import {
  APIBroadcastAttributes,
  APIDashboardAttributes,
  APIBookmarkAttributes,
  APISurveyAttributes,
  APIDiscoverViewpointAttributes,
  ArdoqId,
  AssetType,
  PersistedMetamodel,
  Meta,
  APIScenarioAttributes,
  AssetFolder,
  Report,
  Workspace,
  APIPresentationAssetAttributes,
  APIViewpointAttributes,
} from '@ardoq/api-types';
import currentUser$, {
  CurrentUserState,
  getFavorites,
  getIsOrgWriter,
} from 'streams/currentUser/currentUser$';
import { getIsOrgAdmin, toByIdDictionary } from '@ardoq/common-helpers';
import { derivedStream } from '@ardoq/rxbeach';
import surveys$, { SurveysById } from 'streams/surveys/surveys$';
import metamodels$ from 'streams/metamodels/metamodels$';
import reports$ from 'streams/reports/reports$';
import dashboards$ from 'streams/dashboards/dashboards$';
import scenarios$ from 'streams/scenarios/scenarios$';
import { Features, hasFeature } from '@ardoq/features';
import {
  Permissions,
  hasPermission,
} from 'streams/currentUserPermissions/permissionInterface';
import {
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';
import traversals$ from 'streams/traversals$';
import { surveyAccessControlInterface } from 'resourcePermissions/accessControlHelpers/surveys';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import viewpoints$ from '../../viewpoints/viewpoints$';
import { viewpointAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/viewpoints';
import { traversalAccessControlInterface } from 'resourcePermissions/accessControlHelpers/traversals';
import broadcast$ from '../../broadcasts/broadcast$';
import {
  getLatestSchedule,
  getIntervalInDays,
} from 'broadcasts/broadcastOverview/utils';
import { presentationAccessControlOperations } from '../../resourcePermissions/accessControlHelpers/presentations';
import { dashboardAccessControlInterface } from 'resourcePermissions/accessControlHelpers/dashboards';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { traversalStateOperations } from 'streams/traversals/traversalsStateOperations';
import bookmarks$ from 'streams/bookmarks/bookmarks$';

export type AssetWithMeta<T, E> = T & Meta<E>;

export type AssetsState = {
  assetFoldersById: {
    [id: string]: AssetWithMeta<AssetFolder, AssetType.FOLDER>;
  };
  workspaceAssetsById: {
    [id: string]: AssetWithMeta<Workspace, AssetType.WORKSPACE>;
  };
  presentationsById: {
    [id: string]: AssetWithMeta<
      APIPresentationAssetAttributes,
      AssetType.PRESENTATION
    >;
  };
  surveysById: {
    [id: string]: AssetWithMeta<APISurveyAttributes, AssetType.SURVEY>;
  };
  metamodelsById: {
    [id: string]: AssetWithMeta<PersistedMetamodel, AssetType.METAMODEL>;
  };
  scenariosById: {
    [id: string]: AssetWithMeta<APIScenarioAttributes, AssetType.SCENARIO>;
  };
  reportsById: {
    [id: string]: AssetWithMeta<Report, AssetType.REPORT>;
  };
  dashboardsById: {
    [id: string]: AssetWithMeta<APIDashboardAttributes, AssetType.DASHBOARD>;
  };
  traversalsById: {
    [id: string]: AssetWithMeta<APIViewpointAttributes, AssetType.TRAVERSAL>;
  };
  bookmarksById: {
    [id: string]: AssetWithMeta<APIBookmarkAttributes, AssetType.BOOKMARK>;
  };
  viewpointsById: {
    [id: string]: AssetWithMeta<
      APIDiscoverViewpointAttributes,
      AssetType.VIEWPOINT
    >;
  };
  broadcastsById: {
    [id: string]: AssetWithMeta<APIBroadcastAttributes, AssetType.BROADCAST>;
  };
  permissionContext: PermissionContext;
};
type InputStreamShape = [
  CurrentUserState,
  Record<ArdoqId, AssetFolder>,
  Record<ArdoqId, Workspace>,
  Record<ArdoqId, APIPresentationAssetAttributes>,
  SurveysById,
  Record<ArdoqId, PersistedMetamodel>,
  Record<ArdoqId, APIScenarioAttributes>,
  Record<ArdoqId, Report>,
  Record<ArdoqId, APIDashboardAttributes>,
  Record<ArdoqId, APIViewpointAttributes>,
  PermissionContext,
  Record<ArdoqId, APIBookmarkAttributes>,
  Record<ArdoqId, APIDiscoverViewpointAttributes>,
  Record<ArdoqId, APIBroadcastAttributes>,
];
export type AssetsStreamState = AssetsState & {
  previousInput: null | InputStreamShape;
  assetIdToFolderIdLookup: Record<string, ArdoqId>;
};
export const emptyState: AssetsStreamState = {
  assetFoldersById: {},
  workspaceAssetsById: {},
  presentationsById: {},
  surveysById: {},
  metamodelsById: {},
  scenariosById: {},
  reportsById: {},
  dashboardsById: {},
  traversalsById: {},
  previousInput: null,
  assetIdToFolderIdLookup: {},
  permissionContext: permissionsOperations.createEmptyPermissionContext(),
  bookmarksById: {},
  viewpointsById: {},
  broadcastsById: {},
};

const mapAssetIdsToFolderIds = (
  assetFoldersById: Record<string, AssetFolder>
) =>
  Object.values(assetFoldersById).reduce<Record<string, ArdoqId>>(
    (acc, { _id: folderId, content }) => {
      content.forEach(({ _id }) => {
        acc[_id] = folderId;
      });
      return acc;
    },
    {}
  );

type GetAssetByIdBaseArgs = {
  isFavorite: (id: string) => boolean;
  getFolderId: (assetId: string) => string | null;
};

type GetAssetFoldersByIdArgs = GetAssetByIdBaseArgs & {
  assetFoldersById: Record<string, AssetFolder>;
  currentUserIsOrgWriter: boolean;
};
const getAssetFoldersById = ({
  assetFoldersById,
  isFavorite,
  getFolderId,
  currentUserIsOrgWriter,
}: GetAssetFoldersByIdArgs) =>
  enhanceWithMeta<AssetFolder, AssetType.FOLDER>(
    assetFoldersById,
    AssetType.FOLDER,
    ({ _id }) => ({
      favorite: isFavorite(_id),
      folderId: getFolderId(_id) || null,
      permissions: {
        canUpdate: currentUserIsOrgWriter,
        canDelete: currentUserIsOrgWriter,
      },
    })
  );

type GetWorkspaceAssetsByIdArgs = GetAssetByIdBaseArgs & {
  workspacesById: Record<string, Workspace>;
  permissionContext: PermissionContext;
};
const getWorkspaceAssetsById = ({
  workspacesById,
  permissionContext,
  isFavorite,
  getFolderId,
}: GetWorkspaceAssetsByIdArgs) =>
  enhanceWithMeta<Workspace, AssetType.WORKSPACE>(
    workspacesById,
    AssetType.WORKSPACE,
    ({ _id }) => {
      const canEditWorkspace = workspaceAccessControlInterface.canEditWorkspace(
        permissionContext,
        _id,
        null
      );
      const canAdminWorkspace =
        workspaceAccessControlInterface.canAdminWorkspace(
          permissionContext,
          _id,
          null
        );
      return {
        favorite: isFavorite(_id),
        folderId: getFolderId(_id),
        permissions: {
          canCreate:
            workspaceAccessControlInterface.canCreateWorkspace(
              permissionContext
            ),
          canRead: workspaceAccessControlInterface.canAccessWorkspace(
            permissionContext,
            _id
          ),
          canCopy: workspaceAccessControlInterface.canCopyWorkspace(
            permissionContext,
            _id
          ),
          canUpdate: canEditWorkspace,
          canAdmin: canAdminWorkspace,
          canDelete: canAdminWorkspace,
          canManageProperties: canEditWorkspace,
          canManagePermissions: canEditWorkspace,
        },
      };
    }
  );

type GetPresentationsByIdArgs = GetAssetByIdBaseArgs & {
  presentationsById: Record<string, APIPresentationAssetAttributes>;
  permissionContext: PermissionContext;
};

const getPresentationsById = ({
  presentationsById,
  isFavorite,
  getFolderId,
  permissionContext,
}: GetPresentationsByIdArgs) =>
  enhanceWithMeta<APIPresentationAssetAttributes, AssetType.PRESENTATION>(
    presentationsById,
    AssetType.PRESENTATION,
    presentation => {
      const canEditPresentation =
        presentationAccessControlOperations.canEditPresentation(
          permissionContext,
          presentation
        );
      const canCopyPresentation =
        presentationAccessControlOperations.canCopyPresentation(
          permissionContext,
          presentation
        );
      return {
        favorite: isFavorite(presentation._id),
        folderId: getFolderId(presentation._id),
        permissions: {
          canUpdate: canEditPresentation,
          canDelete: canEditPresentation,
          canCopy: canCopyPresentation,
        },
      };
    }
  );

type GetBookmarksByIdArgs = GetAssetByIdBaseArgs & {
  bookmarksById: Record<string, APIBookmarkAttributes>;
  currentUserIsOrgWriter: boolean;
  currentUserIsOrgAdmin: boolean;
};
const getBookmarksById = ({
  bookmarksById,
  isFavorite,
  getFolderId,
  currentUserIsOrgWriter,
  currentUserIsOrgAdmin,
}: GetBookmarksByIdArgs) =>
  enhanceWithMeta<APIBookmarkAttributes, AssetType.BOOKMARK>(
    bookmarksById,
    AssetType.BOOKMARK,
    ({ _id }) => {
      return {
        favorite: isFavorite(_id),
        folderId: getFolderId(_id),
        permissions: {
          canManagePermissions: false, // bookmarks don't have this feature implemented
          canUpdate: true, // TODO AM bookmarks fix permissions
          canDelete: true, // TODO AM bookmarks fix permissions
          canCopy: currentUserIsOrgWriter || currentUserIsOrgAdmin, // TODO AM bookmarks fix permissions
        },
      };
    }
  );

type GetSurveysByIdArgs = GetAssetByIdBaseArgs & {
  surveysById: Record<string, APISurveyAttributes>;
  canAdminSurvey: (id: string) => boolean;
  canEditSurvey: (id: string) => boolean;
};
const getSurveysById = ({
  surveysById,
  isFavorite,
  getFolderId,
  canAdminSurvey,
  canEditSurvey,
}: GetSurveysByIdArgs) =>
  pickBy(
    enhanceWithMeta<APISurveyAttributes, AssetType.SURVEY>(
      surveysById,
      AssetType.SURVEY,
      ({ _id }) => ({
        favorite: isFavorite(_id),
        folderId: getFolderId(_id),
        permissions: {
          canManagePermissions: canAdminSurvey(_id),
          canUpdate: canEditSurvey(_id),
          canDelete: canEditSurvey(_id),
        },
      })
    ),
    survey => survey.published || survey.meta.permissions.canUpdate
  );

type GetBroadcastsByIdArgs = GetAssetByIdBaseArgs & {
  broadcastsById: Record<string, APIBroadcastAttributes>;
  currentUserIsOrgWriter: boolean;
  currentUserIsOrgAdmin: boolean;
};
const getBroadcastsById = ({
  broadcastsById,
  isFavorite,
  getFolderId,
  currentUserIsOrgWriter,
  currentUserIsOrgAdmin,
}: GetBroadcastsByIdArgs) =>
  enhanceWithMeta<APIBroadcastAttributes, AssetType.BROADCAST>(
    broadcastsById,
    AssetType.BROADCAST,
    broadcast => ({
      favorite: isFavorite(broadcast._id),
      folderId: getFolderId(broadcast._id),
      permissions: {
        canUpdate: currentUserIsOrgWriter,
        canDelete: currentUserIsOrgWriter,
        canCopy: currentUserIsOrgWriter || currentUserIsOrgAdmin,
      },
      latestSchedule: getLatestSchedule(broadcast),
      intervalInDays: getIntervalInDays(broadcast.scheduling),
    })
  );

type GetMetaModelsByIdArgs = GetAssetByIdBaseArgs & {
  metamodelsById: Record<string, PersistedMetamodel>;
  currentUserIsOrgWriter: boolean;
  currentUserIsOrgAdmin: boolean;
};
const getMetamodelsById = ({
  metamodelsById,
  isFavorite,
  getFolderId,
  currentUserIsOrgWriter,
  currentUserIsOrgAdmin,
}: GetMetaModelsByIdArgs) =>
  enhanceWithMeta<PersistedMetamodel, AssetType.METAMODEL>(
    metamodelsById,
    AssetType.METAMODEL,
    ({ _id, wholeOrg }) => ({
      favorite: isFavorite(_id),
      folderId: getFolderId(_id),
      permissions: {
        canUpdate: currentUserIsOrgWriter,
        canDelete: !wholeOrg && currentUserIsOrgWriter,
        canCopy: currentUserIsOrgWriter || currentUserIsOrgAdmin,
      },
    })
  );
type GetScenariosByIdArgs = GetAssetByIdBaseArgs & {
  scenariosById: Record<string, APIScenarioAttributes>;
  permissionContext: PermissionContext;
};
const getScenariosById = ({
  scenariosById,
  isFavorite,
  getFolderId,
  permissionContext,
}: GetScenariosByIdArgs) =>
  enhanceWithMeta<APIScenarioAttributes, AssetType.SCENARIO>(
    scenariosById,
    AssetType.SCENARIO,
    ({ _id }) => ({
      favorite: isFavorite(_id),
      folderId: getFolderId(_id),
      permissions: {
        canManagePermissions: scenarioAccessControlInterface.canAdminScenario(
          permissionContext,
          _id,
          null
        ),
        canUpdate: scenarioAccessControlInterface.canEditScenario(
          permissionContext,
          _id,
          null
        ),
        canDelete: scenarioAccessControlInterface.canAdminScenario(
          permissionContext,
          _id,
          null
        ),
      },
    })
  );

type GetReportsByIdArgs = GetAssetByIdBaseArgs & {
  reportsById: Record<string, Report>;
  currentUserIsOrgAdmin: boolean;
  currentUserIsOrgWriter: boolean;
};
const getReportsById = ({
  reportsById,
  isFavorite,
  getFolderId,
  currentUserIsOrgAdmin,
  currentUserIsOrgWriter,
}: GetReportsByIdArgs) =>
  enhanceWithMeta<Report, AssetType.REPORT>(
    reportsById,
    AssetType.REPORT,
    ({ _id }) => ({
      favorite: isFavorite(_id),
      folderId: getFolderId(_id),
      permissions: {
        canManagePermissions: hasPermission(Permissions.REPORTS_ADMIN, _id),
        canUpdate: hasPermission(Permissions.REPORTS_EDIT, _id),
        canDelete: hasPermission(Permissions.REPORTS_ADMIN, _id),
        canCopy:
          currentUserIsOrgAdmin ||
          (currentUserIsOrgWriter &&
            hasPermission(Permissions.REPORTS_READ, _id)),
      },
    })
  );

type GetDashboardsByIdArgs = GetAssetByIdBaseArgs & {
  dashboardsById: Record<string, APIDashboardAttributes>;
  permissionContext: PermissionContext;
};
const getDashboardsById = ({
  dashboardsById,
  isFavorite,
  getFolderId,
  permissionContext,
}: GetDashboardsByIdArgs) =>
  enhanceWithMeta<APIDashboardAttributes, AssetType.DASHBOARD>(
    dashboardsById,
    AssetType.DASHBOARD,
    ({ _id }) => ({
      favorite: isFavorite(_id),
      folderId: getFolderId(_id),
      permissions: {
        // TODO
        canManagePermissions: dashboardAccessControlInterface.canAdminDashboard(
          permissionContext,
          _id
        ),
        canUpdate: dashboardAccessControlInterface.canEditDashboard(
          permissionContext,
          _id
        ),
        canRead: dashboardAccessControlInterface.canReadDashboard(
          permissionContext,
          _id
        ),
        canDelete: dashboardAccessControlInterface.canAdminDashboard(
          permissionContext,
          _id
        ),
      },
    })
  );

type GetTraversalsByIdArgs = GetAssetByIdBaseArgs & {
  traversalsById: Record<string, APIViewpointAttributes>;
  permissionContext: PermissionContext;
};
const getTraversalsById = ({
  traversalsById,
  isFavorite,
  getFolderId,
  permissionContext,
}: GetTraversalsByIdArgs) =>
  enhanceWithMeta<APIViewpointAttributes, AssetType.TRAVERSAL>(
    traversalsById,
    AssetType.TRAVERSAL,
    ({ _id }) => {
      const canCurrentUserAdminTraversal =
        traversalAccessControlInterface.canAdminTraversal(
          permissionContext,
          _id
        );

      return {
        favorite: isFavorite(_id),
        folderId: getFolderId(_id),
        permissions: {
          canManagePermissions: canCurrentUserAdminTraversal,
          canUpdate: traversalAccessControlInterface.canEditTraversal(
            permissionContext,
            _id
          ),
          canDelete: canCurrentUserAdminTraversal,
          canCopy:
            traversalAccessControlInterface.canCreateTraversal(
              permissionContext
            ),
        },
      };
    }
  );

type GetViewpointsByIdArgs = GetAssetByIdBaseArgs & {
  viewpointsById: Record<string, APIDiscoverViewpointAttributes>;
};
const getViewpointsById = ({
  viewpointsById,
  isFavorite,
  getFolderId,
}: GetViewpointsByIdArgs) =>
  enhanceWithMeta<APIDiscoverViewpointAttributes, AssetType.VIEWPOINT>(
    viewpointsById,
    AssetType.VIEWPOINT,
    ({ _id }) => ({
      favorite: isFavorite(_id),
      folderId: getFolderId(_id),
      permissions: {
        canManagePermissions:
          viewpointAccessControlInterface.canAdminViewpoint(_id),
        canUpdate: viewpointAccessControlInterface.canEditViewpoint(_id),
        canDelete: viewpointAccessControlInterface.canEditViewpoint(_id),
        canCopy: viewpointAccessControlInterface.canCopyViewpoint(),
      },
    })
  );

const isStateUpdated =
  (previousState: AssetsStreamState) =>
  (streamPart: unknown, streamIndex: number) =>
    !previousState.previousInput ||
    streamPart !== previousState.previousInput[streamIndex];

type AssetsUpdatedState = {
  currentUserStateUpdated: boolean;
  assetFoldersStateUpdated: boolean;
  workspacesStateUpdated: boolean;
  presentationsStateUpdated: boolean;
  surveysStateUpdated: boolean;
  metamodelsStateUpdated: boolean;
  scenariosStateUpdated: boolean;
  reportsStateUpdated: boolean;
  dashboardsStateUpdated: boolean;
  traversalsStateUpdated: boolean;
  currentUserPermissionStateUpdated: boolean;
  bookmarksStateUpdated: boolean;
  viewpointsStateUpdated: boolean;
  broadcastsStateUpdated: boolean;
};
const assetsToRefresh = ({
  currentUserStateUpdated,
  assetFoldersStateUpdated,
  workspacesStateUpdated,
  presentationsStateUpdated,
  surveysStateUpdated,
  metamodelsStateUpdated,
  scenariosStateUpdated,
  reportsStateUpdated,
  dashboardsStateUpdated,
  traversalsStateUpdated,
  currentUserPermissionStateUpdated,
  bookmarksStateUpdated,
  viewpointsStateUpdated,
  broadcastsStateUpdated,
}: AssetsUpdatedState) => ({
  refreshAssetFoldersById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated,
  refreshWorkspaceAssetsById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated ||
    workspacesStateUpdated,
  refreshPresentationsById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated ||
    presentationsStateUpdated,
  refreshSurveysById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated ||
    surveysStateUpdated,
  refreshMetamodelsById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated ||
    metamodelsStateUpdated,
  refreshScenariosById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated ||
    scenariosStateUpdated,
  refreshReportsById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated ||
    reportsStateUpdated,
  refreshDashboardsById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated ||
    dashboardsStateUpdated,
  refreshTraversalsById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated ||
    traversalsStateUpdated,
  refreshBookmarksById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated ||
    bookmarksStateUpdated,
  refreshViewpointsById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated ||
    viewpointsStateUpdated,
  refreshBroadcastsById:
    currentUserPermissionStateUpdated ||
    assetFoldersStateUpdated ||
    currentUserStateUpdated ||
    broadcastsStateUpdated,
});

const mapProps = (
  previousState: AssetsStreamState,
  inputStream: InputStreamShape
): AssetsStreamState => {
  const [
    currentUserState,
    assetFoldersByIdWithoutMeta,
    workspacesByIdWithoutMeta,
    presentationsByIdWithoutMeta,
    surveysByIdWithoutMeta,
    metamodelsByIdWithoutMeta,
    scenariosByIdWithoutMeta,
    reportsByIdWithoutMeta,
    dashboardsByIdWithoutMeta,
    traversalsByIdWithoutMeta,
    permissionContext,
    bookmarksByIdWithoutMeta,
    viewpointsByIdWithoutMeta,
    broadcastsByIdWithoutMeta,
  ] = inputStream;

  const [
    currentUserStateUpdated,
    assetFoldersStateUpdated,
    workspacesStateUpdated,
    presentationsStateUpdated,
    surveysStateUpdated,
    metamodelsStateUpdated,
    scenariosStateUpdated,
    reportsStateUpdated,
    dashboardsStateUpdated,
    traversalsStateUpdated,
    currentUserPermissionStateUpdated,
    bookmarksStateUpdated,
    viewpointsStateUpdated,
    broadcastsStateUpdated,
  ] = inputStream.map(isStateUpdated(previousState));
  const presentationsEnabled = hasFeature(Features.PRESENTATIONS);
  const surveysEnabled = hasFeature(Features.SURVEYS);
  const broadcastsEnabled = hasFeature(Features.BROADCASTS);

  const currentUserIsOrgWriter = getIsOrgWriter(currentUserState);
  const currentUserIsOrgAdmin = getIsOrgAdmin(currentUserState);
  const favorites = getFavorites(currentUserState);

  const assetIdToFolderIdLookup = assetFoldersStateUpdated
    ? mapAssetIdsToFolderIds(assetFoldersByIdWithoutMeta)
    : previousState.assetIdToFolderIdLookup;

  const {
    refreshAssetFoldersById,
    refreshWorkspaceAssetsById,
    refreshPresentationsById,
    refreshSurveysById,
    refreshMetamodelsById,
    refreshScenariosById,
    refreshReportsById,
    refreshDashboardsById,
    refreshTraversalsById,
    refreshBookmarksById,
    refreshViewpointsById,
    refreshBroadcastsById,
  } = assetsToRefresh({
    currentUserStateUpdated,
    assetFoldersStateUpdated,
    workspacesStateUpdated,
    presentationsStateUpdated,
    surveysStateUpdated,
    metamodelsStateUpdated,
    scenariosStateUpdated,
    reportsStateUpdated,
    dashboardsStateUpdated,
    traversalsStateUpdated,
    currentUserPermissionStateUpdated,
    bookmarksStateUpdated,
    viewpointsStateUpdated,
    broadcastsStateUpdated,
  });

  const getFolderId = (id: string) => assetIdToFolderIdLookup[id] || null;
  const assetFoldersById = refreshAssetFoldersById
    ? getAssetFoldersById({
        assetFoldersById: assetFoldersByIdWithoutMeta,
        isFavorite: id => favorites[AssetType.FOLDER].includes(id),
        getFolderId,
        currentUserIsOrgWriter,
      })
    : previousState.assetFoldersById;

  const workspaceAssetsById = refreshWorkspaceAssetsById
    ? getWorkspaceAssetsById({
        workspacesById: workspacesByIdWithoutMeta,
        permissionContext,
        isFavorite: id => favorites[AssetType.WORKSPACE].includes(id),
        getFolderId,
      })
    : previousState.workspaceAssetsById;

  const presentationsById = refreshPresentationsById
    ? presentationsEnabled
      ? getPresentationsById({
          presentationsById: presentationsByIdWithoutMeta,
          isFavorite: id => favorites[AssetType.PRESENTATION].includes(id),
          getFolderId,
          permissionContext,
        })
      : {}
    : previousState.presentationsById;

  const { canAdminSurvey, canEditSurvey } = surveyAccessControlInterface;
  const surveysById = refreshSurveysById
    ? surveysEnabled
      ? getSurveysById({
          surveysById: surveysByIdWithoutMeta,
          isFavorite: id => favorites[AssetType.SURVEY].includes(id),
          getFolderId,
          canAdminSurvey,
          canEditSurvey,
        })
      : {}
    : previousState.surveysById;

  const metamodelsById = refreshMetamodelsById
    ? getMetamodelsById({
        metamodelsById: metamodelsByIdWithoutMeta,
        isFavorite: id => favorites[AssetType.METAMODEL].includes(id),
        getFolderId,
        currentUserIsOrgWriter,
        currentUserIsOrgAdmin,
      })
    : previousState.metamodelsById;

  const scenariosById = refreshScenariosById
    ? getScenariosById({
        scenariosById: scenariosByIdWithoutMeta,
        isFavorite: id => favorites[AssetType.SCENARIO].includes(id),
        getFolderId,
        permissionContext,
      })
    : previousState.scenariosById;

  const reportsById = refreshReportsById
    ? getReportsById({
        reportsById: reportsByIdWithoutMeta,
        isFavorite: id => favorites[AssetType.REPORT].includes(id),
        getFolderId,
        currentUserIsOrgAdmin,
        currentUserIsOrgWriter,
      })
    : previousState.reportsById;

  const dashboardsById = refreshDashboardsById
    ? getDashboardsById({
        dashboardsById: dashboardsByIdWithoutMeta,
        isFavorite: id => favorites[AssetType.DASHBOARD].includes(id),
        getFolderId,
        permissionContext,
      })
    : previousState.dashboardsById;

  const traversalsById = refreshTraversalsById
    ? getTraversalsById({
        traversalsById: traversalsByIdWithoutMeta,
        isFavorite: id => favorites[AssetType.TRAVERSAL].includes(id),
        getFolderId,
        permissionContext,
      })
    : previousState.traversalsById;

  const bookmarksById = refreshBookmarksById
    ? getBookmarksById({
        bookmarksById: bookmarksByIdWithoutMeta,
        isFavorite: id => favorites[AssetType.BOOKMARK].includes(id),
        getFolderId,
        currentUserIsOrgAdmin,
        currentUserIsOrgWriter,
      })
    : previousState.bookmarksById;

  const shouldHideDiscoverViewpoints =
    viewpointAccessControlInterface.getDiscoverViewpointOverViewHidden(
      Object.values(viewpointsByIdWithoutMeta)
    );

  const viewpointsById = shouldHideDiscoverViewpoints
    ? {}
    : refreshViewpointsById
      ? getViewpointsById({
          viewpointsById: viewpointsByIdWithoutMeta,
          isFavorite: id => favorites[AssetType.VIEWPOINT].includes(id),
          getFolderId,
        })
      : previousState.viewpointsById;

  const broadcastsById = refreshBroadcastsById
    ? broadcastsEnabled
      ? getBroadcastsById({
          broadcastsById: broadcastsByIdWithoutMeta,
          isFavorite: id => favorites[AssetType.BROADCAST].includes(id),
          getFolderId,
          currentUserIsOrgAdmin,
          currentUserIsOrgWriter,
        })
      : {}
    : previousState.broadcastsById;

  const result = {
    assetFoldersById,
    workspaceAssetsById,
    presentationsById,
    surveysById,
    scenariosById,
    metamodelsById,
    reportsById,
    dashboardsById,
    traversalsById,
    bookmarksById,
    viewpointsById,
    broadcastsById,
    previousInput: inputStream,
    assetIdToFolderIdLookup,
    permissionContext,
  };
  return result;
};

const assets$ = derivedStream(
  'asset$',
  currentUser$.pipe(distinctUntilChanged(isEqual)),
  assetFolders$.pipe(
    distinctUntilChanged(isEqual),
    map(folders => keyBy(folders, '_id'))
  ),
  workspaces$.pipe(
    map(({ byId }) => byId),
    distinctUntilChanged(isEqual)
  ),
  presentations$.pipe(
    map(({ byId }) => byId),
    distinctUntilChanged(isEqual)
  ),
  surveys$.pipe(
    map(({ byId }) => byId),
    distinctUntilChanged(isEqual)
  ),
  metamodels$.pipe(map(toByIdDictionary), distinctUntilChanged()),
  scenarios$.pipe(
    map(({ byId }) => byId),
    distinctUntilChanged(isEqual)
  ),
  reports$.pipe(
    map(({ byId }) => byId),
    distinctUntilChanged(isEqual)
  ),
  dashboards$.pipe(
    map(({ dashboardsById }) => dashboardsById),
    distinctUntilChanged(isEqual)
  ),
  traversals$.pipe(
    map(traversalStateOperations.getAllById),
    distinctUntilChanged()
  ),
  currentUserPermissionContext$.pipe(distinctUntilChanged(isEqual)),
  bookmarks$.pipe(
    map(({ byId }) => byId),
    distinctUntilChanged(isEqual)
  ),
  viewpoints$.pipe(
    map(({ viewpoints }) => (viewpoints ? toByIdDictionary(viewpoints) : {})),
    distinctUntilChanged(isEqual)
  ),
  broadcast$.pipe(
    map(({ broadcasts }) => toByIdDictionary(broadcasts)),
    distinctUntilChanged(isEqual)
  )
).pipe(
  scan(
    (previousState: AssetsStreamState, inputStream: unknown) =>
      mapProps(previousState, inputStream as InputStreamShape), // TODO AM remove casting once PR https://github.com/ardoq/rxbeach/pull/674 is merged
    emptyState
  )
);

export default assets$;
