import { CSSProperties } from 'react';
import WorkspaceModelNode from './WorkspaceModelNode';
import { WorkspaceModelBrowserWrapper } from './atoms';
import TreeSvg from './TreeSvg';
import { ArdoqId, ComponentTypeHierarchy } from '@ardoq/api-types';

type WorkspaceModelBrowserProps = {
  root: ComponentTypeHierarchy;
  highlightedComponentTypeId?: ArdoqId;
  style?: CSSProperties;
};

const getComponentTypes = (root: ComponentTypeHierarchy) => Object.values(root);

const WorkspaceModelBrowser = ({
  highlightedComponentTypeId,
  root = {},
  style,
}: WorkspaceModelBrowserProps) => {
  const componentTypes = getComponentTypes(root);
  return (
    <WorkspaceModelBrowserWrapper
      role="list"
      aria-label="Metamodel preview"
      data-test-id="metamodel-preview"
      style={style}
    >
      {componentTypes.map(componentType => (
        <WorkspaceModelNode
          key={componentType.id}
          componentType={componentType}
          highlightedComponentTypeId={highlightedComponentTypeId}
        />
      ))}
      <TreeSvg componentTypes={componentTypes} />
    </WorkspaceModelBrowserWrapper>
  );
};

export default WorkspaceModelBrowser;
