import Context from 'context';
import Fields from 'collections/fields';
import Perspectives from 'collections/perspectives';
import { getIn } from 'utils/collectionUtil';
import { getCurrentLocale, localeCompareNumericLowercase } from '@ardoq/locale';
import { BooleanOperator, QueryBuilderSubquery } from '@ardoq/api-types';
import {
  isPureTypeEqualitySubquery,
  isTypeEqualityQueryBuilderRule,
} from '@ardoq/filter-interface';

export type PerspectiveResult = {
  id: string;
  name: string;
  isSelected: boolean;
  isCurrentlyModified: boolean;
};

type Filter = {
  rules?: Filter[];
  name: string;
};

export const getPerspectivesData = () => {
  const matchingPerspectives: PerspectiveResult[] = [];
  const nonMatchingPerspectives: PerspectiveResult[] = [];
  const workspacePerspectives: PerspectiveResult[] = [];
  const referenceTypes = new Set();
  const componentFields = new Set();
  const filterByFields = (filters: Filter[]): boolean =>
    filters.some(({ rules, name }) =>
      rules
        ? filterByFields(rules)
        : componentFields.has(name) || referenceTypes.has(name)
    );
  Context.workspaces().forEach(ws => {
    Object.values(ws.getModel()?.getReferenceTypes() ?? {})
      .map(rt => rt.name)
      .forEach(rt => referenceTypes.add(rt));
    Fields.collection
      .getByModel(ws.getModel() ?? '')
      .forEach(field => componentFields.add(field));
  });
  Perspectives.filter(perspective => perspective!.hasContent()).forEach(
    perspective => {
      const workspaceMatch = Context.workspaces().some(
        ws => ws.id === perspective.attributes.workspace
      );

      const isThisTheCurrentSet =
        Perspectives.getCurrentSet()?.id === perspective.id;
      const isCurrentSetBeingModified = Perspectives.isCurrentSetModified();

      const perspectiveResult = {
        id: perspective.id,
        name: perspective.get('name'),
        isCurrentlyModified: isThisTheCurrentSet && isCurrentSetBeingModified,
        isSelected: isThisTheCurrentSet && !isCurrentSetBeingModified,
      };

      if (workspaceMatch) {
        workspacePerspectives.push(perspectiveResult);
        return;
      }
      const filterMatch = filterByFields(
        perspective.get('filters')?.advancedFilters ?? []
      );

      const groupMatch = (perspective.attributes.groupBys ?? []).some(
        (group: { type: string }) => {
          if (group.type === 'Field') {
            const name = getIn(
              Fields.collection,
              ['g', 'targetId', 'name'],
              ''
            );
            return componentFields.has(name);
          }
        }
      );
      if (filterMatch || groupMatch) {
        matchingPerspectives.push(perspectiveResult);
      } else {
        nonMatchingPerspectives.push(perspectiveResult);
      }
    }
  );

  const locale = getCurrentLocale();
  return [
    ...workspacePerspectives,
    ...matchingPerspectives,
    ...nonMatchingPerspectives,
  ].sort((a, b) => localeCompareNumericLowercase(a.name, b.name, locale));
};

/**
 * Refer to js doc for `findQuickPerspectivesTypeEqualitySubquery`
 */
export const clearQucikPerspectiveTypeFilters = (
  ruleRoot: QueryBuilderSubquery
) => {
  const isQickPerspectivesTypeFilter =
    ruleRoot.condition === BooleanOperator.OR
      ? isTypeEqualityQueryBuilderRule
      : isPureTypeEqualitySubquery;

  const newRules = ruleRoot.rules.filter(
    rule => !isQickPerspectivesTypeFilter(rule)
  );

  return {
    ...ruleRoot,
    rules: newRules,
  };
};
