import { collectRoutines, dispatchAction, tapRoutine } from '@ardoq/rxbeach';
import {
  createToken,
  createTokenFailed,
  deleteToken,
  getOrgTokens,
  getUserTokens,
  receivedAPIOrgTokens,
  receivedAPIUserTokens,
  regenerateToken,
  regenerateTokenFailed,
  setError,
  userTokenCreated,
  userTokenDeleted,
  userTokenRegenerated,
} from './actions';
import {
  createTokenAPI,
  deleteTokenAPI,
  getAllOrgTokensAPI,
  getAllTokensAPI,
  updateTokenAPI,
} from './utils';
import { APIToken } from '@ardoq/api-types';
import { showToast } from '@ardoq/status-ui';
import { newTokenGeneratedAction } from './CopyAndDisappearToken/actions';

const handleGetUserTokens = async () => {
  const response = await getAllTokensAPI();
  if (response.status === 'success') {
    dispatchAction(receivedAPIUserTokens(response.tokens));
  } else {
    dispatchAction(setError(response.message));
  }
};

const handleGetOrgTokens = async () => {
  const response = await getAllOrgTokensAPI();
  if (response.status === 'success') {
    dispatchAction(receivedAPIOrgTokens(response.tokens));
  } else {
    dispatchAction(setError(response.message));
  }
};

const handleCreateToken = async (payload: string) => {
  const response = await createTokenAPI(payload);
  if (response.status === 'success') {
    dispatchAction(userTokenCreated(response.token));
    dispatchAction(newTokenGeneratedAction(response.token));
  } else {
    dispatchAction(createTokenFailed());
    showToast('Failed to create API token. Please try again later.');
  }
};

const handleRegenerateToken = async (payload: APIToken) => {
  const response = await updateTokenAPI(payload);
  if (response.status === 'success') {
    dispatchAction(userTokenRegenerated(response.token));
    dispatchAction(newTokenGeneratedAction(response.token));
  } else {
    dispatchAction(regenerateTokenFailed());
    showToast('Failed to regenerate API token. Please try again later.');
  }
};

const handleDeleteToken = async (payload: APIToken) => {
  const response = await deleteTokenAPI(payload._id);
  if (response.status === 'success') {
    dispatchAction(userTokenDeleted(payload));
  } else {
    showToast('Failed to delete API token. Please try again later.');
  }
};

export default collectRoutines(
  tapRoutine(getUserTokens, handleGetUserTokens),
  tapRoutine(getOrgTokens, handleGetOrgTokens),
  tapRoutine(createToken, handleCreateToken),
  tapRoutine(deleteToken, handleDeleteToken),
  tapRoutine(regenerateToken, handleRegenerateToken)
);
