import { ArdoqId } from '@ardoq/api-types';
import { DoqType } from '@ardoq/doq';
import { logError } from '@ardoq/logging';
import { AlertDialog } from '@ardoq/modal';

export default ({
  name,
  workspaceId,
  onClose,
}: {
  name: string;
  workspaceId: ArdoqId;
  onClose: () => void;
}) => {
  logError(Error(`Cannot find ${name}.`), null, {
    workspaceId,
  });
  return (
    <AlertDialog
      onClose={onClose}
      title={`We Cannot find ${name}`}
      errorMessage={`We Cannot find ${name}`}
      subtitle="We are sorry about the inconvenience and have been notified. If the error persists, please contact our support."
      doqType={DoqType.ERROR}
    />
  );
};
