import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { updateCurrentUser } from 'streams/currentUser/actions';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  updateFeatureValue,
  updateFeatureValueFailed,
  updateFeatureValueSuccess,
} from './actions';
import currentUser$ from 'streams/currentUser/currentUser$';
import { ToastType, showToast } from '@ardoq/status-ui';
import { api, featuresApi, handleError } from '@ardoq/api';

const handleUpdateFeatureValue = routine(
  ofType(updateFeatureValue),
  extractPayload(),
  withLatestFrom(currentUser$),
  switchMap(([{ featureIds, value }, { organization }]) => {
    if (value === true) {
      return featuresApi.updateFeatures(organization._id, featureIds);
    }
    return featuresApi.deleteFeatures(organization._id, featureIds);
  }),
  handleError(error => {
    api.logErrorIfNeeded(error);
    dispatchAction(updateFeatureValueFailed());
    showToast('Toggling feature failed!', ToastType.INFO);
  }),
  tap(() => {
    dispatchAction(updateCurrentUser());
    dispatchAction(updateFeatureValueSuccess());
  })
);

export const featureSettingsRoutines = collectRoutines(
  handleUpdateFeatureValue
);
