import { AqLayout } from '@ardoq/layout';
import { CreateNewButton, PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import broadcastOverview$ from 'broadcasts/broadcastOverview/broadcastOverview$';
import { BroadcastOverviewStreamShape } from 'broadcasts/broadcastOverview/types';
import { initiateNavigationToNewBroadcastForm } from 'router/navigationActions';
import BroadcastLoader from 'broadcasts/components/BroadcastLoader';
import BroadcastOverviewTable from './BroadcastOverviewTable';
import { trackCreateBroadcastClicked } from '../tracking';
import { PrimaryButton } from '@ardoq/button';
import MainToolbar from 'menus/topbar/MainToolbar';
import Navbar from 'views/navbar/Navbar';
import { useWindowSize } from '@ardoq/hooks';
import { PageBody, PageWrapper } from '@ardoq/page-layout';

const PAGE_BODY_PADDING = 64;

const BroadcastOverview = ({
  fetchStatus,
  hasNewJourneyFeature,
  currentUserId,
}: BroadcastOverviewStreamShape) => {
  const handleCreateNewBroadcast = () => {
    dispatchAction(initiateNavigationToNewBroadcastForm(null));
    trackCreateBroadcastClicked('Overview');
  };
  const windowHeight = useWindowSize().height;
  // Needed to prevent a scrollbar on the overview page
  const PIXELS_ABOVE_ASSETS_BROWSER = hasNewJourneyFeature
    ? PAGE_TOPBAR_HEIGHT
    : 105;
  const pageContent = (
    <BroadcastLoader status={fetchStatus}>
      <BroadcastOverviewTable
        height={windowHeight - PIXELS_ABOVE_ASSETS_BROWSER - PAGE_BODY_PADDING}
        currentUserId={currentUserId}
      />
    </BroadcastLoader>
  );
  return hasNewJourneyFeature ? (
    <PageWrapper>
      <Navbar
        primaryContent="Broadcasts"
        primaryButton={
          <CreateNewButton
            onClick={handleCreateNewBroadcast}
            dataTestId="create-new-button"
          />
        }
        secondaryContent="Overview"
        toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
      />
      <PageBody backgroundColor="surfaceDefault">{pageContent}</PageBody>
    </PageWrapper>
  ) : (
    <AqLayout
      title="Broadcast Overview"
      renderHeaderButtons={() => (
        <PrimaryButton
          onClick={handleCreateNewBroadcast}
          dataTestId="create-new-button"
        >
          Create new
        </PrimaryButton>
      )}
    >
      {pageContent}
    </AqLayout>
  );
};

export default connect(BroadcastOverview, broadcastOverview$);
