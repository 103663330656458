import {
  Privilege,
  PrivilegeLabel,
  PrivilegesByUser,
  APIRoleAttributes,
  OrgAccessLevel,
} from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';
import { PRIVILEGES_ICONS } from './consts';

/* -----------------------------------------------------
 * -----------------    NOT USED    --------------------
 * -------------------------------------------------- */

const getRolePrivilegesLabels = (
  role: OrgAccessLevel,
  roles: APIRoleAttributes[]
): PrivilegeLabel[] => {
  return (
    roles.find(currentRole => currentRole.label === role)?.privileges ?? []
  );
};

/* -----------------------------------------------------
 * ------------------    USED    -----------------------
 * -------------------------------------------------- */

const isConfigurablePrivilege = (
  privilege: Privilege,
  configurablePrivileges: Privilege[]
): boolean => {
  return configurablePrivileges.some(p => p.label === privilege.label);
};

const getPrivilegeDisplayLabel = (privilege: Privilege): string => {
  return privilege.description ?? privilege.label;
};

const getPrivilegeIconName = (privilege: PrivilegeLabel): IconName => {
  return PRIVILEGES_ICONS[privilege] ?? IconName.FILE_OUTLINED;
};

const getUserPrivileges = (
  userId: string,
  privilegesByUser: PrivilegesByUser
): Privilege[] => {
  return privilegesByUser[userId] ?? [];
};

const isRoleBasedPrivilege = (privilege: Privilege): boolean =>
  !!privilege.inheritedFromRole;

export const getUserBasedPrivileges = (
  userPrivileges: Privilege[]
): Privilege[] =>
  userPrivileges.filter(privilege => !isRoleBasedPrivilege(privilege));

export const getRoleBasedPrivileges = (
  userPrivileges: Privilege[]
): Privilege[] => userPrivileges.filter(isRoleBasedPrivilege);

export const privilegesOperations = {
  isConfigurablePrivilege,
  getPrivilegeDisplayLabel,
  getUserPrivileges,
  getPrivilegeIconName,
  getUserBasedPrivileges,
  getRoleBasedPrivileges,
  isRoleBasedPrivilege,
  // ----------------- NOT USED -----------------
  getRolePrivilegesLabels,
};
