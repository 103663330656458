import { trackEvent } from 'tracking/tracking';
import { OpenScenarioClickSource, ScopeSize } from './types';
import { ArdoqId, DiffMode, ViewIds } from '@ardoq/api-types';

export const trackClickStartCreatingNewScenario = (
  clickSource: 'topbarView' | 'topbarSelection' | 'contextmenu'
) => trackEvent('Clicked to start creating new scenario', { clickSource });

export const trackOpenScenario = (
  clickSource: OpenScenarioClickSource,
  scopeSize: ScopeSize,
  scenarioId: ArdoqId
) =>
  trackEvent(
    'Opened scenario',
    { clickSource, ...scopeSize, resourceId: scenarioId },
    true
  );

export const trackClickStartAddToScenario = (
  clickSource:
    | 'relatedNavigator'
    | 'relatedView'
    | 'topbarSelection'
    | 'view'
    | 'search'
    | 'navigator'
) =>
  trackEvent('Clicked to start adding components to scenario', { clickSource });

export const trackToggleRelatedComponents = (type: 'open' | 'close') =>
  trackEvent('Toggled related components in scenario', { type });

export const trackClickRelatedComponents = (
  modifier: 'none' | 'control' | 'shift'
) => trackEvent('Clicked related components', { modifier });

export const trackAddScenarioToPresentationSlide = (slideView: ViewIds) =>
  trackEvent('Added scenario to presentation slide', { slideView });

export const trackToggleVisualDiffMode = (diffmode: DiffMode) =>
  trackEvent('Toggled visual diff mode', { diffmode }, true);

export type CloseVisualDiffClickSource =
  | 'related'
  | 'topbar'
  | 'switcher'
  | 'settingsBar';

export const trackClickCloseVisualDiff = (
  clickSource: CloseVisualDiffClickSource
) => trackEvent('Closed visual diff mode', { clickSource });

export const trackClickOpenVisualDiff = (
  clickSource: CloseVisualDiffClickSource
) => trackEvent('Opened visual diff mode', { clickSource });
