import { useState } from 'react';
import { ModalSize, ModalTemplate } from '@ardoq/modal';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { bulkRemoveUsers } from 'admin/user/actions';
import { APIOrganizationUser } from '@ardoq/api-types';
import currentUser$ from 'streams/currentUser/currentUser$';
import { map } from 'rxjs';
import { TextInput } from '@ardoq/forms';
import styled from 'styled-components';
import { s32 } from '@ardoq/design-tokens';
import { pluralize } from '@ardoq/common-helpers';

type RemoveUsersDialogProps = {
  orgName: string;
  selectedUsers: APIOrganizationUser[];
  closeDialog: () => void;
};

const Wrapper = styled.div`
  padding: ${s32};
`;

const RemoveUsersDialog = ({
  orgName,
  closeDialog,
  selectedUsers,
}: RemoveUsersDialogProps) => {
  const [inputValue, setInputValue] = useState('');
  const canDelete = inputValue.toLowerCase() === 'yes';

  const handleBulkUpdateUsers = () => {
    if (!canDelete) return;
    const selectedUserIds = selectedUsers.map(({ _id }) => _id);
    dispatchAction(bulkRemoveUsers(selectedUserIds));
    closeDialog();
  };
  const userCount = selectedUsers.length;
  return (
    <ModalTemplate
      headerText={pluralize('Remove user', userCount)}
      modalSize={ModalSize.S}
      onCloseButtonClick={closeDialog}
      onSecondaryButtonClick={closeDialog}
      onDangerButtonClick={handleBulkUpdateUsers}
      dangerButtonClickId="remove-users-click-id"
      dangerButtonText={pluralize('Remove user', userCount)}
      secondaryButtonClickId="cancel-remove-users-click-id"
      secondaryButtonText="Cancel"
      subtitle={`Selected ${pluralize('user', userCount)}:`}
      text={selectedUsers.map(user => user.email).join(', ')}
      isDangerButtonDisabled={!canDelete}
    >
      <Wrapper>
        <TextInput
          onValueChange={setInputValue}
          value={inputValue}
          label={`${userCount > 1 ? 'These' : 'This'} ${pluralize(
            'user',
            userCount
          )} will no longer have any access to ${
            orgName ?? ''
          } in Ardoq. To continue, type "Yes".`}
        />
      </Wrapper>
    </ModalTemplate>
  );
};

export default connect(
  RemoveUsersDialog,
  currentUser$.pipe(
    map(currentUser => ({
      orgName: currentUser.organization?.name,
    }))
  )
);
