import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { filter, tap, withLatestFrom } from 'rxjs';
import fp from 'lodash/fp';
import { showToast, ToastType } from '@ardoq/status-ui';
import { upsertSchedule } from 'integrations/common/streams/schedule/actions';
import {
  createSchedule,
  updateSchedule,
} from 'integrations/common/streams/schedules/actions';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getCurrentTransferConfig } from 'integrations/common/streams/transferConfigs/utils';
import { tabularMappingIntoTransferConfig } from 'integrations/common/streams/tabularMappings/utils';
import { ScheduleType } from '@ardoq/api-types';
import { CLOUD_PROVIDER_TYPE } from '@ardoq/api-types/integrations';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { getScheduleStream } from 'integrations/common/streams/schedule/schedule$';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { vpcs$ } from '../vpcs/vpcs$';
import { createProviderParams } from '../../utils';

const handleUpsertSchedule = routine(
  ofType(upsertSchedule),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'aws-v3'),
  withLatestFrom(
    getTabularMappingStream('aws-v3'),
    getTransferConfigsStream('aws-v3'),
    getActiveIntegrationStream('aws-v3'),
    getSchedulesStream('aws-v3'),
    getScheduleStream('aws-v3'),
    getRegionsStream('aws-v3'),
    getResourceTypesStream('aws-v3'),
    getConnectionsStream('aws-v3'),
    vpcs$
  ),
  tap(
    ([
      { formData },
      tabularMapping,
      transferConfigs,
      activeIntegration,
      schedules,
      scheduleState,
      regionsState,
      resourceTypesState,
      { selectedConnectionIds },
      vpcs,
    ]) => {
      const currentTransferConfig = getCurrentTransferConfig(
        transferConfigs.configs,
        activeIntegration
      );

      const configName =
        currentTransferConfig.name || activeIntegration.integrationName;

      const config = tabularMappingIntoTransferConfig(
        tabularMapping,
        currentTransferConfig
      );

      if (!selectedConnectionIds.length) {
        showToast(`No connection`, ToastType.INFO);
        return;
      }

      dispatchAction(
        trackIntegrationEvent({
          integrationId: 'aws-v3',
          name: 'SELECTED_SCHEDULE_INTERVAL',
          metadata: {
            source: 'import-step',
            interval: formData.policy.type,
            day:
              formData.policy.type === 'weekly'
                ? formData.policy.weekDay
                : null,
            managesWorkspace: formData.managesWorkspace ? 'on' : 'off',
          },
        })
      );

      const cloudProviderConfig = {
        accountIds: selectedConnectionIds,
        providerParams: createProviderParams({
          regions: regionsState.selectedRegionIds,
          resourceTypes: resourceTypesState.selectedResourceTypeIds,
          vpcs: vpcs.selectedVpcIds,
        }),
      };

      const scheduleConfig = {
        ...config,
        sourceConfig: cloudProviderConfig,
        name: configName,
      };

      if (scheduleState.loadedScheduleId) {
        const loadedSchedule = schedules.schedules.find(
          s => s._id === scheduleState.loadedScheduleId
        );

        if (!loadedSchedule) {
          return;
        }

        return dispatchAction(
          updateSchedule({
            integrationId: 'aws-v3',
            schedule: fp.flow(
              fp.set('schedulingPolicy', formData.policy),
              fp.update('jobOptions', options => ({
                ...options,
                name: formData.name,
                managesWorkspace: formData.managesWorkspace,
                cloudProviderConfig,
                tabularMapping: scheduleConfig,
              }))
            )(loadedSchedule),
          })
        );
      }

      dispatchAction(
        createSchedule({
          integrationId: 'aws-v3',
          schedule: {
            schedulingPolicy: formData.policy,
            jobOptions: {
              type: ScheduleType.CLOUD_IMPORT,
              name: formData.name,
              managesWorkspace: formData.managesWorkspace,
              tabularMapping: scheduleConfig,
              sourceId: null,
              provider: CLOUD_PROVIDER_TYPE.AWS,
              cloudProviderConfig,
            },
          },
        })
      );
    }
  )
);

export default [handleUpsertSchedule];
