import { trackEvent } from 'tracking/tracking';

export const trackClickedToolbarButton = (tooltipText: string) => {
  trackEvent('Clicked top right toolbar button', {
    tooltipText,
  });
};

export const trackClickedLeftSidebarMenu = (label: string) => {
  trackEvent('Clicked left sidebar menu', {
    label,
  });
};

export const trackClickedToChangeOrgLogo = (origin: string) => {
  trackEvent('Clicked to set Org Logo', { origin });
};
