import styled from 'styled-components';
import { SubscriptionsSelector } from './subscriptionsSelector/SubscriptionsSelector';

type SelectSubscriptionsProps = {
  subscriptions: any[];
  onSelect: (ids: string[]) => void;
  selectedSubscriptionIds: string[];
  isDisabled: boolean;
};

export const SelectSubscriptions = ({
  subscriptions,
  selectedSubscriptionIds,
  onSelect,
  isDisabled = false,
}: SelectSubscriptionsProps) => {
  return (
    <SelectorContainer>
      <SubscriptionsSelector
        subscriptions={subscriptions}
        selectedSubscriptionIds={selectedSubscriptionIds}
        onSelect={onSelect}
        isDisabled={isDisabled}
      />
    </SelectorContainer>
  );
};

const SelectorContainer = styled.div`
  max-width: 730px;
`;
