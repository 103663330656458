import { BroadcastsPane, BroadcastsRoute } from './types';
import { reducer } from '@ardoq/rxbeach';
import {
  navigateToActiveBroadcastForm,
  navigateToBroadcastOverview,
  navigateToEditBroadcastForm,
  navigateToNewBroadcastForm,
} from 'router/navigationActions';
import { ArdoqId } from '@ardoq/api-types';
import { notifyDeletingBroadcastSucceeded } from 'broadcasts/actions';

export const defaultState: BroadcastsRoute = {
  broadcastId: null,
  broadcastsPane: BroadcastsPane.OVERVIEW,
};

const handleNavigateToBroadcastOverview = (currentState: BroadcastsRoute) => ({
  ...currentState,
  broadcastsPane: BroadcastsPane.OVERVIEW,
});

const handleNavigateToNewBroadcastForm = (
  currentState: BroadcastsRoute
): BroadcastsRoute => ({
  ...currentState,
  broadcastId: null,
  broadcastsPane: BroadcastsPane.NEW,
});

const handleNavigateToEditBroadcastForm = (
  currentState: BroadcastsRoute,
  broadcastId: ArdoqId
): BroadcastsRoute => ({
  ...currentState,
  broadcastId,
  broadcastsPane: BroadcastsPane.EDIT,
});

const handleNavigateToActiveBroadcastForm = (
  currentState: BroadcastsRoute
) => ({
  ...currentState,
  broadcastsPane: currentState.broadcastId
    ? BroadcastsPane.EDIT
    : BroadcastsPane.NEW,
});

const handleNotifyDeletingBroadcastSucceeded = (
  currentState: BroadcastsRoute,
  broadcastId: ArdoqId
): BroadcastsRoute => {
  if (broadcastId !== currentState.broadcastId) return currentState;
  return {
    ...currentState,
    broadcastId: null,
  };
};

export const reducers = [
  reducer(navigateToBroadcastOverview, handleNavigateToBroadcastOverview),
  reducer(navigateToNewBroadcastForm, handleNavigateToNewBroadcastForm),
  reducer(navigateToEditBroadcastForm, handleNavigateToEditBroadcastForm),
  reducer(navigateToActiveBroadcastForm, handleNavigateToActiveBroadcastForm),
  reducer(
    notifyDeletingBroadcastSucceeded,
    handleNotifyDeletingBroadcastSucceeded
  ),
];
