import {
  hideFeatureHighlight,
  resetPosition,
  showFeatureHighlight,
  updatePosition,
} from 'featureHighlight/actions';
import { FeatureHighlightState } from 'featureHighlight/types';
import { getHighlightBoxPositionByRefBox } from 'featureHighlight/utils';
import {
  DEFAULT_ARROW_SIZE,
  DEFAULT_MARGIN,
  getTooltipPositionByRefBox,
} from '@ardoq/tooltip';
import { ExtractPayload, reducer } from '@ardoq/rxbeach';

const MARGIN_FROM_REFERENCE_BOX = 10;

export const defaultState: FeatureHighlightState = {
  referenceElementSelector: '',
  tooltipText: '',
  tooltipPosition: null,
  arrowPosition: null,
  highlightBoxPosition: null,
  isFlipped: false,
};

const handleShowFeatureHighlight = (
  currentState: FeatureHighlightState,
  {
    referenceElementSelector,
    tooltipText,
  }: ExtractPayload<typeof showFeatureHighlight>
): FeatureHighlightState => ({
  ...currentState,
  referenceElementSelector,
  tooltipText: tooltipText.trim(),
});

const handleUpdatePosition = (
  currentState: FeatureHighlightState,
  { referenceBox, tooltipBox }: ExtractPayload<typeof updatePosition>
): FeatureHighlightState => ({
  ...currentState,
  highlightBoxPosition: getHighlightBoxPositionByRefBox(referenceBox),
  ...getTooltipPositionByRefBox(
    referenceBox,
    tooltipBox,
    DEFAULT_ARROW_SIZE,
    DEFAULT_MARGIN,
    MARGIN_FROM_REFERENCE_BOX
  ),
});

const handleResetPosition = (
  currentState: FeatureHighlightState
): FeatureHighlightState => ({
  ...currentState,
  highlightBoxPosition: null,
  tooltipPosition: null,
  arrowPosition: null,
  isFlipped: false,
});

const handleHideFeatureHighlight = () => defaultState;

export const reducers = [
  reducer(showFeatureHighlight, handleShowFeatureHighlight),
  reducer(updatePosition, handleUpdatePosition),
  reducer(resetPosition, handleResetPosition),
  reducer(hideFeatureHighlight, handleHideFeatureHighlight),
];
