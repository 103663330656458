import React, { useState } from 'react';
import {
  AwsAuthMethod,
  AwsConnection,
  AwsUpsertConnectionPayload,
} from '@ardoq/api-types/integrations';
import { ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { header4, Link, text1 } from '@ardoq/typography';
import {
  FormWrapper,
  GroupFieldLayout,
  RadioGroup,
  RadioGroupItem,
  TextInput,
} from '@ardoq/forms';
import { ConnectionsAsyncState } from 'integrations/common/streams/connections/types';
import styled from 'styled-components';
import { ErrorNotification, showToast, ToastType } from '@ardoq/status-ui';
import { IconName } from '@ardoq/icons';
import fp from 'lodash/fp';
import { SettingsState } from '../../streams/settings/types';
import { awsDictionary } from 'integrations/common/dictionary';
import { AutoFocus } from 'integrations/common/components/AutoFocus';

type UpsertConnectionParams = {
  upsertStatus: ConnectionsAsyncState;
  existingConnection?: AwsConnection;
  settingsState: SettingsState;
  onClose: () => void;
  onSubmit: (connection: AwsUpsertConnectionPayload) => Promise<boolean>;
};

const Form = styled(FormWrapper)`
  width: 100%;
`;

const FormHeader = styled.div`
  ${header4}
`;

const FormText = styled.div`
  ${text1}
`;

type AuthOptions = {
  label: string;
  value: AwsAuthMethod;
};

const authOptions: AuthOptions[] = [
  {
    label: 'Role-based',
    value: 'role',
  },
  {
    label: 'Access key',
    value: 'basic',
  },
];

const defaultConnectionPayload: AwsUpsertConnectionPayload = {
  name: '',
  authMethod: 'role' satisfies AwsConnection['authMethod'],
  provider: 'aws',
  accessKey: '',
  secretKey: '',
  role: '',
};

const handleCopy = (content: string, setting: string) => {
  navigator.clipboard.writeText(content);
  showToast(`${setting} copied.`, ToastType.SUCCESS);
};

const isInvalidConnection = (connection: AwsUpsertConnectionPayload) => {
  const validProps =
    connection.authMethod === 'role'
      ? ['name', 'role']
      : ['name', 'accessKey', 'secretKey'];
  const propsToValidate = fp.pick(validProps, connection);
  return fp.some(value => fp.isEmpty(fp.trim(value ?? '')), propsToValidate);
};

export const UpsertConnection = ({
  existingConnection,
  settingsState,
  upsertStatus,
  onClose,
  onSubmit,
}: UpsertConnectionParams) => {
  const [connectionInput, setConnectionInput] =
    useState<AwsUpsertConnectionPayload>(
      existingConnection
        ? {
            ...defaultConnectionPayload,
            ...existingConnection,
          }
        : {
            ...defaultConnectionPayload,
          }
    );

  const onPrimaryButtonClick = async () => {
    const submitData = { ...connectionInput };
    if (submitData.authMethod === 'role') {
      delete submitData.secretKey;
      delete submitData.accessKey;
    } else {
      delete submitData.role;
    }

    const isSuccess = await onSubmit(submitData);

    if (isSuccess) {
      onClose();
    }
  };

  return (
    <ModalTemplate
      modalSize={ModalSize.S}
      headerText={`${
        existingConnection ? 'Edit connection' : 'Create new connection'
      }`}
      isInProgress={upsertStatus.status === 'LOADING'}
      primaryButtonText={`${
        existingConnection ? 'Save changes' : 'Create connection'
      }`}
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={onPrimaryButtonClick}
      isPrimaryButtonDisabled={
        settingsState.asyncStatus !== 'SUCCESS' ||
        isInvalidConnection(connectionInput)
      }
      onSecondaryButtonClick={onClose}
    >
      <ModalLayout>
        {upsertStatus.status === 'FAILURE' && upsertStatus.message && (
          <AutoFocus>
            <ErrorNotification>{upsertStatus.message}</ErrorNotification>
          </AutoFocus>
        )}
        <Form>
          <FormHeader>
            Enter your {awsDictionary.name} account details to establish a
            secure connection with Ardoq. The information will be kept private.
          </FormHeader>
          <FormText>
            Your data is securely handled:{' '}
            <Link href="https://www.ardoq.com/privacy" target="_blank">
              Privacy Notice
            </Link>
          </FormText>

          <TextInput
            autoFocus
            value={connectionInput.name}
            onValueChange={val =>
              setConnectionInput({ ...connectionInput, name: val })
            }
            label="Connection name"
            helperText="Give the connection a descriptive name to locate it easily in the
  overview page"
          />

          <RadioGroup
            label="Authentication type"
            groupFieldLayout={GroupFieldLayout.HORIZONTAL}
            onValueChange={value =>
              setConnectionInput({
                ...connectionInput,
                authMethod: value as AwsAuthMethod,
              })
            }
            value={connectionInput.authMethod}
          >
            {authOptions.map((radioItem, i) => (
              <RadioGroupItem key={i} value={radioItem.value}>
                {radioItem.label}
              </RadioGroupItem>
            ))}
          </RadioGroup>
          {connectionInput.authMethod === 'role' ? (
            <>
              <TextInput
                value={connectionInput.role}
                onValueChange={val =>
                  setConnectionInput({ ...connectionInput, role: val })
                }
                label="Role ARN"
                helperText={
                  <>
                    The Role ARN is the {awsDictionary.name} role you set up for
                    the Ardoq integration. Learn more about how to create a
                    connection using the{' '}
                    <Link
                      href="https://help.ardoq.com/en/articles/158198-aws-integration-beta#h_b74ceb8615"
                      target="_blank"
                    >
                      Role ARN
                    </Link>
                  </>
                }
              />
              <TextInput
                value={settingsState.settings.externalId}
                label="External ID"
                rightIconName={IconName.COPY}
                rightIconOnClick={() =>
                  handleCopy(settingsState.settings.externalId, 'External ID')
                }
                isReadOnly
              />
              <TextInput
                value={settingsState.settings.ardoqRoleArn}
                label="Ardoq role ARN"
                rightIconName={IconName.COPY}
                rightIconOnClick={() =>
                  handleCopy(
                    settingsState.settings.ardoqRoleArn,
                    'Ardoq role ARN'
                  )
                }
                isReadOnly
              />
            </>
          ) : (
            <>
              <TextInput
                value={connectionInput.accessKey}
                onValueChange={val =>
                  setConnectionInput({ ...connectionInput, accessKey: val })
                }
                label="IAM access key"
                helperText={
                  <>
                    Learn more about how to create a connection using{' '}
                    <Link
                      href="https://help.ardoq.com/en/articles/158198-aws-integration-beta#h_27a59a8734"
                      target="_blank"
                    >
                      IAM access key
                    </Link>
                  </>
                }
              />
              <TextInput
                type="password"
                value={connectionInput.secretKey}
                onValueChange={val =>
                  setConnectionInput({ ...connectionInput, secretKey: val })
                }
                label="IAM secret access key"
              />
            </>
          )}
        </Form>
      </ModalLayout>
    </ModalTemplate>
  );
};
