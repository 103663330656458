import { MetaModelLoadedState } from 'architectureModel/loadMetaModel';
import { ErrorState, MetaInfoState } from './metaInfoTypes';
import { ActiveViewState } from 'streams/views/mainContent/activeView$';
import { AvailableViews } from 'views/availableViews$';
import { editTraversalOperations } from 'viewpointBuilder/traversals/editTraversalOperations';
import { getId } from 'viewpointBuilder/getId';
import {
  SetDescriptionPayload,
  SetGraphDataPayload,
  SetInitialMetaInfoPayload,
  SetNamePayload,
} from './metaInfoActions';
import {
  SetStartTypeAndTraversalStartSetOrCount,
  SetTraversalsPayload,
} from 'viewpointBuilder/traversals/editTraversalActions';
import { MetaInfo } from 'streams/views/mainContent/types';
import {
  ensureContrast,
  getDefaultCSSColor,
  getLightenedColor,
} from '@ardoq/color-helpers';
import { IconSize, getIcon } from '@ardoq/icons';
import { MetaModel, MetaModelComponentType, ViewIds } from '@ardoq/api-types';
import {
  getViewpointModeSupportedViews,
  isViewWithoutFullyMultiLabelsSupport,
} from 'traversals/getViewpointModeSupportedViews';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { isArdoqError } from '@ardoq/common-helpers';
import { SelectedViewState } from '../selectedView/types';
import { findIndex } from 'lodash';
import { getRestrictedMultilabelFormattingWarningMessage } from 'getRestrictedMultilabelFormattingWarningMessage';
import { LabelFormattingInfo } from '@ardoq/data-model';
import { getIsWarningMessage } from 'perspectiveSidebar/perspectiveEditor/multiLabelUtils';

const initialState: MetaInfoState = {
  name: null,
  description: null,
  viewName: ViewIds.NONE,
  viewSelectWarning: undefined,
  folderId: null,

  viewOptions: [],

  paths: [],
  startType: null,
  metaModel: null,
  graphNodes: new Map(),
  graphEdges: new Map(),
  graphGroups: new Map(),

  initialState: null,
  errorState: 'NONE',

  canSaveViewpoint: false,
  stateAsSavableAttributes: null,
};

export const getInitialMetaInfoState = () => structuredClone(initialState);

const viewsToViewOptions = (views: MetaInfo[]) => {
  const viewOptions = views
    .map(({ id, name }) => ({
      label: name ?? 'Missing view name',
      value: id,
    }))
    .filter(({ value }) => getViewpointModeSupportedViews().includes(value));

  const index = findIndex(viewOptions, {
    value: ViewIds.MODERNIZED_BLOCK_DIAGRAM,
  });
  if (index > 0) {
    const [modernizedBlockDiagram] = viewOptions.splice(index, 1);
    viewOptions.unshift(modernizedBlockDiagram);
  }

  return viewOptions;
};

const metaModelToStartTypeOptions = ({ componentTypes }: MetaModel) => {
  const locale = getCurrentLocale();

  return componentTypes
    .sort((a, b) => localeCompare(a.name, b.name, locale))
    .map(({ name, style }) => {
      return {
        label: name,
        value: name,
        leftContentProps: getComponentTypeRepresentationProps(style),
      };
    });
};

const getComponentTypeRepresentationProps = ({
  color: colorCandidate,
  icon,
  image,
  level,
}: MetaModelComponentType['style']) => {
  const color = colorCandidate ?? getDefaultCSSColor(level);
  const lightenedColor = getLightenedColor(color);
  const contrastColor = ensureContrast(lightenedColor, color);
  return {
    isImage: Boolean(image),
    value: image,
    icon: getIcon(icon),
    iconSize: IconSize.MEDIUM,
    style: { color: contrastColor },
  };
};

const getSelectedStartTypeOption = (
  startTypeOptions: ReturnType<typeof metaModelToStartTypeOptions>,
  startType: string | null
) => startTypeOptions.find(({ value }) => value === startType) ?? null;

type MetaInfoDependencies = {
  metaModelResult: MetaModelLoadedState;
  activeViews: ActiveViewState;
  availableViews: AvailableViews;
};

const processDependencies = (
  state: MetaInfoState,
  metaInfoDependencies: MetaInfoDependencies
): MetaInfoState =>
  dependenciesReducerWithIdGenerator(state, metaInfoDependencies);

const dependenciesReducerWithIdGenerator = (
  state: MetaInfoState,
  {
    metaModelResult,
    activeViews: { mainViewId },
    availableViews: { views },
  }: MetaInfoDependencies,
  _getId: () => string = getId
) => {
  if (isArdoqError(metaModelResult)) {
    return { ...state, errorState: 'NO_META_MODEL' as ErrorState };
  }

  if (state.initialState === null) {
    const viewOptions = viewsToViewOptions(views);
    const startTypeOptions = metaModelToStartTypeOptions(metaModelResult);
    return {
      ...state,
      metaModel: metaModelResult,
      viewOptions,
      startTypeOptions,
      graphEdges: new Map(),
      graphNodes: new Map(),
    };
  }
  if (!state.initialState.startType) {
    return { ...state, errorState: 'NO_INITIAL_STATE' as ErrorState };
  }

  if (
    !metaModelResult.componentTypes.some(
      ({ name }) => name === state.initialState?.startType
    )
  ) {
    return { ...state, errorState: 'INVALID_START_TYPE' as ErrorState };
  }

  const viewOptions = viewsToViewOptions(views);
  const selectedView = getSelectedView(
    viewOptions,
    mainViewId || state.initialState.viewName
  );
  const startTypeOptions = metaModelToStartTypeOptions(metaModelResult);
  const selectedStartTypeOption = getSelectedStartTypeOption(
    startTypeOptions,
    state.startType
  );

  const { paths, startType } = state.initialState;
  if (!paths || paths.length === 0) {
    return { ...state, errorState: 'NO_PATHS' as ErrorState };
  }

  const { graphEdges, graphNodes } = editTraversalOperations.pathsToGraph({
    paths,
    startType,
    metaModel: metaModelResult,
    getId: _getId,
    startNode: null,
    filters: null,
  });

  return {
    ...state,
    metaModel: metaModelResult,
    viewOptions,
    selectedView,
    startTypeOptions,
    selectedStartTypeOption,
    graphEdges,
    graphNodes,
  };
};

const getSelectedView = (
  viewOptions: ReturnType<typeof viewsToViewOptions>,
  viewId: ViewIds
) => viewOptions.find(({ value }) => value === viewId) ?? null;

const setTraversalName = (
  state: MetaInfoState,
  { name }: SetNamePayload
): MetaInfoState => ({
  ...state,
  name,
});

const setSelectedView = (
  state: MetaInfoState,
  { selectedView }: SelectedViewState
) => ({
  ...state,
  viewName: selectedView?.value ?? ViewIds.NONE,
});

type SelectedViewWarningDependencies = {
  selectedView: ViewIds;
  labelFormatting: LabelFormattingInfo[];
};
const setSelectedViewWarning = (
  state: MetaInfoState,
  { selectedView, labelFormatting }: SelectedViewWarningDependencies
) => {
  const isFormattingFullySupported =
    !labelFormatting.length ||
    !isViewWithoutFullyMultiLabelsSupport(selectedView) ||
    !getIsWarningMessage(labelFormatting, selectedView);

  const viewSelectWarning = isFormattingFullySupported
    ? undefined
    : getRestrictedMultilabelFormattingWarningMessage(true, selectedView);

  return {
    ...state,
    viewSelectWarning,
  };
};

const setInitialState = (
  state: MetaInfoState,
  { initialState }: SetInitialMetaInfoPayload
): MetaInfoState => {
  if (initialState === null) {
    return {
      ...state,
      initialState,
    };
  }

  return {
    ...state,
    initialState,
    name: initialState.name,
    description: initialState.description,
    paths: initialState.paths.length > 0 ? initialState.paths : [],
    startType: initialState.startType,
    viewName: initialState.viewName,
  };
};

const setTraversal = (
  state: MetaInfoState,
  { paths }: SetTraversalsPayload
) => {
  if (!(state.startType && state.metaModel)) {
    return {
      ...state,
      paths,
    };
  }

  const { graphEdges, graphNodes } = editTraversalOperations.pathsToGraph({
    paths,
    startType: state.startType,
    metaModel: state.metaModel,
    getId,
    startNode: null,
    filters: null,
  });

  return {
    ...state,
    paths,
    graphEdges,
    graphNodes,
  };
};

const setStartType = (
  state: MetaInfoState,
  { startType }: SetStartTypeAndTraversalStartSetOrCount
) => {
  if (!state.metaModel || !startType) {
    return {
      ...state,
      startType,
    };
  }

  const { graphEdges, graphNodes } = editTraversalOperations.pathsToGraph({
    paths: [],
    startType,
    metaModel: state.metaModel,
    getId,
    startNode: null,
    filters: null,
  });

  return {
    ...state,
    startType,
    graphEdges,
    graphNodes,
  };
};

const setTraversalDescription = (
  state: MetaInfoState,
  { description }: SetDescriptionPayload
) => ({
  ...state,
  description,
});

const stateWithSavableAttributes = (state: MetaInfoState): MetaInfoState => {
  const { name, viewName, description, initialState, folderId } = state;
  const savableAttributesCandidate = {
    name,
    viewName,
    description,
    _id: initialState?._id ?? null,
    _version: initialState?._version ?? null,
    folderId: folderId ?? undefined,
  };

  return {
    ...state,
    canSaveViewpoint: isTraversalUpdateOrSaveAttributes(
      savableAttributesCandidate
    ),
    stateAsSavableAttributes: savableAttributesCandidate,
  };
};

const isTraversalUpdateOrSaveAttributes = (attributes: {
  name: string | null;
  description: string | null;
  viewName: ViewIds;
}) => {
  const { name, viewName } = attributes;
  return Boolean(
    name?.trim() &&
      viewName &&
      getViewpointModeSupportedViews().includes(viewName)
  );
};

const setGraphData = (
  state: MetaInfoState,
  { graphNodes, graphEdges, graphGroups, paths }: SetGraphDataPayload
) => {
  return {
    ...state,
    graphNodes,
    graphEdges,
    graphGroups,
    paths,
  };
};

const resetMetaInfoState = () => getInitialMetaInfoState();

export const viewpointMetaInfoOperations = {
  getInitialMetaInfoState,
  processDependencies,
  setTraversalName,
  setInitialState,
  setSelectedView,
  setTraversal,
  setStartType,
  setTraversalDescription,
  stateWithSavableAttributes,
  setGraphData,
  setSelectedViewWarning,
  resetMetaInfoState,
};
