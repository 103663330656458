import { colors, space } from '@ardoq/design-tokens';
import { text1 } from '@ardoq/typography';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: ${space.small};
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: ${space.small};
  flex: 1;
  cursor: pointer;
  ${text1};
  padding: ${space.medium};
  border-radius: ${space.small};
  border: 1px solid ${colors.grey80};
  &:has(input[type='radio']:checked) {
    border-color: ${colors.iconActionActive};
  }
  input[type='radio'] {
    width: 1.25rem !important;
    height: 1.25rem !important;
  }
  &:has(input[type='radio']:disabled) {
    cursor: not-allowed;
    border-color: ${colors.textDisabled};
    color: ${colors.textDisabled};
  }
`;

const Input = styled.input`
  appearance: none;
  margin: 0;
  width: 1.25rem !important;
  height: 1.25rem !important;
  border: 2px solid ${colors.iconAction};
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: ${colors.iconActionActive};
  }
  &:checked {
    border-color: ${colors.iconActionActive};
    &::before {
      transform: scale(1);
    }
  }
  &:disabled {
    border-color: ${colors.textDisabled};
    cursor: not-allowed;
    &::before {
      background-color: ${colors.textDisabled};
    }
  }
`;
type RadioGroupProps<TValue> = {
  options: {
    label: ReactNode;
    value: TValue;
    isDisabled?: boolean;
    'data-testid'?: string;
  }[];
  value?: TValue;
  onChange?: (value: TValue) => void;
};
export function RadioGroup({
  options,
  value,
  onChange,
}: RadioGroupProps<string>) {
  // we need the name of the input to be able to group the radio buttons
  const inputName = useRef(uuidv4());

  return (
    <Fieldset>
      {options.map(option => (
        <Label key={option.value} data-testid={option['data-testid']}>
          <Input
            value={option.value}
            type="radio"
            name={inputName.current}
            checked={option.value === value}
            onChange={() => onChange?.(option.value)}
            disabled={option.isDisabled}
          />
          {option.label}
        </Label>
      ))}
    </Fieldset>
  );
}
