import { OverviewRoute } from 'integrations/common/navigation/types';
import { Integration } from 'integrations/types';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  openNewTab,
  setVisibleIntegration,
  showSupport,
} from 'integrations/actions';
import { updateIntegrationConfig } from 'integrations/common/streams/integrationConfigs/actions';
import { isIntegrationId } from 'integrations/common/streams/activeIntegrations/utils';
import { importerAvailable } from 'integrations/tabularImporter';
import { ScheduleCommands } from './widgets/schedules/schedulesTable/SchedulesTable';
import { emitOverviewActionEvent } from 'integrations/common/tracking/utils';
import { IntegrationJobPayload, IntegrationSchedule } from '@ardoq/api-types';
import {
  pauseSchedule,
  runSchedule,
  updateSchedule,
} from 'integrations/common/streams/schedules/actions';
import { omit } from 'lodash/fp';
import { startEditScheduleIntervalModal } from 'integrations/common/modals/editScheduleModal/EditScheduleModal';
import { startDeleteScheduleModal } from 'integrations/common/modals/deleteSchedule/DeleteScheduleModal';
import { isJobWithTabularMapping } from 'integrations/common/utils/scheduleApi';
import { getScheduleSourceConfig } from 'integrations/common/streams/schedules/utils';
import {
  IntegrationWorkspace,
  TransferConfig,
} from '@ardoq/api-types/integrations';
import { startSaveModal } from 'integrations/common/modals/saveModal/SaveModal';
import { isIntegrationWithTransferConfigs } from 'integrations/common/streams/transferConfigs/utils';
import { setVisibleAsset } from '../../assets/navigation/actions';
import {
  showScheduleInformation,
  editSchedule,
} from './streams/schedule/actions';
import { showSetAlertsModal } from './widgets/schedules/ShowAlertsModal/AlertsModal';
import { Maybe } from '@ardoq/common-helpers';
import { navigateToOverview } from 'integrations/common/navigation/actions';

interface IntegrationConfig {
  allowedOverviewRoutes: OverviewRoute[];
}

type IntegrationsCommands = {
  openIntegration: (integration: Integration, path?: Maybe<string>) => void;
  showSupport: (message: string) => void;
  openNewTab: (url: string) => void;
  updateIntegrationConfig: (
    integrationId: IntegrationId,
    config: IntegrationConfig
  ) => void;
};

const integrationCommands: IntegrationsCommands = {
  openIntegration: (integration, path) => {
    if (path) {
      return dispatchAction(
        setVisibleIntegration({
          id: integration.id,
          path,
        })
      );
    }
    if (isIntegrationId(integration.id)) {
      return dispatchAction(
        navigateToOverview({ integrationId: integration.id })
      );
    }
    // Integrations without overview/imports
    return dispatchAction(
      setVisibleIntegration({
        id: integration.id,
      })
    );
  },
  showSupport: message => dispatchAction(showSupport({ message: message })),
  openNewTab: url => dispatchAction(openNewTab({ url })),
  updateIntegrationConfig: (integrationId, config) =>
    dispatchAction(
      updateIntegrationConfig({
        integrationId,
        config,
      })
    ),
};

export const onIntegrationClick = (
  integration: Integration,
  path?: Maybe<string>
) => {
  if (
    integration.typescriptImporter?.config &&
    isIntegrationId(integration.id)
  ) {
    integrationCommands.updateIntegrationConfig(
      integration.id,
      integration.typescriptImporter.config
    );
  }
  if (importerAvailable(integration)) {
    return integrationCommands.openIntegration(integration, path);
  }

  if (integration.supportMessage) {
    return integrationCommands.showSupport(integration.supportMessage);
  }

  if (integration.helpUrl) {
    return integrationCommands.openNewTab(integration.helpUrl);
  }
};

export const getScheduleCommands = (): ScheduleCommands => ({
  onShowScheduleInformation: (
    integrationId: IntegrationId,
    schedule: IntegrationSchedule
  ) => {
    dispatchAction(showScheduleInformation({ integrationId, schedule }));
  },
  onEditSchedule: (integrationId: IntegrationId, scheduleId: string) => {
    emitOverviewActionEvent('schedule-edit', integrationId);
    dispatchAction(editSchedule({ integrationId, scheduleId }));
  },
  onRenameSchedule: (
    integrationId: IntegrationId,
    name: string,
    schedule: IntegrationSchedule
  ) => {
    dispatchAction(
      updateSchedule({
        integrationId: integrationId,
        schedule: omit('meta')({
          ...schedule,
          jobOptions: { ...schedule.jobOptions, name },
        }) as IntegrationJobPayload,
      })
    );
  },
  onStartScheduleRenaming: (integrationId: IntegrationId) => {
    emitOverviewActionEvent('schedule-renaming', integrationId);
  },
  onPauseSchedule: (integrationId: IntegrationId, scheduleId: string) => {
    emitOverviewActionEvent('schedule-pause', integrationId);
    dispatchAction(pauseSchedule(scheduleId));
  },
  onRunSchedule: (integrationId: IntegrationId, scheduleId: string) => {
    emitOverviewActionEvent('schedule-import-now', integrationId);
    dispatchAction(runSchedule(scheduleId));
  },
  onStartEditInterval: (
    integrationId: IntegrationId,
    schedule: IntegrationSchedule
  ) => {
    emitOverviewActionEvent('schedule-edit-interval', integrationId);
    startEditScheduleIntervalModal(integrationId, schedule);
  },
  onStartDeleteSchedule: (
    integrationId: IntegrationId,
    schedule: IntegrationSchedule
  ) => {
    emitOverviewActionEvent('schedule-delete', integrationId);
    startDeleteScheduleModal(schedule);
  },
  onStartCreateConfig: (
    integrationId: IntegrationId,
    schedule: IntegrationSchedule
  ) => {
    if (!isJobWithTabularMapping(schedule.jobOptions)) {
      return;
    }
    const sourceConfig = getScheduleSourceConfig(schedule);

    const { tabularMapping, name } = schedule.jobOptions;

    const cleanTabularMapping = Object.fromEntries(
      Object.entries(tabularMapping).filter(
        ([key]) =>
          !['_id', '_version', 'id', 'version', 'lastUpdated'].includes(key)
      )
    );

    const config = {
      ...cleanTabularMapping,
      name,
      sourceConfig,
    } as TransferConfig;

    startSaveModal({
      integrationId,
      title: 'Save as a configuration',
      isNewConfig: false,
      config,
    });
  },
  allowedConfigurations: (integrationId: IntegrationId) =>
    isIntegrationWithTransferConfigs(integrationId),
  onWorkspaceClick: (workspace: IntegrationWorkspace) => {
    if (workspace.id) {
      dispatchAction(
        setVisibleAsset({ assetId: workspace.id, assetType: 'workspace' })
      );
    }
  },
  onShowSetAlerts: () => {
    showSetAlertsModal();
  },
});
