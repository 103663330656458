import React from 'react';
import { Paragraph, caption } from '@ardoq/typography';
import { LabelWithPopoverIcon } from 'integrations/common/components/LabelWithPopoverIcon/LabelWithPopoverIcon';
import { TextInput } from '@ardoq/forms';
import {
  JsonPathTransformation,
  Transformation,
} from '@ardoq/api-types/integrations';
import styled from 'styled-components';
import { colors, s64 } from '@ardoq/design-tokens';
import { ValidationErrors } from 'integrations/common/modals/transformModal/types';
import { FlexBox } from '@ardoq/layout';

type JsonPathTransformationProps = {
  transformation: JsonPathTransformation;
  setTransformation: (newTransformation: Transformation) => void;
  validationErrors: ValidationErrors;
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  setLabel: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const Grey50Wrapper = styled.div`
  ${caption};
  color: ${colors.grey50};
`;

const InputsSection = styled(FlexBox).attrs({ gap: 'medium' })`
  height: ${s64};
`;

const JsonPathTransformationSection = ({
  transformation,
  setTransformation,
  validationErrors,
  setValidationErrors,
  setLabel,
}: JsonPathTransformationProps) => {
  const onChangeJsonPath = (value: string) => {
    setTransformation({
      ...transformation,
      config: {
        ...transformation.config,
        path: value,
      },
    });

    setLabel(`Extract: ${value}`);

    setValidationErrors(prevState => ({
      ...prevState,
      path: !value,
    }));
  };

  return (
    <>
      <Grey50Wrapper>
        Provide the complete JSON path to extract the value.
      </Grey50Wrapper>
      <InputsSection>
        <FlexBox justify="center" padding="small">
          <Paragraph variant="text1">Extract</Paragraph>
        </FlexBox>
        <FlexBox align="start">
          <LabelWithPopoverIcon gap="s8">
            <TextInput
              value={transformation.config.path}
              onValueChange={onChangeJsonPath}
              placeholder="Type JSON path"
              onBlur={() =>
                setValidationErrors(prevState => ({
                  ...prevState,
                  path: !transformation.config.path,
                }))
              }
              errorMessage={
                validationErrors.path ? 'This field is required.' : undefined
              }
            />
          </LabelWithPopoverIcon>
        </FlexBox>
      </InputsSection>
    </>
  );
};

export default JsonPathTransformationSection;
