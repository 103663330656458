import { ApplicablePerspectiveState, PerspectivesEditorState } from '../types';
import { defaultSubquery } from './filtersTabReducers';

export const getApplicablePerspectiveState = (
  perspectiveEditorState: Pick<
    PerspectivesEditorState,
    'formattingTab' | 'groupsTab' | 'availableRelatedData' | 'activeViewId'
  >
): ApplicablePerspectiveState => {
  const {
    formattingTab,
    groupsTab: { groupingRules },
  } = perspectiveEditorState;

  const { labelFormatting, showReferenceType, conditionalFormattingRules } =
    formattingTab;

  const applicablePerspectiveState: ApplicablePerspectiveState = {
    filters: {
      filterByWorkspaceIds: [],
      componentFilterRules: defaultSubquery,
      referenceFilterRules: defaultSubquery,
      dynamicFilterStates: [],
    },
    groupingRules,
    formatting: {
      conditionalFormattingRules,
    },
    labelFormatting: { labelFormatting, showReferenceType },
  };

  return applicablePerspectiveState;
};
