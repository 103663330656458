import { combineLatest, map } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { context$ } from '../context/context$';
import { filters$ } from './filters$';
import {
  collectRelatedComponents,
  collectComponentTypeNamesFromWorkspaces,
  collectRelatedReferences,
  collectReferenceTypeNamesFromWorkspaces,
  collectRelatedTags,
} from './collectRelatedData';
import { FilterAttributes } from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { uniq } from 'lodash';
import { fieldInterface } from '@ardoq/field-interface';
import { PerspectivesRelatedData } from 'perspective/perspectiveEditor/types';
import { perspectiveInterface } from 'modelInterface/perspectives/perspectiveInterface';
import {
  ComponentDefaultFilterKeys,
  ReferenceDefaultFilterKeys,
} from '@ardoq/filter-interface';
import { dateRangeOperations } from '@ardoq/date-range';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import modelUpdateNotification$ from 'modelInterface/modelUpdateNotification$';
import {
  collectRelatedWorkspaces,
  collectRelatedFieldsRecord,
  isStubWorkspace,
} from './collectRelatedUtils';
import {
  ViewpointOptionsSourceData,
  viewpointOptionsSourceData$,
} from './viewpointOptionsSourceData$';
import { loadedCurrentUser$ } from 'streams/currentUser/currentUser$';

const getReferenceTypeNamesFrom = (filters: FilterAttributes[]) => {
  const isReferenceTypeFilter = (filter: FilterAttributes) =>
    filter.name === 'type' && Boolean(filter.affectReference);

  const referenceTypeNames = getAllFiltersValuesMatching(
    filters,
    isReferenceTypeFilter
  );
  return uniq(referenceTypeNames) as string[];
};

const getComponentTypeNamesFrom = (filters: FilterAttributes[]): string[] => {
  const isComponentTypeFilter = (filter: FilterAttributes) =>
    filter.name === 'type' && Boolean(filter.affectComponent);

  const typeNames = getAllFiltersValuesMatching(filters, isComponentTypeFilter);
  return uniq(typeNames) as string[];
};

const getStateDateAndEndDateCustomFieldForEachDateRangeCustomField = (
  customFieldName: string
) => {
  const dateRangeFieldName =
    dateRangeOperations.extractDateRangeFieldName(customFieldName);
  return [
    dateRangeOperations.toStartDateName(dateRangeFieldName),
    dateRangeOperations.toEndDateName(dateRangeFieldName),
  ];
};

/**
 * We try to identify all custom fields used in saved perspective's formatting
 * and filtering rules and include them in relatedData. This way the formatting
 * and filtering rules based on those custom fields can be properly displayed even
 * in case those custom fields are not available among currently opened workspaces.
 *
 * @param filters advanced filters from saved perspective
 * @param isCustomFieldFilter predicate testing if a filter refers to the custom field
 */
const getCustomFieldsFrom = (
  filters: FilterAttributes[],
  isCustomFieldFilter: (filter: FilterAttributes) => boolean
) => {
  const customFieldNames = getAllFiltersMatching(filters, isCustomFieldFilter)
    .map(filter => filter.name)
    .filter(ExcludeFalsy);

  /**
   * We need this to be able to construct 3 types of date range custom fields
   * (start date, end date and range) for formatting rules and 2 types for filtering rules
   * (start date, end date and range).
   *
   * Date range custom fields are modeled as a pair of custom fields
   * - one for the start date, the other for the end date.
   * The type for those fields is both 'DateTime', so the only way to recognise
   * if that's a date range field is to check if the name contains proper suffix.
   *
   * It definitely might be the case that user has created the formatting or filtering
   * rule only for the start date or only for end date.
   * That's why we are recreating the second half just in case so that Perspective editor
   * have a valid date range custom field with both start date part and end date part.
   */
  const startDateAndEndDateForDateRangeFields = customFieldNames
    .filter(name => dateRangeOperations.fieldNameIsPartOfDateRangeField(name))
    .flatMap(getStateDateAndEndDateCustomFieldForEachDateRangeCustomField);

  const componentFields = uniq([
    ...customFieldNames,
    ...startDateAndEndDateForDateRangeFields,
  ])
    .map(fieldName =>
      fieldInterface.getByName(fieldName, {
        acrossWorkspaces: true,
        includeTemplateFields: false,
      })
    )
    .filter(ExcludeFalsy)
    .map(field => fieldInterface.pickGlobalFieldAttributes(field));
  return componentFields;
};

const getReferenceFieldsFrom = (advancedFilters: FilterAttributes[]) => {
  const referenceFilterKeys: string[] = Object.values(
    ReferenceDefaultFilterKeys
  );
  const isCustomReferenceFieldFilter = (filter: FilterAttributes) =>
    !referenceFilterKeys.includes(filter.name ?? '') &&
    Boolean(filter.affectReference);

  return getCustomFieldsFrom(advancedFilters, isCustomReferenceFieldFilter);
};

const getComponentFieldsFrom = (advancedFilters: FilterAttributes[]) => {
  const componentFilterKeys: string[] = Object.values(
    ComponentDefaultFilterKeys
  );
  const isCustomComponentFieldFilter = (filter: FilterAttributes) =>
    !componentFilterKeys.includes(filter.name ?? '') &&
    Boolean(filter.affectComponent);

  return getCustomFieldsFrom(advancedFilters, isCustomComponentFieldFilter);
};
/**
 * It might be the case that the data from saved perspective depends on the workspaces that are not loaded,
 * e.g. has filtering/formatting rules dependent on custom fields that is not available in currently opened workspaces.
 * We pre-load some data that is available in ardoq-front.
 */
const loadDataFomSavedPerspective = () => {
  const selectedSavedPerspectiveId =
    perspectiveInterface.getSelectedSavedPerspectiveId();
  const savedPerspective = perspectiveInterface.getById(
    selectedSavedPerspectiveId
  );
  if (!savedPerspective) {
    return {
      referenceTypeNames: [],
      componentTypeNames: [],
      componentFields: [],
      referenceFields: [],
    };
  }

  const advancedFilters = savedPerspective.filters.advancedFilters;

  return {
    referenceTypeNames: getReferenceTypeNamesFrom(advancedFilters),
    componentTypeNames: getComponentTypeNamesFrom(advancedFilters),
    componentFields: getComponentFieldsFrom(advancedFilters),
    referenceFields: getReferenceFieldsFrom(advancedFilters),
  };
};

type FilterAttributesWithNestedRules = FilterAttributes & {
  rules: FilterAttributes[];
};

/**
 * This calls a recursive function that finds all filters satisfying given predicate, including nested filters.
 */
const getAllFiltersValuesMatching = (
  advancedFilters: FilterAttributes[],
  predicate: (advancedFilters: FilterAttributes) => boolean
): NonNullable<FilterAttributes['value']>[] => {
  return getAllFiltersMatching(advancedFilters, predicate)
    .map(filter => filter.value)
    .filter(ExcludeFalsy);
};

/**
 * This is a recursive function that finds all filters satisfying given predicate, including nested filters.
 */
const getAllFiltersMatching = (
  advancedFilters: FilterAttributes[],
  predicate: (advancedFilters: FilterAttributes) => boolean
): FilterAttributes[] => {
  const filterAttributesOnCurrentLevel = advancedFilters.filter(predicate);

  const nextedFilterAttributes = advancedFilters
    .filter(
      (filter): filter is FilterAttributesWithNestedRules =>
        (filter.rules?.length ?? 0) > 0
    )
    .flatMap(nestedRules =>
      getAllFiltersMatching(nestedRules.rules, predicate)
    );

  return [...filterAttributesOnCurrentLevel, ...nextedFilterAttributes];
};

const getStubWorkspacesByIds = (workspaceIds: string[]) =>
  workspaceIds
    .map(id =>
      workspaceInterface.getAttributes(id, ['_id', 'name', 'componentModel'])
    )
    .filter(isStubWorkspace);

export const getViewpointFormattingOptionsSourceData = ({
  workspaceIds,
  componentTypeNames,
  referenceTypeNames,
  availableComponentFields,
  availableReferenceFields,
  tags,
}: ViewpointOptionsSourceData) => {
  const relatedTags = tags
    .filter(tag => workspaceIds.includes(tag.rootWorkspace))
    .map(({ _id, name, rootWorkspace }) => ({
      _id,
      name,
      rootWorkspace,
    }));

  const availableComponentFieldsRecord = Object.fromEntries(
    availableComponentFields.map(f => [f.name, f])
  );
  const availableReferenceFieldsRecord = Object.fromEntries(
    availableReferenceFields.map(f => [f.name, f])
  );

  const relatedFieldsByName = {
    ...availableComponentFieldsRecord,
    ...availableReferenceFieldsRecord,
  };

  const relatedWorkspaces = getStubWorkspacesByIds(workspaceIds);

  return {
    relatedWorkspaces,
    componentTypeNames,
    referenceTypeNames,
    relatedTags,
    relatedFieldsByName,
    connectedWorkspaceReferenceFields: availableReferenceFields,
    connectedWorkspaceComponentFields: availableComponentFields,
  };
};

/**
 * Provides connected workspaces to the current context, currently opened workspace
 */
export const perspectivesRelatedData$ = combineLatest([
  context$,
  viewpointOptionsSourceData$,
  loadedCurrentUser$,
  modelUpdateNotification$.pipe(startWith(null)),
  filters$,
]).pipe(
  map(
    ([
      contextState,
      viewpointOptionsSourceData,
      currentUser,
    ]): PerspectivesRelatedData => {
      const organizationId = currentUser.organization._id;

      if (viewpointOptionsSourceData) {
        return {
          organizationId,
          currentWorkspaceId: null,
          isScenarioMode: Boolean(contextState.scenarioId),
          relatedComponents: [],
          relatedReferences: [],
          ...getViewpointFormattingOptionsSourceData(
            viewpointOptionsSourceData
          ),
        };
      }

      const {
        referenceTypeNames: referenceTypeNamesForLoadedSavedPerspective,
        componentTypeNames: componentTypeNamesForLoadedSavedPerspective,
        componentFields: componentFieldsForLoadedSavedPerspective,
        referenceFields: referenceFieldsForLoadedSavedPerspective,
      } = loadDataFomSavedPerspective();

      // Loaded (visible in the navigator) and linked workspaces.
      const workspaceIds = contextState.workspacesIds;
      const isScenarioMode = Boolean(contextState.scenarioId);
      const relatedWorkspaces = collectRelatedWorkspaces(workspaceIds);
      const relatedWorkspaceIds = relatedWorkspaces.map(({ _id }) => _id);

      const relatedReferences = collectRelatedReferences();
      const referenceTypeNames =
        collectReferenceTypeNamesFromWorkspaces(relatedWorkspaceIds);

      const relatedComponents = collectRelatedComponents();
      const componentTypeNames =
        collectComponentTypeNamesFromWorkspaces(relatedWorkspaceIds);

      const { componentFields, referenceFields, relatedFieldsByName } =
        collectRelatedFieldsRecord(relatedWorkspaces);

      return {
        organizationId,
        currentWorkspaceId: contextState.workspaceId,
        isScenarioMode,
        relatedWorkspaces,
        relatedComponents,
        relatedReferences,
        referenceTypeNames: [
          ...referenceTypeNames,
          ...referenceTypeNamesForLoadedSavedPerspective,
        ],
        relatedTags: collectRelatedTags(),
        // This is a mix, it has all fields by name and workspace specific
        // configurations for all related workspaces.
        relatedFieldsByName,
        connectedWorkspaceReferenceFields: [
          ...referenceFields,
          ...referenceFieldsForLoadedSavedPerspective,
        ],
        connectedWorkspaceComponentFields: [
          ...componentFields,
          ...componentFieldsForLoadedSavedPerspective,
        ],
        componentTypeNames: [
          ...componentTypeNames,
          ...componentTypeNamesForLoadedSavedPerspective,
        ],
      };
    }
  )
);
