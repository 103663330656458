import { TraversalBuilderState } from '../types';
import { ReferenceDirection } from '@ardoq/api-types';
import { GraphEdge, GraphNode } from '@ardoq/graph';
import { logError } from '@ardoq/logging';
import { getTripleId } from './getTripleId';

type AddNodeAndEdgeArgs = {
  graphNodeId: string;
  tripleId: string;
  direction: ReferenceDirection;
  state: TraversalBuilderState;
  graphNodes: TraversalBuilderState['graphNodes'];
  graphEdges: TraversalBuilderState['graphEdges'];
  referenceMap: TraversalBuilderState['selectedGraph']['referenceMap'];
  getId: () => string;
  instanceCountsOption: number | null;
  shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings: boolean;
};

export const addNodeAndEdge = ({
  graphNodeId,
  tripleId,
  direction,
  state,
  graphNodes,
  graphEdges,
  referenceMap,
  getId,
  instanceCountsOption,
  shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings,
}: AddNodeAndEdgeArgs) => {
  const { sourceId, targetId, referenceId } = state.triples.get(tripleId)!;
  const componentId =
    direction === ReferenceDirection.OUTGOING ? targetId : sourceId;
  const { metaData } = state.rawGraphNodes.get(componentId)!;
  const newGraphNode = GraphNode.createWithMetaData(
    componentId,
    false,
    getId(),
    {
      ...metaData,
      shouldDisplayClickOtherComponentsHint:
        shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings,
      labelWithCount:
        instanceCountsOption === null
          ? undefined
          : `${metaData.label} (${instanceCountsOption})`,
    }
  );
  const graphEdgeToReferenceMapEntry =
    getGraphEdgeToReferenceMapEntry(graphNodes);

  graphNodes.set(newGraphNode.id, newGraphNode);
  const { metaData: edgeMetaData } = state.rawGraphEdges.get(referenceId)!;
  const graphEdge = GraphEdge.createWithMetaData(
    referenceId,
    direction === ReferenceDirection.OUTGOING ? graphNodeId : newGraphNode.id,
    direction === ReferenceDirection.OUTGOING ? newGraphNode.id : graphNodeId,
    graphNodes,
    getId(),
    edgeMetaData
  );
  graphEdges.set(graphEdge.id, graphEdge);
  referenceMap.set(graphEdge.id, graphEdgeToReferenceMapEntry(graphEdge));
  return { graphEdge, graphNode: newGraphNode };
};

export const getGraphEdgeToReferenceMapEntry =
  (graphNodes: Map<string, GraphNode>) => (graphEdge: GraphEdge) => {
    const { source, target, modelId } = graphEdge;
    const sourceGraphNode = graphNodes.get(source);
    const targetGraphNode = graphNodes.get(target);

    if (!(sourceGraphNode && targetGraphNode)) {
      // It's not expected that we land in this branch, but we need to follow
      // TypeScript. If we see errors from here then we will have to
      // investigate.
      logError(
        Error(
          'Missing source or target graph nodes in getGraphEdgeToReferenceMapEntry'
        )
      );
      return {
        sourceId: 'unknown-id',
        targetId: 'unknown-id',
        tripleId: 'unknown-id',
      };
    }
    return {
      sourceId: source,
      targetId: target,
      tripleId: getTripleId({
        referenceId: modelId,
        sourceId: sourceGraphNode.modelId,
        targetId: targetGraphNode.modelId,
      }),
    };
  };
