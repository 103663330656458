import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { qualitativeTheme } from '@ardoq/color-picker';
import {
  selectDashboardColorTheme,
  setDashboardColorThemes,
  setIsDashboardColorThemesLoading,
} from './actions';
import { ArdoqId, APIDashboardColorTheme } from '@ardoq/api-types';

export const DEFAULT_DASHBOARD_COLOR_THEME = {
  isSelected: true,
  ...qualitativeTheme,
  label: 'Default qualitative theme',
  isDefaultTheme: true,
  _id: 'default',
};

export type DashboardColorThemes$State = {
  isLoading: boolean;
  dashboardColorThemes: APIDashboardColorTheme[];
  savedSelectedId: ArdoqId | null;
};

const defaultState: DashboardColorThemes$State = {
  isLoading: true,
  dashboardColorThemes: [],
  savedSelectedId: null,
};

const handleSetDashboardColorThemes = (
  state: DashboardColorThemes$State,
  dashboardColorThemes: APIDashboardColorTheme[]
): DashboardColorThemes$State => {
  return {
    ...state,
    dashboardColorThemes: dashboardColorThemes.sort((a, b) =>
      a.created! < b.created! ? -1 : 1
    ),
    savedSelectedId:
      dashboardColorThemes.find(({ isSelected }) => isSelected)?._id ??
      DEFAULT_DASHBOARD_COLOR_THEME._id,
  };
};

const handleSetIsDashboardColorThemesLoading = (
  state: DashboardColorThemes$State,
  isLoading: boolean
) => ({ ...state, isLoading });

const handleSelectDashboardColorTheme = (
  state: DashboardColorThemes$State,
  selectedDashboardId: string
) => ({
  ...state,
  dashboardColorThemes: state.dashboardColorThemes.map(dashboardColorTheme => ({
    ...dashboardColorTheme,
    isSelected: dashboardColorTheme._id === selectedDashboardId,
  })),
});
const dashboardColorThemes$ = persistentReducedStream(
  'dashboardColorThemes$',
  defaultState,
  [
    reducer(setDashboardColorThemes, handleSetDashboardColorThemes),
    reducer(
      setIsDashboardColorThemesLoading,
      handleSetIsDashboardColorThemesLoading
    ),
    reducer(selectDashboardColorTheme, handleSelectDashboardColorTheme),
  ]
);

export default dashboardColorThemes$;
