import { ModalContent, ModalSize, ModalTemplate } from '@ardoq/modal';
import { BroadcastContent } from '@ardoq/api-types';
import { useEffectOnce } from '@ardoq/hooks';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { ComponentPreviewDialogStreamShape } from './types';
import { fetchPreviewComponents } from './actions';
import componentPreviewDialog$ from './componentPreviewDialog$';
import { ComponentPreviewDialogContent } from './ComponentPreviewDialogContent';
import { ErrorContent } from './ErrorContent';

type ComponentPreviewDialogProps = {
  readonly content: BroadcastContent;
} & ComponentPreviewDialogStreamShape &
  ModalContent;

const ComponentPreviewDialog = ({
  isLoading,
  hasError,
  components,
  total,
  content,
  resolve,
}: ComponentPreviewDialogProps) => {
  useEffectOnce(() => dispatchAction(fetchPreviewComponents(content)));
  return (
    <ModalTemplate
      headerText="Component preview"
      modalSize={ModalSize.M}
      fixedHeight
      secondaryButtonText="Close"
      onSecondaryButtonClick={() => resolve(false)}
    >
      {hasError ? (
        <ErrorContent />
      ) : (
        <ComponentPreviewDialogContent
          components={components}
          total={total}
          isLoading={isLoading}
        />
      )}
    </ModalTemplate>
  );
};

export default connect(ComponentPreviewDialog, componentPreviewDialog$);
