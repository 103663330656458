import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { Box } from '@ardoq/layout';
import {
  getTokensTableColumns,
  getTokensTableDataSource,
} from './tokensTableUtils';
import { APIToken } from '@ardoq/api-types';
import { TokenViewCommands } from './commands';
import { PaginationBar, PaginationController } from '@ardoq/pagination';
import { useState } from 'react';

const TokensTable = ({
  commands,
  tokens,
  isOrgTokens,
}: {
  tokens: APIToken[];
  commands: TokenViewCommands;
  isOrgTokens: boolean;
}) => {
  const [perPage, setPerPage] = useState(10);

  return (
    <PaginationController
      dataSource={getTokensTableDataSource({ tokens, commands, isOrgTokens })}
      perPage={perPage}
      render={({
        currentPageDataSource,
        currentPageNumber,
        perPage,
        totalResults,
        onPageSelect,
      }) => (
        <Box>
          <DatasourceTable
            dataSource={currentPageDataSource}
            components={newTableTheme}
            columns={getTokensTableColumns()}
            renderEmptyTable={{ title: 'No results' }}
          />
          {perPage < totalResults && (
            <PaginationBar
              style={{ justifyContent: 'center', padding: '20px' }}
              currentPageNumber={currentPageNumber}
              perPage={perPage}
              totalResults={totalResults}
              onChange={({
                currentPageNumber: newPageNumber,
                perPage: newPerPage,
              }) => {
                setPerPage(newPerPage);
                onPageSelect(newPageNumber);
              }}
            />
          )}
        </Box>
      )}
    />
  );
};

export default TokensTable;
