import * as dynamicFilterService from 'services/dynamicFilterServiceTC';
import { perspectiveInterface } from 'modelInterface/perspectives/perspectiveInterface';
import {
  applyPerspective,
  clearPerspectiveEditor,
  createNewPerspective,
  notifyCreateNewPerspectiveFailed,
  notifyCreateNewPerspectiveFinished,
  deletePerspective,
  notifyDeleteSavedPerspectiveFailed,
  notifyDeletingPerspectiveFinished,
  fetchDynamicFilterOptions,
  renameSavedPerspective,
  notifyRenameSavedPerspectiveFailed,
  notifyRenameSavedPerspectiveFinished,
  saveAsNewPerspective,
  notifySaveAsNewPerspectiveFailed,
  notifySaveAsNewPerspectiveFinished,
  savePerspective,
  notifySavePerspectiveFailed,
  notifySavePerspectiveFinished,
  selectSavedPerspective,
  toggleDynamicFilterActive as perspectiveEditorToggleDynamicFilterActiveAction,
  selectDynamicFilterOption as perspectiveEditorSelectDynamicFilterOptionAction,
  notifyPerspectiveCleared,
  notifyPerspectiveApplied,
} from '../../perspective/actions';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs/operators';
import { LegacyApplicablePerspectiveState } from 'perspective/perspectiveEditor/types';
import {
  applyConditionalFormatting,
  applyFormatting,
  applyLabelFormatting,
} from './applyFormatting';
import { applyGroupingRules } from './applyGroupingRules';
import { filterInterface } from '@ardoq/filter-interface';
import { getFormattedSavedPerspectives } from './utils';
import { context$ } from 'streams/context/context$';
import { mapSavedPerspectiveToPerspectiveEditorSavedPerspectiveState } from './mapSavedPerspectiveToPerspectiveEditorSavedPerspectiveState';
import { logError } from '@ardoq/logging';
import { requestOptionsDataFetch } from './actions';
import { metaModelAsScopeData$ } from 'viewpointBuilder/metaModel/loadMetaModelAsScopeData$';
import { requestLoadMetaModel } from 'architectureModel/actions';
import tags$ from '../tags/tags$';
import { fetchTags } from '../tags/actions';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { ApplicablePerspectiveState } from 'perspectiveSidebar/perspectiveEditor/types';

const logErrorWithDefaultMessage = (thrown: any, defaultMessage: string) => {
  const error =
    thrown instanceof Error ? thrown : new Error(defaultMessage, thrown);
  logError(error);
};

export const applyPerspectiveData = (
  perspectiveState:
    | LegacyApplicablePerspectiveState
    | ApplicablePerspectiveState
) => {
  filterInterface.applyPerspectiveFilters(perspectiveState.filters);
  applyGroupingRules(perspectiveState.groupingRules);

  if (
    loadedGraph$.state.isViewpointMode &&
    'labelFormatting' in perspectiveState
  ) {
    const {
      labelFormatting: labelFormattingConfig,
      formatting: { conditionalFormattingRules },
    } = perspectiveState;
    const { labelFormatting, showReferenceType } = labelFormattingConfig;
    applyLabelFormatting(labelFormatting, showReferenceType);
    applyConditionalFormatting(conditionalFormattingRules);
  } else {
    applyFormatting(perspectiveState.formatting);
  }

  dispatchAction(notifyPerspectiveApplied());
};

const handleApplyPerspective = routine(
  ofType(applyPerspective),
  extractPayload(),
  tap(perspectiveState => {
    applyPerspectiveData(perspectiveState);
  })
);

const handleSavePerspective = routine(
  ofType(savePerspective),
  extractPayload(),
  tap(async ({ perspectiveId, applicablePerspectiveState }) => {
    applyPerspectiveData(applicablePerspectiveState);
    try {
      await perspectiveInterface.saveExistingPerspective(perspectiveId);
      dispatchAction(notifySavePerspectiveFinished());
    } catch (error: any) {
      logErrorWithDefaultMessage(
        error,
        'Unknown error when saving perspective'
      );
      dispatchAction(
        notifySavePerspectiveFailed({
          errorMessage: 'Saving perspective failed',
        })
      );
    }
  })
);

const handleSelectSavedPerspective = routine(
  ofType(selectSavedPerspective),
  extractPayload(),
  tap(savedPerspectiveId => {
    perspectiveInterface.loadSavedPerspective(savedPerspectiveId);
  })
);

const handleClearPerspective = routine(
  ofType(clearPerspectiveEditor),
  tap(() => perspectiveInterface.clearPerspectives())
);

const handleRenameSavedPerspective = routine(
  ofType(renameSavedPerspective),
  extractPayload(),
  tap(async ({ newSavedPerspectiveName, selectedSavedPerspectiveId }) => {
    try {
      await perspectiveInterface.renameSavedPerspective(
        newSavedPerspectiveName,
        selectedSavedPerspectiveId
      );

      const savedPerspectives = perspectiveInterface.getAll();
      const savedPerspectivesPermissions =
        perspectiveInterface.getSavedPerspectivesPermissions();
      const formattedSavedPerspectives = getFormattedSavedPerspectives({
        savedPerspectives,
        savedPerspectivesPermissions,
      });

      dispatchAction(
        notifyRenameSavedPerspectiveFinished({
          savedPerspectives: formattedSavedPerspectives,
        })
      );
    } catch (error: any) {
      logErrorWithDefaultMessage(
        error,
        'Unknown error when renaming perspective'
      );
      dispatchAction(
        notifyRenameSavedPerspectiveFailed({
          errorMessage: 'Renaming saved perspective failed',
        })
      );
    }
  })
);

const handleDeletePerspective = routine(
  ofType(deletePerspective),
  extractPayload(),
  tap(async perspectiveId => {
    try {
      await perspectiveInterface.removePerspective(perspectiveId);

      dispatchAction(notifyDeletingPerspectiveFinished());
    } catch (error: any) {
      logErrorWithDefaultMessage(
        error,
        'Unknown error when deleting perspective'
      );
      dispatchAction(
        notifyDeleteSavedPerspectiveFailed({
          errorMessage: 'Deleting perspective failed',
        })
      );
    }
  })
);

const handleFetchDynamicFilterOptions = routine(
  ofType(fetchDynamicFilterOptions),
  extractPayload(),
  tap(parameterQueryId => {
    dynamicFilterService.fetchDynamicFilterOptions(parameterQueryId);
  })
);

const handleSelectDynamicFilterOption = routine(
  ofType(perspectiveEditorSelectDynamicFilterOptionAction),
  extractPayload(),
  tap(({ graphFilterId, paramName, paramValue }) => {
    dynamicFilterService.selectDynamicFilterOption(
      graphFilterId,
      paramName,
      paramValue
    );
  })
);

const handleToggleDynamicFilterActive = routine(
  ofType(perspectiveEditorToggleDynamicFilterActiveAction),
  extractPayload(),
  tap(({ filterId, previouslyActive }) => {
    dynamicFilterService.toggleDynamicFilterActive(filterId, previouslyActive);
  })
);

const handleSaveAsNewPerspective = routine(
  ofType(saveAsNewPerspective),
  extractPayload(),
  tap(async ({ newPerspectiveName, applicablePerspectiveState }) => {
    applyPerspectiveData(applicablePerspectiveState);
    try {
      await perspectiveInterface.saveAsNewPerspective(newPerspectiveName);
      dispatchAction(notifySaveAsNewPerspectiveFinished());
    } catch (error: any) {
      logErrorWithDefaultMessage(
        error,
        'Unknown thrown when saving perspective as a new one'
      );
      dispatchAction(
        notifySaveAsNewPerspectiveFailed({
          errorMessage: 'Saving perspective as a new one failed',
        })
      );
    }
  })
);

const handleCreateNewPerspective = routine(
  ofType(createNewPerspective),
  extractPayload(),
  withLatestFrom(context$),
  tap(
    async ([
      { perspectiveName, applicablePerspectiveState },
      { workspaceId },
    ]) => {
      applyPerspectiveData(applicablePerspectiveState);
      try {
        const newPerspective =
          await perspectiveInterface.createNewPerspective(perspectiveName);

        const savedPerspectives = perspectiveInterface.getAll();
        const savedPerspectivesPermissions =
          perspectiveInterface.getSavedPerspectivesPermissions();
        const isOnlyReaderRole =
          !workspaceInterface.hasWriteAccess(workspaceId);
        const formattedSavedPerspectives = getFormattedSavedPerspectives({
          savedPerspectives,
          savedPerspectivesPermissions,
        });

        dispatchAction(
          notifyCreateNewPerspectiveFinished({
            savedPerspectives: formattedSavedPerspectives,
            selectedSavedPerspectiveState:
              mapSavedPerspectiveToPerspectiveEditorSavedPerspectiveState(
                newPerspective.attributes
              ),
            isOnlyReaderRole,
          })
        );
      } catch (error: any) {
        logErrorWithDefaultMessage(
          error,
          'Unknown thrown when creating new perspective'
        );
        dispatchAction(
          notifyCreateNewPerspectiveFailed({
            errorMessage: 'Creating new perspective failed',
          })
        );
      }
    }
  )
);

const handleClearPerspectiveInTheMainApp = routine(
  ofType(clearPerspectiveEditor),
  extractPayload(),
  tap(() => {
    perspectiveInterface.clearPerspectives();
    dispatchAction(notifyPerspectiveCleared());
  })
);

const handleViewpointModeOptionsDataRequested = routine(
  ofType(requestOptionsDataFetch),
  withLatestFrom(metaModelAsScopeData$, tags$),
  tap(([_, metaModelAsScopeData, tags]) => {
    // Fetch metaModel if it was not fetched
    if (!('data' in metaModelAsScopeData)) {
      dispatchAction(requestLoadMetaModel({ currentTriples: [] }));
    }

    if (
      !(tags.fetchingStatus === 'success' || tags.fetchingStatus === 'loading')
    ) {
      dispatchAction(fetchTags());
    }
  })
);

export default collectRoutines(
  handleApplyPerspective,
  handleClearPerspective,
  handleClearPerspectiveInTheMainApp,
  handleDeletePerspective,
  handleSelectSavedPerspective,
  handleSavePerspective,
  handleRenameSavedPerspective,
  handleSaveAsNewPerspective,
  handleCreateNewPerspective,
  handleToggleDynamicFilterActive,
  handleSelectDynamicFilterOption,
  handleFetchDynamicFilterOptions,
  handleViewpointModeOptionsDataRequested
);
