import { Space } from '@ardoq/style-helpers';
import { Feedback } from 'bestPracticeAssistant/types';
import { ButtonSize, IconButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';

export type FeedbackButtonsProps = {
  submitPositive: () => void;
  submitNegative: () => void;
  submitted?: Feedback;
};

const FeedbackButtons = ({
  submitted,
  submitPositive,
  submitNegative,
}: FeedbackButtonsProps) => (
  <Space $gap="none" $justify="flex-end" $flex={1}>
    <IconButton
      iconName={IconName.THUMB_UP}
      buttonSize={ButtonSize.SMALL}
      data-tooltip-text="It was helpful"
      onClick={() => submitPositive()}
      iconColor={submitted === 'good' ? colors.brand60 : undefined}
    />
    <IconButton
      iconName={IconName.THUMB_DOWN}
      buttonSize={ButtonSize.SMALL}
      data-tooltip-text="It was not helpful"
      onClick={() => submitNegative()}
      iconColor={submitted === 'bad' ? colors.brand60 : undefined}
    />
  </Space>
);

export default FeedbackButtons;
