import { tap } from 'rxjs';
import { alert } from '@ardoq/modal';
import { loadMetaModel$ } from './loadMetaModel';
import { isAutomatedTestMode } from 'appConfig';
import { isArdoqError } from '@ardoq/common-helpers';

export const metaModel$ = loadMetaModel$.pipe(
  tap(async result => {
    if (isArdoqError(result) && !isAutomatedTestMode()) {
      await alert({
        title: 'Loading meta model failed',
        subtitle: 'Loading the meta model failed.',
        text: '',
        confirmButtonTitle: 'Close',
      });
    }
  })
);
