import {
  getGraphNodeOnHover,
  getToggleGraphNodeSelection,
} from './toggleGraphNodeSelection';
import { viewpointBuilderCommands } from 'viewpointBuilder/viewpointBuilderCommands';

const toggleGraphNodeSelectionInMetamodelView = getToggleGraphNodeSelection(
  viewpointBuilderCommands.metamodelViewCommands
);

const toggleGraphNodeSelectionInTraversalEdit = getToggleGraphNodeSelection(
  viewpointBuilderCommands.editTraversalCommands
);

const toggleGraphNodeSelectionInFilters = getToggleGraphNodeSelection(
  viewpointBuilderCommands.filterCommands
);

const collapsePathsNodeSelection = getToggleGraphNodeSelection(
  viewpointBuilderCommands.collapsePathsCommands
);

const requiredComponentsNodeSelection = getToggleGraphNodeSelection(
  viewpointBuilderCommands.requiredComponentsCommands
);

const collapsedPathsGraphNodeOnHover = getGraphNodeOnHover(
  viewpointBuilderCommands.collapsePathsCommands
);

const requiredComponentsGraphNodeOnHover = getGraphNodeOnHover(
  viewpointBuilderCommands.requiredComponentsCommands
);

export const viewpointBuilderEventListeners = {
  toggleGraphNodeSelectionInMetamodelView,
  toggleGraphNodeSelectionInTraversalEdit,
  toggleGraphNodeSelectionInFilters,
  collapsePathsNodeSelection,
  requiredComponentsNodeSelection,
  collapsedPathsGraphNodeOnHover,
  requiredComponentsGraphNodeOnHover,
};
