import { colors } from '@ardoq/design-tokens';
import styled from 'styled-components';

export const Body = styled.div`
  height: 1500px;
  max-height: 80vh;
  overflow: hidden;
`;

export const ClickableName = styled.div`
  display: inline-block;
  line-height: 1em;
  padding-top: 8px;
  cursor: pointer;

  &:hover {
    color: ${colors.grey25};
    text-decoration: underline;
  }
`;
