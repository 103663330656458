import _ from 'lodash/fp';
import { InfoNotification } from '@ardoq/status-ui';
import { isTransferEffectsEmpty } from 'integrations/common/streams/transferState/utils';
import {
  TableMapping,
  TableMappingType,
  IntegrationWorkspace,
  TransferEffectType,
  TransferEffectSummary,
  TransferEffectSummaries,
  AffectedEntityType,
} from '@ardoq/api-types/integrations';

type TransferEffectsPanelProps = {
  mapping: Pick<
    TableMapping,
    'rootWorkspace' | 'targetWorkspace' | 'rowRepresentation'
  >;
  transferEffects: TransferEffectSummaries;
};

export const EffectPanel = ({
  mapping,
  transferEffects,
}: TransferEffectsPanelProps) => {
  if (isTransferEffectsEmpty(transferEffects)) {
    return null;
  }

  return (
    <InfoNotification>
      <PanelHeader mapping={mapping} />
      <EffectList effects={transferEffects} />
    </InfoNotification>
  );
};

const PanelHeader = ({
  mapping: { rootWorkspace, targetWorkspace, rowRepresentation },
}: Pick<TransferEffectsPanelProps, 'mapping'>) => {
  const inWorkspace = (
    <>
      In {rootWorkspace.id ? '' : 'new '}workspace {rootWorkspace.name}
    </>
  );
  const displayableTargetWorkspace =
    rowRepresentation === TableMappingType.REFERENCES &&
    targetWorkspace &&
    !sameWorkspaces(rootWorkspace, targetWorkspace);

  const toWorkspace = displayableTargetWorkspace ? (
    <>
      To {targetWorkspace?.id ? '' : 'new '}workspace {targetWorkspace?.name}
    </>
  ) : null;

  return (
    <>
      {inWorkspace}
      {toWorkspace}
    </>
  );
};

const EffectList = ({ effects }: { effects: TransferEffectSummaries }) => {
  return (
    <div>
      {_.toPairs(effects).map(([type, summary]) => (
        <TransferEffect
          key={type}
          type={type as TransferEffectType}
          summary={summary}
        />
      ))}
    </div>
  );
};

const TransferEffect = ({
  type,
  summary,
}: {
  type: TransferEffectType;
  summary: TransferEffectSummary;
}) => {
  const suffix =
    type === 'updated'
      ? 'to update'
      : type === 'deleted'
        ? 'to delete'
        : 'to create';
  const messages = _.toPairs(summary)
    .filter(([_, quantity]) => quantity > 0)
    .map(([affectedEntityType, quantity]) => {
      const affectedEntityName =
        quantity === 1
          ? singularizedAffectedEntityType[
              affectedEntityType as AffectedEntityType
            ]
          : affectedEntityType;

      return (
        <div key={`${type},${affectedEntityType}`}>
          {quantity} {affectedEntityName} {suffix}
        </div>
      );
    });
  return <>{messages}</>;
};

const sameWorkspaces = (ws1: IntegrationWorkspace, ws2: IntegrationWorkspace) =>
  ws1.id === ws2.id || _.isEqual(ws1, ws2) || ws1.name === ws2.name;

const singularizedAffectedEntityType: Record<AffectedEntityType, string> = {
  tags: 'tag',
  referenceTypes: 'referenceType',
  fields: 'field',
  references: 'reference',
  models: 'model',
  components: 'component',
  componentTypes: 'componentType',
  workspaces: 'workspace',
  listFieldValues: 'listFieldValue',
};
