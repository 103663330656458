import {
  Checkbox,
  CheckboxGroup,
  FormSize,
  FormWrapper,
  TextInput,
} from '@ardoq/forms';
import { ModalLayout, ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { useState } from 'react';

export type CopyWsModalResult = {
  newName: string;
  copyComponents: boolean;
  copyReferences: boolean;
  copyCrossWorkspaceReferences: boolean;
};
type CopyWsDialogProps = {
  name: string;
  onConfirm: (
    newName: string,
    copyComponents: boolean,
    copyReferences: boolean,
    copyCrossWorkspaceReferences: boolean
  ) => void;
  onCancel: () => void;
};

const CopyWsDialog = ({ name, onConfirm, onCancel }: CopyWsDialogProps) => {
  const [newName, setNewName] = useState<string>(`${name} (copy)`);
  const [copyComponents, setCopyComponents] = useState<boolean>(true);
  const [copyReferences, setCopyReferences] = useState<boolean>(true);
  const [copyCrossWorkspaceReferences, setCopyCrossWorkspaceReferences] =
    useState<boolean>(true);
  return (
    <ModalTemplate
      modalSize={ModalSize.S}
      headerText="Copy workspace"
      onPrimaryButtonClick={() => {
        onConfirm(
          newName,
          copyComponents,
          copyComponents && copyReferences,
          copyComponents && copyReferences && copyCrossWorkspaceReferences
        );
      }}
      isPrimaryButtonDisabled={!newName.trim()}
      onSecondaryButtonClick={onCancel}
      primaryButtonText="Create copy"
      secondaryButtonText="Cancel"
    >
      <ModalLayout>
        <FormWrapper $formSize={FormSize.AUTO}>
          <TextInput
            label="New workspace name"
            value={newName}
            onValueChange={setNewName}
          />
          <CheckboxGroup label="Select what's included">
            <Checkbox isChecked={copyComponents} onChange={setCopyComponents}>
              Copy components
            </Checkbox>
            <Checkbox
              isChecked={copyComponents && copyReferences}
              onChange={setCopyReferences}
              isDisabled={!copyComponents}
            >
              Copy references
            </Checkbox>
            <Checkbox
              isChecked={
                copyComponents && copyReferences && copyCrossWorkspaceReferences
              }
              onChange={setCopyCrossWorkspaceReferences}
              isDisabled={!copyComponents || !copyReferences}
            >
              Copy cross-workspace references
            </Checkbox>
          </CheckboxGroup>
        </FormWrapper>
      </ModalLayout>
    </ModalTemplate>
  );
};

export const copyWsModal = (name: string) => {
  return modal<CopyWsModalResult>(resolve => {
    const onConfirm = (
      newName: string,
      copyComponents: boolean,
      copyReferences: boolean,
      copyCrossWorkspaceReferences: boolean
    ) => {
      resolve({
        newName,
        copyComponents,
        copyReferences,
        copyCrossWorkspaceReferences,
      });
    };
    return (
      <CopyWsDialog
        name={name}
        onConfirm={onConfirm}
        onCancel={() => {
          resolve(false);
        }}
      />
    );
  });
};
