import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import type { HasViewInstanceId } from '@ardoq/graph';

/**
 * Action that's dispatched when a visualization is done rendering.
 * This is for tracking performance metrics in presentation mode
 *
 * in legacy views it's also used for:
 * - updating the view legend
 * - auto-zooming to fit
 * - removing the loading spinner
 */
export const notifyViewRenderDone = actionCreator<ViewIds>(
  '[tabview] NOTIFY_VIEW_RENDER_DONE'
);

export interface NotifyViewLoadingPayload extends HasViewInstanceId {
  isBusy: boolean;
}
export const notifyViewLoading = actionCreator<NotifyViewLoadingPayload>(
  '[tabview] NOTIFY_VIEW_LOADING'
);

export const hoveredComponentChanged = actionCreator<ArdoqId | null>(
  '[highlighting] HOVERED_COMPONENT_CHANGED'
);
export const focusedComponentChanged = actionCreator<ArdoqId | null>(
  '[highlighting] FOCUSED_COMPONENT_CHANGED'
);
