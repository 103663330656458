import { reducedStream, reducer, streamReducer } from '@ardoq/rxbeach';
import { InferredTraversalWarningEvent, ViewInferenceState } from './types';
import {
  closeViewInferenceWindow,
  openViewInferenceWindow,
  sendViewInference,
  notifyViewInferenceError,
  notifyViewInferenceWarning,
  notifyViewInferenceSuccess,
  setViewInferenceInput,
  sendSuggestTraversal,
  notifySuggestTraversalError,
  notifySuggestTraversalSuccess,
  notifySuggestTraversalWarning,
  applySuggestion,
} from './actions';
import { viewInferenceOperations } from './viewInferenceOperations';
import { websocket$ } from 'sync/websocket$';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import {
  ClientRequest,
  isClientRequestErrorEvent,
  UserEvent,
} from 'sync/types';
import { requestShowAppModule } from 'appContainer/actions';
import { isEqual } from 'lodash';

const defaultState: ViewInferenceState = {
  componentIds: [],
  isOpen: false,
  input: '',
  sendPrompt: {
    status: 'READY',
  },
  suggest: {
    status: 'IDLE',
  },
  initialPosition: new DOMPoint(),
};

export const websocketInferredTraversal$ = websocket$.pipe(
  filter(viewInferenceOperations.isInferredTraversalEvent)
);

export const websocketSuggestTraversal$ = websocket$.pipe(
  filter(viewInferenceOperations.isSuggestTraversalEvent)
);

const websocketInferredTraversalWarning$: Observable<
  UserEvent<ClientRequest<InferredTraversalWarningEvent>>
> = websocket$.pipe(
  filter(viewInferenceOperations.isInferredTraversalWarningEvent),
  distinctUntilChanged(isEqual)
);

const websocketClientRequestError$ = websocket$.pipe(
  filter(isClientRequestErrorEvent)
);

const reducers = [
  reducer(openViewInferenceWindow, viewInferenceOperations.handleOpenWindow),
  reducer(requestShowAppModule, viewInferenceOperations.handleCloseWindow),
  reducer(closeViewInferenceWindow, viewInferenceOperations.handleCloseWindow),
  reducer(setViewInferenceInput, viewInferenceOperations.setInput),
  reducer(sendViewInference, viewInferenceOperations.handleSendViewInference),
  reducer(
    notifyViewInferenceSuccess,
    viewInferenceOperations.handleViewInferenceSuccess
  ),
  reducer(
    notifyViewInferenceError,
    viewInferenceOperations.handleViewInferenceError
  ),
  reducer(
    notifyViewInferenceWarning,
    viewInferenceOperations.handleViewInferenceWarning
  ),
  streamReducer(
    websocketClientRequestError$,
    viewInferenceOperations.handleClientRequestErrorEvent
  ),
  reducer(
    sendSuggestTraversal,
    viewInferenceOperations.handleSendSuggestTraversal
  ),
  reducer(
    notifySuggestTraversalError,
    viewInferenceOperations.handleSuggestTraversalError
  ),
  reducer(
    notifySuggestTraversalSuccess,
    viewInferenceOperations.handleSuggestTraversalSuccess
  ),
  reducer(
    notifySuggestTraversalWarning,
    viewInferenceOperations.setSuggestWarning
  ),
  reducer(applySuggestion, viewInferenceOperations.handleApplySuggestion),
  streamReducer(
    websocketInferredTraversalWarning$,
    viewInferenceOperations.handleViewInferenceWarningMessage
  ),
];

const viewInference$ = reducedStream('viewInference$', defaultState, reducers);

export default viewInference$;
