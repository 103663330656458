import { connect } from '@ardoq/rxbeach';
import { ConfigurationState } from 'integrations/signavio-exporter/streams/types';
import { StepContainer } from '../components/StepContainer';
import { IntegrationWorkspace } from '@ardoq/api-types/integrations';
import { Checkbox, FormWrapper } from '@ardoq/forms';
import { Multiselect, Select, SelectOption } from '@ardoq/select';
import { WorkspaceSelector } from 'integrations/common/components/workspaceSelector/WorkspaceSelector';
import { PrimaryButton, SecondaryButton } from '@ardoq/button';
import { StatusType, Tag } from '@ardoq/status-ui';
import { QueryBuilderQuery } from '@ardoq/api-types';
// Todo: Move ComponentFilter to common directory
import { ComponentFilter } from 'integrations/ITPedia/NewSync/ComponentSelection/Step1/ComponentFilter';
import { viewModel$ } from './viewModel$';
import { Maybe } from '@ardoq/common-helpers';
import { Island, CollapsibleIsland } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';

type ComponentSelectionParams = {
  dataSelectionIsValid: boolean;
  workspaces: IntegrationWorkspace[];
  accounts: { label: string; value: string }[];
  configurationState: ConfigurationState;
  componentTypeOptions: SelectOption<string>[];
  selectedComponentTypes: string[];
  onNext: () => void;
  onQueryChange: (
    query: QueryBuilderQuery,
    componentType: string,
    isValid: boolean
  ) => void;
  onFetchResults: (componentName: string) => void;
  onConnChange: (accountId: Maybe<string>) => void;
  onWorkspaceChange: (workspace: IntegrationWorkspace) => void;
  onComponentTypesChange: (componentTypes: Maybe<string[]>) => void;
  onApplyFilterChange: (applyFilters: boolean) => void;
};

function ComponentSelectionComponent({
  dataSelectionIsValid,
  workspaces,
  accounts,
  configurationState,
  componentTypeOptions,
  selectedComponentTypes,
  onNext,
  onQueryChange,
  onFetchResults,
  onConnChange,
  onWorkspaceChange,
  onComponentTypesChange,
  onApplyFilterChange,
}: ComponentSelectionParams) {
  return (
    <>
      <StepContainer $isVertical>
        <Island
          title="Select components to export"
          subtitle="Specify the SAP Signavio connection, Ardoq workspace, and component types you want to export."
          subtitleWidth="medium"
          footerContent={
            <FlexBox justify="end" flex={1}>
              <PrimaryButton
                isDisabled={!dataSelectionIsValid}
                onClick={onNext}
              >
                Next: Map fields
              </PrimaryButton>
            </FlexBox>
          }
        >
          <FormWrapper>
            <Select
              value={configurationState.accountId}
              label="SAP Signavio connection"
              placeholder="Type and select an existing connection"
              options={accounts}
              onValueChange={onConnChange}
            />
            <WorkspaceSelector
              title="Select a workspace"
              isCreationDisabled
              selectedWorkspace={configurationState.workspace}
              workspaces={{
                used: [],
                existing: workspaces,
              }}
              onWorkspaceSelect={onWorkspaceChange}
              helperText="Select a workspace with technology products."
            />
            <Multiselect
              label="Component types"
              placeholder="Type and select component types in Ardoq"
              isClearable
              value={selectedComponentTypes}
              helperText="Choose the component types to sync."
              options={componentTypeOptions}
              onValueChange={onComponentTypesChange}
            />
            <Checkbox
              isChecked={configurationState.applyFilters}
              onChange={onApplyFilterChange}
            >
              Apply filter to specify components to sync
            </Checkbox>
          </FormWrapper>
          {configurationState.applyFilters &&
            Object.entries(configurationState.componentTypes)?.map(
              ([componentName, componentValue]) => (
                <CollapsibleIsland
                  subtitleWidth="medium"
                  key={componentName}
                  isDefaultExpanded
                  title={
                    <FlexBox gap="xsmall">
                      Component type:{componentName}
                      {componentValue.componentsQuery && (
                        <Tag statusType={StatusType.INFO}>Filters applied</Tag>
                      )}
                    </FlexBox>
                  }
                >
                  <ComponentFilter
                    query={componentValue.componentsQuery as QueryBuilderQuery}
                    onQueryChange={query =>
                      onQueryChange(query.query, componentName, query.isValid)
                    }
                  />
                  <FlexBox>
                    <SecondaryButton
                      isLoading={
                        componentValue.componentsQueryResults?.status ===
                        'LOADING'
                      }
                      onClick={() => onFetchResults(componentName)}
                    >
                      Apply rules
                    </SecondaryButton>
                  </FlexBox>
                  {componentValue.componentsQueryResults &&
                    componentValue.componentsQueryResults.status ===
                      'SUCCESS' && (
                      <FlexBox>
                        Compliant components:{' '}
                        {componentValue.componentsQueryResults.total}
                      </FlexBox>
                    )}
                  {componentValue.componentsQueryResults?.status ===
                    'FAILURE' && (
                    <Tag statusType={StatusType.ERROR}>
                      {componentValue.componentsQueryResults?.message ||
                        'Failed to fetch'}
                    </Tag>
                  )}
                </CollapsibleIsland>
              )
            )}
        </Island>
      </StepContainer>
    </>
  );
}

export const ComponentSelection = connect(
  ComponentSelectionComponent,
  viewModel$
);
