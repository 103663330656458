import { radius } from '@ardoq/design-tokens';
import { AqLayout } from '@ardoq/layout';
import { Island } from '@ardoq/page-layout';
import { connect } from '@ardoq/rxbeach';
import ManagementSidebar from './ManagementSidebar';
import NewUseCaseEditor from './UseCaseEditor/NewUseCaseEditor';
import UseCaseOverview from './UseCaseOverview/UseCaseOverview';
import { UseCaseManagementViewModel } from './types';
import { viewModel$ } from './viewModel$';

const UseCaseManagement = ({
  editorProps,
  sidebarProps,
  overviewProps,
  isEditorVisible,
}: UseCaseManagementViewModel) => (
  <Island
    title="Use cases"
    subtitle="Manage and configure use cases."
    bodySpacing="none"
  >
    <AqLayout
      layoutWrapperStyle={{
        overflow: 'hidden',
        borderBottomLeftRadius: radius.medium,
        borderBottomRightRadius: radius.medium,
      }}
      renderLeftSidebar={() => <ManagementSidebar {...sidebarProps} />}
    >
      {isEditorVisible ? (
        <NewUseCaseEditor {...editorProps} />
      ) : (
        <UseCaseOverview {...overviewProps} />
      )}
    </AqLayout>
  </Island>
);

export default connect(UseCaseManagement, viewModel$);
