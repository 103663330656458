import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  chooseBroadcastContentComponentType,
  chooseBroadcastContentWorkspace,
} from 'broadcasts/actions';
import workspaces$, { WorkspacesState } from 'streams/workspaces/workspaces$';
import { map } from 'rxjs/operators';
import { LabeledValue } from 'aqTypes';
import { APIComponentType, ArdoqId } from '@ardoq/api-types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { Select } from '@ardoq/select';
import { Field, FormSize } from '@ardoq/forms';
import { getParentsByWorkspaceId } from 'utils/hierarchy';
import assetFolders$, {
  AssetFoldersState,
} from 'streams/assetFolders/assetFolders$';
import { combineLatest } from 'rxjs';

type MessageContentProps = {
  readonly selectedWorkspace: ArdoqId | null;
  readonly selectedComponentType: string | null;
  readonly required: boolean;
};
type ComponentTypeSelectorProps = {
  readonly componentTypeOptions: LabeledValue<string>[];
  readonly selectedComponentType: string | null;
  readonly isDisabled: boolean;
  readonly required?: boolean;
};
type WorkspaceSelectorProps = {
  readonly selectedWorkspace: ArdoqId | null;
  readonly workspaceOptions: LabeledValue<ArdoqId>[];
  readonly required: boolean;
  readonly onChange: (option: LabeledValue<ArdoqId> | null) => void;
};

const toCompTypeOption = (componentType: APIComponentType) => ({
  value: componentType.id,
  label: componentType.name,
});

const toWorkspaceOptions = ([assetFolders, workspaces]: [
  AssetFoldersState,
  WorkspacesState,
]) => {
  const parentsByWorkspaceId = getParentsByWorkspaceId(
    Object.values(workspaces.byId),
    assetFolders
  );
  return {
    workspaceOptions: Object.entries(workspaces.byId).map(([id, { name }]) => ({
      value: id,
      label: name,
      breadcrumbs: parentsByWorkspaceId.get(id)?.map(text => ({ text })),
    })),
  };
};

export const WorkspaceSelector = connect(
  ({
    selectedWorkspace,
    workspaceOptions,
    required,
    onChange,
  }: WorkspaceSelectorProps) => {
    const selected =
      workspaceOptions.find(({ value: wsId }) => wsId === selectedWorkspace) ??
      null;
    return (
      <Select
        dataTestId="workspace-select"
        errorMessage={required && !selected ? 'Required field' : undefined}
        placeholder="Select workspace"
        options={workspaceOptions}
        value={selected}
        onChange={onChange}
      />
    );
  },
  combineLatest([assetFolders$, workspaces$]).pipe(map(toWorkspaceOptions))
);

const ComponentTypeSelector = ({
  componentTypeOptions,
  selectedComponentType,
  isDisabled,
  required,
}: ComponentTypeSelectorProps) => {
  const selected =
    componentTypeOptions.find(
      ({ value: compType }) => compType === selectedComponentType
    ) ?? null;
  return (
    <Select
      dataTestId="component-type-select"
      errorMessage={required && !selected ? 'Required field' : undefined}
      placeholder="Select component type"
      options={componentTypeOptions}
      value={selected}
      onChange={option => {
        if (!option) return;
        if (option.value !== selectedComponentType) {
          dispatchAction(
            chooseBroadcastContentComponentType(option.value as string)
          );
        }
      }}
      isDisabled={isDisabled}
    />
  );
};

const MessageContent = ({
  selectedWorkspace,
  selectedComponentType,
  required,
}: MessageContentProps) => {
  return (
    <>
      <Field label="Workspace" formSize={FormSize.DEFAULT}>
        <WorkspaceSelector
          required={required}
          selectedWorkspace={selectedWorkspace}
          onChange={option => {
            if (!option) return;
            if (option.value !== selectedWorkspace) {
              dispatchAction(
                chooseBroadcastContentWorkspace(option.value as ArdoqId)
              );
            }
          }}
        />
      </Field>
      <Field label="Component type" formSize={FormSize.DEFAULT}>
        <ComponentTypeSelector
          required={required}
          componentTypeOptions={
            selectedWorkspace
              ? workspaceInterface
                  .getComponentTypes(selectedWorkspace)
                  .map(toCompTypeOption)
              : []
          }
          selectedComponentType={selectedComponentType}
          isDisabled={!selectedWorkspace}
        />
      </Field>
    </>
  );
};

export default MessageContent;
