import {
  DecoratedSubdivision,
  ManageMembershipModalCommands,
  ManageMembershipTableData,
} from './types';
import { Box } from '@ardoq/layout';
import { Subdivision } from '@ardoq/api-types';
import { Multiselect } from '@ardoq/select';
import { subdivisionOperations } from 'subdivisions/subdivisionOperations';
import { SubdivisionsColumnHeader } from './SubdivisionsColumnHeader';
import { manageMembershipTableDataOperations } from './manageMembershipTableDataOperations';

export const SubdivisionsColumn = (
  allSubdivisions: DecoratedSubdivision[],
  commands: ManageMembershipModalCommands
) => {
  const options = allSubdivisions.map(
    subdivisionOperations.convertToSelectOption
  );
  return {
    valueRender: (
      selectedSubdivisions: Subdivision[],
      tableData: ManageMembershipTableData
    ) => {
      const defaultValue = selectedSubdivisions.map(
        subdivision => subdivision._id
      );
      return (
        <Box>
          <Multiselect
            options={options}
            defaultValue={defaultValue}
            onValueChange={subdivisionIds => {
              commands.applySelectionChanges({
                componentId:
                  manageMembershipTableDataOperations.getRowId(tableData),
                previousSelection: defaultValue,
                currentSelection: subdivisionIds ?? [],
              });
            }}
          />
        </Box>
      );
    },
    headerRender: SubdivisionsColumnHeader,
    dataIndex: 'memberships',
  };
};
