import { ChipsList, Chip, ChipsSeparator } from '@ardoq/status-ui';
import { without } from 'lodash';

type FilterChipsProps = {
  filterChips: { label: string; key: string }[];
  activeChips: string[];
  showAllChip?: boolean;
  setFilterChips: (filterChips: string[]) => void;
};

const FilterChips = ({
  filterChips,
  activeChips,
  showAllChip,
  setFilterChips,
}: FilterChipsProps) => {
  const handleSetFilterChips = (filterChip: string) => {
    setFilterChips(
      activeChips.includes(filterChip)
        ? without(activeChips, filterChip)
        : [...activeChips, filterChip]
    );
  };
  return (
    <ChipsList>
      {showAllChip && (
        <>
          <Chip onClick={() => setFilterChips([])}>All</Chip>
          <ChipsSeparator />
        </>
      )}
      {filterChips.map(chip => (
        <Chip
          dataTestId={`${chip.key}`}
          key={chip.key}
          isActive={activeChips.includes(chip.key)}
          onClick={() => handleSetFilterChips(chip.key)}
        >
          {chip.label}
        </Chip>
      ))}
    </ChipsList>
  );
};

export default FilterChips;
