import { SortOrder } from '@ardoq/api-types';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import {
  WorkspaceOverviewManagementColumnState,
  WorkspaceOverviewManagementRowState,
} from './types';

export const getTableSelection = (
  rows: WorkspaceOverviewManagementRowState[]
) => {
  const totalNumberOfRows = rows.length;
  const selectedCount = rows.filter(row => row.selected).length;
  return selectedCount > 0
    ? selectedCount === totalNumberOfRows
      ? ('all' as const)
      : ('some' as const)
    : ('none' as const);
};

export const compareValues = (a: unknown, b: unknown): number => {
  if (typeof a === 'string' && typeof b === 'string') {
    return localeCompare(a, b, getCurrentLocale());
  }
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  if (typeof a === 'boolean' && typeof b === 'boolean') {
    return a === b ? 0 : a ? 1 : -1;
  }
  return 0;
};

export const sortRows = (
  rows: WorkspaceOverviewManagementRowState[],
  columns: WorkspaceOverviewManagementColumnState
): WorkspaceOverviewManagementRowState[] => {
  const sortedColumn = Object.values(columns).find(
    column => column.sortOrder !== null
  );
  if (!sortedColumn) {
    return rows;
  }
  const direction = sortedColumn.sortOrder === SortOrder.ASC ? 1 : -1;
  const sortKey = sortedColumn.id;
  return rows.toSorted(
    (a, b) => compareValues(a[sortKey], b[sortKey]) * direction
  );
};

export const filterRows = (
  rows: WorkspaceOverviewManagementRowState[],
  searchQuery: string
): WorkspaceOverviewManagementRowState[] =>
  rows.filter(row =>
    row.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
  );

export const sliceRows = (
  rows: WorkspaceOverviewManagementRowState[],
  currentPage: number,
  rowsPerPage: number
): WorkspaceOverviewManagementRowState[] => {
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  return rows.slice(start, end);
};

export const getCurrentPage = ({
  rows,
  columns,
  searchQuery,
  currentPageNumber,
  rowsPerPage,
}: {
  rows: WorkspaceOverviewManagementRowState[];
  columns: WorkspaceOverviewManagementColumnState;
  searchQuery: string;
  currentPageNumber: number;
  rowsPerPage: number;
}): WorkspaceOverviewManagementRowState[] => {
  return sliceRows(
    sortRows(filterRows(rows, searchQuery), columns),
    currentPageNumber,
    rowsPerPage
  );
};

export const selectRange = (
  rows: WorkspaceOverviewManagementRowState[],
  startId: string,
  endId: string
): WorkspaceOverviewManagementRowState[] => {
  const startIndex = rows.findIndex(row => row.id === startId);
  const endIndex = rows.findIndex(row => row.id === endId);
  const [minIndex, maxIndex] = [startIndex, endIndex].sort((a, b) => a - b);
  return rows.filter(
    (row, index) => (index >= minIndex && index <= maxIndex) || row.selected
  );
};
