import { dispatchAction } from '@ardoq/rxbeach';
import { addBookmark } from './actions';
import { ViewIds } from '@ardoq/api-types';
import {
  setBookmarkName as setBookmarkNameAction,
  setBookmarkFolder as setBookmarkFolderAction,
} from './actions';
import tracking from './tracking';

const saveBookmark = (
  bookmarkName: string,
  viewId: ViewIds,
  folderId: string | null
) => {
  dispatchAction(
    addBookmark({
      bookmarkName,
      folderId,
      viewId,
    })
  );
  tracking.trackClickedSaveAsBookmark(folderId !== null);
};

const setBookmarkName = (bookmarkName: string) =>
  dispatchAction(setBookmarkNameAction(bookmarkName));

const setBookmarkFolder = (folderId: string | null) =>
  dispatchAction(setBookmarkFolderAction(folderId));

type BookmarkCommands = {
  saveBookmark: (
    bookmarkName: string,
    viewId: ViewIds,
    folderId: string | null
  ) => void;
  setBookmarkName: (bookmarkName: string) => void;
  setBookmarkFolder: (folderId: string | null) => void;
};

const bookmarkCommands: BookmarkCommands = {
  saveBookmark,
  setBookmarkName,
  setBookmarkFolder,
};

export default bookmarkCommands;
