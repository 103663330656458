import { ButtonType } from '@ardoq/button';
import React, { useRef } from 'react';
import { SettingsBarButton } from '@ardoq/settings-bar';
import {
  ViewOptionsSectionRowContainer,
  ViewOptionsSectionRowContent,
  ViewOptionsSectionRowLabel,
  ViewOptionsSectionRightControls,
} from './atoms';
import { Tag } from '@ardoq/status-ui';
import {
  ChevronRightIcon,
  Icon,
  IconName,
  IconSize,
  InfoIcon,
} from '@ardoq/icons';
import {
  isDropdownConfig,
  isButtonConfig,
  isToggleConfig,
  isCustomComponentConfig,
  type SettingsConfig,
} from '@ardoq/view-settings';
import {
  ButtonWithDropdown,
  dispatchMouseEventOnTarget,
  simpleNotifier,
  Notifier,
} from '@ardoq/dropdown-menu';
import { colors } from '@ardoq/design-tokens';
import { ViewIds } from '@ardoq/api-types';
import { Switch } from '@ardoq/forms';
import { POPOVER_ID_ATTR } from '@ardoq/popovers';

const forwardClickOnViewSettingCustomComponent = (
  event: React.MouseEvent<HTMLDivElement>,
  controlsContainer?: Element | null
) => {
  if (
    controlsContainer &&
    controlsContainer !== event.target &&
    !controlsContainer.contains(event.target as Node)
  ) {
    const checkbox = controlsContainer.querySelector('input[type="checkbox"]');
    const clickTarget = checkbox || controlsContainer;

    dispatchMouseEventOnTarget(clickTarget);
  }
};

type GetOnClick = (
  event: React.MouseEvent<HTMLDivElement>,
  controlsContainer?: Element | null
) => void;
const getOnClick = (
  viewModifier: SettingsConfig,
  viewId: ViewIds,
  dropdownNotifier: Notifier
): GetOnClick => {
  if (isDropdownConfig(viewModifier)) {
    return () => {
      dropdownNotifier.notify();
    };
  }

  if (isButtonConfig(viewModifier)) {
    return viewModifier.onClick;
  }

  return forwardClickOnViewSettingCustomComponent;
};

type ViewSettingsRowProperties = {
  viewModifier: SettingsConfig;
  displayValue: string | null;
  viewId: ViewIds;
};
const ViewSettingsRow = ({
  viewId,
  viewModifier,
  displayValue,
}: ViewSettingsRowProperties) => {
  const label = 'label' in viewModifier ? viewModifier.label : viewModifier.id;
  const iconName = 'iconName' in viewModifier ? viewModifier.iconName : null;
  const rightControlsRef = React.useRef<HTMLDivElement>(null);
  const notifier = useRef(simpleNotifier());
  const onClick = getOnClick(viewModifier, viewId, notifier.current);

  const popoverId = 'popoverId' in viewModifier ? viewModifier.popoverId : null;

  return (
    <ViewOptionsSectionRowContainer
      onClick={event =>
        onClick(event, rightControlsRef.current?.lastElementChild)
      }
      data-testid="view-settings-row"
      {...(popoverId ? { [POPOVER_ID_ATTR]: popoverId } : {})}
    >
      <ViewOptionsSectionRowContent>
        {iconName && <Icon color={colors.grey50} iconName={iconName} />}
        <ViewOptionsSectionRowLabel>{label}</ViewOptionsSectionRowLabel>
        {popoverId && (
          <InfoIcon
            color={colors.grey50}
            size={IconSize.MEDIUM}
            {...{ [POPOVER_ID_ATTR]: popoverId }}
          />
        )}
        <ViewOptionsSectionRightControls ref={rightControlsRef}>
          {displayValue && <Tag>{displayValue}</Tag>}
          {isDropdownConfig(viewModifier) ? (
            <ButtonWithDropdown
              iconName={IconName.CHEVRON_RIGHT}
              options={viewModifier.options}
              isKeepOpen={viewModifier.isKeepOpen}
              dropdownSize={viewModifier.dropdownSize}
              requestOpen={notifier.current}
              buttonType={ButtonType.GHOST}
            />
          ) : isToggleConfig(viewModifier) ? (
            <Switch
              name={viewModifier.id}
              isChecked={viewModifier.isActive}
              onChange={() =>
                viewModifier.onViewSettingsUpdate?.(
                  viewId,
                  { [viewModifier.id]: !viewModifier.isActive },
                  true
                )
              }
            />
          ) : isButtonConfig(viewModifier) ? (
            <SettingsBarButton {...viewModifier} />
          ) : isCustomComponentConfig(viewModifier) ? (
            React.createElement(
              viewModifier.component,
              viewModifier.componentProps
            )
          ) : (
            <ChevronRightIcon disabled={true} />
          )}
        </ViewOptionsSectionRightControls>
      </ViewOptionsSectionRowContent>
    </ViewOptionsSectionRowContainer>
  );
};

export default ViewSettingsRow;
