import QuestionBuilder from '../questions/QuestionBuilder';
import { SurveyValidation } from 'surveyAdmin/types';
import { ValidatorFunction } from 'aqTypes';
import { APIFieldType, isPersistedSurvey } from '@ardoq/api-types';
import { PermissionContext } from '@ardoq/access-control';
import { SurveyEditorSectionProps } from './SurveyEditor';
import { hasFeature, Features } from '@ardoq/features';

interface SurveyEditorSurveySectionProperties {
  selectedTypeId?: string;
  selectedModelId?: string;
  validators: Partial<Record<APIFieldType, ValidatorFunction>>;
  surveyValidation: SurveyValidation | undefined;
  permissionsContext: PermissionContext;
  hasSurveyResponseApprovalsFeature: boolean;
}
const SurveyEditorSurveySection = (
  props: Omit<SurveyEditorSectionProps, 'subtitle'> &
    SurveyEditorSurveySectionProperties
) => {
  const {
    surveyAttributes,
    selectedTypeId,
    selectedModelId,
    validators,
    surveyValidation,
    permissionsContext,
    hasSurveyResponseApprovalsFeature,
    setSurveyAttributes,
  } = props;

  return (
    <QuestionBuilder
      questions={surveyAttributes.questions}
      isComponent={true}
      typeId={selectedTypeId}
      modelId={selectedModelId}
      workspaceId={surveyAttributes.workspace}
      surveyId={
        isPersistedSurvey(surveyAttributes) ? surveyAttributes._id : undefined
      }
      validators={validators}
      surveyValidation={surveyValidation}
      addQuestionLabel="Add section"
      addQuestionDescription="Add a question or a text section between questions. A field question will allow respondents to change field values of the survey components, while a reference question will allow them to create references to/from the survey components."
      permissionsContext={permissionsContext}
      allowComponentCreation={surveyAttributes.allowComponentCreation}
      surveyIsPermissionDivisionsAware={
        hasFeature(Features.PERMISSION_ZONES_INTERNAL) &&
        Boolean(surveyAttributes.subdivisions?.enabled)
      }
      hasSurveyResponseApprovalsFeature={
        hasSurveyResponseApprovalsFeature &&
        Boolean(surveyAttributes.approvalFlow?.enabled)
      }
      updateQuestions={questions =>
        setSurveyAttributes({
          questions,
        })
      }
    />
  );
};

export default SurveyEditorSurveySection;
