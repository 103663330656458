import {
  collectRoutines,
  routine,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { switchMap, tap } from 'rxjs/operators';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import {
  createPermissionGroup,
  createPermissionGroupFailed,
  createPermissionGroupSuccess,
} from './actions';
import { trackEvent } from 'tracking/tracking';
import { ToastType, showToast } from '@ardoq/status-ui';
import { api, groupApi, handleError } from '@ardoq/api';
import { ArdoqError } from '@ardoq/common-helpers';

const handleErrorObject = (error: ArdoqError) => {
  const errorMessage = api.logErrorIfNeededAndReturnMessage(
    error,
    'Failed to create new permission group'
  );
  dispatchAction(createPermissionGroupFailed(errorMessage));
};

const handleCreateGroup = routine(
  ofType(createPermissionGroup),
  extractPayload(),
  switchMap(groupApi.createGroupWithUsers),
  handleError(handleErrorObject),
  tap(group => {
    dispatchAction(createPermissionGroupSuccess(group._id));
    showToast('Group created.', ToastType.SUCCESS);
    trackEvent(APPSEC_EVENTS.CREATED_NEW_GROUP);
  })
);

export const createPermissionGroupRoutines = collectRoutines(handleCreateGroup);
