import { CSSProperties } from 'react';
import {
  DataSource,
  DataSourceItem,
  DiffTableRowType,
  MergeState,
} from 'components/DiffMergeTable/types';
import {
  Aligner,
  BaseTr,
  CheckboxWithPointer,
  DisabledCheckbox,
  StyledTd,
  StyledTh,
} from 'components/DiffMergeTable/atoms';
import { dispatchAction } from '@ardoq/rxbeach';
import { setMergeState } from 'components/DiffMergeTable/actions';
import { Branch } from 'components/DiffMergeTable/Branch';
import { entityTypeToCollection } from 'components/DiffMergeTable/utils';
import { APIEntityType, ScopeDataCollection, Verb } from '@ardoq/api-types';
import { Icon, IconName } from '@ardoq/icons';
import { entityRepresentationRenderer } from 'components/DiffMergeTable/EntityRepresentation/EntityRepresentation';
import { DatasourceTable } from '@ardoq/table';
import { Graphics, Nonexistent, getEntityById } from '@ardoq/renderers';
import { getBranchWhereEntityExists } from 'scopeData/utils/getBranchWhereEntityExists';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { withPlainTextPopover } from '@ardoq/popovers';
import { getPopoverStringForDisabledCheckbox } from 'components/DiffMergeTable/popoverStrings';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';

const FadedIcon = styled(Icon)`
  opacity: 0.3;
`;

const getRowStyle = ({ rowType }: DataSourceItem) =>
  rowType === DiffTableRowType.SUB_HEADER_ROW
    ? { background: colors.grey90 }
    : {};

const getCheckBoxRenderer =
  (mergeDirection: MergeDirection) =>
  (_: string, dataSourceRow: DataSourceItem) => {
    if (!dataSourceRow) return null;
    const { status, isDisabled, rowType } = dataSourceRow;
    return (
      <Aligner>
        {isDisabled ? (
          <DisabledCheckbox
            checked={status === MergeState.SOURCE}
            mixed={status === MergeState.PARTIAL}
            {...withPlainTextPopover(
              getPopoverStringForDisabledCheckbox({
                verb: Verb.MERGE,
                entityType: dataSourceRow.entityType,
                mergeDirection,
                rowType,
                hasWritePermission: dataSourceRow.hasWritePermission,
              })
            )}
          />
        ) : (
          <CheckboxWithPointer
            checked={status === MergeState.SOURCE}
            mixed={status === MergeState.PARTIAL}
          />
        )}
      </Aligner>
    );
  };

const toggleRowMergeStatus = ({
  index,
  status,
  isDisabled,
}: DataSourceItem) => {
  if (!isDisabled) {
    dispatchAction(
      setMergeState({
        mergeStatus:
          status === MergeState.NONE ? MergeState.SOURCE : MergeState.NONE,
        index,
      })
    );
  }
};

const getCheckMarkRenderer =
  (dataSource: DataSource, branch: Branch) =>
  (_: string, dataSourceRow: DataSourceItem) => {
    if (
      !dataSourceRow ||
      dataSourceRow.rowType === DiffTableRowType.SUB_HEADER_ROW
    ) {
      return null;
    }

    if (
      dataSourceRow.rowType === DiffTableRowType.PROPERTY_ROW &&
      !getEntityById(
        dataSourceRow.entityType,
        dataSourceRow.entityId,
        dataSourceRow.enhancedDiffContextData[branch]
      )
    ) {
      return <Nonexistent />;
    }

    const { entityId: tagId, enhancedDiffContextData } =
      dataSource[dataSourceRow.parent!];
    const tag = getEntityById(
      APIEntityType.TAG,
      tagId,
      enhancedDiffContextData[branch]
    );
    const scopeDataCollection = entityTypeToCollection[
      dataSourceRow.entityType
    ] as ScopeDataCollection.COMPONENTS | ScopeDataCollection.REFERENCES;
    const entityIsTaggedInBranch = tag?.[scopeDataCollection].includes(
      dataSourceRow.entityId
    );

    const iconName = entityIsTaggedInBranch
      ? IconName.CHECK_MARK
      : IconName.CLOSE;

    return branch !== Branch.BRANCH_OFF &&
      ((dataSourceRow.status === MergeState.SOURCE && entityIsTaggedInBranch) ||
        (dataSourceRow.status === MergeState.NONE &&
          !entityIsTaggedInBranch)) ? (
      <Icon iconName={iconName} />
    ) : (
      <FadedIcon iconName={iconName} />
    );
  };

const checkMarkColumnHeaderStyle: CSSProperties = {
  width: 125,
  textAlign: 'center',
};

const getEntityRepresentationRenderer =
  (graphics: Graphics) =>
  (
    _: string,
    {
      entityId,
      entityType,
      parentEntityId,
      enhancedDiffContextData,
    }: DataSourceItem
  ) => {
    const branch = getBranchWhereEntityExists(
      entityType,
      entityId,
      enhancedDiffContextData,
      Branch.SOURCE
    );

    return (
      branch &&
      entityRepresentationRenderer(
        entityId,
        parentEntityId,
        entityType,
        enhancedDiffContextData[branch],
        graphics
      )
    );
  };

const MergeTagsTable = ({
  dataSource,
  mergeDirection,
  graphics,
}: {
  dataSource: DataSource;
  mergeDirection: MergeDirection;
  graphics: Graphics;
}) => {
  const [headerRow, ...bodyRows] = dataSource;
  const columns = [
    {
      valueRender: getCheckBoxRenderer(mergeDirection),
      headerRender: () => getCheckBoxRenderer(mergeDirection)('', headerRow),
      onHeaderClick: () => toggleRowMergeStatus(headerRow),
      onCellClick: toggleRowMergeStatus,
      headerStyle: { width: 64 },
    },
    {
      title: 'select all',
      headerStyle: { width: '65%' },
      valueRender: getEntityRepresentationRenderer(graphics),
      getColSpan: (_: string, { rowType }: DataSourceItem) =>
        rowType === DiffTableRowType.SUB_HEADER_ROW ? 4 : 1,
    },
    {
      title: 'original',
      valueRender: getCheckMarkRenderer(dataSource, Branch.BRANCH_OFF),
      headerStyle: checkMarkColumnHeaderStyle,
      cellStyle: checkMarkColumnHeaderStyle,
    },
    {
      title:
        mergeDirection === MergeDirection.MAINLINE_TO_BRANCH
          ? 'mainline'
          : 'scenario',
      valueRender: getCheckMarkRenderer(dataSource, Branch.SOURCE),
      headerStyle: checkMarkColumnHeaderStyle,
      cellStyle: checkMarkColumnHeaderStyle,
    },
    {
      title:
        mergeDirection === MergeDirection.MAINLINE_TO_BRANCH
          ? 'scenario'
          : 'mainline',
      valueRender: getCheckMarkRenderer(dataSource, Branch.TARGET),
      headerStyle: checkMarkColumnHeaderStyle,
      cellStyle: checkMarkColumnHeaderStyle,
    },
  ];
  return (
    <DatasourceTable
      components={{
        Th: StyledTh,
        Td: StyledTd,
        BodyTr: BaseTr,
      }}
      columns={columns}
      dataSource={bodyRows}
      rowStyle={getRowStyle}
      scrollableSectionHeight="100%"
    />
  );
};

export default MergeTagsTable;
