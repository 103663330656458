import { ViewpointBuilderContext } from 'viewpointBuilder/types';
import {
  SelectViewpointBuilderContextPayload,
  SelectTabPayload,
  SetSelectedComponentTypeAndCountPayload,
  SetGraphCountsAndComponentTypeRepresentationPayload,
  SetFormattingRulesCount,
  SetGroupingRulesCount,
  SetSavedViewpointDetails,
  SetCollapsedPathsCount,
  SetRequiredComponentsCount,
} from './actions';
import {
  createViewpointMenuItems,
  editViewpointMenuItems,
  getTabsBehindFeatureFlags,
  metamodelInformationMenuItem,
  openDatasetMenuItems,
} from './viewpointBuilderNavigationMenuItems';
import { getPopoverContent, isTabItemDisabled } from './isTabItemDisabled';
import { getTagsForNavMenuItem } from './getTagsForNavMenuItem';
import {
  ViewpointBuilderNavigationState,
  ViewpointBuilderNavigationViewModel,
  NavigationMode,
  ViewpointBuilderTabItem,
  ViewpointBuilderTabItemMeta,
} from './types';

const getInitialState = (): ViewpointBuilderNavigationState => {
  return {
    selectedTab: 'SELECT_CONTEXT_COMPONENT_INSTANCES_TAB',
    context: 'editSubgraph',
    selectedComponentTypes: [],
    selectedComponentCount: 0,
    filtersCount: 0,
    edgesCount: 0,
    nodesCount: 0,
    selectedComponentTypeRepresentation: null,
    conditionalFormattingRulesCount: 0,
    labelFormattingRulesCount: 0,
    groupingRulesCount: 0,
    collapsedPathsCount: 0,
    requiredComponentsCount: 0,
    viewpointName: null,
  };
};

const getInitialViewModel = (): ViewpointBuilderNavigationViewModel => {
  return viewpointBuilderNavigationOperations.composeViewModel(
    viewpointBuilderNavigationOperations.getInitialState()
  );
};

const getTabItems = (
  state: ViewpointBuilderNavigationState
): ViewpointBuilderTabItem[] => {
  return getMenuListBasedOnContext(state).map(item => ({
    ...item,
    isSelected: item.tabId === state.selectedTab,
    isDisabled: isTabItemDisabled(item.tabId, state),
    popoverContentForDisabledItem: getPopoverContent(item, state),
    tags: getTagsForNavMenuItem(item.tabId, state),
  }));
};

const getMenuListBasedOnContext = (
  state: ViewpointBuilderNavigationState
): ViewpointBuilderTabItemMeta[] => {
  if (state.context === 'createViewpoint') {
    return createViewpointMenuItems;
  }

  if (getModeFromContext(state.context) === 'simplified') {
    return [...openDatasetMenuItems, ...getTabsBehindFeatureFlags()];
  }

  return editViewpointMenuItems;
};

const composeViewModel = (
  state: ViewpointBuilderNavigationState
): ViewpointBuilderNavigationViewModel => {
  return {
    tabItems: getTabItems(state),
    navigationMode: getModeFromContext(state.context),
    sidebarTitle: getSidebarTitleFromContext(state.context),
    sidebarSubtitle: getSidebarSubtitleFromContext(state),
    bottomItem: {
      ...metamodelInformationMenuItem,
      isSelected: metamodelInformationMenuItem.tabId === state.selectedTab,
      isDisabled: false,
      popoverContentForDisabledItem: undefined,
      tags: [],
    },
    ...state,
  };
};

const getModeFromContext = (
  context: ViewpointBuilderContext
): NavigationMode => {
  switch (context) {
    case 'editViewpoint':
    case 'createViewpoint':
      return 'advanced';

    case 'loadNewViewpoint':
    case 'openViewpoint':
    case 'editSubgraph':
    case 'editSearch':
    case 'contextMenu':
      return 'simplified';

    default:
      context satisfies never;
      return 'simplified';
  }
};

const getSidebarTitleFromContext = (context: ViewpointBuilderContext) => {
  switch (context) {
    case 'editSubgraph':
      return 'Edit dataset';

    case 'editViewpoint':
      return 'Edit viewpoint';

    case 'createViewpoint':
      return 'Create viewpoint';

    default:
      return 'Open dataset';
  }
};
const getSidebarSubtitleFromContext = (
  state: ViewpointBuilderNavigationState
) => {
  if (state.context === 'createViewpoint') {
    return 'You can save a Viewpoint template from an opened dataset.';
  }
  if (state.context === 'editViewpoint') {
    return `Making changes to '${state.viewpointName}'.`;
  }
  return undefined;
};

const selectTab = (
  state: ViewpointBuilderNavigationState,
  { tab }: SelectTabPayload
): ViewpointBuilderNavigationState => {
  return { ...state, selectedTab: tab };
};

const selectNavigationModeAndContext = (
  state: ViewpointBuilderNavigationState,
  { context }: SelectViewpointBuilderContextPayload
): ViewpointBuilderNavigationState => {
  return {
    ...state,
    context,
  };
};
const setComponentTypeAndCount = (
  state: ViewpointBuilderNavigationState,
  {
    selectedComponentTypes,
    selectedComponentCount,
  }: SetSelectedComponentTypeAndCountPayload
): ViewpointBuilderNavigationState => {
  return {
    ...state,
    selectedComponentTypes,
    selectedComponentCount,
  };
};
const setGraphCountsAndComponentTypeRepresentation = (
  state: ViewpointBuilderNavigationState,
  {
    filtersCount,
    edgesCount,
    nodesCount,
    selectedComponentTypeRepresentation,
  }: SetGraphCountsAndComponentTypeRepresentationPayload
): ViewpointBuilderNavigationState => {
  return {
    ...state,
    filtersCount,
    edgesCount,
    nodesCount,
    selectedComponentTypeRepresentation,
  };
};
const setFormattingRulesCount = (
  state: ViewpointBuilderNavigationState,
  payload: SetFormattingRulesCount
): ViewpointBuilderNavigationState => {
  return {
    ...state,
    ...payload,
  };
};
const setGroupingRulesCount = (
  state: ViewpointBuilderNavigationState,
  { groupingRulesCount }: SetGroupingRulesCount
): ViewpointBuilderNavigationState => {
  return {
    ...state,
    groupingRulesCount,
  };
};
const setCollapsedPathsCount = (
  state: ViewpointBuilderNavigationState,
  { collapsedPathsCount }: SetCollapsedPathsCount
): ViewpointBuilderNavigationState => {
  return { ...state, collapsedPathsCount };
};
const setRequiredComponentsCount = (
  state: ViewpointBuilderNavigationState,
  { requiredComponentsCount }: SetRequiredComponentsCount
): ViewpointBuilderNavigationState => {
  return { ...state, requiredComponentsCount };
};
const setSavedViewpointDetails = (
  state: ViewpointBuilderNavigationState,
  { viewpointName }: SetSavedViewpointDetails
): ViewpointBuilderNavigationState => {
  return {
    ...state,
    viewpointName,
  };
};
const resetState = (): ViewpointBuilderNavigationState => getInitialState();

export const viewpointBuilderNavigationOperations = {
  getInitialState,
  selectTab,
  selectNavigationModeAndContext,
  composeViewModel,
  getInitialViewModel,
  getModeFromContext,
  setComponentTypeAndCount,
  setGraphCountsAndComponentTypeRepresentation,
  setFormattingRulesCount,
  setGroupingRulesCount,
  setCollapsedPathsCount,
  setRequiredComponentsCount,
  setSavedViewpointDetails,
  resetState,
};
