import { DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';
import { IconName } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import { openResourcePermissionDialog } from 'resourcePermissions/actions';
import { ArdoqId, ResourceType } from '@ardoq/api-types';
import { prompt } from '@ardoq/modal';
import {
  TrackedContextMenus,
  TrackedScenarioContextMenuLabels,
  trackContextMenuOptionClick,
} from './tracking';
import { renameAsset } from 'streams/assets/actions';
import { scenarioNamespace } from 'streams/scenarios/actions';
import { ScenarioModeState } from 'scope/types';
import { PermissionContext } from '@ardoq/access-control';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';

const trackScenarioContextMenuOptionClick = (
  optionTitle: TrackedScenarioContextMenuLabels
) => {
  trackContextMenuOptionClick({
    contextMenuNamespace: TrackedContextMenus.SCENARIO_CONTEXT_MENU,
    selectedOptionTitle: optionTitle,
  });
};

export const getScenarioPermissionsMenuItem = (
  scenarioId: ArdoqId
): DropdownOption | null => {
  const scenarioName = scenarioInterface.getScenarioNameById(scenarioId);

  if (!scenarioName) {
    return null;
  }

  return {
    name: 'scenario-permissions',
    testId: 'scenario-permissions-item',
    label: `Permissions`,
    iconName: IconName.PERSON,
    type: DropdownOptionType.OPTION,
    onClick: () => {
      trackScenarioContextMenuOptionClick(
        TrackedScenarioContextMenuLabels.PERMISSIONS
      );
      dispatchAction(
        openResourcePermissionDialog({
          resources: [
            {
              resourceId: scenarioId,
              resourceName: scenarioName,
              resourceType: ResourceType.SCENARIO,
            },
          ],
          originPage: 'scenario',
        })
      );
    },
  };
};

export const getRenameScenarioMenuItem = (
  scenarioId: ArdoqId,
  permissionContext: PermissionContext,
  activeScenarioState: ScenarioModeState
): DropdownOption | null => {
  const scenario = scenarioInterface.getScenarioById(scenarioId);
  const canEditScenario = scenarioAccessControlInterface.canEditScenario(
    permissionContext,
    scenarioId,
    activeScenarioState
  );

  if (!scenario || !canEditScenario) {
    return null;
  }

  return {
    name: 'scenario-rename',
    testId: 'scenario-rename-item',
    label: `Rename`,
    iconName: IconName.EDIT,
    type: DropdownOptionType.OPTION,
    onClick: async () => {
      const name = await prompt({
        title: 'Rename the scenario',
        inputLabel: 'New name',
        initialInputValue: scenario.name,
        confirmButtonClickId: 'confirm-rename-scenario',
        textInputId: 'enter-scenario-new-name',
      });

      trackScenarioContextMenuOptionClick(
        TrackedScenarioContextMenuLabels.RENAME
      );

      if (!name) {
        return;
      }
      dispatchAction(
        renameAsset({
          _id: scenarioId,
          _version: scenario._version,
          name,
        }),
        scenarioNamespace
      );
    },
  };
};
