import { combineLatest, map } from 'rxjs';
import { tokenTabViewModel$ } from './tokenTabViewModel$';
import currentUser$ from 'streams/currentUser/currentUser$';
import { TokenTabViewProps } from './TokenView';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { hasPrivilege } from '@ardoq/privileges';
import { PrivilegeLabel } from '@ardoq/api-types';
import { tokenViewCommands } from './commands';
import { caseInsensitiveStringIncludes } from '@ardoq/common-helpers';
import { tokenTabViewModelOperations } from './tokenTabViewModelOperations';

export const tokenTabViewProps$ = combineLatest({
  tokenTabViewModel: tokenTabViewModel$,
  currentUser: currentUser$,
}).pipe(
  map(({ tokenTabViewModel, currentUser }): TokenTabViewProps => {
    const { isLoading, errorMessage, tokens, searchKey, activeTokensTab } =
      tokenTabViewModel;
    const isOrgAdmin = currentUserInterface.currentUserIsOrgAdmin();

    const myTokens = tokens.filter(token =>
      tokenTabViewModelOperations.isMyToken(token, currentUser._id)
    );
    const orgTokens = isOrgAdmin
      ? tokens.filter(
          token =>
            !tokenTabViewModelOperations.isMyToken(token, currentUser._id)
        )
      : [];

    return {
      isLoading,
      activeTokensTab,
      canCreateToken: hasPrivilege(PrivilegeLabel.CREATE_API_TOKEN),
      isOrgAdmin,
      errorMessage: errorMessage || '',
      searchedMyTokens: myTokens.filter(token =>
        caseInsensitiveStringIncludes(token.description, searchKey)
      ),
      isMyTokensEmpty: myTokens.length === 0,
      searchedOrgTokens: orgTokens.filter(token =>
        caseInsensitiveStringIncludes(token.description, searchKey)
      ),
      isOrgTokensEmpty: orgTokens.length === 0,
      searchKey,
      commands: tokenViewCommands,
    };
  })
);
