import { FormikErrors } from 'formik';
import { api, userApi } from '@ardoq/api';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';

export interface FormValues {
  currentPassword: string;
  email: string;
}

const emailValidator = (maybe: string) => /.+@.+\..+/.test(maybe);

export function validate({ currentPassword, email }: FormValues) {
  const errors: FormikErrors<FormValues> = {};
  // password
  if (!currentPassword) {
    errors.currentPassword = 'Required';
  }
  // email
  if (!email) {
    errors.email = 'Required';
  } else if (!emailValidator(email)) {
    errors.email = 'Please enter a valid email address';
  }
  return errors;
}

/**
 * On failure, it will return a rejected promise yielding a `FetchError`.
 * See `fetchUtils` for more info.
 */
export function submit(
  userId: string,
  values: FormValues
): Promise<
  { success: true; message: never } | { success: false; message: string }
> {
  return userApi
    .changeEmail(userId, { ...values, _id: userId })
    .then(result => {
      if (isArdoqError(result)) {
        if (api.isConflict(result))
          return { success: false, message: getArdoqErrorMessage(result) };
        throw result;
      }
      return { success: true } as { success: true; message: never };
    });
}
