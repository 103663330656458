import ViewContainer from '../../../tabview/ViewContainer';
import { colors, s16 } from '@ardoq/design-tokens';
import { SIMPLE_GRAPH_BACKGROUND_COLOR_CSS_VARIABLE } from './SimpleGraphView';
import dottedBackground from '../dotted-bg-darker.svg';
import styled from 'styled-components';
import React from 'react';

export const SimpleGraphContainerWithGreyBackground = styled(ViewContainer)<{
  $margin?: React.CSSProperties['margin'];
}>`
  height: 100%;
  margin: ${({ $margin }) =>
    $margin !== undefined
      ? $margin
      : s16}; /* creates more pleasant dotted-background effect */
  /*
    If you need non-white background for the graph, you need to set background-color
    and this variable to the same value so that the label background matches the graph background.
   */

  ${SIMPLE_GRAPH_BACKGROUND_COLOR_CSS_VARIABLE}: ${colors.grey95};
  background-color: ${colors.grey95};
  /* To ensure inline SVGs work in Vite, we need to wrap the URL in double quotes. */
  /* prettier-ignore */
  background-image: url("${dottedBackground}");
  background-size: 20px 20px;
  background-repeat: repeat;
`;
