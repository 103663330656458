import { action$, actionCreator, reducer, reduceState } from '@ardoq/rxbeach';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { combineLatest } from 'rxjs';
import activeView$ from './views/mainContent/activeView$';
import { map, shareReplay } from 'rxjs/operators';

type ViewIdToComponentIds = Partial<Record<ViewIds, ArdoqId[]>>;

export const readViewComponentIds = actionCreator<ViewIdToComponentIds>(
  '[viewComponentIds$] READ_VIEW_COMPONENT_IDS'
);

const handleReadViewComponentIdsReducer = (
  state: ViewIdToComponentIds,
  update: ViewIdToComponentIds
) => ({ ...state, ...update });

const defaultState: ViewIdToComponentIds = {};

const viewIdToComponentIds$ = action$.pipe(
  reduceState<ViewIdToComponentIds>('viewIdToComponentIds$', defaultState, [
    reducer(readViewComponentIds, handleReadViewComponentIdsReducer),
  ])
);

const viewComponentIds$ = combineLatest([
  viewIdToComponentIds$,
  activeView$,
]).pipe(
  map(
    ([viewIdToComponentIds, { mainViewId }]) =>
      viewIdToComponentIds[mainViewId] || []
  ),
  shareReplay({ bufferSize: 1, refCount: true })
);

export default viewComponentIds$;
