import styled, { css } from 'styled-components';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { AnalyticsIcon, ICON_SELECTOR, IconSize } from '@ardoq/icons';
import { Chip } from '@ardoq/status-ui';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ClearChip = styled(Chip)``;

const AssetChip = styled(Chip)`
  user-select: none;

  ${props =>
    !props.isActive &&
    css`
      ${ICON_SELECTOR} {
        color: ${colors.grey60};
      }
    `}
`;

const SplitBar = styled.div`
  height: 32px;
  border-left: 1px solid ${colors.grey80};
  margin-left: ${s8};
  margin-right: ${s16};
`;

interface ChipFilterBarProps {
  isFiltered: boolean;
  setIsFiltered: (state: boolean) => void;
}

const ChipFilterBar = ({ isFiltered, setIsFiltered }: ChipFilterBarProps) => {
  return (
    <Container>
      <ClearChip
        data-click-id="clear-field-filters-chip"
        onClick={() => setIsFiltered(false)}
      >
        Clear
      </ClearChip>
      <SplitBar />
      <AssetChip
        data-click-id="filter-calculated-chip"
        isActive={isFiltered}
        onClick={() => setIsFiltered(!isFiltered)}
        icon={<AnalyticsIcon iconSize={IconSize.SMALL} />}
      >
        Calculated
      </AssetChip>
    </Container>
  );
};

export default ChipFilterBar;
