import { Badge, StatusType } from '@ardoq/status-ui';

const TOKEN_ACTIVE_LABEL = 'Active';
const TOKEN_EXPIRED_LABEL = 'Expired';

export const TokenStatus = ({ isActive }: { isActive: boolean }) => {
  return (
    <Badge
      statusType={isActive ? StatusType.SUCCESS : StatusType.ERROR}
      label={isActive ? TOKEN_ACTIVE_LABEL : TOKEN_EXPIRED_LABEL}
    />
  );
};
