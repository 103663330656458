import AudienceChip from 'broadcasts/components/audienceTag/AudienceTag';
import audienceTags$ from './audienceTags$';
import { BroadcastAudience } from '@ardoq/api-types';
import { AudienceTagsStreamShape } from './types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { AudiencePreviewStatus } from 'broadcasts/broadcastBuilder/audiencePreview/types';
import {
  openAudienceDialog,
  removeAudienceFromBroadcast,
} from 'broadcasts/actions';
import { TagGroup } from '@ardoq/status-ui';

type AudienceTagsOwnProps = {
  audiences: BroadcastAudience[];
  inReviewBroadcastDialog?: boolean;
  warnings?: string[] | undefined;
};

const audienceWarnings = {
  gremlin: 'No audience is found based on the current component scope',
  workspace: 'The selected people workspace is not found',
  email: 'No emails were added to the email audience',
  predefined: 'No audience is found based on the current component scope',
};

const AudienceTags = ({
  audiences,
  audienceCountByType,
  audienceWarningByType,
  canRunGremlinQueries,
  status,
  inReviewBroadcastDialog,
}: AudienceTagsOwnProps &
  AudienceTagsStreamShape & { canRunGremlinQueries: boolean }) => (
  <TagGroup>
    {audiences.map((audience, key) => (
      <AudienceChip
        key={key}
        audience={audience}
        resultCount={audienceCountByType[audience.audienceType]}
        disabled={
          status === AudiencePreviewStatus.INCOMPLETE_CONTENT_SELECTION ||
          (!canRunGremlinQueries &&
            ['workspace', 'gremlin'].includes(audience.audienceType))
        }
        warningMessage={
          audienceWarningByType[audience.audienceType] ||
          audienceWarnings[audience.audienceType]
        }
        onClick={() => {
          dispatchAction(openAudienceDialog(audience.audienceType));
        }}
        onRemove={() => {
          dispatchAction(removeAudienceFromBroadcast(audience.audienceType));
        }}
        inReviewBroadcastDialog={inReviewBroadcastDialog}
      />
    ))}
  </TagGroup>
);

export default connect(AudienceTags, audienceTags$);
