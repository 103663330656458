import { pluralize } from '@ardoq/common-helpers';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';

const CountContainer = styled.span`
  color: ${colors.grey50};
`;

const LabelContainer = styled.span`
  color: ${colors.grey15};
  white-space: pre;
`;

export const RadioItemLabel = ({
  label,
  count,
}: {
  label: string;
  count?: number;
}) => (
  <>
    <LabelContainer>{label} </LabelContainer>
    {count !== undefined && (
      <CountContainer>
        ({count} {pluralize('user', count)})
      </CountContainer>
    )}
  </>
);
