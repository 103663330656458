import { getFormattingTabInitialState } from 'perspective/perspectiveEditor/perspectivesEditor$/formattingTabReducers';
import {
  FormattingRule,
  addNewFormattingRule,
  deleteFormattingRule as deleteFormattingRuleHandler,
  reorderFormattingRules as reorderFormattingRulesHandler,
  updateFormattingRules as updateFormattingRulesHandler,
} from '@ardoq/perspectives';
import {
  UpdateFormattingRulePayload,
  UpdateLabelFormattingAtArgs,
} from 'perspective/perspectiveEditor/perspectivesEditor$/actions';
import {
  SetFormattingOptionsPayload,
  SetFormattingRulesPayload,
  SetLabelFormattingPayload,
} from './viewpointBuilderFormattingActions';
import { ViewpointFormattingState } from './viewpointFormattingTypes';
import { mapToViewpointBuilderFormattingViewState } from './mapToViewpointBuilderFormattingViewState';
import { ViewIds } from '@ardoq/api-types';
import { LabelFormattingInfo } from '@ardoq/data-model';
import { SelectedViewState } from '../selectedView/types';

const showReferenceTypeToggled = (
  state: ViewpointFormattingState,
  showReferenceType: boolean
): ViewpointFormattingState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    showReferenceType,
  },
});

const updateLabelFormattingAt = (
  state: ViewpointFormattingState,
  { index, ...rest }: UpdateLabelFormattingAtArgs
): ViewpointFormattingState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    labelFormatting: state.formattingTab.labelFormatting.toSpliced(index, 1, {
      ...state.formattingTab.labelFormatting[index],
      ...rest,
    }),
  },
});

const deleteLabelFormatting = (
  state: ViewpointFormattingState,
  deletedLabelFormattingIndex: number
): ViewpointFormattingState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    labelFormatting: state.formattingTab.labelFormatting.toSpliced(
      deletedLabelFormattingIndex,
      1
    ),
  },
});

const reorderLabelFormatting = (
  state: ViewpointFormattingState,
  reorderedLabelFormatting: LabelFormattingInfo[]
): ViewpointFormattingState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    labelFormatting: reorderedLabelFormatting,
  },
});

const createLabelFormatting = (
  state: ViewpointFormattingState,
  newLabelFormatting: LabelFormattingInfo
): ViewpointFormattingState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    labelFormatting: [
      ...(state.formattingTab.labelFormatting ?? []),
      newLabelFormatting,
    ],
  },
});

const createFormattingRule = (
  state: ViewpointFormattingState,
  newFormattingRule: FormattingRule
): ViewpointFormattingState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: addNewFormattingRule(
      state.formattingTab.conditionalFormattingRules,
      newFormattingRule
    ),
  },
});

const updateFormattingRule = (
  state: ViewpointFormattingState,
  {
    updatedFormattingRuleId,
    updatedFormattingRule,
  }: UpdateFormattingRulePayload
): ViewpointFormattingState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: updateFormattingRulesHandler(
      state.formattingTab.conditionalFormattingRules,
      updatedFormattingRuleId,
      updatedFormattingRule
    ),
  },
});

const deleteFormattingRule = (
  state: ViewpointFormattingState,
  deletedFormattingRuleId: string
): ViewpointFormattingState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: deleteFormattingRuleHandler(
      state.formattingTab.conditionalFormattingRules,
      deletedFormattingRuleId
    ),
  },
});

const reorderFormattingRules = (
  state: ViewpointFormattingState,
  reorderedFormattingRulesIds: string[]
): ViewpointFormattingState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: reorderFormattingRulesHandler(
      state.formattingTab.conditionalFormattingRules,
      reorderedFormattingRulesIds
    ),
  },
});

const setFormattingOptions = (
  state: ViewpointFormattingState,
  { viewpointBuilderFormattingOptions }: SetFormattingOptionsPayload
): ViewpointFormattingState => ({
  ...state,
  viewpointBuilderFormattingOptions,
});

const setViewName = (
  state: ViewpointFormattingState,
  { selectedView }: SelectedViewState
) => ({
  ...state,
  viewName: selectedView?.value || ViewIds.NONE,
});

const getInitialState = (): ViewpointFormattingState => {
  const { isAppliedFormattingIncluded, conditionalFormattingRules } =
    getFormattingTabInitialState();
  return {
    viewName: ViewIds.NONE,
    formattingTab: {
      isAppliedFormattingIncluded,
      conditionalFormattingRules,
      labelFormatting: [],
      showReferenceType: true,
    },
    viewpointBuilderFormattingOptions: {
      availableWorkspaces: [],
      organizationId: '',
      availableTags: [],
      workspaceIds: [],
      componentTypeNames: [],
      referenceTypeNames: [],
      componentFieldsByType: new Map(),
      referenceFieldsByType: new Map(),
      availableComponentFields: [],
      availableReferenceFields: [],
      asyncSuggestionsLoaders: {
        loadComponentKeySuggestionsAsync: fetchSuggestionsEmpty,
        loadComponentSuggestionsAsync: fetchSuggestionsEmpty,
        loadParentComponentSuggestionsAsync: fetchSuggestionsEmpty,
        loadComponentSuggestionsIncludingCurrentComponentAsync:
          fetchSuggestionsEmpty,
        loadWorkspaceSuggestionsAsync: fetchSuggestionsEmpty,
        loadUserSuggestionsAsync: fetchSuggestionsEmpty,
        loadTagSuggestionsAsync: fetchSuggestionsEmpty,
        loadComponentTypeNameSuggestionsAsync: fetchSuggestionsEmpty,
        loadReferenceTypeNameSuggestionsAsync: fetchSuggestionsEmpty,
        loadReferenceOIDSuggestionsAsync: fetchSuggestionsEmpty,
      },
      componentFormattingCustomLabels: [],
      referenceFormattingCustomLabels: [],
      tagOptions: [],
      asyncLabelLoaders: {
        getComponentKeyLabelAsync: getLabelAsyncEmpty,
        getComponentLabelAsync: getLabelAsyncEmpty,
        getWorkspaceLabelAsync: getLabelAsyncEmpty,
        getUserLabelAsync: getLabelAsyncEmpty,
        getReferenceLabelAsync: getLabelAsyncEmpty,
      },
    },
  };
};

const fetchSuggestionsEmpty = async () => [];

const getLabelAsyncEmpty = async () => undefined;

const getInitialViewState = () =>
  mapToViewpointBuilderFormattingViewState(getInitialState());

const setFormattingRules = (
  state: ViewpointFormattingState,
  { formattingRules }: SetFormattingRulesPayload
): ViewpointFormattingState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: formattingRules,
  },
});

const setLabelFormatting = (
  state: ViewpointFormattingState,
  { labelFormatting }: SetLabelFormattingPayload
): ViewpointFormattingState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    labelFormatting,
  },
});

const resetFormattingState = (): ViewpointFormattingState => getInitialState();

export const viewpointBuilderFormattingOperations = {
  getInitialState,
  getInitialViewState,
  showReferenceTypeToggled,
  updateLabelFormattingAt,
  reorderLabelFormatting,
  deleteLabelFormatting,
  createLabelFormatting,
  createFormattingRule,
  updateFormattingRule,
  deleteFormattingRule,
  reorderFormattingRules,
  setFormattingOptions,
  setFormattingRules,
  setLabelFormatting,
  resetFormattingState,
  setViewName,
};
