import { fieldInterface } from '@ardoq/field-interface';
import { CreatableSelect, Select, SelectOptionsOrGroups } from '@ardoq/select';
import { NewBadge } from '@ardoq/status-ui';
import { Field as FieldType } from 'integrations/common/streams/fields/types';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import { FieldMapping } from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { byLocaleField } from 'integrations/common/utils/common';
import { useMemo } from 'react';
import {
  MappedColumnsErrors,
  SidebarSelectorsIds,
} from 'integrations/common/streams/tabularMappingErrors/types';

type FieldProps = {
  tableMapping: TableMappingMap;
  fieldMapping: FieldMapping;
  usedFields: FieldType[];
  allFields: FieldType[];
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
  isCreationDisabled: boolean;
  mappedColumnsErrors: MappedColumnsErrors;
  fieldTypeOptions: SelectOptionsOrGroups<string>;
};

export const Field = ({
  tableMapping,
  fieldMapping,
  usedFields,
  allFields,
  onMapping,
  isCreationDisabled,
  mappedColumnsErrors,
  fieldTypeOptions,
}: FieldProps) => {
  const wsFields = tableMapping.rootWorkspace.id
    ? fieldInterface
        .getAllFieldsOfWorkspace(tableMapping.rootWorkspace.id)
        .sort(byLocaleField('label'))
    : [];

  const orgFields = useMemo(
    () => [...allFields].sort(byLocaleField('label')),
    [allFields]
  );

  const errorsExist = Object.values(mappedColumnsErrors).some(
    column => column !== null
  );

  const options = [
    {
      options: usedFields.map(f => ({
        value: f.name || f.label, // support for new fields without name
        label: f.label,
        rightContent: !f.name && !errorsExist ? <NewBadge /> : null,
      })),
      label: 'Used in import',
    },
    {
      options: wsFields.map(f => ({
        value: f.name,
        label: f.label,
      })),
      label: 'In workspace',
    },
    {
      options: orgFields.map(f => ({
        value: f.name,
        label: f.label,
      })),
      label: 'All fields',
    },
  ];

  const FieldSelector = isCreationDisabled ? Select : CreatableSelect;

  return (
    <>
      <FieldSelector
        dataTestId="tabularMapping-field-name--select"
        autoFocus
        label="Name"
        value={fieldMapping.fieldName || fieldMapping.fieldLabel}
        hasError={!!mappedColumnsErrors[SidebarSelectorsIds.FIELD_NAME]}
        errorMessage={
          mappedColumnsErrors[SidebarSelectorsIds.FIELD_NAME] || undefined
        }
        options={options}
        onCreateOption={value => {
          onMapping({
            ...fieldMapping,
            fieldName: null, // a new field does not have fieldName
            fieldLabel: value,
          });
        }}
        onValueChange={value => {
          if (!value) return;
          const selectedField = allFields.find(f => f.name === value);

          if (!selectedField) {
            onMapping({
              ...fieldMapping,
              fieldName: null,
              fieldLabel: value,
            });
            return;
          }

          onMapping({
            fieldName: selectedField.name,
            fieldLabel: selectedField.label,
            fieldType: selectedField.type,
          });
        }}
        isValidNewOption={value => {
          return !options.some(group =>
            group.options.some(
              o => o.label.toLowerCase() === value.trim().toLowerCase()
            )
          );
        }}
      />

      <Select
        dataTestId="tabularMapping-field-type--select"
        label="Type"
        value={fieldMapping.fieldType}
        options={fieldTypeOptions}
        isDisabled={Boolean(fieldMapping.fieldName)}
        onValueChange={value => {
          if (value) {
            onMapping({
              ...fieldMapping,
              fieldType: value,
            });
          }
        }}
      />
    </>
  );
};
