import { FlexBox } from '@ardoq/layout';
import React from 'react';
import { Icon } from '@ardoq/icons';
import { Integration } from 'integrations/types';
import { Paragraph } from '@ardoq/typography';
import styled from 'styled-components';
import { colors, shadowL } from '@ardoq/design-tokens';

const ApiGuideBoxContainer = styled(FlexBox)<{
  $isSmall?: boolean;
  $isDisabled?: boolean;
}>`
  width: ${({ $isSmall }) => ($isSmall ? '190px' : '210px')};
  border: 1px solid ${colors.borderAction};
  background-color: ${colors.surfaceDefault};

  ${({ $isDisabled }) =>
    !$isDisabled &&
    `
    &:hover {
      cursor: pointer;
      box-shadow: ${shadowL};
      transition: box-shadow 200ms ease-in-out;
    }
  `}
`;

const LogoContainer = styled.div<{
  $isDeprecated?: boolean;
  $isDisabled?: boolean;
}>`
  height: 48px;
  width: 48px;
  max-width: 120px;
  opacity: ${props => (props.$isDisabled ? 0.5 : 1)};

  img {
    max-width: 48px;
    max-height: 48px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: ${props =>
      props.$isDeprecated || props.$isDisabled
        ? 'grayscale(100%)'
        : 'grayscale(0)'};
  }
`;

const ApiGuideBox = ({
  integration,
  onClick,
  isSmall = false,
}: {
  integration: Integration;
  onClick: (integration: Integration) => void;
  isSmall?: boolean;
}) => {
  const isDisabled = Boolean(integration.disableOptions?.isDisabled);
  const logo = integration.logoIcon ? (
    <Icon
      iconName={integration.logoIcon}
      style={{
        height: '100%',
        width: '100%',
        color: integration.logoIconColor,
      }}
    />
  ) : (
    <img src={integration.logoUrl} alt={`${integration.name} logo`} />
  );
  return (
    <ApiGuideBoxContainer
      padding="small"
      borderRadius="r8"
      gap="medium"
      align="center"
      DEPRECATED_onClick={() => onClick(integration)}
      $isSmall={isSmall}
      $isDisabled={isDisabled}
    >
      <LogoContainer
        $isDeprecated={integration.isDeprecated}
        $isDisabled={isDisabled}
      >
        {logo}
      </LogoContainer>
      <Paragraph variant={isSmall ? 'caption' : 'text2'} align="left">
        {integration.name}
      </Paragraph>
    </ApiGuideBoxContainer>
  );
};

export default ApiGuideBox;
