import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { SelectionState, SelectionRequestPayload } from './types';
import {
  setSelectionAsyncStatus,
  resetSelection,
  applySubscriptions,
  setActiveFilter,
  setFetchErrorMessage,
  setSelectionRequest,
  fetchSelectionSuccess,
} from './actions';
import { AsyncStatus } from 'integrations/common/types/api';
import { Maybe } from '@ardoq/common-helpers';
import { SelectionResponse } from '@ardoq/api-types/integrations';

const initialState: SelectionState = {
  asyncStatus: 'INIT',
  subscriptionIds: [],
  selectionRequest: null,
  selectionResponse: null,
  activeFilterId: null,
  fetchErrorMessage: null,
};

const resetSelectionReducer = () => initialState;
const handleResetSelectionState = reducer<SelectionState, void>(
  resetSelection,
  resetSelectionReducer
);

const applySubscriptionsReducer = (
  state: SelectionState,
  subscriptionIds: string[]
) => ({
  ...state,
  subscriptionIds,
});
const handleApplySubscriptions = reducer<SelectionState, string[]>(
  applySubscriptions,
  applySubscriptionsReducer
);

// filtering

const setActiveFilterReducer = (
  state: SelectionState,
  activeFilterId: Maybe<string>
) => ({
  ...state,
  activeFilterId:
    state.activeFilterId === activeFilterId ? null : activeFilterId,
});
const handleSetActiveFilter = reducer<SelectionState, Maybe<string>>(
  setActiveFilter,
  setActiveFilterReducer
);

// fetching

const setSelectionRequestReducer = (
  state: SelectionState,
  selectionRequest: SelectionRequestPayload
): SelectionState => {
  return {
    ...state,
    selectionRequest,
  };
};

const handleSetSelectionRequest = reducer<
  SelectionState,
  SelectionRequestPayload
>(setSelectionRequest, setSelectionRequestReducer);

const fetchSelectionSuccessReducer = (
  state: SelectionState,
  selectionResponse: SelectionResponse
): SelectionState => {
  return {
    ...state,
    selectionResponse: selectionResponse,
    asyncStatus: 'SUCCESS',
  };
};

const handleFetchSelectionSuccess = reducer<SelectionState, SelectionResponse>(
  fetchSelectionSuccess,
  fetchSelectionSuccessReducer
);

const setSelectionAsyncStatusReducer = (
  state: SelectionState,
  asyncStatus: AsyncStatus
) => ({
  ...state,
  asyncStatus,
});
const handleSetSelectionAsyncStatus = reducer<SelectionState, AsyncStatus>(
  setSelectionAsyncStatus,
  setSelectionAsyncStatusReducer
);

const setFetchErrorMessageReducer = (
  state: SelectionState,
  fetchErrorMessage: string | null
) => ({
  ...state,
  fetchErrorMessage,
});
const handleSetFetchErrorMessage = reducer<SelectionState, string | null>(
  setFetchErrorMessage,
  setFetchErrorMessageReducer
);

const reducers = [
  handleSetSelectionRequest,
  handleFetchSelectionSuccess,
  handleSetSelectionAsyncStatus,
  handleResetSelectionState,
  handleApplySubscriptions,
  handleSetActiveFilter,
  handleSetFetchErrorMessage,
];

export const selectionState$ = persistentReducedStream(
  `azureSelection$`,
  initialState,
  reducers
);
