import { NonPersistedSubdivision, Subdivision } from '@ardoq/api-types';
import {
  SubdivisionEditorSteps,
  SubdivisionEditorSubStep,
  SubdivisionNavigationState,
} from './navigation/types';
import { subdivisionEditorCommands } from './subdivisionEditorViewModel$/commands';
import { ZonesViewModelState } from './Steps/permissionsConfiguration/zones/types';
import {
  BindWorkspacesCommands,
  BindWorkspacesState,
} from './Steps/BindWorkspaces/types';
import { ComponentSelectionState } from './Steps/SelectComponents/types';
import { SubdivisionsById } from '@ardoq/subdivisions';
import { SelectComponentsCommands } from './Steps/SelectComponents/selectComponentsCommands';

export type SubdivisionViewModelState = SubdivisionNavigationState & {
  subdivision: Subdivision | NonPersistedSubdivision;
  subdivisions: SubdivisionsById;
  missingSubdivision: boolean;
  hasNewCoreJourneyFeature: boolean;
  isSubmitting: boolean;
  submittingError: string | null;
  stepsErrors: StepsValidationErrorMap;
  touchedSteps: Record<SubdivisionEditorSteps, boolean>;
  collapsed: Record<SubdivisionEditorSteps, boolean>;
  isFetchingSubdivisions: boolean;
  zonesState: ZonesViewModelState;
  isOrgAdmin: boolean;
  bindWorkspacesState: BindWorkspacesState;
  componentsSelection: ComponentSelectionState;
};

export type SubdivisionCommands = typeof subdivisionEditorCommands;

export type SubdivisionProps = SubdivisionViewModelState & {
  commands: SubdivisionCommands;
  selectComponentsCommands: SelectComponentsCommands;
  bindWorkspacesCommands: BindWorkspacesCommands;
};

export type ValidationErrors = {
  [key in keyof Subdivision | keyof Subdivision['style']]?: string;
} & {
  selectedWorkspaces?: string;
  zoneName?: string;
};

export type StepsValidationErrorMap = Record<
  SubdivisionEditorSteps | SubdivisionEditorSubStep,
  ValidationErrors
>;

export enum StepStateText {
  INVALID = 'Invalid',
  PENDING = 'Pending',
  EDITED = 'In progress',
  DONE = 'Completed',
}
