import { ReactNode } from 'react';
import {
  AllPossibleQuestions,
  MaybePersistedFieldOrAttributeQuestion,
  SurveyQuestionValidations,
  SurveyTypeOption,
} from 'surveyAdmin/types';
import { FieldsWrapper } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import {
  isFieldSelectedOrNotDuplicated,
  getFieldQuestionErrors,
  getSortedFields,
  isReadonlyField,
} from './utils';
import {
  APIFieldAttributes,
  ArdoqId,
  PersistedHiddenQuestion,
  SurveyQuestionType,
  UnpersistedHiddenQuestion,
} from '@ardoq/api-types';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { FieldQuestionData } from './types';
import { isFieldQuestion, isHiddenFieldQuestion } from './questionPredicates';

type FieldSelectProps = {
  question:
    | MaybePersistedFieldOrAttributeQuestion
    | UnpersistedHiddenQuestion
    | PersistedHiddenQuestion;
  questions?: AllPossibleQuestions[];
  fields: APIFieldAttributes[];
  attributes: {
    label: string;
    attributeName: string;
  }[];
  workspaceId: ArdoqId | null;
  questionValidations: SurveyQuestionValidations;
  updateFieldQuestion: (fieldQuestionData: FieldQuestionData) => void;
  popoverHelpContent?: ReactNode;
  parentQuestionIsReadonly?: boolean;
  hintMessage?: string;
  shouldShowErrorMessagesIfPresent?: boolean;
  onBlur?: () => void;
};

const getSortedAttributes = (
  attributes: {
    label: string;
    attributeName: string;
  }[],
  questions?: AllPossibleQuestions[],
  selectedField?: string
): SurveyTypeOption[] => {
  const locale = getCurrentLocale();
  return [
    ...attributes.map(({ label, attributeName }) => ({
      label,
      value: attributeName,
      type: SurveyQuestionType.ATTRIBUTE as const,
    })),
  ]
    .filter(opt =>
      isFieldSelectedOrNotDuplicated(opt, questions, selectedField)
    )
    .sort((a, b) => localeCompare(a.label, b.label, locale));
};

const FieldSelect = ({
  question,
  fields,
  attributes,
  questions,
  workspaceId,
  questionValidations,
  updateFieldQuestion,
  popoverHelpContent,
  parentQuestionIsReadonly,
  hintMessage,
  shouldShowErrorMessagesIfPresent = true,
  onBlur = () => null,
}: FieldSelectProps) => {
  const selectedField =
    isFieldQuestion(question) || isHiddenFieldQuestion(question)
      ? question.properties.fieldName
      : question.properties.attributeName;

  const attributeOptions = getSortedAttributes(
    attributes,
    questions,
    selectedField
  );

  const fieldOptions = getSortedFields(fields, questions, selectedField);
  const options = [...attributeOptions, ...fieldOptions];

  return (
    <FieldsWrapper>
      <Select
        options={[
          {
            label: 'Default fields',
            options: attributeOptions,
          },
          {
            label: 'Custom fields',
            options: fieldOptions,
          },
        ]}
        label="Select field"
        placeholder="Select a field..."
        dataTestId="field-select"
        isClearable={false}
        value={selectedField}
        onChange={option => {
          if (!option) return;
          const { value, type, fieldType, description, label } =
            option as SurveyTypeOption;
          const readonly = parentQuestionIsReadonly
            ? parentQuestionIsReadonly
            : isReadonlyField(value, workspaceId);
          updateFieldQuestion({
            type,
            readonly,
            value,
            fieldType,
            description,
            label,
          });
        }}
        hintMessage={hintMessage}
        onBlur={onBlur}
        errorMessage={
          shouldShowErrorMessagesIfPresent
            ? getFieldQuestionErrors(
                question,
                questionValidations,
                options,
                selectedField
              )
            : undefined
        }
        popoverHelpContent={popoverHelpContent}
      />
    </FieldsWrapper>
  );
};

export default FieldSelect;
