import { TraversalBuilderState } from '../types';

// Rebuilds source and target map based on all the references.
export const updateSelectedGraph = (
  referenceMap: TraversalBuilderState['selectedGraph']['referenceMap']
) => {
  const sourceMap: TraversalBuilderState['selectedGraph']['sourceMap'] =
    new Map();
  const targetMap: TraversalBuilderState['selectedGraph']['targetMap'] =
    new Map();

  Array.from(referenceMap).forEach(
    ([referenceId, { sourceId, targetId, tripleId }]) => {
      let outgoingConnections = sourceMap.get(sourceId);
      if (!outgoingConnections) {
        outgoingConnections = [];
        sourceMap.set(sourceId, outgoingConnections);
      }
      outgoingConnections.push({
        componentId: targetId,
        referenceId,
        tripleId,
      });
      let incomingConnections = targetMap.get(targetId);
      if (!incomingConnections) {
        incomingConnections = [];
        targetMap.set(targetId, incomingConnections);
      }
      incomingConnections.push({
        componentId: sourceId,
        referenceId,
        tripleId,
      });
    }
  );
  return { referenceMap, targetMap, sourceMap };
};
