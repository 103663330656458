import { forwardRef } from 'react';
import DashboardBuilderSidebar from './DashboardBuilderSidebar';
import { TextInput } from '@ardoq/forms';
import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateSelectedWidget } from '../actions';

interface WidgetSidebarProps {
  headingText?: string;
  isWidgetRecentlyCreated?: boolean;
}

const HeaderWidgetSidebar = forwardRef<HTMLFormElement, WidgetSidebarProps>(
  ({ headingText, isWidgetRecentlyCreated }, ref) => {
    const title = isWidgetRecentlyCreated
      ? 'Add new heading'
      : `Edit ${headingText && `"${headingText}"`} heading`;
    return (
      <DashboardBuilderSidebar title={title} ref={ref}>
        <SidebarMenuSection title="Heading name" isCollapsible={false}>
          <TextInput
            dataTestId="dashboard-header-name-input"
            autoFocus
            label="Give your heading a name"
            value={headingText ?? ''}
            onValueChange={newHeadingName =>
              dispatchAction(updateSelectedWidget({ content: newHeadingName }))
            }
          />
        </SidebarMenuSection>
      </DashboardBuilderSidebar>
    );
  }
);

export default HeaderWidgetSidebar;
