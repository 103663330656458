import { Route } from '../router/StreamRouter';
import { AppRouterState, InventoryModuleRoute } from '../router/appRouterTypes';
import { InventoryModule } from './consts';
import { AppModules } from '../appContainer/types';
import { inventoryModuleWasSelected } from '../router/navigationActions';
import { dispatchAction } from '@ardoq/rxbeach';
import inventoryNavigation$ from './inventoryNavigation$';

const inventoryDataOverviewRegex = /^\/inventory\/overview\/?$/;
const inventoryBrowserWorkspaceRegex =
  /^\/inventory\/browse\/workspace\/([a-f0-9+]+)\/?$/;
const inventoryBrowserReportRegex =
  /^\/inventory\/browse\/report\/([a-f0-9]+)\/?$/;

const separator = '+';

export const inventoryModuleRoute = new Route<
  AppRouterState,
  InventoryModuleRoute
>({
  doesLocationMatch: ({ path }) => {
    return (
      inventoryDataOverviewRegex.test(path) ||
      inventoryBrowserWorkspaceRegex.test(path) ||
      inventoryBrowserReportRegex.test(path)
    );
  },
  locationToRouterState: ({ path }) => {
    const inventoryModule = inventoryDataOverviewRegex.test(path)
      ? InventoryModule.OVERVIEW
      : InventoryModule.BROWSE;
    const selectedWorkspaceIds = path
      .match(inventoryBrowserWorkspaceRegex)?.[1]
      .split(separator);
    const selectedReportId = path.match(inventoryBrowserReportRegex)?.[1];

    if (
      inventoryModule === InventoryModule.OVERVIEW ||
      !(selectedReportId || selectedWorkspaceIds)
    ) {
      return {
        appModule: AppModules.INVENTORY,
        inventoryModule: InventoryModule.OVERVIEW,
        dataSourceType: 'none',
      };
    }
    if (selectedWorkspaceIds) {
      return {
        appModule: AppModules.INVENTORY,
        inventoryModule: InventoryModule.BROWSE,
        dataSourceType: 'workspace',
        selectedWorkspaceIds: selectedWorkspaceIds,
      };
    }
    return {
      appModule: AppModules.INVENTORY,
      inventoryModule: InventoryModule.BROWSE,
      dataSourceType: 'report',
      selectedReportId: selectedReportId!,
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.INVENTORY,
  routerStateToLocation: navigationState => {
    if (navigationState.inventoryModule === InventoryModule.OVERVIEW) {
      return {
        path: '/inventory/overview',
        title: 'Data Home',
      };
    }
    switch (navigationState.dataSourceType) {
      case 'workspace':
        return {
          title: 'Browse data',
          path: `/inventory/browse/workspace/${navigationState.selectedWorkspaceIds.join(separator)}`,
        };
      case 'componentType':
        return {
          title: 'Browse data',
          path: `/inventory/browse/component-type/${navigationState.componentTypeNames.join(separator)}`,
        };
      case 'report':
        return {
          title: 'Browse data',
          path: `/inventory/browse/report/${navigationState.selectedReportId}`,
        };
      default:
        navigationState satisfies 'never';
        return {
          path: '/inventory/overview',
          title: 'Data Home',
        };
    }
  },
  setApplicationStateFromRoute: navigationState => {
    switch (navigationState.dataSourceType) {
      case 'none':
        dispatchAction(
          inventoryModuleWasSelected({
            inventoryModule: InventoryModule.OVERVIEW,
            dataSourceType: 'none',
          })
        );
        break;
      case 'workspace':
        dispatchAction(
          inventoryModuleWasSelected({
            inventoryModule: InventoryModule.BROWSE,
            dataSourceType: 'workspace',
            selectedWorkspaceIds: navigationState.selectedWorkspaceIds,
          })
        );
        break;
      case 'componentType':
        dispatchAction(
          inventoryModuleWasSelected({
            inventoryModule: InventoryModule.BROWSE,
            dataSourceType: 'componentType',
            componentTypeNames: navigationState.componentTypeNames,
          })
        );
        break;
      case 'report':
        dispatchAction(
          inventoryModuleWasSelected({
            inventoryModule: InventoryModule.BROWSE,
            dataSourceType: 'report',
            selectedReportId: navigationState.selectedReportId,
          })
        );
        break;
      default:
        navigationState satisfies 'never';
        break;
    }
  },
  getPartialRouterStateStream: () => inventoryNavigation$.pipe(),
});
