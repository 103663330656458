import { showSupport } from 'utils/support';
import { SmallPrimaryButton } from '@ardoq/button';
import { pluralize } from '@ardoq/common-helpers';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';

const TrialDaysInquiryButton = styled.div`
  flex: 1 0 auto;
  button {
    background: transparent;
    color: ${colors.blue50};
  }
  button:hover {
    background: ${colors.white};
  }
`;

interface TrialDaysRemainingProps {
  daysRemaining: number;
}

const TrialDaysRemaining = ({ daysRemaining }: TrialDaysRemainingProps) => {
  const contactSales = () => {
    showSupport({
      prePopulateMessage: "Hi, I'd like to upgrade my trial.",
    });
  };
  const actualDaysRemaining = daysRemaining <= 0 ? 0 : daysRemaining;

  return (
    <TrialDaysInquiryButton>
      <SmallPrimaryButton onClick={contactSales}>
        {`${daysRemaining} ${pluralize(
          'day',
          actualDaysRemaining
        )} left of trial.${
          actualDaysRemaining === 0 ? ' Click to contact Ardoq.' : ''
        }`}
      </SmallPrimaryButton>
    </TrialDaysInquiryButton>
  );
};

export default TrialDaysRemaining;
