import { from, combineLatest, of } from 'rxjs';
import { filter, switchMap, tap, catchError, map } from 'rxjs/operators';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import {
  ApiError,
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';
import { initIntegration } from 'integrations/actions';
import { getErrorStatusCode } from '@ardoq/api';
import {
  listConnections,
  upsertConnection,
  updateConnectionStatuses,
  updateConnectionList,
  deleteConnection,
  deleteConnectionFailure,
  upsertConnectionFailure,
} from './actions';
import * as apiUtils from './apiUtils';
import { getConnectionMethod, isIntegrationWithConnections } from './utils';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';

const handleFetchListConnections = routine(
  ofType(listConnections),
  extractPayload(),
  switchMap(({ integrationId }) => {
    dispatchAction(
      updateConnectionStatuses({
        integrationId,
        statuses: {
          list: { status: 'LOADING' },
        },
      })
    );
    return combineLatest([
      of(integrationId),
      retriableRequest(
        () => apiUtils.fetchConnectionsList(integrationId),
        'Unable to fetch connections list'
      ).pipe(
        catchError(error => {
          dispatchAction(
            updateConnectionStatuses({
              integrationId,
              statuses: {
                list: {
                  status: 'FAILURE',
                  message:
                    "We're unable to fetch the connections. Please refresh the app and try again.",
                },
              },
            })
          );
          return handleApiError(error);
        })
      ),
    ]);
  }),
  tap(([integrationId, connections]) => {
    dispatchAction(
      updateConnectionList({ integrationId, connectionsState: { connections } })
    );
    dispatchAction(
      updateConnectionStatuses({
        integrationId,
        statuses: { list: { status: 'SUCCESS' } },
      })
    );
  })
);

const handleUpsertConnection = routine(
  ofType(upsertConnection),
  extractPayload(),
  switchMap(({ integrationId, connection }) => {
    dispatchAction(
      updateConnectionStatuses({
        integrationId,
        statuses: { upsert: { status: 'LOADING' } },
      })
    );
    return combineLatest([
      of(integrationId),
      of(getConnectionMethod(connection)),
      from(apiUtils.upsertConnectionRequest(integrationId, connection)).pipe(
        map(response => {
          if (isArdoqError(response)) {
            throw new ApiError(
              `Unable to upsert the ${integrationId} connection`,
              getErrorStatusCode(response),
              getArdoqErrorMessage(response),
              { readableMessage: getArdoqErrorMessage(response) }
            );
          }
          return response;
        }),
        catchError(error => {
          const message =
            error.extraData?.readableMessage ||
            "Something went wrong, and we're unable to create a new connection. Please try connecting again. Contact support if the problem continues.";
          dispatchAction(
            upsertConnectionFailure({
              integrationId: integrationId,
              statuses: { upsert: { status: 'FAILURE', message } },
            })
          );

          return handleApiError(error);
        })
      ),
    ]);
  }),
  tap(([integrationId, authMethod]) => {
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'CREATED_CONNECTION',
        metadata: {
          authMethod: authMethod,
        },
      })
    );

    dispatchAction(
      updateConnectionStatuses({
        integrationId,
        statuses: { upsert: { status: 'SUCCESS' } },
      })
    );
    dispatchAction(listConnections({ integrationId }));
  })
);

const handleDeleteConnection = routine(
  ofType(deleteConnection),
  extractPayload(),
  switchMap(({ integrationId, connection }) => {
    dispatchAction(
      updateConnectionStatuses({
        integrationId,
        statuses: { delete: { status: 'LOADING' } },
      })
    );
    return combineLatest([
      of(integrationId),
      retriableRequest(
        () => apiUtils.deleteConnection(integrationId, connection),
        'Unable to delete the connection'
      ).pipe(
        catchError(error => {
          dispatchAction(
            deleteConnectionFailure({
              integrationId: integrationId,
              statuses: {
                delete: {
                  status: 'FAILURE',
                  message:
                    "We're unable to delete the connection. Please try again.",
                },
              },
            })
          );
          return handleApiError(error);
        })
      ),
    ]);
  }),
  tap(([integrationId]) => {
    dispatchAction(listConnections({ integrationId }));
    dispatchAction(
      updateConnectionStatuses({
        integrationId,
        statuses: { delete: { status: 'SUCCESS' } },
      })
    );
  })
);

const handleInitIntegration = routine(
  ofType(initIntegration),
  extractPayload(),
  filter(({ id }) => isIntegrationWithConnections(id as IntegrationId)),
  tap(({ id }) => {
    dispatchAction(listConnections({ integrationId: id as IntegrationId }));
  })
);

export default [
  handleFetchListConnections,
  handleUpsertConnection,
  handleDeleteConnection,
  handleInitIntegration,
];
