import { isArdoqError } from '@ardoq/common-helpers';
import { confirm, ModalSize, prompt } from '@ardoq/modal';
import { CopyModalProps } from './types';
import { trackEvent } from 'tracking/tracking';
import { ArdoqId, AssetType } from '@ardoq/api-types';
import { showToast, ToastType } from '@ardoq/status-ui';

export const copyModal = async ({
  entityName,
  copyEntity,
  entityType,
  openCopy,
}: CopyModalProps) => {
  // we don't expose the term "traversal" to the user
  const displayEntityType =
    entityType === AssetType.TRAVERSAL ? 'viewpoint' : entityType;
  const copyName = await prompt({
    title: `Copy ${displayEntityType}`,
    initialInputValue: `${entityName} (copy)`,
    subtitle: `Are you sure you want to copy "${entityName}"?`,
    inputLabel: 'New name',
    cancelButtonClickId: 'cancel-create-copy',
    confirmButtonClickId: 'confirm-create-copy',
    textInputId: 'copy-name-input',
    confirmButtonTitle: 'Create copy',
  });
  if (copyName) {
    trackEvent('Copy modal: confirmed copy asset', { assetType: entityType });
    const newCopy = await copyEntity(copyName);
    if (isArdoqError(newCopy) || newCopy._id === 'error') {
      return showToast(`Copying ${entityType} failed`, ToastType.INFO);
    }
    const copyId = newCopy._id;
    openCopiedEntityModal({ entityType, copyName, copyId, openCopy });
  }
};

type OpenCopiedEntityModal = {
  entityType: string;
  copyName: string;
  copyId: ArdoqId;
  openCopy: (copyId: ArdoqId) => void;
};

export const openCopiedEntityModal = ({
  entityType,
  copyName,
  copyId,
  openCopy,
}: OpenCopiedEntityModal) => {
  // we don't expose the term "traversal" to the user
  const displayEntityType =
    entityType === AssetType.TRAVERSAL ? 'viewpoint' : entityType;
  return confirm({
    modalSize: ModalSize.S,
    title: 'Copy created',
    subtitle: `Do you want to open the new ${displayEntityType} "${copyName}"?`,
    confirmButtonClickId: 'confirm-open-copy',
    confirmButtonTitle: 'Open copy',
    cancelButtonClickId: 'cancel-open-copy',
    cancelButtonTitle: 'Close',
    onConfirmAsync: () => {
      trackEvent('Copy modal: confirmed open copy', { assetType: entityType });
      openCopy(copyId);
    },
  });
};
