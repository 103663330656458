import Models from 'collections/models';
import NodeModel from 'models/node';
import Backbone from 'backbone';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';
import { createViewOptions } from 'models/utils/viewUtils';
import { getApiUrl } from 'backboneExtensions';
import type { Workspace as WorkspaceBackboneModel } from 'aqTypes';
import type { ViewIds } from '@ardoq/api-types';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { includes, isArray, omit, uniq, without } from 'lodash';
import { currentTimestamp } from '@ardoq/date-time';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

const getViewOptions = () => [...createViewOptions()];
type WorkspaceClass = {
  extend: typeof NodeModel.model.extend;
  new (attributes?: Record<string, any>): WorkspaceBackboneModel;
};
const Workspace: WorkspaceClass = NodeModel.model.extend({
  idAttribute: '_id',
  urlRoot: `${getApiUrl(Backbone.Model)}/api/workspace`,
  validationErrors: null,
  defaults: {
    name: '',
    views: [],
    description: '',
    folder: null,
    componentModel: null,
    type: '1',
    defaultPerspective: null,
    defaultSort: null,
    startView: null,
  },
  blacklist: [
    'components',
    'references',
    'stats',
    'linked-workspaces',
    'tags',
    'incoming-ext-refs',
  ],
  toJSON: function () {
    return omit(this.attributes, this.blacklist);
  },
  initialize: function () {
    NodeModel.model.prototype.initialize.apply(this);
    this.on('change', (model: WorkspaceBackboneModel) => {
      model.lastChangeTime = currentTimestamp();
      model.validationErrors = null;
    });

    this.on('merged', function (this: WorkspaceBackboneModel) {
      this.changedDescription = false;
    });
    this.on(
      'sync',
      function (this: WorkspaceBackboneModel) {
        this.changedDescription = false;
      },
      this
    );
  },
  validate: function (
    this: WorkspaceBackboneModel,
    attrs: Record<string, any>
  ) {
    const errors: Record<string, string> = {};
    if (!attrs.name) {
      errors.name = 'Name is required';
    }
    return Object.keys(errors).length ? errors : undefined;
  },
  removeView: function (this: WorkspaceBackboneModel, viewId: ViewIds) {
    let views = this.get('views') || [];
    views = without(views, viewId);
    this.set('views', views);
  },
  setViews: function (this: WorkspaceBackboneModel, views: ViewIds[]) {
    if (views && isArray(views)) {
      this.set('views', uniq(views));
    }
  },
  addView: function (this: WorkspaceBackboneModel, viewId: ViewIds) {
    const views: ViewIds[] = this.get('views') || [];
    if (!includes(views, viewId)) {
      views.push(viewId);
      this.set('views', views);
    }
  },
  getStartViewOptions: function () {
    return getViewOptions();
  },

  hasWriteAccess: function (this: WorkspaceBackboneModel) {
    // Scope components inherit the scenario permission
    // https://docs.google.com/document/d/1IpMzUI-ceZzWpq6PmkOJ9obzrDoTWIurEYz_DPDrSb4/edit#heading=h.34ywapr1sszf

    return workspaceAccessControlInterface.canEditWorkspace(
      currentUserInterface.getPermissionContext(),
      this.id,
      getActiveScenarioState()
    );
  },
  getModel: function (this: WorkspaceBackboneModel) {
    return Models.collection.get(this.getModelId());
  },
  getModelId: function (this: WorkspaceBackboneModel) {
    return this.get('componentModel');
  },
  changedAndMustBeSaved: function (this: WorkspaceBackboneModel) {
    return (
      this.validationErrors === null &&
      NodeModel.model.prototype.changedAndMustBeSaved.apply(this)
    );
  },
  getStats: function (this: WorkspaceBackboneModel) {
    return this.get('stats') || {};
  },
  getNumberOfComponents: function (this: WorkspaceBackboneModel) {
    return this.getStats().pages?.count ?? 0;
  },
  getNumberOfReferences: function (this: WorkspaceBackboneModel) {
    return this.getStats().links?.count ?? 0;
  },
  getWorkspaceViews: function (this: WorkspaceBackboneModel): ViewIds[] {
    return this.get('views');
  },
  hasView: function (this: WorkspaceBackboneModel, viewId: ViewIds) {
    return this.getWorkspaceViews().includes(viewId);
  },
  getCSS: function (this: WorkspaceBackboneModel) {
    return `workspace ${this.cid} ${this.id}`;
  },
  getFolder: function (this: WorkspaceBackboneModel) {
    return this.get('folder');
  },
  getIntegrations: function (this: WorkspaceBackboneModel) {
    return this.get('integrations') || [];
  },
});

function createWorkspace(props: Record<string, any>) {
  return new Workspace(props);
}

export default {
  model: Workspace,
  create: createWorkspace,
};
