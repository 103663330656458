import { TextInput } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import { ViewpointsStreamShape } from 'viewpoints/types';
import { connect } from '@ardoq/rxbeach';
import viewpoints$ from '../../viewpoints$';
import { RelativeDatePicker } from '@ardoq/date-time-picker';
import {
  CommonDefaultFilterKeys,
  ComponentDefaultFilterKeys,
  ReferenceDefaultFilterKeys,
} from '@ardoq/filter-interface';
import {
  APIDiscoverViewpointAttributes,
  ArdoqId,
  FormattingFilter,
  Workspace,
} from '@ardoq/api-types';
import AsyncSearch from './AsyncSearch';
import { useFormContext } from 'react-hook-form';
import {
  getSelectedComponentTypeNames,
  getSelectedReferenceTypeNames,
} from 'viewpoints/utils';

const getValueOptions = (
  format: FormattingFilter,
  workspaceIds: string[],
  workspaces: Workspace[] | null,
  componentTypesInViewpoint: string[],
  referenceTypesInViewpoint: string[]
) => {
  if (format.name === CommonDefaultFilterKeys.TYPE && format.affectComponent) {
    return componentTypesInViewpoint.map(componentType => ({
      label: componentType,
      value: componentType,
    }));
  }

  if (format.name === CommonDefaultFilterKeys.TYPE && format.affectReference) {
    return referenceTypesInViewpoint.map(referenceType => ({
      label: referenceType,
      value: referenceType,
    }));
  }

  if (
    format.name === CommonDefaultFilterKeys.ROOT_WORKSPACE ||
    format.name === ReferenceDefaultFilterKeys.TARGET_WORKSPACE
  ) {
    return workspaces
      ?.filter(workspace => workspaceIds.includes(workspace._id))
      ?.map(workspace => ({
        label: workspace.name,
        value: workspace._id,
      }));
  }
  return [];
};

type StandardFormattingFieldProps = {
  format: FormattingFilter;
  handleUpdateFormatting: (
    formattingProperty: Partial<FormattingFilter>
  ) => void;
  workspaceIds: ArdoqId[];
};

const StandardFormattingField = ({
  format,
  handleUpdateFormatting,
  workspaceIds,
  workspaces,
}: StandardFormattingFieldProps & ViewpointsStreamShape) => {
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const [steps] = watch(['dynamicGroupedTraversals']);
  const componentTypesInViewpoint = getSelectedComponentTypeNames(steps);
  const referenceTypesInViewpoint = getSelectedReferenceTypeNames(steps);

  const commonProps = {
    hasError: Boolean((format.name || format.comparator) && !format.value),
  };
  switch (format.name) {
    case CommonDefaultFilterKeys.ID:
    case ComponentDefaultFilterKeys.NAME:
    case ComponentDefaultFilterKeys.PARENT:
    case ReferenceDefaultFilterKeys.TARGET:
    case ReferenceDefaultFilterKeys.SOURCE:
      return (
        <AsyncSearch
          format={format}
          workspaceIds={workspaceIds}
          componentTypesInViewpoint={componentTypesInViewpoint}
          handleUpdateFormatting={handleUpdateFormatting}
        />
      );
    case CommonDefaultFilterKeys.LAST_UPDATED:
    case CommonDefaultFilterKeys.CREATED:
      return (
        <RelativeDatePicker
          value={format.value as string}
          placeholder="Select a date"
          onChange={value => handleUpdateFormatting({ value })}
          dataTestId="date-select"
          {...commonProps}
        />
      );
    case ReferenceDefaultFilterKeys.DISPLAY_TEXT:
    case CommonDefaultFilterKeys.DESCRIPTION:
    case ComponentDefaultFilterKeys.VERSION:
    case ComponentDefaultFilterKeys.INCOMING_REF_COUNT:
    case ComponentDefaultFilterKeys.OUTGOING_REF_COUNT:
      return (
        <TextInput
          value={format.value as string}
          placeholder="Value"
          onValueChange={value => handleUpdateFormatting({ value })}
          dataTestId="text-input"
          {...commonProps}
        />
      );
    case CommonDefaultFilterKeys.ROOT_WORKSPACE:
    case ReferenceDefaultFilterKeys.TARGET_WORKSPACE:
    case CommonDefaultFilterKeys.TYPE: {
      const options = getValueOptions(
        format,
        workspaceIds,
        workspaces,
        componentTypesInViewpoint,
        referenceTypesInViewpoint
      );
      const value = options?.find(option => option.value === format.value);
      const hasError = Boolean(
        (format.name || format.comparator) && (!format.value || !value)
      );
      return (
        <Select
          onValueChange={selectedValue => {
            if (!selectedValue) return;
            handleUpdateFormatting({ value: selectedValue });
          }}
          menuPlacement="auto"
          placeholder="Type or select your chosen value"
          options={options}
          value={value}
          dataTestId="select"
          hasError={hasError}
        />
      );
    }
    default:
      return (
        <TextInput
          value={format.value as string}
          placeholder="Value"
          onValueChange={value => handleUpdateFormatting({ value })}
          dataTestId="text-input"
          {...commonProps}
        />
      );
  }
};

export default connect(StandardFormattingField, viewpoints$);
