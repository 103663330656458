import { Button, ButtonType } from '@ardoq/button';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { Checkbox } from '@ardoq/forms';
import { CircleIcon, IconSize } from '@ardoq/icons';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { DecoratedSubdivision } from './types';
import { useState } from 'react';
import { Box, FlexBox, Stack } from '@ardoq/layout';

type SubdivisionDropdownButtonProps = {
  subdivisions: DecoratedSubdivision[];
  selection: DecoratedSubdivision[];
  onApply: () => void;
  onClear: () => void;
  onAddSubdivision: (subdivision: DecoratedSubdivision) => void;
  onRemoveSubdivision: (subdivision: DecoratedSubdivision) => void;
  children: React.ReactNode | React.ReactNode[];
  role: 'assign' | 'remove';
};

const getLabel = (
  subdivision: DecoratedSubdivision,
  role: 'assign' | 'remove'
) => {
  if (role === 'assign') return subdivision.name;
  if (subdivision.componentsCount === 0) return subdivision.name;
  return `${subdivision.name} (${subdivision.componentsCount})`;
};

export const SubdivisionDropdownButton = ({
  subdivisions,
  selection,
  onApply,
  onClear,
  onAddSubdivision,
  onRemoveSubdivision,
  children,
  role,
}: SubdivisionDropdownButtonProps) => {
  const { MANAGE_MEMBERSHIP_MODAL } = SUBDIVISIONS_STRINGS;

  const [isOpen, setIsOpen] = useState(false);

  const openDropdown = () => setIsOpen(true);
  const closeDropdown = () => setIsOpen(false);

  return (
    <ButtonWithDropdown
      isKeepOpen
      disablePortal
      isOpen={isOpen}
      onMenuOpen={openDropdown}
      onMenuClose={closeDropdown}
      options={subdivisions.map(subdivision => {
        const isSelected = selection.some(({ _id }) => _id === subdivision._id);
        return {
          leftContent: (
            <FlexBox>
              <Checkbox isChecked={isSelected} />
              <CircleIcon
                color={subdivision.style.color}
                size={IconSize.SMALL}
              />
            </FlexBox>
          ),
          label: getLabel(subdivision, role),
          onClick: () =>
            isSelected
              ? onRemoveSubdivision(subdivision)
              : onAddSubdivision(subdivision),
        };
      })}
      footerContent={
        <Box padding="small">
          <Stack gap="xsmall">
            <Button
              buttonType={ButtonType.PRIMARY}
              isFlexible
              onClick={() => {
                onApply();
                closeDropdown();
              }}
              isDisabled={selection.length === 0}
            >
              {MANAGE_MEMBERSHIP_MODAL.APPLY_SELECTION}
            </Button>
            <Button
              buttonType={ButtonType.SECONDARY}
              isFlexible
              onClick={onClear}
              isDisabled={selection.length === 0}
            >
              {MANAGE_MEMBERSHIP_MODAL.CLEAR_SELECTION}
            </Button>
          </Stack>
        </Box>
      }
      buttonType={ButtonType.GHOST}
    >
      {children}
    </ButtonWithDropdown>
  );
};
