import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  confirmApprovedChanges,
  fetchChangeApprovalData,
  cancelSavingApprovedChanges,
  notifySavingApprovedChangesSucceeded,
  saveApprovedChanges,
  setApprovedReferenceData,
  approveEntireReference,
  rejectEntireReference,
  resetDataForEntireReference,
  resetAnswers,
  resetInvalidChangeRequest,
} from './actions';
import { surveyAlert } from '../streams/surveyAlert';
import { openSurveyAdminView } from 'surveyAdmin/navigation/utils';
import changeApprovalData$ from './changeApprovalData$';
import { prepareAndSetChangeApprovalData$ } from './routineHelper';
import { trackEvent } from 'tracking/tracking';
import {
  getApprovedChangesForComponent,
  getApprovedChangesForReferences,
} from './utils';
import { isEmpty, omit } from 'lodash';
import { SurveyAdminMode } from 'surveyAdmin/navigation/types';
import { ToastType, showToast } from '@ardoq/status-ui';
import { handleError, surveyApi } from '@ardoq/api';

const handleFetchChangeApprovalData = routine(
  ofType(fetchChangeApprovalData),
  extractPayload(),
  switchMap(surveyApi.fetchPendingApproval),
  handleError(error => {
    surveyAlert({
      action: 'fetched',
      entity: 'The pending changes for this survey',
      additionalMessage:
        'This could be because the survey is no longer available, or response approval has since been turned off.',
      error,
    });
    openSurveyAdminView(SurveyAdminMode.OVERVIEW);
  }),
  tap(changeApprovalData => {
    prepareAndSetChangeApprovalData$(changeApprovalData);
  })
);

const handleConfirmApprovedChanges = routine(
  ofType(confirmApprovedChanges),
  extractPayload(),
  withLatestFrom(changeApprovalData$),
  tap(
    async ([
      surveyId,
      {
        masterData,
        branchData,
        approvedComponentChanges,
        componentId,
        approvedReferenceChanges,
        changedReferencesByComponentId,
        changedFieldsByComponentId,
        changeRequests,
      },
    ]) => {
      const referenceChanges =
        changedReferencesByComponentId[componentId] ?? {};
      const hasReferenceChanges = Boolean(
        Object.values(referenceChanges).length
      );

      if (!hasReferenceChanges) {
        const changes = {
          components: getApprovedChangesForComponent(
            changeRequests,
            approvedComponentChanges,
            componentId
          ),
        };
        dispatchAction(saveApprovedChanges({ surveyId, changes, componentId }));
        return;
      }

      const hasComponentChanges = !isEmpty(
        omit(changedFieldsByComponentId[componentId], 'references')
      );

      const componentChanges = hasComponentChanges
        ? getApprovedChangesForComponent(
            changeRequests,
            approvedComponentChanges,
            componentId
          )
        : {};

      const changes = getApprovedChangesForReferences(
        componentId,
        approvedReferenceChanges,
        referenceChanges,
        masterData,
        branchData,
        componentChanges
      );
      dispatchAction(saveApprovedChanges({ surveyId, changes, componentId }));
    }
  )
);

const handleSaveApprovedChanges = routine(
  ofType(saveApprovedChanges),
  extractPayload(),
  switchMap(({ surveyId, changes, componentId }) =>
    surveyApi.approvedChanges(surveyId, componentId, changes)
  ),
  handleError(error => {
    dispatchAction(cancelSavingApprovedChanges());
    surveyAlert({
      action: 'saved',
      entity: 'Your approved changes',
      error,
    });
  }),
  tap(changeApprovalData => {
    prepareAndSetChangeApprovalData$(changeApprovalData);
    dispatchAction(notifySavingApprovedChangesSucceeded());
  })
);

const handleSetApprovedReferenceData = routine(
  ofType(setApprovedReferenceData),
  extractPayload(),
  tap(({ switchPosition, reference }) => {
    if (switchPosition === 'approved') {
      trackEvent('Survey change approval: clicked approve all');
      dispatchAction(approveEntireReference(reference));
    } else if (switchPosition === 'rejected') {
      trackEvent('Survey change approval: clicked reject all');
      dispatchAction(rejectEntireReference(reference));
    } else {
      dispatchAction(resetDataForEntireReference(reference._id));
    }
  })
);

const handleClearAnswers = routine(
  ofType(resetAnswers),
  extractPayload(),
  switchMap(surveyApi.resetAnswers),
  handleError(error => {
    surveyAlert({
      action: 'deleted',
      entity: 'The pending answers for this survey',
      error,
    });
  }),
  tap(() => {
    showToast(
      'The pending answers for this survey were successfully deleted',
      ToastType.SUCCESS
    );
    openSurveyAdminView(SurveyAdminMode.OVERVIEW);
  })
);

const handleResetInvalidChangeRequest = routine(
  ofType(resetInvalidChangeRequest),
  extractPayload(),
  switchMap(({ surveyId, componentId }) =>
    surveyApi.resetInvalidChangeRequest(surveyId, componentId)
  ),
  handleError(error => {
    surveyAlert({
      action: 'deleted',
      entity: 'The pending answers for this component',
      error,
    });
  }),
  tap(changeApprovalData => {
    prepareAndSetChangeApprovalData$(changeApprovalData);
    showToast(
      'The pending answers for this component were successfully deleted',
      ToastType.SUCCESS
    );
  })
);

export default collectRoutines(
  handleFetchChangeApprovalData,
  handleConfirmApprovedChanges,
  handleSaveApprovedChanges,
  handleSetApprovedReferenceData,
  handleClearAnswers,
  handleResetInvalidChangeRequest
);
