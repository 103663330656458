import { TableDataRow, TablePreview } from './types';
import { AsyncStatus } from 'integrations/common/types/api';
import { actionCreatorWithIntegrationId } from '../../utils/actionCreatorWithIntegrationId';

const NAMESPACE = '[integrations] TABLE_PREVIEWS';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

type SetTablePreviewsPayload = {
  tablePreviews: TablePreview[];
};
export const setTablePreviews =
  actionCreatorWithIntegrationId<SetTablePreviewsPayload>(
    getActionName('SET_TABLE_PREVIEWS')
  );

type SetTableRowsPayload = {
  rows: TableDataRow[];
  tableId: string;
};
export const setTableDataRows =
  actionCreatorWithIntegrationId<SetTableRowsPayload>(
    getActionName('SET_TABLE_DATA_ROWS')
  );

type FetchRowsPayload = {
  sourceId: string;
  tableId: string;
};
export const fetchPreviewRows =
  actionCreatorWithIntegrationId<FetchRowsPayload>(
    getActionName('FETCH_PREVIEW_ROWS')
  );

// problem rows refers to both error and warnings
export const fetchProblemRows =
  actionCreatorWithIntegrationId<FetchRowsPayload>(
    getActionName('FETCH_PROBLEM_ROWS')
  );

type FetchPreviewRowsSuccessPayload = {
  tableId: string;
  header: string[];
  rows: TableDataRow[];
};
export const fetchPreviewRowsSuccess =
  actionCreatorWithIntegrationId<FetchPreviewRowsSuccessPayload>(
    getActionName('FETCH_PREVIEW_ROWS_SUCCESS')
  );

type SetFetchRowsStatusPayload = {
  tableId: string;
  fetchRowsStatus?: AsyncStatus;
  fetchProblemRowsStatus?: AsyncStatus;
};
export const setFetchRowsStatus =
  actionCreatorWithIntegrationId<SetFetchRowsStatusPayload>(
    getActionName('SET_FETCH_ROWS_STATUS')
  );

export const resetTablesRows = actionCreatorWithIntegrationId(
  getActionName('RESET_TABLES_ROWS')
);
