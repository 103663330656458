import { ArdoqId, DirectedTriple } from '@ardoq/api-types';
import { EnhancedScopeData } from '@ardoq/data-model';
import { actionCreator } from '@ardoq/rxbeach';

export type SetDrawerDataPayload = {
  scopeData: EnhancedScopeData;
  componentId: ArdoqId;
};

export const setDrawerData = actionCreator<SetDrawerDataPayload>(
  '[detailsDrawer] SET_DRAWER_DATA'
);

/**
 * shows the component details in the details drawer. the details drawer will open.
 *
 * only the first component will be shown, as the details drawer lacks the ability to display multiple components.
 *
 * do not dispatch this action with an empty payload!
 */
export const openDetailsDrawer = actionCreator<ArdoqId[]>(
  '[detailsDrawer] OPEN_DETAILS_DRAWER'
);
/**
 * shows the component details in the details drawer, if it is already open.
 *
 * only the first component will be shown, as the details drawer lacks the ability to display multiple components.
 *
 * do not dispatch this action with an empty payload!
 */
export const updateDetailsDrawer = actionCreator<ArdoqId[]>(
  '[detailsDrawer] UPDATE_DETAILS_DRAWER'
);

export const showDetailsDrawer = actionCreator(
  '[detailsDrawer] SHOW_DETAILS_DRAWER'
);

export const hideDetailsDrawer = actionCreator(
  '[detailsDrawer] HIDE_DETAILS_DRAWER'
);

type ToggleToggleTripleInViewPayload = {
  namedDirectedTriple: DirectedTriple;
  isSelected: boolean;
};

export const toggleTripleInView =
  actionCreator<ToggleToggleTripleInViewPayload>(
    '[detailsDrawer] TOGGLE_TRIPLE_IN_VIEW'
  );
