import { FlexBox, Stack } from '@ardoq/layout';
import {
  EmptyState,
  EmptyStateActions,
  EmptyStateToAddIllustration,
} from '@ardoq/status-ui';
import { SecondaryButton } from '@ardoq/button';
import { AddIcon } from '@ardoq/icons';
import { ZoneCommands } from '../types';
import {
  ComponentTypeFieldsGroupSearch,
  SearchByTypes,
} from './ComponentTypeFieldsGroupSearch';
import {
  LocalizedTextPlural,
  pickCorrectPlural,
  PluralOperand,
} from '@ardoq/common-helpers';

const description: LocalizedTextPlural = {
  [PluralOperand.ONE]:
    "This workspace doesn't have any fields yet. Once you add fields, they'll appear here for configuration.",
  [PluralOperand.OTHER]:
    "These workspaces don't have any fields yet. Once you add fields, they'll appear here for configuration.",
};

export const NoFieldsToConfigure = ({
  commands,
  boundWorkspacesCount,
}: {
  commands: ZoneCommands;
  boundWorkspacesCount: number;
}) => (
  <Stack>
    <ComponentTypeFieldsGroupSearch
      searchKey={''}
      searchBy={SearchByTypes.COMPONENT_TYPE}
      commands={commands}
      isDisabled={true}
    />
    <FlexBox
      flex={1}
      paddingY="xxlarge"
      width="full"
      align="center"
      justify="center"
    >
      <EmptyState
        title="No fields to configure"
        description={pickCorrectPlural(description, boundWorkspacesCount)}
        media={<EmptyStateToAddIllustration />}
        layout="horizontal"
        size="large"
      >
        <EmptyStateActions>
          You can continue creating the division or select a workspace with
          fields to set permissions.
          <SecondaryButton onClick={() => commands.goToSelectDataStep()}>
            <AddIcon /> Add workspace
          </SecondaryButton>
        </EmptyStateActions>
      </EmptyState>
    </FlexBox>
  </Stack>
);
