import { dispatchAction } from '@ardoq/rxbeach';
import {
  resetQuickSearch,
  lockResultItem,
  previewResultItem,
  setSearchParams,
  showSearchBar,
} from './actions';
import { ResultInState, SearchParamsPayload } from './types';
import { navigateToComponentOverviewPage } from 'router/navigationActions';
import { ArdoqId } from '@ardoq/api-types';
import { setContextToEntity } from 'search/searchUtil/actions';

export type QuickSearchCommands = {
  setSearchParams: (args: SearchParamsPayload) => void;
  resetQuickSearch: () => void;
  setSearchBarActive: (isActive: boolean) => void;
  openComponentOverviewPage: (id: ArdoqId) => void;
  openWorkspaceOrReference: (id: ArdoqId, workspaceId: ArdoqId) => void;
};

export const quickSearchCommands: QuickSearchCommands = {
  setSearchParams: ({ query, options }) =>
    dispatchAction(setSearchParams({ query, options })),
  resetQuickSearch: () => dispatchAction(resetQuickSearch()),
  setSearchBarActive: isActive => dispatchAction(showSearchBar(isActive)),
  openComponentOverviewPage: id => {
    dispatchAction(resetQuickSearch());
    dispatchAction(navigateToComponentOverviewPage(id));
  },
  openWorkspaceOrReference: (id, workspaceId) => {
    dispatchAction(resetQuickSearch());
    dispatchAction(setContextToEntity({ id, workspaceId }));
  },
};

export type ResultCommands = {
  setPreviewedResultItem: (args: ResultInState | null) => void;
  setLockedResultItem: (args: ResultInState | null) => void;
};

export const resultCommands: ResultCommands = {
  setPreviewedResultItem: args => dispatchAction(previewResultItem(args)),
  setLockedResultItem: args => dispatchAction(lockResultItem(args)),
};
