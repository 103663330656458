import { connect } from '@ardoq/rxbeach';
import {
  NewExportRoutes,
  OverviewRoute,
  RoutePath,
} from 'integrations/common/navigation/types';
import { NewExport } from './pages/NewExport/NewExport';
import { Overview } from 'integrations/common/containers/overview/Overview';
import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { isOverviewRoute } from 'integrations/common/navigation/utils';
import { LoadingOverlay } from '@ardoq/layout';
import viewModel$ from './viewModel$';

type SignavioExporterParams = {
  activeIntegration: ActiveIntegrationState;
  loading: boolean;
};

function SignavioExporterComponent({
  activeIntegration,
  loading,
}: SignavioExporterParams) {
  const integrationPath = activeIntegration.integrationPath as RoutePath;
  const isOverviewPage = !integrationPath || isOverviewRoute(integrationPath);
  return (
    <>
      {isOverviewPage && (
        <Overview
          integrationPath={integrationPath}
          initialTab={OverviewRoute.CONNECTIONS}
        />
      )}
      {!isOverviewPage && <NewExport />}
      {loading && integrationPath !== NewExportRoutes.REVIEW_EXPORT && (
        <LoadingOverlay />
      )}
    </>
  );
}

export const Signavio = connect(SignavioExporterComponent, viewModel$);
