import Context from 'context';
import { MouseButton, isMac } from '@ardoq/common-helpers';
import getComponentsFromElement from 'utils/getComponentsFromElement';
import getReferencesFromElement from 'utils/getReferencesFromElement';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import { isPresentationMode } from 'appConfig';
import { GLOBAL_MODEL_SELECTORS } from 'consts';
import { isLinking } from 'createReferences2024/createReferences$';

const handleDocumentDoubleClick = (event: MouseEvent) => {
  if (isLinking()) {
    return;
  }
  if (!(event.target instanceof Element)) {
    return;
  }
  const eventTarget = event.target;
  if (!GLOBAL_MODEL_SELECTORS.some(selector => eventTarget.matches(selector))) {
    return;
  }
  if (
    event.button === MouseButton.PRIMARY &&
    event.target.closest('.prevent-context-change')
  ) {
    return;
  }
  const multiSelectModifier = isMac() ? event.metaKey : event.ctrlKey;
  if (multiSelectModifier) {
    return;
  }
  const components = getComponentsFromElement(event.target);
  const references = getReferencesFromElement(event.target);
  if (!components?.length && !references?.length) {
    return;
  }
  const [component] = components;
  const [reference] = references;
  event.preventDefault();
  event.stopImmediatePropagation();
  if (component) {
    if (componentInterface.isScenarioRelated(component.getId())) {
      return false;
    }
    Context.setComponent(component);
  } else if (reference) {
    if (referenceInterface.isScenarioRelated(reference.getId())) {
      return false;
    }
    Context.setReference(reference);
  }
};

export const initGlobalEvents = () => {
  if (!isPresentationMode()) {
    document.addEventListener('dblclick', handleDocumentDoubleClick);
  }
};
