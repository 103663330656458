import { TileConfig, UnifiedIntegrationId } from './types';
import { Category, Group, Integration } from 'integrations/types';
import { Features } from '@ardoq/features';
import {
  ardoqUrl,
  celonisUrl,
  jiraUrl,
  signavioUrl,
  symbioUrl,
} from 'integrations/common/logos';
import { UNIFIED_INTEGRATION_IDS } from './constants';
import { initialStates } from './initialState';
import { UnifiedIntegration } from './init';
import { OverviewRoute } from 'integrations/common/navigation/types';
import { OrgAccessLevel } from '@ardoq/api-types';

const tileConfigs: Record<UnifiedIntegrationId, TileConfig> = {
  'jira-software': {
    categories: [Category.SERVICE, Category.IMPORTER],
    description: 'Import from JIRA Software',
    logoUrl: jiraUrl,
    disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
    group: Group.INTEGRATIONS,
  },
  signavio: {
    categories: [Category.SERVICE, Category.IMPORTER],
    description: 'Import from SAP Signavio',
    logoUrl: signavioUrl,
    disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
    group: Group.INTEGRATIONS,
  },
  ardoq: {
    categories: [Category.SERVICE, Category.IMPORTER],
    description: 'Import from Ardoq',
    logoUrl: ardoqUrl,
    requiredFeatures: [Features.ARDOQ_IMPORT_ENABLE],
    isBeta: true,
    group: Group.INTEGRATIONS,
  },
  'ardoq-reference-data': {
    categories: [Category.SERVICE, Category.IMPORTER],
    description: 'Import reference data from Ardoq',
    logoUrl: ardoqUrl,
    requiredFeatures: [Features.ARDOQ_REFERENCE_DATA_IMPORT_ENABLE],
    isBeta: true,
    group: Group.INTEGRATIONS,
  },
  'ardoq-sample-data': {
    categories: [Category.SERVICE, Category.IMPORTER],
    description: 'Import sample data from Ardoq',
    logoUrl: ardoqUrl,
    requiredFeatures: [Features.ARDOQ_SAMPLE_DATA_IMPORT_ENABLE],
    isBeta: true,
    group: Group.INTEGRATIONS,
  },
  'sharepoint-files': {
    categories: [Category.SERVICE, Category.IMPORTER],
    description: 'Import data from files stored in Sharepoint/OneDrive',
    logoUrl: ardoqUrl,
    requiredFeatures: [Features.SHAREPOINT_IMPORT_ENABLE],
    isBeta: true,
    group: Group.INTEGRATIONS,
  },
  celonis: {
    categories: [Category.SERVICE, Category.IMPORTER],
    description: 'Import from Celonis',
    logoUrl: celonisUrl,
    requiredFeatures: [Features.CELONIS_ENABLE],
    disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
    isBeta: true,
    group: Group.INTEGRATIONS,
  },
  symbio: {
    categories: [Category.SERVICE, Category.IMPORTER],
    description: 'Import from Symbio',
    logoUrl: symbioUrl,
    // We're using Celonis toggle for Symbio as well
    requiredFeatures: [Features.CELONIS_ENABLE],
    disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
    isBeta: true,
    group: Group.INTEGRATIONS,
  },
} as const;

export const unifiedIntegrations: Integration[] = UNIFIED_INTEGRATION_IDS.map(
  id => {
    return {
      ...tileConfigs[id],
      id,
      importerPath: id,
      name: initialStates[id].termsDictionary.name,
      typescriptImporter: {
        component: UnifiedIntegration,
        config: {
          allowedOverviewRoutes: [
            OverviewRoute.SCHEDULES,
            OverviewRoute.CONFIGURATIONS,
            OverviewRoute.CONNECTIONS,
          ],
        },
      },
      requiredOrgAccessLevel: OrgAccessLevel.ADMIN,
    };
  }
);
