import { APIBroadcastAttributes, ArdoqId, AssetType } from '@ardoq/api-types';
import { connect } from '@ardoq/rxbeach';
import broadcast$ from 'broadcasts/broadcast$';
import { AppModules } from 'appContainer/types';
import {
  favoriteFilter,
  getAssetFilter,
  getCreatedByMeFilter,
} from 'components/AssetsBrowser2024/FilterBar/filters';
import AssetsBrowser2024 from 'components/AssetsBrowser2024/AssetsBrowser2024';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { DoqType } from '@ardoq/doq';
import {
  getContentTypeColumn,
  getIntervalColumn,
  getLastReminderColumn,
  getLastSentColumn,
  getStatusColumn,
} from './columnConfig';
import EmptyState from 'components/AssetsBrowser2024/EmptyState';
import { getLastUpdatedColumn } from 'components/AssetsBrowser2024/assetsBrowser2024Columns';
import {
  unpublishedFilter,
  runningFilter,
  pausedFilter,
  doneFilter,
} from './overviewHelpers';

type BroadcastOverviewTableProps = {
  height: number;
  broadcasts: APIBroadcastAttributes[];
  currentUserId: ArdoqId;
};

const BroadcastOverviewTable = ({
  height,
  broadcasts,
  currentUserId,
}: BroadcastOverviewTableProps) => {
  return broadcasts.length ? (
    <AssetsBrowser2024
      appModule={AppModules.BROADCASTS}
      alwaysActiveFilters={[getAssetFilter(AssetType.BROADCAST)]}
      filters={[
        favoriteFilter,
        getCreatedByMeFilter(currentUserId),
        unpublishedFilter,
        runningFilter,
        pausedFilter,
        doneFilter,
      ]}
      scrollHeight={height}
      getCustomColumns={columnsProps =>
        [
          getStatusColumn(columnsProps),
          getIntervalColumn(columnsProps),
          getLastReminderColumn(columnsProps),
          getContentTypeColumn(columnsProps),
          getLastSentColumn(columnsProps),
          getLastUpdatedColumn(columnsProps),
        ].filter(ExcludeFalsy)
      }
    />
  ) : (
    <EmptyState
      doqType={DoqType.EMPTY_BOX}
      title="You don't have any broadcasts yet"
      text="Create a new broadcast to get started."
    />
  );
};

export default connect(BroadcastOverviewTable, broadcast$);
