import { Space } from '@ardoq/style-helpers';
import { Tab, TabsGroup } from '@ardoq/tabs';
import { LifecycleForm } from './LifecycleForm';
import { ProductFieldsForm } from './ProductFieldsForm';
import { s4 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import {
  ComponentType,
  ConfigurationState,
} from 'integrations/ITPedia/streams/types';
import { Tag } from '@ardoq/status-ui';
import { CreatableSelect } from '@ardoq/select';
import {
  getDateRangeFields,
  getProductIdOptions,
  getProductUrlOptions,
  getSelectedOption,
  SELECT_PLACEHOLDER,
} from './utils';
import { viewModel$ } from './viewModel$';
import { FieldsGroup } from 'integrations/ITPedia/streams/fields/fields$';
import { Maybe } from '@ardoq/common-helpers';
import { ChangedField } from './types';
import { Island } from '@ardoq/page-layout';

type Step1Params = {
  workspaceId?: string | null;
  selectedComponentTypes: ConfigurationState['componentTypes'];
  globalFields: FieldsGroup;
  workspaceFields: FieldsGroup;
  onChange: (
    componentType: string,
    fieldName: keyof ComponentType['fields'],
    field: Maybe<ChangedField>
  ) => void;
};

export function Step1Component({
  selectedComponentTypes,
  globalFields,
  workspaceFields,
  onChange,
}: Step1Params) {
  return (
    <Island
      tags={<Tag>Step 1</Tag>}
      title="Tech product and lifecycle fields"
      subtitle={`In this step, you will define how IT-Pedia product and lifecycle data should appear in Ardoq fields.`}
      subtitleWidth="medium"
    >
      <TabsGroup>
        {Object.entries(selectedComponentTypes).map(
          ([componentTypeName, componentType]) => (
            <Tab
              key={componentTypeName}
              label={`Component Type: ${componentTypeName}`}
              tabId={componentTypeName}
            >
              <TabContainer $isVertical $gap="s32">
                <ProductFieldsForm>
                  <CreatableSelect
                    placeholder={SELECT_PLACEHOLDER}
                    value={getSelectedOption(componentType, 'productId')}
                    options={getProductIdOptions(globalFields, workspaceFields)}
                    onChange={value =>
                      onChange(componentTypeName, 'productId', value)
                    }
                    onCreateOption={value =>
                      onChange(componentTypeName, 'productId', value)
                    }
                    errorMessage={componentType.fields.productId.errorMessage}
                  />
                  <CreatableSelect
                    placeholder={SELECT_PLACEHOLDER}
                    value={getSelectedOption(componentType, 'url')}
                    options={getProductUrlOptions(
                      globalFields,
                      workspaceFields
                    )}
                    onChange={value =>
                      onChange(componentTypeName, 'url', value)
                    }
                    onCreateOption={value =>
                      onChange(componentTypeName, 'url', value)
                    }
                    errorMessage={componentType.fields.url.errorMessage}
                  />
                </ProductFieldsForm>
                <LifecycleForm>
                  <CreatableSelect
                    placeholder={SELECT_PLACEHOLDER}
                    value={getSelectedOption(componentType, 'live')}
                    options={getDateRangeFields(globalFields, workspaceFields, [
                      componentType.fields.support,
                      componentType.fields.extendedSupport,
                    ])}
                    onChange={value =>
                      onChange(componentTypeName, 'live', value)
                    }
                    onCreateOption={value =>
                      onChange(componentTypeName, 'live', value)
                    }
                    errorMessage={componentType.fields.live.errorMessage}
                  />
                  <CreatableSelect
                    placeholder={SELECT_PLACEHOLDER}
                    options={getDateRangeFields(globalFields, workspaceFields, [
                      componentType.fields.live,
                      componentType.fields.extendedSupport,
                    ])}
                    value={getSelectedOption(componentType, 'support')}
                    onChange={value =>
                      onChange(componentTypeName, 'support', value)
                    }
                    onCreateOption={value =>
                      onChange(componentTypeName, 'support', value)
                    }
                    errorMessage={componentType.fields.support.errorMessage}
                  />
                  <CreatableSelect
                    placeholder={SELECT_PLACEHOLDER}
                    options={getDateRangeFields(globalFields, workspaceFields, [
                      componentType.fields.live,
                      componentType.fields.support,
                    ])}
                    value={getSelectedOption(componentType, 'extendedSupport')}
                    onChange={value =>
                      onChange(componentTypeName, 'extendedSupport', value)
                    }
                    onCreateOption={value =>
                      onChange(componentTypeName, 'extendedSupport', value)
                    }
                    errorMessage={
                      componentType.fields.extendedSupport.errorMessage
                    }
                  />
                </LifecycleForm>
              </TabContainer>
            </Tab>
          )
        )}
      </TabsGroup>
    </Island>
  );
}

export const Step1 = connect(Step1Component, viewModel$);

const TabContainer = styled(Space)`
  padding: ${s4};
`;
