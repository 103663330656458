import {
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';

type State = { isCreating: boolean };

const defaultState: State = { isCreating: false };

export const setIsCreatingWsFromTemplate = actionCreator<boolean>(
  '[isCreatingWsFromTemplate$] UPDATE_WS_FROM_TEMPLATE_STATUS'
);

const setIsCreatingWsFromTemplateReducer = (_: State, isCreating: boolean) => ({
  isCreating,
});
export const wsFromTemplateState$ = persistentReducedStream<State>(
  'wsFromTemplateState$',
  defaultState,
  [reducer(setIsCreatingWsFromTemplate, setIsCreatingWsFromTemplateReducer)]
);

export const isCreatingWorkspaceFromTemplate = () =>
  wsFromTemplateState$.state.isCreating;
