import {
  DropTargetView,
  NavigatorDropType,
  NodeModelTypes,
  PARENT_DROP_TARGET_HEIGHT,
  START_DRAG_THRESHOLD,
} from '../utils/consts';
import { setClassNames } from '../utils/utils';
import { TargetManager } from '../utils/TargetManager';
import {
  DragStart,
  DragUpdate,
  FinalizeDragEndPayload,
} from '../actions/navigatorActions';
import layout from '../models/layout';
import LayoutBox from '../models/LayoutBox';
import { NavigatorLayoutState } from '../types';
import { SortAttribute } from '@ardoq/common-helpers';
import { LayoutBoxModel } from '../types';
import { NodeModel } from '../models/types';
import { geometry } from '@ardoq/math';
import { logError } from '@ardoq/logging';
import Node from '../models/node';
import Tree from '../models/tree';
import { getDropTarget } from '../utils/getDropTarget';

export const hasNodeAccessToDrop = (node: NodeModel) =>
  node.type !== NodeModelTypes.COMPONENT || node.hasWriteAccess;

const resetSelection = (
  selection: string[],
  selectionStart: string,
  tree: Tree
) => {
  let actualSelection = selection;
  if (actualSelection.length > 1) {
    actualSelection = [selectionStart];
    tree.syncSelection(actualSelection);
  }
  return actualSelection;
};

const handleDropInNavigator = (
  state: NavigatorLayoutState,
  { changedIds }: FinalizeDragEndPayload
) => {
  // TOD ensure that we only enter if there are changes
  const { dragTargetNode } = state;

  const isHighlightingChanges = changedIds.length > 0;
  const layoutNodeSet = new Set(state.layoutNodeSet);
  if (dragTargetNode) {
    layoutNodeSet.delete(dragTargetNode.id);
  }

  const highlightChangeRequests =
    state.highlightChangeRequests + (isHighlightingChanges ? 1 : 0);
  const { linkSourceNode, existingRefs } = state;
  state.tree.setHasChanged(changedIds);
  return {
    ...state,
    layoutNodeSet,
    isDragEndTransition: true,
    isDrag: false,
    isDropHandled: true,
    highlightChangeRequests,
    rootLayoutBox: layout(
      state.tree,
      state.height,
      state.scrollTop,
      state.layoutNodeSet,
      setClassNames({ linkSourceNode, existingRefs }),
      state.showFilteredComponents
    ),
  };
};

const handleDropInTagScape = (
  state: NavigatorLayoutState,
  action: FinalizeDragEndPayload
) => {
  const { isHandled, position, isAborted } = action;
  const isDropHandled = !isAborted && isHandled;
  const { dragTargetNode } = state;
  let { dropTargetLayoutBox, layoutNodeSet } = state;
  layoutNodeSet = new Set(layoutNodeSet);
  if (dragTargetNode) {
    layoutNodeSet.delete(dragTargetNode.id);
  }
  if (isHandled) {
    dropTargetLayoutBox = new LayoutBox(
      dragTargetNode!,
      position.y,
      position.x,
      null,
      {
        isViewportCoordinates: true,
      }
    );
  }
  const { linkSourceNode, existingRefs } = state;
  return {
    ...state,
    layoutNodeSet,
    isDragEndTransition: true,
    isDrag: false,
    isDropHandled,
    dropTargetLayoutBox,
    rootLayoutBox: layout(
      state.tree,
      state.height,
      state.scrollTop,
      state.layoutNodeSet,
      setClassNames({ linkSourceNode, existingRefs }),
      state.showFilteredComponents
    ),
  };
};

const handleDropNone = (state: NavigatorLayoutState) => {
  const isDropHandled = false;
  const { dragTargetNode } = state;
  let { layoutNodeSet } = state;
  layoutNodeSet = new Set(layoutNodeSet);
  if (dragTargetNode) {
    layoutNodeSet.delete(dragTargetNode.id);
  }
  const { linkSourceNode, existingRefs } = state;
  return {
    ...state,
    layoutNodeSet,
    isDragEndTransition: true,
    isDrag: false,
    isDropHandled,
    rootLayoutBox: layout(
      state.tree,
      state.height,
      state.scrollTop,
      state.layoutNodeSet,
      setClassNames({ linkSourceNode, existingRefs }),
      state.showFilteredComponents
    ),
  };
};

const startDrag = (
  state: NavigatorLayoutState,
  { dragTargetNode, dragStartPosition }: DragStart
): NavigatorLayoutState => {
  // This will not start the DnD yet, but will set the dragTargetNode
  return {
    ...state,
    dragTargetNodeCandidate: dragTargetNode,
    dragStartPosition,
    // If we actually start a DnD will be checked in the mousemove event, that
    // in the updateDrag reducer.
    isDrag: false,
  };
};

const updateDrag = (
  state: NavigatorLayoutState,
  payload: DragUpdate
): NavigatorLayoutState => {
  return state.isDrag
    ? updateDragMove(state, payload)
    : checkStartDragAndDrop(state, payload);
};

const checkStartDragAndDrop = (
  state: NavigatorLayoutState,
  { event }: DragUpdate
): NavigatorLayoutState => {
  const { x: x1, y: y1 } = state.dragStartPosition;
  const { clientX: x2, clientY: y2 } = event;
  if (geometry.distanceTwoArgs([x1, y1], [x2, y2]) <= START_DRAG_THRESHOLD) {
    return state;
  }
  if (!state.dragTargetNodeCandidate) {
    logError(Error('No dragTargetNodeCandidate set'));
    return state;
  }

  const layoutNodeSet = new Set(state.layoutNodeSet);
  const { dragTargetNodeCandidate, linkSourceNode, existingRefs } = state;
  layoutNodeSet.add(dragTargetNodeCandidate.id);
  const selectionStash = {
    selection: state.selection,
    selectionStart: state.selectionStart,
  };
  let { selection } = state;
  const isInSelection =
    selection.length > 1 && selection.includes(dragTargetNodeCandidate.id);
  if (!isInSelection) {
    selection = resetSelection(selection, state.selectionStart, state.tree);
  } else {
    const dragParent = dragTargetNodeCandidate.parent;
    selection = selection.filter(id => {
      const node = state.tree.getNode(id);
      return node && node.parent === dragParent;
    });
    state.tree.syncSelection(selection);
  }

  return {
    ...state,
    layoutNodeSet,
    selection,
    selectionSize: selection.length,
    selectionStash,
    dragTargetNodeCandidate: null,
    dragTargetNode: dragTargetNodeCandidate,
    dragPosition: { x: x2, y: y2 },
    isDrag: true,
    rootLayoutBox: layout(
      state.tree,
      state.height,
      state.scrollTop,
      layoutNodeSet,
      setClassNames({
        dragTargetNode: dragTargetNodeCandidate,
        linkSourceNode,
        existingRefs,
      }),
      state.showFilteredComponents
    ),
  };
};

const updateDragMove = (
  state: NavigatorLayoutState,
  { event }: DragUpdate
): NavigatorLayoutState => {
  const { rootLayoutBox, container, dragTargetNode } = state;
  if (!(container && dragTargetNode)) {
    logError(Error('No container set or no dragTargetNode set'));
    return state;
  }
  const { clientX: x, clientY: y } = event;
  const containerLayoutBox = container.getBoundingClientRect();
  const absPos = fixedToAbsolute(containerLayoutBox, container.scrollTop, x, y);
  const {
    layoutBox: dropTargetLayoutBox,
    isParent: isDropTargetParent,
    isBefore: isDropTargetBefore,
  } = getDropTargetLayoutBox(
    containerLayoutBox,
    rootLayoutBox.children,
    absPos.y,
    x
  );
  const isSortedByOrder = state.sort.attr === SortAttribute.ORDER;
  const dropNode = getDropTarget(
    dropTargetLayoutBox,
    isDropTargetParent,
    isSortedByOrder
  );
  const dropType = dropNode
    ? dropNode.getDropTypeForNode(dragTargetNode)
    : NavigatorDropType.DROP_TYPE_NO_DROP;
  return {
    ...state,
    dragPosition: { x, y },
    dropTargetLayoutBox,
    isDropTargetParent,
    isDropTargetBefore,
    dropType,
    dropTargetView: getDropTargetView(event),
  };
};

const getDropTargetLayoutBox = (
  containerLayoutBox: DOMRect,
  layoutBoxes: LayoutBoxModel[],
  y: number,
  fixedX: number
) => {
  const { left, right } = containerLayoutBox;
  if (fixedX < left || fixedX > right) {
    return {
      layoutBox: null,
      isParent: false,
      isBefore: false,
    };
  }
  let isParent = false;
  let isBefore = false;
  const layoutBox =
    layoutBoxes.find(box => {
      if (y >= box.top && y <= box.bottom) {
        const parent = box.node.parent;
        if (parent && parent instanceof Node) {
          if (y <= box.top + PARENT_DROP_TARGET_HEIGHT) {
            isParent = true;
            isBefore = true;
          } else if (y >= box.bottom - PARENT_DROP_TARGET_HEIGHT) {
            isParent = true;
          }
        }
        return true;
      }
    }) ?? null;
  return { layoutBox, isParent, isBefore };
};

enum TargetClassName {
  NAVIGATOR_CONTENT = 'navigator-content',
  TAB_TAGSCAPE = 'tabtagscape',
  COMPONENT = 'component',
}

const targetManager = new TargetManager({
  mousemove: [TargetClassName.NAVIGATOR_CONTENT, TargetClassName.TAB_TAGSCAPE],
  mousedown: [TargetClassName.COMPONENT],
});

const getDropTargetView = (event: Event) => {
  const { className } = targetManager.getTarget(event);
  switch (className) {
    case TargetClassName.NAVIGATOR_CONTENT:
      return DropTargetView.NAVIGATOR;
    case TargetClassName.TAB_TAGSCAPE:
      return DropTargetView.TAGSCAPE;
    default:
      return DropTargetView.NONE;
  }
};

const finalizeDragEnd = (
  state: NavigatorLayoutState,
  payload: FinalizeDragEndPayload
): NavigatorLayoutState => {
  if (!state.isDrag) {
    return {
      ...state,
      dragTargetNodeCandidate: null,
      dragStartPosition: { x: 0, y: 0 },
    };
  }

  const newState = {
    ...state,
    selection: state.selectionStash.selection,
    selectionSize: state.selectionStash.selection.length,
    selectionStart: state.selectionStash.selectionStart,
    selectionStash: {
      selection: [],
      selectionStart: '',
    },
  };
  newState.tree.syncSelection(newState.selection);

  if (payload.isAborted) {
    return handleDropNone(newState);
  }

  const { dropTargetView } = newState;
  switch (dropTargetView) {
    case DropTargetView.NAVIGATOR:
      return handleDropInNavigator(newState, payload);

    case DropTargetView.TAGSCAPE:
      return handleDropInTagScape(newState, payload);

    default:
      return handleDropNone(newState);
  }
};

const endDragEndTransition = (
  state: NavigatorLayoutState
): NavigatorLayoutState => {
  if (state.isDragEndTransition) {
    const { linkSourceNode, existingRefs } = state;
    return {
      ...state,
      dragTargetNode: null,
      isDragEndTransition: false,
      dragPosition: { x: 0, y: 0 },
      dropTargetLayoutBox: null,
      isDropTargetParent: false,
      isDropTargetBefore: false,
      dropType: NavigatorDropType.DROP_TYPE_NONE,
      rootLayoutBox: state.rootLayoutBox.copy(
        setClassNames({ linkSourceNode, existingRefs })
      ),
    };
  }
  return state;
};

const clearHighlight = (state: NavigatorLayoutState): NavigatorLayoutState => {
  if (state.highlightChangeRequests > 0) {
    const highlightChangeRequests = state.highlightChangeRequests - 1;
    const { dragTargetNode, linkSourceNode, existingRefs } = state;
    if (highlightChangeRequests === 0) {
      state.tree.clearHasChanged();
    }
    return {
      ...state,
      highlightChangeRequests,
      rootLayoutBox: state.rootLayoutBox.copy(
        setClassNames({ dragTargetNode, linkSourceNode, existingRefs })
      ),
    };
  }
  return state;
};

const fixedToAbsolute = (
  containerBox: DOMRect,
  scrollTop: number,
  x: number,
  y: number
) => ({
  x: x - containerBox.left,
  y: y - containerBox.top + scrollTop,
});

export const navigatorTreeDragAndDropOperations = {
  startDrag,
  updateDrag,
  finalizeDragEnd,
  endDragEndTransition,
  clearHighlight,
};
