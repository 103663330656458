import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { text1 } from '@ardoq/typography';
import styled from 'styled-components';

const TextContainer = styled.div`
  ${text1}
`;
const ConfigName = styled.li`
  font-weight: 600;
`;
const ConfigList = styled.ul`
  padding-left: 29px;
  margin-bottom: 0;
`;

interface DeleteConfigModalTextProps {
  configs: SavedTransferConfig[];
}
export const DeleteConfigModalText = ({
  configs,
}: DeleteConfigModalTextProps) => {
  if (configs.length === 0) return;
  return configs.length > 1 ? (
    <TextContainer>
      If the configurations are deleted, they can’t be recovered.
      <br />
      <br />
      Permanently delete the configurations:
      <ConfigList>
        {configs.map(({ name }, index) => (
          <ConfigName key={index}>{name}</ConfigName>
        ))}
      </ConfigList>
    </TextContainer>
  ) : (
    <TextContainer>
      If the configuration <b>{configs[0].name}</b> is deleted, it can’t be
      recovered.
    </TextContainer>
  );
};
