import { FormattingTabState, PerspectivesEditorState } from '../types';
import {
  createFormattingRule as createFormattingRuleAction,
  deleteFormattingRule as deleteFormattingRuleAction,
  reorderFormattingRules as reorderFormattingRulesAction,
  selectComponentLabelFormattingValue,
  selectReferenceLabelFormattingValue,
  setComponentLabelFormattingTimeIncluded,
  setReferenceLabelFormattingTimeIncluded,
  updateFormattingRule as updateFormattingRuleAction,
  UpdateFormattingRulePayload,
} from './actions';
import { reducer } from '@ardoq/rxbeach';
import {
  addNewFormattingRule,
  deleteFormattingRule,
  FormattingRule,
  PerspectivesFormattingOptions,
  updateFormattingRules,
  reorderFormattingRules,
} from '@ardoq/perspectives';
import { notifyFormattingOptionsChanged } from '../../actions';
import { getAppliedFormattingState } from './getAppliedFormattingState';
import {
  validReferenceLabelFormattingOrDefault,
  validComponentLabelFormattingOrDefault,
  DEFAULT_REFERENCE_LABEL_FORMATTING,
  DEFAULT_COMPONENT_LABEL_FORMATTING,
} from '../labelFormattingUtils';

export const getFormattingTabInitialState = (): FormattingTabState => ({
  isAppliedFormattingIncluded: false,
  conditionalFormattingRules: [],
  componentLabelFormattingValue: DEFAULT_COMPONENT_LABEL_FORMATTING,
  referenceLabelFormattingValue: DEFAULT_REFERENCE_LABEL_FORMATTING,
  formattingOptions: {
    appliedConditionalFormattingRules: [],
    appliedComponentFormattingLabel: DEFAULT_COMPONENT_LABEL_FORMATTING,
    appliedReferenceFormattingLabel: DEFAULT_REFERENCE_LABEL_FORMATTING,
    componentFormattingCustomLabels: [],
    referenceFormattingCustomLabels: [],
  },
});

export const clearFormattingTabState = (
  state: PerspectivesEditorState
): FormattingTabState => ({
  ...state.formattingTab,
  conditionalFormattingRules: [],
  componentLabelFormattingValue: DEFAULT_COMPONENT_LABEL_FORMATTING,
  referenceLabelFormattingValue: DEFAULT_REFERENCE_LABEL_FORMATTING,
});

const handleSelectComponentLabelFormattingValue = (
  state: PerspectivesEditorState,
  newLabelFormattingValue: string
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    componentLabelFormattingValue: newLabelFormattingValue,
  },
});

const handleSelectReferenceLabelFormattingValue = (
  state: PerspectivesEditorState,
  newLabelFormattingValue: string
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    referenceLabelFormattingValue: newLabelFormattingValue,
  },
});

const handleSetComponentLabelTimeIncluded = (
  state: PerspectivesEditorState,
  isIncluded: boolean
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    isComponentLabelTimeIncluded: isIncluded,
  },
});

const handleSetReferenceLabelTimeIncluded = (
  state: PerspectivesEditorState,
  isIncluded: boolean
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    isReferenceLabelTimeIncluded: isIncluded,
  },
});

const handleCreateFormattingRule = (
  state: PerspectivesEditorState,
  newFormattingRule: FormattingRule
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: addNewFormattingRule(
      state.formattingTab.conditionalFormattingRules,
      newFormattingRule
    ),
  },
});

const handleUpdateFormattingRule = (
  state: PerspectivesEditorState,
  {
    updatedFormattingRuleId,
    updatedFormattingRule,
  }: UpdateFormattingRulePayload
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: updateFormattingRules(
      state.formattingTab.conditionalFormattingRules,
      updatedFormattingRuleId,
      updatedFormattingRule
    ),
  },
});

const handleDeleteFormattingRule = (
  state: PerspectivesEditorState,
  deletedFormattingRuleId: string
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: deleteFormattingRule(
      state.formattingTab.conditionalFormattingRules,
      deletedFormattingRuleId
    ),
  },
});

const handleReorderFormattingRules = (
  state: PerspectivesEditorState,
  reorderedFormattingRulesIds: string[]
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: reorderFormattingRules(
      state.formattingTab.conditionalFormattingRules,
      reorderedFormattingRulesIds
    ),
  },
});

const getNextFormattingTabState = (
  state: Pick<PerspectivesEditorState, 'formattingTab'>,
  formattingOptions: PerspectivesFormattingOptions
) => {
  if (!state.formattingTab.isAppliedFormattingIncluded) {
    // include formatting applied in the main app on initial load
    return getAppliedFormattingState(formattingOptions);
  }

  return pruneInvalidFormattingState(state.formattingTab, formattingOptions);
};

/**
 * handleNotifyFormattingOptionsChange is emitted by the postMessageBridge and informs that the formattingOptions have changed.
 */

const handleNotifyFormattingOptionsChange = (
  state: PerspectivesEditorState,
  formattingOptions: PerspectivesFormattingOptions
): PerspectivesEditorState => {
  const formattingTabState = getNextFormattingTabState(
    state,
    formattingOptions
  );

  return {
    ...state,
    formattingTab: {
      isAppliedFormattingIncluded: true,
      ...formattingTabState,
      formattingOptions,
      isComponentLabelTimeIncluded:
        formattingTabState.isComponentLabelTimeIncluded,
      isReferenceLabelTimeIncluded:
        formattingTabState.isReferenceLabelTimeIncluded,
    },
  };
};

const pruneInvalidFormattingState = (
  formattingTabState: FormattingTabState,
  formattingOptions: PerspectivesFormattingOptions
) => ({
  conditionalFormattingRules: formattingTabState.conditionalFormattingRules,
  componentLabelFormattingValue: validComponentLabelFormattingOrDefault(
    formattingTabState.componentLabelFormattingValue,
    formattingOptions.componentFormattingCustomLabels
  ),
  referenceLabelFormattingValue: validReferenceLabelFormattingOrDefault(
    formattingTabState.referenceLabelFormattingValue,
    formattingOptions.referenceFormattingCustomLabels
  ),
  isComponentLabelTimeIncluded: formattingTabState.isComponentLabelTimeIncluded,
  isReferenceLabelTimeIncluded: formattingTabState.isReferenceLabelTimeIncluded,
});

export const formattingTabReducers = [
  reducer(
    selectComponentLabelFormattingValue,
    handleSelectComponentLabelFormattingValue
  ),
  reducer(
    selectReferenceLabelFormattingValue,
    handleSelectReferenceLabelFormattingValue
  ),
  reducer(
    setComponentLabelFormattingTimeIncluded,
    handleSetComponentLabelTimeIncluded
  ),
  reducer(
    setReferenceLabelFormattingTimeIncluded,
    handleSetReferenceLabelTimeIncluded
  ),
  reducer(createFormattingRuleAction, handleCreateFormattingRule),
  reducer(updateFormattingRuleAction, handleUpdateFormattingRule),
  reducer(deleteFormattingRuleAction, handleDeleteFormattingRule),
  reducer(reorderFormattingRulesAction, handleReorderFormattingRules),
  reducer(notifyFormattingOptionsChanged, handleNotifyFormattingOptionsChange),
];
