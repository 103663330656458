import { FC } from 'react';
import { Checkbox, FieldsWrapper } from '@ardoq/forms';
import { ReadOnlyCheckboxRowProps } from 'surveyAdmin/types';
import { isRequiredValidator } from './utils';

const ReadOnlyCheckboxRow: FC<ReadOnlyCheckboxRowProps> = ({
  readonly,
  validators,
  disabled,
  descriptionExtension,
  fieldIsReadonly,
  allowComponentCreation,
  isNameQuestion,
  updateReadonlyAndValidators,
}) => {
  const isChecked = Boolean(fieldIsReadonly || readonly);
  return (
    <FieldsWrapper>
      <Checkbox
        label="Read-only"
        isChecked={isChecked}
        onChange={() => {
          updateReadonlyAndValidators({
            readonly: !readonly,
            validators: (validators || []).filter(v => !isRequiredValidator(v)),
          });
        }}
        isDisabled={disabled}
        helperText={`Respondents can view this information but not change it. ${
          descriptionExtension ?? ''
        }`}
        warningMessage={
          allowComponentCreation && isNameQuestion && isChecked
            ? 'Setting this question to read-only will make it impossible for users to create new components.'
            : undefined
        }
      >
        Is this field read-only?
      </Checkbox>
    </FieldsWrapper>
  );
};

export default ReadOnlyCheckboxRow;
