import PredefinedAudienceQuerySection from './PredefinedAudienceQuerySection';
import BroadcastAudienceDialogFooter from '../BroadcastAudienceDialogFooter';
import predefinedAudienceDialog$ from './predefinedAudienceDialog$';
import TabbedAudienceTable from 'broadcasts/components/audienceTable/tabbedAudienceTable/TabbedAudienceTable';
import GenericErrorContent from '../GenericErrorContent';
import {
  AudiencePreview,
  BroadcastContentType,
  PredefinedQueryOption,
} from '@ardoq/api-types';
import { ModalContent, ModalLayout, ModalTemplate } from '@ardoq/modal';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { AudienceTableColumn } from 'broadcasts/components/audienceTable/types';
import { WarningNotification } from '@ardoq/status-ui';
import { OverflowWrapper } from 'broadcasts/components/atoms';
import { BROADCAST_AUDIENCE_DIALOG_MODAL_SIZE } from '../dialogConsts';
import { setTableFilter } from './actions';

type PredefinedAudienceDialogProps = {
  selectedQueryOptions: PredefinedQueryOption[];
  isUpdatingExistingAudience: boolean;
  formIsValid: boolean;
  audiencePreview: AudiencePreview;
  isFetchingAudiencePreview: boolean;
  failedToFetchAudiencePreview: boolean;
  warningMessage: string | null;
  contentType: BroadcastContentType;
  tableFilter: string;
} & ModalContent<PredefinedQueryOption[]>;

const PredefinedAudienceDialog = ({
  selectedQueryOptions,
  isUpdatingExistingAudience,
  formIsValid,
  audiencePreview,
  isFetchingAudiencePreview,
  failedToFetchAudiencePreview,
  warningMessage,
  resolve,
  contentType,
  tableFilter,
}: PredefinedAudienceDialogProps) => {
  const closeDialog = () => resolve(false);
  const addToAudienceList = async () => {
    if (!formIsValid) return;
    resolve(selectedQueryOptions);
  };
  return (
    <ModalTemplate
      modalSize={BROADCAST_AUDIENCE_DIALOG_MODAL_SIZE}
      fixedHeight
      headerText="Add Audiences by Predefined People Query"
      onCloseButtonClick={closeDialog}
      footerContent={
        <BroadcastAudienceDialogFooter
          isUpdatingExistingAudience={isUpdatingExistingAudience}
          onCancel={closeDialog}
          onSubmit={addToAudienceList}
          audienceCount={audiencePreview.length}
          formIsValid={formIsValid}
          hasError={failedToFetchAudiencePreview}
        />
      }
    >
      {failedToFetchAudiencePreview ? (
        <GenericErrorContent />
      ) : (
        <>
          <ModalLayout>
            <PredefinedAudienceQuerySection />
            {warningMessage && (
              <WarningNotification>{warningMessage}</WarningNotification>
            )}
          </ModalLayout>
          <OverflowWrapper>
            <TabbedAudienceTable
              audiencePreview={audiencePreview}
              loading={isFetchingAudiencePreview}
              emptyTableContent={
                selectedQueryOptions.length > 0
                  ? {
                      title: 'No result yet...',
                      message:
                        'This is an automatically updated list and results may not be visible if no result currently meets the filter rules.',
                    }
                  : undefined
              }
              description="This list automatically updates based on your filter rules."
              defaultAudienceSortById={AudienceTableColumn.PERSON_LABEL}
              defaultComponentSortById={AudienceTableColumn.COMPONENT_LABEL}
              contentType={contentType}
              tableFilter={tableFilter}
              onTableFilterChange={
                audiencePreview.length > 0
                  ? query => dispatchAction(setTableFilter(query))
                  : undefined
              }
            />
          </OverflowWrapper>
        </>
      )}
    </ModalTemplate>
  );
};

export default connect(PredefinedAudienceDialog, predefinedAudienceDialog$);
