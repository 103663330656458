import { ModalSize, ModalTemplate } from '@ardoq/modal';
import { ManageMembershipModalProps } from './types';
import { manageMembershipModal$ } from './viewmodel$';
import { connect } from '@ardoq/rxbeach';
import { Box, Stack, FlexBox } from '@ardoq/layout';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import ComponentsMembershipTable from './ComponentsMembershipTable';
import { ButtonGroup } from '@ardoq/button';
import { SubdivisionDropdownButton } from './SubdivisionDropdownButton';
import { interpolate, pickCorrectPlural } from '@ardoq/common-helpers';
import { Pagination } from '@ardoq/pagination';
import { colors } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { text1, text1Bold } from '@ardoq/typography';
import { Icon, IconName } from '@ardoq/icons';
import {
  DIVISIONS_USER_EVENTS,
  trackDivisionMembershipUserEvent,
} from 'subdivisionEditor/trackingUtils';
import { IslandContainer, IslandHeader } from '@ardoq/page-layout';

const Strong = styled.span`
  ${text1Bold}
`;

const TextPrimary = styled(FlexBox)`
  ${text1}
`;

const ManageMembershipModal = ({
  onClose,
  tableData,
  commands,
  activeSubdivisions,
  currentPageTableData,
  noChangesToSave,
  ...state
}: ManageMembershipModalProps) => {
  const {
    components,
    currentPage,
    currentAddSelection,
    currentRemoveSelection,
    membershipsState,
    COMPONENTS_PER_PAGE,
    isBulkChange,
    selectedComponentRows,
  } = state;
  const { MANAGE_MEMBERSHIP_MODAL } = SUBDIVISIONS_STRINGS;
  return (
    <ModalTemplate
      modalSize={ModalSize.M}
      onCloseButtonClick={() => {
        trackDivisionMembershipUserEvent(
          DIVISIONS_USER_EVENTS.CANCEL_DIVISION_MEMBERSHIP_CHANGES
        );
        onClose();
      }}
      headerText={MANAGE_MEMBERSHIP_MODAL.HEADER}
      primaryButtonText={MANAGE_MEMBERSHIP_MODAL.PRIMARY_BUTTON}
      onPrimaryButtonClick={() => {
        commands.saveMembershipChanges(membershipsState);
        onClose();
      }}
      isPrimaryButtonDisabled={noChangesToSave}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={() => {
        trackDivisionMembershipUserEvent(
          DIVISIONS_USER_EVENTS.CANCEL_DIVISION_MEMBERSHIP_CHANGES
        );
        onClose();
      }}
      warningBlockMessage={
        activeSubdivisions.length === 0
          ? MANAGE_MEMBERSHIP_MODAL.NO_SUBDIVISIONS_WARNING_TEXT
          : undefined
      }
    >
      <Box paddingX="large" paddingY="medium" width="full">
        <Stack gap="small">
          <FlexBox justify="flex-start" paddingBottom="small">
            {interpolate(
              pickCorrectPlural(
                MANAGE_MEMBERSHIP_MODAL.HELPER_TEXT,
                components.length
              ),
              {
                componentName: components[0].name,
              }
            )}
          </FlexBox>
          <IslandContainer>
            {isBulkChange && (
              <IslandHeader
                skipVerticalPadding={true}
                title={
                  <TextPrimary
                    flex={1}
                    gap="small"
                    justify="flex-start"
                    paddingY="medium"
                  >
                    <Strong>{selectedComponentRows.length}</Strong>
                    <span>
                      {pickCorrectPlural(
                        MANAGE_MEMBERSHIP_MODAL.SELECTED_COMPONENTS,
                        selectedComponentRows.length
                      )}
                    </span>
                  </TextPrimary>
                }
                rightContent={
                  <ButtonGroup>
                    <SubdivisionDropdownButton
                      subdivisions={activeSubdivisions}
                      selection={currentAddSelection}
                      onApply={() => commands.applyAddSelection(state)}
                      onClear={commands.clearAddSelection}
                      onAddSubdivision={commands.addSubdivisionToAddSelection}
                      onRemoveSubdivision={
                        commands.removeSubdivisionFromAddSelection
                      }
                      role="assign"
                    >
                      <Icon iconName={IconName.ADD} color={colors.grey50} />
                      {MANAGE_MEMBERSHIP_MODAL.ASSIGN_TO_ALL}
                    </SubdivisionDropdownButton>
                    <SubdivisionDropdownButton
                      subdivisions={activeSubdivisions}
                      selection={currentRemoveSelection}
                      onApply={() => commands.applyRemoveSelection(state)}
                      onClear={commands.clearRemoveSelection}
                      onAddSubdivision={
                        commands.addSubdivisionToRemoveSelection
                      }
                      onRemoveSubdivision={
                        commands.removeSubdivisionFromRemoveSelection
                      }
                      role="remove"
                    >
                      <Icon iconName={IconName.DELETE} color={colors.grey50} />
                      {MANAGE_MEMBERSHIP_MODAL.REMOVE_FROM_ALL}
                    </SubdivisionDropdownButton>
                  </ButtonGroup>
                }
              ></IslandHeader>
            )}
            <Box paddingY="xsmall">
              <ComponentsMembershipTable
                currentPageTableData={currentPageTableData}
                commands={commands}
                activeSubdivisions={activeSubdivisions}
                isBulkChange={isBulkChange}
                selectedComponentRows={selectedComponentRows}
              />
            </Box>
          </IslandContainer>
          {tableData.length > COMPONENTS_PER_PAGE && (
            <FlexBox justify="center">
              <Pagination
                currentPageNumber={currentPage}
                perPage={COMPONENTS_PER_PAGE}
                totalResults={tableData.length}
                onPageSelect={selectedPageNo =>
                  commands.changeCurrentPage(selectedPageNo)
                }
              />
            </FlexBox>
          )}
        </Stack>
      </Box>
    </ModalTemplate>
  );
};

export default connect(ManageMembershipModal, manageMembershipModal$);
