import { Select } from '@ardoq/select';
import { Icon, IconName } from '@ardoq/icons';
import { Field as FieldType } from 'integrations/common/streams/fields/types';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import {
  getDefaultWorkspaceField,
  isCustomIdReferenceFormat,
  isPathReferenceFormat,
} from 'integrations/common/streams/tabularMappings/utils';
import {
  MissingComponentsStrategy,
  ReferenceFormat as ReferenceFormatType,
  ReferenceMapping,
} from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { CustomIdFieldSelector } from './CustomIdFieldSelector';
import {
  getReferenceFormatName,
  isReferenceFormatDisabled,
} from 'integrations/common/pages/tabularConfigMapping/utils';
import { TextInput } from '@ardoq/forms';
import {
  MappedColumnsErrors,
  SidebarSelectorsIds,
} from '../../streams/tabularMappingErrors/types';
import { Link } from '@ardoq/typography';
import { Box } from '@ardoq/layout';

const getReferenceFormatOptions = (
  missingComponentsStrategy?: MissingComponentsStrategy
) =>
  (
    [
      'path',
      'ardoq-oid',
      'custom-id',
    ] satisfies ReferenceMapping['referenceFormat'][]
  ).map(format => {
    const typeName = {
      'ardoq-oid': 'Ardoq OID',
      'custom-id': 'Custom ID',
      path: 'Path',
    };
    return {
      value: format,
      label: getReferenceFormatName(format),
      isDisabled: isReferenceFormatDisabled(format, missingComponentsStrategy),
      popoverContent: isReferenceFormatDisabled(
        format,
        missingComponentsStrategy
      )
        ? `To enable ${typeName[format]}, go to “If target component is missing” and select Exclude reference or Block the import.`
        : '',
      rightContent: isReferenceFormatDisabled(
        format,
        missingComponentsStrategy
      ) ? (
        <Icon iconName={IconName.WARNING} />
      ) : null,
    };
  });

type ReferenceFormatProps = {
  tableMapping: TableMappingMap;
  columnMapping: ReferenceFormatType;
  usedFields: FieldType[];
  allFields: FieldType[];
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
  columnName: string;
  isCreationDisabled: boolean;
  missingComponentsStrategy?: MissingComponentsStrategy;
  mappedColumnsErrors: MappedColumnsErrors;
};

export const ReferenceFormat = ({
  tableMapping,
  columnMapping,
  usedFields,
  allFields,
  onMapping,
  columnName,
  isCreationDisabled,
  missingComponentsStrategy,
  mappedColumnsErrors,
}: ReferenceFormatProps) => {
  return (
    <>
      <Select
        dataTestId="tabularMapping-reference-format--select"
        label="Format"
        value={columnMapping.referenceFormat}
        options={getReferenceFormatOptions(missingComponentsStrategy)}
        onValueChange={value => {
          if (value === 'custom-id') {
            const defaultField = getDefaultWorkspaceField({
              columnName,
              tableMappingMap: tableMapping,
            });
            onMapping({
              ...columnMapping,
              referenceFormat: value,
              fieldName: defaultField.id,
              fieldLabel: defaultField.label,
            });
          } else if (value) {
            onMapping({
              ...columnMapping,
              referenceFormat: value,
            });
          }
        }}
      />
      {isPathReferenceFormat(columnMapping) && (
        <TextInput
          popoverHelpContent={
            <Box>
              Learn more about the path separator in{' '}
              <Link
                target="_blank"
                href="https://help.ardoq.com/en/articles/233896-fully-qualified-path-double-colon-notation"
              >
                our documentation
              </Link>
            </Box>
          }
          dataTestId="tabularMapping-reference-path-separator--input"
          label="Path separator"
          value={columnMapping.referencePathSeparator}
          displayValue={columnMapping.referencePathSeparator}
          errorMessage={
            mappedColumnsErrors[SidebarSelectorsIds.REFERENCE_PATH_SEPARATOR] ||
            undefined
          }
          onValueChange={value => {
            onMapping({
              ...columnMapping,
              referencePathSeparator: value,
            });
          }}
        />
      )}
      {isCustomIdReferenceFormat(columnMapping) && (
        <CustomIdFieldSelector
          usedFields={usedFields}
          allFields={allFields}
          tableMapping={tableMapping}
          value={columnMapping.fieldName || columnMapping.fieldLabel}
          onMapping={onMapping}
          isCreationDisabled={isCreationDisabled}
        />
      )}
    </>
  );
};
