import { Maybe } from '@ardoq/common-helpers';
import { ITPediaImportRoutes } from './types';
import { ComponentType, ConfigurationState } from './streams/types';
import { ITPediaSchedulePayload } from '@ardoq/api-types/integrations';
import { get, mapValues, omit, some } from 'lodash/fp';
import {
  addWorkspaceRuleToQuery,
  removeWorkspaceRuleToQuery,
} from './api/utils';
import { ScheduleType } from '@ardoq/api-types';
import { OverviewRoute } from 'integrations/common/navigation/types';

export function getRouteFromString(path: Maybe<string>) {
  switch (path) {
    case OverviewRoute.CONNECTIONS:
    default:
      return OverviewRoute.CONNECTIONS;
    case OverviewRoute.SCHEDULES:
      return OverviewRoute.SCHEDULES;
    case ITPediaImportRoutes.FIELD_MAPPING:
      return ITPediaImportRoutes.FIELD_MAPPING;
    case ITPediaImportRoutes.DATA_SELECTION:
      return ITPediaImportRoutes.DATA_SELECTION;
    case ITPediaImportRoutes.SUMMARY_SYNC:
      return ITPediaImportRoutes.SUMMARY_SYNC;
    case ITPediaImportRoutes.SUCCESS:
      return ITPediaImportRoutes.SUCCESS;
    case ITPediaImportRoutes.FAILED:
      return ITPediaImportRoutes.FAILED;
  }
}

export const ONBOARDING_GUIDE_LINK =
  'https://help.ardoq.com/en/articles/99168-it-pedia-integration';

export const configStateToEnrichmentJobOptions = (
  configurationState: ConfigurationState
): ITPediaSchedulePayload['jobOptions'] => {
  const filteredTopFields = {
    _id: configurationState._id,
    name: configurationState.name,
    accountId: configurationState.accountId,
    vulnerabilityFields: configurationState.vulnerabilityFields,
    workspaces: configurationState.workspaces,
  };
  const componentTypes = mapValues((componentValue: ComponentType) => {
    const newComponentValues = omit(
      ['isQueryValid', 'componentsQueryResults'],
      componentValue
    );
    newComponentValues.componentsQuery = addWorkspaceRuleToQuery(
      configurationState.workspaces.products.id,
      newComponentValues.componentsQuery
    );
    return newComponentValues;
  })(configurationState.componentTypes);

  return {
    ...filteredTopFields,
    componentTypes,
    type: ScheduleType.ITPEDIA_ENRICHMENT,
  };
};

export const enrichmentToConfigurationState = (
  enrichment: ITPediaSchedulePayload
): ConfigurationState => {
  // we need to remove the workspace from queries
  const componentTypes: ConfigurationState['componentTypes'] = mapValues(
    componentType => {
      return {
        ...componentType,
        // we also need to remove the value for the out of support field from previously saved configs
        fields: {
          live: componentType.fields.live,
          support: componentType.fields.support,
          extendedSupport: componentType.fields.extendedSupport,
          productId: componentType.fields.productId,
          url: componentType.fields.url,
        },
        isQueryValid: true,
        componentsQuery: removeWorkspaceRuleToQuery(
          componentType.componentsQuery
        ),
      };
    },
    enrichment.jobOptions.componentTypes
  );
  const applyFilters = some(get('componentsQuery'), componentTypes);
  return {
    ...enrichment.jobOptions,
    statuses: {
      upsert: {
        code: 'Initial',
        message: '',
      },
      list: 'Initial',
      delete: 'Initial',
    },
    applyFilters,
    componentTypes,
    _id: enrichment._id,
  } as ConfigurationState;
};
