import { RestrictedWorkspaces } from './types';
import { ArdoqId } from '@ardoq/api-types';
import { ErrorNotification, NotificationDetails } from '@ardoq/status-ui';
import {
  getRestrictedWorkspacesNames,
  thereAreSomeRestrictedWorkspaces,
} from './operations';
import { formatListAsEnglishProse } from '@ardoq/common-helpers';
import { hasReasonableMaxSize } from './utils';

type Props = {
  restrictedWorkspacesForEveryViewpoint: RestrictedWorkspaces;
  viewpointId: ArdoqId;
};

const MESSAGE =
  "To update this viewpoint, contact your admin and request read permission for the workspaces you don't currently have access to";

const RestrictedWorkspacesErrorNotification = ({
  restrictedWorkspacesForEveryViewpoint,
  viewpointId,
}: Props) => {
  const restrictedWorkspacesDataIsNotAvailable =
    !thereAreSomeRestrictedWorkspaces(restrictedWorkspacesForEveryViewpoint);
  if (restrictedWorkspacesDataIsNotAvailable) {
    return <ErrorNotification>{`${MESSAGE}.`}</ErrorNotification>;
  }

  const relevantRestrictedWorkspacesNames = getRestrictedWorkspacesNames(
    restrictedWorkspacesForEveryViewpoint,
    viewpointId
  );
  const formattedRestrictedWorkspacesNames = formatListAsEnglishProse(
    relevantRestrictedWorkspacesNames.map(workspaceName => `"${workspaceName}"`)
  );

  if (
    hasReasonableMaxSize(formattedRestrictedWorkspacesNames, 100) &&
    hasReasonableMaxSize(relevantRestrictedWorkspacesNames, 4)
  ) {
    return (
      <ErrorNotification>{`${MESSAGE}: ${formattedRestrictedWorkspacesNames}.`}</ErrorNotification>
    );
  }

  return (
    <ErrorNotification>
      {`${MESSAGE}.`}
      <NotificationDetails label="View workspaces">
        {hasReasonableMaxSize(relevantRestrictedWorkspacesNames, 10) ? (
          <ul>
            {relevantRestrictedWorkspacesNames.map(workspaceName => (
              <li key={workspaceName}>{workspaceName}</li>
            ))}
          </ul>
        ) : (
          formattedRestrictedWorkspacesNames
        )}
      </NotificationDetails>
    </ErrorNotification>
  );
};

export default RestrictedWorkspacesErrorNotification;
