import SearchResultTable, {
  OnFieldSelect,
  OnPageSelect,
  OnSortChange,
  SelectionProps,
} from 'components/SearchResultTable/SearchResultTable';
import { findRelevantFieldsFromQuery } from 'components/SearchResultTable/utils';
import { SEARCH_RESULTS_PER_PAGE } from 'search/types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { selectFields, selectPage, setSort } from './actions';
import advancedSearch$, { AdvancedSearchShape } from './advancedSearch$';
import AdvancedSearchErrorMessage from 'search/AdvancedSearch/AdvancedSearchErrorMessage';
import { map } from 'rxjs';

const onFieldSelect: OnFieldSelect = selectedFieldNames =>
  dispatchAction(selectFields({ selectedFieldNames }));

const onPageSelect: OnPageSelect = pageNumber =>
  dispatchAction(selectPage({ pageNumber }));

const onSortChange: OnSortChange = (sortBy, sortOrder) =>
  dispatchAction(setSort({ sortBy, sortOrder }));

type ResultsViewProps = SelectionProps &
  AdvancedSearchShape & {
    onFieldSelect: OnFieldSelect;
    onPageSelect: OnPageSelect;
    onSortChange: OnSortChange;
  };

export const ResultsView = ({
  total,
  results,
  selectedPage,
  selectedFieldNames,
  queryBuilderRules,
  fields,
  searchError,
  sortBy,
  sortOrder,
  ruleErrorMessages,
  showResults,
  onFieldSelect,
  onPageSelect,
  onSortChange,
  ...props
}: ResultsViewProps) => {
  if (searchError) {
    return (
      <AdvancedSearchErrorMessage
        searchError={searchError}
        ruleErrorMessages={ruleErrorMessages}
      />
    );
  }
  if (showResults) {
    return (
      <SearchResultTable
        queryRelevantFieldsNames={
          queryBuilderRules
            ? findRelevantFieldsFromQuery(queryBuilderRules)
            : []
        }
        selectedFieldNames={selectedFieldNames}
        onFieldSelect={onFieldSelect}
        onPageSelect={onPageSelect}
        onSortChange={onSortChange}
        sortBy={sortBy}
        sortOrder={sortOrder}
        fields={fields}
        results={results}
        page={selectedPage}
        perPage={SEARCH_RESULTS_PER_PAGE}
        total={total}
        intercomTarget="advanced search results"
        {...props}
      />
    );
  }
  return null;
};

export default connect(
  ResultsView,
  advancedSearch$.pipe(
    map(args => ({ ...args, onFieldSelect, onPageSelect, onSortChange }))
  )
);
