import currentBroadcast$ from './currentBroadcast$';
import { Observable } from 'rxjs';
import { BroadcastContent } from '@ardoq/api-types';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';
import { isEqual } from 'lodash';

const content$: Observable<BroadcastContent> = currentBroadcast$.pipe(
  map(({ content }) => content),
  excludeNullAndUndefined(),
  distinctUntilChanged(isEqual)
);

export default content$;
