import { CheckMarkIcon, IconName, IconSize, WarningIcon } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';

type FormattedIconProps = {
  iconName: IconName.CHECK_MARK | IconName.WARNING | null;
};

const FormattedIcon = ({ iconName }: FormattedIconProps) => (
  <>
    {iconName === IconName.CHECK_MARK && (
      <CheckMarkIcon iconSize={IconSize.MEDIUM} color={colors.iconSuccess} />
    )}
    {iconName === IconName.WARNING && (
      <WarningIcon iconSize={IconSize.MEDIUM} color={colors.iconWarning} />
    )}
  </>
);

export default FormattedIcon;
