import { entityGroupApi } from '@ardoq/api';
import { ArdoqId, PersistedEntityGroupShape } from '@ardoq/api-types';
import { PrimaryButton } from '@ardoq/button';
import { confirmDelete } from '@ardoq/modal';
import { useCallback, useEffect, useState } from 'react';
import ManageEntityGroupTable from './EntityGroupList';
import { LoadingContainer } from 'admin/bundles/BundleOverview/atoms';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { Header1, Paragraph } from '@ardoq/typography';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';

const noEntityGroupsIn = (entityGroups: Array<PersistedEntityGroupShape>) => {
  if (entityGroups.length < 1) return true;
  return false;
};

const findName = (
  entityGroups: Array<PersistedEntityGroupShape>,
  id?: ArdoqId
) => {
  if (!entityGroups) return 'Name does not exist';
  if (noEntityGroupsIn(entityGroups)) return 'Name does not exist';
  const entityGroup = entityGroups.find(({ _id }) => _id === id);
  if (!entityGroup) return 'Name does not exist';
  return entityGroup.name;
};

const Loading = () => (
  <LoadingContainer>
    <ArdoqLoaderComponent
      size={LoaderSize.MEDIUM}
      loaderType="spinner"
      loaderColor={LoaderColor.LIGHT}
    />
  </LoadingContainer>
);

const Error = ({ error, onClick }: { error: string; onClick: () => void }) => (
  <Box padding="xxlarge" marginTop="xxlarge">
    <Stack gap="medium">
      <FlexBox justify="center">
        <Header1>Error</Header1>
      </FlexBox>
      <FlexBox justify="center">
        <Paragraph>{error}</Paragraph>
      </FlexBox>
      <FlexBox justify="center">
        <PrimaryButton onClick={onClick}>Reload</PrimaryButton>
      </FlexBox>
    </Stack>
  </Box>
);

const Empty = ({ onClick }: { onClick: () => void }) => (
  <Box padding="xxlarge" marginTop="xxlarge">
    <Stack gap="medium">
      <FlexBox justify="center">
        <Header1>Error</Header1>
      </FlexBox>
      <FlexBox justify="center">
        <Paragraph>No entity groups exist.</Paragraph>
      </FlexBox>
      <FlexBox justify="center">
        <PrimaryButton onClick={onClick}>Create entity group</PrimaryButton>
      </FlexBox>
    </Stack>
  </Box>
);

const confirmDeleteEntityGroup = (name: string) => {
  return confirmDelete({
    title: 'Delete entity group?',
    text: (
      <>
        <p>You are about to delete the entity group &quot;{name}&quot;.</p>
        <p>This action can not be undone.</p>
      </>
    ),
  });
};

type EntityGroupOverviewProps = {
  editEntityGroup: (entityGroupId: ArdoqId) => void;
  createFromExistingEntityGroup: (entityGroupId: ArdoqId) => void;
  createEntityGroup: () => void;
};

const EntityGroupOverview = ({
  editEntityGroup,
  createEntityGroup,
  createFromExistingEntityGroup,
}: EntityGroupOverviewProps) => {
  const locale = getCurrentLocale();
  const [entityGroups, setEntityGroups] = useState<PersistedEntityGroupShape[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchEntityGroups = useCallback(async () => {
    setError(null);
    setIsLoading(true);
    const fetchedEntityGroups = await entityGroupApi.fetchAll();
    if (isArdoqError(fetchedEntityGroups)) {
      setError(getArdoqErrorMessage(fetchedEntityGroups));
    } else {
      setEntityGroups(
        fetchedEntityGroups.sort((a, b) =>
          localeCompare(a.name, b.name, locale)
        )
      );
    }
    setIsLoading(false);
  }, [locale]);

  const handleDelete = async (id: ArdoqId) => {
    const confirmed = await confirmDeleteEntityGroup(
      findName(entityGroups, id)
    );
    if (!confirmed) return;

    setIsLoading(true);
    const maybeError = await entityGroupApi.delete(id);
    if (isArdoqError(maybeError)) {
      setError(getArdoqErrorMessage(maybeError));
      setIsLoading(false);
      return;
    }
    await fetchEntityGroups();
    setIsLoading(false);
  };

  const handleEdit = (id: ArdoqId) => {
    editEntityGroup(id);
  };

  const handleCreateFromExistingEntityGroup = (id: ArdoqId) => {
    createFromExistingEntityGroup(id);
  };

  useEffect(() => {
    fetchEntityGroups();
  }, [fetchEntityGroups]);

  const entityGroupsWithBoundActions = entityGroups.map(
    (entityGroup: PersistedEntityGroupShape) => {
      return {
        ...entityGroup,
        handleDelete,
        handleEdit,
        handleCreateFromExistingEntityGroup,
      };
    }
  );

  if (isLoading) return <Loading />;
  if (error) return <Error onClick={fetchEntityGroups} error={error} />;
  if (noEntityGroupsIn(entityGroups))
    return <Empty onClick={createEntityGroup} />;
  return <ManageEntityGroupTable entityGroups={entityGroupsWithBoundActions} />;
};

export default EntityGroupOverview;
