import { ViewpointNavigatorListItem } from './types';
import { getIcon, IconName, IconSize } from '@ardoq/icons';
import { viewpointNavigatorCommands } from './viewpointNavigatorCommands';
import { traversalInterface } from 'modelInterface/traversals/traversalInterface';
import { pluralize } from '@ardoq/common-helpers';
import { componentInterface } from '@ardoq/component-interface';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import { getViewpointNavigatorEntrySummary } from './getViewpointNavigatorEntrySummary';
import {
  isAnyTraversalLoadedState,
  isCreatedItemsLoadedState,
  isManualComponentSelection,
  isTraversalCreatedInViewLoadedState,
  isTraversalLoadedState,
  LoadedState,
  LoadedStateType,
  MetaModelComponentType,
} from '@ardoq/api-types';
import { getStartSetFilter } from 'viewpointBuilder/getStartSetFilter';

const getEntryLabel = (entry: LoadedState): string => {
  switch (entry.type) {
    case LoadedStateType.TRAVERSAL:
      if (entry.traversalId) {
        const storedTraversal = traversalInterface.getById(entry.traversalId);
        if (storedTraversal) {
          return storedTraversal.name;
        }
      }
      return 'New dataset';

    case LoadedStateType.TRAVERSAL_CREATED_IN_VIEW:
      return 'New dataset';

    case LoadedStateType.SEARCH: {
      const componentIds = isManualComponentSelection(
        entry.data.componentSelection
      )
        ? entry.data.componentSelection.startSet
        : (entry.componentIdsAndReferences?.componentIds ?? []);
      const componentCount = componentIds.length;
      return `${componentCount} ${pluralize('component', componentCount)}`;
    }

    case LoadedStateType.CREATED_ITEMS:
      return 'New connected data';

    default:
      entry satisfies never;
      return '';
  }
};

const getContextComponentType = (entry: LoadedState) => {
  if (!isTraversalLoadedState(entry)) {
    return undefined;
  }
  const componentIds = entry.componentIdsAndReferences?.startSetResult ?? [];
  // when the dataset generates no data due to, for example, required paths,
  // the startSetResult will be empty. To make the context switcher only show
  // components for the correct type, we get the start type from the paths instead
  // in these cases.
  if (!componentIds.length) {
    const firstTriple = entry.data.paths[0][0];
    return firstTriple.direction === 'outgoing'
      ? firstTriple.sourceType
      : firstTriple.targetType;
  }
  return componentInterface.getTypeName(componentIds[0]) ?? undefined;
};

const getContextSwitcherCurrentContext = (
  entry: LoadedState,
  componentTypes: MetaModelComponentType[]
) => {
  if (isAnyTraversalLoadedState(entry)) {
    const componentIds = entry.componentIdsAndReferences?.startSetResult ?? [];
    if (componentIds.length === 1) {
      const representationData = componentInterface.getRepresentationData(
        componentIds[0]
      );
      const label = componentInterface.getDisplayName(componentIds[0]) ?? '';
      return {
        label,
        representationData: representationData
          ? {
              ...representationData,
              style: {
                color: componentInterface.getComponentColor(componentIds[0]),
              },
            }
          : null,
      };
    }
    const typeName = componentInterface.getTypeName(componentIds[0]);
    const componentType = componentTypes.find(type => type.name === typeName);
    if (!componentType) {
      return null;
    }
    const representationData = {
      isImage: Boolean(componentType.style.image) && !componentType.style.icon,
      value: componentType.style.image ?? null,
      icon: getIcon(componentType.style.icon),
      iconSize: IconSize.SMALL,
      style: { color: componentType.style.color },
    };

    return {
      label: `${componentIds.length} ${pluralize(
        componentType.name,
        componentIds.length
      )}`,
      representationData,
    };
  }
  return null;
};

const getEditButton = (entry: LoadedState) => {
  if (isCreatedItemsLoadedState(entry)) {
    return null;
  }
  return {
    text: 'Edit dataset',
    icon: isTraversalLoadedState(entry)
      ? IconName.ACCOUNT_TREE
      : IconName.APPLICATION_GROUP,
    action: () =>
      entry.isHidden ? {} : viewpointNavigatorCommands.editBlock(entry),
  };
};

const prepareViewpointNavigatorListItem = (
  entry: LoadedState,
  componentTypes: MetaModelComponentType[]
): ViewpointNavigatorListItem => {
  const { isHidden } = entry;
  const label = getEntryLabel(entry);
  const loadedStateHash = loadedStateOperations.stateToHash(entry);

  const isTraversal = isAnyTraversalLoadedState(entry);

  const startSetFilter = isTraversal
    ? getStartSetFilter({
        paths: entry.data.paths,
        filters: entry.data.filters,
      })
    : null;

  return {
    key: `loaded-state-${loadedStateHash}`,
    loadedStateHash,
    label,
    isTraversal,
    isHidden,
    contextSwitcherCurrentContext: getContextSwitcherCurrentContext(
      entry,
      componentTypes
    ),
    isContextReadOnly: isTraversalCreatedInViewLoadedState(entry),
    editButton: getEditButton(entry),
    summary: getViewpointNavigatorEntrySummary(entry),
    traversalContextComponentType: getContextComponentType(entry),
    isSaved: isTraversalLoadedState(entry) && Boolean(entry.traversalId),
    startSetFilter,
    pathCollapsingRules: isTraversal ? entry.data.pathCollapsingRules : [],
  } satisfies ViewpointNavigatorListItem;
};

/**
 * This module provides an interface for the viewpoint navigator.
 * It reaches to model interfaces to get the necessary data to display the navigator.
 */
export const viewpointNavigatorInterface = {
  prepareViewpointNavigatorListItem,
};
