import { combineLatest, map, Observable } from 'rxjs';
import { Field, rawFields$ } from '../fields/fields$';
import { getWorkspaceIdsAndTypeNamesFromTraversalPaths } from './getWorkspaceIdsAndTypeNamesFromTraversalPaths';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { loadedState$ } from 'loadedState/loadedState$';
import { componentInterface } from '@ardoq/component-interface';
import { metaModelAsScopeData$ } from 'viewpointBuilder/metaModel/loadMetaModelAsScopeData$';
import tags$ from '../tags/tags$';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { isEqual, partition } from 'lodash';
import {
  type APITagAttributes,
  ArdoqId,
  CreatedItemsLoadedState,
  isAnyTraversalLoadedState,
  LoadedState,
  SearchLoadedState,
  TraversalCreatedInViewLoadedState,
  TraversalLoadedState,
} from '@ardoq/api-types';

type OptionsSourceData = {
  componentTypeNamesSet: Set<string>;
  referenceTypeNamesSet: Set<string>;
  workspaceIdsSet: Set<string>;
  availableComponentFields: Field[];
  availableReferenceFields: Field[];
};

type OptionsSourceDataReducerState = OptionsSourceData & {
  metaModelAsScopeData: {
    componentNameAndFieldsIndex: Map<string, string[]>;
    componentNameAndWorkspacesIndex: Map<string, string[]>;
    referenceNameAndFieldsIndex: Map<string, string[]>;
  };
  fieldMap: Map<string, Field>;
};

const addOptionsSourceDataForTraversal = (
  optionsSourceDataReducerState: OptionsSourceDataReducerState,
  entry: TraversalLoadedState | TraversalCreatedInViewLoadedState
) => {
  const {
    componentTypeNamesSet,
    referenceTypeNamesSet,
    workspaceIdsSet,
    availableComponentFields,
    availableReferenceFields,
    fieldMap,
    metaModelAsScopeData: {
      componentNameAndFieldsIndex,
      componentNameAndWorkspacesIndex,
      referenceNameAndFieldsIndex,
    },
  } = optionsSourceDataReducerState;

  const {
    componentTypeNames,
    referenceTypeNames,
    workspaceIds,
    availableComponentFields: entryAvailableComponentFields,
    availableReferenceFields: entryAvailableReferenceFields,
  } = getWorkspaceIdsAndTypeNamesFromTraversalPaths({
    paths: entry.data.paths,
    componentNameAndFieldsIndex,
    componentNameAndWorkspacesIndex,
    referenceNameAndFieldsIndex,
    fieldMap,
  });
  componentTypeNames.forEach(componentTypeNamesSet.add, componentTypeNamesSet);
  referenceTypeNames.forEach(referenceTypeNamesSet.add, referenceTypeNamesSet);
  workspaceIds.forEach(workspaceIdsSet.add, workspaceIdsSet);
  entryAvailableComponentFields.forEach(field =>
    availableComponentFields.push(field)
  );
  entryAvailableReferenceFields.forEach(field =>
    availableReferenceFields.push(field)
  );
  entry.data.pathCollapsingRules.forEach(({ displayText }) =>
    referenceTypeNamesSet.add(displayText)
  );
  return optionsSourceDataReducerState;
};

const addOptionsSourceDataForSearchOrCreatedItemsLoadedState = (
  optionsSourceDataReducerState: OptionsSourceDataReducerState,
  entry: SearchLoadedState | CreatedItemsLoadedState
) => {
  const {
    componentTypeNamesSet,
    workspaceIdsSet,
    availableComponentFields,
    fieldMap,
    metaModelAsScopeData: {
      componentNameAndFieldsIndex,
      componentNameAndWorkspacesIndex,
    },
  } = optionsSourceDataReducerState;

  const componentIds = entry.componentIdsAndReferences?.componentIds;
  componentIds?.forEach(componentId => {
    const componentTypeName = componentInterface.getType(componentId)?.name;
    if (!componentTypeName) {
      return;
    }

    const workspaceIds = componentNameAndWorkspacesIndex.get(componentTypeName);
    const fields = componentNameAndFieldsIndex.get(componentTypeName);

    componentTypeNamesSet.add(componentTypeName);
    workspaceIds?.forEach(workspaceIdsSet.add, workspaceIdsSet);
    fields?.forEach(fieldId => {
      const field = fieldMap.get(fieldId);
      if (field) {
        availableComponentFields.push(field);
      }
    });
  });
  return optionsSourceDataReducerState;
};

const getLoadedStatesSeparatedByType = (loadedStates: LoadedState[]) => {
  const [traversalLoadedStates, searchOrCreatedItemsLoadedStates] = partition(
    loadedStates,
    state => isAnyTraversalLoadedState(state)
  );

  return {
    traversalLoadedStates,
    searchOrCreatedItemsLoadedStates,
  };
};

export type ViewpointOptionsSourceData = {
  workspaceIds: ArdoqId[];
  componentTypeNames: string[];
  referenceTypeNames: string[];
  availableComponentFields: Field[];
  availableReferenceFields: Field[];
  tags: APITagAttributes[];
};

export const viewpointOptionsSourceData$: Observable<ViewpointOptionsSourceData | null> =
  combineLatest({
    loadedStates: loadedState$,
    rawFields: rawFields$,
    isViewpointMode: isViewpointMode$,
    metaModelAsScopeData: metaModelAsScopeData$,
    tags: tags$,
  }).pipe(
    map(
      ({
        loadedStates,
        rawFields: { byId },
        isViewpointMode: { isViewpointMode },
        metaModelAsScopeData,
        tags,
      }) => {
        if (
          !isViewpointMode ||
          metaModelAsScopeData.status !== 'DATA_LOADED' ||
          tags.fetchingStatus !== 'success'
        ) {
          return null;
        }

        const fieldMap = new Map(
          Object.values(byId).map(field => [field.name, field])
        );

        const groupingOptionsSourceDataReducerState: OptionsSourceDataReducerState =
          {
            componentTypeNamesSet: new Set<string>(),
            referenceTypeNamesSet: new Set<string>(),
            workspaceIdsSet: new Set<string>(),
            availableComponentFields: [] as Field[],
            availableReferenceFields: [] as Field[],
            metaModelAsScopeData,
            fieldMap,
          };

        const { traversalLoadedStates, searchOrCreatedItemsLoadedStates } =
          getLoadedStatesSeparatedByType(loadedStates);

        const traversalStatesOptionsData = traversalLoadedStates.reduce(
          addOptionsSourceDataForTraversal,
          groupingOptionsSourceDataReducerState
        );

        const traversalAndSearchAndCreatedItemsStatesOptionsData =
          searchOrCreatedItemsLoadedStates.reduce(
            addOptionsSourceDataForSearchOrCreatedItemsLoadedState,
            traversalStatesOptionsData
          );

        const {
          componentTypeNamesSet,
          referenceTypeNamesSet,
          workspaceIdsSet,
          availableComponentFields,
          availableReferenceFields,
        } = traversalAndSearchAndCreatedItemsStatesOptionsData;
        return {
          workspaceIds: Array.from(workspaceIdsSet),
          componentTypeNames: Array.from(componentTypeNamesSet),
          referenceTypeNames: Array.from(referenceTypeNamesSet),
          tags: tags.tags,
          availableComponentFields,
          availableReferenceFields,
        };
      }
    ),
    startWith(null),
    distinctUntilChanged(isEqual)
  );
