import { SubdivisionSummary } from '@ardoq/api-types';
import { SubdivisionComponentsTable } from './SubdivisionComponentsTable';
import { Header4 } from '@ardoq/typography';
import { FlexBox } from '@ardoq/layout';
import {
  EmptyState,
  EmptyStateNoResultIllustration,
  Tag,
} from '@ardoq/status-ui';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { connect } from '@ardoq/rxbeach';
import { subdivisionSummaryViewModel$ } from './subdivisionSummaryViewModel$/subdivisionSummaryViewModel$';
import {
  SubdivisionSummaryViewModelCommands,
  subdivisionSummaryViewModelCommands,
} from './subdivisionSummaryViewModel$/commands';
import {
  ArdoqLoaderComponent,
  LoaderColor,
} from '@ardoq/ardoq-loader-component';
import { ErrorMessage } from '@ardoq/error-boundary';
import { WrappedSubdivisionSummaryInformation } from './atoms';
import { combineLatest, map } from 'rxjs';
import { subdivisionSummary$ } from './subdivisionSummary$/subdivisionSummary$';

const _SubdivisionSummaryInformation = ({
  subdivisionSummary,
  isInDrawer = true,
  searchKey,
  isLoading,
  errorMessage,
  commands,
}: {
  subdivisionSummary?: SubdivisionSummary;
  isInDrawer?: boolean;
  searchKey: string;
  isLoading: boolean;
  errorMessage: string;
  commands: SubdivisionSummaryViewModelCommands;
}) => {
  if (isLoading) {
    return (
      <FlexBox height="full" align="center" justify="center">
        <ArdoqLoaderComponent
          labelPosition="vertical"
          loaderColor={LoaderColor.BRAND}
          loaderType="logo"
          size={50}
        />
      </FlexBox>
    );
  }

  if (errorMessage) {
    return <ErrorMessage />;
  }

  const components = subdivisionSummary?.components || [];

  return (
    <>
      <Header4>
        <FlexBox gap="xsmall" align="center">
          Components
          <Tag>{components.length}</Tag>
        </FlexBox>
      </Header4>
      {components.length > 0 ? (
        <WrappedSubdivisionSummaryInformation isWrapped={isInDrawer}>
          <SubdivisionComponentsTable
            searchKey={searchKey}
            isInDrawer={isInDrawer}
            components={components}
            commands={commands}
          />
        </WrappedSubdivisionSummaryInformation>
      ) : (
        <FlexBox justify="center" align="center" height="full">
          <EmptyState
            title={SUBDIVISIONS_STRINGS.OVERVIEW_PAGE.NO_COMPONENTS_TITLE}
            description={
              SUBDIVISIONS_STRINGS.OVERVIEW_PAGE.NO_COMPONENTS_DESCRIPTION
            }
            media={<EmptyStateNoResultIllustration />}
          ></EmptyState>
        </FlexBox>
      )}
    </>
  );
};

export const SubdivisionSummaryInformation = connect(
  _SubdivisionSummaryInformation,
  combineLatest([subdivisionSummaryViewModel$, subdivisionSummary$]).pipe(
    map(([{ searchKey }, { isLoading, subdivisionSummary, errorMessage }]) => {
      return {
        searchKey,
        isLoading,
        subdivisionSummary,
        errorMessage,
        commands: subdivisionSummaryViewModelCommands,
      };
    })
  )
);
