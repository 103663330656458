import { workspaceApi, handleError } from '@ardoq/api';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { switchMap, tap } from 'rxjs';
import {
  deleteSelectedWorkspaces,
  fetchWorkspaceOverview,
  fetchWorkspaceOverviewSuccess,
  setGremlinForIds,
  setGremlinForIdsSuccess,
  websocketFakerDeletedWorkspaces,
} from './actions';

const handleOpenWorkspaceManager = routine(
  ofType(fetchWorkspaceOverview),
  switchMap(workspaceApi.overview),
  handleError(),
  tap(response => {
    dispatchAction(fetchWorkspaceOverviewSuccess(response));
  })
);

const handleDeleteWorkspaces = routine(
  ofType(deleteSelectedWorkspaces),
  extractPayload(),
  switchMap(workspaceApi.deleteWorkspaces),
  handleError(),
  tap(ids => {
    dispatchAction(websocketFakerDeletedWorkspaces(ids));
  })
);

const handleSetGremlinForIds = routine(
  ofType(setGremlinForIds),
  extractPayload(),
  switchMap(workspaceApi.setGremlinEnabled),
  handleError(),
  tap(response => {
    dispatchAction(setGremlinForIdsSuccess(response));
  })
);

export default collectRoutines(
  handleOpenWorkspaceManager,
  handleDeleteWorkspaces,
  handleSetGremlinForIds
);
