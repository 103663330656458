import {
  InventoryGridApi,
  InventoryInteractiveTopRowCommands,
} from '@ardoq/inventory';
import { dispatchAction } from '@ardoq/rxbeach';
import { inventoryGridApi$ } from '../inventoryGridApi$';
import { enterCreateComponentsMode, exitCreateComponentsMode } from './actions';
import { Maybe } from '@ardoq/common-helpers';
import { inventoryTracking } from '../inventoryTracking';

export const getInventoryInteractiveTopRowCommands = (
  inventoryGridApi: Maybe<InventoryGridApi>
): InventoryInteractiveTopRowCommands => ({
  enterCreateComponentsMode: () => {
    dispatchAction(enterCreateComponentsMode());
    inventoryTracking.trackEnterCreateComponentsModeFromTopRow();
  },
  exitCreateComponentsMode: () => {
    dispatchAction(exitCreateComponentsMode());
    inventoryGridApi$.state?.exitCreateComponentMode();
    inventoryTracking.trackExitCreateComponentsModeFromTopRow();
  },
  openColumnSelectionPanel: () => {
    inventoryGridApi$.state?.openColumnSelectionPanel();
    inventoryTracking.trackOpenColumnSelectionPanelFromTopRow();
  },
  deselectAllRows: () => {
    inventoryGridApi?.deselectAllRows();
    inventoryTracking.trackDeselectAllRowsFromTopRow();
  },
  addNewRow: () => {
    inventoryGridApi?.addNewRow();
    inventoryTracking.trackAddNewRowFromTopRow();
  },
  setSearchPhrase: (searchPhrase: string) => {
    inventoryGridApi?.setSearchByNameFilter(searchPhrase);
  },
});
