import {
  loadDataFailure,
  loadDataInit,
  loadDataSuccess,
  resetIntegration,
} from 'integrations/unified/streams/dataLoading/actions';
import {
  IntegrationDataLoading,
  IntegrationIdToDataLoading,
  DataLoadingInitialState,
} from 'integrations/unified/streams/dataLoading/types';
import { getInstanceStream } from '../utils';
import { buildInitialStreamState } from 'integrations/unified/streams/utils';
import { UnifiedIntegrationId } from 'integrations/unified/types';
import fp from 'lodash/fp';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { Observable } from 'rxjs';
import { ActionCreatorParameter } from 'integrations/common/utils/actionCreatorWithIntegrationId';

const initialInstanceState: DataLoadingInitialState = {
  status: 'INIT',
};

const defaultState: IntegrationIdToDataLoading =
  buildInitialStreamState<DataLoadingInitialState>(initialInstanceState);

const resetIntegrationReducerFn = (
  state: IntegrationIdToDataLoading,
  { integrationId }: ActionCreatorParameter<typeof resetIntegration>
) => ({
  ...state,
  [integrationId]: initialInstanceState,
});

const loadDataInitReducerFn = (
  state: IntegrationIdToDataLoading,
  { integrationId, payload }: ActionCreatorParameter<typeof loadDataInit>
): IntegrationIdToDataLoading => ({
  ...state,
  [integrationId]: {
    status: 'LOADING',
    payload,
  },
});

const loadDataSuccessReducerFn = (
  state: IntegrationIdToDataLoading,
  { response, integrationId }: ActionCreatorParameter<typeof loadDataSuccess>
) =>
  fp.update(
    integrationId,
    integrationState => ({
      ...integrationState,
      status: 'SUCCESS',
      response,
    }),
    state
  );

const loadDataFailureReducerFn = (
  state: IntegrationIdToDataLoading,
  {
    message,
    level,
    integrationId,
  }: ActionCreatorParameter<typeof loadDataFailure>
) =>
  fp.update(
    integrationId,
    integrationState => ({
      ...integrationState,
      status: 'FAILURE',
      message,
      level,
    }),
    state
  );

const handleResetIntegration = reducer<
  IntegrationIdToDataLoading,
  ActionCreatorParameter<typeof resetIntegration>
>(resetIntegration, resetIntegrationReducerFn);

const handleLoadDataInit = reducer<
  IntegrationIdToDataLoading,
  ActionCreatorParameter<typeof loadDataInit>
>(loadDataInit, loadDataInitReducerFn);

const handleLoadDataSuccess = reducer<
  IntegrationIdToDataLoading,
  ActionCreatorParameter<typeof loadDataSuccess>
>(loadDataSuccess, loadDataSuccessReducerFn);

const handleLoadDataFailure = reducer<
  IntegrationIdToDataLoading,
  ActionCreatorParameter<typeof loadDataFailure>
>(loadDataFailure, loadDataFailureReducerFn);

const reducers = [
  handleResetIntegration,
  handleLoadDataInit,
  handleLoadDataSuccess,
  handleLoadDataFailure,
];

const dataLoading$ = persistentReducedStream(
  'integrationsUnifiedDataLoading$',
  defaultState,
  reducers
);

export const getDataLoadingStream = (
  integrationId: UnifiedIntegrationId
): Observable<IntegrationDataLoading> =>
  getInstanceStream(dataLoading$, integrationId);
