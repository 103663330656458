import {
  APIResourcePermissionAttributes,
  ArdoqId,
  GroupResourcePermission,
  Zone,
} from '@ardoq/api-types';
import { Icon, IconName } from '@ardoq/icons';
import { Select, SelectOption } from '@ardoq/select';
import { ZonesById } from '@ardoq/subdivisions';
import { PermissionSelectValueType, ZoneCommands } from '../types';
import { ButtonType, IconButton } from '@ardoq/button';
import { zonesOperations } from '../operations';
import {
  ALL_ORGANIZATION_MEMBERS_GROUP,
  ENTIRE_ORGANIZATION_GROUP,
} from '../groups$/types';
import { modal } from '@ardoq/modal';
import { NewZoneModal } from './NewZoneModal';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { FlexBox } from '@ardoq/layout';
import {
  DIVISIONS_USER_EVENTS,
  trackDivisionEditorUserEvent,
} from 'subdivisionEditor/trackingUtils';

const permissionSelectOptions = [
  {
    value: PermissionSelectValueType.READ,
    label: 'Can view',
    iconName: IconName.VISIBILITY,
  },
  {
    value: PermissionSelectValueType.EDIT,
    label: 'Can edit',
    iconName: IconName.EDIT,
  },
];

type GroupPermissionTableRow = {
  id: string;
  groupPermission: GroupResourcePermission;
  zoneId: ArdoqId;
};

const getZoneDropdownOptions = (
  zonesById: ZonesById,
  groupName: string,
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>
): SelectOption<ArdoqId>[] => {
  const options: SelectOption<ArdoqId>[] = Object.values(zonesById).map(
    zone => ({
      label: zone.name,
      value: zone._id,
      isDisabled: zonesOperations.isGroupPermissionConfiguredWithZone(
        groupName,
        permissionsByZoneId,
        zone
      ),
    })
  );
  options.push({
    label: 'Create new field group',
    value: '',
    description: 'Specify a group of component fields',
  });
  return options;
};

export const getGroupPermissionsTableDataSource = (
  zonesById: ZonesById,
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>
): GroupPermissionTableRow[] => {
  const locale = getCurrentLocale();
  const result: GroupPermissionTableRow[] = [];

  Object.entries(zonesById).forEach(([zoneId, zone]) => {
    const permission = permissionsByZoneId[zoneId];
    if (!permission) {
      return;
    }
    const groupsInPermission = permission.groups;
    groupsInPermission.forEach(group => {
      result.push({
        id: `${group._id}-${zone._id}`,
        groupPermission: group,
        zoneId: zone._id,
      });
    });
  });
  result.sort((a, b) => {
    if (!a.groupPermission.displayName) {
      return -1;
    }
    if (!b.groupPermission.displayName) {
      return 1;
    }
    return localeCompare(
      a.groupPermission.displayName,
      b.groupPermission.displayName,
      locale
    );
  });
  return result;
};

const getGroupName = (groupPermission: GroupResourcePermission) => {
  if (zonesOperations.isAllOrganizationMembersGroup(groupPermission.name))
    return ALL_ORGANIZATION_MEMBERS_GROUP.name;
  if (zonesOperations.isEntireOrganizationMembersGroup(groupPermission.name))
    return ENTIRE_ORGANIZATION_GROUP.name;
  return groupPermission.displayName;
};

const updatePermissionWithNewlyCreatedZone = (
  newZoneData: {
    newZone: Zone;
    permissions: APIResourcePermissionAttributes[];
  },
  previousZoneId: ArdoqId,
  groupPermission: GroupResourcePermission,
  commands: ZoneCommands,
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>
) => {
  const permissions = newZoneData.permissions.find(
    ({ resource }) => resource === newZoneData.newZone._id
  );

  if (permissions && permissionsByZoneId[previousZoneId]) {
    commands.moveZoneGroupPermission({
      groupPermission,
      fromZonePermission: permissionsByZoneId[previousZoneId],
      toZonePermission: permissions,
    });
  }
};

export const getGroupPermissionsTableColumns = (
  zonesById: ZonesById,
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>,
  commands: ZoneCommands
) => [
  {
    dataIndex: 'id',
    title: 'Group',
    valueRender: (_: string, { groupPermission }: GroupPermissionTableRow) => (
      <FlexBox align="end" gap="small">
        <Icon iconName={zonesOperations.getGroupIcon(groupPermission.name)} />
        {getGroupName(groupPermission)}
      </FlexBox>
    ),
  },
  {
    dataIndex: 'id',
    title: 'Permissions',
    valueRender: (
      _: string,
      { groupPermission, zoneId }: GroupPermissionTableRow
    ) => (
      <Select
        value={zonesOperations.mapPermissionLevelsToSelectOptionValue(
          groupPermission.permissions
        )}
        options={permissionSelectOptions}
        onValueChange={value =>
          commands.changeZoneGroupPermission({
            permissionLevels:
              zonesOperations.mapSelectOptionValueToPermissionLevels(value),
            groupName: groupPermission.name,
            zonePermission: permissionsByZoneId[zoneId],
          })
        }
      />
    ),
  },
  {
    dataIndex: 'id',
    title: 'Field(s)',
    valueRender: (
      _: string,
      { zoneId, groupPermission }: GroupPermissionTableRow
    ) => (
      <Select
        value={zoneId}
        keepRightContentActive
        options={getZoneDropdownOptions(
          zonesById,
          groupPermission.name,
          permissionsByZoneId
        )}
        onValueChange={newZoneId =>
          newZoneId
            ? commands.moveZoneGroupPermission({
                groupPermission,
                fromZonePermission: permissionsByZoneId[zoneId],
                toZonePermission: permissionsByZoneId[newZoneId],
              })
            : (trackDivisionEditorUserEvent(
                DIVISIONS_USER_EVENTS.OPENED_CREATE_NEW_ZONE_MODAL
              ),
              modal(closeModal => (
                <NewZoneModal
                  subdivisionId={zonesById[zoneId].subId}
                  commands={commands}
                  closeModal={() => closeModal(true)}
                  onConfirm={newZone => {
                    updatePermissionWithNewlyCreatedZone(
                      newZone,
                      zoneId,
                      groupPermission,
                      commands,
                      permissionsByZoneId
                    );
                  }}
                />
              )))
        }
      ></Select>
    ),
  },
  {
    dataIndex: 'id',
    headerStyle: { width: 56 },
    valueRender: (
      _: string,
      { zoneId, groupPermission }: GroupPermissionTableRow
    ) => (
      <IconButton
        buttonType={ButtonType.GHOST}
        iconName={IconName.DELETE}
        onClick={() =>
          commands.deleteZoneGroupPermission({
            groupName: groupPermission.name,
            zonePermission: permissionsByZoneId[zoneId],
          })
        }
      />
    ),
  },
];
