import { colors } from '@ardoq/design-tokens';
import { Space } from '@ardoq/style-helpers';
import styled from 'styled-components';

export const FixedInfoBanner = styled(Space).attrs({
  $paddingLeft: 's16',
  $paddingRight: 's16',
  $paddingTop: 's8',
  $paddingBottom: 's8',
  $justify: 'space-between',
  $align: 'center',
})`
  background-color: ${colors.blue95};
  z-index: 9;
  width: 100%;
  position: sticky;
`;
