import { QueryBuilderSubquery } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { SelectedComponent } from '@ardoq/inventory';

export const enterCreateComponentsMode = actionCreator(
  '[inventoryInteractiveTopRow] ENTER_CREATE_COMPONENTS_MODE'
);

export const exitCreateComponentsMode = actionCreator(
  '[inventoryInteractiveTopRow] EXIT_CREATE_COMPONENTS_MODE'
);

export const componentSelectionChanged = actionCreator<SelectedComponent[]>(
  '[inventoryInteractiveTopRow] COMPONENT_SELECTION_CHANGED'
);

export const filtersChanged = actionCreator<QueryBuilderSubquery>(
  '[inventoryInteractiveTopRow] FILTERS_CHANGED'
);

export type GotNextRowsBatchPayload = {
  totalNumberOfRows: number;
};

export const gotNextRowsBatch = actionCreator<GotNextRowsBatchPayload>(
  '[inventoryInteractiveTopRow] GOT_NEXT_ROWS_BATCH'
);

export const componentWasCreated = actionCreator(
  '[inventoryInteractiveTopRow] COMPONENT_WAS_CREATED'
);
