import * as React from 'react';
import { ViewpointBuilderFooter } from 'viewpointBuilder/components/ViewpointBuilderFooter';
import { TraversalBuilderViewState } from '../types';
import { PrimaryButtonConfig } from 'viewpointBuilder/openViewpointBuilder/getPrimaryButtonConfig';
import { WithMetamodelLoader } from '../components/WithMetamodelLoader';
import { ResizablePageBody } from '@ardoq/page-layout';
import SimpleGraphView from 'viewpointBuilder/components/SimpleGraph/SimpleGraphView';
import { SimpleGraphContainerWithGreyBackground } from 'viewpointBuilder/components/SimpleGraph/SimpleGraphViewContainer';
import { createGraphInterface } from '../components/SimpleGraph/GraphInterface';
import CollapsePathsSidebar from './CollapsePathsSidebar';
import { collapsePathsCommands } from './collapsePathsCommands';

type CollapsePathsTabContentProps = {
  state: TraversalBuilderViewState;
  closeViewpointBuilder: () => void;
  toggleGraphNodeSelection: (
    event: React.MouseEvent<Element, MouseEvent>
  ) => void;
  primaryButtonConfig: PrimaryButtonConfig;
  label: string;
  graphNodeOnHover: (event: React.MouseEvent<Element, MouseEvent>) => void;
};

export const CollapsePathsTabContent = ({
  state,
  closeViewpointBuilder,
  toggleGraphNodeSelection,
  primaryButtonConfig,
  label,
  graphNodeOnHover,
}: CollapsePathsTabContentProps) => {
  const {
    graphNodes,
    graphEdges,
    graphGroups,
    isMetaModelLoaded,
    pathCollapsing: { currentEditableRule },
  } = state;
  const graphInterface = React.useRef(createGraphInterface());
  const isFirstRender = React.useRef(true);
  React.useEffect(() => {
    isFirstRender.current = false;
  }, []);
  return (
    <WithMetamodelLoader
      isMetaModelLoaded={isMetaModelLoaded}
      closeViewpointBuilder={closeViewpointBuilder}
    >
      <ResizablePageBody
        skipPadding
        footerContent={
          <ViewpointBuilderFooter
            closeViewpointBuilder={closeViewpointBuilder}
            primaryButtonConfig={primaryButtonConfig}
          />
        }
        rightContent={
          <CollapsePathsSidebar
            state={state}
            commands={collapsePathsCommands}
          />
        }
        rightContentInitialWidth={530}
        rightContentMinWidth={400}
      >
        <SimpleGraphContainerWithGreyBackground
          onClick={toggleGraphNodeSelection}
          onMouseOver={graphNodeOnHover}
        >
          <SimpleGraphView
            viewModel={{ graphNodes, graphEdges, graphGroups }}
            graphInterface={graphInterface.current}
            label={label}
            shouldFitToContentOnLayout={
              // This is a bit hacky, but at the moment I don't know a better way.
              // The intention is to not re-center the view while the user is
              // building the path to be collapsed. Each hover over a node will
              // would do that otherwise, so the user could not zoom in on some
              // detail while hovering on a node in some bigger graph. On the
              // other hand we like to make sure that on each first render the
              // view gets centered, if the user e.g. switch to another tab and
              // back while creating a collapse rule.
              isFirstRender.current || !currentEditableRule
            }
          />
        </SimpleGraphContainerWithGreyBackground>
      </ResizablePageBody>
    </WithMetamodelLoader>
  );
};
