import { metaModel$ } from 'architectureModel/metaModel$';
import { reducedStream, reducer, streamReducer } from '@ardoq/rxbeach';
import { distinctUntilChanged } from 'rxjs';
import { setTraversalId } from './actions';
import { traversalPreviewOperations } from './traversalPreviewOperations';
import traversals$ from 'streams/traversals$';
import { TraversalPreviewState } from './types';
import { emptyCollectionStream } from 'streams/utils/streamUtils';

const initialState: TraversalPreviewState = {
  metaModel: null,
  traversalId: null,
  errorState: 'NONE',
  graphNodes: new Map(),
  graphEdges: new Map(),
  graphGroups: new Map(),
  traversal: null,
  traversals: emptyCollectionStream(),
  isMetaModelLoaded: false,
};

export const TraversalPreview$ = reducedStream(
  'TraversalPreview$',
  initialState,
  [
    reducer(setTraversalId, traversalPreviewOperations.reduceSetTraversalId),
    streamReducer(traversals$, traversalPreviewOperations.reduceSetTraversals),
    streamReducer(metaModel$, traversalPreviewOperations.reduceSetMetamodel),
  ]
).pipe(distinctUntilChanged());
