import { DiffMergeTableProps, MergeStep } from './types';
import UpdatedEntitiesTable from 'components/DiffMergeTable/UpdatedEntitiesTable';
import diffMergeTable$ from './diffMergeTable$';
import { connect } from '@ardoq/rxbeach';
import TableWithCheckbox from './TableWithCheckbox';
import { Verb } from '@ardoq/api-types';
import { getEntityTypeAndVerbFromMergeStep } from 'components/DiffMergeTable/utils';
import MergeTagsTable from 'components/DiffMergeTable/MergeTagsTable';
import { withErrorBoundaries } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import { EmptyPageWrapper } from './atoms';
import NothingToMerge from './NothingToMerge';

const DiffMergeTable = ({
  mergeStep,
  dataSource,
  graphics,
  users,
  options,
  mergeDirection,
  nothingToMerge,
}: DiffMergeTableProps & {
  nothingToMerge: boolean;
}) => {
  const { entityType, verb } = getEntityTypeAndVerbFromMergeStep(mergeStep);
  if (nothingToMerge) {
    return <NothingToMerge mergeDirection={mergeDirection} />;
  }
  if (
    !(
      dataSource &&
      graphics &&
      users &&
      options &&
      mergeStep !== MergeStep.NONE &&
      entityType &&
      verb
    )
  ) {
    return <EmptyPageWrapper />;
  }
  if (verb === Verb.MERGE) {
    return (
      <MergeTagsTable
        dataSource={dataSource}
        mergeDirection={mergeDirection}
        graphics={graphics}
      />
    );
  } else if (verb === Verb.UPDATE) {
    return (
      <UpdatedEntitiesTable
        dataSource={dataSource}
        graphics={graphics}
        users={users}
        options={options}
        mergeDirection={mergeDirection}
        mergeStep={mergeStep}
      />
    );
  }
  return (
    <TableWithCheckbox
      mergeStep={mergeStep}
      entityType={entityType}
      dataSource={dataSource}
      graphics={graphics}
      users={users}
      options={options}
      mergeDirection={mergeDirection}
    />
  );
};

export default connect(
  withErrorBoundaries({
    logError,
    errorContextDescription: 'Error at <DiffMergeTable />',
  })(DiffMergeTable),
  diffMergeTable$
);
