import styled from 'styled-components';
import Topbar from 'menus/topbar/OldTopbar';
import Logo from './Logo';
import selectedModule$ from 'appContainer/selectedModule$';
import { NavBarProps, stateToNavBarProps } from './navBarHelpers';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { map } from 'rxjs/operators';
import { connect } from '@ardoq/rxbeach';
import { colors } from '@ardoq/design-tokens';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { combineLatest } from 'rxjs';
import { hasFeature, Features } from '@ardoq/features';

const Nav = styled.nav<{ $isScenarioMode: boolean; $isViewpointMode: boolean }>`
  display: flex;
  align-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;
  z-index: 11;
  font-size: 18px;
  height: 52px;
  color: ${colors.white};
  background-color: ${({ $isScenarioMode, $isViewpointMode }) =>
    $isScenarioMode
      ? colors.surfaceScenario
      : $isViewpointMode
        ? colors.brand25
        : colors.grey15};
`;

const Navbar = ({
  isScenarioMode,
  isViewpointMode,
  shouldShowNavBar,
  shouldShowLogo,
}: NavBarProps) => {
  if (!shouldShowNavBar) return null;
  return (
    <Nav $isScenarioMode={isScenarioMode} $isViewpointMode={isViewpointMode}>
      {shouldShowLogo && <Logo shouldUseNewJourneyVersion={false} />}
      <Topbar shouldUseNewJourneyVersion={false} />
    </Nav>
  );
};

export default connect(
  Navbar,
  combineLatest({
    activeScenario: activeScenario$,
    loadedGraph: loadedGraph$,
    selectedModule: selectedModule$,
  }).pipe(
    map(
      ({
        activeScenario: { scenarioId },
        loadedGraph: { isViewpointMode },
        selectedModule: { selectedModule },
      }) => ({
        scenarioId,
        isViewpointMode,
        selectedModule,
        hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
      })
    ),
    map(stateToNavBarProps)
  )
);
