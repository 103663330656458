import { useState } from 'react';
import { ModalLayout, ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { ColorThemeInput } from '@ardoq/color-picker';
import {
  APIDashboardColorTheme,
  HexColor,
  UnpersistedEntity,
} from '@ardoq/api-types';
import { ButtonGroup, SecondaryButton } from '@ardoq/button';
import { PasteIcon } from '@ardoq/icons';
import { s24 } from '@ardoq/design-tokens';
import { EMPTY_PALETTE, getColorsFromClipBoard } from './utils';
import { Field, FULL_TRANSPARENT } from '@ardoq/forms';
import { uniq } from 'lodash';

type CustomColorThemeModalContentProps = {
  colorTheme: UnpersistedEntity<APIDashboardColorTheme> | undefined;
  saveColorTheme: (
    colorTheme: UnpersistedEntity<APIDashboardColorTheme>
  ) => void;
  cancel: VoidFunction;
};

const CustomColorThemeModalContent = ({
  colorTheme,
  saveColorTheme,
  cancel,
}: CustomColorThemeModalContentProps) => {
  const [themeLabel, setThemeLabel] = useState(colorTheme?.label ?? '');
  const [themeColors, setThemeColors] = useState<Array<HexColor | null>>(
    colorTheme?.paletteColors ?? EMPTY_PALETTE
  );
  const isSaveButtonDisabled = !themeLabel || themeColors.some(color => !color);
  return (
    <ModalTemplate
      headerText="Add new color theme"
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={() =>
        saveColorTheme({
          isSelected: false,
          ...colorTheme,
          label: themeLabel,
          paletteColors: themeColors as HexColor[],
        })
      }
      onSecondaryButtonClick={cancel}
      inputLabel="Color theme name"
      inputPlaceHolderText="Type color theme name"
      inputValue={themeLabel}
      onInputValueChange={setThemeLabel}
      isPrimaryButtonDisabled={isSaveButtonDisabled}
      modalSize={ModalSize.S}
    >
      <ModalLayout>
        <ColorThemeInput
          label="Select colors"
          helperText="Select at least 8 distinct colors."
          themeColors={themeColors}
          updateThemeColors={setThemeColors}
        />
        <Field
          label="Paste color codes"
          helperText={
            <>
              Start each HEX code with “#” E.g #A88A8E #67C59A
              <br />
              Start each RGB code with “RGB” and separate them with brackets e.g
              RGB(139,0,0) RGB(0,255,0)
            </>
          }
        >
          <ButtonGroup style={{ marginTop: s24 }}>
            <SecondaryButton
              onClick={async () => {
                const colorsFromClipBoard = await getColorsFromClipBoard();
                const mergedColors = uniq(
                  [...themeColors, ...colorsFromClipBoard].filter(
                    x => x !== FULL_TRANSPARENT && x !== null
                  )
                );
                setThemeColors(
                  mergedColors.length < 8
                    ? mergedColors.concat(
                        EMPTY_PALETTE.slice(mergedColors.length)
                      )
                    : mergedColors
                );
              }}
            >
              <PasteIcon /> Paste from clipboard
            </SecondaryButton>
          </ButtonGroup>
        </Field>
      </ModalLayout>
    </ModalTemplate>
  );
};

export const launchCustomColorThemeModal = (
  colorTheme?: UnpersistedEntity<APIDashboardColorTheme>
) =>
  modal<UnpersistedEntity<APIDashboardColorTheme> | null>(
    resolve => (
      <CustomColorThemeModalContent
        colorTheme={colorTheme}
        saveColorTheme={colorThemeToSave => resolve(colorThemeToSave)}
        cancel={() => resolve(null)}
      />
    ),
    {
      shouldCloseOnBackdropClick: false,
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
    }
  );
