import { connect } from '@ardoq/rxbeach';
import { CloudProviderRegion } from '@ardoq/api-types/integrations';
import styled from 'styled-components';
import { Stack } from '@ardoq/layout';
import { s32 } from '@ardoq/design-tokens';
import { PopoverContentType } from '@ardoq/popovers';
import { ErrorNotification } from '@ardoq/status-ui';
import { Maybe } from '@ardoq/common-helpers';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { SelectConnections } from 'integrations/common/components/selectors/SelectConnections';
import { SelectConfigs } from 'integrations/common/components/selectors/SelectConfigs';
import ResourceTypeSelector from 'integrations/aws/containers/resourceTypeSelector/ResourceTypeSelector';
import { SelectRegions } from 'integrations/cloudProviders/components/selectors/SelectRegions';
import { SelectVpcs } from 'integrations/aws/containers/selectVpcs/SelectVpcs';
import { Connection } from '@ardoq/api-types/integrations';
import { viewModel$ } from './viewModel$';
import { Island } from '@ardoq/page-layout';
import { AutoFocus } from 'integrations/common/components/AutoFocus';

type SelectDataProps = {
  connections: Connection[];
  selectedConnectionIds: string[];
  transferConfigs: SavedTransferConfig[];
  selectedTransferConfigId: Maybe<string>;
  regions: CloudProviderRegion[];
  selectedRegionIds: string[];
  onTransferConfigSelect: (configId: Maybe<SavedTransferConfig['_id']>) => void;
  onClearConfiguration: () => void;
  onConnectionsSelect: (connectionIds: string[]) => void;
  onRegionsSelect: (regions: string[]) => void;
  selectConnectionPopoverContent: PopoverContentType;
  selectConnectionHelperText: string;
  selectConnectionPlaceHolder: string;
  selectConfigPopoverContent: PopoverContentType;
  selectConfigHelperText: string;
  errorMessage: Maybe<string>;
  selectRegionsLabel: string;
  selectRegionsHelperText: string;
};

const SelectDataComponent = ({
  connections,
  selectedConnectionIds,
  transferConfigs,
  selectedTransferConfigId,
  regions,
  selectedRegionIds,
  onTransferConfigSelect,
  onClearConfiguration,
  onConnectionsSelect,
  onRegionsSelect,
  selectConnectionHelperText,
  selectConnectionPlaceHolder,
  selectConfigHelperText,
  selectConnectionPopoverContent,
  selectConfigPopoverContent,
  errorMessage,
  selectRegionsLabel,
  selectRegionsHelperText,
}: SelectDataProps) => {
  return (
    <Container>
      <SelectConfigs
        transferConfigs={transferConfigs}
        selectedConfigId={selectedTransferConfigId}
        onSelect={onTransferConfigSelect}
        onClearConfiguration={onClearConfiguration}
        selectConfigHelperText={selectConfigHelperText}
        popoverContent={selectConfigPopoverContent}
      />
      <IslandContainer>
        <Island maxWidth="100%" footerJustify="end">
          <Stack gap="xlarge">
            {errorMessage && (
              <AutoFocus>
                <ErrorNotification>
                  <span>{errorMessage}</span>
                </ErrorNotification>
              </AutoFocus>
            )}
            <SelectConnections
              connections={connections}
              selectedConnectionIds={selectedConnectionIds}
              onSelect={onConnectionsSelect}
              selectConnectionHelperText={selectConnectionHelperText}
              popoverContent={selectConnectionPopoverContent}
              selectConnectionPlaceHolder={selectConnectionPlaceHolder}
              isMultiSelect={true}
            />
            <SelectRegions
              regions={regions.map(({ regionName }) => ({
                label: regionName,
                value: regionName,
              }))}
              onSelect={onRegionsSelect}
              selectedRegionIds={selectedRegionIds}
              isDisabled={selectedConnectionIds.length === 0}
              label={selectRegionsLabel}
              helperText={selectRegionsHelperText}
            />
            <SelectVpcs />
            <ResourceTypeSelector />
          </Stack>
        </Island>
      </IslandContainer>
    </Container>
  );
};

const IslandContainer = styled.div`
  padding: ${s32};
  width: 100%;
`;

const Container = styled.div`
  overflow: auto;
  padding-bottom: ${s32};
`;

// Marked for modification
export const SelectData = connect(SelectDataComponent, viewModel$);
