import {
  APIEntityType,
  ArdoqId,
  AllSupportedAPIAttributes,
  AllSupportedEntityTypes,
  APIOrganizationUser,
} from '@ardoq/api-types';
import {
  DataSourceItem,
  EntityMergeViewModel,
} from 'components/EntityMerge/types';
import { COLUMN_MAX_WIDTH } from 'components/DiffMergeTable/styleVariables';
import {
  BaseTr,
  BorderlessThead,
  DisabledRadio,
  EntityMergeTh,
  FieldName,
  MetaInfoLabel,
  MetaInfoTr,
  MetaInfoTrWithPadding,
  MetaInfoValue,
  PropertyValue,
  Radio,
  RadioButtonContainer,
  StyledTd,
  SubheaderTr,
} from 'components/DiffMergeTable/atoms';
import { IconName } from '@ardoq/icons';
import { DatasourceTable } from '@ardoq/table';
import { DiffTableRowType } from 'components/DiffMergeTable/types';
import { capitalize } from 'utils/stringUtils';
import { setEntityMergeState } from 'components/EntityMerge/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { withPlainTextPopover } from '@ardoq/popovers';
import styled from 'styled-components';
import {
  EnhancedScopeDataWithBranchName,
  Graphics,
  RenderOptions,
  Renderer,
  getFieldLabel,
} from '@ardoq/renderers';

const HeaderLabel = styled(FieldName)`
  text-transform: none;
  padding: 0;
`;

const HiddenDiv = styled.div`
  /* Using this instead of null to avoid bug where row height is too big */
  display: none;
`;

const getRadioButtonRenderer =
  ({
    entityId,
    showEmptyPage,
    isDisabledParent,
  }: {
    entityId: ArdoqId;
    isDisabledParent?: boolean;
    showEmptyPage?: boolean;
  }) =>
  (_: string, { status, rowType, fieldName }: DataSourceItem) =>
    !showEmptyPage && rowType !== DiffTableRowType.META_ROW ? (
      <RadioButtonContainer>
        {fieldName === 'parent' && isDisabledParent ? (
          <DisabledRadio
            className="no-underline"
            {...withPlainTextPopover(
              'This parent cannot be selected because it will break the component hierarchy'
            )}
            iconName={IconName.RADIO_BUTTON_UNCHECKED}
          />
        ) : (
          <Radio
            iconName={
              status === entityId
                ? IconName.RADIO_BUTTON_CHECKED
                : IconName.RADIO_BUTTON_UNCHECKED
            }
            data-click-id="merge-entity-radio-button"
          />
        )}
      </RadioButtonContainer>
    ) : (
      <HiddenDiv />
    );

const getHeaderRender =
  (
    entity: AllSupportedAPIAttributes,
    entityType: AllSupportedEntityTypes,
    index: number
  ) =>
  () => {
    const value =
      entityType === APIEntityType.COMPONENT
        ? entity.name
        : `Reference ${index}`;
    return <HeaderLabel>{value}</HeaderLabel>;
  };

const getFieldLabelRenderer =
  (
    enhancedScopeData: EnhancedScopeDataWithBranchName,
    entityType: AllSupportedEntityTypes
  ) =>
  (_: string, { rowType, fieldName }: DataSourceItem) => {
    const FontAndColor =
      rowType === DiffTableRowType.META_ROW ? MetaInfoLabel : FieldName;

    return (
      <FontAndColor>
        {rowType === DiffTableRowType.SUB_HEADER_ROW
          ? capitalize(fieldName)
          : getFieldLabel({ fieldName, enhancedScopeData, entityType })}
      </FontAndColor>
    );
  };

const getEntityValueRenderer =
  (
    entity: AllSupportedAPIAttributes,
    entityType: AllSupportedEntityTypes,
    enhancedScopeData: EnhancedScopeDataWithBranchName,
    graphics: Graphics,
    users: Record<ArdoqId, APIOrganizationUser>,
    options: RenderOptions
  ) =>
  (_: string, { rowType, fieldName }: DataSourceItem) => {
    const FontAndColor =
      rowType === DiffTableRowType.META_ROW ? MetaInfoValue : PropertyValue;

    if (rowType === DiffTableRowType.SUB_HEADER_ROW) {
      return null;
    }

    return (
      <FontAndColor>
        <Renderer
          entityId={entity._id}
          entityType={entityType}
          fieldName={fieldName}
          enhancedScopeData={enhancedScopeData}
          graphics={graphics}
          users={users}
          options={options}
        />
      </FontAndColor>
    );
  };

const getRowComponent = ({ rowType, fieldName }: DataSourceItem) => {
  if (rowType === DiffTableRowType.SUB_HEADER_ROW) return SubheaderTr;
  else if (rowType === DiffTableRowType.META_ROW) {
    return fieldName === 'lastUpdated' ? MetaInfoTrWithPadding : MetaInfoTr;
  }
  return BaseTr;
};

const getOnRadioButtonClick =
  (id: ArdoqId, isDisabledParent?: boolean) =>
  ({ index, fieldName }: DataSourceItem) => {
    if (!(fieldName === 'parent' && isDisabledParent)) {
      dispatchAction(setEntityMergeState({ id, index }));
    }
  };

type EntityMergeTableProps = {
  [P in keyof Omit<EntityMergeViewModel, 'isLoading'>]: NonNullable<
    EntityMergeViewModel[P]
  >;
} & { showEmptyPage: boolean };

const EntityMergeTable = ({
  graphics,
  options,
  users,
  entities,
  enhancedScopeData,
  entityType,
  dataSource,
  entitiesWithInvalidParent,
  showEmptyPage,
}: EntityMergeTableProps) => {
  const [headerRow, ...bodyRows] = dataSource;
  const columns = [
    {
      headerStyle: { width: COLUMN_MAX_WIDTH },
      valueRender: getFieldLabelRenderer(enhancedScopeData, entityType),
      headerRender: () => <HiddenDiv />,
    },
    ...entities.flatMap((entity, i) => [
      {
        headerStyle: { width: 64 },
        headerRender: () =>
          getRadioButtonRenderer({ entityId: entity._id, showEmptyPage })(
            '',
            headerRow
          ),
        onHeaderClick: () => getOnRadioButtonClick(entity._id)(headerRow),
        valueRender: getRadioButtonRenderer({
          entityId: entity._id,
          isDisabledParent: entitiesWithInvalidParent.has(entity._id),
        }),
        onCellClick: getOnRadioButtonClick(
          entity._id,
          entitiesWithInvalidParent.has(entity._id)
        ),
      },
      {
        headerStyle: { width: COLUMN_MAX_WIDTH },
        headerRender: getHeaderRender(entity, entityType, i),
        valueRender: getEntityValueRenderer(
          entity,
          entityType,
          enhancedScopeData,
          graphics,
          users,
          options
        ),
      },
    ]),
  ];

  return (
    <DatasourceTable
      components={{ Th: EntityMergeTh, Td: StyledTd, Thead: BorderlessThead }}
      dataSource={bodyRows}
      columns={columns}
      scrollableSectionHeight={showEmptyPage ? undefined : '100%'}
      getRowComponent={getRowComponent}
    />
  );
};

export default EntityMergeTable;
