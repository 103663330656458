import { useState } from 'react';
import { FormWrapper, TextInput } from '@ardoq/forms';
import { TagGroup, Tag, StatusType } from '@ardoq/status-ui';
import { inviteNewUserOperations } from '../inviteNewUserOperations';
import { IconName } from '@ardoq/icons';
import styled from 'styled-components';

const INVALID_EMAIL_INPUT_ERROR_MESSAGE = 'Please enter valid email addresses.';

interface EmailsInputFieldProps {
  emails: string[];
  onEmailsChange: (newEmails: string[]) => void;
  isValid: boolean;
}

export const EmailsInputField = ({
  emails,
  onEmailsChange,
  isValid,
}: EmailsInputFieldProps) => {
  const [inputValue, setInputValue] = useState('');

  const addNewEmails = () => {
    if (!inputValue) return;

    const cleanedEmails = inviteNewUserOperations.parseEmails(inputValue);

    onEmailsChange([...emails, ...cleanedEmails]);
    setInputValue('');
  };

  const inputErrorMessage =
    !isValid && emails.length > 0
      ? INVALID_EMAIL_INPUT_ERROR_MESSAGE
      : undefined;

  return (
    <StyledFormWrapper>
      <TextInput
        label="Emails"
        helperText="Enter the emails of the users you want to invite. Separate each email with a comma for bulk invitations."
        value={inputValue}
        onValueChange={setInputValue}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            addNewEmails();
          }
        }}
        placeholder="Enter email address"
        errorMessage={inputErrorMessage}
        type="email"
        autoFocus
        rightIconName={IconName.ADD}
        rightIconOnClick={addNewEmails}
        onBlur={addNewEmails}
      />
      <TagGroup>
        {emails.map((email, index) => (
          <Tag
            onCloseClick={() =>
              onEmailsChange(
                inviteNewUserOperations.removeEmailAtIndex(emails, index)
              )
            }
            statusType={
              !inviteNewUserOperations.isEmailValid(email)
                ? StatusType.ERROR
                : undefined
            }
            key={`${email}${index}`}
          >
            {email}
          </Tag>
        ))}
      </TagGroup>
    </StyledFormWrapper>
  );
};

const StyledFormWrapper = styled(FormWrapper)`
  min-height: 172px;
`;
