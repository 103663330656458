import currentUser$ from 'streams/currentUser/currentUser$';
import { derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { ArdoqId } from '@ardoq/api-types';
import surveyAdmin$ from 'surveyAdmin/streams/surveyAdmin$';
import {
  SurveyOverviewSearchParams,
  SurveyAdminMode,
} from 'surveyAdmin/navigation/types';
import { Features, hasFeature } from '@ardoq/features';
import { surveyAccessControlInterface } from 'resourcePermissions/accessControlHelpers/surveys';
import { OrgUsersStreamShape } from 'streams/orgUsers/types';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { SurveyValidation } from 'surveyAdmin/types';

export type SurveyOrgAndUserStreamProps = {
  currentUserId: ArdoqId;
  currentUserEmail: string | null;
  orgLabel: string;
  surveyValidation: Record<ArdoqId, SurveyValidation>;
  surveyOverviewSearchParams?: SurveyOverviewSearchParams;
  mode: SurveyAdminMode;
  users: OrgUsersStreamShape['users'];
  isLoadingPendingApprovals: boolean;
  isLoadingSurveyValidation: boolean;
  hasSurveyResponseApprovalsFeature: boolean;
  canCreateSurvey: boolean;
};

const surveyOrgAndUserDetails$ = derivedStream(
  'surveyOrgAndUserDetails$',
  surveyAdmin$,
  orgUsers$,
  currentUser$
).pipe(
  map(
    ([
      {
        surveyOverviewSearchParams,
        mode,
        surveyValidation,
        surveyValidationFetchingStatus,
        pendingApprovalFetchingStatus,
      },
      { users },
      currentUser,
    ]) => {
      return {
        users,
        currentUserId: currentUser._id,
        currentUserEmail: currentUser.email,
        orgLabel: currentUser.organization?.label,
        surveyOverviewSearchParams,
        surveyValidation,
        mode,
        isLoadingPendingApprovals: pendingApprovalFetchingStatus === 'loading',
        isLoadingSurveyValidation: surveyValidationFetchingStatus === 'loading',
        hasSurveyResponseApprovalsFeature: hasFeature(
          Features.SURVEYS_CHANGE_APPROVAL_V2
        ),
        canCreateSurvey: surveyAccessControlInterface.canCreateSurvey(),
      };
    }
  )
);

export default surveyOrgAndUserDetails$;
