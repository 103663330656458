import { FlexBox } from '@ardoq/layout';
import styled from 'styled-components';
import { ButtonType, GhostButton } from '@ardoq/button';
import {
  DropdownOptionType,
  SplitButtonWithDropdown,
} from '@ardoq/dropdown-menu';
import { colors } from '@ardoq/design-tokens';
import { confirm, ModalSize } from '@ardoq/modal';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { text2 } from '@ardoq/typography';
import { Features, hasFeature } from '@ardoq/features';
import { PageHeader, PageTopbar } from '@ardoq/page-layout';

const PageHeaderWrapper = styled(PageHeader)`
  align-items: center;
`;

const RestartMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${text2};
  height: 100%;
  padding: 16px 24px 24px 24px;
`;

type ImportHeaderProps = {
  title: string;
  canRestart: boolean;
  isSavingDisabled: boolean;
  onRestart: () => void;
  onSave: () => void;
  onSaveAs: () => void;
  onStartTransferConfigsModal: () => void;
  disabledConfigurationActions?: boolean;
  transferDirection: TransferDirection;
};

export const FlowHeader = ({
  title,
  canRestart,
  isSavingDisabled,
  onRestart,
  onStartTransferConfigsModal,
  disabledConfigurationActions,
  transferDirection,
  onSave,
  onSaveAs,
}: ImportHeaderProps) => {
  const isImport = transferDirection === TransferDirection.IMPORT;
  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);
  const handleRestart = async () => {
    const confirmed = await confirm({
      title: `Restart ${isImport ? 'import' : 'export'}?`,
      body: (
        <RestartMessage>
          {disabledConfigurationActions
            ? 'Your schedule will be lost if you haven’t saved it.'
            : 'Your configurations will be lost if you haven’t saved them.'}
        </RestartMessage>
      ),
      confirmButtonTitle: `Restart ${isImport ? 'import' : 'export'}`,
      cancelButtonTitle: 'Cancel',
      modalSize: ModalSize.XS,
    });

    if (confirmed) {
      onRestart();
    }
  };
  return hasNewJourneyFeature ? (
    <PageTopbar
      primaryContent={title}
      secondaryContent="Import and integrations"
      primaryButton={
        !disabledConfigurationActions && (
          <SplitButtonWithDropdown
            disablePortal
            alignMenuTo="right"
            isSplitButtonDisabled={isSavingDisabled}
            onSplitButtonClick={onSave}
            buttonType={ButtonType.SECONDARY}
            iconStyle={{ color: colors.grey25 }}
            options={[
              {
                label: 'Save as a copy',
                type: DropdownOptionType.BUTTON_OPTION,
                isDisabled: isSavingDisabled,
                onClick: onSaveAs,
              },
              {
                label: 'Manage configurations',
                type: DropdownOptionType.BUTTON_OPTION,
                onClick: onStartTransferConfigsModal,
              },
            ]}
          >
            Save configuration
          </SplitButtonWithDropdown>
        )
      }
      secondaryButton={
        <GhostButton onClick={handleRestart} isDisabled={!canRestart}>
          Restart
        </GhostButton>
      }
    />
  ) : (
    <PageHeaderWrapper
      title={title}
      rightContent={
        <FlexBox gap="small">
          <GhostButton onClick={handleRestart} isDisabled={!canRestart}>
            Restart
          </GhostButton>
          {!disabledConfigurationActions && (
            <SplitButtonWithDropdown
              disablePortal
              alignMenuTo="right"
              isSplitButtonDisabled={isSavingDisabled}
              onSplitButtonClick={onSave}
              buttonType={ButtonType.SECONDARY}
              iconStyle={{ color: colors.grey25 }}
              options={[
                {
                  label: 'Save as a copy',
                  type: DropdownOptionType.BUTTON_OPTION,
                  isDisabled: isSavingDisabled,
                  onClick: onSaveAs,
                },
                {
                  label: 'Manage configurations',
                  type: DropdownOptionType.BUTTON_OPTION,
                  onClick: onStartTransferConfigsModal,
                },
              ]}
            >
              Save configuration
            </SplitButtonWithDropdown>
          )}
        </FlexBox>
      }
    />
  );
};
