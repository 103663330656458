import { Field, NumberInput } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import {
  GriddedTableRow,
  RulesSectionContainer,
  TableHeaderLabel,
} from './atoms';
import reports$ from 'streams/reports/reports$';
import {
  ArdoqId,
  BroadcastAggregate,
  ReportRuleOperator,
  Report,
} from '@ardoq/api-types';
import { combineLatest, map } from 'rxjs';
import {
  broadcastAggregatesWithLabel,
  reportRuleOperatorsWithLabel,
} from './types';
import reportRulesSection$ from './reportRulesSection$';
import {
  selectAggregate,
  selectReportColumn,
  selectRuleOperator,
  selectRuleValue,
} from './actions';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import validation$ from 'broadcasts/broadcastBuilder/validation$';
import {
  isValidAggregateForColumnType,
  isValidBroadcastReportColumn,
} from './utils';

const ReportRulesTableHeader = () => (
  <GriddedTableRow $hideBorder={true}>
    <TableHeaderLabel>Column</TableHeaderLabel>
    <TableHeaderLabel>Aggregate</TableHeaderLabel>
    <TableHeaderLabel>Condition</TableHeaderLabel>
    <TableHeaderLabel>Value</TableHeaderLabel>
  </GriddedTableRow>
);

interface ReportRulesSectionProps {
  readonly selectedReport: ArdoqId | null;
  readonly reportsById: Record<ArdoqId, Report>;
  readonly selectedColumn: string | null;
  readonly selectedAggregate: BroadcastAggregate | null;
  readonly selectedOperator: ReportRuleOperator | null;
  readonly selectedValue: number | null;
  readonly showRequiredFields: boolean;
}

const ReportRulesSection = ({
  selectedReport,
  reportsById,
  selectedColumn,
  selectedAggregate,
  selectedOperator,
  selectedValue,
  showRequiredFields,
}: ReportRulesSectionProps) => {
  const validReportColumns = selectedReport
    ? (reportsById[selectedReport]?.columns.filter(
        isValidBroadcastReportColumn
      ) ?? [])
    : [];

  return (
    <RulesSectionContainer>
      <Field
        label="Set up conditions to trigger the broadcast"
        helperText="Broadcasts are only triggered when the condition changes from unmet to
          met."
      />
      <ReportRulesTableHeader />
      <GriddedTableRow>
        <Select
          dataTestId={'column-report-broadcast'}
          placeholder="Select column"
          value={selectedColumn}
          options={validReportColumns.map(col => ({
            label: col.label,
            value: col.key,
          }))}
          onValueChange={value =>
            value &&
            dispatchAction(
              selectReportColumn(
                validReportColumns.find(({ key }) => key === value)!
              )
            )
          }
          errorMessage={
            selectedColumn === null && showRequiredFields
              ? 'Select a column.'
              : undefined
          }
        />
        <Select
          dataTestId={'aggregate-report-broadcast'}
          placeholder="Select aggregate"
          value={selectedAggregate}
          options={Object.entries(broadcastAggregatesWithLabel).map(
            ([value, label]) => ({
              value: value as BroadcastAggregate,
              label,
              isDisabled: !isValidAggregateForColumnType(
                validReportColumns.find(
                  reportColumn => reportColumn.key === selectedColumn
                ),
                value as BroadcastAggregate
              ),
            })
          )}
          onValueChange={value =>
            value && dispatchAction(selectAggregate(value))
          }
          errorMessage={
            selectedAggregate === null && showRequiredFields
              ? 'Select an aggregate.'
              : undefined
          }
        />
        <Select
          dataTestId={'condition-report-broadcast'}
          value={selectedOperator}
          options={Object.entries(reportRuleOperatorsWithLabel).map(
            ([value, label]) => ({
              value: value as ReportRuleOperator,
              label,
            })
          )}
          onValueChange={value =>
            value && dispatchAction(selectRuleOperator(value))
          }
          errorMessage={
            selectedOperator === null && showRequiredFields
              ? 'Select a condition.'
              : undefined
          }
        />
        <NumberInput
          dataTestId={'value-report-broadcast'}
          placeholder="Enter value"
          value={selectedValue!}
          onValueChange={value => dispatchAction(selectRuleValue(value))}
          errorMessage={
            selectedValue === null && showRequiredFields
              ? 'Select a value.'
              : undefined
          }
        />
      </GriddedTableRow>
    </RulesSectionContainer>
  );
};

export default connect(
  ReportRulesSection,
  combineLatest([reports$, reportRulesSection$, validation$]).pipe(
    map(
      ([
        { byId: reportsById },
        {
          columnName: selectedColumn,
          aggregate: selectedAggregate,
          threshold: selectedValue,
          predicate: selectedOperator,
        },
        { showRequiredFields },
      ]) => ({
        showRequiredFields,
        reportsById,
        selectedColumn,
        selectedAggregate,
        selectedValue,
        selectedOperator,
      })
    )
  )
);
