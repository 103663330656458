import { AssetType } from '@ardoq/api-types';
import { capitalize } from 'lodash';

export const assetTypesLabels: Record<AssetType, string> = {
  [AssetType.TRAVERSAL]: 'Viewpoint',
  [AssetType.FOLDER]: capitalize(AssetType.FOLDER),
  [AssetType.WORKSPACE]: capitalize(AssetType.WORKSPACE),
  [AssetType.PRESENTATION]: capitalize(AssetType.PRESENTATION),
  [AssetType.SURVEY]: capitalize(AssetType.SURVEY),
  [AssetType.METAMODEL]: capitalize(AssetType.METAMODEL),
  [AssetType.SCENARIO]: capitalize(AssetType.SCENARIO),
  [AssetType.REPORT]: capitalize(AssetType.REPORT),
  [AssetType.DASHBOARD]: capitalize(AssetType.DASHBOARD),
  [AssetType.BROADCAST]: capitalize(AssetType.BROADCAST),
  [AssetType.VIEWPOINT]: 'Discover viewpoint',
  [AssetType.BOOKMARK]: capitalize(AssetType.BOOKMARK),
};
