import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { Features, hasFeature } from '@ardoq/features';
import { useEffectOnce } from '@ardoq/hooks';
import { logError } from '@ardoq/logging';
import { PageBody, PageHeader, PageWrapper } from '@ardoq/page-layout';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { DoqLoader } from '@ardoq/snowflakes';
import { Tab, TabsGroup } from '@ardoq/tabs';
import BundleManagement from 'admin/bundles/BundleManagement';
import EntityGroupManagement from 'admin/entityGroup/EntityGroupManagement';
import FeatureSettings from 'admin/featureSettings/FeatureSettings';
import manageOrganizationNavigation$ from 'admin/manageOrganization/navigation/manageOrganizationNavigation$';
import {
  ManageOrganizationRoute,
  OrgTabs,
} from 'admin/manageOrganization/navigation/types';
import MigrationChecklist from 'admin/migrationChecklist/MigrationChecklist';
import OrgSettings from 'admin/orgSettings/OrgSettings';
import Webhooks from 'admin/orgSettings/WebhookManagement';
import { initWebhooks } from 'admin/orgSettings/webhook$';
import UseCaseManagement from 'admin/useCase/UseCaseManagement';
import { WorkspaceOverview } from 'admin/workspaceOverview/workspaceOverviewManagement$';
import ModuleContainer from 'appContainer/ModuleContainer';
import MainToolbar from 'menus/topbar/MainToolbar';
import { organizationInterface } from 'modelInterface/organization/organizationInterface';
import currentUser from 'models/currentUser';
import { useState } from 'react';
import { navigateToManageOrganization } from 'router/navigationActions';
import { map } from 'rxjs';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import { trackEvent } from 'tracking/tracking';
import Navbar from 'views/navbar/Navbar';
import { openHome } from '../components/AppMainSidebar/utils';

const ManageOrganizationContainer = ({
  manageOrgTab,
}: ManageOrganizationRoute) => {
  const [isLoading, setIsLoading] = useState(true);
  const isOrgAdmin = currentUser.isOrgAdmin();

  const showWebhooks = hasFeature(Features.WEBHOOKS_ENABLE);
  const showBundles = hasFeature(Features.BUNDLE_MANAGEMENT);
  const showEntityGroups = hasFeature(
    Features.ENTITY_GROUP_AND_USE_CASE_MANAGEMENT
  );
  const showUseCases = hasFeature(
    Features.ENTITY_GROUP_AND_USE_CASE_MANAGEMENT
  );
  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);
  const showMigrationChecklist = isOrgAdmin;
  const showWorkspaceOverview = isOrgAdmin;
  const currentUserIsOrgAdmin = isOrgAdmin;

  useEffectOnce(() => {
    if (!currentUserIsOrgAdmin) return;

    organizationInterface.fetchCurrentOrganization().then(() => {
      setIsLoading(false);
    });

    if (showWebhooks) {
      initWebhooks();
    }
  });

  const tabs = [
    {
      label: 'Settings',
      tabId: OrgTabs.SETTINGS,
      content: <OrgSettings />,
    },
    {
      label: 'Features',
      tabId: OrgTabs.FEATURE_SETTINGS,
      content: <FeatureSettings />,
    },
    ...(showMigrationChecklist
      ? [
          {
            label: 'Migration checklist',
            tabId: OrgTabs.MIGRATION_CHECKLIST,
            content: <MigrationChecklist />,
          },
        ]
      : []),
    ...(showWebhooks
      ? [
          {
            label: 'Webhooks',
            tabId: OrgTabs.WEBHOOKS,
            content: <Webhooks />,
          },
        ]
      : []),
    ...(showBundles
      ? [
          {
            label: 'Bundles',
            tabId: OrgTabs.BUNDLES,
            content: <BundleManagement />,
          },
        ]
      : []),
    ...(showEntityGroups
      ? [
          {
            label: 'Entity groups',
            tabId: OrgTabs.ENTITY_GROUPS,
            content: <EntityGroupManagement />,
          },
        ]
      : []),
    ...(showUseCases
      ? [
          {
            label: 'Use cases',
            tabId: OrgTabs.USE_CASES,
            content: <UseCaseManagement />,
          },
        ]
      : []),
    ...(showWorkspaceOverview
      ? [
          {
            label: 'Workspaces overview',
            tabId: OrgTabs.WORKSPACE_OVERVIEW,
            content: <WorkspaceOverview />,
          },
        ]
      : []),
  ];

  const selectedTab = tabs.find(({ tabId }) => tabId === manageOrgTab);

  return (
    <ModuleContainer $overflow="hidden">
      <ErrorBoundary logError={logError}>
        <PageWrapper>
          {hasNewJourneyFeature ? (
            <Navbar
              primaryContent="Organization settings"
              secondaryContent="Preferences"
              toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
            />
          ) : (
            <PageHeader title="Organization settings" />
          )}
          {currentUserIsOrgAdmin ? (
            <>
              {isLoading ? (
                <DoqLoader />
              ) : (
                <>
                  <PageHeader skipVerticalPadding>
                    <TabsGroup
                      activeTabId={manageOrgTab}
                      onTabChange={tabId => {
                        trackEvent(
                          APPSEC_EVENTS.CLICKED_MANAGE_ORGANIZATION_TAB,
                          {
                            to: tabId,
                            from: manageOrgTab,
                            namespace: 'organization settings',
                          }
                        );
                        dispatchAction(
                          navigateToManageOrganization({
                            manageOrgTab: tabId as OrgTabs,
                          })
                        );
                      }}
                    >
                      {tabs.map((tab, i) => (
                        <Tab key={i} tabId={tab.tabId} label={tab.label} />
                      ))}
                    </TabsGroup>
                  </PageHeader>
                  {selectedTab && (
                    <PageBody alignIslandsToLeft>
                      {selectedTab.content}
                    </PageBody>
                  )}
                </>
              )}
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                justifyContent: 'center',
              }}
            >
              <DoqWithSpeechBubble
                layout={DoqLayout.PAGE}
                doqType={DoqType.ALARM}
                buttonLabel="Take me back"
                onButtonClick={openHome}
                title="Access denied"
                message="You do not have access to this page. If you believe this is a mistake please contact an admin or customer support."
              />
            </div>
          )}
        </PageWrapper>
      </ErrorBoundary>
    </ModuleContainer>
  );
};

export default connect(
  ManageOrganizationContainer,
  manageOrganizationNavigation$.pipe(
    map(({ manageOrgTab }) => ({
      manageOrgTab,
    }))
  )
);
