import {
  DataPresentationSectionCommands,
  previewSearchResults,
  reportBuilderOperations,
  ReportTemplate,
  UpdatedColumnNames,
  RESETED_COLUMN_CUSTOM_NAME,
  EMPTY_COLUMN_CUSTOM_NAME,
} from '@ardoq/report-builder';
import { ExcludeFalsy, Maybe } from '@ardoq/common-helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  reportBuilderSortingWasChanged,
  resetAllColumnsNames,
} from '../actions';
import {
  APIOrganizationUser,
  ArdoqId,
  SortOrder,
  ReportColumn,
  APIAttachment,
  APISearchAggregatesResponse,
} from '@ardoq/api-types';
import { addSectionToScrollArrayIfError, setReportValue } from './utils';
import { Locale } from '@ardoq/locale';
import {
  EnhancedSearchResponse,
  getColumnCustomLabel,
  isColumnWithCustomLabel,
} from '@ardoq/report-reader';

const getShowReportPreview =
  ({
    reportTemplate,
    searchAggregatesResults,
    searchResults,
    currentUserLocale,
    users,
    attachments,
  }: {
    reportTemplate: ReportTemplate;
    searchAggregatesResults: Maybe<APISearchAggregatesResponse>;
    searchResults: Maybe<EnhancedSearchResponse>;
    currentUserLocale: Locale;
    users: Record<ArdoqId, APIOrganizationUser>;
    attachments: Record<ArdoqId, APIAttachment>;
  }) =>
  () => {
    if (!searchResults) {
      return;
    }

    previewSearchResults({
      title: 'Preview Report',
      columns:
        reportBuilderOperations.getColumnsWithUpdatedNames(reportTemplate),
      selectedAggregate: reportTemplate.selectedAggregate,
      currentUserLocale,
      users,
      attachments,
      searchAggregatesResults,
      searchResults,
      showAggregate: true,
      sort: reportBuilderOperations.getSortKeyAndOrder(reportTemplate),
    });
  };

const getColumnOriginalCustomName = (
  reportTemplate: ReportTemplate,
  column: ReportColumn
) => {
  const originalColumn = reportTemplate.columns.find(
    c => c.key === column.key && c.type === column.type
  );
  const originalCustomName =
    originalColumn && getColumnCustomLabel(originalColumn);

  return originalCustomName;
};

type GetDataPresentationSectionCommandArgs = {
  searchAggregatesResults: Maybe<APISearchAggregatesResponse>;
  searchResults: Maybe<EnhancedSearchResponse>;
  reportTemplate: ReportTemplate;
  currentUserLocale: Locale;
  users: Record<ArdoqId, APIOrganizationUser>;
  attachments: Record<ArdoqId, APIAttachment>;
};
export const getDataPresentationSectionCommands = ({
  searchAggregatesResults,
  searchResults,
  reportTemplate,
  users,
  attachments,
  currentUserLocale,
}: GetDataPresentationSectionCommandArgs): DataPresentationSectionCommands => ({
  selectColumn: columns => {
    if (!searchResults) return;

    const newColumns =
      columns
        ?.map(({ value }) => {
          const searchResultColumn = searchResults.columns[value];
          if (searchResultColumn) {
            const reportTemplateColumn = reportTemplate.columns.find(
              reportColumn =>
                reportColumn.key === searchResultColumn.key &&
                reportColumn.type === searchResultColumn.type
            );

            if (reportTemplateColumn) {
              // columns from search results don't contain sort information
              return { ...reportTemplateColumn };
            }
            return searchResultColumn;
          }
          return null;
        })
        .filter(ExcludeFalsy) ?? [];

    setReportValue('columns', newColumns);
    setReportValue(
      'updatedColumnNames',
      reportBuilderOperations.getUpdatedColumnNamesThatAreValidForTheSelectedColumns(
        newColumns,
        reportTemplate.updatedColumnNames
      )
    );
  },
  setAllColumns: () => {
    if (!searchResults) return;

    const allColumns = [
      ...reportTemplate.columns,
      ...searchResults.columns.filter(
        searchResultsColumn =>
          !reportTemplate.columns.some(
            reportColumn =>
              reportColumn.key === searchResultsColumn.key &&
              reportColumn.type === searchResultsColumn.type
          )
      ),
    ];
    setReportValue('columns', allColumns);
  },
  setSortKey: columnKey => {
    dispatchAction(
      reportBuilderSortingWasChanged({
        order: SortOrder.ASC,
        columnKey,
      })
    );
  },
  setSortOrder: order => {
    const sort = reportBuilderOperations.getSortKeyAndOrder(reportTemplate);
    dispatchAction(reportBuilderSortingWasChanged({ ...sort, order }));
  },
  setSelectedAggregate: selectedAggregate =>
    setReportValue('selectedAggregate', selectedAggregate),
  showReportPreview: getShowReportPreview({
    reportTemplate,
    searchAggregatesResults,
    searchResults,
    currentUserLocale,
    users,
    attachments,
  }),
  addSectionToScrollArrayIfError,
  updateColumnName: (column: ReportColumn, customName: string) => {
    setReportValue('updatedColumnNames', {
      ...reportTemplate.updatedColumnNames,
      [reportBuilderOperations.getUpdatedColumnNameKey(column)]: customName,
    });
  },
  resetColumnName: (column: ReportColumn) => {
    const originalCustomName = getColumnOriginalCustomName(
      reportTemplate,
      column
    );
    const currentUpdatedColumnKey =
      reportBuilderOperations.getUpdatedColumnNameKey(column);

    let newUpdatedColumnNames = { ...reportTemplate.updatedColumnNames };
    if (originalCustomName) {
      // name is reset to be the original name, so include this name change in updatedColumnNames,
      newUpdatedColumnNames = {
        ...newUpdatedColumnNames,
        [currentUpdatedColumnKey]: RESETED_COLUMN_CUSTOM_NAME,
      };
    } else {
      // since this is a column without saved custom name, so reset means no name change actually
      delete newUpdatedColumnNames[currentUpdatedColumnKey];
    }
    setReportValue('updatedColumnNames', newUpdatedColumnNames);
  },
  addColumnToRename: (column: ReportColumn) => {
    const originalCustomName = getColumnOriginalCustomName(
      reportTemplate,
      column
    );
    const currentUpdatedColumnNameKey =
      reportBuilderOperations.getUpdatedColumnNameKey(column);

    let newUpdatedColumnNames = {
      ...reportTemplate.updatedColumnNames,
    };
    if (originalCustomName) {
      delete newUpdatedColumnNames[currentUpdatedColumnNameKey];
    } else {
      newUpdatedColumnNames = {
        ...newUpdatedColumnNames,
        [currentUpdatedColumnNameKey]: EMPTY_COLUMN_CUSTOM_NAME,
      };
    }
    setReportValue('updatedColumnNames', newUpdatedColumnNames);
  },
  addAllColumnsToRename: () => {
    const newUpdatedColumnNames =
      reportTemplate.columns.reduce<UpdatedColumnNames>((acc, column) => {
        const currentKey =
          reportBuilderOperations.getUpdatedColumnNameKey(column);
        if (reportTemplate.updatedColumnNames[currentKey]) {
          acc[currentKey] = reportTemplate.updatedColumnNames[currentKey];
        } else if (!isColumnWithCustomLabel(column)) {
          acc[currentKey] = EMPTY_COLUMN_CUSTOM_NAME;
        }
        return acc;
      }, {});
    setReportValue('updatedColumnNames', newUpdatedColumnNames);
  },
  resetAllColumnsNames: () => {
    dispatchAction(resetAllColumnsNames());
  },
});
