import { ButtonType, IconButton } from '@ardoq/button';
import { colors, s32, s8, space } from '@ardoq/design-tokens';
import { TextArea } from '@ardoq/forms';
import { IconName } from '@ardoq/icons';
import { bestPracticeAssistantOperations } from 'bestPracticeAssistant/bestPracticeAssistantOperations';
import styled from 'styled-components';

type ChatFooterProps = {
  input: string;
  onInputChange: (value: string) => void;
  sendMessage: () => void;
  sendMessageIsLoading: boolean;
  className?: string;
};

export const ChatFooter = ({
  input,
  onInputChange,
  sendMessage,
  sendMessageIsLoading,
  className,
}: ChatFooterProps) => {
  return (
    <Container className={className}>
      <MessageTextareaWrapper>
        <MessageTextarea
          value={input}
          onValueChange={onInputChange}
          onKeyDown={e => {
            const shiftOrControl = e.shiftKey || e.ctrlKey;
            if (e.key === 'Enter' && !shiftOrControl) {
              e.preventDefault();
              sendMessage();
            }
          }}
          placeholder="What would you like to know?"
          autoComplete="off"
          autoFocus
          onFocus={e => {
            // Toggle value to put cursor at end
            const value = e.target.value;
            e.target.value = '';
            e.target.value = value;
          }}
        />
      </MessageTextareaWrapper>
      <IconButton
        onClick={sendMessage}
        isLoading={sendMessageIsLoading}
        isDisabled={
          sendMessageIsLoading ||
          !bestPracticeAssistantOperations.validateInput(input)
        }
        iconName={IconName.ARROW_UPWARD}
        buttonType={ButtonType.BRAND}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${s8};
  max-height: 200px;
  border-top: 1px solid ${colors.grey80};
  padding: ${space.medium};
`;

const MessageTextareaWrapper = styled.div`
  flex-grow: 1;
`;

const MessageTextarea = styled(TextArea)`
  min-height: ${s32};
  max-height: 192px;
`;
