import { Progress, ProgressDetails } from './types';
import { actionCreatorWithIntegrationId } from 'integrations/common/utils/actionCreatorWithIntegrationId';

const NAMESPACE = '[integrations] PROGRESS';

export const setFetchProgress = actionCreatorWithIntegrationId<
  Omit<Progress, 'progress'> & { progress?: ProgressDetails }
>(`${NAMESPACE}_SET_FETCH_PROGRESS`);

export const resetFetchProgress = actionCreatorWithIntegrationId(
  `${NAMESPACE}_RESET_FETCH_PROGRESS_FAILURE`
);
