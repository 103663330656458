import { TrackingPlaceInApp } from 'tracking/types';
import { trackEvent } from 'tracking/tracking';
import { loadedGraph$ } from 'traversals/loadedGraph$';

const { RIGHT_PANE } = TrackingPlaceInApp;

const trackButtonClicked = (action: string) => {
  trackEvent(`${RIGHT_PANE} clicked ${action}`, {
    isViewpointMode: loadedGraph$.state.isViewpointMode,
  });
};

export const trackRightPaneClosed = () => {
  trackButtonClicked('close');
};

export const trackSaveComponentButtonClicked = () => {
  trackButtonClicked('save component');
};

export const trackCreateComponentButtonClicked = () => {
  trackButtonClicked('create component');
};

export const trackDeleteComponentButtonClicked = () => {
  trackButtonClicked('delete component');
};
