export const STRINGS = {
  NO_EDIT_ACCESS_DOQI: {
    TITLE: "You can't edit this Dashboard",
    MESSAGE:
      "You don't have permission to edit this Dashboard. Go to Dashboard overview to access a different Dashboard.",
    BUTTON_LABEL: 'Dashboard overview',
  },
  NO_EDIT_PRIVILEGE_POPOVER:
    "You don't have permission to edit this Dashboard. Contact your admin to request permission.",
  NO_ADD_TO_PRESENTATION_PRIVILEGE_POPOVER:
    "You don't have permission to add this Dashboard to a presentation. Contact your admin to request permission.",
  NO_ADD_TO_CURRENT_PRESENTATION_POPOVER:
    "You don't have permission to add this Dashboard to the current presentation. Contact the author to request permission.",
  NO_ACCESS_DOQI: {
    TITLE: "You can't access this Dashboard",
    MESSAGE:
      "You don't have permission to access this Dashboard. Go to Dashboard overview to access a different Dashboard.",
    BUTTON_LABEL: 'Dashboard overview',
  },
};

export const CANNOT_EDIT_POPOVER_ID = 'cannot-edit-dashboard';
export const CANNOT_ADD_TO_PRESENTATION_POPOVER_ID =
  'cannot-add-to-presentation';
export const CANNOT_ADD_TO_CURRENT_PRESENTATION_POPOVER_ID =
  'cannot-add-to-current-presentation';
export const METRICS_DSB_ID = '0000000000000000000001C5';
