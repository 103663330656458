import styled from 'styled-components';

const SmallerUL = styled.ul`
  padding-left: 1.5rem;
`;

type RoleSideNoteProps = {
  role: string;
};

const DefaultRoleDescription = ({ role }: RoleSideNoteProps) => {
  switch (role) {
    case 'admin':
      return (
        <div>
          <b>Admin:</b>
          <div>
            <p>Complete organizational control.</p>
            <strong>Can always:</strong>
            <SmallerUL>
              <li>Access all data</li>
              <li>Manage and view all groups</li>
              <li>Configure user privileges</li>
            </SmallerUL>
          </div>
        </div>
      );
    case 'contributor':
      return (
        <div>
          <b>Contributor:</b>
          <div>
            <p>Access shared tools and content.</p>
            <strong>Can be granted:</strong>
            <SmallerUL>
              <li>
                Access to Presentations, Surveys, Viewpoints, Dashboards and
                Reports.
              </li>
            </SmallerUL>
          </div>
        </div>
      );
    case 'reader':
      return (
        <div>
          <b>Reader:</b>
          <div>Can only view specific content (set by Admin).</div>
        </div>
      );
    case 'writer':
      return (
        <div>
          <b>Writer:</b>
          <div>
            <p>Workspace-specific access.</p>
            <strong>Can always:</strong>
            <SmallerUL>
              <li>View all groups.</li>
            </SmallerUL>
            <strong>Can be granted:</strong>
            <SmallerUL>
              <li>Read, Write, or Admin access to workspaces.</li>
            </SmallerUL>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default DefaultRoleDescription;
