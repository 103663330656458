import { streamReducer, dispatchAction, reducer } from '@ardoq/rxbeach';
import accessControlPageNavigation$ from 'admin/accessControl/navigation/accessControlPageNavigation$';
import privileges$ from 'privileges/privileges$';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import roles$ from 'roles/roles$';
import permissionGroup$ from 'admin/accessControl/PermissionGroups/streams/permissionGroups$';
import currentOrganization$ from 'streams/organizations/currentOrganization$';
import { withOnFirstSubscription } from 'streams/utils/streamUtils';
import { fetchCurrentOrganizationRequested } from 'streams/organizations/actions';
import { filter } from 'rxjs/operators';
import { accessControlPageStreamOperations } from './accessControlPageStreamOperations';
import { combineLatest } from 'rxjs';
import { AccessControlPageStream } from './types';
import { accessControlPageActions } from './actions';
import { AccessControlRoute } from 'admin/accessControl/navigation/types';
import { setAccessControlTab } from 'admin/accessControl/navigation/actions';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import ipAllowlist$ from 'admin/manageIPAllowlist/ipAllowlist$';
import SSOAttributeMapping$ from 'admin/ssoAttributeMapping/SSOAttributeMapping$';
import { serviceAccounts$ } from 'admin/serviceAccounts/serviceAccounts$';

const loadedCurrentOrganization$ = withOnFirstSubscription(
  currentOrganization$.pipe(filter(Boolean)),
  () => {
    dispatchAction(fetchCurrentOrganizationRequested());
  }
);

export const reducers = [
  streamReducer(
    accessControlPageNavigation$,
    accessControlPageStreamOperations.updateNavigationState
  ),
  streamReducer(
    loadedCurrentOrganization$,
    accessControlPageStreamOperations.updateOrganization
  ),
  streamReducer(
    privileges$,
    accessControlPageStreamOperations.updatePrivileges
  ),
  streamReducer(
    combineLatest({
      orgUsers: orgUsers$,
      rolesShape: roles$,
      permissionGroup: permissionGroup$,
      subdivisions: subdivisions$,
      ipAllowlist: ipAllowlist$,
      ssoAttributeMapping: SSOAttributeMapping$,
      serviceAccounts: serviceAccounts$,
    }),
    accessControlPageStreamOperations.updateUserPageViewModel
  ),
  reducer<AccessControlPageStream>(
    accessControlPageActions.openUserAssetsFilterSidebar,
    accessControlPageStreamOperations.handleOpenUserAssetsFilterSidebar
  ),
  reducer<AccessControlPageStream>(
    accessControlPageActions.closeUserAssetsFilterSidebar,
    accessControlPageStreamOperations.handleCloseUserAssetsFilterSidebar
  ),
  reducer<AccessControlPageStream, AccessControlRoute>(
    setAccessControlTab,
    accessControlPageStreamOperations.handleCloseUserAssetsFilterSidebar
  ),
  reducer(
    accessControlPageActions.requestUserAssetsSuccess,
    accessControlPageStreamOperations.requestUserAssetsSuccess
  ),
  reducer(
    accessControlPageActions.requestUserAssetsFailure,
    accessControlPageStreamOperations.requestUserAssetsFailure
  ),
  reducer(
    accessControlPageActions.requestUserAssets,
    accessControlPageStreamOperations.loadingUserAssets
  ),
  reducer(
    accessControlPageActions.userAssetsFilterChanged,
    accessControlPageStreamOperations.updateUserAssetsQuery
  ),
];
