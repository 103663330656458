import { ModalSize, alert, confirm } from '@ardoq/modal';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  catchError,
  delayWhen,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  fetchAvailableTriples,
  fetchAvailableTriplesError,
  fetchAvailableTriplesSuccess,
  fetchTypeRelationships,
  fetchTypeRelationshipsSuccess,
  fetchRestrictedWorkspaces,
  setViewpointPublished,
  viewpointDelete,
  viewpointDeleteSuccess,
  viewpointSave,
  viewpointSaveError,
  viewpointSaveSuccess,
  viewpointsFetch,
  viewpointsFetchSuccess,
  populateRestrictedWorkspaces,
  viewpointsFetchError,
  updateDefaultViewpoint,
  viewpointFetch,
  renameViewpoint,
} from './actions';
import {
  getAvailableTriples,
  isPersisted,
  mapAPIResponse,
  prepareIsIncluded,
} from './utils';
import { navigateToViewpointForm } from 'router/navigationActions';
import viewpoints$ from './viewpoints$';
import { trackEvent } from 'tracking/tracking';
import { every } from 'lodash';
import { PrivilegeLabel } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { showToast, ToastType, WarningNotification } from '@ardoq/status-ui';
import { hasPrivilege } from '@ardoq/privileges';
import viewpointsNavigation$ from './navigation/viewpointsNavigation$';
import workspaces$ from 'streams/workspaces/workspaces$';
import { handleError, viewpointApi } from '@ardoq/api';
import { saveExistingViewpoint } from './routineUtils';
import { ArdoqError, getArdoqErrorMessage } from '@ardoq/common-helpers';
import { addPermissionForResource } from 'streams/currentUserPermissions/actions';
import currentUser$ from 'streams/currentUser/currentUser$';

const errorHandler = (error: ArdoqError) => {
  viewpointAlert('saved', getArdoqErrorMessage(error));
  dispatchAction(viewpointSaveError());
};

const viewpointAlert = (
  action: 'deleted' | 'copied' | 'saved',
  errorMessage?: string
) =>
  alert({
    title: `Viewpoint could not be ${action} due to the following:`,
    subtitle: `Error Message: ${errorMessage}`,
    modalSize: ModalSize.S,
  });

const currentUserHasDiscoverAccess = () => {
  return hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER);
};

const handleFetchViewpoints = routine(
  ofType(viewpointsFetch),
  filter(currentUserHasDiscoverAccess),
  switchMap(viewpointApi.fetchAll),
  handleError(error => {
    dispatchAction(
      viewpointsFetchError({ error: getArdoqErrorMessage(error) })
    );
  }),
  map(res => res.map(mapAPIResponse)),
  withLatestFrom(viewpointsNavigation$, workspaces$),
  tap(([viewpoints, { viewpointId }, { byId: workspacesById }]) => {
    const workspaceUsedInViewpoints = viewpoints?.flatMap(
      ({ workspaceIds }) => workspaceIds
    );
    const isIncludedInAvailableWorkspaces = prepareIsIncluded(
      Object.keys(workspacesById)
    );
    if (!every(workspaceUsedInViewpoints, isIncludedInAvailableWorkspaces)) {
      dispatchAction(fetchRestrictedWorkspaces());
    }

    dispatchAction(
      viewpointsFetchSuccess({
        viewpoints,
        currentViewpointId: viewpointId,
      })
    );
  }),
  catchError((error, caught) => {
    dispatchAction(
      viewpointsFetchError({
        error: getArdoqErrorMessage(error),
      })
    );
    return caught;
  })
);

const handleViewpointSave = routine(
  ofType(viewpointSave),
  filter(currentUserHasDiscoverAccess),
  extractPayload(),
  filter(isPersisted),
  switchMap(saveExistingViewpoint),
  handleError(error => {
    showToast(getArdoqErrorMessage(error), ToastType.ERROR);
  })
);

const handleNewViewpointSave = routine(
  ofType(viewpointSave),
  filter(currentUserHasDiscoverAccess),
  extractPayload(),
  filter(viewpoint => !isPersisted(viewpoint)),
  switchMap(viewpointApi.createWithPermissions),
  handleError(errorHandler),
  withLatestFrom(currentUser$),
  tap(([response, currentUser]) => {
    dispatchAction(
      addPermissionForResource({
        currentUser,
        permission: response.permission,
      })
    );
    dispatchAction(viewpointSaveSuccess(mapAPIResponse(response.viewpoint)));
    dispatchAction(navigateToViewpointForm(response.viewpoint._id));
    trackEvent('Created viewpoint');
  })
);

const handleViewpointDelete = routine(
  ofType(viewpointDelete),
  filter(currentUserHasDiscoverAccess),
  extractPayload(),
  delayWhen(viewpoint => viewpointApi.delete(viewpoint._id)),
  handleError(error => viewpointAlert('deleted', getArdoqErrorMessage(error))),
  tap(viewpoint => {
    trackEvent('Deleted viewpoint');
    dispatchAction(viewpointDeleteSuccess(viewpoint._id));
  })
);

const handleFetchTypeRelationships = routine(
  ofType(fetchTypeRelationships),
  filter(currentUserHasDiscoverAccess),
  extractPayload(),
  switchMap(viewpointApi.fetchTypeRelationships),
  handleError(),
  tap(result => {
    dispatchAction(fetchTypeRelationshipsSuccess(result));
  })
);

const handleFetchAvailableTriples = routine(
  ofType(fetchAvailableTriples),
  filter(currentUserHasDiscoverAccess),
  extractPayload(),
  withLatestFrom(viewpoints$),
  filter(([, { workspaces, viewpoints }]) => {
    return workspaces !== null && viewpoints !== null;
  }),
  map(([workspaceIds]) => workspaceIds),
  switchMap(viewpointApi.fetchTypeRelationships),
  handleError(error => {
    dispatchAction(fetchAvailableTriplesError());
    logError(error, 'Failed to fetch type relationships');
  }),
  map(response => response.typeRelationships),
  map(getAvailableTriples),
  tap(availableTriples => {
    dispatchAction(fetchAvailableTriplesSuccess(availableTriples));
  })
);

const handleSetViewpointPublished = routine(
  ofType(setViewpointPublished),
  filter(currentUserHasDiscoverAccess),
  extractPayload(),
  withLatestFrom(viewpoints$),
  tap(async ([{ viewpointId, published }, { viewpoints }]) => {
    const viewpoint = viewpoints?.find(({ _id }) => _id === viewpointId);
    if (!viewpoint) return;
    if (
      !published &&
      viewpoint.defaultForComponentTypeNames &&
      viewpoint.defaultForComponentTypeNames.length > 0
    ) {
      const confirmed = await confirm({
        title: 'Unpublish default viewpoint',
        text: (
          <>
            This is the default viewpoint for:
            <ul>
              {viewpoint.defaultForComponentTypeNames.map(t => (
                <li key={t.componentTypeName + t.workspaceId}>
                  {t.componentTypeName}
                </li>
              ))}
            </ul>
            <WarningNotification>
              When users search for these components, they will be taken to the
              Default Overview
            </WarningNotification>
          </>
        ),
        confirmButtonTitle: 'Unpublish',
        isConfirmButtonDanger: true,
      });
      if (confirmed) {
        dispatchAction(
          viewpointSave({
            ...viewpoint,
            published,
            defaultForComponentTypeNames: [],
          })
        );
      }
    } else {
      dispatchAction(viewpointSave({ ...viewpoint, published }));
    }
  })
);

const handleFetchRestrictedWorkspaces = routine(
  ofType(fetchRestrictedWorkspaces),
  switchMap(() => viewpointApi.fetchRestrictedWorkspaces()),
  handleError(),
  tap(response => {
    dispatchAction(populateRestrictedWorkspaces(response));
  })
);

// the BE already updates the old viewpoint to remove the component type and
// workspace so we just need to refetch it after updating the new one (if it exists)
const handleUpdateDefaultViewpoint = routine(
  ofType(updateDefaultViewpoint),
  filter(currentUserHasDiscoverAccess),
  extractPayload(),
  withLatestFrom(viewpoints$),
  switchMap(
    ([{ componentTypeName, workspaceId, viewpointId }, { viewpoints }]) => {
      const viewpointToUnset = viewpoints?.find(v =>
        v.defaultForComponentTypeNames?.some(
          vt =>
            vt.componentTypeName === componentTypeName &&
            vt.workspaceId === workspaceId
        )
      );
      const viewpointToSet = viewpoints?.find(v => v._id === viewpointId);

      const oldViewpoint = viewpointToUnset
        ? {
            ...viewpointToUnset,
            defaultForComponentTypeNames:
              viewpointToUnset.defaultForComponentTypeNames?.filter(
                t =>
                  t.componentTypeName !== componentTypeName &&
                  t.workspaceId !== workspaceId
              ),
          }
        : undefined;
      const newViewpoint = viewpointToSet
        ? {
            ...viewpointToSet,
            defaultForComponentTypeNames: [
              ...(viewpointToSet.defaultForComponentTypeNames ?? []),
              { componentTypeName, workspaceId },
            ],
          }
        : undefined;

      if (newViewpoint) {
        const result = saveExistingViewpoint(newViewpoint);
        if (oldViewpoint) {
          dispatchAction(viewpointFetch(oldViewpoint._id));
        }
        return result;
      }
      if (!oldViewpoint) {
        return [];
      }
      return saveExistingViewpoint(oldViewpoint);
    }
  ),
  handleError(errorHandler)
);

const handleFetchViewpoint = routine(
  ofType(viewpointFetch),
  filter(currentUserHasDiscoverAccess),
  extractPayload(),
  switchMap(viewpointApi.fetch),
  handleError(error => {
    dispatchAction(
      viewpointsFetchError({
        error: getArdoqErrorMessage(error),
      })
    );
  }),
  tap(response =>
    dispatchAction(viewpointSaveSuccess(mapAPIResponse(response)))
  )
);

const handleRenameViewpoint = routine(
  ofType(renameViewpoint),
  filter(currentUserHasDiscoverAccess),
  extractPayload(),
  tap(payload => {
    dispatchAction(viewpointSave({ ...payload.viewpoint, name: payload.name }));
  })
);

export default collectRoutines(
  handleFetchViewpoints,
  handleViewpointSave,
  handleViewpointDelete,
  handleFetchAvailableTriples,
  handleSetViewpointPublished,
  handleFetchTypeRelationships,
  handleFetchRestrictedWorkspaces,
  handleNewViewpointSave,
  handleUpdateDefaultViewpoint,
  handleFetchViewpoint,
  handleRenameViewpoint
);
