import {
  SurveyFilterSetupAttributes,
  SurveyMiscAttributes,
  SurveySectionStatus,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { MaybePersistedSurvey, SurveyValidation } from 'surveyAdmin/types';
import { SurveyEditorSectionKey, SurveyEditorState } from './types';

export const sectionSelected = actionCreator<number>(
  '[surveyEditor] SECTION_SELECTED'
);

export type SetValidSectionsPayload = {
  discoverEnabled: boolean;
  canSeeChangeApprovalStep: boolean;
};

export const setValidSections = actionCreator<SetValidSectionsPayload>(
  '[surveyEditor] SET_VALID_SECTIONS'
);

export const setValidationErrors = actionCreator<SurveyValidation>(
  '[surveyEditor] SET_VALIDATION_ERRORS'
);

export const setSurveyAttributes = actionCreator<MaybePersistedSurvey>(
  '[surveyEditor] SET_SURVEY_ATTRIBUTES'
);

export const setMiscSurveyAttribute = actionCreator<{
  key: keyof SurveyMiscAttributes;
  value: SurveyMiscAttributes[keyof SurveyMiscAttributes];
}>('[surveyEditor] SET_MISC_SURVEY_ATTRIBUTE');

export const setFilterSetupSurveyAttribute =
  actionCreator<SurveyFilterSetupAttributes>(
    '[surveyEditor] SET_FILTER_SETUP_SURVEY_ATTRIBUTE'
  );

export type SetSectionStatusPayload = {
  key: SurveyEditorSectionKey;
  status: SurveySectionStatus;
};

export const setSectionStatus = actionCreator<SetSectionStatusPayload>(
  '[surveyEditor] SET_SECTION_STATUS'
);

export const setSurveyFieldHasBeenInteractedWith = actionCreator<{
  fieldKey: keyof SurveyEditorState['fieldHasBeenInteractedWith'];
}>('[surveyEditor] SET_SURVEY_FIELD_HAS_BEEN_INTERACTED_WITH');

export const setSurveyQuestionFieldHasBeenInteractedWith = actionCreator<{
  questionKey: string;
  fieldKey: keyof SurveyEditorState['fieldHasBeenInteractedWith']['questions'][string];
}>('[surveyEditor] SET_SURVEY_QUESTION_FIELD_HAS_BEEN_INTERACTED_WITH');

export const setAllExistingQuestionsInteractedWith = actionCreator(
  '[surveyEditor] HANDLE_SET_ALL_EXISTING_QUESTIONS_INTERACTED_WITH'
);

export const resetSurveyFieldHasBeenInteractedWith = actionCreator(
  '[surveyEditor] RESET_SURVEY_FIELD_HAS_BEEN_INTERACTED_WITH'
);

export const updateSurveyAttributes = actionCreator<
  Partial<MaybePersistedSurvey>
>('[surveyEditor] UPDATE_SURVEY_ATTRIBUTES');

export const setHasInvalidatedSurveyChangeApproval = actionCreator<boolean>(
  '[surveyEditor] SET_HAS_INVALIDATED_SURVEY_CHANGE_APPROVAL'
);
