import { OrgAccessLevel } from '@ardoq/api-types';
import { reducedStream, reducer } from '@ardoq/rxbeach';
import { setInvitationFormState } from 'streams/invitations/actions';
import { FormState } from 'streams/invitations/types';
import { inviteNewUserOperations } from '../../sharing/inviteNewUserOperations';

export interface InvitationFormShape {
  formState: FormState;
}

const setStateReducer = (
  { formState }: InvitationFormShape,
  { emails, role, privileges }: Partial<FormState>
) => {
  const isValid =
    emails &&
    emails.length !== 0 &&
    emails
      .filter(email => email !== '')
      .every(email => inviteNewUserOperations.isEmailValid(email));
  return {
    formState: {
      ...formState,
      emails: emails ? [...emails] : formState.emails,
      role: role ?? formState.role,
      privileges: privileges ?? formState.privileges,
      isValid: Boolean(isValid),
    },
  };
};

const invitationForm$ = reducedStream<InvitationFormShape>(
  'userInvitationForm$',
  {
    formState: {
      emails: [],
      role: OrgAccessLevel.WRITER,
      privileges: [],
      isValid: false,
    },
  },
  [reducer(setInvitationFormState, setStateReducer)]
);

export default invitationForm$;
