import { collectRoutines } from '@ardoq/rxbeach';
import { surveyApi } from '@ardoq/api';
import { renameRoutine } from 'streams/assets/routines';
import { handleFetchAll, handleDelete } from 'streams/crud/routines';
import { surveysNamespace } from './surveys$';

export const surveyRoutines = collectRoutines(
  handleDelete(surveysNamespace, surveyApi.delete),
  handleFetchAll(surveysNamespace, surveyApi.fetchAll),
  renameRoutine(surveysNamespace, surveyApi.rename)
);
