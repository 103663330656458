import {
  APIBroadcastAttributes,
  Asset,
  AssetType,
  BroadcastAsset,
  BroadcastStatus,
} from '@ardoq/api-types';
import { Filter } from 'components/AssetsBrowser2024/FilterBar/filterBarTypes';
import { DeleteType } from 'components/DeleteAssetModal/types';
import { deleteBroadcast } from './actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { confirmDeleteAsset } from 'components/DeleteAssetModal/DeleteAssetModal';
import { handleDeleteAsset } from 'appContainer/DashboardContainer/getMenuOptions/handleDeleteAsset';

const isBroadcastAsset = (asset: Asset): asset is BroadcastAsset => {
  return asset.meta.assetType === AssetType.BROADCAST;
};

export const unpublishedFilter: Filter = {
  label: 'Unpublished',
  filterFn: asset =>
    isBroadcastAsset(asset) && asset.status === BroadcastStatus.UNPUBLISHED,
  filterRule: 'OR',
};

export const runningFilter: Filter = {
  label: 'Running',
  filterFn: asset =>
    isBroadcastAsset(asset) && asset.status === BroadcastStatus.RUNNING,
  filterRule: 'OR',
};

export const pausedFilter: Filter = {
  label: 'Paused',
  filterFn: asset =>
    isBroadcastAsset(asset) && asset.status === BroadcastStatus.PAUSED,
  filterRule: 'OR',
};

export const doneFilter: Filter = {
  label: 'Done',
  filterFn: asset =>
    isBroadcastAsset(asset) && asset.status === BroadcastStatus.DONE,
  filterRule: 'OR',
};

export const openDeleteDialog = async (
  broadcast: APIBroadcastAttributes,
  clearSelected: VoidFunction
) => {
  return handleDeleteAsset({
    assetType: AssetType.BROADCAST,
    onDelete: () =>
      confirmDeleteAsset({
        deleteType: DeleteType.BROADCAST,
        name: broadcast.name,
      }),
    onDeleteConfirmed: () => dispatchAction(deleteBroadcast(broadcast._id)),
    clearSelected,
  });
};
