import {
  APIComponentAttributes,
  APICurrentUser,
  APIEntityType,
} from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { combineLatest, map, Observable } from 'rxjs';
import { context$ } from 'streams/context/context$';
import type { ContextShape } from '@ardoq/data-model';
import {
  Permissions,
  isDisabled,
} from 'streams/currentUserPermissions/permissionInterface';
import { Features, hasFeature } from '@ardoq/features';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import {
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { componentInterface } from '@ardoq/component-interface';
import { ComponentOptionsCommands, ComponentOptionsProps } from './types';
import { trackAuditLogEntryPoint } from 'auditLog/tracking';
import { navigateToAuditLog } from 'router/navigationActions';
import {
  createSurveyFromComponent,
  deleteComponent,
  toggleLockComponent,
} from 'streams/components/ComponentActions';
import { trackEvent } from 'tracking/tracking';
import { editComponent, editComponentStyle } from './actions';
import { MenuOptionProps } from '@ardoq/sidebar-menu';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { ScenarioModeState } from 'scope/types';
import { trackButtonClicked } from 'menus/tracking';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

const commands: ComponentOptionsCommands = {
  createSurveyFromComponent: componentId => {
    trackEvent('create-survey-from-component-menu-item');
    dispatchAction(createSurveyFromComponent(componentId));
  },
  deleteComponent: componentId => {
    dispatchAction(deleteComponent(componentId));
  },
  editComponentProperties: componentId => {
    dispatchAction(editComponent(componentId));
  },
  editComponentStyle: componentId => {
    dispatchAction(editComponentStyle(componentId));
  },
  toggleLockComponent: componentId => {
    dispatchAction(toggleLockComponent(componentId));
  },
  openAuditLog: (componentId, name) => {
    trackAuditLogEntryPoint('component history');
    dispatchAction(
      navigateToAuditLog({
        entities: [{ id: componentId, name }],
        entityType: APIEntityType.COMPONENT,
      })
    );
  },
};

const getEditComponentOptions = (
  component: APIComponentAttributes,
  data: {
    commands: ComponentOptionsCommands;
    permissionContext: PermissionContext;
    subdivisionsContext: SubdivisionsContext;
    activeScenarioState: ScenarioModeState | null;
  }
): MenuOptionProps[] => {
  const {
    commands,
    permissionContext,
    subdivisionsContext,
    activeScenarioState,
  } = data;
  const canEditComponentProperties =
    componentAccessControlOperation.canEditComponent({
      component,
      permissionContext,
      subdivisionsContext,
    });

  const hasWorkspaceWriteAccess =
    workspaceAccessControlInterface.canEditWorkspace(
      permissionContext,
      component.rootWorkspace,
      activeScenarioState
    );

  if (!canEditComponentProperties) return [];

  const isLocked = Boolean(component.lock);
  const canToggleLock =
    canUnlock(permissionContext.user, component) || !isLocked;

  return [
    {
      label: 'Edit component properties',
      onClick: () => {
        trackButtonClicked('edit component properties');
        commands.editComponentProperties(component._id);
      },
      isDisabled: isLocked,
      dataClickId: 'edit-component-properties-menu-item',
      isVisible: true,
    },
    {
      label: 'Edit component style',
      onClick: () => {
        trackButtonClicked('edit component style');
        commands.editComponentStyle(component._id);
      },
      isDisabled: isLocked,
      dataClickId: 'edit-component-style-menu-item',
      isVisible: hasWorkspaceWriteAccess,
    },
    {
      label: 'Delete component',
      onClick: () => {
        trackButtonClicked('delete component');
        commands.deleteComponent(component._id);
      },
      isDisabled: isLocked,
      dataClickId: 'delete-component-menu-item',
      isVisible: hasWorkspaceWriteAccess,
    },
    {
      label: isLocked ? 'Unlock component' : 'Lock component',
      onClick: () => {
        trackButtonClicked('toggle lock component');
        commands.toggleLockComponent(component._id);
      },
      dataClickId: 'toggle-lock-component-menu-item',
      isVisible: canToggleLock,
    },
  ].filter(option => option.isVisible);
};

const getActionOptions = (
  component: APIComponentAttributes,
  data: {
    commands: ComponentOptionsCommands;
    context: ContextShape;
    permissionContext: PermissionContext;
    activeScenarioState: ScenarioModeState | null;
  }
): MenuOptionProps[] => {
  const { commands, context, permissionContext, activeScenarioState } = data;
  const hasWorkspaceWriteAccess =
    workspaceAccessControlInterface.canEditWorkspace(
      permissionContext,
      context.workspaceId,
      activeScenarioState
    );

  if (!hasWorkspaceWriteAccess) return [];

  const canCreateSurvey = hasFeature(Features.SURVEYS);
  const isViewVersionHistoryDisabled = isDisabled(
    Permissions.COMPONENT_VIEW_HISTORY
  );

  const isCreateSurveyFromComponentDisabled =
    !workspaceAccessControlInterface.canAdminWorkspace(
      permissionContext,
      context.workspaceId,
      activeScenarioState
    ) || isDisabled(Permissions.SURVEY_CREATE_FROM_COMPONENT);

  return [
    {
      label: 'View component history',
      onClick: () => commands.openAuditLog(component._id, component.name),
      isDisabled: isViewVersionHistoryDisabled,
      dataClickId: 'view-component-history-menu-item',
      isVisible: true,
    },
    {
      label: 'Create survey from component',
      onClick: () => commands.createSurveyFromComponent(component._id),
      isDisabled: isCreateSurveyFromComponentDisabled,
      dataClickId: 'create-survey-from-component-menu-item',
      isVisible: canCreateSurvey,
    },
  ].filter(option => option.isVisible);
};

const canUnlock = (
  user: APICurrentUser | null,
  component: Pick<APIComponentAttributes, '_id' | 'name' | 'lock'>
): boolean => {
  if (!user) return false;

  // admins can always override locks
  if (permissionsOperations.isOrgAdmin(user)) return true;

  // only the user who locked the component can unlock it
  return user._id === component.lock;
};

type StreamDependencies = {
  context: ContextShape;
  permissionContext: PermissionContext;
  activeScenarioState: ScenarioModeState;
  subdivisionsContext: SubdivisionsContext;
};

const toComponentOptionsViewModel = ({
  context,
  permissionContext,
  activeScenarioState,
  subdivisionsContext,
}: StreamDependencies): ComponentOptionsProps => {
  const isScenarioMode =
    activeScenarioOperations.isInScenarioMode(activeScenarioState);

  // This is a side effect, but we can't avoid it. because the components$ stream is not reliable.
  const component = componentInterface.getComponentData(context.componentId);

  if (!component) {
    return {
      mode: 'error',
      message: 'Please select a component',
    };
  }

  if (
    isScenarioMode &&
    componentInterface.isScenarioContextComponent(component._id)
  ) {
    return {
      mode: 'error',
      message: 'Components that are not part of the scenario cannot be edited.',
    };
  }

  const canEditComponentProperties =
    componentAccessControlOperation.canEditComponent({
      component,
      permissionContext,
      subdivisionsContext,
    });
  if (!canEditComponentProperties) {
    return {
      mode: 'error',
      message: "You don't have edit access to the component.",
    };
  }

  return {
    mode: 'ok',
    actionOptions: getActionOptions(component, {
      commands,
      context,
      permissionContext,
      activeScenarioState,
    }),
    editComponentOptions: getEditComponentOptions(component, {
      commands,
      permissionContext,
      subdivisionsContext,
      activeScenarioState,
    }),
  };
};
export const componentOptions$: Observable<ComponentOptionsProps> =
  combineLatest({
    context: context$,
    permissionContext: currentUserPermissionContext$,
    activeScenarioState: activeScenario$,
    subdivisionsContext: subdivisions$,
  }).pipe(map(toComponentOptionsViewModel));
