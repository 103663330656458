import { createBrowserHistory } from 'history';

export const APPLICATION_BASE_PATH = window.IS_PRESENTATION
  ? '/presentation'
  : '/app';

const history = createBrowserHistory({
  basename: APPLICATION_BASE_PATH,
});

const warnBeforeNavigateUnlisteners: VoidFunction[] = [];

type WarnBeforeNavigate = (message?: string) => VoidFunction;

export const warnBeforeNavigate: WarnBeforeNavigate = (
  message = 'Unsaved changes will be lost. Are you sure you want to leave this page?'
) => {
  const beforeUnloadHandler = (evt: BeforeUnloadEvent) => {
    evt.preventDefault();
    return (evt.returnValue = message);
  };

  // listen for the browser navigating away from the current page
  window.addEventListener('beforeunload', beforeUnloadHandler, {
    capture: true,
  });

  // return function to stop listening for navigation changes
  // listen for the history being updated
  const unblock = history.block(() => message);

  // function to remove history and page unload listeners
  const unlisten = () => {
    unblock();
    window.removeEventListener('beforeunload', beforeUnloadHandler, {
      capture: true,
    });
  };

  // add the inlisten function to the array so that all listeners can be cleared at once
  warnBeforeNavigateUnlisteners.push(unlisten);

  return unlisten;
};

// call all unlisten finctions, and leave the array empty
export const clearAllWarnBeforeNavigateListeners = () => {
  while (warnBeforeNavigateUnlisteners.length) {
    const warnBeforeNavigateUnlistener = warnBeforeNavigateUnlisteners.pop()!;
    warnBeforeNavigateUnlistener();
  }
};

export default history;
