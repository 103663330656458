import gremlinAudienceDialog$ from '../gremlinAudienceDialog$';
import AudienceTableByPerson from 'broadcasts/components/audienceTable/audienceByPerson/AudienceTableByPerson';
import getColumnConfigByPerson from 'broadcasts/components/audienceTable/tabbedAudienceTable/audienceByPerson/columnConfig';
import GenericErrorContent from 'broadcasts/broadcastBuilder/broadcastAudienceForm/dialogs/GenericErrorContent';
import EmptyContent from 'broadcasts/components/EmptyContent';
import { connect } from '@ardoq/rxbeach';
import { GremlinAudienceDialogStreamShape } from '../types';
import { doesAudiencePreviewHaveWarnings } from 'broadcasts/utils';
import { AudienceTableColumn } from 'broadcasts/components/audienceTable/types';
import { BroadcastContentType } from '@ardoq/api-types';

type GremlinAudienceByPersonTableStreamedProps = Pick<
  GremlinAudienceDialogStreamShape,
  | 'audiencePreview'
  | 'isFetchingAudiencePreview'
  | 'failedToFetchAudiencePreview'
>;

type GremlinAudienceByPersonTableOwnProps = {
  readonly contentType: BroadcastContentType;
  readonly tableFilter: string;
};

const GremlinAudienceByPersonTable = ({
  audiencePreview,
  isFetchingAudiencePreview,
  failedToFetchAudiencePreview,
  contentType,
  tableFilter,
}: GremlinAudienceByPersonTableStreamedProps &
  GremlinAudienceByPersonTableOwnProps) => {
  const shouldShowStatusColumn =
    doesAudiencePreviewHaveWarnings(audiencePreview);
  return (
    <AudienceTableByPerson
      audiencePreview={audiencePreview}
      getColumnConfig={getColumnConfigByPerson(
        shouldShowStatusColumn,
        contentType
      )}
      tableFilter={tableFilter}
      loading={isFetchingAudiencePreview}
      renderEmptyTable={() => {
        if (isFetchingAudiencePreview) return null;
        if (failedToFetchAudiencePreview) return <GenericErrorContent />;
        return <EmptyContent />;
      }}
      defaultSortById={AudienceTableColumn.PERSON_LABEL}
    />
  );
};

export default connect(GremlinAudienceByPersonTable, gremlinAudienceDialog$);
