import {
  deleteLoadedState,
  loadedStateUpdated,
  setTraversalContextComponent,
} from 'loadedState/actions';
import { buildTraversalState, isError } from 'loadedState/buildState';
import { loadedState$ } from 'loadedState/loadedState$';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs';
import { closeAllWorkspaces } from 'streams/workspaces/actions';
import { resetAndClosePresentationEditor } from 'presentationEditor/presentationUtil';
import { isTraversalLoadedState } from '@ardoq/api-types';
import { enterViewpointMode } from 'streams/traversals/actions';
import { scopeDataLoaded } from '../traversals/stagedLoadedDataAndState$';

const closeAllWorkspacesOnDeleteLastRoutine = routine(
  ofType(deleteLoadedState),
  withLatestFrom(loadedState$),
  tap(async ([, loadedState]) => {
    if (loadedState.length === 0) {
      dispatchAction(closeAllWorkspaces());
    }
  })
);
const resetPresentationEditorOnAllWorkspacesClosed = routine(
  ofType(closeAllWorkspaces),
  tap(() => resetAndClosePresentationEditor())
);

const changeLoadedStateTraversalContext = routine(
  ofType(setTraversalContextComponent),
  extractPayload(),
  withLatestFrom(loadedState$),
  tap(async ([{ loadedStateHash, componentId }, loadedState]) => {
    const previousLoadedState = loadedStateOperations.getLoadedStateWithHash(
      loadedState,
      loadedStateHash
    );

    if (!previousLoadedState || !isTraversalLoadedState(previousLoadedState))
      return;

    const params = loadedStateOperations.updateTraversalContextComponents(
      previousLoadedState,
      [componentId]
    );

    const loadedGraphOrError = await buildTraversalState(params.data);

    if (isError(loadedGraphOrError)) return;
    dispatchAction(
      scopeDataLoaded({
        trackingLocation: 'viewpointNavigator',
        scopeData: loadedGraphOrError.scopeData,
      })
    );
    dispatchAction(enterViewpointMode());

    const newLoadedState = loadedStateOperations.attachLoadedGraph(
      params,
      loadedGraphOrError
    );
    dispatchAction(
      loadedStateUpdated({
        loadedState: newLoadedState,
        existingHash: loadedStateHash,
      })
    );
  })
);

export const viewpointNavigatorRoutines = collectRoutines(
  closeAllWorkspacesOnDeleteLastRoutine,
  resetPresentationEditorOnAllWorkspacesClosed,
  changeLoadedStateTraversalContext
);
