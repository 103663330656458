import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  saveSubdivisionDetailsChanges,
  saveSubdivisionDetailsFailure,
  saveSubdivisionDetailsSuccessfully,
} from 'streams/subdivisions/actions';
import {
  SubdivisionViewModelState,
  ValidationErrors,
} from 'subdivisionEditor/types';
import { openSubdivisionEditor } from 'components/AppMainSidebar/utils';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';
import { Subdivision } from '@ardoq/api-types';
import { SubdivisionEditorSteps } from 'subdivisionEditor/navigation/types';
import { Features, hasFeature } from '@ardoq/features';
import { dispatchAction } from '@ardoq/rxbeach';
import { subdivisionEditorChangesAreSaved } from 'subdivisionEditor/subdivisionEditorViewModel$/actions';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';

const stepValidation = (subdivisionEditorState: SubdivisionViewModelState) => {
  let errors: ValidationErrors = {};
  const {
    subdivision: { name, style },
  } = subdivisionEditorState;

  const isValidColor = style.color.trim().length > 0;

  const nameIsValid = name.trim().length > 0;
  if (!nameIsValid) {
    errors = { ...errors, name: 'Name is not valid' };
  }
  if (!isValidColor) {
    errors = { ...errors, color: 'Color is not valid' };
  }

  return errors;
};

const saveDetails = async (
  subdivisionEditorState: SubdivisionViewModelState
): Promise<boolean> => {
  const { subdivision } = subdivisionEditorState;
  const response = await dispatchActionAndWaitForResponse(
    saveSubdivisionDetailsChanges(subdivision),
    saveSubdivisionDetailsSuccessfully,
    saveSubdivisionDetailsFailure
  );
  if (response.type === saveSubdivisionDetailsSuccessfully.type) {
    if (subdivisionEditorOperations.isCreationMode(subdivisionEditorState)) {
      dispatchAction(subdivisionEditorChangesAreSaved({ silent: true }));
      openSubdivisionEditor(
        (response.payload as Subdivision)._id,
        // Not the nicest but we would have a circular dependency if we imported nextStep operation.
        hasFeature(Features.PERMISSION_ZONES_INTERNAL)
          ? SubdivisionEditorSteps.COMPONENTS_SELECTION
          : SubdivisionEditorSteps.WORKSPACES_BINDING
      );
    }
    return true;
  }
  return false;
};

const onNextButtonClicked = async (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  if (subdivisionEditorOperations.isCreationMode(subdivisionEditorState)) {
    await saveDetails(subdivisionEditorState);
  }
  return true;
};

const nextButtonIsDisabled = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  if (!subdivisionEditorOperations.isCreationMode(subdivisionEditorState)) {
    return false;
  }
  const stepError = stepValidation(subdivisionEditorState);
  return Object.keys(stepError).length !== 0;
};

const getNextButtonHintMessage = (
  subdivisionEditorState: SubdivisionViewModelState
): string | undefined => {
  const {
    subdivision: { name },
  } = subdivisionEditorState;
  if (!name && nextButtonIsDisabled(subdivisionEditorState)) {
    return SUBDIVISIONS_STRINGS.STEPS.DETAILS.DISABLED_NEXT_BUTTON_HINT_TEXT;
  }
  return undefined;
};

export const commands = {
  stepValidation,
  saveDetails,
  onNextButtonClicked,
  nextButtonIsDisabled,
  getNextButtonHintMessage,
};
