import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { StatusType, Tag } from '@ardoq/status-ui';

const Link = styled.a`
  cursor: pointer;
`;

export const NoGraphFiltersActionButton = () => (
  <Link target="_blank" href={KnowledgeBaseLink.NO_GRAPH_FILTERS}>
    Read about how to apply graph filters{' '}
    <Icon
      style={{ verticalAlign: 'middle' }}
      iconName={IconName.OPEN_IN_NEW}
      iconSize={IconSize.SMALL}
    />
  </Link>
);

const unsavedTagStyles: CSSProperties = {
  wordBreak: 'keep-all',
  fontWeight: 'bold',
};
export const UnsavedChangesTag = () => {
  return (
    <Tag
      style={unsavedTagStyles}
      statusType={StatusType.DEFAULT}
      label="Unsaved&nbsp;changes"
    />
  );
};
