import {
  APIReportAttributes,
  ArdoqId,
  QueryBuilderQuery,
  ReportQuery,
  ResourceType,
} from '@ardoq/api-types';
import {
  reducedStream,
  streamReducer,
  toPersistentStream,
} from '@ardoq/rxbeach';
import { ArdoqEvent, EventType } from 'sync/types';
import { ardoqEventOperations } from 'sync/ardoqEventOperations';
import { websocket$ } from 'sync/websocket$';
import { filter, map } from 'rxjs/operators';
import { logWarn } from '@ardoq/logging';
import { reportNamespace } from './actions';
import { toCollectionStream } from 'streams/utils/streamUtils';
import { mergeDateRangeFieldsInColumns } from '@ardoq/report-reader';
import { mapContainsOperatorForQueryRules } from 'search/AdvancedSearch/utils';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { emptyCollectionStream } from '../utils/streamUtils';
import { fetchAllReducer } from 'streams/crud/reducers';
import { reportBuilderOperations } from '@ardoq/report-builder';

const isQueryBuilderQuery = (query: ReportQuery): query is QueryBuilderQuery =>
  typeof query !== 'string';

const fixDateRangeColumns = (
  report: APIReportAttributes
): APIReportAttributes => {
  if (!report.columns) {
    return report;
  }
  const columns = mergeDateRangeFieldsInColumns(
    report.columns,
    fieldInterface.isDateRangeField
  );
  return { ...report, columns };
};

const fixQueryRules = (report: APIReportAttributes): APIReportAttributes => {
  if (reportBuilderOperations.isGremlinSearchBased(report)) {
    return report;
  }
  if (report.query && isQueryBuilderQuery(report.query)) {
    const mappedRules = mapContainsOperatorForQueryRules(report.query.rules);
    return {
      ...report,
      query: {
        ...report.query,
        rules: mappedRules as QueryBuilderQuery['rules'],
      },
    };
  }
  return report;
};

const fixReport = (report: APIReportAttributes): APIReportAttributes =>
  fixQueryRules(fixDateRangeColumns(report));

const isReport = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<APIReportAttributes> => {
  return ardoqEventOperations.isOfResourceType(event, ResourceType.REPORT);
};

const resetReports = (
  _: APIReportAttributes[],
  reports: APIReportAttributes[]
): APIReportAttributes[] => reports.map(fixReport);

const deleteReport = (
  state: APIReportAttributes[],
  id: ArdoqId
): APIReportAttributes[] => state.filter(report => report._id !== id);

const updateReport = (
  state: APIReportAttributes[],
  report: APIReportAttributes
): APIReportAttributes[] => {
  const newReports = state.map(existingReport =>
    existingReport._id === report._id ? report : existingReport
  );

  return resetReports(state, newReports);
};

const addReport = (
  state: APIReportAttributes[],
  report: APIReportAttributes
): APIReportAttributes[] => resetReports(state, [report, ...state]);

const handleWebSocketEvents = (
  state: APIReportAttributes[],
  event: ArdoqEvent<APIReportAttributes>
): APIReportAttributes[] => {
  const eventType = event['event-type'];
  switch (eventType) {
    case EventType.DELETE:
      return deleteReport(state, event.data._id);
    case EventType.CREATE:
      return addReport(state, event.data);
    case EventType.UPDATE:
      return updateReport(state, event.data);
    default:
      logWarn(new Error('unknown event-type'), eventType);
  }

  return state;
};

const reportsList$ = reducedStream<APIReportAttributes[]>(
  'reports$',
  [],
  [
    fetchAllReducer(resetReports),
    streamReducer(websocket$.pipe(filter(isReport)), handleWebSocketEvents),
  ],
  { namespace: reportNamespace }
);

export default toPersistentStream(
  'reportState$',
  reportsList$.pipe(map(toCollectionStream)),
  emptyCollectionStream()
);
