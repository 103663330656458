import { reducer } from '@ardoq/rxbeach';
import { scopeDataOperations } from '@ardoq/scope-data';
import {
  fetchChangeApprovalData,
  notifyFetchingChangeApprovalDataFailed,
  notifyFetchingChangeApprovalDataSucceeded,
  cancelSavingApprovedChanges,
  notifySavingApprovedChangesSucceeded,
  saveApprovedChanges,
  setApprovedDataForComponentField,
  setSearchPhrase,
  setApprovedDataForEntireComponent,
  resetDataForComponentField,
  setSortBy,
  setPerPage,
  setFilterChips,
  setActiveComponentId,
  setChangeRequestAction,
  resetDataForEntireComponent,
  setApprovalFocus,
  resetDataForReferenceField,
  setApprovedDataForReferenceField,
  resetDataForEntireReference,
  approveEntireReference,
  rejectEntireReference,
} from './actions';
import {
  ApprovalFocus,
  ChangeApprovalData,
  ChangeApprovalsStreamShape,
  FieldDataArgs,
  ApproveOrRejectAllComponentPayload,
  ReferenceFieldResetArgs,
  ComponentFieldResetArgs,
} from './types';
import {
  APIReferenceAttributes,
  ArdoqId,
  ChangeRequestAction,
  SortOrder,
} from '@ardoq/api-types';
import { isEmpty, omit } from 'lodash';
import { alterSortOrder } from '@ardoq/common-helpers';
import { getChosenReferences } from './utils';
import {
  ValidateOrReset,
  createOrGetPartiallyApprovedComponent,
  createOrGetPartiallyApprovedReference,
  updateAllValidations,
  updateComponentValidationObjectForComponentChange,
  updateComponentValidationObjectForReferenceChange,
  updateComponenteRecordInApprovedComponentChanges,
  updateFieldValidation,
  updateReferenceRecordInApprovedReferenceChanges,
} from './reducerHelpers';

export const emptyChangeApprovalData = {
  deletedOnMainlineIds: null,
  invalidChangeRequests: [],
  componentsWithPendingChanges: [],
  changeRequests: [],
  masterData: scopeDataOperations.enhance(scopeDataOperations.getEmpty()),
  branchData: scopeDataOperations.enhance(scopeDataOperations.getEmpty()),
  componentValidationObject: {},
  referenceValidationObject: {},
  changedFieldsByComponentId: {},
  changedReferencesByComponentId: {},
  changedFieldsByReferenceId: {},
};

export const defaultState: ChangeApprovalsStreamShape = {
  status: 'idle',
  approvedReferenceChanges: {},
  approvedComponentChanges: {},
  searchPhrase: '',
  sortById: 'name',
  sortOrder: SortOrder.ASC,
  perPage: 10,
  filterChips: [],
  componentId: '',
  changeAction: 'update',
  approvalFocus: 'component',
  ...emptyChangeApprovalData,
};

const handleFetchingChangeApprovalDataFailed = (
  state: ChangeApprovalsStreamShape
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    status: 'error',
  };
};

const handleChangeApprovalDataIsFetching = (): ChangeApprovalsStreamShape => {
  return {
    ...defaultState,
    status: 'loading',
  };
};

const handleFetchingChangeApprovalDataFetchSucceeded = (
  state: ChangeApprovalsStreamShape,
  changeApprovalData: ChangeApprovalData
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    status: 'success',
    ...changeApprovalData,
  };
};

const handleSaveApprovedChanges = (
  state: ChangeApprovalsStreamShape
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    status: 'saving',
  };
};

const handleResetApprovedDataForEntireComponent = (
  state: ChangeApprovalsStreamShape,
  componentId: ArdoqId
): ChangeApprovalsStreamShape => {
  const { componentValidationObject } = state;
  return {
    ...state,
    componentValidationObject: {
      ...componentValidationObject,
      [componentId]: updateAllValidations(
        componentValidationObject,
        componentId,
        ValidateOrReset.RESET
      ),
    },
    approvedComponentChanges: {},
  };
};

const handleResetApprovedDataForEntireReference = (
  state: ChangeApprovalsStreamShape,
  referenceId: ArdoqId
): ChangeApprovalsStreamShape => {
  const { componentId, componentValidationObject, approvedReferenceChanges } =
    state;

  return {
    ...state,
    componentValidationObject:
      updateComponentValidationObjectForReferenceChange(
        componentValidationObject,
        componentId,
        referenceId,
        true
      ),
    approvedReferenceChanges: updateReferenceRecordInApprovedReferenceChanges(
      approvedReferenceChanges,
      referenceId,
      undefined
    ),
  };
};

const handleApproveEntireReference = (
  state: ChangeApprovalsStreamShape,
  reference: APIReferenceAttributes
): ChangeApprovalsStreamShape => {
  const { componentId, componentValidationObject, approvedReferenceChanges } =
    state;

  return {
    ...state,
    componentValidationObject:
      updateComponentValidationObjectForReferenceChange(
        componentValidationObject,
        componentId,
        reference._id,
        false
      ),
    approvedReferenceChanges: updateReferenceRecordInApprovedReferenceChanges(
      approvedReferenceChanges,
      reference._id,
      reference
    ),
  };
};

const handleRejectEntireReference = (
  state: ChangeApprovalsStreamShape,
  reference: APIReferenceAttributes
): ChangeApprovalsStreamShape => {
  const { componentId, componentValidationObject, approvedReferenceChanges } =
    state;

  return {
    ...state,
    componentValidationObject:
      updateComponentValidationObjectForReferenceChange(
        componentValidationObject,
        componentId,
        reference._id,
        false
      ),
    approvedReferenceChanges: updateReferenceRecordInApprovedReferenceChanges(
      approvedReferenceChanges,
      reference._id,
      undefined
    ),
  };
};

const handleSetApprovedDataForEntireComponent = (
  state: ChangeApprovalsStreamShape,
  {
    componentId,
    approvedComponentData,
    action,
  }: ApproveOrRejectAllComponentPayload
): ChangeApprovalsStreamShape => {
  const {
    approvedComponentChanges,
    componentValidationObject,
    branchData,
    masterData,
    changedReferencesByComponentId,
  } = state;

  const releventChangedReferences =
    changedReferencesByComponentId[componentId] ?? {};

  const chosenReferences = getChosenReferences(
    action,
    releventChangedReferences,
    branchData,
    masterData
  );

  return {
    ...state,
    componentValidationObject:
      updateComponentValidationObjectForComponentChange(
        componentValidationObject,
        componentId,
        updateAllValidations(
          componentValidationObject,
          componentId,
          ValidateOrReset.VALIDATE
        )
      ),
    approvedComponentChanges: {
      ...approvedComponentChanges,
      [componentId]: approvedComponentData,
    },
    referenceValidationObject: {},
    approvedReferenceChanges: chosenReferences,
  };
};

const handleSetApprovedDataForComponentField = (
  state: ChangeApprovalsStreamShape,
  { whatChanged, masterComponent, changedFields, chosenValue }: FieldDataArgs
): ChangeApprovalsStreamShape => {
  const { approvedComponentChanges, componentValidationObject } = state;

  const partiallyUpdatedComponent = createOrGetPartiallyApprovedComponent(
    approvedComponentChanges,
    masterComponent._id,
    masterComponent,
    changedFields
  );

  const componentWithNewlyApprovedField = {
    ...partiallyUpdatedComponent,
    [whatChanged]: chosenValue,
  };

  const updatedFieldValidation = updateFieldValidation(
    componentValidationObject,
    masterComponent._id,
    whatChanged,
    ValidateOrReset.VALIDATE
  );

  return {
    ...state,
    componentValidationObject:
      updateComponentValidationObjectForComponentChange(
        componentValidationObject,
        masterComponent._id,
        updatedFieldValidation
      ),
    approvedComponentChanges: updateComponenteRecordInApprovedComponentChanges(
      approvedComponentChanges,
      masterComponent._id,
      componentWithNewlyApprovedField
    ),
  };
};

const handleResetDataForComponentField = (
  state: ChangeApprovalsStreamShape,
  { whatChanged, masterComponent, changedFields }: ComponentFieldResetArgs
): ChangeApprovalsStreamShape => {
  const { approvedComponentChanges, componentValidationObject } = state;

  const partiallyUpdatedComponent = createOrGetPartiallyApprovedComponent(
    approvedComponentChanges,
    masterComponent._id,
    masterComponent,
    changedFields
  );

  const componentWithResetField = omit(partiallyUpdatedComponent, whatChanged);

  const updatedFieldValidation = updateFieldValidation(
    componentValidationObject,
    masterComponent._id,
    whatChanged,
    ValidateOrReset.RESET
  );

  return {
    ...state,
    componentValidationObject:
      updateComponentValidationObjectForComponentChange(
        componentValidationObject,
        masterComponent._id,
        updatedFieldValidation
      ),
    approvedComponentChanges: updateComponenteRecordInApprovedComponentChanges(
      approvedComponentChanges,
      masterComponent._id,
      componentWithResetField
    ),
  };
};

const handleSetApprovedDataForReferenceField = (
  state: ChangeApprovalsStreamShape,
  {
    whatChanged,
    masterComponent,
    changedFields,
    chosenValue,
    changeApprovalEntityId,
  }: FieldDataArgs
): ChangeApprovalsStreamShape => {
  const {
    componentValidationObject,
    masterData,
    approvedReferenceChanges,
    referenceValidationObject,
  } = state;

  const masterReference = masterData.referencesById[changeApprovalEntityId];
  const partiallyUpdatedReference = createOrGetPartiallyApprovedReference(
    approvedReferenceChanges,
    masterReference,
    changedFields
  );

  const referenceWithNewlyApprovedField = {
    ...partiallyUpdatedReference,
    [whatChanged]: chosenValue,
  };

  const updatedReferenceValidation = omit(
    referenceValidationObject[masterReference._id],
    whatChanged
  );

  return {
    ...state,
    componentValidationObject:
      updateComponentValidationObjectForReferenceChange(
        componentValidationObject,
        masterComponent._id,
        masterReference._id,
        !isEmpty(updatedReferenceValidation)
      ),
    approvedReferenceChanges: updateReferenceRecordInApprovedReferenceChanges(
      approvedReferenceChanges,
      masterReference._id,
      referenceWithNewlyApprovedField
    ),
    referenceValidationObject: {
      ...referenceValidationObject,
      [masterReference._id]: updatedReferenceValidation,
    },
  };
};

const handleResetDataForReferenceField = (
  state: ChangeApprovalsStreamShape,
  {
    whatChanged,
    masterComponent,
    changeApprovalEntityId,
    changedFields,
  }: ReferenceFieldResetArgs
): ChangeApprovalsStreamShape => {
  const {
    componentValidationObject,
    approvedReferenceChanges,
    referenceValidationObject,
    masterData,
  } = state;

  const masterReference = masterData.referencesById[changeApprovalEntityId];

  const partiallyUpdatedReference = createOrGetPartiallyApprovedReference(
    approvedReferenceChanges,
    masterReference,
    changedFields
  );

  const referenceWithResetField = omit(partiallyUpdatedReference, whatChanged);

  const resetReferenceValidationObject = {
    ...referenceValidationObject[masterReference._id],
    [whatChanged]: true,
  };

  return {
    ...state,
    componentValidationObject:
      updateComponentValidationObjectForReferenceChange(
        componentValidationObject,
        masterComponent._id,
        masterReference._id,
        true
      ),
    approvedReferenceChanges: updateReferenceRecordInApprovedReferenceChanges(
      approvedReferenceChanges,
      masterReference._id,
      referenceWithResetField
    ),
    referenceValidationObject: {
      ...referenceValidationObject,
      [masterReference._id]: resetReferenceValidationObject,
    },
  };
};

const handleSavingApprovedChangesSucceded = (
  state: ChangeApprovalsStreamShape
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    status: 'saved',
  };
};

const handleCancelSavingApprovedChanges = (
  state: ChangeApprovalsStreamShape
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    status: 'idle',
  };
};

const handleSetSearchPhrase = (
  state: ChangeApprovalsStreamShape,
  searchPhrase: string
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    searchPhrase,
  };
};

const handleSetSortBy = (
  state: ChangeApprovalsStreamShape,
  sortById: string
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    sortById,
    sortOrder:
      sortById === state.sortById
        ? alterSortOrder(state.sortOrder)
        : SortOrder.ASC,
  };
};

const handleSetPerPage = (
  state: ChangeApprovalsStreamShape,
  perPage: number
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    perPage,
  };
};

const handleSetFilterChips = (
  state: ChangeApprovalsStreamShape,
  filterChips: string[]
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    filterChips,
  };
};

const handleSetActiveComponentId = (
  state: ChangeApprovalsStreamShape,
  componentId: ArdoqId
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    componentId,
  };
};

const handleSetChangeRequestAction = (
  state: ChangeApprovalsStreamShape,
  changeAction: ChangeRequestAction
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    changeAction,
  };
};

const handleSetApprovalFocus = (
  state: ChangeApprovalsStreamShape,
  approvalFocus: ApprovalFocus
): ChangeApprovalsStreamShape => {
  return {
    ...state,
    approvalFocus,
  };
};

export const reducers = [
  reducer(setSearchPhrase, handleSetSearchPhrase),
  reducer(cancelSavingApprovedChanges, handleCancelSavingApprovedChanges),
  reducer(
    notifySavingApprovedChangesSucceeded,
    handleSavingApprovedChangesSucceded
  ),
  reducer(
    setApprovedDataForComponentField,
    handleSetApprovedDataForComponentField
  ),
  reducer(
    setApprovedDataForReferenceField,
    handleSetApprovedDataForReferenceField
  ),
  reducer(
    setApprovedDataForEntireComponent,
    handleSetApprovedDataForEntireComponent
  ),
  reducer(resetDataForComponentField, handleResetDataForComponentField),
  reducer(resetDataForReferenceField, handleResetDataForReferenceField),
  reducer(saveApprovedChanges, handleSaveApprovedChanges),
  reducer(
    notifyFetchingChangeApprovalDataSucceeded,
    handleFetchingChangeApprovalDataFetchSucceeded
  ),
  reducer(fetchChangeApprovalData, handleChangeApprovalDataIsFetching),
  reducer(
    notifyFetchingChangeApprovalDataFailed,
    handleFetchingChangeApprovalDataFailed
  ),
  reducer(setSortBy, handleSetSortBy),
  reducer(setPerPage, handleSetPerPage),
  reducer(setFilterChips, handleSetFilterChips),
  reducer(setActiveComponentId, handleSetActiveComponentId),
  reducer(setChangeRequestAction, handleSetChangeRequestAction),
  reducer(
    resetDataForEntireComponent,
    handleResetApprovedDataForEntireComponent
  ),
  reducer(setApprovalFocus, handleSetApprovalFocus),
  reducer(
    resetDataForEntireReference,
    handleResetApprovedDataForEntireReference
  ),
  reducer(approveEntireReference, handleApproveEntireReference),
  reducer(rejectEntireReference, handleRejectEntireReference),
];
