import { useState } from 'react';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import {
  DeprecatedSlidesNotificationElement,
  KilldateCountdownContainer,
  NotificationMessageContainer,
  StyledNotificationBody,
} from 'viewDeprecation/atoms';
import { DeprecatedSlidesNotificationProperties } from 'viewDeprecation/types';
import { getSlideOwnerMigrationPermissions } from 'presentation/dataLoader';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { setSlidesMigrationInfo } from 'presentation/actions';
import { slidesMigrationInfo$ } from 'streams/slidesMigrationInfo$';
import { map } from 'rxjs/operators';
import { getPhaseMessageText } from 'viewDeprecation/DepreciatedSlideNotificationUtil';
import { LoadingOverlay } from '@ardoq/layout';
import {
  DeprecatedSlideNotificationDetails,
  SolutionAdvice,
} from './DeprecatedSlidesNotificationParts';
import {
  currentDate,
  formatDuration,
  intervalToDuration,
} from '@ardoq/date-time';
import { LoaderSize } from '@ardoq/ardoq-loader-component';
import { APIPresentationAssetAttributes } from '@ardoq/api-types';
import { presentationOperations } from 'streams/presentations/presentationOperations';
import currentUser$ from 'streams/currentUser/currentUser$';
import { combineLatest } from 'rxjs';
import presentations$ from 'streams/presentations/presentations$';
import { getIsOrgAdmin } from '@ardoq/common-helpers';
import { presentationAccessControlOperations } from 'resourcePermissions/accessControlHelpers/presentations';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';

export const expressTimespan = (start: Date, end: Date) =>
  formatDuration(intervalToDuration({ end, start }), {
    format: ['years', 'months', 'weeks', 'days'],
  });

const DeprecatedSlidesNotification = ({
  presentation,
  canEditPresentation,
  deprecatedSlides,
  minimumKillDate,
  overallPresentationViewInfo,
  slidesInfo,
  currentUserIsOrgAdmin,
  permissionContext,
}: DeprecatedSlidesNotificationProperties) => {
  // A notification in the presentation sidebar, that summarizes deprecation process info
  // for all slides containing a deprecated view within the current presentation
  // Read about the logic and relevant code parts in
  // https://ardoqcom.atlassian.net/wiki/spaces/INSIGHT/pages/2413002789/Discontinued+Views+engineering+perspective.

  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const getSlidesMigrationInfo = async (
    deprecatedSlideIds: string[],
    activePresentation?: APIPresentationAssetAttributes
  ) => {
    setIsRequestInProgress(true);
    const slidesMigrationInfo = activePresentation
      ? await getSlideOwnerMigrationPermissions(
          activePresentation,
          deprecatedSlideIds
        )
      : undefined;
    dispatchAction(
      setSlidesMigrationInfo({
        activePresentationId: activePresentation?._id,
        slidesInfo: slidesMigrationInfo,
      })
    );
    setIsRequestInProgress(false);
  };

  const activePresentation = overallPresentationViewInfo?.presentation;

  const shouldShowNotification =
    activePresentation?._id &&
    overallPresentationViewInfo?.overallDeprecationPhase &&
    overallPresentationViewInfo?.overallPermissionsLevel &&
    deprecatedSlides?.length;

  if (shouldShowNotification) {
    const isSlideMigrationInfoOutOfDate =
      presentation?._id !== activePresentation?._id || !presentation;

    if (isSlideMigrationInfoOutOfDate && !isRequestInProgress) {
      getSlidesMigrationInfo(
        deprecatedSlides?.map(({ slideId }) => slideId),
        overallPresentationViewInfo?.presentation
      );
    }

    const discontinuedSlidesPresent = !!deprecatedSlides.filter(
      slideInfo => slideInfo.viewStatus === MetaInfoViewStatus.DISCONTINUED
    ).length;

    const creatorCanMigrateNothing =
      deprecatedSlides.every(
        ({ slideId }) => !slidesInfo?.[slideId]?.creatorCanMigrate
      ) && !currentUserIsOrgAdmin;

    const creatorCanMigrateSome =
      !creatorCanMigrateNothing &&
      deprecatedSlides.some(
        ({ slideId }) => !slidesInfo?.[slideId]?.creatorCanMigrate
      );

    const phaseMessageText = getPhaseMessageText({
      overallPresentationViewInfo,
      isKilldated: !!minimumKillDate,
    });

    const now = currentDate();

    return isSlideMigrationInfoOutOfDate ? (
      <LoadingOverlay loaderSize={LoaderSize.MEDIUM} />
    ) : (
      <DeprecatedSlidesNotificationElement
        error={discontinuedSlidesPresent}
        warning={!discontinuedSlidesPresent}
        details={
          creatorCanMigrateNothing || isSlideMigrationInfoOutOfDate
            ? null
            : DeprecatedSlideNotificationDetails({
                ...{
                  deprecatedSlides,
                  canEditPresentation,
                  overallPresentationViewInfo,
                  slidesMigrationInfo: slidesInfo,
                },
              })
        }
        detailsLabel="Contact Details"
      >
        <StyledNotificationBody>
          {minimumKillDate && minimumKillDate > now ? (
            <div>
              <KilldateCountdownContainer>
                This presentation contains views that will be removed in{' '}
                {expressTimespan(now, minimumKillDate)}.
              </KilldateCountdownContainer>
            </div>
          ) : null}
          {phaseMessageText ? (
            <NotificationMessageContainer>
              {phaseMessageText}
            </NotificationMessageContainer>
          ) : null}
          <SolutionAdvice
            isCurrentUserCreator={presentationAccessControlOperations.isPresentationOwner(
              permissionContext,
              activePresentation
            )}
            overallPresentationViewInfo={overallPresentationViewInfo}
            creatorCanMigrateNothing={creatorCanMigrateNothing}
            creatorCanMigrateSome={creatorCanMigrateSome}
          />
        </StyledNotificationBody>
      </DeprecatedSlidesNotificationElement>
    );
  }
  return null;
};

export default connect(
  DeprecatedSlidesNotification,
  combineLatest([
    slidesMigrationInfo$,
    currentUser$,
    presentations$,
    currentUserPermissionContext$,
  ]).pipe(
    map(
      ([
        { slidesInfo, activePresentationId },
        currentUser,
        presentationsState,
        permissionContext,
      ]) => {
        const presentation = activePresentationId
          ? presentationOperations.getPresentationById(
              presentationsState,
              activePresentationId
            )
          : undefined;
        return {
          slidesInfo,
          presentation,
          currentUserIsOrgAdmin: getIsOrgAdmin(currentUser),
          permissionContext,
        };
      }
    )
  )
);
