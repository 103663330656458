import { connect } from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import type { ContextShape } from '@ardoq/data-model';
import { RelatedContextWrapper } from './atoms';

const EmptyRelatedView = ({ componentId, workspaceId }: ContextShape) => {
  const contextResourceName =
    componentInterface.getAttribute(componentId, 'name') ||
    workspaceInterface.getAttribute(workspaceId, 'name');
  return (
    <RelatedContextWrapper>
      {contextResourceName} has no related components
    </RelatedContextWrapper>
  );
};

export default connect(EmptyRelatedView, context$);
