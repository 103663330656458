import { SubdivisionProps } from 'subdivisionEditor/types';
import { FormWrapper, TextArea, TextInput } from '@ardoq/forms';
import { ColorPickerInput } from '@ardoq/color-picker';
import { CaptionText } from 'subdivisionEditor/atoms';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';

const {
  SUBDIVISIONS_EDITOR: { DETAILS_PAGE },
} = SUBDIVISIONS_STRINGS;

export const DetailsPage = (props: SubdivisionProps) => {
  const { subdivision, commands } = props;
  return (
    <FormWrapper>
      <TextInput
        label={DETAILS_PAGE.TITLE}
        placeholder={DETAILS_PAGE.PLACEHOLDER}
        value={subdivision.name}
        onValueChange={newValue => commands.updateTitle(props, newValue)}
        errorMessage={props.stepsErrors[props.subdivisionEditorStep]?.name}
      ></TextInput>
      <TextArea
        label="Description"
        fixedHeight
        isOptional
        value={subdivision.description || ''}
        placeholder={DETAILS_PAGE.DESCRIPTION_PLACEHOLDER}
        onValueChange={newValue => commands.updateDescription(props, newValue)}
        errorMessage={
          props.stepsErrors[props.subdivisionEditorStep]?.description
        }
      />
      <ColorPickerInput
        placeholder="#123456"
        label="Color"
        helperText={<CaptionText>{DETAILS_PAGE.COLOR_PICKER_TEXT}</CaptionText>}
        value={subdivision.style.color}
        onValueChange={newValue => commands.updateColor(props, newValue)}
        errorMessage={props.stepsErrors[props.subdivisionEditorStep]?.color}
      ></ColorPickerInput>
    </FormWrapper>
  );
};
