import styled, { css } from 'styled-components';
import { colors, s16, s24, s32, s4, s8 } from '@ardoq/design-tokens';
import { BASE_TRANSITION } from 'consts';
import { header4 } from '@ardoq/typography';
import { Icon } from '@ardoq/icons';

const padding = css`
  padding: ${s24};
`;
export const TemplateCategoryNav = styled.ul`
  ${padding}
  list-style: none;
  margin-right: ${s32};
  min-width: 300px;
`;
export const TemplateListContainer = styled.div`
  padding-left: ${s24};
  flex: 1;
  overflow: auto;
`;

export const VerticalTabs = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

export const CategoryItem = styled.li`
  transition: ${BASE_TRANSITION};
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 ${s8};
  font-size: 13px;
  line-height: 36px;
  letter-spacing: 0 ${s8};
  cursor: pointer;
  text-transform: uppercase;

  &:hover,
  &.active {
    background-color: ${colors.grey80}99;
  }
  &.active {
    font-weight: 700;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  flex-wrap: wrap;
`;

export const CategoryIcon = styled(Icon)`
  margin-right: ${s16};
  opacity: 0.8;
`;

export const TemplatePreviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 376px;
  width: 264px;
  background-color: ${colors.white};
  margin: ${s16} ${s32} ${s16} 0;
  border-radius: 2px;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
`;

export const CardContent = styled.div`
  padding: ${s16} ${s16} 0px ${s16};
  flex: 1;
`;

export const ModelLabels = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  color: ${colors.white};
  background-color: ${colors.blue50};
  padding: ${s4} ${s8};
  border-radius: 0;
  font-size: 12px;
`;

export const TemplatePreviewHeader = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-bottom: ${s8};
`;

export const TemplatePreviewImage = styled.img`
  align-items: center;
  display: flex;
  height: 148px;
  justify-content: center;
  margin: 0 -${s16};
  padding: 0 ${s8};
  width: 264px;
`;

export const CardTitle = styled.div`
  font-size: 18;
  color: ${colors.grey25};
  letter-spacing: 0;
  line-height: 1.2;
`;
export const TemplatePreviewFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${s4} ${s8} ${s8} ${s8};
  background-color: ${colors.white};
`;

export const ModelPreviewSection = styled.div`
  position: absolute;
  z-index: 100;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey80};
  box-shadow: 0 2px ${s4} rgba(0, 0, 0, 0.21);
`;

export const TemplatePreviewDescription = styled.div`
  padding: ${s16} 0;
  word-break: break-word;
`;

export const CategoryCollectionLabel = styled.div`
  ${header4}
  line-height: ${s32}
`;

export const TemplateCategorySectionContainer = styled.div`
  &:not(:first-child) {
    margin-top: ${s8};
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.grey80};
    padding-bottom: ${s8};
  }
`;
