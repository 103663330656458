import React from 'react';
import {
  FilterCondition,
  FilterConditionForValue,
  Transformation,
  TransformationColumn,
  TransformationOperation,
  Transformations,
} from '@ardoq/api-types/integrations';
import { isEqual } from 'lodash/fp';
import {
  transformationComponents,
  TransformationHandler,
} from './transformSection/constants';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import {
  EmptyFilterCondition,
  EmptyTransformation,
  TransformationConfigOptions,
  TransformationParams,
  ValidationErrors,
} from './types';
import { isNonEmptyString, validateFailure } from './transformSection/utils';
import { SelectOption } from '@ardoq/select';

// ordered conditions
export const ALL_FILTER_CONDITIONS: FilterCondition[] = [
  { operator: 'empty' },
  { operator: 'not-empty' },
  { operator: 'equals', value: '' },
  { operator: 'not-equals', value: '' },
  { operator: 'contains', value: '' },
  { operator: 'not-contains', value: '' },
  { operator: 'starts-with', value: '' },
  { operator: 'not-starts-with', value: '' },
];

export const FILTER_LABEL: Record<FilterCondition['operator'], string> = {
  empty: 'Is empty',
  'not-empty': 'Is not empty',
  equals: 'Is equal to',
  'not-equals': 'Is not equal to',
  contains: 'Contains',
  'not-contains': 'Does not contain',
  'starts-with': 'Starts with',
  'not-starts-with': 'Does not start with',
};

export const isConditionWithValue = (
  condition: FilterCondition | EmptyFilterCondition
): condition is FilterConditionForValue => {
  switch (condition.operator) {
    case 'empty':
    case 'not-empty':
    case null:
      return false;
    case 'contains':
    case 'not-contains':
    case 'equals':
    case 'not-equals':
    case 'starts-with':
    case 'not-starts-with':
      return true;
  }
};

export const isFilterCondition = (
  condition: FilterCondition | EmptyFilterCondition
): condition is FilterConditionForValue => Boolean(condition.operator);

export const isTransformation = (
  transformation: Transformation | EmptyTransformation
): transformation is Transformation => Boolean(transformation.operation);

export const isTransformationColumn = (
  transformationColumn:
    | TransformationColumn
    | (Omit<TransformationColumn, 'transformations'> & {
        transformations: (Transformation | EmptyTransformation)[];
      })
): transformationColumn is TransformationColumn =>
  transformationColumn.transformations.every(isTransformation);

export const isTransformationColumnValid = (
  transformationColumn: Omit<TransformationColumn, 'transformations'> & {
    transformations: (Transformation | EmptyTransformation)[];
  },
  {
    integrationId,
    setTransformation = () => null,
    validationErrors = {
      dictionary: {},
      path: false,
      defaultValue: false,
    },
    setValidationErrors = () => null,
    setLabel = () => null,
  }: {
    integrationId: IntegrationId;
    setTransformation?: (transformation: Transformation) => void;
    validationErrors?: ValidationErrors;
    setValidationErrors?: React.Dispatch<
      React.SetStateAction<ValidationErrors>
    >;
    setLabel?: React.Dispatch<React.SetStateAction<string | undefined>>;
  }
): boolean => {
  const { sourceFieldName, transformations } = transformationColumn;

  if (!isNonEmptyString(sourceFieldName)) {
    return false;
  }

  return transformations.every(transformation => {
    if (!validateFailure(transformation.config.failure)) {
      return false;
    }

    return getTransformationData({
      transformation,
      integrationId,
      setTransformation,
      validationErrors,
      setValidationErrors,
      setLabel,
    })?.isValid;
  });
};

const isFilterDisabled = (
  conditionsList: Array<FilterCondition | EmptyFilterCondition>,
  columnFilters: FilterCondition[],
  conditions: FilterConditionForValue[]
): boolean =>
  conditionsList.some(condition => !condition.operator) ||
  isEqual(columnFilters, conditionsList) ||
  conditions.some(
    condition => isConditionWithValue(condition) && !condition.value.trim()
  );

const isTransformationDisabled = (
  transformations: Transformations,
  columnTransformations: Omit<TransformationColumn, 'transformations'> & {
    transformations: (Transformation | EmptyTransformation)[];
  },
  sourceFieldName: string,
  {
    integrationId,
  }: {
    integrationId: IntegrationId;
  }
): boolean =>
  !isTransformationColumnValid(columnTransformations, {
    integrationId,
  }) ||
  isEqual(
    transformations.column?.find(
      column => column.sourceFieldName === sourceFieldName
    ),
    columnTransformations
  );

export const isSaveButtonDisabled = (
  columnTransformations: Omit<TransformationColumn, 'transformations'> & {
    transformations: (Transformation | EmptyTransformation)[];
  },
  transformations: Transformations,
  conditionsList: Array<FilterCondition | EmptyFilterCondition>,
  columnFilters: FilterCondition[],
  conditions: FilterConditionForValue[],
  sourceFieldName: string,
  {
    integrationId,
  }: {
    integrationId: IntegrationId;
  }
): boolean => {
  const hasFilters = conditionsList.length > 0;
  const hasTransformations = columnTransformations.transformations.length > 0;

  const filterDisabled = isFilterDisabled(
    conditionsList,
    columnFilters,
    conditions
  );
  const transformationDisabled = isTransformationDisabled(
    transformations,
    columnTransformations,
    sourceFieldName,
    {
      integrationId,
    }
  );

  if (!hasFilters && !hasTransformations) {
    return (
      isEqual(columnFilters, conditionsList) &&
      isEqual(
        transformations.column?.find(
          column => column.sourceFieldName === sourceFieldName
        ),
        columnTransformations
      )
    );
  }

  if (hasFilters && !hasTransformations) {
    return (
      filterDisabled &&
      isEqual(
        transformations.column?.find(
          column => column.sourceFieldName === sourceFieldName
        ),
        columnTransformations
      )
    );
  }

  if (!hasFilters && hasTransformations) {
    return transformationDisabled && isEqual(columnFilters, conditionsList);
  }

  if (hasFilters && hasTransformations) {
    const equalFilters = isEqual(columnFilters, conditionsList);
    const equalTransformations = isEqual(
      transformations.column?.find(
        column => column.sourceFieldName === sourceFieldName
      ),
      columnTransformations
    );

    return (
      (equalFilters &&
        equalTransformations &&
        (filterDisabled || transformationDisabled)) ||
      (!equalFilters && filterDisabled) ||
      (!equalTransformations && transformationDisabled) ||
      (!equalFilters &&
        !equalTransformations &&
        (filterDisabled || transformationDisabled))
    );
  }

  return false;
};

export const isClearButtonDisabled = (
  columnTransformations: Omit<TransformationColumn, 'transformations'> & {
    transformations: (Transformation | EmptyTransformation)[];
  },
  conditionsList: Array<FilterCondition | EmptyFilterCondition>
): boolean => {
  const hasFilters = conditionsList.length > 0;
  const hasTransformations = columnTransformations.transformations.length > 0;

  return !hasFilters && !hasTransformations;
};

export const getOperationOptionValue = (operation: TransformationOperation) =>
  // arithmetic_expression is a special case, as it has multiple operations,
  // so all arithmetic expressions should return 'arithmetic_expression' as the option value.
  ['multiply', 'divide', 'add', 'subtract'].includes(operation)
    ? TransformationOperation.MULTIPLY
    : operation;

export const getTransformationData = (
  {
    transformation,
    integrationId,
    setTransformation,
    validationErrors,
    setValidationErrors,
    setLabel = () => undefined,
  }: TransformationParams,
  {
    defaultValue = '',
    failureStrategy = 'use_existing',
    defaultColumn = { label: '', value: '' },
    availableColumns = [],
  }: TransformationConfigOptions = {}
) => {
  if (isTransformation(transformation)) {
    const operation = transformation.operation;
    const components = transformationComponents[
      operation
    ] as TransformationHandler<typeof operation>;

    if (components) {
      return components(
        {
          transformation,
          integrationId,
          setTransformation,
          validationErrors,
          setValidationErrors,
          setLabel,
        },
        defaultValue,
        failureStrategy,
        defaultColumn,
        availableColumns
      );
    }
  }
};

export const getColumnOptions = (
  columnIndex: number,
  columnHeaders: string[],
  sourceFieldNames: string[]
): [selected: SelectOption<string>, available: SelectOption<string>[]] => {
  const selectedColumnOption: SelectOption<string> = {
    label: columnHeaders[columnIndex],
    value: sourceFieldNames[columnIndex],
  };

  const availableColumnOptions: SelectOption<string>[] = columnHeaders
    .map((label, index) => ({
      label,
      value: sourceFieldNames[index],
    }))
    .filter((_, index) => index !== columnIndex);

  return [selectedColumnOption, availableColumnOptions];
};
