import { reducer } from '@ardoq/rxbeach';
import {
  fetchPreviewComponentsError,
  openComponentPreviewDialog,
  setPreviewComponentsAndTotal,
} from './actions';
import type { ComponentPreviewDialogStreamShape } from './types';
import { getDefaultState, withHasMoreSpecialItem } from './utils';
import { ComponentPreviewResponse } from '@ardoq/api-types';

const handleFetchPreviewComponentsError = (
  state: ComponentPreviewDialogStreamShape
): ComponentPreviewDialogStreamShape => ({
  ...state,
  isLoading: false,
  hasError: true,
  components: [],
  total: 0,
});
const handleSetPreviewComponentsAndTotal = (
  state: ComponentPreviewDialogStreamShape,
  { components, total }: ComponentPreviewResponse
) => {
  const hasMore = total > components.length;
  return {
    ...state,
    isLoading: false,
    hasError: false,
    components: hasMore
      ? withHasMoreSpecialItem(components, total)
      : components,
    total,
  };
};

export const reducers = [
  reducer(openComponentPreviewDialog, getDefaultState),
  reducer(fetchPreviewComponentsError, handleFetchPreviewComponentsError),
  reducer(setPreviewComponentsAndTotal, handleSetPreviewComponentsAndTotal),
];
