import { OpenInNewIcon } from '@ardoq/icons';
import { GhostButton } from '@ardoq/button';
import BroadcastContextMenu from 'surveyAdmin/SurveyEditor/LayoutElements/BroadcastContextMenu';
import { SurveyEditorHeaderHelper } from '../atoms';
import { Switch } from '@ardoq/forms';
import { FlexBox } from '@ardoq/layout';

type SurveyEditorHeaderRightProps = {
  canPublish: boolean;
  isPublished: boolean;
  onOpenSurvey: () => void;
  onPublish: () => void;
  surveyId?: string;
  hasError: boolean;
  notEnoughSelectedWorkspacePermission?: boolean;
};

const SurveyEditorHeaderRight = ({
  canPublish,
  isPublished,
  onOpenSurvey,
  onPublish,
  surveyId,
  hasError,
  notEnoughSelectedWorkspacePermission,
}: SurveyEditorHeaderRightProps) => {
  return (
    <FlexBox width="full">
      <SurveyEditorHeaderHelper>
        {canPublish && (
          <Switch
            label="LIVE"
            name="publish"
            isChecked={isPublished}
            onChange={onPublish}
            isDisabled={
              (hasError || notEnoughSelectedWorkspacePermission) && !isPublished
            }
          />
        )}
        <GhostButton
          isDisabled={!canPublish || hasError}
          onClick={onOpenSurvey}
          dataTestId="open-survey-button"
        >
          Open survey
          <OpenInNewIcon />
        </GhostButton>

        {canPublish && (
          <BroadcastContextMenu surveyId={surveyId} isDisabled={!isPublished} />
        )}
      </SurveyEditorHeaderHelper>
    </FlexBox>
  );
};

export default SurveyEditorHeaderRight;
