import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';

const isEndDateEnabled$ = persistentReducedStream(
  'isEndDateEnabled$',
  { isEndDateEnabled: false },
  reducers
);

export default isEndDateEnabled$;
