import { ArdoqId, LabeledValue } from '@ardoq/api-types';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';

export enum ProtectedSelectionPlaceholder {
  HIDDEN_WORKSPACE = '[Workspace Name Hidden]',
  HIDDEN_COMPONENT = '[Component Name Hidden]',
  HIDDEN_REFERENCE_TYPE = '[Reference Type Hidden]',
}

const EMPTY_OPTION: LabeledValue<string> = { label: '', value: '' };

export const getMaybeProtectSelectedOption = (workspaceId?: ArdoqId | null) => {
  const cannotAccessWorkspace =
    !workspaceId ||
    !workspaceAccessControlInterface.canAccessWorkspace(
      currentUserInterface.getPermissionContext(),
      workspaceId
    );
  return (
    accessibleOptions: LabeledValue<string>[],
    selectedOption: string | null | undefined,
    placeHolder: ProtectedSelectionPlaceholder
  ): LabeledValue<string> => {
    if (!selectedOption) return EMPTY_OPTION;
    if (cannotAccessWorkspace)
      return {
        label: placeHolder,
        value: selectedOption,
      };
    return (
      accessibleOptions.find(({ value }) => value === selectedOption) ||
      EMPTY_OPTION
    );
  };
};
