import { BooleanOperator } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { GraphId, ViewpointBuilderFilter } from '../types';

export type AddDefaultFilterPayload = {
  graphNodeId: GraphId;
};

export const addDefaultFilter = actionCreator<AddDefaultFilterPayload>(
  '[ViewpointBuilderFilters] ADD_DEFAULT_FILTER'
);

export type ChangeFilterConditionPayload = {
  graphNodeId: GraphId;
  condition: BooleanOperator;
};

export const changeFilterCondition =
  actionCreator<ChangeFilterConditionPayload>(
    '[ViewpointBuilderFilters] CHANGE_FILTER_CONDITION'
  );

export type UpdateFilterPayload = {
  graphNodeId: GraphId;
  nonPersistentId: string;
  partialFilter: Partial<ViewpointBuilderFilter>;
};
export const updateFilter = actionCreator<UpdateFilterPayload>(
  '[ViewpointBuilderFilters] UPDATE_FILTER'
);

export type DeleteFilterPayload = {
  graphNodeId: GraphId;
  nonPersistentId: string;
};
export const deleteFilter = actionCreator<DeleteFilterPayload>(
  '[ViewpointBuilderFilters] DELETE_FILTER'
);
