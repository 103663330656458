import {
  BooleanOperator,
  Named,
  SearchResult,
  Operator,
  QueryBuilderQuery,
  SearchContextRule,
} from '@ardoq/api-types';
import { Maybe } from '@ardoq/common-helpers';
import { get, set } from 'lodash/fp';
import { QueryResults, Status } from '../streams/types';

export const contextRule = {
  field: 'type',
  id: 'type',
  input: 'select',
  operator: Operator.EQUAL,
  type: 'string',
  value: 'component',
} as SearchContextRule;

export const rootWorkspaceRule = (workspace: Maybe<string>) => ({
  field: 'rootWorkspace',
  id: 'rootWorkspace',
  input: 'text',
  operator: Operator.EQUAL,
  type: 'string',
  value: workspace,
});

export function addWorkspaceRuleToQuery(
  workspace: Maybe<string>,
  query?: QueryBuilderQuery
): QueryBuilderQuery {
  if (!query) {
    return {
      condition: BooleanOperator.AND,
      rules: [
        contextRule,
        {
          condition: BooleanOperator.AND,
          rules: [rootWorkspaceRule(workspace)],
        },
      ],
    };
  } else if (
    // The first rule would be context at index 0
    // The second rule would be a sub query.
    // We're adding a new root node to that subquery for workspace
    get('rules[1].condition', query)
  ) {
    return set(
      'rules[1]',
      {
        condition: BooleanOperator.AND,
        rules: [
          rootWorkspaceRule(workspace),
          {
            condition: get('rules[1].condition', query),
            rules: get('rules[1].rules', query),
          },
        ],
      },
      query
    );
  }
  return query;
}

// We'll only use this function when running the query to get the count result
// Because when we save the enrichment it will automatically add the component type
export function addComponentTypeToQuery(
  componentType: string,
  query: QueryBuilderQuery
): QueryBuilderQuery {
  return {
    ...query,
    rules: [
      query.rules[0],
      {
        condition: query.rules[1].condition,
        rules: [
          ...query.rules[1].rules,
          {
            id: 'typeName',
            field: 'typeName',
            input: 'text',
            type: 'string',
            operator: Operator.EQUAL,
            value: componentType,
          },
        ],
      },
    ],
  };
}

export function removeWorkspaceRuleToQuery(query?: QueryBuilderQuery) {
  if (!query) {
    return;
  } else if (
    // The first rule would be context at index 0
    // The second rule would be a sub query.
    // We're removing the root workspace query from the subquery
    get('rules[1].rules[0].id', query) === 'rootWorkspace'
  ) {
    // When users have their own queries they will be at rules[1].rules[1]
    if (get('rules[1].rules[1]', query)) {
      return set('rules[1]', get('rules[1].rules[1]', query), query);
    }
    // When there's no user queries we don't need the query because it the query checkbox is hidden
    return;
  }
  return;
}

export function queryRespToResults(
  resp: SearchResult<Named>,
  status: Status,
  message?: string
): QueryResults {
  return {
    status,
    message,
    total: resp.total,
    components: resp.results?.map(result => result?.doc?.name),
  };
}
