import { APISlideAttributes, ArdoqId, ResourceType } from '@ardoq/api-types';
import {
  reducedStream,
  reducer,
  streamReducer,
  toPersistentStream,
} from '@ardoq/rxbeach';
import { fetchAllReducer, websocketReducer } from 'streams/crud/reducers';
import { toResourceTypeStream, websocket$ } from 'sync/websocket$';
import { slideNamespace } from './actions';
import { loadSlides } from 'presentation/slideNavigator/actions';
import {
  emptyCollectionStream,
  toCollectionStream,
} from 'streams/utils/streamUtils';
import { map } from 'rxjs';

export type SlidesState = APISlideAttributes[];

const resetSlides = (
  _: SlidesState,
  slides: APISlideAttributes[]
): SlidesState => slides;

const deleteSlide = (state: SlidesState, id: ArdoqId): SlidesState =>
  state.filter(m => m._id !== id);

const updateSlide = (
  state: SlidesState,
  slide: APISlideAttributes
): SlidesState => {
  const newModels = state.map(existingSlide =>
    existingSlide._id === slide._id ? slide : existingSlide
  );

  return resetSlides(state, newModels);
};

const createSlide = (
  state: SlidesState,
  slide: APISlideAttributes
): SlidesState => resetSlides(state, [slide, ...state]);

const slides$ = reducedStream<SlidesState>(
  'slidesonly$',
  [],
  [
    fetchAllReducer(resetSlides),
    reducer(loadSlides, resetSlides),
    streamReducer(
      toResourceTypeStream<APISlideAttributes>(websocket$, ResourceType.SLIDE),
      websocketReducer({
        create: createSlide,
        update: updateSlide,
        delete: deleteSlide,
      })
    ),
  ],
  { namespace: slideNamespace }
);

export default toPersistentStream(
  'slides$',
  slides$.pipe(map(toCollectionStream)),
  emptyCollectionStream()
);
