import { navigateToManageOrganization } from '../../router/navigationActions';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { filter, tap } from 'rxjs';
import { OrgTabs } from '../manageOrganization/navigation/types';
import {
  getReportDatasourceMigrationData,
  setActiveMigration,
  setPresentationMetadata,
  setReportDatasourceMigrationData,
} from './actions';
import {
  MigrationChecklistState,
  PresentationMetadata,
  ViewToMigrate,
} from './types';
import {
  extractRulesWithContainsPhrase,
  getIsReportThatNeedsMigration,
  getPresentationMetadata,
  mapRuleToString,
} from './utils';
import { withLatestFrom } from 'rxjs/operators';
import migrationChecklist$ from './migrationChecklist$';
import { REPORT_DATASOURCE_MIGRATION_ID } from './ReportDatasourceChecklistContent';
import { trackOpenedMigrationChecklist } from './tracking';
import reports$ from 'streams/reports/reports$';
import globalFields$ from '../../globalFields/globalFields$';
import { DEFAULT_FIELDS } from '../../streams/fields/defaultFields';
import { migrationChecklistApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import { presentationOperations } from 'streams/presentations/presentationOperations';
import presentations$ from 'streams/presentations/presentations$';

const mapPresentationMetadataToViewIds = (
  presentationMetadata: PresentationMetadata,
  presentationDataForViewId: MigrationChecklistState['presentationMetadata']
) =>
  Object.entries(presentationMetadata.viewIdCount)
    .filter(([_, count]) => Boolean(count))
    .reduce<MigrationChecklistState['presentationMetadata']>(
      (acc, [viewId]) => ({
        ...acc,
        [viewId]: [
          ...(presentationDataForViewId[viewId as ViewToMigrate] ?? []),
          presentationMetadata,
        ],
      }),
      {}
    );

const handleNavigateToManageOrganization = routine(
  ofType(navigateToManageOrganization),
  extractPayload(),
  filter(({ manageOrgTab }) => manageOrgTab === OrgTabs.MIGRATION_CHECKLIST),
  tap(trackOpenedMigrationChecklist),
  withLatestFrom(presentations$),
  tap(([, presentations]) =>
    dispatchAction(
      setPresentationMetadata(
        presentationOperations
          .getPresentationsList(presentations)
          .reduce<MigrationChecklistState['presentationMetadata']>(
            (presentationDataForViewId, presentationId) => ({
              ...presentationDataForViewId,
              ...mapPresentationMetadataToViewIds(
                getPresentationMetadata(presentationId),
                presentationDataForViewId
              ),
            }),
            {}
          )
      )
    )
  ),
  withLatestFrom(migrationChecklist$),
  filter(
    ([, { activeMigrationId }]) =>
      activeMigrationId === REPORT_DATASOURCE_MIGRATION_ID
  ),
  tap(() => dispatchAction(getReportDatasourceMigrationData()))
);

const handleSetActiveMigration = routine(
  ofType(setActiveMigration),
  extractPayload(),
  filter(
    activeMigrationId => activeMigrationId === REPORT_DATASOURCE_MIGRATION_ID
  ),
  tap(() => dispatchAction(getReportDatasourceMigrationData()))
);

const handleGetReportDatasourceMigrationData = routine(
  ofType(getReportDatasourceMigrationData),
  withLatestFrom(reports$, globalFields$),
  tap(async ([, { byId: reportsById }, { globalFields }]) => {
    const reportDatasourceMigrationData =
      await migrationChecklistApi.fetchAdvancedSearch();
    if (isArdoqError(reportDatasourceMigrationData)) {
      logError(reportDatasourceMigrationData);
      return;
    }
    const fieldLabelByName = {
      ...globalFields.reduce<Record<string, string>>(
        (acc, field) => ({ ...acc, [field.name]: field.label }),
        {}
      ),
      ...DEFAULT_FIELDS.reduce<Record<string, string>>(
        (acc, field) => ({ ...acc, [field.name]: field.label }),
        {}
      ),
    };
    const migrationData = reportDatasourceMigrationData.rows
      .filter(getIsReportThatNeedsMigration(reportsById))
      .map(migrationDataRow => ({
        ...migrationDataRow,
        invalidQueries: extractRulesWithContainsPhrase(
          migrationDataRow.query
        ).map(rule => mapRuleToString(rule, fieldLabelByName)),
      }));
    dispatchAction(setReportDatasourceMigrationData(migrationData));
  })
);

export default collectRoutines(
  handleNavigateToManageOrganization,
  handleSetActiveMigration,
  handleGetReportDatasourceMigrationData
);
