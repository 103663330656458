import { Paragraph } from '@ardoq/typography';
import { NUM_DATA_ROWS } from 'integrations/common/streams/tablePreviews/constants';

export const PreviewingRows = ({ rowCnt }: { rowCnt: number }) => {
  return (
    <Paragraph variant="caption" color="textSubtle">
      Previewing {NUM_DATA_ROWS} of {rowCnt} rows
    </Paragraph>
  );
};
