import { DetailsColumn } from './atoms';
import { UserProfilePageProps } from '../../../types';
import { PrivilegesList } from './PrivilegesList';
import { privilegesOperations } from 'privileges/privilegesOperations';
import { FlexBox } from '@ardoq/layout';
import { LabeledDetail } from 'admin/accessControl/UserProfilePage/components/LabeledDetail';

export type RoleRelatedPrivilegesProps = UserProfilePageProps;

export const RoleRelatedPrivileges = ({
  userPrivileges,
}: RoleRelatedPrivilegesProps) => {
  const rolePrivileges =
    privilegesOperations.getRoleBasedPrivileges(userPrivileges);
  return (
    <DetailsColumn $flexGrow={2.3}>
      <FlexBox width="full">
        <LabeledDetail label="Role privileges">
          <PrivilegesList
            privileges={rolePrivileges}
            emptyStateText="No role privileges assigned to this user."
            context="role-privileges"
          />
        </LabeledDetail>
      </FlexBox>
    </DetailsColumn>
  );
};
