import { omit, pick } from 'lodash';
import { ViewIds, ViewpointSavedViewSettings } from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';
import { TrackingPlaceInApp } from 'tracking/types';
import { IGNORED_SETTINGS_BY_VIEW_ID } from 'viewSettings/tracking';

export type SaveViewpointTrackingData = {
  numberOfLabels: number;
  numberOfComponents: number;
  numberOfFilters: number;
  saved: string;
  viewStyle: string;
  numberOfGroups: number;
  numberOfFormattingRules: number;
  numberOfReferences: number;
  viewSettings: ViewpointSavedViewSettings;
  viewId: ViewIds;
};

const trackSavingViewpoint = (trackingData: SaveViewpointTrackingData) => {
  const viewSettingsToOmit =
    IGNORED_SETTINGS_BY_VIEW_ID.get(trackingData.viewId) || [];

  const trackedViewSettings = omit(
    trackingData.viewSettings,
    viewSettingsToOmit
  );

  const metadata = {
    ...pick(trackingData, [
      'numberOfComponents',
      'numberOfReferences',
      'numberOfFilters',
      'numberOfGroups',
      'numberOfFormattingRules',
      'numberOfLabels',
      'saved',
      'viewStyle',
    ]),
    viewSettings: trackedViewSettings,
  };

  trackEvent(
    `${TrackingPlaceInApp.SAVE_AS_VIEWPOINT_PANEL} Saved viewpoint`,
    metadata
  );
};
export { trackSavingViewpoint };
