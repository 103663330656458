import {
  ArdoqId,
  APIResetRequest,
  APIScenarioScopeData,
} from '@ardoq/api-types';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { splitDateRangeFields } from 'scope/merge/fieldSplitUtils';
import { EnhancedDiffContextData } from 'components/DiffMergeTable/EnhancedDiffContextDataType';
import { fixDiffContext, fixScopeData } from 'scopeData/utils/fixScopeData';
import { ApiResponse, scenarioApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';

export const loadAndFixScenario = async (
  scenarioId: ArdoqId
): ApiResponse<APIScenarioScopeData> => {
  const result = await scenarioApi.load(scenarioId);
  if (isArdoqError(result)) {
    return result;
  }
  return fixScopeData(result, scenarioId);
};

export const loadAndFixDiffContext = async (scenarioId: ArdoqId) => {
  const result = await scenarioApi.diffContext(scenarioId);
  if (isArdoqError(result)) {
    return result;
  }
  return { ...result, response: fixDiffContext(result, scenarioId) };
};

export const apiResetScenario = (
  scenarioId: ArdoqId,
  mergeDirection: MergeDirection,
  enhancedDiffContextData: EnhancedDiffContextData,
  payload: APIResetRequest
) => {
  const { branchId } = scenarioInterface.getScopeAndBranchId(scenarioId);
  return scenarioApi.reset(
    scenarioId,
    splitDateRangeFields(payload, enhancedDiffContextData),
    mergeDirection === MergeDirection.MAINLINE_TO_BRANCH ? branchId! : undefined
  );
};

export const copyScenario = async ({
  scenarioId,
  scenarioCopyName,
}: {
  scenarioId: ArdoqId;
  scenarioCopyName: string;
}) => await scenarioApi.copy(scenarioId, scenarioCopyName);

export const updateBranchOffPoint = async (scenarioId: ArdoqId) =>
  await scenarioApi.updateBranchOff(scenarioId);
