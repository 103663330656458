import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

const DOMAIN = '[permissionGroups]';

type RemoveUsersPayload = {
  permissionGroupId: ArdoqId;
  userIds: ArdoqId[];
  force?: boolean;
};

export const removeUsersFromGroup = actionCreator<RemoveUsersPayload>(
  `${DOMAIN} PERMISSION_GROUP_REMOVE_USERS`
);

export const removeUsersFromGroupSuccess = actionCreator(
  `${DOMAIN} PERMISSION_GROUP_REMOVE_USERS_SUCCESS`
);

export const removeUsersFromGroupFailed = actionCreator<string>(
  `${DOMAIN} PERMISSION_GROUP_REMOVE_USERS_FAILED`
);

export const removeUsersFromGroupConfirmationModalOpened = actionCreator(
  `${DOMAIN} PERMISSION_GROUP_REMOVE_USERS_CONFIRMATION_MODAL_OPENED`
);
