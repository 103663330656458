import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { requestLoadMetaModel } from 'architectureModel/actions';
import { metaModelOperations } from 'architectureModel/metaModelOperations';
import { tap, withLatestFrom } from 'rxjs';
import { setTraversalId } from './actions';
import traversals$ from 'streams/traversals$';
import { traversalStateOperations } from 'streams/traversals/traversalsStateOperations';

const handleSetTraversalPreviewId = routine(
  ofType(setTraversalId),
  extractPayload(),
  withLatestFrom(traversals$),
  tap(([{ traversalId }, state]) => {
    const traversal = traversalId
      ? traversalStateOperations.getById(state, traversalId)
      : null;
    if (!traversal) return;
    dispatchAction(
      requestLoadMetaModel({
        currentTriples: metaModelOperations.getTriplesFromPaths(
          traversal.paths
        ),
      })
    );
  })
);

export const traversalPreviewRoutines = collectRoutines(
  handleSetTraversalPreviewId
);
