import { s16 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { FlexBox, Stack } from '@ardoq/layout';

export const Wrapper = styled(Stack).attrs({
  justify: 'space-between',
})`
  min-height: 300px;
`;

const Header = styled.span`
  font-size: larger;
  font-weight: 300;
`;

export const SectionHeader = styled(Header)`
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 5px;
`;

export const Section = styled.div`
  border-left: 1px solid lightGray;
  border-bottom: 1px solid lightGray;
  border-right: 1px solid lightGray;
`;

export const EntityItem = styled.div`
  margin-left: 10px;
  margin-bottom: 5px;
  margin-right: 10px;
`;

export const EntityWrapper = styled(FlexBox).attrs({
  justify: 'space-between',
  width: 'full',
})`
  padding: 5px;
`;

export const Info = styled(FlexBox).attrs({ align: 'center' })`
  /* Overwrite some default, global styles (Bootstrap ?) */
  label {
    margin: 0;
  }
`;

export const ClickableRow = styled(FlexBox)`
  cursor: pointer;
`;

export const Chevron = styled.i<{ $hidden: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${s16};
  visibility: ${props => (props.$hidden ? 'hidden' : 'visible')};
`;

export const ComponentInlineInfo = styled.span`
  padding-left: 15px;
  color: #626262;
  font-weight: 300;
`;

export const CenteredViewText = styled(Stack).attrs({ justify: 'center' })`
  font-size: 24px;
  font-weight: 300;
`;
