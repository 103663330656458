import Slide from './Slide';
import UnauthorizedSlide from './UnauthorizedSlide';
import { UnauthorizedSlideType } from '../types';
import { isUnauthorizedSlide } from '../util';
import { APISlideAttributes, ArdoqId } from '@ardoq/api-types';
import {
  DragAndDropContextState,
  DragAndDropSectionsProvider,
} from '@ardoq/drag-and-drop';
import styled from 'styled-components';
import { SidebarContentBody } from './atoms';
import { Box } from '@ardoq/layout';
import { Text } from '@ardoq/typography';

type SlideHandler = (slide: APISlideAttributes) => void;
export interface SlidesListProperties {
  slides: (APISlideAttributes | UnauthorizedSlideType)[];
  onSlideClick: SlideHandler;
  onEditClick: SlideHandler;
  onDeleteClick: SlideHandler;
  selectedSlideId: ArdoqId | null;
  editingSlideId: ArdoqId | null;
  canEdit: boolean;
  isPresentationPreview?: boolean;
  onReplaceSlideClick: SlideHandler;
  onSlideEditedAttributes: (
    value: string,
    attributeName: 'name' | 'description'
  ) => void;
  onDiscardChangesClick: SlideHandler;
  onSaveChangesClick: (slide?: APISlideAttributes) => void;
  viewIsSupported?: boolean;
  currentPresentationId: ArdoqId;
  onSortEnd: (
    newOrder: string[],
    dragAndDropState: DragAndDropContextState
  ) => void;
  editsToCurrentSlide?: Partial<Record<'name' | 'description', string>>;
}

const PresentationDragAndDropSectionsProvider = styled(
  DragAndDropSectionsProvider
)`
  overflow: auto;
  flex-grow: 1;
`;

export const SlidesList = ({
  slides,
  onSlideClick,
  onEditClick,
  onDeleteClick,
  selectedSlideId,
  editingSlideId,
  canEdit,
  isPresentationPreview,
  onReplaceSlideClick,
  onSlideEditedAttributes,
  onDiscardChangesClick,
  onSaveChangesClick,
  viewIsSupported,
  currentPresentationId,
  onSortEnd,
  editsToCurrentSlide,
}: SlidesListProperties) => {
  return (
    <SidebarContentBody>
      {slides.length ? (
        <PresentationDragAndDropSectionsProvider onDrop={onSortEnd}>
          {slides.map((slide, index) => {
            if (isUnauthorizedSlide(slide)) {
              return (
                <UnauthorizedSlide
                  key={`item-${index}`}
                  dragAndDropCardId={canEdit ? `${index}` : undefined}
                />
              );
            }
            return (
              <Slide
                key={slide._id}
                slide={slide}
                dragAndDropCardId={canEdit ? `${index}` : undefined}
                onSlideClick={onSlideClick}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                selected={slide._id === selectedSlideId}
                editing={slide._id === editingSlideId}
                canEdit={canEdit}
                isPresentationPreview={isPresentationPreview}
                onReplaceSlideClick={onReplaceSlideClick}
                onSlideEditedAttributes={onSlideEditedAttributes}
                onDiscardChangesClick={onDiscardChangesClick}
                onSaveChangesClick={onSaveChangesClick}
                viewIsSupported={viewIsSupported}
                currentPresentationId={currentPresentationId}
                editsToCurrentSlide={editsToCurrentSlide}
              />
            );
          })}
        </PresentationDragAndDropSectionsProvider>
      ) : (
        <Box padding="large">
          <Text>There are no slides yet, be the first to add one.</Text>
        </Box>
      )}
    </SidebarContentBody>
  );
};
