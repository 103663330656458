import { hasFeature } from '@ardoq/features';
import {
  SubdivisionEditorSteps,
  SubdivisionEditorSubStep,
} from './navigation/types';
import { STEPS } from './steps';
import { subdivisionEditorOperations } from './subdivisionEditorOperations';
import {
  StepStateText,
  StepsValidationErrorMap,
  SubdivisionViewModelState,
  ValidationErrors,
} from './types';
import { StepState as StepState } from '@ardoq/steppers';
import { StepConfig, SubStepConfig } from './Steps/types';

const { isCreationMode } = subdivisionEditorOperations;

const isStepFeatureEnabled = (step: SubStepConfig | StepConfig) => {
  return !step.behindFeatureFlag || hasFeature(step.behindFeatureFlag);
};

const resolveStepConfigIsHidden = (
  subdivisionEditorState: SubdivisionViewModelState,
  stepConfig: StepConfig | SubStepConfig | null
): boolean => {
  if (typeof stepConfig?.isHidden === 'function') {
    return stepConfig.isHidden(subdivisionEditorState);
  }
  return Boolean(stepConfig?.isHidden);
};

const isStepShown = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubStepConfig | StepConfig
) => {
  return (
    !resolveStepConfigIsHidden(subdivisionEditorState, step) &&
    isStepFeatureEnabled(step)
  );
};

const getSteps = (subdivisionEditorState: SubdivisionViewModelState) =>
  STEPS.filter(step => isStepShown(subdivisionEditorState, step));

const getFlatStaticSteps = () =>
  STEPS.flatMap(step => {
    const subSteps = Array.isArray(step.subSteps) ? step.subSteps : [];
    return [step, ...subSteps];
  }).filter(isStepFeatureEnabled);

const getSubSteps = (
  subSteps:
    | SubStepConfig[]
    | ((subdivisionEditorState: SubdivisionViewModelState) => SubStepConfig[])
    | undefined,
  subdivisionEditorState: SubdivisionViewModelState
): SubStepConfig[] | null => {
  let result: SubStepConfig[] | null = null;
  if (Array.isArray(subSteps)) {
    result = subSteps;
  }
  if (typeof subSteps === 'function') {
    result = subSteps(subdivisionEditorState);
  }
  return result ? result.filter(isStepFeatureEnabled) : null;
};

const getStepIndex = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps
) => {
  return getSteps(subdivisionEditorState).findIndex(s => s.step === step);
};

const getStepByIndex = (
  subdivisionEditorState: SubdivisionViewModelState,
  index: number
) => {
  const steps = getSteps(subdivisionEditorState);
  if (index < 0 || index >= steps.length) {
    return null;
  }
  return steps[index];
};

const getFlatStaticStepIndex = (step: SubdivisionEditorSteps) => {
  return getFlatStaticSteps().findIndex(s => s.step === step);
};

const getFlatStaticStepByIndex = (index: number) => {
  const steps = getFlatStaticSteps();
  if (index < 0 || index >= steps.length) {
    return null;
  }
  return steps[index];
};

const getStepConfigs = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps,
  subStep?: SubdivisionEditorSubStep
): StepConfig | SubStepConfig | null => {
  const index = getFlatStaticStepIndex(step);
  const stepConfig = getFlatStaticStepByIndex(index);

  if (!stepConfig) {
    return null;
  }
  if (!subStep) {
    return stepConfig;
  }
  return (
    getSubSteps(stepConfig?.subSteps, subdivisionEditorState)?.find(
      subStepConfig => subStepConfig.step === subStep
    ) || stepConfig
  );
};

const getNextStep = (
  subdivisionEditorState: SubdivisionViewModelState,
  subdivisionEditorStep: SubdivisionEditorSteps
): StepConfig | null => {
  const index = getStepIndex(subdivisionEditorState, subdivisionEditorStep);
  const newStep = getStepByIndex(subdivisionEditorState, index + 1);
  if (newStep === null) {
    return null;
  }
  if (stepIsDisabled(subdivisionEditorState, newStep.step)) {
    return getNextStep(subdivisionEditorState, newStep.step);
  }
  return newStep;
};

const getPreviousStep = (
  subdivisionEditorState: SubdivisionViewModelState,
  subdivisionEditorStep: SubdivisionEditorSteps
): StepConfig | null => {
  const index = getStepIndex(subdivisionEditorState, subdivisionEditorStep);
  const newStep = getStepByIndex(subdivisionEditorState, index - 1);
  if (newStep === null) {
    return null;
  }
  if (stepIsDisabled(subdivisionEditorState, newStep.step)) {
    return getPreviousStep(subdivisionEditorState, newStep.step);
  }
  return newStep;
};

const shouldShowPreviousButton = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  const stepConfig = getStepConfigs(
    subdivisionEditorState,
    subdivisionEditorState.subdivisionEditorStep
  );
  return (
    !stepConfig?.hidePreviousButton && !isFirstStep(subdivisionEditorState)
  );
};

const shouldShowNextButton = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  const stepConfig = getStepConfigs(
    subdivisionEditorState,
    subdivisionEditorState.subdivisionEditorStep
  );
  return !stepConfig?.hideNextButton || isLastStep(subdivisionEditorState);
};

/**
 * Returns true if the given step has a previous step
 */
const hasPreviousStep = (
  subdivisionEditorState: SubdivisionViewModelState,
  subdivisionEditorStep: SubdivisionEditorSteps
): boolean => {
  return !!getPreviousStep(subdivisionEditorState, subdivisionEditorStep);
};

/**
 * Returns true if the given step has a next step
 */
const hasNextStep = (
  subdivisionEditorState: SubdivisionViewModelState,
  subdivisionEditorStep: SubdivisionEditorSteps
): boolean => {
  return !!getNextStep(subdivisionEditorState, subdivisionEditorStep);
};

/**
 * Returns true if the given step has a next step and the next step is not disabled
 */
const canGoNextStep = (
  subdivisionEditorState: SubdivisionViewModelState,
  subdivisionEditorStep: SubdivisionEditorSteps
): boolean => {
  if (isCreationMode(subdivisionEditorState)) {
    return false;
  }
  const isLastStep = subdivisionEditorStepsOperations.isLastStep(
    subdivisionEditorState
  );

  if (isLastStep) {
    return (
      canSaveChanges(subdivisionEditorState) ||
      !subdivisionEditorOperations.hasUnsavedChanges(subdivisionEditorState)
    );
  }
  const currentHasNextStep = hasNextStep(
    subdivisionEditorState,
    subdivisionEditorStep
  );
  return currentHasNextStep;
};

const stepIsDisabled = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps,
  subStep?: SubdivisionEditorSubStep
): boolean => {
  const stepConfig = getStepConfigs(subdivisionEditorState, step, subStep);
  return resolveStepConfigIsDisabled(stepConfig, subdivisionEditorState);
};

const stepIsExpanded = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps
): boolean => {
  return !(subdivisionEditorState.collapsed[step] ?? true);
};

const getStepValidationErrors = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps,
  subStep?: SubdivisionEditorSubStep
): ValidationErrors => {
  const stepConfigs = getStepConfigs(subdivisionEditorState, step, subStep);
  if (!stepConfigs?.stepValidation) {
    return {};
  }
  const errors = stepConfigs.stepValidation(subdivisionEditorState);

  if (!errors || Object.keys(errors).length === 0) {
    return {};
  }
  return errors;
};

const isValidStep = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps,
  subStep?: SubdivisionEditorSubStep
): boolean => {
  let stepError;

  if (subStep) {
    stepError =
      subdivisionEditorState.stepsErrors[subStep] ??
      getStepValidationErrors(subdivisionEditorState, step, subStep);
  } else {
    stepError =
      subdivisionEditorState.stepsErrors[step] ??
      getStepValidationErrors(subdivisionEditorState, step);
  }

  return Object.keys(stepError).length === 0;
};

const getAllStepValidationErrors = (
  subdivisionEditorState: SubdivisionViewModelState
): StepsValidationErrorMap => {
  const errors: StepsValidationErrorMap =
    subdivisionEditorOperations.initializeEmptyStepsErrors();

  getSteps(subdivisionEditorState).forEach(step => {
    if (!stepIsTouched(subdivisionEditorState, step.step)) {
      return;
    }
    const stepErrors = getStepValidationErrors(
      subdivisionEditorState,
      step.step
    );
    if (stepErrors) {
      errors[step.step] = stepErrors;
    }

    getSubSteps(step.subSteps, subdivisionEditorState)?.forEach(subStep => {
      errors[subStep.step] = getStepValidationErrors(
        subdivisionEditorState,
        step.step,
        subStep.step
      );
    });
  });

  return errors;
};

const hasValidationErrors = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  return (
    getSteps(subdivisionEditorState).filter(
      step => !isValidStep(subdivisionEditorState, step.step)
    ).length > 0
  );
};

const canSaveChanges = (
  subdivisionEditorState: SubdivisionViewModelState
): boolean => {
  return (
    !hasValidationErrors(subdivisionEditorState) &&
    subdivisionEditorOperations.hasUnsavedChanges(subdivisionEditorState)
  );
};

const setStepTouched = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps
): SubdivisionViewModelState => {
  return {
    ...subdivisionEditorState,
    touchedSteps: {
      ...subdivisionEditorState.touchedSteps,
      [step]: true,
    },
  };
};

const setCollapsedStep = (
  subdivisionEditorState: SubdivisionViewModelState,
  { collapsed, step }: { step: SubdivisionEditorSteps; collapsed: boolean }
): SubdivisionViewModelState => {
  return {
    ...subdivisionEditorState,
    collapsed: {
      ...subdivisionEditorState.collapsed,
      [step]: collapsed,
    },
  };
};

const stepIsTouched = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps
): boolean => {
  return !!subdivisionEditorState.touchedSteps[step];
};

const resolveStepConfigIsDisabled = (
  stepConfig: StepConfig | SubStepConfig | null,
  subdivisionEditorState: SubdivisionViewModelState
): boolean => {
  if (typeof stepConfig?.isDisabled !== 'function') {
    return Boolean(stepConfig?.isDisabled);
  }
  return stepConfig.isDisabled(subdivisionEditorState);
};

const getNextStepButtonLabel = (
  subdivisionEditorState: SubdivisionViewModelState
): string => {
  const { subdivisionEditorStep } = subdivisionEditorState;
  if (isLastStep(subdivisionEditorState)) {
    return 'Go to Overview';
  }
  const stepConfig = getStepConfigs(
    subdivisionEditorState,
    subdivisionEditorStep
  );
  const nextStep = getNextStep(subdivisionEditorState, subdivisionEditorStep);
  const defaultNextLabel = `Next: ${nextStep?.title ?? ''}`;
  if (!stepConfig?.nextButtonLabel) {
    return defaultNextLabel;
  }
  if (typeof stepConfig.nextButtonLabel === 'function') {
    return (
      stepConfig.nextButtonLabel(subdivisionEditorState) ?? defaultNextLabel
    );
  }
  return stepConfig.nextButtonLabel ?? defaultNextLabel;
};

const getPreviousStepButtonLabel = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  const { subdivisionEditorStep } = subdivisionEditorState;
  const stepConfig = getStepConfigs(
    subdivisionEditorState,
    subdivisionEditorStep
  );
  const previousStep = getPreviousStep(
    subdivisionEditorState,
    subdivisionEditorStep
  );
  const defaultPreviousLabel = `Back: ${previousStep?.title ?? ''}`;
  if (!stepConfig?.previousButtonLabel) {
    return defaultPreviousLabel;
  }
  if (typeof stepConfig.previousButtonLabel === 'function') {
    return (
      stepConfig.previousButtonLabel(subdivisionEditorState) ??
      defaultPreviousLabel
    );
  }
  return stepConfig.previousButtonLabel ?? defaultPreviousLabel;
};

const nextStepIsDisabled = (
  subdivisionEditorState: SubdivisionViewModelState
): boolean => {
  const { subdivisionEditorStep, isSubmitting } = subdivisionEditorState;
  const stepConfig = getStepConfigs(
    subdivisionEditorState,
    subdivisionEditorStep
  );

  if (stepConfig?.nextButtonIsDisabled) {
    if (typeof stepConfig.nextButtonIsDisabled === 'function') {
      return stepConfig.nextButtonIsDisabled(subdivisionEditorState);
    }
    return Boolean(stepConfig.nextButtonIsDisabled);
  }

  return (
    !canGoNextStep(subdivisionEditorState, subdivisionEditorStep) ||
    isSubmitting
  );
};

const previousStepIsDisabled = (
  subdivisionEditorState: SubdivisionViewModelState
): boolean => {
  const { subdivisionEditorStep, isSubmitting } = subdivisionEditorState;
  const currentHasPreviousStep = hasPreviousStep(
    subdivisionEditorState,
    subdivisionEditorStep
  );

  return (
    !currentHasPreviousStep ||
    isSubmitting ||
    subdivisionEditorStepsOperations.stepIsDisabled(
      subdivisionEditorState,
      subdivisionEditorStep
    )
  );
};

const getOnNextButtonClicked = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  const stepConfig = getStepConfigs(
    subdivisionEditorState,
    subdivisionEditorState.subdivisionEditorStep
  );
  if (!stepConfig?.onNextButtonClicked) {
    return null;
  }
  return stepConfig.onNextButtonClicked;
};

const getOnPreviousButtonClicked = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  const stepConfig = getStepConfigs(
    subdivisionEditorState,
    subdivisionEditorState.subdivisionEditorStep
  );
  if (!stepConfig?.onPreviousButtonClicked) {
    return null;
  }
  return stepConfig.onPreviousButtonClicked;
};

const getStepSubtitle = (subdivisionEditorState: SubdivisionViewModelState) => {
  const stepConfig = getStepConfigs(
    subdivisionEditorState,
    subdivisionEditorState.subdivisionEditorStep
  );
  if (typeof stepConfig?.subtitle === 'function') {
    return stepConfig.subtitle(subdivisionEditorState);
  }
  return stepConfig?.subtitle;
};

const isStepConfigured = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps
) => {
  const stepConfig = subdivisionEditorStepsOperations.getStepConfigs(
    subdivisionEditorState,
    step
  );
  return stepConfig?.isConfigured(subdivisionEditorState);
};

const getStepState = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps,
  subStep?: SubdivisionEditorSubStep
) => {
  if (
    !subdivisionEditorStepsOperations.isValidStep(
      subdivisionEditorState,
      step,
      subStep
    )
  ) {
    return StepState.ERROR;
  }
  if (isCreationMode(subdivisionEditorState)) {
    return StepState.ACTIVE;
  }
  if (
    !stepIsTouched(subdivisionEditorState, step) &&
    isStepConfigured(subdivisionEditorState, step)
  ) {
    return StepState.DONE;
  }

  return StepState.ACTIVE;
};

const getStepStateText = (
  subdivisionEditorState: SubdivisionViewModelState,
  step: SubdivisionEditorSteps
) => {
  if (
    !subdivisionEditorStepsOperations.isValidStep(subdivisionEditorState, step)
  ) {
    return StepStateText.INVALID;
  }
  if (stepIsTouched(subdivisionEditorState, step)) {
    return StepStateText.EDITED;
  }
  if (isStepConfigured(subdivisionEditorState, step)) {
    return StepStateText.DONE;
  }

  return StepStateText.PENDING;
};

const isFirstStep = (subdivisionEditorState: SubdivisionViewModelState) => {
  return (
    getSteps(subdivisionEditorState)[0].step ===
    subdivisionEditorState.subdivisionEditorStep
  );
};

const isLastStep = (subdivisionEditorState: SubdivisionViewModelState) => {
  const stepsList = getSteps(subdivisionEditorState);
  return (
    stepsList[stepsList.length - 1].step ===
    subdivisionEditorState.subdivisionEditorStep
  );
};

const getNextButtonHintMessage = (
  subdivisionEditorState: SubdivisionViewModelState
): string | undefined => {
  const { subdivisionEditorStep } = subdivisionEditorState;
  const stepConfig = getStepConfigs(
    subdivisionEditorState,
    subdivisionEditorStep
  );

  if (typeof stepConfig?.nextButtonHintMessage === 'function') {
    return stepConfig.nextButtonHintMessage(subdivisionEditorState);
  }
  return stepConfig?.nextButtonHintMessage;
};

const getSubStepHeadingForDisplay = ({
  stepperHeading,
}: SubStepConfig): string => {
  if (stepperHeading.length <= 20) {
    return stepperHeading;
  }
  return `${stepperHeading.substring(0, 20)}...`;
};

export const subdivisionEditorStepsOperations = {
  /**
   * Returns the list of steps for the subdivision editor
   */
  getSteps,
  getSubSteps,
  /**
   * Returns the StepConfig for the given step
   */
  getStepConfigs,
  getStepSubtitle,
  /**
   * Returns true if the given step has a previous step
   */
  hasPreviousStep,
  /**
   * Returns true if the given step has a next step
   */
  hasNextStep,
  isFirstStep,
  isLastStep,
  /**
   * Returns true if the given step has a next step and the next step is not disabled
   */
  canGoNextStep,
  /**
   * Returns true if the given step is disabled
   */
  stepIsDisabled,
  /**
   * Returns true if the given step is expanded
   */
  stepIsExpanded,
  /**
   * Returns the index of the given step name
   */
  getStepIndex,
  /**
   * Returns the step config at the given index
   */
  getStepByIndex,
  /**
   * Returns the next non-disabled step after the given step
   */
  getNextStep,
  /**
   * Returns next step button label
   */
  getNextStepButtonLabel,
  /**
   * Returns the previous non-disabled step before the given step
   */
  getPreviousStep,
  /**
   * Returns previous step button label
   */
  getPreviousStepButtonLabel,
  /**
   * Returns true if the given step is valid, using the step's validation function
   */
  isValidStep,
  /**
   * Returns the validation errors for the given step
   */
  getStepValidationErrors,
  getAllStepValidationErrors,
  /**
   * Sets the given step as touched
   */
  setStepTouched,

  setCollapsedStep,
  /**
   * Returns true if the step has been touched
   */
  stepIsTouched,
  /**
   * Returns true if all steps are valid
   */
  canSaveChanges,
  /**
   * Returns true if there are validation errors
   */
  hasValidationErrors,
  /**
   * Returns true if the next button is disabled
   */
  nextStepIsDisabled,
  /**
   * Returns true if the previous button is disabled
   */
  previousStepIsDisabled,
  /**
   * Returns the state of a step
   */
  getStepState,
  /**
   * Returns the state text of a step
   */
  getStepStateText,
  getOnNextButtonClicked,
  getOnPreviousButtonClicked,
  shouldShowPreviousButton,
  shouldShowNextButton,
  getNextButtonHintMessage,
  /**
   * Returns the step heading ready for being displayed
   */
  getSubStepHeadingForDisplay,
};
