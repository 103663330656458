import { ArdoqId, AssetFolder, Workspace } from '@ardoq/api-types';
import { reducer, reducedStream } from '@ardoq/rxbeach';
import { combineLatest } from 'rxjs';
import { map, shareReplay, withLatestFrom } from 'rxjs/operators';
import workspaces$ from 'streams/workspaces/workspaces$';
import {
  searchValueChanged,
  setSelectedWorkspaces,
  workspaceBindingsSaved,
  workspaceDeselected,
  workspaceSelected,
} from './actions';
import {
  BindWorkspacesState,
  DecoratedWorkspace,
  ViewModelState,
} from './types';
import { resetStepStates } from 'subdivisionEditor/subdivisionEditorViewModel$/actions';
import { bindWorkspacesOperations } from './bindWorkspacesOperations';
import assetFolders$ from 'streams/assetFolders/assetFolders$';
import subdivisionNavigation$ from 'subdivisionEditor/navigation/subdivisionNavigation$';
import { workspaceOperations } from 'streams/workspaces/workspaceOperations';
import { caseInsensitiveStringIncludes } from '@ardoq/common-helpers';
import { getAssetPathName } from 'utils/hierarchy';

const defaultViewModel: ViewModelState = {
  searchValue: '',
  selectedWorkspaces: [],
};

const resetViewModel = (): ViewModelState => defaultViewModel;

const reducers = [
  reducer(workspaceSelected, bindWorkspacesOperations.setWorkspaceSelected),
  reducer(workspaceDeselected, bindWorkspacesOperations.setWorkspaceDeselected),
  reducer(
    setSelectedWorkspaces,
    bindWorkspacesOperations.setSelectedWorkspaces
  ),
  reducer(
    searchValueChanged,
    bindWorkspacesOperations.handleSearchValueChanged
  ),
  reducer(resetStepStates, resetViewModel),
  reducer(
    workspaceBindingsSaved,
    bindWorkspacesOperations.resetSelectedWorkspaces
  ),
];

const viewModel$ = reducedStream(
  'bindWorkspacesViewModel$',
  defaultViewModel,
  reducers
);

const decorateWorkspace = (assetFolders: AssetFolder[]) => {
  return (workspace: Workspace): DecoratedWorkspace => {
    const dirPath = getAssetPathName(assetFolders, workspace._id);

    const decoratedWs = {
      ...workspace,
      dirPath,
    };

    return decoratedWs;
  };
};

const filterBySearchValue =
  (searchValue: string) => (workspace: DecoratedWorkspace) => {
    if (searchValue === '') {
      return true;
    }
    return (
      caseInsensitiveStringIncludes(workspace.name, searchValue) ||
      workspace.dirPath.some(path =>
        caseInsensitiveStringIncludes(path, searchValue)
      )
    );
  };

const mapStreamsIntoState = ({
  viewModel,
  workspaces: { workspaces, workspacesBoundToSubdivisionIds },
}: {
  viewModel: ViewModelState;
  workspaces: {
    workspaces: DecoratedWorkspace[];
    workspacesBoundToSubdivisionIds: ArdoqId[];
  };
}): BindWorkspacesState => {
  return {
    ...viewModel,
    workspaces: workspaces.filter(filterBySearchValue(viewModel.searchValue)),
    workspacesBoundToSubdivisionIds,
  };
};

const decoratedWorkspaces$ = workspaces$.pipe(
  withLatestFrom(assetFolders$),
  map(([{ byId }, folders]) => {
    const decorateFunction = decorateWorkspace(folders);
    const workspaces = Object.values(byId).map(decorateFunction);
    return {
      workspaces,
    };
  })
);

const bindWorkspaces$ = combineLatest({
  viewModel: viewModel$,
  workspaces: combineLatest([
    decoratedWorkspaces$,
    subdivisionNavigation$,
  ]).pipe(
    map(([{ workspaces }, { subdivisionId }]) => {
      return {
        workspaces,
        workspacesBoundToSubdivisionIds: workspaces
          .filter(workspace =>
            workspaceOperations.isSubdivisionBoundToWorkspace(
              workspace,
              subdivisionId
            )
          )
          .map(workspace => workspace._id),
      };
    })
  ),
}).pipe(
  map(mapStreamsIntoState),
  // as long as the state is not changed, the stream will emit
  // the same value to all subscribers without re-calculating it
  shareReplay(1)
);

export default bindWorkspaces$;
