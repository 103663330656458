import {
  APIComponentAttributesLite,
  ArdoqId,
  Subdivision,
  Workspace,
} from '@ardoq/api-types';
import { workspaceOperations } from 'streams/workspaces/workspaceOperations';
import { DecoratedSubdivision } from './types';

type SubdivisionsComponentsCount = Record<ArdoqId, number>;

const getSubdivisionsComponentsCount = <T extends APIComponentAttributesLite>(
  components: T[]
): SubdivisionsComponentsCount => {
  return components.reduce<SubdivisionsComponentsCount>(
    (acc, { subdivisionMembership }) => {
      subdivisionMembership.forEach(subdivisionId => {
        acc[subdivisionId] = (acc[subdivisionId] || 0) + 1;
      });
      return acc;
    },
    {}
  );
};

const ascByNameLength = <T extends Subdivision>(a: T, b: T) =>
  a.name.length - b.name.length;

const decorate = (
  subdivision: Subdivision,
  {
    workspacesById,
    contextWorkspaceId,
    subdivisionsComponentsCount,
  }: {
    workspacesById: Record<string, Workspace>;
    contextWorkspaceId: ArdoqId;
    subdivisionsComponentsCount: SubdivisionsComponentsCount;
  }
): DecoratedSubdivision => ({
  ...subdivision,
  isDisabled: !workspaceOperations.isSubdivisionBoundToWorkspace(
    workspacesById[contextWorkspaceId],
    subdivision._id
  ),
  componentsCount: subdivisionsComponentsCount[subdivision._id] || 0,
});

const decorateBulk = (
  subdivisions: Subdivision[],
  {
    workspacesById,
    contextWorkspaceId,
    relevantComponents,
  }: {
    workspacesById: Record<string, Workspace>;
    contextWorkspaceId: ArdoqId;
    relevantComponents: APIComponentAttributesLite[];
  }
): DecoratedSubdivision[] => {
  const subdivisionsComponentsCount =
    getSubdivisionsComponentsCount(relevantComponents);
  return subdivisions.map(subdivision =>
    decorate(subdivision, {
      workspacesById,
      contextWorkspaceId,
      subdivisionsComponentsCount,
    })
  );
};

const updateComponentsCount = (
  subdivisions: DecoratedSubdivision[],
  components: APIComponentAttributesLite[]
): DecoratedSubdivision[] => {
  const subdivisionsComponentsCount =
    getSubdivisionsComponentsCount(components);
  return subdivisions.map(subdivision => ({
    ...subdivision,
    componentsCount: subdivisionsComponentsCount[subdivision._id] || 0,
  }));
};

const selectSubdivisionsByMaxLength = <T extends Subdivision>(
  subdivisions: T[],
  maxLength: number
): Subdivision[] => {
  if (subdivisions.length === 0) return [];
  if (maxLength <= 0) return [];

  const sortedSubdivisions = subdivisions.sort(ascByNameLength);

  if (maxLength === Infinity) return sortedSubdivisions;

  const { selectedSubdivisions } = sortedSubdivisions.reduce<{
    selectedSubdivisions: T[];
    currentLength: number;
  }>(
    ({ selectedSubdivisions, currentLength }, subdivision) => {
      const nameLength = subdivision.name.length;
      if (currentLength + nameLength <= maxLength)
        return {
          selectedSubdivisions: [...selectedSubdivisions, subdivision],
          currentLength: currentLength + nameLength,
        };
      return { selectedSubdivisions, currentLength };
    },
    { selectedSubdivisions: [], currentLength: 0 }
  );

  return selectedSubdivisions.sort(ascByNameLength);
};

export const decoratedSubdivisionOperations = {
  decorateBulk,
  updateComponentsCount,
  selectSubdivisionsByMaxLength,
};
