import { ArdoqId, Asset, AssetFolder } from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { AssetsStreamState } from '../../streams/assets/assets$';
import { intersection } from 'lodash';
import {
  searchDatasourceByName,
  combineFoldersFromAssetArray,
  getRowTypeByAssetType,
} from '../EntityBrowser/utils';
import { Filter } from './FilterBar/filterBarTypes';
import { AssetRow } from '../AssetsBrowser/types';
import { Row } from '../EntityBrowser/types';
import { Locale } from '@ardoq/locale';
import { viewpointAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/viewpoints';

export const mapIdsToAssets = (
  assetIds: ArdoqId[],
  assetsById: Record<string, Asset>
): Asset[] => assetIds.map(id => assetsById[id]).filter(ExcludeFalsy);

const assetToAssetRow = (asset: Asset) => {
  return {
    ...asset,
    meta: { ...asset.meta, level: 0 },
    rowType: getRowTypeByAssetType(asset.meta.assetType, asset._id),
  } as Row<AssetRow>;
};

const assetsStateToAssets = (assetsState: AssetsStreamState): Asset[] => {
  return [
    ...Object.values(assetsState.workspaceAssetsById),
    ...Object.values(assetsState.presentationsById),
    ...Object.values(assetsState.surveysById),
    ...Object.values(assetsState.metamodelsById),
    ...Object.values(assetsState.scenariosById),
    ...Object.values(assetsState.reportsById),
    ...Object.values(assetsState.dashboardsById),
    ...Object.values(assetsState.traversalsById),
    // Discover viewpoints cannot be viewed via the assets browser, only edited
    // Therefore it makes sense to only include viewpoints the user has edit access to
    ...Object.values(assetsState.viewpointsById).filter(viewpoint =>
      viewpointAccessControlInterface.canEditViewpoint(viewpoint._id)
    ),
    ...Object.values(assetsState.broadcastsById),
    ...Object.values(assetsState.bookmarksById),
  ];
};

const filterAssets = (assets: Asset[], activeFilters: Filter[]) => {
  const activeAndFilters = activeFilters.filter(
    filter => filter.filterRule === 'AND'
  );
  const activeOrFilters = activeFilters.filter(
    filter => filter.filterRule === 'OR'
  );
  const isActiveAndFiltersEmpty = activeAndFilters.length === 0;
  const isActiveOrFiltersEmpty = activeOrFilters.length === 0;
  const assetsAfterAndFilters = isActiveAndFiltersEmpty
    ? assets
    : assets.filter(asset =>
        activeAndFilters.every(filter => filter.filterFn(asset))
      );
  const assetsAfterOrFilters = isActiveOrFiltersEmpty
    ? assetsAfterAndFilters
    : assetsAfterAndFilters.filter(asset =>
        activeOrFilters.some(filter => filter.filterFn(asset))
      );
  return assetsAfterOrFilters;
};

export const getFilteredDatasource = (
  assetsState: AssetsStreamState,
  searchPhrase: string,
  activeFilters: Filter[],
  alwaysActiveFilters: Filter[],
  useFolders: boolean,
  locale: Locale
): Row<AssetRow>[] => {
  const assets = assetsStateToAssets(assetsState);
  const combinedFilters = [...activeFilters, ...alwaysActiveFilters];
  const isFilterEmpty = combinedFilters.length === 0;
  const filteredAssets = intersection(
    filterAssets(assets, alwaysActiveFilters),
    filterAssets(assets, activeFilters)
  );

  if (!useFolders) {
    return searchDatasourceByName(
      filteredAssets.map(assetToAssetRow),
      searchPhrase,
      locale
    );
  }

  const allVisibleAssetIds = filteredAssets.map(asset => asset._id);

  const getIsFolderVisible = (assetFolder: AssetFolder) => {
    const nestedContentIds = assetFolder.nestedContent.map(({ _id }) => _id);
    return (
      isFilterEmpty ||
      intersection(nestedContentIds, allVisibleAssetIds).length > 0
    );
  };

  const dataSource = combineFoldersFromAssetArray(
    Object.values(assetsState.assetFoldersById).filter(getIsFolderVisible),
    filteredAssets
  );
  return searchDatasourceByName(dataSource, searchPhrase, locale);
};
