import { DoqFace, DoqSize, DoqType } from '@ardoq/doq';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { header1, text1 } from '@ardoq/typography';
import { WorkspacesSelector } from 'integrations/common/components/workspacesSelector';
import styled from 'styled-components';
import { Island } from '@ardoq/page-layout';

export type TransferSuccessProps = {
  title: string;
  message: string;
  affectedWorkspaces: { id: string; name: string }[];
  selectedWorkspaces: string[];
  onWorkspacesSelected: (workspaceIds: string[]) => void;
};

export function TransferSuccessComponent({
  title,
  message,
  affectedWorkspaces,
  selectedWorkspaces,
  onWorkspacesSelected,
}: TransferSuccessProps) {
  return (
    <>
      <Container footerJustify="end" maxWidth="100%">
        <FlexBox gap="xlarge">
          <DoqFace doqSize={DoqSize.SMALL} doqType={DoqType.IMPORT_SUCCESS} />
          <Stack gap="small">
            <Title>{title}</Title>
            <Message>{message}</Message>
            {affectedWorkspaces.length > 0 && (
              <WorkspacesSelector
                title="Workspaces"
                workspaces={affectedWorkspaces}
                selectedWorkspaces={selectedWorkspaces}
                onSelectionChange={workspaces => {
                  onWorkspacesSelected(workspaces);
                }}
              />
            )}
          </Stack>
        </FlexBox>
      </Container>
    </>
  );
}

const Container = styled(Island)`
  width: 100%;
`;

const Title = styled.div`
  ${header1}
`;

const Message = styled.div`
  ${text1}
`;
