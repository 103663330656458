import { PropsWithChildren } from 'react';
import { CheckMarkIcon, CloseIcon } from '@ardoq/icons';

interface QuickPerspectivesCheckboxOptions {
  isImplicitlySelected?: boolean;
  isOutsideScope?: boolean;
}
interface QuickPerspectivesLegendItemProperties
  extends QuickPerspectivesCheckboxOptions {
  title?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: VoidFunction;
  contextMenuName?: string;
}
const QuickPerspectivesLegendItem = ({
  isImplicitlySelected,
  isOutsideScope,
  title,
  isChecked,
  isDisabled,
  onChange,
  children,
  contextMenuName,
}: PropsWithChildren<QuickPerspectivesLegendItemProperties>) => (
  <div data-context-menu={contextMenuName}>
    <label className="modern-checkbox" title={title}>
      {isImplicitlySelected ? (
        <CheckMarkIcon />
      ) : isOutsideScope ? (
        <CloseIcon />
      ) : (
        <input
          type="checkbox"
          checked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
        />
      )}
      <i className="material-icons-round" />
      {children}
    </label>
  </div>
);
export default QuickPerspectivesLegendItem;
