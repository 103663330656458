import { APIScenarioAttributes } from '@ardoq/api-types';
import { Maybe } from '@ardoq/common-helpers';
import { SelectOption } from '@ardoq/select';
import { CollectionStream } from 'streams/utils/streamUtils';

export type SelectedBranch = Maybe<string>;

type ScenarioSelectionChanged = (branchId: SelectedBranch) => void;

export type ScenarioSelection = {
  options: SelectOption<SelectedBranch>[];
  selection: SelectOption<SelectedBranch> | null;
  onChange: ScenarioSelectionChanged;
};

export const buildScenarioOptions = (
  scenarios: CollectionStream<APIScenarioAttributes>,
  branchId: SelectedBranch,
  onChange: ScenarioSelectionChanged
): ScenarioSelection => {
  const options = [
    {
      label: 'Mainline',
      value: null,
    },
    ...scenarios.list.map(scenario => ({
      label: scenario.name,
      value: scenario.branchId,
    })),
  ];

  const selection = options.find(option => option.value === branchId) ?? null;
  return {
    options,
    selection,
    onChange,
  };
};
