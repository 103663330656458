import { GroupFieldLayout, RadioGroup } from '@ardoq/forms';
import { Stack } from '@ardoq/layout';
import { useState } from 'react';
import User from '../../scopeData/editors/user/User';
import { SelectionMode } from './types';

type DateTimeHiddenFieldProps = {
  value: any;
  onChange: (value: any) => void;
  errorMessage?: string;
  onBlur?: () => void;
  hintMessage?: string;
};

export const HiddenFieldUser = ({
  value,
  onChange,
  errorMessage,
  onBlur = () => null,
  hintMessage,
}: DateTimeHiddenFieldProps) => {
  const [selectedMode, setSelectedMode] = useState<SelectionMode>(
    value ? SelectionMode.MANUAL : SelectionMode.AUTOMATIC
  );

  const onModeChange = (mode: SelectionMode) => {
    if (mode === SelectionMode.AUTOMATIC) onChange(undefined);
    setSelectedMode(mode);
  };

  return (
    <Stack gap="small">
      <RadioGroup
        value={selectedMode}
        onValueChange={mode => {
          onModeChange(mode as SelectionMode);
          onBlur();
        }}
        groupFieldLayout={GroupFieldLayout.HORIZONTAL}
        options={[
          {
            label: 'Automatic',
            value: SelectionMode.AUTOMATIC,
            helperText: 'Set to the user who last submitted',
          },
          {
            label: 'Select user',
            value: SelectionMode.MANUAL,
          },
        ]}
      />
      {selectedMode === SelectionMode.MANUAL && (
        <User
          value={value}
          onValueChange={onChange}
          label={''}
          errorMessages={errorMessage ? [errorMessage] : undefined}
          onBlur={onBlur}
          description={hintMessage}
        />
      )}
    </Stack>
  );
};
