import { connect } from '@ardoq/rxbeach';
import { StepContainer } from '../components/StepContainer';
import { viewModel$ } from './viewModel$';
import {
  ConfigurationState,
  SelectableField,
} from 'integrations/signavio-exporter/streams/types';
import { TabButton, TabsGroup } from '@ardoq/tabs';
import { Header4, InlineText } from '@ardoq/typography';
import { CreatableSelect } from '@ardoq/select';
import { FormWrapper, TextInput } from '@ardoq/forms';
import {
  getCategoryAttributeOptions,
  getCategoryOptions,
  getSelectedOption,
  isValidCreatableValue,
} from './utils';
import { Maybe } from '@ardoq/common-helpers';
import styled from 'styled-components';
import { s16, s32 } from '@ardoq/design-tokens';
import { SelectionKeys } from './types';
import { PrimaryButton } from '@ardoq/button';
import { DictionaryCategoriesState } from 'integrations/signavio-exporter/streams/dictionaryCategories/types';
import { Island } from '@ardoq/page-layout';
import { Box, FlexBox, Stack } from '@ardoq/layout';

type MapFieldsProps = {
  fieldMappingIsValid: boolean;
  configurationState: ConfigurationState;
  dictionaryCategories: DictionaryCategoriesState;
  onFieldChange: (
    componentType: string,
    fieldName: SelectionKeys,
    selectedField: Maybe<SelectableField> | string
  ) => void;
  onNext: () => void;
};

function MapFieldsComponent({
  fieldMappingIsValid,
  configurationState,
  dictionaryCategories,
  onFieldChange,
  onNext,
}: MapFieldsProps) {
  const componentTypes = Object.entries(configurationState.componentTypes);
  return (
    <StepContainer $isVertical>
      <Island
        title="Configure components mapping to export to Signavio"
        footerContent={
          <FlexBox justify="end" flex={1}>
            <PrimaryButton isDisabled={!fieldMappingIsValid} onClick={onNext}>
              Next: Review export
            </PrimaryButton>
          </FlexBox>
        }
      >
        {componentTypes.length > 0 && (
          <TabsGroup>
            {componentTypes.map(([componentTypeName, componentTypeValue]) => (
              <TabButton
                label={componentTypeName}
                tabId={componentTypeName}
                key={componentTypeName}
              >
                <Box padding="xxsmall">
                  <Stack gap="large">
                    <Stack gap="small">
                      <Header4>
                        1. Select or create Signavio dictionary to export the
                        components
                      </Header4>
                      <FormWrapper>
                        <CreatableSelect
                          placeholder="Select or start typing"
                          value={getSelectedOption(
                            componentTypeValue,
                            'dictionaryCategory'
                          )}
                          onChange={value =>
                            onFieldChange(
                              componentTypeName,
                              'dictionaryCategory',
                              value
                            )
                          }
                          onCreateOption={value =>
                            onFieldChange(
                              componentTypeName,
                              'dictionaryCategory',
                              value
                            )
                          }
                          errorMessage={
                            dictionaryCategories.status === 'FAILURE'
                              ? 'Failed to fetch dictionary categories'
                              : undefined
                          }
                          isLoading={dictionaryCategories.status === 'LOADING'}
                          options={getCategoryOptions(dictionaryCategories)}
                        />
                      </FormWrapper>
                    </Stack>
                    <Header4>2. Map fields</Header4>
                    <Grid>
                      {(
                        [
                          {
                            map: { label: 'Component name' },
                            to: {
                              label: 'Dictionary Entry name',
                              isReadOnly: true,
                              value: 'dictionaryCategory',
                            },
                          },
                          {
                            map: { label: 'Ardoq object identifier (OID)' },
                            to: {
                              isReadOnly: false,
                              value: 'mappedOid',
                            },
                          },
                          {
                            map: { label: 'Ardoq component URL' },
                            to: {
                              isReadOnly: false,
                              value: 'mappedComponentUrl',
                            },
                          },
                        ] as const
                      ).map(entry => {
                        return (
                          <>
                            {/* Grid items
                             * Map: From Ardoq field
                             * To: Signavio field
                             */}
                            <InlineText variant="text1">Map</InlineText>
                            <TextInput isReadOnly value={entry.map.label} />
                            <InlineText variant="text1">to</InlineText>
                            {entry.to.isReadOnly && (
                              <TextInput isReadOnly value={entry.to.label} />
                            )}
                            {!entry.to.isReadOnly && (
                              <CreatableSelect
                                placeholder="Select or start typing"
                                value={getSelectedOption(
                                  componentTypeValue,
                                  entry.to.value
                                )}
                                isDisabled={
                                  !getSelectedOption(
                                    componentTypeValue,
                                    'dictionaryCategory'
                                  )
                                }
                                onChange={value =>
                                  onFieldChange(
                                    componentTypeName,
                                    entry.to.value,
                                    value
                                  )
                                }
                                onCreateOption={value =>
                                  onFieldChange(
                                    componentTypeName,
                                    entry.to.value,
                                    value
                                  )
                                }
                                isLoading={
                                  dictionaryCategories.status === 'LOADING'
                                }
                                errorMessage={
                                  dictionaryCategories.status === 'FAILURE'
                                    ? 'Failed to fetch dictionary attributes'
                                    : undefined
                                }
                                options={getCategoryAttributeOptions(
                                  dictionaryCategories,
                                  componentTypeValue
                                )}
                                isValidNewOption={(inputValue, _, options) => {
                                  return isValidCreatableValue(
                                    inputValue,
                                    options,
                                    [
                                      componentTypeValue.mappedOid.label,
                                      componentTypeValue.mappedComponentUrl
                                        .label,
                                    ]
                                  );
                                }}
                              />
                            )}
                          </>
                        );
                      })}
                    </Grid>
                  </Stack>
                </Box>
              </TabButton>
            ))}
          </TabsGroup>
        )}
      </Island>
    </StepContainer>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content 300px min-content 300px;
  text-overflow: nowrap;
  gap: ${s16} ${s32};
  align-items: center;
`;

export const MapFields = connect(MapFieldsComponent, viewModel$);
