import { TableId } from 'integrations/common/streams/tabularMappings/types';
import { AsyncStatus } from 'integrations/common/types/api';
import { actionCreator } from '@ardoq/rxbeach';
import { ServiceNowField } from '@ardoq/api-types/integrations';

const NAMESPACE = '[integrations] SERVICENOW_TABLE_FIELDS';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const fetchTableFields = actionCreator<TableId>(
  getActionName('FETCH_TABLE_FIELDS')
);

export type SetRequestStatusPaylod = { tableId: TableId; status: AsyncStatus };

export const setRequestStatus = actionCreator<SetRequestStatusPaylod>(
  getActionName('SET_REQUEST_STATUS')
);

export type SetTableFieldsPayload = {
  tableId: TableId;
  fields: ServiceNowField[];
};

export const setTableFields = actionCreator<SetTableFieldsPayload>(
  getActionName('SET_TABLE_FIELDS')
);

export const setMultipleTablesFields = actionCreator<SetTableFieldsPayload[]>(
  getActionName('SET_MULTIPLE_TABLES_FIELDS')
);

export const initTables = actionCreator<TableId[]>(
  getActionName('INIT_TABLES')
);

export const resetTableFields = actionCreator(
  getActionName('RESET_TABLE_FIELDS')
);
