import { APILoadTraversalParams } from '@ardoq/api';
import {
  APIComponentAttributes,
  TraversalPathMatchingType,
} from '@ardoq/api-types';

export const getTraversalWithStartSetAndWildcardTripleOfType = (
  componentIds: string[],
  componentApiResponse: APIComponentAttributes
): APILoadTraversalParams => {
  return {
    startSet: componentIds,
    paths: [
      [
        {
          sourceType: componentApiResponse.type,
          referenceType: '@@wildcard@@',
          targetType: '@@wildcard@@',
          direction: 'outgoing' as const,
        },
      ],
      [
        {
          sourceType: '@@wildcard@@',
          referenceType: '@@wildcard@@',
          targetType: componentApiResponse.type,
          direction: 'incoming' as const,
        },
      ],
    ],
    startQuery: null,
    filters: {},
    pathMatching: TraversalPathMatchingType.LOOSE,
    pathCollapsingRules: [],
  };
};
