import { SimpleGraphContainerWithGreyBackground } from 'viewpointBuilder/components/SimpleGraph/SimpleGraphViewContainer';
import { ViewpointBuilderFooter } from 'viewpointBuilder/components/ViewpointBuilderFooter';
import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { ResizablePageBody } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';

type MetamodelLoaderProps = {
  isMetaModelLoaded: boolean;
  children: React.ReactElement;
  closeViewpointBuilder: () => void;
};

export const WithMetamodelLoader = ({
  isMetaModelLoaded,
  closeViewpointBuilder,
  children,
}: MetamodelLoaderProps) => {
  if (!isMetaModelLoaded) {
    return (
      <ResizablePageBody
        skipPadding
        rightContent={null}
        footerContent={
          <ViewpointBuilderFooter
            closeViewpointBuilder={closeViewpointBuilder}
          />
        }
      >
        <MetaModelLoader />
      </ResizablePageBody>
    );
  }

  return children;
};

export const MetaModelLoader = () => {
  return (
    <SimpleGraphContainerWithGreyBackground>
      <FlexBox flex={1} justify="center" align="center">
        <ArdoqLoaderComponent
          size={LoaderSize.MEDIUM}
          loaderColor={LoaderColor.BRAND}
        />
      </FlexBox>
    </SimpleGraphContainerWithGreyBackground>
  );
};
