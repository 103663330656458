import { ViewIds } from '@ardoq/api-types';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { relationshipsTraversalMenu } from 'tabview/relationsD3View/menus';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import {
  showOnlyConnectedComponents,
  replaceLegendButton,
  SettingsBar,
} from '@ardoq/settings-bar';
import {
  type ButtonConfig,
  SettingsType,
  viewSettingsConsts,
  type SettingsConfig,
  type ToggleConfig,
  type DropdownConfig,
} from '@ardoq/view-settings';
import { RelationshipsNode, RelationshipsViewSettings } from './types';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import {
  type TraversalViewModel,
  type RelationshipDiagramViewSettings,
  type HasViewpointMode,
} from '@ardoq/graph';
import { IconName } from '@ardoq/icons';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import {
  collapseAll as collapseAllBase,
  expandAll as expandAllBase,
} from 'tabview/relationshipDiagrams/leftMenuItems';
import {
  relationshipsViewSetCollapsedGroupIds,
  relationshipsViewSetHighlightDisconnectedNodes,
  relationshipsViewSetLinkBundling,
} from './actions';
import { HIGHLIGHT_DISCONNECTED_COMPONENTS } from './consts';
import { map } from 'rxjs';
import { everyDescendant } from './util';
import { unfilteredRelationshipsViewSettings$ } from './viewModel$/viewModel$';
import { isEqual, noop } from 'lodash';
import { toggleLegend } from 'presentation/viewPane/actions';
import { WILDCARD } from '@ardoq/common-helpers';
import {
  type DropdownItem,
  type DropdownOption,
  DropdownOptionType,
} from '@ardoq/dropdown-menu';
import { viewLegendCommands } from '@ardoq/view-legend';
import pngExporter from './pngExporter';

const VIEW_ID = ViewIds.RELATIONSHIPS_3;
type ExpandAllButtonConfigParams = {
  viewSettings: RelationshipDiagramViewSettings;
  isDisabled: boolean;
};
type CollapseAllButtonConfigParams = ExpandAllButtonConfigParams & {
  rootNode: RelationshipsNode | null;
};

export const getCollapseAllButtonConfig = ({
  viewSettings,
  isDisabled,
  rootNode,
}: CollapseAllButtonConfigParams): ButtonConfig => ({
  ...collapseAllBase(viewSettings, VIEW_ID, isDisabled),
  ...(isDisabled
    ? {
        onClick: noop,
        isActive: false,
      }
    : {
        onClick: () => {
          const newCollapsedGroupIds = [WILDCARD];
          if (!isEqual(viewSettings.collapsedGroupIds, newCollapsedGroupIds)) {
            notifyCollapsedGroupIdsChanged(newCollapsedGroupIds);
          }
        },
        isActive:
          viewSettings.collapsedGroupIds.includes(WILDCARD) ||
          (rootNode !== null &&
            everyDescendant(
              rootNode,
              node => !node.descendantCount || !node.open
            )),
      }),
});

export const getExpandAllButtonConfig = ({
  viewSettings,
  isDisabled,
}: ExpandAllButtonConfigParams): ButtonConfig => ({
  ...expandAllBase(viewSettings, VIEW_ID, isDisabled),
  onClick: !isDisabled
    ? () => {
        const newCollapsedGroupIds: string[] = [];
        if (!isEqual(viewSettings.collapsedGroupIds, newCollapsedGroupIds)) {
          notifyCollapsedGroupIdsChanged(newCollapsedGroupIds);
        }
      }
    : noop,
});

type NotifyHighlightDisconnectedComponentsChangedArgs = {
  highlightDisconnectedComponents: boolean;
  persistent: boolean;
};
/**
 * Inverts the supplied highlightDisconnectedComponents boolean
 * and dispatches updateViewSettings action
 */
export const notifyHighlightDisconnectedComponentsChanged = ({
  highlightDisconnectedComponents,
  persistent,
}: NotifyHighlightDisconnectedComponentsChangedArgs) => {
  const newHighlightDisconnectedComponents = !highlightDisconnectedComponents;
  dispatchAction(
    relationshipsViewSetHighlightDisconnectedNodes({
      highlightDisconnectedNodes: newHighlightDisconnectedComponents,
    })
  );
  dispatchAction(
    updateViewSettings({
      viewId: VIEW_ID,
      settings: {
        highlightDisconnectedComponents: newHighlightDisconnectedComponents,
      },
      persistent,
    })
  );
};

const notifyCollapsedGroupIdsChanged = (collapsedGroupIds: string[]) => {
  dispatchAction(
    relationshipsViewSetCollapsedGroupIds({
      collapsedGroupIds,
    })
  );
  dispatchAction(
    updateViewSettings({
      viewId: VIEW_ID,
      settings: { collapsedGroupIds },
      persistent: true,
    })
  );
};

const bundleRelationshipsHandler = (newValue: boolean) => () => {
  dispatchAction(
    relationshipsViewSetLinkBundling({
      bundleRelationships: newValue,
    })
  );
  dispatchAction(
    updateViewSettings({
      viewId: VIEW_ID,
      settings: { bundleRelationships: newValue },
      persistent: true,
    })
  );
};

type RelationshipsViewModifiersArgs = Pick<
  RelationshipsViewSettingsBarProperties,
  | 'isViewpointMode'
  | 'viewSettings'
  | 'traversedIncomingReferenceTypes'
  | 'traversedOutgoingReferenceTypes'
  | 'referenceTypes'
  | 'activeGroups'
  | 'rootNode'
>;
export const relationshipsViewModifiers = ({
  isViewpointMode,
  viewSettings,
  traversedIncomingReferenceTypes,
  traversedOutgoingReferenceTypes,
  referenceTypes,
  activeGroups,
  rootNode,
}: RelationshipsViewModifiersArgs): SettingsConfig[] => {
  const { highlightDisconnectedComponents, bundleRelationships } = viewSettings;

  const bundleRelationshipsConfig = {
    id: 'bundleRelationships',
    label: 'Combine reference lines',
    iconName: IconName.EDGE_BUNDLING,
    isActive: bundleRelationships,
    type: SettingsType.TOGGLE,
    onViewSettingsUpdate: bundleRelationshipsHandler(!bundleRelationships),
  } satisfies ToggleConfig;

  const hasNoGroups = activeGroups.length === 0;
  const collapseAllButtonConfig = getCollapseAllButtonConfig({
    viewSettings,
    isDisabled: hasNoGroups,
    rootNode,
  });
  const expandAllButtonConfig = getExpandAllButtonConfig({
    viewSettings,
    isDisabled: hasNoGroups,
  });

  if (!isViewpointMode) {
    const traversalMenu = relationshipsTraversalMenu({
      viewSettings,
      traversedIncomingReferenceTypes,
      traversedOutgoingReferenceTypes,
      referenceTypes,
      viewId: VIEW_ID,
    });
    const showAllConnectedComponentsConfig = showOnlyConnectedComponents(
      viewSettings.showOnlyConnectedComponents,
      onViewSettingsUpdate
    );
    const highlightDisconnectedComponentsConfig = {
      id: 'highlightDisconnectedComponents',
      label: HIGHLIGHT_DISCONNECTED_COMPONENTS,
      iconName: IconName.BULLSEYE,
      isActive: highlightDisconnectedComponents,
      type: SettingsType.TOGGLE,
      onViewSettingsUpdate: () =>
        notifyHighlightDisconnectedComponentsChanged({
          highlightDisconnectedComponents,
          persistent: true,
        }),
    } satisfies ToggleConfig;
    return [
      traversalMenu,
      bundleRelationshipsConfig,
      collapseAllButtonConfig,
      expandAllButtonConfig,
      showAllConnectedComponentsConfig,
      highlightDisconnectedComponentsConfig,
    ];
  }

  return [
    {
      id: 'bundleRelationships',
      label: 'Separate references',
      iconName: IconName.SEPARATE_REFERENCES,
      isActive: bundleRelationships,
      type: SettingsType.DROPDOWN,
      options: [
        {
          label: 'Combine reference lines',
          type: DropdownOptionType.OPTION,
          isActive: bundleRelationships,
          onClick: bundleRelationshipsHandler(true),
        } satisfies DropdownOption,
        {
          label: 'Separate reference lines',
          type: DropdownOptionType.OPTION,
          isActive: !bundleRelationships,
          onClick: bundleRelationshipsHandler(false),
        } satisfies DropdownOption,
      ] satisfies DropdownItem[],
    } satisfies DropdownConfig,
  ];
};

type RelationshipsViewSettingsBarProperties = TraversalViewModel &
  HasViewpointMode & {
    viewSettings: RelationshipsViewSettings;
    getExportContainer: () => HTMLElement | null;
    addToPresentation: VoidFunction;
    collapsedGroupIds?: string[];
    rootNode: RelationshipsNode | null;
    activeGroups: string[];
    isEmptyView: boolean;
    zoomIn: VoidFunction;
    zoomOut: VoidFunction;
    onOptimizeLayout: VoidFunction;
  };
const RelationshipsViewSettingsBar = (
  props: RelationshipsViewSettingsBarProperties
) => {
  const {
    viewSettings,
    getExportContainer,
    addToPresentation,
    isEmptyView,
    isViewpointMode,
    zoomIn,
    zoomOut,
    onOptimizeLayout,
  } = props;

  return (
    <SettingsBar
      viewId={VIEW_ID}
      leftMenu={relationshipsViewModifiers(props)}
      rightMenu={replaceLegendButton(
        viewSettings[viewSettingsConsts.IS_LEGEND_ACTIVE],
        getRightMenuConfig({
          viewId: VIEW_ID,
          viewstate: viewSettings,
          exports: {
            exportToPng: pngExporter(
              getExportContainer,
              () => getExportContainer()?.querySelector('canvas') ?? null,
              ViewIds.RELATIONSHIPS_3
            ),
            addToPresentation,
            isDisabled: isEmptyView,
          },
          knowledgeBaseLink: isViewpointMode
            ? undefined
            : KnowledgeBaseLink.RELATIONSHIPS,
          onViewSettingsUpdate,
          legendOnClick: () => {
            const newLegendActive = !viewSettings.isLegendActive;
            viewLegendCommands.updateViewLegendSettings({
              viewId: VIEW_ID,
              isActive: newLegendActive,
            });
            dispatchAction(
              toggleLegend({
                viewId: VIEW_ID,
                legendActive: newLegendActive,
              }),
              VIEW_ID
            );
          },
          additionalItems: isViewpointMode
            ? [
                {
                  id: 'zoomIn',
                  type: SettingsType.BUTTON,
                  label: 'Zoom in',
                  iconName: IconName.ZOOM_IN,
                  onClick: zoomIn,
                } satisfies ButtonConfig,
                {
                  id: 'zoomOut',
                  type: SettingsType.BUTTON,
                  label: 'Zoom out',
                  iconName: IconName.ZOOM_OUT,
                  onClick: zoomOut,
                } satisfies ButtonConfig,
                {
                  id: 'optimizeLayout',
                  type: SettingsType.BUTTON,
                  label: 'Optimize layout',
                  iconName: IconName.OPTIMIZE_LAYOUT,
                  onClick: onOptimizeLayout,
                } satisfies ButtonConfig,
              ]
            : undefined,
        })
      )}
    />
  );
};

export default connect(
  RelationshipsViewSettingsBar,
  unfilteredRelationshipsViewSettings$.pipe(
    map(({ currentSettings }) => ({
      viewSettings: currentSettings,
    }))
  )
);
