import {
  SelectionState,
  setupSelection$,
} from 'components/VirtualWindow/streamBuilders/setupSelection$';
import { actionCreator, reducer } from '@ardoq/rxbeach';
import { ArdoqId } from '@ardoq/api-types';

const selectClick = actionCreator<ArdoqId>(
  '[scenarioRelatedSelection$] scenarioRelated select click'
);
type SetSelectedPayload = { id: ArdoqId | null; ids: ArdoqId[] };
const setSelected = actionCreator<SetSelectedPayload>(
  '[scenarioRelatedSelection$] scenarioRelated setSelected'
);

const handleSetSelected = (
  state: SelectionState,
  { id: current, ids }: SetSelectedPayload
): SelectionState => ({
  byId: ids.reduce(
    (acc, id) => ({ ...acc, [id]: state.current !== current }),
    {}
  ),
  current: state.current === current ? null : current,
  selectionCache: null,
});

const {
  selection$: scenarioRelatedSelection$,
  selectMeta,
  selectShift,
  resetSelection,
  removeSelection,
} = setupSelection$('scenarioRelated', {
  reducers: [reducer(setSelected, handleSetSelected)],
});

const getSelectedIds = () =>
  Object.entries(scenarioRelatedSelection$.state.byId)
    .filter(([, selected]) => selected)
    .map(([selectedId]) => selectedId);

export {
  scenarioRelatedSelection$,
  selectMeta,
  selectShift,
  setSelected,
  selectClick,
  resetSelection,
  removeSelection,
  getSelectedIds,
};
