import { InfoNotification } from '@ardoq/status-ui';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { map, switchMap } from 'rxjs';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { connect } from '@ardoq/rxbeach';
import { Maybe } from '@ardoq/common-helpers';

type ImportNotificationWrapperProps = {
  infoMessage: Maybe<string>;
};

export const ImportNotificationWrapper = ({
  infoMessage,
}: ImportNotificationWrapperProps) => {
  if (!infoMessage) {
    return <></>;
  }
  return <InfoNotification>{infoMessage}</InfoNotification>;
};

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => getTransferStateStream(integrationId)),
  map(transferState => ({
    infoMessage: transferState.infoMessage,
  }))
);

export const ImportNotification = connect(
  ImportNotificationWrapper,
  viewModel$
);
