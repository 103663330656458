import {
  Connection,
  ConnectionRequestPayload,
} from '@ardoq/api-types/integrations';
import {
  ConnectionsAsyncOperationsState,
  IntegrationConnectionsState,
} from './types';
import { Maybe } from '@ardoq/common-helpers';
import { actionCreatorWithIntegrationId } from 'integrations/common/utils/actionCreatorWithIntegrationId';

const NAMESPACE = '[integrations] CONNECTIONS';

// Routine to load connections from api
export const listConnections = actionCreatorWithIntegrationId(
  `${NAMESPACE}_LIST_CONNECTIONS`
);

type ConnectionOperationPayload = {
  connection: Connection;
};

type ConnectionUpsertPayload = {
  connection: ConnectionRequestPayload | Connection;
};

export const upsertConnection =
  actionCreatorWithIntegrationId<ConnectionUpsertPayload>(
    `${NAMESPACE}_UPSERT_CONNECTION`
  );

export const deleteConnection =
  actionCreatorWithIntegrationId<ConnectionOperationPayload>(
    `${NAMESPACE}_DELETE_CONNECTION`
  );

type ConnectionStatusPayload = {
  statuses: Partial<ConnectionsAsyncOperationsState>;
};

export const deleteConnectionFailure =
  actionCreatorWithIntegrationId<ConnectionStatusPayload>(
    `${NAMESPACE}_DELETE_CONNECTION_FAILURE`
  );

export const updateConnectionStatuses =
  actionCreatorWithIntegrationId<ConnectionStatusPayload>(
    `${NAMESPACE}_UPDATE_CONNECTIONS_STATUSES`
  );

export const upsertConnectionFailure =
  actionCreatorWithIntegrationId<ConnectionStatusPayload>(
    `${NAMESPACE}_UPSERT_CONNECTION_FAILURE`
  );

type UpdateConnectionsListPayload = {
  connectionsState: Partial<IntegrationConnectionsState>;
};

export const updateConnectionList =
  actionCreatorWithIntegrationId<UpdateConnectionsListPayload>(
    `${NAMESPACE}_UPDATE_CONNECTIONS_LIST`
  );

export const connectionClick =
  actionCreatorWithIntegrationId<ConnectionOperationPayload>(
    `${NAMESPACE}_CONNECTION_CLICK`
  );

type UpsertConnectionInitiatePayload = {
  connection?: Connection;
};

export const upsertConnectionInitiate =
  actionCreatorWithIntegrationId<UpsertConnectionInitiatePayload>(
    `${NAMESPACE}_UPSERT_CONNECTION_INITIATE`
  );

type UpdateConnectionPayload = {
  selectedConnectionId: Maybe<Connection['_id']>;
};

export const selectConnection =
  actionCreatorWithIntegrationId<UpdateConnectionPayload>(
    `${NAMESPACE}_SELECT_CONNECTION`
  );

export const resetSelectedConnectionIds = actionCreatorWithIntegrationId(
  `${NAMESPACE}_RESET_SELECTED_CONNECTION_IDS`
);

export const updateSingleSelectedConnectionId =
  actionCreatorWithIntegrationId<UpdateConnectionPayload>(
    `${NAMESPACE}_UPDATE_CONNECTION`
  );

type UpdateSelectedConnectionsIds = {
  selectedConnectionIds: string[];
};

export const updateSelectedConnectionsIds =
  actionCreatorWithIntegrationId<UpdateSelectedConnectionsIds>(
    `${NAMESPACE}_UPDATE_SELECTED_CONNECTIONS`
  );
