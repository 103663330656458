import { dispatchAction } from '@ardoq/rxbeach';
import { closeAllWorkspaces } from 'streams/workspaces/actions';
import { confirmCloseAllDatasets } from 'viewpointNavigator/confirmCloseAllDatasets';
import { openViewpointBuilderWithoutClosingLoadedViewpoints } from 'viewpointBuilder/actions';
import { trackEvent } from 'tracking/tracking';
import { MainAppModuleTrackingEventsNames } from '../tracking';

const closeAllDatasets = async () => {
  const confirm = await confirmCloseAllDatasets();
  if (!confirm) return;
  dispatchAction(closeAllWorkspaces());
};

const openMoreData = () => {
  trackEvent(MainAppModuleTrackingEventsNames.CLICKED_OPEN_MORE_DATA);
  dispatchAction(openViewpointBuilderWithoutClosingLoadedViewpoints());
};

export type LoadedStateCommands = {
  openMoreData: () => void;
  closeAllDatasets: () => Promise<void>;
};

export const loadedStateCommands: LoadedStateCommands = {
  openMoreData,
  closeAllDatasets,
};
