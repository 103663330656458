import {
  DirectedTripleWithFilters,
  isQueryBuilderSubquery,
  QueryBuilderRule,
  QueryBuilderSubquery,
  ViewIds,
} from '@ardoq/api-types';
import { LabelFormattingInfo } from '@ardoq/data-model';
import { uniq } from 'lodash';
import { trackEvent } from 'tracking/tracking';
import { getLabelFormatingRulesWithFieldsCountByType } from '../utils/getLabelFormatingRulesWithFieldsCountByType';
import {
  ViewpointBuilderAdvancedSearchState,
  ViewpointBuilderAdvancedSearchValidState,
} from './selectContextTab/types';
import {
  GraphId,
  PathCollapsingRuleInternal,
  TraversalBuilderFiltersPerNode,
  ViewpointBuilderContext,
  ViewpointBuilderState,
} from './types';

const getSearchType = ({
  advancedSearchState,
  startQuery,
}: ViewpointBuilderState['searchComponentsState']) => {
  if (advancedSearchState) {
    return 'Advanced search';
  } else if (startQuery) {
    return 'Query';
  }

  return 'Component ID';
};

const getLongestPathLength = (paths: DirectedTripleWithFilters[][]) =>
  paths?.reduce((longestPathLength, path) => {
    return path.length > longestPathLength ? path.length : longestPathLength;
  }, 0) ?? 0;

const getRequiredComponentsLongestPathLength = (
  paths: DirectedTripleWithFilters[][]
) => {
  const arrayOfRequiredComponentsPathLengths = paths.map(
    path => path.findLastIndex(({ qualifier }) => qualifier === 'required') + 1
  );

  // Get the largest number from the array, representing the longest required path.
  return Math.max(...arrayOfRequiredComponentsPathLengths);
};

const getOrigin = (context: ViewpointBuilderContext) => {
  switch (context) {
    case 'editSubgraph':
      return 'Edit dataset with traversal';

    case 'editSearch':
      return 'Edit dataset without traversal';

    case 'contextMenu':
      return 'Viewpoint builder opened from context menu';

    case 'openViewpoint':
      return 'Open existing viewpoint';

    case 'loadNewViewpoint':
      return 'Load new dataset from "Explore data" or "Open more"';

    case 'editViewpoint':
      return 'Edit existing viewpoint';

    case 'createViewpoint':
      return 'Create viewpoint';

    default:
      return context satisfies never;
  }
};

const getComponentTypeCount = (
  state: ViewpointBuilderState['searchComponentsState']
) =>
  state.allComponentsSelected
    ? uniq(state.loadedComponents.map(c => c.entityType)).length
    : uniq(state.selectedComponents.map(c => c.entityType)).length;

const getLocalFiltersCount = (
  localFilters: string[],
  filters: Record<GraphId, TraversalBuilderFiltersPerNode>
) =>
  localFilters.reduce(
    (count, filterId) =>
      count +
      (Object.values(filters).find(
        ({ localFilterId }) => localFilterId === filterId
      )?.filters.length || 0),
    0
  );

const getAppliedFiltersCount = (
  paths: DirectedTripleWithFilters[][],
  filters: Record<GraphId, TraversalBuilderFiltersPerNode>
) => {
  const componentLocalFilterIDs = new Set<string>();
  const referenceLocalFiltersIDs = new Set<string>();

  paths.forEach(path => {
    path.forEach(({ targetFilter, sourceFilter, referenceFilter }) => {
      if (targetFilter) componentLocalFilterIDs.add(targetFilter);
      if (sourceFilter) componentLocalFilterIDs.add(sourceFilter);
      if (referenceFilter) referenceLocalFiltersIDs.add(referenceFilter);
    });
  });

  const componentFiltersAppliedCount = getLocalFiltersCount(
    Array.from(componentLocalFilterIDs),
    filters
  );
  const referenceFiltersAppliedCount = getLocalFiltersCount(
    Array.from(referenceLocalFiltersIDs),
    filters
  );

  return {
    referenceFiltersAppliedCount,
    componentFiltersAppliedCount,
  };
};

const isValidAdvancedSearch = (
  state: ViewpointBuilderAdvancedSearchState
): state is ViewpointBuilderAdvancedSearchValidState =>
  state?.status === 'valid' && state.query !== null;

const calculateNumberOfRulesInSubQuery = (
  numberOfRules: number = 0,
  query: QueryBuilderSubquery | QueryBuilderRule
): number => {
  if (isQueryBuilderSubquery(query)) {
    // If subQuery, we do recursion for each rule in the subQuery
    return query.rules.reduce(calculateNumberOfRulesInSubQuery, numberOfRules);
  }

  return numberOfRules + 1;
};

const getAdvancedSearchNumberOfRules = (
  advancedSearchState: ViewpointBuilderAdvancedSearchValidState
): number => {
  const { query } = advancedSearchState;
  const subQuery = query.rules[1];

  return calculateNumberOfRulesInSubQuery(0, subQuery);
};

const getAdvancedSearchCondition = (
  advancedSearchState: ViewpointBuilderAdvancedSearchValidState
): string => {
  const { query } = advancedSearchState;
  const subQuery = query.rules[1];

  return subQuery.condition;
};

const getCollapsedPathsLongestPathLength = (
  rules: PathCollapsingRuleInternal[]
): number => {
  if (rules.length === 0) {
    return 0;
  }

  return rules.reduce((longestPathLength, rule) => {
    if (!rule.path) {
      return longestPathLength;
    }
    return Math.max(rule.path.length, longestPathLength);
  }, 0);
};

const getViewpointTrackingMeta = (state: ViewpointBuilderState) => {
  const {
    editTraversalsState: { filters, pathMatching, pathCollapsing },
    searchComponentsState,
    viewpointBuilderNavigationState: {
      context,
      collapsedPathsCount,
      requiredComponentsCount,
    },
  } = state;

  const paths = state.editTraversalsState.stateAsSavableAttributes.paths;
  const advancedSearchState = searchComponentsState.advancedSearchState;

  const advancedSearchNumberOfRules = isValidAdvancedSearch(advancedSearchState)
    ? getAdvancedSearchNumberOfRules(advancedSearchState)
    : 0;
  const advancedSearchCondition = isValidAdvancedSearch(advancedSearchState)
    ? getAdvancedSearchCondition(advancedSearchState)
    : undefined;

  const componentsLoaded = searchComponentsState.allComponentsSelected
    ? searchComponentsState.totalCount
    : searchComponentsState.selectedComponents.length;

  if (!paths.length) {
    return {
      type: 'search',
      searchType: getSearchType(searchComponentsState),
      origin: getOrigin(context),
      componentsLoaded,
      componentTypeCount: getComponentTypeCount(searchComponentsState),
      advancedSearchNumberOfRules,
      advancedSearchCondition,
    };
  }

  const { componentFiltersAppliedCount, referenceFiltersAppliedCount } =
    getAppliedFiltersCount(paths, filters);

  const hasStartSetFilter =
    !!state.editTraversalsState.stateAsSavableAttributes.startSetFilterId;

  return {
    type: 'traversal',
    searchType: getSearchType(searchComponentsState),
    origin: getOrigin(context),
    hasStartSetFilter,
    componentsLoaded,
    traversalLoaded: {
      pathsCount: paths.length,
      longestPath: getLongestPathLength(paths),
      componentTypes: state.editTraversalsState.graphNodes.size,
      referenceTypes: state.editTraversalsState.graphEdges.size,
      filtersApplied: Object.keys(filters).length,
      componentFiltersApplied: componentFiltersAppliedCount,
      referenceFiltersApplied: referenceFiltersAppliedCount,
    },
    requiredComponents: {
      count: requiredComponentsCount,
      maxDepth: getRequiredComponentsLongestPathLength(paths),
      pathMatching: pathMatching,
    },
    advancedSearchNumberOfRules,
    advancedSearchCondition,
    collapsedPaths: {
      count: collapsedPathsCount,
      maxDepth: getCollapsedPathsLongestPathLength(pathCollapsing.rules),
    },
  };
};

const trackOpenDataSet = (state: ViewpointBuilderState) => {
  trackEvent(
    'Viewpoint Builder0 Dataset properties',
    getViewpointTrackingMeta(state)
  );
};

const trackMultiLabelApply = (
  viewStyle: ViewIds,
  labelFormatting: LabelFormattingInfo[]
) => {
  const { referenceLabelsCount, componentLabelsCount } =
    getLabelFormatingRulesWithFieldsCountByType(labelFormatting);
  trackEvent('Viewpoint builder0 Applied multi-label formatting', {
    referenceLabelsCount,
    componentLabelsCount,
    viewStyle,
  });
};

const trackComponentTypeNodeSelectedInGraph = (tab: string) => {
  trackEvent('Viewpoint builder0 Selected component type node in graph', {
    tab,
  });
};

const trackOpenMetaModel = () => {
  trackEvent('Viewpoint builder0 Viewed metamodel');
};

const trackCloseViewpointBuilder = () => {
  trackEvent('Viewpoint builder0 Cancelled');
};

const trackSelectReferenceTraversalTripleSortOrder = () => {
  trackEvent('Traversal dialog: Triple sort order reference selected');
};

const trackSelectComponentTraversalTripleSortOrder = () => {
  trackEvent('Traversal dialog: Triple sort order component selected');
};

const trackSelectDescendingInstanceCountTraversalTripleSortOrder = () => {
  trackEvent(
    'Traversal dialog: Triple sort order Descending instance count selected'
  );
};

const trackClickNavigationTab = (tab: string) => {
  trackEvent('Viewpoint Builder0 Clicked tab', {
    tab,
  });
};
const trackOpenSavedViewpoint = (state: ViewpointBuilderState) => {
  trackEvent(
    'Viewpoint builder0 Opened saved viewpoint',
    getViewpointTrackingMeta(state)
  );
};

export const viewpointBuilderTracking = {
  trackOpenDataSet,
  trackMultiLabelApply,
  trackComponentTypeNodeSelectedInGraph,
  trackOpenMetaModel,
  trackCloseViewpointBuilder,
  trackSelectReferenceTraversalTripleSortOrder,
  trackSelectComponentTraversalTripleSortOrder,
  trackSelectDescendingInstanceCountTraversalTripleSortOrder,
  trackClickNavigationTab,
  trackOpenSavedViewpoint,
};
