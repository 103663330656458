import { useState } from 'react';
import {
  AllPossibleQuestions,
  SurveyQuestionValidations,
} from 'surveyAdmin/types';
import {
  APIFieldAttributes,
  APIFieldType,
  ArdoqId,
  PartiallyPersistedConditionalQuestion,
  PersistedConditionalFieldQuestion,
  PersistedFieldQuestion,
  PersistedFieldSubQuestion,
  SurveyQuestionType,
  UnpersistedConditionalFieldQuestion,
  UnpersistedFieldQuestion,
  UnpersistedFieldSubQuestion,
} from '@ardoq/api-types';
import QuestionTypeButtons from '../QuestionTypeButtons';
import {
  DragAndDropContextState,
  DragAndDropSectionsProvider,
  Position,
  arrayMove,
} from '@ardoq/drag-and-drop';
import { getBarColor, getQuestionId } from '../utils';
import AddSectionButton from '../AddSectionButton';
import DraggableDependentQuestion from './DraggableDependentQuestion';
import { trackEvent } from 'tracking/tracking';
import { PermissionContext } from '@ardoq/access-control';
import { createDependentQuestion } from './utils';
import { isConditionalQuestion } from '../questionPredicates';
import { dispatchAction } from '@ardoq/rxbeach';
import { setSurveyQuestionFieldHasBeenInteractedWith } from 'surveyAdmin/SurveyEditor/streams/actions';

type DependentQuestionsSectionProps = {
  question:
    | PersistedFieldQuestion
    | UnpersistedFieldQuestion
    | PersistedConditionalFieldQuestion
    | UnpersistedConditionalFieldQuestion
    | PartiallyPersistedConditionalQuestion;
  questions?: AllPossibleQuestions[];
  fields: APIFieldAttributes[];
  attributes: {
    label: string;
    attributeName: string;
  }[];
  workspaceId: ArdoqId | null;
  questionValidations?: SurveyQuestionValidations[];
  surveyComponentTypeId?: string;
  permissionsContext: PermissionContext;
  surveyIsPermissionDivisionsAware: boolean;
  hasSurveyResponseApprovalsFeature: boolean;
  addDependentQuestion: (
    dependentQuestion: UnpersistedFieldSubQuestion
  ) => void;
  updateQuestion: (
    dependentQuestions: (
      | UnpersistedFieldSubQuestion
      | PersistedFieldSubQuestion
    )[]
  ) => void;
};

const DependentQuestionsSection = ({
  question,
  fields,
  attributes,
  questions,
  workspaceId,
  questionValidations,
  surveyComponentTypeId,
  permissionsContext,
  surveyIsPermissionDivisionsAware,
  hasSurveyResponseApprovalsFeature,
  addDependentQuestion,
  updateQuestion,
}: DependentQuestionsSectionProps) => {
  const [showQuestionTypeButtons, setShowQuestionTypeButtons] = useState(false);
  const [expandedQuestion, setExpandedQuestion] = useState<string | undefined>(
    undefined
  );

  const handleAddDependentQuestion = (
    type:
      | SurveyQuestionType.ATTRIBUTE
      | SurveyQuestionType.FIELD
      | SurveyQuestionType.REFERENCE
  ) => {
    trackEvent('Survey builder: added conditional question', { type });
    const dependentQuestion = createDependentQuestion(type);
    if (!dependentQuestion) return;
    addDependentQuestion(dependentQuestion);
    expandQuestion(dependentQuestion);
  };

  const expandQuestion = (
    question: PersistedFieldSubQuestion | UnpersistedFieldSubQuestion
  ) => {
    if (expandedQuestion) {
      dispatchAction(
        setSurveyQuestionFieldHasBeenInteractedWith({
          questionKey: expandedQuestion,
          fieldKey: 'questionViewHasBeenClosed',
        })
      );
    }
    const questionId = getQuestionId(question);
    const updatedExpandedQuestion =
      expandedQuestion === questionId ? undefined : questionId;
    setExpandedQuestion(updatedExpandedQuestion);
  };

  const parentQuestionField = fields.find(
    field => field.name === question.properties.fieldName
  );

  if (
    parentQuestionField?.type !== APIFieldType.CHECKBOX &&
    parentQuestionField?.type !== APIFieldType.LIST
  )
    return null;

  const onSortEnd = (
    newOrder: string[],
    { draggedCardId }: DragAndDropContextState
  ) => {
    if (!isConditionalQuestion(question)) return;
    const dependentQuestions = question.properties.questions;
    if (!dependentQuestions?.length || !draggedCardId) return;
    const oldIndex = dependentQuestions.findIndex(
      dependentQ => getQuestionId(dependentQ) === draggedCardId
    );
    const newIndex = newOrder.indexOf(draggedCardId);

    const updatedOrder = arrayMove(dependentQuestions, oldIndex, newIndex);
    updateQuestion(updatedOrder);
  };

  const handleOrderChange = (id: string | null, position: Position | null) => {
    if (!isConditionalQuestion(question)) return;
    const dependentQuestions = question.properties.questions;
    if (!dependentQuestions?.length || !id) return;

    const currentIndex = dependentQuestions.findIndex(
      dependentQ => getQuestionId(dependentQ) === id
    );
    if (currentIndex === 0 && position === 'above') return;

    const newOrder = arrayMove(
      dependentQuestions,
      currentIndex,
      position === 'above' ? currentIndex - 1 : currentIndex + 1
    );
    updateQuestion(newOrder);
  };

  return (
    <>
      {isConditionalQuestion(question) &&
      question.properties.questions?.length ? (
        <DragAndDropSectionsProvider onDrop={onSortEnd}>
          {question.properties.questions.map((dependentQ, index) => {
            const questionId = getQuestionId(dependentQ);
            return (
              <DraggableDependentQuestion
                question={dependentQ}
                questionId={questionId}
                key={index}
                index={index}
                dragAndDropCardId={questionId}
                fields={fields}
                attributes={attributes}
                questions={questions}
                workspaceId={workspaceId}
                parentQuestionTitle={question.label}
                parentQuestionIsReadonly={question.readonly}
                questionValidations={questionValidations}
                surveyComponentTypeId={surveyComponentTypeId}
                parentQuestionField={parentQuestionField}
                expandedQuestion={expandedQuestion}
                dependentQuestions={question.properties.questions}
                permissionsContext={permissionsContext}
                surveyIsPermissionDivisionsAware={
                  surveyIsPermissionDivisionsAware
                }
                hasSurveyResponseApprovalsFeature={
                  hasSurveyResponseApprovalsFeature
                }
                expandQuestion={() => expandQuestion(dependentQ)}
                handleOrderChange={handleOrderChange}
                updateQuestion={updateQuestion}
              />
            );
          })}
        </DragAndDropSectionsProvider>
      ) : null}

      {showQuestionTypeButtons ? (
        <QuestionTypeButtons
          buttonConfig={[
            {
              label: 'Conditional field question',
              dataTestId: 'conditional-field-button',
              onClick: () => {
                handleAddDependentQuestion(SurveyQuestionType.FIELD);
                setShowQuestionTypeButtons(false);
              },
              color: getBarColor(SurveyQuestionType.FIELD),
            },
            {
              label: 'Conditional reference question',
              dataTestId: 'conditional-reference-button',
              onClick: () => {
                handleAddDependentQuestion(SurveyQuestionType.REFERENCE);
                setShowQuestionTypeButtons(false);
              },
              color: getBarColor(SurveyQuestionType.REFERENCE),
            },
          ]}
          onCancelButtonClick={() => {
            setShowQuestionTypeButtons(false);
            trackEvent(
              'Survey builder: clicked cancel add conditional section'
            );
          }}
        />
      ) : (
        <AddSectionButton
          onClick={() => {
            setShowQuestionTypeButtons(true);
            trackEvent('Survey builder: clicked add conditional section');
          }}
          title="Add conditional section"
          subtitle="Based on the answers of this questions, display different follow-up questions"
          dataTestId="add-conditional-section-button"
        />
      )}
    </>
  );
};

export default DependentQuestionsSection;
