import { ViewIds } from '@ardoq/api-types';

export const LAYOUT_SETTINGS_BY_VIEW_ID = new Map<ViewIds, string[]>([
  [
    ViewIds.BLOCK_DIAGRAM,
    ['layoutOrientation', 'separateReferences', 'showOnlyConnectedComponents'],
  ],
  [
    ViewIds.MODERNIZED_BLOCK_DIAGRAM,
    ['layoutOrientation', 'separateReferences', 'showOnlyConnectedComponents'],
  ],
  [
    ViewIds.RELATIONSHIPS_3,
    ['bundleRelationships', 'showOnlyConnectedComponents'],
  ],
  [
    ViewIds.DEPENDENCY_MAP_2,
    ['showVertical', 'colorByReference', 'showOnlyConnectedComponents'],
  ],
  [
    ViewIds.TIMELINE,
    [
      'domainRange',
      'gridPrecision',
      'selectedPhaseFields',
      'selectedMilestoneFields',
    ],
  ],
]);

export const PERSISTED_VIEW_ACTIONS_SETTINGS_BY_VIEW_ID = new Map<
  ViewIds,
  string[]
>([
  [
    ViewIds.DEPENDENCY_MAP_2,
    ['degreesOfParenthood', 'degreesOfChildhood', 'treeDepth'],
  ],
]);
