import { ArdoqId } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { MetamodelPane } from './navigation/types';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { selectMetamodel, selectMetamodelPane } from './navigation/actions';

export const goToMetamodel = (metamodelId: ArdoqId | undefined) => {
  dispatchAction(selectMetamodel(metamodelId));
  dispatchAction(selectMetamodelPane({ pane: MetamodelPane.SELECTED }));
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.METAMODEL })
  );
};

export const goToMetamodelList = () => {
  dispatchAction(selectMetamodelPane({ pane: MetamodelPane.LIST }));
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.METAMODEL })
  );
};
