import { APIEntityType, APIOrganizationUser, ArdoqId } from '@ardoq/api-types';
import {
  DataSourceItem,
  MergeStep,
  StructuralConflict,
} from 'components/DiffMergeTable/types';
import AdditionalContent from 'components/DiffMergeTable/AdditionalContent/AdditionalContent';
import { Branch } from 'components/DiffMergeTable/Branch';
import {
  getComponentIdsAffectedByComponentTypeDeletion,
  getReferencesByReferenceTypeOnTarget,
} from 'components/DiffMergeTable/typeCollectionsUtil';
import {
  collectRelatedReferenceIds,
  getEntitiesAffectedByFieldDeletion,
  getEntitiesAffectedByTagDeletion,
} from 'components/DiffMergeTable/utils';
import AdditionalContentOfStructuralConflict from 'components/DiffMergeTable/AdditionalContent/AdditionalContentOfStructuralConflict';
import { MergeDirection } from 'scope/merge/MergeDirection';
import AdditionalContentForTypeConflict from 'components/DiffMergeTable/AdditionalContent/AdditionalContentForTypeConflict';
import AdditionalContentForMissingComponents from 'components/DiffMergeTable/AdditionalContent/AdditionalContentForMissingComponents';
import {
  Graphics,
  RenderOptions,
  getModelTypeDictionaryKey,
} from '@ardoq/renderers';
import { componentUtils } from '@ardoq/scope-data';

interface GetAdditionalContentRendererArgs {
  entityType: APIEntityType;
  users: Record<ArdoqId, APIOrganizationUser>;
  options: RenderOptions;
  graphics: Graphics;
  mergeStep: MergeStep;
  mergeDirection: MergeDirection;
}

export const getAdditionalContentRenderer = ({
  mergeStep,
  mergeDirection,
  ...rest
}: GetAdditionalContentRendererArgs) => {
  switch (mergeStep) {
    case MergeStep.CREATE_COMPONENTS:
    case MergeStep.CREATE_COMPONENT_TYPES:
    case MergeStep.CREATE_REFERENCE_TYPES:
    case MergeStep.CREATE_REFERENCES:
    case MergeStep.CREATE_TAGS:
    case MergeStep.CREATE_FIELDS:
      return (dataSourceItem: DataSourceItem) => (
        <div>
          {dataSourceItem.hasTypeConflict ? (
            <AdditionalContentForTypeConflict
              entityType={rest.entityType}
              graphics={rest.graphics}
              dataSourceItem={dataSourceItem}
              mergeDirection={mergeDirection}
            />
          ) : dataSourceItem.structuralConflict !== StructuralConflict.NONE ? (
            <AdditionalContentOfStructuralConflict
              {...rest}
              dataSourceItem={dataSourceItem}
              branch={Branch.SOURCE}
            />
          ) : dataSourceItem.missingComponentConflict ? (
            <AdditionalContentForMissingComponents
              dataSourceItem={dataSourceItem}
              mergeDirection={mergeDirection}
              {...rest}
            />
          ) : (
            <AdditionalContent
              {...rest}
              mergeStep={mergeStep}
              dataSourceItem={dataSourceItem}
              branch={Branch.SOURCE}
            />
          )}
        </div>
      );
    case MergeStep.DELETE_COMPONENT_TYPES:
      return (dataSourceItem: DataSourceItem) => (
        <AdditionalContent
          {...rest}
          mergeStep={mergeStep}
          componentIds={getComponentIdsAffectedByComponentTypeDeletion(
            dataSourceItem.enhancedDiffContextData,
            getModelTypeDictionaryKey(
              dataSourceItem.parentEntityId!,
              dataSourceItem.entityId
            )
          )}
          dataSourceItem={dataSourceItem}
          branch={Branch.TARGET}
        />
      );

    case MergeStep.DELETE_REFERENCE_TYPES:
      return (dataSourceItem: DataSourceItem) => (
        <AdditionalContent
          {...rest}
          mergeStep={mergeStep}
          referenceIds={getReferencesByReferenceTypeOnTarget(
            dataSourceItem.enhancedDiffContextData,
            getModelTypeDictionaryKey(
              dataSourceItem.parentEntityId!,
              dataSourceItem.entityId
            )
          )}
          dataSourceItem={dataSourceItem}
          branch={Branch.TARGET}
        />
      );
    case MergeStep.DELETE_COMPONENTS:
      return (dataSourceItem: DataSourceItem) => (
        <AdditionalContent
          {...rest}
          mergeStep={mergeStep}
          componentIds={componentUtils.getDescendantIds(
            dataSourceItem.enhancedDiffContextData[Branch.TARGET],
            dataSourceItem.entityId
          )}
          referenceIds={collectRelatedReferenceIds(
            dataSourceItem.entityId,
            dataSourceItem.enhancedDiffContextData[Branch.TARGET].graphData
          )}
          dataSourceItem={dataSourceItem}
          branch={Branch.TARGET}
        />
      );
    case MergeStep.DELETE_FIELDS:
      return (dataSourceItem: DataSourceItem) => (
        <AdditionalContent
          {...rest}
          {...getEntitiesAffectedByFieldDeletion(
            dataSourceItem.entityId,
            dataSourceItem.enhancedDiffContextData[Branch.TARGET]
          )}
          mergeStep={mergeStep}
          dataSourceItem={dataSourceItem}
          branch={Branch.TARGET}
        />
      );
    case MergeStep.DELETE_REFERENCES:
      return (dataSourceItem: DataSourceItem) => (
        <AdditionalContent
          {...rest}
          mergeStep={mergeStep}
          dataSourceItem={dataSourceItem}
          branch={Branch.TARGET}
        />
      );
    case MergeStep.DELETE_TAGS:
      return (dataSourceItem: DataSourceItem) => (
        <AdditionalContent
          {...rest}
          mergeStep={mergeStep}
          dataSourceItem={dataSourceItem}
          {...getEntitiesAffectedByTagDeletion(
            dataSourceItem.entityId,
            dataSourceItem.enhancedDiffContextData[Branch.TARGET]
          )}
          branch={Branch.TARGET}
        />
      );
  }
  return (_: DataSourceItem) => null;
};
