import { createElement } from 'react';
import { withLatestFrom } from 'rxjs/operators';
import broadcast$ from 'broadcasts/broadcast$';
import BroadcastDetails from '../components/BroadcastDetails';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  deleteBroadcast,
  pauseBroadcast,
  renameBroadcast,
  showBroadcastDetailsDialog,
  showBroadcastPauseDialog,
  triggerBroadcast,
} from 'broadcasts/broadcastOverview/actions';
import { map, switchMap, tap } from 'rxjs/operators';
import { initiateNavigationToEditBroadcastForm } from 'router/navigationActions';
import { getBroadcastDetailsDialogData } from 'broadcasts/infoDialogDataUtils';
import { BroadcastContentType, BroadcastStatus } from '@ardoq/api-types';
import { findBroadcast } from 'broadcasts/utils';
import {
  launchBroadcast,
  notifyDeletingBroadcastSucceeded,
  notifyUpdatingBroadcastSucceeded,
} from 'broadcasts/actions';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';
import {
  ensureBroadcastMayBeOverwritten$,
  fetchBroadcasts$,
  syncCurrentBroadcastWithBroadcastList$,
} from 'broadcasts/routineUtils';
import {
  BroadcastEditOrigin,
  trackClickedToEditBroadcast,
} from 'broadcasts/tracking';
import confirmPauseBroadcast from './confirmPauseBroadcast';
import notifyBroadcastError from './notifyBroadcastError';
import { ModalSize, confirm } from '@ardoq/modal';
import reports$ from 'streams/reports/reports$';
import { fetchAudiencePreview } from 'broadcasts/broadcastBuilder/audiencePreview/actions';
import { broadcastApi, handleError } from '@ardoq/api';
import { showToast, ToastType } from '@ardoq/status-ui';

const handleShowBroadcastDetailsDialog = routine(
  ofType(showBroadcastDetailsDialog),
  extractPayload(),
  withLatestFrom(broadcast$, reports$),
  tap(async ([broadcastId, { broadcasts }, { byId: reportsById }]) => {
    const broadcast = broadcasts.find(b => b._id === broadcastId);
    if (!broadcast) return;
    dispatchAction(fetchAudiencePreview(broadcast));
    const { errors, warnings } = broadcast;
    const report =
      broadcast.content.contentType === BroadcastContentType.REPORT &&
      broadcast.content.contentId
        ? reportsById[broadcast.content.contentId]
        : null;
    await confirm({
      title: 'Broadcast details',
      body: createElement(BroadcastDetails, {
        ...getBroadcastDetailsDialogData(broadcast),
        report,
        errors,
        warnings,
      }),
      onConfirmAsync: () => {
        dispatchAction(initiateNavigationToEditBroadcastForm(broadcastId));
        trackClickedToEditBroadcast(
          BroadcastEditOrigin.BROADCAST_DETAILS_DIALOG
        );
      },
      cancelButtonTitle: 'Close',
      confirmButtonTitle: 'Edit',
      modalSize: ModalSize.S,
    });
  })
);

const handleShowBroadcastPauseDialog = routine(
  ofType(showBroadcastPauseDialog),
  extractPayload(),
  withLatestFrom(broadcast$),
  map(([broadcastId, { broadcasts }]) => {
    return findBroadcast(broadcasts, broadcastId);
  }),
  excludeNullAndUndefined(),
  tap(async broadcast => {
    if (broadcast.scheduling.reminderInDays) {
      const shouldPauseBroadcast = await confirmPauseBroadcast(broadcast.name);
      if (!shouldPauseBroadcast) return;
    }
    dispatchAction(pauseBroadcast(broadcast._id));
  })
);

const handlePauseBroadcast = routine(
  ofType(pauseBroadcast),
  extractPayload(),
  switchMap(broadcastId =>
    broadcastApi.setStatus(broadcastId, BroadcastStatus.PAUSED)
  ),
  handleError(),
  tap(broadcast => dispatchAction(notifyUpdatingBroadcastSucceeded(broadcast)))
);

const handleRenameBroadcast = routine(
  ofType(renameBroadcast),
  extractPayload(),
  switchMap(broadcastApi.rename),
  switchMap(fetchBroadcasts$),
  switchMap(syncCurrentBroadcastWithBroadcastList$)
);

const handleDeleteBroadcast = routine(
  ofType(deleteBroadcast),
  extractPayload(),
  switchMap(broadcastId => {
    return broadcastApi.delete(broadcastId);
  }),
  handleError(),
  tap(broadcastId => {
    dispatchAction(notifyDeletingBroadcastSucceeded(broadcastId));
  })
);

const handleTriggerBroadcast = routine(
  ofType(triggerBroadcast),
  extractPayload(),
  switchMap(broadcast =>
    ensureBroadcastMayBeOverwritten$(broadcast._id).pipe(
      switchMap(() => broadcastApi.trigger(broadcast._id)),
      handleError(notifyBroadcastError),
      tap(() => {
        showToast('Broadcast sent successfully', ToastType.SUCCESS);
        if (broadcast.status === BroadcastStatus.RUNNING) return;
        dispatchAction(launchBroadcast(broadcast));
      }),
      switchMap(fetchBroadcasts$),
      switchMap(syncCurrentBroadcastWithBroadcastList$)
    )
  )
);

export default collectRoutines(
  handleShowBroadcastDetailsDialog,
  handleShowBroadcastPauseDialog,
  handlePauseBroadcast,
  handleRenameBroadcast,
  handleDeleteBroadcast,
  handleTriggerBroadcast
);
