import styled, { css } from 'styled-components';
import { colors } from '@ardoq/design-tokens';

const messageBoxStyles = css`
  border-radius: 4px;
  margin: 10px 0;
  padding: 10px 20px;
`;
const ErrorBoxContainer = styled.div`
  ${messageBoxStyles}
  background-color: ${colors.red60};
  border: 2px solid ${colors.borderError};

  color: white;
  ul > li {
    margin-bottom: 5px;
  }
`;

type ErrorBoxProps = {
  errorMsgs: string[];
  style?: { [attr: string]: string };
};
export const ErrorBox = ({ errorMsgs, style = {} }: ErrorBoxProps) => (
  <ErrorBoxContainer style={{ ...style }}>
    <p>The following errors have been found:</p>
    <ul style={{ wordWrap: 'break-word' }}>
      {errorMsgs.map((msg: string, index: number) => (
        <li key={index}>{msg}</li>
      ))}
    </ul>
  </ErrorBoxContainer>
);

export const SuccessBox = styled.div`
  ${messageBoxStyles}
  font-size: 24px;
  color: white;
  text-align: center;
  background-color: ${colors.green50};
  border: 2px solid ${colors.green50};
`;

export const Description = styled.div`
  font-style: italic;
  margin-top: 5px;
  font-size: 12px;
`;
