import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { EMPTY, combineLatest, of, switchMap } from 'rxjs';
import { getOverviewDatasource } from './utils';
import { tableStatuses$ } from 'integrations/common/streams/tabularMappings/tableStatus$';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      activeIntegration: getActiveIntegrationStream(integrationId),
      tablePreviews: getTablePreviewsStream(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
      tableStatuses: tableStatuses$,
    });
  }),
  switchMap(
    ({ activeIntegration, tablePreviews, tabularMapping, tableStatuses }) => {
      if (!activeIntegration.currentTableId) {
        return EMPTY;
      }

      return of({
        integrationName: activeIntegration.integrationName,
        dataSource: getOverviewDatasource(
          tablePreviews,
          tabularMapping,
          tableStatuses
        ),
      });
    }
  )
);
