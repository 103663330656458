import {
  TransferResponse,
  RunParams,
  WithTabularMapping,
  TransferDirection,
  InfoMessage,
} from './types';
import { actionCreatorWithIntegrationId } from 'integrations/common/utils/actionCreatorWithIntegrationId';

const NAMESPACE = '[integrations] TRANSFER_STATE';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

type WithOptionalIsSelectionDataFetched = {
  isSelectionDataFetched?: boolean;
};

type ResetTransferPayload = {
  resetTransferDirection?: boolean;
};

export const resetTransfer =
  actionCreatorWithIntegrationId<ResetTransferPayload>(
    getActionName('RESET_TRANSFER')
  );

type StartTransferPayload = RunParams &
  WithTabularMapping &
  WithOptionalIsSelectionDataFetched;

export const startTransfer =
  actionCreatorWithIntegrationId<StartTransferPayload>(
    getActionName('START_TRANSFER')
  );

type TransferSuccessPayload = {
  response: TransferResponse;
  dryRun: boolean;
  transferDirection: TransferDirection;
};

export const transferSuccess =
  actionCreatorWithIntegrationId<TransferSuccessPayload>(
    getActionName('TRANSFER_SUCCESS')
  );

type UpdateTabularMappingPayload = WithTabularMapping;

export const updateTabularMapping =
  actionCreatorWithIntegrationId<UpdateTabularMappingPayload>(
    getActionName('UPDATE_TABULAR_MAPPING')
  );

type TransferFailurePayload = {
  dryRun: boolean;
  errorMessage: string;
};

export const transferFailure =
  actionCreatorWithIntegrationId<TransferFailurePayload>(
    getActionName('TRANSFER_FAILURE')
  );

type TransferPayload = Pick<RunParams, 'async' | 'dryRun'>;

export const transferRun = actionCreatorWithIntegrationId<TransferPayload>(
  getActionName('TRANSFER_RUN')
);

type SetTransferDirectionPayload = {
  transferDirection: TransferDirection;
};

export const setTransferDirection =
  actionCreatorWithIntegrationId<SetTransferDirectionPayload>(
    getActionName('SET_TRANSFER_DIRECTION')
  );

type SetInfoMessagePayload = InfoMessage;

export const setInfoMessage =
  actionCreatorWithIntegrationId<SetInfoMessagePayload>(
    getActionName('SET_INFO_MESSAGE')
  );

type UpdateSelectedWorkspacesPayload = {
  selectedWorkspaces: string[];
};

export const updateSelectedWorkspaces =
  actionCreatorWithIntegrationId<UpdateSelectedWorkspacesPayload>(
    getActionName('UPDATE_SELECTED_WORKSPACES')
  );

export const importRun = actionCreatorWithIntegrationId<RunParams>(
  getActionName('IMPORT_RUN')
);
export const exportRun = actionCreatorWithIntegrationId<RunParams>(
  getActionName('EXPORT_RUN')
);

export const setFetchSelectionDataSuccess = actionCreatorWithIntegrationId(
  getActionName('SET_FETCH_SELECTION_DATA_SUCCESS')
);
