import { ChevronIconName, Icon } from '@ardoq/icons';
import {
  TopBarButton,
  TopBarTitle,
  TopBarWrapper,
} from 'appContainer/MainAppModule/MainAppModuleSidebar/atoms';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import PerspectivesMenu from 'quickPerspectives/PerspectivesMenu';

type QuickPerspectivesTopBarProps = {
  toggleOpen?: (isOpened: boolean) => void;
  isOpened: boolean;
  userHasPermissionToSavePerspective: boolean;
};

const QuickPerspectivesTopBar = ({
  toggleOpen,
  isOpened,
  userHasPermissionToSavePerspective,
}: QuickPerspectivesTopBarProps) => {
  const scenarioMode = isScenarioMode();
  return (
    <TopBarWrapper $isScenarioMode={scenarioMode}>
      <TopBarTitle $isScenarioMode={scenarioMode}>Perspectives</TopBarTitle>
      <div style={{ display: 'flex', height: '100%' }}>
        <PerspectivesMenu
          isScenarioMode={scenarioMode}
          userHasPermissionToSavePerspective={
            userHasPermissionToSavePerspective
          }
        />
        {toggleOpen && (
          <TopBarButton
            $isScenarioMode={scenarioMode}
            data-click-id="perspectives-toggle"
            style={{ marginRight: 8 }}
            onClick={() => toggleOpen(!isOpened)}
          >
            <Icon
              iconName={isOpened ? ChevronIconName.DOWN : ChevronIconName.UP}
            />
          </TopBarButton>
        )}
      </div>
    </TopBarWrapper>
  );
};

export default QuickPerspectivesTopBar;
