import { Branch } from 'components/DiffMergeTable/Branch';
import PopoverDataProvider, { ProvidedProps } from './PopoverDataProvider';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { EntityRepresentation } from '@ardoq/renderers';
import styled from 'styled-components';
import { ListItem, PopoverRenderer } from '@ardoq/popovers';

const Wrapper = styled.div`
  min-width: 150px;
  display: flex;
  flex-direction: column;
`;

type EntitiesListPopoverProps = {
  branch: Branch;
  entityType: APIEntityType;
  entityIds: ArdoqId[];
  parentEntityId?: ArdoqId;
  width?: string;
};

const EntitiesListPopover = ({
  entityIds,
  entityType,
  parentEntityId,
  enhancedScopeData,
  graphics,
  width,
}: Omit<EntitiesListPopoverProps, 'branch'> & ProvidedProps) => (
  <Wrapper style={{ width }}>
    {entityIds.map(entityId => (
      <ListItem key={entityId}>
        <EntityRepresentation
          entityId={entityId}
          entityType={entityType}
          parentEntityId={parentEntityId}
          enhancedScopeData={enhancedScopeData}
          graphics={graphics}
        />
      </ListItem>
    ))}
  </Wrapper>
);

export const diffMergeEntitiesListPopoverRenderer: PopoverRenderer = target => {
  const {
    diffMergePopoverBranch: branch,
    diffMergePopoverEntityIds: entityIdsString,
    diffMergePopoverEntityType: entityType,
    diffMergePopoverParentEntityId: parentEntityId,
    diffMergePopoverWidth: width,
  } = (target as HTMLElement)?.dataset || {};

  if (!entityType || !entityIdsString || !branch) return null;

  return (
    <PopoverDataProvider branch={branch as Branch}>
      {data => (
        <EntitiesListPopover
          entityIds={entityIdsString.split(',') as ArdoqId[]}
          entityType={entityType as APIEntityType}
          parentEntityId={parentEntityId as ArdoqId}
          width={width}
          {...data}
        />
      )}
    </PopoverDataProvider>
  );
};
