import { FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Header3 } from '@ardoq/typography';

const BundleContentContainer = styled.div`
  min-height: 58vh;
`;

interface BundlesContentLayoutProps {
  title: string;
}

const BundlesContentLayout: FunctionComponent<
  PropsWithChildren<BundlesContentLayoutProps>
> = ({ title, children }) => (
  <BundleContentContainer>
    <Header3>{title}</Header3>
    {children}
  </BundleContentContainer>
);

export default BundlesContentLayout;
