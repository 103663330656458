import {
  PresentationReadPermissions,
  PresentationWritePermissions,
} from '@ardoq/api-types';

type WritePermLabels = { [key in PresentationWritePermissions]: string };
export const PRES_WRITE_PERMISSION_LABELS: WritePermLabels = {
  [PresentationWritePermissions.ME]: 'Only me',
  [PresentationWritePermissions.WS]: 'My Organization',
};

export type AccessPermLabels = { [key in PresentationReadPermissions]: string };
export const PRES_READ_PERMISSION_LABELS: AccessPermLabels = {
  [PresentationReadPermissions.ME]: 'Only me',
  [PresentationReadPermissions.WS]: "Use viewer's permissions",
  [PresentationReadPermissions.CONTRIBUTOR]: 'Contributors with the URL',
  [PresentationReadPermissions.ALL]: 'Everyone with the URL',
};

export const SUBDIVISION_PRESENTATION_READ_PERMISSION_LABELS: AccessPermLabels =
  {
    [PresentationReadPermissions.ME]: 'Private (only me)',
    [PresentationReadPermissions.WS]: 'Restricted (viewers see their data)',
    [PresentationReadPermissions.CONTRIBUTOR]:
      'Internal (anyone in the org with URL)',
    [PresentationReadPermissions.ALL]: 'Public (anyone with URL)',
  };
