import { setTransferConfigId } from 'integrations/common/streams/activeIntegrations/actions';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { updateSingleSelectedConnectionId } from 'integrations/common/streams/connections/actions';
import { connections$ } from 'integrations/common/streams/connections/connections$';
import { tabularMappings$ } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import {
  createTransferConfig,
  loadTransferConfig,
  saveConfiguration,
  updateTransferConfig,
} from 'integrations/common/streams/transferConfigs/actions';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import {
  SavedTransferConfig,
  TransferConfig,
  UnifiedSourceConfig,
} from '@ardoq/api-types/integrations';
import {
  getSavableTransferConfig,
  isSavedTransferConfig,
} from 'integrations/common/streams/transferConfigs/utils';
import fp from 'lodash/fp';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  catchError,
  EMPTY,
  filter,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  tap,
  withLatestFrom,
} from 'rxjs';
import { getResourceFailure, setSelectedResources } from '../resources/actions';
import { resources$ } from '../resources/resources$';
import {
  selectedResourcesToSourceConfigResources,
  sourceConfigResourcesToSelectedResources,
} from '../resources/utils';
import { UnifiedIntegrationId } from '../../types';
import {
  isUnifiedIntegrationId,
  isUnifiedIntegrationPayload,
} from '../../utils';
import { applySourceConfig, ApplySourceConfigPayload } from './actions';
import { filterInvalidQueries, isUnifiedTransferConfig } from './utils';
import { startMissingConnectionModal } from 'integrations/common/modals/missingConnectionModal/MissingConnectionModal';
import { ImportRoute } from 'integrations/common/navigation/types';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { ActionCreatorParameter } from 'integrations/common/utils/actionCreatorWithIntegrationId';
import * as api from 'integrations/unified/streams/resources/api';
import { INTEGRATION_ID_TO_PROVIDER_ID } from 'integrations/unified/constants';

const handleApplySourceConfig = routine(
  ofType(applySourceConfig),
  extractPayload(),
  filter(isUnifiedIntegrationPayload<ApplySourceConfigPayload>),
  tap(({ integrationId, sourceConfig: { connectionId, resources } }) => {
    dispatchAction(
      setSelectedResources({
        resources: sourceConfigResourcesToSelectedResources(resources),
        integrationId,
      })
    );
    dispatchAction(
      updateSingleSelectedConnectionId({
        selectedConnectionId: connectionId,
        integrationId,
      })
    );
  })
);

const handleSaveCurrentConfig = routine(
  ofType(saveConfiguration),
  extractPayload(),
  filter(({ integrationId }) => isUnifiedIntegrationId(integrationId)),
  withLatestFrom(
    activeIntegrations$,
    tabularMappings$,
    transferConfigs$,
    resources$,
    connections$
  ),
  map(
    ([
      { name, isNewConfig, integrationId },
      activeIntegrations,
      tabularMappings,
      transferConfigs,
      resources,
      connections,
    ]) => ({
      integrationId,
      name,
      isNewConfig,
      activeIntegration: activeIntegrations[integrationId],
      tabularMapping: tabularMappings[integrationId],
      transferConfigs,
      selectedResources:
        resources[integrationId as UnifiedIntegrationId].selectedResources,
      connections: connections[integrationId],
    })
  ),
  tap(
    ({
      integrationId,
      name,
      isNewConfig,
      activeIntegration,
      tabularMapping,
      transferConfigs,
      selectedResources,
      connections,
    }) => {
      if (!connections.selectedConnectionIds.length) {
        return;
      }

      const sourceConfigResources =
        selectedResourcesToSourceConfigResources(selectedResources);

      const sourceConfig: UnifiedSourceConfig = {
        connectionId: connections.selectedConnectionIds[0],
        resources: sourceConfigResources,
      };

      const savableConfig: TransferConfig = {
        ...getSavableTransferConfig({
          transferConfigs,
          activeIntegration,
          tabularMapping,
        }),
        name,
        sourceConfig,
      };

      const isCreation =
        isNewConfig && activeIntegration.selectedTransferConfigId;

      if (isSavedTransferConfig(savableConfig) && !isCreation) {
        return dispatchAction(
          updateTransferConfig({
            integrationId,
            config: savableConfig as SavedTransferConfig,
          })
        );
      }
      /*
       * saving a new config
       */
      return dispatchAction(
        createTransferConfig({
          integrationId,
          config: fp.omit(
            ['_id', '_version', 'lastUpdated'],
            savableConfig
          ) as TransferConfig,
        })
      );
    }
  )
);

const handleLoadTransferConfig = routine(
  ofType(loadTransferConfig),
  extractPayload(),
  filter(
    isUnifiedIntegrationPayload<
      ActionCreatorParameter<typeof loadTransferConfig>
    >
  ),
  withLatestFrom(connections$),
  mergeMap(([{ transferConfig, integrationId }, connections]) => {
    if (!isUnifiedTransferConfig(integrationId, transferConfig)) {
      return EMPTY;
    }

    const connectionId = transferConfig.sourceConfig?.connectionId;
    const resourceIds = transferConfig.sourceConfig?.resources.map(
      resource => resource.id
    );

    if (!connectionId || !Array.isArray(resourceIds)) {
      return EMPTY;
    }

    return forkJoin(
      resourceIds.map(resourceId =>
        from(
          api.getResource({
            providerId: INTEGRATION_ID_TO_PROVIDER_ID[integrationId],
            resourceId,
            connectionId,
          })
        ).pipe(
          catchError(error => {
            dispatchAction(
              getResourceFailure({
                integrationId: error.extraData.integrationId,
                resourceId: error.extraData.resourceId,
                message: "We're unable to fetch table fields.",
              })
            );
            return of(null);
          })
        )
      )
    ).pipe(
      map(resources => ({
        resources: resources.filter(resource => !!resource),
        integrationId,
        transferConfig,
        connections,
      }))
    );
  }),
  tap(({ resources, integrationId, transferConfig, connections }) => {
    if (!isUnifiedTransferConfig(integrationId, transferConfig)) {
      return;
    }

    const handleLoadConfig = (
      config: SavedTransferConfig<UnifiedSourceConfig>
    ) => {
      dispatchAction(
        setTransferConfigId({
          integrationId,
          id: config._id,
        })
      );
      dispatchAction(
        navigateToPath({ integrationId, path: ImportRoute.SELECT_DATA })
      );
      if (config.sourceConfig) {
        config.sourceConfig = filterInvalidQueries(
          config.sourceConfig,
          resources
        );

        dispatchAction(
          applySourceConfig({
            integrationId: integrationId,
            sourceConfig: config.sourceConfig,
          })
        );
      }
    };
    const sourceConfig = transferConfig?.sourceConfig;
    const connection =
      sourceConfig &&
      connections[integrationId].connections.find(
        conn =>
          'connectionId' in sourceConfig &&
          conn._id === sourceConfig.connectionId
      );

    if (!connection) {
      return startMissingConnectionModal({
        onCancel: () => null,
        onSubmit: connection => {
          const enrichedConfig = fp.update(
            'sourceConfig',
            sc => ({
              ...(sc || {}),
              connectionId: connection._id,
              resources: sc?.resources ?? [],
            }),
            transferConfig
          );
          handleLoadConfig(enrichedConfig);
        },
      });
    }
    handleLoadConfig(transferConfig);
  })
);

export default [
  handleApplySourceConfig,
  handleSaveCurrentConfig,
  handleLoadTransferConfig,
];
