import { CreateReferencesState } from './createReferencesTypes';
import {
  ObservableState,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import {
  linkUpdate,
  setLinkSources,
  setLinkTarget,
} from 'components/WorkspaceHierarchies/actions/navigatorActions';
import { createReferencesOperations } from './createReferencesOperations';
import { defaultCreateReferencesState } from './defaultCreateReferencesState';
import { createReferenceSourceChanged } from './createReferencesActions';

export const createReferences$: ObservableState<CreateReferencesState> =
  persistentReducedStream<CreateReferencesState>(
    'createReferences$',
    defaultCreateReferencesState,
    [
      reducer(setLinkSources, createReferencesOperations.setLinkSources),
      reducer(linkUpdate, createReferencesOperations.linkUpdate),
      reducer(setLinkTarget, createReferencesOperations.reset),
      reducer(
        createReferenceSourceChanged,
        createReferencesOperations.createReferenceSourceChanged
      ),
    ]
  );

export const isLinking = () => createReferences$.state.isLinking;
