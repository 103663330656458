import selectedModule$ from 'appContainer/selectedModule$';
import { AppModules } from 'appContainer/types';

// Should be removed after all Backbone views are gone.
// Backbone views are kept alive after first instantiation.
// The resize event is used to notify them that they should update.
export const startLegacyBackboneViewRoutine = () => {
  selectedModule$.subscribe(({ selectedModule }) => {
    const showMainApp = selectedModule === AppModules.WORKSPACES;
    if (showMainApp) {
      setTimeout(() => window.dispatchEvent(new Event('resize')), 16);
    }
  });
};
