import { CheckboxTree, Option } from '@ardoq/forms';
import { Stack } from '@ardoq/layout';
import { header4 } from '@ardoq/typography';
import styled from 'styled-components';

type WorkspacesSelectorProps = {
  title?: string;
  workspaces?: { id: string; name: string }[];
  selectedWorkspaces: string[];
  onSelectionChange: (ids: string[]) => void;
};

interface WorkspacesCheckboxTree {
  children: string;
  name: string;
  isChecked: boolean;
  isMixed: boolean;
  options: Option[];
}

export function WorkspacesSelector({
  workspaces = [],
  selectedWorkspaces,
  onSelectionChange,
  title = 'Select workspaces',
}: WorkspacesSelectorProps) {
  const workspacesCheckboxTree: WorkspacesCheckboxTree = {
    children: 'All',
    name: 'All',
    isChecked: workspaces.every(ws => selectedWorkspaces.includes(ws.id)),
    isMixed: false,
    options: workspaces.map(ws => ({
      children: ws.name,
      name: ws.id,
      isChecked: selectedWorkspaces.includes(ws.id),
    })),
  };

  const handleCheckboxTreeChange = (
    _: string,
    __: boolean,
    [checkboxTree]: Option[]
  ) => {
    onSelectionChange(
      checkboxTree?.options?.filter(o => o.isChecked)?.map(o => o.name) || []
    );
  };

  return (
    <Stack gap="small">
      <WorkspacesHeading>{title}</WorkspacesHeading>
      <CheckboxTree
        onChange={handleCheckboxTreeChange}
        options={[workspacesCheckboxTree]}
      />
    </Stack>
  );
}

const WorkspacesHeading = styled.div`
  ${header4}
`;
