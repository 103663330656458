import { ArdoqId } from '@ardoq/api-types';

export enum BroadcastsPane {
  OVERVIEW = 'broadcasts-overview',
  NEW = 'broadcast-creator',
  EDIT = 'broadcast-editor',
}

export type BroadcastsRoute = {
  broadcastsPane: BroadcastsPane;
  broadcastId: ArdoqId | null;
};
