import styled from 'styled-components';
import { s8, s16, colors } from '@ardoq/design-tokens';
import { normal16 } from '@ardoq/typography';
import { pluralize } from '@ardoq/common-helpers';
import { ComponentType } from 'viewpointBuilder/components/ComponentType';
import { ApplySavedTraversalComponent } from './actions';
import { Tag } from '@ardoq/status-ui';
import { WithPopover } from '@ardoq/popovers';
import { FlexBox } from '@ardoq/layout';

const COUNT_OF_DISPLAYED_COMPONENTS = 5;

type SelectedComponentsSummaryProps = {
  components: ApplySavedTraversalComponent[];
};

const SelectedComponentsSummary = ({
  components,
}: SelectedComponentsSummaryProps) => {
  const componentCount = components.length;

  return (
    <SummaryText>
      <Paragraph>{`The selected viewpoint will be applied to ${componentCount} context ${pluralize(
        'component',
        componentCount
      )}:`}</Paragraph>
      <SelectedComponentsAsTags components={components} />
    </SummaryText>
  );
};

const SelectedComponentsAsTags = ({
  components,
}: SelectedComponentsSummaryProps) => {
  const displayedComponents = components.slice(
    0,
    COUNT_OF_DISPLAYED_COMPONENTS
  );

  return (
    <FlexBox>
      <SelectedComponentsList>
        {displayedComponents.map((component, index) => (
          <li key={`${index}-${component.label}`}>
            <ComponentType {...component} />
          </li>
        ))}
        {components.length > COUNT_OF_DISPLAYED_COMPONENTS && (
          <li>
            <RemainingComponents
              remainingComponents={components.slice(
                COUNT_OF_DISPLAYED_COMPONENTS
              )}
            />
          </li>
        )}
      </SelectedComponentsList>
    </FlexBox>
  );
};

type RemainingComponentsProps = {
  remainingComponents: ApplySavedTraversalComponent[];
};

const RemainingComponents = ({
  remainingComponents,
}: RemainingComponentsProps) => {
  const popoverContent = remainingComponents
    .map(component => component.label)
    .join(', ');

  return (
    <FlexBox paddingY="xsmall">
      <WithPopover content={popoverContent}>
        <Tag>{`+ ${remainingComponents.length} more`}</Tag>
      </WithPopover>
    </FlexBox>
  );
};

const SummaryText = styled.div`
  ${normal16};
  color: ${colors.grey50};
  gap: ${s8};
  font-weight: 500;
  display: flex;
  flex-direction: column;
`;

const SelectedComponentsList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  gap: ${s16};
  margin: 0;
`;

const Paragraph = styled.p`
  margin: 0;
`;

export default SelectedComponentsSummary;
