import {
  ExteriorLabelModel,
  ExteriorLabelModelPosition,
  FreeEdgeLabelModel,
  IEdge,
  IGraph,
  ILabel,
  ILabelOwner,
  IModelItem,
  INode,
  INodeStyle,
  Rect,
  Size,
} from '@ardoq/yfiles';
import ArdoqLabelStyle from './ArdoqLabelStyleClone';
import {
  GraphItem,
  GraphNode,
  type RelationshipDiagramGraphEdgeType,
} from '@ardoq/graph';
import { isEqualWith } from 'lodash';
import * as encodingUtils from '@ardoq/html';
import { getDefaultSize } from 'yfilesExtensions/styles/styleLoader';
import { logError } from '@ardoq/logging';
import { DiffType } from '@ardoq/data-model';
import getNodeLayoutSize from 'yfilesExtensions/getNodeLayoutSize';
import {
  CONTEXT_HIGHLIGHT_PADDING,
  CONTEXT_HIGHLIGHT_STROKE,
  NODE_HEIGHT_WIDTH_SELECTION as NODE_HEIGHT,
  NODE_WIDTH,
} from 'yfilesExtensions/styles/consts';
import EdgeStyle from './EdgeStyleClone';
import { isContextNode } from '../../utils';

const graphItemsEqual = (a: GraphItem | GraphItem[], b: GraphItem) =>
  !Array.isArray(a) && a.isEqual(b);

const updateLabels = (
  owner: ILabelOwner,
  graph: IGraph,
  graphItem: GraphItem
) => {
  const labelsToRemove: ILabel[] = [];
  owner.labels.forEach(label => {
    if (label.tag === null) {
      graph.setLabelText(
        label,
        encodingUtils.unescapeHTML(graphItem.getLabelWithCount())
      );
    }
  });
  labelsToRemove.forEach(label => graph.remove(label));
};

const labelStyle = ArdoqLabelStyle.Instance;
const southsideLabelParameter = new ExteriorLabelModel({
  insets: CONTEXT_HIGHLIGHT_STROKE,
}).createParameter(ExteriorLabelModelPosition.SOUTH);

const filterNullValues = <TKey, TValue>(
  keyValuePair: [TKey, TValue | null]
): keyValuePair is [TKey, TValue] => keyValuePair[1] !== null;

const diffUpdateGraph = <T extends GraphItem, Y extends IModelItem>({
  oldMap,
  businessObjects,
  create,
  update,
  remove,
}: {
  oldMap: Map<string, Y>;
  businessObjects: T[];
  create: (graphItem: T) => Y | null;
  update: (graphItem: T) => Y | null;
  remove: (graphItem: Y, oldMap: Map<string, Y>) => void;
}): Map<string, Y> => {
  const newMap = new Map(
    businessObjects
      .map((businessObject): [string, Y | null] => [
        businessObject.id,
        // Add new items, update existing items
        (oldMap.has(businessObject.id) ? update : create)(businessObject),
      ])
      .filter(filterNullValues)
  );
  businessObjects.forEach(businessObject => oldMap.delete(businessObject.id));

  // Delete old items
  Array.from(oldMap.values()).forEach(graphItem => remove(graphItem, oldMap));
  return newMap;
};
export default class CollapsibleGroupsYGraphBuilder {
  private edgeMap = new Map<string, IEdge>();
  private nodeMap = new Map<string, INode>();
  nodesSource: GraphNode[] = [];
  edgesSource: RelationshipDiagramGraphEdgeType[] = [];
  constructor(
    private graph: IGraph,
    private getNodeStyle: (node: GraphNode) => INodeStyle,
    private getNodeSize: (nodeStyle: INodeStyle) => Size
  ) {
    // Initialization
    graph.edgeDefaults.style = new EdgeStyle();
    // Style and labels
    graph.nodeDefaults.labels.layoutParameter = southsideLabelParameter;
    graph.edgeDefaults.labels.style = labelStyle;
    graph.nodeDefaults.labels.style = labelStyle;

    const edgeLabelModel = new FreeEdgeLabelModel();
    graph.edgeDefaults.labels.layoutParameter =
      edgeLabelModel.createDefaultParameter();
  }
  private createEdge = (graphEdge: RelationshipDiagramGraphEdgeType) => {
    const sourceNode = this.nodeMap.get(graphEdge.source);
    const targetNode = this.nodeMap.get(graphEdge.target);
    if (!sourceNode || !targetNode) {
      logError(Error('Source or Target node not found while creating Edge.'));
      return null;
    }
    const edge = this.graph.createEdge({
      tag: graphEdge,
      labels: [encodingUtils.unescapeHTML(graphEdge.getLabel())],
      source: sourceNode,
      target: targetNode,
    });

    return edge;
  };
  private updateEdge = (graphEdge: RelationshipDiagramGraphEdgeType) => {
    const edge = this.edgeMap.get(graphEdge.id);
    if (!edge) {
      logError(Error('Edge not found in updateEdge.'));
      return null;
    }

    // Create new edge if sourcePort/targetPort is gone (group Node turned into a normal node or vice-versa)
    if (!edge.sourcePort || !edge.targetPort) {
      return this.createEdge(graphEdge);
    }

    if (
      edge.sourceNode?.tag instanceof GraphItem &&
      edge.targetNode?.tag instanceof GraphItem
    ) {
      if (
        edge.sourceNode.tag.id !== graphEdge.source ||
        edge.targetNode.tag.id !== graphEdge.target
      ) {
        // if source or target has changed, we must create a new edge.
        this.removeEdge(edge);
        return this.createEdge(graphEdge);
      }
    } else {
      logError(Error('Unexpected edge source or target.'));
    }
    updateLabels(edge, this.graph, graphEdge);
    return edge;
  };

  private createNode = (graphNode: GraphNode) => {
    const node = this.graph.createNode({
      parent: null,
      tag: graphNode,
      labels: [encodingUtils.unescapeHTML(graphNode.getLabelWithCount())],
      layout: null,
    });

    this.updateNodeLayout(node);
    return node;
  };
  private updateNode = (graphNode: GraphNode) => {
    const node = this.nodeMap.get(graphNode.id)!;
    if (!node) {
      logError(Error('Node not found in updateNode.'));
      return null;
    }
    const style = this.getNodeStyle(graphNode);

    if (style !== node.style) {
      this.graph.setStyle(node, style);
    }
    this.updateNodeLayout(node);

    updateLabels(node, this.graph, graphNode);

    this.graph.setParent(node, null);

    return node;
  };

  private removeItem = (item: IModelItem, map: Map<string, IModelItem>) => {
    const businessObject: GraphItem = item.tag;
    map.delete(businessObject.id);
    if (this.graph.contains(item)) {
      this.graph.remove(item);
    }
  };
  private removeEdge = (edge: IEdge) => {
    // Make sure the edge is attached to the graph
    if (edge.sourcePort || edge.targetPort) {
      this.removeItem(edge, this.edgeMap);
    }
  };

  hasLayoutUpdate({
    nodes,
    edges,
  }: {
    nodes: GraphNode[];
    edges: RelationshipDiagramGraphEdgeType[];
  }) {
    const nodesUnchanged =
      !nodes || isEqualWith(nodes, this.nodesSource, graphItemsEqual);
    const groupsUnchanged = true;
    const edgesUnchanged =
      !edges || isEqualWith(edges, this.edgesSource, graphItemsEqual);

    return !(nodesUnchanged && groupsUnchanged && edgesUnchanged);
  }
  clearEdges() {
    Array.from(this.edgeMap.values()).forEach(this.removeEdge);
  }
  clear() {
    this.graph.clear();
    this.nodeMap.clear();
    this.edgeMap.clear();
  }
  buildGraph() {
    this.clear();

    this.nodeMap = new Map(
      this.nodesSource.map(graphNode => [
        graphNode.id,
        this.createNode(graphNode),
      ])
    );

    this.edgeMap = new Map(
      this.edgesSource.map(graphEdge => {
        return [graphEdge.id, this.createEdge(graphEdge)!];
      })
    );
  }

  updateGraph() {
    if (this.nodeMap.size + this.edgeMap.size === 0) {
      this.buildGraph();
      return;
    }

    this.nodeMap = diffUpdateGraph<GraphNode, INode>({
      oldMap: this.nodeMap,
      businessObjects: this.nodesSource,
      create: this.createNode,
      update: this.updateNode,
      remove: this.removeItem,
    });

    this.edgeMap = diffUpdateGraph({
      oldMap: this.edgeMap,
      businessObjects: this.edgesSource,
      create: this.createEdge,
      update: this.updateEdge,
      remove: this.removeEdge,
    });
  }

  private updateNodeLayout(node: INode) {
    const style = this.getNodeStyle(node.tag);
    const size = this.getNodeSize(style);
    const styleWidth = size?.width || getDefaultSize().width;
    const styleHeight = size?.height || getDefaultSize().height;
    const yAspectRatio = NODE_HEIGHT / styleHeight;
    const xAspectRatio = NODE_WIDTH / styleWidth;

    let width;
    let height;

    if (styleWidth * yAspectRatio > NODE_WIDTH) {
      width = NODE_WIDTH;
      height = styleHeight * xAspectRatio;
    } else {
      width = styleWidth * yAspectRatio;
      height = NODE_HEIGHT;
    }

    this.graph.setStyle(node, style);
    this.graph.setNodeLayout(
      node,
      new Rect(
        node.layout.topLeft,
        getNodeLayoutSize({
          styleWidth: width,
          styleHeight: height,
          hasContextHighlight: isContextNode(node),
          contextHighlightPadding: CONTEXT_HIGHLIGHT_PADDING,
          visualDiffType:
            node.tag instanceof GraphItem
              ? node.tag.getVisualDiffType()
              : DiffType.NONE,
        })
      )
    );
  }
}
