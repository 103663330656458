import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { AppRouterState, DeclarativeRouterRoute } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { from, map, startWith } from 'rxjs';
import { DeclarativeRouter } from '../declarativeRouter';
import { interpolatePath } from '../declarativeRouterLogic';
import { emptyLocation, Location, MatchedLocation } from '../location';
import { RouteNode } from '../routeNode';
import { navigateToDeclarativeRoute } from './actions';

export const streamRoute = <RouteTree extends RouteNode>(
  location$: DeclarativeRouter<RouteTree>,
  appModule: AppModules
): Route<AppRouterState, DeclarativeRouterRoute> => {
  return new Route<AppRouterState, DeclarativeRouterRoute>({
    doesLocationMatch: ({ path }) =>
      typeof location$.routeTree.path === 'string' &&
      path.startsWith(location$.routeTree.path),
    doesRouterStateMatch: routerState => routerState.appModule === appModule,

    locationToRouterState: ({ path }) => {
      const location = location$.arrive(path) as MatchedLocation | false;

      if (location) {
        return {
          appModule,
          declarativeRouterState: location,
        };
      }

      // Should be protected by this by `doesLocationMatch` above
      throw new Error('Could not parse path');
    },
    routerStateToLocation: routerState => ({
      path: interpolatePath(routerState.declarativeRouterState),
      // TODO title
    }),

    setApplicationStateFromRoute: () =>
      dispatchAction(navigateToDeclarativeRoute({ appModule })),
    getPartialRouterStateStream: () =>
      from(location$).pipe(
        map((state: unknown) => ({
          declarativeRouterState: state as Location,
        })),
        startWith({ declarativeRouterState: emptyLocation })
      ),
  });
};
