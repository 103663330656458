import { UserProfilePageProps } from '../../types';
import { FlexBox } from '@ardoq/layout';
import { UserRoleDetails } from './components/UserRoleDetails';
import { RoleRelatedPrivileges } from './components/RoleRelatedPrivileges';
import { UserRelatedPrivileges } from './components/UserRelatedPrivileges';
import { TabBox } from '../TabBox';

export type RolesAndPrivilegesDetailsProps = UserProfilePageProps;

export const RolesAndPrivilegesDetails = (
  props: RolesAndPrivilegesDetailsProps
) => {
  return (
    <TabBox skipPaddingLeft>
      <FlexBox height="full" width="full">
        <UserRoleDetails {...props} />
        <RoleRelatedPrivileges {...props} />
        <UserRelatedPrivileges {...props} />
      </FlexBox>
    </TabBox>
  );
};
