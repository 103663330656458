import { Features, hasFeature } from '@ardoq/features';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';
import { SubdivisionViewModelState } from 'subdivisionEditor/types';

export const editOnlyStepIsDisabled = (
  subdivisionEditorState: SubdivisionViewModelState
) => subdivisionEditorOperations.isCreationMode(subdivisionEditorState);

export const isForInternalDev = (_?: SubdivisionViewModelState) =>
  hasFeature(Features.PERMISSION_ZONES_INTERNAL);
