import { take } from 'rxjs/operators';
import { action$, dispatchAction, ofType } from '@ardoq/rxbeach';
import { resetAllSearchState } from '../actions';
import { selectSearchPane } from './actions';

export const errorRecovery = (recoveryCompleted: () => any) => {
  action$.pipe(ofType(selectSearchPane), take(1)).subscribe(() => {
    dispatchAction(resetAllSearchState());
    recoveryCompleted();
  });
};
