import { SidebarMenu, SidebarMenuFormSection } from '@ardoq/sidebar-menu';
import AddFieldToWorkspace from 'appModelStateEdit/propertiesEditor/addField/addFieldToWorkspace/AddFieldToWorkspace';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { showRightPane } from 'appContainer/actions';
import { dispatchAction } from '@ardoq/rxbeach';

const AddFieldPane = () => {
  return (
    <SidebarMenu
      headerTitle="Add field to workspace"
      onCloseClick={() =>
        dispatchAction(
          showRightPane({
            type: GetContentOptionsType.MANAGE_FIELDS,
          })
        )
      }
    >
      <SidebarMenuFormSection isCollapsible={false}>
        <AddFieldToWorkspace />
      </SidebarMenuFormSection>
    </SidebarMenu>
  );
};

export default AddFieldPane;
