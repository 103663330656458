import { buildInitialStreamState } from 'integrations/unified/streams/utils';
import {
  UnifiedIntegrationId,
  WithUnifiedIntegrationId,
} from 'integrations/unified/types';
import fp from 'lodash/fp';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { Observable } from 'rxjs';
import { getInstanceStream } from '../utils';
import {
  GetResourceFailurePayload,
  GetResourcePayload,
  GetResourceSuccessPayload,
  GetResourcesFailurePayload,
  GetResourcesSuccessPayload,
  ResetResourceQueryPayload,
  ResourceSelectionPayload,
  SelectResourceFieldPayload,
  SelectResourceFieldsPayload,
  SetFocusedResourcePayload,
  SetResourceQueryPayload,
  SetSelectedResourcesPayload,
  UnselectResourceFieldsPayload,
  getResource,
  getResourceFailure,
  getResourceSuccess,
  getResources,
  getResourcesFailure,
  getResourcesSuccess,
  resetAllResourceFields,
  resetIntegration,
  resetResourceQuery,
  resetSelectedResources,
  resetUnavailableResources,
  selectResource,
  selectResourceField,
  selectResourceFields,
  setFocusedResource,
  setResourceQuery,
  setSelectedResources,
  setUnavailableResources,
  unselectResource,
  unselectResourceField,
  unselectResourceFields,
} from './actions';
import { IntegrationIdToResources, IntegrationResources } from './types';

const initialInstanceState: IntegrationResources = {
  requests: {
    getResources: { status: 'INIT' },
    getResource: {},
  },
  resources: {},
  focusedResource: null,
  selectedResources: {},
  unavailableResources: {},
};

const defaultState: IntegrationIdToResources =
  buildInitialStreamState<IntegrationResources>(initialInstanceState);

const resetIntegrationReducerFn = (
  state: IntegrationIdToResources,
  { integrationId }: { integrationId: string }
) => fp.set(integrationId, initialInstanceState, state);

const handleResetIntegration = reducer<
  IntegrationIdToResources,
  WithUnifiedIntegrationId
>(resetIntegration, resetIntegrationReducerFn);

const setFocusedResourceReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId }: SetFocusedResourcePayload
) => fp.set([integrationId, 'focusedResource'], resourceId, state);

const handleSetFocusedResource = reducer<
  IntegrationIdToResources,
  SetFocusedResourcePayload
>(setFocusedResource, setFocusedResourceReducerFn);

const resetFocusedResourceReducerFn = (
  state: IntegrationIdToResources,
  { integrationId }: WithUnifiedIntegrationId
) => fp.set([integrationId, 'focusedResource'], null, state);

const handleResetFocusedResource = reducer(
  setFocusedResource,
  resetFocusedResourceReducerFn
);

const resetAllFieldsReducerFn = (
  state: IntegrationIdToResources,
  { integrationId }: WithUnifiedIntegrationId
) =>
  fp.update(
    [integrationId, 'resources'],
    fp.mapValues(_ => {}),
    state
  );

const handleResetAllFields = reducer<
  IntegrationIdToResources,
  WithUnifiedIntegrationId
>(resetAllResourceFields, resetAllFieldsReducerFn);

const getResourcesReducerFn = (
  state: IntegrationIdToResources,
  { integrationId }: { integrationId: string }
) =>
  fp.set(
    [integrationId, 'requests', 'getResources'],
    { status: 'LOADING' },
    state
  );

const handleGetResources = reducer<
  IntegrationIdToResources,
  WithUnifiedIntegrationId
>(getResources, getResourcesReducerFn);

const getResourceReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId }: GetResourcePayload
) =>
  fp.set(
    [integrationId, 'requests', 'getResource', resourceId],
    { status: 'LOADING' },
    state
  );

const handleGetResource = reducer<IntegrationIdToResources, GetResourcePayload>(
  getResource,
  getResourceReducerFn
);

// request handlers ---------------------------------------------------------

// getResources

const getResourcesSuccessReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resources }: GetResourcesSuccessPayload
) => {
  return fp.flow(
    fp.set(
      [integrationId, 'resources'],
      fp.reduce(
        (acc, resource) =>
          fp.set(
            resource.id,
            { ...resource, fields: { definitions: [] } },
            acc
          ),
        {},
        resources
      )
    ),
    fp.set([integrationId, 'requests', 'getResources'], { status: 'SUCCESS' })
  )(state);
};

const handleGetResourcesSuccess = reducer<
  IntegrationIdToResources,
  GetResourcesSuccessPayload
>(getResourcesSuccess, getResourcesSuccessReducerFn);

const getResourcesFailureReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, message }: GetResourcesFailurePayload
) =>
  fp.set(
    [integrationId, 'requests', 'getResources'],
    { status: 'FAILURE', message },
    state
  );

const handleGetResourcesFailure = reducer<
  IntegrationIdToResources,
  GetResourcesFailurePayload
>(getResourcesFailure, getResourcesFailureReducerFn);

// getResource

const getResourceSuccessReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId, resource }: GetResourceSuccessPayload
) => {
  return fp.flow(
    fp.set([integrationId, 'resources', resourceId], resource),
    fp.set([integrationId, 'requests', 'getResource', resourceId], {
      status: 'SUCCESS',
    })
  )(state);
};

const handleGetResourceSuccess = reducer<
  IntegrationIdToResources,
  GetResourceSuccessPayload
>(getResourceSuccess, getResourceSuccessReducerFn);

const handleGetResourceFailureReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId, message }: GetResourceFailurePayload
) =>
  fp.set(
    [integrationId, 'requests', 'getResource', resourceId],
    { status: 'FAILURE', message },
    state
  );

const handleGetResourceFailure = reducer<
  IntegrationIdToResources,
  GetResourceFailurePayload
>(getResourceFailure, handleGetResourceFailureReducerFn);

// Resource selection

const setSelectedResourcesReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resources }: SetSelectedResourcesPayload
): IntegrationIdToResources =>
  fp.set([integrationId, 'selectedResources'], resources, state);

const handleSetSelectedResources = reducer<
  IntegrationIdToResources,
  SetSelectedResourcesPayload
>(setSelectedResources, setSelectedResourcesReducerFn);

const resetSelectedResourcesReducerFn = (
  state: IntegrationIdToResources,
  { integrationId }: WithUnifiedIntegrationId
): IntegrationIdToResources =>
  setSelectedResourcesReducerFn(state, { integrationId, resources: {} });

const handleResetSelectedResources = reducer<
  IntegrationIdToResources,
  WithUnifiedIntegrationId
>(resetSelectedResources, resetSelectedResourcesReducerFn);

const setUnavailableResourcesReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resources }: SetSelectedResourcesPayload
): IntegrationIdToResources =>
  fp.set([integrationId, 'unavailableResources'], resources, state);

const handleSetUnavailableResources = reducer<
  IntegrationIdToResources,
  SetSelectedResourcesPayload
>(setUnavailableResources, setUnavailableResourcesReducerFn);

const resetUnavailableResourcesReducerFn = (
  state: IntegrationIdToResources,
  { integrationId }: WithUnifiedIntegrationId
): IntegrationIdToResources =>
  setUnavailableResourcesReducerFn(state, { integrationId, resources: {} });

const handleResetUnavailableResources = reducer<
  IntegrationIdToResources,
  WithUnifiedIntegrationId
>(resetUnavailableResources, resetUnavailableResourcesReducerFn);

// field selection

const selectResourceFieldReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId, id }: SelectResourceFieldPayload
): IntegrationIdToResources => {
  return setSelectedResourcesReducerFn(state, {
    integrationId,
    resources: fp.set(
      [resourceId, 'fields', id],
      true,
      state[integrationId].selectedResources
    ),
  });
};

const handleSelectResourceField = reducer<
  IntegrationIdToResources,
  SelectResourceFieldPayload
>(selectResourceField, selectResourceFieldReducerFn);

const selectResourceFieldsReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId, ids }: SelectResourceFieldsPayload
): IntegrationIdToResources =>
  fp.reduce(
    (state, id) =>
      selectResourceFieldReducerFn(state, {
        integrationId,
        resourceId,
        id,
      }),
    state,
    ids
  );

const handleSelectResourceFields = reducer<
  IntegrationIdToResources,
  SelectResourceFieldsPayload
>(selectResourceFields, selectResourceFieldsReducerFn);

const unselectResourceFieldReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId, id }: SelectResourceFieldPayload
): IntegrationIdToResources => {
  const selectedResources = state[integrationId].selectedResources;
  const isLastField =
    fp.toPairs(selectedResources[resourceId].fields || {}).length <= 1;
  if (isLastField) {
    return fp.unset([integrationId, 'selectedResources', resourceId], state);
  }
  return fp.unset(
    [integrationId, 'selectedResources', resourceId, 'fields', id],
    state
  );
};

const handleUnselectResourceField = reducer<
  IntegrationIdToResources,
  SelectResourceFieldPayload
>(unselectResourceField, unselectResourceFieldReducerFn);

const unselectResourceFieldsReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId }: UnselectResourceFieldsPayload
) =>
  setSelectedResourcesReducerFn(state, {
    integrationId,
    resources: fp.unset(
      resourceId,
      state[integrationId]?.selectedResources || {}
    ),
  });

const handleUnselectResourceFields = reducer<
  IntegrationIdToResources,
  UnselectResourceFieldsPayload
>(unselectResourceFields, unselectResourceFieldsReducerFn);

const setResourceQueryReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId, query }: SetResourceQueryPayload
) => {
  return fp.set(
    [integrationId, 'selectedResources', resourceId, 'query'],
    query,
    state
  );
};

const handleSetResourceQuery = reducer<
  IntegrationIdToResources,
  SetResourceQueryPayload
>(setResourceQuery, setResourceQueryReducerFn);

const resetResourceQueryReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId }: ResetResourceQueryPayload
) => {
  return fp.unset(
    [integrationId, 'selectedResources', resourceId, 'query'],
    state
  );
};

const handleResetResourceQuery = reducer<
  IntegrationIdToResources,
  ResetResourceQueryPayload
>(resetResourceQuery, resetResourceQueryReducerFn);

//

const selectResourceReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId }: ResourceSelectionPayload
) => {
  const withFocusedResource = setFocusedResourceReducerFn(state, {
    integrationId,
    resourceId,
  });
  return state[integrationId]?.selectedResources[resourceId]
    ? withFocusedResource
    : setSelectedResourcesReducerFn(withFocusedResource, {
        integrationId,
        resources: fp.set(
          resourceId,
          { fields: {} },
          withFocusedResource[integrationId]?.selectedResources
        ),
      });
};

const handleSelectResource = reducer<
  IntegrationIdToResources,
  ResourceSelectionPayload
>(selectResource, selectResourceReducerFn);

const unselectResourceReducerFn = (
  state: IntegrationIdToResources,
  { integrationId, resourceId }: ResourceSelectionPayload
) => {
  const withoutFocusedResource = resetFocusedResourceReducerFn(state, {
    integrationId,
  });
  return state[integrationId]?.selectedResources[resourceId]
    ? setSelectedResourcesReducerFn(withoutFocusedResource, {
        integrationId,
        resources: fp.unset(
          resourceId,
          withoutFocusedResource[integrationId]?.selectedResources
        ),
      })
    : withoutFocusedResource;
};

const handleUnselectResource = reducer<
  IntegrationIdToResources,
  ResourceSelectionPayload
>(unselectResource, unselectResourceReducerFn);

const reducers = [
  handleResetIntegration,
  handleSetFocusedResource,
  handleResetFocusedResource,
  handleResetAllFields,
  handleGetResources,
  handleGetResource,
  handleGetResourcesSuccess,
  handleGetResourcesFailure,
  handleGetResourceSuccess,
  handleGetResourceFailure,
  handleSetSelectedResources,
  handleResetSelectedResources,
  handleSetUnavailableResources,
  handleResetUnavailableResources,
  handleSelectResourceField,
  handleSelectResourceFields,
  handleUnselectResourceField,
  handleUnselectResourceFields,
  handleSelectResource,
  handleUnselectResource,
  handleSetResourceQuery,
  handleResetResourceQuery,
];

export const resources$ = persistentReducedStream(
  'integrationsUnifiedResources',
  defaultState,
  reducers
);

export const getResourcesStream = (
  integrationId: UnifiedIntegrationId
): Observable<IntegrationResources> =>
  getInstanceStream(resources$, integrationId);
