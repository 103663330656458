import { FlexBox } from '@ardoq/layout';
import { TabsGroup, TabsTheme, TabButton } from '@ardoq/tabs';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import {
  DataOverviewComponentTypeRow,
  DataOverviewWorkspaceRow,
  DataOverviewTypes,
} from './types';
import { DataOverviewCommands } from './dataOverviewCommands';
import { ArdoqId } from '@ardoq/api-types';
import { BrandButton } from '@ardoq/button';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { pluralizeWithCount } from '@ardoq/common-helpers';
import { inventoryTracking } from '../inventoryTracking';

// the tab buttons get stretched too much without a container
const TabGroupContainer = styled.div`
  width: 300px;
`;

export type DataOverviewSwitchProps = {
  dataOverviewType: DataOverviewTypes;
  dataOverviewCommands: DataOverviewCommands;
  datasource: DataOverviewWorkspaceRow[] | DataOverviewComponentTypeRow[];
  isError: boolean;
  selectedItems: ArdoqId[] | string[];
};

const DataOverviewSwitch = ({
  dataOverviewType,
  dataOverviewCommands,
  datasource,
  isError,
  selectedItems,
}: DataOverviewSwitchProps) => (
  <FlexBox gap="medium" align="center">
    <FlexBox gap="xsmall" align="center">
      <Icon
        iconName={
          dataOverviewType === DataOverviewTypes.WORKSPACE
            ? IconName.WORKSPACE
            : IconName.SHOW_COMPONENT
        }
        iconSize={IconSize.SMALL}
        color={colors.icon}
      />
      {selectedItems.length === 0
        ? pluralizeWithCount(dataOverviewType, datasource.length)
        : `${pluralizeWithCount(dataOverviewType, selectedItems.length)} selected`}
    </FlexBox>
    {selectedItems.length > 0 && (
      <BrandButton
        onClick={() => {
          dataOverviewCommands.openItemsInInventory(
            dataOverviewType,
            selectedItems
          );
          inventoryTracking.trackClickOnOpenButtonInDataOverview(
            dataOverviewType
          );
        }}
      >
        Open
      </BrandButton>
    )}
    <TabGroupContainer>
      <TabsGroup
        activeTabId={dataOverviewType}
        theme={TabsTheme.COLOR_FILL}
        onTabChange={dataOverviewCommands.selectDataOverviewType}
      >
        <TabButton
          isDisabled={isError}
          isActive
          label="By type"
          tabId={DataOverviewTypes.COMPONENT_TYPE}
          iconName={IconName.COMPONENT}
        />
        <TabButton
          isDisabled={isError}
          label="By workspace"
          tabId={DataOverviewTypes.WORKSPACE}
          iconName={IconName.WORKSPACE}
        />
      </TabsGroup>
    </TabGroupContainer>
  </FlexBox>
);

export default DataOverviewSwitch;
