import { trackEvent } from 'tracking/tracking';

export const trackCreateBroadcastClicked = (origin: string) =>
  trackEvent('Clicked create broadcast', { origin }, true);

export const trackClickedToOpenBroadcastDetailsDialog = () => {
  trackEvent('Clicked to open broadcast details dialog', {}, true);
};

export const trackClickedToOpenBroadcastProgressDetailsDialog = () => {
  trackEvent('Clicked to open broadcast progress details dialog');
};

export enum BroadcastEditOrigin {
  BROADCAST_DETAILS_DIALOG = 'Broadcast details dialog',
  BROADCAST_OVERVIEW_TABLE_NAME_CELL = 'Broadcast overview table name cell',
}

export const trackClickedToEditBroadcast = (origin: BroadcastEditOrigin) => {
  trackEvent('Clicked to edit broadcast', { origin });
};

export const trackClickedOnGoToBroadcastOverviewInBroadcastBuilder = () => {
  trackEvent('Clicked on go to broadcast overview in broadcast builder');
};

export const trackOpenedNewbroadcastBuilder = () => {
  trackEvent('Opened new broadcast builder');
};

export const trackNavigateToBroadcastOverview = (origin: string) => {
  trackEvent('Navigated to broadcast overview', { origin });
};
