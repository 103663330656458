import { NonSearchBackendSearchPane, SubSearchBackend } from 'search/types';
import { SearchBackend, SearchType } from '@ardoq/api-types';
import { QueryEditorNamespace } from 'search/QueryEditor/queryEditor$';

export interface SearchTabStateShape {
  selectedPane: SearchPane;
  searchOverviewPageDict: Record<string, number>;
  isOpenInModal: boolean;
}

export const SearchPane = {
  ...SearchBackend,
  ...NonSearchBackendSearchPane,
  ...SubSearchBackend,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchPane =
  | SearchBackend
  | NonSearchBackendSearchPane
  | SubSearchBackend;

export const SearchPaneToLabel = {
  [SearchPane.SEARCH_OVERVIEW]: 'Reporting overview',
  [SearchPane.GREMLIN]: 'Gremlin graph search',
  [SearchPane.ADVANCED_SEARCH]: 'Advanced search',
  [SearchPane.CALCULATED_FIELD_SEARCH]: 'Calculation',
  [SearchPane.DYNAMIC_FILTER_OVERVIEW]: 'Graph filter overview',
  [SearchPane.DYNAMIC_FILTER_SEARCH]: 'Graph filter',
};

export const searchBackendTypePairToSearchPaneMap = new Map<string, SearchPane>(
  [
    [
      `${SearchBackend.ADVANCED_SEARCH}${SearchType.QUERY}`,
      SearchPane.ADVANCED_SEARCH,
    ],
    [`${SearchBackend.GREMLIN}${SearchType.QUERY}`, SearchPane.GREMLIN],
    [
      `${SearchBackend.GREMLIN}${SearchType.DYNAMIC_FILTER_QUERY}`,
      SearchPane.DYNAMIC_FILTER_SEARCH,
    ],
    [
      `${SearchBackend.GREMLIN}${SearchType.CALCULATED_FIELD_QUERY}`,
      SearchPane.CALCULATED_FIELD_SEARCH,
    ],
    [
      `${SearchBackend.GREMLIN}${SearchType.PARAMETER_QUERY}`,
      SearchPane.DYNAMIC_FILTER_SEARCH,
    ],
  ]
);

export const queryEditorNamespaceToSearchPaneMap = new Map<string, SearchPane>([
  [QueryEditorNamespace.ADVANCED_SEARCH, SearchPane.ADVANCED_SEARCH],
  [QueryEditorNamespace.GREMLIN_SEARCH, SearchPane.GREMLIN],
  [QueryEditorNamespace.DYANMIC_FILTER_QUERY, SearchPane.DYNAMIC_FILTER_SEARCH],
]);
