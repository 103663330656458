import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId } from '@ardoq/api-types';

export const addField = actionCreator(
  '[appModelStateEdit/manageFields] ADD_FIELD'
);

export const editField = actionCreator<string>(
  '[appModelStateEdit/manageFields] EDIT_FIELD'
);

export const deleteField = actionCreator<string>(
  '[appModelStateEdit/manageFields] DELETE_FIELD'
);

type ModifyFieldOrderPayload = ArdoqId[];
export const modifyFieldOrder = actionCreator<ModifyFieldOrderPayload>(
  '[appModelStateEdit/manageFields] MODIFY_FIELD_ORDER'
);

export const setFilter = actionCreator<string>(
  '[appModelStateEdit/manageFields] SET_FIELD_FILTER'
);
