import * as encodingUtils from '@ardoq/html';
import { markdownToSanitizedHTML } from '@ardoq/markdown';

export const stripEmphasis = (str = '') => str.replace(/§§\/?em§§/g, '');

export const stripParagraph = (str = '') => str.replace(/<\/?p>/g, '');

const EMPHASIS_REGEX = /§§(\/?em)§§/gm;

export const escapeString = (str: string) =>
  encodingUtils.escapeHTML(str).replace(EMPHASIS_REGEX, '');

export const sanitizeMarkdown = (markdownText: string) =>
  new DOMParser().parseFromString(
    markdownToSanitizedHTML(markdownText, 'permissive'),
    'text/html'
  ).documentElement.textContent || '';
