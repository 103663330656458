import { TablePreview } from 'integrations/common/streams/tablePreviews/types';
import { TabularMapping, TableId, ColumnIndex, TableMappingMap } from './types';
import type {
  TableMappingType,
  SavedTransferConfig,
  FilterCondition,
  ComponentMapping,
  TransformationColumn,
} from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { actionCreatorWithIntegrationId } from '../../utils/actionCreatorWithIntegrationId';

const NAMESPACE = '[integrations] TABULAR_MAPPING';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

type ApplyTabularMappingPayload = {
  mapping: TabularMapping;
};
export const applyTabularMapping =
  actionCreatorWithIntegrationId<ApplyTabularMappingPayload>(
    getActionName('APPLY_TABULAR_MAPPING')
  );

export const clearTabularMapping = actionCreatorWithIntegrationId(
  getActionName('CLEAR_TABULAR_MAPPING')
);

type ClearSingleTabularMappingPayload = {
  id: string;
};

export const clearSingleTabularMapping =
  actionCreatorWithIntegrationId<ClearSingleTabularMappingPayload>(
    getActionName('CLEAR_SINGLE_TABULAR_MAPPING')
  );

type SetTableMappingTypePayload = {
  tableId: TableId;
  tableType: TableMappingType;
};
export const setTableMappingType =
  actionCreatorWithIntegrationId<SetTableMappingTypePayload>(
    getActionName('SET_TABLE_MAPPING_TYPE')
  );

type ApplyTableMappingPayload = {
  tableId: TableId;
  // accepts partial mapping in-favor of sending subset of data
  tableMapping: Partial<TableMappingMap>;
};

export const applyTableMapping =
  actionCreatorWithIntegrationId<ApplyTableMappingPayload>(
    getActionName('APPLY_TABLE_MAPPING')
  );

export type ApplyColumnMappingPayload = {
  tableId: TableId;
  columnIndex: ColumnIndex;
  columnMapping: ColumnMapping;
};

export const applyColumnMapping =
  actionCreatorWithIntegrationId<ApplyColumnMappingPayload>(
    getActionName('APPLY_COLUMN_MAPPING')
  );

type RemoveTargetWorkspacePayload = {
  tableId: TableId;
};

export const removeTargetWorkspace =
  actionCreatorWithIntegrationId<RemoveTargetWorkspacePayload>(
    getActionName('UNSET_TARGET_WORKSPACE')
  );

type RemoveColumnTransformationsPayload = {
  tableId: TableId;
  index: number;
};

export const removeColumnTransformations =
  actionCreatorWithIntegrationId<RemoveColumnTransformationsPayload>(
    getActionName('REMOVE_COLUMN_TRANSFORMATIONS')
  );

type ApplyColumnTransformationsPayload = {
  tableId: TableId;
  index: number;
  transformationColumn: TransformationColumn;
};

export const applyColumnTransformations =
  actionCreatorWithIntegrationId<ApplyColumnTransformationsPayload>(
    getActionName('APPLY_COLUMN_TRANSFORMATIONS')
  );

export const setColumnTransformations = actionCreatorWithIntegrationId<{
  index: number;
  transformationColumn: TransformationColumn | null;
}>(getActionName('SET_COLUMN_TRANSFORMATIONS'));

export type RemoveColumnMappingsPayload = {
  tableId: TableId;
  columnIndexes: ColumnIndex[];
};

export const removeColumnMappings =
  actionCreatorWithIntegrationId<RemoveColumnMappingsPayload>(
    getActionName('REMOVE_COLUMN_MAPPINGS')
  );

type ApplyColumnFilterPayload = {
  tableId: TableId;
  columnIndex: ColumnIndex;
  filters: Array<FilterCondition>;
};

export const applyColumnFilter =
  actionCreatorWithIntegrationId<ApplyColumnFilterPayload>(
    getActionName('APPLY_COLUMN_FILTER')
  );

export const setColumnFilter = actionCreatorWithIntegrationId<{
  columnIndex: ColumnIndex;
  filters: Array<FilterCondition>;
}>(getActionName('SET_COLUMN_FILTER'));

type SetColumnMappingPayload = {
  columnName: string;
  columnIndex: ColumnIndex;
  columnMapping: Partial<ColumnMapping>; // enable partially setting column mapping values
};

export const setColumnMapping =
  actionCreatorWithIntegrationId<SetColumnMappingPayload>(
    getActionName('SET_COLUMN_MAPPING')
  );

type UnsetColumnMappingsPayload = {
  columnIndexes: ColumnIndex[];
};

type ChangeColumnMappingPayload = {
  tableId: TableId;
  columnName: string;
  columnIndex: ColumnIndex;
  previousMapping: ComponentMapping;
  nextMapping: ColumnMapping;
};

export const changeComponentColumnMapping =
  actionCreatorWithIntegrationId<ChangeColumnMappingPayload>(
    getActionName('CHANGE_COMPONENT_COLUMN_MAPPING')
  );

export const unsetColumnMappings =
  actionCreatorWithIntegrationId<UnsetColumnMappingsPayload>(
    getActionName('UNSET_COLUMN_MAPPINGS')
  );

type FillHierarchyLevelPayload = {
  tableId: TableId;
  hierarchyLevel: number;
};

export const fillHierarchyLevel =
  actionCreatorWithIntegrationId<FillHierarchyLevelPayload>(
    getActionName('FILL_HIERARCHY_LEVEL')
  );

type SelectMappingConfigPayload = {
  config: SavedTransferConfig;
};

export const selectMappingConfig =
  actionCreatorWithIntegrationId<SelectMappingConfigPayload>(
    getActionName('SELECT_MAPPING_CONFIG')
  );

export const unselectMappingConfig = actionCreatorWithIntegrationId(
  getActionName('UNSELECT_MAPPING_CONFIG')
);

export const recalculateFilterColumnsIndexes = actionCreatorWithIntegrationId<{
  oldTablePreviews: TablePreview[];
  newTablePreviews: TablePreview[];
  mapping: TabularMapping;
}>(getActionName('RECALCULATE_FILTER_COLUMNS_INDEXES'));

export const applyRequiredTableMapping = actionCreatorWithIntegrationId(
  getActionName('APPLY_REQUIRED_TABLE_MAPPING')
);

export const applyRequiredColumnsMapping = actionCreatorWithIntegrationId(
  getActionName('APPLY_REQUIRED_COLUMNS_MAPPING')
);
