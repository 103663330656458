const isNodeElement = (node: Node): node is HTMLElement =>
  node.nodeType === Node.ELEMENT_NODE;

export const isEditableElement = (node: Node) => {
  // https://stackoverflow.com/questions/26723648/check-whether-an-html-element-is-editable-or-not-using-js
  const nodeName = node.nodeName.toLowerCase();
  return (
    (node as HTMLElement).isContentEditable ||
    (isNodeElement(node) &&
      (nodeName === 'textarea' ||
        (nodeName === 'input' &&
          /^(?:text|email|number|search|tel|url|password)$/i.test(
            (node as HTMLInputElement).type
          ))))
  );
};

export const isElementInTargetPath = (
  element: HTMLElement,
  target: HTMLElement
): boolean => {
  if (element === target) {
    return true;
  } else if (!element.parentElement) {
    return false;
  }
  return isElementInTargetPath(element.parentElement, target);
};

export const findAncestorWithProperty = (
  element: Element,
  propertyName: string
) => {
  let result: Element | null = element;
  while (result && !(result as Record<string, any>)[propertyName]) {
    result = result.parentElement;
  }
  return result;
};

const generateElCssSelector = (el: Element) => {
  if (el === document.body) return 'body';
  const id = el.getAttribute('id');
  const classes = Array.from(el.classList)
    .map(className => `.${className}`)
    .join('');
  return id ? `#${id}` : `${el.tagName.toLowerCase()}${classes}`;
};

/** Generate CSS selector for el, starting at rootEl */
export const generateCssSelectorPath = (
  el: Element,
  rootEl: Element = document.body,
  currSelectors: string[] = []
): string => {
  const elSelector = generateElCssSelector(el);
  const parent = el.parentNode as HTMLElement;
  const newCurrSelectors = [...currSelectors, elSelector];

  if (!parent || el === rootEl) return newCurrSelectors.reverse().join(' > ');
  return generateCssSelectorPath(parent, rootEl, newCurrSelectors);
};
