import { dispatchAction } from '@ardoq/rxbeach';
import { Folder, Row } from 'components/EntityBrowser/types';
import { AssetRow } from '../AssetsBrowser/types';
import {
  moveAsset,
  setRenameId,
  setSelectedAssets,
} from './assetsBrowser2024Actions';
import {
  findSelectedFolders,
  getPermissionsForSelection,
} from '../../appContainer/DashboardContainer/utils';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { confirmMoveAssets } from '../../appContainer/DashboardContainer/confirmMoveAssets';
import { getMenuOptions as getMenuOptionsUtil } from '../../appContainer/DashboardContainer/getMenuOptions';
import { openMoveToModal } from '../FolderSelectDialog/utils';
import { Asset } from '@ardoq/api-types';
import { mapIdsToAssets } from './assetsBrowser2024Utils';
import { DropdownOption } from '@ardoq/dropdown-menu';
import { AppModules } from '../../appContainer/types';
import { APISurveyPendingApprovalsSummary } from '@ardoq/api-types';
import { RowType } from '@ardoq/table';
import { CreateAssetOptions } from '../../appContainer/DashboardContainer/getMenuOptions/types';

export type GetMenuOptionProps = {
  row: Row<AssetRow> | null;
  dataSource: Row<AssetRow>[];
  assetsById: Record<string, Asset>;
  selectedAssetIds: string[];
  expandedFoldersIds: string[];
  setHighlights: (ids: string[]) => void;
  appModule: AppModules;
  createAssetOptions: CreateAssetOptions;
  surveysWithPendingApprovals: APISurveyPendingApprovalsSummary;
};

const handleMoveToModal = async (
  assets: (Asset | AssetRow | Folder<AssetRow>)[],
  selected: string[],
  assetsById: Record<string, Asset>,
  setHighlights: (ids: string[]) => void,
  appModule: AppModules
) => {
  const selectedFolderId = await openMoveToModal();
  if (selectedFolderId === undefined || selectedFolderId === false) return;
  const assetsIds = selected.length ? selected : assets.map(({ _id }) => _id);
  const isConfirmed = await confirmMoveAssets({
    assetsIds,
    folderId: selectedFolderId,
    assetsById,
  });
  if (!isConfirmed) return;

  dispatchAction(
    moveAsset({
      payload: {
        assets,
        folderId: selectedFolderId,
      },
      appModule,
    })
  );

  setHighlights(
    [selectedFolderId, ...assets.map(({ _id }) => _id)].filter(ExcludeFalsy)
  );
};

export const getDefaultMenuOptions = ({
  row,
  dataSource,
  selectedAssetIds,
  assetsById,
  setHighlights,
  appModule,
  createAssetOptions,
  surveysWithPendingApprovals,
}: GetMenuOptionProps): DropdownOption[] => {
  if (!row || row.rowType === RowType.EMPTY_FOLDER) return [];
  const selectedAssets = mapIdsToAssets(selectedAssetIds, assetsById);
  const selectionPermissions = getPermissionsForSelection(
    selectedAssetIds,
    assetsById
  );
  const selectedFolderIds = findSelectedFolders(selectedAssetIds, dataSource);
  return getMenuOptionsUtil(row, {
    selected: selectedAssetIds,
    selectedAssets,
    selectedFolders: selectedFolderIds,
    selectionPermissions,
    rowPermissions: row.meta.permissions || {},
    createAssetOptions,
    surveysWithPendingApprovals,
    onRenameSelect: (rowId: string) =>
      dispatchAction(setRenameId({ payload: rowId, appModule })),
    clearSelected: () =>
      dispatchAction(setSelectedAssets({ payload: [], appModule })),
    handleMoveToModal: (...args) =>
      handleMoveToModal(
        ...args,
        selectedAssetIds,
        assetsById,
        setHighlights,
        appModule
      ),
  });
};
