import { Text } from '@ardoq/typography';
import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { NavigationMenuGroup } from '../types';
import { IconSize } from '@ardoq/icons';

export const NavigationSidebarMenuSection = ({
  item,
  children,
  onItemClick,
}: React.PropsWithChildren<{
  item: NavigationMenuGroup;
  onItemClick?: (item: NavigationMenuGroup) => void;
}>) => {
  return (
    <SidebarMenuSection
      key={item.label}
      title={
        <Text align="left" variant="text2Bold" color="textSubtle">
          {item.label}
        </Text>
      }
      leftIcon={{ name: item.iconName, size: IconSize.REGULAR }}
      isCollapsible={item.isCollapsible}
      isCollapsed={item.isCollapsed}
      titleDataTestId="section-user-management"
      onClick={isCollapsed => {
        item.onClick?.({ ...item, isCollapsed });
        onItemClick?.({ ...item, isCollapsed });
      }}
      itemsGap="none"
      gap="none"
    >
      {children}
    </SidebarMenuSection>
  );
};
