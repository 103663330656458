import { Asset, AssetType } from '@ardoq/api-types';
import { ButtonType } from '@ardoq/button';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { AssetRow } from 'components/AssetsBrowser/types';
import { Folder } from 'components/EntityBrowser/types';
import { HandleMoveToModal } from './types';
import { trackEvent } from 'tracking/tracking';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';

const handleDropdownMenuTracking = (
  action: string,
  location?: string,
  metadata = {}
) => {
  trackEvent(`${location ?? 'Assets Browser'}: clicked dropdown menu`, {
    action,
    ...metadata,
  });
};

type DeleteMenuOptionArgs = {
  asset: Asset | AssetRow | Folder<AssetRow>;
  hasPermission: boolean | undefined;
  location?: string;
  onClick: VoidFunction;
};

export const deleteMenuOption = ({
  asset,
  hasPermission,
  location,
  onClick,
}: DeleteMenuOptionArgs) => {
  if (!hasPermission) return null;
  return {
    label: 'Delete',
    type: DropdownOptionType.BUTTON_OPTION,
    buttonType: ButtonType.DANGER,
    onClick: () => {
      onClick();
      handleDropdownMenuTracking('delete', location, {
        assetType: asset.meta.assetType,
      });
    },
  };
};

type MoveToMenuOptionArgs = {
  asset: Asset | AssetRow | Folder<AssetRow>;
  hasPermission: boolean | undefined;
  location?: string;

  handleMoveToModal?: HandleMoveToModal;
};

export const moveToMenuOption = ({
  asset,
  hasPermission,
  location,
  handleMoveToModal,
}: MoveToMenuOptionArgs) => {
  if (!handleMoveToModal || !hasPermission) return null;
  return {
    label: 'Move to',
    type: DropdownOptionType.OPTION,
    onClick: () => {
      handleMoveToModal([asset]);
      handleDropdownMenuTracking('move', location, {
        assetType: asset.meta.assetType,
      });
    },
  };
};

type RenameMenuOptionArgs = {
  asset: Asset | AssetRow | Folder<AssetRow>;
  hasPermission: boolean | undefined;
  location?: string;

  onRenameSelect: (editRowId: string) => void;
};

export const renameMenuOption = ({
  asset,
  hasPermission,
  location,
  onRenameSelect,
}: RenameMenuOptionArgs) => {
  if (!hasPermission) return null;
  return {
    label: 'Rename',
    type: DropdownOptionType.OPTION,
    onClick: () => {
      onRenameSelect(asset._id);
      handleDropdownMenuTracking('rename', location, {
        assetType: asset.meta.assetType,
      });
    },
  };
};

type OpenMenuOptionArgs = {
  asset: Asset | AssetRow | Folder<AssetRow>;
  location?: string;
  onClick: VoidFunction;
};

export const openMenuOption = ({
  asset,
  location,
  onClick,
}: OpenMenuOptionArgs) => {
  return {
    label: 'Open',
    type: DropdownOptionType.OPTION,
    onClick: () => {
      onClick();
      handleDropdownMenuTracking('open', location, {
        assetType: asset.meta.assetType,
      });
    },
  };
};

type CopyMenuOptionArgs = {
  assetType: AssetType;
  hasPermission: boolean | undefined;
  location?: string;

  onCopy: VoidFunction;
};

export const copyMenuOption = ({
  assetType,
  hasPermission,
  location,
  onCopy,
}: CopyMenuOptionArgs) => {
  if (!hasPermission) return null;
  return {
    label: 'Copy',
    type: DropdownOptionType.OPTION,
    onClick: () => {
      onCopy();
      handleDropdownMenuTracking('copy', location, {
        assetType,
      });
    },
  };
};

type EditMenuOptionArgs = {
  asset: Asset | AssetRow | Folder<AssetRow>;
  hasPermission: boolean | undefined;
  location?: string;

  onEdit: VoidFunction;
};

export const editMenuOption = ({
  asset,
  hasPermission,
  location,
  onEdit,
}: EditMenuOptionArgs) => {
  if (!hasPermission) return null;
  return {
    label: 'Edit',
    type: DropdownOptionType.OPTION,
    onClick: () => {
      onEdit();
      handleDropdownMenuTracking('edit', location, {
        assetType: asset.meta.assetType,
      });
    },
  };
};

export const dividerMenuOption = (isVisible = true) =>
  isVisible
    ? {
        label: null,
        type: DropdownOptionType.DIVIDER,
      }
    : null;

type AssetOverviewMenuOptionArgs = {
  asset: Asset | AssetRow | Folder<AssetRow>;
  location?: string;
  onClick: VoidFunction;
};

export const assetOverviewMenuOption = ({
  asset,
  location,
  onClick,
}: AssetOverviewMenuOptionArgs) => {
  return {
    label: 'View details',
    type: DropdownOptionType.OPTION,
    onClick: () => {
      onClick();
      handleDropdownMenuTracking('view details', location, {
        assetType: asset.meta.assetType,
      });
    },
  };
};

type ManagePermissionsMenuOptionArgs = {
  asset: Asset | AssetRow | Folder<AssetRow>;
  hasPermission: boolean | undefined;
  location?: string;
  onClick: VoidFunction;
};

export const managePermissionsMenuOption = ({
  asset,
  hasPermission,
  location,
  onClick,
}: ManagePermissionsMenuOptionArgs) => {
  if (!hasPermission) return null;
  return {
    label: 'Manage permissions',
    type: DropdownOptionType.OPTION,
    onClick: () => {
      onClick();
      trackEvent(APPSEC_EVENTS.CLICKED_ASSET_PERMISSIONS_MENU_ITEM, {
        from: location,
        assetType: asset.meta.assetType,
      });
    },
  };
};
