import { Select, CreatableSelect } from '@ardoq/select';
import { NewBadge } from '@ardoq/status-ui';
import { ComponentMapping } from '@ardoq/api-types/integrations';
import { OnColumnMappingChange } from './types';
import {
  getColumnMappingByHierararchyLevel,
  getHierarchyLevelOptions,
  getWorkspaceComponentTypeOptions,
  hasComponentTypeColumn,
  hasParentColumn,
  rootWorkspaceExists,
} from 'integrations/common/streams/tabularMappings/utils';
import { TextInput } from '@ardoq/forms';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import { isNil } from 'lodash/fp';
import styled from 'styled-components';
import {
  MappedColumnsErrors,
  SidebarSelectorsIds,
} from 'integrations/common/streams/tabularMappingErrors/types';
import { useEffect, useRef } from 'react';

export const ComponentType = ({
  onColumnMappingChange,
  columnMapping,
  columnName,
  selectedColumn,
  tableMapping,
  mappedColumnsErrors,
  isCreationDisabled,
  onFirstComponentTypeChange,
}: {
  tableMapping: TableMappingMap;
  selectedColumn: number;
  onColumnMappingChange: OnColumnMappingChange;
  columnMapping: ComponentMapping;
  columnName: string;
  mappedColumnsErrors: MappedColumnsErrors;
  isCreationDisabled: boolean;
  onFirstComponentTypeChange?: (changedDefaultColumnType: boolean) => void;
}) => {
  const hasRootWorkspace = rootWorkspaceExists(tableMapping);
  const containsComponentTypeColumn = hasComponentTypeColumn(tableMapping);
  const containsParentColumn = hasParentColumn(tableMapping);
  const hierarchyLevelOptions = getHierarchyLevelOptions(tableMapping);
  const existingWorkspaceComponentTypeOptions =
    getWorkspaceComponentTypeOptions(tableMapping);
  const isNewComponentType = !existingWorkspaceComponentTypeOptions.find(
    option => option.value === columnMapping.componentType
  );
  const workspaceComponentTypeOptions = isNewComponentType
    ? [
        ...existingWorkspaceComponentTypeOptions,
        {
          label: columnMapping.componentType,
          value: columnMapping.componentType,
          rightContent: <NewBadge />,
        },
      ]
    : existingWorkspaceComponentTypeOptions;
  const SelectComponentType = isCreationDisabled ? Select : CreatableSelect;

  const changedDefaultColumnType = useRef(false);

  function handleComponentTypeChange(value: string | null) {
    // Empty string is a valid value
    if (value === null) return;
    changedDefaultColumnType.current = true;
    onColumnMappingChange({
      columnName,
      columnIndex: selectedColumn,
      columnMapping: { componentType: value },
    });
  }

  useEffect(() => {
    return () => {
      onFirstComponentTypeChange?.(changedDefaultColumnType.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Select
        dataTestId="tabularMapping-component-hierarchy-level--select"
        autoFocus
        isDisabled={containsParentColumn}
        popoverContent={
          containsParentColumn
            ? 'The hierarchy level is determined by the “Parent component” column.'
            : undefined
        }
        label="Hierarchy level"
        value={columnMapping.hierarchyLevel}
        options={hierarchyLevelOptions}
        onValueChange={value => {
          if (isNil(value)) return;
          const swappedColumn = getColumnMappingByHierararchyLevel(
            tableMapping,
            value
          );
          if (swappedColumn && swappedColumn.index !== columnMapping.index) {
            onColumnMappingChange({
              columnName: '',
              columnIndex: swappedColumn.index,
              columnMapping: { hierarchyLevel: columnMapping.hierarchyLevel },
            });
          }
          onColumnMappingChange({
            columnName,
            columnIndex: selectedColumn,
            columnMapping: { hierarchyLevel: value },
          });
        }}
      />
      {hasRootWorkspace && !containsComponentTypeColumn ? (
        <SelectComponentType
          dataTestId="tabularMapping-component-component-type--select"
          label="Component Type"
          value={columnMapping.componentType}
          options={workspaceComponentTypeOptions}
          onValueChange={handleComponentTypeChange}
        />
      ) : (
        <ComponentTypeTextInput
          dataTestId="tabularMapping-component-component-type--input"
          isDisabled={hasRootWorkspace || containsComponentTypeColumn}
          label="Component Type"
          popoverContent={
            containsComponentTypeColumn
              ? 'The component type is determined by the selected "Type" column.'
              : undefined
          }
          hasError={!!mappedColumnsErrors[SidebarSelectorsIds.COMPONENT_TYPE]}
          errorMessage={
            mappedColumnsErrors[SidebarSelectorsIds.COMPONENT_TYPE] || undefined
          }
          value={columnMapping.componentType}
          displayValue={
            containsComponentTypeColumn
              ? 'Defined by "Type" column'
              : columnMapping.componentType
          }
          onValueChange={handleComponentTypeChange}
        />
      )}
    </>
  );
};

const ComponentTypeTextInput = styled(TextInput)`
  input {
    padding: 6px 8px;
  }
`;
