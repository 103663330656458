import { colors } from '@ardoq/design-tokens';
import { opacify, parseToRgb, rgb } from 'polished';
import { RgbColor } from 'polished/lib/types/color';

export const NAVIGATOR_BACKGROUND = colors.grey95;
export const NAVIGATOR_ITEM_ACTIVE_BACKGROUND = colors.white;
const NAVIGATOR_ITEM_SELECTION_OPACITY = 0.3;
export const NAVIGATOR_ITEM_SELECTION_BACKGROUND = `hsla(210, 100%, 50%, ${NAVIGATOR_ITEM_SELECTION_OPACITY})`;

const flattenChannel = (
  opacity: number,
  foreground: number,
  background: number
) => Math.round(opacity * foreground + (1 - opacity) * background);
const flattenColor = (
  opacity: number,
  foreground: RgbColor,
  background: RgbColor
) =>
  rgb(
    flattenChannel(opacity, foreground.red, background.red),
    flattenChannel(opacity, foreground.green, background.green),
    flattenChannel(opacity, foreground.blue, background.blue)
  );

/** this is the effective selected color, considering the navigator background and the partially transparent selection color above. */
export const NAVIGATOR_ITEM_SELECTED_BACKGROUND_OPAQUE = flattenColor(
  NAVIGATOR_ITEM_SELECTION_OPACITY,
  parseToRgb(opacify(1, NAVIGATOR_ITEM_SELECTION_BACKGROUND)),
  parseToRgb(NAVIGATOR_BACKGROUND)
);

export const NAVIGATOR_TREE_FILTER_ID = 'navigator-tree-filter';
export const NAVIGATOR_TREE_FILTER_BUTTON_ID = 'navigator-tree-filter-button';

export const WORKSPACE_ITEM_CLASS_NAME = 'workspace-item';
export const COMPONENT_ITEM_CLASS_NAME = 'component-item';
export const SCENARIO_ITEM_CLASS_NAME = 'scenario-item';
export const DRAG_TARGET_CLASS_NAME = 'drag-target';
