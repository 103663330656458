export enum SurveyAdminMode {
  OVERVIEW,
  NEW_SURVEY,
  EDIT_SURVEY,
  CHANGE_APPROVAL,
}

export type SurveyOverviewSearchParams = {
  token?: string | null;
  updateType?: string | null;
  ref?: string | null;
  surveyId?: string | null;
};
