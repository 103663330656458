import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { catchError, tap, mergeMap, filter } from 'rxjs/operators';
import {
  fetchTableFields as fetchTableFieldsAction,
  resetTableFields,
  setRequestStatus,
  setTableFields,
} from './actions';
import { EMPTY, forkJoin, of, withLatestFrom } from 'rxjs';
import {
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';
import { tablesFields$ } from './tableFields$';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { servicenowIntegrationApi } from '@ardoq/api';

const handleFetchTableFields = routine(
  ofType(fetchTableFieldsAction),
  extractPayload(),
  withLatestFrom(tablesFields$, getConnectionsStream('servicenow-v3')),
  mergeMap(([tableId, tableFields, { selectedConnectionIds }]) => {
    const requestStatus = tableFields[tableId]?.requestStatus;

    // fetch table fields only if it hasn't already been fetched
    if (
      (requestStatus && requestStatus !== 'INIT') ||
      !selectedConnectionIds.length
    ) {
      return EMPTY;
    }
    const connectionId = selectedConnectionIds[0];
    dispatchAction(setRequestStatus({ tableId, status: 'LOADING' }));

    return forkJoin({
      tableId: of(tableId),
      fields: retriableRequest(
        () =>
          servicenowIntegrationApi.fetchTableFields({
            tableId,
            connectionId,
          }),
        'Unable to fetch servicenow table fields'
      ).pipe(
        catchError(error => {
          dispatchAction(setRequestStatus({ tableId, status: 'FAILURE' }));
          return handleApiError(error);
        })
      ),
    });
  }),
  tap(({ fields, tableId }) => {
    dispatchAction(
      setTableFields({
        fields,
        tableId,
      })
    );
  })
);

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  filter((integrationId: IntegrationId) => integrationId === 'servicenow-v3'),
  tap(() => {
    dispatchAction(resetTableFields());
  })
);

export default [handleFetchTableFields, handleResetIntegration];
