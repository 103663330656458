import { isInScopeDiffMode } from 'scope/scopeDiff';
import {
  loadDiff,
  updateEnhancedScopeDiffData,
  updateScopeDiff,
} from 'scope/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import useUserSettingToggle from 'models/utils/useUserSettingToggle';
import {
  CloseVisualDiffClickSource,
  trackClickCloseVisualDiff,
  trackClickOpenVisualDiff,
} from 'scope/tracking';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { hideRightPane } from 'appContainer/actions';
import { isRightEditorPaneOpen$ } from 'appLayout/isRightEditorPaneOpen$';
import { trackEvent } from 'tracking/tracking';
import { MainAppModuleTrackingEventsNames } from '../../tracking';

export const useShowFilteredComponents = (): [boolean, () => void] => {
  const [areFilteredComponentsShown, toggleShowFilteredComponents] =
    useUserSettingToggle('showFilteredComponents');

  const toggleShowFilteredComponentsAndTrack = () => {
    trackEvent(
      areFilteredComponentsShown
        ? MainAppModuleTrackingEventsNames.HIDE_FILTERED_COMPONENTS
        : MainAppModuleTrackingEventsNames.SHOW_FILTERED_COMPONENTS
    );
    toggleShowFilteredComponents();
  };

  return [areFilteredComponentsShown, toggleShowFilteredComponentsAndTrack];
};

export const closeVisualDiff = () => {
  dispatchAction(updateScopeDiff({ scopeDiff: null }));
  dispatchAction(
    updateEnhancedScopeDiffData({
      main: null,
      branch: null,
      branchOff: null,
      diffData: null,
    })
  );
};

export const toggleVisualDiff = ({
  clickSource,
}: {
  clickSource: CloseVisualDiffClickSource;
}) => {
  if (isInScopeDiffMode()) {
    closeVisualDiff();
    trackClickCloseVisualDiff(clickSource);
  } else {
    dispatchAction(
      loadDiff({
        scenarioId: getActiveScenarioId(),
      })
    );
    if (isRightEditorPaneOpen$.state) {
      dispatchAction(hideRightPane());
    }
    trackClickOpenVisualDiff(clickSource);
  }
};
