import { ReactElement } from 'react';
import { connect } from '@ardoq/rxbeach';
import diffMergeTable$ from 'components/DiffMergeTable/diffMergeTable$';
import { Branch } from 'components/DiffMergeTable/Branch';
import { EnhancedDiffContextData } from 'components/DiffMergeTable/EnhancedDiffContextDataType';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { EnhancedScopeDataWithBranchName, Graphics } from '@ardoq/renderers';

export type ProvidedProps = {
  enhancedScopeData: EnhancedScopeDataWithBranchName;
  graphics: Graphics;
  mergeDirection: MergeDirection;
};

type PopoverDataProviderProps = {
  branch: Branch;
  children: (params: ProvidedProps) => ReactElement;
};

type StreamProps = {
  enhancedDiffContextData: EnhancedDiffContextData | null;
  graphics: Graphics | null;
  mergeDirection: MergeDirection;
};

const PopoverDataProvider = ({
  enhancedDiffContextData,
  branch,
  graphics,
  children,
  mergeDirection,
}: PopoverDataProviderProps & StreamProps) => {
  const enhancedScopeData = enhancedDiffContextData?.[branch];
  if (!enhancedScopeData || !graphics) return <></>;
  return children({ enhancedScopeData, graphics, mergeDirection });
};

export default connect(PopoverDataProvider, diffMergeTable$);
