import { DoqType } from '@ardoq/doq';
import { getFormattedDate, getFutureDate, isRepeatingSchedule } from '../utils';
import { pluralize } from '@ardoq/common-helpers';
import { isToday, parseDate } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { addHours, startOfHour } from '@ardoq/date-time';
import { APIBroadcastAttributes } from '@ardoq/api-types';
import { info, ModalLayout, ModalSize } from '@ardoq/modal';
import { Text } from '@ardoq/typography';

type BroadcastScheduledProps = {
  broadcast: APIBroadcastAttributes;
};

const formatTime = (date: Date) => {
  const locale = getCurrentLocale();

  return new Intl.DateTimeFormat(locale.code, {
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

const betweenText = (dateString: string) => {
  const date = parseDate(dateString);
  const startTime = startOfHour(date);
  const endTime = addHours(startTime, 1);
  const formattedDate = getFormattedDate(dateString);

  if (date) {
    return `It will be sent on ${formattedDate} between ${formatTime(
      startTime
    )} and ${formatTime(endTime)}`;
  }

  return `It will be sent on ${dateString}`;
};

const BroadcastScheduled = ({ broadcast }: BroadcastScheduledProps) => {
  return (
    <ModalLayout>
      <div>
        <Text>Your broadcast is scheduled!</Text>
        {isToday(parseDate(broadcast.nextRuntime))
          ? 'It will be sent within 1 hour'
          : betweenText(broadcast.nextRuntime)}
        {isRepeatingSchedule(broadcast.scheduling)
          ? [
              ', and it will repeat every',
              broadcast.scheduling.interval.intervalValue === 1
                ? ''
                : broadcast.scheduling.interval.intervalValue,
              pluralize(
                broadcast.scheduling.interval.intervalType,
                broadcast.scheduling.interval.intervalValue
              ),
            ].join(' ')
          : ''}
        .
        {broadcast.scheduling.reminderInDays && (
          <p>
            The reminder will be sent {broadcast.scheduling.reminderInDays}{' '}
            {pluralize('day', broadcast.scheduling.reminderInDays)} after the
            broadcast date, and the next reminder date is on{' '}
            {getFutureDate(
              broadcast.nextRuntime,
              broadcast.scheduling.reminderInDays
            )}
            .
          </p>
        )}
      </div>
    </ModalLayout>
  );
};

/**
 * Helper function to get the dialog content a successful broadcast
 */
const openBroadcastScheduledDialog = (broadcast: APIBroadcastAttributes) =>
  info({
    title: 'Broadcast scheduled',
    doqType: DoqType.SUCCESS,
    body: <BroadcastScheduled broadcast={broadcast} />,
    modalSize: ModalSize.XS,
  });

export default openBroadcastScheduledDialog;
