import {
  NAVIGATOR_TREE_FILTER_ID,
  NAVIGATOR_TREE_FILTER_BUTTON_ID,
} from 'components/WorkspaceHierarchies/consts';

export const isNavigatorTreeFilterOrFilterButton = (
  target: EventTarget | null
) => {
  if (!(target instanceof Element)) return false;

  return Boolean(
    (target.matches('input') &&
      target.closest(`#${NAVIGATOR_TREE_FILTER_ID}`)) ||
      target.closest(`#${NAVIGATOR_TREE_FILTER_BUTTON_ID}`)
  );
};
