import { TripleDirection } from '@ardoq/api-types';
import { MetadataComponent, PathCollapsingRuleInternal } from '../types';

export type EditablePathCollapsingRule = PathCollapsingRuleInternal & {
  currentRuleHash: string;
  startNodeId: string | null;
  errorMessage: string | null;
};

export type ViewStateEditablePathCollapsingRule = EditablePathCollapsingRule & {
  startNode: MetadataComponent | null;
  endNode: MetadataComponent | null;
  isSavable: boolean;
};

export const isSavedPathCollapsingRule = (
  rule: ViewStatePathCollapsingRule
): rule is SavedViewStatePathCollapsingRule =>
  'collapsedPath' in rule && rule.collapsedPath !== null;

export type SavedViewStatePathCollapsingRule = {
  hash: string;
  collapsedPath: {
    direction: TripleDirection;
    referenceType: string;
    componentTypeMetaData: MetadataComponent;
  }[];
  startNode: MetadataComponent;
  endNode: MetadataComponent;
  displayText: string;
  isExpanded: boolean;
  isActive: boolean;
};

type UnsavedViewStatePathCollapsingRule = {
  hash: string;
};

export type ViewStatePathCollapsingRule =
  | UnsavedViewStatePathCollapsingRule
  | SavedViewStatePathCollapsingRule;

export type PathCollapsingViewState = {
  currentEditableRule: ViewStateEditablePathCollapsingRule | null;
  rules: ViewStatePathCollapsingRule[];
};
