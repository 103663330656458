import { colors, spacing } from '@ardoq/design-tokens';
import styled from 'styled-components';

export const UserCircularWrapper = styled.div`
  color: ${colors.grey25};
  height: ${spacing.s24};
  width: ${spacing.s24};
  border-radius: 50%;
  font-size: 9px;
  background: ${colors.grey90};
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  user-select: none;
  border: 1px solid ${colors.white};
  &:not(:first-of-type) {
    margin-left: -${spacing.s4};
  }
`;
