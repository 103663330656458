import styled from 'styled-components';
import { ArdoqId } from '@ardoq/api-types';
import {
  Drawer,
  ResolvedComponentIcon,
  SidebarContent,
} from '@ardoq/component-overview';
import { EnhancedScopeData } from '@ardoq/data-model';
import { connect } from '@ardoq/rxbeach';
import { detailsDrawer$, DetailsDrawerCommands } from './detailsDrawer$';
import { zIndex } from '@ardoq/design-tokens';
import { TabsGroup, Tab } from '@ardoq/tabs';
import { TriplesToggler } from 'viewpointBuilder/components/TriplesToggler';
import { TraversalBuilderViewState } from 'viewpointBuilder/types';
import { EditTraversalCommands } from 'viewpointBuilder/traversals/editTraversalCommands';
import { Box } from '@ardoq/layout';
import { Link, Text } from '@ardoq/typography';

const DetailsDrawerContainer = styled.div`
  position: fixed;
  right: 0;
  height: 100%;
  z-index: ${zIndex.DETAILS_DRAWER};
`;

type DetailsDrawerProps = {
  scopeData: EnhancedScopeData | null;
  componentId: ArdoqId | null;
  editTraversalState: TraversalBuilderViewState;
  toggleReferenceTypesInViewCommands: EditTraversalCommands;
  bottomOffsetHeight?: number | string;
  commands: DetailsDrawerCommands;
};

const DetailsDrawer = ({
  scopeData,
  componentId,
  editTraversalState,
  toggleReferenceTypesInViewCommands,
  bottomOffsetHeight,
  commands,
}: DetailsDrawerProps) => {
  if (!scopeData || !componentId) return null;
  const component = scopeData.componentsById[componentId];

  return (
    <DetailsDrawerContainer
      style={{
        maxHeight: `calc(100% - ${bottomOffsetHeight ?? 0}px)`,
      }}
    >
      <Drawer
        onClose={() => commands.hideDetailsDrawer()}
        isOpen
        sidebarContent={
          <TabsGroup defaultActiveTabId="details">
            <Tab label="Details" tabId="details">
              <SidebarContent
                widgetId="details"
                scopeData={scopeData}
                component={component}
              />
              <Box
                borderColor="borderSubtle00"
                paddingY="medium"
                paddingX="large"
              >
                <Link
                  href={`/app/component-overview/${component._id}`}
                  target="_blank"
                >
                  Go to overview page
                </Link>
              </Box>
              <SidebarContent
                widgetId="references"
                scopeData={scopeData}
                component={component}
              />
            </Tab>
            <Tab label="Open new dataset" tabId="select-reference-types">
              <Box paddingLeft="small">
                <Box padding="medium" paddingBottom="small">
                  <Text variant="text2" color="textSubtle">
                    Add a new dataset by turning on references for the selected
                    component.
                  </Text>
                </Box>
                <TriplesToggler
                  tripleOptions={editTraversalState.filteredTripleOptions}
                  commands={toggleReferenceTypesInViewCommands}
                  triplesSortAndFiltersState={
                    editTraversalState.triplesSortAndFiltersState
                  }
                  hasTripleOptions={editTraversalState.hasTripleOptions}
                  togglerStyle="visibility"
                  isDisabledWholeRow={false}
                />
              </Box>
            </Tab>
          </TabsGroup>
        }
        drawerTitle={component.name}
        drawerHeaderLeftContent={
          <ResolvedComponentIcon component={component} scopeData={scopeData} />
        }
        shouldCoverContent={false}
        shadow="shadowM"
      />
    </DetailsDrawerContainer>
  );
};

export const BoxWithMaxWidth = styled(Box)`
  max-width: 550px;
`;

export default connect(DetailsDrawer, detailsDrawer$);
