import { ArdoqId } from '@ardoq/api-types';
import {
  actionCreator,
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap } from 'rxjs/operators';
import { showEditTemplateDialog } from 'editors/template/templateEditor';
import { modelInterface } from 'modelInterface/models/modelInterface';

interface EditTemplatePayload {
  id: ArdoqId;
}

export const showEditTemplate = actionCreator<EditTemplatePayload>(
  '[template] SHOW_EDIT_TEMPLATE'
);

const handleShowEditTemplate = routine(
  ofType(showEditTemplate),
  extractPayload(),
  tap(({ id }) => {
    const model = modelInterface.get(id)!;
    showEditTemplateDialog(model);
  })
);

export default collectRoutines(handleShowEditTemplate);
