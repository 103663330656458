import { getOr, set } from 'lodash/fp';
import { FormWrapper, TextInput } from '@ardoq/forms';
import { ModalLayout, ModalTemplate } from '@ardoq/modal';
import { ErrorNotification } from '@ardoq/status-ui';
import { Link, header4, text1 } from '@ardoq/typography';
import { AsyncStatus } from 'integrations/common/types/api';
import {
  ConnectionFieldDefinition,
  AuthMethodFields,
} from 'integrations/unified/types';
import {
  UnifiedAuthMethod,
  UnifiedConnection,
} from '@ardoq/api-types/integrations';
import { useState } from 'react';
import styled from 'styled-components';
import { getHiddenFieldValues, validateNewAccountInput } from './utils';
import { URLInputOption } from 'integrations/common/components/urlInputOption/URLInputOption';
import { RadioGroup } from 'integrations/common/components/tabularMapping/RadioGroupItem';

type UpsertAccountDialogParams = {
  integrationName: string;
  apiStatus: AsyncStatus;
  apiError: string | undefined;
  exisitingAccount?: UnifiedConnection;
  onSubmit: (account: UnifiedConnection) => void;
  onCancel?: () => void;
  authMethodFields: AuthMethodFields;
  defaultAuthMethod: UnifiedAuthMethod;
};

const defaultAccount: UnifiedConnection = {
  _id: '',
  name: '',
  baseUrl: '',
  accessKey: '',
  secretKey: '',
  token: '',
  authMethod: 'basic',
};

export function UpsertAccountDialog({
  integrationName,
  apiStatus,
  apiError,
  exisitingAccount,
  onCancel,
  onSubmit,
  authMethodFields,
  defaultAuthMethod,
}: UpsertAccountDialogParams) {
  const [accountInput, setAccountInput] = useState<UnifiedConnection>(
    exisitingAccount || { ...defaultAccount, authMethod: defaultAuthMethod }
  );
  const [validationError, setValidationError] = useState<string>();

  const fieldDefinitions: ConnectionFieldDefinition[] =
    authMethodFields[accountInput.authMethod]?.fieldDefinitions || [];

  const hiddenFieldValues = getHiddenFieldValues(fieldDefinitions);

  const handleSubmit = () => {
    const { isValid, message } = validateNewAccountInput([], accountInput);
    if (!isValid) {
      setValidationError(message);
      return;
    }
    onSubmit({
      ...accountInput,
      ...hiddenFieldValues,
      providerParams: {
        ...accountInput.providerParams,
        ...hiddenFieldValues.providerParams,
      },
    });
  };

  return (
    <ModalTemplate
      modalSize={2}
      headerText={
        exisitingAccount
          ? `Modify ${integrationName} account`
          : `Connect new ${integrationName} account`
      }
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onCancel}
      primaryButtonText="Connect"
      onPrimaryButtonClick={handleSubmit}
      isInProgress={apiStatus === 'LOADING'}
    >
      <ModalLayout>
        <Form>
          <Header4>
            Enter your {integrationName} connection details to connect your
            account to Ardoq. This information will not be shared.
          </Header4>
          <Text1>
            We securely handle your data:{' '}
            <Link target="_blank" href="https://www.ardoq.com/privacy">
              Privacy Notice
            </Link>
          </Text1>
          <TextInput
            value={accountInput.name}
            onValueChange={val =>
              setAccountInput({ ...accountInput, name: val })
            }
            label="Give your connection a descriptive name"
          />
          {Object.values(authMethodFields).length > 1 && (
            <RadioGroup
              value={accountInput.authMethod}
              options={Object.entries(authMethodFields).map(
                ([type, { label }]) => ({
                  label,
                  value: type,
                })
              )}
              onChange={val =>
                setAccountInput({
                  ...accountInput,
                  authMethod: val as UnifiedAuthMethod,
                })
              }
            />
          )}
          {fieldDefinitions.map(
            (fieldDefinition: ConnectionFieldDefinition) => {
              switch (fieldDefinition.type) {
                case 'url':
                  return (
                    <URLInputOption
                      key={fieldDefinition.keyPath.join('.')}
                      label={fieldDefinition.label}
                      baseUrl={accountInput.baseUrl}
                      urlProtocol={fieldDefinition.urlProtocol}
                      standardDomain={fieldDefinition.standardDomain}
                      onChange={val =>
                        setAccountInput(
                          set(fieldDefinition.keyPath, val, accountInput)
                        )
                      }
                    />
                  );
                case 'text':
                case 'password':
                  return (
                    <TextInput
                      key={fieldDefinition.keyPath.join('.')}
                      type={fieldDefinition.type}
                      value={getOr('', fieldDefinition.keyPath, accountInput)}
                      onValueChange={val =>
                        setAccountInput(
                          set(fieldDefinition.keyPath, val, accountInput)
                        )
                      }
                      label={fieldDefinition.label}
                      helperText={fieldDefinition.helperText}
                    />
                  );
              }
            }
          )}
          {(validationError || apiError) && (
            <ErrorNotification>{validationError || apiError}</ErrorNotification>
          )}
        </Form>
      </ModalLayout>
    </ModalTemplate>
  );
}

const Form = styled(FormWrapper)`
  width: 100%;
`;

const Header4 = styled.div`
  ${header4};
`;

const Text1 = styled.div`
  ${text1};
`;
