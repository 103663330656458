import { Column, DatasourceTable, newTableTheme } from '@ardoq/table';
import { IconDecorator } from '@ardoq/decorators';
import { IconName } from '@ardoq/icons';
import { DataOverviewWorkspaceRow, DataOverviewTypes } from './types';
import { formatDateTime } from '@ardoq/date-time';
import { getNumberFormat } from '@ardoq/locale';
import { Locale } from '@ardoq/locale';
import { FlexBox, Box } from '@ardoq/layout';
import { PaginationBar, PaginationController } from '@ardoq/pagination';
import { Island } from '@ardoq/page-layout';
import { DataOverviewCommands } from './dataOverviewCommands';
import { Tag, TagGroup } from '@ardoq/status-ui';
import { DEFAULT_PAGE_SIZE_OPTIONS } from './consts';
import { DoqWithSpeechBubble, DoqType, DoqLayout } from '@ardoq/doq';
import { ArdoqId } from '@ardoq/api-types';
import { Checkbox } from '@ardoq/forms';
import { SortOrder } from '@ardoq/api-types';
import { Link } from '@ardoq/typography';
import { s24 } from '@ardoq/design-tokens';
import { inventoryTracking } from '../inventoryTracking';

const getColumns = (
  locale: Locale,
  dataOverviewCommands: DataOverviewCommands,
  selectedItems: ArdoqId[]
): Column<DataOverviewWorkspaceRow>[] => [
  {
    title: '',
    dataIndex: 'id',
    headerStyle: {
      width: '0',
      paddingRight: '0',
      paddingTop: s24,
      paddingBottom: s24,
    },
    cellStyle: { width: '0', paddingRight: '0' },
    valueRender: (_dataIndex: string, row: DataOverviewWorkspaceRow) => (
      <Checkbox
        onChange={value => {
          dataOverviewCommands.toggleSelectedItem(row._id);
          inventoryTracking.trackToggleCheckboxForItemInDataOverview({
            dataOverviewType: DataOverviewTypes.WORKSPACE,
            value,
          });
        }}
        stopPropagation
        isChecked={selectedItems.includes(row._id)}
      />
    ),
  },
  {
    title: 'Workspace name',
    dataIndex: 'name',
    valueRender: (_dataIndex: string, row: DataOverviewWorkspaceRow) => (
      <Link
        hideIcon
        underlineOnHover
        type="primary"
        typography="text1"
        title={row.name}
        onClick={() => {
          dataOverviewCommands.openItemsInInventory(
            DataOverviewTypes.WORKSPACE,
            [row._id]
          );
          inventoryTracking.trackClickOnItemNameInDataOverview(
            DataOverviewTypes.WORKSPACE
          );
        }}
      >
        <IconDecorator iconName={IconName.WORKSPACE}>{row.name}</IconDecorator>
      </Link>
    ),
  },
  {
    title: 'Component types',
    valueRender: (_dataIndex: string, row: DataOverviewWorkspaceRow) => (
      <TagGroup>
        <Tag leftIcon={{ name: IconName.SHOW_COMPONENT }}>
          {row.componentTypeIds.length}
        </Tag>
      </TagGroup>
    ),
  },
  {
    title: 'Total number of components',
    valueRender: (_dataIndex: string, row: DataOverviewWorkspaceRow) =>
      getNumberFormat({}, locale).format(row.totalNumberOfComponents),
  },
  {
    title: 'Last updated',
    valueRender: (_dataIndex: string, row: DataOverviewWorkspaceRow) =>
      formatDateTime(row.lastUpdated, locale),
  },
];

export type WorkspacesTableProps = {
  datasource: DataOverviewWorkspaceRow[];
  locale: Locale;
  dataOverviewCommands: DataOverviewCommands;
  numberOfRowsPerPage: number;
  isSearchActive: boolean;
  isLoading: boolean;
  selectedItems: ArdoqId[];
};

const WorkspacesTable = ({
  locale,
  datasource,
  dataOverviewCommands,
  numberOfRowsPerPage,
  isSearchActive,
  isLoading,
  selectedItems,
}: WorkspacesTableProps) => {
  if (!isLoading && isSearchActive && datasource.length === 0) {
    return (
      <Box height="full">
        <Island maxWidth="100%" bodySpacing="none" fullHeight>
          <FlexBox justify="center" align="center" height="full" flexGrow={1}>
            <DoqWithSpeechBubble
              layout={DoqLayout.DIALOG}
              doqType={DoqType.MISSING_ORG}
              message="There are no workspaces that match your search. Try searching for a different name."
              title="No workspaces found"
            />
          </FlexBox>
        </Island>
      </Box>
    );
  }

  if (!isLoading && datasource.length === 0) {
    return (
      <Box height="full">
        <Island maxWidth="100%" bodySpacing="none" fullHeight>
          <FlexBox justify="center" align="center" height="full" flexGrow={1}>
            <DoqWithSpeechBubble
              layout={DoqLayout.DIALOG}
              doqType={DoqType.EMPTY_BOX}
              message="There are no workspaces."
              title="No workspaces found"
            />
          </FlexBox>
        </Island>
      </Box>
    );
  }

  return (
    <PaginationController
      defaultSortOrder={SortOrder.ASC}
      defaultSortById="name"
      dataSource={datasource}
      perPage={numberOfRowsPerPage}
      render={({
        currentPageDataSource,
        onPageSelect,
        currentPageNumber,
        totalResults,
      }) => (
        <Island
          preventScroll
          maxWidth="100%"
          bodySpacing="none"
          footerContent={
            <PaginationBar
              pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
              currentPageNumber={currentPageNumber}
              perPage={numberOfRowsPerPage}
              totalResults={totalResults}
              onChange={({
                currentPageNumber: newPageNumber,
                perPage: newPerPage,
              }) => {
                dataOverviewCommands.selectNumberOfRowsPerPage(newPerPage);
                onPageSelect(newPageNumber);
              }}
            />
          }
        >
          <DatasourceTable
            fixedHeader
            scrollableSectionHeight="100%"
            loading={isLoading}
            dataSource={currentPageDataSource}
            components={newTableTheme}
            columns={getColumns(locale, dataOverviewCommands, selectedItems)}
          />
        </Island>
      )}
    />
  );
};

export default WorkspacesTable;
