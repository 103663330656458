import { getCDNLink } from 'appConfig';
import { MetaInfo, MetaInfoViewStatus } from 'streams/views/mainContent/types';
import { ViewIds } from '@ardoq/api-types';

export default class TabView {
  id: ViewIds;
  name?: string;
  description?: string;
  exampleImage?: string;
  active: boolean;
  viewStatus: MetaInfoViewStatus;
  constructor({ name, id, exampleImage, description, viewStatus }: MetaInfo) {
    this.name = name;
    this.id = id;
    this.description = description;
    this.exampleImage = exampleImage;
    this.active = false;
    this.viewStatus = viewStatus ?? MetaInfoViewStatus.NONE;
  }

  getDescription() {
    return this.description || '';
  }

  getExampleImageLink() {
    return this.exampleImage ? getCDNLink(this.exampleImage) : null;
  }
}
