import { trackEvent } from 'tracking/tracking';

export enum BookmarksTrackingEventsNames {
  CLICKED_SAVE_AS_BOOKMARK = 'Bookmarks panel0 Save as bookmark clicked',
}

const trackClickedSaveAsBookmark = (hasSelectedFolder: boolean) => {
  trackEvent(BookmarksTrackingEventsNames.CLICKED_SAVE_AS_BOOKMARK, {
    hasSelectedFolder,
  });
};

export default {
  trackClickedSaveAsBookmark,
};
