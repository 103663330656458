import { RegionsState } from 'integrations/cloudProviders/streams/regions/types';
import { ResourceTypesState } from 'integrations/cloudProviders/streams/resourceTypes/types';
import { ImportRoute } from 'integrations/common/navigation/types';
import { TabularMapping } from 'integrations/common/streams/tabularMappings/types';
import { TransferState } from 'integrations/common/streams/transferState/types';
import {
  isDryRunOutOfDate,
  isSuccessTransfer,
} from 'integrations/common/streams/transferState/utils';
import { SelectionState } from './streams/selectionState/types';
import {
  areFetchedResourcesInSync,
  isValidSelectionState,
} from './streams/selectionState/utils';
import { Maybe } from '@ardoq/common-helpers';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';
import { StepState } from '@ardoq/steppers';
import { VpcRecord, VpcState } from './streams/vpcs/types';
import { CloudProviderConfig } from '@ardoq/api-types/integrations';
import { AsyncStatus } from 'integrations/common/types/api';
import { flatMap, values } from 'lodash/fp';

type Props = {
  tabularMapping: TabularMapping;
  transferState: TransferState;
  selectionState: SelectionState;
  resourceTypesState: ResourceTypesState;
  regionsState: RegionsState;
};

const isConfigureStepAvailable = ({
  selectionState,
  resourceTypesState,
  regionsState,
}: Props) => {
  return areFetchedResourcesInSync(
    selectionState,
    resourceTypesState,
    regionsState
  );
};

const isReviewStepAvailable = (props: Props) => {
  return (
    isConfigureStepAvailable(props) &&
    !isDryRunOutOfDate(props.transferState, props.tabularMapping)
  );
};

const isTransferStepAvailable = (props: Props) => {
  return isReviewStepAvailable(props) && isSuccessTransfer(props.transferState);
};

const STEP_AVAILABILITY: Record<ImportRoute, (props: Props) => boolean> = {
  [ImportRoute.SELECT_DATA]: () => true,
  [ImportRoute.CONFIGURE]: isConfigureStepAvailable,
  [ImportRoute.REVIEW]: isReviewStepAvailable,
  [ImportRoute.IMPORT_AND_SCHEDULE]: isTransferStepAvailable,
};

export const isStepAvailable = (path: ImportRoute, props: Props): boolean => {
  return STEP_AVAILABILITY[path](props);
};

export const getSelectDataStepState = ({
  connectionsState,
  regionsState,
  resourceTypesState,
}: {
  integrationPath: Maybe<string>;
  connectionsState: IntegrationConnectionsState;
  regionsState: RegionsState;
  resourceTypesState: ResourceTypesState;
}) => {
  if (
    isValidSelectionState({
      connectionsState,
      regionsState,
      resourceTypesState,
    })
  ) {
    return StepState.DONE;
  }

  return StepState.ACTIVE;
};

export const createProviderParams = ({
  resourceTypes,
  regions,
  vpcs,
}: {
  resourceTypes: string[];
  regions: string[];
  vpcs: string[];
}): CloudProviderConfig['providerParams'] => {
  return {
    resourceTypes,
    regions,
    // Backend currently expects vpcs for the providerParams
    vpcs,
  };
};

export const getLoadingMessage = ({
  selectionState,
  transferState,
  regionState,
  vpcsState,
  connectionsState,
}: {
  selectionState: SelectionState;
  transferState: { asyncStatus: AsyncStatus };
  regionState: RegionsState;
  vpcsState: VpcState;
  connectionsState: { asyncStatus: AsyncStatus };
}) => {
  if (transferState.asyncStatus === 'LOADING') {
    return 'Reviewing the import...';
  }

  if (selectionState.asyncStatus === 'LOADING') {
    return 'Fetching selected data...';
  }

  if (regionState.asyncStatus === 'LOADING') {
    return 'Fetching regions...';
  }

  const vpcs: VpcRecord[] = flatMap(values, values(vpcsState.connectionsVpcs));

  if (vpcs.some(({ asyncStatus }) => asyncStatus === 'LOADING')) {
    return 'Fetching vpcs...';
  }

  if (connectionsState.asyncStatus === 'LOADING') {
    return 'Fetching connections...';
  }

  return 'Loading';
};

export const getSourceConfig = ({
  accountIds,
  resourceTypes,
  regions,
  vpcs,
}: {
  accountIds: string[];
  resourceTypes: string[];
  regions: string[];
  vpcs: string[];
}) =>
  accountIds.length
    ? {
        accountIds,
        providerParams: createProviderParams({
          resourceTypes,
          regions,
          vpcs,
        }),
      }
    : null;
