import {
  ComponentType,
  ExistingField,
} from 'integrations/ITPedia/streams/types';
import { FieldsGroup } from 'integrations/ITPedia/streams/fields/fields$';
import { filterCalculatedFields, mapFieldsToSelectOptions } from '../utils';
import React from 'react';
import { NewBadge } from '@ardoq/status-ui';
import { Field } from '@ardoq/api-types/integrations';
import { ChangedField } from './types';
import { updateFields } from 'integrations/ITPedia/streams/configurationState/actions';

const createFieldsGroup = (label: string, fields: ExistingField[]) => ({
  label,
  options: mapFieldsToSelectOptions(filterCalculatedFields(fields)),
});

export function getProductIdOptions(
  globalFields: FieldsGroup,
  workspaceFields: FieldsGroup
) {
  return [
    createFieldsGroup('Workspace fields', [
      ...workspaceFields.text,
      ...workspaceFields.list,
    ]),
    createFieldsGroup('Fields from all workspaces', [
      ...globalFields.text,
      ...globalFields.list,
    ]),
  ];
}

export function getProductUrlOptions(
  globalFields: FieldsGroup,
  workspaceFields: FieldsGroup
) {
  return [
    createFieldsGroup('Workspace fields', workspaceFields.url),
    createFieldsGroup('Fields from all workspaces', globalFields.url),
  ];
}

export function getDateRangeFields(
  globalFields: FieldsGroup,
  workspaceFields: FieldsGroup,
  alreadySelectedFields: Field[]
) {
  return [
    createFieldsGroup('Workspace fields', [
      ...workspaceFields.dateRange.filter(
        field => !alreadySelectedFields.some(f => f.name === field.name)
      ),
    ]),
    createFieldsGroup('Fields from all workspaces', [
      ...globalFields.dateRange.filter(
        field => !alreadySelectedFields.some(f => f.name === field.name)
      ),
    ]),
  ];
}

export function getSelectedOption(
  componentType: ComponentType,
  fieldName: keyof ComponentType['fields']
) {
  if (!componentType || !fieldName || !componentType.fields[fieldName].label) {
    return;
  }
  const isNew = !componentType.fields[fieldName].name;

  return {
    label: componentType.fields[fieldName].label,
    value: componentType.fields[fieldName].name,
    rightContent: isNew ? React.createElement(NewBadge) : null,
  };
}

export function getUpdatedField(
  fields: {
    globalFields: FieldsGroup;
    workspaceFields: FieldsGroup;
  },
  field: ChangedField,
  componentType: string,
  fieldName: keyof ComponentType['fields']
): Parameters<typeof updateFields>[0] {
  if (typeof field === 'string') {
    const existingField = fields.globalFields.allFields.find(f => {
      return (
        f.label.replace(/\.StartDate$|\.EndDate$/, '').toLocaleLowerCase() ===
        field.toLocaleLowerCase()
      );
    });
    if (existingField) {
      return {
        componentType,
        fields: {
          [fieldName]: {
            name: null,
            value: '',
            errorMessage: `Field with label '${field}' already exists for another field type`,
          },
        },
      };
    }
    return {
      componentType,
      fields: {
        [fieldName]: {
          name: null,
          label: field,
        },
      },
    };
  }
  return {
    componentType,
    fields: {
      [fieldName]: {
        name: field.value,
        label: field.label,
      },
    },
  };
}

export const SELECT_PLACEHOLDER = 'Select or start typing to create';
