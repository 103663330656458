import { getColumnTypeName } from 'integrations/common/pages/tabularConfigMapping/utils';
import {
  ComponentsColumnType,
  ReferencesColumnType,
  OptionValue,
} from './types';
import { Field } from 'integrations/common/streams/fields/types';
import { TableMappingType } from '@ardoq/api-types/integrations';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { Tag } from '@ardoq/status-ui';
import { isComponentMapping } from 'integrations/common/streams/tabularMappings/utils';

/** Ordered options for the dropdown */
const componentsColumnTypes: OptionValue<ComponentsColumnType>[] = (
  [
    'component',
    'custom-id',
    'ardoq-oid',
    'component-type',
    'field',
    'description',
    'tags',
    'parent',
    'reference',
  ] satisfies ComponentsColumnType[]
).map(type => ({ value: type, label: getColumnTypeName(type) }));

const referencesColumnTypes: OptionValue<ReferencesColumnType>[] = (
  [
    'root-reference',
    'target-reference',
    'custom-id',
    'ardoq-oid',
    'reference-type',
    'field',
    'description',
    'display-text',
    'tags',
  ] satisfies ReferencesColumnType[]
).map(type => ({ value: type, label: getColumnTypeName(type) }));

const withoutFieldColumnType = <T extends string>(
  columnTypes: OptionValue<T>[]
): OptionValue<T>[] => columnTypes.filter(({ value }) => value !== 'field');

const availableComponentsColumnTypes = (
  allFields: Field[],
  isCreationDisabled: boolean,
  isTypeSelectionDisabled: boolean
): OptionValue<ComponentsColumnType>[] => {
  if (isCreationDisabled && allFields.length === 0) {
    return withoutFieldColumnType(componentsColumnTypes);
  }
  if (isTypeSelectionDisabled) {
    return componentsColumnTypes.filter(
      ({ value }) => value !== 'component-type'
    );
  }
  return componentsColumnTypes;
};

const availableReferencesColumnTypes = (
  allFields: Field[],
  isCreationDisabled: boolean,
  isTypeSelectionDisabled: boolean
) => {
  if (isCreationDisabled && allFields.length === 0) {
    return withoutFieldColumnType(referencesColumnTypes);
  }
  if (isTypeSelectionDisabled) {
    return referencesColumnTypes.filter(rc => rc.value !== 'reference-type');
  }
  return referencesColumnTypes;
};

export const getColumnTypeForIdentifiers = ({
  tableMapping,
}: {
  tableMapping: TableMappingMap;
}) => {
  const mappedColumns = tableMapping?.mappedColumns || {};
  for (const column in mappedColumns) {
    const columnMapping = mappedColumns[column];

    if (
      columnMapping?.columnType === 'custom-id' ||
      columnMapping?.columnType === 'ardoq-oid'
    ) {
      return columnMapping.columnType;
    }
  }
};

const getComponentTypeOptions = ({
  allOptions,
  isHardDeleteEnabled,
  usedIdentifier,
  selectedColumnMapping,
  isNewWorkspace,
}: {
  allOptions: OptionValue<ComponentsColumnType>[];
  isHardDeleteEnabled: boolean;
  usedIdentifier?: ColumnMapping['columnType'];
  selectedColumnMapping: ColumnMapping;
  isNewWorkspace: boolean;
}) => {
  const transformedOptions = allOptions
    .filter(option => !(isNewWorkspace && option.value === 'ardoq-oid'))
    .map(option => {
      if (option.value !== 'component-type') {
        return option;
      }

      return {
        ...option,
        isDisabled: isHardDeleteEnabled,
        popoverContent: isHardDeleteEnabled
          ? 'When the “Delete missing assets” is active, “Type” option is deactivated.'
          : undefined,
      };
    });
  const identifierSection = transformedOptions
    .filter(option =>
      ['ardoq-oid', 'component', 'custom-id'].includes(option.value)
    )
    .map(option => {
      if (
        usedIdentifier &&
        option.value !== usedIdentifier &&
        option.value !== 'component'
      ) {
        return {
          ...option,
          isDisabled: true,
        };
      }

      if (option.value === usedIdentifier) {
        return {
          ...option,
          rightContent: <Tag label="Configured" statusType={1} />,
        };
      }

      if (
        option.value === 'component' &&
        isComponentMapping(selectedColumnMapping)
      ) {
        return {
          ...option,
          description: `Level ${selectedColumnMapping.hierarchyLevel + 1}`,
          rightContent: isNewWorkspace && (
            <Tag label="Required" statusType={1} />
          ),
        };
      }
      return option;
    });

  const fieldsSection = transformedOptions.filter(
    option =>
      option.value === 'field' ||
      option.value === 'description' ||
      option.value === 'tags'
  );

  const relationsSection = transformedOptions.filter(
    option => option.value === 'parent' || option.value === 'reference'
  );

  return [
    {
      label: 'Identifier',
      options: identifierSection,
      ...(isNewWorkspace
        ? {}
        : {
            description:
              'Component must have at least one identifier configured.',
          }),
    },
    {
      options: transformedOptions.filter(
        option => option.value === 'component-type'
      ),
    },
    {
      label: 'Field',
      options: fieldsSection,
    },
    {
      label: 'Relations',
      options: relationsSection,
    },
  ];
};

const getReferenceTypeOptions = ({
  allOptions,
  isHardDeleteEnabled,
  isNewWorkspace,
}: {
  allOptions: OptionValue<ReferencesColumnType>[];
  isHardDeleteEnabled: boolean;
  isNewWorkspace: boolean;
}) => {
  const transformedOptions = allOptions
    .filter(option => !(isNewWorkspace && option.value === 'ardoq-oid'))
    .map(option => {
      if (option.value !== 'reference-type') {
        return option;
      }

      return {
        ...option,
        isDisabled: isHardDeleteEnabled,
        popoverContent: isHardDeleteEnabled
          ? 'When the “Delete missing assets” is active, “Type” option is deactivated.'
          : undefined,
      };
    });

  const identifierSection = transformedOptions
    .filter(option =>
      ['ardoq-oid', 'root-reference', 'target-reference', 'custom-id'].includes(
        option.value
      )
    )
    .map(option => {
      if (
        option.value === 'root-reference' ||
        option.value === 'target-reference'
      ) {
        return {
          ...option,
          rightContent: <Tag label="Required" statusType={1} />,
        };
      }
      return option;
    });

  const fieldsSection = transformedOptions.filter(
    option =>
      option.value === 'field' ||
      option.value === 'description' ||
      option.value === 'display-text' ||
      option.value === 'tags'
  );

  return [
    {
      label: 'Identifier',
      options: identifierSection,
    },
    {
      options: transformedOptions.filter(
        option => option.value === 'reference-type'
      ),
    },
    {
      label: 'Field',
      options: fieldsSection,
    },
  ];
};

export const getColumnTypeOptions = ({
  tableMappingType,
  allFields,
  isCreationDisabled,
  isHardDeleteEnabled,
  usedIdentifier,
  selectedColumnMapping,
  isNewWorkspace,
  isTypeSelectionDisabled,
}: {
  tableMappingType: TableMappingType;
  allFields: Field[];
  isCreationDisabled: boolean;
  isHardDeleteEnabled: boolean;
  usedIdentifier?: ColumnMapping['columnType'];
  selectedColumnMapping: ColumnMapping;
  isNewWorkspace: boolean;
  isTypeSelectionDisabled: boolean;
}) =>
  tableMappingType === TableMappingType.COMPONENTS
    ? getComponentTypeOptions({
        allOptions: availableComponentsColumnTypes(
          allFields,
          isCreationDisabled,
          isTypeSelectionDisabled
        ),
        isHardDeleteEnabled,
        usedIdentifier,
        selectedColumnMapping,
        isNewWorkspace,
      })
    : getReferenceTypeOptions({
        allOptions: availableReferencesColumnTypes(
          allFields,
          isCreationDisabled,
          isTypeSelectionDisabled
        ),
        isHardDeleteEnabled,
        isNewWorkspace,
      });
