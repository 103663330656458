import integrationViewState$ from 'integrations/integrationViewState$';
import { combineLatest, of, switchMap } from 'rxjs';
import { connect } from '@ardoq/rxbeach';
import { NewSync } from './NewSync/NewSync';
import { IntegrationViewState } from 'integrations/types';
import { getRouteFromString } from './utils';
import { isITPediaOverviewRoute } from './navigation/utils';
import { Overview } from 'integrations/common/containers/overview/Overview';
import { OverviewRoute } from 'integrations/common/navigation/types';

type ITPediaComponentParams = {
  activeIntegration: IntegrationViewState;
};

function ITPediaComponent({
  activeIntegration: { integrationPath },
}: ITPediaComponentParams) {
  const route = getRouteFromString(integrationPath);
  const isOverviewPage = isITPediaOverviewRoute(route);

  return (
    <>
      {isOverviewPage && (
        <Overview
          integrationPath={route}
          initialTab={OverviewRoute.CONNECTIONS}
        />
      )}
      {!isOverviewPage && <NewSync route={route} />}
    </>
  );
}

const viewModel$ = integrationViewState$.pipe(
  switchMap(integrationViewState =>
    combineLatest({
      activeIntegration: of(integrationViewState),
    })
  )
);

export const ITPedia = connect(ITPediaComponent, viewModel$);
