import { APIOrganizationUser } from '@ardoq/api-types';
import { PrimaryButton } from '@ardoq/button';
import { FlexBox } from '@ardoq/layout';
import { EmptyState, EmptyStateNoResultIllustration } from '@ardoq/status-ui';

export const NoGroups = ({
  user,
  onAddToGroupClick,
}: {
  user: APIOrganizationUser;
  onAddToGroupClick: (user: APIOrganizationUser) => void;
}) => {
  return (
    <FlexBox
      align="center"
      justify="center"
      height="full"
      width="full"
      paddingBottom="xxlarge"
      paddingTop="xxlarge"
    >
      <EmptyState
        description={`This user is not currently a member of any groups. 
  Assign groups to manage their access and permissions.`}
        title="No groups assigned"
        media={<EmptyStateNoResultIllustration />}
        layout={'horizontal'}
      >
        <PrimaryButton onClick={() => onAddToGroupClick(user)}>
          Assign group
        </PrimaryButton>
      </EmptyState>
    </FlexBox>
  );
};
