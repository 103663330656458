import { actionCreator } from '@ardoq/rxbeach';

export const showScopeRelatedNavigator = actionCreator(
  '[MainAppModuleSidebar] SHOW_SCOPE_RELATED_NAVIGATOR'
);

export const hideScopeRelatedNavigator = actionCreator(
  '[MainAppModuleSidebar] HIDE_SCOPE_RELATED_NAVIGATOR'
);

export const setIsFilterFocused = actionCreator<boolean>(
  '[MainAppModuleSidebar] SET_IS_FILTER_FOCUSED'
);

export const setFilterTerm = actionCreator<string>(
  '[MainAppModuleSidebar] SET_FILTER_TERM'
);
