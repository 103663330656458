import fp from 'lodash/fp';
import { Connection } from '@ardoq/api-types/integrations';
import { StepState } from '@ardoq/steppers';
import { Maybe } from '@ardoq/common-helpers';
import {
  areFetchedTablesInSync,
  isValidSelectionState,
} from './streams/selectionState/utils';
import { SelectionState } from './streams/selectionState/types';
import { TransferState } from 'integrations/common/streams/transferState/types';
import {
  isDryRunOutOfDate,
  isSuccessTransfer,
} from 'integrations/common/streams/transferState/utils';
import { ServiceNowTablesState } from './streams/tables/tables$';
import { TabularMapping } from 'integrations/common/streams/tabularMappings/types';
import { ExportRoute, ImportRoute } from 'integrations/common/navigation/types';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';

export function isInvalidConnection(connection: Connection) {
  const propsToValidate = fp.omit(['_id'], connection);
  return fp.some(value => fp.isEmpty(fp.trim(value ?? '')), propsToValidate);
}

export const getSelectDataStepState = ({
  selectionState,
  connectionIds,
}: {
  integrationPath: Maybe<string>;
  selectionState: SelectionState;
  connectionIds: string[];
}) => {
  if (isValidSelectionState(selectionState, connectionIds)) {
    return StepState.DONE;
  }
  return StepState.ACTIVE;
};

export const getExportFinalStepState = ({
  transferState: { requestStatus, dryRun },
}: {
  transferState: TransferState;
}): StepState => {
  if (dryRun) {
    return StepState.ACTIVE;
  }

  if (requestStatus === 'FAILURE') {
    return StepState.ERROR;
  }

  if (requestStatus === 'SUCCESS') {
    return StepState.DONE;
  }

  return StepState.ACTIVE;
};

export const isWaitingOverlayDisplayed = ({
  transferState,
  tables,
  selectionState,
  connectionsState,
}: {
  transferState: TransferState;
  tables: ServiceNowTablesState;
  selectionState: SelectionState;
  connectionsState: IntegrationConnectionsState;
}) => {
  return (
    transferState.requestStatus === 'LOADING' ||
    tables.asyncStatus === 'LOADING' ||
    selectionState.asyncStatus === 'LOADING' ||
    connectionsState.statuses.list.status === 'LOADING'
  );
};

export const getWaitingOverlayMessage = ({
  transferState,
  tables,
  selectionState,
  connectionsState,
}: {
  transferState: TransferState;
  tables: ServiceNowTablesState;
  selectionState: SelectionState;
  connectionsState: IntegrationConnectionsState;
}) => {
  if (tables.asyncStatus === 'LOADING') {
    return 'Fetching tables... almost done.';
  }

  if (selectionState.asyncStatus === 'LOADING') {
    return 'Fetching selected data...';
  }

  if (transferState.dryRun) {
    return `Reviewing the ${transferState.transferDirection}...`;
  }

  if (connectionsState.statuses.list.status === 'LOADING') {
    return 'Fetching connections...';
  }

  return "Nice work, you're already done...";
};

type Props = {
  tabularMapping: TabularMapping;
  transferState: TransferState;
  selectionState: SelectionState;
};

const isConfigureStepAvailable = ({ selectionState }: Props) => {
  return areFetchedTablesInSync(selectionState);
};

const isReviewStepAvailable = (props: Props) => {
  return (
    isConfigureStepAvailable(props) &&
    !isDryRunOutOfDate(props.transferState, props.tabularMapping)
  );
};

const isTransferStepAvailable = (props: Props) => {
  return isReviewStepAvailable(props) && isSuccessTransfer(props.transferState);
};

const STEP_AVAILABILITY: Record<
  ImportRoute | ExportRoute,
  (props: Props) => boolean
> = {
  [ImportRoute.SELECT_DATA]: () => true,
  [ExportRoute.SELECT_DATA]: () => true,
  [ImportRoute.CONFIGURE]: isConfigureStepAvailable,
  [ExportRoute.CONFIGURE]: isConfigureStepAvailable,
  [ImportRoute.REVIEW]: isReviewStepAvailable,
  [ExportRoute.REVIEW]: isReviewStepAvailable,
  [ImportRoute.IMPORT_AND_SCHEDULE]: isTransferStepAvailable,
  [ExportRoute.EXPORT]: isTransferStepAvailable,
};

export const isStepAvailable = (
  path: ImportRoute | ExportRoute,
  props: Props
): boolean => {
  return STEP_AVAILABILITY[path](props);
};

export const getSourceConfig = (
  selectionState: SelectionState,
  connectionsState: IntegrationConnectionsState
) => {
  const tables = fp.flow(
    fp.toPairs,
    fp.filter(([_, table]) => !fp.isEmpty(table.fields)),
    fp.map(([tableId, { fields, queryFilter }]) => ({
      name: tableId,
      sysparmQuery: queryFilter,
      fields: fp.flow(
        fp.toPairs,
        fp.map(([fieldId]) => ({ name: fieldId }))
      )(fields),
    }))
  )(selectionState.tables || []);
  return connectionsState.selectedConnectionIds.length
    ? {
        connectionId: connectionsState.selectedConnectionIds[0],
        tables,
      }
    : null;
};

const DEFAULT_FETCH_ERROR_MESSAGE =
  'An error occurred when fetching tables. Please try again and  contact support if the issue persists.';

export const getFetchTablesError = (
  selectionState: SelectionState,
  connectionsState: IntegrationConnectionsState<Connection>
): Maybe<string> => {
  if (selectionState.asyncStatus === 'FAILURE') {
    return selectionState.errorMessage ?? DEFAULT_FETCH_ERROR_MESSAGE;
  }
  if (connectionsState.statuses.list.status === 'FAILURE') {
    return DEFAULT_FETCH_ERROR_MESSAGE;
  }

  return null;
};
