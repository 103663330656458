import styled from 'styled-components';
import { BrandButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { TextOverflow } from '@ardoq/popovers';
import { trackEvent } from 'tracking/tracking';
import Navbar from 'views/navbar/Navbar';
import { connect } from '@ardoq/rxbeach';
import { SwitchOrganizationMenu, TopHeaderType } from '@ardoq/sidebar-menu';
import { Tag, StatusType } from '@ardoq/status-ui';
import { logout } from 'components/AppMainSidebar/utils';
import MainToolbar from 'menus/topbar/MainToolbar';
import { SaveBar } from 'menus/topbar/SaveBar';
import { topbarStream$ } from 'menus/topbar/topbar$';
import { APICurrentUser } from '@ardoq/api-types';
import { OpenViewpointBuilderCommands } from '../../viewpointBuilder/openViewpointBuilderCommands';
import { FlexBox } from '@ardoq/layout';

const ExploreDataButton = styled(BrandButton)`
  width: max-content;
`;

const HomeNavbarToolbarContentContainer = styled(FlexBox).attrs({
  gap: 'medium',
  width: 'full',
})`
  min-width: 400px;
`;

const setOrganization = (label: string) => {
  trackEvent('Topbar: clicked to switch organization');
  document.location.href = `/api/switchOrg?org=${label}`;
};

type HomeNavbarProps = {
  daysRemaining: number;
  isTrial: boolean;
  isScenarioMode: boolean;
  syncProgress: number;
  currentUser: APICurrentUser;
  commands: OpenViewpointBuilderCommands;
};

const HomeNavbar = ({
  daysRemaining,
  isTrial,
  isScenarioMode,
  syncProgress,
  currentUser,
  commands,
}: HomeNavbarProps) => {
  const showSyncBar = syncProgress >= 0 && syncProgress < 1;
  return (
    <Navbar
      primaryContent={
        <TextOverflow lineClamp={1}>{currentUser.name}</TextOverflow>
      }
      primaryButton={
        <ExploreDataButton
          onClick={() => {
            trackEvent('Topbar: clicked explore data');
            commands.openViewpointBuilderToExploreDataAndCloseLoadedStates();
          }}
          data-intercom-target="explore-button"
          dataTestId="explore-button"
        >
          <Icon iconName={IconName.NAVIGATE} />
          Explore data
        </ExploreDataButton>
      }
      secondaryContent="Welcome"
      isTrial={isTrial}
      daysRemaining={daysRemaining}
      toolbarContent={
        <HomeNavbarToolbarContentContainer>
          {showSyncBar && <SaveBar progress={syncProgress} />}
          <MainToolbar shouldUseNewJourneyVersion={true} />
          <SwitchOrganizationMenu
            currentUser={currentUser}
            setOrganization={setOrganization}
            logout={logout}
            topHeaderType={TopHeaderType.LIGHT}
          />
          {isScenarioMode && (
            <Tag statusType={StatusType.SCENARIO}>Scenario mode</Tag>
          )}
        </HomeNavbarToolbarContentContainer>
      }
    />
  );
};

export default connect(HomeNavbar, topbarStream$);
