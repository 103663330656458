import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { scopeDiff$ } from 'scope/scopeDiff$';
import currentViewId$ from 'streams/currentViewId$';
import { ViewIds } from '@ardoq/api-types';
import { scenarioRelatedSelection$ } from '../navigator/scenarioRelatedSelection$';
import { mainAppModuleSidebar$ } from '../../mainAppModuleSidebar$';
import { scenarioIsInSync$ } from 'scenarioIsInSync$';

const VIEWS_SUPPORTING_DIFF_MODE = [ViewIds.BLOCK_DIAGRAM];

export type ViewProps = {
  isAnyRelatedComponentSelected: boolean;
  isCurrentViewSupportingDiffMode: boolean;
  isCurrentScenarioInSync: boolean;
  showScopeRelated: boolean;
  disabled: boolean;
  isDiffModeActive: boolean;
};

const view$ = combineLatest([
  scopeDiff$,
  scenarioIsInSync$,
  currentViewId$,
  scenarioRelatedSelection$,
  mainAppModuleSidebar$,
]).pipe(
  map(
    ([
      currentScopeDiff,
      { isInSync: isCurrentScenarioInSync },
      currentViewId,
      scenarioRelatedSelection,
      { showScopeRelated },
    ]) => {
      const isDiffModeActive = Boolean(currentScopeDiff.scopeDiff);
      const isAnyRelatedComponentSelected = Boolean(
        scenarioRelatedSelection.current
      );
      const isCurrentViewSupportingDiffMode = Boolean(
        currentViewId && VIEWS_SUPPORTING_DIFF_MODE.includes(currentViewId)
      );

      return {
        isCurrentViewSupportingDiffMode,
        isAnyRelatedComponentSelected,
        isCurrentScenarioInSync,
        showScopeRelated,
        isDiffModeActive,
        disabled:
          isDiffModeActive ||
          !isCurrentScenarioInSync ||
          !isAnyRelatedComponentSelected ||
          !isCurrentViewSupportingDiffMode,
      };
    }
  )
);

export default view$;
