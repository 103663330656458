import { actionCreator } from '@ardoq/rxbeach';
import { IntegrationResources, Query, Resource } from './types';
import { WithUnifiedIntegrationId } from 'integrations/unified/types';

const NAMESPACE = '[integrations unified] RESOURCES';

export const resetIntegration = actionCreator<WithUnifiedIntegrationId>(
  `${NAMESPACE}_RESET_INTEGRATION`
);

export const resetAllResourceFields = actionCreator<WithUnifiedIntegrationId>(
  `${NAMESPACE}_RESET_ALL_RESOURCE_FIELDS`
);

// get resources

export const getResources = actionCreator<WithUnifiedIntegrationId>(
  `${NAMESPACE}_GET_RESOURCES`
);

export const ensureResources = actionCreator<WithUnifiedIntegrationId>(
  `${NAMESPACE}_ENSURE_RESOURCES`
);

export type GetResourcesSuccessPayload = WithUnifiedIntegrationId & {
  resources: Resource[];
};

export const getResourcesSuccess = actionCreator<GetResourcesSuccessPayload>(
  `${NAMESPACE}_GET_RESOURCES_SUCCESS`
);

export type GetResourcesFailurePayload = WithUnifiedIntegrationId & {
  message: string;
};

export const getResourcesFailure = actionCreator<GetResourcesFailurePayload>(
  `${NAMESPACE}_GET_RESOURCES_FAILURE`
);

// get resource

export type GetResourcePayload = WithUnifiedIntegrationId & {
  resourceId: Resource['id'];
};

export const getResource = actionCreator<GetResourcePayload>(
  `${NAMESPACE}_GET_RESOURCE`
);

export const ensureResource = actionCreator<GetResourcePayload>(
  `${NAMESPACE}_ENSURE_RESOURCE`
);

export type GetResourceSuccessPayload = GetResourcePayload & {
  resource: Resource;
};

export const getResourceSuccess = actionCreator<GetResourceSuccessPayload>(
  `${NAMESPACE}_GET_RESOURCE_SUCCESS`
);

export type GetResourceFailurePayload = WithUnifiedIntegrationId & {
  resourceId: Resource['id'];
  message: string;
};

export const getResourceFailure = actionCreator<GetResourceFailurePayload>(
  `${NAMESPACE}_GET_RESOURCE_FAILURE`
);

// selection

export type SetSelectedResourcesPayload = WithUnifiedIntegrationId & {
  resources: IntegrationResources['selectedResources'];
};

export const setSelectedResources = actionCreator<SetSelectedResourcesPayload>(
  `${NAMESPACE}_SET_SELECTED_RESOURCES`
);

export const resetSelectedResources = actionCreator<WithUnifiedIntegrationId>(
  `${NAMESPACE}_RESET_SELECTED_RESOURCES`
);

export const setUnavailableResources =
  actionCreator<SetSelectedResourcesPayload>(
    `${NAMESPACE}_SET_UNAVAILABLE_RESOURCES`
  );

export const resetUnavailableResources =
  actionCreator<WithUnifiedIntegrationId>(
    `${NAMESPACE}_RESET_UNAVAILABLE_RESOURCES`
  );

export type SelectResourceFieldPayload = WithUnifiedIntegrationId & {
  resourceId: Resource['id'];
  id: string;
};

export const selectResourceField = actionCreator<SelectResourceFieldPayload>(
  `${NAMESPACE}_SELECT_RESOURCE_FIELD`
);

export const unselectResourceField = actionCreator<SelectResourceFieldPayload>(
  `${NAMESPACE}_UNSELECT_RESOURCE_FIELD`
);

export type SelectResourceFieldsPayload = WithUnifiedIntegrationId & {
  resourceId: Resource['id'];
  ids: string[];
};

export const selectResourceFields = actionCreator<SelectResourceFieldsPayload>(
  `${NAMESPACE}_SELECT_ALL_RESOURCE_FIELDS`
);

export type UnselectResourceFieldsPayload = WithUnifiedIntegrationId & {
  resourceId: Resource['id'];
};

export const unselectResourceFields =
  actionCreator<UnselectResourceFieldsPayload>(
    `${NAMESPACE}_UNSELECT_RESOURCE_FIELDS`
  );

// filter

export type SetResourceQueryPayload = WithUnifiedIntegrationId & {
  resourceId: Resource['id'];
  query: Query;
};

export const setResourceQuery = actionCreator<SetResourceQueryPayload>(
  `${NAMESPACE}_SET_RESOURCE_QUERY`
);

export type ResetResourceQueryPayload = WithUnifiedIntegrationId & {
  resourceId: Resource['id'];
};

export const resetResourceQuery = actionCreator<ResetResourceQueryPayload>(
  `${NAMESPACE}_RESET_RESOURCE_QUERY`
);

// focused resource

export type SetFocusedResourcePayload = WithUnifiedIntegrationId & {
  resourceId: Resource['id'];
};

export const setFocusedResource = actionCreator<SetFocusedResourcePayload>(
  `${NAMESPACE}_SET_FOCUSED_RESOURCE`
);

export type ResourceSelectionPayload = WithUnifiedIntegrationId & {
  resourceId: Resource['id'];
};

export const selectResource = actionCreator<ResourceSelectionPayload>(
  `${NAMESPACE}_SELECT_RESOURCE`
);

export const unselectResource = actionCreator<ResourceSelectionPayload>(
  `${NAMESPACE}_UNSELECT_RESOURCE`
);
