import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { combineLatest, switchMap, tap } from 'rxjs';
import { overlay$ } from './streams/overlay$';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { configState$ } from './streams/configurationState/configurationState$';
import { isPathReady } from './navigation/utils';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { OverviewRoute } from 'integrations/common/navigation/types';

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      activeIntegration: getActiveIntegrationStream(integrationId),
      loading: overlay$,
      config: configState$,
    });
  }),
  tap(({ activeIntegration, config }) => {
    if (!isPathReady(activeIntegration.integrationPath, config)) {
      dispatchAction(
        navigateToPath({
          path: OverviewRoute.SCHEDULES,
          integrationId: 'signavio-exporter',
        })
      );
    }
  })
);

export default viewModel$;
