import { Select, SelectOption } from '@ardoq/select';
import { Field, FormSize } from '@ardoq/forms';
import ExpandableDescriptionAndBusinessOutcomes from './ExpandableDescriptionAndBusinessOutcomes';
import UseCaseLandingPageIsland from './UseCaseLandingPageIsland';
import { SelectOptionWithContextData } from 'useCases/types';
import { AddedTag, UpdateTag } from 'useCases/atoms';
import { Features, hasFeature } from '@ardoq/features';
import StartUseCaseButton from './StartUseCaseButton';
import Navbar from '../../views/navbar/Navbar';
import MainToolbar from '../../menus/topbar/MainToolbar';
import { s24 } from '@ardoq/design-tokens';
import { ParseAPIErrorNotification } from '@ardoq/status-ui';
import { DASHBOARD_MAX_WIDTH } from '@ardoq/dashboard';
import { UseCaseLandingPageProps, isLandingPageErrorProps } from './types';
import viewModel$ from './viewModel$';
import { connect } from '@ardoq/rxbeach';
import { ErrorMessage } from '@ardoq/error-boundary';
import { UseCaseStatus } from '@ardoq/api-types';
import { KnowledgeBaseIcon } from '@ardoq/icons';
import { SmallGhostButton } from '@ardoq/button';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { PageHeader, PageBody, PageWrapper } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';
import Augmentations from './augmentations/Augmentations';

const getUseCaseOptionsRightContent = (
  updateAvailable: boolean,
  status: UseCaseStatus
) => {
  if (updateAvailable) return <UpdateTag />;
  if (status === UseCaseStatus.IN_PROGRESS) return <AddedTag />;
  return null;
};

const toSelectOptionsWithTag = (
  options: SelectOptionWithContextData[]
): SelectOption<string>[] =>
  options.map(({ updateAvailable, status, ...option }) => ({
    ...option,
    rightContent: getUseCaseOptionsRightContent(updateAvailable, status),
  }));

export const UseCaseLandingPage = (state: UseCaseLandingPageProps) => {
  if (isLandingPageErrorProps(state)) {
    return <ErrorMessage />;
  }

  const {
    selectedUseCase,
    showAugmentations,
    augmentationEnablement,
    selectUseCase,
    useCaseSelectOptions,
    isLoadingUseCase,
    loadUseCase,
    loadUseCaseErrorProps,
    isOrgAdmin,
    islandProps,
    descriptionAndBusinessOutcomesProps,
  } = state;

  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);
  const hasRestrictDownloadFeature = hasFeature(
    Features.RESTRICT_USE_CASE_DOWNLOAD
  );
  const showStartUseCaseButton = !hasRestrictDownloadFeature;

  const selectOptions = toSelectOptionsWithTag(useCaseSelectOptions);

  return (
    <PageWrapper>
      {hasNewJourneyFeature ? (
        <Navbar
          primaryContent={
            <FlexBox gap="xsmall">
              <Field formSize={FormSize.DEFAULT}>
                {/* Using field here to get the correct size for the Select. It's rendering too narrow without the Field wrapper */}
                <Select
                  value={selectedUseCase?._id}
                  options={selectOptions}
                  onValueChange={selectUseCase}
                />
              </Field>
              <SmallGhostButton
                onClick={() =>
                  window.open(KnowledgeBaseLink.SOLUTIONS, '_blank')
                }
              >
                <KnowledgeBaseIcon data-tooltip-text="Knowledge base" />
              </SmallGhostButton>
            </FlexBox>
          }
          toolbarContent={<MainToolbar shouldUseNewJourneyVersion />}
          primaryButton={
            showStartUseCaseButton && (
              <StartUseCaseButton
                isLoadingUseCase={isLoadingUseCase}
                loadUseCase={loadUseCase}
                hasNewJourneyFeature={hasNewJourneyFeature}
                isOrgAdmin={isOrgAdmin}
                selectedUseCase={selectedUseCase}
              />
            )
          }
          secondaryContent="Ardoq Solutions"
        />
      ) : (
        <PageHeader
          title="Ardoq Solutions"
          leftContent={
            <Field formSize={FormSize.DEFAULT}>
              {/* Using field here to get the correct size for the Select. It's rendering too narrow without the Field wrapper */}
              <Select
                value={selectedUseCase?._id}
                options={selectOptions}
                onValueChange={selectUseCase}
              />
            </Field>
          }
          rightContent={
            showStartUseCaseButton && (
              <StartUseCaseButton
                isLoadingUseCase={isLoadingUseCase}
                loadUseCase={loadUseCase}
                hasNewJourneyFeature={hasNewJourneyFeature}
                isOrgAdmin={isOrgAdmin}
                selectedUseCase={selectedUseCase}
              />
            )
          }
        />
      )}
      <PageBody
        backgroundColor={hasNewJourneyFeature ? 'surfaceDefault' : undefined}
        padding={hasNewJourneyFeature ? s24 : undefined}
      >
        {loadUseCaseErrorProps && (
          <ParseAPIErrorNotification
            maxWidth={`${DASHBOARD_MAX_WIDTH}px`}
            error={loadUseCaseErrorProps.error}
          />
        )}
        {selectedUseCase && (
          <>
            <ExpandableDescriptionAndBusinessOutcomes
              {...descriptionAndBusinessOutcomesProps}
            />
            {showAugmentations && (
              <Augmentations enabledState={augmentationEnablement} />
            )}
            <UseCaseLandingPageIsland {...islandProps} />
          </>
        )}
      </PageBody>
    </PageWrapper>
  );
};

export default connect(UseCaseLandingPage, viewModel$);
