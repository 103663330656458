import { perspectiveInterface } from 'modelInterface/perspectives/perspectiveInterface';

import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  notifyPerspectivesDestroyed,
  setActivePerspective,
} from 'streams/filters/FilterActions';
import { action$, ofType } from '@ardoq/rxbeach';
import { combineLatest } from 'rxjs';
import { context$ } from 'streams/context/context$';
import {
  editorOpened,
  NotifySavedPerspectivesOptionsChangedPayload,
} from 'perspective/actions';
import { getFormattedSavedPerspectives } from './utils';
import { ArdoqId } from '@ardoq/api-types';
import {
  SelectedSavedPerspectiveOptions,
  StoredPerspectivesOption,
} from '@ardoq/perspectives';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { toGroupingOptionsIncludingSavedPerspectiveEntriesFromClosedWorkspaces } from './perspectiveEditorGroupingOptions$';
import { getFormattingOptions } from './perspectiveEditorFormattingOptions$';
import { mapSavedPerspectiveToPerspectiveEditorSavedPerspectiveState } from './mapSavedPerspectiveToPerspectiveEditorSavedPerspectiveState';

const savedPerspectiveEditorOptions$ = action$.pipe(
  ofType(editorOpened, setActivePerspective, notifyPerspectivesDestroyed),
  map((): StoredPerspectivesOption[] => {
    const savedPerspectives = perspectiveInterface.getAll();
    const savedPerspectivesPermissions =
      perspectiveInterface.getSavedPerspectivesPermissions();
    return getFormattedSavedPerspectives({
      savedPerspectives,
      savedPerspectivesPermissions,
    });
  })
);

export const savedPerspectiveOptions$ = combineLatest([
  savedPerspectiveEditorOptions$,
  context$,
]).pipe(
  map(
    ([
      savedPerspectiveOptions,
      { workspaceId },
    ]): NotifySavedPerspectivesOptionsChangedPayload => ({
      savedPerspectives: savedPerspectiveOptions,
      isOnlyReaderRole: !workspaceInterface.hasWriteAccess(workspaceId),
    })
  )
);

/**
 * We can load a saved perspective that has some applied changes.
 * (e.g. user loads a saved perspective, then applies some changes
 * and closes Perspective Editor, then he/she comes back to save his/her changes).
 *
 * In that case, we need both:
 * - current formatting, filtering and grouping rules (provided by formatting and grouping options)
 * - formatting, filtering and grouping rules saved in saved perspective (savedPerspective)
 *
 * in order to:
 * - display currently applied rules (saved perspective + changes),
 * - run change detection between saved perspective and current state of Perspective editor.
 *
 */
export const getCurrentlyLoadedSavedPerspectiveAndLoadMissingData = async (
  workspacesIds: ArdoqId[]
): Promise<SelectedSavedPerspectiveOptions | null> => {
  const savedPerspectiveId =
    perspectiveInterface.getSelectedSavedPerspectiveId();
  const savedPerspective = perspectiveInterface.getById(savedPerspectiveId);

  if (!savedPerspective) {
    return null;
  }

  const formattingOptions = getFormattingOptions(workspacesIds);
  const groupsOptions =
    await toGroupingOptionsIncludingSavedPerspectiveEntriesFromClosedWorkspaces(
      workspacesIds
    );

  return {
    savedPerspective:
      mapSavedPerspectiveToPerspectiveEditorSavedPerspectiveState(
        savedPerspective
      ),
    groupsOptions,
    formattingOptions,
  };
};

export const currentlySelectedSavedPerspectiveValues$ = action$
  .pipe(ofType(setActivePerspective, editorOpened))
  .pipe(
    withLatestFrom(context$),
    switchMap(async ([, context]) => {
      return getCurrentlyLoadedSavedPerspectiveAndLoadMissingData(
        context.workspacesIds
      );
    })
  );
