import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId } from '@ardoq/api-types';

export const openGlobalFieldManager = actionCreator(
  '[globalFieldManager] OPEN_GLOBAL_FIELD_MANAGER'
);

export const openCalculatedFieldEditor = actionCreator(
  '[globalFieldManager] OPEN_CALCULATED_FIELD_EDITOR'
);

export const deleteGlobalField = actionCreator<{
  label: string;
  ids: ArdoqId[];
}>('[globalFieldManager] DELETE_GLOBAL_FIELD');
