import { FormWrapper, Checkbox } from '@ardoq/forms';
import { Multiselect, Select } from '@ardoq/select';
import { FlexBox } from '@ardoq/layout';
import { ComponentFilter } from './ComponentFilter';
import { ConfigurationState } from 'integrations/ITPedia/streams/types';
import { debounce } from 'lodash';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  fetchQueryResults,
  updateAccountId,
  updateApplyFilters,
  updateComponentTypesKeys,
  updateComponentsQuery,
  updateProductWorkspace,
} from 'integrations/ITPedia/streams/configurationState/actions';
import { SecondaryButton } from '@ardoq/button';
import { StatusType, Tag } from '@ardoq/status-ui';
import { trackITPediaEvent } from 'integrations/ITPedia/streams/tracking/actions';
import { APIComponentType, QueryBuilderQuery } from '@ardoq/api-types';
import 'integrations/ITPedia/streams/workspaces/workspaces$';
import { WorkspaceSelector } from 'integrations/common/components/workspaceSelector/WorkspaceSelector';
import {
  Connection,
  IntegrationWorkspace,
} from '@ardoq/api-types/integrations';
import { Island, CollapsibleIsland } from '@ardoq/page-layout';
import { createQueryBuilderQuery } from './utils';
type Step1Params = {
  selectedAccount?: string;
  selectedWorkspace: IntegrationWorkspace;
  selectedComponentTypes?: ConfigurationState['componentTypes'];
  componentTypes: APIComponentType[];
  applyFilters?: boolean;
  itPediaAccounts: Connection[];
  workspaces: IntegrationWorkspace[];
};

export function Step1({
  selectedAccount,
  selectedWorkspace,
  selectedComponentTypes = {},
  componentTypes,
  applyFilters,
  itPediaAccounts,
  workspaces,
}: Step1Params) {
  return (
    <Island
      title="Select components to enrich"
      tags={<Tag>Step 1</Tag>}
      subtitle="Specify the IT-Pedia connection, subscription plan, Ardoq workspace, and component types you want to include in this sync."
      subtitleWidth="medium"
    >
      <FormWrapper>
        <Select
          value={selectedAccount}
          label="IT-Pedia connection"
          placeholder="Type and select an existing connection"
          options={itPediaAccounts.map(itp => ({
            label: itp.name,
            value: itp._id,
          }))}
          onValueChange={val =>
            val && dispatchAction(updateAccountId({ accountId: val }))
          }
        />
        <WorkspaceSelector
          title="Select a workspace"
          isCreationDisabled
          selectedWorkspace={selectedWorkspace}
          workspaces={{
            used: [],
            existing: workspaces,
          }}
          onWorkspaceSelect={ws => dispatchAction(updateProductWorkspace(ws))}
          helperText="Select a workspace with technology products."
        />
        <Multiselect
          label="Component types"
          placeholder="Type and select component types in Ardoq"
          isClearable
          value={Object.keys(selectedComponentTypes)}
          helperText="Choose the component types to sync."
          options={componentTypes.map(ct => ({
            label: ct.name,
            value: ct.name,
          }))}
          onValueChange={val => {
            if (val) {
              dispatchAction(updateComponentTypesKeys(val));
              dispatchAction(
                trackITPediaEvent({ name: 'SELECTED_COMPONENT_TYPES' })
              );
            }
          }}
        />
        <Checkbox
          isChecked={!!applyFilters}
          onChange={val => {
            dispatchAction(updateApplyFilters({ applyFilters: val }));
            dispatchAction(trackITPediaEvent({ name: 'ENABLED_FILTERS' }));
          }}
        >
          Apply filter to specify components to sync
        </Checkbox>
      </FormWrapper>
      {applyFilters &&
        Object.entries(selectedComponentTypes)?.map(
          ([componentName, componentValue]) => (
            <CollapsibleIsland
              subtitleWidth="medium"
              key={componentName}
              isDefaultExpanded
              title={
                <FlexBox gap="xsmall">
                  Component type:{componentName}
                  {componentValue.componentsQuery && (
                    <Tag statusType={StatusType.INFO}>Filters applied</Tag>
                  )}
                </FlexBox>
              }
            >
              <ComponentFilter
                query={componentValue.componentsQuery as QueryBuilderQuery}
                onQueryChange={query =>
                  debounce(
                    () =>
                      dispatchAction(
                        updateComponentsQuery({
                          componentType: componentName,
                          query: query.query,
                          isValid: query.isValid,
                        })
                      ),
                    500
                  )()
                }
              />
              <FlexBox gap="medium">
                <SecondaryButton
                  isLoading={
                    componentValue.componentsQueryResults?.status === 'Loading'
                  }
                  onClick={() => {
                    dispatchAction(
                      fetchQueryResults({
                        componentType: componentName,
                        query: createQueryBuilderQuery(
                          selectedWorkspace.id,
                          componentValue.componentsQuery
                        ),
                      })
                    );
                    dispatchAction(
                      trackITPediaEvent({ name: 'APPLIED_FILTERS' })
                    );
                  }}
                >
                  Apply rules
                </SecondaryButton>
              </FlexBox>
              {componentValue.componentsQueryResults &&
                componentValue.componentsQueryResults.status === 'Success' && (
                  <FlexBox gap="medium">
                    Compliant components:{' '}
                    {componentValue.componentsQueryResults.total}
                  </FlexBox>
                )}
              {componentValue.componentsQueryResults?.status === 'Failed' && (
                <Tag statusType={StatusType.ERROR}>
                  {componentValue.componentsQueryResults?.message ||
                    'Failed to fetch'}
                </Tag>
              )}
            </CollapsibleIsland>
          )
        )}
    </Island>
  );
}
