import { useState } from 'react';
import {
  DatasourceTable,
  ExpandableTableProvider,
  newTableTheme,
} from '@ardoq/table';
import { ExpandableTableProps } from 'broadcasts/components/expandableTable/types';
import {
  PaginationBar,
  PaginationController,
  SortBy,
  SortByFn,
} from '@ardoq/pagination';
import { DataSourceRow } from '../audienceTable/audienceByComponent/utils';
import { AudienceDataSourceItem } from '../audienceTable/audienceByPerson/utils';
import { Box } from '@ardoq/layout';

const handleSortBy = <T,>(sortId: SortBy<T>, sortBy: SortByFn<T>) => {
  if (typeof sortId !== 'string') return;
  sortBy(sortId);
};

const ExpandableTable = ({
  dataSource,
  sortId,
  sortOrder,
  getColumnConfig,
  idIndex,
  sortNumbersAfterStrings,
  showPaginationBar,
  ...props
}: ExpandableTableProps<DataSourceRow | AudienceDataSourceItem>) => {
  const [perPage, setPerPage] = useState(50);

  return (
    <PaginationController
      dataSource={dataSource}
      perPage={showPaginationBar ? perPage : Infinity}
      defaultSortById={sortId}
      defaultSortOrder={sortOrder}
      sortNumbersAfterStrings={sortNumbersAfterStrings}
      render={({
        currentPageDataSource,
        sortById,
        sortOrder: renderSortOrder,
        sortBy,
        currentPageNumber,
        onPageSelect,
      }) => (
        <>
          <ExpandableTableProvider
            dataSource={currentPageDataSource}
            idIndex={idIndex}
            render={({
              dataSource: renderDataSource,
              expandedFoldersIds: expandedRowIds,
              expandFolder: expandRow,
            }) => (
              <DatasourceTable
                dataSource={renderDataSource}
                columns={getColumnConfig(
                  expandedRowIds,
                  expandRow,
                  newSortBy => handleSortBy(newSortBy, sortBy),
                  sortById,
                  renderSortOrder
                )}
                components={newTableTheme}
                fixedHeader
                {...props}
              />
            )}
          />
          {showPaginationBar && (
            <Box paddingY="xxsmall">
              <PaginationBar
                currentPageNumber={currentPageNumber}
                perPage={perPage}
                totalResults={dataSource.length}
                onChange={({
                  currentPageNumber: newPageNumber,
                  perPage: newPerPage,
                }) => {
                  setPerPage(newPerPage);
                  onPageSelect(newPageNumber);
                }}
              />
            </Box>
          )}
        </>
      )}
    />
  );
};

export default ExpandableTable;
