import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { tap } from 'rxjs';
import { dismissBanner, setActiveTab, setIsExpanded } from './actions';
import { updateHomepageViewpointsState } from '../../streams/currentUser/actions';

const handleActiveTabChange = routine(
  ofType(setActiveTab),
  extractPayload(),
  tap(activeTab => {
    dispatchAction(updateHomepageViewpointsState({ activeTab }));
  })
);

const handleSetIsExpanded = routine(
  ofType(setIsExpanded),
  extractPayload(),
  tap(isExpanded => {
    dispatchAction(updateHomepageViewpointsState({ isExpanded }));
  })
);

const handleDismissBanner = routine(
  ofType(dismissBanner),
  tap(() => {
    dispatchAction(updateHomepageViewpointsState({ isBannerDismissed: true }));
  })
);

export const viewpointsWidgetRoutines = collectRoutines(
  handleActiveTabChange,
  handleSetIsExpanded,
  handleDismissBanner
);
