import { APIReportAttributes, ArdoqId } from '@ardoq/api-types';
import { Field, FormSize } from '@ardoq/forms';
import { LabeledValue } from 'aqTypes';
import { Select } from '@ardoq/select';
import reports$ from 'streams/reports/reports$';
import { map } from 'rxjs/operators';
import { chooseBroadcastContentId } from '../../actions';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { Tag } from '@ardoq/status-ui';
import { isValidBroadcastReportColumn } from './reportRulesSection/utils';
import { PopoverPlacement, WithPopover } from '@ardoq/popovers';
import { CollectionStream } from 'streams/utils/streamUtils';

const toReportOptions = ({
  byId: reportsMap,
}: CollectionStream<APIReportAttributes>) => ({
  reportOptions: Object.entries(reportsMap).map(
    ([id, { _id, name, columns }]) => {
      const validBroadcastReportColumns = columns.filter(
        isValidBroadcastReportColumn
      );
      const isDisabled = !validBroadcastReportColumns.length;
      return {
        value: id,
        label: name,
        isDisabled,
        rightContent: isDisabled ? (
          <WithPopover
            content="Add at least one column that's not a reference type to this report before using it in a broadcast."
            preferredPlacement={PopoverPlacement.TOP}
          >
            <Tag label="No columns in report" />
          </WithPopover>
        ) : undefined,
      };
    }
  ),
});

type ReportOption = LabeledValue<ArdoqId> & {
  readonly isDisabled: boolean;
};

type ReportContentProps = {
  readonly selectedReport: ArdoqId | null;
  readonly reportOptions: ReportOption[];
  readonly required: boolean;
};

const ReportContent = ({
  selectedReport,
  reportOptions,
  required,
}: ReportContentProps) => {
  const selectedOption = reportOptions.find(
    ({ value: reportId }) => reportId === selectedReport
  );
  const hasError = required && !selectedOption;
  return (
    <Field label="Report" formSize={FormSize.DEFAULT}>
      <Select
        keepRightContentActive
        dataTestId="report-select"
        errorMessage={hasError ? 'Required field' : undefined}
        placeholder="Select a report"
        options={reportOptions}
        value={selectedOption}
        onValueChange={report => {
          if (selectedReport !== report) {
            dispatchAction(chooseBroadcastContentId(report as ArdoqId));
          }
        }}
      />
    </Field>
  );
};

export default connect(ReportContent, reports$.pipe(map(toReportOptions)));
