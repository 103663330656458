import { ExcludeFalsy } from '@ardoq/common-helpers';

export const getErrorMesssage = (error: any) => {
  if (!error) {
    return '';
  }
  return [
    error.message ? `Error message: ${error.message}` : '',
    error.responseText ? `Error text: ${error.responseText}` : '',
    error.status ? `Error code: ${error.status}` : '',
  ]
    .filter(ExcludeFalsy)
    .join(', ');
};

// Fetch Response utils
const isFetchResponse = (error: unknown): error is Response => {
  return error instanceof Response;
};

const getResponseHeaderValueByName = (
  error: Response,
  targetHeaderName: string
): unknown | null => {
  const serverHeader = [...error.headers].find(
    ([headerName]) => headerName === targetHeaderName
  );
  const [, serverHeaderValue] = serverHeader || [];
  return serverHeaderValue;
};

const getFetchResponseErrorData = (response: Response) => {
  const { status, statusText } = response;
  const server = getResponseHeaderValueByName(response, 'server');

  return {
    status,
    statusText,
    server,
    // TODO: Server responds with an error code, but in order to attain it the
    // whole function becomes async because we have to perform response.json()
    // errorCode,
  };
};

// XMLRequest utils
const isXMLHttpRequest = (error: any): error is XMLHttpRequest => {
  return error instanceof XMLHttpRequest;
};

const getXMLHttpRequestErrorCode = (error: XMLHttpRequest) => {
  try {
    const parsedResponseText = JSON.parse(error.responseText || '{}');
    return parsedResponseText['error-code'] || parsedResponseText.code;
  } catch {
    return undefined;
  }
};

// in backbone-promised.js we add requestArguments for logging purposes
const getRequestArguments = (error: XMLHttpRequest | Response) => {
  const { type, url, dataType } = (error as any).requestArguments || {};
  return { type, url, dataType };
};

const getXMLHttpRequestErrorData = (error: XMLHttpRequest) => {
  const { status, statusText } = error;

  const errorCode = getXMLHttpRequestErrorCode(error);
  const server = error.getResponseHeader('server');

  return {
    ...getRequestArguments(error),
    status,
    statusText,
    server,
    errorCode,
  };
};

/**
 * Parses error information from an unknown error object.
 */
export const getErrorData = (error: unknown) => {
  if (isFetchResponse(error)) {
    return getFetchResponseErrorData(error);
  }
  if (isXMLHttpRequest(error)) {
    return getXMLHttpRequestErrorData(error);
  }

  if (error instanceof Error) {
    return {
      name: error.name,
      message: error.message,
    };
  }

  return {
    name: 'Unknown error',
    message: `getErrorData could not parse error of type "${typeof error}"`,
  };
};

export const getErrorMessages: { [key: string]: string } = {
  '009-0001': 'Duplicate calculated field issue. It should match existing one',
  '009-0002': "Calculated field query either doesn't exist or is misconfigured",
};
