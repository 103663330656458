import TabbedContent from 'broadcasts/components/TabbedContent';
import AudienceTableByComponent from 'broadcasts/components/audienceTable/audienceByComponent/AudienceTableByComponent';
import AudienceTableByPerson from 'broadcasts/components/audienceTable/audienceByPerson/AudienceTableByPerson';
import TabTitleWithCount from 'broadcasts/components/audienceTable/TabTitleWithCount';
import getColumnConfigByPerson from './audienceByPerson/columnConfig';
import getColumnConfigByComponent from './audienceByComponent/columnConfig';
import {
  AudiencePreview,
  BroadcastContentType,
  InstanceProgress,
  InstanceComponent,
} from '@ardoq/api-types';
import { AudienceTableTab } from 'broadcasts/components/audienceTable/types';
import { getUniqueComponentCount } from 'broadcasts/components/audienceTable/utils';
import { ExpandableTableProps } from 'broadcasts/components/expandableTable/types';
import { doesAudiencePreviewHaveWarnings } from 'broadcasts/utils';
import { isAtLeastOneComponentComplete } from '../audienceByComponent/utils';
import { AudienceDataSourceItem } from '../audienceByPerson/utils';
import EmptyContent, {
  EmptyTableContent,
} from 'broadcasts/components/EmptyContent';
import { ExcludeFalsy } from '@ardoq/common-helpers';

type TabbedAudienceTableProps = {
  audiencePreview: AudiencePreview;
  components?: InstanceComponent[];
  description?: string;
  progress?: InstanceProgress;
  initiallySelectedTab?: AudienceTableTab;
  defaultAudienceSortById: string;
  defaultComponentSortById: string;
  contentType: BroadcastContentType;
  emptyTableContent?: EmptyTableContent;
  tableFilter?: string;
  onTableFilterChange?: (query: string) => void;
} & Pick<ExpandableTableProps<AudienceDataSourceItem>, 'loading'>;

const TabbedAudienceTable = ({
  audiencePreview,
  loading,
  description,
  progress,
  defaultAudienceSortById,
  defaultComponentSortById,
  initiallySelectedTab,
  contentType,
  emptyTableContent,
  components,
  tableFilter = '',
  onTableFilterChange,
}: TabbedAudienceTableProps) => {
  const shouldShowAudienceStatusColumn =
    doesAudiencePreviewHaveWarnings(audiencePreview);

  const shouldShowComponentStatusColumn =
    isAtLeastOneComponentComplete(progress);

  const componentToConsider =
    components ?? audiencePreview.flatMap(({ components }) => components);

  const audienceColumnContent = {
    title: AudienceTableTab.AUDIENCE,
    titleContent: (
      <TabTitleWithCount
        title={AudienceTableTab.AUDIENCE}
        count={audiencePreview.length}
      />
    ),
    content: (
      <AudienceTableByPerson
        audiencePreview={audiencePreview}
        progress={progress}
        getColumnConfig={getColumnConfigByPerson(
          shouldShowAudienceStatusColumn,
          contentType
        )}
        loading={loading}
        renderEmptyTable={() =>
          loading ? null : (
            <EmptyContent
              tab={AudienceTableTab.AUDIENCE}
              emptyTableContent={emptyTableContent}
            />
          )
        }
        defaultSortById={defaultAudienceSortById}
        tableFilter={tableFilter}
      />
    ),
  };

  const componentColumnContent =
    contentType !== BroadcastContentType.REPORT
      ? {
          title: AudienceTableTab.COMPONENT,
          titleContent: (
            <TabTitleWithCount
              title={AudienceTableTab.COMPONENT}
              count={getUniqueComponentCount(componentToConsider)}
            />
          ),
          content: (
            <AudienceTableByComponent
              components={components}
              audiencePreview={audiencePreview}
              progress={progress}
              getColumnConfig={getColumnConfigByComponent(
                shouldShowComponentStatusColumn
              )}
              loading={loading}
              renderEmptyTable={() =>
                loading ? null : (
                  <EmptyContent
                    tab={AudienceTableTab.COMPONENT}
                    emptyTableContent={emptyTableContent}
                  />
                )
              }
              defaultSortById={defaultComponentSortById}
              tableFilter={tableFilter}
            />
          ),
        }
      : null;

  return (
    <TabbedContent
      tabs={
        contentType === BroadcastContentType.REPORT
          ? [audienceColumnContent]
          : initiallySelectedTab === AudienceTableTab.COMPONENT
            ? [componentColumnContent, audienceColumnContent].filter(
                ExcludeFalsy
              )
            : [audienceColumnContent, componentColumnContent].filter(
                ExcludeFalsy
              )
      }
      description={description}
      onFilterChange={onTableFilterChange}
    />
  );
};

export default TabbedAudienceTable;
