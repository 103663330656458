import {
  ExtractPayload,
  action$,
  ofType,
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import Context from 'context';
import {
  notifyComponentChanged,
  notifyContextWorkspaceOrderChanged,
  notifyPresentationChanged,
  notifyReferenceContextChanged,
  notifyScenarioChanged,
  notifySortChanged,
  notifyWorkspaceChanged,
  notifyWorkspaceClosed,
  notifyWorkspaceOpened,
} from './ContextActions';
import { ContextShape } from '@ardoq/data-model';
import { toggleExploreMode } from 'presentation/actions';
import { debounceTime, distinct } from 'rxjs';

export const makeContext = (): ContextShape => {
  const componentId = Context.componentId() ?? '';
  const referenceId = Context.referenceId() || '';
  const workspaceId = Context.activeWorkspaceId() || '';
  const modelId = Context.activeModelId() || '';
  const scenarioId = Context.scenarioId() ?? '';
  const workspacesIds = Context.workspaces().map(({ id }) => id);
  const connectedWorkspaceIds = Array.from(Context.getConnectedWorkspaceIds());
  const presentationId = Context.presentationId() || '';
  return {
    componentId,
    referenceId,
    workspaceId,
    modelId,
    scenarioId,
    workspacesIds,
    presentationId,
    connectedWorkspaceIds,
    isExploreMode: Context.isExploreMode(),
    sort: { ...Context.sort },
  };
};
const toggleExploreModeReducer = (
  state: ContextShape,
  { isExploreMode }: ExtractPayload<typeof toggleExploreMode>
) => ({
  ...state,
  isExploreMode,
});

const dependencies$ = action$.pipe(
  ofType(
    notifyComponentChanged,
    notifyContextWorkspaceOrderChanged,
    notifyPresentationChanged,
    notifyReferenceContextChanged,
    notifyScenarioChanged,
    notifySortChanged,
    notifyWorkspaceClosed,
    notifyWorkspaceChanged,
    notifyWorkspaceOpened,
    toggleExploreMode
  ),
  debounceTime(16)
);

export const context$ = persistentReducedStream('context$', makeContext(), [
  streamReducer(dependencies$, makeContext),
  reducer(toggleExploreMode, toggleExploreModeReducer),
]);

export const contextWorkspaceId$ = context$.pipe(
  distinct(({ workspaceId }) => workspaceId)
);
