import { actionCreator } from '@ardoq/rxbeach';
import { MigrationChecklistState, MigrationId } from './types';

export const setPresentationMetadata = actionCreator<
  MigrationChecklistState['presentationMetadata']
>('[migrationChecklist] SET_MIGRATION_CHECKLIST_STATE');

export const setActiveMigration = actionCreator<MigrationId>(
  '[migrationChecklist] SET_ACTIVE_MIGRATION'
);

export const setReportDatasourceMigrationData = actionCreator<
  MigrationChecklistState['reportDatasourceMigrationData']
>('[migrationChecklist] SET_REPORT_DATASOURCE_MIGRATION_DATA');

export const getReportDatasourceMigrationData = actionCreator(
  '[migrationChecklist] GET_REPORT_DATASOURCE_MIGRATION_DATA'
);
