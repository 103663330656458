import buildRelationshipsViewBaseProperties from './buildRelationshipsViewBaseProperties';
import groupDisconnectedChildren from './groupDisconnectedChildren';
import divideExcessiveChildren from './divideExcessiveChildren';
import createNode from './createNode';
import createLink from './createLink';
import { RelationshipDiagramViewModelStreamState } from '@ardoq/graph';
import { RelationshipsViewSettings } from '../types';

/** builds nodes for the Relationships View. */
const buildRelationshipsViewProperties =
  (viewInstanceId: string) =>
  (
    viewModel: RelationshipDiagramViewModelStreamState<RelationshipsViewSettings>
  ) => {
    const {
      viewSettings: { collapsedGroupIds: viewSettingsCollapsedGroupIds },
    } = viewModel;
    const collapsedGroupIds = new Set(viewSettingsCollapsedGroupIds);
    const relationshipsViewProperties = buildRelationshipsViewBaseProperties(
      viewModel,
      createNode(collapsedGroupIds),
      createLink()
    );

    const { rootNode } = relationshipsViewProperties;
    const disconnectedChildren = rootNode
      ? [rootNode].reduce(groupDisconnectedChildren, [])
      : null;
    if (rootNode) {
      divideExcessiveChildren(rootNode);
    }

    return {
      ...relationshipsViewProperties,
      disconnectedChildren,
      viewInstanceId,
    };
  };
export default buildRelationshipsViewProperties;
