import { TraversalBuilderState } from '../types';

// Only keep the subgraph which is connected to the start node;
export const toConnectedSubgraph = (
  startNodeId: string,
  referenceMap: TraversalBuilderState['selectedGraph']['referenceMap'],
  excludeReferences = new Set<string>()
): Map<
  string,
  {
    sourceId: string;
    targetId: string;
    tripleId: string;
  }
> => BFS(startNodeId, referenceMap, excludeReferences).referenceMap;

export const BFS = (
  startNodeId: string,
  referenceMap: TraversalBuilderState['selectedGraph']['referenceMap'],
  excludeReferences = new Set<string>()
): {
  referenceMap: Map<
    string,
    {
      sourceId: string;
      targetId: string;
      tripleId: string;
    }
  >;
  visitedReferences: Set<string>;
} => {
  const references = Array.from(referenceMap).map(
    ([referenceId, { sourceId, targetId, tripleId }]) => ({
      referenceId,
      sourceId,
      targetId,
      tripleId,
    })
  );
  let startSet = new Set([startNodeId]);
  const visitedReferences = new Set<string>(excludeReferences);
  while (true) {
    const nextStep = step(startSet, visitedReferences, references);
    if (nextStep.size === 0) break;
    startSet = nextStep;
  }
  const actuallyVisitedReferences =
    visitedReferences.difference(excludeReferences);
  return {
    referenceMap: new Map(
      references
        .filter(({ referenceId }) => actuallyVisitedReferences.has(referenceId))
        .map(({ referenceId, ...rest }) => [referenceId, rest])
    ),
    visitedReferences: actuallyVisitedReferences,
  };
};

const step = (
  startSet: Set<string>,
  visitedReferences: Set<string>,
  references: {
    referenceId: string;
    sourceId: string;
    targetId: string;
    tripleId: string;
  }[]
) => {
  const nextStep = new Set<string>();
  references.forEach(({ sourceId, targetId, referenceId }) => {
    if (visitedReferences.has(referenceId)) return;
    if (startSet.has(sourceId)) {
      nextStep.add(targetId);
      visitedReferences.add(referenceId);
      return;
    }
    if (startSet.has(targetId)) {
      nextStep.add(sourceId);
      visitedReferences.add(referenceId);
      return;
    }
  });

  return nextStep;
};
