import { FieldsWrapper, Checkbox } from '@ardoq/forms';
import { SurveyQuestionValidator } from '@ardoq/api-types';

export const toggleValidator = (
  toggledValidator: SurveyQuestionValidator,
  enable: boolean,
  existingValidators: SurveyQuestionValidator[] = []
) => {
  if (enable) {
    return [...existingValidators, toggledValidator]; // Add validator if enabled
  }
  return existingValidators.filter(
    validator => validator.type !== toggledValidator.type
  );
};

type RequiredFieldProps = {
  isRequired: boolean;
  validators?: SurveyQuestionValidator[];
  updateValidators: (validators: SurveyQuestionValidator[]) => void;
};

export const RequiredField = ({
  isRequired,
  validators,
  updateValidators,
}: RequiredFieldProps) => {
  return (
    <FieldsWrapper>
      <Checkbox
        label="Required"
        isChecked={isRequired}
        onChange={() =>
          updateValidators(
            toggleValidator({ type: 'required' }, !isRequired, validators)
          )
        }
        popoverHelpContent={`Even if respondents haven't answered all required questions, 
        they can still submit the survey. Required questions are marked orange.`}
      >
        Is this field required?
      </Checkbox>
    </FieldsWrapper>
  );
};
