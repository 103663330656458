import styled from 'styled-components';
import NodeExpander from './NodeExpander';
// TODO side effect
import ExternallyManagedIndicatorWithPopover from 'externallyManaged/ExternallyManagedIndicatorWithPopover';
import {
  NavigatorItemCloseWorkspace,
  NavigatorItemContextMenu,
  NonComponentNavigatorItem,
  WorkspaceIcon,
} from './Atoms';
import { colors } from '@ardoq/design-tokens';
import { WorkspaceItemProps } from './PropTypes';
import { memo } from 'react';

const Container = styled(NonComponentNavigatorItem)`
  &.show-active {
    border-left-color: ${colors.blue50};
  }
`;

const WorkspaceItem = memo(
  ({
    name,
    nodeId,
    containerClassNames,
    containerStyle,
    hasVisibleContent,
    isExpanded,
    isExternallyManaged,
    hasCloseButton,
    hasContextMenu,
  }: WorkspaceItemProps) => (
    <Container
      className={containerClassNames}
      data-id={nodeId}
      style={containerStyle}
    >
      <NodeExpander
        hasVisibleContent={hasVisibleContent}
        isExpanded={isExpanded}
      />
      <WorkspaceIcon />
      <span className="workspace-name">{name}</span>
      {isExternallyManaged && (
        <ExternallyManagedIndicatorWithPopover workspaceId={nodeId} />
      )}
      {hasContextMenu && (
        <>
          <NavigatorItemContextMenu>
            <i
              className="material-icons-round"
              data-test-id="workspace-kebab-menu"
            >
              more_vert
            </i>
          </NavigatorItemContextMenu>
          {hasCloseButton && (
            <NavigatorItemCloseWorkspace>
              <i className="material-icons-round">close</i>
            </NavigatorItemCloseWorkspace>
          )}
        </>
      )}
    </Container>
  )
);

export default WorkspaceItem;
