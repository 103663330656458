import { APIOrganizationUser, ArdoqId } from '@ardoq/api-types';
import { Dispatch, SetStateAction, useState } from 'react';

/**
 * Similar to a `useState`, but only returns `selectedIds` that have a
 * corresponding user in `usersById`. This makes sure users don't remain
 * selected after being removed.
 */
export const useSelectedIds = (
  usersById: Partial<Record<string, APIOrganizationUser>>,
  initialState: ArdoqId[]
): [ArdoqId[], Dispatch<SetStateAction<ArdoqId[]>>] => {
  const [selectedIds, setSelectedIds] = useState<ArdoqId[]>(initialState);
  return [selectedIds.filter(id => usersById[id]), setSelectedIds];
};
