import { colors, space } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { Tabs as TabsUI, type Tab } from '@ardoq/integrations';
import { TablePreview } from 'integrations/common/streams/tablePreviews/types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { setCurrentTableId } from 'integrations/common/streams/activeIntegrations/actions';
import { viewModel$ } from './viewModel$';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { Box } from '@ardoq/layout';

// Tabs will overflow to the right to be scrollable
const TabsContainer = styled(Box)`
  background-color: ${colors.grey90};
  position: sticky;
  top: 0;
  z-index: 1;
`;

const TabsComponent = ({
  activeTabId,
  tabs,
  integrationId,
}: {
  activeTabId?: string;
  tabs: Tab<string>[];
  integrationId: IntegrationId;
}) => {
  const onTableSelect = (id: TablePreview['id']) => {
    dispatchAction(setCurrentTableId({ id, integrationId }));
  };
  return (
    <TabsContainer paddingTop="xsmall">
      <TabsUI
        activeTabId={activeTabId}
        tabs={tabs}
        onTabChange={onTableSelect}
      />
      {/*
        We need to add a bottom spacing to the tabs to show white space
        under tabs when the user scrolls down and tabs are sticky
      */}
      <Box minHeight={space.large} width="full" backgroundColor="bgDefault" />
    </TabsContainer>
  );
};

export const WorksheetTabs = connect(TabsComponent, viewModel$);
