import { PrimaryButton, BrandButton } from '@ardoq/button';
import { AddIcon } from '@ardoq/icons';
import { UseCaseStatus, UseCaseSummary } from '@ardoq/api-types';

type StartUseCaseButtonProps = {
  loadUseCase: VoidFunction;
  hasNewJourneyFeature: boolean;
  isOrgAdmin: boolean;
  selectedUseCase: UseCaseSummary | null;
  isLoadingUseCase: boolean;
};

const StartUseCaseButton = ({
  loadUseCase,
  hasNewJourneyFeature,
  isOrgAdmin,
  selectedUseCase,
  isLoadingUseCase,
}: StartUseCaseButtonProps) => {
  const isAdded = selectedUseCase?.status === UseCaseStatus.PENDING;
  const isStartButtonEnabled =
    selectedUseCase &&
    isOrgAdmin &&
    selectedUseCase.status !== UseCaseStatus.IN_PROGRESS;
  const Button = hasNewJourneyFeature ? BrandButton : PrimaryButton;

  return (
    <Button
      isDisabled={!isStartButtonEnabled}
      isLoading={isLoadingUseCase}
      onClick={loadUseCase}
      data-tooltip-text={
        !isOrgAdmin && isAdded
          ? 'Contact your organization’s admin to add solution materials.'
          : undefined
      }
    >
      {`${isAdded ? 'Add' : 'Update'} solution materials`}
      <AddIcon />
    </Button>
  );
};

export default StartUseCaseButton;
