import {
  QueryBuilderFilterDefinitionGroup,
  QueryBuilderFilterType,
  createGenericFilter,
  createSelectFilter,
  COMPONENT_NAME,
  CREATED,
  CREATED_BY,
  DESCRIPTION,
  DynamicFilterSelectOptions,
  LAST_MODIFIED_BY,
  LAST_UPDATED,
  SearchContext,
  composeFiltersFromFields,
  toOption,
  replaceNonPerspectiveOperators,
} from '@ardoq/query-builder';
import { FieldGlobalAttributes } from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { StubComponent } from '@ardoq/data-model';

type Options = {
  relatedFields: FieldGlobalAttributes[];
  relatedComponents: StubComponent[];
};

export const composeViewpointComponentsFilterGroups = ({
  relatedFields,
  relatedComponents,
}: Options): QueryBuilderFilterDefinitionGroup[] => {
  // modify the global property of the mock fields, otherwise composeFiltersFromFields will filter them out.
  // we want to see these fields in the query builder.
  const relatedComponentFields = relatedFields.map(field => ({
    ...field,
    global: true,
  }));

  const customFilters = composeFiltersFromFields(
    relatedComponentFields,
    SearchContext.COMPONENT
  );

  const componentOptions = relatedComponents.map(({ name, _id }) =>
    toOption(name, _id, _id)
  );
  const componentKeyOptions = relatedComponents.map(
    ({ 'component-key': componentKey }) => toOption(componentKey)
  );

  return [
    {
      label: 'Default',
      filters: [
        COMPONENT_NAME,
        DESCRIPTION,
        createSelectFilter('_id', 'Ardoq OID', [
          DynamicFilterSelectOptions.COMPONENT_CURRENT,
          ...componentOptions,
        ]),
        createSelectFilter('component-key', 'Ardoq ID', [
          DynamicFilterSelectOptions.COMPONENT_CURRENT,
          ...componentKeyOptions,
        ]),
        createSelectFilter('parent', 'Children of', [
          DynamicFilterSelectOptions.COMPONENT_PARENT_NONE,
          DynamicFilterSelectOptions.COMPONENT_CURRENT,
          ...componentOptions,
        ]),
        createGenericFilter(
          '_version',
          'Revision',
          QueryBuilderFilterType.NUMBER
        ),
        CREATED,
        LAST_UPDATED,
        CREATED_BY,
        LAST_MODIFIED_BY,
        createGenericFilter(
          'rootWorkspace',
          'Belongs to workspace',
          QueryBuilderFilterType.ASYNC_ROOT_WORKSPACE
        ),
      ].map(replaceNonPerspectiveOperators),
    },
    customFilters.length
      ? {
          label: 'Custom fields',
          filters: customFilters.map(replaceNonPerspectiveOperators),
        }
      : null,
  ].filter(ExcludeFalsy);
};
