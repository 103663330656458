import {
  AddIcon,
  ComponentLabel,
  MasterIcon,
  Representation,
  RowContent,
  RowWrapper,
  WorkspaceLabel,
} from './atoms';
import WorkspaceIcon from 'atomicComponents/WorkspaceIcon';
import { addComponentsToScenario, select, toggle } from './actionWrappers';
import { ComponentRepresentation } from '@ardoq/renderers';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { VirtualWindowItem } from 'components/VirtualWindow/types';
import { Expander } from 'components/Expander/Expander';
import { ByIdItem, DataSourceItem } from './types';
import { getEntityType, isComponent, isWorkspace } from '@ardoq/api-types';
import { trackClickStartAddToScenario } from 'scope/tracking';
import { getSelectedIds } from './scenarioRelatedSelection$';
import { IconName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { CONDITIONAL_IMAGE_COLORING_CLASS_NAME } from '@ardoq/global-consts';
import { classes } from '@ardoq/common-helpers';

type RowItemProperties = VirtualWindowItem<DataSourceItem, ByIdItem>;

const RowItem = ({ id, height, data, dataSource }: RowItemProperties) => {
  const {
    depth,
    isCollapsed,
    hasChildren,
    isSelected,
    isMultiSelect,
    name,
    ghosted,
  } = data;
  const showSelectedBorder = !isMultiSelect && isComponent(data) && !ghosted;
  const componentRepresentationData = componentInterface.getRepresentationData(
    id
  ) ?? {
    isImage: false,
    value: null,
  };

  return (
    <RowWrapper
      $depth={depth}
      $selected={isSelected}
      $isMultiSelect={isMultiSelect}
      $height={height}
      $showSelectedBorder={showSelectedBorder}
      onClick={select(id, dataSource)}
      data-component-id={id}
    >
      <Expander
        onClick={toggle(id)}
        isCollapsed={isCollapsed}
        hasChildren={hasChildren}
      />
      <RowContent $ghosted={ghosted}>
        {isComponent(data) && (
          <>
            <Representation
              className={classes(
                CONDITIONAL_IMAGE_COLORING_CLASS_NAME,
                ghosted
                  ? undefined
                  : (componentInterface.getCssClassNames(id) ?? '')
              )}
            >
              <ComponentRepresentation
                isImage={componentRepresentationData.isImage}
                value={componentRepresentationData.value}
                icon={componentRepresentationData.icon}
              />
            </Representation>
            <ComponentLabel>{name}</ComponentLabel>
          </>
        )}
        {isWorkspace(data) && (
          <>
            <WorkspaceIcon />
            <WorkspaceLabel>{name}</WorkspaceLabel>
          </>
        )}
        {!getEntityType(data) && (
          <>
            <MasterIcon />
            <WorkspaceLabel>{name}</WorkspaceLabel>
          </>
        )}
      </RowContent>
      {!ghosted && getEntityType(data) && (
        <AddIcon
          color={colors.grey80}
          containerStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          iconName={IconName.ADD}
          onClick={e => {
            e.stopPropagation();
            const selectedIds = getSelectedIds();
            addComponentsToScenario([...new Set([...selectedIds, id])]);
            trackClickStartAddToScenario('relatedNavigator');
          }}
          tooltipText="Include to the scenario"
        />
      )}
    </RowWrapper>
  );
};

export default RowItem;
