import { switchMap, tap } from 'rxjs/operators';
import {
  collectRoutines,
  routine,
  dispatchAction,
  ofType,
} from '@ardoq/rxbeach';
import { fetchQueries, fetchQueriesSuccess } from './actions';
import { handleError, storedQueryApi } from '@ardoq/api';

const handleFetchqueries = routine(
  ofType(fetchQueries),
  switchMap(() => storedQueryApi.fetchAll()),
  handleError(),
  tap(response =>
    dispatchAction(
      fetchQueriesSuccess({
        storedQueries: response,
      })
    )
  )
);

export default collectRoutines(handleFetchqueries);
