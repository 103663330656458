import { useState } from 'react';
import {
  SearchContext,
  composeAdvancedSearchFilterGroups,
} from '@ardoq/query-builder';
import { QueryBuilderWithSuggestionsLoaders } from 'search/QueryBuilderWithSuggestionsLoaders';
import { QueryBuilderQuery } from '@ardoq/api-types';
import Fields from 'collections/fields';

type ComponentFilterParams = {
  query?: QueryBuilderQuery;
  onQueryChange: (query: {
    isValid: boolean;
    query: QueryBuilderQuery;
  }) => void;
};

export function ComponentFilter({
  onQueryChange,
  query,
}: ComponentFilterParams) {
  // On set query for first render
  const [defaultQuery] = useState(query);
  return (
    <QueryBuilderWithSuggestionsLoaders
      query={defaultQuery}
      onChange={({ isValid, query }) => onQueryChange({ isValid, query })}
      includeContextSelect={false}
      hideContainerStyles
      allowedSearchContexts={[SearchContext.COMPONENT]}
      getFilterGroups={() =>
        composeAdvancedSearchFilterGroups(
          SearchContext.COMPONENT,
          Fields.collection.toJSON()
        )
      }
    />
  );
}
