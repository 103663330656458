import { Params } from './paramRegistry';

export type ChildProp = { children?: React.ReactNode };

// prettier-ignore
type Alphanumeric =
  | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'
  | 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j' | 'k' | 'l' | 'm' | 'n' | 'o' | 'p' | 'q' | 'r' | 's' | 't' | 'u' | 'v' | 'w' | 'x' | 'y' | 'z'
  | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' | 'Y' | 'Z';
export type ParamPathSegment = `/:${keyof Params}`;
type ConstantPathSegment = '/' | `/${Alphanumeric}${string}`;
type LayoutSegment = { layout: true };
type IndexSegment = { index: true };

export type PathRouteComponent = (props: {
  children?: React.ReactNode;
}) => React.ReactNode;

export type LayoutRouteComponent = (props: {
  children: React.ReactNode;
}) => React.ReactNode;

export type IndexRouteComponent = (
  props: Record<string, unknown>
) => React.ReactNode;

export type RouteComponent =
  | PathRouteComponent
  | LayoutRouteComponent
  | IndexRouteComponent;

export type PathRouteContent = Promise<PathRouteComponent>;
export type LayoutRouteContent = Promise<LayoutRouteComponent>;
export type IndexRouteContent = Promise<IndexRouteComponent>;

export type RouteContent =
  | PathRouteContent
  | LayoutRouteContent
  | IndexRouteContent;

export type IndexNode = {
  readonly path: IndexSegment;
  readonly content?: () => IndexRouteContent;
  readonly children?: undefined;
};

export type LayoutNode = {
  readonly path: LayoutSegment;
  readonly content?: () => LayoutRouteContent;
  readonly children: readonly RouteNode[];
};

export type PathNode = {
  /**
   * The path of the route.
   *
   * Can only be a single path segment, so it must start with a slash and
   * cannot contain any other slashes.
   *
   * If the second character is a colon, the path segment is interpreted as a
   * path parameter
   */
  readonly path: ParamPathSegment | ConstantPathSegment;

  /**
   * The content of the route.
   *
   * The content should be lazily imported to avoid cyclic dependency between the route tree, routeState$ and the content.
   * The content can be empty, in which case child routes will be rendered directly.
   *
   * Example:
   * ```ts
   * content: async () => (await import('./ExampleRoot')).default,
   * ```
   */
  readonly content?: () => PathRouteContent;

  readonly children?: readonly RouteNode[];
};

export type RouteNode = IndexNode | LayoutNode | PathNode;

export const isParamNode = (
  node: RouteNode
): node is RouteNode & { path: ParamPathSegment } =>
  typeof node.path === 'string' && node.path.startsWith('/:');

export const isLayoutNode = (
  node: RouteNode
): node is RouteNode & { path: { layout: true } } =>
  typeof node.path === 'object' && 'layout' in node.path && node.path.layout;

export const isIndexNode = (node: RouteNode): node is IndexNode =>
  typeof node.path === 'object' && 'index' in node.path && node.path.index;
