import {
  collectRoutines,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { tap } from 'rxjs';
import { loadBookmark as loadBookmarkAction } from './actions';
import { loadBookmark } from './loadBookmark';
import { withLatestFrom } from 'rxjs/operators';
import bookmarks$ from 'streams/bookmarks/bookmarks$';

const handleLoadBookmark = routine(
  ofType(loadBookmarkAction),
  extractPayload(),
  withLatestFrom(bookmarks$),
  tap(([bookmarkId, bookmarks]) => {
    const bookmark = bookmarks.byId[bookmarkId];
    loadBookmark(bookmark);
  })
);

export const bookmarkRoutines = collectRoutines(handleLoadBookmark);
