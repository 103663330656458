import { DataSourceItem } from 'components/DiffMergeTable/types';
import {
  APIEntityType,
  ArdoqId,
  ScopeDataCollection,
  Verb,
} from '@ardoq/api-types';
import { MergeDirection } from './MergeDirection';
import { MergeStepLabel } from './typesMergeStepLabel';

export const mergeStepLabelToDataMap: Record<
  MergeStepLabel,
  | Exclude<ScopeDataCollection, ScopeDataCollection.PERMISSIONS>
  | APIEntityType.COMPONENT_TYPE
  | APIEntityType.REFERENCE_TYPE
> = {
  [MergeStepLabel.STRUCTURE]: ScopeDataCollection.MODELS,
  [MergeStepLabel.COMPONENT_TYPES]: APIEntityType.COMPONENT_TYPE,
  [MergeStepLabel.REFERENCE_TYPES]: APIEntityType.REFERENCE_TYPE,
  [MergeStepLabel.FIELDS]: ScopeDataCollection.FIELDS,
  [MergeStepLabel.COMPONENTS]: ScopeDataCollection.COMPONENTS,
  [MergeStepLabel.REFERENCES]: ScopeDataCollection.REFERENCES,
  [MergeStepLabel.TAGS]: ScopeDataCollection.TAGS,
};

export enum StepResolution {
  COMPLETE = 'complete',
  UNFINISHED = 'unfinished',
}

export enum DisabledReason {
  NONE,
  MISSING_COMPONENT_TYPE,
  MISSING_REFERENCE_TYPE,
  NOTHING_TO_MERGE,
  MISSING_FIELD,
}

export type DiffStateMeta = {
  state: StepResolution;
  ids: Set<ArdoqId>;
  disabledReason: DisabledReason;
};

export type DiffMetaStates = Record<
  MergeStepLabel,
  {
    [Verb.CREATE]?: DiffStateMeta;
    [Verb.UPDATE]: DiffStateMeta;
    [Verb.DELETE]?: DiffStateMeta;
    [Verb.MERGE]?: DiffStateMeta;
  }
>;

export type MergeState = {
  mergeDirection: MergeDirection;
  diffMetaStates: DiffMetaStates | null;
  mainStateStep: MergeStepLabel | null;
  subStateStep: Verb | null;
  expandedMainSteps: MergeStepLabel[];
  submittedSteps: Record<MergeStepLabel, Set<Verb>>;
};

export type DataSourceGroup = {
  [Verb.CREATE]: { [id: string]: DataSourceItem[] };
  [Verb.DELETE]: { [id: string]: DataSourceItem[] };
  [Verb.UPDATE]: { [id: string]: DataSourceItem[] };
};
