import {
  DashboardBuilderChartWidget,
  DashboardTrackingEventsNames,
  getDefaultNumberSlicesForWidgetType,
  getMaximumNumberOfSlicesForWidgetType,
  getRecommendedMaxNumberOfSlicesForWidgetType,
  widgetIcons,
  widgetLabels,
  widgetOperations,
} from '@ardoq/dashboard';
import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { Select } from '@ardoq/select';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  selectChartDataOrder,
  selectNumberSlices,
  selectNumberTrend,
  selectWidgetType,
  updateSelectedWidget,
} from '../../actions';
import { ArrowDownwardIcon, ArrowUpwardIcon } from '@ardoq/icons';
import {
  dataSourceFieldToSelectOption,
  DEFAULT_TREND_CONFIGURATION,
  getSelectedFields,
  getSortOrder,
  isSortableColumInTableWidget,
} from '../../utils';
import { aggregates, aggregatesWithLabel } from '@ardoq/report-reader';
import { trackEvent } from '../../../../tracking/tracking';
import {
  Checkbox,
  GroupFieldLayout,
  NumberInput,
  RadioGroup,
  TextInput,
} from '@ardoq/forms';
import {
  Aggregate,
  APIFieldType,
  BarChartConfiguration,
  ChartOrderOptions,
  DataSourceField,
  NumberFormat,
  NumberWidgetConfiguration,
  Periods,
  PieChartConfiguration,
  SortOrder,
  StackedBarChartConfiguration,
  TrendColorScheme,
  WidgetDatasource,
  WidgetTypes,
} from '@ardoq/api-types';
import { FlexBox } from '@ardoq/layout';
import {
  getDisabledTrendReason,
  RollingTrendRangeSelect,
  TrendColorSchemeSelect,
  TrendPercentageSwitch,
} from './subsections/Trends';
import {
  getIsAggregateDisabledAndPopoverContent,
  WIDGETS_TYPES_THAT_WORK_WITH_COUNT_AGGREGATE,
} from '../../aggregateValidation';
import { getIsWidgetTypeDisabledAndPopoverContent } from '../../widgetTypeValidation';
import { Maybe } from '@ardoq/common-helpers';

const DISABLED_CHART_TYPE_SELECT_POPOVER_TEXT =
  'Select a report or survey first view the chart types.';

interface ChartTypeSelectProps {
  selectedWidgetType?: WidgetTypes;
  selectedDataSource?: WidgetDatasource;
  selectedFields: DataSourceField[];
  availableFieldsForDataSource?: DataSourceField[];
}

const widgetTypesForChartSelect = [
  WidgetTypes.TABLE,
  WidgetTypes.NUMBER,
  WidgetTypes.LINE_CHART,
  WidgetTypes.PIE_CHART,
  WidgetTypes.STACKED_BAR_CHART,
  WidgetTypes.BAR_CHART,
];
const ChartTypeSelect = ({
  selectedWidgetType,
  selectedDataSource,
  selectedFields,
  availableFieldsForDataSource,
}: ChartTypeSelectProps) => {
  const isDisabled = !selectedDataSource;
  return (
    <Select
      helperText={
        selectedFields.length > 1
          ? 'Only the Table and Line charts can display multiple fields. Deselect all but 1 field to select another chart type.'
          : undefined
      }
      dataTestId="dashboard-chart-type-select"
      isDisabled={isDisabled}
      placeholder="Select chart type"
      popoverContent={
        isDisabled ? DISABLED_CHART_TYPE_SELECT_POPOVER_TEXT : undefined
      }
      label="Select chart type"
      value={selectedWidgetType}
      options={
        !isDisabled
          ? widgetTypesForChartSelect.map(widgetType => ({
              label: widgetLabels[widgetType],
              value: widgetType,
              iconName: widgetIcons[widgetType],
              ...getIsWidgetTypeDisabledAndPopoverContent({
                widgetType,
                datasource: selectedDataSource,
                availableFieldsForDataSource,
                selectedFields,
              }),
            }))
          : []
      }
      onValueChange={value => {
        if (value === selectedWidgetType) return;
        trackEvent(
          DashboardTrackingEventsNames.SELECTED_CHART_TYPE_IN_DASHBOARD_BUILDER,
          { chartType: value }
        );
        dispatchAction(selectWidgetType(value!));
      }}
    />
  );
};

const DISABLED_AGGREGATE_SELECT_POPOVER_TEXT =
  'Aggregates can only be used with number, table and line charts.';

interface AggregateSelectProps {
  selectedFields: DataSourceField[];
  selectedAggregate: Aggregate | undefined;
  selectedWidgetType?: WidgetTypes;
}

const AggregateSelect = ({
  selectedWidgetType,
  selectedFields,
  selectedAggregate,
}: AggregateSelectProps) => {
  const isDisabled =
    !selectedWidgetType ||
    !WIDGETS_TYPES_THAT_WORK_WITH_COUNT_AGGREGATE.has(selectedWidgetType);

  return (
    <Select
      dataTestId="dashboard-aggregate-select"
      label="Select aggregate"
      value={selectedAggregate}
      options={aggregates.map(aggregate => ({
        value: aggregate,
        label: aggregatesWithLabel[aggregate],
        ...getIsAggregateDisabledAndPopoverContent({
          aggregate,
          fieldTypes: selectedFields.map(field => field.type),
          widgetType: selectedWidgetType,
        }),
      }))}
      isDisabled={isDisabled}
      popoverContent={
        isDisabled ? DISABLED_AGGREGATE_SELECT_POPOVER_TEXT : undefined
      }
      onValueChange={aggregate => {
        if (aggregate === selectedAggregate) return;
        trackEvent(
          DashboardTrackingEventsNames.SELECTED_AGGREGATE_IN_DASHBOARD_BUILDER,
          { aggregate }
        );
        dispatchAction(
          updateSelectedWidget({ aggregate: aggregate ?? undefined })
        );
      }}
      isClearable={
        selectedWidgetType === WidgetTypes.TABLE ||
        (selectedWidgetType === WidgetTypes.NUMBER && !selectedFields.length)
      }
    />
  );
};

interface NumberFormatSelectProps {
  isAbbreviated?: boolean;
}

const NumberFormatSelect = ({ isAbbreviated }: NumberFormatSelectProps) => (
  <RadioGroup
    label="Select number format"
    groupFieldLayout={GroupFieldLayout.VERTICAL}
    options={[
      { value: NumberFormat.FULL, label: 'Full numbers with decimals' },
      { value: NumberFormat.ABBREVIATED, label: 'Abbreviate large numbers' },
    ]}
    value={isAbbreviated ? NumberFormat.ABBREVIATED : NumberFormat.FULL}
    onValueChange={newFormat => {
      dispatchAction(
        updateSelectedWidget({
          isAbbreviated: newFormat === NumberFormat.ABBREVIATED,
        })
      );
    }}
  />
);

interface SortSelectProps {
  selectedSortColumn?: string;
  selectedSortOrder?: SortOrder;
  selectedFields: DataSourceField[];
}

const SortSelect = ({
  selectedSortColumn,
  selectedSortOrder,
  selectedFields,
}: SortSelectProps) => {
  const sortableFields = selectedFields.filter(isSortableColumInTableWidget);
  const sortedColumn =
    selectedSortColumn &&
    sortableFields.some(field => field.name === selectedSortColumn)
      ? selectedSortColumn
      : sortableFields.length
        ? sortableFields[sortableFields.length - 1].name
        : undefined;

  return (
    <>
      <Select
        label="Select column to sort by"
        value={sortedColumn}
        options={sortableFields.map(dataSourceFieldToSelectOption)}
        onValueChange={selectedColumn => {
          if (selectedColumn) {
            dispatchAction(
              updateSelectedWidget({
                sort: selectedColumn,
                order: getSortOrder(selectedColumn, sortableFields),
              })
            );
          }
        }}
      />
      <RadioGroup
        label="Default direction"
        groupFieldLayout={GroupFieldLayout.VERTICAL}
        isDisabled={!sortedColumn}
        options={[
          {
            value: SortOrder.ASC,
            label: (
              <FlexBox gap="small">
                Ascending
                <ArrowUpwardIcon />
              </FlexBox>
            ),
          },
          {
            value: SortOrder.DESC,
            label: (
              <FlexBox gap="small">
                Descending
                <ArrowDownwardIcon />
              </FlexBox>
            ),
          },
        ]}
        value={
          selectedSortOrder ??
          (sortableFields.find(field => field.name === sortedColumn)?.type ===
          APIFieldType.NUMBER
            ? SortOrder.DESC
            : SortOrder.ASC)
        }
        onValueChange={newOrder => {
          dispatchAction(
            updateSelectedWidget({
              sort: sortedColumn,
              order: newOrder as SortOrder,
            })
          );
        }}
      />
    </>
  );
};

const NumberSlicesInput = ({
  selectedWidget: { numberSlices, viewType },
}: {
  selectedWidget:
    | BarChartConfiguration
    | PieChartConfiguration
    | StackedBarChartConfiguration;
}) => {
  const maxNumberOfSlices = getMaximumNumberOfSlicesForWidgetType(viewType);
  const recommendedNumberOfSlices =
    getRecommendedMaxNumberOfSlicesForWidgetType(viewType);
  const helperText =
    numberSlices && numberSlices > recommendedNumberOfSlices
      ? viewType === WidgetTypes.PIE_CHART
        ? 'A pie chart with more than 7 slices can be difficult to understand. Consider combining slices or using a stacked bar chart for clearer data presentation.'
        : 'A bar chart with more than 20 bars can be difficult to understand. Consider combining bars or using a stacked bar chart for clearer data presentation.'
      : undefined;

  return (
    <NumberInput
      value={numberSlices ?? getDefaultNumberSlicesForWidgetType(viewType)}
      label={
        viewType === WidgetTypes.PIE_CHART
          ? 'Slices'
          : viewType === WidgetTypes.STACKED_BAR_CHART
            ? 'Bar sections'
            : 'Bars'
      }
      helperText={helperText}
      min={1}
      max={maxNumberOfSlices}
      warningMessage={
        numberSlices && numberSlices >= maxNumberOfSlices
          ? `Maximum of ${maxNumberOfSlices} ${
              viewType === WidgetTypes.PIE_CHART
                ? 'slices'
                : viewType === WidgetTypes.STACKED_BAR_CHART
                  ? 'sections'
                  : 'bars'
            } can be displayed.`
          : undefined
      }
      onValueChange={newValue => {
        if (!newValue) return; // newValue can be undefined if the user selects the number in the input and deletes it.
        const numberSlices =
          newValue > maxNumberOfSlices ? maxNumberOfSlices : newValue;
        dispatchAction(selectNumberSlices(numberSlices));
      }}
      showUpDownArrows
    />
  );
};

const WidgetDataOrderSelect = ({
  selectedWidget,
  selectedFields,
}: {
  selectedWidget:
    | BarChartConfiguration
    | PieChartConfiguration
    | StackedBarChartConfiguration;
  selectedFields: DataSourceField[];
}) => {
  const isPieChart = selectedWidget.viewType === WidgetTypes.PIE_CHART;
  const isListFieldSelected = selectedFields.some(
    field => field.type === APIFieldType.LIST
  );

  const chartType = isPieChart ? 'Slice' : 'Bar';

  const options: { label: string; value: ChartOrderOptions }[] = [
    {
      label: `${chartType} size (Descending order)`,
      value: ChartOrderOptions.SIZE,
    },
    isListFieldSelected
      ? {
          label: 'List field order',
          value: ChartOrderOptions.LIST_FIELD,
        }
      : {
          label: 'Field value (Alphabetical order)',
          value: ChartOrderOptions.FIELD_VALUE,
        },
  ];

  return (
    <RadioGroup
      label={`${chartType} order`}
      options={options}
      onValueChange={value => {
        dispatchAction(selectChartDataOrder(value as ChartOrderOptions));
      }}
      value={selectedWidget.orderSelection ?? ChartOrderOptions.SIZE}
    />
  );
};

const periodOptions = [
  { value: Periods.ONE_MONTH, label: '1 month' },
  { value: Periods.THREE_MONTHS, label: '3 months' },
  { value: Periods.SIX_MONTHS, label: '6 months' },
  { value: Periods.ONE_YEAR, label: '1 year' },
  { value: Periods.ALL_TIME, label: 'All time' },
];

type DataPresentationSectionProps = {
  availableFieldsForDataSource?: DataSourceField[];
  selectedWidget: DashboardBuilderChartWidget;
};

const getNumberWidgetCommands = (selectedWidget: NumberWidgetConfiguration) => {
  const hasTrendEnabled = widgetOperations.hasTrendEnabled(selectedWidget);
  const hasRollingTrend =
    hasTrendEnabled && widgetOperations.hasRollingTrend(selectedWidget);

  return {
    onResultLabelChange: (value: string) =>
      dispatchAction(updateSelectedWidget({ resultLabel: value })),
    onTrendSwitchChange: (value: boolean) => {
      if (value) {
        trackEvent(DashboardTrackingEventsNames.ENABLED_NUMBER_WIDGET_TREND);
        trackEvent(
          DashboardTrackingEventsNames.TREND_PERIOD_NUMBER_OF_DAYS_CHANGED,
          {
            numberOfDays: DEFAULT_TREND_CONFIGURATION.rolling.value,
          }
        );
      }
      if (!value)
        trackEvent(DashboardTrackingEventsNames.DISABLED_NUMBER_WIDGET_TREND);
      dispatchAction(selectNumberTrend(value));
    },
    ...(hasTrendEnabled
      ? {
          selectClassicColorScheme: () => {
            dispatchAction(
              updateSelectedWidget({
                trend: {
                  ...selectedWidget.trend,
                  colorScheme: TrendColorScheme.CLASSIC,
                },
              })
            );
          },
          selectInverseColorScheme: () => {
            dispatchAction(
              updateSelectedWidget({
                trend: {
                  ...selectedWidget.trend,
                  colorScheme: TrendColorScheme.INVERSE,
                },
              })
            );
          },
        }
      : {}),
    ...(hasRollingTrend
      ? {
          onRollingTrendRangeSelect: (value: Maybe<number>) => {
            if (!value || value === selectedWidget.trend.rolling.value) return;
            trackEvent(
              DashboardTrackingEventsNames.TREND_PERIOD_NUMBER_OF_DAYS_CHANGED,
              {
                numberOfDays: value,
              }
            );
            dispatchAction(
              selectNumberTrend({
                ...selectedWidget.trend,
                rolling: { value, unit: selectedWidget.trend.rolling.unit },
              })
            );
          },
        }
      : {}),
  };
};

const DataPresentationSection = ({
  availableFieldsForDataSource,
  selectedWidget,
}: DataPresentationSectionProps) => {
  const selectedFields = getSelectedFields(
    selectedWidget,
    availableFieldsForDataSource ?? []
  );

  const isNumberWidget = widgetOperations.isNumberWidgetData(selectedWidget);

  const hasTrendEnabled =
    isNumberWidget && widgetOperations.hasTrendEnabled(selectedWidget);

  const hasRollingTrend =
    hasTrendEnabled && widgetOperations.hasRollingTrend(selectedWidget);

  const numberWidgetCommands = isNumberWidget
    ? getNumberWidgetCommands(selectedWidget)
    : undefined;

  return (
    <SidebarMenuSection title="Data presentation">
      <ChartTypeSelect
        availableFieldsForDataSource={availableFieldsForDataSource}
        selectedWidgetType={selectedWidget.viewType}
        selectedDataSource={selectedWidget.datasource}
        selectedFields={selectedFields}
      />
      {isNumberWidget && (
        <TextInput
          dataTestId="dashboard-result-label-input"
          value={selectedWidget.resultLabel ?? ''}
          onValueChange={numberWidgetCommands?.onResultLabelChange}
          label="Results label"
          isOptional
        />
      )}
      {(widgetOperations.isLineChartData(selectedWidget) ||
        widgetOperations.isStackedBarChartData(selectedWidget)) && (
        <Select
          label="Filter changes made within"
          options={periodOptions}
          value={selectedWidget.period}
          defaultValue={Periods.THREE_MONTHS}
          onValueChange={period => {
            if (period && period !== selectedWidget.period)
              dispatchAction(updateSelectedWidget({ period }));
          }}
        />
      )}
      {widgetOperations.isLineChartData(selectedWidget) && (
        <Checkbox
          isChecked={Boolean(selectedWidget.hasDynamicYAxis)}
          label="Dynamic Y-axis"
          onChange={hasDynamicYAxis =>
            dispatchAction(updateSelectedWidget({ hasDynamicYAxis }))
          }
        >
          Start Y-axis with the lowest value instead of 0
        </Checkbox>
      )}
      {widgetOperations.hasConfigurableSlices(selectedWidget) && (
        <NumberSlicesInput selectedWidget={selectedWidget} />
      )}
      <AggregateSelect
        selectedAggregate={selectedWidget.aggregate}
        selectedFields={selectedFields}
        selectedWidgetType={selectedWidget.viewType}
      />
      {(isNumberWidget || widgetOperations.isLineChartData(selectedWidget)) && (
        <NumberFormatSelect isAbbreviated={selectedWidget.isAbbreviated} />
      )}
      {widgetOperations.isTableWidgetData(selectedWidget) && (
        <SortSelect
          selectedSortColumn={selectedWidget.sort}
          selectedFields={selectedFields}
          selectedSortOrder={selectedWidget.order}
        />
      )}
      {isNumberWidget && (
        <>
          <TrendPercentageSwitch
            isChecked={hasTrendEnabled}
            onChange={numberWidgetCommands!.onTrendSwitchChange}
            {...getDisabledTrendReason(selectedWidget)}
          />
          {hasTrendEnabled && (
            <>
              {hasRollingTrend && (
                <RollingTrendRangeSelect
                  onChange={numberWidgetCommands!.onRollingTrendRangeSelect!}
                  trend={selectedWidget.trend}
                />
              )}
              <TrendColorSchemeSelect
                selectClassicColorScheme={
                  numberWidgetCommands!.selectClassicColorScheme!
                }
                selectInverseColorScheme={
                  numberWidgetCommands!.selectInverseColorScheme!
                }
                trend={selectedWidget.trend}
              />
            </>
          )}
        </>
      )}
      {widgetOperations.isPieChartData(selectedWidget) && (
        <Checkbox
          isChecked={selectedWidget.showTotal}
          name="show-total-pie-chart"
          onChange={value =>
            dispatchAction(updateSelectedWidget({ showTotal: value }))
          }
        >
          Show total
        </Checkbox>
      )}
      {widgetOperations.hasConfigurableSlices(selectedWidget) && (
        <WidgetDataOrderSelect
          selectedWidget={selectedWidget}
          selectedFields={selectedFields}
        />
      )}
    </SidebarMenuSection>
  );
};

export default DataPresentationSection;
