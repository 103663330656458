import { actionCreator } from '@ardoq/rxbeach';
import { EditablePathCollapsingRule } from './types';
import { PathCollapsingRule } from '@ardoq/api-types';

export const setPathCollapsingRules = actionCreator<PathCollapsingRule[]>(
  '[viewpointBuilderPathCollapsing] SET_PATH_COLLAPSING_RULES'
);

export const createPathCollapsingRule = actionCreator(
  '[viewpointBuilderPathCollapsing] CREATE_PATH_COLLAPSING_RULE'
);

export const savePathCollapsingRule = actionCreator<EditablePathCollapsingRule>(
  '[viewpointBuilderPathCollapsing] SAVE_PATH_COLLAPSING_RULE'
);

export const deletePathCollapsingRule = actionCreator<string>(
  '[viewpointBuilderPathCollapsing] DELETE_PATH_COLLAPSING_RULE'
);

export const editPathCollapsingRule = actionCreator<string>(
  '[viewpointBuilderPathCollapsing] EDIT_PATH_COLLAPSING_RULE'
);

export const cancelEditPathCollapsingRule =
  actionCreator<EditablePathCollapsingRule>(
    '[viewpointBuilderPathCollapsing] CANCEL_EDIT_PATH_COLLAPSING_RULE'
  );

export const displayTextUpdated = actionCreator<string>(
  '[viewpointBuilderPathCollapsing] DISPLAY_TEXT_UPDATED'
);

export const pathCollapsingNodeSelected = actionCreator<string>(
  '[viewpointBuilderPathCollapsing] PATH_COLLAPSING_NODE_SELECTED'
);

export const clearCollapsedPathNodes = actionCreator(
  '[viewpointBuilderPathCollapsing] CLEAR_COLLAPSED_PATH_NODES'
);

export const graphNodeHovered = actionCreator<string | null>(
  '[viewpointBuilderPathCollapsing] GRAPH_NODE_HOVERED'
);

export type CollapsingRuleToggledPayload = {
  hash: string;
  isExpanded: boolean;
};

export const collapsingRuleToggled =
  actionCreator<CollapsingRuleToggledPayload>(
    '[viewpointBuilderPathCollapsing] COLLAPSING_RULE_TOGGLED'
  );

export type CollapsingRuleIsActiveToggledPayload = {
  hash: string;
  isActive: boolean;
};

export const collapsingRuleIsActiveToggled =
  actionCreator<CollapsingRuleIsActiveToggledPayload>(
    '[viewpointBuilderPathCollapsing] COLLAPSING_RULE_IS_ACTIVE_TOGGLED'
  );
