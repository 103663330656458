import { persistentReducedStream } from '@ardoq/rxbeach';
import { defaultState, reducers } from './reducers';

const audiencePreviewDialog$ = persistentReducedStream(
  'audiencePreviewDialog$',
  defaultState,
  reducers
);

export default audiencePreviewDialog$;
