import { actionCreator } from '@ardoq/rxbeach';
import { ExpandableSectionId } from './types';

export type ToggleIsSelectedGraphNodePayload = { graphNodeId: string };

export const toggleIsSelectedGraphNode =
  actionCreator<ToggleIsSelectedGraphNodePayload>(
    '[metamodelView] TOGGLE_IS_SELECTED'
  );

export type ToggleExpandableSectionPayload = {
  expandableId: ExpandableSectionId;
};

export const toggleExpandableSection =
  actionCreator<ToggleExpandableSectionPayload>(
    '[metamodelView] TOGGLE_EXPANDABLE_SECTION'
  );
