import { getPopoverConfig, NameCellWrapper } from './atoms';
import { SurveyRow as SurveyRowType } from '../AssetsBrowser/types';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import SelectCell from './SelectCell';
import RowIcon from './RowIcon';
import NameCell from './NameCell';
import { findIsSelected } from './utils';
import {
  AssetBrowserStateProps,
  AssetsBrowserProps,
} from 'components/AssetsBrowser/AssetsBrowser';
import { ColumnsOptions, Folder, RowItem } from './types';

type SurveyRowProps = {
  row: SurveyRowType;
  name: string;
  noAssetIds: boolean;
  selectRows: (row: RowItem | Folder<RowItem>, rangeSelect?: boolean) => void;
} & AssetBrowserStateProps &
  ColumnsOptions<SurveyRowType> &
  Pick<
    AssetsBrowserProps,
    'selected' | 'renameId' | 'renameConfirm' | 'renameCancel'
  >;

const SurveyRow = ({
  row,
  name,
  noAssetIds,
  permissionContext,
  selected,
  selectRows,
  onRowOpen,
  onRowPreviewClick,
  renameId,
  renameConfirm,
  renameCancel,
}: SurveyRowProps) => {
  const userHasInsufficientWorkspacePermissions =
    row.workspace &&
    !workspaceAccessControlInterface.canAdminWorkspace(
      permissionContext,
      row.workspace,
      null
    );
  const isDisabled =
    userHasInsufficientWorkspacePermissions || !row.meta.permissions.canUpdate;

  const popoverConfig = getPopoverConfig({
    showPopover: isDisabled,
    reason: !row.meta.permissions.canUpdate
      ? 'insufficientSurveyPermission'
      : 'insufficientWorkspaceAdminPermission',
    survey: row,
  });

  return (
    <NameCellWrapper>
      <SelectCell
        disabled={isDisabled || noAssetIds}
        isSelected={findIsSelected(row, selected)}
        indentationLevel={row.meta.level}
        rowId={row._id}
        onCheckboxClick={e => selectRows(row, e.shiftKey)}
      />
      <RowIcon
        isDisabled={isDisabled}
        rowType={row.rowType}
        onClick={() => onRowOpen(row)}
        popoverConfig={popoverConfig}
      />
      <NameCell
        rowData={row}
        isEditing={row._id !== undefined && row._id === renameId}
        onEditNameConfirm={(newName: string) =>
          renameConfirm && renameConfirm(newName, row)
        }
        onEditNameCancel={renameCancel}
        name={name}
        rowId={row._id}
        rowType={row.rowType}
        matchGroups={row.meta.matchGroups}
        isClickDisabled={isDisabled}
        onClick={() => onRowOpen(row)}
        popoverConfig={popoverConfig}
        onPreviewClick={() => onRowPreviewClick?.(row)}
      />
    </NameCellWrapper>
  );
};

export default SurveyRow;
