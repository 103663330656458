import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  scenarioRelatedDataSource$,
  setCollapsed,
} from './scenarioRelatedDataSource$';
import RowItem from './RowItem';
import VirtualWindow from 'components/VirtualWindow/VirtualWindow';
import { ByIdItem, DataSourceItem } from './types';
import { scenarioRelatedContextMenuName } from './ScenarioRelatedContextMenu';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { MAIN_NODE_ID } from './consts';
import EmptyRelatedView from './EmptyRelatedView';
import OutOfSyncRelatedView from './OutOfSyncRelatedView';
import { useResizeObserver } from '@ardoq/hooks';

interface ScenarioRelatedNavigatorProps {
  dataSource?: DataSourceItem[];
  byId: Record<string, ByIdItem>;
  isInSync: boolean;
}

const ScenarioRelatedNavigatorWrapper = styled.div`
  height: 100%;
  z-index: 1;
`;

const ScenarioRelatedNavigator = ({
  dataSource,
  byId,
  isInSync,
}: ScenarioRelatedNavigatorProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const size = useResizeObserver(ref);

  useEffect(() => dispatchAction(setCollapsed([MAIN_NODE_ID])), []);

  if (!dataSource) return null;

  if (!isInSync) {
    return <OutOfSyncRelatedView />;
  }

  const notJustCollapsedMasterNodeOrEmpty =
    dataSource.length <= 1 && !dataSource?.[0].isCollapsed;
  if (notJustCollapsedMasterNodeOrEmpty) {
    return <EmptyRelatedView />;
  }

  return (
    <ScenarioRelatedNavigatorWrapper
      ref={ref}
      data-context-menu={scenarioRelatedContextMenuName}
    >
      <VirtualWindow
        dataSource={dataSource}
        height={size.height || 0}
        rowHeight={30}
        byId={byId}
      >
        {RowItem}
      </VirtualWindow>
    </ScenarioRelatedNavigatorWrapper>
  );
};

export default connect(ScenarioRelatedNavigator, scenarioRelatedDataSource$);
