import { dispatchAction } from '@ardoq/rxbeach';
import {
  saveSubmittedFeedback,
  submitFeedback as submitFeedbackAction,
  dismissBanner as dismissBannearAction,
  passToIntercom as passToIntercomAction,
  sendMessage,
  closeChatWindow,
  minimizeChatWindow,
  openChatWindow,
  setInput as setInputAction,
  setCurrentScrollTop,
} from './actions';
import {
  BestPracticeAssistantCommands,
  DecoratedSendMessageRequest,
} from './types';

const submitPositiveFeedback = (messageIndex: number) => {
  dispatchAction(submitFeedbackAction('good'));
  dispatchAction(saveSubmittedFeedback({ messageIndex, feedback: 'good' }));
};

const submitNegativeFeedback = (messageIndex: number) => {
  dispatchAction(submitFeedbackAction('bad'));
  dispatchAction(saveSubmittedFeedback({ messageIndex, feedback: 'bad' }));
};

const dismissBanner = () => {
  dispatchAction(dismissBannearAction());
};

const passToIntercom = () => {
  dispatchAction(passToIntercomAction());
};

const send = (payload: DecoratedSendMessageRequest) =>
  dispatchAction(sendMessage(payload));

const close = () => dispatchAction(closeChatWindow());

const minimize = () =>
  dispatchAction(
    minimizeChatWindow({
      silenceEvent: false,
    })
  );

const open = () => dispatchAction(openChatWindow({ type: 'default' }));

const setInput = (value: string) => dispatchAction(setInputAction(value));

const setScrollTop = (value: number) =>
  dispatchAction(setCurrentScrollTop(value));

export default {
  submitPositiveFeedback,
  submitNegativeFeedback,
  dismissBanner,
  passToIntercom,
  send,
  close,
  minimize,
  open,
  setInput,
  setScrollTop,
} satisfies BestPracticeAssistantCommands;
