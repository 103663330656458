import {
  AdvancedSearchRuleError,
  AdvancedSearchRuleErrorType,
} from 'search/types';
import { AdvancedSearchFieldsHashState } from 'streams/fields/advancedSearchFields$';
import { getIn } from 'utils/collectionUtil';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  DEFAULT_FILTERS_BY_SEARCH_CONTEXT,
  LEGACY_OPERATOR_REPLACEMENTS,
  SearchContext,
} from '@ardoq/query-builder';
import { DateRangeRule, isDateRangeRule } from 'collections/filterUtils';
import {
  BooleanOperator,
  Operator,
  SortOrder,
  QueryBuilderSubquery,
  QueryBuilderQuery,
  QueryBuilderRule,
  SearchContextRule,
  FiltersBooleanRuleDataExtension,
  QueryBuilderRow,
  isQueryBuilderSubquery,
} from '@ardoq/api-types';

type GetCombinedRules = (options: {
  queryBuilderRules: QueryBuilderSubquery | null;
  selectedFilterType: SearchContext;
}) => QueryBuilderQuery;

const hasANDConditionAndRules = (
  queryBuilderRules: QueryBuilderSubquery
): boolean =>
  queryBuilderRules.rules &&
  queryBuilderRules.condition === BooleanOperator.AND &&
  queryBuilderRules.rules.length > 1 &&
  !('condition' in queryBuilderRules.rules[0]);

const isAlreadyCombinedRule = (
  queryBuilderRules: QueryBuilderSubquery
): boolean => {
  if (hasANDConditionAndRules(queryBuilderRules)) {
    const { id, field, type, input, operator } = queryBuilderRules
      .rules[0] as QueryBuilderRule;
    return (
      id === 'type' &&
      field === 'type' &&
      type === 'string' &&
      input === 'select' &&
      operator === 'equal'
    );
  }
  return false;
};

/**
 * @deprecated suspected dead-code
 */
export const createDateRangeQueries = (
  queryBuilderRules: QueryBuilderSubquery
) => {
  queryBuilderRules.rules = queryBuilderRules.rules.map(rule => {
    if ('rules' in rule) {
      return createDateRangeQueries(rule);
    } else if (isDateRangeRule(rule as FiltersBooleanRuleDataExtension)) {
      return createDateRangeQuery(rule as QueryBuilderRule & DateRangeRule);
    }
    return rule;
  });
  return queryBuilderRules;
};

export const getCombinedRules: GetCombinedRules = ({
  queryBuilderRules,
  selectedFilterType,
}): QueryBuilderQuery => {
  if (queryBuilderRules && isAlreadyCombinedRule(queryBuilderRules)) {
    // This here is to avoid adding the selectedFilterType rule more than once.
    return {
      ...queryBuilderRules,
      rules: [
        queryBuilderRules.rules[0] as SearchContextRule,
        createDateRangeQueries(
          queryBuilderRules.rules[1] as QueryBuilderSubquery
        ),
      ],
    };
  }

  const combinedRules: QueryBuilderSubquery = {
    condition: BooleanOperator.AND,
    rules: [
      {
        id: 'type',
        field: 'type',
        type: 'string',
        input: 'select',
        operator: Operator.EQUAL,
        value: selectedFilterType,
      },
    ],
  };

  if (queryBuilderRules && queryBuilderRules.rules.length) {
    combinedRules.rules.push(createDateRangeQueries(queryBuilderRules));
  }
  return combinedRules as QueryBuilderQuery;
};

type CreateDateRangeQuery = (
  dateRangeRule: QueryBuilderRule & DateRangeRule
) => QueryBuilderSubquery | QueryBuilderRule;

/**
 * @deprecated suspected dead-code
 */
const createDateRangeQuery: CreateDateRangeQuery = dateRangeRule =>
  dateRangeRule.operator === Operator.CONTAINS ||
  dateRangeRule.operator === Operator.NOT_CONTAINS
    ? createDateRangeContainsQuery(dateRangeRule)
    : createDateRangeBeforeOrAfterQuery(dateRangeRule);

/**
 * @deprecated suspected dead-code
 */
const createDateRangeContainsQuery: CreateDateRangeQuery = dateRangeRule => {
  const { dateRangeStartFieldName, dateRangeEndFieldName } = dateRangeRule.data;
  const isContains = dateRangeRule.operator === Operator.CONTAINS;

  return {
    condition: isContains ? BooleanOperator.AND : BooleanOperator.OR,
    rules: [
      {
        field: dateRangeStartFieldName,
        id: dateRangeStartFieldName,
        operator: isContains ? Operator.LESS : Operator.GREATER,
        input: 'text',
        type: 'date',
        value: dateRangeRule.value,
      },
      {
        field: dateRangeEndFieldName,
        id: dateRangeEndFieldName,
        operator: isContains ? Operator.GREATER : Operator.LESS,
        input: 'text',
        type: 'date',
        value: dateRangeRule.value,
      },
    ],
  };
};

/**
 * @deprecated suspected dead-code
 */
const createDateRangeBeforeOrAfterQuery: CreateDateRangeQuery =
  dateRangeRule => {
    const { dateRangeStartFieldName, dateRangeEndFieldName } =
      dateRangeRule.data;
    const isLess = dateRangeRule.operator === Operator.LESS;

    return {
      field: isLess ? dateRangeEndFieldName : dateRangeStartFieldName,
      id: isLess ? dateRangeEndFieldName : dateRangeStartFieldName,
      input: 'text',
      type: 'date',
      operator: isLess ? Operator.LESS : Operator.GREATER,
      value: dateRangeRule.value,
    };
  };

export const getSearchParams = ({
  from = 0,
  sortOrder,
  sortBy,
  size = 100,
}: {
  from?: number;
  sortOrder?: SortOrder;
  sortBy?: string;
  size?: number;
}) => ({
  from,
  size,
  order: sortOrder,
  sort: sortBy,
});

export const extractQueryBuilderRulesFromCombinedRules = (
  combinedRules: QueryBuilderSubquery
) =>
  combinedRules.rules.length > 1
    ? (combinedRules.rules[1] as QueryBuilderSubquery)
    : null;

export const extractFilterTypeFromCombinedRules = (
  combinedRules: QueryBuilderQuery
) => (combinedRules.rules[0] as QueryBuilderRule).value as SearchContext;
export const extractConditionFromCombinedRules = (
  combinedRules: QueryBuilderSubquery
) =>
  getIn(
    extractQueryBuilderRulesFromCombinedRules(combinedRules),
    ['condition'],
    null
  );

export enum SearchFieldNames {
  ROOT_WORKSPACE = 'rootWorkspace',
  TYPE_NAME = 'typeName',
  TYPE = 'type',
  NAME_SUGGEST = 'name-suggest',
}

// Legacy
interface Attribute {
  attribute: string;
  operator?: Operator;
  value: any;
}

type EqualRulesFromAttributes = (attributes: Attribute[]) => QueryBuilderRule[];

const equalRulesFromAttributes: EqualRulesFromAttributes = attributes =>
  attributes.map(({ attribute, operator = Operator.EQUAL, value }) => ({
    field: attribute,
    id: attribute,
    input: 'text',
    operator,
    type: 'string',
    value,
  }));

type SearchOptions = { attributes: Attribute[] };

type AdvancedSearchQueryRuleSearchOptions = SearchOptions & {
  additionalConditions?: QueryBuilderRule | null;
};

type ComposeSearchQuery = (
  params: AdvancedSearchQueryRuleSearchOptions
) => QueryBuilderQuery;

export const composeSearchQuery: ComposeSearchQuery = ({
  attributes = [],
  additionalConditions,
}): QueryBuilderQuery => {
  const composedRules = equalRulesFromAttributes(attributes);
  if (additionalConditions) {
    composedRules.push(additionalConditions);
  }
  return {
    condition: BooleanOperator.AND,
    rules: [
      {
        field: 'type',
        id: 'type',
        input: 'select',
        operator: Operator.EQUAL,
        type: 'string',
        value: SearchContext.COMPONENT,
      },
      ...composedRules,
    ],
  } as unknown as QueryBuilderQuery;
};

type SubquerySearchOptions = SearchOptions & {
  additionalConditions: QueryBuilderSubquery | null | undefined;
};

export const composeSearchQueryFromSubQuery = ({
  attributes = [],
  additionalConditions,
}: SubquerySearchOptions): QueryBuilderQuery => {
  const composedRules = equalRulesFromAttributes(attributes);
  if (additionalConditions) {
    // ugly as f*ck but the types _almost_ line up
    composedRules.push(additionalConditions as unknown as QueryBuilderRule);
  }

  return {
    condition: BooleanOperator.AND,
    rules: [
      {
        field: 'type',
        id: 'type',
        input: 'select',
        operator: Operator.EQUAL,
        type: 'string',
        value: SearchContext.COMPONENT,
      },
      {
        condition: BooleanOperator.AND,
        rules: composedRules,
      },
    ],
  };
};

export const collectRulesFromQuery = (
  advancedSearchQuery: QueryBuilderSubquery
): QueryBuilderRule[] => {
  return (advancedSearchQuery.rules || [])
    .map(ruleOrSubquery => {
      if ((ruleOrSubquery as QueryBuilderSubquery).condition) {
        return collectRulesFromQuery(ruleOrSubquery as QueryBuilderSubquery);
      }
      return ruleOrSubquery;
    })
    .flat() as QueryBuilderRule[];
};

export const validateAdvancedSearchQueryRule = (
  queryRule: QueryBuilderRule
): AdvancedSearchRuleError | null => {
  // TODO: For now we are validtaing very basic case
  // but extending rule validation should goes here
  if (queryRule.value === undefined) {
    return {
      id: queryRule.id,
      field: queryRule.field,
      type: queryRule.type,
      errorType: AdvancedSearchRuleErrorType.VALUE_MISSING,
    };
  }
  return null;
};

export const validateAdvancedSearchQuery = (
  advancedSearchQuery: QueryBuilderSubquery
): AdvancedSearchRuleError[] =>
  collectRulesFromQuery(advancedSearchQuery)
    .map(validateAdvancedSearchQueryRule)
    .filter(ExcludeFalsy);

export const ruleErrorToMessage = (
  { field, errorType }: AdvancedSearchRuleError,
  fieldsHash: AdvancedSearchFieldsHashState = {}
): string => {
  const fieldLabel = (fieldsHash[field] || {}).label || field;
  if (errorType === AdvancedSearchRuleErrorType.VALUE_MISSING) {
    return `${fieldLabel} field is missing value`;
  }
  return `${fieldLabel} is invalid`;
};

export const mapContainsOperatorForQueryRules = (
  rules: QueryBuilderRow[]
): QueryBuilderRow[] =>
  rules.map(ruleOrSubquery =>
    isQueryBuilderSubquery(ruleOrSubquery)
      ? {
          ...ruleOrSubquery,
          rules: mapContainsOperatorForQueryRules(ruleOrSubquery.rules),
        }
      : {
          ...ruleOrSubquery,
          operator:
            LEGACY_OPERATOR_REPLACEMENTS.get(ruleOrSubquery.operator) ??
            ruleOrSubquery.operator,
        }
  );

type FieldOperatorsAndTargetEntityTypes = {
  fields: Record<string, number>;
  operators: Record<string, number>;
  targetEntityTypes: Record<string, number>;
};

const DEFAULT_FIELD_IDS = new Set(
  Object.values(DEFAULT_FILTERS_BY_SEARCH_CONTEXT)
    .flat()
    .map(filter => filter.id)
);
export const getDefaultFieldsOperatorsAndTargetEntityTypesCountFromQuery = (
  advancedSearchQuery: QueryBuilderSubquery
) => {
  const emptyResult = {
    fields: {},
    operators: {},
    targetEntityTypes: {},
  };
  const reducer = (
    results: FieldOperatorsAndTargetEntityTypes,
    rule: QueryBuilderSubquery | QueryBuilderRule
  ): FieldOperatorsAndTargetEntityTypes => {
    if ('rules' in rule) {
      return rule.rules.reduce(reducer, results);
    }

    return {
      fields:
        rule.field && DEFAULT_FIELD_IDS.has(rule.field) // we don't want to track usage of custom fields in query
          ? {
              ...results.fields,
              [rule.field]: (results.fields[rule.field] ?? 0) + 1,
            }
          : results.fields,
      operators: rule.operator
        ? {
            ...results.operators,
            [rule.operator]: (results.operators[rule.operator] ?? 0) + 1,
          }
        : results.operators,
      targetEntityTypes: rule.targetEntityType
        ? {
            ...results.targetEntityTypes,
            [rule.targetEntityType]:
              (results.targetEntityTypes[rule.targetEntityType] ?? 0) + 1,
          }
        : results.targetEntityTypes,
    };
  };
  return 'rules' in advancedSearchQuery
    ? advancedSearchQuery.rules.reduce(reducer, emptyResult)
    : emptyResult;
};
