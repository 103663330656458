import {
  ViewpointBuilderNavigationState,
  ViewpointBuilderTab,
  ViewpointBuilderTabItemMeta,
} from './types';

export const isTabItemDisabled = (
  tabId: ViewpointBuilderTab,
  state: ViewpointBuilderNavigationState
) => {
  switch (tabId) {
    case 'METAMODEL_TAB':
    case 'SELECT_CONTEXT_COMPONENT_TYPE_TAB':
      return false;

    case 'FORMATTING_TAB':
    case 'LABELS_TAB':
    case 'GROUPS_TAB':
    case 'META_INFO_TAB':
      return isComponentTypeNotSelectedAndCreatingViewpoint(state);

    case 'SELECT_CONTEXT_COMPONENT_INSTANCES_TAB':
      return isContextEditViewpoint(state);

    case 'SUBGRAPH_TAB':
      switch (state.context) {
        case 'createViewpoint':
          return isComponentTypeNotSelectedAndCreatingViewpoint(state);
        case 'editViewpoint':
          return false;
        default:
          return (
            isNoComponentSelected(state) ||
            isMoreThanOneComponentTypeSelectedInSearch(state)
          );
      }

    case 'REQUIRED_COMPONENTS_TAB':
    case 'COLLAPSE_PATHS_TAB':
    case 'FILTERS_TAB':
      switch (state.context) {
        case 'createViewpoint':
          return isComponentTypeNotSelectedAndCreatingViewpoint(state);
        case 'editViewpoint':
          return false;
        default:
          return (
            isNoComponentSelected(state) ||
            isNoReferenceTypeSelected(state) ||
            isMoreThanOneComponentTypeSelectedInSearch(state)
          );
      }

    default:
      tabId satisfies never;
      return false;
  }
};

const isNoComponentSelected = (state: ViewpointBuilderNavigationState) => {
  return state.selectedComponentCount === 0;
};
const isNoReferenceTypeSelected = (state: ViewpointBuilderNavigationState) => {
  return state.edgesCount === 0;
};

const isComponentTypeNotSelectedAndCreatingViewpoint = (
  state: ViewpointBuilderNavigationState
) => {
  return (
    state.selectedComponentTypes.length === 0 &&
    state.context === 'createViewpoint'
  );
};

const isContextEditViewpoint = (state: ViewpointBuilderNavigationState) => {
  return state.context === 'editViewpoint';
};

const isMoreThanOneComponentTypeSelectedInSearch = (
  state: ViewpointBuilderNavigationState
) => {
  return state.selectedComponentTypes.length > 1;
};

export const getPopoverContent = (
  item: ViewpointBuilderTabItemMeta,
  state: ViewpointBuilderNavigationState
) => {
  if (!isTabItemDisabled(item.tabId, state)) {
    return undefined;
  }

  if (item.tabId === 'SELECT_CONTEXT_COMPONENT_INSTANCES_TAB') {
    return 'Editing the context component type of a saved Viewpoint is currently not supported. You can create a new Viewpoint for other component types.';
  }
  if (item.tabId === 'SUBGRAPH_TAB') {
    return getPopoverContentForReferencesTab(state);
  }
  if (item.tabId === 'FILTERS_TAB') {
    return getPopoverContentForFiltersTab(state);
  }

  return undefined;
};

const getPopoverContentForReferencesTab = (
  state: ViewpointBuilderNavigationState
): string => {
  if (isComponentTypeNotSelectedAndCreatingViewpoint(state)) {
    return 'Select a context component type first';
  }

  if (isNoComponentSelected(state)) {
    return 'Select at least 1 context component before adding references or filters';
  }

  if (isMoreThanOneComponentTypeSelectedInSearch(state))
    return 'You can only add references if the context components selected above are the same type';

  return '';
};
const getPopoverContentForFiltersTab = (
  state: ViewpointBuilderNavigationState
) => {
  if (isNoComponentSelected(state)) {
    return 'Select at least 1 context component before adding filters';
  }
  if (isMoreThanOneComponentTypeSelectedInSearch(state)) {
    return 'You can only add filters if the context components selected above are the same type';
  }
  if (isNoReferenceTypeSelected(state)) {
    return 'Select reference types first';
  }

  return '';
};
