import { ArdoqId } from '@ardoq/api-types';
import { DropdownItem } from '@ardoq/dropdown-menu';
import { dispatchAction, reducer, streamReducer } from '@ardoq/rxbeach';
import { requestShowAppModule } from 'appContainer/actions';
import { maybeShowConfirmCloseScenario } from 'appContainer/maybeShowConfirmCloseScenario';
import { AppModules } from 'appContainer/types';
import { getComponentMenu } from 'contextMenus/componentMenu';
import { ContextMenuTestIds } from 'contextMenus/consts';
import { setContextMenuState } from 'contextMenus/contextMenuState$';
import { getWorkspaceMenu } from 'contextMenus/workspaceMenu';
import { contextInterface } from 'modelInterface/contextInterface';
import * as scopeActions from 'scope/actions';
import { getScenarioMenuItems } from 'contextMenus/scenarioMenu';
import { componentSelection$ } from 'componentSelection/componentSelection$';
import { Observable } from 'rxjs';
import { updateTreeAction$ } from './streams/updateTreeAction$';
import { reloadTreeAction$ } from './streams/reloadTreeAction$';
import { componentsUpdate$ } from './streams/componentsUpdate$';
import { navigatorViewInterface$ } from './streams/navigatorViewInterface$';
import { showFilteredComponentsSetting$ } from './streams/showFilteredComponentsSettings$';
import { contextSort$ } from './streams/contextSort$';
import {
  activeScenario$,
  getActiveScenarioState,
} from 'streams/activeScenario/activeScenario$';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import {
  NavigatorConfiguration,
  NavigatorLayoutState,
} from 'components/WorkspaceHierarchies/types';
import { navigatorTreeOperations } from 'components/WorkspaceHierarchies/reducers/navigatorTreeOperations';
import { navigatorLayoutOperations } from 'components/WorkspaceHierarchies/reducers/navigatorLayoutOperations';
import { setActiveTab } from 'appContainer/MainAppModule/MainAppModuleSidebar/ViewpointSidebar/viewpointSidebarTabs$';
import { navigatorSelectionOperations } from 'components/WorkspaceHierarchies/reducers/navigatorSelectionOperations';
import { getScrollToSelectedRoutine } from './scrollSelectedIntoViewIfNeededRoutine';
import { popoverRegistry, registerTextPopovers } from '@ardoq/popovers';
import {
  CHANGED_POPOVER_ID,
  changedPopoverRenderer,
} from 'components/WorkspaceHierarchies/components/ChangedPopover';
import {
  SCENARIO_OUT_OF_SYNC_POPOVER_ID,
  scenarioOutOfSyncPopoverRenderer,
} from 'app/popovers/ScenarioOutOfSyncPopover';
import { registerDiffMergePopovers } from 'components/DiffMergeTablePopover/registerDiffMergePopovers';
import { notifyWorkspaceNameChanged } from 'streams/workspaces/actions';
import { mainNavigatorActionNamespace } from './consts';

export const mainNavigatorConfiguration: NavigatorConfiguration = {
  eventHandlers: {
    onComponentClick: (componentId: ArdoqId) =>
      contextInterface.setComponentById(componentId),
    onWorkspaceClick: (workspaceId: ArdoqId) =>
      contextInterface.loadWorkspaces({
        contextWorkspaceId: workspaceId,
        workspaceIds: [],
      }),
    onScenarioClick: (scenarioId: ArdoqId) =>
      contextInterface.setScenarioById(scenarioId),
    onWorkspaceCloseClick: (workspaceId: ArdoqId) =>
      contextInterface.closeWorkspace(workspaceId, {}),
    onScenarioCloseClick: async () => {
      const didConfirm = await maybeShowConfirmCloseScenario();
      if (didConfirm) {
        dispatchAction(scopeActions.closeScenario());
        dispatchAction(
          requestShowAppModule({ selectedModule: AppModules.HOME })
        );
      }
    },
    onComponentContextMenu: (
      event: MouseEvent,
      componentIds: ArdoqId[],
      isViewpointMode: boolean,
      targetComponentId: ArdoqId,
      getNextTreeSelectionCandidate: () => ArdoqId | null
    ) => {
      const items = getContextMenuOptions({
        event,
        componentIds,
        isViewpointMode,
        targetComponentId,
        getNextTreeSelectionCandidate,
      });
      dispatchAction(
        setContextMenuState({
          items,
          position: { left: event.clientX, top: event.clientY },
          testId: ContextMenuTestIds.COMPONENT,
        })
      );
    },
    onWorkspaceContextmenu: (
      event: MouseEvent,
      workspaceIds: ArdoqId[],
      scenarioId: ArdoqId | null,
      isViewpointMode: boolean
    ) => {
      const items = getContextMenuOptions({
        event: event,
        workspaceIds: workspaceIds,
        isViewpointMode,
        scenarioId,
      });
      dispatchAction(
        setContextMenuState({
          items,
          position: { left: event.clientX, top: event.clientY },
          testId: ContextMenuTestIds.WORKSPACE,
        })
      );
    },
    onScenarioContextmenu: (event: MouseEvent, scenarioId: string | null) => {
      const items =
        getScenarioMenuItems(
          scenarioId,
          contextInterface.getCurrentContext(),
          getActiveScenarioState()
        ) || [];
      dispatchAction(
        setContextMenuState({
          items,
          position: { left: event.clientX, top: event.clientY },
          testId: ContextMenuTestIds.SCENARIO,
        })
      );
    },
  },
  additionalRoutines: [
    (navigator$: Observable<NavigatorLayoutState>) =>
      getScrollToSelectedRoutine(navigator$),
  ],
  externalReducers: [
    streamReducer(updateTreeAction$, navigatorTreeOperations.resyncTree),
    streamReducer(reloadTreeAction$, navigatorTreeOperations.reloadTree),
    reducer(
      notifyWorkspaceNameChanged,
      navigatorLayoutOperations.treeChangedNoPayload
    ),
    streamReducer(
      showFilteredComponentsSetting$,
      navigatorLayoutOperations.showFilteredComponentsChanged
    ),
    streamReducer(
      componentsUpdate$,
      navigatorTreeOperations.handleComponentsUpdate
    ),
    streamReducer(
      navigatorViewInterface$,
      navigatorTreeOperations.setNavigatorViewInterface
    ),
    streamReducer(
      isViewpointMode$,
      navigatorLayoutOperations.setIsViewpointMode
    ),
    streamReducer(contextSort$, navigatorTreeOperations.setSort),
    streamReducer(activeScenario$, navigatorTreeOperations.setScenarioState),
    reducer(scopeActions.openScenario, navigatorTreeOperations.setTreeExpired),
    reducer(scopeActions.loadDiff, navigatorTreeOperations.setTreeExpired),
    reducer(
      scopeActions.setScenarioId,
      navigatorTreeOperations.handleSetScenarioId
    ),
    reducer(setActiveTab, navigatorLayoutOperations.resetScroll),
    streamReducer(
      componentSelection$,
      navigatorSelectionOperations.updateSelection
    ),
  ],
  onViewInitialized: () => {
    popoverRegistry.set(CHANGED_POPOVER_ID, changedPopoverRenderer);
    popoverRegistry.set(
      SCENARIO_OUT_OF_SYNC_POPOVER_ID,
      scenarioOutOfSyncPopoverRenderer
    );
    registerTextPopovers();
    registerDiffMergePopovers();
  },
  componentSelection$: componentSelection$,
  actionNamespace: mainNavigatorActionNamespace,
};

type MenuOptions = {
  event: MouseEvent;
  componentIds?: ArdoqId[];
  workspaceIds?: ArdoqId[];
  scenarioId?: ArdoqId | null;
  isViewpointMode: boolean;
  targetComponentId?: ArdoqId;
  getNextTreeSelectionCandidate?: () => ArdoqId | null;
};

const getContextMenuOptions = ({
  event,
  componentIds,
  workspaceIds,
  isViewpointMode,
  scenarioId = null,
  targetComponentId,
  getNextTreeSelectionCandidate,
}: MenuOptions): DropdownItem[] => {
  if (
    !(event.target instanceof HTMLElement || event.target instanceof SVGElement)
  ) {
    return [];
  }

  const eventDetails = {
    event,
    target: event.target,
    x: event.clientX,
    y: event.clientY,
  };

  if (componentIds?.length) {
    return (
      getComponentMenu({
        componentIds,
        isViewpointMode,
        eventTargetModelId: targetComponentId,
        isStartInNavigator: true,
        getNextTreeSelectionCandidate,
        ...eventDetails,
      }) || []
    );
  }

  if (workspaceIds?.length) {
    return (
      getWorkspaceMenu({
        workspaceIds,
        scenarioId,
        isViewpointMode,
        ...eventDetails,
      }) || []
    );
  }

  return [];
};
