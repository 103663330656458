import { APIFieldType } from '@ardoq/api-types';
import {
  IntegrationReferenceDirection,
  ReferenceMapping,
  MissingComponentsStrategy,
  MissingParentComponentsStrategy,
  ParentMapping,
} from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { IconSize, IconName } from '@ardoq/icons';

export const COLUMN_TYPES: Record<ColumnMapping['columnType'], string> = {
  'ardoq-oid': 'Ardoq OID',
  component: 'Component name',
  'component-type': 'Type',
  'custom-id': 'Custom ID',
  description: 'Description',
  'display-text': 'Display text',
  field: 'Field',
  parent: 'Parent component',
  reference: 'Reference',
  'reference-type': 'Type',
  'root-reference': 'Source',
  'target-reference': 'Target',
  tags: 'Tags',
};

const REFERENCE_FORMATS: Record<ReferenceMapping['referenceFormat'], string> = {
  path: 'Component path',
  'ardoq-oid': 'Ardoq OID',
  'custom-id': 'Custom ID',
};

const PARENT_FORMATS: Record<ParentMapping['parentFormat'], string> = {
  path: 'Component path',
  'ardoq-oid': 'Ardoq OID',
  'custom-id': 'Custom ID',
};

const REFERENCE_DIRECTIONS: Record<IntegrationReferenceDirection, string> = {
  outgoing: 'Target component',
  incoming: 'Source component',
};

const REFERENCE_IF_COMPONENT_IS_MISSING: Record<
  MissingComponentsStrategy,
  string
> = {
  create: 'Create new component',
  error: 'Block the import',
  // warn and exclude-from-import are the same
  // initially for Reference mapping for components, it used warn
  // going forward exclude-from-import is used
  warn: 'Exclude reference',
  'exclude-from-import': 'Exclude reference',
};

const PARENT_IF_COMPONENT_IS_MISSING: Record<
  MissingParentComponentsStrategy,
  string
> = {
  error: 'Block the import',
  'create-as-top-level': 'Import as top-level components',
};

type FIELD_TYPE = {
  label: string;
  icon?: IconName;
};

const FIELD_TYPES: Partial<Record<string, FIELD_TYPE>> = {
  [APIFieldType.TEXT]: { label: 'Text', icon: IconName.TEXT_FORMAT },
  [APIFieldType.TEXT_AREA]: {
    label: 'Text area',
    icon: IconName.TEXT_AREA,
  },
  [APIFieldType.NUMBER]: { label: 'Number', icon: IconName.NUMBER },
  [APIFieldType.DATE_ONLY]: { label: 'Date', icon: IconName.DATE },
  [APIFieldType.DATE_TIME]: { label: 'Date-time', icon: IconName.DATE_TIME },
  [APIFieldType.URL]: { label: 'URL', icon: IconName.REFERENCE },
  [APIFieldType.EMAIL]: { label: 'Email', icon: IconName.AT },
  [APIFieldType.CHECKBOX]: {
    label: 'Checkbox',
    icon: IconName.CHECKBOX_ITEM,
  },
  [APIFieldType.LIST]: { label: 'List', icon: IconName.LIST },
  [APIFieldType.SELECT_MULTIPLE_LIST]: {
    label: 'Select-multiple list',
    icon: IconName.SELECT_LIST,
  },
  [APIFieldType.FILE]: { label: 'File', icon: IconName.FILE_UPLOADED },
};

export const getColumnTypeName = (columnType: ColumnMapping['columnType']) =>
  COLUMN_TYPES[columnType];

export const getReferenceFormatName = (
  format: ReferenceMapping['referenceFormat']
) => REFERENCE_FORMATS[format];

export const getParentFormatName = (format: ParentMapping['parentFormat']) =>
  PARENT_FORMATS[format];

export const isReferenceFormatDisabled = (
  format: ReferenceMapping['referenceFormat'],
  missingComponentsStrategy?: MissingComponentsStrategy
) =>
  missingComponentsStrategy === MissingComponentsStrategy.CREATE &&
  format !== 'path';

export const isParentFormatDisabled = (format: ParentMapping['parentFormat']) =>
  format === 'path';

export const getReferenceDirectionName = (
  direction: IntegrationReferenceDirection
) => REFERENCE_DIRECTIONS[direction];

export const getFieldTypeName = (fieldType: string) =>
  FIELD_TYPES[fieldType]?.label ?? '';

export const getFieldTypeIcon = (fieldType: string) =>
  FIELD_TYPES[fieldType]?.icon
    ? { size: IconSize.SMALL, name: FIELD_TYPES[fieldType]?.icon }
    : undefined;

export const getReferenceIfComponentIsMissingName = (
  missingComponentsStrategy: MissingComponentsStrategy
) => REFERENCE_IF_COMPONENT_IS_MISSING[missingComponentsStrategy];

export const getParentIfComponentIsMissingName = (
  MissingComponentsStrategy: MissingParentComponentsStrategy
) => PARENT_IF_COMPONENT_IS_MISSING[MissingComponentsStrategy];
