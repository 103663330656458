import { ActionButtons, StyledIcon } from '../bundles/BundleOverview/atoms';
import { DatasourceTable, FlyWheelTable } from '@ardoq/table';
import { dispatchAction } from '@ardoq/rxbeach';
import { deleteWebhook } from './webhook$';
import { WebhookMessage } from './WebhookMessage';
import { IconName } from '@ardoq/icons';
import { Webhook } from '@ardoq/api-types';

type WebhookListProps = {
  webhooks: Webhook[];
};

const WebhookList = ({ webhooks }: WebhookListProps) => (
  <div>
    <WebhookMessage />
    <DatasourceTable
      components={FlyWheelTable}
      dataSource={webhooks}
      columns={[
        {
          title: 'Name',
          dataIndex: 'name',
        },
        {
          title: 'URL',
          dataIndex: 'url',
        },
        {
          dataIndex: '_id',
          valueRender: id => {
            return (
              <ActionButtons>
                <StyledIcon
                  iconName={IconName.DELETE}
                  tooltipText="Delete"
                  onClick={() => dispatchAction(deleteWebhook(id))}
                />
              </ActionButtons>
            );
          },
        },
      ]}
    />
  </div>
);

export default WebhookList;
