import { dispatchAction } from '@ardoq/rxbeach';
import { NavigatorLayoutState } from '../types';
import { distinctUntilChanged, map, Observable, tap } from 'rxjs';
import { isEqual } from 'lodash';
import { afterDragStart } from '../actions/navigatorActions';
import { catchErrorLogWithMessageAndContinue } from 'streams/utils/streamOperators';

export const getDragTargetsNotificationRoutine = (
  navigator$: Observable<NavigatorLayoutState>
) =>
  navigator$.pipe(
    map(({ isDrag, dragTargetNode, selection }) => ({
      isDrag,
      selection: isDrag ? selection : [],
      dragTargetNodeId: isDrag ? dragTargetNode?.id : null,
    })),
    distinctUntilChanged(isEqual),
    tap(({ isDrag, selection, dragTargetNodeId }) => {
      if (isDrag) {
        dispatchAction(
          afterDragStart({ ids: new Set([...selection, dragTargetNodeId]) })
        );
      }
    }),
    catchErrorLogWithMessageAndContinue(
      'Error in getDragTargetsNotificationRoutine'
    )
  );
