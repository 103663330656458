import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  SelectSubdivisionEditorStepActionPayload,
  SubdivisionEditorSteps,
  SubdivisionNavigationState,
} from './types';
import { selectSubdivision, selectSubdivisionEditorStep } from './actions';
import { subdivisionNavigationOperations } from './operations';

const defaultState: SubdivisionNavigationState = {
  subdivisionId: '',
  subdivisionEditorStep: SubdivisionEditorSteps.DETAILS,
};

const reducers = [
  reducer<SubdivisionNavigationState, SubdivisionNavigationState>(
    selectSubdivision,
    subdivisionNavigationOperations.setSubdivisionIdState
  ),
  reducer<SubdivisionNavigationState, SelectSubdivisionEditorStepActionPayload>(
    selectSubdivisionEditorStep,
    subdivisionNavigationOperations.selectSubdivisionEditorStep
  ),
];

const subdivisionNavigation$ = persistentReducedStream(
  'subdivisionNavigation$',
  defaultState,
  reducers
);

export default subdivisionNavigation$;
