import { catchError, filter, tap } from 'rxjs/operators';
import { MonoTypeOperatorFunction, Observable, OperatorFunction } from 'rxjs';
import { logError } from '@ardoq/logging';
import { Action } from '@ardoq/rxbeach';

export const tapEach =
  <T>(consume: (element: T) => void) =>
  (source: Observable<T[]>) => {
    return source.pipe(tap(elements => elements.forEach(consume)));
  };

export const catchErrorLogAndContinue = <T>(): OperatorFunction<T, T> => {
  return (source: Observable<T>) =>
    source.pipe(
      catchError((error, stream) => {
        logError(error);
        return stream;
      })
    );
};

export const catchErrorLogWithMessageAndContinue = <T>(
  message: string
): OperatorFunction<T, T> => {
  return (source: Observable<T>) =>
    source.pipe(
      catchError((_, stream) => {
        logError(Error(message));
        return stream;
      })
    );
};

export const excludeNullAndUndefined =
  <T>() =>
  (source$: Observable<null | undefined | T>) =>
    source$.pipe(
      filter(
        (input: null | undefined | T): input is T =>
          input !== null && input !== undefined
      )
    );

export const withNamespaceOrNullNamespace = (
  viewInstanceId: string
): MonoTypeOperatorFunction<Action<unknown>> =>
  filter(
    ({ meta: { namespace } }) =>
      namespace === undefined || namespace === viewInstanceId
  );
