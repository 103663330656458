import { ReactNode } from 'react';
import SplitPane from 'react-split-pane';

type CloseableSplitPaneProps = {
  isOpened?: boolean;
  closablePane: 'first' | 'second';
  split?: 'horizontal' | 'vertical';
  closedPaneSize?: number;
  minSize?: number;
  size?: number;
  defaultSize?: number;
  onDragFinished?: (size: number) => void;
  children: ReactNode;
};

const CloseableSplitPane = ({
  closablePane,
  isOpened = true,
  closedPaneSize = 0,
  children,
  size,
  ...props
}: CloseableSplitPaneProps) => {
  return (
    <SplitPane
      {...props}
      primary={closablePane}
      allowResize={isOpened}
      size={isOpened ? size : closedPaneSize}
    >
      {children}
    </SplitPane>
  );
};

export default CloseableSplitPane;
