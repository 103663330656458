import GenericErrorContent from '../GenericErrorContent';
import { WarningNotification } from '@ardoq/status-ui';
import { ModalContent, ModalLayout, ModalTemplate } from '@ardoq/modal';
import AudiencePreviewDescription from './AudiencePreviewDescription';
import AudienceTableByPerson from 'broadcasts/components/audienceTable/audienceByPerson/AudienceTableByPerson';
import EmptyContent from 'broadcasts/components/EmptyContent';
import audiencePreviewDialog$ from './audiencePreviewDialog$';
import { getMessageColumnConfig, getSurveyColumnConfig } from './utils';
import { AudiencePreviewStreamShape } from './types';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { BroadcastFetchStatus } from 'broadcasts/types';
import { BroadcastContentType } from '@ardoq/api-types';
import { AudienceTableColumn } from 'broadcasts/components/audienceTable/types';
import { doesAudiencePreviewHaveWarnings } from 'broadcasts/utils';
import { BROADCAST_AUDIENCE_DIALOG_MODAL_SIZE } from '../dialogConsts';
import { FlexBox, Stack } from '@ardoq/layout';
import { SearchInput } from '@ardoq/forms';
import { setFilter } from './actions';
import { spacing } from '@ardoq/design-tokens';
import styled from 'styled-components';

type AudiencePreviewDialogProps = {
  broadcastContentType: BroadcastContentType;
} & AudiencePreviewStreamShape &
  ModalContent;

const OverflowWrapper = styled(Stack)`
  overflow: hidden;
  padding: ${spacing.s4} ${spacing.s24};
  height: calc(100% - ${spacing.s24});
`;

const AudiencePreviewDialog = ({
  audiencePreview,
  audienceStartingPage,
  fetchStatus,
  audienceCount,
  warningMessage,
  resolve,
  broadcastContentType,
  filter,
}: AudiencePreviewDialogProps) => {
  const shouldShowStatusColumn =
    doesAudiencePreviewHaveWarnings(audiencePreview);
  const onFilterChange = (query: string) => {
    dispatchAction(setFilter(query));
  };
  return (
    <ModalTemplate
      headerText="Audience Preview"
      modalSize={BROADCAST_AUDIENCE_DIALOG_MODAL_SIZE}
      onSecondaryButtonClick={() => resolve(false)}
      secondaryButtonText="Close"
      onCloseButtonClick={() => resolve(false)}
    >
      {fetchStatus === BroadcastFetchStatus.ERROR ? (
        <GenericErrorContent />
      ) : (
        <>
          <ModalLayout>
            {warningMessage && (
              <WarningNotification>{warningMessage}</WarningNotification>
            )}
            <AudiencePreviewDescription audienceCount={audienceCount} />{' '}
          </ModalLayout>
          <OverflowWrapper>
            <FlexBox
              width="full"
              flex-direction="row"
              justify="end"
              align="center"
              paddingX="xxsmall"
              paddingBottom="small"
            >
              <SearchInput
                placeholder="Search..."
                onValueChange={onFilterChange}
              />
            </FlexBox>
            <AudienceTableByPerson
              audiencePreview={audiencePreview}
              audienceStartingPage={audienceStartingPage}
              getColumnConfig={
                broadcastContentType === BroadcastContentType.MESSAGE
                  ? getMessageColumnConfig(shouldShowStatusColumn)
                  : getSurveyColumnConfig(shouldShowStatusColumn)
              }
              loading={fetchStatus === BroadcastFetchStatus.LOADING}
              renderEmptyTable={() =>
                fetchStatus === BroadcastFetchStatus.LOADING ? null : (
                  <EmptyContent />
                )
              }
              defaultSortById={AudienceTableColumn.PERSON_LABEL}
              tableFilter={filter}
            />
          </OverflowWrapper>
        </>
      )}
    </ModalTemplate>
  );
};

export default connect(AudiencePreviewDialog, audiencePreviewDialog$);
