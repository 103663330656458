import {
  DataSyncStrategy,
  TableMappingType,
} from '@ardoq/api-types/integrations';

export const TABLE_MAPPING_DISPLAY_TEXTS: Record<TableMappingType, string> = {
  [TableMappingType.COMPONENTS]: 'Components',
  [TableMappingType.REFERENCES]: 'References',
};
export const MAPPING_SIDEBAR_WIDTH = 260;

export const DATA_SYNC_STRATEGY_TEXTS = {
  [DataSyncStrategy.MERGE_AND_UPSERT]: 'Create new and update existing assets.',
  [DataSyncStrategy.UPDATE_ONLY]:
    'Update only existing assets when using custom ID or Ardoq OID.',
  [DataSyncStrategy.CREATE_ONLY]:
    'Create only new assets when using custom ID or Ardoq OID.',
};
