import styled from 'styled-components';

import {
  TableId,
  TableMappingMap,
} from 'integrations/common/streams/tabularMappings/types';
import { header4 } from '@ardoq/typography';
import { WorkspaceSelector } from 'integrations/common/components/workspaceSelector/WorkspaceSelector';
import { TABLE_MAPPING_DISPLAY_TEXTS } from 'integrations/common/pages/tabularConfigMapping/constants';
import { testId } from '../../testUtils/testId';
import { IconName, Icon } from '@ardoq/icons';
import {
  TableMappingType,
  IntegrationWorkspace,
} from '@ardoq/api-types/integrations';
import { WithPopover } from '@ardoq/popovers';
import { RadioGroup } from './RadioGroupItem';
import { FlexBox } from '@ardoq/layout';
import { space } from '@ardoq/design-tokens';

const Header4 = styled.div`
  ${header4};
`;

const WorkspaceSelectorsContainer = styled.div`
  display: grid;
  width: 100%;
  align-items: end;
  gap: ${space.small};
  position: relative;
  grid-template-columns: repeat(
    auto-fit,
    minmax(140px, 1fr) minmax(auto, 59px)
  );
  @media (max-width: 1190px) {
    grid-template-columns: 1fr;
  }
`;

const SelectorContainer = styled.div`
  width: 100%;
`;

const Arrow = styled.div`
  align-self: end;
  @media (max-width: 1190px) {
    margin-left: 0;
    display: flex;
    justify-content: center;
    height: 70px;
    svg {
      transform: rotate(90deg);
      transform-origin: left;
    }
  }
`;

const getTableType = (value: string | number): value is TableMappingType => {
  return (
    value === TableMappingType.COMPONENTS ||
    value === TableMappingType.REFERENCES
  );
};

type TabularMappingProps = {
  tableId?: TableId;
  tableMapping?: TableMappingMap;
  workspaces: {
    used: IntegrationWorkspace[];
    existing: IntegrationWorkspace[];
  };
  stepTitle: string;
  selectWorkspace: string;
  disabledReferenceTypePopoverContent?: string;
  onTableTypeSelect: (tableType: TableMappingType) => void;
  onHomeWorkspaceSelect: (workspace: IntegrationWorkspace) => void;
  onGoalWorkspaceSelect: (workspace: IntegrationWorkspace) => void;
  isCreationDisabled?: boolean;
  disabledReferenceType?: boolean;
};
export const TabularMapping = ({
  tableMapping,
  workspaces,
  stepTitle,
  selectWorkspace,
  disabledReferenceTypePopoverContent,
  onTableTypeSelect,
  onHomeWorkspaceSelect,
  onGoalWorkspaceSelect,
  isCreationDisabled = false,
  disabledReferenceType = false,
}: TabularMappingProps) => {
  const hasRowRepresentation = !!tableMapping?.rowRepresentation;
  const availableOptions = Object.entries(TABLE_MAPPING_DISPLAY_TEXTS).map(
    ([value, label]) => ({
      label,
      value,
      isDisabled:
        disabledReferenceType && value === TableMappingType.REFERENCES,
      ...testId('tabularMapping-worksheet-type-radio-item'),
    })
  );

  const isReferenceMappingType =
    tableMapping?.rowRepresentation === TableMappingType.REFERENCES;

  const onChange = (tableType: string | number) => {
    if (!getTableType(tableType)) {
      return;
    }
    onTableTypeSelect(tableType);
  };

  return (
    <>
      <FlexBox gap="large" justify="space-between" flexDirection="column">
        <FlexBox gap="small" flexDirection="column">
          <Header4>1. {stepTitle}</Header4>
          <WithPopover
            content={
              disabledReferenceType && disabledReferenceTypePopoverContent
            }
          >
            <RadioGroup
              value={tableMapping?.rowRepresentation}
              options={availableOptions}
              onChange={onChange}
            />
          </WithPopover>
        </FlexBox>
        <WorkspaceSelectorsContainer>
          <WorkspaceSelector
            title={
              isReferenceMappingType
                ? '2. Source component workspace'
                : `2. ${selectWorkspace}`
            }
            workspaces={workspaces}
            selectedWorkspace={tableMapping?.rootWorkspace}
            onWorkspaceSelect={onHomeWorkspaceSelect}
            isDisabled={!hasRowRepresentation}
            isCreationDisabled={isCreationDisabled}
          />

          {isReferenceMappingType && (
            <>
              <Arrow>
                <Icon
                  style={{ width: '59px', minWidth: '59px', height: '24px' }}
                  iconName={IconName.ARROW_RIGHT_LONG}
                />
              </Arrow>
              <SelectorContainer>
                <WorkspaceSelector
                  title="Target component workspace"
                  workspaces={workspaces}
                  selectedWorkspace={tableMapping?.targetWorkspace}
                  onWorkspaceSelect={onGoalWorkspaceSelect}
                  isDisabled={!hasRowRepresentation}
                  isCreationDisabled={isCreationDisabled}
                />
              </SelectorContainer>
            </>
          )}
        </WorkspaceSelectorsContainer>
      </FlexBox>
    </>
  );
};
