import {
  DataSourceItem,
  DiffTableRowType,
  FieldConflict,
  MergeState,
  MergeStep,
  NonNullableDiffMergeTableProps,
} from './types';
import { Branch } from './Branch';
import {
  APIEntityType,
  APIOrganizationUser,
  ArdoqId,
  Verb,
} from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';
import {
  Aligner,
  BaseTr,
  DisabledRadio,
  FieldName,
  PropertyValue,
  Radio,
  RadioButtonContainer,
  ResetButton,
  Status,
  StyledTd,
  StyledTh,
  SubheaderTr,
} from './atoms';
import { dispatchAction } from '@ardoq/rxbeach';
import { resetMergeState, setMergeState } from './actions';
import {
  EnhancedScopeDataWithBranchName,
  Graphics,
  Nonexistent,
  RenderOptions,
  Renderer,
  getEntityById,
  getFieldLabel,
  transposeFieldNameIfNeeded,
} from '@ardoq/renderers';
import {
  COLUMN_MAX_WIDTH,
  horizontalSpacing,
} from 'components/DiffMergeTable/styleVariables';
import { entityRepresentationRenderer } from 'components/DiffMergeTable/EntityRepresentation/EntityRepresentation';
import styled from 'styled-components';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { AlertIcon } from './iconAndHintMessageRenderer';
import { colors, s8 } from '@ardoq/design-tokens';
import { withPlainTextPopover } from '@ardoq/popovers';
import { withMissingParentPopover } from 'components/DiffMergeTablePopover/utils';
import { DatasourceTable } from '@ardoq/table';
import { getPopoverStringForDisabledCheckbox } from 'components/DiffMergeTable/popoverStrings';
import ScenarioMergeIntercomTourClickIds, {
  shouldSetIntercomId,
} from 'components/DiffMergeTable/intercomTourClickIds';
import { getVerbFromMergeStep } from 'components/DiffMergeSidebarNavigator/utils';

const columnStyle = { width: COLUMN_MAX_WIDTH };

type PropertyCellProps = {
  graphics: Graphics;
  users: Record<ArdoqId, APIOrganizationUser>;
  options: RenderOptions;
  entityId: ArdoqId;
  entityType: APIEntityType;
  enhancedScopeData: EnhancedScopeDataWithBranchName;
  fallbackEnhancedScopeData?: EnhancedScopeDataWithBranchName;
  fieldName: string;
  index: number;
  branch?: Branch;
};

const FieldCell = (props: PropertyCellProps) => {
  const fieldName = transposeFieldNameIfNeeded(props);
  return <FieldName>{getFieldLabel({ ...props, fieldName })}</FieldName>;
};

const SubHeaderPropertyCell = () => null;

const PropertyCell = (props: PropertyCellProps) => (
  <PropertyValue
    data-click-id={
      shouldSetIntercomId(props.index) && props.branch === Branch.BRANCH_OFF
        ? ScenarioMergeIntercomTourClickIds.ORIGINAL_VALUE
        : ''
    }
  >
    <Renderer {...props} />
  </PropertyValue>
);

const getBranchValueRenderer =
  (
    branch: Branch,
    graphics: Graphics,
    users: Record<ArdoqId, APIOrganizationUser>,
    options: RenderOptions
  ) =>
  (_: string, dataSourceRow: DataSourceItem) => {
    const {
      enhancedDiffContextData: { [branch]: enhancedScopeData },
      rowType,
      ...props
    } = dataSourceRow;
    if (rowType === DiffTableRowType.SUB_HEADER_ROW) {
      return <SubHeaderPropertyCell />;
    }

    if (
      branch === Branch.BRANCH_OFF &&
      !getEntityById(
        dataSourceRow.entityType,
        dataSourceRow.entityId,
        enhancedScopeData,
        dataSourceRow.parentEntityId
      )
    ) {
      // Entity doesn't exist on branch off point.
      return <Nonexistent />;
    }

    return (
      <PropertyCell
        {...props}
        branch={branch}
        enhancedScopeData={enhancedScopeData}
        graphics={graphics}
        users={users}
        options={options}
      />
    );
  };

const LeftPadding = styled.div`
  padding-left: ${horizontalSpacing.SMALL};
`;

const AlertIconWithMargin = styled(AlertIcon)`
  margin-right: ${s8};
`;

const missingFieldMessage = (
  mergeDirection: MergeDirection
) => `This field is not available ${
  mergeDirection === MergeDirection.MAINLINE_TO_BRANCH
    ? 'in your scenario'
    : 'on mainline'
}, so this change cannot be merged.
Go back to the Field merge step and include this field if you wish to merge this field value.`;

const missingTypeMessage = (
  mergeDirection: MergeDirection,
  entityType: APIEntityType
) => {
  const typeString =
    entityType === APIEntityType.COMPONENT
      ? 'component type'
      : 'reference type';

  return `This ${typeString} is not available ${
    mergeDirection === MergeDirection.MAINLINE_TO_BRANCH
      ? 'in your scenario'
      : 'on mainline'
  }, so this change cannot be merged.
Go back to the ${typeString} create step and include this ${typeString}  if you wish to merge this ${typeString}.`;
};

interface GetConflictPopoverArgs {
  mergeDirection: MergeDirection;
  entityType: APIEntityType;
  fieldConflict?: FieldConflict;
  hasTypeConflict?: boolean;
  entityId: ArdoqId;
  hasMissingParentConflict?: boolean;
  hasWritePermission?: boolean;
}

const getConflictPopover = ({
  mergeDirection,
  entityType,
  fieldConflict,
  hasTypeConflict,
  entityId,
  hasMissingParentConflict,
  hasWritePermission,
}: GetConflictPopoverArgs) => {
  if (fieldConflict === FieldConflict.MISSING_FIELD_ON_TARGET) {
    return withPlainTextPopover(missingFieldMessage(mergeDirection));
  } else if (hasTypeConflict) {
    return withPlainTextPopover(missingTypeMessage(mergeDirection, entityType));
  } else if (hasMissingParentConflict) {
    return withMissingParentPopover(entityId);
  } else if (hasWritePermission === false) {
    return withPlainTextPopover(
      getPopoverStringForDisabledCheckbox({
        verb: Verb.UPDATE,
        entityType,
        mergeDirection,
        hasWritePermission,
      })
    );
  }
  return {};
};

const getFieldValueRenderer =
  (
    branch: Branch,
    graphics: Graphics,
    users: Record<ArdoqId, APIOrganizationUser>,
    options: RenderOptions,
    fallbackBranch: Branch,
    mergeStep: MergeStep
  ) =>
  (_: string, dataSourceRow: DataSourceItem) => {
    const { enhancedDiffContextData, rowType, ...props } = dataSourceRow;

    if (rowType === DiffTableRowType.SUB_HEADER_ROW) {
      return (
        <LeftPadding>
          {entityRepresentationRenderer(
            dataSourceRow.entityId,
            dataSourceRow.parentEntityId,
            dataSourceRow.entityType,
            enhancedDiffContextData[branch],
            graphics,
            dataSourceRow.index,
            getVerbFromMergeStep(mergeStep)
          )}
        </LeftPadding>
      );
    }
    return (
      <FieldCell
        {...props}
        enhancedScopeData={enhancedDiffContextData[branch]}
        fallbackEnhancedScopeData={enhancedDiffContextData[fallbackBranch]}
        graphics={graphics}
        users={users}
        options={options}
      />
    );
  };

const getStatusRenderer =
  (mergeState: MergeState, mergeDirection: MergeDirection) =>
  (_: string, dataSourceRow: DataSourceItem) => {
    if (!dataSourceRow) {
      return null;
    }
    const {
      entityId,
      entityType,
      status,
      index,
      fieldConflict,
      isDisabled,
      hasTypeConflict,
      hasMissingParentConflict,
      hasWritePermission,
    } = dataSourceRow;
    if (
      (mergeState === MergeState.TARGET &&
        fieldConflict === FieldConflict.MISSING_FIELD_ON_TARGET) ||
      (mergeState === MergeState.SOURCE && hasTypeConflict) ||
      (mergeState === MergeState.SOURCE && hasMissingParentConflict) ||
      (mergeState === MergeState.SOURCE && hasWritePermission === false)
    ) {
      return (
        <RadioButtonContainer>
          <AlertIconWithMargin
            {...getConflictPopover({
              mergeDirection,
              entityType,
              fieldConflict,
              hasTypeConflict,
              hasMissingParentConflict,
              entityId,
              hasWritePermission,
            })}
          />
        </RadioButtonContainer>
      );
    }

    if (
      mergeState === MergeState.SOURCE &&
      fieldConflict === FieldConflict.MISSING_FIELD_ON_SOURCE
    ) {
      return null;
    }

    const iconName =
      status === mergeState
        ? IconName.RADIO_BUTTON_CHECKED
        : IconName.RADIO_BUTTON_UNCHECKED;

    return (
      <RadioButtonContainer
        data-click-id={
          mergeState === MergeState.SOURCE && shouldSetIntercomId(index)
            ? ScenarioMergeIntercomTourClickIds.RADIO_BUTTON
            : ''
        }
      >
        {isDisabled ? (
          <DisabledRadio iconName={iconName} />
        ) : (
          <Radio iconName={iconName} />
        )}
      </RadioButtonContainer>
    );
  };

const resetButtonRenderer = (_: string, dataSourceRow: DataSourceItem) => {
  if (!dataSourceRow) {
    return null;
  }
  const { rowType, isDisabled } = dataSourceRow;
  if (isDisabled) {
    return null;
  }

  const onlyShowOnHover = rowType !== DiffTableRowType.HEADER_ROW;
  return (
    <Aligner>
      <ResetButton
        data-click-id={
          dataSourceRow.index === 0
            ? ScenarioMergeIntercomTourClickIds.RESET_ICON
            : ''
        }
        className={onlyShowOnHover ? 'hiddenIcon' : ''}
        iconName={IconName.REFRESH}
      />
    </Aligner>
  );
};

const resetButtonCellOnClick = ({ index }: DataSourceItem) =>
  dispatchAction(resetMergeState(index));

const getOnCellClick =
  (mergeState: MergeState) =>
  ({ index, isDisabled }: DataSourceItem) =>
    !isDisabled &&
    dispatchAction(
      setMergeState({
        mergeStatus: mergeState,
        index,
      })
    );

const statusValueRenderer = (_: string, dataSourceRow: DataSourceItem) => {
  const { rowType, status, isDisabled, index } = dataSourceRow;
  if (rowType === DiffTableRowType.SUB_HEADER_ROW || isDisabled) {
    return null;
  }

  const color =
    status === MergeState.SOURCE || status === MergeState.TARGET
      ? colors.green50
      : colors.red60;

  return (
    <Status
      $color={color}
      data-click-id={
        shouldSetIntercomId(index)
          ? ScenarioMergeIntercomTourClickIds.STATUS_LABEL
          : ''
      }
    />
  );
};

const UpdatedEntitiesTable = ({
  dataSource,
  graphics,
  users,
  options,
  mergeDirection,
  mergeStep,
}: NonNullableDiffMergeTableProps) => {
  const [headerRow, ...bodyRows] = dataSource;

  const sourceColumns = [
    {
      headerStyle: { width: 64 },
      headerRender: () =>
        getStatusRenderer(MergeState.SOURCE, mergeDirection)('', headerRow),
      onHeaderClick:
        headerRow && (() => getOnCellClick(MergeState.SOURCE)(headerRow)),
      valueRender: getStatusRenderer(MergeState.SOURCE, mergeDirection),
      onCellClick: getOnCellClick(MergeState.SOURCE),
    },
    {
      headerStyle: columnStyle,
      title:
        mergeDirection === MergeDirection.MAINLINE_TO_BRANCH
          ? 'Mainline value'
          : 'Scenario value',
      onHeaderClick:
        headerRow && (() => getOnCellClick(MergeState.SOURCE)(headerRow)),
      valueRender: getBranchValueRenderer(
        Branch.SOURCE,
        graphics,
        users,
        options
      ),
      onCellClick: getOnCellClick(MergeState.SOURCE),
    },
  ];

  const targetColumns = [
    {
      headerStyle: { width: 64 },
      headerRender: () =>
        getStatusRenderer(MergeState.TARGET, mergeDirection)('', headerRow),
      onHeaderClick:
        headerRow && (() => getOnCellClick(MergeState.TARGET)(headerRow)),
      valueRender: getStatusRenderer(MergeState.TARGET, mergeDirection),
      onCellClick: getOnCellClick(MergeState.TARGET),
    },
    {
      headerStyle: columnStyle,
      title:
        mergeDirection === MergeDirection.MAINLINE_TO_BRANCH
          ? 'Scenario value'
          : 'Mainline value',
      onHeaderClick:
        headerRow && (() => getOnCellClick(MergeState.TARGET)(headerRow)),
      valueRender: getBranchValueRenderer(
        Branch.TARGET,
        graphics,
        users,
        options
      ),
      onCellClick: getOnCellClick(MergeState.TARGET),
    },
  ];

  return (
    <DatasourceTable
      components={{
        Th: StyledTh,
        Td: StyledTd,
      }}
      columns={[
        {
          headerStyle: columnStyle,
          valueRender: getFieldValueRenderer(
            Branch.TARGET,
            graphics,
            users,
            options,
            Branch.SOURCE,
            mergeStep
          ),
        },
        {
          headerStyle: columnStyle,
          headerRender: () => (
            <span data-tooltip-text="Value at the time of scenario creation or last complete merge">
              Original value
            </span>
          ),
          valueRender: getBranchValueRenderer(
            Branch.BRANCH_OFF,
            graphics,
            users,
            options
          ),
        },
        ...sourceColumns,
        ...targetColumns,
        {
          headerStyle: { width: 80 },
          title: 'Status',
          valueRender: statusValueRenderer,
        },
        {
          onHeaderClick: headerRow && (() => resetButtonCellOnClick(headerRow)),
          headerRender: headerRow && (() => resetButtonRenderer('', headerRow)),
          valueRender: resetButtonRenderer,
          onCellClick: resetButtonCellOnClick,
          headerStyle: { width: 64 },
        },
      ]}
      dataSource={bodyRows}
      scrollableSectionHeight="100%"
      getRowComponent={({ rowType }: DataSourceItem) =>
        rowType === DiffTableRowType.SUB_HEADER_ROW ? SubheaderTr : BaseTr
      }
    />
  );
};

export default UpdatedEntitiesTable;
