import { tap, withLatestFrom } from 'rxjs/operators';
import { showRightPane } from 'appContainer/actions';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { v4 as uuidv4 } from 'uuid';
import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
} from '@ardoq/rxbeach';
import { activateReferenceFab } from './actions';
import { context$ } from 'streams/context/context$';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { IMPLICIT_REFTYPE_ID } from 'models/model';

const handleActivateReferenceFab = routine(
  ofType(activateReferenceFab),
  withLatestFrom(context$),
  tap(([, { workspaceId }]) => {
    const newReferenceAttributes = {
      _id: uuidv4(),
      type: IMPLICIT_REFTYPE_ID,
      rootWorkspace: workspaceId,
      model: workspaceInterface.getWorkspaceModelId(workspaceId)!,
    };
    dispatchAction(
      showRightPane({
        newReferenceAttributes: [newReferenceAttributes],
        type: GetContentOptionsType.CREATE_REFERENCE,
        referenceIds: [newReferenceAttributes._id],
      })
    );
  })
);

export default collectRoutines(handleActivateReferenceFab);
