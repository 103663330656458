import {
  enhanceScopeData,
  getDynamicFilter,
  diffSourceToTarget,
  ignoredProperties,
} from '@ardoq/renderers';
import { dispatchAction } from '@ardoq/rxbeach';
import { notifyFetchingChangeApprovalDataSucceeded } from './actions';
import {
  APIFieldType,
  APIReferenceAttributes,
  APISurveyChangeApprovalData,
  APISurveyChangeRequest,
  ArdoqId,
  ChangeRequestAction,
  DeletedOnMainlineData,
} from '@ardoq/api-types';
import {
  createChangedFieldsByComponentId,
  createValidationObject,
} from './utils';
import {
  ComponentValidation,
  ComponentWithPendingChanges,
  FieldsByReferenceId,
  InvalidChangeRequest,
  ValidationObject,
} from './types';
import { EnhancedScopeData } from '@ardoq/data-model';
import { isEmpty, isEqual } from 'lodash';
import { emptyChangeApprovalData } from './reducers';
import { fieldUtils } from '@ardoq/scope-data';
import { markdownToTextPreview } from '@ardoq/markdown';

const withoutInsignificantTextAreaUpdates = (
  changedFieldsByReferenceId: FieldsByReferenceId,
  branchData: EnhancedScopeData,
  masterData: EnhancedScopeData,
  deletedOnMainlineIds: DeletedOnMainlineData
): FieldsByReferenceId => {
  if (!Object.values(changedFieldsByReferenceId).length)
    return changedFieldsByReferenceId;

  const textAreaFields = masterData.fields.filter(
    ({ type }) => type === APIFieldType.TEXT_AREA
  );

  return Object.entries(changedFieldsByReferenceId)
    .filter(
      ([referenceId]) => !deletedOnMainlineIds.references.includes(referenceId)
    )
    .reduce((acc, [referenceId, changedFields]) => {
      const textAreaFieldNames = new Set(
        textAreaFields
          .filter(field =>
            fieldUtils.appliesToReference(branchData, field._id, referenceId)
          )
          .map(field => field.name)
      );

      const fieldsWithChanges = Object.keys(changedFields).reduce(
        (acc, fieldName) => {
          if (!textAreaFieldNames.has(fieldName))
            return { ...acc, [fieldName]: true };
          const originalValue =
            masterData.referencesById[referenceId][fieldName];
          const value = branchData.referencesById[referenceId][fieldName];
          const hasChanges = !isEqual(
            markdownToTextPreview(originalValue ?? ''),
            markdownToTextPreview(value ?? '')
          );
          if (!hasChanges) return acc;
          return { ...acc, [fieldName]: true };
        },
        {}
      );

      if (isEmpty(fieldsWithChanges)) return acc;
      return {
        ...acc,
        [referenceId]: fieldsWithChanges,
      };
    }, {});
};

const getCreatedReferencesForComponent = (
  createdReferences: APIReferenceAttributes[],
  componentId: ArdoqId
) => {
  const createdReferencesForComponent = createdReferences.filter(
    reference =>
      reference.source === componentId || reference.target === componentId
  );
  return createdReferencesForComponent.length
    ? { create: createdReferencesForComponent }
    : {};
};

const getDeletedReferencesForComponent = (
  deletedReferences: APIReferenceAttributes[],
  componentId: ArdoqId
) => {
  const deletedReferencesForComponent = deletedReferences.filter(
    reference =>
      reference.source === componentId || reference.target === componentId
  );
  return deletedReferencesForComponent.length
    ? { delete: deletedReferencesForComponent }
    : {};
};

const getUpdatedReferencesForComponent = (
  changedFieldsByReferenceId: FieldsByReferenceId,
  componentId: ArdoqId,
  branchData: EnhancedScopeData
) => {
  const updatedReferencesForComponent = Object.keys(changedFieldsByReferenceId)
    .filter(
      referenceId =>
        branchData.referencesById[referenceId].source === componentId ||
        branchData.referencesById[referenceId].target === componentId
    )
    .map(referenceId => branchData.referencesById[referenceId]);

  return updatedReferencesForComponent.length
    ? { update: updatedReferencesForComponent }
    : {};
};

const getChangedReferencesByComponentId = (
  changeRequests: APISurveyChangeRequest[],
  createdReferences: APIReferenceAttributes[],
  deletedReferences: APIReferenceAttributes[],
  changedFieldsByReferenceId: FieldsByReferenceId,
  branchData: EnhancedScopeData
) => {
  if (
    !createdReferences.length &&
    !deletedReferences.length &&
    isEmpty(changedFieldsByReferenceId)
  )
    return {};

  return changeRequests.reduce((acc, changeRequest) => {
    const componentId = changeRequest.component;
    const createdReferencesForComponent = getCreatedReferencesForComponent(
      createdReferences,
      componentId
    );
    const deletedReferencesForComponent = getDeletedReferencesForComponent(
      deletedReferences,
      componentId
    );

    const updatedReferencesForComponent = getUpdatedReferencesForComponent(
      changedFieldsByReferenceId,
      componentId,
      branchData
    );

    if (
      isEmpty(createdReferencesForComponent) &&
      isEmpty(deletedReferencesForComponent) &&
      isEmpty(updatedReferencesForComponent)
    ) {
      return acc;
    }
    return {
      ...acc,
      [componentId]: {
        ...updatedReferencesForComponent,
        ...createdReferencesForComponent,
        ...deletedReferencesForComponent,
      },
    };
  }, {});
};

const getNumberOfChangesForComponent = (
  changeAction: ChangeRequestAction,
  componentValidation: ComponentValidation | undefined
) => {
  if (changeAction === 'create' || changeAction === 'delete') return -1;
  const fieldChanges = Object.keys(componentValidation?.fields ?? {});
  const referenceChanges = Object.keys(componentValidation?.references ?? {});
  return fieldChanges.length + referenceChanges.length;
};

/** This function takes the change requests and converts them either into a component with some extra data needed within the change approval flow
 * or into a list of invalid change requests that are missing the data needed to be valid
 */
export const convertChangeRequests = (
  changeRequests: APISurveyChangeRequest[],
  branchData: EnhancedScopeData,
  masterData: EnhancedScopeData,
  componentValidationObject: ValidationObject
) =>
  changeRequests.reduce<{
    componentsWithPendingChanges: ComponentWithPendingChanges[];
    invalidChangeRequests: InvalidChangeRequest[];
  }>(
    (acc, changeRequest) => {
      const branchComponent =
        branchData.componentsById[changeRequest.component];
      const masterComponent =
        masterData.componentsById[changeRequest.component];
      const changeAction = changeRequest.action;
      const hasDataRequiredToBeValid =
        changeAction === 'create'
          ? Boolean(branchComponent)
          : changeAction === 'update'
            ? branchComponent && masterComponent
            : Boolean(masterComponent);
      if (!hasDataRequiredToBeValid)
        return {
          ...acc,
          invalidChangeRequests: acc.invalidChangeRequests.concat({
            ...changeRequest,
            missingRequiredData: true,
          }),
        };
      const numberOfChanges = getNumberOfChangesForComponent(
        changeRequest.action,
        componentValidationObject[changeRequest.component]
      );
      return {
        ...acc,
        componentsWithPendingChanges: acc.componentsWithPendingChanges.concat({
          ...branchComponent,
          name:
            changeAction === 'create'
              ? branchComponent.name
              : masterComponent.name,
          _id: changeRequest.component,
          lastUpdated: changeRequest.lastUpdated,
          changeAction: numberOfChanges !== 0 ? changeAction : 'no_change',
          numberOfChanges,
        }),
      };
    },
    { componentsWithPendingChanges: [], invalidChangeRequests: [] }
  );

export const prepareAndSetChangeApprovalData$ = (
  changeApprovalData: APISurveyChangeApprovalData
) => {
  const { branch, mainline, changeRequests, deletedOnMainlineIds } =
    changeApprovalData;

  if (!changeRequests.length || !branch || !mainline) {
    dispatchAction(
      notifyFetchingChangeApprovalDataSucceeded(emptyChangeApprovalData)
    );
    return;
  }

  const branchData = enhanceScopeData(branch, {
    shouldFreeze: false,
  });
  const masterData = enhanceScopeData(mainline, {
    shouldFreeze: false,
  });

  const dynamicFilter = getDynamicFilter(branchData, masterData);
  const { diffData } = diffSourceToTarget({
    sourceData: branch,
    targetData: mainline,
    dynamicFilter,
    ignoredProperties,
  });

  const changedFieldsByComponentId = createChangedFieldsByComponentId(
    changeRequests,
    diffData.components.update ?? {}
  );

  const createdReferences = Object.values(diffData.references.create).filter(
    reference => !deletedOnMainlineIds.references.includes(reference._id)
  );
  const deletedReferences = Object.values(diffData.references.delete).filter(
    reference => !deletedOnMainlineIds.references.includes(reference._id)
  );

  const changedFieldsByReferenceId = withoutInsignificantTextAreaUpdates(
    diffData.references.update ?? {},
    branchData,
    masterData,
    deletedOnMainlineIds
  );

  const changedReferencesByComponentId = getChangedReferencesByComponentId(
    changeRequests,
    createdReferences,
    deletedReferences,
    changedFieldsByReferenceId,
    branchData
  );

  const componentValidationObject = createValidationObject(
    changedFieldsByComponentId,
    changedReferencesByComponentId
  );

  const { componentsWithPendingChanges, invalidChangeRequests } =
    convertChangeRequests(
      changeRequests,
      branchData,
      masterData,
      componentValidationObject
    );

  dispatchAction(
    notifyFetchingChangeApprovalDataSucceeded({
      deletedOnMainlineIds,
      componentsWithPendingChanges,
      invalidChangeRequests,
      masterData,
      branchData,
      changeRequests,
      changedFieldsByComponentId,
      componentValidationObject,
      changedReferencesByComponentId,
      changedFieldsByReferenceId,
      referenceValidationObject: changedFieldsByReferenceId,
    })
  );
};
