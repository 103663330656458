import { APIEntityType, AllSupportedEntityTypes } from '@ardoq/api-types';
import { alert } from '@ardoq/modal';
import { pluralize } from '@ardoq/common-helpers';
import { DoqType } from '@ardoq/doq';
import { Fragment } from 'react';
import { showDialogWithRememberAction } from 'utils/showDialogWithRememberAction';
import { DontShowAgain } from 'models/dontShowAgainSettings';
import GenericErrorMessageWithSupportLink from 'atomicComponents/GenericErrorMessageWithSupportLink';
import { InvalidEntityMergeReason } from 'components/EntityMerge/utils';

const getInvalidMergeSubtitle = (
  entityNoun: string,
  invalidReason: InvalidEntityMergeReason
) => {
  const commonString = `You can't merge ${pluralize(entityNoun, 2)} that`;
  if (invalidReason === InvalidEntityMergeReason.TYPE_MISMATCH) {
    return `${commonString} are of different types.`;
  } else if (
    invalidReason === InvalidEntityMergeReason.ROOT_WORKSPACE_MISMATCH
  ) {
    return `${commonString} belong to different workspaces.`;
  } else if (
    invalidReason === InvalidEntityMergeReason.SOURCE_TARGET_MISMATCH
  ) {
    return `${commonString} have different sources or targets.`;
  }
};

const getInvalidMergeMessageBody = (
  entityNoun: string,
  invalidReason: InvalidEntityMergeReason
) => {
  if (invalidReason === InvalidEntityMergeReason.TYPE_MISMATCH) {
    return (
      <Fragment key="message">
        Please select{entityNoun}of the same type only. Alternatively, change
        the type of the selected{pluralize(entityNoun, 2)}to the same type from
        the{' '}
        <em>
          Sidebar menu {'>'} Edit {entityNoun} properties {'>'} Type.
        </em>
      </Fragment>
    );
  } else if (
    invalidReason === InvalidEntityMergeReason.ROOT_WORKSPACE_MISMATCH
  ) {
    return (
      <Fragment key="message">
        Please select{pluralize(entityNoun, 2)}from the same workspace only.
      </Fragment>
    );
  } else if (
    invalidReason === InvalidEntityMergeReason.SOURCE_TARGET_MISMATCH
  ) {
    return (
      <Fragment key="message">
        Please select references with the same source and target only.
      </Fragment>
    );
  }
};

export const launchInvalidMergeAlert = (
  entityType: AllSupportedEntityTypes,
  invalidReason: InvalidEntityMergeReason
) => {
  const entityNoun =
    entityType === APIEntityType.COMPONENT ? 'component' : 'reference';

  return alert({
    title: `Couldn't merge ${pluralize(entityNoun, 2)}`,
    subtitle: getInvalidMergeSubtitle(entityNoun, invalidReason),
    doqType: DoqType.ALARM,
    text: getInvalidMergeMessageBody(entityNoun, invalidReason),
  });
};

const ConfirmComponentMergeMessage = (
  <>
    Your components will be merged immediately and will inherit all references,
    child components and tags of all the components. You can modify the tags on
    the merged component from the <em>Edit Component Properties</em> menu and
    the references from the <em>Grid Editor</em>
  </>
);

const ConfirmReferenceMergeMessage = (
  <>
    Your references will be merged immediately and will inherit all tags of all
    the references. You can modify the tags on the merge references from the{' '}
    <em>Edit Reference Properties</em> menu
  </>
);

export const confirmEntityMerge = (entityType: AllSupportedEntityTypes) =>
  showDialogWithRememberAction({
    dontShowAgainSettingKey: DontShowAgain.APPLY_ENTITY_MERGE,
    title: 'Apply changes',
    subtitle: 'Your components will be merged immediately',
    doqType: DoqType.ALARM,
    text:
      entityType === APIEntityType.COMPONENT
        ? ConfirmComponentMergeMessage
        : ConfirmReferenceMergeMessage,
    confirmButtonTitle: 'Apply',
  });

export const mergeErrorAlert = ({
  message,
  errorCode,
  traceId,
}: {
  message?: string;
  errorCode: string;
  traceId?: string;
}) =>
  alert({
    title: 'Merge error',
    subtitle: 'An error occurred in the merge process.',
    doqType: DoqType.ERROR,
    text: [
      message ? `Description: ${message}` : 'Please exit and try again.',
      errorCode && `Code: ${errorCode}`,
      <GenericErrorMessageWithSupportLink key="message" />,
      ...(traceId ? [`Trace ID: ${traceId}`] : []),
    ],
  });
