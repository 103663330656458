import { persistentReducedStream } from '@ardoq/rxbeach';
import { CONDITION_OPTIONS_BY_TYPE } from './consts';
import reducers from './reducers';
import { SSOAttributeMappingState } from './types';

const defaultState: SSOAttributeMappingState = {
  initialMapping: [],
  currentMapping: {},
  showValidationErrors: false,
  currentClaims: {},
  groups: [],
  attributeTypeOptions: [],
  conditionOptions: CONDITION_OPTIONS_BY_TYPE,
  mappingPreview: {
    preview: [],
    leaveGroups: [],
  },
  lastUpdated: null,
  isLoggedInWithSSO: false,
  loginOptions: [],
  allowMultipleSsoOptionsInMappings: false,
  isLoading: true,
  ssoSelectedForPreview: null,
};

const SSOAttributeMapping$ = persistentReducedStream<SSOAttributeMappingState>(
  'SSOAttributeMappingState',
  defaultState,
  reducers
);

export default SSOAttributeMapping$;
