import { IntegrationSchedule } from '@ardoq/api-types';
import { actionCreatorWithIntegrationId } from 'integrations/common/utils/actionCreatorWithIntegrationId';

export const showScheduleInformation = actionCreatorWithIntegrationId<{
  schedule: IntegrationSchedule;
}>('[Dashboard] SHOW_SCHEDULE_INFORMATION');

export const editSchedule = actionCreatorWithIntegrationId<{
  scheduleId: string;
}>('[Dashboard] EDIT_SCHEDULE');
