import { IconName } from '@ardoq/icons';
import currentUser from 'models/currentUser';
import { dispatchAction } from '@ardoq/rxbeach';
import { copyQuery, deleteQuery, loadStoredQuery } from 'search/actions';
import {
  ExcludeFalsy,
  findMatchGroups,
  MatchGroups,
} from '@ardoq/common-helpers';
import {
  getQueryEditorNamespace,
  unpackStoredQuery,
} from 'search/QueryEditor/queryEditor$';
import { SearchOverviewStoredQueryModel } from 'search/types';
import { Features, hasFeature } from '@ardoq/features';
import { openAddToScenarioDialog } from 'scope/actions';
import { trackClickStartAddToScenario } from 'scope/tracking';
import { confirmDeleteAsset } from 'components/DeleteAssetModal/DeleteAssetModal';
import { DeleteType } from 'components/DeleteAssetModal/types';
import {
  ArdoqId,
  QueryBuilderQuery,
  QueryBuilderRule,
  StoredQueryModel,
} from '@ardoq/api-types';
import { openDeleteMultipleAssetsDialog } from 'appContainer/DashboardContainer/getMenuOptions/openDeleteMultipleAssetsDialog';
import {
  SavedPerspective,
  perspectiveInterface,
} from 'modelInterface/perspectives/perspectiveInterface';
import { getCurrentLocale } from '@ardoq/locale';
import { CurrentContext } from 'modelInterface/types';

type SearchQueryWithMeta = SearchOverviewStoredQueryModel & {
  meta?: { matchGroups: MatchGroups; isSelected: boolean };
};

const dispatchDeleteQuery = (query: StoredQueryModel) => {
  dispatchAction(
    deleteQuery({
      queryId: query._id,
      parameterQueryId: query.parameterQueryId,
    }),
    getQueryEditorNamespace(query.backend, query.type)
  );
};

const dispatchCopyQuery = (query: StoredQueryModel) => {
  dispatchAction(
    copyQuery({
      model: unpackStoredQuery(query),
    }),
    getQueryEditorNamespace(query.backend, query.type)
  );
};

export const search = (
  storedQueries: SearchOverviewStoredQueryModel[],
  searchPhrase: string
): SearchQueryWithMeta[] => {
  const locale = getCurrentLocale();

  if (!searchPhrase) return storedQueries;

  return storedQueries.reduce((acc: SearchQueryWithMeta[], item) => {
    const matchGroups = findMatchGroups(item.name ?? '', searchPhrase, locale);

    if (matchGroups.match) {
      acc.push({ ...item, meta: { matchGroups, isSelected: false } });
    }

    return acc;
  }, []);
};

const confirmDeleteMultipleReports = async ({
  queries,
}: {
  queries: { _id: string; name: string }[];
}) =>
  await openDeleteMultipleAssetsDialog({
    reports: queries,
  });

type ConfirmDeleteQueryArgs = {
  name: string;
  id: ArdoqId;
  affectedPerspectives: SavedPerspective[];
};
const confirmDeleteQuery = async ({
  name,
  id,
  affectedPerspectives,
}: ConfirmDeleteQueryArgs) =>
  await confirmDeleteAsset({
    deleteType: DeleteType.QUERY,
    name,
    id,
    affectedPerspectives,
  });

const openDeleteQueryModal = async (query: StoredQueryModel) => {
  const affectedPerspectives = perspectiveInterface
    .getAll()
    .filter(perspective =>
      perspective.dynamicFilters?.some(
        dynamicPerspectiveFilter =>
          dynamicPerspectiveFilter.storedQueryId === query._id
      )
    );

  const shouldDelete = await confirmDeleteQuery({
    name: query.name,
    id: query._id,
    affectedPerspectives,
  });
  if (shouldDelete) dispatchDeleteQuery(query);
};

const openDeleteMultipleQueriesModal = async (
  queries: SearchOverviewStoredQueryModel[],
  clearSelection: () => void
) => {
  const shouldDelete = await confirmDeleteMultipleReports({ queries });
  if (shouldDelete) {
    queries.forEach(query => dispatchDeleteQuery(query));
    clearSelection();
  }
};

const isAdvancedSearch = (query: any): query is QueryBuilderQuery => {
  return query && query.rules;
};
const isComponentsSearch = (query: QueryBuilderQuery) => {
  try {
    return (query.rules[0] as QueryBuilderRule).value === 'component';
  } catch (e) {
    return false;
  }
};

export const getKebabMenuOptions = (
  query: SearchQueryWithMeta,
  context: CurrentContext,
  selectedQueries: SearchQueryWithMeta[],
  clearSelection: () => void
) => {
  if (!currentUser.hasWriteAccess()) return [];
  if (selectedQueries.length > 1 && query.meta && query.meta.isSelected) {
    return [
      {
        label: `Delete selected (${selectedQueries.length})`,
        onClick: () =>
          openDeleteMultipleQueriesModal(selectedQueries, clearSelection),
        iconName: IconName.DELETE,
      },
    ];
  }
  const underlyingQuery = query.query;
  return [
    query.hasCopyOption && {
      label: 'Copy',
      onClick: () => dispatchCopyQuery(query),
      iconName: IconName.FILE_COPY,
    },
    query.hasDeleteOption && {
      label: 'Delete',
      onClick: () => openDeleteQueryModal(query),
      iconName: IconName.DELETE,
    },
    hasFeature(Features.SCENARIOS_BETA) &&
      isAdvancedSearch(underlyingQuery) &&
      isComponentsSearch(underlyingQuery) && {
        label: 'Create new scenario',
        onClick: () => {
          dispatchAction(
            openAddToScenarioDialog({
              context,
              searchQuery: underlyingQuery,
            })
          );
          trackClickStartAddToScenario('search');
        },
        iconName: IconName.SCENARIO,
      },
  ].filter(ExcludeFalsy);
};

export const dispatchLoadStoredQuery = (storedQuery: StoredQueryModel) =>
  dispatchAction(
    loadStoredQuery({
      storedQuery,
    }),
    getQueryEditorNamespace(storedQuery.backend, storedQuery.type)
  );
