import { useRef } from 'react';
import * as React from 'react';
import SimpleGraphView from 'viewpointBuilder/components/SimpleGraph/SimpleGraphView';
import { ToggleIsSelectedGraphNodeCommands } from './viewpointBuilderFiltersCommands';
import { createGraphInterface } from 'viewpointBuilder/components/SimpleGraph/GraphInterface';
import { EditTraversalState } from '../types';
import { SimpleGraphContainerWithGreyBackground } from 'viewpointBuilder/components/SimpleGraph/SimpleGraphViewContainer';

type TraversalWithFiltersProps = {
  commands: ToggleIsSelectedGraphNodeCommands;
  state: EditTraversalState;
  toggleGraphNodeSelection: (
    event: React.MouseEvent<Element, MouseEvent>
  ) => void;
  label: string;
};

export const GraphVisualizationWithFilters = ({
  state,
  toggleGraphNodeSelection,
  label,
}: TraversalWithFiltersProps) => {
  const graphInterface = useRef(createGraphInterface());

  const { graphNodes, graphEdges, graphGroups } = state;

  return (
    <SimpleGraphContainerWithGreyBackground onClick={toggleGraphNodeSelection}>
      <SimpleGraphView
        label={label}
        viewModel={{ graphNodes, graphEdges, graphGroups }}
        graphInterface={graphInterface.current}
      />
    </SimpleGraphContainerWithGreyBackground>
  );
};
