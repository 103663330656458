import { dispatchAction } from '@ardoq/rxbeach';
import { editViewpoint } from 'viewpointBuilder/actions';
import {
  deleteLoadedState,
  setTraversalContextComponent,
  toggleCollapsedPathActive,
  toggleLoadedStateVisibility,
} from 'loadedState/actions';
import {
  applyFiltersForContextSwitcher as applyFiltersForContextSwitcherAction,
  ApplyFiltersForContextSwitcherPayload,
  editComponentSearch,
} from 'viewpointBuilder/selectContextTab/actions';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import {
  ArdoqId,
  LoadedState,
  LoadedStateHash,
  LoadedStateType,
  PathCollapsingRule,
} from '@ardoq/api-types';
import { confirmCloseAllDatasets } from './confirmCloseAllDatasets';
import { confirmCloseUnsavedViewpoint } from './confirmCloseUnsavedViewpoint';
import { trackEvent } from 'tracking/tracking';
import { ViewpointNavigatorTrackingEventsNames } from './tracking';
import { resetAllSearchState } from '../search/actions';

const toggleItemVisibility = (loadedStateHash: LoadedStateHash) => {
  dispatchAction(toggleLoadedStateVisibility({ loadedStateHash }));
};

const removeLoadedState = async ({
  loadedStateHash,
  loadedStatesCount,
  isTraversal,
  isSaved,
}: {
  loadedStateHash: LoadedStateHash;
  loadedStatesCount: number;
  isTraversal: boolean;
  isSaved: boolean;
}) => {
  if (isTraversal && !isSaved) {
    const ok = await confirmCloseUnsavedViewpoint();
    if (!ok) return;
  } else if (loadedStatesCount === 1) {
    const ok = await confirmCloseAllDatasets();
    if (!ok) return;
  }

  dispatchAction(deleteLoadedState(loadedStateHash));
};

const editBlock = (loadedState: LoadedState) => {
  trackEvent(ViewpointNavigatorTrackingEventsNames.CLICKED_EDIT_DATASET_BUTTON);
  const loadedStateHash = loadedStateOperations.stateToHash(loadedState);
  switch (loadedState.type) {
    case LoadedStateType.TRAVERSAL:
    case LoadedStateType.TRAVERSAL_CREATED_IN_VIEW:
      dispatchAction(
        editViewpoint({
          loadedStateHash: loadedStateHash,
          context: 'editSubgraph',
        })
      );
      break;
    case LoadedStateType.SEARCH:
      dispatchAction(editComponentSearch(loadedStateHash));
      break;
    case LoadedStateType.CREATED_ITEMS:
      // this should never happen, editing created items is not possible and the button should not be available
      break;
    default:
      loadedState satisfies never;
  }
};

const setContextComponentForBlock = (
  loadedStateHash: LoadedStateHash,
  componentId: ArdoqId
) => {
  dispatchAction(
    setTraversalContextComponent({
      loadedStateHash,
      componentId,
    })
  );
};

const resetComponentSearchStream = () => {
  dispatchAction(resetAllSearchState());
};

const applyFiltersForContextSwitcher = ({
  componentTypeName,
  startSetFilter,
}: ApplyFiltersForContextSwitcherPayload) => {
  dispatchAction(
    applyFiltersForContextSwitcherAction({ startSetFilter, componentTypeName })
  );
};

const toggleCollapsedPath = (
  rule: PathCollapsingRule,
  loadedStateHash: LoadedStateHash
) => dispatchAction(toggleCollapsedPathActive({ rule, loadedStateHash }));

export type ViewpointNavigatorCommands = {
  toggleItemVisibility: (loadedStateHash: LoadedStateHash) => void;
  removeLoadedState: ({
    loadedStateHash,
    loadedStatesCount,
    isTraversal,
    isSaved,
  }: {
    loadedStateHash: LoadedStateHash;
    loadedStatesCount: number;
    isTraversal: boolean;
    isSaved: boolean;
  }) => void;
  editBlock: (loadedState: LoadedState) => void;
  setContextComponentForBlock: (
    loadedStateHash: LoadedStateHash,
    componentId: ArdoqId
  ) => void;
  resetComponentSearchStream: () => void;
  applyFiltersForContextSwitcher: (
    payload: ApplyFiltersForContextSwitcherPayload
  ) => void;
  toggleCollapsedPath: (
    rule: PathCollapsingRule,
    loadedStateHash: LoadedStateHash
  ) => void;
};

export const viewpointNavigatorCommands: ViewpointNavigatorCommands = {
  toggleItemVisibility,
  removeLoadedState,
  editBlock,
  setContextComponentForBlock,
  resetComponentSearchStream,
  applyFiltersForContextSwitcher,
  toggleCollapsedPath,
};
