import { clamp } from 'lodash';

const takeFrom = <T>(
  inputData: T[],
  requestedNumberItemsToTake: number,
  fromIndex = 0
): T[] => {
  const numberItemsToTake = Math.min(
    requestedNumberItemsToTake,
    inputData.length
  );
  const toIndex = Math.min(fromIndex + numberItemsToTake, inputData.length);
  return inputData.slice(toIndex - numberItemsToTake, toIndex);
};

type CalculateDerivedStateParams = {
  dataSource: any[];
  height: number;
  rowHeight: number;
  scrollTop: number;
  prerender?: boolean;
};

export const calculateVirtualWindowParams = ({
  dataSource,
  height,
  rowHeight,
  scrollTop,
  prerender = false,
}: CalculateDerivedStateParams) => {
  const visibleNumberOfRows = Math.ceil(height / rowHeight);
  const numberOfRowsToRender = visibleNumberOfRows * (prerender ? 3 : 1);
  const showFromIndex = Math.min(
    Math.max(0, dataSource.length - visibleNumberOfRows),
    Math.floor(scrollTop / rowHeight)
  );

  const innerTop = prerender
    ? Math.min(
        Math.max(0, showFromIndex - visibleNumberOfRows),
        Math.max(0, dataSource.length - numberOfRowsToRender)
      ) * rowHeight
    : showFromIndex * rowHeight;

  const renderFromIndex = prerender
    ? clamp(showFromIndex - visibleNumberOfRows, 0, showFromIndex)
    : showFromIndex;
  const partialDataSource = takeFrom(
    dataSource,
    numberOfRowsToRender,
    renderFromIndex
  );

  const innerHeight = dataSource.length * rowHeight - innerTop;

  return {
    innerTop,
    innerHeight,
    partialDataSource,
  };
};
