import { ArrowType } from '@ardoq/api-types';
import { DiffType } from '@ardoq/data-model';
import { EdgePath, HoverPath } from '@ardoq/graph';
import { GRAPH_EDGE_ID } from '../../consts';
import { colors } from '@ardoq/design-tokens';
import styled from 'styled-components';

// Copied from AggregatedGraphEdge.ts, but we're not using AggregatedGraphEdge, but GraphEdge

const ShortestPath = styled.path`
  fill: none;
  stroke-width: 16;
  transition: opacity 0.3s;
  pointer-events: none;
`;
interface EdgeProperties {
  pathData: string;
  hoverPathData: string;
  lineBeginning: ArrowType;
  lineEnding: ArrowType;
  stroke: string;
  strokeDasharray: string;
  graphId: string;
  isSelected: boolean;
  isInSelectedPath: boolean;
  opacity?: number;
  hasCollapsingRuleError?: boolean;
}
const Edge = ({
  pathData,
  hoverPathData,
  lineBeginning,
  lineEnding,
  stroke,
  strokeDasharray,
  graphId,
  isSelected,
  isInSelectedPath,
  opacity,
  hasCollapsingRuleError,
}: EdgeProperties) => {
  return (
    <g {...{ [GRAPH_EDGE_ID]: graphId }} opacity={opacity}>
      <HoverPath
        $isSelected={isSelected}
        d={hoverPathData}
        // we don't use diff type, it's here only to make typescript happy
        $diffType={DiffType.NONE}
        stroke={stroke}
        color={stroke}
        fill="none"
        pointerEvents="stroke"
      />
      <ShortestPath
        d={pathData}
        stroke={hasCollapsingRuleError ? colors.red80 : colors.blue80}
        opacity={isInSelectedPath ? 1 : 0}
      />
      <EdgePath
        color={isInSelectedPath ? colors.grey35 : stroke}
        pointerEvents="stroke"
        stroke={isInSelectedPath ? colors.grey35 : stroke}
        strokeWidth="2"
        d={pathData}
        $diffType={DiffType.NONE}
        strokeDasharray={strokeDasharray}
        markerStart={`url(#${lineBeginning})`}
        markerEnd={`url(#${lineEnding})`}
      />
    </g>
  );
};
export default Edge;
