import {
  notifyTagAdded,
  notifyTagRemoved,
  notifyTagUpdated,
} from 'streams/tags/TagActions';
import { action$, ActionCreator, derivedStream, ofType } from '@ardoq/rxbeach';
import { debounceTime, map, shareReplay, startWith } from 'rxjs/operators';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsSynced,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
  notifyFieldUpdated,
} from 'streams/fields/FieldActions';
import { notifyFiltersChanged } from 'streams/filters/FilterActions';
import {
  notifyGroupingAdded,
  notifyGroupingRemoved,
  notifyGroupingUpdated,
} from 'streams/grouping/GroupingActions';
import { startAction } from 'actions/utils';
import graphModel$ from './graphModel$';
import { notifyWorkspaceModelChanged } from 'streams/workspaces/actions';
import { DEFAULT_DEBOUNCE_TIME } from './consts';
import { notifyModelChanged } from 'streams/models/actions';
import { notifyLayoutConstraintsUpdated } from 'streams/layoutConstraints/LayoutConstraintActions';

export const modelUpdateActions: ActionCreator<any>[] = [
  notifyComponentsAdded,
  notifyComponentsUpdated,
  notifyComponentsRemoved,
  notifyComponentsSynced,
  notifyFieldRemoved,
  notifyFieldUpdated,
  notifyFieldAdded,
  notifyTagAdded,
  notifyTagRemoved,
  notifyTagUpdated,
  notifyGroupingRemoved,
  notifyGroupingAdded,
  notifyGroupingUpdated,
  notifyFiltersChanged,
  notifyWorkspaceModelChanged,
  notifyModelChanged,
  notifyLayoutConstraintsUpdated,
];
const modelUpdate$ = action$.pipe(
  ofType(...modelUpdateActions),
  startWith(startAction()),
  debounceTime(DEFAULT_DEBOUNCE_TIME)
);

const modelUpdateNotification$ = derivedStream(
  'modelUpdateNotification$',
  modelUpdate$,
  graphModel$
).pipe(
  map(() => null),
  shareReplay({ bufferSize: 1, refCount: true })
);

export default modelUpdateNotification$;

export const modelUpdateNotificationWithoutGraph$ = modelUpdate$.pipe(
  map(() => null),
  shareReplay({ bufferSize: 1, refCount: true })
);
