import { action$, extractPayload, ofType, withNamespace } from '@ardoq/rxbeach';
import { setFilterTerm } from 'appContainer/MainAppModule/MainAppModuleSidebar/actions';
import { identity } from 'lodash';
import { debounceTime } from 'rxjs';

export const getFilterTermStream = (namespace?: string) =>
  action$.pipe(
    namespace ? withNamespace(namespace) : identity,
    ofType(setFilterTerm),
    extractPayload(),
    debounceTime(16)
  );
