import { APIDiscoverViewpointAttributes } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { viewpointSaveError, viewpointSaveSuccess } from './actions';
import { mapAPIResponse } from './utils';
import { trackEvent } from 'tracking/tracking';
import { viewpointApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';

export const saveExistingViewpoint = async (
  viewpoint: APIDiscoverViewpointAttributes
) => {
  const result = await viewpointApi.update(viewpoint);
  if (isArdoqError(result)) {
    dispatchAction(viewpointSaveError());
    return result;
  }
  dispatchAction(viewpointSaveSuccess(mapAPIResponse(result)));
  trackEvent('Updated viewpoint');
  return result;
};
