import { api, permissionApi, reportApi } from '@ardoq/api';
import {
  APIOrganizationUser,
  APIResourcePermissionAttributes,
  ArdoqId,
  GroupResourcePermission,
  PermissionGroup,
  Report,
  ResourceType,
} from '@ardoq/api-types';
import {
  ExcludeFalsy,
  isArdoqError,
  isJust,
  Maybe,
  Result,
  toArdoqError,
} from '@ardoq/common-helpers';
import {
  ENTIRE_ORGANIZATION_GROUP_LOOK_UP_ID,
  getContributorGroupRole,
  getMemberGroupRole,
  getPermissionsRole,
} from '@ardoq/manage-resource-permissions';
import {
  openShareReportModal,
  patchExistingReportPermissions,
  reportBuilderOperations,
  ReportBuilderWithSubdivisionsPermissions,
} from '@ardoq/report-builder';
import permissionGroup$ from 'admin/accessControl/PermissionGroups/streams/permissionGroups$';
import {
  prepareResourceGroupsPermissions,
  prepareResourceUserPermissions,
} from 'resourcePermissions/viewModel$';
import { mergeMap, switchMap, tap, withLatestFrom } from 'rxjs';
import currentUser$, {
  CurrentUserState,
} from 'streams/currentUser/currentUser$';
import reports$ from 'streams/reports/reports$';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  exportToExcelButtonWasClicked,
  exportToReadableExcelButtonWasClicked,
  shareReportDialogLoadingFinished,
  shareReportSelected,
} from './actions';
import { updatePermissionsAndShowToast } from './utils';
import { keyBy, uniq } from 'lodash';
import { permissionsOperations } from '@ardoq/access-control';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { openChatWithUs } from 'components/AppMainSidebar/utils';
import {
  fetchPermissionGroups,
  setError as setPermissionGroupsError,
  setPermissionGroups,
} from '../admin/accessControl/PermissionGroups/streams/actions';
import { dispatchActionAndWaitForResponse } from '../actions/utils';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { ENTIRE_ORGANIZATION_GROUP } from '../subdivisionEditor/Steps/permissionsConfiguration/zones/groups$/types';
import { trackEvent } from 'tracking/tracking';
import { excelExportFailed } from './ReportReader/actions';
import { exportToExcel, exportToReadableExcel } from './ReportReader/utils';

const prepareGroupsForReportSharing = ({
  report,
  permissionsByZoneId,
  groupsById,
  orgLabel,
}: {
  report: Maybe<Report>;
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>;
  groupsById: Record<ArdoqId, PermissionGroup>;
  orgLabel: string;
}): {
  allPermissionGroups: GroupResourcePermission[];
  groupsWithFullReportDataAccess: ArdoqId[];
} => {
  if (!isJust(report))
    return {
      allPermissionGroups: [],
      groupsWithFullReportDataAccess: [],
    };

  const allPermissionGroups: GroupResourcePermission[] = Object.values(
    groupsById
  ).map(group => ({
    _id: group._id,
    name: getPermissionsRole(orgLabel, group.label),
    permissions: [], // unused in this context
    displayName: group.name,
    memberCount: group.users?.length, // users can be undefined only for "Everyone" group
  }));

  const { groupsAbleToShareWith: availablePermissionGroups } =
    reportBuilderOperations.getGroupShareStatusFromZoneIdList(
      reportBuilderOperations.getPermissionZoneIds(report),
      permissionsByZoneId
    );

  return {
    allPermissionGroups,
    groupsWithFullReportDataAccess: availablePermissionGroups
      .map(group => group._id)
      .filter(ExcludeFalsy),
  };
};

const prepareUsersForReportSharing = ({
  report,
  groupsWithFullReportDataAccess,
  groupsById,
  usersById,
}: {
  report: Maybe<Report>;
  groupsWithFullReportDataAccess: ArdoqId[];
  groupsById: Record<ArdoqId, PermissionGroup>;
  usersById: Record<ArdoqId, APIOrganizationUser>;
}): {
  usersWithFullReportDataAccess: ArdoqId[];
} => {
  if (!isJust(report))
    return {
      usersWithFullReportDataAccess: [],
    };

  const allowedUsers = groupsWithFullReportDataAccess.includes(
    ENTIRE_ORGANIZATION_GROUP_LOOK_UP_ID
  )
    ? Object.keys(usersById) // allow to select all users if "everyone" group has access to all report data
    : uniq(
        groupsWithFullReportDataAccess
          .flatMap(groupId => {
            const group = groupsById[groupId];
            if (group) return group.users;
          })
          .filter(ExcludeFalsy)
      );

  return {
    usersWithFullReportDataAccess: allowedUsers,
  };
};

const preparePermissions = async ({
  reportId,
  usersById,
  currentUser,
  groupsById,
}: {
  reportId: ArdoqId;
  usersById: Record<ArdoqId, APIOrganizationUser>;
  currentUser: CurrentUserState;
  groupsById: Record<ArdoqId, PermissionGroup>;
}): Promise<Result<ReportBuilderWithSubdivisionsPermissions>> => {
  const permissions = await permissionApi.getResourcePermission({
    resourceId: reportId,
    resourceType: ResourceType.REPORT,
  });

  if (isArdoqError(permissions)) {
    return permissions;
  }

  const orgLabel = currentUser.organization.label;

  permissions.permissions = prepareResourceUserPermissions(
    permissions.permissions,
    ResourceType.REPORT,
    usersById,
    currentUser._id
  ).map(permission => ({
    ...permission,
  }));

  permissions.groups = prepareResourceGroupsPermissions(
    permissions.groups ?? [],
    ResourceType.REPORT,
    groupsById,
    getContributorGroupRole(orgLabel),
    getMemberGroupRole(orgLabel)
  ).map(permission => ({
    ...permission,
  }));

  return permissions as ReportBuilderWithSubdivisionsPermissions;
};

const getPermissionsByZoneId = async (): Promise<
  Result<Record<ArdoqId, APIResourcePermissionAttributes>>
> => {
  const permissions = await permissionApi.fetchPermissionsForAllZones();

  return isArdoqError(permissions)
    ? permissions
    : keyBy(permissions, 'resource');
};

const fetchAndWaitForPermissionGroups = mergeMap(async (reportId: ArdoqId) => {
  await dispatchActionAndWaitForResponse(
    fetchPermissionGroups(),
    setPermissionGroups,
    setPermissionGroupsError
  );
  return reportId;
});

const UNEXPECTED_ERROR_MESSAGE =
  "There was a technical issue, and we're unable to load the data. Please check you connection and try reloading.";

const openShareReportModalRoutine = routine(
  ofType(shareReportSelected),
  extractPayload(),
  fetchAndWaitForPermissionGroups,
  withLatestFrom(
    reports$,
    currentUser$,
    permissionGroup$,
    orgUsers$,
    currentUserPermissionContext$
  ),
  switchMap(
    async ([
      reportId,
      reportsStatus,
      currentUser,
      { groupsById: groups },
      { byId: usersById, users: allUsers },
      permissionContext,
    ]) => {
      const reloadModal = () => dispatchAction(shareReportSelected(reportId));

      const report = reportBuilderOperations.fromCollection(
        reportsStatus.byId,
        reportId
      );

      const isReportNotFound = report === null;

      if (
        isReportNotFound ||
        !permissionsOperations.isLoadedPermissionContext(permissionContext)
      ) {
        return {
          openCustomerSupport: openChatWithUs,
          reloadModal,
          error: toArdoqError({
            error: new Error(
              isReportNotFound
                ? 'Report not found in collection'
                : 'Unable to determine your permissions'
            ),
            userMessage: UNEXPECTED_ERROR_MESSAGE,
          }),
        };
      }

      const groupsById = {
        ...groups,
        [ENTIRE_ORGANIZATION_GROUP_LOOK_UP_ID]: {
          ...ENTIRE_ORGANIZATION_GROUP,
          _id: ENTIRE_ORGANIZATION_GROUP_LOOK_UP_ID,
        },
      };

      const existingPermissions = await preparePermissions({
        reportId,
        usersById,
        groupsById,
        currentUser,
      });
      if (isArdoqError(existingPermissions)) {
        return {
          openCustomerSupport: openChatWithUs,
          reloadModal,
          error: existingPermissions,
        };
      }

      const permissionsByZoneId = await getPermissionsByZoneId();
      if (isArdoqError(permissionsByZoneId)) {
        return {
          openCustomerSupport: openChatWithUs,
          reloadModal,
          error: permissionsByZoneId,
        };
      }

      const { allPermissionGroups, groupsWithFullReportDataAccess } =
        prepareGroupsForReportSharing({
          report,
          groupsById,
          permissionsByZoneId,
          orgLabel: currentUser.organization.label,
        });

      const { usersWithFullReportDataAccess } = prepareUsersForReportSharing({
        report,
        groupsWithFullReportDataAccess,
        groupsById,
        usersById,
      });

      return {
        report,
        updatePermissions: updatePermissionsAndShowToast,
        existingPermissions:
          patchExistingReportPermissions(existingPermissions),
        allPermissionGroups,
        groupsWithFullReportDataAccess,
        currentUser,
        allUsers,
        usersWithFullReportDataAccess,
        trackingEventProvider: trackEvent,
      };
    }
  ),
  tap(openShareReportModal),
  tap(() => dispatchAction(shareReportDialogLoadingFinished()))
);

const exportToExcelRoutine = routine(
  ofType(exportToExcelButtonWasClicked),
  extractPayload(),
  tap(() => dispatchAction(excelExportFailed(false))),
  switchMap(({ id, filterQuery, reportName }) =>
    reportApi.exportReport(id, { filters: filterQuery }).then(response => ({
      response,
      reportName,
    }))
  ),
  tap(({ response }) => api.logErrorIfNeeded(response)),
  tap(({ response, reportName }) => {
    if (isArdoqError(response)) {
      dispatchAction(excelExportFailed(true));
    } else {
      exportToExcel(response, reportName);
    }
  })
);

const exportToReadableExcelRoutine = routine(
  ofType(exportToReadableExcelButtonWasClicked),
  extractPayload(),
  tap(() => dispatchAction(excelExportFailed(false))),
  switchMap(async ({ id, filterQuery, reportName }) => {
    const response = await reportApi.exportReportExcel(id, {
      filters: filterQuery,
    });
    return { response, reportName };
  }),
  tap(({ response }) => api.logErrorIfNeeded(response)),
  tap(({ response, reportName }) => {
    if (isArdoqError(response)) {
      dispatchAction(excelExportFailed(true));
    } else {
      exportToReadableExcel(response, reportName);
    }
  })
);

export const reportRoutines = collectRoutines(
  openShareReportModalRoutine,
  exportToExcelRoutine,
  exportToReadableExcelRoutine
);
