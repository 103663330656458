import { ConfirmConfig, alert, confirm } from '@ardoq/modal';
import {
  DontShowAgain,
  getDontShowAgainSetting,
  setDontShowAgainSetting,
} from 'models/dontShowAgainSettings';

type ConfirmDialogWithRememberActionProps = {
  hideCancelButton?: boolean;
  rememberActionLabel?: string;
  dontShowAgainSettingKey: DontShowAgain;
} & ConfirmConfig;

const dialogWithCheckbox = (
  options: Omit<
    ConfirmDialogWithRememberActionProps,
    'dontShowAgainSettingKey' | 'onConfirmAsync'
  >
) =>
  (options.hideCancelButton
    ? alert({
        ...options,
        showRememberActionCheckbox: true,
      })
    : confirm({
        ...options,
        showRememberActionCheckbox: true,
      })) as Promise<
    | false
    | {
        rememberAction: boolean;
        confirmed: boolean;
      }
  >;

export const showDialogWithRememberAction = async ({
  dontShowAgainSettingKey,
  ...options
}: ConfirmDialogWithRememberActionProps) => {
  const dontShowDialog = await getDontShowAgainSetting(dontShowAgainSettingKey);
  if (dontShowDialog) return true;
  const { rememberAction, confirmed } = (await dialogWithCheckbox(options)) || {
    rememberAction: false,
    confirmed: false,
  };
  if (confirmed && rememberAction) {
    await setDontShowAgainSetting(dontShowAgainSettingKey, true);
  }
  return confirmed;
};
