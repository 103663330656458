import { AggregatedReferenceType, GraphModelShape } from '@ardoq/data-model';
import { uniq } from 'lodash';
import { ArdoqId, GroupType, GroupingRule } from '@ardoq/api-types';
import {
  GraphItem,
  RawGraphItem,
  type TraverseOptions,
  traverseGraph,
} from '@ardoq/graph';
import type { GraphInterface } from '@ardoq/graph';

type GetReferenceTypesArgs = {
  graphInterface: GraphInterface;
  startSet: ArdoqId[];
  graph: GraphModelShape;
  traverseOptions: TraverseOptions;
};

type GetReferenceTypes = (getReferenceTypesArgs: GetReferenceTypesArgs) => {
  traversedOutgoingReferenceTypes: AggregatedReferenceType[];
  traversedIncomingReferenceTypes: AggregatedReferenceType[];
};

export const getReferenceTypesOverwrittenOptions = Object.freeze({
  outgoingReferenceTypes: null,
  incomingReferenceTypes: null,
  disableAllFilters: true,
});

export const getReferenceTypes: GetReferenceTypes = ({
  graphInterface,
  startSet,
  graph,
  traverseOptions = {
    maxDegreesIncoming: 0,
    maxDegreesOutgoing: 0,
    maxDegrees: 0,
    isParentRelationAsReference: false,
    outgoingReferenceTypes: null,
    incomingReferenceTypes: null,
    disableAllFilters: true,
  },
}) => {
  const { traversedOutgoingReferenceTypes, traversedIncomingReferenceTypes } =
    traverseGraph(graphInterface, startSet, graph, {
      ...traverseOptions,
      ...getReferenceTypesOverwrittenOptions,
    });
  return { traversedOutgoingReferenceTypes, traversedIncomingReferenceTypes };
};

const mergeListMaps = <Key, Value>(
  target: Map<Key, Value[]>,
  source: Map<Key, Value[]>
): Map<Key, Value[]> => {
  Array.from(source).forEach(([key, list]) => {
    target.set(key, [...(target.get(key) || []), ...list]);
  });
  return target;
};

const mergeMaps = <Key, Value>(
  target: Map<Key, Value>,
  source: Map<Key, Value>
): Map<Key, Value> => {
  Array.from(source).forEach(([key, value]) => {
    target.set(key, value);
  });
  return target;
};

const mergeReferenceTypes = (
  target: AggregatedReferenceType[],
  source: AggregatedReferenceType[]
) => {
  source.forEach(typeDict => {
    const targetTypeDict = target.find(({ name }) => typeDict.name === name);
    if (targetTypeDict) {
      targetTypeDict.typeIds = uniq([
        ...targetTypeDict.typeIds,
        ...typeDict.typeIds,
      ]);
    } else {
      target.push(typeDict);
    }
  });
  return target;
};

export const combineGraphs = (
  ...graphs: GraphModelShape[]
): GraphModelShape => {
  const combinedGraph = graphs.reduce<GraphModelShape>(
    (accGraph, graph) => ({
      sourceMap: mergeListMaps(accGraph.sourceMap, graph.sourceMap),
      targetMap: mergeListMaps(accGraph.targetMap, graph.targetMap),
      referenceMap: mergeMaps(accGraph.referenceMap, graph.referenceMap),
      referenceTypes: mergeReferenceTypes(
        accGraph.referenceTypes,
        graph.referenceTypes
      ),
    }),
    {
      sourceMap: new Map(),
      targetMap: new Map(),
      referenceMap: new Map(),
      referenceTypes: [],
    }
  );
  combinedGraph.sourceMap.forEach(list => Object.freeze(list));
  combinedGraph.targetMap.forEach(list => Object.freeze(list));
  combinedGraph.referenceMap.forEach(list => Object.freeze(list));
  Object.freeze(combinedGraph.referenceTypes);
  return combinedGraph;
};

type ShortestPathsArgs = {
  graph: GraphModelShape;
  targetComponentIds: Set<ArdoqId>;
};

type GetShortestPathsArgs = {
  componentId: ArdoqId;
} & ShortestPathsArgs;

type GetAllShortestPathsArgs = {
  componentIds: ArdoqId[];
  visited: Map<ArdoqId, ArdoqId | null>;
} & ShortestPathsArgs;

type ShortestPathsResult = {
  components: RawGraphItem[];
  references: RawGraphItem[];
};

export const getShortestPaths = ({
  componentId,
  graph,
  targetComponentIds,
}: GetShortestPathsArgs): ShortestPathsResult | null =>
  getAllShortestPaths({
    componentIds: [componentId],
    graph,
    targetComponentIds,
    visited: new Map([[componentId, null]]),
  });

const getAllShortestPaths = ({
  componentIds,
  graph,
  visited,
  targetComponentIds,
}: GetAllShortestPathsArgs): ShortestPathsResult | null => {
  if (componentIds.length === 0) {
    return null;
  }

  const connectedComponentIds = componentIds.filter(componentId =>
    targetComponentIds.has(componentId)
  );

  if (connectedComponentIds.length > 0) {
    return connectedComponentIds.reduce(
      (result, componentId) => {
        const { components, references } = buildPath({
          componentId,
          graph,
          visited,
        });
        return {
          components: [...result.components, ...components],
          references: [...result.references, ...references],
        };
      },
      { components: [], references: [] } as ShortestPathsResult
    );
  }

  const nextDepth: ArdoqId[] = componentIds.flatMap(componentId => {
    const linkedComponents = [
      ...(graph.sourceMap.get(componentId) || []),
      ...(graph.targetMap.get(componentId) || []),
    ];

    return linkedComponents.reduce<ArdoqId[]>(
      (nextDepth, { referenceId, componentId }) => {
        if (visited.has(componentId)) {
          return nextDepth;
        }

        visited.set(componentId, referenceId);
        return [...nextDepth, componentId];
      },
      []
    );
  });

  return getAllShortestPaths({
    componentIds: nextDepth,
    graph,
    visited,
    targetComponentIds,
  });
};

type BuildPathsArgs = {
  componentId: ArdoqId;
  graph: GraphModelShape;
  visited: Map<ArdoqId, ArdoqId | null>;
  result?: ShortestPathsResult;
};

const buildPath = ({
  componentId,
  graph,
  visited,
  result = { components: [], references: [] },
}: BuildPathsArgs): ShortestPathsResult => {
  const referenceId = visited.get(componentId);
  if (!referenceId) {
    return result;
  }

  const { sourceId, targetId } = graph.referenceMap.get(referenceId)!;

  result.references.push({
    id: referenceId,
    modelId: referenceId,
    sourceId,
    targetId,
  });

  const linkedComponentId = sourceId === componentId ? targetId : sourceId;

  result.components.push({
    id: linkedComponentId,
    modelId: linkedComponentId,
  });

  return buildPath({
    componentId: linkedComponentId,
    graph,
    visited,
    result,
  });
};

export const isDescendant = ({
  parentId,
  item,
}: {
  parentId: string;
  item: GraphItem;
}): boolean =>
  !item.parent
    ? false
    : item.parent.id === parentId ||
      isDescendant({ parentId, item: item.parent });

export const hasGroupByParentAll = (groupingRules: GroupingRule[]) =>
  groupingRules.some(({ type }) => type === GroupType.PARENT_ALL);
