import { ExcludeFalsy } from '@ardoq/common-helpers';
import { APIReferenceAttributes, ChangeRequestAction } from '@ardoq/api-types';
import { Column, ExpandableRowData } from '@ardoq/table';
import ReferenceAttributesTable from './ReferenceAttributesTable';
import ComponentNameAndIcon from './ComponentNameAndIcon';
import { getReferenceTypeByReferenceId } from '@ardoq/renderers';
import { dispatchAction } from '@ardoq/rxbeach';
import { SwitchPosition, TriStateSwitch } from '@ardoq/snowflakes';
import { setApprovedReferenceData } from '../actions';
import {
  RecordOfReferences,
  ComponentValidation,
  ApprovalStatus,
  ChangeApprovalRowData,
  ReferenceRow,
  ReferenceTableRow,
} from '../types';
import { isEqual } from 'lodash';
import { AnimatedChevron } from '@ardoq/icons';
import { SmallGhostButton } from '@ardoq/button';
import { colors } from '@ardoq/design-tokens';
import { TextOverflow } from '@ardoq/popovers';
import UpdateTable from './UpdateTable';
import { FullWidthFlexBox, StyledColorBar } from './atoms';
import { isReferenceAttributeRow } from './utils';

const getSourceColumn = (
  changeAction: ChangeRequestAction,
  handleApprovedDataForSpecificField: (
    switchPosition: SwitchPosition,
    rowData: ChangeApprovalRowData
  ) => void
): Column<ExpandableRowData<ReferenceTableRow>> => ({
  title: 'Source',
  dataIndex: 'source',
  headerStyle: { padding: 0, width: '20%' },
  getColSpan: (_v, rowData) => (!isReferenceAttributeRow(rowData) ? 0 : 5),
  valueRender: (_any: any, rowData) => {
    if (isReferenceAttributeRow(rowData))
      return changeAction === 'update' ? (
        <FullWidthFlexBox>
          <StyledColorBar $barColor={colors.blue50} />
          <UpdateTable
            dataSource={rowData.dataSource}
            handleApprovedDataForSpecificField={
              handleApprovedDataForSpecificField
            }
          />
        </FullWidthFlexBox>
      ) : (
        <ReferenceAttributesTable reference={rowData.reference} />
      );
    const sourceComponent =
      rowData.scopeData.componentsById[rowData.reference.source];
    return (
      <ComponentNameAndIcon
        scopeData={rowData.scopeData}
        component={sourceComponent}
      />
    );
  },
});

const getTypeColumn = (): Column<ExpandableRowData<ReferenceRow>> => ({
  title: 'Type',
  dataIndex: 'type',
  headerStyle: { padding: 0, width: '20%' },
  valueRender: (_any: any, rowData) => {
    if (isReferenceAttributeRow(rowData)) return;
    const referenceType = getReferenceTypeByReferenceId(
      rowData._id,
      rowData.scopeData
    );
    return (
      <TextOverflow style={{ maxWidth: 200 }}>
        {referenceType?.name}
      </TextOverflow>
    );
  },
});

const getTargetColumn = (): Column<ExpandableRowData<ReferenceRow>> => ({
  title: 'Target',
  dataIndex: 'target',
  headerStyle: { padding: 0, width: '20%' },
  valueRender: (_any, rowData) => {
    if (isReferenceAttributeRow(rowData)) return;
    const targetComponent =
      rowData.scopeData.componentsById[rowData.reference.target];
    return (
      <ComponentNameAndIcon
        scopeData={rowData.scopeData}
        component={targetComponent}
      />
    );
  },
});

const getExpanderColumnConfig = (
  expandedRowIds: string[],
  expandRow: (path: string) => void
) => ({
  headerStyle: { padding: 0, width: '5%' },
  valueRender: (_any: any, rowData: ExpandableRowData<ReferenceRow>) => {
    if (isReferenceAttributeRow(rowData)) return;
    const { path } = rowData.meta;
    const isExpanded = expandedRowIds.includes(path);
    return (
      <SmallGhostButton onClick={() => expandRow(path)}>
        <AnimatedChevron $isUp={isExpanded} />
      </SmallGhostButton>
    );
  },
});

const getApprovalStatus = (
  reference: APIReferenceAttributes,
  approvedReferenceChanges: RecordOfReferences,
  componentValidationObject: ComponentValidation
): ApprovalStatus => {
  if (componentValidationObject.references?.[reference._id]) return 'pending';
  return isEqual(reference, approvedReferenceChanges[reference._id])
    ? 'approved'
    : 'rejected';
};

const getTriStateSwitchColumn = (
  changeAction: ChangeRequestAction,
  approvedReferenceChanges: RecordOfReferences,
  componentValidationObject: ComponentValidation
): Column<ExpandableRowData<ReferenceRow>> => ({
  headerStyle: { padding: 0, width: '10%' },
  valueRender: (_any, rowData) => {
    if (isReferenceAttributeRow(rowData) || changeAction === 'update') return;
    const status = getApprovalStatus(
      rowData.reference,
      approvedReferenceChanges,
      componentValidationObject
    );
    return (
      <TriStateSwitch
        dataTestId="approval-switch"
        name={rowData._id}
        value={status}
        onChange={switchPosition =>
          dispatchAction(
            setApprovedReferenceData({
              switchPosition,
              reference: rowData.reference,
            })
          )
        }
      />
    );
  },
});

export const getReferenceColumns = (
  changeAction: ChangeRequestAction,
  expandedRowIds: string[],
  approvedReferenceChanges: RecordOfReferences,
  componentValidationObject: ComponentValidation,
  handleApprovedDataForSpecificField: (
    switchPosition: SwitchPosition,
    rowData: ChangeApprovalRowData
  ) => void,
  expandRow: (path: string) => void
) =>
  [
    getSourceColumn(changeAction, handleApprovedDataForSpecificField),
    getTypeColumn(),
    getTargetColumn(),
    getExpanderColumnConfig(expandedRowIds, expandRow),
    getTriStateSwitchColumn(
      changeAction,
      approvedReferenceChanges,
      componentValidationObject
    ),
  ].filter(ExcludeFalsy);
