import { useRef } from 'react';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { useResizeObserver } from '@ardoq/hooks';
import { colors } from '@ardoq/design-tokens';
import { dispatchAction } from '@ardoq/rxbeach';
import { mainNavigatorConfiguration } from './mainNavigatorConfiguration';
import { Navigator } from 'components/WorkspaceHierarchies/components/Navigator';
import { containerHeightChanged } from 'components/WorkspaceHierarchies/actions/navigatorActions';

export const dispatchContainerHeightChanged = () =>
  dispatchAction(containerHeightChanged());

const debouncedHeightChanged = debounce(dispatchContainerHeightChanged, 16);

const Root = styled.div`
  background-color: ${colors.grey95};
  color: ${colors.grey25};
  flex: 2;
  overflow: auto;
  position: relative;
`;

const NavigatorRoot = () => {
  const rootRef = useRef<HTMLDivElement>(null);

  useResizeObserver(rootRef, () => {
    if (rootRef.current) {
      debouncedHeightChanged();
    }
  });

  const dragElementRoot =
    (document.querySelector('#appContentContainer') as HTMLElement) ??
    undefined;

  return (
    <Root id="navigator-root" ref={rootRef}>
      <Navigator
        dragElementRoot={dragElementRoot}
        navigatorConfiguration={mainNavigatorConfiguration}
      />
    </Root>
  );
};

export default NavigatorRoot;
