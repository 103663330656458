import { Button, ButtonProps } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { Box, FlexBox } from '@ardoq/layout';

function OptionalPopoverText({
  popoverText,
  children,
}: {
  popoverText?: string;
  children: JSX.Element;
}) {
  if (popoverText) {
    return (
      <WithPopover content={popoverText}>
        <span>{children}</span>
      </WithPopover>
    );
  }

  return children;
}

export interface StickyFooterProps {
  buttons?: {
    onClick: () => void;
    isDisabled: boolean;
    text: string;
    type: ButtonProps['buttonType'];
    iconName?: IconName;
    popoverText?: string;
  }[];
}
export function StickyFooter({ buttons }: StickyFooterProps) {
  if (!buttons || buttons?.length === 0) return null;
  return (
    <Box paddingRight="medium" backgroundColor="bgDefault">
      <FlexBox
        justify="end"
        padding="medium"
        backgroundColor="bgDefault"
        borderColor="borderAction"
        gap="medium"
        style={{ zIndex: 1 }}
      >
        {buttons.map((button, index) => (
          <OptionalPopoverText key={index} popoverText={button.popoverText}>
            <Button
              onClick={button.onClick}
              isDisabled={button.isDisabled}
              buttonType={button.type}
            >
              {button.text}
              {button.iconName && <Icon iconName={button.iconName} />}
            </Button>
          </OptionalPopoverText>
        ))}
      </FlexBox>
    </Box>
  );
}
