import styled, { css } from 'styled-components';
import { FiltersBar, LoadingWrapper } from '@ardoq/status-ui';
import { highlightCss } from 'utils/withHighlightStatusMap';
import { colors, s16, s32 } from '@ardoq/design-tokens';
import { FlyWheelTable } from '@ardoq/table';
import { LinkOverAssetName } from '../../components/EntityBrowser/atoms';

export const StatusMessage = styled.span<{ $disabled?: boolean }>`
  /* transitions is needed in order to match Button behaviour */
  transition: all 0.2s ease-out;
  display: inline-flex;
  align-items: center;
  color: #999;
  ${props =>
    props.$disabled &&
    css`
      opacity: 0;
    `};
`;

export const GhostWrapper = styled.div`
  background: ${colors.blue95};
  color: #000;
  border: 1px solid ${colors.blue50};
  pointer-events: none;
  position: fixed;
  border-radius: 5px;
  padding: 10px;
  top: 0;
  left: 0;
  z-index: 100000;
  min-width: 150px;
  text-align: center;
  box-shadow:
    0 0 2px 0 color-mix(in srgb, 12% rgba(0, 0, 0, 1), 88% transparent),
    0 2px 2px 0 color-mix(in srgb, 24% rgba(0, 0, 0, 1), 76% transparent);
`;

export const TableTag = styled(FlyWheelTable.Table)<{ $renameId?: string }>`
  ${({ $renameId }) =>
    !$renameId &&
    css`
      span {
        user-select: none;
        -webkit-appearance: none;
      }
      *::selection {
        background: transparent; /* WebKit/Blink Browsers */
      }
      *::-moz-selection {
        background: transparent; /* Gecko Browsers */
      }
    `}
`;

export const TrTag = styled(FlyWheelTable.Tr)<{
  $isDisabled: boolean;
  $isOver: boolean;
  $isPreview?: boolean;
}>`
  height: 40px;

  ${LinkOverAssetName} {
    visibility: hidden;
  }
  &:hover {
    ${LinkOverAssetName} {
      visibility: visible;
    }
  }

  ${highlightCss as any};
  ${props =>
    props.$isOver &&
    css`
      background: ${colors.blue95} !important;
    `}

  ${props =>
    props.$isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}

  ${props =>
    props.$isPreview &&
    css`
      background: #eee;
    `}
`;

export const DragTableHeaderWrapper = styled.div`
  z-index: 2;
  height: 121px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  width: 100%;
  position: absolute;
  background: #fff;
  border: 1px solid #ddd;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  padding: 10px;
  text-align: center;
  font-size: 1.2em;
  color: #546476;
  user-select: none;
  -webkit-appearance: none;

  &:hover {
    background: ${colors.blue95} !important;
    border: 1px solid ${colors.blue50};
  }
`;

export const DashboardFiltersBar = styled(FiltersBar)`
  padding: ${s16} ${s32};
  /* Not ideal to do the calculations here,
     but until we have new layout in place
     this is the best soultion I can see
     56px - width of the left nav bar*/
  max-width: calc(100vw - 56px);
`;

export const LoadingIndicator = styled(LoadingWrapper)`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 20;
  ${props =>
    props.loading &&
    css`
      pointer-events: none;
    `}
`;
