import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import {
  COMPONENT_ID_SELECTOR,
  GLOBAL_HANDLER_ID_ATTRIBUTE,
  REFERENCE_ID_SELECTOR,
} from 'consts';
import {
  GetContextMenuData,
  GetContextMenuOptionsArguments,
} from '@ardoq/context-menu';
import { selection$ } from 'streams/selection/selection$';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import getCommonMenuData from './getCommonMenuData';
import { findElement } from './utils';
import { ArdoqId } from '@ardoq/api-types';

const GLOBAL_HANDLER_ID_SELECTOR = `[${GLOBAL_HANDLER_ID_ATTRIBUTE}]`;

type GetModelIdsFromTargetElementArgs = {
  target: HTMLElement | SVGElement;
  selector: string;
  datasetAttributeName?: string;
  elementAttributeName?: string;
};
const getModelIdsFromTargetElement = ({
  target,
  selector,
  datasetAttributeName,
  elementAttributeName,
}: GetModelIdsFromTargetElementArgs) => {
  const elementWithModelId = findElement(selector, target);
  if (datasetAttributeName) {
    return elementWithModelId?.dataset[datasetAttributeName]?.split(',') ?? [];
  }

  const attributeValue = elementAttributeName
    ? elementWithModelId?.getAttribute(elementAttributeName)
    : null;
  return attributeValue ? [attributeValue] : [];
};

const getComponentIdFromTarget = (target: HTMLElement | SVGElement) =>
  getModelIdsFromTargetElement({
    target,
    selector: COMPONENT_ID_SELECTOR,
    datasetAttributeName: 'componentId',
  });

const getReferenceIdsFromTarget = (target: HTMLElement | SVGElement) =>
  getModelIdsFromTargetElement({
    target,
    selector: REFERENCE_ID_SELECTOR,
    datasetAttributeName: 'referenceModelIds',
  });

const getGlobalHandlerIdFromTarget = (target: HTMLElement | SVGElement) =>
  getModelIdsFromTargetElement({
    target,
    selector: GLOBAL_HANDLER_ID_SELECTOR,
    elementAttributeName: GLOBAL_HANDLER_ID_ATTRIBUTE,
  })[0];

const getContextMenuData: GetContextMenuData = ctxMenuArguments => {
  const { target } = ctxMenuArguments;

  const componentTargetIds = target ? getComponentIdFromTarget(target) : [];
  // if node matches both conditions (component & reference) in its selectors, then it is considered a component
  const referenceTargetIds =
    !componentTargetIds.length && target
      ? getReferenceIdsFromTarget(target)
      : [];
  return contextMenuData(
    componentTargetIds,
    referenceTargetIds,
    componentTargetIds[0] ??
      (referenceTargetIds.length || !target
        ? '' // we don't need the eventTargetModelId if it is a reference
        : getGlobalHandlerIdFromTarget(target)),
    ctxMenuArguments
  );
};
export const contextMenuData = (
  componentTargetIds: ArdoqId[],
  referenceTargetIds: ArdoqId[],
  eventTargetModelId: ArdoqId | '',
  ctxMenuArguments: GetContextMenuOptionsArguments
) => {
  const { state: selection } = selection$;
  const isTargetComponentSelected =
    selection.componentIds.includes(eventTargetModelId);

  const isTargetReferencesSelected = referenceTargetIds.some(id =>
    selection.referenceIds.includes(id)
  );

  // Need to include both selected components and selected references to display mixed entities context-menu
  // https://github.com/ardoq/ardoq-packages/pull/3049#discussion_r1063424490
  const isClickOnSelectedTarget =
    isTargetComponentSelected || isTargetReferencesSelected;

  const componentIds = (
    isClickOnSelectedTarget
      ? selection.componentIds
      : componentTargetIds.length
        ? componentTargetIds
        : [eventTargetModelId]
  ).filter(componentInterface.isComponent);

  const referenceIds = (
    isClickOnSelectedTarget ? selection.referenceIds : referenceTargetIds
  ).filter(referenceInterface.isReference);

  // in timelines, 'group' is part of the 'item', which uses component selectors
  const workspaceIds = [eventTargetModelId].filter(
    workspaceInterface.isWorkspace
  );

  return getCommonMenuData(ctxMenuArguments, {
    componentIds,
    referenceIds,
    workspaceIds,
    eventTargetModelId,
  });
};

export const objectContextMenu = {
  getContextMenuData,
};
