import { APIDictionariesResponse } from '@ardoq/api-types/integrations';
import { DictionaryCategoriesState } from './types';

export function apiDictionariesToState(
  dictionaries: APIDictionariesResponse
): Pick<DictionaryCategoriesState, 'categories' | 'allAttributes'> {
  const categories: DictionaryCategoriesState['categories'] = {};
  const allAttributes: DictionaryCategoriesState['allAttributes'] = [];
  dictionaries.dictionaryCategories.forEach(dictionary => {
    categories[dictionary.value] = {
      value: dictionary.value,
      label: dictionary.label,
      categoryAttributes: [],
    };
  });

  dictionaries.metaData
    .filter(metaData => metaData.value.startsWith('meta-'))
    .forEach(metaData => {
      allAttributes.push({
        value: metaData.value,
        label: metaData.label,
      });
      metaData.glossaryBindings.forEach(glossaryBinding => {
        categories[glossaryBinding.category].categoryAttributes.push({
          value: metaData.value,
          label: metaData.label,
        });
      });
    }, categories);
  return { categories, allAttributes };
}
