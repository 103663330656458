import { ExtractPayload, reducer } from '@ardoq/rxbeach';
import { SurveyAdminStreamState, SurveySettingsFormData } from './types';
import {
  saveSurveySettings,
  notifySavingSurveySettingsSucceeded,
  notifySavingSurveySettingsFailed,
  toggleSurveySettingsSidebar,
  setOrganizationLogo,
  setSurveySettingsSidebarData,
  setIsOrgLogoValid,
  fetchPendingApprovalsSummary,
  notifyFetchingPendingApprovalsFailed,
  notifyFetchingPendingApprovalsSucceeded,
  notifySavingSurveyFailed,
  saveSurvey,
  notifyFetchingSurveyValidationSucceeded,
  notifySavingSurveySucceeded,
  fetchSurveyValidation,
  notifyFetchingSurveyValidationFailed,
  saveNewSurvey,
  saveNewSurveyInFolder,
} from './actions';
import {
  SurveyAdminMode,
  SurveyOverviewSearchParams,
} from 'surveyAdmin/navigation/types';
import {
  selectSurveyMode,
  openNewSurvey,
  selectSurveyId,
  setSurveyOverviewSearchParams,
} from 'surveyAdmin/navigation/actions';
import { APISurveyPendingApprovalsSummary, ArdoqId } from '@ardoq/api-types';
import { SurveyValidation } from 'surveyAdmin/types';

const handleSelectSurveyMode = (
  state: SurveyAdminStreamState,
  { mode }: ExtractPayload<typeof selectSurveyMode>
): SurveyAdminStreamState => {
  return {
    ...state,
    mode,
  };
};

const handleOpenNewSurvey = (
  state: SurveyAdminStreamState,
  folderId: ExtractPayload<typeof openNewSurvey>
): SurveyAdminStreamState => ({
  ...state,
  mode: SurveyAdminMode.NEW_SURVEY,
  surveyId: null,
  folderId,
});

const handleSelectSurveyId = (
  state: SurveyAdminStreamState,
  { surveyId }: ExtractPayload<typeof selectSurveyId>
): SurveyAdminStreamState => ({
  ...state,
  surveyId,
});

const handleSetSurveyOverviewSearchParams = (
  state: SurveyAdminStreamState,
  surveyOverviewSearchParams: SurveyOverviewSearchParams | undefined
): SurveyAdminStreamState => ({
  ...state,
  surveyOverviewSearchParams,
});

const handleSaveSurveySettings = (
  state: SurveyAdminStreamState
): SurveyAdminStreamState => {
  return {
    ...state,
    surveySettings: {
      ...state.surveySettings,
      status: 'saving',
      errorMessage: undefined,
    },
  };
};

const handleSavingSurveySettingsSucceeded = (
  state: SurveyAdminStreamState
): SurveyAdminStreamState => {
  return {
    ...state,
    surveySettings: {
      ...state.surveySettings,
      status: 'success',
      hasChanges: false,
      errorMessage: undefined,
    },
  };
};

const handleSavingSurveySettingsFailed = (
  state: SurveyAdminStreamState,
  errorMessage: string
): SurveyAdminStreamState => {
  return {
    ...state,
    surveySettings: {
      ...state.surveySettings,
      status: 'error',
      errorMessage,
    },
  };
};

const handleSurveySettingsSidebar = (
  state: SurveyAdminStreamState
): SurveyAdminStreamState => {
  return {
    ...state,
    surveySettings: {
      ...state.surveySettings,
      isSidebarOpen: !state.surveySettings.isSidebarOpen,
    },
  };
};

const handleSetOrganizationLogo = (
  state: SurveyAdminStreamState,
  organizationLogo: string
): SurveyAdminStreamState => {
  const isEmptyLogo = organizationLogo.length === 0;

  return {
    ...state,
    surveySettings: {
      ...state.surveySettings,
      organizationLogo,
      isOrgLogoValid: state.surveySettings.isOrgLogoValid || isEmptyLogo,
      hasChanges: true,
      errorMessage: undefined,
    },
  };
};

const handleSetSurveySettingsSidebarData = (
  state: SurveyAdminStreamState,
  surveySettingsFormData: SurveySettingsFormData
): SurveyAdminStreamState => {
  return {
    ...state,
    surveySettings: {
      ...state.surveySettings,
      ...surveySettingsFormData,
      errorMessage: undefined,
    },
  };
};

const handleSetIsOrgLogoValid = (
  state: SurveyAdminStreamState,
  isOrgLogoValid: boolean
): SurveyAdminStreamState => {
  return {
    ...state,
    surveySettings: {
      ...state.surveySettings,
      isOrgLogoValid,
    },
  };
};

const handleSurveySaveError = (
  state: SurveyAdminStreamState
): SurveyAdminStreamState => {
  return {
    ...state,
    isSaving: false,
    changesSaved: false,
  };
};

const handleFetchingPendingApprovalsSummary = (
  state: SurveyAdminStreamState
): SurveyAdminStreamState => {
  return {
    ...state,
    pendingApprovalFetchingStatus: 'loading',
  };
};

const handleSurveyWithPendingApprovalsFetchSuccess = (
  state: SurveyAdminStreamState,
  surveysWithPendingApprovals: APISurveyPendingApprovalsSummary
): SurveyAdminStreamState => {
  return {
    ...state,
    surveysWithPendingApprovals,
    pendingApprovalFetchingStatus: 'success',
  };
};

const handleSurveyWithPendingApprovalsFetchFailure = (
  state: SurveyAdminStreamState
): SurveyAdminStreamState => {
  return {
    ...state,
    pendingApprovalFetchingStatus: 'error',
  };
};

const handleFetchingSurveyValidation = (
  state: SurveyAdminStreamState
): SurveyAdminStreamState => {
  return {
    ...state,
    surveyValidationFetchingStatus: 'loading',
  };
};

const handleSurveyValidationFetchSuccess = (
  state: SurveyAdminStreamState,
  surveyValidation: Record<ArdoqId, SurveyValidation>
): SurveyAdminStreamState => {
  return {
    ...state,
    surveyValidation,
    surveyValidationFetchingStatus: 'success',
  };
};

const handleSurveyValidationFetchFailure = (
  state: SurveyAdminStreamState
): SurveyAdminStreamState => {
  return {
    ...state,
    surveyValidationFetchingStatus: 'error',
  };
};

const handleSurveyIsSaving = (
  state: SurveyAdminStreamState
): SurveyAdminStreamState => {
  return {
    ...state,
    isSaving: true,
  };
};

const handleSurveySaveSuccess = (
  state: SurveyAdminStreamState
): SurveyAdminStreamState => {
  return {
    ...state,
    isSaving: false,
    changesSaved: true,
  };
};

export const reducers = [
  reducer(saveSurvey, handleSurveyIsSaving),
  reducer(saveNewSurvey, handleSurveyIsSaving),
  reducer(saveNewSurveyInFolder, handleSurveyIsSaving),
  reducer(notifySavingSurveySucceeded, handleSurveySaveSuccess),
  reducer(notifySavingSurveyFailed, handleSurveySaveError),
  reducer(fetchPendingApprovalsSummary, handleFetchingPendingApprovalsSummary),
  reducer(
    notifyFetchingPendingApprovalsSucceeded,
    handleSurveyWithPendingApprovalsFetchSuccess
  ),
  reducer(
    notifyFetchingPendingApprovalsFailed,
    handleSurveyWithPendingApprovalsFetchFailure
  ),
  reducer(selectSurveyMode, handleSelectSurveyMode),
  reducer(openNewSurvey, handleOpenNewSurvey),
  reducer(selectSurveyId, handleSelectSurveyId),
  reducer(setSurveyOverviewSearchParams, handleSetSurveyOverviewSearchParams),
  reducer(saveSurveySettings, handleSaveSurveySettings),
  reducer(
    notifySavingSurveySettingsSucceeded,
    handleSavingSurveySettingsSucceeded
  ),
  reducer(notifySavingSurveySettingsFailed, handleSavingSurveySettingsFailed),
  reducer(toggleSurveySettingsSidebar, handleSurveySettingsSidebar),
  reducer(setOrganizationLogo, handleSetOrganizationLogo),
  reducer(setSurveySettingsSidebarData, handleSetSurveySettingsSidebarData),
  reducer(setIsOrgLogoValid, handleSetIsOrgLogoValid),
  reducer(fetchSurveyValidation, handleFetchingSurveyValidation),
  reducer(
    notifyFetchingSurveyValidationSucceeded,
    handleSurveyValidationFetchSuccess
  ),
  reducer(
    notifyFetchingSurveyValidationFailed,
    handleSurveyValidationFetchFailure
  ),
];
