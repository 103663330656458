import { dispatchAction } from '@ardoq/rxbeach';
import { changeSearchKey } from './actions';

const updateSearchKey = (newKey: string) => {
  dispatchAction(changeSearchKey(newKey));
};

export const subdivisionSummaryViewModelCommands = {
  updateSearchKey,
};

export type SubdivisionSummaryViewModelCommands =
  typeof subdivisionSummaryViewModelCommands;
