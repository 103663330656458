import { PerspectivesReducerFn } from '../types';
import { streamReducer } from '@ardoq/rxbeach';
import activeView$, {
  ActiveViewState,
} from 'streams/views/mainContent/activeView$';

const activeViewIdReducerFn: PerspectivesReducerFn<ActiveViewState> = (
  state,
  { mainViewId }
) => ({
  ...state,
  activeViewId: mainViewId,
});

export const activeViewIdReducer = streamReducer(
  activeView$,
  activeViewIdReducerFn
);
