import _ from 'lodash/fp';
import { confirm } from '@ardoq/modal';
import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { SourceType } from '@ardoq/api-types/integrations';
import {
  DEFAULT_TRANSFER_CONFIG,
  INTEGRATIONS_WITH_TRANSFER_CONFIGS,
} from './constants';
import { IntegrationId, TabularMapping } from '../tabularMappings/types';
import {
  isEmptyTabularMapping,
  tabularMappingIntoTransferConfig,
} from '../tabularMappings/utils';
import {
  CloudProviderConfig,
  ExcelConfig,
  MicrosoftEntraIdConfig,
  SavedTransferConfig,
  ServiceNowConfig,
  SourceConfig,
  TransferConfig,
  TransferConfigsResponse,
  UnifiedSourceConfig,
} from '@ardoq/api-types/integrations';
import { integrationIdToSourceTypes } from '../activeIntegrations/sourceTypes';
import { TransferConfigsState } from './types';
import { pick } from 'lodash';

export const isSavedTransferConfig = (
  transferConfig: TransferConfig
): transferConfig is SavedTransferConfig => {
  return '_id' in transferConfig && '_version' in transferConfig;
};

const isJiraConfig = (
  config: SavedTransferConfig
): config is SavedTransferConfig<UnifiedSourceConfig> =>
  integrationIdToSourceTypes('jira-software').every((element: SourceType) =>
    config.sources.includes(element)
  );

export const isServiceNowConfig = (
  config: SavedTransferConfig
): config is SavedTransferConfig<ServiceNowConfig> =>
  integrationIdToSourceTypes('servicenow-v3').every((element: SourceType) =>
    config.sources.includes(element)
  );

export const isAzureConfig = (
  config: SavedTransferConfig
): config is SavedTransferConfig<CloudProviderConfig> =>
  integrationIdToSourceTypes('azure-v3').every((element: SourceType) =>
    config.sources.includes(element)
  );

export const isAwsConfig = (
  config: SavedTransferConfig
): config is SavedTransferConfig<CloudProviderConfig> =>
  integrationIdToSourceTypes('aws-v3').every((element: SourceType) =>
    config.sources.includes(element)
  );

export const isMicrosoftEntraIdConfig = (
  config: SavedTransferConfig
): config is SavedTransferConfig<MicrosoftEntraIdConfig> =>
  integrationIdToSourceTypes('microsoft-entra-id').every(
    (element: SourceType) => config.sources.includes(element)
  );

export const isExcelConfig = (
  config: SavedTransferConfig
): config is SavedTransferConfig<ExcelConfig> =>
  integrationIdToSourceTypes('excel-v3').every((element: SourceType) =>
    config.sources.includes(element)
  );

export const isConnectionUsedInSourceConfig = (
  config: SavedTransferConfig,
  connectionId: string
) => {
  if (isServiceNowConfig(config) || isJiraConfig(config)) {
    return connectionId === config.sourceConfig?.connectionId;
  }

  if (isAzureConfig(config) || isAwsConfig(config)) {
    return Boolean(config.sourceConfig?.accountIds.includes(connectionId));
  }

  return false;
};

export const filterBySourceTypes = (
  sourceTypes: SourceType[],
  configs: SavedTransferConfig[]
) =>
  _.filter((conf: SavedTransferConfig) => {
    return sourceTypes.every((element: SourceType) =>
      conf.sources.includes(element)
    );
  }, configs);

const getSelectedTransferConfig = (
  { configs }: TransferConfigsState,
  { selectedTransferConfigId }: ActiveIntegrationState
): SavedTransferConfig | undefined => {
  return configs.find(c => c._id === selectedTransferConfigId);
};

const getSelectedMappingConfig = (
  { configs }: TransferConfigsState,
  { selectedMappingConfigId }: ActiveIntegrationState
): SavedTransferConfig | undefined => {
  return configs.find(c => c._id === selectedMappingConfigId);
};

const getDefaultTransferConfig = ({ sourceTypes }: ActiveIntegrationState) => {
  return _.set('sources', sourceTypes, DEFAULT_TRANSFER_CONFIG);
};

export const getSavableTransferConfig = ({
  transferConfigs,
  activeIntegration,
  tabularMapping,
}: {
  transferConfigs: TransferConfigsState;
  activeIntegration: ActiveIntegrationState;
  tabularMapping: TabularMapping;
}): TransferConfig => {
  const transferConfig =
    getSelectedTransferConfig(transferConfigs, activeIntegration) ||
    getDefaultTransferConfig(activeIntegration);
  const selectedTabularMapping =
    getSelectedMappingConfig(transferConfigs, activeIntegration)?.tables || [];
  return _.isEqual(tabularMapping, {})
    ? { ...transferConfig, tables: selectedTabularMapping }
    : tabularMappingIntoTransferConfig(tabularMapping, transferConfig);
};

export const getCurrentTransferConfig = (
  transferConfigs: SavedTransferConfig[],
  {
    selectedTransferConfigId,
    selectedMappingConfigId,
    sourceTypes,
  }: ActiveIntegrationState
) => {
  const transferConfig = transferConfigs.find(
    c => c._id === selectedTransferConfigId
  );
  const mappingConfig = transferConfigs.find(
    c => c._id === selectedMappingConfigId
  );
  const defaultConfig = _.set('sources', sourceTypes, DEFAULT_TRANSFER_CONFIG);

  if (transferConfig) {
    return {
      ...transferConfig,
      ...(mappingConfig ? { tables: mappingConfig.tables } : {}),
    };
  }

  return {
    ...defaultConfig,
    ...(mappingConfig ? { tables: mappingConfig.tables } : {}),
  };
};

export const getNewConfigPayload = ({
  name,
  activeIntegration,
  tabularMapping,
  sourceConfig,
}: {
  name: string;
  activeIntegration: ActiveIntegrationState;
  tabularMapping: TabularMapping;
  sourceConfig?: SourceConfig;
}) => {
  const newTransferConfig = tabularMappingIntoTransferConfig(
    tabularMapping,
    DEFAULT_TRANSFER_CONFIG
  );
  const payload = {
    ...newTransferConfig,
    sources: activeIntegration.sourceTypes,
    name,
    sourceConfig: sourceConfig ?? null,
  };

  return payload;
};

export const getUpdatedConfigPayload = ({
  name,
  activeIntegration,
  tabularMapping,
  transferConfigs,
  sourceConfig,
}: {
  name: string;
  activeIntegration: ActiveIntegrationState;
  tabularMapping: TabularMapping;
  transferConfigs: TransferConfigsState;
  sourceConfig?: SourceConfig;
}) => {
  const transferConfig = transferConfigs.configs.find(
    ({ _id }) => _id === activeIntegration.selectedTransferConfigId
  );

  if (!transferConfig) {
    throw new Error('No config found');
  }

  const newTransferConfig = tabularMappingIntoTransferConfig(
    tabularMapping,
    transferConfig
  );

  const payload = {
    ...newTransferConfig,
    name,
    sourceConfig: sourceConfig ?? null,
  };

  return payload;
};

export const formatApiTransferConfigs = (
  apiResponse: TransferConfigsResponse
): SavedTransferConfig[] => {
  return apiResponse.map(config => ({
    ...pick(config, [
      '_id',
      '_version',
      'name',
      'tables',
      'sources',
      'options',
      'lastUpdated',
      'sourceConfig',
    ]),
  }));
};

export const getIsUserAgreeToOverrideMapping = async (
  tabularMapping: TabularMapping
) => {
  if (isEmptyTabularMapping(tabularMapping)) {
    return true;
  }

  return confirm({
    title: 'Use a saved configuration?',
    subtitle:
      'Using a saved configuration will override your current configuration.',
    warningBlockMessage: 'This cannot be undone',
    confirmButtonTitle: 'Use configuration',
    cancelButtonTitle: 'Cancel',
  });
};

export const isIntegrationWithTransferConfigs = (
  integrationId: IntegrationId
) => INTEGRATIONS_WITH_TRANSFER_CONFIGS.includes(integrationId);
