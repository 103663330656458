import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
  carry,
} from '@ardoq/rxbeach';
import {
  requestEntityGroupChange,
  saveUseCase,
  saveUseCasePending,
  saveUseCaseSuccess,
  setEditorErrors,
  setEntityGroup,
  setInitialEditorState,
} from './actions';
import { forkJoin, map, switchMap, tap, of } from 'rxjs';
import { getUseCaseEditorFormErrors } from './validationUtils';
import { filter, withLatestFrom } from 'rxjs/operators';
import { api, handleError, useCaseApi } from '@ardoq/api';
import {
  editUseCase,
  fetchUseCasesData,
  navigateToUseCaseTab,
} from '../actions';
import { UseCaseEditorStreamState, UseCaseTabs } from '../types';
import { useCaseEditor$ } from './useCaseEditor$';
import { omit } from 'lodash';
import { PersistedUseCase } from '@ardoq/api-types';
import { useCaseManagement$ } from '../useCaseManagement$';
import { confirm } from '@ardoq/modal';
import { ArdoqError } from '@ardoq/common-helpers';

const logAndSetError = (messageString: string) => (error: ArdoqError) => {
  const errorMessage = api.logErrorIfNeededAndReturnMessage(
    error,
    messageString
  );
  dispatchAction(setEditorErrors([errorMessage]));
};

const formatUseCaseForApi = (editorState: UseCaseEditorStreamState) =>
  omit(editorState, ['errors', 'initialStateToCompare']) as PersistedUseCase;

const confirmChangeEntityGroup = () => {
  return confirm({
    title: `Confirmation required`,
    text: `Are you sure you want to change Entity Group? This will reset your Triples and Key Metrics Dashboard`,
  });
};

const handleSaveUseCase = routine(
  ofType(saveUseCase),
  withLatestFrom(useCaseEditor$, useCaseManagement$),
  switchMap(([, useCaseInEditing, { useCasesData: availableUseCases }]) =>
    forkJoin({
      useCase: of(useCaseInEditing),
      validationResult: of(
        getUseCaseEditorFormErrors({
          useCase: formatUseCaseForApi(useCaseInEditing),
          availableUseCases,
        })
      ),
    })
  ),
  tap(({ validationResult }) => {
    if (!validationResult.isValid && validationResult.errorMsgs.length) {
      dispatchAction(setEditorErrors(validationResult.errorMsgs));
    }
  }),
  filter(({ validationResult }) => validationResult.isValid),
  tap(() => dispatchAction(saveUseCasePending())),
  switchMap(({ useCase }) => {
    const saveFunction = useCase._id ? useCaseApi.update : useCaseApi.create;
    return saveFunction(formatUseCaseForApi(useCase));
  }),
  handleError(logAndSetError('Error saving use case')),
  tap(() => {
    dispatchAction(
      navigateToUseCaseTab({ tab: UseCaseTabs.MANAGE, skipCheck: true })
    );
    dispatchAction(fetchUseCasesData());
    dispatchAction(saveUseCaseSuccess());
  })
);

const handleEditUseCase = routine(
  ofType(editUseCase),
  extractPayload(),
  switchMap(useCaseApi.fetch),
  handleError(logAndSetError('Error fetching editing use case')),
  map(setInitialEditorState),
  tap(dispatchAction)
);

const confirmEntityGroupChange = routine(
  ofType(requestEntityGroupChange),
  extractPayload(),
  carry(
    switchMap(({ currentEntityGroupId }) =>
      currentEntityGroupId ? confirmChangeEntityGroup() : of(true)
    )
  ),
  filter(([_, confirmed]) => !!confirmed),
  map(([{ newEntityGroupId }]) => newEntityGroupId ?? ''),
  map(setEntityGroup),
  tap(dispatchAction)
);

export default collectRoutines(
  handleSaveUseCase,
  handleEditUseCase,
  confirmEntityGroupChange
);
