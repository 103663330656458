import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { Paragraph } from '@ardoq/typography';
import { ProgressBar } from './ProgressBar';
import { useEffect } from 'react';
import { Progress } from 'integrations/common/streams/fetchProgress/types';
import { resetFetchProgress } from 'integrations/common/streams/fetchProgress/actions';
import { viewModel$ } from './viewModel$';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';

type ProgressModalProps = Progress & {
  totalCount: number;
  onComplete: () => void;
  progressLabel: string;
  renderProgressLabel?: (
    completed: number,
    total: number,
    progressLabel: string
  ) => string;
};

function ProgressModalComponent({
  status,
  progress,
  totalCount,
  onComplete,
  progressLabel,
  renderProgressLabel,
}: ProgressModalProps) {
  useEffect(() => {
    if (status === 'SUCCESS' || status === 'FAILURE') {
      onComplete();
    }
  }, [onComplete, status]);

  return (
    <ModalTemplate headerText="Fetching data" modalSize={ModalSize.S}>
      <ModalLayout>
        <Paragraph variant="text2">
          Depending on the amount of data in selected tables, it might take a
          couple of minutes.
        </Paragraph>
        <ProgressBar
          progress={progress}
          totalCount={totalCount}
          progressLabel={progressLabel}
          renderProgressLabel={renderProgressLabel}
        />
      </ModalLayout>
    </ModalTemplate>
  );
}

const ProgressModal = connect(ProgressModalComponent, viewModel$);

export const startProgressModal = ({
  integrationId,
  totalCount,
  progressLabel = 'tables',
  renderProgressLabel,
}: {
  integrationId: IntegrationId;
  totalCount: number;
  progressLabel?: string;
  renderProgressLabel?: (
    completed: number,
    total: number,
    progressLabel: string
  ) => string;
}) => {
  modal(
    resolve => (
      <ProgressModal
        totalCount={totalCount}
        progressLabel={progressLabel}
        onComplete={() => {
          dispatchAction(resetFetchProgress({ integrationId }));
          resolve(true);
        }}
        renderProgressLabel={renderProgressLabel}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: false,
      shouldCloseOnBackdropClick: false,
    }
  );
};
