import {
  LegacyApplicablePerspectiveState,
  CreateNewPerspectiveState,
  SaveAsNewPerspectiveState,
  SavePerspectiveState,
  SavedPerspectivesState,
  PerspectivesEditorFilters,
  PerspectivesRelatedData,
  BackgroundAsyncOperationError,
  RenameSavedPerspectiveActionPayload,
} from './perspectiveEditor/types';
import { ApplicablePerspectiveState } from '../perspectiveSidebar/perspectiveEditor/types';
import {
  PerspectivesGroupsOptions,
  SelectedSavedPerspectiveOptions,
  StoredPerspectivesOption,
} from '@ardoq/perspectives';
import { PerspectivesFormattingOptions } from '@ardoq/perspectives-sidebar';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

/**
 *
 *  BRIDGE ACTIONS:
 *
 * Whenever something relevant in perspectiveEditorGroupingOptions$ changes in ardoq-front, this action
 * communicates those changes over the postMessage bridge.
 */
export const notifyGroupingOptionsChanged =
  actionCreator<PerspectivesGroupsOptions>(
    '[perspectiveEditor] GROUPING_OPTIONS_CHANGE'
  );

export type NotifySavedPerspectivesOptionsChangedPayload = {
  savedPerspectives: StoredPerspectivesOption[];
  isOnlyReaderRole: boolean;
};
export const notifySavedPerspectivesOptionsChanged =
  actionCreator<NotifySavedPerspectivesOptionsChangedPayload>(
    '[perspectiveEditor] SAVED_PERSPECTIVES_OPTIONS_CHANGE'
  );

export const notifyFiltersChanged = actionCreator<PerspectivesEditorFilters>(
  '[perspectiveEditor] FILTERS_CHANGE'
);

export const notifyFormattingOptionsChanged =
  actionCreator<PerspectivesFormattingOptions>(
    '[perspectiveEditor] FORMATTING_OPTION_CHANGE'
  );

export const savePerspective = actionCreator<SavePerspectiveState>(
  '[perspectiveEditor] SAVE_PERSPECTIVE'
);

export const notifySavePerspectiveFinished = actionCreator(
  '[perspectiveEditor] SAVE_PERSPECTIVE_FINISHED'
);

export const notifySavePerspectiveFailed =
  actionCreator<BackgroundAsyncOperationError>(
    '[perspectiveEditor] SAVE_PERSPECTIVE_FAILED'
  );

/**
 * Dispatched when 'Apply' or 'Apply and close' button is clicked in Perspective Editor
 */
export const applyPerspective = actionCreator<
  LegacyApplicablePerspectiveState | ApplicablePerspectiveState
>('[perspectiveEditor] APPLY_PERSPECTIVE');

export const notifyPerspectiveApplied = actionCreator(
  '[perspectiveEditor] NOTIFY_PERSPECTIVE_APPLIED'
);

/**
 * Dispatched when 'Clear all' button is clicked in Perspective Editor or when 'Clear all' button is clicked in the main app (from the in Perspectives dropdown or NoDataWithFiltersDoq 'Clear button')
 */
export const clearPerspectiveEditor = actionCreator(
  '[perspectiveEditor] CLEAR_PERSPECTIVE_EDITOR_ACTION'
);

/**
 * Dispatched as a result of `clearPerspectiveEditor` or 'clearPerspectives'
 * to notify perspective editor that it needs to clear its state.
 */
export const notifyPerspectiveCleared = actionCreator(
  '[perspectiveEditor] NOTIFY_PERSPECTIVE_ACTION_CLEARED'
);

export const deletePerspective = actionCreator<string>(
  '[perspectiveEditor] DELETE_PERSPECTIVE'
);

export const notifyDeletingPerspectiveFinished = actionCreator(
  '[perspectiveEditor] DELETING_PERSPECTIVE_FINISHED'
);

export const notifyDeleteSavedPerspectiveFailed =
  actionCreator<BackgroundAsyncOperationError>(
    '[perspectiveEditor] DELETE_PERSPECTIVE_ERROR'
  );

export const displayDeleteConfirmationDialog = actionCreator(
  '[perspectiveEditor] DISPLAY_DELETE_CONFIRMATION_DIALOG'
);

export const displayCreatePerspectiveDialog = actionCreator(
  '[perspectiveEditor] DISPLAY_CREATE_PERSPECTIVE_DIALOG'
);

export const submitAsyncBackgroundOperation = actionCreator(
  '[perspectiveEditor] SUBMIT_ASYNC_BACKGROUND_OPERATION'
);

export const displayRenameConfirmationDialog = actionCreator(
  '[perspectiveEditor] DISPLAY_RENAME_CONFIRMATION_DIALOG'
);

export const displaySaveAsNewConfirmationDialog = actionCreator(
  '[perspectiveEditor] DISPLAY_SAVE_AS_NEW_CONFIRMATION_DIALOG'
);

export const resetAsyncBackgroundOperationAction = actionCreator(
  '[perspectiveEditor] RESET_ASYNC_BACKGROUND_OPERATION_ACTION'
);
export const renameSavedPerspective =
  actionCreator<RenameSavedPerspectiveActionPayload>(
    '[perspectiveEditor] RENAME_SAVED_PERSPECTIVE_ACTION'
  );

export const notifyRenameSavedPerspectiveFinished = actionCreator<
  Pick<SavedPerspectivesState, 'savedPerspectives'>
>('[perspectiveEditor] RENAME_SAVED_PERSPECTIVE_FINISHED');

export const notifyRenameSavedPerspectiveFailed =
  actionCreator<BackgroundAsyncOperationError>(
    '[perspectiveEditor] RENAME_SAVED_PERSPECTIVE_FAILED'
  );

export const saveAsNewPerspective = actionCreator<SaveAsNewPerspectiveState>(
  '[perspectiveEditor] SAVE_AS_NEW_PERSPECTIVE_ACTION'
);

export const createNewPerspective = actionCreator<CreateNewPerspectiveState>(
  '[perspectiveEditor] CREATE_NEW_PERSPECTIVE_ACTION'
);

export const notifyCreateNewPerspectiveFinished =
  actionCreator<SavedPerspectivesState>(
    '[perspectiveEditor] CREATE_NEW_PERSPECTIVE_FINISHED'
  );

export const notifyCreateNewPerspectiveFailed =
  actionCreator<BackgroundAsyncOperationError>(
    '[perspectiveEditor] CREATE_NEW_PERSPECTIVE_FAILED'
  );

export const notifySaveAsNewPerspectiveFinished = actionCreator(
  '[perspectiveEditor] SAVE_AS_NEW_PERSPECTIVE_FINISHED'
);

export const notifySaveAsNewPerspectiveFailed =
  actionCreator<BackgroundAsyncOperationError>(
    '[perspectiveEditor] SAVE_AS_NEW_PERSPECTIVE_FAILED'
  );

/**
 * Action emitted only once, on editor open to perform the stream refresh
 * with the valid initial data
 */
export const editorOpened = actionCreator('[perspectiveEditor] EDITOR_OPENED');

/**
 * Graph filters (aka dynamic filters) can be parameterized. The parameters
 * are expressed in select UI. Initially we are not preloading all possible
 * options. We do this only on select open. This select open action triggers
 * the one below FETCH_DYNAMIC_FILTER_OPTIONS
 */
export const fetchDynamicFilterOptions = actionCreator<string>(
  '[perspectiveEditor] FETCH_DYNAMIC_FILTER_OPTIONS'
);

/**
 * This action is emitted from Perspective editor to inform ardoq-front that
 * user has selected a saved perspective.
 */
export const selectSavedPerspective = actionCreator<ArdoqId>(
  '[perspectiveEditor] SELECT_SAVED_PERSPECTIVE'
);

/**
 * This action is dispatched by the bridge to notify Perspective Editor that
 * user has selected a saved perspective either in the Perspective Editor
 * or in the main app and it has been applied in the ardoq-front state.
 * Perspective Editor needs to apply the selected perspective's grouping,
 * formatting and filtering rules based on the action payload.
 */
export const notifySavedPerspectiveSelected =
  actionCreator<SelectedSavedPerspectiveOptions | null>(
    '[perspectiveEditor] NOTIFY_SAVED_PERSPECTIVE_SELECTED'
  );

/**
 * This is the action, that's emitted once perspectives related data changed
 * Perspective editor is listening for that action so it can refresh it's state
 * once the change occur
 */
export const notifyPerspectivesRelatedDataChanged =
  actionCreator<PerspectivesRelatedData>(
    '[perspectiveEditor] NOTIFY_PERSPECTIVES_RELATED_DATA_CHANGE'
  );

export const notifyViewIdChanged = actionCreator<ViewIds>(
  '[perspectiveEditor] NOTIFY_VIEW_ID_CHANGED'
);

type ToggleDynamicFilterActivePayload = {
  filterId: string;
  previouslyActive: boolean;
};

export const toggleDynamicFilterActive =
  actionCreator<ToggleDynamicFilterActivePayload>(
    '[perspectiveEditor] TOGGLE_DYNAMIC_FILTER_ACTION'
  );

type SelectDynamicFilterOptionPayload = {
  graphFilterId: string;
  paramName: string;
  paramValue: string;
};

export const selectDynamicFilterOption =
  actionCreator<SelectDynamicFilterOptionPayload>(
    '[perspectiveEditor] SELECT_DYNAMIC_FILTER_OPTION'
  );
