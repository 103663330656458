import { action$, derivedStream, reduceState } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import {
  ruleErrorToMessage,
  validateAdvancedSearchQuery,
} from 'search/AdvancedSearch/utils';
import { map } from 'rxjs/operators';
import {
  AdvancedSearchFieldsHashState,
  advancedSearchFieldsHash$,
} from 'streams/fields/advancedSearchFields$';
import { defaultState } from 'search/AdvancedSearch/consts';
import {
  AdvancedSearchShape,
  AdvancedSearchStateShape,
} from 'search/AdvancedSearch/types';
import { AdvancedSearchQueryBuilderProps } from 'search/AdvancedSearch/AdvancedSearchQueryBuilder';
import { SearchContext } from '@ardoq/query-builder';
import {
  selectCondition,
  selectFilterType,
  updateAdvancedSearch,
} from './actions';

const advancedSearchStateForZones$ = action$.pipe(
  reduceState('advancedSearchStateForZones$', defaultState, reducers)
);

const mapState = ([advancedSearchStateForZones, fieldsHash]: [
  AdvancedSearchStateShape,
  AdvancedSearchFieldsHashState,
]): AdvancedSearchShape => {
  const ruleErrors = advancedSearchStateForZones.queryBuilderRules
    ? validateAdvancedSearchQuery(advancedSearchStateForZones.queryBuilderRules)
    : [];

  const ruleErrorMessages = ruleErrors.map(ruleError =>
    ruleErrorToMessage(ruleError, fieldsHash)
  );

  return { ...advancedSearchStateForZones, ruleErrorMessages };
};

const advancedSearchForZones$ = derivedStream(
  'advancedSearchForZones-withFields$',
  advancedSearchStateForZones$,
  advancedSearchFieldsHash$
).pipe(map(mapState));

export default advancedSearchForZones$.pipe(
  map<AdvancedSearchShape, Omit<AdvancedSearchQueryBuilderProps, 'onChange'>>(
    advancedSearch =>
      ({
        ...advancedSearch,
        contextSelectIsReadOnly: true,
        allowedSearchContexts: [SearchContext.COMPONENT],
        defaultSearchContext: SearchContext.COMPONENT,
        actions: {
          selectCondition,
          selectFilterType,
          updateAdvancedSearch,
        },
      }) satisfies Omit<AdvancedSearchQueryBuilderProps, 'onChange'>
  )
);
