import { bundleApi } from '@ardoq/api';
import {
  DependentEntityId,
  DependentEntityTypes,
  MissingDependencyIds,
  MissingEntityTypeDetails,
} from '@ardoq/api-types';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import { Features, hasFeature } from '@ardoq/features';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { isEmpty } from 'lodash';
import { EditingBundleShape } from './types';
import { convertEditingBundleShapeToApi } from './utils';

/** Returns validation information to getBundleEditorFormErrors below
 * If bundleName already exists in the org, and user is not editing an existing bundle, not valid.
 */
type BundleEditorValidator = {
  isValid: boolean;
  errorMsg: string;
};
const getBundleNameValidator = async (
  bundleName: string | undefined,
  isEditing: boolean
): Promise<BundleEditorValidator> => {
  if (isEditing) return { isValid: true, errorMsg: '' };

  const existingBundles = await bundleApi.fetchAll();
  if (isArdoqError(existingBundles)) {
    return {
      isValid: false,
      errorMsg: 'Error fetching existing bundles for the organization.',
    };
  }

  const isUniqueName = existingBundles.every(({ name }) => name !== bundleName);
  if (!isUniqueName)
    return {
      isValid: false,
      errorMsg: 'Bundle name already exists in this organization.',
    };
  return { isValid: true, errorMsg: '' };
};

type GetBundleEditorErrorsParams = {
  bundleAttrs: Partial<EditingBundleShape>;
  isEditing: boolean;
};
export const getBundleEditorFormErrors = async ({
  bundleAttrs,
  isEditing,
}: GetBundleEditorErrorsParams) => {
  const {
    name,
    useCases = [],
    entityGroups = [],
    componentTypes = [],
    referenceTypes = [],
    fields = [],
    surveys = [],
    presentations = [],
    perspectives = [],
    dashboards = [],
    tabularMappingConfigIds = [],
    serviceNowImportConfigIds = [],
    broadcasts = [],
    graphFilter: storedQuery = [],
    reports = [],
    traversals = [],
    viewpoints = [],
  } = bundleAttrs;
  const workspaces = Array.from(bundleAttrs.workspaces?.values() ?? []);
  const hasBroadcastsFeature = hasFeature(Features.BROADCASTS);
  const hasTraversalsFeature = hasFeature(Features.SUPPORT_LARGE_DATASETS);

  const hasSelectedMetamodel = Boolean(
    componentTypes.length || referenceTypes.length || fields.length
  );
  const hasNonUseCaseEntities = Boolean(
    hasSelectedMetamodel ||
      workspaces.length ||
      surveys.length ||
      presentations.length ||
      perspectives.length ||
      dashboards.length ||
      tabularMappingConfigIds.length ||
      serviceNowImportConfigIds.length ||
      storedQuery.length ||
      reports.length ||
      viewpoints.length ||
      (hasBroadcastsFeature && broadcasts.length) ||
      (hasTraversalsFeature && traversals.length)
  );
  const hasEntities = Boolean(
    useCases.length || entityGroups.length || hasNonUseCaseEntities
  );
  const workspacesHaveRenameTxt =
    !workspaces.length ||
    workspaces.every(
      ({ renameWorkspace, name: workspaceName }) =>
        !renameWorkspace || Boolean(workspaceName)
    );
  const workspacesHaveFolderTxt =
    !workspaces.length ||
    workspaces.every(
      ({ chooseFolder, targetFolderName }) =>
        !chooseFolder || Boolean(targetFolderName)
    );
  const bothMetamodelAndWorkspace = hasSelectedMetamodel && workspaces.length;
  const bothEntityGroupAndWorkspace = entityGroups.length && workspaces.length;
  const bothUseCasesAndOtherEntities = useCases.length && hasNonUseCaseEntities;

  const validators: BundleEditorValidator[] = [
    {
      isValid: hasEntities,
      errorMsg: `At least one of use cases, entity groups, component types, reference types, fields, workspaces,
        surveys, presentations, perspectives, dashboards, viewpoints, ${
          hasBroadcastsFeature ? 'broadcasts, ' : ''
        }${
          hasTraversalsFeature ? 'traversals, ' : ''
        }or import configuration must be included in the bundle.`,
    },
    {
      isValid: !bothMetamodelAndWorkspace,
      errorMsg: `Not allowed to select both workspaces and metamodel (component types, reference
        types, fields) at the same time. Pick one or the other.`,
    },
    {
      isValid: !bothEntityGroupAndWorkspace,
      errorMsg: `Not allowed to select both workspaces and entity groups at the same time. Pick one or the other.`,
    },
    {
      isValid: !bothUseCasesAndOtherEntities,
      errorMsg: `Not allowed to select both use cases and other entities (except for entity groups) at the same time. Pick one or the other.`,
    },
    {
      isValid: workspacesHaveRenameTxt,
      errorMsg:
        'All workspaces with the "Rename" checkbox checked must have a new name.',
    },
    {
      isValid: workspacesHaveFolderTxt,
      errorMsg:
        'All workspaces with the "Specify target folder" checkbox checked must have a folder name.',
    },
    {
      isValid: Boolean(name),
      errorMsg: 'Bundle must have a name.',
    },
    await getBundleNameValidator(name, isEditing),
  ];

  return {
    isValid: validators.every(({ isValid }) => isValid),
    errorMsgs: validators
      .filter(({ isValid }) => !isValid)
      .map(({ errorMsg }) => errorMsg),
  };
};

export const getCopyConfigErrors = async (bundleAttrs: EditingBundleShape) => {
  const { copyConfig } = convertEditingBundleShapeToApi(bundleAttrs);
  const response = await bundleApi.validateCopyConfig(copyConfig);
  if (isArdoqError(response)) {
    return {
      isValid: false,
      errorMsgs: [getArdoqErrorMessage(response, 'Error validating bundle')],
    };
  }
  const dependencyErrors = getMissingDependencyErrors(
    response.missingDependencyIds
  );
  return {
    isValid: isEmpty(dependencyErrors),
    errorMsgs: dependencyErrors,
  };
};

/** Convert any MissingDependencyIds data into error strings */
export const getMissingDependencyErrors = (
  missingDependencyIds: MissingDependencyIds
): string[] => {
  const missingEntityTypes = Object.keys(missingDependencyIds);
  const hasErrors = Boolean(missingEntityTypes.length);
  if (!hasErrors) return [];
  return missingEntityTypes.reduce(
    (acc: string[], missingEntityType: string) =>
      acc.concat(
        getMissingEntityTypeErrors(
          missingEntityType,
          missingDependencyIds[missingEntityType]
        )
      ),
    []
  );
};

const getMissingEntityTypeErrors = (
  missingEntityType: string,
  missingEntityTypeDetails: MissingEntityTypeDetails
): string[] => {
  const missingEntityIds = Object.keys(missingEntityTypeDetails);
  if (!missingEntityIds.length) return [];
  return missingEntityIds.reduce(
    (acc: string[], missingEntityId: DependentEntityId) =>
      acc.concat(
        getDependentEntityTypeErrors(
          missingEntityType,
          missingEntityId,
          missingEntityTypeDetails[missingEntityId]
        )
      ),
    []
  );
};

const getDependentEntityTypeErrors = (
  missingEntityType: string,
  missingEntityId: DependentEntityId,
  dependentEntityTypes: DependentEntityTypes
): string[] =>
  Object.keys(dependentEntityTypes).map(dependentEntityType => {
    const dependentEntityIds = dependentEntityTypes[dependentEntityType];
    return (
      `Missing ${missingEntityType} ${getDescriptiveIdentity(
        missingEntityType,
        missingEntityId
      )} -- ` +
      `required by entities of type ${dependentEntityType} ` +
      `[${String(
        dependentEntityIds.map(id =>
          getDescriptiveIdentity(dependentEntityType, id)
        )
      )
        .split(',')
        .join(', ')}]`
    );
  });

const getDescriptiveIdentity = (type: string, id: DependentEntityId) => {
  switch (type) {
    case 'workspace': {
      const name = workspaceInterface.getWorkspaceName(id);
      return name ? `"${name}"` : id;
    }
    default:
      return id;
  }
};
