import {
  SidebarMenuSection,
  SidebarMenuSubsectionHeader,
} from '@ardoq/sidebar-menu';
import { Field, FormSize, NumberInput, TextInput } from '@ardoq/forms';
import { FlexBox } from '@ardoq/layout';
import {
  ColorPickerSwatchInput,
  conditionalFormattingTheme,
} from '@ardoq/color-picker';
import { SecondaryButton } from '@ardoq/button';
import { AddIcon } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateSelectedWidget } from '../../actions';
import { createConditionalFormattingLimit } from '../../utils';
import { ConditionalFormattingLimit } from '@ardoq/api-types';

interface LimitSubSectionProps {
  updateLimit: (updatedLimit: ConditionalFormattingLimit) => void;
  removeLimit: VoidFunction;
  limit: ConditionalFormattingLimit;
}

const LimitSubSection = ({
  limit,
  updateLimit,
  removeLimit,
}: LimitSubSectionProps) => {
  const { color, above, name } = limit;
  return (
    <>
      <SidebarMenuSubsectionHeader title="Custom limit" onClose={removeLimit} />
      <Field label="Limit Name">
        <FlexBox gap="small">
          <ColorPickerSwatchInput
            dataTestId="dashboard-cond-format-color-button"
            value={color}
            palettes={[conditionalFormattingTheme]}
            onValueChange={value => updateLimit({ ...limit, color: value })}
          />
          <TextInput
            dataTestId="dashboard-cond-format-name-input"
            value={name}
            onValueChange={newName => updateLimit({ ...limit, name: newName })}
            formSize={FormSize.FULL}
          />
        </FlexBox>
      </Field>
      <TextInput label="Condition" value="Greater than" isReadOnly />
      <NumberInput
        data-testid="conditional-formatting-value-input"
        allowDecimals
        label="Value"
        value={(above as number | undefined) ?? 0}
        onValueChange={value => updateLimit({ ...limit, above: value })}
      />
    </>
  );
};

interface ConditionalFormattingSectionProps {
  limits?: ConditionalFormattingLimit[];
}
const ConditionalFormattingSection = ({
  limits,
}: ConditionalFormattingSectionProps) => (
  <SidebarMenuSection title="Conditional formatting">
    {limits
      ? limits.map(limit => (
          <LimitSubSection
            key={limit.id}
            limit={limit}
            removeLimit={() =>
              dispatchAction(
                updateSelectedWidget({
                  limits: limits.filter(l => l !== limit),
                })
              )
            }
            updateLimit={updatedLimit =>
              dispatchAction(
                updateSelectedWidget({
                  limits: limits.map(l => (l === limit ? updatedLimit : l)),
                })
              )
            }
          />
        ))
      : null}
    <SecondaryButton
      dataTestId="dashboard-add-new-limit-button"
      onClick={() =>
        dispatchAction(
          updateSelectedWidget({
            limits: [...(limits ?? []), createConditionalFormattingLimit()],
          })
        )
      }
    >
      <AddIcon /> Add new limit
    </SecondaryButton>
  </SidebarMenuSection>
);

export default ConditionalFormattingSection;
