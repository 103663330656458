import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  fetchAllSubdivisions,
  fetchAllZones,
  fetchedSubdivisionsSuccessfully,
  fetchedZonesSuccessfully,
  fetchingSubdivisionsFailure,
  fetchingZonesFailure,
} from './actions';
import { ApiZonesFailurePayload } from './types';
import { Subdivision } from '@ardoq/api-types';

export const fetchAllZonesPromise = () => {
  return dispatchActionAndWaitForResponse(
    fetchAllZones(),
    fetchedZonesSuccessfully,
    fetchingZonesFailure
  ).then(responseAction => {
    if (responseAction.type === fetchedZonesSuccessfully.type) {
      return { response: responseAction.payload, error: null };
    }
    return {
      response: null,
      error: responseAction.payload as ApiZonesFailurePayload,
    };
  });
};

export const fetchAllSubdivisionsPromise = () => {
  return dispatchActionAndWaitForResponse(
    fetchAllSubdivisions(),
    fetchedSubdivisionsSuccessfully,
    fetchingSubdivisionsFailure
  ).then(responseAction => {
    if (responseAction.type === fetchedSubdivisionsSuccessfully.type) {
      return { data: responseAction.payload as Subdivision[], error: null };
    }
    return {
      response: null,
      error: responseAction.payload as ApiZonesFailurePayload,
    };
  });
};
