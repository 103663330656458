import { ExcludeFalsy, pluralize } from '@ardoq/common-helpers';
import { s16 } from '@ardoq/design-tokens';
import { enhanceOptionsWithDefaults } from '@ardoq/dropdown-menu';
import { modal } from '@ardoq/modal';
import { InfoNotification } from '@ardoq/status-ui';
import { CellWithDropdown } from '@ardoq/table';
import { header2 } from '@ardoq/typography';
import styled from 'styled-components';
import ManagePrivilegeDialog, {
  APIOrganizationUserWithPrivileges,
} from './Dialogs/ManagePrivilegeDialog';
import ManageRoleDialog from './Dialogs/ManageRoleDialog';
import RemoveUsersDialog from './Dialogs/RemoveUsersDialog';
import { PrivilegeAction } from 'privileges/types';
import { APIOrganizationUser } from '@ardoq/api-types';
import AddToGroupDialog from './Dialogs/AddToGroupDialog';
import { trackOrgMembersView } from './utils';
import { TrackOrgMembersViewUserEvents } from './types';

export const DualUserManagementModeInfo = () => (
  <InfoNotification>
    <p>
      This organization is configured with both external and in-app user
      management. Users added via the application will not be synced to your
      external user management, but will be able to log in via plain log in.
    </p>
    <p>
      If the user is defined in both places, the access rights within your
      external user management will take precedence.
    </p>
  </InfoNotification>
);

export const OnlyExternalUserManagementInfo = () => (
  <InfoNotification>
    <p>
      Only showing updated information for users that have logged in, since this
      organization is configured with external user management.
    </p>
    <p>
      If you wish to manage the users you must do so in your provisioning system
      (e.g. Active Directory).
    </p>
    <p>
      If you would rather like to manage users in the Ardoq application, please
      contact Ardoq support.
    </p>
  </InfoNotification>
);

type EditMemberIconProps = {
  hasConfigurablePrivileges: boolean;
  user: APIOrganizationUserWithPrivileges | APIOrganizationUser;
  selectedUsers: APIOrganizationUserWithPrivileges[] | APIOrganizationUser[];
  hasInternalUserManagement: boolean;
};

export const EditMemberIcon = ({
  hasConfigurablePrivileges,
  user,
  selectedUsers,
  hasInternalUserManagement,
}: EditMemberIconProps) => {
  const includedInSelectedUsers = selectedUsers.some(
    ({ _id }) => _id === user._id
  );
  const actionUsers = includedInSelectedUsers ? selectedUsers : [user];

  const getMenuLabel = (label: string, list: unknown[]) =>
    `${label} ${actionUsers.length > 1 ? `(${list.length})` : ''}`;

  const menuOptions = [
    hasInternalUserManagement && {
      label: getMenuLabel('Assign role', actionUsers),
      onClick: () => {
        trackOrgMembersView(TrackOrgMembersViewUserEvents.CLICKED_ACTION, {
          action: 'Assign role',
          selectedUsersCount: actionUsers.length,
        });
        modal(closeModal => (
          <ManageRoleDialog
            closeDialog={() => closeModal(true)}
            selectedUsers={actionUsers}
          />
        ));
      },
    },
    ...(hasConfigurablePrivileges
      ? [
          {
            label: getMenuLabel('Assign privileges', actionUsers),
            onClick: () => {
              trackOrgMembersView(
                TrackOrgMembersViewUserEvents.CLICKED_ACTION,
                {
                  action: 'Assign privileges',
                  selectedUsersCount: actionUsers.length,
                }
              );
              modal(closeModal => (
                <ManagePrivilegeDialog
                  closeDialog={() => closeModal(true)}
                  selectedUsers={actionUsers}
                  action={PrivilegeAction.ASSIGN}
                />
              ));
            },
          },
          {
            label: getMenuLabel('Remove privileges', actionUsers),
            onClick: () => {
              trackOrgMembersView(
                TrackOrgMembersViewUserEvents.CLICKED_ACTION,
                {
                  action: 'Remove privileges',
                  selectedUsersCount: actionUsers.length,
                }
              );
              modal(closeModal => (
                <ManagePrivilegeDialog
                  closeDialog={() => closeModal(true)}
                  selectedUsers={actionUsers}
                  action={PrivilegeAction.REMOVE}
                />
              ));
            },
          },
        ]
      : []),
    hasInternalUserManagement && {
      label: getMenuLabel(
        pluralize('Remove user', actionUsers.length),
        actionUsers
      ),
      onClick: () => {
        trackOrgMembersView(TrackOrgMembersViewUserEvents.CLICKED_ACTION, {
          action: 'Remove user',
          selectedUsersCount: actionUsers.length,
        });
        modal(closeModal => (
          <RemoveUsersDialog
            closeDialog={() => closeModal(true)}
            selectedUsers={actionUsers}
          />
        ));
      },
    },
    hasInternalUserManagement && {
      label: getMenuLabel('Add to group', actionUsers),
      onClick: () => {
        trackOrgMembersView(TrackOrgMembersViewUserEvents.CLICKED_ACTION, {
          action: 'Add to group',
          selectedUsersCount: actionUsers.length,
        });
        modal(closeModal => (
          <AddToGroupDialog
            closeDialog={() => closeModal(true)}
            selectedUsers={actionUsers}
          />
        ));
      },
    },
  ];
  return (
    <CellWithDropdown
      dataTestId="cell-with-dropdown"
      trackOnClick={() =>
        trackOrgMembersView(TrackOrgMembersViewUserEvents.OPENED_ACTION_MENU, {
          selectedUsersCount: selectedUsers.length,
        })
      }
      options={enhanceOptionsWithDefaults(menuOptions.filter(ExcludeFalsy))}
    />
  );
};

export const Header = styled.h2`
  ${header2}
  margin: 0 0 ${s16}
`;
