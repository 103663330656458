import { trackEvent } from 'tracking/tracking';
import { TrackingPlaceInApp } from 'tracking/types';
import { loadedGraph$ } from 'traversals/loadedGraph$';

const { RIGHT_PANE_TOOLBAR } = TrackingPlaceInApp;

export const trackButtonClicked = (action: string) => {
  trackEvent(`${RIGHT_PANE_TOOLBAR} clicked ${action}`, {
    isViewpointMode: loadedGraph$.state.isViewpointMode,
  });
};

export const trackTabClicked = (tab: string) => {
  trackEvent(`${RIGHT_PANE_TOOLBAR} clicked ${tab}`, {
    isViewpointMode: loadedGraph$.state.isViewpointMode,
  });
};
