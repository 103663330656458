import React from 'react';
import { Paragraph } from '@ardoq/typography';
import { TextInput } from '@ardoq/forms';
import { IconButton, SecondaryButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import {
  DictionaryLookupTransformation,
  Transformation,
} from '@ardoq/api-types/integrations';
import { dispatchAction } from '@ardoq/rxbeach';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import styled from 'styled-components';
import { s64 } from '@ardoq/design-tokens';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { ValidationErrors } from 'integrations/common/modals/transformModal/types';
import { FlexBox } from '@ardoq/layout';

type DictionaryLookupTransformationSectionProps = {
  integrationId: IntegrationId;
  transformation: DictionaryLookupTransformation;
  setTransformation: (newTransformation: Transformation) => void;
  validationErrors: ValidationErrors;
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
};

const InputsSection = styled(FlexBox).attrs({ gap: 'medium' })`
  height: ${s64};
`;

const DictionaryLookupTransformationSection = ({
  integrationId,
  transformation,
  setTransformation,
  validationErrors,
  setValidationErrors,
}: DictionaryLookupTransformationSectionProps) => {
  const addNewRule = () => {
    setTransformation({
      ...transformation,
      config: {
        ...transformation.config,
        dictionary: [
          ...transformation.config.dictionary,
          { key: '', value: '' },
        ],
      },
    });
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'ADDED_TRANSFORMATIONS_DICTIONARY_RULE',
      })
    );
  };

  const onDictionaryChange = (
    value: string,
    property: string,
    dictionaryIndex: number
  ) => {
    setTransformation({
      ...transformation,
      config: {
        ...transformation.config,
        dictionary: transformation.config.dictionary.map((item, index) =>
          index === dictionaryIndex ? { ...item, [property]: value } : item
        ),
      },
    });

    setValidationErrors(prevState => ({
      ...prevState,
      dictionary: {
        ...prevState.dictionary,
        [dictionaryIndex]: {
          ...prevState.dictionary[dictionaryIndex],
          [property]: !value,
        },
      },
    }));
  };

  const onDeleteDictionary = (dictionaryIndex: number) => {
    setTransformation({
      ...transformation,
      config: {
        ...transformation.config,
        dictionary: transformation.config.dictionary.filter(
          (_, index) => index !== dictionaryIndex
        ),
      },
    });
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'DELETED_TRANSFORMATIONS_DICTIONARY_RULE',
      })
    );
  };

  return (
    <>
      {transformation.config.dictionary.map((dictionary, dictionaryIndex) => (
        <InputsSection key={dictionaryIndex}>
          <FlexBox justify="center" padding="small">
            <Paragraph variant="text1">Replace</Paragraph>
          </FlexBox>
          <TextInput
            value={dictionary.key}
            onValueChange={value =>
              onDictionaryChange(value, 'key', dictionaryIndex)
            }
            placeholder="Select an existing value"
            onBlur={() =>
              setValidationErrors(prevState => {
                if (!transformation.config.dictionary) {
                  return prevState;
                }

                return {
                  ...prevState,
                  dictionary: {
                    ...prevState.dictionary,
                    [dictionaryIndex]: {
                      ...prevState.dictionary[dictionaryIndex],
                      key: !transformation.config.dictionary[dictionaryIndex]
                        .key,
                    },
                  },
                };
              })
            }
            errorMessage={
              validationErrors.dictionary[dictionaryIndex]?.key
                ? 'This field is required.'
                : undefined
            }
          />
          <FlexBox justify="center" padding="small">
            <Paragraph variant="text1">with</Paragraph>
          </FlexBox>
          <TextInput
            value={dictionary.value}
            onValueChange={value =>
              onDictionaryChange(value, 'value', dictionaryIndex)
            }
            placeholder="Type in the desired value"
          />
          {transformation.config.dictionary.length > 1 && (
            <FlexBox align="start">
              <IconButton
                iconName={IconName.DELETE}
                onClick={() => onDeleteDictionary(dictionaryIndex)}
              />
            </FlexBox>
          )}
        </InputsSection>
      ))}
      <FlexBox paddingBottom="large" align="start">
        <SecondaryButton onClick={addNewRule}>Add rule</SecondaryButton>
      </FlexBox>
    </>
  );
};

export default DictionaryLookupTransformationSection;
