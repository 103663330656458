import { APIRoleAttributes, PrivilegeLabel } from '@ardoq/api-types';
import {
  setSelectedPrivilegesAction,
  setSelectedRoleLabelAction,
} from './streams/actions';
import { dispatchAction } from '@ardoq/rxbeach';

export const setSelectedPrivileges = (privileges: PrivilegeLabel[]) =>
  dispatchAction(setSelectedPrivilegesAction({ privileges }));

export const setSelectedRoleLabel = (role: APIRoleAttributes) =>
  dispatchAction(setSelectedRoleLabelAction(role));
