import { MergeDirection } from 'scope/merge/MergeDirection';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { Branch } from 'components/DiffMergeTable/Branch';
import { PopoverRenderer } from '@ardoq/popovers';
import PopoverDataProvider from 'components/DiffMergeTablePopover/PopoverDataProvider';
import styled from 'styled-components';
import * as fonts from 'app/designTokens/fonts';
import {
  EnhancedScopeDataWithBranchName,
  EntityRepresentation,
  Graphics,
  readRawValue,
} from '@ardoq/renderers';

const MessageContainer = styled.div`
  ${fonts.pageViewMediumTemp};
  line-height: 28px;
  max-width: 800px;
`;

const MissingParentPopoverContent = ({
  mergeDirection,
  componentId,
  enhancedScopeData,
  graphics,
}: {
  mergeDirection: MergeDirection;
  componentId: ArdoqId;
  enhancedScopeData: EnhancedScopeDataWithBranchName;
  graphics: Graphics;
}) => {
  const parentId = readRawValue(
    APIEntityType.COMPONENT,
    componentId,
    'parent',
    enhancedScopeData
  );

  return (
    <MessageContainer>
      &quot;
      <EntityRepresentation
        entityId={parentId}
        entityType={APIEntityType.COMPONENT}
        enhancedScopeData={enhancedScopeData}
        graphics={graphics}
      />
      <span>
        &quot; is not available
        {mergeDirection === MergeDirection.MAINLINE_TO_BRANCH
          ? ' in your scenario'
          : ' on mainline'}
        , so this change cannot be merged.
      </span>
      <p>
        Go back to the create components step and include this component if you
        wish to merge this.
      </p>
    </MessageContainer>
  );
};

export const diffMergeMissingParentPopoverRenderer: PopoverRenderer =
  target => {
    const { diffMergePopoverEntityId: entityId } =
      (target as HTMLElement)?.dataset || {};
    if (!entityId) return null;
    return (
      <PopoverDataProvider branch={Branch.SOURCE}>
        {data => (
          <MissingParentPopoverContent componentId={entityId} {...data} />
        )}
      </PopoverDataProvider>
    );
  };
