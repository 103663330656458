import { trackEvent } from 'tracking/tracking';

export const trackLandingPageImpression = (page: string) =>
  trackEvent('Viewed upsell landing page', {
    page,
  });

export const trackLandingPageTrialRequestClick = (page: string) =>
  trackEvent('Clicked upsell landing page CTA button', {
    page,
  });

export const trackLandingPageContactAdminClick = (page: string) =>
  trackEvent('Clicked upsell landing page Contact Admin button', {
    page,
  });

export const trackLandingPageFooterFeatureClick = (
  sourcePage: string,
  targetPage: string
) =>
  trackEvent('Clicked upsell landing page footer link', {
    sourcePage,
    targetPage,
  });
