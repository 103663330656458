import { useEffectOnce } from '@ardoq/hooks';
import { setManageUsersState } from 'admin/orgMembers/manageUsers$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { clearInvitationsAlert } from 'streams/invitations/utils';
import { OrgMembersView } from '../orgMembers/orgMembersView';
import orgMembersView$, {
  OrgMembersViewStreamShape,
} from 'admin/orgMembers/orgMembersView$';
import { Island } from '@ardoq/page-layout';
import { organizationOperations } from '@ardoq/core';
import { accessControlNavigationOperations } from 'admin/accessControl/navigation/accessControlPageNavigationOperations';
import { UserProfilePage } from 'admin/accessControl/UserProfilePage/UserProfilePage';
import { AccessControlPageProps } from 'appContainer/AccessControlPage/types';
import { BrandButton } from '@ardoq/button';
import { trackOrgMembersView } from 'admin/orgMembers/utils';
import { TrackOrgMembersViewUserEvents } from 'admin/orgMembers/types';
import { modal } from '@ardoq/modal';
import UserInvitations from 'sharing/UserInvitations';
import { Icon, IconName } from '@ardoq/icons';
import { Features, hasFeature } from '@ardoq/features';

export type ManageAndInviteUsersProps = OrgMembersViewStreamShape & {
  accessControlPageViewModel: AccessControlPageProps;
};

const ManageAndInviteUsers = ({
  organization,
  accessControlPageViewModel,
  users,
  ...props
}: ManageAndInviteUsersProps) => {
  useEffectOnce(() => {
    dispatchAction(
      setManageUsersState({
        hasInternalUserManagement:
          organizationOperations.hasInternalUserManagement(organization),
        hasInternalAndExternalUserManagement:
          organizationOperations.hasInternalAndExternalUserManagement(
            organization
          ),
      })
    );

    return () => {
      clearInvitationsAlert();
    };
  });

  if (
    accessControlNavigationOperations.isUserProfilePage(
      accessControlPageViewModel
    ) &&
    accessControlPageViewModel?.userPageViewModel
  ) {
    return (
      <UserProfilePage
        {...accessControlPageViewModel.userPageViewModel}
        {...accessControlPageViewModel}
      />
    );
  }

  // HACK - Rafaa - Temporary until we can Merge the new Navigation pattern.
  const allUsers = (
    <div>
      All users: <b>{users.length}</b>
    </div>
  );
  // HACK - Rafaa - Temporary until we can Merge the new Navigation pattern.
  const header = hasFeature(Features.PERMISSION_ZONES_INTERNAL)
    ? {}
    : {
        title: 'Users',
        subtitle: allUsers,
        rightContent: props.hasInternalUserManagement ? (
          <BrandButton
            onClick={() => {
              trackOrgMembersView(
                TrackOrgMembersViewUserEvents.CLICKED_INVITE_USER
              );
              modal(closeModal => (
                <UserInvitations closeDialog={() => closeModal(true)} />
              ));
            }}
            data-intercom-target="invite-user"
            dataTestId="invite-user-button"
          >
            <Icon iconName={IconName.MAIL} />
            Invite users
          </BrandButton>
        ) : null,
      };

  return (
    <Island {...header}>
      <OrgMembersView {...props} organization={organization} users={users} />
    </Island>
  );
};

export default connect(ManageAndInviteUsers, orgMembersView$);
