import { ReactNode } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@ardoq/forms';
import { DynamicFilterSelectState } from '@ardoq/data-model';
import { InlineText } from '@ardoq/typography';
import {
  ArdoqLoaderSpinner,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { FlexBox, Stack } from '@ardoq/layout';

const SpinnerHolder = styled.span`
  height: 24px;
  width: 24px;
`;

const checkedStates = new Set([
  DynamicFilterSelectState.ACTIVE,
  DynamicFilterSelectState.LOADING,
]);

export const shouldBeChecked = (state: DynamicFilterSelectState) =>
  checkedStates.has(state);

interface Props {
  state: DynamicFilterSelectState;
  label: string;
  name?: string;
  error?: string;
  onChange?: () => void;
  children?: ReactNode;
  isDisabled?: boolean;
}
const DynamicFilterCheckbox = ({
  state,
  name,
  label,
  isDisabled,
  error,
  onChange,
  children,
}: Props) => (
  <Stack>
    <FlexBox marginTop={children ? 'medium' : 'small'} gap="small">
      {state === DynamicFilterSelectState.LOADING ? (
        <FlexBox gap="small" align="center">
          <SpinnerHolder>
            <ArdoqLoaderSpinner
              color={LoaderColor.GRAY}
              size={LoaderSize.SMALL}
            />
          </SpinnerHolder>
          <InlineText variant="caption">{label}</InlineText>
        </FlexBox>
      ) : (
        <Checkbox
          name={name}
          isChecked={shouldBeChecked(state)}
          onChange={onChange}
          isDisabled={isDisabled}
          hasError={!!error}
        >
          <InlineText variant="caption">{label}</InlineText>
        </Checkbox>
      )}
    </FlexBox>
    <FlexBox marginY={children ? 'small' : 'none'}>{children}</FlexBox>
  </Stack>
);

export default DynamicFilterCheckbox;
