import { filter, map, tap, withLatestFrom } from 'rxjs';
import { userEvent } from 'sync/actions';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  SelectionResponse,
  TableMappingType,
} from '@ardoq/api-types/integrations';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import {
  ensureCorrectAsyncRequest,
  getAsyncRequestId,
  parseAsyncResponsePayload,
} from 'integrations/common/async/utils';
import { AsyncOperations } from 'integrations/common/async/constants';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import {
  fetchSelection,
  fetchSelectionError,
  fetchSelectionSuccess,
  loadSelection,
} from 'integrations/common/streams/selectionState/actions';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { microsoftEntraIdTables$ } from '../tables/tables$';
import {
  resetSelection,
  setSelectionAsyncStatus,
  fetchSelectionSuccess as fetchMicrosoftEntraSelectionSuccess,
  setFetchErrorMessage,
  selectRequiredFields,
  selectTable,
  selectFields,
  setImportProfilesPhotos,
  setHasRequestedPhotos,
} from './actions';
import { selectionState$ } from './selectionState$';
import { prepareSelectionRequest } from './utils';
import { applyRequiredTableMapping } from 'integrations/common/streams/tabularMappings/actions';
import { getTabularMappingConstraintsStream } from 'integrations/common/streams/tabularMappingConstraints/getTabularMappingConstraintsStream';
import {
  resetTabularMappingConstraints,
  setTableMappingConstraints,
} from 'integrations/common/streams/tabularMappingConstraints/actions';
import {
  setIntegrationTerms,
  unsetIntegrationTerms,
} from 'integrations/common/streams/integrationTermsDictionary/actions';

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  filter(integrationId => integrationId === 'microsoft-entra-id'),
  tap(() => {
    dispatchAction(resetSelection());
  })
);

const handleFetchSelection = routine(
  ofType(loadSelection),
  extractPayload(),
  filter(integrationId => integrationId === 'microsoft-entra-id'),
  withLatestFrom(selectionState$, activeIntegrations$, microsoftEntraIdTables$),
  tap(([integrationId, selectionState, activeIntegrations, tablesState]) => {
    const funnelId = activeIntegrations[integrationId].trackingFunnelId;
    const requestId = getAsyncRequestId({
      integrationId: 'microsoft-entra-id',
      operation: AsyncOperations.MICROSOFT_ENTRA_ID_FETCH_DATA,
      funnelId,
    });
    const selectionRequestPayload = prepareSelectionRequest({
      selectedTables: selectionState.tables,
      tables: tablesState.tables,
    });

    dispatchAction(setSelectionAsyncStatus('LOADING'));
    dispatchAction(setFetchErrorMessage(null));
    dispatchAction(setHasRequestedPhotos(selectionState.importProfilesPhotos));
    dispatchAction(
      trackIntegrationEvent({
        integrationId: 'microsoft-entra-id',
        name: 'TRIGGERED_SOURCE_DATA_FETCH',
        metadata: {
          numberOfFields: selectionRequestPayload.tables.reduce(
            (acc, table) => acc + table.fields.length,
            0
          ),
          importPhotos: selectionState.importProfilesPhotos,
        },
      })
    );
    dispatchAction(
      fetchSelection({
        integrationId: 'microsoft-entra-id',
        payload: selectionRequestPayload,
        requestId,
      })
    );
  })
);

const handleFetchSelectionResponse = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncResponsePayload<SelectionResponse>),
  withLatestFrom(activeIntegrations$),
  ensureCorrectAsyncRequest(AsyncOperations.MICROSOFT_ENTRA_ID_FETCH_DATA),
  tap(([response]) => {
    if (!response) return;

    if (response.status !== 'success') {
      dispatchAction(setSelectionAsyncStatus('FAILURE'));

      if (response.data.message) {
        dispatchAction(setFetchErrorMessage(response.data.message));
      }

      return;
    }

    dispatchAction(setFetchErrorMessage(null));
    dispatchAction(
      fetchSelectionSuccess({
        response: response.data,
        integrationId: 'microsoft-entra-id',
      })
    );
  })
);

const handleFetchSelectionError = routine(
  ofType(fetchSelectionError),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'microsoft-entra-id'),
  tap(() => {
    dispatchAction(setSelectionAsyncStatus('FAILURE'));
    dispatchAction(setFetchErrorMessage('Failed to fetch data'));
  })
);

const handleFetchSelectionSuccess = routine(
  ofType(fetchSelectionSuccess),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'microsoft-entra-id'),
  tap(({ response, integrationId }) => {
    dispatchAction(fetchMicrosoftEntraSelectionSuccess(response));
    dispatchAction(applyRequiredTableMapping({ integrationId }));
  })
);

const handleSelectRequiredFields = routine(
  ofType(selectRequiredFields),
  withLatestFrom(
    getTabularMappingConstraintsStream('microsoft-entra-id'),
    microsoftEntraIdTables$
  ),
  tap(([_, tabularMappingConstraints, microsoftEntraIdTables]) => {
    if (!tabularMappingConstraints.user) {
      return;
    }

    const usersTableId = microsoftEntraIdTables.tables.find(
      table => table.id === 'user'
    )?.id;

    if (!usersTableId) {
      return;
    }

    dispatchAction(selectTable(usersTableId));
    dispatchAction(
      selectFields({
        tableId: usersTableId,
        ids: ['id', 'displayName'],
      })
    );
  })
);

const handleSetImportProfilesPhotos = routine(
  ofType(setImportProfilesPhotos),
  extractPayload(),
  tap(importProfilesPhotos => {
    if (importProfilesPhotos) {
      dispatchAction(
        setIntegrationTerms({
          integrationId: 'microsoft-entra-id',
          terms: {
            dryRunAdditionalMessage:
              'All profile photos will be securely stored as images in the Document Archive.',
            additionalHardDeleteWarningText: 'Profile photos can`t be deleted.',
          },
        })
      );
      return dispatchAction(
        setTableMappingConstraints({
          integrationId: 'microsoft-entra-id',
          tableId: 'user',
          tableMappingConstraints: {
            tableType: TableMappingType.COMPONENTS,
            disabledReferenceType: true,
            disabledReferenceTypeReason:
              'When importing profile photos, rows should contain "Components"',
            mappedColumns: {
              displayName: {
                columnType: 'component',
                disabledColumnType: true,
                disabledColumnTypeReason:
                  'When importing profile photos, "Display name" maps to "Component name".',
              },
            },
          },
        })
      );
    }

    dispatchAction(
      unsetIntegrationTerms({
        integrationId: 'microsoft-entra-id',
        keys: ['dryRunAdditionalMessage', 'additionalHardDeleteWarningText'],
      })
    );
    dispatchAction(
      resetTabularMappingConstraints({ integrationId: 'microsoft-entra-id' })
    );
  })
);

export default [
  handleFetchSelection,
  handleFetchSelectionResponse,
  handleResetIntegration,
  handleFetchSelectionSuccess,
  handleSelectRequiredFields,
  handleSetImportProfilesPhotos,
  handleFetchSelectionError,
];
