import {
  action$,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { ZoneCommands } from './types';
import {
  createZone,
  createZoneFailure,
  createZoneSuccessfully,
  deleteZone,
} from 'streams/subdivisions/actions';
import {
  addNewZoneGroupPermission,
  changeFieldsConfigInComponentTypeInZone,
  changeZoneGroupPermission,
  deleteZoneGroupPermission,
  deselectAllFields,
  savePermissionsAndZones,
  savePermissionsAndZonesFailure,
  savePermissionsAndZonesSuccessfully,
  selectAllFields,
  updateComponentTypeFieldsGroupSearchKey,
  changeZoneName,
  setEditingZoneName,
  resetEditingZoneName,
  updateComponentTypeFieldsGroupSearchBy,
} from './zonesViewState$/actions';
import { touchStep } from 'subdivisionEditor/subdivisionEditorViewModel$/actions';
import { SubdivisionEditorSteps } from 'subdivisionEditor/navigation/types';
import { SubdivisionViewModelState } from 'subdivisionEditor/types';
import { selectSubdivisionEditorStep } from 'subdivisionEditor/navigation/actions';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { zonesOperations } from './operations';
import { getZonePermissionsFinished } from './permissions$/actions';
import { firstValueFrom, map } from 'rxjs';
import {
  ArdoqId,
  MetaModelComponentType,
  PermissionAccessLevel,
  Zone,
} from '@ardoq/api-types';
import { isForInternalDev } from 'subdivisionEditor/Steps/utils';
import {
  DIVISIONS_USER_EVENTS,
  trackDivisionEditorUserEvent,
} from 'subdivisionEditor/trackingUtils';
import { debounce } from 'lodash';
import { subdivisionsZonesOperations } from '@ardoq/subdivisions';
import { ComponentTypeFieldsGroupSearchParams } from './zonesViewState$/types';

const setPermissionConfigurationStepTouched = () =>
  dispatchAction(touchStep(SubdivisionEditorSteps.PERMISSIONS_CONFIGURATION));

const savePermissions = async (
  subdivisionEditorState: SubdivisionViewModelState
): Promise<boolean> => {
  const zonesToUpdate = Object.values(
    subdivisionEditorState.zonesState.zonesById
  );
  const zonesAndPermissionsToUpdate = zonesToUpdate.map(zone => ({
    zoneId: zone._id,
    zone: subdivisionsZonesOperations.expandDateRangeInZoneObject(zone),
    permission: subdivisionEditorState.zonesState.permissionsByZoneId[zone._id],
  }));
  const response = await dispatchActionAndWaitForResponse(
    savePermissionsAndZones(zonesAndPermissionsToUpdate),
    savePermissionsAndZonesSuccessfully,
    savePermissionsAndZonesFailure
  );
  return response.type === savePermissionsAndZonesSuccessfully.type;
};

const selectAll = (
  zone: Zone,
  selectedComponentTypes: MetaModelComponentType[],
  componentTypeFieldsGroupSearch: ComponentTypeFieldsGroupSearchParams
) => {
  trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.CLICKED_SELECT_ALL_FIELDS);
  dispatchAction(
    selectAllFields({
      zone,
      selectedComponentTypes,
      componentTypeFieldsGroupSearch,
    })
  );
  setPermissionConfigurationStepTouched();
};

const deselectAll = (
  zone: Zone,
  selectedComponentTypes: MetaModelComponentType[],
  componentTypeFieldsGroupSearch: ComponentTypeFieldsGroupSearchParams
) => {
  trackDivisionEditorUserEvent(
    DIVISIONS_USER_EVENTS.CLICKED_DESELECT_ALL_FIELDS
  );
  dispatchAction(
    deselectAllFields({
      zone,
      selectedComponentTypes,
      componentTypeFieldsGroupSearch,
    })
  );
  setPermissionConfigurationStepTouched();
};

const trackFieldConfigUserEvent = debounce(
  <T extends object>(event: DIVISIONS_USER_EVENTS, metadata?: T) =>
    trackDivisionEditorUserEvent(event, metadata),
  3000,
  { leading: true, trailing: false }
);

export const zoneCommands: ZoneCommands = {
  deleteZone: zone => {
    trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.DELETED_ZONE);
    dispatchAction(deleteZone(zone));
  },
  createNewZone: async zone => {
    trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.CREATE_NEW_ZONE);
    const response = await Promise.all([
      dispatchActionAndWaitForResponse(
        createZone(zone),
        createZoneSuccessfully,
        createZoneFailure
      ),
      firstValueFrom(
        action$.pipe(
          ofType(getZonePermissionsFinished),
          extractPayload(),
          map(payload => payload)
        )
      ),
    ]);
    return { newZone: response[0].payload, permissions: response[1] ?? [] };
  },
  addZoneGroupPermission: ({
    label,
    displayName,
    currentUser,
    permissionsByZoneId,
  }) => {
    trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.ADD_GROUP_TO_DIVISION);
    const permission = zonesOperations.generateGroupResourcePermissionItem({
      permissions: [PermissionAccessLevel.READ],
      label: label,
      displayName: displayName,
      currentUser: currentUser,
    });
    const targetGroupPermission =
      zonesOperations.getAGroupPermissionNotIncludesThisGroup(
        label,
        permissionsByZoneId
      );
    if (!targetGroupPermission) {
      return;
    }

    dispatchAction(
      addNewZoneGroupPermission({
        permission,
        zonePermission: targetGroupPermission,
      })
    );
    setPermissionConfigurationStepTouched();
  },
  deleteZoneGroupPermission: data => {
    trackDivisionEditorUserEvent(
      DIVISIONS_USER_EVENTS.REMOVE_GROUP_FROM_DIVISION
    );
    dispatchAction(deleteZoneGroupPermission(data));
    setPermissionConfigurationStepTouched();
  },
  moveZoneGroupPermission: data => {
    trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.CHANGE_GROUP_ZONE);
    dispatchAction(
      deleteZoneGroupPermission({
        groupName: data.groupPermission.name,
        zonePermission: data.fromZonePermission,
      })
    );
    dispatchAction(
      addNewZoneGroupPermission({
        permission: data.groupPermission,
        zonePermission: data.toZonePermission,
      })
    );
    setPermissionConfigurationStepTouched();
  },
  changeZoneGroupPermission: data => {
    trackDivisionEditorUserEvent(
      DIVISIONS_USER_EVENTS.CHANGE_ZONE_GROUP_PERMISSION
    );
    dispatchAction(changeZoneGroupPermission(data));
    setPermissionConfigurationStepTouched();
  },
  changeZoneName: data => {
    trackFieldConfigUserEvent(DIVISIONS_USER_EVENTS.CHANGED_ZONE_NAME);
    setPermissionConfigurationStepTouched();
    dispatchAction(changeZoneName(data));
  },
  setEditingZoneName: zoneId => {
    trackDivisionEditorUserEvent(
      DIVISIONS_USER_EVENTS.CLICKED_CHANGE_ZONE_NAME
    );
    dispatchAction(setEditingZoneName(zoneId));
  },
  finishEditingZoneName: (hasError: boolean, zoneId: ArdoqId) => {
    if (!hasError) {
      dispatchAction(resetEditingZoneName(zoneId));
    }
  },
  changeComponentTypeFieldsGroupSearchKey: key => {
    trackFieldConfigUserEvent(DIVISIONS_USER_EVENTS.SEARCH_BY_COMPONENT_TYPE);
    dispatchAction(updateComponentTypeFieldsGroupSearchKey(key));
  },
  changeComponentTypeFieldsGroupSearchBy: searchBy => {
    trackFieldConfigUserEvent(DIVISIONS_USER_EVENTS.SEARCH_BY_FIELD_NAME);
    dispatchAction(updateComponentTypeFieldsGroupSearchBy(searchBy));
  },
  changeFieldsConfigInComponentTypeInZone: data => {
    trackDivisionEditorUserEvent(
      DIVISIONS_USER_EVENTS.CLICKED_FIELD_SELECTION_IN_A_ZONE,
      {
        allFieldsCount: data.allFields.length,
        selectedFieldsCount: data.enabledFields.length,
      }
    );
    dispatchAction(changeFieldsConfigInComponentTypeInZone(data));
    setPermissionConfigurationStepTouched();
  },
  savePermissions,
  goToSelectDataStep: () => {
    dispatchAction(
      selectSubdivisionEditorStep({
        subdivisionEditorStep: isForInternalDev()
          ? SubdivisionEditorSteps.COMPONENTS_SELECTION
          : SubdivisionEditorSteps.WORKSPACES_BINDING,
      })
    );
  },
  goToPermissionsConfigurationStep: () => {
    dispatchAction(
      selectSubdivisionEditorStep({
        subdivisionEditorStep: SubdivisionEditorSteps.PERMISSIONS_CONFIGURATION,
      })
    );
  },
  selectAll,
  deselectAll,
};
