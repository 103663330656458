import { colors, s4 } from '@ardoq/design-tokens';
import { TableList, TableListItem } from '@ardoq/list';
import { getFieldNamesAndLabels, renderOptions } from './utils';
import { FullWidthFlexBox, StyledColorBar } from './atoms';
import {
  APIOrganizationUser,
  APIReferenceAttributes,
  ChangeRequestAction,
} from '@ardoq/api-types';
import { EnhancedScopeData } from '@ardoq/data-model';
import { ReferenceRenderer } from '@ardoq/renderers';
import { map } from 'rxjs';
import changeApprovalData$ from '../changeApprovalData$';
import { connect, derivedStream } from '@ardoq/rxbeach';
import { omit } from 'lodash';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';

type ReferenceAttributesTableProps = {
  reference: APIReferenceAttributes;
  changeAction: ChangeRequestAction;
  sourceOfTruthScopeData: EnhancedScopeData;
  users: Record<string, APIOrganizationUser>;
};

const ReferenceAttributesTable = ({
  reference,
  changeAction,
  sourceOfTruthScopeData,
  users,
}: ReferenceAttributesTableProps) => {
  const fieldNamesAndLabels = getFieldNamesAndLabels(
    Object.keys(
      omit(reference, [
        'ardoq',
        'ardoq-persistent',
        'last-updated',
        'createdBy',
        '_id',
        'mustBeSaved',
        'last-modified-by',
      ])
    ),
    sourceOfTruthScopeData
  );
  return (
    <FullWidthFlexBox>
      <StyledColorBar
        $barColor={changeAction === 'create' ? colors.green50 : colors.red50}
      />
      <TableList style={{ margin: `${s4} 0` }}>
        <tbody>
          {fieldNamesAndLabels.map(({ fieldName, label }) => {
            return (
              <TableListItem key={fieldName} label={label}>
                <ReferenceRenderer
                  entityId={reference._id}
                  fieldName={fieldName}
                  enhancedScopeData={sourceOfTruthScopeData}
                  users={users}
                  options={renderOptions}
                />
              </TableListItem>
            );
          })}
        </tbody>
      </TableList>
    </FullWidthFlexBox>
  );
};

const referenceAttributesTable$ = derivedStream(
  'referenceAttributesTable$',
  changeApprovalData$,
  orgUsers$
).pipe(
  map(([{ masterData, branchData, changeAction }, { byId: users }]) => {
    return {
      sourceOfTruthScopeData:
        changeAction === 'delete' ? masterData : branchData,
      users,
      changeAction,
    };
  })
);

export default connect(ReferenceAttributesTable, referenceAttributesTable$);
