import { ArdoqId, APIViewpointAttributes, LoadedState } from '@ardoq/api-types';
import { EnhancedScopeData, ContextShape } from '@ardoq/data-model';
import { showDrawer } from '@ardoq/snowflakes';
import { dispatchAction } from '@ardoq/rxbeach';
import { selectedComponentForOverviewDrawerChanged } from './actions';
import { ConnectedComponentOverviewDrawer } from './ComponentOverviewDrawer';
import { commands as viewpointCommands } from 'appContainer/componentOverviewPage/commands';
import { inventoryTracking } from '../inventoryTracking';

export type ComponentOverviewDrawerCommands = {
  openDrawer: (componentId: ArdoqId, scopeData: EnhancedScopeData) => void;
  closeDrawer: (callback: VoidFunction) => void;
  setSelectedComponent: (
    componentId: ArdoqId,
    scopeData: EnhancedScopeData
  ) => void;
  openViewpoint: (
    componentId: ArdoqId,
    scopeData: EnhancedScopeData,
    viewpointId: ArdoqId,
    viewpoints: APIViewpointAttributes[],
    context: ContextShape,
    loadedStates: LoadedState[],
    handleCloseDrawer: VoidFunction
  ) => void;
};

export const componentOverviewDrawerCommands: ComponentOverviewDrawerCommands =
  {
    openDrawer: (componentId, scopeData) => {
      showDrawer({
        stackPageName: 'inventoryComponentOverview',
        renderDrawer: ({ handleCloserDrawer }) => (
          <ConnectedComponentOverviewDrawer
            handleCloseDrawer={handleCloserDrawer}
          />
        ),
        shouldCloseOnBackdropClick: true,
      });
      setTimeout(() => {
        // setTimeout because the drawer has to be rendered before the action is dispatched
        dispatchAction(
          selectedComponentForOverviewDrawerChanged({ componentId, scopeData })
        );
      });
      inventoryTracking.trackOpenComponentDrawer();
    },
    closeDrawer: callback => {
      callback();
      inventoryTracking.trackCloseComponentDrawer();
    },
    setSelectedComponent: (componentId, scopeData) => {
      dispatchAction(
        selectedComponentForOverviewDrawerChanged({
          componentId,
          scopeData,
        })
      );
      inventoryTracking.trackSelectComponentFromComponentDrawer();
    },
    openViewpoint: (
      componentId,
      scopeData,
      viewpointId,
      viewpoints,
      context,
      loadedStates,
      handleCloseDrawer
    ) => {
      const viewpoint = viewpoints.find(
        viewpoint => viewpoint._id === viewpointId
      );
      const component = scopeData.componentsById[componentId];
      if (!(viewpoint && component)) return;
      viewpointCommands.openViewpoint({
        component,
        viewpoint,
        context,
        loadedStates,
        onClosingConfirmed: handleCloseDrawer,
      });
      inventoryTracking.trackOpenViewpointFromComponentDrawer();
    },
  };
