import { initIntegration } from 'integrations/actions';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { catchError, tap, filter, switchMap } from 'rxjs';
import { fetchLegacyConfigs, setLegacyConfigs } from './actions';
import {
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { servicenowIntegrationApi } from '@ardoq/api';

const handleInitIntegration = routine(
  ofType(initIntegration),
  extractPayload(),
  filter(({ id }) => id === 'servicenow-v3'),
  tap(() => {
    dispatchAction(fetchLegacyConfigs());
  })
);

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  filter(id => id === 'servicenow-v3'),
  tap(() => {
    dispatchAction(setLegacyConfigs({ configs: [] }));
  })
);

const handleFetchLegacyConfigs = routine(
  ofType(fetchLegacyConfigs),
  switchMap(() =>
    retriableRequest(
      servicenowIntegrationApi.fetchLegacyConfigs,
      'Unable to fetch ServiceNow Legacy Configs'
    )
  ),
  tap(configs => {
    dispatchAction(setLegacyConfigs({ configs }));
  }),
  catchError(handleApiError)
);

export default [
  handleInitIntegration,
  handleResetIntegration,
  handleFetchLegacyConfigs,
];
