import { Box, FlexBox, Stack } from '@ardoq/layout';
import { TraversalBuilderViewState } from '../types';
import { RequiredComponentsCommands } from './requiredComponentsCommands';
import { SmallGhostButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { ComponentType } from '../components/ComponentType';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { Select } from '@ardoq/select';
import { TraversalPathMatchingType } from '@ardoq/api-types';
import styled from 'styled-components';

type Props = {
  state: TraversalBuilderViewState['requiredComponentsState'];
  commands: RequiredComponentsCommands;
};

const RequiredComponentsSelection = ({ state, commands }: Props) => {
  if (!state) return null;

  return (
    <Box width="full">
      {state.items.length > 1 && (
        <FlexBox gap="small" align="center" marginBottom="medium">
          Result must match
          <ConditionSelectWrapper>
            <Select
              value={state.pathMatching}
              onValueChange={val => commands.setPathMatching(val!)}
              options={[
                {
                  label: 'all',
                  value: TraversalPathMatchingType.SUPERSTRICT,
                },
                { label: 'any', value: TraversalPathMatchingType.STRICT },
              ]}
            />
          </ConditionSelectWrapper>
          required
          {state.pathMatching === TraversalPathMatchingType.SUPERSTRICT
            ? ' paths'
            : ' path'}
        </FlexBox>
      )}
      <Stack gap="small">
        {state.items.map(item => {
          return (
            <FlexBox
              key={item.nodeId}
              borderRadius="r4"
              borderColor="borderSubtle00"
              padding="xsmall"
              paddingLeft="small"
              align="center"
              justify="space-between"
              onMouseOver={() => commands.highlightPath(item.nodeId)}
              onMouseLeave={() => commands.highlightPath(null)}
            >
              <FlexBox align="center" gap="xxsmall">
                <ComponentType {...state.traversalStartTypeMetaData} />
                <Icon iconName={IconName.ARROW_FORWARD} color={colors.icon} />
                <ComponentType {...item.componentTypeMetaData} />
              </FlexBox>
              <SmallGhostButton
                onClick={() => commands.removeRequiredComponent(item.nodeId)}
                onFocus={() => commands.highlightPath(item.nodeId)}
                onBlur={() => commands.highlightPath(null)}
              >
                <Icon iconName={IconName.DELETE} />
                <VisuallyHidden>Delete</VisuallyHidden>
              </SmallGhostButton>
            </FlexBox>
          );
        })}
      </Stack>
    </Box>
  );
};

const ConditionSelectWrapper = styled.div`
  width: 80px;
`;

export default RequiredComponentsSelection;
