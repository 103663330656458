import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
  ActionCreatorWithoutNamespace,
} from '@ardoq/rxbeach';
import {
  createZone,
  createZoneFailure,
  createZoneSuccessfully,
  deleteZone,
  deleteZoneFailure,
  deleteZoneSuccessfully,
  addComponentsToSubdivision,
  removeComponentsFromSubdivision,
  fetchAllZones,
  fetchedZonesSuccessfully,
  fetchingZonesFailure,
  updateZone,
  updateZoneFailure,
  updateZoneSuccessfully,
  fetchedSubdivisionsSuccessfully,
  fetchAllSubdivisions,
  saveSubdivisionDetailsSuccessfully,
  saveSubdivisionDetailsFailure,
  fetchingSubdivisionsFailure,
  saveSubdivisionDetailsChanges,
  deleteSubdivision,
  deleteSubdivisionSuccessfully,
  deleteSubdivisionFailure,
  addResourcesToSubdivision,
  removeComponentsFromSubdivisionFailure,
  removeComponentsFromSubdivisionSuccess,
} from './actions';
import { tap, switchMap, forkJoin, of, filter } from 'rxjs';
import { logError } from '@ardoq/logging';
import {
  getErrorStatusCode,
  handleError,
  permissionZoneApi,
  subdivisionApi,
} from '@ardoq/api';
import { Team } from '@ardoq/profiling';
import { ApiZonesFailurePayload } from './types';
import { ToastType, showToast } from '@ardoq/status-ui';
import { subdivisionsOperations } from '@ardoq/subdivisions';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { SubdivisionsMembershipsMap } from '@ardoq/api-types';
import { ArdoqError, getArdoqErrorMessage } from '@ardoq/common-helpers';

const STRINGS = SUBDIVISIONS_STRINGS.ROUTINES;

const log = (errorType: string, error: ArdoqError) => {
  logError(error, errorType, { tags: { team: Team.APPSEC } });
  return {
    errorMessage: getArdoqErrorMessage(error, errorType),
    status: getErrorStatusCode(error),
  };
};

const logAndDispatch = (
  errorType: string,
  actionFunc: ActionCreatorWithoutNamespace<ApiZonesFailurePayload>,
  error: ArdoqError
) => {
  const { errorMessage, status } = log(errorType, error);
  showToast(errorMessage, ToastType.INFO);
  dispatchAction(actionFunc({ message: errorMessage, status }));
};

const logHandler = (errorType: string) => (error: ArdoqError) =>
  log(errorType, error);

const logAndDispatchHandler =
  (
    errorType: string,
    actionFunc: ActionCreatorWithoutNamespace<ApiZonesFailurePayload>
  ) =>
  (error: ArdoqError) =>
    logAndDispatch(errorType, actionFunc, error);

const getZonesRoutine = routine(
  ofType(fetchAllZones),
  switchMap(permissionZoneApi.fetchAll),
  handleError(
    logAndDispatchHandler(STRINGS.FETCHING_ZONES_ERROR, fetchingZonesFailure)
  ),
  tap(response => dispatchAction(fetchedZonesSuccessfully(response)))
);

const createZoneRoutine = routine(
  ofType(createZone),
  extractPayload(),
  switchMap(permissionZoneApi.create),
  handleError(
    logAndDispatchHandler(STRINGS.CREATE_ZONE_ERROR, createZoneFailure)
  ),
  tap(zone => dispatchAction(createZoneSuccessfully(zone)))
);

const updateZoneRoutine = routine(
  ofType(updateZone),
  extractPayload(),
  switchMap(permissionZoneApi.update),
  handleError(
    logAndDispatchHandler(STRINGS.UPDATE_ZONE_ERROR, updateZoneFailure)
  ),
  tap(zone => dispatchAction(updateZoneSuccessfully(zone)))
);

const deleteZoneRoutine = routine(
  ofType(deleteZone),
  extractPayload(),
  switchMap(({ _id }) =>
    forkJoin({ zoneId: of(_id), response: permissionZoneApi.delete(_id) })
  ),
  handleError(response => {
    logAndDispatch(STRINGS.DELETE_ZONE_ERROR, deleteZoneFailure, response);
    return;
  }),
  tap(({ zoneId }) => {
    dispatchAction(deleteZoneSuccessfully(zoneId));
  })
);

const handleAddComponentsToSubdivision = routine(
  ofType(addComponentsToSubdivision),
  extractPayload(),
  switchMap(subdivisionApi.addComponents),
  handleError(logHandler(STRINGS.ADD_COMPONENTS_ERROR)),
  handleError(response => {
    log(STRINGS.ADD_COMPONENTS_ERROR, response);
    showToast(STRINGS.ADD_COMPONENTS_ERROR);
    return;
  }),
  tap(() => {
    showToast(STRINGS.MEMBERSHIP_ADDED);
  })
);

const handleAddResourcesToSubdivision = routine(
  ofType(addResourcesToSubdivision),
  extractPayload(),
  switchMap(subdivisionApi.addResources),
  handleError(response => {
    logHandler(STRINGS.ADD_RESOURCE_ERROR);
    log(STRINGS.ADD_RESOURCE_ERROR, response);
    showToast(STRINGS.ADD_RESOURCE_ERROR);
  })
);

const handleremoveComponentsFromSubdivision = routine(
  ofType(removeComponentsFromSubdivision),
  extractPayload(),
  filter((payload: SubdivisionsMembershipsMap) => {
    const shouldSave = Object.values(payload).some(
      components => components.length > 0
    );
    if (!shouldSave) {
      dispatchAction(removeComponentsFromSubdivisionSuccess());
    }
    return shouldSave;
  }),
  switchMap(subdivisionApi.removeComponents),
  handleError(response => {
    log(STRINGS.REMOVE_COMPONENTS_ERROR, response);
    showToast(STRINGS.REMOVE_COMPONENTS_ERROR);
    dispatchAction(removeComponentsFromSubdivisionFailure());
  }),
  tap(() => {
    dispatchAction(removeComponentsFromSubdivisionSuccess());
    showToast(STRINGS.MEMBERSHIP_REVOKED, ToastType.SUCCESS);
  })
);

// --------------------------------------------------------
// -----------      Subdivisions STRINGS      ------------
// --------------------------------------------------------

const getSubdivisionRoutine = routine(
  ofType(fetchAllSubdivisions),
  switchMap(subdivisionApi.fetchAll),
  handleError(
    logAndDispatchHandler(STRINGS.FETCH_ERROR, fetchingSubdivisionsFailure)
  ),
  tap(response => dispatchAction(fetchedSubdivisionsSuccessfully(response)))
);

const deleteSubdivisionRoutine = routine(
  ofType(deleteSubdivision),
  extractPayload(),
  switchMap(subdivisionId =>
    forkJoin({
      subdivisionId: of(subdivisionId),
      response: subdivisionApi.delete(subdivisionId),
    })
  ),
  handleError(response => {
    logAndDispatch(STRINGS.FETCH_ERROR, deleteSubdivisionFailure, response);
  }),
  tap(({ subdivisionId }) => {
    dispatchAction(deleteSubdivisionSuccessfully(subdivisionId));
  })
);

const createSubdivisionRoutine = routine(
  ofType(saveSubdivisionDetailsChanges),
  extractPayload(),
  switchMap(subdivision => {
    if (subdivisionsOperations.isPersistedSubdivision(subdivision)) {
      return subdivisionApi.update(subdivision);
    }
    return subdivisionApi.create({
      name: subdivision.name,
      description: subdivision.description,
      style: subdivision.style,
      advancedSearchQueries: [],
      componentAssignmentsMode: subdivision.componentAssignmentsMode,
    });
  }),
  handleError(
    logAndDispatchHandler(STRINGS.SAVE_ERROR, saveSubdivisionDetailsFailure)
  ),
  tap(subdivision =>
    dispatchAction(saveSubdivisionDetailsSuccessfully(subdivision))
  ),
  tap(() => dispatchAction(fetchAllZones()))
);

export const subdivisionRoutines = collectRoutines(
  getZonesRoutine,
  createZoneRoutine,
  updateZoneRoutine,
  deleteZoneRoutine,
  handleAddComponentsToSubdivision,
  handleremoveComponentsFromSubdivision,
  // Subdivisions Routines
  getSubdivisionRoutine,
  createSubdivisionRoutine,
  deleteSubdivisionRoutine,
  handleAddResourcesToSubdivision
);
