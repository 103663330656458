import { dispatchAction } from '@ardoq/rxbeach';
import { ViewInferenceCommands } from './types';
import {
  closeViewInferenceWindow,
  setViewInferenceInput,
  sendViewInference,
  applySuggestion,
} from './actions';

export const viewInferenceCommands: ViewInferenceCommands = {
  closeWindow: () => dispatchAction(closeViewInferenceWindow()),
  setInput: (value: string) => dispatchAction(setViewInferenceInput(value)),
  send: payload => dispatchAction(sendViewInference(payload)),
  applySuggestion: suggestion => dispatchAction(applySuggestion(suggestion)),
};
