import {
  Field,
  GroupFieldLayout,
  RadioGroup,
  RadioGroupItem,
} from '@ardoq/forms';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateScheduleRecurrence } from 'broadcasts/actions';

type ScheduleRecurrenceSelectorProps = {
  readonly isRepeating: boolean;
};

enum ScheduleType {
  REPEATING = 'repeating',
  SINGLE = 'single',
}

const recurrenceOptions = [
  {
    value: ScheduleType.REPEATING,
    label: 'Run a repeating broadcast',
  },
  { value: ScheduleType.SINGLE, label: 'Run a single broadcast' },
];

const ScheduleRecurrenceSelector = ({
  isRepeating,
}: ScheduleRecurrenceSelectorProps) => {
  return (
    <Field>
      <RadioGroup
        label="Schedule"
        groupFieldLayout={GroupFieldLayout.HORIZONTAL}
        onValueChange={scheduleType => {
          dispatchAction(
            updateScheduleRecurrence(
              (scheduleType as ScheduleType) === ScheduleType.REPEATING
            )
          );
        }}
        // it was `currentValue` here before, but I think the expected prop is `value`
        value={isRepeating ? ScheduleType.REPEATING : ScheduleType.SINGLE}
      >
        {recurrenceOptions.map((radioItem, i) => (
          <RadioGroupItem key={i} value={radioItem.value}>
            {radioItem.label}
          </RadioGroupItem>
        ))}
      </RadioGroup>
    </Field>
  );
};

export default ScheduleRecurrenceSelector;
