import { ViewIds } from '@ardoq/api-types';
import { SvgImage, SvgImageName } from '@ardoq/icons';
import { getViewThumbnailSrc } from 'tabview/consts';

const viewIdToNewThumbnailSvgImageName = new Map([
  [ViewIds.SIMPLE_BLOCK_DIAGRAM, SvgImageName.SIMPLE_BLOCK_DIAGRAM],
  [ViewIds.BLOCK_DIAGRAM, SvgImageName.SIMPLE_BLOCK_DIAGRAM],
  [ViewIds.MODERNIZED_BLOCK_DIAGRAM, SvgImageName.SIMPLE_BLOCK_DIAGRAM],
  [ViewIds.TIMELINE, SvgImageName.TIMELINE],
  [ViewIds.RELATIONSHIPS, SvgImageName.NETWORK],
  [ViewIds.RELATIONSHIPS_3, SvgImageName.NETWORK],
  [ViewIds.DEPENDENCY_MAP_2, SvgImageName.DEPENDENCY_MAP],
]);

type VisualizationThumbnailProps = {
  viewId: ViewIds;
};

const VisualizationThumbnail = ({ viewId }: VisualizationThumbnailProps) => {
  const newThumbnailSvgImageName = viewIdToNewThumbnailSvgImageName.get(viewId);
  if (newThumbnailSvgImageName) {
    return <SvgImage svgImageName={newThumbnailSvgImageName} />;
  }
  return <img src={`/${getViewThumbnailSrc(viewId)}`} />;
};

export default VisualizationThumbnail;
