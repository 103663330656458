import { ExtractPayload, reducer } from '@ardoq/rxbeach';
import {
  getImmutableFirstLineOfQuery,
  getWarningMessageFromGremlinResults,
  isFormValid,
} from './utils';
import {
  fetchContentIds,
  fetchContentIdsError,
  fetchGremlinAudiencePreview,
  notifyFailedToFetchGremlinAudiencePreview,
  notifyRunningGremlinAudienceQuery,
  notifyRunningGremlinAudienceQueryFailed,
  notifyRunningGremlinAudienceQuerySucceeded,
  openExistingGremlinAudienceDialog,
  openNewGremlinAudienceDialog,
  setContentIdsAndTotalNumberOfContentIds,
  setGremlinAudiencePreview,
  setTableFilter,
  updateGremlinAudienceQuery,
} from './actions';
import { FetchStatus, GremlinAudienceDialogStreamShape } from './types';
import { DEFAULT_GREMLIN_AUDIENCE_DIALOG_QUERY } from 'broadcasts/consts';
import { ArdoqId, APIAudiencePreviewResponse } from '@ardoq/api-types';
import {
  getWarningMessageFromAudiencePreview,
  toComponentMappedPreviewAudience,
} from 'broadcasts/utils';
import { GremlinResult } from 'search/Gremlin/GremlinResults/types';

const handleFetchContentIds = (
  state: GremlinAudienceDialogStreamShape
): GremlinAudienceDialogStreamShape => {
  const contentIds: ArdoqId[] = [];
  const totalNumberOfContentIds = 0;
  return {
    ...state,
    contentIdsFetchStatus: FetchStatus.LOADING,
    contentIds,
    totalNumberOfContentIds,
    immutableFirstLineOfQuery: getImmutableFirstLineOfQuery(
      contentIds,
      totalNumberOfContentIds
    ),
  };
};

const handleFetchContentIdsError = (
  state: GremlinAudienceDialogStreamShape
): GremlinAudienceDialogStreamShape => {
  const contentIds: ArdoqId[] = [];
  const totalNumberOfContentIds = 0;
  return {
    ...state,
    contentIdsFetchStatus: FetchStatus.ERROR,
    contentIds,
    totalNumberOfContentIds,
    immutableFirstLineOfQuery: getImmutableFirstLineOfQuery(
      contentIds,
      totalNumberOfContentIds
    ),
  };
};

const handleSetContentIdsAndTotalNumberOfContentIds = (
  state: GremlinAudienceDialogStreamShape,
  {
    contentIds,
    totalNumberOfContentIds,
  }: ExtractPayload<typeof setContentIdsAndTotalNumberOfContentIds>
): GremlinAudienceDialogStreamShape => ({
  ...state,
  contentIdsFetchStatus: FetchStatus.SUCCESS,
  contentIds,
  totalNumberOfContentIds,
  immutableFirstLineOfQuery: getImmutableFirstLineOfQuery(
    contentIds,
    totalNumberOfContentIds
  ),
});

const handleFetchGremlinAudiencePreview = (
  state: GremlinAudienceDialogStreamShape
): GremlinAudienceDialogStreamShape => ({
  ...state,
  isFetchingAudiencePreview: true,
  failedToFetchAudiencePreview: false,
});

const handleSetGremlinAudiencePreview = (
  state: GremlinAudienceDialogStreamShape,
  apiAudiencePreview: APIAudiencePreviewResponse
): GremlinAudienceDialogStreamShape => {
  const mappedAudiencePreview =
    toComponentMappedPreviewAudience(apiAudiencePreview);
  return {
    ...state,
    audiencePreview: mappedAudiencePreview,
    isFetchingAudiencePreview: false,
    failedToFetchAudiencePreview: false,
    audiencePreviewWarningMessage: getWarningMessageFromAudiencePreview(
      mappedAudiencePreview
    ),
  };
};

const handleNotifyFailedToFetchGremlinAudiencePreview = (
  state: GremlinAudienceDialogStreamShape
): GremlinAudienceDialogStreamShape => ({
  ...state,
  audiencePreview: [],
  isFetchingAudiencePreview: false,
  failedToFetchAudiencePreview: true,
  audiencePreviewWarningMessage: null,
});

const handleOpenNewGremlinAudienceDialog = (
  state: GremlinAudienceDialogStreamShape
): GremlinAudienceDialogStreamShape => {
  const query = DEFAULT_GREMLIN_AUDIENCE_DIALOG_QUERY;
  return {
    ...state,
    isUpdatingExistingAudience: false,
    query,
    formIsValid: isFormValid(query),
  };
};

const handleOpenExistingGremlinAudienceDialog = (
  state: GremlinAudienceDialogStreamShape,
  query: string
): GremlinAudienceDialogStreamShape => ({
  ...state,
  isUpdatingExistingAudience: true,
  query,
  formIsValid: isFormValid(query),
});

const handleUpdateGremlinAudienceQuery = (
  state: GremlinAudienceDialogStreamShape,
  query: string
): GremlinAudienceDialogStreamShape => ({
  ...state,
  query,
  formIsValid: isFormValid(query),
});

const handleNotifyRunningGremlinAudienceQuery = (
  state: GremlinAudienceDialogStreamShape
): GremlinAudienceDialogStreamShape => ({ ...state, isSearching: true });

const handleNotifyRunningGremlinAudienceQuerySucceeded = (
  state: GremlinAudienceDialogStreamShape,
  results: GremlinResult[]
): GremlinAudienceDialogStreamShape => ({
  ...state,
  isSearching: false,
  hasGremlinSearchError: false,
  gremlinSyntaxError: null,
  gremlinWarningMessage: getWarningMessageFromGremlinResults(results),
});

const handleNotifyRunningGremlinAudienceQueryFailed = (
  state: GremlinAudienceDialogStreamShape,
  gremlinSyntaxError: string | null
): GremlinAudienceDialogStreamShape => ({
  ...state,
  isSearching: false,
  audiencePreviewWarningMessage: null,
  hasGremlinSearchError: true,
  gremlinSyntaxError,
  gremlinWarningMessage: null,
});

const handleSetTableFilter = (
  state: GremlinAudienceDialogStreamShape,
  tableFilter: string
): GremlinAudienceDialogStreamShape => {
  return {
    ...state,
    tableFilter,
  };
};

export const reducers = [
  reducer(fetchContentIds, handleFetchContentIds),
  reducer(fetchContentIdsError, handleFetchContentIdsError),
  reducer(
    setContentIdsAndTotalNumberOfContentIds,
    handleSetContentIdsAndTotalNumberOfContentIds
  ),
  reducer(fetchGremlinAudiencePreview, handleFetchGremlinAudiencePreview),
  reducer(setGremlinAudiencePreview, handleSetGremlinAudiencePreview),
  reducer(
    notifyFailedToFetchGremlinAudiencePreview,
    handleNotifyFailedToFetchGremlinAudiencePreview
  ),
  reducer(openNewGremlinAudienceDialog, handleOpenNewGremlinAudienceDialog),
  reducer(
    openExistingGremlinAudienceDialog,
    handleOpenExistingGremlinAudienceDialog
  ),
  reducer(updateGremlinAudienceQuery, handleUpdateGremlinAudienceQuery),
  reducer(
    notifyRunningGremlinAudienceQuery,
    handleNotifyRunningGremlinAudienceQuery
  ),
  reducer(
    notifyRunningGremlinAudienceQuerySucceeded,
    handleNotifyRunningGremlinAudienceQuerySucceeded
  ),
  reducer(
    notifyRunningGremlinAudienceQueryFailed,
    handleNotifyRunningGremlinAudienceQueryFailed
  ),
  reducer(setTableFilter, handleSetTableFilter),
];
