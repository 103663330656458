import {
  AppliedSettingsData,
  LabelSetting,
  LegacyLabelSetting,
} from './appliedSettings/types';
import { ViewIds, ViewpointSavedViewSettings } from '@ardoq/api-types';
import { viewIdToUserFriendlyName } from 'views/metaInfoTabs';

import { SaveViewpointTrackingData } from './tracking';

const getSaveStatus = (isExistingViewpoint: boolean, saveAsNew: boolean) => {
  if (isExistingViewpoint) {
    return saveAsNew ? 'save as new' : 'updated existing';
  }
  return 'saved';
};

const isLegacyLabelSetting = (
  labelSettings: LegacyLabelSetting | LabelSetting
): labelSettings is LegacyLabelSetting => 'customLabelsCount' in labelSettings;

type SaveViewpointTrackingArgs = {
  appliedSettings: AppliedSettingsData;
  viewSettings: ViewpointSavedViewSettings;
  saveAsNew: boolean;
  viewName: ViewIds;
};

const getSaveViewpointTrackingData = ({
  appliedSettings,
  viewName,
  saveAsNew,
  viewSettings,
}: SaveViewpointTrackingArgs): SaveViewpointTrackingData => {
  const {
    componentAndReferenceTypesSettings: {
      componentTypesCount: numberOfComponents,
      referenceTypesCount: numberOfReferences,
    },
    filterSettings: { appliedFiltersCount: numberOfFilters },
    groupingSettings: { appliedGroupingRuleDescriptions: numberOfGroups },
    formattingSettings: { appliedRulesCount: numberOfFormattingRules },
    labelSettings,
  } = appliedSettings;

  const numberOfLabels = isLegacyLabelSetting(labelSettings)
    ? labelSettings.customLabelsCount
    : labelSettings.labelFormatting.length;

  const saved = getSaveStatus(
    Boolean(appliedSettings.viewpointData?.traversalId),
    saveAsNew
  );

  return {
    numberOfFormattingRules,
    numberOfComponents,
    numberOfReferences,
    numberOfFilters,
    numberOfLabels,
    viewSettings,
    saved,
    numberOfGroups: numberOfGroups.length,
    viewStyle: viewIdToUserFriendlyName[viewName],
    viewId: viewName,
  };
};

export default getSaveViewpointTrackingData;
