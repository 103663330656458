import {
  SuccessState,
  TransferState,
  DryRunStatus,
  TableSummary,
  ComplaintsIndices,
  TableComplaints,
  ComplaintsByLevel,
  TransferDirection,
} from './types';
import fp from 'lodash/fp';
import {
  TableId,
  TabularMapping,
} from 'integrations/common/streams/tabularMappings/types';
import { withoutEmptyTableMappings } from 'integrations/common/streams/tabularMappings/utils';
import { emptyComplaintsIndices } from './constants';
import { Dictionary } from 'integrations/common/types/common';
import {
  Complaint,
  ComplaintLevel,
  TransferEffectSummaries,
} from '@ardoq/api-types/integrations';
import { Features, hasFeature } from '@ardoq/features';

export const isSuccessTransfer = (
  state: TransferState
): state is SuccessState => {
  return Boolean(state.requestStatus === 'SUCCESS' && state.response);
};

export const complaintsToDryRunStatus = (
  complaints: Complaint[]
): DryRunStatus => {
  return complaints.length === 0
    ? DryRunStatus.SUCCESS
    : fp.some(complaint => complaint.level === 'error', complaints)
      ? DryRunStatus.ERROR
      : DryRunStatus.WARNING;
};

export const successStateToDryRunStatus = (
  transferState: SuccessState
): DryRunStatus =>
  complaintsToDryRunStatus(transferState.response.totals.validation);

const rowsIndicesPicker = (complaints: Complaint[]) =>
  fp.flow(
    fp.map<Complaint, number | undefined>(c => c.rowIndex),
    fp.reject<number>(fp.isUndefined),
    fp.uniq,
    fp.sortBy(fp.identity)
  )(complaints);

export const eliminateDuplicateFollowups = (
  errors: number[],
  warnings: number[]
) => {
  return [...errors, ...fp.difference(warnings, errors)];
};

export const getRowIndicesWithProblems = (
  tableComplaints?: TableComplaints
): ComplaintsIndices => {
  if (!tableComplaints?.level) {
    return emptyComplaintsIndices;
  }

  const { error, warn } = tableComplaints.level;

  const errors = rowsIndicesPicker(error || []);
  const warnings = rowsIndicesPicker(warn || []);

  return {
    errors: errors,
    warnings: warnings,
    byPriority: eliminateDuplicateFollowups(errors, warnings),
  };
};

export const getUnknownError = (message: string | undefined | null) => [
  {
    level: 'error',
    messageKey: '',
    message: message || 'Unknown error',
    target: 'sheet',
    tableId: '',
  } satisfies Complaint,
];

export const getComplaints = (validation: Complaint[] = []) => {
  const errors = fp.filter(
    complaint => complaint.level === 'error',
    validation
  );
  const warnings = fp.filter(
    complaint => complaint.level === 'warn',
    validation
  );
  return { errors, warnings };
};

export const complaintsToComplaintLevel = (complaints: Complaint[]) => {
  return complaints.find(({ level }) => level === 'error')
    ? 'Error'
    : complaints.find(({ level }) => level === 'warn')
      ? 'Warning'
      : null;
};

export const isDryRunOutOfDate = (
  transferState: TransferState,
  tabularMapping: TabularMapping
) => {
  return (
    isSuccessTransfer(transferState) &&
    !fp.isEqual(
      transferState.tabularMapping,
      withoutEmptyTableMappings(tabularMapping)
    )
  );
};

const tableComplaints = (complaints: Complaint[]): TableComplaints => {
  const by = <T extends keyof Complaint>(
    key: T,
    complaints: Complaint[]
  ): Record<string, Complaint[]> =>
    fp.flow(
      fp.filter<Complaint>(c => c[key] !== undefined),
      fp.groupBy<Complaint>(key)
    )(complaints);

  const level = by('level', complaints);

  const row = fp.mapValues(
    (cs: Complaint[]) => {
      const { cell, row } = fp.merge({ cell: [], row: [] }, by('target', cs));
      return {
        level: by('level', row),
        cellLevel: by('level', cell),
        column:
          fp.mapValues(
            cs => ({ level: by('level', cs) }),
            by('columnIndex', cell)
          ) || {},
      };
    },
    by('rowIndex', complaints)
  );

  const column =
    fp.mapValues(
      (cs: Complaint[]) => {
        const { cell, column, row, config } = fp.merge(
          { cell: [], column: [], row: [] },
          by('target', cs)
        );
        return {
          level: column.length ? by('level', column) : by('level', config),
          cellLevel: cell.length ? by('level', cell) : by('level', row),
          row:
            fp.mapValues(
              cs => ({ level: by('level', cs) }),
              cell.length ? by('rowIndex', cell) : by('rowIndex', row)
            ) || {},
        };
      },
      by('columnIndex', complaints)
    ) || {};

  return {
    level,
    row,
    column,
  };
};

export const tablesComplaints = (
  tables: TableSummary[]
): Dictionary<TableComplaints> =>
  fp.reduce(
    (result, { validation, id }) =>
      fp.set(id, tableComplaints(validation), result),
    {},
    tables
  );

export const complaintsByLevelToComplaintLevel = (
  byLevel: ComplaintsByLevel
): ComplaintLevel | null =>
  byLevel?.error?.length ? 'error' : byLevel?.warn?.length ? 'warn' : null;

const complaintLevelToDryRunStatus = (
  complaintLevel: ComplaintLevel | null
): DryRunStatus =>
  !complaintLevel
    ? DryRunStatus.SUCCESS
    : complaintLevel === 'error'
      ? DryRunStatus.ERROR
      : DryRunStatus.WARNING;

export const tablesComplaintsToTablesStatuses = (
  tablesComplaints: Record<TableId, TableComplaints>
): Record<TableId, DryRunStatus> =>
  fp.mapValues(
    ({ level = {} }) =>
      complaintLevelToDryRunStatus(complaintsByLevelToComplaintLevel(level)),
    tablesComplaints
  );

export const dryRunStatusesCounts = (
  tablesComplaints: Record<TableId, TableComplaints>
): Partial<Record<DryRunStatus, number>> =>
  fp.countBy(
    fp.identity,
    fp.values(tablesComplaintsToTablesStatuses(tablesComplaints))
  );

export const isTransferEffectsEmpty = (
  importEffects: TransferEffectSummaries
) => {
  const isAllZero = fp.flow(
    fp.values,
    fp.filter(fp.isNumber),
    fp.every(fp.eq(0))
  );

  return fp.every(isAllZero, [
    importEffects.updated,
    importEffects.created,
    importEffects.deleted,
  ]);
};

export const isResourceCreationDisabled = (transferState: TransferState) =>
  hasFeature(Features.QUICK_START) ||
  transferState.transferDirection === TransferDirection.EXPORT;

export const isTypeSelectionDisabled = (transferState: TransferState) =>
  transferState.transferDirection !== TransferDirection.EXPORT &&
  hasFeature(Features.QUICK_START);

export const isSyncStrategyOptionsDisabled = (transferState: TransferState) =>
  transferState.transferDirection === TransferDirection.EXPORT;
