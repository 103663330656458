import { action$, carry, extractPayload, ofType } from '@ardoq/rxbeach';
import { MetaModel } from '@ardoq/api-types';
import { RequestLoadMetaModelPayload, requestLoadMetaModel } from './actions';
import {
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  switchMap,
} from 'rxjs';
import { metaModelOperations } from './metaModelOperations';
import { metamodel2Api } from '@ardoq/api';
import { ArdoqError, isArdoqError } from '@ardoq/common-helpers';
import { isEqual } from 'lodash';

export type MetaModelLoadedState = ArdoqError | MetaModel;

export const loadMetaModel = async (): Promise<MetaModelLoadedState> => {
  const result = await metamodel2Api.fetch();
  if (isArdoqError(result)) {
    return result;
  }

  result.componentTypes.forEach(componentType => {
    const { style, usedInWorkspaceIds } = componentType;

    // TODO fix it on the BE.
    // Workaround, clear the image if we have an icon for a given component type
    if (style.icon) {
      style.image = null;
    }

    // TODO PHNX-730 - Fix to eliminate null values - can be removed when BE is cleaned up
    componentType.usedInWorkspaceIds = usedInWorkspaceIds?.filter(Boolean);
  });

  return result;
};

const extendMetamodelWithCurrentTriples = ([payload, result]: [
  RequestLoadMetaModelPayload,
  MetaModelLoadedState,
]): MetaModelLoadedState => {
  if (isArdoqError(result) || !payload?.currentTriples) {
    return result;
  }

  return metaModelOperations.extendMetamodelWithTriples(
    result,
    payload.currentTriples
  );
};

export const loadMetaModel$: Observable<MetaModelLoadedState> = action$.pipe(
  ofType(requestLoadMetaModel),
  extractPayload(),
  carry(switchMap(loadMetaModel)),
  map(extendMetamodelWithCurrentTriples),
  // When the user opens the viewpoint builder, we request the current metamodel.
  // However, the metamodel is also cached for use in the viewpoint panel to
  // ensure consistent type styling. Processing the metamodel in the viewpoint
  // builder triggers additional updates, such as requesting instance counts for
  // the current graph. These updates can be resource-intensive and are
  // unnecessary if the cached metamodel is identical to the newly requested one.
  // Therefore, we skip such updates when there is no difference between the
  // cached and newly requested metamodel.
  distinctUntilChanged(isEqual),
  shareReplay({ bufferSize: 1, refCount: true })
);
