import AudienceTypeLabel from 'broadcasts/components/audienceTag/audienceTagLabel/AudienceTypeLabel';
import PredefinedQueries from 'broadcasts/components/audienceTag/audienceTagLabel/PredefinedQueries';
import { BroadcastAudience, BroadcastAudienceType } from '@ardoq/api-types';
import { ResultCount } from 'broadcasts/components/audienceTag/audienceTagLabel/atoms';

type AudienceTagLabelProps = {
  audience: BroadcastAudience;
  resultCount: number | null;
};

const AudienceTagLabel = ({ audience, resultCount }: AudienceTagLabelProps) => (
  <>
    <AudienceTypeLabel audienceType={audience.audienceType} />
    {resultCount !== null && <ResultCount> ({resultCount}) </ResultCount>}
    {audience.audienceType === BroadcastAudienceType.PREDEFINED && (
      <>
        : <PredefinedQueries queryOptions={audience.options} />
      </>
    )}
  </>
);

export default AudienceTagLabel;
