import { ErrorNotification } from '@ardoq/status-ui';
import { showSupport } from '../../utils/support';
import { Link } from '@ardoq/typography';

const ChatTimedOutErrorNotification = () => (
  <ErrorNotification>
    We&apos;re unable to load the message. Please type the question again. If
    the issue continues,{' '}
    <Link
      typography="text2"
      hideIcon
      onClick={() => showSupport({ chat: true })}
    >
      contact support
    </Link>
  </ErrorNotification>
);

export default ChatTimedOutErrorNotification;
