import { actionCreator } from '@ardoq/rxbeach';
import { ComponentBackboneModel, Reference } from 'aqTypes';
import { ContextSort, Maybe } from '@ardoq/common-helpers';
import { TrackingLocation } from 'tracking/types';
import { ArdoqId, APIPresentationAssetAttributes } from '@ardoq/api-types';

interface SortChanged {
  readonly sort: ContextSort;
}

export const notifySortChanged = actionCreator<SortChanged>(
  '[ContextActions] NOTIFY_SORT_CHANGED'
);

export interface WorkspaceChanged {
  workspaceId: Maybe<ArdoqId>;
}

export const notifyWorkspaceChanged = actionCreator<WorkspaceChanged>(
  '[ContextActions] NOTIFY_WORKSPACE_CHANGED'
);

export const notifyScenarioChanged = actionCreator<ArdoqId | null>(
  '[ContextActions] NOTIFY_SCENARIO_CHANGED'
);

export interface PayloadPresentationChanged {
  readonly presentation: APIPresentationAssetAttributes | null;
}

export const notifyPresentationChanged =
  actionCreator<PayloadPresentationChanged>(
    '[ContextActions] NOTIFY_PRESENTATION_CHANGED'
  );

export interface PayloadSetSlideId {
  readonly selectedSlideId: string | null;
}

export const setSelectedSlideId = actionCreator<PayloadSetSlideId>(
  '[ContextActions] SET_SLIDE_ID'
);

export interface PayloadSetLoadedSlideId {
  readonly loadedSlideId: string | null;
}

export const setLoadedSlideId = actionCreator<PayloadSetLoadedSlideId>(
  '[ContextActions] SET_LOADED_SLIDE_ID'
);

export interface ComponentChanged {
  readonly component: ComponentBackboneModel | null;
}

export const notifyComponentChanged = actionCreator<ComponentChanged>(
  '[ContextActions] NOTIFY_COMPONENT_CHANGED'
);

export const notifyReferenceContextChanged = actionCreator<Reference | null>(
  '[ContextActions] NOTIFY_REFERENCE_CONTEXT_CHANGED'
);

export const applicationStarted = actionCreator(
  '[ContextActions] NOTIFY_APPLICATION_STARTED'
);

export const notifyContextWorkspaceOrderChanged = actionCreator(
  '[context] WORKSPACES_REORDRED'
);

export const notifyWorkspaceOpened = actionCreator<{
  id?: string;
  useCase?: string;
  trackingLocation?: TrackingLocation;
}>('[ContextActions] NOTIFY_WORKSPACE_OPENED');

type NotifyWorkspaceClosedPayload = { workspaceId: ArdoqId };
export const notifyWorkspaceClosed =
  actionCreator<NotifyWorkspaceClosedPayload>(
    '[ContextActions] NOTIFY_WORKSPACE_CLOSED'
  );
