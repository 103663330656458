import {
  ComponentOrReferenceFormattingRule,
  Formatting,
  FormattingGroup,
  FormattingRule,
} from '@ardoq/perspectives-sidebar';
import { getCustomFieldsFormattingGroupIfAnyFieldIsAvailableOrNull } from '@ardoq/perspectives';
import { APIEntityType } from '@ardoq/api-types';
import { dateRangeOperations } from '@ardoq/date-range';
import { fieldInterface } from '@ardoq/field-interface';
import { isString } from 'lodash';

const getCustomFieldFormatting = (
  rule: ComponentOrReferenceFormattingRule
): Formatting | undefined => {
  const isCombinedDateRangeField =
    dateRangeOperations.isCombinedDateRangeFieldNames(rule.field);

  const fieldName = isCombinedDateRangeField
    ? dateRangeOperations.toStartDateName(
        dateRangeOperations.extractDateRangeFieldName(rule.field)
      )
    : rule.field;

  const fieldData = fieldInterface.getByName(fieldName, {
    acrossWorkspaces: true,
    includeTemplateFields: true,
  });

  if (!fieldData) {
    return;
  }

  const formattingGroup =
    getCustomFieldsFormattingGroupIfAnyFieldIsAvailableOrNull([fieldData]);

  // For the combined date range field, we have two formatting options.
  // The first one is for the start date, which we don't need.
  const formatting = formattingGroup?.filters[isCombinedDateRangeField ? 1 : 0];

  if (formatting) {
    formatting.isDisabled = true;
    return formatting;
  }
};

type GetInvalidRuleFilterFunctionArgs = {
  conditionalFormattingRules: FormattingRule[];
  componentsFormattingGroups: FormattingGroup[];
  referencesFormattingGroups: FormattingGroup[];
};
export const getFormattingForInvalidRuleByRuleId = ({
  conditionalFormattingRules,
  componentsFormattingGroups,
  referencesFormattingGroups,
}: GetInvalidRuleFilterFunctionArgs) => {
  const formattingForInvalidRuleByRuleId: Record<string, Formatting> = {};

  conditionalFormattingRules.forEach(rule => {
    if (
      rule.type === APIEntityType.COMPONENT ||
      rule.type === APIEntityType.REFERENCE
    ) {
      const isComponent = rule.type === APIEntityType.COMPONENT;

      const formattingGroups = isComponent
        ? componentsFormattingGroups
        : referencesFormattingGroups;

      const existingFormatting = formattingGroups
        .flatMap(({ filters }) => filters)
        .find(group => group.id === rule.field);

      if (!existingFormatting) {
        const formatting = getCustomFieldFormatting(rule);
        if (formatting) {
          formattingForInvalidRuleByRuleId[rule.__id] = formatting;
        }

        return;
      }

      if (rule.field === 'type') {
        const typeFilter = formattingGroups[0].filters.find(
          filter => filter.id === 'type'
        );

        const hasFormattingForRuleValue = typeFilter?.selectOptions?.some(
          option => option.value === rule.value
        );

        if (!typeFilter || !isString(rule.value) || hasFormattingForRuleValue) {
          return;
        }

        formattingForInvalidRuleByRuleId[rule.__id] = {
          ...typeFilter,
          selectOptions: [
            {
              label: rule.value,
              value: rule.value,
              isDisabled: true,
            },
            ...(typeFilter.selectOptions || []),
          ],
        };
      }
    }
  });

  return formattingForInvalidRuleByRuleId;
};
