import { ArdoqId, SearchType } from '@ardoq/api-types';
import {
  CodeEditor,
  EditorLanguage,
  ImmutableCodeEditor,
} from '@ardoq/code-editor';
import { getIn } from 'utils/collectionUtil';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateQueryParams, updateSearchQuery } from 'search/actions';
import { queryGremlinSearch } from 'search/Gremlin/actions';
import {
  QueryEditorNamespace,
  QueryEditorStateShape,
} from 'search/QueryEditor/queryEditor$';
import QueryActions from 'search/QueryEditor/QueryActions';
import { GremlinSearchStateShape } from 'search/Gremlin/types';
import {
  GremlinComponentResult,
  GremlinResult,
} from 'search/Gremlin/GremlinResults/types';
import GremlinSearchError from 'search/Gremlin/GremlinResults/GremlinSearchError';
import {
  GremlinSearchSource,
  trackQueriedGremlinSearch,
} from 'search/tracking';
import GremlinSearchWarning from 'search/Gremlin/GremlinResults/GremlinSearchWarning';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { Paragraph } from '@ardoq/typography';
import { Select, SelectOption } from '@ardoq/select';
import { FormWrapper } from '@ardoq/forms';
import { Box, Stack } from '@ardoq/layout';
import { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';

const isGremlinComponentResult = (
  result: unknown
): result is GremlinComponentResult => {
  if (!result) return false;
  const { type, id, properties } = result as GremlinComponentResult;
  if (type !== 'vertex' || !id || !properties?.name?.[0]?.value) return false;
  return true;
};

// Supports only results with vertices
const convertGremlinResultToOptions = (results: GremlinResult[] | null) => {
  const locale = getCurrentLocale();

  return (results ?? [])
    .filter(isGremlinComponentResult)
    .map(({ id, properties }) => ({
      label: properties.name[0].value,
      value: id,
    }))
    .sort((a, b) => localeCompare(a.label, b.label, locale));
};

type ViewProps = {
  parameterQueryEditor: QueryEditorStateShape;
  parameterGremlinResults: GremlinSearchStateShape;
  queryParams: Record<string, string> | Record<string, string[]>;
  isEditing: boolean;
};

const usePreviousOptions = (
  currentOptions: Array<SelectOption<string>>,
  hasError: boolean
): SelectOption<string>[] => {
  const options = useRef<SelectOption<string>[]>([]);

  useEffect(() => {
    if (!hasError) {
      options.current = currentOptions;
    }
  }, [hasError, currentOptions]);

  return options.current;
};

const ParameterQueryEditor = ({
  parameterQueryEditor,
  parameterGremlinResults,
  isEditing,
  queryParams,
}: ViewProps) => {
  const { isSearching, model } = parameterQueryEditor;
  const { results, syntaxError, searchError, searchWarning } =
    parameterGremlinResults;
  const apiOptions = convertGremlinResultToOptions(results);
  const currentOptions = usePreviousOptions(apiOptions, searchError);

  const selectedOption = currentOptions.find(
    ({ value }) => value === queryParams.selectedId
  );

  const doSearch = () => {
    trackQueriedGremlinSearch({
      searchSource: GremlinSearchSource.PARAMETER_SEARCH_TEST,
    });
    dispatchAction(
      queryGremlinSearch({
        query: model.query as string,
        queryParams: {
          size: 10000, // max amount of options for selection, large number might cause lag
          from: 0,
        },
      }),
      SearchType.PARAMETER_QUERY
    );
  };

  return (
    <>
      {isEditing && (
        <>
          <CodeEditor
            language={EditorLanguage.GROOVY}
            value={model.query as string}
            onChange={(query: string) =>
              dispatchAction(
                updateSearchQuery({
                  query,
                }),
                QueryEditorNamespace.PARAMETER_QUERY
              )
            }
            onCtrlCmdEnter={doSearch}
          />
          <Stack>
            <QueryActions
              searchButtonText="Run parameter query"
              doSearch={doSearch}
              showSpinner={isSearching}
            />
          </Stack>
        </>
      )}
      {searchError && <GremlinSearchError syntaxError={syntaxError} />}
      {searchWarning && <GremlinSearchWarning searchWarning={searchWarning} />}
      {!isEmpty(apiOptions) && !syntaxError && (
        <Box marginTop="medium">
          <Stack gap="medium">
            <Paragraph variant="text2Bold">Test parameter</Paragraph>
            <FormWrapper>
              <Select
                options={currentOptions}
                value={selectedOption}
                onValueChange={(selectedId: ArdoqId | null) => {
                  if (!selectedId) return;

                  dispatchAction(
                    updateQueryParams({
                      queryParams: { selectedId },
                    }),
                    QueryEditorNamespace.DYANMIC_FILTER_QUERY
                  );
                }}
              />
            </FormWrapper>
            {isEditing && (
              <ImmutableCodeEditor
                value={
                  queryParams.selectedId
                    ? `selectedId = "${
                        queryParams.selectedId
                      }" // This a read only parameter, and it is the id of your selected option ${getIn(
                        selectedOption,
                        ['label']
                      )}`
                    : `selectedId = null // Do a gremlin search above and select an id`
                }
              />
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default ParameterQueryEditor;
