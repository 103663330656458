export enum UserSettingsTabs {
  PROFILE = 'profile',
  EMAIL = 'email-settings',
  API = 'api',
  PASSWORD = 'password-settings',
}

export type UserSettingsRoute = {
  userSettingsSelectedTab: UserSettingsTabs;
};
