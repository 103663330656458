import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { SourceType } from '@ardoq/api-types/integrations';
import { INTEGRATION_ID_TO_SOURCE_TYPES } from 'integrations/unified/constants';
import {
  awsDictionary,
  azureDictionary,
  excelDictionary,
  microsoftEntraIdDictionary,
  serviceNowDictionary,
} from 'integrations/common/dictionary';

const sourceTypesByIntegrationId: Record<IntegrationId, SourceType[]> = {
  'aws-v3': ['aws'],
  'azure-v3': ['azure'],
  'excel-v3': ['sheets'],
  'servicenow-v3': ['servicenow'],
  'microsoft-entra-id': ['microsoftgraph'],
  'signavio-exporter': ['signavio-exporter'],
  'it-pedia': ['itpedia'],
  ...INTEGRATION_ID_TO_SOURCE_TYPES,
};

export const integrationNamesBySourceTypes: Record<SourceType, string> = {
  servicenow: serviceNowDictionary.name,
  'jira-software': 'Jira Software Cloud',
  microsoftgraph: microsoftEntraIdDictionary.name,
  azure: azureDictionary.name,
  aws: awsDictionary.name,
  itpedia: 'IT-Pedia',
  'signavio-exporter': 'SAP Signavio',
  signavio: 'SAP Signavio Importer',
  celonis: 'Celonis Process Intelligence Importer',
  symbio: 'Celonis Process Management',
  ardoq: 'Ardoq Importer',
  'ardoq-sample-data': 'Ardoq Sample Data Importer',
  'ardoq-reference-data': 'Ardoq Reference Data Importer',
  'sharepoint-files': 'Sharepoint/OneDrive Files Importer',
  sheets: excelDictionary.name,
  excel: excelDictionary.name,
};

export const IntegrationIdBySourceTypes = Object.entries(
  sourceTypesByIntegrationId
).reduce(
  (acc, [integrationId, sourceTypes]) => {
    sourceTypes.forEach(sourceType => {
      acc[sourceType] = (acc[sourceType] || []).concat(
        integrationId as IntegrationId
      );
    });
    return acc;
  },
  {} as Record<SourceType, IntegrationId[]>
);

export const integrationIdToSourceTypes = (integrationId: IntegrationId) => {
  return sourceTypesByIntegrationId[integrationId] || [];
};
