import { connect, derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import PresentationsNavBar from './PresentationsNavBar';
import { AssetType, APIPresentationAssetAttributes } from '@ardoq/api-types';
import { AppModules } from 'appContainer/types';
import AssetsBrowser2024 from 'components/AssetsBrowser2024/AssetsBrowser2024';
import {
  favoriteFilter,
  getAssetFilter,
  getCreatedByMeFilter,
} from 'components/AssetsBrowser2024/FilterBar/filters';
import { useWindowSize } from '@ardoq/hooks';
import EmptyState from 'components/AssetsBrowser2024/EmptyState';
import { DoqType } from '@ardoq/doq';
import presentations$ from 'streams/presentations/presentations$';
import {
  contributorFilter,
  onlyMeFilter,
  publicFilter,
  workspaceFilter,
} from './overviewHelpers';
import presentationsOverview$ from './presentationsOverview$';
import { getReadAccessColumn } from './columns';
import {
  getLastUpdatedColumn,
  getUpdatedByColumn,
} from 'components/AssetsBrowser2024/assetsBrowser2024Columns';
import currentUser$, {
  CurrentUserState,
} from 'streams/currentUser/currentUser$';
import { PageBody } from '@ardoq/page-layout';
import { Box, FlexBox } from '@ardoq/layout';
import PresentationSidebar from 'presentationOrganizer/components/PresentationSidebar';
import { PresentationOverviewStreamShape } from './types';
import { PresentationsStreamShape } from 'streams/presentations/types';

const PAGE_BODY_PADDING = 64;

type PresentationsOverviewProps = {
  presentations: APIPresentationAssetAttributes[] | null;
  isSidebarOpen: boolean;
  currentUser: CurrentUserState;
};

const PresentationsOverview = ({
  presentations,
  isSidebarOpen,
  currentUser,
}: PresentationsOverviewProps) => {
  const windowHeight = useWindowSize().height;
  return (
    <PresentationsNavBar>
      {presentations?.length ? (
        <FlexBox width="full" height="full">
          <PageBody backgroundColor="surfaceDefault" skipPadding preventScroll>
            <Box padding="xlarge" width="full">
              <AssetsBrowser2024
                appModule={AppModules.PRESENTATIONS}
                alwaysActiveFilters={[getAssetFilter(AssetType.PRESENTATION)]}
                filters={[
                  favoriteFilter,
                  getCreatedByMeFilter(currentUser._id),
                  publicFilter,
                  contributorFilter,
                  onlyMeFilter,
                  workspaceFilter,
                ]}
                scrollHeight={
                  windowHeight - PAGE_TOPBAR_HEIGHT - PAGE_BODY_PADDING
                }
                getCustomColumns={columnsProps => [
                  getUpdatedByColumn(columnsProps),
                  getLastUpdatedColumn(columnsProps),
                  getReadAccessColumn(columnsProps),
                ]}
              />
            </Box>
          </PageBody>
          {isSidebarOpen && <PresentationSidebar />}
        </FlexBox>
      ) : (
        <PageBody backgroundColor="surfaceDefault" skipPadding preventScroll>
          <EmptyState
            doqType={DoqType.EMPTY_BOX}
            title="You don't have any presentations yet"
            text="Create a new presentation to get started."
          />
        </PageBody>
      )}
    </PresentationsNavBar>
  );
};

const toPresentationsOverviewStream = ([
  { list },
  { isSidebarOpen },
  currentUser,
]: [
  PresentationsStreamShape,
  PresentationOverviewStreamShape,
  CurrentUserState,
]) => {
  return {
    presentations: list,
    isSidebarOpen,
    currentUser,
  };
};

const viewModel$ = derivedStream(
  'viewModel$',
  presentations$,
  presentationsOverview$,
  currentUser$
).pipe(map(toPresentationsOverviewStream));

export default connect(PresentationsOverview, viewModel$);
