import { Maybe } from '@ardoq/common-helpers';
import {
  EnhancedSearchResponse,
  ReportEventLocations,
  trackCopyReportURL,
} from '@ardoq/report-reader';
import {
  ExportReportButtonProps,
  MoreMenuButtonProps,
  ToolbarButtonProps,
} from '../NavBarButtons';
import {
  APIPresentationAssetAttributes,
  ArdoqId,
  EnhancedSearchStrategy,
} from '@ardoq/api-types';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { IconName } from '@ardoq/icons';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { PermissionContext } from '@ardoq/access-control';
import { currentUserAccessControlInterface } from 'resourcePermissions/accessControlHelpers/currentUser';
import { presentationAccessControlOperations } from 'resourcePermissions/accessControlHelpers/presentations';
import { NavBarCommands } from '../commands/navBarCommands';
import { trackEvent } from 'tracking/tracking';
import { reportAccessControlInterface } from 'resourcePermissions/accessControlHelpers/report';

const getExportButtonProps = (
  searchResults: Maybe<EnhancedSearchResponse>,
  commands: NavBarCommands
): ExportReportButtonProps => ({
  // TODO: Adapt isDisabled to API changes when aggregates will come from the aggregates endpoint, instead of drilldown
  options: [
    {
      isDisabled:
        searchResults?.strategy === EnhancedSearchStrategy.GREMLIN_PATH ||
        !searchResults?.totalNumberOfRows,
      label: 'Export to Excel (report data only)',
      description:
        searchResults?.strategy === EnhancedSearchStrategy.GREMLIN_PATH
          ? 'Not available for Gremlin Path reports'
          : !searchResults?.totalNumberOfRows
            ? 'No data is available for export'
            : undefined,
      onClick: commands.exportToReadableExcel,
      type: DropdownOptionType.OPTION,
    },
    {
      label: 'Export to Excel (import format)',
      isDisabled: !searchResults?.totalNumberOfRows,
      description: !searchResults?.totalNumberOfRows
        ? 'No data is available for export'
        : undefined,
      onClick: commands.exportToExcel,
      type: DropdownOptionType.OPTION,
    },
    {
      label: 'Export to JSON',
      onClick: commands.exportToJSON,
      type: DropdownOptionType.OPTION,
    },
  ],
});

const getMoreMenuButtonProps = (
  commands: NavBarCommands,
  presentation: APIPresentationAssetAttributes | undefined,
  permissionContext: PermissionContext
): MoreMenuButtonProps => ({
  options: [
    {
      label: 'Create Broadcast',
      onClick: commands.createBroadcast,
      type: DropdownOptionType.OPTION,
      iconName: IconName.BROADCAST,
      isDisabled: !currentUserInterface.currentUserIsOrgAdmin(),
    },
    {
      label: 'Add to presentation',
      onClick: commands.addToPresentation,
      isDisabled: !presentation
        ? !currentUserAccessControlInterface.canCurrentUserWrite()
        : !presentationAccessControlOperations.canEditPresentation(
            permissionContext,
            presentation
          ),
      type: DropdownOptionType.OPTION,
      iconName: IconName.ADD_PRESENTATION,
    },
  ],
});

const getToolbarButtonProps = (
  commands: NavBarCommands,
  reportId: ArdoqId,
  permissionContext: PermissionContext
): ToolbarButtonProps => ({
  copyUrlButtonProps: {
    reportId,
    trackCopyReportURLButton: copyURLType =>
      trackCopyReportURL(trackEvent, ReportEventLocations.ARDOQ, copyURLType),
  },
  editButtonProps: {
    isDisabled: !reportAccessControlInterface.canEditReport({
      reportId,
      permissionContext,
    }),
    onClick: commands.editReport,
  },
});

export const getNavBarButtonsProps = (
  commands: NavBarCommands,
  reportId: ArdoqId,
  searchResults: Maybe<EnhancedSearchResponse>,
  presentation: APIPresentationAssetAttributes | undefined,
  permissionContext: PermissionContext
) => ({
  exportButtonProps: getExportButtonProps(searchResults, commands),
  moreMenuButtonProps: getMoreMenuButtonProps(
    commands,
    presentation,
    permissionContext
  ),
  toolbarButtonProps: getToolbarButtonProps(
    commands,
    reportId,
    permissionContext
  ),
});
