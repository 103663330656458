import { DirectedTriple, TripleDirection } from '@ardoq/api-types';
import { TraversalBuilderState } from 'viewpointBuilder/types';

export const getShortestPath = (
  state: TraversalBuilderState,
  startNodeId: string,
  targetNodeId: string
): ShortestPath | null => {
  if (startNodeId === targetNodeId) {
    return null;
  }
  const currentPath: ShortestPath = {
    startNodeId,
    endNodeId: targetNodeId,
    path: [],
    pathByReferenceIds: [],
  };
  return step(state, targetNodeId, new Set(), [
    { currentHeadId: startNodeId, currentPath },
  ]);
};

export type ShortestPath = {
  startNodeId: string;
  endNodeId: string;
  path: DirectedTriple[];
  pathByReferenceIds: string[];
};

type GetPathResult = {
  currentHeadId: string;
  currentPath: ShortestPath;
};

const step = (
  state: TraversalBuilderState,
  targetId: string,
  visited: Set<string>,
  paths: GetPathResult[]
): ShortestPath | null => {
  if (paths.length === 0) {
    return null;
  }
  const newPaths: GetPathResult[] = [];
  const stepWithDirection = getStepWithDirection({
    state,
    newPaths: newPaths,
    visited,
    targetId,
  });
  for (const { currentHeadId, currentPath } of paths) {
    if (visited.has(currentHeadId)) {
      continue;
    }
    visited.add(currentHeadId);
    const shortestPath =
      stepWithDirection(currentHeadId, currentPath, 'outgoing') ||
      stepWithDirection(currentHeadId, currentPath, 'incoming');
    if (shortestPath) {
      return shortestPath;
    }
  }
  return step(state, targetId, visited, newPaths);
};

type StepWithDirectionArgs = {
  state: TraversalBuilderState;
  newPaths: GetPathResult[];
  visited: Set<string>;
  targetId: string;
};

const getStepWithDirection =
  ({ state, newPaths, visited, targetId }: StepWithDirectionArgs) =>
  (
    currentNodeId: string,
    currentPath: ShortestPath,
    direction: TripleDirection
  ) => {
    const isOutgoing = direction === 'outgoing';
    const linkedComponents =
      (isOutgoing
        ? state.selectedGraph.sourceMap
        : state.selectedGraph.targetMap
      ).get(currentNodeId) || [];
    for (const { componentId, referenceId } of linkedComponents) {
      if (visited.has(componentId)) {
        continue;
      }
      const sourceType = getComponentLabel(
        state,
        isOutgoing ? currentNodeId : componentId
      );
      const targetType = getComponentLabel(
        state,
        isOutgoing ? componentId : currentNodeId
      );
      const referenceType = getReferenceLabel(state, referenceId);
      const currentTriple: DirectedTriple = {
        sourceType,
        targetType,
        referenceType,
        direction,
      };
      const nextPath = {
        ...currentPath,
        path: [...currentPath.path, currentTriple],
        pathByReferenceIds: [...currentPath.pathByReferenceIds, referenceId],
      };
      if (componentId === targetId) {
        return nextPath;
      }
      newPaths.push({ currentHeadId: componentId, currentPath: nextPath });
    }
  };

const getComponentLabel = (state: TraversalBuilderState, nodeId: string) => {
  const node = state.graphNodes.get(nodeId);
  return node?.metaData?.label ?? 'Missing Label';
};

const getReferenceLabel = (
  state: TraversalBuilderState,
  referenceId: string
) => {
  const node = state.graphEdges.get(referenceId);
  return node?.metaData?.representationData?.displayLabel ?? 'Missing Label';
};
