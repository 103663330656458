import {
  TogglePresentationEditorPayload,
  togglePresentationEditor,
} from './actions';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';

type State = boolean;

const togglePresentationEditorReducer = (
  _: State,
  { shouldShow }: TogglePresentationEditorPayload
) => shouldShow;
const handleTogglePresentationEditor = reducer(
  togglePresentationEditor,
  togglePresentationEditorReducer
);

const togglePresentationEditor$ = persistentReducedStream(
  'togglePresentationEditor$',
  false,
  [handleTogglePresentationEditor]
);

export default togglePresentationEditor$;
