import fp from 'lodash/fp';
import { areArraysEqual } from 'integrations/common/utils/common';
import { RegionsState } from 'integrations/cloudProviders/streams/regions/types';
import { ResourceTypesState } from 'integrations/cloudProviders/streams/resourceTypes/types';
import { SelectionState } from './types';
import { ResourceGroupsState } from '../resourceGroups/types';
export const isValidSelectionState = ({
  selectionState,
  selectedConnectionIds,
  regionsState,
  resourceGroupsState,
  resourceTypesState,
}: {
  selectionState: SelectionState;
  selectedConnectionIds: string[];
  regionsState: RegionsState;
  resourceGroupsState: ResourceGroupsState;
  resourceTypesState: ResourceTypesState;
}) => {
  return Boolean(
    fp.every(fp.size, [
      selectedConnectionIds,
      selectionState.subscriptionIds,
      resourceTypesState.selectedResourceTypeIds,
      regionsState.selectedRegionIds,
      resourceGroupsState.selectedResourceGroupNames,
    ])
  );
};

export const areFetchedResourcesInSync = (
  { selectionRequest, subscriptionIds }: SelectionState,
  { selectedResourceTypeIds }: ResourceTypesState,
  { selectedResourceGroupNames }: ResourceGroupsState,
  { selectedRegionIds }: RegionsState
): boolean => {
  if (!selectionRequest) {
    return false;
  }

  const fetchedResourceTypes = selectionRequest.providerParams.resourceTypes;
  const fetchedRegions = selectionRequest.providerParams.regions;
  const fetchedSubscriptions = selectionRequest.providerParams.subscriptions;
  const fetchedResourceGroups = selectionRequest.providerParams.vpcs;

  return (
    areArraysEqual(selectedResourceTypeIds, fetchedResourceTypes) &&
    areArraysEqual(selectedRegionIds, fetchedRegions) &&
    areArraysEqual(subscriptionIds, fetchedSubscriptions) &&
    areArraysEqual(selectedResourceGroupNames, fetchedResourceGroups)
  );
};
