import { Aggregate, APIFieldType, WidgetTypes } from '@ardoq/api-types';
import { aggregates, aggregatesWithLabel } from '@ardoq/report-reader';

export const WIDGETS_TYPES_THAT_WORK_WITH_COUNT_AGGREGATE = new Set([
  WidgetTypes.LINE_CHART,
  WidgetTypes.NUMBER,
  WidgetTypes.TABLE,
]);

type AggregateValidationAttributes = {
  aggregate: Aggregate;
  fieldTypes: APIFieldType[];
  widgetType?: WidgetTypes;
};

const isValidAggregate = ({
  aggregate,
  fieldTypes,
  widgetType,
}: AggregateValidationAttributes) => {
  if (aggregate === Aggregate.COUNT) {
    return (
      widgetType && WIDGETS_TYPES_THAT_WORK_WITH_COUNT_AGGREGATE.has(widgetType)
    );
  }
  if (widgetType === WidgetTypes.LINE_CHART) {
    return fieldTypes.every(item => item === APIFieldType.NUMBER);
  }
  return (
    (widgetType === WidgetTypes.NUMBER || widgetType === WidgetTypes.TABLE) &&
    fieldTypes.includes(APIFieldType.NUMBER)
  );
};

export const getValidAggregates = (args: {
  fieldTypes: APIFieldType[];
  widgetType?: WidgetTypes;
}) => aggregates.filter(aggregate => isValidAggregate({ ...args, aggregate }));

export const getIsAggregateDisabledAndPopoverContent = ({
  aggregate,
  fieldTypes,
  widgetType,
}: AggregateValidationAttributes) =>
  isValidAggregate({ aggregate, fieldTypes, widgetType })
    ? { isDisabled: false }
    : {
        isDisabled: true,
        popoverContent: `${aggregatesWithLabel[aggregate]} ${
          !(
            widgetType &&
            WIDGETS_TYPES_THAT_WORK_WITH_COUNT_AGGREGATE.has(widgetType)
          )
            ? 'aggregate can only be used with number, table and line charts.'
            : 'aggregate can only be used with number fields.'
        }`,
      };
