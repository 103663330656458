import { ScheduleState } from 'integrations/common/streams/schedule/schedule$';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { IntegrationSchedule } from '@ardoq/api-types';
import { Maybe } from '@ardoq/common-helpers';
export const getHeaderTitle = ({
  selectedTransferConfigId,
  transferConfigs,
  prefix,
  loadedScheduleId,
  schedules,
}: {
  selectedTransferConfigId: Maybe<string>;
  transferConfigs: SavedTransferConfig[];
  prefix: string;
  loadedScheduleId: ScheduleState['loadedScheduleId'];
  schedules: IntegrationSchedule[];
}) => {
  const loadedScheduleName = schedules.find(
    ({ _id }) => _id === loadedScheduleId
  )?.jobOptions.name;

  if (loadedScheduleName) {
    return `${prefix} - ${loadedScheduleName}`;
  }

  const transferConfigName = transferConfigs.find(
    ({ _id }) => _id === selectedTransferConfigId
  )?.name;

  if (transferConfigName) {
    return `${prefix} - ${transferConfigName}`;
  }

  return `${prefix} - untitled`;
};
