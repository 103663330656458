import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { PermissionState } from './types';
import {
  getZonePermissions,
  getZonePermissionsFinished,
  updateZonePermissionFinished,
} from './actions';
import { APIResourcePermissionAttributes } from '@ardoq/api-types';
import { zonePermissionsOperations } from './zonePermissionsOperations';

const defaultState: PermissionState = {
  permissionsByZoneId: {},
  isLoading: false,
};

const reducers = [
  reducer<PermissionState>(
    getZonePermissions,
    zonePermissionsOperations.setLoadingState
  ),
  reducer<PermissionState, APIResourcePermissionAttributes[] | null>(
    getZonePermissionsFinished,
    zonePermissionsOperations.updateAllPermissionsStateAfterLoading
  ),
  reducer<PermissionState, APIResourcePermissionAttributes | null>(
    updateZonePermissionFinished,
    zonePermissionsOperations.updatePermissionState
  ),
];

const permissions$ = persistentReducedStream(
  'permissions$',
  defaultState,
  reducers
);

export default permissions$;
