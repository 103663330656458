import { APIViewpointAttributes, ArdoqId, LoadedState } from '@ardoq/api-types';
import { ContextShape, EnhancedScopeData } from '@ardoq/data-model';
import { GhostButton } from '@ardoq/button';
import { Drawer } from '@ardoq/snowflakes';
import { ComponentOverview, TopBar } from '@ardoq/component-overview';
import { connect } from '@ardoq/rxbeach';
import { componentOverviewDrawerViewModel$ } from './componentOverviewDrawer$';
import { ComponentOverviewDrawerCommands } from './componentOverviewDrawerCommands';

export type ComponentOverviewDrawerProps = {
  componentId: ArdoqId;
  scopeData: EnhancedScopeData;
  loadedStates: LoadedState[];
  context: ContextShape;
  handleCloseDrawer: VoidFunction;
  viewpoints: APIViewpointAttributes[];
  isLoadingReferences: boolean;
  componentOverviewDrawerCommands: ComponentOverviewDrawerCommands;
};

const ComponentOverviewDrawer = ({
  handleCloseDrawer,
  componentId,
  isLoadingReferences,
  loadedStates,
  context,
  viewpoints,
  scopeData,
  componentOverviewDrawerCommands,
}: ComponentOverviewDrawerProps) => {
  const component = scopeData.componentsById[componentId];
  return (
    <Drawer
      title={<TopBar component={component} scopeData={scopeData} />}
      drawerSize="large"
      controls={
        <GhostButton
          onClick={() => {
            componentOverviewDrawerCommands.closeDrawer(handleCloseDrawer);
          }}
        >
          Cancel
        </GhostButton>
      }
    >
      <ComponentOverview
        showTopBar={false}
        scopeData={scopeData}
        componentId={componentId}
        viewpoints={viewpoints}
        onComponentClick={componentId => {
          componentOverviewDrawerCommands.setSelectedComponent(
            componentId,
            scopeData
          );
        }}
        onViewpointClick={viewpointId => {
          componentOverviewDrawerCommands.openViewpoint(
            componentId,
            scopeData,
            viewpointId,
            viewpoints,
            context,
            loadedStates,
            handleCloseDrawer
          );
        }}
        isLoadingReferences={isLoadingReferences}
      />
    </Drawer>
  );
};

export const ConnectedComponentOverviewDrawer = connect(
  ComponentOverviewDrawer,
  componentOverviewDrawerViewModel$
);
