import { FormattingRule } from '@ardoq/perspectives-sidebar';
import {
  composeComponentFormattingGroups,
  composeReferencesFormattingGroups,
  getAddFormattingRuleButtonOptions,
} from '@ardoq/perspectives';
import {
  createFormattingRule,
  deleteFormattingRule,
  perspectivesEditor$,
  reorderFormattingRules,
  updateFormattingRule,
} from './perspectivesEditor$';
import { dispatchAction } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import getFilterTabProps from './getFilterTabProps';
import { getViewpointComponentSuggestionLoaders } from 'viewpointBuilder/formattingTabs/getFormattingSuggestionLoader';
import { getFormattingForInvalidRuleByRuleId } from './getFormattingForInvalidRuleByRuleId';

const onFormattingRuleDeleted = (deletedRuleId: string) =>
  dispatchAction(deleteFormattingRule(deletedRuleId));

const onFormattingRuleReordered = (reorderedGroupingRules: string[]) =>
  dispatchAction(reorderFormattingRules(reorderedGroupingRules));

const onFormattingRuleUpdated = (
  updatedFormattingRuleId: string,
  updatedFormattingRule: Partial<FormattingRule>
) =>
  dispatchAction(
    updateFormattingRule({ updatedFormattingRuleId, updatedFormattingRule })
  );

const onFormattingRuleAdded = (newFormattingRule: FormattingRule) =>
  dispatchAction(createFormattingRule(newFormattingRule));

const getAddButtonDisabledPopoverContent = (entitiesName: string) =>
  `There are no ${entitiesName} in the opened datasets. Try editing your datasets or open more data.`;

const formattingTab$ = perspectivesEditor$.pipe(
  map(state => {
    const { perspectivesRelatedData, formattingTab, availableRelatedData } =
      state;
    const { conditionalFormattingRules } = formattingTab;

    const { referenceTypeNames, componentTypeNames } = perspectivesRelatedData;

    const {
      availableWorkspaceComponentFields,
      availableWorkspaceReferenceFields,
      availableWorkspaceTags,
    } = availableRelatedData;

    const { tagOptions, asyncLabelLoaders, asyncSuggestionsLoaders } =
      getFilterTabProps({
        ...perspectivesRelatedData,
        relatedTags: availableWorkspaceTags,
      });

    const componentsFormattingGroups = composeComponentFormattingGroups({
      relatedFields: availableWorkspaceComponentFields,
      componentTypeNames,
    });

    const referencesFormattingGroups = composeReferencesFormattingGroups({
      relatedFields: availableWorkspaceReferenceFields,
      referenceTypeNames,
    });

    const formattingForInvalidRuleByRuleId =
      getFormattingForInvalidRuleByRuleId({
        conditionalFormattingRules,
        componentsFormattingGroups,
        referencesFormattingGroups,
      });

    const getFormattingForInvalidRule = (ruleId: string) =>
      formattingForInvalidRuleByRuleId[ruleId];

    const addRuleButtonOptions = getAddFormattingRuleButtonOptions({
      onFormattingRuleAdded,
      referencesFormattingGroups,
      tagsFormattingGroups: tagOptions,
      getDisabledOptionPopoverContent: getAddButtonDisabledPopoverContent,
    });

    const description =
      'Set rules to automatically highlight components and references with colors.';
    const tabDescription = {
      description,
    };

    return {
      conditionalFormatting: {
        formattingRules: conditionalFormattingRules,
        onFormattingRuleDeleted,
        onFormattingRuleReordered,
        onFormattingRuleAdded,
        onFormattingRuleUpdated,
        asyncSuggestionsLoaders: {
          ...asyncSuggestionsLoaders,
          ...getViewpointComponentSuggestionLoaders(
            perspectivesRelatedData.relatedWorkspaces.map(
              workspace => workspace._id
            ),
            perspectivesRelatedData.componentTypeNames
          ),
        },
        asyncLabelLoaders,
        componentsFormattingGroups,
        referencesFormattingGroups,
        tagsFormattingGroups: tagOptions,
        getFormattingForInvalidRule,
        addRuleButtonOptions,
      },
      tabDescription,
    };
  })
);

export default formattingTab$;
