import { getIconNameForAssetType, getPermissionLevelIcon } from '@ardoq/icons';
import { assetTypesLabels } from './consts';
import { AssetType, PermissionAccessLevel } from '@ardoq/api-types';
import { PERMISSION_LEVELS_LABELS } from '@ardoq/manage-resource-permissions';

const assetTypeToOption = (assetType: AssetType) => ({
  iconName: getIconNameForAssetType(assetType),
  label: assetTypesLabels[assetType],
  value: assetType,
});

const permissionLevelToOption = (permissionLevel: PermissionAccessLevel) => ({
  label: PERMISSION_LEVELS_LABELS[permissionLevel] || 'unknown',
  value: permissionLevel,
  iconName: getPermissionLevelIcon(permissionLevel),
});

export const optionsHelpers = {
  assetTypeToOption,
  permissionLevelToOption,
};
