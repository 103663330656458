import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { AppRouterState, IntegrationRoute } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { map } from 'rxjs/operators';
import { setVisibleIntegration } from './actions';
import { requestShowAppModule } from 'appContainer/actions';
import integrationViewState$ from './integrationViewState$';
import {
  getIntegrationById,
  getIntegrationByImporterPath,
} from './allIntegrations';
import { updateIntegrationConfig } from 'integrations/common/streams/integrationConfigs/actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { RootPages } from './types';

const pathToIndividualIntegration = (path: string) => {
  /*
    Path format:    /integrations/importerPath/integrationPath

    Sclices after split:
    +-------------------------------------------------------+
    | 0  |      1        |     2        |         3         |
    | "" |"integrations" |"importerPath"| "integrationPath" |
    +-------------------------------------------------------+
   */
  const parts = path.split('/');
  const integrationPath = parts.slice(3).join('/');
  const importerPath = parts.slice(2, 3).join('/');
  const integration = getIntegrationByImporterPath(importerPath);
  if (!integration) {
    return null;
  }
  return {
    integrationId: integration.id,
    integrationPath: integrationPath || null,
  };
};

const integrationRoute = new Route<AppRouterState, IntegrationRoute>({
  doesLocationMatch: ({ path }) => /\/integrations\/?([0-9a-z]+)?/.test(path),

  locationToRouterState: ({
    path,
  }): IntegrationRoute & { appModule: AppModules.INTEGRATIONS } => {
    switch (path) {
      case RootPages.DASHBOARD:
        return {
          appModule: AppModules.INTEGRATIONS,
          integrationId: null,
          integrationPath: RootPages.DASHBOARD,
        };
      case RootPages.HISTORY:
        return {
          appModule: AppModules.INTEGRATIONS,
          integrationId: null,
          integrationPath: RootPages.HISTORY,
        };
      default:
        return {
          appModule: AppModules.INTEGRATIONS,
          ...(pathToIndividualIntegration(path) || {
            integrationId: null,
            integrationPath: RootPages.DASHBOARD,
          }),
        };
    }
  },

  doesRouterStateMatch: ({ appModule }) =>
    appModule === AppModules.INTEGRATIONS,

  routerStateToLocation: ({ integrationId, integrationPath }) => {
    let path = '/integrations';
    let title = 'Integrations';

    if (!integrationId) {
      return { path: integrationPath || path, title: title };
    }

    const integration = getIntegrationById(integrationId);

    if (integration?.importerPath) {
      path += `/${integration.importerPath}`;
    }
    if (integrationPath) {
      path += `/${integrationPath}`;
    }

    if (integration?.name) {
      title += ` - ${integration.name}`;
    }

    return { path: path, title: title };
  },

  setApplicationStateFromRoute: ({ integrationId, integrationPath }) => {
    if (integrationId) {
      const integration = getIntegrationById(integrationId);

      if (integration && integration.typescriptImporter?.config) {
        dispatchAction(
          updateIntegrationConfig({
            integrationId: integration.id as IntegrationId,
            config: integration.typescriptImporter.config,
          })
        );
      }

      dispatchAction(
        setVisibleIntegration({
          id: integration ? integration.id : integrationId,
          path: integrationPath,
        })
      );
    } else {
      dispatchAction(
        setVisibleIntegration({ id: null, path: integrationPath })
      );
      dispatchAction(
        requestShowAppModule({ selectedModule: AppModules.INTEGRATIONS })
      );
    }
  },

  getPartialRouterStateStream: () =>
    integrationViewState$.pipe(
      map(({ selectedIntegrationId, integrationPath }) => ({
        integrationId: selectedIntegrationId,
        integrationPath,
      }))
    ),
});

export default integrationRoute;
