import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { filter, tap, withLatestFrom } from 'rxjs';
import fp from 'lodash/fp';
import {
  loadSchedule,
  setLoadedScheduleId,
  upsertSchedule,
} from 'integrations/common/streams/schedule/actions';
import {
  createSchedule,
  updateSchedule,
} from 'integrations/common/streams/schedules/actions';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getCurrentTransferConfig } from 'integrations/common/streams/transferConfigs/utils';
import { tabularMappingIntoTransferConfig } from 'integrations/common/streams/tabularMappings/utils';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { getScheduleStream } from 'integrations/common/streams/schedule/schedule$';
import { isMicrosoftEntraIdSchedule } from 'integrations/common/streams/schedules/utils';
import { navigateToSelectData } from 'integrations/common/navigation/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { microsoftEntraIdTables$ } from '../tables/tables$';
import { selectionState$ } from '../selectionState/selectionState$';
import { applyConfig } from '../selectionState/actions';
import { ScheduleType } from '@ardoq/api-types';
import { getOrderedUserTableFields } from '../../utils';

const handleLoadSchedule = routine(
  ofType(loadSchedule),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'microsoft-entra-id'),
  withLatestFrom(getSchedulesStream('microsoft-entra-id')),
  tap(([{ scheduleId, integrationId }, schedules]) => {
    const schedule = schedules.schedules.find(s => scheduleId === s._id);

    if (!schedule || !isMicrosoftEntraIdSchedule(schedule)) {
      return;
    }

    dispatchAction(
      setLoadedScheduleId({
        scheduleId: schedule._id,
        integrationId,
      })
    );
    dispatchAction(navigateToSelectData({ integrationId }));
    dispatchAction(
      applyConfig({
        sourceConfig: schedule.jobOptions.microsoftgraphConfig,
        importPictures: Boolean(
          schedule.jobOptions.tabularMapping.options.importPictures
        ),
      })
    );
  })
);

const handleUpsertSchedule = routine(
  ofType(upsertSchedule),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'microsoft-entra-id'),
  withLatestFrom(
    getTabularMappingStream('microsoft-entra-id'),
    getTransferConfigsStream('microsoft-entra-id'),
    getActiveIntegrationStream('microsoft-entra-id'),
    getSchedulesStream('microsoft-entra-id'),
    getScheduleStream('microsoft-entra-id'),
    microsoftEntraIdTables$,
    selectionState$
  ),
  tap(
    ([
      { formData },
      tabularMapping,
      transferConfigs,
      activeIntegration,
      schedules,
      scheduleState,
      { tables },
      selectionState,
    ]) => {
      const currentTransferConfig = getCurrentTransferConfig(
        transferConfigs.configs,
        activeIntegration
      );

      const configName =
        currentTransferConfig.name || activeIntegration.integrationName;

      const config = tabularMappingIntoTransferConfig(
        tabularMapping,
        currentTransferConfig
      );

      dispatchAction(
        trackIntegrationEvent({
          integrationId: 'microsoft-entra-id',
          name: 'SELECTED_SCHEDULE_INTERVAL',
          metadata: {
            source: 'import-step',
            interval: formData.policy.type,
            day:
              formData.policy.type === 'weekly'
                ? formData.policy.weekDay
                : null,
            managesWorkspace: formData.managesWorkspace ? 'on' : 'off',
          },
        })
      );

      const selectedTables = tables
        .filter(table => Object.keys(selectionState.tables).includes(table.id))
        .map(table => ({
          ...table,
          fields: getOrderedUserTableFields(
            Object.keys(selectionState.tables[table.id].fields)
          ),
        }));

      const microsoftgraphConfig = {
        name: formData.name,
        tables: selectedTables,
      };

      const scheduleConfig = {
        ...config,
        sourceConfig: microsoftgraphConfig,
        options: {
          ...config.options,
          importPictures: selectionState.importProfilesPhotos,
        },
        name: configName,
      };

      if (scheduleState.loadedScheduleId) {
        const loadedSchedule = schedules.schedules.find(
          s => s._id === scheduleState.loadedScheduleId
        );

        if (!loadedSchedule) {
          return;
        }

        return dispatchAction(
          updateSchedule({
            integrationId: 'microsoft-entra-id',
            schedule: fp.flow(
              fp.set('schedulingPolicy', formData.policy),
              fp.update('jobOptions', options => ({
                ...options,
                name: formData.name,
                managesWorkspace: formData.managesWorkspace,
                microsoftgraphConfig,
                tabularMapping: scheduleConfig,
              }))
            )(loadedSchedule),
          })
        );
      }

      dispatchAction(
        createSchedule({
          integrationId: 'microsoft-entra-id',
          schedule: {
            schedulingPolicy: formData.policy,
            jobOptions: {
              type: ScheduleType.MICROSOFT_ENTRA_ID_IMPORT,
              name: formData.name,
              managesWorkspace: formData.managesWorkspace,
              tabularMapping: scheduleConfig,
              sourceId: null,
              microsoftgraphConfig,
            },
          },
        })
      );
    }
  )
);

export default [handleLoadSchedule, handleUpsertSchedule];
