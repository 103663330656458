import { SpinnerIcon } from '@ardoq/icons';
import { CONTENT_BAR_HEIGHT } from 'appLayout/consts';
import { CSSProperties, useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from '@ardoq/rxbeach';
import { combineLatest, map } from 'rxjs';
import styled from 'styled-components';
import gridEditor$ from './gridEditor$';
import DockedGridEditorIFrame, {
  type DockedGridEditorIFrameProps,
} from './DockedGridEditorIFrame';
import { useEditorPaneContentSizeObserver } from './DockedGridEditorLayoutContainerHelpers';
import { getGridEditorIframeSrc } from './gridEditorUtils';
import { gridEditorStateOperations } from './gridEditorStateOperations';
import { GridEditorState } from './types';
import selectedModule$ from 'appContainer/selectedModule$';
import { AppModules } from 'appContainer/types';
import { Centered } from '@ardoq/snowflakes';
import { Text } from '@ardoq/typography';

const GridEditorPane = styled.div`
  width: 100%;
  position: absolute;
  max-height: calc(100% - ${CONTENT_BAR_HEIGHT}px);
`;

const CenteredWithOverflowHidden = styled(Centered)`
  overflow: hidden;
`;

const GRID_EDITOR_PORTAL_TARGET_ID = 'grid-editor-portal-target';

/**
 * GridEditorPortalTarget
 *
 * Like a modal the GridEditor needs to be rendered in a container that is close to the <body>
 * of the document in order to escape various positioning and layout constraints applied by
 * its parents. This container sets up a rendering target for the GridEditor.
 *
 * @returns React.ReactNode
 */
export function GridEditorPortalTarget() {
  return <div id={GRID_EDITOR_PORTAL_TARGET_ID} />;
}

type GridEditorLayoutContainerViewModel =
  | {
      isGridEditorOpen: false;
      gridEditorProps: undefined;
      portalTarget: HTMLElement;
    }
  | {
      isGridEditorOpen: true;
      gridEditorProps: DockedGridEditorIFrameProps;
      portalTarget: HTMLElement;
    };

type GridEditorLayoutContainerProps = GridEditorLayoutContainerViewModel & {
  style?: CSSProperties;
};

/**
 * GridEditorLayoutContainer
 *
 * Responsible for:
 * - Measuring and syncronizing the "content rectangle" that the GridEditor
 *   should position its content inside. Think of this as a "hole" in
 *   ardoq-front's MainAppLayout where the GridEditor is visible through.
 * - Rendering the GridEditor, and because this component is restricted from
 *   rendering outside the rect set up by MainAppLayout we render the
 *   GridEditor through a portal to escape UI constraints (eg. like a modal)
 * - Setting up the communication bridge between ardoq-front and the
 *   GridEditor running inside the <iframe>.
 *
 * NOTE: This is the <iframe> that loads the Grid Editor in ardoq-front, so by
 * definition this component will only be used when
 * gridEditor$.activeFrame === DOCKED.
 *
 * The GridEditor has two visibilities:
 * - background: Only partially visible through a planned "gap" in the UI
 * - foreground: Inverse of 'background'; The rest of the UI is visible through
 *               the iframe's transparent background, allowing the content take
 *               any space it needs.
 *
 * |--------------------------------------------------------------------------|
 * |                                                                          |
 * |                  iFrame (fullscreen)                                     |
 * |                                                                          |
 * |                                                                          |
 * |                            ----------------------------------------------|
 * |                            |                                            ||
 * |                            |      ContentRect synced between:           ||
 * |                            |      - #editorPane                         ||
 * |                            |      - Grid Editor content                 ||
 * |                            |                                            ||
 * -----------------------------|--------------------------------------------||
 *
 * When the mouse is over the content box the whole iframe is foregrounded using zIndex allowing
 * the Grid Editor content to take as much space as it needs to show popovers and modals.
 */
const GridEditorLayoutContainer = (props: GridEditorLayoutContainerProps) => {
  const { style, gridEditorProps, isGridEditorOpen, portalTarget } = props;

  // Sync the content size with the iframe
  const [editorPane, setEditorPane] = useState<HTMLDivElement | null>(null);
  useEditorPaneContentSizeObserver(editorPane);

  return (
    <GridEditorPane
      id="editorPane"
      data-testid="docked-grid-editor-layout-container"
      style={style}
      ref={editorPaneEl => setEditorPane(editorPaneEl)}
    >
      <CenteredWithOverflowHidden
        height="100%"
        className="grid-editor-loading-container"
      >
        <Text color="textSubtle">
          <SpinnerIcon />
        </Text>
      </CenteredWithOverflowHidden>

      {isGridEditorOpen &&
        createPortal(
          <DockedGridEditorIFrame {...gridEditorProps} />,
          portalTarget
        )}
    </GridEditorPane>
  );
};

type ViewModelData = {
  gridEditor: GridEditorState;
  selectedModuleState: { selectedModule: AppModules };
};

export const _toDockedGridEditorLayoutContainerViewModel = ({
  gridEditor,
  selectedModuleState,
}: ViewModelData): GridEditorLayoutContainerViewModel => {
  const portalTarget = document.getElementById(GRID_EDITOR_PORTAL_TARGET_ID);
  if (!portalTarget) {
    throw new Error(
      'GridEditorLayoutContainer was rendered without its portal target,' +
        ' make sure `GridEditorPortalTarget` is always rendered'
    );
  }

  // By definition the Docked GridEditor is open as long as the Popout GridEditor
  // is closed. This is because:
  // - Docked GridEditor should open as fast as possible
  // - We prefer loading all bundles to run Ardoq up-front
  // - Popout GridEditor cannot be open at the same time as Docked GridEditor
  //   because it would cause 2x work when syncing the GridEditor state; in
  //   particular scopeData which can be quite large
  const isGridEditorOpen =
    !gridEditorStateOperations.isPopoutGridEditorState(gridEditor);

  if (!isGridEditorOpen) {
    return { isGridEditorOpen, portalTarget, gridEditorProps: undefined };
  }

  return {
    isGridEditorOpen,
    portalTarget,
    gridEditorProps: {
      id: 'grid-editor-2023-iframe',
      'data-testid': 'grid-editor-2023-iframe',
      title: 'Grid Editor',
      src: getGridEditorIframeSrc(),
      loading:
        // The GridEditor is only used in the WORKSPACES module, by setting
        // the loading attribute to 'lazy' we can defer loading the iframe in other modules
        selectedModuleState.selectedModule !== AppModules.WORKSPACES
          ? 'lazy'
          : undefined,
      $dockedGridEditorFocus: gridEditor.dockedGridEditorFocus,
      $dockedGridEditorVisibility: gridEditor.dockedGridEditorVisibility,
    },
  };
};

const viewModel$ = combineLatest({
  gridEditor: gridEditor$,
  selectedModuleState: selectedModule$,
}).pipe(map(_toDockedGridEditorLayoutContainerViewModel));

export default connect(GridEditorLayoutContainer, viewModel$);
