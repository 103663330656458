import { SetClassNames } from '../types';
import { NodeModel } from './types';

export default class LayoutBox {
  node: NodeModel;
  top: number;
  left: number;
  height: number;
  bottom: number;
  classNames: ReturnType<ReturnType<SetClassNames>>;
  isViewportCoordinates: boolean;
  options?: {
    isViewportCoordinates: boolean;
  };

  constructor(
    node: NodeModel,
    top: number,
    left: number,
    setClassNames?: ReturnType<SetClassNames> | null,
    { isViewportCoordinates = false } = {}
  ) {
    this.node = node;
    this.top = top;
    this.left = left;
    this.height = node.getItemHeight();
    this.bottom = this.top + this.height;
    this.classNames =
      typeof setClassNames === 'function' ? setClassNames(node) : [];
    this.isViewportCoordinates = isViewportCoordinates;
  }

  updateClassNames(setClassNames?: ReturnType<SetClassNames> | null) {
    if (setClassNames) {
      this.classNames = setClassNames(this.node);
    }
  }

  toViewportCoordinates(
    container: Element,
    box = container.getBoundingClientRect()
  ) {
    const layoutBox = new LayoutBox(this.node, this.top, this.left, null);
    if (!this.isViewportCoordinates) {
      layoutBox.top += box.top - container.scrollTop;
      layoutBox.left += box.left - container.scrollLeft;
    }
    return layoutBox;
  }
}
