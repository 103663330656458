import {
  ArdoqId,
  RequestResourceAccessResolutionOperationType,
  APIReviewRequestResourceAccessData,
} from '@ardoq/api-types';
import { ExtractPayload, reducedStream, reducer } from '@ardoq/rxbeach';
import {
  setPermissionType,
  setRequestAccessData,
  setSelectedGroup,
} from '../actions';

export interface ReviewAccessRequestViewModelShape {
  requestData: APIReviewRequestResourceAccessData | null;
  selectedGroup?: ArdoqId;
  permissionType: RequestResourceAccessResolutionOperationType | null;
  requestId?: ArdoqId;
}

const setRequestAccessDataHandler = (
  _: ReviewAccessRequestViewModelShape,
  payload: ExtractPayload<typeof setRequestAccessData>
): ReviewAccessRequestViewModelShape => {
  return {
    permissionType: null,
    requestData: payload.response,
    selectedGroup: undefined,
    requestId: payload.id,
  };
};

const setPermissionTypeHandler = (
  state: ReviewAccessRequestViewModelShape,
  { permissionType }: ExtractPayload<typeof setPermissionType>
): ReviewAccessRequestViewModelShape => {
  return {
    ...state,
    permissionType,
  };
};

const setSelectedGroupHandler = (
  state: ReviewAccessRequestViewModelShape,
  { id }: ExtractPayload<typeof setSelectedGroup>
): ReviewAccessRequestViewModelShape => {
  return {
    ...state,
    selectedGroup: id,
  };
};

export const reviewAccessRequestDialogViewModel$ =
  reducedStream<ReviewAccessRequestViewModelShape>(
    'ReviewAccessRequestDialogViewModel$',
    {
      permissionType:
        RequestResourceAccessResolutionOperationType.SET_USER_PERMISSIONS,
      selectedGroup: undefined,
      requestData: null,
      requestId: undefined,
    },
    [
      reducer(setRequestAccessData, setRequestAccessDataHandler),
      reducer(setPermissionType, setPermissionTypeHandler),
      reducer(setSelectedGroup, setSelectedGroupHandler),
    ]
  );
