import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';

const StyledBadge = styled.span`
  background-color: ${colors.red60};
  border-radius: 50%;
  color: ${colors.white};
  font-size: 8px;
  height: 16px;
  line-height: 8px;
  min-width: 16px;
  padding: 4px;
  position: absolute;
  right: 2px;
  top: 2px;
`;

type BadgeProps = {
  count: number;
};

const Badge = ({ count }: BadgeProps) => {
  if (!count) {
    return null;
  }

  return <StyledBadge>{count}</StyledBadge>;
};

export default Badge;
