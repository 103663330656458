import {
  APIResourcePermissionAttributes,
  ArdoqId,
  Zone,
  isZone,
} from '@ardoq/api-types';
import { ButtonGroup, PrimaryButton, SecondaryButton } from '@ardoq/button';
import { FormFooter, FormSize, FormWrapper, TextInput } from '@ardoq/forms';
import { ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { useState } from 'react';
import { ZoneCommands } from '../types';
import { Paragraph } from '@ardoq/typography';
import { fieldGroupNameOperations } from '@ardoq/subdivisions';

export const NewZoneModal = ({
  subdivisionId,
  commands,
  closeModal,
  onConfirm,
}: {
  subdivisionId: ArdoqId;
  commands: ZoneCommands;
  closeModal: () => void;
  onConfirm?: (zone: {
    newZone: Zone;
    permissions: APIResourcePermissionAttributes[];
  }) => void;
}) => {
  const [formData, setFormData] = useState({
    name: '',
  });

  const handleCreateButtonClick = async () => {
    const response = await commands.createNewZone({
      name: formData.name,
      subId: subdivisionId,
    } as Zone);
    if (isZone(response.newZone)) {
      onConfirm?.({
        newZone: response.newZone,
        permissions: response.permissions,
      });
      closeModal();
    }
  };

  return (
    <ModalTemplate headerText="Create field group" modalSize={ModalSize.S}>
      <ModalLayout>
        <Paragraph>
          Before selecting the fields, please give this group a name. This will
          allow you to reuse the selected fields for other permission groups and
          easily identify them later.
        </Paragraph>
        <FormWrapper $formSize={FormSize.AUTO}>
          <TextInput
            value={formData.name}
            label="Field group name"
            onValueChange={value => setFormData({ ...formData, name: value })}
            hasError={
              !!formData?.name &&
              !fieldGroupNameOperations.isValid(formData?.name)
            }
            errorMessage={
              !!formData?.name &&
              !fieldGroupNameOperations.isValid(formData?.name)
                ? `Name must be less than ${fieldGroupNameOperations.ALLOWED_MAX_LENGTH} characters.`
                : undefined
            }
          />
          <FormFooter>
            <ButtonGroup $alignToRight>
              <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
              <PrimaryButton
                isDisabled={!fieldGroupNameOperations.isValid(formData.name)}
                type="submit"
                onClick={handleCreateButtonClick}
              >
                Create field group
              </PrimaryButton>
            </ButtonGroup>
          </FormFooter>
        </FormWrapper>
      </ModalLayout>
    </ModalTemplate>
  );
};
