import { CSSProperties } from 'react';
import styled from 'styled-components';

const ModuleContainer = styled.div<{
  $overflow?: CSSProperties['overflow'];
  $hide?: boolean;
}>`
  width: 100%;
  height: 100%;
  overflow: ${({ $overflow }) => $overflow || 'auto'};
  display: ${({ $hide }) => ($hide ? 'none' : 'block')};
`;

export default ModuleContainer;
