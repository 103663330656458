import {
  ComponentTypeRepresentationMetaInfo,
  CountTagMetaInfo,
  ViewpointBuilderNavigationState,
  ViewpointBuilderTab,
  TagsMetaInfo,
  TagMetaInfoType,
} from './types';
import { IconName } from '@ardoq/icons';

const isCountGreaterThanZero = ({ count }: CountTagMetaInfo) => count > 0;

export const getTagsForNavMenuItem = (
  tabId: ViewpointBuilderTab,
  state: ViewpointBuilderNavigationState
): TagsMetaInfo => {
  switch (tabId) {
    case 'SELECT_CONTEXT_COMPONENT_INSTANCES_TAB':
      if (
        state.context === 'editViewpoint' ||
        state.context === 'createViewpoint'
      ) {
        return getComponentTypeRepresentationForStartContext(state);
      }

      return [
        {
          count: state.selectedComponentCount,
          type: TagMetaInfoType.TAG,
        } as const,
      ].filter(isCountGreaterThanZero);

    case 'SELECT_CONTEXT_COMPONENT_TYPE_TAB':
      return getComponentTypeRepresentationForStartContext(state);

    case 'SUBGRAPH_TAB':
      return [
        {
          count: state.nodesCount,
          type: TagMetaInfoType.TAG,
          iconName: IconName.COMPONENT,
        } as const,
        {
          count: state.edgesCount,
          type: TagMetaInfoType.TAG,
          iconName: IconName.REFERENCE,
        } as const,
      ].filter(isCountGreaterThanZero);

    case 'FILTERS_TAB':
      return [
        {
          count: state.filtersCount,
          type: TagMetaInfoType.TAG,
        } as const,
      ].filter(isCountGreaterThanZero);

    case 'META_INFO_TAB':
      return [];

    case 'GROUPS_TAB':
      return [
        {
          count: state.groupingRulesCount,
          type: TagMetaInfoType.TAG,
        } as const,
      ].filter(isCountGreaterThanZero);

    case 'FORMATTING_TAB':
      return [
        {
          count: state.conditionalFormattingRulesCount,
          type: TagMetaInfoType.TAG,
        } as const,
      ].filter(isCountGreaterThanZero);
    case 'LABELS_TAB': {
      return [
        {
          count: state.labelFormattingRulesCount,
          type: TagMetaInfoType.TAG,
        } as const,
      ].filter(isCountGreaterThanZero);
    }
    case 'COLLAPSE_PATHS_TAB':
      return [
        {
          count: state.collapsedPathsCount,
          type: TagMetaInfoType.TAG,
        } as const,
      ].filter(isCountGreaterThanZero);

    case 'REQUIRED_COMPONENTS_TAB':
      return [
        {
          count: state.requiredComponentsCount,
          type: TagMetaInfoType.TAG,
        } as const,
      ].filter(isCountGreaterThanZero);
    case 'METAMODEL_TAB':
      return [];

    default:
      tabId satisfies never;
      return [];
  }
};

const getComponentTypeRepresentationForStartContext = (
  state: ViewpointBuilderNavigationState
): ComponentTypeRepresentationMetaInfo[] => {
  if (!state.selectedComponentTypeRepresentation?.representationData) {
    return [];
  }
  return [
    {
      label: state.selectedComponentTypeRepresentation.label,
      representationData:
        state.selectedComponentTypeRepresentation.representationData,
      type: TagMetaInfoType.COMPONENT_TYPE_REPRESENTATION,
    },
  ];
};
