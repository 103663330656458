import { modal } from '@ardoq/modal';
import FolderSelectDialog from './FolderSelectDialog';
import { ArdoqId } from '@ardoq/api-types';

export const openMoveToModal = async () => {
  const selectedFolderId = await modal<ArdoqId | null | undefined>(resolve => (
    <FolderSelectDialog closeHandler={result => resolve(result)} />
  ));
  return selectedFolderId;
};
