import {
  connectInstance,
  dispatchAction,
  reducedStream,
  reducer,
} from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { ArdoqId } from '@ardoq/api-types';
import { getInitialState, setWsId } from './reducers';
import { setActiveWsId } from './actions';
import { ExtendedWorkspaceSelectorComponent } from './ExtendedWorkspaceSelectorComponent';
import { type WorkspacePreviewState } from './types';

const setActiveWorkspaceId = (wsId: ArdoqId | undefined, instanceId: string) =>
  dispatchAction(setActiveWsId(instanceId, { wsId }));

export const ExtendedWorkspaceSelector = connectInstance(
  ExtendedWorkspaceSelectorComponent<string>,
  instanceId => {
    const workspacePreviewStream$ = reducedStream<WorkspacePreviewState>(
      `workspace-preview-${instanceId}`,
      getInitialState(),
      [reducer(setActiveWsId, setWsId)],
      { namespace: instanceId }
    );

    return workspacePreviewStream$.pipe(
      map(({ wsId }) => {
        const workspace = workspaceInterface.get(wsId ?? '');

        if (!workspace || !wsId) {
          return {
            wsInfo: null,
            setActiveWsId: (wsId: ArdoqId | undefined) =>
              setActiveWorkspaceId(wsId, instanceId),
          };
        }

        const componentTypes = workspaceInterface
          .getComponentTypes(wsId)
          .map(({ name }) => name);

        const referenceTypes = Object.values(
          workspaceInterface.getReferenceTypes(wsId) || {}
        ).map(({ name }) => name);

        return {
          wsInfo: {
            id: wsId,
            name: workspace.name,
            description: workspace.description || 'No description',
            componentTypes,
            referenceTypes,
          },
          setActiveWsId: (wsId: ArdoqId | undefined) =>
            setActiveWorkspaceId(wsId, instanceId),
        };
      })
    );
  }
);
