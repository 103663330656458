import {
  CollectionStream,
  emptyCollectionStream,
  toCollectionStream,
} from 'streams/utils/streamUtils';
import { APISurveyAttributes, ArdoqId, ResourceType } from '@ardoq/api-types';
import {
  reducedStream,
  streamReducer,
  toPersistentStream,
} from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { fetchAllReducer, websocketReducer } from 'streams/crud/reducers';
import { toResourceTypeStream, websocket$ } from 'sync/websocket$';

export const surveysNamespace = 'surveys';
export type SurveysById = Record<ArdoqId, APISurveyAttributes>;

const resetSurveys = (
  _: APISurveyAttributes[],
  surveys: APISurveyAttributes[]
): APISurveyAttributes[] => surveys;

const handleSurveyDeleted = (
  state: APISurveyAttributes[],
  id: ArdoqId
): APISurveyAttributes[] => state.filter(survey => survey._id !== id);

const handleSurveyUpdated = (
  state: APISurveyAttributes[],
  survey: APISurveyAttributes
): APISurveyAttributes[] => {
  const newModels = state.map(existingSurvey =>
    existingSurvey._id === survey._id ? survey : existingSurvey
  );

  return resetSurveys(state, newModels);
};

const handleSurveyCreated = (
  state: APISurveyAttributes[],
  response: APISurveyAttributes
): APISurveyAttributes[] => resetSurveys(state, [response, ...state]);

const surveysList$ = reducedStream<APISurveyAttributes[]>(
  'surveysList$',
  [],
  [
    fetchAllReducer(resetSurveys),
    streamReducer(
      toResourceTypeStream<APISurveyAttributes>(
        websocket$,
        ResourceType.SURVEY
      ),
      websocketReducer({
        create: handleSurveyCreated,
        update: handleSurveyUpdated,
        delete: handleSurveyDeleted,
      })
    ),
  ],
  { namespace: surveysNamespace }
);

export type SurveysStreamShape = CollectionStream<APISurveyAttributes>;
const surveys$ = toPersistentStream(
  'surveys$',
  surveysList$.pipe(map(toCollectionStream)),
  emptyCollectionStream()
);

export default surveys$;
