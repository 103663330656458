import { ViewIds } from '@ardoq/api-types';
import { radius } from '@ardoq/design-tokens';
import { AqLayout } from '@ardoq/layout';
import { Island } from '@ardoq/page-layout';
import { connect } from '@ardoq/rxbeach';
import migrationChecklist$ from './migrationChecklist$';
import MigrationViewTab from './MigrationViewTab';
import PresentationMigrationChecklistContent from './PresentationMigrationChecklistContent';
import ReportDatasourceChecklistContent, {
  REPORT_DATASOURCE_MIGRATION_ID,
} from './ReportDatasourceChecklistContent';
import { MigrationChecklistState } from './types';

const MigrationCheckList = ({
  activeMigrationId,
  presentationMetadata,
  reportDatasourceMigrationData,
}: MigrationChecklistState) => (
  <Island
    title="View migration checklist"
    subtitle="Step-by-step guide for a smooth migration process."
    bodySpacing="none"
  >
    <AqLayout
      fullWidth
      layoutWrapperStyle={{
        overflow: 'hidden',
        borderBottomLeftRadius: radius.medium,
        borderBottomRightRadius: radius.medium,
      }}
      renderLeftSidebar={() => (
        <>
          <MigrationViewTab
            title="Migration checklist"
            migrationId={REPORT_DATASOURCE_MIGRATION_ID}
            activeMigrationId={activeMigrationId}
          />
          <MigrationViewTab
            title="Relationships"
            migrationId={ViewIds.RELATIONSHIPS}
            activeMigrationId={activeMigrationId}
          />
          <MigrationViewTab
            title="Dependency Map"
            migrationId={ViewIds.DEPMAP}
            activeMigrationId={activeMigrationId}
          />
          <MigrationViewTab
            title="Sequence diagram"
            migrationId={ViewIds.SEQUENCE}
            activeMigrationId={activeMigrationId}
          />
          <MigrationViewTab
            title="Aggregation"
            migrationId={ViewIds.REFERENCE_AGGREGATION_VIEW}
            activeMigrationId={activeMigrationId}
          />
          <MigrationViewTab
            title="Process flow"
            migrationId={ViewIds.PROCESSFLOW}
            activeMigrationId={activeMigrationId}
          />
          <MigrationViewTab
            title="Heatmap"
            migrationId={ViewIds.HEATMAP}
            activeMigrationId={activeMigrationId}
          />
          <MigrationViewTab
            title="API Documentation"
            migrationId={ViewIds.API_DOCUMENTATION}
            activeMigrationId={activeMigrationId}
          />
          <MigrationViewTab
            title="Roadmap"
            migrationId={ViewIds.ROADMAP}
            activeMigrationId={activeMigrationId}
          />
        </>
      )}
    >
      {activeMigrationId === REPORT_DATASOURCE_MIGRATION_ID ? (
        <ReportDatasourceChecklistContent
          data={reportDatasourceMigrationData}
        />
      ) : (
        <PresentationMigrationChecklistContent
          activeMigration={activeMigrationId}
          relevantMetadata={presentationMetadata[activeMigrationId]}
        />
      )}
    </AqLayout>
  </Island>
);

export default connect(MigrationCheckList, migrationChecklist$);
