import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs/operators';
import {
  setTransferConfigId,
  setCurrentTableId,
  setMappingConfigId,
} from './actions';
import {
  fetchProblemRows,
  fetchPreviewRows,
} from 'integrations/common/streams/tablePreviews/actions';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { tablePreviews$ } from '../tablePreviews/getTablePreviewsStream';

const handleSetCurrentTableId = routine(
  ofType(setCurrentTableId),
  extractPayload(),
  withLatestFrom(activeIntegrations$, tablePreviews$),
  tap(([{ id, integrationId }, activeIntegrations, tablePreviews]) => {
    const sourceId = activeIntegrations[integrationId].sourceId;
    if (sourceId) {
      dispatchAction(
        fetchPreviewRows({ integrationId, sourceId, tableId: id })
      );
      dispatchAction(
        fetchProblemRows({ integrationId, sourceId, tableId: id })
      );
      dispatchAction(
        trackIntegrationEvent({
          integrationId,
          name: 'SELECTED_TABLE',
          metadata: { index: tablePreviews[integrationId][id]?.index },
        })
      );
    }
  })
);

const handleSetTransferConfigId = routine(
  ofType(setTransferConfigId),
  extractPayload(),
  tap(({ integrationId, id }) => {
    dispatchAction(setMappingConfigId({ integrationId, id }));
  })
);

export default [handleSetCurrentTableId, handleSetTransferConfigId];
