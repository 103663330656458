import { formatDateOnly, formatDateTime, parseDate } from '@ardoq/date-time';
import { DateTimePicker } from '@ardoq/date-time-picker';
import { getCurrentLocale } from '@ardoq/locale';
import { ReactNode } from 'react';

type ScheduleDateProps = {
  readonly date: string | null;
  readonly label?: string;
  readonly onChange: (date: Date | null) => void;
  readonly minDate?: string | null;
  readonly warningMessage?: string;
  readonly helperText?: ReactNode;
  readonly useTimePicker?: boolean;
};

const ScheduleDate = ({
  date,
  minDate,
  label,
  onChange,
  warningMessage,
  helperText,
  useTimePicker,
}: ScheduleDateProps) => {
  return (
    <DateTimePicker
      label={label}
      helperText={helperText}
      date={date ? parseDate(date) : null}
      onChange={onChange}
      config={{
        formatter: (value, locale) => {
          return useTimePicker
            ? formatDateTime(value ?? null, locale)
            : formatDateOnly(value ?? null, locale);
        },
        locale: getCurrentLocale(),
        ...(minDate ? { minDate: parseDate(minDate) } : {}),
        useTimePicker: useTimePicker,
      }}
      warningMessage={warningMessage}
    />
  );
};

export default ScheduleDate;
