import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  catchError,
  forkJoin,
  of,
  switchMap,
  tap,
  withLatestFrom,
  filter,
  firstValueFrom,
} from 'rxjs';
import { showScheduleInformation, editSchedule } from './actions';
import { showSchedulesDrawer } from 'integrations/dashboard/widgets/schedules/schedulesDrawer/SchedulesDrawer';
import {
  connections$,
  getConnectionsStream,
} from 'integrations/common/streams/connections/connections$';
import {
  updateConnectionList,
  updateConnectionStatuses,
} from 'integrations/common/streams/connections/actions';
import { retriableRequest } from 'integrations/common/utils/api';
import * as apiUtils from 'integrations/common/streams/connections/apiUtils';
import { getScheduleConnectionName } from 'integrations/common/streams/schedules/utils';
import { setVisibleIntegration } from 'integrations/actions';
import { loadSchedule } from 'integrations/common/streams/schedule/actions';
import { ImportRoute } from 'integrations/common/navigation/types';

const handleShowScheduleInformation = routine(
  ofType(showScheduleInformation),
  extractPayload(),
  withLatestFrom(connections$),
  switchMap(([{ integrationId, schedule }, connectionsState]) => {
    const isInit =
      connectionsState[integrationId]?.statuses.list.status === 'INIT';

    if (isInit) {
      dispatchAction(
        updateConnectionStatuses({
          integrationId,
          statuses: {
            list: { status: 'LOADING' },
          },
        })
      );
    }

    return forkJoin({
      connections: isInit
        ? retriableRequest(
            () => apiUtils.fetchConnectionsList(integrationId),
            'Unable to fetch connections list'
          ).pipe(
            catchError(() => {
              dispatchAction(
                updateConnectionStatuses({
                  integrationId,
                  statuses: {
                    list: {
                      status: 'FAILURE',
                      message:
                        "We're unable to fetch the connections. Please refresh the app and try again.",
                    },
                  },
                })
              );
              return of([]);
            })
          )
        : of(connectionsState[integrationId]?.connections || []),
      integrationId: of(integrationId),
      schedule: of(schedule),
    });
  }),
  tap(({ integrationId, schedule, connections }) => {
    dispatchAction(
      updateConnectionList({ integrationId, connectionsState: { connections } })
    );
    dispatchAction(
      updateConnectionStatuses({
        integrationId,
        statuses: { list: { status: 'SUCCESS' } },
      })
    );

    const connectionName = getScheduleConnectionName(schedule, connections);

    showSchedulesDrawer({
      integrationId,
      schedule,
      connectionName,
    });
  })
);

const handleEditSchedule = routine(
  ofType(editSchedule),
  extractPayload(),
  withLatestFrom(connections$),
  tap(async ([{ integrationId, scheduleId }, connectionsState]) => {
    dispatchAction(
      setVisibleIntegration({
        id: integrationId,
        path: ImportRoute.SELECT_DATA,
      })
    );
    if (
      ['INIT', 'LOADING'].includes(
        connectionsState[integrationId].statuses.list.status
      )
    ) {
      const integrationsConnections = getConnectionsStream(integrationId);
      const stream$ = integrationsConnections.pipe(
        filter(
          connections =>
            connections.statuses.list.status === 'SUCCESS' ||
            connections.statuses.list.status === 'FAILURE'
        )
      );
      await firstValueFrom(stream$);
    }
    dispatchAction(loadSchedule({ integrationId, scheduleId }));
  })
);

export default [handleShowScheduleInformation, handleEditSchedule];
