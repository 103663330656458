import { ArdoqId, RenameRequest } from '@ardoq/api-types';
import { ButtonGroup, PrimaryButton, GhostButton } from '@ardoq/button';
import { useOnMount, useResizeObserver } from '@ardoq/hooks';
import { AqLayout } from '@ardoq/layout';
import { ModalHeader } from '@ardoq/modal';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { StackPageProps } from '@ardoq/stack-page-manager';
import { RowType } from '@ardoq/table';
import { Tab, TabsGroup } from '@ardoq/tabs';
import DocumentBrowser from 'components/DocumentBrowser/DocumentBrowser';
import {
  alertDuplicatedNamesConflict,
  alertFileSizeIsTooBig,
  areFileNamesUniq,
  useDragover,
} from 'components/DocumentBrowser/utils';
import {
  setIsLoading,
  setIsLoadingWithConfig,
} from 'components/GlobalPaneLoader/globalPaneLoader$';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { useRef } from 'react';
import { MaybeIsScenarioMode } from 'scope/types';
import { CurrentUserState } from 'streams/currentUser/currentUser$';
import {
  setDocumentArchiveContext,
  setDocumentArchiveUIDefaultState,
  setExpandedFoldersIds,
  setRenameFocus,
  setSelected,
  uploadFiles,
} from './actions';
import {
  DocumentArchiveContext,
  DocumentArchiveMode,
  DocumentBrowserEntity,
  DocumentBrowserUIState,
} from './types';
import {
  documentBrowserStackPage$,
  MoveAssetsPayload,
} from './documentBrowserStackPage$';

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB

export const areFileSizesValid = (files: File[] = []) =>
  !files.find(file => file.size > MAX_FILE_SIZE);

const areFileNamesValid = (
  files: File[] = [],
  context: DocumentArchiveContext
) => {
  const fileNames = files.map(({ name }) => name);
  return areFileNamesUniq(fileNames, context);
};

const getUploadFilesTypeInfo = (
  context: DocumentArchiveContext,
  workspaceId: ArdoqId,
  organizationId: ArdoqId
) => {
  if (context === DocumentArchiveContext.WS) {
    return { type: 'workspace' as const, typeId: workspaceId };
  }
  return { type: 'org' as const, typeId: organizationId };
};

const DocumentBrowserStackPage = ({
  context,
  mode,
  selected,
  defaultUIState,
  onAddSelectedFilesClick,
  addSelectedFilesButtonLabel,
  isScenarioMode,
  doRename,
  doCreateFolder,
  moveAssets,
  currentUser,
  workspaceId,
  ...props
}: StackPageProps &
  Pick<DocumentBrowserUIState, 'context' | 'mode' | 'selected'> &
  MaybeIsScenarioMode & {
    onAddSelectedFilesClick?: (selected: ArdoqId[]) => void;
    addSelectedFilesButtonLabel?: string;
    defaultUIState?: Partial<DocumentBrowserUIState>;
    doRename: (
      context: DocumentArchiveContext,
      rowType: RowType,
      renameRequest: RenameRequest,
      currentUser: CurrentUserState,
      workspaceId: ArdoqId
    ) => void;
    doCreateFolder: () => void;
    moveAssets: (payload: MoveAssetsPayload) => void;
    currentUser: CurrentUserState;
    workspaceId: ArdoqId;
  }) => {
  const ref = useRef<HTMLDivElement>(null);
  const size = useResizeObserver(ref);
  useOnMount(() => {
    dispatchAction(setDocumentArchiveUIDefaultState(defaultUIState));
  });

  useDragover({
    onEnter: () => {
      dispatchAction(
        setIsLoadingWithConfig({
          title: 'Drop file(s) to upload',
          showSpinner: false,
        })
      );
    },
    onLeave: () => {
      dispatchAction(setIsLoading(false));
    },
    onDrop: async event => {
      const files = Array.from(event.dataTransfer?.files || []);
      if (!areFileSizesValid(files)) {
        dispatchAction(setIsLoading(false));
        alertFileSizeIsTooBig();
        return;
      }
      if (!areFileNamesValid(files, context)) {
        dispatchAction(setIsLoading(false));
        alertDuplicatedNamesConflict();
        return;
      }

      if (files) {
        dispatchAction(
          setIsLoadingWithConfig({
            title: 'Uploading...',
          })
        );
        dispatchAction(
          uploadFiles({
            files,
            folderId: null,
            ...getUploadFilesTypeInfo(
              context,
              workspaceId,
              currentUser.organization._id
            ),
          })
        );
      }
      // TODO `uploadFiles` should be handeled by routine where we take care about things
      // like hiding loading state once the upload is completed.
      // I will make card for this. For now let's just show this loading overlay for 0,5s
      await new Promise(resolve => setTimeout(resolve, 500));
      dispatchAction(setIsLoading(false));
    },
  });

  const fileInput = useRef<HTMLInputElement | null>(null);

  const selectFiles = (event: any) => {
    event.preventDefault();
    fileInput.current?.click();
  };

  const openFile = (files: File[] = []) => {
    if (!areFileSizesValid(files)) {
      alertFileSizeIsTooBig();
      return;
    }
    if (!areFileNamesValid(files, context)) {
      alertDuplicatedNamesConflict();
      return;
    }
    if (files.length) {
      dispatchAction(
        uploadFiles({
          files,
          folderId: null,
          ...getUploadFilesTypeInfo(
            context,
            workspaceId,
            currentUser.organization._id
          ),
        })
      );
    }
  };

  const handleTabChange = (tabId: string) => {
    if (tabId === 'global') {
      dispatchAction(setDocumentArchiveContext(DocumentArchiveContext.ORG));
      return;
    }
    dispatchAction(setDocumentArchiveContext(DocumentArchiveContext.WS));
  };

  return (
    <>
      <ModalHeader
        title="Document Archive"
        onCloseButtonClick={props.closeStackPage}
        closeButtonClickId="close-document-archive"
      />
      <AqLayout
        ref={ref}
        bodyContentStyle={{
          padding: 0,
          height: '100%',
          position: 'relative',
        }}
        headerBody={
          <TabsGroup
            overflowType="chevrons"
            onTabChange={tabId => handleTabChange(tabId)}
            rightContent={
              <ButtonGroup>
                {/* padding added to give space from top */}
                <GhostButton onClick={selectFiles} isDisabled={isScenarioMode}>
                  Upload file
                </GhostButton>
                {mode === DocumentArchiveMode.FILE_PICKER ? (
                  <PrimaryButton
                    key="add-selected-button"
                    isDisabled={!selected.length}
                    onClick={() => onAddSelectedFilesClick?.(selected)}
                  >
                    {addSelectedFilesButtonLabel}{' '}
                    {selected.length ? `(${selected.length})` : ''}
                  </PrimaryButton>
                ) : (
                  <GhostButton
                    key="create-folder-button"
                    onClick={doCreateFolder}
                    isDisabled={isScenarioMode}
                  >
                    Create new folder
                  </GhostButton>
                )}
              </ButtonGroup>
            }
          >
            <Tab
              tabId="global"
              label="Global"
              isActive={context === DocumentArchiveContext.ORG}
            />
            <Tab
              tabId="workspace"
              label={workspaceInterface.getCurrentWsName() || 'Workspace'}
              isActive={context === DocumentArchiveContext.WS}
            />
          </TabsGroup>
        }
      >
        <DocumentBrowser
          isDndEnabled={mode === DocumentArchiveMode.DEFAULT}
          setSelected={(ids: ArdoqId[]) => {
            dispatchAction(setSelected(ids));
          }}
          setExpandedFoldersIds={(ids: ArdoqId[]) =>
            dispatchAction(setExpandedFoldersIds(ids))
          }
          onEditNameConfirm={(name: string, row: DocumentBrowserEntity) => {
            if (areFileNamesUniq([name], context)) {
              const renameRequest = {
                name,
                _id: row._id,
                _version: row._version,
              };
              doRename(
                context,
                row.rowType,
                renameRequest,
                currentUser,
                workspaceId
              );
            } else {
              dispatchAction(setRenameFocus(null));
              alertDuplicatedNamesConflict();
            }
          }}
          onEditNameCancel={() => {
            dispatchAction(setRenameFocus(null));
          }}
          onAssetsMove={(assetIds: ArdoqId[], folderId: ArdoqId | null) => {
            moveAssets({
              assetIds,
              folderId,
              context,
              currentUser,
              workspaceId,
            });
          }}
          tableHeight={(size.height || 0) - 190}
        />
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={event => {
            const files = Array.from(event.target.files || []);
            // target has to be cleard, othewhise we won't get "change"
            // event if we try to upload file with the same name
            if (fileInput && fileInput.current) {
              fileInput.current.value = '';
            }
            openFile(files);
          }}
          ref={fileInput}
          multiple
        />
      </AqLayout>
    </>
  );
};

export default connect(DocumentBrowserStackPage, documentBrowserStackPage$);
