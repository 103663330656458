import {
  APIComponentAttributes,
  APIEntityType,
  APIFieldAttributes,
  APIReferenceAttributes,
  ArdoqId,
  DateRangeFieldDefaultValue,
  ScopeDataCollection,
  Verb,
  APIResetData,
  APIResetRequest,
} from '@ardoq/api-types';
import { Branch } from 'components/DiffMergeTable/Branch';
import { EnhancedDiffContextData } from 'components/DiffMergeTable/EnhancedDiffContextDataType';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';

export const splitDateRangeFields = (
  payload: APIResetRequest,
  enhancedDiffContextData: EnhancedDiffContextData
): APIResetRequest => ({
  ...payload,
  [ScopeDataCollection.FIELDS]: splitFields(
    payload[ScopeDataCollection.FIELDS],
    enhancedDiffContextData
  ),
  [ScopeDataCollection.COMPONENTS]:
    splitFieldsOnEntities<APIComponentAttributes>(
      payload[ScopeDataCollection.COMPONENTS],
      APIEntityType.COMPONENT,
      enhancedDiffContextData
    ),
  [ScopeDataCollection.REFERENCES]:
    splitFieldsOnEntities<APIReferenceAttributes>(
      payload[ScopeDataCollection.REFERENCES],
      APIEntityType.REFERENCE,
      enhancedDiffContextData
    ),
});

const splitFieldsOnEntities = <
  T extends APIComponentAttributes | APIReferenceAttributes,
>(
  resetDataForEntities: APIResetData<T>,
  entityType: APIEntityType,
  enhancedDiffContextData: EnhancedDiffContextData
) => ({
  ...resetDataForEntities,
  [Verb.CREATE]: resetDataForEntities[Verb.CREATE].map(entity =>
    dateRangeOperations.splitFieldsOnEntity<T>(
      entity,
      entity.rootWorkspace,
      enhancedDiffContextData[Branch.SOURCE]
    )
  ),
  [Verb.UPDATE]: resetDataForEntities[Verb.UPDATE].map(entity =>
    dateRangeOperations.splitFieldsOnEntity<T>(
      entity,
      entity.rootWorkspace,
      enhancedDiffContextData[Branch.SOURCE]
    )
  ),
});

const splitFields = (
  resetDataForFields: APIResetData<APIFieldAttributes>,
  enhancedDiffContextData: EnhancedDiffContextData
) => ({
  [Verb.DELETE]: splitDeletedFieldIds(
    resetDataForFields[Verb.DELETE],
    enhancedDiffContextData
  ),
  [Verb.CREATE]: splitCreatedFields(
    resetDataForFields[Verb.CREATE],
    enhancedDiffContextData
  ),
  [Verb.UPDATE]: splitUpdatedFields(
    resetDataForFields[Verb.UPDATE],
    enhancedDiffContextData
  ),
});

const splitUpdatedFields = (
  fields: APIFieldAttributes[],
  enhancedDiffContextData: EnhancedDiffContextData
) => {
  const targetModelIdWorkspaceMap = new Map(
    enhancedDiffContextData[Branch.TARGET].workspaces.map(workspace => [
      workspace.componentModel,
      workspace._id,
    ])
  );
  return fields.flatMap(field => {
    if (!isDateRangeFieldType(field.type)) return field;
    const workspaceId = targetModelIdWorkspaceMap.get(field.model);
    const dateRangeFieldsForWorkspace = enhancedDiffContextData[
      Branch.TARGET
    ].dateRangeFieldMap!.get(workspaceId!)!;
    const { start, end } = dateRangeFieldsForWorkspace[field.name];
    const fieldAttributes: Array<keyof APIFieldAttributes> = [
      'description',
      'componentType',
      'referenceType',
      'global',
      'globalref',
    ];

    return [
      {
        ...start,
        ...Object.fromEntries(
          fieldAttributes.map(fieldAttributeName => [
            fieldAttributeName,
            field[fieldAttributeName],
          ])
        ),
        defaultValue: (field.defaultValue as DateRangeFieldDefaultValue).start,
      },
      {
        ...end,
        ...Object.fromEntries(
          fieldAttributes.map(fieldAttributeName => [
            fieldAttributeName,
            field[fieldAttributeName],
          ])
        ),
        defaultValue: (field.defaultValue as DateRangeFieldDefaultValue).end,
      },
    ];
  });
};

export const splitCreatedFields = (
  fields: APIFieldAttributes[],
  enhancedDiffContextData: EnhancedDiffContextData
): APIFieldAttributes[] => {
  const sourceModelIdWorkspaceIdMap = new Map(
    enhancedDiffContextData[Branch.SOURCE].workspaces.map(workspace => [
      workspace.componentModel,
      workspace._id,
    ])
  );
  return fields.flatMap(field => {
    if (!isDateRangeFieldType(field.type)) return field;
    const workspaceId = sourceModelIdWorkspaceIdMap.get(field.model);
    const dateRangeFieldsForWorkspace = enhancedDiffContextData[
      Branch.SOURCE
    ].dateRangeFieldMap!.get(workspaceId!)!;
    const { start, end } = dateRangeFieldsForWorkspace[field.name];
    return [start, end];
  });
};

const splitDeletedFieldIds = (
  fieldIds: ArdoqId[],
  enhancedDiffContextData: EnhancedDiffContextData
) => {
  const targetModelIdWorkspaceMap = new Map(
    enhancedDiffContextData[Branch.TARGET].workspaces.map(workspace => [
      workspace.componentModel,
      workspace._id,
    ])
  );

  return fieldIds.flatMap(fieldId => {
    const field = enhancedDiffContextData[Branch.TARGET].fieldsById[fieldId];
    if (!isDateRangeFieldType(field.type)) return fieldId;

    const workspaceId = targetModelIdWorkspaceMap.get(field.model);
    const dateRangeFieldsForWorkspace = enhancedDiffContextData[
      Branch.TARGET
    ].dateRangeFieldMap!.get(workspaceId!)!;
    const { end: dateRangeEndField } = dateRangeFieldsForWorkspace[field.name];
    return [fieldId, dateRangeEndField._id];
  });
};
