import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  dynamicFilterQueryEditor$,
  parameterQueryEditor$,
  QueryEditorNamespace,
  QueryEditorStateShape,
} from 'search/QueryEditor/queryEditor$';
import BodyHeader from 'atomicComponents/Layout/BodyHeader';
import { SearchBackend } from '@ardoq/api-types';
import { SearchTabContainer } from 'search/QueryEditor/atoms';
import {
  dynamicFilterQueryResults$,
  parameterQueryResults$,
} from 'search/Gremlin/gremlinResults$';
import { GremlinSearchStateShape } from 'search/Gremlin/types';
import { SearchPane } from 'search/SearchTabContainer/types';
import QueryNameEditor from 'search/QueryEditor/QueryNameEditor';
import { saveSearchQuery } from 'search/actions';
import GoToOverviewButton from '../GoToOverviewButton';
import ReturnButton from '../ReturnButton';
import ParameterQueryEditor from './ParameterQueryEditor';
import DynamicQueryEditor from './DynamicQueryEditor';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { isEmpty } from 'lodash';

const saveDynamicAndParametricQuery = (
  dynamicQueryEditor: QueryEditorStateShape,
  parameterQueryEditor: QueryEditorStateShape
) => {
  dispatchAction(
    saveSearchQuery({
      model: dynamicQueryEditor.model,
      queryId: dynamicQueryEditor.selectedQueryId,
    }),
    QueryEditorNamespace.DYANMIC_FILTER_QUERY
  );
  dispatchAction(
    saveSearchQuery({
      model: parameterQueryEditor.model,
      queryId: parameterQueryEditor.selectedQueryId,
    }),
    QueryEditorNamespace.PARAMETER_QUERY
  );
};

type ViewProps = {
  dynamicQueryEditor: QueryEditorStateShape;
  dynamicGremlinResults: GremlinSearchStateShape;
  parameterQueryEditor: QueryEditorStateShape;
  parameterGremlinResults: GremlinSearchStateShape;
};

const DynamicSearch = ({
  dynamicQueryEditor,
  dynamicGremlinResults,
  parameterQueryEditor,
  parameterGremlinResults,
}: ViewProps) => {
  const isParameterizedSearch = Boolean(
    dynamicQueryEditor.model.parameterQueryId
  );
  const showParameterQueryEditor = isParameterizedSearch;
  const parameterQueryHasResults = !isEmpty(parameterGremlinResults.results);
  const showDynamicQueryEditor =
    (((!parameterGremlinResults.searchWarning &&
      !parameterQueryEditor.isSearching) ||
      !parameterGremlinResults.syntaxError) &&
      parameterQueryHasResults) ||
    !dynamicQueryEditor.model.parameterQueryId;

  return (
    <>
      <BodyHeader
        style={{ padding: '32px 32px 0' }}
        title={`Gremlin ${
          isParameterizedSearch ? 'Parameterized' : 'Plain'
        } Graph Filter`}
      >
        {dynamicGremlinResults.returnLocation ? (
          <ReturnButton returnLocation={dynamicGremlinResults.returnLocation} />
        ) : (
          <GoToOverviewButton searchPane={SearchPane.DYNAMIC_FILTER_OVERVIEW} />
        )}
      </BodyHeader>
      <SearchTabContainer>
        <QueryNameEditor
          placeholder="Graph filter name"
          searchBackend={SearchBackend.GREMLIN}
          model={dynamicQueryEditor.model}
          isEditing={dynamicQueryEditor.isEditing}
          hasChanges={
            dynamicQueryEditor.hasChanges || parameterQueryEditor.hasChanges
          }
          hasSaveError={dynamicQueryEditor.hasSaveError}
          selectedQueryId={dynamicQueryEditor.selectedQueryId}
          hasWriteAccess
          onSave={() =>
            saveDynamicAndParametricQuery(
              dynamicQueryEditor,
              parameterQueryEditor
            )
          }
        />
        {showParameterQueryEditor && (
          <ParameterQueryEditor
            parameterQueryEditor={parameterQueryEditor}
            parameterGremlinResults={parameterGremlinResults}
            queryParams={dynamicQueryEditor.queryParams}
            isEditing={dynamicQueryEditor.isEditing}
          />
        )}
        {showDynamicQueryEditor && (
          <DynamicQueryEditor
            dynamicQueryEditor={dynamicQueryEditor}
            dynamicGremlinResults={dynamicGremlinResults}
          />
        )}
      </SearchTabContainer>
    </>
  );
};

const mapStreamsToViewProps = ([
  dynamicQueryEditor,
  dynamicGremlinResults,
  parameterQueryEditor,
  parameterGremlinResults,
]: [
  QueryEditorStateShape,
  GremlinSearchStateShape,
  QueryEditorStateShape,
  GremlinSearchStateShape,
]): ViewProps => ({
  dynamicQueryEditor,
  dynamicGremlinResults,
  parameterQueryEditor,
  parameterGremlinResults,
});

export default connect(
  DynamicSearch,
  combineLatest([
    dynamicFilterQueryEditor$,
    dynamicFilterQueryResults$,
    parameterQueryEditor$,
    parameterQueryResults$,
  ]).pipe(map(mapStreamsToViewProps))
);
