import { Multiselect } from '@ardoq/select';
import { byLocaleField } from 'integrations/common/utils/common';

type RegionsSelectorProps = {
  regions: any[];
  onSelect: (ids: string[]) => void;
  selectedRegionIds: string[];
  isDisabled: boolean;
  helperText: string;
};

export const RegionsSelector = ({
  selectedRegionIds,
  regions,
  onSelect,
  isDisabled,
  helperText,
}: RegionsSelectorProps) => {
  const options = [...regions].sort(byLocaleField('label'));

  return (
    <Multiselect
      options={options}
      placeholder="Type and select a region"
      onValueChange={ids => onSelect(ids || [])}
      value={selectedRegionIds}
      helperText={helperText}
      isDisabled={isDisabled}
    />
  );
};
