import { FieldsWrapper } from '@ardoq/forms';
import { Multiselect } from '@ardoq/select';
import { SurveyQuestionValidation } from 'surveyAdmin/types';
import {
  APIFieldAttributes,
  APIFieldType,
  ArdoqId,
  ValidDisplayConditions,
} from '@ardoq/api-types';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { isUnsetCondition } from '../utils';
import { ExcludeFalsy } from '@ardoq/common-helpers';

const getDisplayConditionOptions = (
  chosenFieldId: ArdoqId,
  parentQuestionFieldType: APIFieldType
) => {
  switch (parentQuestionFieldType) {
    case APIFieldType.CHECKBOX:
      return [
        { value: 'true', label: 'Checked' },
        { value: 'false', label: 'Unchecked' },
        { value: 'unset', label: 'Unset' },
      ];
    case APIFieldType.LIST:
      return fieldInterface
        .getAllowedValues(chosenFieldId)
        .map(value => ({ value, label: value }));
    default:
      return [];
  }
};

const convertValueToValidDisplayCondition = (
  values: (string | boolean)[],
  parentQuestionFieldType: APIFieldType
): ValidDisplayConditions[] => {
  switch (parentQuestionFieldType) {
    case APIFieldType.CHECKBOX:
      return values.map(value =>
        value === 'unset'
          ? { type: value }
          : { value: value !== 'false', type: 'value' }
      );
    case APIFieldType.LIST:
      return values.map(value => ({ value, type: 'value' }));
    default:
      return [];
  }
};

const convertConditionToValidSelectValue = (
  displayConditions: ValidDisplayConditions[] = [],
  parentQuestionFieldType: APIFieldType
) => {
  switch (parentQuestionFieldType) {
    case APIFieldType.CHECKBOX:
      return displayConditions.map(condition =>
        isUnsetCondition(condition)
          ? condition.type
          : condition.value.toString()
      );
    case APIFieldType.LIST:
      return displayConditions
        .map(condition =>
          isUnsetCondition(condition) ? null : condition.value
        )
        .filter(ExcludeFalsy);
    default:
      return [];
  }
};

type DisplayConditionsQuestionProps = {
  onBlur: () => void;
  parentQuestionField: APIFieldAttributes;
  displayConditions?: ValidDisplayConditions[];
  parentQuestionTitle: string;
  errorMessage?: SurveyQuestionValidation['message'];
  updatedDisplayConditions: (
    displayConditions: ValidDisplayConditions[]
  ) => void;
};

const DisplayConditionsQuestion = ({
  onBlur = () => {},
  parentQuestionField,
  displayConditions = [],
  parentQuestionTitle,
  errorMessage,
  updatedDisplayConditions,
}: DisplayConditionsQuestionProps) => {
  const displayConditionOptions = getDisplayConditionOptions(
    parentQuestionField._id,
    parentQuestionField.type
  );
  const parentQuestionHelperText = parentQuestionTitle
    ? `"${parentQuestionTitle}"`
    : `Field question (${parentQuestionField.name})`;

  const fieldSpecificLabelAddition =
    parentQuestionField.type === APIFieldType.CHECKBOX
      ? 'when the checkbox state is:'
      : 'if a user selects:';
  const fieldSpecificNoteAddition =
    parentQuestionField.type === APIFieldType.CHECKBOX
      ? `checkbox state.`
      : `option.`;

  return (
    <FieldsWrapper>
      <Multiselect
        label={`This question will only appear ${fieldSpecificLabelAddition}`}
        helperText={`Based on the answer from ${parentQuestionHelperText}`}
        dataTestId="conditional-reference-select"
        options={displayConditionOptions}
        onValueChange={values => {
          if (!values) return;
          const convertedValues = convertValueToValidDisplayCondition(
            values,
            parentQuestionField.type
          );
          updatedDisplayConditions(convertedValues);
        }}
        value={convertConditionToValidSelectValue(
          displayConditions,
          parentQuestionField.type
        )}
        onBlur={onBlur}
        errorMessage={errorMessage}
        popoverHelpContent={`This question is only shown to users who have the 
        specified response(s) to ${parentQuestionHelperText}. 
        You can specify more than one ${fieldSpecificNoteAddition}`}
        hintMessage="Required"
      />
    </FieldsWrapper>
  );
};

export default DisplayConditionsQuestion;
