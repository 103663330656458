import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { modal } from '@ardoq/modal';
import { tap, withLatestFrom } from 'rxjs/operators';
import {
  initializeManageSubdivisionMembershipModal,
  saveSubdivisionMemberships,
  showManageSubdivisionMembershipModal,
} from './actions';
import ManageMembershipModal from './ManageMembershipModal';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import {
  addComponentsToSubdivision,
  removeComponentsFromSubdivision,
} from 'streams/subdivisions/actions';
import { subdivisionAccessControlInterface } from 'resourcePermissions/accessControlHelpers/subdivisions';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { componentInterface } from '@ardoq/component-interface';
import { ArdoqId } from '@ardoq/api-types';
import { context$ } from 'streams/context/context$';
import workspaces$ from 'streams/workspaces/workspaces$';
import Components from 'collections/components';
import { ComponentBackboneModel } from 'aqTypes';
import { manageMembershipModal$ } from './viewmodel$';
import { manageMembershipModalOperations } from './manageMembershipModalOperations';
import { membershipOperations } from './membershipOperations';
import { getRelevantComponentData } from './helpers';
import { componentSelection$ } from 'componentSelection/componentSelection$';
import { decoratedSubdivisionOperations } from './decoratedSubdivisionOperations';
import {
  DIVISIONS_USER_EVENTS,
  trackDivisionMembershipUserEvent,
} from 'subdivisionEditor/trackingUtils';

const getCorrectSelectedRootComponents = (
  currentComponentId: ArdoqId,
  selection: string[]
): ArdoqId[] => {
  const selectedRootComponents = selection
    .map(id => Components.collection.get(id))
    .filter((component): component is ComponentBackboneModel =>
      Boolean(component)
    )
    .map(node => node.id)
    .filter(
      componentId => componentInterface.getParentId(componentId) === null
    );
  const currentIsSelected = selectedRootComponents.includes(currentComponentId);
  return currentIsSelected ? selectedRootComponents : [currentComponentId];
};

const handleShowManageSubdivisionMembershipModal = routine(
  ofType(showManageSubdivisionMembershipModal),
  extractPayload(),
  withLatestFrom(
    subdivisions$,
    currentUserPermissionContext$,
    context$,
    workspaces$,
    componentSelection$,
    manageMembershipModal$
  ),
  tap(
    ([
      rootComponentIds,
      subdivisionsContext,
      permissionContext,
      context,
      workspaces,
      { treeSelection },
      _,
    ]) => {
      const relevantRootComponentIds = Array.isArray(rootComponentIds)
        ? rootComponentIds
        : getCorrectSelectedRootComponents(rootComponentIds, treeSelection);

      const relevantRootComponentsWithEditableMemberships =
        relevantRootComponentIds
          .map(componentInterface.getLiteAttributes)
          .filter(ExcludeFalsy)
          .map(component =>
            getRelevantComponentData(component, {
              permissionContext,
              subdivisionsContext,
            })
          );

      const editableAndRelevantSubdivisions =
        subdivisionAccessControlInterface.getEditableSubdivisionsList(
          permissionContext,
          subdivisionsContext
        );

      const decoratedSubdivisions = decoratedSubdivisionOperations.decorateBulk(
        editableAndRelevantSubdivisions,
        {
          workspacesById: workspaces.byId,
          contextWorkspaceId: context.workspaceId,
          relevantComponents: relevantRootComponentsWithEditableMemberships,
        }
      );

      const membershipsState = membershipOperations.createMembershipsState(
        relevantRootComponentsWithEditableMemberships
      );
      trackDivisionMembershipUserEvent(
        DIVISIONS_USER_EVENTS.OPENED_DIVISION_MEMBERSHIP_MODAL,
        {
          componentsCount: relevantRootComponentsWithEditableMemberships.length,
          subdivisionsCount: editableAndRelevantSubdivisions.length,
        }
      );
      const initialState = {
        ...manageMembershipModalOperations.getEmptyState(),
        subdivisions: decoratedSubdivisions,
        components: relevantRootComponentsWithEditableMemberships,
        membershipsState,
        subdivisionsContext,
        isBulkChange: relevantRootComponentsWithEditableMemberships.length > 1,
        selectedComponentRows: relevantRootComponentIds,
      };

      dispatchAction(initializeManageSubdivisionMembershipModal(initialState));
    }
  ),
  tap(() => {
    modal(resolve => ManageMembershipModal({ onClose: () => resolve(false) }));
  })
);

const handleSaveSubdivisionMemberships = routine(
  ofType(saveSubdivisionMemberships),
  extractPayload(),
  tap(({ toAssign, toRevoke }) => {
    if (Object.keys(toAssign).length > 0) {
      dispatchAction(addComponentsToSubdivision(toAssign));
    }
    if (Object.keys(toRevoke).length > 0) {
      dispatchAction(removeComponentsFromSubdivision(toRevoke));
    }
  })
);

export const manageMembershipModalRoutines = collectRoutines(
  handleShowManageSubdivisionMembershipModal,
  handleSaveSubdivisionMemberships
);
