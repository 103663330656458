import {
  Font,
  ILabel,
  IRenderContext,
  LabelStyleBase,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import addText from 'yfilesExtensions/addText';
import { Node } from 'graph/node';
import {
  CollapsibleGraphGroup,
  ComponentRepresentationData,
  ensureRoot,
  GraphEdge,
  GraphItem,
  GraphNode,
  ReferenceTypeRepresentationData,
  GRAPH_ITEM_LABEL_CACHE_SIZE,
} from '@ardoq/graph';
import { createFifoCache } from '@ardoq/common-helpers';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import SparkMD5 from 'spark-md5';
import {
  DATA_RENDER_HASH,
  LABEL_HORIZONTAL_PADDING,
  LABEL_VERTICAL_PADDING,
} from '../../../yfilesExtensions/styles/consts';
import { EDGE_LABEL_FONT, LABEL_TEXT_PARAMS, NODE_LABEL_FONT } from './consts';
import getLabelPreferredSize from './getLabelPreferredSize';
import { ensureContrast } from '@ardoq/color-helpers';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { GLOBAL_HANDLER_ID_ATTRIBUTE } from 'consts';
import { colors } from '@ardoq/design-tokens';
import { GRAPH_EDGE_ID, GRAPH_NODE_ID } from '../../consts';
import { createElement } from 'react';
import { createSvgElement } from '@ardoq/dom-utils';
import measureLabelElement from './measureLabelElement';

export const LABEL_BACKGROUND_CLASS = 'label-background';

const labelIsNode = (label: ILabel) => {
  const graphItem = label.owner ? label.owner.tag : null;
  return (
    graphItem instanceof Node ||
    graphItem instanceof GraphNode ||
    graphItem instanceof CollapsibleGraphGroup
  );
};

const getLabelFont = (label: ILabel) =>
  labelIsNode(label) ? NODE_LABEL_FONT : EDGE_LABEL_FONT;

const getHash = (label: ILabel) => {
  const owner = label.owner!.tag as GraphEdge | GraphNode;
  const metaData = owner?.metaData;
  return SparkMD5.hash(
    `${label.text},${JSON.stringify(
      metaData
    )}}${owner?.getIsSelected()}${owner?.getOpacity()}`
  );
};

const render = (container: SVGGElement, label: ILabel, hash: string) => {
  container.innerHTML = '';
  container.setAttribute(DATA_RENDER_HASH, hash);
  const { text, layout } = label;
  if (!text) {
    return;
  }

  const isNodeLabel = labelIsNode(label);
  const node: Node | GraphItem = label.owner!.tag;
  const modelId = dataModelId(node);
  container.setAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE, modelId);
  if (isNodeLabel) {
    container.setAttribute(GRAPH_NODE_ID, node.id);
    container.setAttribute('data-test-id', 'component-type-label');
  } else {
    container.setAttribute(GRAPH_EDGE_ID, node.id);
    container.setAttribute('data-test-id', 'reference-type-label');
  }

  const centerTransformOrigin = `${layout.width / 2}px ${
    layout.height / 4
  }px 0px`;
  container.setAttribute('transform-origin', centerTransformOrigin);

  const getTextElement = isNodeLabel
    ? nodeLabelTextElementCache
    : edgeLabelTextElementCache;
  const {
    textElement: cachedTextElement,
    width,
    isSingleLine,
  } = getTextElement(text);
  // text elements are cached based on the text value, so it's possible we'll be pulling another node's label (with the same text) from the cache.
  // we don't want to steal another node's label! if it already has a parent, it's being used, so just clone it.
  const textElement = cachedTextElement.parentNode
    ? (cachedTextElement.cloneNode(true) as SVGTextElement)
    : cachedTextElement;

  const representationData:
    | ComponentRepresentationData
    | ReferenceTypeRepresentationData
    // @ts-expect-error metaData is not defined on the GraphItem class
    | null = node?.metaData?.representationData;

  let labelColor = representationData?.color ?? colors.black;
  if (node instanceof GraphEdge && node.getIsSelected()) {
    labelColor = colors.yellow60;
  }

  const opacity = node instanceof GraphItem ? node.getOpacity() : 1;
  textElement.style.transition = 'opacity 0.5s';

  textElement.style.fill = ensureContrast(
    'white', // the colour we use here to ensure readability
    labelColor
  );
  textElement.style.stroke = 'none';
  const translateX = isSingleLine
    ? layout.width / 2
    : width / 2 + LABEL_HORIZONTAL_PADDING / 2;
  const translateY = isSingleLine
    ? layout.height / 2
    : LABEL_VERTICAL_PADDING / 2;

  if (isSingleLine) {
    textElement.setAttribute('dominant-baseline', 'central');
  }
  textElement.setAttribute(
    'transform',
    `translate(${translateX} ${translateY})`
  );
  textElement.style.cursor = 'pointer';

  container.style.pointerEvents = modelId ? '' : 'none';

  const rect = createSvgElement('rect', {
    width: `${label.layout.width}`,
    height: `${label.layout.height}`,
    stroke: 'none',
    rx: '4',
    ry: '4',
  });
  rect.classList.add(LABEL_BACKGROUND_CLASS);
  rect.style.cursor = 'pointer';

  container.appendChild(rect);
  container.appendChild(textElement);
  setTimeout(() => {
    textElement.style.opacity = String(opacity);
  });

  // @ts-expect-error metaData is not defined on the GraphItem class
  const hasFilters = !isNodeLabel && node?.metaData?.hasFilters;

  if (hasFilters) {
    container.appendChild(createFilterIconReactElement());
  }
};

const FilterIcon = () => (
  <div
    style={{
      position: 'absolute',
      height: 24,
      width: 24,
      borderRadius: '50%',
      backgroundColor: colors.brand15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Icon
      iconName={IconName.FILTER_LIST}
      color={colors.white}
      iconSize={IconSize.MEDIUM}
    />
  </div>
);

const createFilterIconReactElement = () => {
  const outerContainer = createSvgElement('foreignObject', {
    x: '-5',
    y: '-10',
    width: '24px',
    height: '24px',
  });

  ensureRoot(outerContainer).render(createElement(FilterIcon));

  return outerContainer;
};

export default class ArdoqLabelStyle extends LabelStyleBase {
  static Instance = new ArdoqLabelStyle();

  private constructor() {
    super();
  }

  override createVisual(context: IRenderContext, label: ILabel) {
    const g = createSvgElement('g');

    const hash = getHash(label);
    render(g, label, hash);

    const transform = LabelStyleBase.createLayoutTransform(label.layout, true);
    transform.applyTo(g);

    return new SvgVisual(g);
  }

  override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    label: ILabel
  ) {
    const container = (oldVisual as SvgVisual).svgElement as SVGGElement;
    const oldHash = container.getAttribute(DATA_RENDER_HASH);
    const newHash = getHash(label);
    if (oldHash !== newHash) {
      render(container, label, newHash);
    }
    const transform = LabelStyleBase.createLayoutTransform(label.layout, true);
    transform.applyTo(container);
    return oldVisual;
  }

  override getPreferredSize(label: ILabel) {
    return getLabelPreferredSize(label.text, getLabelFont(label));
  }
}

const createTextElement = (text: string, font: Font) => {
  const textElement = createSvgElement('text', {
    'text-anchor': 'middle',
  });
  font.applyTo(textElement);

  const { width, height } = measureLabelElement(text, font);

  const isSingleLine = height < 2 * font.fontSize;
  if (isSingleLine) {
    // optimization: if the text is not multiline, don't use TextRenderSupport.addText. this call is expensive, and it generates <tspan> elements which leads to a larger DOM.
    textElement.textContent = text;
    return { textElement, width, height, isSingleLine };
  }

  addText({
    targetElement: textElement,
    text,
    font,
    ...LABEL_TEXT_PARAMS,
  });

  return { textElement, width, height, isSingleLine };
};

const [nodeLabelTextElementCache, edgeLabelTextElementCache] = [
  NODE_LABEL_FONT,
  EDGE_LABEL_FONT,
].map(font =>
  createFifoCache(GRAPH_ITEM_LABEL_CACHE_SIZE, (text: string) =>
    createTextElement(text, font)
  )
);
