import { actionCreator } from '@ardoq/rxbeach';
import { APISlideAttributes, ArdoqId } from '@ardoq/api-types';
import { ComponentBackboneModel, Reference } from 'aqTypes';
import { SlideNavigatorState } from './types';
import { getActionNamespacer } from 'streams/utils/streamUtils';

const name = getActionNamespacer('slideNavigator');

export const selectSlide = actionCreator<ArdoqId | null>(name('Select slide'));

/** Dispatched when the description bar in presentations is expanded or collapsed */
export const presentationDescriptionToggled = actionCreator(
  name('PRESENTATION_DESCRIPTION_TOGGLED')
);

export const nextSlide = actionCreator(name('Next slide'));

export const previousSlide = actionCreator(name('Previous slide'));

export const backToSlide = actionCreator(name('Back to slide'));

export const toggleDescription = actionCreator(name('Toggle description'));

export const setIsDescriptionExpanded = actionCreator<boolean>(
  name('Set is description expanded')
);

export const setSlideDescription = actionCreator<ArdoqId>(
  name('Show slide description')
);

type NodePayload = { node: ComponentBackboneModel | Reference };
export const setNodeDescription = actionCreator<NodePayload>(
  name('Show node description')
);

export const selectNodeInPresentationMode = actionCreator<NodePayload>(
  name('Select node')
);

export const setSlideNavigatorState = actionCreator<
  Partial<SlideNavigatorState>
>(name('Set state'));

export const reloadCurrentSlide = actionCreator(name('Reload slide'));

export const loadSlides = actionCreator<APISlideAttributes[]>(
  '[slides] LOAD_SLIDES'
);
