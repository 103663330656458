import broadcast$ from './broadcast$';
import { Observable } from 'rxjs';
import { CurrentBroadcast } from './types';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';
import { isEqual } from 'lodash';

const currentBroadcast$: Observable<CurrentBroadcast> = broadcast$.pipe(
  map(({ currentBroadcast }) => currentBroadcast),
  excludeNullAndUndefined(),
  distinctUntilChanged(isEqual)
);

export default currentBroadcast$;
