import { connect } from '@ardoq/rxbeach';
import { ViewpointsPane, ViewpointsRoute } from './navigation/types';
import viewpointsNavigation$ from './navigation/viewpointsNavigation$';
import ViewpointForm from './ViewpointForm';
import ViewpointOverview from './ViewpointOverview';
import ViewpointDefaults from './ViewpointDefaults';

const Viewpoints = ({ viewpointsPane, viewpointId }: ViewpointsRoute) => {
  switch (viewpointsPane) {
    case ViewpointsPane.FORM:
      return <ViewpointForm viewpointId={viewpointId} />;
    case ViewpointsPane.DEFAULTS:
      return <ViewpointDefaults />;
    default:
      return <ViewpointOverview />;
  }
};

export default connect(Viewpoints, viewpointsNavigation$);
