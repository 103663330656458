import { useState } from 'react';
import SortableQuestions from './SortableQuestions';
import { UnpersistedSurveyQuestion } from '@ardoq/api-types';
import { QuestionBuilderProps } from './types';
import { getQuestionId } from './utils';
import { AllPossibleQuestions } from 'surveyAdmin/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { setSurveyQuestionFieldHasBeenInteractedWith } from 'surveyAdmin/SurveyEditor/streams/actions';

const QuestionBuilder = ({
  modelId,
  typeId,
  questions,
  parentQuestionIsReadonly,
  validators,
  surveyValidation,
  addQuestionLabel,
  addQuestionDescription,
  workspaceId,
  isComponent,
  surveyId,
  permissionsContext,
  subQuestionValidations,
  allowComponentCreation,
  surveyIsPermissionDivisionsAware,
  hasSurveyResponseApprovalsFeature,
  updateQuestions,
}: QuestionBuilderProps) => {
  const [expandedQuestion, setExpandedQuestion] = useState<string | undefined>(
    undefined
  );

  const addQuestion = (question: UnpersistedSurveyQuestion) => {
    const questionUpdatedWithReadonly = {
      ...question,
      readonly: parentQuestionIsReadonly ?? false,
    };
    expandQuestion(questionUpdatedWithReadonly);
    updateQuestions([...(questions ?? []), questionUpdatedWithReadonly]);
  };

  const expandQuestion = (question: AllPossibleQuestions | undefined) => {
    if (expandedQuestion) {
      dispatchAction(
        setSurveyQuestionFieldHasBeenInteractedWith({
          questionKey: expandedQuestion,
          fieldKey: 'questionViewHasBeenClosed',
        })
      );
    }
    if (!question) {
      setExpandedQuestion(undefined);
      return;
    }
    const questionId = getQuestionId(question);
    const updatedExpandedQuestion =
      expandedQuestion === questionId ? undefined : questionId;
    setExpandedQuestion(updatedExpandedQuestion);
  };

  return (
    <SortableQuestions
      questions={questions}
      workspaceId={workspaceId}
      surveyValidation={surveyValidation}
      subQuestionValidations={subQuestionValidations}
      QuestionBuilder={QuestionBuilder}
      expandedQuestion={expandedQuestion}
      expandQuestion={question => expandQuestion(question)}
      addQuestion={question => addQuestion(question)}
      addQuestionLabel={addQuestionLabel}
      addQuestionDescription={addQuestionDescription}
      parentQuestionIsReadonly={parentQuestionIsReadonly}
      isComponent={isComponent}
      updateQuestions={updateQuestions}
      modelId={modelId}
      typeId={typeId}
      validators={validators}
      surveyId={surveyId}
      permissionsContext={permissionsContext}
      allowComponentCreation={allowComponentCreation}
      surveyIsPermissionDivisionsAware={surveyIsPermissionDivisionsAware}
      hasSurveyResponseApprovalsFeature={hasSurveyResponseApprovalsFeature}
    />
  );
};

export default QuestionBuilder;
