import { renameRoutine } from 'streams/assets/routines';
import { traversalNamespace } from './actions';
import {
  action$,
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { uniq, uniqBy } from 'lodash';
import { prototypeInterface } from 'modelInterface/prototype/prototypeInterface';
import { Features, hasFeature } from '@ardoq/features';
import { openViewpointBuilder } from 'viewpointBuilder/openViewpointBuilder/openViewpointBuilder';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import graphModel$ from 'modelInterface/graphModel$';
import currentUser$ from 'streams/currentUser/currentUser$';
import { prompt } from '@ardoq/modal';
import {
  isCreatedItemsLoadedState,
  isSearchLoadedState,
  isManualComponentSelection,
  LoadedState,
  isAnyTraversalLoadedState,
  FEParentChildReferenceAttributes,
  DirectedTriple,
} from '@ardoq/api-types';
import { traversalApi } from '@ardoq/api';
import { referenceAttributesOperations } from '@ardoq/core';
import traversals$ from 'streams/traversals$';
import { traversalStateOperations } from 'streams/traversals/traversalsStateOperations';
import {
  navigateToViewpointsOverview,
  editViewpoint,
  rebuildTraversalAndSearchState,
  renameViewpoint,
  replaceScopeComponentIds,
} from 'streams/traversals/actions';
import openApplySavedViewpointForContextComponentsModal from 'traversals/selectTraversalForContextComponentsModal/openApplySavedViewpointForContextComponentsModal';
import { openApplySavedViewpointModal } from 'traversals/selectTraversalForContextComponentsModal/actions';
import {
  stagedLoadedDataAndState$,
  loadedDataProcessed,
} from 'traversals/stagedLoadedDataAndState$';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { traversalOperations } from 'traversals/traversalOperations';
import { renameAsset } from 'streams/assets/actions';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { handleFetchAll, handleDelete } from 'streams/crud/routines';
import { scopeDataLoaded } from '../enhancedScopeData/actions';
import { scopeDataOperations } from '@ardoq/scope-data';
import { getGraphInterfaceWithModelInterfaces } from '@ardoq/graph';
import { processCollapsingRulesOnLoadedData } from 'traversals/pathAndGraphUtils';
import { NewReference } from 'traversals/pathAndGraphTypes';
import Components from 'collections/components';
import { virtualModel } from 'models/virtualReference';
import { metaModelAsScopeData$ } from 'viewpointBuilder/metaModel/loadMetaModelAsScopeData$';
import { LoadMetaModelAsScopedDataState } from 'viewpointBuilder/metaModel/loadMetaModelTypes';
import { createComponentsAndReferencesStateRoutine } from 'loadedState/createComponentsAndReferencesStateRoutine';

const handleNavigateToViewpointsOverview = action$.pipe(
  ofType(navigateToViewpointsOverview),
  tap(() => {
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.TRAVERSALS })
    );
  })
);

const handleEditViewpoint = action$.pipe(
  ofType(editViewpoint),
  extractPayload(),
  withLatestFrom(traversals$),
  tap(([traversalId, state]) => {
    const traversal = traversalStateOperations.getById(state, traversalId);
    if (!traversal) {
      // log error?
      // different action to create?
      return;
    }

    if (hasFeature(Features.SUPPORT_LARGE_DATASETS)) {
      const {
        name,
        viewName,
        startType,
        paths: initialPaths = [],
        filters = {},
        groupBys = [],
        _id,
        conditionalFormatting = [],
        description,
        pathMatching,
      } = traversal;

      openViewpointBuilder({
        activeTab: 'META_INFO_TAB',
        context: 'editViewpoint',
        initialConfiguration: {
          componentSelection: null,
          name,
          viewName,
          description,
          startType,
          initialPaths,
          filters,
          groupBys,
          conditionalFormatting,
          viewpointId: _id,
          viewpointVersion: traversal._version,
          pathMatching,
        },
      });
    }
  })
);

const handleOpenApplySavedViewpointModal = action$.pipe(
  ofType(openApplySavedViewpointModal),
  extractPayload(),
  withLatestFrom(currentUser$, traversals$, currentUserPermissionContext$),
  tap(
    ([
      { selectedComponents, componentType },
      currentUser,
      traversals,
      permissionContext,
    ]) => {
      const filteredTraversals = traversalStateOperations
        .getAsList(traversals)
        .filter(traversal => traversal.startType === componentType)
        .map(traversal =>
          traversalOperations.toTraversalRepresentationInOverview(
            traversal,
            [], // favourite column is not displayed in search,
            permissionContext
          )
        );
      return openApplySavedViewpointForContextComponentsModal({
        components: selectedComponents,
        traversals: filteredTraversals,
        currentUserId: currentUser._id,
      });
    }
  )
);

const extractScopeComponentIds = (loadedState: LoadedState) => {
  if (
    isSearchLoadedState(loadedState) &&
    isManualComponentSelection(loadedState.data.componentSelection)
  ) {
    return loadedState.data.componentSelection.startSet;
  }
  if (isCreatedItemsLoadedState(loadedState)) {
    return loadedState.data.componentIds;
  }
  return loadedState.componentIdsAndReferences?.componentIds ?? [];
};

/**
 * This function primarily performs three operations:
 *   1. It updates the collections with the staged loaded scope data and opens
 *      the according workspaces.
 *   2. It removes components and references that are no longer in scope and
 *      closes 'empty' workspaces.
 *   3. It updates the loadedGraph$ stream after the loadedState$ stream
 *      has been updated.
 *
 * In viewpoint mode, state management is handled as follows:
 *   - The backbone collections store the loaded components and references.
 *   - The loadedState$ stream acts as the view model for the configuration
 *     panel. It maintains the loaded states for the search and traversal
 *     configurations in their respective blocks.
 *   - The loadedGraph$ stream serves as the single source of truth for
 *     the views, indicating which components and references should be displayed.
 *
 * When a block is edited, it's necessary to update the collections. This
 * involves removing components and references that are no longer in scope and
 * closing workspaces that are essentially empty. This operation is performed
 * after the loadedState$ stream is updated to ensure that the components
 * and references still in scope are accurately identified.
 */
const handleRebuildTraversalAndSearchState = action$.pipe(
  ofType(rebuildTraversalAndSearchState),
  extractPayload(),
  withLatestFrom(
    isViewpointMode$,
    graphModel$,
    stagedLoadedDataAndState$,
    metaModelAsScopeData$
  ),
  tap(
    ([
      { loadedStates },
      { isViewpointMode },
      graphModel,
      { scopeDataList, trackingLocation, activeWorkspaceId, workspaceOrder },
      metaModelAsScopeData,
    ]) => {
      if (!isViewpointMode) {
        return;
      }

      dispatchAction(loadedDataProcessed());
      registerVirtualReferences(loadedStates, metaModelAsScopeData);

      // Loaded states can be registered before they are actually loaded.
      const loadedStatesLoaded = loadedStates.filter(loadedStateIsLoaded);
      const mergedScopeData = scopeDataOperations.merge(...scopeDataList);

      // NOTE: Enhanced scope data can be moved into map/reduce once w've
      // transitioned away from backbone for all viewpoint mode views.
      // https://ardoqcom.atlassian.net/browse/ARD-25509
      const enhancedScopeData = scopeDataOperations.enhance(
        hasFeature(Features.ENHANCED_SCOPE_DATA_STREAM)
          ? mergedScopeData
          : scopeDataOperations.getEmpty()
      );

      const referenceIdsToKeep = uniq(
        loadedStatesLoaded.flatMap(extractReferenceIds)
      );
      // include reference source and target ids because we can end up
      // in a situation where components required by a reference created
      // in the view are not included in the scope and would cause errors
      // and a blank view
      const componentIdsToKeep = uniq([
        ...loadedStatesLoaded.flatMap(extractScopeComponentIds),
        ...loadedStatesLoaded.flatMap(extractReferenceSourceAndTarget),
      ]);
      const isGraphReset = graphModel.referenceMap.size === 0;

      if (hasFeature(Features.ENHANCED_SCOPE_DATA_STREAM)) {
        dispatchAction(scopeDataLoaded(mergedScopeData));
      }

      prototypeInterface.updateCollectionsAndContextAndDispatchNotifyActions({
        components: mergedScopeData.components,
        references: mergedScopeData.references,
        tags: mergedScopeData.tags,
        trackingLocation,
        activeWorkspaceId,
        workspaceOrder,
        componentIdsToKeep,
        referenceIdsToKeep,
        isGraphReset,
      });

      const states = loadedStatesLoaded.filter(state => !state.isHidden);
      const scopeComponentIds = uniq(states.flatMap(extractScopeComponentIds));
      const scopeReferenceIds = uniq(states.flatMap(extractReferenceIds));

      const parentChildReferences = uniqBy(
        states.flatMap(
          state => state.componentIdsAndReferences?.parentChildReferences ?? []
        ),
        '_id'
      ).map(referenceAttributesOperations.toFEParentChildFormat);

      const collapsedResult = applyCollapsingRules({
        loadedStates: states,
        scopeReferenceIds,
        scopeComponentIds,
        parentChildReferences,
      });

      prototypeInterface.updateVirtualReferences(collapsedResult.newReferences);

      dispatchAction(
        replaceScopeComponentIds({
          scopeComponentIds: collapsedResult.scopeComponentIds,
          scopeReferenceIds: collapsedResult.scopeReferenceIds,
          parentChildReferences: collapsedResult.parentChildReferences,
          hierarchyDefinition:
            loadedStateOperations.toPathsWithStartSetResultsAndCollapsedPaths(
              states
            ),
          componentParentMap: getComponentParentMap(),
          scopeData: enhancedScopeData,
        })
      );
    }
  )
);

const registerVirtualReferences = (
  loadedStates: LoadedState[],
  metaModelAsScopeData: LoadMetaModelAsScopedDataState
) => {
  const collapseRules = loadedStates
    .filter(loadedState => isAnyTraversalLoadedState(loadedState))
    .flatMap(({ data: { pathCollapsingRules } }) => pathCollapsingRules);
  collapseRules.forEach(({ displayText, referenceStyle, path }) => {
    const workspaceIds = getWorkspaceIdsForVirtualReferences(
      path,
      metaModelAsScopeData
    );
    virtualModel.registerVirtualTypeForWorkspaces(
      referenceStyle,
      displayText,
      workspaceIds
    );
  });
};

const getWorkspaceIdsForVirtualReferences = (
  path: DirectedTriple[] | null,
  metaModelAsScopeData: LoadMetaModelAsScopedDataState
): string[] => {
  if (!(path && path[0]) || metaModelAsScopeData.status !== 'DATA_LOADED')
    return [];
  const { direction, sourceType, targetType } = path[0];
  // The source of the virtual reference is the start type of the first triple
  // of the path to be collapsed. That means the root workspace of the virtual
  // reference is the workspace of the start type of the path.
  const startTypeOfCollapsedReference =
    direction === 'outgoing' ? sourceType : targetType;
  const workspaceIds = metaModelAsScopeData.componentNameAndWorkspacesIndex.get(
    startTypeOfCollapsedReference
  );
  return workspaceIds ?? [];
};

/**
 * @deprecated
 * Temporary solution, can be removed as soon as we have scope data
 * as part of the LoadedGraphWithViewpointMode
 */
const getComponentParentMap = () =>
  new Map(
    Components.collection.map(({ attributes: { _id, parent } }) => [
      _id,
      parent,
    ])
  );

type ApplyCollapsingRules = {
  loadedStates: LoadedState[];
  scopeReferenceIds: string[];
  scopeComponentIds: string[];
  parentChildReferences: FEParentChildReferenceAttributes[];
};

const applyCollapsingRules = ({
  loadedStates,
  scopeReferenceIds,
  parentChildReferences,
  scopeComponentIds,
}: ApplyCollapsingRules): {
  scopeReferenceIds: string[];
  scopeComponentIds: string[];
  parentChildReferences: FEParentChildReferenceAttributes[];
  newReferences: NewReference[];
} => {
  const collapsePathsArgs =
    getLoadedStatesWithActiveCollapsingRule(loadedStates);
  const startSetResultTraversalsAndSearches = new Set(
    loadedStates.flatMap(
      ({ componentIdsAndReferences }) =>
        componentIdsAndReferences?.startSetResult ?? []
    )
  );
  const workspacesIds = prototypeInterface.getLoadedWorkspaceIds();
  const graphInterface = getGraphInterfaceWithModelInterfaces();

  return processCollapsingRulesOnLoadedData({
    graphInterface,
    scopeComponentIds,
    scopeReferenceIds,
    parentChildReferences,
    collapsePathsArgs,
    workspacesIds,
    startSetResultTraversalsAndSearches,
  });
};

const getLoadedStatesWithActiveCollapsingRule = (
  loadedState: LoadedState[]
) => {
  return loadedState
    .filter(state => isAnyTraversalLoadedState(state))
    .map(state => {
      const {
        data: { paths, pathCollapsingRules = [] },
        componentIdsAndReferences: { startSetResult } = {
          startSetResult: [],
        },
      } = state;
      return {
        startSetResult,
        paths,
        pathCollapsingRules: pathCollapsingRules.filter(
          ({ isActive }) => isActive
        ),
      };
    });
};

const loadedStateIsLoaded = (loadedState: LoadedState) =>
  Boolean(loadedState.componentIdsAndReferences);

const extractReferenceIds = (loadedState: LoadedState) => {
  if (loadedState.componentIdsAndReferences) {
    return loadedState.componentIdsAndReferences.references.map(
      ({ _id }) => _id
    );
  }
  if (isCreatedItemsLoadedState(loadedState)) {
    return loadedState.data.referenceIds;
  }
  return [];
};

const extractReferenceSourceAndTarget = (
  loadedState: LoadedState
): string[] => {
  if (loadedState.componentIdsAndReferences) {
    return loadedState.componentIdsAndReferences.references.flatMap(
      ({ source, target }) => [source, target]
    );
  }
  return [];
};

// TODO: This should be rewritten to have the prompt outside the routine, and dispatch renameAsset directly
const handleRenameViewpoint = action$.pipe(
  ofType(renameViewpoint),
  extractPayload(),
  tap(async viewpoint => {
    const newName = await prompt({
      title: 'Rename viewpoint',
      inputLabel: 'Viewpoint name',
      initialInputValue: viewpoint.name,
      confirmButtonTitle: 'Rename',
    });
    if (!newName) return;
    dispatchAction(
      renameAsset({
        _id: viewpoint._id,
        _version: viewpoint._version,
        name: newName,
      }),
      traversalNamespace
    );
  })
);

export const viewpointRoutines = collectRoutines(
  handleFetchAll(traversalNamespace, traversalApi.fetchAll),
  handleDelete(traversalNamespace, traversalApi.delete),
  renameRoutine(traversalNamespace, traversalApi.rename),
  handleNavigateToViewpointsOverview,
  handleEditViewpoint,
  handleOpenApplySavedViewpointModal,
  handleRebuildTraversalAndSearchState,
  handleRenameViewpoint,
  createComponentsAndReferencesStateRoutine
);
