import { Observable } from 'rxjs';
import { dispatchAction } from '@ardoq/rxbeach';
import { PredefinedAudienceDialogStreamShape } from './types';
import { fetchPredefinedAudiencePreview } from './actions';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

export const handlePredefinedAudienceDialogChangeRoutine = (
  predefinedAudienceDialog$: Observable<PredefinedAudienceDialogStreamShape>
) =>
  predefinedAudienceDialog$.pipe(
    map(({ selectedQueryOptions }) => selectedQueryOptions),
    distinctUntilChanged(),
    tap(() => dispatchAction(fetchPredefinedAudiencePreview()))
  );
