import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { SubdivisionEditorSteps } from 'subdivisionEditor/navigation/types';
import { StepConfig } from '../types';
import { editOnlyStepIsDisabled } from '../utils';
import { SelectComponentsPage } from './SelectComponentsPage';
import { Features } from '@ardoq/features';
import { selectComponentsCommands } from './selectComponentsCommands';

export const selectComponentsStepConfig: StepConfig = {
  step: SubdivisionEditorSteps.COMPONENTS_SELECTION,
  stepperHeading: 'Select components',
  title: 'Select components',
  subtitle: SUBDIVISIONS_STRINGS.STEPS.COMPONENTS_SELECTION.SUBTITLE,
  isConfigured: selectComponentsCommands.isConfigured,
  component: SelectComponentsPage,
  isDisabled: editOnlyStepIsDisabled,
  behindFeatureFlag: Features.PERMISSION_ZONES_INTERNAL,
  stepValidation: selectComponentsCommands.stepValidation,
  onSave: selectComponentsCommands.onSave,
};
