import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  sendViewInference,
  notifyViewInferenceError,
  sendSuggestTraversal,
  notifySuggestTraversalError,
  openViewInferenceWindow,
  applySuggestion,
} from './actions';
import { delay, filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { handleError, traversalApi } from '@ardoq/api';
import { viewInferenceOperations } from './viewInferenceOperations';
import viewInference$ from './viewInference$';
import { v4 as uuidv4 } from 'uuid';
import { openTraversalInVisualization } from 'viewpointBuilder/openTraversalInVisualization';
import { loadedState$ } from '../../loadedState/loadedState$';
import currentViewId$ from '../../streams/currentViewId$';
import { isViewpointMode$ } from '../loadedGraph$';
import {
  saveSuggestTraversalStartTime,
  trackOpenedInferTraversalWindow,
  trackSuggestTraversalTimeout,
  trackAppliedTraversalSuggestion,
  trackInferTraversalTimeout,
  saveInferTraversalStartTimeAndInput,
} from './tracking';
import { getSupportedTraversalViewIdOrDefault } from '../getViewpointModeSupportedViews';
import { normalizeApiTraversalParams } from 'viewpointBuilder/getComponentSelection';
import { getArdoqErrorMessage } from '@ardoq/common-helpers';

const SEND_VIEW_INFERENCE_TIMEOUT = 30_000; // Timeout in ms
const SEND_SUGGEST_TRAVERSAL_TIMEOUT = 30_000; // Timeout in ms

/**
 * This routine works together with stateRoutines that listens for websocket events, in order to have the following flow:
 *
 * 1) Send http request
 *
 * Then one of these scenarios will happen
 * 2a) Receive error in http response
 * 2b) Timeout
 * 2c) Receive success websocket event
 * 2d) Receive error websocket event
 *
 * In this routine, we handle 1), 2a) and 2b)
 */
const handleSendViewInference = routine(
  ofType(sendViewInference),
  extractPayload(),
  tap(saveInferTraversalStartTimeAndInput),
  switchMap(traversalApi.infer),
  handleError(error =>
    dispatchAction(notifyViewInferenceError(getArdoqErrorMessage(error)))
  ),
  delay(SEND_VIEW_INFERENCE_TIMEOUT),
  withLatestFrom(viewInference$),
  filter(([clientRequestId, state]) =>
    viewInferenceOperations.isActiveSendPromptRequest(state, clientRequestId)
  ),
  tap(trackInferTraversalTimeout),
  tap(() =>
    dispatchAction(notifyViewInferenceError('Timed out, please try again.'))
  )
);

/**
 * This routine works together with stateRoutines that listens for websocket events, in order to have the following flow:
 *
 * 1) Send http request
 *
 * Then one of these scenarios will happen
 * 2a) Receive error in http response
 * 2b) Timeout
 * 2c) Receive success websocket event
 * 2d) Receive error websocket event
 *
 * In this routine, we handle 1), 2a) and 2b)
 */
const handleSendSuggestTraversal = routine(
  ofType(sendSuggestTraversal),
  extractPayload(),
  tap(saveSuggestTraversalStartTime),
  withLatestFrom(loadedState$),
  switchMap(([clientRequestIdAndStartSet, loadedStates]) =>
    traversalApi.suggest({
      ...clientRequestIdAndStartSet,
      ...viewInferenceOperations.getReferenceIdsFromLoadedState(loadedStates),
    })
  ),
  handleError(error =>
    dispatchAction(notifySuggestTraversalError(getArdoqErrorMessage(error)))
  ),
  delay(SEND_SUGGEST_TRAVERSAL_TIMEOUT),
  withLatestFrom(viewInference$),
  filter(([clientRequestId, state]) =>
    viewInferenceOperations.isActiveSuggestRequest(state, clientRequestId)
  ),
  tap(trackSuggestTraversalTimeout),
  tap(() =>
    dispatchAction(notifySuggestTraversalError('Timed out, please try again.'))
  )
);

const handleOpenWindow = routine(
  ofType(openViewInferenceWindow),
  extractPayload(),
  tap(trackOpenedInferTraversalWindow),
  tap(payload => {
    if (payload.componentIds.length === 1) {
      dispatchAction(
        sendSuggestTraversal({
          startSet: payload.componentIds,
          clientRequestId: uuidv4(),
        })
      );
    }
  })
);

const handleApplySuggestion = routine(
  ofType(applySuggestion),
  tap(trackAppliedTraversalSuggestion),
  extractPayload(),
  withLatestFrom(currentViewId$, isViewpointMode$),
  tap(([suggestion, currentViewId, { isViewpointMode }]) => {
    const traversalParams = normalizeApiTraversalParams(suggestion.traversal);
    return openTraversalInVisualization({
      type: 'EXECUTE_UNSAVED_VIEWPOINT',
      ...traversalParams,
      viewName: isViewpointMode
        ? currentViewId
        : getSupportedTraversalViewIdOrDefault(undefined),
    });
  })
);

export const viewInferenceRoutines = collectRoutines(
  handleSendViewInference,
  handleSendSuggestTraversal,
  handleOpenWindow,
  handleApplySuggestion
);
