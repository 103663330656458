import { logError } from '@ardoq/logging';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  addCreatedInvitation,
  createInvitations,
  deleteInvitation,
  fetchInvitations,
  removeDeletedInvitation,
  replaceUpdatedInvitation,
  resendInvitation,
  setInvitationFormState,
  setInvitationsState,
  submitInvitationForm,
  updateInvitationRole,
} from './actions';
import invitations$ from './invitations$';
import { trackStreamEvent } from 'tracking/tracking';
import {
  clearInvitationsAlert,
  getInvitationSuccessMessage,
  setInvitationsError,
  showSuccessToast,
} from './utils';
import { STRINGS } from './consts';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import invitationForm$ from 'streams/invitations/invitationForm$';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';
import { handleError, invitationApi } from '@ardoq/api';
import { ArdoqError, getArdoqErrorMessage } from '@ardoq/common-helpers';

const logAndSetInvitationsError = (error: ArdoqError) => {
  logError(error);
  setInvitationsError(getArdoqErrorMessage(error));
};

const handleFetchInvitations = routine(
  ofType(fetchInvitations),
  switchMap(() => invitationApi.fetchAll()),
  handleError(logAndSetInvitationsError),
  tap(invitations => dispatchAction(setInvitationsState({ invitations })))
);

const handleCreateInvitation = routine(
  ofType(createInvitations),
  extractPayload(),
  trackStreamEvent(({ emails, role, privileges }) => ({
    eventName: APPSEC_EVENTS.INVITED_USER_TO_ORG,
    metadata: {
      role,
      emailsCount: emails.length,
      privileges,
    },
  })),
  switchMap(invitations => invitationApi.create(invitations)),
  handleError(logAndSetInvitationsError),
  tap(invitations => {
    dispatchAction(addCreatedInvitation(invitations));
    dispatchAction(setInvitationFormState({ emails: [] }));
    showSuccessToast(getInvitationSuccessMessage(invitations.length));
  })
);

const handleUpdateInvitationRole = routine(
  ofType(updateInvitationRole),
  extractPayload(),
  trackStreamEvent(({ newRole }) => ({
    eventName: 'Updated org invitation role',
    metadata: { newRole },
  })),
  withLatestFrom(invitations$),
  map(([{ invitationId, newRole }, { invitations }]) => {
    const invitation = invitations.find(({ _id }) => _id === invitationId);
    if (invitation) {
      return { ...invitation, role: newRole };
    }
  }),
  tap(updatedInvitation => {
    if (!updatedInvitation) {
      const msg = 'No invitation with specified ID found in invitations$';
      logError(new Error(msg), 'Failed to update invitation role');
      setInvitationsError('Failed to update invitation role');
    }
  }),
  excludeNullAndUndefined(),
  switchMap(updatedInvitation => invitationApi.update(updatedInvitation)),
  handleError(logAndSetInvitationsError),
  tap(updatedInvitation =>
    dispatchAction(replaceUpdatedInvitation(updatedInvitation))
  )
);

const handleDeleteInvitation = routine(
  ofType(deleteInvitation),
  trackStreamEvent(() => ({ eventName: 'Canceled org invitation' })),
  extractPayload(),
  switchMap(({ invitationId }) => invitationApi.delete(invitationId)),
  handleError(logAndSetInvitationsError),
  tap(invitationId => {
    dispatchAction(removeDeletedInvitation({ invitationId }));
    showSuccessToast(STRINGS.USER_REMOVED);
  })
);

const handleResendInvitation = routine(
  ofType(resendInvitation),
  trackStreamEvent(() => ({ eventName: 'Resent org invitation' })),
  extractPayload(),
  switchMap(({ invitationId }) => invitationApi.resend(invitationId)),
  handleError(logAndSetInvitationsError),
  tap(() => {
    showSuccessToast(STRINGS.INVITATION_RESENT);
  })
);

const handleSubmitForm = routine(
  ofType(submitInvitationForm),
  withLatestFrom(invitations$, invitationForm$),
  tap(
    async ([
      ,
      { invitations },
      {
        formState: { emails, isValid, privileges, role },
      },
    ]) => {
      if (!isValid) return;
      const submittedEmails = emails.filter(
        email => email !== '' && email !== ' '
      );
      const alreadyInvitedEmail = submittedEmails.find(email =>
        invitations.some(
          invitation => email.toLowerCase() === invitation.email.toLowerCase()
        )
      );

      if (alreadyInvitedEmail) {
        setInvitationsError(`${alreadyInvitedEmail} has already been invited.`);
        return;
      }
      clearInvitationsAlert();
      const response: any = await dispatchActionAndWaitForResponse(
        createInvitations({
          emails: [...submittedEmails],
          role,
          privileges,
        }),
        addCreatedInvitation,
        setInvitationsState
      );
      if (response.type === addCreatedInvitation.type) {
        dispatchAction(setInvitationFormState({ emails: [] }));
      }
      if (
        response.type === setInvitationsState.type &&
        response.payload.alert.type === 'success'
      ) {
        dispatchAction(setInvitationFormState({ emails: [] }));
        dispatchAction(fetchInvitations());
      }
    }
  )
);

export default collectRoutines(
  handleFetchInvitations,
  handleCreateInvitation,
  handleUpdateInvitationRole,
  handleDeleteInvitation,
  handleResendInvitation,
  handleSubmitForm
);
