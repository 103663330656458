import { APIComponentType } from '@ardoq/api-types';
import { last } from 'lodash';

type Point = { x: number; y: number };
type Line = [Point, Point];

export const getTreeBranchesStartAndEndPoints = (
  componentType: APIComponentType,
  parentPosition: Point = { x: 0, y: 0 },
  offsetY = 0
): Point[] => {
  return [
    {
      x: parentPosition.x,
      y: parentPosition.y + offsetY,
    },
    ...Object.values(componentType.children).reduce(
      (acc: Point[], el: APIComponentType) => {
        const lastSibling = last(acc);
        const x = parentPosition.x + 1;
        const y = lastSibling
          ? lastSibling.y + 1
          : parentPosition.y + 1 + offsetY;
        return [...acc, ...getTreeBranchesStartAndEndPoints(el, { x, y })];
      },
      []
    ),
  ];
};

export const getLinesFromPoints = (points: Point[]): [Point, Point][] => {
  const parents: Point[] = [];
  return points.reduce((lines: [Point, Point][], currentPoint, index) => {
    const previousPoint = points[index - 1];
    if (!previousPoint) return lines;
    if (previousPoint.x === currentPoint.x - 1) {
      parents[previousPoint.x] = previousPoint;
    }
    lines.push([parents[currentPoint.x - 1], currentPoint]);
    return lines;
  }, []);
};

export const getLinesFromComponentTypes = (
  componentTypes: APIComponentType[]
): Line[] =>
  componentTypes.reduce((lines: [Point, Point][], componentType, i) => {
    const [, lastLineEndPoint] = last(lines) || [];
    const offsetY = lastLineEndPoint ? lastLineEndPoint.y + 1 : i;
    const currentComponentLines = getLinesFromPoints(
      getTreeBranchesStartAndEndPoints(componentType, undefined, offsetY)
    );
    return [...lines, ...currentComponentLines];
  }, []);
