import { EmptyState, EmptyStateSuccessIllustration } from '@ardoq/status-ui';
import styled from 'styled-components';
import { FlexBox } from '@ardoq/layout';
import { PropsWithChildren } from 'react';

type ViewpointBuilderSidebarEmptyProps = PropsWithChildren<{
  title: string;
  description: string;
}>;

const ViewpointBuilderSidebarEmptyState = ({
  title,
  description,
  children,
}: ViewpointBuilderSidebarEmptyProps) => (
  <Container padding="large" align="center" height="full">
    <EmptyState
      layout="vertical"
      media={<EmptyStateSuccessIllustration />}
      title={title}
      description={description}
    >
      {children}
    </EmptyState>
  </Container>
);

const Container = styled(FlexBox)`
  /* Limit width to prevent the description from "un-wrapping" to fewer lines.
     This keeps it nice, tidy and centered */
  max-width: 334px;
`;

export default ViewpointBuilderSidebarEmptyState;
