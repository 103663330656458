import {
  AttributeQuestionProperties,
  FieldQuestionProperties,
  BaseReferenceQuestionProperties,
  HiddenQuestionProperties,
  SurveyQuestionType,
  TagQuestionProperties,
  UnpersistedAttributeQuestion,
  UnpersistedFieldQuestion,
  UnpersistedHiddenQuestion,
  UnpersistedReferenceQuestion,
  UnpersistedTagQuestion,
  UnpersistedTextQuestion,
} from '@ardoq/api-types';
import { currentTimestamp } from '@ardoq/date-time';

export const createHiddenQuestion = (
  type: SurveyQuestionType.HIDDEN_FIELD,
  properties: HiddenQuestionProperties
): UnpersistedHiddenQuestion => ({
  key: `${currentTimestamp() - Math.random()}`,
  type,
  description: '',
  label: '',
  properties,
  validators: [],
});

export const createTextQuestion = (
  type: SurveyQuestionType.TEXT,
  label: string
): UnpersistedTextQuestion => ({
  key: `${currentTimestamp() - Math.random()}`,
  type,
  label,
  description: '',
  properties: {},
  validators: [],
  readonly: false,
});

export const createAttributeQuestion = (
  properties: AttributeQuestionProperties,
  label: string,
  isReadonly?: boolean
): UnpersistedAttributeQuestion => ({
  key: `${currentTimestamp() - Math.random()}`,
  type: SurveyQuestionType.ATTRIBUTE,
  label,
  description: '',
  properties,
  validators: [],
  readonly: isReadonly ?? false,
});

export const createFieldQuestion = (
  properties: FieldQuestionProperties,
  label: string,
  isReadonly?: boolean
): UnpersistedFieldQuestion => ({
  key: `${currentTimestamp() - Math.random()}`,
  type: SurveyQuestionType.FIELD,
  label,
  description: '',
  properties,
  validators: [],
  readonly: isReadonly ?? false,
});

export const createReferenceQuestion = (
  properties: BaseReferenceQuestionProperties,
  label: string,
  isReadonly?: boolean
): UnpersistedReferenceQuestion => ({
  key: `${currentTimestamp() - Math.random()}`,
  type: SurveyQuestionType.REFERENCE,
  label,
  description: '',
  properties: {
    questions: [],
    outgoing: true,
    ...properties,
  },
  validators: [],
  readonly: isReadonly ?? false,
  aiSuggestionsEnabled: false,
});

export const createTagQuestion = (
  properties: TagQuestionProperties,
  label: string,
  isReadonly?: boolean
): UnpersistedTagQuestion => ({
  key: `${currentTimestamp() - Math.random()}`,
  type: SurveyQuestionType.TAG,
  label,
  description: '',
  properties,
  validators: [],
  readonly: isReadonly ?? false,
});
