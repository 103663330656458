import { Route } from 'router/StreamRouter';
import { AppRouterState, DashboardRoute } from '../../router/appRouterTypes';
import { DashboardModule } from '../types';
import { AppModules } from '../../appContainer/types';
import { navigateToDashboardModule } from '../../router/navigationActions';
import { dispatchAction } from '@ardoq/rxbeach';
import dashboardNavigation$ from './dashboardNavigation$';
import { map } from 'rxjs/operators';

const dashboardBuilderRegex = /^\/dashboard\/builder(?:\/([a-f0-9]+))?\/?$/;
const dashboardReaderRegex = /^\/dashboard\/reader\/([a-f0-9]+)\/?$/;
const dashboardOverviewRegex = /^\/dashboard\/$/;
const dashboardColorThemeSettingsRegex =
  /^\/dashboard\/color-theme-settings\/?$/;
const usageMetricsRegex = /^\/dashboard\/usage-metrics\/?$/;

export const dashboardModuleRoute = new Route<AppRouterState, DashboardRoute>({
  doesLocationMatch: ({ path }) =>
    dashboardReaderRegex.test(path) ||
    dashboardBuilderRegex.test(path) ||
    dashboardOverviewRegex.test(path) ||
    dashboardColorThemeSettingsRegex.test(path) ||
    usageMetricsRegex.test(path),
  locationToRouterState: ({ path }) => {
    const selectedDashboardId =
      path.match(dashboardReaderRegex)?.[1] ||
      path.match(dashboardBuilderRegex)?.[1];

    const module = dashboardReaderRegex.test(path)
      ? DashboardModule.READER
      : dashboardOverviewRegex.test(path)
        ? DashboardModule.OVERVIEW
        : dashboardBuilderRegex.test(path)
          ? DashboardModule.BUILDER
          : usageMetricsRegex.test(path)
            ? DashboardModule.USAGE_METRICS
            : DashboardModule.COLOR_THEME_SETTINGS;

    return {
      appModule: AppModules.DASHBOARDS,
      dashboardModule: module,
      selectedDashboardId,
      branchId: null,
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.DASHBOARDS,
  routerStateToLocation: ({ selectedDashboardId, dashboardModule }) => {
    if (dashboardModule === DashboardModule.BUILDER) {
      return {
        path: selectedDashboardId
          ? `/dashboard/builder/${selectedDashboardId}`
          : `/dashboard/builder`,
        title: 'Dashboard Builder',
      };
    } else if (dashboardModule === DashboardModule.OVERVIEW) {
      return {
        path: '/dashboard/',
        title: 'Dashboard overview',
      };
    } else if (dashboardModule === DashboardModule.READER) {
      return {
        path: `/dashboard/reader/${selectedDashboardId!}`,
        title: 'Dashboard Reader',
      };
    } else if (dashboardModule === DashboardModule.USAGE_METRICS) {
      return {
        path: '/dashboard/usage-metrics',
        title: 'Usage Metrics',
      };
    }
    return {
      path: `/dashboard/color-theme-settings`,
      title: 'Dashboard Color Theme Settings',
    };
  },
  setApplicationStateFromRoute: ({ selectedDashboardId, dashboardModule }) => {
    dispatchAction(
      navigateToDashboardModule({
        selectedDashboardId,
        dashboardModule: dashboardModule || DashboardModule.OVERVIEW,
      })
    );
  },
  getPartialRouterStateStream: () =>
    dashboardNavigation$.pipe(
      map(({ selectedDashboardId, dashboardModule, branchId }) => ({
        selectedDashboardId,
        dashboardModule,
        branchId,
      }))
    ),
});
