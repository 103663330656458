import { NotificationsProps } from '@ardoq/report-builder';
import currentUser from '../../models/currentUser';
import { PersonalSetting } from '@ardoq/api-types';
import { SHARE_BUTTON_ID } from './NavBarButtons';

type GetNotificationPropsArgs = {
  hasSubdivisionsFeature: boolean;
};

export const getNotificationsProps = ({
  hasSubdivisionsFeature,
}: GetNotificationPropsArgs): NotificationsProps => {
  const shouldHideDataSelectionNotification = currentUser.getPersonalSetting(
    PersonalSetting.HIDE_UPDATED_REPORT_BUILDER_NOTIFICATION
  );
  const dataSelectionNotification = {
    isNewFeatureNotificationVisible:
      hasSubdivisionsFeature && !shouldHideDataSelectionNotification,
    onNotificationClose: () =>
      currentUser.setPersonalSetting(
        PersonalSetting.HIDE_UPDATED_REPORT_BUILDER_NOTIFICATION,
        true
      ),
    onShareButtonLinkClick: () => {
      const shareButton = document.getElementById(SHARE_BUTTON_ID);
      if (shareButton) {
        shareButton.focus();
      }
    },
  };

  const shouldHideColumnRenameNotification = currentUser.getPersonalSetting(
    PersonalSetting.HIDE_REPORT_BUILDER_COLUMN_RENAME_NOTIFICATION
  );

  const columnRenameNotification = {
    isNewFeatureNotificationVisible: !shouldHideColumnRenameNotification,
    onNotificationClose: () =>
      currentUser.setPersonalSetting(
        PersonalSetting.HIDE_REPORT_BUILDER_COLUMN_RENAME_NOTIFICATION,
        true
      ),
  };

  return {
    dataSelectionNotification,
    columnRenameNotification,
  };
};
