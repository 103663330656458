import { MetaInfoState } from './metaInfoTypes';
import { Field, TextInput } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import type { MetaInfoCommands } from './metaInfoCommands';
import type { SelectedViewCommands } from '../selectedView/commands';
import { SidebarMenu } from '@ardoq/sidebar-menu';
import { SidePanelSection } from '../components/atoms';
import { IconName } from '@ardoq/icons';
import { Box } from '@ardoq/layout';
import {
  defaultExtensions,
  RichTextEditorTransition,
} from '@ardoq/rich-text-editor-2024';
import {
  useGenerateDescriptionState,
  GenerateDescriptionButton,
} from '@ardoq/snowflakes';
import { ApiResponse } from '@ardoq/api';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import { DirectedTriple } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';

export type MetaInfoPanelProps = {
  state: MetaInfoState;
  commands: MetaInfoCommands & SelectedViewCommands;
  generateViewpointDescription: GenerateViewpointDescription;
};

export type GenerateViewpointDescription = (params: {
  name: string | null;
  description: string | null;
  viewName: string;
  paths: DirectedTriple[][];
}) => ApiResponse<{
  text: string;
  type: 'description' | 'excuse';
}>;

export const MetaInfoPanel = ({
  state: { name, viewOptions, description, viewName, viewSelectWarning, paths },
  commands,
  generateViewpointDescription,
}: MetaInfoPanelProps) => {
  const {
    state: generateDescriptionState,
    commands: generateDescriptionCommands,
  } = useGenerateDescriptionState();
  const generateDescription = async () => {
    generateDescriptionCommands.onLoading();
    const response = await generateViewpointDescription({
      name,
      description,
      viewName,
      paths,
    });
    generateDescriptionCommands.onDescriptionReceived();
    if (isArdoqError(response)) {
      generateDescriptionCommands.onError(getArdoqErrorMessage(response));
    } else if (response.type === 'description') {
      commands.setDescription(response.text);
    } else {
      generateDescriptionCommands.onLLMCouldNotGenerateDescription(
        response.text
      );
    }
  };

  const extensions = [...defaultExtensions];
  if (hasFeature(Features.AUTOCOMPLETE_OPENAI)) {
    extensions.push({
      name: 'Generate',
      generateFunction: async () => {
        return generateViewpointDescription({
          name,
          description,
          viewName,
          paths,
        });
      },
    });
  }

  return (
    <SidebarMenu
      isPageBodyMenu
      isResizable
      isRightContent
      headerTitle="Manage the meta info of your viewpoint"
      headerLeftIconName={IconName.SAVE}
    >
      <Box padding="medium">
        <SidePanelSection $isVertical={true} $padding="s16" $gap={'s16'}>
          <TextInput
            label="Viewpoint name"
            dataTestId="viewpoint-name-input"
            value={name ?? ''}
            onValueChange={commands.setName}
          />
          <Select
            label="View style "
            value={viewName}
            dataTestId="view-style-select"
            warningMessage={viewSelectWarning || undefined}
            options={viewOptions}
            onChange={commands.setSelectedView}
          />
          <Field label="Description">
            <RichTextEditorTransition
              dataTestId="viewpoint-description-textarea"
              content={description ?? ''}
              onInputChange={commands.setDescription}
            >
              {hasFeature(Features.AUTOCOMPLETE_OPENAI) && (
                <GenerateDescriptionButton
                  onClick={generateDescription}
                  isLoading={generateDescriptionState.isLoading}
                  hasError={generateDescriptionState.hasError}
                  componentName={name}
                  componentTypeName={'Viewpoint'}
                  currentDescription={description}
                />
              )}
            </RichTextEditorTransition>
          </Field>
        </SidePanelSection>
      </Box>
    </SidebarMenu>
  );
};
