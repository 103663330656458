import { Features, hasFeature } from '@ardoq/features';
import { map } from 'rxjs/operators';
import currentUser$, {
  CurrentUserState,
  getIsOrgWriter,
} from 'streams/currentUser/currentUser$';
import expandedFolders$, { ExpandedFoldersState } from './expandedFolders$';
import { combineFolders } from 'components/EntityBrowser/utils';
import assets$, { AssetsState } from 'streams/assets/assets$';
import { AssetType, PrivilegeLabel, AssetFolder } from '@ardoq/api-types';
import { SelectableAssetType } from './types';
import { intersection } from 'lodash';
import { debouncedCombineLatest } from 'streams/utils/streamUtils';
import { dashboardAssetBrowserCommands } from './commands';
import { DashboardContainerProps } from './DashboardContainer';
import { surveyAccessControlInterface } from 'resourcePermissions/accessControlHelpers/surveys';
import { hasPrivilege } from '@ardoq/privileges';
import { viewpointAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/viewpoints';
import surveyAdmin$ from 'surveyAdmin/streams/surveyAdmin$';
import { SurveyAdminStreamState } from 'surveyAdmin/streams/types';
import { locale$ } from 'streams/locale$';
import { Locale } from '@ardoq/locale';

const getAssets = (
  assetsById: { [key: string]: any },
  assetsType: SelectableAssetType,
  showAssets: boolean
) => (showAssets ? Object.values(assetsById) : []);

const mapProps = ([
  currentUserState,
  expandedFoldersState,
  assets,
  surveyAdminState,
  locale,
]: [
  CurrentUserState,
  ExpandedFoldersState,
  AssetsState,
  SurveyAdminStreamState,
  Locale,
]): Partial<DashboardContainerProps> &
  Pick<DashboardContainerProps, 'permissionContext'> => {
  const currentUserIsOrgWriter = getIsOrgWriter(currentUserState);
  const scenariosEnabled = hasFeature(Features.SCENARIOS_BETA);
  const traversalsEnabled = hasFeature(Features.SUPPORT_LARGE_DATASETS);
  const bookmarksEnabled = hasFeature(Features.BOOKMARKS);

  const {
    expandedFoldersIds,
    expandedFoldersSearchPhrase,
    expandedFoldersSortParams,
    expandedFoldersFiltersByType,
    expandedFoldersShowFavoritesOnly,
  } = expandedFoldersState;

  const showAll = !Object.values(expandedFoldersFiltersByType).some(Boolean);

  const favoriteFilterFn = (assetWithMeta: { meta: { favorite: boolean } }) =>
    !expandedFoldersShowFavoritesOnly || assetWithMeta.meta.favorite;

  const filteredWorkspaces = getAssets(
    assets.workspaceAssetsById,
    AssetType.WORKSPACE,
    showAll || expandedFoldersFiltersByType[AssetType.WORKSPACE]
  ).filter(favoriteFilterFn);

  const filteredScenarios = scenariosEnabled
    ? getAssets(
        assets.scenariosById,
        AssetType.SCENARIO,
        showAll || expandedFoldersFiltersByType[AssetType.SCENARIO]
      ).filter(favoriteFilterFn)
    : [];

  const filteredPresentations = getAssets(
    assets.presentationsById,
    AssetType.PRESENTATION,
    showAll || expandedFoldersFiltersByType[AssetType.PRESENTATION]
  ).filter(favoriteFilterFn);

  const filteredSurveys = getAssets(
    assets.surveysById,
    AssetType.SURVEY,
    showAll || expandedFoldersFiltersByType[AssetType.SURVEY]
  ).filter(favoriteFilterFn);

  const filteredMetamodels = getAssets(
    assets.metamodelsById,
    AssetType.METAMODEL,
    showAll || expandedFoldersFiltersByType[AssetType.METAMODEL]
  ).filter(favoriteFilterFn);

  const filteredReports = getAssets(
    assets.reportsById,
    AssetType.REPORT,
    showAll || expandedFoldersFiltersByType[AssetType.REPORT]
  ).filter(favoriteFilterFn);
  const filteredDashboards = getAssets(
    assets.dashboardsById,
    AssetType.DASHBOARD,
    showAll || expandedFoldersFiltersByType[AssetType.DASHBOARD]
  ).filter(favoriteFilterFn);
  const filteredTraversals = traversalsEnabled
    ? getAssets(
        assets.traversalsById,
        AssetType.TRAVERSAL,
        showAll || expandedFoldersFiltersByType[AssetType.TRAVERSAL]
      ).filter(favoriteFilterFn)
    : [];
  const filteredBookmarks = bookmarksEnabled
    ? getAssets(
        assets.bookmarksById,
        AssetType.BOOKMARK,
        showAll || expandedFoldersFiltersByType[AssetType.BOOKMARK]
      ).filter(favoriteFilterFn)
    : [];

  const allVisibleAssetIds = [
    ...filteredWorkspaces,
    ...filteredScenarios,
    ...filteredPresentations,
    ...filteredSurveys,
    ...filteredMetamodels,
    ...filteredReports,
    ...filteredDashboards,
    ...filteredTraversals,
    ...filteredBookmarks,
  ].map(({ _id }) => _id);

  const assetGroupsByType = {
    [AssetType.WORKSPACE]: filteredWorkspaces,
    [AssetType.SCENARIO]: filteredScenarios,
    [AssetType.PRESENTATION]: filteredPresentations,
    [AssetType.SURVEY]: filteredSurveys,
    [AssetType.METAMODEL]: filteredMetamodels,
    [AssetType.REPORT]: filteredReports,
    [AssetType.DASHBOARD]: filteredDashboards,
    [AssetType.TRAVERSAL]: filteredTraversals,
    [AssetType.BOOKMARK]: filteredBookmarks,
  };

  const assetsById: { [key: string]: any } = {
    ...assets.assetFoldersById,
    ...assets.metamodelsById,
    ...assets.presentationsById,
    ...assets.surveysById,
    ...assets.workspaceAssetsById,
    ...assets.scenariosById,
    ...assets.reportsById,
    ...assets.dashboardsById,
    ...assets.traversalsById,
    ...assets.bookmarksById,
  };

  const getIsFolderVisible = (assetFolder: AssetFolder) => {
    const nestedContentIds = assetFolder.nestedContent.map(({ _id }) => _id);
    return (
      (showAll && !expandedFoldersShowFavoritesOnly) ||
      intersection(nestedContentIds, allVisibleAssetIds).length > 0
    );
  };

  const dataSource = combineFolders(
    Object.values(assets.assetFoldersById).filter(getIsFolderVisible),
    Object.values(assetGroupsByType)
  );

  const commands = dashboardAssetBrowserCommands;

  return {
    dataSource,
    assetsById,
    expandedFoldersIds,
    expandedFoldersSearchPhrase,
    expandedFoldersSortParams,
    expandedFoldersFiltersByType,
    expandedFoldersShowFavoritesOnly,
    currentUserIsOrgWriter,
    scenariosEnabled,
    locale,
    traversalsEnabled: hasFeature(Features.SUPPORT_LARGE_DATASETS),
    bookmarksEnabled: hasFeature(Features.BOOKMARKS),
    presentationsEnabled: hasFeature(Features.PRESENTATIONS),
    surveysEnabled: hasFeature(Features.SURVEYS),
    canCreateSurvey: surveyAccessControlInterface.canCreateSurvey(),
    surveysWithPendingApprovals: surveyAdminState.surveysWithPendingApprovals,
    broadcastsEnabled: hasFeature(Features.BROADCASTS),
    hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
    discoverEnabled: hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER),
    canCreateViewpoint: viewpointAccessControlInterface.canCreateViewpoint(),
    folderIds: Object.keys(assets.assetFoldersById),
    commands,
    permissionContext: assets.permissionContext,
  };
};

const dashboardContainer$ = debouncedCombineLatest([
  currentUser$,
  expandedFolders$,
  assets$,
  surveyAdmin$,
  locale$,
]).pipe(map(mapProps));

export default dashboardContainer$;
