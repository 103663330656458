import DeletedReference from './DeletedReference';
import ChevronDecorator from './ChevronDecorator';
import { DeletedReferenceProperties } from './types';
import { FlexBox } from '@ardoq/layout';

const DeletedReferenceWithIndentation = (props: DeletedReferenceProperties) => {
  return (
    <FlexBox>
      <ChevronDecorator hidden>
        <DeletedReference {...props} />
      </ChevronDecorator>
    </FlexBox>
  );
};

export default DeletedReferenceWithIndentation;
