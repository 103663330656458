import { Component } from 'react';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { AddQuestionProperties } from './types';
import { SurveyQuestionType } from '@ardoq/api-types';
import AddSectionButton from './AddSectionButton';
import QuestionTypeButtons from './QuestionTypeButtons';
import { getBarColor } from './utils';
import { trackEvent } from 'tracking/tracking';
import {
  createAttributeQuestion,
  createFieldQuestion,
  createHiddenQuestion,
  createTextQuestion,
  createReferenceQuestion,
  createTagQuestion,
} from './questionBuilderUtil';

interface AddQuestionState {
  type: 'field';
  flow: 0 | 1;
}

export default class AddQuestion extends Component<
  AddQuestionProperties,
  AddQuestionState
> {
  constructor(props: AddQuestionProperties) {
    super(props);
    this.state = {
      type: 'field',
      flow: 0,
    };
  }

  handleOnClick(value: SurveyQuestionType) {
    this.setState({ flow: 0 });
    trackEvent('Survey builder: added question', { type: value });
    const properties = {};
    const label = '';
    switch (value) {
      case SurveyQuestionType.ATTRIBUTE:
        return this.props.addQuestion(
          createAttributeQuestion({ attributeName: '' }, label)
        );
      case SurveyQuestionType.FIELD:
        return this.props.addQuestion(
          createFieldQuestion({ fieldName: '' }, label)
        );
      case SurveyQuestionType.REFERENCE:
        return this.props.addQuestion(
          createReferenceQuestion(properties, label)
        );
      case SurveyQuestionType.TAG:
        return this.props.addQuestion(createTagQuestion(properties, label));
      case SurveyQuestionType.HIDDEN_FIELD:
        return this.props.addQuestion(
          createHiddenQuestion(value, { fieldName: '' })
        );
      case SurveyQuestionType.TEXT:
        return this.props.addQuestion(createTextQuestion(value, label));
      default:
        return;
    }
  }

  render() {
    const hasTagQuestion = this.props.questions.some(
      question => question.type === SurveyQuestionType.TAG
    );
    const questionOptions = [
      {
        label: 'Field Question',
        dataTestId: 'field-button',
        onClick: () => this.handleOnClick(SurveyQuestionType.FIELD),
        color: getBarColor(SurveyQuestionType.FIELD),
      },
      this.props.isComponent && {
        label: 'Reference Question',
        dataTestId: 'reference-button',
        onClick: () => this.handleOnClick(SurveyQuestionType.REFERENCE),
        color: getBarColor(SurveyQuestionType.REFERENCE),
      },
      {
        label: 'Text Section',
        dataTestId: 'text-button',
        onClick: () => this.handleOnClick(SurveyQuestionType.TEXT),
        color: getBarColor(SurveyQuestionType.TEXT),
      },
      this.props.isComponent && {
        label: 'Hidden Field',
        dataTestId: 'hidden-field-button',
        onClick: () => this.handleOnClick(SurveyQuestionType.HIDDEN_FIELD),
        color: getBarColor(SurveyQuestionType.HIDDEN_FIELD),
      },
      this.props.isComponent && {
        label: 'Tag Question',
        dataTestId: 'tag-button',
        onClick: () => this.handleOnClick(SurveyQuestionType.TAG),
        color: getBarColor(SurveyQuestionType.TAG),
        isDisabled:
          hasTagQuestion || this.props.hasSurveyResponseApprovalsFeature,
        popoverContent: hasTagQuestion
          ? 'Only one tag question is allowed'
          : this.props.hasSurveyResponseApprovalsFeature
            ? 'Tag questions are not currently supported by surveys using response approval'
            : undefined,
      },
    ].filter(ExcludeFalsy);

    if (this.state.flow === 0) {
      return (
        <AddSectionButton
          onClick={() => {
            this.setState({ flow: 1 });
            trackEvent('Survey builder: clicked add section');
          }}
          title={this.props.addQuestionLabel}
          subtitle={this.props.addQuestionDescription}
          dataTestId="add-question-button"
        />
      );
    }
    return (
      <QuestionTypeButtons
        buttonConfig={questionOptions}
        onCancelButtonClick={() => {
          this.setState({ flow: 0 });
          trackEvent('Survey builder: clicked cancel add section');
        }}
      />
    );
  }
}
