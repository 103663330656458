/* eslint-disable camelcase */
/**
 * Beamer is a 3rd-party tool we use to deliver product changelog/update
 * notifications to users.
 */

import { colors } from '@ardoq/design-tokens';
import { IconName } from '@ardoq/icons';
import currentUser from 'models/currentUser';
import { createGlobalStyle } from 'styled-components';

/** Used to identify which element to attach badge to */
export const BEAMER_TRIGGER_ID = 'beamerTrigger';

export const BEAMER_TRIGGER_ICON = IconName.PRODUCT_UPDATES;

// This overrides the style of the badge overlay that is used to show alerts
// from Beamer (https://getbeamer.com)
export const BeamerStyle = createGlobalStyle`
  .beamer_icon.active {
    font-size: 0 !important;
    width: 8px !important;
    height: 8px !important;
    background-color: ${colors.purple50} !important;
  }
`;

// The output from this must match exactly the hashing we do in the data
// pipeline so we can correlate the hashed IDs
// Source: https://stackoverflow.com/questions/18338890/are-there-any-sha-256-javascript-implementations-that-are-generally-considered-t/48161723
const sha256 = async (message: string) => {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};

type BeamerConfig = {
  product_id: string;
  user_id?: string;
  selector?: string;
  button?: boolean;
  bounce?: boolean;
  top?: number;
  right?: number;
  /** Disables all pop-ups, topbar, snippets and tooltips. Does not disable the
   * badge */
  ignore_boosted_announcements?: boolean;
} & Record<string, any>;

export const loadExternalBeamerScript = async () => {
  const hashedUserId = await sha256(currentUser.id);
  const beamerConfig: BeamerConfig = {
    product_id: 'rGtZYFRn38391',
    user_id: hashedUserId,
    selector: BEAMER_TRIGGER_ID,
    button: false,
    bounce: false,
    top: 4,
    right: 4,
    ignore_boosted_announcements: true,
  };
  window.beamer_config = beamerConfig;

  const externalScript = document.createElement('script');
  externalScript.src = 'https://app.getbeamer.com/js/beamer-embed.js';
  externalScript.type = 'text/javascript';
  externalScript.defer = true;
  document.head.appendChild(externalScript);
};
