import { TopBarButton, TopBarTitle, TopBarWrapper } from '../atoms';
import { ChevronIconName, Icon, IconName } from '@ardoq/icons';
import ShowHideRelatedComponentsTopBarButton from './showHideRelatedComponentsTopBarButton/ShowHideRelatedComponentsTopBarButton';
import { POPOVER_ID_ATTR, popoverRegistry } from '@ardoq/popovers';
import CloseDiffPopover, { POPOVER_ID } from './CloseDiffPopover';

interface ScenarioRelatedTopBarProps {
  toggle: () => void;
  isOpened: boolean;
  isDiffModeActive: boolean;
  isFiltering: boolean;
  isInSync: boolean;
}

const ScenarioRelatedTopBar = ({
  toggle,
  isOpened,
  isDiffModeActive,
  isInSync,
}: ScenarioRelatedTopBarProps) => {
  popoverRegistry.set(POPOVER_ID, () => (
    <CloseDiffPopover openRelatedComponents={toggle} />
  ));

  return (
    <TopBarWrapper
      $isScenarioMode
      $isScenarioOutOfSync={!isInSync}
      {...{ [POPOVER_ID_ATTR]: POPOVER_ID }}
    >
      <TopBarTitle $isScenarioMode>
        Related
        {!isInSync && (
          <Icon iconName={IconName.WARNING} style={{ marginLeft: 8 }} />
        )}
      </TopBarTitle>
      <div>
        <ShowHideRelatedComponentsTopBarButton />
        <TopBarButton
          data-click-id="related-components-toggle"
          $isScenarioMode
          style={{ marginRight: 8 }}
          onClick={() => !isDiffModeActive && toggle()}
        >
          <Icon
            iconName={isOpened ? ChevronIconName.DOWN : ChevronIconName.UP}
          />
        </TopBarButton>
      </div>
    </TopBarWrapper>
  );
};
export default ScenarioRelatedTopBar;
