import { DisabledReason, StepResolution } from 'scope/merge/types';
import { MergeStepLabel } from 'scope/merge/typesMergeStepLabel';
import { Verb } from '@ardoq/api-types';

export enum StepType {
  METAMODEL,
  MODEL,
}

export type Step = {
  name: MergeStepLabel;
  isSelected: boolean;
  isExpanded: boolean;
  subSteps: SubStep[];
  isDisabled: boolean;
};

export type SubStep = {
  name: Verb;
  state: StepResolution;
  isSelected: boolean;
  isCompleted: boolean;
  isDisabled: boolean;
  isSubmitted: boolean;
  hasEntities: boolean;
  entityCount: number;
  disabledReason: DisabledReason;
};
