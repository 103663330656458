import { Header } from 'integrations/dashboard/Header';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import { DashboardState, viewModel$ } from './viewModel$';
import { s24 } from '@ardoq/design-tokens';
import { LoadingOverlay, Stack } from '@ardoq/layout';
import IntegrationsList from 'integrations/dashboard/components/integrationsList/IntegrationsList';
import { ScheduleCommands } from 'integrations/dashboard/widgets/schedules/schedulesTable/SchedulesTable';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { Integration } from 'integrations/types';
import { connect } from '@ardoq/rxbeach';
import Overview from './Overview';
import { LoaderSize } from '@ardoq/ardoq-loader-component';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';
import { useEffect } from 'react';
import { trackEvent } from 'tracking/tracking';
import { EVENT_NAMES } from 'integrations/common/tracking/events';

export type DashboardComponentProps = {
  integrations: Integration[];
  dashboardState: DashboardState;
  scheduleCommands: ScheduleCommands;
  recentIntegrationsIds: IntegrationId[];
  importsCount: number;
  exportsCount: number;
  isLoading: boolean;
  onIntegrationClick: (integration: Integration) => void;
  onViewHistory: () => void;
  allConnections: Record<IntegrationId, IntegrationConnectionsState>;
  alertsEnabled: boolean;
};

const DashboardComponent = ({
  integrations,
  dashboardState,
  onIntegrationClick,
  scheduleCommands,
  recentIntegrationsIds,
  importsCount,
  exportsCount,
  onViewHistory,
  isLoading,
  allConnections,
  alertsEnabled,
}: DashboardComponentProps) => {
  useEffect(() => {
    if (dashboardState === DashboardState.Overview) {
      trackEvent(EVENT_NAMES.VISITED_INTEGRATIONS_DASHBOARD_PAGE, {
        view: 'dashboard-with-widgets',
      });
      return;
    }
    trackEvent(EVENT_NAMES.VISITED_INTEGRATIONS_DASHBOARD_PAGE, {
      view: 'simple',
    });
  }, [dashboardState]);

  if (isLoading) {
    return <LoadingOverlay loaderSize={LoaderSize.MEDIUM} />;
  }

  return (
    <PageWrapper>
      <Header
        integrations={integrations}
        primaryContent="Integrations"
        secondaryContent="Home"
      />

      <PageBody
        backgroundColor={
          dashboardState === DashboardState.AllIntegrations
            ? 'surfaceDefault'
            : undefined
        }
        padding={s24}
      >
        <Stack align="center">
          {dashboardState === DashboardState.AllIntegrations && (
            <IntegrationsList
              integrations={integrations}
              onIntegrationClick={onIntegrationClick}
            />
          )}
          {dashboardState === DashboardState.Overview && (
            <Overview
              integrations={integrations}
              recentIntegrationsIds={recentIntegrationsIds}
              onViewHistory={onViewHistory}
              onIntegrationClick={onIntegrationClick}
              scheduleCommands={scheduleCommands}
              importsCount={importsCount}
              exportsCount={exportsCount}
              allConnections={allConnections}
              alertsEnabled={alertsEnabled}
            />
          )}
        </Stack>
      </PageBody>
    </PageWrapper>
  );
};

export const Dashboard = connect(DashboardComponent, viewModel$);
