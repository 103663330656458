import PersonCell from 'broadcasts/components/audienceTable/audienceByPerson/cellRenderers/PersonRenderer';
import SourceCell from 'broadcasts/components/audienceTable/audienceByPerson/cellRenderers/SourceCell';
import ComponentCell from 'broadcasts/components/audienceTable/audienceByPerson/cellRenderers/ComponentCell';
import { BroadcastContentType, SortOrder } from '@ardoq/api-types';
import LandingPageHeader from './LandingPageHeader';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { AudienceTableColumn } from '../../types';
import { SortByFn, SortById } from '@ardoq/pagination';
import { AudienceDataSourceItem } from '../../audienceByPerson/utils';
import { Column, ExpandableRowData } from '@ardoq/table';
import { getIconName } from '../../utils';
import FormattedIcon from '../../FormattedIcon';

export const getStatusColumnConfig = (
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
): Column<ExpandableRowData<AudienceDataSourceItem>> => ({
  dataIndex: AudienceTableColumn.AUDIENCE_STATUS,
  headerStyle: { width: '5%' },
  cellStyle: { width: '5%' },
  valueRender: (_, { audienceStatus }) => (
    <FormattedIcon iconName={getIconName(audienceStatus)} />
  ),
  sortOrder:
    sortById === AudienceTableColumn.AUDIENCE_STATUS ? sortOrder : undefined,
  onHeaderClick: () => sortByFn(AudienceTableColumn.AUDIENCE_STATUS),
});

export const getAudienceColumnConfig = (
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
): Column<ExpandableRowData<AudienceDataSourceItem>> => ({
  title: 'Audience',
  dataIndex: AudienceTableColumn.PERSON_LABEL,
  valueRender: (personLabel, { personSublabel, tableFilter }) => (
    <PersonCell
      personLabel={personLabel}
      personSublabel={personSublabel}
      highlight={tableFilter}
    />
  ),
  sortOrder:
    sortById === AudienceTableColumn.PERSON_LABEL ? sortOrder : undefined,
  onHeaderClick: () => sortByFn(AudienceTableColumn.PERSON_LABEL),
});

export const getSourceColumnConfig = (
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
): Column<ExpandableRowData<AudienceDataSourceItem>> => ({
  title: 'Source',
  dataIndex: AudienceTableColumn.SOURCE_LABELS,
  valueRender: (_value, { sourceLabels }) => (
    <SourceCell sourceLabels={sourceLabels} />
  ),
  sortOrder:
    sortById === AudienceTableColumn.SOURCE_LABELS ? sortOrder : undefined,
  onHeaderClick: () => sortByFn(AudienceTableColumn.SOURCE_LABELS),
});

export const getComponentColumnConfig = (
  expandedFoldersIds: string[],
  expandRow: (path: string) => void,
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
): Column<ExpandableRowData<AudienceDataSourceItem>> => ({
  title: 'Component',
  headerStyle: { width: '40%' },
  cellStyle: { width: '40%' },
  dataIndex: AudienceTableColumn.COMPONENT_LABEL,
  valueRender: (
    componentLabel,
    { meta, children, componentStatus, tableFilter }
  ) => {
    const { path } = meta;
    const isExpandable = Boolean(children);
    const isExpanded = expandedFoldersIds.includes(path);
    return (
      <ComponentCell
        isExpandable={isExpandable}
        isExpanded={isExpanded}
        toggleExpand={() => expandRow(path)}
        componentLabel={componentLabel}
        componentStatus={componentStatus}
        highlight={tableFilter}
      />
    );
  },
  sortOrder:
    sortById === AudienceTableColumn.SORTABLE_COMPONENT_LABEL
      ? sortOrder
      : undefined,
  onHeaderClick: () => sortByFn(AudienceTableColumn.SORTABLE_COMPONENT_LABEL),
});

export const getAudienceStartingPageColumnConfig = (
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
): Column<ExpandableRowData<AudienceDataSourceItem>> => ({
  title: 'Landing page',
  headerStyle: { width: '30%' },
  cellStyle: { width: '30%' },
  headerRender: LandingPageHeader,
  dataIndex: AudienceTableColumn.AUDIENCE_STARTING_PAGE_LABEL,
  valueRender: audienceStartingPageLabel => audienceStartingPageLabel,
  sortOrder:
    sortById === AudienceTableColumn.AUDIENCE_STARTING_PAGE_LABEL
      ? sortOrder
      : undefined,
  onHeaderClick: () =>
    sortByFn(AudienceTableColumn.AUDIENCE_STARTING_PAGE_LABEL),
});

const getColumnConfigByPerson =
  (shouldShowStatusColumn: boolean, contentType: BroadcastContentType) =>
  (
    expandedRowIds: string[],
    expandRow: (path: string) => void,
    sortByFn: SortByFn,
    sortById: SortById,
    sortOrder: SortOrder
  ) =>
    [
      shouldShowStatusColumn &&
        getStatusColumnConfig(sortByFn, sortById, sortOrder),
      getAudienceColumnConfig(sortByFn, sortById, sortOrder),
      contentType !== BroadcastContentType.REPORT &&
        getComponentColumnConfig(
          expandedRowIds,
          expandRow,
          sortByFn,
          sortById,
          sortOrder
        ),
    ].filter(ExcludeFalsy);

export default getColumnConfigByPerson;
