import { ColumnMappingSidebar as ColumnMappingSidebarComponent } from 'integrations/common/components/columnMappingSidebar/ColumnMappingSidebar';
import { Maybe } from '@ardoq/common-helpers';
import { TablePreview } from 'integrations/common/streams/tablePreviews/types';
import {
  IntegrationId,
  TableMappingMap,
  TabularMapping,
} from 'integrations/common/streams/tabularMappings/types';
import { Field } from 'integrations/common/streams/fields/types';
import {
  TableMappingType,
  IntegrationWorkspace,
} from '@ardoq/api-types/integrations';
import type { Workspace as OrgWorkspace } from '@ardoq/api-types';
import type {
  OnColumnMappingChange,
  OnColumnMappingClear,
} from 'integrations/common/components/columnMappingSidebar/types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  setColumnMapping,
  unsetColumnMappings,
} from 'integrations/common/streams/tabularMappings/actions';
import { extractNewReferenceTypes } from 'integrations/common/streams/references/utils';
import { viewModel$ } from './viewModel$';
import { MappedColumnsConstraints } from 'integrations/common/streams/tabularMappingConstraints/types';
import { TableMappingErrors } from 'integrations/common/streams/tabularMappingErrors/types';
import { SelectOptionsOrGroups } from '@ardoq/select';

const MappingSidebarComponent = ({
  integrationId,
  tableMapping,
  tableMappingType,
  tablePreview,
  fields,
  workspaces,
  selectedColumn,
  setSelectedColumn,
  isCreationDisabled,
  tabularMapping,
  isHardDeleteEnabled,
  mappedColumnsConstraints,
  tableMappingErrors,
  fieldTypeOptions,
  isTypeSelectionDisabled,
  onFirstComponentTypeChange,
}: {
  integrationId: IntegrationId;
  tableMapping: TableMappingMap;
  tableMappingType: TableMappingType;
  tablePreview?: TablePreview;
  fields: {
    used: Field[];
    all: Field[];
  };
  workspaces: {
    used: IntegrationWorkspace[];
    existing: OrgWorkspace[];
  };
  selectedColumn: Maybe<number>;
  setSelectedColumn: (number: Maybe<number>) => void;
  isCreationDisabled: boolean;
  tabularMapping: TabularMapping;
  isHardDeleteEnabled: boolean;
  mappedColumnsConstraints: MappedColumnsConstraints;
  tableMappingErrors: TableMappingErrors;
  fieldTypeOptions: SelectOptionsOrGroups<string>;
  isTypeSelectionDisabled: boolean;
  onFirstComponentTypeChange?: (changedDefaultColumnType: boolean) => void;
}) => {
  const isColumnSelected = selectedColumn !== null && tableMapping;
  const newReferenceTypes = isColumnSelected
    ? extractNewReferenceTypes(
        tabularMapping,
        tableMapping.mappedColumns[selectedColumn]
      )
    : [];

  const onColumnMappingChange: OnColumnMappingChange = ({
    columnName,
    columnIndex,
    columnMapping,
  }) => {
    dispatchAction(
      setColumnMapping({
        integrationId,
        columnName,
        columnIndex,
        columnMapping,
      })
    );
  };

  const onColumnMappingClear: OnColumnMappingClear = columnIndex => {
    dispatchAction(
      unsetColumnMappings({ integrationId, columnIndexes: [columnIndex] })
    );
  };

  return (
    <>
      {selectedColumn !== null && (
        <ColumnMappingSidebarComponent
          tableMappingType={tableMappingType}
          tablePreview={tablePreview}
          tableMapping={tableMapping}
          selectedColumn={selectedColumn}
          usedFields={fields.used}
          allFields={fields.all}
          usedWorkspaces={workspaces.used}
          existingWorkspaces={workspaces.existing}
          newReferenceTypes={newReferenceTypes}
          setSelectedColumn={setSelectedColumn}
          onColumnMappingChange={onColumnMappingChange}
          onColumnMappingClear={onColumnMappingClear}
          isCreationDisabled={isCreationDisabled}
          isHardDeleteEnabled={isHardDeleteEnabled}
          mappedColumnsConstraints={mappedColumnsConstraints}
          tableMappingErrors={tableMappingErrors}
          fieldTypeOptions={fieldTypeOptions}
          isTypeSelectionDisabled={isTypeSelectionDisabled}
          onFirstComponentTypeChange={onFirstComponentTypeChange}
        />
      )}
    </>
  );
};

export const ColumnMappingSidebar = connect(
  MappingSidebarComponent,
  viewModel$
);
