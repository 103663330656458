import { trackEvent } from 'tracking/tracking';

export enum API_TOKENS_EVENTS {
  // Create api token
  CLICKED_CREATE_NEW_BUTTON = 'clicked create new button',
  CREATED_NEW_TOKEN = 'created new token',
  // Regenerate api token
  CLICKED_REGENERATE_TOKEN_MENU_ITEM = 'clicked regenerate token menu item',
  REGENERATED_TOKEN = 'regenerated token',
  // Delete api token
  CLICKED_DELETE_TOKEN_MENU_ITEM = 'clicked delete token menu item',
  DELETED_TOKEN = 'deleted token',
  // Copy token
  COPIED_TOKEN = 'copied token',
}

export const trackAPITokensEvent = <T extends object>(
  name: API_TOKENS_EVENTS,
  metadata?: T
) => trackEvent(`API tokens0 ${name}`, metadata);
