import {
  APIComponentAttributes,
  APIViewpointAttributes,
  ArdoqId,
  LoadedState,
  StartContextSelectionType,
} from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  getComponentOverviewPageData,
  setComponentOverviewId,
} from './actions';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { openHomeWithFilter } from 'appContainer/actions';
import {
  executeViewpoint,
  openViewpointBuilderWithComponents,
  openViewpointBuilderWithComponentsAndCloseLoadedViewpoints,
} from 'viewpointBuilder/actions';
import { componentSearchInterface } from 'viewpointBuilder/selectContextTab/componentSearchInterface';
import { confirmClosingPreviousViewIfOpen } from 'viewpointBuilder/utils';
import { exitTraversalMode } from 'traversals/exitTraversalMode';
import { ContextShape } from '@ardoq/data-model';
import component from 'models/component';
import { trackEvent } from 'tracking/tracking';

export type ComponentOverviewPageCommands = {
  openViewpoint: (args: {
    component: APIComponentAttributes;
    viewpoint: APIViewpointAttributes;
    context: ContextShape;
    loadedStates: LoadedState[];
    onClosingConfirmed?: VoidFunction;
  }) => void;
  navigateToComponent: (componentId: ArdoqId) => void;
  createViewpoint: (args: {
    component: APIComponentAttributes;
    context: ContextShape;
    loadedStates: LoadedState[];
  }) => void;
  exploreData: (component: APIComponentAttributes) => void;
  navigateToHome: () => void;
};

export const commands: ComponentOverviewPageCommands = {
  navigateToComponent: componentId => {
    dispatchAction(setComponentOverviewId(componentId));
    dispatchAction(getComponentOverviewPageData(componentId));
  },
  openViewpoint: async ({
    component,
    viewpoint,
    context,
    loadedStates,
    onClosingConfirmed,
  }) => {
    const closingConfirmed = await confirmClosingPreviousViewIfOpen(
      loadedStates,
      context,
      'openSavedViewpoint'
    );
    if (!closingConfirmed) {
      return;
    }
    onClosingConfirmed?.();
    exitTraversalMode();

    dispatchAction(
      executeViewpoint({
        ...viewpoint,
        viewpointId: viewpoint._id,
        groupBys: viewpoint.groupBys ?? [],
        filters: viewpoint.filters ?? {},
        conditionalFormatting: viewpoint.conditionalFormatting ?? [],
        componentSelection: {
          startContextSelectionType: StartContextSelectionType.MANUAL_SELECTION,
          startSet: [component._id],
        },
        type: 'EXECUTE_SAVED_VIEWPOINT',
        pathCollapsingRules: [],
      })
    );
  },
  createViewpoint: async ({ component, context, loadedStates }) => {
    const closingConfirmed = await confirmClosingPreviousViewIfOpen(
      loadedStates,
      context,
      'openNewDataset'
    );
    if (!closingConfirmed) {
      return;
    }
    exitTraversalMode();

    const components = [component]
      .map(componentSearchInterface.buildSearchDataFromComponent)
      .filter(ExcludeFalsy);
    dispatchAction(
      openViewpointBuilderWithComponents({
        components,
        componentIds: [component._id],
      })
    );
  },
  exploreData: component => {
    trackEvent('Component overview: clicked explore data');
    const components = [component]
      .map(componentSearchInterface.buildSearchDataFromComponent)
      .filter(ExcludeFalsy);
    dispatchAction(
      openViewpointBuilderWithComponentsAndCloseLoadedViewpoints({
        components,
        componentIds: [component._id],
      })
    );
  },
  navigateToHome: () => dispatchAction(openHomeWithFilter([])),
};
