import { PageBody, PageWrapper } from '@ardoq/page-layout';
import Navbar from 'views/navbar/Navbar';
import ExternalDocumentView from './ExternalDocumentView';

interface ExternalDocumentViewContainerProps {
  documentId: string;
  title: string;
  clientId: string;
}

const ExternalDocumentViewContainer = ({
  documentId,
  title,
  clientId,
}: ExternalDocumentViewContainerProps) => {
  return (
    <PageWrapper>
      <Navbar primaryContent={title} secondaryContent="External Document" />
      <PageBody backgroundColor="surfaceDefault" skipPadding>
        <ExternalDocumentView documentId={documentId} clientId={clientId} />
      </PageBody>
    </PageWrapper>
  );
};

export default ExternalDocumentViewContainer;
