import { GroupType, GroupingRule, ReferenceDirection } from '@ardoq/api-types';
import { PerspectiveGroupingRule } from '@ardoq/perspectives';
import { exhaustiveCheck } from '@ardoq/common-helpers';
import { v4 as uuidv4 } from 'uuid';

export const mapToPerspectiveGroupingRule = (
  groupingRule: GroupingRule
): PerspectiveGroupingRule => {
  const type = groupingRule.type as Exclude<
    GroupType,
    GroupType.REFERENCE_CONTEXTUAL | GroupType.ALL
  >;

  switch (type) {
    case GroupType.REFERENCE:
      return {
        id: uuidv4(),
        type: GroupType.REFERENCE,
        workspaceId: groupingRule.workspaceId!,
        direction: groupingRule.direction ?? ReferenceDirection.INCOMING,
        sublabel: groupingRule.sublabel,
        targetId: parseReferenceTypeIdFromTargetId(groupingRule.targetId!),
      };
    case GroupType.COMPONENT:
      return {
        id: uuidv4(),
        type: GroupType.COMPONENT,
      };
    case GroupType.FIELD:
      return {
        id: uuidv4(),
        type: GroupType.FIELD,
        targetId: groupingRule.targetId!,
        workspaceId: groupingRule.workspaceId!,
      };
    case GroupType.CHILD:
      return {
        id: uuidv4(),
        type: GroupType.CHILD,
        targetId: groupingRule.targetId!,
        workspaceId: groupingRule.workspaceId!,
      };
    case GroupType.PARENT:
      return {
        id: uuidv4(),
        type: GroupType.PARENT,
        targetId: groupingRule.targetId!,
        workspaceId: groupingRule.workspaceId!,
        sublabel: groupingRule.sublabel,
      };
    case GroupType.PARENT_ALL:
      return {
        id: uuidv4(),
        type: GroupType.PARENT_ALL,
        sublabel: groupingRule.sublabel,
      };
    case GroupType.TAG:
      return {
        id: uuidv4(),
        type: GroupType.TAG,
        targetId: groupingRule.targetId,
        workspaceId: groupingRule.workspaceId!,
      };
    case GroupType.WORKSPACE:
      return {
        id: uuidv4(),
        type: GroupType.WORKSPACE,
      };
    case GroupType.SUBDIVISION:
      return {
        id: uuidv4(),
        type: GroupType.SUBDIVISION,
      };
    default:
      return exhaustiveCheck(type);
  }
};

// target id for reference grouping rule comes as `${workspaceId}-${typeId}
export const parseReferenceTypeIdFromTargetId = (targetId: string): string => {
  const index = targetId.indexOf('-');
  return index > -1 ? targetId.slice(index + 1) : targetId;
};
