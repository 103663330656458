import { deepCopyObject } from 'utils/collectionUtil';
import Workspaces from 'collections/workspaces';
import { Features, hasFeature } from '@ardoq/features';
import {
  APISurveyAttributes,
  ArdoqId,
  MetaModel,
  SurveyApprovalRelationship,
  SurveyQuestionType,
  SurveyQuestionValidator,
  SurveySectionStatus,
  MetaModelTriple,
  APIOrganizationUser,
  OrgAccessLevel,
  SurveyFilterSetupAttributes,
  PersistedFieldSubQuestion,
  PersistedReferenceSubQuestion,
  isPersistedSurvey,
  UnpersistedReferenceSubQuestion,
  UnpersistedFieldSubQuestion,
  PersistedReferenceQuestion,
  UnpersistedReferenceQuestion,
  PersistedFieldQuestion,
  UnpersistedFieldQuestion,
  SurveyApprovalFallbackAudience,
  SurveyApprovalFlow,
  APISurveyPendingApprovals,
  SurveyApprovalAudiences,
} from '@ardoq/api-types';
import { ConfirmResult } from '@ardoq/modal';
import Fields from 'collections/fields';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { PermissionContext } from '@ardoq/access-control';
import { StepState } from '@ardoq/steppers';
import logMissingModel from 'models/logMissingModel';
import { isDateRangeFieldType } from '@ardoq/date-range';
import { isDateFieldType } from '@ardoq/date-time';
import { broadcastApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import { omit } from 'lodash';
import {
  isConditionalQuestion,
  isReferenceQuestion,
  isFieldQuestion,
  isHiddenFieldQuestion,
} from 'surveyAdmin/questions/questionPredicates';
import { Field } from 'streams/fields/fields$';
import { APPROVER_FIELD_TYPES } from './consts';
import {
  AllPossibleQuestions,
  FieldsByComponentTypeName,
  MaybePersistedSurvey,
} from 'surveyAdmin/types';
import {
  defaultRelationshipsAudience,
  defaultTraversalAudience,
  getRelationshipIdentifier,
  getRelationshipsAudience,
  getUsersAndGroupsAudience,
} from 'surveyAdmin/ChangeApproval/utils';
import { Locale, localeCompare } from '@ardoq/locale';
import {
  confirmUnpublishDueToErrorModal,
  confirmUnpublishModal,
  confirmUnpublishDueToErrorWhenBroadcastsModal,
  confirmUnpublishWhenBroadcastsModal,
} from 'surveyAdmin/SurveyModals';

export const isAdminOrWriter = (user: APIOrganizationUser) =>
  user.role === OrgAccessLevel.ADMIN || user.role === OrgAccessLevel.WRITER;

export const checkIfSurveyConflictsWithChangeApprovalScope = (
  surveyAttributes: MaybePersistedSurvey
): boolean => {
  if (!hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2)) return false;

  return (
    surveyAttributes.questions.some(
      question => SurveyQuestionType.TAG === question.type
    ) ||
    (hasFeature(Features.PERMISSION_ZONES_INTERNAL) &&
      Boolean(surveyAttributes.subdivisions?.enabled))
  );
};

export const getSurveyWithoutSurveySectionStatuses = (
  surveyAttributes: MaybePersistedSurvey
) => {
  const copyOfMisc = {
    ...(surveyAttributes.misc ?? {}),
  };
  delete copyOfMisc.generalInformationSectionStatus;
  delete copyOfMisc.defineTheDataSectionStatus;
  delete copyOfMisc.questionsSectionStatus;
  delete copyOfMisc.feedbackMessageSectionStatus;
  delete copyOfMisc.landingPageSectionStatus;
  delete copyOfMisc.resultsDisplaySectionStatus;
  delete copyOfMisc.ardoqDiscoverSectionStatus;
  delete copyOfMisc.changeApprovalSectionStatus;

  return { ...surveyAttributes, misc: copyOfMisc };
};

const stripKeysFromSubQuestions = (
  questions: (UnpersistedReferenceSubQuestion | PersistedReferenceSubQuestion)[]
) => {
  return questions.map(question => omit(question, 'key'));
};

const stripKeysFromDependentQuestions = (
  questions: (UnpersistedFieldSubQuestion | PersistedFieldSubQuestion)[]
) => {
  return questions.map(question => omit(question, 'key'));
};

export const stripKeysFromQuestions = (
  questions: AllPossibleQuestions[]
): AllPossibleQuestions[] => {
  return questions.map(question => {
    if (isReferenceQuestion(question)) {
      return {
        ...omit(question, 'key'),
        properties: {
          ...question.properties,
          questions: stripKeysFromSubQuestions(
            question.properties.questions ?? []
          ),
        },
      } as PersistedReferenceQuestion | UnpersistedReferenceQuestion;
    }
    if (isConditionalQuestion(question)) {
      return {
        ...omit(question, 'key'),
        properties: {
          ...question.properties,
          questions: stripKeysFromDependentQuestions(
            question.properties.questions ?? []
          ),
        },
      } as PersistedFieldQuestion | UnpersistedFieldQuestion;
    }

    return omit(question, 'key') as AllPossibleQuestions;
  });
};

type QuestionTypeCount = {
  attribute: number;
  field: number;
  reference: number;
  text: number;
  hiddenField: number;
  tag: number;
};

const determineQuestionTypes = (
  question: AllPossibleQuestions,
  questionTypeCount: QuestionTypeCount
) => {
  if (isConditionalQuestion(question)) {
    const combinedQuestions = [
      question,
      ...(question.properties.questions ?? []),
    ];
    return combinedQuestions.reduce((updatedCount, question) => {
      return {
        ...updatedCount,
        [question.type]: updatedCount[question.type] + 1,
      };
    }, questionTypeCount);
  }
  return {
    ...questionTypeCount,
    [question.type]: questionTypeCount[question.type] + 1,
  };
};

const requiredQuestionsCountReducer = (
  currentRequiredQuestionsCount: number,
  question: AllPossibleQuestions
): number => {
  const requiredQuestionsCount = hasRequiredValidator(question) ? 1 : 0;

  if (!isConditionalQuestion(question) && !isReferenceQuestion(question))
    return currentRequiredQuestionsCount + requiredQuestionsCount;

  const numberOfRequiredSubQuestions =
    question.properties.questions?.reduce(
      (acc, question) => acc + requiredQuestionsCountReducer(0, question),
      0
    ) ?? 0;
  return (
    currentRequiredQuestionsCount +
    requiredQuestionsCount +
    numberOfRequiredSubQuestions
  );
};

const getNumberOfQuestionsPerCategory = (questions: AllPossibleQuestions[]) => {
  return questions.reduce(
    (total, question) => {
      if (isConditionalQuestion(question)) {
        const dependentCount = question.properties.questions?.length ?? 0;
        const questionTypeData = determineQuestionTypes(
          question,
          total.questionTypeCount
        );
        return {
          ...total,
          conditionalQuestions: total.conditionalQuestions + 1,
          dependentQuestions: total.dependentQuestions + dependentCount,
          totalQuestions: total.totalQuestions + (dependentCount + 1),
          questionTypeCount: questionTypeData,
          requiredQuestions: requiredQuestionsCountReducer(
            total.requiredQuestions,
            question
          ),
        };
      }

      return {
        ...total,
        questionTypeCount: determineQuestionTypes(
          question,
          total.questionTypeCount
        ),
        normalQuestions: total.normalQuestions + 1,
        totalQuestions: total.totalQuestions + 1,
        requiredQuestions: requiredQuestionsCountReducer(
          total.requiredQuestions,
          question
        ),
      };
    },
    {
      questionTypeCount: {
        attribute: 0,
        field: 0,
        reference: 0,
        text: 0,
        hiddenField: 0,
        tag: 0,
      },
      conditionalQuestions: 0,
      dependentQuestions: 0,
      normalQuestions: 0,
      totalQuestions: 0,
      requiredQuestions: 0,
    }
  );
};

const getNumberOfUsersAndGroups = (selectors: SurveyApprovalAudiences) => {
  const groupLength = getUsersAndGroupsAudience(selectors).groupIds.length;
  const usersLength = getUsersAndGroupsAudience(selectors).userIds.length;
  return groupLength + usersLength;
};

const getNumberOfApproversByType = (approvalFlow: SurveyApprovalFlow) => {
  return {
    fallbackAudience: approvalFlow.fallbackAudience.audiencesIds.length,
    relationship: getRelationshipsAudience(approvalFlow.selectors ?? [])
      .relationships.length,
    usersAndGroups: getNumberOfUsersAndGroups(approvalFlow.selectors ?? []),
    // add this if possible
    traversal: 0,
  };
};

const getChangeApprovalTrackingData = (approvalFlow?: SurveyApprovalFlow) => {
  if (
    !approvalFlow ||
    !approvalFlow.enabled ||
    !hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2)
  )
    return { enabled: false };
  return {
    enabled: true,
    audienceType: approvalFlow.selectors?.map(
      selector => selector.audienceType
    ),
    numberOfApprovers: getNumberOfApproversByType(approvalFlow),
  };
};

export const getSurveyTrackingData = (
  surveyAttributes: MaybePersistedSurvey
) => {
  const { questions, approvalFlow } = surveyAttributes;
  const questionsAnalysis = getNumberOfQuestionsPerCategory(questions);
  const changeApprovalAnalysis = getChangeApprovalTrackingData(approvalFlow);
  return {
    questions: questionsAnalysis,
    changeApproval: changeApprovalAnalysis,
  };
};

export const cloneSurveyAttributes = (survey: APISurveyAttributes) => {
  const clonedSurvey = deepCopyObject(survey);
  clonedSurvey.misc = clonedSurvey.misc || {};
  clonedSurvey.filterSetup =
    clonedSurvey.filterSetup || ({} as SurveyFilterSetupAttributes);
  return clonedSurvey;
};

export const isModelRigid = (
  surveyAttributes: MaybePersistedSurvey
): boolean => {
  if (!surveyAttributes.workspace) return false;
  const workspace = Workspaces.collection.get(surveyAttributes.workspace);

  if (!workspace) return false;

  const model = workspace.getModel();
  if (!model) {
    logMissingModel({
      id: workspace.id,
      rootWorkspace: workspace.id,
      modelTypeName: 'workspace',
    });
    return false;
  }

  return !model.isFlexible();
};

export const getComponentTypeAndModelId = (
  workspaceId?: ArdoqId | null,
  componentTypeName?: string | null
) => {
  if (!workspaceId) return {};

  const workspace = Workspaces.collection.get(workspaceId);
  if (!workspace) return {};

  const model = workspace.getModel();
  if (!model) {
    logMissingModel({
      id: workspace.id,
      rootWorkspace: workspace.id,
      modelTypeName: 'workspace',
    });
    return {};
  }
  const modelTypes = model.getAllTypes();

  return {
    selectedModelId: model.id,
    selectedTypeId: Object.keys(modelTypes).find(
      typeId => modelTypes[typeId].name === componentTypeName
    ),
    modelIsRigid: !model.isFlexible(),
  };
};

export const getUnpublishConfirmationDialog = async (
  survey: MaybePersistedSurvey,
  hasError = false,
  hasPendingApproval: boolean
): Promise<false | ConfirmResult> => {
  if (!isPersistedSurvey(survey)) return false;
  if (!hasFeature(Features.BROADCASTS)) {
    return hasError
      ? confirmUnpublishDueToErrorModal(hasPendingApproval)
      : confirmUnpublishModal(hasPendingApproval);
  }
  const result = await broadcastApi.fetchByContentId(survey._id);
  if (isArdoqError(result)) {
    // TODO[ARD-22935] There doesn’t seem to be any error handling walking up this component tree
    logError(result);
    return false;
  }
  const { broadcastIds } = result;

  if (broadcastIds.length) {
    return hasError
      ? confirmUnpublishDueToErrorWhenBroadcastsModal(hasPendingApproval)
      : confirmUnpublishWhenBroadcastsModal(hasPendingApproval);
  }

  return hasError
    ? confirmUnpublishDueToErrorModal(hasPendingApproval)
    : confirmUnpublishModal(hasPendingApproval);
};

export const doesQuestionHaveDateField = (
  question: AllPossibleQuestions,
  surveyAttributes: MaybePersistedSurvey
) => {
  const { selectedModelId, selectedTypeId } = getComponentTypeAndModelId(
    surveyAttributes.workspace,
    surveyAttributes.componentTypeName
  );
  if (
    (isFieldQuestion(question) || isHiddenFieldQuestion(question)) &&
    selectedModelId &&
    selectedTypeId
  ) {
    const fields = Fields.collection.getByComponentType(
      selectedTypeId,
      selectedModelId
    );

    const field = fields.find(field => {
      // Date range fields are represented as "start_date,end_date" in questionProperties.fieldName
      if (question.properties.fieldName?.includes(',')) {
        const [start, end] = question.properties.fieldName.split(',');
        return field.name() === start || field.name() === end;
      }
      return field.name() === question.properties.fieldName;
    });

    return (
      isDateFieldType(field?.getType()) ||
      isDateRangeFieldType(field?.getType())
    );
  }
  return false;
};

export const currentUserHasNoAdminAccessToWorkspace = (
  permissionsContext: PermissionContext,
  workspace?: ArdoqId | null
) => {
  if (!workspace) return true;
  return !workspaceAccessControlInterface.canAdminWorkspace(
    permissionsContext,
    workspace,
    null
  );
};

export const currentUserHasNoEditAccessToWorkspace = (
  permissionsContext: PermissionContext,
  workspace?: ArdoqId | null
) => {
  if (!workspace) return true;
  return !workspaceAccessControlInterface.canEditWorkspace(
    permissionsContext,
    workspace,
    null
  );
};

export const currentUserHasNoAccessToWorkspace = (
  permissionsContext: PermissionContext,
  workspace?: ArdoqId | null
) => {
  if (!workspace) return true;
  return !workspaceAccessControlInterface.canAccessWorkspace(
    permissionsContext,
    workspace
  );
};

export const hasRequiredValidator = (question: AllPossibleQuestions) =>
  question.validators &&
  question.validators.some(
    (val: SurveyQuestionValidator) => val.type === 'required'
  );

const hasMinimalReferencesValidator = (question: AllPossibleQuestions) =>
  question.validators &&
  question.validators.some(
    (val: SurveyQuestionValidator) =>
      val.type === 'range' && val.min && val.min > 0
  );

export const isRequiredQuestion = (question: AllPossibleQuestions) =>
  hasRequiredValidator(question) || hasMinimalReferencesValidator(question);

export const getSectionStepState = (
  sectionStatus: SurveySectionStatus,
  hasError: boolean,
  isPersistedSurvey: boolean
): StepState => {
  if (isPersistedSurvey && hasError) {
    return StepState.ERROR;
  }
  switch (sectionStatus) {
    case 'initial':
      return StepState.ACTIVE;
    case 'entered':
      return hasError ? StepState.MODIFIED : StepState.DONE;
    case 'left':
      return hasError ? StepState.ERROR : StepState.DONE;
  }
};

const isOutgoingTriple =
  (surveyComponentTypeName: string) => (triple: MetaModelTriple) => {
    return triple.sourceComponentType === surveyComponentTypeName;
  };

const isIncomingTriple =
  (surveyComponentTypeName: string) => (triple: MetaModelTriple) => {
    return triple.targetComponentType === surveyComponentTypeName;
  };

const getTargetFields = (
  fieldsByComponentTypeName: FieldsByComponentTypeName,
  triple: MetaModelTriple
) => {
  return fieldsByComponentTypeName[triple.targetComponentType] ?? [];
};

const getSourceFields = (
  fieldsByComponentTypeName: FieldsByComponentTypeName,
  triple: MetaModelTriple
) => {
  return fieldsByComponentTypeName[triple.sourceComponentType] ?? [];
};

const targetHasApproverField =
  (fieldsByComponentTypeName: FieldsByComponentTypeName) =>
  (triple: MetaModelTriple) => {
    const targetFields = getTargetFields(fieldsByComponentTypeName, triple);
    return targetFields.some(isApproverField);
  };

const sourceHasApproverField =
  (fieldsByComponentTypeName: FieldsByComponentTypeName) =>
  (triple: MetaModelTriple) => {
    const sourceFields = getSourceFields(fieldsByComponentTypeName, triple);
    return sourceFields.some(isApproverField);
  };

export const isApproverField = (field: Field) => {
  return APPROVER_FIELD_TYPES.has(field.type);
};

export const compareRelationships =
  (locale: Locale) =>
  (
    relationshipA: SurveyApprovalRelationship,
    relationshipB: SurveyApprovalRelationship
  ) => {
    const componentTypeA = relationshipA.componentTypeName;
    const componentTypeB = relationshipB.componentTypeName;
    if (componentTypeA === 'Person' && componentTypeB !== 'Person') return -1;
    if (componentTypeB === 'Person' && componentTypeA !== 'Person') return 1;
    return localeCompare(
      getRelationshipIdentifier(relationshipA),
      getRelationshipIdentifier(relationshipB),
      locale
    );
  };

export const getApproverFieldsByComponentTypeName = (
  fieldsByComponentTypeName: FieldsByComponentTypeName,
  componentTypeName: string
) => {
  return (fieldsByComponentTypeName[componentTypeName] ?? []).filter(
    isApproverField
  );
};

export const preselectContactEmailIfItExists = (fields: Field[]) =>
  fields.find(field => field.name === 'contact_email') ? 'contact_email' : null;

export const toSurveyApprovalRelationships = (
  metamodel: MetaModel,
  fieldsByComponentTypeName: FieldsByComponentTypeName,
  workspaceId: ArdoqId | null,
  componentTypeName: string
): SurveyApprovalRelationship[] => {
  if (!workspaceId) return [];
  const outgoingRelationships = metamodel.triples
    .filter(isOutgoingTriple(componentTypeName))
    .filter(targetHasApproverField(fieldsByComponentTypeName))
    .map(triple => {
      const targetFields = getTargetFields(fieldsByComponentTypeName, triple);
      return {
        outgoing: true,
        referenceTypeName: triple.referenceType,
        componentTypeName: triple.targetComponentType,
        emailField: preselectContactEmailIfItExists(targetFields),
      };
    });
  const incomingRelationships = metamodel.triples
    .filter(isIncomingTriple(componentTypeName))
    .filter(sourceHasApproverField(fieldsByComponentTypeName))
    .map(triple => {
      const sourceFields = getSourceFields(fieldsByComponentTypeName, triple);
      return {
        outgoing: false,
        referenceTypeName: triple.referenceType,
        componentTypeName: triple.sourceComponentType,
        emailField: preselectContactEmailIfItExists(sourceFields),
      };
    });

  return [...outgoingRelationships, ...incomingRelationships];
};

export const getFallbackUserAudience = (
  approvalFlow?: SurveyApprovalFlow
): SurveyApprovalFallbackAudience => {
  return (
    approvalFlow?.fallbackAudience ?? {
      audienceType: 'user',
      audiencesIds: [],
    }
  );
};

export const maybeResetApprovalFlowRelationshipSelector = (
  surveyAttributes: MaybePersistedSurvey,
  newAttributes: Partial<MaybePersistedSurvey>
): SurveyApprovalFlow | undefined => {
  const attributeKeys = Object.keys(newAttributes) as Array<
    keyof MaybePersistedSurvey
  >;
  const updatedSurvey = { ...surveyAttributes, ...newAttributes };
  const updatedApprovalFlow = updatedSurvey.approvalFlow;
  // we don't need to reset the audience selectors if:
  // - the change is not related to the workspace or component type
  // - change approvals are disabled
  // - the distributed feature is not enabled
  // - there is no relationship or traversal audience
  if (!isChangeToWorkspaceOrComponentType(attributeKeys)) {
    return updatedApprovalFlow;
  }
  if (!updatedApprovalFlow) return updatedApprovalFlow;
  if (!updatedApprovalFlow.enabled) return updatedApprovalFlow;
  if (!hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2)) {
    return updatedApprovalFlow;
  }
  const selectedAudienceType = updatedApprovalFlow.selectors?.[0]?.audienceType;
  if (!selectedAudienceType) return updatedApprovalFlow;
  if (
    selectedAudienceType !== 'relationships' &&
    selectedAudienceType !== 'traversal'
  ) {
    return updatedApprovalFlow;
  }
  return {
    ...updatedApprovalFlow,
    selectors:
      selectedAudienceType === 'relationships'
        ? [defaultRelationshipsAudience]
        : [defaultTraversalAudience],
  };
};

export const maybeResetNewComponentParentId = (
  surveyAttributes: MaybePersistedSurvey,
  newAttributes: Partial<MaybePersistedSurvey>
) => {
  const attributeKeys = Object.keys(newAttributes) as Array<
    keyof MaybePersistedSurvey
  >;
  if (
    isChangeToWorkspaceOrComponentTypeWithRigidModel(
      surveyAttributes,
      attributeKeys
    )
  ) {
    return null;
  }
  return attributeKeys.includes('newComponentParentId')
    ? newAttributes.newComponentParentId
    : surveyAttributes.newComponentParentId;
};

const isChangeToWorkspaceOrComponentType = (
  attributeKeys: Array<keyof MaybePersistedSurvey>
) =>
  attributeKeys.includes('componentTypeName') ||
  attributeKeys.includes('workspace');

const isChangeToWorkspaceOrComponentTypeWithRigidModel = (
  surveyAttributes: MaybePersistedSurvey,
  attributeKeys: Array<keyof MaybePersistedSurvey>
) =>
  attributeKeys.includes('workspace') ||
  (attributeKeys.includes('componentTypeName') &&
    isModelRigid(surveyAttributes));

export const isReferenceFilterEnabled = (
  filterSetup: SurveyFilterSetupAttributes | null
) => {
  return !!filterSetup?.enabled && filterSetup?.type === 'reference';
};

export const getHasPendingApprovals = (
  survey: MaybePersistedSurvey,
  surveysWithPendingApprovals: Partial<
    Record<ArdoqId, APISurveyPendingApprovals>
  >
) => {
  return Boolean(
    isPersistedSurvey(survey) &&
      survey.approvalFlow?.enabled &&
      (surveysWithPendingApprovals[survey._id]?.total ?? 0) > 0
  );
};
