import UserSettings from './UserSettings';

const DONT_SHOW_AGAIN = 'dont-show-again';

// I intentionally don't export the settings
// use getDontShowAgainSetting and setDontShowAgainSetting
// to have better TS (with enum and boolean flags always)
const dontShowAgainSettings = new UserSettings(DONT_SHOW_AGAIN);

export enum DontShowAgain {
  APPLY_MERGE_INSTANTLY_DIALOG = 'APPLY_MERGE_INSTANTLY_DIALOG',
  BRANCH_OFF_POINT_NOT_UPDATED_ALERT = 'BRANCH_OFF_POINT_NOT_UPDATED_ALERT',
  APPLY_ENTITY_MERGE = 'APPLY_ENTITY_MERGE',
}

export const getDontShowAgainSetting = async (settingKey: DontShowAgain) => {
  if (window.STORYBOOK_ENV) return false;
  return (await dontShowAgainSettings.get<boolean>(settingKey)) || false;
};

export const setDontShowAgainSetting = (
  settingKey: DontShowAgain,
  settingValue: boolean
) => dontShowAgainSettings.set(settingKey, settingValue);
