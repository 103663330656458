import { Subject } from 'rxjs';
import {
  ActionStream,
  collectRoutines,
  subscribeRoutine,
} from '@ardoq/rxbeach';
import { popoverRoutines } from 'components/popover/routines';
import { tagscapeRoutines } from 'tabview/tagscape/routines';
import tagRoutines from 'streams/tags/routines';
import modelRoutines from 'streams/models/routines';
import componentRoutines from 'streams/components/routines';
import componentOptionsRoutines from 'menus/optionsMenu/component/routines';
import referenceRoutines from 'streams/references/routines';
import referenceOptionsRoutines from 'menus/optionsMenu/reference/routines';
import workspaceOptionsRoutines from 'menus/optionsMenu/workspace/routines';
import { dashboardRoutines } from 'streams/dashboards/routines';
import { appContainerRoutines } from 'appContainer/routines';
import integrationsRoutines from 'integrations/routines';
import gridEditor2023Routines from 'gridEditor2023/routines';
import { landingPageRoutines } from 'appContainer/landingPage/routines';
import templateRoutines from 'streams/templates/routines';
import editTemplateRoutines from 'components/AppMainSidebar/editTemplates/routines';
import webhookRoutines from 'admin/orgSettings/webhook$';
import copyWorkspaceRoutines from 'copyWsToOrg/copyws$';
import dynamicFilterRoutines from 'streams/dynamicFilters/routines';
import { metamodelRoutines } from 'streams/metamodels/routines';
import { assetFoldersRoutines } from 'streams/assetFolders/routines';
import { currentUserRoutines } from 'streams/currentUser/routines';
import { assetsRoutines } from 'streams/assets/routines';
import scenarioRoutines from 'streams/scenarios/routines';
import addToPresentationRoutines from 'presentationEditor/presentationChooser/routines';
import workspaceRoutines from 'streams/workspaces/routines';
import workspaceManagerRoutines from 'admin/workspaceOverview/routines';
import { searchRoutines } from 'search/routines';
import queriesRoutines from 'streams/queries/routines';
import parameterOptionsRoutines from 'streams/queries/parameterOptions/routines';
import calculatedFieldOptionsRoutines from 'streams/queries/calculatedFieldOptions/routines';
import { restoreDeletedRoutines } from 'restoreDeleted/routines';
import { assetsBrowserRoutines } from 'components/assetsBrowserDialog/routines';
import { logError } from '@ardoq/logging';
import { scopeRoutines } from 'scope/routines';
import { Features, hasFeature } from '@ardoq/features';
import userRoutines from 'admin/user/routines';
import { currentUserPermissionRoutines } from 'streams/currentUserPermissions/routines';
import { workspaceWizardRoutines } from 'workspaceWizard/fromTemplate/routines';
import syncRoutines from 'sync/routines';
import globalFieldManagerRoutines from 'fields/globalFieldManager/routines';
import presentationEditorRoutines from 'presentationEditor/routines';
import presentationSidebarRoutines from 'components/presentationSidebar/routines';
import searchResultTableRoutines from 'components/SearchResultTable/routines';
import { resourcePermissionRoutines } from 'resourcePermissions/routines';
import { permissionGroupRoutines } from 'admin/accessControl/PermissionGroups/streams/routines';
import { createPermissionGroupRoutines } from 'admin/accessControl/PermissionGroups/components/CreatePermissionGroupModal/routines';
import { removeUsersRoutines } from 'admin/accessControl/PermissionGroups/components/RemoveUsersModal/routines';
import { scenarioRelatedRoutines } from 'appContainer/MainAppModule/MainAppModuleSidebar/scenarioRelated/navigator/routines';
import { appModuleSidebarRoutines } from 'appContainer/MainAppModule/MainAppModuleSidebar/routines';
import navigationRoutines from 'router/navigationRoutines';
import broadcastRoutines from 'broadcasts/routines';
import broadcastOverviewRoutines from 'broadcasts/broadcastOverview/routines';
import broadcastComponentPreviewRoutines from 'broadcasts/broadcastBuilder/broadcastContentForm/componentPreview/routines';
import { mergeScopeRoutines } from 'scope/merge/routines';
import entityMergeRoutines from 'components/EntityMerge/routines';
import broadcastAudiencePreviewRoutines from 'broadcasts/broadcastBuilder/audiencePreview/routines';
import broadcastWorkspaceAudienceDialogRoutines from 'broadcasts/broadcastBuilder/broadcastAudienceForm/dialogs/workspaceAudienceDialog/routines';
import broadcastPredefinedAudienceDialogRoutines from 'broadcasts/broadcastBuilder/broadcastAudienceForm/dialogs/predefinedAudienceDialog/routines';
import broadcastGremlinAudienceDialogRoutines from 'broadcasts/broadcastBuilder/broadcastAudienceForm/dialogs/gremlinAudienceDialog/routines';
import salesDemoTourLauncherRoutines from 'salesDemoTourLauncher/routines';
import viewpointsRoutines from 'viewpoints/routines';
import { documentBrowserRoutines } from 'components/DocumentBrowser/routines';
import newWorkspaceModelEditorRoutines from 'workspaceModelEditor/streams/routines';
import appModelStateEditRoutines from 'appModelStateEdit/routines';
import componentFabRoutines from 'views/mainApp/fab/componentFab/routines';
import referenceFabRoutines from 'views/mainApp/fab/referenceFab/routines';
import manageFieldsRoutines from 'appModelStateEdit/manageFields/routines';
import manageReferenceTypesRoutines from 'appModelStateEdit/manageReferenceTypes/routines';
import addFieldToWorkspaceRoutines from 'appModelStateEdit/propertiesEditor/addField/addFieldToWorkspace/routines';
import addFieldToGridEditorRoutines from 'appModelStateEdit/propertiesEditor/addField/gridEditor/routines';
import globalFieldsRoutines from 'globalFields/routines';
import invitationsAPIRoutines from 'streams/invitations/routines';
import { loadDefaultPerspectiveOnWorkspaceChanged as perspectivesRoutines } from 'perspectives/routines';
import scimManagementRoutines from 'admin/orgMembers/SCIMManagement/routines';
import { quickPerspectivesFilterRoutines } from 'quickPerspectives/routines';
import ipAllowlistRoutines from 'admin/manageIPAllowlist/ipAllowlist$/routines';
import appLayoutRoutines from 'appLayout/appLayoutRoutines';
import privilegeRoutines from 'privileges/routines';
import roleRoutines from 'roles/routines';
import surveyEditorRoutines from 'surveyAdmin/SurveyEditor/streams/routines';
import surveyAdminRoutines from 'surveyAdmin/streams/routines';
import surveyChangeApprovalRoutines from 'surveyAdmin/ChangeApproval/routines';
import surveyResponseLogRoutines from 'surveyAdmin/SurveyOverview/ResponseLog/routines';
import surveyAudienceTraversalDrawerRoutines from 'surveyAdmin/ChangeApproval/SurveyBuilderElements/AudienceTraversalDrawer/routines';
import dashboardV2Routines from './dashboard/routines';
import dashboardBuilderRoutines from './dashboard/DashboardBuilder/routines';
import perspectiveEditorRoutines from './streams/perspectiveEditorData/perspectiveEditorRoutines';
import SSOAttributeMappingRoutines from 'admin/ssoAttributeMapping/routines';
import { handleLoggedOutRoutine } from 'authentication/routines';
import { viewpointBuilderRoutines } from 'viewpointBuilder/routines';
import contextRoutines from 'streams/context/routines';
import dashboardColorThemeRoutines from 'dashboard/DashboardColorThemeSettings/routines';
import viewpointsNavigation from 'viewpoints/navigation/routines';
import { viewSettingsRoutines } from './viewSettings/routines';
import { viewpointRoutines } from 'streams/traversals/routines';
import { componentSearchRoutines } from 'viewpointBuilder/selectContextTab/componentSearchRoutines';
import { requestAccessRoutines } from 'resourcePermissions/requestAccess/routines';
import inAppNotificationsRoutines from 'streams/inAppNotifications/routines';
import { subdivisionRoutines } from 'streams/subdivisions/routines';
import { groupsRoutines } from 'subdivisionEditor/Steps/permissionsConfiguration/zones/groups$/routines';
import { permissionByZoneRoutines } from 'subdivisionEditor/Steps/permissionsConfiguration/zones/permissions$/routines';
import { componentTypesRoutines } from 'subdivisionEditor/Steps/permissionsConfiguration/zones/componentTypes$/routines';
import auditLogRoutines from 'auditLog/routines';
import { filterRoutines } from 'streams/filters/routines';
import { proteanEditModeRoutines } from 'tabview/proteanDiagram/intentionalLayout/routines';
import migrationChecklistRoutines from 'admin/migrationChecklist/routines';
import tokensRoutines from 'admin/user/TokenView/routines';
import { viewpointNavigatorRoutines } from 'viewpointNavigator/viewpointNavigatorRoutines';
import serviceAccountsRoutines from 'admin/serviceAccounts/routines';
import { manageMembershipModalRoutines } from 'subdivisionEditor/manageMembershipModal/routines';
import { detailsDrawerRoutines } from 'appLayout/ardoqStudio/detailsDrawer/routines';
import { featureSettingsRoutines } from 'admin/featureSettings/routines';
import { bestPracticeAssistantRoutines } from 'bestPracticeAssistant/routines';
import { zonesViewStateRoutines } from 'subdivisionEditor/Steps/permissionsConfiguration/zones/routines';
import useCaseRoutines from 'useCases/routines';
import useCaseManagementRoutines from 'admin/useCase/routines';
import useCaseEditorRoutines from 'admin/useCase/UseCaseEditor/routines';
import { viewInferenceRoutines } from 'traversals/viewInference/routines';
import { componentOverviewPageRoutines } from 'appContainer/componentOverviewPage/routines';
import { bindWorkspacesRoutines } from 'subdivisionEditor/Steps/BindWorkspaces/routines';
import unsavedStateRoutines from 'streams/unsavedState/routines';
import quickSearchRoutines from 'search/quickSearch/routines';
import subdivisionEditorRoutines from 'subdivisionEditor/subdivisionEditorViewModel$/routines';
import { reportRoutines } from 'report/routines';
import subdivisionCreationContextRoutines from 'subdivisions/subdivisionCreationContext/routines';
import { surveyRoutines } from 'streams/surveys/routines';
import viewPaneHeaderRoutines from 'appLayout/ardoqStudio/viewPaneHeader/routines';
import { traversalPreviewRoutines } from 'components/TraversalPreview/traversalPreviewRoutine';
import { assetsBrowser2024Routines } from './components/AssetsBrowser2024/assetsBrowser2024Routines';
import { createReferenceRoutines } from 'createReferences2024/createReferencesRoutines';
import { reportsRoutines } from 'streams/reports/routines';
import { loadedStateRoutines } from 'loadedState/loadedStateRoutines';
import { slidesRoutines } from 'streams/slides/routines';
import { usersRoutines } from 'streams/orgUsers/routine';
import reportBuilderRoutines from 'report/ReportBuilder/routines';
import { presentationRoutines } from 'streams/presentations/routines';
import assetsUsageRoutines from 'assets/usage/routines';
import { viewpointsWidgetRoutines } from './homePage/viewpointsWidget/routines';
import organizationRoutines from 'streams/organizations/routines';
import oauthRoutines from 'oauth/routines';
import externalDocumentsRoutine from 'externalDocuments/routines';
import subdivisionOverviewRoutines from 'subdivisionEditor/SubdivisionOverview/routines';
import { inventoryRoutines } from './inventory/inventoryRoutines';
import entityBrowserRoutines from './components/EntityBrowser/streams/routines';
import { bookmarkStreamRoutines } from './streams/bookmarks/routines';
import { bookmarkRoutines } from './bookmarks/routines';
import { userAssetsOverviewRoutines } from 'admin/accessControl/UserProfilePage/userAssetsOverview/assetsOverviewRoutines';
import { ephemeralNotificationRoutines } from 'ephemeralNotification/routines';

const error$ = new Subject<any>();

/**
 * Early routines are started before application data is loaded.
 */
const earlyRoutines = collectRoutines(syncRoutines, handleLoggedOutRoutine);

const getRoutines = () => {
  const routines = [
    appContainerRoutines,
    resourcePermissionRoutines,
    assetFoldersRoutines,
    assetsBrowserRoutines,
    auditLogRoutines,
    currentUserRoutines,
    assetsRoutines,
    calculatedFieldOptionsRoutines,
    componentRoutines,
    componentOptionsRoutines,
    referenceRoutines,
    referenceOptionsRoutines,
    workspaceOptionsRoutines,
    currentUserPermissionRoutines,
    reportsRoutines,
    dashboardRoutines,
    documentBrowserRoutines,
    dynamicFilterRoutines,
    editTemplateRoutines,
    gridEditor2023Routines,
    integrationsRoutines,
    webhookRoutines,
    copyWorkspaceRoutines,
    landingPageRoutines,
    metamodelRoutines,
    modelRoutines,
    userRoutines,
    invitationsAPIRoutines,
    parameterOptionsRoutines,
    permissionGroupRoutines,
    createPermissionGroupRoutines,
    removeUsersRoutines,
    popoverRoutines,
    queriesRoutines,
    restoreDeletedRoutines,
    reportRoutines,
    scenarioRelatedRoutines,
    searchResultTableRoutines,
    searchRoutines,
    serviceAccountsRoutines,
    slidesRoutines,
    tagRoutines,
    tagscapeRoutines,
    templateRoutines,
    tokensRoutines,
    workspaceRoutines,
    workspaceManagerRoutines,
    workspaceWizardRoutines,
    globalFieldManagerRoutines,
    navigationRoutines,
    ...(hasFeature(Features.SCENARIOS_BETA)
      ? [
          scopeRoutines,
          scenarioRoutines,
          appModuleSidebarRoutines,
          mergeScopeRoutines,
        ]
      : []),
    ...(hasFeature(Features.BROADCASTS)
      ? [
          broadcastRoutines,
          broadcastOverviewRoutines,
          broadcastComponentPreviewRoutines,
          broadcastAudiencePreviewRoutines,
          broadcastWorkspaceAudienceDialogRoutines,
          broadcastPredefinedAudienceDialogRoutines,
          broadcastGremlinAudienceDialogRoutines,
        ]
      : []),
    ...(hasFeature(Features.SALES_DEMO_TOUR_LAUNCHER)
      ? [salesDemoTourLauncherRoutines]
      : []),
    viewpointsRoutines,
    entityMergeRoutines,
    newWorkspaceModelEditorRoutines,
    appModelStateEditRoutines,
    manageFieldsRoutines,
    manageReferenceTypesRoutines,
    addFieldToWorkspaceRoutines,
    addFieldToGridEditorRoutines,
    globalFieldsRoutines,
    componentFabRoutines,
    ...(hasFeature(Features.REFERENCE_FAB_PROTOTYPE)
      ? [referenceFabRoutines]
      : []),
    perspectivesRoutines,
    quickPerspectivesFilterRoutines,
    filterRoutines,
    ...(hasFeature(Features.SCIM_PROVISIONING) ? [scimManagementRoutines] : []),
    ...(hasFeature(Features.IP_ALLOWLIST_MANAGEMENT)
      ? [ipAllowlistRoutines]
      : []),
    appLayoutRoutines,
    privilegeRoutines,
    roleRoutines,
    ...(hasFeature(Features.SURVEYS)
      ? [
          surveyRoutines,
          surveyAdminRoutines,
          surveyResponseLogRoutines,
          surveyEditorRoutines,
        ]
      : []),
    ...(hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2)
      ? [surveyChangeApprovalRoutines, surveyAudienceTraversalDrawerRoutines]
      : []),
    dashboardV2Routines,
    dashboardBuilderRoutines,
    perspectiveEditorRoutines,
    SSOAttributeMappingRoutines,
    ...(hasFeature(Features.SUPPORT_LARGE_DATASETS)
      ? [
          viewpointBuilderRoutines,
          componentSearchRoutines,
          viewpointRoutines,
          detailsDrawerRoutines,
          traversalPreviewRoutines,
          loadedStateRoutines,
        ]
      : []),
    contextRoutines,
    dashboardColorThemeRoutines,
    viewpointsNavigation,
    viewSettingsRoutines,
    requestAccessRoutines,
    inAppNotificationsRoutines,
    ...(hasFeature(Features.PERMISSION_ZONES)
      ? [
          subdivisionRoutines,
          componentTypesRoutines,
          groupsRoutines,
          permissionByZoneRoutines,
          manageMembershipModalRoutines,
          zonesViewStateRoutines,
          subdivisionEditorRoutines,
          subdivisionOverviewRoutines,
          bindWorkspacesRoutines,
          subdivisionCreationContextRoutines,
        ]
      : []),
    proteanEditModeRoutines,
    migrationChecklistRoutines,
    viewpointNavigatorRoutines,
    ...(hasFeature(Features.NEW_CORE_JOURNEY)
      ? [componentOverviewPageRoutines]
      : []),
    ...(hasFeature(Features.AZURE_OPENAI_BEST_PRACTICE_ASSISTANT)
      ? [bestPracticeAssistantRoutines]
      : []),
    featureSettingsRoutines,
    useCaseRoutines,
    useCaseManagementRoutines,
    useCaseEditorRoutines,
    ...(hasFeature(Features.AZURE_OPENAI_VIEW_INFERENCE)
      ? [viewInferenceRoutines]
      : []),
    unsavedStateRoutines,
    quickSearchRoutines,
    viewPaneHeaderRoutines,
    assetsBrowser2024Routines,
    createReferenceRoutines,
    reportBuilderRoutines,
    usersRoutines,
    ...(hasFeature(Features.PRESENTATIONS)
      ? [
          presentationRoutines,
          presentationEditorRoutines,
          presentationSidebarRoutines,
          addToPresentationRoutines,
        ]
      : []),
    ...assetsUsageRoutines,
    viewpointsWidgetRoutines,
    organizationRoutines,
    oauthRoutines,
    externalDocumentsRoutine,
    ephemeralNotificationRoutines,
    ...(hasFeature(Features.INVENTORY) ? [inventoryRoutines] : []),
    entityBrowserRoutines,
    ...(hasFeature(Features.BOOKMARKS)
      ? [bookmarkStreamRoutines, bookmarkRoutines]
      : []),
    userAssetsOverviewRoutines,
  ];

  return collectRoutines(...routines);
};

export function startEarlyRoutines(action$: ActionStream) {
  const routineSubscription = subscribeRoutine(action$, earlyRoutines, error$);
  const errorSubscription = error$.subscribe(error =>
    logError(error, 'Routine error')
  );

  if (module.hot) {
    module.hot.dispose(() => {
      routineSubscription.unsubscribe();
      errorSubscription.unsubscribe();
    });
  }
}

/**
 * Must be called after `startEarlyRoutines`, but no check is made to ensure this.
 */
export default function startRoutines(action$: ActionStream) {
  const routineSubscription = subscribeRoutine(action$, getRoutines(), error$);

  if (module.hot) {
    module.hot.dispose(() => {
      routineSubscription.unsubscribe();
    });
  }
}
