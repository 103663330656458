import { reducer } from '@ardoq/rxbeach';
import {
  QuickSearchStreamState,
  ResultInState,
  ResultsPayload,
  SearchParamsPayload,
} from './types';
import {
  resetQuickSearch,
  showSearchBar,
  lockResultItem,
  setResults,
  setSearchParams,
  performSearch,
  previewResultItem,
} from './actions';
import {
  mapFuzzySortResultsToNormalizedResults,
  quickSearchFilter,
  quickSearchFormatter,
} from './dataTransformation';
import { QuickSearchOptions, SearchBy } from '@ardoq/api-types';
import fuzzysort from 'fuzzysort';

export const MAX_RESULTS = 100;

const initialOptions: QuickSearchOptions = {
  searchBy: SearchBy.NAME_DESCRIPTION,
  customFieldName: '_id',
  isWorkspace: true,
  isComponent: true,
  isReference: true,
  useOpenWorkspaces: undefined,
};

export const defaultState: QuickSearchStreamState = {
  isActive: false,
  query: '',
  options: initialOptions,
  lockedResultItem: null,
  previewedResultItem: null,
  isLoading: false,
  total: 0,
  results: null,
};

const setIsSearchBarActive = (
  state: QuickSearchStreamState,
  isActive: boolean
): QuickSearchStreamState => {
  return {
    ...state,
    isActive,
  };
};

const setIsSearching = (
  state: QuickSearchStreamState
): QuickSearchStreamState => {
  return {
    ...state,
    isActive: true,
    isLoading: true,
  };
};

const updateSearchParams = (
  state: QuickSearchStreamState,
  { query, options }: SearchParamsPayload
): QuickSearchStreamState => {
  return {
    ...state,
    query,
    options: {
      ...state.options,
      ...options,
    },
  };
};

const setPreviewedResultItem = (
  state: QuickSearchStreamState,
  previewedResultItem: ResultInState | null
): QuickSearchStreamState => {
  return {
    ...state,
    previewedResultItem,
  };
};

const setLockedResultItem = (
  state: QuickSearchStreamState,
  lockedResultItem: ResultInState | null
): QuickSearchStreamState => {
  return {
    ...state,
    lockedResultItem,
  };
};

const workspaceAndComponentKeys = ['name', 'description'];
const referenceKeys = ['displayText', 'typeName'];
const setAndFilterResults = (
  state: QuickSearchStreamState,
  { total, results }: ResultsPayload
): QuickSearchStreamState => {
  const { query, options } = state;
  const filteredResults = (results ?? [])
    .filter(quickSearchFilter)
    .map(
      quickSearchFormatter(query, options.customFieldName, options.searchBy)
    );

  const attributeKeys = !options.isReference
    ? workspaceAndComponentKeys
    : [...workspaceAndComponentKeys, ...referenceKeys];
  const fieldKey = ['fieldValue'];

  const highlightedResults = fuzzysort.go(state.query, filteredResults, {
    threshold: 0.4,
    limit: MAX_RESULTS, // Don't return more results than this
    all: false, // If true, returns all results for an empty search
    keys:
      options.searchBy === SearchBy.NAME_DESCRIPTION ? attributeKeys : fieldKey,
  });

  return {
    ...state,
    total,
    results: mapFuzzySortResultsToNormalizedResults(
      options.searchBy,
      highlightedResults
    ),
    isLoading: false,
  };
};

const resetQuickSearchState = (
  state: QuickSearchStreamState
): QuickSearchStreamState => {
  return {
    ...state,
    ...defaultState,
  };
};

export const reducers = [
  reducer(showSearchBar, setIsSearchBarActive),
  reducer(performSearch, setIsSearching),
  reducer(setSearchParams, updateSearchParams),
  reducer(previewResultItem, setPreviewedResultItem),
  reducer(lockResultItem, setLockedResultItem),
  reducer(setResults, setAndFilterResults),
  reducer(resetQuickSearch, resetQuickSearchState),
];
