import styled from 'styled-components';
import { Icon, IconName } from '@ardoq/icons';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  addAdvancedSearchCondition,
  expandAdvancedSearchCondition,
  removeAdvancedSearchCondition,
  toggleAdvancedSearchCondition,
  toggleIncomingReferences,
  toggleOutgoingReferences,
  updateAdvancedSearchCondition,
  updateAdvancedSearchQuery,
} from './actions';
import CurrentContentConditions from './CurrentContentConditions';
import AdvancedQueryControls from '../AdvancedQueryControls';
import { AdvancedSearchConditionStreamShape } from './types';
import advancedSearchConditionByReference$ from './advancedSearchConditionByReference$';
import { GhostButton } from '@ardoq/button';
import { SearchContext, getSearchContextRule } from '@ardoq/query-builder';
import { QueryBuilderWithSuggestionsLoaders } from 'search/QueryBuilderWithSuggestionsLoaders';
import { composeBroadcastReferencesFilterGroups } from '@ardoq/query-builder';
import { s8 } from '@ardoq/design-tokens';
import { BooleanOperator, QueryBuilderSubquery } from '@ardoq/api-types';
import Fields from 'collections/fields';
import CollapsedAdvancedSearchCondition from '../CollapsedAdvancedSearchCondition';
import { Stack } from '@ardoq/layout';

const Wrapper = styled.div`
  display: flex;
`;

const QueryBuilderWrapper = styled.div`
  border: 1px solid #d6d9e1;
  width: 100%;
  margin-left: ${s8};
  & > div:nth-child(2) {
    border: none;
  }
`;

const AddCustomConditionButton = () => {
  return (
    <GhostButton
      onClick={() => dispatchAction(addAdvancedSearchCondition())}
      dataTestId="reference-filter-button"
    >
      <Icon iconName={IconName.ADD} style={{ marginRight: s8 }} />
      Components references
    </GhostButton>
  );
};

const AdvancedSearchConditionByReference = ({
  isEnabled,
  isChecked,
  isExpanded,
  advancedSearchCondition,
}: AdvancedSearchConditionStreamShape) => {
  const { advancedQuery } = advancedSearchCondition;
  if (!isEnabled) {
    return (
      <Wrapper>
        <AddCustomConditionButton />
      </Wrapper>
    );
  }
  return (
    <Stack gap="xsmall">
      <CollapsedAdvancedSearchCondition
        entityType="references"
        isChecked={isChecked}
        isExpanded={isExpanded}
        toggleAdvancedSearchCondition={() =>
          dispatchAction(toggleAdvancedSearchCondition(advancedSearchCondition))
        }
        removeAdvancedSearchCondition={() =>
          dispatchAction(removeAdvancedSearchCondition())
        }
        expandAdvancedSearchCondition={newIsExpanded =>
          dispatchAction(expandAdvancedSearchCondition(newIsExpanded))
        }
      />
      {isExpanded && (
        <QueryBuilderWrapper data-test-id="broadcast-reference-filter-section">
          <CurrentContentConditions
            outgoingReferencesSelected={
              advancedSearchCondition.outgoingReferencesSelected
            }
            incomingReferencesSelected={
              advancedSearchCondition.incomingReferencesSelected
            }
            handleOutgoingReferencesClick={() => {
              dispatchAction(toggleOutgoingReferences());
            }}
            handleIncomingReferencesClick={() => {
              dispatchAction(toggleIncomingReferences());
            }}
          />
          <QueryBuilderWithSuggestionsLoaders
            includeContextSelect
            contextSelectIsReadOnly
            query={{
              condition: BooleanOperator.AND,
              rules: [
                getSearchContextRule(SearchContext.REFERENCE),
                advancedQuery as QueryBuilderSubquery,
              ],
            }}
            getFilterGroups={({ searchContext }) =>
              composeBroadcastReferencesFilterGroups(
                searchContext,
                Fields.collection.toJSON()
              )
            }
            onChange={({ isValid, subquery }) => {
              if (isValid) {
                dispatchAction(
                  updateAdvancedSearchQuery(subquery as QueryBuilderSubquery)
                );
                dispatchAction(
                  updateAdvancedSearchCondition(subquery.condition)
                );
              }
            }}
          />
          <AdvancedQueryControls
            onClick={() => dispatchAction(expandAdvancedSearchCondition(false))}
          />
        </QueryBuilderWrapper>
      )}
    </Stack>
  );
};

export default connect(
  AdvancedSearchConditionByReference,
  advancedSearchConditionByReference$
);
