import { ButtonType } from '@ardoq/button';
import {
  ButtonGroup,
  DangerButton,
  SecondaryButton,
  GhostButton,
} from '@ardoq/button';
import { colors } from '@ardoq/design-tokens';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { Checkbox, SearchInput, Switch } from '@ardoq/forms';
import { Icon, IconName } from '@ardoq/icons';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import {
  AnimatedModalDialog,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalSize,
} from '@ardoq/modal';
import { Island } from '@ardoq/page-layout';
import { PaginationBar } from '@ardoq/pagination';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { Link, Text } from '@ardoq/typography';
import styled from 'styled-components';
import {
  DeleteModalProps,
  WorkspaceOverviewManagementHeaderProps,
  WorkspaceOverviewManagementProps,
  WorkspaceOverviewManagementTableProps,
} from './types';

const SelectionCount = (props: { count: number }) => {
  return (
    <Text>
      <strong>{props.count}</strong> selected
    </Text>
  );
};

const WorkspaceOverviewManagementHeader = (
  props: WorkspaceOverviewManagementHeaderProps
) => {
  return (
    <FlexBox width="full" align="center" gap="medium" paddingBottom="small">
      <SearchInput
        autoFocus
        placeholder={props.searchPlaceholder}
        value={props.searchQuery}
        onValueChange={newSearchQuery =>
          props.onSearchQueryChange(newSearchQuery)
        }
      />
      <Box
        width="full"
        style={{
          visibility:
            props.numberOfSelectedWorkspaces > 0 ? 'visible' : 'hidden',
        }}
      >
        <FlexBox justify="end" align="center" width="full">
          <FlexBox
            justify="start"
            align="center"
            gap="small"
            paddingX="large"
            paddingY="small"
            borderRadius="r8"
            backgroundColor="surfaceDefaultSubtle"
          >
            <SelectionCount count={props.numberOfSelectedWorkspaces} />
            {props.showSelectAllWorkspaces && (
              <Link hideIcon onClick={props.selectAllWorkspaces.onClick}>
                {props.selectAllWorkspaces.text}
              </Link>
            )}
            <Switch
              name="Toggle multi-select"
              isChecked={props.areAnyGremlinEnabled}
              onChange={props.onToggleAll}
              label={props.labels.gremlin}
            />
            <GhostButton onClick={props.onMultiDelete}>
              <Icon iconName={IconName.DELETE} />
              {props.labels.delete}
            </GhostButton>
            <GhostButton onClick={props.onClearSelection}>
              {props.labels.cancel}
            </GhostButton>
          </FlexBox>
        </FlexBox>
      </Box>
    </FlexBox>
  );
};

const TableContainer = styled.div`
  border: 1px solid ${colors.borderAction};
  border-radius: 16px;
  overflow: hidden;
`;

const WorkspaceOverviewManagementTable = (
  props: WorkspaceOverviewManagementTableProps
) => (
  <TableContainer>
    <DatasourceTable
      dataSource={props.rows}
      components={newTableTheme}
      fixedHeader
      style={{
        maxHeight: '60em',
        contain: 'paint',
      }}
      columns={[
        {
          headerStyle: {
            width: 0,
          },
          headerRender: () => (
            <Checkbox
              isChecked={props.selection === 'all'}
              isMixed={props.selection === 'some'}
              onChange={props.onToggleAllChange}
            />
          ),
          dataIndex: 'selected',
          valueRender: (selected, row) => {
            return (
              <Checkbox
                isChecked={selected}
                onChange={row.onSelectedChange}
                onShiftClick={row.onShiftClick}
              />
            );
          },
        },
        {
          headerStyle: {
            cursor: 'pointer',
          },
          title: props.columns.name.label,
          dataIndex: 'name',
          sortOrder: props.columns.name.sortOrder,
          onCellClick: row => row.onSelectedChange(),
          onShiftClick: row => row.onShiftClick(),
          onHeaderClick: props.columns.name.onSortOrderChange,
          valueRender: (name, row) => {
            return (
              <Link hideIcon onClick={row.onNameClick}>
                {name}
              </Link>
            );
          },
        },
        {
          headerStyle: {
            textAlign: 'right',
            width: 200, // Make it as small as possible
            cursor: 'pointer',
          },
          cellStyle: { textAlign: 'right' },
          title: props.columns.componentCount.label,
          dataIndex: 'componentCount',
          sortOrder: props.columns.componentCount.sortOrder,
          onCellClick: row => row.onSelectedChange(),
          onShiftClick: row => row.onShiftClick(),
          onHeaderClick: props.columns.componentCount.onSortOrderChange,
        },
        {
          headerStyle: {
            textAlign: 'right',
            width: 190, // Make it as small as possible
            cursor: 'pointer',
          },
          cellStyle: { textAlign: 'right' },
          title: props.columns.referenceCount.label,
          dataIndex: 'referenceCount',
          sortOrder: props.columns.referenceCount.sortOrder,
          onCellClick: row => row.onSelectedChange(),
          onShiftClick: row => row.onShiftClick(),
          onHeaderClick: props.columns.referenceCount.onSortOrderChange,
        },
        {
          headerStyle: {
            textAlign: 'center',
            width: 120, // Make it as small as possible
            cursor: 'pointer',
          },
          cellStyle: { textAlign: 'center' },
          title: props.columns.gremlinEnabled.label,
          dataIndex: 'gremlinEnabled',
          sortOrder: props.columns.gremlinEnabled.sortOrder,
          onCellClick: row => row.onSelectedChange(),
          onShiftClick: row => row.onShiftClick(),
          onHeaderClick: props.columns.gremlinEnabled.onSortOrderChange,
          valueRender: (gremlinEnabled, row) => {
            return (
              <Switch
                name={row.id}
                isChecked={gremlinEnabled}
                onChange={row.onGremlinEnabledChange}
              />
            );
          },
        },
        {
          headerStyle: {
            width: 160, // Make it as small as possible
            cursor: 'pointer',
          },
          title: props.columns.lastUpdatedAt.label,
          dataIndex: 'lastUpdatedAt',
          sortOrder: props.columns.lastUpdatedAt.sortOrder,
          onCellClick: row => row.onSelectedChange(),
          onShiftClick: row => row.onShiftClick(),
          onHeaderClick: props.columns.lastUpdatedAt.onSortOrderChange,
        },
        {
          headerStyle: {
            width: 0, // Make it as small as possible
          },
          cellStyle: { textAlign: 'center' },
          dataIndex: 'actions',
          valueRender: actions => {
            return (
              <ButtonWithDropdown
                {...actions}
                iconName={IconName.MORE_VERT}
                buttonType={ButtonType.GHOST}
              />
            );
          },
        },
      ]}
    />
  </TableContainer>
);

export const WorkspaceOverviewManagement = (
  props: WorkspaceOverviewManagementProps
) => (
  <Island title="Workspace overview">
    <Stack gap="small">
      <WorkspaceOverviewManagementHeader {...props.header} />
      <WorkspaceOverviewManagementTable {...props.table} />
      <PaginationBar {...props.pagination} />
    </Stack>
    <DeleteModal {...props.deleteModal} />
  </Island>
);

const DeleteModal = (props: DeleteModalProps) => (
  <AnimatedModalDialog
    isOpen={props.isOpen}
    onClickOutside={props.onCancelClick}
  >
    <ModalContainer modalSize={ModalSize.S}>
      <ModalHeader onCloseButtonClick={props.onCancelClick}>
        {props.title}
      </ModalHeader>
      <ModalBody>
        <Text variant="text1">
          <Box padding="large">{props.message}</Box>
        </Text>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <FlexBox gap="small">
            <SecondaryButton onClick={props.onCancelClick}>
              Cancel
            </SecondaryButton>
            <DangerButton onClick={props.onConfirmClick}>Delete</DangerButton>
          </FlexBox>
        </ButtonGroup>
      </ModalFooter>
    </ModalContainer>
  </AnimatedModalDialog>
);
