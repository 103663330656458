import { useEffect, useState } from 'react';
import EntryPointForm from './EntryPointForm';
import { Label } from '@ardoq/forms';
import { APIDiscoverViewpointAttributes, EntryPoint } from '@ardoq/api-types';
import { deriveNewEntryPoints, hasAmbiguousEntryPoints } from '../../utils';
import { isEqual } from 'lodash';
import { Description } from './atoms';
import { GhostButton } from '@ardoq/button';
import { ChevronDownIcon, ChevronUpIcon } from '@ardoq/icons';
import { s16, s8 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';

const Wrapper = styled.div`
  margin-top: ${s8};
`;

const EntryPointsWrapper = styled.div`
  margin: ${s16} 0 0 ${s16};
  width: 448px;
`;

const EntryPointsForm = () => {
  const [showEntryPointsForm, setShowEntryPointsForm] = useState(false);
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const {
    field: { onChange },
  } = useController({ name: 'entryPoints' });
  const [steps, entryPoints] = watch([
    'dynamicGroupedTraversals',
    'entryPoints',
  ]);

  // Update entry points when steps update
  useEffect(() => {
    const currentEntryPoints = entryPoints;
    const newSteps = steps;
    const newEntryPoints = deriveNewEntryPoints(currentEntryPoints, newSteps);
    if (isEqual(currentEntryPoints, newEntryPoints)) return;
    onChange(newEntryPoints as EntryPoint[]);
  }, [steps, entryPoints, onChange]);

  if (!hasAmbiguousEntryPoints(steps, entryPoints)) {
    return null;
  }
  return (
    <Wrapper>
      <GhostButton onClick={() => setShowEntryPointsForm(!showEntryPointsForm)}>
        Set entry point{' '}
        {showEntryPointsForm ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </GhostButton>
      {showEntryPointsForm && (
        <EntryPointsWrapper>
          <Label>Entry Points</Label>
          <Description>
            The following component types are included in multiple steps. Please
            select which step should be performed first for each of these types.
          </Description>
          {entryPoints.map((entryPoint, index) => (
            <EntryPointForm
              key={entryPoint[0]}
              entryPoint={entryPoint}
              steps={steps}
              onChange={currentEntryPoint => {
                onChange([
                  ...entryPoints.slice(0, index),
                  currentEntryPoint,
                  ...entryPoints.slice(index + 1),
                ]);
              }}
            />
          ))}
        </EntryPointsWrapper>
      )}
    </Wrapper>
  );
};

export default EntryPointsForm;
