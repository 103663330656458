import { DirectedTripleWithFilters, Path } from '@ardoq/api-types';
import { isEqual, partition } from 'lodash';

type PathIndex = number;
type MaybeBoundTriple = DirectedTripleWithFilters & {
  bound?: PathIndex[];
};

const hasRequiredTriple = (path: DirectedTripleWithFilters[]) =>
  path.some(triple => triple.qualifier === 'required');

// compares two paths and returns the number of triples that are overlapping
const getPathOverlapLength = (path1: Path, path2: Path): number =>
  path1.findIndex((value, index) => !isEqual(value, path2[index]));

const bindTriplesInPath = (
  path: MaybeBoundTriple[],
  numberOfTriplesToBind: number,
  bindToPathAtIndex: number
): MaybeBoundTriple[] =>
  path.map((triple, i) => {
    // -1 because length vs. index
    if (i > numberOfTriplesToBind - 1) return triple;
    return { ...triple, bound: [...(triple.bound || []), bindToPathAtIndex] };
  });

// First splits the array of paths into two arrays, one with paths that contain the required triple and one without.
// Then iterates over non-required paths and binds any required triples.
export const getPathsWithDependencies = (
  paths: DirectedTripleWithFilters[][]
): MaybeBoundTriple[][] => {
  if (!paths.some(hasRequiredTriple)) return paths;

  const [withRequiredTriples, withoutRequiredTriples] = partition(
    paths,
    hasRequiredTriple
  );
  const maybeBoundPaths = withoutRequiredTriples.map(path =>
    withRequiredTriples.reduce((boundPath, requiredPath, i) => {
      const pathOverlapLength = getPathOverlapLength(path, requiredPath);
      if (pathOverlapLength > 0) {
        return bindTriplesInPath(boundPath, pathOverlapLength, i);
      }
      return boundPath;
    }, path)
  );
  return [...withRequiredTriples, ...maybeBoundPaths];
};
