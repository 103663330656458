import { IconName } from '@ardoq/icons';
import { SidebarMenuMenu, SidebarMenuSection } from '@ardoq/sidebar-menu';
import { Space } from '@ardoq/style-helpers';
import styled from 'styled-components';
import { StatusType, Tag } from '@ardoq/status-ui';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { setApprovalFocus, setChangeRequestAction } from '../actions';
import { map } from 'rxjs';
import changeApprovalData$ from '../changeApprovalData$';
import { trackEvent } from 'tracking/tracking';
import { ApprovalFocus, ChangeActionExtended } from '../types';
import { ExcludeFalsy } from '@ardoq/common-helpers';

const StyledSpace = styled(Space)`
  width: 100%;
`;

type ComponentsSidebarSectionProps = {
  numberOfComponentChanges: number;
  changeAction: ChangeActionExtended;
  approvalFocus: ApprovalFocus;
};

const ComponentsSidebarSection = ({
  numberOfComponentChanges,
  changeAction,
  approvalFocus,
}: ComponentsSidebarSectionProps) => {
  return (
    <SidebarMenuSection
      leftIcon={{ name: IconName.FIELD }}
      title="Fields"
      titleDataTestId="fields-section"
      isCollapsible={false}
    >
      <SidebarMenuMenu
        options={[
          changeAction === 'update' && {
            label: (
              <StyledSpace $justify="space-between" $align="center">
                Updated
                <Tag
                  label={`${numberOfComponentChanges}`}
                  statusType={StatusType.INFO}
                />
              </StyledSpace>
            ),
            onClick: () => {
              dispatchAction(setApprovalFocus('component'));
              dispatchAction(setChangeRequestAction('update'));
              trackEvent(
                'Survey change approval: clicked on component sidebar',
                {
                  changeAction: 'update',
                }
              );
            },
            isSelected:
              approvalFocus === 'component' && changeAction === 'update',
          },
          (changeAction === 'create' || changeAction === 'delete') && {
            label: 'Details',
            onClick: () => {
              dispatchAction(setApprovalFocus('component'));
              dispatchAction(setChangeRequestAction(changeAction));
              trackEvent(
                'Survey change approval: clicked on component sidebar',
                {
                  changeAction,
                }
              );
            },
            isSelected: approvalFocus === 'component',
          },
        ].filter(ExcludeFalsy)}
      />
    </SidebarMenuSection>
  );
};

const componentsSidebarSectionData$ = changeApprovalData$.pipe(
  map(
    ({
      componentsWithPendingChanges,
      changedFieldsByComponentId,
      componentId,
      approvalFocus,
    }) => {
      const pendingChange = componentsWithPendingChanges.find(
        pendingChange => pendingChange._id === componentId
      );
      return {
        numberOfComponentChanges: Object.keys(
          changedFieldsByComponentId[componentId] ?? {}
        ).length,
        approvalFocus,
        changeAction: pendingChange?.changeAction,
      };
    }
  )
);

export default connect(ComponentsSidebarSection, componentsSidebarSectionData$);
