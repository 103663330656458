import { ReactNode } from 'react';
import { BroadcastFetchStatus } from 'broadcasts/types';
import { ErrorMessage } from '@ardoq/error-boundary';
import { DoqLoader } from '@ardoq/snowflakes';

type BroadcastLoaderProps = {
  status: BroadcastFetchStatus;
  children: ReactNode;
};

const BroadcastLoader = ({ status, children }: BroadcastLoaderProps) => {
  switch (status) {
    case BroadcastFetchStatus.LOADING:
      return <DoqLoader />;
    case BroadcastFetchStatus.SUCCESS:
    case BroadcastFetchStatus.REVALIDATING:
      return <>{children}</>;
    case BroadcastFetchStatus.ERROR:
      return <ErrorMessage />;
    default:
      return null;
  }
};

export default BroadcastLoader;
