import { ArdoqId, AudiencePreview } from '@ardoq/api-types';

export enum FetchStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type GremlinAudienceDialogStreamShape = {
  readonly query: string;
  readonly formIsValid: boolean;
  readonly isSearching: boolean;
  readonly isUpdatingExistingAudience: boolean;
  readonly contentIdsFetchStatus: FetchStatus;
  readonly contentIds: ArdoqId[];
  readonly totalNumberOfContentIds: number;
  readonly immutableFirstLineOfQuery: string;
  readonly audiencePreview: AudiencePreview;
  readonly audiencePreviewWarningMessage: string | null;
  readonly isFetchingAudiencePreview: boolean;
  readonly failedToFetchAudiencePreview: boolean;
  readonly hasGremlinSearchError: boolean;
  readonly gremlinSyntaxError: string | null;
  readonly gremlinWarningMessage: string | null;
  readonly tableFilter: string;
};
