import { FeatureSettingsCommands, FeatureSwitcherState } from './types';
import { Features } from '@ardoq/features';
import { IconName } from '@ardoq/icons';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { Link } from '@ardoq/typography';
import { FeatureToggle } from './FeatureToggle';
import EaseOfUseImage from './images/easeOfUse.png';
import { trackEvent } from 'tracking/tracking';
import { confirm, ModalSize } from '@ardoq/modal';
import { Island } from '@ardoq/page-layout';

export const EaseOfUseFeatureSection = ({
  isUpdating,
  easeOfUseFeature,
  commands,
}: {
  isUpdating: boolean;
  easeOfUseFeature: FeatureSwitcherState;
  commands: FeatureSettingsCommands;
}) => {
  return easeOfUseFeature.isHidden || easeOfUseFeature.isChecked ? null : (
    <Island
      title="Seamless data exploration"
      iconName={isUpdating ? IconName.SPINNER : undefined}
      subtitle={
        <p>
          We&apos;ve redesigned Ardoq to work better and faster for you.{' '}
          <Link
            href={KnowledgeBaseLink.EASE_OF_USE}
            target="_blank"
            typography="caption"
          >
            Learn more here.
          </Link>
        </p>
      }
      subtitleWidth="large"
    >
      <FeatureToggle
        name="Refreshed Ardoq design and new Viewpoint builder"
        description={
          <>
            <ul>
              <li>
                A new Ardoq interface design that makes it easier to find what
                you need
              </li>
              <li>A faster way to precisely scope and visualize your data</li>
              <li>Reusable Viewpoint templates that work within Ardoq</li>
            </ul>
            Once enabled, the change will be introduced to all users in this
            organization.
          </>
        }
        isChecked={easeOfUseFeature.isChecked}
        isDisabled={easeOfUseFeature.isDisabled}
        onChange={async isChecked => {
          confirm({
            title: 'Turn on seamless data exploration features',
            warningBlockMessage: (
              <p>
                The new Ardoq design and features are now out of beta so after
                turning this on, it will be the default way you use Ardoq. You
                can still turn this off in future by contacting customer
                support.
              </p>
            ),
            modalSize: ModalSize.S,
            confirmButtonTitle: 'Turn on',
            cancelButtonTitle: 'Cancel',
            onConfirmAsync: () => {
              trackEvent('Ease of use customer toggle', { isChecked }, true);
              commands.setFeatureValue({
                featureIds: [
                  Features.SUPPORT_LARGE_DATASETS.persistedId as string,
                  Features.NEW_CORE_JOURNEY.persistedId as string,
                ],
                value: isChecked,
              });
            },
          });
        }}
        imageSrc={EaseOfUseImage}
      />
    </Island>
  );
};
