export enum ChooseEntityResultType {
  EXISTING = 'EXISTING',
  NEW = 'NEW',
}

export type ChooseEntityResult =
  | {
      type: ChooseEntityResultType.EXISTING;
      id: string;
    }
  | {
      type: ChooseEntityResultType.NEW;
      name: string;
    };

export type ChooseEntityDialogContentConfig = {
  title: string;
  inputLabel: string;
  confirmButtonTitle: string;
};
