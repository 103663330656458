import React from 'react';
import { SecondaryButton } from '@ardoq/button';
import { openProductPortal } from 'components/AppMainSidebar/utils';
import { OpenInNewIcon } from '@ardoq/icons';
import styled from 'styled-components';
import { Header3, Header4, Paragraph } from '@ardoq/typography';
import { FlexBox } from '@ardoq/layout';

const RequestIntegrationWidgetContainer = styled(FlexBox)<{
  $isVertical?: boolean;
}>`
  width: 100%;
  flex-direction: ${({ $isVertical }) => ($isVertical ? 'column' : 'row')};
`;

const footerContent = {
  header: "Can't find the integration you're looking for?",
  text: "Let us know what integration you're missing below.",
};

type RequestIntegrationWidgetProps = {
  isVertical?: boolean;
};

const RequestIntegrationWidget = ({
  isVertical = false,
}: RequestIntegrationWidgetProps) => {
  return (
    <RequestIntegrationWidgetContainer
      align={isVertical ? 'flex-start' : 'center'}
      justify="space-between"
      borderColor="borderAction"
      backgroundColor="surfaceDefault"
      padding="large"
      borderRadius="r8"
      $isVertical={isVertical}
    >
      {isVertical ? (
        <div>
          <Header4>{footerContent.header}</Header4>
          <Paragraph variant="caption" color="textSubtle">
            {footerContent.text}
          </Paragraph>
        </div>
      ) : (
        <div>
          <Header3>{footerContent.header}</Header3>
          <Paragraph variant="text2" color="textSubtle">
            {footerContent.text}
          </Paragraph>
        </div>
      )}
      <FlexBox marginTop={isVertical ? 'large' : undefined}>
        <SecondaryButton onClick={openProductPortal}>
          Request integration <OpenInNewIcon />
        </SecondaryButton>
      </FlexBox>
    </RequestIntegrationWidgetContainer>
  );
};

export default RequestIntegrationWidget;
