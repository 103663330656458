import { Subdivision } from '@ardoq/api-types';
import { Box } from '@ardoq/layout';
import { dispatchAction } from '@ardoq/rxbeach';
import { useEffect } from 'react';
import { fetchSubdivisionSummary } from 'subdivisionEditor/SubdivisionOverview/subdivisionSummary$/actions';
import { SubdivisionSummaryInformation } from 'subdivisionEditor/SubdivisionOverview/SubdivisionSummaryInformation';
import { SubdivisionProps } from 'subdivisionEditor/types';

export const SummaryPage = ({ subdivision }: SubdivisionProps) => {
  useEffect(() => {
    dispatchAction(fetchSubdivisionSummary((subdivision as Subdivision)._id));
  }, [subdivision]);

  return (
    <Box>
      <SubdivisionSummaryInformation isInDrawer={false} />
    </Box>
  );
};
