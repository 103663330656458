import {
  APIComponentAttributes,
  ArdoqId,
  DrilldownScopeData,
} from '@ardoq/api-types';
import {
  ReportEventLocations,
  ReportExportType,
  trackExportReport,
  trackOpenComponentInWorkspace,
  trackOpenComponentOverviewPage,
} from '@ardoq/report-reader';
import { toByIdDictionary } from '@ardoq/common-helpers';
import FileSaver from 'file-saverjs';
import { PermissionContext } from '@ardoq/access-control';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { DropdownItem, DropdownOptionType } from '@ardoq/dropdown-menu';
import { Features, hasFeature } from '@ardoq/features';
import { IconName } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToComponentOverviewPage } from 'router/navigationActions';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { componentInWorkspaceNavigationRequested } from 'streams/workspaces/actions';
import { trackEvent } from 'tracking/tracking';
import { presentationApi } from '@ardoq/api';
import { drilldownWasTriggered } from './actions';
import {
  FetchPresentationSearchResultParams,
  FetchPresentationSearchResults,
} from './presentationSearchResults$';

export const exportToExcel = (exportData: Blob, reportName: string) => {
  FileSaver(
    new Blob([exportData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
    `${reportName}.xlsx`
  );
  trackExportReport(
    trackEvent,
    ReportEventLocations.ARDOQ,
    ReportExportType.EXCEL
  );
};

export const exportToReadableExcel = (exportData: Blob, reportName: string) => {
  FileSaver(
    new Blob([exportData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
    `${reportName}.xlsx`
  );
  trackExportReport(
    trackEvent,
    ReportEventLocations.ARDOQ,
    ReportExportType.EXCEL_READABLE
  );
};

export const exportToJSON = (reportId: ArdoqId) => {
  window.open(`/api/report/${reportId}/export`);
  trackExportReport(
    trackEvent,
    ReportEventLocations.ARDOQ,
    ReportExportType.JSON
  );
};

export const getHasComponentReadPermission = (
  scopeData: DrilldownScopeData,
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext
) => {
  const componentsById = toByIdDictionary(scopeData.components);

  return (entityId: ArdoqId) =>
    componentAccessControlOperation.canAccessComponent({
      component: componentsById[entityId],
      permissionContext,
      subdivisionsContext,
    });
};
export const getComponentLinkHandler = (
  componentId: ArdoqId,
  workspaceId?: ArdoqId
) => ({
  href: `/app/view/workspace/${workspaceId}/component/${componentId}`,
  // Component overview page needs traversals and new core journey
  tooltipText:
    hasFeature(Features.NEW_CORE_JOURNEY) &&
    hasFeature(Features.SUPPORT_LARGE_DATASETS)
      ? 'Go to component overview'
      : 'Go to workspace',
  onClick: () => {
    trackOpenComponentInWorkspace(trackEvent, ReportEventLocations.ARDOQ);
    if (hasFeature(Features.COMPONENT_LANDING_PAGE)) {
      dispatchAction(navigateToComponentOverviewPage(componentId));
    } else {
      dispatchAction(
        componentInWorkspaceNavigationRequested({
          componentId,
          trackingLocation: 'reportReader',
        })
      );
    }
  },
});

export const prepareGetComponentNavigationOptions =
  (
    permissionContext: PermissionContext,
    subdivisionsContext: SubdivisionsContext
  ): ((component: APIComponentAttributes) => DropdownItem[]) =>
  component => {
    const entries: DropdownItem[] = [];

    if (
      componentAccessControlOperation.canAccessComponent({
        component,
        permissionContext,
        subdivisionsContext,
      }) &&
      // Component overview page needs traversals and new core journey
      hasFeature(Features.NEW_CORE_JOURNEY) &&
      hasFeature(Features.SUPPORT_LARGE_DATASETS)
    ) {
      entries.push({
        label: 'Component Overview Page',
        iconName: IconName.COMPONENT,
        type: DropdownOptionType.BUTTON_OPTION,
        onClick: () => {
          trackOpenComponentOverviewPage(
            trackEvent,
            ReportEventLocations.ARDOQ
          );
          dispatchAction(navigateToComponentOverviewPage(component._id));
        },
      });
    }

    if (
      workspaceAccessControlInterface.canAccessWorkspace(
        permissionContext,
        component.rootWorkspace
      )
    ) {
      entries.push({
        label: 'Workspace',
        iconName: IconName.WORKSPACE,
        type: DropdownOptionType.BUTTON_OPTION,
        onClick: () => {
          trackOpenComponentInWorkspace(trackEvent, ReportEventLocations.ARDOQ);
          dispatchAction(
            componentInWorkspaceNavigationRequested({
              componentId: component._id,
              trackingLocation: 'reportReader',
            })
          );
        },
      });
    }

    return entries;
  };

export const fetchPresentationSearchResults: FetchPresentationSearchResults =
  async ({
    pagination,
    sort,
    filterQuery,
    reportId,
    slideId,
    presentationId,
  }: FetchPresentationSearchResultParams) => {
    dispatchAction(drilldownWasTriggered(true));
    const searchResults = await presentationApi.search(
      presentationId!,
      reportId,
      slideId,
      {
        from: pagination.from,
        size: pagination.size,
        sort: sort.columnKey ?? undefined,
        order: sort.order ?? undefined,
        filters: filterQuery,
      }
    );
    dispatchAction(drilldownWasTriggered(false));
    return searchResults;
  };
