import {
  Button,
  ButtonGroup,
  ButtonType,
  SecondaryButton,
  GhostButton,
} from '@ardoq/button';
import { ChevronLeftIcon, EditIcon } from '@ardoq/icons';
import { trackEvent } from 'tracking/tracking';
import { CopyURlButton, DashboardTrackingEventsNames } from '@ardoq/dashboard';
import { popoverRegistry } from '@ardoq/popovers';
import {
  CANNOT_ADD_TO_CURRENT_PRESENTATION_POPOVER_ID,
  CANNOT_ADD_TO_PRESENTATION_POPOVER_ID,
  CANNOT_EDIT_POPOVER_ID,
  STRINGS,
} from 'dashboard/consts';
import { ArdoqId } from '@ardoq/api-types';
import { ToolbarDivider } from '@ardoq/page-layout';
import { ScenarioSelection } from 'utils/searchBranchSelection';
import styled from 'styled-components';
import { Select } from '@ardoq/select';
import { useEffect } from 'react';

const getPopoverId = (
  userCanAddToPresentation: boolean,
  hasPresentationOpen: boolean
) => {
  if (userCanAddToPresentation) return undefined;
  if (hasPresentationOpen) return CANNOT_ADD_TO_CURRENT_PRESENTATION_POPOVER_ID;
  return CANNOT_ADD_TO_PRESENTATION_POPOVER_ID;
};

interface HeaderBarProps {
  editDashboard: VoidFunction;
  addDashboardToPresentation: VoidFunction;
  userHasEditAccess: boolean;
  hasPresentationOpen: boolean;
  goToOverview: VoidFunction;
  goToOpenDashboard?: VoidFunction;
  hasNewJourneyFeature: boolean;
  dashboardId: ArdoqId;
  hasBranchFeature: boolean;
  scenarioSelection: ScenarioSelection;
  userCanAddToPresentation: boolean;
}

const DropdownContainer = styled.div`
  width: 20em;
`;

const ChooseScenario = ({
  options,
  selection,
  onChange,
}: ScenarioSelection) => {
  return (
    <DropdownContainer>
      <Select
        label={undefined} // "Scenario"
        value={selection}
        options={options}
        onChange={option => onChange(option?.value ?? null)}
      />
    </DropdownContainer>
  );
};

const HeaderButtons = ({
  editDashboard,
  addDashboardToPresentation,
  goToOverview,
  userHasEditAccess,
  hasPresentationOpen,
  hasNewJourneyFeature,
  dashboardId,
  hasBranchFeature,
  scenarioSelection,
  userCanAddToPresentation,
}: HeaderBarProps) => {
  useEffect(() => {
    popoverRegistry.set(
      CANNOT_EDIT_POPOVER_ID,
      () => STRINGS.NO_EDIT_PRIVILEGE_POPOVER
    );

    popoverRegistry.set(
      CANNOT_ADD_TO_PRESENTATION_POPOVER_ID,
      () => STRINGS.NO_ADD_TO_PRESENTATION_PRIVILEGE_POPOVER
    );

    popoverRegistry.set(
      CANNOT_ADD_TO_CURRENT_PRESENTATION_POPOVER_ID,
      () => STRINGS.NO_ADD_TO_CURRENT_PRESENTATION_POPOVER
    );

    return () => {
      popoverRegistry.delete(CANNOT_EDIT_POPOVER_ID);
      popoverRegistry.delete(CANNOT_ADD_TO_PRESENTATION_POPOVER_ID);
      popoverRegistry.delete(CANNOT_ADD_TO_CURRENT_PRESENTATION_POPOVER_ID);
    };
  }, []);
  return (
    <ButtonGroup>
      {hasBranchFeature && <ChooseScenario {...scenarioSelection} />}
      <CopyURlButton dashboardId={dashboardId} trackEvent={trackEvent} />
      <ToolbarDivider />
      <GhostButton
        type="button"
        onClick={() => {
          trackEvent(
            DashboardTrackingEventsNames.CLICKED_ON_GO_TO_DASHBOARD_OVERVIEW_DASHBOARD_READER
          );
          goToOverview();
        }}
      >
        <ChevronLeftIcon />
        Back to Dashboards
      </GhostButton>
      <SecondaryButton
        type="button"
        onClick={() => {
          trackEvent(
            DashboardTrackingEventsNames.CLICKED_ON_EDIT_IN_DASHBOARD_READER
          );
          editDashboard();
        }}
        isDisabled={!userHasEditAccess}
        popoverId={!userHasEditAccess ? CANNOT_EDIT_POPOVER_ID : undefined}
      >
        <EditIcon />
        Edit
      </SecondaryButton>
      <Button
        buttonType={
          hasNewJourneyFeature ? ButtonType.BRAND : ButtonType.PRIMARY
        }
        type="button"
        onClick={() => {
          trackEvent(
            DashboardTrackingEventsNames.CLICKED_ON_ADD_TO_PRESENTATION_IN_DASHBOARD_READER
          );
          addDashboardToPresentation();
        }}
        isDisabled={!userCanAddToPresentation}
        popoverId={getPopoverId(userCanAddToPresentation, hasPresentationOpen)}
      >
        Add to presentation
      </Button>
    </ButtonGroup>
  );
};

export default HeaderButtons;
