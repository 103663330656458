import References from 'collections/references';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap } from 'rxjs/operators';
import { editReference, selectReference } from './actions';
import Context from 'context';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { showRightPane } from 'appContainer/actions';

const handleEditReference = routine(
  ofType(editReference),
  extractPayload(),
  tap(id => {
    const reference = References.collection.get(id);
    if (reference) {
      dispatchAction(
        showRightPane({
          type: GetContentOptionsType.REFERENCE_PROPERTIES,
          referenceIds: [id],
        })
      );
    }
  })
);

const handleSelectReference = routine(
  ofType(selectReference),
  extractPayload(),
  tap(id => {
    const reference = References.collection.get(id);
    if (reference) {
      Context.setReference(reference);
    }
  })
);

export default collectRoutines(handleEditReference, handleSelectReference);
