import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  resetToInitialState,
  setCurrentCreationContext,
  submitUpdateToTheServer,
} from './actions';
import { map } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { subdivisionCreationContextOperations } from './subdivisionCreationContextOperations';
import { handleError, subdivisionApi } from '@ardoq/api';
import { showToast, ToastType } from '@ardoq/status-ui';
import currentUser from 'models/currentUser';

const handleSubmitUpdateToTheServer = routine(
  ofType(submitUpdateToTheServer),
  extractPayload(),
  map(
    subdivisionCreationContextOperations.generateSetCreationContextRequestPayload
  ),
  switchMap(subdivisionApi.setCreationContext),
  handleError(() => {
    dispatchAction(resetToInitialState());
    showToast('Failed to update the creation context', ToastType.INFO);
  }),
  switchMap(({ currentCreationContext }) => {
    dispatchAction(setCurrentCreationContext(currentCreationContext));
    return currentUser.fetch();
  })
);

export default collectRoutines(handleSubmitUpdateToTheServer);
