import { colors, r8, s16 } from '@ardoq/design-tokens';
import { Space } from '@ardoq/style-helpers';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { header3, header4, text1 } from '@ardoq/typography';
import { useMemo } from 'react';
import styled from 'styled-components';
import { getModelHierarchy } from '../utils';
import {
  MetamodelTree,
  MetamodelEditorStateProvider,
} from '@ardoq/metamodel-editor';
import { configurationState$ } from '../../streams/configurationState/configurationState$';
import { ConfigurationState } from '../../streams/types';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { ComponentIcon, IconName } from '@ardoq/icons';
import { ComponentTypeHierarchy } from '@ardoq/api-types';
import { InfoNotification } from '@ardoq/status-ui';
import { FormWrapper, TextInput } from '@ardoq/forms';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  upsertEnrichment,
  updateName,
} from '../../streams/configurationState/actions';
import { PrimaryButton, GhostButton } from '@ardoq/button';
import { map } from 'rxjs';
import { flatMapDeep, flow, map as _map, sum } from 'lodash/fp';
import { StepContainer } from '../components/StepContainer';
import { trackITPediaEvent } from 'integrations/ITPedia/streams/tracking/actions';
import { navigateToPath } from 'integrations/ITPedia/navigation/actions';
import { ITPediaImportRoutes } from 'integrations/ITPedia/types';
import { configStateToEnrichmentJobOptions } from 'integrations/ITPedia/utils';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { Island, CollapsibleIsland } from '@ardoq/page-layout';

type SummarySyncParam = {
  configurationState: ConfigurationState;
};

function SummarySyncComponent({ configurationState }: SummarySyncParam) {
  const { workspaces, componentTypes: selectedComponentTypes } =
    configurationState;
  const componentTypes = useMemo(
    () =>
      workspaces.products.id
        ? workspaceInterface.getComponentTypes(workspaces.products.id)
        : [],
    [workspaces.products.id]
  );
  const lifecyleHeirarchy = getModelHierarchy(
    true,
    Object.keys(selectedComponentTypes),
    Object.keys(selectedComponentTypes),
    componentTypes
  );

  const onPrev = () => {
    dispatchAction(
      trackITPediaEvent({
        name: 'CLICKED_BUTTON',
        metadata: {
          name: 'Back on summary and sync',
          to: 'Mapping field',
        },
      })
    );
    dispatchAction(navigateToPath(ITPediaImportRoutes.FIELD_MAPPING));
  };

  const vulnerabilitiesHeirarchy: ComponentTypeHierarchy = {
    [workspaces.vulnerabilities.name]: {
      id: '',
      name: 'Vulnerability',
      index: 0,
      level: 1,
      returnsValue: false,
      children: {},
      color: colors.red50,
      icon: null,
      image: null,
      shape: null,
      standard: null,
    },
  };

  const locale = getCurrentLocale();

  const components = flatMapDeep(
    ct => [...(ct.componentsQueryResults?.components || [])],
    configurationState.componentTypes
  ).sort((a, b) => localeCompare(a, b, locale));

  const totalComponents =
    flow(
      _map('componentsQueryResults.total'),
      sum
    )(configurationState.componentTypes) || 0;

  return (
    <StepContainer $isVertical>
      <CollapsibleIsland
        title="Review"
        subtitle="Before starting the sync, review the included components and enrichment tech overview."
        subtitleWidth="large"
        isDefaultExpanded
        isScrollable
      >
        <Stack gap="xlarge">
          <Stack gap="medium">
            <Stack gap="small">
              <Header3>1. Components to enrich</Header3>
              <Text1>
                Send the following components to IT-Pedia for matching and
                enrichment.
              </Text1>
            </Stack>
            <CollapsibleIsland
              subtitleWidth="medium"
              // When there are more than 500 components we only show 500 in the list
              title={
                <FlexBox gap="small">
                  <ComponentIcon />
                  {totalComponents > components.length &&
                    `Showing ${components.length}/`}
                  {totalComponents}Components
                </FlexBox>
              }
              maxWidth="382px"
              onToggle={() =>
                dispatchAction(
                  trackITPediaEvent({
                    name: 'TOGGLED',
                    metadata: { title: 'Components' },
                  })
                )
              }
            >
              <ComponentList>
                {components?.map(component => (
                  <Text1 key={component}>{component}</Text1>
                ))}
              </ComponentList>
            </CollapsibleIsland>
          </Stack>
          <Stack gap="medium">
            <Stack gap="small">
              <Header3>2. Enrichment</Header3>
              <Text1>
                The chosen components will be enriched with <b>lifecycle</b> and
                <b> vulnerability</b> details. Use this overview to locate the
                enriched components in Ardoq.
              </Text1>
            </Stack>
            <Space $gap="s32" $align="center">
              <MetamodelHeading>Lifecycle enrichment</MetamodelHeading>
              <Stack gap="xsmall" align="center">
                <img src="/img/it-pedia/it_pedia.png" width={58} height={54} />
                <img src="/img/it-pedia/arrow.svg" width={98} />
                <Title>IT-Pedia</Title>
              </Stack>
              <CollapsibleIsland
                subtitleWidth="medium"
                isDefaultExpanded
                iconName={IconName.WORKSPACE}
                title={workspaces.products.name}
              >
                <MetaModelContainer>
                  <MetamodelEditorStateProvider
                    initialMetamodel={lifecyleHeirarchy}
                    usedMetamodelTypeIds={new Set()}
                  >
                    <MetamodelTree />
                  </MetamodelEditorStateProvider>
                </MetaModelContainer>
              </CollapsibleIsland>
            </Space>
            <Space $gap="s32" $align="center">
              <MetamodelHeading>Vulnerability enrichment</MetamodelHeading>
              <Stack gap="xsmall" align="center">
                <img src="/img/it-pedia/it_pedia.png" width={58} height={54} />
                <img src="/img/it-pedia/arrow.svg" width={98} />
                <Title>IT-Pedia</Title>
              </Stack>
              <CollapsibleIsland
                subtitleWidth="medium"
                isDefaultExpanded
                iconName={IconName.WORKSPACE}
                title={workspaces.vulnerabilities.name}
              >
                <MetaModelContainer>
                  <MetamodelEditorStateProvider
                    initialMetamodel={vulnerabilitiesHeirarchy}
                    usedMetamodelTypeIds={new Set()}
                  >
                    <MetamodelTree />
                  </MetamodelEditorStateProvider>
                </MetaModelContainer>
              </CollapsibleIsland>
            </Space>
          </Stack>
        </Stack>
      </CollapsibleIsland>
      <Island
        isScrollable
        title="Sync process"
        subtitle="We will send components to match with products in IT-Pedia. Once matched, we will check IT-Pedia for updates and sync the data."
        subtitleWidth="medium"
        footerContent={
          <Space $justify="end" $flex={1}>
            <GhostButton onClick={onPrev}>Back</GhostButton>
            <PrimaryButton
              isDisabled={!configurationState.name}
              isLoading={configurationState.statuses.upsert.code === 'Loading'}
              onClick={() => {
                dispatchAction(
                  trackITPediaEvent({ name: 'STARTED_CREATING_NEW_SYNC' })
                );
                dispatchAction(
                  upsertEnrichment(
                    configStateToEnrichmentJobOptions(configurationState)
                  )
                );
              }}
            >
              Start sync
            </PrimaryButton>
          </Space>
        }
      >
        <InfoNotification>
          <Stack gap="xsmall">
            <Header4>Keep Ardoq and IT-Pedia synched:</Header4>
            <li>
              Deleting components in Ardoq will automatically remove the
              corresponding products in IT-Pedia.
            </li>
            <li>
              Any new components added to the workspace will be added to the
              sync job automatically.
            </li>
          </Stack>
        </InfoNotification>
        <FormWrapper>
          <TextInput
            value={configurationState.name}
            label="Name of sync"
            onClear={() => dispatchAction(updateName({ name: '' }))}
            onValueChange={name =>
              name !== undefined && dispatchAction(updateName({ name }))
            }
            helperText={
              <>
                Provide a clear and recognisable name for the sync to easily
                identify it in the sync overview.
              </>
            }
          />
        </FormWrapper>
      </Island>
    </StepContainer>
  );
}

const viewModel$ = configurationState$.pipe(
  map(val => ({ configurationState: val }))
);

export const SummarySync = connect(SummarySyncComponent, viewModel$);

const MetamodelHeading = styled(Space).attrs({ $flex: 1 })`
  border-radius: ${r8};
  border: 1px solid ${colors.grey90};
  padding: ${s16};
  max-width: 268px;
  ${header3};
`;

const ComponentList = styled(Space).attrs({ $isVertical: true })`
  max-height: 268px;
  overflow: auto;
`;

const Text1 = styled.div`
  ${text1};
`;

const Header3 = styled.div`
  ${header3};
`;

const Header4 = styled.div`
  ${header4};
`;

const Title = styled.div`
  ${header4};
  height: 54px;
`;

const MetaModelContainer = styled.div`
  div {
    &:before {
      box-shadow: none;
    }
  }
`;
