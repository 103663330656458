import { TopBarButtonWithMenu, TopBarButtonWithMenuProps } from '../atoms';
import { Icon, IconName } from '@ardoq/icons';
import { SortOption } from './types';
import {
  ComponentWithDropdown,
  ComponentWithDropdownComponentProps,
  DropdownOption,
} from '@ardoq/dropdown-menu';
import { SortOrder } from '@ardoq/api-types';

type NavigatorTopBarSortMenuProps = {
  isScenarioMode: boolean;
  sortOptions: SortOption[];
  onSortChange: (sortOption: SortOption) => void;
};

export const mapSortOptionToMenuOption = (
  { label, sortOrder, ...rest }: SortOption,
  onSortChange: (sortOption: SortOption) => void
): Partial<DropdownOption> => {
  const iconName = sortOrder
    ? sortOrder === SortOrder.ASC
      ? IconName.ARROW_UPWARD
      : IconName.ARROW_DOWNWARD
    : IconName.NONE;

  const nextSortOrder =
    !sortOrder || sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;

  return {
    label,
    rightContent: <Icon iconName={iconName} />,
    onClick: () => onSortChange({ label, ...rest, sortOrder: nextSortOrder }),
  };
};

const componentProps = (
  isScenarioMode: boolean
): ComponentWithDropdownComponentProps<TopBarButtonWithMenuProps> => ({
  isScenarioMode,
  iconName: IconName.SORT,
});

const NavigatorTopBarSortMenu = ({
  onSortChange,
  sortOptions,
  isScenarioMode,
}: NavigatorTopBarSortMenuProps) => (
  <ComponentWithDropdown
    Component={TopBarButtonWithMenu}
    componentProps={componentProps(isScenarioMode)}
    options={sortOptions.map(sortOption =>
      mapSortOptionToMenuOption(sortOption, onSortChange)
    )}
  />
);

export default NavigatorTopBarSortMenu;
