import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { filter, map, switchMap, combineLatest, Observable } from 'rxjs';
import { selectionState$ } from './selectionState/selectionState$';
import { azureSubscriptions$ } from './subscriptions/subscriptions$';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { getLoadingMessage } from '../utils';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import { resourceGroups$ } from './resourceGroups/resourceGroups$';
import { resourcesSummary$ } from './resourcesSummary/resourcesSummary$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';

export type OverlayState = {
  isLoading: boolean;
  message: string;
};

export const overlay$: Observable<OverlayState> = integrationId$.pipe(
  filter(integrationId => integrationId === 'azure-v3'),
  switchMap(integrationId => {
    return combineLatest([
      getResourceTypesStream('azure-v3'),
      selectionState$,
      azureSubscriptions$,
      resourceGroups$,
      getTransferStateStream(integrationId).pipe(
        map(val => ({ asyncStatus: val.requestStatus }))
      ),
      getRegionsStream('azure-v3'),
      resourcesSummary$.pipe(
        map(resourcesSummary => ({
          asyncStatus: resourcesSummary.asyncStatus,
        }))
      ),
      getConnectionsStream(integrationId).pipe(
        map(val => ({ asyncStatus: val.statuses.list.status }))
      ),
    ]);
  }),
  map(streams => {
    const [
      resourceTypes,
      selectionState,
      subscriptions,
      resourceGroups,
      transferState,
      regionState,
      connectionsState,
    ] = streams;
    return {
      isLoading: streams.some(stream => stream.asyncStatus === 'LOADING'),
      message: getLoadingMessage({
        resourceTypes,
        selectionState,
        subscriptions,
        resourceGroups,
        transferState,
        regionState,
        connectionsState,
      }),
    };
  })
);
