import { useState } from 'react';
import { ViewIds } from '@ardoq/api-types';
import { ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import MediaHoverContainer from './mediaHoverContainer';
import {
  isViewDiscontinued,
  isViewRestrictedInWorkspaceMode,
} from 'viewDeprecation/restrictedViews';
import TabView from 'views/tabView';
import { ExistentMetaInfoViewStatus } from 'views/types';
import ViewStatusTextBadge from 'views/ViewStatusTextBadge';
import { FlexBox } from '@ardoq/layout';
import { isViewRestrictedByQuickStart } from 'quickStart/isViewRestrictedByQuickStart';

type ViewMetaInfoWithImage = {
  id: ViewIds;
  view: TabView;
  imageUrl: string;
};

export const getViewOptionLabel = (
  viewStatus: ExistentMetaInfoViewStatus,
  label: string
) => (
  <FlexBox gap="small" paddingRight="medium">
    {label}
    {viewStatus ? <ViewStatusTextBadge viewStatus={viewStatus} /> : null}
  </FlexBox>
);

type SelectViewProps = {
  initViews: ViewIds[];
  eligibleViews: ViewMetaInfoWithImage[];
};

type ModalContentProps = SelectViewProps & {
  setNewViewIds: (viewIds: ViewIds[]) => void;
};

const ModalContents = ({
  initViews,
  eligibleViews,
  setNewViewIds,
}: ModalContentProps) => {
  const [selectedViewIds, setSelectedViewIds] = useState(initViews);

  const toggleViewId = (viewId: ViewIds) => {
    const isSelected = selectedViewIds.includes(viewId);
    const newIds = isSelected
      ? selectedViewIds.filter(id => id !== viewId)
      : selectedViewIds.concat(viewId);
    setSelectedViewIds(newIds);
  };

  const availableViews = eligibleViews.filter(
    view =>
      !isViewDiscontinued(view.id) &&
      !isViewRestrictedByQuickStart(view.id) &&
      !isViewRestrictedInWorkspaceMode(view.id)
  );

  return (
    <ModalTemplate
      headerText="Manage workspace views"
      modalSize={ModalSize.L}
      primaryButtonText="Apply changes"
      onPrimaryButtonClick={() => setNewViewIds(selectedViewIds)}
      onSecondaryButtonClick={() => setNewViewIds(initViews)}
      secondaryButtonText="Cancel"
      fixedHeight
    >
      <FlexBox flexWrap padding="large">
        {availableViews.map(({ imageUrl, view }) => (
          <MediaHoverContainer
            key={view.id}
            imageUrl={imageUrl}
            title={view.name ?? ''}
            description={view.getDescription()}
            isSelected={selectedViewIds.includes(view.id)}
            viewStatus={view.viewStatus}
            toggleView={() => toggleViewId(view.id)}
          />
        ))}
      </FlexBox>
    </ModalTemplate>
  );
};

export default async (props: SelectViewProps) => {
  const newViewIds = await modal<ViewIds[]>(resolve => (
    <ModalContents {...props} setNewViewIds={newIds => resolve(newIds)} />
  ));
  return newViewIds || props.initViews;
};
