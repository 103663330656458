import { persistentReducedStream } from '@ardoq/rxbeach';
import { getDefaultState } from './utils';
import { reducers } from './reducers';

const gremlinAudienceDialog$ = persistentReducedStream(
  'gremlinAudienceDialog$',
  getDefaultState(),
  reducers
);

export default gremlinAudienceDialog$;
