import { derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { APIFieldAttributes, ArdoqId } from '@ardoq/api-types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { ScenarioModeState } from 'scope/types';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { fieldOps } from 'models/utils/fieldOps';
import { filterField$ } from './filterField$';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';

const getTypeName = (workspaceId: ArdoqId) => (typeId: string) => {
  return workspaceInterface.getTypeById(workspaceId, typeId)?.name ?? '';
};

const getReferenceTypeName = (workspaceId: ArdoqId) => (typeId: string) => {
  return workspaceInterface.getReferenceTypeById(workspaceId, typeId)?.name;
};

const getFieldData = (workspaceId: ArdoqId, field: APIFieldAttributes) => {
  return [
    {
      name: 'Type',
      value: fieldOps.getFieldTypeLabelWithPopulateMethod(field),
    },
    {
      name: 'Component types',
      value: field.global
        ? 'All'
        : field.componentType === null
          ? 'None'
          : field.componentType.map(getTypeName(workspaceId)).join(', '),
    },
    {
      name: 'Reference types',
      value: field.globalref
        ? 'All'
        : field.referenceType === null
          ? 'None'
          : field.referenceType
              .map(getReferenceTypeName(workspaceId))
              .join(', '),
    },
  ];
};

const appModelStateEditStreamToManageFieldsStream = ([
  { enhancedScopeData, model },
  activeScenarioState,
  filterTerm,
  permissionContext,
]: [
  AppModelStateEditStreamShape,
  ScenarioModeState,
  string,
  PermissionContext,
]) => {
  if (!enhancedScopeData || !model) {
    return {
      canAddFields: false,
      fieldCards: [],
    };
  }
  const workspaceId = workspaceInterface.findByModel(model)?._id;
  if (!workspaceId) {
    return {
      canAddFields: false,
      fieldCards: [],
    };
  }
  const fields = enhancedScopeData.fields
    .filter(({ model: fieldModel }) => model === fieldModel)
    .filter(
      ({ label }) => !filterTerm || label.toLowerCase().includes(filterTerm)
    )
    .sort((a, b) => (a._order ?? 0) - (b._order ?? 0));
  const canAddFields = workspaceAccessControlInterface.canAddFieldsToWorkspace(
    permissionContext,
    workspaceId,
    activeScenarioState
  );
  const canEditFields = workspaceAccessControlInterface.canEditWorkspaceFields(
    permissionContext,
    workspaceId,
    activeScenarioState
  );

  return {
    canAddFields,
    filterTerm,
    fieldCards: fields.map(field => {
      const isExternallyManaged =
        fieldInterface.isExternallyManagedWithinWorkspace(
          field.name,
          workspaceId
        );
      const externallyManagedWorkspaceId = isExternallyManaged
        ? workspaceId
        : null;
      return {
        fieldId: field._id,
        fieldName: field.name,
        fieldLabel: field.label,
        fieldData: getFieldData(workspaceId, field),
        isExternallyManaged,
        externallyManagedWorkspaceId,
        isEditable: canEditFields && !isExternallyManaged,
      };
    }),
  };
};

const manageFields$ = derivedStream(
  'manageFields$',
  appModelStateEdit$,
  activeScenario$,
  filterField$,
  currentUserPermissionContext$
).pipe(map(appModelStateEditStreamToManageFieldsStream));

export default manageFields$;
