import { useState } from 'react';
import { useKeydown } from '@ardoq/hooks';
import { clamp } from 'lodash';

enum KeyboardEventKey {
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
}

export const useSelectorController = <T extends { id: string }>(
  results: T[],
  handleSelection: (result: T) => void
) => {
  const [selector, setSelector] = useState(0);
  const setBoundedSelector = (value: number) =>
    setSelector(clamp(value, 0, results.length - 1));

  useKeydown((event: KeyboardEvent) => {
    if (results.length === 0) return;
    if (event.key === KeyboardEventKey.ARROW_UP) {
      setBoundedSelector(selector - 1);
    }
    if (event.key === KeyboardEventKey.ARROW_DOWN) {
      setBoundedSelector(selector + 1);
    }
    if (event.key === KeyboardEventKey.ENTER && handleSelection) {
      handleSelection(results[selector]);
    }
  });

  const handleHover = (id: string) =>
    setBoundedSelector(results.findIndex(e => e.id === id));

  const resetSelectedId = () => setSelector(0);
  const selectedId = results.length !== 0 ? results[selector]?.id : null;
  return { selectedId, handleHover, resetSelectedId };
};
