import { TraversalBuilderState } from '../types';
import { RequiredComponentsViewState } from './types';
import {
  getNodeMetaDataFromGraphNodeId,
  getNodeMetaDataFromTypeName,
} from '../getNodeMetaData';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { TraversalPathMatchingType } from '@ardoq/api-types';
import { getShortestPath } from '../collapsePathsTab/getShortestPath';
import { viewpointBuilderGraphOperations } from '../viewpointBuilderGraphOperations';

const addRequiredComponent = (state: TraversalBuilderState, nodeId: string) => {
  if (
    state.requiredNodeIds.includes(nodeId) ||
    state.startNode?.id === nodeId
  ) {
    return state;
  }
  const requiredNodeIds = [...state.requiredNodeIds, nodeId];
  return {
    ...state,
    pathMatching:
      requiredNodeIds.length === 1 // if adding the first required node, set pathMatching to STRICT
        ? TraversalPathMatchingType.STRICT
        : state.pathMatching,
    requiredNodeIds,
  };
};

const removeRequiredComponent = (
  state: TraversalBuilderState,
  nodeId: string
) => {
  const requiredNodeIds = state.requiredNodeIds.filter(id => id !== nodeId);
  return {
    ...state,
    pathMatching:
      requiredNodeIds.length === 0
        ? TraversalPathMatchingType.LOOSE
        : state.pathMatching,
    requiredNodeIds,
  };
};

const getRequiredComponentsViewState = (
  state: TraversalBuilderState
): RequiredComponentsViewState => {
  const { requiredNodeIds, pathMatching, startComponentType, graphNodes } =
    state;
  const traversalStartTypeMetaData = getNodeMetaDataFromTypeName(
    graphNodes,
    startComponentType ?? ''
  );
  if (!traversalStartTypeMetaData || !graphNodes) {
    return null;
  }
  const items = requiredNodeIds
    .map(nodeId => {
      const componentTypeMetaData = getNodeMetaDataFromGraphNodeId(
        graphNodes,
        nodeId
      );
      // shouldn't ever be falsy, but needed to make TS happy
      if (!componentTypeMetaData) {
        return null;
      }
      return {
        nodeId,
        componentTypeMetaData,
      };
    })
    .filter(ExcludeFalsy);
  return {
    traversalStartTypeMetaData,
    items,
    pathMatching,
  };
};

const onGraphNodeHover = (
  state: TraversalBuilderState,
  nodeId: string | null
): TraversalBuilderState => {
  if (!state.startNode?.id) {
    return state;
  }
  const shortestPath = nodeId
    ? getShortestPath(state, state.startNode.id, nodeId)
    : null;

  return viewpointBuilderGraphOperations.updatePathHighlighting(state, {
    shortestPath,
    highlightedPathId: 'requiredComponents',
    isFadedOut: Boolean(shortestPath),
  });
};

export const requiredComponentsOperations = {
  addRequiredComponent,
  removeRequiredComponent,
  getRequiredComponentsViewState,
  onGraphNodeHover,
};
