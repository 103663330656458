import { connect } from '@ardoq/rxbeach';
import FilterSidebar from './filterSidebar/FilterSidebar';
import viewModel$ from './viewModel$';
import { SearchAndFilterBar } from '@ardoq/snowflakes';
import styled from 'styled-components';
import {
  AuditLogAvailablePeriod,
  AuditLogEmptyState,
  AuditLogErrorPage,
  AuditLogLoader,
  AuditLogPagination,
  AuditLogTable,
} from '@ardoq/audit-log';
import { AuditLogViewModelState } from './types';
import { LoadingState } from './consts';
import { hasAPIError } from './utils';
import { DASHBOARD_MIN_WIDTH } from '@ardoq/dashboard';
import MainToolbar from 'menus/topbar/MainToolbar';
import Navbar from 'views/navbar/Navbar';
import {
  IslandBody,
  IslandContainer,
  IslandFooter,
  PageBody,
  PageHeader,
  PageWrapper,
} from '@ardoq/page-layout';
import { Box, Stack } from '@ardoq/layout';
import { s32 } from '@ardoq/design-tokens';
import { NotificationArea } from './NotificationArea';

const AuditLogIslandContainer = styled(IslandContainer).attrs({
  $maxWidth: 'auto',
})`
  position: relative; /* needs this to position the loader correctly */
  min-width: ${DASHBOARD_MIN_WIDTH}px;
  min-height: 100%;
`;

const AuditLogIslandBody = styled(IslandBody)`
  flex-grow: 1;
`;

const PageContentWrapper = styled(Stack).attrs({ gap: 'medium' })<{
  hasFullAuditLogHistoryFeature: boolean;
}>`
  flex: 1;
  overflow: scroll;
  padding: ${s32};
  padding-top: ${({ hasFullAuditLogHistoryFeature }) =>
    hasFullAuditLogHistoryFeature ? s32 : 'none'};
`;

const AuditLogContainer = ({
  data,
  isFilterSidebarOpen,
  filterProps,
  loadingState,
  commands,
  searchAndFilterBarProps,
  apiErrors,
  hasNewJourneyFeature,
  hasFullAuditLogHistoryFeature,
  isAuditLogEmpty,
  auditLogTableProps,
  auditLogPaginationProps,
  notificationAreaProps,
}: AuditLogViewModelState) => {
  return (
    <PageWrapper>
      {hasNewJourneyFeature ? (
        <Navbar
          primaryContent="Audit Log"
          secondaryContent="Preferences"
          toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
        />
      ) : (
        <PageHeader title="Audit Log" />
      )}
      <PageBody
        skipPadding={true}
        rightContent={
          isFilterSidebarOpen ? <FilterSidebar {...filterProps} /> : undefined
        }
        headerContent={
          !hasFullAuditLogHistoryFeature ? (
            <Box paddingX="xlarge" width="full">
              <AuditLogAvailablePeriod />
            </Box>
          ) : undefined
        }
      >
        <PageContentWrapper
          hasFullAuditLogHistoryFeature={hasFullAuditLogHistoryFeature}
        >
          <NotificationArea {...notificationAreaProps} />

          {hasAPIError(apiErrors) ? (
            <AuditLogErrorPage />
          ) : (
            <AuditLogIslandContainer $preventScroll>
              <SearchAndFilterBar
                {...searchAndFilterBarProps}
                searchValue={filterProps.name}
              />
              <AuditLogIslandBody $preventScroll>
                {loadingState === LoadingState.LOADING && <AuditLogLoader />}
                {isAuditLogEmpty && <AuditLogEmptyState />}
                {data && !isAuditLogEmpty && (
                  <AuditLogTable {...auditLogTableProps} commands={commands} />
                )}
              </AuditLogIslandBody>
              {!isAuditLogEmpty && (
                <IslandFooter>
                  <AuditLogPagination
                    {...auditLogPaginationProps}
                    commands={commands}
                  />
                </IslandFooter>
              )}
            </AuditLogIslandContainer>
          )}
        </PageContentWrapper>
      </PageBody>
    </PageWrapper>
  );
};

export default connect(AuditLogContainer, viewModel$);
