import Node from './node';
import { HEIGHT_COMPONENT_ITEM, NodeModelTypes } from '../utils/consts';
import { NodeModel } from './types';
import type Tree from './tree';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

class ComponentNode extends Node {
  private _id: string;
  private _children: NodeModel[] = [];

  constructor(tree: Tree, id: string, parent: NodeModel | null = null) {
    super(tree, parent);
    this._id = id;
    this.updateChildren();
  }

  override get id() {
    return this._id;
  }

  get hasWriteAccess() {
    if (
      !this.parent ||
      activeScenarioOperations.isInDiffMode(this.tree.activeScenarioState)
    ) {
      return false;
    }

    // Hack - but it will be deleted in the next PR.
    if (
      activeScenarioOperations.isInScenarioMode(this.tree.activeScenarioState)
    ) {
      return scenarioAccessControlInterface.canEditActiveScenario(
        currentUserInterface.getPermissionContext(),
        this.tree.activeScenarioState
      );
    }
    return this.tree.navigatorViewInterface.hasComponentWriteAccess(this.id);
  }

  override get type() {
    return NodeModelTypes.COMPONENT;
  }

  override get isContextNode() {
    return this.tree.navigatorViewInterface.isScenarioContextComponent(this.id);
  }

  getChildren() {
    return this._children;
  }

  override getItemHeight() {
    return HEIGHT_COMPONENT_ITEM;
  }

  override getLock() {
    return this.tree.navigatorViewInterface.getComponentLock(this.id);
  }

  override getRepresentationData() {
    return this.tree.navigatorViewInterface.getComponentRepresentationData(
      this.id
    );
  }

  override getCSSFilterColor() {
    return this.tree.navigatorViewInterface.getCSSFilterColor(this.id);
  }

  override isIncludedInContextByFilter() {
    return (
      this.tree.isIncludedInFilterTerm(this.getName()) &&
      this.tree.navigatorViewInterface.isIncludedInContextByFilter(this.id)
    );
  }

  override getModelTypeId() {
    return this.tree.navigatorViewInterface.getComponentTypeId(this.id);
  }

  override canHaveChildren() {
    return this.tree.navigatorViewInterface.canComponentHaveChildren(this.id);
  }

  override isFlexibleModel() {
    return this.tree.navigatorViewInterface.isComponentInFlexibleModel(this.id);
  }

  override getWorkspaceId() {
    return (
      this.tree.navigatorViewInterface.getRootWorkspaceIdOfComponent(this.id) ??
      ''
    );
  }

  override getOrder() {
    return this.tree.navigatorViewInterface.getComponentOrder(this.id) ?? 0;
  }

  override getName() {
    return this.tree.navigatorViewInterface.getComponentName(this.id) ?? '';
  }

  hasChildren() {
    const children =
      this.tree.navigatorViewInterface.getUnsortedComponentChildrenIds(this.id);
    return children ? children.length > 0 : false;
  }

  updateChildren() {
    const tree = this.tree;
    const children = tree.navigatorViewInterface
      .getSortedComponentChildrenIds(this.id)
      .map(componentId => tree.getOrCreateNode(tree, componentId, this));
    this._children = children;
  }

  override getVisualDiffType() {
    return this.tree.navigatorViewInterface.getVisualDiffType(this.id);
  }
}

export default ComponentNode;
