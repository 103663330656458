import { SubdivisionEditorSteps } from './navigation/types';
import { DetailsPage, detailsPageCommands } from './Steps/DetailsPage';
import { subdivisionEditorOperations } from './subdivisionEditorOperations';
import { zoneCommands } from './Steps/permissionsConfiguration/zones/commands';
import BindWorkspaces from './Steps/BindWorkspaces/BindWorkspaces';
import { bindWorkspacesCommands } from './Steps/BindWorkspaces/commands';
import { Features, hasFeature } from '@ardoq/features';
import { bindWorkspacesOperations } from './Steps/BindWorkspaces/bindWorkspacesOperations';
import { zonesOperations } from './Steps/permissionsConfiguration/zones/operations';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { getPermissionConfigurationSubSteps } from './stepsUtils';
import { selectComponentsStepConfig } from './Steps/SelectComponents/stepConfig';
import { NewPermissionsConfiguration } from './Steps/permissionsConfiguration/NewPermissionsConfiguration';
import { StepConfig } from './Steps/types';
import { editOnlyStepIsDisabled, isForInternalDev } from './Steps/utils';
import { SummaryPage } from './Steps/Summary/SummaryPage';

/* ------------------------------------------------------------------------------------
   ------------------------------------------------------------------------------------
   -----------------------      STEPS CONFIGURATION      ------------------------------
   ------------------------------------------------------------------------------------
   --------------------------------------------------------------------------------- */

export const STEPS: readonly StepConfig[] = [
  {
    step: SubdivisionEditorSteps.DETAILS,
    stepperHeading: 'General information',
    title: 'General information',
    subtitle: SUBDIVISIONS_STRINGS.STEPS.DETAILS.SUBTITLE,
    maxWidth: '1070px',
    isConfigured: subdivisionEditorState =>
      !subdivisionEditorOperations.isCreationMode({
        subdivisionId: subdivisionEditorState.subdivisionId,
      }),
    component: DetailsPage,
    stepValidation: detailsPageCommands.stepValidation,
    onSave: detailsPageCommands.saveDetails,
    nextButtonLabel: subdivisionEditorState =>
      subdivisionEditorOperations.isCreationMode(subdivisionEditorState)
        ? hasFeature(Features.PERMISSION_ZONES_INTERNAL)
          ? 'Next: Select components'
          : 'Next: Select scope'
        : null,
    onNextButtonClicked: detailsPageCommands.onNextButtonClicked,
    nextButtonIsDisabled: detailsPageCommands.nextButtonIsDisabled,
    nextButtonHintMessage: detailsPageCommands.getNextButtonHintMessage,
  },
  selectComponentsStepConfig,
  {
    step: SubdivisionEditorSteps.WORKSPACES_BINDING,
    stepperHeading: 'Select scope',
    title: 'Select scope',
    subtitle: SUBDIVISIONS_STRINGS.STEPS.WORKSPACES_BINDING.SUBTITLE,
    isConfigured: subdivisionEditorState =>
      bindWorkspacesOperations.hasDataConfigured(
        subdivisionEditorState.bindWorkspacesState
      ),
    component: BindWorkspaces,
    isDisabled: editOnlyStepIsDisabled,
    onSave: bindWorkspacesCommands.saveWorkspaceBindings,
    isHidden: isForInternalDev,
  },
  {
    step: SubdivisionEditorSteps.PERMISSIONS_CONFIGURATION,
    stepperHeading: 'Configure permissions',
    title: 'Configure permissions',
    subtitle: SUBDIVISIONS_STRINGS.STEPS.PERMISSIONS_CONFIGURATION.SUBTITLE,
    nextButtonLabel: 'Next: Summary',
    nextButtonHintMessage: subdivisionEditorState =>
      subdivisionEditorOperations.hasUnsavedChanges(subdivisionEditorState)
        ? 'Save changes to view the summary'
        : undefined,
    subSteps: getPermissionConfigurationSubSteps,
    isConfigured: subdivisionEditorState =>
      zonesOperations.hasDataConfigured(subdivisionEditorState.zonesState),
    stepValidation: zonesOperations.stepValidation,
    component: NewPermissionsConfiguration,
    isDisabled: editOnlyStepIsDisabled,
    onSave: zoneCommands.savePermissions,
  },
  {
    step: SubdivisionEditorSteps.SUMMARY,
    stepperHeading: 'Summary',
    title: 'Summary',
    subtitle: subdivisionEditorState =>
      subdivisionEditorState.subdivision.description,
    isConfigured: subdivisionEditorState =>
      !subdivisionEditorOperations.hasUnsavedChanges(subdivisionEditorState),
    component: SummaryPage,
    isDisabled: subdivisionEditorState =>
      editOnlyStepIsDisabled(subdivisionEditorState) ||
      subdivisionEditorOperations.hasUnsavedChanges(subdivisionEditorState),
  },
] as const;
