import {
  APIFieldType,
  APIOrganizationUser,
  ReportColumn,
  SortOrder,
} from '@ardoq/api-types';
import { Maybe } from '@ardoq/common-helpers';
import { fieldOperations } from '@ardoq/core';
import { EnhancedScopeData } from '@ardoq/data-model';
import { SelectOption } from '@ardoq/select';
import { uniq } from 'lodash';
import { InventoryColumnDefinitionWithOptionList } from './types';

const userToOption = (user: APIOrganizationUser) => ({
  label: `${user.name} (${user.email})`,
  value: user._id,
});

const getListOptions = (
  scopeData: Maybe<EnhancedScopeData>,
  column: ReportColumn
) => {
  const field = scopeData?.fields.find(field => field.name === column.key);
  return field
    ? fieldOperations
        .getListFieldOptions(field)
        .map(option => ({ label: option, value: option }))
    : [];
};

const getComponentTypeOptions = (scopeData: Maybe<EnhancedScopeData>) => {
  const componentTypeNames = Object.values(
    scopeData?.typesByModelId ?? {}
  ).flatMap(type =>
    Object.values(type.componentTypesById).flatMap(
      componentType => componentType.name
    )
  );

  return uniq(componentTypeNames).map(option => ({
    label: option,
    value: option,
  }));
};

export const apiColumnDefinitionToColumWithOptionsAndSort = (
  scopeData: Maybe<EnhancedScopeData>,
  column: ReportColumn,
  users: APIOrganizationUser[]
): InventoryColumnDefinitionWithOptionList => {
  let options: SelectOption<string>[] = [];
  if (column.dataType === APIFieldType.USER) {
    options = users.map(userToOption);
  } else if (
    column.dataType === APIFieldType.LIST ||
    column.dataType === APIFieldType.SELECT_MULTIPLE_LIST
  ) {
    options = getListOptions(scopeData, column);
  }
  if (column.key === 'type') {
    options = getComponentTypeOptions(scopeData);
  }

  return {
    ...column,
    sort: column.key === 'name' ? SortOrder.ASC : null,
    options,
  };
};
