import { ReactNode } from 'react';
import styled from 'styled-components';
import { Text } from '@ardoq/typography';
import { ErrorNotification, WarningNotification } from '@ardoq/status-ui';
import { FlexBox, Stack } from '@ardoq/layout';

type DetailsDialogRowProps = {
  readonly label: string;
  readonly value: ReactNode | string | null;
  readonly errors?: string[];
  readonly warnings?: string[];
};

const Label = styled(Text)`
  width: 160px;
`;

const Value = styled(Text).attrs({ variant: 'text2Bold' })`
  width: 260px;
  word-break: break-word;
`;

const Messages = styled(Stack)`
  flex-shrink: 1;
  width: min-content;
`;

const DetailsDialogRow = ({
  label,
  value,
  errors = [],
  warnings = [],
}: DetailsDialogRowProps) => {
  return (
    <FlexBox align="flex-start">
      <Label>{label}</Label>
      <Messages gap="small">
        <Value>{value}</Value>
        {errors.length > 0 &&
          errors.map(error => (
            <ErrorNotification key={error}>{error}</ErrorNotification>
          ))}
        {warnings.length > 0 &&
          warnings.map(warning => (
            <WarningNotification key={warning}>{warning}</WarningNotification>
          ))}
      </Messages>
    </FlexBox>
  );
};

export default DetailsDialogRow;
