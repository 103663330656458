import { dispatchAction } from '@ardoq/rxbeach';
import { copyTokenAction, deleteTokenAction } from './actions';
import { delay } from 'lodash';
import {
  API_TOKENS_EVENTS,
  trackAPITokensEvent,
} from '../apiTokensTrackingUtils';

const SECONDS_TO_DISAPPEAR = 5000;

const copyToken = (token: string) => {
  dispatchAction(copyTokenAction());
  trackAPITokensEvent(API_TOKENS_EVENTS.COPIED_TOKEN);
  navigator.clipboard.writeText(token);
  delay(() => dispatchAction(deleteTokenAction(token)), SECONDS_TO_DISAPPEAR);
};

export const copyAndDisappearTokenCommands = {
  copyToken,
};

export type CopyAndDisappearTokenCommands =
  typeof copyAndDisappearTokenCommands;
