import GridEditorBottomBar2023 from 'gridEditor2023/bottomBar/BottomBarView';
import DockedGridEditor2023LayoutContainer from 'gridEditor2023/DockedGridEditorLayoutContainer';
import { useCallback, useRef } from 'react';
import { connect } from '@ardoq/rxbeach';
import ContextBar from 'contextBar/ContextBar';
import { MainAppLayoutProps } from './types';
import {
  ContextBarOuterContainer,
  MainContainer,
  ResizeBackdrop,
  RightEditorContainer,
} from './atoms';
import { HorizontalResizer, VerticalResizer } from './Resizers';
import ViewPaneContainer from './ViewPaneContainer';
import { appLayout$ } from './appLayout$';
import {
  MAIN_PANE_LEFT,
  MAIN_PANE_RIGHT,
} from 'streams/views/mainContent/types';
import { RightPane } from 'views/mainApp/rightPane/rightPane';

const MainApp = ({
  isBottomBarVisible,
  isEditorPanelVisible,
  editorPaneStyle,
  contentBarStyle,
  verticalResizeStyle,
  isVerticalResizing,
  isHorizontalResizing,
  leftPaneStyle,
  isSplitPane,
  rightPaneStyle,
  horizontalResizeStyle,
  isRightEditorPaneOpen,
  rightEditorPaneStyle,
  isDetailsDrawerOpen,
  isViewpointMode,
}: MainAppLayoutProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const getBox = useCallback(
    () => containerRef.current?.getBoundingClientRect() ?? null,
    [containerRef]
  );

  return (
    <MainContainer ref={containerRef}>
      {(isVerticalResizing || isHorizontalResizing) && <ResizeBackdrop />}
      <ViewPaneContainer
        style={leftPaneStyle}
        location={MAIN_PANE_LEFT}
        isSplitPane={isSplitPane}
        isRightEditorPaneOpen={isRightEditorPaneOpen}
        isDetailsDrawerOpen={isDetailsDrawerOpen}
      />
      {!isViewpointMode && (isSplitPane || isRightEditorPaneOpen) && (
        <HorizontalResizer
          style={horizontalResizeStyle}
          isActive={isHorizontalResizing}
          getBox={getBox}
        />
      )}
      {isSplitPane && !isRightEditorPaneOpen && (
        <ViewPaneContainer
          style={rightPaneStyle}
          location={MAIN_PANE_RIGHT}
          isSplitPane={isSplitPane}
          isDetailsDrawerOpen={isDetailsDrawerOpen}
        />
      )}
      {!isViewpointMode && (
        <RightEditorContainer style={rightEditorPaneStyle}>
          <RightPane isViewpointMode={isViewpointMode} />
        </RightEditorContainer>
      )}
      {isEditorPanelVisible && (
        <VerticalResizer
          style={verticalResizeStyle}
          isActive={isVerticalResizing}
          getBox={getBox}
        />
      )}

      <DockedGridEditor2023LayoutContainer style={editorPaneStyle} />

      {!isViewpointMode && <ContextBar style={contentBarStyle} />}
      {isBottomBarVisible && (
        <ContextBarOuterContainer>
          <GridEditorBottomBar2023 />
        </ContextBarOuterContainer>
      )}
    </MainContainer>
  );
};

export default connect(MainApp, appLayout$);
