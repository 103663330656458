import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { filter, map, switchMap, combineLatest, Observable } from 'rxjs';
import { selectionState$ } from './selectionState/selectionState$';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { getLoadingMessage } from '../utils';

export type OverlayState = {
  isLoading: boolean;
  message: string;
};

export const overlay$: Observable<OverlayState> = integrationId$.pipe(
  filter(integrationId => integrationId === 'microsoft-entra-id'),
  switchMap(integrationId => {
    return combineLatest([
      selectionState$,
      getTransferStateStream(integrationId).pipe(
        map(val => ({ asyncStatus: val.requestStatus, dryRun: val.dryRun }))
      ),
    ]);
  }),
  map(streams => {
    const [selectionState, transferState] = streams;
    return {
      isLoading: streams.some(stream => stream.asyncStatus === 'LOADING'),
      message: getLoadingMessage({
        selectionState,
        transferState,
      }),
    };
  })
);
