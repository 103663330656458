import { FormSize, TextInput } from '@ardoq/forms';
import { IconName } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import { setFilterTerm } from '../actions';
import { NavigatorTopBarStreamShape } from '../NavigatorTopBar/types';
import { FlexBox } from '@ardoq/layout';

const ViewpointNavigatorFilter = ({
  filterState,
}: Pick<NavigatorTopBarStreamShape, 'filterState'>) => {
  return (
    <FlexBox padding="small">
      <TextInput
        formSize={FormSize.FULL}
        value={filterState.filterTerm}
        placeholder="Search open components"
        leftIconName={IconName.SEARCH}
        onValueChange={term => dispatchAction(setFilterTerm(term))}
        onClear={() => {
          dispatchAction(setFilterTerm(''));
        }}
      />
    </FlexBox>
  );
};

export default ViewpointNavigatorFilter;
