import { colors, s16, s24, spacing } from '@ardoq/design-tokens';
import { dispatchAction } from '@ardoq/rxbeach';
import { ViewPaneHeaderProps } from 'appLayout/ardoqStudio/types';
import { setActiveMainTabLeft } from 'streams/views/mainContent/actions';
import styled from 'styled-components';
import { ViewOptionsRow } from './atoms';
import { Icon, IconName, KnowledgeBaseIcon } from '@ardoq/icons';
import { text2 } from '@ardoq/typography';
import { getViewpointModeSupportedViews } from 'traversals/getViewpointModeSupportedViews';
import {
  NumberVisualProperties,
  Pagination,
  PaginationController,
  RenderArgs,
} from '@ardoq/pagination';
import { Attributes } from 'react';
import viewInfo from './viewInfo';
import { CardProps } from '@ardoq/card';
import { trackViewStyleChange } from '../tracking';
import { BetaBadge } from '@ardoq/status-ui';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
  border-bottom: 1px solid ${colors.grey90};
`;
const PickerContainer = styled.div`
  display: flex;
  height: 156px;
  width: 100%;
  padding: ${spacing.s4} 0;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
const CardsContainer = styled.div`
  display: flex;
  height: 120px;
  padding: 0 ${s16} ${s16} ${s16};
  align-items: center;
  align-content: center;
  gap: ${s24} ${s16};
  flex-shrink: 0;
  align-self: stretch;
`;
const InfoRow = styled(ViewOptionsRow)`
  padding: ${s16};
  color: ${colors.grey50};
`;

const InfoBox = styled.div`
  display: flex;
  height: 24px;
  padding: 0;
  align-items: center;
  gap: ${spacing.s4};
  flex: 1 0 0;
`;

const InfoLabel = styled.label`
  flex: 1 0 0;
  margin: 0;
  color: ${colors.grey60};
  ${text2}
`;

const VISIBLE_VIEW_COUNT = 3;

type CardInfo = Pick<Attributes, 'key'> & ViewPickerCardProperties;
type ViewPickerCardProperties = Pick<
  CardProps,
  'media' | 'title' | 'onClick'
> & { isSelected: boolean };

type CardContainerProperties = { $isActive: boolean };
const CardContainer = styled.div<CardContainerProperties>`
  display: flex;
  height: 106px;
  width: 130px;
  min-width: 118.182px;
  max-width: 141.818px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 9.455px;
  border: ${({ $isActive }) =>
    $isActive
      ? `1.18182px solid ${colors.brand50}`
      : `0.591px solid ${colors.grey80}`};
  &:hover {
    box-shadow:
      0 0 1.182px 0 rgba(0, 0, 0, 0.2),
      0 1.182px 5.909px 0 rgba(0, 0, 0, 0.14);
  }
  background: ${colors.white};
  cursor: pointer;
`;

const ImageContainer = styled.div`
  position: relative;
  flex: 1;

  img {
    height: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  min-height: fit-content;
  padding: ${spacing.s8};
  flex-direction: column;
  align-items: flex-start;
  gap: 18.909px;
  align-self: stretch;
`;

const TitleContent = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 9.455px;
  align-self: stretch;
`;

const TitleContentInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.364px;
  flex: 1 0 0;
  color: ${colors.grey15};
  text-overflow: ellipsis;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 11.818px; /* 90.909% */
`;
const ViewPickerCard = ({
  media,
  title,
  onClick,
  isSelected,
}: ViewPickerCardProperties) => (
  <CardContainer onClick={onClick} $isActive={isSelected}>
    {media}
    <TitleContainer>
      <TitleContent>
        <TitleContentInner>
          <TextContainer>{title}</TextContainer>
        </TitleContentInner>
      </TitleContent>
    </TitleContainer>
  </CardContainer>
);
const PaginationContainer = styled.div`
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
`;

const BulletElementContainer = styled.div`
  display: flex;
  width: 16px;
  height: 32px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
type BulletElementProperties = { $isActive: boolean };
const BulletElement = styled.div<BulletElementProperties>`
  display: flex;
  width: 8px;
  height: 8px;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 20px;
  background: ${({ $isActive }) => ($isActive ? colors.grey25 : colors.grey90)};
  cursor: pointer;
`;
const Bullet = ({ isActive, onClick }: NumberVisualProperties) => (
  <BulletElementContainer>
    <BulletElement $isActive={isActive} onClick={onClick} />
  </BulletElementContainer>
);

const CardsPage = ({
  currentPageDataSource,
  currentPageNumber,
  perPage,
  totalResults,
  onPageSelect,
}: RenderArgs<CardInfo>) => (
  <PickerContainer>
    <CardsContainer>
      {currentPageDataSource.map(({ key, ...cardProps }) => (
        <ViewPickerCard key={key} {...cardProps} />
      ))}
    </CardsContainer>
    <PaginationContainer>
      <Pagination
        currentPageNumber={currentPageNumber}
        perPage={perPage}
        totalResults={totalResults}
        onPageSelect={onPageSelect}
        NumberVisual={Bullet}
      />
    </PaginationContainer>
  </PickerContainer>
);
const BETA_ICON_MARGIN = 4;
const ViewPicker = ({ mainViewId, views }: ViewPaneHeaderProps) => {
  const viewLabels = new Map(views.map(({ id, name }) => [id, name]));
  const viewIds = getViewpointModeSupportedViews();
  const { knowledgeBaseLink, description, isBeta, badgeKnowledgeBaseLink } =
    viewInfo[mainViewId] ?? {};
  const cardInfo: CardInfo[] = viewIds.map(viewId => ({
    key: viewId,
    title: viewLabels.get(viewId) || '',
    media: (
      <ImageContainer>
        <img width="130" height="73" src={viewInfo[viewId]?.image} />
        {viewInfo[viewId]?.isBeta && (
          <Icon
            iconName={IconName.BETA}
            color={colors.blue50}
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              margin: BETA_ICON_MARGIN,
            }}
          />
        )}
      </ImageContainer>
    ),
    onClick: () => {
      trackViewStyleChange(mainViewId, viewId);
      dispatchAction(setActiveMainTabLeft({ activeTabId: viewId }));
    },
    isSelected: mainViewId === viewId,
  }));

  const startPageNumber =
    1 + Math.floor(viewIds.indexOf(mainViewId) / VISIBLE_VIEW_COUNT);

  return (
    <Container>
      <PaginationController
        startPageNumber={startPageNumber}
        perPage={VISIBLE_VIEW_COUNT}
        dataSource={cardInfo}
        render={CardsPage}
      />
      <InfoRow>
        <InfoBox>
          {knowledgeBaseLink && (
            <KnowledgeBaseIcon
              color={colors.grey60}
              style={{ cursor: 'pointer' }}
              onClick={event => {
                event.stopPropagation();
                window.open(knowledgeBaseLink, '_blank noreferrer');
              }}
            />
          )}
          <InfoLabel>{description || 'Learn about the view'}</InfoLabel>
          {isBeta && <BetaBadge knowledgeBaseUrl={badgeKnowledgeBaseLink} />}
        </InfoBox>
      </InfoRow>
    </Container>
  );
};

export default ViewPicker;
