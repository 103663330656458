import {
  addSlideFromCurrentContext,
  copySlideFromCurrentPresentation,
  replaceSlideWithCurrentContext,
  showPresentationEditor,
} from './actions';
import Context from 'context';
import GroupByCollection from 'collections/groupByCollection';
import viewInterface from 'streams/views/mainContent/viewInterface';
import Filters from 'collections/filters';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { maybeShowEnforceFiltersDialog } from './enforceFiltersDialog';
import { type NewSlide, type NewVisualizationSlide } from './types';
import selectedModule$ from 'appContainer/selectedModule$';
import { AppModules } from 'appContainer/types';
import type { ApiDynamicFilter } from 'filters/dynamicFilters';
import dynamicFilters from 'filters/dynamicFilters';
import { hideRightPane, togglePresentationEditor } from 'appContainer/actions';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { selectSlideForEditing } from 'presentationEditor/actions';
import metamodelNavigation$ from 'metamodel/navigation/metamodelNavigation$';
import togglePresentationEditor$ from 'appContainer/togglePresentationEditor$';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { Features, hasFeature } from '@ardoq/features';
import { trackAddScenarioToPresentationSlide } from 'scope/tracking';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { omit, pick } from 'lodash';
import { getConnectedWorkspaceIds } from 'streams/linkedWorkspaces$';
import { info, ModalSize } from '@ardoq/modal';
import * as gridEditor2023Actions from 'gridEditor2023/actions';
import type { GroupByBackboneModel, PerspectiveFilters } from 'aqTypes';
import type {
  APIPresentationAssetAttributes,
  APISlideAttributes,
  ArdoqId,
  PersistedMetamodel,
  ReportSlideParams,
  ViewIds,
} from '@ardoq/api-types';
import {
  hasLoadedState,
  isLucidSlide,
  isVisualizationSlide,
  LoadedState,
  Report,
  SlideTypes,
} from '@ardoq/api-types';
import { componentInterface } from '@ardoq/component-interface';
import { ContextShape } from '@ardoq/data-model';
import { workspaceInterface } from '@ardoq/workspace-interface';
import reportNavigation$ from 'report/navigation/reportNavigation$';
import { context$ } from 'streams/context/context$';
import reports$ from 'streams/reports/reports$';
import { loadedState$ } from 'loadedState/loadedState$';
import dashboardNavigation$ from '../dashboard/navigation/dashboardNavigation$';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { presentationAccessControlOperations } from 'resourcePermissions/accessControlHelpers/presentations';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { subdivisionsOperations } from '@ardoq/subdivisions';
import presentations$ from 'streams/presentations/presentations$';
import { presentationOperations } from 'streams/presentations/presentationOperations';
import { handleError, presentationApi, slideApi } from '@ardoq/api';
import reportReader$ from '../report/ReportReader/reportReader$';
import { filterQueryToApiReportFilterQuery } from '@ardoq/report-reader';
import { showToast, ToastType } from '@ardoq/status-ui';

type GetSlideAttributesFromAppStateArgs = {
  context: ContextShape;
  viewId: ViewIds;
  viewstate: Record<string, any>;
  activeGroupBys: GroupByBackboneModel[];
  filters: PerspectiveFilters;
  dynamicFilters: ApiDynamicFilter[];
  loadedStates: LoadedState[];
  subdivisionIds: ArdoqId[];
  scenarioId: string | null;
};
const getSlideAttributesFromAppState = ({
  context,
  viewId,
  viewstate,
  activeGroupBys,
  filters,
  dynamicFilters,
  loadedStates,
  subdivisionIds,
  scenarioId,
}: GetSlideAttributesFromAppStateArgs) => {
  const name = context.componentId
    ? componentInterface.getDisplayName(context.componentId)
    : workspaceInterface.getWorkspaceName(context.workspaceId);

  // All workspaces in the slide and the workspaces
  // that have a connection to them
  // have been "considered" for this slide
  const consideredWorkspaceIds = Array.from(
    getConnectedWorkspaceIds(context.workspacesIds)
  ).filter(workspaceId =>
    // In presentation mode we can still get workspace ids we don't have access
    // to.
    workspaceInterface.workspaceExists(workspaceId)
  );
  return {
    type: SlideTypes.VISUALIZATION,
    view: viewId,
    name: name || '',
    description: '',
    workspaceId: context.workspaceId,
    workspaceIds: context.workspacesIds,
    consideredWorkspaceIds,
    componentId: context.componentId ? context.componentId : null,
    selectedScenarioId: context.scenarioId,
    isInVisualDiffMode: isInScopeDiffMode(),
    viewstate,
    sortAttr: context.sort.attr,
    sortOrder: context.sort.order,
    filters,
    dynamicFilters,
    groupBys: activeGroupBys,
    loadedStates: loadedStates
      .filter(loadedState => !loadedState.isHidden)
      .map(loadedState => pick(loadedState, ['data', 'type', 'isHidden'])),
    ...(hasFeature(Features.PERMISSION_ZONES_INTERNAL)
      ? { subdivisionIds }
      : {}),
    ...(scenarioId ? { scenarioId } : {}),
  };
};

type CreateSlideFromViewArgs = {
  context: ContextShape;
  viewId: ViewIds;
  viewstate?: Record<string, any>;
};
export const createSlideFromView = ({
  context,
  viewId,
  viewstate = {},
}: CreateSlideFromViewArgs) => {
  const connectedWorkspaceIds = Array.from(
    context.connectedWorkspaceIds
  ).filter(workspaceId =>
    // In presentation mode we can still get workspace ids we don't have access
    // to.
    workspaceInterface.workspaceExists(workspaceId)
  );

  // If there is no workspace filter, forcefully add one
  if (!Filters.workspaceFilter) {
    Filters.updateWorkspaceFilter(connectedWorkspaceIds);
    maybeShowEnforceFiltersDialog();
  }

  return getSlideAttributesFromAppState({
    context,
    viewId,
    viewstate,
    activeGroupBys: GroupByCollection.filter(groupBy => groupBy.isValid()),
    filters: Filters.getPersistedFilters(),
    dynamicFilters: dynamicFilters.getDefinitions(),
    loadedStates: hasLoadedState(loadedState$.state)
      ? structuredClone(loadedState$.state)
      : [],
    subdivisionIds: subdivisionsOperations.getSortedSubdivisionsIds(
      subdivisions$.state
    ),
    scenarioId: getActiveScenarioId(),
  });
};

const createSlideFromCurrentView = (
  context: ContextShape,
  visualizationSlide?: NewVisualizationSlide
) => {
  const mainView = (
    visualizationSlide?.viewId
      ? viewInterface.getViewById(visualizationSlide.viewId)
      : viewInterface.getMainView()
  )!;

  return createSlideFromView({
    context,
    viewId: mainView.id,
    viewstate: mainView.getViewstate ? mainView.getViewstate() : {},
  });
};

const getSlideAttributes = (newSlide: NewSlide, context: ContextShape) => {
  switch (newSlide.type) {
    case SlideTypes.VISUALIZATION:
      return createSlideFromCurrentView(context, newSlide);
    case SlideTypes.METAMODEL:
      return {
        name: newSlide.name,
        description: '',
        metamodelId: newSlide.metamodelId,
        fullOrg: newSlide.fullOrg,
        type: SlideTypes.METAMODEL,
      };
    case SlideTypes.DASHBOARD:
      return {
        name: newSlide.dashboardName,
        description: '',
        dashboardId: newSlide.dashboardId,
        type: SlideTypes.DASHBOARD,
      };
    case SlideTypes.REPORT:
      return {
        name: newSlide.reportName,
        description: '',
        reportId: newSlide.reportId,
        type: SlideTypes.REPORT,
        reportParams: newSlide.reportParams,
      };
    case SlideTypes.LUCID:
      return {
        name: newSlide.name,
        description: '',
        lucidDocumentId: newSlide.lucidDocumentId,
        type: SlideTypes.LUCID,
      };
  }
};

const getReplaceSlideAttributes = (
  selectedModule: AppModules,
  selectedDashboardId: ArdoqId | undefined,
  selectedReport: Report,
  context: ContextShape,
  metamodel?: PersistedMetamodel,
  reportParams?: ReportSlideParams
) => {
  switch (selectedModule) {
    case AppModules.DASHBOARDS:
      return {
        dashboardId: selectedDashboardId,
        type: SlideTypes.DASHBOARD,
      };
    case AppModules.REPORTS:
      return {
        reportId: selectedReport._id,
        type: SlideTypes.REPORT,
        reportParams,
      };
    case AppModules.METAMODEL:
      return {
        metamodelId: metamodel!._id,
        type: SlideTypes.METAMODEL,
      };
    case AppModules.WORKSPACES:
      return omit(createSlideFromCurrentView(context), 'name', 'description');
  }
};

const userCanAddToPresentation = (
  presentation: APIPresentationAssetAttributes
) =>
  presentationAccessControlOperations.canEditPresentation(
    currentUserInterface.getPermissionContext(),
    presentation
  );

const showInfoMessage = () =>
  info({
    title: 'Your slide could not be added',
    text: 'If you believe this is incorrect, please contact an admin or customer support.',
    errorMessage:
      'You do not have permission to add slides to this presentation.',
    modalSize: ModalSize.S,
  });

const handleAddSlideFromCurrentContext = routine(
  ofType(addSlideFromCurrentContext),
  extractPayload(),
  filter(({ presentation }) => {
    if (!userCanAddToPresentation(presentation)) {
      // this is a hack because we're doing a side-effect
      // inside a filter :shrug:
      showInfoMessage();
      return false;
    }
    return true;
  }),
  withLatestFrom(context$),
  switchMap(([{ presentation, newSlide }, context]) => {
    const slide = getSlideAttributes(newSlide, context) as APISlideAttributes;
    return presentationApi.addSlide(presentation._id, slide);
  }),
  handleError(),
  tap(({ slide }) => {
    dispatchAction(selectSlideForEditing(slide));
    if (isVisualizationSlide(slide) && slide.scenarioId) {
      trackAddScenarioToPresentationSlide(slide.view);
    }
    if (isLucidSlide(slide)) {
      showToast(
        'Lucid document added successfully to presentation',
        ToastType.SUCCESS
      );
    }
  })
);

const handleCopySlideFromCurrentPresentation = routine(
  ofType(copySlideFromCurrentPresentation),
  extractPayload(),
  switchMap(({ slide, presentationId }) => {
    const freshSlide = omit({ ...slide }, ['_id', '_version']);
    return presentationApi.addSlide(
      presentationId,
      freshSlide as APISlideAttributes
    );
  }),
  handleError(),
  tap(({ presentation }) => {
    dispatchAction(
      showPresentationEditor({ presentationId: presentation._id })
    );
  })
);

const hidePresentationEditorWhenGridEditorIsShown = routine(
  ofType(gridEditor2023Actions.showGridEditor),
  tap(() => {
    if (togglePresentationEditor$.state) {
      dispatchAction(
        togglePresentationEditor({
          shouldShow: false,
        })
      );
    }
  })
);

const handleShowPresentationEditor = routine(
  ofType(showPresentationEditor),
  extractPayload(),
  withLatestFrom(presentations$),
  tap(([{ presentationId }, presentations]) => {
    if (!presentationId) {
      Context.setPresentation(null);
      dispatchAction(hideRightPane());
      dispatchAction(
        togglePresentationEditor({
          shouldShow: false,
        })
      );
      return;
    }

    const presentation = presentationOperations.getPresentationById(
      presentations,
      presentationId
    );

    if (hasFeature(Features.PRESENTATIONS) && presentation) {
      Context.setPresentation(presentation);
      dispatchAction(
        togglePresentationEditor({
          shouldShow: true,
        })
      );
    }
  })
);

const handleTogglePresentationEditor = routine(
  ofType(togglePresentationEditor),
  extractPayload(),
  tap(({ shouldShow }) => {
    if (shouldShow) {
      // close anything that's open in the cyber editor. this will also fix visual pane view tab size
      dispatchAction(hideRightPane());
    }
  })
);

const handleReplaceSlidesWithCurrentContext = routine(
  ofType(replaceSlideWithCurrentContext),
  extractPayload(),
  withLatestFrom(
    reports$,
    reportReader$,
    selectedModule$,
    dashboardNavigation$,
    reportNavigation$,
    metamodelNavigation$,
    context$
  ),
  map(
    ([
      targetSlide,
      reports,
      { filterQuery, sort },
      { selectedModule },
      { selectedDashboardId },
      selectedReport,
      { metamodel },
      context,
    ]) =>
      ({
        ...targetSlide,
        ...getReplaceSlideAttributes(
          selectedModule,
          selectedDashboardId,
          reports.byId[selectedReport.reportId!],
          context,
          metamodel,
          {
            sort: sort.columnKey,
            order: sort.order,
            filters: filterQueryToApiReportFilterQuery(filterQuery),
          }
        ),
      }) as APISlideAttributes
  ),
  switchMap(slideApi.update),
  handleError()
);

export default collectRoutines(
  handleAddSlideFromCurrentContext,
  hidePresentationEditorWhenGridEditorIsShown,
  handleTogglePresentationEditor,
  handleReplaceSlidesWithCurrentContext,
  handleShowPresentationEditor,
  handleCopySlideFromCurrentPresentation
);
