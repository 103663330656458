import { IconDecorator } from '@ardoq/decorators';
import { AsyncMultiselect, SelectOption } from '@ardoq/select';
import { CheckboxGroupItem, FormSize } from '@ardoq/forms';
import {
  entityTypeFilterIconMap,
  entityTypeFilterNameMap,
} from '@ardoq/audit-log';
import { isNull } from 'lodash';
import { ChangeTypeFilterProps } from '../types';
import { Box, Stack } from '@ardoq/layout';

const ChangeTypeFilter = ({
  selectedOptions,
  entityType,
  loadOptions,
  onSelectValueChange,
}: ChangeTypeFilterProps) => {
  return (
    <Box width="full">
      <Stack>
        <CheckboxGroupItem value={entityType}>
          <IconDecorator iconName={entityTypeFilterIconMap[entityType]}>
            {entityTypeFilterNameMap[entityType]}
          </IconDecorator>
        </CheckboxGroupItem>
        {!isNull(selectedOptions) ? (
          <Box marginLeft="xlarge" marginTop="small">
            <AsyncMultiselect
              formSize={FormSize.FULL}
              loadOptions={loadOptions}
              value={selectedOptions}
              onChange={values =>
                onSelectValueChange(
                  values as Array<string | SelectOption<string>> | null,
                  entityType
                )
              }
              cacheOptions
            />
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
};

export default ChangeTypeFilter;
