import { ComponentInlineInfo } from './atoms';

type ComponentParentInfoProperties = { text: string | null };
const ComponentParentInfo = ({ text }: ComponentParentInfoProperties) => {
  if (!text) return null;
  return (
    <ComponentInlineInfo>
      <b>parent:&nbsp;</b>
      {text}
    </ComponentInlineInfo>
  );
};

export default ComponentParentInfo;
