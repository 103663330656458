import {
  collectRoutines,
  dispatchAction,
  routine,
  carry,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  getInAppNotificationsList,
  getInAppNotificationsListSuccess,
  onNotificationClick,
  setReadStatus,
  submitMarkNotifications,
  submitMarkNotificationsSuccess,
  toggleInAppNotificationsList,
} from './actions';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  NotificationCardProps,
  notificationOperations,
} from '@ardoq/in-app-notifications';
import {
  NotificationApiResponse,
  isBroadcastTaskNotification,
  isChangeApprovalNotification,
  isDummyNotification,
  isRequestAccessNotification,
} from '@ardoq/api-types';
import { openReviewAccessRequestDialog } from 'resourcePermissions/requestAccess/actions';
import { handleError, notificationApi } from '@ardoq/api';
import { trackOpenChangeApprovalPage } from '../../surveyAdmin/tracking';
import { navigateToChangeApprovalPage } from '../../surveyAdmin/navigation/actions';
import { Features, hasFeature } from '@ardoq/features';
import { ExcludeFalsy, getIsOrgAdmin } from '@ardoq/common-helpers';
import currentUser$ from 'streams/currentUser/currentUser$';
import { websocketConnected } from 'sync/actions';

const dismissNotification = (id: string) => () =>
  dispatchAction(
    submitMarkNotifications([{ notificationId: id, status: 'dismissed' }])
  );

export const apiDataToNotificationCard = (
  notification: NotificationApiResponse
): NotificationCardProps | undefined => {
  if (isRequestAccessNotification(notification)) {
    return notificationOperations.decorateRequestAccessNotification(
      notification,
      () => dispatchAction(onNotificationClick(notification)),
      dismissNotification(notification._id)
    );
  }
  if (
    isChangeApprovalNotification(notification) &&
    hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2)
  ) {
    return notificationOperations.decorateChangeApprovalNotification(
      notification,
      () => dispatchAction(onNotificationClick(notification)),
      dismissNotification(notification._id)
    );
  }
  if (
    isBroadcastTaskNotification(notification) &&
    hasFeature(Features.BROADCAST_C2A_TASKS)
  ) {
    return notificationOperations.decorateBroadcastTaskNotification(
      notification,
      () => dispatchAction(onNotificationClick(notification)),
      dismissNotification(notification._id)
    );
  }
  if (isDummyNotification(notification)) {
    return notificationOperations.decorateDummyNotification(
      notification,
      () => dispatchAction(onNotificationClick(notification)),
      dismissNotification(notification._id)
    );
  }
};

const handleGetInAppNotificationsList = routine(
  ofType(getInAppNotificationsList),
  switchMap(notificationApi.fetchAll),
  handleError(),
  tap(notificationsPayload => {
    const notifications = notificationsPayload
      .map<NotificationCardProps | undefined>(apiDataToNotificationCard)
      .filter(ExcludeFalsy);

    dispatchAction(getInAppNotificationsListSuccess(notifications));
  })
);

const handleMarkNotifications = routine(
  ofType(submitMarkNotifications),
  extractPayload(),
  carry(switchMap(notificationApi.markNotifications)),
  tap(([markNotificationPayload]) => {
    dispatchAction(submitMarkNotificationsSuccess(markNotificationPayload));
  })
);

const handleOnNotificationClick = routine(
  ofType(onNotificationClick),
  extractPayload(),
  withLatestFrom(currentUser$),
  tap(([notification, currentUser]) => {
    if (isRequestAccessNotification(notification)) {
      dispatchAction(
        openReviewAccessRequestDialog({
          id: notification.details.callToActionId,
        })
      );
    }
    if (isChangeApprovalNotification(notification)) {
      trackOpenChangeApprovalPage('Notification');
      const { data } = notification;
      if (getIsOrgAdmin(currentUser)) {
        dispatchAction(
          navigateToChangeApprovalPage(data.changeRequest.surveyId)
        );
      } else {
        window.open(
          `/discover/my-tasks?changeRequestId=${data.changeRequest._id}&componentId=${data.changeRequest.componentId}&surveyId=${data.changeRequest.surveyId}`,
          '_blank'
        );
      }
    }
    if (isBroadcastTaskNotification(notification)) {
      const { data } = notification;
      trackOpenChangeApprovalPage('Notification');
      window.open(
        `/discover/my-tasks?broadcastInstanceId=${data.broadcastInstanceId}&componentId=${data.componentId}&surveyId=${data.contentId}`,
        '_blank'
      );
    }
    dispatchAction(setReadStatus({ id: notification._id }));
    dispatchAction(toggleInAppNotificationsList());
  })
);

const handleSetReadStatus = routine(
  ofType(setReadStatus),
  extractPayload(),
  tap(({ id }) => {
    notificationApi.markNotifications([{ notificationId: id, status: 'read' }]);
  })
);

const handleWebsocketConnected = routine(
  ofType(websocketConnected),
  tap(() => {
    dispatchAction(getInAppNotificationsList());
  })
);

export default collectRoutines(
  handleGetInAppNotificationsList,
  handleMarkNotifications,
  handleOnNotificationClick,
  handleSetReadStatus,
  handleWebsocketConnected
);
