import { LabeledValue } from 'aqTypes';
import { ArdoqId } from '@ardoq/api-types';
import { Description } from 'admin/bundles/atoms';
import { Paragraph } from '@ardoq/typography';
import { Multiselect, SelectOption } from '@ardoq/select';
import { Box } from '@ardoq/layout';

type CollectionSelectInputProps = {
  allOptions: LabeledValue<string>[];
  collectionLabel: string;
  selectedEntityIds: ArdoqId[];
  onOptionChange: (entityId: any) => void;
  description?: string;
};
type CollectionSelectInputOptions = {
  selectedOptions: SelectOption<string>[];
  unselectedOptions: SelectOption<string>[];
};

const CollectionSelectInput = ({
  allOptions,
  collectionLabel,
  selectedEntityIds,
  onOptionChange,
  description,
}: CollectionSelectInputProps) => {
  const { selectedOptions, unselectedOptions } = allOptions.reduce(
    (
      acc: CollectionSelectInputOptions,
      option: SelectOption<string>
    ): CollectionSelectInputOptions => {
      const isSelected = selectedEntityIds?.includes(option.value);
      if (isSelected) acc.selectedOptions.push(option);
      else acc.unselectedOptions.push(option);
      return acc;
    },
    { selectedOptions: [], unselectedOptions: [] }
  );

  const placeholder = !unselectedOptions.length
    ? `No ${collectionLabel}s found`
    : `Select a ${collectionLabel}`;
  const onChange = (options: string[] | null) =>
    onOptionChange(options?.map(value => value) ?? []);

  return (
    <>
      <Box paddingBottom="small">
        <Paragraph variant="text1Bold">{`Add ${collectionLabel}s`}</Paragraph>
      </Box>
      {description && <Description>{description}</Description>}
      <Multiselect
        placeholder={placeholder}
        options={unselectedOptions}
        isDisabled={!allOptions.length}
        onValueChange={onChange}
        isMulti={true}
        closeMenuOnSelect={false}
        value={selectedOptions}
        isClearable
      />
    </>
  );
};

export default CollectionSelectInput;
