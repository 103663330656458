import { UnifiedSourceConfig } from '@ardoq/api-types/integrations';
import fp from 'lodash/fp';
import { IntegrationResources, SelectedResource } from './types';
import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';

export const isValidResourcesSelection = (
  selectedResources: IntegrationResources['selectedResources']
) => {
  return Boolean(
    !fp.isEmpty(selectedResources) &&
      Object.keys(selectedResources).some(resourceId =>
        isValidResourceSelection(selectedResources[resourceId])
      )
  );
};

const isValidResourceSelection = (resource: SelectedResource) => {
  return !fp.isEmpty(resource.fields);
};

export const splitByAvailibility = (
  selectedResources: IntegrationResources['selectedResources'],
  resources: IntegrationResources['resources']
): Pick<IntegrationResources, 'unavailableResources' | 'selectedResources'> => {
  return fp.reduce(
    (ret, [resourceId, resource]) => {
      const availableResourceFieldIds = fp
        .get([resourceId, 'fields', 'definitions'], resources)
        .map(({ id }) => id);

      const [availableResourceFields, unavailableResourceFields] = fp.flow(
        fp.toPairs,
        fp.partition(([id, _]) => availableResourceFieldIds.includes(id))
      )(resource.fields);

      return fp.flow(
        fp.isEmpty(availableResourceFields)
          ? fp.identity
          : fp.set(['selectedResources', resourceId], {
              query: resource.query,
              fields: fp.fromPairs(availableResourceFields),
            }),
        fp.isEmpty(unavailableResourceFields)
          ? fp.identity
          : fp.set(['unavailableResources', resourceId], {
              query: resource.query,
              fields: fp.fromPairs(unavailableResourceFields),
            })
      )(ret);
    },
    { selectedResources: {}, unavailableResources: {} },
    fp.toPairs(selectedResources)
  );
};

export const selectedResourcesToSourceConfigResources = (
  selectedResources: IntegrationResources['selectedResources']
): UnifiedSourceConfig['resources'] => {
  return fp.flow(
    fp.toPairs,
    fp.filter(([_, resource]) => !fp.isEmpty(resource.fields)),
    fp.map(([resourceId, { fields, query }]) => ({
      id: resourceId,
      query,
      fields: fp.flow(
        fp.toPairs,
        fp.map(([fieldId]) => ({ id: fieldId }))
      )(fields),
    }))
  )(selectedResources);
};

export const sourceConfigResourcesToSelectedResources = (
  sourceConfigResources: UnifiedSourceConfig['resources']
): IntegrationResources['selectedResources'] => {
  return fp.reduce(
    (selectedResources, resource) => ({
      ...selectedResources,
      [resource.id]: {
        query: resource.query,
        fields: fp.reduce(
          (fields, { id }) => ({ ...fields, [id]: true }),
          {},
          resource.fields
        ),
      },
    }),
    {},
    sourceConfigResources
  );
};

const getDefaultResourcesErrorMessage = (integrationName: string) =>
  `An error occurred when fetching ${integrationName} tables. Please try again and contact support if the issue persists.`;

const getDefaultResourceErrorMessage = (resourceName: string) =>
  `An error occurred when fetching ${resourceName} resource. Please try again and contact support if the issue persists.`;

export const getRequestProblem = (
  resources: IntegrationResources,
  activeIntegration: ActiveIntegrationState,
  connectionsState: IntegrationConnectionsState
): { level: 'error'; message: string } | null => {
  const requests = resources.requests;

  if (requests.getResources.status === 'FAILURE') {
    return {
      level: 'error',
      message:
        requests.getResources.message ||
        getDefaultResourcesErrorMessage(activeIntegration.integrationName),
    };
  }

  if (connectionsState.statuses.list.status === 'FAILURE') {
    return {
      level: 'error',
      message:
        connectionsState.statuses.list.message ||
        getDefaultResourcesErrorMessage(activeIntegration.integrationName),
    };
  }

  const problematicResource = fp
    .toPairs(requests.getResource)
    .find(([_, { status }]) => status === 'FAILURE');

  if (problematicResource) {
    return {
      level: 'error',
      message:
        problematicResource[1].message ||
        getDefaultResourceErrorMessage(
          resources.resources[problematicResource[0]].displayText
        ),
    };
  }
  return null;
};
