import { derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { componentSearch$ } from 'viewpointBuilder/selectContextTab/componentSearch$';
import { ComponentSearchViewState } from 'viewpointBuilder/selectContextTab/types';
import { searchTraversalOperations } from 'traversals/selectTraversalForContextComponentsModal/searchTraversalOperations';
import { viewpointNavigatorCommands } from './viewpointNavigatorCommands';

const toContextSwitcherStream = ({
  isLoading,
  loadedComponents,
}: ComponentSearchViewState) => {
  return {
    isLoading,
    searchResults: loadedComponents.map(
      searchTraversalOperations.prepareComponentFromSearchData
    ),
    commands: viewpointNavigatorCommands,
  };
};

const contextSwitcher$ = derivedStream(
  'contextSwitcher$',
  componentSearch$
).pipe(
  map(([state]) => state),
  map(toContextSwitcherStream)
);

export default contextSwitcher$;
