import {
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import {
  clearAllFilters,
  resetAPIErrors,
  restoreChangeComponent,
  restoreChangeError,
  RestoreChangePayload,
  restoreChangeReference,
  restoreChangeSuccess,
  setAPIError,
  setAreAllRowsExpanded,
  setAuditLogData,
  setAuditLogFilter,
  setAuditLogPageSize,
  setChangeTypeFilterGroups,
  setChangeTypeFilterSpecifics,
  setIsRowExpanded,
  setLoadingState,
  toggleIsFilterSidebarOpen,
} from './actions';
import {
  hasTablePreviousPage,
  mapAPIResultDataToTableRowData,
  mapPropertiesOnChange,
} from './utils';
import { emptyFilterState, LoadingState } from './consts';
import { APIError, AuditLog$State, SetAuditLogFilterPayload } from './types';
import { AuditLogRowData } from '@ardoq/audit-log';
import { SelectOption } from '@ardoq/select';
import { AuditLogAPIResponse, AuditLogEntityType } from '@ardoq/api-types';
import { componentRestore$, referenceRestore$ } from './streams';
import { setStateProperty } from '@ardoq/common-helpers';

const defaultState: AuditLog$State = {
  data: [],
  isFilterSidebarOpen: false,
  filters: emptyFilterState,
  loadingState: LoadingState.LOADED,
  paginationState: {
    pageSize: 25,
    hasNextPage: true,
    hasPreviousPage: false,
    currentPage: 1,
  },
  showRefreshNotification: false,
  apiErrors: [],
  lastRestoreResult: '',
};

const handleToggleIsFilterSidebarOpen = (
  state: AuditLog$State,
  isOpen: boolean
) => ({
  ...state,
  isFilterSidebarOpen: isOpen,
});

const handleSetAuditLogFilter = (
  state: AuditLog$State,
  newFilter: SetAuditLogFilterPayload
) => ({
  ...state,
  filters: {
    ...state.filters,
    ...newFilter,
  },
});

const handleClearAllFilters = (state: AuditLog$State) => ({
  ...state,
  filters: emptyFilterState,
});

export const handleSetChangeTypeFilterGroups = (
  state: AuditLog$State,
  entityTypes: Array<AuditLogEntityType>
): AuditLog$State => ({
  ...state,
  filters: {
    ...state.filters,
    entityTypes: Object.fromEntries(
      Object.entries(state.filters.entityTypes).map(([entityType, value]) => [
        entityType,
        entityTypes.includes(entityType as AuditLogEntityType)
          ? value || []
          : null,
      ])
    ) as Record<AuditLogEntityType, Array<SelectOption<string>> | null>,
  },
});

export const handleSetChangeTypeFilterSpecifics = (
  state: AuditLog$State,
  newSpecifics: {
    entityType: AuditLogEntityType;
    specifics: Array<SelectOption<string> | string> | null;
  }
): AuditLog$State => ({
  ...state,
  filters: {
    ...state.filters,
    entityTypes: {
      ...state.filters.entityTypes,
      [newSpecifics.entityType]: newSpecifics.specifics,
    },
  },
});

const handleSetLoadingState = (
  state: AuditLog$State,
  loadingState: LoadingState
) => ({
  ...state,
  loadingState,
});

const handleSetAuditLogData = (
  state: AuditLog$State,
  { results, pageState, morePages }: AuditLogAPIResponse
): AuditLog$State => ({
  ...state,
  data: results.map(mapAPIResultDataToTableRowData),
  paginationState: {
    ...state.paginationState,
    pageState,
    hasNextPage: morePages,
    hasPreviousPage: hasTablePreviousPage(pageState),
  },
  showRefreshNotification: false,
});

const handleSetAuditLogPageSize = (
  state: AuditLog$State,
  pageSize: number
): AuditLog$State => ({
  ...state,
  paginationState: {
    ...state.paginationState,
    pageSize,
  },
});

const expandRow = (row: AuditLogRowData, isSelected: boolean) => ({
  ...row,
  meta: { isSelected: row.areEmptyChanges ? false : isSelected }, // this to get the right css state for the row
});
const handleSetIsRowExpanded = (
  { data, ...state }: AuditLog$State,
  rowId: string
) => ({
  ...state,
  data: data.map(row =>
    row.rowId === rowId ? expandRow(row, !row.meta.isSelected) : row
  ),
});

const handleSetAreAllRowsExpanded = (
  { data, ...state }: AuditLog$State,
  isExpanded: boolean
) => ({
  ...state,
  data: data.map(row => expandRow(row, isExpanded)),
});

const handleRestoreChange = (
  { data, ...state }: AuditLog$State,
  restoreChangePayload: RestoreChangePayload
): AuditLog$State => ({
  ...state,
  data: mapPropertiesOnChange(data, restoreChangePayload, {
    isRestoring: true,
  }),
});

const handleRestoreChangeSuccess = (
  { data, ...state }: AuditLog$State,
  restoreChangePayload: RestoreChangePayload
): AuditLog$State => ({
  ...state,
  data: mapPropertiesOnChange(data, restoreChangePayload, {
    isRestoring: false,
    hasBeenRestored: true,
    hasError: false,
  }),
  showRefreshNotification: true,
});

const handleRestoreChangeError = (
  { data, ...state }: AuditLog$State,
  { errorTraceId, ...restoreChangePayload }: RestoreChangePayload
): AuditLog$State => ({
  ...state,
  data: mapPropertiesOnChange(data, restoreChangePayload, {
    isRestoring: false,
    hasError: true,
    errorTraceId,
  }),
});

const handleSetAPIError = (
  state: AuditLog$State,
  newError: APIError
): AuditLog$State => ({
  ...state,
  apiErrors: [...state.apiErrors, newError],
});

const handleResetAPIErrors = (state: AuditLog$State): AuditLog$State => ({
  ...state,
  apiErrors: [],
});

const auditLog$ = persistentReducedStream<AuditLog$State>(
  'auditLog$',
  defaultState,
  [
    reducer(toggleIsFilterSidebarOpen, handleToggleIsFilterSidebarOpen),
    reducer(setAuditLogFilter, handleSetAuditLogFilter),
    reducer(setChangeTypeFilterGroups, handleSetChangeTypeFilterGroups),
    reducer(setChangeTypeFilterSpecifics, handleSetChangeTypeFilterSpecifics),
    reducer(clearAllFilters, handleClearAllFilters),
    reducer(setLoadingState, handleSetLoadingState),
    reducer(setAuditLogData, handleSetAuditLogData),
    reducer(setAuditLogPageSize, handleSetAuditLogPageSize),
    reducer(setIsRowExpanded, handleSetIsRowExpanded),
    reducer(setAreAllRowsExpanded, handleSetAreAllRowsExpanded),
    reducer(restoreChangeComponent, handleRestoreChange),
    reducer(restoreChangeReference, handleRestoreChange),
    reducer(restoreChangeSuccess, handleRestoreChangeSuccess),
    reducer(restoreChangeError, handleRestoreChangeError),
    reducer(setAPIError, handleSetAPIError),
    reducer(resetAPIErrors, handleResetAPIErrors),
    streamReducer(componentRestore$, setStateProperty('lastRestoreResult')),
    streamReducer(referenceRestore$, setStateProperty('lastRestoreResult')),
  ]
);

export default auditLog$;
