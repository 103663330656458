import styled from 'styled-components';
import { text2 } from '@ardoq/typography';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { PropsWithChildren } from 'react';

export const Subtitle = styled.div`
  ${text2};
  color: ${colors.grey35};
  width: 600px;
`;

const DoqyWithMessageContainer = styled.div`
  ${text2};
  display: flex;
  flex-direction: column;
  gap: ${s8};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: ${s16};
`;

export const DoqyWithMessage = ({ children }: PropsWithChildren) => (
  <DoqyWithMessageContainer>{children}</DoqyWithMessageContainer>
);
