import { combineLatest, map } from 'rxjs';
import { configurationState$ } from '../streams/configurationState/configurationState$';
import integrationViewState$ from 'integrations/integrationViewState$';
import { getRouteFromString } from 'integrations/ITPedia/utils';
import { StepState } from '@ardoq/steppers';
import { isDataSelectionValid } from 'integrations/ITPedia/NewSync/ComponentSelection/utils';
import { isFieldMappingValid } from 'integrations/ITPedia/NewSync/FieldMapping/utils';
import { ITPediaImportRoutes } from 'integrations/ITPedia/types';
import { StepStatuses } from './types';
import { ConfigurationState } from '../streams/types';

export function buildModel(
  config: ConfigurationState,
  integrationPath: string | null
) {
  const route = getRouteFromString(integrationPath);
  const dataSelectionValid = isDataSelectionValid(config);
  const fieldMappingValid =
    dataSelectionValid &&
    isFieldMappingValid(
      config.componentTypes,
      config.workspaces.vulnerabilities.name
    );
  const summarySyncValid = fieldMappingValid && !!config.name;

  let fieldMappingStatus = StepState.ACTIVE;
  if (dataSelectionValid && fieldMappingValid) {
    fieldMappingStatus = StepState.DONE;
  }

  let summarySyncStatus = StepState.ACTIVE;
  if (fieldMappingValid && summarySyncValid) {
    summarySyncStatus = StepState.DONE;
  }

  const stepStatuses: StepStatuses = {
    dataSelection: {
      selected: route === ITPediaImportRoutes.DATA_SELECTION,
      enabled: true,
      state: dataSelectionValid ? StepState.DONE : StepState.ACTIVE,
    },
    fieldMapping: {
      selected: route === ITPediaImportRoutes.FIELD_MAPPING,
      enabled: dataSelectionValid,
      state: fieldMappingStatus,
    },
    summarySync: {
      selected: route === ITPediaImportRoutes.SUMMARY_SYNC,
      enabled: fieldMappingValid,
      state: summarySyncStatus,
    },
  };

  return { stepStatuses };
}

export const viewModel$ = combineLatest([
  configurationState$,
  integrationViewState$,
]).pipe(
  map(([config, { integrationPath }]) => buildModel(config, integrationPath))
);
