import { reducedStream, reducer } from '@ardoq/rxbeach';
import { createPermissionGroupOperations } from './createPermissionGroupOperations';
import type { CreatePermissionGroup$State } from './types';
import {
  createPermissionGroup,
  createPermissionGroupSuccess,
  createPermissionGroupFailed,
  setFormFieldValue,
  formValidationFailed,
} from './actions';

const defaultState: CreatePermissionGroup$State = {
  isSubmitting: false,
  apiErrorMessage: null,
  formValues: {
    name: '',
    users: [],
  },
  formErrors: {
    name: [],
    users: [],
  },
  displayFieldErrors: false,
};

const createPermissionGroupViewModel$ =
  reducedStream<CreatePermissionGroup$State>(
    'createPermissionGroup',
    defaultState,
    [
      reducer(
        createPermissionGroup,
        createPermissionGroupOperations.handleCreate
      ),
      reducer(
        createPermissionGroupFailed,
        createPermissionGroupOperations.handleApiError
      ),
      reducer(
        createPermissionGroupSuccess,
        createPermissionGroupOperations.handleCreateSuccess
      ),
      reducer(
        setFormFieldValue,
        createPermissionGroupOperations.handleSetFormValue
      ),
      reducer(
        formValidationFailed,
        createPermissionGroupOperations.handleValidationError
      ),
    ]
  );

export default createPermissionGroupViewModel$;
