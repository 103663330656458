import {
  APIViewpointAttributes,
  DiffMode,
  ExternalDocument,
  ExternalDocumentProvider,
  JSONEdge,
  JSONEdgeLegend,
  JSONGraph,
  JSONLegend,
  JSONNode,
  JSONNodeLegend,
} from '@ardoq/api-types';
import { Action, dispatchAction } from '@ardoq/rxbeach';
import { getComponentTypesForLegend } from '@ardoq/view-legend';

import {
  LucidChartDocumentPickerStatus,
  LucidDocumentSelectPayload,
  RequiredAuth,
} from './types';
import {
  LUCID_CHART_URL,
  DOCUMENT_PICKER_URL,
  LUCIDCHART_INVALID_SESSION,
  MAX_AUTH_ATTEMPTS,
  EMBED_VIEWER_URL,
} from './const';
import { exportToLucidchart as exportToLucidchartAction } from './actions';
import { referenceInterface } from '@ardoq/reference-interface';
import { componentInterface } from '@ardoq/component-interface';
import { Color, GraphComponent } from '@ardoq/yfiles';
import { toJSON } from '@ardoq/export';
import { format } from '@ardoq/date-time';
import { from, tap } from 'rxjs';
import { api, ApiResponse } from '@ardoq/api';
import { ArdoqError, ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { startOAuthFlow } from 'oauth/actions';
import { showToast, ToastType } from '@ardoq/status-ui';
import { ParentChildGraphEdge } from '@ardoq/graph';
import { uniqBy } from 'lodash';
import { getActiveDiffMode } from '../../scope/activeDiffMode$';
import { isInScopeDiffMode } from '../../scope/scopeDiff';
import { DiffType } from '@ardoq/data-model';
import { colors } from '@ardoq/design-tokens';

export const isSuccessDocumentSelection = (
  event: any
): event is LucidDocumentSelectPayload =>
  event?.status === LucidChartDocumentPickerStatus.SUCCESS;

export const createExternalDocument = ({
  componentId,
  document,
}: {
  componentId: string;
  document: LucidDocumentSelectPayload;
}): ExternalDocument => {
  return {
    componentId,
    url: `${LUCID_CHART_URL}/${document.documentId}/edit`,
    displayText: document.title,
    provider: ExternalDocumentProvider.LUCIDCHART,
    externalId: document.documentId,
  };
};

export const createDocumentPickerUrl = ({
  token,
  titleSuggestion,
}: {
  token: string;
  titleSuggestion: string;
}) => {
  const params = new URLSearchParams({
    token,
    allowDocumentCreation: 'true',
    autoSelectCreatedDocument: 'true',
    newDocumentTitleSuggestion: titleSuggestion,
  });

  return `${DOCUMENT_PICKER_URL}?${params.toString()}`;
};

export const exportToLucidchart = (isLegendActive: boolean) => {
  dispatchAction(exportToLucidchartAction({ attemptCount: 0, isLegendActive }));
};

export const createEmbedViewerUrl = ({
  documentId,
  clientId,
}: {
  documentId: string;
  clientId: string;
}) => {
  const params = new URLSearchParams({
    clientId,
    document: documentId,
    mode: 'viewer',
  });
  return `${EMBED_VIEWER_URL}?${params.toString()}`;
};

const isHexColor = (color: string): boolean => {
  return /^#[0-9A-F]{6}$/i.test(color);
};

const toHex = (num: number) => num.toString(16).padStart(2, '0');

const rgbaToHex = (colorString: string): string => {
  const color = Color.from(colorString);

  const hex = `#${toHex(color.r)}${toHex(color.g)}${toHex(color.b)}`;

  if (color.a < 1) {
    const alphaHex = Math.round(color.a * 255)
      .toString(16)
      .padStart(2, '0');
    return `${hex}${alphaHex}`;
  }
  return hex;
};

const getDiffTypeColor = (diffType: DiffType): string => {
  switch (diffType) {
    case DiffType.ADDED:
      return colors.green60;
    case DiffType.CHANGED:
      return colors.blue60;
    case DiffType.REMOVED:
      return colors.red60;
    default:
      return colors.grey80;
  }
};

const getStrokeWidth = (diffType: DiffType): number => {
  switch (diffType) {
    case DiffType.ADDED:
    case DiffType.REMOVED:
    case DiffType.CHANGED:
      return 6;
    default:
      return 3;
  }
};

export const addReferenceColors = (
  edgeList: Array<JSONEdge>
): Array<JSONEdge> => {
  return edgeList.map(edge => {
    const attributes = referenceInterface.getCssColors(edge.referenceId, {
      useAsBackgroundStyle: false,
    });
    const visualDiffType = referenceInterface.getVisualDiffType(
      edge.referenceId
    );
    if (visualDiffType !== DiffType.NONE) {
      return {
        ...edge,
        stroke: getDiffTypeColor(visualDiffType),
        strokeWidth: getStrokeWidth(visualDiffType),
      };
    }
    if (!attributes || !attributes.stroke || attributes.stroke === 'black') {
      return edge;
    }
    if (isHexColor(attributes.stroke)) {
      return {
        ...edge,
        stroke: attributes.stroke,
      };
    }

    const hexColor = rgbaToHex(attributes.stroke ?? edge.stroke);
    return attributes
      ? {
          ...edge,
          stroke: hexColor,
        }
      : edge;
  });
};

const getComponentId = (node: JSONNode): string | undefined => {
  // Examples
  // Component ID: 31d43c7eb4b60fbc97bbc846
  // Grouped Component ID: p1617318794319_31d43c7eb4b60fbc97bbc846
  return node.componentId?.split('_').pop();
};

export const addComponentColors = (
  nodeList: Array<JSONNode>
): Array<JSONNode> => {
  return nodeList.map(node => {
    const componentId = getComponentId(node);
    if (!componentId || node.isGroup) {
      return node;
    }
    const attributes = componentInterface.getAttributes(componentId, [
      'shape',
      'type',
      'color',
    ]);
    const visualDiffType = componentInterface.getVisualDiffType(componentId);
    if (visualDiffType !== DiffType.NONE) {
      return {
        ...node,
        fill: getDiffTypeColor(visualDiffType),
      };
    }
    // Fetch the display color as a fallback for when conditional formatting is applied
    const displayColor =
      componentInterface.getComponentDisplayColorAsSVGAttributes(componentId, {
        useAsBackgroundStyle: false,
      });

    if (!attributes) {
      return node;
    }

    const fillColor = displayColor?.fill || attributes.color;
    if (!fillColor) {
      return node;
    }

    if (isHexColor(fillColor)) {
      return {
        ...node,
        fill: fillColor,
        shape: attributes.shape ?? node.shape,
        type: attributes.type ?? node.type,
      };
    }

    const hexColor = rgbaToHex(fillColor);

    return {
      ...node,
      fill: hexColor,
      shape: attributes.shape ?? node.shape,
      type: attributes.type ?? node.type,
    };
  });
};

const measureTextWidth = (text: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return 0;
  }
  context.font = '12px Arial';
  return context.measureText(text).width;
};

const getCompLegendTypes = (nodeList: JSONNode[]): JSONNodeLegend[] => {
  return getComponentTypesForLegend(
    nodeList.map(getComponentId).filter(ExcludeFalsy)
  ).map(
    (legendType): JSONNodeLegend => ({
      name: legendType.label,
      nameWidth: measureTextWidth(legendType.label),
      color: rgbaToHex(legendType.color),
      representationData: legendType.representationData.isImage
        ? {
            isImage: true,
            value: legendType.representationData.value ?? undefined,
          }
        : {
            isImage: false,
            shapeName: legendType.representationData.shapeName ?? undefined,
            icon: legendType.representationData.icon?.id ?? undefined,
          },
    })
  );
};

const getRefLegendTypes = (edgeList: JSONEdge[]): JSONEdgeLegend[] => {
  const normalRefTypes = uniqBy(
    edgeList
      .map(edge => edge.referenceId)
      .filter(ExcludeFalsy)
      .map(referenceInterface.getModelType)
      .filter(ExcludeFalsy),
    'name'
  );

  const hasParentChildEdges = edgeList.some(
    edge => edge.referenceId.charAt(24) === '-'
  );

  return normalRefTypes
    .concat(hasParentChildEdges ? [ParentChildGraphEdge.getType()] : [])
    .map(
      (referenceType): JSONEdgeLegend => ({
        color: rgbaToHex(referenceType.color),
        line: referenceType.line,
        lineBeginning: referenceType.lineBeginning,
        lineEnding: referenceType.lineEnding,
        name: referenceType.name,
        nameWidth: measureTextWidth(referenceType.name),
      })
    );
};

const getLegend = (nodeList: JSONNode[], edgeList: JSONEdge[]): JSONLegend => {
  const activeDiffMode = isInScopeDiffMode() ? getActiveDiffMode() : null;

  if (activeDiffMode === DiffMode.DIFF) {
    return {
      diffMode: activeDiffMode,
      nodeTypes: [],
      edgeTypes: [],
    };
  }

  return {
    diffMode: activeDiffMode,
    nodeTypes: getCompLegendTypes(nodeList),
    edgeTypes: getRefLegendTypes(edgeList),
  };
};

export const getJSONGraph = (
  graph: GraphComponent,
  isLegendActive: boolean
): JSONGraph => {
  const jsonData = toJSON(graph.graph);
  const updatedNodeList = addComponentColors(jsonData.nodeList);
  const updatedEdgeList = addReferenceColors(jsonData.edgeList);
  const legend = isLegendActive
    ? getLegend(jsonData.nodeList, jsonData.edgeList)
    : undefined;

  const jsonGraph: JSONGraph = {
    ...jsonData,
    nodeList: updatedNodeList,
    edgeList: updatedEdgeList,
    legend,
  };

  return jsonGraph;
};

export const getExportLucidDocumentTitle = (
  traversals: APIViewpointAttributes[]
): string => {
  const currentDateTime = new Date();
  const timestamp = format(currentDateTime, 'yyyy-MM-dd HH:mm');

  const title = traversals.length > 0 ? traversals[0].name : 'Ardoq Export';

  return `${title} - ${timestamp}`;
};

type HandleAuthErrorArgs = {
  operation: string;
  attemptCount: number;
  error: ArdoqError;
  onCompleted: VoidFunction;
};

const handleLucidchartAuthError = ({
  operation,
  attemptCount,
  error,
  onCompleted,
}: HandleAuthErrorArgs) => {
  if (
    api.isForbidden(error) &&
    error.userMessage === LUCIDCHART_INVALID_SESSION &&
    attemptCount < MAX_AUTH_ATTEMPTS
  ) {
    return dispatchAction(
      startOAuthFlow({
        provider: 'lucidchart',
        onCompleted,
        operation,
      })
    );
  }

  showToast(`Failed to ${operation}`, ToastType.INFO);
};

export const authRequiredLucidchartAction = <
  Response,
  LucidActionResult extends ApiResponse<Response>,
  Payload extends RequiredAuth,
>({
  lucidchartAction,
  onCompleteAction,
  payload,
  operation,
}: {
  lucidchartAction: () => LucidActionResult;
  onCompleteAction: (payload: Payload) => Action<unknown>;
  payload: Payload;
  operation: string;
}) => {
  return from(lucidchartAction()).pipe(
    tap(response => {
      if (isArdoqError(response)) {
        const attemptCount = payload.attemptCount ?? 0;
        handleLucidchartAuthError({
          operation,
          attemptCount,
          error: response,
          onCompleted: () =>
            dispatchAction(
              onCompleteAction({
                ...payload,
                attemptCount: attemptCount + 1,
              })
            ),
        });
      }
    })
  );
};

export const getLucidEditUrl = (documentId: string) =>
  `${LUCID_CHART_URL}/${documentId}/edit`;
