import { Features, hasFeature } from '@ardoq/features';
import { navigateToBroadcastOverview } from 'router/navigationActions';
import { dispatchAction } from '@ardoq/rxbeach';
import { BroadcastIcon, IconSize } from '@ardoq/icons';
import { initiateCreatingNewBroadcastFromSurvey } from 'broadcasts/actions';
import {
  trackCreateBroadcastClicked,
  trackNavigateToBroadcastOverview,
} from 'broadcasts/tracking';
import { ArdoqId } from '@ardoq/api-types';
import { ButtonType } from '@ardoq/button';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';

type BroadcastContextMenuProps = {
  readonly surveyId?: ArdoqId;
  readonly isDisabled: boolean;
};

const getBroadcastOptions = (surveyId: ArdoqId) => [
  {
    label: 'Create broadcast',
    onClick: () => {
      dispatchAction(initiateCreatingNewBroadcastFromSurvey(surveyId));
      trackCreateBroadcastClicked('SurveyEditor');
    },
  },
  {
    label: 'Broadcast overview',
    onClick: () => {
      trackNavigateToBroadcastOverview('SurveyEditor');
      dispatchAction(navigateToBroadcastOverview());
    },
  },
];

const BroadcastContextMenu = ({
  surveyId,
  isDisabled,
}: BroadcastContextMenuProps) => {
  const hasBroadcasts = hasFeature(Features.BROADCASTS);
  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);
  if (!hasBroadcasts || !surveyId) return null;
  return (
    <ButtonWithDropdown
      buttonType={
        hasNewJourneyFeature ? ButtonType.GHOST : ButtonType.SECONDARY
      }
      alignMenuTo="right"
      options={getBroadcastOptions(surveyId)}
      dropdownSize={DropdownSize.S}
      isDisabled={isDisabled}
      dataTestId="broadcast-context-menu"
      hideChevron={hasNewJourneyFeature}
      data-tooltip-text="Broadcast"
    >
      <BroadcastIcon
        iconSize={IconSize.SMALL}
        style={{ alignSelf: 'center' }}
      />
      {hasNewJourneyFeature ? '' : 'Broadcast'}
    </ButtonWithDropdown>
  );
};

export default BroadcastContextMenu;
