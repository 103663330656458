import { isEqual, pick } from 'lodash';
import {
  PayloadStoredQuery,
  createSearchQuerySuccess,
  deleteQuerySuccess,
  editSearchQuery,
  loadStoredQuery,
  resetAllSearchState,
  saveSearchQuery,
  saveSearchQueryError,
  triggerSearchLoading,
  triggerSearchLoadingDone,
  updateCalculatedFieldValue,
  updateQueryName,
  updateQueryParams,
  updateSearchQuery,
} from 'search/actions';
import { QueryModel, SearchBackend, SearchType } from '@ardoq/api-types';
import {
  ExtractPayload,
  ObservableState,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';

export interface QueryEditorStateShape {
  selectedQueryId: string | null;
  isEditing: boolean;
  isSearching: boolean;
  hasChanges: boolean;
  hasSaveError: boolean;
  initialModel: QueryModel | null;
  model: QueryModel;
  queryParams: Record<string, string> | Record<string, string[]>;
  calculatedFieldSelectedOption: string | null;
}

const defaultState = (
  searchBackend: SearchBackend,
  searchType: SearchType
): QueryEditorStateShape => ({
  selectedQueryId: null,
  isEditing: true,
  isSearching: false,
  hasChanges: false,
  hasSaveError: false,
  initialModel: null,
  model: {
    type: searchType,
    backend: searchBackend,
    name: '',
    query: '',
    supportedParams: [],
    parameterQueryId: null,
  },
  queryParams: {},
  calculatedFieldSelectedOption: null,
});

/**
 * Shallow copy a stored query without including _version.
 *
 * We cannot ...storedQuery because that would unpack all fields from
 * StoredQueryModel, including _version, which means it would be impossible
 * to save twice in a row
 *
 * @param storedQuery Stored query to unpack / copy
 */
export const unpackStoredQuery = (storedQuery: QueryModel) =>
  pick(storedQuery, [
    'type',
    'backend',
    'name',
    'query',
    'supportedParams',
    'parameterQueryId',
  ]);

const handleLoadStoredQuery = (
  _: QueryEditorStateShape,
  { storedQuery }: PayloadStoredQuery
): QueryEditorStateShape => ({
  ...defaultState(storedQuery.backend, storedQuery.type),
  selectedQueryId: storedQuery._id,
  isEditing: false,
  initialModel: unpackStoredQuery(storedQuery),
  model: unpackStoredQuery(storedQuery),
});

const handleCreateSearchQuerySuccess = (
  state: QueryEditorStateShape,
  { storedQuery }: PayloadStoredQuery
): QueryEditorStateShape => ({
  ...state,
  selectedQueryId: storedQuery._id,
  isEditing: true,
  hasChanges: false,
  hasSaveError: false,
  model: unpackStoredQuery(storedQuery),
});

const handleEditSearchQuery = (state: QueryEditorStateShape) => ({
  ...state,
  isEditing: true,
});

const handleUpdateQueryName = (
  state: QueryEditorStateShape,
  { name }: ExtractPayload<typeof updateQueryName>
) => {
  const newModel = {
    ...state.model,
    name,
  };
  return {
    ...state,
    hasChanges: !isEqual(state.initialModel, newModel),
    hasSaveError: false,
    model: newModel,
  };
};

const handleSaveSearchQuery = (state: QueryEditorStateShape) => ({
  ...state,
  hasChanges: false,
  hasSaveError: false,
});

const handleUpdateQuery = (
  state: QueryEditorStateShape,
  { query }: ExtractPayload<typeof updateSearchQuery>
) => {
  const newModel = {
    ...state.model,
    query,
  };
  return {
    ...state,
    hasChanges: !isEqual(state.initialModel, newModel),
    hasSaveError: false,
    model: newModel,
  };
};

const handleSearchLoading = (state: QueryEditorStateShape) => ({
  ...state,
  isSearching: true,
});

const handleSearchLoadingDone = (state: QueryEditorStateShape) => ({
  ...state,
  isSearching: false,
});

const handleUpdateQueryParams = (
  state: QueryEditorStateShape,
  { queryParams }: ExtractPayload<typeof updateQueryParams>
) => ({
  ...state,
  queryParams,
});

const handleUpdateCalculatedFieldValue = (
  state: QueryEditorStateShape,
  {
    ids,
    calculatedFieldSelectedOption,
  }: ExtractPayload<typeof updateCalculatedFieldValue>
): QueryEditorStateShape => ({
  ...state,
  calculatedFieldSelectedOption,
  queryParams: { ids },
  hasSaveError: false,
});

const handleSaveError = (state: QueryEditorStateShape) => ({
  ...state,
  hasSaveError: true,
  hasChanges: true,
});

const handleDeletedQuery = (
  searchBackend: SearchBackend,
  searchType: SearchType
) => {
  const handler = (
    state: QueryEditorStateShape,
    { queryId }: ExtractPayload<typeof deleteQuerySuccess>
  ) =>
    state.selectedQueryId === queryId
      ? defaultState(searchBackend, searchType)
      : state;

  return reducer(deleteQuerySuccess, handler);
};

const handleResetState = (
  searchBackend: SearchBackend,
  searchType: SearchType
) => {
  const handler = () => defaultState(searchBackend, searchType);
  return reducer(resetAllSearchState, handler);
};

const reducers = [
  reducer(createSearchQuerySuccess, handleCreateSearchQuerySuccess),
  reducer(loadStoredQuery, handleLoadStoredQuery),
  reducer(editSearchQuery, handleEditSearchQuery),
  reducer(saveSearchQuery, handleSaveSearchQuery),
  reducer(updateQueryName, handleUpdateQueryName),
  reducer(saveSearchQueryError, handleSaveError),
  reducer(updateSearchQuery, handleUpdateQuery),
  reducer(triggerSearchLoading, handleSearchLoading),
  reducer(triggerSearchLoadingDone, handleSearchLoadingDone),
  reducer(updateCalculatedFieldValue, handleUpdateCalculatedFieldValue),
  reducer(updateQueryParams, handleUpdateQueryParams),
];

export enum QueryEditorNamespace {
  ADVANCED_SEARCH = 'advanced-search',
  GREMLIN_SEARCH = 'gremlin-search',
  CALCULATED_FIELD_QUERY = 'calculated-field',
  DYANMIC_FILTER_QUERY = 'dynamic-filter',
  PARAMETER_QUERY = 'parameter-query',
  BROADCAST_AUDIENCE_QUERY = 'broadcast-audience-query',
  SEARCH_AND_ASSIGN_TO_ZONE = 'search-and-assign-to-zone',
}

const backendFromEditorType = {
  [QueryEditorNamespace.ADVANCED_SEARCH]: SearchBackend.ADVANCED_SEARCH,
  [QueryEditorNamespace.GREMLIN_SEARCH]: SearchBackend.GREMLIN,
  [QueryEditorNamespace.CALCULATED_FIELD_QUERY]: SearchBackend.GREMLIN,
  [QueryEditorNamespace.DYANMIC_FILTER_QUERY]: SearchBackend.GREMLIN,
  [QueryEditorNamespace.PARAMETER_QUERY]: SearchBackend.GREMLIN,
  [QueryEditorNamespace.BROADCAST_AUDIENCE_QUERY]: SearchBackend.GREMLIN,
  [QueryEditorNamespace.SEARCH_AND_ASSIGN_TO_ZONE]:
    SearchBackend.ADVANCED_SEARCH,
};

const searchTypeFromEditorType = {
  [QueryEditorNamespace.ADVANCED_SEARCH]: SearchType.QUERY,
  [QueryEditorNamespace.GREMLIN_SEARCH]: SearchType.QUERY,
  [QueryEditorNamespace.CALCULATED_FIELD_QUERY]:
    SearchType.CALCULATED_FIELD_QUERY,
  [QueryEditorNamespace.DYANMIC_FILTER_QUERY]: SearchType.DYNAMIC_FILTER_QUERY,
  [QueryEditorNamespace.PARAMETER_QUERY]: SearchType.PARAMETER_QUERY,
  [QueryEditorNamespace.BROADCAST_AUDIENCE_QUERY]:
    SearchType.BROADCAST_AUDIENCE_QUERY,
  [QueryEditorNamespace.SEARCH_AND_ASSIGN_TO_ZONE]: SearchType.QUERY,
};

export const getQueryEditorNamespace = (
  backend: SearchBackend,
  type: SearchType
) => {
  if (backend === SearchBackend.ADVANCED_SEARCH) {
    return QueryEditorNamespace.ADVANCED_SEARCH;
  }
  if (type === SearchType.QUERY) {
    return QueryEditorNamespace.GREMLIN_SEARCH;
  }
  if (type === SearchType.CALCULATED_FIELD_QUERY) {
    return QueryEditorNamespace.CALCULATED_FIELD_QUERY;
  }
  if (type === SearchType.DYNAMIC_FILTER_QUERY) {
    return QueryEditorNamespace.DYANMIC_FILTER_QUERY;
  }
  if (type === SearchType.PARAMETER_QUERY) {
    return QueryEditorNamespace.PARAMETER_QUERY;
  }
  if (type === SearchType.BROADCAST_AUDIENCE_QUERY) {
    return QueryEditorNamespace.BROADCAST_AUDIENCE_QUERY;
  }
};

const createQueryEditor$ = (namespace: QueryEditorNamespace) => {
  const searchBackend = backendFromEditorType[namespace];
  const searchType = searchTypeFromEditorType[namespace];
  const applicableReducers = [
    ...reducers,
    // FIXME - it would be nice if these reducers were "static" as well
    handleResetState(searchBackend, searchType),
    handleDeletedQuery(searchBackend, searchType),
  ];

  const queryEditor$ = persistentReducedStream(
    `queryEditor$-${namespace}`,
    defaultState(searchBackend, searchType),
    applicableReducers,
    { namespace }
  );

  return queryEditor$;
};

export type QueryEditor$ = ObservableState<QueryEditorStateShape>;

export const advancedSearchEditor$ = createQueryEditor$(
  QueryEditorNamespace.ADVANCED_SEARCH
);

export const gremlinSearchEditor$ = createQueryEditor$(
  QueryEditorNamespace.GREMLIN_SEARCH
);

export const calculatedFieldQueryEditor$ = createQueryEditor$(
  QueryEditorNamespace.CALCULATED_FIELD_QUERY
);

export const dynamicFilterQueryEditor$ = createQueryEditor$(
  QueryEditorNamespace.DYANMIC_FILTER_QUERY
);

export const parameterQueryEditor$ = createQueryEditor$(
  QueryEditorNamespace.PARAMETER_QUERY
);

export const searchAndAssignToZoneQueryEditor$ = createQueryEditor$(
  QueryEditorNamespace.SEARCH_AND_ASSIGN_TO_ZONE
);
