import React from 'react';
import { LabeledDetail } from '../../components/LabeledDetail';
import { IconDecorator } from '@ardoq/decorators';
import { IconName } from '@ardoq/icons';
import { TextOverflow } from '@ardoq/popovers';
import { APIOrganizationUser } from '@ardoq/api-types';

type EmailSectionProps = {
  user: APIOrganizationUser;
};
export const EmailSection = ({ user }: EmailSectionProps) => {
  return (
    <LabeledDetail label="Email" maxWidth="50%">
      <IconDecorator iconName={IconName.MAIL}>
        <TextOverflow>{user.email}</TextOverflow>
      </IconDecorator>
    </LabeledDetail>
  );
};
