import styled from 'styled-components';
import { AqHeader, Stack } from '@ardoq/layout';
import { header1, header4, text2 } from '@ardoq/typography';
import { ButtonGroup, PrimaryButton, SecondaryButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { colors, s64 } from '@ardoq/design-tokens';
import { dispatchAction } from '@ardoq/rxbeach';
import { openNewTab } from 'integrations/actions';
import Navbar from 'views/navbar/Navbar';
import MainToolbar from 'menus/topbar/MainToolbar';

const Page = styled.div`
  background: ${colors.white};
  height: 100%;
`;

const InfoLayout = styled(Stack).attrs({
  gap: 'xlarge',
})`
  margin-top: ${s64};
`;

const BodyContent = styled(Stack).attrs({
  gap: 'medium',
})`
  width: 540px;
  padding: 32px;
`;

const Title = styled.div`
  ${header1};
`;

const Subtitle = styled.div`
  ${header4}
`;

const Text = styled.p`
  ${text2}
`;

const handleDeveloperPortalClick = () =>
  dispatchAction(openNewTab({ url: 'http://developer.ardoq.com/' }));

const handleApiV1Click = () =>
  dispatchAction(openNewTab({ url: '/api-docs/index.html' }));

const Content = () => {
  return (
    <BodyContent>
      <Title>Get started with the Ardoq REST API</Title>
      <InfoLayout>
        <div>
          <Subtitle>API Documentation</Subtitle>
          <Text>
            Our API documentation is a comprehensive guide to help you use the
            Ardoq REST API.
          </Text>
        </div>
        <div>
          <Subtitle>Different versions of Ardoq API documentation</Subtitle>
          <Text>
            We currently have two versions of our REST API available. Head over
            to the Ardoq Developer Portal for the latest version.
          </Text>
        </div>
        <div>
          <Text>
            Version 1 of the API can still be used to manage existing
            integrations, but it will no longer be updated. We advise using the
            Developer Portal when setting up any new integrations to ensure
            continuous support.
          </Text>
        </div>
        <ButtonGroup>
          <PrimaryButton onClick={handleDeveloperPortalClick}>
            Ardoq Developer Portal
            <Icon iconName={IconName.OPEN_IN_NEW} />
          </PrimaryButton>
          <SecondaryButton onClick={handleApiV1Click}>
            Ardoq API v1
            <Icon iconName={IconName.OPEN_IN_NEW} />
          </SecondaryButton>
        </ButtonGroup>
      </InfoLayout>
    </BodyContent>
  );
};

const PublicApi = () => {
  return (
    <Page>
      <Navbar
        primaryContent="Import and integrations"
        secondaryContent="Overview"
        toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
      />
      <AqHeader title="Ardoq Api" titleStyle={{ padding: '16px 0' }} />
      <Content />
    </Page>
  );
};

export default PublicApi;
