import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { dispatchAction } from '@ardoq/rxbeach';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { ensureCloudProvider } from 'integrations/cloudProviders/utils';
import { vpcs$ } from 'integrations/aws/streams/vpcs/vpcs$';
import { changeVpcsSelection } from 'integrations/aws/streams/vpcs/actions';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { getVpcsOptions } from './utils';

export const viewModel$ = integrationId$.pipe(
  ensureCloudProvider(),
  switchMap(integrationId => {
    return combineLatest({
      connectionsState: getConnectionsStream(integrationId),
      vpcState: vpcs$,
    });
  }),
  map(
    ({
      connectionsState: { selectedConnectionIds, connections },
      vpcState: { connectionsVpcs, selectedVpcIds },
    }) => {
      return {
        vpcsOptions: getVpcsOptions(connectionsVpcs, connections),
        onSelect: (vpcIds: string[]) => {
          dispatchAction(changeVpcsSelection({ selectedVpcIds: vpcIds }));
        },
        selectedVpcIds: selectedVpcIds,
        isDisabled: selectedConnectionIds.length === 0,
      };
    }
  )
);
