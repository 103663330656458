import { horizontalSpacing } from 'components/DiffMergeTable/styleVariables';
import { APIEntityType, APIWorkspaceAttributes } from '@ardoq/api-types';
import {
  ItemWrapper,
  MediumSizeWorkspaceLabel,
} from 'components/DiffMergeTablePopover/atoms';
import { AncestorItem, AncestorItemType } from './types';
import styled, { css } from 'styled-components';
import { visualDiffBackgroundCircle } from 'scope/BackgroundImageResources';
import { colors, s8 } from '@ardoq/design-tokens';
import * as fonts from 'app/designTokens/fonts';
import {
  ComponentIconRepresentation,
  EnhancedScopeDataWithBranchName,
  FlexWrapper,
  Graphics,
  readRawValue,
} from '@ardoq/renderers';

const INDENTATION_STEP = parseInt(horizontalSpacing.MEDIUM, 10);

const FilterIconWrapper = styled(FlexWrapper)`
  margin-right: ${s8};
  justify-content: center;
  width: 18px;
  height: 18px;
`;

const ItemWrapperWithFilter = styled(ItemWrapper)<{
  $indentationLevel?: number;
  $ancestorItemType: AncestorItemType;
}>`
  ${fonts.pageViewMediumTemp};
  margin-left: ${({ $indentationLevel = 0 }) =>
    INDENTATION_STEP + INDENTATION_STEP * $indentationLevel}px;

  ${FilterIconWrapper} {
    ${({ $ancestorItemType }) =>
      $ancestorItemType === AncestorItemType.DELETED_COMPONENT &&
      css`
        filter: url(#visual-diff-filter-removed);
      `}

    ${({ $ancestorItemType }) =>
      $ancestorItemType === AncestorItemType.CREATED_COMPONENT &&
      css`
        filter: url(#visual-diff-filter-added);
      `}

    ${({ $ancestorItemType }) =>
      $ancestorItemType === AncestorItemType.UPDATED_COMPONENT &&
      css`
        filter: url(#visual-diff-filter-changed);
      `}

    position: relative;
    &::before {
      display: block;
      content: ' ';
      position: absolute;
      width: 24px;
      height: 24px;
      z-index: -1;
      ${visualDiffBackgroundCircle}
    }
  }
`;

const IconPlaceholder = styled.div`
  margin-right: 8px;
  width: 20px;
  height: 20px;
  border: 1px solid ${colors.grey35};
  border-radius: 20px;
`;

const AncestorItemComponent = ({
  ancestorItem,
  enhancedScopeData,
  enhancedBranchOffScopeData,
  graphics,
}: {
  ancestorItem: AncestorItem;
  enhancedScopeData: EnhancedScopeDataWithBranchName;
  enhancedBranchOffScopeData: EnhancedScopeDataWithBranchName;
  graphics: Graphics;
}) => {
  if (ancestorItem.ancestorItemType === AncestorItemType.PLACEHOLDER)
    return (
      <FlexWrapper>
        <IconPlaceholder />
        {ancestorItem.label}
      </FlexWrapper>
    );

  const relativeScopeData =
    ancestorItem.ancestorItemType === AncestorItemType.DELETED_COMPONENT
      ? enhancedBranchOffScopeData
      : enhancedScopeData;

  const name = readRawValue(
    APIEntityType.COMPONENT,
    ancestorItem.componentId,
    'name',
    relativeScopeData
  );

  return (
    <>
      <FilterIconWrapper>
        <ComponentIconRepresentation
          entityType={APIEntityType.COMPONENT}
          entityId={ancestorItem.componentId}
          enhancedScopeData={relativeScopeData}
          graphics={graphics}
          style={{ marginRight: 0 }}
        />
      </FilterIconWrapper>
      {name}
    </>
  );
};

const ComponentsAncestorsWithConflicts = ({
  workspace,
  graphics,
  enhancedScopeData,
  enhancedBranchOffScopeData,
  ancestorItems,
}: {
  workspace: APIWorkspaceAttributes;
  ancestorItems: AncestorItem[];
  enhancedBranchOffScopeData: EnhancedScopeDataWithBranchName;
  enhancedScopeData: EnhancedScopeDataWithBranchName;
  graphics: Graphics;
}) => {
  return (
    <>
      <MediumSizeWorkspaceLabel workspace={workspace} />
      {ancestorItems.map((ancestorItem, i) => (
        <ItemWrapperWithFilter
          key={i}
          $ancestorItemType={ancestorItem.ancestorItemType}
          $indentationLevel={i}
        >
          <AncestorItemComponent
            ancestorItem={ancestorItem}
            enhancedScopeData={enhancedScopeData}
            enhancedBranchOffScopeData={enhancedBranchOffScopeData}
            graphics={graphics}
          />
        </ItemWrapperWithFilter>
      ))}
    </>
  );
};

export default ComponentsAncestorsWithConflicts;
