import {
  TablesSelection,
  TablesSelectionBaseTable,
  TablesSelectionTablePayload,
} from '@ardoq/integrations';
import { TableId } from 'integrations/common/streams/tabularMappings/types';
import { AsyncStatus } from 'integrations/common/types/api';
import { Maybe } from '@ardoq/common-helpers';
import { IntegrationResources } from 'integrations/unified/streams/resources/types';
import { RefObject } from 'react';
import { connect } from '@ardoq/rxbeach';
import styled from 'styled-components';
import { viewModel$ } from './viewModel$';

export const TableSelector = ({
  tables = [],
  selectedTable,
  selectedTables,
  getTablesRequestStatus,
  onSelectTable,
  onUnselectTable,
  selectionRef,
}: {
  tables: TablesSelectionTablePayload[];
  selectedTable: Maybe<TableId>;
  selectedTables: IntegrationResources['selectedResources'];
  getTablesRequestStatus: AsyncStatus;
  onSelectTable: (table: TablesSelectionBaseTable) => void;
  onUnselectTable: (table: TablesSelectionBaseTable) => void;
  selectionRef: RefObject<HTMLDivElement>;
}) => {
  return (
    <TablesSelectionContainer>
      <TablesSelection
        dataSource={tables}
        onSelectTable={onSelectTable}
        onUnselectTable={onUnselectTable}
        selectedRowId={selectedTable}
        selectedTables={selectedTables}
        disabled={
          getTablesRequestStatus === 'INIT' ||
          getTablesRequestStatus === 'LOADING'
        }
        selectionRef={selectionRef}
      />
    </TablesSelectionContainer>
  );
};

const TablesSelectionContainer = styled.div`
  flex: 1;
  min-width: 400px;
`;

export default connect(TableSelector, viewModel$);
