import { IconName } from '@ardoq/icons';
import { FilterState } from './types';
import { APIEntityType } from '@ardoq/api-types';

export enum FilterSections {
  WORKSPACES = 'Workspaces',
  ACTIONS = 'Actions',
  USERS = 'Users',
  DATE_RANGE = 'Date range',
  CHANGE_TYPE = 'Change type',
}

export enum LoadingState {
  LOADING = 1,
  LOADED = 2,
}

export const sectionIconNamesMap = {
  [FilterSections.WORKSPACES]: IconName.WORKSPACE,
  [FilterSections.ACTIONS]: IconName.SYNC,
  [FilterSections.USERS]: IconName.PEOPLE,
  [FilterSections.DATE_RANGE]: IconName.TIME,
  [FilterSections.CHANGE_TYPE]: IconName.ARROWS_H,
};

export const emptyChangeTypeFilterState = {
  [APIEntityType.COMPONENT]: null,
  [APIEntityType.COMPONENT_TYPE]: null,
  [APIEntityType.REFERENCE]: null,
  [APIEntityType.REFERENCE_TYPE]: null,
  [APIEntityType.FIELD]: null,
  [APIEntityType.WORKSPACE]: null,
};

export const emptyFilterState: FilterState = {
  workspaces: [],
  actions: [],
  users: [],
  dateRange: {
    before: null,
    since: null,
  },
  name: '',
  entityTypes: emptyChangeTypeFilterState,
};
