import { AppModules } from 'appContainer/types';
import SwitchOrganizationMenu from './SwitchOrganizationMenu';
import QuickSearch from 'search/quickSearch/QuickSearch';
import TrialDaysRemaining from './TrialDaysRemaining';
import MainToolbar from './MainToolbar';
import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import { SaveBar } from './SaveBar';
import { SelectOption } from '@ardoq/select';
import { topbarStream$ } from './topbar$';

const TopbarWrapper = styled.div`
  width: 100%;
  display: flex;
  font-size: 18px;
`;

const TopbarItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Left = styled(TopbarItem)`
  /* To trigger ellipsis on dropdown for a very long names */
  overflow: hidden;
  margin-left: 24px;
`;

const Center = styled(TopbarItem)`
  flex: 0 1 400px;
`;

const Right = styled(TopbarItem)`
  justify-content: flex-end;
`;

const ModeHeader = styled(TopbarItem)`
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 20px;
  color: white;
`;

type OldTopbarProps = {
  selectedModule: AppModules;
  customFields: SelectOption<string>[];
  daysRemaining: number;
  isTrial: boolean;
  isScenarioMode: boolean;
  syncProgress: number;
  shouldUseNewJourneyVersion: boolean;
};

const OldTopbar = ({
  isScenarioMode,
  selectedModule,
  customFields,
  daysRemaining,
  isTrial,
  syncProgress,
  shouldUseNewJourneyVersion,
}: OldTopbarProps) => {
  const showQuickSearch =
    selectedModule &&
    (selectedModule === AppModules.WORKSPACES ||
      selectedModule === AppModules.HOME);

  return (
    <TopbarWrapper>
      <Left>
        <SwitchOrganizationMenu />
        {isScenarioMode ? (
          <ModeHeader>&apos;Scenario mode&apos;</ModeHeader>
        ) : null}
      </Left>
      <Center>
        {showQuickSearch && (
          <QuickSearch
            placeholderText="Go to..."
            customFields={customFields}
            hasNewJourneyFeature={false}
          />
        )}
      </Center>
      <Right>
        <SaveBar progress={syncProgress} />
        {isTrial && <TrialDaysRemaining daysRemaining={daysRemaining} />}
        <MainToolbar shouldUseNewJourneyVersion={shouldUseNewJourneyVersion} />
      </Right>
    </TopbarWrapper>
  );
};

export default connect(OldTopbar, topbarStream$);
