import styled from 'styled-components';
import { colors, s16 } from '@ardoq/design-tokens';
import {
  ArdoqLoaderComponent,
  LoaderColor,
} from '@ardoq/ardoq-loader-component';

export const SearchTabContainer = styled('div')`
  padding: 32px;
`;

const SpinnerContainer = styled('div')`
  display: inline-block;
  margin-left: 16px;
  line-height: 0;
  vertical-align: text-bottom;
  .spinner {
    vertical-align: bottom;
  }
`;

export const Container = styled.div`
  display: flex;
  margin-bottom: ${s16};
`;

export const Error = styled.div`
  margin-top: 8px;
  flex-grow: 1;
  color: ${colors.red60};
`;

export const SearchSpinner = () => (
  <SpinnerContainer>
    <ArdoqLoaderComponent
      loaderText=""
      labelPosition="horizontal"
      loaderType="spinner"
      loaderColor={LoaderColor.GRAY}
      size={20}
    />
  </SpinnerContainer>
);
