import AudienceTags from './audienceTags/AudienceTags';
import AudienceOptionsDropdown from './audienceOptionsDropdown/AudienceOptionsDropdown';
import BroadcastAudienceDescription from './BroadcastAudienceDescription';
import { useEffectOnce } from '@ardoq/hooks';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { openAudiencePreviewDialog } from 'broadcasts/actions';
import { VisibilityIcon } from '@ardoq/icons';
import broadcastAudienceForm$, {
  BroadcastAudienceFormStreamShape,
} from './broadcastAudienceForm$';
import { BroadcastAudience, BroadcastContent } from '@ardoq/api-types';
import { isValidAudience, isValidContent } from 'broadcasts/utils';
import { fetchPeopleWorkspaceOptions } from 'broadcasts/broadcastBuilder/broadcastAudienceForm/dialogs/workspaceAudienceDialog/actions';
import { ButtonGroup, GhostButton } from '@ardoq/button';
import { Island } from '@ardoq/page-layout';
import { ErrorNotification } from '@ardoq/status-ui';
import { Box } from '@ardoq/layout';

type BroadcastAudienceFormProps = {
  content: BroadcastContent;
  audiences: BroadcastAudience[];
} & BroadcastAudienceFormStreamShape;

const BroadcastAudienceForm = ({
  content,
  audiences,
  validation: { showRequiredFields },
  canRunGremlinQueries,
}: BroadcastAudienceFormProps) => {
  useEffectOnce(() => {
    if (canRunGremlinQueries) {
      dispatchAction(fetchPeopleWorkspaceOptions());
    }
  });
  return (
    <Island
      data-intercom-target="broadcast-audience"
      title="2. Select audience"
      subtitle={
        <BroadcastAudienceDescription contentType={content.contentType} />
      }
    >
      <div>
        {audiences.length ? <AudienceTags audiences={audiences} /> : null}
        {showRequiredFields && !audiences.length && isValidContent(content) && (
          <ErrorNotification>Required field</ErrorNotification>
        )}
      </div>
      <Box paddingTop="medium">
        <ButtonGroup>
          <AudienceOptionsDropdown
            contentType={content.contentType}
            audiences={audiences}
            canRunGremlinQueries={canRunGremlinQueries}
            disabled={!isValidContent(content)}
          />
          <GhostButton
            onClick={() => dispatchAction(openAudiencePreviewDialog())}
            isDisabled={!isValidAudience(content, audiences)}
            data-click-id="open-audience-preview-dialog"
            dataTestId="open-audience-preview-dialog"
          >
            <VisibilityIcon />
            Preview audience
          </GhostButton>
        </ButtonGroup>
      </Box>
    </Island>
  );
};

export default connect(BroadcastAudienceForm, broadcastAudienceForm$);
