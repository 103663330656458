import { ViewpointBuilderTab } from 'viewpointBuilder/viewpointBuilderNavigation/types';
import {
  InitialViewpointBuilderConfiguration,
  ViewpointBuilderCommands,
  ViewpointBuilderContext,
} from 'viewpointBuilder/types';
import {
  DirectedTripleWithFilters,
  FilterInterfaceFilter,
  FormattingFilter,
  StartContextSelectionType,
  QueryBuilderQuery,
  QueryBuilderSubquery,
  ComponentSelection,
  isQueryComponentSelection,
  isManualComponentSelection,
  FilterTypes,
} from '@ardoq/api-types';
import { filterOperations } from '@ardoq/core';
import { isPerspectiveGroupingRule } from '@ardoq/perspectives';
import { v4 as uuidv4 } from 'uuid';
import { mapToPerspectiveEditorFormattingRule } from 'streams/perspectiveEditorData/mapToPerspectiveEditorFormattingRule';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateAdvancedSearch } from 'search/AdvancedSearch/actions';
import { ComponentSearchData } from './selectContextTab/types';
import { getStartSetFilter } from './getStartSetFilter';
import { setShouldIncludeInstanceCounts } from './traversals/editTraversalActions';
import { setFolderId } from './viewpointMetaInfo/metaInfoActions';
import { resetViewpointBuilder } from './resetViewpointBuilder';
import { ReferenceLabelSource } from '@ardoq/data-model';
import { getSupportedTraversalViewIdOrDefault } from 'traversals/getViewpointModeSupportedViews';
import { getLabelFormattingFromFilterAttributes } from '@ardoq/filter-interface';

type InitViewpointBuilderArgs = {
  activeTab?: ViewpointBuilderTab;
  context: ViewpointBuilderContext;
  initialConfiguration?: InitialViewpointBuilderConfiguration;
  folderId?: string | null;
  viewpointBuilderCommands: ViewpointBuilderCommands;
};

export const initViewpointBuilder = ({
  activeTab,
  context,
  initialConfiguration,
  folderId,
  viewpointBuilderCommands,
}: InitViewpointBuilderArgs) => {
  const startSetFilter = initialConfiguration
    ? getStartSetFilter({
        paths: initialConfiguration.initialPaths,
        filters: initialConfiguration.filters,
      })
    : null;

  dispatchAction(
    setShouldIncludeInstanceCounts(shouldIncludeInstanceCounts(context))
  );

  resetViewpointBuilder();
  dispatchAction(setFolderId(folderId ?? null));

  viewpointBuilderCommands.navigationCommands.selectViewpointBuilderContext(
    context
  );
  if (activeTab) {
    viewpointBuilderCommands.navigationCommands.selectTab(activeTab);
  }

  if (!initialConfiguration) {
    return;
  }

  const {
    initialPaths,
    filters,
    name,
    viewName,
    description,
    startType: initialStartType,
    groupBys,
    conditionalFormatting,
    viewpointId,
    viewpointVersion,
    loadedStateHash,
    pathMatching,
    pathCollapsingRules,
  } = initialConfiguration;

  const startType = initialPaths
    ? getStartTypeFromPaths(initialPaths)
    : (initialStartType ?? null);

  initSearchTab({
    ...initialConfiguration,
    startType: startType ?? undefined,
    commands: viewpointBuilderCommands,
    context,
    startSetFilter,
  });

  if (initialPaths) {
    viewpointBuilderCommands.editTraversalCommands.setTraversals(
      initialPaths,
      filters
    );
  }

  if (pathMatching) {
    viewpointBuilderCommands.editTraversalCommands.setPathMatchingType(
      pathMatching
    );
  }

  if (pathCollapsingRules) {
    viewpointBuilderCommands.collapsePathsCommands.setPathCollapsingRules(
      pathCollapsingRules
    );
  }

  viewpointBuilderCommands.metaInfoCommands.setInitialMetaInfo({
    initialState: {
      name: name ?? null,
      description: description ?? null,
      startType: startType ?? null,
      paths: initialPaths ?? [],
      viewName: getSupportedTraversalViewIdOrDefault(viewName),
      _id: viewpointId ?? null,
      _version: viewpointVersion ?? null,
      loadedStateHash: loadedStateHash ?? null,
    },
  });

  if (groupBys) {
    viewpointBuilderCommands.groupingsCommands.setGroupingRules(
      groupBys
        .map(rule => ({ ...rule, id: uuidv4() }))
        .filter(isPerspectiveGroupingRule)
    );
  }

  if (conditionalFormatting) {
    applyFormatting(viewpointBuilderCommands, conditionalFormatting);
  }

  if (viewName && context === 'editViewpoint') {
    viewpointBuilderCommands.selectedViewCommands.setSelectedView({
      value: viewName,
      label: viewName,
    });
  }
};

const initBasicSearch = (
  componentSelection: ComponentSelection | null,
  loadedComponents: ComponentSearchData[] | undefined,
  commands: ViewpointBuilderCommands,
  startType: string | undefined,
  startSearchTerm: string | undefined,
  context: ViewpointBuilderContext,
  startSetFilter: QueryBuilderSubquery | null
) => {
  if (
    componentSelection &&
    isManualComponentSelection(componentSelection) &&
    loadedComponents
  ) {
    commands.componentsSearchCommands.setLoadedComponents({
      loadedComponents,
      totalCount: loadedComponents.length,
    });
    commands.componentsSearchCommands.setSelectedComponents(
      loadedComponents.map(component => ({
        ...component,
        isSelected: true,
      }))
    );
  }

  const shouldApplyStartSetQuery = context === 'openViewpoint';
  if (startSetFilter && shouldApplyStartSetQuery) {
    commands.componentsSearchCommands.setStartSetFilter(startSetFilter);
  }

  if (startType) {
    commands.componentsSearchCommands.setComponentTypeName(startType);
    commands.editTraversalCommands.setStartTypeAndTraversalStartSetOrCount(
      startType,
      componentSelection && isManualComponentSelection(componentSelection)
        ? componentSelection.startSet
        : []
    );
  }
  if (startSearchTerm) {
    commands.componentsSearchCommands.setComponentName(startSearchTerm);
  }
  if (componentSelection && isQueryComponentSelection(componentSelection)) {
    commands.componentsSearchCommands.setTraversalStartQuery(
      componentSelection.startQuery
    );
    commands.componentsSearchCommands.setAllComponentsSelected(true);
  }
};

const initSearchTab = ({
  componentSelection,
  loadedComponents,
  startType,
  startSearchTerm,
  commands,
  context,
  startSetFilter,
}: InitialViewpointBuilderConfiguration & {
  commands: ViewpointBuilderCommands;
  context: ViewpointBuilderContext;
  startSetFilter: QueryBuilderSubquery | null;
}) => {
  if (
    componentSelection?.startContextSelectionType ===
    StartContextSelectionType.ADVANCED_SEARCH_SELECT_ALL
  ) {
    initAdvancedSearch(componentSelection.startQuery, commands, startType);
  } else {
    initBasicSearch(
      componentSelection,
      loadedComponents,
      commands,
      startType,
      startSearchTerm,
      context,
      startSetFilter
    );
  }
};

const initAdvancedSearch = (
  startQuery: QueryBuilderQuery,
  commands: ViewpointBuilderCommands,
  startType?: string
) => {
  // We need to extract the user-added rules from the query,
  // rules[0] contains the SearchContextRule.
  const queryBuilderSubQuery = startQuery.rules[1];
  dispatchAction(
    updateAdvancedSearch({ queryBuilderRules: queryBuilderSubQuery })
  );
  commands.componentsSearchCommands.viewpointBuilderAdvancedSearchQueryUpdated({
    isValid: true,
    isEmpty: false,
    query: startQuery,
  });
  commands.componentsSearchCommands.setAllComponentsSelected(true);
  if (startType) {
    commands.editTraversalCommands.setStartType(startType);
  }
};

const getStartTypeFromPaths = (paths: DirectedTripleWithFilters[][]) => {
  const firstTriple = paths[0][0];
  if (!firstTriple) return null;
  const { sourceType, targetType, direction } = firstTriple;
  return direction === 'outgoing' ? sourceType : targetType;
};

const applyFormatting = (
  viewpointBuilderCommands: ViewpointBuilderCommands,
  conditionalFormatting: (FormattingFilter | FilterInterfaceFilter)[]
) => {
  viewpointBuilderCommands.formattingCommands.setFormattingRules({
    formattingRules: conditionalFormatting
      .filter(filterOperations.isStrictlyFormattingFilter)
      .map(mapToPerspectiveEditorFormattingRule)
      .filter(ExcludeFalsy),
  });

  const labelFormatting = getLabelFormattingFromFilterAttributes(
    conditionalFormatting
  );
  viewpointBuilderCommands.formattingCommands.setLabelFormatting({
    labelFormatting,
  });

  const showReferenceType =
    !conditionalFormatting.length ||
    conditionalFormatting.some(
      ({ value, type }) =>
        type === FilterTypes.REFERENCE_LABEL &&
        (value === ReferenceLabelSource.REFERENCE_TYPE ||
          // DISPLAY_TEXT_OR_REFERENCE_TYPE is a legacy value which is not in use anymore
          value === ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE)
    );

  viewpointBuilderCommands.formattingCommands.onShowReferenceTypeToggled(
    showReferenceType
  );

  return;
};

const contextWithInstanceCounts = new Set<ViewpointBuilderContext>([
  'editSubgraph',
  'contextMenu',
  'editSearch',
  'loadNewViewpoint',
]);

const shouldIncludeInstanceCounts = (context: ViewpointBuilderContext) => {
  return contextWithInstanceCounts.has(context);
};
