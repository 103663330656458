import { PortalConfig, startTargetPortalHub } from '@ardoq/post-message-bridge';
import { isAutomatedTestMode } from '../appConfig';

// target configs
import { gridEditorTargetPortalConfig } from './configs/gridEditor.target';
import { perspectivesEditorTargetPortalConfig } from './configs/perspectivesEditor.target';

/**
 * REGISTER YOUR TARGET CONFIG HERE
 * This configures the ardoq-front -> popout/iframe communication
 */
const TARGET_ENTRIES = [
  gridEditorTargetPortalConfig,
  perspectivesEditorTargetPortalConfig,
].map(config => {
  return [config.id, config];
});

// ensure this is not imported in the wrong side of the bridge
if (!window.IS_MAIN_ARDOQ_APP && !isAutomatedTestMode()) {
  throw new Error(
    `postMessageBridge "targetConfigs.ts" were imported by a standalone app, ` +
      `standalone apps should use "sourceConfigs.ts"`
  );
}

export const startArdoqFrontTargetPortalHub = () => {
  const targetConfigs: Record<string, PortalConfig> =
    Object.fromEntries(TARGET_ENTRIES);

  const cleanupFn = startTargetPortalHub({
    currentWindow: window,
    targetConfigs,
    logTag: 'ardoq-front',
  });

  return cleanupFn;
};
