import { ApiResponse, entityGroupApi } from '@ardoq/api';
import {
  ArdoqId,
  EntityGroupShape,
  PersistedEntityGroupShape,
  PrivilegeLabel,
} from '@ardoq/api-types';
import { PrimaryButton } from '@ardoq/button';
import { Features, hasFeature } from '@ardoq/features';
import { LoadingWrapper } from '@ardoq/status-ui';
import FormCell from 'admin/bundles/BundleEditor/FormCell';
import FormRow from 'admin/bundles/BundleEditor/FormRow';
import {
  backboneModelsToSelectOptions,
  getBroadcastSelectOptions,
  getGraphFilterSelectOptions,
  getPresentationSelectOptions,
  getReportSelectOptions,
  getSurveySelectOptions,
  getTraversalSelectOptions,
  getViewpointSelectOptions,
  loadAsync,
  splitDateRangeFieldsIds,
} from 'admin/bundles/BundleEditor/utils';
import { Description, ErrorBox } from 'admin/bundles/atoms';
import privileges from 'admin/privileges';
import { LabeledValue } from 'aqTypes';
import Perspectives from 'collections/perspectives';
import { debounce, isEqual } from 'lodash';
import { ChangeEvent, Component } from 'react';
import { getEntityGroupEditorFormErrors } from '../validationUtils';
import CollectionSelectInput from './CollectionSelectInput';
import { DateRangeFieldAttributes } from '@ardoq/date-range';
import { TextInput } from '@ardoq/forms';
import { Paragraph } from '@ardoq/typography';
import { Box } from '@ardoq/layout';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';

const emptyEntityGroup: EntityGroupShape = {
  name: '',
  componentTypeIds: [],
  referenceTypeIds: [],
  fieldIds: [],
  surveyIds: [],
  presentationIds: [],
  perspectiveIds: [],
  dashboardIds: [],
  tabularMappingIds: [],
  servicenowConfigIds: [],
  broadcastIds: [],
  storedQueryIds: [],
  viewpointIds: [],
  reportIds: [],
  traversalIds: [],
};

const convertPersistedEntityGroupShapeToEditing = (
  {
    name,
    componentTypeIds = [],
    referenceTypeIds = [],
    fieldIds = [],
    surveyIds = [],
    presentationIds = [],
    perspectiveIds = [],
    dashboardIds = [],
    tabularMappingIds = [],
    servicenowConfigIds = [],
    broadcastIds = [],
    viewpointIds = [],
    storedQueryIds = [],
    reportIds = [],
    traversalIds = [],
  }: PersistedEntityGroupShape,
  shouldOmitName: boolean
): EntityGroupShape => ({
  name: shouldOmitName ? '' : name,
  componentTypeIds,
  referenceTypeIds,
  fieldIds,
  surveyIds,
  presentationIds,
  perspectiveIds,
  dashboardIds,
  tabularMappingIds,
  servicenowConfigIds,
  broadcastIds,
  viewpointIds,
  storedQueryIds,
  reportIds,
  traversalIds,
});

type EntityGroupEditorProps = {
  navigateToEntityGroupOverview: () => void;
  editingEntityGroupId?: ArdoqId;
  createEntityGroupHasChanges: boolean;
  setChanges: (createEntityGroupHasChanges: boolean) => void;
  isCreatingFromExistingEntityGroup: boolean;
};

type EntityGroupEditorState = {
  entityGroup: EntityGroupShape;
  isLoaded: boolean;
  errorMsgs: string[];
};

class EntityGroupEditor extends Component<
  EntityGroupEditorProps,
  EntityGroupEditorState
> {
  state = { entityGroup: emptyEntityGroup, isLoaded: false, errorMsgs: [] };
  componentTypeSelectOptions: LabeledValue[] = [];
  referenceTypeSelectOptions: LabeledValue[] = [];
  fieldSelectOptions: LabeledValue[] = [];
  dashboardSelectOptions: LabeledValue[] = [];
  excelImportMappingConfigs: LabeledValue[] = [];
  createdDateRangeFields: DateRangeFieldAttributes[] = [];
  serviceNowImportMappingConfigs: LabeledValue[] = [];
  editingPersistedEntityGroupShape: PersistedEntityGroupShape | undefined =
    undefined;

  debouncedSetState = debounce(
    partialState => this.setState(partialState),
    200
  );

  submit = async () => {
    const validation = await getEntityGroupEditorFormErrors({
      entityGroupAttrs: this.state.entityGroup,
      isEditing: Boolean(this.props.editingEntityGroupId),
    });
    if (!validation.isValid && validation.errorMsgs.length) {
      this.setState({ errorMsgs: validation.errorMsgs });
      return;
    }

    const handleError = async (response: ApiResponse<any>) => {
      const maybeError = await response;
      if (isArdoqError(maybeError)) {
        this.setState({ errorMsgs: [getArdoqErrorMessage(maybeError)] });
        return true;
      }
      return false;
    };

    const validatedEntityGroup: EntityGroupShape = {
      ...this.state.entityGroup,
      fieldIds: splitDateRangeFieldsIds({
        fieldIds: this.state.entityGroup.fieldIds,
        dateRangeFields: this.createdDateRangeFields,
      }),
    };
    const response = this.editingPersistedEntityGroupShape
      ? entityGroupApi.update({
          ...this.editingPersistedEntityGroupShape,
          ...validatedEntityGroup,
        })
      : entityGroupApi.create(validatedEntityGroup);
    if (await handleError(response)) {
      return;
    }
    this.editingPersistedEntityGroupShape = undefined;
    this.props.navigateToEntityGroupOverview();
  };

  updateName = (name: string) => {
    this.debouncedSetState({
      entityGroup: {
        ...this.state.entityGroup,
        name,
      },
    });
  };

  updaterFor = (key: string) => {
    return (entityIds: ArdoqId[]) => {
      this.setState({
        entityGroup: { ...this.state.entityGroup, ...{ [key]: entityIds } },
      });
    };
  };

  componentDidUpdate(prevProps: EntityGroupEditorProps) {
    const wasEditingEntityGroup = Boolean(prevProps.editingEntityGroupId);
    const switchedFromEditToCreate =
      wasEditingEntityGroup && !this.props.editingEntityGroupId;
    if (switchedFromEditToCreate) {
      this.editingPersistedEntityGroupShape = undefined;
      this.setState({ entityGroup: emptyEntityGroup, errorMsgs: [] });
      this.props.setChanges(false);
      return;
    }

    const createEntityGroupHasChanges = this.editingPersistedEntityGroupShape
      ? !isEqual(
          convertPersistedEntityGroupShapeToEditing(
            this.editingPersistedEntityGroupShape,
            false
          ),
          this.state.entityGroup
        )
      : !isEqual(emptyEntityGroup, this.state.entityGroup);
    if (this.props.createEntityGroupHasChanges !== createEntityGroupHasChanges)
      this.props.setChanges(createEntityGroupHasChanges);
  }

  async componentDidMount() {
    this.setState({ isLoaded: false });
    const {
      loadAsyncErrorMsgs,
      componentTypeSelectOptions,
      referenceTypeSelectOptions,
      fieldSelectOptions,
      dashboardSelectOptions,
      excelImportMappingConfigs,
      serviceNowImportMappingConfigs,
      createdDateRangeFields,
    } = await loadAsync();
    this.componentTypeSelectOptions = componentTypeSelectOptions;
    this.referenceTypeSelectOptions = referenceTypeSelectOptions;
    this.fieldSelectOptions = fieldSelectOptions;
    this.dashboardSelectOptions = dashboardSelectOptions;
    this.excelImportMappingConfigs = excelImportMappingConfigs;
    this.serviceNowImportMappingConfigs = serviceNowImportMappingConfigs;
    this.createdDateRangeFields = createdDateRangeFields;
    this.setState({ isLoaded: true });

    if (!this.props.editingEntityGroupId) {
      if (loadAsyncErrorMsgs.length)
        this.setState({ errorMsgs: loadAsyncErrorMsgs });
      return;
    }

    const persistedEntityGroup = await entityGroupApi.fetch(
      this.props.editingEntityGroupId
    );
    if (isArdoqError(persistedEntityGroup)) {
      this.setState({
        errorMsgs: [
          ...loadAsyncErrorMsgs,
          getArdoqErrorMessage(persistedEntityGroup),
        ],
      });
      return;
    }
    const entityGroupToLoad = convertPersistedEntityGroupShapeToEditing(
      persistedEntityGroup,
      this.props.isCreatingFromExistingEntityGroup
    );
    this.editingPersistedEntityGroupShape = this.props
      .isCreatingFromExistingEntityGroup
      ? undefined
      : persistedEntityGroup;
    this.setState({ entityGroup: entityGroupToLoad });
  }

  render() {
    const isEditing = Boolean(this.props.editingEntityGroupId);
    return (
      <LoadingWrapper loading={!this.state.isLoaded}>
        <FormRow>
          <FormCell>
            <Box paddingBottom="small">
              <Paragraph variant="text1Bold">Entity group name</Paragraph>
            </Box>
            <TextInput
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                return this.updateName(event.target.value);
              }}
              defaultValue={this.state.entityGroup.name}
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.componentTypeSelectOptions}
              collectionLabel="component type"
              selectedEntityIds={this.state.entityGroup.componentTypeIds}
              onOptionChange={this.updaterFor('componentTypeIds')}
            />
          </FormCell>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.referenceTypeSelectOptions}
              collectionLabel="reference type"
              selectedEntityIds={this.state.entityGroup.referenceTypeIds}
              onOptionChange={this.updaterFor('referenceTypeIds')}
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.fieldSelectOptions}
              collectionLabel="field"
              selectedEntityIds={this.state.entityGroup.fieldIds}
              onOptionChange={this.updaterFor('fieldIds')}
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={getPresentationSelectOptions()}
              collectionLabel="presentation"
              selectedEntityIds={this.state.entityGroup.presentationIds}
              onOptionChange={this.updaterFor('presentationIds')}
            />
          </FormCell>
          <FormCell>
            <CollectionSelectInput
              allOptions={getSurveySelectOptions()}
              collectionLabel="survey"
              selectedEntityIds={this.state.entityGroup.surveyIds}
              onOptionChange={this.updaterFor('surveyIds')}
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={backboneModelsToSelectOptions(Perspectives.toArray())}
              collectionLabel="perspective"
              selectedEntityIds={this.state.entityGroup.perspectiveIds}
              onOptionChange={this.updaterFor('perspectiveIds')}
            />
          </FormCell>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.dashboardSelectOptions}
              collectionLabel="dashboard"
              selectedEntityIds={this.state.entityGroup.dashboardIds}
              onOptionChange={this.updaterFor('dashboardIds')}
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={getGraphFilterSelectOptions()}
              collectionLabel="stored query"
              selectedEntityIds={this.state.entityGroup.storedQueryIds}
              onOptionChange={this.updaterFor('storedQueryIds')}
            />
          </FormCell>
          {hasFeature(Features.BROADCASTS) ? (
            <FormCell>
              <CollectionSelectInput
                allOptions={getBroadcastSelectOptions()}
                collectionLabel="broadcast"
                selectedEntityIds={this.state.entityGroup.broadcastIds}
                onOptionChange={this.updaterFor('broadcastIds')}
              />
            </FormCell>
          ) : null}
        </FormRow>
        <FormRow>
          {privileges.hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER) ? (
            <FormCell>
              <CollectionSelectInput
                allOptions={getViewpointSelectOptions()}
                collectionLabel="viewpoint"
                selectedEntityIds={this.state.entityGroup.viewpointIds}
                onOptionChange={this.updaterFor('viewpointIds')}
              />
            </FormCell>
          ) : null}
          <FormCell>
            <CollectionSelectInput
              allOptions={getReportSelectOptions()}
              collectionLabel="report"
              selectedEntityIds={this.state.entityGroup.reportIds}
              onOptionChange={this.updaterFor('reportIds')}
            />
          </FormCell>
        </FormRow>
        <FormRow>
          {hasFeature(Features.SUPPORT_LARGE_DATASETS) ? (
            <FormCell>
              <CollectionSelectInput
                allOptions={getTraversalSelectOptions()}
                collectionLabel="traversal"
                selectedEntityIds={this.state.entityGroup.traversalIds}
                onOptionChange={this.updaterFor('traversalIds')}
              />
            </FormCell>
          ) : null}
        </FormRow>
        <FormRow>
          <FormCell $fullWidth>
            <Box paddingBottom="small">
              <Paragraph variant="text1Bold">Integrations options</Paragraph>
            </Box>
            <Description>
              Include configuration settings for integrations below.
            </Description>
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.excelImportMappingConfigs}
              collectionLabel="column mapping"
              selectedEntityIds={this.state.entityGroup.tabularMappingIds}
              onOptionChange={this.updaterFor('tabularMappingIds')}
              description={`Saved configuration options for how to map imported data columns to Ardoq entities.
              E.g. Excel column "description" should always map to the component's description field.`}
            />
          </FormCell>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.serviceNowImportMappingConfigs}
              collectionLabel="ServiceNow import configuration"
              selectedEntityIds={this.state.entityGroup.servicenowConfigIds}
              onOptionChange={this.updaterFor('servicenowConfigIds')}
              description={`Configuration for which ServiceNow tables & fields should be imported into Ardoq.`}
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell $fullWidth>
            {!this.state.errorMsgs.length ? null : (
              <ErrorBox errorMsgs={this.state.errorMsgs} />
            )}
            <PrimaryButton
              isDisabled={!this.props.createEntityGroupHasChanges}
              onClick={this.submit}
            >
              {isEditing ? 'Save Changes' : 'Create Entity Group'}
            </PrimaryButton>
          </FormCell>
        </FormRow>
      </LoadingWrapper>
    );
  }
}

export default EntityGroupEditor;
