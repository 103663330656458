import {
  APIDiscoverViewpointAttributes,
  ReferenceDirection,
} from '@ardoq/api-types';
import GroupRow, { groupTypeLabelMap } from './GroupRow';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { Icon, IconName } from '@ardoq/icons';
import { InfoNotification as NotificationBase } from '@ardoq/status-ui';
import { useController, useFormContext } from 'react-hook-form';
import { GroupNameHeaderInfoIcon } from '@ardoq/perspectives';
import Table from '../Table';
import { Stack } from '@ardoq/layout';
import DefaultGroupingCollapseStateSelect from './DefaultGroupingCollapseStateSelect';

const NOTIFICATION_MAX_WIDTH = '520px';
const groupByFieldHeaders = [
  { label: 'Group by' },
  { label: 'Type' },
  { label: 'Direction' },
  { label: 'Group name', icon: <GroupNameHeaderInfoIcon /> },
];

const Grouping = () => {
  const {
    field: { onChange },
  } = useController({ name: 'groupBys' });

  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const [workspaceIds, groupBys, viewStyle] = watch([
    'workspaceIds',
    'groupBys',
    'viewStyle',
  ]);

  const groupOptions = [...groupTypeLabelMap].map(groupType => ({
    label: groupType[1],
    onClick: () => {
      onChange([
        ...(groupBys ?? []),
        { type: groupType[0], direction: ReferenceDirection.INCOMING },
      ]);
    },
  }));

  const multipleGroupingsAppliedWarning =
    (viewStyle.style === 'dependency-map' || viewStyle.style === 'timeline') &&
    groupBys &&
    groupBys.length > 1;

  return (
    <Stack gap="medium" align="flex-start">
      <ButtonWithDropdown
        options={groupOptions}
        dataTestId="add-grouping-button"
      >
        <Icon iconName={IconName.ADD} />
        Add grouping
      </ButtonWithDropdown>
      {multipleGroupingsAppliedWarning && (
        <NotificationBase maxWidth={NOTIFICATION_MAX_WIDTH}>
          The order of the grouping rules will be reflected in the view style
          layout.
        </NotificationBase>
      )}
      {groupBys && groupBys.length ? (
        <>
          <Table tableHeaders={groupByFieldHeaders}>
            {groupBys.map((group, index) => (
              <GroupRow
                group={group}
                key={index}
                updateGrouping={updatedGroup => {
                  const updatedGroupBys = [
                    ...groupBys.slice(0, index),
                    updatedGroup,
                    ...groupBys.slice(index + 1),
                  ];
                  onChange(updatedGroupBys);
                }}
                removeGroup={() => {
                  onChange(groupBys.filter((_, i) => i !== index));
                }}
                workspaceIds={workspaceIds}
              />
            ))}
          </Table>
          {(viewStyle.style === 'timeline' ||
            viewStyle.style === 'simple-block-diagram') && (
            <DefaultGroupingCollapseStateSelect viewStyle={viewStyle} />
          )}
        </>
      ) : null}
    </Stack>
  );
};

export default Grouping;
