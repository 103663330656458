import styled, { css } from 'styled-components';
import * as fonts from 'app/designTokens/fonts';

export const FlexWrapper = styled.div<{ $isCommaSeparated?: boolean }>`
  display: inline-flex;
  align-items: center;
  ${({ $isCommaSeparated }) => $isCommaSeparated && afterComaPseudoElement};
`;

const afterComaPseudoElement = css`
  &::after {
    content: ',';
    display: inline-block;
    width: 10px;
  }
`;

export const Name = styled.span<{
  $withEllipsis?: boolean;
  $isCommaSeparated?: boolean;
}>`
  ${fonts.pageViewMediumTemp};
  ${({ $withEllipsis }) =>
    $withEllipsis &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 300px;
    `}
  ${({ $isCommaSeparated }) => $isCommaSeparated && afterComaPseudoElement};
`;
