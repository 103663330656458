import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  addComponentsToSubdivisionAction,
  stagedComponentsFailedToFetch,
  stagedComponentsFetched,
  fetchStagedComponents,
  addComponentsToSubdivisionFailure,
} from './actions';
import {
  removeComponentsFromSubdivisionSuccess,
  saveSubdivisionDetailsSuccessfully,
} from 'streams/subdivisions/actions';
import {
  subdivisionEditorSubdivisionChanged$,
  subdivisionEditorViewModel$,
} from 'subdivisionEditor/subdivisionEditorViewModel$/subdivisionEditorViewModel$';
import {
  addOnlyInWorkspacesQuery,
  addOnlyRootComponentsQuery,
} from './routineOperators';
import { getCombinedRules } from 'search/AdvancedSearch/utils';
import { SearchContext } from '@ardoq/query-builder';
import { handleError, subdivisionApi } from '@ardoq/api';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';
import { SubdivisionViewModelState } from 'subdivisionEditor/types';
import { addComponentsToSubdivisionSuccess } from './actions';
import {
  ArdoqId,
  QueryBuilderQuery,
  QueryBuilderSubquery,
  Subdivision,
  SubdivisionsMembershipsMap,
} from '@ardoq/api-types';
import { workspaceBindingsSaved } from 'subdivisionEditor/Steps/BindWorkspaces/actions';
import { searchAdvancedSearch } from '../utils';
import {
  SUBDIVISIONS_STRINGS,
  subdivisionsOperations,
} from '@ardoq/subdivisions';
import { showToast } from '@ardoq/status-ui';
import { logError, logWarn } from '@ardoq/logging';
import { Team } from '@ardoq/profiling';
import { isArdoqError } from '@ardoq/common-helpers';

type SubdivisionQueryDataContext = {
  subdivisionId: ArdoqId;
  query: QueryBuilderSubquery;
  boundWorkspaceIds: ArdoqId[];
};

type EnhancedQueryDataWithSubdivisionRules = SubdivisionQueryDataContext & {
  query: QueryBuilderQuery;
};

const enhanceQueryDataWithSubdivisionRules = (
  state: SubdivisionQueryDataContext
): EnhancedQueryDataWithSubdivisionRules => {
  return {
    ...state,
    ...addOnlyInWorkspacesQuery(
      addOnlyRootComponentsQuery({
        query: getCombinedRules({
          queryBuilderRules: state.query,
          selectedFilterType: SearchContext.COMPONENT,
        }),
        searchParams: {},
      }),
      state.boundWorkspaceIds
    ),
  };
};

const subdivisionsStateToEnhancedQueryDataWithSubdivisionRules = (
  subdivisionEditorState: SubdivisionViewModelState
): SubdivisionQueryDataContext | null => {
  const subdivisionData = subdivisionEditorState.subdivision;
  const advancedSearchQueries =
    subdivisionsOperations.getSubdivisionSearchQuery(subdivisionData);
  if (
    !advancedSearchQueries ||
    !subdivisionEditorOperations.isAdvancedSearchMode(subdivisionEditorState)
  ) {
    return null;
  }

  return {
    subdivisionId: subdivisionEditorState.subdivisionId,
    query: advancedSearchQueries,
    boundWorkspaceIds: subdivisionEditorOperations.getSelectedWorkspacesToBind(
      subdivisionEditorState
    ),
  };
};

const searchAdvancedSearchRoutine = routine(
  ofType(fetchStagedComponents),
  extractPayload(),
  map(subdivisionsStateToEnhancedQueryDataWithSubdivisionRules),
  filter(Boolean),
  map(enhanceQueryDataWithSubdivisionRules),
  switchMap(subdivisionApi.componentsToBeAddedApi),
  handleError(() => dispatchAction(stagedComponentsFailedToFetch())),
  tap(response => {
    dispatchAction(stagedComponentsFetched(response));
  })
);

const handleChangedSubdivisionRoutine = routine(
  ofType(
    saveSubdivisionDetailsSuccessfully,
    addComponentsToSubdivisionSuccess,
    removeComponentsFromSubdivisionSuccess,
    workspaceBindingsSaved
  ),
  debounceTime(500),
  extractPayload(),
  withLatestFrom(subdivisionEditorViewModel$),
  map(([_, state]) => state),
  tap(state => {
    searchAdvancedSearch(state);
  })
);

const STRINGS = SUBDIVISIONS_STRINGS.STEPS.COMPONENTS_SELECTION.ROUTINES;

const handleAddSearchResultsToSubdivision = routine(
  ofType(addComponentsToSubdivisionAction),
  extractPayload(),
  map(state => {
    const subdivision = state.subdivision as Subdivision;
    if (!subdivisionEditorOperations.isAdvancedSearchMode(state)) {
      dispatchAction(addComponentsToSubdivisionSuccess());
      return;
    }
    if (!subdivision._id) {
      logWarn(
        Error(
          'Failed to bind workspaces to subdivision: Subdivision is missing _id'
        ),
        null,
        { tags: { team: Team.APPSEC } }
      );
      showToast(STRINGS.IMPOSSIBLE_TO_ADD);
      return;
    }

    const componentIds = state.componentsSelection.selectedComponentsToBeAdded;
    if (!componentIds.length) {
      dispatchAction(addComponentsToSubdivisionSuccess());
      return;
    }
    return {
      [subdivision._id]: componentIds,
    } as SubdivisionsMembershipsMap;
  }),
  filter((payload): payload is SubdivisionsMembershipsMap => !!payload),
  switchMap(subdivisionApi.addComponents),
  tap(response => {
    if (isArdoqError(response)) {
      logError(response, STRINGS.ADD_COMPONENTS_ERROR, {
        tags: { team: Team.APPSEC },
      });
      showToast(STRINGS.ADD_COMPONENTS_ERROR);
      dispatchAction(addComponentsToSubdivisionFailure());
      return;
    }
    dispatchAction(addComponentsToSubdivisionSuccess());
  })
);

export const componentsToBeAddedStreamRoutine =
  subdivisionEditorSubdivisionChanged$.pipe(
    tap(state => searchAdvancedSearch(state))
  );

export const componentsToAddRoutines = collectRoutines(
  handleChangedSubdivisionRoutine,
  handleAddSearchResultsToSubdivision,
  searchAdvancedSearchRoutine
);
