import {
  collectRoutines,
  routine,
  dispatchAction,
  extractPayload,
  ofType,
  carry,
} from '@ardoq/rxbeach';
import {
  addUsersToGroup,
  changeGroupName,
  deleteGroup,
  deleteGroupSuccess,
  fetchPermissionGroups,
  setError,
  setPermissionGroups,
} from './actions';
import { map, switchMap, tap } from 'rxjs/operators';
import { trackStreamEvent } from 'tracking/tracking';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import { createPermissionGroupSuccess } from '../components/CreatePermissionGroupModal/actions';
import { removeUsersFromGroupSuccess } from '../components/RemoveUsersModal/actions';
import { api, groupApi, handleError } from '@ardoq/api';
import { ArdoqError } from '@ardoq/common-helpers';
import { showToast, ToastType } from '@ardoq/status-ui';

const logAndSetError = (messageString: string) => (error: ArdoqError) => {
  const errorMessage = api.logErrorIfNeededAndReturnMessage(
    error,
    messageString
  );
  dispatchAction(setError(errorMessage));
};

const handleAddUsersToGroup = routine(
  ofType(addUsersToGroup),
  extractPayload(),
  trackStreamEvent(({ permissionGroupId, userIds }) => ({
    eventName: APPSEC_EVENTS.ADDED_USERS_TO_GROUP,
    metadata: { groupId: permissionGroupId, usersCount: userIds.length },
  })),
  map(({ permissionGroupId, userIds }) => ({
    groupId: permissionGroupId,
    userIds,
  })),
  carry(switchMap(groupApi.addUsers)),
  handleError(logAndSetError('Error adding users to group')),
  tap(([{ userIds }]) =>
    showToast(`${userIds.length}  members added.`, ToastType.SUCCESS)
  ),
  tap(() => dispatchAction(fetchPermissionGroups()))
);

const handleFetchPermissionGroups = routine(
  ofType(
    fetchPermissionGroups,
    createPermissionGroupSuccess,
    removeUsersFromGroupSuccess
  ),
  switchMap(groupApi.fetchAll),
  handleError(logAndSetError('Error getting groups')),
  tap(permissionGroups => dispatchAction(setPermissionGroups(permissionGroups)))
);

const handleDeleteGroup = routine(
  ofType(deleteGroup),
  extractPayload(),
  trackStreamEvent(groupId => ({
    eventName: APPSEC_EVENTS.DELETED_GROUP,
    metadata: { groupId },
  })),
  switchMap(groupApi.delete),
  handleError(logAndSetError('Error deleting permission group')),
  tap(groupId => dispatchAction(deleteGroupSuccess(groupId))),
  tap(() => dispatchAction(fetchPermissionGroups()))
);

const handleChangeGroupName = routine(
  ofType(changeGroupName),
  extractPayload(),
  trackStreamEvent(({ group }) => ({
    eventName: APPSEC_EVENTS.UPDATED_GROUP_NAME,
    metadata: {
      groupId: group._id,
    },
  })),
  map(({ group, newName }) => ({ ...group, name: newName, label: newName })),
  switchMap(groupApi.update),
  handleError(logAndSetError('Error changing group name')),
  tap(() => showToast('Group renamed.', ToastType.SUCCESS)),
  tap(() => dispatchAction(fetchPermissionGroups()))
);

export const permissionGroupRoutines = collectRoutines(
  handleAddUsersToGroup,
  handleDeleteGroup,
  handleChangeGroupName,
  handleFetchPermissionGroups
);
