import styled from 'styled-components';
import { DragTargetProps } from './PropTypes';

const DragTargetComponent = styled.div`
  position: fixed;
  padding: 8px 16px;
  background-color: hsl(210, 100%, 85%);
  border: 1px solid hsl(210, 100%, 50%);
  pointer-events: none;
  z-index: 10000;
`;

const DragTarget = ({
  shouldShowGhost,
  ghostStyle,
  ghostText,
  onTransitionEnd,
  targetId,
}: DragTargetProps) => {
  if (!shouldShowGhost) {
    return null;
  }
  return (
    <DragTargetComponent
      id="navigator-drag-target"
      data-id={targetId}
      style={ghostStyle}
      onTransitionEnd={onTransitionEnd}
    >
      {ghostText}
    </DragTargetComponent>
  );
};

export default DragTarget;
