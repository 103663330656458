import {
  APIEntityType,
  APIOrganizationUser,
  ArdoqId,
  PermissionGroup,
  SurveyApprovalUsersAndGroupsAudience,
} from '@ardoq/api-types';
import {
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  IconName,
} from '@ardoq/icons';
import { Select } from '@ardoq/select';

type UserOrGroupOptionBase = {
  value: ArdoqId;
  label: string;
  iconName: IconName;
  leftContent: JSX.Element;
};

type GroupOption = UserOrGroupOptionBase & {
  option: PermissionGroup;
};

type UserOption = UserOrGroupOptionBase & {
  option: APIOrganizationUser;
};

type UserOrGroupOption = GroupOption | UserOption;

const isUserOrGroupOption = (
  userOrGroup: UserOrGroupOption
): userOrGroup is UserOrGroupOption =>
  isGroupOption(userOrGroup) || Boolean('role' in userOrGroup.option);

const isGroupOption = (
  userOrGroup: UserOrGroupOption
): userOrGroup is GroupOption =>
  (userOrGroup.option as PermissionGroup).ardoq?.entityType ===
  APIEntityType.GROUP;

type UserAndGroupSelectorProps = {
  selectableGroups: PermissionGroup[];
  selectableUsers: APIOrganizationUser[];
  selectedAudience: SurveyApprovalUsersAndGroupsAudience;
  selectGroup: (groupId: ArdoqId) => void;
  unselectGroup: (groupId: ArdoqId) => void;
  selectUser: (userId: ArdoqId) => void;
  unselectUser: (userId: ArdoqId) => void;
};

const UserAndGroupSelector = ({
  selectableGroups,
  selectableUsers,
  selectedAudience,
  selectGroup,
  unselectGroup,
  selectUser,
  unselectUser,
}: UserAndGroupSelectorProps) => {
  const groupOptions = selectableGroups.map(group => ({
    value: group._id,
    label: group.name,
    iconName: IconName.PEOPLE,
    option: group,
    leftContent: selectedAudience.groupIds.includes(group._id) ? (
      <CheckboxCheckedIcon />
    ) : (
      <CheckboxUncheckedIcon />
    ),
  }));
  const userOptions = selectableUsers.map(user => ({
    value: user._id,
    label: user.name,
    iconName: IconName.PERSON,
    option: user,
    leftContent: selectedAudience.userIds.includes(user._id) ? (
      <CheckboxCheckedIcon />
    ) : (
      <CheckboxUncheckedIcon />
    ),
  }));
  return (
    <Select
      options={[
        {
          label: 'Groups',
          options: groupOptions,
        },
        {
          label: 'Users',
          options: userOptions,
        },
      ]}
      placeholder="Search and select a user or group"
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      // @ts-expect-error won't type correctly
      onChange={(userOrGroup: UserOrGroupOption) => {
        if (!userOrGroup) return;
        if (!isUserOrGroupOption(userOrGroup)) return;
        if (isGroupOption(userOrGroup)) {
          if (selectedAudience.groupIds.includes(userOrGroup.value)) {
            unselectGroup(userOrGroup.value);
          } else {
            selectGroup(userOrGroup.value);
          }
          return;
        }
        if (selectedAudience.userIds.includes(userOrGroup.value)) {
          unselectUser(userOrGroup.value);
        } else {
          selectUser(userOrGroup.value);
        }
      }}
    />
  );
};

export default UserAndGroupSelector;
