import { ArdoqId } from '@ardoq/api-types';
import { GetContextMenuOptionsArguments } from '@ardoq/context-menu';
import { getComponentMenu } from './componentMenu';
import { getReferenceMenu } from './referenceMenu';
import { getWorkspaceMenu } from './workspaceMenu';
import { getMixedEntitiesMenu } from './mixedEntitiesMenu';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { getNonComponentGroupMenu } from './groupContextMenu';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { ContextMenuTestIds } from './consts';

export type ContextTargetIds = {
  componentIds?: ArdoqId[];
  referenceIds?: ArdoqId[];
  workspaceIds?: ArdoqId[];
  eventTargetModelId?: ArdoqId;
  getNextTreeSelectionCandidate?: () => ArdoqId | null;
};

const getCommonMenuData = (
  ctxMenuArguments: GetContextMenuOptionsArguments,
  targetIds: ContextTargetIds
) => {
  const {
    componentIds = [],
    referenceIds = [],
    workspaceIds = [],
    eventTargetModelId,
    getNextTreeSelectionCandidate = () => null,
  } = targetIds;

  const hasReferences = referenceIds.length > 0;
  const hasComponents = componentIds.length > 0;
  const hasWorkspaces = workspaceIds.length > 0;

  if (
    ![hasReferences, hasComponents, hasWorkspaces, eventTargetModelId].some(
      Boolean
    )
  ) {
    return { options: null };
  }

  // a group which is not a component or a workspace (currently relevant for component type group).
  // if eventTargetModelId === id of the type group, filter options for component type will be shown
  if (
    [!hasReferences, !hasComponents, !hasWorkspaces].every(Boolean) &&
    eventTargetModelId
  ) {
    return {
      testId: ContextMenuTestIds.NON_COMPONENT_GROUP,
      options: getNonComponentGroupMenu({
        eventTargetModelId,
        clickedElement: ctxMenuArguments.target,
        isViewpointMode: ctxMenuArguments.isViewpointMode,
      }),
    };
  }

  // Only components
  if (hasComponents && !hasReferences && !hasWorkspaces) {
    return {
      testId: ContextMenuTestIds.COMPONENT,
      options: getComponentMenu({
        ...ctxMenuArguments,
        componentIds,
        eventTargetModelId,
        getNextTreeSelectionCandidate,
      }),
      componentIds,
    };
  }

  // Only references
  if (hasReferences && !hasComponents && !hasWorkspaces) {
    return {
      testId: ContextMenuTestIds.REFERENCE,
      options: getReferenceMenu({
        ...ctxMenuArguments,
        referenceIds,
      }),
      referenceIds,
    };
  }

  // Only workspaces
  if (hasWorkspaces && !hasComponents && !hasReferences) {
    return {
      testId: ContextMenuTestIds.WORKSPACE,
      options: getWorkspaceMenu({
        ...ctxMenuArguments,
        workspaceIds,
        scenarioId: getActiveScenarioId(),
      }),
      workspaceIds,
    };
  }

  // Mixed entities
  if (hasComponents && hasReferences) {
    const isScenarioContext = componentIds.some(
      componentInterface.isScenarioContextComponent
    );

    if (isScenarioContext) {
      return {
        options: null,
      };
    }

    return {
      testId: ContextMenuTestIds.COMPONENT_AND_REFERENCE,
      options: getMixedEntitiesMenu({
        ...ctxMenuArguments,
        componentIds,
        referenceIds,
      }),
      componentIds,
      referenceIds,
    };
  }

  return { options: null };
};

export default getCommonMenuData;
