import { GhostButton } from '@ardoq/button';
import { Step } from '@ardoq/steppers';
import { ComponentSelection } from './ComponentSelection/ComponentSelection';
import { MapFields } from './MapFields/MapFields';
import { ReviewExport } from './ReviewExport/ReviewExport';
import { connect } from '@ardoq/rxbeach';

import { viewModel$ } from './viewModel$';
import { StepStatuses } from './types';
import { Stepper } from 'integrations/common/components/stepper';
import { Features, hasFeature } from '@ardoq/features';
import {
  NewExportRoutes,
  OverviewRoute,
} from 'integrations/common/navigation/types';
// Todo: Move Success and Failed Pages to commn directory
import { Failed } from 'integrations/ITPedia/NewSync/SummarySync/Failed';
import { ExportAndSchedule } from './ExportAndSchedule/ExportAndSchedule';
import { PageHeader, PageWrapper, PageTopbar } from '@ardoq/page-layout';
type NewSyncProps = {
  route: NewExportRoutes | OverviewRoute;
  stepStatuses: StepStatuses;
  onResetClick: () => void;
  navigateToDataSelection: () => void;
  navigateToFieldMapping: () => void;
  navigateToReviewExport: () => void;
  navigateToExportAndSchedule: () => void;
};

function NewExportComponent({
  route,
  stepStatuses,
  onResetClick,
  navigateToDataSelection,
  navigateToFieldMapping,
  navigateToReviewExport,
  navigateToExportAndSchedule,
}: NewSyncProps) {
  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);

  return (
    <>
      <PageWrapper>
        {hasNewJourneyFeature ? (
          <PageTopbar
            primaryContent="SAP Signavio exporter"
            secondaryContent="Import and integrations"
            secondaryButton={
              <GhostButton onClick={onResetClick}>Restart</GhostButton>
            }
          />
        ) : (
          <PageHeader
            title="SAP Signavio exporter"
            rightContent={
              <GhostButton onClick={onResetClick}>Restart</GhostButton>
            }
          />
        )}
        <Stepper>
          <Step
            onPress={navigateToDataSelection}
            isSelected={stepStatuses.dataSelection.selected}
            state={stepStatuses.dataSelection.state}
            heading="Select components"
          />
          <Step
            isDisabled={!stepStatuses.fieldMapping.enabled}
            onPress={navigateToFieldMapping}
            isSelected={stepStatuses.fieldMapping.selected}
            state={stepStatuses.fieldMapping.state}
            heading="Map fields"
          />
          <Step
            isDisabled={!stepStatuses.reviewExport.enabled}
            onPress={navigateToReviewExport}
            isSelected={stepStatuses.reviewExport.selected}
            state={stepStatuses.reviewExport.state}
            heading="Review export"
          />
          <Step
            isDisabled={!stepStatuses.exportAndSchedule.enabled}
            onPress={navigateToExportAndSchedule}
            isSelected={stepStatuses.exportAndSchedule.selected}
            state={stepStatuses.exportAndSchedule.state}
            heading="Export and schedule"
          />
        </Stepper>
        {route === NewExportRoutes.DATA_SELECTION && <ComponentSelection />}
        {route === NewExportRoutes.FIELD_MAPPING && <MapFields />}
        {route === NewExportRoutes.REVIEW_EXPORT && <ReviewExport />}
        {route === NewExportRoutes.EXPORT_AND_SCHEDULE && <ExportAndSchedule />}
        {route === NewExportRoutes.FAILED && <Failed />}
      </PageWrapper>
    </>
  );
}

export const NewExport = connect(NewExportComponent, viewModel$);
