import { showDrawer } from '@ardoq/snowflakes';
import { connect } from '@ardoq/rxbeach';
import { workspaceDrawerViewModel$ } from './workspaceDrawer$';
import { WorkspaceDrawer } from '@ardoq/report-builder';

const ConnectedWorkspaceDrawer = connect(
  WorkspaceDrawer,
  workspaceDrawerViewModel$
);

export const showWorkspaceDrawer = () =>
  showDrawer({
    stackPageName: 'reportBuilderWorkspaceSelector',
    renderDrawer: ({ handleCloserDrawer }) => (
      <ConnectedWorkspaceDrawer handleCloserDrawer={handleCloserDrawer} />
    ),
  });
