import { Icon, IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';

export const SuggestionsLoading = () => {
  return (
    <FlexBox align="center" gap="xsmall">
      <Icon iconName={IconName.SPINNER} />
      Loading suggestions...
    </FlexBox>
  );
};
