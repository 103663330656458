export enum BestPracticeAssistantTrackingEvents {
  INITIALIZED_NEW_CHAT = 'initialized best practice assistant chat window',
  QUESTION_SUBMITTED = 'submitted question to best practice assistant',
  GOT_SUCCESSFUL_RESPONSE = 'got successful response from best practice assistant',
  MINIMIZED_CHAT_WINDOW = 'minimized best practice assistant chat window',
  CLOSED_CHAT_WINDOW = 'closed best practice assistant chat window',
  MAXIMIZED_CHAT_WINDOW = 'maximized best practice assistant chat window',
  SUBMITTED_POSITIVE_FEEDBACK = 'submitted positive feedback to best practice assistant',
  SUBMITTED_NEGATIVE_FEEDBACK = 'submitted negative feedback to best practice assistant',
  PASSED_TO_INTERCOM = 'passed conversation to intercom',
  CHAT_TIMED_OUT = 'best practice assistant0 chat timed out',
}
