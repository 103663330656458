import {
  APIOrganizationUser,
  APIRoleAttributes,
  Privilege,
  PrivilegeLabel,
} from '@ardoq/api-types';
import { PrivilegesByUser } from '@ardoq/api-types';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { IconName } from '@ardoq/icons';
import { getCurrentLocale, localeIncludesLowercase } from '@ardoq/locale';
import { userHasPrivilege } from 'privileges/utils';
import { trackEvent } from 'tracking/tracking';
import { TrackOrgMembersViewUserEvents } from './types';

const satisfiesRoleFilters = (
  user: APIOrganizationUser,
  roleFilters: string[]
) => {
  return roleFilters.length === 0 || roleFilters.includes(user.role);
};

const satisfiesPrivilegeFilters = (
  user: APIOrganizationUser,
  privilegesByUser: PrivilegesByUser,
  privilegeFilters: PrivilegeLabel[]
) => {
  return privilegeFilters.every(label =>
    userHasPrivilege(privilegesByUser, user._id, label)
  );
};

export const satisfiesSearchFilter = (
  user: APIOrganizationUser,
  searchPhrase: string
) => {
  const locale = getCurrentLocale();
  const userString = `${user.name} ${user.email} ${user.role}`;

  return localeIncludesLowercase(userString, searchPhrase, locale);
};

export const getFilteredUsers = (
  users: APIOrganizationUser[],
  privilegesByUser: PrivilegesByUser,
  privilegeFilters: PrivilegeLabel[],
  roleFilters: string[],
  searchPhrase: string
) => {
  return users.filter(user => {
    return (
      satisfiesRoleFilters(user, roleFilters) &&
      satisfiesPrivilegeFilters(user, privilegesByUser, privilegeFilters) &&
      satisfiesSearchFilter(user, searchPhrase)
    );
  });
};

const withToggledFilter = <Filter extends string>(
  filters: Filter[],
  filterToToggle: Filter
) => {
  return filters.includes(filterToToggle)
    ? filters.filter(filter => filter !== filterToToggle)
    : [...filters, filterToToggle];
};

export const getPrivilegeFilterOptions = (
  configurablePrivileges: Privilege[],
  privilegeFilters: PrivilegeLabel[],
  onPrivilegeFilterChange: (filters: PrivilegeLabel[]) => void
) => {
  if (configurablePrivileges.length === 0) return [];
  return [
    { label: 'Privileges', type: DropdownOptionType.HEADER },
    ...configurablePrivileges.map(({ label, description }) => ({
      label: description,
      onClick: () => {
        onPrivilegeFilterChange(withToggledFilter(privilegeFilters, label));
      },
      iconName: privilegeFilters.includes(label)
        ? IconName.CHECKBOX_CHECKED
        : IconName.CHECKBOX_UNCHECKED,
    })),
  ];
};

export const getRoleFilterOptions = (
  roles: APIRoleAttributes[],
  roleFilters: string[],
  onRoleFilterChange: (filters: string[]) => void
) => {
  if (roles.length === 0) return [];
  return [
    { label: 'Roles', type: DropdownOptionType.HEADER },
    ...roles.map(({ label, title }) => ({
      label: title,
      onClick: () => {
        onRoleFilterChange(withToggledFilter(roleFilters, label));
      },
      iconName: roleFilters.includes(label)
        ? IconName.CHECKBOX_CHECKED
        : IconName.CHECKBOX_UNCHECKED,
    })),
  ];
};

const toSelectableDataSourceRow = (user: APIOrganizationUser) => {
  return { ...user, id: user._id };
};

export const toSelectableDataSource = (users: APIOrganizationUser[]) => {
  return users.map(toSelectableDataSourceRow);
};

export const trackOrgMembersView = <T extends object>(
  event: TrackOrgMembersViewUserEvents,
  meta?: T
) => trackEvent(`Manage organization members0 ${event}`, meta);
