import { colors } from '@ardoq/design-tokens';
import styled, { css } from 'styled-components';

import {
  DockedGridEditorFocus,
  DockedGridEditorState,
  DockedGridEditorVisibility,
} from './types';

/**
 * To manage whether the iframe lies above or below the rest of the UI
 */
const DOCKED_EDITOR_FOCUS_Z_INDEX: ZIndexMap = {
  [DockedGridEditorFocus.BACKGROUND]: 0,
  [DockedGridEditorFocus.FOREGROUND]: 2010,
};
type ZIndexMap = Record<DockedGridEditorState['dockedGridEditorFocus'], number>;

/**
 * The iframe itself is always fullscreen, but the content within will change
 * size to fit within a "window" in the UI. These styles control minor
 * visibility rules based on the docked Grid Editor's visibility.
 */
const visibilityCSS: Record<DockedGridEditorVisibility, Styles | null> = {
  [DockedGridEditorVisibility.EXPANDED]: null,
  [DockedGridEditorVisibility.CLOSED]: css`
    display: none;
  `,
  [DockedGridEditorVisibility.FULLSCREEN]: css`
    background-color: ${colors.grey95};
  `,
};
type Styles = ReturnType<typeof css>;

/**
 * We prefix props with $ to utilize Styled Component's "Transient props",
 * meaning they are not forwarded to the underlying <iframe> component.
 */
export interface DockedGridEditorIFrameProps {
  id: string;
  'data-testid': string;
  src: string;
  loading?: React.IframeHTMLAttributes<HTMLIFrameElement>['loading'];
  $dockedGridEditorFocus: DockedGridEditorState['dockedGridEditorFocus'];
  $dockedGridEditorVisibility: DockedGridEditorState['dockedGridEditorVisibility'];
  title: string; // for accessibility
}

const DockedGridEditorIFrame = styled.iframe<DockedGridEditorIFrameProps>`
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  border: none;
  z-index: ${props =>
    DOCKED_EDITOR_FOCUS_Z_INDEX[props.$dockedGridEditorFocus]};
  ${({ $dockedGridEditorVisibility }) =>
    visibilityCSS[$dockedGridEditorVisibility]}
`;

export default DockedGridEditorIFrame;
