import { selectionState$ } from 'integrations/azure/streams/selectionState/selectionState$';
import { dispatchAction } from '@ardoq/rxbeach';
import { combineLatest, map } from 'rxjs';
import { toggleResourceGroup } from '../../streams/selectionState/actions';
import { azureSubscriptions$ } from 'integrations/azure/streams/subscriptions/subscriptions$';
import { TablesSelectionBaseTable } from '@ardoq/integrations';
import { resourceGroups$ } from 'integrations/azure/streams/resourceGroups/resourceGroups$';
import { createDataSource } from './utils';
import { ResourceGroupsState } from 'integrations/azure/streams/resourceGroups/types';
import { SelectionState } from 'integrations/azure/streams/selectionState/types';
import { SubscriptionsState } from 'integrations/azure/streams/subscriptions/types';

function toViewModel([
  resourceGroupsState,
  selectionState,
  azureSubscriptions,
]: [ResourceGroupsState, SelectionState, SubscriptionsState]) {
  const datasource = createDataSource(
    resourceGroupsState.resourceGroups,
    azureSubscriptions.subscriptions,
    selectionState.subscriptionIds
  );

  const onToggleResourceGroup = (selectedTable: TablesSelectionBaseTable) => {
    const resourceGroupName = selectedTable.id;

    const resourceGroupId = resourceGroupsState.resourceGroups.find(
      ({ name }) => name === resourceGroupName
    )?.id;

    if (resourceGroupId) {
      dispatchAction(
        toggleResourceGroup({ resourceGroupName, resourceGroupId })
      );
    }
  };

  const selectedTables = resourceGroupsState.selectedResourceGroupNames.reduce(
    (acc: { [key: string]: { fields: Record<string, true> } }, id) => {
      acc[id] = { fields: { '*': true } };
      return acc;
    },
    {}
  );

  return {
    datasource,
    selectedTables,
    tablesStatus: resourceGroupsState.asyncStatus,
    onToggleResourceGroup,
  };
}

export const viewModel$ = combineLatest([
  resourceGroups$,
  selectionState$,
  azureSubscriptions$,
]).pipe(map(toViewModel));
