import { useEffect, useState } from 'react';
import styled from 'styled-components';

const CELL_COLORS = ['#FFF', '#FFF', '#FFF', '#541161', '#8114B0', '#9F46CE'];
const CELL_COLOR_TRANSITION_INTERVAL = 300;

const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * CELL_COLORS.length);
  return CELL_COLORS[randomIndex];
};

const getRandomOpacity = () => Math.random().toFixed(2);

const Cell = ({ isActive }: { isActive: boolean }) => {
  const [backgroundColor, setBackgroundColor] = useState(getRandomColor());
  const [opacity, setOpacity] = useState(getRandomOpacity());

  useEffect(() => {
    if (!isActive) return;

    const interval = setInterval(() => {
      setBackgroundColor(getRandomColor());
      setOpacity(getRandomOpacity());
    }, CELL_COLOR_TRANSITION_INTERVAL);

    return () => clearInterval(interval);
  }, [isActive]);

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        opacity: opacity,
        width: '100%',
        height: '100%',
        transition: `all ${
          CELL_COLOR_TRANSITION_INTERVAL * 0.001
        }s ease-in-out`,
      }}
    />
  );
};

const Wrapper = styled.div<{
  $columns: number;
  $rows: number;
  $cellSize: number;
}>`
  display: grid;
  grid-template-columns: ${({ $columns }) => `repeat(${$columns}, 1fr)`};
  grid-template-rows: ${({ $rows }) => `repeat(${$rows}, 1fr)`};
  width: ${({ $cellSize, $columns }) => $cellSize * $columns}px;
  height: ${({ $cellSize, $rows }) => $cellSize * $rows}px;
`;

type LoadingAnimationProps = {
  isActive: boolean;
  rows: number;
  columns: number;
  cellSize: number;
};

export const LoadingAnimation = ({
  isActive,
  rows,
  columns,
  cellSize,
}: LoadingAnimationProps) => {
  return (
    <Wrapper $columns={columns} $rows={rows} $cellSize={cellSize}>
      {Array.from(Array(columns * rows).keys()).map((_, index) => (
        <Cell key={index} isActive={isActive} />
      ))}
    </Wrapper>
  );
};
