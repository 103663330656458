import { DiffType } from '@ardoq/data-model';
import { NavigatorViewInterface } from './types';

export const getNavigatorStubViewInterface = (): NavigatorViewInterface => ({
  isComponentInFlexibleModel: () => false,
  isFlexibleWorkspace: () => false,
  canComponentHaveChildren: () => false,
  getComponentLock: () => false,
  getComponentTypeId: () => null,
  getCSSFilterColor: () => null,
  getComponentRepresentationData: () => null,
  getSortedComponentChildrenIds: () => [],
  isIncludedInContextByFilter: () => false,
  hasComponentWriteAccess: () => false,
  isScenarioContextComponent: () => false,
  getVisualDiffType: () => DiffType.NONE,
  getUnsortedComponentChildrenIds: () => [],
  isWorkspaceEmpty: () => true,
  getWorkspaceRootComponents: () => [],
  isWorkspace: () => false,
  hasWorkspaceWriteAccess: () => false,
  getRootWorkspaceIdOfComponent: () => null,
  getComponentName: () => '',
  getComponentOrder: () => -1,
  getWorkspaceName: () => '',
  getScenarioName: () => '',
  getComponentCssClassNames: () => null,
  getComponentDisplayColorAsSVGAttributes: () => ({ fill: '', stroke: '' }),
  isComponentExternallyManaged: () => false,
  isComponentExternallyMissing: () => false,
  currentUserIsOrgAdmin: () => false,
  isWorkspaceExternallyManaged: () => false,
  getComponentSubdivisionMembership: () => [],
  getSubdivisionById: () => null,
  getLinkedSourceComponents: () => [],
  getLinkedTargetComponents: () => [],
  hasFeature: () => false,
  getWorkspaceBoundSubdivisionsIds: () => [],
  isComponent: () => false,
  removeZonesFromComponent: () => null,
  copyZones: () => null,
  getComponentData: () => null,
  copyComponents: async () => {},
});
