import { createGlobalStyle } from 'styled-components';
import { GraphVisualizationSvgDefs } from '@ardoq/graph';
import ModelCssManagerGlobalStyle from 'utils/modelCssManager/ModelCssManagerGlobalStyle';
import HideBodyDivsGlobalStyle from './HideBodyDivsGlobalStyle';
import { visualDiffBackgroundCircle } from 'scope/BackgroundImageResources';

const BackgroundImageResourcesGlobalStyle = createGlobalStyle`
  .visual-diff-background-circle {
    ${visualDiffBackgroundCircle}
  }
`;

export const AppContainerGlobalStyles = () => (
  <>
    <BackgroundImageResourcesGlobalStyle />
    <ModelCssManagerGlobalStyle />
    <HideBodyDivsGlobalStyle />
    <GraphVisualizationSvgDefs />
  </>
);
