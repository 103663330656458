import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  SelectionRequestPayload,
  SelectionResponse,
} from '@ardoq/api-types/integrations';
import { AsyncStatus } from 'integrations/common/types/api';
import { SelectionState } from './types';
import {
  setSelectionAsyncStatus,
  fetchSelectionSuccess,
  setFetchErrorMessage,
  setSelectionRequest,
} from './actions';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';

const initialState: SelectionState = {
  asyncStatus: 'INIT',
  selectionRequest: null,
  selectionResponse: null,
  fetchErrorMessage: null,
};

const setSelectionAsyncStatusReducer = (
  state: SelectionState,
  asyncStatus: AsyncStatus
) => ({
  ...state,
  asyncStatus,
});

const handleSetSelectionAsyncStatus = reducer<SelectionState, AsyncStatus>(
  setSelectionAsyncStatus,
  setSelectionAsyncStatusReducer
);

const setFetchErrorMessageReducer = (
  state: SelectionState,
  fetchErrorMessage: string | null
) => ({
  ...state,
  fetchErrorMessage,
});

const handleSetFetchErrorMessage = reducer<SelectionState, string | null>(
  setFetchErrorMessage,
  setFetchErrorMessageReducer
);

const fetchSelectionSuccessReducer = (
  state: SelectionState,
  selectionResponse: SelectionResponse
): SelectionState => {
  return {
    ...state,
    selectionResponse: selectionResponse,
    asyncStatus: 'SUCCESS',
  };
};

const handleFetchSelectionSuccess = reducer<SelectionState, SelectionResponse>(
  fetchSelectionSuccess,
  fetchSelectionSuccessReducer
);

const setSelectionRequestReducer = (
  state: SelectionState,
  selectionRequest: SelectionRequestPayload
): SelectionState => {
  return {
    ...state,
    selectionRequest,
  };
};

const handleSetSelectionRequest = reducer<
  SelectionState,
  SelectionRequestPayload
>(setSelectionRequest, setSelectionRequestReducer);

const resetIntegrationReducer = () => initialState;

const handleResetIntegration = reducer<SelectionState>(
  resetIntegration,
  resetIntegrationReducer
);

const reducers = [
  handleSetSelectionAsyncStatus,
  handleSetFetchErrorMessage,
  handleFetchSelectionSuccess,
  handleSetSelectionRequest,
  handleResetIntegration,
];

export const selectionState$ = persistentReducedStream(
  'awsSelection$',
  initialState,
  reducers
);
