import CustomFieldsInSurveyOverview from 'surveyAdmin/CustomFieldsInSurveyOverview';
import FilterSetup from '../FilterSetup';
import { SurveyValidation } from 'surveyAdmin/types';
import { HeaderField, SurveyFilterSetupAttributes } from '@ardoq/api-types';
import { SurveyEditorSectionProps } from './SurveyEditor';
import { SurveyEditorState } from './streams/types';

type SurveyEditorResultFilteringSectionProps = Omit<
  SurveyEditorSectionProps,
  'setSurveyAttribute'
> & {
  surveyValidation: SurveyValidation | undefined;
  selectedTypeId?: string;
  selectedModelId: string;
  setFilterSetupAttributes: (
    updatedFilterSetup: SurveyFilterSetupAttributes,
    shouldShowReferenceResultFilteringFieldErrors: boolean,
    fieldsHaveBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith']
  ) => void;
  updateCustomHeaderFields: (headerFields: HeaderField[]) => void;
};

const SurveyEditorResultFilteringSection = ({
  surveyAttributes,
  surveyValidation,
  selectedTypeId,
  selectedModelId,
  setFilterSetupAttributes,
  updateCustomHeaderFields,
}: SurveyEditorResultFilteringSectionProps) => {
  return (
    <>
      <CustomFieldsInSurveyOverview
        typeId={selectedTypeId}
        modelId={selectedModelId}
        customHeaderFields={surveyAttributes.customHeaderFields}
        updateCustomHeaderFields={updateCustomHeaderFields}
      />
      <FilterSetup
        surveyWorkspaceId={surveyAttributes.workspace}
        surveyFilterSetup={surveyAttributes.filterSetup}
        surveyValidation={surveyValidation}
        setFilterSetupAttributes={setFilterSetupAttributes}
      />
    </>
  );
};

export default SurveyEditorResultFilteringSection;
