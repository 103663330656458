import {
  APIComponentType,
  APIModelAttributes,
  ModelUsagePageType,
} from '@ardoq/api-types';

export const modelUsageToDict = (usage: ModelUsagePageType[]) => {
  return Object.fromEntries(
    usage.map(({ id, ['used-by-components']: usedByComponents }) => [
      id,
      usedByComponents,
    ])
  );
};

const doesModelComeFromBundle = (model: APIModelAttributes) => {
  return model['ardoq-persistent']?.['use-case'] !== undefined;
};

const getTypesFromHierarchy = (
  typeHierarchy: Record<string, APIComponentType>
): APIComponentType[] => {
  return Object.values(typeHierarchy).flatMap(type => {
    return [type, ...getTypesFromHierarchy(type.children)];
  });
};

/**
 * Returns an object where the ids of all the types in the provided type
 * hierarchy are keys and the type id of the type's parent type is the value. If
 * the type does not have a parent, the value is `null`.
 */
export const getParentTypeIdsByName = (
  typeHierarchy: Record<string, APIComponentType>
) => {
  const types = getTypesFromHierarchy(typeHierarchy);
  const initialParentTypeIdsByName: Record<string, string | null> =
    Object.fromEntries(types.map(type => [type.name, null]));

  const parentTypeIdsByName = types.reduce((parentTypeIdsByTypeName, type) => {
    const children = Object.values(type.children);
    return {
      ...parentTypeIdsByTypeName,
      ...Object.fromEntries(children.map(child => [child.name, type.id])),
    };
  }, initialParentTypeIdsByName);

  return parentTypeIdsByName;
};

/**
 * We consider a type to have been added (as opposed to just having changed
 * position in the hierarchy) if the new model contains a type whose id and name
 * are not present in the old model.
 */
const hasAddedType = (
  currentModel: APIModelAttributes,
  newModel: APIModelAttributes
) => {
  const typesInCurrentModel = getTypesFromHierarchy(currentModel.root);
  const typesInNewModel = getTypesFromHierarchy(newModel.root);
  const typeIdsInCurrentModel = new Set(
    typesInCurrentModel.map(({ id }) => id)
  );
  const typeNamesInCurrentModel = new Set(
    typesInCurrentModel.map(({ name }) => name)
  );
  return typesInNewModel.some(({ id, name }) => {
    return !typeIdsInCurrentModel.has(id) && !typeNamesInCurrentModel.has(name);
  });
};

/**
 * We consider a type to have been removed (as opposed to just having changed
 * position in the hierarchy) if the old model contains a type whose id and name
 * are not present in the old model.
 */
const hasRemovedType = (
  currentModel: APIModelAttributes,
  newModel: APIModelAttributes
) => {
  return hasAddedType(newModel, currentModel);
};

/**
 * We consider a type to have been renamed if the old model contains a type
 * that is also present in the new model with a different name, but only if the
 * old name is not present on another type. In that case we rather consider the
 * type as having been moved.
 */
const hasRenamedType = (
  currentModel: APIModelAttributes,
  newModel: APIModelAttributes
) => {
  const typesInCurrentModel = getTypesFromHierarchy(currentModel.root);
  const typesInNewModel = getTypesFromHierarchy(newModel.root);
  const newTypesById = Object.fromEntries(
    typesInNewModel.map(type => [type.id, type])
  );
  const typeNamesInNewModel = new Set(typesInNewModel.map(({ name }) => name));
  return typesInCurrentModel.some(({ name, id }) => {
    if (!(id in newTypesById)) return false;
    if (typeNamesInNewModel.has(name)) return false;
    const newName = newTypesById[id].name;
    return newName !== name;
  });
};

/**
 * We consider a type to have changed position in the hierarchy if the new model
 * contains a type with the same name as a type in the old model at a different
 * place in the hierarchy (its parent type has a different id).
 */
const hasChangedPositionInHierarchy = (
  currentModel: APIModelAttributes,
  newModel: APIModelAttributes
) => {
  const oldParentTypeIdsByName = getParentTypeIdsByName(currentModel.root);
  const newParentTypeIdsByName = getParentTypeIdsByName(newModel.root);
  return Object.entries(oldParentTypeIdsByName).some(
    ([name, oldParentTypeId]) => {
      if (!(name in newParentTypeIdsByName)) return false;
      const newParentTypeId = newParentTypeIdsByName[name];
      return oldParentTypeId !== newParentTypeId;
    }
  );
};

export const getWorkspaceModelChangeMetadata = (
  currentModel: APIModelAttributes,
  newModel: APIModelAttributes
) => {
  return {
    addedType: hasAddedType(currentModel, newModel),
    removedType: hasRemovedType(currentModel, newModel),
    renamedType: hasRenamedType(currentModel, newModel),
    changedPositionInHierarchy: hasChangedPositionInHierarchy(
      currentModel,
      newModel
    ),
    modelComesFromBundle: doesModelComeFromBundle(currentModel),
  };
};
