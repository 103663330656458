import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { connect } from '@ardoq/rxbeach';
import { filter, switchMap } from 'rxjs';
import { Import } from './import/import';
import { isUnifiedIntegrationId } from './utils';
import { Overview } from 'integrations/common/containers/overview/Overview';
import { OverviewRoute, RoutePath } from 'integrations/common/navigation/types';
import {
  isImportRoute,
  isOverviewRoute,
} from 'integrations/common/navigation/utils';

const UnifiedIntegrationComponent = ({
  integrationPath,
}: ActiveIntegrationState) => {
  const path = integrationPath as RoutePath;
  if (!path || isOverviewRoute(path)) {
    return (
      <Overview integrationPath={path} initialTab={OverviewRoute.CONNECTIONS} />
    );
  }

  if (isImportRoute(path)) {
    return <Import />;
  }

  return null;
};

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId => getActiveIntegrationStream(integrationId))
);

export const UnifiedIntegration = connect(
  UnifiedIntegrationComponent,
  viewModel$
);
