import { APITagAttributes, ArdoqId, APIEntityType } from '@ardoq/api-types';
import { getAttributesOfModel } from 'modelInterface/genericInterfaces';
import {
  DeleteTag,
  GetAttributes,
  GetDescription,
  GetIdsOfEntitiesWithTag,
  GetName,
  GetTagCount,
  GetTagData,
  GetTagsByWorkspaceId,
  GetTagsForModel,
  GetVersion,
  RemoveTag,
  SetAttributes,
} from '@ardoq/tag-interface';
import Workspaces from 'collections/workspaces';
import Tags from 'collections/tags';
import { pick } from 'lodash';
import { getCollectionForEntityType } from 'collectionInterface/utils';
import { getWorkspaceEntities } from 'collectionInterface/genericInterfaces';

const getAttributes: GetAttributes = getAttributesOfModel<
  APITagAttributes & { id?: ArdoqId }
>(Tags.collection, new Map([['id', '_id']]));

const setAttributes: SetAttributes = attributes => {
  Tags.collection.get(attributes._id)?.set(attributes);
};

const getIdsOfComponentsWithTag: GetIdsOfEntitiesWithTag = tagId => {
  const tag = Tags.collection.get(tagId);
  return tag ? tag.getIdsOfComponentsWithTag() : [];
};

const getIdsOfReferencesWithTag: GetIdsOfEntitiesWithTag = tagId =>
  Tags.collection.get(tagId)?.getIdsOfReferencesWithTag() ?? [];

const getTagsForModel: GetTagsForModel = modelId => {
  const tags = Tags.collection.getTagsByModel(modelId);
  return tags
    ? tags.map(tag => ({
        id: tag.id,
        name: tag.name(),
      }))
    : [];
};

const getDescription: GetDescription = tagId => {
  const tag = Tags.collection.get(tagId);
  return tag ? tag.getDescription() : null;
};

const getName: GetName = tagId => Tags.collection.get(tagId)?.name() ?? null;

const deleteTag: DeleteTag = tagId => {
  Tags.collection.get(tagId)?.destroy();
};

const getTagData: GetTagData = tagId =>
  Tags.collection.get(tagId)?.toJSON() ?? null;

const getVersion: GetVersion = tagId =>
  Tags.collection.get(tagId)?.get('_version') ?? null;

const getTagsByWorkspaceId: GetTagsByWorkspaceId = workspaceId => {
  const workspace = Workspaces.collection.get(workspaceId);
  const tags = Tags.collection.getWorkspaceTags(workspace);
  return tags.map(tag => tag.toJSON());
};

const getTagCount: GetTagCount = () => {
  return Tags.collection.length;
};

const getAllPartial = (keys: string[]) =>
  Tags.collection.map(({ attributes }) => pick(attributes, keys));

const getWorkspaceTags = (id: ArdoqId) =>
  getWorkspaceEntities(APIEntityType.TAG, id);

const addTag = (
  tagName: string,
  entityType: APIEntityType,
  entityID: ArdoqId,
  rootWorkspaceId?: ArdoqId
) => {
  const entity = getCollectionForEntityType(entityType).get(entityID);
  const workspace = Workspaces.collection.get(rootWorkspaceId!);
  return Tags.collection.addTag(tagName, entity, workspace);
};

const removeTag: RemoveTag = (tagName, entityType, entityID) => {
  const entity = getCollectionForEntityType(entityType).get(entityID);
  if (!entity) {
    return;
  }
  return Tags.collection.getByName(tagName)?.remove(entity);
};

/**
 * Remove tags belonging to a workspace from collection
 */
const removeWorkspaceTags = (workspaceId: ArdoqId) => {
  getWorkspaceTags(workspaceId).forEach(tag => {
    Tags.collection.remove(tag, { silent: true });
  });
  Tags.collection.trigger('update');
};

export const tagInterface = {
  getWorkspaceTags,
  addTag,
  removeTag,
  removeWorkspaceTags,
  getAttributes,
  setAttributes,
  getIdsOfComponentsWithTag,
  getIdsOfReferencesWithTag,
  getTagsForModel,
  getDescription,
  getName,
  deleteTag,
  getTagData,
  getVersion,
  getTagsByWorkspaceId,
  getTagCount,
  getAllPartial,
};
