import {
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { setIsProteanEditModeEnabled } from '../actions';
import { trackStreamEvent } from 'tracking/tracking';
import { tap } from 'rxjs/operators';
import { ToastType, showToast } from '@ardoq/status-ui';

const handleSetIsEditModeEnabled = routine(
  ofType(setIsProteanEditModeEnabled),
  extractPayload(),
  trackStreamEvent(({ isEditModeEnabled }) => ({
    eventName: `layout edit mode ${isEditModeEnabled ? 'enabled' : 'disabled'}`,
  })),
  tap(({ isEditModeEnabled }) => {
    if (isEditModeEnabled) {
      showToast('Edit mode opened', ToastType.INFO);
    }
  })
);

export const proteanEditModeRoutines = collectRoutines(
  handleSetIsEditModeEnabled
);
