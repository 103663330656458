import ReportReaderContainer from './ReportReader/ReportReaderContainer';
import { connect } from '@ardoq/rxbeach';
import { ReportModule } from './types';
import reportNavigation$ from './navigation/reportNavigation$';
import ReportOverview from './ReportOverview';
import ReportBuilderContainer from './ReportBuilder/ReportBuilderContainer';
import { ShareModalLoadingOverlay } from './atoms';

interface ReportsContainerProps {
  reportModule: ReportModule;
  isShareDialogLoading: boolean;
}

const ReportsContainer = ({
  reportModule,
  isShareDialogLoading,
}: ReportsContainerProps) => {
  return (
    <>
      {reportModule === ReportModule.BUILDER ? (
        <ReportBuilderContainer />
      ) : reportModule === ReportModule.READER ? (
        <ReportReaderContainer />
      ) : (
        <ReportOverview />
      )}
      {isShareDialogLoading && <ShareModalLoadingOverlay />}
    </>
  );
};

export default connect(ReportsContainer, reportNavigation$);
