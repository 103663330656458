import {
  getRenameScenarioMenuItem,
  getScenarioPermissionsMenuItem,
} from './scenatioMenuItems';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { DropdownItem } from '@ardoq/dropdown-menu';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { CurrentContext } from 'modelInterface/types';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';
import { copyMenuOption } from 'appContainer/DashboardContainer/getMenuOptions/sharedMenuOptions';
import { openCopyScenarioMenu } from 'appContainer/DashboardContainer/getMenuOptions';
import { AssetType } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { openScenario } from 'scope/actions';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';
import { ScenarioModeState } from 'scope/types';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';

export const getScenarioMenuItems = (
  scenarioId: string | null,
  context: CurrentContext,
  activeScenarioState: ScenarioModeState
): DropdownItem[] | null => {
  const scenario = scenarioInterface.getScenarioById(scenarioId);

  if (!scenarioId || !scenario) {
    return null;
  }

  const menu = [
    getScenarioPermissionsMenuItem(scenarioId),
    getRenameScenarioMenuItem(
      scenarioId,
      currentUserInterface.getPermissionContext(),
      getActiveScenarioState()
    ),
    copyMenuOption({
      assetType: AssetType.SCENARIO,
      hasPermission: scenarioAccessControlInterface.canCreateScenario(
        currentUserInterface.getPermissionContext()
      ),
      onCopy: () =>
        openCopyScenarioMenu({
          scenario,
          openCopy: copyId =>
            dispatchAction(
              openScenario({
                scenarioId: copyId,
                workspaceId: context?.workspaceId,
                componentId: context?.componentId,
                trackingClickSource: 'addToScenarioDialog',
              })
            ),
        }),
    }),
  ].filter(ExcludeFalsy);

  const diffModeAwareMenu = activeScenarioOperations.isInDiffMode(
    activeScenarioState
  )
    ? menu.map(menuItem => ({ ...menuItem, isDisabled: true }))
    : menu;

  return diffModeAwareMenu.length ? diffModeAwareMenu : null;
};
