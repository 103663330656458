import { FlexBox } from '@ardoq/layout';
import { GroupDetailsPageProps } from '../types';
import { SearchInput } from '@ardoq/forms';
import {
  Column,
  DatasourceTable,
  GetColumns,
  newTableTheme,
} from '@ardoq/table';
import { APIOrganizationUser } from '@ardoq/api-types';
import { getUsersTableColumns } from 'admin/orgMembers/columnConfig';
import { IconName } from '@ardoq/icons';
import { IconButton } from '@ardoq/button';
import { Features, hasFeature } from '@ardoq/features';
import { useMemo, useState } from 'react';
import { EmptyUsers } from './EmptyUsers';
import { EmptySearchUsers } from './EmptySearchUsers';
import { PaginationBar, PaginationController } from '@ardoq/pagination';

const getColumn =
  (
    removeUser: GroupDetailsPageProps['commands']['removeUserFromGroup'],
    group: GroupDetailsPageProps['group']
  ): GetColumns<APIOrganizationUser> =>
  (sortBy, sortById, sortOrder): Column<APIOrganizationUser>[] => [
    ...getUsersTableColumns(
      false,
      false,
      false,
      hasFeature(Features.SCIM_PROVISIONING),
      [],
      {},
      sortBy,
      sortById,
      sortOrder
    ),
    {
      title: '',
      dataIndex: '_id',
      cellStyle: { display: 'flex' },
      valueRender: (_: any, user: APIOrganizationUser) => (
        <IconButton
          data-tooltip-text="Remove member from group"
          iconName={IconName.DELETE}
          onClick={event => {
            event.stopPropagation();
            removeUser(group, user);
          }}
        />
      ),
    },
  ];

export const GroupUsersList = (
  props: GroupDetailsPageProps & { setSearchPhrase: (query: string) => void }
) => {
  const [perPage, setPerPage] = useState(10);

  const { commands, permissionGroups, group, searchPhrase, setSearchPhrase } =
    props;

  const allUsers = useMemo(
    () =>
      permissionGroups.find(group => group._id === props.groupId)?.users || [],
    [permissionGroups, props.groupId]
  );

  const users = useMemo(() => {
    if (!searchPhrase) {
      return allUsers;
    }
    return allUsers.filter(user =>
      user.name.toLowerCase().includes(searchPhrase.toLowerCase())
    );
  }, [allUsers, searchPhrase]);

  if (allUsers.length === 0) {
    return <EmptyUsers {...props} />;
  }
  return (
    <FlexBox gap="xlarge" flexDirection="column" align="stretch" flex={1}>
      <FlexBox justify="space-between" align="center">
        <SearchInput
          onValueChange={setSearchPhrase}
          placeholder="Search members"
          value={searchPhrase}
        />
      </FlexBox>
      <PaginationController
        dataSource={users}
        perPage={perPage}
        render={({
          currentPageDataSource,
          sortBy,
          sortById,
          sortOrder,
          ...rest
        }) => {
          return (
            <>
              <DatasourceTable<APIOrganizationUser>
                style={{ flexGrow: 1, height: '100%' }}
                components={newTableTheme}
                rowStyle={() => ({
                  cursor: 'pointer',
                })}
                dataSource={currentPageDataSource}
                columns={getColumn(commands.removeUserFromGroup, group)(
                  sortBy,
                  sortById,
                  sortOrder
                )}
                renderEmptyTable={() => <EmptySearchUsers />}
                onRowClick={user => {
                  commands.goToUserProfilePage(user._id, 'Groups details page');
                }}
              />
              <PaginationBar
                {...rest}
                onChange={({
                  currentPageNumber: newPageNumber,
                  perPage: newPerPage,
                }) => {
                  setPerPage(newPerPage);
                  rest.onPageSelect(newPageNumber);
                }}
              />
            </>
          );
        }}
      />
    </FlexBox>
  );
};
