import { currentUserPermissions$ } from './currentUserPermissions$';
import { ArdoqId } from '@ardoq/api-types';
import currentUser from 'models/currentUser';
import { Features, hasFeature } from '@ardoq/features';
import { isScenarioMode } from 'models/utils/scenarioUtils';

type HasPermissionFn = (resourceId?: ArdoqId) => boolean;

type ResourcePermissions = {
  hasAdminAccess: boolean;
  hasEditAccess: boolean;
  hasReadAccess: boolean;
};

const resourceDefaultPermissions: ResourcePermissions = {
  hasAdminAccess: false,
  hasEditAccess: false,
  hasReadAccess: false,
};

const getPermissionsByResourceId = (resourceId = ''): ResourcePermissions =>
  currentUserPermissions$.state[resourceId] || resourceDefaultPermissions;

// Low-level API for checking resource permissions
const hasAdminAccess: HasPermissionFn = resourceId =>
  currentUser.hasWriteAccess() &&
  (currentUser.isOrgAdmin() ||
    Boolean(
      resourceId && getPermissionsByResourceId(resourceId)?.hasAdminAccess
    ));

const hasEditAccess: HasPermissionFn = resourceId =>
  currentUser.hasWriteAccess() &&
  (currentUser.isOrgAdmin() ||
    Boolean(
      resourceId && getPermissionsByResourceId(resourceId)?.hasEditAccess
    ));

const hasResourceEditAccess: HasPermissionFn = resourceId =>
  getPermissionsByResourceId(resourceId).hasEditAccess;

const hasResourceAdminAccess: HasPermissionFn = resourceId =>
  getPermissionsByResourceId(resourceId).hasAdminAccess;

const hasResourceReadAccess: HasPermissionFn = resourceId =>
  getPermissionsByResourceId(resourceId).hasReadAccess;

const hasReadAccess: HasPermissionFn = resourceId =>
  currentUser.isOrgAdmin() ||
  Boolean(resourceId && getPermissionsByResourceId(resourceId)?.hasReadAccess);

const canCreateTemplateFromModel: HasPermissionFn = modelId =>
  hasReadAccess(modelId) && currentUser.hasWriteAccess();

const canManageGlobalFields = () => currentUser.isOrgAdmin();

const isOrgWriter = () =>
  currentUser.isOrgAdmin() || currentUser.hasWriteAccess();
const isOrgAdmin = () => currentUser.isOrgAdmin();

const canManagePermissionGroups = () => currentUser.isOrgAdmin();
const canCreateMetamodels = () => currentUser.isOrgAdmin();

const canManageIPAllowlisting = () =>
  hasFeature(Features.IP_ALLOWLIST_MANAGEMENT);

// TO-DO, think about:
// Default workspace permissions
// Default survey / dashboard permissions

// Naming scheme: RESOURCE_ACTION
/**
 * @deprecated use @ardoq/access-control
 */
export enum Permissions {
  // Resource-specific permissions
  // Workspace
  WORKSPACE_EDIT,
  WORKSPACE_EDIT_FIELDS,
  WORKSPACE_EDIT_REFERENCE_TYPES,
  WORKSPACE_LOAD_ADDITIONAL,
  WORKSPACE_RELATED_WORKSPACES,
  WORKSPACE_EXPORT_EXCEL,
  // Component
  COMPONENT_VIEW_HISTORY,
  // Rereference
  REFERENCE_VIEW_HISTORY,
  // Survey
  SURVEY_CREATE_FROM_COMPONENT,
  SURVEY_ACCESS,
  SURVEY_EDIT,
  SURVEY_ADMIN,
  // Model
  MODEL_EDIT,
  MODEL_CREATE_TEMPLATE,
  // Views
  MANAGE_GLOBAL_FIELDS,
  // Bundles
  COPY_BUNDLE,
  // Permission Management
  MANAGE_PERMISSION_GROUPS,
  // Meta Model
  METAMODELS_CREATE,
  // Viewpoints
  VIEWPOINTS_MANAGE_PERMISSIONS,
  VIEWPOINT_READ,
  VIEWPOINT_EDIT,
  VIEWPOINT_ADMIN,
  // Reports
  REPORTS_CREATE,
  REPORTS_EDIT,
  REPORTS_ADMIN,
  REPORTS_READ,
  // Misc
  IP_ALLOWLIST_MANAGE,
}
const actionToPermission: Partial<Record<Permissions, HasPermissionFn>> = {
  [Permissions.MODEL_CREATE_TEMPLATE]: canCreateTemplateFromModel,
  [Permissions.MANAGE_GLOBAL_FIELDS]: canManageGlobalFields,
  [Permissions.COPY_BUNDLE]: isOrgAdmin,
  [Permissions.MANAGE_PERMISSION_GROUPS]: canManagePermissionGroups,
  [Permissions.METAMODELS_CREATE]: canCreateMetamodels,

  // Viewpoint
  [Permissions.VIEWPOINTS_MANAGE_PERMISSIONS]: hasAdminAccess,
  [Permissions.VIEWPOINT_READ]: hasResourceReadAccess,
  [Permissions.VIEWPOINT_EDIT]: hasResourceEditAccess,
  [Permissions.VIEWPOINT_ADMIN]: hasResourceAdminAccess,
  // Reports
  [Permissions.REPORTS_CREATE]: isOrgWriter,
  [Permissions.REPORTS_EDIT]: hasEditAccess,
  [Permissions.REPORTS_ADMIN]: hasAdminAccess,
  [Permissions.REPORTS_READ]: hasReadAccess,
  [Permissions.IP_ALLOWLIST_MANAGE]: canManageIPAllowlisting,
  // Surveys
  [Permissions.SURVEY_ACCESS]: hasResourceReadAccess,
  [Permissions.SURVEY_EDIT]: hasResourceEditAccess,
  [Permissions.SURVEY_ADMIN]: hasResourceAdminAccess,
};

/**
 * @deprecated Use @ardoq/access-control
 *
 * BE CAREFUL! Some of these "permission checks" mix in ardoq-front state
 * to make a combination of permission and state assertions. Specifically
 * anything that touches currentUser.hasWriteAccess()
 */
export const hasPermission = (
  action: Permissions,
  resourceId?: ArdoqId // we accept undefined in order to support optional property accesses
) => {
  const handler = actionToPermission[action];
  return handler ? handler(resourceId) : false;
};

const negate = (fn: HasPermissionFn) => (resourceId?: ArdoqId) =>
  !fn(resourceId);

const actionToDisabled: Partial<Record<Permissions, HasPermissionFn>> = {
  [Permissions.WORKSPACE_EDIT]: isScenarioMode,
  [Permissions.WORKSPACE_EDIT_FIELDS]: isScenarioMode,
  [Permissions.WORKSPACE_EDIT_REFERENCE_TYPES]: isScenarioMode,
  [Permissions.WORKSPACE_LOAD_ADDITIONAL]: isScenarioMode,
  [Permissions.WORKSPACE_RELATED_WORKSPACES]: isScenarioMode,
  [Permissions.WORKSPACE_EXPORT_EXCEL]: isScenarioMode,
  [Permissions.MODEL_EDIT]: isScenarioMode,
  [Permissions.MODEL_CREATE_TEMPLATE]: negate(canCreateTemplateFromModel),
  [Permissions.SURVEY_CREATE_FROM_COMPONENT]: isScenarioMode,
  [Permissions.COMPONENT_VIEW_HISTORY]: isScenarioMode,
  [Permissions.REFERENCE_VIEW_HISTORY]: isScenarioMode,
};

/**
 * @deprecated Most of these checks are not permissions checks, but rather state
 * assertions. Move somewhere else.
 */
export const isDisabled = (action: Permissions, resourceId?: ArdoqId) => {
  const handler = actionToDisabled[action];
  return handler ? handler(resourceId || '') : false;
};
