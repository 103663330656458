import {
  isSavedPathCollapsingRule,
  ViewStatePathCollapsingRule,
} from './types';
import { CollapsePathsCommands } from './collapsePathsCommands';
import { ButtonSize, IconButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import PathCollapsingRulePathSummary from './PathCollapsingRulePathSummary';
import { TextOverflow } from '@ardoq/popovers';
import { Switch } from '@ardoq/forms';

type PathCollapsingRuleItemProps = {
  rule: ViewStatePathCollapsingRule;
  commands: CollapsePathsCommands;
  isEditingAnotherRule: boolean;
};

const PathCollapsingRuleItem = ({
  rule,
  commands,
  isEditingAnotherRule,
}: PathCollapsingRuleItemProps) => {
  if (!isSavedPathCollapsingRule(rule)) {
    return null;
  }
  return (
    <Box
      borderColor="borderSubtle00"
      borderRadius="r8"
      padding="medium"
      paddingTop="small"
    >
      <Stack gap="small">
        <FlexBox align="center" justify="space-between">
          <TextOverflow
            style={{ fontSize: '18px', fontWeight: 600 }}
            content={rule.displayText}
          >
            {rule.displayText}
          </TextOverflow>
          <FlexBox gap="xsmall">
            <IconButton
              buttonSize={ButtonSize.SMALL}
              iconName={IconName.EDIT}
              onClick={() => commands.editPathCollapsingRule(rule.hash)}
              isDisabled={isEditingAnotherRule}
            />
            <IconButton
              buttonSize={ButtonSize.SMALL}
              iconName={IconName.DELETE}
              onClick={() => commands.deletePathCollapsingRule(rule.hash)}
            />
          </FlexBox>
        </FlexBox>
        {isSavedPathCollapsingRule(rule) && (
          <FlexBox align="center" gap="xsmall">
            <Switch
              name={`rule-${rule.displayText}-${rule.hash}`}
              label="is active"
              isChecked={rule.isActive}
              onChange={() =>
                commands.collapsingRuleIsActiveToggled(
                  rule.hash,
                  !rule.isActive
                )
              }
            />
          </FlexBox>
        )}
        <PathCollapsingRulePathSummary rule={rule} commands={commands} />
      </Stack>
    </Box>
  );
};

export default PathCollapsingRuleItem;
