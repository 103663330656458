import DetailsDialogRow from 'broadcasts/components/DetailsDialogRow';
import { capitalize, compact, pick, values } from 'lodash';
import { ErrorNotification, WarningNotification } from '@ardoq/status-ui';
import isEmpty from 'lodash/isEmpty';
import AudienceTags from 'broadcasts/broadcastBuilder/broadcastAudienceForm/audienceTags/AudienceTags';
import { getRulesAsText } from '../broadcastBuilder/broadcastContentForm/reportRulesSection/utils';
import {
  BroadcastContentType,
  BroadcastAudience,
  BroadcastAggregate,
  ReportRuleOperator,
  BroadcastValidation,
  BroadcastStatus,
  Report,
} from '@ardoq/api-types';
import { currentDateTimeISO, isDateBeforeDay } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { ModalLayout } from '@ardoq/modal';

export type BroadcastDetailsProps = {
  readonly name: string;
  readonly contentType: BroadcastContentType;
  readonly surveyName: string | null;
  readonly workspaceName: string | null;
  readonly componentTypeName: string | null;
  readonly audiences: BroadcastAudience[];
  readonly sentFrom: string;
  readonly startDate: string | null;
  readonly scheduleType: string;
  readonly interval: string | null;
  readonly endDate: string | null;
  readonly reminder: string | null;
  readonly report: Report | null;
  readonly aggregate: BroadcastAggregate | null;
  readonly threshold: number | null;
  readonly predicate: ReportRuleOperator | null;
  readonly columnName: string | null;
  readonly broadcastStatus: BroadcastStatus;
} & BroadcastValidation;

const pickValues = (
  object: Record<string, string | undefined> | undefined,
  keys: string[]
) => {
  return compact(values(pick(object, keys)));
};

const checkStartDateNotInPast = (
  startDate: string | null,
  broadcastStatus: BroadcastStatus
) => {
  const locale = getCurrentLocale();

  if (!startDate || broadcastStatus !== BroadcastStatus.UNPUBLISHED) return [];
  if (isDateBeforeDay(startDate, currentDateTimeISO(), locale))
    return [
      'The start date is in the past. Update the start date before launching.',
    ];
};

const BroadcastDetails = ({
  name,
  contentType,
  surveyName,
  workspaceName,
  componentTypeName,
  audiences,
  sentFrom,
  startDate,
  scheduleType,
  interval,
  endDate,
  reminder,
  errors,
  warnings,
  report,
  aggregate,
  threshold,
  predicate,
  columnName,
  broadcastStatus,
}: BroadcastDetailsProps) => {
  const contentNotFound = pickValues(warnings, ['CONTENT_NOT_FOUND']).length;
  return (
    <ModalLayout>
      {!isEmpty(errors) && (
        <ErrorNotification>
          Please add the missing required items to run your broadcast.
        </ErrorNotification>
      )}
      {!isEmpty(warnings) && (
        <WarningNotification>
          Some items are not found in the current scope and this might affect
          your broadcast.
        </WarningNotification>
      )}
      <DetailsDialogRow
        label="Name"
        value={name}
        errors={pickValues(errors, ['NAME_MISSING'])}
      />
      <DetailsDialogRow label="Content type" value={capitalize(contentType)} />
      {contentType === BroadcastContentType.SURVEY && (
        <DetailsDialogRow
          label="Survey name"
          value={surveyName}
          errors={pickValues(errors, ['CONTENT_INCOMPLETE'])}
        />
      )}
      {contentType !== BroadcastContentType.REPORT && (
        <>
          <DetailsDialogRow
            label="Workspace"
            value={workspaceName}
            errors={
              !workspaceName ? pickValues(errors, ['CONTENT_INCOMPLETE']) : []
            }
          />
          <DetailsDialogRow
            label="Component type"
            value={!contentNotFound ? componentTypeName : null}
            errors={pickValues(errors, ['CONTENT_INCOMPLETE'])}
            warnings={pickValues(warnings, ['CONTENT_NOT_FOUND'])}
          />
        </>
      )}
      {contentType === BroadcastContentType.REPORT && report && (
        <DetailsDialogRow label="Report name" value={report.name} />
      )}
      {contentType === BroadcastContentType.REPORT &&
        report &&
        aggregate &&
        threshold !== null &&
        predicate &&
        columnName && (
          <DetailsDialogRow
            label="Report threshold"
            value={getRulesAsText(
              report.columns.find(column => column.key === columnName)?.label ??
                columnName,
              aggregate,
              predicate,
              threshold
            )}
          />
        )}
      <DetailsDialogRow
        label="Audience Source"
        value={
          audiences.length ? (
            <AudienceTags
              inReviewBroadcastDialog={true}
              audiences={audiences}
            />
          ) : null
        }
        errors={pickValues(errors, ['AUDIENCE_INCOMPLETE'])}
        warnings={pickValues(warnings, [
          'AUDIENCE_PEOPLE_WS_NOT_FOUND',
          'AUDIENCE_GREMLIN_INCOMPLETE',
          'AUDIENCE_GREMLIN_ERROR',
          'AUDIENCE_PREDEFINED_INCOMPLETE',
          'AUDIENCE_PREDEFINED_NOT_FOUND',
          'AUDIENCE_EMAIL_INCOMPLETE',
          'AUDIENCE_EMAIL_MALFORMED',
        ])}
      />
      <DetailsDialogRow label="Sent from" value={sentFrom} />
      <DetailsDialogRow
        label="Start date"
        value={startDate}
        warnings={checkStartDateNotInPast(startDate, broadcastStatus)}
      />
      <DetailsDialogRow
        label="Schedule type"
        value={scheduleType}
        errors={pickValues(errors, ['SCHEDULING_INCOMPLETE'])}
      />
      {interval && <DetailsDialogRow label="Interval" value={interval} />}
      {endDate && <DetailsDialogRow label="End date" value={endDate} />}
      {reminder && <DetailsDialogRow label="Reminder" value={reminder} />}
    </ModalLayout>
  );
};

export default BroadcastDetails;
