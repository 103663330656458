import { dispatchAction } from '@ardoq/rxbeach';
import { logError } from '@ardoq/logging';
import { loadedStateUpdated, registerLoadedState } from 'loadedState/actions';
import { buildTraversalState, isError } from 'loadedState/buildState';
import { loadedState$ } from 'loadedState/loadedState$';
import { setActiveMainTabLeft } from '../streams/views/mainContent/actions';
import { ExecuteViewpointPayload } from './actions';
import { getSupportedTraversalViewIdOrDefault } from 'traversals/getViewpointModeSupportedViews';
import { applyViewpointPerspectives } from 'traversals/applyViewpointPerspectives';
import { applyViewpointViewSettings } from 'traversals/applyViewpointViewSettings';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import selectedModule$ from 'appContainer/selectedModule$';
import { enterViewpointMode } from 'streams/traversals/actions';
import { isExecuteSavedViewpointPayload } from './utils';
import { TraversalParams } from '@ardoq/api-types';
import { traversalInterface } from 'modelInterface/traversals/traversalInterface';
import { scopeDataLoaded } from '../traversals/stagedLoadedDataAndState$';
import {
  setIsLoading,
  setIsLoadingWithConfig,
} from 'components/GlobalPaneLoader/globalPaneLoader$';
import { loadedStateOperations } from '../loadedState/loadedStateOperations';

const isLoadedStateHashString = (
  loadedStateHash?: string
): loadedStateHash is string => Boolean(loadedStateHash);

export const openTraversalInVisualization = async (
  executeViewpointConfig: ExecuteViewpointPayload
) => {
  // TODO this is quite ugly
  dispatchAction(
    setIsLoadingWithConfig({
      title: 'Loading data set...',
    })
  );
  // apply perspective and filters of only the first applied traversal
  const isFirstOpenedTraversal = !loadedState$.state.length;
  const traversal: TraversalParams = executeViewpointConfig;

  const viewpointId = isExecuteSavedViewpointPayload(executeViewpointConfig)
    ? executeViewpointConfig.viewpointId
    : undefined;

  const params = loadedStateOperations.fromTraversalParams(
    traversal,
    viewpointId
  );

  try {
    const componentIdsAndReferencesOrError = await buildTraversalState(
      params.data
    );
    if (isError(componentIdsAndReferencesOrError)) {
      dispatchAction(setIsLoading(false));
      return;
    }

    dispatchAction(
      scopeDataLoaded({
        trackingLocation: 'traversal',
        scopeData: componentIdsAndReferencesOrError.scopeData,
      })
    );
    dispatchAction(enterViewpointMode());

    if (selectedModule$.state.selectedModule !== AppModules.WORKSPACES) {
      dispatchAction(
        requestShowAppModule({
          selectedModule: AppModules.WORKSPACES,
        })
      );
    }

    const loadedState = loadedStateOperations.attachLoadedGraph(
      params,
      componentIdsAndReferencesOrError
    );

    if (isLoadedStateHashString(executeViewpointConfig.loadedStateHash)) {
      dispatchAction(
        loadedStateUpdated({
          loadedState,
          existingHash: executeViewpointConfig.loadedStateHash,
        })
      );
    } else {
      dispatchAction(
        setActiveMainTabLeft({
          activeTabId: getSupportedTraversalViewIdOrDefault(
            executeViewpointConfig.viewName
          ),
        })
      );
      dispatchAction(registerLoadedState([loadedState]));
    }

    if (isExecuteSavedViewpointPayload(executeViewpointConfig)) {
      const traversal = traversalInterface.getById(
        executeViewpointConfig.viewpointId
      );

      applyViewpointViewSettings(traversal);

      if (isFirstOpenedTraversal) {
        applyViewpointPerspectives(traversal);
      }
    }
    dispatchAction(setIsLoading(false));
  } catch (e) {
    logError(Error('Loading subgraph from traversal config failed'));
    dispatchAction(setIsLoading(false));
  }
};
