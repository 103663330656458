import { v4 as uuidv4 } from 'uuid';
import { FiltersTabPlainDataParams, PerspectivesEditorState } from '../types';
import { BooleanOperator } from '@ardoq/api-types';

export const defaultSubquery = { condition: BooleanOperator.AND, rules: [] };

export const getFiltersTabPlainDataParamsInitialState =
  (): FiltersTabPlainDataParams => ({
    workspaceFilterIds: [],
    componentRules: defaultSubquery,
    referenceRules: defaultSubquery,
    queryBuilderInternalStateWorkaroundKey: uuidv4(),
    componentsFilterGroups: [],
    referencesFilterGroups: [],
    graphFilters: [],
    appliedFilterRules: {
      componentRules: defaultSubquery,
      referenceRules: defaultSubquery,
      workspaceFilterIds: [],
      graphFilters: [],
    },
  });

export const clearFilterTabState = (
  state: PerspectivesEditorState
): FiltersTabPlainDataParams => ({
  ...state.filtersTabPlainDataParams,
  workspaceFilterIds: [],
  componentRules: defaultSubquery,
  referenceRules: defaultSubquery,
  queryBuilderInternalStateWorkaroundKey: uuidv4(),
  componentsFilterGroups: [],
  referencesFilterGroups: [],
  graphFilters: [],
  appliedFilterRules: {
    componentRules: defaultSubquery,
    referenceRules: defaultSubquery,
    workspaceFilterIds: [],
    graphFilters: [],
  },
});
