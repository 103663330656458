import { withOnFirstSubscription } from '../../streams/utils/streamUtils';
import { action$, dispatchAction, ofType } from '@ardoq/rxbeach';
import {
  fetchComponentTypes,
  fetchEntityGroups,
  fetchKeyMetricsDashboards,
  fetchReferenceTypes,
  fetchUseCasesData,
} from './actions';
import { switchMap, tap, map, combineLatest } from 'rxjs';
import {
  api,
  componentTypeApi,
  dashboardApi,
  entityGroupApi,
  referenceTypeApi,
  useCaseApi,
} from '@ardoq/api';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators';

export const useCases$ = withOnFirstSubscription(
  action$.pipe(
    ofType(fetchUseCasesData),
    switchMap(useCaseApi.fetchAll),
    tap(response => api.logErrorIfNeeded(response)),
    map(response => api.defaultValueIfError(response, [])),
    startWith(null),
    tag('useCases$')
  ),
  () => {
    dispatchAction(fetchUseCasesData());
  }
);

export const keyMetricsDashboards$ = withOnFirstSubscription(
  action$.pipe(
    ofType(fetchKeyMetricsDashboards),
    switchMap(dashboardApi.fetchAll),
    tap(response => api.logErrorIfNeeded(response)),
    map(response => api.defaultValueIfError(response, [])),
    startWith(null),
    tag('keyMetricsDashboards$')
  ),
  () => {
    dispatchAction(fetchKeyMetricsDashboards());
  }
);

export const entityGroups$ = withOnFirstSubscription(
  action$.pipe(
    ofType(fetchEntityGroups),
    switchMap(entityGroupApi.fetchAll),
    tap(response => api.logErrorIfNeeded(response)),
    map(response => api.defaultValueIfError(response, [])),
    startWith(null),
    tag('entityGroups$')
  ),
  () => {
    dispatchAction(fetchEntityGroups());
  }
);

export const referenceTypes$ = withOnFirstSubscription(
  action$.pipe(
    ofType(fetchReferenceTypes),
    switchMap(referenceTypeApi.fetchAll),
    tap(response => api.logErrorIfNeeded(response)),
    map(response => api.defaultValueIfError(response, [])),
    startWith(null),
    tag('referenceTypes$')
  ),
  () => {
    dispatchAction(fetchReferenceTypes());
  }
);

export const componentTypes$ = withOnFirstSubscription(
  action$.pipe(
    ofType(fetchComponentTypes),
    switchMap(componentTypeApi.fetchAll),
    tap(response => api.logErrorIfNeeded(response)),
    map(response => api.defaultValueIfError(response, [])),
    startWith(null),
    tag('componentTypes$')
  ),
  () => {
    dispatchAction(fetchComponentTypes());
  }
);

export const isFetchingData$ = combineLatest([
  useCases$,
  keyMetricsDashboards$,
  entityGroups$,
  referenceTypes$,
  componentTypes$,
]).pipe(
  map(data => !data.every(entityArray => Boolean(entityArray))),
  distinctUntilChanged(),
  startWith(true)
);
