import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId, APIPresentationAssetAttributes } from '@ardoq/api-types';

export const notifyPresentationDeleted = actionCreator<ArdoqId>(
  '[presentations] NOTIFY_PRESENTATION_DELETED'
);

export const updatePresentation = actionCreator<APIPresentationAssetAttributes>(
  '[presentations] UPDATE_PRESENTATION'
);

export const createPresentationAndOpenInPreview = actionCreator<
  Partial<APIPresentationAssetAttributes>
>('[presentations] CREATE_PRESENTATION_AND_OPEN_IN_PREVIEW');

export const createPresentationAndOpenInEditor = actionCreator<
  Partial<APIPresentationAssetAttributes>
>('[presentations] CREATE_PRESENTATION_AND_OPEN_IN_EDITOR');

export const notifyPresentationCreatedByUser =
  actionCreator<APIPresentationAssetAttributes>(
    '[presentations] NOTIFY_PRESENTATION_CREATED_BY_USER'
  );
