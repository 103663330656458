import { action$, extractPayload, ofType } from '@ardoq/rxbeach';
import { shareReplay, startWith } from 'rxjs';
import { setUsedTypeIds } from './actions';

export default action$.pipe(
  ofType(setUsedTypeIds),
  extractPayload(),
  startWith({ usedTypeIds: undefined }),
  shareReplay({
    bufferSize: 1,
    refCount: true,
  })
);
