import { dispatchAction } from '@ardoq/rxbeach';
import { getApplicablePerspectiveState } from './perspectivesEditor$/applicablePerspectiveState';
import { PerspectivesEditorState } from './types';
import { applyPerspective } from 'perspective/actions';
const applyPerspectiveState = (
  perspectiveEditorState: Pick<
    PerspectivesEditorState,
    | 'filtersTabPlainDataParams'
    | 'formattingTab'
    | 'groupsTab'
    | 'perspectivesRelatedData'
    | 'availableRelatedData'
    | 'activeViewId'
  >
) => {
  const applicablePerspectiveState = getApplicablePerspectiveState(
    perspectiveEditorState
  );

  dispatchAction(applyPerspective(applicablePerspectiveState));
};

export default applyPerspectiveState;
