import { ModalLayout, ModalSize, alert } from '@ardoq/modal';
import { Stack } from '@ardoq/layout';
import { ArdoqError, getArdoqErrorMessage } from '@ardoq/common-helpers';

export const surveyAlert = ({
  action,
  entity = 'This survey',
  additionalMessage,
  error,
}: {
  action: 'deleted' | 'copied' | 'saved' | 'fetched';
  entity?: string;
  additionalMessage?: string;
  error: ArdoqError;
}) =>
  alert({
    title: 'Something went wrong',
    body: (
      <ModalLayout>
        <Stack gap="small">
          {entity} could not be {action}.
          {additionalMessage && <p>{additionalMessage}</p>}
        </Stack>
      </ModalLayout>
    ),
    errorMessage: getArdoqErrorMessage(error),
    modalSize: ModalSize.S,
  });
