import { Component } from 'react';
import PresentationGrid from './components/PresentationGrid';
import { AqLayout, Box, FlexBox } from '@ardoq/layout';
import HumanPlaceholder from 'atomicComponents/HumanPlaceholder/HumanPlaceholder';
import {
  APIPresentationAssetAttributes,
  ArdoqId,
  AssetType,
  Meta,
  PersonalSetting,
} from '@ardoq/api-types';
import { connect, derivedStream, dispatchAction } from '@ardoq/rxbeach';
import { toggleFavorite } from 'streams/currentUser/actions';
import { startPresentation } from 'presentation/utils';
import { trackOpenedPresentationSidebar } from 'presentation/tracking';
import { trackClickedPlayPresentation } from '../presentation/tracking';
import { hasFeature, Features } from '@ardoq/features';
import PresentationsNavBar from 'presentation/presentations2024/PresentationsNavBar';
import presentationsOverview$ from 'presentation/presentations2024/presentationsOverview$';
import { map } from 'rxjs';
import {
  PresentationOverviewCommands,
  presentationOverviewCommands,
} from 'presentation/presentations2024/presentationOverviewCommands';
import { PresentationOverviewStreamShape } from 'presentation/presentations2024/types';
import currentUser$, {
  CurrentUserState,
  getIsOrgWriter,
} from 'streams/currentUser/currentUser$';
import { PrimaryButton } from '@ardoq/button';
import PresentationSidebar from './components/PresentationSidebar';
import ListModeSwitch from 'presentation/presentations2024/ListModeSwitch';
import { PageBody, PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import { AppModules } from 'appContainer/types';
import FilterBar from 'components/AssetsBrowser2024/FilterBar/FilterBar';
import {
  favoriteFilter,
  getCreatedByMeFilter,
} from 'components/AssetsBrowser2024/FilterBar/filters';
import {
  publicFilter,
  contributorFilter,
  onlyMeFilter,
  workspaceFilter,
} from 'presentation/presentations2024/overviewHelpers';
import presentations$ from 'streams/presentations/presentations$';
import { AssetsBrowser2024StreamShape } from 'components/AssetsBrowser2024/assetsBrowser2024Types';
import assetsBrowser2024$ from 'components/AssetsBrowser2024/assetsBrowser2024$';
import { Filter } from 'components/AssetsBrowser2024/FilterBar/filterBarTypes';
import { enhanceWithMeta } from 'streams/assets/utils';
import { findMatchGroups } from '@ardoq/common-helpers';
import { getCurrentLocale, Locale } from '@ardoq/locale';
import { PresentationsStreamShape } from 'streams/presentations/types';

const onStarClick = (presentationId: ArdoqId) => {
  dispatchAction(
    toggleFavorite({ _id: presentationId, type: AssetType.PRESENTATION })
  );
};

const onPlayButtonClick = (presentationId: ArdoqId) => {
  startPresentation(presentationId);
  trackClickedPlayPresentation('Presentation view', presentationId);
};

interface PresentationOrganizerState {
  loading: boolean;
  presentationToBeDeleted?: APIPresentationAssetAttributes;
  suppressConfirmationDialogs: boolean;
  deletingPresentation: boolean;
}

type PresentationOrganizerProps = {
  isSidebarOpen: boolean;
  commands: PresentationOverviewCommands;
  canUserCreatePresentations: boolean;
  currentUser: CurrentUserState;
  presentations: (APIPresentationAssetAttributes &
    Meta<AssetType.PRESENTATION>)[];
  activeFilters: Filter[];
  searchPhrase: string;
  locale: Locale;
};

/**
 * Returns presentations that should be shown based on activeCategory
 */
const getCategoryPresentations = (
  presentations: (APIPresentationAssetAttributes &
    Meta<AssetType.PRESENTATION>)[],
  activeFilters: Filter[],
  searchPhrase: string,
  locale: Locale
) => {
  if (!activeFilters.length && !searchPhrase.length) return presentations;
  return presentations.filter(presentation => {
    const matchGroups = findMatchGroups(
      presentation.name,
      searchPhrase,
      locale
    );
    return (
      (!searchPhrase || matchGroups.match) &&
      (!activeFilters.length ||
        activeFilters.every(filter => filter.filterFn(presentation)))
    );
  });
};

class PresentationOrganizer extends Component<
  PresentationOrganizerProps,
  PresentationOrganizerState
> {
  constructor(props: PresentationOrganizerProps) {
    super(props);
    this.state = {
      loading: true,
      presentationToBeDeleted: undefined,
      suppressConfirmationDialogs: false,
      deletingPresentation: false,
    };

    this.onCardClick = this.onCardClick.bind(this);
  }

  onCardClick(presentationId: ArdoqId) {
    const { commands } = this.props;
    commands.previewPresentationInEditor({ presentationId });
    trackOpenedPresentationSidebar('Presentation view', presentationId);
  }

  render() {
    const {
      isSidebarOpen,
      commands,
      canUserCreatePresentations,
      currentUser,
      presentations,
      activeFilters,
      searchPhrase,
      locale,
    } = this.props;
    const filteredPresentations = getCategoryPresentations(
      presentations,
      activeFilters,
      searchPhrase,
      locale
    );
    const pageContent = (
      <>
        <div
          style={{
            overflow: 'auto',
            width: '100%',
            height: `calc(100% - ${PAGE_TOPBAR_HEIGHT})`,
          }}
        >
          <Box paddingX="medium" paddingTop="medium">
            <FilterBar
              filters={[
                favoriteFilter,
                getCreatedByMeFilter(currentUser._id),
                publicFilter,
                contributorFilter,
                onlyMeFilter,
                workspaceFilter,
              ]}
              appModule={AppModules.PRESENTATIONS}
            />
          </Box>
          {filteredPresentations.length ? (
            <PresentationGrid
              presentations={filteredPresentations}
              onStarClick={onStarClick}
              onCardClick={this.onCardClick}
              onPlayButtonClick={onPlayButtonClick}
              onEditButtonClick={commands.openPresentationInEditor}
            />
          ) : (
            <Box padding="large">
              <HumanPlaceholder
                title="There are no presentations in this category yet"
                wrapperStyle={{ height: 'initial' }}
              />
            </Box>
          )}
        </div>
        {isSidebarOpen && <PresentationSidebar />}
      </>
    );
    return hasFeature(Features.NEW_CORE_JOURNEY) ? (
      <PresentationsNavBar>
        <PageBody skipPadding backgroundColor="surfaceDefault" preventScroll>
          <FlexBox flex={1} height="full">
            {pageContent}
          </FlexBox>
        </PageBody>
      </PresentationsNavBar>
    ) : (
      <AqLayout
        fullWidth
        title="Manage presentations"
        renderHeaderButtons={() => (
          <FlexBox align="center">
            <ListModeSwitch />
            {canUserCreatePresentations && (
              <PrimaryButton
                dataTestId="create-presentation-button"
                onClick={commands.openPresentationBuilder}
              >
                Create new
              </PrimaryButton>
            )}
          </FlexBox>
        )}
        bodyContentStyle={{
          padding: 0,
          flexDirection: 'row',
          height: '100%',
        }}
      >
        {pageContent}
      </AqLayout>
    );
  }
}

const toPresentationOrganizerStream = ([
  { isSidebarOpen },
  currentUser,
  { byId: presentationsById },
  { appModuleStates },
]: [
  PresentationOverviewStreamShape,
  CurrentUserState,
  PresentationsStreamShape,
  AssetsBrowser2024StreamShape,
]) => {
  const presentationAppModuleState = appModuleStates[AppModules.PRESENTATIONS];
  const favorites =
    currentUser.settings[PersonalSetting.FAVORITE]?.[AssetType.PRESENTATION] ??
    [];
  return {
    isSidebarOpen,
    commands: presentationOverviewCommands,
    canUserCreatePresentations: getIsOrgWriter(currentUser),
    currentUser,
    presentations: Object.values(
      enhanceWithMeta<APIPresentationAssetAttributes, AssetType.PRESENTATION>(
        presentationsById,
        AssetType.PRESENTATION,
        ({ _id }) => ({
          favorite: favorites.includes(_id),
          folderId: null,
          permissions: {},
        })
      )
    ),
    activeFilters: presentationAppModuleState.activeFilters,
    searchPhrase: presentationAppModuleState.searchPhrase,
    locale: getCurrentLocale(),
  };
};

const presentationsOrganizer$ = derivedStream(
  'presentationsOrganizer$',
  presentationsOverview$,
  currentUser$,
  presentations$,
  assetsBrowser2024$
).pipe(map(toPresentationOrganizerStream));

export default connect(PresentationOrganizer, presentationsOrganizer$);
