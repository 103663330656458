import {
  EditTraversalCommands,
  editTraversalCommands,
} from 'viewpointBuilder/traversals/editTraversalCommands';
import { toggleTripleInView } from './actions';
import { dispatchAction } from '@ardoq/rxbeach';
import tracking from './tracking';

export const toggleReferenceTypesInViewCommands: EditTraversalCommands = {
  ...editTraversalCommands,
  toggleTripleOption: (
    tripleId,
    direction,
    namedDirectedTriple,
    isSelected
  ) => {
    tracking.trackOpenNewDataset(isSelected);
    editTraversalCommands.toggleTripleOption(
      tripleId,
      direction,
      namedDirectedTriple,
      isSelected
    );
    dispatchAction(
      toggleTripleInView({
        namedDirectedTriple,
        isSelected,
      })
    );
  },
};
