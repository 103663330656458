import { useEffectOnce } from '@ardoq/hooks';
import { perspectivesEditorTargetPortalConfig } from 'postMessageBridge/configs/perspectivesEditor.target';
import { dispatchAction } from '@ardoq/rxbeach';
import { editorOpened } from 'perspective/actions';

export const usePerspectiveEditorPortalSubscription = () =>
  useEffectOnce(() => {
    // The initial Perspective Editor in the separate window uses a bridge to receive data from the core app.
    // Following here the same interface to reuse exiting Perspectives code without additional changes.
    // Temporary workaround for sidebar perspectivesEditor$ relying on the same actions
    // as the core app Perspective Editor in a window.
    const subscriptions =
      perspectivesEditorTargetPortalConfig.streamSubscriptions!.map(
        ({ stream, action }) =>
          stream.subscribe(data => dispatchAction(action(data)))
      );
    dispatchAction(editorOpened());
    return () => {
      subscriptions.forEach(subscription => subscription.unsubscribe());
    };
  });
