import { ArdoqId } from '@ardoq/api-types';
import { Description } from '../atoms';
import { SelectOption, Multiselect, Select } from '@ardoq/select';
import { Paragraph } from '@ardoq/typography';
import { Box } from '@ardoq/layout';

type CollectionSelectInputProps = {
  allOptions: SelectOption<string>[];
  collectionLabel: string;
  selectedNothingPlaceholder?: string;
  selectedEntityIds: ArdoqId[];
  addEntityToBundle: (entityId: any) => void;
  isMulti?: boolean;
  clearAfterSelection?: boolean;
  description?: string;
};

type CollectionSelectInputOptions = {
  selectedOptions: SelectOption<string>[] | SelectOption<string> | null;
  unselectedOptions: SelectOption<string>[];
};

const CollectionSelectInput = ({
  allOptions,
  collectionLabel,
  selectedNothingPlaceholder,
  selectedEntityIds,
  addEntityToBundle,
  isMulti = false,
  clearAfterSelection = false,
  description,
}: CollectionSelectInputProps) => {
  const { selectedOptions, unselectedOptions } = allOptions.reduce(
    (
      acc: CollectionSelectInputOptions,
      option: SelectOption<string>
    ): CollectionSelectInputOptions => {
      const isSelected = selectedEntityIds?.includes(option.value);
      if (isSelected) {
        if (isMulti) {
          (acc.selectedOptions as SelectOption<string>[]).push(option);
        } else {
          acc.selectedOptions = option;
        }
      } else {
        acc.unselectedOptions.push(option);
      }
      return acc;
    },
    isMulti
      ? { selectedOptions: [], unselectedOptions: [] }
      : { selectedOptions: null, unselectedOptions: [] }
  );

  const placeholder = !unselectedOptions.length
    ? `No ${collectionLabel}s found`
    : (selectedNothingPlaceholder ?? `Select a ${collectionLabel}`);

  const onChange = (options: string | string[] | null) => {
    if (isMulti) {
      addEntityToBundle((options as string[])?.map(value => value) ?? []);
    } else {
      addEntityToBundle(options ? (options as string) : undefined);
    }
  };

  return (
    <>
      <Box paddingBottom="small">
        <Paragraph variant="text1Bold">{`Add ${collectionLabel}s`}</Paragraph>
      </Box>
      {description && <Description>{description}</Description>}
      {isMulti ? (
        <Multiselect
          placeholder={placeholder}
          options={unselectedOptions}
          isDisabled={!allOptions.length}
          onValueChange={onChange}
          value={
            clearAfterSelection
              ? null
              : (selectedOptions as SelectOption<string>[])
          }
          isClearable
          closeMenuOnSelect={false}
        />
      ) : (
        <Select
          placeholder={placeholder}
          options={unselectedOptions}
          isDisabled={!allOptions.length}
          onValueChange={onChange}
          value={selectedOptions as SelectOption<string>}
          closeMenuOnSelect={true}
          isClearable
        />
      )}
    </>
  );
};

export default CollectionSelectInput;
