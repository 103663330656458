import { actionCreator } from '@ardoq/rxbeach';
import {
  APIBroadcastAttributes,
  APIDiscoverViewpointAttributes,
  ArdoqId,
  AudienceStartingPage,
  BroadcastAudienceType,
  BroadcastContentType,
  BroadcastFilterCondition,
  IntervalType,
  PredefinedQueryOption,
  WorkspaceAudienceData,
  BroadcastPreview,
} from '@ardoq/api-types';
import { BroadcastPreviewFetchStatus } from './types';

export const chooseBroadcastContentType = actionCreator<BroadcastContentType>(
  '[broadcasts] CHOOSE_BROADCAST_CONTENT_TYPE'
);

export const chooseBroadcastContentWorkspace = actionCreator<ArdoqId>(
  '[broadcasts] CHOOSE_BROADCAST_CONTENT_WORKSPACE'
);

export const chooseBroadcastContentComponentType = actionCreator<string>(
  '[broadcasts] CHOOSE_BROADCAST_CONTENT_COMPONENT_TYPE'
);

export const chooseBroadcastContentId = actionCreator<ArdoqId>(
  '[broadcasts] CHOOSE_BROADCAST_CONTENT_ID'
);

export const fetchPredefinedQueryOptions = actionCreator(
  '[broadcasts] FETCH_PREDEFINED_QUERY_OPTIONS'
);

export const openAudienceDialog = actionCreator<BroadcastAudienceType>(
  '[broadcasts] OPEN_AUDIENCE_DIALOG'
);

export const openPredefinedAudienceDialog = actionCreator(
  '[broadcasts] OPEN_PREDEFINED_AUDIENCE_DIALOG'
);

export const openGremlinAudienceDialog = actionCreator(
  '[broadcasts] OPEN_GREMLIN_AUDIENCE_DIALOG'
);

export const openEmailAudienceDialog = actionCreator(
  '[broadcasts] OPEN_EMAIL_AUDIENCE_DIALOG'
);

export const openWorkspaceAudienceDialog = actionCreator(
  '[broadcasts] OPEN_WORKSPACE_AUDIENCE_DIALOG'
);

export const openAudiencePreviewDialog = actionCreator(
  '[broadcasts] OPEN_AUDIENCE_PREVIEW_DIALOG'
);

export const addOrUpdatePredefinedBroadcastAudience = actionCreator<
  PredefinedQueryOption[]
>('[broadcasts] ADD_OR_UPDATE_PREDEFINED_BROADCAST_AUDIENCE');

export const addOrUpdateGremlinBroadcastAudience = actionCreator<ArdoqId>(
  '[broadcasts] ADD_OR_UPDATE_GREMLIN_BROADCAST_AUDIENCE'
);

export const addOrUpdateEmailBroadcastAudience = actionCreator<string[]>(
  '[broadcasts] ADD_OR_UPDATE_EMAIL_BROADCAST_AUDIENCE'
);

export const addOrUpdateWorkspaceBroadcastAudience =
  actionCreator<WorkspaceAudienceData>(
    '[broadcasts] ADD_OR_UPDATE_WORKSPACE_BROADCAST_AUDIENCE'
  );

export const removeAudienceFromBroadcast = actionCreator<BroadcastAudienceType>(
  '[broadcasts] REMOVE_AUDIENCE_FROM_BROADCAST'
);

export const setAudienceStartingPage = actionCreator<AudienceStartingPage>(
  '[broadcasts] SET_AUDIENCE_STARTING_PAGE'
);

export const setAudienceStartingPageViewpoint = actionCreator<ArdoqId | null>(
  '[broadcasts] SET_AUDIENCE_STARTING_PAGE_VIEWPOINT'
);

export const notifyFetchingBroadcasts = actionCreator(
  '[broadcasts] NOTIFY_FETCHING_BROADCASTS'
);

export const fetchBroadcastsError = actionCreator(
  '[broadcasts] FETCH_BROADCASTS_ERROR'
);

export const fetchBroadcastInstances = actionCreator<ArdoqId>(
  '[broadcasts] FETCH_BROADCAST_INSTANCES'
);

export const initBroadcasts = actionCreator('[broadcasts] INIT_BROADCASTS');

export const setBroadcastList = actionCreator<APIBroadcastAttributes[]>(
  '[broadcasts] SET_BROADCAST_LIST'
);

export const setCurrentBroadcast = actionCreator<ArdoqId>(
  '[broadcasts] SET_CURRENT_BROADCAST'
);

export const initFilterConditions = actionCreator<BroadcastFilterCondition[]>(
  '[broadcasts] INIT_FILTER_CONDITIONS'
);

export const notifyIsSavingBroadcast = actionCreator(
  '[broadcasts] NOTIFY_IS_SAVING_BROADCAST'
);

export const notifySavingBroadcastSucceeded =
  actionCreator<APIBroadcastAttributes>(
    '[broadcasts] NOTIFY_SAVING_BROADCAST_SUCCEEDED'
  );

export const notifySavingBroadcastFailed = actionCreator(
  '[broadcasts] NOTIFY_SAVING_BROADCAST_FAILED'
);

export const notifyUpdatingBroadcastSucceeded =
  actionCreator<APIBroadcastAttributes>(
    '[broadcasts] NOTIFY_UPDATING_BROADCAST_SUCCEEDED'
  );

export const notifyDeletingBroadcastSucceeded = actionCreator<ArdoqId>(
  '[broadcasts] NOTIFY_DELETING_BROADCAST_SUCCEEDED'
);

export const initiateCreatingNewBroadcastFromSurvey = actionCreator<ArdoqId>(
  '[broadcasts] INITIATE_CREATING_NEW_BROADCAST_FROM_SURVEY'
);

export const initiateCreatingNewBroadcastFromReport = actionCreator<ArdoqId>(
  '[broadcasts] INITIATE_CREATING_NEW_BROADCAST_FROM_REPORT'
);

export const updateBroadcastName = actionCreator<string>(
  '[broadcasts] UPDATE_BROADCAST_NAME'
);

export const updateMessageSubject = actionCreator<string>(
  '[broadcasts] UPDATE_MESSAGE_SUBJECT'
);

export const updateMessageSender = actionCreator<string>(
  '[broadcasts] UPDATE_MESSAGE_SENDER'
);

export const updateMessageReplyTo = actionCreator<boolean>(
  '[broadcasts] UPDATE_MESSAGE_REPLY_TO'
);

export const updateHideLogo = actionCreator<boolean>(
  '[broadcasts] UPDATE_HIDE_LOGO'
);

export const updateMessageBody = actionCreator<string>(
  '[broadcasts] UPDATE_MESSAGE_BODY'
);

export const updateScheduleRecurrence = actionCreator<boolean>(
  '[broadcasts] UPDATE_SCHEDULE_RECURRENCE'
);

export const chooseBroadcastReminder = actionCreator<number | null>(
  '[broadcasts] CHOOSE_BROADCAST_REMINDER'
);

export const chooseIntervalType = actionCreator<IntervalType>(
  '[broadcasts] CHOOSE_INTERVAL_TYPE'
);

export const chooseIntervalValue = actionCreator<number>(
  '[broadcasts] CHOOSE_INTERVAL_VALUE'
);

export const chooseScheduleStartDate = actionCreator<string | null>(
  '[broadcasts] CHOOSE_SCHEDULE_START_DATE'
);

export const chooseScheduleEndDate = actionCreator<string | null>(
  '[broadcasts] CHOOSE_SCHEDULE_END_DATE'
);

export const openPreviewBroadcastDialog = actionCreator(
  '[broadcasts] OPEN_PREVIEW_BROADCAST_DIALOG'
);

export const getBroadcastPreview = actionCreator<{
  broadcastId: ArdoqId;
  email: string;
}>('[broadcasts] GET_BROADCAST_PREVIEW');

export const setBroadcastPreview = actionCreator<BroadcastPreview | null>(
  '[broadcasts] SET_BROADCAST_PREVIEW'
);

export const setBroadcastPreviewStatus =
  actionCreator<BroadcastPreviewFetchStatus>(
    '[broadcasts] SET_BROADCAST_PREVIEW_FETCH_STATUS'
  );

export const initiateSavingCurrentBroadcast = actionCreator(
  '[broadcasts] INITIATE_SAVING_CURRENT_BROADCAST'
);

export const unpublishBroadcast = actionCreator<ArdoqId>(
  '[broadcasts] UNPUBLISH_BROADCAST'
);

export const showLaunchBroadcastDialog = actionCreator<ArdoqId>(
  '[broadcasts] SHOW_LAUNCH_BROADCAST_DIALOG'
);

export const showLaunchCurrentBroadcastDialog = actionCreator(
  '[broadcasts] SHOW_LAUNCH_CURRENT_BROADCAST_DIALOG'
);

export const launchBroadcast = actionCreator<APIBroadcastAttributes>(
  '[broadcasts] LAUNCH_BROADCAST'
);

export const updateUseCompanyLogo = actionCreator<boolean>(
  '[broadcasts] UPDATE_USE_COMPANY_LOGO'
);

export const fetchViewpointsForComponentTypeName = actionCreator<string>(
  '[broadcasts] FETCH_VIEWPOINTS_FOR_COMPONENT_TYPE'
);

export const updateViewpoints = actionCreator<APIDiscoverViewpointAttributes[]>(
  '[broadcasts] UPDATE_VIEWPOINTS'
);

export const fetchViewpoints = actionCreator('[broadcasts] FETCH_VIEWPOINTS');

export const updateSendOnWeekends = actionCreator<boolean>(
  '[broadcasts] UPDATE_SEND_ON_WEEKENDS'
);
