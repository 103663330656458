import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { IconName } from '@ardoq/icons';
import { Multiselect } from '@ardoq/select';
import { UsersSectionProps } from '../types';
import { AuditLogTestIds } from '@ardoq/audit-log';
import { FilterSections, sectionIconNamesMap } from '../consts';

const UsersSection = ({
  selected,
  options,
  onValueChange,
}: UsersSectionProps) => (
  <SidebarMenuSection
    title={FilterSections.USERS}
    leftIcon={{ name: sectionIconNamesMap[FilterSections.USERS] }}
  >
    <Multiselect
      iconName={IconName.SEARCH}
      label="Filter changes made by"
      placeholder="Search users"
      options={options}
      value={selected}
      onValueChange={userIds => onValueChange(userIds ?? [])}
      dataTestId={AuditLogTestIds.FILTER_USER_SELECT}
    />
  </SidebarMenuSection>
);

export default UsersSection;
