import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { AccountsState } from '../types';
import { updateAccountState } from './actions';

const defaultState: AccountsState = {
  listStatus: 'Initial',
  upsertStatus: 'Initial',
};

const updateAccountReducer = (
  state: AccountsState,
  payload: Partial<AccountsState>
) => {
  return { ...state, ...payload };
};

export const itPediaAccounts$ = persistentReducedStream(
  `itPediaAccounts$`,
  defaultState,
  [reducer(updateAccountState, updateAccountReducer)]
);
