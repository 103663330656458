import {
  APIReferenceType,
  isManualComponentSelection,
  LoadedState,
  Traversal,
} from '@ardoq/api-types';
import type { INode } from '@ardoq/yfiles';
import { getReferenceTypeRepresentationDataFromReferenceType } from 'referenceRepresentationData';
import type { ReferenceTypeRepresentationData } from '@ardoq/graph';
import {
  ExecuteSavedViewpointPayload,
  ExecuteViewpointPayload,
} from './actions';
import { ContextShape } from '@ardoq/data-model';
import { isEmpty } from 'lodash';
import { confirmOpenNewDatasetOrSavedViewpointAndClosePreviousView } from '../viewpointNavigator/confirmOpenNewDatasetOrSavedViewpointAndClosePreviousView';
import { ViewpointBuilderState } from './types';
import { getComponentSelection } from './getComponentSelection';

export const PARENT_REF_TYPE_NAME = 'Is Parent Of';

export const getReferenceTypeRepresentationData = (
  type: APIReferenceType,
  displayLabel: string
): ReferenceTypeRepresentationData =>
  getReferenceTypeRepresentationDataFromReferenceType(type, displayLabel);

export const isContextNode = (node: INode) => node.tag.isContext();

export const isExecuteSavedViewpointPayload = (
  payload: ExecuteViewpointPayload
): payload is ExecuteSavedViewpointPayload => {
  return payload.type === 'EXECUTE_SAVED_VIEWPOINT';
};

export const isAnyWorkspaceOrLoadedStateOpen = (
  loadedStates: LoadedState[],
  context: ContextShape
) => {
  return !isEmpty(loadedStates) || !isEmpty(context.workspacesIds);
};

export const confirmClosingPreviousViewIfOpen = async (
  loadedStates: LoadedState[],
  context: ContextShape,
  origin: 'openNewDataset' | 'openSavedViewpoint'
) => {
  if (!isAnyWorkspaceOrLoadedStateOpen(loadedStates, context)) {
    return true;
  }
  return await confirmOpenNewDatasetOrSavedViewpointAndClosePreviousView({
    origin,
  });
};

/**
 * This is only needed because TS is weird about the componentSelection type,
 * so it makes it difficult to extract the traversal from the state.
 */
export const extractTraversalParamsFromViewpointBuilderState = ({
  searchComponentsState,
  editTraversalsState,
}: ViewpointBuilderState): Traversal => {
  const { paths, filters, pathCollapsingRules, pathMatching } =
    editTraversalsState.stateAsSavableAttributes;
  const traversalBase = { paths, filters, pathCollapsingRules, pathMatching };

  const componentSelection = getComponentSelection(searchComponentsState);
  if (isManualComponentSelection(componentSelection)) {
    return { ...traversalBase, componentSelection };
  }
  return { ...traversalBase, componentSelection };
};
