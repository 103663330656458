import { actionCreator } from '@ardoq/rxbeach';
import { GremlinResult } from './GremlinResults/types';
import { PayloadReturnLocation } from 'search/actions';
import { SearchQuery } from '@ardoq/api-types';

interface PayloadQueryGremlinSearch {
  readonly query: string;
  readonly queryParams?: { [name: string]: any };
}
export const queryGremlinSearch = actionCreator<PayloadQueryGremlinSearch>(
  '[Gremlin] QUERY_GREMLIN_SEARCH'
);

interface PayloadToggleRawResults {
  readonly showRawResults: boolean;
}
export const toggleRawResults = actionCreator<PayloadToggleRawResults>(
  '[Gremlin] TOGGLE_RAW_RESULTS'
);

type PayloadQueryGremlinSearchSuccess = {
  readonly results: GremlinResult[];
  readonly totalResults: number;
};
export const queryGremlinSearchSuccess =
  actionCreator<PayloadQueryGremlinSearchSuccess>(
    '[Gremlin] QUERY_GREMLIN_SEARCH_SUCCESS'
  );

type PayloadQueryGremlinSearchError = {
  readonly syntaxError: string | null;
};
export const queryGremlinSearchError =
  actionCreator<PayloadQueryGremlinSearchError>(
    '[Gremlin] QUERY_GREMLIN_SEARCH_ERROR'
  );

interface PayloadQueryGremlinSearchWarning {
  readonly searchWarning: string | null;
}
export const queryGremlinSearchWarning =
  actionCreator<PayloadQueryGremlinSearchWarning>(
    '[Gremlin] QUERY_GREMLIN_SEARCH_WARNING'
  );

export const resetGremlinResults = actionCreator(
  '[Gremlin] RESET_GREMLIN_RESULTS'
);

interface PayloadExportExcelGremlin {
  readonly query: SearchQuery;
}
export const exportExcelGremlin = actionCreator<PayloadExportExcelGremlin>(
  '[Gremlin] EXPORT_EXCEL_GREMLIN'
);

export const updateResultsReturnLocation = actionCreator<PayloadReturnLocation>(
  '[Gremlin] UPDATE_RESULTS_RETURN_LOCATION'
);
