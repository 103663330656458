import { spacing } from '@ardoq/design-tokens';
import styled, { css } from 'styled-components';
import { OnClick } from 'aqTypes';

type BroadcastNameProps = {
  broadcastName: string | null;
  hasNewJourneyFeature: boolean;
  onClick: OnClick;
};

const Wrapper = styled.span<{
  $broadcastName: string | null;
  $hasNewJourneyFeature: boolean;
}>`
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: ${({ $broadcastName, $hasNewJourneyFeature }) =>
    $broadcastName || $hasNewJourneyFeature ? 'inherit' : '0.5'};

  ${({ $hasNewJourneyFeature }) =>
    !$hasNewJourneyFeature &&
    css`
      margin-left: ${spacing.s8};
    `}

  max-width: 600px;
  @media (max-width: 1280px) {
    max-width: 391px;
  }
  @media (max-width: 1024px) {
    width: 280px;
  }
`;

const BroadcastName = ({
  broadcastName,
  hasNewJourneyFeature,
  onClick,
}: BroadcastNameProps) => (
  <Wrapper
    $broadcastName={broadcastName}
    $hasNewJourneyFeature={hasNewJourneyFeature}
    onClick={onClick}
    data-tooltip-text="Rename"
  >
    {broadcastName ?? 'Untitled Broadcast'}
  </Wrapper>
);

export default BroadcastName;
