import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { AccessControlRoute, AccessControlTabs } from './types';
import { setAccessControlTab } from './actions';
import { accessControlNavigationOperations } from './accessControlPageNavigationOperations';

export const defaultState: AccessControlRoute = {
  accessControlTab: AccessControlTabs.MEMBERS,
};

const handleNavigateToAccessControlPage = (
  _: AccessControlRoute,
  newState: AccessControlRoute
): AccessControlRoute => {
  if (accessControlNavigationOperations.isGroupDetailsPage(newState)) {
    return {
      ...newState,
      groupActiveTabId: newState.groupActiveTabId ?? 'users',
    };
  }
  if (accessControlNavigationOperations.isUserProfilePage(newState)) {
    return {
      ...newState,
      userProfileActiveTabId: newState.userProfileActiveTabId ?? 'roles',
    };
  }

  return newState;
};

const accessControlPageNavigation$ = persistentReducedStream(
  'accessControlPageNavigation$',
  defaultState,
  [
    reducer<AccessControlRoute, AccessControlRoute>(
      setAccessControlTab,
      handleNavigateToAccessControlPage
    ),
  ]
);

export default accessControlPageNavigation$;
