import { ViewIds } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';

/**
 * Users with the feature flag QUICK_START_RESTRICT_AVAILABLE_VISUALIZATIONS set
 * only have access to a subset of visualizations.
 *
 * @see https://ardoqcom.atlassian.net/browse/ARD-24034
 */
export const quickStartViews = new Set<ViewIds>([
  ViewIds.DEPENDENCY_MAP_2,
  ViewIds.BLOCK_DIAGRAM,
  ViewIds.TABLEVIEW,
  ViewIds.RELATIONSHIPS_3,
  ViewIds.COMPONENT_MATRIX,
  ViewIds.TIMELINE,
  ViewIds.PAGESVIEW,
  ViewIds.BUBBLE,
  ViewIds.DEPMATRIX,
  ViewIds.MODERNIZED_BLOCK_DIAGRAM,
]);

export const isViewRestrictedByQuickStart = (id: ViewIds): boolean =>
  hasFeature(Features.QUICK_START_RESTRICT_AVAILABLE_VISUALIZATIONS) &&
  !quickStartViews.has(id);
