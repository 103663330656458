import {
  ExtractPayload,
  dispatchAction,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { MarkNotificationPayload } from '@ardoq/api';
import {
  NotificationsFilterType,
  InAppNotificationsState,
  ChecksCounter,
} from './types';
import {
  closeInAppNotificationsList,
  filterSelected,
  getInAppNotificationsList,
  getInAppNotificationsListSuccess,
  setReadStatus,
  submitMarkNotificationsSuccess,
  toggleCanDisplayToast,
  toggleInAppNotificationsList,
  toggleShowMore,
} from './actions';
import { NotificationCardProps } from '@ardoq/in-app-notifications';
import { websocket$ } from 'sync/websocket$';
import { isWebSocketNotification } from 'sync/types';
import { filter, map } from 'rxjs';
import { apiDataToNotificationCard } from './routines';
import { ExcludeFalsy } from '@ardoq/common-helpers';

export const defaultState: InAppNotificationsState = {
  showList: false,
  notifications: [],
  filters: {
    [NotificationsFilterType.UNREAD]: {
      label: 'Unread',
      isActive: true,
      showCounter: true,
      onClick: () => {
        dispatchAction(filterSelected(NotificationsFilterType.UNREAD));
      },
    },
    [NotificationsFilterType.ALL]: {
      label: 'View all',
      isActive: false,
      onClick: () => {
        dispatchAction(filterSelected(NotificationsFilterType.ALL));
      },
    },
  },
  filteringBy: NotificationsFilterType.UNREAD,
  isPartial: true,
  checksCounter: ChecksCounter.ZERO,
  canDisplayToast: true,
  isLoading: true,
};

const handleToggleInAppNotificationsList = (
  state: InAppNotificationsState
) => ({
  ...state,
  showList: !state.showList,
});

const handleGetInAppNotificationsListSuccess = (
  { checksCounter, ...state }: InAppNotificationsState,
  notifications: NotificationCardProps[]
) => ({
  ...state,
  isLoading: false,
  notifications,
  checksCounter:
    checksCounter < ChecksCounter.MORE ? checksCounter + 1 : checksCounter,
});

const handleAddNotification = (
  state: InAppNotificationsState,
  notification: NotificationCardProps
) => ({
  ...state,
  notifications: state.notifications.concat(notification),
});

const handlefilterSelected = (
  state: InAppNotificationsState,
  filter: NotificationsFilterType
) => {
  const newFilters = { ...state.filters };
  const oppositeFilter =
    filter === NotificationsFilterType.ALL
      ? NotificationsFilterType.UNREAD
      : NotificationsFilterType.ALL;

  newFilters[filter] = {
    ...newFilters[filter],
    isActive: true,
  };

  newFilters[oppositeFilter] = {
    ...newFilters[oppositeFilter],
    isActive: false,
  };

  return {
    ...state,
    filteringBy: filter,
    filters: newFilters,
  };
};

const handleToggleShowMore = (state: InAppNotificationsState) => ({
  ...state,
  isPartial: !state.isPartial,
});

const handleToggleCanDisplayToast = (
  state: InAppNotificationsState,
  canDisplayToast: boolean
) => ({
  ...state,
  canDisplayToast,
});

const handGetInAppNotificationsList = (state: InAppNotificationsState) => ({
  ...state,
  isLoading: true,
});

const handleSubmitMarkNotificationsSuccess = (
  { notifications, ...state }: InAppNotificationsState,
  markNotificationPayload: MarkNotificationPayload[]
) => {
  const dismissedNotificationIds = markNotificationPayload
    .filter(({ status }) => status === 'dismissed')
    .map(({ notificationId }) => notificationId);
  return {
    ...state,
    notifications: notifications.filter(
      notification => !dismissedNotificationIds.includes(notification._id)
    ),
  };
};

const handleReadStatusChanged = (
  state: InAppNotificationsState,
  { id }: ExtractPayload<typeof setReadStatus>
) => ({
  ...state,
  notifications: state.notifications.map(notification =>
    notification._id === id
      ? {
          ...notification,
          isUnread: false,
        }
      : notification
  ),
});

const handleCloseInAppNotificationsList = (state: InAppNotificationsState) => ({
  ...state,
  showList: false,
});

const webSocketNotifications$ = websocket$.pipe(
  filter(isWebSocketNotification),
  map(({ data }) => data),
  map(apiDataToNotificationCard),
  filter(ExcludeFalsy)
);

export const reducers = [
  reducer(toggleInAppNotificationsList, handleToggleInAppNotificationsList),
  reducer(
    getInAppNotificationsListSuccess,
    handleGetInAppNotificationsListSuccess
  ),
  reducer(filterSelected, handlefilterSelected),
  reducer(toggleShowMore, handleToggleShowMore),
  reducer(toggleCanDisplayToast, handleToggleCanDisplayToast),
  reducer(getInAppNotificationsList, handGetInAppNotificationsList),
  reducer(submitMarkNotificationsSuccess, handleSubmitMarkNotificationsSuccess),
  reducer(setReadStatus, handleReadStatusChanged),
  reducer(closeInAppNotificationsList, handleCloseInAppNotificationsList),
  streamReducer(webSocketNotifications$, handleAddNotification),
];
