import { colors, spacing } from '@ardoq/design-tokens';
import styled from 'styled-components';

export const DetailsColumn = styled.div<{ $flexGrow?: number }>`
  flex-grow: ${({ $flexGrow }) => $flexGrow};
  padding: 0 ${spacing.s32};
  &:not(:first-child) {
    border-left: 1px solid ${colors.borderSubtle00};
  }
`;
