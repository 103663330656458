import { TransferConfig } from '@ardoq/api-types/integrations';
import { UNIFIED_INTEGRATION_IDS } from 'integrations/unified/constants';

// TODO: https://ardoqcom.atlassian.net/browse/ARD-18486
// not every integration will need the transfer configs
// we only need to fetch transfer configs for the integrations that uses it
export const INTEGRATIONS_WITH_TRANSFER_CONFIGS = [
  'aws-v3',
  'azure-v3',
  'excel-v3',
  'servicenow-v3',
  'microsoft-entra-id',
  // Change this in case unified integrations have exception of not having transfer configs
  ...UNIFIED_INTEGRATION_IDS,
];

export const DEFAULT_TRANSFER_CONFIG: TransferConfig = {
  options: {
    'duplicate-components': 'warn',
    'missing-component-names': 'warn',
    'missing-referenced-components': 'error',
  },
  name: '',
  sources: [],
  tables: [],
  sourceConfig: null,
};
