import { FlexBox } from '@ardoq/layout';
import { ButtonType, SecondaryButton } from '@ardoq/button';
import { Island, IslandHeader } from '@ardoq/page-layout';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { getGroupActions } from '../GroupsOverView/groupsTableColumns';
import { ChevronLeftIcon } from '@ardoq/icons';
import { Tab, TabProps, TabsGroup } from '@ardoq/tabs';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { Features, hasFeature } from '@ardoq/features';
import { GroupUsersList } from './components/GroupUsersList';
import { GroupDetailsPageProps } from './types';
import { useState } from 'react';
import { TextOverflow } from '@ardoq/popovers';

export const GroupDetailsPage = (props: GroupDetailsPageProps) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const { group, commands, groupActiveTabId } = props;
  const tabs: TabProps[] = [
    {
      label: 'Members',
      tabId: 'users',
      tag: group?.users.length.toString(),
    } satisfies TabProps,
    hasFeature(Features.PERMISSION_ZONES_INTERNAL)
      ? {
          label: 'Assets',
          tabId: 'assets',
        }
      : undefined,
  ].filter(ExcludeFalsy);

  return (
    <FlexBox flexDirection="column" gap="large" height="full">
      <div>
        <SecondaryButton onClick={() => commands.goToGroup('')}>
          <ChevronLeftIcon />
          Go back to groups
        </SecondaryButton>
      </div>

      <Island
        maxWidth="100%"
        title={
          <TextOverflow
            lineClamp={1}
            style={{ flexGrow: 1, maxWidth: '540px' }}
          >
            {group?.name}
          </TextOverflow>
        }
        rightContent={
          <ButtonWithDropdown
            buttonType={ButtonType.SECONDARY}
            options={getGroupActions(props, group)}
          >
            Manage Group
          </ButtonWithDropdown>
        }
      />
      <Island
        bodySpacing="large"
        maxWidth="100%"
        flex={1}
        header={
          <IslandHeader skipVerticalPadding>
            <TabsGroup
              activeTabId={groupActiveTabId}
              onTabChange={tabId => {
                commands.changeGroupDetailsTab(group, tabId);
              }}
            >
              {tabs.map(tab => (
                <Tab key={tab.tabId} {...tab} />
              ))}
            </TabsGroup>
          </IslandHeader>
        }
      >
        {groupActiveTabId === 'users' && (
          <GroupUsersList
            {...props}
            setSearchPhrase={setSearchPhrase}
            searchPhrase={searchPhrase}
          />
        )}
        {groupActiveTabId === 'assets' && <> Coming soon...</>}
      </Island>
    </FlexBox>
  );
};
