import { actionCreator } from '@ardoq/rxbeach';
import { Vpc } from '@ardoq/api-types/integrations';
import { AsyncStatus } from 'integrations/common/types/api';

const NAMESPACE = '[integrations aws] Vpcs';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export type FetchVpcsSuccessPayload = {
  connectionId: string;
  region: string;
  vpcs: Vpc[];
};

export const fetchVpcsSuccess = actionCreator<FetchVpcsSuccessPayload>(
  getActionName('FETCH_VPCS_SUCCESS')
);

export type SetAsyncStatusPayload = {
  asyncStatus: AsyncStatus;
  connectionId: string;
  region: string;
};
export const setAsyncStatus = actionCreator<SetAsyncStatusPayload>(
  getActionName('SET_VPCS_ASYNC_STATUS')
);

export type ChangeVpcsSelectionPayload = {
  selectedVpcIds: string[];
};
export const changeVpcsSelection = actionCreator<ChangeVpcsSelectionPayload>(
  getActionName('CHANGE_VPCS_SELECTION')
);

type GetVpcsPayload = {
  connectionId: string;
  region: string;
};

export const getVpcs = actionCreator<GetVpcsPayload>(getActionName('GET_VPCS'));

type UnselectUnavailableVpcsPayload = {
  connectionIds: string[];
  regions: string[];
};

export const unselectUnavailableVpcs =
  actionCreator<UnselectUnavailableVpcsPayload>(
    getActionName('UNSELECT_UNAVAILABLE_VPCS')
  );
