import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  addReferenceType,
  confirmDeleteReferenceType,
  editReferenceType,
} from './actions';
import { tap, withLatestFrom } from 'rxjs/operators';
import { modelInterface } from 'modelInterface/models/modelInterface';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { showRightPane } from 'appContainer/actions';
import { context$ } from 'streams/context/context$';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import surveys$ from '../../streams/surveys/surveys$';
import { confirmDeleteAsset } from '../../components/DeleteAssetModal/DeleteAssetModal';
import { DeleteType } from '../../components/DeleteAssetModal/types';
import { isReferenceQuestion } from 'surveyAdmin/questions/questionPredicates';

const handleAddReferenceType = routine(
  ofType(addReferenceType),
  tap(() => {
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.CREATE_REFERENCE_TYPE,
      })
    );
  })
);

const handleEditReferenceType = routine(
  ofType(editReferenceType),
  extractPayload(),
  tap(referenceTypeId => {
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.REFERENCE_TYPE_EDITOR,
        referenceType: { id: referenceTypeId },
      })
    );
  })
);

const handleConfirmDeleteReferenceType = routine(
  ofType(confirmDeleteReferenceType),
  extractPayload(),
  withLatestFrom(context$, surveys$),
  tap(async ([referenceTypeId, { workspaceId }, { list: surveys }]) => {
    const modelId = workspaceInterface.getWorkspaceModelId(workspaceId);

    if (!modelId) {
      return;
    }

    const referenceType = modelInterface.getReferenceType(
      modelId,
      referenceTypeId
    );

    if (!referenceType) {
      return;
    }

    const affectedSurveys =
      surveys?.filter(survey =>
        survey.questions.find(
          question =>
            isReferenceQuestion(question) &&
            question.properties.referenceTypeName === referenceType.name &&
            question.properties.workspaceId === workspaceId
        )
      ) ?? [];

    const approved = await confirmDeleteAsset({
      deleteType: DeleteType.REFERENCE_TYPE,
      name: referenceType.name,
      id: referenceTypeId,
      affectedSurveys,
    });

    if (approved) {
      await modelInterface.removeReferenceType(modelId, referenceTypeId);
      dispatchAction(
        showRightPane({
          type: GetContentOptionsType.MANAGE_REFERENCE_TYPES,
        })
      );
    }
  })
);

export default collectRoutines(
  handleAddReferenceType,
  handleEditReferenceType,
  handleConfirmDeleteReferenceType
);
