import {
  AdditionalContentProps,
  MergeStep,
} from 'components/DiffMergeTable/types';
import styled from 'styled-components';
import { AdditionalContentWrapper } from 'components/DiffMergeTable/atoms';
import EntityPropertyValueList from 'components/DiffMergeTable/EntityPropertyValueList';
import { ArdoqId } from '@ardoq/api-types';
import { s16 } from '@ardoq/design-tokens';
import ExpandableEntityList from 'components/DiffMergeTable/AdditionalContent/ExpandableEntityList';

const PropertyListWrapper = styled(AdditionalContentWrapper)`
  padding-left: 56px;
  margin-top: ${s16};
`;

const AdditionalContent = ({
  dataSourceItem,
  graphics,
  branch,
  componentIds = [],
  referenceIds = [],
  entityType,
  mergeStep,
  ...rest
}: AdditionalContentProps & {
  componentIds?: ArdoqId[];
  referenceIds?: ArdoqId[];
  mergeStep: MergeStep;
}) =>
  !componentIds.length && !referenceIds.length ? (
    <PropertyListWrapper>
      <EntityPropertyValueList
        {...rest}
        entityType={entityType}
        graphics={graphics}
        dataSourceItem={dataSourceItem}
        branch={branch}
      />
    </PropertyListWrapper>
  ) : (
    <ExpandableEntityList
      componentIds={componentIds}
      referenceIds={referenceIds}
      enhancedScopeData={dataSourceItem.enhancedDiffContextData[branch]}
      mergeStep={mergeStep}
      graphics={graphics}
    />
  );

export default AdditionalContent;
