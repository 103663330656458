import { action$, reducer, reduceState } from '@ardoq/rxbeach';
import {
  hideScopeRelatedNavigator,
  showScopeRelatedNavigator,
} from './actions';

interface MainAppModuleSidebarState {
  showScopeRelated: boolean;
}

const defaultState: MainAppModuleSidebarState = {
  showScopeRelated: false,
};

const handleShowScopeRelatedNavigator = () => ({
  showScopeRelated: true,
});

const handleHideScopeRelatedNavigator = () => ({ showScopeRelated: false });

const reducers = [
  reducer(showScopeRelatedNavigator, handleShowScopeRelatedNavigator),
  reducer(hideScopeRelatedNavigator, handleHideScopeRelatedNavigator),
];

export const mainAppModuleSidebar$ = action$.pipe(
  reduceState<MainAppModuleSidebarState>(
    'mainAppModuleSidebar$',
    defaultState,
    reducers
  )
);
