import SurveyOverview from './SurveyOverview/SurveyOverview';
import SurveyEditor from './SurveyEditor/SurveyEditor';
import { SurveyAdminMode } from './navigation/types';
import {
  APISurveyAttributes,
  ArdoqId,
  PermissionGroup,
} from '@ardoq/api-types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import surveyAdmin$ from './streams/surveyAdmin$';
import { selectSurveyMode, navigateToSurveyForm } from './navigation/actions';
import styled from 'styled-components';
import { UnauthorizedAccessPage } from '@ardoq/manage-resource-permissions';
import { surveyAccessControlInterface } from 'resourcePermissions/accessControlHelpers/surveys';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import ChangeApprovalPage from './ChangeApproval/ChangeApprovalPage';
import { Header1 } from '@ardoq/typography';
import { emptySurvey } from './surveyUtil';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import ModuleContainer from 'appContainer/ModuleContainer';
import { Observable, combineLatest, map } from 'rxjs';
import currentUser$ from 'streams/currentUser/currentUser$';
import surveys$ from 'streams/surveys/surveys$';
import { SurveyAdminStreamState } from './streams/types';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';
import permissionGroup$ from 'admin/accessControl/PermissionGroups/streams/permissionGroups$';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export type SurveysContainerProps = {
  surveys: APISurveyAttributes[];
  organizationLabel: string;
  selectedSurvey: APISurveyAttributes | undefined;
  permissionContext: PermissionContext;
  groupsById: Record<ArdoqId, PermissionGroup>;
} & SurveyAdminStreamState;

const returnToOverview = () =>
  dispatchAction(selectSurveyMode({ mode: SurveyAdminMode.OVERVIEW }));

const SurveysContainer = ({
  surveys = [],
  surveyId,
  selectedSurvey,
  permissionContext,
  ...props
}: SurveysContainerProps) => {
  const isCreatingNewSurvey = props.mode === SurveyAdminMode.NEW_SURVEY;

  const isEditingSurvey =
    props.mode === SurveyAdminMode.EDIT_SURVEY && selectedSurvey;

  const isViewingChangeApproval =
    props.mode === SurveyAdminMode.CHANGE_APPROVAL && selectedSurvey;

  const shouldDisplaySurveyEditor = isCreatingNewSurvey || isEditingSurvey;

  const hasCorrectAccess =
    props.mode === SurveyAdminMode.OVERVIEW ||
    (isEditingSurvey &&
      surveyAccessControlInterface.canEditSurvey(surveyId || undefined) &&
      (!selectedSurvey.workspace ||
        workspaceAccessControlInterface.canAdminWorkspace(
          permissionContext,
          selectedSurvey.workspace,
          null
        ))) ||
    (isCreatingNewSurvey && surveyAccessControlInterface.canCreateSurvey()) ||
    (isViewingChangeApproval && currentUserInterface.currentUserIsOrgAdmin());

  if (!hasCorrectAccess) {
    if (
      isEditingSurvey &&
      surveyAccessControlInterface.canSubmitSurvey(surveyId ?? '')
    ) {
      return (
        <UnauthorizedAccessPage
          message={
            'You have insufficient access to edit this survey. If you wish to answer it, you can do so here'
          }
          linkLabel="Open Survey"
          link={`/surveys/survey/${surveyId}`}
        />
      );
    }
    return (
      <UnauthorizedAccessPage
        pageName={
          props.mode === SurveyAdminMode.NEW_SURVEY
            ? 'Survey Builder'
            : "this survey, or this survey doesn't exist"
        }
      />
    );
  }

  return (
    <>
      {props.mode === SurveyAdminMode.OVERVIEW && (
        <ModuleContainer $overflow="hidden">
          <ErrorBoundary logError={logError}>
            <SurveyOverview
              surveys={surveys}
              addSurvey={() => dispatchAction(navigateToSurveyForm(null))}
              surveysWithPendingApprovals={props.surveysWithPendingApprovals}
              showSurveySettingsSidebar={props.surveySettings.isSidebarOpen}
            />
          </ErrorBoundary>
        </ModuleContainer>
      )}
      {shouldDisplaySurveyEditor && (
        <SurveyEditor
          organizationLabel={props.organizationLabel}
          survey={
            isCreatingNewSurvey || !selectedSurvey
              ? emptySurvey()
              : selectedSurvey
          }
          returnToOverview={returnToOverview}
          folderId={props.folderId}
          groupsById={props.groupsById}
        />
      )}
      {isViewingChangeApproval && (
        <ChangeApprovalPage selectedSurvey={selectedSurvey} />
      )}
      {[SurveyAdminMode.EDIT_SURVEY].includes(props.mode) &&
        !selectedSurvey && (
          <Wrapper>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Header1>Survey not found - 404</Header1>
            </div>
          </Wrapper>
        )}
    </>
  );
};

const surveysContainer$: Observable<SurveysContainerProps> = combineLatest([
  surveys$,
  currentUser$,
  surveyAdmin$,
  currentUserPermissionContext$,
  permissionGroup$,
]).pipe(
  map(
    ([
      surveys,
      currentUser,
      surveyAdminState,
      permissionContext,
      { groupsById },
    ]) => {
      return {
        ...surveyAdminState,
        organizationLabel: currentUser?.organization?.label,
        surveys: surveys.list,
        selectedSurvey: surveys.byId[surveyAdminState.surveyId ?? ''],
        permissionContext,
        groupsById,
      };
    }
  )
);

export default connect(SurveysContainer, surveysContainer$);
