import { SearchOverview } from './SearchOverview';
import storedQueries$ from 'streams/queries/storedQueries$';
import { connect } from '@ardoq/rxbeach';
import { SearchBackend, SearchType } from '@ardoq/api-types';
import { map, withLatestFrom } from 'rxjs/operators';
import { SearchPane, SearchPaneToLabel } from 'search/SearchTabContainer/types';
import searchTab$ from 'search/SearchTabContainer/searchTab$';

export default connect(
  SearchOverview,
  storedQueries$.pipe(
    withLatestFrom(searchTab$),
    map(([{ storedQueries }, { searchOverviewPageDict }]) => ({
      storedQueries: storedQueries
        .filter(
          ({ type, backend }) =>
            type === SearchType.QUERY &&
            backend === SearchBackend.ADVANCED_SEARCH
        )
        .map(query => ({
          ...query,
          hasDeleteOption: true,
          hasCopyOption: true,
          typeLabel: SearchPaneToLabel[query.backend],
        })),
      overviewType: SearchPane.SEARCH_OVERVIEW,
      searchOverviewPage: searchOverviewPageDict[SearchPane.SEARCH_OVERVIEW],
    }))
  )
);
