import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { requestSaveViews } from './actions';
import {
  distinctUntilChanged,
  filter,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { showSelectViewsDialog } from './actions';
import { MAIN_PANE_LEFT } from 'streams/views/mainContent/types';
import {
  setActiveMainTabLeft,
  setActiveMainTabRight,
} from 'streams/views/mainContent/actions';
import { ViewIds } from '@ardoq/api-types';
import { context$ } from 'streams/context/context$';
import tabOptions$ from './tabOptions$';
import TabView from 'views/tabView';
import SelectViews from 'views/selectViews/selectViews';
import { availableViews$ } from 'views/availableViews$';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import {
  leftTabOptions$,
  rightTabOptions$,
} from './getTabOptionsStreamWithLocation';
import { isEqual } from 'lodash';
import { updateViewSettings } from '@ardoq/view-settings';
import { settingsBarConsts } from '@ardoq/settings-bar';
import { leftPaneRef$ } from './leftPaneRef$';
import { rightPaneRef$ } from './rightPaneRef$';
import fscreen from 'fscreen';
import { trackStreamEvent } from 'tracking/tracking';

const handleShowSelectViewDialog = routine(
  ofType(showSelectViewsDialog),
  extractPayload(),
  withLatestFrom(
    context$,
    availableViews$,
    tabOptions$,
    leftTabOptions$,
    rightTabOptions$
  ),
  trackStreamEvent(() => ({
    eventName: 'openedLegacyView',
    metadata: { legacyViewName: 'selectViewsToBeUsedInWorkspaceModal' },
  })),
  tap(
    async ([
      { location },
      { workspaceId },
      { views },
      { tabs },
      { activeTabId: activeTabIdLeft },
      { activeTabId: activeTabIdRight },
    ]) => {
      const eligibleViews = views
        .map(metaInfo => {
          const view = new TabView(metaInfo);
          return {
            id: view.id,
            view,
            imageUrl: view.getExampleImageLink() ?? '',
            metaInfo,
          };
        })
        .filter(
          ({ imageUrl, metaInfo: { isHiddenInViewSelect } }) =>
            imageUrl && !isHiddenInViewSelect
        );

      const initViews = tabs.map(({ id }) => id);

      const updatedViews = await SelectViews({
        initViews,
        eligibleViews,
      });

      if (activeTabIdLeft && !updatedViews.includes(activeTabIdLeft)) {
        dispatchAction(
          setActiveMainTabLeft({
            activeTabId: ViewIds.PAGESVIEW,
          })
        );
      }

      if (activeTabIdRight && !updatedViews.includes(activeTabIdRight)) {
        dispatchAction(
          setActiveMainTabRight({
            activeTabId: ViewIds.PAGESVIEW,
          })
        );
      }

      await workspaceInterface.save(workspaceId, { views: updatedViews });

      const newViewIds = updatedViews.filter(
        viewId => !initViews.includes(viewId)
      );

      if (newViewIds.length === 1) {
        dispatchAction(
          (location === MAIN_PANE_LEFT
            ? setActiveMainTabLeft
            : setActiveMainTabRight)({
            activeTabId: newViewIds[0],
          })
        );
      }
    }
  )
);

const handleRequestSaveViews = routine(
  ofType(requestSaveViews),
  extractPayload(),
  distinctUntilChanged(isEqual),
  tap(({ workspaceId, views }) => {
    workspaceInterface.save(workspaceId, { views });
  })
);

const isFullscreenSetting = ({
  payload: { settings },
}: ReturnType<typeof updateViewSettings>) =>
  settingsBarConsts.FULLSCREEN_KEY in settings;

const handleFullscreenRequest = routine(
  ofType(updateViewSettings),
  filter(isFullscreenSetting),
  extractPayload(),
  withLatestFrom(
    leftPaneRef$,
    rightPaneRef$,
    leftTabOptions$,
    rightTabOptions$
  ),
  tap(
    async ([
      {
        settings: { fullScreen },
        viewId,
      },
      leftPaneRef,
      rightPaneRef,
      { activeTabId: activeTabIdLeft },
      { activeTabId: activeTabIdRight },
    ]) => {
      if (fscreen.fullscreenElement) {
        if (!fullScreen) {
          fscreen.exitFullscreen();
        }

        return;
      }

      if (!fullScreen) {
        return;
      }

      const paneRef =
        viewId === activeTabIdLeft
          ? leftPaneRef
          : viewId === activeTabIdRight
            ? rightPaneRef
            : null;

      if (!paneRef?.current) return;

      const fullscreenChangeHandler = () => {
        if (!fscreen.fullscreenElement) {
          fscreen.removeEventListener(
            'fullscreenchange',
            fullscreenChangeHandler
          );
          dispatchAction(
            updateViewSettings({
              viewId,
              settings: { [settingsBarConsts.FULLSCREEN_KEY]: false },
              persistent: false,
            })
          );
        }
      };
      await fscreen.requestFullscreen(paneRef.current);
      fscreen.addEventListener('fullscreenchange', fullscreenChangeHandler);
    }
  )
);
export default collectRoutines(
  handleShowSelectViewDialog,
  handleRequestSaveViews,
  handleFullscreenRequest
);
