import {
  notifySearchOverviewPageNumberChange,
  selectSearchPane,
  setQueryEditorIsOpenInModal,
} from './actions';
import type { SearchTabStateShape } from './types';
import { type ExtractPayload, reducer } from '@ardoq/rxbeach';

const handleSelectSearchTab = (
  currentState: SearchTabStateShape,
  { searchPane: selectedPane }: ExtractPayload<typeof selectSearchPane>
) => ({
  ...currentState,
  selectedPane,
});

const handleSearchOverviewPageChange = (
  currentState: SearchTabStateShape,
  {
    searchOverviewPage,
    searchOverviewType,
  }: ExtractPayload<typeof notifySearchOverviewPageNumberChange>
) => ({
  ...currentState,
  searchOverviewPageDict: {
    ...currentState.searchOverviewPageDict,
    [searchOverviewType]: searchOverviewPage,
  },
});

const handleSetQueryEditorIsOpenInModal = (
  currentState: SearchTabStateShape,
  isOpenInModal: boolean
) => ({
  ...currentState,
  isOpenInModal,
});

const reducers = [
  reducer(selectSearchPane, handleSelectSearchTab),
  reducer(notifySearchOverviewPageNumberChange, handleSearchOverviewPageChange),
  reducer(setQueryEditorIsOpenInModal, handleSetQueryEditorIsOpenInModal),
];

export default reducers;
