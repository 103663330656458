import { map } from 'rxjs/operators';
import StreamRouter from './StreamRouter';
import { AppModules } from 'appContainer/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { requestShowAppModule } from 'appContainer/actions';
import selectedModule$ from 'appContainer/selectedModule$';
import { combineLatest, EMPTY } from 'rxjs';
import { AppRouterState } from './appRouterTypes';
import searchRoute from 'search/searchRoute';
import reportRoute from 'report/navigation/reportRoute';
import metamodelRoute from 'metamodel/navigation/metamodelRoute';
import { Features, hasFeature } from '@ardoq/features';
import surveyAdminRoute from 'surveyAdmin/navigation/surveyAdminRoute';
import integrationRoute from 'integrations/integrationRoute';
import viewDigestRoute from './viewDigestRoute';
import workspaceModuleRoute from './workspaceModuleRoute';
import scenarioRoute from './scenarioRoute';
import broadcastsRoute from 'broadcasts/navigation/broadcastsRoute';
import viewpointsRoute from 'viewpoints/navigation/viewpointsRoute';
import { dashboardModuleRoute } from '../dashboard/navigation/dashboardModuleRoute';
import manageOrganizationRoute from 'admin/manageOrganization/navigation/manageOrganizationRoute';
import UserSettingsRoute from 'admin/user/userSettingsRoute';
import traversalRoute from 'traversals/navigation/traversalRoute';
import { auditLogRoute } from 'auditLog/auditLogRoute';
import subdivisionRoute from 'subdivisionEditor/navigation/subdivisionRoute';
import useCasesRoute from 'useCases/useCasesRoute';
import componentOverviewPageRoute from 'appContainer/componentOverviewPage/componentOverviewPageRoute';
import analyticsRoute from 'appContainer/analytics/analyticsRoute';
import accessControlPageRoute from 'admin/accessControl/navigation/accessControlPageRoute';
import { assetsRoute } from 'assets/navigation/routes';
import { inventoryModuleRoute } from 'inventory/inventoryModuleRoute';
import { emptyLocation, Location } from './declarative/location';
import { streamRoute } from './declarative/streamRouterAdapter/buildRouter';
import * as OrganizationMetamodelRoutes from 'organizationMetamodel/organizationMetamodelRoutes';
import externalDocumentRoute from 'externalDocuments/externalDocumentRoute';

const appRouter = new StreamRouter<AppRouterState>();

const registerSimpleAppModuleRouteConfig = (
  title: string,
  routePath: string,
  appModule: AppModules,
  hasPermission: () => boolean
) =>
  appRouter.registerRouteConfig({
    doesLocationMatch: ({ path }) => new RegExp(`\\${routePath}/?`).test(path),
    locationToRouterState: () => ({
      appModule,
    }),
    doesRouterStateMatch: routerState => routerState.appModule === appModule,
    routerStateToLocation: () => ({
      path: routePath,
      title,
    }),
    setApplicationStateFromRoute: () => {
      if (hasPermission()) {
        dispatchAction(requestShowAppModule({ selectedModule: appModule }));
      } else {
        dispatchAction(
          requestShowAppModule({ selectedModule: AppModules.HOME })
        );
      }
    },
    getPartialRouterStateStream: () => EMPTY,
  });

registerSimpleAppModuleRouteConfig(
  'Home',
  '/home',
  AppModules.HOME,
  () => true
);

registerSimpleAppModuleRouteConfig(
  'Presentations',
  '/presentation-admin',
  AppModules.PRESENTATIONS,
  () => hasFeature(Features.PRESENTATIONS)
);

// Legacy routes
appRouter.registerRoute(viewDigestRoute);

appRouter.registerRoute(workspaceModuleRoute);

// /view/:viewId/scenario/:id
appRouter.registerRoute(scenarioRoute);

// /report/...
appRouter.registerRoute(reportRoute);

// /search/...
appRouter.registerRoute(searchRoute);

// /new-dashboard/...
appRouter.registerRoute(dashboardModuleRoute);

// /metamodel/...
appRouter.registerRoute(metamodelRoute);

// /external-document/...
appRouter.registerRoute(externalDocumentRoute);

// /organization-metamodel/...
const declerativeOrganizationMetamodelRoute = streamRoute<
  typeof OrganizationMetamodelRoutes.location$.routeTree
>(OrganizationMetamodelRoutes.location$, AppModules.ORGANIZATION_METAMODEL);

appRouter.registerRoute(declerativeOrganizationMetamodelRoute);

// /survey-admin/...
appRouter.registerRoute(surveyAdminRoute);

// /integrations/...
appRouter.registerRoute(integrationRoute);

// /broadcasts/...
appRouter.registerRoute(broadcastsRoute);

// /viewpoints/...
appRouter.registerRoute(viewpointsRoute);

// /manage-organization/...
appRouter.registerRoute(manageOrganizationRoute);

// /access-control/...
appRouter.registerRoute(accessControlPageRoute);

// /account-preferences/...
appRouter.registerRoute(UserSettingsRoute);
// /traversals/..
appRouter.registerRoute(traversalRoute);

// /audit-log/
appRouter.registerRoute(auditLogRoute);

// /use-cases/
appRouter.registerRoute(useCasesRoute);

// /component-overview-page/
appRouter.registerRoute(componentOverviewPageRoute);

// /analytics/
appRouter.registerRoute(analyticsRoute);

// /assets/
appRouter.registerRoute(assetsRoute);

// /inventory/
appRouter.registerRoute(inventoryModuleRoute);

// Starts the router
export const startAppRouter = () =>
  appRouter.start(() =>
    // Converts application state to router state
    combineLatest([
      selectedModule$,
      searchRoute.getPartialRouterStateStream(),
      workspaceModuleRoute.getPartialRouterStateStream(),
      reportRoute.getPartialRouterStateStream(),
      integrationRoute.getPartialRouterStateStream(),
      metamodelRoute.getPartialRouterStateStream(),
      externalDocumentRoute.getPartialRouterStateStream(),
      declerativeOrganizationMetamodelRoute.getPartialRouterStateStream(),
      subdivisionRoute.getPartialRouterStateStream(),
      surveyAdminRoute.getPartialRouterStateStream(),
      scenarioRoute.getPartialRouterStateStream(),
      broadcastsRoute.getPartialRouterStateStream(),
      viewpointsRoute.getPartialRouterStateStream(),
      dashboardModuleRoute.getPartialRouterStateStream(),
      manageOrganizationRoute.getPartialRouterStateStream(),
      UserSettingsRoute.getPartialRouterStateStream(),
      traversalRoute.getPartialRouterStateStream(),
      useCasesRoute.getPartialRouterStateStream(),
      componentOverviewPageRoute.getPartialRouterStateStream(),
      accessControlPageRoute.getPartialRouterStateStream(),
      assetsRoute.getPartialRouterStateStream(),
      inventoryModuleRoute.getPartialRouterStateStream(),
    ]).pipe(
      map(
        ([
          { selectedModule },
          searchRouterState,
          workspaceModuleRouteState,
          reportRouteState,
          integrationRouterState,
          metamodelRouterState,
          externalDocumentState,
          declerativeOrganizationMetamodelRouterState,
          subdivisionRouterState,
          surveyAdminRouterState,
          scenarioRouterState,
          broadcastsRouterState,
          viewpointsRouterState,
          dashboardModuleRouterState,
          manageOrganizationModuleRouterState,
          UserSettingsRouteState,
          traversalRouterState,
          useCasesRouterState,
          componentOverviewPageRouterState,
          accessControlPageRouteState,
          assetsRouterState,
          inventoryModuleState,
        ]) => {
          let declarativeState = {
            declarativeRouterState: emptyLocation as Location,
          };

          // If adding a route using the declarative router, add it like this:
          if (
            declerativeOrganizationMetamodelRouterState.declarativeRouterState
              .route !== ''
          ) {
            declarativeState = declerativeOrganizationMetamodelRouterState;
          } // Add next route with else if

          return {
            appModule: selectedModule,
            showViewDigest: false,
            ...searchRouterState,
            ...workspaceModuleRouteState,
            ...reportRouteState,
            ...integrationRouterState,
            ...metamodelRouterState,
            ...externalDocumentState,
            ...subdivisionRouterState,
            ...surveyAdminRouterState,
            ...scenarioRouterState,
            ...broadcastsRouterState,
            ...viewpointsRouterState,
            ...dashboardModuleRouterState,
            ...manageOrganizationModuleRouterState,
            ...UserSettingsRouteState,
            ...traversalRouterState,
            ...useCasesRouterState,
            ...componentOverviewPageRouterState,
            ...accessControlPageRouteState,
            ...assetsRouterState,
            ...inventoryModuleState,
            ...declarativeState,
          };
        }
      )
    )
  );

if (module.hot) {
  module.hot.dispose(() => appRouter.teardown());
}

export default appRouter;
