import React from 'react';
import { getScheduleDirection } from 'integrations/common/streams/schedules/utils';
import { CellWithDropdown } from '@ardoq/table';
import { IntegrationSchedule } from '@ardoq/api-types';
import { ScheduleCommands } from './SchedulesTable';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';

type ScheduleDropdownProps = {
  schedule: IntegrationSchedule;
  scheduleCommands: ScheduleCommands;
  integrationId: IntegrationId;
  setEditingId: React.Dispatch<React.SetStateAction<string | null>>;
};

export const ScheduleDropdown = ({
  schedule,
  scheduleCommands,
  integrationId,
  setEditingId,
}: ScheduleDropdownProps) => {
  return (
    <CellWithDropdown
      options={[
        {
          label: schedule.meta.paused ? 'Resume' : `Edit schedule`,
          onClick: () =>
            scheduleCommands.onEditSchedule(integrationId, schedule._id),
        },
        ...(schedule.meta.paused
          ? []
          : [
              {
                label: `Edit interval`,
                onClick: () =>
                  scheduleCommands.onStartEditInterval(integrationId, schedule),
              },
              {
                label: `${
                  getScheduleDirection(schedule.jobOptions.type) === 'import'
                    ? 'Import'
                    : 'Export'
                } now`,
                onClick: () =>
                  scheduleCommands.onRunSchedule(integrationId, schedule._id),
              },
            ]),
        ...(scheduleCommands.allowedConfigurations(integrationId)
          ? [
              {
                label: 'Create configuration',
                onClick: () => {
                  scheduleCommands.onStartCreateConfig(integrationId, schedule);
                },
              },
            ]
          : []),
        ...(schedule.meta.paused
          ? []
          : [
              {
                label: 'Pause',
                onClick: () =>
                  scheduleCommands.onPauseSchedule(integrationId, schedule._id),
              },
              {
                label: 'Rename',
                onClick: () => {
                  scheduleCommands.onStartScheduleRenaming(integrationId);
                  setEditingId(schedule._id);
                },
              },
            ]),
        {
          label: 'Delete',
          onClick: () =>
            scheduleCommands.onStartDeleteSchedule(integrationId, schedule),
        },
      ]}
    />
  );
};
