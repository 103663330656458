import { prompt } from '@ardoq/modal';

const broadcastNamePrompt = () => {
  return prompt({
    title: 'Save broadcast',
    text: 'Broadcast name',
    textInputId: 'enter-broadcast-name',
    confirmButtonTitle: 'Save',
    confirmButtonClickId: 'save-broadcast-dialog-box',
  });
};

export default broadcastNamePrompt;
