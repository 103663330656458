import {
  action$,
  dispatchAction,
  extractPayload,
  ObservableState,
  ofType,
} from '@ardoq/rxbeach';
import { selectionEnd } from '../actions/navigatorActions';
import { componentTreeSelectionChanged } from 'componentSelection/componentSelectionActions';
import { filter, Observable, tap, withLatestFrom } from 'rxjs';
import { NavigatorLayoutState } from '../types';
import { ComponentSelection } from 'componentSelection/componentSelectionTypes';

export const getHandleSelectionEnd = (
  navigator$: Observable<NavigatorLayoutState>,
  componentSelection$: ObservableState<ComponentSelection>,
  actionNamespace?: string
) =>
  action$.pipe(
    ofType(selectionEnd),
    // Strict namespacing, `withNamespace` also lets pass no-namespaced actions.
    filter(({ meta: { namespace } }) => namespace === actionNamespace),
    extractPayload(),
    withLatestFrom(navigator$, componentSelection$),
    tap(([{ nodeId }, { tree }, { selectionStart }]) => {
      const treeSelection = tree.updateRange(selectionStart, nodeId);
      dispatchAction(
        componentTreeSelectionChanged({ treeSelection }),
        actionNamespace
      );
    })
  );
