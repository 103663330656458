import history, { APPLICATION_BASE_PATH } from './history';

export const setWindowTitle = (title = '') => {
  if (title) {
    document.title = `Ardoq - ${title}`;
  } else {
    document.title = 'Ardoq';
  }
};

export const setWindowLocation = (path: string) => {
  if (getWindowLocationPath() === path) {
    return;
  }
  history.push(path);
};

export const getWindowLocationPath = () => {
  const appPathRegex = new RegExp(`^${APPLICATION_BASE_PATH}`);
  return window.location.pathname.replace(appPathRegex, '');
};

const LOGIN_ROUTE_PREFIX = '/login';

const windowHasLoginRoute = () =>
  getWindowLocationPath().startsWith(LOGIN_ROUTE_PREFIX);

export const setLoginRoutePrefix = () => {
  if (!windowHasLoginRoute()) {
    setWindowLocation(
      LOGIN_ROUTE_PREFIX + getWindowLocationPath() + window.location.search
    );
  }
};

export const clearLoginRoutePrefix = () => {
  if (windowHasLoginRoute()) {
    setWindowLocation(
      getWindowLocationPath().replace(/^\/login\/?/, '/') +
        window.location.search
    );
  }
};
