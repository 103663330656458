import { reducers } from './reducers';
import { action$, reduceState } from '@ardoq/rxbeach';
import { RestoreDeletedViewModel } from './types';

const defaultState: RestoreDeletedViewModel = {
  components: [],
  references: [],
  isShowingLoadingIndicator: false,
  hasFetched: false,
  hasFetchingError: false,
  fetchRetry: () => {},
};

const restoreDeleted$ = action$.pipe(
  reduceState('restoreDeleted$', defaultState, reducers)
);

export default restoreDeleted$;
