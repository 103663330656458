import {
  Button,
  ButtonGroup,
  ButtonType,
  SecondaryButton,
  SmallGhostButton,
} from '@ardoq/button';
import styled from 'styled-components';
import { StyledKnowledgeBaseIcon } from '@ardoq/report-reader';
import { PeopleIcon, SaveIcon } from '@ardoq/icons';
import { ToolbarDivider } from '@ardoq/page-layout';
import { focusRingCSS } from '@ardoq/style-helpers';
import { popoverRegistry } from '@ardoq/popovers';

export const SHARE_BUTTON_ID = 'report-builder-share-button';
popoverRegistry.set(SHARE_BUTTON_ID, () => 'Save the report before sharing');

const EXIT_EDITING_BUTTON_ID = 'report-builder-exit-editing-button';
popoverRegistry.set(
  EXIT_EDITING_BUTTON_ID,
  () => 'Save the report before exiting'
);

export type ReportBuilderNavBarCommands = {
  saveReport: () => Promise<void>;
  viewReport: VoidFunction;
  shareReport: VoidFunction;
  openKBArticle: VoidFunction;
  exitEditing: VoidFunction;
};

export type SaveReportButtonProps = {
  isDisabled: boolean;
  onClick: VoidFunction;
};

export type ExitEditingButtonProps = {
  onClick: VoidFunction;
  hasUnsavedProgress: boolean;
};

export type ShareReportButtonProps =
  | { isVisible: false }
  | {
      isVisible: true;
      isDisabled: boolean;
      isLoading: boolean;
      onClick: VoidFunction;
    };

type ToolbarButtonProps = {
  shareButtonProps: ShareReportButtonProps;
  knowledgeBaseButtonProps: {
    onClick: VoidFunction;
  };
};

export type ReportBuilderNavBarProps = {
  saveReportButtonProps: SaveReportButtonProps;
  exitEditingButtonProps: ExitEditingButtonProps;
  toolbarButtonProps: ToolbarButtonProps;
  reportName: string;
};

const ShareButton = styled(SecondaryButton)`
  &:focus {
    ${focusRingCSS};
  }
`;

export const SaveReportButton = (props: SaveReportButtonProps) => (
  <Button
    buttonType={ButtonType.BRAND}
    data-click-id="report-builder-save-button"
    {...props}
  >
    Save changes <SaveIcon />
  </Button>
);

export const ExitEditingButton = ({
  hasUnsavedProgress,
  ...props
}: ExitEditingButtonProps) => (
  <SecondaryButton
    data-click-id={EXIT_EDITING_BUTTON_ID}
    popoverId={hasUnsavedProgress ? EXIT_EDITING_BUTTON_ID : undefined}
    {...props}
  >
    Exit editing
  </SecondaryButton>
);

export const ToolbarButtons = ({
  shareButtonProps,
  knowledgeBaseButtonProps,
}: ToolbarButtonProps) => (
  <ButtonGroup>
    <SmallGhostButton {...knowledgeBaseButtonProps}>
      <StyledKnowledgeBaseIcon data-tooltip-text="Knowledge base" />
    </SmallGhostButton>
    {shareButtonProps.isVisible && (
      <ShareButton
        popoverId={shareButtonProps.isDisabled ? SHARE_BUTTON_ID : undefined}
        type="button"
        data-click-id={SHARE_BUTTON_ID}
        id={SHARE_BUTTON_ID}
        isDisabled={shareButtonProps.isDisabled}
        isLoading={shareButtonProps.isLoading}
        onClick={shareButtonProps.onClick}
      >
        <PeopleIcon />
        Share
      </ShareButton>
    )}
    <ToolbarDivider />
  </ButtonGroup>
);
