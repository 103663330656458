import { dispatchAction } from '@ardoq/rxbeach';
import {
  APIBroadcastAttributes,
  AssetType,
  BroadcastStatus,
} from '@ardoq/api-types';
import {
  notifyCopyingBroadcastSucceeded,
  showBroadcastPauseDialog,
  triggerBroadcast,
} from 'broadcasts/broadcastOverview/actions';
import { capitalize, isEmpty, omit } from 'lodash';
import {
  fetchBroadcastInstances,
  showLaunchBroadcastDialog,
} from 'broadcasts/actions';
import { BroadcastRow } from 'components/AssetsBrowser/types';
import { MenuFnOptions } from 'appContainer/DashboardContainer/getMenuOptions/types';
import { openDeleteDialog } from '../overviewHelpers';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import {
  copyMenuOption,
  deleteMenuOption,
  dividerMenuOption,
  moveToMenuOption,
  renameMenuOption,
} from 'appContainer/DashboardContainer/getMenuOptions/sharedMenuOptions';
import { copyModal } from 'copyComponent/copyModal';
import { broadcastApi } from '@ardoq/api';
import { initiateNavigationToEditBroadcastForm } from 'router/navigationActions';

const openCopyBroadcastModal = async (broadcast: BroadcastRow) => {
  copyModal({
    entityName: broadcast.name,
    copyEntity: async newCopyName => {
      const broadcastCopy = await broadcastApi.copy(
        omit(broadcast, ['meta', 'rowType']),
        newCopyName
      );
      if (isArdoqError(broadcastCopy)) {
        return broadcastCopy;
      }
      dispatchAction(notifyCopyingBroadcastSucceeded(broadcastCopy));
      return {
        name: broadcastCopy.name,
        _id: broadcastCopy._id,
      };
    },
    entityType: AssetType.BROADCAST,
    openCopy: copyId =>
      dispatchAction(initiateNavigationToEditBroadcastForm(copyId)),
  });
};

const getBaseOptions = (
  broadcast: BroadcastRow,
  {
    handleMoveToModal,
    rowPermissions,
    onRenameSelect,
    clearSelected,
  }: MenuFnOptions
) => [
  {
    label: 'View report',
    type: DropdownOptionType.OPTION,
    onClick: () => dispatchAction(fetchBroadcastInstances(broadcast._id)),
  },
  renameMenuOption({
    asset: broadcast,
    hasPermission: rowPermissions.canUpdate,
    onRenameSelect,
  }),
  moveToMenuOption({
    asset: broadcast,
    hasPermission: rowPermissions.canUpdate,
    handleMoveToModal,
  }),
  copyMenuOption({
    assetType: AssetType.BROADCAST,
    hasPermission: rowPermissions.canCopy,
    onCopy: () => openCopyBroadcastModal(broadcast),
  }),
  dividerMenuOption(),
  deleteMenuOption({
    asset: broadcast,
    hasPermission: rowPermissions.canDelete,
    onClick: () => openDeleteDialog(broadcast, clearSelected),
  }),
];

const getTriggerOption = (broadcast: APIBroadcastAttributes) => {
  return {
    name: 'trigger',
    label: 'Send now',
    type: DropdownOptionType.OPTION,
    onClick: () => {
      dispatchAction(triggerBroadcast(broadcast));
    },
  };
};

const getRunningOptions = (
  broadcast: BroadcastRow,
  hasErrors: boolean,
  options: MenuFnOptions
) => {
  const pauseOption = {
    label: 'Pause',
    type: DropdownOptionType.OPTION,
    onClick: () => dispatchAction(showBroadcastPauseDialog(broadcast._id)),
  };
  return [
    ...(hasErrors ? [] : [getTriggerOption(broadcast)]),
    pauseOption,
    ...getBaseOptions(broadcast, options),
  ];
};

const getNonRunningOptions = (
  broadcast: BroadcastRow,
  hasErrors: boolean,
  options: MenuFnOptions
) => {
  const operation =
    broadcast.status === BroadcastStatus.UNPUBLISHED ? 'launch' : 'resume';
  const startOrResumeOption = {
    name: operation,
    label: `${capitalize(operation)} schedule`,
    isDisabled: broadcast.status === BroadcastStatus.DONE,
    type: DropdownOptionType.OPTION,
    onClick: () => dispatchAction(showLaunchBroadcastDialog(broadcast._id)),
  };
  return [
    ...(hasErrors ? [] : [getTriggerOption(broadcast)]),
    startOrResumeOption,
    ...getBaseOptions(broadcast, options),
  ];
};

export const getBroadcastMenuOptions = (
  broadcastRow: BroadcastRow,
  options: MenuFnOptions
) => {
  const hasErrors = !isEmpty(broadcastRow.errors);
  const isRunning =
    broadcastRow.status === BroadcastStatus.RUNNING ||
    broadcastRow.status === BroadcastStatus.WARNING;
  if (isRunning) {
    return getRunningOptions(broadcastRow, hasErrors, options).filter(
      ExcludeFalsy
    );
  }
  return getNonRunningOptions(broadcastRow, hasErrors, options).filter(
    ExcludeFalsy
  );
};
