import { Box } from '@ardoq/layout';
import { TableList, TableListItem } from '@ardoq/list';
import { Island } from '@ardoq/page-layout';
import { Header3 } from '@ardoq/typography';
import { AssetInfo } from 'assets/types';

type AssetDetailsProps = {
  properties: AssetInfo['properties'];
};

export const AssetDetails = ({ properties }: AssetDetailsProps) => {
  return (
    <Island
      title={<Header3>Details</Header3>}
      showHeaderBorder={false}
      maxWidth="100%"
      minWidth="100%"
      bodySpacing="none"
      headerSpacing="large"
    >
      <Box paddingX="large" paddingBottom="large">
        <TableList style={{ width: '100%' }}>
          {properties.map(({ label, value }) => (
            <TableListItem key={label} label={label}>
              {value}
            </TableListItem>
          ))}
        </TableList>
      </Box>
    </Island>
  );
};
