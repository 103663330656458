import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { map, switchMap, combineLatest } from 'rxjs';

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest([
      getTabularMappingStream(integrationId),
      getActiveIntegrationStream(integrationId),
    ])
  ),
  map(([tabularMapping, { currentTableId }]) => {
    if (currentTableId) {
      return {
        columnFilters: tabularMapping[currentTableId]?.columnFilters ?? {},
      };
    }
    return {
      columnFilters: {},
    };
  })
);
