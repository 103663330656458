import { PermissionContext } from '@ardoq/access-control';
import {
  APIFieldAttributes,
  APIFieldType,
  ArdoqId,
  SurveyQuestionType,
  QueryBuilderSubquery,
  SurveyMiscAttributes,
  PersistedFieldQuestion,
  UnpersistedFieldQuestion,
  PersistedAttributeQuestion,
  UnpersistedAttributeQuestion,
  SurveyQuestionValidator,
  PersistedSurveyQuestion,
  UnpersistedSurveyQuestion,
  PersistedConditionalFieldQuestion,
  UnpersistedConditionalFieldQuestion,
  PartiallyPersistedConditionalQuestion,
  PersistedParentQuestion,
  UnpersistedParentQuestion,
  APISurveyAttributes,
  UnpersistedEntity,
  PartiallyPersistedReferenceQuestion,
} from '@ardoq/api-types';
import { SelectOption } from '@ardoq/select';
import {
  YOU_DO_NOT_HAVE_EDIT_ACCESS_TO_WORKSPACE,
  YOU_DO_NOT_HAVE_READ_ACCESS_TO_WORKSPACE,
} from './SurveyEditor/consts';
import { Field } from 'streams/fields/fields$';
import { SurveyErrorName, SurveyWarningName } from './consts';
import { SurveyEditorState } from './SurveyEditor/streams/types';

export type MaybePersistedSurvey =
  | APISurveyAttributes
  | UnpersistedEntity<APISurveyAttributes>;

export type AllPossibleQuestions =
  | UnpersistedSurveyQuestion
  | PersistedSurveyQuestion
  | PartiallyPersistedConditionalQuestion
  | PartiallyPersistedReferenceQuestion;

export type SurveyValidation = {
  readonly _id: string; // Survey ID - empty string when creating new survey
  readonly misc: SurveyMiscAttributes;
  readonly errorMessagesMap: Map<SurveyErrorName, string>;
  readonly warningMessagesMap: Map<SurveyWarningName, string>;
  readonly questionValidations: SurveyQuestionValidations[];
};

export enum SurveyBuilderLocation {
  RESULT_FILTERING = 'Result filtering',
  REFERENCE_QUESTION = 'Reference question',
  DEFINE_YOUR_DATA = 'Workspace and Components',
  PARENT_QUESTION = 'Parent question',
}

export type SurveyQuestionValidation = {
  message: SurveyQuestionError | SurveyQuestionWarning;
  severity: 'error' | 'warning';
};

export enum SurveyQuestionWarning {
  NO_EDIT_ACCESS_TO_SELECTED_REFERENCE_TYPE = YOU_DO_NOT_HAVE_EDIT_ACCESS_TO_WORKSPACE,
  NO_READ_ACCESS_TO_SELECTED_REFERENCE_TYPE = YOU_DO_NOT_HAVE_READ_ACCESS_TO_WORKSPACE,
}

export enum SurveyQuestionError {
  PARENT_NOT_ALLOWED = 'Parent not allowed',
  FIELD_NOT_AVAILABLE = 'Field not available',
  WORKSPACE_MISSING = 'Workspace missing',
  COMPONENT_TYPE_MISSING = 'Component type missing',
  SELECTED_COMPONENT_TYPE_DOES_NOT_EXIST = 'Component type does not exist',
  SELECTED_REFERENCE_TYPE_DOES_NOT_EXIST = 'The selected reference type has been deleted or renamed. Please select another one.',
  REFERENCE_TYPE_MISSING = 'A reference type must be selected.',
  REFERENCE_PARENT_MISSING = 'Reference parent missing',
  REFERENCE_PARENT_INVALID = 'Selected reference parent no longer exists',
  FIELD_NOT_SELECTED = 'Field not selected',
  DISPLAY_CONDITIONS_MISSING = 'Conditional questions must be linked to main question answers',
  DISPLAY_CONDITIONS_INVALID = 'The answers which trigger the conditional questions are invalid based on the field type',
  RANGE_RULES_INVALID = 'The minimum cannot be greater than the maximum',
  MULTIPLE_REFERENCES_REQUIRES_SUBQUESTIONS = 'Adding multiple reference questions to the same component is only supported for reference questions with sub questions.',
  PREVENTING_REFERENCE_MODIFICATION_REQUIRES_SUBQUESTIONS = 'Marking a reference question as edit-only is only supported for reference questions with sub questions.',
  INVALID_HIDDEN_ANSWER_VALUE = 'Answer to hidden question is invalid',
  WORKSPACE_TAGS_MISSING = 'There are no tags for the selected workspace',
  IDENTIFYING_FIELD_MISSING_OR_INVALID = 'The selected identifying field is invalid or no longer exists.',
}

export type SurveyQuestionValidations = {
  questionValidations: SurveyQuestionValidation[];
  subQuestionValidations?: SurveyQuestionValidations[];
};

export type MaybePersistedFieldOrAttributeQuestion =
  | PersistedFieldQuestion
  | UnpersistedFieldQuestion
  | PersistedAttributeQuestion
  | UnpersistedAttributeQuestion
  | PersistedConditionalFieldQuestion
  | UnpersistedConditionalFieldQuestion
  | PartiallyPersistedConditionalQuestion
  | PersistedParentQuestion
  | UnpersistedParentQuestion;

type UpdateFieldQuestion = (
  question: MaybePersistedFieldOrAttributeQuestion
) => void;

export type FieldQuestionArgs = {
  workspaceId: string | null;
  question: MaybePersistedFieldOrAttributeQuestion;
  questionValidations?: SurveyQuestionValidations;
  fields: APIFieldAttributes[];
  attributes: { label: string; attributeName: string }[];
  parentQuestionIsReadonly?: boolean;
  questions?: AllPossibleQuestions[];
  surveyComponentTypeId?: string;
  isComponent: boolean;
  permissionsContext: PermissionContext;
  surveyWorkspaceId: ArdoqId | null;
  allowComponentCreation?: boolean;
  surveyIsPermissionDivisionsAware: boolean;
  hasSurveyResponseApprovalsFeature: boolean;
  updateQuestion: UpdateFieldQuestion;
  fieldHasBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith'];
};

export type SurveyTypeOption = SelectOption<string> & {
  type: SurveyQuestionType.ATTRIBUTE | SurveyQuestionType.FIELD;
  fieldType?: APIFieldType;
};

export type FieldQuestionConfiguratorProps = {
  question: MaybePersistedFieldOrAttributeQuestion;
  updateQuestion: UpdateFieldQuestion;
  fieldIsReadonly: boolean;
  parentQuestionIsReadonly?: boolean;
  showRequiredField?: boolean;
  advancedSearchLimiter?: QueryBuilderSubquery;
  showAdvancedOptions?: boolean;
  surveyWorkspaceId?: ArdoqId | null;
  questionValidations: SurveyQuestionValidations;
  allowComponentCreation?: boolean;
  isNameQuestion?: boolean;
};

export type ReadOnlyCheckboxRowProps = {
  readonly?: boolean;
  validators?: SurveyQuestionValidator[];
  disabled?: boolean;
  descriptionExtension?: string;
  fieldIsReadonly?: boolean;
  allowComponentCreation?: boolean;
  isNameQuestion?: boolean;
  updateReadonlyAndValidators: ({
    readonly,
    validators,
  }: {
    readonly: boolean;
    validators: SurveyQuestionValidator[];
  }) => void;
};

export type FieldsByName = Partial<Record<string, Field>>;

export type FieldsByComponentTypeName = Partial<Record<string, Field[]>>;
