import { Operator, ViewIds } from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';

interface TrackApplyGrouping {
  activeView: ViewIds;
  amountOfGroupings: number;
  groupByWorkspace: boolean;
  groupByReference: boolean;
  groupByComponent: boolean;
  groupByParent: boolean;
  groupByParentAll: boolean;
  groupByChild: boolean;
  groupByField: boolean;
  groupByTag: boolean;
  groupBySubdivision: boolean;
}

// Track when user applies a perspectives grouping with type and amount
export const trackApplyGrouping = (metadata: TrackApplyGrouping) =>
  trackEvent('Applied grouping via manage perspectives', metadata);

interface TrackReferenceLabelFormatOption {
  refLabelFormatOption: string;
  activeView: ViewIds;
}

export const trackReferenceLabelFormatOption = (
  metadata: TrackReferenceLabelFormatOption
) => trackEvent('Applied reference label format option', metadata);

export type RuleAndOperatorCombination = {
  field: string;
  operator: Operator;
};

export type AppliedPerpectiveFilterData = {
  defaultRuleCount: number;
  customRuleCount: number;
  mostCommonDefaultCombination: RuleAndOperatorCombination | null;
};

type TrackSavedPerspectiveFilters = {
  activeView: ViewIds;
  enabledGraphFilterCount: number;
  componentData: AppliedPerpectiveFilterData;
  referenceData: AppliedPerpectiveFilterData;
};

// Track when a user saves changes in the perspective editor
export const trackSavedPerspectiveFilters = (
  metadata: TrackSavedPerspectiveFilters
) => trackEvent('Saved Updated Perspective Filters', metadata);

export interface FilterStats {
  anyCount: number;
  allCount: number;
  ruleCount: number;
  subQueryCount: number;
}

export type TrackAppliedPerspectiveSimpleFilter = {
  activeView: ViewIds;
  filterStats: FilterStats;
};

// Track when a user changes the component filter rules in the perspective editor and clicks apply
export const trackAppliedPerspectiveComponentFilter = (
  metadata: TrackAppliedPerspectiveSimpleFilter
) => trackEvent('Applied Updated Perspective Component Filter', metadata);

// Track when a user changes the reference filter rules in the perspective editor and clicks apply
export const trackAppliedPerspectiveReferenceFilter = (
  metadata: TrackAppliedPerspectiveSimpleFilter
) => trackEvent('Applied Updated Perspective Reference Filter', metadata);

export type TrackModifiedPerspectiveDateFilter = {
  activeView: ViewIds;
  clickedDateFilter: string;
};

// Track when a user changes the a date filter rule in the perspective editor
export const trackModifiedPerspectiveDateFilter = (
  metadata: TrackModifiedPerspectiveDateFilter
) => trackEvent('Modified Perspective Date Filter', metadata);
