import { APIWorkspaceAttributes } from '@ardoq/api-types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { getConnectedWorkspaceIds } from 'streams/linkedWorkspaces$';
import { fieldInterface } from '@ardoq/field-interface';
import { StubWorkspace } from '@ardoq/data-model';

export const collectRelatedWorkspaces = (contextWorkspaceIds: string[]) =>
  Array.from(getConnectedWorkspaceIds(contextWorkspaceIds))
    .map(id =>
      workspaceInterface.getAttributes(id, ['_id', 'name', 'componentModel'])
    )
    .filter(isStubWorkspace);

export const collectRelatedFieldsRecord = (
  relatedWorkspaces: Pick<APIWorkspaceAttributes, '_id'>[]
) =>
  fieldInterface.getFieldDataForFiltersAndFormatting(
    relatedWorkspaces.map(({ _id }) => _id)
  );

export const isStubWorkspace = (
  candidate: Record<string, string | undefined>
): candidate is StubWorkspace =>
  Boolean(candidate._id && candidate.name && candidate.componentModel);
