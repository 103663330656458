import * as React from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import { toggleCollapse } from './scenarioRelatedDataSource$';
import {
  isArdoqError,
  isMac,
  ArdoqError,
  getArdoqErrorMessage,
} from '@ardoq/common-helpers';
import {
  removeSelection,
  selectClick,
  selectMeta,
  selectShift,
} from './scenarioRelatedSelection$';
import { DataSourceItem } from './types';
import {
  AddToScenarioResponse,
  ArdoqId,
  UpdateScenarioPayload,
} from '@ardoq/api-types';
import {
  reloadScenario,
  reloadScenarioError,
  reloadScenarioSuccess,
} from 'scope/actions';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';
import { logError } from '@ardoq/logging';
import { showScopeRelatedNavigator } from '../../actions';
import { trackClickRelatedComponents } from 'scope/tracking';
import {
  setIsMainViewLoading,
  setIsMainViewNotLoading,
} from 'streams/views/mainContent/mainViewLoadingIndicator$';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { alert } from '@ardoq/modal';
import GenericErrorMessageWithSupportLink from 'atomicComponents/GenericErrorMessageWithSupportLink';
import { ApiResponse, getErrorStatusCode, scenarioApi } from '@ardoq/api';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';

export const select =
  (id: string, dataSource: DataSourceItem[]) =>
  async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatchAction(showScopeRelatedNavigator());
    if (isMac() ? event.metaKey : event.ctrlKey) {
      dispatchAction(selectMeta(id));
      trackClickRelatedComponents('control');
    } else if (event.shiftKey) {
      dispatchAction(selectShift({ id, dataSource }));
      trackClickRelatedComponents('shift');
    } else {
      dispatchAction(selectClick(id));
      trackClickRelatedComponents('none');
    }
  };

export const toggle =
  (id: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    dispatchAction(toggleCollapse(id));
  };

const updateComponentsAndReferencesOfScope =
  (
    updateHandler: (
      payload: UpdateScenarioPayload
    ) => ApiResponse<AddToScenarioResponse>,
    errorMessage: string
  ) =>
  async (componentIds: ArdoqId[]) => {
    dispatchAction(setIsMainViewLoading());
    const scenario = scenarioInterface.getScenarioById(getActiveScenarioId());
    if (!scenario) return;
    const scenarioId = scenario._id;
    const scopeId = scenario.scopeId;
    const branchId = scenario.branchId;
    const scenarioReferences = await scenarioApi.getScenarioReferences(
      componentIds,
      scopeId,
      branchId
    );
    if (isArdoqError(scenarioReferences)) {
      logErrorAndAlert(scenarioReferences, errorMessage);
      return;
    }
    const { referencesToScope, references } = scenarioReferences;
    const handler = await updateHandler({
      scenarioId,
      componentIds,
      referenceIds: [
        ...references.map(({ _id }) => _id),
        ...referencesToScope.map(({ _id }) => _id),
      ],
    });
    if (isArdoqError(handler)) {
      logErrorAndAlert(handler, errorMessage);
      return;
    }
    dispatchAction(removeSelection(componentIds));

    await dispatchActionAndWaitForResponse(
      reloadScenario({ scenarioId }),
      reloadScenarioSuccess,
      reloadScenarioError
    );
    dispatchAction(setIsMainViewNotLoading());
  };

const logErrorAndAlert = (error: ArdoqError, errorMessage: string) => {
  const message = getArdoqErrorMessage(error, 'Missing response');
  const code = getErrorStatusCode(error);

  logError(error, errorMessage, {
    message,
    code,
  });
  alert({
    title: 'Add to scenario error',
    subtitle: message ? `Description: ${message}` : 'An error occured.',
    // eslint-disable-next-line
    text: [code && `Code: ${code}`, <GenericErrorMessageWithSupportLink />],
  });
};

export const addComponentsToScenario = updateComponentsAndReferencesOfScope(
  scenarioApi.addToScenario,
  'Error adding components to scope via the relatedScenario navigator'
);

export const removeComponentsFromScenario =
  updateComponentsAndReferencesOfScope(
    scenarioApi.removeFromScenario,
    'Error removing components from scope via the relatedScenario navigator'
  );
