import { actionCreator } from '@ardoq/rxbeach';
import { Verb } from '@ardoq/api-types';
import { MergeDirection } from './MergeDirection';
import { MergeStepLabel } from './typesMergeStepLabel';

export const initMerge = actionCreator<MergeDirection>('[merge] INIT_MERGE');

export const initMergeFinished = actionCreator('[merge] INIT_MERGE_FINISHED'); // this action is for testing purposes only

export const mergeFlowOpened = actionCreator('[merge] MERGE_FLOW_OPENED');

export const closeMergeFlow = actionCreator<{
  shouldMoveBranchOffPoint: boolean;
}>('[merge] CLOSE_MERGE_FLOW');

export const trackSetStep = actionCreator('[merge] TRACK_SET_STEP');

export const setSubmittedStep = actionCreator<{
  submittedStep: { mainStateStep: MergeStepLabel; subStateStep: Verb };
}>('[merge] SET_APPLIED_STEP');

export const updateMergeStateDiff = actionCreator<any>(
  '[merge] UPDATE_MERGE_STATE_DIFF'
);

export const setMergeStateDiff = actionCreator<any>(
  '[merge] SET_MERGE_STATE_DIFF'
);

export const applyMerge = actionCreator('[merge] APPLY_MERGE');

export const setStep = actionCreator<{
  mainStateStep: MergeStepLabel;
  subStateStep: Verb;
}>('[merge] SET_STEP');

export const skipStep = actionCreator<{
  mainStateStep: MergeStepLabel;
  subStateStep: Verb;
}>('[merge] SKIP_STEP');
