import { logError } from '@ardoq/logging';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { switchMap, tap, withLatestFrom, filter } from 'rxjs/operators';
import {
  confirmSurveyDigestUnsubscribe,
  notifySavingSurveySettingsFailed,
  notifySavingSurveySettingsSucceeded,
  postSurveyDigestUnsubscribe,
  saveOrganization,
  toggleSurveySettingsSidebar,
  saveSurveySettings,
  setSurveySettingsSidebarData,
  fetchPendingApprovalsSummary,
  fetchSurveyValidation,
  notifyFetchingPendingApprovalsFailed,
  notifyFetchingPendingApprovalsSucceeded,
  notifySavingSurveyFailed,
  notifySavingSurveySucceeded,
  saveNewSurvey,
  saveNewSurveyInFolder,
  saveSurvey,
  notifyFetchingSurveyValidationSucceeded,
  notifyFetchingSurveyValidationFailed,
  notifyNewSurveySaved,
} from './actions';
import {
  confirmUnsubscribeModal,
  unsubscriptionSuccessModal,
} from '../SurveyModals';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { SurveyAdminMode } from 'surveyAdmin/navigation/types';
import { api, handleError, surveyApi, tagApi } from '@ardoq/api';
import { trackEvent } from 'tracking/tracking';
import { getSurveyErrorsAndWarnings } from '../surveyUtil';
import { surveyAlert } from './surveyAlert';
import { organizationInterface } from 'modelInterface/organization/organizationInterface';
import currentUser$ from 'streams/currentUser/currentUser$';
import organizations$ from 'streams/organizations/organizations$';
import surveyAdmin$ from './surveyAdmin$';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import surveys$, { surveysNamespace } from 'streams/surveys/surveys$';
import {
  ArdoqError,
  isArdoqError,
  toByIdDictionary,
} from '@ardoq/common-helpers';
import { fetchTags } from 'streams/tags/actions';
import { fetchChangeApprovalData } from '../ChangeApproval/actions';
import { fetchOrgUsers } from 'streams/orgUsers/actions';
import { addPermissionForResource } from 'streams/currentUserPermissions/actions';
import {
  setSurveyOverviewSearchParams,
  navigateToSurveyForm,
  openNewSurvey,
  navigateToChangeApprovalPage,
  selectSurveyId,
  selectSurveyMode,
} from 'surveyAdmin/navigation/actions';
import { requestEditSurvey } from 'surveyAdmin/navigation/utils';
import { fetchPermissionGroups } from '../../admin/accessControl/PermissionGroups/streams/actions';
import { Features, hasFeature } from '@ardoq/features';
import currentOrganization$ from 'streams/organizations/currentOrganization$';
import {
  fetchAllFailed,
  fetchAllRequested,
  fetchAllSucceeded,
} from 'streams/crud/actions';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { isAdminOrWriter } from 'surveyAdmin/SurveyEditor/utils';
import permissionGroup$ from 'admin/accessControl/PermissionGroups/streams/permissionGroups$';

const errorHandler = (error: ArdoqError) => {
  surveyAlert({ action: 'saved', error });
  dispatchAction(notifySavingSurveyFailed());
};

const handleFetchSurveyErrors = routine(
  ofType(fetchSurveyValidation),
  switchMap(tagApi.fetchAll),
  handleError(error => {
    dispatchAction(notifyFetchingSurveyValidationFailed());
    api.logErrorIfNeeded(error);
  }),
  withLatestFrom(
    surveys$,
    currentUserPermissionContext$,
    orgUsers$,
    permissionGroup$
  ),
  switchMap(
    async ([
      tags,
      { list: surveys },
      permissionsContext,
      { users },
      { groupsById },
    ]) => {
      const adminOrWriterUsers = users.filter(isAdminOrWriter);
      const errors = surveys
        ? await Promise.all(
            surveys.map(survey =>
              getSurveyErrorsAndWarnings(
                survey,
                tags,
                permissionsContext,
                adminOrWriterUsers,
                groupsById
              )
            )
          )
        : [];
      dispatchAction(
        notifyFetchingSurveyValidationSucceeded(toByIdDictionary(errors))
      );
    }
  )
);

const handleConfirmSurveyDigestUnsubscribe = routine(
  ofType(confirmSurveyDigestUnsubscribe),
  extractPayload(),
  switchMap(async ({ surveyId, surveyName, token, orgLabel }) => {
    const confirm = Boolean(await confirmUnsubscribeModal(surveyName));
    dispatchAction(setSurveyOverviewSearchParams(undefined));
    if (!confirm) return;

    dispatchAction(
      postSurveyDigestUnsubscribe({
        surveyId,
        token,
        orgLabel,
      })
    );
  })
);

const handlePostSurveyDigestUnsubscribe = routine(
  ofType(postSurveyDigestUnsubscribe),
  extractPayload(),
  switchMap(({ surveyId, token, orgLabel }) =>
    surveyApi.postSurveyNotificationUnsubscription(surveyId, token, orgLabel)
  ),
  handleError(api.logErrorIfNeeded),
  tap(unsubscriptionSuccessModal)
);

const handleSaveSurvey = routine(
  ofType(saveSurvey),
  extractPayload(),
  switchMap(surveyApi.update),
  handleError(errorHandler),
  tap(response => {
    dispatchAction(notifySavingSurveySucceeded());
    if (response._version && response._version > 1) {
      trackEvent('Updated survey');
    }
  })
);

const handleSaveNewSurvey = routine(
  ofType(saveNewSurvey),
  extractPayload(),
  switchMap(surveyApi.createWithPermissions),
  handleError(errorHandler),
  tap(surveyResponse => dispatchAction(notifyNewSurveySaved(surveyResponse)))
);

const handleSaveNewSurveyInFolder = routine(
  ofType(saveNewSurveyInFolder),
  extractPayload(),
  switchMap(({ survey, folderId }) =>
    surveyApi.createInFolder(survey, folderId)
  ),
  handleError(errorHandler),
  tap(surveyResponse => dispatchAction(notifyNewSurveySaved(surveyResponse)))
);

const handleNotifyNewSurveySaved = routine(
  ofType(notifyNewSurveySaved),
  extractPayload(),
  withLatestFrom(currentUser$),
  tap(([surveyResponse, currentUser]) => {
    dispatchActionAndWaitForResponse(
      fetchAllRequested(surveysNamespace),
      fetchAllSucceeded,
      fetchAllFailed,
      surveysNamespace
    )
      .then(({ payload }) => payload)
      .then(result => {
        if (isArdoqError(result)) {
          surveyAlert({
            entity: 'Surveys',
            action: 'fetched',
            error: result,
          });
          return;
        }
        dispatchAction(notifySavingSurveySucceeded());
        dispatchAction(
          addPermissionForResource({
            currentUser,
            permission: surveyResponse.permission,
          })
        );
        trackEvent('Created survey');
        requestEditSurvey({
          surveyId: surveyResponse.survey._id,
          newSurveyWasSaved: true,
        });
      });
  })
);

const handleNavigateToSurveyForm = routine(
  ofType(navigateToSurveyForm),
  extractPayload(),
  tap(surveyId => {
    if (!surveyId) {
      dispatchAction(fetchTags());
      if (hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2)) {
        dispatchAction(fetchPermissionGroups());
      }
      dispatchAction(openNewSurvey(null));
      dispatchAction(
        requestShowAppModule({ selectedModule: AppModules.SURVEY_ADMIN })
      );
      return;
    }
    requestEditSurvey({ surveyId });
  })
);

const handleNavigateToChangeApprovalPage = routine(
  ofType(navigateToChangeApprovalPage),
  extractPayload(),
  tap(surveyId => {
    dispatchAction(fetchOrgUsers());
    dispatchAction(fetchChangeApprovalData(surveyId));
    dispatchAction(selectSurveyId({ surveyId }));
    dispatchAction(selectSurveyMode({ mode: SurveyAdminMode.CHANGE_APPROVAL }));
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.SURVEY_ADMIN })
    );
  })
);

const handleFetchPendingApprovals = routine(
  ofType(fetchPendingApprovalsSummary),
  switchMap(surveyApi.fetchPendingApprovalsSummary),
  handleError(error => {
    dispatchAction(notifyFetchingPendingApprovalsFailed());
    surveyAlert({
      action: 'fetched',
      entity: 'Surveys with pending approvals',
      error,
    });
  }),
  tap(response => {
    dispatchAction(notifyFetchingPendingApprovalsSucceeded(response));
  })
);

const handleSaveSurveySettings = routine(
  ofType(saveSurveySettings),
  tap(() => dispatchAction(saveOrganization()))
);

const handleSaveOrganization = routine(
  ofType(saveOrganization),
  withLatestFrom(organizations$, currentUser$, surveyAdmin$),
  switchMap(
    async ([, { byId: organizations }, currentUser, { surveySettings }]) => {
      const currentOrganization = organizations[currentUser.organization._id];
      const updatedOrganization = {
        ...currentOrganization,
        settings: {
          ...currentOrganization.settings,
          logo: surveySettings.organizationLogo,
        },
      };
      const response =
        await organizationInterface.saveOrganization(updatedOrganization);
      if (isArdoqError(response)) {
        dispatchAction(
          notifySavingSurveySettingsFailed(
            "Something went wrong and your logo couldn't be saved. Please try again or contact customer support."
          )
        );
        surveyAlert({
          action: 'saved',
          entity: 'Organization logo',
          error: response,
        });
        logError(response, 'Failed to save organization logo');
      }
      dispatchAction(notifySavingSurveySettingsSucceeded());
    }
  )
);

const handleToggleSurveySettingsSidebar = routine(
  ofType(toggleSurveySettingsSidebar),
  filter(() => surveyAdmin$.state.surveySettings.isSidebarOpen),
  switchMap(organizationInterface.fetchCurrentOrganization),
  withLatestFrom(currentOrganization$),
  handleError(),
  tap(([, organization]) => {
    dispatchAction(
      setSurveySettingsSidebarData({
        organizationLogo: organization.settings.logo,
        isOrgLogoValid: true,
      })
    );
  })
);

export default collectRoutines(
  handleConfirmSurveyDigestUnsubscribe,
  handlePostSurveyDigestUnsubscribe,
  handleSaveSurvey,
  handleSaveNewSurvey,
  handleSaveNewSurveyInFolder,
  handleNavigateToSurveyForm,
  handleFetchPendingApprovals,
  handleNavigateToChangeApprovalPage,
  handleSaveOrganization,
  handleToggleSurveySettingsSidebar,
  handleSaveSurveySettings,
  handleFetchSurveyErrors,
  handleNotifyNewSurveySaved
);
