import { Island, IslandHeader } from '@ardoq/page-layout';
import { TabButton, TabButtonProps, TabsGroup } from '@ardoq/tabs';
import { RolesAndPrivilegesDetails } from './RolesAndPrivilegesDetails';
import { UserProfilePageProps } from '../types';
import { UserGroupsDetails } from './UserGroupsDetails';
import { UserAssetsOverviewTab } from './UserAssetsOverviewTab';
import { StatusType } from '@ardoq/status-ui';
import currentUserModel from 'models/currentUser';
import { PersonalSetting } from '@ardoq/api-types';

export const UserRoleAndGroupsDetails = (props: UserProfilePageProps) => {
  const { activeTabId, commands, user, userGroups } = props;

  const tabs: TabButtonProps[] = [
    {
      label: 'Role and privileges',
      tabId: 'roles',
    },
    {
      label: 'Groups',
      tabId: 'groups',
    },
    {
      label: 'Assets',
      tabId: 'assets',
      statusType: !currentUserModel.getPersonalSetting(
        PersonalSetting.USER_ASSETS_OVERVIEW_TAB_VISITED
      )
        ? StatusType.NEW
        : undefined,
    },
  ];

  return (
    <Island
      bodySpacing="large"
      maxWidth="100%"
      header={
        <IslandHeader skipVerticalPadding>
          <TabsGroup
            activeTabId={activeTabId}
            onTabChange={tabId => {
              commands.changeUserProfilePageTab(user, userGroups, `${tabId}`);
              commands.closeUserAssetsFilterSidebar();
              if (
                tabId === 'assets' &&
                !currentUserModel.getPersonalSetting(
                  PersonalSetting.USER_ASSETS_OVERVIEW_TAB_VISITED
                )
              ) {
                currentUserModel.setPersonalSetting(
                  PersonalSetting.USER_ASSETS_OVERVIEW_TAB_VISITED,
                  'true'
                );
              }
            }}
          >
            {tabs.map(tab => (
              <TabButton key={tab.tabId} {...tab} />
            ))}
          </TabsGroup>
        </IslandHeader>
      }
    >
      {activeTabId === 'roles' && <RolesAndPrivilegesDetails {...props} />}
      {activeTabId === 'groups' && <UserGroupsDetails {...props} />}
      {activeTabId === 'assets' && <UserAssetsOverviewTab {...props} />}
    </Island>
  );
};
