import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  loadUseCaseSuccess,
  loadUseCase,
  loadUseCaseFailed,
  selectUseCase,
  setActiveLandingPageTab,
} from './UseCaseLandingPage/actions';
import { switchMap } from 'rxjs';
import { handleError, useCaseApi } from '@ardoq/api';
import { tap, withLatestFrom } from 'rxjs/operators';
import { showToast, ToastType } from '@ardoq/status-ui';
import { logError } from '@ardoq/logging';
import { navigateToUseCases } from './actions';
import {
  trackSelectedUseCase,
  trackNavigatedToSolutionsPage,
  viewedSolutionMaterialsTab,
  trackLoadingUseCaseFailed,
} from './tracking';
import useCases$ from './useCases$';
import { UseCaseLandingPageTab } from './UseCaseLandingPage/types';
import useCaseLandingPage$ from './UseCaseLandingPage/useCaseLandingPage$';
import { ArdoqError } from '@ardoq/common-helpers';
import { augmentationModalRoutines } from './UseCaseLandingPage/augmentations/routines';

const logErrorAndNotify = (error: ArdoqError) => {
  logError(error, 'Got error when loading use case');
  dispatchAction(loadUseCaseFailed(error));
};

const handleLoadUseCase = routine(
  ofType(loadUseCase),
  extractPayload(),
  switchMap(useCaseApi.loadUseCase),
  handleError(logErrorAndNotify),
  tap(({ id }) => {
    showToast('Please refresh to view solution materials.', ToastType.INFO);
    showToast(
      'Solution materials added to the organization.',
      ToastType.SUCCESS
    );
    dispatchAction(loadUseCaseSuccess(id));
  })
);

const trackNavigatedToUseCases = routine(
  ofType(navigateToUseCases),
  tap(trackNavigatedToSolutionsPage)
);

const trackUserSelectedUseCase = routine(
  ofType(selectUseCase),
  extractPayload(),
  withLatestFrom(useCases$),
  tap(([useCaseId, useCases]) => {
    const name = useCases.find(useCase => useCase._id === useCaseId)?.name;
    if (name) trackSelectedUseCase(name);
  })
);

const trackedUserViewedSolutionMaterialsTab = routine(
  ofType(setActiveLandingPageTab),
  extractPayload(),
  withLatestFrom(useCaseLandingPage$),
  tap(([activeTab, { selectedUseCase }]) => {
    if (
      activeTab === UseCaseLandingPageTab.SOLUTION_MATERIAL &&
      selectedUseCase
    )
      viewedSolutionMaterialsTab(selectedUseCase.name);
  })
);

const trackLoadUseCaseFailed = routine(
  ofType(loadUseCaseFailed),
  withLatestFrom(useCaseLandingPage$),
  tap(([, { selectedUseCase }]) => {
    if (selectedUseCase) trackLoadingUseCaseFailed(selectedUseCase.name);
  })
);

export default collectRoutines(
  handleLoadUseCase,
  trackNavigatedToUseCases,
  trackUserSelectedUseCase,
  trackedUserViewedSolutionMaterialsTab,
  trackLoadUseCaseFailed,
  augmentationModalRoutines
);
