import {
  composeFiltersFromFields,
  createGenericFilter,
  COMPONENT_NAME,
  CREATED,
  CREATED_BY,
  DISPLAY_TEXT,
  LAST_MODIFIED_BY,
  LAST_UPDATED,
  TAGS,
  QueryBuilderFilterDefinition,
  QueryBuilderFilterDefinitionGroup,
  QueryBuilderFilterType,
  replaceNonPerspectiveOperators,
  SearchContext,
} from '@ardoq/query-builder';
import { FieldGlobalAttributes } from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';

type Options = {
  customFields: FieldGlobalAttributes[];
};

export type QueryBuilderFilterDefinitionGroups = {
  defaultFilterDefinitions: QueryBuilderFilterDefinition[];
  customFilterDefinitions: QueryBuilderFilterDefinition[];
};

export const toLabeledGroups = (
  groups: QueryBuilderFilterDefinitionGroups
): QueryBuilderFilterDefinitionGroup[] => {
  return [
    {
      label: 'Default',
      filters: groups.defaultFilterDefinitions,
    },
    groups.customFilterDefinitions.length
      ? {
          label: 'Custom fields',
          filters: groups.customFilterDefinitions,
        }
      : null,
  ].filter(ExcludeFalsy);
};

const composeViewpointBuilderFilterGroups = ({
  customFields,
  extraDefaultFields,
}: Options & {
  extraDefaultFields: QueryBuilderFilterDefinition[];
}): QueryBuilderFilterDefinitionGroups => {
  // modify the global property of the mock fields, otherwise composeFiltersFromFields will filter them out.
  // we want to see these fields in the query builder.
  const globalisedCustomFields = customFields.map(field => ({
    ...field,
    global: true,
  }));

  const customFilters = composeFiltersFromFields(
    globalisedCustomFields,
    SearchContext.COMPONENT
  );

  return {
    defaultFilterDefinitions: [
      CREATED_BY,
      CREATED,
      LAST_MODIFIED_BY,
      LAST_UPDATED,
      ...extraDefaultFields,
    ].map(replaceNonPerspectiveOperators),
    customFilterDefinitions: customFilters.map(replaceNonPerspectiveOperators),
  };
};

/**
 * This function composes the filter groups for the viewpoint components.
 * It's meant for viewpoint builder 2.0.
 */
export const composeViewpointBuilderComponentsFilterGroups = ({
  customFields,
}: Options): QueryBuilderFilterDefinitionGroups => {
  return composeViewpointBuilderFilterGroups({
    customFields,
    extraDefaultFields: [
      createGenericFilter(
        'rootWorkspace',
        'Belongs to workspace',
        QueryBuilderFilterType.ASYNC_ROOT_WORKSPACE
      ),
      COMPONENT_NAME,
      TAGS,
    ],
  });
};

export const composeViewpointBuilderReferencesFilterGroups = ({
  customFields,
}: Options): QueryBuilderFilterDefinitionGroups => {
  return composeViewpointBuilderFilterGroups({
    customFields,
    extraDefaultFields: [TAGS, DISPLAY_TEXT],
  });
};
