import { APIComponentAttributes } from '@ardoq/api-types';
import { ComponentSearchData } from './types';
import { componentInterface } from '@ardoq/component-interface';
import { searchTraversalInterface } from 'traversals/selectTraversalForContextComponentsModal/searchTraversalInterface';
import { workspaceInterface } from '@ardoq/workspace-interface';

const buildSearchDataFromComponentId = (
  componentId: string
): ComponentSearchData | null => {
  const component = componentInterface.getComponentData(componentId);
  if (!component) {
    return null;
  }
  return buildSearchDataFromComponent(component);
};

const buildSearchDataFromComponent = (
  component: APIComponentAttributes
): ComponentSearchData | null => {
  const representationData =
    searchTraversalInterface.getEnhancedRepresentationData(component.id);
  if (!representationData) {
    return null;
  }

  const {
    name,
    type: entityType,
    rootWorkspace,
    lastModifiedByName,
    createdByName,
  } = component;

  return {
    id: component._id,
    name,
    entityType,
    rootWorkspaceName: workspaceInterface.getWorkspaceName(rootWorkspace) ?? '',
    lastModifiedByName,
    createdByName,
    representationData,
  };
};

export const componentSearchInterface = {
  buildSearchDataFromComponentId,
  buildSearchDataFromComponent,
};
