import { combineLatest } from 'rxjs';
import reportNavigation$ from '../navigation/reportNavigation$';
import currentUserPermissionContext$ from '../../streams/currentUserPermissions/currentUserPermissionContext$';
import subdivisions$ from '../../streams/subdivisions/subdivisions$';
import reportReader$ from './reportReader$';
import { contextPresentation$ } from '../../streams/presentations/contextPresentation$';
import { fetchSearchResults, getSearchResults$ } from './searchResults$';
import { filter, map } from 'rxjs/operators';
import { Features, hasFeature } from '@ardoq/features';
import { getReportReaderCommands } from './commands/reportReaderCommands';
import { getNavBarButtonsProps } from './props/navBarButtonProps';
import { getNavBarCommands } from './commands/navBarCommands';
import { ReportReaderContainerProps } from './ReportReaderContainer';
import {
  ReportEventLocations,
  ReportReaderProps,
  SortFilterPagination,
} from '@ardoq/report-reader';
import { getCurrentLocale } from '@ardoq/locale';
import {
  getComponentLinkHandler,
  getHasComponentReadPermission,
  prepareGetComponentNavigationOptions,
} from './utils';
import { openHome } from 'components/AppMainSidebar/utils';
import { trackEvent } from 'tracking/tracking';
import { reportAccessControlInterface } from 'resourcePermissions/accessControlHelpers/report';

export const reportReaderViewModel$ = combineLatest([
  reportNavigation$.pipe(
    map(({ reportId }) => reportId),
    filter(Boolean) // Report id is always defined when you open the report reader, but doing this will make sure the type for reportId is always string and not string | undefined
  ),
  currentUserPermissionContext$,
  subdivisions$,
  reportReader$,
  contextPresentation$,
  getSearchResults$(fetchSearchResults),
]).pipe(
  map(
    ([
      reportId,
      permissionContext,
      subdivisionsContext,
      {
        hasExcelExportFailed,
        sort,
        page,
        pagination,
        isSearchBeingExecuted,
        filterQuery: reportReaderFilterQuery,
      },
      presentation,
      { apiError, searchResults, filterableColumns },
    ]): ReportReaderContainerProps => {
      const filterQuery = reportReaderFilterQuery;
      const sortFilterPagination: SortFilterPagination = {
        sort,
        page,
        pagination,
        filterQuery,
      };
      const navBarButtonProps = getNavBarButtonsProps(
        getNavBarCommands(
          reportId,
          searchResults,
          filterQuery,
          sortFilterPagination
        ),
        reportId,
        searchResults,
        presentation,
        permissionContext
      );

      const reportReaderProps: ReportReaderProps = {
        apiError,
        commands: getReportReaderCommands(
          filterQuery,
          reportId,
          searchResults,
          presentation?._id
        ),
        currentUserLocale: getCurrentLocale(),
        filterableColumns,
        getComponentLinkHandler,
        getComponentNavigationOptions: prepareGetComponentNavigationOptions(
          permissionContext,
          subdivisionsContext
        ),
        getHasComponentReadPermission: searchResults?.scopeData
          ? getHasComponentReadPermission(
              searchResults.scopeData,
              permissionContext,
              subdivisionsContext
            )
          : undefined,
        hasExcelExportFailed,
        hasSubdivisionsFeature: hasFeature(Features.PERMISSION_ZONES),
        isSearchBeingExecuted,
        openHome,
        reportId,
        reportReaderLocation: ReportEventLocations.ARDOQ as const,
        trackEvent,
        searchResults,
        sortFilterPagination,
        userHasReadAccess: Boolean(
          reportId &&
            reportAccessControlInterface.canAccessReport({
              reportId,
              permissionContext,
            })
        ),
      };

      return {
        navBarButtonProps,
        reportReaderProps,
        reportName: searchResults?.name ?? 'Report',
      };
    }
  )
);
