import { AsyncOperations } from 'integrations/common/async/constants';
import {
  ensureCorrectAsyncRequest,
  getAsyncRequestId,
  parseAsyncProgressResponsePayload,
  parseAsyncResponsePayload,
} from 'integrations/common/async/utils';
import {
  resetIntegration,
  setIntegrationName,
  setSourceId,
} from 'integrations/common/streams/activeIntegrations/actions';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { connections$ } from 'integrations/common/streams/connections/connections$';
import { setTablePreviews } from 'integrations/common/streams/tablePreviews/actions';
import { TablePreview } from 'integrations/common/streams/tablePreviews/types';
import {
  isUnifiedIntegrationId,
  isIntegrationWithProgress,
} from 'integrations/unified/utils';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { filter, map, tap, withLatestFrom } from 'rxjs';
import { userEvent } from 'sync/actions';
import { resources$ } from '../resources/resources$';
import { selectedResourcesToSourceConfigResources } from '../resources/utils';
import {
  loadDataFailure,
  loadDataInit,
  loadDataSuccess,
  resetIntegration as resetIntegrationDataLoading,
} from './actions';
import {
  DataLoadingResponse,
  isDataLoadingErrorResponse,
  isDataLoadingSuccessResponse,
} from './types';
import { tablePreviews$ } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { tabularMappings$ } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { recalculateFilterColumnsIndexes } from 'integrations/common/streams/tabularMappings/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { ImportRoute } from 'integrations/common/navigation/types';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { setFetchSelectionDataSuccess } from 'integrations/common/streams/transferState/actions';
import { startProgressModal } from 'integrations/common/modals/progressModal/ProgressModal';
import { setFetchProgress } from 'integrations/common/streams/fetchProgress/actions';
import {
  fetchSelection,
  fetchSelectionError,
  loadSelection,
} from 'integrations/common/streams/selectionState/actions';
import { UnifiedIntegrationId } from 'integrations/unified/types';

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  tap(id => {
    if (isUnifiedIntegrationId(id)) {
      dispatchAction(
        resetIntegrationDataLoading({
          integrationId: id,
        })
      );
    }
  })
);

const handleLoadData = routine(
  ofType(loadSelection),
  extractPayload(),
  filter(isUnifiedIntegrationId),
  withLatestFrom(activeIntegrations$, connections$, resources$),
  map(([integrationId, activeIntegrations, connections, resources]) => ({
    integrationId,
    funnelId: activeIntegrations[integrationId].trackingFunnelId,
    connectionId: connections[integrationId].selectedConnectionIds[0],
    resources: resources[integrationId],
  })),
  tap(async ({ integrationId, funnelId, connectionId, resources }) => {
    if (!connectionId) {
      return;
    }
    const payload = {
      connectionId,
      resources: selectedResourcesToSourceConfigResources(
        resources.selectedResources
      ),
    };

    if (!payload) {
      return;
    }

    if (isIntegrationWithProgress(integrationId)) {
      startProgressModal({
        integrationId,
        totalCount: Object.keys(resources.selectedResources).length,
      });
    }

    dispatchAction(loadDataInit({ integrationId, payload }));

    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'TRIGGERED_SOURCE_DATA_FETCH',
        metadata: {
          numberOfTables: payload.resources.length,
        },
      })
    );

    dispatchAction(
      fetchSelection({
        integrationId,
        payload,
        requestId: getAsyncRequestId({
          integrationId,
          operation: AsyncOperations.UNIFIED_FETCH_DATA,
          funnelId,
        }),
      })
    );
  })
);

const handleLoadDataAsync = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncResponsePayload<DataLoadingResponse>),
  withLatestFrom(activeIntegrations$),
  ensureCorrectAsyncRequest(AsyncOperations.UNIFIED_FETCH_DATA),
  tap(([response]) => {
    if (!response) return;
    if (isUnifiedIntegrationId(response.integrationId)) {
      if (isDataLoadingErrorResponse(response.data)) {
        dispatchAction(
          loadDataFailure({
            integrationId: response.integrationId,
            level: 'error',
            message:
              response.data.message || 'An error occurred when fetching data.',
          })
        );
        dispatchAction(
          setFetchProgress({
            integrationId: response.integrationId,
            status: 'FAILURE',
          })
        );
        return;
      }
      if (isDataLoadingSuccessResponse(response.data)) {
        dispatchAction(
          loadDataSuccess({
            response: response.data,
            integrationId: response.integrationId,
          })
        );
        dispatchAction(
          setFetchProgress({
            integrationId: response.integrationId,
            status: 'SUCCESS',
          })
        );
      }
    }
  })
);

const handleLoadDataAsyncProgress = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncProgressResponsePayload),
  withLatestFrom(activeIntegrations$),
  filter(([response]) => isIntegrationWithProgress(response?.integrationId)),
  ensureCorrectAsyncRequest(AsyncOperations.UNIFIED_FETCH_DATA),
  tap(([response]) => {
    if (!response) return;
    if (isUnifiedIntegrationId(response.integrationId)) {
      dispatchAction(
        setFetchProgress({
          integrationId: response.integrationId,
          progress: response.progress,
          status: 'LOADING',
        })
      );
    }
  })
);

const handleLoadDataSuccess = routine(
  ofType(loadDataSuccess),
  extractPayload(),
  withLatestFrom(connections$, tablePreviews$, tabularMappings$),
  tap(
    ([
      { integrationId, response },
      allConnections,
      allTablePreviews,
      allTabularMappings,
    ]) => {
      const { connections, selectedConnectionIds } =
        allConnections[integrationId];
      const oldTablePreviews = allTablePreviews[integrationId];
      const tabularMapping = allTabularMappings[integrationId];

      const connectionName = connections.find(
        c => c._id === selectedConnectionIds[0]
      )?.name;

      dispatchAction(setFetchSelectionDataSuccess({ integrationId }));

      dispatchAction(
        setIntegrationName({
          integrationId,
          name: response.sourceName || connectionName || 'untitled',
        })
      );

      dispatchAction(setSourceId({ integrationId, id: response.id }));

      const tablePreviews: TablePreview[] = response.tables.map(table => ({
        ...table,
        sourceFieldNames: table.columns.map(({ id }) => id),
      }));

      dispatchAction(
        recalculateFilterColumnsIndexes({
          integrationId,
          oldTablePreviews: Object.values(oldTablePreviews),
          newTablePreviews: tablePreviews,
          mapping: tabularMapping,
        })
      );

      dispatchAction(
        setTablePreviews({
          integrationId,
          tablePreviews,
        })
      );
      dispatchAction(
        navigateToPath({ integrationId, path: ImportRoute.CONFIGURE })
      );
    }
  )
);

const handleFetchSelectionError = routine(
  ofType(fetchSelectionError),
  extractPayload(),
  filter(({ integrationId }) => isUnifiedIntegrationId(integrationId)),
  tap(({ integrationId }) => {
    dispatchAction(
      loadDataFailure({
        integrationId: integrationId as UnifiedIntegrationId,
        level: 'error',
        message: 'An error occurred when fetching data.',
      })
    );
  })
);

export default [
  handleResetIntegration,
  handleLoadData,
  handleLoadDataAsync,
  handleLoadDataAsyncProgress,
  handleLoadDataSuccess,
  handleFetchSelectionError,
];
