import { AppliedSettingsRow } from './AppliedSettingsRow';
import { IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { GroupingSetting } from './types';
import { Tag } from '@ardoq/status-ui';
import { WithPopover } from '@ardoq/popovers';
import styled from 'styled-components';
import { text2Bold } from '@ardoq/typography';
import { PopoverListItem } from './PopoverListItem';

const PopoverHeader = styled.span`
  ${text2Bold}
`;

type GroupSettingsProps = {
  settings: GroupingSetting;
};

export const GroupSettings = ({ settings }: GroupSettingsProps) => {
  const { onEditClick, appliedGroupingRuleDescriptions } = settings;

  const rulesCount = appliedGroupingRuleDescriptions.length;

  const popoverContent = rulesCount
    ? () => (
        <FlexBox flexDirection="column" maxWidth="270px">
          <PopoverHeader>{`${rulesCount} groups is applied, by order:`}</PopoverHeader>
          <FlexBox flexDirection="column" paddingLeft="small">
            {appliedGroupingRuleDescriptions.map(rule => (
              <PopoverListItem text={rule} key={rule} />
            ))}
          </FlexBox>
        </FlexBox>
      )
    : null;

  const tag = (
    <WithPopover content={popoverContent} key="Groups count label">
      <Tag label={rulesCount.toString()} />
    </WithPopover>
  );

  return (
    <AppliedSettingsRow
      label="Groups"
      tags={[tag]}
      icon={IconName.APPLICATION_GROUP}
      onEditClick={onEditClick}
      isDisabled={!rulesCount}
    />
  );
};
