import { trackEvent } from '../tracking/tracking';
import { APIScopeData } from '@ardoq/api-types';

const trackOldVersion = (version: string) => {
  trackEvent('Decoding loaded state with old version', { version });
};

const trackExecutionResult = (scopeData: APIScopeData) => {
  const references = scopeData.references.length;
  const components = scopeData.scopeComponents.length;
  trackEvent('Viewpoint builder0 dataset executed', {
    references,
    components,
  });
};

export const loadedStateTracking = {
  trackOldVersion,
  trackExecutionResult,
};
