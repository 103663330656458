import { Select } from '@ardoq/select';
import {
  GriddedTableRow,
  SelectAttributeContainer,
  TableHeaderIcon,
  TableHeaderLabel,
} from './atoms';
import { CONDITION_OPTIONS_BY_TYPE, STRINGS } from './consts';
import { TextInput } from '@ardoq/forms';
import { IconButton } from '@ardoq/button';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import {
  PopoverPlacement,
  WithPopover,
  WithPopoverProps,
} from '@ardoq/popovers';
import { colors } from '@ardoq/design-tokens';
import { StatusType, Tag } from '@ardoq/status-ui';

import { MappingRulesTableProps, PreviewAttributeType } from './types';
import {
  isValidClauseAttribute,
  isValidClauseCondition,
  isValidClauseProvider,
  isValidClauseValue,
  isValidGroupId,
} from './validators';
import { connect } from '@ardoq/rxbeach';
import viewModel$ from './viewModel$';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

const TableHeaderInfo = ({ content }: Pick<WithPopoverProps, 'content'>) => (
  <WithPopover content={content} preferredPlacement={PopoverPlacement.BOTTOM}>
    <TableHeaderIcon iconName={IconName.INFO} iconSize={IconSize.MEDIUM} />
  </WithPopover>
);

const MappingRulesTableHeader = ({
  showSsoProviderColumn,
}: {
  showSsoProviderColumn: boolean;
}) => (
  <GriddedTableRow
    $hideBorder={true}
    $showSsoProviderColumn={showSsoProviderColumn}
  >
    <TableHeaderLabel>
      {STRINGS.MAPPINGRULESTABLE.HEADER.ATTRIBUTE_NAME}
      <TableHeaderInfo
        content={STRINGS.MAPPINGRULESTABLE.HEADER.ATTRIBUTE_TYPE_POPOVER}
      />
    </TableHeaderLabel>
    <TableHeaderLabel>
      {STRINGS.MAPPINGRULESTABLE.HEADER.CONDITION}
    </TableHeaderLabel>
    <TableHeaderLabel>
      {STRINGS.MAPPINGRULESTABLE.HEADER.VALUE}
      <TableHeaderInfo
        content={STRINGS.MAPPINGRULESTABLE.HEADER.VALUE_POPOVER}
      />
    </TableHeaderLabel>
    {showSsoProviderColumn && (
      <TableHeaderLabel>
        {STRINGS.MAPPINGRULESTABLE.HEADER.SSO_PROVIDER}
        <TableHeaderInfo
          content={STRINGS.MAPPINGRULESTABLE.HEADER.SSO_PROVIDER_POPOVER}
        />
      </TableHeaderLabel>
    )}
    <TableHeaderLabel>
      {STRINGS.MAPPINGRULESTABLE.HEADER.ASSIGNMENT_RULES}
    </TableHeaderLabel>
  </GriddedTableRow>
);

const MappingStatusIcon = ({
  hasChanged,
  hasSelectedClauseProvider,
  allowMultipleSsoOptionsInMappings,
}: {
  hasChanged: boolean;
  hasSelectedClauseProvider: boolean;
  allowMultipleSsoOptionsInMappings: boolean;
}) => {
  if (hasChanged) {
    return (
      <WithPopover
        content={STRINGS.MAPPINGRULESTABLE.POPOVER.SAVE_TO_APPLY}
        preferredPlacement={PopoverPlacement.TOP}
      >
        <Icon iconName={IconName.WARNING} style={{ color: colors.yellow50 }} />
      </WithPopover>
    );
  }

  if (allowMultipleSsoOptionsInMappings && !hasSelectedClauseProvider) {
    return (
      <WithPopover
        content={
          STRINGS.MAPPINGRULESTABLE.POPOVER
            .DEACTIVATED_DUE_TO_MISSING_SSO_PROVIDER
        }
        preferredPlacement={PopoverPlacement.TOP}
      >
        <Icon
          iconName={IconName.INFO}
          style={{ color: colors.grey50, margin: 0 }}
        />
      </WithPopover>
    );
  }

  return (
    <WithPopover
      content={STRINGS.MAPPINGRULESTABLE.POPOVER.IN_USE}
      preferredPlacement={PopoverPlacement.TOP}
    >
      <Icon
        iconName={IconName.CHECK_CIRCLE}
        style={{ color: colors.green50 }}
      />
    </WithPopover>
  );
};

const MappingRulesTable = ({
  currentMapping,
  attributeTypeOptions,
  conditionOptionsByAttribute,
  groupOptions,
  ssoOptions,
  allowMultipleSsoOptionsInMappings,
  showValidationErrors,
  mappedGroupsCount,
  changes,
  commands,
}: MappingRulesTableProps) => {
  const sortedItems = useMemo(() => {
    return Object.values(currentMapping).sort(
      (first, second) => first.created.getTime() - second.created.getTime()
    );
  }, [currentMapping]);

  return !isEmpty(currentMapping) ? (
    <>
      <MappingRulesTableHeader
        showSsoProviderColumn={allowMultipleSsoOptionsInMappings}
      />
      {sortedItems.map(({ _id, action, clause }) => (
        <GriddedTableRow
          key={_id}
          $showSsoProviderColumn={allowMultipleSsoOptionsInMappings}
        >
          <SelectAttributeContainer>
            <MappingStatusIcon
              hasChanged={Boolean(changes[_id])}
              hasSelectedClauseProvider={Boolean(clause.provider)}
              allowMultipleSsoOptionsInMappings={
                allowMultipleSsoOptionsInMappings
              }
            />
            <Select
              placeholder={STRINGS.SELECT_ATTRIBUTE_TYPE.PLACEHOLDER}
              options={attributeTypeOptions}
              value={clause.attribute}
              onChange={selected =>
                commands.updateMapping(_id, 'attribute', selected?.value)
              }
              hasError={
                showValidationErrors &&
                !isValidClauseAttribute(clause.attribute)
              }
            />
          </SelectAttributeContainer>
          <Select
            options={
              conditionOptionsByAttribute[clause.attribute] ||
              CONDITION_OPTIONS_BY_TYPE[PreviewAttributeType.UNKNOWN]
            }
            value={clause.condition}
            onChange={selected =>
              commands.updateMapping(_id, 'condition', selected?.value)
            }
            hasError={
              showValidationErrors && !isValidClauseCondition(clause.condition)
            }
          />
          <TextInput
            value={clause.value}
            onChange={event =>
              commands.updateMapping(_id, 'value', event.target.value)
            }
            hasError={showValidationErrors && !isValidClauseValue(clause.value)}
          ></TextInput>
          {allowMultipleSsoOptionsInMappings && (
            <Select
              options={ssoOptions}
              value={clause.provider}
              onChange={selected =>
                commands.updateMapping(_id, 'provider', selected?.value)
              }
              hasError={
                showValidationErrors &&
                !isValidClauseProvider(clause.provider) &&
                Boolean(changes[_id])
              }
            />
          )}
          <Select
            placeholder={STRINGS.SELECT_GROUP.PLACEHOLDER}
            options={groupOptions.map(({ value, label, ...option }) => ({
              ...option,
              value,
              label,
              rightContent: (
                <>
                  {mappedGroupsCount[value] ? (
                    <Tag
                      label={STRINGS.RULE_COUNT}
                      count={mappedGroupsCount[value]}
                      statusType={StatusType.INFO}
                    />
                  ) : null}
                </>
              ),
            }))}
            value={action.groupId}
            onChange={selected =>
              commands.updateMapping(_id, 'groupId', selected?.value)
            }
            hasError={showValidationErrors && !isValidGroupId(action.groupId)}
          />
          <IconButton
            iconName={IconName.CLOSE}
            onClick={() => commands.deleteMapping(_id)}
            data-tooltip-text={STRINGS.BUTTON.REMOVE_POPOVER}
          />
        </GriddedTableRow>
      ))}
    </>
  ) : (
    <></>
  );
};

export default connect(MappingRulesTable, viewModel$);
