import { useController } from 'react-hook-form';
import { TextInput } from '@ardoq/forms';
import { getMaxLengthWarnings } from './utils';

const MAX_LENGTH = 60;

const NameInput = () => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'name',
    rules: {
      required: { value: true, message: 'Viewpoint must have a name.' },
    },
  });
  return (
    <TextInput
      {...field}
      errorMessage={error?.message}
      label="Give your Viewpoint a descriptive name"
      {...getMaxLengthWarnings(
        field.value,
        MAX_LENGTH,
        'Long names may be truncated.'
      )}
    />
  );
};

export default NameInput;
