import { Button, ButtonGroup, ButtonType } from '@ardoq/button';
import React from 'react';
import { SubdivisionProps } from './types';
import { subdivisionEditorStepsOperations } from './subdivisionEditorStepsOperations';
import { WithPopover } from '@ardoq/popovers';
import { Box } from '@ardoq/layout';

export const SubdivisionEditorFooter = (props: SubdivisionProps) => {
  const { commands, isSubmitting, missingSubdivision } = props;

  if (missingSubdivision) {
    return null;
  }

  return (
    <ButtonGroup>
      {subdivisionEditorStepsOperations.shouldShowPreviousButton(props) && (
        <Button
          onClick={() => commands.previousStep(props)}
          isDisabled={subdivisionEditorStepsOperations.previousStepIsDisabled(
            props
          )}
          buttonType={ButtonType.SECONDARY}
        >
          {subdivisionEditorStepsOperations.getPreviousStepButtonLabel(props)}
        </Button>
      )}
      {subdivisionEditorStepsOperations.shouldShowNextButton(props) && (
        <WithPopover
          content={subdivisionEditorStepsOperations.getNextButtonHintMessage(
            props
          )}
        >
          <Box>
            <Button
              onClick={() => commands.nextStep(props)}
              isDisabled={subdivisionEditorStepsOperations.nextStepIsDisabled(
                props
              )}
              buttonType={ButtonType.PRIMARY}
              isLoading={isSubmitting}
            >
              {subdivisionEditorStepsOperations.getNextStepButtonLabel(props)}
            </Button>
          </Box>
        </WithPopover>
      )}
    </ButtonGroup>
  );
};
