import {
  emptyCollectionStream,
  toCollectionStream,
} from 'streams/utils/streamUtils';
import {
  ArdoqId,
  APIPresentationAssetAttributes,
  ResourceType,
} from '@ardoq/api-types';
import {
  reducedStream,
  reducer,
  streamReducer,
  toPersistentStream,
} from '@ardoq/rxbeach';
import { fetchAllReducer, websocketReducer } from 'streams/crud/reducers';
import { toResourceTypeStream, websocket$ } from 'sync/websocket$';
import { combineLatest, map } from 'rxjs';
import { presentationOperations } from './presentationOperations';
import { PermissionContext } from '@ardoq/access-control';
import { returnPayload } from '@ardoq/common-helpers';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { ardoqEventOperations } from 'sync/ardoqEventOperations';
import { ArdoqEvent } from 'sync/types';
import { updatePresentation } from './actions';

export const presentationsNamespace = 'presentations';
export const isPresentationEvent = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<APIPresentationAssetAttributes> => {
  return ardoqEventOperations.isOfResourceType(
    event,
    ResourceType.PRESENTATION
  );
};

const handleRemovedPresentation = (
  state: APIPresentationAssetAttributes[],
  id: ArdoqId
): APIPresentationAssetAttributes[] => {
  return state.filter(presentation => presentation._id !== id);
};

const handleUpdatedPresentation = (
  state: APIPresentationAssetAttributes[],
  presentation: APIPresentationAssetAttributes
): APIPresentationAssetAttributes[] => {
  return state.map(existingPresentation =>
    existingPresentation._id === presentation._id
      ? presentation
      : existingPresentation
  );
};

const handleAddedPresentation = (
  state: APIPresentationAssetAttributes[],
  presentation: APIPresentationAssetAttributes
): APIPresentationAssetAttributes[] => [presentation, ...state];

const presentationsList$ = reducedStream<APIPresentationAssetAttributes[]>(
  'presentationsList$',
  [],
  [
    fetchAllReducer(returnPayload<APIPresentationAssetAttributes[]>),
    streamReducer(
      toResourceTypeStream<APIPresentationAssetAttributes>(
        websocket$,
        ResourceType.PRESENTATION
      ),
      websocketReducer({
        create: handleAddedPresentation,
        update: handleUpdatedPresentation,
        delete: handleRemovedPresentation,
      })
    ),
  ],
  { namespace: presentationsNamespace }
);

/** An "invisible" presentation is not present in the UI, but is still accessible in the collection.
 * Used for e.g. hiding APM wizard presentations from the user.
 * We filter out invisible presentations here, as this stream is used only for presentations visible to the user.
 * Furthermore, we filter out presentations that are not editable and have no slides.
 */
const getVisiblePresentations = (
  presentations: APIPresentationAssetAttributes[],
  currentUserPermissionContext: PermissionContext
): APIPresentationAssetAttributes[] =>
  presentations.filter(
    presentation =>
      !presentation.invisible &&
      presentationOperations.isEditableOrHasSlides(
        presentation,
        currentUserPermissionContext
      )
  );

const visiblePresentations$ = combineLatest({
  currentUserPermissionContext: currentUserPermissionContext$,
  presentations: presentationsList$,
}).pipe(
  map(({ presentations, currentUserPermissionContext }) => {
    return getVisiblePresentations(presentations, currentUserPermissionContext);
  })
);

const presentations$ = toPersistentStream(
  'presentations$',
  visiblePresentations$.pipe(map(toCollectionStream)),
  emptyCollectionStream()
);

const optimisticUpdatePresentation = (
  state: APIPresentationAssetAttributes[],
  updated: APIPresentationAssetAttributes
) => {
  return state.map(existingPresentation => {
    return existingPresentation._id === updated._id
      ? updated
      : existingPresentation;
  });
};

// This is a hack to get around flickering when doing
// drag and drop with slides. Please don't follow this implementation,
// as it doesn't handle errors correctly
export const optimisticPresentations$ = reducedStream(
  'optimisticPresentations$',
  [],
  [
    streamReducer(
      visiblePresentations$,
      returnPayload<APIPresentationAssetAttributes[]>
    ),
    reducer(updatePresentation, optimisticUpdatePresentation),
  ]
).pipe(map(toCollectionStream));

export default presentations$;
