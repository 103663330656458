import { notifyFiltersChanged } from 'streams/filters/FilterActions';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { getImageColorFilterId, ColorFilters } from '@ardoq/color-helpers';
import { action$, connect, ofType } from '@ardoq/rxbeach';
import { map, startWith } from 'rxjs/operators';
import { defer } from 'rxjs';
import {
  getActiveConditionalFormattingColorForComponent,
  isConditionalFormattingForComponentsActive,
} from 'utils/modelCssManager/getCssColors';
import { ArdoqId } from '@ardoq/api-types';
import { vizFilterGray } from '@ardoq/design-tokens';

/**
 * Returns a filter property url value for the conditional formatting color for the image of a given component.
 * (as in `url(#${getImageColorFilterId(color)})`) or an empty string if no conditional formatting is applied
 * or no modelId provided.
 */
export const getImageColorFilterValueForModel = (modelId?: ArdoqId | null) =>
  isConditionalFormattingForComponentsActive() && modelId
    ? getImageColorFilterValueForColor(
        getActiveConditionalFormattingColorForComponent(modelId)
      )
    : '';

/**
 * Returns a filter property url value for a given conditional formatting color or an empty string if no color
 * provided.
 */
export const getImageColorFilterValueForColor = (color?: ArdoqId | null) =>
  color ? `url(#${getImageColorFilterId(color)})` : '';

const getActiveComponentColors = () => ({
  colors: [
    ...new Set(
      filterInterface
        .getFormattingFilters()
        .filter(({ color, affectComponent }) => color && affectComponent)
        .map(({ color }) => color)
    ),
    // filter gray is not in active filters
    vizFilterGray,
  ],
});

export default connect(
  ColorFilters,
  defer(() =>
    action$.pipe(
      ofType(notifyFiltersChanged),
      map(getActiveComponentColors),
      startWith(getActiveComponentColors())
    )
  )
);
