import { Select } from '@ardoq/select';
import { FormWrapper } from '@ardoq/forms';
import styled from 'styled-components';
import {
  DynamicFilterSelectState,
  DynamicFilterState,
  ParameterOption,
} from '@ardoq/data-model';

const RelativeWidthformWrapper = styled(FormWrapper)`
  width: 100%;

  /* SectionFormElement input[type='text'] is overwriting the height of the select input, 
  this style overwrite is fixing it */
  &&& input[type='text'] {
    height: inherit;
  }
`;

interface DynamicFilterLegendEntryParameterizationProps {
  id: string;
  supportedParams: string[];
  filterState: DynamicFilterState;
  parameterQueryId: string | null;
  parameterOptions: ParameterOption[];
  selectDynamicFilterOption: (
    filterId: string,
    paramName: string,
    paramValue: string
  ) => void;
  fetchDynamicFilterOptions: (parameterQueryId: string) => void;
}
const DynamicFilterLegendEntryParameterization = ({
  id,
  supportedParams,
  filterState,
  parameterQueryId,
  parameterOptions,
  selectDynamicFilterOption,
  fetchDynamicFilterOptions,
}: DynamicFilterLegendEntryParameterizationProps) => {
  const paramName = supportedParams[0];
  const paramValue = paramName && filterState.params[paramName];
  const selectedOption = parameterOptions.find(
    option => option.value === paramValue
  );

  return (
    <RelativeWidthformWrapper>
      <Select
        options={parameterOptions}
        value={
          filterState.state === DynamicFilterSelectState.ACTIVE
            ? selectedOption
            : null
        }
        onValueChange={selected => {
          if (!selected) {
            return;
          }

          selectDynamicFilterOption(id, paramName, selected as string);
        }}
        onFocus={() => fetchDynamicFilterOptions(parameterQueryId as string)}
      />
    </RelativeWidthformWrapper>
  );
};

export default DynamicFilterLegendEntryParameterization;
