import { colors } from '@ardoq/design-tokens';
import { ICON_SELECTOR } from '@ardoq/icons';
import { Box, Stack } from '@ardoq/layout';
import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { Header4, Text } from '@ardoq/typography';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

export const SwitchOrgButtonWrapper = styled.div<{
  $isNotClickable?: boolean;
  $isScenarioMode?: boolean;
}>`
  /* To trigger ellipsis on dropdown for a very long names */
  overflow: hidden;
  /* Make room for button focus ring */
  padding: 3px;

  button {
    background: transparent;
    color: ${({ $isScenarioMode }) =>
      $isScenarioMode ? colors.white : colors.grey80};

    ${ICON_SELECTOR} {
      color: ${({ $isScenarioMode }) =>
        $isScenarioMode ? colors.white : colors.grey80};
    }

    &:hover {
      color: ${colors.white};
      background-color: ${({ $isScenarioMode }) =>
        $isScenarioMode ? colors.grey35 : colors.grey25};
    }
    ${({ $isNotClickable }) =>
      $isNotClickable &&
      css`
        pointer-events: none;
      `}
  }
`;

export const AccessControlFAQSection = ({
  title,
  subtitle,
  children,
}: {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}) => (
  <Box paddingY="large">
    <Stack gap="medium" align="flex-start">
      {(title || subtitle) && (
        <Stack gap="small">
          {title && <Header4 color="textDefault">{title}</Header4>}
          {subtitle && (
            <Text variant="text2" color="textOnInvertedDisabled">
              {subtitle}
            </Text>
          )}
        </Stack>
      )}
      {children}
    </Stack>
  </Box>
);

export const AccessControlFAQExpandableRow = ({
  header,
  children,
  onClick,
}: {
  header: string;
  children: ReactNode;
  onClick?: () => void;
}) => (
  <Box
    style={{ borderBottom: 'none', marginLeft: '-16px', marginRight: '-16px' }}
  >
    <SidebarMenuSection
      gap="medium"
      isCollapsed
      title={
        <Box style={{ textAlign: 'left' }}>
          <Text variant="text1" color="textDefault">
            {header}
          </Text>
        </Box>
      }
      onClick={onClick}
    >
      <Box paddingX="medium">
        <Text variant="text2" color="textOnInvertedDisabled">
          <Stack gap="medium">{children}</Stack>
        </Text>
      </Box>
    </SidebarMenuSection>
  </Box>
);
