import { ExcludeFalsy } from '@ardoq/common-helpers';
import { TabularMapping } from '../tabularMappings/types';
import { uniqBy } from 'lodash';
import {
  TableMapping,
  TableMappingComponents,
  TableMappingReferences,
  TableMappingType,
  IntegrationWorkspace,
} from '@ardoq/api-types/integrations';
import { ColumnMapping } from '../transferConfigs/types';

export const isComponentsTableConfig = (
  config: TableMapping
): config is TableMappingComponents => {
  return config.rowRepresentation === TableMappingType.COMPONENTS;
};

export const isReferencesTableConfig = (
  config?: TableMapping
): config is TableMappingReferences => {
  return config?.rowRepresentation === TableMappingType.REFERENCES;
};

export const extractUsedWorkspaces = (
  mappingConfig: TabularMapping
): IntegrationWorkspace[] => {
  const tableMappingWorkspaces: Array<IntegrationWorkspace | undefined> =
    Object.values(mappingConfig)
      .map(config => [config.rootWorkspace, config.targetWorkspace])
      .flat();

  const columnMappingWorkspaces = Object.values(mappingConfig)
    .map(config =>
      Object.values(config.mappedColumns)
        .map((columnMapping: ColumnMapping) => {
          if ('targetWorkspace' in columnMapping) {
            return [columnMapping.targetWorkspace];
          }
          return [];
        })
        .flat()
    )
    .flat()
    .filter(ExcludeFalsy);

  // when id === null,
  // its a new workspace
  const newWorkspaces = [
    ...tableMappingWorkspaces,
    ...columnMappingWorkspaces,
  ].filter(ExcludeFalsy);

  return uniqBy(newWorkspaces, 'name');
};
