import { s8 } from '@ardoq/design-tokens';
import styled from 'styled-components';

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${s8} 0;
`;

const Italic = styled.div`
  font-style: italic;
`;

type AudiencePreviewDescriptionProps = {
  audienceCount: number;
};
const AudiencePreviewDescription = ({
  audienceCount,
}: AudiencePreviewDescriptionProps) => (
  <DescriptionContainer>
    Audience in total: {audienceCount}
    <Italic>This list automatically updates based on your filter rules.</Italic>
  </DescriptionContainer>
);

export default AudiencePreviewDescription;
