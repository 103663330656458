import {
  SimplifiedSubdivisionSummary,
  SubdivisionSummary,
} from '@ardoq/api-types';
import { SubdivisionSummaryState } from '../subdivisionSummary$/subdivisionSummary$';

const setIsLoadingReducer = (
  subdivisionSummaryState: SubdivisionSummaryState
): SubdivisionSummaryState => ({
  ...subdivisionSummaryState,
  isLoading: true,
  subdivisionSummary: undefined,
  errorMessage: '',
});

const resetIsLoadingReducer = (
  subdivisionSummaryState: SubdivisionSummaryState
): SubdivisionSummaryState => ({
  ...subdivisionSummaryState,
  isLoading: false,
});

const setSubdivisionSummaryAfterLoadingReducer = (
  subdivisionSummaryState: SubdivisionSummaryState,
  subdivisionSummary: SubdivisionSummary
): SubdivisionSummaryState => {
  const stateAfterLoading = resetIsLoadingReducer(subdivisionSummaryState);
  return {
    ...stateAfterLoading,
    subdivisionSummary,
  };
};

const setSubdivisionSummaryErrorAfterLoadingReducer = (
  subdivisionSummaryState: SubdivisionSummaryState,
  errorMessage: string
): SubdivisionSummaryState => {
  const stateAfterLoading = resetIsLoadingReducer(subdivisionSummaryState);
  return {
    ...stateAfterLoading,
    errorMessage,
  };
};

const setSimplifiedSubdivisionSummaryAfterLoadingReducer = (
  subdivisionSummaryState: SubdivisionSummaryState,
  simplifiedSubdivisionSummary: SimplifiedSubdivisionSummary
): SubdivisionSummaryState => {
  const stateAfterLoading = resetIsLoadingReducer(subdivisionSummaryState);
  return {
    ...stateAfterLoading,
    simplifiedSubdivisionSummary,
  };
};

const setSimplifiedSubdivisionSummaryErrorAfterLoadingReducer = (
  subdivisionSummaryState: SubdivisionSummaryState,
  errorMessage: string
): SubdivisionSummaryState => {
  const stateAfterLoading = resetIsLoadingReducer(subdivisionSummaryState);
  return {
    ...stateAfterLoading,
    errorMessage,
  };
};

export const subdivisionSummaryOperations = {
  setIsLoadingReducer,
  resetIsLoadingReducer,
  setSubdivisionSummaryAfterLoadingReducer,
  setSubdivisionSummaryErrorAfterLoadingReducer,
  setSimplifiedSubdivisionSummaryAfterLoadingReducer,
  setSimplifiedSubdivisionSummaryErrorAfterLoadingReducer,
};
