import { DirectedTriple } from '@ardoq/api-types';
import SparkMD5 from 'spark-md5';

export const pathToKey = (path: DirectedTriple[]) =>
  SparkMD5.hash(
    // path could be empty, so we need to make sure we have a string.
    `some-random-seed-${path
      .flatMap(({ sourceType, referenceType, targetType, direction }) => [
        sourceType,
        referenceType,
        targetType,
        direction,
      ])
      .join('')}`
  );
