import {
  Button,
  ButtonGroup,
  ButtonType,
  SecondaryButton,
} from '@ardoq/button';
import { s16, s4 } from '@ardoq/design-tokens';
import { AsFileUpload, Dropzone, ProcessingDropzone } from '@ardoq/dropzone';
import { FlexBox } from '@ardoq/layout';
import { header4, text1 } from '@ardoq/typography';
import { noop } from 'lodash';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { UploadInfo } from 'integrations/excel-v3/streams/upload/types';

type FileDropzoneProps = {
  uploadInfo: UploadInfo;
  onUpload: (data: File) => void;
};

export function FileDropzone({ uploadInfo, onUpload }: FileDropzoneProps) {
  const onFiles = async (files: File[]) => {
    onUpload(files[0]);
  };

  return (
    <>
      {uploadInfo.status === 'Processing' && (
        <ProcessingDropzone>
          <MinimalDropzonePanel
            title={uploadInfo.title}
            message={uploadInfo.message}
            buttons={<UploadButtons uploadInfo={uploadInfo} isDisabled />}
          />
        </ProcessingDropzone>
      )}
      {uploadInfo.status !== 'Processing' && (
        <Dropzone
          dragOverChildren={
            <MinimalDropzonePanel
              title={'Drop to upload...'}
              buttons={<UploadButtons uploadInfo={uploadInfo} isDisabled />}
            />
          }
          onFiles={onFiles}
        >
          <MinimalDropzonePanel
            title={uploadInfo.title}
            message={uploadInfo.message}
            buttons={
              <UploadButtons uploadInfo={uploadInfo} onFiles={onFiles} />
            }
          />
        </Dropzone>
      )}
    </>
  );
}

// Subcomponents

const UploadButton = ({
  isDisabled = false,
  isSecondary = false,
  onFiles = noop,
  children,
}: {
  isDisabled?: boolean;
  isSecondary?: boolean;
  onFiles?: (files: File[]) => void;
  children?: ReactNode;
}) => {
  return (
    <AsFileUpload isDisabled={isDisabled} onFiles={onFiles}>
      <Button
        isDisabled={isDisabled}
        buttonType={isSecondary ? ButtonType.SECONDARY : ButtonType.PRIMARY}
      >
        {children}
      </Button>
    </AsFileUpload>
  );
};

function UploadButtons({
  uploadInfo,
  isDisabled = false,
  onFiles,
}: {
  uploadInfo: UploadInfo;
  isDisabled?: boolean;
  onFiles?: (files: File[]) => void;
}) {
  return (
    <FlexBox gap="medium">
      {uploadInfo.uploadButtonText && (
        <UploadButton
          onFiles={onFiles}
          isDisabled={isDisabled}
          isSecondary={uploadInfo.status === 'Reupload'}
        >
          {uploadInfo.uploadButtonText}
        </UploadButton>
      )}
      {uploadInfo.secondaryButtonText && (
        <SecondaryButton isDisabled={isDisabled}>
          {uploadInfo.secondaryButtonText}
        </SecondaryButton>
      )}
    </FlexBox>
  );
}

// Minimal Dropzone Panel

type MinimalDropzonePanelProps = {
  title?: string;
  message?: ReactNode;
  buttons?: ReactNode;
};

const MinimalDropzonePanel = ({
  title,
  message,
  buttons,
  ...rest
}: MinimalDropzonePanelProps) => {
  return (
    <MinimalDropzonePanelLayout {...rest}>
      <MinimalDropzonePanelText>
        {title && (
          <MinimalDropzonePanelTitle>{title}</MinimalDropzonePanelTitle>
        )}
        <MinimalDropzonePanelMessage>{message}</MinimalDropzonePanelMessage>
      </MinimalDropzonePanelText>
      {buttons && <ButtonGroup>{buttons}</ButtonGroup>}
    </MinimalDropzonePanelLayout>
  );
};

const MinimalDropzonePanelLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${s16};
`;

const MinimalDropzonePanelText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${s4};
`;

const MinimalDropzonePanelTitle = styled.div`
  ${header4}
`;

const MinimalDropzonePanelMessage = styled.div`
  ${text1}
  min-height: 20px; /* = 1 * line-height */
  text-align: center;
`;
