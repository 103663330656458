import { HighlightedText } from '@ardoq/common-helpers';
import { Box, Stack } from '@ardoq/layout';
import { Text } from '@ardoq/typography';

type PersonRendererProps = {
  personLabel: string | null;
  personSublabel: string | null;
  highlight?: string;
};

const PersonRenderer = ({
  personLabel,
  personSublabel,
  highlight,
}: PersonRendererProps) => (
  <Box paddingY="xsmall">
    <Stack gap="xsmall">
      {personLabel && (
        <Box>
          <HighlightedText text={personLabel} highlight={highlight} />
        </Box>
      )}
      {personSublabel && (
        <Text variant="caption" color="textSubtle">
          <Box>
            <HighlightedText text={personSublabel} highlight={highlight} />
          </Box>
        </Text>
      )}
    </Stack>
  </Box>
);

export default PersonRenderer;
