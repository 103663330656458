import { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { GhostButton } from '@ardoq/button';
import { colors, s24, s32, s4, s8 } from '@ardoq/design-tokens';
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from '@ardoq/icons';
import { header4 } from '@ardoq/typography';

const Wrapper = styled.div`
  border: 1px solid ${colors.grey80};
  border-radius: ${s8};
  &:not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:not(:first-of-type) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 0;
  }
`;

const Heading = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  ${header4};
  color: ${colors.grey50};
  justify-content: space-between;
  padding: ${s4};
  height: 56px;
  ${props =>
    !props.$isCollapsed && `border-bottom: 1px solid ${colors.grey80};`}
`;

const Title = styled.div`
  flex-grow: 1;
  ${header4};
`;

const ContentSection = styled.div`
  padding: ${s24} ${s32};
`;

type CollapsibleSectionProps = PropsWithChildren<{
  title: string;
  onDelete: VoidFunction;
  deleteTooltipText: string;
}>;

const CollapsibleSection = ({
  children,
  title,
  onDelete,
  deleteTooltipText,
}: CollapsibleSectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <Wrapper>
      <Heading $isCollapsed={isCollapsed}>
        <GhostButton
          data-tooltip-text={isCollapsed ? 'Expand' : 'Collapse'}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </GhostButton>
        <Title>{title}</Title>
        <GhostButton data-tooltip-text={deleteTooltipText} onClick={onDelete}>
          <CloseIcon />
        </GhostButton>
      </Heading>
      {!isCollapsed && <ContentSection>{children}</ContentSection>}
    </Wrapper>
  );
};

export default CollapsibleSection;
