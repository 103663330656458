import { catchError, of, switchMap, tap, withLatestFrom } from 'rxjs';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { setTablePreviews } from 'integrations/common/streams/tablePreviews/actions';
import {
  setIntegrationName,
  setSourceId,
  setTrackingFunnelId,
} from 'integrations/common/streams/activeIntegrations/actions';
import { TablePreview } from 'integrations/common/streams/tablePreviews/types';
import {
  fetchSelection,
  fetchSelectionError,
  fetchSelectionSuccess,
} from 'integrations/common/streams/selectionState/actions';
import { recalculateFilterColumnsIndexes } from 'integrations/common/streams/tabularMappings/actions';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { setFetchSelectionDataSuccess } from 'integrations/common/streams/transferState/actions';
import { selectionApi } from '@ardoq/api';
import { selectionPayloadToFetchRequestParams } from './utils';
import {
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';
import { parseRequestId } from 'integrations/common/async/utils';

const handleFetchSelectionSuccess = routine(
  ofType(fetchSelectionSuccess),
  extractPayload(),
  switchMap(({ response, integrationId }) =>
    of(integrationId).pipe(
      withLatestFrom(
        of(response),
        getTablePreviewsStream(integrationId),
        getTabularMappingStream(integrationId)
      )
    )
  ),
  tap(([integrationId, response, oldTablePreviews, tabularMapping]) => {
    dispatchAction(setFetchSelectionDataSuccess({ integrationId }));
    dispatchAction(setSourceId({ integrationId, id: response.id }));

    dispatchAction(
      setIntegrationName({
        integrationId,
        name: response.sourceName,
      })
    );

    const tablePreviews: TablePreview[] = response.tables.map(table => ({
      ...table,
      sourceFieldNames: table.columns.map(({ id }) => id),
    }));

    dispatchAction(
      recalculateFilterColumnsIndexes({
        integrationId,
        oldTablePreviews: Object.values(oldTablePreviews),
        newTablePreviews: tablePreviews,
        mapping: tabularMapping,
      })
    );

    dispatchAction(
      setTablePreviews({
        integrationId,
        tablePreviews,
      })
    );
  })
);

const handleFetchSelection = routine(
  ofType(fetchSelection),
  extractPayload(),
  switchMap(payload => {
    return retriableRequest(
      () =>
        selectionApi.fetchSelectionAsync(
          selectionPayloadToFetchRequestParams(payload)
        )
      // Why is this pipe here?
      // We need the integrationId to dispatch the actions
    ).pipe(
      tap(response => {
        if (response.status === 'already-running') {
          const { funnelId } = parseRequestId(response['request-id']);
          dispatchAction(
            setTrackingFunnelId({
              integrationId: payload.integrationId,
              trackingFunnelId: funnelId,
            })
          );
        }
        if (response.status === 'error') {
          dispatchAction(
            fetchSelectionError({ integrationId: payload.integrationId })
          );
        }
      }),
      catchError(error => {
        dispatchAction(
          fetchSelectionError({ integrationId: payload.integrationId })
        );
        return handleApiError(error);
      })
    );
  })
);

export default [handleFetchSelectionSuccess, handleFetchSelection];
