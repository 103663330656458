import { ReactNode, RefObject, createRef, Component } from 'react';
import {
  applySelectionDecorators,
  focusableItems,
  runLayout,
  selectableItems,
} from '../../../tabview/graphComponent/yfilesHelper';
import { applySelectionBehavior } from '../../../tabview/graphComponent/graphEvents';
import ZoomControls from 'atomicComponents/Zoomable/ZoomControls';
import {
  GraphComponent,
  ICommand,
  Insets,
  LayoutData,
  LayoutDescriptor,
  PortAdjustmentPolicy,
  TimeSpan,
} from '@ardoq/yfiles';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';
import { ViewIds } from '@ardoq/api-types';
import { OnLayoutGraphArgs } from '../../../tabview/graphComponent/onLayoutGraph';
import { noop } from 'lodash';
import {
  FillingDiv,
  GraphContainer,
} from '../../../tabview/graphComponent/atoms';
import type { HasViewInstanceId } from '@ardoq/graph';
import {
  ZoomContainer,
  ZoomableViewLegendContainerForwardRef,
} from 'tabview/relationshipDiagrams/atoms';
import { HasUrlFieldValuesById } from 'tabview/graphViews/types';
import {
  RegisterUrlFieldValuesPopoverArgs,
  unregisterUrlFieldValuesPopover,
} from 'tabview/graphViews/urlFieldValuesPopoverRegistry';
import { Subscription } from 'rxjs';
import { createGraphComponent } from './yFilesUtils';
import { SimpleViewModel } from '../../types';
import { GraphInterface } from './GraphInterface';
import { loadedGraph$ } from 'traversals/loadedGraph$';

interface ConfigureLayoutResult {
  layoutDescriptor: LayoutDescriptor;
  layoutData: LayoutData;
  duration?: TimeSpan;
  animateViewport?: boolean;
  updateContentRect?: boolean;
  targetBoundsInsets?: Insets;
  portAdjustmentPolicy?: PortAdjustmentPolicy;
}

interface ArdoqGraphComponentProperties
  extends HasViewInstanceId,
    Partial<HasUrlFieldValuesById> {
  viewModel: SimpleViewModel;
  enableStyles: boolean;
  isLegendActive: boolean;
  buildGraph: (
    graphComponent: GraphComponent,
    bypassLimit: boolean
  ) => {
    isAboveLimit: boolean;
    isEmpty: boolean;
    canBypass: boolean;
    hasLayoutUpdate: boolean;
  };
  configureLayout: (graphComponent: GraphComponent) => ConfigureLayoutResult;
  configureRendering: (graphComponent: GraphComponent) => void;
  onLayoutGraph: (args: OnLayoutGraphArgs) => void;
  useHoverDecorator: boolean;
  viewId: ViewIds;
  additionalZoomControls?: ReactNode;
  graphInterface?: GraphInterface;
  hasZoomControls?: boolean;
  /**
   * If set to true the view will have no zoom controls, not be zoomable nor
   * pannable with the mouse.
   */
  isThumbnailView?: boolean;
  /**
   * If set to false this will remove the hover effect on components.
   */
  hasHoverDecorator?: boolean;
}
interface ArdoqGraphComponentState {
  bypassLimit: boolean;
}

export class ArdoqGraphComponent extends Component<
  ArdoqGraphComponentProperties,
  ArdoqGraphComponentState
> {
  private $graphComponent: GraphComponent | null;
  private popoverEntry: RegisterUrlFieldValuesPopoverArgs | null = null;
  private unregisterDragAndDrop = noop;
  graphComponentContainer: RefObject<HTMLDivElement>;
  id: string;
  previousLayoutDescriptor: LayoutDescriptor | null = null;
  toggleLegendSubscription: Subscription | null = null;
  ZoomableViewLegendContainerForwardRef =
    createRef<React.ElementRef<typeof ZoomableViewLegendContainerForwardRef>>();
  constructor(props: ArdoqGraphComponentProperties) {
    super(props);
    this.$graphComponent = null;
    this.graphComponentContainer = createRef<HTMLDivElement>();
    this.state = { bypassLimit: false };
    this.id = ViewIds.METAMODEL; //  this.props.viewId;
  }

  autoZoomToFit = () => this.graphComponent && this.graphComponent.fitContent();

  componentDidMount() {
    if (
      !this.graphComponentContainer ||
      !this.graphComponentContainer.current
    ) {
      return;
    }
    this.graphComponent = createGraphComponent(
      this.graphComponentContainer.current,
      this.props.useHoverDecorator,
      this.props.isThumbnailView,
      this.props.hasHoverDecorator
    );

    this.props.graphInterface?.registerInterface({
      fitContent: () => this.graphComponent?.fitContent(),
    });

    this.props.configureRendering(this.graphComponent);
    applySelectionDecorators(this.graphComponent);
    applySelectionBehavior(this.graphComponent, {
      selectableItems,
      focusableItems,
      isViewpointMode: () => loadedGraph$.state.isViewpointMode,
      viewId: null,
    });

    this.forceUpdate();
  }

  componentWillUnmount() {
    if (this.popoverEntry) {
      unregisterUrlFieldValuesPopover(this.popoverEntry);
      this.popoverEntry = null;
    }

    this.toggleLegendSubscription?.unsubscribe();

    this.props.graphInterface?.clearInterface();
  }

  get graphComponent() {
    return this.$graphComponent;
  }

  set graphComponent(value) {
    this.$graphComponent = value;
  }

  render() {
    const {
      buildGraph,
      configureLayout,
      onLayoutGraph,
      additionalZoomControls,
      viewInstanceId,
      viewId,
      hasZoomControls = true,
      isThumbnailView = false,
    } = this.props;
    const { hasLayoutUpdate: hasGraphUpdate } = this.graphComponent
      ? buildGraph(this.graphComponent, this.state.bypassLimit)
      : {
          hasLayoutUpdate: false,
        };
    if (this.graphComponent) {
      const {
        layoutDescriptor,
        layoutData,
        duration,
        animateViewport,
        updateContentRect,
        targetBoundsInsets,
        portAdjustmentPolicy,
      } = configureLayout(this.graphComponent);

      this.graphComponent.invalidate();

      runLayout({
        graphComponent: this.graphComponent,
        layoutDescriptor,
        layoutData,
        onLayoutGraph,
        hasGraphUpdate,
        duration,
        previousLayoutDescriptor: this.previousLayoutDescriptor,
        animateViewport,
        updateContentRect,
        targetBoundsInsets,
        portAdjustmentPolicy,
        viewInstanceId,
        viewId,
      });
      this.previousLayoutDescriptor = layoutDescriptor;
    }

    return (
      <FillingDiv>
        {hasZoomControls && !isThumbnailView && (
          <ZoomContainer>
            <ZoomControls
              zoomIn={() =>
                ICommand.INCREASE_ZOOM.execute(1.2, this.graphComponent)
              }
              zoomOut={() =>
                ICommand.DECREASE_ZOOM.execute(1.2, this.graphComponent)
              }
              zoomCenter={() =>
                this.graphComponent && this.graphComponent.fitContent()
              }
            >
              {additionalZoomControls}
            </ZoomControls>
          </ZoomContainer>
        )}
        <GraphContainer
          className="yfiles-canvascomponent"
          ref={this.graphComponentContainer}
          id="graphComponent"
          data-context-menu={OBJECT_CONTEXT_MENU_NAME}
        />
      </FillingDiv>
    );
  }
}
