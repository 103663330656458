import { combineLatest, map } from 'rxjs';
import { configState$ } from './configurationState/configurationState$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';

export const overlay$ = combineLatest([
  configState$,
  getConnectionsStream('signavio-exporter'),
]).pipe(
  map(([configState, connectionState]) => {
    return (
      configState.results.diff.status === 'LOADING' ||
      Object.values(connectionState.statuses).some(
        status => status.status === 'LOADING'
      )
    );
  })
);
