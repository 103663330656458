import { SavedViewStatePathCollapsingRule } from './types';
import { ComponentType } from '../components/ComponentType';
import { Icon, IconName } from '@ardoq/icons';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { colors, s8 } from '@ardoq/design-tokens';
import Collapsible from 'atomicComponents/Collapsible/Collapsible';
import styled from 'styled-components';
import { Text } from '@ardoq/typography';
import { TextOverflow } from '@ardoq/popovers';
import { CollapsePathsCommands } from './collapsePathsCommands';

type PathCollapsingRulePathSummaryProps = {
  rule: SavedViewStatePathCollapsingRule;
  commands: CollapsePathsCommands;
};

const PathCollapsingRulePathSummary = ({
  rule,
  commands,
}: PathCollapsingRulePathSummaryProps) => (
  <Box
    backgroundColor="surfaceDefaultSubtle"
    paddingX="small"
    paddingY="xsmall"
    borderRadius="r8"
  >
    <Stack gap="small">
      <ComponentType {...rule.startNode} />
      <Box
        backgroundColor="surfaceDefault"
        padding="small"
        borderRadius="r4"
        borderColor="borderSubtle00"
        position="relative"
      >
        <Collapsible
          Trigger={({ isExpanded }) => (
            <CollapsibleHeader
              isExpanded={isExpanded}
              displayText={rule.displayText}
            />
          )}
          isExpanded={rule.isExpanded}
          onToggleExpand={isExpanded => {
            commands.collapsingRuleToggled(rule.hash, isExpanded);
          }}
        >
          {rule.collapsedPath.map((pathSummary, index) => (
            <div key={index}>
              <FlexBox align="center" gap="xsmall">
                <Icon
                  color={colors.icon}
                  iconName={
                    pathSummary.direction === 'outgoing'
                      ? IconName.ARROW_DOWNWARD
                      : IconName.ARROW_UPWARD
                  }
                />
                {pathSummary.referenceType}
              </FlexBox>
              {/* We don't show the component tag for the last item, the end node is shown outside of this */}
              {index < rule.collapsedPath.length - 1 && (
                <ComponentType {...pathSummary.componentTypeMetaData} />
              )}
            </div>
          ))}
        </Collapsible>
      </Box>
      <ComponentType {...rule.endNode} />
    </Stack>
  </Box>
);

const CollapsibleHeader = ({
  isExpanded,
  displayText,
}: {
  isExpanded: boolean;
  displayText: string;
}) => (
  <>
    {!isExpanded && (
      <CollapsedHeader align="center" gap="xsmall">
        <Icon color={colors.icon} iconName={IconName.ARROW_DOWNWARD} />
        <TextOverflow content={displayText}>{displayText}</TextOverflow>
      </CollapsedHeader>
    )}
    <CollapsibleTrigger
      backgroundColor="surfaceDefault"
      align="center"
      gap="xsmall"
      position="absolute"
      paddingLeft="xsmall"
    >
      <Text color="textAction" variant="text2Bold">
        {isExpanded ? 'Close full path' : 'Show full path'}
      </Text>
      <Icon
        color={colors.blue50}
        iconName={isExpanded ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
      />
    </CollapsibleTrigger>
  </>
);

// to allow ellipsis and popover when displayName would go behind the trigger
const CollapsedHeader = styled(FlexBox)`
  padding-right: 130px; /* ~width of absolutely positioned trigger */
`;

// this is absolutely positioned to make the design work
const CollapsibleTrigger = styled(FlexBox)`
  top: ${s8};
  right: ${s8};
  cursor: pointer;
`;

export default PathCollapsingRulePathSummary;
