import { actionCreator } from '@ardoq/rxbeach';
import { AddFieldNewToWorkspacePayload } from 'appModelStateEdit/propertiesEditor/addField/types';

export const addFieldByName = actionCreator<string>(
  '[appModelStateEdit/addFieldToWorkspace] ADD_FIELD_BY_NAME'
);

export const addFieldNewToWorkspace =
  actionCreator<AddFieldNewToWorkspacePayload>(
    '[appModelStateEdit/addFieldToWorkspace] ADD_FIELD_NEW_TO_WORKSPACE'
  );

export const createFieldByLabel = actionCreator<string>(
  '[appModelStateEdit/addFieldToWorkspace] CREATE_FIELD_BY_LABEL'
);
