import { ArdoqId } from '@ardoq/api-types';
import * as actions from './actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { modal } from '@ardoq/modal';
import TraversalPreviewModal from './TraversalPreviewModal';

const setTraversalId = (traversalId: ArdoqId | null) => {
  dispatchAction(actions.setTraversalId({ traversalId }));
};

const openTraversalPreviewModal = (
  previewId: ArdoqId,
  isFavorite?: boolean,
  openViewpoint?: (id: string) => void
) => {
  return modal(closeModal => (
    <TraversalPreviewModal
      isFavorite={isFavorite}
      openViewpoint={openViewpoint}
      closeModal={() => {
        closeModal(true);
        setTraversalId(null);
      }}
      previewId={previewId}
    />
  ));
};

type OpenTraversalPreview = {
  traversalId: ArdoqId;
  isFavorite?: boolean;
  openViewpoint?: (id: string) => void;
  trackingFn: (trackingString: string, metadata?: any) => void;
};

export type ViewpointPreviewCommands = {
  setTraversalId: (traversalId: ArdoqId | null) => void;
  openTraversalPreview: ({
    traversalId,
    isFavorite,
    openViewpoint,
    trackingFn,
  }: OpenTraversalPreview) => void;
};

export const viewpointPreviewCommands = {
  setTraversalId,
  openTraversalPreview: ({
    traversalId,
    isFavorite,
    openViewpoint,
    trackingFn,
  }: OpenTraversalPreview) => {
    trackingFn('preview');
    openTraversalPreviewModal(traversalId, isFavorite, openViewpoint);
  },
};
