import { ViewpointBuilderContext } from 'viewpointBuilder/types';

export type ContextConfig = {
  isNavigationPanelHidden: boolean;
  isComponentTypeSelectionDisabled: boolean;
  graphViewTitle: string;
};

export const getContextConfig = (context: ViewpointBuilderContext) => {
  return {
    isNavigationPanelHidden: context === 'openViewpoint',
    isComponentTypeSelectionDisabled: context === 'openViewpoint',
    graphViewTitle: getGraphViewTitle(context),
  };
};

const getGraphViewTitle = (context: ViewpointBuilderContext): string => {
  switch (context) {
    case 'createViewpoint':
    case 'editViewpoint':
      return 'Viewpoint metamodel';
    default:
      return 'Dataset metamodel';
  }
};
