import { Component } from 'react';
import CurrentUser from 'models/currentUser';
import {
  formatNumber,
  getCurrentLocale,
  getLocale,
  Locale,
  localeCompareNumericLowercase,
  localeList,
} from '@ardoq/locale';
import { PrimaryButton } from '@ardoq/button';
import { alert } from '@ardoq/modal';
import { currentDate, formatDateOnly } from '@ardoq/date-time';
import { TextInput } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import { InlineText } from '@ardoq/typography';
import {
  IslandBody,
  IslandContainer,
  IslandFooter,
  IslandHeader,
} from '@ardoq/page-layout';
import { Box, FlexBox, Stack } from '@ardoq/layout';

interface UserSettingsOptions {
  name: string;
  locale: Locale;
  organization: string;
}

interface ProfileFormProps {
  save: (options: UserSettingsOptions) => Promise<void>;
  name: string;
  email: string;
  organization: string;
}

type ProfileFormState = UserSettingsOptions & {
  isSaving: boolean;
};

const getDateFormatExample = (locale: Locale) =>
  formatDateOnly(currentDate(), locale);

const getNumberFormatExample = (locale: Locale) =>
  formatNumber(123456.789, 'default', locale);

class ProfileForm extends Component<ProfileFormProps, ProfileFormState> {
  override state = {
    name: this.props.name,
    locale: getCurrentLocale(),
    organization: this.props.organization,
    isSaving: false,
  };

  private isValid = () => Boolean(this.state.name);

  private handleNameChange = (name: string) => {
    this.setState({ name });
  };

  private handleLocaleChange = (localeCode: string | null) => {
    const locale = getLocale(localeCode ?? undefined);
    if (locale) this.setState({ locale });
  };

  private handleOrganizationChange = (organization: string | null) => {
    this.setState({ organization: organization ?? '' });
  };

  private handleSave = async () => {
    if (this.isValid()) {
      this.setState({ isSaving: true });
      try {
        await this.props.save({
          name: this.state.name,
          locale: this.state.locale,
          organization: this.state.organization,
        });
      } catch (e) {
        alert({
          title: 'Error',
          subtitle: 'Save failed',
          text: 'Please contact support.',
        });
      } finally {
        this.setState({ isSaving: false });
      }
    }
  };

  override render() {
    const locale = getCurrentLocale();
    return (
      <IslandContainer>
        <IslandHeader title="Profile" />
        <IslandBody>
          <Stack gap="large">
            <TextInput
              label="Full Name"
              id="name"
              placeholder="name"
              value={this.state.name}
              onValueChange={this.handleNameChange}
              hasError={!this.isValid()}
            />
            <TextInput
              label="Email"
              id="email"
              placeholder="email"
              value={this.props.email}
              isReadOnly={true}
            />
            <Select
              label="Primary Organization"
              id="primaryOrganization"
              value={this.state.organization}
              onValueChange={this.handleOrganizationChange}
              options={[
                { label: 'None', value: '' },
                ...CurrentUser.getOrganizations().map(({ label }) => ({
                  label,
                  value: label,
                })),
              ]}
            />
            <Select
              label="Format and sorting"
              id="locale"
              hintMessage="This sets your dates/numbers format, and how your lists are sorted. If an option is not selected here, your browser's language will be used by default."
              value={this.state.locale.code}
              onValueChange={this.handleLocaleChange}
              options={localeList
                .sort((a, b) =>
                  localeCompareNumericLowercase(a.name, b.name, locale)
                )
                .map(({ name, code }) => ({
                  label: name,
                  value: code,
                }))}
            />
            <Box>
              <InlineText variant="text1Bold">Date format: </InlineText>
              <InlineText variant="text1">
                {getDateFormatExample(this.state.locale)}
              </InlineText>
            </Box>
            <Box>
              <InlineText variant="text1Bold">Number format: </InlineText>
              <InlineText variant="text1">
                {getNumberFormatExample(this.state.locale)}
              </InlineText>
            </Box>
          </Stack>
        </IslandBody>
        <IslandFooter>
          <FlexBox gap="large" width="full" justify="flex-end">
            <PrimaryButton
              onClick={this.handleSave}
              isDisabled={this.state.isSaving}
            >
              {this.state.isSaving ? 'Saving...' : 'Save'}
            </PrimaryButton>
          </FlexBox>
        </IslandFooter>
      </IslandContainer>
    );
  }
}

export default ProfileForm;
