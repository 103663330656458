import { trackEvent } from 'tracking/tracking';
import { SlideTypes, ViewIds } from '@ardoq/api-types';

// Tracked when a user shows a slide in app
interface TrackViewedSlide {
  slideType: SlideTypes;
  viewId?: ViewIds;
}
export const trackViewedSlide = (metadata: TrackViewedSlide) =>
  trackEvent('Viewed slide in app', metadata);
