import { Paragraph } from '@ardoq/typography';
import styled from 'styled-components';
import { SourceIcon } from 'integrations/common/components/sourceIcon/SourceIcon';
import { FlexBox, Stack } from '@ardoq/layout';
import { ScheduleMeta } from '@ardoq/api-types';
import { Complaint } from '@ardoq/api-types/integrations';
import { complaintsToComplaintLevel } from 'integrations/common/streams/transferState/utils';
import { StatusType, Tag } from '@ardoq/status-ui';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';

export const SourceCell = ({ name }: { name: string }) => {
  return (
    <Stack>
      <Paragraph variant="text2Bold" color="textDefault">
        {name}
      </Paragraph>
    </Stack>
  );
};

const IconWrapper = styled(FlexBox)`
  min-height: 50px;
`;

export const SourceIconCell = ({
  integrationSource,
}: {
  integrationSource: string;
}) => {
  return (
    <IconWrapper align="center">
      <SourceIcon size="24px" integrationSource={integrationSource} />
    </IconWrapper>
  );
};

export const StatusIconCell = ({ meta }: ScheduleMeta) => {
  const defaultComplaints: Complaint[] =
    meta.lastResult?.status === 'error'
      ? [
          {
            level: 'error',
            messageKey: '',
            message: meta.lastResult.message || 'Unknown error',
            target: 'sheet',
            tableId: '',
          } satisfies Complaint,
        ]
      : [];

  const allComplaints = [
    ...(meta.lastResult?.summary?.validation ?? []),
    ...defaultComplaints,
  ];

  const complaintLevel = complaintsToComplaintLevel(allComplaints);

  return (
    <FlexBox gap="small" justify="space-between">
      {meta.paused ? (
        <Tag statusType={StatusType.WARNING}>Paused</Tag>
      ) : (
        <Tag statusType={StatusType.INFO}>Active</Tag>
      )}
      {complaintLevel === 'Error' && (
        <Icon
          iconName={IconName.ERROR}
          size={IconSize.MEDIUM}
          style={{ color: colors.red60 }}
        />
      )}
      {complaintLevel === 'Warning' && (
        <Icon
          iconName={IconName.WARNING}
          size={IconSize.MEDIUM}
          style={{ color: colors.yellow60 }}
        />
      )}
    </FlexBox>
  );
};
