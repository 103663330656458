import { Portal } from '@ardoq/common-helpers';
import NavigatorContent from './navigatorContent';
import DragTarget from './DragTarget';
import { NavigatorWrapper } from './Atoms';
import { NavigatorContainerProps } from './PropTypes';
import { useEffect, useRef } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  containerCreated,
  containerHeightChanged,
} from '../actions/navigatorActions';

const NavigatorContainer = ({
  eventListeners: { onClick, onScroll, onMouseDown, onContextMenu },
  navigatorContainerClassName,
  renderProps,
  navigatorContentStyle,
  dragElementRoot,
  dragGhostProps,
  parentDropTargetIndicatorStyle,
  isInitialized,
  layoutBoxesContainerStyle,
  actionNamespace,
}: NavigatorContainerProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (isInitialized && containerRef.current) {
      dispatchAction(
        containerCreated({ container: containerRef.current }),
        actionNamespace
      );
      dispatchAction(containerHeightChanged(), actionNamespace);
    }
  }, [containerRef, isInitialized, actionNamespace]);

  return (
    <>
      <NavigatorWrapper
        id="navigator-container"
        ref={containerRef}
        onClick={onClick}
        onScroll={onScroll}
        onMouseDown={onMouseDown}
        className={navigatorContainerClassName}
        onContextMenu={onContextMenu}
        tabIndex={1}
      >
        <NavigatorContent
          renderProps={renderProps}
          navigatorContentStyle={navigatorContentStyle}
          parentDropTargetIndicatorStyle={parentDropTargetIndicatorStyle}
          layoutBoxesContainerStyle={layoutBoxesContainerStyle}
        />
      </NavigatorWrapper>
      <Portal to={dragElementRoot}>
        <DragTarget {...dragGhostProps} />
      </Portal>
    </>
  );
};
export default NavigatorContainer;
