import { Subdivision } from '@ardoq/api-types';

import { SubdivisionCommands } from '../types';
import { DatasourceTable, FlyWheelTable } from '@ardoq/table';
import { getSubdivisionOverviewTableColumns } from './subdivisionOverviewTableUtils';
import { subdivisionEditorOperations } from '../subdivisionEditorOperations';

export type SubdivisionOverviewProps = {
  subdivisions: Subdivision[];
  commands: SubdivisionCommands;
};
export const SubdivisionOverviewTable = ({
  subdivisions,
  commands,
}: SubdivisionOverviewProps) => (
  <DatasourceTable
    dataSource={subdivisions.toSorted(
      subdivisionEditorOperations.compareSubdivisionLastUpdatedTime
    )}
    components={FlyWheelTable}
    columns={getSubdivisionOverviewTableColumns(commands)}
    onRowClick={(subdivision: Subdivision) =>
      commands.goToSubdivision(subdivision._id)
    }
    rowStyle={() => ({
      cursor: 'pointer',
    })}
  />
);
