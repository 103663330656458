import { createRef, useRef, useState } from 'react';
import useComponentSize from '@rehooks/component-size';
import { withErrorBoundaries } from '@ardoq/error-boundary';
import {
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalSize,
  ModalTemplate,
} from '@ardoq/modal';
import { useKeydown } from '@ardoq/hooks';
import PresentationsBrowser from './PresentationsBrowser';
import { focusInputOnPrintableKeydown } from '../helpers';
import { Body } from './atoms';
import { logError } from '@ardoq/logging';
import { FormWrapper, SearchInput } from '@ardoq/forms';
import { FlexBox } from '@ardoq/layout';

type PresentationsBrowserDialogProps = ModalContent<string>;

const PresentationsBrowserDialog = ({
  resolve,
}: PresentationsBrowserDialogProps) => {
  const bodyRef = useRef(null);
  const size = useComponentSize(bodyRef);
  const [searchPhrase, setSearchPhrase] = useState<string>('');

  const inputRef = createRef<HTMLInputElement>();

  useKeydown((event: KeyboardEvent) => {
    focusInputOnPrintableKeydown(inputRef, event);
  });

  const handleExit = () => resolve(false);
  const handleConfirm = (presentationId: string) => resolve(presentationId);

  return (
    <ModalTemplate modalSize={ModalSize.L}>
      <ModalHeader onCloseButtonClick={handleExit}>
        <FlexBox marginLeft="large" paddingLeft="medium">
          Open presentation
        </FlexBox>
      </ModalHeader>
      <ModalBody>
        <FlexBox marginLeft="xxlarge" marginY="small">
          <FormWrapper>
            <SearchInput
              ref={inputRef}
              value={searchPhrase}
              onValueChange={setSearchPhrase}
            />
          </FormWrapper>
        </FlexBox>
        <FlexBox>
          <Body ref={bodyRef}>
            <PresentationsBrowser
              searchPhrase={searchPhrase}
              scrollableSectionHeight={size.height}
              openPresentation={handleConfirm}
            />
          </Body>
        </FlexBox>
      </ModalBody>
    </ModalTemplate>
  );
};

export default withErrorBoundaries({
  errorContextDescription: 'Error at <PresentationsBrowserDialog />',
  logError,
})(PresentationsBrowserDialog);
