import { Connection, ITPediaConnection } from '@ardoq/api-types/integrations';

const validationMessages: Record<string, string> = {
  name: 'Name cannot be empty',
  baseUrl: 'Base URL cannot be empty',
  accessKey: 'Username cannot be empty',
  secretKey: 'Password cannot be empty',
};
export function validateInput(input: ITPediaConnection) {
  const emptyInput = Object.entries(input)
    .filter(kv => kv[0] !== '_id')
    .find(keyValue => !keyValue[1]);
  return {
    isValid: !emptyInput,
    message: emptyInput?.[0] ? validationMessages[emptyInput?.[0]] : '',
  };
}

export function connectionIsITPediaAccount(
  conn: Connection
): conn is ITPediaConnection {
  const requiredKeys = ['_id', 'name', 'baseUrl', 'accessKey', 'secretKey'];
  return requiredKeys.every(key => key in conn);
}
