import { actionCreator } from '@ardoq/rxbeach';
import {
  FormattingRule,
  PerspectiveGroupingRule,
  PerspectiveTab,
} from '@ardoq/perspectives';
import { ArdoqId, QueryBuilderSubquery } from '@ardoq/api-types';
import type { LabelFormattingInfo } from '@ardoq/data-model';

export const setActiveTab = actionCreator<PerspectiveTab>(
  '[perspectiveEditor] SET_ACTIVE_TAB'
);

// this action is used only for tests
export const resetPerspectiveEditorState = actionCreator(
  '[perspectiveEditor] RESET_STATE'
);

/**
 * FILTERS TAB
 */
export type UpdateWorkspaceFilterPayload = ArdoqId[];

export const updateWorkspaceFilter =
  actionCreator<UpdateWorkspaceFilterPayload>(
    '[perspectiveEditor] UPDATE_WORKSPACE_FILTER_ACTION'
  );

export const updateComponentFilter = actionCreator<QueryBuilderSubquery>(
  '[perspectiveEditor] UPDATE_COMPONENT_FILTER_ACTION'
);

export const updateReferenceFilter = actionCreator<QueryBuilderSubquery>(
  '[perspectiveEditor] UPDATE_REFERENCE_FILTER_ACTION'
);

/**
 * GROUP TAB
 */
export const reorderGroupingRules = actionCreator<PerspectiveGroupingRule[]>(
  '[perspectiveEditor] REORDERED_GROUPING_RULES'
);

export const deleteGroupingRule = actionCreator<string>(
  '[perspectiveEditor] DELETE_GROUPING_RULE'
);

export type UpdateGroupingRulePayload = {
  updatedGroupingRuleId: string;
  updatedGroupingRule: Partial<PerspectiveGroupingRule>;
};
export const updateGroupingRule = actionCreator<UpdateGroupingRulePayload>(
  '[perspectiveEditor] UPDATE_GROUPING_RULE'
);

export const createGroupingRule = actionCreator<PerspectiveGroupingRule>(
  '[perspectiveEditor] CREATE_GROUPING_RULE'
);

/**
 * FORMATTING TAB
 */
export const createLabelFormatting = actionCreator<LabelFormattingInfo>(
  '[perspectiveEditor] CREATE_LABEL_FORMATTING'
);
export const reorderLabelFormatting = actionCreator<LabelFormattingInfo[]>(
  '[perspectivesEditor] REORDER_LABEL_FORMATTING'
);
export const deleteLabelFormatting = actionCreator<number>(
  '[perspectivesEditor] DELETE_LABEL_FORMATTING'
);
export type UpdateLabelFormattingAtArgs = {
  index: number;
} & Partial<LabelFormattingInfo>;
export const updateLabelFormattingAt =
  actionCreator<UpdateLabelFormattingAtArgs>(
    '[perspectiveEditor] UPDATE_LABEL_FORMATTING_AT'
  );
export const showReferenceTypeToggled = actionCreator<boolean>(
  '[perspectiveEditor] SHOW_REFERENCE_TYPE_TOGGLED'
);
export const selectComponentLabelFormattingValue = actionCreator<string>(
  '[perspectiveEditor] SELECT_COMPONENT_LABEL_FORMATTING_VALUE'
);
export const selectReferenceLabelFormattingValue = actionCreator<string>(
  '[perspectiveEditor] SELECT_REFERENCE_LABEL_FORMATTING_VALUE'
);
export const setComponentLabelFormattingTimeIncluded = actionCreator<boolean>(
  '[perspectiveEditor] SET_COMPONENT_LABEL_FORMATTING_TIME_INCLUDED'
);
export const setReferenceLabelFormattingTimeIncluded = actionCreator<boolean>(
  '[perspectiveEditor] SET_REFERENCE_LABEL_FORMATTING_TIME_INCLUDED'
);
export const deleteFormattingRule = actionCreator<string>(
  '[perspectiveEditor] DELETE_FORMATTING_RULE'
);

export const reorderFormattingRules = actionCreator<string[]>(
  '[perspectiveEditor] REORDER_FORMATTING_RULES'
);

export type UpdateFormattingRulePayload = {
  updatedFormattingRuleId: string;
  updatedFormattingRule: Partial<FormattingRule>;
};
export const updateFormattingRule = actionCreator<UpdateFormattingRulePayload>(
  '[perspectiveEditor] UPDATE_FORMATTING_RULE'
);

export const createFormattingRule = actionCreator<FormattingRule>(
  '[perspectiveEditor] CREATE_FORMATTING_RULE'
);
