import { persistentReducedStream } from '@ardoq/rxbeach';
import { SummaryAndLaunchDialogState } from './types';
import { reducers } from './reducers';

const getDefaultState = (): SummaryAndLaunchDialogState => ({
  filter: '',
});

const summaryAndLaunchDialog$ =
  persistentReducedStream<SummaryAndLaunchDialogState>(
    'summaryAndLaunchDialog$',
    getDefaultState(),
    reducers
  );

export default summaryAndLaunchDialog$;
