import { LayoutBoxModel } from '../types';

export const getDropTarget = (
  dropTargetLayoutBox: LayoutBoxModel | null,
  isDropTargetParent: boolean,
  isSortedByOrder: boolean
) => {
  if (
    !dropTargetLayoutBox ||
    dropTargetLayoutBox.node.isSelected ||
    dropTargetLayoutBox.node.getLock() ||
    (isDropTargetParent && !isSortedByOrder)
  ) {
    return null;
  }
  const { node } = dropTargetLayoutBox;
  return isDropTargetParent ? node.parent : node;
};
