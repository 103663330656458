import { ButtonType } from '@ardoq/button';
import { NonSearchBackendSearchPane } from '../types';
import { SearchBackend } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { createSearchQuery } from 'search/actions';
import currentUser from 'models/currentUser';
import { SearchPaneToLabel } from 'search/SearchTabContainer/types';
import { createParameterizedGraphFilter } from 'search/DynamicSearch/actions';
import { QueryEditorNamespace } from 'search/QueryEditor/queryEditor$';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { ExcludeFalsy } from '@ardoq/common-helpers';

const getMenuOptions = (
  overviewType: NonSearchBackendSearchPane,
  canCreateGraphFilter: boolean
) => {
  return overviewType === NonSearchBackendSearchPane.DYNAMIC_FILTER_OVERVIEW
    ? [
        {
          label: 'Plain graph filter',
          clickId: 'open-create-new-plain-graph-filter',
          intercomTarget: 'create new plain graph filter',
          onClick: () =>
            dispatchAction(
              createSearchQuery(),
              QueryEditorNamespace.DYANMIC_FILTER_QUERY
            ),
        },
        {
          label: 'Parameterized graph filter',
          clickId: 'open-create-new-parameterized-graph-filter',
          intercomTarget: 'create new parameterized graph filter',
          onClick: () => dispatchAction(createParameterizedGraphFilter()),
        },
      ]
    : [
        {
          label: SearchPaneToLabel[SearchBackend.ADVANCED_SEARCH],
          clickId: 'open-create-new-advanced-search',
          intercomTarget: 'create new advanced search',
          onClick: () =>
            dispatchAction(
              createSearchQuery(),
              QueryEditorNamespace.ADVANCED_SEARCH
            ),
        },
        canCreateGraphFilter && {
          label: SearchPaneToLabel[SearchBackend.GREMLIN],
          clickId: 'open-create-new-gremlin-search',
          intercomTarget: 'create new gremlin search',
          onClick: () =>
            dispatchAction(
              createSearchQuery(),
              QueryEditorNamespace.GREMLIN_SEARCH
            ),
        },
      ].filter(ExcludeFalsy);
};

const OverviewButton = ({
  overviewType,
  canCreateGraphFilter,
}: {
  overviewType: NonSearchBackendSearchPane;
  canCreateGraphFilter: boolean;
}) => (
  <ButtonWithDropdown
    dropdownSize={DropdownSize.S}
    data-click-id="open-create-dropdown"
    isDisabled={!currentUser.hasWriteAccess()}
    alignMenuTo="right"
    options={getMenuOptions(overviewType, canCreateGraphFilter)}
    buttonType={ButtonType.GHOST}
  >
    Create new
  </ButtonWithDropdown>
);

export default OverviewButton;
