import broadcastNamePrompt from './components/broadcastNamePrompt';
import broadcast$ from './broadcast$';
import broadcastsNavigation$ from './navigation/broadcastsNavigation$';
import confirmSaveOrDiscardBroadcast from './components/confirmSaveOrDiscardBroadcast';
import confirmUnpublishAndEditBroadcast from './components/confirmUnpublishAndEditBroadcast';
import { dispatchAction } from '@ardoq/rxbeach';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ExcludeFalsy, ArdoqError } from '@ardoq/common-helpers';
import {
  APIBroadcastAttributes,
  AdvancedSearchFilterCondition,
  ArdoqId,
  AssetType,
  AudiencePreview,
  BroadcastContentType,
  Report,
  UnpersistedEntity,
} from '@ardoq/api-types';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';
import {
  fetchBroadcastsError,
  fetchViewpoints,
  launchBroadcast,
  notifyFetchingBroadcasts,
  notifyIsSavingBroadcast,
  notifySavingBroadcastFailed,
  notifySavingBroadcastSucceeded,
  setBroadcastList,
  setCurrentBroadcast,
  updateViewpoints,
} from './actions';
import {
  broadcastInstanceAudienceWithAllScopedComponentsFilled,
  findBroadcast,
  isPersistedBroadcast,
  isReportContent,
  isRunningBroadcast,
  isValidAudience,
  isValidContent,
  mayCurrentBroadcastBeOverwritten,
  mergeAudiencePreviewRowsByPerson,
} from './utils';
import { navigateToEditBroadcastForm } from 'router/navigationActions';
import { mapContainsOperatorForQueryRules } from '../search/AdvancedSearch/utils';
import { showRequiredFields } from './broadcastBuilder/validation$';
import {
  ApiResponse,
  assetApi,
  broadcastApi,
  handleError,
  viewpointApi,
} from '@ardoq/api';
import { isEmpty } from 'lodash';
import { showToast, ToastType } from '@ardoq/status-ui';
import { modal } from '@ardoq/modal';
import { createElement } from 'react';
import { showBroadcastReportDrawer } from './broadcastOverview/instanceTable/InstanceTable';
import { logError } from '@ardoq/logging';
import { getBroadcastDetailsDialogData } from './infoDialogDataUtils';
import SummaryAndLaunchDialog from './components/summaryAndLaunchDialog/SummaryAndLaunchDialog';
import { UnpersistedCurrentBroadcast } from './types';

const mapContainsOperatorForBroadcast = (
  broadcast: APIBroadcastAttributes
): APIBroadcastAttributes => {
  const filterConditions = !isReportContent(broadcast.content)
    ? {
        filterConditions: broadcast.content.filterConditions.map(
          filterCondition =>
            (filterCondition as AdvancedSearchFilterCondition).advancedQuery
              ? {
                  ...filterCondition,
                  advancedQuery: {
                    ...(filterCondition as AdvancedSearchFilterCondition)
                      .advancedQuery,
                    rules: mapContainsOperatorForQueryRules(
                      (filterCondition as AdvancedSearchFilterCondition)
                        .advancedQuery.rules
                    ),
                  },
                }
              : filterCondition
        ),
      }
    : {};

  return {
    ...broadcast,
    content: {
      ...broadcast.content,
      ...filterConditions,
    },
  };
};

export const fetchBroadcasts$ = () => {
  dispatchAction(notifyFetchingBroadcasts());
  return from(broadcastApi.fetchAll()).pipe(
    handleError(error => {
      dispatchAction(fetchBroadcastsError());
      throw error;
    }),
    map(broadcasts => broadcasts.map(mapContainsOperatorForBroadcast)),
    tap(broadcasts => dispatchAction(setBroadcastList(broadcasts)))
  );
};

export const handleFetchBroadcastInstancesFailed = (error: ArdoqError) => {
  logError(error, 'Failed to fetch broadcast instances');
  showToast("Couldn't fetch broadcast instance", ToastType.INFO);
};

export const fetchBroadcastInstances$ = (broadcastId: ArdoqId) => {
  return from(broadcastApi.getInstances(broadcastId)).pipe(
    handleError(error => {
      handleFetchBroadcastInstancesFailed(error);
      throw error;
    }),
    withLatestFrom(broadcast$),
    tap(([instances, { broadcastsById }]) => {
      const broadcast = broadcastsById[broadcastId];
      showBroadcastReportDrawer({
        broadcast,
        instances: instances.map(
          broadcastInstanceAudienceWithAllScopedComponentsFilled
        ),
      });
    })
  );
};

export const syncCurrentBroadcastWithBroadcastList$ = () => {
  return of(broadcastsNavigation$.state.broadcastId).pipe(
    excludeNullAndUndefined(),
    tap(broadcastId => dispatchAction(setCurrentBroadcast(broadcastId)))
  );
};

const dispatchNotifySavingBroadcastSucceeded = (
  broadcast: APIBroadcastAttributes
) => dispatchAction(notifySavingBroadcastSucceeded(broadcast));

export const saveExistingBroadcast$ = (broadcast: APIBroadcastAttributes) => {
  dispatchAction(notifyIsSavingBroadcast());
  return from(broadcastApi.update(broadcast)).pipe(
    handleError(error => {
      dispatchAction(notifySavingBroadcastFailed());
      throw error;
    }),
    tap(dispatchNotifySavingBroadcastSucceeded)
  );
};

const dispatchNotifySavingBroadcastSucceededAndNavigateToEditBroadcastForm = (
  broadcast: APIBroadcastAttributes
) => {
  dispatchNotifySavingBroadcastSucceeded(broadcast);
  dispatchAction(navigateToEditBroadcastForm(broadcast._id));
};

type CreateBroadcastFunction = (
  broadcast: UnpersistedEntity<APIBroadcastAttributes>
) => ApiResponse<APIBroadcastAttributes>;

export const saveNewBroadcast$ = ({
  folderId,
  ...broadcast
}: UnpersistedCurrentBroadcast) => {
  dispatchAction(notifyIsSavingBroadcast());
  const createBroadcastFn: CreateBroadcastFunction = folderId
    ? _ => assetApi.createInFolder(broadcast, folderId, AssetType.BROADCAST)
    : broadcastApi.create;
  return from(createBroadcastFn(broadcast)).pipe(
    handleError(error => {
      dispatchAction(notifySavingBroadcastFailed());
      throw error;
    }),
    tap(dispatchNotifySavingBroadcastSucceededAndNavigateToEditBroadcastForm)
  );
};

export const initiateSavingCurrentBroadcast$ = () => {
  return of(broadcast$.state.currentBroadcast).pipe(
    excludeNullAndUndefined(),
    tap(currentBroadcast => {
      if (
        isReportContent(currentBroadcast.content) &&
        !isValidContent(currentBroadcast.content)
      ) {
        dispatchAction(showRequiredFields(true));
      }
    }),
    filter(
      currentBroadcast =>
        !(
          isReportContent(currentBroadcast.content) &&
          !isValidContent(currentBroadcast.content)
        )
    ),
    switchMap(currentBroadcast => {
      if (isPersistedBroadcast(currentBroadcast)) {
        return currentBroadcast.name
          ? saveExistingBroadcast$(currentBroadcast)
          : from(broadcastNamePrompt()).pipe(
              filter(ExcludeFalsy),
              map(name => ({ ...currentBroadcast, name })),
              switchMap(saveExistingBroadcast$)
            );
      }
      return currentBroadcast.name
        ? saveNewBroadcast$(currentBroadcast)
        : from(broadcastNamePrompt()).pipe(
            filter(ExcludeFalsy),
            map(name => ({ ...currentBroadcast, name })),
            switchMap(saveNewBroadcast$)
          );
    })
  );
};

export const ensureCurrentBroadcastMayBeOverwritten$ = () => {
  if (mayCurrentBroadcastBeOverwritten(broadcast$.state)) return of(true);
  const currentBroadcastName = broadcast$.state.currentBroadcast?.name ?? null;
  return from(confirmSaveOrDiscardBroadcast(currentBroadcastName)).pipe(
    switchMap(shouldSaveCurrentBroadcast => {
      return shouldSaveCurrentBroadcast
        ? initiateSavingCurrentBroadcast$()
        : of(true);
    })
  );
};

export const ensureBroadcastMayBeOverwritten$ = (broadcastId: ArdoqId) => {
  const { currentBroadcast } = broadcast$.state;
  const isCurrentBroadcast =
    isPersistedBroadcast(currentBroadcast) &&
    broadcastId === currentBroadcast._id;
  return isCurrentBroadcast
    ? ensureCurrentBroadcastMayBeOverwritten$()
    : of(true);
};

export const ensureUserIsOkayWithBroadcastBeingUnpublished$ = (
  broadcastId: ArdoqId
) => {
  return of(findBroadcast(broadcast$.state.broadcasts, broadcastId)).pipe(
    excludeNullAndUndefined(),
    switchMap(broadcastToBeEdited => {
      return isRunningBroadcast(broadcastToBeEdited)
        ? from(
            confirmUnpublishAndEditBroadcast(
              Boolean(broadcastToBeEdited.scheduling.reminderInDays)
            )
          ).pipe(filter(ExcludeFalsy))
        : of(true);
    })
  );
};

export const getAudiencePreview$ = ({
  content,
  audiences,
}: Pick<APIBroadcastAttributes, 'content' | 'audiences'>) => {
  return isValidAudience(content, audiences)
    ? from(broadcastApi.getAudience(content, audiences))
    : of({
        audience: [],
        components: [],
      });
};

export const fetchViewpointsForComponentTypeName$ = (
  componentTypeName: string
) => {
  dispatchAction(fetchViewpoints());
  return from(viewpointApi.fetchByComponentType(componentTypeName)).pipe(
    handleError(error => {
      dispatchAction(updateViewpoints([]));
      throw error;
    }),
    tap(viewpoints => {
      dispatchAction(updateViewpoints(viewpoints));
    })
  );
};

export const openSummaryAndLaunchDialog = (
  broadcast: APIBroadcastAttributes,
  audiencePreview: AudiencePreview,
  reportsById: Record<string, Report>
) => {
  const report =
    broadcast.content.contentType === BroadcastContentType.REPORT &&
    broadcast.content.contentId
      ? reportsById[broadcast.content.contentId]
      : null;
  const { errors, warnings } = broadcast;
  return modal(resolve =>
    createElement(SummaryAndLaunchDialog, {
      ...getBroadcastDetailsDialogData(broadcast),
      audiencePreview: mergeAudiencePreviewRowsByPerson(audiencePreview),
      report,
      errors,
      warnings,
      resolve,
      onConfirm: () => {
        dispatchAction(launchBroadcast(broadcast));
        resolve(false);
      },
      confirmButtonText:
        broadcast.processable && isEmpty(errors) ? 'Launch now' : undefined,
      cancelButtonText: 'Cancel',
    })
  );
};
