import { LabeledValue } from 'aqTypes';
import {
  ArdoqId,
  Organization,
  PermissionAccessLevel,
  Entity,
  BundleShape,
  PersistedBundleShape,
} from '@ardoq/api-types';
import CurrentUser from 'models/currentUser';
import {
  BundleWsShape,
  EditingBundleShape,
  EditingBundleWsMapShape,
} from './types';

const objectIdToApiIdObj = (objectId: ArdoqId): Entity => ({
  _id: objectId,
});
const apiIdObjToObjectId = (ardoqIdObj: Entity): ArdoqId => ardoqIdObj._id;

/**
 * Convert from workspace attributes as defined in CreateBundle.tsx to
 * acceptable format for the /api/bundle endpoint
 */
const convertEditingBundleWsShapeToApi = (
  workspaces: EditingBundleWsMapShape
): BundleWsShape[] => {
  if (!workspaces.size) return [];
  return Array.from(workspaces.values()).map(
    ({
      id: _id,
      copyComponents,
      copyReferences,
      chooseFolder,
      targetFolderName,
      renameWorkspace,
      name,
    }) => {
      const body: BundleWsShape = {
        _id,
        copyComponents,
        copyReferences,
      };
      if (renameWorkspace) body.name = name;
      if (chooseFolder) body.targetFolderName = targetFolderName;
      return body;
    }
  );
};

const convertBundleWsShapeToEditing = (
  workspacesApi: BundleWsShape[]
): EditingBundleWsMapShape =>
  new Map(
    workspacesApi.map(
      ({
        _id,
        copyComponents = false,
        copyReferences = false,
        targetFolderName = null,
        name = '',
      }) => [
        _id,
        {
          copyComponents,
          copyReferences,
          chooseFolder: Boolean(targetFolderName),
          targetFolderName: targetFolderName || undefined,
          renameWorkspace: Boolean(name.length),
          name,
          id: _id,
        },
      ]
    )
  );

/** Used e.g. when getting API response with bundle data,
 * then loading it into CreateBundle for editing
 */
export const convertPersistedBundleShapeToEditing = ({
  name,
  copyConfig: {
    useCase = [],
    entityGroup = [],
    componentType = [],
    referenceType = [],
    field = [],
    workspace = [],
    survey = [],
    presentation = [],
    perspective = [],
    dashboard = [],
    tabularMapping = [],
    servicenowConfig = [],
    broadcast = [],
    viewpoint = [],
    storedQuery = [],
    report = [],
    traversal = [],
  },
}: PersistedBundleShape): EditingBundleShape => ({
  name,
  useCases: useCase.map(apiIdObjToObjectId),
  entityGroups: entityGroup.map(apiIdObjToObjectId),
  componentTypes: componentType.map(apiIdObjToObjectId),
  referenceTypes: referenceType.map(apiIdObjToObjectId),
  fields: field.map(apiIdObjToObjectId),
  surveys: survey.map(apiIdObjToObjectId),
  presentations: presentation.map(apiIdObjToObjectId),
  perspectives: perspective.map(apiIdObjToObjectId),
  dashboards: dashboard.map(apiIdObjToObjectId),
  workspaces: convertBundleWsShapeToEditing(workspace),
  tabularMappingConfigIds: tabularMapping.map(apiIdObjToObjectId),
  serviceNowImportConfigIds: servicenowConfig.map(apiIdObjToObjectId),
  broadcasts: broadcast.map(apiIdObjToObjectId),
  viewpoints: viewpoint.map(apiIdObjToObjectId),
  graphFilter: storedQuery.map(apiIdObjToObjectId),
  reports: report.map(apiIdObjToObjectId),
  traversals: traversal.map(apiIdObjToObjectId),
});

export const convertEditingBundleShapeToApi = ({
  name,
  useCases,
  entityGroups,
  componentTypes,
  referenceTypes,
  fields,
  workspaces,
  surveys,
  presentations,
  perspectives,
  dashboards,
  tabularMappingConfigIds,
  serviceNowImportConfigIds,
  broadcasts,
  graphFilter: storedQuery,
  viewpoints,
  reports,
  traversals,
}: EditingBundleShape): BundleShape => ({
  name,
  copyConfig: {
    useCase: useCases.map(objectIdToApiIdObj),
    entityGroup: entityGroups.map(objectIdToApiIdObj),
    componentType: componentTypes.map(objectIdToApiIdObj),
    referenceType: referenceTypes.map(objectIdToApiIdObj),
    field: fields.map(objectIdToApiIdObj),
    workspace: convertEditingBundleWsShapeToApi(workspaces),
    survey: surveys.map(objectIdToApiIdObj),
    presentation: presentations.map(objectIdToApiIdObj),
    perspective: perspectives.map(objectIdToApiIdObj),
    dashboard: dashboards.map(objectIdToApiIdObj),
    tabularMapping: tabularMappingConfigIds.map(objectIdToApiIdObj),
    servicenowConfig: serviceNowImportConfigIds.map(objectIdToApiIdObj),
    broadcast: broadcasts.map(objectIdToApiIdObj),
    viewpoint: viewpoints.map(objectIdToApiIdObj),
    storedQuery: storedQuery.map(objectIdToApiIdObj),
    report: reports.map(objectIdToApiIdObj),
    traversal: traversals.map(objectIdToApiIdObj),
  },
});

/** User must have admin rights on target org and can not copy to current org */
const userCanCopyBundleToOrg = (org: Organization) => {
  const isCurrentOrg = org.label === CurrentUser.getOrganization().label;
  const userCanCopyToOrg = org.role === PermissionAccessLevel.ADMIN;
  return !isCurrentOrg && userCanCopyToOrg;
};

type GetOrganizationOptions = () => LabeledValue<string>[];
/** Get dropdown options for all eligible target orgs for manual bundle copying */
export const getOrganizationOptions: GetOrganizationOptions = () =>
  CurrentUser.getOrganizations()
    .filter(userCanCopyBundleToOrg)
    .map(({ name, label }) => ({ label: name, value: label }));
