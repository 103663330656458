import { PersistedExternalDocument } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export type OpenExternalDocument = {
  externalDocumentId: string;
  externalDocumentTitle: string;
  clientId: string;
};

export type NavigateToExternalDocument = {
  externalDocumentId: string;
  externalDocumentTitle: string;
};

export const deleteExternalDocument = actionCreator<PersistedExternalDocument>(
  '[External Document] DELETE_EXTERNAL_DOCUMENT'
);

export const openExternalDocument = actionCreator<PersistedExternalDocument>(
  '[External Document] OPEN_EXTERNAL_DOCUMENT'
);

export const setExternalDocument = actionCreator<OpenExternalDocument>(
  '[External Document] SET_EXTERNAL_DOCUMENT'
);

export const navigateToExternalDocument =
  actionCreator<NavigateToExternalDocument>(
    '[External Document] NAVIGATE_TO_EXTERNAL_DOCUMENT'
  );
