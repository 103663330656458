import { Privilege, PrivilegeLabel } from '@ardoq/api-types';

const EMAIL_VALIDATION_REGEX = /.+@.+/;
const GOOGLE_CALENDAR_EMAIL_FORMAT_REGEX = /<(.*)>/;

const isEmailValid = (email: string) =>
  Boolean(email) && EMAIL_VALIDATION_REGEX.test(email);

const parseEmails = (emailsInput: string) => {
  const emailsCommaSplitted = emailsInput.split(',');
  return emailsCommaSplitted.map(cleanGoogleCalendarFormattedEmail) ?? [];
};
const cleanGoogleCalendarFormattedEmail = (email: string) => {
  const match = email.match(GOOGLE_CALENDAR_EMAIL_FORMAT_REGEX)?.[1] || email;
  return match.trim();
};

const removeEmailAtIndex = (emails: string[], index: number) =>
  emails.toSpliced(index, 1);

const filterConfigurablePrivilegeLabels = (
  configurablePrivileges: Privilege[],
  privilegeLabels: PrivilegeLabel[]
) => {
  return privilegeLabels.filter(privilegeLabel =>
    configurablePrivileges.find(({ label }) => label === privilegeLabel)
  );
};

export const inviteNewUserOperations = {
  isEmailValid,
  parseEmails,
  removeEmailAtIndex,
  filterConfigurablePrivilegeLabels,
};
