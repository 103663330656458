import { useRef, useState } from 'react';
import { TextInput } from '@ardoq/forms';
import { IconName } from '@ardoq/icons';
import { Space } from '@ardoq/style-helpers';
import { IconButton } from '@ardoq/button';
import { FilterWrapper } from 'appContainer/MainAppModule/MainAppModuleSidebar/atoms';
import styled from 'styled-components';

type ExpandableFilterProps = {
  filterTerm: string;
  onFilterTermChange: (value: string) => void;
};
export const ExpandableFilter = ({
  filterTerm,
  onFilterTermChange,
}: ExpandableFilterProps) => {
  const [isFilterFocused, setIsFilterFocused] = useState(false);
  const input = useRef<HTMLInputElement>(null);
  return (
    <SpaceFilter $gap={'none'} $justify="end">
      {!isFilterFocused && (
        <IconButton
          iconName={IconName.SEARCH}
          onClick={() => {
            setIsFilterFocused(true);
            input.current?.focus();
          }}
        />
      )}
      <FilterWrapper $isOffscreen={!isFilterFocused}>
        <TextInput
          value={filterTerm}
          leftIconName={IconName.SEARCH}
          onValueChange={onFilterTermChange}
          onFocus={() => setIsFilterFocused(true)}
          onBlur={event => {
            if (!event.target.value) setIsFilterFocused(false);
          }}
          onClear={() => {
            onFilterTermChange('');
            setIsFilterFocused(false);
          }}
          ref={input}
        />
      </FilterWrapper>
    </SpaceFilter>
  );
};

const SpaceFilter = styled(Space)`
  flex: 1;
`;
