import { combineLatest, map } from 'rxjs';
import {
  action$,
  ofType,
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import {
  SaveAsViewpointInputsReducer,
  SaveAsViewpointInputsState,
  UpdateSaveAsViewpointStatePayload,
  ViewStyle,
} from './types';
import {
  resetSaveAsViewpoint,
  setIsSavingViewpointSessionActive,
  updateSaveAsViewpointState,
} from './actions';
import { isWorkspaceModule$ } from 'streams/isWorkspaceModule$';
import activeView$, {
  ActiveViewState,
} from 'streams/views/mainContent/activeView$';
import { AvailableViews, availableViews$ } from 'views/availableViews$';
import {
  SAVE_BUTTON_ERROR_MESSAGE,
  VIEWPOINT_NAME_INPUT_ERROR_MESSAGE,
} from './consts';
import { loadedState$ } from 'loadedState/loadedState$';
import { isSingleTraversalState } from './isSingleTraversalState';
import { traversalInterface } from 'modelInterface/traversals/traversalInterface';
import { startWith } from 'rxjs/operators';
import { startAction } from 'actions/utils';
import { LoadedState } from '@ardoq/api-types';

const activeViewChangedReducer: SaveAsViewpointInputsReducer<ViewStyle> = (
  state,
  viewStyle
) => ({ ...state, ...viewStyle });

const validateState = (
  state: SaveAsViewpointInputsState
): SaveAsViewpointInputsState => ({
  ...state,
  errors: !state.viewpointName.trim()
    ? {
        saveButton: SAVE_BUTTON_ERROR_MESSAGE,
        viewpointName: VIEWPOINT_NAME_INPUT_ERROR_MESSAGE,
      }
    : {},
});

const updateStateReducer: SaveAsViewpointInputsReducer<
  UpdateSaveAsViewpointStatePayload
> = (state, payload) =>
  validateState({
    ...state,
    ...payload,
  });

const isSavingViewpointSessionActiveReducer: SaveAsViewpointInputsReducer<
  boolean
> = (state, isSavingViewpointSessionActive) => ({
  ...state,
  isSavingViewpointSessionActive,
});

const getTraversalDetails = (loadedStates: LoadedState[]) => {
  if (!isSingleTraversalState(loadedStates)) {
    return null;
  }

  const traversalState = loadedStates[0];
  const traversalId = traversalState.traversalId;

  if (!traversalId) {
    return defaultEditableState;
  }

  const traversal = traversalInterface.getById(traversalId);

  if (!traversal) {
    // This is bad practice. If we can't find the traversal we're passing the
    // id for, we're in an inconsistent state and should log.error or simply
    // throw
    return null;
  }

  const viewpointName = traversal.name || '';
  const viewpointDescription = traversal.description || '';
  const errors = traversal.name
    ? {}
    : { saveButton: SAVE_BUTTON_ERROR_MESSAGE };

  return {
    viewpointName,
    viewpointDescription,
    errors,
  };
};

const defaultEditableState = {
  viewpointName: '',
  viewpointDescription: '',
  errors: {
    saveButton: SAVE_BUTTON_ERROR_MESSAGE,
  },
};

const updateDefaultStateReducer: SaveAsViewpointInputsReducer<LoadedState[]> = (
  state,
  loadedStates
) => ({
  ...state,
  ...(getTraversalDetails(loadedStates) || {}),
  isSavingViewpointSessionActive: isSingleTraversalState(loadedStates)
    ? state.isSavingViewpointSessionActive
    : false,
});

const getCurrentView = ([{ mainViewId }, { byIds }]: [
  activeView: ActiveViewState,
  availabeViews: AvailableViews,
]) => ({
  mainViewId,
  mainViewName: byIds[mainViewId]?.name,
});

export const getDefaultInputsState = (): SaveAsViewpointInputsState => ({
  ...defaultEditableState,
  isSavingViewpointSessionActive: false,
  ...getCurrentView([activeView$.state, availableViews$.state]),
  ...(getTraversalDetails(loadedState$.state) || {}),
});

const viewPaneHeader$ = combineLatest([activeView$, availableViews$]).pipe(
  map(getCurrentView)
);

const resetReducer = (state: SaveAsViewpointInputsState) => ({
  ...state,
  ...getDefaultInputsState(),
});

const reset$ = combineLatest([
  isWorkspaceModule$,
  action$.pipe(ofType(resetSaveAsViewpoint), startWith(startAction)),
]);

export const saveAsViewpointInputs$ = persistentReducedStream(
  'saveAsViewpointInputs$',
  getDefaultInputsState(),
  [
    streamReducer(loadedState$, updateDefaultStateReducer),
    reducer(updateSaveAsViewpointState, updateStateReducer),
    streamReducer(viewPaneHeader$, activeViewChangedReducer),
    streamReducer(reset$, resetReducer),
    reducer(
      setIsSavingViewpointSessionActive,
      isSavingViewpointSessionActiveReducer
    ),
  ]
);
