import { FunctionComponent } from 'react';
import { formatToFixed } from 'utils/numberUtils';
import { SourceIcon } from 'integrations/common/components/sourceIcon/SourceIcon';
import { ErrorNotification } from '@ardoq/status-ui';
import { Validation, ImportHistory } from '@ardoq/api-types/integrations';
import { Header3, InlineText } from '@ardoq/typography';
import { Header1 } from '@ardoq/typography';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';

const translateHash: { [key: string]: string } = {
  tags: 'Tags',
  referenceTypes: 'Reference types',
  fields: 'Fields',
  references: 'References',
  models: 'Models',
  components: 'Components',
  componentTypes: 'Component types',
  workspaces: 'Workspaces',
  listFieldValues: 'List field values',
};

const toLabel = (key: string): string => {
  return translateHash[key] || key;
};

interface ImportDetailsProps {
  importHistory: ImportHistory;
  onCancel: VoidFunction;
}

type ErrorDetailsProps = {
  importHistory: ImportHistory;
};

type SummaryProps = {
  importHistory: ImportHistory;
};

const hasValidationErrors = (importHistory: ImportHistory) =>
  importHistory.summary.validation !== undefined &&
  importHistory.summary.validation?.length > 0;

const hasUnexpectedError = (importHistory: ImportHistory) =>
  !hasValidationErrors(importHistory) &&
  importHistory.result?.status === 'error';

const getValidationMessages = ({ summary }: ImportHistory) => (
  <ul>
    {summary.validation!.map((v: Validation, idx: number) => (
      <li key={idx}>
        {v.tableId}: {v.message}
      </li>
    ))}
  </ul>
);

const getErrorMessage = (importHistory: ImportHistory) =>
  importHistory.result?.message || 'An unknown error has occurred.';

const getErrorHeader = (importHistory: ImportHistory) =>
  hasUnexpectedError(importHistory)
    ? 'An unexpected error occured during import, nothing was imported.'
    : 'Validation errors occured during import.';

const ErrorDetails = ({ importHistory }: ErrorDetailsProps) => (
  <Box marginTop="xlarge">
    <Header3>{getErrorHeader(importHistory)}</Header3>
    <ErrorNotification style={{ maxHeight: '150px', overflow: 'auto' }}>
      {hasUnexpectedError(importHistory)
        ? getErrorMessage(importHistory)
        : getValidationMessages(importHistory)}
    </ErrorNotification>
  </Box>
);

const Summary = ({ importHistory }: SummaryProps) => (
  <FlexBox gap="medium" marginTop="large" width="full">
    <FlexBox
      width="full"
      paddingX="large"
      paddingY="medium"
      borderColor="borderSuccess"
    >
      <Stack flex={1} gap="small">
        <Header3 color="textSuccess">Created</Header3>
        {Object.entries(importHistory.summary.created || {}).map(
          ([type, count]) => (
            <FlexBox key={type} justify="space-between">
              <InlineText variant="text1">{toLabel(type)}</InlineText>
              <InlineText variant="text1">{formatToFixed(count)}</InlineText>
            </FlexBox>
          )
        )}
      </Stack>
    </FlexBox>
    <FlexBox
      width="full"
      paddingX="large"
      paddingY="medium"
      borderColor="borderError"
    >
      <Stack flex={1} gap="small">
        <Header3 color="textError">Deleted</Header3>
        {Object.entries(importHistory.summary.deleted || {}).map(
          ([type, count]) => (
            <FlexBox key={type} justify="space-between">
              <InlineText variant="text1">{toLabel(type)}</InlineText>
              <InlineText variant="text1">{formatToFixed(count)}</InlineText>
            </FlexBox>
          )
        )}
      </Stack>
    </FlexBox>
    <FlexBox
      width="full"
      paddingX="large"
      paddingY="medium"
      borderColor="borderInfo"
    >
      <Stack flex={1} gap="small">
        <Header3 color="textInfo">Updated</Header3>
        {Object.entries(importHistory.summary.updated || {}).map(
          ([type, count]) => (
            <FlexBox key={type} justify="space-between">
              <InlineText variant="text1">{toLabel(type)}</InlineText>
              <InlineText variant="text1">{formatToFixed(count)}</InlineText>
            </FlexBox>
          )
        )}
      </Stack>
    </FlexBox>
  </FlexBox>
);

const ImportDetailsModal: FunctionComponent<ImportDetailsProps> = ({
  importHistory,
  onCancel,
}) => {
  return (
    <ModalTemplate
      headerText="Import details"
      modalSize={ModalSize.M}
      primaryButtonText="Close"
      onPrimaryButtonClick={onCancel}
    >
      <ModalLayout>
        <Header1>
          <Box display="inline" paddingRight="large">
            <SourceIcon
              size="48px"
              integrationSource={importHistory.integrationSource}
            />
          </Box>
          {importHistory.sourceName}
        </Header1>
        {(hasValidationErrors(importHistory) ||
          hasUnexpectedError(importHistory)) && (
          <ErrorDetails importHistory={importHistory} />
        )}
        {!hasUnexpectedError(importHistory) && (
          <Summary importHistory={importHistory} />
        )}
      </ModalLayout>
    </ModalTemplate>
  );
};

export const startImportDetailsModal = (importHistory: ImportHistory) =>
  modal<boolean>(
    resolve => (
      <ImportDetailsModal
        importHistory={importHistory}
        onCancel={() => resolve(false)}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
