import { APIRoleAttributes, PrivilegeLabel } from '@ardoq/api-types';
import {
  setSelectedPrivilegesAction,
  setSelectedRoleLabelAction,
} from './actions';
import { reducer, streamReducer } from '@ardoq/rxbeach';
import { ManageUserRolesStreamShape } from './manageUserRolesViewModel$';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import roles$ from 'roles/roles$';
import { RolesStreamShape } from 'roles/types';
import { PrivilegesStreamShape } from 'privileges/types';
import privileges$ from 'privileges/privileges$';
import permissionGroup$ from 'admin/accessControl/PermissionGroups/streams/permissionGroups$';
import { PermissionGroup$State } from 'admin/accessControl/PermissionGroups/types';

const setSelectedRoleLabel = (
  state: ManageUserRolesStreamShape,
  { label, privileges }: APIRoleAttributes
): ManageUserRolesStreamShape => ({
  ...state,
  selectedRoleLabel: label,
  selectedPrivileges: privileges,
});

const setSelectedPrivileges = (
  state: ManageUserRolesStreamShape,
  { privileges }: { privileges: PrivilegeLabel[] }
): ManageUserRolesStreamShape => ({
  ...state,
  selectedPrivileges: privileges,
});

const orgUsersReducer = (
  state: ManageUserRolesStreamShape,
  { users }: { users: ManageUserRolesStreamShape['users'] }
): ManageUserRolesStreamShape => ({
  ...state,
  users,
});

const roles$Reducer = (
  state: ManageUserRolesStreamShape,
  { roles, status: rolesStatus }: RolesStreamShape
): ManageUserRolesStreamShape => ({
  ...state,
  roles,
  rolesStatus,
  selectedPrivileges:
    roles.find(role => state.selectedRoleLabel === role.label)?.privileges ||
    [],
});
const permissionGroup$Reducer = (
  state: ManageUserRolesStreamShape,
  { groupsById, error: addToGroupError }: PermissionGroup$State
): ManageUserRolesStreamShape => ({
  ...state,
  permissionGroups: Object.values(groupsById),
  addToGroupError,
});

const privileges$Reducer = (
  state: ManageUserRolesStreamShape,
  {
    configurablePrivileges,
    privilegesByUser,
    status: privilegesStatus,
  }: PrivilegesStreamShape
): ManageUserRolesStreamShape => ({
  ...state,
  configurablePrivileges,
  privilegesByUser,
  privilegesStatus,
});

export const reducers = [
  streamReducer(orgUsers$, orgUsersReducer),
  streamReducer(roles$, roles$Reducer),
  streamReducer(privileges$, privileges$Reducer),
  streamReducer(permissionGroup$, permissionGroup$Reducer),
  reducer(setSelectedRoleLabelAction, setSelectedRoleLabel),
  reducer(setSelectedPrivilegesAction, setSelectedPrivileges),
];
