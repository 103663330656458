import { ArdoqIdMapping } from '@ardoq/api-types/integrations';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import { ExcludeDuplicateIdsCheckbox } from './ExcludeDuplicateIdsChecbox';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';

type ArdoqIdProps = {
  tableMapping: TableMappingMap;
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
  ardoqIdMapping: ArdoqIdMapping;
};

export const ArdoqId = ({
  onMapping,
  ardoqIdMapping,
  tableMapping,
}: ArdoqIdProps) => {
  return (
    <ExcludeDuplicateIdsCheckbox
      onMapping={onMapping}
      isDuplicatesExcluded={ardoqIdMapping.duplicates === 'warn'}
      rowRepresentation={tableMapping.rowRepresentation}
    />
  );
};
