import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import { CanEditDashboardGuardProps } from './types';
import { STRINGS } from 'dashboard/consts';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToDashboardModule } from 'router/navigationActions';
import { DashboardModule } from 'dashboard/types';
import { dashboardAccessControlInterface } from 'resourcePermissions/accessControlHelpers/dashboards';
import { PermissionContext } from '@ardoq/access-control';

const canCreateNew = (permissionContext: PermissionContext) =>
  dashboardAccessControlInterface.canCreateDashboard(permissionContext);

const canEditExisting = (
  permissionContext: PermissionContext,
  dashboardId?: string
) =>
  dashboardId &&
  dashboardAccessControlInterface.canEditDashboard(
    permissionContext,
    dashboardId
  );

const CanEditDashboardGuard = ({
  children,
  dashboardId,
  permissionContext,
}: CanEditDashboardGuardProps) => {
  if (
    canCreateNew(permissionContext) ||
    canEditExisting(permissionContext, dashboardId)
  ) {
    return <>{children}</>;
  }

  return (
    <div style={{ margin: 'auto' }}>
      <DoqWithSpeechBubble
        doqType={DoqType.ERROR}
        layout={DoqLayout.PAGE}
        buttonLabel={STRINGS.NO_EDIT_ACCESS_DOQI.BUTTON_LABEL}
        title={STRINGS.NO_EDIT_ACCESS_DOQI.TITLE}
        message={STRINGS.NO_EDIT_ACCESS_DOQI.MESSAGE}
        onButtonClick={() =>
          dispatchAction(
            navigateToDashboardModule({
              dashboardModule: DashboardModule.OVERVIEW,
            })
          )
        }
      />
    </div>
  );
};

export default CanEditDashboardGuard;
