import { ReactNode, FC } from 'react';
import styled, { css } from 'styled-components';
import { colors, s32 } from '@ardoq/design-tokens';
import { CellWithDropdown } from '@ardoq/table';
import {
  DropdownOption,
  enhanceOptionsWithDefaults,
} from '@ardoq/dropdown-menu';
import { WithPopover, WithPopoverProps } from '@ardoq/popovers';
import { LinkWrapper } from './PaddedLinks';
import { MatchGroups } from '@ardoq/common-helpers';
import { MatchGroupsRenderer } from '@ardoq/renderers';

const OpenLink = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
      &:hover {
        color: ${colors.grey25};
        text-decoration: underline;
      }
    `}
`;

const NameAndOptions = styled.div`
  justify-content: flex-end;
  display: flex;
  padding: 0 0 0 5px;
  align-items: center;
  a {
    cursor: pointer;
  }
  ${LinkWrapper} {
    display: none;
  }
  &:hover {
    ${LinkWrapper} {
      display: block;
    }
  }
`;

const NameWrapper = styled.div<{ $isDisabled?: boolean }>`
  opacity: ${({ $isDisabled = false }) => ($isDisabled ? 0.5 : undefined)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

const HorizontalFillerS32 = styled.div`
  min-width: ${s32};
`;

const NameWithMenuCell: FC<{
  trackOnClick?: () => void;
  onClick?: () => void;
  name?: string;
  menuOptions?: Partial<DropdownOption>[];
  matchGroups?: MatchGroups;
  children?: ReactNode;
  popoverContent?: Omit<WithPopoverProps, 'children'>;
  isDisabled?: boolean;
}> = ({
  onClick,
  matchGroups,
  menuOptions,
  children,
  name,
  popoverContent = { content: undefined },
  isDisabled = false,
  trackOnClick,
}) => {
  return (
    <NameAndOptions>
      <NameWrapper $isDisabled={isDisabled}>
        <WithPopover {...popoverContent}>
          <OpenLink
            onClick={isDisabled ? undefined : onClick}
            data-test-id="name-row"
          >
            <MatchGroupsRenderer
              matchGroups={matchGroups}
              defaultValue={name}
            />
          </OpenLink>
        </WithPopover>
      </NameWrapper>
      {children}
      {menuOptions && menuOptions.length > 0 ? (
        <CellWithDropdown
          dataTestId="cell-with-dropdown"
          options={enhanceOptionsWithDefaults(menuOptions)}
          trackOnClick={trackOnClick}
        />
      ) : (
        <HorizontalFillerS32 />
      )}
    </NameAndOptions>
  );
};

export default NameWithMenuCell;
