import { dispatchAction } from '@ardoq/rxbeach';
import { viewpointBuilderTracking } from '../viewpointBuilderTracking';
import {
  cancelEditPathCollapsingRule,
  clearCollapsedPathNodes,
  collapsingRuleIsActiveToggled,
  collapsingRuleToggled,
  createPathCollapsingRule,
  deletePathCollapsingRule,
  displayTextUpdated,
  editPathCollapsingRule,
  graphNodeHovered,
  pathCollapsingNodeSelected,
  savePathCollapsingRule,
  setPathCollapsingRules,
} from './collapsePathsActions';
import { EditablePathCollapsingRule } from './types';
import { PathCollapsingRule } from '@ardoq/api-types';

export type CollapsePathsCommands = {
  createPathCollapsingRule: () => void;
  savePathCollapsingRule: (rule: EditablePathCollapsingRule) => void;
  deletePathCollapsingRule: (ruleHash: string) => void;
  editPathCollapsingRule: (ruleHash: string) => void;
  cancelEditPathCollapsingRule: (rule: EditablePathCollapsingRule) => void;
  displayTextUpdated: (text: string) => void;
  // naming here is a bit misleading. It's what's required in
  // viewpointBuilderEventListeners.ts, but we're actually not toggling anything,
  // just selecting a node.
  toggleIsSelectedGraphNode: (nodeId: string) => void;
  setPathCollapsingRules: (rules: PathCollapsingRule[]) => void;
  clearCollapsedPathNodes: () => void;
  onGraphNodeHover: (nodeId: string | null) => void;
  collapsingRuleToggled: (hash: string, isExpanded: boolean) => void;
  collapsingRuleIsActiveToggled: (hash: string, isActive: boolean) => void;
};

export const collapsePathsCommands: CollapsePathsCommands = {
  createPathCollapsingRule: () => dispatchAction(createPathCollapsingRule()),
  savePathCollapsingRule: (rule: EditablePathCollapsingRule) =>
    dispatchAction(savePathCollapsingRule(rule)),
  deletePathCollapsingRule: (ruleHash: string) =>
    dispatchAction(deletePathCollapsingRule(ruleHash)),
  editPathCollapsingRule: (ruleHash: string) =>
    dispatchAction(editPathCollapsingRule(ruleHash)),
  cancelEditPathCollapsingRule: (rule: EditablePathCollapsingRule) =>
    dispatchAction(cancelEditPathCollapsingRule(rule)),
  displayTextUpdated: (text: string) =>
    dispatchAction(displayTextUpdated(text)),
  toggleIsSelectedGraphNode: (nodeId: string) => {
    viewpointBuilderTracking.trackComponentTypeNodeSelectedInGraph(
      'COLLAPSE_PATHS_TAB'
    );
    dispatchAction(pathCollapsingNodeSelected(nodeId));
  },
  setPathCollapsingRules: (rules: PathCollapsingRule[]) =>
    dispatchAction(setPathCollapsingRules(rules)),
  clearCollapsedPathNodes: () => dispatchAction(clearCollapsedPathNodes()),
  onGraphNodeHover: (nodeId: string | null) =>
    dispatchAction(graphNodeHovered(nodeId)),
  collapsingRuleToggled: (hash: string, isExpanded: boolean) =>
    dispatchAction(collapsingRuleToggled({ hash, isExpanded })),
  collapsingRuleIsActiveToggled: (hash: string, isActive: boolean) =>
    dispatchAction(collapsingRuleIsActiveToggled({ hash, isActive })),
};
