import { Component } from 'react';
import {
  dispatchAction,
  connect,
  ActionCreatorWithoutNamespace,
} from '@ardoq/rxbeach';
import {
  selectCondition,
  selectFilterType,
  updateAdvancedSearch,
} from './actions';
import advancedSearch$ from './advancedSearch$';
import {
  QueryBuilderFilterDefinitionGroup,
  SearchContext,
  composeAdvancedSearchFilterGroups,
  getSearchContextRule,
} from '@ardoq/query-builder';
import { QueryBuilderWithSuggestionsLoaders } from '../QueryBuilderWithSuggestionsLoaders';
import {
  APIFieldAttributes,
  ArdoqId,
  BooleanOperator,
  QueryBuilderQuery,
  QueryBuilderRow,
  QueryBuilderSubquery,
} from '@ardoq/api-types';
import { map } from 'rxjs/operators';
import {
  AdvancedSearchShape,
  AdvancedSearchStateShape,
  PayloadSelectCondition,
  PayloadSelectFilterType,
  PayloadUpdateAdvancedSearch,
} from './types';
import Fields from 'collections/fields';

export const getFullQuery = ({
  selectedFilterType,
  queryBuilderRules,
}: Pick<
  AdvancedSearchStateShape,
  'selectedFilterType' | 'queryBuilderRules'
>): QueryBuilderQuery => ({
  condition: BooleanOperator.AND,
  rules: [
    getSearchContextRule(selectedFilterType),
    queryBuilderRules as QueryBuilderSubquery,
  ],
});

export type AdvancedSearchOnChangePayload = {
  isValid: boolean;
  isEmpty: boolean;
  query: QueryBuilderQuery;
};

export type AdvancedSearchQueryBuilderProps = AdvancedSearchShape & {
  showErrors?: boolean;
  includeContextSelect?: boolean;
  contextSelectIsReadOnly?: boolean;
  hideContainerStyles?: boolean;
  allowedSearchContexts?: SearchContext[];
  defaultSearchContext?: SearchContext;
  /**
   * Workspace ids that are available for Suggestions loading.
   * If provided, the suggestions will be loaded only from these workspaces data.
   */
  contextWorkspaceIds?: ArdoqId[];
  onChange: (args: AdvancedSearchOnChangePayload) => void;
  getFilterGroups?: (args: {
    searchContext: SearchContext;
    fields: APIFieldAttributes[];
  }) => QueryBuilderFilterDefinitionGroup[];
  isReadOnly?: boolean;
  isDisabled?: boolean;
  hideTopLevelConditionBar?: boolean;
  holdInvalidRules?: boolean;
  maxSubqueryNestingLevel?: number;
  actions: {
    selectCondition?: ActionCreatorWithoutNamespace<PayloadSelectCondition>;
    selectFilterType?: ActionCreatorWithoutNamespace<PayloadSelectFilterType>;
    updateAdvancedSearch?: ActionCreatorWithoutNamespace<PayloadUpdateAdvancedSearch>;
  };
};

export class AdvancedSearchQueryBuilder extends Component<AdvancedSearchQueryBuilderProps> {
  render() {
    const { selectFilterType, selectCondition, updateAdvancedSearch } =
      this.props.actions;
    return (
      <QueryBuilderWithSuggestionsLoaders
        getFilterGroups={({ searchContext }) => {
          if (this.props.getFilterGroups)
            return this.props.getFilterGroups({
              searchContext,
              fields: Fields.collection.toJSON(),
            });
          return composeAdvancedSearchFilterGroups(
            searchContext,
            Fields.collection.toJSON(),
            false
          );
        }}
        hideContainerStyles={this.props.hideContainerStyles}
        query={getFullQuery(this.props)}
        hideTopLevelConditionBar={this.props.hideTopLevelConditionBar}
        holdInvalidRules={this.props.holdInvalidRules}
        includeContextSelect={this.props.includeContextSelect ?? true}
        allowedSearchContexts={this.props.allowedSearchContexts}
        contextSelectIsReadOnly={this.props.contextSelectIsReadOnly}
        defaultSearchContext={this.props.defaultSearchContext}
        maxSubqueryNestingLevel={this.props.maxSubqueryNestingLevel ?? 1}
        showErrors={this.props.showErrors}
        onChange={this.props.onChange}
        isReadOnly={this.props.isReadOnly}
        isDisabled={this.props.isDisabled}
        contextWorkspaceIds={this.props.contextWorkspaceIds}
        onQueryChange={newQuery => {
          const searchContext = newQuery.rules[0].value;
          const subquery = newQuery.rules[1];
          const { condition } = subquery;
          const rules: QueryBuilderRow[] = subquery.rules as any;
          if (selectFilterType) {
            dispatchAction(selectFilterType({ filterType: searchContext }));
          }
          if (selectCondition) {
            dispatchAction(selectCondition({ condition }));
          }
          if (updateAdvancedSearch) {
            dispatchAction(
              updateAdvancedSearch({
                queryBuilderRules: { condition, rules },
              })
            );
          }
        }}
      />
    );
  }
}

export default connect(
  AdvancedSearchQueryBuilder,
  advancedSearch$.pipe(
    map(advancedSearch => ({
      ...advancedSearch,
      actions: {
        selectCondition,
        selectFilterType,
        updateAdvancedSearch,
      },
    }))
  )
);
