import { colors, radius, shadowM } from '@ardoq/design-tokens';
import styled from 'styled-components';

export const ViewInferenceWindow = styled.div`
  width: 500px;
  background-color: ${colors.white};
  box-shadow: ${shadowM};
  border-radius: ${radius.r4};
  overflow: hidden;
`;
