import Context from 'context';
import { FilterWrapper, TopBarButton, TopBarWrapper } from '../atoms';
import { FilterState } from './navigatorTopBar$';
import { SortOption } from './types';
import NavigatorTopBarSortMenu from './NavigatorTopBarSortMenu';
import NavigatorTopBarFilterButton from './NavigatorTopBarFilterButton';
import NavigatorTopBarScenarioMenu from './NavigatorTopBarScenarioMenu';
import { useShowFilteredComponents } from './utils';
import { dispatchAction } from '@ardoq/rxbeach';
import { ArdoqId } from '@ardoq/api-types';
import NavigatorTopBarShowDiffButton from './NavigatorTopBarShowDiffButton';
import ScenarioMergeButton from 'appContainer/MainAppModule/MainAppModuleSidebar/NavigatorTopBar/ScenarioMergeButton';
import { TextInput } from '@ardoq/forms';
import { Icon, IconName } from '@ardoq/icons';
import { Space } from '@ardoq/style-helpers';
import { NavigatorTopBarCloseAllButton } from './NavigatorTopBarCloseAllButton';
import {
  NAVIGATOR_TREE_FILTER_BUTTON_ID,
  NAVIGATOR_TREE_FILTER_ID,
} from 'components/WorkspaceHierarchies/consts';
import { setIsFilterFocused, setFilterTerm } from '../actions';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';
import { PermissionContext } from '@ardoq/access-control';
import { getNavigatorFilter } from 'components/WorkspaceHierarchies/utils/utils';
import { FlexBox } from '@ardoq/layout';

const focusNavigatorFilter = () => getNavigatorFilter()?.focus();
const setIsFilterFocusedTrue = () => dispatchAction(setIsFilterFocused(true));
const setIsFilterFocusedFalse = () => dispatchAction(setIsFilterFocused(false));

type NavigatorTopBarProps = {
  sortOptions: SortOption[];
  scenariosEnabled: boolean;
  selectedComponentIds: ArdoqId[];
  viewComponentIds: ArdoqId[];
  isDiffModeAvailable?: boolean;
  isScenarioInSync: boolean;
  closeAllWorkspaces: () => void;
  isScenarioMode: boolean;
  filterState: FilterState;
  permissionContext: PermissionContext;
  loadedGraph: LoadedGraphWithViewpointMode;
};

const NavigatorTopBar = (props: NavigatorTopBarProps) => {
  const [showComponents, toggleShowComponents] = useShowFilteredComponents();
  const { closeAllWorkspaces, isScenarioMode, filterState } = props;
  return (
    <TopBarWrapper $isScenarioMode={isScenarioMode}>
      <LeftToolbarButtons
        {...props}
        showComponents={showComponents}
        toggleShowComponents={toggleShowComponents}
      />
      <RightToolbarButtons
        filterState={filterState}
        isScenarioMode={isScenarioMode}
        closeAllWorkspaces={closeAllWorkspaces}
        showComponents={showComponents}
        toggleShowComponents={toggleShowComponents}
      />
    </TopBarWrapper>
  );
};

type ComponentVisibilityToggle = {
  showComponents: boolean;
  toggleShowComponents: VoidFunction;
};

type LeftToolbarButtonsProps = NavigatorTopBarProps & ComponentVisibilityToggle;

const LeftToolbarButtons = ({
  filterState,
  isScenarioMode,
  sortOptions,
  scenariosEnabled,
  selectedComponentIds,
  viewComponentIds,
  isScenarioInSync,
  isDiffModeAvailable,
  permissionContext,
  showComponents,
  toggleShowComponents,
}: LeftToolbarButtonsProps) => {
  if (filterState.isFilterFocused) return <div />;
  return (
    <div>
      <NavigatorTopBarSortMenu
        isScenarioMode={isScenarioMode}
        sortOptions={sortOptions}
        onSortChange={sortOption => {
          Context.toggleSort(sortOption.attrName, sortOption.label, true);
        }}
      />
      <NavigatorTopBarFilterButton
        showComponents={showComponents}
        toggleShowComponents={toggleShowComponents}
        isScenarioMode={isScenarioMode}
      />
      {scenariosEnabled && (
        <>
          {!isScenarioMode && (
            <NavigatorTopBarScenarioMenu
              selectedComponentIds={selectedComponentIds}
              viewComponentIds={viewComponentIds}
              permissionContext={permissionContext}
            />
          )}
          {isScenarioMode && (
            <NavigatorTopBarShowDiffButton
              isScenarioInSync={isScenarioInSync}
              isDiffModeAvailable={isDiffModeAvailable}
            />
          )}
          {isScenarioMode && (
            <ScenarioMergeButton isScenarioInSync={isScenarioInSync} />
          )}
        </>
      )}
    </div>
  );
};

type RightToolbarButtonsProps = Pick<
  NavigatorTopBarProps,
  'filterState' | 'isScenarioMode' | 'closeAllWorkspaces'
> &
  ComponentVisibilityToggle;

const RightToolbarButtons = ({
  filterState,
  isScenarioMode,
  closeAllWorkspaces,
  showComponents,
  toggleShowComponents,
}: RightToolbarButtonsProps) => (
  <Space $gap={'none'} $justify="end">
    {!filterState.isFilterFocused && (
      <TopBarButton
        id={NAVIGATOR_TREE_FILTER_BUTTON_ID}
        onClick={focusNavigatorFilter}
        data-tooltip-text={'Search navigation tree'}
      >
        <Icon iconName={IconName.SEARCH} />
      </TopBarButton>
    )}
    <FilterWrapper
      id={NAVIGATOR_TREE_FILTER_ID}
      $isOffscreen={!filterState.isFilterFocused}
      onFocus={setIsFilterFocusedTrue}
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target === event.currentTarget) return;
        if (!event.target.value) {
          setIsFilterFocusedFalse();
          dispatchAction(setFilterTerm(''));
        }
      }}
    >
      <FlexBox>
        <NavigatorTopBarFilterButton
          showComponents={showComponents}
          toggleShowComponents={toggleShowComponents}
          isScenarioMode={isScenarioMode}
        />
        <TextInput
          value={filterState.filterTerm}
          leftIconName={IconName.SEARCH}
          onValueChange={filterTerm =>
            dispatchAction(setFilterTerm(filterTerm))
          }
          onClear={() => {
            dispatchAction(setFilterTerm(''));
            setIsFilterFocusedFalse();
          }}
        />
      </FlexBox>
    </FilterWrapper>

    {!isScenarioMode && !filterState.isFilterFocused && (
      <NavigatorTopBarCloseAllButton closeAllWorkspaces={closeAllWorkspaces} />
    )}
  </Space>
);

export default NavigatorTopBar;
