import {
  ComplaintsIndices,
  TableComplaints,
} from 'integrations/common/streams/transferState/types';
import {
  NUM_DATA_ROWS,
  ROW_ERROR_FIELD,
  ROW_WARNING_FIELD,
} from 'integrations/common/streams/tablePreviews/constants';
import { TablePreview } from 'integrations/common/streams/tablePreviews/types';
import * as _ from 'lodash/fp';
import { DataSource } from './types';
import { byNumericValueAsc } from 'integrations/common/utils/common';
import { complaintsByLevelToComplaintLevel } from 'integrations/common/streams/transferState/utils';
import { ComplaintLevel } from '@ardoq/api-types/integrations';

export const getDisplayingRowIndices = ({
  problemRowIndices,
  availableDataIndices,
}: {
  problemRowIndices: ComplaintsIndices;
  availableDataIndices: number[];
}) => {
  const mustShowIndices = problemRowIndices.byPriority.slice(0, NUM_DATA_ROWS);

  // if there are no enough problem rows, we fill from the other rows
  if (mustShowIndices.length < NUM_DATA_ROWS) {
    const fillingIndices = _.flow(
      _.difference(availableDataIndices),
      _.take(NUM_DATA_ROWS - mustShowIndices.length)
    )(mustShowIndices);
    return [...mustShowIndices, ...fillingIndices].sort(byNumericValueAsc);
  }

  return [...mustShowIndices].sort(byNumericValueAsc);
};

export const getTableData = ({
  previewRows,
  problemRowIndices,
}: {
  previewRows?: TablePreview['previewRows'];
  problemRowIndices: ComplaintsIndices;
}) => {
  const data = previewRows?.rows || [];

  const errorRows = getIndicesMap(problemRowIndices.errors);
  const warningRows = getIndicesMap(problemRowIndices.warnings);

  const displayingRowIndices = getDisplayingRowIndices({
    problemRowIndices,
    availableDataIndices: data.map(([idx]) => idx),
  });

  const dataWithAdjustedIndex = data
    .filter(([idx]) => displayingRowIndices.includes(idx))
    .map(([index, ...rowData]) => [
      index,
      ...rowData,
      Boolean(errorRows[index]),
      Boolean(warningRows[index]),
    ]);

  // to handle the empty strings in the header we preprend the positional index
  // this also enables us to handle duplicated column names properly
  const header = (previewRows?.header || []).map(
    (title, idx) => `${idx}_${title}`
  );
  const dataHeader = [...header, ROW_ERROR_FIELD, ROW_WARNING_FIELD];

  const dataSource: DataSource = _.map(
    _.zipObject(dataHeader),
    dataWithAdjustedIndex
  );
  return { headerIndices: header, dataSource };
};

export const getIndicesMap = (indices: number[]) => {
  const truthyIndices: [number, boolean][] = indices.map(index => [
    index,
    true,
  ]);
  return _.fromPairs(truthyIndices);
};

const cellsComplaintsMessage = (len: number): string => {
  return len === 1
    ? `1 cell has problem`
    : len > 1
      ? `${len} cells have problems`
      : '';
};

export const complaintGetter =
  (tableComplaints?: TableComplaints) =>
  (
    columnIndex: number | null,
    rowIndex: number | null
  ): { level: ComplaintLevel; message: string } | null => {
    if (!tableComplaints) {
      return null;
    }
    const hasColumnIndex = !_.isNil(columnIndex);
    const hasRowIndex = !_.isNil(rowIndex);
    const isCell = hasColumnIndex && hasRowIndex;
    const isColumn = !isCell && hasColumnIndex;
    const isRow = !isCell && hasRowIndex;

    const found = isCell
      ? tableComplaints.column[columnIndex - 1]?.row[rowIndex] || { level: {} }
      : isColumn
        ? tableComplaints.column[columnIndex - 1] || { level: {} }
        : isRow
          ? tableComplaints.row[rowIndex] || { level: {} }
          : { level: {} };

    const level = complaintsByLevelToComplaintLevel(found.level);

    if (level) {
      return {
        level,
        message: _.map('message', found.level[level]).join('\n'),
      };
    }

    if (isColumn && 'row' in found && 'cellLevel' in found) {
      const level = complaintsByLevelToComplaintLevel(found.cellLevel || {});
      if (level) {
        return {
          level,
          message: cellsComplaintsMessage(_.values(found.row).length),
        };
      }
    }

    if (isRow && 'column' in found && 'cellLevel' in found) {
      const level = complaintsByLevelToComplaintLevel(found.cellLevel || {});
      if (level) {
        return {
          level,
          message: cellsComplaintsMessage(_.values(found.column).length),
        };
      }
    }

    return null;
  };
