import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { ImportRoute } from 'integrations/common/navigation/types';
import { setTransferConfigId } from 'integrations/common/streams/activeIntegrations/actions';
import {
  createTransferConfig,
  loadTransferConfig,
  saveConfiguration,
  updateTransferConfig,
} from 'integrations/common/streams/transferConfigs/actions';
import { filter, tap, withLatestFrom } from 'rxjs';
import {
  applyConfig,
  applyTables,
  fetchSelectedTablesFields,
  selectFields,
  selectRequiredFields,
  setImportProfilesPhotos,
} from '../streams/selectionState/actions';
import {
  getSavableTransferConfig,
  isMicrosoftEntraIdConfig,
  isSavedTransferConfig,
} from 'integrations/common/streams/transferConfigs/utils';
import fp from 'lodash/fp';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { selectionState$ } from '../streams/selectionState/selectionState$';
import { TransferConfig } from '@ardoq/api-types/integrations';
import {
  applyColumnMapping,
  applyTableMapping,
  applyRequiredColumnsMapping,
  applyRequiredTableMapping,
  setTableMappingType,
} from 'integrations/common/streams/tabularMappings/actions';
import { defaultComponentMapping } from 'integrations/common/streams/tabularMappings/defaultColumnMappings';
import { getSourceConfig } from '../utils';
import { getTabularMappingConstraintsStream } from 'integrations/common/streams/tabularMappingConstraints/getTabularMappingConstraintsStream';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import {
  enableTabularMappingsDefault,
  setTabularMappingsDefault,
} from 'integrations/common/streams/tabularMappingsDefault/actions';
import { DEFAULT_TABLE_FIELDS, DEFAULT_TABULAR_MAPPING } from './constants';

const handleSaveIntegrationConfigurations = routine(
  ofType(saveConfiguration),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'microsoft-entra-id'),
  withLatestFrom(
    getActiveIntegrationStream('microsoft-entra-id'),
    getTabularMappingStream('microsoft-entra-id'),
    transferConfigs$,
    selectionState$
  ),
  tap(
    ([
      { integrationId, name, isNewConfig },
      activeIntegration,
      tabularMapping,
      transferConfigs,
      selectionState,
    ]) => {
      const sourceConfig = getSourceConfig(selectionState);

      const savableConfig = getSavableTransferConfig({
        transferConfigs,
        activeIntegration,
        tabularMapping,
      });

      const savableConfigWithAdditions: TransferConfig = {
        ...savableConfig,
        options: {
          ...savableConfig.options,
          importPictures: selectionState.importProfilesPhotos,
        },
        name,
        sourceConfig,
      };

      const isCreation =
        isNewConfig && activeIntegration.selectedTransferConfigId;

      if (isSavedTransferConfig(savableConfigWithAdditions) && !isCreation) {
        return dispatchAction(
          updateTransferConfig({
            integrationId,
            config: savableConfigWithAdditions,
          })
        );
      }
      /*
       * saving a new config
       */
      return dispatchAction(
        createTransferConfig({
          integrationId,
          config: fp.omit(
            ['_id', '_version', 'lastUpdated'],
            savableConfigWithAdditions
          ) as TransferConfig,
        })
      );
    }
  )
);

const handleLoadTransferConfig = routine(
  ofType(loadTransferConfig),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'microsoft-entra-id'),
  tap(({ integrationId, transferConfig: config }) => {
    if (!isMicrosoftEntraIdConfig(config)) {
      return;
    }
    dispatchAction(setTransferConfigId({ integrationId, id: config._id }));
    dispatchAction(
      navigateToPath({ integrationId, path: ImportRoute.SELECT_DATA })
    );

    if (config.sourceConfig) {
      dispatchAction(
        applyConfig({
          sourceConfig: config.sourceConfig,
          importPictures: Boolean(config.options.importPictures),
        })
      );
    }
  })
);

const handleApplySourceConfig = routine(
  ofType(applyConfig),
  extractPayload(),
  tap(({ sourceConfig, importPictures }) => {
    const selectedTables = fp.reduce(
      (tables, t) => ({
        ...tables,
        [t.name]: {
          fields: fp.reduce(
            (table, { name }) => ({ ...table, [name]: true }),
            {},
            t.fields
          ),
        },
      }),
      {},
      sourceConfig.tables
    );
    dispatchAction(setImportProfilesPhotos(importPictures));
    dispatchAction(applyTables(selectedTables));
    dispatchAction(selectRequiredFields());
    dispatchAction(fetchSelectedTablesFields());
  })
);

const handleApplyRootWorkspaceForUsers = routine(
  ofType(applyTableMapping),
  extractPayload(),
  withLatestFrom(getTabularMappingConstraintsStream('microsoft-entra-id')),
  filter(
    ([{ integrationId, tableId, tableMapping }, tabularMappingConstraints]) =>
      Boolean(
        integrationId === 'microsoft-entra-id' &&
          tableMapping.rootWorkspace &&
          Object.keys(tabularMappingConstraints).includes(tableId)
      )
  ),
  tap(([{ integrationId }]) => {
    dispatchAction(applyRequiredColumnsMapping({ integrationId }));
  })
);

const handleRequestTabularMappingsDefault = routine(
  ofType(enableTabularMappingsDefault),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'microsoft-entra-id'),
  tap(({ integrationId }) => {
    Object.entries(DEFAULT_TABLE_FIELDS).forEach(([tableId, ids]) => {
      dispatchAction(selectFields({ tableId, ids }));
    });

    dispatchAction(
      setTabularMappingsDefault({
        tabularMappingsDefault: DEFAULT_TABULAR_MAPPING,
        integrationId,
      })
    );
  })
);

const handleApplyRequiredColumnsMapping = routine(
  ofType(applyRequiredColumnsMapping),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'microsoft-entra-id'),
  withLatestFrom(
    getTabularMappingConstraintsStream('microsoft-entra-id'),
    getTabularMappingStream('microsoft-entra-id'),
    selectionState$
  ),
  tap(
    ([
      { integrationId },
      tabularMappingConstraints,
      tabularMapping,
      { selectionResponse },
    ]) => {
      const tabularIds = Object.keys(tabularMappingConstraints);

      tabularIds.forEach(tableId => {
        if (!tabularMappingConstraints[tableId] || !selectionResponse) {
          return;
        }

        const targetTable = selectionResponse.tables.find(
          ({ id }) => id === tableId
        );

        if (!targetTable) {
          return;
        }

        const columnIds = Object.keys(
          tabularMappingConstraints[tableId].mappedColumns
        );

        columnIds.forEach(columnId => {
          const column = targetTable.columns.find(({ id }) => id === columnId);

          if (
            !column ||
            !tabularMappingConstraints[tableId].mappedColumns[columnId]
              .disabledColumnType
          ) {
            return;
          }

          const alreadyMappedColumn = Object.values(
            tabularMapping[tableId]?.mappedColumns || {}
          ).find(({ sourceFieldName }) => sourceFieldName === columnId);

          const componentMapping = defaultComponentMapping({
            columnName: columnId,
            tableMappingMap: tabularMapping[tableId],
          });

          const columnMapping = {
            columnType:
              tabularMappingConstraints[tableId].mappedColumns[columnId]
                .columnType,
            componentType:
              alreadyMappedColumn?.componentType ||
              componentMapping.componentType,
            hierarchyLevel:
              alreadyMappedColumn?.hierarchyLevel ??
              componentMapping.hierarchyLevel,
            index: alreadyMappedColumn?.index ?? column.index,
            sourceFieldName: columnId,
          } as ColumnMapping;

          dispatchAction(
            applyColumnMapping({
              integrationId,
              tableId: tableId,
              columnIndex: alreadyMappedColumn?.index ?? column.index,
              columnMapping,
            })
          );
        });
      });
    }
  )
);

const handleApplyRequiredTableMapping = routine(
  ofType(applyRequiredTableMapping),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'microsoft-entra-id'),
  withLatestFrom(
    getTabularMappingConstraintsStream('microsoft-entra-id'),
    getTabularMappingStream('microsoft-entra-id'),
    selectionState$
  ),
  tap(
    ([
      { integrationId },
      tabularMappingConstraints,
      tabularMapping,
      { selectionResponse },
    ]) => {
      const tabularIds = Object.keys(tabularMappingConstraints);

      if (tabularIds.length === 0 || !selectionResponse) {
        return;
      }

      tabularIds.forEach(tableId => {
        const table = selectionResponse.tables.find(({ id }) => id === tableId);

        if (!tabularMappingConstraints[tableId].disabledReferenceType || !table)
          return;

        dispatchAction(
          setTableMappingType({
            integrationId,
            tableId,
            tableType: tabularMappingConstraints[tableId].tableType,
          })
        );

        if (tabularMapping[tableId]?.rootWorkspace) {
          dispatchAction(
            applyRequiredColumnsMapping({ integrationId: 'microsoft-entra-id' })
          );
        }
      });
    }
  )
);

export default [
  handleLoadTransferConfig,
  handleApplySourceConfig,
  handleSaveIntegrationConfigurations,
  handleApplyRootWorkspaceForUsers,
  handleApplyRequiredTableMapping,
  handleApplyRequiredColumnsMapping,
  handleRequestTabularMappingsDefault,
];
