import {
  ImportAndScheduleProps,
  InfoNotificationComponent,
} from 'integrations/common/components/infoNotification/InfoNotificationComponent';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { TransferState } from 'integrations/common/streams/transferState/types';
import { connect } from '@ardoq/rxbeach';
import { combineLatest, map, switchMap } from 'rxjs';
import {
  extractWorkspaceFromTabularMappings,
  mergeConfiguredAffectedWorkspaces,
} from 'integrations/common/streams/tabularMappings/utils';
import { isSuccessTransfer } from 'integrations/common/streams/transferState/utils';
import { TabularMapping } from 'integrations/common/streams/tabularMappings/types';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';

export const buildModel = ({
  transferState,
  tabularMappings,
}: {
  transferState: TransferState;
  tabularMappings: TabularMapping;
}) => {
  // We need to show both the configured and affected workspaces on
  // PostImport page so that user can navigate to them.
  const affectedWorkspaces = mergeConfiguredAffectedWorkspaces(
    extractWorkspaceFromTabularMappings(tabularMappings),
    isSuccessTransfer(transferState)
      ? transferState.response.affectedWorkspaces
      : []
  );
  return {
    transferState,
    affectedWorkspaces,
  };
};

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      transferState: getTransferStateStream(integrationId),
      tabularMappings: getTabularMappingStream(integrationId),
    });
  }),
  map(buildModel)
);

function ShouldVisibleInfoNotification({
  transferState,
  affectedWorkspaces,
}: {
  transferState: TransferState;
  affectedWorkspaces: ImportAndScheduleProps['affectedWorkspaces'];
}) {
  if (transferState.requestStatus !== 'SUCCESS') {
    return <></>;
  }
  const deletedWorkspaces = affectedWorkspaces?.filter(
    workspace => workspace.deleted
  );

  if (!deletedWorkspaces || deletedWorkspaces.length === 0) {
    return <></>;
  }
  return <InfoNotificationComponent affectedWorkspaces={deletedWorkspaces} />;
}

export const DeleteInfoNotification = connect(
  ShouldVisibleInfoNotification,
  viewModel$
);
