import _ from 'lodash/fp';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { Maybe } from '@ardoq/common-helpers';
import { EMPTY, Observable, map } from 'rxjs';
import { buildInitialStreamState } from '../utils';
import { IntegrationId } from '../tabularMappings/types';
import { TabularMappingErrors } from './types';
import {
  resetTabularMappingErrors,
  resetTabularMappingErrorsPayload,
  setTableMappingErrors,
  setTableMappingErrorsPayload,
  setTabularMappingErrors,
  setTabularMappingErrorsPayload,
} from './actions';

type AllTabularMappingError = Record<IntegrationId, TabularMappingErrors>;

const initialState: AllTabularMappingError =
  buildInitialStreamState<TabularMappingErrors>(() => ({}));

const resetTabularMappingErrorsReducer = (
  state: AllTabularMappingError,
  { integrationId }: resetTabularMappingErrorsPayload
) => ({
  ...state,
  [integrationId]: {},
});

const handleResetTabularMappingErrors = reducer<
  AllTabularMappingError,
  resetTabularMappingErrorsPayload
>(resetTabularMappingErrors, resetTabularMappingErrorsReducer);

const setTableMappingErrorsReducer = (
  state: AllTabularMappingError,
  { integrationId, tableId, errors }: setTableMappingErrorsPayload
) => _.set([integrationId, tableId], errors, state);

const handleColumnMappingErrors = reducer<
  AllTabularMappingError,
  setTableMappingErrorsPayload
>(setTableMappingErrors, setTableMappingErrorsReducer);

const setTabularMappingErrorsReducer = (
  state: AllTabularMappingError,
  { integrationId, errors }: setTabularMappingErrorsPayload
) => _.set([integrationId], errors, state);

const handleSetTableMappingErrors = reducer<
  AllTabularMappingError,
  setTabularMappingErrorsPayload
>(setTabularMappingErrors, setTabularMappingErrorsReducer);

const reducers = [
  handleColumnMappingErrors,
  handleSetTableMappingErrors,
  handleResetTabularMappingErrors,
];

const tabularMappingErrors$ = persistentReducedStream(
  `integrationsTabularMappingErrors$`,
  initialState,
  reducers
);

const integrationToMappingStreams = new Map<
  IntegrationId,
  Observable<TabularMappingErrors>
>();

export const getTabularMappingErrorsStream = (
  integrationId: Maybe<IntegrationId>
) => {
  if (!integrationId) {
    return EMPTY;
  }

  const mappingStream = integrationToMappingStreams.get(integrationId);

  if (mappingStream) {
    return mappingStream;
  }

  const stream$ = tabularMappingErrors$.pipe(
    map(tabularMappingErrors => tabularMappingErrors[integrationId])
  );

  integrationToMappingStreams.set(integrationId, stream$);

  return stream$;
};
