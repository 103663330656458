import { useState } from 'react';
import styled, { css } from 'styled-components';
import { PrimaryButton } from '@ardoq/button';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import MediaHeader from './MediaHeader';
import { colors, shadowM } from '@ardoq/design-tokens';
import { Icon, IconName } from '@ardoq/icons';

type IsExpandedProps = { $isExpanded: boolean };

const MediaBody = styled.div<IsExpandedProps>`
  overflow: hidden;
  zoom: 1;
  vertical-align: top;
  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      display: initial;
    `}
`;
const SelectButton = styled(PrimaryButton)`
  clear: both;
  width: 100%;
  display: inline-block;
  margin: 10px 0px;
`;
const MediaObject = styled.div<IsExpandedProps>`
  overflow: ${({ $isExpanded }) => ($isExpanded ? 'visible' : 'hidden')};
  img,
  svg {
    width: 100%;
  }
  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      height: auto;
      margin-bottom: 10px;
    `}
`;
const ExpandControls = styled.div`
  width: 100%;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;
const MediaContainer = styled.div<IsExpandedProps>`
  width: 275px;
  display: flex;
  flex-direction: column;
  min-height: 179px;
  max-height: ${({ $isExpanded }) => ($isExpanded ? 'none' : '179px')};
  cursor: pointer;
  float: left;
  clear: none;
  padding: 9px 9px 0;
  margin: 0 11px;
  overflow: ${({ $isExpanded }) => ($isExpanded ? 'visible' : 'hidden')};
  zoom: 1;
  border-radius: 3px;
  margin-bottom: 20px;
  background-color: ${colors.grey95};
  border: 1px solid ${colors.grey90};
  &:hover {
    border-width: 2px;
    box-shadow: ${shadowM};
  }
`;

interface MediaContainerElementProperties {
  imageUrl: string;
  title: string;
  isSelected: boolean;
  viewStatus: MetaInfoViewStatus;
  description: string;
  toggleView: () => any;
}
export default ({
  imageUrl,
  title,
  isSelected,
  viewStatus,
  description,
  toggleView,
}: MediaContainerElementProperties) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <MediaContainer
      onClick={() => setIsExpanded(!isExpanded)}
      $isExpanded={isExpanded}
    >
      <MediaHeader
        title={title}
        isSelected={isSelected}
        viewStatus={viewStatus}
        toggleView={toggleView}
      />
      <MediaObject $isExpanded={isExpanded}>
        <img src={imageUrl} />
      </MediaObject>
      {isExpanded && (
        <MediaBody $isExpanded={isExpanded}>
          {description}
          <SelectButton
            onClick={e => {
              toggleView();
              setIsExpanded(false);
              e.stopPropagation();
            }}
          >
            {isSelected ? 'Deselect' : 'Select'}
          </SelectButton>
        </MediaBody>
      )}
      <ExpandControls>
        <Icon
          iconName={isExpanded ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
        />
      </ExpandControls>
    </MediaContainer>
  );
};
