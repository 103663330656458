import { rawFields$ } from 'streams/fields/fields$';
import { groupFields } from './utils';
import { fieldInterface } from '@ardoq/field-interface';
import { Observable, map } from 'rxjs';
import { ExistingField } from '../types';

export type FieldsGroup = {
  allFields: ExistingField[];
  text: ExistingField[];
  list: ExistingField[];
  url: ExistingField[];
  dateRange: ExistingField[];
};

export const getFields = (
  workspaceId: string
): Observable<{ globalFields: FieldsGroup; workspaceFields: FieldsGroup }> => {
  return rawFields$.pipe(
    map(fields => ({
      globalFields: groupFields(Object.values(fields.byId)),
      workspaceFields: groupFields(
        fieldInterface.getAllFieldsOfWorkspace(workspaceId)
      ),
    }))
  );
};
