import { NavigatorDropType, NodeModelTypes } from '../utils/consts';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { DiffType, RepresentationData } from '@ardoq/data-model';
import { NextSelectionContext, NodeModel } from './types';
import type Tree from './tree';
import { ArdoqId, Subdivision } from '@ardoq/api-types';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

const diffTypeToBitFlag = new Map([
  [DiffType.REMOVED, 0b001],
  [DiffType.ADDED, 0b010],
  [DiffType.CHANGED, 0b100],
]);

abstract class Node implements NodeModel {
  private _tree: Tree;
  private _parent: NodeModel | null;
  private _isCollapsed?: boolean;
  private _isSelected: boolean;
  private _hasChanged: boolean;
  private _isSelectedContext?: boolean;
  private _aggregatedVisualDiffState = 0;

  constructor(tree: Tree, parent: NodeModel | null = null) {
    // `model` is the tree to which this node belongs too.
    // But Node is also used as wrapper if a DnD action starts outside
    // of the navigator panel. In that case `model` is null.

    this._tree = tree;
    this._parent = parent;
    this._isCollapsed = true;
    this._isSelected = false;
    this._hasChanged = false;
    this._isSelectedContext = false;
  }

  abstract getChildren(): NodeModel[];

  abstract updateChildren(): void;

  abstract hasChildren(): boolean;

  // abstract id: does not work as expected so we declare like this

  get id() {
    // this is a virtual method.
    return '';
  }

  get hasWriteAccess() {
    // this is a virtual property.
    return false;
  }

  get type() {
    // this is a virtual property.
    return NodeModelTypes.NONE;
  }

  get tree() {
    return this._tree;
  }

  get isCollapsed() {
    return this._isCollapsed;
  }

  set isCollapsed(isCollapsed) {
    this._isCollapsed = isCollapsed;
  }

  get isSelected() {
    return this._isSelected;
  }

  set isSelected(isSelected) {
    this._isSelected = isSelected;
  }

  get hasChanged() {
    return this._hasChanged;
  }

  set hasChanged(hasChanged) {
    this._hasChanged = hasChanged;
  }

  get parent() {
    return this._parent;
  }

  set parent(parent) {
    this._parent = parent;
  }

  get isSelectedContext() {
    return this._isSelectedContext;
  }

  set isSelectedContext(isSelectedContext) {
    this._isSelectedContext = isSelectedContext;
  }

  get showIsActive() {
    // this is a virtual method.
    return false;
  }

  get aggregatedVisualDiffState() {
    return this.isCollapsed ? this._aggregatedVisualDiffState : 0;
  }

  get isContextNode() {
    // this is a virtual method.
    return false;
  }

  getNextSelectionCandidate(nextSelectionContext: NextSelectionContext) {
    if (this.tree.isNodeHidden(this)) {
      return nextSelectionContext;
    }

    if (nextSelectionContext.selection.size === 0) {
      if (!nextSelectionContext.isLastSet) {
        nextSelectionContext.nextSelection = this;
        nextSelectionContext.isLastSet = true;
      }
      return nextSelectionContext;
    }

    if (nextSelectionContext.selection.has(this)) {
      nextSelectionContext.selection.delete(this);
    } else {
      nextSelectionContext.nextSelection = this;
    }

    return this.getChildren().reduce(
      (nextSelectionContext, child) =>
        child.getNextSelectionCandidate(nextSelectionContext),
      nextSelectionContext
    );
  }

  reload() {
    this.updateChildren();
    this.getChildren().forEach(child => child.reload());
  }

  updateAggregatedVisualDiffState() {
    this._aggregatedVisualDiffState = this.getChildren().reduce(
      (aggregatedState, child) =>
        aggregatedState | child.updateAggregatedVisualDiffState(),
      0
    );
    return this._aggregatedVisualDiffState | this.calculateVisualDiffState();
  }

  calculateVisualDiffState() {
    return diffTypeToBitFlag.get(this.getVisualDiffType()) || 0;
  }

  getExistingIds(ids = new Set<string>()) {
    ids.add(this.id);
    this.getChildren().forEach(child => child.getExistingIds(ids));
    return ids;
  }

  getRepresentationData(): RepresentationData | null {
    // this is a virtual method.
    return null;
  }

  getCSSFilterColor(): string | null {
    // this is a virtual method.
    return null;
  }

  isIncludedInContextByFilter() {
    // this is a virtual method.
    return true;
  }

  getModelTypeId(): string | null {
    // this is a virtual method.
    return '';
  }

  getItemHeight(): number {
    // this is a virtual method.
    return 0;
  }

  isFlexibleModel() {
    // this is a virtual method.
    return false;
  }

  getWorkspaceId() {
    // this is a virtual method.
    return '';
  }

  getOrder() {
    // this is a virtual method.
    return -1;
  }

  getName() {
    // this is a virtual method.
    return '';
  }

  canHaveChildren() {
    // this is a virtual method.
    return true;
  }

  getUpdateParentAndOrder(parent: NodeModel, order = -1) {
    const attrs: { id: ArdoqId; _order?: number; parent?: ArdoqId | null } = {
      id: this.id,
    };
    if (order > -1) {
      attrs._order = order;
    }
    if (parent !== this.parent) {
      // Updating the children array of the new and the current parent is handled in ComponentHierarchy.
      attrs.parent =
        parent.type === NodeModelTypes.COMPONENT ? parent.id : null;
    }
    return attrs;
  }

  getLock() {
    // this is a virtual method.
    return false;
  }

  toggleCollapsed({ deep = false } = {}) {
    this.isCollapsed = !this.isCollapsed;
    if (deep) {
      if (this.isCollapsed) {
        this.collapseDeep();
      } else {
        this.expandDeep();
      }
    }
  }

  expand() {
    if (this.isCollapsed) {
      this.toggleCollapsed();
    }
  }

  collapse() {
    if (!this.isCollapsed) {
      this.toggleCollapsed();
    }
  }

  expandDeep() {
    this.expand();
    this.getChildren().forEach(child => child.expandDeep());
  }

  collapseDeep() {
    this.collapse();
    this.getChildren().forEach(child => child.collapseDeep());
  }

  getDropTypeForNode(draggedNode: NodeModel) {
    if (!draggedNode) return NavigatorDropType.DROP_TYPE_NO_DROP;
    if (
      draggedNode.type === NodeModelTypes.SCENARIO ||
      this.type === NodeModelTypes.SCENARIO
    ) {
      return NavigatorDropType.DROP_TYPE_NO_DROP;
    }
    if (!this.isInSameWorkspace(draggedNode)) {
      return activeScenarioOperations.isInScenarioMode(
        this.tree.activeScenarioState
      )
        ? NavigatorDropType.DROP_TYPE_NO_DROP
        : NavigatorDropType.DROP_TYPE_COPY;
    }
    if (this.isAncestor(draggedNode)) {
      return NavigatorDropType.DROP_TYPE_NO_DROP;
    }
    if (
      this.isFlexibleModel() ||
      this === draggedNode.parent ||
      // The parent of the dragged node and the new target parent are of the
      // same type, so obviously they can both have the dragged node as child.
      (draggedNode.parent &&
        this.getModelTypeId() === draggedNode.parent.getModelTypeId()) ||
      (this.canHaveChildren() && !draggedNode.hasChildren())
    ) {
      return NavigatorDropType.DROP_TYPE_MOVE;
    }
    return NavigatorDropType.DROP_TYPE_NO_DROP;
  }

  isInSameWorkspace(node: NodeModel) {
    return this.getWorkspaceId() === node.getWorkspaceId();
  }

  getWorkspaceBoundSubdivisionsIds() {
    return this.tree.navigatorViewInterface.getWorkspaceBoundSubdivisionsIds(
      this.getWorkspaceId()
    );
  }

  isAncestor(ancestor: NodeModel) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let node: NodeModel | null = this;
    while (node) {
      if (node === ancestor) {
        return true;
      }
      node = node.parent;
    }
    return false;
  }

  getRootNode(): NodeModel {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let root: NodeModel = this;
    while (root.parent && root.parent.type !== NodeModelTypes.SCENARIO) {
      root = root.parent;
    }
    return root;
  }

  updateSelectionRange(
    range: NodeModel[],
    rangeBoundaries: Set<NodeModel>,
    isRangeOpen = false
  ): boolean {
    let actualIsRangeOpen = isRangeOpen;
    if (rangeBoundaries.has(this)) {
      if (actualIsRangeOpen) {
        if (this.isIncludedInContextByFilter()) {
          range.push(this);
        }
        this.isSelected = true;
        return false;
      }
      actualIsRangeOpen = true;
    }

    if (actualIsRangeOpen && this.isIncludedInContextByFilter()) {
      range.push(this);
      this.isSelected = true;
    }

    if (!this.isCollapsed) {
      actualIsRangeOpen = this.getChildren().reduce(
        (isRangeOpen, child) =>
          child.updateSelectionRange(range, rangeBoundaries, isRangeOpen),
        actualIsRangeOpen
      );
    }

    return actualIsRangeOpen;
  }

  getVisualDiffType() {
    // this is a virtual method.
    return DiffType.NONE;
  }

  hasVisibleContent() {
    return this.getChildren().some(
      nodeModel => !this.tree?.isNodeHidden(nodeModel)
    );
  }

  getSubdivisions(): Subdivision[] {
    const { navigatorViewInterface } = this.tree;
    const subdivisionMembership =
      navigatorViewInterface.getComponentSubdivisionMembership(this.id);
    return subdivisionMembership
      .map<Subdivision | null>(navigatorViewInterface.getSubdivisionById)
      .filter(ExcludeFalsy);
  }
}

export default Node;
