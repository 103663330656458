import { map, startWith, withLatestFrom, Observable, shareReplay } from 'rxjs';
import { loadMetaModel$ } from 'architectureModel/loadMetaModel';
import currentUser$ from 'streams/currentUser/currentUser$';
import {
  LoadingState,
  LoadMetaModelAsScopedDataState,
} from './loadMetaModelTypes';
import { metaModelOperations } from 'architectureModel/metaModelOperations';

export const metaModelAsScopeData$: Observable<LoadMetaModelAsScopedDataState> =
  loadMetaModel$.pipe(
    withLatestFrom(currentUser$),
    map(metaModelOperations.processMetaModelAndUser),
    startWith({
      loading: 'loading the meta model...',
      status: 'LOADING',
    } satisfies LoadingState),
    shareReplay({ bufferSize: 1, refCount: true })
  );
