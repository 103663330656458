import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import { getDefaultState } from 'broadcasts/broadcastBuilder/broadcastContentForm/componentPreview/utils';

const componentPreviewDialog$ = persistentReducedStream(
  'componentPreviewDialog$',
  getDefaultState(),
  reducers
);

export default componentPreviewDialog$;
