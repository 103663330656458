import styled, { css } from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import { colors, vizGray0, vizGray20 } from '@ardoq/design-tokens';
import { GridEditorIcon, ToolbarIconButton } from '../atoms';
import { BottomBarViewProps, bottomBarViewModel$ } from './bottomBarViewModel';
import { BOTTOM_BAR_HEIGHT, BOTTOM_BAR_BORDER_TOP } from 'appLayout/consts';
import { OptionsGroup } from '@ardoq/settings-bar';
import { ButtonConfig, SettingsType } from '@ardoq/view-settings';
import { ViewIds } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import { FlexBox } from '@ardoq/layout';

const Container = styled.div<{ $hasEaseOfUse: boolean }>`
  ${({ $hasEaseOfUse }) =>
    $hasEaseOfUse
      ? css`
          background-color: ${vizGray0};
          border-top: ${BOTTOM_BAR_BORDER_TOP}px solid ${vizGray20};
          color: ${colors.grey25};
        `
      : css`
          background-color: ${colors.grey25};
          color: ${colors.white}CC;
          text-transform: uppercase;
          &:hover {
            color: white;
          }
        `}
  cursor: pointer;
  height: ${BOTTOM_BAR_HEIGHT}px;
  line-height: 40px;
  transition: color 0.3s;
  @media print {
    display: none !important;
  }
`;

/**
 * Used in ardoq-front to display the black bottom bar when the grid editor
 * is closed or open in an iframe.
 */
const BottomBarView = ({
  isActive,
  onContainerClick,
  bottomBarButtonIcons,
}: BottomBarViewProps) => {
  if (!isActive) {
    return null;
  }

  // few customers have Ardoq Studio turned off so the Grid Editor bar should not change for them
  // these three flags imply the state of Ardoq Studio
  const hasEaseOfUse =
    hasFeature(Features.SUPPORT_LARGE_DATASETS) &&
    hasFeature(Features.NEW_CORE_JOURNEY);

  return (
    <Container $hasEaseOfUse={hasEaseOfUse} onClick={onContainerClick}>
      <FlexBox gap="small" justify="space-between">
        <FlexBox align="center">
          <GridEditorIcon $hasEaseOfUse={hasEaseOfUse} />
          <span>Grid editor</span>
        </FlexBox>

        <FlexBox align="center">
          {hasEaseOfUse ? (
            <OptionsGroup
              viewId={ViewIds.NONE}
              options={bottomBarButtonIcons.map(
                ({ id, onClick, iconName }) =>
                  ({
                    type: SettingsType.BUTTON,
                    label: '',
                    id,
                    onClick,
                    iconName,
                  }) satisfies ButtonConfig
              )}
            />
          ) : (
            bottomBarButtonIcons.map(button => (
              <ToolbarIconButton key={button.id} {...button} />
            ))
          )}
        </FlexBox>
      </FlexBox>
    </Container>
  );
};

export default connect(BottomBarView, bottomBarViewModel$);
