import { APIRoleAttributes, OrgAccessLevel } from '@ardoq/api-types';
import { PrivilegesStreamShape } from 'privileges/types';
import { RolesStreamShape } from './types';
import { getUserByPrivilegesChanges } from 'privileges/utils';
import {
  ChangedUserIdsByPrivileges,
  openConfirmRoleChangesDialog,
} from 'roles/confirmRoleChangesDialog';
import { OrgUsersStreamShape } from 'streams/orgUsers/types';

const roleOrder: { [key in OrgAccessLevel]: number } = {
  [OrgAccessLevel.ADMIN]: 0,
  [OrgAccessLevel.WRITER]: 1,
  [OrgAccessLevel.READER]: 2,
  [OrgAccessLevel.CONTRIBUTOR]: 3,
};

export const roleComparator = (a: OrgAccessLevel, b: OrgAccessLevel) => {
  if (roleOrder[a] < roleOrder[b]) return -1;
  if (roleOrder[a] > roleOrder[b]) return 1;
  return 0;
};

export const getInheritedPrivileges = (
  roles: APIRoleAttributes[],
  roleLabel: string
) => {
  const role = roles.find(({ label }) => label === roleLabel);
  if (!role) return [];
  return role.privileges;
};

export const confirmSavingRoleChanges = async ([
  role,
  privileges,
  orgUsers,
  { roles: previousRoles },
]: [
  APIRoleAttributes,
  PrivilegesStreamShape,
  OrgUsersStreamShape,
  RolesStreamShape,
]) => {
  const previousRole: APIRoleAttributes = previousRoles.find(
    prole => prole.label === role.label
  )!;

  const newUserIdsByPrivileges: ChangedUserIdsByPrivileges =
    getUserByPrivilegesChanges({
      ...privileges,
      orgMembers: [...orgUsers.users],
      newRole: role,
      previousRole,
    });

  const confirmed = await openConfirmRoleChangesDialog({
    userIdsByPrivilege: newUserIdsByPrivileges,
    privileges: privileges.configurablePrivileges,
    roleLabel: role.title,
  });
  if (confirmed) {
    return role;
  }
  return undefined;
};
