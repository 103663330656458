import { BrandButton, DangerButton } from '@ardoq/button';
import { ErrorNotification, SuccessNotification } from '@ardoq/status-ui';
import { Link } from '@ardoq/typography';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { ReactNode, FC } from 'react';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import styled from 'styled-components';
import scimManagement$, {
  ScimManagementState,
  deleteToken,
  generateToken,
} from './scimManagement$';

export const ScimManagementWrapper = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const ScimButtonContainer = styled.div``;

export const ScimManagementSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NewTokenNotification = connect(
  ({ recentlyGeneratedToken }: ScimManagementState) =>
    recentlyGeneratedToken ? (
      <SuccessNotification>
        <p>
          <b>Token generated</b>
        </p>
        <p>
          Your new token is shown below. You will not be able to access it
          again.
        </p>
        <p>{recentlyGeneratedToken}</p>
      </SuccessNotification>
    ) : null,
  scimManagement$
);

export const ScimError = connect(
  ({ errorMessage }: ScimManagementState) =>
    errorMessage ? <ErrorNotification>{errorMessage}</ErrorNotification> : null,
  scimManagement$
);

export const ScimKBLink: FC<{ children?: ReactNode }> = ({ children }) => (
  <Link
    typography="text2"
    onClick={() =>
      window.open(KnowledgeBaseLink.SCIM_TOKEN_MANAGEMENT, '_blank')
    }
  >
    {children}
  </Link>
);

export const GenerateNewButton = () => (
  <BrandButton onClick={() => dispatchAction(generateToken())}>
    Generate new
  </BrandButton>
);

export const RevokeTokenButton = () => (
  <DangerButton onClick={() => dispatchAction(deleteToken())}>
    Revoke
  </DangerButton>
);
