import { CurrentChatMessage } from './bestPracticeAssistant$';
import { clamp, last, sortedUniq } from 'lodash';
import { ChatMessage, Citation } from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  currentDate,
  currentTimestamp,
  differenceInSeconds,
} from '@ardoq/date-time';

type MessageSpeed = 'slow' | 'medium' | 'fast';
type MessageConfig = {
  messagesToRead: number;
  delay: number;
};
const messageConfigs: Record<MessageSpeed, MessageConfig> = {
  slow: { messagesToRead: 1, delay: 200 },
  medium: { messagesToRead: 5, delay: 100 },
  fast: { messagesToRead: 7, delay: 50 },
};

const getMessageConfig = ({
  messageQueue,
  isDone,
}: CurrentChatMessage): MessageConfig => {
  if (isDone || messageQueue.length > 50) return messageConfigs.fast;
  if (messageQueue.length >= 0 && messageQueue.length < 20)
    return messageConfigs.slow;
  return messageConfigs.medium;
};

const getDefault = (clientRequestId: string): CurrentChatMessage => ({
  clientRequestId,
  messageQueue: [],
  isDone: false,
  lastMessageRead: null,
  citations: new Map(),
});

const setMessageQueue = (
  currentChatMessage: CurrentChatMessage,
  messageQueue: string[]
): CurrentChatMessage => ({
  ...currentChatMessage,
  messageQueue,
  lastMessageRead: currentDate(),
});

const appendWordToLastWordInArray = (
  words: string[],
  word: string
): string[] => (words.length ? [...words, `${last(words)} ${word}`] : [word]);
/**
 * A new message can contain more than one word appended compared to the previous message. This function splits a new message into word by word.
 *
 * Example:
 * message: "This is a message"
 * last message in the queue: "This"
 *
 * returns: [
 *  "This is",
 *  "This is a",
 *  "This is a message"
 * ]
 */

const getNewWordsForQueueFromMessage = (
  queue: string[],
  messages: ChatMessage[],
  message: string
): string[] => {
  const messageToCompare = last(queue) || last(messages)?.content;
  if (!messageToCompare) {
    return message.split(' ').reduce(appendWordToLastWordInArray, []);
  }

  const currentMessageWords = messageToCompare.split(' ');
  const wordsFromNewMessage = message.split(' ');
  const newWords = wordsFromNewMessage.slice(currentMessageWords.length);
  return newWords.reduce(appendWordToLastWordInArray, [messageToCompare]);
};

const regexToMatchToken = /\[doc\d+]/g;
const regexToExtractCitationKeyFromToken = /doc\d+/;
const removeUnusedCitationsAndSortByFirstAppearanceInMessage = (
  citations: Record<string, Citation>,
  message: string
): Map<string, Citation> =>
  new Map(
    sortedUniq(message.match(regexToMatchToken))
      .map<[string, Citation] | null>(token => {
        const key = token.match(regexToExtractCitationKeyFromToken)?.[0]; // using this ensures that the citations appear sequentially
        return key && citations[key] ? [token, citations[key]] : null;
      })
      .filter(ExcludeFalsy)
  );

const addMessageToQueue = (
  currentChatMessage: CurrentChatMessage,
  messages: ChatMessage[],
  message: string,
  citations: Record<string, Citation> | null
): CurrentChatMessage => ({
  ...currentChatMessage,
  citations: citations
    ? removeUnusedCitationsAndSortByFirstAppearanceInMessage(citations, message)
    : new Map(),
  messageQueue: [
    ...currentChatMessage.messageQueue,
    ...getNewWordsForQueueFromMessage(
      currentChatMessage.messageQueue,
      messages,
      message
    ),
  ],
});

const setIsDone = (
  currentChatMessage: CurrentChatMessage,
  isDone: boolean
): CurrentChatMessage => ({
  ...currentChatMessage,
  isDone,
});

const calculateMessagesToDequeue = (currentChatMessage: CurrentChatMessage) => {
  if (currentChatMessage.messageQueue.length <= 0) return 0;
  if (!currentChatMessage.lastMessageRead) return 1;

  const messageConfig = getMessageConfig(currentChatMessage);
  const timeSinceLastRead =
    currentTimestamp() - currentChatMessage.lastMessageRead.getTime();
  const messageCount = Math.round(timeSinceLastRead / messageConfig.delay);

  return clamp(
    messageConfig.messagesToRead,
    messageCount > 0 ? messageCount : 0,
    currentChatMessage.messageQueue.length
  );
};

const getDelay = (currentChatMessage: CurrentChatMessage) =>
  getMessageConfig(currentChatMessage).delay;

const hasMoreMessagesToDequeue = ({
  isDone,
  messageQueue,
}: CurrentChatMessage) => !isDone || messageQueue.length > 0;

const CHAT_TIMEOUT_THRESHOLD_IN_SECONDS = 20;
const hasChatTimedOut = (currentChatMessage: CurrentChatMessage) =>
  currentChatMessage.lastMessageRead &&
  differenceInSeconds(currentDate(), currentChatMessage.lastMessageRead) >
    CHAT_TIMEOUT_THRESHOLD_IN_SECONDS;

export const currentChatMessageOperations = {
  addMessageToQueue,
  setIsDone,
  setMessageQueue,
  getDefault,
  hasMoreMessagesToDequeue,
  calculateMessagesToDequeue,
  getDelay,
  hasChatTimedOut,
};
