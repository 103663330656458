import { dispatchAction } from '@ardoq/rxbeach';
import { AppMainSidebarCommands } from './types';
import { openViewpointBuilderCommands } from 'viewpointBuilder/openViewpointBuilderCommands';
import { navigateToViewpointsOverview } from 'streams/traversals/actions';
import { inventoryModuleWasSelected } from 'router/navigationActions';
import { InventoryModule } from 'inventory/consts';

export const appMainSidebarCommands: AppMainSidebarCommands = {
  navigateToViewpointsOverview: () =>
    dispatchAction(navigateToViewpointsOverview()),
  openViewpointBuilder: () =>
    openViewpointBuilderCommands.openViewpointBuilderToCreateViewpoint(),
  navigateToInventory: () =>
    dispatchAction(
      inventoryModuleWasSelected({
        inventoryModule: InventoryModule.OVERVIEW,
        dataSourceType: 'none',
      })
    ),
};
