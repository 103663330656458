import { actionCreator } from '@ardoq/rxbeach';
import { APIToken } from '@ardoq/api-types';
import { TokenTypes } from './types';

const DOMAIN = '[tokenTabView]';

export const receivedAPIUserTokens = actionCreator<APIToken[]>(
  `${DOMAIN} RECEIVED_API_USER_TOKENS`
);
export const receivedAPIOrgTokens = actionCreator<APIToken[]>(
  `[tokenTabView] RECEIVED_API_ORG_TOKENS`
);
export const userTokenCreated = actionCreator<APIToken>(
  `${DOMAIN} USER_TOKEN_CREATED`
);

export const userTokenRegenerated = actionCreator<APIToken>(
  `${DOMAIN} USER_TOKEN_REGENERATED`
);

export const userTokenDeleted = actionCreator<APIToken>(
  `${DOMAIN} USER_TOKEN_DELETED`
);

export const getUserTokens = actionCreator(`${DOMAIN} GET_USER_TOKENS`);

export const getOrgTokens = actionCreator(`[tokenTabView] GET_ORG_TOKENS`);

export const createToken = actionCreator<string>(`${DOMAIN} CREATE_TOKEN`);

export const createTokenFailed = actionCreator(`${DOMAIN} CREATE_TOKEN_FAILED`);

export const regenerateToken = actionCreator<APIToken>(
  `${DOMAIN} REGENERATE_TOKEN`
);

export const regenerateTokenFailed = actionCreator(
  `${DOMAIN} REGENERATE_TOKEN_FAILED`
);

export const deleteToken = actionCreator<APIToken>(`${DOMAIN} DELETE_TOKEN`);

export const setError = actionCreator<string>(`${DOMAIN} SET_ERROR`);

export const changeSearchKey = actionCreator<string>(
  `${DOMAIN} CHANGE_SEARCH_KEY`
);

export const switchTab = actionCreator<TokenTypes>(`${DOMAIN} SWITCH_TAB`);
