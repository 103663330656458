import CollapsedPathRepresentation from './PathRepresentation';
import { IconName, InfoIcon, WarningIcon } from '@ardoq/icons';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { Select } from '@ardoq/select';
import { IconButton } from '@ardoq/button';
import { Box, FlexBox } from '@ardoq/layout';
import { FieldsByComponentTypeName } from 'surveyAdmin/types';
import { getApproverFieldsByComponentTypeName } from 'surveyAdmin/SurveyEditor/utils';
import { Text } from '@ardoq/typography';
import { WithPopover } from '@ardoq/popovers';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { TableContainer } from './atoms';
import { trackEvent } from 'tracking/tracking';
import { last } from 'lodash';
import {
  DirectedTripleWithFilters,
  MetaModel,
  SurveyApprovalTraversalAudience,
} from '@ardoq/api-types';
import { WarningNotification } from '@ardoq/status-ui';
import { pluralize } from '@ardoq/common-helpers';
import styled from 'styled-components';
import { namedDirectedTripleToFrontendFormat } from './AudienceTraversalDrawer/traversalAudienceHelpers';

const EmailPopoverContent = () => (
  <Box maxWidth="300px">
    Users with an email address in the selected field will have access to
    approve survey responses for components they have a relationship with.
  </Box>
);

const DividingLine = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${colors.borderSubtle00};
`;

const DisplayOfPathsWithoutFieldOptions = ({
  numberOfTraversalsWithoutFieldsOptions,
}: {
  numberOfTraversalsWithoutFieldsOptions: number;
}) => {
  return (
    <FlexBox gap="medium" align="center" padding="medium">
      <DividingLine />
      <FlexBox flexShrink={0} gap="xsmall" align="center">
        <Text color="textSubtle" variant="text1Bold">
          {numberOfTraversalsWithoutFieldsOptions}
        </Text>
        <Text color="textSubtle" variant="text1">
          component {pluralize('type', numberOfTraversalsWithoutFieldsOptions)}{' '}
          with no email or user fields
        </Text>
      </FlexBox>
      <DividingLine />
    </FlexBox>
  );
};

const mainApproverCellStyle = {
  width: '60%',
  padding: `${s8} ${s16}`,
};

const emailFieldCellStyle = {
  width: '30%',
  padding: `${s8} ${s16}`,
};

const removeRowCellStyle = {
  width: '10%',
  padding: `${s8} ${s16}`,
};

const getFieldOptions = (
  fieldsByComponentTypeName: FieldsByComponentTypeName,
  componentTypeName: string
) => {
  return getApproverFieldsByComponentTypeName(
    fieldsByComponentTypeName,
    componentTypeName
  ).map(({ label, name }) => ({
    label,
    value: name,
  }));
};

type TraversalAudienceDisplayProps = {
  fieldsByComponentTypeName: FieldsByComponentTypeName;
  audience: SurveyApprovalTraversalAudience;
  metamodel: MetaModel;
  selectEmailField: (componentTypeName: string, emailField: string) => void;
  unselectPath: (path: DirectedTripleWithFilters[]) => void;
};

const TraversalAudienceDisplay = ({
  fieldsByComponentTypeName,
  audience,
  metamodel,
  selectEmailField,
  unselectPath,
}: TraversalAudienceDisplayProps) => {
  const showNoFieldsSelectedWarning = Boolean(
    audience.traversal.paths.length && !audience.userResolveFields.length
  );
  const enhancedDatasource = audience.traversal.paths.reduce<{
    withFieldOptions: {
      path: DirectedTripleWithFilters[];
      fieldName: string | undefined;
      fieldOptions: {
        label: string;
        value: string;
      }[];
      componentTypeName: string;
    }[];
    withoutFieldOptions: {
      path: DirectedTripleWithFilters[];
      fieldName: string | undefined;
      fieldOptions: {
        label: string;
        value: string;
      }[];
      componentTypeName: string;
    }[];
  }>(
    (acc, path) => {
      const normalizedPath = path.map(namedDirectedTripleToFrontendFormat);
      const lastTriple = last(normalizedPath);
      const componentTypeName =
        lastTriple?.direction === 'outgoing'
          ? lastTriple?.targetType
          : lastTriple?.sourceType;
      const fieldName = audience.userResolveFields.find(
        userResolveField =>
          userResolveField.componentTypeName === componentTypeName
      )?.fieldName;
      const fieldOptions = componentTypeName
        ? getFieldOptions(fieldsByComponentTypeName, componentTypeName)
        : [];
      const dataSource = {
        path: normalizedPath,
        fieldName,
        fieldOptions,
        componentTypeName: componentTypeName ?? 'Unknown',
      };
      if (fieldOptions.length === 0) {
        return {
          ...acc,
          withoutFieldOptions: [...acc.withoutFieldOptions, dataSource],
        };
      }
      return {
        ...acc,
        withFieldOptions: [...acc.withFieldOptions, dataSource],
      };
    },
    { withFieldOptions: [], withoutFieldOptions: [] }
  );

  const numberOfTraversalsWithoutFieldsOptions =
    enhancedDatasource.withoutFieldOptions.length;
  return (
    <>
      {showNoFieldsSelectedWarning && (
        <WarningNotification>
          Please select a user or email field for your selected audience(s)
          below. At current, no audience members will receive tasks when a
          survey response is awaiting approval.
        </WarningNotification>
      )}
      <TableContainer>
        <DatasourceTable
          style={{ width: '100%' }}
          dataSource={enhancedDatasource.withFieldOptions}
          columns={[
            {
              title: 'Main approver',
              cellStyle: mainApproverCellStyle,
              valueRender: (_, dataSource) => (
                <CollapsedPathRepresentation
                  path={dataSource.path}
                  metamodel={metamodel}
                />
              ),
            },
            {
              headerRender: () => (
                <FlexBox align="center" gap="small">
                  Email field
                  <WithPopover content={() => <EmailPopoverContent />}>
                    <InfoIcon color={colors.icon} />
                  </WithPopover>
                </FlexBox>
              ),
              cellStyle: emailFieldCellStyle,
              valueRender: (_, path) => {
                return (
                  <Select
                    options={path.fieldOptions}
                    value={path.fieldName}
                    onValueChange={emailField => {
                      if (!emailField) return;
                      selectEmailField(path.componentTypeName, emailField);
                    }}
                  />
                );
              },
            },
            {
              cellStyle: removeRowCellStyle,
              valueRender: (_value, dataSource) => (
                <FlexBox justify="end">
                  <IconButton
                    iconName={IconName.DELETE}
                    onClick={() => {
                      unselectPath(dataSource.path);
                      trackEvent(
                        'Change Approvals0 delete relationship audience member'
                      );
                    }}
                  />
                </FlexBox>
              ),
            },
          ]}
          components={newTableTheme}
          renderEmptyTable={() => (
            <Text variant="text1Italic" color="textSubtle" align="center">
              {numberOfTraversalsWithoutFieldsOptions > 0
                ? 'No relationship with relevant fields selected yet'
                : 'No relationship selected yet'}
            </Text>
          )}
        />
        {numberOfTraversalsWithoutFieldsOptions > 0 && (
          <>
            <DisplayOfPathsWithoutFieldOptions
              numberOfTraversalsWithoutFieldsOptions={
                numberOfTraversalsWithoutFieldsOptions
              }
            />
            <DatasourceTable
              style={{ width: '100%' }}
              dataSource={enhancedDatasource.withoutFieldOptions}
              hideHeader
              columns={[
                {
                  cellStyle: mainApproverCellStyle,
                  valueRender: (_, dataSource) => (
                    <CollapsedPathRepresentation
                      path={dataSource.path}
                      hasNoFieldOptions={true}
                      metamodel={metamodel}
                    />
                  ),
                },
                {
                  cellStyle: emailFieldCellStyle,
                  valueRender: _ => {
                    return (
                      <FlexBox align="center" gap="small">
                        <WarningIcon color={colors.iconWarning} />
                        <Text variant="caption" color="textWarning">
                          An email or user field is required to set an approver
                        </Text>
                      </FlexBox>
                    );
                  },
                },
                {
                  headerRender: () => null,
                  cellStyle: removeRowCellStyle,
                  valueRender: (_value, dataSource) => (
                    <FlexBox justify="end">
                      <IconButton
                        iconName={IconName.DELETE}
                        onClick={() => {
                          unselectPath(dataSource.path);
                          trackEvent(
                            'Change Approvals0 delete relationship audience member'
                          );
                        }}
                      />
                    </FlexBox>
                  ),
                },
              ]}
              components={newTableTheme}
              renderEmptyTable={() => null}
            />
          </>
        )}
      </TableContainer>
    </>
  );
};

export default TraversalAudienceDisplay;
