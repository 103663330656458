import type {
  SavedPerspectiveWithBackboneContext,
  SavedPerspectivesPermissions,
} from 'modelInterface/perspectives/perspectiveInterface';

const getFormattedSavedPerspectiveOptions = (
  savedPerspective: SavedPerspectiveWithBackboneContext,
  isUserAllowedToModifySavedPerspective: boolean
) => {
  return {
    id: savedPerspective._id,
    name: savedPerspective.name,
    authorName: savedPerspective.createdByName,
    createdAt: savedPerspective.created,
    canBeModifiedByCurrentUser: isUserAllowedToModifySavedPerspective,
  };
};

export const getFormattedSavedPerspectives = (savedPerspectiveOptions: {
  savedPerspectives: SavedPerspectiveWithBackboneContext[];
  savedPerspectivesPermissions: SavedPerspectivesPermissions;
}) => {
  const { savedPerspectives, savedPerspectivesPermissions } =
    savedPerspectiveOptions;
  return savedPerspectives.map(perspective => {
    return getFormattedSavedPerspectiveOptions(
      perspective,
      savedPerspectivesPermissions.get(perspective._id)?.update ?? false
    );
  });
};
