import {
  MIN_AMOUNT_OF_COLORS_IN_THEME,
  qualitativeTheme,
} from '@ardoq/color-picker';
import dashboardColorThemes$ from './dashboardColorThemes$';

export const EMPTY_PALETTE = Array(MIN_AMOUNT_OF_COLORS_IN_THEME).fill(null);

// convert 0-255 numeric string into two bit hex string
export const convertToHex = (numStr: string) =>
  `0${parseInt(numStr, 10).toString(16)}`.slice(-2);

export const parseColorsFromString = (colorsString: string) =>
  colorsString // trim whitespace
    .trim()
    // Convert all to lowercase to simplify parsing
    .toLowerCase()
    // Replace all newlines with a space so it's easier to match patterns where newlines interrupt color
    .replace(/\n/g, ' ')
    // replace all rgb strings with hex color strings
    .replace(
      /\brgb\((\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)/g,
      // only if matched numbers are 255 or lower
      (original, c1, c2, c3) =>
        c1 <= 255 && c2 <= 255 && c3 <= 255
          ? `#${[c1, c2, c3].map(convertToHex).join('')}`
          : original
    )
    // match all hex strings
    .match(/#[0-9a-f]{6}\b/g) || [];

export const getColorsFromClipBoard = async () =>
  parseColorsFromString(await navigator.clipboard.readText());

export const getSelectedDashboardColorPalette = () =>
  dashboardColorThemes$.state.dashboardColorThemes.find(
    ({ isSelected }) => isSelected
  )?.paletteColors ?? qualitativeTheme.paletteColors;
