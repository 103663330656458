// 'p' is used in type ids, all other Ardoq ids use only hex chars.
export const BASE = 16;
export const CLOSE_AND_RULES = 'h';
export const CLOSE_OR_RULES = 'k';
export const ID_SEPARATOR = 'r';
export const OPEN_AND_RULES = 's';
export const OPEN_OR_RULES = 'i';
export const PATH_SEPARATOR = 'g';
export const QUERY_PROPERTIES_COUNT = 6;
export const QUERY_RULE_TERMINATOR = 'l';
export const SEARCH_SEPARATOR = 'm';
export const STATE_SEPARATOR = '/';
export const TRAVERSAL_SEPARATOR = 'n';
export const TOKEN_SEPARATOR = ';';
export const FILTER_SEPARATOR = 'o';
export const COLLAPSED_PATH_SEPARATOR = 'q';
