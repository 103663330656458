import type {
  FormattingRule,
  PerspectivesFormatting as LegacyPerspectivesFormatting,
} from '@ardoq/perspectives';
import { PerspectivesFormatting } from '@ardoq/perspectives-sidebar';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { trackReferenceLabelFormatOption } from 'perspective/tracking';
import activeView$ from '../views/mainContent/activeView$';
import {
  DEFAULT_COMPONENT_LABEL_FORMATTING,
  DEFAULT_REFERENCE_LABEL_FORMATTING,
} from 'perspective/perspectiveEditor/labelFormattingUtils';
import {
  ReferenceLabelSource,
  type LabelFormattingInfo,
} from '@ardoq/data-model';

export const applyLabelFormatting = (
  labelFormatting: LabelFormattingInfo[],
  showReferenceType: boolean
) => {
  filterInterface.removeComponentLabelFormattingFilters(true);
  filterInterface.removeReferenceLabelFormattingFilters(true);

  if (showReferenceType) {
    filterInterface.createReferenceLabelFilter(
      ReferenceLabelSource.REFERENCE_TYPE
    );
  } else {
    filterInterface.createReferenceLabelFilter(ReferenceLabelSource.NONE);
  }

  filterInterface.createLabelFormatting(labelFormatting);
};

export const applyFormatting = (
  formatting: PerspectivesFormatting | LegacyPerspectivesFormatting
) => {
  applyComponentLabelFormatting(
    formatting.componentLabelFormattingValue,
    formatting.isComponentLabelTimeIncluded
  );
  applyReferenceLabelFormatting(
    formatting.referenceLabelFormattingValue,
    formatting.isReferenceLabelTimeIncluded
  );
  applyConditionalFormatting(formatting.conditionalFormattingRules);
};

export const applyConditionalFormatting = (
  conditionalFormattingRules?: FormattingRule[]
) => {
  if (!conditionalFormattingRules) {
    return;
  }

  filterInterface.setConditionalFormattingFilters(conditionalFormattingRules);
};

const applyComponentLabelFormatting = (
  componentLabelFormatting?: string,
  includeTime?: boolean
) => {
  if (!componentLabelFormatting) {
    return;
  }

  const isDefaultFormatting =
    componentLabelFormatting === DEFAULT_COMPONENT_LABEL_FORMATTING;

  filterInterface.removeComponentLabelFormattingFilters(isDefaultFormatting);

  if (!isDefaultFormatting) {
    filterInterface.createComponentLabelFilter(
      componentLabelFormatting,
      includeTime
    );
  }
};

const applyReferenceLabelFormatting = (
  referenceLabelFormatting?: string,
  includeTime?: boolean
) => {
  if (!referenceLabelFormatting) {
    return;
  }

  const isSameFormattingAlreadySet =
    filterInterface.getReferenceLabelFilter().value ===
    referenceLabelFormatting;

  const isDefaultFormatting =
    referenceLabelFormatting === DEFAULT_REFERENCE_LABEL_FORMATTING;
  filterInterface.removeReferenceLabelFormattingFilters(isDefaultFormatting);

  if (!isDefaultFormatting) {
    filterInterface.createReferenceLabelFilter(
      referenceLabelFormatting,
      includeTime
    );
  }

  if (!isSameFormattingAlreadySet)
    trackReferenceLabelFormatOption({
      refLabelFormatOption: referenceLabelFormatting,
      activeView: activeView$.state.mainViewId,
    });
};
