import { colors } from '@ardoq/design-tokens';
import { HorizontalStepper } from '@ardoq/steppers';
import styled from 'styled-components';

// We wouldn't need this when we are fully migrated to the new navigation bar
// and overall background color of the app is white
const StepperWrapper = styled.div`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.grey80};
`;

export function Stepper({ children }: { children: React.ReactElement[] }) {
  return (
    <StepperWrapper>
      <HorizontalStepper>{children}</HorizontalStepper>
    </StepperWrapper>
  );
}
