import { useEffect } from 'react';
import styled from 'styled-components';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { ButtonWithDropdown, DropdownOptionType } from '@ardoq/dropdown-menu';
import { colors, s4 } from '@ardoq/design-tokens';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { fetchViewpointsForComponentTypeName } from 'broadcasts/actions';
import broadcast$ from 'broadcasts/broadcast$';
import { APIDiscoverViewpointAttributes } from '@ardoq/api-types';

type ViewpointSelectProps = {
  selectedViewpoint?: string | null;
  viewpoints?: APIDiscoverViewpointAttributes[] | null;
  onChange: (viewpointId: string | null) => void;
  componentTypeName: string;
  isLoadingViewpoints?: boolean;
};

const VisualizationPreview = styled.img`
  border: 1px solid ${colors.grey80};
  border-radius: ${s4};
`;

export function ViewpointSelect({
  componentTypeName,
  onChange,
  viewpoints,
  isLoadingViewpoints,
  selectedViewpoint,
}: ViewpointSelectProps) {
  useEffect(() => {
    dispatchAction(fetchViewpointsForComponentTypeName(componentTypeName));
  }, [componentTypeName]);

  const options = (viewpoints ?? []).map(viewpoint => {
    return {
      label: viewpoint.name,
      rightContent: (
        <VisualizationPreview src={viewpoint.defaultVisualizationImageUrl} />
      ),
      description: viewpoint.description,
      truncateTitle: true,
      truncateDescription: true,
      onClick: () => {
        onChange(viewpoint._id);
      },
    };
  });
  const defaultOption = {
    label: 'Default Overview',
    description:
      'Shows all your selected components direct neightbours. Understand its context and navigate in any direction.',
    rightContent: (
      <VisualizationPreview src="/api/img/visualizations/relationship-view.svg" />
    ),
    truncateTitle: true,
    truncateDescription: true,
    onClick: () => {
      onChange(null);
    },
  };
  const footer = [
    {
      type: DropdownOptionType.DIVIDER,
    },
    {
      description:
        "Haven't found what you are looking for? Get in touch with an Ardoq admin.",
      isDisabled: true,
      type: DropdownOptionType.OPTION,
    },
  ];
  return (
    <ButtonWithDropdown
      isLoading={isLoadingViewpoints}
      dropdownSize={DropdownSize.M}
      alignMenuTo="left"
      options={[defaultOption, ...options, ...footer]}
      dataTestId="viewpoint-select"
    >
      {(viewpoints ?? []).find(viewpoint => viewpoint._id === selectedViewpoint)
        ?.name ?? defaultOption.label}
    </ButtonWithDropdown>
  );
}

export default connect(ViewpointSelect, broadcast$);
