import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { leftPaneCreated } from './actions';
import { returnPayload } from '@ardoq/common-helpers';

export const leftPaneRef$ =
  persistentReducedStream<React.RefObject<HTMLDivElement> | null>(
    'leftPaneRef$',
    null,
    [reducer(leftPaneCreated, returnPayload)]
  );
