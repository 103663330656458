import { IconButton, GhostButton, TertiaryButton } from '@ardoq/button';
import { SaveIcon, ChevronLeftIcon, IconName } from '@ardoq/icons';
import { surveyAccessControlInterface } from 'resourcePermissions/accessControlHelpers/surveys';
import { SurveyTrackingEventsNames } from 'surveyAdmin/tracking';
import { trackEvent } from 'tracking/tracking';
import { Switch } from '@ardoq/forms';
import BroadcastContextMenu from './BroadcastContextMenu';
import { ArdoqId } from '@ardoq/api-types';
import { ToolbarDivider } from '@ardoq/page-layout';
import { Space } from '@ardoq/style-helpers';
import Navbar from 'views/navbar/Navbar';

type SurveyEditorNavBarProps = {
  surveyId?: ArdoqId;
  surveyName: string;
  canSave: boolean;
  saveButtonText: string;
  isPublished: boolean;
  canPublish: boolean;
  hasError: boolean;
  notEnoughSelectedWorkspacePermission?: boolean;
  onBack: () => void;
  onSave: () => void;
  onOpenSurvey: () => void;
  onPublish: () => void;
};

const SurveyEditorNavBar = ({
  surveyId,
  canPublish,
  notEnoughSelectedWorkspacePermission,
  isPublished,
  surveyName,
  canSave,
  saveButtonText,
  hasError,
  onBack,
  onSave,
  onPublish,
  onOpenSurvey,
}: SurveyEditorNavBarProps) => {
  const shouldShowSaveButton = surveyId
    ? surveyAccessControlInterface.canEditSurvey(surveyId)
    : surveyAccessControlInterface.canCreateSurvey();
  return (
    <Navbar
      toolbarContent={
        <Space $align="center">
          <IconButton
            iconName={IconName.VISIBILITY}
            isDisabled={!canPublish || hasError}
            onClick={onOpenSurvey}
            dataTestId="open-survey-button"
            data-tooltip-text="Open survey"
          />
          <BroadcastContextMenu
            surveyId={surveyId}
            isDisabled={!isPublished || !canPublish}
          />
          <ToolbarDivider />
          <Switch
            label="Publish"
            name="publish"
            isChecked={isPublished}
            onChange={onPublish}
            isDisabled={
              !canPublish ||
              ((hasError || notEnoughSelectedWorkspacePermission) &&
                !isPublished)
            }
          />
          <ToolbarDivider />
        </Space>
      }
      primaryContent={surveyName.length ? surveyName : 'New Survey'}
      primaryButton={
        shouldShowSaveButton && (
          <TertiaryButton
            isDisabled={!canSave}
            onClick={onSave}
            dataTestId="save-button"
          >
            {saveButtonText}
            <SaveIcon />
          </TertiaryButton>
        )
      }
      secondaryContent="Survey builder"
      secondaryButton={
        <GhostButton
          onClick={() => {
            trackEvent(
              SurveyTrackingEventsNames.CLICKED_ON_BACK_TO_OVERVIEW_IN_SURVEY_EDITOR
            );
            onBack();
          }}
          dataTestId="overview-button"
        >
          <ChevronLeftIcon />
          Back to Surveys
        </GhostButton>
      }
    />
  );
};

export default SurveyEditorNavBar;
