import {
  action$,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  renderMainContentView,
  setActiveMainTabLeft,
} from 'streams/views/mainContent/actions';
import viewInterface from 'streams/views/mainContent/viewInterface';
import GroupBy from 'models/groupBy';
import Filters from 'collections/filters';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

import { Features, hasFeature } from '@ardoq/features';
import { getWorkspaceIdsInWorkspaceFilter } from 'collections/filterUtils';
import { deepCopyObject } from 'utils/collectionUtil';
import {
  requestShowAppModule,
  togglePresentationEditor,
} from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { updateViewSettings } from '@ardoq/view-settings';
import { setGroupBys } from 'presentation/viewPane/utils';
import { replaceAllDynamicFilters } from 'filters/dynamicFilters';
import { closeOpenWorkspaces } from 'utils/workspace';
import { openAssetsBrowser } from 'components/assetsBrowserDialog/actions';
import { GroupByBackboneModel } from 'aqTypes';
import {
  APIPresentationAssetAttributes,
  APISlideAttributes,
  ArdoqId,
  AssetType,
  DashboardSlide,
  FilterAttributes,
  isDashboardSlide,
  isLucidSlide,
  isMetamodelSlide,
  isReportSlide,
  isSpecificMetamodelSlide,
  isVisualizationSlide,
  MetamodelSlide,
  PresentationReadPermissions,
  PresentationWritePermissions,
  ReportSlide,
  SlideTypes,
  ViewIds,
  VisualizationSlide,
  LucidSlide,
} from '@ardoq/api-types';
import {
  PRES_READ_PERMISSION_LABELS,
  PRES_WRITE_PERMISSION_LABELS,
  SUBDIVISION_PRESENTATION_READ_PERMISSION_LABELS,
} from './consts';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { trackViewedSlide } from './tracking';
import { goToMetamodel } from 'metamodel/navigationUtils';
import { SlideWorkspacesMetadata } from './types';
import { logError } from '@ardoq/logging';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  closeScenario,
  closeScenarioError,
  closeScenarioSuccess,
  loadDiff,
  loadDiffError,
  loadDiffSuccess,
  openScenario,
  openScenarioError,
  openScenarioSuccess,
  updateScopeDiff,
} from 'scope/actions';
import { showPresentationEditor } from 'presentationEditor/actions';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { ArdoqError, ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { setIsUpdatingAppState } from '../isUpdatingAppState$';
import { copyModal } from 'copyComponent/copyModal';
import { first } from 'rxjs/operators';
import { SpecialFilterKeys } from '@ardoq/query-builder';
import { difference, isEmpty } from 'lodash';
import {
  openDashboard,
  openReportInReader,
} from '../components/AppMainSidebar/utils';
import { getConnectedWorkspaceIds } from 'streams/linkedWorkspaces$';
import defaultState from 'views/defaultState';
import { setSlidesMigrationInfo } from 'presentation/actions';
import { isViewDiscontinued } from 'viewDeprecation/restrictedViews';
import { isError, rebuildAndRegisterState } from 'loadedState/buildState';
import { clearLoadedState } from 'loadedState/actions';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { updateSyncStatusIfChanged } from 'scope/utils';
import { presentationApi } from '@ardoq/api';
import { activeWorkspaceRegistered } from 'traversals/stagedLoadedDataAndState$';
import { SelectOption } from '@ardoq/select';
import { notifyPerspectiveApplied } from '../perspective/actions';
import { slideInterface } from 'modelInterface/presentations/slideInterface';
import { clearLoadedGraphState } from 'streams/traversals/actions';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';
import { Badge, StatusType } from '@ardoq/status-ui';
import presentations$, {
  presentationsNamespace,
} from 'streams/presentations/presentations$';
import { presentationOperations } from 'streams/presentations/presentationOperations';
import { loadSlides } from 'presentation/slideNavigator/actions';
import {
  setIsLoading,
  setIsLoadingWithConfig,
} from '../components/GlobalPaneLoader/globalPaneLoader$';
import slides$ from 'streams/slides/slides$';
import { confirmDeleteAsset } from 'components/DeleteAssetModal/DeleteAssetModal';
import { DeleteType } from 'components/DeleteAssetModal/types';
import { deleteRequested } from 'streams/crud/actions';
import { handleDeleteAsset } from 'appContainer/DashboardContainer/getMenuOptions/handleDeleteAsset';
import { filterQueryToReportUriFilters } from '@ardoq/report-reader';
import { contextInterface } from 'modelInterface/contextInterface';
import { navigateToExternalDocument } from 'externalDocuments/actions';

function selectStartingPoint({
  forPresentationId,
}: {
  forPresentationId: ArdoqId;
}) {
  dispatchAction(openAssetsBrowser({ forPresentationId }));
}

const setContextWorkspaces = async (
  workspaceIds: ArdoqId[],
  contextWorkspaceId: ArdoqId
) => {
  await contextInterface.loadWorkspaces({
    contextWorkspaceId,
    workspaceIds,
    trackingLocation: 'presentationSlide',
  });
  closeOpenWorkspaces(workspaceIds);
};

type GetWorkspacesFromSlideReturn = {
  slideWorkspaceIds: ArdoqId[];
  contextWorkspaceId: ArdoqId;
};
const getWorkspacesFromSlide = (
  slide: VisualizationSlide
): GetWorkspacesFromSlideReturn => {
  const contextWorkspaceId = slide.workspaceId;
  const slideWorkspaceIds: ArdoqId[] = slide.workspaceIds || [
    slide.workspaceId,
  ];

  return {
    slideWorkspaceIds: slideWorkspaceIds.filter(
      workspaceInterface.workspaceExists
    ),
    contextWorkspaceId,
  };
};

const getGroupBysNotTiedToWorkspace = (
  groupBys: GroupByBackboneModel['attributes'][]
) =>
  groupBys
    .map(groupByAttributes => new GroupBy.model(groupByAttributes))
    .filter(
      (groupBy: GroupByBackboneModel) =>
        !groupBy.isTiedToWorkspace() ||
        workspaceInterface.workspaceExists(groupBy.get('workspaceId'))
    );

const loadSlideView = async (viewId: ArdoqId | null) =>
  new Promise<void>((resolve, reject) => {
    const activeView = viewInterface.getMainView();
    if (activeView && activeView.id === viewId) {
      resolve();
    } else {
      action$
        .pipe(ofType(setActiveMainTabLeft), extractPayload(), first())
        .subscribe(({ activeTabId }) => {
          if (viewId === activeTabId) {
            resolve();
          } else {
            reject(activeTabId);
          }
        });
      if (!viewId) {
        logError(Error('null viewId in presentationUtil.loadSlideView!'));
      } else {
        dispatchAction(
          setActiveMainTabLeft({ activeTabId: viewId as ViewIds })
        );
      }
    }
  });

type LoadVisualizationSlideParams = {
  slide: VisualizationSlide;
  loadInBackground?: boolean;
  isCurrentlyInViewpointMode?: boolean;
  activePresentationId?: ArdoqId;
  /**
   * Aborting slide data requests is currently only handled for viewpoint
   * slides. We didn't notice that issue of merging slide state for other types
   * of slides, but if that is an issue too, it can be addressed here too with
   * passing along the abort signal to the according requests.
   */
  abortSignal?: AbortSignal;
};
export const loadVisualizationSlide = async ({
  slide,
  loadInBackground = false,
  isCurrentlyInViewpointMode = false,
  abortSignal,
  activePresentationId,
}: LoadVisualizationSlideParams) => {
  let result: { error: Error | string | null } = { error: null };
  const isViewpointSlide = slideInterface.isViewpointSlide(slide._id);
  dispatchAction(setIsUpdatingAppState(true));
  dispatchAction(
    clearLoadedGraphState({
      keepCurrentViewPointMode: isViewpointSlide,
    })
  );
  dispatchAction(clearLoadedState());
  try {
    const { slideWorkspaceIds, contextWorkspaceId } =
      getWorkspacesFromSlide(slide);
    const scenarioId = slide.scenarioId || null;
    // If it is a scenario slide the scenario is already loaded in loadSlide.
    // Calling setContextWorkspaces would in that case potentially close scenario
    // workspaces.
    if (!scenarioInterface.exists(scenarioId)) {
      const loadedStates = slide.loadedStates || [];
      if (isViewpointSlide) {
        // Closing all workspaces to reset the collections. A traversal or
        // a search loads only a subset of workspace entities.
        await contextInterface.resetState({
          keepCurrentViewPointMode: true,
        });
        dispatchAction(activeWorkspaceRegistered(contextWorkspaceId));

        dispatchAction(setIsLoadingWithConfig({ title: 'Loading data...' }));
        const responseOrError = await rebuildAndRegisterState({
          loadedState: loadedStates,
          abortSignal,
        });
        dispatchAction(setIsLoading(false));

        if (isError(responseOrError))
          return {
            error: `${responseOrError.error} - ${responseOrError.reason}`,
          };
      } else {
        if (isCurrentlyInViewpointMode) {
          await contextInterface.resetState();
        }
        await setContextWorkspaces(slideWorkspaceIds, contextWorkspaceId);
      }
    }

    if (scenarioId && scenarioInterface.exists(scenarioId)) {
      // ARD-25291 we need this to happen BEFORE we set the context component so that the navigator shows the correct selected item (either a workspace or a component)
      contextInterface.setScenarioById(scenarioId);
      // ARD-25291 if no selectedSlideId, this means a specific workspace or the entire scenario was set as the focus at the time the slide was created
      if (!slide.selectedScenarioId) {
        await contextInterface.loadWorkspaces({
          contextWorkspaceId,
          workspaceIds: slideWorkspaceIds,
          trackingLocation: 'presentationSlide',
        });
      }
    }

    if (slide.componentId) {
      contextInterface.setComponentById(slide.componentId);
    }

    await replaceAllDynamicFilters(slide.dynamicFilters || []);

    const slideFilters = slide.filters;

    // don't load automatically generated workspace filters for viewpoint mode slides
    const filtersToLoad = isViewpointSlide
      ? {
          advancedFilters: slideFilters.advancedFilters,
        }
      : slideFilters;

    Filters.loadFilters({
      ...filtersToLoad,
      shouldTriggerChangeEvent: true,
    });

    setGroupBys(getGroupBysNotTiedToWorkspace(slide.groupBys || []));
    const sort = slideInterface.getSort(slide._id);
    if (sort) {
      contextInterface.setSort(sort);
    }

    dispatchAction(notifyPerspectiveApplied());

    const slideViewstate = deepCopyObject(slide.viewstate);
    const viewId = slide.view;
    const viewstate = { ...defaultState.get(viewId), ...slideViewstate };

    // working under premise that a discontinued view can only be loaded from old slides
    // if the slide view is discontinued, we need to set the activeSlide in
    // slidesMigrationInfo$ for the view replacement to be shown
    dispatchAction(
      setSlidesMigrationInfo({
        activePresentationId,
        activeSlide: isViewDiscontinued(viewId)
          ? (slideInterface.getSlideLoadPayload(slide._id) ?? undefined)
          : undefined,
      })
    );

    if (!loadInBackground) {
      await loadSlideView(viewId);
    }

    if (!loadInBackground) dispatchAction(renderMainContentView());
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
    );

    setTimeout(() => {
      // this timeout is here to dispatch the view settings update after the saved view settings replay
      dispatchAction(
        updateViewSettings({ viewId, settings: viewstate, persistent: false })
      );
    });
  } catch (e) {
    const error = e instanceof Error ? e : Error(`Failed to load slide view`);
    result = { error };
    logError(error, 'Failed to load visualization slide', {
      expectedViewId: slide.view,
      loadedViewId: typeof error === 'string' ? error : 'unknown',
    });
  } finally {
    setTimeout(() => dispatchAction(setIsUpdatingAppState(false)), 50);
  }
  return result;
};

export const loadDashboardSlide = (slide: DashboardSlide) => {
  openDashboard({
    dashboardId: slide.dashboardId,
  });
};

export const loadReportSlide = (slide: ReportSlide) => {
  openReportInReader({
    reportId: slide.reportId,
    filters: !isEmpty(slide.reportParams?.filters)
      ? filterQueryToReportUriFilters(slide.reportParams.filters)
      : undefined,
  });
};

export const loadLucidSlide = (slide: LucidSlide) => {
  dispatchAction(
    navigateToExternalDocument({
      externalDocumentId: slide.lucidDocumentId,
      externalDocumentTitle: slide.name,
    })
  );
};

export const loadMetamodelSlide = (slide: MetamodelSlide) => {
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.METAMODEL })
  );
  goToMetamodel(
    isSpecificMetamodelSlide(slide) ? slide.metamodelId : undefined
  );
};

export const loadSlide = async (
  slide: APISlideAttributes,
  isCurrentlyInViewpointMode: boolean,
  presentationId?: ArdoqId,
  abortSignal?: AbortSignal
) => {
  if (!slide) return { error: 'Slide not found' };

  let result: { error: Error | string | null } = { error: null };

  try {
    if (isVisualizationSlide(slide)) {
      if (hasFeature(Features.SCENARIOS_BETA)) {
        const currentScenarioId = getActiveScenarioId();
        const wasInScenarioMode = Boolean(currentScenarioId);
        const scenarioId = slide.scenarioId;
        const isScenarioMode = Boolean(scenarioId);
        const isInVisualDiffMode = slide.isInVisualDiffMode;

        if (isScenarioMode) {
          if (isInScopeDiffMode()) {
            dispatchAction(updateScopeDiff({ scopeDiff: null }));
          }
          const componentId = slide.componentId;
          if (isInVisualDiffMode && scenarioId) {
            updateSyncStatusIfChanged(scenarioId);
            await dispatchActionAndWaitForResponse(
              loadDiff({
                scenarioId,
              }),
              loadDiffSuccess,
              loadDiffError
            );
          } else if (currentScenarioId !== scenarioId) {
            await dispatchActionAndWaitForResponse(
              openScenario({
                scenarioId: scenarioId ?? '',
                componentId: componentId || undefined,
                trackingClickSource: 'presentationSlide',
                isPresentationSlide: true,
              }),
              openScenarioSuccess,
              openScenarioError
            );
          } else if (componentId) {
            // Scenario is already open, just set context component
            contextInterface.setComponentById(componentId);
          }
        } else if (wasInScenarioMode) {
          await dispatchActionAndWaitForResponse(
            closeScenario(),
            closeScenarioSuccess,
            closeScenarioError
          );
        }
      }
      result = await loadVisualizationSlide({
        slide,
        isCurrentlyInViewpointMode,
        abortSignal,
        activePresentationId: presentationId,
      });
      trackViewedSlide({
        slideType: slide.type,
        viewId: slide.view,
      });
    } else if (isMetamodelSlide(slide)) {
      loadMetamodelSlide(slide);
      trackViewedSlide({
        slideType: slide.type,
        viewId: undefined,
      });
    } else if (isReportSlide(slide)) {
      loadReportSlide(slide);
      trackViewedSlide({
        slideType: slide.type,
        viewId: undefined,
      });
    } else if (isLucidSlide(slide)) {
      loadLucidSlide(slide);
      trackViewedSlide({
        slideType: slide.type,
        viewId: undefined,
      });
    } else {
      loadDashboardSlide(slide);
      trackViewedSlide({
        slideType: slide.type,
        viewId: undefined,
      });
    }
  } catch (error) {
    result = { error: error as Error };
    logError(error as Error);
  }
  return result;
};

export const getWritePermissionOptions =
  (): SelectOption<PresentationWritePermissions>[] => {
    const labelsLookup = PRES_WRITE_PERMISSION_LABELS;
    return Object.entries(labelsLookup).map(([permissionLevel, label]) => ({
      value: permissionLevel as PresentationWritePermissions,
      label,
    }));
  };

export const getReadPermissionOptions = (
  isSubdivisionsPresentation: boolean
): SelectOption<PresentationReadPermissions>[] => {
  const hasPublicPresentations = hasFeature(Features.PUBLIC_PRESENTATIONS);
  const labelsLookup = isSubdivisionsPresentation
    ? SUBDIVISION_PRESENTATION_READ_PERMISSION_LABELS
    : PRES_READ_PERMISSION_LABELS;
  return Object.entries(labelsLookup)
    .map(([permissionLevel, label]) => ({
      value: permissionLevel as PresentationReadPermissions,
      label,
      rightContent:
        isSubdivisionsPresentation &&
        permissionLevel === PresentationReadPermissions.WS ? (
          <Badge label="Deprecated" statusType={StatusType.WARNING} />
        ) : null,
    }))
    .filter(({ value }) => {
      const isPublicOption = value === PresentationReadPermissions.ALL;
      // Include the public presentation option only if org has public presentations
      return !isPublicOption || hasPublicPresentations;
    });
};

const getSelectedModuleFromSlideType = (slideType: SlideTypes): AppModules => {
  switch (slideType) {
    case SlideTypes.REPORT:
      return AppModules.REPORTS;
    case SlideTypes.DASHBOARD:
      return AppModules.DASHBOARDS;
    case SlideTypes.METAMODEL:
      return AppModules.METAMODEL;
    case SlideTypes.LUCID:
      return AppModules.EXTERNAL_DOCUMENT;
    default:
      return AppModules.WORKSPACES;
  }
};

const showPresentationEditorSidebar = (
  presentationId: ArdoqId,
  editingSlideId: ArdoqId | null,
  selectedSlideType: SlideTypes = SlideTypes.VISUALIZATION,
  isOpeningPresentationEditorToAddSlideFromCurrentView: boolean
) => {
  if (!isOpeningPresentationEditorToAddSlideFromCurrentView) {
    const selectedModule = getSelectedModuleFromSlideType(selectedSlideType);
    dispatchAction(requestShowAppModule({ selectedModule }));
  }
  dispatchAction(
    showPresentationEditor({
      presentationId,
      editingSlideId: editingSlideId || undefined,
    })
  );
};

export const resetAndClosePresentationEditor = () => {
  dispatchAction(
    showPresentationEditor({
      presentationId: undefined,
      editingSlideId: undefined,
    })
  );
  dispatchAction(togglePresentationEditor({ shouldShow: false }));
};

/** When opening a presentation in the editor, determine which slide should be selected, if any */
const getSelectedSlideWhenOpeningPresentation = (
  presentation: APIPresentationAssetAttributes,
  editingSlideId: ArdoqId | null
): APISlideAttributes | null => {
  const hasSlides = presentationOperations.hasSlides(presentation);
  if (!hasSlides) return null;

  const authorizedSlides = presentation.authorizedSlides ?? [];
  const selectedSlideId = editingSlideId
    ? authorizedSlides.find((slideId: ArdoqId) => slideId === editingSlideId)
    : authorizedSlides[0] || null;
  return slides$.state.byId[selectedSlideId!];
};

type OpenPresentationEditorParams = {
  presentationId: ArdoqId;
  editingSlideId?: ArdoqId | null;
  isOpeningPresentationEditorToAddSlideFromCurrentView?: boolean;
};

export const openPresentationEditor = async ({
  presentationId,
  editingSlideId = null,
  isOpeningPresentationEditorToAddSlideFromCurrentView = false,
}: OpenPresentationEditorParams) => {
  const presentation = presentationOperations.getPresentationById(
    presentations$.state,
    presentationId
  );
  if (!presentation) {
    return;
  }
  contextInterface.setPresentation(presentation);
  const selectedSlide = getSelectedSlideWhenOpeningPresentation(
    presentation,
    editingSlideId
  );
  let selectedSlideType;
  if (selectedSlide) {
    if (isReportSlide(selectedSlide)) {
      selectedSlideType = SlideTypes.REPORT;
    } else if (isDashboardSlide(selectedSlide)) {
      selectedSlideType = SlideTypes.DASHBOARD;
    } else if (isMetamodelSlide(selectedSlide)) {
      selectedSlideType = SlideTypes.METAMODEL;
    } else if (isLucidSlide(selectedSlide)) {
      selectedSlideType = SlideTypes.LUCID;
    } else {
      selectedSlideType = SlideTypes.VISUALIZATION;
    }
  }

  const hasActiveWorkspaces = contextInterface.getLoadedWorkspaceIds().length;
  const loadingSlideWillChangeActiveWorkspaces =
    hasActiveWorkspaces && selectedSlideType === SlideTypes.VISUALIZATION;

  const shouldLoadSelectedSlide =
    selectedSlide &&
    !isOpeningPresentationEditorToAddSlideFromCurrentView &&
    !loadingSlideWillChangeActiveWorkspaces;

  const shouldShowPresentationEditor =
    shouldLoadSelectedSlide ||
    hasActiveWorkspaces ||
    isOpeningPresentationEditorToAddSlideFromCurrentView;

  if (shouldLoadSelectedSlide) {
    const isCurrentlyInViewpointMode = loadedGraph$.state.isViewpointMode;
    const { error } = await loadSlide(
      selectedSlide,
      isCurrentlyInViewpointMode,
      presentationId
    );
    if (error) {
      return;
    }
  }
  if (shouldShowPresentationEditor) {
    showPresentationEditorSidebar(
      presentation._id,
      editingSlideId,
      selectedSlideType,
      isOpeningPresentationEditorToAddSlideFromCurrentView
    );
  } else {
    // If there are no workspaces open and no slides, show workspace browser
    selectStartingPoint({ forPresentationId: presentation._id });
  }
};

export const openCopyPresentationModal = async (
  presentation: APIPresentationAssetAttributes
) => {
  copyModal({
    entityName: presentation.name,
    copyEntity: async newCopyName => {
      const response = await duplicatePresentation({
        presentationId: presentation._id,
        newPresentationName: newCopyName,
      });
      if (isArdoqError(response)) {
        return response;
      }
      return {
        name: response.presentation.name,
        _id: response.presentation._id,
      };
    },
    entityType: AssetType.PRESENTATION,
    openCopy: newId => openPresentationEditor({ presentationId: newId }),
  });
};

export const openDeletePresentationMenu = (
  presentation: APIPresentationAssetAttributes,
  clearSelected?: VoidFunction
) => {
  return handleDeleteAsset({
    assetType: AssetType.PRESENTATION,
    onDelete: () =>
      confirmDeleteAsset({
        deleteType: DeleteType.PRESENTATION,
        name: presentation.name,
      }),
    onDeleteConfirmed: () =>
      dispatchAction(deleteRequested(presentationsNamespace, presentation._id)),
    clearSelected,
  });
};

type DuplicatePresentationParams = {
  presentationId: ArdoqId;
  newPresentationName: string;
};
type DuplicatePresentationReturn = Promise<
  | {
      presentation: APIPresentationAssetAttributes;
    }
  | ArdoqError
>;

/** Duplicate presentation with new name, and add duplicated slides to collections.
 * Returns a promise containing new presentation and slides */
export const duplicatePresentation = async ({
  presentationId,
  newPresentationName,
}: DuplicatePresentationParams): DuplicatePresentationReturn => {
  const newPresentation = await presentationApi.copy(
    presentationId,
    newPresentationName
  );
  if (isArdoqError(newPresentation)) {
    return newPresentation;
  }
  const newSlides: APISlideAttributes[] | ArdoqError =
    await presentationApi.fetchSlides(newPresentation._id);

  if (isArdoqError(newSlides)) {
    return newSlides;
  }
  dispatchAction(loadSlides(newSlides));
  return { presentation: newPresentation };
};

const getPossibleConnectedWorkspaceIdsForSlide = (
  slide: VisualizationSlide
): ArdoqId[] =>
  Array.from(getConnectedWorkspaceIds(slide.workspaceIds || [])).filter(
    workspaceInterface.workspaceExists
  );

const workspaceIdsWithPermissions = (workspaceIds: ArdoqId[]): ArdoqId[] =>
  workspaceIds.filter(workspaceInterface.workspaceExists);

const findFiltersReferencingOtherWorkspaces = (
  filter: FilterAttributes
): (ArdoqId | null)[] => {
  if (filter?.rules) {
    return filter.rules.flatMap(rule =>
      findFiltersReferencingOtherWorkspaces(rule)
    );
  }

  if (
    SpecialFilterKeys.COMPONENT_HAS_REFERENCE_TO === filter.name ||
    SpecialFilterKeys.COMPONENT_HAS_REFERENCE_FROM === filter.name
  ) {
    return [componentInterface.getWorkspaceId(filter.value as string)];
  }

  return [];
};

const getReferencedWorkspaceIds = (filters: FilterAttributes[]) => {
  return findFiltersReferencingOtherWorkspaces({ rules: filters }).filter(
    filter => filter !== null
  ) as ArdoqId[];
};

const resolveWsNames = (wsIds: ArdoqId[]) =>
  wsIds.map(id => workspaceInterface.getWorkspaceName(id)).filter(ExcludeFalsy);

export const getSlideWorkspacesMetadata = (
  slide: VisualizationSlide
): SlideWorkspacesMetadata => {
  const includedWorkspaceIds: ArdoqId[] = slide.filters.workspaceFilter
    ? getWorkspaceIdsInWorkspaceFilter(slide.filters.workspaceFilter)
    : [];

  const referencedWorkspaceIds = getReferencedWorkspaceIds(
    slide.filters.advancedFilters
  );

  const missingReferencedWorkspaceIds = difference(
    referencedWorkspaceIds,
    includedWorkspaceIds
  );

  const consideredWorkspaceIds: ArdoqId[] = slide.consideredWorkspaceIds || [];
  const possibleConnectedWorkspaceIds: ArdoqId[] =
    getPossibleConnectedWorkspaceIdsForSlide(slide);

  // Workspaces that are connected but are not in considered workspace IDs array
  const newWorkspacesToConsider = difference(
    possibleConnectedWorkspaceIds,
    consideredWorkspaceIds
  );

  // Excluded workspaces are those that are not included & also have been considered already
  const excludedWorkspaceIds = difference(
    consideredWorkspaceIds,
    includedWorkspaceIds
  );

  return {
    includedWorkspaceNames: resolveWsNames(
      workspaceIdsWithPermissions(includedWorkspaceIds)
    ),
    excludedWorkspaceNames: resolveWsNames(
      workspaceIdsWithPermissions(excludedWorkspaceIds)
    ),
    newWorkspacesToConsiderNames: resolveWsNames(
      workspaceIdsWithPermissions(newWorkspacesToConsider)
    ),
    missingReferencedWorkspaceNames: resolveWsNames(
      missingReferencedWorkspaceIds
    ),
  };
};
