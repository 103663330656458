import { MarkdownViewer } from '@ardoq/markdown';
import styled from 'styled-components';
import { header2, Link } from '@ardoq/typography';
import { colors, s16 } from '@ardoq/design-tokens';
import { DASHBOARD_MAX_WIDTH } from '@ardoq/dashboard';
import { AnimatedChevron, ICON_SELECTOR, TrophyIcon } from '@ardoq/icons';
import { trackOpenedUseCasePDFLink } from '../tracking';
import { useState } from 'react';
import { ButtonSize, GhostButton } from '@ardoq/button';
import { Island } from '@ardoq/page-layout';
import { FlexBox, Stack } from '@ardoq/layout';
import { Badge, StatusType } from '@ardoq/status-ui';
import { Maybe } from '@ardoq/common-helpers';
import { WithPopover } from '@ardoq/popovers';

const DescriptionAndBusinessOutcomesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${s16};
`;

export const DescriptionTitle = styled.div`
  ${header2};
  display: flex;
`;

const BadgeContainer = styled.div`
  align-self: start;
`;

const ExpandButton = styled(GhostButton)`
  color: ${colors.blue50};
  margin: 0;
  padding: 0;
  font-size: ${s16};

  &:hover {
    background-color: transparent;
  }
`;

const BusinessOutcomes = styled(Stack)`
  background: ${colors.grey95};
  padding: ${s16};
  border-radius: 8px;

  ul li::marker,
  ${ICON_SELECTOR} {
    color: ${colors.brand50};
  }

  ul {
    padding-left: ${s16};
  }
`;

const DescriptionPreview = styled(MarkdownViewer)`
  flex: 1;

  :first-child {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export type DescriptionAndBusinessOutcomesProps = {
  title: string;
  description: Maybe<string>;
  businessOutcomes?: string;
  pdfLinks?: Array<{ label: string; link: string }>;
  expandable?: boolean;
  iteration?: string;
};
const ExpandableDescriptionAndBusinessOutcomes = ({
  description,
  businessOutcomes,
  title,
  pdfLinks,
  expandable,
  iteration,
}: DescriptionAndBusinessOutcomesProps) => {
  const [isExpanded, setIsExpanded] = useState(!expandable);
  return (
    <Island maxWidth={`${DASHBOARD_MAX_WIDTH}px`} bodySpacing={'medium'}>
      <DescriptionAndBusinessOutcomesContainer>
        <Stack gap="small" flex={1}>
          <DescriptionTitle>{title}</DescriptionTitle>
          {iteration && (
            <WithPopover
              content={
                'Versions may differ in content such as assets, component fields, references, and reference fields.'
              }
            >
              <BadgeContainer>
                <Badge
                  statusType={StatusType.DEFAULT}
                  label={`Version ${iteration}`}
                />
              </BadgeContainer>
            </WithPopover>
          )}
          {pdfLinks?.map(({ label, link }) => (
            <Link
              key={label}
              href={link}
              onClick={() => {
                trackOpenedUseCasePDFLink(label);
                window.open(link, '_blank');
              }}
            >
              {label}
            </Link>
          ))}
          {description && !isExpanded && (
            <DescriptionPreview
              content={description}
              sanitationLevel={'permissive'}
            />
          )}
          {description && isExpanded && (
            <FlexBox flex={1}>
              <MarkdownViewer
                content={description}
                sanitationLevel="permissive"
              />
            </FlexBox>
          )}
          {expandable && (
            <ExpandButton
              buttonSize={ButtonSize.SMALL}
              onClick={() => setIsExpanded(prev => !prev)}
            >
              {isExpanded ? 'Show less' : 'Show more'}
              <AnimatedChevron color={colors.blue50} $isUp={isExpanded} />
            </ExpandButton>
          )}
        </Stack>
        {businessOutcomes && (
          <BusinessOutcomes flex={1}>
            <FlexBox gap="small">
              <TrophyIcon />
              <DescriptionTitle>
                Uncover answers to business questions
              </DescriptionTitle>
            </FlexBox>
            {!isExpanded && (
              <MarkdownViewer
                content={businessOutcomes.split('\n').slice(0, 3).join('\n')}
                sanitationLevel="permissive"
              />
            )}
            {isExpanded && (
              <MarkdownViewer
                content={businessOutcomes}
                sanitationLevel="permissive"
              />
            )}
          </BusinessOutcomes>
        )}
      </DescriptionAndBusinessOutcomesContainer>
    </Island>
  );
};

export default ExpandableDescriptionAndBusinessOutcomes;
