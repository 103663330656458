import styled from 'styled-components';
import { Space } from '@ardoq/style-helpers';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { header4, Link, text2 } from '@ardoq/typography';
import { StatusType, Tag } from '@ardoq/status-ui';
import {
  Checkbox,
  GroupFieldLayout,
  RadioGroup,
  RadioGroupItem,
  RadioGroupValue,
} from '@ardoq/forms';
import { WithPopover } from '@ardoq/popovers';
import { InfoIcon } from '@ardoq/icons';
import { colors, s32 } from '@ardoq/design-tokens';
import { DataSyncStrategy } from '@ardoq/api-types/integrations';
import { LabelWithPopoverIcon } from '../LabelWithPopoverIcon/LabelWithPopoverIcon';
import { CollapsibleIsland } from '@ardoq/page-layout';

const Header4 = styled.div`
  ${header4};
`;

const ChildrenWrapper = styled.span`
  width: fit-content;
`;

const DeleteSettingsCheckbox = styled(Checkbox)<{
  $isDisabled: boolean;
  $isContainerDisabled: boolean;
}>`
  opacity: ${({ $isDisabled, $isContainerDisabled }) =>
    $isContainerDisabled ? 1 : $isDisabled ? 0.32 : 1};
  cursor: ${({ $isDisabled, $isContainerDisabled }) =>
    $isContainerDisabled || $isDisabled ? 'initial' : 'pointer'};
`;

const DataSyncOption = styled(RadioGroupItem)<{
  $isContainerDisabled: boolean;
}>`
  cursor: ${({ $isContainerDisabled }) =>
    $isContainerDisabled ? 'initial' : 'pointer'};
`;

const DataSyncStrategyContainer = styled(Space).attrs({
  $isVertical: true,
  $gap: 's32',
})<{ $isDisabled: boolean }>`
  margin: 0 ${s32} ${s32} ${s32};
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.32 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'initial')};
`;

const AdditionalText = styled.span`
  ${text2};
  color: ${colors.grey35};
`;

const GreyedOut = styled.span`
  color: ${colors.grey50};
`;

const ItalicText = styled.div`
  ${text2};
  font-style: italic;
`;

type DataSyncStrategyOption = {
  label: string;
  isDisabled: boolean;
  value: string;
};

type DataSyncStrategyProps = {
  deleteSettingsEnabled: boolean;
  configurationAllowed: boolean;
  hasRowRepresentation: boolean;
  isHardDeleteDisabled: boolean;
  onDataSyncStrategySelect: (dataSyncStrategy: DataSyncStrategy) => void;
  onToggle: (enabled: boolean) => void;
  syncTags: string[];
  additionalHardDeleteWarningText?: string;
  onDeleteSettingsChecked: (enabled: boolean) => void;
  hardDeletePopoverText?: string;
  syncOption: string;
  dataSyncStrategyOptions: DataSyncStrategyOption[];
};

export const DataSyncStrategyComponent = ({
  deleteSettingsEnabled,
  onDataSyncStrategySelect,
  onToggle,
  syncTags,
  configurationAllowed,
  hardDeletePopoverText,
  hasRowRepresentation,
  isHardDeleteDisabled,
  additionalHardDeleteWarningText,
  syncOption,
  dataSyncStrategyOptions,
  onDeleteSettingsChecked,
}: DataSyncStrategyProps) => {
  return (
    <DataSyncStrategyContainer $isDisabled={!configurationAllowed}>
      <CollapsibleIsland
        maxWidth="100%"
        minWidth="unset"
        isDefaultExpanded={false}
        onToggle={onToggle}
        title={
          <>
            <FlexBox gap="xsmall">
              <LabelWithPopoverIcon
                popoverContent={() => (
                  <span>
                    Read more about{' '}
                    <Link
                      href="https://help.ardoq.com/en/articles/115713-delete-missing-assets-on-import"
                      target="_blank"
                      hideIcon
                      typography="text2"
                    >
                      advanced data sync strategy settings
                    </Link>
                    .
                  </span>
                )}
              >
                <Header4>
                  Data sync strategy <GreyedOut>(optional)</GreyedOut>
                </Header4>
              </LabelWithPopoverIcon>
              {syncTags.map(tag => (
                <Tag statusType={StatusType.INFO} label={tag} key={tag} />
              ))}
            </FlexBox>
            <AdditionalText>
              Configure the data sync strategy, including asset deletion,
              creation, and updating.
            </AdditionalText>
          </>
        }
      >
        <Stack gap="small">
          <Header4>Update existing and create new assets</Header4>
          <div>
            How do you want to handle existing and new assets during the sync?
          </div>
          <RadioGroup
            value={syncOption as RadioGroupValue}
            onValueChange={value =>
              configurationAllowed
                ? onDataSyncStrategySelect(value as DataSyncStrategy)
                : undefined
            }
            groupFieldLayout={GroupFieldLayout.VERTICAL}
          >
            {dataSyncStrategyOptions.map(
              (
                { value: optionValue, label: optionLabel, isDisabled },
                index
              ) => (
                <WithPopover
                  key={index}
                  content={
                    optionValue === DataSyncStrategy.CREATE_ONLY &&
                    deleteSettingsEnabled
                      ? 'When “Delete missing assets” is selected, “Create only” is deactivated.'
                      : undefined
                  }
                >
                  <ChildrenWrapper>
                    <DataSyncOption
                      $isContainerDisabled={!configurationAllowed}
                      value={optionValue as RadioGroupValue}
                      isDisabled={isDisabled}
                    >
                      {optionLabel}
                    </DataSyncOption>
                  </ChildrenWrapper>
                </WithPopover>
              )
            )}
          </RadioGroup>
        </Stack>
        <Stack gap="small">
          <Header4>
            <Space $align="center" $gap="s8">
              Delete missing assets
              <WithPopover content="To ensure accurate data in the selected workspace, it’s recommended to delete components and references that are not included in the imported data.">
                <InfoIcon color={colors.grey50} />
              </WithPopover>
            </Space>
          </Header4>
          <div>
            Do you want to automatically delete assets from the workspace that
            weren&apos;t included in the import?
            {additionalHardDeleteWarningText && (
              <ItalicText>{additionalHardDeleteWarningText}</ItalicText>
            )}
          </div>
          {/* We're not passing the isDisabled prop to the Checkbox component because it will decrease the opacity further from 0.32 due to its parent being already 0.32 */}
          <WithPopover content={hardDeletePopoverText}>
            <ChildrenWrapper>
              <DeleteSettingsCheckbox
                $isContainerDisabled={!configurationAllowed}
                $isDisabled={isHardDeleteDisabled}
                isChecked={deleteSettingsEnabled}
                onChange={
                  hasRowRepresentation &&
                  configurationAllowed &&
                  !isHardDeleteDisabled
                    ? onDeleteSettingsChecked
                    : undefined
                }
              >
                Yes, automatically delete them from the workspace.
              </DeleteSettingsCheckbox>
            </ChildrenWrapper>
          </WithPopover>
        </Stack>
      </CollapsibleIsland>
    </DataSyncStrategyContainer>
  );
};
