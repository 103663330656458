import Flex from 'atomicComponents/Flex';
import {
  editSearchQuery,
  saveSearchQuery,
  updateQueryName,
} from 'search/actions';
import { QueryModel, SearchBackend, SearchType } from '@ardoq/api-types';
import { Container, Error } from './atoms';
import { getQueryEditorNamespace } from './queryEditor$';
import { dispatchAction } from '@ardoq/rxbeach';
import { SecondaryButton } from '@ardoq/button';
import { FieldsRow, FormSize, TextInput } from '@ardoq/forms';

interface ViewProps {
  searchBackend: SearchBackend;
  model: QueryModel;
  isEditing: boolean;
  hasChanges: boolean;
  hasSaveError: boolean;
  selectedQueryId: string | null;
  ruleErrorMessages?: string[];
  placeholder: string;
  onSave?: () => void;
  hasWriteAccess: boolean;
}

const QueryNameEditor = ({
  searchBackend,
  model,
  isEditing,
  hasChanges,
  hasSaveError,
  selectedQueryId,
  placeholder,
  onSave,
  ruleErrorMessages = [],
  hasWriteAccess,
}: ViewProps) => {
  const isNewQuery = !selectedQueryId;
  const { name } = model;
  return (
    <Container>
      <Flex $direction="column">
        {isEditing ? (
          <FieldsRow $formSize={FormSize.DEFAULT}>
            <TextInput
              placeholder={placeholder}
              isDisabled={!hasWriteAccess || !isEditing}
              value={name}
              onValueChange={(name: string) =>
                dispatchAction(
                  updateQueryName({ name }),
                  getQueryEditorNamespace(
                    searchBackend,
                    SearchType.DYNAMIC_FILTER_QUERY
                  )
                )
              }
              autoComplete="off"
            />
            <SecondaryButton
              isDisabled={!hasWriteAccess || !name || !hasChanges}
              onClick={() =>
                onSave
                  ? onSave()
                  : dispatchAction(
                      saveSearchQuery({
                        model,
                        queryId: selectedQueryId,
                      }),
                      getQueryEditorNamespace(
                        searchBackend,
                        SearchType.DYNAMIC_FILTER_QUERY
                      )
                    )
              }
            >
              {hasChanges || isNewQuery ? 'Save' : 'Saved'}
            </SecondaryButton>
          </FieldsRow>
        ) : (
          <FieldsRow>
            <TextInput isReadOnly value={name} />
            <SecondaryButton
              onClick={() =>
                dispatchAction(
                  editSearchQuery(),
                  getQueryEditorNamespace(
                    searchBackend,
                    SearchType.DYNAMIC_FILTER_QUERY
                  )
                )
              }
            >
              Edit
            </SecondaryButton>
          </FieldsRow>
        )}
        {hasSaveError && (
          <Error>
            {ruleErrorMessages.length ? (
              <>
                Please fix the following query errors before saving:
                <ul>
                  {ruleErrorMessages.map((message, index) => (
                    <li key={index}>{message}</li>
                  ))}
                </ul>
              </>
            ) : (
              'Failed to save the search, please try again or contact support.'
            )}
          </Error>
        )}
      </Flex>
    </Container>
  );
};

export default QueryNameEditor;
