import { actionCreator } from '@ardoq/rxbeach';
import { ImportHistory } from '@ardoq/api-types/integrations';

type PayloadInitIntegration = {
  readonly id: string;
  readonly path?: string | null;
};
export const initIntegration = actionCreator<PayloadInitIntegration>(
  '[integrations] INIT_INTEGRATION'
);

export type PayloadOpenIntegration = {
  readonly id: string | null;
  readonly path?: string | null;
  readonly source?: string | null;
};
export const setVisibleIntegration = actionCreator<PayloadOpenIntegration>(
  '[integrations] SET_VISIBLE_INTEGRATION'
);

interface PayloadOpenUrl {
  readonly url: string;
}
export const openNewTab = actionCreator<PayloadOpenUrl>(
  '[integrations] OPEN_NEW_TAB'
);

interface PayloadShowSupport {
  readonly message: string;
}
export const showSupport = actionCreator<PayloadShowSupport>(
  '[integrations] SHOW_SUPPORT'
);

export const showImportErrorModal = actionCreator(
  '[integrations] SHOW_IMPORT_ERROR_MODAL'
);

// History-related actions

export const loadImportsHistory = actionCreator(
  '[integrations] LOAD_IMPORTS_HISTORY'
);

export const loadImportsHistorySuccess = actionCreator<{
  readonly imports: ImportHistory[];
}>('[integrations] LOAD_IMPORTS_HISTORY_SUCCESS');
