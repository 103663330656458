import { confirm } from '@ardoq/modal';

const confirmPauseBroadcast = (broadcastName: string) => {
  const message = (
    <>
      <p>Pause {broadcastName}?</p>
      <p>
        Unsent reminders for previous runs of this broadcast will be canceled.
        Resuming the broadcast will send reminders for future runs only.
      </p>
    </>
  );
  return confirm({
    title: 'Pause Broadcast',
    text: message,
    confirmButtonTitle: 'Pause',
  });
};

export default confirmPauseBroadcast;
