import { FlexBox } from '@ardoq/layout';
import { capitalize } from 'lodash';

type TabTitleWithCountProps = {
  readonly title: string;
  readonly count: number;
};

const TabTitleWithCount = ({ title, count }: TabTitleWithCountProps) => {
  return (
    <FlexBox gap="xsmall">
      {capitalize(title)} ({count})
    </FlexBox>
  );
};

export default TabTitleWithCount;
