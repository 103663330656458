import { createElement } from 'react';
import { HAS_MORE_ID } from 'broadcasts/consts';
import { HasMore } from './atoms';
import { BroadcastComponent } from '@ardoq/api-types';

const componentNameColumn = {
  title: 'Component name',
  dataIndex: 'name',
  valueRender: (value: string, { _id: id }: BroadcastComponent) => {
    if (id === HAS_MORE_ID) {
      return createElement(HasMore, null, value);
    }
    return value;
  },
};

const columnConfig = [componentNameColumn];

export default columnConfig;
