export enum SurveySectionId {
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  DEFINE_THE_DATA = 'DEFINE_THE_DATA',
  QUESTIONS = 'QUESTIONS',
  RESULTS_DISPLAY = 'RESULTS_DISPLAY',
  CHANGE_APPROVAL = 'CHANGE_APPROVAL',
}

export enum SurveyWarningName {
  // Change approval
  CHANGE_APPROVAL_EMPTY_USER_GROUP = 'CHANGE_APPROVAL_EMPTY_USER_GROUP',
  // Result Filtering
  NO_ACCESS_TO_SELECTED_RESULT_FILTERING_WORKSPACE = 'NO_ACCESS_TO_SELECTED_RESULT_FILTERING_WORKSPACE',
  QUESTION_WARNINGS_EXIST = 'QUESTION_WARNINGS_EXIST',
}

export const WARNING_MESSAGES = {
  CHANGE_APPROVAL_EMPTY_USER_GROUP_MESSAGE:
    'The approval audience contains an empty user group. This will effect who receives approval requests.',
  NO_ACCESS_TO_SELECTED_RESULT_FILTERING_WORKSPACE:
    'You do not have "Read" access on the selected result filtering workspace',
  NO_ACCESS_TO_SELECTED_RESULT_FILTERING_COMPONENT_TYPE:
    'You do not have "Read" access on the selected result filtering component type',
  QUESTION_WARNINGS_EXIST: 'There are warnings in the questions section',
};

export enum SurveyErrorName {
  // General
  NAME_INVALID = 'NAME_INVALID',
  CONTACT_EMAIL_INVALID = 'CONTACT_EMAIL_INVALID',
  // Hidden fields
  HIDDEN_FIELDS = 'HIDDEN_FIELDS',
  // Workspace
  WORKSPACE_INVALID = 'WORKSPACE_INVALID',
  COMPONENT_TYPE_INVALID = 'COMPONENT_TYPE_INVALID',
  REQUIRED_NEW_COMPONENT_PARENT_IS_MISSING = 'REQUIRED_NEW_COMPONENT_PARENT_IS_MISSING',
  SELECTED_NEW_COMPONENT_PARENT_DOES_NOT_EXIST = 'SELECTED_NEW_COMPONENT_PARENT_DOES_NOT_EXIST',
  NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE = 'NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE',
  // Result filtering
  SELECTED_RESULT_FILTERING_COMPONENT_TYPE_INVALID = 'SELECTED_RESULT_FILTERING_COMPONENT_TYPE_INVALID',
  MISSING_RESULT_FILTERING_FIELDS = 'MISSING_RESULT_FILTERING_FIELDS',
  // Questions
  QUESTION_ERRORS_EXIST = 'QUESTION_ERRORS_EXIST',
  MISSING_NAME_QUESTION = 'MISSING_NAME_QUESTION',
  // Change approval
  CHANGE_APPROVAL_COMPATIBILITY = 'CHANGE_APPROVAL_COMPATIBILITY',
  CHANGE_APPROVAL_FALLBACK_USER = 'CHANGE_APPROVAL_FALLBACK_USER',
}

export const ERROR_MESSAGES = {
  SURVEY_NAME_ERROR: 'The survey name is invalid',
  CONTACT_EMAIL_ERROR: 'The contact email is invalid',
  NO_WORKSPACE_ERROR:
    'The workspace has not been selected, or no longer exists.',
  NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE:
    'You do not have "Administrator" access on the selected workspace',
  THE_SELECTED_PARENT_FOR_NEW_COMPONENTS_DOES_NOT_EXIST:
    'The parent for new components does not exist',
  COMPONENT_TYPE_MISSING_OR_INVALID:
    'The component type is invalid or does not exist.',
  IDENTIFYING_FIELD_MISSING_OR_INVALID:
    'The identifying field is invalid or no longer exists.',
  NO_PARENT_SELECTED_FOR_NEW_COMPONENTS:
    'No parent was selected for new components',
  CHANGE_APPROVAL_FALLBACK_USER_ERROR_MESSAGE:
    'No backup approver has been assigned',
  MISSING_NAME_QUESTION: 'The survey is missing the name question',
  QUESTION_ERRORS_EXIST: 'There are errors in the questions section',
  MISSING_RESULT_FILTERING_FIELDS:
    'The survey has missing result filtering fields',
  SELECTED_RESULT_FILTERING_COMPONENT_TYPE_INVALID:
    'The selected result filtering component type is invalid',
};
