import { ApiResponse, getErrorStatusCode } from '@ardoq/api';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import { defer, from, interval, map, retry, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const MAX_RETRY_COUNT = 3;
const INITIAL_DELAY_MS = 500;

type ExtraData = Record<string, string | number | boolean>;
export class ApiError extends Error {
  statusCode: number;
  reason: string;
  extraData: ExtraData;
  constructor(
    message: string,
    statusCode: number,
    reason: string,
    extraData: ExtraData = {}
  ) {
    super(message);
    this.statusCode = statusCode;
    this.reason = reason;
    this.extraData = extraData;
  }
}

export const retriableApiRequest = <T>(requestPromise: () => Promise<T>) => {
  return defer(() => from(requestPromise())).pipe(
    retry({
      delay: (error, retryCount) => {
        if (error instanceof ApiError) {
          // client 4xx errors
          if (error.statusCode >= 400 && error.statusCode < 500) {
            return throwError(() => error);
          }

          // retry with exponential back-off
          if (retryCount < MAX_RETRY_COUNT) {
            const delay = INITIAL_DELAY_MS * Math.pow(2, retryCount);
            return interval(delay);
          }
        }
        // unknown API errors are propagated
        return throwError(() => error);
      },
    })
  );
};
// Todo: In future we should use this function instead of the retriableApiRequest function in the all integrations
export const retriableRequest = <T>(
  requestPromise: () => ApiResponse<T>,
  errorMessage?: string
) => {
  return defer(() => from(requestPromise())).pipe(
    map(response => {
      if (isArdoqError(response)) {
        throw response;
      }
      return response;
    }),
    retry({
      delay: (error, retryCount) => {
        if (isArdoqError(error)) {
          if (isClientError(error)) {
            return throwError(() => error);
          }
          // retry with exponential back-off
          if (retryCount < MAX_RETRY_COUNT) {
            const delay = INITIAL_DELAY_MS * Math.pow(2, retryCount);
            return interval(delay);
          }
        }
        // unknown API errors are propagated
        return throwError(() => error);
      },
    }),
    catchError(error => {
      throw new ApiError(
        errorMessage || 'Unable to fetch',
        getErrorStatusCode(error),
        getArdoqErrorMessage(error, 'Unable to fetch')
      );
    })
  );
};

const isClientError = (error: unknown) => {
  const statusCode = getErrorStatusCode(error);

  return statusCode >= 400 && statusCode <= 499;
};

const logApiError = (error: ApiError) => {
  logError(error, error.reason, {
    ...error.extraData,
    tags: {
      team: 'integrations',
    },
    message: error.message,
    statusCode: error.statusCode,
  });
};

export const handleApiError = (error: unknown) => {
  if (error instanceof ApiError) {
    logApiError(error);
  }

  return throwError(() => error);
};
