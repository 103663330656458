import { APIOrganizationUser, ArdoqId, PrivilegeLabel } from '@ardoq/api-types';
import { PrivilegeAction } from 'privileges/types';
import { actionCreator } from '@ardoq/rxbeach';
import { UserSettingsTabs } from './types';

export const openUserSettings = actionCreator<UserSettingsTabs>(
  '[user] OPEN_USER_SETTINGS'
);

export const navigateToUserSettings = actionCreator<UserSettingsTabs>(
  '[user] NAVIGATE_TO_ACCOUNT_SETTINGS'
);

export const bulkUpdateUserRoles = actionCreator<{
  selectedUserIds: ArdoqId[];
  selectedRoleLabel: string;
}>('[user] BULK_UPDATE_USER_ROLES');

export const bulkUpdateUserPrivileges = actionCreator<{
  selectedUsers: (APIOrganizationUser & {
    inheritedPrivileges: PrivilegeLabel[];
  })[];
  selectedPrivileges: PrivilegeLabel[];
  privilegeAction: PrivilegeAction;
}>('[user] BULK_UPDATE_USER_PRIVILEGES');

export const bulkUpdateUserPrivilegesSucceeded = actionCreator(
  '[user] BULK_UPDATE_USER_PRIVILEGES_SUCCEEDED'
);
export const bulkUpdateUserPrivilegesFailed = actionCreator<{
  message?: string;
}>('[user] BULK_UPDATE_USER_PRIVILEGES_FAILED');
export const bulkRemoveUsers = actionCreator<ArdoqId[]>(
  '[user] BULK_REMOVE_USERS'
);
