import { globalFields$ } from './globalFields$';
import { fieldOps } from 'models/utils/fieldOps';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash';

const ardoqIdField = { label: 'Ardoq ID', value: 'componentKey' };
const ardoqOidField = { label: 'Ardoq OID', value: '_id' };

export const fieldOptions$ = globalFields$.pipe(
  map(globalFields => {
    const fields = Object.values(globalFields);

    fields.sort(fieldOps.compareFieldsByLabelAsc);

    const customFields = fields.map(field => ({
      label: field.label,
      value: field.name,
    }));
    return {
      customFields,
      ardoqIdField,
      ardoqOidField,
    };
  }),
  distinctUntilChanged(isEqual)
);
