import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { dispatchAction } from '@ardoq/rxbeach';
import fp from 'lodash/fp';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import { ensureCloudProvider } from 'integrations/cloudProviders/utils';
import { clearTabularMapping } from 'integrations/common/streams/tabularMappings/actions';
import { setTransferConfigId } from 'integrations/common/streams/activeIntegrations/actions';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { applySourceConfig } from 'integrations/cloudProviders/config/actions';
import { changeRegionsSelection } from 'integrations/cloudProviders/streams/regions/actions';
import {
  selectConnection,
  updateSelectedConnectionsIds,
} from 'integrations/common/streams/connections/actions';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { isValidSelectionState } from 'integrations/aws/streams/selectionState/utils';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { selectionState$ } from 'integrations/aws/streams/selectionState/selectionState$';
import { vpcs$ } from 'integrations/aws/streams/vpcs/vpcs$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getIsUserAgreeToOverrideMapping } from 'integrations/common/streams/transferConfigs/utils';
import { loadSelection } from 'integrations/common/streams/selectionState/actions';

export const viewModel$ = integrationId$.pipe(
  ensureCloudProvider(),
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      transferConfigs: getTransferConfigsStream(integrationId).pipe(
        map(({ configs }) => configs)
      ),
      connectionsState: getConnectionsStream(integrationId),
      vpcState: vpcs$,
      resourceTypesState: getResourceTypesStream(integrationId),
      selectedTransferConfigId: getActiveIntegrationStream(integrationId).pipe(
        map(ci => ci.selectedTransferConfigId),
        distinctUntilChanged()
      ),
      regionsState: getRegionsStream(integrationId),
      integrationTermsDictionary:
        getIntegrationTermsDictionaryStream(integrationId),
      selectionState: selectionState$,
      tabularMapping: getTabularMappingStream(integrationId),
    });
  }),
  map(
    ({
      integrationId,
      connectionsState,
      transferConfigs,
      selectedTransferConfigId,
      regionsState,
      vpcState,
      resourceTypesState,
      integrationTermsDictionary: {
        selectConnectionHelperText,
        selectConnectionPlaceHolder,
        selectConfigHelperText,
        selectRegionsLabel,
        selectRegionsHelperText,
      },
      selectionState: { fetchErrorMessage },
      tabularMapping,
    }) => ({
      connections: connectionsState.connections,
      selectedConnectionIds: connectionsState.selectedConnectionIds,
      regions: regionsState.regions,
      selectedRegionIds: regionsState.selectedRegionIds,
      vpcState,
      resourceTypesState,
      onRegionsSelect: (regions: string[]) => {
        dispatchAction(changeRegionsSelection({ integrationId, regions }));
      },
      transferConfigs,
      selectedTransferConfigId,
      selectConnectionHelperText,
      selectConnectionPlaceHolder,
      selectConfigHelperText,
      errorMessage: fetchErrorMessage || connectionsState.statuses.list.message,
      selectRegionsLabel,
      selectRegionsHelperText,
      selectConfigPopoverContent: null,
      selectConnectionPopoverContent: null,
      onConnectionsSelect: (connectionIds: string[]) => {
        connectionIds.forEach(connectionId =>
          dispatchAction(
            selectConnection({
              integrationId,
              selectedConnectionId: connectionId,
            })
          )
        );

        dispatchAction(
          updateSelectedConnectionsIds({
            selectedConnectionIds: connectionIds,
            integrationId,
          })
        );
      },
      isValidSelection: isValidSelectionState({
        connectionsState,
        regionsState,
        resourceTypesState,
      }),
      onTransferConfigSelect: async (
        configId: SavedTransferConfig['_id'] | null
      ) => {
        if (selectedTransferConfigId === configId) {
          return;
        }

        const transferConfig = transferConfigs.find(m => m._id === configId);

        if (!transferConfig) {
          return;
        }

        const isUserAgreeToOverrideMapping =
          await getIsUserAgreeToOverrideMapping(tabularMapping);

        if (!isUserAgreeToOverrideMapping) {
          return;
        }

        dispatchAction(clearTabularMapping({ integrationId }));
        dispatchAction(setTransferConfigId({ integrationId, id: configId }));

        if (!transferConfig.sourceConfig) {
          return;
        }

        dispatchAction(
          applySourceConfig({
            ...fp.update(
              'accountIds',
              cid =>
                connectionsState.selectedConnectionIds.length > 0
                  ? connectionsState.selectedConnectionIds
                  : cid,
              transferConfig.sourceConfig
            ),
            integrationId,
          })
        );
      },
      onClearConfiguration: () => {
        dispatchAction(clearTabularMapping({ integrationId }));
        dispatchAction(setTransferConfigId({ integrationId, id: null }));
      },
      onFetchData: () => {
        dispatchAction(loadSelection(integrationId));
      },
    })
  )
);
