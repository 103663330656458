import styled from 'styled-components';
import { OrgAccessLevel, Privilege, PrivilegeLabel } from '@ardoq/api-types';
import { Checkbox, CheckboxGroup, FieldsWrapper, FormSize } from '@ardoq/forms';
import { s8 } from '@ardoq/design-tokens';
import { RadioItemLabel } from 'roles/atoms';
import { capitalize } from '@ardoq/common-helpers';

const PrivilegeEditorContainer = styled.div`
  margin: ${s8} 0;
`;

type PrivilegeEditorOwnProps = {
  label?: string;
  configurablePrivileges: Privilege[];
  enabledPrivileges: PrivilegeLabel[];
  inheritedPrivileges?: PrivilegeLabel[];
  role?: OrgAccessLevel;
  onChange: (enabledPrivileges: PrivilegeLabel[]) => void;
};

const PrivilegeEditor = ({
  role,
  label = `Privileges for the ${capitalize(role ?? '')} role`,
  configurablePrivileges,
  enabledPrivileges,
  inheritedPrivileges = [],
  onChange,
}: PrivilegeEditorOwnProps) => {
  return (
    <FieldsWrapper>
      <PrivilegeEditorContainer>
        <CheckboxGroup formSize={FormSize.DEFAULT} label={label}>
          {configurablePrivileges.map(
            ({
              label,
              description,
              'restricted-roles': restrictedRoles,
              warning,
            }) => {
              const isInheritedPrivilege = inheritedPrivileges.includes(label);
              const disabledByRole =
                role && restrictedRoles && restrictedRoles?.includes(role);

              const toolTipText = isInheritedPrivilege
                ? 'Inherited from role'
                : null;
              return (
                !disabledByRole && (
                  <div key={label} data-tooltip-text={toolTipText}>
                    <Checkbox
                      isChecked={
                        enabledPrivileges.includes(label) ||
                        isInheritedPrivilege
                      }
                      warningMessage={warning}
                      isDisabled={isInheritedPrivilege}
                      onChange={() => {
                        onChange(
                          enabledPrivileges.includes(label)
                            ? enabledPrivileges.filter(
                                privilege => privilege !== label
                              )
                            : [...enabledPrivileges, label]
                        );
                      }}
                    >
                      <RadioItemLabel label={description} />
                    </Checkbox>
                  </div>
                )
              );
            }
          )}
        </CheckboxGroup>
      </PrivilegeEditorContainer>
    </FieldsWrapper>
  );
};

export default PrivilegeEditor;
