import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextInput } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import { GroupBy, ViewpointsStreamShape } from 'viewpoints/types';
import { default as GroupByConfig } from 'models/groupBy';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { connect } from '@ardoq/rxbeach';
import viewpoints$ from '../../viewpoints$';
import {
  ArdoqId,
  GroupType,
  GroupingRule,
  ReferenceDirection,
  Workspace,
} from '@ardoq/api-types';
import { dateRangeOperations } from '@ardoq/date-range';
import { TableData, TableDeleteButton } from '../Table';
import { localeIncludesLowercase } from '@ardoq/locale';

const directionOptions = [
  { label: 'Incoming', value: 'incoming' },
  { label: 'Outgoing', value: 'outgoing' },
];

const TableRow = styled.tr`
  vertical-align: top;
`;

const TypeData = styled.td`
  width: 40%;
`;

const LabelData = styled.td`
  width: 25%;
`;

const defaulRowConfig = {
  target: false,
  direction: false,
  label: false,
};

const getTargetOptions = (
  group: GroupBy,
  workspaceIds: string[],
  workspaces: Workspace[] | null
) => {
  switch (group.type) {
    case GroupByConfig.types.REFERENCE: {
      return workspaceIds.flatMap(workspaceId => {
        const workspaceName = workspaces?.find(
          ({ _id }) => _id === workspaceId
        )?.name;
        return Object.values(
          workspaceInterface.getReferenceTypes(workspaceId) ?? {}
        ).map(referenceType => ({
          label: `${workspaceName}: ${referenceType.name}`,
          value: `${referenceType.id}-${workspaceId}`,
        }));
      });
    }
    case GroupByConfig.types.PARENT: {
      return workspaceIds.flatMap(workspaceId =>
        Object.values(
          workspaceInterface.getComponentTypes(workspaceId) ?? {}
        ).map(type => ({
          label: type.name,
          value: `${type.id}-${workspaceId}`,
        }))
      );
    }
    case GroupByConfig.types.FIELD: {
      return workspaceIds.flatMap(workspaceId => {
        const workspaceName = workspaces?.find(
          ({ _id }) => _id === workspaceId
        )?.name;
        return dateRangeOperations
          .mergeDateTimeFieldsToDateRangeFields(
            fieldInterface
              .getAllFieldsOfWorkspace(workspaceId)
              .filter(
                field => Boolean(field.componentType.length) || field.global
              )
          )
          .fields.flatMap(dateRangeOperations.getFieldIdAndLabel)
          .map(({ id, label }) => ({
            value: `${id}-${workspaceId}`,
            label: `${workspaceName}: ${label}`,
          }));
      });
    }
    default:
      return [];
  }
};

export const groupTypeLabelMap = new Map([
  [GroupType.WORKSPACE, 'By Workspace'],
  [GroupType.REFERENCE, 'By Reference Type'],
  [GroupType.COMPONENT, 'By Component Type'],
  [GroupType.PARENT, 'By Parent'],
  [GroupType.PARENT_ALL, 'By Parent All'],
  [GroupType.FIELD, 'By Field'],
  // TODO - RAFAA: This needs to be discussed with David.
  // [GroupType.SUBDIVISION, SUBDIVISIONS_STRINGS.GROUPBY_SUBDIVISION],
]);

type GroupRowProps = {
  group: GroupBy;
  updateGrouping: (updatedGroup: GroupBy) => void;
  removeGroup: () => void;
  workspaceIds: ArdoqId[];
};

const GroupRow = ({
  group,
  updateGrouping,
  removeGroup,
  workspaceIds,
  workspaces,
  locale,
}: GroupRowProps & ViewpointsStreamShape) => {
  const handleUpdateGrouping = (groupProperty: Partial<GroupingRule>) => {
    const updatedGroup = {
      ...group,
      ...groupProperty,
    };

    updateGrouping(updatedGroup);
  };

  const updateRows = useCallback((type?: GroupType) => {
    switch (type) {
      case GroupByConfig.types.REFERENCE: {
        return {
          target: true,
          direction: true,
          label: true,
        };
      }
      case GroupByConfig.types.PARENT: {
        return {
          ...defaulRowConfig,
          target: true,
          label: true,
        };
      }
      case GroupByConfig.types.PARENT_ALL:
        return {
          ...defaulRowConfig,
          label: true,
        };
      case GroupByConfig.types.FIELD: {
        return {
          ...defaulRowConfig,
          target: true,
        };
      }
      default:
        return defaulRowConfig;
    }
  }, []);

  const [rowConfig, setRowConfig] = useState(updateRows(group.type));

  useEffect(() => {
    setRowConfig(updateRows(group.type));
  }, [group.type, updateRows]);

  return (
    <TableRow data-test-id="group-row">
      <TableData>
        {groupTypeLabelMap.get(group.type)?.replace('By ', '')}
      </TableData>
      <TypeData>
        {rowConfig.target && (
          <Select
            onValueChange={optionValue => {
              if (!optionValue) return;
              const [value, workspaceId] = (optionValue as string).split('-');
              handleUpdateGrouping({
                workspaceId: workspaceId,
                targetId: value,
              });
            }}
            filterOption={({ label }, query) =>
              localeIncludesLowercase(label, query, locale)
            }
            noOptionsMessage={() =>
              workspaceIds.length
                ? 'No available options'
                : 'Make sure a workspace is selected'
            }
            placeholder="Type"
            isSearchable
            options={getTargetOptions(group, workspaceIds, workspaces)}
            value={`${group.targetId}-${group.workspaceId}`}
            dataTestId="type-select"
            warningMessage={group.targetId ? undefined : 'No type selected'}
          />
        )}
      </TypeData>
      <TableData>
        {rowConfig.direction && (
          <Select
            onValueChange={direction => {
              if (!direction) return;
              handleUpdateGrouping({
                direction: direction as ReferenceDirection,
              });
            }}
            value={group.direction}
            options={directionOptions}
            placeholder="Direction"
            dataTestId="direction-select"
          />
        )}
      </TableData>
      <LabelData>
        {rowConfig.label && (
          <TextInput
            onValueChange={sublabel => {
              handleUpdateGrouping({ sublabel });
            }}
            placeholder="Add a label"
            value={group.sublabel}
            dataTestId="label"
          />
        )}
      </LabelData>
      <TableDeleteButton
        tooltipText="Delete group"
        handleDelete={removeGroup}
        dataTestId="delete-group"
      />
    </TableRow>
  );
};

export default connect(GroupRow, viewpoints$);
