import { reducedStream, reducer } from '@ardoq/rxbeach';
import { AssetsUsageState } from 'assets/types';
import {
  setAssetUsage,
  SetAssetUsage,
  setAssetUsageStatus,
  SetAssetUsageStatus,
} from './actions';

const defaultState: AssetsUsageState = {};

const setAssetUsageReducer = (
  currentState: AssetsUsageState,
  { id, usage }: SetAssetUsage
): AssetsUsageState => ({
  ...currentState,
  [id]: {
    status: 'success',
    usage,
  },
});

const setAssetUsageStatusReducer = (
  currentState: AssetsUsageState,
  { id, status }: SetAssetUsageStatus
): AssetsUsageState => ({
  ...currentState,
  [id]: {
    ...currentState[id],
    status,
  },
});

const reducers = [
  reducer(setAssetUsage, setAssetUsageReducer),
  reducer(setAssetUsageStatus, setAssetUsageStatusReducer),
];

export const assetsUsage$ = reducedStream<AssetsUsageState>(
  'assetsUsage$',
  defaultState,
  reducers
);
