import { map } from 'rxjs/operators';
import validation$, { ValidationStreamShape } from '../validation$';

export type BroadcastContentFormStreamShape = {
  validation: ValidationStreamShape;
};

const mapState = (validation: ValidationStreamShape) => ({ validation });

const broadcastContentForm$ = validation$.pipe(map(mapState));

export default broadcastContentForm$;
