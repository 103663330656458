import {
  BestPracticeAssistantState,
  bestPracticeAssistant$,
} from './bestPracticeAssistant$';
import { connect } from '@ardoq/rxbeach';
import {
  ChatMessagesBody,
  ChatFooter,
  ChatWindow,
  SendMessageError,
  StartChatError,
  StartChatLoading,
  MinimizedChat,
} from './components';
import { ChatTitle } from './components/ChatTitle';
import bestPracticeAssistantCommands from './commands';
import { BestPracticeAssistantCommands, Feedback } from './types';
import { map } from 'rxjs';
import { ButtonSize, IconButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import { Link } from '@ardoq/typography';
import { FixedInfoBanner } from './components/FixedInfoBanner';
import { ChatMessageItemProps } from './components/ChatMessageItem';
import { FeedbackButtonsProps } from './components/FeedbackButtons';
import { bestPracticeAssistantOperations } from './bestPracticeAssistantOperations';
import { v4 as uuidv4 } from 'uuid';
import ChatTimedOutErrorNotification from './components/ChatTimedOutErrorNotification';
import { currentTimestamp } from '@ardoq/date-time';

type BestPracticeAssistantManagerProps = BestPracticeAssistantState & {
  commands: BestPracticeAssistantCommands;
  messages: ChatMessageItemProps[];
};

type ChatWindowContentProps = Pick<
  BestPracticeAssistantManagerProps,
  | 'startChatIsLoading'
  | 'startChatError'
  | 'sendMessageIsLoading'
  | 'commands'
  | 'sendMessageError'
  | 'input'
  | 'threadId'
  | 'showBanner'
  | 'messages'
  | 'hasChatTimedOut'
>;

const ChatWindowBody = ({
  startChatIsLoading,
  startChatError,
  sendMessageError,
  messages,
  sendMessageIsLoading,
  commands,
  input,
  threadId,
  showBanner,
  hasChatTimedOut,
}: ChatWindowContentProps) => {
  if (startChatIsLoading) return <StartChatLoading />;
  if (startChatError) return <StartChatError error={startChatError} />;

  if (!threadId)
    return <StartChatError error="Failed to start chat, please try again." />;
  if (sendMessageError) return <SendMessageError error={sendMessageError} />;

  return (
    <>
      {hasChatTimedOut && <ChatTimedOutErrorNotification />}
      {showBanner && (
        <FixedInfoBanner>
          <span>
            I want to talk to a{' '}
            <Link hideIcon typography="text2" onClick={commands.passToIntercom}>
              person
            </Link>
          </span>
          <IconButton
            iconName={IconName.CLOSE}
            buttonSize={ButtonSize.SMALL}
            data-tooltip-text="Dismiss"
            onClick={() => commands.dismissBanner()}
          />
        </FixedInfoBanner>
      )}
      <ChatMessagesBody messages={messages} onScroll={commands.setScrollTop} />
      <ChatFooter
        input={input}
        sendMessageIsLoading={sendMessageIsLoading}
        onInputChange={commands.setInput}
        sendMessage={() => {
          const inputIsValid =
            bestPracticeAssistantOperations.validateInput(input);
          if (!inputIsValid || sendMessageIsLoading) return;

          commands.send({
            content: input,
            threadId,
            timestamp: currentTimestamp(),
            clientRequestId: uuidv4(),
          });
        }}
      />
    </>
  );
};

const BestPracticeAssistantManager = ({
  mode,
  commands,
  ...chatWindowBodyProps
}: BestPracticeAssistantManagerProps) => {
  if (mode === 'closed') return <></>;
  if (mode === 'minimized')
    return <MinimizedChat close={commands.close} open={commands.open} />;

  return (
    <ChatWindow>
      <ChatTitle minimize={commands.minimize} close={commands.close} />
      <ChatWindowBody commands={commands} {...chatWindowBodyProps} />
    </ChatWindow>
  );
};

const isLastMessageAndLoading = ({
  index,
  totalMessages,
  sendMessageIsLoading,
}: {
  index: number;
  totalMessages: number;
  sendMessageIsLoading: boolean;
}) => index === totalMessages - 1 && sendMessageIsLoading;

const maybeShowFeedbackButtons = ({
  content,
  role,
  submitPositive,
  submitNegative,
  sendMessageIsLoading,
  submitted,
}: {
  content?: string;
  role: string;
  submitPositive: () => void;
  submitNegative: () => void;
  sendMessageIsLoading: boolean;
  submitted: Feedback;
}): FeedbackButtonsProps | undefined => {
  if (!content || role !== 'assistant') return undefined;
  if (
    isLastMessageAndLoading({
      index: 0,
      totalMessages: 1,
      sendMessageIsLoading,
    })
  ) {
    return undefined;
  }
  return {
    submitted,
    submitPositive,
    submitNegative,
  };
};

export default connect(
  BestPracticeAssistantManager,
  bestPracticeAssistant$.pipe(
    map(
      ({
        showBanner,
        messages,
        sendMessageIsLoading,
        submittedFeedbacksByMessageIndex,
        ...bestPracticeAssistant
      }) => {
        return {
          ...bestPracticeAssistant,
          sendMessageIsLoading,
          submittedFeedbacksByMessageIndex,
          messages: messages.map((message, index) => ({
            ...message,
            isLastMessageAndLoading: isLastMessageAndLoading({
              index,
              totalMessages: messages.length,
              sendMessageIsLoading,
            }),
            feedback: maybeShowFeedbackButtons({
              content: message.content,
              role: message.role,
              sendMessageIsLoading,
              submitted: submittedFeedbacksByMessageIndex[index],
              submitPositive: () =>
                bestPracticeAssistantCommands.submitPositiveFeedback(index),
              submitNegative: () =>
                bestPracticeAssistantCommands.submitNegativeFeedback(index),
            }),
          })),
          showBanner:
            showBanner &&
            messages.filter(({ role }) => role === 'user').length >= 3,
          commands: bestPracticeAssistantCommands,
        };
      }
    )
  )
);
