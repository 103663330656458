import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { tabularMappingIntoTransferConfig } from 'integrations/common/streams/tabularMappings/utils';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getCurrentTransferConfig } from 'integrations/common/streams/transferConfigs/utils';
import { combineLatest, map, of, switchMap } from 'rxjs';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import {
  ScheduleEditor as ScheduleEditorComponent,
  ScheduleEditorProps,
} from 'integrations/common/components/scheduleEditor/ScheduleEditor';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import {
  ScheduleFormValues,
  getScheduleStream,
} from 'integrations/common/streams/schedule/schedule$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  setScheduleFormValues,
  upsertSchedule,
} from 'integrations/common/streams/schedule/actions';

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest({
      integrationId: of(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
      transferConfigs: getTransferConfigsStream(integrationId),
      activeIntegration: getActiveIntegrationStream(integrationId),
      scheduleState: getScheduleStream(integrationId),
      schedules: getSchedulesStream(integrationId),
      transferState: getTransferStateStream(integrationId),
      connectionsState: getConnectionsStream(integrationId),
    })
  ),
  map(
    ({
      integrationId,
      tabularMapping,
      transferConfigs: { configs },
      activeIntegration,
      schedules,
      scheduleState,
      transferState,
      connectionsState,
    }) => {
      const currentTransferConfig = getCurrentTransferConfig(
        configs,
        activeIntegration
      );

      const config = tabularMappingIntoTransferConfig(
        tabularMapping,
        currentTransferConfig
      );

      const connectionName =
        integrationId !== 'microsoft-entra-id'
          ? connectionsState.connections
              .filter(c =>
                connectionsState.selectedConnectionIds.includes(c._id)
              )
              .map(({ name }) => name)
              .join(', ')
          : // TODO: for Microsoft EI we have only one connection
            connectionsState.connections[0]?.name;

      const tables = config.tables.map(t => ({
        name: t.name,
        rootWorkspace: t.rootWorkspace.name,
        targetWorkspace: t.targetWorkspace?.name,
      }));

      const loadedSchedule =
        scheduleState.loadedScheduleId &&
        schedules.schedules.find(s => s._id === scheduleState.loadedScheduleId);

      return {
        connectionName,
        tables,
        scheduleData: scheduleState.scheduleForm,
        setScheduleData: (scheduleFormValues: Partial<ScheduleFormValues>) => {
          dispatchAction(
            setScheduleFormValues({ scheduleFormValues, integrationId })
          );
        },
        onActivate: () => {
          dispatchAction(
            upsertSchedule({
              formData: {
                policy: scheduleState.scheduleForm.policy,
                name: scheduleState.scheduleForm.name.trim(),
                managesWorkspace: scheduleState.scheduleForm.managesWorkspace,
              },
              integrationId: activeIntegration.integrationId,
            })
          );
        },
        isNew: !loadedSchedule,
        isAlreadyExisting: scheduleState.alreadyExistingSchedule,
        transferDirection: transferState.transferDirection,
        transferStatus: transferState.requestStatus,
        visible: scheduleState.scheduleStage === 'toBeScheduled',
      };
    }
  )
);

function ScheduleEditorWrapper(
  props: ScheduleEditorProps & { visible: boolean }
) {
  if (!props.visible) return null;
  return <ScheduleEditorComponent {...props} />;
}

export const ScheduleEditorContainer = connect(
  ScheduleEditorWrapper,
  viewModel$
);
