import { actionCreator } from '@ardoq/rxbeach';
import { WithIntegrationId } from 'integrations/common/utils/actionCreatorWithIntegrationId';
import { FetchSelectionPayload, FetchSelectionSuccessPayload } from './types';
import { IntegrationId } from '../tabularMappings/types';

const NAMESPACE = '[integrations] SELECTION';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const fetchSelectionSuccess =
  actionCreator<FetchSelectionSuccessPayload>(
    getActionName('FETCH_SELECTION_SUCCESS')
  );

export const fetchSelectionError = actionCreator<WithIntegrationId>(
  getActionName('FETCH_SELECTION_ERROR')
);

export const fetchSelection = actionCreator<FetchSelectionPayload>(
  getActionName('FETCH_SELECTION')
);

export const loadSelection = actionCreator<IntegrationId>(
  getActionName('LOAD_SELECTION')
);
