import { dispatchAction } from '@ardoq/rxbeach';
import {
  addDefaultFilter,
  changeFilterCondition,
  updateFilter,
  ChangeFilterConditionPayload,
  UpdateFilterPayload,
  deleteFilter,
  DeleteFilterPayload,
} from './viewpointBuilderFilterActions';
import * as editTraversalActions from '../traversals/editTraversalActions';
import { viewpointBuilderTracking } from '../viewpointBuilderTracking';

export type AddFilterCommands = {
  addDefaultFilter: (graphNodeId: string) => void;
};

export type ChangeConditionCommands = {
  changeFilterCondition: (payload: ChangeFilterConditionPayload) => void;
};

export type EditFilterCommands = {
  updateFilter: (payload: UpdateFilterPayload) => void;
  deleteFilter: (payload: DeleteFilterPayload) => void;
};

export type ToggleIsSelectedGraphNodeCommands = {
  toggleIsSelectedGraphNode: (nodeId: string) => void;
  toggleIsSelectedGraphEdge: (edgeId: string) => void;
};

export type ViewpointBuilderFiltersCommands = AddFilterCommands &
  ChangeConditionCommands &
  EditFilterCommands &
  ToggleIsSelectedGraphNodeCommands;

export const viewpointBuilderFiltersCommands: ViewpointBuilderFiltersCommands =
  {
    addDefaultFilter: (graphNodeId: string) => {
      dispatchAction(addDefaultFilter({ graphNodeId }));
    },

    toggleIsSelectedGraphNode: (graphNodeId: string) => {
      viewpointBuilderTracking.trackComponentTypeNodeSelectedInGraph(
        'FILTERS_TAB'
      );
      dispatchAction(
        editTraversalActions.toggleIsSelectedGraphNode({ graphNodeId })
      );
    },

    toggleIsSelectedGraphEdge: (graphEdgeId: string) =>
      dispatchAction(
        editTraversalActions.toggleIsSelectedGraphEdge({ graphEdgeId })
      ),

    changeFilterCondition: ({ graphNodeId, condition }) => {
      dispatchAction(
        changeFilterCondition({
          graphNodeId,
          condition,
        })
      );
    },

    updateFilter: (payload: UpdateFilterPayload) =>
      dispatchAction(updateFilter(payload)),

    deleteFilter: (payload: DeleteFilterPayload) =>
      dispatchAction(deleteFilter(payload)),
  };
