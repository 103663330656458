import { IconName } from '@ardoq/icons';
import { getCurrentLocale } from '@ardoq/locale';
import { DatasourceTable } from '@ardoq/table';
import { StyledIcon } from 'admin/bundles/BundleOverview/atoms';
import styled from 'styled-components';
import { UseCaseDataSourceItem } from '../types';
import { formatDateOnly } from '@ardoq/date-time';

type UseCaseListProps = {
  dataSource: UseCaseDataSourceItem[];
};

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 60px;
`;

const useCaseTableColumns = [
  { title: 'Name', dataIndex: 'name' },
  { title: 'Version', dataIndex: 'iteration' },
  { title: 'Created by', dataIndex: 'createdByEmail' },
  {
    title: 'Last updated',
    dataIndex: 'lastUpdated',
    valueRender: (value: string) => formatDateOnly(value, getCurrentLocale()),
  },
  {
    title: 'Actions',
    dataIndex: '_id',
    valueRender: (_id: string, dataSourceRow: UseCaseDataSourceItem) => {
      return (
        <ActionButtons>
          <StyledIcon
            tooltipText="Delete"
            onClick={dataSourceRow.handleDelete}
            iconName={IconName.DELETE}
          />
          <StyledIcon
            tooltipText="Edit"
            onClick={dataSourceRow.handleEdit}
            iconName={IconName.EDIT}
          />
          <StyledIcon
            tooltipText="Create new Use Case based on this one"
            iconName={IconName.ADD_CIRCLE}
            onClick={dataSourceRow.handleCreateFromExistingUseCase}
          />
        </ActionButtons>
      );
    },
  },
];

const UseCaseList = ({ dataSource }: UseCaseListProps) => {
  return (
    <DatasourceTable<UseCaseDataSourceItem>
      dataSource={dataSource}
      columns={useCaseTableColumns}
    />
  );
};

export default UseCaseList;
