import {
  EnhancedScopeDataWithBranchName,
  getComponentColor,
  getComponentRepresentationData,
} from '@ardoq/renderers';
import {
  ensureContrast,
  getLightenedColor,
  getShadedColor,
} from '@ardoq/color-helpers';
import { getReferenceTypeRepresentationData } from '../utils';

const createRawGraphNodes = (
  enhancedScopeData: EnhancedScopeDataWithBranchName
) => {
  return new Map(
    enhancedScopeData.components.map(componentData => {
      const { _id: id } = componentData;
      const representationData = getComponentRepresentationData(
        id,
        enhancedScopeData
      );
      const color = getComponentColor(id, enhancedScopeData);
      const shadedColor = getShadedColor(color);
      const lightenedColor = getLightenedColor(color);
      const contrastColor = ensureContrast(lightenedColor, color);
      const typeId = componentData.typeId;
      const label = componentData.name;

      return [
        id,
        {
          modelId: id,
          label,
          metaData: {
            representationData: {
              ...representationData,
              color,
              shadedColor,
              lightenedColor,
              contrastColor,
            },
            color,
            typeId,
            label,
          },
        },
      ];
    })
  );
};

const createRawGraphEdges = (
  enhancedScopeData: EnhancedScopeDataWithBranchName
) =>
  new Map(
    Array.from(enhancedScopeData.graphData.referenceMap.keys()).map(id => {
      const reference = enhancedScopeData.referencesById[id];
      // We are only dealing with the meta model triple workspace, so there can
      // be only a single model.
      const model = enhancedScopeData.models[0];
      const referenceType = model.referenceTypes[reference.type];
      const representationData = getReferenceTypeRepresentationData(
        referenceType,
        reference.displayText ?? ''
      );
      const { sourceId, targetId } =
        enhancedScopeData.graphData.referenceMap.get(id) ?? {};

      return [
        id,
        {
          modelId: id,
          sourceId: sourceId!,
          targetId: targetId!,
          metaData: {
            representationData,
          },
        },
      ];
    })
  );

type ValueOfMapType<T extends Map<string, any>> =
  T extends Map<string, infer R> ? R : any;

/**
 * The raw graph node is a initialization object for an actual graph node.
 * It contains the model id of the node and the meta data of the node,
 * including the representation data.
 */
export type RawGraphNode = ValueOfMapType<
  ReturnType<typeof enhancedScopeDataOperations.createRawGraphNodes>
>;

/**
 * The raw graph edge is a initialization object for an actual graph edge.
 * It contains the model id of the edge, the model id of the source and target
 * node and the meta data of the edge, including the representation data.
 */
export type RawGraphEdge = ValueOfMapType<
  ReturnType<typeof enhancedScopeDataOperations.createRawGraphEdges>
>;

export const enhancedScopeDataOperations = {
  createRawGraphNodes,
  createRawGraphEdges,
};
