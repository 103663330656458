import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { initIntegration } from '../../actions';
import { filter, tap } from 'rxjs';
import { enableTabularMappingsDefault } from 'integrations/common/streams/tabularMappingsDefault/actions';
import { ImportRoute } from 'integrations/common/navigation/types';

const handleInitIntegration = routine(
  ofType(initIntegration),
  extractPayload(),
  filter(
    ({ id, path }) =>
      id === 'microsoft-entra-id' && path === ImportRoute.SELECT_DATA
  ),
  tap(() => {
    dispatchAction(
      enableTabularMappingsDefault({ integrationId: 'microsoft-entra-id' })
    );
  })
);

export default [handleInitIntegration];
