import { ReactNode } from 'react';
import { Table, TableColumnMappingType } from '@ardoq/integrations';
import { TablePreview } from 'integrations/common/streams/tablePreviews/types';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';

import {
  hasComponentTypeColumn,
  hasParentColumn,
  hasReferenceTypeColumn,
  isConfigurableTable,
} from 'integrations/common/streams/tabularMappings/utils';
import { complaintGetter, getTableData } from './utils';
import {
  ComplaintsIndices,
  TableComplaints,
} from 'integrations/common/streams/transferState/types';
import { colors } from '@ardoq/design-tokens';
import { header2 } from '@ardoq/typography';
import styled from 'styled-components';
import { Space } from '@ardoq/style-helpers';
import {
  getColumnTypeName,
  getFieldTypeName,
  getReferenceFormatName,
} from 'integrations/common/pages/tabularConfigMapping/utils';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { isNil } from 'lodash/fp';
import { ROW_INDEX_FIELD } from 'integrations/common/streams/tablePreviews/constants';
import { DataRecord } from './types';
import { emptyComplaintsIndices } from 'integrations/common/streams/transferState/constants';
import { DEFAULT_REFERENCE_TYPE } from 'integrations/common/streams/references/constants';
import { TableMappingErrors } from 'integrations/common/streams/tabularMappingErrors/types';

type MappingTableProps = {
  tableMapping?: TableMappingMap;
  tablePreview?: TablePreview;
  selectedColumn: number | null;
  setSelectedColumn: (column: number) => void;
  onStartColumnFilter: ({
    columnName,
    columnIndex,
    sourceFieldName,
  }: {
    columnName: string;
    columnIndex: number;
    sourceFieldName: string;
  }) => void;
  rowComplaintsIndices?: ComplaintsIndices;
  tableComplaints?: TableComplaints;
  tableMappingErrors?: TableMappingErrors;
};

const ColumnInfoRow = styled.div``;

const getConfiguredTitle = ({
  columnMapping,
  tableMapping,
}: {
  columnMapping: ColumnMapping;
  tableMapping?: TableMappingMap;
}): ReactNode => {
  switch (columnMapping.columnType) {
    case 'field':
      return (
        <>
          <ColumnInfoRow>
            {getColumnTypeName(columnMapping.columnType)}
          </ColumnInfoRow>
          <ColumnInfoRow>Name: {columnMapping.fieldLabel}</ColumnInfoRow>
          <ColumnInfoRow>
            Type: {getFieldTypeName(columnMapping.fieldType)}
          </ColumnInfoRow>
        </>
      );
    case 'component':
      return (
        <>
          <ColumnInfoRow>
            {getColumnTypeName(columnMapping.columnType)}
          </ColumnInfoRow>
          {tableMapping && !hasParentColumn(tableMapping) && (
            <ColumnInfoRow>
              Hierarchy level: {columnMapping.hierarchyLevel + 1}
            </ColumnInfoRow>
          )}
          {tableMapping && !hasComponentTypeColumn(tableMapping) && (
            <ColumnInfoRow>Type: {columnMapping.componentType}</ColumnInfoRow>
          )}
        </>
      );
    case 'custom-id':
      return (
        <>
          <ColumnInfoRow>
            {getColumnTypeName(columnMapping.columnType)}
          </ColumnInfoRow>
          <ColumnInfoRow>Field name: {columnMapping.fieldLabel}</ColumnInfoRow>
        </>
      );
    case 'parent':
      return (
        <>
          <ColumnInfoRow>
            {getColumnTypeName(columnMapping.columnType)}
          </ColumnInfoRow>
          <ColumnInfoRow>
            Format: {getReferenceFormatName(columnMapping.parentFormat)}
          </ColumnInfoRow>
        </>
      );
    case 'reference':
      return (
        <>
          <ColumnInfoRow>
            {getColumnTypeName(columnMapping.columnType)}
          </ColumnInfoRow>
          <ColumnInfoRow>
            {columnMapping.referenceDirection === 'incoming'
              ? 'From: '
              : 'To: '}
            {columnMapping.targetWorkspace?.name}
          </ColumnInfoRow>
          <ColumnInfoRow>Type: {columnMapping.referenceType}</ColumnInfoRow>
          <ColumnInfoRow>
            Format: {getReferenceFormatName(columnMapping.referenceFormat)}
          </ColumnInfoRow>
        </>
      );
    case 'target-reference':
      return (
        <>
          <ColumnInfoRow>
            {getColumnTypeName(columnMapping.columnType)}
          </ColumnInfoRow>
          <ColumnInfoRow>
            Format: {getReferenceFormatName(columnMapping.referenceFormat)}
          </ColumnInfoRow>
        </>
      );
    case 'root-reference':
      return (
        <>
          <ColumnInfoRow>
            {getColumnTypeName(columnMapping.columnType)}
          </ColumnInfoRow>
          <ColumnInfoRow>
            Format: {getReferenceFormatName(columnMapping.referenceFormat)}
          </ColumnInfoRow>
          {tableMapping && !hasReferenceTypeColumn(tableMapping) && (
            <ColumnInfoRow>
              Reference type:{' '}
              {columnMapping.referenceType || DEFAULT_REFERENCE_TYPE}
            </ColumnInfoRow>
          )}
        </>
      );
    default:
      return getColumnTypeName(columnMapping.columnType);
  }
};

const getColumnConfiguration = ({
  tableMapping,
  tableMappingErrors = {},
  columnIndex,
}: {
  tableMapping?: TableMappingMap;
  tableMappingErrors?: TableMappingErrors;
  columnIndex: number;
}) => {
  // 1st column is the number (#)
  if (columnIndex === 0) {
    return {
      configuredType: TableColumnMappingType.NONE,
    };
  }

  if (!tableMapping || !isConfigurableTable(tableMapping)) {
    return {
      configuredType: TableColumnMappingType.DISABLED,
    };
  }
  // columnIndex - 1 to skip the number (#) column
  const columnMapping: ColumnMapping =
    tableMapping.mappedColumns[columnIndex - 1];

  if (!columnMapping) {
    return {
      configuredType: TableColumnMappingType.NONE,
    };
  }

  const configuredError = Object.values(
    tableMappingErrors[columnIndex - 1] || {}
  ).some(Boolean);

  return {
    configuredType: TableColumnMappingType.MANUAL,
    configuredTitle: getConfiguredTitle({ columnMapping, tableMapping }),
    configuredError,
  };
};

export const MappingTable = ({
  tablePreview,
  tableMapping,
  selectedColumn,
  setSelectedColumn,
  onStartColumnFilter,
  rowComplaintsIndices,
  tableComplaints,
  tableMappingErrors,
}: MappingTableProps) => {
  const { headerIndices, dataSource } = getTableData({
    previewRows: tablePreview?.previewRows,
    problemRowIndices: rowComplaintsIndices || emptyComplaintsIndices,
  });

  const isLoading =
    tablePreview?.previewRows?.fetchRowsStatus === 'LOADING' ||
    tablePreview?.previewRows?.fetchProblemRowsStatus === 'LOADING';

  // use columnIndex - 1 to offset the number(#) column
  const handleSetSelectedColumn = (column: number) => {
    setSelectedColumn(column - 1);
  };

  const columns = headerIndices.map((title, columnIndex) => {
    return {
      title:
        columnIndex === 0
          ? '#'
          : tablePreview?.previewRows?.header[columnIndex], // 1st column is always #
      dataIndex: title,
      config: getColumnConfiguration({
        tableMapping,
        tableMappingErrors,
        columnIndex,
      }),
      hideConfig: columnIndex === 0,
      isConfigurableTable: isConfigurableTable(tableMapping),
      hasActiveFilters: Boolean(
        (tableMapping?.columnFilters &&
          tableMapping.columnFilters[columnIndex - 1]?.length) ||
          (tableMapping?.transformations &&
            tableMapping.transformations.column.find(
              col =>
                col.sourceFieldName ===
                tablePreview?.sourceFieldNames?.[columnIndex - 1]
            )?.transformations.length)
      ),
      isHighlighted: isNil(selectedColumn)
        ? false
        : selectedColumn + 1 === columnIndex,
      onFilter: () => {
        onStartColumnFilter({
          columnName: tablePreview?.previewRows?.header[columnIndex] || '',
          columnIndex: columnIndex - 1,
          sourceFieldName:
            tablePreview?.sourceFieldNames?.[columnIndex - 1] || '',
        });
      },
    };
  });

  if (!isLoading && !tablePreview?.previewRows?.rows.length) {
    return (
      <NoDataContainer>
        <NoDataText>No data</NoDataText>
      </NoDataContainer>
    );
  }
  return (
    <Table
      idIndex={headerIndices[0]}
      loading={isLoading}
      dataSource={dataSource}
      columns={columns}
      // extra space to avoid overlapping with sidebar
      // 40px as the buffer since the table can expand with the mapping
      selectedColumnIndex={isNil(selectedColumn) ? null : selectedColumn + 1}
      getRowOriginalIndex={(dataSourceRow: DataRecord): number => {
        // TODO The idea was not to use these values outside of the table rendering.
        return dataSourceRow[`0_${ROW_INDEX_FIELD}`];
      }}
      getComplaint={complaintGetter(tableComplaints)}
      setSelectedColumn={handleSetSelectedColumn}
    />
  );
};

const NoDataContainer = styled(Space).attrs({
  $align: 'center',
  $justify: 'center',
})`
  height: 300px;
  width: 100%;
`;

const NoDataText = styled.div`
  ${header2};
  color: ${colors.grey50};
`;
