import { currentDate, formatInTimeZone } from '@ardoq/date-time';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';

type TimeZoneOption = {
  label: string;
  value: string;
};

export const getUserTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getTimeZoneOptions = () => {
  const locale = getCurrentLocale();
  const allTimeZones = Intl.supportedValuesOf('timeZone');

  return allTimeZones
    .map<TimeZoneOption>(timeZoneName => {
      const gmtFormattedTimeZone = formatInTimeZone(
        currentDate(),
        timeZoneName,
        'OOOO'
      );
      const humanReadableTimezoneName = timeZoneName.replace('_', ' ');

      return {
        label: `(${gmtFormattedTimeZone}) ${humanReadableTimezoneName}`,
        value: timeZoneName,
      };
    })
    .sort((a, b) => localeCompare(a.label, b.label, locale));
};
