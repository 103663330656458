import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Icon, IconName } from '@ardoq/icons';
import {
  PopoverContentType,
  PopoverPlacement,
  WithPopover,
} from '@ardoq/popovers';
import { Space } from '@ardoq/style-helpers';
import { SizeVariant } from '@ardoq/layout';
import { header4 } from '@ardoq/typography';
import { colors } from '@ardoq/design-tokens';

const LabelContainer = styled(Space)<{ $isDisabled: boolean }>`
  ${header4}
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
    `}
`;

export const LabelWithPopoverIcon = ({
  children,
  popoverContent,
  iconName = IconName.KNOWLEDGE_BASE,
  preferredPlacement = PopoverPlacement.BOTTOM,
  gap = 's4',
  isDisabled = false,
  ...props
}: {
  popoverContent?: PopoverContentType;
  children?: ReactNode;
  isDisabled?: boolean;
  iconName?: IconName;
  gap?: SizeVariant;
  preferredPlacement?: PopoverPlacement;
}) => (
  <LabelContainer
    $isDisabled={isDisabled}
    $align="center"
    $gap={gap}
    {...props}
  >
    <div>{children}</div>
    {popoverContent && (
      <WithPopover
        content={popoverContent}
        preferredPlacement={preferredPlacement}
      >
        <Icon iconName={iconName} color={colors.grey50}></Icon>
      </WithPopover>
    )}
  </LabelContainer>
);
