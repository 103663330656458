import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  navigateToOverview,
  navigateToPath,
  navigateToReview,
  navigateToSelectData,
} from './actions';
import { filter, withLatestFrom, map, of, EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  loadImportsHistory,
  setVisibleIntegration,
} from 'integrations/actions';
import { transferState$ } from 'integrations/common/streams/transferState/getTransferStateStream';
import { ExportRoute, ImportRoute, OverviewRoute } from './types';
import {
  transferFailure,
  transferSuccess,
} from 'integrations/common/streams/transferState/actions';
import {
  setCurrentTableId,
  resetIntegration,
} from 'integrations/common/streams/activeIntegrations/actions';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { isPathReady, waitUntilConnectionsListAvailable } from './utils';
import { tablePreviews$ } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { tabularMappings$ } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { navigateToConfigure } from 'integrations/common/navigation/actions';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import {
  connectionClick,
  selectConnection,
  updateSelectedConnectionsIds,
} from 'integrations/common/streams/connections/actions';
import { IntegrationId } from '../streams/tabularMappings/types';
import { getAllowedTabs } from './tabs';
import { UNIFIED_INTEGRATION_IDS } from 'integrations/unified/constants';
import { openHome } from '../../../components/AppMainSidebar/utils';
import { integrationConfigs$ } from '../streams/integrationConfigs/integrationConfigs$';
import { setSelectedConfigIds } from '../streams/transferConfigs/actions';
import { ActionCreatorParameter } from 'integrations/common/utils/actionCreatorWithIntegrationId';
import {
  getIntegrationById,
  getIntegrationDisabledOptions,
} from 'integrations/allIntegrations';
import currentUser$ from 'streams/currentUser/currentUser$';
import { connections$ } from '../streams/connections/connections$';
import { isIntegrationWithConnections } from '../streams/connections/utils';

const INTEGRATIONS_WITH_COMMON_NAVIGATION_MODULE: Array<IntegrationId> = [
  'aws-v3',
  'azure-v3',
  'servicenow-v3',
  'microsoft-entra-id',
  'signavio-exporter',
  'excel-v3',
  ...UNIFIED_INTEGRATION_IDS,
];

const handleNavigateToPath = routine(
  ofType(navigateToPath),
  extractPayload(),
  tap(({ path, integrationId }) => {
    if (path !== OverviewRoute.CONFIGURATIONS) {
      dispatchAction(setSelectedConfigIds([]));
    }

    dispatchAction(
      setVisibleIntegration({
        id: integrationId,
        path,
      })
    );
  })
);

const handleNavigateToDashboard = routine(
  ofType(setVisibleIntegration),
  extractPayload(),
  filter(({ id }) => id === null),
  tap(() => {
    dispatchAction(loadImportsHistory());
  })
);

const handleNavigateToIntegration = routine(
  ofType(setVisibleIntegration),
  extractPayload(),
  filter(({ id }) =>
    INTEGRATIONS_WITH_COMMON_NAVIGATION_MODULE.includes(id as IntegrationId)
  ),
  withLatestFrom(
    activeIntegrations$,
    transferState$,
    tablePreviews$,
    tabularMappings$,
    integrationConfigs$,
    currentUser$
  ),
  map(
    ([
      { path, id },
      activeIntegration,
      transferState,
      tablePreviews,
      tabularMapping,
      integrationConfig,
      currentUser,
    ]) => {
      const integrationId = id as IntegrationId;
      return {
        integrationId,
        path,
        activeIntegration: activeIntegration[integrationId],
        transferState: transferState[integrationId],
        tablePreviews: tablePreviews[integrationId],
        tabularMapping: tabularMapping[integrationId],
        integrationConfig: integrationConfig[integrationId],
        currentUser,
      };
    }
  ),
  tap(
    ({
      integrationId,
      path,
      activeIntegration,
      transferState,
      tablePreviews,
      tabularMapping,
      integrationConfig,
      currentUser,
    }) => {
      const allowedTabs = getAllowedTabs({
        integrationId,
        allowedOverviewRoutes: integrationConfig.config?.allowedOverviewRoutes,
      });

      const integration = getIntegrationById(integrationId);
      const isDisabled =
        !integration ||
        getIntegrationDisabledOptions(integration, currentUser)?.isDisabled;

      if (allowedTabs.length === 0 || isDisabled) {
        return openHome();
      }

      // if there is an ongoing import
      // when navigating back from the outside of the integration
      // user should be taken to where they left off
      if (!path && activeIntegration.integrationPath) {
        return dispatchAction(
          setVisibleIntegration({
            id: integrationId,
            path: activeIntegration.integrationPath,
          })
        );
      }
      if (!path) {
        return dispatchAction(navigateToOverview({ integrationId }));
      }

      const pathIsReady = isPathReady(path, {
        tabularMapping,
        tablePreviews,
        transferState,
        allowedOverviewRoutes: integrationConfig.config?.allowedOverviewRoutes,
        integrationId,
      });

      if (!pathIsReady) {
        dispatchAction(navigateToOverview({ integrationId }));
      }

      dispatchAction(
        trackIntegrationEvent({
          integrationId,
          name: 'NAVIGATED_TO_PATH',
          metadata: {
            path,
            isOverviewPage: Object.values(OverviewRoute).includes(
              path as OverviewRoute
            ),
          },
        })
      );
    }
  )
);

const handleTransferDryRunResult = routine(
  ofType<
    ActionCreatorParameter<typeof transferSuccess | typeof transferFailure>
  >(transferSuccess, transferFailure),
  extractPayload(),
  withLatestFrom(transferState$),
  filter(
    ([{ integrationId }, transferState]) =>
      transferState[integrationId].dryRun &&
      INTEGRATIONS_WITH_COMMON_NAVIGATION_MODULE.includes(integrationId)
  ),
  tap(([{ integrationId }]) => {
    dispatchAction(navigateToReview({ integrationId }));
  })
);

const handleActualTransferResult = routine(
  ofType<
    ActionCreatorParameter<typeof transferSuccess | typeof transferFailure>
  >(transferSuccess, transferFailure),
  extractPayload(),
  withLatestFrom(transferState$),
  filter(
    ([{ integrationId }, transferState]) =>
      !transferState[integrationId].dryRun &&
      INTEGRATIONS_WITH_COMMON_NAVIGATION_MODULE.includes(integrationId)
  ),
  tap(([{ integrationId }, transferState]) => {
    dispatchAction(
      navigateToPath({
        integrationId,
        path:
          transferState[integrationId].transferDirection ===
          TransferDirection.IMPORT
            ? ImportRoute.IMPORT_AND_SCHEDULE
            : ExportRoute.EXPORT,
      })
    );
  })
);

const handleNavigateToConfigure = routine(
  ofType(navigateToConfigure),
  extractPayload(),
  withLatestFrom(transferState$),
  filter(([{ integrationId }]) =>
    INTEGRATIONS_WITH_COMMON_NAVIGATION_MODULE.includes(integrationId)
  ),
  tap(([{ tableId, integrationId }, transferState]) => {
    if (tableId) {
      dispatchAction(setCurrentTableId({ integrationId, id: tableId }));
    }
    dispatchAction(
      navigateToPath({
        integrationId,
        path:
          transferState[integrationId].transferDirection ===
          TransferDirection.IMPORT
            ? ImportRoute.CONFIGURE
            : ExportRoute.CONFIGURE,
      })
    );
  })
);

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  filter(integrationId =>
    INTEGRATIONS_WITH_COMMON_NAVIGATION_MODULE.includes(integrationId)
  ),
  tap(integrationId => {
    dispatchAction(
      setVisibleIntegration({
        id: integrationId,
        path: null,
      })
    );
  })
);

const handleNavigateToIntegraionReview = routine(
  ofType(navigateToReview),
  extractPayload(),
  withLatestFrom(transferState$),
  filter(([{ integrationId }]) =>
    INTEGRATIONS_WITH_COMMON_NAVIGATION_MODULE.includes(integrationId)
  ),
  tap(([{ integrationId }, transferState]) =>
    dispatchAction(
      navigateToPath({
        integrationId,
        path:
          transferState[integrationId].transferDirection ===
          TransferDirection.IMPORT
            ? ImportRoute.REVIEW
            : ExportRoute.REVIEW,
      })
    )
  )
);

const handleNavigateToIntegraionSelectData = routine(
  ofType(navigateToSelectData),
  extractPayload(),
  withLatestFrom(transferState$),
  filter(([{ integrationId }]) =>
    INTEGRATIONS_WITH_COMMON_NAVIGATION_MODULE.includes(integrationId)
  ),
  tap(([{ integrationId }, transferState]) =>
    dispatchAction(
      navigateToPath({
        integrationId,
        path:
          transferState[integrationId].transferDirection ===
          TransferDirection.IMPORT
            ? ImportRoute.SELECT_DATA
            : ExportRoute.SELECT_DATA,
      })
    )
  )
);

const handleConnectionClick = routine(
  ofType(connectionClick),
  extractPayload(),
  filter(({ integrationId }) =>
    INTEGRATIONS_WITH_COMMON_NAVIGATION_MODULE.includes(integrationId)
  ),
  tap(({ connection, integrationId }) => {
    dispatchAction(
      navigateToPath({ integrationId, path: ImportRoute.SELECT_DATA })
    );
    dispatchAction(
      selectConnection({
        integrationId,
        selectedConnectionId: connection._id,
      })
    );

    dispatchAction(
      updateSelectedConnectionsIds({
        selectedConnectionIds: [connection._id],
        integrationId,
      })
    );
  })
);

const handleNavigateToOverview = routine(
  ofType(navigateToOverview),
  extractPayload(),
  withLatestFrom(integrationConfigs$),
  switchMap(([{ integrationId }, configs]) => {
    const defaultTab = getAllowedTabs({
      integrationId,
      allowedOverviewRoutes:
        configs[integrationId].config?.allowedOverviewRoutes,
    }).find(tab => tab.isAccessible(integrationId));
    if (!isIntegrationWithConnections(integrationId)) {
      dispatchAction(
        setVisibleIntegration({
          id: integrationId,
          path: defaultTab?.path,
        })
      );
      return EMPTY;
    }
    return of({ integrationId, defaultTab });
  }),
  withLatestFrom(connections$),
  switchMap(([{ integrationId, defaultTab }, connections]) => {
    const connectionState = connections[integrationId];
    const loadingConnections = ['INIT', 'LOADING'].includes(
      connectionState.statuses.list.status
    );

    if (loadingConnections) {
      // go to default tab
      dispatchAction(
        setVisibleIntegration({
          id: integrationId,
          path: defaultTab?.path,
        })
      );
      return waitUntilConnectionsListAvailable(integrationId).then(
        connections => {
          return {
            integrationId,
            hasConnections: connections?.length > 0,
          };
        }
      );
    }

    return of({
      integrationId,
      hasConnections: connectionState.connections.length > 0,
    });
  }),
  withLatestFrom(integrationConfigs$),
  tap(([{ integrationId, hasConnections }, integrationConfigs]) => {
    if (!hasConnections) {
      dispatchAction(
        setVisibleIntegration({
          id: integrationId,
          path: OverviewRoute.CONNECTIONS,
        })
      );
      return;
    }
    const integrationConfigState = integrationConfigs[integrationId];

    const allowedTabs = getAllowedTabs({
      hasConnections,
      integrationId,
      allowedOverviewRoutes:
        integrationConfigState.config?.allowedOverviewRoutes,
    });

    if (allowedTabs.length !== 0) {
      dispatchAction(
        setVisibleIntegration({ id: integrationId, path: allowedTabs[0].path })
      );
      return;
    }
    // edge case, not really relevant now
    // navigate to home when no allowed tabs
    dispatchAction(setVisibleIntegration({ id: null, path: null }));
  })
);

export default [
  handleNavigateToPath,
  handleNavigateToIntegration,
  handleActualTransferResult,
  handleTransferDryRunResult,
  handleNavigateToConfigure,
  handleNavigateToIntegraionSelectData,
  handleNavigateToIntegraionReview,
  handleResetIntegration,
  handleConnectionClick,
  handleNavigateToDashboard,
  handleNavigateToOverview,
];
