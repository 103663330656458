import { Tag, StatusType } from '@ardoq/status-ui';
import { HeaderCell } from '@ardoq/table';
import { ColumnsProps } from '../components/AssetsBrowser/columns';
import SortHeader from '../components/EntityBrowser/SortHeader';

export const getStatusColumn = (columnProps: ColumnsProps) => ({
  title: 'Status',
  dataIndex: 'published',
  headerStyle: {
    width: 100,
  },
  headerRender: ({ title, dataIndex = 'published' }: HeaderCell) => (
    <SortHeader
      dataIndex={dataIndex}
      title={title}
      dataClickId="published"
      sortByField={columnProps.sortByField}
      sortOrder={columnProps.sortOrder}
      onSortChanged={columnProps.setSortByField}
    />
  ),
  valueRender: (value: boolean | undefined) =>
    value === undefined ? (
      <></>
    ) : (
      <Tag
        statusType={value ? StatusType.SUCCESS : StatusType.ERROR}
        label={value ? 'Published' : 'Unpublished'}
      />
    ),
});
