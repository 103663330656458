import { persistentReducedStream } from '@ardoq/rxbeach';
import { defaultState, reducers } from './reducers';

const quickSearch$ = persistentReducedStream(
  'quickSearch$',
  defaultState,
  reducers
);

export default quickSearch$;
