import React, { useState } from 'react';
import { modal, ModalLayout, ModalTemplate } from '@ardoq/modal';
import { InfoNotification } from '@ardoq/status-ui';
import { IconName } from '@ardoq/icons';
import {
  getColumnOptions,
  isClearButtonDisabled,
  isFilterCondition,
  isSaveButtonDisabled,
  isTransformationColumn,
} from './utils';
import {
  FilterCondition,
  Transformation,
  TransformationColumn,
  Transformations,
} from '@ardoq/api-types/integrations';
import {
  IntegrationId,
  TableMappingMap,
} from 'integrations/common/streams/tabularMappings/types';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import {
  setColumnFilter,
  setColumnTransformations,
} from 'integrations/common/streams/tabularMappings/actions';
import { viewModel$ } from './viewModel$';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { FilterSection } from './filterSection/FilterSection';
import { TransformSection } from './transformSection/TransformSection';
import { AddTransformButtons } from './AddTransformButtons';
import { Paragraph } from '@ardoq/typography';
import { EmptyFilterCondition, EmptyTransformation } from './types';

type TransformModalProps = {
  columnFilters: TableMappingMap['columnFilters'];
  transformations: Transformations;
  columnName: string;
  columnIndex: number;
  sourceFieldNames: string[];
  sourceFieldName: string;
  columnHeaders: string[];
  integrationId: IntegrationId;
  onApply: (
    transformationColumn: TransformationColumn,
    filters: FilterCondition[],
    index: number
  ) => void;
  onClearFilter: (index: number) => void;
  onClose: () => void;
};

const DEFAULT_FILTER_CONDITION: EmptyFilterCondition = {
  operator: null,
};

const DEFAULT_TRANSFORMATIONS: EmptyTransformation = {
  operation: null,
  config: {
    failure: {
      strategy: 'use_existing',
      warn: false,
    },
    dictionary: [{ key: '', value: '' }],
  },
};

const buttonOptions = [
  {
    label: 'Transform data',
    dataTestId: 'transform-button',
    color: 'grey',
    icon: IconName.ACCOUNT_TREE,
  },
  {
    label: 'Filter data',
    dataTestId: 'filter-button',
    color: 'grey',
    icon: IconName.FILTER_LIST,
  },
];

function TransformModalComponent({
  columnFilters,
  transformations,
  columnName,
  columnIndex,
  sourceFieldNames,
  sourceFieldName,
  columnHeaders,
  integrationId,
  onClose,
  onApply,
  onClearFilter,
}: TransformModalProps) {
  const [showButtons, setShowButtons] = useState<boolean>(false);

  const initFilters = columnFilters[columnIndex]?.length
    ? columnFilters[columnIndex]
    : [];

  const [conditionsList, setConditionsList] =
    useState<Array<FilterCondition | EmptyFilterCondition>>(initFilters);

  const initTransformations = transformations.column?.find(
    column => column.sourceFieldName === sourceFieldName
  ) || { sourceFieldName: sourceFieldName, transformations: [] };

  const [transformationColumn, setTransformationColumn] = useState<
    Omit<TransformationColumn, 'transformations'> & {
      transformations: (Transformation | EmptyTransformation)[];
    }
  >(initTransformations);

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const index = transformations.column?.findIndex(
    column => column.sourceFieldName === sourceFieldName
  );

  const indexToUpdate = index !== -1 ? index : transformations.column.length;

  const [selectedColumnOption, availableColumnOptions] = getColumnOptions(
    columnIndex,
    columnHeaders,
    sourceFieldNames
  );

  const handleTransformationChange = (
    index: number,
    newTransformation: Transformation
  ) => {
    setTransformationColumn(prevState => ({
      ...prevState,
      transformations: prevState.transformations.map((transformation, i) =>
        i === index ? newTransformation : transformation
      ),
    }));
  };

  const handleTransformationRemove = (index: number) => {
    setTransformationColumn(prevState => ({
      ...prevState,
      transformations: prevState.transformations.filter((_, i) => i !== index),
    }));
    dispatchAction(
      trackIntegrationEvent({ integrationId, name: 'DELETED_TRANSFORMATIONS' })
    );
  };

  const addNewTransformation = () => {
    setTransformationColumn(prevState => ({
      ...prevState,
      transformations: [...prevState.transformations, DEFAULT_TRANSFORMATIONS],
    }));
    setExpandedIndex(transformationColumn.transformations.length);
  };

  const saveChanges = () => {
    if (isTransformationColumn(transformationColumn)) {
      onApply(transformationColumn, conditions, indexToUpdate);
    }
  };

  const conditions = conditionsList.filter(isFilterCondition);

  const buttonOptionsWithOnClick = buttonOptions.map((option, index) => ({
    ...option,
    onClick: () => {
      setShowButtons(false);

      if (index === 0) {
        dispatchAction(
          trackIntegrationEvent({
            integrationId,
            name: 'OPENED_TRANSFORMATIONS',
          })
        );
        addNewTransformation();
      } else {
        dispatchAction(
          trackIntegrationEvent({
            integrationId,
            name: 'OPENED_TRANSFORMATIONS_FILTERS',
          })
        );
        setConditionsList([DEFAULT_FILTER_CONDITION]);
        setExpandedIndex(null);
        dispatchAction(
          trackIntegrationEvent({ integrationId, name: 'ADDED_FILTER_OPTION' })
        );
      }
    },
  }));

  const buttonConfig =
    conditionsList.length > 0
      ? [buttonOptionsWithOnClick[0]]
      : buttonOptionsWithOnClick;

  return (
    <ModalTemplate
      headerText={`Advance configuration for column: ${columnName}`}
      modalSize={3}
      onCloseButtonClick={onClose}
      onPrimaryButtonClick={saveChanges}
      onSecondaryButtonClick={() => onClearFilter(indexToUpdate)}
      primaryButtonClickId="apply-filter-click"
      primaryButtonText="Save changes"
      secondaryButtonClickId="cancel-filter-click"
      secondaryButtonText="Clear"
      isPrimaryButtonDisabled={isSaveButtonDisabled(
        transformationColumn,
        transformations.column.length
          ? transformations
          : {
              column: [
                { sourceFieldName: sourceFieldName, transformations: [] },
              ],
            },
        conditionsList,
        columnFilters[columnIndex] || [],
        conditions,
        sourceFieldName,
        {
          integrationId,
        }
      )}
      isSecondaryButtonDisabled={isClearButtonDisabled(
        transformationColumn,
        conditionsList
      )}
    >
      <ModalLayout>
        <Paragraph variant="text2" color="textDefault">
          The transformation and filter won&apos;t show in the preview but will
          be applied during testing and importing.
        </Paragraph>
        {conditionsList.length > 0 &&
          transformationColumn &&
          transformationColumn.transformations.length > 0 && (
            <InfoNotification>
              The filter will be applied to the transformed data.
            </InfoNotification>
          )}
        {transformationColumn.transformations.map((transformation, index) => (
          <TransformSection
            key={index}
            integrationId={integrationId}
            index={index}
            expanded={expandedIndex === index}
            defaultColumn={selectedColumnOption}
            availableColumns={availableColumnOptions}
            transformation={transformation}
            setTransformation={(newTransformation: Transformation) =>
              handleTransformationChange(index, newTransformation)
            }
            removeTransformation={() => handleTransformationRemove(index)}
          />
        ))}
        {conditionsList.length > 0 && (
          <>
            <FilterSection
              integrationId={integrationId}
              isExpanded={!columnFilters[columnIndex]}
              conditionsList={conditionsList}
              setConditionsList={setConditionsList}
              defaultFilterCondition={DEFAULT_FILTER_CONDITION}
              index={
                transformationColumn
                  ? transformationColumn?.transformations.length + 1
                  : 1
              }
            />
          </>
        )}
        <AddTransformButtons
          title="Add advanced configuration"
          subtitle="You can add a filter, or transform the data in the column."
          showButtons={showButtons}
          buttonsConfig={buttonConfig}
          onAddButtonClick={() => setShowButtons(true)}
          onCloseButtonClick={() => setShowButtons(false)}
        />
      </ModalLayout>
    </ModalTemplate>
  );
}

const TransformModal = connect(TransformModalComponent, viewModel$);

export const startTransformModal = ({
  columnName,
  columnIndex,
  sourceFieldName,
  integrationId,
}: Pick<
  TransformModalProps,
  'columnName' | 'columnIndex' | 'sourceFieldName'
> & { integrationId: IntegrationId }) => {
  dispatchAction(
    trackIntegrationEvent({
      integrationId,
      name: 'OPENED_TRANSFORMATION_MODAL',
    })
  );
  modal(
    resolve => (
      <TransformModal
        columnName={columnName}
        columnIndex={columnIndex}
        sourceFieldName={sourceFieldName}
        integrationId={integrationId}
        onApply={(transformationColumn, filters, index) => {
          dispatchAction(
            trackIntegrationEvent({
              integrationId,
              name: 'APPLIED_COLUMN_FILTERS',
            })
          );
          dispatchAction(
            setColumnFilter({ integrationId, filters, columnIndex })
          );
          dispatchAction(
            setColumnTransformations({
              integrationId,
              index,
              transformationColumn,
            })
          );
          resolve({ transformationColumn, filters });
        }}
        onClearFilter={index => {
          dispatchAction(
            trackIntegrationEvent({
              integrationId,
              name: 'CLEARED_COLUMN_FILTERS',
            })
          );
          dispatchAction(
            trackIntegrationEvent({
              integrationId,
              name: 'DELETED_TRANSFORMATIONS',
            })
          );
          dispatchAction(
            setColumnFilter({ integrationId, filters: [], columnIndex })
          );
          dispatchAction(
            setColumnTransformations({
              integrationId,
              index,
              transformationColumn: null,
            })
          );
          resolve([]);
        }}
        onClose={() => resolve([])}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
};
