import { MouseEvent, useRef } from 'react';
import { MetamodelViewCommands } from './types';
import { MetamodelViewState } from './types';
import { createGraphInterface } from 'viewpointBuilder/components/SimpleGraph/GraphInterface';
import SimpleGraphView from 'viewpointBuilder/components/SimpleGraph/SimpleGraphView';
import { SimpleGraphContainerWithGreyBackground } from 'viewpointBuilder/components/SimpleGraph/SimpleGraphViewContainer';
import { ViewpointBuilderFooter } from 'viewpointBuilder/components/ViewpointBuilderFooter';
import { MetamodelSidePanel } from './MetamodelSidePanel';
import { WithMetamodelLoader } from '../components/WithMetamodelLoader';
import { ResizablePageBody } from '@ardoq/page-layout';

type MetamodelViewContentProps = {
  state: MetamodelViewState;
  toggleGraphNodeSelection: (event: MouseEvent) => void;
  commands: MetamodelViewCommands;
  closeViewpointBuilder: () => void;
};
export const MetamodelViewContent = ({
  state,
  toggleGraphNodeSelection,
  commands,
  closeViewpointBuilder,
}: MetamodelViewContentProps) => {
  const { graphNodes, graphEdges, graphGroups, shouldFitToContentOnLayout } =
    state;

  const viewModel = {
    graphNodes,
    graphEdges,
    graphGroups,
  };
  const graphInterface = useRef(createGraphInterface());

  return (
    <WithMetamodelLoader
      isMetaModelLoaded={state.isMetaModelLoaded}
      closeViewpointBuilder={closeViewpointBuilder}
    >
      <ResizablePageBody
        skipPadding
        footerContent={
          <ViewpointBuilderFooter
            closeViewpointBuilder={closeViewpointBuilder}
          />
        }
        rightContentInitialWidth={600}
        rightContent={<MetamodelSidePanel state={state} commands={commands} />}
      >
        <SimpleGraphContainerWithGreyBackground
          onClick={toggleGraphNodeSelection}
        >
          <SimpleGraphView
            viewModel={viewModel}
            graphInterface={graphInterface.current}
            label={'Metamodel view'}
            shouldFitToContentOnLayout={shouldFitToContentOnLayout}
          />
        </SimpleGraphContainerWithGreyBackground>
      </ResizablePageBody>
    </WithMetamodelLoader>
  );
};
