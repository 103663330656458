import { ComponentProps } from 'react';
import { openPresentationEditor } from 'presentationEditor/presentationUtil';
import styled from 'styled-components';
import { trackPresentationLinkClicked } from './tracking';
import { openSlideMigrationKBArticle } from 'viewDeprecation/util';
import { Link } from '@ardoq/typography';

const DescriptionContainer = styled.p`
  margin-bottom: 30px;
`;

export const ViewMigrationGuideLink = (
  props: Omit<ComponentProps<typeof Link>, 'onClick'>
) => (
  <Link {...props} onClick={openSlideMigrationKBArticle} typography="text2" />
);

export const ChecklistDescription = () => (
  <DescriptionContainer>
    Below is a list of all presentations in your organization that need to be
    migrated to the latest version of the selected view.{' '}
    <ViewMigrationGuideLink>
      Find a complete guide on the best way to migrate them here
    </ViewMigrationGuideLink>
    .
  </DescriptionContainer>
);

type ClickableTitleProps = {
  presentationId: string;
  name: string;
};

export const ClickableTitle = ({
  presentationId,
  name,
}: ClickableTitleProps) => (
  <Link
    onClick={() => {
      trackPresentationLinkClicked();
      openPresentationEditor({ presentationId });
    }}
    underlineOnHover
    hideIcon
    typography="text2"
  >
    {name}
  </Link>
);
