import { allIntegrations } from './allIntegrations';
import { Integration } from './types';

const integrationToImporterInit = new Map([
  ...allIntegrations
    .filter(i => i.typescriptImporter)
    .map(integration => [integration.id, true] as const),
]);

export const importerAvailable = (integration: Integration) => {
  return integrationToImporterInit.has(integration.id);
};
