import {
  ArrowBackIcon,
  ComponentIcon,
  ParentChildIcon,
  PathIcon,
} from '@ardoq/icons';
import { Space } from '@ardoq/style-helpers';
import { FlexBox } from '@ardoq/layout';
import styled from 'styled-components';
import { header4 } from '@ardoq/typography';
import { PrimaryButton } from '@ardoq/button';
import { colors, r8, s16, s32 } from '@ardoq/design-tokens';
import { ConfigurationState } from 'integrations/ITPedia/streams/types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  updateManufacturer,
  updateProduct,
  updateVersion,
} from 'integrations/ITPedia/streams/configurationState/actions';
import { SelectOption, SelectOptionsOrGroups } from '@ardoq/select';
import { MatchFormRow } from './MatchFormRow';
import {
  FieldsGroup,
  getFields,
} from 'integrations/ITPedia/streams/fields/fields$';
import { configurationState$ } from 'integrations/ITPedia/streams/configurationState/configurationState$';
import { switchMap } from 'rxjs';
import { trackITPediaEvent } from 'integrations/ITPedia/streams/tracking/actions';
import { mapFieldsToSelectOptions } from '../../FieldMapping/utils';
import { MatchingValue } from '@ardoq/api-types/integrations';
import { Tag } from '@ardoq/status-ui';
import { Island } from '@ardoq/page-layout';

type Step2Params = {
  selectedComponentTypes?: ConfigurationState['componentTypes'];
  workspaceFields: FieldsGroup;
  isValid?: boolean;
  onNext: () => void;
};

function Step2Component({
  selectedComponentTypes = {},
  isValid,
  workspaceFields,
  onNext,
}: Step2Params) {
  const ardoqFieldOptions: SelectOptionsOrGroups<string> = [
    {
      label: 'Ardoq fields',
      options: [
        {
          value: 'name',
          label: 'Component name',
          // Not using righIcon prop because icon doesn't appear after selecting option
          rightContent: <ComponentIcon color={colors.grey50} />,
        },
        {
          value: 'path',
          label: 'Component path',
          rightContent: <PathIcon style={{ color: colors.grey50 }} />,
        },
        {
          value: 'parent-name',
          label: 'Component Parent',
          rightContent: <ParentChildIcon style={{ color: colors.grey50 }} />,
        },
      ],
    },
  ];

  const fieldOptions = [
    {
      label: 'Workspace fields',
      options: mapFieldsToSelectOptions([
        ...workspaceFields.text,
        ...workspaceFields.list,
      ]),
    },
  ];

  const referenceOptions = [
    {
      label: 'Workspace references',
      options: [
        {
          value: 'Owns',
          label: 'Owns',
          rightContent: (
            <ReferenceRightContentContainer $gap="s4">
              Incoming
              <ArrowBackIcon />
            </ReferenceRightContentContainer>
          ),
        },
        {
          value: 'Supplies',
          label: 'Supplies',
          rightContent: (
            <ReferenceRightContentContainer $gap="s4">
              Incoming
              <ArrowBackIcon />
            </ReferenceRightContentContainer>
          ),
        },
      ],
    },
  ];

  const onMatchChange = (
    itPediaField: 'product' | 'manufacturer' | 'version',
    componentType: string,
    option: SelectOption<string> | null
  ) => {
    let matchingValue: MatchingValue | undefined;
    if (option && option.rightContent) {
      switch (option.value) {
        case 'path':
        case 'name':
        case 'parent-name':
          matchingValue = { matchBy: option.value };
          break;
        case 'Owns':
          matchingValue = {
            matchBy: 'reference',
            referenceTypeName: option.value,
          };
          break;
        case 'Supplies':
          matchingValue = {
            matchBy: 'reference',
            referenceTypeName: option.value,
          };
          break;
        // Shouldn't go in this case because fields don't have rightContent
        // But just in case we'll add a default
        default:
          matchingValue = {
            matchBy: 'field',
            fieldName: option.value,
          };
          break;
      }
    } else if (option?.value) {
      matchingValue = {
        matchBy: 'field',
        fieldName: option.value,
      };
    }
    switch (itPediaField) {
      case 'product':
        dispatchAction(
          updateProduct({
            componentType,
            product: matchingValue,
          })
        );
        break;
      case 'manufacturer':
        dispatchAction(
          updateManufacturer({
            componentType,
            manufacturer: matchingValue,
          })
        );
        break;
      case 'version':
        dispatchAction(
          updateVersion({
            componentType,
            version: matchingValue,
          })
        );
        break;
    }
  };
  return (
    <Island
      title="Identify Ardoq and IT-Pedia components"
      tags={<Tag>Step 2</Tag>}
      subtitle="Identify the corresponding Ardoq field for the IT-Pedia field. To ensure accurate component enrichment in Ardoq, we'll share the product name and manufacturer information with IT-Pedia."
      subtitleWidth="medium"
    >
      {Object.entries(selectedComponentTypes)?.map(([ctid, componentType]) => (
        <Island key={ctid} title={`Component type: ${ctid}`}>
          <FlexBox gap="xlarge">
            <FlexBox flexDirection="column" gap="xlarge">
              <Grid>
                <div>&nbsp;</div>
                <Header4>Ardoq field</Header4>
                <div>&nbsp;</div>
                <Header4>IT-Pedia field</Header4>
                <GridDivider />
                <MatchFormRow
                  value={
                    componentType.matching.product?.fieldName ||
                    componentType.matching.product?.referenceTypeName ||
                    componentType.matching.product?.matchBy
                  }
                  itPediaFieldName="Product name"
                  onChange={option => {
                    dispatchAction(
                      trackITPediaEvent({
                        name: 'SELECTED_FIELDS',
                        metadata: { name: 'Selected product name' },
                      })
                    );
                    onMatchChange('product', ctid, option);
                  }}
                  options={[...ardoqFieldOptions, ...fieldOptions]}
                />
                <MatchFormRow
                  value={
                    componentType.matching.manufacturer?.fieldName ||
                    componentType.matching.manufacturer?.referenceTypeName ||
                    componentType.matching.manufacturer?.matchBy
                  }
                  itPediaFieldName="Manufacturer"
                  onChange={val => {
                    dispatchAction(
                      trackITPediaEvent({
                        name: 'SELECTED_FIELDS',
                        metadata: { name: 'Selected manufacturer' },
                      })
                    );
                    onMatchChange('manufacturer', ctid, val);
                  }}
                  options={[
                    ...ardoqFieldOptions,
                    ...referenceOptions,
                    ...fieldOptions,
                  ]}
                />
                <MatchFormRow
                  value={
                    componentType.matching.version?.fieldName ||
                    componentType.matching.version?.referenceTypeName ||
                    componentType.matching.version?.matchBy
                  }
                  isOptional={true}
                  itPediaFieldName="Version"
                  onChange={val => {
                    dispatchAction(
                      trackITPediaEvent({
                        name: 'SELECTED_FIELDS',
                        metadata: { name: 'Selected version' },
                      })
                    );
                    onMatchChange('version', ctid, val);
                  }}
                  options={[...ardoqFieldOptions, ...fieldOptions]}
                />
              </Grid>
            </FlexBox>
            <FlexBox gap="medium" flex={1}>
              <InfoContainer $isVertical $gap="s16">
                <Header4 $align="center">How does it work?</Header4>
                <div>
                  In order to enrich the &apos;Microsoft Windows 11.4&apos;
                  application component in Ardoq, you should match the following
                  fields: &apos;Component name: Windows 11 (Ardoq field)&apos;
                  to &apos;Product name (IT-Pedia field)&apos; and &apos;Vendor:
                  Microsoft (Ardoq field)&apos; to &apos;Manufacturer (IT-Pedia
                  field)&apos;.
                </div>
              </InfoContainer>
            </FlexBox>
          </FlexBox>
        </Island>
      ))}
      <Space $justify="end">
        <PrimaryButton onClick={onNext} isDisabled={!isValid}>
          Next: Map fields
        </PrimaryButton>
      </Space>
    </Island>
  );
}

export const Step2 = connect(
  Step2Component,
  configurationState$.pipe(
    switchMap(config => {
      return getFields(config.workspaces.products.id || '');
    })
  )
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content minmax(200px, auto) min-content minmax(
      200px,
      auto
    );
  text-overflow: nowrap;
  gap: ${s16} ${s32};
  align-items: center;
`;

const GridDivider = styled.hr`
  grid-column: span 4;
  border: 0;
  border-top: 1px solid ${colors.borderSubtle00};
  width: 100%;
  margin: 0;
`;

const InfoContainer = styled(Space)`
  background-color: ${colors.grey95};
  padding: ${s16};
  border-radius: ${r8};
`;

const Header4 = styled(Space)`
  ${header4};
`;

const ReferenceRightContentContainer = styled(Space)`
  color: ${colors.grey50};
`;
