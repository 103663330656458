type FlatTree = Record<string, string[]>;

/*
 * Given a set of ids and a get children function
 * it returns a flat tree in the format Record<parentId, childIds[]>
 */
export const getDescendantsAsFlatTree = (
  getChildren: (id: string) => string[],
  ids: string[],
  depth = 0,
  maxDepth = 10
): FlatTree =>
  ids.reduce((nodes, id) => {
    const childIds = depth === maxDepth ? [] : getChildren(id);
    const childs = getDescendantsAsFlatTree(getChildren, childIds, depth + 1);
    return { ...nodes, ...childs, [id]: childIds };
  }, {});

/*
 * Given two flat trees
 * it returns a tree with unioned values of same keys
 */
const merge = (one: FlatTree, two: FlatTree) =>
  Object.entries(one).reduce(
    (acc, [id, items]) => ({
      ...acc,
      [id]: [...new Set([...(acc[id] || []), ...items])],
    }),
    two
  );

/*
 * Given a set of ids and a get parent function
 * it returns a flat tree in the format Record<parentId, childIds[]>
 * assumed single parent
 */
export const getAncestorsAsFlatTree = (
  getParentId: (id: string) => string | null,
  ids: string[]
): FlatTree =>
  ids.reduce<FlatTree>((nodes, id) => {
    const parentId = getParentId(id);
    if (!parentId) return nodes;
    const upperLevelNodes = getAncestorsAsFlatTree(getParentId, [parentId]);
    const all = merge(upperLevelNodes, nodes);
    return {
      ...all,
      [parentId]: [...(all[parentId] || []), id],
      [id]: [...(all[id] || [])],
    };
  }, {});

/*
 * Runs up the tree for all ids and looks for the parents
 * returns a list of all found parents together with the start ids
 */
export const getAncestorsAsList = (
  getParentId: (id: string) => string | null,
  ids: string[]
): string[] =>
  ids.reduce<string[]>((acc, id) => {
    const parentId = getParentId(id);
    if (!parentId) return [...acc, id];
    return [...acc, parentId, ...getAncestorsAsList(getParentId, [parentId])];
  }, ids);

/*
 * Runs down the tree for all ids and looks for the children
 * returns a list of all found children together with the start ids
 */
export const getDescendantsAsList = (
  getChildren: (id: string) => string[],
  ids: string[],
  depth = 0,
  maxDepth = 10
): string[] =>
  ids.reduce<string[]>((acc, id) => {
    const childIds = depth === maxDepth ? [] : getChildren(id);
    const childs = getDescendantsAsList(getChildren, childIds, depth + 1);
    return [...acc, ...childs];
  }, ids);
