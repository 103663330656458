import { colors, s8 } from '@ardoq/design-tokens';
import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

type Status = 'pending' | 'approved' | 'rejected';

type ChangeStatusDecoratorProps = {
  status?: Status;
};

const getStatusCSS = (status?: Status) => {
  switch (status) {
    case 'pending':
      return css`
        background-color: ${colors.blue95};
        border-left: 4px solid ${colors.blue50};
      `;
    case 'approved':
      return css`
        background-color: ${colors.green95};
        border-left: 4px solid ${colors.green50};
      `;
    case 'rejected':
      return css`
        background-color: ${colors.red95};
        border-left: 4px solid ${colors.red50};
        text-decoration: 'line-through';
      `;
    default:
      return css`
        background-color: ${colors.grey95};
        border-left: 4px solid ${colors.grey50};
      `;
  }
};

const Wrapper = styled.div<{ $status?: Status }>`
  padding-left: ${s8};
  align-items: center;
  display: flex;
  height: 100%;
  ${({ $status }) => getStatusCSS($status)}
`;

export const ChangeStatusDecorator = ({
  status,
  children,
}: PropsWithChildren<ChangeStatusDecoratorProps>) => {
  return <Wrapper $status={status}>{children}</Wrapper>;
};
