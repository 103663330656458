import { persistentReducedStream } from '@ardoq/rxbeach';
import { getDefaultState } from './utils';
import { reducers } from './reducers';

const emailAudienceDialog$ = persistentReducedStream(
  'emailAudienceDialog$',
  getDefaultState(),
  reducers
);

export default emailAudienceDialog$;
