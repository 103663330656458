import { AiSectionFeatures, FeatureSettingsCommands } from './types';
import { IconName } from '@ardoq/icons';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { Link } from '@ardoq/typography';
import { FeatureToggle } from './FeatureToggle';
import BestPracticeAssistantChatImage from './images/bestPracticeAssistantChat.png';
import SurveysAutomaticComponentDescriptionsImage from './images/surveysAutomaticComponentDescriptions.png';
import ViewInferenceImage from './images/viewInference.png';
import IntelligentDataEnrichmentImage from './images/intelligentDataEnrichment.png';
import { Island } from '@ardoq/page-layout';

const AI_FEATURES_DISABLED_MESSAGE =
  'To activate this feature, your agreement may need an update. Please contact support or your customer success manager to approve these changes.';

export const AIFeaturesSection = ({
  isDisabled,
  isUpdating,
  aiFeatures,
  commands,
}: {
  isDisabled: boolean;
  isUpdating: boolean;
  aiFeatures: AiSectionFeatures;
  commands: FeatureSettingsCommands;
}) => {
  const {
    azureAutoCompleteOpenai,
    azureOpenaiBestPracticeAssistant,
    azureOpenAIViewInference,
    azureOpenAIComponentLinkingSuggestions,
  } = aiFeatures;

  const features = [
    {
      name: 'Best practice assistant',
      description:
        'Get answers to modeling best practice questions with the AI-driven best practice assistant chatbot.',
      isBeta: false,
      feature: azureOpenaiBestPracticeAssistant,
      image: BestPracticeAssistantChatImage,
    },
    {
      name: 'Automatic component descriptions',
      description:
        'Use generative AI to automatically create component descriptions.',
      isBeta: false,
      feature: azureAutoCompleteOpenai,
      image: SurveysAutomaticComponentDescriptionsImage,
    },
    {
      name: 'Add new dataset with AI',
      description:
        'Use AI to generate a new dataset. Describe what you want to see or select an AI-generated suggestion.',
      isBeta: true,
      feature: azureOpenAIViewInference,
      image: ViewInferenceImage,
    },
    {
      name: 'Intelligent data enrichment',
      description: (
        <>
          Leverage{' '}
          <Link
            type="primary"
            hideIcon
            typography="text1"
            href="https://help.ardoq.com/en/articles/212247-create-references-with-the-help-of-ai"
          >
            AI-driven suggestions
          </Link>{' '}
          to enrich your data, accelerate data creation, and improve accuracy by
          ensuring seamless connections between components.
        </>
      ),
      isBeta: false,
      feature: azureOpenAIComponentLinkingSuggestions,
      image: IntelligentDataEnrichmentImage,
    },
  ];

  return (
    <Island
      title="AI Features"
      iconName={isUpdating ? IconName.SPINNER : undefined}
      subtitle={
        <p>
          Activate Ardoq{`'`}s AI features to work faster. Learn more about{' '}
          <Link
            href={KnowledgeBaseLink.AI}
            target="_blank"
            typography="caption"
          >
            AI features in Ardoq.
          </Link>
        </p>
      }
      subtitleWidth="large"
    >
      {features.map(({ name, description, isBeta, feature, image }) => {
        if (feature.isHidden) return null;

        return (
          <FeatureToggle
            key={feature.persistedId}
            name={name}
            description={description}
            isChecked={feature.isChecked}
            isDisabled={feature.isDisabled}
            isBeta={isBeta}
            helperText={isDisabled ? AI_FEATURES_DISABLED_MESSAGE : undefined}
            onChange={isChecked =>
              commands.setFeatureValue({
                featureIds: [feature.persistedId],
                value: isChecked,
              })
            }
            imageSrc={image}
          />
        );
      })}
    </Island>
  );
};
