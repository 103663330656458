import {
  action$,
  dispatchAction,
  extractPayload,
  ofType,
  withNamespace,
} from '@ardoq/rxbeach';
import { NavigatorConfiguration, NavigatorLayoutState } from '../types';
import {
  draggableElementClicked,
  dragEnd,
  dragStart,
  dragUpdate,
} from '../actions/navigatorActions';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { logError } from '@ardoq/logging';
import { startScrollHandler } from '../scrollHandler';
import { catchErrorLogWithMessageAndContinue } from 'streams/utils/streamOperators';
import { identity } from 'lodash';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

export const getStartDragAndDropRoutine = (
  navigator$: Observable<NavigatorLayoutState>,
  { actionNamespace }: NavigatorConfiguration
) =>
  action$.pipe(
    actionNamespace ? withNamespace(actionNamespace) : identity,
    ofType(draggableElementClicked),
    extractPayload(),
    withLatestFrom(navigator$),
    tap(
      ([
        { event, target },
        { tree, container, isLinking, activeScenarioState },
      ]) => {
        if (
          // drag & drop should be disabled in scenario mode
          activeScenarioOperations.isInScenarioMode(activeScenarioState) ||
          !(target && container && !isLinking)
        )
          return;

        const node = tree.getNodeByDomNode(target);
        if (!node) {
          logError(
            Error(
              'Could not find navigatorStore$.state.tree node corresponding to DOM node'
            )
          );
          return;
        }
        if (!node.hasWriteAccess || node.getLock()) return;

        event.preventDefault();
        event.stopPropagation();

        const abortController = new AbortController();
        document.addEventListener(
          'mousemove',
          event => dispatchAction(dragUpdate({ event }), actionNamespace),
          { signal: abortController.signal }
        );
        document.addEventListener(
          'mouseup',
          event => {
            abortController.abort();
            dispatchAction(
              dragEnd({ event, isAborted: false }),
              actionNamespace
            );
          },
          { signal: abortController.signal }
        );
        document.addEventListener(
          'keydown',
          event => {
            if (event.key === 'Escape') {
              abortController.abort();
              dispatchAction(
                dragEnd({ event, isAborted: true }),
                actionNamespace
              );
            }
          },
          { signal: abortController.signal, capture: true }
        );
        startScrollHandler(abortController.signal, container);
        setTimeout(() => container.focus(), 16);

        const dragStartPosition = {
          x: event.clientX,
          y: event.clientY,
        };
        dispatchAction(
          dragStart({
            dragTargetNode: node,
            dragStartPosition,
          }),
          actionNamespace
        );
      }
    ),
    catchErrorLogWithMessageAndContinue('Error in startDragAndDropRoutine')
  );
