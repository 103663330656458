import { colors } from '@ardoq/design-tokens';
import { ModalLayout, ModalTemplate } from '@ardoq/modal';
import { StatusType, Tag } from '@ardoq/status-ui';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { TableStatus } from 'integrations/common/streams/tabularMappings/types';
import styled from 'styled-components';
import { viewModel$ } from './viewModel$';
import { connect } from '@ardoq/rxbeach';
import { OverviewDataSource } from './types';
import { DryRunStatus } from 'integrations/common/streams/transferState/types';

type OverviewModalProps = {
  onClose: () => void;
  integrationName: string;
  dataSource: OverviewDataSource;
};

function OverviewModal({
  onClose,
  integrationName,
  dataSource,
}: OverviewModalProps) {
  return (
    <ModalTemplate
      headerText={`Overview: ${integrationName}`}
      modalSize={3}
      onCloseButtonClick={onClose}
      onPrimaryButtonClick={onClose}
      primaryButtonText="Close"
      primaryButtonClickId="overview-close"
    >
      <ModalLayout>
        <DatasourceTable
          components={newTableTheme}
          dataSource={dataSource}
          columns={[
            {
              title: 'Worksheet',
              dataIndex: 'worksheet',
            },
            {
              title: 'Mapped To',
              dataIndex: 'mappedTo',
              valueRender: mappedTo =>
                !mappedTo ? (
                  <NotMappedText>Not mapped</NotMappedText>
                ) : (
                  mappedTo
                ),
            },
            {
              title: 'Workspace',
              dataIndex: 'workspace',
            },
            {
              title: 'Test status',
              dataIndex: 'tableStatus',
              valueRender: (tableStatus: TableStatus) => {
                switch (tableStatus) {
                  case DryRunStatus.ERROR:
                    return <Tag label="Error" statusType={StatusType.ERROR} />;
                  case DryRunStatus.WARNING:
                    return (
                      <Tag label="Warning" statusType={StatusType.WARNING} />
                    );
                  case DryRunStatus.SUCCESS:
                    return (
                      <Tag label="Complete" statusType={StatusType.SUCCESS} />
                    );
                  default:
                    return (
                      <Tag label="Not tested" statusType={StatusType.DEFAULT} />
                    );
                }
              },
            },
          ]}
        />
      </ModalLayout>
    </ModalTemplate>
  );
}

const NotMappedText = styled.div`
  color: ${colors.grey60};
  font-style: italic;
`;

export default connect(OverviewModal, viewModel$);
