import { setContextToEntity } from 'search/searchUtil/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  GremlinComponentResult,
  GremlinParentReferenceResult,
  GremlinReferenceResult,
  GremlinResult,
  GremlinResultType,
} from './types';
import { LazyLoadResultsParams } from '@ardoq/pagination';
import { GremlinSearchQuery, SearchQuery, SearchType } from '@ardoq/api-types';
import { QueryEditorStateShape } from 'search/QueryEditor/queryEditor$';
import { queryGremlinSearch } from '../actions';
import { RESULTS_PER_PAGE } from './consts';
import { formatDateTime, isISO8601DateTime } from '@ardoq/date-time';
import { trackEvent } from 'tracking/tracking';
import { getCurrentLocale } from '@ardoq/locale';
import { jsonModal } from 'jsonModal';
import { dateRangeOperations } from '@ardoq/date-range';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { componentApi, referenceApi } from '@ardoq/api';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';

/* Type guard predicate */
function isReferenceResult(
  result: GremlinReferenceResult | GremlinComponentResult
): result is GremlinReferenceResult {
  return getResultType(result) === GremlinResultType.REFERENCE;
}

export const isComponentResult = (
  result:
    | GremlinComponentResult
    | GremlinReferenceResult
    | GremlinParentReferenceResult
): result is GremlinComponentResult => {
  return getResultType(result) === GremlinResultType.COMPONENT;
};

const getWorkspaceIdFromEntity = (
  result: GremlinReferenceResult | GremlinComponentResult
) => {
  if (isReferenceResult(result)) return result.properties.rootWorkspace;
  return result.properties.rootWorkspace[0].value;
};

export const navigateToEntity = (
  result: GremlinReferenceResult | GremlinComponentResult
) => {
  const workspaceId = getWorkspaceIdFromEntity(result);
  dispatchAction(setContextToEntity({ id: result.id, workspaceId }));
};

export const getResultType = (result: GremlinResult): GremlinResultType => {
  if (result === undefined || result === null) return GremlinResultType.MAP;
  if (typeof result !== 'object') return GremlinResultType.DIRECT;
  if (result.type === 'vertex') return GremlinResultType.COMPONENT;
  if (result.type === 'edge') return GremlinResultType.REFERENCE;
  if (result.labels) return GremlinResultType.PATH;
  return GremlinResultType.MAP;
};

export const formatValue = (value: string | boolean) => {
  const locale = getCurrentLocale();

  return typeof value === 'boolean'
    ? value.toString()
    : isISO8601DateTime(value)
      ? formatDateTime(value, locale)
      : value;
};

export const showDetails = async (
  result: GremlinResult,
  resultType: GremlinResultType
) => {
  trackEvent('openedLegacyView', { legacyViewName: 'jsonPreviewModal' });
  const { id } = result;
  if (
    resultType === GremlinResultType.COMPONENT ||
    resultType === GremlinResultType.REFERENCE
  ) {
    const json = await (resultType === GremlinResultType.COMPONENT
      ? componentApi.fetch(id)
      : referenceApi.fetch(id));
    if (isArdoqError(json)) {
      return jsonModal({ json: { error: getArdoqErrorMessage(json) } });
    }

    const dateRangeFieldMap =
      dateRangeOperations.getDateRangeFieldsGroupedByModelMap(
        fieldInterface.getAllFieldsOfWorkspace(json.rootWorkspace)
      );
    return jsonModal({
      json: dateRangeOperations.mergeDateRangeFieldsForEntity(
        json,
        dateRangeFieldMap
      ),
    });
  }
  return jsonModal({ json: result });
};

type LoadPaginatedGremlinResultsParams = LazyLoadResultsParams & {
  query: SearchQuery;
  queryParams: QueryEditorStateShape['queryParams'];
  searchType: SearchType;
};
export const loadPaginatedGremlinResults = ({
  pageSize = RESULTS_PER_PAGE,
  startIndex,
  sortById,
  sortBy,
  query,
  queryParams,
  searchType,
}: LoadPaginatedGremlinResultsParams) =>
  dispatchAction(
    queryGremlinSearch({
      query: query as GremlinSearchQuery,
      queryParams: {
        ...queryParams,
        size: pageSize,
        from: startIndex,
        ...(sortById ? { sort: sortById } : {}),
        ...(sortBy ? { order: sortBy } : {}),
      },
    }),
    searchType
  );
