import { actionCreator } from '@ardoq/rxbeach';
import {
  APIServiceAccount,
  APIToken,
  CreateServiceAccountAndTokenPayload,
} from '@ardoq/api-types';

const DOMAIN = '[serviceAccounts]';

export const receivedAPIServiceAccounts = actionCreator<APIServiceAccount[]>(
  `${DOMAIN} RECEIVED_API_SERVICE_ACCOUNTS`
);

export const getServiceAccounts = actionCreator(
  `${DOMAIN} GET_SERVICE_ACCOUNTS`
);

export const createServiceAccount =
  actionCreator<CreateServiceAccountAndTokenPayload>(
    `${DOMAIN} CREATE_SERVICE_ACCOUNT`
  );

export const serviceAccountCreated = actionCreator<APIServiceAccount>(
  `${DOMAIN} SERVICE_ACCOUNT_CREATED`
);

export const createServiceAccountFailed = actionCreator(
  `${DOMAIN} CREATE_SERVICE_ACCOUNT_FAILED`
);

export const regenerateToken = actionCreator<APIToken>(
  `${DOMAIN} REGENERATE_TOKEN`
);

export const tokenRegenerated = actionCreator<APIToken>(
  `${DOMAIN} TOKEN_REGENERATED`
);

export const regenerateTokenFailed = actionCreator(
  `${DOMAIN} REGENERATE_TOKEN_FAILED`
);

export const deleteAccount = actionCreator<string>(`${DOMAIN} DELETE_ACCOUNT`);

export const accountDeleted = actionCreator<string>(
  `${DOMAIN} ACCOUNT_DELETED`
);

export const setError = actionCreator<string | null>(`${DOMAIN} SET_ERROR`);

export const updateSearchKey = actionCreator<string>(
  `${DOMAIN} UPDATE_SEARCH_KEY`
);
