import { ViewpointBuilderContext } from '../types';
import { ComponentTypeProps } from '../components/ComponentType';
import { IconName } from '@ardoq/icons';

export type ViewpointBuilderTab =
  | 'SELECT_CONTEXT_COMPONENT_INSTANCES_TAB'
  | 'SELECT_CONTEXT_COMPONENT_TYPE_TAB'
  | 'SUBGRAPH_TAB'
  | 'COLLAPSE_PATHS_TAB'
  | 'REQUIRED_COMPONENTS_TAB'
  | 'FILTERS_TAB'
  | 'GROUPS_TAB'
  | 'FORMATTING_TAB'
  | 'LABELS_TAB'
  | 'META_INFO_TAB'
  | 'METAMODEL_TAB';

/**
 * We provide simplified navigation mode if user is just exploring the Viewpoint builder to open dataset so that we don't overwhelm them with options.
 * If user is editing existing viewpoint, we provide more detailed navigation options.
 */
export type NavigationMode = 'simplified' | 'advanced';

export type ViewpointBuilderNavigationState = {
  selectedTab: ViewpointBuilderTab;
  context: ViewpointBuilderContext;
  selectedComponentTypes: string[];
  selectedComponentCount: number;
  filtersCount: number;
  edgesCount: number;
  nodesCount: number;
  selectedComponentTypeRepresentation: ComponentTypeProps | null;
  conditionalFormattingRulesCount: number;
  labelFormattingRulesCount: number;
  groupingRulesCount: number;
  collapsedPathsCount: number;
  requiredComponentsCount: number;
  viewpointName: string | null;
};

export type ViewpointBuilderNavigationViewModel =
  ViewpointBuilderNavigationState & {
    tabItems: ViewpointBuilderTabItem[];
    bottomItem: ViewpointBuilderTabItem;
    sidebarTitle: string;
    sidebarSubtitle?: string;
    navigationMode: NavigationMode;
  };

export type ViewpointBuilderTabItemMeta = {
  tabId: ViewpointBuilderTab;
  label: string;
  icon: IconName;
  description: string;
};

export type ViewpointBuilderTabItem = ViewpointBuilderTabItemMeta & {
  isSelected: boolean;
  isDisabled: boolean;
  popoverContentForDisabledItem: string | undefined;
  tags: TagsMetaInfo;
};

export enum TagMetaInfoType {
  TAG = 'tag',
  COMPONENT_TYPE_REPRESENTATION = 'componentTypeRepresentation',
}

export type CountTagMetaInfo = {
  count: number;
  type: TagMetaInfoType.TAG;
  iconName?: IconName;
};

export type ComponentTypeRepresentationMetaInfo = ComponentTypeProps & {
  type: TagMetaInfoType.COMPONENT_TYPE_REPRESENTATION;
};

export type TagsMetaInfo = (
  | CountTagMetaInfo
  | ComponentTypeRepresentationMetaInfo
)[];
