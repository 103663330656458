import { useState } from 'react';
import { InfoNotification } from '@ardoq/status-ui';
import { SmallGhostButton } from '@ardoq/button';
import { RefreshIcon } from '@ardoq/icons';
import { fetchAuditLogData } from './actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { FlexBox } from '@ardoq/layout';

const RefreshNotification = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <InfoNotification>
      <FlexBox align="center" justify="space-between">
        Refresh to see the latest changes
        <SmallGhostButton
          isLoading={isLoading}
          onClick={() => {
            setIsLoading(true);
            dispatchAction(fetchAuditLogData(null));
          }}
        >
          <RefreshIcon /> Refresh
        </SmallGhostButton>
      </FlexBox>
    </InfoNotification>
  );
};

export default RefreshNotification;
