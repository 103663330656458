import { CreateNewTraversalButton } from './CreateNewTraversalButton';
import { ViewpointsOverviewCommands } from './viewpointsOverviewCommands';
import { useWindowSize } from '@ardoq/hooks';
import { ViewpointAssetsBrowser } from '../../viewpoints/ViewpointOverview';
import { PageHeader, PageBody, PageWrapper } from '@ardoq/page-layout';

type TraversalOverviewProps = {
  commands: ViewpointsOverviewCommands;
  canUserCreateTraversals: boolean;
  currentUserId: string;
};
const TraversalOverview = ({
  commands,
  canUserCreateTraversals,
  currentUserId,
}: TraversalOverviewProps) => {
  const screenHeight = useWindowSize().height;
  // Needed in order to prevent a scrollbar on the page
  const PIXELS_ABOVE_TABLE = 170;
  return (
    <PageWrapper>
      <PageHeader
        title="Viewpoint overview"
        rightContent={
          <CreateNewTraversalButton
            canUserCreateTraversals={canUserCreateTraversals}
            commands={commands}
          />
        }
      />

      <PageBody backgroundColor="surfaceDefault">
        <ViewpointAssetsBrowser
          height={screenHeight - PIXELS_ABOVE_TABLE}
          currentUserId={currentUserId}
        />
      </PageBody>
    </PageWrapper>
  );
};

export default TraversalOverview;
