import { APIEntityType, Verb } from '@ardoq/api-types';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { DiffTableRowType } from 'components/DiffMergeTable/types';
import { capitalize } from 'utils/stringUtils';

export const getPopoverStringForDisabledCheckbox = ({
  verb,
  entityType,
  mergeDirection,
  rowType,
  hasWritePermission,
}: {
  verb: Verb;
  entityType: APIEntityType;
  mergeDirection: MergeDirection;
  rowType?: DiffTableRowType;
  hasWritePermission?: boolean;
}) => {
  if (hasWritePermission === false) {
    return `You don't have the necessary permissions to merge this into your ${
      mergeDirection === MergeDirection.BRANCH_TO_MAINLINE
        ? 'mainline'
        : 'scenario'
    }. Contact an administrator to change this`;
  } else if (
    verb === Verb.DELETE &&
    (entityType === APIEntityType.COMPONENT_TYPE ||
      entityType === APIEntityType.REFERENCE_TYPE)
  ) {
    return `You can't delete this ${
      entityType === APIEntityType.COMPONENT_TYPE ? 'component' : 'reference'
    } type because it's used in your ${
      mergeDirection === MergeDirection.BRANCH_TO_MAINLINE
        ? 'mainline'
        : 'scenario'
    }`;
  } else if (verb === Verb.CREATE && entityType === APIEntityType.FIELD) {
    return 'You can’t deselect new fields, as they are required later in the merge process';
  } else if (verb === Verb.MERGE) {
    if (
      entityType === APIEntityType.TAG &&
      rowType === DiffTableRowType.SUB_HEADER_ROW
    ) {
      return `The components and/or references that have this tag don't exist in your ${
        mergeDirection === MergeDirection.BRANCH_TO_MAINLINE
          ? 'mainline'
          : 'scenario'
      }`;
    } else if (
      entityType === APIEntityType.COMPONENT ||
      entityType === APIEntityType.REFERENCE
    ) {
      const entityString =
        entityType === APIEntityType.COMPONENT ? 'component' : 'reference';

      return `Before you can tag this ${entityString}, you have to go back to the Create ${capitalize(
        entityString
      )} merge step and create the missing ${entityString}.`;
    }
  }
  return '';
};
