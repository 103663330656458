import { reducer, RegisteredReducer } from '@ardoq/rxbeach';
import {
  setStagedComponentToBeRemoved,
  setSelectedComponentsToAdd,
  advancedSearchChange,
} from './actions';
import { SubdivisionViewModelState } from 'subdivisionEditor/types';
import { ArdoqId, Subdivision } from '@ardoq/api-types';
import { SubdivisionEditorSteps } from 'subdivisionEditor/navigation/types';
import { subdivisionEditorStepsOperations } from 'subdivisionEditor/subdivisionEditorStepsOperations';
import { apiReducers } from './apiReducers';
import { AdvancedSearchOnChangePayload } from 'search/AdvancedSearch/AdvancedSearchQueryBuilder';
import { subdivisionsOperations } from '@ardoq/subdivisions';

const advancedSearchChangeReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  { isEmpty, isValid, query }: AdvancedSearchOnChangePayload
): SubdivisionViewModelState => {
  const advancedSearchQueries = isEmpty ? null : (query?.rules[1] ?? null);

  const partialSubdivision: Partial<Subdivision> = {
    advancedSearchQueries: advancedSearchQueries
      ? [{ query: advancedSearchQueries }]
      : [],
  };

  const newState: SubdivisionViewModelState = {
    ...subdivisionEditorStepsOperations.setStepTouched(
      subdivisionEditorState,
      SubdivisionEditorSteps.COMPONENTS_SELECTION
    ),
    subdivision: subdivisionsOperations.updateSubdivisionProperties(
      subdivisionEditorState.subdivision,
      partialSubdivision
    ),
    componentsSelection: {
      ...subdivisionEditorState.componentsSelection,
      advancedSearchState: {
        ...subdivisionEditorState.componentsSelection.advancedSearchState,
        isEmpty,
        isValid,
      },
    },
  };

  return {
    ...newState,
    stepsErrors:
      subdivisionEditorStepsOperations.getAllStepValidationErrors(newState),
  };
};

const setSelectedComponentsToAddReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  payload: { selectedIds: ArdoqId[] }
): SubdivisionViewModelState => {
  return {
    ...subdivisionEditorState,
    componentsSelection: {
      ...subdivisionEditorState.componentsSelection,
      selectedComponentsToBeAdded: payload.selectedIds,
    },
  };
};

const setStagedComponentToBeRemovedReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  { componentIds }: { componentIds: string[] }
): SubdivisionViewModelState => {
  const newState: SubdivisionViewModelState = {
    ...subdivisionEditorState,
    componentsSelection: {
      ...subdivisionEditorState.componentsSelection,
      selectedComponentsToBeRemoved: componentIds,
    },
  };

  return subdivisionEditorStepsOperations.setStepTouched(
    newState,
    SubdivisionEditorSteps.COMPONENTS_SELECTION
  );
};

export const componentSelectionStepReducers: RegisteredReducer<SubdivisionViewModelState>[] =
  [
    // --------------------- Advanced Search State Reducers ------------------------
    reducer(advancedSearchChange, advancedSearchChangeReducer),

    // --------------------- Selected Components State Reducers ------------------------
    reducer(setSelectedComponentsToAdd, setSelectedComponentsToAddReducer),
    reducer(
      setStagedComponentToBeRemoved,
      setStagedComponentToBeRemovedReducer
    ),
    // --------------------- API Reducers ------------------------
    ...apiReducers,
  ];
