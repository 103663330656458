import {
  ensureContrast,
  getLightenedColor,
  getShadedColor,
} from '@ardoq/color-helpers';
import { ComponentRepresentationData } from '@ardoq/graph';

type ComponentRepresentationDataWithColorVariants =
  ComponentRepresentationData & {
    shadedColor: string;
    lightenedColor: string;
    contrastColor: string;
  };
export const getRepresentationDataWithColorVariants = (
  representationData: ComponentRepresentationData
): ComponentRepresentationDataWithColorVariants => {
  const lightenedColor = getLightenedColor(representationData.color);
  const shadedColor = getShadedColor(representationData.color);
  const contrastColor = ensureContrast(
    lightenedColor,
    representationData.color
  );

  return {
    ...representationData,
    lightenedColor,
    contrastColor,
    shadedColor,
  };
};
