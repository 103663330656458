import { MAX_SCROLL_DELTA, SCROLL_DELTA_TRIGGER } from './utils/consts';

export const startScrollHandler = (
  signal: AbortSignal,
  navigatorContainer: HTMLElement,
  checkStopScrolling: (target: EventTarget | null) => boolean = () => false
) => {
  let scrollDelta: number;
  let scrollInterval: number = 0;

  const scrollContainer = () => (navigatorContainer.scrollTop += scrollDelta);

  const setScrollInterval = () => {
    if (scrollInterval === 0) {
      scrollInterval = window.setInterval(scrollContainer, 16);
    }
  };

  const stopScrolling = () => {
    if (scrollInterval) {
      window.clearInterval(scrollInterval);
      scrollInterval = 0;
    }
  };

  signal.addEventListener('abort', stopScrolling);
  document.addEventListener(
    'mousemove',
    ({ clientX: x, clientY: y, target }: MouseEvent) => {
      scrollDelta = getScrollDelta(navigatorContainer, x, y);
      if (scrollDelta === 0 || checkStopScrolling(target)) {
        stopScrolling();
      } else {
        setScrollInterval();
      }
    },
    { signal }
  );
};

const getScrollDelta = (
  navigatorContainer: HTMLElement,
  x: number,
  y: number
) => {
  const { left, top, right, bottom } =
    navigatorContainer.getBoundingClientRect();

  if (x < left || x > right) {
    return 0;
  }
  if (y > bottom - SCROLL_DELTA_TRIGGER) {
    return Math.min(MAX_SCROLL_DELTA, y - (bottom - SCROLL_DELTA_TRIGGER));
  }
  if (y < top + SCROLL_DELTA_TRIGGER) {
    return Math.max(-MAX_SCROLL_DELTA, y - (top + SCROLL_DELTA_TRIGGER));
  }
  return 0;
};
