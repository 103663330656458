import { connect } from '@ardoq/rxbeach';
import { combineLatest, map } from 'rxjs';
import { ConfigurationState } from 'integrations/ITPedia/streams/types';
import { configurationState$ } from 'integrations/ITPedia/streams/configurationState/configurationState$';
import { isFieldMappingValid } from './utils';
import { Step1 } from './Step1/Step1';
import { Step2 } from './Step2/Step2';
import { StepContainer } from '../components/StepContainer';
import { IntegrationWorkspace } from '@ardoq/api-types/integrations';
import { workspaceOptions$ } from 'integrations/ITPedia/streams/workspaces/workspaces$';

type FieldMappingParams = {
  workspaceId?: string | null;
  selectedComponentTypes?: ConfigurationState['componentTypes'];
  vulnerabilitiesWorkspace: IntegrationWorkspace;
  workspaces: IntegrationWorkspace[];
  isValid: boolean;
};

function FieldMappingComponent({
  workspaceId,
  selectedComponentTypes = {},
  vulnerabilitiesWorkspace,
  isValid,
  workspaces,
}: FieldMappingParams) {
  return (
    <StepContainer $isVertical>
      <Step1
        selectedComponentTypes={selectedComponentTypes}
        workspaceId={workspaceId}
      />
      <Step2
        workspaces={workspaces}
        vulnerabilitiesWorkspace={vulnerabilitiesWorkspace}
        isValid={isValid}
      />
    </StepContainer>
  );
}

const viewModel$ = combineLatest([configurationState$, workspaceOptions$]).pipe(
  map(([configurationState, workspaceOptions]) => ({
    workspaceId: configurationState.workspaces.products.id,
    selectedComponentTypes: configurationState.componentTypes,
    vulnerabilitiesWorkspace: configurationState.workspaces.vulnerabilities,
    isValid: isFieldMappingValid(
      configurationState.componentTypes,
      configurationState.workspaces.vulnerabilities.name
    ),
    workspaces: workspaceOptions,
  }))
);

export const FieldMapping = connect(FieldMappingComponent, viewModel$);
