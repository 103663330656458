import viewpointsNavigation$ from 'viewpoints/navigation/viewpointsNavigation$';
import Viewpoints from 'viewpoints/Viewpoints';
import { connect } from '@ardoq/rxbeach';
import ModuleContainer from './ModuleContainer';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';

const ViewpointsContainer = () => (
  <ModuleContainer $overflow="hidden">
    <ErrorBoundary logError={logError}>
      <Viewpoints />
    </ErrorBoundary>
  </ModuleContainer>
);

export default connect(ViewpointsContainer, viewpointsNavigation$);
