import { LabeledValue } from 'aqTypes';
import { ArdoqId } from '@ardoq/api-types';
import { Select } from '@ardoq/select';
import { caseInsensitiveStringIncludes } from '@ardoq/common-helpers';

export type SurveyOption = LabeledValue<ArdoqId> & {
  readonly isDisabled?: boolean;
};

type SurveySelectProps = {
  readonly surveyOptions: [
    { label: string; options: SurveyOption[] },
    { label: string; options: SurveyOption[] },
  ];
  readonly selectedOption: SurveyOption | null;
  readonly onChange: (selectedOption: SurveyOption) => void;
  readonly required: boolean;
};

export const SurveySelect = ({
  surveyOptions,
  selectedOption,
  onChange,
  required,
}: SurveySelectProps) => {
  const hasError = required && !selectedOption;
  return (
    <Select
      dataTestId="survey-select"
      errorMessage={hasError ? 'Required field' : undefined}
      placeholder="Select a survey"
      options={surveyOptions}
      value={selectedOption}
      onChange={option => onChange(option as SurveyOption)}
      // we have to define custom filter, because `getOptionLabel` doesn't
      // return string anymore, so default filter doesn't work
      filterOption={(
        { data: { label = '' } }: { data: { label: string } },
        searchPhrase = ''
      ) => caseInsensitiveStringIncludes(label, searchPhrase)}
    />
  );
};
