const StepsSvgDefinitions = () => {
  return (
    <svg style={{ display: 'none' }}>
      <defs>
        <path
          id="halfPiece"
          d="M-11,0 a0.22,0.22 0 0,0 22,0"
          x="0"
          y="0"
          stroke="#fff"
          strokeWidth="2"
        />
        <path
          id="oneThirdPiece"
          d="M 0 0 L -8.66 -5 A 10 10 24 0 1 8.66 -5 Z"
          x="0"
          y="0"
          stroke="#fff"
          strokeWidth="2"
        />
        <path
          id="oneFourthPiece"
          d="M0 0-7-7A10 10 0 0 1 7-7Z"
          x="0"
          y="0"
          stroke="#fff"
          strokeWidth="2"
        />
        <circle
          id="onePiece"
          cx="0"
          cy="0"
          r="10"
          stroke="#fff"
          strokeWidth="2"
        />
      </defs>
    </svg>
  );
};

export default StepsSvgDefinitions;
