import { withOnFirstSubscription } from 'streams/utils/streamUtils';
import { ViewpointBuilderTab } from 'viewpointBuilder/viewpointBuilderNavigation/types';
import {
  InitialViewpointBuilderConfiguration,
  ViewpointBuilderCommands,
  ViewpointBuilderContext,
} from 'viewpointBuilder/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { requestLoadMetaModel } from 'architectureModel/actions';
import { initViewpointBuilder } from '../initViewpointBuilder';
import { viewpointBuilder$ } from './viewpointBuilder$';
import { metaModelOperations } from 'architectureModel/metaModelOperations';

export type GetViewpointBuilderStreamArgs = {
  activeTab: ViewpointBuilderTab;
  context: ViewpointBuilderContext;
  initialConfiguration: InitialViewpointBuilderConfiguration | undefined;
  viewpointBuilderCommands: ViewpointBuilderCommands;
  folderId?: string | null;
};

export const getViewpointBuilderStream = ({
  activeTab,
  context,
  initialConfiguration,
  folderId,
  viewpointBuilderCommands,
}: GetViewpointBuilderStreamArgs) =>
  withOnFirstSubscription(viewpointBuilder$, () => {
    dispatchAction(
      requestLoadMetaModel({
        currentTriples: metaModelOperations.getTriplesFromPaths(
          initialConfiguration?.initialPaths ?? []
        ),
      })
    );
    initViewpointBuilder({
      activeTab,
      context,
      initialConfiguration,
      folderId,
      viewpointBuilderCommands,
    });
  });
