import { tap } from 'rxjs/operators';
import {
  getDeletedEntitiesByWorkspaceId,
  getDeletedEntitiesByWorkspaceIdError,
  getDeletedEntitiesByWorkspaceIdSuccess,
  loadingEntitiesStart,
} from './actions';
import {
  dispatchAction,
  type ExtractPayload,
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { restoreComponentRoutines } from './component.routines';
import { restoreReferenceRoutines } from './reference.routines';
import type { APIDeletedComponentAttributes, ArdoqId } from '@ardoq/api-types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { DeletedComponent } from './types';
import { restoreApi } from '@ardoq/api';
import { restoreDeletedView } from './restoreDeletedView';
import { isArdoqError } from '@ardoq/common-helpers';
import { restoreDeleted } from 'menus/optionsMenu/workspace/actions';

const withWorkspaceId =
  (workspaceId: ArdoqId) =>
  <T>(entity: T) => ({
    ...entity,
    workspaceId: workspaceId,
  });

const withParentName =
  (components: APIDeletedComponentAttributes[]) =>
  <T extends APIDeletedComponentAttributes>(component: T) => {
    const parentName = component.ancestors.reduce(
      (foundName: ArdoqId | null, nextAncestor) =>
        nextAncestor === component.parent
          ? ((componentInterface.getDisplayName(nextAncestor) ||
              components.find(({ _id }) => _id === nextAncestor)?.name) ??
            null)
          : foundName,
      null
    );
    return { ...component, parentName };
  };

const withDescendantNames =
  (components: APIDeletedComponentAttributes[]) =>
  <T extends APIDeletedComponentAttributes>(component: T) => ({
    ...component,
    descendantNames: component.descendants.map(
      descendantId =>
        (componentInterface.getDisplayName(descendantId) ||
          components.find(({ _id }) => _id === descendantId)?.name) ??
        null
    ),
  });

const handleGetDeletedEntitiesByWorkspaceId = async ({
  workspaceId,
}: ExtractPayload<typeof getDeletedEntitiesByWorkspaceId>) => {
  let hasFetched = false;
  setTimeout(() => {
    if (!hasFetched) {
      dispatchAction(loadingEntitiesStart());
    }
  }, 200);
  const data = await restoreApi.workspace(workspaceId);
  if (isArdoqError(data)) {
    dispatchAction(
      getDeletedEntitiesByWorkspaceIdError({
        retryAction: () =>
          handleGetDeletedEntitiesByWorkspaceId({ workspaceId }),
      })
    );
    return;
  }
  const components: DeletedComponent[] = data.components
    .map(withWorkspaceId(workspaceId))
    .map(withParentName(data.components))
    .map(withDescendantNames(data.components));
  const references = data.references.map(withWorkspaceId(workspaceId));
  dispatchAction(
    getDeletedEntitiesByWorkspaceIdSuccess({ components, references })
  );
  hasFetched = true;
};

const handleGetDeletedEntities = routine(
  ofType(getDeletedEntitiesByWorkspaceId),
  extractPayload(),
  tap(handleGetDeletedEntitiesByWorkspaceId)
);

const handleOpenDialog = routine(
  ofType(restoreDeleted),
  extractPayload(),
  tap(restoreDeletedView)
);

export const restoreDeletedRoutines = collectRoutines(
  restoreReferenceRoutines,
  restoreComponentRoutines,
  handleOpenDialog,
  handleGetDeletedEntities
);
