import styled from 'styled-components';
import { ICON_SELECTOR, IconName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { ButtonGroup, IconButton } from '@ardoq/button';
import { ColorBarBigButton } from '@ardoq/snowflakes';
import { ColorBar } from '@ardoq/page-layout';
import { IslandContainer, IslandHeader } from '@ardoq/page-layout';
import { WithPopover } from '@ardoq/popovers';
import { WithPointerEvents } from '@ardoq/style-helpers';

const StyledIconButton = styled(IconButton)`
  ${ICON_SELECTOR} {
    color: ${colors.blue50};
  }
`;

type QuestionButtonConfig = {
  label: string;
  onClick: () => void;
  dataTestId: string;
  color: string;
  isDisabled?: boolean;
  popoverContent?: string;
};

type QuestionTypeButtonsProps = {
  buttonConfig: QuestionButtonConfig[];
  onCancelButtonClick: () => void;
};

const QuestionTypeButtons = ({
  buttonConfig,
  onCancelButtonClick,
}: QuestionTypeButtonsProps) => {
  return (
    <IslandContainer>
      <IslandHeader>
        <ButtonGroup>
          <StyledIconButton
            iconName={IconName.CLOSE}
            data-tooltip-text="Cancel"
            dataTestId="cancel-button"
            onClick={onCancelButtonClick}
          />
          {buttonConfig.map((button, i) => (
            <WithPopover key={i} content={button.popoverContent}>
              {/* popover wont apply without this */}
              <WithPointerEvents>
                <ColorBarBigButton
                  onClick={button.onClick}
                  dataTestId={button.dataTestId}
                  isDisabled={button?.isDisabled}
                >
                  <ColorBar $barColor={button.color} />
                  {button.label}
                </ColorBarBigButton>
              </WithPointerEvents>
            </WithPopover>
          ))}
        </ButtonGroup>
      </IslandHeader>
    </IslandContainer>
  );
};

export default QuestionTypeButtons;
