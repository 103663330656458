import {
  DatasourceTable,
  newTableTheme,
  SelectableDatasourceTable,
} from '@ardoq/table';
import { ManageMembershipModalProps, ManageMembershipTableData } from './types';
import { ArdoqId } from '@ardoq/api-types';
import styled from 'styled-components';
import { colors, s4, s8 } from '@ardoq/design-tokens';
import { useState } from 'react';
import { SubdivisionsColumnBulk } from './SubdivisionsColumnBulk';
import { SubdivisionsColumn } from './SubdivisionsColumn';
import { ComponentColumn } from './ComponentColumn';
import { manageMembershipTableDataOperations } from './manageMembershipTableDataOperations';

type ComponentsMembershipTableProps = Pick<
  ManageMembershipModalProps,
  | 'currentPageTableData'
  | 'commands'
  | 'activeSubdivisions'
  | 'isBulkChange'
  | 'selectedComponentRows'
>;

const tableTheme = {
  ...newTableTheme,
  BodyTr: styled(newTableTheme.BodyTr)`
    height: auto;
  `,
  Td: styled(newTableTheme.Td)`
    &:last-child {
      width: 50%;
    }
    padding-top: ${s8}; /* === s */
    padding-bottom: ${s4}; /* === xs */
    height: 100%;
    vertical-align: top;
  `,
  Th: styled(newTableTheme.Th)`
    &:last-child {
      width: 50%;
    }
    height: 40px;
    padding-bottom: 4px;
  `,
  Thead: styled(newTableTheme.Thead)`
    &::after,
    &::before {
      top: 0;
    }
  `,
};

const Divider = styled.hr`
  margin: 0;
  border: none;
  border-top: 1px solid ${colors.grey80};
`;

const toggleExpandedRow =
  (setExpandedRowIds: React.Dispatch<React.SetStateAction<string[]>>) =>
  (componentId: ArdoqId) => {
    setExpandedRowIds(prev =>
      prev?.includes(componentId)
        ? prev.filter(id => id !== componentId)
        : [...(prev ?? []), componentId]
    );
  };

const ComponentsMembershipTable = ({
  currentPageTableData,
  commands,
  activeSubdivisions,
  isBulkChange,
  selectedComponentRows,
}: ComponentsMembershipTableProps) => {
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);

  const toggleExpandedRowHandler = toggleExpandedRow(setExpandedRowIds);

  const dataSourceTableCommonProps = {
    components: tableTheme,
    dataSource: currentPageTableData,
    columns: [
      ComponentColumn,
      isBulkChange
        ? SubdivisionsColumnBulk(toggleExpandedRowHandler, expandedRowIds)
        : SubdivisionsColumn(activeSubdivisions, commands),
    ],
    expandedRowIds: expandedRowIds,
    getExpandedRow: (tableData: ManageMembershipTableData) => {
      if (
        manageMembershipTableDataOperations.isLastRow(
          currentPageTableData,
          tableData
        )
      ) {
        return null;
      }
      return <Divider />;
    },
  };

  return isBulkChange ? (
    <SelectableDatasourceTable
      selected={selectedComponentRows}
      setSelected={commands.setSelectComponentRows}
      {...dataSourceTableCommonProps}
    />
  ) : (
    <DatasourceTable {...dataSourceTableCommonProps} />
  );
};

export default ComponentsMembershipTable;
