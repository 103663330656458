import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useRef, useState } from 'react';
import {
  ButtonTitle,
  MenuPositioner,
  IconWrapper,
  SectionButton,
} from './atoms';
import {
  ChevronRightIcon,
  Icon,
  IconName,
  IconSize,
  OpenInNewIcon,
} from '@ardoq/icons';
import AppMainSidebarMenu from 'components/AppMainSidebarMenu/AppMainSidebarMenu';
import { MenuItemProps } from 'components/AppMainSidebarMenu/types';
import { IconBadge, StatusType } from '@ardoq/status-ui';
import { hasFeature, Features } from '@ardoq/features';
import { debounce } from 'lodash';
import { colors } from '@ardoq/design-tokens';
import { FlexBox } from '@ardoq/layout';

export type AppMainSidebarButtonProps = {
  iconName?: IconName;
  isActive?: boolean;
  menuItems?: MenuItemProps[];
  onClick?: () => void;
  shouldShow?: boolean;
  hasNewItems?: boolean;
  isExternalLink?: boolean;
  sidebarIsExpanded: boolean;
  clearHover?: VoidFunction;
  'data-test-id'?: string;
  'data-click-id'?: string;
};

const AppMainSidebarButton = ({
  iconName,
  isActive,
  menuItems,
  onClick,
  shouldShow = true,
  hasNewItems,
  sidebarIsExpanded,
  isExternalLink,
  clearHover,
  ...props
}: AppMainSidebarButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const debouncedHandleMouseEnter = debounce(() => setIsHovered(true), 200);
  const debouncedHandleMouseLeave = debounce(() => setIsHovered(false), 200);

  const handleOnMouseEnter = () => {
    debouncedHandleMouseEnter();
    debouncedHandleMouseLeave.cancel();
  };

  const handleOnMouseLeave = () => {
    debouncedHandleMouseLeave();
    debouncedHandleMouseEnter.cancel();
  };

  const positionerRef = useRef<HTMLDivElement>(null);

  const onKeydown = (event: React.KeyboardEvent) => {
    // this may not be the best way to handle this, needs rethinking
    if (!popupMenuItems || popupMenuItems.length === 0) {
      return;
    }

    const previouslyFocusedButton = positionerRef.current?.querySelector(
      'button:focus:not(:disabled)'
    );
    if (event.key === 'Tab' && !event.shiftKey) {
      const lastButtonFromSubMenu = positionerRef.current?.querySelector(
        'button:not(:disabled):last-child'
      );
      if (isHovered && previouslyFocusedButton === lastButtonFromSubMenu) {
        setIsHovered(false);
      }
    }

    if (event.key === 'Tab' && event.shiftKey) {
      const mainSidebarButton =
        positionerRef.current?.querySelector('button:first-child');
      if (isHovered && previouslyFocusedButton === mainSidebarButton) {
        setIsHovered(false);
      }
    }
  };

  if (!shouldShow) return null;
  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);
  const buttonTitle = menuItems?.[0].title;
  const popupMenuItems = hasNewJourneyFeature ? menuItems?.slice(1) : menuItems;

  return (
    <MenuPositioner
      ref={positionerRef}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onKeyDown={onKeydown}
    >
      <SectionButton
        onBlur={() => {
          if (!popupMenuItems || popupMenuItems.length === 0) {
            // this is the problem area - we need to mark the button as not hovered when we leave the button or its submenu
            handleOnMouseLeave();
          }
        }}
        $isActive={isActive}
        $isOpen={isHovered}
        onClick={() => {
          onClick?.();
          setIsHovered(false);
        }}
        $hasNewJourneyFeature={hasNewJourneyFeature}
        {...props}
      >
        {iconName ? (
          <IconWrapper
            $isActive={isActive}
            $hasNewJourneyFeature={hasNewJourneyFeature}
          >
            {hasNewItems ? (
              <IconBadge iconName={iconName} statusType={StatusType.NEW} />
            ) : (
              <Icon
                iconName={iconName}
                iconSize={
                  hasNewJourneyFeature ? IconSize.MEDIUM : IconSize.REGULAR
                }
              />
            )}
          </IconWrapper>
        ) : null}
        {sidebarIsExpanded ? (
          <FlexBox flex={1} justify="space-between" align="center">
            <ButtonTitle>{buttonTitle}</ButtonTitle>
            {popupMenuItems && popupMenuItems.length > 0 && (
              <ChevronRightIcon style={{ color: colors.grey50 }} />
            )}
            {isExternalLink && (
              <OpenInNewIcon
                iconSize={
                  hasNewJourneyFeature ? IconSize.MEDIUM : IconSize.REGULAR
                }
              />
            )}
          </FlexBox>
        ) : (
          <VisuallyHidden>{buttonTitle}</VisuallyHidden>
        )}
      </SectionButton>
      {(!hasNewJourneyFeature || (hasNewJourneyFeature && sidebarIsExpanded)) &&
        isHovered &&
        popupMenuItems &&
        popupMenuItems.length > 0 && (
          <AppMainSidebarMenu
            menuItems={popupMenuItems}
            clearHover={() => {
              clearHover?.();
              setIsHovered(false);
            }}
          />
        )}
    </MenuPositioner>
  );
};

export default AppMainSidebarButton;
