import SimpleGraphView from 'viewpointBuilder/components/SimpleGraph/SimpleGraphView';
import { APIViewpointAttributes, MetaModel } from '@ardoq/api-types';
import { PrimaryButton, GhostButton } from '@ardoq/button';
import { CollapsibleGraphGroup } from '@ardoq/graph';
import { FlexBox } from '@ardoq/layout';
import { ResizablePageBody } from '@ardoq/page-layout';
import { Drawer } from '@ardoq/snowflakes';
import { noop } from 'lodash';
import { useEffect, useRef } from 'react';
import { EditTraversalOptionsPanel } from 'viewpointBuilder/components/EditTraversalOptionsPanel';
import { createGraphInterface } from 'viewpointBuilder/components/SimpleGraph/GraphInterface';
import { SimpleGraphContainerWithGreyBackground } from 'viewpointBuilder/components/SimpleGraph/SimpleGraphViewContainer';
import { getToggleGraphNodeSelection } from 'viewpointBuilder/toggleGraphNodeSelection';
import { GraphId } from 'viewpointBuilder/types';
import ComponentTypeTag from '../ComponentTypeTag';
import audienceTraversalDrawer$, {
  AudienceTraversalDrawerState,
} from './audienceTraversalDrawer$';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import {
  fetchInstanceCounts,
  setTriplesSortAndFiltersState,
  addTriple,
  removeTriple,
  selectGraphNode,
} from './actions';

type AudienceTraversalEditorOwnProps = {
  surveyComponentTypeName: string;
  metamodel: MetaModel;
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>;
  onConfirmClick: (
    traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>
  ) => void;
  onCancelClick: VoidFunction;
} & AudienceTraversalDrawerState;

const AudienceTraversalDrawer = ({
  surveyComponentTypeName,
  selectedComponentTypeName,
  metamodel,
  traversal,
  selectedGraphNodeId,
  graphEdges,
  graphNodes,
  triplesSortAndFiltersState,
  tripleOptions,
  outgoingTriplesHiddenCount,
  incomingTriplesHiddenCount,
  onConfirmClick,
  onCancelClick,
}: AudienceTraversalEditorOwnProps) => {
  useEffect(() => {
    if (
      tripleOptions.optionCountLoadingState !== 'idle' ||
      !tripleOptions.fetchCountsArgs
    ) {
      return;
    }
    dispatchAction(fetchInstanceCounts(tripleOptions.fetchCountsArgs));
  }, [tripleOptions.fetchCountsArgs, tripleOptions.optionCountLoadingState]);

  const graphInterface = useRef(createGraphInterface());
  const graphGroups = new Map<GraphId, CollapsibleGraphGroup>();
  return (
    <Drawer
      title={
        <FlexBox gap="small" align="center">
          Select advanced relationship for
          <ComponentTypeTag
            componentTypeName={selectedComponentTypeName}
            metamodel={metamodel}
          />
        </FlexBox>
      }
      subtitle={`Component types at the end of each path can approve survey responses for ${surveyComponentTypeName} components they are connected to.`}
      drawerSize="large"
      controls={
        <FlexBox gap="small">
          <GhostButton onClick={onCancelClick}>Cancel</GhostButton>
          <PrimaryButton
            onClick={() => {
              onConfirmClick(traversal);
            }}
          >
            Confirm
          </PrimaryButton>
        </FlexBox>
      }
    >
      <FlexBox height="full">
        <ResizablePageBody
          leftContentInitialWidth={520}
          leftContent={
            <EditTraversalOptionsPanel
              selectedGraphNodeId={selectedGraphNodeId}
              tripleOptions={tripleOptions}
              commands={{
                setStartType: noop,
                setStartTypeAndTraversalStartSetOrCount: noop,
                setTraversals: noop,
                setTripleSortOrder: ({ tripleSortOrder }) =>
                  dispatchAction(
                    setTriplesSortAndFiltersState({
                      ...triplesSortAndFiltersState,
                      tripleSortOrder,
                    })
                  ),
                toggleIsSelectedGraphNode: graphNodeId =>
                  dispatchAction(selectGraphNode(graphNodeId)),
                removeNodeFromGraph: noop,
                responseInstanceCounts: noop,
                setSectionFilter: ({ filterTerm }) =>
                  dispatchAction(
                    setTriplesSortAndFiltersState({
                      ...triplesSortAndFiltersState,
                      filterTerm,
                    })
                  ),
                toggleShowOnlyOptionsWithInstanceCounts: () =>
                  dispatchAction(
                    setTriplesSortAndFiltersState({
                      ...triplesSortAndFiltersState,
                      showOnlyOptionsWithInstanceCounts:
                        !triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts,
                    })
                  ),
                updateStartContext: noop,
                setPathMatchingType: noop,
                skipClickOtherComponentTypeNodesHints: noop,
                toggleTripleOption: (
                  _tripleId,
                  direction,
                  namedDirectedTriple,
                  isSelected
                ) => {
                  if (isSelected) {
                    dispatchAction(
                      addTriple({
                        direction,
                        namedDirectedTriple,
                      })
                    );
                    return;
                  }
                  dispatchAction(removeTriple({ namedDirectedTriple }));
                },
              }}
              triplesSortAndFiltersState={{
                ...triplesSortAndFiltersState,
                incomingTriplesHiddenCount,
                outgoingTriplesHiddenCount,
              }}
              hasTripleOptions={{
                outgoingReferences: tripleOptions.outgoing.length > 0,
                incomingReferences: tripleOptions.incoming.length > 0,
              }}
              shouldShowDeleteCurrentNodeButton={false}
            />
          }
        >
          <SimpleGraphContainerWithGreyBackground
            onClick={event => {
              getToggleGraphNodeSelection({
                toggleIsSelectedGraphNode: graphNodeId =>
                  dispatchAction(selectGraphNode(graphNodeId)),
              })(event);
            }}
          >
            <SimpleGraphView
              key={selectedComponentTypeName}
              viewModel={{ graphNodes, graphEdges, graphGroups }}
              graphInterface={graphInterface.current}
              label="Metamodel"
            />
          </SimpleGraphContainerWithGreyBackground>
        </ResizablePageBody>
      </FlexBox>
    </Drawer>
  );
};

export default connect(AudienceTraversalDrawer, audienceTraversalDrawer$);
