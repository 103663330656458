import { Step, StepProps, StepState } from '@ardoq/steppers';
import { Upload } from './pages/upload/upload';
import TabularConfigMapping from 'integrations/common/pages/tabularConfigMapping/TabularConfigMapping';

import {
  getImporterTitle,
  getReviewStepPopoverText,
  getReviewStepState,
} from './utils';
import Flex from 'atomicComponents/Flex';
import { FlowHeader } from 'integrations/common/components/flowHeader/FlowHeader';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { UploadInfo, UploadState } from './streams/upload/types';
import { uploadedFile$ } from './streams/upload/upload$';
import { combineLatest, switchMap } from 'rxjs';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import {
  IntegrationId,
  TabularMapping,
} from 'integrations/common/streams/tabularMappings/types';
import { TransferState } from 'integrations/common/streams/transferState/types';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { isValidTabularMapping } from 'integrations/common/streams/tabularMappings/utils';
import {
  isSuccessTransfer,
  successStateToDryRunStatus,
} from 'integrations/common/streams/transferState/utils';
import { usePopover } from '@ardoq/popovers';
import { WaitingOverlay } from 'integrations/common/components/overlays/WaitingOverlay';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { TransferConfigsState } from 'integrations/common/streams/transferConfigs/types';
import { startTransferConfigsModal } from 'integrations/common/modals/transferConfigsModal/TransferConfigsModal';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import {
  getConfigureStepState,
  getScheduleStepState,
} from 'integrations/common/utils/step';
import { saveActiveConfiguration } from 'integrations/common/streams/transferConfigs/actions';
import { startSaveModal } from 'integrations/common/modals/saveModal/SaveModal';
import { Stepper } from 'integrations/common/components/stepper';
import {
  ImportRoute,
  OverviewRoute,
  RoutePath,
} from 'integrations/common/navigation/types';
import {
  isOverviewRoute,
  isPathReady,
} from 'integrations/common/navigation/utils';
import { Overview } from 'integrations/common/containers/overview/Overview';
import {
  navigateToConfigure,
  navigateToPath,
} from 'integrations/common/navigation/actions';
import { ImportAndSchedule } from 'integrations/common/pages/importAndSchedule/ImportAndSchedule';
import { ReviewTest } from 'integrations/common/pages/reviewTest/ReviewTest';
import { FileDropzone } from 'integrations/excel-v3/components/MinimalDropzone';
import { uploadFileRequest } from 'integrations/excel-v3/streams/upload/actions';
import { DryRunStatus } from 'integrations/common/streams/transferState/types';
import { StickyFooter } from 'integrations/common/containers/stickyFooter/StickyFooter';
import {
  ScheduleState,
  getScheduleStream,
} from 'integrations/common/streams/schedule/schedule$';
import { isStepAvailable } from './utils';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { TablePreviews } from 'integrations/common/streams/tablePreviews/types';
import { PageBody } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';
import { FlowContainer } from 'integrations/common/components/flowContainer/FlowContainer';
import { excelDictionary } from 'integrations/common/dictionary';

type ExcelParams = {
  uploadedFile: UploadState;
  activeIntegration: ActiveIntegrationState;
  transferConfigs: TransferConfigsState;
  tabularMapping: TabularMapping;
  transferState: TransferState;
  scheduleState: ScheduleState;
  tablePreviews: TablePreviews;
};

const handleStartNewImport = () => {
  dispatchAction(resetIntegration('excel-v3'));
};

const handleStartTransferConfigsModal = () => {
  dispatchAction(
    trackIntegrationEvent({
      integrationId: 'excel-v3',
      name: 'CLICKED_MANAGE_CONFIGURATION',
    })
  );
  startTransferConfigsModal();
};

const handleSaveAs = () => {
  startSaveModal({
    integrationId: 'excel-v3',
    title: 'Save as a new version',
    isNewConfig: true,
  });
};

const initialReuploadInfo: UploadInfo = {
  status: 'Reupload',
  title: 'Drop your corrected file here',
  message: 'We support .xls, .xlsx, .xlsm, and .csv formats',
  uploadButtonText: 'Browse',
};

const onReupload = (data: File) => {
  dispatchAction(uploadFileRequest({ file: data, isReuploading: true }));
};

const onNextClicked = (integrationId: IntegrationId) => () => {
  dispatchAction(navigateToConfigure({ integrationId, tableId: null }));
};

function ExcelStepper({
  uploadedFile,
  activeIntegration: {
    integrationPath,
    selectedTransferConfigId,
    integrationId,
  },
  transferConfigs,
  tabularMapping,
  transferState,
  scheduleState,
  tablePreviews,
}: ExcelParams) {
  const onPathChange = (path: ImportRoute) => {
    dispatchAction(
      navigateToPath({
        integrationId,
        path,
        source: 'stepper',
      })
    );
  };

  const uploadInfo: UploadInfo =
    uploadedFile.uploadInfo.status === 'Success'
      ? initialReuploadInfo
      : uploadedFile.uploadInfo;

  const isDryRunError = isSuccessTransfer(transferState)
    ? successStateToDryRunStatus(transferState) === DryRunStatus.ERROR &&
      transferState.dryRun
    : false;

  const DryRunError = isDryRunError
    ? () => <FileDropzone onUpload={onReupload} uploadInfo={uploadInfo} />
    : undefined;

  const uploadStatus = uploadedFile.uploadInfo.status;

  const isUploadPage =
    integrationPath === ImportRoute.SELECT_DATA || integrationPath === null;

  const isConfigurePage = integrationPath === ImportRoute.CONFIGURE;

  const isReviewPage = integrationPath === ImportRoute.REVIEW;

  const isImportPage = integrationPath === ImportRoute.IMPORT_AND_SCHEDULE;

  const handleSaveCurrentConfig = () => {
    if (!selectedTransferConfigId) {
      startSaveModal({
        integrationId,
        title: 'Save as a new version',
        isNewConfig: true,
      });
      return;
    }
    dispatchAction(saveActiveConfiguration({ integrationId: 'excel-v3' }));
  };

  const isDisabledStep = (path: ImportRoute) =>
    !isPathReady(path, {
      tabularMapping,
      tablePreviews,
      transferState,
      integrationId: 'excel-v3',
    }) ||
    !isStepAvailable(path, {
      tabularMapping,
      transferState,
      uploadStatus,
    });

  return (
    <PageBody
      headerContent={
        <FlowHeader
          title={getImporterTitle({
            filename: uploadedFile.uploadResponse?.sourceName,
            selectedTransferConfigId,
            transferConfigs: transferConfigs.configs,
          })}
          onSave={handleSaveCurrentConfig}
          onSaveAs={handleSaveAs}
          canRestart={true}
          isSavingDisabled={!isValidTabularMapping(tabularMapping)}
          onRestart={handleStartNewImport}
          onStartTransferConfigsModal={handleStartTransferConfigsModal}
          transferDirection={transferState.transferDirection}
        />
      }
      padding={0}
      backgroundColor="bgDefault"
      footerContent={
        <StickyFooter
          integrationName={excelDictionary.name}
          isDataSelectionValid={uploadStatus === 'Success'}
          onDataSelectionNext={onNextClicked(integrationId)}
        />
      }
    >
      <FlowContainer>
        {transferState.requestStatus === 'LOADING' && (
          <WaitingOverlay>
            {transferState.dryRun
              ? 'Reviewing the import...'
              : "Nice work, you're already done..."}
          </WaitingOverlay>
        )}
        <Flex $direction="column">
          <Stepper>
            <Step
              onPress={() => onPathChange(ImportRoute.SELECT_DATA)}
              isSelected={isUploadPage}
              heading="Upload file"
              state={
                uploadStatus === 'Success' ? StepState.DONE : StepState.ACTIVE
              }
            />
            <Step
              onPress={() => onPathChange(ImportRoute.CONFIGURE)}
              isSelected={isConfigurePage}
              isDisabled={isDisabledStep(ImportRoute.CONFIGURE)}
              heading="Configure data"
              state={getConfigureStepState({
                integrationPath,
                configureRoute: ImportRoute.CONFIGURE,
                tabularMapping,
              })}
            />
            <ReviewStep
              onPress={() => onPathChange(ImportRoute.REVIEW)}
              isDisabled={isDisabledStep(ImportRoute.REVIEW)}
              isSelected={isReviewPage}
              heading="Review"
              state={getReviewStepState({ transferState })}
            />
            <Step
              onPress={() => onPathChange(ImportRoute.IMPORT_AND_SCHEDULE)}
              isSelected={isImportPage}
              isDisabled={isDisabledStep(ImportRoute.IMPORT_AND_SCHEDULE)}
              heading="Import"
              state={getScheduleStepState({
                transferState,
                schedule: scheduleState,
              })}
            />
          </Stepper>
        </Flex>

        {isUploadPage && <Upload />}
        {isConfigurePage && <TabularConfigMapping />}
        {isReviewPage && <ReviewTest DryRunError={DryRunError} />}
        {isImportPage && <ImportAndSchedule />}
      </FlowContainer>
    </PageBody>
  );
}

const ReviewStep = ({
  onPress,
  isDisabled,
  isSelected,
  heading,
  state,
}: StepProps) => {
  const popoverText = getReviewStepPopoverText(state);
  const popoverProps = usePopover(() => popoverText);

  return (
    <FlexBox flex={1} {...(isDisabled && popoverText ? popoverProps : {})}>
      <Step
        onPress={onPress}
        isDisabled={isDisabled}
        isSelected={isSelected}
        heading={heading}
        state={state}
        stepNumber={3}
      />
    </FlexBox>
  );
};

const Excel = (props: ExcelParams) => {
  const integrationPath = props.activeIntegration.integrationPath as RoutePath;
  if (!integrationPath || isOverviewRoute(integrationPath)) {
    return (
      <Overview
        integrationPath={integrationPath}
        initialTab={OverviewRoute.CONFIGURATIONS}
      />
    );
  }

  return <ExcelStepper {...props} />;
};

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest({
      activeIntegration: getActiveIntegrationStream(integrationId),
      transferConfigs: transferConfigs$,
      uploadedFile: uploadedFile$,
      tabularMapping: getTabularMappingStream(integrationId),
      transferState: getTransferStateStream(integrationId),
      scheduleState: getScheduleStream(integrationId),
      tablePreviews: getTablePreviewsStream(integrationId),
    })
  )
);

export default connect(Excel, viewModel$);
