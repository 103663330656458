import { filter, map, OperatorFunction, pipe, switchMap, tap } from 'rxjs';
import {
  createSearchQuery,
  deleteQuerySuccess,
  loadStoredQuery,
  resetAllSearchState,
  triggerSearchLoading,
  triggerSearchLoadingDone,
  updateReturnLocation,
} from 'search/actions';
import {
  ActionWithPayload,
  apply,
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
  withoutNamespace,
} from '@ardoq/rxbeach';
import {
  exportExcelGremlin,
  queryGremlinSearch,
  queryGremlinSearchError,
  queryGremlinSearchSuccess,
  resetGremlinResults,
  updateResultsReturnLocation,
} from './actions';
import searchAPIService from 'search/searchAPIService';
import {
  GremlinSearchSource,
  trackQueriedGremlinSearch,
} from 'search/tracking';
import { logError } from '@ardoq/logging';
import {
  getQueryEditorNamespace,
  QueryEditorNamespace,
} from 'search/QueryEditor/queryEditor$';
import {
  ReportEventLocations,
  trackExportAdHocSearchToExcel,
  trackQueryResults,
} from '@ardoq/report-reader';
import { trackEvent } from '../../tracking/tracking';
import { graphSearchApi, handleError } from '@ardoq/api';
import {
  DataSourceType,
  GraphSearchResultObject,
  GremlinSearchQuery,
  SearchBackend,
  SearchType,
} from '@ardoq/api-types';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';

const extractPayloadAndSearchType = <P>(): OperatorFunction<
  ActionWithPayload<P>,
  [P, SearchType]
> =>
  pipe(
    map(({ payload, meta: { namespace } }) => {
      // Verify that the namespace is actually a SearchType
      for (const val of Object.values(SearchType)) {
        if (val === namespace) {
          return [payload, val] as const;
        }
      }

      return undefined;
    }),
    filter((d): d is [P, SearchType] => d !== undefined)
  );

const handleQueryGremlinSeach = routine(
  ofType(queryGremlinSearch),
  extractPayloadAndSearchType(),
  tap(([, searchType]) =>
    dispatchAction(
      triggerSearchLoading(),
      getQueryEditorNamespace(SearchBackend.GREMLIN, searchType)
    )
  ),
  apply(([, searchType]) =>
    pipe(
      switchMap(([{ query, queryParams }]) =>
        graphSearchApi.graphSearch<GraphSearchResultObject>({
          query: query.trim(),
          queryParams,
        })
      ),
      map(searchresult => {
        if (isArdoqError(searchresult)) {
          return searchresult;
        }
        if (searchType === SearchType.QUERY) {
          trackQueryResults(
            trackEvent,
            DataSourceType.GREMLIN_SEARCH,
            ReportEventLocations.AD_HOC_GREMLIN_SEARCH,
            searchresult.count
          );
        }
        return [searchresult, searchType] as const;
      }),
      handleError(error => {
        dispatchAction(
          queryGremlinSearchError({
            syntaxError: getArdoqErrorMessage(error),
          }),
          searchType
        );
      })
    )
  ),
  tap(([response, searchType]) => {
    if (response.status === 'error') {
      dispatchAction(
        queryGremlinSearchError({
          syntaxError: response.error,
        }),
        searchType
      );
    } else {
      dispatchAction(
        queryGremlinSearchSuccess({
          results: response.result,
          totalResults: response.count,
        }),
        searchType
      );
    }
  })
);

const handleExportExcel = routine(
  ofType(exportExcelGremlin),
  extractPayload(),
  tap(async ({ query }) => {
    trackQueriedGremlinSearch({
      searchSource: GremlinSearchSource.EXPORT_EXCEL_RESULTS,
    });
    dispatchAction(triggerSearchLoading(), QueryEditorNamespace.GREMLIN_SEARCH);
    const response = await searchAPIService.runGremlinExport({
      query: query as GremlinSearchQuery,
      getAllResults: true,
    });
    if (isArdoqError(response)) {
      logError(response, 'Error retrieving all graph search results');
    }
    dispatchAction(
      triggerSearchLoadingDone(),
      QueryEditorNamespace.GREMLIN_SEARCH
    );
    trackExportAdHocSearchToExcel(trackEvent, DataSourceType.GREMLIN_SEARCH);
  })
);

const invokeSearchLoadingDone = routine(
  ofType(queryGremlinSearchError, queryGremlinSearchSuccess),
  extractPayloadAndSearchType(),
  tap(([, searchType]) =>
    dispatchAction(
      triggerSearchLoadingDone(),
      getQueryEditorNamespace(SearchBackend.GREMLIN, searchType)
    )
  )
);

const handleResetAllSearchState = routine(
  ofType(resetAllSearchState),
  withoutNamespace(),
  tap(() => {
    dispatchAction(resetGremlinResults());
  })
);

const handleReset = routine(
  ofType(createSearchQuery, loadStoredQuery, deleteQuerySuccess),
  // We need to handle these actions for any gremlin based search
  // Instead of including each of the gremlin based search types, we exclude
  // Advanced search
  withoutNamespace(QueryEditorNamespace.ADVANCED_SEARCH),
  tap(() => dispatchAction(resetGremlinResults()))
);

// updateReturnLocation is dispatched with a namespace to reach the correct
// queryEditor$, but that means it won't pass through the namespace filter of
// gremlinResult$.
// It should reach every gremlinResult$, so we dispatch the new action without a
// namespace. The action is a differnt action to not interfere with queryEditor$.
const redispatchUpdateReturnLocation = routine(
  ofType(updateReturnLocation),
  extractPayload(),
  tap(payload => dispatchAction(updateResultsReturnLocation(payload)))
);

export const gremlinResultsRoutines = collectRoutines(
  handleQueryGremlinSeach,
  handleExportExcel,
  invokeSearchLoadingDone,
  handleResetAllSearchState,
  handleReset,
  redispatchUpdateReturnLocation
);
