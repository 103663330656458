import { ViewIds } from '@ardoq/api-types';
import {
  ExtractPayload,
  dispatchAction,
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import {
  type ViewContext,
  type SettingsConfig,
  DEFAULT_VIEW_CONTEXT,
} from '@ardoq/view-settings';
import { map, debounceTime, distinctUntilKeyChanged } from 'rxjs';
import activeView$ from 'streams/views/mainContent/activeView$';
import {
  getLeftMenu as blockDiagramViewModifiers,
  getRightMenu as blockDiagramRightMenu,
} from 'tabview/blockDiagram/menus';
import modernizedBlockDiagramViewModifiers from 'tabview/blockDiagram/modernized/getLeftMenu';
import { getLeftMenuConfig as dependencyMapViewModifiers } from 'tabview/dependencyMap/view/DependencyMapSettingsBar';
import { relationshipsViewModifiers } from 'tabview/relationsD3View/hierarchical/RelationshipsViewSettingsBar';
import type {
  BlockDiagramViewProperties,
  ModernizedBlockDiagramViewProperties,
} from 'tabview/blockDiagram/types';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import type { ExtractStreamShape } from 'tabview/types';
import { updateViewContext, updateViewProperties } from '@ardoq/view-settings';
import { DependencyMapViewProps } from 'tabview/dependencyMap/types';
import {
  RelationshipsViewProperties,
  RelationshipsViewSettings,
} from 'tabview/relationsD3View/hierarchical/types';
import {
  isTimelineSettingsControlsDependencies,
  logSettingsControlDependenciesError,
} from '@ardoq/timeline2024';
import { getGraphNodeCount } from '@ardoq/graph';
import { isEqual } from 'lodash';
import { getExportsForHtmlView, getExportsForYFilesView } from '@ardoq/export';
import { trackExportingVisualization } from 'tracking/events/visualizations';
import { addToPresentation } from 'viewSettings/exportHandlers';
import { getStyleSheets } from 'tabview/getSharedExportFunctions';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { toggleLegend } from 'presentation/viewPane/actions';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { DEFAULT_DEBOUNCE_TIME } from 'modelInterface/consts';
import { distinctUntilChanged } from 'rxjs/operators';
import { viewLegendCommands } from '@ardoq/view-legend';
import pngExporter from 'tabview/relationsD3View/hierarchical/pngExporter';

export const asyncNoop = async () => {};

type ViewModifiersStreamShape = {
  mainViewId: ViewIds;
  viewPropertiesViewId: ViewIds;
  viewProperties: Record<string, any>;
  viewModifiers: SettingsConfig[];
  rightMenu: SettingsConfig[];
  viewContext: ViewContext;
  exportToPng: () => Promise<void>;
};

const resetViewModifiers = (
  state: ViewModifiersStreamShape
): ViewModifiersStreamShape => {
  const { mainViewId, viewProperties, viewPropertiesViewId } = state;
  if (mainViewId !== viewPropertiesViewId) {
    return { ...state, viewModifiers: [] };
  }
  switch (mainViewId) {
    case ViewIds.BLOCK_DIAGRAM: {
      const blockDiagramProperties =
        viewProperties as unknown as BlockDiagramViewProperties;
      const { viewSettings, viewModel, isViewpointMode, noContext } =
        blockDiagramProperties;
      const {
        groups,
        traversedIncomingReferenceTypes,
        traversedOutgoingReferenceTypes,
        referenceTypes,
        noConnectedComponents,
      } = viewModel;
      state.viewModifiers = blockDiagramViewModifiers({
        viewSettings,
        traversedIncomingReferenceTypes,
        traversedOutgoingReferenceTypes,
        referenceTypes,
        viewId: mainViewId,
        onViewSettingsUpdate,
        activeGroups: [...groups.add, ...groups.update],
        isViewpointMode,
      });
      const isEmptyView =
        !getGraphNodeCount(viewModel) || noConnectedComponents || noContext;

      // #region  these functions return the *current* renderingParams state, which will be initialized after this menu. */
      const getCurrentContainer = () => state.viewContext.getContainer();
      const getCurrentGraphComponent = () =>
        state.viewContext.getGraphComponent();
      // #endregion
      state.rightMenu = blockDiagramRightMenu({
        viewId: mainViewId,
        viewSettings,
        getContainer: getCurrentContainer,
        getGraphComponent: () => state.viewContext.getGraphComponent(),
        onViewSettingsUpdate,
        isEmptyView,
      });

      const getExports = () =>
        getExportsForYFilesView({
          container: getCurrentContainer,
          graphComponent: getCurrentGraphComponent,
          exportedViewMetadata: {
            name: mainViewId,
            isViewpointMode: true,
          },
          trackingFunction: trackExportingVisualization,
          addToPresentation,
          getStyleSheets,
        });
      state.exportToPng = async () => await getExports().exportToPng();
      break;
    }
    case ViewIds.MODERNIZED_BLOCK_DIAGRAM: {
      const blockDiagramProperties =
        viewProperties as unknown as ModernizedBlockDiagramViewProperties;
      const { viewSettings, viewModel, isViewpointMode, noContext } =
        blockDiagramProperties;
      const {
        groups,
        traversedIncomingReferenceTypes,
        traversedOutgoingReferenceTypes,
        referenceTypes,
        noConnectedComponents,
      } = viewModel;
      state.viewModifiers = modernizedBlockDiagramViewModifiers({
        viewSettings,
        traversedIncomingReferenceTypes,
        traversedOutgoingReferenceTypes,
        referenceTypes,
        viewId: mainViewId,
        onViewSettingsUpdate,
        activeGroups: [...groups.add, ...groups.update],
        isViewpointMode,
      });
      const isEmptyView =
        !getGraphNodeCount(viewModel) || noConnectedComponents || noContext;

      // #region  these functions return the *current* renderingParams state, which will be initialized after this menu. */
      const getCurrentContainer = () => state.viewContext.getContainer();
      const getCurrentGraphComponent = () =>
        state.viewContext.getGraphComponent();
      // #endregion
      state.rightMenu = blockDiagramRightMenu({
        viewId: mainViewId,
        viewSettings,
        getContainer: getCurrentContainer,
        getGraphComponent: () => state.viewContext.getGraphComponent(),
        onViewSettingsUpdate,
        isEmptyView,
      });

      const getExports = () =>
        getExportsForYFilesView({
          container: getCurrentContainer,
          graphComponent: getCurrentGraphComponent,
          exportedViewMetadata: {
            name: mainViewId,
            isViewpointMode: true,
          },
          trackingFunction: trackExportingVisualization,
          addToPresentation,
          getStyleSheets,
        });
      state.exportToPng = async () => await getExports().exportToPng();
      break;
    }
    case ViewIds.DEPENDENCY_MAP_2: {
      const dependencyMapProperties =
        viewProperties as unknown as DependencyMapViewProps;
      const { viewSettings, viewModel } = dependencyMapProperties;
      const { nodes, noConnectedComponents } = viewModel;
      state.viewModifiers = dependencyMapViewModifiers({
        viewSettings,
        viewModel,
        onViewSettingsUpdate,
      });
      const isEmptyView = !nodes.length || noConnectedComponents;
      const exports = {
        ...getExportsForHtmlView({
          container: () => viewModifiers$.state.viewContext.getContainer(),
          exportedViewMetadata: {
            name: ViewIds.DEPENDENCY_MAP_2,
            isViewpointMode: true,
          },
          trackingFunction: trackExportingVisualization,
          addToPresentation,
          showPresentationOption: true,
          getStyleSheets,
        }),
        isDisabled: isEmptyView,
      };
      state.rightMenu = getRightMenuConfig({
        viewstate: viewSettings,
        exports,
        onViewSettingsUpdate,
        viewId: ViewIds.DEPENDENCY_MAP_2,
      });
      state.exportToPng = exports.exportToPng;
      break;
    }
    case ViewIds.RELATIONSHIPS_3: {
      const relationshipsViewProperties =
        viewProperties as unknown as RelationshipsViewProperties;
      const {
        viewSettings,
        groups,
        traversedIncomingReferenceTypes,
        traversedOutgoingReferenceTypes,
        referenceTypes,
        rootNode,
        isViewpointMode,
        componentTypes,
        noConnectedComponents,
      } = relationshipsViewProperties;
      state.viewModifiers = relationshipsViewModifiers({
        viewSettings,
        traversedIncomingReferenceTypes,
        traversedOutgoingReferenceTypes,
        referenceTypes,
        activeGroups: [...groups.add, ...groups.update],
        isViewpointMode,
        rootNode,
      });
      const noComponents = !componentTypes.length;
      const isEmptyView = noComponents || noConnectedComponents;

      const exportToPng = pngExporter(
        () => viewModifiers$.state.viewContext.getContainer(),
        () =>
          viewModifiers$.state.viewContext
            .getContainer()
            ?.querySelector('canvas') ?? null,
        ViewIds.RELATIONSHIPS_3
      );
      state.rightMenu = getRightMenuConfig({
        viewId: ViewIds.RELATIONSHIPS_3,
        viewstate: viewSettings,
        exports: {
          exportToPng,
          addToPresentation: () => addToPresentation(ViewIds.RELATIONSHIPS_3),
          isDisabled: isEmptyView,
        },
        onViewSettingsUpdate,
        legendOnClick: () => {
          const newLegendActive = !viewSettings.isLegendActive;
          viewLegendCommands.updateViewLegendSettings({
            viewId: ViewIds.RELATIONSHIPS_3,
            isActive: newLegendActive,
          });
          dispatchAction(
            toggleLegend({
              viewId: ViewIds.RELATIONSHIPS_3,
              legendActive: newLegendActive,
            }),
            ViewIds.RELATIONSHIPS_3
          );
        },
      });
      state.exportToPng = exportToPng;
      break;
    }
    case ViewIds.TIMELINE: {
      if (!isTimelineSettingsControlsDependencies(viewProperties)) {
        logSettingsControlDependenciesError();
        return state;
      }
      const { exportToPng } = getExportsForHtmlView({
        container: () => viewModifiers$.state.viewContext.getContainer(),
        exportedViewMetadata: {
          name: ViewIds.TIMELINE,
          isViewpointMode: true,
        },
        trackingFunction: trackExportingVisualization,
        addToPresentation,
        showPresentationOption: true,
        getStyleSheets,
      });

      state.viewModifiers = viewProperties.viewModifiers;
      state.exportToPng = exportToPng;
      break;
    }
    case ViewIds.BLOCKS: {
      state.viewModifiers = [];
      state.exportToPng = pngExporter(
        () => viewModifiers$.state.viewContext.getContainer(),
        () => viewModifiers$.state.viewContext.createExportCanvas?.() ?? null,
        ViewIds.BLOCKS
      );
      break;
    }
    default: {
      state.viewModifiers = [];
      state.exportToPng = asyncNoop;
    }
  }

  return state;
};

const viewPropertiesUpdated = (
  state: ViewModifiersStreamShape,
  {
    viewProperties,
    viewId: viewPropertiesViewId,
  }: ExtractPayload<typeof updateViewProperties>
): ViewModifiersStreamShape =>
  resetViewModifiers({
    ...state,
    viewProperties,
    viewPropertiesViewId,
    viewContext: viewProperties.viewContext ?? state.viewContext,
  });

const mainViewChanged$ = activeView$.pipe(
  distinctUntilKeyChanged('mainViewId')
);

const viewChanged = (
  state: ViewModifiersStreamShape,
  { mainViewId }: ExtractStreamShape<typeof mainViewChanged$>
): ViewModifiersStreamShape => resetViewModifiers({ ...state, mainViewId });

const viewContextUpdated = (
  state: ViewModifiersStreamShape,
  viewContext: ViewContext
) => resetViewModifiers({ ...state, viewContext });

const updateRelationshipViewViewSettings$ =
  getViewSettingsStream<RelationshipsViewSettings>(
    ViewIds.RELATIONSHIPS_3
  ).pipe(
    map(({ collapsedGroupIds, bundleRelationships }) => ({
      collapsedGroupIds,
      bundleRelationships,
    })),
    distinctUntilChanged((prev, next) => isEqual(prev, next)),
    debounceTime(DEFAULT_DEBOUNCE_TIME)
  );

const updateRelationshipViewViewSettings = (
  state: ViewModifiersStreamShape,
  updatedViewSettings: Partial<RelationshipsViewSettings>
) =>
  state.mainViewId === ViewIds.RELATIONSHIPS_3
    ? resetViewModifiers({
        ...state,
        viewProperties: {
          ...state.viewProperties,
          viewSettings: {
            ...state.viewProperties.viewSettings,
            ...updatedViewSettings,
          },
        },
      })
    : state;

const reducers = [
  streamReducer(mainViewChanged$, viewChanged),
  reducer(updateViewProperties, viewPropertiesUpdated),
  reducer(updateViewContext, viewContextUpdated),
  streamReducer(
    updateRelationshipViewViewSettings$,
    updateRelationshipViewViewSettings
  ),
];

const defaultState: ViewModifiersStreamShape = {
  mainViewId: ViewIds.NONE,
  viewProperties: {},
  viewPropertiesViewId: ViewIds.NONE,
  viewModifiers: [],
  rightMenu: [],
  viewContext: DEFAULT_VIEW_CONTEXT,
  exportToPng: asyncNoop,
};

const viewModifiers$ = persistentReducedStream<ViewModifiersStreamShape>(
  'viewModifiers$',
  defaultState,
  reducers
);

export default viewModifiers$;
