import { InventoryModule as InventoryModuleEnum } from './consts';
import { connect } from '@ardoq/rxbeach';
import inventoryNavigation$ from './inventoryNavigation$';
import Inventory from './Inventory';
import DataOverview from './dataOverview/DataOverview';

type InventoryModuleProps = {
  inventoryModule: InventoryModuleEnum;
};
const InventoryModule = ({ inventoryModule }: InventoryModuleProps) =>
  inventoryModule === InventoryModuleEnum.BROWSE ? (
    <Inventory />
  ) : (
    <DataOverview />
  );

export default connect(InventoryModule, inventoryNavigation$);
