import { getIndexer } from 'components/DiffMergeTable/utils/toDataSourceHelpers';
import { DiffTableRowType } from 'components/DiffMergeTable/types';
import { filterUnique } from 'utils/collectionUtil';
import {
  AllSupportedAPIAttrsArray,
  DataSource,
} from 'components/EntityMerge/types';
import {
  APIComponentAttributes,
  APIEntityType,
  ArdoqId,
  AllSupportedEntityTypes,
} from '@ardoq/api-types';
import {
  ComparableAPIEntityAttributes,
  ENTITY_DIFF_UTILS_CONST,
  getDefaultFieldNamesDifferentBetweenEntities,
  hasDifferentValuesOnEntities,
  ignoredProperties,
} from '@ardoq/renderers';
import type { EnhancedScopeData } from '@ardoq/data-model';

const { COMPONENT_STYLE_NAMES, DEFAULT_FIELD_NAMES } = ENTITY_DIFF_UTILS_CONST;

const getFieldNamesFilter = (
  entities: AllSupportedAPIAttrsArray,
  enhancedScopeData: EnhancedScopeData,
  entityType: AllSupportedEntityTypes
) => {
  const existingCustomFieldNames = new Set(
    enhancedScopeData.fields.map(field => field.name)
  ); // sometimes components contains data for fields that have been deleted from the workspace, so we have to check that the field name still exists on the workspace

  return (fieldName: string, i: number, all: string[]) =>
    existingCustomFieldNames.has(fieldName) &&
    !DEFAULT_FIELD_NAMES.has(fieldName) &&
    !enhancedScopeData.calculatedFields.has(fieldName) &&
    filterUnique(fieldName, i, all) &&
    hasDifferentValuesOnEntities(
      fieldName as keyof ComparableAPIEntityAttributes,
      entities
    ) &&
    !ignoredProperties[entityType].has(fieldName);
};

const getParentPreselection = (
  entities: Array<APIComponentAttributes>,
  entitiesWithInvalidParent: Set<ArdoqId>
) => {
  const entitiesWithValidParent = entities.filter(
    ({ _id }) => !entitiesWithInvalidParent.has(_id)
  );
  return entitiesWithValidParent.length === 1
    ? entitiesWithValidParent[0]._id
    : null;
};

export const toDataSource = (
  entities: AllSupportedAPIAttrsArray,
  entityType: AllSupportedEntityTypes,
  entitiesWithInvalidParent: Set<ArdoqId>,
  enhancedScopeData: EnhancedScopeData
): DataSource => {
  if (!entities) return [];

  const getIndex = getIndexer();
  const rootIndex = getIndex();
  const rootChildren: number[] = [];
  const headerRow = {
    rowType: DiffTableRowType.HEADER_ROW,
    fieldName: '',
    index: rootIndex,
    parent: null,
    status: null,
    children: rootChildren,
  };

  const lastUpdatedByEmailRow = {
    rowType: DiffTableRowType.META_ROW,
    fieldName: 'lastModifiedByEmail',
    index: getIndex(),
    parent: null,
    status: null,
  };

  const lastModifiedRow = {
    rowType: DiffTableRowType.META_ROW,
    fieldName: 'lastUpdated',
    index: getIndex(),
    parent: null,
    status: null,
  };

  let propertyRows: DataSource = [];
  const propertiesWithDifferentValuesOnEntities =
    getDefaultFieldNamesDifferentBetweenEntities(entities, entityType).filter(
      /**
       * 'type', 'source', 'target' properties have to be the same in order
       * to be able to merge the references, so displaying them in the merge
       * table has no value.
       *
       * https://github.com/ardoq/ardoq-packages/pull/2408/files#r1016531893
       */
      fieldName =>
        entityType !== APIEntityType.REFERENCE ||
        ['type', 'source', 'target'].includes(fieldName)
    );

  if (propertiesWithDifferentValuesOnEntities.length) {
    const propertyRowIndex = getIndex();
    rootChildren.push(propertyRowIndex);
    const propertyChildren: number[] = [];
    propertyRows = [
      {
        rowType: DiffTableRowType.SUB_HEADER_ROW,
        fieldName: 'properties',
        index: propertyRowIndex,
        parent: rootIndex,
        status: null,
        children: propertyChildren,
      },
      ...propertiesWithDifferentValuesOnEntities.map(propertyName => {
        const index = getIndex();
        propertyChildren.push(index);
        return {
          rowType: DiffTableRowType.PROPERTY_ROW,
          fieldName: propertyName,
          parent: propertyRowIndex,
          status:
            propertyName === 'parent'
              ? getParentPreselection(
                  entities as Array<APIComponentAttributes>,
                  entitiesWithInvalidParent
                )
              : null,
          index,
        };
      }),
    ];
  }

  let customFieldRows: DataSource = [];
  const customFieldNames = entities
    .flatMap(entity => Object.keys(entity))
    .filter(getFieldNamesFilter(entities, enhancedScopeData, entityType));

  if (customFieldNames.length) {
    const fieldsIndex = getIndex();
    rootChildren.push(fieldsIndex);

    const fieldsChildren: number[] = [];
    customFieldRows = [
      {
        rowType: DiffTableRowType.SUB_HEADER_ROW,
        fieldName: 'fields',
        index: fieldsIndex,
        parent: rootIndex,
        status: null,
        children: fieldsChildren,
      },
      ...customFieldNames.map(fieldName => {
        const index = getIndex();
        fieldsChildren.push(index);
        return {
          rowType: DiffTableRowType.PROPERTY_ROW,
          parent: fieldsIndex,
          status: null,
          index,
          fieldName,
        };
      }),
    ];
  }

  let styleRows: DataSource = [];
  const styleNamesWithDifferentValuesOnEntities =
    entityType === APIEntityType.COMPONENT
      ? COMPONENT_STYLE_NAMES.filter(styleName =>
          hasDifferentValuesOnEntities(
            styleName as keyof ComparableAPIEntityAttributes,
            entities
          )
        )
      : [];
  if (styleNamesWithDifferentValuesOnEntities.length) {
    const styleIndex = getIndex();
    rootChildren.push(styleIndex);
    const styleChildren: number[] = [];
    styleRows = [
      {
        rowType: DiffTableRowType.SUB_HEADER_ROW,
        fieldName: 'style',
        index: styleIndex,
        parent: rootIndex,
        status: null,
        children: styleChildren,
      },
      ...styleNamesWithDifferentValuesOnEntities.map(styleName => {
        const index = getIndex();
        styleChildren.push(index);
        return {
          rowType: DiffTableRowType.PROPERTY_ROW,
          parent: styleIndex,
          status: null,
          index,
          fieldName: styleName,
        };
      }),
    ];
  }

  return [
    headerRow,
    lastUpdatedByEmailRow,
    lastModifiedRow,
    ...propertyRows,
    ...customFieldRows,
    ...styleRows,
  ];
};
