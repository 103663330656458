import { ButtonType } from '@ardoq/button';
import { SubStepConfig } from './Steps/types';
import { SubdivisionViewModelState } from './types';
import { PermissionsConfigurationSubStep } from './Steps/permissionsConfiguration/zones/components/PermissionsConfigurationSubStep';
import { ComponentTypeFieldsGroupTitle } from './Steps/permissionsConfiguration/zones/components/ComponentTypeFieldsGroupTitle';
import { zonesOperations } from './Steps/permissionsConfiguration/zones/operations';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { IconName } from '@ardoq/icons';
import {
  DIVISIONS_USER_EVENTS,
  trackDivisionEditorUserEvent,
} from './trackingUtils';
import { confirmDelete } from '@ardoq/modal';

export const getPermissionConfigurationSubSteps = (
  subdivisionEditorState: SubdivisionViewModelState
): SubStepConfig[] => {
  return Object.values(subdivisionEditorState.zonesState.zonesById).map(
    zone => {
      const canDeleteZone = zonesOperations.canDeleteZone(
        zone,
        subdivisionEditorState.zonesState.zonesById
      );

      return {
        step: zone._id,
        stepperHeading: zone.name,
        title: (
          <ComponentTypeFieldsGroupTitle
            isEditingTitle={
              subdivisionEditorState.zonesState.editingZoneNames[zone._id]
            }
            zone={zone}
            commands={subdivisionEditorState.zonesState.commands}
            errorMessage={
              subdivisionEditorState.stepsErrors[zone._id]?.zoneName
            }
          />
        ),
        subtitle:
          'Select the fields from the available component types to include in your field group.',
        headerRightContent: (
          <ButtonWithDropdown
            iconName={IconName.MORE_VERT}
            options={[
              {
                label: 'Rename',
                onClick: () =>
                  subdivisionEditorState.zonesState.commands.setEditingZoneName(
                    zone._id
                  ),
              },
              {
                label: 'Delete',
                isDisabled: !canDeleteZone,
                description: !canDeleteZone
                  ? 'You cannot delete this field group'
                  : undefined,
                onClick: () => {
                  trackDivisionEditorUserEvent(
                    DIVISIONS_USER_EVENTS.CLICKED_DELETE_ZONE
                  );
                  confirmDelete({
                    title: `Delete ${zone.name}`,
                    text: `Are you sure you want to delete ${zone.name}?`,
                    isConfirmButtonDanger: true,
                    confirmButtonTitle: 'Delete',
                    onConfirmAsync: () =>
                      subdivisionEditorState.zonesState.commands.deleteZone(
                        zone
                      ),
                  });
                },
              },
            ]}
            buttonType={ButtonType.GHOST}
          />
        ),
        isConfigured: () => true,
        stepValidation: _ => zonesOperations.zoneValidation(zone),
        component: PermissionsConfigurationSubStep,
        previousButtonLabel: 'Back: Configure permissions',
        hideNextButton: true,
        onSave: subdivisionEditorState.zonesState.commands.savePermissions,
        onPreviousButtonClicked: () => {
          subdivisionEditorState.zonesState.commands.goToPermissionsConfigurationStep();
          return Promise.resolve(false);
        },
      };
    }
  );
};
