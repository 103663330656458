import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { RadioGroup } from '@ardoq/forms';
import { dispatchAction } from '@ardoq/rxbeach';
import { selectBarChartColorSelection } from '../../actions';
import { BarChartColorOptions, BarChartConfiguration } from '@ardoq/api-types';

const BarChartColorSelectionSection = ({
  colorSelection = BarChartColorOptions.SINGLE_COLOR,
}: Pick<BarChartConfiguration, 'colorSelection'>) => (
  <SidebarMenuSection title="Color selection" isCollapsed>
    <RadioGroup
      label="Color by"
      options={[
        {
          label: 'Single color',
          value: BarChartColorOptions.SINGLE_COLOR,
        },
        {
          label: 'Color theme',
          value: BarChartColorOptions.COLOR_THEME,
        },
      ]}
      onValueChange={value => {
        dispatchAction(
          selectBarChartColorSelection(value as BarChartColorOptions)
        );
      }}
      value={colorSelection}
    />
  </SidebarMenuSection>
);

export default BarChartColorSelectionSection;
