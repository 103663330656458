import { radius } from '@ardoq/design-tokens';
import { AqLayout, NavItem } from '@ardoq/layout';
import { Island } from '@ardoq/page-layout';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import CreateWebhookForm from './CreateWebhookForm';
import { Tabs, WebhookStreamState, selectTab, webhook$ } from './webhook$';
import WebhookList from './WebhookList';

const WebhookManagement = ({
  webhooks,
  registrationError,
  selectedTab,
}: WebhookStreamState) => {
  return (
    <Island
      title="Webhooks"
      subtitle="Manage and configure automated webhooks."
      bodySpacing="none"
    >
      <AqLayout
        layoutWrapperStyle={{
          overflow: 'hidden',
          borderBottomLeftRadius: radius.medium,
          borderBottomRightRadius: radius.medium,
        }}
        renderLeftSidebar={() => (
          <>
            <NavItem
              $active={selectedTab === Tabs.MANAGE}
              onClick={() => dispatchAction(selectTab(Tabs.MANAGE))}
            >
              Manage Webhooks
            </NavItem>
            <NavItem
              $active={selectedTab === Tabs.CREATE}
              onClick={() => dispatchAction(selectTab(Tabs.CREATE))}
            >
              Create Webhook
            </NavItem>
          </>
        )}
      >
        {selectedTab === Tabs.CREATE ? (
          <CreateWebhookForm registrationError={registrationError} />
        ) : (
          <WebhookList webhooks={webhooks} />
        )}
      </AqLayout>
    </Island>
  );
};

export default connect(WebhookManagement, webhook$);
