import {
  LabelsTab,
  MultiLabelFormattingProps,
} from '@ardoq/perspectives-sidebar';
import { connect } from '@ardoq/rxbeach';
import labelsTab$ from 'perspectiveSidebar/perspectiveEditor/labelsTab$';
import ViewOptionsPerspectivesPage from './ViewOptionsPerspectivesPage';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';

const ViewOptionsPerspectivesLabels = (props: MultiLabelFormattingProps) => (
  <ViewOptionsPerspectivesPage
    title="Labels"
    knowledgeBaseLink={KnowledgeBaseLink.LABEL_FORMATTING}
    shareFeedbackUrl="https://ardoq.productboard.com/feature-board/8381974-team-insight-eou-feature-list"
    isBeta
  >
    <LabelsTab {...props} />
  </ViewOptionsPerspectivesPage>
);

export default connect(ViewOptionsPerspectivesLabels, labelsTab$);
