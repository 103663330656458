import { getCurrentLocale, localeCompareBase } from '@ardoq/locale';
import { APIFieldType } from '@ardoq/api-types';
import type { Field } from 'integrations/common/streams/fields/types';
import fp from 'lodash/fp';

/**
 * sorting utils
 */
type ByIndexItem = {
  index: number;
};
export const byIndex = (a: ByIndexItem, b: ByIndexItem) => a.index - b.index;

type ByFieldNameItem<FieldName extends string> = {
  [K in FieldName]: string;
};
export const byLocaleField =
  <FieldName extends string>(field: FieldName) =>
  (a: ByFieldNameItem<FieldName>, b: ByFieldNameItem<FieldName>) =>
    localeCompareBase(a[field], b[field], getCurrentLocale());

export const byNumericValueAsc = (a: number, b: number) => a - b;
/**
 * case transforming utils
 */

export const toPascalCase = (text: string): string => {
  return text.replace(/(^|-)(\w)/g, (_, __, char) => char.toUpperCase());
};

export const filterFieldsByType = fp.curry(
  (types: APIFieldType[], fields: Field[]): Field[] =>
    fields.filter(({ type }) => !type || types.includes(type as APIFieldType))
);

export const areArraysEqual = (array1: string[], array2: string[]) => {
  const sortedArray1 = fp.sortBy(fp.identity)(array1);
  const sortedArray2 = fp.sortBy(fp.identity)(array2);
  return fp.isEqual(sortedArray1, sortedArray2);
};

export function exhaustiveCheck(value: never): never {
  throw new Error(`Unhandled value: ${value}`);
}
