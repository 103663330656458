import {
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { showAppModule } from 'appContainer/actions';
import { tap } from 'rxjs';
import { AppModules } from 'appContainer/types';
import { ViewIds } from '@ardoq/api-types';
import defaultState, { FIELDS_TO_RESET } from 'views/defaultState';
import { isNumber } from 'lodash';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { isPresentationMode } from 'appConfig';
import { pairwise, startWith, withLatestFrom } from 'rxjs/operators';
import { getViewSettingsStream } from './viewSettingsStreams';

const fieldsToResetList = Object.values(FIELDS_TO_RESET);

// get the viewIds with ViewSettings that have properties to reset
const viewSettingsToReset = [...defaultState.entries()].reduce<
  { viewId: ViewIds; settings: Record<string, any> }[]
>((acc, [viewId, viewSettings]: [ViewIds, Record<string, any>]) => {
  const settingsToReset = fieldsToResetList.reduce<Record<string, any>>(
    (settings, field) => {
      if (isNumber(viewSettings[field])) {
        settings[field] = viewSettings[field];
      }

      return settings;
    },
    {}
  );

  if (Object.keys(settingsToReset).length > 0) {
    acc.push({ viewId, settings: settingsToReset });
  }
  return acc;
}, []);

// reset the "degrees" view settings to default on initial app load and on workspace app module close
const relationshipsDegreesReset = routine(
  ofType(showAppModule),
  extractPayload(),
  startWith({ selectedModule: null }), // null is used to indicate initial app load
  pairwise(),
  withLatestFrom(
    // get current user view settings data of views with settings to reset
    ...viewSettingsToReset.map(({ viewId }) => getViewSettingsStream(viewId))
  ),
  tap(
    ([
      [{ selectedModule: prevSelectedModule }, { selectedModule }],
      ...userViewSettings
    ]) => {
      const isInitialAppLoad = prevSelectedModule === null;
      const isWorkspacesViewClosed =
        prevSelectedModule === AppModules.WORKSPACES &&
        selectedModule !== AppModules.WORKSPACES;

      if (
        userViewSettings.length &&
        (isInitialAppLoad || isWorkspacesViewClosed) &&
        !isPresentationMode()
      ) {
        const userViewSettingsMap = viewSettingsToReset.reduce<
          Partial<Record<ViewIds, any>>
        >((acc, { viewId }, currentIndex) => {
          acc[viewId] = userViewSettings[currentIndex];
          return acc;
        }, {});

        viewSettingsToReset.forEach(({ viewId, settings }) => {
          const shouldResetViewSettings = Object.entries(settings).some(
            ([settingName, defaultValue]) => {
              const currentValue = userViewSettingsMap[viewId][settingName];
              return currentValue !== defaultValue;
            }
          );

          if (shouldResetViewSettings) {
            onViewSettingsUpdate(viewId, settings, true);
          }
        });

        onViewSettingsUpdate(
          ViewIds.DEPENDENCY_MAP_2,
          { treeDepth: defaultState.get(ViewIds.DEPENDENCY_MAP_2).treeDepth },
          true
        );
      }
    }
  )
);

export const viewSettingsRoutines = collectRoutines(relationshipsDegreesReset);
