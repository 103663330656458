import { confirm } from '@ardoq/modal';

export const confirmMakePresentationPublic = async () => {
  return confirm({
    title: 'Confirmation required',
    text: (
      <>
        <p>
          This presentation will now be accessible to anyone with the URL. No
          login required.
        </p>
        <p>
          When shared, people outside of your intended audience could
          potentially find and access this presentation and any confidential
          information within it.
        </p>
      </>
    ),
    isConfirmButtonDanger: true,
  });
};
