import { PermissionGroup } from '@ardoq/api-types';
import {
  ALL_ORG_MEMBERS_DISPLAY_LABEL,
  ALL_ORG_MEMBERS_LOOK_UP_ID,
  ENTIRE_ORGANIZATION_GROUP_DISPLAY_LABEL,
  ENTIRE_ORGANIZATION_GROUP_LOOK_UP_ID,
} from '@ardoq/manage-resource-permissions';

export type GroupsState = {
  groups: PermissionGroup[];
  isLoading: boolean;
};

export const ALL_ORGANIZATION_MEMBERS_GROUP = {
  name: ALL_ORG_MEMBERS_DISPLAY_LABEL,
  label: ALL_ORG_MEMBERS_LOOK_UP_ID,
} as PermissionGroup;

export const ENTIRE_ORGANIZATION_GROUP = {
  name: ENTIRE_ORGANIZATION_GROUP_DISPLAY_LABEL,
  label: ENTIRE_ORGANIZATION_GROUP_LOOK_UP_ID,
} as PermissionGroup;
