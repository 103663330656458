import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { DiffMode, ViewIds } from '@ardoq/api-types';
import { distinctUntilKeyChanged, map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const activeDiffMode$: Observable<DiffMode> = getViewSettingsStream(
  ViewIds.BLOCK_DIAGRAM
).pipe(
  distinctUntilKeyChanged('activeDiffMode'),
  map(({ activeDiffMode }) => activeDiffMode),
  shareReplay({ bufferSize: 1, refCount: true })
);

let currentActiveDiffMode: DiffMode = DiffMode.MAIN;

activeDiffMode$.subscribe(
  activeDiffMode => (currentActiveDiffMode = activeDiffMode)
);

export const getActiveDiffMode = () => currentActiveDiffMode;
