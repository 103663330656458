import {
  dispatchAction,
  collectRoutines,
  routine,
  apply,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  restoreComponent,
  restoreComponentError,
  restoreComponentSuccess,
} from './component.actions';
import { mergeMap, tap } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { handleError, restoreApi } from '@ardoq/api';

const handleRestoreComponent = routine(
  ofType(restoreComponent),
  extractPayload(),
  apply(({ component }) =>
    pipe(
      mergeMap(() =>
        restoreApi.component({
          workspace: component.workspaceId,
          component: component._id,
        })
      ),
      handleError(() => dispatchAction(restoreComponentError({ component }))),
      tap(() => dispatchAction(restoreComponentSuccess({ component })))
    )
  )
);

export const restoreComponentRoutines = collectRoutines(handleRestoreComponent);
