import fp from 'lodash/fp';
import { ImportRoute } from 'integrations/common/navigation/types';
import { TabularMapping } from 'integrations/common/streams/tabularMappings/types';
import { TransferState } from 'integrations/common/streams/transferState/types';
import {
  isDryRunOutOfDate,
  isSuccessTransfer,
} from 'integrations/common/streams/transferState/utils';
import { AsyncStatus } from 'integrations/common/types/api';
import { SelectionState } from './streams/selectionState/types';
import {
  areFetchedTablesInSync,
  isValidSelectionState,
} from './streams/selectionState/utils';
import { StepState } from '@ardoq/steppers';
import { userTableFields } from './streams/tables/tables$';

type Props = {
  tabularMapping: TabularMapping;
  transferState: TransferState;
  selectionState: SelectionState;
};

export const getSelectDataStepState = (selectionState: SelectionState) =>
  isValidSelectionState(selectionState) ? StepState.DONE : StepState.ACTIVE;

const isConfigureStepAvailable = ({ selectionState }: Props) => {
  return areFetchedTablesInSync(selectionState);
};

const isReviewStepAvailable = (props: Props) => {
  return (
    isConfigureStepAvailable(props) &&
    !isDryRunOutOfDate(props.transferState, props.tabularMapping)
  );
};

const isTransferStepAvailable = (props: Props) => {
  return isReviewStepAvailable(props) && isSuccessTransfer(props.transferState);
};

const STEP_AVAILABILITY: Record<ImportRoute, (props: Props) => boolean> = {
  [ImportRoute.SELECT_DATA]: () => true,
  [ImportRoute.CONFIGURE]: isConfigureStepAvailable,
  [ImportRoute.REVIEW]: isReviewStepAvailable,
  [ImportRoute.IMPORT_AND_SCHEDULE]: isTransferStepAvailable,
};

export const isStepAvailable = (path: ImportRoute, props: Props): boolean => {
  return STEP_AVAILABILITY[path](props);
};

export const getLoadingMessage = ({
  selectionState,
  transferState,
}: {
  selectionState: SelectionState;
  transferState: { asyncStatus: AsyncStatus; dryRun: boolean };
}) => {
  if (transferState.asyncStatus === 'LOADING') {
    return transferState.dryRun
      ? 'Reviewing the import...'
      : 'Just a moment, importing data...';
  }

  if (selectionState.asyncStatus === 'LOADING') {
    return 'Fetching selected data...';
  }

  return 'Loading';
};

export const getOrderedUserTableFields = (
  fields: string[]
): Array<{ name: string }> => {
  return userTableFields
    .filter(userTableField => fields.includes(userTableField.id))
    .map(userTableField => ({ name: userTableField.id }));
};

export const getSourceConfig = (selectionState: SelectionState) => {
  const tables = fp.flow(
    fp.toPairs,
    fp.filter(([_, table]) => !fp.isEmpty(table.fields)),
    fp.map(([tableId, { fields }]) => ({
      name: tableId,
      fields: getOrderedUserTableFields(Object.keys(fields)),
    }))
  )(selectionState.tables || []);

  return {
    name: 'AD',
    tables,
  };
};
