import LandingPage from 'appContainer/landingPage/LandingPage';
import { FEATURE_KEY_PRESENTATIONS } from 'appContainer/landingPage/consts';

const TITLE = `Your plan doesn’t include Presentations`;
const SUBTITLE = `Share live presentations across your organization`;
const DESCRIPTION = `
  Ardoq presentations show an always up-to-date view of your data to your
  colleagues, or to the public. Embed presentations in your intranet for easy
  information sharing. Tell a story with live data to illustrate your point.
`;
const VIDEO_URL = `https://player.vimeo.com/video/259326836?byline=0&portrait=0`;

const VIEW_MODEL = {
  featureKey: FEATURE_KEY_PRESENTATIONS,
  title: TITLE,
  subtitle: SUBTITLE,
  description: DESCRIPTION,
  videoUrl: VIDEO_URL,
  requiresOrgAdmin: () => false,
};

const PresentationLandingPage = () => <LandingPage viewModel={VIEW_MODEL} />;
export default PresentationLandingPage;
