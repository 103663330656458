import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  setTabularMappingsDefault,
  resetTabularMappingsDefault,
} from './actions';
import { buildInitialStreamState } from '../utils';
import {
  TabularMappingsDefaultMapping,
  TabularMappingsDefaultState,
} from './types';
import { ActionCreatorParameter } from 'integrations/common/utils/actionCreatorWithIntegrationId';

const createInitialTabularMappingsDefaultState =
  (): TabularMappingsDefaultState => ({});

const defaultState: TabularMappingsDefaultMapping = buildInitialStreamState(
  createInitialTabularMappingsDefaultState
);

const handleSetTabularMappingsDefaultMapping = (
  state: TabularMappingsDefaultMapping,
  {
    integrationId,
    tabularMappingsDefault,
  }: ActionCreatorParameter<typeof setTabularMappingsDefault>
) => {
  return {
    ...state,
    [integrationId]: tabularMappingsDefault,
  };
};

const setIntegrationDefaultMappingReducer = reducer<
  TabularMappingsDefaultMapping,
  ActionCreatorParameter<typeof setTabularMappingsDefault>
>(setTabularMappingsDefault, handleSetTabularMappingsDefaultMapping);

const handleResetTabularMappingsDefaultMapping = (
  state: TabularMappingsDefaultMapping,
  { integrationId }: ActionCreatorParameter<typeof resetTabularMappingsDefault>
) => ({
  ...state,
  [integrationId]: {},
});

const resetTabularMappingsDefaultMappingReducer = reducer<
  TabularMappingsDefaultMapping,
  ActionCreatorParameter<typeof resetTabularMappingsDefault>
>(resetTabularMappingsDefault, handleResetTabularMappingsDefaultMapping);

export const defaultTabularMapping$ = persistentReducedStream(
  `integrationDefaultTabularMapping$`,
  defaultState,
  [
    setIntegrationDefaultMappingReducer,
    resetTabularMappingsDefaultMappingReducer,
  ]
);
