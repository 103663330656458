import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { DateRangeSectionProps } from '../types';
import { FilterSections } from '../consts';
import { DateTimePicker } from '@ardoq/date-time-picker';
import { parseLocaleDateString } from '@ardoq/date-time';
import { Label } from '@ardoq/forms';
import {
  formatDateTime,
  setSeconds,
  setMilliseconds,
  startOfToday,
  subDays,
} from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { IconName } from '@ardoq/icons';
import { Paragraph } from '@ardoq/typography';
import { FlexBox, Stack } from '@ardoq/layout';

const getPopoverContent = (dateString: string | null) =>
  dateString ? formatDateTime(dateString, getCurrentLocale()) : null;

const getLocalizedDateValue = (dateString: string | null) =>
  dateString ? parseLocaleDateString(dateString, getCurrentLocale()) : null;

const formatNewDateValue = (date: Date) =>
  setSeconds(setMilliseconds(date, 0), 0).toISOString();

const DateRangeSection = ({
  selected,
  hasFullAuditLogHistoryFeature,
  onValueChange,
}: DateRangeSectionProps) => {
  const { since, before } = selected;
  const onSinceChange = (value: Date | null) => {
    if (value === null && since !== null) {
      onValueChange({ since: null, before: null });
    } else if (value) {
      const formattedValue = formatNewDateValue(value);
      if (formattedValue !== since) {
        onValueChange({
          ...selected,
          since: formattedValue,
        });
      }
    }
  };
  const onBeforeChange = (value: Date | null) => {
    if (value === null && before !== null) {
      onValueChange({ ...selected, before: null });
    } else if (value) {
      const formattedValue = formatNewDateValue(value);
      if (formattedValue !== before) {
        onValueChange({
          ...selected,
          before: formattedValue,
        });
      }
    }
  };
  return (
    <SidebarMenuSection
      title={FilterSections.DATE_RANGE}
      leftIcon={{ name: IconName.CALENDAR }}
    >
      <FlexBox paddingX="medium">
        <Stack gap="small">
          <Label>{'Select date and time'}</Label>
          {!hasFullAuditLogHistoryFeature && (
            <Paragraph variant="caption" color="textSubtle">
              {'The audit log shows data of up to 30 days ago'}
            </Paragraph>
          )}
          <FlexBox marginTop="small" gap="small">
            <DateTimePicker
              date={getLocalizedDateValue(since)}
              popoverContent={getPopoverContent(since)}
              onChange={onSinceChange}
              placeholder={'Start date'}
              config={{
                formatter: formatDateTime,
                locale: getCurrentLocale(),
                useTimePicker: true,
                maxDate: getLocalizedDateValue(before) ?? undefined,
                minDate: !hasFullAuditLogHistoryFeature
                  ? subDays(startOfToday(), 30)
                  : undefined,
                disabledDayExplanationText: !hasFullAuditLogHistoryFeature
                  ? 'The audit log shows data of up to 30 days ago.'
                  : undefined,
              }}
              additionalDropdownOffset={{
                left: -8,
              }}
            />
            <DateTimePicker
              date={getLocalizedDateValue(before)}
              popoverContent={getPopoverContent(before)}
              isDisabled={!since}
              onChange={onBeforeChange}
              placeholder={'End date'}
              config={{
                formatter: formatDateTime,
                locale: getCurrentLocale(),
                useTimePicker: true,
                minDate: getLocalizedDateValue(since) ?? undefined,
              }}
              additionalDropdownOffset={{
                left: 8,
              }}
            />
          </FlexBox>
        </Stack>
      </FlexBox>
    </SidebarMenuSection>
  );
};

export default DateRangeSection;
