import { Label } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import { ReferenceLabelSource } from '@ardoq/data-model';
import { s16 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  APIFieldAttributes,
  APIFieldType,
  APIDiscoverViewpointAttributes,
  FilterInterfaceFilter,
  FormattingFilter,
  FilterTypes,
  LabelFormattingAttributes,
} from '@ardoq/api-types';
import {
  getRelevantFields,
  isComponentField,
  isReferenceField,
} from '../utils';
import { useFormContext } from 'react-hook-form';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';
import { Space } from '@ardoq/style-helpers';
import { fieldInterface } from '@ardoq/field-interface';
import { IncludeTimeCheckbox } from './IncludeTimeCheckbox';

const baseFormat = {
  affectComponent: false,
  affectReference: false,
  isNegative: false,
  name: '',
};

const FormattingName = styled.p`
  margin-bottom: 0;
  flex-grow: 1;
  justify-self: flex-start;
  max-width: 83px;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 83px minmax(320px, 447px) 150px;
  column-gap: ${s16};
  align-items: center;
`;

const StyledSpace = styled(Space)`
  max-width: 780px;
`;

const defaultComponentOptions = [
  { label: 'Name (default)', value: 'name' },
  { label: 'Component type', value: 'type' },
];

const defaultReferenceOptions = [
  {
    label: 'Display Text or Reference Type (default)',
    value: ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE,
  },
  {
    label: 'Display Text',
    value: ReferenceLabelSource.DISPLAY_TEXT,
  },
  {
    label: 'Reference Type',
    value: ReferenceLabelSource.REFERENCE_TYPE,
  },
];

const getLabelFormatOptions = (
  type: FilterTypes.COMPONENT_LABEL | FilterTypes.REFERENCE_LABEL,
  relevantFields: APIFieldAttributes[]
) => {
  const customFields = {
    label: 'Custom fields',
    options: [
      ...relevantFields
        .filter(field =>
          type === FilterTypes.COMPONENT_LABEL
            ? isComponentField(field)
            : isReferenceField(field)
        )
        .flatMap(field =>
          isDateRangeFieldType(field.type)
            ? dateRangeOperations.splitDateRangeFieldIntoStartAndEndSelectOptions(
                field
              )
            : {
                label: field.label,
                value: field.name,
              }
        ),
    ],
  };
  return [
    {
      label: 'Default fields',
      options:
        type === FilterTypes.COMPONENT_LABEL
          ? defaultComponentOptions
          : defaultReferenceOptions,
    },
    customFields,
    {
      label: 'Other',
      options: [{ label: 'None', value: 'none' }],
    },
  ].filter(ExcludeFalsy);
};

const isDateTimeField = (fieldName: string) => {
  const field = fieldInterface.getByName(fieldName, {
    acrossWorkspaces: true,
    includeTemplateFields: false,
  });
  return field?.type === APIFieldType.DATE_TIME;
};

const defaultCompLabelFormatting: FilterInterfaceFilter = {
  ...baseFormat,
  affectComponent: true,
  value: 'name',
  type: FilterTypes.COMPONENT_LABEL,
};

const defaultRefLabelFormatting: FilterInterfaceFilter = {
  ...baseFormat,
  affectReference: true,
  value: ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE,
  type: FilterTypes.REFERENCE_LABEL,
};

export const defaultLabelFormatting = [
  defaultCompLabelFormatting,
  defaultRefLabelFormatting,
];

type LabelFormattingProps = {
  onChange: (value: (FormattingFilter | FilterInterfaceFilter)[]) => void;
};

const LabelFormatting = ({ onChange }: LabelFormattingProps) => {
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const [conditionalFormatting, workspaceIds] = watch([
    'conditionalFormatting',
    'workspaceIds',
  ]);
  const { fields: relevantFields } =
    dateRangeOperations.mergeDateTimeFieldsToDateRangeFields(
      getRelevantFields(workspaceIds)
    );
  const labelFormatting =
    conditionalFormatting?.filter(format => format.type !== 'attribute') ?? [];

  const compLabelFormatting =
    (labelFormatting.find(
      format => format.affectComponent === true
    ) as LabelFormattingAttributes) ?? defaultCompLabelFormatting;

  const refLabelFormatting =
    (labelFormatting.find(
      format => format.affectReference === true
    ) as LabelFormattingAttributes) ?? defaultRefLabelFormatting;

  const handleSetLabelFormatting = ({
    includeTime,
    ...restFormatData
  }: FilterInterfaceFilter) => {
    const format =
      includeTime && isDateTimeField(restFormatData.value)
        ? { includeTime, ...restFormatData }
        : restFormatData;

    const formatIndex = labelFormatting?.findIndex(
      existingFormat => existingFormat.type === format.type
    );
    const updatedFormatting = [
      ...labelFormatting.slice(0, formatIndex),
      format,
      ...labelFormatting.slice(formatIndex + 1),
    ];

    onChange([
      ...updatedFormatting,
      ...(conditionalFormatting?.filter(({ type }) => type === 'attribute') ??
        []),
    ]);
  };

  const isComponentIncludeTimeCheckboxVisible = isDateTimeField(
    compLabelFormatting.value
  );
  const isReferenceIncludeTimeCheckboxVisible = isDateTimeField(
    refLabelFormatting.value
  );

  return (
    <StyledSpace $isVertical>
      <Label>Select label formatting</Label>
      <FormRow>
        <FormattingName>Component</FormattingName>
        <Select
          menuPlacement="auto"
          onValueChange={value => {
            if (!value) return;
            handleSetLabelFormatting({
              ...compLabelFormatting,
              value: value as string,
            });
          }}
          options={getLabelFormatOptions(
            FilterTypes.COMPONENT_LABEL,
            relevantFields
          )}
          value={compLabelFormatting.value}
          dataTestId="component-label-select"
        />
        <IncludeTimeCheckbox
          isVisible={isComponentIncludeTimeCheckboxVisible}
          labelFormatting={compLabelFormatting}
          handleSetLabelFormatting={handleSetLabelFormatting}
        />
      </FormRow>
      <FormRow>
        <FormattingName>Reference</FormattingName>
        <Select
          menuPlacement="auto"
          onValueChange={value => {
            if (!value) return;
            handleSetLabelFormatting({
              ...refLabelFormatting,
              value: value as string,
            });
          }}
          options={getLabelFormatOptions(
            FilterTypes.REFERENCE_LABEL,
            relevantFields
          )}
          value={refLabelFormatting.value}
          dataTestId="reference-label-select"
        />
        <IncludeTimeCheckbox
          isVisible={isReferenceIncludeTimeCheckboxVisible}
          labelFormatting={refLabelFormatting}
          handleSetLabelFormatting={handleSetLabelFormatting}
        />
      </FormRow>
    </StyledSpace>
  );
};

export default LabelFormatting;
