import { dispatchAction } from '@ardoq/rxbeach';
import {
  isSearchLoadedState,
  CreatedItemsLoadedStateParams,
  isCreatedItemsLoadedState,
  LoadedState,
  SearchLoadedStateParams,
  TraversalParams,
  LoadedStateParams,
  LoadedGraphChunk,
} from '@ardoq/api-types';
import { scopeDataOperations } from '@ardoq/scope-data';
import { enterViewpointMode } from 'streams/traversals/actions';
import { Features, hasFeature } from '@ardoq/features';
import { registerLoadedState } from './actions';
import { advancedSearchApi, traversalApi } from '@ardoq/api';
import { scopeDataReset } from 'streams/enhancedScopeData/actions';
import { scopeDataLoaded } from 'traversals/stagedLoadedDataAndState$';
import { getStartSetAndStartQueryFromComponentSelection } from 'viewpointBuilder/getComponentSelection';
import { isArdoqError } from '@ardoq/common-helpers';
import { logBuildLoadedStateError } from './logBuildTraversalError';
import { loadedStateTracking } from './loadedStateTracking';

export const buildSearchState = async (
  params: SearchLoadedStateParams,
  abortSignal?: AbortSignal
): Promise<LoadedGraphOrError> => {
  const { componentSelection } = params.data;
  const { startSet, startQuery } =
    getStartSetAndStartQueryFromComponentSelection(componentSelection);

  const scopeDataOrError = await advancedSearchApi.scopeData(
    {
      componentIds: startSet,
      query: startQuery ?? undefined,
    },
    abortSignal
  );
  if (isArdoqError(scopeDataOrError)) {
    return scopeDataOrError.isAborted
      ? { error: 'Aborted', reason: TraversalErrorReason.ABORTED }
      : {
          error: 'Failed to load search',
          reason: TraversalErrorReason.UNKNOWN,
        };
  }
  loadedStateTracking.trackExecutionResult(scopeDataOrError);
  return {
    scopeData: scopeDataOrError,
    componentIdsAndReferences: {
      componentIds: scopeDataOrError.scopeComponents,
      references: [],
      parentChildReferences: [],
      startSetResult: scopeDataOrError.scopeComponents,
    },
  };
};

export const buildStateForCreatedItems = async (
  params: CreatedItemsLoadedStateParams,
  abortSignal?: AbortSignal
): Promise<LoadedGraphOrError> => {
  const { componentIds, referenceIds } = params.data;
  const scopeDataOrError = await advancedSearchApi.scopeData(
    {
      componentIds,
      referenceIds,
    },
    abortSignal
  );
  if (isArdoqError(scopeDataOrError)) {
    return scopeDataOrError.isAborted
      ? { error: 'Aborted', reason: TraversalErrorReason.ABORTED }
      : {
          error: 'Failed to load created items loaded state',
          reason: TraversalErrorReason.UNKNOWN,
        };
  }

  return {
    scopeData: scopeDataOrError,
    componentIdsAndReferences: {
      componentIds: scopeDataOrError.scopeComponents,
      references: referenceIds.length > 0 ? scopeDataOrError.references : [],
      parentChildReferences: [],
      startSetResult: scopeDataOrError.scopeComponents,
    },
  };
};

export const buildTraversalState = async (
  params: TraversalParams,
  abortSignal?: AbortSignal
): Promise<LoadedGraphOrError> => {
  if (!hasFeature(Features.SUPPORT_LARGE_DATASETS)) {
    return {
      error: 'Failed to load traversal',
      reason: TraversalErrorReason.UNKNOWN,
    };
  }

  try {
    const {
      paths,
      filters,
      pathMatching,
      pathCollapsingRules,
      componentSelection,
    } = params;

    const { startSet, startQuery } =
      getStartSetAndStartQueryFromComponentSelection(componentSelection);

    const result = await traversalApi.loadTraversal(
      {
        startSet,
        paths,
        startQuery: startQuery ?? null,
        filters,
        pathMatching,
        pathCollapsingRules: pathCollapsingRules ?? [],
      },
      abortSignal
    );

    if (isArdoqError(result)) {
      return result.isAborted
        ? { error: 'Aborted', reason: TraversalErrorReason.ABORTED }
        : {
            error: 'Failed to load traversal',
            reason: TraversalErrorReason.UNKNOWN,
          };
    }

    const { scopeData: rawScopeData, startSetResult } = result;

    loadedStateTracking.trackExecutionResult(rawScopeData);

    const { scopeData, parentChildReferences } =
      scopeDataOperations.partitionParentChildReferences(rawScopeData);
    return {
      scopeData,
      componentIdsAndReferences: {
        componentIds: scopeData.scopeComponents,
        references: scopeData.references,
        parentChildReferences,
        startSetResult,
      },
    };
  } catch (e) {
    return {
      error:
        (e as Error)?.message ??
        'Unknown error happened when building traversal state',
      reason: TraversalErrorReason.UNKNOWN,
    };
  }
};

export enum TraversalErrorReason {
  USER_CANCELLED = 'user-cancelled',
  NETWORK_ERROR = 'network-error',
  MISSING_DATA = 'missing-data',
  UNKNOWN = 'unknown',
  ABORTED = 'aborted',
}

export type TraversalError = {
  error: string;
  reason: TraversalErrorReason;
};

export type LoadedGraphOrError = TraversalError | LoadedGraphChunk;

type RebuildAndRegisterStateArgs = {
  loadedState: LoadedStateParams[];
  abortSignal?: AbortSignal;
};

export const rebuildAndRegisterState = async ({
  loadedState,
  abortSignal,
}: RebuildAndRegisterStateArgs) => {
  dispatchAction(enterViewpointMode());
  dispatchAction(scopeDataReset());
  const result = await rebuildActiveState({
    states: loadedState,
    abortSignal,
  });
  if (isError(result)) {
    logBuildLoadedStateError(result, loadedState);
    return result;
  }

  const trackingLocation = 'directURLVisit';
  result.map(({ scopeData }) => {
    dispatchAction(scopeDataLoaded({ scopeData, trackingLocation }));
  });

  const loadedStates = result;
  dispatchAction(registerLoadedState(loadedStates));
  return { error: null };
};

type RebuildActiveStateArgs = {
  states: LoadedStateParams[];
  abortSignal?: AbortSignal;
};

export const isError = (x: unknown): x is TraversalError =>
  Boolean(x && typeof x === 'object' && 'error' in x && x.error);

const rebuildActiveState = async ({
  states,
  abortSignal,
}: RebuildActiveStateArgs) => {
  const results: LoadedState[] = [];
  for (const state of states) {
    let loadedGraphOrError: LoadedGraphOrError;
    if (isSearchLoadedState(state)) {
      loadedGraphOrError = await buildSearchState(state, abortSignal);
    } else if (isCreatedItemsLoadedState(state)) {
      loadedGraphOrError = await buildStateForCreatedItems(state, abortSignal);
    } else {
      loadedGraphOrError = await buildTraversalState(state.data, abortSignal);
    }
    if (isError(loadedGraphOrError)) {
      return loadedGraphOrError;
    }
    const { componentIdsAndReferences, scopeData } = loadedGraphOrError;

    results.push({ ...state, componentIdsAndReferences, scopeData });
  }

  return results;
};
