import { PresentationMetadata, ViewIdCount } from './types';
import {
  ArdoqId,
  Report,
  Operator,
  APIReportDatasourceMigrationData,
  QueryBuilderRule,
  QueryBuilderSubquery,
  APIPresentationAssetAttributes,
} from '@ardoq/api-types';
import { slideInterface } from 'modelInterface/presentations/slideInterface';

const getSlideViewIdCount = (presentation: APIPresentationAssetAttributes) =>
  presentation.slides.reduce<ViewIdCount>((acc, slideId) => {
    const viewId = slideInterface.getViewId(slideId);
    if (viewId) {
      acc[viewId] = (acc[viewId] || 0) + 1;
    }

    return acc;
  }, {});

export const getPresentationMetadata = (
  presentation: APIPresentationAssetAttributes
): PresentationMetadata => ({
  id: presentation._id,
  name: presentation.name,
  author: presentation.createdByEmail,
  viewIdCount: getSlideViewIdCount(presentation),
});

export const extractRulesWithContainsPhrase = (
  query: QueryBuilderSubquery
): QueryBuilderRule[] =>
  query.rules.flatMap(rule =>
    'rules' in rule
      ? extractRulesWithContainsPhrase(rule)
      : rule.operator === Operator.CONTAINS_PHRASE ||
          rule.operator === Operator.NOT_CONTAINS_PHRASE
        ? rule
        : []
  );
export const mapRuleToString = (
  rule: QueryBuilderRule,
  fieldLabelByName: Record<string, string>
) =>
  `${fieldLabelByName[rule.field] ?? rule.field} ${
    rule.operator === Operator.CONTAINS_PHRASE
      ? 'contains phrase'
      : 'not contains phrase'
  } ${rule.value}`;

export const getIsReportThatNeedsMigration =
  (reportsById: Record<ArdoqId, Report>) =>
  (
    migrationData: APIReportDatasourceMigrationData
  ): migrationData is APIReportDatasourceMigrationData & {
    query: QueryBuilderSubquery;
  } => {
    const report = reportsById[migrationData._id];
    return Boolean(
      report && typeof migrationData.query !== 'string' && migrationData.query
    );
  };
