import { SurveyMiscAttributes } from '@ardoq/api-types';
import { SurveyErrorName, SurveySectionId, SurveyWarningName } from './consts';
import { SurveyValidation } from './types';

const hasError = (validation: SurveyValidation | undefined): boolean => {
  return !!validation && validation.errorMessagesMap.size > 0;
};

const hasWarning = (validation: SurveyValidation | undefined): boolean => {
  return !!validation && validation.warningMessagesMap.size > 0;
};

const containsErrorId = (
  validation: SurveyValidation | undefined,
  errorId: SurveyErrorName
): boolean => {
  return containsAnyErrorId(validation, [errorId]);
};

const containsWarningId = (
  errors: SurveyValidation | undefined,
  warningId: SurveyWarningName
): boolean => {
  return !!errors && errors.warningMessagesMap.has(warningId);
};

const containsAnyErrorId = (
  validation: SurveyValidation | undefined,
  errorIds: SurveyErrorName[]
): boolean => {
  return (
    !!validation &&
    errorIds.some(errorId => validation.errorMessagesMap.has(errorId))
  );
};

const sectionHasError = (
  validation: SurveyValidation | undefined,
  sectionId: SurveySectionId
): boolean => {
  return validation
    ? sectionMappedErrorNames[sectionId].some(errorId =>
        validation.errorMessagesMap.has(errorId)
      )
    : false;
};

const sectionHasWarning = (
  validation: SurveyValidation | undefined,
  sectionId: SurveySectionId
): boolean => {
  return validation
    ? sectionMappedWarningNames[sectionId].some(warningId =>
        validation.warningMessagesMap.has(warningId)
      )
    : false;
};

const getWarningMessageIfExists = (
  errors: SurveyValidation | undefined,
  warningId: SurveyWarningName
): string | undefined => {
  return errors?.warningMessagesMap.get(warningId);
};

const getOverviewErrorMessages = (validation: SurveyValidation): string[] => {
  return Array.from(validation.errorMessagesMap.values()).reduce<string[]>(
    (messages, errorMessage) => [...messages, errorMessage],
    []
  );
};

const getOverviewWarningMessages = (validation: SurveyValidation): string[] => {
  return Array.from(validation.warningMessagesMap.values()).reduce<string[]>(
    (messages, warningMessage) => [...messages, warningMessage],
    []
  );
};

const getErrorMessageIfExists = (
  validation: SurveyValidation | undefined,
  errorId: SurveyErrorName
): string | undefined => {
  return validation?.errorMessagesMap.get(errorId);
};

const getHiddenFieldsErrorMessages = (
  validation: SurveyValidation | undefined
): string | undefined => {
  if (validation && hasVisibleError(validation)) {
    return `Please check the validation errors ${getErrorMessageIfExists(validation, SurveyErrorName.HIDDEN_FIELDS) ?? ''}`;
  }
};

const hasVisibleError = (validation: SurveyValidation): boolean => {
  return Object.entries(sectionStatusPropertyBySectionId).some(
    ([sectionId, statusProp]) =>
      sectionHasError(validation, sectionId as SurveySectionId) &&
      (validation.misc[statusProp] ?? 'left') === 'left'
  );
};

export const validationHelpers = {
  hasError,
  hasWarning,
  containsErrorId,
  containsWarningId,
  containsAnyErrorId,
  sectionHasError,
  sectionHasWarning,
  getOverviewErrorMessages,
  getOverviewWarningMessages,
  getErrorMessageIfExists,
  getWarningMessageIfExists,
  getHiddenFieldsErrorMessages,
  hasVisibleError,
};

const sectionStatusPropertyBySectionId: Record<
  SurveySectionId,
  keyof SurveyMiscAttributes
> = {
  [SurveySectionId.GENERAL_INFORMATION]: 'generalInformationSectionStatus',
  [SurveySectionId.DEFINE_THE_DATA]: 'defineTheDataSectionStatus',
  [SurveySectionId.QUESTIONS]: 'questionsSectionStatus',
  [SurveySectionId.RESULTS_DISPLAY]: 'resultsDisplaySectionStatus',
  [SurveySectionId.CHANGE_APPROVAL]: 'changeApprovalSectionStatus',
};

const sectionMappedErrorNames: Record<SurveySectionId, SurveyErrorName[]> = {
  [SurveySectionId.GENERAL_INFORMATION]: [
    SurveyErrorName.NAME_INVALID,
    SurveyErrorName.CONTACT_EMAIL_INVALID,
  ],
  [SurveySectionId.DEFINE_THE_DATA]: [
    SurveyErrorName.WORKSPACE_INVALID,
    SurveyErrorName.COMPONENT_TYPE_INVALID,
    SurveyErrorName.REQUIRED_NEW_COMPONENT_PARENT_IS_MISSING,
    SurveyErrorName.SELECTED_NEW_COMPONENT_PARENT_DOES_NOT_EXIST,
    SurveyErrorName.NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE,
  ],
  [SurveySectionId.QUESTIONS]: [
    SurveyErrorName.HIDDEN_FIELDS,
    SurveyErrorName.MISSING_NAME_QUESTION,
    SurveyErrorName.REQUIRED_NEW_COMPONENT_PARENT_IS_MISSING,
    SurveyErrorName.QUESTION_ERRORS_EXIST,
  ],
  [SurveySectionId.RESULTS_DISPLAY]: [
    SurveyErrorName.MISSING_RESULT_FILTERING_FIELDS,
    SurveyErrorName.SELECTED_RESULT_FILTERING_COMPONENT_TYPE_INVALID,
  ],
  [SurveySectionId.CHANGE_APPROVAL]: [
    SurveyErrorName.CHANGE_APPROVAL_COMPATIBILITY,
    SurveyErrorName.CHANGE_APPROVAL_FALLBACK_USER,
  ],
};

const sectionMappedWarningNames: Record<SurveySectionId, SurveyWarningName[]> =
  {
    [SurveySectionId.GENERAL_INFORMATION]: [],
    [SurveySectionId.DEFINE_THE_DATA]: [],
    [SurveySectionId.CHANGE_APPROVAL]: [
      SurveyWarningName.CHANGE_APPROVAL_EMPTY_USER_GROUP,
    ],
    [SurveySectionId.RESULTS_DISPLAY]: [
      SurveyWarningName.NO_ACCESS_TO_SELECTED_RESULT_FILTERING_WORKSPACE,
    ],
    [SurveySectionId.QUESTIONS]: [SurveyWarningName.QUESTION_WARNINGS_EXIST],
  };
