import {
  DataSource,
  DataSourceItem,
  DiffTableRowType,
  MergeState,
} from 'components/DiffMergeTable/types';
import { Branch } from 'components/DiffMergeTable/Branch';
import { groupBy, mapValues } from 'lodash';
import { omit } from 'lodash/fp';
import {
  fieldAttributesMap,
  FieldType,
  getEntityById,
  readRawValue,
} from '@ardoq/renderers';
import {
  APIComponentAttributes,
  APIEntityType,
  APIFieldAttributes,
  APIModelAttributes,
  APIReferenceAttributes,
  APITagAttributes,
  ArdoqId,
  Entity,
  ScopeDataCollection,
  Verb,
  APIResetData,
  APIResetRequest,
} from '@ardoq/api-types';
import { DataSourceGroup } from './types';
import { EnhancedDiffScopeData } from 'components/DiffMergeTable/enhanceDiffContextData';
import { getKeyAndPayloadForComponentAndReferenceTypes } from 'components/DiffMergeTable/typeCollectionsUtil';
import { shouldMapEntityTypeForFields } from 'scope/merge/utils';

const deepGroupBy = <T>(seq: T[], [first, ...rest]: (keyof T)[]): any => {
  if (!first) return seq;
  return mapValues(groupBy(seq, first), value => deepGroupBy<T>(value, rest));
};

const getPathTypesafe = (path: any[]) =>
  path as [Exclude<ScopeDataCollection, 'permissions'>, Verb, ArdoqId, string];

const entries = Object.entries as <T>(
  o: T
) => [Extract<keyof T, string>, T[keyof T]][];

const getAPIEntityTypeKey = (entityType: string) => `${entityType}s`;

const getVerbFromPath = (path: string[]) => getPathTypesafe(path)[1];

const mergeStateToBranch: Record<
  MergeState,
  Branch.BRANCH | Branch.MAIN | Branch.SOURCE | Branch.TARGET | null
> = {
  [MergeState.BRANCH]: Branch.BRANCH,
  [MergeState.MAIN]: Branch.MAIN,
  [MergeState.NONE]: null,
  [MergeState.PARTIAL]: null,
  [MergeState.SOURCE]: Branch.SOURCE,
  [MergeState.TARGET]: Branch.TARGET,
};

const getDiffObjectFromItems = (dataSourceItems: DataSource) =>
  dataSourceItems.reduce<Record<string, any>>(
    (
      acc,
      { status, enhancedDiffContextData, fieldName, entityType, entityId }
    ) => {
      const branchKey = mergeStateToBranch[status];
      if (!branchKey) {
        // If nothing is selected, we ignore the key
        return acc;
      }
      const data = readRawValue(
        entityType,
        entityId,
        fieldName,
        enhancedDiffContextData[branchKey]
      );

      if (shouldMapEntityTypeForFields(entityType, fieldName)) {
        const globalKey =
          fieldAttributesMap.get(fieldName) === FieldType.REFERENCE_TYPE
            ? 'globalref'
            : 'global';
        const isGlobal = readRawValue(
          entityType,
          entityId,
          globalKey,
          enhancedDiffContextData[branchKey]
        );

        return {
          ...acc,
          [fieldName]: data,
          ...(typeof isGlobal === 'boolean' ? { [globalKey]: isGlobal } : {}),
        };
      }
      return { ...acc, [fieldName]: data };
    },
    {}
  );

const getMergePayload = (dataSource: DataSource, tag: APITagAttributes) =>
  dataSource.reduce(
    ({ components, references }, dataSourceRow) => {
      if (dataSourceRow.entityType === APIEntityType.COMPONENT) {
        if (dataSourceRow.status === MergeState.NONE) {
          return {
            components: components.filter(id => id !== dataSourceRow.entityId),
            references,
          };
        }
        return {
          components: components.includes(dataSourceRow.entityId)
            ? components
            : [...components, dataSourceRow.entityId],
          references,
        };
      } else if (dataSourceRow.entityType === APIEntityType.REFERENCE) {
        if (dataSourceRow.status === MergeState.NONE) {
          return {
            references: references.filter(id => id !== dataSourceRow.entityId),
            components,
          };
        }
        return {
          references: references.includes(dataSourceRow.entityId)
            ? references
            : [...references, dataSourceRow.entityId],
          components,
        };
      }
      return { components, references };
    },
    {
      components: tag.components,
      references: tag.references,
    }
  );

const ENTITY_TYPE = 'entityType';
const VERB = 'verb';
const ENTITY_ID = 'entityId';
const PARENT_ENTITY_ID = 'parentEntityId';

type GroupedDataSource = {
  [APIEntityType.COMPONENT_TYPE]: DataSourceGroup;
  [APIEntityType.COMPONENT]: DataSourceGroup;
  [APIEntityType.FIELD]: DataSourceGroup;
  [APIEntityType.PERMISSION]: DataSourceGroup;
  [APIEntityType.REFERENCE_TYPE]: DataSourceGroup;
  [APIEntityType.REFERENCE]: DataSourceGroup;
  [APIEntityType.TAG]: DataSourceGroup;
};

const groupDataSource = (dataSource: DataSource): GroupedDataSource => {
  const id =
    dataSource[0]?.entityType === APIEntityType.COMPONENT_TYPE ||
    dataSource[0]?.entityType === APIEntityType.REFERENCE_TYPE
      ? PARENT_ENTITY_ID
      : ENTITY_ID;

  return deepGroupBy(
    dataSource
      ?.map(item => ({
        ...item,
        [VERB]: getVerbFromPath(item.path),
      }))
      .filter(item => item.rowType !== DiffTableRowType.HEADER_ROW),

    [ENTITY_TYPE, VERB, id]
  );
};

const groupMergeDataSource = (dataSource: DataSource) => ({
  [APIEntityType.TAG]: {
    [Verb.MERGE]: dataSource.reduce(
      (groupedDataSource, dataSourceRow) => {
        if (dataSourceRow.rowType === DiffTableRowType.SUB_HEADER_ROW) {
          return {
            ...groupedDataSource,
            [dataSourceRow.entityId]: [dataSourceRow],
          };
        }
        const matchingTagEntityList = Object.values(groupedDataSource).find(
          dataSourceRows =>
            dataSourceRows.find(({ index }) => index === dataSourceRow.parent)
        );
        matchingTagEntityList?.push(dataSourceRow);
        return groupedDataSource;
      },
      {} as Record<ArdoqId, DataSource>
    ),
  },
});

const getEntityPayload = (
  actionVerb: Verb,
  resourceId: ArdoqId,
  entityType: Exclude<
    APIEntityType,
    APIEntityType.COMPONENT_TYPE | APIEntityType.REFERENCE_TYPE
  >,
  dataSource: DataSource,
  enhancedDiffContextData: EnhancedDiffScopeData
) => {
  const getEntityFromTarget = () =>
    getEntityById(
      entityType,
      resourceId,
      enhancedDiffContextData[Branch.TARGET]
    ) as
      | APIComponentAttributes
      | APIReferenceAttributes
      | APIFieldAttributes
      | APIModelAttributes;
  const getEntityFromSource = () =>
    getEntityById(
      entityType,
      resourceId,
      enhancedDiffContextData[Branch.SOURCE]
    );
  const getResourceData = () => {
    if (actionVerb === Verb.CREATE)
      return omit(['_version', 'children'], getEntityFromSource());
    return getEntityFromTarget();
  };

  const getVersion = () => getEntityFromTarget()?._version;

  const resourceData = getResourceData();
  const updatePayload =
    actionVerb === Verb.UPDATE
      ? { ...getDiffObjectFromItems(dataSource), _version: getVersion() }
      : {};
  const mergePayload =
    actionVerb === Verb.MERGE
      ? getMergePayload(dataSource, resourceData as APITagAttributes)
      : {};
  return {
    ...resourceData,
    ...updatePayload,
    ...mergePayload,
  };
};

const getDataSourceFilter =
  (verb: Verb) =>
  ({ status, entityType, rowType, hasWritePermission }: DataSourceItem) => {
    if (
      hasWritePermission === false ||
      rowType === DiffTableRowType.SECTION_HEADER_ROW
    ) {
      return false;
    } else if (verb === Verb.UPDATE) {
      return (
        rowType !== DiffTableRowType.SUB_HEADER_ROW &&
        status !== MergeState.NONE
      );
    } else if (verb === Verb.MERGE) {
      return rowType !== DiffTableRowType.HEADER_ROW;
    }
    return (
      status === MergeState.SOURCE ||
      status === MergeState.TARGET ||
      (status === MergeState.PARTIAL &&
        entityType === APIEntityType.TAG &&
        rowType === DiffTableRowType.SUB_HEADER_ROW)
    );
  };

const isComponentOrReferenceType = (
  entityType: APIEntityType
): entityType is APIEntityType.COMPONENT_TYPE | APIEntityType.REFERENCE_TYPE =>
  entityType === APIEntityType.COMPONENT_TYPE ||
  entityType === APIEntityType.REFERENCE_TYPE;

export const prepareMergeData = (
  dataSource: DataSource,
  enhancedDiffContextData: EnhancedDiffScopeData
): APIResetRequest => {
  const verb = getVerbFromPath(dataSource[0].path);
  const dataSourceToBeMerged = dataSource.filter(getDataSourceFilter(verb));
  const apiDiffBranchToBranch =
    verb === Verb.MERGE
      ? groupMergeDataSource(dataSourceToBeMerged)
      : groupDataSource(dataSourceToBeMerged);
  const payload = Object.fromEntries(
    entries(apiDiffBranchToBranch).map(([entityType, entityTypeData]) => {
      // TODO fix: should be a mapping, not a string manipulation
      if (isComponentOrReferenceType(entityType)) {
        return getKeyAndPayloadForComponentAndReferenceTypes(
          entityTypeData as DataSourceGroup
        );
      }
      const APIEntityTypeKey = getAPIEntityTypeKey(entityType);
      return [
        [APIEntityTypeKey],
        Object.fromEntries(
          entries(entityTypeData).map(([actionVerb, actionVerbData]) => [
            actionVerb === Verb.MERGE ? Verb.UPDATE : actionVerb,
            entries(actionVerbData).map(([resourceId, dataSourceItems]) =>
              (actionVerb as Verb) === Verb.DELETE
                ? resourceId
                : getEntityPayload(
                    actionVerb,
                    resourceId,
                    entityType,
                    dataSourceItems,
                    enhancedDiffContextData
                  )
            ),
          ])
        ),
      ];
    })
  );

  const payloadWithEmptyFields = fillInEmptyValues(payload);
  return filterEmptyMergeChanges(payloadWithEmptyFields, dataSource);
};

const FullScopeDataCollection = [
  ScopeDataCollection.COMPONENTS,
  ScopeDataCollection.REFERENCES,
  ScopeDataCollection.FIELDS,
  ScopeDataCollection.MODELS,
  ScopeDataCollection.PERMISSIONS,
  ScopeDataCollection.TAGS,
];

const fillInEmptyValues = (payload: APIResetRequest) =>
  FullScopeDataCollection.reduce((acc, scopeDataCollection) => {
    if (!acc[scopeDataCollection]) {
      acc[scopeDataCollection] = {
        [Verb.CREATE]: [],
        [Verb.DELETE]: [],
        [Verb.UPDATE]: [],
      } as APIResetData<any>;
    } else {
      [Verb.CREATE, Verb.UPDATE, Verb.DELETE].forEach(verb => {
        if (verb !== Verb.MERGE && !acc[scopeDataCollection][verb]) {
          acc[scopeDataCollection][verb] = [];
        }
      });
    }
    return acc;
  }, payload as ReduceAccumulatorType);

const isEntityNotFromMergeTarget = (entity: Entity, dataSource: DataSource) =>
  !(
    dataSource.find(ds => ds.entityId === entity._id)?.status ===
    MergeState.TARGET
  );

type ReduceAccumulatorType = Record<ScopeDataCollection, any>;

const excludeTargetValuesFromPayload = (
  body: APIResetRequest,
  scopeDataCollection: ScopeDataCollection,
  dataSource: DataSource,
  acc: ReduceAccumulatorType
) => {
  Object.entries(body[scopeDataCollection]).forEach(([verb, entities]) => {
    acc[scopeDataCollection] = {
      ...acc[scopeDataCollection],
      [verb]: entities.filter((entity: Entity) =>
        isEntityNotFromMergeTarget(entity, dataSource)
      ),
    };
  });
};

// excluding Target values from payload when merging into Target to prevent ChangeLog empty changes
const filterEmptyMergeChanges = (
  body: APIResetRequest,
  dataSource: DataSource
) =>
  FullScopeDataCollection.reduce((acc, scopeDataCollection) => {
    if (body[scopeDataCollection]) {
      excludeTargetValuesFromPayload(
        body,
        scopeDataCollection,
        dataSource,
        acc
      );
    }
    return acc;
  }, {} as ReduceAccumulatorType);
