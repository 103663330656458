import { ButtonGroup, PrimaryButton, SecondaryButton } from '@ardoq/button';
import { SidebarMenu, SidebarMenuFormSection } from '@ardoq/sidebar-menu';
import { trackClickedToChangeOrgLogo } from 'tracking/events/clicks';
import { TextInput } from '@ardoq/forms';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  saveSurveySettings,
  setIsOrgLogoValid,
  setOrganizationLogo,
  toggleSurveySettingsSidebar,
} from 'surveyAdmin/streams/actions';
import surveyAdmin$ from 'surveyAdmin/streams/surveyAdmin$';
import { SurveySettings } from 'surveyAdmin/streams/types';
import styled, { css } from 'styled-components';
import { s4, colors, s16 } from '@ardoq/design-tokens';
import { Stack } from '@ardoq/layout';
import { text2Bold } from '@ardoq/typography';
import { Icon, IconName } from '@ardoq/icons';
import { Space } from '@ardoq/style-helpers';

const commonCSS = `
  width: 256px;
  height: 80px;
  border-radius: ${s4};
`;

const LogoOrErrorWrapper = styled(Space)<{ $isOrgLogoValid: boolean }>`
  ${props =>
    props.$isOrgLogoValid
      ? css`
          ${InvalidImageDisplay} {
            display: none;
          }
        `
      : css`
          ${LogoWrapper} {
            display: none;
          }
        `}
`;

const LogoWrapper = styled.div`
  ${commonCSS}
  border: 1px solid ${colors.grey90};
  background-color: ${colors.grey95};
  display: flex;
`;

const InvalidImageDisplay = styled(Space)`
  ${commonCSS}
  background-color: ${colors.red95};
  color: ${colors.red15};
`;

const OrgLogo = styled.img`
  object-fit: cover;
  height: 100%;
  margin: auto;
  display: block;
`;

const ArdoqLogo = styled.img`
  width: 140px;
  margin: auto;
  display: block;
`;

const StyledSpace = styled(Space)`
  padding: ${s16};
`;

const PreviewText = styled.div`
  color: ${colors.grey60};
  ${text2Bold}
`;

type SurveySettingsSidebarProps = {
  surveySettings: SurveySettings;
};

const SurveySettingsSidebar = ({
  surveySettings,
}: SurveySettingsSidebarProps) => {
  const { status, hasChanges, isOrgLogoValid, errorMessage } = surveySettings;
  const logoUrl = surveySettings.organizationLogo ?? '';

  const handleSaveSurveySettings = () => {
    if (hasChanges) {
      trackClickedToChangeOrgLogo('Survey Organization Settings');
    }
    dispatchAction(saveSurveySettings());
  };

  const isSaving = status === 'saving';
  return (
    <SidebarMenu
      isPageBodyMenu
      isRightContent
      headerTitle="Settings"
      onCloseClick={() => dispatchAction(toggleSurveySettingsSidebar())}
      footerContent={
        <StyledSpace $justify="flex-end">
          <ButtonGroup>
            <SecondaryButton
              onClick={() => dispatchAction(toggleSurveySettingsSidebar())}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              isLoading={isSaving}
              isDisabled={!hasChanges}
              onClick={handleSaveSurveySettings}
            >
              {isSaving ? 'Saving' : 'Save'}
            </PrimaryButton>
          </ButtonGroup>
        </StyledSpace>
      }
    >
      <SidebarMenuFormSection isCollapsible={false}>
        <Stack gap="medium">
          <TextInput
            label="Logo URL"
            placeholder="https://url.to.image.png"
            errorMessage={errorMessage}
            value={logoUrl}
            onValueChange={value => dispatchAction(setOrganizationLogo(value))}
            helperText={
              <>
                <p>
                  Add your company&apos;s logo to survey headers, broadcast
                  message templates and Ardoq Discover.
                </p>
                <p>
                  <strong>Tip:</strong> Choose a PNG file of 80px by 250px with
                  a transparent background.
                </p>
              </>
            }
          />
          <LogoOrErrorWrapper
            $isVertical
            $gap="s8"
            $isOrgLogoValid={isOrgLogoValid}
          >
            <PreviewText>Preview</PreviewText>
            <LogoWrapper>
              {logoUrl.length > 0 ? (
                <OrgLogo
                  src={logoUrl}
                  alt="Organization logo preview"
                  onError={() => dispatchAction(setIsOrgLogoValid(false))}
                  onLoad={() => dispatchAction(setIsOrgLogoValid(true))}
                />
              ) : (
                <ArdoqLogo src="/img/ardoq_web.png" alt="Ardoq logo preview" />
              )}
            </LogoWrapper>
            <InvalidImageDisplay
              $isVertical
              $align="center"
              $justify="center"
              $gap="s4"
            >
              <Icon iconName={IconName.BROKEN_IMAGE} />
              Invalid image URL
            </InvalidImageDisplay>
          </LogoOrErrorWrapper>
        </Stack>
      </SidebarMenuFormSection>
    </SidebarMenu>
  );
};

export default connect(SurveySettingsSidebar, surveyAdmin$);
