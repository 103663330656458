import { SetClassNames } from '../types';
import { NodeModel } from '../models/types';
import { css } from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { DRAG_TARGET_CLASS_NAME, NAVIGATOR_TREE_FILTER_ID } from '../consts';
import isInputElement from 'utils/isInputElment';

export const setClassNames: SetClassNames =
  ({
    dragTargetNode,
    linkSourceNode,
    existingRefs,
    multiselect,
    shouldShowRefDrawing,
    hasLinkTarget,
  }) =>
  (node: NodeModel) => {
    const isLinkSourceNode = linkSourceNode && node === linkSourceNode;
    const isLinkSource = node.isSelected && multiselect;
    return [
      !node.hasWriteAccess && 'no-write-access',
      node.hasWriteAccess && !node.getLock() && DRAG_TARGET_CLASS_NAME,
      node.isSelected && 'selected',
      node.hasChanged && 'highlight-flash',
      node.isSelectedContext && 'active',
      node.showIsActive && 'show-active',
      dragTargetNode && node === dragTargetNode && 'active-drag-target',
      isLinkSourceNode && 'link-source',
      !multiselect &&
        existingRefs &&
        existingRefs.outgoing.has(node.id) &&
        'outgoing',
      !multiselect &&
        existingRefs &&
        existingRefs.incoming.has(node.id) &&
        'incoming',
      shouldShowRefDrawing && (isLinkSource || isLinkSourceNode) && 'linking',
      // TODO can be removed
      hasLinkTarget &&
        shouldShowRefDrawing &&
        (isLinkSource || isLinkSourceNode) &&
        'has-target',
      node.isContextNode && 'is-context-node',
    ].filter(ExcludeFalsy);
  };

export const handleChanges = (changedIds: string[]) => {
  const isHighlightingChanges = changedIds && changedIds.length > 0;
  return { changedIds, isHighlightingChanges };
};

export const pseudoTopBottomBorder = css`
  background-image: linear-gradient(0deg, ${colors.grey80}, ${colors.grey80}),
    linear-gradient(0deg, ${colors.grey80}, ${colors.grey80});
  background-repeat: repeat-x;
  background-size: 0 0;
  background-position:
    left top,
    left bottom;
`;

export const getNavigatorFilter = () => {
  const filter = document.querySelector(`#${NAVIGATOR_TREE_FILTER_ID} input`);
  return isInputElement(filter) ? filter : null;
};
