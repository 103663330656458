import { ButtonType } from '@ardoq/button';
import { TraversalBuilderState } from '../types';
import { EditTraversalCommands } from 'viewpointBuilder/traversals/editTraversalCommands';
import { IconName } from '@ardoq/icons';
import { FormSize, Switch, TextInput } from '@ardoq/forms';
import { ButtonWithDropdown, DropdownOptionType } from '@ardoq/dropdown-menu';
import { viewpointBuilderTracking } from '../viewpointBuilderTracking';
import { Box, FlexBox } from '@ardoq/layout';
import { VisuallyHidden } from '@react-aria/visually-hidden';

type FilterRowProps = {
  triplesSortAndFiltersState: TraversalBuilderState['triplesSortAndFiltersState'];
  commands: EditTraversalCommands;
  isDisabled: boolean;
};

export const FilterRow = ({
  triplesSortAndFiltersState,
  commands,
  isDisabled,
}: FilterRowProps) => {
  return (
    <FlexBox gap="small">
      <TextInput
        autoComplete="off"
        value={triplesSortAndFiltersState.filterTerm}
        leftIconName={IconName.FILTER_LIST}
        placeholder="Search reference and component types"
        onValueChange={filterTerm =>
          commands.setSectionFilter({
            filterTerm,
          })
        }
        onClear={() => {
          commands.setSectionFilter({
            filterTerm: '',
          });
        }}
        formSize={FormSize.FULL}
        isDisabled={isDisabled}
      />

      <SettingsDropdown
        triplesSortAndFiltersState={triplesSortAndFiltersState}
        commands={commands}
        isDisabled={isDisabled}
      />
    </FlexBox>
  );
};

const SettingsDropdown = ({
  triplesSortAndFiltersState,
  commands,
  isDisabled,
}: FilterRowProps) => {
  return (
    <ButtonWithDropdown
      title="Settings"
      tooltipText="Settings"
      iconName={IconName.SETTINGS}
      alignMenuTo="right"
      dataTestId="traversal-dialog-tripe-sort-order"
      isDisabled={isDisabled}
      options={[
        {
          label: 'Show preferences',
          type: DropdownOptionType.HEADER,
        },
        {
          type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
          content: (
            <Box
              key="showOnlyOptionsWithInstanceCountsToggleOption"
              padding="medium"
              DEPRECATED_onClick={event => event.stopPropagation()}
            >
              <Switch
                name="showOnlyOptionsWithInstanceCounts"
                isChecked={
                  triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts
                }
                label="Hide references with 0 linked components"
                onChange={commands.toggleShowOnlyOptionsWithInstanceCounts}
              />
            </Box>
          ),
        },
        {
          type: DropdownOptionType.DIVIDER,
        },
        {
          label: 'Sort by',
          type: DropdownOptionType.HEADER,
        },
        {
          leftIcon: { name: IconName.REFERENCE_TYPE },
          label: 'Reference type A-Z',
          isActive:
            triplesSortAndFiltersState.tripleSortOrder === 'reference_type_a_z',
          onClick: () => {
            commands.setTripleSortOrder({
              tripleSortOrder: 'reference_type_a_z',
            });
            viewpointBuilderTracking.trackSelectReferenceTraversalTripleSortOrder();
          },
        },
        {
          leftIcon: { name: IconName.COMPONENT },
          label: 'Component type A-Z',
          isActive:
            triplesSortAndFiltersState.tripleSortOrder === 'component_type_a_z',
          onClick: () => {
            commands.setTripleSortOrder({
              tripleSortOrder: 'component_type_a_z',
            });
            viewpointBuilderTracking.trackSelectComponentTraversalTripleSortOrder();
          },
        },
        {
          leftIcon: { name: IconName.ARROW_DOWNWARD },
          label: 'Descending instance count',
          isActive:
            triplesSortAndFiltersState.tripleSortOrder ===
            'descending_instance_count',
          onClick: () => {
            commands.setTripleSortOrder({
              tripleSortOrder: 'descending_instance_count',
            });
            viewpointBuilderTracking.trackSelectDescendingInstanceCountTraversalTripleSortOrder();
          },
        },
      ]}
      buttonType={ButtonType.GHOST}
    >
      <VisuallyHidden>Settings</VisuallyHidden>
    </ButtonWithDropdown>
  );
};
