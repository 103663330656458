import { pick } from 'lodash/fp';
import { size } from 'lodash';

export const pickMap = <SourceT, ResultT>(
  data: SourceT[],
  fields: string[]
): ResultT[] => data.map(pick(fields)) as ResultT[];

export const hasReasonableMaxSize = <T>(input: string | T[], maxSize: number) =>
  size(input) <= maxSize;
