import { HTMLAttributes } from 'react';
import { AnimatedChevron } from '@ardoq/icons';
import { SmallGhostButton } from '@ardoq/button';
import { FlexBox } from '@ardoq/layout';

type ExpandableProps = {
  isExpanded: boolean;
  toggleExpand: VoidFunction;
} & HTMLAttributes<HTMLDivElement>;

const Expandable = ({
  isExpanded,
  toggleExpand,
  children,
}: ExpandableProps) => {
  return (
    <FlexBox align="center" gap="xsmall">
      <SmallGhostButton onClick={toggleExpand}>
        <AnimatedChevron $isUp={isExpanded} />
      </SmallGhostButton>
      {children}
    </FlexBox>
  );
};

export default Expandable;
