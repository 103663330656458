import {
  DashboardTrackingEventsNames,
  DEFAULT_BAR_CHART_PRIMARY_COLOR,
  DEFAULT_BAR_CHART_SECONDARY_COLOR,
  OTHER_SEGMENT_NAME,
} from '@ardoq/dashboard';
import { Space } from '@ardoq/style-helpers';
import { Field, TextInput } from '@ardoq/forms';
import { ColorPickerSwatchInput } from '@ardoq/color-picker';
import { trackEvent } from 'tracking/tracking';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { updateSelectedWidget } from '../../actions';
import { CloseIcon } from '@ardoq/icons';
import { GhostButton } from '@ardoq/button';
import {
  SidebarMenuSection,
  SidebarMenuSubsectionHeader,
} from '@ardoq/sidebar-menu';
import dashboardColorThemes$, {
  DEFAULT_DASHBOARD_COLOR_THEME,
} from 'dashboard/DashboardColorThemeSettings/dashboardColorThemes$';
import {
  BarChartConfiguration,
  APIDashboardColorTheme,
  HexColor,
  UnpersistedEntity,
} from '@ardoq/api-types';

type BarChartSingleColorSectionProps = Pick<
  BarChartConfiguration,
  'primaryColor' | 'secondaryColor'
> & { dashboardColorThemes: APIDashboardColorTheme[] };

const ColorSubSection = ({
  color,
  selectedColorTheme,
  isPrimaryColor,
  isClearable,
}: {
  color: HexColor;
  selectedColorTheme: UnpersistedEntity<APIDashboardColorTheme>;
  isPrimaryColor: boolean;
  isClearable: boolean;
}) => (
  <Field>
    <Space $gap="s8" $align="center">
      <ColorPickerSwatchInput
        dataTestId="dashboard-bar-chart-single-color-button"
        palettes={[selectedColorTheme]}
        value={color}
        trackingFunctions={{
          trackSelectedColorFromColorPicker: () =>
            trackEvent(
              DashboardTrackingEventsNames.SELECTED_COLOR_WITH_COLORPICKER_IN_DASHBOARD_BUILDER
            ),
          trackSelectedColorFromHexInput: () =>
            trackEvent(
              DashboardTrackingEventsNames.SELECTED_COLOR_WITH_HEX_IN_DASHBOARD_BUILDER
            ),
          trackSelectedColorFromPalette: () =>
            trackEvent(
              DashboardTrackingEventsNames.SELECTED_COLOR_FROM_PALETTE_IN_DASHBOARD_BUILDER
            ),
        }}
        onValueChange={newColor => {
          dispatchAction(
            updateSelectedWidget({
              [isPrimaryColor ? 'primaryColor' : 'secondaryColor']: newColor,
            })
          );
        }}
      />
      <TextInput value={isPrimaryColor ? 'All bars' : OTHER_SEGMENT_NAME} />
      {isClearable && (
        <GhostButton
          onClick={() => {
            dispatchAction(
              updateSelectedWidget(
                isPrimaryColor
                  ? {
                      primaryColor: DEFAULT_BAR_CHART_PRIMARY_COLOR,
                    }
                  : { secondaryColor: DEFAULT_BAR_CHART_SECONDARY_COLOR }
              )
            );
          }}
        >
          <CloseIcon />
        </GhostButton>
      )}
    </Space>
  </Field>
);

const BarChartSingleColorSection = ({
  primaryColor = DEFAULT_BAR_CHART_PRIMARY_COLOR,
  secondaryColor = DEFAULT_BAR_CHART_SECONDARY_COLOR,
  dashboardColorThemes,
}: BarChartSingleColorSectionProps) => {
  const isDefaultPrimaryColor =
    primaryColor === DEFAULT_BAR_CHART_PRIMARY_COLOR;
  const isDefaultSecondaryColor =
    secondaryColor === DEFAULT_BAR_CHART_SECONDARY_COLOR;
  const selectedColorTheme =
    dashboardColorThemes.find(({ isSelected }) => isSelected) ??
    DEFAULT_DASHBOARD_COLOR_THEME;
  return (
    <SidebarMenuSection title="Data colors" isCollapsed>
      {isDefaultPrimaryColor || isDefaultSecondaryColor ? (
        <>
          <SidebarMenuSubsectionHeader title="Auto assigned" />
          {isDefaultPrimaryColor && (
            <ColorSubSection
              color={primaryColor}
              selectedColorTheme={selectedColorTheme}
              isPrimaryColor
              isClearable={false}
            />
          )}
          {isDefaultSecondaryColor && (
            <ColorSubSection
              color={secondaryColor}
              selectedColorTheme={selectedColorTheme}
              isPrimaryColor={false}
              isClearable={false}
            />
          )}
        </>
      ) : null}
      {!isDefaultPrimaryColor || !isDefaultSecondaryColor ? (
        <>
          <SidebarMenuSubsectionHeader title="Manually assigned" />
          {!isDefaultPrimaryColor && (
            <ColorSubSection
              color={primaryColor}
              selectedColorTheme={selectedColorTheme}
              isPrimaryColor
              isClearable
            />
          )}
          {!isDefaultSecondaryColor && (
            <ColorSubSection
              color={secondaryColor}
              selectedColorTheme={selectedColorTheme}
              isPrimaryColor={false}
              isClearable
            />
          )}
        </>
      ) : null}
    </SidebarMenuSection>
  );
};

export default connect(BarChartSingleColorSection, dashboardColorThemes$);
