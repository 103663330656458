import { trackEvent } from 'tracking/tracking';
import { TrackingPlaceInApp } from 'tracking/types';
import { ViewIds } from '@ardoq/api-types';
import { viewIdToUserFriendlyName } from 'views/metaInfoTabs';

const { VIEW_PANE_HEADER, VIEW_SETTINGS_PANEL } = TrackingPlaceInApp;

export const trackOpenViewSettingsPanel = () =>
  trackEvent(`${VIEW_PANE_HEADER} Opened view settings panel`);

export const trackViewStyleChange = (prevView: ViewIds, currentView: ViewIds) =>
  trackEvent(`${VIEW_SETTINGS_PANEL} Changed view style`, {
    viewStyle: viewIdToUserFriendlyName[currentView],
    previousViewStyle: viewIdToUserFriendlyName[prevView],
  });

export const trackViewOptionClick = (viewOption: string) =>
  trackEvent(`${VIEW_SETTINGS_PANEL} Clicked view option`, { viewOption });

type SavedAs = 'viewpoint' | 'slide';
export const trackClickOnSaveAs = (savedAs: SavedAs) =>
  trackEvent(`${VIEW_PANE_HEADER} Clicked saved as`, { savedAs });

export const trackViewOptionsApply = (viewId: ViewIds) =>
  trackEvent(`${VIEW_SETTINGS_PANEL} Applied view options`, {
    activeView: viewIdToUserFriendlyName[viewId],
  });

type TrackViewSettingsApplyMetadata = {
  referenceLabelsCount: number;
  componentLabelsCount: number;
  viewStyle: ViewIds;
};

export const trackMultiLabelFormattingApply = ({
  referenceLabelsCount,
  componentLabelsCount,
  viewStyle,
}: TrackViewSettingsApplyMetadata) =>
  trackEvent(`${VIEW_SETTINGS_PANEL} Applied multi-label formatting`, {
    referenceLabelsCount,
    componentLabelsCount,
    viewStyle,
  });
