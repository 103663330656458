import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import { ProgressDetailsState } from './types';

const getDefaultState = (): ProgressDetailsState => ({
  filter: '',
});

const progressDetails$ = persistentReducedStream<ProgressDetailsState>(
  'progressDetails$',
  getDefaultState(),
  reducers
);

export default progressDetails$;
