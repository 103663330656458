import { useCallback } from 'react';
import { connect } from '@ardoq/rxbeach';
import { Select } from '@ardoq/select';
import {
  ComponentRepresentation,
  representationDataToEntityIconProps,
} from '@ardoq/renderers';
import { componentsSearchCommands } from 'viewpointBuilder/selectContextTab/commands';
import contextSwitcher$ from './contextSwitcher$';
import { ViewpointNavigatorCommands } from './viewpointNavigatorCommands';
import { EnhancedRepresentationData } from 'modelInterface/prototype/types';
import { ContextSwitcherCurrentContext } from './types';
import { trackEvent } from 'tracking/tracking';
import { ViewpointNavigatorTrackingEventsNames } from './tracking';
import { LoadedStateHash, QueryBuilderSubquery } from '@ardoq/api-types';
import { ComponentDecorator } from '@ardoq/decorators';
import { Stack } from '@ardoq/layout';
import { Text } from '@ardoq/typography';
import { Icon, IconName, IconSize } from '@ardoq/icons';

type ContextSwitcherProps = {
  isLoading: boolean;
  loadedStateHash: LoadedStateHash;
  componentTypeName: string;
  currentContext: ContextSwitcherCurrentContext;
  searchResults: {
    id: string;
    label: string;
    representationData: EnhancedRepresentationData;
  }[];
  commands: ViewpointNavigatorCommands;
  startSetFilter: QueryBuilderSubquery | null;
  isContextReadOnly: boolean;
};

const ViewpointNavigatorContextSwitcher = ({
  loadedStateHash,
  componentTypeName,
  isLoading,
  searchResults,
  currentContext,
  commands,
  startSetFilter,
  isContextReadOnly,
}: ContextSwitcherProps) => {
  const onFocus = useCallback(() => {
    commands.applyFiltersForContextSwitcher({
      componentTypeName,
      startSetFilter,
    });
  }, [commands, componentTypeName, startSetFilter]);

  if (isContextReadOnly && currentContext.representationData) {
    return (
      <Stack gap="xsmall">
        <Text variant="caption" color="textSubtle">
          Context component:
        </Text>
        <ComponentDecorator
          useDefaultIcon={true}
          {...representationDataToEntityIconProps(
            currentContext.representationData
          )}
          label={currentContext.label}
        />
      </Stack>
    );
  }

  const currentValue = currentContext
    ? {
        label: currentContext.label,
        value: currentContext.label,
        leftContent: currentContext.representationData ? (
          <ComponentRepresentation {...currentContext.representationData} />
        ) : null,
      }
    : {
        value: '',
        label: 'No data for selection',
        leftContent: (
          <Icon iconName={IconName.COMPONENT} iconSize={IconSize.SMALL} />
        ),
      };

  const options = searchResults.map(option => ({
    value: option.id,
    label: option.label,
    leftContent: <ComponentRepresentation {...option.representationData} />,
  }));

  return (
    <Select
      helperText={
        <Text variant="caption" color="textSubtle">
          Context component:
        </Text>
      }
      isLoading={isLoading}
      aria-label="Change context component"
      options={options}
      value={currentValue}
      onBlur={commands.resetComponentSearchStream}
      onInputChange={val => {
        componentsSearchCommands.setComponentName(val ?? '');
      }}
      onFocus={onFocus}
      onChange={(event: any) => {
        trackEvent(
          ViewpointNavigatorTrackingEventsNames.CLICKED_CONTEXT_COMPONENT_DROPDOWN
        );
        commands.setContextComponentForBlock(
          loadedStateHash,
          event?.value ?? ''
        );
        commands.resetComponentSearchStream();
      }}
    />
  );
};

export default connect(ViewpointNavigatorContextSwitcher, contextSwitcher$);
