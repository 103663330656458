import { FlexBox } from '@ardoq/layout';
import { ButtonGroup, GhostButton, TertiaryButton } from '@ardoq/button';

const RejectAndApproveAllButtons = ({
  onRejectAll,
  onApproveAll,
}: {
  onRejectAll: () => void;
  onApproveAll: () => void;
}) => {
  return (
    <FlexBox
      justify="flex-end"
      align="center"
      gap="medium"
      paddingX="large"
      paddingBottom="medium"
    >
      <ButtonGroup>
        <GhostButton onClick={onRejectAll} dataTestId="reject-all-button">
          Reject all
        </GhostButton>
        <TertiaryButton onClick={onApproveAll} dataTestId="approve-all-button">
          Approve all
        </TertiaryButton>
      </ButtonGroup>
    </FlexBox>
  );
};

export default RejectAndApproveAllButtons;
