import { action$, ofType } from '@ardoq/rxbeach';
import { debounceTime } from 'rxjs';
import {
  notifyComponentsRemoved,
  notifyComponentsAdded,
} from 'streams/components/ComponentActions';
import { notifySortChanged } from 'streams/context/ContextActions';

export const reloadTreeAction$ = action$.pipe(
  ofType(notifyComponentsRemoved, notifyComponentsAdded, notifySortChanged),
  debounceTime(16)
);
