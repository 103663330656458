import { DisabledOptionProps } from '@ardoq/perspectives';

export const disabledOptionProps: DisabledOptionProps = {
  getAddButtonDisabledPopoverContent: entitiesName => {
    if (entitiesName === 'references') {
      return 'There is no reference type in this Viewpoint. Select a reference type in the previous steps first.';
    }
    return `There are no ${entitiesName} in the component and reference types for this Viewpoint.`;
  },
  getDisabledWorkspacePopoverContent: entitiesName =>
    `There are no ${entitiesName} in this workspace for the component and reference types in this Viewpoint`,
};
