import { map, switchMap, combineLatest, of, distinctUntilChanged } from 'rxjs';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { isEmptyTabularMapping } from 'integrations/common/streams/tabularMappings/utils';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
      selectedConfigId: getActiveIntegrationStream(integrationId).pipe(
        map(ci => ci.selectedMappingConfigId),
        distinctUntilChanged()
      ),
      transferConfigs: getTransferConfigsStream(integrationId).pipe(
        map(({ configs }) => configs)
      ),
    });
  }),
  map(
    ({ integrationId, tabularMapping, selectedConfigId, transferConfigs }) => {
      return {
        integrationId,
        isEmptyTabularMapping: isEmptyTabularMapping(tabularMapping),
        selectedConfigId,
        transferConfigs,
      };
    }
  )
);
