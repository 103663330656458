import {
  actionCreator,
  collectRoutines,
  routine,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  closeWorkspaceWizard,
  createWorkspaceFromTemplate,
  toggleFavoriteTemplate,
} from './actions';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { ArdoqId, CreateWorkspaceResponse, ViewIds } from '@ardoq/api-types';
import { setIsCreatingWsFromTemplate } from './isCreatingWsFromTemplate$';
import { BLANK_WORKSPACE_MODEL_NAME } from './consts';
import { requestShowAppModule, showRightPane } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { setVisibleIntegration } from 'integrations/actions';
import { EXCEL_IMPORTER } from './createFromTemplate$';
import CurrentUser from 'models/currentUser';
import {
  isViewDiscontinued,
  replaceViewIfNotEligible,
} from 'viewDeprecation/restrictedViews';
import { showWorkspaceModelEditor } from 'workspaceModelEditor/streams/actions';
import { handleError, workspaceApi } from '@ardoq/api';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { modelInterface } from 'modelInterface/models/modelInterface';
import { contextInterface } from 'modelInterface/contextInterface';
import { navigateToOrganizationMetamodel } from 'router/navigationActions';
import { Features, hasFeature } from '@ardoq/features';
import { handleWorkspaceCreatedTransaction } from 'sync/eventHandlers';

const doCreateWorkspaceFromTemplate = actionCreator<string>(
  '[fromTemplate] DO_CREATE_WORKSPACE_FROM_TEMPLATE'
);

const getDefaultWorkspaceName = (templateName: string) => {
  return [templateName, templateName.match('Workspace') ? '' : 'Workspace']
    .filter(Boolean)
    .join(' ');
};
// this might be a bit too elaborate, but I don't know how to do this in one routine,
// ie create a branching routine.
const handleCreateWorkspace = routine(
  ofType(createWorkspaceFromTemplate),
  extractPayload(),
  tap(({ templateId }) => {
    if (templateId === EXCEL_IMPORTER) {
      dispatchAction(
        requestShowAppModule({ selectedModule: AppModules.INTEGRATIONS })
      );
      dispatchAction(
        setVisibleIntegration({
          id: 'excel-v3',
        })
      );
    } else {
      dispatchAction(doCreateWorkspaceFromTemplate(templateId));
    }
  })
);

const toCreatePayload = (templateId: ArdoqId) => {
  const selectedTemplate = modelInterface.get(templateId)!;
  const name = getDefaultWorkspaceName(selectedTemplate.name);

  // exclude discontinued views from the template, replace them through
  // successors or pagesView
  const templateStartView = selectedTemplate.startView;
  const templateDefaultViews = selectedTemplate.defaultViews;

  const filteredTemplateDefaultViews = templateDefaultViews.filter(
    viewId => !isViewDiscontinued(viewId)
  );
  const conformTemplateStartView = replaceViewIfNotEligible(templateStartView);

  return {
    name,
    componentTemplate: templateId,
    description: selectedTemplate.description,
    startView: conformTemplateStartView || null,
    views: filteredTemplateDefaultViews.length
      ? filteredTemplateDefaultViews
      : [ViewIds.COMPONENTTREE, ViewIds.BLOCK_DIAGRAM],
  };
};

const handleWorkspaceCreated = async (data: CreateWorkspaceResponse) => {
  // We need to call this to avoid a race-condition where the websocket-event comes
  // in _after_ we get to here.
  handleWorkspaceCreatedTransaction(data);
  const { workspace } = data;
  dispatchAction(closeWorkspaceWizard());
  await contextInterface.loadWorkspaces({
    contextWorkspaceId: workspace._id,
    workspaceIds: [],
  });

  dispatchAction(setIsCreatingWsFromTemplate(true));
  const isBlankTemplate = workspace.name === BLANK_WORKSPACE_MODEL_NAME;

  if (
    hasFeature(Features.METAMODEL_EDITOR_ALPHA) ||
    hasFeature(Features.METAMODEL_EDITOR_BETA)
  ) {
    dispatchAction(
      navigateToOrganizationMetamodel({
        route:
          '/organization-metamodel/workspaces/:workspaceId/component-types',
        params: {
          workspaceId: workspace._id,
        },
      })
    );
  } else if (isBlankTemplate) {
    dispatchAction(showWorkspaceModelEditor({ workspaceId: workspace._id }));
  } else {
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.WORKSPACE_PROPERTIES,
        workspaceId: workspace._id,
      })
    );
  }
};

const doHandleCreateWorkspace = routine(
  ofType(doCreateWorkspaceFromTemplate),
  extractPayload(),
  map(toCreatePayload),
  exhaustMap(workspaceApi.create),
  handleError(),
  tap(handleWorkspaceCreated)
);

const handleToggleFavoriteTemplate = routine(
  ofType(toggleFavoriteTemplate),
  extractPayload(),
  tap(({ templateId }) => {
    CurrentUser.toggleFavoriteTemplate(templateId);
  })
);
export const workspaceWizardRoutines = collectRoutines(
  handleCreateWorkspace,
  doHandleCreateWorkspace,
  handleToggleFavoriteTemplate
);
