import styled from 'styled-components';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { colors, s16, s32, s4, s8 } from '@ardoq/design-tokens';
import { text1 } from '@ardoq/typography';

const Container = styled.div`
  width: 448px;
`;

const PersonIcon = styled(Icon).attrs({
  iconName: IconName.PERSON,
  iconSize: IconSize.SMALL,
})`
  margin-left: ${s4};
  border-radius: 100%;
  border: 1px solid ${colors.grey80};
  background-color: ${colors.grey95};
  color: ${colors.grey60};
  width: ${s32};
  height: ${s32};
  padding-top: ${s4};
  vertical-align: middle;
  text-align: center;
`;

const ArrowDownIcon = styled(Icon).attrs({
  iconName: IconName.ARROW_DOWN,
  iconSize: IconSize.SMALL,
})`
  vertical-align: middle;
  margin-left: -${s8};
`;

const Preview = styled.div`
  ${text1}
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: ${s16};
`;

const SenderHighlight = styled.span`
  background-color: color-mix(in srgb, ${colors.purple25}, transparent 70%);
  font-weight: bold;
  border-radius: 2px;
  padding-left: 2px;
  padding-right: 2px;
`;

const Muted = styled.span`
  color: ${colors.grey60};
`;

type ContentProps = {
  sender: string;
};

const SenderPopoverContent = ({ sender }: ContentProps) => (
  <Container>
    <p>
      This address will be displayed in the email sender field as shown below.
      The actual email will be sent from {'<'}no-reply@ardoq.com{'>'}.
    </p>
    <Preview>
      <PersonIcon />
      <div>
        <div>
          <SenderHighlight>{sender}</SenderHighlight>
          <Muted>
            {'<'}no-reply@ardoq.com{'>'}
          </Muted>
        </div>
        <Muted>
          to me <ArrowDownIcon />
        </Muted>
      </div>
    </Preview>
  </Container>
);

export default SenderPopoverContent;
