import { LEFT_SIDEBAR_WIDTH, SidebarMenu } from '@ardoq/sidebar-menu';
import { map } from 'rxjs';
import changeApprovalData$ from '../changeApprovalData$';
import { connect } from '@ardoq/rxbeach';
import { isEmpty } from 'lodash';
import ReferencesSidebarSection from './ReferencesSidebarSection';
import ComponentsSidebarSection from './ComponentsSidebarSection';
import { WidthAdjustedStack } from './atoms';
import { Box } from '@ardoq/layout';

type ChangeApprovalSidebarProps = {
  hasComponentChanges: boolean;
  hasReferenceChanges: boolean;
};

const ChangeApprovalSidebar = ({
  hasComponentChanges,
  hasReferenceChanges,
}: ChangeApprovalSidebarProps) => {
  return (
    <WidthAdjustedStack $width={`${LEFT_SIDEBAR_WIDTH}px`}>
      <Box padding="medium" paddingTop="none">
        <SidebarMenu isPageBodyMenu>
          {hasComponentChanges && <ComponentsSidebarSection />}
          {hasReferenceChanges && <ReferencesSidebarSection />}
        </SidebarMenu>
      </Box>
    </WidthAdjustedStack>
  );
};

const changeApprovalSidebarData$ = changeApprovalData$.pipe(
  map(
    ({
      changedFieldsByComponentId,
      changedReferencesByComponentId,
      componentId,
    }) => {
      return {
        hasComponentChanges: !isEmpty(changedFieldsByComponentId[componentId]),
        hasReferenceChanges: !isEmpty(
          changedReferencesByComponentId[componentId]
        ),
      };
    }
  )
);

export default connect(ChangeApprovalSidebar, changeApprovalSidebarData$);
