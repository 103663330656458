import LandingPage from 'appContainer/landingPage/LandingPage';
import { FEATURE_KEY_SURVEYS } from 'appContainer/landingPage/consts';
import currentUser from 'models/currentUser';

const TITLE = `Your plan doesn’t include Surveys`;
const NO_ACCESS_TITLE = `Surveys is only available to organization admins`;
const SUBTITLE = `Crowdsource information gathering across the enterprise`;
const DESCRIPTION = `
  Many projects use a huge amount of time collecting quality data from various
  stakeholders. Surveys allow you to gather and automatically validate critical
  information across the organization, making the answers readily available for
  analysis. Survey reports allow you to monitor completion rates in dashboards
  that update in real-time as responses come in.
`;
const VIDEO_URL = `https://player.vimeo.com/video/259326870?byline=0&portrait=0`;

const VIEW_MODEL = {
  featureKey: FEATURE_KEY_SURVEYS,
  title: TITLE,
  noAccessTitle: NO_ACCESS_TITLE,
  subtitle: SUBTITLE,
  description: DESCRIPTION,
  videoUrl: VIDEO_URL,
  requiresOrgAdmin: () => !currentUser.isOrgAdmin(),
};

const SurveyLandingPage = () => <LandingPage viewModel={VIEW_MODEL} />;
export default SurveyLandingPage;
