import {
  ArdoqId,
  AllowlistType,
  PersistedIPAllowlistEntry,
} from '@ardoq/api-types';
import { IconButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import { Column, SearchableTable } from '@ardoq/table';
import { omit } from 'lodash';
import { connect } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { editAllowlistEntryInModal } from './AllowlistEntryForm';
import { ActionButtonWrapper } from './atoms';
import { allowlistTypeOptionMetadata } from './consts';
import ipAllowlist$ from './ipAllowlist$';
import { IPAllowlistingStreamShape } from './types';
import { confirmEntryDeletion } from './utils';

type EntryWithSearchField = PersistedIPAllowlistEntry & { searchField: string };

type CombinedProps = IPAllowlistingStreamShape & {
  entries: EntryWithSearchField[];
};

type EntryCol = Column<any> & { dataIndex?: keyof PersistedIPAllowlistEntry };

const mapProps = (streamState: IPAllowlistingStreamShape): CombinedProps => ({
  ...streamState,
  entries: streamState.entries.map(entry => ({
    ...entry,
    searchField: [entry.address, entry.whitelistType, entry.description].join(
      ' '
    ),
  })),
});

const AllowlistEntryList = ({ entries }: CombinedProps) => {
  return (
    <SearchableTable
      dataSource={entries}
      searchAttribute="searchField"
      getColumns={(sortBy, sortById, sortOrder) =>
        (
          [
            {
              dataIndex: 'address',
              title: 'IP address',
              cellStyle: { minWidth: '150px' },
            },
            {
              dataIndex: 'maskSize',
              title: 'Mask size',
              cellStyle: { minWidth: '150px' },
            },
            {
              dataIndex: 'whitelistType',
              title: 'Allows',
              valueRender: (type: AllowlistType) => (
                <>{allowlistTypeOptionMetadata[type].label}</>
              ),
              cellStyle: { minWidth: '100px' },
            },
            { dataIndex: 'description', title: 'Description' },
            {
              dataIndex: '_id',
              valueRender: (id: ArdoqId) => {
                const entry = omit(
                  entries.find(e => e._id === id)!,
                  'searchField'
                );
                return (
                  <ActionButtonWrapper>
                    <IconButton
                      iconName={IconName.EDIT}
                      data-tooltip-text="Edit"
                      onClick={() => editAllowlistEntryInModal(entry)}
                    />
                    <IconButton
                      iconName={IconName.DELETE}
                      data-tooltip-text="Delete"
                      onClick={() => confirmEntryDeletion(entry)}
                    />
                  </ActionButtonWrapper>
                );
              },
            },
          ] as EntryCol[]
        ).map(({ dataIndex, ...rest }) => ({
          onHeaderClick: () => sortBy(dataIndex!),
          sortOrder: sortById === dataIndex && sortOrder,
          dataIndex,
          ...rest,
        }))
      }
    />
  );
};

export default connect(AllowlistEntryList, ipAllowlist$.pipe(map(mapProps)));
