import AssetsBrowser, {
  AssetsBrowserProps,
} from 'components/AssetsBrowser/AssetsBrowser';
import { RowType } from '@ardoq/table';
import withDndRows, { DndDecoratorState } from './withDndRows';
import { TableTag } from './atoms';
import Ghost from './Ghost';
import BodyTr from './BodyTr';
import DragTableHeader from './DragTableHeader';
import { ArdoqId } from '@ardoq/api-types';
import { HighlightStatusMap } from '../../utils/withHighlightStatusMap';
import type { Point } from '@ardoq/math';

type AssetsBrowserWithDndRowsProps = AssetsBrowserProps &
  DndDecoratorState & {
    previewId?: ArdoqId | null;
    highlightStatusMap: HighlightStatusMap;
    hoverId: string;
    dragStartedOnFolderId: undefined | string;
    overFolderId: string;
    draggedRowType: RowType;
    currentMousePosition: Point;
  };

const AssetsBrowserWithDndRows = ({
  isDragging,
  isUpdating,
  draggedIds,
  currentMousePosition,
  hoverId,
  overFolderId,
  dragStartedOnFolderId,
  draggedRowType,
  highlightStatusMap,
  previewId,
  selected,
  ...props
}: AssetsBrowserWithDndRowsProps) => {
  const dragDropProps = {
    isUpdating,
    isDragging,
    hoverId,
    overFolderId,
    draggedIds,
    dragStartedOnFolderId,
    selected,
    renameId: props.renameId,
    highlightStatusMap,
    previewId,
  };

  return (
    <>
      {isDragging && (
        <>
          <DragTableHeader />
          <Ghost
            position={currentMousePosition}
            draggedIds={draggedIds}
            draggedRowType={draggedRowType}
          />
        </>
      )}
      <AssetsBrowser
        skipExpandOnSearch
        loading={isUpdating}
        selected={selected}
        components={{ BodyTr, Table: TableTag }}
        dragDropProps={dragDropProps}
        {...props}
      />
    </>
  );
};

export default withDndRows(AssetsBrowserWithDndRows);
