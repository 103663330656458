import { NavigatorConfiguration, NavigatorLayoutState } from './types';
import { combineLatest, from, map, Observable } from 'rxjs';
import { getEventListeners } from './navigatorEventListeners';
import { getStartDragAndDropRoutine } from './routines/startDragAndDropRoutine';
import { getDragTargetsNotificationRoutine } from './routines/dragTargetsNotificationRoutine';
import { getNavigatorClickRoutine } from './routines/navigatorClickRoutine';
import { createReferences$ } from 'createReferences2024/createReferences$';
import { getUpdateReferencesCreationRoutine } from './routines/updateReferencesCreationRoutine';
import { getNavigatorContextMenuRoutine } from './routines/showContextMenuRoutine';
import { getEndDragAndDropRoutine } from './routines/endDragAndDropRoutine';
import { stateToRenderProps } from './streams/stateToRenderProps';
import { getResetAnimationRoutine } from './routines/resetAnimationRoutine';
import { NavigatorContainerProps } from './components/PropTypes';
import { getNavigatorStream } from './streams/navigator$';
import { getStartScrollHandlerRoutine } from './routines/startScrollHandlerRoutine';
import { withOnFirstSubscription } from 'streams/utils/streamUtils';
import { getHandleSelectionEnd } from './routines/componentSelectionRoutines';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

export type SubscribeToNavigatorStreamAndRoutinesArgs = {
  setState: React.Dispatch<React.SetStateAction<NavigatorContainerProps>>;
  navigatorConfiguration: NavigatorConfiguration;
};

export const subscribeToNavigatorStreamAndRoutines = ({
  setState,
  navigatorConfiguration,
}: SubscribeToNavigatorStreamAndRoutinesArgs) => {
  const navigator$ = getNavigatorStream(
    navigatorConfiguration.externalReducers,
    navigatorConfiguration.actionNamespace
  );

  const navigatorContainer$ = getNavigatorContainerStream({
    navigator$,
    navigatorConfiguration$: from([navigatorConfiguration]),
  });
  const startDragAndDropRoutine = getStartDragAndDropRoutine(
    navigator$,
    navigatorConfiguration
  );
  const endDragAndDropRoutine = getEndDragAndDropRoutine(
    navigator$,
    navigatorConfiguration
  );
  const dragTargetsNotificationRoutine =
    getDragTargetsNotificationRoutine(navigator$);
  const navigatorContextMenuRoutine = getNavigatorContextMenuRoutine(
    navigator$,
    navigatorConfiguration
  );
  const navigatorClickRoutine = getNavigatorClickRoutine(
    navigator$,
    createReferences$,
    navigatorConfiguration
  );
  const updateReferencesCreationRoutine =
    getUpdateReferencesCreationRoutine(navigator$);
  const resetAnimationRoutine = getResetAnimationRoutine(navigatorContainer$);
  const startScrollHandlerRoutine = getStartScrollHandlerRoutine(navigator$);
  const handleSelectionEndRoutine = getHandleSelectionEnd(
    navigator$,
    navigatorConfiguration.componentSelection$,
    navigatorConfiguration.actionNamespace
  );
  const additionalRoutineSubscriptions = (
    navigatorConfiguration.additionalRoutines ?? []
  ).map(getRoutine => getRoutine(navigator$).subscribe());
  const subscriptions = [
    withOnFirstSubscription(
      navigatorContainer$,
      navigatorConfiguration.onViewInitialized
    ).subscribe(setState),
    // Temporary routines only meant to be subscribed during the life time of
    // the component.
    startDragAndDropRoutine.subscribe(),
    endDragAndDropRoutine.subscribe(),
    dragTargetsNotificationRoutine.subscribe(),
    navigatorClickRoutine.subscribe(),
    updateReferencesCreationRoutine.subscribe(),
    navigatorContextMenuRoutine.subscribe(),
    resetAnimationRoutine.subscribe(),
    startScrollHandlerRoutine.subscribe(),
    handleSelectionEndRoutine.subscribe(),
    ...additionalRoutineSubscriptions,
  ];

  return () => {
    subscriptions.forEach(subscription => subscription.unsubscribe());
  };
};

type MapToNavigatorContainerPropsArgs = {
  navigatorState: NavigatorLayoutState;
  navigatorConfiguration: NavigatorConfiguration;
};

export const mapToNavigatorContainerProps = ({
  navigatorState,
  navigatorConfiguration,
}: MapToNavigatorContainerPropsArgs): NavigatorContainerProps =>
  stateToRenderProps({
    ...navigatorState,
    navigatorConfiguration,
    eventListeners: getEventListeners(navigatorConfiguration.actionNamespace),
    container: navigatorState.container ?? document.createElement('div'),
    isInDiffMode: activeScenarioOperations.isInDiffMode(
      navigatorState.activeScenarioState
    ),
    isScenarioMode: activeScenarioOperations.isInScenarioMode(
      navigatorState.activeScenarioState
    ),
    activeDiffMode: activeScenarioOperations.getDiffMode(
      navigatorState.activeScenarioState
    ),
  });

export const getNavigatorContainerStream = ({
  navigator$,
  navigatorConfiguration$,
}: {
  navigator$: Observable<NavigatorLayoutState>;
  navigatorConfiguration$: Observable<NavigatorConfiguration>;
}): Observable<NavigatorContainerProps> =>
  combineLatest({
    navigatorState: navigator$,
    navigatorConfiguration: navigatorConfiguration$,
  }).pipe(map(mapToNavigatorContainerProps));
