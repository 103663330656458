import React from 'react';
import styled from 'styled-components';
import { s8 } from '@ardoq/design-tokens';
import { SmallGhostButton } from '@ardoq/button';
import { BasicTable } from '@ardoq/table';
import { SortOrder } from '@ardoq/api-types';
import { FilterHistory } from './FilterHistory';
import { Filters, FiltersIds } from './DataHistory';

const SortIcon = styled(BasicTable.SortIcon)`
  font-size: 20px;
  color: inherit;
`;

export const HeaderControlsContainer = styled.div<{
  $showContentOnHoverOnly?: boolean;
}>`
  opacity: ${({ $showContentOnHoverOnly }) =>
    $showContentOnHoverOnly ? 0 : 1};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${s8};
  cursor: pointer;

  &:hover {
    ${HeaderControlsContainer} {
      opacity: 1;
    }
  }
`;

export const HeaderDataHistory = ({
  title,
  columnId,
  columnHaveFilters = false,
  columnHaveSearch = false,
  friendlyNamesMap = {},
  filters,
  handleAllClick,
  handleOnClick,
  sortOrder,
  sortById,
  getOnSortByClick,
}: {
  title: string;
  columnId: string;
  columnHaveFilters?: boolean;
  columnHaveSearch?: boolean;
  friendlyNamesMap?: Record<string, string>;
  filters?: Filters;
  handleAllClick?: (id: FiltersIds, value: boolean) => void;
  handleOnClick?: (id: FiltersIds, value: boolean, label: string) => void;
  sortOrder?: SortOrder;
  sortById?: string | null;
  getOnSortByClick?: (clickedSortBy: string) => () => void;
}) => {
  return (
    <HeaderContainer
      onClick={() => getOnSortByClick && getOnSortByClick(columnId)()}
    >
      {title}
      {columnHaveFilters &&
        filters &&
        handleAllClick &&
        handleOnClick &&
        handleOnClick && (
          <FilterHistory
            values={filters}
            id={columnId as FiltersIds}
            handleAllClick={handleAllClick}
            handleOnClick={handleOnClick}
            haveSearch={columnHaveSearch}
            friendlyNamesMap={friendlyNamesMap}
          />
        )}
      {sortOrder && getOnSortByClick && (
        <HeaderControlsContainer
          $showContentOnHoverOnly={columnId !== sortById}
        >
          <SmallGhostButton onClick={() => getOnSortByClick(columnId)()}>
            <SortIcon
              order={columnId === sortById ? sortOrder : SortOrder.ASC}
            />
          </SmallGhostButton>
        </HeaderControlsContainer>
      )}
    </HeaderContainer>
  );
};
