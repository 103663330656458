import { combineLatest, EMPTY, of, switchMap } from 'rxjs';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getTransferStateStream } from '../../streams/transferState/getTransferStateStream';
import { isSyncStrategyOptionsDisabled } from '../../streams/transferState/utils';

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest([
      getActiveIntegrationStream(integrationId),
      getTransferStateStream(integrationId),
    ])
  ),
  switchMap(([{ currentTableId }, transferState]) => {
    if (!currentTableId) {
      return EMPTY;
    }
    return of({
      currentTableId,
      isSyncStrategyOptionsDisabled:
        isSyncStrategyOptionsDisabled(transferState),
    });
  })
);
