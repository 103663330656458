import { APIEntityType, ArdoqId, Verb } from '@ardoq/api-types';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import {
  DataSource,
  DataSourceItem,
  MergeState as MergeStatus,
} from 'components/DiffMergeTable/types';
import { MergeDirection } from './MergeDirection';
import { confirm } from '@ardoq/modal';
import { pluralize } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import { scenarioApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';

const isItemSelected = ({ status, entityId }: DataSourceItem) =>
  Boolean(entityId) && status === MergeStatus.SOURCE;

const getSelectedComponentIds = (dataSource: DataSource | null) =>
  (dataSource || []).reduce<ArdoqId[]>(
    (acc, dataSourceItem) =>
      isItemSelected(dataSourceItem) ? [...acc, dataSourceItem.entityId] : acc,
    []
  );

const requestComponentsOutOfScopeCheck = async (
  componentIds: string[]
): Promise<{
  numberOfDescendantsOutsideScope: number;
  numberOfReferencesOutsideScope: number;
}> => {
  if (process.env.NODE_ENV === 'test') {
    return {
      numberOfDescendantsOutsideScope: 0,
      numberOfReferencesOutsideScope: 0,
    };
  }
  const response = await scenarioApi.getNumberOfRelationsOutsideScope(
    getActiveScenarioId()!,
    componentIds
  );
  if (!isArdoqError(response)) return response;
  logError(response, `requestComponentsOutOfScopeCheck() call failed`);
  return {
    numberOfDescendantsOutsideScope: 0,
    numberOfReferencesOutsideScope: 0,
  };
};

export const checkIfComponentsOrReferencesOutOfScopeWillBeDeleted = async ({
  mergeDirection,
  dataSource,
}: {
  mergeDirection: MergeDirection;
  dataSource: DataSource;
}) => {
  const firstDataRow = dataSource && dataSource[0];
  const entityType = firstDataRow?.entityType || null;
  const [, verb] = firstDataRow?.path || [];

  if (
    verb === Verb.DELETE &&
    entityType === APIEntityType.COMPONENT &&
    mergeDirection === MergeDirection.BRANCH_TO_MAINLINE
  ) {
    const componentIds = getSelectedComponentIds(dataSource);
    return await requestComponentsOutOfScopeCheck(componentIds);
  }
  return {
    numberOfDescendantsOutsideScope: 0,
    numberOfReferencesOutsideScope: 0,
  };
};

export const confirmContinueDeletingEntitiesOutOfScopeModal = ({
  numberOfDescendantsOutsideScope,
  numberOfReferencesOutsideScope,
}: {
  numberOfDescendantsOutsideScope: number;
  numberOfReferencesOutsideScope: number;
}) => {
  const hasBoth =
    numberOfDescendantsOutsideScope && numberOfReferencesOutsideScope;
  const descendantsPart = numberOfDescendantsOutsideScope ? (
    <>
      {numberOfDescendantsOutsideScope}{' '}
      {pluralize('descendant', numberOfDescendantsOutsideScope)}
    </>
  ) : (
    <></>
  );
  const referencesPart = numberOfReferencesOutsideScope ? (
    <>
      {numberOfReferencesOutsideScope}{' '}
      {pluralize('reference', numberOfReferencesOutsideScope)}
    </>
  ) : (
    <></>
  );

  return confirm({
    title: 'Are you sure?',
    text: (
      <p>
        By deleting the selected components {descendantsPart}
        {hasBoth ? ' and ' : ''}
        {referencesPart} will also be deleted.
      </p>
    ),
  });
};
