import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodeEditor, EditorLanguage } from '@ardoq/code-editor';
import { updateSearchQuery } from 'search/actions';
import { SearchTabContainer } from 'search/QueryEditor/atoms';
import QueryActions from 'search/QueryEditor/QueryActions';
import {
  QueryEditorNamespace,
  QueryEditorStateShape,
  gremlinSearchEditor$,
} from 'search/QueryEditor/queryEditor$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import GremlinResults from '../Gremlin/GremlinResults/GremlinResults';
import { exportExcelGremlin, toggleRawResults } from '../Gremlin/actions';
import { GremlinSearchStateShape } from '../Gremlin/types';
import { gremlinQueryResults$ } from '../Gremlin/gremlinResults$';
import { usePaginationGremlinSearch } from '../Gremlin/usePaginationGremlinSearch';
import GremlinSearchError from '../Gremlin/GremlinResults/GremlinSearchError';
import {
  ReportEventLocations,
  ReportTrackingEvents,
} from '@ardoq/report-reader';
import {
  DataSourceType,
  GremlinSearchQuery,
  SearchType,
} from '@ardoq/api-types';
import {
  ButtonGroup,
  BrandButton,
  SecondaryButton,
  GhostButton,
} from '@ardoq/button';
import { openCreateReport } from '../../components/AppMainSidebar/utils';
import { AqLayout, Box } from '@ardoq/layout';
import { ToolbarDivider } from '@ardoq/page-layout';
import { Switch } from '@ardoq/forms';
import { logError } from '@ardoq/logging';
import { errorRecovery } from '../SearchTabContainer/utils';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { ExportIcon } from '@ardoq/icons';
import { Features, hasFeature } from '@ardoq/features';
import MainToolbar from 'menus/topbar/MainToolbar';
import Navbar from 'views/navbar/Navbar';
import { Space } from '@ardoq/style-helpers';
import { PageBody, PageWrapper } from '@ardoq/page-layout';

type ViewProps = GremlinSearchStateShape &
  QueryEditorStateShape & { hasNewJourneyFeature: boolean };

const GremlinSearch = ({
  isSearching,
  model,
  results,
  totalResults,
  showRawResults,
  syntaxError,
  searchError,
  queryParams,
  hasNewJourneyFeature,
}: ViewProps) => {
  const { resultsId, loadPaginatedResults, doPaginatedSearch } =
    usePaginationGremlinSearch({
      model,
      queryParams,
      searchType: SearchType.QUERY,
    });

  const handleCreateReport = () => {
    openCreateReport(ReportEventLocations.AD_HOC_GREMLIN_SEARCH, {
      datasource: DataSourceType.GREMLIN_SEARCH,
      query: model.query as GremlinSearchQuery,
    });
  };

  const pageContent = (
    <ErrorBoundary
      logError={logError}
      errorContextDescription="Search Error Boundary"
      recoverOnError={errorRecovery}
    >
      <SearchTabContainer>
        <CodeEditor
          language={EditorLanguage.GROOVY}
          value={model.query as string}
          onChange={(query: string) =>
            dispatchAction(
              updateSearchQuery({
                query,
              }),
              QueryEditorNamespace.GREMLIN_SEARCH
            )
          }
          onCtrlCmdEnter={doPaginatedSearch}
        />
        <QueryActions
          searchButtonText="Search"
          doSearch={doPaginatedSearch}
          showSpinner={isSearching}
          actionMenuClickId={
            ReportTrackingEvents.AD_HOC_GREMLIN_SEARCH_ACTION_MENU
          }
        />
        <Box marginTop="xlarge">
          {searchError && <GremlinSearchError syntaxError={syntaxError} />}
          {results && (
            <GremlinResults
              result={results}
              showRaw={showRawResults}
              loadResults={loadPaginatedResults}
              totalResults={totalResults}
              resultsId={resultsId}
            />
          )}
        </Box>
      </SearchTabContainer>
    </ErrorBoundary>
  );
  return hasNewJourneyFeature ? (
    <PageWrapper>
      <Navbar
        primaryContent="Gremlin Graph Search"
        primaryButton={
          <BrandButton onClick={handleCreateReport}>Create report</BrandButton>
        }
        secondaryContent="Analytics"
        secondaryButton={
          <GhostButton
            onClick={() =>
              dispatchAction(exportExcelGremlin({ query: model.query }))
            }
            data-tooltip-text="Export to Excel"
          >
            Export
            <ExportIcon />
          </GhostButton>
        }
        toolbarContent={
          <Space $align="center">
            <MainToolbar shouldUseNewJourneyVersion={true} />
            <ToolbarDivider />
            <Switch
              name="gremlin-show-raw-results"
              isChecked={showRawResults}
              onChange={() => displayModeToggleMenuOption(showRawResults)}
              label={
                showRawResults ? 'Show normal results' : 'Show raw results'
              }
            />
            <ToolbarDivider />
          </Space>
        }
      />
      <PageBody skipPadding backgroundColor="surfaceDefault">
        {pageContent}
      </PageBody>
    </PageWrapper>
  ) : (
    <AqLayout
      title="Gremlin Graph Search"
      bodyContentStyle={{
        height: '100%',
        position: 'relative',
        padding: 0,
        paddingBottom: 5,
      }}
      renderHeaderButtons={() => (
        <ButtonGroup>
          <Switch
            name="gremlin-show-raw-results"
            isChecked={showRawResults}
            onChange={() => displayModeToggleMenuOption(showRawResults)}
            label={showRawResults ? 'Show normal results' : 'Show raw results'}
          />
          <GhostButton
            onClick={() =>
              dispatchAction(exportExcelGremlin({ query: model.query }))
            }
            data-tooltip-text="Export to Excel"
          >
            <ExportIcon />
          </GhostButton>
          <SecondaryButton onClick={handleCreateReport}>
            Create report
          </SecondaryButton>
        </ButtonGroup>
      )}
    >
      {pageContent}
    </AqLayout>
  );
};

const displayModeToggleMenuOption = (showRawResults: boolean) =>
  dispatchAction(
    toggleRawResults({
      showRawResults: !showRawResults,
    }),
    SearchType.QUERY
  );

const mapStreamsToViewProps = ([queryEditor, gremlinResults]: [
  QueryEditorStateShape,
  GremlinSearchStateShape,
]): ViewProps => ({
  ...queryEditor,
  ...gremlinResults,
  hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
});

export default connect(
  GremlinSearch,
  combineLatest([gremlinSearchEditor$, gremlinQueryResults$]).pipe(
    map(mapStreamsToViewProps)
  )
);
