import {
  action$,
  dispatchAction,
  extractPayload,
  ofType,
  withNamespace,
} from '@ardoq/rxbeach';
import { NavigatorConfiguration, NavigatorLayoutState } from '../types';
import {
  clearHighlight,
  dragEnd,
  finalizeDragEnd,
} from '../actions/navigatorActions';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { SortAttribute } from '@ardoq/common-helpers';
import { NodeModel } from '../models/types';
import { DropTargetView, NavigatorDropType } from '../utils/consts';
import Tree from '../models/tree';
import { ConfirmResult, ModalSize, confirm } from '@ardoq/modal';
import { Features, hasFeature } from '@ardoq/features';
import { handleDropInNavigator } from '../dropInNavigator';
import { beforeDragEnd } from '../actions/navigatorActions';
import { getDropTarget } from '../utils/getDropTarget';
import { catchErrorLogWithMessageAndContinue } from 'streams/utils/streamOperators';
import { getDragTargetIds, getDragTargetNodes } from '../getDragTargetIds';
import { identity } from 'lodash';

export const getEndDragAndDropRoutine = (
  navigator$: Observable<NavigatorLayoutState>,
  { actionNamespace }: NavigatorConfiguration
) =>
  action$.pipe(
    actionNamespace ? withNamespace(actionNamespace) : identity,
    ofType(dragEnd),
    extractPayload(),
    withLatestFrom(navigator$),
    tap(
      async ([
        { event, isAborted },
        {
          tree,
          isDrag,
          dragTargetNode,
          dropTargetLayoutBox,
          dropTargetView,
          dropType,
          isDropTargetParent,
          isDropTargetBefore,
          selection,
          sort,
          navigatorViewInterface,
        },
      ]) => {
        event.preventDefault();
        event.stopPropagation();

        const { position, dropContext, targetDropId, isHandled } =
          handleExternalDrop(event, isAborted);

        if (!isDrag || isAborted) {
          dispatchAction(
            finalizeDragEnd({
              isAborted,
              position,
              changedIds: [],
              isHandled: false,
            }),
            actionNamespace
          );
          return;
        }

        const isSortedByOrder = sort.attr === SortAttribute.ORDER;
        const dropNode = getDropTarget(
          dropTargetLayoutBox,
          isDropTargetParent,
          isSortedByOrder
        );

        const isConfirmed = await confirmDrop({
          isAborted,
          dragTargetNode,
          dropNode,
          dropTargetView,
          dropType,
          selection,
          tree,
        });

        const ids = getDragTargetIds(selection, dragTargetNode);
        dispatchAction(
          // Externally handled action, should not be namespaced.
          beforeDragEnd({ ids, dropContext, targetDropId })
        );

        if (dropTargetView === DropTargetView.NAVIGATOR) {
          const { isHandled, changedIds } = await handleDropInNavigator({
            dragTargetNode,
            dropTargetLayoutBox,
            isDropTargetParent,
            isDropTargetBefore,
            dropType,
            isSortedByOrder,
            selection,
            isAborted: !isConfirmed,
            tree,
            sort,
            navigatorViewInterface,
          });
          dispatchAction(
            finalizeDragEnd({
              isAborted: !isConfirmed,
              changedIds,
              position,
              isHandled,
            }),
            actionNamespace
          );
          window.setTimeout(
            () => dispatchAction(clearHighlight(), actionNamespace),
            1000
          );
        } else {
          dispatchAction(
            finalizeDragEnd({
              isAborted: !isConfirmed,
              changedIds: [],
              position,
              isHandled,
            }),
            actionNamespace
          );
        }
      }
    ),
    catchErrorLogWithMessageAndContinue('Error in endDragAndDropRoutine')
  );

const SESSION_KEY_MOVE_OR_COPY = '[navigator] MOVE_OR_COPY';

const getDropConfirmUIStrings = (
  dropType: NavigatorDropType,
  dragTargetNodes: NodeModel[],
  dragTargetNode: NodeModel,
  dropNode: NodeModel
) => {
  const targetCount = dragTargetNodes.length;
  const hasChildren = dragTargetNodes.some(node => node.hasChildren());
  const dragTargetName =
    targetCount === 1 ? dragTargetNode.getName() : `${targetCount} components`;
  const entity = `component${hasChildren || targetCount > 1 ? 's' : ''}`;
  const isMove = dropType === NavigatorDropType.DROP_TYPE_MOVE;
  const title = `${isMove ? 'Move' : 'Copy'} ${entity}?`;
  const moveTarget =
    dragTargetNode.parent === dropNode
      ? 'a new position'
      : dropNode.getName() === dropNode.getRootNode().getName()
        ? 'the root level'
        : `${dropNode.getName()}`;
  const text = `This will ${isMove ? 'move' : 'copy'} ${dragTargetName}${
    hasChildren ? ` and ${targetCount === 1 ? 'its' : 'their'} children` : ''
  }${
    isMove
      ? ` to ${moveTarget}`
      : ` to the ${dropNode.getRootNode().getName()} workspace`
  }.`;
  const confirmButtonTitle = `${isMove ? 'Move' : 'Copy'}`;
  const text2 =
    hasFeature(Features.PERMISSION_ZONES) && isMove
      ? `NOTE: After this operation, the permissions zones of the ${entity} might change.`
      : undefined;
  return {
    title,
    text,
    text2,
    confirmButtonTitle,
  };
};

const handleExternalDrop = (event: Event, isAborted: boolean) => {
  const target = (event.target as Element)?.closest?.('.canDrop');
  if (
    isAborted ||
    !(target instanceof HTMLElement) ||
    !target.dataset.dropContext
  ) {
    return {
      isHandled: false,
      position: { x: 0, y: 0 },
      targetDropId: null,
      dropContext: null,
    };
  }
  const box = target.getBoundingClientRect();
  return {
    isHandled: true,
    targetDropId: target.dataset.id || null,
    dropContext: target.dataset.dropContext,
    position: { x: box.left, y: box.top },
  };
};

const confirmDrop = async ({
  isAborted,
  dragTargetNode,
  dropNode,
  dropTargetView,
  dropType,
  selection,
  tree,
}: {
  isAborted: boolean;
  dragTargetNode: NodeModel | null;
  dropNode: NodeModel | null;
  dropTargetView: DropTargetView;
  dropType: NavigatorDropType;
  selection: string[];
  tree: Tree;
}) => {
  const isSuppressConfirmDialog =
    window.sessionStorage.getItem(SESSION_KEY_MOVE_OR_COPY) === 'true';

  const isShowConfirm =
    !isSuppressConfirmDialog &&
    !isAborted &&
    dragTargetNode &&
    dropNode &&
    dropNode.hasWriteAccess &&
    dropTargetView === DropTargetView.NAVIGATOR &&
    (dropType === NavigatorDropType.DROP_TYPE_COPY ||
      dropType === NavigatorDropType.DROP_TYPE_MOVE);

  if (!isShowConfirm) {
    return true;
  }

  const dragTargetNodes = getDragTargetNodes(selection, dragTargetNode, tree);

  const { rememberAction, confirmed } = (await confirm({
    ...getDropConfirmUIStrings(
      dropType,
      dragTargetNodes,
      dragTargetNode,
      dropNode!
    ),
    showRememberActionCheckbox: true,
    rememberActionCustomCheckboxText: `Don't ask again in this session.`,
    modalSize: ModalSize.S,
  })) as ConfirmResult;

  if (rememberAction) {
    window.sessionStorage.setItem(SESSION_KEY_MOVE_OR_COPY, 'true');
  }
  return confirmed;
};
