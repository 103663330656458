import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { AppRouterState, TraversalRoute } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { TraversalModule } from './types';
import traversalsNavigation$ from './traversalsNavigation$';
import { navigateToViewpointsOverview } from 'streams/traversals/actions';

const traversalsRoute = new Route<AppRouterState, TraversalRoute | null>({
  doesLocationMatch: ({ path }) =>
    new RegExp(`\\${AppModules.TRAVERSALS}/?`).test(path),
  locationToRouterState: () => {
    return {
      appModule: AppModules.TRAVERSALS,
      traversalModule: TraversalModule.OVERVIEW,
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.TRAVERSALS,
  routerStateToLocation: () => {
    return { path: AppModules.TRAVERSALS, title: 'Traversal overview' };
  },
  setApplicationStateFromRoute: () => {
    dispatchAction(navigateToViewpointsOverview());
  },
  getPartialRouterStateStream: () => traversalsNavigation$.pipe(),
});

export default traversalsRoute;
