import { QueryResults as ITPediaQueryResults } from 'integrations/ITPedia/streams/types';
import { ComponentType, ConfigurationState, QueryResults } from '../types';

export const defaultState: ConfigurationState = {
  _id: null,
  name: 'Signavio Export',
  accountId: '',
  applyFilters: false,
  workspace: {
    id: null,
    name: '',
  },
  componentTypes: {},
  schedulingPolicy: {
    type: 'nightly',
  },
  results: {
    diff: {
      status: 'INIT',
    },
  },
  scheduleStatus: 'INIT',
};

export const defaultComponentType: ComponentType = {
  isQueryValid: true,
  dictionaryCategory: {
    value: null,
    label: '',
  },
  mappedOid: {
    value: null,
    label: '',
  },
  mappedComponentUrl: {
    value: null,
    label: '',
  },
};

// Todo: replace IT Pedia status with common AsyncStatus

export function ITPediaResultsToSignavioResults(
  itPediaResults: ITPediaQueryResults
) {
  let Status: QueryResults['status'] = 'INIT';
  switch (itPediaResults.status) {
    case 'Success':
      Status = 'SUCCESS';
      break;
    case 'Failed':
      Status = 'FAILURE';
      break;
    case 'Loading':
      Status = 'LOADING';
      break;
    case 'Initial':
      Status = 'INIT';
      break;
  }
  return {
    status: Status,
    message: itPediaResults.message,
    total: itPediaResults.total,
    components: itPediaResults.components,
  };
}
