import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { NewExportRoutes } from 'integrations/common/navigation/types';
import { performExport } from 'integrations/signavio-exporter/streams/api/actions';
import { configState$ } from 'integrations/signavio-exporter/streams/configurationState/configurationState$';
import { map } from 'rxjs';

const onNext = () => {
  dispatchAction(performExport({ dryRun: false }));
};

const onBack = () => {
  dispatchAction(
    navigateToPath({
      integrationId: 'signavio-exporter',
      path: NewExportRoutes.FIELD_MAPPING,
    })
  );
};

export const viewModel$ = configState$.pipe(
  map(state => ({
    onNext,
    onBack,
    diff: state.results.diff,
  }))
);
