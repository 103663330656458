import { AsyncStatus } from 'integrations/common/types/api';
import { ServiceNowTable } from '@ardoq/api-types/integrations';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  FetchServiceNowTablesSuccessPayload,
  fetchServiceNowTables,
  fetchServiceNowTablesFailure,
  fetchServiceNowTablesSuccess,
  resetState,
  setIsAsync,
} from './actions';

export type ServiceNowTablesState = {
  tables: ServiceNowTable[];
  asyncStatus: AsyncStatus;
  isAsync: boolean;
};

const defaultState: ServiceNowTablesState = {
  tables: [],
  asyncStatus: 'INIT',
  isAsync: true,
};

const resetStateReducer = () => defaultState;

const handleResetState = reducer<ServiceNowTablesState>(
  resetState,
  resetStateReducer
);

const setAsyncStatus =
  (asyncStatus: AsyncStatus) => (state: ServiceNowTablesState) => {
    return {
      ...state,
      asyncStatus,
    };
  };

const setIsAsyncReducer = (state: ServiceNowTablesState, isAsync: boolean) => ({
  ...state,
  isAsync,
});
const handleSetIsAsync = reducer<ServiceNowTablesState, boolean>(
  setIsAsync,
  setIsAsyncReducer
);

const handleFetchServiceNowTables = reducer<ServiceNowTablesState>(
  fetchServiceNowTables,
  setAsyncStatus('LOADING')
);

const handleFetchServiceNowTablesFailure = reducer<ServiceNowTablesState>(
  fetchServiceNowTablesFailure,
  setAsyncStatus('FAILURE')
);

const fetchServiceNowTablesSuccessReducer = (
  state: ServiceNowTablesState,
  { tables }: FetchServiceNowTablesSuccessPayload
) => {
  return {
    ...state,
    tables,
    asyncStatus: 'SUCCESS' as AsyncStatus,
  };
};
const handleFetchServiceNowTablesSuccess = reducer<
  ServiceNowTablesState,
  FetchServiceNowTablesSuccessPayload
>(fetchServiceNowTablesSuccess, fetchServiceNowTablesSuccessReducer);

const reducers = [
  handleFetchServiceNowTablesSuccess,
  handleFetchServiceNowTables,
  handleFetchServiceNowTablesFailure,
  handleResetState,
  handleSetIsAsync,
];

export const serviceNowTables$ = persistentReducedStream(
  `serviceNowTables$`,
  defaultState,
  reducers
);
