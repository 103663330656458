import styled from 'styled-components';
import { FlexBox } from '@ardoq/layout';
import { colors, radius } from '@ardoq/design-tokens';
import { ComponentDecoratorAsIcon } from '@ardoq/decorators';
import { TextOverflow } from '@ardoq/popovers';
import { CurrentToolbarContext } from './types';

const Container = styled(FlexBox)`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, calc(-100% + 1px));
  animation: 0.2s summary-in;
  z-index: -1;
  border: 1px solid ${colors.borderSubtle00};
  border-bottom: 0;
  border-radius: ${radius.small} ${radius.small} 0 0;
  max-width: 80%;
  @keyframes summary-in {
    from {
      transform: translate(-50%, 0);
    }
  }
`;

const CurrentSelection = ({
  currentContext,
}: {
  currentContext: CurrentToolbarContext;
}) => (
  <Container
    backgroundColor="bgDefault"
    padding="small"
    align="center"
    gap="small"
  >
    {currentContext.representationData && (
      <ComponentDecoratorAsIcon {...currentContext.representationData} />
    )}
    <TextOverflow>{currentContext?.label ?? 'No selection'}</TextOverflow>
  </Container>
);

export default CurrentSelection;
