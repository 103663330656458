import { TextInput } from '@ardoq/forms';
import { ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { Paragraph } from '@ardoq/typography';
import { useState } from 'react';

export const TokenCreationModal = ({
  onCancel,
  onSubmit,
}: {
  onCancel: () => void;
  onSubmit: (tokenName: string) => void;
}) => {
  const [tokenName, setTokenName] = useState('');

  return (
    <ModalTemplate
      headerText="Create new token?"
      modalSize={ModalSize.S}
      primaryButtonText="Create token"
      onPrimaryButtonClick={() => onSubmit(tokenName)}
      isPrimaryButtonDisabled={!tokenName}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onCancel}
    >
      <ModalLayout>
        <Paragraph variant="text2">
          Create a new token to enable secure access. Tokens are tied to the
          organization where they were created and will expire 60 days after
          their last use.
        </Paragraph>
        <TextInput
          label="Token name"
          placeholder="Untitled"
          value={tokenName}
          onValueChange={setTokenName}
        />
      </ModalLayout>
    </ModalTemplate>
  );
};
