import { combineLatest, of } from 'rxjs';
import { dispatchAction } from '@ardoq/rxbeach';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { selectionState$ } from '../../streams/selectionState/selectionState$';
import { isValidSelectionState } from '../../streams/selectionState/utils';
import {
  applySourceConfig,
  setQueryFilter,
  unselectTables,
} from '../../streams/selectionState/actions';
import {
  SavedTransferConfig,
  ServiceNowConfig,
} from '@ardoq/api-types/integrations';
import { Connection } from '@ardoq/api-types/integrations';
import { setTransferConfigId } from 'integrations/common/streams/activeIntegrations/actions';
import { clearTabularMapping } from 'integrations/common/streams/tabularMappings/actions';
import fp from 'lodash/fp';
import { legacyConfigs$ } from '../../streams/legacyConfigs/legacyConfigs$';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { selectConnection } from 'integrations/common/streams/connections/actions';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getIsUserAgreeToOverrideMapping } from '../../../common/streams/transferConfigs/utils';
import { loadSelection } from 'integrations/common/streams/selectionState/actions';
import { getFetchTablesError } from 'integrations/service-now/utils';

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      transferConfigs: getTransferConfigsStream(integrationId).pipe(
        map(({ configs }) => configs)
      ),
      connectionsState: getConnectionsStream(integrationId),
      selectedTransferConfigId: getActiveIntegrationStream(integrationId).pipe(
        map(ci => ci.selectedTransferConfigId),
        distinctUntilChanged()
      ),
      transferState: getTransferStateStream(integrationId),
      selectionState: selectionState$,
      legacyConfigs: legacyConfigs$,
      integrationTermsDictionary:
        getIntegrationTermsDictionaryStream(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
    });
  }),
  map(
    ({
      integrationId,
      transferConfigs,
      connectionsState,
      selectedTransferConfigId,
      selectionState,
      legacyConfigs: { configs: legacyConfigs },
      transferState,
      integrationTermsDictionary: {
        selectConnectionHelperText,
        selectConfigHelperText,
        selectConnectionPlaceHolder,
      },
      tabularMapping,
    }) => ({
      connections: connectionsState.connections,
      tableFetchError: getFetchTablesError(selectionState, connectionsState),
      selectedConnectionIds: connectionsState.selectedConnectionIds,
      transferConfigs,
      selectedTransferConfigId,
      isValidSelectionState: isValidSelectionState(
        selectionState,
        connectionsState.selectedConnectionIds
      ),
      unavailableTables: selectionState.unavailableTables,
      transferDirection: transferState.transferDirection,
      selectConnectionHelperText,
      selectConnectionPlaceHolder,
      selectConfigHelperText,
      onTransferConfigSelect: async (
        configId: SavedTransferConfig['_id'] | null
      ) => {
        if (selectedTransferConfigId === configId) {
          return;
        }

        const transferConfig = transferConfigs.find(m => m._id === configId);

        if (!transferConfig) {
          return;
        }

        const isUserAgreeToOverrideMapping =
          await getIsUserAgreeToOverrideMapping(tabularMapping);

        if (!isUserAgreeToOverrideMapping) {
          return;
        }

        dispatchAction(clearTabularMapping({ integrationId }));
        dispatchAction(
          setTransferConfigId({ integrationId: 'servicenow-v3', id: configId })
        );

        if (!transferConfig.sourceConfig) {
          const legacyConfig = legacyConfigs.find(
            c => c.name === transferConfig.name
          ) ?? { tables: [] };

          const sourceConfig: ServiceNowConfig = {
            connectionId: connectionsState.selectedConnectionIds[0] || '',
            tables: legacyConfig.tables,
          };
          dispatchAction(applySourceConfig(sourceConfig));
          return;
        }

        dispatchAction(
          applySourceConfig(
            // We are not overiding the connectionId if already in the selectionState
            fp.update(
              'connectionId',
              cid => connectionsState.selectedConnectionIds[0] || cid,
              transferConfig.sourceConfig
            )
          )
        );
      },
      onConnectionSelect: (connectionsIds: Connection['_id'][]) => {
        if (!connectionsIds.length) {
          return;
        }

        dispatchAction(
          selectConnection({
            integrationId: 'servicenow-v3',
            selectedConnectionId: connectionsIds[0],
          })
        );
        if (selectionState.currentTableId) {
          dispatchAction(
            setQueryFilter({
              tableId: selectionState.currentTableId,
              queryFilter: null,
            })
          );
        }
      },
      onFetchData: () => {
        dispatchAction(loadSelection('servicenow-v3'));
      },
      isFetchable: false,
      onClearConfiguration: () => {
        dispatchAction(unselectTables());
        dispatchAction(clearTabularMapping({ integrationId: 'servicenow-v3' }));
        dispatchAction(
          setTransferConfigId({ integrationId: 'servicenow-v3', id: null })
        );
      },
    })
  )
);
