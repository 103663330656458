import TabbedAudienceTable from 'broadcasts/components/audienceTable/tabbedAudienceTable/TabbedAudienceTable';
import ProgressWidget from 'broadcasts/broadcastOverview/ProgressWidget';
import DetailsDialogRow from 'broadcasts/components/DetailsDialogRow';
import {
  AudienceTableColumn,
  AudienceTableTab,
} from 'broadcasts/components/audienceTable/types';
import { isEmpty, isNumber } from 'lodash';
import { DefaultNotification } from '@ardoq/status-ui';
import {
  getFormattedDate,
  getFutureDate,
  mergeAudiencePreviewRowsByPerson,
} from 'broadcasts/utils';
import { getRulesAsText } from '../../broadcastBuilder/broadcastContentForm/reportRulesSection/utils';
import {
  InstanceProgress,
  AudiencePreview,
  BroadcastContentType,
  Report,
  ArdoqId,
  APIBroadcastAttributes,
  BroadcastInstance,
} from '@ardoq/api-types';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import reports$ from 'streams/reports/reports$';
import { map } from 'rxjs/operators';
import { getReportContent } from 'broadcasts/infoDialogDataUtils';
import progressDetails$ from './stream/progressDetails$';
import { combineLatest } from 'rxjs';
import { ProgressDetailsState } from './stream/types';
import { setFilter } from './stream/actions';

type ShowProgressOrInfoMessageProps = {
  readonly progress: InstanceProgress;
  readonly audiencePreview: AudiencePreview;
  readonly contentType: BroadcastContentType;
};

const ShowProgressOrInfoMessage = ({
  progress,
  contentType,
  audiencePreview,
}: ShowProgressOrInfoMessageProps) => {
  if (
    contentType === BroadcastContentType.MESSAGE ||
    contentType === BroadcastContentType.REPORT
  ) {
    return null;
  } else if (!isEmpty(progress)) {
    return <ProgressWidget progress={progress} />;
  } else if (audiencePreview.length > 0) {
    return (
      <DefaultNotification>
        There are no components matching the filter in this instance.
      </DefaultNotification>
    );
  }
  return (
    <DefaultNotification>
      There are no audiences or components matching the filter in this instance.
    </DefaultNotification>
  );
};

const getReminderValue = (
  hasErrors: boolean,
  isCancelled: boolean,
  instanceReminderDate: string | null,
  reminderInterval: number,
  date: string
) => {
  if (hasErrors) {
    return 'Unexpected errors. This reminder fails';
  }
  if (isCancelled) {
    return 'Reminder is cancelled';
  }
  if (instanceReminderDate) {
    return getFormattedDate(instanceReminderDate);
  }
  return getFormattedDate(getFutureDate(date, reminderInterval));
};

type ProgressDetailsProps = ProgressDetailsState & {
  readonly reportsById: Record<ArdoqId, Report>;
  readonly broadcast: APIBroadcastAttributes;
  readonly instance: BroadcastInstance;
};

const ProgressDetails = ({
  reportsById,
  broadcast,
  instance,
  filter,
}: ProgressDetailsProps) => {
  const contentType = broadcast.content.contentType;
  const report =
    contentType === BroadcastContentType.REPORT && broadcast.content.contentId
      ? reportsById[broadcast.content.contentId]
      : null;

  const hasErrors = !isEmpty(broadcast.errors);
  const reminderInterval = broadcast.scheduling.reminderInDays;
  const isCancelled =
    isNumber(reminderInterval) &&
    reminderInterval > 0 &&
    instance.reminderDate === null;
  const date = getFormattedDate(instance.executedDate);
  const audiencePreview = mergeAudiencePreviewRowsByPerson(instance.audience);
  const { aggregate, threshold, predicate, columnName } = getReportContent(
    broadcast.content
  );
  return (
    <>
      <DetailsDialogRow label="Broadcast date" value={date} />
      {reminderInterval && (
        <DetailsDialogRow
          label="Reminder date"
          value={getReminderValue(
            hasErrors,
            isCancelled,
            instance.reminderDate,
            reminderInterval,
            date
          )}
        />
      )}
      {contentType === BroadcastContentType.REPORT && report && (
        <DetailsDialogRow label="Report name" value={report.name} />
      )}
      {contentType === BroadcastContentType.REPORT &&
        report &&
        aggregate &&
        threshold !== null &&
        predicate &&
        columnName && (
          <DetailsDialogRow
            label="Report threshold"
            value={getRulesAsText(
              report.columns.find(column => column.key === columnName)?.label ??
                columnName,
              aggregate,
              predicate,
              threshold
            )}
          />
        )}
      <ShowProgressOrInfoMessage
        progress={instance.progress ?? {}}
        contentType={contentType}
        audiencePreview={audiencePreview}
      />
      <TabbedAudienceTable
        components={instance.components}
        audiencePreview={audiencePreview}
        progress={instance.progress ?? {}}
        initiallySelectedTab={AudienceTableTab.COMPONENT}
        defaultAudienceSortById={AudienceTableColumn.PERSON_LABEL}
        defaultComponentSortById={AudienceTableColumn.COMPONENT_STATUS}
        contentType={contentType}
        tableFilter={filter}
        onTableFilterChange={query => dispatchAction(setFilter(query))}
      />
    </>
  );
};

export default connect(
  ProgressDetails,
  combineLatest([reports$, progressDetails$]).pipe(
    map(([{ byId: reportsById }, progressDetails]) => ({
      reportsById,
      ...progressDetails,
    }))
  )
);
