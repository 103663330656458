import { AqLayout, FlexBox } from '@ardoq/layout';
import { CreateNewButton, PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import { dispatchAction, connect, derivedStream } from '@ardoq/rxbeach';
import {
  ButtonGroup,
  PrimaryButton,
  SecondaryButton,
  GhostButton,
} from '@ardoq/button';
import {
  navigateToDefaultViewpoints,
  navigateToViewpointForm,
} from 'router/navigationActions';
import viewpoints$ from './viewpoints$';
import styled from 'styled-components';
import { colors, s16, s24, s8 } from '@ardoq/design-tokens';
import { IconName, OpenInNewIcon } from '@ardoq/icons';
import IconClickable from 'atomicComponents/IconClickable';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { trackEvent } from 'tracking/tracking';
import { ViewpointTrackingEventsNames } from './tracking';
import { popoverRegistry } from '@ardoq/popovers';
import { CANNOT_CREATE_VIEWPOINT_POPOVER_ID, STRINGS } from './consts';
import { UnauthorizedAccessPage } from '@ardoq/manage-resource-permissions';
import { viewpointAccessControlInterface } from 'resourcePermissions/accessControlHelpers/viewpoints';
import { hasFeature, Features } from '@ardoq/features';
import MainToolbar from 'menus/topbar/MainToolbar';
import { Switch } from '@ardoq/forms';
import { toggleViewpointLegacyMode } from './actions';
import { map } from 'rxjs';
import Navbar from 'views/navbar/Navbar';
import { WarningNotification } from '@ardoq/status-ui';
import {
  APIDiscoverViewpointAttributes,
  AssetType,
  PrivilegeLabel,
} from '@ardoq/api-types';
import currentUser$, {
  CurrentUserState,
  getIsOrgWriter,
} from 'streams/currentUser/currentUser$';
import { ViewpointsStreamShape } from './types';
import {
  viewpointsOverviewCommands,
  ViewpointsOverviewCommands,
} from '../traversals/TraversalOverview/viewpointsOverviewCommands';
import { Link } from '@ardoq/typography';
import { hasPrivilege } from '@ardoq/privileges';
import AssetsBrowser2024 from '../components/AssetsBrowser2024/AssetsBrowser2024';
import { AppModules } from '../appContainer/types';
import {
  favoriteFilter,
  getAssetFilter,
  getCreatedByMeFilter,
} from '../components/AssetsBrowser2024/FilterBar/filters';
import {
  getLastUpdatedColumn,
  getUpdatedByColumn,
} from '../components/AssetsBrowser2024/assetsBrowser2024Columns';
import { useWindowSize } from '@ardoq/hooks';
import {
  getContextComponentTypeColumn,
  getVisualizationColumn,
} from '../traversals/TraversalOverview/columns';
import { getStatusColumn } from './columns';
import { PageBody, PageWrapper } from '@ardoq/page-layout';

const LegacySwich = ({
  isInLegacyViewpointMode,
}: {
  isInLegacyViewpointMode: boolean;
}) => {
  return (
    <Switch
      name="legacy-mode"
      isChecked={isInLegacyViewpointMode}
      onChange={() => {
        trackEvent('Topbar: clicked set default viewpoints');
        dispatchAction(toggleViewpointLegacyMode());
      }}
      label="Show Discover Viewpoints"
    />
  );
};

const GoToDiscoverButton = () => (
  <GhostButton
    onClick={() => window.open(`/discover/`, '_blank')}
    dataTestId="discover-button"
  >
    Go to Discover <OpenInNewIcon />
  </GhostButton>
);

type ViewpointAssetsBrowserProps = {
  height: number;
  currentUserId: string;
  showDiscoverButton?: boolean;
};

export const ViewpointAssetsBrowser = ({
  height,
  currentUserId,
  showDiscoverButton,
}: ViewpointAssetsBrowserProps) => (
  <AssetsBrowser2024
    appModule={AppModules.TRAVERSALS}
    alwaysActiveFilters={[getAssetFilter(AssetType.TRAVERSAL)]}
    filters={[favoriteFilter, getCreatedByMeFilter(currentUserId)]}
    topRightContent={showDiscoverButton && <GoToDiscoverButton />}
    scrollHeight={height}
    getCustomColumns={columnsProps => [
      getContextComponentTypeColumn(columnsProps),
      getVisualizationColumn(columnsProps),
      getUpdatedByColumn(columnsProps),
      getLastUpdatedColumn(columnsProps),
    ]}
  />
);

type DiscoverViewpointAssetsBrowserProps = {
  height: number;
  currentUserId: string;
};

const DiscoverViewpointAssetsBrowser = ({
  height,
  currentUserId,
}: DiscoverViewpointAssetsBrowserProps) => (
  <AssetsBrowser2024
    appModule={AppModules.VIEWPOINTS}
    alwaysActiveFilters={[getAssetFilter(AssetType.VIEWPOINT)]}
    filters={[favoriteFilter, getCreatedByMeFilter(currentUserId)]}
    topRightContent={<GoToDiscoverButton />}
    scrollHeight={height}
    getCustomColumns={columnsProps => [
      getStatusColumn(columnsProps),
      getUpdatedByColumn(columnsProps),
      getLastUpdatedColumn(columnsProps),
    ]}
  />
);

const Notification = styled(WarningNotification)`
  padding: ${s8} ${s16};
`;

const RightAligned = styled.div`
  display: flex;
  flex-grow: 1;
  min-width: fit-content;
  justify-content: flex-end;
`;

popoverRegistry.set(CANNOT_CREATE_VIEWPOINT_POPOVER_ID, () => (
  <div>{STRINGS.CANNOT_CREATE_VIEWPOINT_POPOVER_TEXT}</div>
));

type ViewpointOverviewProps = {
  viewpoints: APIDiscoverViewpointAttributes[] | null;
  isInLegacyViewpointMode: boolean;
  hasNewJourneyFeature: boolean;
  canUserCreateTraversals: boolean;
  discoverViewpointOverviewHidden: boolean;
  commands: ViewpointsOverviewCommands;
  currentUserId: string;
};

const ViewpointOverview = ({
  isInLegacyViewpointMode,
  hasNewJourneyFeature,
  canUserCreateTraversals,
  discoverViewpointOverviewHidden,
  commands,
  currentUserId,
}: ViewpointOverviewProps) => {
  const canCreateViewpoint =
    viewpointAccessControlInterface.canCreateViewpoint();

  const screenHeight = useWindowSize().height;

  const handleCreateViewpoint = () => {
    trackEvent(
      ViewpointTrackingEventsNames.CLICKED_ON_CREATE_VIEWPOINT_IN_VIEWPOINT_OVERVIEW
    );
    dispatchAction(navigateToViewpointForm(null));
  };

  const handleCreateNewViewpoint = () => {
    trackEvent(
      ViewpointTrackingEventsNames.CLICKED_ON_CREATE_NEW_VIEWPOINT_IN_VIEWPOINT_OVERVIEW
    );
    return commands.createNewViewpoint();
  };

  const handleNavigateToDefaultViewpoints = () => {
    trackEvent(
      ViewpointTrackingEventsNames.CLICKED_ON_SET_DEFAULT_VIEWPOINTS_IN_VIEWPOINT_OVERVIEW
    );
    dispatchAction(navigateToDefaultViewpoints());
  };

  const hasDiscoverPrivilege = hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER);

  const isLegacyModeAndUserCantCreateViewpoint =
    isInLegacyViewpointMode && !canCreateViewpoint;

  const isNotLegacyModeAndUserCantCreateTraversals =
    !isInLegacyViewpointMode && !canUserCreateTraversals;

  const userCantCreateViewpointOrTraversal =
    isLegacyModeAndUserCantCreateViewpoint ||
    isNotLegacyModeAndUserCantCreateTraversals;

  // Needed to prevent a scrollbar on the overview page
  const PIXELS_ABOVE_ASSETS_BROWSER = hasNewJourneyFeature
    ? 70 + PAGE_TOPBAR_HEIGHT
    : 130;

  return hasNewJourneyFeature ? (
    <PageWrapper>
      <Navbar
        primaryContent="Viewpoints"
        primaryButton={
          <CreateNewButton
            dataTestId="create-viewpoints-button"
            onClick={
              isInLegacyViewpointMode
                ? handleCreateViewpoint
                : handleCreateNewViewpoint
            }
            popoverId={
              userCantCreateViewpointOrTraversal
                ? CANNOT_CREATE_VIEWPOINT_POPOVER_ID
                : undefined
            }
            isDisabled={userCantCreateViewpointOrTraversal}
          />
        }
        secondaryButton={
          hasDiscoverPrivilege &&
          !discoverViewpointOverviewHidden && (
            <GhostButton
              onClick={handleNavigateToDefaultViewpoints}
              dataTestId="defaults-overview-button"
              isDisabled={!isInLegacyViewpointMode}
            >
              Set defaults
            </GhostButton>
          )
        }
        secondaryContent="Overview"
        toolbarContent={
          <FlexBox align="center" gap="medium" flex={1}>
            <MainToolbar shouldUseNewJourneyVersion={true} />
            {hasDiscoverPrivilege && !discoverViewpointOverviewHidden && (
              <LegacySwich isInLegacyViewpointMode={isInLegacyViewpointMode} />
            )}
          </FlexBox>
        }
      />
      <PageBody backgroundColor="surfaceDefault">
        <>
          {isInLegacyViewpointMode && (
            <Notification>
              <FlexBox gap="xsmall">
                The Discover Viewpoints can be edited here but can only be used
                in Discover.
                <Link
                  target="_blank"
                  hideIcon
                  href={KnowledgeBaseLink.EMPTY_STATE_VIEW_BUILDER}
                  typography="text2"
                >
                  Learn about the new Ardoq Viewpoints
                </Link>
              </FlexBox>
            </Notification>
          )}
          {isInLegacyViewpointMode ? (
            <DiscoverViewpointAssetsBrowser
              height={screenHeight - PIXELS_ABOVE_ASSETS_BROWSER}
              currentUserId={currentUserId}
            />
          ) : (
            <ViewpointAssetsBrowser
              height={screenHeight - PIXELS_ABOVE_ASSETS_BROWSER}
              currentUserId={currentUserId}
              showDiscoverButton={hasDiscoverPrivilege}
            />
          )}
        </>
      </PageBody>
    </PageWrapper>
  ) : (
    <AqLayout
      title="Viewpoint overview"
      buttonContainerStyle={{
        flexGrow: 1,
        alignItems: 'center',
      }}
      renderHeaderButtons={() => (
        <>
          <IconClickable
            color={colors.iconAction}
            hoverColor={colors.iconActionHover}
            iconName={IconName.KNOWLEDGE_BASE}
            tooltipText="Knowledge base"
            onClick={() => {
              trackEvent('Clicked knowledge base link', {
                location: 'Viewpoints overview',
              });
              window.open(
                KnowledgeBaseLink.VIEWPOINTS_OVERVIEW,
                '_blank noopener'
              );
            }}
            containerStyle={{
              marginLeft: s8,
              height: s24,
            }}
          />
          <RightAligned>
            <ButtonGroup>
              <SecondaryButton
                onClick={handleNavigateToDefaultViewpoints}
                dataTestId="defaults-overview-button"
              >
                Set default viewpoints
              </SecondaryButton>
              <PrimaryButton
                dataTestId="create-viewpoints-button"
                onClick={handleCreateViewpoint}
                popoverId={
                  canCreateViewpoint
                    ? undefined
                    : CANNOT_CREATE_VIEWPOINT_POPOVER_ID
                }
                isDisabled={!canCreateViewpoint}
              >
                Create viewpoint
              </PrimaryButton>
            </ButtonGroup>
          </RightAligned>
        </>
      )}
    >
      <DiscoverViewpointAssetsBrowser
        height={screenHeight - PIXELS_ABOVE_ASSETS_BROWSER}
        currentUserId={currentUserId}
      />
    </AqLayout>
  );
};

const ViewpointOverviewContainer = (
  props: Omit<ViewpointOverviewProps, 'discoverViewpointOverviewHidden'>
) => {
  const discoverViewpointOverviewHidden =
    viewpointAccessControlInterface.getDiscoverViewpointOverViewHidden(
      props.viewpoints
    );
  if (discoverViewpointOverviewHidden && !props.hasNewJourneyFeature) {
    return <UnauthorizedAccessPage />;
  }
  return (
    <ViewpointOverview
      {...props}
      discoverViewpointOverviewHidden={discoverViewpointOverviewHidden}
    />
  );
};

const toViewpointOverviewStream = ([
  { viewpoints, isInLegacyViewpointMode },
  currentUser,
]: [ViewpointsStreamShape, CurrentUserState]) => {
  return {
    viewpoints,
    isInLegacyViewpointMode,
    hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
    canUserCreateTraversals: getIsOrgWriter(currentUser),
    currentUserId: currentUser._id,
    commands: viewpointsOverviewCommands,
  };
};

const viewpointOverview$ = derivedStream(
  'viewpointOverview$',
  viewpoints$,
  currentUser$
).pipe(map(toViewpointOverviewStream));

export default connect(ViewpointOverviewContainer, viewpointOverview$);
