import { SelectOption } from '@ardoq/select';
import { NewBadge } from '@ardoq/status-ui';
import { Stack } from '@ardoq/layout';
import { IntegrationWorkspace } from '@ardoq/api-types/integrations';
import { Maybe } from '@ardoq/common-helpers';
import { useMemo } from 'react';
import { testId } from 'integrations/common/testUtils/testId';
import { byLocaleField } from 'integrations/common/utils/common';
import { ExtendedWorkspaceSelector } from 'integrations/common/components/ExtendedWorkspaceSelector';

type WorkspaceSelectorProps = {
  title?: string;
  selectedWorkspace?: Maybe<IntegrationWorkspace>;
  workspaces: {
    used: IntegrationWorkspace[];
    existing: IntegrationWorkspace[];
  };
  onWorkspaceSelect: (workspace: IntegrationWorkspace) => void;
  isDisabled?: boolean;
  isCreationDisabled?: boolean;
  helperText?: string;
};

export const WorkspaceSelector = ({
  title,
  selectedWorkspace,
  workspaces,
  onWorkspaceSelect,
  isDisabled,
  isCreationDisabled,
  helperText,
}: WorkspaceSelectorProps) => {
  const workspaceToOption = (ws: IntegrationWorkspace) => ({
    label: ws.name,
    value: ws.id || ws.name,
    rightContent: !ws.id ? <NewBadge /> : null,
  });

  const usedWorkspaces = useMemo(
    () => workspaces.used.map(workspaceToOption).sort(byLocaleField('label')),
    [workspaces.used]
  );

  const allWorkspaces = useMemo(
    () =>
      workspaces.existing.map(workspaceToOption).sort(byLocaleField('label')),
    [workspaces.existing]
  );

  const options = [
    {
      label: 'Used in import',
      options: usedWorkspaces,
    },
    {
      label: 'All workspaces',
      options: allWorkspaces,
    },
  ];
  const handleWorkspaceSelect = (option: SelectOption<string> | null) => {
    if (!option) {
      return;
    }

    const newWorkspace: IntegrationWorkspace = {
      id: null,
      name: option.value,
    };

    const workspace =
      workspaces.existing.find(ws => ws.id === option.value) || newWorkspace;

    onWorkspaceSelect(workspace);
  };

  return (
    <Stack
      gap="small"
      {...testId('tabularMapping-workspace-selector-container')}
    >
      <ExtendedWorkspaceSelector
        label={title}
        placeholder="Select or start typing"
        value={selectedWorkspace?.id || selectedWorkspace?.name}
        options={options}
        onChange={handleWorkspaceSelect}
        isDisabled={isDisabled}
        helperText={helperText}
        isCreationDisabled={isCreationDisabled}
      />
    </Stack>
  );
};
