import { IconButton } from '@ardoq/button';
import { colors } from '@ardoq/design-tokens';
import { IconName } from '@ardoq/icons';
import { Header3, caption } from '@ardoq/typography';
import styled from 'styled-components';
import { LoadingAnimation } from 'bestPracticeAssistant/components/LoadingAnimation';
import { BetaBadge } from '@ardoq/status-ui';
import { DraggableHandle } from '@ardoq/draggable';
import { FlexBox, Stack } from '@ardoq/layout';

type ViewInferenceHeaderProps = {
  onClose: () => void;
  isLoading: boolean;
};

export const ViewInferenceHeader = ({
  onClose,
  isLoading,
}: ViewInferenceHeaderProps) => {
  return (
    <Stack>
      <DraggableHandle ignoreFormElements>
        <FlexBox paddingX="medium" paddingTop="medium" justify="space-between">
          <FlexBox gap="small">
            <LoadingAnimation
              isActive={isLoading}
              rows={2}
              columns={4}
              cellSize={16}
            />
            <BetaBadge
              knowledgeBaseUrl="https://help.ardoq.com/en/articles/171731-how-to-create-viewpoints-using-ai"
              shareFeedbackUrl="https://www.surveymonkey.com/r/FXDCDXY"
            />
          </FlexBox>
          <div>
            <IconButton iconName={IconName.CLOSE} onClick={onClose} />
          </div>
        </FlexBox>
      </DraggableHandle>
      <FlexBox padding="medium">
        {isLoading ? (
          <Title>Generating dataset...</Title>
        ) : (
          <Stack gap="xsmall">
            <Title>Add new dataset with AI</Title>
            <SubTitle>
              Describe what you want to see or select an option to generate a
              new dataset.
            </SubTitle>
          </Stack>
        )}
      </FlexBox>
    </Stack>
  );
};

const Title = styled(Header3)`
  color: ${colors.textDefault};
`;

const SubTitle = styled.div`
  ${caption};
  color: ${colors.grey50};
`;
