import { forwardRef } from 'react';
import styled from 'styled-components';
import { Icon, IconName } from '@ardoq/icons';
import { TopBarButton } from 'appContainer/MainAppModule/MainAppModuleSidebar/atoms';
import Badge from './Badge';

const EyeIconButton = styled(TopBarButton)`
  position: relative;
`;

export type PerspectiveMenuButtonProps = {
  onMenuOpen: VoidFunction;
  isMenuOpened: boolean;
  isScenarioMode: boolean;
  badgeCount: number;
};

/**
 * This is the "target" element that the drop-down will align itself with
 */
export const PerspectiveMenuButton = forwardRef<
  HTMLButtonElement,
  PerspectiveMenuButtonProps
>((props, ref) => {
  return (
    <EyeIconButton
      ref={ref}
      data-click-id="perspectives-menu-toggle"
      data-tooltip-text="Perspective"
      $isScenarioMode={props.isScenarioMode}
      $isActive={props.isMenuOpened}
      onClick={props.onMenuOpen}
    >
      <Icon iconName={IconName.VISIBILITY} />
      <Badge count={props.badgeCount} />
    </EyeIconButton>
  );
});
