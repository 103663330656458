import { actionCreator } from '@ardoq/rxbeach';
import {
  RequestEntityGroupChangePayload,
  SetUseCaseFieldValuePayload,
  UpdatePdfLinkPayload,
  UpdateTriplePartPayload,
  UseCaseEditorStreamState,
} from '../types';

export const addTriple = actionCreator('[useCaseEditor] ADD_TRIPLE');

export const removeTriple = actionCreator<number>(
  '[useCaseEditor] REMOVE_TRIPLE'
);

export const updateTripleSourceComponentType =
  actionCreator<UpdateTriplePartPayload>(
    '[useCaseEditor] UPDATE_TRIPLE_SOURCE_COMPONENT_TYPE'
  );

export const updateTripleReferenceType = actionCreator<UpdateTriplePartPayload>(
  '[useCaseEditor] UPDATE_TRIPLE_REFERENCE_COMPONENT_TYPE'
);

export const updateTripleTargetComponentType =
  actionCreator<UpdateTriplePartPayload>(
    '[useCaseEditor] UPDATE_TRIPLE_TARGET_COMPONENT_TYPE'
  );

export const addPdfLink = actionCreator('[useCaseEditor] ADD_PDF_LINK');

export const removePdfLink = actionCreator<number>(
  '[useCaseEditor] REMOVE_PDF_LINK'
);

export const updatePdfLink = actionCreator<UpdatePdfLinkPayload>(
  '[useCaseEditor] UPDATE_PDF_LINK'
);

// This will be left as a standalone action because of upcoming changes that need it specifically
export const setEntityGroup = actionCreator<string>(
  '[useCaseEditor] SET_ENTITY_GROUP'
);

export const saveUseCase = actionCreator('[useCaseEditor] SAVE_USE_CASE');

export const saveUseCasePending = actionCreator(
  '[useCaseEditor] SAVE_USE_CASE_PENDING'
);

export const saveUseCaseSuccess = actionCreator(
  '[useCaseEditor] SAVE_USE_CASE_SUCCESS'
);

export const setEditorErrors = actionCreator<string[]>(
  '[useCaseEditor] SET_EDITOR_ERRORS'
);

export const resetEditor = actionCreator('[useCaseEditor] RESET_EDITOR');

export const setInitialEditorState = actionCreator<
  Partial<UseCaseEditorStreamState>
>('[useCaseEditor] SET_INITIAL_EDITOR_STATE');

export const setFieldValue = actionCreator<SetUseCaseFieldValuePayload>(
  '[useCaseEditor] SET_FIELD_VALUE'
);

export const requestEntityGroupChange =
  actionCreator<RequestEntityGroupChangePayload>(
    '[useCaseEditor] REQUEST_ENTITY_GROUP_CHANGE'
  );
