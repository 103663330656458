import { StepState } from '@ardoq/steppers';
import { TabularMapping } from '../streams/tabularMappings/types';
import { Maybe } from '@ardoq/common-helpers';
import { isValidTabularMapping } from '../streams/tabularMappings/utils';
import { DryRunStatus, TransferState } from '../streams/transferState/types';
import { successStateToDryRunStatus } from '../streams/transferState/utils';
import { IntegrationSchedule } from '@ardoq/api-types';
import { ScheduleState } from 'integrations/common/streams/schedule/schedule$';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';

export const getConfigureStepState = ({
  tabularMapping,
}: {
  integrationPath: Maybe<string>;
  configureRoute: Maybe<string>;
  tabularMapping: TabularMapping;
}): StepState => {
  if (isValidTabularMapping(tabularMapping)) {
    return StepState.DONE;
  }

  return StepState.ACTIVE;
};

export const getReviewStepState = ({
  transferState,
}: {
  transferState: TransferState;
}): StepState => {
  const { requestStatus, dryRun } = transferState;

  if (!dryRun && requestStatus === 'INIT') {
    return StepState.DONE;
  }

  if (requestStatus === 'FAILURE') {
    return StepState.ERROR;
  }

  if (requestStatus === 'SUCCESS') {
    const status = successStateToDryRunStatus(transferState);
    return status === DryRunStatus.ERROR ? StepState.ERROR : StepState.DONE;
  }

  return StepState.ACTIVE;
};

export const getReviewStepPopoverText = (state: StepState) =>
  state === StepState.ERROR
    ? 'Fix the errors in your configuration, then test and review it to continue.'
    : state === StepState.DONE
      ? 'Please trigger the test again to review the up to date results.'
      : null;

export const getScheduleStepState = ({
  transferState: { requestStatus, dryRun },
  schedule: { scheduleStage },
}: {
  transferState: TransferState;
  schedule: ScheduleState;
}): StepState => {
  if (scheduleStage === 'scheduled') {
    return StepState.DONE;
  }
  if (
    !dryRun &&
    requestStatus === 'SUCCESS' &&
    scheduleStage === 'noScheduleAllowed'
  ) {
    return StepState.DONE;
  }
  if (!dryRun && requestStatus === 'FAILURE') {
    return StepState.ERROR;
  }
  return StepState.ACTIVE;
};

export const getFlowTitle = ({
  selectedTransferConfigId,
  transferConfigs,
  prefix,
  loadedScheduleId,
  schedules,
}: {
  selectedTransferConfigId: Maybe<string>;
  transferConfigs: SavedTransferConfig[];
  prefix: string;
  loadedScheduleId: ScheduleState['loadedScheduleId'];
  schedules: IntegrationSchedule[];
}) => {
  const loadedScheduleName = schedules.find(
    ({ _id }) => _id === loadedScheduleId
  )?.jobOptions.name;

  if (loadedScheduleName) {
    return `${prefix} - ${loadedScheduleName}`;
  }

  const transferConfigName = transferConfigs.find(
    ({ _id }) => _id === selectedTransferConfigId
  )?.name;

  if (transferConfigName) {
    return `${prefix} - ${transferConfigName}`;
  }

  return `${prefix} - untitled`;
};

export function getScheduleStepTitle(
  stepStage: ScheduleState['scheduleStage']
) {
  if (stepStage === 'noScheduleAllowed') return 'Import';
  return 'Import & Schedule';
}
