import { Features, hasFeature } from '@ardoq/features';
import { scopeDiff$ } from 'scope/scopeDiff$';
import resourcePermissions$ from 'resourcePermissions/resourcePermissions$';
import expandedFolders$ from 'appContainer/DashboardContainer/expandedFolders$';
import searchResultApi$ from 'components/SearchResultTable/searchResultApi$';
import featureHighlight$ from 'featureHighlight/featureHighlight$';
import metamodelNavigation$ from 'metamodel/navigation/metamodelNavigation$';
import { advancedSearchState$ } from 'search/AdvancedSearch/advancedSearch$';
import searchTab$ from 'search/SearchTabContainer/searchTab$';
import currentViewId$ from 'streams/currentViewId$';
import { Observable } from 'rxjs';

const subscribeToStateStreams = () => {
  const streams: Observable<unknown>[] = [
    advancedSearchState$,
    searchTab$,
    metamodelNavigation$,
    searchResultApi$,
    featureHighlight$,
    expandedFolders$,
    resourcePermissions$,
    currentViewId$,
    ...(hasFeature(Features.SCENARIOS_BETA) ? [scopeDiff$] : []),
  ];

  const subscriptions = streams.map(stream => stream.subscribe());

  return subscriptions;
};

export function startLegacyApplicationStateStreams() {
  const subscriptions = subscribeToStateStreams();

  if (module.hot) {
    module.hot.dispose(() => {
      subscriptions.forEach(subscription => subscription.unsubscribe());
    });
  }
}
