import { TransferConfig } from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import fp from 'lodash/fp';

export const addColumnIds = (config: TransferConfig) => {
  return fp.update(
    'tables',
    fp.map(
      fp.update(
        'mappedColumns',
        fp.map((columnMapping: ColumnMapping) => ({
          ...columnMapping,
          id: columnMapping.sourceFieldName,
        }))
      )
    ),
    config
  );
};
