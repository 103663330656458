import { map } from 'rxjs';
import { perspectivesEditor$ } from './perspectivesEditor$/perspectivesEditor$';
import { Features, hasFeature } from '@ardoq/features';
import { getAvailableGroupOptionsWorkspaces } from './utils';
import { getSidebarGroupingRuleNotificationParamsFunction } from '@ardoq/perspectives-sidebar';
import { type PerspectiveGroupingRule } from '@ardoq/perspectives';
import { getInvalidGroupingRuleSelectOptionsByRuleId } from './getInvalidGroupingRuleSelectOptionsByRuleId';

import { dispatchAction } from '@ardoq/rxbeach';
import {
  reorderGroupingRules,
  updateGroupingRule,
  deleteGroupingRule,
  createGroupingRule,
} from './perspectivesEditor$/actions';

const onGroupingRuleReordered = (
  reorderedGroupingRules: PerspectiveGroupingRule[]
) => dispatchAction(reorderGroupingRules(reorderedGroupingRules));

const onGroupingRuleDeleted = (deletedGroupingRuleId: string) =>
  dispatchAction(deleteGroupingRule(deletedGroupingRuleId));

const onGroupingRuleUpdated = (
  updatedGroupingRuleId: string,
  updatedGroupingRule: Partial<PerspectiveGroupingRule>
) =>
  dispatchAction(
    updateGroupingRule({ updatedGroupingRuleId, updatedGroupingRule })
  );

const onGroupingRuleAdded = (newGroupingRule: PerspectiveGroupingRule) =>
  dispatchAction(createGroupingRule(newGroupingRule));

const groupsTab$ = perspectivesEditor$.pipe(
  map(perspectivesEditorState => {
    const {
      filtersTabPlainDataParams: { workspaceFilterIds },
      groupsTab: { groupOptions, groupingRules, rulesIdsWithWarnings },
    } = perspectivesEditorState;

    const groupingOptions = {
      ...groupOptions,
      shouldShowSubdivisionsOption: hasFeature(Features.PERMISSION_ZONES),
      workspaces: getAvailableGroupOptionsWorkspaces(
        groupOptions.workspaces,
        workspaceFilterIds
      ),
    };

    const getRuleNotificationParams = rulesIdsWithWarnings.length
      ? getSidebarGroupingRuleNotificationParamsFunction(rulesIdsWithWarnings)
      : undefined;

    const invalidGroupingSelectOptionsDataByRuleId =
      getInvalidGroupingRuleSelectOptionsByRuleId(groupingRules, groupOptions);

    const getInvalidGroupingSelectOptions = (rule: PerspectiveGroupingRule) =>
      invalidGroupingSelectOptionsDataByRuleId[rule.id] || {};

    const description =
      'Show the hierarchy and relationship between components.';
    const tabDescription = {
      description,
    };

    return {
      groupingRules,
      onGroupingRuleAdded,
      onGroupingRuleDeleted,
      onGroupingRuleReordered,
      onGroupingRuleUpdated,
      groupingOptions,
      getRuleNotificationParams,
      getInvalidGroupingSelectOptions,
      tabDescription,
    };
  })
);
export default groupsTab$;
