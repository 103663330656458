import { useState } from 'react';
import { DatasourceTable, FlyWheelTable } from '@ardoq/table';
import invitations$ from 'streams/invitations/invitations$';
import { connect } from '@ardoq/rxbeach';
import { InvitationActions } from './atoms';
import { getRenderedDatasourceColumnById } from './utils';
import { InvitationsStreamShape } from 'streams/invitations/types';
import { PaginationBar, PaginationController } from '@ardoq/pagination';
import { s64 } from '@ardoq/design-tokens';
import { CollapsibleIsland } from '@ardoq/page-layout';

const PER_PAGE = 5;
const actionsColumn = getRenderedDatasourceColumnById({
  title: '',
  headerStyle: {
    width: s64,
  },
  valueRender: invitationId => (
    <InvitationActions invitationId={invitationId} />
  ),
});

const PendingInvitationsTable = ({
  invitations,
}: Pick<InvitationsStreamShape, 'invitations'>) => {
  const [perPage, setPerPage] = useState(PER_PAGE);

  return (
    <PaginationController
      dataSource={invitations}
      perPage={perPage}
      render={({
        currentPageDataSource,
        currentPageNumber,
        perPage,
        totalResults,
        onPageSelect,
      }) => {
        return (
          <>
            <DatasourceTable
              components={FlyWheelTable}
              dataSource={currentPageDataSource}
              style={{ overflowY: 'visible' }}
              columns={[
                {
                  title: 'Email',
                  dataIndex: 'email',
                  headerStyle: {
                    width: `33%`,
                  },
                },
                {
                  title: 'Role',
                  dataIndex: 'role',
                  headerStyle: {
                    width: `33%`,
                  },
                  cellStyle: {
                    textTransform: 'capitalize',
                  },
                },
                {
                  title: 'Invited by',
                  dataIndex: 'createdByName',
                  headerStyle: {
                    width: `33%`,
                  },
                },
                actionsColumn,
              ]}
            />
            {
              <PaginationBar
                currentPageNumber={currentPageNumber}
                perPage={perPage}
                showPerPageSelector={true}
                totalResults={totalResults}
                onChange={({ currentPageNumber, perPage }) => {
                  setPerPage(perPage);
                  onPageSelect(currentPageNumber);
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
              />
            }
          </>
        );
      }}
    ></PaginationController>
  );
};

const PendingInvitations = ({ invitations }: InvitationsStreamShape) => {
  if (!invitations.length) return null;
  return (
    <CollapsibleIsland title="Pending invitations" isDefaultExpanded>
      <PendingInvitationsTable invitations={invitations} />
    </CollapsibleIsland>
  );
};

export default connect(PendingInvitations, invitations$);
