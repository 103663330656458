import { ButtonSize, PrimaryButton, SecondaryButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { connect } from '@ardoq/rxbeach';
import { FooterWrapper } from 'admin/manageOrganization/atoms';
import { useEffectOnce } from '@ardoq/hooks';
import { InfoNotification } from '@ardoq/status-ui';
import {
  DoqLayout,
  DoqLoadingAnimation,
  DoqType,
  DoqWithSpeechBubble,
} from '@ardoq/doq';
import { Link } from '@ardoq/typography';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { s16 } from '@ardoq/design-tokens';
import { STRINGS } from './consts';
import MappingRulesTable from './MappingRulesTable';
import { SSOAttributeMappingProps } from './types';
import viewModel$ from './viewModel$';
import Timestamp from './Timestamp';
import {
  DoqContainer,
  DoqMessageContainer,
  SelectProviderContainer,
} from './atoms';
import { Select } from '@ardoq/select';
import MappingPreviewTable from './MappingPreviewTable';
import { Island } from '@ardoq/page-layout';
import { Inline } from '@ardoq/layout';
import { Features, hasFeature } from '@ardoq/features';

const DoqMessage = (message: string, link: string) => (
  <DoqMessageContainer>
    <div>{message}</div>
    <Link href={KnowledgeBaseLink.SSO_MAPPING}>{link}</Link>
  </DoqMessageContainer>
);

const SSOAttributeMapping = ({
  changes,
  isValidChanges,
  disableSave,
  lastUpdated,
  ssoOptions,
  numberOfConfiguredSso,
  allowMultipleSsoOptionsInMappings,
  noGroupsConfigured,
  mappingPreview,
  currentMapping,
  deletions,
  isLoggedInWithSSO,
  enablePreviewButton,
  isLoading,
  ssoSelectedForPreview,
  commands,
}: SSOAttributeMappingProps) => {
  useEffectOnce(commands.loadMappings);

  commands.setUnsaved(changes);

  // HACK - Rafaa - Temporary until we can Merge the new Navigation pattern.
  const header = hasFeature(Features.PERMISSION_ZONES_INTERNAL)
    ? {}
    : {
        title: STRINGS.HEADER.TITLE,
        subtitle: STRINGS.HEADER.SUBTITLE,
      };
  if (isLoading) {
    return (
      <Island>
        <DoqContainer>
          <DoqLoadingAnimation doqSize={240} doqs={[1, 2, 3, 12]} />
        </DoqContainer>
      </Island>
    );
  }

  if (
    numberOfConfiguredSso === 0 ||
    (numberOfConfiguredSso > 1 && !allowMultipleSsoOptionsInMappings)
  ) {
    const { TITLE, MESSAGE, LINK } =
      numberOfConfiguredSso === 0 ? STRINGS.NO_SSO : STRINGS.NO_MULTIPLE_SSO;
    const doqType =
      numberOfConfiguredSso === 0 ? DoqType.LOCKED : DoqType.ERROR;
    return (
      <Island {...header}>
        <DoqContainer>
          <DoqWithSpeechBubble
            doqType={doqType}
            title={TITLE}
            message={DoqMessage(MESSAGE, LINK)}
            layout={DoqLayout.DIALOG}
          />
        </DoqContainer>
      </Island>
    );
  }

  if (!isLoggedInWithSSO && Object.keys(currentMapping).length === 0)
    return (
      <Island {...header}>
        <InfoNotification style={{ marginBottom: s16 }}>
          {STRINGS.SIGNIN_WITH_SSO}
        </InfoNotification>
        <Inline>
          <SecondaryButton
            dataTestId="add-mapping-button"
            buttonSize={ButtonSize.REGULAR}
            isDisabled={true}
          >
            <Icon iconName={IconName.ADD} />
            {STRINGS.BUTTON.ADD_MAPPING}
          </SecondaryButton>
        </Inline>
      </Island>
    );

  if (noGroupsConfigured)
    return (
      <Island {...header}>
        <InfoNotification style={{ marginBottom: s16 }}>
          {STRINGS.NO_GROUPS_CONFIGURED}
        </InfoNotification>
        <Inline>
          <SecondaryButton
            dataTestId="add-mapping-button"
            buttonSize={ButtonSize.REGULAR}
            isDisabled={true}
          >
            <Icon iconName={IconName.ADD} />
            {STRINGS.BUTTON.ADD_MAPPING}
          </SecondaryButton>
        </Inline>
      </Island>
    );

  return (
    <Island
      {...header}
      footerContent={
        <FooterWrapper>
          <PrimaryButton
            isDisabled={disableSave}
            onClick={() =>
              commands.saveMappings(isValidChanges, changes, deletions)
            }
          >
            {STRINGS.BUTTON.SAVE}
          </PrimaryButton>
        </FooterWrapper>
      }
    >
      {lastUpdated && <Timestamp value={lastUpdated} />}
      <MappingRulesTable />
      <Inline gap="medium">
        <SecondaryButton
          dataTestId="add-mapping-button"
          buttonSize={ButtonSize.REGULAR}
          onClick={commands.addNewEmptyMapping}
        >
          <Icon iconName={IconName.ADD} />
          {STRINGS.BUTTON.ADD_MAPPING}
        </SecondaryButton>
        <SecondaryButton
          isDisabled={!enablePreviewButton}
          onClick={() =>
            commands.getPreview(
              isValidChanges,
              currentMapping,
              allowMultipleSsoOptionsInMappings,
              ssoSelectedForPreview
            )
          }
        >
          <Icon iconName={IconName.VISIBILITY} />
          {STRINGS.BUTTON.PREVIEW}
        </SecondaryButton>
        {allowMultipleSsoOptionsInMappings && isLoggedInWithSSO && (
          <SelectProviderContainer>
            <Select
              placeholder={STRINGS.SELECT_PROVIDER.PLACEHOLDER}
              options={ssoOptions}
              value={ssoSelectedForPreview}
              onChange={commands.selectSsoForPreview}
            />
          </SelectProviderContainer>
        )}
      </Inline>
      <MappingPreviewTable
        isLoggedInWithSSO={isLoggedInWithSSO}
        mappingPreview={mappingPreview}
      />
    </Island>
  );
};

export default connect(SSOAttributeMapping, viewModel$);
