import {
  addRequiredComponent,
  graphNodeHovered,
  removeRequiredComponent,
} from './requiredComponentsActions';
import { requiredComponentsOperations } from './requiredComponentsOperations';
import { reducer } from '@ardoq/rxbeach';

export const requiredComponentsReducers = [
  reducer(
    addRequiredComponent,
    requiredComponentsOperations.addRequiredComponent
  ),
  reducer(
    removeRequiredComponent,
    requiredComponentsOperations.removeRequiredComponent
  ),
  reducer(graphNodeHovered, requiredComponentsOperations.onGraphNodeHover),
];
