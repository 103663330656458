import { ChangeRequestAction } from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';
import { SidebarMenuMenu, SidebarMenuSection } from '@ardoq/sidebar-menu';
import { Space } from '@ardoq/style-helpers';
import styled from 'styled-components';
import { StatusType, Tag } from '@ardoq/status-ui';
import { map } from 'rxjs';
import changeApprovalData$ from '../changeApprovalData$';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { setApprovalFocus, setChangeRequestAction } from '../actions';
import { ApprovalFocus } from '../types';
import { trackEvent } from 'tracking/tracking';

const StyledSpace = styled(Space)`
  width: 100%;
`;

type ReferencesSidebarSectionProps = {
  numberOfReferencesUpdated: number;
  numberOfReferencesCreated: number;
  numberOfReferencesDeleted: number;
  changeAction: ChangeRequestAction;
  approvalFocus: ApprovalFocus;
};

const ReferencesSidebarSection = ({
  numberOfReferencesUpdated,
  numberOfReferencesCreated,
  numberOfReferencesDeleted,
  changeAction,
  approvalFocus,
}: ReferencesSidebarSectionProps) => {
  const handleSetApprovalFocusAndAction = (
    changeAction: ChangeRequestAction
  ) => {
    trackEvent('Survey change approval: clicked on reference sidebar', {
      changeAction,
    });
    dispatchAction(setApprovalFocus('reference'));
    dispatchAction(setChangeRequestAction(changeAction));
  };

  return (
    <SidebarMenuSection
      leftIcon={{ name: IconName.REFERENCE }}
      title="References"
      titleDataTestId="references-section"
      isCollapsible={false}
    >
      <SidebarMenuMenu
        options={[
          numberOfReferencesUpdated
            ? {
                label: (
                  <StyledSpace $justify="space-between" $align="center">
                    Updated
                    <Tag
                      label={`${numberOfReferencesUpdated}`}
                      statusType={StatusType.INFO}
                    />
                  </StyledSpace>
                ),
                onClick: () => handleSetApprovalFocusAndAction('update'),
                isSelected:
                  approvalFocus === 'reference' && changeAction === 'update',
              }
            : null,
          numberOfReferencesCreated
            ? {
                label: (
                  <StyledSpace $justify="space-between" $align="center">
                    Created
                    <Tag
                      label={`${numberOfReferencesCreated}`}
                      statusType={StatusType.SUCCESS}
                    />
                  </StyledSpace>
                ),
                onClick: () => handleSetApprovalFocusAndAction('create'),
                isSelected:
                  approvalFocus === 'reference' && changeAction === 'create',
              }
            : null,
          numberOfReferencesDeleted
            ? {
                label: (
                  <StyledSpace $justify="space-between" $align="center">
                    Deleted
                    <Tag
                      label={`${numberOfReferencesDeleted}`}
                      statusType={StatusType.ERROR}
                    />
                  </StyledSpace>
                ),
                onClick: () => handleSetApprovalFocusAndAction('delete'),
                isSelected:
                  approvalFocus === 'reference' && changeAction === 'delete',
              }
            : null,
        ].filter(ExcludeFalsy)}
      />
    </SidebarMenuSection>
  );
};

const combinedData$ = changeApprovalData$.pipe(
  map(
    ({
      changedReferencesByComponentId,
      componentId,
      changeAction,
      approvalFocus,
    }) => {
      return {
        componentId,
        changedReferencesByComponentId,
        numberOfReferencesUpdated:
          changedReferencesByComponentId[componentId].update?.length,
        numberOfReferencesCreated:
          changedReferencesByComponentId[componentId].create?.length,
        numberOfReferencesDeleted:
          changedReferencesByComponentId[componentId].delete?.length,
        changeAction,
        approvalFocus,
      };
    }
  )
);

export default connect(ReferencesSidebarSection, combinedData$);
