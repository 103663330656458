import { ComponentType } from 'react';
import styled from 'styled-components';
import ComponentSubsetSelector from '../ComponentSubsetSelector';
import NewComponentParentSelector from 'surveyAdmin/NewComponentParentSelector';
import { getReferenceData } from '../referenceUtil';
import {
  SurveyBuilderLocation,
  SurveyQuestionError,
  SurveyQuestionValidations,
  SurveyQuestionWarning,
} from '../types';
import { QuestionBuilderProps } from './types';
import {
  ArdoqId,
  BooleanOperator,
  PermissionAccessLevel,
  SurveyQuestionValidator,
  QueryBuilderSubquery,
  PersistedReferenceQuestion,
  UnpersistedReferenceQuestion,
  PersistedReferenceSubQuestion,
  UnpersistedReferenceSubQuestion,
  PartiallyPersistedReferenceQuestion,
  isEntity,
} from '@ardoq/api-types';
import { Checkbox, FieldsWrapper, Label, TextInput } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import { Header4, Text } from '@ardoq/typography';
import { RangeValidation } from './RangeValidation';
import { trackEvent } from 'tracking/tracking';
import {
  ProtectedSelectionPlaceholder,
  getMaybeProtectSelectedOption,
} from 'surveyAdmin/maybeProtectSelectedOption';
import {
  YOU_DO_NOT_HAVE_EDIT_ACCESS_TO_WORKSPACE,
  YOU_DO_NOT_HAVE_READ_ACCESS_TO_WORKSPACE,
} from 'surveyAdmin/SurveyEditor/consts';
import {
  currentUserHasNoAccessToWorkspace,
  currentUserHasNoEditAccessToWorkspace,
} from 'surveyAdmin/SurveyEditor/utils';
import { StyledSpace } from './questionAtoms';
import { PermissionContext } from '@ardoq/access-control';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { Box } from '@ardoq/layout';
import { ERROR_MESSAGES } from 'surveyAdmin/consts';
import { isFieldQuestion } from './questionPredicates';
import {
  isReadonlyField,
  isRequiredValidator,
  isRangeValidator,
  getQuestionId,
  questionValidationsHasMessage,
  getQuestionValidationMessageIfExists,
} from './utils';
import { RichTextEditorTransition } from '@ardoq/rich-text-editor-2024';
import { shouldDisplayErrorMessageForField } from 'surveyAdmin/surveyUtil';
import surveyEditor$ from 'surveyAdmin/SurveyEditor/streams/surveyEditor$';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { setSurveyQuestionFieldHasBeenInteractedWith } from 'surveyAdmin/SurveyEditor/streams/actions';
import { SurveyEditorState } from 'surveyAdmin/SurveyEditor/streams/types';

const TextWrapper = styled(Box)`
  max-width: 520px;
`;

type ReferenceQuestionMode = 'readonly' | 'editonly' | 'default';

const getSurveyModeOptions = (isDependentQuestion?: boolean) =>
  [
    {
      label: 'Create, update and delete',
      value: 'default' as const,
      description: 'Respondents can create, update and delete references.',
    },
    {
      label: 'Read-only',
      value: 'readonly' as const,
      description:
        'Respondents can view this information but not change it. Setting this question to read-only will also set all sub-questions of this question to read-only.',
    },
    !isDependentQuestion && {
      label: 'Field edit only',
      value: 'editonly' as const,
      description:
        'Respondents will only be able to edit the field data of existing references and not add or remove references.',
    },
  ].filter(ExcludeFalsy);

type ReferenceQuestionTypes =
  | PersistedReferenceQuestion
  | UnpersistedReferenceQuestion
  | PartiallyPersistedReferenceQuestion;

const withSubQuestionsReadonlyStatusSet = (
  question: ReferenceQuestionTypes,
  desiredReadonlyStatus: boolean
):
  | (PersistedReferenceSubQuestion | UnpersistedReferenceSubQuestion)[]
  | undefined => {
  const { properties } = question;
  const workspaceId = properties?.workspaceId;
  return properties.questions?.map(subQuestion => {
    const isSubquestionReadonly = isFieldQuestion(subQuestion)
      ? isReadonlyField(subQuestion.properties.fieldName, workspaceId ?? '')
      : desiredReadonlyStatus;
    return {
      ...subQuestion,
      readonly: isSubquestionReadonly ?? desiredReadonlyStatus,
    };
  });
};

const updateQuestionToBeReadonly = (
  question: ReferenceQuestionTypes
): ReferenceQuestionTypes => {
  const questions = withSubQuestionsReadonlyStatusSet(question, true);
  return {
    ...question,
    readonly: true,
    validators: question.validators?.filter(v => !isRequiredValidator(v)),
    properties: {
      ...question.properties,
      questions,
      preventReferenceModification: false,
    },
  } as ReferenceQuestionTypes;
};

export type ReferenceQuestionProperties = {
  surveyComponentTypeId?: string;
  surveyWorkspaceId: ArdoqId | null;
  question: ReferenceQuestionTypes;
  questionValidations?: SurveyQuestionValidations;
  QuestionBuilder?: ComponentType<QuestionBuilderProps>;
  isDependentQuestion?: boolean;
  permissionsContext: PermissionContext;
  parentQuestionIsReadonly?: boolean;
  surveyIsPermissionDivisionsAware: boolean;
  hasSurveyResponseApprovalsFeature: boolean;
  updateQuestion: (question: ReferenceQuestionTypes) => void;
  fieldHasBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith'];
};

const ReferenceQuestion = function ({
  surveyWorkspaceId,
  surveyComponentTypeId,
  question,
  questionValidations,
  QuestionBuilder,
  isDependentQuestion,
  permissionsContext,
  parentQuestionIsReadonly,
  surveyIsPermissionDivisionsAware,
  hasSurveyResponseApprovalsFeature,
  updateQuestion,
  fieldHasBeenInteractedWith,
}: ReferenceQuestionProperties) {
  const {
    referenceWorkspace,
    referenceTypeOptions,
    referenceTypeId,
    referenceTypeName,
    sourceTypeName,
    targetTypeName,
  } = getReferenceData({
    surveyWorkspaceId,
    surveyComponentTypeId,
    outgoing: question.properties.outgoing,
    workspaceId: question.properties.workspaceId,
    componentTypeName: question.properties.componentTypeName,
    referenceTypeName: question.properties.referenceTypeName ?? undefined,
  });

  const hasSelectedCompType =
    !!question.properties.workspaceId &&
    !!question.properties.componentTypeName;
  const referenceDirectionOptions = [
    { label: 'Incoming', value: false },
    { label: 'Outgoing', value: true },
  ];

  const advancedSearchLimiter = question.properties.advancedSearchLimiter || {
    condition: BooleanOperator.AND,
    rules: [],
  };
  const showAdvancedOptions = Boolean(
    question.properties.advancedSearchLimiter?.rules
  );

  const workspaceId = question.properties.workspaceId;

  const selectedReferenceTypeValidationErrorMessage =
    questionValidations?.questionValidations.find(
      ({ message }) =>
        message ===
          SurveyQuestionError.SELECTED_REFERENCE_TYPE_DOES_NOT_EXIST ||
        message === SurveyQuestionError.REFERENCE_TYPE_MISSING
    )?.message;

  const selectedReferenceTypeValidationWarningMessage =
    questionValidations?.questionValidations.find(
      ({ message }) =>
        message ===
          SurveyQuestionWarning.NO_READ_ACCESS_TO_SELECTED_REFERENCE_TYPE ||
        message ===
          SurveyQuestionWarning.NO_EDIT_ACCESS_TO_SELECTED_REFERENCE_TYPE
    )?.message;

  const maybeProtectSelectedOption = getMaybeProtectSelectedOption(
    question.properties.outgoing ? surveyWorkspaceId : workspaceId
  );

  const hasNoAccessToReferenceWorkspace = currentUserHasNoAccessToWorkspace(
    permissionsContext,
    workspaceId
  );

  const hasNoEditAccesstoReferenceWorkspace =
    currentUserHasNoEditAccessToWorkspace(permissionsContext, workspaceId);

  const canAddAndRemoveReferences =
    !question.properties.preventReferenceModification;

  const permittedActionsText = canAddAndRemoveReferences
    ? 'create, update or delete'
    : 'update';

  const handleSetReferenceQuestionMode = (
    mode: ReferenceQuestionMode | null
  ) => {
    if (mode === 'readonly') {
      const updatedQuestion = updateQuestionToBeReadonly(question);
      updateQuestion(updatedQuestion);
      return;
    }
    const subQuestions = withSubQuestionsReadonlyStatusSet(question, false);
    if (mode === 'editonly') {
      updateQuestion({
        ...question,
        validators: question.validators?.filter(
          (validator: SurveyQuestionValidator) => !isRangeValidator(validator)
        ),
        readonly: false,
        properties: {
          ...question.properties,
          preventReferenceModification: true,
          allowComponentCreation: false,
          questions: subQuestions,
        },
      } as ReferenceQuestionTypes);
      return;
    }
    updateQuestion({
      ...question,
      readonly: false,
      properties: {
        ...question.properties,
        preventReferenceModification: false,
        questions: subQuestions,
      },
    } as ReferenceQuestionTypes);
  };

  const questionHasBeenClosed =
    fieldHasBeenInteractedWith.questions[getQuestionId(question)]
      ?.questionViewHasBeenClosed;

  const shouldShowWorkspaceFieldErrorIfPresent =
    shouldDisplayErrorMessageForField(
      isEntity(question),
      null,
      fieldHasBeenInteractedWith.questions[getQuestionId(question)]
        ?.questionsSectionReferenceQuestionWorkspaceSelectField ||
        questionHasBeenClosed
    );

  const shouldShowComponentTypeErrorIfPresent =
    shouldDisplayErrorMessageForField(
      isEntity(question),
      null,
      fieldHasBeenInteractedWith.questions[getQuestionId(question)]
        ?.questionsSectionReferenceQuestionComponentTypeSelectField ||
        questionHasBeenClosed
    );

  const shouldShowReferenceTypeValidationIfPresent =
    shouldDisplayErrorMessageForField(
      isEntity(question),
      null,
      fieldHasBeenInteractedWith.questions[getQuestionId(question)]
        ?.questionsSectionReferenceQuestionReferenceTypeSelectField ||
        questionHasBeenClosed
    );

  return (
    <>
      <FieldsWrapper>
        <Select
          label="Set question restrictions"
          popoverHelpContent='Select how you want your respondents to interact with this question. "Create, update and delete" will allow respondents to create, update and delete references. "Read-only" will allow respondents to view the references but not change them. "Field edit only" will allow respondents to edit the field data of existing references but not add or remove references.'
          options={getSurveyModeOptions(isDependentQuestion)}
          isClearable={false}
          value={
            question.properties.preventReferenceModification
              ? 'editonly'
              : parentQuestionIsReadonly || question.readonly
                ? 'readonly'
                : 'default'
          }
          onValueChange={handleSetReferenceQuestionMode}
          errorMessage={getQuestionValidationMessageIfExists(
            questionValidations,
            SurveyQuestionError.PREVENTING_REFERENCE_MODIFICATION_REQUIRES_SUBQUESTIONS
          )}
          dataTestId="reference-question-mode-select"
          isDisabled={parentQuestionIsReadonly}
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <TextInput
          label="Question title"
          value={question.label}
          onChange={event =>
            updateQuestion({ ...question, label: event.target.value })
          }
          dataTestId="question-title-input"
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <StyledSpace $isVertical $gap="s4">
          <Label>Question help text</Label>
          <RichTextEditorTransition
            content={question.description}
            onInputChange={description =>
              updateQuestion({ ...question, description })
            }
            dataTestId="question-help-input"
          />
        </StyledSpace>
      </FieldsWrapper>
      <TextWrapper>
        <Header4>Components to be referenced:</Header4>
        <Text variant="caption" color="textSubtle">
          Select which components the respondent should be able to reference to
          or from. You can decide direction below.
        </Text>
      </TextWrapper>
      <FieldsWrapper>
        <Select
          label="Reference direction"
          options={referenceDirectionOptions}
          isClearable={false}
          value={
            referenceDirectionOptions.find(
              ({ value }) => value === question.properties.outgoing
            ) || false
          }
          onValueChange={value =>
            updateQuestion({
              ...question,
              properties: {
                ...question.properties,
                outgoing: Boolean(value),
                workspaceId: undefined,
                newComponentParentId: undefined,
              },
            } as ReferenceQuestionTypes)
          }
          popoverHelpContent={`Select the direction of the reference, remember that this is from
              the context of the survey's starting component. This will also
              impact what type of references are available below.`}
          dataTestId="reference-direction-select"
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <ComponentSubsetSelector
          accessLevel={PermissionAccessLevel.EDIT}
          componentTypeName={question.properties.componentTypeName}
          updateComponentTypeName={componentTypeName =>
            updateQuestion({
              ...question,
              properties: {
                ...question.properties,
                componentTypeName,
                newComponentParentId: undefined,
              },
            } as ReferenceQuestionTypes)
          }
          workspace={workspaceId}
          surveyWorkspaceId={surveyWorkspaceId}
          updateWorkspace={workspaceId =>
            updateQuestion({
              ...question,
              properties: {
                ...question.properties,
                workspaceId,
                newComponentParentId: undefined,
              },
            } as ReferenceQuestionTypes)
          }
          advancedSearchRules={advancedSearchLimiter as QueryBuilderSubquery}
          advancedSearchCondition={advancedSearchLimiter.condition}
          updateParentState={({
            advancedSearchRules,
            advancedSearchCondition,
          }) => {
            if (advancedSearchRules) {
              updateQuestion({
                ...question,
                properties: {
                  ...question.properties,
                  advancedSearchLimiter: advancedSearchRules,
                },
              } as ReferenceQuestionTypes);
            }
            if (advancedSearchCondition) {
              updateQuestion({
                ...question,
                properties: {
                  ...question.properties,
                  advancedSearchLimiter: {
                    ...advancedSearchLimiter,
                    condition: advancedSearchCondition,
                  },
                },
              } as ReferenceQuestionTypes);
            }
          }}
          showAdvancedOptions={showAdvancedOptions}
          toggleAdvancedOptions={() => {
            updateQuestion({
              ...question,
              properties: {
                ...question.properties,
                advancedSearchLimiter: showAdvancedOptions
                  ? undefined
                  : { ...advancedSearchLimiter, rules: [] },
              },
            } as ReferenceQuestionTypes);
          }}
          workspaceDoesNotExistErrorMessage={
            shouldShowWorkspaceFieldErrorIfPresent
              ? questionValidationsHasMessage(
                  questionValidations,
                  SurveyQuestionError.WORKSPACE_MISSING
                )
                ? 'The workspace that was selected no longer exists.'
                : undefined
              : undefined
          }
          workspaceAccessWarningMessage={
            shouldShowWorkspaceFieldErrorIfPresent
              ? workspaceId && hasNoAccessToReferenceWorkspace
                ? YOU_DO_NOT_HAVE_READ_ACCESS_TO_WORKSPACE
                : hasNoEditAccesstoReferenceWorkspace
                  ? YOU_DO_NOT_HAVE_EDIT_ACCESS_TO_WORKSPACE
                  : undefined
              : undefined
          }
          workspaceRequiredErrorMessage={
            shouldShowWorkspaceFieldErrorIfPresent
              ? 'This field is required.'
              : undefined
          }
          compTypeMissingErrorMessage={
            shouldShowComponentTypeErrorIfPresent
              ? questionValidationsHasMessage(
                  questionValidations,
                  SurveyQuestionError.SELECTED_COMPONENT_TYPE_DOES_NOT_EXIST
                )
                ? ERROR_MESSAGES.COMPONENT_TYPE_MISSING_OR_INVALID
                : undefined
              : undefined
          }
          componentTypeRequiredErrorMessage={
            shouldShowComponentTypeErrorIfPresent
              ? 'This field is required.'
              : undefined
          }
          workspaceSelectHint="Required. You can only select workspaces which you have Edit permissions on."
          componentTypeHint="Required"
          onWorkspaceSelectBlur={() =>
            dispatchAction(
              setSurveyQuestionFieldHasBeenInteractedWith({
                questionKey: getQuestionId(question),
                fieldKey:
                  'questionsSectionReferenceQuestionWorkspaceSelectField',
              })
            )
          }
          onComponentTypeSelectBlur={() =>
            dispatchAction(
              setSurveyQuestionFieldHasBeenInteractedWith({
                questionKey: getQuestionId(question),
                fieldKey:
                  'questionsSectionReferenceQuestionComponentTypeSelectField',
              })
            )
          }
          showAdditionalIdentifiers={true}
          additionalIdentifier={question.properties.additionalIdentifier}
          setAdditionalIdentifier={additionalIdentifier =>
            updateQuestion({
              ...question,
              properties: {
                ...question.properties,
                additionalIdentifier: additionalIdentifier ?? undefined,
              },
            } as ReferenceQuestionTypes)
          }
          surveyBuilderLocation={SurveyBuilderLocation.REFERENCE_QUESTION}
          identifyingFieldError={
            questionValidationsHasMessage(
              questionValidations,
              SurveyQuestionError.IDENTIFYING_FIELD_MISSING_OR_INVALID
            )
              ? ERROR_MESSAGES.IDENTIFYING_FIELD_MISSING_OR_INVALID
              : undefined
          }
        />
      </FieldsWrapper>
      {hasSelectedCompType && (
        <FieldsWrapper>
          <Select
            onBlur={() =>
              dispatchAction(
                setSurveyQuestionFieldHasBeenInteractedWith({
                  questionKey: getQuestionId(question),
                  fieldKey:
                    'questionsSectionReferenceQuestionReferenceTypeSelectField',
                })
              )
            }
            hintMessage="Required"
            label="Select reference type"
            options={referenceTypeOptions}
            isClearable={false}
            value={maybeProtectSelectedOption(
              referenceTypeOptions,
              question.properties.referenceTypeName,
              ProtectedSelectionPlaceholder.HIDDEN_REFERENCE_TYPE
            )}
            isDisabled={!question.properties.workspaceId}
            onValueChange={value =>
              updateQuestion({
                ...question,
                properties: {
                  ...question.properties,
                  referenceTypeName: value,
                },
              } as ReferenceQuestionTypes)
            }
            warningMessage={
              shouldShowReferenceTypeValidationIfPresent
                ? selectedReferenceTypeValidationWarningMessage
                : undefined
            }
            errorMessage={
              shouldShowReferenceTypeValidationIfPresent
                ? selectedReferenceTypeValidationErrorMessage
                : undefined
            }
            dataTestId="reference-type-select"
          />
        </FieldsWrapper>
      )}
      {!question.readonly &&
        canAddAndRemoveReferences &&
        hasSelectedCompType && (
          <NewComponentParentSelector
            allowComponentCreation={question.properties.allowComponentCreation}
            updateAllowComponentCreation={allowComponentCreation =>
              updateQuestion({
                ...question,
                properties: { ...question.properties, allowComponentCreation },
              } as ReferenceQuestionTypes)
            }
            newComponentParentId={question.properties.newComponentParentId}
            updateNewComponentParentId={newComponentParentId =>
              updateQuestion({
                ...question,
                properties: { ...question.properties, newComponentParentId },
              } as ReferenceQuestionTypes)
            }
            componentTypeName={question.properties.componentTypeName}
            workspaceId={question.properties.workspaceId}
            description={
              <p>
                If the respondent tries to create a reference
                {question.properties.outgoing ? ' to ' : ' from '}a non-existing
                component they will
                {question.properties.allowComponentCreation ? ' ' : ' not '}
                be allowed to create a new component.
              </p>
            }
            referenceParentIsMissing={questionValidationsHasMessage(
              questionValidations,
              SurveyQuestionError.REFERENCE_PARENT_MISSING
            )}
            selectedNewComponentParentDoesNotExist={questionValidationsHasMessage(
              questionValidations,
              SurveyQuestionError.REFERENCE_PARENT_INVALID
            )}
            inReferenceQuestion={true}
            surveyBuilderLocation={SurveyBuilderLocation.REFERENCE_QUESTION}
            isDisabled={surveyIsPermissionDivisionsAware}
          />
        )}
      {!question.readonly &&
        canAddAndRemoveReferences &&
        !isDependentQuestion && (
          <Checkbox
            label="Multiple references"
            isChecked={Boolean(question.properties.allowMultipleReferences)}
            onChange={value => {
              trackEvent('Survey editing', {
                allowMultipleReferences: value,
              });
              updateQuestion({
                ...question,
                properties: {
                  ...question.properties,
                  allowMultipleReferences: value,
                },
              } as ReferenceQuestionTypes);
            }}
            errorMessage={getQuestionValidationMessageIfExists(
              questionValidations,
              SurveyQuestionError.MULTIPLE_REFERENCES_REQUIRES_SUBQUESTIONS
            )}
          >
            Allow multiple references to the same component
          </Checkbox>
        )}
      {!question.readonly && referenceTypeName && sourceTypeName && (
        <TextWrapper>
          <Header4>
            References that respondents {permittedActionsText} will be from
            &apos;
            {sourceTypeName}&apos; to &apos;{targetTypeName}&apos; of the type
            &apos;{referenceTypeName}&apos;.
          </Header4>
        </TextWrapper>
      )}
      {surveyIsPermissionDivisionsAware && (
        <TextWrapper>
          <Header4>
            Respondents will be able to see components they have read access to
            within their assigned divisions.
          </Header4>
        </TextWrapper>
      )}
      {!question.readonly &&
        hasSelectedCompType &&
        canAddAndRemoveReferences && (
          <RangeValidation
            labelText="Number of references that can be created"
            question={question}
            questionValidations={questionValidations}
            updateValidators={validators =>
              updateQuestion({ ...question, validators })
            }
          />
        )}
      {QuestionBuilder &&
        !isDependentQuestion &&
        question.properties.referenceTypeName && (
          <QuestionBuilder
            questions={question.properties.questions}
            isComponent={false}
            typeId={String(referenceTypeId)}
            modelId={
              referenceWorkspace ? referenceWorkspace.getModel()!.id : undefined
            }
            workspaceId={surveyWorkspaceId}
            addQuestionLabel="Add section to reference question"
            addQuestionDescription={`Add a field question or a text section to the
                                   reference question. The information your respondents
                                   are inputting will then become a value in a field
                                   on the reference.`}
            parentQuestionIsReadonly={question.readonly}
            permissionsContext={permissionsContext}
            subQuestionValidations={questionValidations?.subQuestionValidations}
            surveyIsPermissionDivisionsAware={surveyIsPermissionDivisionsAware}
            hasSurveyResponseApprovalsFeature={
              hasSurveyResponseApprovalsFeature
            }
            updateQuestions={questions =>
              updateQuestion({
                ...question,
                properties: {
                  ...question.properties,
                  questions: questions as (
                    | PersistedReferenceSubQuestion
                    | UnpersistedReferenceSubQuestion
                  )[],
                },
              } as ReferenceQuestionTypes)
            }
          />
        )}
    </>
  );
};

export default connect(ReferenceQuestion, surveyEditor$);
