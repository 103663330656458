import { ErrorNotification, NotificationDetails } from '@ardoq/status-ui';

type SuggestionsErrorProps = {
  error: string;
};

export const SuggestionsError = ({ error }: SuggestionsErrorProps) => (
  <ErrorNotification>
    Unable to add new dataset with AI
    <NotificationDetails>{error}</NotificationDetails>
  </ErrorNotification>
);
