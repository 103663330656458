import { useEffect } from 'react';
import BroadcastAudienceDialogFooter from '../BroadcastAudienceDialogFooter';
import gremlinAudienceDialog$ from './gremlinAudienceDialog$';
import GremlinAudienceEditor from './GremlinAudienceEditor';
import TabbedGremlinAudienceTable from './tabbedGremlinAudienceTable/TabbedGremlinAudienceTable';
import GenericErrorContent from '../GenericErrorContent';
import GremlinAudienceSearchButton from './GremlinAudienceSearchButton';
import { FetchStatus, GremlinAudienceDialogStreamShape } from './types';
import { usePaginationGremlinSearch } from 'search/Gremlin/usePaginationGremlinSearch';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { getBroadcastAudienceQueryModel } from './utils';
import { WarningNotification } from '@ardoq/status-ui';
import { ModalContent, ModalLayout, ModalTemplate } from '@ardoq/modal';
import {
  fetchGremlinAudiencePreview,
  updateGremlinAudienceQuery,
} from './actions';
import { BroadcastContentType, SearchType } from '@ardoq/api-types';
import { Header3, Text } from '@ardoq/typography';
import { AudiencePreviewColumn } from 'broadcasts/components/atoms';
import { FlexBox } from '@ardoq/layout';
import { BROADCAST_AUDIENCE_DIALOG_MODAL_SIZE } from '../dialogConsts';

type GremlinAudienceDialogProps = ModalContent<string> &
  GremlinAudienceDialogStreamShape & { contentType: BroadcastContentType };

const GremlinAudienceDialog = ({
  query,
  formIsValid,
  isSearching,
  isUpdatingExistingAudience,
  contentIds,
  immutableFirstLineOfQuery,
  contentIdsFetchStatus,
  audiencePreviewWarningMessage,
  gremlinWarningMessage,
  audiencePreview,
  resolve,
  contentType,
}: GremlinAudienceDialogProps) => {
  const warningMessage = gremlinWarningMessage ?? audiencePreviewWarningMessage;
  const closeDialog = () => resolve(false);

  const addToAudienceList = () => {
    if (!formIsValid) return;
    resolve(query);
  };

  const { resultsId, loadPaginatedResults, doPaginatedSearch } =
    usePaginationGremlinSearch({
      model: getBroadcastAudienceQueryModel(query),
      queryParams: { ids: contentIds },
      searchType: SearchType.BROADCAST_AUDIENCE_QUERY,
    });

  useEffect(() => {
    if (contentIdsFetchStatus === FetchStatus.SUCCESS) {
      doPaginatedSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentIdsFetchStatus]);

  return (
    <ModalTemplate
      modalSize={BROADCAST_AUDIENCE_DIALOG_MODAL_SIZE}
      headerText="Add Audiences by Gremlin People Query"
      onCloseButtonClick={closeDialog}
      fixedHeight
      footerContent={
        <BroadcastAudienceDialogFooter
          isUpdatingExistingAudience={isUpdatingExistingAudience}
          audienceCount={audiencePreview.length}
          onCancel={closeDialog}
          onSubmit={addToAudienceList}
          formIsValid={formIsValid}
          hasError={contentIdsFetchStatus === FetchStatus.ERROR}
        />
      }
    >
      {contentIdsFetchStatus === FetchStatus.ERROR ? (
        <GenericErrorContent />
      ) : (
        <FlexBox height="full" width="full" flexDirection="column">
          <ModalLayout>
            <FlexBox justify="space-between" align="center" width="full">
              <Header3>Edit Gremlin people query</Header3>
              <GremlinAudienceSearchButton
                onClick={() => {
                  doPaginatedSearch();
                  dispatchAction(fetchGremlinAudiencePreview());
                }}
                isSearching={isSearching}
              />
            </FlexBox>
            <Text>
              Use this section to add an audience to the broadcast by writing a
              Gremlin graph query that links your broadcast components with one
              or more people.
            </Text>
            <GremlinAudienceEditor
              onCtrlCmdEnter={doPaginatedSearch}
              immutableFirstLineOfQuery={immutableFirstLineOfQuery}
              value={query}
              onChange={newQuery =>
                dispatchAction(updateGremlinAudienceQuery(newQuery))
              }
            />
            {warningMessage && (
              <WarningNotification>{warningMessage}</WarningNotification>
            )}
          </ModalLayout>
          <AudiencePreviewColumn>
            <TabbedGremlinAudienceTable
              loadResults={loadPaginatedResults}
              resultsId={resultsId}
              contentType={contentType}
            />
          </AudiencePreviewColumn>
        </FlexBox>
      )}
    </ModalTemplate>
  );
};

export default connect(GremlinAudienceDialog, gremlinAudienceDialog$);
