import { TraversalRow } from '../components/AssetsBrowser/types';
import { trackEvent } from 'tracking/tracking';

export enum HomePageTrackingEventsNames {
  CLICKED_OPEN_VIEWPOINT = 'Home page Opened viewpoint',
  CLICKED_CARD_MENU_OPTIONS = 'Clicked on viewpoint card menu options',
  CLICKED_CREATE_BUTTON = 'open-create-dropdown',
  CLICKED_CREATE_FOLDER = 'open-create-folder-modal',
  CLICKED_CREATE_WORKSPACE = 'open-create-workspace-wizard',
  CLICKED_CREATE_PRESENTATION = 'open-presentation-creator',
  CLICKED_CREATE_MEDAMODEL = 'open-metamodel-creator',
  CLICKED_CREATE_SURVEY = 'open-survey-creator',
  CLICKED_CREATE_VIEWPOINT = 'open-viewpoint-creator',
  CLICKED_CREATE_BROADCAST = 'open-new-broadcast',
  CLICKED_CREATE_REPORT = 'open-new-report',
  CLICKED_CREATE_DROPWON = 'open-new-dashboard',
  CLICKED_CREATE_DISCOVER_VIEWPOINT = 'open-new-discover-viewpoint',
}

const trackOpenViewPointFromCard = (traversal: TraversalRow) => {
  trackEvent(HomePageTrackingEventsNames.CLICKED_OPEN_VIEWPOINT, {
    ...traversal.meta,
    viewName: traversal.viewName,
    source: 'From card',
  });
};

export { trackOpenViewPointFromCard };
