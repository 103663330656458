import { dispatchAction } from '@ardoq/rxbeach';
import {
  searchValueChanged as searchValueChangedAction,
  workspaceDeselection,
  workspaceSelection,
  setSelectedWorkspaces as setSelectedWorkspacesAction,
  saveWorkspaceBindings as saveWorkspaceBindingsAction,
  workspaceBindingsSaved,
  workspaceBindingsSavingFailure,
} from './actions';
import { SubdivisionViewModelState } from 'subdivisionEditor/types';
import { BindWorkspacesCommands } from './types';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { ArdoqId } from '@ardoq/api-types';
import { touchStep } from 'subdivisionEditor/subdivisionEditorViewModel$/actions';
import { SubdivisionEditorSteps } from 'subdivisionEditor/navigation/types';
import {
  DIVISIONS_USER_EVENTS,
  trackDivisionEditorUserEvent,
} from 'subdivisionEditor/trackingUtils';

const toggleWorkspaceSelection = (workspaceId: string, isSelected: boolean) => {
  const action = isSelected ? workspaceDeselection : workspaceSelection;
  dispatchAction(action(workspaceId));
};
const setSelectedWorkspaces = (
  subdivisionStateData: SubdivisionViewModelState,
  workspacesBoundToSubdivisionIds: ArdoqId[],
  workspaceIds: string[] | null
) => {
  trackDivisionEditorUserEvent(
    DIVISIONS_USER_EVENTS.BIND_WORKSPACES_SELECTION,
    {
      alreadySavedWorkspacesCount:
        subdivisionStateData.bindWorkspacesState.workspacesBoundToSubdivisionIds
          .length,
      previousSelectedWorkspacesCount:
        subdivisionStateData.bindWorkspacesState.selectedWorkspaces?.length ??
        0,
      newSelectedWorkspacesCount: workspaceIds?.length ?? 0,
    }
  );
  dispatchAction(
    setSelectedWorkspacesAction({
      workspaceIds: workspaceIds ?? [],
      workspacesBoundToSubdivisionIds,
    })
  );
  dispatchAction(touchStep(SubdivisionEditorSteps.COMPONENTS_SELECTION));
};

const searchValueChanged = (searchValue: string) => {
  dispatchAction(searchValueChangedAction(searchValue));
};

const clearSearchValue = () => {
  dispatchAction(searchValueChangedAction(''));
};

const saveWorkspaceBindings = async (
  subdivisionEditorState: SubdivisionViewModelState
): Promise<boolean> => {
  const response = await dispatchActionAndWaitForResponse(
    saveWorkspaceBindingsAction(subdivisionEditorState),
    workspaceBindingsSaved,
    workspaceBindingsSavingFailure
  );
  return response.type === workspaceBindingsSaved.type;
};

const hasBoundWorkspaces = (
  subdivisionState: SubdivisionViewModelState
): boolean => {
  return (
    subdivisionState.bindWorkspacesState.workspacesBoundToSubdivisionIds
      .length > 0
  );
};

const countBoundWorkspaces = (
  subdivisionState: SubdivisionViewModelState
): number => {
  return subdivisionState.bindWorkspacesState.workspacesBoundToSubdivisionIds
    .length;
};

export const bindWorkspacesCommands = {
  toggleWorkspaceSelection,
  searchValueChanged,
  clearSearchValue,
  saveWorkspaceBindings,
  setSelectedWorkspaces,
  hasBoundWorkspaces,
  countBoundWorkspaces,
} satisfies BindWorkspacesCommands;
