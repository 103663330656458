import { Observable, tap, withLatestFrom } from 'rxjs';
import { NavigatorLayoutState } from '../types';
import { action$, extractPayload, ofType } from '@ardoq/rxbeach';
import { startedCreatingReferences } from 'createReferences2024/createReferencesActions';
import { startScrollHandler } from '../scrollHandler';
import { isNavigatorTreeFilterOrFilterButton } from 'createReferences2024/isNavigatorTreeFilterOrFilterButton';

export const getStartScrollHandlerRoutine = (
  navigator$: Observable<NavigatorLayoutState>
) =>
  action$.pipe(
    ofType(startedCreatingReferences),
    extractPayload(),
    withLatestFrom(navigator$),
    tap(([abortSignal, { container }]) => {
      if (!container) {
        return;
      }
      startScrollHandler(
        abortSignal,
        container,
        isNavigatorTreeFilterOrFilterButton
      );
    })
  );
