import {
  ArdoqId,
  SSOMappingAction,
  SSOMappingCondition,
  MappingRule,
  TempId,
  Clause,
  SSOAttributeMappingAPIResponse,
  LoginOptionWithEnabledFlag,
  PermissionGroup,
} from '@ardoq/api-types';
import { uniqueId } from 'lodash';
import { PreviewAttributeType, RulesById, SsoOption } from './types';
import { currentDate, parseDate } from '@ardoq/date-time';

const TEMP_ID_PREFIX = '__temp_id_';

const generateTempId = (): TempId => TEMP_ID_PREFIX + uniqueId();

export const isTempId = (id: ArdoqId | TempId) => id.includes(TEMP_ID_PREFIX);

export const generateKeyedEmptyRule = (
  allowMultipleSsoOptionsInMappings: boolean
): { [k: TempId]: MappingRule } => {
  const _id = generateTempId();
  return {
    [_id]: {
      _id,
      action: {
        action: SSOMappingAction.ADD_TO_GROUP,
        groupId: '',
      },
      clause: ensureClauseProviderIf(allowMultipleSsoOptionsInMappings, {
        condition: SSOMappingCondition.EMPTY,
        value: '',
        attribute: '',
      }),
      created: currentDate(),
    },
  };
};

export const isPlural = (value: number) => value !== 1;

export const inferAttributeType = (value: unknown) => {
  if (value === null) return PreviewAttributeType.NULL;
  if (Array.isArray(value)) return PreviewAttributeType.ARRAY;
  if (typeof value === 'string') return PreviewAttributeType.STRING;
  return PreviewAttributeType.UNKNOWN;
};

export const loginConfigToSsoOptions = (
  loginOptions: LoginOptionWithEnabledFlag[]
): SsoOption[] =>
  loginOptions.map(({ _id, name, enabled }) => ({
    label: enabled ? name : `${name} (disabled)`,
    value: _id,
  }));

export const permissionGroupsToGroupOptions = (groups: PermissionGroup[]) =>
  groups.map(({ _id, name }) => ({
    label: name,
    value: _id,
  }));

const ensureClauseProviderIf = (
  allowMultipleSsoOptionsInMappings: boolean,
  clause: Clause
): Clause => {
  const { provider, ...rest } = clause;
  return {
    ...rest,
    ...(allowMultipleSsoOptionsInMappings && { provider }),
  };
};

export const mappingResponseToInitialMapping = (
  response: SSOAttributeMappingAPIResponse[],
  allowMultipleSsoOptionsInMappings: boolean
) => {
  return response.reduce<RulesById>(
    (acc, curr) => ({
      ...acc,
      [curr._id]: {
        _id: curr._id,
        action: curr.action,
        clause: ensureClauseProviderIf(
          allowMultipleSsoOptionsInMappings,
          curr.clause
        ),
        created: parseDate(curr.created),
      },
    }),
    {}
  );
};

export const fieldToPath = (id: string, field: string) => {
  if (field === 'groupId') return `${id}.action.${field}`;
  return `${id}.clause.${field}`;
};
