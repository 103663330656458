import { createElement } from 'react';
import { INode, IRenderContext, Size, SvgVisual } from '@ardoq/yfiles';
import { isSelectedRelatedNode } from 'yfilesExtensions/styles/nodeDecorator';
import { Node } from 'graph/node';
import { CompElementExtension } from 'aqTypes';
import ArdoqNode from './ArdoqNodeClone';
import SparkMD5 from 'spark-md5';
import {
  CONTEXT_HIGHLIGHT_PADDING,
  SELECTION_RADIUS_DIFF,
  SELECTION_STROKE_WIDTH,
} from '../../../yfilesExtensions/styles/consts';
import {
  ComponentRepresentationData,
  ensureRoot,
  disposeCallback,
} from '@ardoq/graph';
import ArdoqNodeStyleBase from './ArdoqNodeStyleBaseClone';
import { isContextNode } from '../../utils';

class ArdoqNodeStyle extends ArdoqNodeStyleBase {
  private representationData: ComponentRepresentationData | null;
  private color?: string | null;
  private _size = new Size(150, 150);
  constructor({
    representationData,
    color,
  }: {
    representationData: ComponentRepresentationData | null;
    color?: string | null;
  }) {
    super();
    this.representationData = representationData;
    this.color = color;
  }

  get size() {
    return this._size;
  }

  override getHash(node: INode) {
    return SparkMD5.hash(
      [
        node.tag ? (node.tag as Node).getCSS() : '',
        node.tag.getVisualDiffType(),
        node.tag ? node.tag.isGhost : false,
        isContextNode(node),
        isSelectedRelatedNode(node),
        node.layout.width,
        node.layout.height,
        node.tag?.getIsSelected(),
        node.tag?.getIsInSelectedPath(),
        node.tag?.hasFilters(),
        node.tag?.getOpacity(),
        node.tag?.shouldDisplayClickOtherComponentsHint(),
      ].join('-')
    );
  }
  override createVisual(context: IRenderContext, node: INode): SvgVisual {
    const result = super.createVisual(context, node);
    context.setDisposeCallback(result, disposeCallback);
    return result;
  }
  protected override render(container: SVGElement, node: INode) {
    (container as SVGElement & CompElementExtension).comp =
      node.tag && node.tag.dataModel;

    const isContext = isContextNode(node);
    const isSelectedRelated = isSelectedRelatedNode(node);
    const { width, height } = node.layout;
    ensureRoot(container).render(
      createElement(ArdoqNode, {
        representationData: this.representationData,
        color: this.color || 'red',
        isContext,
        isSelectedRelated,
        width,
        height,
        contextHighlightPadding: CONTEXT_HIGHLIGHT_PADDING,
        graphId: node.tag.id,
        isSelected: node.tag.getIsSelected(),
        selectionRadiusDiff: SELECTION_RADIUS_DIFF,
        selectionStrokeWidth: SELECTION_STROKE_WIDTH,
        hasFilters: node.tag.hasFilters(),
        opacity: node.tag.getOpacity(),
        label: node.tag.getLabelWithCount(),
        shouldDisplayClickOtherComponentsHint:
          node.tag.shouldDisplayClickOtherComponentsHint(),
      })
    );
  }
}
export default ArdoqNodeStyle;
