import { MetaModel } from '@ardoq/api-types';
import { ComponentTypeContainer } from '@ardoq/decorators';
import { colors } from '@ardoq/design-tokens';
import { ComponentRepresentationData } from '@ardoq/graph';
import { ComponentIcon, getIcon, IconSize } from '@ardoq/icons';
import { TextOverflow } from '@ardoq/popovers';
import { Text } from '@ardoq/typography';
import { ComponentType } from 'viewpointBuilder/components/ComponentType';
import { getRepresentationDataWithColorVariants } from 'viewpointBuilder/components/getRepresentationDataWithColorVariants';
import {
  ensureContrast,
  getDefaultCSSColor,
  getLightenedColor,
} from '@ardoq/color-helpers';

const getRepresentationData = (
  metamodel: MetaModel,
  componentTypeName: string
): ComponentRepresentationData | undefined => {
  const componentType = metamodel.componentTypes.find(
    type => type.name === componentTypeName
  );
  const styleData = componentType?.style;
  if (!styleData) return undefined;
  return {
    color: styleData.color ?? getDefaultCSSColor(styleData.level),
    isImage: Boolean(styleData.image),
    icon: getIcon(styleData.icon ?? null),
    value: styleData.image ?? '',
    shapeName: styleData.shape,
  };
};

const FallBackComponentType = ({
  representationData,
  componentTypeName,
}: {
  representationData: ComponentRepresentationData | undefined;
  componentTypeName: string;
}) => {
  const lightenedColor = representationData
    ? getLightenedColor(representationData.color)
    : colors.surfaceInfo;
  const contrastColor = representationData?.color
    ? ensureContrast(lightenedColor, representationData.color)
    : colors.borderInfo;
  const textColor = representationData?.color ? contrastColor : colors.textInfo;
  return (
    <ComponentTypeContainer
      $borderColor={contrastColor}
      $backgroundColor={lightenedColor}
      $isSelected={false}
    >
      <ComponentIcon iconSize={IconSize.SMALL} color={textColor} />
      <TextOverflow>
        <span style={{ color: textColor }}>{componentTypeName}</span>
      </TextOverflow>
    </ComponentTypeContainer>
  );
};

const ComponentTypeTag = ({
  metamodel,
  componentTypeName,
  hideLabel = false,
}: {
  metamodel: MetaModel;
  componentTypeName: string;
  hideLabel?: boolean;
}) => {
  const representationData = getRepresentationData(
    metamodel,
    componentTypeName
  );

  const representationDataHasIcon =
    representationData?.icon.id !== 'none' ||
    representationData?.isImage ||
    representationData?.shapeName;

  if (!representationData || !representationDataHasIcon) {
    return (
      <Text variant="text2">
        <FallBackComponentType
          representationData={representationData}
          componentTypeName={componentTypeName}
        />
      </Text>
    );
  }
  const representationDataWithColorVariants =
    getRepresentationDataWithColorVariants(representationData);
  return (
    <Text variant="text2">
      <ComponentType
        representationData={representationDataWithColorVariants}
        label={hideLabel ? '' : componentTypeName}
      />
    </Text>
  );
};

export default ComponentTypeTag;
