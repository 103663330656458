import styled from 'styled-components';
import { colors, radius, shadowS } from '@ardoq/design-tokens';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { Link, Text } from '@ardoq/typography';

type FloatingClickOtherComponentsHintProps = {
  onClickNeverShowAgainHints: () => void;
};

export const FloatingClickOtherComponentsHint = ({
  onClickNeverShowAgainHints,
}: FloatingClickOtherComponentsHintProps) => {
  return (
    <FloatingWrapper onClick={event => event.stopPropagation()}>
      <FlexBox gap="medium" padding="small" align="center">
        <FlexBox gap="small" align="center">
          <Icon
            iconName={IconName.INFO}
            iconSize={IconSize.SMALL}
            color={colors.icon}
          />
          <Text variant="text2">
            Click other component types to explore further
          </Text>
        </FlexBox>
        <Link
          typography="text2Bold"
          hideIcon
          onClick={onClickNeverShowAgainHints}
        >
          Don&apos;t show again
        </Link>
      </FlexBox>
    </FloatingWrapper>
  );
};

const FloatingWrapper = styled.div`
  position: absolute;
  width: max-content;
  left: 0;
  right: 0;
  margin-inline: auto;
  transform: translateY(60vh);

  z-index: 5;

  border-radius: ${radius.medium};
  background-color: ${colors.surfaceDefault};
  border: 1px solid ${colors.borderSubtle00};
  box-shadow: ${shadowS};
`;
