import { uniqBy } from 'lodash/fp';
import { TabularMapping } from '../tabularMappings/types';
import { Field } from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import {
  isCustomIdMapping,
  isCustomIdParentMapping,
  isCustomIdReferenceFormat,
  isFieldColumnMapping,
  isReferenceFormat,
} from '../tabularMappings/utils';

export const extractUsedFields = (mappingConfig: TabularMapping): Field[] => {
  const allFields: Array<Field | undefined> = Object.values(mappingConfig)
    .map(config =>
      Object.values(config.mappedColumns)
        .map((mapping: ColumnMapping) => {
          if (isFieldColumnMapping(mapping)) {
            return [
              {
                name: mapping.fieldName,
                label: mapping.fieldLabel,
                type: mapping.fieldType,
              },
            ];
          }
          if (
            isCustomIdParentMapping(mapping) ||
            isCustomIdMapping(mapping) ||
            (isReferenceFormat(mapping) && isCustomIdReferenceFormat(mapping))
          ) {
            return [
              {
                name: mapping.fieldName,
                label: mapping.fieldLabel,
              },
            ];
          }
          return [];
        })
        .flat()
    )
    .flat();

  const usedFields = allFields.filter(ExcludeFalsy);

  return uniqBy('label', usedFields);
};
