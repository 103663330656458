import Flex from 'atomicComponents/Flex';
import TabularConfigMapping from 'integrations/common/pages/tabularConfigMapping/TabularConfigMapping';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { ImportHeader } from 'integrations/unified/import/containers/header/ImportHeader';
import { WaitingOverlay } from 'integrations/unified/import/containers/overlay/Overlay';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { combineLatest, filter, map, of, switchMap } from 'rxjs';
import { UnifiedIntegrationId } from '../types';
import { ConfigureStep } from './stepper/configure';
import { ImportAndScheduleStep } from './stepper/importAndSchedule';
import { SelectData } from './selectData/page';
import { SelectDataStep } from './stepper/selectData';
import { ImportAndSchedule } from 'integrations/common/pages/importAndSchedule/ImportAndSchedule';
import { ReviewTest } from 'integrations/common/pages/reviewTest/ReviewTest';
import { ReviewStep } from './stepper/reviewTest';
import { isUnifiedIntegrationId } from '../utils';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { ImportRoute } from 'integrations/common/navigation/types';
import { Stepper } from 'integrations/common/components/stepper';
import { StickyFooter } from 'integrations/common/containers/stickyFooter/StickyFooter';
import { getResourcesStream } from '../streams/resources/resources$';
import { IntegrationResources } from '../streams/resources/types';
import { isValidResourcesSelection } from '../streams/resources/utils';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { PageBody } from '@ardoq/page-layout';
import { FlowContainer } from 'integrations/common/components/flowContainer/FlowContainer';
import { loadSelection } from 'integrations/common/streams/selectionState/actions';

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId =>
    combineLatest({
      integrationId: of(integrationId),
      activeIntegration: getActiveIntegrationStream(integrationId),
      resources: getResourcesStream(integrationId),
      integrationsTerms: getIntegrationTermsDictionaryStream(integrationId),
    })
  ),
  map(
    ({
      integrationId,
      activeIntegration: { integrationPath },
      resources,
      integrationsTerms,
    }) => ({
      integrationPath,
      integrationId,
      integrationName: integrationsTerms.name,
      resources,
    })
  )
);

const ImportComponent = ({
  integrationId,
  integrationPath,
  integrationName,
  resources,
}: {
  integrationName: string;
  integrationPath: ActiveIntegrationState['integrationPath'];
  integrationId: UnifiedIntegrationId;
  resources: IntegrationResources;
}) => {
  return (
    <PageBody
      padding={0}
      backgroundColor="bgDefault"
      headerContent={<ImportHeader />}
      footerContent={
        <StickyFooter
          integrationName={integrationName}
          isDataSelectionValid={isValidResourcesSelection(
            resources.selectedResources
          )}
          onDataSelectionNext={() =>
            dispatchAction(loadSelection(integrationId))
          }
        />
      }
    >
      <FlowContainer>
        <WaitingOverlay />
        <Flex $direction="column">
          <Stepper>
            <SelectDataStep />
            <ConfigureStep />
            <ReviewStep />
            <ImportAndScheduleStep />
          </Stepper>
        </Flex>
        {integrationPath === ImportRoute.SELECT_DATA && <SelectData />}
        {integrationPath === ImportRoute.CONFIGURE && <TabularConfigMapping />}
        {integrationPath === ImportRoute.REVIEW && <ReviewTest />}
        {integrationPath === ImportRoute.IMPORT_AND_SCHEDULE && (
          <ImportAndSchedule />
        )}
      </FlowContainer>
    </PageBody>
  );
};

export const Import = connect(ImportComponent, viewModel$);
