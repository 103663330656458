import { actionCreator } from '@ardoq/rxbeach';
import { AwsSettings } from '@ardoq/api-types/integrations';
import { AsyncStatus } from 'integrations/common/types/api';

const NAMESPACE = '[integrations aws-v3] SETTINGS';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const fetchAwsSettings = actionCreator(
  getActionName('FETCH_AWS_SETTINGS')
);

export const setSettingsAsyncStatus = actionCreator<AsyncStatus>(
  getActionName('SET_SETTINGS_ASYNC_STATUS')
);

export const fetchAwsSettingsSuccess =
  actionCreator<FetchAwsSettingsSuccessPayload>(
    getActionName('FETCH_AWS_SETTINGS_SUCCESS')
  );

export type FetchAwsSettingsSuccessPayload = {
  settings: AwsSettings;
};
