import { DateRangePropertyValue } from 'aqTypes';
import { CreatableValidator, Validator } from 'scopeData/editors/types';
import { FieldType } from '@ardoq/renderers';
import { isISO8601DateOnly, isISO8601DateTime } from '@ardoq/date-time';
import { isUndefinedOrNull } from 'utils/collectionUtil';
import {
  FIELD_NAME_REGEX,
  INVALID_LIST_FIELD_VALUE_MESSAGE,
  RESERVED_ATTRIBUTE_NAMES,
  TAG_REGEX,
} from 'models/consts';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { LocallyDerivedTransformationOperation } from '@ardoq/api-types';

const GENERIC_VALIDATOR_MESSAGE = 'Invalid value';
const REQUIRED_VALIDATOR_MESSAGE = 'This field is required';

const requiredTextValidator = (value: string | null) => {
  if (value?.trim().length) {
    return null;
  }
  return REQUIRED_VALIDATOR_MESSAGE;
};

export const numberValidator = (value: number) => {
  if (isUndefinedOrNull(value)) {
    return null;
  }
  if (!Number.isFinite(value)) {
    return GENERIC_VALIDATOR_MESSAGE;
  }
  if (value > Number.MAX_SAFE_INTEGER) {
    return 'Number is too large';
  }
  if (value < Number.MIN_SAFE_INTEGER) {
    return 'Number is too small';
  }
  return null;
};

const dateOnlyValidator = (value: string | null) => {
  if (isUndefinedOrNull(value) || isISO8601DateOnly(value)) {
    return null;
  }
  return GENERIC_VALIDATOR_MESSAGE;
};

const dateTimeValidator = (value: string | null) => {
  if (isUndefinedOrNull(value) || isISO8601DateTime(value)) {
    return null;
  }
  return GENERIC_VALIDATOR_MESSAGE;
};

const dateOnlyRangeValidator = (value: DateRangePropertyValue | null) => {
  if (!value) {
    return null;
  }
  const start = value?.start ?? null;
  const end = value?.end ?? null;
  const invalidStartDateMessage = dateOnlyValidator(start);
  const invalidEndDateMessage = dateOnlyValidator(end);
  if (invalidStartDateMessage || invalidEndDateMessage) {
    return {
      start: invalidStartDateMessage,
      end: invalidEndDateMessage,
    };
  }
  if (start !== null && end !== null && start > end) {
    return 'Start date should precede end date';
  }
  return null;
};

const dateTimeRangeValidator = (value: DateRangePropertyValue | null) => {
  if (!value) {
    return null;
  }
  const start = value?.start ?? null;
  const end = value?.end ?? null;
  const invalidStartDateMessage = dateTimeValidator(start);
  const invalidEndDateMessage = dateTimeValidator(end);
  if (invalidStartDateMessage || invalidEndDateMessage) {
    return {
      start: invalidStartDateMessage,
      end: invalidEndDateMessage,
    };
  }
  if (start !== null && end !== null && start > end) {
    return 'Start date should precede end date';
  }
  return null;
};

export const emailValidator = (value: string) => {
  if (isUndefinedOrNull(value) || /.+@.+/.test(value)) {
    return null;
  }
  return GENERIC_VALIDATOR_MESSAGE;
};

const colorValidator = (value: string) => {
  if (isUndefinedOrNull(value) || CSS.supports('color', value)) {
    return null;
  }
  return GENERIC_VALIDATOR_MESSAGE;
};

const fieldLabelValidator = (value: string | null) => {
  if (typeof value !== 'string') {
    return GENERIC_VALIDATOR_MESSAGE;
  }
  const trimmed = value.trim();
  const isValid =
    trimmed.length !== 0 &&
    FIELD_NAME_REGEX.test(trimmed) &&
    !trimmed.startsWith('_') &&
    !RESERVED_ATTRIBUTE_NAMES.includes(trimmed.toLowerCase());
  if (isValid) {
    return null;
  }
  return GENERIC_VALIDATOR_MESSAGE;
};

export const fieldLabelCreationValidator = (value: string | null) => {
  const errorMessage = fieldLabelValidator(value);
  return !errorMessage;
};

export const tagNameValidator = (value: string | null) =>
  typeof value === 'string' && TAG_REGEX.test(value);

const dictionaryLookupValidator = (transformation: any) => {
  const additionalFields = transformation?.config?.additionalFields;
  if (
    !additionalFields ||
    !Array.isArray(additionalFields) ||
    additionalFields.length < 1
  ) {
    return 'Missing lookup field';
  }
  return null;
};

const mathTransformationValidator = (transformation: any) => {
  const expression = transformation?.config?.expression;
  if (!expression || typeof expression !== 'string' || !expression.trim()) {
    return 'Missing expression';
  }
  return null;
};

const concatTransformationValidator = (transformation: any) => {
  const additionalFields = transformation?.config?.additionalFields;
  if (
    !additionalFields ||
    !Array.isArray(additionalFields) ||
    additionalFields.length < 1
  ) {
    return 'Missing fields to concatenate';
  }
  return null;
};

export const transformationsValidator = (value: any) => {
  if (!Array.isArray(value)) {
    return 'Invalid transformation';
  }
  if (value.length === 0) {
    return 'No transformation selected';
  }
  const transformation = value[0];

  switch (transformation?.operation) {
    case LocallyDerivedTransformationOperation.DICTIONARY_LOOKUP:
      return dictionaryLookupValidator(transformation);
    case LocallyDerivedTransformationOperation.MATH:
      return mathTransformationValidator(transformation);
    case LocallyDerivedTransformationOperation.CONCAT:
      return concatTransformationValidator(transformation);
    default:
      return null;
  }
};

const validatorMap = new Map<FieldType, Validator[]>([
  // text
  [FieldType.NAME, [requiredTextValidator]],
  // field label (human-readable field name)
  [FieldType.LABEL, [requiredTextValidator, fieldLabelValidator]],
  // number
  [FieldType.NUMBER, [numberValidator]],
  // [FieldType.ORDER, numberValidator], // reference order is deprecated
  [FieldType.DEFAULT_VALUE_NUMBER, [numberValidator]],
  // date only
  [FieldType.DATE_ONLY, [dateOnlyValidator]],
  [FieldType.DEFAULT_VALUE_DATE_ONLY, [dateOnlyValidator]],
  // date time
  [FieldType.DATE_TIME, [dateTimeValidator]],
  [FieldType.DEFAULT_VALUE_DATE_TIME, [dateTimeValidator]],
  // date only range
  [FieldType.DATE_ONLY_RANGE, [dateOnlyRangeValidator]],
  [FieldType.DEFAULT_VALUE_DATE_ONLY_RANGE, [dateOnlyRangeValidator]],
  // date time range
  [FieldType.DATE_TIME_RANGE, [dateTimeRangeValidator]],
  [FieldType.DEFAULT_VALUE_DATE_TIME_RANGE, [dateTimeRangeValidator]],
  // email
  [FieldType.EMAIL, [emailValidator]],
  [FieldType.DEFAULT_VALUE_EMAIL, [emailValidator]],
  // color picker
  [FieldType.COLOR, [colorValidator]],
  [FieldType.SOURCE, [requiredTextValidator]],
  [FieldType.TARGET, [requiredTextValidator]],
  // Locally derived fields
  [FieldType.TRANSFORM_NUMBER, [transformationsValidator]],
  [FieldType.TRANSFORM_TEXT, [transformationsValidator]],
  [FieldType.TRANSFORM_LIST, [transformationsValidator]],
]);

const getValidatorsForType = (type: FieldType) => {
  if (!validatorMap.has(type)) {
    return [];
  }
  return validatorMap.get(type);
};

export const getValidationErrorMessages = (type: FieldType, value: any) => {
  const validators = getValidatorsForType(type)!;
  return validators.map(validator => validator(value)).filter(ExcludeFalsy);
};

const listFieldValueValidator = (value: string | null) =>
  typeof value === 'string' &&
  value.trim().length !== 0 &&
  !value.includes(',');

export const creationValidatorMap = new Map<FieldType, CreatableValidator>([
  [FieldType.DEFAULT_VALUE_LIST, listFieldValueValidator],
  [FieldType.DEFAULT_VALUE_SELECT_MULTIPLE_LIST, listFieldValueValidator],
]);

export const getInvalidOptionMsg = (type: FieldType) =>
  creationValidatorMap.has(type) ? INVALID_LIST_FIELD_VALUE_MESSAGE : null;
