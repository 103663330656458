import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  dispatchAction,
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { fetchParameterOptions, updateParameterOptions } from './actions';
import { getIn } from 'utils/collectionUtil';
import { dynamicFilterError } from 'streams/dynamicFilters/actions';
import dynamicFilters$ from 'streams/dynamicFilters/dynamicFilters$';
import { ArdoqId, GraphSearchResultObject } from '@ardoq/api-types';
import { graphSearchApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';

const getParameterizedGraphSearch = async (parameterQueryId: ArdoqId) => {
  const response =
    await graphSearchApi.parameterizedGraphSearch<GraphSearchResultObject>(
      parameterQueryId
    );
  return { parameterQueryId, response };
};

const handleFetchParameterOptions = routine(
  ofType(fetchParameterOptions),
  extractPayload(),
  switchMap(({ parameterQueryId }) =>
    getParameterizedGraphSearch(parameterQueryId)
  ),
  withLatestFrom(dynamicFilters$),
  tap(([{ parameterQueryId, response }, filters]) => {
    if (isArdoqError(response)) {
      const filter = filters.find(f => f.parameterQueryId === parameterQueryId);
      dispatchAction(
        dynamicFilterError({
          id: filter!._id,
          error: 'Error while fetching parameter options',
        })
      );
      return;
    }

    const emptyResult = !getIn(response, ['result', 0]);
    if (emptyResult) {
      return;
    }
    const parameterOptions = response.result.map(
      ({ id, properties, label }) => ({
        label: getIn(properties, ['name', 0, 'value']),
        value: id,
        type: label,
      })
    );
    dispatchAction(
      updateParameterOptions({
        parameterQueryId,
        parameterOptions,
      })
    );
  })
);

export default collectRoutines(handleFetchParameterOptions);
