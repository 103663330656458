import { APIOrganizationAttributes } from '@ardoq/api-types';
import { ArdoqError } from '@ardoq/common-helpers';
import { actionCreator } from '@ardoq/rxbeach';

export const fetchCurrentOrganizationRequested = actionCreator(
  '[organizations] FETCH_CURRENT'
);

export const fetchCurrentOrganizationSucceeded =
  actionCreator<APIOrganizationAttributes>(
    '[organizations] FETCH_CURRENT_SUCCEEDED'
  );

export const fetchCurrentOrganizationFailed = actionCreator<ArdoqError>(
  '[organizations] FETCH_CURRENT_FAILED'
);

export const updateOrganizationRequested =
  actionCreator<APIOrganizationAttributes>('[organizations] UPDATE');

export const updateOrganizationSucceeded =
  actionCreator<APIOrganizationAttributes>('[organizations] UPDATE_SUCCEEDED');

export const updateOrganizationFailed = actionCreator<ArdoqError>(
  '[organizations] UPDATE_FAILED'
);
