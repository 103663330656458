import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { fetchDictionaryResource, updateDictionaryCategories } from './actions';
import { catchError, filter, switchMap, tap, withLatestFrom } from 'rxjs';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { NewExportRoutes } from 'integrations/common/navigation/types';
import { signavioExporterApi } from '@ardoq/api';
import { configState$ } from '../configurationState/configurationState$';
import {
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';
import { apiDictionariesToState } from './utils';
import { dictionaryCategories$ } from './dictionaryCategories$';

const handleFetchDictionaryCategory = routine(
  ofType(fetchDictionaryResource),
  extractPayload(),
  withLatestFrom(configState$, dictionaryCategories$),
  filter(([force, , categories]) => {
    return (
      force ||
      (categories.status !== 'SUCCESS' && categories.status !== 'LOADING')
    );
  }),
  switchMap(([, config]) => {
    dispatchAction(
      updateDictionaryCategories({
        status: 'LOADING',
        categories: {},
        allAttributes: [],
      })
    );
    return retriableRequest(() =>
      signavioExporterApi.fetchDictionaries(config.accountId)
    );
  }),
  tap(response => {
    dispatchAction(
      updateDictionaryCategories({
        status: 'SUCCESS',
        ...apiDictionariesToState(response),
      })
    );
  }),
  catchError(err => {
    dispatchAction(
      updateDictionaryCategories({
        status: 'FAILURE',
        categories: {},
        allAttributes: [],
      })
    );
    return handleApiError(err);
  })
);

const handleNavigateToMapFields = routine(
  ofType(navigateToPath),
  extractPayload(),
  filter(
    ({ integrationId, path }) =>
      integrationId === 'signavio-exporter' &&
      path === NewExportRoutes.FIELD_MAPPING
  ),
  tap(() => {
    dispatchAction(fetchDictionaryResource());
  })
);

export default [handleFetchDictionaryCategory, handleNavigateToMapFields];
