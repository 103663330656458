export enum TrackOrgMembersViewUserEvents {
  CLICKED_INVITE_USER = 'clicked invite user',
  SEARCH_USERS = 'searched for users',
  OPENED_ACTION_MENU = 'opened action menu',
  OPENED_FILTER_MENU = 'opened filter menu',
  TOGGLED_ROLE_FILTER = 'toggled role filter',
  TOGGLED_PRIVILEGE_FILTER = 'toggled privilege filter',
  SELECTED_USERS = 'selected users',
  CLICKED_ACTION = 'clicked action',
  SORT_USERS = 'sorted users',
  CLICKED_NAVIGATE_TO_PERMISSION_GROUPS_PAGE = 'clicked navigate to permission groups page',
}
