import { QueryModel, GremlinSearchQuery, SearchType } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { createSearchQuerySuccess } from './actions';
import { GremlinSearchSource, trackQueriedGremlinSearch } from './tracking';
import { queryGremlinSearch } from './Gremlin/actions';
import { INITIAL_LOAD_SIZE } from './Gremlin/GremlinResults/consts';
import { getQueryEditorNamespace } from './QueryEditor/queryEditor$';
import { storedQueryApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';

// Helper to get a new copied name
// If it already has a copied name return with number instead
export const getFixedCopiedName = (name: string, end = ' - Copy') => {
  if (name.endsWith(end)) return `${name} 2`;
  if (name.includes(`${end} `) && name.match('^.+?\\d$')) {
    const nextNumber = (parseInt(name.split(' ').pop() || '', 10) || 1) + 1;
    const original = name.substring(0, name.indexOf(end));
    return `${original}${end} ${nextNumber}`;
  }
  return `${name}${end}`;
};

export const createNewQuery = async (model: QueryModel) => {
  const response = await storedQueryApi.create(model);
  if (isArdoqError(response)) {
    return response;
  }
  dispatchAction(
    createSearchQuerySuccess({
      storedQuery: response,
    }),
    getQueryEditorNamespace(model.backend, model.type)
  );
  return response;
};

export const executeGremlinSearch = (
  query: GremlinSearchQuery,
  searchType: SearchType,
  searchSource: GremlinSearchSource,
  queryParams?: any
) => {
  trackQueriedGremlinSearch({
    searchSource,
  });
  dispatchAction(
    queryGremlinSearch({
      query,
      queryParams: {
        size: INITIAL_LOAD_SIZE,
        from: 0,
        ...(queryParams || {}),
      },
    }),
    searchType
  );
};
