import {
  CloudProviderResourceType,
  ResourceTypesSummary,
} from '@ardoq/api-types/integrations';
import {
  ResourceType,
  ResourceTypesState,
} from 'integrations/cloudProviders/streams/resourceTypes/types';
import { byLocaleField } from 'integrations/common/utils/common';
import fp from 'lodash/fp';

const getFilteredResourceTypesSummary = (
  selectedResourceGroupNames: string[],
  selectedRegionIds: string[],
  resourcesSummary: ResourceTypesSummary[]
) =>
  resourcesSummary.filter(({ resourceGroup }) =>
    selectedResourceGroupNames.includes(resourceGroup)
  );

export const getFilteredResourceTypes = ({
  resourceTypesState,
  selectedResourceGroupNames,
  selectedRegionIds,
  resourcesSummary,
}: {
  resourceTypesState: ResourceTypesState;
  selectedResourceGroupNames: string[];
  selectedRegionIds: string[];
  resourcesSummary: ResourceTypesSummary[];
}) => {
  if (fp.isEmpty(resourcesSummary)) {
    return [];
  }

  const resourceTypesSummary = getFilteredResourceTypesSummary(
    selectedResourceGroupNames,
    selectedRegionIds,
    resourcesSummary
  );
  const types = resourceTypesSummary.map(({ type }) => type);
  const commonResourceTypes = fp.orderBy<ResourceType>(
    'name',
    'asc'
  )(
    resourceTypesState.resourceTypes.filter(
      resource => resource.type && types.includes(resource.type.toLowerCase())
    )
  );
  const customResourceTypes = fp.orderBy<ResourceType>(
    'name',
    'asc'
  )(resourceTypesState.resourceTypes.filter(resource => !resource.type));

  return [...commonResourceTypes, ...customResourceTypes];
};

/**
 * FIXME: From the cljs `azure` integration, There were two entries for the Resource Groups,
 * https://github.com/ardoq/ardoq-api/blob/5b6c40dd78a2f8da5f07df42d77a7db33f2a6c2c/src/ardoq/integrations/cloudprovider/azure/connector.clj#L20-L21
 * Both of these are referring to the same resource type.
 * So we need to hide them out in the new `azure-v3` integration to avoid showing duplicates.
 * It is not possible to remove them from the backend at this point, since they are still being used.
 * Backend ticket: https://ardoqcom.atlassian.net/browse/ARD-21014
 */
const RESOURCE_TYPE_IDS_TO_EXCLUDE = [
  // this is a duplicate of existing `resource-groups` type
  'vpcs',
];

/**
 * Get the selected and available resource types based on the selected resource type ids
 * @param resourceTypes List of all the resource types
 * @param selectedResourceTypeIds List of selected resource type ids
 * @returns An object containing the selected resource types and available resource types
 */
export function getSelectedAvailableResourceTypes(
  resourceTypes: CloudProviderResourceType[],
  selectedResourceTypeIds: string[]
) {
  const selectedResourceTypes = resourceTypes
    .filter(rt => selectedResourceTypeIds.includes(rt.id))
    .sort(byLocaleField('name'));

  const availableResourceTypes = resourceTypes.filter(
    rt =>
      !selectedResourceTypeIds.includes(rt.id) &&
      !RESOURCE_TYPE_IDS_TO_EXCLUDE.includes(rt.id)
  );

  return { selectedResourceTypes, availableResourceTypes };
}

/**
 * Convert the resource types to fields
 * @param resourceTypes List of resource types
 * @param resourceTypesSummary List of resource summary
 * @returns List of fields
 */
export function resourceTypesToFields(resourceTypes: ResourceType[]) {
  return resourceTypes.map(rt => ({
    name: rt.id,
    label: rt.type ? rt.type.toLowerCase() : rt.name,
  }));
}
