import { existingWorkspaces$ } from 'integrations/common/streams/workspaces/workspaces$';
import { byLocaleField } from 'integrations/common/utils/common';
import { combineLatest, map } from 'rxjs';
import { configurationState$ } from '../configurationState/configurationState$';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { ITPediaSchedule } from '@ardoq/api-types/integrations';

export const workspaceOptions$ = existingWorkspaces$.pipe(
  map(workspaces =>
    workspaces
      .map(workspace => ({
        name: workspace.name,
        id: workspace._id,
      }))
      .sort(byLocaleField('name'))
  )
);

export const unUsedWorkspaceOptions$ = combineLatest([
  workspaceOptions$,
  getSchedulesStream<ITPediaSchedule>('it-pedia'),
  configurationState$,
]).pipe(
  map(([workspaceOptions, { schedules }, configurationState]) => {
    /**
     * Existing workspaces for other enrichments
     */
    const existingEnrichmentWorkspaceIds = schedules
      .filter(enrichment => enrichment._id !== configurationState._id)
      .flatMap(enrichment => [
        enrichment.jobOptions.workspaces.products.id,
        enrichment.jobOptions.workspaces.vulnerabilities.id,
      ]);

    const filteredWorkspaces = workspaceOptions.filter(
      workspace => !existingEnrichmentWorkspaceIds.includes(workspace.id)
    );
    return filteredWorkspaces;
  })
);
