import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { PageContainer } from '../../../common/pages/layout';
import { default as DataHistoryComponent } from 'integrations/common/components/importsHistory/DataHistory';
import { Header } from 'integrations/dashboard/Header';
import { Integration } from 'integrations/types';
import { GhostButton } from '@ardoq/button';
import { ChevronLeftIcon } from '@ardoq/icons';

const Page = styled(PageContainer)`
  background: ${colors.white};
  min-height: 100vh;
`;

type DataHistoryPageProps = {
  integrations: Integration[];
  goBack: () => void;
};

export const History = ({ integrations, goBack }: DataHistoryPageProps) => (
  <Page>
    <Header
      integrations={integrations}
      primaryContent="Data history"
      secondaryContent="Integrations"
      secondaryButton={
        <GhostButton onClick={() => goBack()}>
          <ChevronLeftIcon /> Back to overview
        </GhostButton>
      }
    />
    <DataHistoryComponent goBack={goBack} />
  </Page>
);
