import { action$, extractPayload, ofType } from '@ardoq/rxbeach';
import { Observable, map, switchMap, tap } from 'rxjs';
import { api, useCaseApi } from '@ardoq/api';
import { requestLoadMetamodel } from 'useCases/actions';
import { tag } from 'rxjs-spy/operators';
import { dateRangeOperations } from '@ardoq/date-range';
import { APIMetaModelPreview, UseCaseStatus } from '@ardoq/api-types';
import { isArdoqError, Result } from '@ardoq/common-helpers';

const mapOnResult = (
  data: Result<APIMetaModelPreview>,
  callback: (
    metamodelPreview: APIMetaModelPreview
  ) => Result<APIMetaModelPreview>
): Result<APIMetaModelPreview> => (isArdoqError(data) ? data : callback(data));

const mergeDateRangeFieldLabelsInMetaModel = (
  metamodelPreview: APIMetaModelPreview
): APIMetaModelPreview => ({
  ...metamodelPreview,
  componentTypeFieldsAndTriples:
    metamodelPreview.componentTypeFieldsAndTriples.map(
      componentTypeWithFieldsAndTriples => ({
        ...componentTypeWithFieldsAndTriples,
        fields: dateRangeOperations.mergeDateRangeFieldLabels(
          componentTypeWithFieldsAndTriples.fields
        ),
        metamodelTriples:
          componentTypeWithFieldsAndTriples.metamodelTriples.map(
            metaModelTriple => ({
              ...metaModelTriple,
              fields: dateRangeOperations.mergeDateRangeFieldLabels(
                metaModelTriple.fields
              ),
            })
          ),
      })
    ),
});

export const metaModelPreview$: Observable<Result<APIMetaModelPreview>> =
  action$.pipe(
    ofType(requestLoadMetamodel),
    extractPayload(),
    switchMap(({ id, status }) =>
      status === UseCaseStatus.PENDING
        ? useCaseApi.fetchMetamodelPreview(id)
        : useCaseApi.fetchMetaModel(id)
    ),
    tap(response => api.logErrorIfNeeded(response)),
    map(response =>
      mapOnResult(response, mergeDateRangeFieldLabelsInMetaModel)
    ),
    tag('loadMetaModelPreview$')
  );
