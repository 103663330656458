import { Select } from '@ardoq/select';
import {
  APIResourcePermissionAttributes,
  ArdoqId,
  PermissionGroup,
} from '@ardoq/api-types';
import { CurrentUserState } from 'streams/currentUser/currentUser$';
import { FormSize } from '@ardoq/forms';
import { ZoneCommands } from '../types';
import { zonesOperations } from '../operations';
import { ZonesById } from '@ardoq/subdivisions';

export const GroupPermissionNewItem = ({
  groups,
  permissionsByZoneId,
  currentUser,
  zonesById,
  commands,
}: {
  groups: PermissionGroup[];
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>;
  currentUser: CurrentUserState;
  zonesById: ZonesById;
  commands: ZoneCommands;
}) => (
  <Select
    label="Select permission group"
    helperText="Select at least one group to configure permissions."
    placeholder="Type and select a group"
    formSize={FormSize.DEFAULT}
    options={zonesOperations.mapUserGroupsToSelectOptions(
      groups,
      permissionsByZoneId,
      zonesById
    )}
    onChange={option =>
      option &&
      commands.addZoneGroupPermission({
        label: option.value,
        displayName: option.label,
        currentUser,
        permissionsByZoneId,
      })
    }
  />
);
