import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { HeaderField } from '@ardoq/api-types';
import { SortableMultiselect } from '@ardoq/select';
import { FieldsWrapper } from '@ardoq/forms';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';
import { fieldInterface } from '@ardoq/field-interface';

const MAX_CUSTOM_HEADER_FIELDS = 6;

type CustomFieldsInSurveyOverviewProps = {
  typeId?: string;
  modelId: string;
  customHeaderFields: HeaderField[];
  updateCustomHeaderFields: (customHeaderFields: HeaderField[]) => void;
};

const CustomFieldsInSurveyOverview = ({
  typeId,
  modelId,
  customHeaderFields,
  updateCustomHeaderFields,
}: CustomFieldsInSurveyOverviewProps) => {
  const locale = getCurrentLocale();

  const fields = typeId
    ? dateRangeOperations.mergeDateTimeFieldsToDateRangeFields(
        fieldInterface
          .getFieldsByComponentType(typeId, modelId)
          .filter(field => field.type !== 'User')
      ).fields
    : [];

  const options = [
    {
      value: 'lastModifiedByName',
      label: 'Last Updated By',
    },
    {
      value: 'createdByName',
      label: 'Created By',
    },
    {
      value: 'last-updated',
      label: 'Last Updated',
    },
    {
      value: 'created',
      label: 'Created',
    },
    {
      value: 'surveyComponentStatus',
      label: 'Task Status',
    },
    {
      value: 'description',
      label: 'Description',
    },
    {
      value: 'component-key',
      label: 'Ardoq ID',
    },
    ...fields.flatMap(field =>
      isDateRangeFieldType(field.type)
        ? dateRangeOperations.splitDateRangeFieldIntoStartAndEndSelectOptions(
            field
          )
        : {
            label: field.label || field.name,
            value: field.name,
          }
    ),
  ].sort((a, b) => localeCompare(a.label, b.label, locale));

  const selectedCustomHeaderFields = customHeaderFields.map(field => ({
    value: field.name,
    label: field.label,
  }));

  const showWarning = customHeaderFields.length === MAX_CUSTOM_HEADER_FIELDS;

  return (
    <FieldsWrapper>
      <SortableMultiselect
        label="Add field columns to component overview"
        isSearchable
        placeholder="Add..."
        options={options}
        value={selectedCustomHeaderFields}
        onChange={selected => {
          const updatedSelected = selected
            ?.filter((_, i) => i <= MAX_CUSTOM_HEADER_FIELDS - 1)
            .map(headerField => ({
              name: headerField.value as string,
              label: headerField.label,
            }));

          updateCustomHeaderFields(updatedSelected ?? []);
        }}
        warningMessage={
          showWarning
            ? 'You have reached the maximum number of fields. Please remove some before trying to add more.'
            : undefined
        }
        hintMessage={
          !showWarning
            ? `The component name will always be the first column shown. You can add up to ${MAX_CUSTOM_HEADER_FIELDS} more fields.`
            : undefined
        }
        popoverHelpContent={`You can add fields as columns in the component overview. The
            component overview can be sorted by these fields. Add those that are
            relevant for your respondents, and drag to order.`}
        dataTestId="custom-fields-select"
      />
    </FieldsWrapper>
  );
};

export default CustomFieldsInSurveyOverview;
