import { NavigatorContainerProps } from '../components/PropTypes';
import { TRANSITION_DURATION } from '../utils/consts';
import { distinctUntilChanged, filter, map, Observable, tap } from 'rxjs';
import { catchErrorLogWithMessageAndContinue } from 'streams/utils/streamOperators';

export const getResetAnimationRoutine = (
  navigator$: Observable<NavigatorContainerProps>
) =>
  navigator$.pipe(
    map(({ dragGhostProps: { onTransitionEnd } }) => onTransitionEnd),
    distinctUntilChanged(),
    filter(Boolean),
    tap(onTransitionEnd =>
      // There is a transition end event listener, this is a fallback to ensure
      // that the animation style is cleared even if the transition end event is
      // not fired.
      window.setTimeout(onTransitionEnd, TRANSITION_DURATION)
    ),
    catchErrorLogWithMessageAndContinue('Error in resetAnimation routine')
  );

// ;
