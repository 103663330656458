import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { EMPTY, combineLatest, map, of, switchMap } from 'rxjs';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { workspaces$ } from 'integrations/common/streams/workspaces/workspaces$';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { isResourceCreationDisabled } from 'integrations/common/streams/transferState/utils';
import { getTabularMappingConstraintsStream } from 'integrations/common/streams/tabularMappingConstraints/getTabularMappingConstraintsStream';

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      currentTableId: getActiveIntegrationStream(integrationId).pipe(
        map(ci => ci.currentTableId)
      ),
      tabularMapping: getTabularMappingStream(integrationId),
      workspaces: workspaces$,
      integrationTermsDictionary:
        getIntegrationTermsDictionaryStream(integrationId),
      transferState: getTransferStateStream(integrationId),
      tabularMappingConstraints:
        getTabularMappingConstraintsStream(integrationId),
    });
  }),
  switchMap(
    ({
      integrationId,
      tabularMapping,
      currentTableId,
      workspaces,
      integrationTermsDictionary,
      transferState,
      tabularMappingConstraints,
    }) => {
      if (!currentTableId) return EMPTY;

      const currentTableMappingConstraints =
        tabularMappingConstraints[currentTableId];

      return of({
        integrationId,
        currentTableId,
        tableMapping: tabularMapping[currentTableId],
        workspaces: {
          used: workspaces.used,
          existing: workspaces.existing.map(({ _id, name }) => ({
            id: _id,
            name,
          })),
        },
        integrationTermsDictionary,
        isCreationDisabled: isResourceCreationDisabled(transferState),
        disabledReferenceType: Boolean(
          currentTableMappingConstraints?.disabledReferenceType
        ),
        disabledReferenceTypePopover:
          currentTableMappingConstraints?.disabledReferenceTypeReason,
      });
    }
  )
);
