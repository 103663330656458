import { Observable, combineLatest, map, switchMap } from 'rxjs';
import allWorkspaces$ from 'streams/workspaces/workspaces$';
import { getTabularMappingStream } from '../tabularMappings/getTabularMappingStream';
import { Workspace as OrgWorkspace } from '@ardoq/api-types';
import { extractUsedWorkspaces } from './util';
import { integrationId$ } from '../integrationId/integrationId$';

export const existingWorkspaces$: Observable<OrgWorkspace[]> =
  allWorkspaces$.pipe(
    map(({ byId, sortIds }) => {
      return sortIds.map(id => byId[id]);
    })
  );

const usedWorkspaces$ = integrationId$.pipe(
  switchMap(integrationId => {
    return getTabularMappingStream(integrationId);
  }),
  map(extractUsedWorkspaces)
);

export const workspaces$ = combineLatest([
  existingWorkspaces$,
  usedWorkspaces$,
]).pipe(
  map(([existing, used]) => ({
    used,
    existing,
  }))
);
