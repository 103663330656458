import {
  ParentMapping,
  MissingParentComponentsStrategy,
} from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import {
  getParentIfComponentIsMissingName,
  isParentFormatDisabled,
} from 'integrations/common/pages/tabularConfigMapping/utils';
import { Select } from '@ardoq/select';
import { Icon, IconName } from '@ardoq/icons';

const getParentComponentIsMissingOptions = (
  format: ParentMapping['parentFormat']
): Array<{
  value: MissingParentComponentsStrategy;
  label: string;
}> =>
  [
    MissingParentComponentsStrategy.ERROR,
    MissingParentComponentsStrategy.CREATE_TOP_LEVEL,
  ].map(strategy => ({
    value: strategy,
    label: getParentIfComponentIsMissingName(strategy),
    isDisabled: isParentFormatDisabled(format),
    popoverContent: isParentFormatDisabled(format)
      ? 'Please select another option above to make this enabled'
      : '',
    rightContent: isParentFormatDisabled(format) ? (
      <Icon iconName={IconName.WARNING} />
    ) : null,
  }));

type ParentIfComponentIsMissingProps = {
  columnMapping: ParentMapping;
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
};

export const ParentMissingComponentsStrategy = ({
  columnMapping,
  onMapping,
}: ParentIfComponentIsMissingProps) => {
  return (
    <>
      <Select
        dataTestId="tabularMapping-parent-if-component-is-missing--select"
        label="If parent component is missing"
        value={columnMapping.missingComponents}
        options={getParentComponentIsMissingOptions(columnMapping.parentFormat)}
        onValueChange={value => {
          if (value) {
            onMapping({
              ...columnMapping,
              missingComponents: value,
            });
          }
        }}
      />
    </>
  );
};
