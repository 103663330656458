import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { SetVisibleAsset, setVisibleAsset } from './actions';
import { AssetsViewState } from 'assets/types';

const defaultState: AssetsViewState = {
  assetType: null,
  assetId: null,
};

const setVisibleAssetReducer = (
  currentState: AssetsViewState,
  { assetType, assetId }: SetVisibleAsset
): AssetsViewState => ({
  ...currentState,
  assetType,
  assetId,
});

const reducers = [reducer(setVisibleAsset, setVisibleAssetReducer)];

export const assetsViewState$ = persistentReducedStream<AssetsViewState>(
  'assetsViewState$',
  defaultState,
  reducers
);
