import { actionCreator } from '@ardoq/rxbeach';
import { LoadSlideErrorTypes } from './types';
import { ArdoqId, SlideTypes, ViewIds } from '@ardoq/api-types';
import { viewSettingsConsts } from '@ardoq/view-settings';

export type PayloadLoadSlide = {
  id: ArdoqId;
  isScenario: boolean;
  metamodelId: ArdoqId | undefined;
  type: SlideTypes;
  viewId: ViewIds | undefined;
  viewState:
    | Record<keyof typeof viewSettingsConsts, string>
    | Record<string, never>;
  hasFullAccess: boolean;
};

export const loadSlide = actionCreator<PayloadLoadSlide>(
  '[viewPane] LOAD_SLIDE'
);

export const loadVisualizationSlideSuccess = actionCreator(
  '[viewPane] LOAD_VISUALIZATION_SLIDE_SUCCESS'
);

type PayloadLoadSlideError = {
  error: LoadSlideErrorTypes;
};
export const loadSlideError = actionCreator<PayloadLoadSlideError>(
  '[viewPane] LOAD_SLIDE_ERROR'
);

export const activeSlideIsOutdated = actionCreator(
  '[viewPane] ACTIVE_SLIDE_IS_OUTDATED'
);

type LegendPayload = {
  legendActive: boolean;
  viewId: ViewIds;
};
export const toggleLegend = actionCreator<LegendPayload>(
  '[viewPane] SET_LEGEND_ACTIVE'
);
