import WorkspaceModelBrowser from 'components/WorkspaceModelBrowser/WorkspaceModelBrowser';
import { Branch } from 'components/DiffMergeTable/Branch';
import PopoverDataProvider, { ProvidedProps } from './PopoverDataProvider';
import styled from 'styled-components';
import { WorkspaceLabel } from './atoms';
import { getWorkspaceByEntity } from 'components/DiffMergeTable/utils';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { PopoverRenderer } from '@ardoq/popovers';

const WorkspaceHeader = styled(WorkspaceLabel)`
  margin-bottom: 16px;
`;

type ComponentsModelPopoverProps = {
  branch: Branch;
  modelId: ArdoqId;
  highlightedComponentTypeId?: ArdoqId;
};

const ComponentsModelPopover = ({
  modelId,
  highlightedComponentTypeId,
  enhancedScopeData,
}: Omit<ComponentsModelPopoverProps, 'branch'> & ProvidedProps) => {
  const model = enhancedScopeData.modelsById[modelId];
  if (!model) return <></>;

  const workspace = getWorkspaceByEntity(
    modelId,
    APIEntityType.MODEL,
    enhancedScopeData
  );

  return (
    <div style={{ minWidth: 200 }}>
      {workspace && <WorkspaceHeader workspace={workspace} />}
      <WorkspaceModelBrowser
        highlightedComponentTypeId={highlightedComponentTypeId}
        root={model.root}
      />
    </div>
  );
};

export const diffMergeComponentModelPopoverRenderer: PopoverRenderer =
  target => {
    const {
      diffMergePopoverBranch: branch,
      diffMergePopoverModelId: modelId,
      diffMergePopoverHighlightedComponentTypeId: highlightedComponentTypeId,
    } = (target as HTMLElement)?.dataset || {};

    if (!modelId || !highlightedComponentTypeId || !branch) return null;
    return (
      <PopoverDataProvider branch={branch as Branch}>
        {data => (
          <ComponentsModelPopover
            modelId={modelId}
            highlightedComponentTypeId={highlightedComponentTypeId}
            {...data}
          />
        )}
      </PopoverDataProvider>
    );
  };
