import { reducer } from '@ardoq/rxbeach';
import {
  getWarningMessageFromAudiencePreview,
  toComponentMappedPreviewAudience,
} from 'broadcasts/utils';
import { PredefinedAudienceDialogStreamShape } from './types';
import { toggleQuery } from './utils';
import {
  fetchPredefinedAudiencePreview,
  notifyFailedToFetchPredefinedAudiencePreview,
  openExistingPredefinedAudienceDialog,
  openNewPredefinedAudienceDialog,
  setPredefinedAudiencePreview,
  setPredefinedReferenceTypeQueryOptions,
  setTableFilter,
  togglePredefinedAudienceQueryCheckbox,
} from './actions';
import type {
  PredefinedQueryOption,
  APIAudiencePreviewResponse,
} from '@ardoq/api-types';

export const defaultState: PredefinedAudienceDialogStreamShape = {
  queryOptions: [],
  selectedQueryOptions: [],
  isUpdatingExistingAudience: false,
  formIsValid: false,
  audiencePreview: [],
  isFetchingAudiencePreview: false,
  failedToFetchAudiencePreview: false,
  warningMessage: null,
  tableFilter: '',
};

const handleSetPredefinedReferenceTypeQueryOptions = (
  state: PredefinedAudienceDialogStreamShape,
  queryOptions: PredefinedQueryOption[]
): PredefinedAudienceDialogStreamShape => ({
  ...state,
  queryOptions,
});

const handleOpenExistingPredefinedAudienceDialog = (
  state: PredefinedAudienceDialogStreamShape,
  selectedQueryOptions: PredefinedQueryOption[]
): PredefinedAudienceDialogStreamShape => ({
  ...state,
  selectedQueryOptions,
  isUpdatingExistingAudience: true,
  formIsValid: Boolean(selectedQueryOptions.length),
});

const handleOpenNewPredefinedAudienceDialog = (
  state: PredefinedAudienceDialogStreamShape
): PredefinedAudienceDialogStreamShape => ({
  ...state,
  selectedQueryOptions: [],
  isUpdatingExistingAudience: false,
  formIsValid: false,
});
const handleTogglePredefinedAudienceQueryCheckbox = (
  state: PredefinedAudienceDialogStreamShape,
  queryOptionToToggle: PredefinedQueryOption
): PredefinedAudienceDialogStreamShape => {
  const selectedQueryOptions = toggleQuery(
    state.selectedQueryOptions,
    queryOptionToToggle
  );
  return {
    ...state,
    selectedQueryOptions,
    formIsValid: Boolean(selectedQueryOptions.length),
  };
};

const handleFetchPredefinedAudiencePreview = (
  state: PredefinedAudienceDialogStreamShape
): PredefinedAudienceDialogStreamShape => ({
  ...state,
  isFetchingAudiencePreview: true,
  failedToFetchAudiencePreview: false,
});

const handleSetPredefinedAudiencePreview = (
  state: PredefinedAudienceDialogStreamShape,
  apiAudiencePreview: APIAudiencePreviewResponse
): PredefinedAudienceDialogStreamShape => {
  const mappedAudiencePreview =
    toComponentMappedPreviewAudience(apiAudiencePreview);
  return {
    ...state,
    audiencePreview: mappedAudiencePreview,
    isFetchingAudiencePreview: false,
    failedToFetchAudiencePreview: false,
    warningMessage: getWarningMessageFromAudiencePreview(mappedAudiencePreview),
  };
};

const handleNotifyFailedToFetchPredefinedAudiencePreview = (
  state: PredefinedAudienceDialogStreamShape
): PredefinedAudienceDialogStreamShape => ({
  ...state,
  audiencePreview: [],
  isFetchingAudiencePreview: false,
  failedToFetchAudiencePreview: true,
  warningMessage: null,
});

const handleSetTableFilter = (
  state: PredefinedAudienceDialogStreamShape,
  tableFilter: string
): PredefinedAudienceDialogStreamShape => ({
  ...state,
  tableFilter,
});

export const reducers = [
  reducer(
    setPredefinedReferenceTypeQueryOptions,
    handleSetPredefinedReferenceTypeQueryOptions
  ),
  reducer(
    openExistingPredefinedAudienceDialog,
    handleOpenExistingPredefinedAudienceDialog
  ),
  reducer(
    openNewPredefinedAudienceDialog,
    handleOpenNewPredefinedAudienceDialog
  ),
  reducer(
    togglePredefinedAudienceQueryCheckbox,
    handleTogglePredefinedAudienceQueryCheckbox
  ),
  reducer(fetchPredefinedAudiencePreview, handleFetchPredefinedAudiencePreview),
  reducer(setPredefinedAudiencePreview, handleSetPredefinedAudiencePreview),
  reducer(
    notifyFailedToFetchPredefinedAudiencePreview,
    handleNotifyFailedToFetchPredefinedAudiencePreview
  ),
  reducer(setTableFilter, handleSetTableFilter),
];
