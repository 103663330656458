import {
  FieldQuestionArgs,
  MaybePersistedFieldOrAttributeQuestion,
} from 'surveyAdmin/types';
import { getQuestionId, isReadonlyField } from './utils';
import DependentQuestionsSection from './DependentQuestions/DependentQuestionsSection';
import {
  confirmDescriptionChangeModal,
  confirmFieldChangeModal,
} from 'surveyAdmin/SurveyModals';
import FieldQuestionConfigurator from './FieldQuestionConfigurator';
import FieldSelect from './FieldSelect';
import {
  APIFieldType,
  BooleanOperator,
  isEntity,
  PartiallyPersistedConditionalQuestion,
  PersistedAttributeQuestion,
  PersistedFieldQuestion,
  PersistedFieldSubQuestion,
  SurveyQuestionType,
  SurveyQuestionValidator,
  UnpersistedAttributeQuestion,
  UnpersistedConditionalFieldQuestion,
  UnpersistedFieldQuestion,
  UnpersistedFieldSubQuestion,
} from '@ardoq/api-types';
import { FIELD_EXPLANATION } from '../SurveyEditor/consts';
import { isEqual, omit } from 'lodash';
import { FieldQuestionData } from './types';
import {
  isFieldQuestion,
  isConditionalQuestion,
  isAttributeQuestion,
  isParentQuestion,
} from './questionPredicates';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import surveyEditor$ from 'surveyAdmin/SurveyEditor/streams/surveyEditor$';
import { shouldDisplayErrorMessageForField } from 'surveyAdmin/surveyUtil';
import { setSurveyQuestionFieldHasBeenInteractedWith } from 'surveyAdmin/SurveyEditor/streams/actions';
import { hasRequiredValidator } from 'surveyAdmin/SurveyEditor/utils';
import { toggleValidator } from './RequiredField';

const confirmDescriptionChange = async (
  currentDescription: string,
  fieldDescription: string | undefined
) => {
  return fieldDescription &&
    !isEqual(fieldDescription, currentDescription) &&
    currentDescription
    ? Boolean(await confirmDescriptionChangeModal())
    : true;
};

const updateValidatorsIfReadonlyAndRequired = (
  question: MaybePersistedFieldOrAttributeQuestion,
  readonly: boolean
) => {
  const isMarkedAsRequired = hasRequiredValidator(question);
  if (!readonly || !isMarkedAsRequired) return question.validators;
  return toggleValidator({ type: 'required' }, false, question.validators);
};

const FieldQuestion = ({
  question,
  questionValidations = { questionValidations: [] },
  fields,
  attributes,
  workspaceId,
  parentQuestionIsReadonly,
  questions,
  surveyComponentTypeId,
  isComponent,
  permissionsContext,
  surveyWorkspaceId,
  allowComponentCreation,
  surveyIsPermissionDivisionsAware,
  hasSurveyResponseApprovalsFeature,
  updateQuestion,
  fieldHasBeenInteractedWith,
}: FieldQuestionArgs) => {
  const filteredFields = fields.filter(
    field => field.type !== APIFieldType.USER
  );

  const selectedField = isFieldQuestion(question)
    ? question.properties.fieldName
    : question.properties.attributeName;

  const handleAddDependentQuestion = (
    dependentQuestion: UnpersistedFieldSubQuestion
  ) => {
    if (isAttributeQuestion(question)) return;
    updateQuestion({
      ...question,
      hasConditionals: true,
      properties: {
        ...question.properties,
        questions: [
          ...(isConditionalQuestion(question)
            ? question.properties.questions
            : []),
          dependentQuestion,
        ],
      },
    } as
      | UnpersistedConditionalFieldQuestion
      | PartiallyPersistedConditionalQuestion);
  };

  const handleUpdateConditionalFieldQuestion = async ({
    type,
    readonly,
    value,
    description,
    label,
  }: FieldQuestionData) => {
    const confirmFieldChange = Boolean(await confirmFieldChangeModal());
    if (!confirmFieldChange) return;
    const updatedProperties =
      type === SurveyQuestionType.ATTRIBUTE
        ? {
            fieldName: undefined,
            attributeName: value,
          }
        : {
            fieldName: value,
            attributeName: undefined,
          };
    const shouldChangeDescription = await confirmDescriptionChange(
      question.description,
      description
    );
    updateQuestion({
      ...omit(question, 'hasConditionals'),
      type,
      readonly,
      label,
      description:
        shouldChangeDescription && description
          ? description
          : question.description,
      hasConditionals: undefined,
      properties: {
        ...omit(question.properties, 'questions'),
        ...updatedProperties,
      },
    } as
      | PersistedFieldQuestion
      | UnpersistedFieldQuestion
      | PersistedAttributeQuestion
      | UnpersistedAttributeQuestion);
  };

  const handleUpdateAttributeQuestion = async ({
    readonly,
    value,
    description,
    label,
    validators,
  }: Omit<FieldQuestionData, 'type'> & {
    validators?: SurveyQuestionValidator[];
  }) => {
    const shouldChangeDescription = await confirmDescriptionChange(
      question.description,
      description
    );
    updateQuestion({
      ...question,
      type: SurveyQuestionType.ATTRIBUTE,
      readonly,
      label,
      description:
        shouldChangeDescription && description
          ? description
          : question.description,
      properties: {
        ...question.properties,
        ...{ attributeName: value, fieldName: undefined },
      },
      validators,
    });
  };

  const handleUpdateFieldQuestion = async ({
    type,
    readonly,
    value,
    description,
    label,
  }: FieldQuestionData) => {
    if (isConditionalQuestion(question)) {
      // conditional questions cannot be set to required
      handleUpdateConditionalFieldQuestion({
        type,
        readonly,
        value,
        description,
        label,
      });
      return;
    }
    const updatedValidators = updateValidatorsIfReadonlyAndRequired(
      question,
      readonly
    );
    if (type === SurveyQuestionType.ATTRIBUTE) {
      handleUpdateAttributeQuestion({
        readonly,
        value,
        description,
        label,
        validators: updatedValidators,
      });
      return;
    }
    const shouldChangeDescription = await confirmDescriptionChange(
      question.description,
      description
    );
    updateQuestion({
      ...question,
      type: SurveyQuestionType.FIELD,
      readonly,
      label,
      description:
        shouldChangeDescription && description
          ? description
          : question.description,
      properties: {
        ...question.properties,
        ...{ fieldName: value, attributeName: undefined },
      },
      validators: updatedValidators,
    });
  };

  const updateDependentQuestions = (
    dependentQuestions: (
      | PersistedFieldSubQuestion
      | UnpersistedFieldSubQuestion
    )[]
  ) => {
    if (!isConditionalQuestion(question)) return;
    updateQuestion({
      ...question,
      properties: {
        ...question.properties,
        questions: !dependentQuestions.length ? undefined : dependentQuestions,
      },
    });
  };

  const isParentField = isParentQuestion(question);
  const advancedSearchLimiter = isParentField
    ? (question.properties.advancedSearchLimiter ?? {
        condition: BooleanOperator.AND,
        rules: [],
      })
    : undefined;
  const showAdvancedOptions = Boolean(
    isParentField && question.properties.advancedSearchLimiter?.rules
  );

  const shouldShowFieldSelectErrorIfPresent = shouldDisplayErrorMessageForField(
    isEntity(question),
    null,
    fieldHasBeenInteractedWith.questions[getQuestionId(question)]
      ?.questionViewHasBeenClosed ||
      fieldHasBeenInteractedWith.questions[getQuestionId(question)]
        ?.questionsSectionFieldSelectField
  );
  return (
    <>
      <FieldSelect
        question={question}
        fields={filteredFields}
        attributes={attributes}
        questions={questions}
        workspaceId={workspaceId}
        questionValidations={questionValidations}
        parentQuestionIsReadonly={parentQuestionIsReadonly}
        updateFieldQuestion={handleUpdateFieldQuestion}
        popoverHelpContent={FIELD_EXPLANATION}
        shouldShowErrorMessagesIfPresent={shouldShowFieldSelectErrorIfPresent}
        hintMessage="Required"
        onBlur={() =>
          dispatchAction(
            setSurveyQuestionFieldHasBeenInteractedWith({
              questionKey: getQuestionId(question),
              fieldKey: 'questionsSectionFieldSelectField',
            })
          )
        }
      />
      {selectedField && (
        <FieldQuestionConfigurator
          showRequiredField={Boolean(
            !question.readonly ||
              (isAttributeQuestion(question) &&
                question.properties.attributeName !== 'name')
          )}
          fieldIsReadonly={isReadonlyField(selectedField, workspaceId)}
          question={question}
          updateQuestion={updateQuestion}
          parentQuestionIsReadonly={parentQuestionIsReadonly}
          advancedSearchLimiter={advancedSearchLimiter}
          showAdvancedOptions={showAdvancedOptions}
          surveyWorkspaceId={surveyWorkspaceId}
          questionValidations={questionValidations}
          allowComponentCreation={allowComponentCreation}
        />
      )}
      {isFieldQuestion(question) && isComponent && (
        <DependentQuestionsSection
          question={question}
          fields={filteredFields}
          attributes={attributes}
          questions={questions}
          workspaceId={workspaceId}
          questionValidations={questionValidations.subQuestionValidations}
          surveyComponentTypeId={surveyComponentTypeId}
          permissionsContext={permissionsContext}
          surveyIsPermissionDivisionsAware={surveyIsPermissionDivisionsAware}
          hasSurveyResponseApprovalsFeature={hasSurveyResponseApprovalsFeature}
          addDependentQuestion={handleAddDependentQuestion}
          updateQuestion={updateDependentQuestions}
        />
      )}
    </>
  );
};

export default connect(FieldQuestion, surveyEditor$);
