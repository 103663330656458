import { Maybe } from '@ardoq/common-helpers';

import { RoutePath } from './types';
import { actionCreatorWithIntegrationId } from '../utils/actionCreatorWithIntegrationId';

const NAMESPACE = '[integrations] NAVIGATION';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

type NavigateToPathPayload = {
  path: RoutePath | null;
  source?: string | null;
};

export const navigateToPath =
  actionCreatorWithIntegrationId<NavigateToPathPayload>(
    getActionName('NAVIGATE_TO_PATH')
  );

type NavigateToConfigurePayload = {
  tableId: Maybe<string>;
};

export const navigateToConfigure =
  actionCreatorWithIntegrationId<NavigateToConfigurePayload>(
    getActionName('NAVIGATE_TO_CONFIGURE')
  );

export const navigateToSelectData = actionCreatorWithIntegrationId(
  getActionName('NAVIGATE_TO_SELECT_DATA')
);

export const navigateToReview = actionCreatorWithIntegrationId(
  getActionName('NAVIGATE_TO_REVIEW')
);

export const navigateToOverview = actionCreatorWithIntegrationId(
  getActionName('NAVIGATE_TO_OVERVIEW')
);
