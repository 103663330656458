import { dispatchAction, connect } from '@ardoq/rxbeach';
import { ModalSize, confirm, modal } from '@ardoq/modal';
import { ConfigsSection } from 'integrations/common/components/configsSection/ConfigsSection';
import { testId } from 'integrations/common/testUtils/testId';
import { Maybe } from '@ardoq/common-helpers';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import {
  selectMappingConfig,
  unselectMappingConfig,
} from 'integrations/common/streams/tabularMappings/actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { viewModel$ } from './viewModel$';
import OverviewModal from 'integrations/common/modals/overviewModal/OverviewModal';
import { Box } from '@ardoq/layout';

const ConfigsComponent = ({
  integrationId,
  selectedConfigId,
  transferConfigs,
  isEmptyTabularMapping,
}: {
  integrationId: IntegrationId;
  selectedConfigId: Maybe<string>;
  transferConfigs: SavedTransferConfig[];
  isEmptyTabularMapping: boolean;
}) => {
  const onMappingConfigSelect = (
    configId: SavedTransferConfig['_id'] | null
  ) => {
    const config = transferConfigs.find(m => m._id === configId);
    if (!config) {
      return;
    }
    dispatchAction(selectMappingConfig({ integrationId, config }));
  };

  // TODO: consider moving confirm action to a routine
  const onConfigClear = async () => {
    const result = await confirm({
      modalSize: ModalSize.XS,
      title: 'Clear current configuration',
      subtitle:
        'This will clear everything you have configured for all of these worksheets.',
      confirmButtonTitle: 'Clear',
      cancelButtonTitle: 'Cancel',
    });

    if (result) {
      dispatchAction(unselectMappingConfig({ integrationId }));
    }
  };

  const onOverviewClick = () => {
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'OPENED_WORKSHEET_OVERVIEW',
      })
    );
    modal(closeModal => <OverviewModal onClose={() => closeModal(null)} />);
  };

  return (
    <Box {...testId('tabularMapping-configs-container')} width="full">
      <ConfigsSection
        selectedConfigId={selectedConfigId}
        transferConfigs={transferConfigs}
        isEmptyTabularMapping={isEmptyTabularMapping}
        onMappingConfigSelect={onMappingConfigSelect}
        onConfigClear={onConfigClear}
        onOverviewClick={onOverviewClick}
      />
    </Box>
  );
};

export const TransferConfigs = connect(ConfigsComponent, viewModel$);
