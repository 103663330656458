import { APIViewpointAttributes, ArdoqId } from '@ardoq/api-types';
import traversals$ from 'streams/traversals$';
import { traversalStateOperations } from 'streams/traversals/traversalsStateOperations';

const getById = (id: ArdoqId): APIViewpointAttributes | null => {
  return traversalStateOperations.getById(traversals$.state, id);
};

export const traversalInterface = {
  getById,
};
