import {
  Path,
  ArdoqId,
  ReferenceDirection,
  type DirectedTriple,
  type PathCollapsingRule,
  DirectedTripleWithFiltersWithGraphIds,
  FEParentChildReferenceAttributes,
  TripleDirection,
  PathCollapsingRuleNonNullablePath,
  DirectedTripleWithFilters,
  CollapsingPathRuleMatch,
  PathMatch,
} from '@ardoq/api-types';
import { returnTrue, ExcludeFalsy } from '@ardoq/common-helpers';
import type {
  GraphModelShape,
  ComponentPath,
  HierarchyOfLinkedComponentsWithDirection,
} from '@ardoq/data-model';
import {
  GraphEdge,
  GraphEdgeWithMetaData,
  GraphInterface,
  GraphNodeWithMetaData,
} from '@ardoq/graph';
import { logError } from '@ardoq/logging';
import { countBy, groupBy, last, uniqBy } from 'lodash';
import { getId } from 'viewpointBuilder/getId';
import { getTripleId } from 'viewpointBuilder/traversals/getTripleId';
import { pathToKey } from 'viewpointBuilder/traversals/pathToKey';
import {
  NamedDirectedTriple,
  TraversalBuilderState,
  TripleOption,
  TripleOptionReplacement,
  VirtualEdgeState,
} from 'viewpointBuilder/types';
import { processPathCollapsingRules } from './processPathCollapsingRules';
import { CollapsePathsArgs } from './pathAndGraphTypes';

export const pathsToFilters = (
  graphInterface: GraphInterface,
  paths: Path[] | null,
  workspaceIds: ArdoqId[]
) => {
  const pathToFilters = getPathToFilters(graphInterface, workspaceIds);
  return paths?.map(pathToFilters) ?? [];
};

export type PathToFilters = (path: Path) => {
  sourceFilter: (id: ArdoqId) => boolean;
  targetFilter: (id: ArdoqId) => boolean;
  isParentChildReference: boolean;
  referenceFilter: (id: ArdoqId) => boolean;
  direction: ReferenceDirection;
}[];

export const getPathToFilters =
  (graphInterface: GraphInterface, workspaceIds: ArdoqId[]): PathToFilters =>
  (path: Path) =>
    path.map(({ sourceType, targetType, referenceType, direction }) => {
      const isParentChildReference = referenceType === 'ardoq_parent';
      return {
        sourceFilter: graphInterface.getComponentTypeNameFilter(
          sourceType,
          workspaceIds
        ),
        targetFilter: graphInterface.getComponentTypeNameFilter(
          targetType,
          workspaceIds
        ),
        isParentChildReference,
        referenceFilter: isParentChildReference
          ? returnTrue
          : graphInterface.getReferenceTypeNameFilter(
              referenceType,
              workspaceIds
            ),
        direction:
          direction === 'outgoing'
            ? ReferenceDirection.OUTGOING
            : ReferenceDirection.INCOMING,
      };
    });

type PathAsFilters = {
  sourceFilter: (id: string) => boolean;
  targetFilter: (id: string) => boolean;
  referenceFilter: (id: string) => boolean;
  direction: ReferenceDirection;
  isParentChildReference: boolean;
};
/**
 * In a first step we get all the paths according to the meta model traversals.
 * In a second step we transform these paths to a hierarchy (or tree).
 */
export const getAllPaths = (
  graphInterface: GraphInterface,
  traversals: {
    startSet: string[];
    pathsAsFilters: PathAsFilters[][] | null;
  }[],
  graphModel: GraphModelShape
) => {
  const idToPathsDicts: Record<string, ComponentPath[]>[] = traversals.map(
    ({ startSet, pathsAsFilters }) => {
      const tuples: [string, ComponentPath[]][] = startSet.map(componentId => {
        const allPaths =
          pathsAsFilters?.flatMap(pathAsFilters =>
            getPathsWithFilters({
              graphInterface,
              pathAsFilters,
              graphModel,
              componentId,
            })
          ) ?? [];
        /**
         * Each item in the path list describes the connection to the previous
         * linked component in the list. So we remove the first item,
         * which is the start component (the direction is unset, and there
         * is no reference id because the start component is not linked to a
         * previous component). We only need the according id so that we can
         * group the paths by the start components.
         */
        return [componentId, allPaths.map(path => path.slice(1))];
      });
      return Object.fromEntries(tuples);
    }
  );

  return idToPathsDicts.reduce<
    Record<
      string,
      {
        componentId: string;
        referenceId: string;
        direction: ReferenceDirection;
        isParentChildReference: boolean;
      }[][]
    >
  >((acc, dict) => {
    Object.entries(dict).forEach(([id, paths]) => {
      acc[id] = acc[id] ? [...acc[id], ...paths] : paths;
    });
    return acc;
  }, {});
};
const reducePathsWithFilters = (
  graphInterface: GraphInterface,
  graphModel: GraphModelShape
) => {
  const visitedReferences = new Set<string>();
  return (
    paths: ComponentPath[],
    {
      direction,
      sourceFilter,
      targetFilter,
      referenceFilter,
      isParentChildReference,
    }: PathAsFilters,
    index: number
  ) => {
    const visitedInStep = new Set<string>();
    const newPathsFromBFSStep = paths.flatMap(path => {
      /**
       * This is the core part of the transformation. For each path we
       * found so far we take the tail component and get all linked components
       * from the graph model. We filter the linked components based on the
       * filters we got from the meta model traversals. From each linked
       * filtered component we create a new path. As a simplified example
       * for a single path:
       * current path (with tail c): a b c
       * all linked components of c: e f g h k
       * the filtered linked components: e g h
       * which give the new paths: a b c e, a b c g, a b c h
       */
      const tail = path[index];
      // If the previous step resulted in an empty list of new tails,
      // it means this path cannot be extended further and is considered complete.
      if (!tail) return [path];
      const { componentId: tailComponentId } = tail;

      const [allLinkedComponents, componentFilter, componentTailFilter] =
        direction === ReferenceDirection.OUTGOING
          ? [
              getCandidates({
                graphInterface,
                graphModel,
                isParentChildReference,
                tailComponentId,
                isOutgoing: true,
              }),
              targetFilter,
              sourceFilter,
            ]
          : [
              getCandidates({
                graphInterface,
                graphModel,
                isParentChildReference,
                tailComponentId,
                isOutgoing: false,
              }),
              sourceFilter,
              targetFilter,
            ];

      if (!componentTailFilter(tailComponentId)) return [path];

      const newTails = allLinkedComponents
        .filter(({ referenceId }) => !visitedReferences.has(referenceId))
        .filter(
          ({ componentId, referenceId }) =>
            componentFilter(componentId) && referenceFilter(referenceId)
        );
      newTails.forEach(({ referenceId }) => visitedInStep.add(referenceId));

      if (newTails.length === 0) return [path];

      return newTails.map(({ componentId, referenceId }) => [
        ...path,
        { componentId, referenceId, direction, isParentChildReference },
      ]);
    });
    visitedInStep.forEach(referenceId => visitedReferences.add(referenceId));
    return newPathsFromBFSStep;
  };
};
type GetPathsWithFiltersArgs = {
  graphInterface: GraphInterface;
  pathAsFilters: PathAsFilters[];
  graphModel: GraphModelShape;
  componentId: string;
};
/**
 * To get all paths we need to traverse the graph for each component of the
 * start set. The traversals are defined as filters. The filters are built from
 * the meta model traversals, based on types.
 */
export const getPathsWithFilters = ({
  graphInterface,
  pathAsFilters,
  graphModel,
  componentId,
}: GetPathsWithFiltersArgs) => {
  const start: ComponentPath[] = [
    [
      {
        componentId,
        direction: ReferenceDirection.UNSET,
        referenceId: '',
        isParentChildReference: false,
      },
    ],
  ];

  return pathAsFilters.reduce(
    reducePathsWithFilters(graphInterface, graphModel),
    start
  );
};
type GetCandidatesArgs = {
  graphInterface: GraphInterface;
  graphModel: GraphModelShape;
  isParentChildReference: boolean;
  tailComponentId: string;
  isOutgoing: boolean;
};
const getCandidates = ({
  graphInterface,
  graphModel,
  isParentChildReference,
  tailComponentId,
  isOutgoing,
}: GetCandidatesArgs) => {
  if (isParentChildReference) {
    if (isOutgoing) {
      return graphInterface
        .getComponentChildren(tailComponentId, true)
        .map(componentId =>
          toParentChildLinkedComponent({
            parentId: tailComponentId,
            childId: componentId,
            isOutgoing,
          })
        );
    }

    return [graphInterface.getComponentParentId(tailComponentId)]
      .filter(ExcludeFalsy)
      .map(componentId =>
        toParentChildLinkedComponent({
          parentId: componentId,
          childId: tailComponentId,
          isOutgoing,
        })
      );
  }

  return isOutgoing
    ? graphModel.sourceMap.get(tailComponentId) || []
    : graphModel.targetMap.get(tailComponentId) || [];
};
type ToParentChildLinkedComponentArgs = {
  parentId: string;
  childId: string;
  isOutgoing: boolean;
};
const toParentChildLinkedComponent = ({
  parentId,
  childId,
  isOutgoing,
}: ToParentChildLinkedComponentArgs) => ({
  componentId: isOutgoing ? childId : parentId,
  referenceId: `${parentId}-${childId}`,
  direction: isOutgoing
    ? ReferenceDirection.OUTGOING
    : ReferenceDirection.INCOMING,
  isParentChildReference: true,
});
/**
 * Transform the paths to a hierarchy (or tree). We try to find For each path
 * in the already built hierarchy. Starting at the bottom, as long as we
 * find the subpath, we  ensure that the parent connections contains the link to
 * the found component. The rest of the subpath we don't find, we add to the
 * hierarchy.
 */
export const pathsToHierarchy = (
  pathsDict: Record<string, ComponentPath[]>
): Record<string, HierarchyOfLinkedComponentsWithDirection> => {
  const tuples: [string, HierarchyOfLinkedComponentsWithDirection][] =
    Object.entries(pathsDict).map(([id, paths]) => {
      const root: HierarchyOfLinkedComponentsWithDirection = {
        componentId: id,
        linkedComponents: [],
        parentConnections: [],
      };

      paths.forEach(path => {
        let current = root;
        path.forEach(
          ({ componentId, referenceId, direction, isParentChildReference }) => {
            const connection = current.linkedComponents.find(
              ({ componentId: componentIdCandidate }) =>
                componentIdCandidate === componentId
            );
            if (connection) {
              if (
                !connection.parentConnections.some(
                  ({
                    referenceId: candidateReferenceId,
                    direction: candidateDirection,
                  }) =>
                    candidateReferenceId === referenceId &&
                    candidateDirection === direction
                )
              ) {
                connection.parentConnections.push({
                  referenceId,
                  direction,
                  isParentChildReference,
                });
              }
              current = connection;
            } else {
              const newConnection: HierarchyOfLinkedComponentsWithDirection = {
                componentId,
                linkedComponents: [],
                parentConnections: [
                  { referenceId, direction, isParentChildReference },
                ],
              };
              current.linkedComponents.push(newConnection);
              current = newConnection;
            }
          }
        );
      });
      return [id, root];
    });

  return Object.fromEntries(
    // Only return components with linked components.
    tuples.filter(([, root]) => root.linkedComponents.length > 0)
  );
};

export const getMatchingPaths = <T extends DirectedTriple>(
  paths: T[][] | null,
  rule: PathCollapsingRuleNonNullablePath,
  /**
   * If used to build the instance hierarchy, include matches from any path
   * index to ensure all possible matches across all paths are captured.
   * If used to collapse type paths in the viewpoint builder, only one match is
   * needed to avoid duplicated collapsed paths.
   */
  includePathIndexInEqualityCheck = true,
  withMatchesOverlapValidation = true
): CollapsingPathRuleMatch<T>[] => {
  if (!paths) return [];
  const { path: rulePath } = rule;
  const invertedRulePath = invertTriplePath(rulePath);
  const isSymmetric = invertedRulePath.every((triple, index) =>
    isSameTriple(triple, rulePath[index])
  );
  const allMatchingPaths = paths.flatMap((path, pathIndex) => {
    const matches = isSymmetric
      ? getMatchesForPath(path, rulePath, 'start')
      : [
          ...getMatchesForPath(path, rulePath, 'start'),
          ...getMatchesForPath(path, invertedRulePath, 'end'),
        ];
    if (
      withMatchesOverlapValidation &&
      !validateMatchesAgainstOverlaps(matches)
    ) {
      logError(
        Error('Overlapping matches found for path in getMatchesForPath')
      );
      return [];
    }
    return matches.map(match => ({
      path,
      match,
      rule,
      pathIndex,
      matchKey: getMatchKey(match, pathIndex, includePathIndexInEqualityCheck),
    }));
  });
  const countMatchesIndex = countBy(allMatchingPaths, 'matchKey');
  allMatchingPaths.forEach(match => {
    match.match.matchCount = countMatchesIndex[match.matchKey];
  });
  return uniqBy(allMatchingPaths, 'matchKey');
};

const getMatchKey = (
  { rulePathKey, startIndex, start }: PathMatch,
  pathIndex: number,
  includePathIndexInEqualityCheck: boolean
) =>
  includePathIndexInEqualityCheck
    ? `${rulePathKey}-${startIndex}-${start}-${pathIndex}`
    : `${rulePathKey}-${startIndex}-${start}`;

export const isSameTriple = (
  triple1: DirectedTriple,
  triple2: DirectedTriple
) => {
  return (
    triple1 &&
    triple2 &&
    triple1.sourceType === triple2.sourceType &&
    triple1.targetType === triple2.targetType &&
    triple1.referenceType === triple2.referenceType &&
    triple1.direction === triple2.direction
  );
};

export const invertTriplePath = (path: DirectedTriple[]): DirectedTriple[] =>
  path
    .toReversed()
    .map(({ sourceType, targetType, referenceType, direction }) => ({
      sourceType,
      targetType,
      referenceType,
      direction: direction === 'outgoing' ? 'incoming' : 'outgoing',
    }));

const getMatchesForPath = (
  path: DirectedTriple[],
  rulePath: DirectedTriple[],
  start: 'start' | 'end'
): PathMatch[] => {
  const firstRuleTriple = rulePath[0];
  // If a path has repeating triples we must try for each of them.
  const startIndexes = path
    .map((triple, index) =>
      isSameTriple(triple, firstRuleTriple) ? index : -1
    )
    .filter(index => index !== -1);
  if (startIndexes.length === 0) return [];
  return startIndexes
    .map(startIndex => {
      const isMatch = rulePath.every((ruleTriple, index) => {
        const pathTriple = path[startIndex + index];
        return isSameTriple(pathTriple, ruleTriple);
      });
      return isMatch
        ? {
            startIndex,
            length: rulePath.length,
            start,
            rulePathKey: pathToKey(rulePath),
            // Actual match count is set after all matches have been processed.
            matchCount: 0,
          }
        : null;
    })
    .filter(match => match !== null);
};

export const validateMatchesAgainstOverlaps = (matches: PathMatch[]) => {
  const matchesSortedByStartIndex = matches.toSorted(
    (a, b) => a.startIndex - b.startIndex
  );
  return matchesSortedByStartIndex.every((match, index) => {
    if (index === 0) return true;
    const previousMatch = matchesSortedByStartIndex[index - 1];
    // Matches cannot overlap.
    return match.startIndex >= previousMatch.startIndex + previousMatch.length;
  });
};

export const processMatches = (
  state: TraversalBuilderState,
  matches: CollapsingPathRuleMatch<DirectedTripleWithFiltersWithGraphIds>[]
): VirtualEdgeState[] => {
  const newEdges = matches.map(match => {
    const {
      path,
      match: { start, startIndex, length, matchCount },
      rule,
    } = match;

    const collapsedPath = path.slice(startIndex, startIndex + length);
    const { sourceGraphNodeId, targetGraphNodeId } =
      start === 'start'
        ? getSourceAndTargetGraphNodeIdFromStart(collapsedPath)
        : getSourceAndTargetGraphNodeIdFromEnd(collapsedPath);
    const collapsedEdges = collapsedPath.map(
      ({ referenceGraphId }) => referenceGraphId
    );
    const virtualEdge = GraphEdge.createWithMetaData(
      // This is the model id used to look up the reference in the according
      // scope data. A virtual edge is not based on a reference, so this id
      // is not useful for anything. We need to make sure that this case is
      // handled in the according code branches. The main use case is building
      // the triple options, or rather creating nodes and edges from a selected
      // triples. For a virtual edge we need to make sure that the according
      // triple is disabled.
      `virtual-edge-${getId()}`,
      sourceGraphNodeId,
      targetGraphNodeId,
      state.graphNodes,
      getId(),
      {
        representationData: {
          ...rule.referenceStyle,
          displayLabel: rule.displayText,
        },
      }
    );
    virtualEdge.setIsVirtual(true);
    const startTriple = collapsedPath[0]!;
    const endTriple = last(collapsedPath)!;
    const replacementDirection = start === 'start' ? 'outgoing' : 'incoming';
    const tripleReplacements = getTripleReplacements(
      state,
      startTriple,
      endTriple,
      replacementDirection,
      virtualEdge
    );
    return {
      collapsedEdges,
      virtualEdge,
      // Can only be calculated properly after all matches have been processed.
      // See `editTraversalOperations.updateEdgesToBeRemovedOnCollapsing`
      edgesToBeRemovedOnCollapsing: [],
      tripleReplacements,
      matchCount,
    };
  });
  return newEdges;
};

const getTripleReplacements = (
  state: TraversalBuilderState,
  startTriple: DirectedTripleWithFiltersWithGraphIds,
  endTriple: DirectedTripleWithFiltersWithGraphIds,
  replacementDirection: TripleDirection,
  virtualEdge: GraphEdgeWithMetaData
): TripleOptionReplacement[] => {
  const { graphNodes } = state;
  const { source, target } = virtualEdge;
  const sourceGraphNode = graphNodes.get(source);
  const targetGraphNode = graphNodes.get(target);
  if (!(sourceGraphNode && targetGraphNode)) {
    logError(
      Error('Missing source or target graph node in getTripleReplacements')
    );
    return [];
  }

  return [
    getStartTripleReplacement({
      state,
      triple: startTriple,
      replacementDirection,
      sourceGraphNode,
      targetGraphNode,
      virtualEdge,
    }),
    getEndTripleReplacement({
      state,
      triple: endTriple,
      replacementDirection:
        replacementDirection === 'outgoing' ? 'incoming' : 'outgoing',
      sourceGraphNode,
      targetGraphNode,
      virtualEdge,
    }),
  ].filter(ExcludeFalsy);
};

type GetTripleReplacement = {
  state: TraversalBuilderState;
  replacementDirection: TripleDirection;
  sourceGraphNode: GraphNodeWithMetaData;
  targetGraphNode: GraphNodeWithMetaData;
  virtualEdge: GraphEdgeWithMetaData;
  triple: DirectedTripleWithFiltersWithGraphIds;
};

const getStartTripleReplacement = (
  args: GetTripleReplacement
): TripleOptionReplacement | null => getTripleReplacement(args, 'start');

const getEndTripleReplacement = (
  args: GetTripleReplacement
): TripleOptionReplacement | null => getTripleReplacement(args, 'end');

const getTripleReplacement = (
  {
    state,
    triple,
    replacementDirection,
    sourceGraphNode,
    targetGraphNode,
    virtualEdge,
  }: GetTripleReplacement,
  start: 'start' | 'end'
): TripleOptionReplacement | null => {
  const { graphNodes, graphEdges } = state;
  const { sourceGraphId, targetGraphId, referenceGraphId } = triple;
  const collapsedSource = graphNodes.get(sourceGraphId);
  const collapsedTarget = graphNodes.get(targetGraphId);
  const collapsedReference = graphEdges.get(referenceGraphId);
  const virtualTripleOption = getVirtualTripleOption({
    state,
    sourceGraphNode,
    targetGraphNode,
    virtualEdge,
    context:
      replacementDirection === 'outgoing' ? sourceGraphNode : targetGraphNode,
  });
  if (
    !(
      collapsedSource &&
      collapsedTarget &&
      collapsedReference &&
      virtualTripleOption
    )
  ) {
    logError(
      Error(
        'Missing source, target or reference graph node in getStartTripleReplacement'
      )
    );
    return null;
  }
  const collapsedTripleId = getTripleId({
    sourceId: collapsedSource.modelId,
    targetId: collapsedTarget.modelId,
    referenceId: collapsedReference.modelId,
  });

  return {
    start,
    graphNodeId: getGraphNodeIdFromStartAndDirection(
      start,
      triple.direction,
      collapsedReference
    ),
    tripleId: collapsedTripleId,
    direction: triple.direction,
    replacementDirection,
    tripleReplacement: virtualTripleOption,
  };
};

const getGraphNodeIdFromStartAndDirection = (
  start: 'start' | 'end',
  tripleDirection: TripleDirection,
  collapsedReference: GraphEdgeWithMetaData
) => {
  if (start === 'start') {
    return tripleDirection === 'outgoing'
      ? collapsedReference.source
      : collapsedReference.target;
  }

  return tripleDirection === 'outgoing'
    ? collapsedReference.target
    : collapsedReference.source;
};

type GetVirtualOptionArgs = {
  state: TraversalBuilderState;
  sourceGraphNode: GraphNodeWithMetaData;
  targetGraphNode: GraphNodeWithMetaData;
  virtualEdge: GraphEdgeWithMetaData;
  context: GraphNodeWithMetaData;
};

const getVirtualTripleOption = ({
  state,
  sourceGraphNode,
  targetGraphNode,
  virtualEdge,
  context,
}: GetVirtualOptionArgs): TripleOption | null => {
  const { instanceCounts } = state;
  const tripleId = getTripleId({
    sourceId: sourceGraphNode.modelId,
    targetId: targetGraphNode.modelId,
    referenceId: virtualEdge.modelId,
  });
  const isSelected = true;
  const isDisabled = true;
  const togglingDisabledExplanation = `This is a collapsed path. It cannot be unselected. 
The collapsing rules can be changed in the 'Collapse paths' tab.`;
  const metaDataComponent =
    context === sourceGraphNode
      ? targetGraphNode.metaData
      : sourceGraphNode.metaData;
  const metaDataReference = virtualEdge.metaData;
  const namedDirectedTriple: NamedDirectedTriple = {
    // The direction doesn't really matter for a virtual edge. They are not
    // selectable by the user and never really part of the traversal definition.
    // (the direction indicates in which direction the triple should be traversed)
    direction: ReferenceDirection.OUTGOING,
    sourceType: sourceGraphNode.getLabel(),
    referenceType: virtualEdge.getLabel(),
    targetType: targetGraphNode.getLabel(),
  };
  const countKey = '';
  const tripleInstanceCount =
    instanceCounts.get(targetGraphNode.id)?.counts ?? null;
  return {
    tripleId,
    isSelected,
    isDisabled,
    togglingDisabledExplanation,
    metaDataComponent,
    metaDataReference,
    namedDirectedTriple,
    countKey,
    instanceCounts: tripleInstanceCount,
  };
};

const getSourceAndTargetGraphNodeIdFromStart = (
  path: DirectedTripleWithFiltersWithGraphIds[]
) => {
  const startTriple = path[0];
  const endTriple = path[path.length - 1];
  const sourceGraphNodeId =
    startTriple.direction === 'outgoing'
      ? startTriple.sourceGraphId
      : startTriple.targetGraphId;
  const targetGraphNodeId =
    endTriple.direction === 'outgoing'
      ? endTriple.targetGraphId
      : endTriple.sourceGraphId;
  return { sourceGraphNodeId, targetGraphNodeId };
};

const getSourceAndTargetGraphNodeIdFromEnd = (
  path: DirectedTripleWithFiltersWithGraphIds[]
) => {
  const startTriple = path[path.length - 1];
  const endTriple = path[0];
  const sourceGraphNodeId =
    startTriple.direction === 'outgoing'
      ? startTriple.targetGraphId
      : startTriple.sourceGraphId;
  const targetGraphNodeId =
    endTriple.direction === 'outgoing'
      ? endTriple.sourceGraphId
      : endTriple.targetGraphId;
  return { sourceGraphNodeId, targetGraphNodeId };
};

export const toCollapsedPaths = (
  paths: Path[],
  pathCollapsingRules: PathCollapsingRuleNonNullablePath[]
): Path[] => {
  const matchesPerPathIndex = groupBy(
    pathCollapsingRules.flatMap(rule => getMatchingPaths(paths, rule)),
    'pathIndex'
  );

  return paths.map((path, pathIndex) => {
    const matches = (matchesPerPathIndex[pathIndex] ?? []).toSorted(
      (a, b) => b.match.startIndex - a.match.startIndex
    );
    if (!validateCollapsingRuleMatches(matches)) {
      logError(Error('Overlapping matches found for path in toCollapsedPaths'));
      return path;
    }
    return matches.reduce((collapsedPath, match) => {
      return [
        ...collapsedPath.slice(0, match.match.startIndex),
        getCollapsedTriple(match),
        ...collapsedPath.slice(match.match.startIndex + match.match.length),
      ];
    }, path);
  });
};

const validateCollapsingRuleMatches = <T extends DirectedTripleWithFilters>(
  matches: CollapsingPathRuleMatch<T>[]
) =>
  matches.every(({ match: { startIndex, length } }, index) => {
    if (index === 0) return true;
    const previousMatch = matches[index - 1];
    return startIndex + length <= previousMatch.match.startIndex;
  });

const getCollapsedTriple = (matchObject: {
  path: DirectedTripleWithFilters[];
  match: {
    startIndex: number;
    length: number;
    start: string;
  };
  rule: PathCollapsingRule & { path: DirectedTriple[] };
  pathIndex: number;
}): DirectedTripleWithFilters => {
  const firstTriple = matchObject.rule.path[0];
  const lastTriple = last(matchObject.rule.path)!;
  const referenceType = matchObject.rule.displayText;

  if (matchObject.match.start === 'start') {
    const sourceType =
      firstTriple.direction === 'outgoing'
        ? firstTriple.sourceType
        : firstTriple.targetType;
    const targetType =
      lastTriple.direction === 'outgoing'
        ? lastTriple.targetType
        : lastTriple.sourceType;
    const direction = 'outgoing';
    return {
      sourceType,
      targetType,
      referenceType,
      direction,
    };
  }

  const sourceType =
    lastTriple.direction === 'outgoing'
      ? firstTriple.targetType
      : firstTriple.sourceType;
  const targetType =
    firstTriple.direction === 'outgoing'
      ? lastTriple.sourceType
      : lastTriple.targetType;
  const direction = 'incoming';
  return {
    sourceType,
    targetType,
    referenceType,
    direction,
  };
};

export type ProcessCollapsingRulesArgs = {
  graphInterface: GraphInterface;
  scopeReferenceIds: string[];
  scopeComponentIds: string[];
  parentChildReferences: FEParentChildReferenceAttributes[];
  collapsePathsArgs: CollapsePathsArgs[];
  workspacesIds: string[];
  startSetResultTraversalsAndSearches: Set<string>;
};

export const processCollapsingRulesOnLoadedData = ({
  graphInterface,
  scopeComponentIds,
  scopeReferenceIds,
  parentChildReferences,
  collapsePathsArgs,
  workspacesIds,
  startSetResultTraversalsAndSearches,
}: ProcessCollapsingRulesArgs) => {
  if (collapsePathsArgs.length === 0) {
    return {
      scopeReferenceIds,
      scopeComponentIds,
      parentChildReferences,
      newReferences: [],
    };
  }

  const updatedGraphModel = graphInterface.getGraphSnapshot(
    scopeReferenceIds,
    parentChildReferences
  );
  const { newReferences, removedComponentIds, removedReferenceIds } =
    processPathCollapsingRules({
      graphInterface,
      collapsePathsArgs,
      graphModel: updatedGraphModel,
      workspacesIds,
    });

  return {
    newReferences,
    scopeComponentIds: scopeComponentIds.filter(
      id =>
        !removedComponentIds.has(id) ||
        startSetResultTraversalsAndSearches.has(id)
    ),
    scopeReferenceIds: [
      ...scopeReferenceIds.filter(id => !removedReferenceIds.has(id)),
      ...newReferences.map(({ referenceId }) => referenceId),
    ],
    parentChildReferences: parentChildReferences.filter(
      ({ _id }) => !removedReferenceIds.has(_id)
    ),
  };
};
