import { APIFieldAttributes } from '@ardoq/api-types';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { addField, deleteField, editField, modifyFieldOrder } from './actions';
import { tap, withLatestFrom } from 'rxjs/operators';
import { deleteFieldWithConfirmation } from 'utils/fieldUtils';
import { updateFieldOrder } from 'appModelStateEdit/actions';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { showRightPane } from 'appContainer/actions';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { isDateRangeFieldType } from '@ardoq/date-range';

const handleAddField = routine(
  ofType(addField),
  tap(() => {
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.ADD_FIELD_TO_WORKSPACE,
      })
    );
  })
);

const handleEditField = routine(
  ofType(editField),
  extractPayload(),
  tap(fieldId => {
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.FIELD_EDITOR,
        field: { id: fieldId },
      })
    );
  })
);

const handleDeleteField = routine(
  ofType(deleteField),
  extractPayload(),
  withLatestFrom(appModelStateEdit$),
  tap(async ([fieldId, { enhancedScopeData }]) => {
    if (!enhancedScopeData) {
      return;
    }
    const field = enhancedScopeData.fieldsById[fieldId];
    await deleteFieldWithConfirmation(field);
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.MANAGE_FIELDS,
      })
    );
  })
);

const expandDateRangeFieldFromScopeData = (
  field: APIFieldAttributes,
  enhancedScopeData: EnhancedScopeData
) => {
  if (!isDateRangeFieldType(field.type)) {
    throw new Error('Tried to expand non date-range field');
  }
  const workspace = enhancedScopeData.workspacesByModelId[field.model];
  if (!workspace) return;

  const workspaceDateRangeFields =
    enhancedScopeData.dateRangeFieldMap?.get(workspace._id) ?? {};

  return workspaceDateRangeFields[field.name];
};

const handleModifyFieldOrder = routine(
  ofType(modifyFieldOrder),
  extractPayload(),
  withLatestFrom(appModelStateEdit$),
  tap(([orderedFieldIds, { enhancedScopeData }]) => {
    if (!enhancedScopeData) {
      return;
    }
    orderedFieldIds
      .flatMap(fieldId => {
        const field = enhancedScopeData.fieldsById[fieldId];

        if (!isDateRangeFieldType(field.type)) return [field];

        // Split DATE_RANGE into it's two DATE_TIME parts
        const { start, end } =
          expandDateRangeFieldFromScopeData(field, enhancedScopeData) ?? {};
        return [start, end];
      })
      .forEach((field, order) => {
        if (!field || field._order === order) {
          return; // do nothing
        }
        dispatchAction(updateFieldOrder({ fieldId: field._id, order }));
      });
  })
);

export default collectRoutines(
  handleAddField,
  handleEditField,
  handleDeleteField,
  handleModifyFieldOrder
);
