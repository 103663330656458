import { combineLatest } from 'rxjs';
import { action$, reducer, reduceState, streamReducer } from '@ardoq/rxbeach';
import currentUser$, {
  CurrentUserState,
} from 'streams/currentUser/currentUser$';
import { models$, ModelsState } from 'streams/models/models$';
import { APIModelAttributes, ArdoqId, PersonalSetting } from '@ardoq/api-types';
import {
  getCategorizedTemplates,
  getSpecialCategories,
  isSpecialCategoryTemplate,
} from './templateCategorizer';
import { selectCategory } from './actions';
import { getWorkspaceTemplateCategories } from 'collections/modelCategories';
import {
  CategoryWithTemplatesCollection,
  CreateFromTemplateStreamState,
  CurrentTab,
  FavoriteableModel,
  ModelCategoryWithTemplates,
} from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';

const initialState: CreateFromTemplateStreamState = {
  categories: [],
  currentTab: {
    category: {
      ...getWorkspaceTemplateCategories()[0].categories[0],
      templates: { favorites: [], customs: [], commons: [] },
    },
  },
  blankTemplateModel: null,
};

const addFavorites = (
  models: Record<ArdoqId, APIModelAttributes>,
  favorites: ArdoqId[]
) =>
  Object.values(models).map<FavoriteableModel>(model => ({
    ...model,
    favorite: favorites.indexOf(model._id) > -1,
  }));

const getCategoriesWithTemplates = (
  currentUserState: CurrentUserState,
  models: FavoriteableModel[]
): CategoryWithTemplatesCollection[] => [
  ...getCategorizedTemplates(models, getWorkspaceTemplateCategories()),
  getSpecialCategories(models, currentUserState),
];

export const EXCEL_IMPORTER = 'excelImport';

const excel: FavoriteableModel = {
  _id: EXCEL_IMPORTER,
  name: 'Excel upload',
  category: '',
  useAsTemplate: true,
  common: true,
  flexible: true,
  startView: null,
  description:
    'Import your Excel sheet with your systems, applications, process steps, or any other items to automatically create components in Ardoq.',
  root: {},
  favorite: false,
  createdBy: '',
  blankTemplate: false,
};

const addExcelAndBlankTemplateIfNotSpecialCategory = (
  model: ModelCategoryWithTemplates,
  blankTemplateModel: FavoriteableModel | null
): ModelCategoryWithTemplates => {
  const commonTemplatesWithoutBlankTemplateModel =
    model.templates.commons.filter(templateModel => {
      const modelDuplicatesBlankTemplate = Boolean(
        blankTemplateModel && blankTemplateModel._id === templateModel._id
      );
      return !modelDuplicatesBlankTemplate;
    });
  return {
    ...model,
    templates: {
      ...model.templates,
      ...(!isSpecialCategoryTemplate(model.id) && {
        commons: [
          ...commonTemplatesWithoutBlankTemplateModel,
          blankTemplateModel,
          excel,
        ].filter(ExcludeFalsy),
      }),
    },
  };
};

const getActiveTabData = (
  categoryCollections: CategoryWithTemplatesCollection[],
  categoryId: string,
  blankTemplateModel: FavoriteableModel | null
): CurrentTab => {
  const allCategories = categoryCollections.flatMap(
    ({ categories }) => categories
  );
  const newActiveCategory =
    allCategories.find(category => category.id === categoryId) ??
    allCategories[0];
  return {
    category: addExcelAndBlankTemplateIfNotSpecialCategory(
      newActiveCategory,
      blankTemplateModel
    ),
  };
};

const categorySelectedReducer = (
  state: CreateFromTemplateStreamState,
  categoryId: string
) => ({
  ...state,
  currentTab: getActiveTabData(
    state.categories,
    categoryId,
    state.blankTemplateModel
  ),
});

const resetReducer = (
  state: CreateFromTemplateStreamState,
  [currentUserState, modelsState]: [CurrentUserState, ModelsState]
) => {
  const favoriteTemplates =
    currentUserState.settings[PersonalSetting.FAVORITE_TEMPLATES] || [];
  const models = addFavorites(modelsState.byId, favoriteTemplates);
  // The expectation here is that someone will manage 1 template in
  // ardoq-common that has the `blankTemplate === true`. That template will
  // then be shown in all template categories, excluding "special categories"
  // like favorites or my templates.
  const blankTemplateModel =
    models.find(model => model.blankTemplate && model.useAsTemplate) ?? null;
  const categories = getCategoriesWithTemplates(currentUserState, models);
  return {
    ...state,
    categories,
    currentTab: getActiveTabData(
      categories,
      state.currentTab.category.id,
      blankTemplateModel
    ),
    blankTemplateModel,
  };
};

export const createFromTemplate$ = action$.pipe(
  reduceState('createFromTemplate$', initialState, [
    streamReducer(combineLatest([currentUser$, models$]), resetReducer),
    reducer(selectCategory, categorySelectedReducer),
  ])
);
