import PersonCell from 'broadcasts/components/audienceTable/audienceByComponent/cellRenderers/PersonCell';
import { ExpandRow } from 'broadcasts/components/expandableTable/types';
import { ExcludeFalsy, HighlightedText } from '@ardoq/common-helpers';
import { AudienceTableColumn } from '../../types';
import { SortByFn, SortById } from '@ardoq/pagination';
import { SortOrder } from '@ardoq/api-types';
import { DataSourceRow } from '../../audienceByComponent/utils';
import { Column, ExpandableRowData } from '@ardoq/table';
import FormattedIcon from '../../FormattedIcon';
import { getIconName } from '../../utils';

const getStatusColumnConfig = (
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
) => ({
  dataIndex: AudienceTableColumn.COMPONENT_STATUS,
  headerStyle: { width: '5%' },
  cellStyle: { width: '5%' },
  valueRender: (_: string, { componentStatus }: DataSourceRow) => (
    <FormattedIcon iconName={getIconName(componentStatus)} />
  ),
  sortOrder:
    sortById === AudienceTableColumn.COMPONENT_STATUS ? sortOrder : undefined,
  onHeaderClick: () => sortByFn(AudienceTableColumn.COMPONENT_STATUS),
});

export const getComponentColumnConfig = (
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
): Column<ExpandableRowData<DataSourceRow>> => ({
  title: 'Component',
  headerStyle: { width: '55%' },
  cellStyle: { width: '55%' },
  dataIndex: AudienceTableColumn.COMPONENT_LABEL,
  sortOrder:
    sortById === AudienceTableColumn.COMPONENT_LABEL ? sortOrder : undefined,
  onHeaderClick: () => sortByFn(AudienceTableColumn.COMPONENT_LABEL),
  valueRender: (componentLabel: string, { tableFilter }) => (
    <HighlightedText text={componentLabel} highlight={tableFilter} />
  ),
});

export const getAudienceColumnConfig = (
  expandedRowIds: string[],
  expandRow: ExpandRow,
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
): Column<ExpandableRowData<DataSourceRow>> => ({
  title: 'Audience',
  headerStyle: { width: '40%' },
  cellStyle: { width: '40%' },
  dataIndex: AudienceTableColumn.PERSON_LABEL,
  valueRender: (
    personLabel: string,
    { personSublabel, meta, children, isChildRow, audienceStatus, tableFilter }
  ) => {
    const { path } = meta;
    const isExpandable = Boolean(children);
    const isExpanded = expandedRowIds.includes(path);
    return (
      <PersonCell
        personLabel={personLabel}
        personSublabel={personSublabel}
        isChildRow={isChildRow}
        isExpandable={isExpandable}
        isExpanded={isExpanded}
        toggleExpand={() => expandRow(path)}
        audienceStatus={audienceStatus}
        highlight={tableFilter}
      />
    );
  },
  sortOrder:
    sortById === AudienceTableColumn.PERSON_LABEL ? sortOrder : undefined,
  onHeaderClick: () => sortByFn(AudienceTableColumn.PERSON_LABEL),
});

const getColumnConfigByComponent =
  (shouldShowStatusColumn: boolean) =>
  (
    expandedRowIds: string[],
    expandRow: ExpandRow,
    sortByFn: SortByFn,
    sortById: SortById,
    sortOrder: SortOrder
  ) =>
    [
      shouldShowStatusColumn &&
        getStatusColumnConfig(sortByFn, sortById, sortOrder),
      getComponentColumnConfig(sortByFn, sortById, sortOrder),
      getAudienceColumnConfig(
        expandedRowIds,
        expandRow,
        sortByFn,
        sortById,
        sortOrder
      ),
    ].filter(ExcludeFalsy);

export default getColumnConfigByComponent;
