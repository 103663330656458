import { useCallback, useEffect, useState } from 'react';
import { SidebarWrapper } from './atoms';
import NavigatorPane from './NavigatorPane';
import QuickPerspectivesPane from './QuickPerspectivesPane';
import CloseableSplitPane from 'components/CloseableSplitPane/CloseableSplitPane';
import ScenarioRelatedPane from './scenarioRelated/ScenarioRelatedPane';
import { trackToggleRelatedComponents } from 'scope/tracking';
import { dispatchContainerHeightChanged } from 'navigator2024/NavigatorRoot';
import { connect } from '@ardoq/rxbeach';
import { viewModel$ } from './viewModel$';
import { NavigatorTopBarStreamShape } from './NavigatorTopBar/types';

const TOP_BAR_HEIGHT = 48;

type Props = {
  isQuickPerspectiveExpanded: boolean;
  isDiffModeActive: boolean;
  isFiltering: boolean;
  navigatorTopBarProps: NavigatorTopBarStreamShape;
  isScenarioMode: boolean;
};

const MainAppModuleSidebarOld = ({
  navigatorTopBarProps,
  isQuickPerspectiveExpanded,
  isDiffModeActive,
  isFiltering,
  isScenarioMode,
}: Props) => {
  const [isOpened, setIsOpened] = useState(isQuickPerspectiveExpanded);
  const [size, setSize] = useState(isScenarioMode ? 200 : 300);
  const onSizeChange = (newSize: number) => {
    dispatchContainerHeightChanged();
    setSize(newSize);
  };

  return (
    <SidebarWrapper>
      <CloseableSplitPane
        split="horizontal"
        closablePane="second"
        onDragFinished={onSizeChange}
        size={size}
        minSize={100}
        closedPaneSize={TOP_BAR_HEIGHT}
        isOpened={isOpened}
      >
        {isScenarioMode ? (
          <NestedSidebar
            isDiffModeActive={isDiffModeActive}
            isFiltering={isFiltering}
            navigatorTopBarProps={navigatorTopBarProps}
          />
        ) : (
          <NavigatorPane topBarProps={navigatorTopBarProps} />
        )}
        <QuickPerspectivesPane setIsOpened={setIsOpened} />
      </CloseableSplitPane>
    </SidebarWrapper>
  );
};

const NestedSidebar = ({
  isDiffModeActive,
  isFiltering,
  navigatorTopBarProps,
}: {
  isDiffModeActive: boolean;
  isFiltering: boolean;
  navigatorTopBarProps: NavigatorTopBarStreamShape;
}) => {
  const [isOpened, setIsOpened] = useState(true);
  const [size, setSize] = useState(300);
  const onSizeChange = (newSize: number) => {
    dispatchContainerHeightChanged();
    setSize(newSize);
  };

  const toggleRelatedComponents = useCallback(() => {
    setIsOpened(!isOpened);
    trackToggleRelatedComponents(isOpened ? 'open' : 'close');
    setTimeout(dispatchContainerHeightChanged, 10);
  }, [isOpened]);

  useEffect(() => {
    if (isDiffModeActive && isOpened) {
      toggleRelatedComponents();
    }
  }, [isOpened, isDiffModeActive, toggleRelatedComponents]);

  return (
    <CloseableSplitPane
      split="horizontal"
      closablePane="second"
      onDragFinished={onSizeChange}
      size={size}
      minSize={100}
      closedPaneSize={TOP_BAR_HEIGHT}
      isOpened={isOpened}
    >
      <NavigatorPane topBarProps={navigatorTopBarProps} />
      <ScenarioRelatedPane
        toggle={toggleRelatedComponents}
        isOpened={isOpened}
        isDiffModeActive={isDiffModeActive}
        isFiltering={isFiltering}
      />
    </CloseableSplitPane>
  );
};

export default connect(MainAppModuleSidebarOld, viewModel$);
