import { APISurveyAttributes, ArdoqId } from '@ardoq/api-types';
import changeApprovalData$ from './changeApprovalData$';
import { ChangeApprovalsStreamShape } from './types';
import { DoqWithSpeechBubble, DoqType } from '@ardoq/doq';
import { openSurveyAdminView } from 'surveyAdmin/navigation/utils';
import { combineLatest, map } from 'rxjs';
import { SurveyAdminMode } from 'surveyAdmin/navigation/types';
import { BetaBadge } from '@ardoq/status-ui';
import { GhostButton, DangerButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { trackEvent } from 'tracking/tracking';
import EmptyState from 'components/AssetsBrowser2024/EmptyState';
import { hasFeature, Features } from '@ardoq/features';
import ComponentOverviewTable from './ComponentOverviewTable/ComponentOverviewTable';
import { SearchInput } from '@ardoq/forms';
import FilterChips from 'surveyAdmin/SurveyOverview/FilterChips';
import { resetAnswers, setFilterChips, setSearchPhrase } from './actions';
import { s16, colors } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { confirm } from '@ardoq/modal';
import surveyAdmin$ from 'surveyAdmin/streams/surveyAdmin$';
import InvalidChangeRequestsTable from './PageElements/InvalidChangeRequestsTable';
import { DoqLoader } from '@ardoq/snowflakes';
import {
  Island,
  PageHeader,
  PageBody,
  PageWrapper,
  PageTopbar,
} from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';

const BorderBottom = styled.div`
  padding: ${s16};
  border-bottom: 1px solid ${colors.borderAction};
`;

const chips = [
  { label: 'Updated', key: 'update' },
  { label: 'Created', key: 'create' },
  { label: 'Deleted', key: 'delete' },
  { label: 'Invalid change', key: 'no_change' },
];

type ChangeApprovalPageProps = {
  selectedSurvey: APISurveyAttributes;
  hasNewJourneyFeature: boolean;
  surveyId: ArdoqId | null;
} & ChangeApprovalsStreamShape;

const ChangeApprovalPage = ({
  selectedSurvey,
  status,
  componentsWithPendingChanges,
  hasNewJourneyFeature,
  searchPhrase,
  filterChips,
  surveyId,
  invalidChangeRequests,
}: ChangeApprovalPageProps) => {
  const backToOverviewButton = (
    <GhostButton
      onClick={() => {
        trackEvent('Change approval: clicked on back to overview');
        openSurveyAdminView(SurveyAdminMode.OVERVIEW);
      }}
      dataTestId="back-button"
    >
      <Icon iconName={IconName.CHEVRON_LEFT} />
      Back to Surveys
    </GhostButton>
  );

  const handleClearAnswers = async () => {
    trackEvent('Change approval: opened reset answers modal');
    const approved = await confirm({
      title: 'Clear all pending changes?',
      text: 'If you clear all pending changes, no components will be updated, created or deleted.',
      text2: "Once cleared the changes can't be recovered.",
      confirmButtonTitle: 'Clear changes',
      cancelButtonTitle: 'Keep changes',
    });

    if (!approved || !surveyId) return;
    trackEvent('Change approval: clicked on reset answers');
    dispatchAction(resetAnswers(surveyId));
  };
  return (
    <PageWrapper>
      {hasNewJourneyFeature ? (
        <PageTopbar
          primaryContent={
            <FlexBox gap="xsmall" align="center">
              {selectedSurvey.name}
              <BetaBadge />
            </FlexBox>
          }
          secondaryContent={'Response approval'}
          primaryButton={backToOverviewButton}
        />
      ) : (
        <PageHeader
          title={selectedSurvey.name}
          rightContent={backToOverviewButton}
        />
      )}
      <PageBody alignIslandsToLeft>
        {status === 'error' ? (
          <DoqWithSpeechBubble
            buttonLabel="Back to survey overview"
            doqType={DoqType.ERROR}
            message="Something went wrong and your data couldn't be fetched. Please try again or contact customer support."
            onButtonClick={() => openSurveyAdminView(SurveyAdminMode.OVERVIEW)}
            title="Oops! Something went wrong"
          />
        ) : status === 'loading' ? (
          <DoqLoader />
        ) : componentsWithPendingChanges.length ||
          invalidChangeRequests.length ? (
          <>
            <InvalidChangeRequestsTable />
            {componentsWithPendingChanges.length > 0 && (
              <Island
                preventScroll
                header={
                  <BorderBottom>
                    <FlexBox justify="space-between">
                      <FlexBox gap="medium">
                        <SearchInput
                          placeholder="Search component"
                          value={searchPhrase}
                          onValueChange={searchPhrase =>
                            dispatchAction(setSearchPhrase(searchPhrase))
                          }
                          dataTestId="search-input"
                          onFocus={() =>
                            trackEvent(
                              'Survey change approval: used type to filter'
                            )
                          }
                        />
                        <FilterChips
                          showAllChip
                          filterChips={chips}
                          setFilterChips={chips => {
                            trackEvent(
                              'Survey change approval: used chips to filter',
                              { chips }
                            );
                            dispatchAction(setFilterChips(chips));
                          }}
                          activeChips={filterChips}
                        />
                      </FlexBox>
                      <DangerButton onClick={handleClearAnswers}>
                        Clear pending answers
                      </DangerButton>
                    </FlexBox>
                  </BorderBottom>
                }
              >
                <ComponentOverviewTable />
              </Island>
            )}
          </>
        ) : (
          <EmptyState
            doqType={DoqType.HEADPHONES}
            title="You're all up to date!"
            text="You have no pending survey responses. Go back to Surveys to view all running surveys."
          />
        )}
      </PageBody>
    </PageWrapper>
  );
};

const changeApprovalPageData$ = combineLatest(
  surveyAdmin$,
  changeApprovalData$
).pipe(
  map(([{ surveyId }, changeApprovalData]) => {
    return {
      ...changeApprovalData,
      hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
      surveyId,
    };
  })
);

export default connect(ChangeApprovalPage, changeApprovalPageData$);
