import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { SetLegacyConfigsPayload, setLegacyConfigs } from './actions';
import { ServiceNowLegacyConfig } from '@ardoq/api-types/integrations';

type LegacyConfigState = {
  configs: ServiceNowLegacyConfig[];
};

const initialState: LegacyConfigState = {
  configs: [],
};

const setLegacyConfigsReducer = (
  state: LegacyConfigState,
  { configs }: SetLegacyConfigsPayload
) => {
  return {
    ...state,
    configs,
  };
};
const handleFetchLegacyConfigsuccess = reducer<
  LegacyConfigState,
  SetLegacyConfigsPayload
>(setLegacyConfigs, setLegacyConfigsReducer);

const reducers = [handleFetchLegacyConfigsuccess];

export const legacyConfigs$ = persistentReducedStream(
  `servicenowLegacyConfigs$`,
  initialState,
  reducers
);
