import getSortOptions, { getFieldOptions } from 'utils/getSortOptions';
import { Features, hasFeature } from '@ardoq/features';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { context$ } from 'streams/context/context$';
import viewComponentIds$ from 'streams/viewComponentIds$';
import { SortOrder, ViewIds } from '@ardoq/api-types';
import { VIEW_SUPPORTING_DIFF } from 'scope/consts';
import { scenarioIsInSync$ } from 'scenarioIsInSync$';
import activeView$ from 'streams/views/mainContent/activeView$';
import { dispatchAction, reducedStream, reducer } from '@ardoq/rxbeach';
import { closeAllWorkspaces as closeAllWorkspacesAction } from 'streams/workspaces/actions';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { setFilterTerm, setIsFilterFocused } from '../actions';
import { NavigatorTopBarStreamShape } from './types';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { loadedGraphOperations } from 'traversals/loadedGraphOperations';
import { componentSelection$ } from 'componentSelection/componentSelection$';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { permissionsOperations } from '@ardoq/access-control';
import { componentInterface } from '@ardoq/component-interface';

const sortOptionMap = ({
  label,
  value,
  selected,
  iconDirection,
}: {
  label: string;
  value: string;
  selected: boolean;
  iconDirection: string;
}) => {
  const sortOrder =
    selected && (iconDirection === 'down' ? SortOrder.DESC : SortOrder.ASC);
  return {
    label,
    attrName: value,
    ...(sortOrder ? { sortOrder } : {}),
  };
};

export type FilterState = {
  isFilterFocused: boolean;
  filterTerm: string;
};

const handleSetIsFilterFocused = (
  state: FilterState,
  isFilterFocused: boolean
) => ({ ...state, isFilterFocused });

const handleSetFilterTerm = (state: FilterState, filterTerm: string) => ({
  ...state,
  filterTerm,
});

const filterState$ = reducedStream(
  'filterState$',
  { isFilterFocused: false, filterTerm: '' },
  [
    reducer(setIsFilterFocused, handleSetIsFilterFocused),
    reducer(setFilterTerm, handleSetFilterTerm),
  ]
);

const initialState: NavigatorTopBarStreamShape = {
  isScenarioMode: false,
  closeAllWorkspaces: () => {},
  sortOptions: [],
  scenariosEnabled: false,
  viewComponentIds: [],
  isDiffModeAvailable: false,
  selectedComponentIds: [],
  isScenarioInSync: false,
  filterState: {
    isFilterFocused: false,
    filterTerm: '',
  },
  permissionContext: permissionsOperations.createEmptyPermissionContext(),
  loadedGraph: loadedGraphOperations.getEmpty(),
};

export default combineLatest({
  context: context$,
  componentSelection: componentSelection$,
  viewComponentIds: viewComponentIds$,
  activeView: activeView$,
  scenarioIsInSync: scenarioIsInSync$,
  filterState: filterState$,
  activeScenario: activeScenario$,
  loadedGraph: loadedGraph$,
  permissionContext: currentUserPermissionContext$,
}).pipe(
  map(
    ({
      context,
      componentSelection: { treeSelection },
      viewComponentIds,
      activeView: { mainViewId: viewId },
      scenarioIsInSync: { isInSync: isScenarioInSync },
      filterState,
      activeScenario: { isScenarioMode },
      loadedGraph,
      permissionContext,
    }): NavigatorTopBarStreamShape => {
      const scenariosEnabled =
        hasFeature(Features.SCENARIOS_BETA) &&
        Boolean(permissionContext.user?.writeAccess) &&
        Boolean(viewId) &&
        viewId !== ViewIds.BLOCKS;
      const fieldOptions = context.workspaceId
        ? getFieldOptions(context.workspaceId)
        : [];
      const sortOptions = getSortOptions({
        fieldOptions,
        currentSort: context.sort,
      }).map(sortOptionMap);

      const selectedComponentIds = treeSelection.filter(
        componentInterface.isComponent
      );

      const closeAllWorkspaces = () =>
        dispatchAction(closeAllWorkspacesAction());

      return {
        isScenarioMode: scenariosEnabled && isScenarioMode,
        closeAllWorkspaces,
        sortOptions,
        scenariosEnabled,
        viewComponentIds,
        isDiffModeAvailable: Boolean(
          viewId && VIEW_SUPPORTING_DIFF.includes(viewId)
        ),
        selectedComponentIds,
        isScenarioInSync,
        filterState,
        loadedGraph,
        permissionContext,
      };
    }
  ),
  startWith(initialState)
);
