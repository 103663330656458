import { BLANK_WORKSPACE_MODEL_NAME } from './consts';
import { CurrentUserState } from 'streams/currentUser/currentUser$';
import {
  CategoryCollection,
  CategoryWithTemplatesCollection,
  FavoriteableModel,
  GroupedTemplates,
  ModelCategory,
  ModelCategoryWithTemplates,
} from './types';
import { IconName } from '@ardoq/icons';

const sortCustomFirst = (a: FavoriteableModel, b: FavoriteableModel) =>
  a.common ? 1 : b.common ? -1 : 0;

const sortFavoritesFirst = (a: FavoriteableModel, b: FavoriteableModel) =>
  a.favorite ? -1 : b.favorite ? 1 : 0;

const sortBlankLast = (a: FavoriteableModel, b: FavoriteableModel) =>
  a.name === BLANK_WORKSPACE_MODEL_NAME
    ? 1
    : b.name === BLANK_WORKSPACE_MODEL_NAME
      ? -1
      : 0;

const getTemplatesByCategory = (
  models: FavoriteableModel[],
  categoryName: string
) =>
  models
    .filter(model => model.useAsTemplate && model.category === categoryName)
    .sort(sortCustomFirst)
    .sort((a, b) => sortFavoritesFirst(a, b))
    .sort(sortBlankLast);

const groupTemplates = (templates: FavoriteableModel[]) => ({
  favorites: templates.filter(t => t.favorite),
  customs: templates.filter(t => !t.favorite && !t.common),
  commons: templates.filter(t => t.common),
});

const containsTemplates = (groupedTemplates: GroupedTemplates) =>
  groupedTemplates.favorites.length ||
  groupedTemplates.customs.length ||
  groupedTemplates.commons.length;

const enhanceWithTemplates = (
  category: ModelCategory,
  models: FavoriteableModel[]
): ModelCategoryWithTemplates => ({
  ...category,
  templates: groupTemplates(getTemplatesByCategory(models, category.name)),
});

const removeUnshownCategories = ({
  label,
  categories,
}: CategoryWithTemplatesCollection): CategoryWithTemplatesCollection => ({
  label,
  categories: categories.filter(
    ({ templates, displayCategoryWhenTemplatesEmpty }) =>
      containsTemplates(templates) || displayCategoryWhenTemplatesEmpty
  ),
});

export const getCategorizedTemplates = (
  models: FavoriteableModel[],
  categoryCollections: CategoryCollection[]
): CategoryWithTemplatesCollection[] =>
  categoryCollections
    .map<CategoryWithTemplatesCollection>(({ label, categories }) => ({
      label,
      categories: categories.map(category =>
        enhanceWithTemplates(category, models)
      ),
    }))
    .map(removeUnshownCategories)
    .filter(({ categories }) => categories.length);

const isTemplate = (model: FavoriteableModel) =>
  model.useAsTemplate || model.common;

const SPECIAL_CATEGORY_TEMPLATE_IDS = {
  FAVORITES: 'all_favorites',
  MINE: 'my_templates',
  ORG: 'org_templates',
};

export const isSpecialCategoryTemplate = (templateID: string) =>
  Object.values(SPECIAL_CATEGORY_TEMPLATE_IDS).some(
    specialID => specialID === templateID
  );

export const getSpecialCategories = (
  models: FavoriteableModel[],
  currentUserState: CurrentUserState
): CategoryWithTemplatesCollection => ({
  label: null,
  categories: [
    {
      id: SPECIAL_CATEGORY_TEMPLATE_IDS.FAVORITES,
      name: 'All favorites',
      icon: IconName.STAR_BORDER,
      templates: groupTemplates(models.filter(m => m.favorite)),
      displayCategoryWhenTemplatesEmpty: false,
    },
    {
      id: SPECIAL_CATEGORY_TEMPLATE_IDS.MINE,
      name: 'My templates',
      icon: IconName.TAG,
      displayCategoryWhenTemplatesEmpty: false,
      templates: groupTemplates(
        models
          .filter(isTemplate)
          .filter(template => template.createdBy === currentUserState._id)
      ),
    },
    {
      id: SPECIAL_CATEGORY_TEMPLATE_IDS.ORG,
      name: 'Organization templates',
      icon: IconName.TAG,
      displayCategoryWhenTemplatesEmpty: false,
      templates: groupTemplates(
        models.filter(isTemplate).filter(template => !template.common)
      ),
    },
  ],
});
