import { dispatchAction } from '@ardoq/rxbeach';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import dashboardBuilder$ from 'dashboard/DashboardBuilder/DashboardBuilder$';
import { applyDashboardWidgetFiltersRequested } from 'dashboard/DashboardBuilder/actions';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import {
  fetchSearchResults,
  getSearchResults$,
} from 'report/ReportReader/searchResults$';
import reportReader$ from 'report/ReportReader/reportReader$';
import { ReportFilterDrawerProps } from './ReportFilterDrawer';
import { getSelectedChartWidget } from '../utils';
import { trackEvent } from '../../../tracking/tracking';
import { DashboardTrackingEventsNames } from '@ardoq/dashboard';
import { getReportReaderCommands } from '../../../report/ReportReader/commands/reportReaderCommands';

export const reportFilterDrawerViewModel$ = combineLatest([
  dashboardBuilder$,
  reportReader$,
  getSearchResults$(fetchSearchResults),
  currentUserPermissionContext$,
  subdivisions$,
]).pipe(
  map(
    ([
      dashboardBuilderState,
      {
        isSearchBeingExecuted,
        page,
        pagination,
        reportId: selectedWidgetReportId,
        filterQuery,
        sort,
      },
      { searchResults, apiError, filterableColumns },
      permissionContext,
      subdivisionsContext,
    ]): ReportFilterDrawerProps => {
      const selectedWidget = getSelectedChartWidget(dashboardBuilderState); // this will never be visible for header widget

      const commands = getReportReaderCommands(
        filterQuery,
        selectedWidgetReportId!,
        searchResults
      );

      const onSave = () => {
        dispatchAction(
          applyDashboardWidgetFiltersRequested({
            widgetId: selectedWidget._id,
            filterQuery: filterQuery,
          })
        );
        trackEvent(DashboardTrackingEventsNames.APPLIED_WIDGET_FILTER);
      };

      return {
        apiError,
        commands,
        filterableColumns,
        isSearchBeingExecuted,
        onSave,
        sortFilterPagination: { sort, page, pagination, filterQuery },
        permissionContext,
        searchResults,
        subdivisionsContext,
      };
    }
  )
);
