import { IntegrationId } from '../tabularMappings/types';
import {
  CloudSchedule,
  MicrosoftEntraIdSchedule,
  ScheduleType,
  ServiceNowSchedule,
  UnifiedSchedule,
  IntegrationSchedule,
  UnifiedScheduleType,
} from '@ardoq/api-types';
import {
  CLOUD_PROVIDER_TYPE,
  IntegrationWorkspace,
  ITPediaSchedule,
  SignavioExportSchedule,
} from '@ardoq/api-types/integrations';
import { Connection } from '@ardoq/api-types/integrations';
import {
  INTEGRATION_ID_TO_SCHEDULE_TYPE,
  UNIFIED_INTEGRATION_IDS,
  UNIFIED_SCHEDULE_TYPES,
} from 'integrations/unified/constants';
import { createUnifiedIntegrationRecord } from 'integrations/unified/utils';
import { isSameDay, toZonedTime } from '@ardoq/date-time';

// We run our schedules at UTC+0
// https://ardoqcom.atlassian.net/browse/ARD-24820
export const isScheduleWorkedTodayInUTC = (date: Date) =>
  isSameDay(toZonedTime(new Date(), 'UTC'), toZonedTime(date, 'UTC'));

export const getScheduleDirection = (type: ScheduleType) => {
  return type.endsWith('-export') ? 'export' : 'import';
};

export const getAffectedSchedules = (
  schedules: IntegrationSchedule[],
  connectionId: string
) =>
  schedules.filter(
    schedule =>
      ('servicenowConfig' in schedule.jobOptions &&
        schedule.jobOptions.servicenowConfig.connectionId === connectionId) ||
      ('cloudProviderConfig' in schedule.jobOptions &&
        schedule.jobOptions.cloudProviderConfig.accountIds.includes(
          connectionId
        )) ||
      ('sourceConfig' in schedule.jobOptions &&
        schedule.jobOptions.sourceConfig.connectionId === connectionId)
  );

const INTEGRATION_IDS_WITH_SCHEDULES: Array<IntegrationId> = [
  'microsoft-entra-id',
  'aws-v3',
  'azure-v3',
  'servicenow-v3',
  'signavio-exporter',
  'it-pedia',
  ...UNIFIED_INTEGRATION_IDS,
];

export const isIntegrationWithSchedules = (integrationId: IntegrationId) =>
  INTEGRATION_IDS_WITH_SCHEDULES.includes(integrationId);

export const isCloudSchedule = (
  job?: IntegrationSchedule
): job is CloudSchedule =>
  job ? job.jobOptions.type === ScheduleType.CLOUD_IMPORT : false;

export const isUnifiedSchedule = (
  job?: IntegrationSchedule
): job is UnifiedSchedule =>
  job ? UNIFIED_SCHEDULE_TYPES.includes(job.jobOptions.type) : false;

export const isUnifiedScheduleFor = (
  unifiedScheduleType: UnifiedScheduleType,
  job?: IntegrationSchedule
): job is UnifiedSchedule =>
  isUnifiedSchedule(job) && job.jobOptions.type === unifiedScheduleType;

export const isServiceNowSchedule = (
  job?: IntegrationSchedule
): job is ServiceNowSchedule =>
  job
    ? [ScheduleType.SERVICENOW_EXPORT, ScheduleType.SERVICENOW_IMPORT].includes(
        job.jobOptions.type
      )
    : false;

export const isMicrosoftEntraIdSchedule = (
  job?: IntegrationSchedule
): job is MicrosoftEntraIdSchedule =>
  job?.jobOptions.type === ScheduleType.MICROSOFT_ENTRA_ID_IMPORT;

export const isSignavioExportSchedule = (
  job?: IntegrationSchedule
): job is SignavioExportSchedule => {
  return job?.jobOptions?.type === ScheduleType.SIGNAVIO_EXPORT;
};

export const isITPediaSchedule = (
  job?: IntegrationSchedule
): job is ITPediaSchedule => {
  return job?.jobOptions?.type === ScheduleType.ITPEDIA_ENRICHMENT;
};

const isCloudProviderSchedule = (
  cloudProvider: CLOUD_PROVIDER_TYPE,
  job?: IntegrationSchedule
): job is CloudSchedule =>
  job
    ? isCloudSchedule(job) && job.jobOptions.provider === cloudProvider
    : false;

const integrationToIsIntegrationSchedule: Record<
  IntegrationId,
  (job?: IntegrationSchedule) => boolean
> = {
  'excel-v3': () => false,
  'aws-v3': job => isCloudProviderSchedule(CLOUD_PROVIDER_TYPE.AWS, job),
  'microsoft-entra-id': isMicrosoftEntraIdSchedule,
  'azure-v3': job => isCloudProviderSchedule(CLOUD_PROVIDER_TYPE.AZURE, job),
  'servicenow-v3': isServiceNowSchedule,
  'signavio-exporter': isSignavioExportSchedule,
  'it-pedia': isITPediaSchedule,
  ...createUnifiedIntegrationRecord(
    integrationId => job =>
      isUnifiedScheduleFor(INTEGRATION_ID_TO_SCHEDULE_TYPE[integrationId], job)
  ),
};

export const filterSchedulesByIntegrationId = (
  schedules: IntegrationSchedule[],
  integrationId: IntegrationId
) =>
  schedules.filter(job =>
    integrationToIsIntegrationSchedule[integrationId](job)
  );

export const getScheduleConnectionName = (
  job: IntegrationSchedule,
  connections: Connection[]
) => {
  if (isServiceNowSchedule(job)) {
    return (
      connections.find(
        c => c._id === job.jobOptions.servicenowConfig.connectionId
      )?.name || null
    );
  }

  if (isCloudSchedule(job)) {
    return connections
      .filter(({ _id }) =>
        job.jobOptions.cloudProviderConfig.accountIds.includes(_id)
      )
      .map(({ name }) => name)
      .join(', ');
  }

  if (isUnifiedSchedule(job)) {
    return (
      connections.find(c => c._id === job.jobOptions.sourceConfig.connectionId)
        ?.name || null
    );
  }

  if (isMicrosoftEntraIdSchedule(job)) {
    // TODO: currently, it is done this way because we only have one connection for Microsoft EI integration
    return connections[0]?.name ?? null;
  }

  if (isSignavioExportSchedule(job)) {
    return (
      connections.find(
        connection => connection._id === job.jobOptions.accountId
      )?.name || null
    );
  }

  if (isITPediaSchedule(job)) {
    return (
      connections.find(
        connection => connection._id === job.jobOptions.accountId
      )?.name || null
    );
  }

  return null;
};

export const getScheduleSourceConfig = (schedule: IntegrationSchedule) => {
  if (isUnifiedSchedule(schedule)) {
    return schedule.jobOptions.sourceConfig;
  }
  if (isServiceNowSchedule(schedule)) {
    return schedule.jobOptions.servicenowConfig;
  }
  if (isMicrosoftEntraIdSchedule(schedule)) {
    return schedule.jobOptions.microsoftgraphConfig;
  }
  if (isCloudSchedule(schedule)) {
    return schedule.jobOptions.cloudProviderConfig;
  }

  return {};
};

export const isJobOptionsWithWorkspace = (
  jobOptions: IntegrationSchedule['jobOptions']
): jobOptions is IntegrationSchedule['jobOptions'] & {
  workspace: IntegrationWorkspace;
} => {
  return 'workspace' in jobOptions && Boolean(jobOptions.workspace?.id);
};
