import { action$, extractPayload, ofType } from '@ardoq/rxbeach';
import { navigateToReportBuilder } from '../../router/navigationActions';
import { filter, map, switchMap } from 'rxjs/operators';
import { reportApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';

const reportHasHistoricalData$ = action$.pipe(
  ofType(navigateToReportBuilder),
  extractPayload(),
  map(payload => 'reportId' in payload && payload.reportId),
  filter(Boolean),
  switchMap(reportApi.reportWithHistoricalData),
  map(reportWithHistoricalData => {
    if (isArdoqError(reportWithHistoricalData)) {
      // TODO[ARD-22935] it does not seem like there are any error handling for this case
      logError(reportWithHistoricalData);
      return null;
    }
    return reportWithHistoricalData;
  }),
  filter(Boolean)
);

export default reportHasHistoricalData$;
