import React from 'react';
import {
  isNavigationMenuGroup,
  NavigationMenuItem,
  NavigationMenuSingleItem,
} from '../types';
import { NavigationSidebarMenuItem } from './NavigationSidebarMenuItem';
import { Text } from '@ardoq/typography';
import { NavigationSidebarMenuSection } from './NavigationSidebarMenuSection';
import { FlexBox } from '@ardoq/layout';

const isSelected = (
  item: NavigationMenuSingleItem,
  selectedItemId?: string
): boolean | undefined => {
  if (item.isDisabled) {
    return false;
  }
  if (selectedItemId) {
    return item.id === selectedItemId;
  }
  return item.isSelected;
};

export const NavigationSidebarTree = ({
  item,
  onItemClick,
  selectedItemId,
}: {
  item: NavigationMenuItem;
  selectedItemId?: string;
  onItemClick?: (item: NavigationMenuItem) => void;
}) => {
  if (!isNavigationMenuGroup(item)) {
    return (
      <NavigationSidebarMenuItem
        iconName={item.iconName}
        label={item.label}
        isSelected={isSelected(item, selectedItemId)}
        onClick={() => {
          item.onClick?.(item);
          onItemClick?.(item);
        }}
        dataTestId={item.dataTestId}
        dataClickId={item.dataClickId}
        isDisabled={item.isDisabled}
        rightContent={
          item.rightText && (
            <Text variant="caption" color="textSubtle">
              {item.rightText}
            </Text>
          )
        }
      />
    );
  }
  return (
    <NavigationSidebarMenuSection item={item} onItemClick={onItemClick}>
      <FlexBox flexDirection="column" paddingLeft="xlarge">
        {item.items.map(subItem => (
          <NavigationSidebarTree
            key={subItem.label}
            item={subItem}
            onItemClick={onItemClick}
            selectedItemId={selectedItemId}
          />
        ))}
      </FlexBox>
    </NavigationSidebarMenuSection>
  );
};
