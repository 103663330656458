import { ButtonType } from '@ardoq/button';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  DryRunStatus,
  TransferDirection,
  TransferState,
} from '../streams/transferState/types';
import { ScheduleState } from '../streams/schedule/schedule$';
import { resetIntegration } from '../streams/activeIntegrations/actions';
import { openWorkspaces } from 'appContainer/DashboardContainer/utils';
import { interposeSaveReminder } from '../streams/transferConfigs/actions';
import {
  IntegrationId,
  TabularMapping,
} from '../streams/tabularMappings/types';
import { navigateToPath } from '../navigation/actions';
import { isValidTabularMapping } from '../streams/tabularMappings/utils';
import { transferRun } from '../streams/transferState/actions';
import { ExportRoute, ImportRoute } from '../navigation/types';
import {
  isSuccessTransfer,
  successStateToDryRunStatus,
} from '../streams/transferState/utils';
import { upsertSchedule } from '../streams/schedule/actions';
import { Maybe } from '@ardoq/common-helpers';
import { StickyFooterProps } from '../components/stickyFooter/StickyFooter';
import { IntegrationConnectionsState } from '../streams/connections/types';
import { IconName } from '@ardoq/icons';
import { TabularMappingErrors } from '../streams/tabularMappingErrors/types';

export type FooterButtonParams = {
  integrationId: IntegrationId;
  integrationName: string;
  integrationPath: Maybe<string>;
  isDataSelectionValid: boolean;
  onDataSelectionNext: () => void;
  tabularMapping: TabularMapping;
  tabularMappingErrors: TabularMappingErrors;
  transferState: TransferState;
  scheduleState: ScheduleState;
  connectionsState?: IntegrationConnectionsState;
};

function onOpenWorkspaces(
  integrationId: IntegrationId,
  transferState: TransferState,
  schedule: ScheduleState
) {
  const selectedWorkspaces =
    transferState.requestStatus === 'SUCCESS'
      ? transferState.selectedWorkspaces
      : [];
  const onDone = () => {
    dispatchAction(resetIntegration(integrationId));
    openWorkspaces(selectedWorkspaces);
  };
  if (schedule.loadedScheduleId) {
    return onDone();
  }
  dispatchAction(
    interposeSaveReminder({
      integrationId,
      onDone,
    })
  );
}

function onOpenIntegrationExternalLink(
  connectionsState?: IntegrationConnectionsState
) {
  const selectedConnection = connectionsState?.connections.find(c =>
    connectionsState.selectedConnectionIds.includes(c._id)
  );
  if (selectedConnection && 'baseUrl' in selectedConnection) {
    window.open(selectedConnection.baseUrl, '_blank');
  }
}

// Since data selection is the only step that varies across the integrations
// we need the validation and click action should be coming from the individual integrations
function getSelectDataFooterButtons({
  isDataSelectionValid,
  onDataSelectionNext,
}: FooterButtonParams) {
  return [
    {
      text: 'Next: Configure data',
      type: ButtonType.PRIMARY,
      isDisabled: !isDataSelectionValid,
      onClick: onDataSelectionNext,
    },
  ];
}

function getConfigureFooterButtons({
  integrationId,
  tabularMapping,
  tabularMappingErrors,
  transferState,
}: FooterButtonParams) {
  const transferDirectionText =
    transferState.transferDirection === TransferDirection.IMPORT
      ? 'import'
      : 'export';
  return [
    {
      text: 'Back',
      type: ButtonType.GHOST,
      onClick: () =>
        dispatchAction(
          navigateToPath({ integrationId, path: ImportRoute.SELECT_DATA })
        ),
      isDisabled: false,
    },
    {
      text: `Next: Review ${transferDirectionText}`,
      type: ButtonType.PRIMARY,
      isDisabled: !isValidTabularMapping(tabularMapping, tabularMappingErrors),
      onClick: () =>
        dispatchAction(
          transferRun({ integrationId, async: true, dryRun: true })
        ),
    },
  ];
}

function getReviewFooterButtons({
  integrationId,
  transferState,
}: FooterButtonParams) {
  const transferDirectionText =
    transferState.transferDirection === TransferDirection.IMPORT
      ? 'Import'
      : 'Export';
  const status = isSuccessTransfer(transferState)
    ? successStateToDryRunStatus(transferState)
    : DryRunStatus.ERROR;
  const transferSucceeded = status !== DryRunStatus.ERROR;
  const popoverText = `Review errors before ${transferDirectionText.toLowerCase()}ing your data`;
  return [
    {
      text: 'Back',
      type: ButtonType.GHOST,
      onClick: () =>
        dispatchAction(
          navigateToPath({ integrationId, path: ImportRoute.CONFIGURE })
        ),
      isDisabled: false,
    },
    {
      text: `Next: ${transferDirectionText} my data`,
      type: ButtonType.PRIMARY,
      isDisabled: !transferSucceeded,
      popoverText: !transferSucceeded ? popoverText : undefined,
      onClick: () =>
        dispatchAction(
          transferRun({
            integrationId,
            async: true,
            dryRun: false,
          })
        ),
    },
  ];
}

function getImportAndScheduleFooterButtons({
  integrationId,
  integrationName,
  scheduleState,
  transferState,
  connectionsState,
}: FooterButtonParams) {
  const transferSucceeded = isSuccessTransfer(transferState);
  const toBeScheduled = scheduleState.scheduleStage === 'toBeScheduled';
  if (!transferSucceeded) {
    return [];
  }
  const isImport = transferState.transferDirection === TransferDirection.IMPORT;
  return [
    {
      text: toBeScheduled ? 'Back' : `Back to ${integrationName}`,
      type: ButtonType.GHOST,
      isDisabled: false,
      onClick: () =>
        dispatchAction(
          toBeScheduled
            ? navigateToPath({ integrationId, path: ImportRoute.REVIEW })
            : resetIntegration(integrationId)
        ),
    },
    {
      text: `Open ${isImport ? 'workspaces' : integrationName}`,
      type: toBeScheduled ? ButtonType.SECONDARY : ButtonType.PRIMARY,
      isDisabled: false,
      onClick: () =>
        isImport
          ? onOpenWorkspaces(integrationId, transferState, scheduleState)
          : onOpenIntegrationExternalLink(connectionsState),
      iconName: !isImport ? IconName.OPEN_IN_NEW : undefined,
    },
    ...(toBeScheduled
      ? [
          {
            text: scheduleState.loadedScheduleId
              ? 'Update & Activate schedule'
              : 'Create schedule',
            type: ButtonType.PRIMARY,
            isDisabled: Boolean(scheduleState.scheduleForm.errorMessage),
            onClick: () => {
              dispatchAction(
                upsertSchedule({
                  formData: {
                    policy: scheduleState.scheduleForm.policy,
                    name: scheduleState.scheduleForm.name.trim(),
                    managesWorkspace:
                      scheduleState.scheduleForm.managesWorkspace,
                  },
                  integrationId,
                })
              );
            },
          },
        ]
      : []),
  ];
}

/**
 * isDataSelectionValid and onDataSelectionNext are for checking if data selection is valid and what to do on next click.
 * Data selection is the only step that varies across the integrations that means every integraiton would have its own validation
 * and click action for the next button.
 * @returns
 */

export function getFooterButtons(
  params: FooterButtonParams
): StickyFooterProps['buttons'] {
  switch (params.integrationPath) {
    case ImportRoute.SELECT_DATA:
    case ExportRoute.SELECT_DATA:
      return getSelectDataFooterButtons(params);
    case ImportRoute.CONFIGURE:
    case ExportRoute.CONFIGURE:
      return getConfigureFooterButtons(params);
    case ImportRoute.REVIEW:
    case ExportRoute.REVIEW:
      return getReviewFooterButtons(params);
    case ImportRoute.IMPORT_AND_SCHEDULE:
    case ExportRoute.EXPORT:
      return getImportAndScheduleFooterButtons(params);
    default:
      return [];
  }
}
