import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import {
  ContactYourAdminLink,
  CreatorNameAndEmailContainer,
  DeprecatedSlideNotificationDetailsContainer,
  LeftBoundFlexContainerNoTextWrap,
  NotificationMessageContainer,
  SlideCreatedByEmail,
  SlideCreatedByInfoContainer,
  SlideCreatedByName,
  SlideCreatedByPersonIcon,
  ViewMigrationGuideLinkWithProps,
  ViewStatusTextBadgeContainer,
  WHY_CANT_I_MIGRATE_SLIDES_POPOVER_ID,
  WhyCantIMigrateSlidesLabel,
} from 'viewDeprecation/atoms';
import {
  DeprecatedSlideNotificationDetailsArgs,
  DeprecationPhasesInPresentationScope,
  OverallPresentationViewInfo,
  PresentationSlideViewInfo,
  SlidePermisionsInPresentationScope,
} from 'viewDeprecation/types';
import ViewStatusTextBadge from 'views/ViewStatusTextBadge';
import currentUser from 'models/currentUser';
import { POPOVER_ID_ATTR } from '@ardoq/popovers';

type SlideCreatorDetailsProps = {
  createdByName: string;
  createdByEmail: string;
  showCreatorDetails?: boolean;
};
export const SlideCreatorDetails = ({
  createdByName,
  createdByEmail,
  showCreatorDetails,
}: SlideCreatorDetailsProps) => (
  <SlideCreatedByInfoContainer>
    <SlideCreatedByPersonIcon />
    <CreatorNameAndEmailContainer>
      {showCreatorDetails ? (
        <>
          <SlideCreatedByName>{createdByName}</SlideCreatedByName>
          <SlideCreatedByEmail>{createdByEmail}</SlideCreatedByEmail>
        </>
      ) : (
        <SlideCreatedByName>
          <ContactYourAdminLink />
        </SlideCreatedByName>
      )}
    </CreatorNameAndEmailContainer>
  </SlideCreatedByInfoContainer>
);

export const DeprecatedSlideNotificationDetails = ({
  deprecatedSlides,
  canEditPresentation,
  overallPresentationViewInfo,
  slidesMigrationInfo,
}: DeprecatedSlideNotificationDetailsArgs) => {
  // show creator contact details for slides witout permissions
  // show 'contact your admin' for slides where creator can not migrate
  const canCreatorMigrate = (slideId: string) =>
    !!slidesMigrationInfo?.[slideId]?.creatorCanMigrate;

  const soonToBeDiscontinuedNoPermissions: PresentationSlideViewInfo[] = [];
  const discontinuedNoPermissions: PresentationSlideViewInfo[] = [];

  const isMixedPhase =
    overallPresentationViewInfo.overallDeprecationPhase ===
    DeprecationPhasesInPresentationScope.MIXED_PHASES;

  deprecatedSlides.forEach(slideInfo => {
    if (!canEditPresentation || !slideInfo.hasFullAccess) {
      if (slideInfo.viewStatus === MetaInfoViewStatus.SOON_TO_BE_DISCONTINUED) {
        soonToBeDiscontinuedNoPermissions.push(slideInfo);
      } else if (slideInfo.viewStatus === MetaInfoViewStatus.DISCONTINUED) {
        discontinuedNoPermissions.push(slideInfo);
      }
    }
  });

  if (currentUser.isOrgAdmin()) return null;
  return soonToBeDiscontinuedNoPermissions.length ||
    discontinuedNoPermissions.length ? (
    <DeprecatedSlideNotificationDetailsContainer>
      {isMixedPhase && soonToBeDiscontinuedNoPermissions.length ? (
        <NotificationMessageContainer>
          <ViewStatusTextBadgeContainer>
            <ViewStatusTextBadge
              viewStatus={MetaInfoViewStatus.SOON_TO_BE_DISCONTINUED}
            />
          </ViewStatusTextBadgeContainer>
        </NotificationMessageContainer>
      ) : null}
      {soonToBeDiscontinuedNoPermissions?.map(
        ({ slideName, createdByName, createdByEmail, slideId }, index) => (
          <div key={index}>
            <div>{slideName}:</div>
            {SlideCreatorDetails({
              createdByName,
              createdByEmail,
              showCreatorDetails: canCreatorMigrate(slideId),
            })}
          </div>
        )
      )}
      {isMixedPhase && discontinuedNoPermissions.length ? (
        <NotificationMessageContainer>
          <ViewStatusTextBadgeContainer>
            <ViewStatusTextBadge viewStatus={MetaInfoViewStatus.DISCONTINUED} />
          </ViewStatusTextBadgeContainer>
        </NotificationMessageContainer>
      ) : null}
      {discontinuedNoPermissions?.map(
        ({ slideName, createdByName, createdByEmail, slideId }, index) => (
          <div key={index}>
            <div>{slideName}:</div>
            {SlideCreatorDetails({
              createdByName,
              createdByEmail,
              showCreatorDetails: canCreatorMigrate(slideId),
            })}
          </div>
        )
      )}
      {overallPresentationViewInfo.overallPermissionsLevel !==
        SlidePermisionsInPresentationScope.FULL_PERMISSIONS && (
        <WhyCantIMigrateSlidesLabel
          {...{ [POPOVER_ID_ATTR]: WHY_CANT_I_MIGRATE_SLIDES_POPOVER_ID }}
        >
          Why can&apos;t I migrate slides?
        </WhyCantIMigrateSlidesLabel>
      )}
    </DeprecatedSlideNotificationDetailsContainer>
  ) : null;
};

type CallToActionProps = {
  permissions: SlidePermisionsInPresentationScope;
  onlyAdminsCanMigrate: boolean;
  creatorCanPartiallyMigrate: boolean;
  isCurrentUserCreator: boolean;
};
const CallToActionMessage = ({
  permissions,
  onlyAdminsCanMigrate,
  creatorCanPartiallyMigrate,
  isCurrentUserCreator,
}: CallToActionProps) => {
  if (onlyAdminsCanMigrate) {
    return (
      <LeftBoundFlexContainerNoTextWrap>
        <ContactYourAdminLink />
        <span>&nbsp;to migrate slides to the new view.</span>
      </LeftBoundFlexContainerNoTextWrap>
    );
  }
  if (permissions === SlidePermisionsInPresentationScope.FULL_PERMISSIONS) {
    return (
      <NotificationMessageContainer>
        <ViewMigrationGuideLinkWithProps />
      </NotificationMessageContainer>
    );
  } else if (
    permissions === SlidePermisionsInPresentationScope.LIMITED_PERMISSIONS
  ) {
    return (
      <NotificationMessageContainer>
        Contact the slide creator
        {creatorCanPartiallyMigrate ? ' or admin' : null} to migrate slides.
      </NotificationMessageContainer>
    );
  } else if (
    permissions === SlidePermisionsInPresentationScope.MIXED_PERMISSIONS
  ) {
    return (
      <>
        <NotificationMessageContainer>
          <ViewMigrationGuideLinkWithProps />
        </NotificationMessageContainer>
        <NotificationMessageContainer>
          In cases where you don’t have workplace access, contact{' '}
          {isCurrentUserCreator || onlyAdminsCanMigrate
            ? 'your admin'
            : creatorCanPartiallyMigrate
              ? 'the slide creator or your admin'
              : 'the slide creator'}{' '}
          to migrate.
        </NotificationMessageContainer>
      </>
    );
  }

  return null;
};

type SolutionAdviceArgs = {
  isCurrentUserCreator: boolean;
  overallPresentationViewInfo: OverallPresentationViewInfo;
  creatorCanMigrateNothing: boolean;
  creatorCanMigrateSome: boolean;
};
export const SolutionAdvice = ({
  isCurrentUserCreator,
  overallPresentationViewInfo,
  creatorCanMigrateNothing,
  creatorCanMigrateSome,
}: SolutionAdviceArgs) => (
  <>
    <CallToActionMessage
      permissions={overallPresentationViewInfo.overallPermissionsLevel!}
      onlyAdminsCanMigrate={creatorCanMigrateNothing}
      creatorCanPartiallyMigrate={creatorCanMigrateSome}
      isCurrentUserCreator={isCurrentUserCreator}
    />
    {creatorCanMigrateNothing ? (
      <WhyCantIMigrateSlidesLabel
        {...{ [POPOVER_ID_ATTR]: WHY_CANT_I_MIGRATE_SLIDES_POPOVER_ID }}
      >
        &apos;Why can&apos;t I migrate slides?&apos;
      </WhyCantIMigrateSlidesLabel>
    ) : null}
  </>
);
