import { FieldsWrapper, Label, TextInput } from '@ardoq/forms';
import { StyledSpace } from './questionAtoms';
import {
  PersistedTextQuestion,
  UnpersistedTextQuestion,
} from '@ardoq/api-types';
import { RichTextEditorTransition } from '@ardoq/rich-text-editor-2024';

interface TextSectionProperties {
  question: PersistedTextQuestion | UnpersistedTextQuestion;
  updateQuestion: (
    question: PersistedTextQuestion | UnpersistedTextQuestion
  ) => void;
}

const TextSection = ({ question, updateQuestion }: TextSectionProperties) => (
  <>
    <FieldsWrapper>
      <TextInput
        label="Text Section Heading"
        value={question.label}
        onChange={event =>
          updateQuestion({ ...question, label: event.target.value })
        }
        dataTestId="text-heading-input"
      />
    </FieldsWrapper>
    <FieldsWrapper>
      <StyledSpace $isVertical $gap="s4">
        <Label>Text Section Body</Label>
        <RichTextEditorTransition
          content={question.description}
          onInputChange={description =>
            updateQuestion({ ...question, description })
          }
          dataTestId="text-section-input"
        />
      </StyledSpace>
    </FieldsWrapper>
  </>
);
export default TextSection;
