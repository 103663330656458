import { combineLatest, map } from 'rxjs';
import assetsBrowser2024$ from '../components/AssetsBrowser2024/assetsBrowser2024$';
import viewpoints$ from '../viewpoints/viewpoints$';
import { viewpointAccessControlInterface } from '../resourcePermissions/accessControlHelpers/viewpoints';
import { getAssetTypesInHomePage } from './homePageUtils';
import { HomePageConnectedProps } from './HomePage';

const homePage$ = combineLatest([assetsBrowser2024$, viewpoints$]).pipe(
  map(
    ([
      { permissions, assetsById, commands, features, appModuleStates },
      { viewpoints },
    ]): HomePageConnectedProps => {
      const discoverViewpointsHidden =
        viewpointAccessControlInterface.getDiscoverViewpointOverViewHidden(
          viewpoints
        );
      const assetTypesInHomePage = getAssetTypesInHomePage(
        discoverViewpointsHidden,
        features
      );
      return {
        permissions,
        assetsById,
        commands,
        appModuleStates,
        features,
        assetTypesInHomePage,
      };
    }
  )
);

export default homePage$;
