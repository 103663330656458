import { connect, derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { CreateNewButton } from '@ardoq/page-layout';
import MainToolbar from 'menus/topbar/MainToolbar';
import Navbar from 'views/navbar/Navbar';
import {
  PresentationOverviewCommands,
  presentationOverviewCommands,
} from './presentationOverviewCommands';
import currentUser$, {
  CurrentUserState,
  getIsOrgWriter,
} from 'streams/currentUser/currentUser$';
import { PropsWithChildren } from 'react';
import LegacySwitch from './ListModeSwitch';
import { PageWrapper } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';

type PresentationsNavBarProps = {
  canUserCreatePresentations: boolean;
  commands: PresentationOverviewCommands;
};

const PresentationsNavBar = ({
  canUserCreatePresentations,
  commands,
  children,
}: PropsWithChildren<PresentationsNavBarProps>) => {
  return (
    <PageWrapper>
      <Navbar
        primaryContent="Presentations"
        secondaryContent="Overview"
        toolbarContent={
          <FlexBox align="center" gap="medium" flex={1}>
            <MainToolbar shouldUseNewJourneyVersion={true} />
            <LegacySwitch />
          </FlexBox>
        }
        primaryButton={
          canUserCreatePresentations && (
            <CreateNewButton
              dataTestId="create-presentation-button"
              onClick={commands.openPresentationBuilder}
            />
          )
        }
      />
      {children}
    </PageWrapper>
  );
};

const toPresentationsNavBarStream = ([currentUser]: [CurrentUserState]) => {
  return {
    commands: presentationOverviewCommands,
    canUserCreatePresentations: getIsOrgWriter(currentUser),
  };
};

const presentationsNavBar$ = derivedStream(
  'presentationsNavBar$',
  currentUser$
).pipe(map(toPresentationsNavBarStream));

export default connect(PresentationsNavBar, presentationsNavBar$);
