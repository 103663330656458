import { ConfigsSelector } from '../configSelector/ConfigSelector';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { Maybe } from '@ardoq/common-helpers';
import { header4 } from '@ardoq/typography';
import styled from 'styled-components';
import { SecondaryButton } from '@ardoq/button';
import { ApplicationIcon } from '@ardoq/icons';
import { FlexBox, Stack } from '@ardoq/layout';

const SelectorContainer = styled.div`
  max-width: 448px;
`;

const SelectContainer = styled(Stack).attrs({
  gap: 'small',
})`
  width: 600px;
`;

const GrowingContainer = styled.div`
  flex-grow: 2;
`;

const SelectLabel = styled.div`
  ${header4};
`;

type ConfigsSectionProps = {
  transferConfigs: SavedTransferConfig[];
  selectedConfigId: Maybe<SavedTransferConfig['_id']>;
  isEmptyTabularMapping: boolean;
  onMappingConfigSelect: (id: string | null) => void;
  onConfigClear: VoidFunction;
  onOverviewClick: VoidFunction;
};
export const ConfigsSection = ({
  transferConfigs,
  selectedConfigId,
  isEmptyTabularMapping,
  onMappingConfigSelect,
  onConfigClear,
  onOverviewClick,
}: ConfigsSectionProps) => (
  <FlexBox justify="space-between" padding="medium" align="start">
    <SelectContainer>
      <SelectLabel>Use mappings from a saved configuration</SelectLabel>
      <FlexBox gap="small">
        <GrowingContainer>
          <SelectorContainer>
            <ConfigsSelector
              configs={transferConfigs}
              selectedConfigId={selectedConfigId}
              onSelect={onMappingConfigSelect}
              placeholder="Type and select saved configurations"
              isOptional={true}
            />
          </SelectorContainer>
        </GrowingContainer>
        <SecondaryButton
          isDisabled={isEmptyTabularMapping}
          onClick={onConfigClear}
          dataTestId="tabularMapping-clear-all-configuration-btn"
        >
          Clear configurations
        </SecondaryButton>
      </FlexBox>
    </SelectContainer>
    <SecondaryButton onClick={onOverviewClick}>
      <ApplicationIcon /> Overview
    </SecondaryButton>
  </FlexBox>
);
