import { AccessControlPageStream } from './types';

const emptyState = (): AccessControlPageStream['resources'] => ({
  navigationState: undefined,
  organization: undefined,
  privileges: undefined,
  orgUsers: undefined,
  rolesShape: undefined,
  permissionGroup: undefined,
});

const updateResources = (
  state: AccessControlPageStream['resources'],
  update: AccessControlPageStream['resources']
): AccessControlPageStream['resources'] => ({
  ...state,
  ...update,
});

export const accessControlPageResourcesOperations = {
  emptyState,
  updateResources,
};
