import { NodeModel } from '../models/types';
import {
  NavigatorConfiguration,
  NavigatorViewInterface,
  PartialNavigatorState,
} from '../types';
import { LayoutBoxModel } from '../types';
import { getCssClassFromDiffType } from 'scope/modelUtil';
import { classes } from '@ardoq/common-helpers';
import {
  COMPONENT_ITEM_CLASS_NAME,
  DRAG_TARGET_CLASS_NAME,
  NAVIGATOR_BACKGROUND,
  NAVIGATOR_ITEM_ACTIVE_BACKGROUND,
  NAVIGATOR_ITEM_SELECTED_BACKGROUND_OPAQUE,
  WORKSPACE_ITEM_CLASS_NAME,
} from '../consts';
import { ensureContrast } from '@ardoq/color-helpers';
import { Features, hasFeature } from '@ardoq/features';
import { CONDITIONAL_IMAGE_COLORING_CLASS_NAME } from '@ardoq/global-consts';
import { DiffType } from '@ardoq/data-model';
import { IconName } from '@ardoq/icons';
import {
  CSS_CLASS_IGNORE_LINK_TARGET,
  NavigatorDropType,
  NodeModelTypes,
  SHIFT_X,
  SHIFT_Y,
  TRANSITION_DURATION,
} from '../utils/consts';
import { DiffMode } from '@ardoq/api-types';
import { CSSProperties } from 'react';
import {
  ComponentItemProps,
  WorkspaceItemProps,
  ScenarioItemProps,
  DragTargetProps,
  NavigatorContainerProps,
} from '../components/PropTypes';

export const stateToRenderProps = (
  state: PartialNavigatorState
): Omit<NavigatorContainerProps, 'dragElementRoot'> => {
  const {
    rootLayoutBox,
    isInDiffMode,
    isViewpointMode,
    isScenarioMode,
    tree,
    activeDiffMode,
    dropType,
    isLinking,
    eventListeners,
    navigatorViewInterface,
    navigatorConfiguration,
    isInitialized,
    dataLoadingMessage,
    dataErrorMessage,
  } = state;
  const selection = tree.getSelection();
  const isMultiselectActive = selection.length > 1;
  const canMultiselect = selection.every(node => node.hasWriteAccess);
  return {
    eventListeners,
    navigatorContentStyle: {
      height: `${rootLayoutBox.scrollHeight}px`,
    },
    navigatorContainerClassName: classes(
      CSS_CLASS_IGNORE_LINK_TARGET,
      dropType,
      isLinking && 'is-linking'
    ),
    dragGhostProps: getDragGhostProps(state),
    parentDropTargetIndicatorStyle: getParentDropTargetIndicatorStyle(state),
    layoutBoxesContainerStyle: {
      top: `${rootLayoutBox.children[0]?.top ?? 0}px`,
    },
    actionNamespace: navigatorConfiguration.actionNamespace,
    renderProps: rootLayoutBox.children.map(layoutBoxModel =>
      layoutBoxToRenderProps({
        ...layoutBoxModel,
        selection,
        isInDiffMode,
        isViewpointMode,
        isMultiselectActive,
        canMultiselect,
        isScenarioMode,
        activeDiffMode,
        navigatorViewInterface,
        navigatorConfiguration,
      })
    ),
    isInitialized,
    dataLoadingMessage,
    dataErrorMessage,
  };
};

type ToRenderPropsArgs = LayoutBoxModel & {
  selection: NodeModel[];
  isInDiffMode: boolean;
  isViewpointMode: boolean;
  isMultiselectActive: boolean;
  isScenarioMode: boolean;
  canMultiselect: boolean;
  activeDiffMode: DiffMode;
  navigatorViewInterface: NavigatorViewInterface;
  navigatorConfiguration: NavigatorConfiguration;
};

const layoutBoxToRenderProps = (
  props: ToRenderPropsArgs
): Record<string, any> => {
  if (props.node.type === NodeModelTypes.COMPONENT) {
    return layoutBoxToComponentItemProps(props);
  }
  if (props.node.type === NodeModelTypes.WORKSPACE) {
    return layoutBoxToWorkspaceItemProps(props);
  }
  if (props.node.type === NodeModelTypes.SCENARIO) {
    return layoutBoxToScenarioItemProps(props);
  }
  return {};
};

const layoutBoxToComponentItemProps = ({
  node,
  left,
  classNames = [],
  selection,
  isInDiffMode,
  isViewpointMode,
  isMultiselectActive,
  canMultiselect,
  navigatorViewInterface,
}: ToRenderPropsArgs): ComponentItemProps => {
  const showContextMenuButton = !(
    isMultiselectActive &&
    !canMultiselect &&
    selection.includes(node)
  );
  const containerStyle = {
    paddingLeft: `${left}px`,
  };
  const filterExcludedClass = !node.isIncludedInContextByFilter() && 'excluded';
  const containerClassNames = classes(
    COMPONENT_ITEM_CLASS_NAME,
    ...classNames,
    filterExcludedClass
  );
  const visualDiffClass = isInDiffMode
    ? getCssClassFromDiffType(node.getVisualDiffType())
    : '';
  const isLinking = classNames.includes('linking');
  const componentRepresentationData = node.getRepresentationData();
  const nodeSubdivisions = node.getSubdivisions();

  const background = classNames.includes('selected')
    ? NAVIGATOR_ITEM_SELECTED_BACKGROUND_OPAQUE
    : classNames.includes('active')
      ? NAVIGATOR_ITEM_ACTIVE_BACKGROUND
      : NAVIGATOR_BACKGROUND;

  // to get class name with image color filter
  const nodeClassNames = componentRepresentationData?.isImage
    ? navigatorViewInterface.getComponentCssClassNames(node.id)
    : '';
  const { fill } =
    navigatorViewInterface.getComponentDisplayColorAsSVGAttributes(node.id, {
      useAsBackgroundStyle: false,
    }) ?? { fill: undefined };
  const componentColor = ensureContrast(background, fill ?? 'black');
  const canManageMemberships = navigatorViewInterface.currentUserIsOrgAdmin();
  const hasNoSubdivisions = nodeSubdivisions.length === 0;
  const workspacesSubdivisionIds = node.getWorkspaceBoundSubdivisionsIds();
  const shouldShowSubdivisionWarning =
    !isViewpointMode &&
    !!workspacesSubdivisionIds.length &&
    hasFeature(Features.PERMISSION_ZONES) &&
    hasNoSubdivisions &&
    canManageMemberships &&
    isRootComponent(node);
  const iconWrapperClassNames = classes(
    'icon-container',
    'component',
    node.getModelTypeId(),
    node.id,
    visualDiffClass,
    visualDiffClass && 'visual-diff-background-circle',
    nodeClassNames,
    CONDITIONAL_IMAGE_COLORING_CLASS_NAME
  );
  const iconWrapperStyle = { fill: componentColor, color: componentColor };
  const isExternallyManaged =
    navigatorViewInterface.isComponentExternallyManaged(node.id);
  const isExternallyMissing =
    navigatorViewInterface.isComponentExternallyMissing(node.id);
  const isLocked = node.getLock();
  const isDiffTypeChanged = node.getVisualDiffType() === DiffType.CHANGED;
  const hasWriteAccess = node.hasWriteAccess;
  const linkIconName = isLinking ? IconName.NONE : IconName.REFERENCE;
  const name = node.getName();
  const aggregatedVisualDiffState = node.aggregatedVisualDiffState;
  const nodeId = node.id;
  const hasVisibleContent = node.hasVisibleContent();
  const isExpanded = !node.isCollapsed;

  return {
    type: NodeModelTypes.COMPONENT,
    name,
    showContextMenuButton,
    containerStyle,
    containerClassNames,
    componentRepresentationData,
    shouldShowSubdivisionWarning,
    iconWrapperClassNames,
    iconWrapperStyle,
    isExternallyManaged,
    isExternallyMissing,
    isLocked,
    isDiffTypeChanged,
    hasWriteAccess,
    linkIconName,
    isInDiffMode,
    aggregatedVisualDiffState,
    nodeId,
    hasVisibleContent,
    isExpanded,
  };
};

const isRootComponent = (node: NodeModel) => {
  const parent = node.parent;
  return !parent || parent.type === 'workspace';
};

const layoutBoxToWorkspaceItemProps = ({
  node,
  left,
  classNames = [],
  isViewpointMode,
  isScenarioMode,
  navigatorViewInterface,
  navigatorConfiguration: { eventHandlers, showWorkspaceCloseButton },
}: ToRenderPropsArgs): WorkspaceItemProps => {
  const containerStyle = {
    paddingLeft: `${left}px`,
  };
  const containerClassNames = classes(
    WORKSPACE_ITEM_CLASS_NAME,
    ...classNames.filter(className => className !== DRAG_TARGET_CLASS_NAME)
  );
  const name = node.getName();
  const nodeId = node.id;
  const hasVisibleContent = node.hasVisibleContent();
  const isExpanded = !node.isCollapsed;
  const isExternallyManaged =
    navigatorViewInterface.isWorkspaceExternallyManaged(node.id);
  const hasCloseButton = Boolean(
    eventHandlers.onWorkspaceCloseClick &&
      (!isViewpointMode || showWorkspaceCloseButton)
  );
  const hasContextMenu = !isScenarioMode;

  return {
    type: NodeModelTypes.WORKSPACE,
    containerStyle,
    containerClassNames,
    name,
    nodeId,
    hasVisibleContent,
    isExpanded,
    isExternallyManaged,
    hasCloseButton,
    hasContextMenu,
  };
};

const layoutBoxToScenarioItemProps = ({
  node,
  left,
  classNames = [],
  activeDiffMode,
  isInDiffMode,
}: ToRenderPropsArgs): ScenarioItemProps => {
  const containerStyle = {
    paddingLeft: `${left}px`,
  };
  const containerClassNames = classes(
    'scenario-item',
    ...classNames.filter(className => className !== 'drag-target')
  );
  const nodeId = node.id;
  const hasVisibleContent = node.hasVisibleContent();
  const isExpanded = !node.isCollapsed;
  const scenarioIconName = getScenarioIconName(
    isInDiffMode ? activeDiffMode : null
  );
  const name = node.getName();
  return {
    type: NodeModelTypes.SCENARIO,
    containerStyle,
    containerClassNames,
    nodeId,
    hasVisibleContent,
    isExpanded,
    scenarioIconName,
    name,
  };
};

const getScenarioIconName = (activeDiffMode: DiffMode | null) => {
  if (activeDiffMode === DiffMode.DIFF) return IconName.DIFF;
  if (activeDiffMode === DiffMode.MAIN) return IconName.MASTER;
  return IconName.SCENARIO;
};

const getDragGhostProps = ({
  dragPosition,
  dragTargetNode,
  isDrag,
  container,
  rootLayoutBox,
  eventListeners,
  isDragEndTransition,
  isDropHandled,
  dropTargetLayoutBox,
  selectionSize,
}: PartialNavigatorState): DragTargetProps => {
  const shouldShowGhost = isDrag || isDragEndTransition;
  if (!(dragTargetNode && shouldShowGhost)) {
    return {
      shouldShowGhost,
      ghostStyle: { left: '0', top: '0' },
      ghostText: '',
      targetId: '',
    };
  }

  const ghostStyle: CSSProperties = {
    left: `${dragPosition.x + SHIFT_X}px`,
    top: `${dragPosition.y + SHIFT_Y}px`,
  };
  if (isDragEndTransition) {
    ghostStyle.opacity = 0;
    ghostStyle.transition = ['opacity', 'left', 'top']
      .map(prop => `${prop} ${TRANSITION_DURATION}ms`)
      .join(', ');
    const fadeOutLayoutBox = isDropHandled
      ? dropTargetLayoutBox
      : rootLayoutBox.layoutMap.get(dragTargetNode.id);
    if (fadeOutLayoutBox) {
      const layoutBox = fadeOutLayoutBox.toViewportCoordinates(container);
      ghostStyle.left = `${layoutBox.left}px`;
      ghostStyle.top = `${layoutBox.top}px`;
    }
  }

  const onTransitionEnd = isDragEndTransition
    ? eventListeners.onTransitionEnd
    : undefined;

  const ghostText =
    selectionSize > 1
      ? `${selectionSize} components`
      : dragTargetNode.getName();

  return {
    shouldShowGhost,
    ghostStyle,
    ghostText,
    onTransitionEnd,
    targetId: dragTargetNode.id,
  };
};

const getParentDropTargetIndicatorStyle = ({
  isDropTargetParent,
  dropTargetLayoutBox,
  isDropTargetBefore,
  dropType,
}: PartialNavigatorState): CSSProperties | null => {
  if (!(isDropTargetParent && dropTargetLayoutBox)) return null;
  const isNoDrop = dropType === NavigatorDropType.DROP_TYPE_NO_DROP;
  return {
    top: `${dropTargetLayoutBox.top + (isDropTargetBefore ? 0 : 26)}px`,
    left: `${dropTargetLayoutBox.left}px`,
    opacity: `${isNoDrop ? '0.5' : '1'}`,
    cursor: `${isNoDrop ? 'no-drop' : 'pointer'}`,
  };
};
