import { APIComponentAttributes, APIEntityType } from '@ardoq/api-types';
import { allocateToBuffer } from '@ardoq/rxbeach';
import { filter, map } from 'rxjs';
import { ardoqEventOperations } from 'sync/ardoqEventOperations';
import { ArdoqEvent, isWebSocketUpdate } from 'sync/types';
import { websocket$ } from 'sync/websocket$';

const isWebSocketComponentUpdate = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<APIComponentAttributes> => {
  const entityType = ardoqEventOperations.getEntityType(event);
  return isWebSocketUpdate(event) && entityType === APIEntityType.COMPONENT;
};

export const componentsUpdate$ = websocket$.pipe(
  filter(isWebSocketComponentUpdate),
  map(({ data }) => data),
  allocateToBuffer()
);
