import { SourceType } from '@ardoq/api-types/integrations';
import {
  SavedTransferConfig,
  UnifiedSourceConfig,
} from '@ardoq/api-types/integrations';
import { INTEGRATION_ID_TO_SOURCE_TYPES } from '../../constants';
import { UnifiedIntegrationId } from '../../types';
import { Resource } from '../resources/types';
import { Option } from '@ardoq/forms';

export const isUnifiedTransferConfig = (
  integrationId: UnifiedIntegrationId,
  config: SavedTransferConfig
): config is SavedTransferConfig<UnifiedSourceConfig> =>
  INTEGRATION_ID_TO_SOURCE_TYPES[integrationId].every((element: SourceType) =>
    config.sources.includes(element)
  );

export const extractPossibleQueryIds = (resources: Resource[]) => {
  const queryIds: string[] = [];

  const collectValues = (possibleValues: Option[]) => {
    for (const value of possibleValues) {
      queryIds.push(value.name);
      if (value.options) {
        collectValues(value.options);
      }
    }
  };

  for (const resource of resources) {
    if (resource.queryDefinition && resource.queryDefinition.possibleValues) {
      collectValues(resource.queryDefinition.possibleValues);
    }
  }

  return queryIds;
};

export const filterInvalidQueries = (
  sourceConfig: UnifiedSourceConfig,
  resources: Resource[]
) => {
  const queryIds = extractPossibleQueryIds(resources);
  const updatedResources = sourceConfig.resources.map(resource => {
    if (Array.isArray(resource.query)) {
      return {
        ...resource,
        query: resource.query.filter(query => queryIds.includes(query)),
      };
    }
    return resource;
  });

  return { ...sourceConfig, resources: updatedResources };
};
