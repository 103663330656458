import { AssetType, PermissionAccessLevel } from '@ardoq/api-types';
import { SelectOption } from '@ardoq/select';
import { omit } from 'lodash';
import { UserAssetsOverviewQuery } from '../../userAssetsOverview/userAssetsOverviewTypes';
import { userAssetsOverviewQueryOperations } from '../../userAssetsOverview/userAssetsOverviewQueryOperations';
import { optionsHelpers } from './optionsHelpers';

export type UserAssetsFilterSidebarState = {
  isVisible: boolean;
  hasActiveFilters: boolean;
  assetTypes: AssetType[];
  permissionAccessLevelOptions: SelectOption<PermissionAccessLevel>[];
};

const unsupportedAssetTypes: (keyof typeof AssetType)[] = [
  'FOLDER',
  'BOOKMARK',
  'PRESENTATION',
  'METAMODEL',
  'BROADCAST',
];

const getEmpty = (): UserAssetsFilterSidebarState => {
  return {
    isVisible: false,
    hasActiveFilters: false,
    assetTypes: Object.values<AssetType>(
      omit(AssetType, unsupportedAssetTypes)
    ).sort(),
    permissionAccessLevelOptions: Object.values(PermissionAccessLevel).map(
      permissionAccessLevel => {
        const { label, value, iconName } =
          optionsHelpers.permissionLevelToOption(permissionAccessLevel);
        return {
          label,
          value,
          leftIcon: {
            name: iconName,
          },
        };
      }
    ),
  };
};

type SetVisibilityParams =
  | {
      visible: true;
      userAssetsQuery: UserAssetsOverviewQuery;
    }
  | {
      visible: false;
      userAssetsQuery?: never;
    };

const setVisibility = (
  state: UserAssetsFilterSidebarState,
  params: SetVisibilityParams
): UserAssetsFilterSidebarState => {
  const { visible, userAssetsQuery } = params;
  if (visible) {
    return {
      ...state,
      isVisible: visible,
      hasActiveFilters:
        userAssetsOverviewQueryOperations.isNotEmpty(userAssetsQuery),
    };
  }
  return {
    ...state,
    isVisible: visible,
  };
};

const mapAssetTypesToOptions = (state: UserAssetsFilterSidebarState) => {
  return state.assetTypes.map(optionsHelpers.assetTypeToOption);
};

export const userAssetsFilterSidebarStateOperations = {
  getEmpty,
  setVisibility,
  mapAssetTypesToOptions,
};
