import { APIFieldAttributes } from '@ardoq/api-types';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { FieldSelectOption } from 'viewpoints/types';
import { dateRangeOperations } from '@ardoq/date-range';

export const getRelevantFields = (workspaceIds: string[]) =>
  workspaceIds.flatMap(workspaceId =>
    Object.values(fieldInterface.getAllFieldsOfWorkspace(workspaceId) ?? {})
  );

export const isComponentField = (field: APIFieldAttributes) => {
  return Boolean(field.global || field.componentType.length);
};

export const isReferenceField = (field: APIFieldAttributes) => {
  return Boolean(field.globalref || field.referenceType.length);
};

export const correctDateRangeFieldValues = (
  selectedFields: FieldSelectOption[]
) =>
  selectedFields.flatMap(opt => {
    if (!opt.isDateRange) {
      return {
        name: opt.value as string,
        category: opt.category,
      };
    }
    return [
      {
        name: dateRangeOperations.toStartDateName(opt.value),
        category: opt.category,
      },
      {
        name: dateRangeOperations.toEndDateName(opt.value),
        category: opt.category,
      },
    ];
  });

export const getMaxLengthWarnings = (
  value: string | undefined,
  maxLength: number,
  warningMessage: string
) => {
  if (!value) return {};

  return {
    hasWarning: value.length > maxLength,
    warningMessage: value.length > maxLength ? warningMessage : undefined,
    hintMessage:
      value.length <= maxLength
        ? `${value.length ?? 0}/${maxLength} characters.`
        : undefined,
  };
};
