import { fieldInterface } from '@ardoq/field-interface';
import { CreatableSelect, Select } from '@ardoq/select';
import { NewBadge } from '@ardoq/status-ui';
import { CUSTOM_ID_ALLOWED_TYPES } from 'integrations/common/streams/fields/constants';
import { Field as FieldType } from 'integrations/common/streams/fields/types';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import {
  byLocaleField,
  filterFieldsByType,
} from 'integrations/common/utils/common';

type CustomIdFieldSelectorProps = {
  tableMapping: TableMappingMap;
  usedFields: FieldType[];
  allFields: FieldType[];
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
  value: string;
  isCreationDisabled: boolean;
};

export const CustomIdFieldSelector = ({
  tableMapping,
  usedFields,
  allFields,
  onMapping,
  value,
  isCreationDisabled,
}: CustomIdFieldSelectorProps) => {
  const wsFields = tableMapping.rootWorkspace.id
    ? fieldInterface
        .getAllFieldsOfWorkspace(tableMapping.rootWorkspace.id)
        .sort(byLocaleField('label'))
    : [];

  const filteredFields = filterFieldsByType(CUSTOM_ID_ALLOWED_TYPES);
  const filteredUsedFields = filteredFields(usedFields);
  const filteredAllFields = filteredFields(allFields);
  const filteredWsFields = filteredFields(wsFields);

  const FieldNameSelector = isCreationDisabled ? Select : CreatableSelect;

  return (
    <FieldNameSelector
      dataTestId="tabularMapping-custom-id-field-name--select"
      autoFocus
      label="Field name"
      value={value}
      options={[
        {
          options: filteredUsedFields.map(f => ({
            value: f.name || f.label, // support for new fields without name
            label: f.label,
            rightContent: !f.name ? <NewBadge /> : null,
          })),
          label: 'Used in import',
        },
        {
          options: filteredWsFields.map(f => ({
            value: f.name,
            label: f.label,
          })),
          label: 'In workspace',
        },
        {
          options: filteredAllFields.map(f => ({
            value: f.name || '',
            label: f.label,
          })),
          label: 'All fields',
        },
      ]}
      onCreateOption={value => {
        onMapping({
          fieldName: null,
          fieldLabel: value,
        });
      }}
      onValueChange={value => {
        if (!value) return;
        const selectedField = filteredAllFields.find(f => f.name === value);

        if (!selectedField) {
          onMapping({
            fieldName: null,
            fieldLabel: value,
          });
          return;
        }

        onMapping({
          fieldName: selectedField.name,
          fieldLabel: selectedField.label,
        });
      }}
    />
  );
};
