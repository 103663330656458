import styled from 'styled-components';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { FunctionComponent } from 'react';
import { POPOVER_ID_ATTR } from '@ardoq/popovers';
import { svgToDataUri } from 'svg/util';
import AggregatedVisualDiffState from 'scope/AggregatedVisualDiffState';
import { pseudoTopBottomBorder } from '../utils/utils';
import { colors } from '@ardoq/design-tokens';
import {
  NAVIGATOR_BACKGROUND,
  NAVIGATOR_ITEM_ACTIVE_BACKGROUND,
  NAVIGATOR_ITEM_SELECTION_BACKGROUND,
} from '../consts';
import { VISUAL_DIFF_FOREGROUND } from '@ardoq/color-helpers';
import { Stack } from '@ardoq/layout';
import { CHANGED_POPOVER_ID } from './ChangedPopover';

const DiffIcon = styled(Icon).attrs({
  iconName: IconName.DIFF,
  iconsize: IconSize.SMALL,
  className: 'diff-state-changed',
  [POPOVER_ID_ATTR]: CHANGED_POPOVER_ID,
})`
  color: ${colors.grey35};
  fill: ${colors.grey35};
  left: 4px;
  margin-right: 6px;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s;
`;

const AggregatedDiffStateIcon = styled.span<{ $aggregatedDiffState: number }>`
  background-image: url(${svgToDataUri(AggregatedVisualDiffState)});
  background-position: 50%
    ${({ $aggregatedDiffState }) => -$aggregatedDiffState * 24}px;
  background-repeat: no-repeat;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
`;

const DiffIconContainer = styled.span`
  height: 24px;
  margin: 0 6px 0 -18px;
  position: relative;
  width: 32px;
`;

export const VisualDiffIcon: FunctionComponent<{
  aggregatedDiffState: number;
  isDiffTypeChanged: boolean;
}> = ({ aggregatedDiffState, isDiffTypeChanged, ...rest }) => (
  <DiffIconContainer {...rest}>
    <AggregatedDiffStateIcon $aggregatedDiffState={aggregatedDiffState} />
    {isDiffTypeChanged && <DiffIcon />}
  </DiffIconContainer>
);

export const NavigatorItem = styled.div`
  background-color: transparent;
  border-left: 6px solid transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  ${pseudoTopBottomBorder}
  &:hover ${DiffIcon} {
    opacity: 0.3;
  }
  &:hover ${DiffIcon}:hover {
    opacity: 1;
  }
  &:hover ${AggregatedDiffStateIcon} {
    opacity: 0;
  }
  &:hover {
    border-color: black;
  }
  &.active {
    background-color: ${NAVIGATOR_ITEM_ACTIVE_BACKGROUND};
    background-size: 100% 1px;
  }
  &.selected {
    background-color: ${NAVIGATOR_ITEM_SELECTION_BACKGROUND};
  }
`;
export const NonComponentNavigatorItem = styled(NavigatorItem)`
  font-size: 16px;
  line-height: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavigatorItemIconContainer = styled.span`
  cursor: pointer;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  user-select: none;
`;
export const NavigatorItemLink = styled(NavigatorItemIconContainer)``;
export const NavigatorItemContextMenu = styled(NavigatorItemIconContainer)``;
export const NavigatorItemCloseScenario = styled(NavigatorItemIconContainer)``;
export const NavigatorItemCloseWorkspace = styled(NavigatorItemIconContainer)``;
export const NavigatorItemExpander = styled(NavigatorItemIconContainer).attrs({
  className: 'navigatorItemExpander', // browser tests depend on this class name :( (navigator.test.js)
})`
  margin-right: 4px;
`;

export const NavigatorWrapper = styled.div`
  background: ${NAVIGATOR_BACKGROUND};
  height: 100%;
  left: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  user-select: none;
  width: 100%;
  contain: layout;
  z-index: 1;
  &.no-drop,
  &.no-drop span,
  &.no-drop div {
    cursor: no-drop;
  }

  .is-context-node {
    opacity: 0.5;
  }

  .lock-icon {
    width: 24px;
  }

  .material-icons-round {
    font-size: 18px;
    line-height: inherit;
    vertical-align: top;
    margin-top: 0 !important;
  }

  .component-name,
  .scenario-name,
  .workspace-name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-right: 10px;
    width: 18px;
    font-size: 18px;
    line-height: inherit;
  }

  .excluded .expander,
  .excluded .icon-container,
  .excluded .component-name {
    opacity: 0.5;
  }

  &.is-linking .component-item:hover .component-name {
    font-weight: bold;
    color: orange;
  }

  .component-name::after,
  .component-name::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    direction: ltr;
    display: inline-block;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: 'Material Icons Round';
    font-feature-settings: 'liga';
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    text-rendering: optimizeLegibility;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    vertical-align: top;
  }

  .component-name::before {
    margin-right: 4px;
  }

  .component-name::after {
    margin-left: 4px;
  }

  .outgoing > .component-name::after,
  .incoming > .component-name::before {
    content: 'arrow_forward';
  }

  .drag-target {
    cursor: pointer;
  }

  .active-drag-target {
    opacity: 0.5;
  }

  @keyframes flash-highlight {
    0% {
      background-color: hsla(60, 100%, 70%, 1);
    }
    100% {
      background-color: hsla(60, 100%, 70%, 0);
    }
  }

  .highlight-flash {
    animation: flash-highlight 1s;
  }

  &&& .icon-container.visual-diff-added,
  &&& .icon-container.visual-diff-removed,
  &&& .icon-container.visual-diff-changed,
  &&& .icon-container.visual-diff-unchanged {
    fill: ${VISUAL_DIFF_FOREGROUND};
    color: ${VISUAL_DIFF_FOREGROUND};
    width: 24px;
  }
`;

export const WorkspaceIcon = styled(Icon).attrs({
  iconName: IconName.WORKSPACE,
  iconSize: IconSize.SMALL,
})`
  margin-right: 6px;
  fill: ${colors.grey35};
`;

export const ScenarioIcon = styled(Icon).attrs({
  iconSize: IconSize.SMALL,
})`
  margin-right: 6px;
  color: ${colors.grey35};
  fill: ${colors.grey35};
`;

export const SubdivisionsPopoverContainer = styled(Stack)`
  max-width: 275px;
`;

export const LayoutBoxesContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
`;
