import { IconName } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import { showViewSidePanel } from 'views/mainApp/viewSidePanel/actions';
import { ViewSidePanelContentIds } from 'views/mainApp/viewSidePanel/types';
import ViewOptionsHeader from './ViewOptionsHeader';
import { FlexBox } from '@ardoq/layout';
import { BetaBadge } from '@ardoq/status-ui';
import { ViewOptionsClickableIcon } from '@ardoq/perspectives-sidebar';

type SubLevelPageHeaderProps = {
  title: string;
  knowledgeBaseLink?: string;
  shareFeedbackUrl?: string;
  isBeta?: boolean;
};

const SubLevelPageHeader = ({
  title,
  knowledgeBaseLink,
  shareFeedbackUrl,
  isBeta,
}: SubLevelPageHeaderProps) => (
  <ViewOptionsHeader isUnderlined>
    <FlexBox gap="small" align="center">
      <ViewOptionsClickableIcon
        iconName={IconName.CHEVRON_LEFT}
        onClick={() =>
          dispatchAction(
            showViewSidePanel(ViewSidePanelContentIds.VIEW_OPTIONS)
          )
        }
      />
      {title}
      {knowledgeBaseLink && (
        <ViewOptionsClickableIcon
          iconName={IconName.KNOWLEDGE_BASE}
          data-tooltip-text="Knowledge base"
          onClick={e => {
            e.stopPropagation();
            window.open(knowledgeBaseLink, '_blank noreferrer');
          }}
        />
      )}
      {isBeta && <BetaBadge shareFeedbackUrl={shareFeedbackUrl} />}
    </FlexBox>
  </ViewOptionsHeader>
);

export default SubLevelPageHeader;
