import { TraversalBuilderState } from '../types';
import { ReferenceDirection } from '@ardoq/api-types';
import { BFS } from './toConnectedSubgraph';

type RemoveEdgeArgs = {
  graphNodeId: string;
  tripleIds: string[];
  direction: ReferenceDirection;
  selectedGraph: TraversalBuilderState['selectedGraph'];
  referenceMap: TraversalBuilderState['selectedGraph']['referenceMap'];
  referenceIdsToStartContext: Set<string>;
  excludeReferenceIds?: Set<string>;
};
export const removeEdgesForTriples = ({
  graphNodeId,
  tripleIds,
  direction,
  selectedGraph,
  referenceMap,
  referenceIdsToStartContext,
  excludeReferenceIds = new Set(),
}: RemoveEdgeArgs) => {
  const map =
    direction === ReferenceDirection.OUTGOING
      ? selectedGraph.sourceMap
      : selectedGraph.targetMap;
  tripleIds.forEach(tripleId => {
    const linkedComponent = (map.get(graphNodeId) || []).find(
      ({ tripleId: candidateTripleId, referenceId: currentReferenceId }) =>
        candidateTripleId === tripleId &&
        !referenceIdsToStartContext.has(currentReferenceId)
    );
    if (linkedComponent) {
      const { referenceId, componentId } = linkedComponent;
      referenceMap.delete(referenceId);
      const { visitedReferences } = BFS(
        componentId,
        referenceMap,
        excludeReferenceIds
      );
      Array.from(visitedReferences).forEach(referenceId => {
        referenceMap.delete(referenceId);
      });
    }
  });
};

export const removeEdgesForGraphNodeId = ({
  graphNodeId,
  selectedGraph,
  referenceMap,
}: Pick<RemoveEdgeArgs, 'graphNodeId' | 'selectedGraph' | 'referenceMap'>) => {
  const referenceMapWithRemovedEdges = new Map(referenceMap);
  (selectedGraph.sourceMap.get(graphNodeId) || []).forEach(reference => {
    referenceMapWithRemovedEdges.delete(reference.referenceId);
  });

  (selectedGraph.targetMap.get(graphNodeId) || []).forEach(reference => {
    referenceMapWithRemovedEdges.delete(reference.referenceId);
  });

  return referenceMapWithRemovedEdges;
};
