import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { EMPTY, combineLatest, map, of, switchMap } from 'rxjs';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { getTabularMappingErrorsStream } from 'integrations/common/streams/tabularMappingErrors/getTabularMappingErrorsStream';
import {
  dryRunStatusesCounts,
  getRowIndicesWithProblems,
  isSuccessTransfer,
} from 'integrations/common/streams/transferState/utils';
import { isValidTabularMapping } from 'integrations/common/streams/tabularMappings/utils';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { dispatchAction } from '@ardoq/rxbeach';
import { transferRun } from 'integrations/common/streams/transferState/actions';
import { startTransformModal } from 'integrations/common/modals/transformModal/TransformModal';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { startFilterModal } from 'integrations/common/modals/filterModal/FilterModal';

const onCompleteMapping = (integrationId: IntegrationId) => () => {
  dispatchAction(transferRun({ integrationId, async: true, dryRun: true }));
};

const onStartColumnFilter =
  (integrationId: IntegrationId, transferDirection: TransferDirection) =>
  ({
    columnName,
    columnIndex,
    sourceFieldName,
  }: {
    columnName: string;
    columnIndex: number;
    sourceFieldName: string;
  }) => {
    if (transferDirection === TransferDirection.IMPORT) {
      return startTransformModal({
        columnName,
        columnIndex,
        sourceFieldName,
        integrationId,
      });
    }

    startFilterModal({
      columnName,
      columnIndex,
      integrationId,
    });
  };

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      currentTableId: getActiveIntegrationStream(integrationId).pipe(
        map(ci => ci.currentTableId)
      ),
      tabularMapping: getTabularMappingStream(integrationId),
      tablePreviews: getTablePreviewsStream(integrationId),
      transferState: getTransferStateStream(integrationId),
      tabularMappingErrors: getTabularMappingErrorsStream(integrationId),
      integrationTermsDictionary:
        getIntegrationTermsDictionaryStream(integrationId),
    });
  }),
  switchMap(
    ({
      integrationId,
      currentTableId,
      tabularMapping,
      tablePreviews,
      transferState,
      tabularMappingErrors,
      integrationTermsDictionary,
    }) => {
      if (!currentTableId) {
        return EMPTY;
      }

      const base = {
        tableMapping: tabularMapping[currentTableId],
        tablePreview: tablePreviews[currentTableId],
        tableMappingErrors: tabularMappingErrors[currentTableId],
        isValidTabularMapping: isValidTabularMapping(tabularMapping),
        tablesCount: Object.keys(tablePreviews).length,
        integrationTermsDictionary,
        onCompleteMapping: onCompleteMapping(integrationId),
        onStartColumnFilter: onStartColumnFilter(
          integrationId,
          transferState.transferDirection
        ),
        transferDirection: transferState.transferDirection,
      };

      if (isSuccessTransfer(transferState)) {
        const tablesComplaints = transferState.tablesComplaints;
        const tableComplaints = tablesComplaints?.[currentTableId];
        return of({
          ...base,
          rowComplaintsIndices: getRowIndicesWithProblems(tableComplaints),
          dryRunStatusesCounts: dryRunStatusesCounts(tablesComplaints),
          tableComplaints,
        });
      }

      return of(base);
    }
  )
);
