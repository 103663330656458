import currentUser from 'models/currentUser';
import Terms from 'admin/terms/Terms';
import CurrentUser from 'models/currentUser';
import { ModalSize, alert } from '@ardoq/modal';
import { saveBackboneModelAsync } from 'models/utils/modelUtils';
import { createElement } from 'react';

export const ensureEULAAccepted = async () => {
  if (!CurrentUser.hasAcceptedTerms()) {
    await alert({
      title: 'ARDOQ END USER TERMS OF SERVICE',
      text: createElement(Terms),
      confirmButtonTitle: 'Accept',
      modalSize: ModalSize.M,
      shouldCloseOnBackdropClick: false,
      shouldCloseOnEscapeKey: false,
    });
    await saveBackboneModelAsync(currentUser, { 'accepted-terms': true });
  }
};
