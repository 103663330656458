import { ArdoqId } from '@ardoq/api-types';

export type SubdivisionNavigationState = {
  subdivisionId: ArdoqId;
  subdivisionEditorStep: SubdivisionEditorSteps;
  subdivisionEditorSubStep?: SubdivisionEditorSubStep;
};

export enum SubdivisionEditorSteps {
  DETAILS = 'details',
  PERMISSIONS_CONFIGURATION = 'permissions-configuration',
  WORKSPACES_BINDING = 'workspaces-binding',
  COMPONENTS_SELECTION = 'components-selection',
  SUMMARY = 'summary',
}

export type SubdivisionEditorSubStep = ArdoqId;

export type SelectSubdivisionEditorStepActionPayload = {
  subdivisionEditorStep: SubdivisionNavigationState['subdivisionEditorStep'];
  subdivisionEditorSubStep?: SubdivisionNavigationState['subdivisionEditorSubStep'];
};
