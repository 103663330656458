import { APIEntityType, Verb } from '@ardoq/api-types';
import { MergeStep } from 'components/DiffMergeTable/types';
import {
  DiffMetaStates,
  DiffStateMeta,
  DisabledReason,
  StepResolution,
} from 'scope/merge/types';
import { Step, StepType, SubStep } from './types';
import { MergeStepLabel } from 'scope/merge/typesMergeStepLabel';

export const getMergeStepEnum = (
  mainStateKey: MergeStepLabel,
  subStateKey: Verb
) => {
  if (
    mainStateKey === MergeStepLabel.STRUCTURE &&
    subStateKey === Verb.UPDATE
  ) {
    return MergeStep.UPDATE_MODELS;
  }
  if (mainStateKey === MergeStepLabel.COMPONENT_TYPES) {
    if (subStateKey === Verb.UPDATE) return MergeStep.UPDATE_COMPONENT_TYPES;
    if (subStateKey === Verb.DELETE) return MergeStep.DELETE_COMPONENT_TYPES;
    if (subStateKey === Verb.CREATE) return MergeStep.CREATE_COMPONENT_TYPES;
  }
  if (mainStateKey === MergeStepLabel.REFERENCE_TYPES) {
    if (subStateKey === Verb.UPDATE) return MergeStep.UPDATE_REFERENCE_TYPES;
    if (subStateKey === Verb.DELETE) return MergeStep.DELETE_REFERENCE_TYPES;
    if (subStateKey === Verb.CREATE) return MergeStep.CREATE_REFERENCE_TYPES;
  }
  if (mainStateKey === MergeStepLabel.FIELDS) {
    if (subStateKey === Verb.UPDATE) return MergeStep.UPDATE_FIELDS;
    if (subStateKey === Verb.DELETE) return MergeStep.DELETE_FIELDS;
    if (subStateKey === Verb.CREATE) return MergeStep.CREATE_FIELDS;
  }
  if (mainStateKey === MergeStepLabel.COMPONENTS) {
    if (subStateKey === Verb.UPDATE) return MergeStep.UPDATE_COMPONENTS;
    if (subStateKey === Verb.DELETE) return MergeStep.DELETE_COMPONENTS;
    if (subStateKey === Verb.CREATE) return MergeStep.CREATE_COMPONENTS;
  }
  if (mainStateKey === MergeStepLabel.REFERENCES) {
    if (subStateKey === Verb.UPDATE) return MergeStep.UPDATE_REFERENCES;
    if (subStateKey === Verb.DELETE) return MergeStep.DELETE_REFERENCES;
    if (subStateKey === Verb.CREATE) return MergeStep.CREATE_REFERENCES;
  }
  if (mainStateKey === MergeStepLabel.TAGS) {
    if (subStateKey === Verb.UPDATE) return MergeStep.UPDATE_TAGS;
    if (subStateKey === Verb.DELETE) return MergeStep.DELETE_TAGS;
    if (subStateKey === Verb.CREATE) return MergeStep.CREATE_TAGS;
    if (subStateKey === Verb.MERGE) return MergeStep.MERGE_TAGS;
  }
  return MergeStep.NONE;
};

export const getVerbFromMergeStep = (mergeStep: MergeStep) => {
  switch (mergeStep) {
    case MergeStep.UPDATE_MODELS:
    case MergeStep.UPDATE_COMPONENT_TYPES:
    case MergeStep.UPDATE_REFERENCE_TYPES:
    case MergeStep.UPDATE_FIELDS:
    case MergeStep.UPDATE_COMPONENTS:
    case MergeStep.UPDATE_REFERENCES:
    case MergeStep.UPDATE_TAGS:
      return Verb.UPDATE;

    case MergeStep.DELETE_COMPONENT_TYPES:
    case MergeStep.DELETE_REFERENCE_TYPES:
    case MergeStep.DELETE_FIELDS:
    case MergeStep.DELETE_COMPONENTS:
    case MergeStep.DELETE_REFERENCES:
    case MergeStep.DELETE_TAGS:
      return Verb.DELETE;

    case MergeStep.CREATE_COMPONENT_TYPES:
    case MergeStep.CREATE_REFERENCE_TYPES:
    case MergeStep.CREATE_FIELDS:
    case MergeStep.CREATE_COMPONENTS:
    case MergeStep.CREATE_REFERENCES:
    case MergeStep.CREATE_TAGS:
      return Verb.CREATE;
    case MergeStep.MERGE_TAGS:
      return Verb.MERGE;

    case MergeStep.NONE:
      return Verb.CREATE;
  }
};

export const getEntityTypeFromMergeStep = (mergeStep: MergeStep) => {
  switch (mergeStep) {
    case MergeStep.UPDATE_MODELS:
      return APIEntityType.MODEL;

    case MergeStep.UPDATE_COMPONENT_TYPES:
    case MergeStep.DELETE_COMPONENT_TYPES:
    case MergeStep.CREATE_COMPONENT_TYPES:
      return APIEntityType.COMPONENT_TYPE;

    case MergeStep.UPDATE_REFERENCE_TYPES:
    case MergeStep.DELETE_REFERENCE_TYPES:
    case MergeStep.CREATE_REFERENCE_TYPES:
      return APIEntityType.REFERENCE_TYPE;

    case MergeStep.UPDATE_FIELDS:
    case MergeStep.DELETE_FIELDS:
    case MergeStep.CREATE_FIELDS:
      return APIEntityType.FIELD;

    case MergeStep.UPDATE_COMPONENTS:
    case MergeStep.DELETE_COMPONENTS:
    case MergeStep.CREATE_COMPONENTS:
      return APIEntityType.COMPONENT;

    case MergeStep.UPDATE_REFERENCES:
    case MergeStep.DELETE_REFERENCES:
    case MergeStep.CREATE_REFERENCES:
      return APIEntityType.REFERENCE;

    case MergeStep.UPDATE_TAGS:
    case MergeStep.DELETE_TAGS:
    case MergeStep.CREATE_TAGS:
    case MergeStep.MERGE_TAGS:
      return APIEntityType.TAG;

    // TODO fix NONE value
    case MergeStep.NONE:
      return APIEntityType.COMPONENT;
  }
};

export const getIsStepSubmitted = (
  mergeStepLabel: MergeStepLabel,
  subStateName: Verb,
  submittedSteps: Record<MergeStepLabel, Set<Verb>>
) => submittedSteps[mergeStepLabel]?.has(subStateName);

const mapSubStateToSubStep = (
  mergeStepLabel: MergeStepLabel,
  subState: DiffStateMeta,
  subStateName: Verb,
  isParentStepSelected: boolean,
  currentSubStateName: Verb | null,
  submittedSteps: Record<MergeStepLabel, Set<Verb>>
): SubStep => ({
  name: subStateName,
  state: subState.state,
  isDisabled: subState.disabledReason !== DisabledReason.NONE,
  disabledReason: subState.disabledReason,
  isSelected: isParentStepSelected && currentSubStateName === subStateName,
  isCompleted: subState.state === StepResolution.COMPLETE,
  isSubmitted: getIsStepSubmitted(mergeStepLabel, subStateName, submittedSteps),
  hasEntities: subState.ids.size > 0,
  entityCount: subState.ids.size,
});

const metamodelSteps = new Set([
  MergeStepLabel.STRUCTURE,
  MergeStepLabel.COMPONENT_TYPES,
  MergeStepLabel.REFERENCE_TYPES,
  MergeStepLabel.FIELDS,
]);

const getStepType = (mainStepName: MergeStepLabel) => {
  if (metamodelSteps.has(mainStepName)) return StepType.METAMODEL;
  return StepType.MODEL;
};

export const mapDiffMetaStatesToSteps = (
  diffMetaStates: DiffMetaStates | null,
  mainStateStep: MergeStepLabel | null,
  currentSubStateStep: Verb | null,
  expandedMainSteps: MergeStepLabel[],
  stepType: StepType,
  submittedSteps: Record<MergeStepLabel, Set<Verb>>
): Step[] => {
  if (!diffMetaStates) {
    return [];
  }

  return Object.entries(diffMetaStates)
    .filter(
      ([mainStepName]) =>
        getStepType(mainStepName as MergeStepLabel) === stepType
    )
    .map(([mainStepName, mainStepSubSteps]) => {
      const isMainStepSelected = mainStepName === mainStateStep;
      const subSteps = Object.entries(mainStepSubSteps).map(
        ([subStateName, subState]) =>
          mapSubStateToSubStep(
            mainStepName as MergeStepLabel,
            subState as DiffStateMeta,
            subStateName as Verb,
            isMainStepSelected,
            currentSubStateStep,
            submittedSteps
          )
      );

      return {
        name: mainStepName as MergeStepLabel,
        isSelected: isMainStepSelected,
        isExpanded: expandedMainSteps.includes(mainStepName as MergeStepLabel),
        isDisabled: false,
        subSteps,
      };
    });
};
