import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToSurveyForm } from '../../surveyAdmin/navigation/actions';
import { ArdoqId, BroadcastStatus } from '@ardoq/api-types';
import {
  openDashboard,
  openReportInReader,
} from '../../components/AppMainSidebar/utils';
import { openViewpoint } from '../../viewpointBuilder/actions';
import { navigateToViewpointForm } from '../../router/navigationActions';
import { openPresentationEditor } from '../../presentationEditor/presentationUtil';
import { openBroadcast } from '../../broadcasts/utils';

type SolutionMaterialsBrowserCommands = {
  openSurveyEditor: (id: ArdoqId) => void;
  openReport: (id: ArdoqId) => void;
  openDashboard: (id: ArdoqId) => void;
  openTraversal: (id: ArdoqId) => void;
  openDiscoverViewpoint: (id: ArdoqId) => void;
  openPresentationEditor: (id: ArdoqId) => void;
  openBroadcast: (id: ArdoqId, status: BroadcastStatus) => void;
};

export const solutionMaterialsBrowserCommands: SolutionMaterialsBrowserCommands =
  {
    openSurveyEditor: id => dispatchAction(navigateToSurveyForm(id)),
    openReport: id => openReportInReader({ reportId: id }),
    openDashboard: id =>
      openDashboard({
        dashboardId: id,
      }),
    openTraversal: id => dispatchAction(openViewpoint({ viewpointId: id })),
    openDiscoverViewpoint: id => dispatchAction(navigateToViewpointForm(id)),
    openPresentationEditor: id =>
      openPresentationEditor({ presentationId: id }),
    openBroadcast: (id, status) => openBroadcast(id, status),
  };
