import { actionCreator } from '@ardoq/rxbeach';
import {
  APIAudiencePreviewResponse,
  ArdoqId,
  BroadcastContent,
} from '@ardoq/api-types';
import { GremlinResult } from 'search/Gremlin/GremlinResults/types';

export const openExistingGremlinAudienceDialog = actionCreator<string>(
  '[gremlinAudienceDialog] OPEN_EXISTING_GREMLIN_AUDIENCE_DIALOG'
);

export const fetchContentIds = actionCreator<BroadcastContent>(
  '[gremlinAudienceDialog] FETCH_CONTENT_IDS'
);

export const fetchContentIdsError = actionCreator(
  '[gremlinAudienceDialog] FETCH_CONTENT_IDS_ERROR'
);

export const setContentIdsAndTotalNumberOfContentIds = actionCreator<{
  contentIds: ArdoqId[];
  totalNumberOfContentIds: number;
}>('[gremlinAudienceDialog] SET_CONTENT_IDS_AND_TOTAL_NUMBER_OF_CONTENT_IDS');

export const fetchGremlinAudiencePreview = actionCreator(
  '[gremlinAudienceDialog] FETCH_GREMLIN_AUDIENCE_PREVIEW'
);

export const setGremlinAudiencePreview =
  actionCreator<APIAudiencePreviewResponse>(
    '[gremlinAudienceDialog] SET_GREMLIN_AUDIENCE_PREVIEW'
  );

export const notifyFailedToFetchGremlinAudiencePreview = actionCreator(
  '[gremlinAudienceDialog] NOTIFY_FAILED_TO_FETCH_AUDIENCE_PREVIEW'
);

export const openNewGremlinAudienceDialog = actionCreator(
  '[gremlinAudienceDialog] OPEN_NEW_GREMLIN_AUDIENCE_DIALOG'
);

export const updateGremlinAudienceQuery = actionCreator<string>(
  '[gremlinAudienceDialog] UPDATE_GREMLIN_AUDIENCE_QUERY'
);

export const notifyRunningGremlinAudienceQuery = actionCreator(
  '[gremlinAudienceDialog] NOTIFY_RUNNING_GREMLIN_AUDIENCE_QUERY'
);

export const notifyRunningGremlinAudienceQuerySucceeded = actionCreator<
  GremlinResult[]
>('[gremlinAudienceDialog] NOTIFY_RUNNING_GREMLIN_AUDIENCE_QUERY_SUCCEEDED');

export const notifyRunningGremlinAudienceQueryFailed = actionCreator<
  string | null
>('[gremlinAudienceDialog] NOTIFY_RUNNING_GREMLIN_AUDIENCE_QUERY_FAILED');

export const setTableFilter = actionCreator<string>(
  '[gremlinAudienceDialog] SET_TABLE_FILTER'
);
