import { Space } from '@ardoq/style-helpers';
import Integrations from 'integrations/integrations';
import styled from 'styled-components';

export default () => {
  return (
    <Container $isVertical $gap="none" id="integrationsContainer">
      <Integrations />
    </Container>
  );
};

const Container = styled(Space)`
  width: 100%;
  height: 100%;
  overflow: auto;
`;
