import { dispatchAction } from '@ardoq/rxbeach';
import {
  setStartTypeAndTraversalStartSetOrCount,
  setTraversals,
} from './traversals/editTraversalActions';
import { resetAllSearchState } from '../search/actions';
import { selectedViewCommands } from './selectedView/commands';
import { resetViewpointBuilderNavigation } from './viewpointBuilderNavigation/actions';
import { resetTraversalBuilderState } from './traversals/editTraversalActions';
import { resetFormattingState } from './formattingTabs/viewpointBuilderFormattingActions';

const resetComponentSearchState = () => {
  dispatchAction(resetAllSearchState());
};

const resetEditTraversalState = () => {
  dispatchAction(resetTraversalBuilderState());
  dispatchAction(
    setStartTypeAndTraversalStartSetOrCount({
      startType: null,
      traversalStartSet: [],
    }) // editTraversal$ is also used in TripleToggler in the DetailsDrawer
  );
  dispatchAction(setTraversals({ paths: [], filters: null }));
};

export const resetViewpointBuilder = () => {
  resetComponentSearchState();
  resetEditTraversalState();
  selectedViewCommands.resetSelectedView();
  dispatchAction(resetViewpointBuilderNavigation());
  dispatchAction(resetFormattingState());
};
