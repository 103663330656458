import { configState$ } from 'integrations/signavio-exporter/streams/configurationState/configurationState$';
import { workspaceOptions$ } from 'integrations/ITPedia/streams/workspaces/workspaces$';
import { combineLatest, map } from 'rxjs';
import { isDataSelectionValid } from './utils';
import { IntegrationWorkspace } from '@ardoq/api-types/integrations';
import { ConfigurationState } from 'integrations/signavio-exporter/streams/types';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { dispatchAction } from '@ardoq/rxbeach';
import { NewExportRoutes } from 'integrations/common/navigation/types';
import { debounce } from 'lodash';
import {
  fetchQueryResults,
  updateComponentTypeProps,
  updateComponentTypesKeys,
  updateConfigState,
} from 'integrations/signavio-exporter/streams/configurationState/actions';
import { QueryBuilderQuery } from '@ardoq/api-types';
import { Connection } from '@ardoq/api-types/integrations';
import { Maybe } from '@ardoq/common-helpers';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';

const onNext = () => {
  dispatchAction(
    navigateToPath({
      integrationId: 'signavio-exporter',
      path: NewExportRoutes.FIELD_MAPPING,
    })
  );
};

function buildModel(
  workspaces: IntegrationWorkspace[],
  configurationState: ConfigurationState,
  connectionsState: IntegrationConnectionsState<Connection>
) {
  const componentTypeOptions = configurationState.workspace.id
    ? workspaceInterface
        .getComponentTypes(configurationState.workspace.id)
        .map(ct => ({
          label: ct.name,
          value: ct.name,
        }))
    : [];

  const selectedComponentTypes = Object.keys(configurationState.componentTypes);

  const accounts = connectionsState.connections.map(c => ({
    label: c.name,
    value: c._id,
  }));

  const onQueryChange = (
    query: QueryBuilderQuery,
    componentType: string,
    isValid: boolean
  ) => {
    debounce(
      () =>
        dispatchAction(
          updateComponentTypeProps({
            componentType,
            props: {
              componentsQuery: query,
              isQueryValid: isValid,
            },
          })
        ),
      500
    )();
  };
  const onFetchResults = (componentType: string) => {
    dispatchAction(
      fetchQueryResults({
        componentType,
      })
    );
  };

  const onConnChange = (accountId: Maybe<string>) =>
    accountId && dispatchAction(updateConfigState({ accountId }));

  const onWorkspaceChange = (workspace: IntegrationWorkspace) =>
    dispatchAction(updateConfigState({ workspace }));

  const onComponentTypesChange = (componentTypes: Maybe<string[]>) =>
    componentTypes && dispatchAction(updateComponentTypesKeys(componentTypes));

  const onApplyFilterChange = (applyFilters: boolean) =>
    dispatchAction(updateConfigState({ applyFilters }));
  return {
    dataSelectionIsValid: isDataSelectionValid(configurationState),
    workspaces,
    accounts,
    configurationState,
    componentTypeOptions,
    selectedComponentTypes,
    onQueryChange,
    onNext,
    onFetchResults,
    onConnChange,
    onWorkspaceChange,
    onComponentTypesChange,
    onApplyFilterChange,
  };
}

export const viewModel$ = combineLatest([
  workspaceOptions$,
  configState$,
  getConnectionsStream('signavio-exporter'),
]).pipe(
  map(([workspaces, configurationState, connectionsState]) => {
    return buildModel(workspaces, configurationState, connectionsState);
  })
);
