import Context from 'context';
import { Component } from 'react';
import styled from 'styled-components';
import {
  clearComponentTypeFilters,
  ensureComponentTypesIncluded,
  getFilterIncludedTypeNames,
  setIncludedComponentTypeNames,
} from './componentFilterRuleManager';
import { ComponentRepresentation } from '@ardoq/renderers';
import { CUSTOM_SVG_ICON_SELECTOR, getIcon } from '@ardoq/icons';
import {
  QuickPerspectiveType,
  trackToggledQuickPerspective,
} from 'quickPerspectives/tracking';
import { QuickPerspectivesContext } from './types';
import { ModelType } from 'models/ModelType';
import { Label } from './atoms';
import QuickPerspectivesLegendItem from './QuickPerspectivesLegendItem';
import { connect } from '@ardoq/rxbeach';
import quickPerspectivesContext$ from 'quickPerspectives/quickPerspectivesContext$';
import filterRules$ from 'quickPerspectives/filterRules$';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { FiltersAsQueryBuilderQueries } from '@ardoq/filter-interface';

const IconContainer = styled.div`
  margin-left: 9px;
  margin-right: 8px;
  line-height: 0;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  > ${CUSTOM_SVG_ICON_SELECTOR} {
    width: inherit;
    max-height: 18px;
  }
`;

interface ComponentLegendProperties {
  quickPerspectives: QuickPerspectivesContext;
  filterRules: FiltersAsQueryBuilderQueries;
}

class ComponentLegend extends Component<ComponentLegendProperties> {
  constructor(props: ComponentLegendProperties) {
    super(props);
  }

  handleComponentSelectedChange(
    componentTypeName: string,
    isSelected: boolean
  ) {
    const newSelectedValue = !isSelected;
    const componentRules = this.props.filterRules.componentRules;
    if (!newSelectedValue) {
      const includedTypeNames = getFilterIncludedTypeNames(componentRules);
      const filteredIncludedTypeNames = includedTypeNames.filter(
        item => item !== componentTypeName
      );
      setIncludedComponentTypeNames(filteredIncludedTypeNames, componentRules);
    } else {
      const componentTypeNames = [componentTypeName];
      const contextComponent = Context.component();
      if (contextComponent) {
        componentTypeNames.push(
          (contextComponent.getMyType() as ModelType).name
        );
        const parentComponent = contextComponent.getParent();
        if (parentComponent) {
          componentTypeNames.push(
            (parentComponent.getMyType() as ModelType).name
          );
        }
      }
      ensureComponentTypesIncluded(componentTypeNames, componentRules);
    }
    trackToggledQuickPerspective({
      type: QuickPerspectiveType.COMPONENT_FILTER,
      isEnabling: !isSelected,
    });
  }

  handleAllSelected() {
    clearComponentTypeFilters(this.props.filterRules.componentRules);
  }

  render() {
    const allComponentTypes = this.props.quickPerspectives.componentTypes || [];
    const includedTypeNames = getFilterIncludedTypeNames(
      this.props.filterRules.componentRules
    ) as string[];
    const outsideTypeNames = includedTypeNames.filter(
      typeName => !allComponentTypes.find(ct => typeName === ct.name)
    );

    return (
      <div>
        <QuickPerspectivesLegendItem
          isImplicitlySelected={includedTypeNames.length === 0}
          isChecked={includedTypeNames.length === 0}
          isDisabled={includedTypeNames.length === 0}
          onChange={() => this.handleAllSelected()}
        >
          <IconContainer />
          <span>All</span>
        </QuickPerspectivesLegendItem>

        {allComponentTypes.map(componentType => {
          const isSelected = includedTypeNames.includes(componentType.name);
          const hasContextComponent = Boolean(Context.componentId());
          const isTypeOfContextComponent =
            (Context.component()?.getMyType() as ModelType | undefined)
              ?.name === componentType.name;
          const isImplicitlySelected = isSelected && isTypeOfContextComponent;
          const borderingType = hasContextComponent
            ? this.props.quickPerspectives.borderingComponentTypes.has(
                componentType.name
              ) && !isTypeOfContextComponent
            : true;

          return (
            <QuickPerspectivesLegendItem
              key={componentType.id}
              isImplicitlySelected={isImplicitlySelected}
              title={
                isTypeOfContextComponent
                  ? 'Type of component in selected Context'
                  : ''
              }
              isChecked={isSelected}
              isDisabled={isImplicitlySelected}
              onChange={() =>
                this.handleComponentSelectedChange(
                  componentType.name,
                  isSelected
                )
              }
            >
              <IconContainer>
                <ComponentRepresentation
                  isImage={Boolean(componentType.image)}
                  value={componentType.image ?? null}
                  icon={getIcon(componentType.icon)}
                />
              </IconContainer>
              <Label
                $isInContext={isTypeOfContextComponent}
                $borderingType={borderingType}
                data-tooltip-text={
                  componentType.name.length >= 16 ? componentType.name : ''
                }
              >
                {componentType.name}
              </Label>
            </QuickPerspectivesLegendItem>
          );
        })}

        <div>
          {outsideTypeNames.map(typeName => (
            <QuickPerspectivesLegendItem
              key={typeName}
              isOutsideScope={true}
              isChecked={true}
              onChange={() =>
                this.handleComponentSelectedChange(typeName, true)
              }
            >
              {typeName}
            </QuickPerspectivesLegendItem>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(
  ComponentLegend,
  combineLatest([quickPerspectivesContext$, filterRules$]).pipe(
    map(([quickPerspectives, filterRules]) => ({
      quickPerspectives,
      filterRules,
    }))
  )
);
