import {
  collectRoutines,
  routine,
  ofType,
  dispatchAction,
} from '@ardoq/rxbeach';
import {
  hideScopeRelatedNavigator,
  showScopeRelatedNavigator,
} from './actions';
import { debounceTime, tap } from 'rxjs/operators';
import { containerHeightChanged } from 'components/WorkspaceHierarchies/actions/navigatorActions';

const handleHeightChanged = routine(
  ofType(showScopeRelatedNavigator, hideScopeRelatedNavigator),
  debounceTime(100),
  tap(() => dispatchAction(containerHeightChanged()))
);

export const appModuleSidebarRoutines = collectRoutines(handleHeightChanged);
