import { APIToken } from '@ardoq/api-types';

export type TokenTabViewModel = {
  isLoading: boolean;
  activeTokensTab: TokenTypes;
  searchKey: string;
  tokens: APIToken[];
  errorMessage: string;
};

export type GetTokensResult =
  | { status: 'success'; tokens: APIToken[] }
  | { status: 'error'; message: string };

export type CreateUpdateTokenResult =
  | { status: 'success'; token: APIToken }
  | { status: 'error'; message: string };

export type DeleteTokenResult =
  | { status: 'success' }
  | { status: 'error'; message: string };

export enum TokenTypes {
  MY_TOKENS = 'myTokens',
  ORG_TOKENS = 'orgTokens',
}
