import { APIViewpointAttributes, ArdoqId } from '@ardoq/api-types';
import { modal } from '@ardoq/modal';
import SearchTraversalForContextComponentsModal from './SearchTraversalForContextComponentsModal';
import { applyTraversalToComponentIds } from 'traversals/applyTraversal';
import { searchViewpointCommands } from './searchViewpointCommands';
import { ApplySavedTraversalComponent } from './actions';

type OpenSearchTraversalForContextComponentsModalArgs = {
  traversals: APIViewpointAttributes[];
  currentUserId: ArdoqId;
  components: ApplySavedTraversalComponent[];
};

export const openApplySavedViewpointForContextComponentsModal = ({
  traversals,
  currentUserId,
  components,
}: OpenSearchTraversalForContextComponentsModalArgs) => {
  return modal<boolean>(resolve => {
    return (
      <SearchTraversalForContextComponentsModal
        components={components}
        commands={searchViewpointCommands}
        currentUserId={currentUserId}
        applyTraversal={async traversal => {
          // TODO AM replace with dispatching action
          await applyTraversalToComponentIds(
            traversal,
            components.map(({ id }) => id)
          );
          resolve(true);
        }}
        traversals={traversals}
        close={() => resolve(false)}
      />
    );
  });
};

export default openApplySavedViewpointForContextComponentsModal;
