import styled from 'styled-components';
import { FAB_BOTTOM, FAB_SIZE } from './consts';
import { colors, zIndex } from '@ardoq/design-tokens';
import { FabColor } from './types';

type FabContainerProps = {
  readonly color: FabColor;
  readonly $rightOffset?: number;
};

export const FabContainer = styled.div<FabContainerProps>`
  z-index: ${zIndex.FAB_CONTAINER};
  position: absolute;
  bottom: ${FAB_BOTTOM}px;
  left: -${({ $rightOffset = 0 }) => 80 + $rightOffset}px;
  width: ${FAB_SIZE}px;
  height: ${FAB_SIZE}px;
  line-height: ${FAB_SIZE}px;
  border-radius: 50%;
  background-color: ${({ color }) =>
    color === 'red' ? colors.red50 : colors.blue50};
  font-size: 20px;
  color: ${colors.white};
  text-align: center;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

  transition:
    all 200ms ease-in-out,
    background-color 300ms cubic-bezier(0.55, 0, 0.55, 0.2);

  > .fab-main {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    transition: transform 200ms ease-in-out;
    transform: rotate(0deg);
  }

  &:not(.active) {
    > .fab-main {
      transform: rotate(-45deg);
    }
  }

  &.active,
  &:hover {
    background-color: ${({ color }) =>
      color === 'red' ? colors.red35 : colors.blue35};
    cursor: pointer;
  }
`;
