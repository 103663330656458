import { LineSeparatorWithText } from '@ardoq/snowflakes';

type TriplesSectionHeaderProps = {
  label: string;
  count: number;
};

export const TriplesSectionHeader = ({
  label,
  count,
}: TriplesSectionHeaderProps) => {
  return <LineSeparatorWithText text={`${count} ${label}`} />;
};
