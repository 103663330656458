import { actionCreator, reducedStream, reducer } from '@ardoq/rxbeach';

export const setActiveTab = actionCreator<string>(
  '[viewpointSidebarTabs] SET_ACTIVE_TAB'
);

const reduceSetActiveTab = (
  _state: { activeTab: string },
  activeTab: string
) => ({ activeTab });

const viewpointSidebarTabs$ = reducedStream(
  'viewpointSidebarTabs$',
  { activeTab: 'datasets' },
  [reducer(setActiveTab, reduceSetActiveTab)]
);

export default viewpointSidebarTabs$;
