import { BestPracticeAssistantState } from './bestPracticeAssistant$';
import { APIStartChatResponse } from '@ardoq/api-types';
import {
  ChatMessage,
  ChatMessageContentEvent,
  ChatMessageDoneEvent,
  ChatMessageErrorEvent,
  ChatOptions,
  ChatType,
  DecoratedSendMessageRequest,
  Feedback,
} from './types';
import { ClientRequest, UserEvent } from 'sync/types';
import { currentChatMessageOperations } from './currentChatMessageOperations';
import { dispatchAction } from '@ardoq/rxbeach';
import { openScenario } from 'scope/actions';
import { currentTimestamp } from '@ardoq/date-time';

const initialMessages: ChatMessage[] = [
  {
    content:
      "Hi there!\n\nI can help you answer any questions you might have about Ardoq's modeling principles.",
    role: 'pinned',
    citations: new Map(),
  },
];

const BCRInitialMessages: ChatMessage[] = [
  {
    content:
      "Hi there! I see that you have 77 apps that need linking to capabilities. I've come up with some proposals and put them in a scenario for your approval.",
    role: 'pinned',
    citations: new Map(),
    relevantLinks: [
      {
        title: 'Open BCR Scenario',
        onClick: () => {
          dispatchAction(
            openScenario({
              scenarioId: '29c9e51eb07c726cde4159fe',
              trackingClickSource: 'assetManager',
            })
          );
        },
      },
    ],
  },
];

const getInitialMessages = (type: ChatType): ChatMessage[] => {
  if (type === 'BCRSuggestions') {
    return BCRInitialMessages;
  }
  return initialMessages;
};

const isPinnedMessage = (message: ChatMessage) => message.role === 'pinned';

const handleExpandChatWindow = (
  state: BestPracticeAssistantState
): BestPracticeAssistantState => {
  return {
    ...state,
    mode: 'open',
  };
};

const handleCloseChatWindow = (
  state: BestPracticeAssistantState
): BestPracticeAssistantState => ({
  ...state,
  mode: 'closed',
  threadId: null,
  messages: [],
  sendMessageIsLoading: false,
  currentChatMessage: null,
  sendMessageError: null,
});

const handleMinimizeChatWindow = (
  state: BestPracticeAssistantState
): BestPracticeAssistantState => ({
  ...state,
  mode: 'minimized',
});

const handleInitializeNewChat = (
  state: BestPracticeAssistantState,
  { type }: ChatOptions
): BestPracticeAssistantState => ({
  ...state,
  mode: 'open',
  startChatIsLoading: true,
  threadId: null,
  type: type,
});

const handleStartChatSuccess = (
  state: BestPracticeAssistantState,
  { threadId }: APIStartChatResponse
): BestPracticeAssistantState => ({
  ...state,
  startChatIsLoading: false,
  startChatError: null,
  threadId,
  messages: getInitialMessages(state.type),
});

const handleStartChatFailed = (
  state: BestPracticeAssistantState,
  error: string
): BestPracticeAssistantState => ({
  ...state,
  startChatError: error,
  startChatIsLoading: false,
});

const handleSendMessage = (
  state: BestPracticeAssistantState,
  { content, timestamp, clientRequestId }: DecoratedSendMessageRequest
): BestPracticeAssistantState => ({
  ...state,
  sendMessageIsLoading: true,
  messages: [
    // remove pinned message after user sends a message
    ...state.messages.filter(message => !isPinnedMessage(message)),
    { content, role: 'user', timestamp, citations: new Map() },
    { content: '', role: 'assistant', citations: new Map() },
  ],
  input: '',
  autoScrollToBottom: true,
  currentChatMessage: currentChatMessageOperations.getDefault(clientRequestId),
  hasChatTimedOut: false,
});

const handleSendMessageDone = (
  state: BestPracticeAssistantState
): BestPracticeAssistantState => ({
  ...state,
  sendMessageIsLoading: false,
  sendMessageError: null,
  currentChatMessage: null,
});

const handleSendMessageFailed = (
  state: BestPracticeAssistantState,
  error: string
): BestPracticeAssistantState => ({
  ...state,
  sendMessageError: error,
  sendMessageIsLoading: false,
  currentChatMessage: null,
});

const handleSetInput = (
  state: BestPracticeAssistantState,
  value: string
): BestPracticeAssistantState => ({
  ...state,
  input: value,
});

const saveSubmittedFeedbackReducer = (
  state: BestPracticeAssistantState,
  { messageIndex, feedback }: { messageIndex: number; feedback: Feedback }
): BestPracticeAssistantState => {
  return {
    ...state,
    submittedFeedbacksByMessageIndex: {
      ...state.submittedFeedbacksByMessageIndex,
      [`${messageIndex}`]: feedback,
    },
  };
};

const handleDismissBanner = (
  state: BestPracticeAssistantState
): BestPracticeAssistantState => ({
  ...state,
  showBanner: false,
});

const setAutoScrollToBottom = (
  state: BestPracticeAssistantState,
  value: boolean
): BestPracticeAssistantState => ({
  ...state,
  autoScrollToBottom: value,
});

const setCurrentScrollTop = (
  state: BestPracticeAssistantState,
  value: number
): BestPracticeAssistantState => ({
  ...state,
  currentScrollTop: value,
});

const validateInput = (value: string) => {
  if (!value) return false;

  const valueWithoutNewLines = value.replaceAll('\n', '');
  if (!valueWithoutNewLines) return false;

  return true;
};

const setMessageQueueFromWebsocket = (
  state: BestPracticeAssistantState,
  event: UserEvent<ClientRequest<ChatMessageContentEvent>>
): BestPracticeAssistantState => {
  if (!state.currentChatMessage || !isCurrentChatMessageEvent(state, event))
    return state;

  return {
    ...state,
    currentChatMessage: currentChatMessageOperations.addMessageToQueue(
      state.currentChatMessage,
      state.messages,
      event.data.message,
      event.data.citations
    ),
  };
};

const setIsCurrentChatMessageDone = (
  state: BestPracticeAssistantState,
  event: UserEvent<ClientRequest<ChatMessageDoneEvent>>
): BestPracticeAssistantState => {
  if (!state.currentChatMessage || !isCurrentChatMessageEvent(state, event))
    return state;

  return {
    ...state,
    currentChatMessage: currentChatMessageOperations.setIsDone(
      state.currentChatMessage,
      true
    ),
  };
};

const setSendMessageError = (
  state: BestPracticeAssistantState,
  event: UserEvent<ClientRequest<ChatMessageErrorEvent>>
): BestPracticeAssistantState => {
  if (!isCurrentChatMessageEvent(state, event)) return state;

  return {
    ...state,
    sendMessageError: event.data.message,
    sendMessageIsLoading: false,
    currentChatMessage: null,
  };
};

const isCurrentChatMessageEvent = (
  state: BestPracticeAssistantState,
  event: UserEvent<ClientRequest<unknown>>
) => {
  const currentId = state.currentChatMessage?.clientRequestId;

  if (!currentId) return false;

  return currentId === event.data.clientRequestId;
};

const dequeMessages = (
  state: BestPracticeAssistantState
): BestPracticeAssistantState => {
  if (!state.currentChatMessage) return state;
  const count = currentChatMessageOperations.calculateMessagesToDequeue(
    state.currentChatMessage
  );
  if (count === 0) return state;

  const queue = state.currentChatMessage.messageQueue;

  const itemsToDequeue = Math.min(count, queue.length);
  const dequeuedItems = queue.slice(0, itemsToDequeue);
  const newQueueWithoutItems = queue.slice(itemsToDequeue, queue.length);

  const lastMessage = state.messages[state.messages.length - 1];
  const newestMessage = dequeuedItems[dequeuedItems.length - 1];
  return {
    ...state,
    currentChatMessage: currentChatMessageOperations.setMessageQueue(
      state.currentChatMessage,
      newQueueWithoutItems
    ),
    messages: [
      ...state.messages.slice(0, -1),
      {
        ...lastMessage,
        content: newestMessage,
        timestamp: currentTimestamp(),
        citations: state.currentChatMessage.citations,
      },
    ],
  };
};

const sanitizeFeedbackContent = (content: string) => {
  const maxLength = 500;
  const sanitizedContent = content.replace(/[^a-zA-Z0-9 .,!?;:]/g, '');
  return sanitizedContent.slice(0, maxLength);
};

const setChatTimedOut = (
  state: BestPracticeAssistantState
): BestPracticeAssistantState => {
  return {
    ...state,
    currentChatMessage: state.currentChatMessage
      ? currentChatMessageOperations.setIsDone(state.currentChatMessage, true)
      : null,
    sendMessageIsLoading: false,
    hasChatTimedOut: true,
  };
};

export const bestPracticeAssistantOperations = {
  handleExpandChatWindow,
  handleCloseChatWindow,
  handleMinimizeChatWindow,
  handleInitializeNewChat,
  handleStartChatSuccess,
  handleStartChatFailed,
  handleSendMessage,
  handleSendMessageDone,
  handleSendMessageFailed,
  handleSetInput,
  saveSubmittedFeedbackReducer,
  handleDismissBanner,
  setAutoScrollToBottom,
  setCurrentScrollTop,
  validateInput,
  sanitizeFeedbackContent,
  setMessageQueueFromWebsocket,
  setIsCurrentChatMessageDone,
  setSendMessageError,
  dequeMessages,
  setChatTimedOut,
};
