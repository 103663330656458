import { combineLatest, map } from 'rxjs';
import { useCaseManagement$ } from './useCaseManagement$';
import { useCaseEditor$ } from './UseCaseEditor/useCaseEditor$';
import { dispatchAction } from '@ardoq/rxbeach';
import { fetchUseCasesData, navigateToUseCaseTab } from './actions';
import {
  UseCaseManagementSidebarProps,
  UseCaseEditorProps,
  UseCaseEditorStreamState,
  UseCaseOverviewProps,
  UseCaseTabs,
  TripleSelectOptions,
} from './types';
import {
  addPdfLink,
  addTriple,
  removePdfLink,
  removeTriple,
  requestEntityGroupChange,
  saveUseCase,
  setFieldValue,
  updatePdfLink,
  updateTripleReferenceType,
  updateTripleSourceComponentType,
  updateTripleTargetComponentType,
} from './UseCaseEditor/actions';
import { useCaseEditorOperations } from './UseCaseEditor/useCaseEditorOperations';
import { isFetchingData$ } from './streams';
import { locale$ } from '../../streams/locale$';
import {
  getAddTriplesButtonState,
  getDashboardSelectorState,
  getReferenceTypesByComponentTypeName,
  getSaveButtonEditorNotificationMessage,
  getTargetComponentTypesByReferenceType,
} from './utils';
import { localeCompare } from '@ardoq/locale';

export const viewModel$ = combineLatest([
  useCaseManagement$,
  useCaseEditor$,
  isFetchingData$,
  locale$,
]).pipe(
  map(([managementState, editor, isFetchingData, userLocale]) => {
    const {
      entityGroups,
      referenceTypes,
      keyMetricsDashboards,
      componentTypes,
      isLoading: isSaving,
      selectedTab,
      useCasesData,
      error,
    } = managementState;

    const isLoading = isFetchingData || isSaving;

    const hasDashboardError = useCaseEditorOperations.getDashboardError(
      editor.keyMetricsDashboardId,
      keyMetricsDashboards.selectOptions
    );

    const hasChanges = useCaseEditorOperations.getUseCaseEditorHasChanges(
      editor as UseCaseEditorStreamState
    );

    const navigateToCreateUseCaseTab = () =>
      dispatchAction(navigateToUseCaseTab({ tab: UseCaseTabs.CREATE }));

    const overviewProps: UseCaseOverviewProps = {
      useCases: useCasesData.sort((a, b) =>
        localeCompare(a.name, b.name, userLocale)
      ),
      error,
      isLoading,
      onReloadClick: () => dispatchAction(fetchUseCasesData()),
      onCreateClick: navigateToCreateUseCaseTab,
    };

    const sidebarProps: UseCaseManagementSidebarProps = {
      selectedTab,
      onManageClick: () =>
        dispatchAction(navigateToUseCaseTab({ tab: UseCaseTabs.MANAGE })),
      onCreateClick: navigateToCreateUseCaseTab,
    };

    const { entityGroupId } = editor;

    const addTriplesButtonState = getAddTriplesButtonState({
      entityGroupId,
      availableComponentTypes: componentTypes.selectOptions,
      availableReferenceTypes: referenceTypes.selectOptions,
    });

    const dashboardsSelectorState = getDashboardSelectorState({
      entityGroupId,
      keyMetricsDashboardsOptions: keyMetricsDashboards.selectOptions,
    });

    const triplesSelectOptions: TripleSelectOptions[] =
      editor.metamodelTriples.map(triple => {
        const sourceComponentTypeName = triple[0];
        const referenceTypeName = triple[1];
        return {
          sourceComponentTypeOptions: componentTypes.selectOptions,
          referenceTypeOptions: getReferenceTypesByComponentTypeName({
            componentTypeName: sourceComponentTypeName,
            componentTypes:
              managementState.componentTypes.componentTypesFromEntityGroup,
            referenceTypes:
              managementState.referenceTypes.referenceTypesFromEntityGroup,
            locale: managementState.userLocale,
          }),
          targetComponentTypeOptions: getTargetComponentTypesByReferenceType({
            componentTypeName: sourceComponentTypeName,
            referenceTypeName,
            componentTypes:
              managementState.componentTypes.componentTypesFromEntityGroup,
            currentComponentTypesSelectOptions:
              managementState.componentTypes.selectOptions,
            locale: managementState.userLocale,
          }),
        };
      });

    const triplesErrors = useCaseEditorOperations.getTriplesErrors(
      componentTypes.selectOptions,
      referenceTypes.selectOptions,
      editor.metamodelTriples,
      triplesSelectOptions
    );

    const hasTriplesError = triplesErrors
      .flatMap(
        tripleErrorObject =>
          tripleErrorObject.hasDeletedSourceComponentType ||
          tripleErrorObject.hasDeletedReferenceType ||
          tripleErrorObject.hasDeletedTargetComponentType ||
          tripleErrorObject.hasReferenceOutOfSourceComponentTypeWorkspace ||
          tripleErrorObject.hasTargetComponentTypeOutOfSourceComponentTypeWorkspace
      )
      .some(hasError => Boolean(hasError));

    const editorProps: UseCaseEditorProps = {
      editorState: editor as UseCaseEditorStreamState,
      hasChanges,
      isLoading,
      triplesSelectOptions: triplesSelectOptions,
      availableEntityGroups: entityGroups.selectOptions,
      availableKeyMetricsDashboards: keyMetricsDashboards.selectOptions,
      editorErrors: {
        triplesErrors,
        hasTriplesError,
        hasDashboardError,
        errorNotifications: getSaveButtonEditorNotificationMessage(
          hasTriplesError,
          hasDashboardError
        ),
      },
      editorCommands: {
        changeName: value =>
          dispatchAction(setFieldValue({ key: 'name', value })),
        changeVersion: value =>
          dispatchAction(setFieldValue({ key: 'iteration', value })),
        changeCategory: value =>
          dispatchAction(setFieldValue({ key: 'category', value })),
        changeDescription: value =>
          dispatchAction(setFieldValue({ key: 'description', value })),
        changeBusinessOutcomes: value =>
          dispatchAction(setFieldValue({ key: 'businessOutcomes', value })),
        changeKeyMetricsDashboard: value =>
          dispatchAction(
            setFieldValue({ key: 'keyMetricsDashboardId', value })
          ),
        changeEntityGroup: value =>
          dispatchAction(
            requestEntityGroupChange({
              newEntityGroupId: value,
              currentEntityGroupId: entityGroupId,
            })
          ),

        changePdfLinkUrl: index => value =>
          dispatchAction(
            updatePdfLink({ index, updatedLink: { link: value ?? '' } })
          ),
        changePdfLinkLabel: index => value =>
          dispatchAction(
            updatePdfLink({ index, updatedLink: { label: value ?? '' } })
          ),
        deletePdfLink: index => () => dispatchAction(removePdfLink(index)),
        addPdfLink: () => dispatchAction(addPdfLink()),

        updateTripleSourceComponentType: index => value =>
          dispatchAction(updateTripleSourceComponentType({ index, value })),
        updateTripleReferenceType: index => value =>
          dispatchAction(updateTripleReferenceType({ index, value })),
        updateTripleTargetComponentType: index => value =>
          dispatchAction(updateTripleTargetComponentType({ index, value })),

        deleteTriple: index => () => dispatchAction(removeTriple(index)),
        addTriple: () => dispatchAction(addTriple()),

        saveUseCase: () => dispatchAction(saveUseCase()),
      },
      formState: {
        fields: {
          keyMetricsDashboards: {
            isDisabled: dashboardsSelectorState.isDisabled,
            popoverContent: dashboardsSelectorState.popoverMessage,
            errorMessage: hasDashboardError
              ? 'Selected dashboard was removed from Entity Group'
              : undefined,
          },
        },
        buttons: {
          addTriples: {
            dataTooltipText: addTriplesButtonState.dataTooltipText,
            isDisabled: addTriplesButtonState.isDisabled,
          },
          saveButton: {
            isDisabled: !hasChanges || hasTriplesError || hasDashboardError,
          },
        },
      },
    };
    return {
      overviewProps,
      editorProps,
      sidebarProps,
      isEditorVisible:
        selectedTab === UseCaseTabs.EDIT || selectedTab === UseCaseTabs.CREATE,
    };
  })
);
