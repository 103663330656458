import { Select } from '@ardoq/select';
import { Field as FieldType } from 'integrations/common/streams/fields/types';
import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import {
  getDefaultWorkspaceField,
  isCustomIdParentMapping,
} from 'integrations/common/streams/tabularMappings/utils';
import { ParentMapping } from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { getParentFormatName } from 'integrations/common/pages/tabularConfigMapping/utils';
import { CustomIdFieldSelector } from './CustomIdFieldSelector';
import { ParentMissingComponentsStrategy } from './ParentMissingComponentsStrategy';

const PARENT_FORMATS: Array<{
  value: ParentMapping['parentFormat'];
  label: string;
}> = (
  ['path', 'ardoq-oid', 'custom-id'] satisfies ParentMapping['parentFormat'][]
).map(format => ({
  value: format,
  label: getParentFormatName(format),
}));

type ParentComponentProps = {
  tableMapping: TableMappingMap;
  parentMapping: ParentMapping;
  usedFields: FieldType[];
  allFields: FieldType[];
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
  columnName: string;
  isCreationDisabled: boolean;
};

export const ParentComponent = ({
  tableMapping,
  parentMapping,
  usedFields,
  allFields,
  onMapping,
  columnName,
  isCreationDisabled,
}: ParentComponentProps) => {
  return (
    <>
      <Select
        dataTestId="tabularMapping-parent-format--select"
        autoFocus
        label="Format"
        value={parentMapping.parentFormat}
        options={PARENT_FORMATS}
        onValueChange={value => {
          if (value === 'custom-id') {
            const defaultField = getDefaultWorkspaceField({
              columnName,
              tableMappingMap: tableMapping,
            });
            onMapping({
              parentFormat: value,
              fieldName: defaultField.id,
              fieldLabel: defaultField.label,
            });
          }
          if (value === 'ardoq-oid' || value === 'path') {
            onMapping({
              parentFormat: value,
            });
          }
        }}
      />
      {isCustomIdParentMapping(parentMapping) && (
        <CustomIdFieldSelector
          usedFields={usedFields}
          allFields={allFields}
          tableMapping={tableMapping}
          value={parentMapping.fieldName || parentMapping.fieldLabel}
          onMapping={onMapping}
          isCreationDisabled={isCreationDisabled}
        />
      )}

      <ParentMissingComponentsStrategy
        columnMapping={parentMapping}
        onMapping={onMapping}
      />
    </>
  );
};
