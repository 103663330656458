import {
  addWorkspaceRuleToQuery,
  removeWorkspaceRuleToQuery,
} from 'integrations/ITPedia/api/utils';
import { ConfigurationState } from '../types';
import { SignavioExporterBody } from '@ardoq/api-types/integrations';
import { Maybe } from '@ardoq/common-helpers';

export function configToApiBody(
  config: ConfigurationState
): SignavioExporterBody {
  return {
    _id: config._id,
    name: config.name,
    accountId: config.accountId,
    workspace: config.workspace,
    schedulingPolicy: config.schedulingPolicy,
    provider: 'signavio',
    componentTypes: Object.fromEntries(
      Object.entries(config.componentTypes).map(
        ([key, value]: [
          key: string,
          value: ConfigurationState['componentTypes'][string],
        ]) => {
          return [
            key,
            {
              name: key,
              componentsQuery: addWorkspaceRuleToQuery(
                config.workspace.id,
                value.componentsQuery
              ),
              dictionaryCategory: value.dictionaryCategory,
              mappedOid: value.mappedOid,
              mappedComponentUrl: value.mappedComponentUrl,
            } as SignavioExporterBody['componentTypes'][string],
          ];
        }
      )
    ),
  };
}

// Taking _id as a separate argument because the backend returns _id as a null when getting schedule
// Because usually _id on jobOptions means configuration _id rather than schedule _id
// In case of SignavioExporter, _id is the configuration _id

export function apiBodyToConfig(
  _id: Maybe<string>,
  body: SignavioExporterBody,
  results?: ConfigurationState['results']
): ConfigurationState {
  const componentTypes: ConfigurationState['componentTypes'] = Object.values(
    body.componentTypes
  ).reduce(
    (acc, ct) => ({
      ...acc,
      [ct.name]: {
        componentsQuery: removeWorkspaceRuleToQuery(ct.componentsQuery),
        componentsQueryResults: {
          status: 'INIT',
          total: 0,
        },
        isQueryValid: Boolean(ct.componentsQuery),
        dictionaryCategory: ct.dictionaryCategory,
        /**
         * The API sends some extra stuff for mappedOid and mappedComponentUrl which don't store
         * So mapping children props explicitly
         */
        mappedOid: {
          label: ct.mappedOid.label,
          value: ct.mappedOid.value,
        },
        mappedComponentUrl: {
          label: ct.mappedComponentUrl.label,
          value: ct.mappedComponentUrl.value,
        },
      },
    }),
    {}
  );
  return {
    _id,
    name: body.name,
    accountId: body.accountId,
    workspace: body.workspace,
    schedulingPolicy: body.schedulingPolicy,
    componentTypes,
    results: results || {
      diff: {
        status: 'INIT',
      },
    },
    scheduleStatus: 'INIT',
    applyFilters: Object.values(componentTypes).some(t =>
      Boolean(t.componentsQuery)
    ),
  };
}

/**
 * Calculates and renders a progress label for a multi-step process.
 *
 * The calculation logic is based on the following breakdown of the process:
 *
 * - 1 step: Calculate the difference between Signavio and Ardoq.
 * - 2 steps: Create dictionary categories, create meta-attributes.
 * - 2 steps: Create and update entries for every component type.
 *
 * Total steps: (1 step for diff calculation) + (2 steps for dictionary and meta-attributes) +
 * (2 steps per component type) * count of component types.
 *
 * The actual exports of the component types start on step 4.
 *
 * @param {number} completed - The current step that has been completed in the process.
 * @param {number} total - The total number of steps in the process.
 * @param {string} progressLabel - The label indicating what is being processed (e.g., "component types", "tables").
 * @returns {string} - A formatted string showing the current progress in the format `X/Y label`.
 */
export const renderProgressLabel = (
  completed: number,
  total: number,
  progressLabel: string
): string =>
  `${Math.max(0, Math.ceil((completed - 3) / 2))}/${total} ${progressLabel}`;
