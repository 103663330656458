import { actionCreator } from '@ardoq/rxbeach';

export type ComponentGraphSelectionChanged = {
  readonly graphSelection: string[];
};

export const componentGraphSelectionChanged =
  actionCreator<ComponentGraphSelectionChanged>(
    '[component selection] COMPONENT_GRAPH_SELECTION_CHANGED'
  );

export type ComponentTreeSelectionChanged = {
  readonly treeSelection: string[];
};

export const componentTreeSelectionChanged =
  actionCreator<ComponentTreeSelectionChanged>(
    '[component selection] COMPONENT_TREE_SELECTION_CHANGED'
  );

export type ComponentTreeSelectionStartSelected = {
  readonly selectionStart: string;
};

export const componentTreeSelectionStartSelected =
  actionCreator<ComponentTreeSelectionStartSelected>(
    '[component selection] COMPONENT_TREE_SELECTION_START_SELECTED'
  );
