import { GroupFieldLayout, RadioGroup } from '@ardoq/forms';
import {
  GroupingCollapseState,
  ViewpointSimpleBlockDiagramViewStyle,
  ViewpointTimelineViewStyle,
} from '@ardoq/api-types';
import { useController } from 'react-hook-form';

const groupingCollapseStateOptions = [
  {
    label: 'Expand all',
    value: GroupingCollapseState.EXPAND_ALL,
  },
  {
    label: 'Collapse all',
    value: GroupingCollapseState.COLLAPSE_ALL,
  },
];

type DefaultGroupingCollapseStateSelectProps = {
  viewStyle: ViewpointSimpleBlockDiagramViewStyle | ViewpointTimelineViewStyle;
};

const DefaultGroupingCollapseStateSelect = ({
  viewStyle,
}: DefaultGroupingCollapseStateSelectProps) => {
  const {
    field: { onChange },
  } = useController({ name: 'viewStyle.defaultGroupingCollapseState' });

  return (
    <RadioGroup
      onValueChange={newDefaultGroupingCollapseState =>
        onChange(newDefaultGroupingCollapseState)
      }
      label="Default grouping layout"
      groupFieldLayout={GroupFieldLayout.VERTICAL}
      value={
        viewStyle.defaultGroupingCollapseState ??
        GroupingCollapseState.EXPAND_ALL
      }
      options={groupingCollapseStateOptions}
    />
  );
};

export default DefaultGroupingCollapseStateSelect;
