import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { ResourceGroupsState } from './types';
import {
  FetchResourceGroupsSuccessPayload,
  UpdateResourceGroupsPayload,
  UpdateSelectedResourceGroupNamesPayload,
  fetchResourceGroupsFailure,
  fetchResourceGroupsSuccess,
  updateResourceGroups,
  updateSelectedResourceGroupNames,
  resetResourceGroups,
} from './actions';
import { AsyncStatus } from 'integrations/common/types/api';
import fp from 'lodash/fp';

const defaultState: ResourceGroupsState = {
  asyncStatus: 'INIT',
  selectedResourceGroupNames: [],
  resourceGroups: [],
};

const resetResourceGroupsState = () => defaultState;

const handleResetResourceGroups = reducer(
  resetResourceGroups,
  resetResourceGroupsState
);

const setAsyncStatus =
  (asyncStatus: AsyncStatus) =>
  (state: ResourceGroupsState): ResourceGroupsState => ({
    ...state,
    asyncStatus,
  });

const handleFetchResourceGroupsFailure = reducer<ResourceGroupsState>(
  fetchResourceGroupsFailure,
  setAsyncStatus('FAILURE')
);

const updateResourceGroupsReducer = (
  state: ResourceGroupsState,
  { resourceGroups }: FetchResourceGroupsSuccessPayload
): ResourceGroupsState => ({ ...state, resourceGroups });

const handleFetchResourceGroupsSuccess = reducer<
  ResourceGroupsState,
  FetchResourceGroupsSuccessPayload
>(
  fetchResourceGroupsSuccess,
  fp.flow(updateResourceGroupsReducer, setAsyncStatus('SUCCESS'))
);

const handleUpdateResourceGroups = reducer<
  ResourceGroupsState,
  UpdateResourceGroupsPayload
>(updateResourceGroups, updateResourceGroupsReducer);

const updateSelectedResourceGroupNamesReducer = (
  state: ResourceGroupsState,
  { names }: UpdateSelectedResourceGroupNamesPayload
): ResourceGroupsState => ({
  ...state,
  selectedResourceGroupNames: names,
});

const handleUpdateSelectedResourceGroupNames = reducer<
  ResourceGroupsState,
  UpdateSelectedResourceGroupNamesPayload
>(updateSelectedResourceGroupNames, updateSelectedResourceGroupNamesReducer);

const reducers = [
  handleUpdateResourceGroups,
  handleUpdateSelectedResourceGroupNames,
  handleFetchResourceGroupsSuccess,
  handleFetchResourceGroupsFailure,
  handleResetResourceGroups,
];

export const resourceGroups$ = persistentReducedStream(
  'integrationsAzureResourceGroups$',
  defaultState,
  reducers
);
