import { ViewIds } from '@ardoq/api-types';
import { SecondaryButton } from '@ardoq/button';
import { AddIcon } from '@ardoq/icons';
import { Link, header2, text2 } from '@ardoq/typography';
import VisualizationThumbnail from 'homePage/viewpointsWidget/VisualizationThumbnail';
import styled from 'styled-components';
import { CANNOT_CREATE_VIEWPOINT_POPOVER_ID } from 'viewpoints/consts';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { FlexBox, Stack } from '@ardoq/layout';

const StyledStack = styled(Stack)`
  max-width: 672px;
  margin: 0 auto;
  text-align: center;
`;

const Header2 = styled.h2`
  ${header2}
`;

const Text2 = styled.p`
  ${text2}
`;
type GetStartedWithViewpointsProperties = {
  canUserCreateTraversals: boolean;
  showVisual?: boolean;
  createNewViewpoint: () => void;
};
const GetStartedWithViewpoints = ({
  canUserCreateTraversals,
  showVisual = true,
  createNewViewpoint,
}: GetStartedWithViewpointsProperties) => {
  return (
    <StyledStack align="center" gap="small" justify="center" flex={1}>
      {showVisual && (
        <FlexBox>
          <VisualizationThumbnail viewId={ViewIds.RELATIONSHIPS} />
          <VisualizationThumbnail viewId={ViewIds.DEPENDENCY_MAP_2} />
          <VisualizationThumbnail viewId={ViewIds.MODERNIZED_BLOCK_DIAGRAM} />
          <VisualizationThumbnail viewId={ViewIds.TIMELINE} />
        </FlexBox>
      )}
      <Header2>Create your first Viewpoint</Header2>
      <Text2>
        Viewpoints are reusable templates for visualizing your data. They
        contain a set of component types connected by reference types.{' '}
        <Link
          typography="text2"
          target="_blank"
          hideIcon
          href={KnowledgeBaseLink.EMPTY_STATE_VIEW_BUILDER}
        >
          See how to do it
        </Link>
      </Text2>
      <SecondaryButton
        onClick={createNewViewpoint}
        popoverId={
          canUserCreateTraversals
            ? undefined
            : CANNOT_CREATE_VIEWPOINT_POPOVER_ID
        }
        isDisabled={!canUserCreateTraversals}
      >
        Create a viewpoint <AddIcon />
      </SecondaryButton>
    </StyledStack>
  );
};

export default GetStartedWithViewpoints;
