import { FunnelIds, TrackingAction } from './types';
import { actionCreator } from '@ardoq/rxbeach';

const NAMESPACE = '[integrations] IT_PEDIA';

export const trackITPediaEvent = actionCreator<TrackingAction>(
  `${NAMESPACE}_TRACK_IT_PEDIA_EVENT`
);

export const setFunnelId = actionCreator<Partial<FunnelIds>>(
  `${NAMESPACE}_SET_FUNNEL_ID`
);
