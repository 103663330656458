import { reducer } from '@ardoq/rxbeach';
import {
  stagedComponentsFailedToFetch,
  stagedComponentsFetched,
  fetchStagedComponents,
  addComponentsToSubdivisionFailure,
  addComponentsToSubdivisionAction,
} from './actions';
import {
  removeComponentsFromSubdivision,
  removeComponentsFromSubdivisionFailure,
} from 'streams/subdivisions/actions';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';
import { SubdivisionViewModelState } from 'subdivisionEditor/types';
import { BindingComponentsToSubdivisionStagedChanges } from '@ardoq/api-types';
import { AdvancedSearchError } from 'search/AdvancedSearch/types';

const { saveSubdivisionChangesReducer, resetIsSubmittingReducer } =
  subdivisionEditorOperations;

const fetchStagedComponentsReducer = (
  subdivisionEditorState: SubdivisionViewModelState
): SubdivisionViewModelState => {
  return {
    ...subdivisionEditorState,
    componentsSelection: {
      ...subdivisionEditorState.componentsSelection,
      isFetchingStagedComponents: true,
      searchError: null,
    },
  };
};

const fetchStagedComponentsSuccessReducer = (
  subdivisionEditorState: SubdivisionViewModelState,
  {
    componentsToAdd,
    componentsToRemove,
  }: BindingComponentsToSubdivisionStagedChanges
): SubdivisionViewModelState => {
  return {
    ...subdivisionEditorState,
    componentsSelection: {
      ...subdivisionEditorState.componentsSelection,
      componentsToAdd,
      componentsToRemove,
      isFetchingStagedComponents: false,
      showResults: true,
      resultsTotalCount: componentsToAdd.length + componentsToRemove.length,
    },
  };
};

const fetchStagedComponentsReducerFailureReducer = (
  subdivisionEditorState: SubdivisionViewModelState
): SubdivisionViewModelState => {
  return {
    ...subdivisionEditorState,
    componentsSelection: {
      ...subdivisionEditorState.componentsSelection,
      isFetchingStagedComponents: false,
      searchError: AdvancedSearchError.QUERY,
    },
  };
};

export const apiReducers = [
  // --------------------- API staged components Reducers ----------------------------
  reducer(fetchStagedComponents, fetchStagedComponentsReducer),
  reducer(stagedComponentsFetched, fetchStagedComponentsSuccessReducer),
  reducer(
    stagedComponentsFailedToFetch,
    fetchStagedComponentsReducerFailureReducer
  ),

  // --------------------- API Add Components to Subdivision Reducers ----------------
  reducer(addComponentsToSubdivisionAction, saveSubdivisionChangesReducer),
  reducer(addComponentsToSubdivisionFailure, resetIsSubmittingReducer),

  // --------------------- API Remove Components from Subdivision Reducers -----------
  reducer(removeComponentsFromSubdivision, saveSubdivisionChangesReducer),
  reducer(removeComponentsFromSubdivisionFailure, resetIsSubmittingReducer),
];
