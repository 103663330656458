import { APIToken } from '@ardoq/api-types';
import { reducedStream, reducer } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import {
  copyTokenAction,
  deleteTokenAction,
  newTokenGeneratedAction,
  resetTokenAction,
} from './actions';
import {
  copyAndDisappearTokenOperations,
  defaultCopyAndDisappearTokenState,
} from './operations';
import { copyAndDisappearTokenCommands } from './commands';
import * as serviceAccountsActions from 'admin/serviceAccounts/actions';
import * as tokensActions from '../actions';

export type CopyAndDisappearTokenState = {
  token?: APIToken;
  isCopied: boolean;
  isLoading: boolean;
};

export const copyAndDisappearToken$ = reducedStream(
  'copyAndDisappearToken$',
  defaultCopyAndDisappearTokenState,
  [
    reducer(
      serviceAccountsActions.createServiceAccount,
      copyAndDisappearTokenOperations.setIsLoading
    ),
    reducer(
      serviceAccountsActions.regenerateToken,
      copyAndDisappearTokenOperations.setIsLoading
    ),
    reducer(
      tokensActions.createToken,
      copyAndDisappearTokenOperations.setIsLoading
    ),
    reducer(
      tokensActions.regenerateToken,
      copyAndDisappearTokenOperations.setIsLoading
    ),
    reducer(
      serviceAccountsActions.createServiceAccountFailed,
      copyAndDisappearTokenOperations.resetStateReducer
    ),
    reducer(
      serviceAccountsActions.regenerateTokenFailed,
      copyAndDisappearTokenOperations.resetStateReducer
    ),
    reducer(
      serviceAccountsActions.accountDeleted,
      copyAndDisappearTokenOperations.deleteTokenForServiceAccount
    ),
    reducer(
      tokensActions.createTokenFailed,
      copyAndDisappearTokenOperations.resetStateReducer
    ),
    reducer(
      tokensActions.regenerateTokenFailed,
      copyAndDisappearTokenOperations.resetStateReducer
    ),
    reducer(
      tokensActions.userTokenDeleted,
      copyAndDisappearTokenOperations.deleteToken
    ),
    reducer(copyTokenAction, copyAndDisappearTokenOperations.copyTokenReducer),
    reducer(
      resetTokenAction,
      copyAndDisappearTokenOperations.resetStateReducer
    ),
    reducer(
      newTokenGeneratedAction,
      copyAndDisappearTokenOperations.setTokenReducer
    ),
    reducer(deleteTokenAction, copyAndDisappearTokenOperations.deleteToken),
  ]
).pipe(
  map(({ token, isCopied, isLoading }) => {
    return {
      token,
      isCopied,
      isLoading,
      commands: copyAndDisappearTokenCommands,
    };
  })
);
