import { trackEvent } from '../tracking/tracking';

enum UseCaseTrackingEventNames {
  NAVIGATED_TO_SOLUTIONS_PAGE = 'viewed_solutions_page',
  SELECTED_USE_CASE = 'selected_use_case',
  CLICKED_START_USE_CASE_BUTTON = 'clicked_start_use_case_button',
  CLICKED_START_USE_CASE_BUTTON_IN_CONFIRMATION_DIALOG = 'clicked_start_use_case_button_in_confirmation_dialog',
  VIEWED_SOLUTION_MATERIALS_TAB = 'viewed_solution_materials_tab',
  LOADING_USE_CASE_FAILED = 'loading_use_case_failed',
  OPENED_PDF_LINK = 'opened_pdf_link',
}

export const trackNavigatedToSolutionsPage = () =>
  trackEvent(UseCaseTrackingEventNames.NAVIGATED_TO_SOLUTIONS_PAGE);

export const trackSelectedUseCase = (useCaseName: string) =>
  trackEvent(UseCaseTrackingEventNames.SELECTED_USE_CASE, { useCaseName });

export const trackClickedStartUseCaseButton = (useCaseName: string) =>
  trackEvent(UseCaseTrackingEventNames.CLICKED_START_USE_CASE_BUTTON, {
    useCaseName,
  });

export const trackClickedStartUseCaseButtonInConfirmationDialog = (
  useCaseName: string
) =>
  trackEvent(
    UseCaseTrackingEventNames.CLICKED_START_USE_CASE_BUTTON_IN_CONFIRMATION_DIALOG,
    { useCaseName }
  );

export const viewedSolutionMaterialsTab = (useCaseName: string) =>
  trackEvent(UseCaseTrackingEventNames.VIEWED_SOLUTION_MATERIALS_TAB, {
    useCaseName,
  });

export const trackLoadingUseCaseFailed = (useCaseName: string) =>
  trackEvent(UseCaseTrackingEventNames.LOADING_USE_CASE_FAILED, {
    useCaseName,
  });

export const trackOpenedUseCasePDFLink = (linkLabel: string) =>
  trackEvent(UseCaseTrackingEventNames.OPENED_PDF_LINK, { linkLabel });
