import { metaModel$ } from 'architectureModel/metaModel$';
import { action$, reducer, reduceState, streamReducer } from '@ardoq/rxbeach';
import { combineLatest, map, distinctUntilChanged, Observable } from 'rxjs';
import activeView$ from 'streams/views/mainContent/activeView$';
import { availableViews$ } from 'views/availableViews$';
import {
  setFolderId,
  setGraphData,
  setInitialMetaInfo,
  setTraversalDescription,
  setTraversalName,
  resetMetaInfoState,
} from './metaInfoActions';
import {
  setStartTypeAndTraversalStartSetOrCount,
  setTraversals,
} from 'viewpointBuilder/traversals/editTraversalActions';
import { viewpointMetaInfoOperations } from './viewpointMetaInfoOperations';
import { MetaInfoState } from './metaInfoTypes';
import { setStateProperty } from '@ardoq/common-helpers';
import { selectedView$ } from '../selectedView/selectedView$';
import { viewpointFormatting$ } from '../formattingTabs/viewpointFormatting$';
import { ViewIds } from '@ardoq/api-types';
import { isEqual } from 'lodash';

const dependencies$ = combineLatest({
  metaModelResult: metaModel$,
  activeViews: activeView$,
  availableViews: availableViews$,
});

const selectedViewWarningDependency$ = combineLatest({
  selectedView: selectedView$,
  viewpointFormatting: viewpointFormatting$,
}).pipe(
  map(({ selectedView, viewpointFormatting }) => ({
    selectedView: selectedView.selectedView?.value || ViewIds.NONE,
    labelFormatting: viewpointFormatting.multiLabelFormatting.labelFormatting,
  })),
  distinctUntilChanged(isEqual)
);

export const metaInfo$: Observable<MetaInfoState> = action$.pipe(
  reduceState<MetaInfoState>(
    'viewpointBuilder2.0/metaInfo$',
    viewpointMetaInfoOperations.getInitialMetaInfoState(),
    [
      streamReducer(
        dependencies$,
        viewpointMetaInfoOperations.processDependencies
      ),
      streamReducer(selectedView$, viewpointMetaInfoOperations.setSelectedView),
      streamReducer(
        selectedViewWarningDependency$,
        viewpointMetaInfoOperations.setSelectedViewWarning
      ),
      reducer(setTraversalName, viewpointMetaInfoOperations.setTraversalName),
      reducer(setInitialMetaInfo, viewpointMetaInfoOperations.setInitialState),
      reducer(setTraversals, viewpointMetaInfoOperations.setTraversal),
      reducer(
        setStartTypeAndTraversalStartSetOrCount,
        viewpointMetaInfoOperations.setStartType
      ),
      reducer(
        setTraversalDescription,
        viewpointMetaInfoOperations.setTraversalDescription
      ),
      reducer(setGraphData, viewpointMetaInfoOperations.setGraphData),
      reducer(setFolderId, setStateProperty('folderId')),
      reducer(
        // This reducer is mainly for the test
        resetMetaInfoState,
        viewpointMetaInfoOperations.resetMetaInfoState
      ),
    ]
  ),
  map(viewpointMetaInfoOperations.stateWithSavableAttributes)
);
