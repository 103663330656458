import {
  APIViewpointAttributes,
  MetaModel,
  MetaModelTriple,
  QueryBuilderQuery,
  ViewpointBuilderFilters,
} from '@ardoq/api-types';
import { GraphNodeWithMetaData, GraphEdgeWithMetaData } from '@ardoq/graph';
import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import {
  TripleOptions,
  TriplesSortAndFiltersState,
} from 'viewpointBuilder/types';
import { getEmptySelectedNodeData } from 'viewpointBuilder/traversals/getEmptySelectedNodeData';
import { DirectedTripleWithNodeIds } from './traversalAudienceHelpers';

export const defaultMetamodel = {
  componentTypes: [],
  referenceTypes: [],
  triples: [],
};

const emptyTraversal = { paths: [], filters: {} };

export type OptionCountLoadingState = 'idle' | 'loading' | 'loaded';
export type MetaModelStatus = 'loading' | 'success' | 'error';
export type TraversalWithNodeIds = {
  filters?: ViewpointBuilderFilters;
  paths: DirectedTripleWithNodeIds[][];
};

export type AudienceTraversalDrawerState = {
  selectedComponentTypeName: string;
  surveyComponentTypeName: string;
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>;
  selectedGraphNodeId: string | null;
  graphEdges: Map<string, GraphEdgeWithMetaData>;
  graphNodes: Map<string, GraphNodeWithMetaData>;
  triplesSortAndFiltersState: TriplesSortAndFiltersState;
  traversalStartQuery: QueryBuilderQuery;
  tripleOptions: TripleOptions;
  unfilteredIncomingOptions: MetaModelTriple[];
  unfilteredOutgoingOptions: MetaModelTriple[];
  outgoingTriplesHiddenCount: number;
  incomingTriplesHiddenCount: number;
  metamodel: MetaModel;
  metamodelStatus: MetaModelStatus;
  traversalWithNodeIds: TraversalWithNodeIds;
  startNode: GraphNodeWithMetaData | undefined;
};

export const defaultState: AudienceTraversalDrawerState = {
  selectedComponentTypeName: '',
  surveyComponentTypeName: '',
  traversal: emptyTraversal,
  traversalWithNodeIds: emptyTraversal,
  selectedGraphNodeId: null,
  graphEdges: new Map(),
  graphNodes: new Map(),
  triplesSortAndFiltersState: {
    filterTerm: '',
    showOnlyOptionsWithInstanceCounts: true,
    tripleSortOrder: 'reference_type_a_z' as const,
  },
  traversalStartQuery: {} as QueryBuilderQuery,
  tripleOptions: {
    incoming: [],
    outgoing: [],
    instanceCountsResponse: null,
    selectedNode: getEmptySelectedNodeData(),
    selectedEdge: undefined,
    fetchCountsArgs: null,
    optionCountLoadingState: 'idle',
    showOnlyOptionsWithInstanceCounts: true,
  },
  unfilteredIncomingOptions: [],
  unfilteredOutgoingOptions: [],
  outgoingTriplesHiddenCount: 0,
  incomingTriplesHiddenCount: 0,
  metamodel: defaultMetamodel,
  metamodelStatus: 'loading',
  startNode: undefined,
};

export default persistentReducedStream(
  'audienceTraversalDrawer$',
  defaultState,
  reducers
);
