import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import {
  DecoratedSubdivision,
  ManageMembershipModalState,
  SelectionChanges,
} from './types';
import { MembershipsState } from './membershipOperations';

const DOMAIN = '[PERMISSION/PERMISSION_ZONES/MANAGE_MEMBERSHIP_MODAL/]';

export const showManageSubdivisionMembershipModal = actionCreator<
  ArdoqId[] | ArdoqId
>(`${DOMAIN} SHOW_MANAGE_SUBDIVISION_MEMBERSHIP_MODAL`);

export const selectedSubdivisionsChange = actionCreator<ArdoqId[]>(
  `${DOMAIN} SELECTED_SUBDIVISIONS_CHANGE`
);

export const initializeManageSubdivisionMembershipModal =
  actionCreator<ManageMembershipModalState>(
    `${DOMAIN} INITIALIZE_MANAGE_SUBDIVISION_MEMBERSHIP_MODAL`
  );

export const saveSubdivisionMemberships = actionCreator<MembershipsState>(
  `${DOMAIN} SAVE_SUBDIVISION_MEMBERSHIPS`
);

export const changeTablePage = actionCreator<number>(
  `${DOMAIN} CHANGE_TABLE_PAGE`
);

export const addSubdivisionToAddSelection = actionCreator<DecoratedSubdivision>(
  `${DOMAIN} ADD_SUBDIVISION_TO_ADD_SELECTION`
);

export const removeSubdivisionFromAddSelection =
  actionCreator<DecoratedSubdivision>(
    `${DOMAIN} REMOVE_SUBDIVISION_FROM_ADD_SELECTION`
  );

export const clearAddSelection = actionCreator(`${DOMAIN} CLEAR_ADD_SELECTION`);

export const applyAddSelection = actionCreator(`${DOMAIN} APPLY_ADD_SELECTION`);

export const applySelectionChanges = actionCreator<SelectionChanges>(
  `${DOMAIN} APPLY_SELECTION_CHANGES`
);

export const addSubdivisionToRemoveSelection =
  actionCreator<DecoratedSubdivision>(
    `${DOMAIN} ADD_SUBDIVISION_TO_REMOVE_SELECTION`
  );

export const removeSubdivisionFromRemoveSelection =
  actionCreator<DecoratedSubdivision>(
    `${DOMAIN} REMOVE_SUBDIVISION_FROM_REMOVE_SELECTION`
  );

export const clearRemoveSelection = actionCreator(
  `${DOMAIN} CLEAR_REMOVE_SELECTION`
);

export const applyRemoveSelection = actionCreator(
  `${DOMAIN} APPLY_REMOVE_SELECTION`
);

export const setSelectComponentRows = actionCreator<ArdoqId[]>(
  `${DOMAIN} SET_SELECTED_COMPONENT_ROWS`
);
