import * as perspectivesEditorActions from 'perspective/actions';

import {
  currentlySelectedSavedPerspectiveValues$,
  filters$,
  perspectiveEditorFormattingOptions$,
  perspectiveEditorGroupingOptions$,
  savedPerspectiveOptions$,
} from 'streams/perspectiveEditorData';

import type { TargetPortalConfig } from '@ardoq/post-message-bridge';
import { perspectivesRelatedData$ } from 'streams/perspectiveEditorData/perspectivesRelatedData$';
import { setIsUpdatingAppState } from 'isUpdatingAppState$';
import currentViewId$ from '../../streams/currentViewId$';

// Configures `ardoq-front -> standalone (PerspectiveEditor)` communication
export const perspectivesEditorTargetPortalConfig: TargetPortalConfig = {
  id: 'PERSPECTIVES_EDITOR',
  type: 'TARGET_PORTAL',
  actions: [
    perspectivesEditorActions.notifyDeleteSavedPerspectiveFailed,
    perspectivesEditorActions.notifyDeletingPerspectiveFinished,
    perspectivesEditorActions.notifyRenameSavedPerspectiveFinished,
    perspectivesEditorActions.notifyRenameSavedPerspectiveFailed,
    perspectivesEditorActions.notifySavePerspectiveFinished,
    perspectivesEditorActions.notifySavePerspectiveFailed,
    perspectivesEditorActions.notifySaveAsNewPerspectiveFinished,
    perspectivesEditorActions.notifySaveAsNewPerspectiveFailed,
    perspectivesEditorActions.notifyCreateNewPerspectiveFinished,
    perspectivesEditorActions.notifyCreateNewPerspectiveFailed,
    perspectivesEditorActions.notifyPerspectiveCleared,
    perspectivesEditorActions.notifyPerspectiveApplied,
    setIsUpdatingAppState,
  ],
  streamSubscriptions: [
    {
      stream: savedPerspectiveOptions$,
      action: perspectivesEditorActions.notifySavedPerspectivesOptionsChanged,
    },
    {
      stream: currentlySelectedSavedPerspectiveValues$,
      action: perspectivesEditorActions.notifySavedPerspectiveSelected,
    },
    {
      stream: perspectiveEditorGroupingOptions$,
      action: perspectivesEditorActions.notifyGroupingOptionsChanged,
    },

    {
      stream: perspectiveEditorFormattingOptions$,
      action: perspectivesEditorActions.notifyFormattingOptionsChanged,
    },
    {
      stream: filters$,
      action: perspectivesEditorActions.notifyFiltersChanged,
    },
    {
      stream: perspectivesRelatedData$,
      action: perspectivesEditorActions.notifyPerspectivesRelatedDataChanged,
    },
    {
      stream: currentViewId$,
      action: perspectivesEditorActions.notifyViewIdChanged,
    },
  ],
};
