import { ResizeUpdate, resizeCancel } from './actions';
import * as React from 'react';
import { ActionCreatorWithPayload, dispatchAction } from '@ardoq/rxbeach';
import {
  BOTTOM_BAR_HEIGHT,
  BOTTOM_BAR_BORDER_TOP,
  CONTENT_BAR_HEIGHT,
  RESIZER_MARGIN,
  SPLIT_WIDTH,
} from './consts';
import { AppLayoutState, GetBox, ResizeDirection } from './types';

const BOTTOM_BAR_LAYOUT_HEIGHT = BOTTOM_BAR_HEIGHT + BOTTOM_BAR_BORDER_TOP;

export const getContentBarStyle = ({
  isBottomBarVisible,
}: AppLayoutState): React.CSSProperties => ({
  bottom: isBottomBarVisible ? `${BOTTOM_BAR_LAYOUT_HEIGHT}px` : 0,
});

export const getEditorPaneStyle = ({
  isBottomBarVisible,
  editorHeight,
  isVerticalResizing,
  isEditorPanelVisible,
  isViewpointMode,
}: AppLayoutState): React.CSSProperties => ({
  height: `${isEditorPanelVisible ? editorHeight : 0}px`,
  zIndex: isVerticalResizing ? 1 : undefined,
  bottom: `${
    (isBottomBarVisible ? BOTTOM_BAR_LAYOUT_HEIGHT : 0) +
    (isViewpointMode ? 0 : CONTENT_BAR_HEIGHT)
  }px`,
});

export const getVerticalResizeStyle = ({
  isBottomBarVisible,
  isHorizontalResizing,
  editorHeight,
  resizeDelta,
  isViewpointMode,
}: AppLayoutState) => {
  if (isHorizontalResizing) {
    return {};
  }
  const height = Math.abs(resizeDelta) + 2 * RESIZER_MARGIN;

  const bottom =
    (isViewpointMode ? 0 : CONTENT_BAR_HEIGHT) +
    (isBottomBarVisible ? BOTTOM_BAR_LAYOUT_HEIGHT : 0) +
    editorHeight -
    (resizeDelta < 0 ? 0 : resizeDelta);

  return {
    width: '100%',
    height: `${height}px`,
    bottom: `min(100%, ${bottom}px)`,
  };
};

export const getHorizontalResizeStyle = ({
  isBottomBarVisible,
  isEditorPanelVisible,
  isVerticalResizing,
  isViewpointMode,
  editorHeight,
  resizeDelta,
  isSplitPane,
  leftPaneWidth,
  isRightEditorPaneOpen,
  rightEditorPaneWidth,
}: AppLayoutState): React.CSSProperties => {
  if (!(isSplitPane || isRightEditorPaneOpen) || isVerticalResizing) {
    return {};
  }

  const bottom = getPaneBottom(
    isBottomBarVisible,
    isEditorPanelVisible,
    isViewpointMode,
    editorHeight
  );

  const width = Math.abs(resizeDelta) + 2 * RESIZER_MARGIN;

  if (isRightEditorPaneOpen) {
    return {
      right: `min(100%, ${
        resizeDelta < 0
          ? rightEditorPaneWidth
          : rightEditorPaneWidth - resizeDelta
      }px)`,
      width: `${width}px`,
      bottom: `${bottom}px`,
    };
  }

  return {
    left: `calc(${(leftPaneWidth * 100).toFixed(1)}% - ${
      resizeDelta < 0 ? Math.abs(resizeDelta) : 0
    }px)`,
    width: `${width}px`,
    bottom: `${bottom}px`,
  };
};

export const getLeftPaneStyle = ({
  isBottomBarVisible,
  isEditorPanelVisible,
  isViewpointMode,
  editorHeight,
  isSplitPane,
  leftPaneWidth,
  isRightEditorPaneOpen,
  rightEditorPaneWidth,
}: AppLayoutState) => {
  const bottom = getPaneBottom(
    isBottomBarVisible,
    isEditorPanelVisible,
    isViewpointMode,
    editorHeight
  );
  if (isViewpointMode) {
    return { bottom };
  }
  if (isRightEditorPaneOpen) {
    return {
      right: `${rightEditorPaneWidth}px`,
      bottom: `${bottom}px`,
    };
  }

  const width = getLeftPaneWidth(isSplitPane, leftPaneWidth);

  return {
    width: isSplitPane
      ? `calc(${(width * 100).toFixed(1)}% - ${SPLIT_WIDTH / 2}px)`
      : '100%',
    bottom: `${bottom}px`,
  };
};

const getLeftPaneWidth = (isSplitPane: boolean, leftPaneWidth: number) =>
  isSplitPane ? leftPaneWidth : 1;

export const getRightPaneStyle = ({
  isBottomBarVisible,
  isEditorPanelVisible,
  isViewpointMode,
  editorHeight,
  isSplitPane,
  leftPaneWidth,
}: AppLayoutState) => {
  const bottom = getPaneBottom(
    isBottomBarVisible,
    isEditorPanelVisible,
    isViewpointMode,
    editorHeight
  );

  const left = getLeftPaneWidth(isSplitPane, leftPaneWidth);

  return {
    left: `calc(${(left * 100).toFixed(1)}% + ${SPLIT_WIDTH / 2}px)`,
    right: 0,
    bottom: `${bottom}px`,
  };
};

export const getRightEditorPaneStyle = ({
  isRightEditorPaneOpen,
  rightEditorPaneWidth,
  isBottomBarVisible,
  isEditorPanelVisible,
  isViewpointMode,
  editorHeight,
}: AppLayoutState): React.CSSProperties => {
  const bottom = getPaneBottom(
    isBottomBarVisible,
    isEditorPanelVisible,
    isViewpointMode,
    editorHeight
  );

  return {
    width: isRightEditorPaneOpen ? `${rightEditorPaneWidth}px` : '0',
    bottom: `${bottom}px`,
  };
};

const getPaneBottom = (
  isBottomBarVisible: boolean,
  isEditorPanelVisible: boolean,
  isViewpointMode: boolean,
  editorHeight: number
) =>
  (isViewpointMode ? 0 : CONTENT_BAR_HEIGHT) +
  (isBottomBarVisible ? BOTTOM_BAR_LAYOUT_HEIGHT : 0) +
  (isEditorPanelVisible ? editorHeight : 0);

export const dispatch = (
  event: MouseEvent | React.MouseEvent,
  getBox: GetBox,
  action: ActionCreatorWithPayload<ResizeUpdate>,
  direction: ResizeDirection
) => {
  const { clientX: x, clientY: y } = event;
  const box = getBox();
  const areValidValues =
    box &&
    box.width > 0 &&
    box.height > 0 &&
    Number.isFinite(x) &&
    Number.isFinite(y) &&
    x >= 0 &&
    x <= window.innerWidth &&
    y >= 0 &&
    y <= window.innerHeight;

  if (areValidValues) {
    dispatchAction(action({ x, y, box, direction }));
  } else {
    dispatchAction(resizeCancel());
  }
};

// Returns the panel top in viewport coordinates.
export const getPanelTop = (editorHeight: number, box: DOMRect) => {
  return box.bottom - editorHeight;
};

// Returns the pane left in viewport coordinates.
export const getPaneLeft = (leftPaneWidth: number, box: DOMRect) => {
  return box.left + box.width * leftPaneWidth;
};
