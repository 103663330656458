import { getFiltersTabPlainDataParamsInitialState } from './filtersTabReducers';
import {
  formattingTabReducers,
  getFormattingTabInitialState,
} from './formattingTabReducers';
import {
  getAsyncBackgroundOperationInitialState,
  perspectiveEditorReducers,
} from './perspectiveEditorReducers';
import {
  getGroupsTabInitialState,
  groupTabReducers,
} from './groupings/groupsTabReducers';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { PerspectiveTab } from '@ardoq/perspectives-sidebar';
import { PerspectivesEditorState } from '../types';
import { resetPerspectiveEditorState, setActiveTab } from './actions';
import {
  emptyAvailableRelatedData,
  emptyPerspectivesRelatedData,
} from './emptyPerspectivesRelatedData';
import { commonReducers } from './commonReducers';
import { perspectiveAppliedReducer } from './perspectiveAppliedReducer';
import { resetEditorStateToAppliedRulesReducer } from './resetEditorStateToAppliedRulesReducer';
import { ViewIds } from '@ardoq/api-types';
import { activeViewIdReducer } from './activeViewIdReducer';
import { handleDatasetChangeReducer } from './handleDatasetChangeReducer';

export const getInitialState: () => PerspectivesEditorState = () => ({
  activeViewId: ViewIds.NONE,
  currentWorkspaceId: null,
  activeTabId: PerspectiveTab.GROUPS,
  filtersTabPlainDataParams: getFiltersTabPlainDataParamsInitialState(),
  formattingTab: getFormattingTabInitialState(),
  groupsTab: getGroupsTabInitialState(),
  savedPerspectives: {
    savedPerspectives: [],
    isOnlyReaderRole: false,
    selectedSavedPerspectiveState: null,
  },
  asyncBackgroundOperation: getAsyncBackgroundOperationInitialState(),
  perspectivesRelatedData: emptyPerspectivesRelatedData,
  // holds data limited by workspace filter
  availableRelatedData: emptyAvailableRelatedData,
});

/**
 * This is tiny reducer, so I have put it here, but for bigger reducers
 * please create separated files.
 */
const activeTabReducer = (
  state: PerspectivesEditorState,
  activeTabId: PerspectiveTab
) => ({
  ...state,
  activeTabId,
});

/**
 * This reducer is used for tests to assure that tests do not interfere with each other.
 */
const resetStateReducer = reducer<PerspectivesEditorState>(
  resetPerspectiveEditorState,
  getInitialState
);

const stateReducers = [
  reducer<PerspectivesEditorState, PerspectiveTab>(
    setActiveTab,
    activeTabReducer
  ),
  resetStateReducer,
  activeViewIdReducer,
  perspectiveAppliedReducer,
  resetEditorStateToAppliedRulesReducer,
  handleDatasetChangeReducer,
  ...groupTabReducers,
  ...formattingTabReducers,
  ...perspectiveEditorReducers,
  ...commonReducers,
];

export const perspectivesEditor$ = persistentReducedStream(
  'perspectivesEditor$',
  getInitialState(),
  stateReducers
);
