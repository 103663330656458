import { AssetType, Asset, ArdoqId } from '@ardoq/api-types';
import { Filter } from './filterBarTypes';
import { IconName, getIconNameForAssetType } from '@ardoq/icons';
import { capitalize } from 'lodash';

const getAssetFilterLabel = (assetType: AssetType) => {
  if (assetType === AssetType.TRAVERSAL) {
    return 'Viewpoints';
  }
  if (assetType === AssetType.VIEWPOINT) {
    return 'Discover Viewpoints';
  }
  return `${capitalize(assetType)}s`;
};

const isAssetFn = (asset: Asset, assetType: AssetType) => {
  return asset.meta.assetType === assetType;
};

export const getAssetFilter = (assetType: AssetType): Filter => ({
  label: getAssetFilterLabel(assetType),
  filterFn: asset => isAssetFn(asset, assetType),
  filterRule: 'OR',
  iconName: getIconNameForAssetType(assetType),
});

export const favoriteFilter: Filter = {
  label: 'Favorites',
  filterFn: asset => asset.meta.favorite,
  filterRule: 'AND',
  iconName: IconName.STAR,
};

export const getCreatedByMeFilter = (currentUserId: ArdoqId): Filter => ({
  label: 'Created by me',
  filterFn: asset => 'createdBy' in asset && asset.createdBy === currentUserId,
  filterRule: 'AND',
});
