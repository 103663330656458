import { AppModules } from 'appContainer/types';
import { AppRouterState, WorkspaceModuleRoute } from './appRouterTypes';
import { Route } from 'router/StreamRouter';
import { map } from 'rxjs/operators';
import { ArdoqId, LoadedStateParams, ViewIds } from '@ardoq/api-types';
import { context$ } from 'streams/context/context$';
import activeView$ from 'streams/views/mainContent/activeView$';
import { combineLatest } from 'rxjs';
import gridEditor2023$ from 'gridEditor2023/gridEditor$';
import { gridEditorStateOperations as gridEditor2023Operations } from 'gridEditor2023/gridEditorStateOperations';
import { ContextSort, NumericSortOrder } from '@ardoq/common-helpers';
import Context from 'context';
import activeFilter$ from 'streams/filters/activeFilter$';
import { dispatchAction } from '@ardoq/rxbeach';
import { loadWorkspaceModuleRoute } from './navigationActions';
import { APP_ROUTER_DEFAULT_LOCATION } from './appRouterUtils';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { Features, hasFeature } from '@ardoq/features';
import { loadedState$ } from 'loadedState/loadedState$';
import {
  decodeLoadedState,
  encodeLoadedState,
} from 'loadedState/encodeDecodeState';
import { selectedSlide$ } from 'streams/selectedSlide/selectedSlide$';

const routePatterns = {
  view: /\/view\/workspace\/([\da-z+]+)[/]*(component|integration)*[/]*([\da-z]+)*\??(.+)*/,
  viewTab:
    /\/view\/([a-z\dA-Z_\-+]+)\/workspace\/([\da-z+]+)[/]*(component|integration)*[/]*([\da-z]+)*\??(.+)*/,
  presentation:
    /\/presentation(?:\/id_)?([\da-z+]+)?\/workspace\/([\da-z+]+)[/]*(component|integration)*[/]*([\da-z]+)*\??(.+)*/,
  presentationTab:
    /\/presentation(?:\/id_)?([\da-z+]+)?\/(?!id_)([a-z\dA-Z_\-+]+)\/workspace\/([\da-z+]+)[/]*(component|integration)*[/]*([\da-z]+)*\??(.+)*/,
  grid: /\/grid\/workspace\/([\da-z+]+)[/]*(component|integration)*[/]*([\da-z]+)*\??(.+)*/,
  gridTab:
    /\/grid\/([a-z\dA-Z_\-+]+)\/workspace\/([\da-z+]+)[/]*(component|integration)*[/]*([\da-z]+)*\??(.+)*/,
};

enum QueryParamKeys {
  Filters = 'filters',
  PerspectiveId = 'ps_id',
  SortAscending = 'sort_ASC',
  SortDescending = 'sort_DESC',
  ActiveWorkspace = 'activeWorkspace',
  LoadedState = 'loadedState',
  SelectedSlide = 'selectedSlide',
}
const getMainViewId = (mainViewId: ViewIds | null, workspaceId: string) =>
  mainViewId ||
  workspaceInterface.getStartView(workspaceId) ||
  ViewIds.PAGESVIEW;
const workspaceModuleRoute = new Route<AppRouterState, WorkspaceModuleRoute>({
  doesLocationMatch: ({ path }) =>
    Object.values(routePatterns).some(regexp => regexp.test(path)),
  locationToRouterState: ({ path, searchParams, search }) => {
    const appModule = AppModules.WORKSPACES;
    let workspaceIds: ArdoqId[] = [];
    let isGridEditorShown = false;
    let componentId = null;
    let referenceId = null;
    let mainViewId: ViewIds | null = null;
    let secondaryViewId: ViewIds | null = null;
    let presentationId: string | null = null;
    let perspectiveId: string | null = null;
    let activeFiltersQueryString = '';
    let loadedState: LoadedStateParams[] = [];
    let selectedSlideId = null;
    const sort: ContextSort = {} as ContextSort;

    const viewMatches = path.match(routePatterns.view);
    const viewTabMatches = path.match(routePatterns.viewTab);
    const presentationMatches = path.match(routePatterns.presentation);
    const presentationTabMatches = path.match(routePatterns.presentationTab);
    const gridMatches = path.match(routePatterns.grid);
    const gridTabMatches = path.match(routePatterns.gridTab);
    if (viewMatches) {
      const [, workspaceIdsMatch, type, objectId] = viewMatches;

      workspaceIds = workspaceIdsMatch.split('+');
      componentId = type === 'component' ? objectId : null;
      referenceId = type === 'integration' ? objectId : null;
    } else if (viewTabMatches) {
      const [, viewTabs, workspaceIdsMatch, type, objectId] = viewTabMatches;
      const [mainViewIdMatch, secondaryViewIdMatch] = viewTabs.split('+') as [
        ViewIds,
        ViewIds | undefined,
      ];

      workspaceIds = workspaceIdsMatch.split('+');
      componentId = type === 'component' ? objectId : null;
      referenceId = type === 'integration' ? objectId : null;
      mainViewId = mainViewIdMatch;
      secondaryViewId = secondaryViewIdMatch || null;
    } else if (presentationMatches) {
      const [, presentationIdMatch, workspaceIdsMatch, type, objectId] =
        presentationMatches;

      workspaceIds = workspaceIdsMatch.split('+');
      componentId = type === 'component' ? objectId : null;
      referenceId = type === 'integration' ? objectId : null;
      presentationId = presentationIdMatch;
    } else if (presentationTabMatches) {
      const [
        ,
        presentationIdMatch,
        viewTabs,
        workspaceIdsMatch,
        type,
        objectId,
      ] = presentationTabMatches;
      const [mainViewIdMatch, secondaryViewIdMatch] = viewTabs.split('+') as [
        ViewIds,
        ViewIds | undefined,
      ];

      workspaceIds = workspaceIdsMatch.split('+');
      componentId = type === 'component' ? objectId : null;
      referenceId = type === 'integration' ? objectId : null;
      mainViewId = mainViewIdMatch;
      secondaryViewId = secondaryViewIdMatch || null;
      presentationId = presentationIdMatch;
    } else if (gridMatches) {
      const [, workspaceIdsMatch, type, objectId] = gridMatches;

      workspaceIds = workspaceIdsMatch.split('+');
      componentId = type === 'component' ? objectId : null;
      referenceId = type === 'integration' ? objectId : null;
      isGridEditorShown = true;
    } else if (gridTabMatches) {
      const [, viewTabs, workspaceIdsMatch, type, objectId] = gridTabMatches;
      const [mainViewIdMatch, secondaryViewIdMatch] = viewTabs.split('+') as [
        ViewIds,
        ViewIds | undefined,
      ];

      workspaceIds = workspaceIdsMatch.split('+');
      componentId = type === 'component' ? objectId : null;
      referenceId = type === 'integration' ? objectId : null;
      mainViewId = mainViewIdMatch;
      secondaryViewId = secondaryViewIdMatch || null;
      isGridEditorShown = true;
    }

    if (searchParams) {
      const ascendingSort = searchParams.get(QueryParamKeys.SortAscending);
      const descendingSort = searchParams.get(QueryParamKeys.SortDescending);
      if (ascendingSort) {
        sort.order = NumericSortOrder.ASC;
        sort.attr = ascendingSort;
      } else if (descendingSort) {
        sort.order = NumericSortOrder.DESC;
        sort.attr = descendingSort;
      }

      perspectiveId = searchParams.get(QueryParamKeys.PerspectiveId) || null;

      activeFiltersQueryString = (
        searchParams.get(QueryParamKeys.Filters) || ''
      )
        // location.searchParams decodes the search params in a different way
        // than what we use to encode it. encodeURI
        // (what we use in most cases) encodes 'now+0d' to 'now+0d'
        // (encodeURIComponent would encode it to 'now%2B0d'), but
        // location.searchParams decodes the '+' to a space e.g. treats
        // the search params as 'application/x-www-form-urlencoded' string.
        // This is more of a workaround. A proper fix would be to implement
        // our search param encoding/decoding properly, but that is potentially
        // a bigger task.
        .replaceAll(/now (\d+)d/gi, (_, days) => `now+${days}d`);

      loadedState = decodeLoadedState(
        searchParams.get(QueryParamKeys.LoadedState) ?? ''
      );
      selectedSlideId = searchParams.get(QueryParamKeys.SelectedSlide) ?? null;
    }

    // Support legacy perspective ID routing
    const legacyPerspectiveMatches =
      search && search.match(/ps_@id=([a-z0-9]+)/i);
    if (!perspectiveId && legacyPerspectiveMatches) {
      perspectiveId = legacyPerspectiveMatches[1];
    }

    const workspaceId = searchParams?.get(QueryParamKeys.ActiveWorkspace) ?? '';
    return {
      appModule,
      workspaceIds,
      workspaceId,
      componentId,
      referenceId,
      mainViewId: getMainViewId(mainViewId, workspaceIds[0]),
      secondaryViewId,
      isGridEditorShown,
      presentationId,
      sort,
      perspectiveId,
      activeFiltersQueryString,
      loadedState,
      selectedSlideId,
    };
  },
  doesRouterStateMatch: ({ appModule, scenarioId }) => {
    return appModule === AppModules.WORKSPACES && !scenarioId;
  },
  routerStateToLocation: ({
    workspaceIds,
    workspaceId,
    componentId,
    referenceId,
    mainViewId,
    secondaryViewId,
    isGridEditorShown,
    presentationId,
    sort,
    perspectiveId,
    activeFiltersQueryString,
    loadedState,
    selectedSlideId,
  }) => {
    if (workspaceIds.length === 0) {
      return APP_ROUTER_DEFAULT_LOCATION;
    }
    const searchParams = new URLSearchParams();
    let path = '/view';
    if (isGridEditorShown) {
      path = '/grid';
    } else if (presentationId) {
      path = `/presentation/id_${presentationId}`;
    }
    if (mainViewId) {
      path += `/${mainViewId}`;
    }
    if (secondaryViewId) {
      path += `+${secondaryViewId}`;
    }
    path += `/workspace/${workspaceIds.join('+')}`;

    if (referenceId) {
      path += `/integration/${referenceId}`;
    } else if (componentId) {
      path += `/component/${componentId}`;
    }

    if (sort.order === NumericSortOrder.ASC) {
      searchParams.set(QueryParamKeys.SortAscending, sort.attr);
    }
    if (sort.order === NumericSortOrder.DESC) {
      searchParams.set(QueryParamKeys.SortDescending, sort.attr);
    }

    if (perspectiveId) {
      searchParams.set(QueryParamKeys.PerspectiveId, perspectiveId);
    } else if (activeFiltersQueryString) {
      searchParams.set(
        QueryParamKeys.Filters,
        // Decode to prevent double encoding
        // since the query string is already URL encoded
        // This should be cleaned up once the backbone router is removed
        decodeURI(activeFiltersQueryString)
      );
    }
    searchParams.set(QueryParamKeys.ActiveWorkspace, workspaceId);
    if (hasFeature(Features.SUPPORT_LARGE_DATASETS)) {
      searchParams.set(
        QueryParamKeys.LoadedState,
        encodeLoadedState(loadedState)
      );
    }
    if (selectedSlideId) {
      searchParams.set(QueryParamKeys.SelectedSlide, selectedSlideId);
    }

    const contextWorkspaceId = Context.activeWorkspaceId() ?? '';
    const contextWorkspaceName =
      workspaceInterface.getWorkspaceName(contextWorkspaceId);

    return {
      path,
      title: contextWorkspaceName ?? '',
      searchParams,
    };
  },
  setApplicationStateFromRoute: routerState =>
    dispatchAction(loadWorkspaceModuleRoute(routerState)),
  getPartialRouterStateStream: () =>
    combineLatest({
      context: context$,
      activeView: activeView$,
      gridEditor2023: gridEditor2023$,
      activeFilter: activeFilter$,
      loadedState: loadedState$,
      selectedSlide: selectedSlide$,
    }).pipe(
      map(
        ({
          context: {
            workspacesIds,
            workspaceId,
            presentationId,
            componentId,
            referenceId,
            sort,
          },
          activeView: { mainViewId, secondaryViewId },
          gridEditor2023,
          activeFilter: {
            selectedPerspectiveQueryString,
            selectedPerspectiveId,
            activeFiltersQueryString,
          },
          loadedState,
          selectedSlide: { selectedSlideId },
        }) => {
          const isGridEditorShown =
            gridEditor2023Operations.isDockedGridEditorState(gridEditor2023) &&
            gridEditor2023Operations.isDockedGridEditorOpen(gridEditor2023);

          return {
            workspaceIds: workspacesIds,
            workspaceId,
            componentId: componentId || null,
            referenceId,
            mainViewId,
            secondaryViewId,
            isGridEditorShown,
            presentationId: presentationId || null,
            sort,
            perspectiveId:
              selectedPerspectiveQueryString === activeFiltersQueryString
                ? selectedPerspectiveId
                : null,
            activeFiltersQueryString,
            loadedState,
            selectedSlideId,
          };
        }
      )
    ),
});

export default workspaceModuleRoute;
