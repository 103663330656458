import { ZoomTransform } from 'd3';
import { actionCreator } from '@ardoq/rxbeach';
import {
  LayoutType,
  RelationshipsLinkVisual,
  RelationshipsNode,
  WindowRect,
} from './types';
interface ZoomChangedPayload {
  transform: ZoomTransform;
}
export const zoomChanged = actionCreator<ZoomChangedPayload>(
  '[Relationships] ZOOM_CHANGED'
);

type LayoutBeginArgs = { layout: LayoutType };
export const layoutBegin = actionCreator<LayoutBeginArgs>(
  '[Relationships] LAYOUT_BEGIN'
);
type LayoutCompleteArgs = { needsFit: boolean };
export const layoutComplete = actionCreator<LayoutCompleteArgs>(
  '[Relationships] LAYOUT_COMPLETE'
);

export interface SetHighlightArgs {
  highlightedNode: RelationshipsNode | null;
  highlightedLink: RelationshipsLinkVisual | null;
}
export const relationshipsViewSetHighlight = actionCreator<SetHighlightArgs>(
  '[Relationships] SET_HIGHLIGHT'
);

interface SetLinkBundlingArgs {
  bundleRelationships: boolean;
}
export const relationshipsViewSetLinkBundling =
  actionCreator<SetLinkBundlingArgs>('[Relationships] SET_LINK_BUNDLING');

interface RelationshipsViewZoomToFitArgs {
  node?: RelationshipsNode;
  duration?: number;
}
export const relationshipsViewZoomToFit =
  actionCreator<RelationshipsViewZoomToFitArgs>('[Relationships] ZOOM_TO_FIT');

interface SetHighlightDisconnectedNodesArgs {
  highlightDisconnectedNodes: boolean;
}
export const relationshipsViewSetHighlightDisconnectedNodes =
  actionCreator<SetHighlightDisconnectedNodesArgs>(
    '[Relationships] HIGHLIGHT_DISCONNECTED_NODES'
  );

interface SetCollapsedGroupIdsArgs {
  collapsedGroupIds: string[];
}
export const relationshipsViewSetCollapsedGroupIds =
  actionCreator<SetCollapsedGroupIdsArgs>(
    '[Relationships] SET_COLLAPSED_GROUP_IDS'
  );

interface RelationshipsViewUpdateUpdateWindowRectArgs {
  currentWindowRect: WindowRect | null;
}
export const relationshipsViewUpdateWindowRect =
  actionCreator<RelationshipsViewUpdateUpdateWindowRectArgs>(
    '[Relationships] UPDATE_WINDOW_RECT'
  );

export const relationshipsViewLayoutUpdated = actionCreator(
  '[Relationships] LAYOUT_UPDATED'
);
