import {
  NavigatorItemExpander,
  NavigatorItemLink,
  NavigatorItemCloseWorkspace,
  NavigatorItemCloseScenario,
  NavigatorItemContextMenu,
} from './components/Atoms';
import {
  COMPONENT_ITEM_CLASS_NAME,
  DRAG_TARGET_CLASS_NAME,
  SCENARIO_ITEM_CLASS_NAME,
  WORKSPACE_ITEM_CLASS_NAME,
} from './consts';
import { TargetManager } from './utils/TargetManager';

export const [
  EXPANDER_CLASS_NAME,
  LINK_CLASS_NAME,
  CLOSE_WORKSPACE_CLASS_NAME,
  CLOSE_SCENARIO_CLASS_NAME,
  CONTEXT_MENU_CLASS_NAME,
] = [
  NavigatorItemExpander,
  NavigatorItemLink,
  NavigatorItemCloseWorkspace,
  NavigatorItemCloseScenario,
  NavigatorItemContextMenu,
].map(styledComponent => styledComponent.toString().slice(1));

export const targetManager = new TargetManager({
  click: [
    EXPANDER_CLASS_NAME,
    LINK_CLASS_NAME,
    WORKSPACE_ITEM_CLASS_NAME,
    COMPONENT_ITEM_CLASS_NAME,
    CLOSE_WORKSPACE_CLASS_NAME,
    CLOSE_SCENARIO_CLASS_NAME,
    CONTEXT_MENU_CLASS_NAME,
    SCENARIO_ITEM_CLASS_NAME,
  ],
  mousedown: [DRAG_TARGET_CLASS_NAME],
  contextmenu: [
    COMPONENT_ITEM_CLASS_NAME,
    WORKSPACE_ITEM_CLASS_NAME,
    SCENARIO_ITEM_CLASS_NAME,
  ],
});
