import { TripleWithRelatedFields } from '@ardoq/api-types';
import { pluralize } from '@ardoq/common-helpers';

import { Icon, IconName, IconSize } from '@ardoq/icons';
import { TextOverflow } from '@ardoq/popovers';
import { StatusType, Tag } from '@ardoq/status-ui';
import { isEmpty } from 'lodash';
import { Caption, ReferenceFieldsText } from 'useCases/atoms';
import { FlexBox, Stack } from '@ardoq/layout';

export const TripleDecorator = ({
  triple,
  fields,
}: TripleWithRelatedFields) => (
  <Stack gap="xxsmall">
    <FlexBox align="center" gap="xxsmall">
      <Tag label={triple[0]} statusType={StatusType.INFO} />
      <Icon iconName={IconName.ARROW_FORWARD} iconSize={IconSize.MEDIUM} />
      <TextOverflow>
        <Caption>{triple[1]}</Caption>
      </TextOverflow>
      <Icon iconName={IconName.ARROW_FORWARD} iconSize={IconSize.MEDIUM} />
      <Tag label={triple[2]} statusType={StatusType.INFO} />
    </FlexBox>

    {!isEmpty(fields) ? (
      <FlexBox flexWrap gap="xsmall" marginTop="xsmall">
        <ReferenceFieldsText>
          {pluralize('Reference field', fields.length)}:
        </ReferenceFieldsText>
        {fields.map(field => (
          <Tag key={field} label={field} statusType={StatusType.INFO} />
        ))}
      </FlexBox>
    ) : null}
  </Stack>
);
