import { catchError, combineLatest, of, switchMap, tap } from 'rxjs';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  handleApiError,
  retriableApiRequest,
} from 'integrations/common/utils/api';
import {
  fetchRegions,
  fetchRegionsFailure,
  fetchRegionsSuccess,
  updateAsyncStatus,
} from './actions';
import * as apiUtils from './apiUtils';

const handleFetchRegionsList = routine(
  ofType(fetchRegions),
  extractPayload(),
  switchMap(payload => {
    dispatchAction(
      updateAsyncStatus({
        integrationId: payload.integrationId,
        asyncStatus: 'LOADING',
      })
    );
    return combineLatest([
      of(payload.integrationId),
      retriableApiRequest(() => apiUtils.fetchRegionsList(payload)).pipe(
        catchError(error => {
          dispatchAction(
            fetchRegionsFailure({ integrationId: payload.integrationId })
          );
          return handleApiError(error);
        })
      ),
    ]);
  }),
  tap(([integrationId, regions]) => {
    dispatchAction(fetchRegionsSuccess({ integrationId, regions }));
  }),
  catchError((_, stream$) => {
    // TODO: throw an error instead of returning the stream. Currently, we don't throw the error because this breaks the stream and stops the parallel routines.
    return stream$;
  })
);

export default [handleFetchRegionsList];
