import {
  ArdoqId,
  BroadcastPerson,
  WorkspaceAudienceData,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { WorkspaceOption } from './types';

export const fetchPeopleWorkspaceOptions = actionCreator(
  '[workspaceAudienceDialog] FETCH_PEOPLE_WORKSPACE_OPTIONS'
);

export const notifyFailedToFetchPeopleWorkspaceOptions = actionCreator(
  '[workspaceAudienceDialog] NOTIFY_FAILED_TO_FETCH_PEOPLE_WORKSPACE_OPTIONS'
);

export const openExistingWorkspaceAudienceDialog =
  actionCreator<WorkspaceAudienceData>(
    '[workspaceAudienceDialog] OPEN_EXISTING_WORKSPACE_AUDIENCE_DIALOG'
  );

export const openNewWorkspaceAudienceDialog = actionCreator(
  '[workspaceAudienceDialog] OPEN_NEW_WORKSPACE_AUDIENCE_DIALOG'
);

export const setPeopleWorkspaceOptions = actionCreator<WorkspaceOption[]>(
  '[workspaceAudienceDialog] SET_PEOPLE_WORKSPACE_OPTIONS'
);

export const choosePeopleWorkspace = actionCreator<ArdoqId>(
  '[workspaceAudienceDialog] CHOOSE_PEOPLE_WORKSPACE'
);

export const setLimitedToPersonIds = actionCreator<ArdoqId[]>(
  '[workspaceAudienceDialog] SET_LIMITED_TO_PERSON_IDS'
);

export const fetchPeopleInSelectedWorkspace = actionCreator<ArdoqId>(
  '[workspaceAudienceDialog] FETCH_PEOPLE_IN_SELECTED_WORKSPACE'
);

export const setPeopleInSelectedWorkspace = actionCreator<BroadcastPerson[]>(
  '[workspaceAudienceDialog] SET_PEOPLE_IN_SELECTED_WORKSPACE'
);

export const notifyFailedToFetchPeopleInSelectedWorkspace = actionCreator(
  '[workspaceAudienceDialog] NOTIFY_FAILED_TO_FETCH_PEOPLE_IN_SELECTED_WORKSPACE'
);
