import {
  getDeletedEntitiesByWorkspaceIdError,
  getDeletedEntitiesByWorkspaceIdSuccess,
  loadingEntitiesStart,
} from './actions';
import {
  restoreComponent,
  restoreComponentError,
  restoreComponentSuccess,
} from './component.actions';
import {
  restoreReference,
  restoreReferenceError,
  restoreReferenceSuccess,
} from './reference.actions';
import { ExtractPayload, reducer } from '@ardoq/rxbeach';
import { ArdoqId } from '@ardoq/api-types';
import { DeletedComponent, DeletedReference, Restorable } from './types';

type RestoreDeletedState = {
  components: DeletedComponent[];
  references: DeletedReference[];
  isShowingLoadingIndicator: boolean;
  hasFetched: boolean;
  hasFetchingError: boolean;
  fetchRetry: () => void;
};

const handleLoadingEntitiesStart = (currentState: RestoreDeletedState) => ({
  ...currentState,
  isShowingLoadingIndicator: true,
});

const handleGetDeletedEntitiesError = (
  currentState: RestoreDeletedState,
  { retryAction }: ExtractPayload<typeof getDeletedEntitiesByWorkspaceIdError>
) => ({
  ...currentState,
  isShowingLoadingIndicator: false,
  hasFetchingError: true,
  fetchRetry: retryAction,
});

const handleGetDeletedEntitiesSuccess = (
  currentState: RestoreDeletedState,
  {
    components,
    references,
  }: ExtractPayload<typeof getDeletedEntitiesByWorkspaceIdSuccess>
) => ({
  ...currentState,
  components,
  references,
  isShowingLoadingIndicator: false,
  hasFetchingError: false,
  hasFetched: true,
  fetchRetry: () => {},
});

const toRestored = <T>(entity: T) => ({
  ...entity,
  isRestored: true,
  hasError: false,
});

const setError = <T extends Restorable>(entity: T) => ({
  ...entity,
  hasError: true,
});

const clearError = <T extends Restorable>(entity: T) => ({
  ...entity,
  hasError: false,
});
type HasId = { _id: ArdoqId };
const markWithError =
  (targetId: ArdoqId) =>
  <T extends HasId & Restorable>(entity: T) => {
    if (entity._id === targetId) {
      return setError(entity);
    }
    return entity;
  };

const markWithoutError =
  (targetId: ArdoqId) =>
  <T extends HasId & Restorable>(entity: T) => {
    if (entity._id === targetId) {
      return clearError(entity);
    }
    return entity;
  };

const markAncestorsAsRestored =
  (ancestorIds: ArdoqId[]) =>
  <T extends HasId>(component: T) => {
    if (ancestorIds.includes(component._id)) {
      return toRestored(component);
    }
    return component;
  };

const markAsRestored =
  (targetId: ArdoqId) =>
  <T extends HasId>(entity: T) => {
    if (entity._id === targetId) {
      return toRestored(entity);
    }
    return entity;
  };

const handleClearRestoreComponentError = (
  currentState: RestoreDeletedState,
  { component: { _id } }: ExtractPayload<typeof restoreComponent>
) => ({
  ...currentState,
  components: currentState.components.map(markWithoutError(_id)),
});

const handleComponentRestored = (
  currentState: RestoreDeletedState,
  {
    component: { _id, ancestors },
  }: ExtractPayload<typeof restoreComponentSuccess>
) => {
  const x = {
    ...currentState,
    components: currentState.components
      .map(markAsRestored(_id))
      .map(markAncestorsAsRestored(ancestors)),
  };
  return x;
};

const handleComponentRestoreError = (
  currentState: RestoreDeletedState,
  { component: { _id } }: ExtractPayload<typeof restoreComponentError>
) => ({
  ...currentState,
  components: currentState.components.map(markWithError(_id)),
});

const handleClearRestoreReferenceError = (
  currentState: RestoreDeletedState,
  { reference: { _id } }: ExtractPayload<typeof restoreReference>
) => ({
  ...currentState,
  references: currentState.references.map(markWithoutError(_id)),
});

const handleReferenceRestored = (
  currentState: RestoreDeletedState,
  { reference: { _id } }: ExtractPayload<typeof restoreReferenceSuccess>
) => ({
  ...currentState,
  references: currentState.references.map(markAsRestored(_id)),
});

const handleReferenceRestoreError = (
  currentState: RestoreDeletedState,
  { reference: { _id } }: ExtractPayload<typeof restoreReferenceError>
) => ({
  ...currentState,
  references: currentState.references.map(markWithError(_id)),
});

export const reducers = [
  reducer(loadingEntitiesStart, handleLoadingEntitiesStart),
  reducer(getDeletedEntitiesByWorkspaceIdError, handleGetDeletedEntitiesError),
  reducer(
    getDeletedEntitiesByWorkspaceIdSuccess,
    handleGetDeletedEntitiesSuccess
  ),
  reducer(restoreComponentSuccess, handleComponentRestored),
  reducer(restoreComponentError, handleComponentRestoreError),
  reducer(restoreComponent, handleClearRestoreComponentError),
  reducer(restoreReferenceSuccess, handleReferenceRestored),
  reducer(restoreReferenceError, handleReferenceRestoreError),
  reducer(restoreReference, handleClearRestoreReferenceError),
];
