import { InventoryInteractiveTopRowState } from '@ardoq/inventory';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { combineLatestWith, map } from 'rxjs/operators';
import { inventoryModuleWasSelected } from '../../router/navigationActions';
import { updatedInventoryColumns } from '../actions';
import { inventoryGridApi$ } from '../inventoryGridApi$';
import {
  componentSelectionChanged,
  enterCreateComponentsMode,
  exitCreateComponentsMode,
  gotNextRowsBatch,
  filtersChanged,
  componentWasCreated,
} from './actions';
import { getInventoryInteractiveTopRowCommands } from './inventoryInteractiveTopRowCommands';
import { inventoryInteractiveTopRowOperations } from './inventoryInteractiveTopRowOperations';
import { loadedState$ } from 'loadedState/loadedState$';
import { context$ } from 'streams/context/context$';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { selectedDataSetDatasource$ } from '../inventory$';

export const inventoryInteractiveTopRow$ =
  persistentReducedStream<InventoryInteractiveTopRowState>(
    'inventoryInteractiveRow$',
    inventoryInteractiveTopRowOperations.getDefaultInventoryInteractiveState(),
    [
      reducer(
        enterCreateComponentsMode,
        inventoryInteractiveTopRowOperations.enterCreateComponentsModeReducer
      ),
      reducer(
        exitCreateComponentsMode,
        inventoryInteractiveTopRowOperations.exitCreateComponentsModeReducer
      ),
      reducer(
        inventoryModuleWasSelected,
        inventoryInteractiveTopRowOperations.resetState
      ),
      reducer(
        updatedInventoryColumns,
        inventoryInteractiveTopRowOperations.ignoreUpdate
      ), // we get the selected columns count from the grid api, we just need to update the view state
      reducer(
        filtersChanged,
        inventoryInteractiveTopRowOperations.ignoreUpdate
      ), // we get the name filter from the grid api, we just need to update the view state
      reducer(
        componentSelectionChanged,
        inventoryInteractiveTopRowOperations.setSelectedComponents
      ),
      reducer(
        gotNextRowsBatch,
        inventoryInteractiveTopRowOperations.setTotalNumberOfRows
      ),
      reducer(
        componentWasCreated,
        inventoryInteractiveTopRowOperations.incrementTotalNumberOfRows
      ),
    ]
  ).pipe(
    combineLatestWith(
      inventoryGridApi$,
      loadedState$,
      context$,
      subdivisions$,
      currentUserPermissionContext$,
      selectedDataSetDatasource$
    ),
    map(
      ([
        state,
        inventoryGridApi,
        loadedStates,
        context,
        subdivisionsContext,
        permissionContext,
        selectedDatasourceState,
      ]) => {
        return {
          state:
            inventoryInteractiveTopRowOperations.getInventoryInteractiveTopRowViewState(
              state,
              inventoryGridApi,
              loadedStates,
              context,
              subdivisionsContext,
              permissionContext,
              selectedDatasourceState
            ),
          commands: getInventoryInteractiveTopRowCommands(inventoryGridApi),
        };
      }
    )
  );
