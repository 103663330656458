import { DASHBOARD_MAX_WIDTH } from '@ardoq/dashboard';
import { BrandButton, Button, ButtonGroup, ButtonType } from '@ardoq/button';
import { AugmentationsProps } from '../types';
import { Island } from '@ardoq/page-layout';
import { Drawer, showDrawer } from '@ardoq/snowflakes';
import { DescriptionTitle } from '../ExpandableDescriptionAndBusinessOutcomes';
import ValuePropositionModal from './ValuePropositionModal';
import { Badge, StatusType } from '@ardoq/status-ui';
import { Text } from '@ardoq/typography';
import { SparklesIcon } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { FlexBox } from '@ardoq/layout';
import ValueStreamModal from './ValueStreamModal';

const Augmentations = ({ enabledState }: AugmentationsProps) => {
  const openValuePropositionModal = async () => {
    const { closeDrawer } = showDrawer({
      stackPageName: 'demoInfoDrawer',
      renderDrawer: () => (
        <Drawer
          title="Generate Value Proposition"
          drawerSize="small"
          icon={<SparklesIcon color={colors.brand50} />}
          orientation="vertical"
          paddingX="none"
        >
          <ValuePropositionModal closeModal={closeDrawer} />
        </Drawer>
      ),
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: true,
    });
  };

  const openValueStreamModal = async () => {
    const { closeDrawer } = showDrawer({
      stackPageName: 'demoInfoDrawer',
      renderDrawer: () => (
        <Drawer
          title="Generate Value Streams"
          drawerSize="small"
          icon={<SparklesIcon color={colors.brand50} />}
          orientation="vertical"
          paddingX="none"
        >
          <ValueStreamModal closeModal={closeDrawer} />
        </Drawer>
      ),
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: true,
    });
  };
  return (
    <Island
      maxWidth={`${DASHBOARD_MAX_WIDTH}px`}
      isScrollable={false}
      bodySpacing="xlarge"
    >
      <FlexBox gap="small">
        <DescriptionTitle>
          AI-powered Value Propositions and Value Streams
        </DescriptionTitle>
        <Badge statusType={StatusType.NEW} label="New" />
      </FlexBox>
      <Text color={'textSubtle'}>
        Generate AI-powered Value Propositions and Value Streams.
      </Text>
      <ButtonGroup>
        <BrandButton
          isDisabled={!enabledState.valueProposition.enabled}
          data-tooltip-text={enabledState.valueProposition.disabledReason}
          onClick={openValuePropositionModal}
        >
          <SparklesIcon color={colors.white} />
          Generate Value Proposition
        </BrandButton>
        <Button
          isDisabled={!enabledState.valueStream.enabled}
          data-tooltip-text={enabledState.valueStream.disabledReason}
          buttonType={ButtonType.SECONDARY}
          onClick={openValueStreamModal}
        >
          <SparklesIcon color={colors.surfaceActionSecondary} />
          Generate Value Stream
        </Button>
      </ButtonGroup>
    </Island>
  );
};

export default Augmentations;
