import { Component } from 'react';
import Context from 'context';
import Components from 'collections/components';
import OptionsMenu from 'menus/optionsMenu/OptionsMenu';
import ComponentFab from '../fab/componentFab/ComponentFab';
import { ArdoqId } from '@ardoq/api-types';
import {
  Permissions,
  isDisabled,
} from 'streams/currentUserPermissions/permissionInterface';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  createEntities,
  finishWorkflow,
  saveAddedFieldsAndExit,
  saveEditedEntities,
} from 'appModelStateEdit/actions';
import { attributesFromProperties } from 'appModelStateEdit/propertiesEditor/utils';
import { launchDeleteComponentsAndReferencesConfirmationDialogByIds } from 'components/Dialogs/confirmDeletion/confirmDeletion';
import { Features, hasFeature } from '@ardoq/features';
import PropertiesEditor from 'appModelStateEdit/propertiesEditor/PropertiesEditorConnected';
import {
  clearSubscriptions,
  subscribeToAction,
} from 'streams/utils/streamUtils';
import {
  notifyComponentChanged,
  notifyReferenceContextChanged,
  notifyScenarioChanged,
  notifyWorkspaceChanged,
} from 'streams/context/ContextActions';
import { Subscription } from 'rxjs';
import ManageFields from 'appModelStateEdit/manageFields/ManageFields';
import ManageReferenceTypes from 'appModelStateEdit/manageReferenceTypes/ManageReferenceTypes';
import AddFieldPane from 'appModelStateEdit/propertiesEditor/addField/addFieldPane/AddFieldPane';
import { hideRightPane, showRightPane } from 'appContainer/actions';
import {
  GetContentOptions,
  GetContentOptionsType,
} from 'appModelStateEdit/legacyTypes';
import { SidebarMenu } from '@ardoq/sidebar-menu';
import ReferenceFab from '../fab/referenceFab/ReferenceFab';
import { classes, Maybe } from '@ardoq/common-helpers';
import { trackEvent } from '../../../tracking/tracking';
import styled, { css } from 'styled-components';
import { debounce } from 'lodash';
import {
  trackRightPaneClosed,
  trackSaveComponentButtonClicked,
  trackCreateComponentButtonClicked,
  trackDeleteComponentButtonClicked,
} from './tracking';
import { contextInterface } from 'modelInterface/contextInterface';

const saveEditedEntitiesForPropertyGroups = () =>
  dispatchAction(saveEditedEntities());

const createEntitiesForPropertyGroups = () => dispatchAction(createEntities());

const onSaveComponent = () => {
  trackSaveComponentButtonClicked();
  saveEditedEntitiesForPropertyGroups();
};

const onCreateComponent = () => {
  trackCreateComponentButtonClicked();
  createEntitiesForPropertyGroups();
};

const RightPaneContent = styled.div`
  display: flex;
  flex: 1;
  opacity: 0;
  width: 0;
  transition:
    width 0ms ease-in-out 100ms,
    opacity 100ms ease-in-out 100ms;
`;

const RightPaneElement = styled.div<{
  $isViewpointMode: boolean;
}>`
  max-width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex: 1;
  opacity: 1;
  transition: opacity 150ms ease-in-out;

  &.switchingContent {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  &.open {
    ${({ $isViewpointMode }) =>
      !$isViewpointMode &&
      css`
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      `}

    ${RightPaneContent} {
      opacity: 1;
      width: 100%;
    }
  }
`;

const hidePane = () => dispatchAction(hideRightPane());

const getParent = (parentId?: string | null) => {
  if (!parentId) {
    return null;
  }
  return Components.collection.get(parentId);
};

const getContent = (options: GetContentOptions) => {
  switch (options.type) {
    case GetContentOptionsType.MENU:
      return <OptionsMenu />;
    case GetContentOptionsType.WORKSPACE_PROPERTIES:
      return (
        <PropertiesEditor
          onClose={hidePane}
          onSave={saveEditedEntitiesForPropertyGroups}
        />
      );
    case GetContentOptionsType.CREATE_COMPONENT: {
      const parent = getParent(options.parentId);
      if (parent && !parent.canHaveChildren()) {
        return (
          <SidebarMenu
            headerTitle="Create component"
            onCloseClick={() => {
              trackRightPaneClosed();
              hidePane();
            }}
            emptyContentMessage="Can't create child component of selected node"
          />
        );
      }
      return (
        <PropertiesEditor
          onClose={() => {
            trackRightPaneClosed();
            hidePane();
          }}
          onSave={onCreateComponent}
        />
      );
    }
    case GetContentOptionsType.COMPONENT_PROPERTIES: {
      const componentIds = options.componentIds;
      if (!componentIds) {
        return;
      }
      return (
        <PropertiesEditor
          onClose={() => {
            trackRightPaneClosed();
            dispatchAction(saveAddedFieldsAndExit());
          }}
          onSave={onSaveComponent}
          onDelete={async () => {
            trackDeleteComponentButtonClicked();
            const confirmed =
              await launchDeleteComponentsAndReferencesConfirmationDialogByIds({
                componentIds,
              });

            if (confirmed) {
              hidePane();
            }
          }}
        />
      );
    }
    case GetContentOptionsType.COMPONENT_STYLE: {
      return (
        <PropertiesEditor
          onClose={() => {
            trackRightPaneClosed();
            hidePane();
          }}
          onSave={onSaveComponent}
        />
      );
    }
    case GetContentOptionsType.CREATE_REFERENCE: {
      return (
        <PropertiesEditor
          onClose={() => dispatchAction(hideRightPane())}
          onSave={createEntitiesForPropertyGroups}
          trackingContext={GetContentOptionsType.CREATE_REFERENCE}
        />
      );
    }
    case GetContentOptionsType.REFERENCE_PROPERTIES: {
      const referenceIds = options.referenceIds;
      if (!referenceIds) {
        return;
      }
      return (
        <PropertiesEditor
          onClose={() => dispatchAction(saveAddedFieldsAndExit())}
          onSave={saveEditedEntitiesForPropertyGroups}
          onDelete={async () => {
            const confirmed =
              await launchDeleteComponentsAndReferencesConfirmationDialogByIds({
                referenceIds,
              });

            if (confirmed) {
              hidePane();
            }
          }}
        />
      );
    }
    case GetContentOptionsType.CREATE_FIELD: {
      return (
        <PropertiesEditor
          onClose={() => dispatchAction(finishWorkflow())}
          onSave={propertyGroups => {
            const attrs = attributesFromProperties(propertyGroups);
            trackEvent('Created Field In Sidebar Editor', {
              fieldType: attrs.type,
            });
            createEntitiesForPropertyGroups();
          }}
        />
      );
    }
    case GetContentOptionsType.FIELD_EDITOR: {
      return (
        <PropertiesEditor
          onClose={() =>
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.MANAGE_FIELDS,
              })
            )
          }
          onSave={() => {
            trackEvent('Updated Field In Sidebar Editor');
            saveEditedEntitiesForPropertyGroups();
          }}
        />
      );
    }
    case GetContentOptionsType.MANAGE_FIELDS: {
      return <ManageFields />;
    }
    case GetContentOptionsType.ADD_FIELD_TO_WORKSPACE: {
      return <AddFieldPane />;
    }
    case GetContentOptionsType.MANAGE_REFERENCE_TYPES: {
      return <ManageReferenceTypes />;
    }
    case GetContentOptionsType.CREATE_REFERENCE_TYPE: {
      return (
        <PropertiesEditor
          onClose={() =>
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.MANAGE_REFERENCE_TYPES,
              })
            )
          }
          onSave={saveEditedEntitiesForPropertyGroups}
        />
      );
    }
    case GetContentOptionsType.REFERENCE_TYPE_EDITOR: {
      return (
        <PropertiesEditor
          onClose={() =>
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.MANAGE_REFERENCE_TYPES,
              })
            )
          }
          onSave={saveEditedEntitiesForPropertyGroups}
        />
      );
    }
  }
};
interface RightPaneProperties {
  focusDelay?: number;
  isViewpointMode: boolean;
}
interface RightPaneState {
  workspaceId: Maybe<ArdoqId>;
  content?: JSX.Element | null;
  showing?: GetContentOptionsType;
  switchingContent: boolean;
}
export class RightPane extends Component<RightPaneProperties, RightPaneState> {
  private boundOpen?: (options: GetContentOptions) => void;
  private boundClose?: VoidFunction;
  private onContextChange?: () => void;
  private workspaceChanged?: (workspaceId: Maybe<ArdoqId>) => void;
  private contentContainer: HTMLDivElement | null = null;
  private subscriptions: Subscription[] = [];
  constructor(props: RightPaneProperties) {
    super(props);
    this.state = {
      workspaceId: contextInterface.getCurrentWsId(),
      content: undefined,
      showing: undefined,
      switchingContent: false,
    };
  }

  componentDidMount() {
    this.boundOpen = this.open.bind(this);
    this.boundClose = this.close.bind(this);

    this.onContextChange = debounce(() => {
      if (this.state.showing) {
        const editors = [
          GetContentOptionsType.WORKSPACE_PROPERTIES,
          GetContentOptionsType.CREATE_COMPONENT,
          GetContentOptionsType.COMPONENT_PROPERTIES,
          GetContentOptionsType.COMPONENT_STYLE,
          GetContentOptionsType.REFERENCE_PROPERTIES,
          GetContentOptionsType.FIELD_EDITOR,
        ];
        const isShowingEditor = editors.indexOf(this.state.showing) !== -1;
        const disableEditWorkspace = isDisabled(Permissions.WORKSPACE_EDIT);

        const contextComponentId = Context.componentId();
        if (isShowingEditor) {
          const referenceId = Context.referenceId();
          if (referenceId) {
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.REFERENCE_PROPERTIES,
                referenceIds: [referenceId],
              })
            );
          } else if (
            this.state.showing === GetContentOptionsType.CREATE_COMPONENT
          ) {
            dispatchAction(
              showRightPane({
                type: this.state.showing,
                parentId: contextComponentId,
                workspaceId: Context.activeWorkspaceId(),
              })
            );
          } else if (contextComponentId) {
            if (this.state.showing === GetContentOptionsType.COMPONENT_STYLE) {
              dispatchAction(
                showRightPane({
                  type: GetContentOptionsType.COMPONENT_STYLE,
                  componentIds: [contextComponentId],
                })
              );
            } else {
              dispatchAction(
                showRightPane({
                  type: GetContentOptionsType.COMPONENT_PROPERTIES,
                  componentIds: [contextComponentId],
                })
              );
            }
          } else if (
            this.state.showing.match('-properties') &&
            !disableEditWorkspace
          ) {
            if (this.state.workspaceId) {
              dispatchAction(
                showRightPane({
                  type: GetContentOptionsType.WORKSPACE_PROPERTIES,
                  workspaceId: this.state.workspaceId,
                })
              );
            }
          } else {
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.MENU,
              })
            );
          }
        } else if (
          [
            GetContentOptionsType.MANAGE_FIELDS,
            GetContentOptionsType.ADD_FIELD_TO_WORKSPACE,
          ].includes(this.state.showing)
        ) {
          if (contextComponentId || Context.referenceId()) {
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.MENU,
              })
            );
          } else {
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.MANAGE_FIELDS,
              })
            );
          }
        } else if (
          [
            GetContentOptionsType.MANAGE_REFERENCE_TYPES,
            GetContentOptionsType.REFERENCE_TYPE_EDITOR,
          ].includes(this.state.showing)
        ) {
          if (contextComponentId || Context.referenceId()) {
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.MENU,
              })
            );
          } else {
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.MANAGE_REFERENCE_TYPES,
              })
            );
          }
        } else if (this.state.showing === GetContentOptionsType.MENU) {
          dispatchAction(
            showRightPane({
              type: GetContentOptionsType.MENU,
            })
          );
        }
      }
    }, 100);

    this.workspaceChanged = workspaceId => {
      if (workspaceId) {
        this.setState({ workspaceId });
      } else {
        dispatchAction(hideRightPane());
      }
    };

    this.subscriptions.push(
      subscribeToAction(showRightPane, this.boundOpen),
      subscribeToAction(hideRightPane, this.boundClose),
      subscribeToAction(notifyWorkspaceChanged, this.onContextChange),
      subscribeToAction(notifyReferenceContextChanged, this.onContextChange),
      subscribeToAction(notifyComponentChanged, this.onContextChange),
      subscribeToAction(notifyScenarioChanged, this.onContextChange),
      subscribeToAction(notifyWorkspaceChanged, ({ workspaceId }) => {
        this.workspaceChanged?.(workspaceId);
      })
    );
  }

  open(options: GetContentOptions) {
    const content = this.state.workspaceId && getContent(options);

    if (content) {
      const doSwitch = () => {
        this.setState(() => {
          return {
            showing: options.type,
            switchingContent: false,
            content,
          };
        });
      };

      if (this.state.showing) {
        this.setState({ switchingContent: true });
      }
      window.setTimeout(doSwitch, 150);
    } else {
      dispatchAction(hideRightPane());
    }
  }

  close() {
    this.setState({
      showing: undefined,
      content: undefined,
      switchingContent: false,
    });
  }

  render() {
    const hasReferenceFab = hasFeature(Features.REFERENCE_FAB_PROTOTYPE);
    return (
      <RightPaneElement
        $isViewpointMode={this.props.isViewpointMode}
        className={classes(
          'right-pane',
          this.state.content && 'open',
          this.state.switchingContent && 'switchingContent'
        )}
      >
        {hasReferenceFab && <ReferenceFab />}
        {this.props.isViewpointMode ? null : <ComponentFab />}
        <RightPaneContent
          className="right-pane-content"
          ref={contentContainer => {
            this.contentContainer = contentContainer;
          }}
        >
          {this.state.content}
        </RightPaneContent>
      </RightPaneElement>
    );
  }

  componentWillUnmount() {
    this.subscriptions = clearSubscriptions(this.subscriptions);
  }
}
