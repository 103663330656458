import styled from 'styled-components';
import QuickPerspectives from './QuickPerspectives';
import QuickPerspectivesContainerElement from './QuickPerspectivesContainerElement';
import { getExpandedSetting } from './quickPerspectivesUtils';
import { SectionSettingKey } from './types';

import { colors } from '@ardoq/design-tokens';

const Container = styled.div`
  border-bottom: 1px solid ${colors.grey35};
  position: relative;
  min-height: 40px;
  height: 100%;
`;
interface QuickPerspectivesOptions {
  toggleShow?: (args: { expanded: boolean }) => void;
}
const QuickPerspectivesContainer = ({
  toggleShow,
}: QuickPerspectivesOptions) => (
  <Container>
    <QuickPerspectivesContainerElement
      style={{
        flex: getExpandedSetting(SectionSettingKey.CONTAINER) ? 1 : 0,
      }}
    >
      <QuickPerspectives toggleShow={toggleShow}></QuickPerspectives>
    </QuickPerspectivesContainerElement>
  </Container>
);

export default QuickPerspectivesContainer;
