import { IconName } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  restoreChangeComponent,
  RestoreChangePayload,
  restoreChangeReference,
  setAuditLogFilter,
  setChangeTypeFilterSpecifics,
} from './actions';
import type { AuditLogChangeType, AuditLogRowData } from '@ardoq/audit-log';
import {
  actionTypeIconNameMap,
  entityTypeFilterIconMap,
  entityTypeNameMap,
  areEmptyChanges,
  isAuditLogEntityType,
  toAuditLogActionLabel,
} from '@ardoq/audit-log';
import {
  APIError,
  DateRange,
  AuditLog$State,
  AuditLogRequest,
  FilterSectionProps,
  FilterState,
  FetchAuditLogDataPayload,
} from './types';
import { FilterSections, sectionIconNamesMap } from './consts';
import {
  APIComponentAttributes,
  APIEntityType,
  APIReferenceAttributes,
  ArdoqId,
  PageState,
  APIAuditLogRowData,
  AuditLogEntityType,
  APIAuditLogChangeType,
} from '@ardoq/api-types';
import {
  currentDate,
  formatDateTime,
  parseDate,
  startOfToday,
  subDays,
} from '@ardoq/date-time';
import { isEmpty, omit } from 'lodash';
import { componentInterface } from '@ardoq/component-interface';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { Features, hasFeature } from '@ardoq/features';
import { referenceInterface } from '@ardoq/reference-interface';
import { FilterBarValue } from '@ardoq/snowflakes';
import { getCurrentLocale } from '@ardoq/locale';

const getDateRangeChipLabel = (dateRange: DateRange) => {
  const locale = getCurrentLocale();
  const { since, before } = dateRange;

  return `${formatDateTime(since, locale)}${
    before ? ` - ${formatDateTime(before, locale)}` : ''
  }`;
};

// TODO [audit log improvements] pass filter state instead of props
export const filtersToSearchAndFilterBarActiveFilters = (
  filterProps: FilterSectionProps
) => [
  ...filterProps[FilterSections.WORKSPACES].selected.map(
    ({ label, value }) => ({
      value,
      label,
      type: FilterSections.WORKSPACES,
      iconName: sectionIconNamesMap[FilterSections.WORKSPACES],
    })
  ),
  ...filterProps[FilterSections.USERS].selected.map(userId => ({
    value: userId,
    label:
      filterProps[FilterSections.USERS].options.find(
        ({ value }) => value === userId
      )?.label ?? 'missing user name',
    type: FilterSections.USERS,
    iconName: IconName.PERSON,
  })),
  ...filterProps[FilterSections.ACTIONS].selected.map(action => ({
    value: action,
    label: toAuditLogActionLabel(action),
    type: FilterSections.ACTIONS,
    iconName: actionTypeIconNameMap[action],
  })),
  ...(filterProps[FilterSections.DATE_RANGE].selected.since
    ? [
        {
          value: filterProps[FilterSections.DATE_RANGE].selected.since,
          label: getDateRangeChipLabel(
            filterProps[FilterSections.DATE_RANGE].selected
          ),
          type: FilterSections.DATE_RANGE,
          iconName: sectionIconNamesMap[FilterSections.DATE_RANGE],
        },
      ]
    : []),
  ...Object.entries(filterProps[FilterSections.CHANGE_TYPE].selected).flatMap(
    ([entityType, selectedValues]) =>
      selectedValues === null
        ? []
        : [
            ...(isEmpty(selectedValues)
              ? [
                  {
                    label: entityTypeNameMap[entityType as AuditLogEntityType],
                    value: entityType,
                  },
                ]
              : selectedValues),
          ].map(({ label, value }) => ({
            value,
            label,
            type: entityType as AuditLogEntityType,
            iconName: entityTypeFilterIconMap[entityType as AuditLogEntityType],
          }))
  ),
];

export const getRemoveFilter =
  ({ workspaces, users, actions, entityTypes }: FilterState) =>
  (value: FilterBarValue, type: string) => {
    if (type === FilterSections.WORKSPACES) {
      dispatchAction(
        setAuditLogFilter({
          workspaces: workspaces.filter(
            workspaceOption => workspaceOption.value !== value
          ),
        })
      );
    } else if (type === FilterSections.USERS) {
      dispatchAction(
        setAuditLogFilter({
          users: users.filter(userId => userId !== value),
        })
      );
    } else if (type === FilterSections.ACTIONS) {
      dispatchAction(
        setAuditLogFilter({
          actions: actions.filter(action => action !== value),
        })
      );
    } else if (type === FilterSections.DATE_RANGE) {
      dispatchAction(
        setAuditLogFilter({
          dateRange: {
            before: null,
            since: null,
          },
        })
      );
    } else if (isAuditLogEntityType(type)) {
      const newSpecifics = (entityTypes[type] ?? []).filter(
        entity => entity.value !== value
      );
      dispatchAction(
        setChangeTypeFilterSpecifics({
          entityType: type,
          specifics: isEmpty(newSpecifics) ? null : newSpecifics,
        })
      );
    }
  };

const getRestoreEntityChange =
  (
    entityId: ArdoqId,
    entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
    fieldName: keyof APIComponentAttributes | keyof APIReferenceAttributes,
    restorableValue:
      | APIComponentAttributes[keyof APIComponentAttributes]
      | APIReferenceAttributes[keyof APIReferenceAttributes]
  ) =>
  () => {
    if (entityType === APIEntityType.COMPONENT) {
      dispatchAction(
        restoreChangeComponent({
          entityId,
          fieldName,
          restorableValue,
        })
      );
    } else if (entityType === APIEntityType.REFERENCE) {
      dispatchAction(
        restoreChangeReference({
          entityId,
          fieldName,
          restorableValue,
        })
      );
    }
  };

const mapAPIResultChangeToTableRowChange = (
  entityId: ArdoqId,
  entityType: APIEntityType,
  {
    restorableValue,
    notRestorableReason,
    isRestorable,
    ...change
  }: APIAuditLogChangeType
): AuditLogChangeType => ({
  ...change,
  restoreChangeData: {
    restorableValue,
    isRestorable,
    notRestorableReason,
    isRestoring: false,
    hasError: false,
    hasBeenRestored: false,
    restoreChange:
      (entityType === APIEntityType.COMPONENT ||
        entityType === APIEntityType.REFERENCE) &&
      isRestorable
        ? getRestoreEntityChange(
            entityId,
            entityType,
            change.field,
            restorableValue
          )
        : null,
  },
});

export const mapAPIResultDataToTableRowData = (
  row: APIAuditLogRowData
): AuditLogRowData => ({
  ...row,
  changes: row.changes.map(change =>
    mapAPIResultChangeToTableRowChange(row.entityId, row.entityType, change)
  ),
  rowId: `${row.entityId}-${row.entityVersion}-${row.timestamp}`,
  meta: { isSelected: false },
  areEmptyChanges: areEmptyChanges(row.changes),
});

export const hasTablePreviousPage = (pageState?: PageState) =>
  getCurrentPageFromPageState(pageState) > 1;

export const hasAPIError = (apiErrors: APIError[]) => apiErrors.length > 0;

export const getCurrentPageFromPageState = (pageState?: PageState) =>
  pageState?.pageNumber ?? 1;

export const mapPropertiesOnChange = (
  data: AuditLogRowData[],
  { entityId, fieldName, restorableValue }: RestoreChangePayload,
  propertiesToMap:
    | { isRestoring: true }
    | { isRestoring: false; hasBeenRestored: true; hasError: false }
    | { isRestoring: false; hasError: true; errorTraceId?: string }
): AuditLogRowData[] =>
  data.map(row =>
    row.entityId === entityId
      ? {
          ...row,
          changes: row.changes.map(change =>
            change.field === fieldName &&
            change.restoreChangeData.restorableValue === restorableValue
              ? {
                  ...change,
                  restoreChangeData: {
                    ...change.restoreChangeData,
                    ...propertiesToMap,
                  },
                }
              : change
          ),
        }
      : row
  );

const formatFiltersForAPI = (filters: FilterState) => ({
  ...filters,
  workspaces: filters.workspaces.map(option => option.value),
  entityTypes: Object.fromEntries(
    Object.entries(filters.entityTypes).map(([key, specifics]) => [
      key,
      specifics?.map(({ value }) => value),
    ])
  ),
});

const getSinceDate = (dateRange: DateRange) => {
  if (dateRange.since) {
    return parseDate(dateRange.since);
  }
  return hasFeature(Features.FULL_AUDIT_LOG_HISTORY)
    ? undefined
    : subDays(startOfToday(), 30);
};

export const formatStateForAPIRequest = (
  state: AuditLog$State
): AuditLogRequest => {
  const stateWithoutData = omit(state, 'data');

  return {
    ...stateWithoutData,
    before: stateWithoutData.filters.dateRange.before
      ? parseDate(stateWithoutData.filters.dateRange.before)
      : currentDate(),
    since: getSinceDate(stateWithoutData.filters.dateRange),
    filters: formatFiltersForAPI(state.filters),
  };
};

export const mergePayloadAndStateForAPIRequest = (
  payload: FetchAuditLogDataPayload,
  state: AuditLog$State
): AuditLogRequest => {
  const filters = {
    ...state.filters,
    ...(payload && 'filters' in payload ? payload.filters : {}),
  };
  const stateWithoutData = omit(state, 'data');
  return {
    pageNumber: 1, // resets page number for anything else then fetch next/fetch previous
    size: stateWithoutData.paginationState.pageSize,
    ...payload,
    before: stateWithoutData.filters.dateRange.before
      ? parseDate(stateWithoutData.filters.dateRange.before)
      : currentDate(),
    since: getSinceDate(stateWithoutData.filters.dateRange),
    filters: formatFiltersForAPI(filters),
  };
};

const typeCollectionMap: Record<string, (id: string) => string | null> = {
  [APIEntityType.COMPONENT]: componentInterface.getDisplayName,
  [APIEntityType.WORKSPACE]: workspaceInterface.getWorkspaceName,
  [APIEntityType.REFERENCE]: (id: string) =>
    `${componentInterface.getDisplayName(
      referenceInterface.getSourceComponentId(id)!
    )} → ${componentInterface.getDisplayName(
      referenceInterface.getTargetComponentId(id)!
    )}`,
};

export const getNameFromIdAndCollection = (
  entityId: ArdoqId,
  entityType: AuditLogEntityType
) => typeCollectionMap[entityType](entityId);
