import { Icon, IconName, IconSize } from '@ardoq/icons';
import { ReactElement } from 'react';
import { colors } from '@ardoq/design-tokens';
import { ButtonSize, IconButton } from '@ardoq/button';
import {
  RowLabel,
  RowContainer,
  RowLeftContentContainer,
  TagsContainer,
} from './atoms';

type AppliedSettingRowProps = {
  label: string;
  icon: IconName;
  onEditClick?: () => void;
  tags?: ReactElement[];
  isDisabled?: boolean;
};
export const AppliedSettingsRow = ({
  label,
  icon,
  onEditClick,
  tags,
  isDisabled,
}: AppliedSettingRowProps) => (
  <RowContainer>
    <RowLeftContentContainer $isDisabled={isDisabled}>
      <Icon
        iconName={icon}
        iconSize={IconSize.MEDIUM}
        style={{ color: colors.grey60 }}
      />
      <RowLabel>{label}</RowLabel>
      <TagsContainer>{tags}</TagsContainer>
    </RowLeftContentContainer>

    {onEditClick ? (
      <IconButton
        iconName={IconName.EDIT}
        buttonSize={ButtonSize.SMALL}
        onClick={onEditClick}
      />
    ) : null}
  </RowContainer>
);
