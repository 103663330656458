import {
  ExtractPayload,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import {
  UpdateLinkedWorkspacesPayload,
  updateLinkedWorkspaces,
} from 'models/actions';
import {
  APIReferenceAttributes,
  APIWorkspaceAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import { workspacesClosed } from 'sync/actions';

const updateLinkedWorkspacesFromReferences = (
  currentLinkedWorkspaces: LinkedWorkspaces,
  references: APIReferenceAttributes[]
) => {
  const linkedWorkspaces = new Map(
    Array.from(currentLinkedWorkspaces).map(([wsId, linkedWsIds]) => [
      wsId,
      new Set(linkedWsIds),
    ])
  );
  references.forEach(
    ({
      rootWorkspace: rootWorkspaceId,
      targetWorkspace: targetWorkspaceId,
    }: APIReferenceAttributes) => {
      if (
        rootWorkspaceId &&
        targetWorkspaceId &&
        rootWorkspaceId !== targetWorkspaceId
      ) {
        populateMap(linkedWorkspaces, rootWorkspaceId, targetWorkspaceId);
      }
    }
  );

  return linkedWorkspaces;
};

// Temporary till we have the new presentation aggregated endpoint.
const resetWithLinkedWorkspaces = (
  workspaces: APIWorkspaceAttributes[],
  references: APIReferenceAttributes[]
) => {
  const linkedWorkspaces = new Map();
  workspaces.forEach(({ _id }) => {
    const linkedWorkspaceIds = references
      .filter(({ rootWorkspace, targetWorkspace }) => {
        if (rootWorkspace === targetWorkspace) return false;
        return rootWorkspace === _id || targetWorkspace === _id;
      })
      .map(({ rootWorkspace, targetWorkspace }) => {
        return rootWorkspace === _id ? targetWorkspace : rootWorkspace;
      });

    new Set(linkedWorkspaceIds).forEach(linkedId =>
      populateMap(linkedWorkspaces, _id, linkedId)
    );
  });

  return linkedWorkspaces;
};

const populateMap = (
  linkedWorkspaces: LinkedWorkspaces,
  sourceWorkspaceId: ArdoqId,
  targetWorkspaceId: ArdoqId
) => {
  let linkedTargetWorkspaces = linkedWorkspaces.get(sourceWorkspaceId);
  if (!linkedTargetWorkspaces) {
    linkedTargetWorkspaces = new Set();
    linkedWorkspaces.set(sourceWorkspaceId, linkedTargetWorkspaces);
  }
  linkedTargetWorkspaces.add(targetWorkspaceId);

  let linkedSourceWorkspaces = linkedWorkspaces.get(targetWorkspaceId);
  if (!linkedSourceWorkspaces) {
    linkedSourceWorkspaces = new Set();
    linkedWorkspaces.set(targetWorkspaceId, linkedSourceWorkspaces);
  }
  linkedSourceWorkspaces.add(sourceWorkspaceId);
};

type LinkedWorkspaces = Map<ArdoqId, Set<ArdoqId>>;

const handleUpdateLinkedWorkspaces = (
  linkedWorkspaces: LinkedWorkspaces,
  { references, workspaces }: UpdateLinkedWorkspacesPayload
) =>
  workspaces
    ? resetWithLinkedWorkspaces(workspaces, references)
    : updateLinkedWorkspacesFromReferences(linkedWorkspaces, references);

const handleWorkspacesClosed = (
  linkedWorkspaces: LinkedWorkspaces,
  { openWorkspaceIds }: ExtractPayload<typeof workspacesClosed>
) => {
  if (openWorkspaceIds.length === 0) {
    return new Map();
  }

  // When we close workspaces we keep only the references which have either
  // the source or target (or both) workspace in the set of open workspaces.
  // Accordingly we can keep any open workspace and for any closed workspace
  // all linked workspaces which are still open.
  const openIds = new Set(openWorkspaceIds);
  return new Map(
    Array.from(linkedWorkspaces)
      .map<[string, Set<string>]>(([wsId, linkedIds]) => {
        if (openIds.has(wsId)) {
          return [wsId, linkedIds];
        }

        const stillLinkedWorkspaces = Array.from(linkedIds).filter(linkedId =>
          openIds.has(linkedId)
        );
        return [wsId, new Set(stillLinkedWorkspaces)];
      })
      .filter(([, linkedIds]) => linkedIds.size > 0)
  );
};

const defaultState: LinkedWorkspaces = new Map<ArdoqId, Set<ArdoqId>>();

const reducers = [
  reducer(updateLinkedWorkspaces, handleUpdateLinkedWorkspaces),
  reducer(workspacesClosed, handleWorkspacesClosed),
];

export const linkedWorkspaces$ = persistentReducedStream<LinkedWorkspaces>(
  'linkedWorkspaces$',
  defaultState,
  reducers
);

// Returns the connected workspaces, including the input set, one step in and
// out matching the pattern how we load workspaces.
export const getConnectedWorkspaceIds = (workspaceIds: ArdoqId[]) =>
  new Set(
    workspaceIds.flatMap(id => [
      id,
      ...Array.from(linkedWorkspaces$.state.get(id) ?? []),
    ])
  );

// Returns the connected workspaces, excluding the input set, one step in and
// out matching the pattern how we load workspaces.
export const getOnlyConnectedWorkspaceIds = (workspaceIds: ArdoqId[]) => {
  const allConnectedWorkspaces = getConnectedWorkspaceIds(workspaceIds);
  workspaceIds.forEach(id => allConnectedWorkspaces.delete(id));
  return allConnectedWorkspaces;
};

// Gets the connected workspaces with a DFS, excluding the input workspace.
export const getConnectedWorkspaceIdsDeep = (
  workspaceId: ArdoqId
): ArdoqId[] => {
  const visited = workspaceDepthFirstSearch(workspaceId);
  visited.delete(workspaceId);
  return Array.from(visited);
};

const workspaceDepthFirstSearch = (
  workspaceId: ArdoqId,
  visited = new Set<ArdoqId>()
): Set<ArdoqId> => {
  if (!visited.has(workspaceId)) {
    visited.add(workspaceId);
    Array.from(linkedWorkspaces$.state.get(workspaceId) ?? []).forEach(id =>
      workspaceDepthFirstSearch(id, visited)
    );
  }
  return visited;
};
