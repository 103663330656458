import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { dispatchAction } from '@ardoq/rxbeach';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import {
  applyConfig,
  selectRequiredFields,
  setImportProfilesPhotos,
  unselectTables,
} from '../../streams/selectionState/actions';
import { selectionState$ } from '../../streams/selectionState/selectionState$';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { setTransferConfigId } from 'integrations/common/streams/activeIntegrations/actions';
import {
  getIsUserAgreeToOverrideMapping,
  isMicrosoftEntraIdConfig,
} from 'integrations/common/streams/transferConfigs/utils';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import {
  clearTabularMapping,
  applyRequiredTableMapping,
} from 'integrations/common/streams/tabularMappings/actions';
import { isValidSelectionState } from 'integrations/microsoftEntraId/streams/selectionState/utils';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { loadSelection } from 'integrations/common/streams/selectionState/actions';

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      integrationTermsDictionary:
        getIntegrationTermsDictionaryStream(integrationId),
      selectionState: selectionState$,
      transferConfigs: getTransferConfigsStream(integrationId),
      selectedTransferConfigId: getActiveIntegrationStream(integrationId).pipe(
        map(ci => ci.selectedTransferConfigId),
        distinctUntilChanged()
      ),
      tabularMapping: getTabularMappingStream(integrationId),
    });
  }),
  map(
    ({
      integrationId,
      integrationTermsDictionary: { selectConfigHelperText },
      selectionState,
      transferConfigs,
      selectedTransferConfigId,
      tabularMapping,
    }) => {
      return {
        transferConfigs: transferConfigs.configs,
        selectedTransferConfigId,
        selectConfigHelperText,
        selectConfigPopoverContent: null,
        importProfilesPhotos: selectionState.importProfilesPhotos,
        isValidSelectionState: isValidSelectionState(selectionState),
        errorMessage: selectionState.fetchErrorMessage,
        onFetchData: () => {
          dispatchAction(loadSelection(integrationId));
        },
        onChangeImportProfilesPhotos: (importProfilesPhotos: boolean) => {
          dispatchAction(setImportProfilesPhotos(importProfilesPhotos));
          dispatchAction(selectRequiredFields());
          dispatchAction(applyRequiredTableMapping({ integrationId }));
        },
        onTransferConfigSelect: async (
          configId: SavedTransferConfig['_id'] | null
        ) => {
          if (selectedTransferConfigId === configId) {
            return;
          }

          const transferConfig = transferConfigs.configs.find(
            m => m._id === configId
          );
          if (!transferConfig || !isMicrosoftEntraIdConfig(transferConfig)) {
            return;
          }

          const isUserAgreeToOverrideMapping =
            await getIsUserAgreeToOverrideMapping(tabularMapping);

          if (!isUserAgreeToOverrideMapping) {
            return;
          }

          dispatchAction(clearTabularMapping({ integrationId }));
          dispatchAction(setTransferConfigId({ integrationId, id: configId }));
          if (!transferConfig.sourceConfig) {
            return;
          }
          dispatchAction(
            applyConfig({
              importPictures: Boolean(transferConfig.options.importPictures),
              sourceConfig: transferConfig.sourceConfig,
            })
          );
        },
        onClearConfiguration: () => {
          dispatchAction(unselectTables());
          dispatchAction(
            clearTabularMapping({ integrationId: 'microsoft-entra-id' })
          );
          dispatchAction(
            setTransferConfigId({
              integrationId: 'microsoft-entra-id',
              id: null,
            })
          );
          dispatchAction(setImportProfilesPhotos(false));
        },
      };
    }
  )
);
