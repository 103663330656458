import {
  createParameterizedGraphFilter,
  openDynamicSearchModal as openDynamicSearchModalAction,
} from './actions';
import { filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { createNewQuery, executeGremlinSearch } from 'search/helper';
import {
  dispatchAction,
  collectRoutines,
  routine,
  extractPayload,
  ofType,
  withNamespace,
} from '@ardoq/rxbeach';
import {
  createSearchQuery,
  deleteQuery,
  deleteQuerySuccess,
  loadStoredQuery,
  updateQueryParams,
} from 'search/actions';
import {
  GremlinSearchSource,
  trackQueriedGremlinSearch,
} from 'search/tracking';
import {
  queryGremlinSearchError,
  queryGremlinSearchSuccess,
  queryGremlinSearchWarning,
} from 'search/Gremlin/actions';
import { getIn } from 'utils/collectionUtil';
import {
  QueryEditorNamespace,
  dynamicFilterQueryEditor$,
} from 'search/QueryEditor/queryEditor$';
import {
  GraphSearchResultObject,
  GremlinSearchQuery,
  SearchBackend,
  SearchType,
} from '@ardoq/api-types';
import { graphSearchApi, handleError } from '@ardoq/api';
import { CloseStackPage } from './CloseStackPage';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';

const EMPTY_PARAMETER_RESULTS_WARNING =
  'The parameter search gave no results. Try to modify the query, or get in touch with us on intercom for assistance.';
const NOT_SUPPORTED_RESULTS_WARNING =
  'Search results for edges or paths is not supported';

const handleCreateParameterizedGraphFilter = routine(
  ofType(createParameterizedGraphFilter),
  tap(() =>
    dispatchAction(createSearchQuery(), QueryEditorNamespace.PARAMETER_QUERY)
  ),
  switchMap(() =>
    createNewQuery({
      backend: SearchBackend.GREMLIN,
      type: SearchType.PARAMETER_QUERY,
      name: '',
      query: '',
      supportedParams: [],
      parameterQueryId: null,
    })
  ),
  handleError(),
  tap(() => {
    dispatchAction(
      createSearchQuery(),
      QueryEditorNamespace.DYANMIC_FILTER_QUERY
    );
  }),
  switchMap(response =>
    createNewQuery({
      backend: SearchBackend.GREMLIN,
      type: SearchType.DYNAMIC_FILTER_QUERY,
      name: '',
      query: 'g.V(selectedId)',
      supportedParams: ['selectedId'],
      parameterQueryId: response._id,
    })
  ),
  handleError()
);

const executeParameterizedGraphFilter = routine(
  withNamespace(QueryEditorNamespace.DYANMIC_FILTER_QUERY),
  ofType(loadStoredQuery),
  extractPayload(),
  filter(
    ({ storedQuery: { parameterQueryId, query } }) =>
      Boolean(parameterQueryId) && Boolean(query)
  ),
  switchMap(({ storedQuery: { parameterQueryId } }) =>
    graphSearchApi.parameterizedGraphSearch<GraphSearchResultObject>(
      parameterQueryId!
    )
  ),
  tap(response => {
    trackQueriedGremlinSearch({
      searchSource: GremlinSearchSource.PARAMETER_SEARCH_TEST,
    });

    if (isArdoqError(response)) {
      dispatchAction(
        queryGremlinSearchError({
          syntaxError: getArdoqErrorMessage(response),
        }),
        SearchType.PARAMETER_QUERY
      );
      return;
    }
    const emptyResult = !getIn(response, ['result', 0]);
    if (emptyResult) {
      dispatchAction(
        queryGremlinSearchWarning({
          searchWarning: EMPTY_PARAMETER_RESULTS_WARNING,
        }),
        SearchType.PARAMETER_QUERY
      );
      return;
    }
    dispatchAction(
      queryGremlinSearchSuccess({
        results: response.result,
        totalResults: response.count,
      }),
      SearchType.PARAMETER_QUERY
    );
    return response;
  })
);

const validateParameterSearchTriggerDynamicSearchIfResults = routine(
  withNamespace(SearchType.PARAMETER_QUERY),
  ofType(queryGremlinSearchSuccess),
  extractPayload(),
  tap(({ results, totalResults }) => {
    if (totalResults === 0) {
      dispatchAction(
        queryGremlinSearchWarning({
          searchWarning: EMPTY_PARAMETER_RESULTS_WARNING,
        }),
        SearchType.PARAMETER_QUERY
      );
    } else if (
      getIn(results, [0, 'labels', 'length']) || // path
      getIn<string>(results, [0, 'type']) === 'edge' // edges
    ) {
      dispatchAction(
        queryGremlinSearchWarning({
          searchWarning: NOT_SUPPORTED_RESULTS_WARNING,
        }),
        SearchType.PARAMETER_QUERY
      );
    } else {
      const selectedId = getIn(results, [0, 'id']);
      dispatchAction(
        updateQueryParams({
          queryParams: { selectedId },
        }),
        QueryEditorNamespace.DYANMIC_FILTER_QUERY
      );
    }
  })
);

const deleteParameterQueryWhenDynamicFieldDeleted = routine(
  withNamespace(QueryEditorNamespace.DYANMIC_FILTER_QUERY),
  ofType(deleteQuerySuccess),
  extractPayload(),
  filter(({ parameterQueryId }) => Boolean(parameterQueryId)),
  tap(({ parameterQueryId }) => {
    dispatchAction(
      deleteQuery({
        queryId: parameterQueryId!,
      }),
      QueryEditorNamespace.PARAMETER_QUERY
    );
  })
);

const triggerParameterizedGraphSearchWhenQueryParamsUpdated = routine(
  withNamespace(QueryEditorNamespace.DYANMIC_FILTER_QUERY),
  ofType(updateQueryParams),
  extractPayload(),
  withLatestFrom(dynamicFilterQueryEditor$),
  tap(
    ([
      {
        queryParams: { selectedId },
      },
      {
        model: { query },
      },
    ]) => {
      executeGremlinSearch(
        query as GremlinSearchQuery,
        SearchType.DYNAMIC_FILTER_QUERY,
        GremlinSearchSource.STORED_GREMLIN_QUERY,
        { selectedId }
      );
    }
  )
);

const openDynamicSearchModal = routine(
  ofType(openDynamicSearchModalAction),
  tap(CloseStackPage)
);

export const dynamicSearchRoutines = collectRoutines(
  handleCreateParameterizedGraphFilter,
  executeParameterizedGraphFilter,
  validateParameterSearchTriggerDynamicSearchIfResults,
  deleteParameterQueryWhenDynamicFieldDeleted,
  triggerParameterizedGraphSearchWhenQueryParamsUpdated,
  openDynamicSearchModal
);
