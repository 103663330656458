import styled from 'styled-components';
import { s8 } from '@ardoq/design-tokens';
import { TargetedSurveyAudiencePopoverContent } from 'broadcasts/broadcastBuilder/manageLandingPageForm/PopoverContent';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Text = styled.span`
  margin-right: ${s8};
`;

const InfoIcon = styled(Icon).attrs({
  iconName: IconName.INFO,
  iconSize: IconSize.SMALL,
})`
  cursor: help;
  margin-left: 4px;
`;

const LandingPageHeader = () => (
  <Container>
    <Text>Landing Page</Text>{' '}
    <WithPopover content={TargetedSurveyAudiencePopoverContent}>
      <InfoIcon />
    </WithPopover>
  </Container>
);

export default LandingPageHeader;
