import { actionCreator } from '@ardoq/rxbeach';
import { ViewIds } from '@ardoq/api-types';
import { ArdoqError } from '@ardoq/common-helpers';

export type AddBookmarkPayload = {
  viewId: ViewIds;
  bookmarkName: string;
  folderId: string | null;
};

export const addBookmark = actionCreator<AddBookmarkPayload>(
  '[bookmarks] ADD_BOOKMARK'
);

export const loadBookmark = actionCreator<string>('[bookmarks] LOAD_BOOKMARK');

export const setBookmarkName = actionCreator<string>(
  '[bookmarks] SET_BOOKMARK_NAME'
);

export const setBookmarkFolder = actionCreator<string | null>(
  '[bookmarks] SET_BOOKMARK_FOLDER'
);

export const setSaveBookmarkError = actionCreator<ArdoqError | null>(
  '[bookmarks] SET_SAVE_BOOKMARK_ERROR'
);
