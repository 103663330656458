import type { Restorable } from './types';
export const isRestored = (entity: Restorable) => Boolean(entity.isRestored);

export const getButtonText = (isRestored: boolean) => {
  if (isRestored) return 'Restored';
  return 'Restore';
};

export const anyRestored = (
  components: Restorable[],
  references: Restorable[]
): boolean => {
  return Boolean(
    components.filter(isRestored).length || references.filter(isRestored).length
  );
};
