import { api, ApiResponse, externalDocumentApi, scenarioApi } from '@ardoq/api';
import { ArdoqId } from '@ardoq/api-types';
import { isArdoqError, Maybe } from '@ardoq/common-helpers';
import { showToast, ToastType } from '@ardoq/status-ui';

const branchedDeleteExternalDocument = async (
  externalDocumentId: ArdoqId,
  scenarioId: Maybe<ArdoqId>
): ApiResponse<void> => {
  if (scenarioId) {
    const response = await scenarioApi.deleteExternalDocument(
      scenarioId,
      externalDocumentId
    );
    if (isArdoqError(response)) {
      if (api.isNotFound(response)) {
        showToast(
          'Document is not created in a scenario. Please, exit scenario mode to delete it.',
          ToastType.INFO
        );
      } else return response;
    } else {
      showToast('Document has been successfully unlinked.', ToastType.SUCCESS);
    }
  } else {
    const response = await externalDocumentApi.delete(externalDocumentId);
    if (isArdoqError(response)) {
      return response;
    }
    showToast('Document has been successfully unlinked.', ToastType.SUCCESS);
  }
};

export { branchedDeleteExternalDocument };
