import { TabularMapping } from 'integrations/common/streams/tabularMappings/types';
import { TransferState } from 'integrations/common/streams/transferState/types';
import {
  isDryRunOutOfDate,
  isSuccessTransfer,
} from 'integrations/common/streams/transferState/utils';
import {
  IntegrationDataLoading,
  DataLoadingSuccessState,
} from 'integrations/unified/streams/dataLoading/types';
import { isDataLoadingSuccessState } from 'integrations/unified/streams/dataLoading/utils';
import { IntegrationResources } from 'integrations/unified/streams/resources/types';
import fp from 'lodash/fp';

export const isConfigureStepAvailable = ({
  dataLoading,
  selectedResources,
}: {
  selectedResources: IntegrationResources['selectedResources'];
  dataLoading: IntegrationDataLoading;
}) => {
  if (isDataLoadingSuccessState(dataLoading)) {
    return areFetchedTablesInSync({
      selectedResources,
      dataLoading,
    });
  }
  return false;
};

export const isReviewStepAvailable = ({
  transferState,
  tabularMapping,
  dataLoading,
  selectedResources,
}: {
  tabularMapping: TabularMapping;
  transferState: TransferState;
  selectedResources: IntegrationResources['selectedResources'];
  dataLoading: IntegrationDataLoading;
}) => {
  return (
    isConfigureStepAvailable({ selectedResources, dataLoading }) &&
    isSuccessTransfer(transferState) &&
    !isDryRunOutOfDate(transferState, tabularMapping)
  );
};

export const isTransferStepAvailable = ({
  transferState,
  tabularMapping,
  dataLoading,
  selectedResources,
}: {
  tabularMapping: TabularMapping;
  transferState: TransferState;
  selectedResources: IntegrationResources['selectedResources'];
  dataLoading: IntegrationDataLoading;
}) => {
  return (
    isReviewStepAvailable({
      transferState,
      tabularMapping,
      dataLoading,
      selectedResources,
    }) &&
    isSuccessTransfer(transferState) &&
    !transferState.dryRun
  );
};

const areFetchedTablesInSync = ({
  dataLoading,
  selectedResources,
}: {
  dataLoading: DataLoadingSuccessState;
  selectedResources: IntegrationResources['selectedResources'];
}): boolean => {
  const fetchedTables = dataLoading.response.tables;
  const nonEmptySelectedResources = fp.pickBy(
    resource => !fp.isEmpty(resource.fields),
    selectedResources
  );
  const selectedResourceIds = Object.keys(nonEmptySelectedResources).sort();
  const fetchedTableIds = fetchedTables.map(t => t.id).sort();
  if (!fp.isEqual(selectedResourceIds, fetchedTableIds)) {
    return false;
  }
  return fp.every(([resourceId, { fields }]) => {
    const selectedFieldIds = Object.keys(fields).sort();
    const fetchedTable = fetchedTables.find(t => t.id === resourceId);
    const resourceConfig = dataLoading.payload.resources.find(
      r => r.id === resourceId
    );
    if (!fetchedTable || !resourceConfig) {
      return false;
    }
    const fetchedFieldIds = resourceConfig.fields.map(c => c.id).sort();
    return fp.isEqual(selectedFieldIds, fetchedFieldIds);
  }, fp.toPairs(nonEmptySelectedResources));
};
