import { Checkbox } from '@ardoq/forms';
import { chooseScheduleEndDate } from 'broadcasts/actions';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import ScheduleDate from 'broadcasts/broadcastBuilder/broadcastSchedulerAndReminderForm/ScheduleDate';
import isEndDateEnabled$ from 'broadcasts/broadcastBuilder/broadcastSchedulerAndReminderForm/endDate/isEndDateEnabled$';
import { IsEndDateEnabledStreamShape } from 'broadcasts/broadcastBuilder/broadcastSchedulerAndReminderForm/endDate/types';
import { toggleEndDateEnabled } from 'broadcasts/broadcastBuilder/broadcastSchedulerAndReminderForm/endDate/actions';
import {
  formatDateOnly,
  isDateBeforeDay,
  isDateSameDay,
} from '@ardoq/date-time';
import { Stack } from '@ardoq/layout';
import { getCurrentLocale } from '@ardoq/locale';

type ScheduleEndDateProps = IsEndDateEnabledStreamShape & {
  readonly endDate: string | null;
  readonly minDate: string;
};

const getScheduleDateWarnings = (endDate: string | null, minDate: string) => {
  const locale = getCurrentLocale();

  if (endDate === null) {
    return 'Please provide an end date.';
  }
  if (
    isDateBeforeDay(endDate, minDate, locale) ||
    isDateSameDay(endDate, minDate, locale)
  ) {
    return `Choose an end date after ${formatDateOnly(minDate, locale)}.`;
  }
};

const ScheduleEndDate = ({
  endDate,
  minDate,
  isEndDateEnabled,
}: ScheduleEndDateProps) => {
  return (
    <Stack gap="small">
      <Checkbox
        label="End date"
        isChecked={isEndDateEnabled}
        onChange={() => dispatchAction(toggleEndDateEnabled())}
      >
        Schedule an end date
      </Checkbox>
      {isEndDateEnabled && (
        <ScheduleDate
          date={endDate}
          minDate={minDate}
          onChange={newDate =>
            dispatchAction(
              chooseScheduleEndDate(newDate?.toISOString() ?? null)
            )
          }
          warningMessage={getScheduleDateWarnings(endDate, minDate)}
        />
      )}
    </Stack>
  );
};

export default connect(ScheduleEndDate, isEndDateEnabled$);
