import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { UploadInfo, UploadState } from './types';
import { saveResponse, setUploadInfo } from './actions';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { UploadResponse } from '@ardoq/api-types/integrations';

const defaultState: UploadState = {
  uploadResponse: null,
  uploadInfo: {
    status: 'Initial',
    title: 'Drop your files here, or pick a file.',
    message: 'We support .xls, .xlsx, .xlsm, and .csv formats',
    uploadButtonText: 'Upload a file',
  },
};

const saveResponseReducer = (
  currentState: UploadState,
  payload: UploadResponse
) => ({
  ...currentState,
  uploadResponse: payload,
});
const handleSaveUploadResponse = reducer<UploadState, UploadResponse>(
  saveResponse,
  saveResponseReducer
);

const setUploadInfoReducer = (
  currentState: UploadState,
  payload: UploadInfo
) => ({
  ...currentState,
  uploadInfo: {
    // Some times we want to keep the texts for previous state buttons
    // Here we don't update those when provided with the payload
    // If payload doesn't have a new texts for buttons we keep the old ones.
    ...currentState.uploadInfo,
    ...payload,
  },
});
const handleSetUploadInfo = reducer<UploadState, UploadInfo>(
  setUploadInfo,
  setUploadInfoReducer
);

const resetIntegrationReducer = () => defaultState;
const handleResetIntegration = reducer<UploadState, IntegrationId>(
  resetIntegration,
  resetIntegrationReducer
);

const reducers = [
  handleSaveUploadResponse,
  handleSetUploadInfo,
  handleResetIntegration,
];

export const uploadedFile$ = persistentReducedStream(
  `upload$`,
  defaultState,
  reducers
);
