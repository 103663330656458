import { Connection } from '@ardoq/api-types/integrations';
import { byLocaleField } from 'integrations/common/utils/common';
import { VpcRecord, VpcsOptions } from 'integrations/aws/streams/vpcs/types';

export function getVpcsOptions(
  connectionsVpcs: Record<string, Record<string, VpcRecord>>,
  connections: Connection[]
) {
  const options: VpcsOptions[] = [];
  for (const connection in connectionsVpcs) {
    const selectedConnection = connections.find(con => con._id === connection);
    for (const region in connectionsVpcs[connection]) {
      if (connectionsVpcs[connection][region].vpcs.length) {
        const vpcsOptions = connectionsVpcs[connection][region].vpcs
          .map(vpc => ({ label: vpc.vpcName, value: vpc.vpcId }))
          .sort(byLocaleField('label'));

        options.push({
          label: `${selectedConnection?.name} ${region}`,
          options: vpcsOptions,
        });
      }
    }
  }

  return options.sort(byLocaleField('label'));
}
