import { ReportBuilder, ReportBuilderProps } from '@ardoq/report-builder';
import { connect } from '@ardoq/rxbeach';
import reportBuilderViewModel$ from './reportBuilderViewModel$';
import { ReportBuilderLoader } from '@ardoq/snowflakes';
import { ErrorPage, ReportError } from '@ardoq/report-reader';
import { openHome } from 'components/AppMainSidebar/utils';
import {
  ExitEditingButton,
  ReportBuilderNavBarCommands,
  ReportBuilderNavBarProps,
  SaveReportButton,
  ToolbarButtons,
} from './NavBarButtons';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import Navbar from 'views/navbar/Navbar';

export type ReportBuilderContainerProps = {
  navBarProps: ReportBuilderNavBarProps;
  navBarCommands: ReportBuilderNavBarCommands;
} & (
  | { reportError: ReportError }
  | { isReportBuilderLoading: true }
  | ({
      isReportBuilderLoading: false;
    } & ReportBuilderProps)
);

const ReportBuilderContainer = ({
  navBarProps,
  navBarCommands,
  ...props
}: ReportBuilderContainerProps) => (
  <PageWrapper>
    <Navbar
      primaryContent={navBarProps.reportName}
      secondaryContent="Report builder"
      primaryButton={
        <SaveReportButton {...navBarProps.saveReportButtonProps} />
      }
      secondaryButton={
        <ExitEditingButton {...navBarProps.exitEditingButtonProps} />
      }
      toolbarContent={<ToolbarButtons {...navBarProps.toolbarButtonProps} />}
    />

    <PageBody skipPadding>
      {'reportError' in props ? (
        <ErrorPage
          error={props.reportError}
          openHome={openHome}
          viewReport={navBarCommands.viewReport}
        />
      ) : props.isReportBuilderLoading ? (
        <ReportBuilderLoader />
      ) : (
        <ReportBuilder {...props} />
      )}
    </PageBody>
  </PageWrapper>
);

export default connect(ReportBuilderContainer, reportBuilderViewModel$);
