import {
  APIFieldAttributes,
  ResourceType,
  ComponentBatchDeleteResponse,
  WebSocketDeletePayload,
  WebSocketRecalculationDonePayload,
  WebSocketRecalculationErrorPayload,
  WebSocketRecalculationStartedPayload,
  WebSocketResetAttributePayload,
  WebSocketUnsetFieldValuesPayload,
  WebSocketUpdateReferenceCountsPayload,
  NotificationApiResponse,
  ArdoqId,
  APIComponentAttributes,
  VersionedEntity,
  MetaData,
} from '@ardoq/api-types';

export enum WSClosingReason {
  SESSION_EXPIRED = 'session-expired',
  LOGOUT = 'logout',
}
export enum EventType {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  UNSET = 'unset',
  RESET_ATTRIBUTE = 'reset-attribute',
  COMPONENT_REFERENCE_COUNT = 'component-reference-count',
  BULK_DELETE = 'bulk-delete',
  UPDATE_SIBLINGS = 'update-siblings',
  RECALCULATION_STARTED = 'recalculation-started',
  RECALCULATION_DONE = 'recalculation-done',
  RECALCULATION_ERROR = 'recalculation-error',
  INFERRED = 'inferred',
  ERROR = 'error',
  SUGGEST = 'suggest',
  NOTIFICATION = 'notification',
  SUBDIVISION_MEMBERSHIP_UPDATE = 'subdivision-membership-update',
  MESSAGE_CONTENT = 'message-content',
  MESSAGE_DONE = 'message-done',
  MESSAGE_ERROR = 'message-error',
  ASYNC_REQUEST_PROGRESS = 'async-request-progress',
  BUNDLE_COPY = 'bundle-copy',
  WARNING = 'warning',
}

type PingEvent = 'Ping!';

export type TokenEvent = {
  token: string;
};

type ExpiredEvent = 'Expired!';

type SessionExpiredEvent = {
  topic: 'session-expired';
  data: {
    ['session-id']: string;
  };
};

type LoggedOutEvent = {
  topic: 'logout';
};

export type ArdoqEvent<Data = unknown> = {
  token: string;
  ['resource-type']: ResourceType;
  ['event-type']: EventType;
  data: Data;
  branchId?: string;
  org?: { label: string; _id: string };
  user?: { name: string; id: string };
};

export type OrgEvent<Data = unknown> = ArdoqEvent<Data> & {
  transactionEvent?: true;
  topic: string;
  user: string | null;
};

export type UserEvent<Data = unknown> = ArdoqEvent<Data> & {
  topic: string;
  user: string | null;
};

export type WorkspaceEvent<Data = unknown> = ArdoqEvent<Data> & {
  topic: string;
  user?: { name: string; id: string };
};

type BatchEvent<Data = unknown> = ArdoqEvent<Data> & {
  topic: 'batch';
  user: { name: string; id: string };
};

export type UnknownEvent =
  | PingEvent
  | TokenEvent
  | ExpiredEvent
  | SessionExpiredEvent
  | LoggedOutEvent
  | OrgEvent
  | UserEvent
  | WorkspaceEvent
  | BatchEvent;

export type ClientRequest<Data> = { clientRequestId?: string } & Data;

export type MessageEvent = {
  message: string;
};

export type SubdivisionMembershipAssignmentEvent =
  WorkspaceEvent<SubdivisionMembershipAssignmentEventData> & {
    ['resource-type']: ResourceType.COMPONENT;
    ['event-type']: EventType.SUBDIVISION_MEMBERSHIP_UPDATE;
  };

export type SubdivisionMembershipAssignmentEventData = {
  grantPermissionToResource: APIComponentAttributes[];
  updatePermissionToResource: APIComponentAttributes[];
  revokePermissionToResource: ArdoqId[];
};

export const isClientRequestErrorEvent = (
  event: ArdoqEvent<unknown>
): event is UserEvent<ClientRequest<MessageEvent>> =>
  event['event-type'] === EventType.ERROR;

export const isWebSocketCreate = (event: ArdoqEvent<unknown>): boolean =>
  event['event-type'] === EventType.CREATE;

export const isWebSocketUpdate = (event: ArdoqEvent<unknown>): boolean =>
  event['event-type'] === EventType.UPDATE;

export const isWebSocketInferred = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<unknown> => event['event-type'] === EventType.INFERRED;

export const isWebSocketWarning = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<unknown> => event['event-type'] === EventType.WARNING;

export const isWebSocketSuggest = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<unknown> => event['event-type'] === EventType.SUGGEST;

export const isWebSocketDelete = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<WebSocketDeletePayload> =>
  event['event-type'] === EventType.DELETE;

export const isWebSocketBulkDelete = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<ComponentBatchDeleteResponse> =>
  event['event-type'] === EventType.BULK_DELETE;

export const isWebSocketResetAttribute = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<WebSocketResetAttributePayload> =>
  event['event-type'] === EventType.RESET_ATTRIBUTE;

export const isWebSocketUnset = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<WebSocketUnsetFieldValuesPayload> =>
  event['event-type'] === EventType.UNSET;

export const isWebSocketComponentReferenceCount = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<WebSocketUpdateReferenceCountsPayload> =>
  event['event-type'] === EventType.COMPONENT_REFERENCE_COUNT;

export const isWebSocketUpdateSiblings = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<APIFieldAttributes[]> =>
  event['event-type'] === EventType.UPDATE_SIBLINGS;

export const isWebSocketRecalculationStarted = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<WebSocketRecalculationStartedPayload> =>
  event['event-type'] === EventType.RECALCULATION_STARTED;

export const isWebSocketRecalculationDone = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<WebSocketRecalculationDonePayload> =>
  event['event-type'] === EventType.RECALCULATION_DONE;

export const isWebSocketRecalculationError = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<WebSocketRecalculationErrorPayload> =>
  event['event-type'] === EventType.RECALCULATION_ERROR;

export const isWebSocketNotification = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<NotificationApiResponse> => {
  return event['event-type'] === EventType.NOTIFICATION;
};

export const isWebSocketSubdivisionMembershipAssignment = (
  event: ArdoqEvent<unknown>
): event is SubdivisionMembershipAssignmentEvent =>
  event['event-type'] === EventType.SUBDIVISION_MEMBERSHIP_UPDATE;

export const isResourceCreate = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<VersionedEntity & MetaData> =>
  event['event-type'] === EventType.CREATE;

export const isResourceUpdate = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<VersionedEntity & MetaData> =>
  event['event-type'] === EventType.UPDATE;

export const isResourceDelete = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<VersionedEntity> =>
  event['event-type'] === EventType.DELETE;

export const isBundleCopy = (event: ArdoqEvent<unknown>): boolean =>
  event['event-type'] === EventType.BUNDLE_COPY;
