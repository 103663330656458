import { ComponentTypeHierarchy } from '@ardoq/api-types';
import { APIComponentType } from '@ardoq/api-types';

export function getModelHierarchy(
  isRoot: boolean,
  rootComponentNames: string[],
  componentNames: string[],
  componentTypes: APIComponentType[],
  duplicates: string[] = []
): ComponentTypeHierarchy {
  const hierarchy: ComponentTypeHierarchy = {};
  componentNames.forEach(componentName => {
    if (!rootComponentNames.includes(componentName)) return;
    const componentData = componentTypes.find(
      componentType => componentType.name === componentName
    );
    if (componentData) {
      if (!isRoot && rootComponentNames.includes(componentName)) {
        duplicates.push(componentData.id);
      }
      const childrenNames = Object.values(componentData.children).map(
        child => child.name
      );
      const children = getModelHierarchy(
        false,
        rootComponentNames,
        childrenNames,
        componentTypes,
        duplicates
      );
      hierarchy[componentData.id] = {
        ...componentData,
        children,
      };
    }
  });
  if (isRoot) {
    duplicates.forEach(duplicate => {
      delete hierarchy[duplicate];
    });
  }
  return hierarchy;
}
