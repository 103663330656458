import { reducedStream, reducer } from '@ardoq/rxbeach';
import { featureSettingsOperations } from './featureSettingsOperations';
import { FeatureSettingsState } from './types';
import {
  updateFeatureValue,
  updateFeatureValueFailed,
  updateFeatureValueSuccess,
} from './actions';

const defaultState: FeatureSettingsState = {
  isUpdating: false,
};

const featureSettings$ = reducedStream<FeatureSettingsState>(
  'featureSettings',
  defaultState,
  [
    reducer(updateFeatureValue, featureSettingsOperations.setIsUpdating),
    reducer(
      updateFeatureValueSuccess,
      featureSettingsOperations.resetIsUpdating
    ),
    reducer(
      updateFeatureValueFailed,
      featureSettingsOperations.resetIsUpdating
    ),
  ]
);

export default featureSettings$;
