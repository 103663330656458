import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const editComponent = actionCreator<ArdoqId>(
  '[component] COMPONENT_OPTIONS_EDIT_COMPONENT'
);

export const editComponentStyle = actionCreator<ArdoqId>(
  '[component] COMPONENT_OPTIONS_EDIT_COMPONENT_STYLE'
);

export const selectComponent = actionCreator<ArdoqId>(
  '[component] COMPONENT_OPTIONS_SELECT_COMPONENT'
);
