import { colors } from '@ardoq/design-tokens';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { zonesOperations } from '../operations';

export const ZoneComponentTypesFieldsCounter = ({
  enabledFields,
  totalFields,
}: {
  enabledFields: number;
  totalFields: number;
}) => (
  <FlexBox gap="xsmall">
    {enabledFields === 0 ? (
      <Icon
        iconName={IconName.CLOSE}
        color={colors.grey50}
        iconSize={IconSize.MEDIUM}
      />
    ) : (
      <Icon
        iconName={IconName.CHECK_CIRCLE}
        color={colors.green50}
        iconSize={IconSize.MEDIUM}
      />
    )}
    {zonesOperations.getFieldsCounterText(enabledFields, totalFields)}
  </FlexBox>
);
