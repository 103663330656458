/**
 * Wrapper component for the main sidebar. Shows the old sidebar/navigator if not in viewpoint(/phoenix) mode.
 * Currently, viewpointMode is behind feature flag SUPPORT_LARGE_DATASETS, only beta testers
 * will see the ViewpointSidebar.
 */

import { connect } from '@ardoq/rxbeach';
import ViewpointSidebar from './ViewpointSidebar/ViewpointSidebar';
import MainAppModuleSidebarOld from './MainAppModuleSidebarOld';
import { LoadedStateCommands } from './commands';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';
import { NavigatorTopBarStreamShape } from './NavigatorTopBar/types';
import sideBar$ from './sideBar$';
import { ViewpointNavigatorListItem } from 'viewpointNavigator/types';
import { ViewpointNavigatorCommands } from 'viewpointNavigator/viewpointNavigatorCommands';

type SideBarProps = {
  isViewpointMode: LoadedGraphWithViewpointMode['isViewpointMode'];
  navigatorTopBar: NavigatorTopBarStreamShape;
  configPanelItems: ViewpointNavigatorListItem[];
  activeTab: string;
  commands: LoadedStateCommands & ViewpointNavigatorCommands;
};

const MainAppModuleSidebar = ({
  isViewpointMode,
  navigatorTopBar,
  configPanelItems,
  activeTab,
  commands,
}: SideBarProps) => {
  return isViewpointMode ? (
    <ViewpointSidebar
      navigatorTopBarProps={navigatorTopBar}
      commands={commands}
      configPanelItems={configPanelItems}
      activeTab={activeTab}
    />
  ) : (
    <MainAppModuleSidebarOld navigatorTopBarProps={navigatorTopBar} />
  );
};

export default connect(MainAppModuleSidebar, sideBar$);
