import React, { type ReactNode, useState } from 'react';

type TriggerProps = { isExpanded: boolean };
type CollapsibleProps = {
  children: ReactNode;
  Trigger: React.ElementType<TriggerProps>;
  isExpanded?: boolean;
  onToggleExpand?: (isExpanded: boolean) => void;
};
const Collapsible = ({
  children,
  Trigger,
  isExpanded = false,
  onToggleExpand,
}: CollapsibleProps) => {
  const [expandedState, setExpandedState] = useState(isExpanded);

  const toggleExpanded = () => {
    const newIsExpanded = !expandedState;
    setExpandedState(newIsExpanded);
    onToggleExpand?.(newIsExpanded);
  };

  return (
    <>
      <span onClick={toggleExpanded}>
        <Trigger isExpanded={expandedState} />
      </span>
      {expandedState && children}
    </>
  );
};

export default Collapsible;
