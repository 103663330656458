import { ArdoqId } from '@ardoq/api-types';
import { radius } from '@ardoq/design-tokens';
import { AqLayout, NavItem } from '@ardoq/layout';
import { confirm } from '@ardoq/modal';
import { Island } from '@ardoq/page-layout';
import BundlesContentLayout from 'admin/bundles/BundlesContentLayout';
import { useState } from 'react';
import CreateEntityGroup from './EntityGroupEditor/EntityGroupEditor';
import EntityGroupOverview from './EntityGroupOverview/EntityGroupOverview';

enum Tabs {
  MANAGE = 'manage',
  CREATE = 'create',
  EDIT = 'edit',
}

const getTitle = (selectedTab: Tabs): string => {
  switch (selectedTab) {
    case Tabs.MANAGE:
      return 'Manage existing entity groups';
    case Tabs.CREATE:
      return 'Create new entity group';
    case Tabs.EDIT:
      return 'Edit entity group';
  }
};

const confirmedDiscardingChanges = () => {
  return confirm({
    title: `Confirmation required`,
    text: `Are you sure you want to leave? You have some unsaved changes.`,
  });
};

const EntityGroupManagement = () => {
  const [selectedTab, setSelectedTab] = useState(Tabs.MANAGE);
  const [editingEntityGroupId, setEditingEntityGroupId] = useState<
    ArdoqId | undefined
  >();
  const [
    isCreatingFromExistingEntityGroup,
    setIsCreatingFromExistingEntityGroup,
  ] = useState<boolean>(false);
  const [existsEntityGroupWithChanges, setExistsEntityGroupWithChanges] =
    useState(false);

  const manageEntityGroups = async () => {
    if (selectedTab === Tabs.MANAGE) return;
    if (existsEntityGroupWithChanges) {
      if (!(await confirmedDiscardingChanges())) return;
    }
    setSelectedTab(Tabs.MANAGE);
    setExistsEntityGroupWithChanges(false);
    setIsCreatingFromExistingEntityGroup(false);
  };

  const createEntityGroup = async () => {
    if (selectedTab === Tabs.CREATE) return;
    if (existsEntityGroupWithChanges) {
      if (!(await confirmedDiscardingChanges())) return;
    }
    setSelectedTab(Tabs.CREATE);
    setEditingEntityGroupId(undefined);
  };

  const editEntityGroup = (entityGroupId: ArdoqId) => {
    setSelectedTab(Tabs.EDIT);
    setEditingEntityGroupId(entityGroupId);
  };

  const createFromExistingEntityGroup = (entityGroupId: ArdoqId) => {
    setSelectedTab(Tabs.CREATE);
    setEditingEntityGroupId(entityGroupId);
    setIsCreatingFromExistingEntityGroup(true);
  };

  const handleSubmit = () => {
    setSelectedTab(Tabs.MANAGE);
    setExistsEntityGroupWithChanges(false);
    setIsCreatingFromExistingEntityGroup(false);
  };

  return (
    <Island
      title="Entity groups"
      subtitle="Organize, create, and manage entity groups here."
      bodySpacing="none"
    >
      <AqLayout
        layoutWrapperStyle={{
          overflow: 'hidden',
          borderBottomLeftRadius: radius.medium,
          borderBottomRightRadius: radius.medium,
        }}
        renderLeftSidebar={() => (
          <>
            <NavItem
              $active={selectedTab === Tabs.MANAGE}
              onClick={manageEntityGroups}
            >
              Manage Entity Groups
            </NavItem>
            <NavItem
              $active={selectedTab === Tabs.CREATE}
              onClick={createEntityGroup}
            >
              Create Entity Group
            </NavItem>
            <NavItem
              disabled={selectedTab !== Tabs.EDIT}
              $active={selectedTab === Tabs.EDIT}
            >
              Edit Entity Group
            </NavItem>
          </>
        )}
      >
        <BundlesContentLayout title={getTitle(selectedTab)}>
          {(() => {
            switch (selectedTab) {
              case Tabs.MANAGE:
                return (
                  <EntityGroupOverview
                    editEntityGroup={editEntityGroup}
                    createEntityGroup={createEntityGroup}
                    createFromExistingEntityGroup={
                      createFromExistingEntityGroup
                    }
                  />
                );
              case Tabs.CREATE:
              case Tabs.EDIT:
                return (
                  <CreateEntityGroup
                    createEntityGroupHasChanges={existsEntityGroupWithChanges}
                    setChanges={setExistsEntityGroupWithChanges}
                    navigateToEntityGroupOverview={handleSubmit}
                    editingEntityGroupId={editingEntityGroupId}
                    isCreatingFromExistingEntityGroup={
                      isCreatingFromExistingEntityGroup
                    }
                  />
                );
              default:
                return null;
            }
          })()}
        </BundlesContentLayout>
      </AqLayout>
    </Island>
  );
};

export default EntityGroupManagement;
