import { map } from 'rxjs/operators';
import dynamicFilters$ from 'streams/dynamicFilters/dynamicFilters$';
import dynamicFilterStates$ from 'streams/dynamicFilters/dynamicFilterStates$';
import { defaultFilterState } from 'streams/dynamicFilters/dynamicFilterStates$';
import parameterOptions$ from 'streams/queries/parameterOptions/parameterOptions$';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  requestActivateDynamicFilter,
  setDynamicFilterInactive,
  setDynamicFilterParameters,
} from 'streams/dynamicFilters/actions';
import { fetchParameterOptions } from 'streams/queries/parameterOptions/actions';
import { combineLatest } from 'rxjs';
import {
  QuickPerspectiveType,
  trackToggledQuickPerspective,
} from 'quickPerspectives/tracking';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';

export const viewModel$ = combineLatest([
  dynamicFilters$,
  dynamicFilterStates$,
  parameterOptions$,
  activeScenario$,
]).pipe(
  map(
    ([
      dynamicFilters,
      dynamicFiltersStates,
      parameterOptions,
      { isScenarioMode },
    ]) => ({
      shouldDisplayGraphFilters: !isScenarioMode,
      dynamicFilters: dynamicFilters.map(filter => {
        const filterState = dynamicFiltersStates.find(
          filterState => filterState.id === filter._id
        ) || { ...defaultFilterState, id: filter._id };

        const options =
          parameterOptions
            .filter(
              options => options.parameterQueryId === filter.parameterQueryId
            )
            .map(options => options.parameterOptions)[0] || [];

        return {
          ...filter,
          filterState,
          options,
        };
      }),
    })
  )
);

export const toggleDynamicFilterActive = (
  filterId: string,
  previouslyChecked: boolean
) => {
  if (previouslyChecked) {
    dispatchAction(setDynamicFilterInactive({ id: filterId }));
  } else {
    dispatchAction(requestActivateDynamicFilter({ id: filterId }));
  }
  trackToggledQuickPerspective({
    type: QuickPerspectiveType.DYNAMIC_FILTER,
    isEnabling: !previouslyChecked,
  });
};

export const selectDynamicFilterOption = (
  filterId: string,
  paramName: string,
  paramValue: string
) => {
  dispatchAction(
    setDynamicFilterParameters({
      id: filterId,
      params: { [paramName]: paramValue },
    })
  );
};

export const fetchDynamicFilterOptions = (parameterQueryId: string) => {
  dispatchAction(fetchParameterOptions({ parameterQueryId }));
};
