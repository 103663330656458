import { derivedStream } from '@ardoq/rxbeach';
import { map, debounceTime } from 'rxjs/operators';
import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import { PropertiesEditorStreamShape } from 'appModelStateEdit/propertiesEditor/types';
import {
  canAddField,
  canDelete,
  canShowTags,
  getAddFieldAdminMessage,
  getHeaderTitle,
  getLockedMessage,
  getMultiEditingMessage,
  getPrimaryButtonLabel,
  getPropertyGroups,
  getSidebarInstanceId,
  getEditableEntityPropertiesToCompare,
  getTags,
  isAnyEntityLocked,
  isEntityCalculatedField,
  isEntityCalculatedFieldWithoutQuery,
  isEntityDateRangeField,
  getSaveButtonDisabledState,
} from 'appModelStateEdit/propertiesEditor/utils';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { APIEntityType } from '@ardoq/api-types';
import { DEFAULT_DEBOUNCE_TIME } from 'modelInterface/consts';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { ScenarioModeState } from 'scope/types';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { logError } from '@ardoq/logging';
import subdivisionCreationContext$ from 'subdivisions/subdivisionCreationContext/subdivisionCreationContext$';
import { SubdivisionCreationContextState } from 'subdivisions/subdivisionCreationContext/types';
import { IntendedRefTypes, intendedRefTypes$ } from './intendedRefTypes$';

/**
 * Create viewModel$ for the properties editor.
 * NOTE: This is not pure due to all the utils that are used
 */
export const toPropertiesEditorViewModel = (data: {
  appModelStateEdit: AppModelStateEditStreamShape;
  permissionContext: PermissionContext;
  subdivisionsContext: SubdivisionsContext;
  activeScenarioState: ScenarioModeState;
  subdivisionCreationContext: SubdivisionCreationContextState;
  intendedRefTypes?: IntendedRefTypes;
}): PropertiesEditorStreamShape => {
  const {
    appModelStateEdit,
    permissionContext,
    subdivisionsContext,
    activeScenarioState,
    subdivisionCreationContext,
  } = data;
  const {
    newEntityAttributes,
    entityIDs,
    entityType,
    model,
    isEditingComponentStyle,
    enhancedScopeData,
    originalEnhancedScopeData,
    addedFields,
    dirtyAttributes,
    errorMessage,
    refTypesSortedByMostUsed,
  } = appModelStateEdit;
  if (
    !entityType ||
    !entityIDs?.length ||
    !enhancedScopeData ||
    !originalEnhancedScopeData
  ) {
    return getEmptyPropertiesEditorViewModel(errorMessage);
  }
  const sidebarInstanceId = getSidebarInstanceId(
    entityType,
    entityIDs,
    enhancedScopeData,
    model
  );
  const isCreatingNewEntity = Boolean(newEntityAttributes);

  const propertyGroups = getPropertyGroups(
    entityType,
    entityIDs,
    activeScenarioState,
    enhancedScopeData,
    addedFields,
    dirtyAttributes,
    isCreatingNewEntity,
    subdivisionsContext,
    permissionContext,
    subdivisionCreationContext,
    model,
    isEditingComponentStyle,
    refTypesSortedByMostUsed,
    data.intendedRefTypes
  );
  const tags = getTags(
    entityType,
    entityIDs,
    enhancedScopeData,
    permissionContext,
    subdivisionsContext,
    activeScenarioState,
    isCreatingNewEntity
  );
  const headerTitle = getHeaderTitle(
    entityType,
    isCreatingNewEntity,
    isEditingComponentStyle
  );
  const isCalculatedField = isEntityCalculatedField(
    entityType,
    entityIDs[0],
    enhancedScopeData
  );
  const isCalculatedFieldWithoutQuery = isEntityCalculatedFieldWithoutQuery(
    entityType,
    entityIDs[0],
    enhancedScopeData
  );
  const isDateRangeField = isEntityDateRangeField(
    entityType,
    entityIDs[0],
    enhancedScopeData
  );
  const buttonLabel = getPrimaryButtonLabel(
    isCreatingNewEntity,
    isCalculatedField,
    isCalculatedFieldWithoutQuery
  );
  const isLocked = isAnyEntityLocked(entityType, entityIDs, enhancedScopeData);
  const lockedMessage = getLockedMessage(isLocked);
  const multiEditingMessage = getMultiEditingMessage(
    entityType,
    entityIDs,
    enhancedScopeData,
    isEditingComponentStyle
  );
  const shouldShowTags = canShowTags(entityType, isEditingComponentStyle);
  const shouldShowSaveButton = !isLocked;
  const currentEditableProperties = getEditableEntityPropertiesToCompare(
    enhancedScopeData,
    entityType,
    entityIDs[0]
  );
  const originalProperties = getEditableEntityPropertiesToCompare(
    originalEnhancedScopeData,
    entityType,
    entityIDs[0]
  );
  const isSaveButtonDisabled = getSaveButtonDisabledState(
    propertyGroups,
    isCreatingNewEntity,
    currentEditableProperties,
    originalProperties
  );
  const shouldShowDeleteButton = canDelete(
    entityType,
    isCreatingNewEntity,
    isLocked,
    isEditingComponentStyle
  );
  const shouldShowAddField = canAddField(
    permissionContext,
    activeScenarioState,
    entityType,
    enhancedScopeData,
    isLocked,
    isEditingComponentStyle
  );
  const addFieldAdminMessage = getAddFieldAdminMessage(
    permissionContext,
    activeScenarioState,
    entityType,
    enhancedScopeData,
    isLocked,
    isEditingComponentStyle
  );
  const shouldShowCalculatedFieldSettings =
    isCalculatedField &&
    !isCreatingNewEntity &&
    !isCalculatedFieldWithoutQuery &&
    // Necessary due to hack calculated date range field, there is no calculated date range types
    // See: https://help.ardoq.com/en/articles/43910-calculated-date-ranges
    !isDateRangeField;
  const shouldShowComponentStylePreview = Boolean(isEditingComponentStyle);
  const shouldShowReferenceTypePreview =
    entityType === APIEntityType.REFERENCE_TYPE;
  return {
    propertyGroups,
    tags,
    headerTitle,
    buttonLabel,
    shouldShowTags,
    shouldShowSaveButton,
    isSaveButtonDisabled,
    shouldShowDeleteButton,
    shouldShowAddField,
    addFieldAdminMessage,
    shouldShowCalculatedFieldSettings,
    shouldShowComponentStylePreview,
    shouldShowReferenceTypePreview,
    lockedMessage,
    multiEditingMessage,
    errorMessage,
    sidebarInstanceId,
    originalProperties,
  };
};

const getEmptyPropertiesEditorViewModel = (errorMessage: string | null) => ({
  propertyGroups: [],
  tags: { value: [], options: [] },
  headerTitle: 'Editing',
  buttonLabel: 'Save',
  shouldShowTags: false,
  shouldShowSaveButton: true,
  isSaveButtonDisabled: false,
  shouldShowDeleteButton: true,
  shouldShowAddField: false,
  addFieldAdminMessage: null,
  shouldShowCalculatedFieldSettings: false,
  shouldShowComponentStylePreview: false,
  shouldShowReferenceTypePreview: false,
  lockedMessage: '',
  multiEditingMessage: '',
  errorMessage,
  sidebarInstanceId: null,
  originalProperties: undefined,
});

const propertiesEditor$ = derivedStream(
  'propertiesEditor$',
  appModelStateEdit$,
  currentUserPermissionContext$,
  subdivisions$,
  activeScenario$,
  subdivisionCreationContext$,
  intendedRefTypes$
).pipe(
  debounceTime(DEFAULT_DEBOUNCE_TIME),

  map(
    ([
      appModelStateEdit,
      permissionContext,
      subdivisionsContext,
      activeScenarioState,
      subdivisionCreationContext,
      intendedRefTypes,
    ]) => {
      try {
        return toPropertiesEditorViewModel({
          appModelStateEdit,
          permissionContext,
          subdivisionsContext,
          activeScenarioState,
          subdivisionCreationContext,
          intendedRefTypes,
        });
      } catch (e) {
        logError(
          Error('Failed to create properties editor view model', { cause: e })
        );
        return getEmptyPropertiesEditorViewModel('Failed to edit properties');
      }
    }
  )
);

export default propertiesEditor$;
