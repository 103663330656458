import {
  DataSourceItem,
  MergeStep,
  StructuralConflict,
} from 'components/DiffMergeTable/types';
import HintMessage from 'components/DiffMergeTable/HintMessage';
import { Aligner, Expander } from 'components/DiffMergeTable/atoms';
import { ChevronIcon, Icon, IconName } from '@ardoq/icons';
import styled from 'styled-components';
import * as fonts from 'app/designTokens/fonts';
import {
  collectRelatedReferencesAndComponents,
  getEntitiesAffectedByTagDeletion,
} from 'components/DiffMergeTable/utils';
import { getVerbFromMergeStep } from 'components/DiffMergeSidebarNavigator/utils';
import { Verb } from '@ardoq/api-types';
import { Branch } from 'components/DiffMergeTable/Branch';
import ScenarioMergeIntercomTourClickIds, {
  shouldSetIntercomId,
} from 'components/DiffMergeTable/intercomTourClickIds';
import { colors } from '@ardoq/design-tokens';

const InfoIcon = styled(Icon).attrs({
  iconName: IconName.INFO,
})`
  cursor: default;
  color: ${colors.grey25};
`;

const WarningIcon = styled(Icon).attrs({
  iconName: IconName.WARNING,
})`
  cursor: default;
  color: ${colors.yellow60};
`;

export const AlertIcon = styled(Icon).attrs({
  iconName: IconName.WARNING,
})`
  cursor: default;
  color: ${colors.red60};
`;

const AlignerWithMargin = styled(Aligner)`
  ${fonts.pageViewMediumTemp};
  margin-left: 16px;
`;

const showAlertIcon = ({
  missingComponentConflict,
  hasTypeConflict,
  structuralConflict,
}: DataSourceItem) =>
  missingComponentConflict ||
  hasTypeConflict ||
  structuralConflict === StructuralConflict.RIGID_MODEL_PARENT_TYPE_HAS_CHANGED;

const showInfoIcon = (mergeStep: MergeStep, dataSourceRow: DataSourceItem) => {
  if (
    showAlertIcon(dataSourceRow) ||
    dataSourceRow.structuralConflict === StructuralConflict.MISSING_ANCESTOR
  ) {
    return false;
  }
  if (
    mergeStep === MergeStep.DELETE_COMPONENT_TYPES ||
    mergeStep === MergeStep.DELETE_REFERENCE_TYPES ||
    mergeStep === MergeStep.DELETE_FIELDS
  ) {
    return !dataSourceRow.isInUseOnTarget;
  }
  if (mergeStep === MergeStep.DELETE_COMPONENTS) {
    const { referenceIds, componentIds } =
      collectRelatedReferencesAndComponents(
        dataSourceRow.entityId,
        dataSourceRow.enhancedDiffContextData[Branch.TARGET]
      );
    return !referenceIds.length && !componentIds.length;
  }
  if (mergeStep === MergeStep.DELETE_TAGS) {
    const { referenceIds, componentIds } = getEntitiesAffectedByTagDeletion(
      dataSourceRow.entityId,
      dataSourceRow.enhancedDiffContextData[Branch.TARGET]
    );
    return !referenceIds.length && !componentIds.length;
  }
  return (
    getVerbFromMergeStep(mergeStep) === Verb.CREATE ||
    mergeStep === MergeStep.DELETE_REFERENCES
  );
};

const MergeStepsWithWarningIcon = new Set([
  MergeStep.DELETE_COMPONENTS,
  MergeStep.DELETE_TAGS,
  MergeStep.DELETE_FIELDS,
  MergeStep.DELETE_COMPONENT_TYPES,
  MergeStep.DELETE_REFERENCE_TYPES,
]);

const showWarningIcon = (
  mergeStep: MergeStep,
  { structuralConflict }: DataSourceItem
) =>
  structuralConflict === StructuralConflict.MISSING_ANCESTOR ||
  MergeStepsWithWarningIcon.has(mergeStep);

const RowStatusIcon = ({
  dataSourceRow,
  mergeStep,
}: {
  dataSourceRow: DataSourceItem;
  mergeStep: MergeStep;
}) => {
  const dataClickId = shouldSetIntercomId(dataSourceRow.index)
    ? ScenarioMergeIntercomTourClickIds.INFO_ICON
    : '';

  if (showAlertIcon(dataSourceRow)) {
    return (
      <AlertIcon data-index={dataSourceRow.index} data-click-id={dataClickId} />
    );
  }
  if (showInfoIcon(mergeStep, dataSourceRow)) {
    return (
      <InfoIcon data-index={dataSourceRow.index} data-click-id={dataClickId} />
    );
  }
  if (showWarningIcon(mergeStep, dataSourceRow)) {
    return (
      <WarningIcon
        data-index={dataSourceRow.index}
        data-click-id={dataClickId}
      />
    );
  }
  return null;
};

export const getIconAndHintMessageRenderer =
  ({ mergeStep }: { mergeStep: MergeStep }) =>
  (_: string, dataSourceRow: DataSourceItem) => (
    <AlignerWithMargin>
      <RowStatusIcon dataSourceRow={dataSourceRow} mergeStep={mergeStep} />
      <HintMessage
        shouldShowPropertiesString={showInfoIcon(mergeStep, dataSourceRow)}
        mergeStep={mergeStep}
        dataSourceRow={dataSourceRow}
      />
      <Expander>
        {dataSourceRow.isExpanded ? (
          <ChevronIcon direction="up" />
        ) : (
          <ChevronIcon direction="down" />
        )}
      </Expander>
    </AlignerWithMargin>
  );
