import { map, Observable } from 'rxjs';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { AsyncStatus } from 'integrations/common/types/api';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { CloudProviderIntegrationId } from '../../types';
import {
  changeResourceTypesSelection,
  ChangeResourceTypesSelectionPayload,
  fetchResourceTypesFailure,
  fetchResourceTypesSuccess,
  FetchResourceTypesSuccessPayload,
  updateResourceTypeList,
  UpdateResourceTypeListPayload,
} from './actions';
import { ResourceTypesState } from './types';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';

type AllResourceTypesState = Record<
  CloudProviderIntegrationId,
  ResourceTypesState
>;

const initialState: ResourceTypesState = {
  asyncStatus: 'INIT',
  selectedResourceTypeIds: [],
  resourceTypes: [],
};

const defaultState: AllResourceTypesState = {
  'aws-v3': initialState,
  'azure-v3': initialState,
};

const setAsyncStatus =
  (asyncStatus: AsyncStatus) =>
  (
    state: AllResourceTypesState,
    { integrationId }: { integrationId: CloudProviderIntegrationId }
  ) => {
    return {
      ...state,
      [integrationId]: {
        ...state[integrationId],
        asyncStatus,
      },
    };
  };

const handleFetchResourceTypesFailure = reducer<
  AllResourceTypesState,
  { integrationId: CloudProviderIntegrationId }
>(fetchResourceTypesFailure, setAsyncStatus('FAILURE'));

const fetchResourceTypesSuccessReducer = (
  state: AllResourceTypesState,
  { integrationId, resourceTypes }: FetchResourceTypesSuccessPayload
): AllResourceTypesState => {
  return {
    ...state,
    [integrationId]: {
      ...state[integrationId],
      resourceTypes,
      asyncStatus: 'SUCCESS',
    },
  };
};
const handleFetchResourceTypesSuccess = reducer<
  AllResourceTypesState,
  FetchResourceTypesSuccessPayload
>(fetchResourceTypesSuccess, fetchResourceTypesSuccessReducer);

const updateResourceTypeListReducer = (
  state: AllResourceTypesState,
  { integrationId, resourceTypes }: UpdateResourceTypeListPayload
) => {
  return {
    ...state,
    [integrationId]: {
      ...state[integrationId],
      resourceTypes,
    },
  };
};
const handleListResourceTypes = reducer<
  AllResourceTypesState,
  UpdateResourceTypeListPayload
>(updateResourceTypeList, updateResourceTypeListReducer);

const changeResourceTypesSelectionReducer = (
  state: AllResourceTypesState,
  { integrationId, resourceTypes }: ChangeResourceTypesSelectionPayload
) => ({
  ...state,
  [integrationId]: {
    ...state[integrationId],
    selectedResourceTypeIds: resourceTypes,
  },
});
const handleChangeResourceTypesSelection = reducer<
  AllResourceTypesState,
  ChangeResourceTypesSelectionPayload
>(changeResourceTypesSelection, changeResourceTypesSelectionReducer);

const resetIntegrationReducer = (
  state: AllResourceTypesState,
  integrationId: IntegrationId
) => ({
  ...state,
  [integrationId]: initialState,
});

const handleResetIntegration = reducer<AllResourceTypesState, IntegrationId>(
  resetIntegration,
  resetIntegrationReducer
);

export const resourceTypes$ = persistentReducedStream(
  `integrationCloudProviderResourceTypes$`,
  defaultState,
  [
    handleListResourceTypes,
    handleFetchResourceTypesFailure,
    handleFetchResourceTypesSuccess,
    handleChangeResourceTypesSelection,
    handleResetIntegration,
  ]
);

const cloudProviderToResourceTypeStreams = new Map<
  IntegrationId,
  Observable<ResourceTypesState>
>();

export const getResourceTypesStream = (
  integrationId: CloudProviderIntegrationId
) => {
  const resourceTypesStream =
    cloudProviderToResourceTypeStreams.get(integrationId);

  if (resourceTypesStream) {
    return resourceTypesStream;
  }

  const stream$ = resourceTypes$.pipe(
    map(allResourceTypesState => allResourceTypesState[integrationId])
  );

  cloudProviderToResourceTypeStreams.set(integrationId, stream$);

  return stream$;
};
