import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { setFunnelId } from './actions';
import { v4 as uuidv4 } from 'uuid';
import { FunnelIds } from './types';

const defaultState: FunnelIds = {
  overviewId: uuidv4(),
  importId: uuidv4(),
};

const setFunnelIdReducer = (state: FunnelIds, payload: Partial<FunnelIds>) => {
  return { ...state, ...payload };
};

const handleSetFunnelId = reducer<FunnelIds, Partial<FunnelIds>>(
  setFunnelId,
  setFunnelIdReducer
);

export const funnelIds$ = persistentReducedStream(`funnelId$`, defaultState, [
  handleSetFunnelId,
]);
