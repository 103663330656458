import UserSettings from 'models/UserSettings';

const gridEditorSettings = new UserSettings('gridEditor');
const SETTINGS_KEY = 'hiddenColumns';

export const getHiddenColumns = async () => {
  return gridEditorSettings.get(SETTINGS_KEY);
};
export const setHiddenColumns = async (newHiddenColumns: string[]) => {
  return gridEditorSettings.set(SETTINGS_KEY, newHiddenColumns);
};
