import { action$, reduceState, reducer, streamReducer } from '@ardoq/rxbeach';
import { Observable, map } from 'rxjs';
import { viewpointBuilderFormattingOperations } from './viewpointBuilderFormattingOperations';
import {
  createFormattingRule,
  updateFormattingRule,
  deleteFormattingRule,
  reorderFormattingRules,
  reorderLabelFormatting,
  createLabelFormatting,
  deleteLabelFormatting,
  showReferenceTypeToggled,
  updateLabelFormattingAt,
} from 'perspective/perspectiveEditor/perspectivesEditor$/actions';
import {
  setFormattingOptions,
  setFormattingRules,
  setLabelFormatting,
  resetFormattingState,
} from './viewpointBuilderFormattingActions';
import {
  ViewpointFormattingState,
  ViewpointFormattingProps,
} from './viewpointFormattingTypes';
import { mapToViewpointBuilderFormattingViewState } from './mapToViewpointBuilderFormattingViewState';
import { selectedView$ } from '../selectedView/selectedView$';

export const viewpointFormatting$: Observable<ViewpointFormattingProps> =
  action$.pipe(
    reduceState<ViewpointFormattingState>(
      'viewpointBuilder2.0/viewpointFormatting$',
      viewpointBuilderFormattingOperations.getInitialState(),
      [
        streamReducer(
          selectedView$,
          viewpointBuilderFormattingOperations.setViewName
        ),
        reducer(
          showReferenceTypeToggled,
          viewpointBuilderFormattingOperations.showReferenceTypeToggled
        ),
        reducer(
          updateLabelFormattingAt,
          viewpointBuilderFormattingOperations.updateLabelFormattingAt
        ),
        reducer(
          deleteLabelFormatting,
          viewpointBuilderFormattingOperations.deleteLabelFormatting
        ),
        reducer(
          reorderLabelFormatting,
          viewpointBuilderFormattingOperations.reorderLabelFormatting
        ),
        reducer(
          createLabelFormatting,
          viewpointBuilderFormattingOperations.createLabelFormatting
        ),
        reducer(
          createFormattingRule,
          viewpointBuilderFormattingOperations.createFormattingRule
        ),
        reducer(
          updateFormattingRule,
          viewpointBuilderFormattingOperations.updateFormattingRule
        ),
        reducer(
          deleteFormattingRule,
          viewpointBuilderFormattingOperations.deleteFormattingRule
        ),
        reducer(
          reorderFormattingRules,
          viewpointBuilderFormattingOperations.reorderFormattingRules
        ),
        reducer(
          setFormattingOptions,
          viewpointBuilderFormattingOperations.setFormattingOptions
        ),
        reducer(
          setFormattingRules,
          viewpointBuilderFormattingOperations.setFormattingRules
        ),
        reducer(
          setLabelFormatting,
          viewpointBuilderFormattingOperations.setLabelFormatting
        ),
        reducer(
          resetFormattingState,
          viewpointBuilderFormattingOperations.resetFormattingState
        ),
      ]
    ),
    map(mapToViewpointBuilderFormattingViewState)
  );
