import styled from 'styled-components';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { Label } from '@ardoq/forms';
import { Icon } from '@ardoq/icons';
import { normal14 } from '@ardoq/typography';

import { GriddedTableRowProps } from './types';

export const GriddedTableRow = styled.div<GriddedTableRowProps>`
  ${({ $hideBorder }) =>
    $hideBorder
      ? ''
      : `
    border: 1px dashed ${colors.grey80};
    border-radius: 8px;
    padding: ${s16} ${s8};
  `};
  display: grid;
  grid-template-columns: ${({ $showSsoProviderColumn }) =>
    $showSsoProviderColumn
      ? `20% 16% 16% 13% auto fit-content(${s16})`
      : `22% 18% 22% auto fit-content(${s16})`};
  grid-gap: ${s16};
`;

export const TableHeaderLabel = styled(Label)`
  margin-left: ${s16};
`;

export const TableHeaderIcon = styled(Icon)`
  margin-left: 0.4rem;
  color: ${colors.grey50};
`;

export const TableContainer = styled.div`
  margin-top: ${s8};
`;

export const TimestampContainer = styled.div`
  width: 100%;
  text-align: right;
  color: ${colors.grey50};
`;

export const DoqContainer = styled.div`
  margin: auto;
`;

export const DoqMessageContainer = styled.div`
  ${normal14}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 85%;
`;

export const Unmapped = styled.div`
  color: ${colors.grey60};
  font-style: italic;
`;

export const SelectProviderContainer = styled.div`
  width: 25%;
`;

export const SelectAttributeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    width: 100%;
  }
  i {
    margin-right: ${s8};
    margin-left: 2px;
  }
`;
