import { CSSProperties } from 'react';
import {
  DataSourceItem,
  DiffTableRowType,
  MergeState,
  NonNullableDiffMergeTableProps,
} from './types';
import { Branch } from './Branch';
import { DatasourceTable } from '@ardoq/table';
import { APIEntityType, Verb } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { setMergeState, toggleIsExpandedTableRow } from './actions';
import {
  Aligner,
  BaseTr,
  CheckboxWithPointer,
  DisabledCheckbox,
  ExpandedContentTr,
  StyledTable,
  StyledTd,
  StyledTh,
  TrWithPointerOnHover,
} from './atoms';
import { getEntityRepresentationRenderer } from 'components/DiffMergeTable/EntityRepresentation/EntityRepresentation';
import { getVerbFromMergeStep } from 'components/DiffMergeSidebarNavigator/utils';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { getPopoverStringForDisabledCheckbox } from 'components/DiffMergeTable/popoverStrings';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { getAdditionalContentRenderer } from 'components/DiffMergeTable/AdditionalContent/additionalContentRender';
import { getIconAndHintMessageRenderer } from './iconAndHintMessageRenderer';
import { logError } from '@ardoq/logging';
import { colors } from '@ardoq/design-tokens';
import { registerTextPopovers, withPlainTextPopover } from '@ardoq/popovers';

registerTextPopovers();

const getValueRenderer =
  (
    mergeDirection: MergeDirection,
    verb: Verb,
    additionalContentRenderer: (
      dataSourceItem: DataSourceItem
    ) => JSX.Element | null
  ) =>
  (_: string, dataSourceRow: DataSourceItem) => {
    if (!dataSourceRow) return null;
    if (dataSourceRow.rowType === DiffTableRowType.EXPANDED_CONTENT) {
      return (
        <ErrorBoundary logError={logError}>
          {additionalContentRenderer(dataSourceRow)}
        </ErrorBoundary>
      );
    }

    let itemSelectOption = `checkbox-item-count-${dataSourceRow.index}`;
    if (dataSourceRow.rowType === DiffTableRowType.HEADER_ROW) {
      itemSelectOption = 'select-all-checkbox';
    } else if (dataSourceRow.rowType === DiffTableRowType.SECTION_HEADER_ROW) {
      itemSelectOption = `select-${dataSourceRow.sectionType}-section-checkbox`;
    }
    const { status, isDisabled } = dataSourceRow;
    return (
      <Aligner data-click-id={itemSelectOption}>
        {isDisabled ? (
          <DisabledCheckbox
            checked={status === MergeState.SOURCE}
            mixed={status === MergeState.PARTIAL}
            className="no-underline"
            {...withPlainTextPopover(
              getPopoverStringForDisabledCheckbox({
                verb,
                entityType: dataSourceRow.entityType,
                mergeDirection,
                hasWritePermission: dataSourceRow.hasWritePermission,
              })
            )}
          />
        ) : (
          <CheckboxWithPointer
            checked={status === MergeState.SOURCE}
            mixed={status === MergeState.PARTIAL}
          />
        )}
      </Aligner>
    );
  };

const onCellClick = ({ index, status, isDisabled, rowType }: DataSourceItem) =>
  rowType !== DiffTableRowType.EXPANDED_CONTENT &&
  !isDisabled &&
  dispatchAction(
    setMergeState({
      mergeStatus:
        status === MergeState.NONE ? MergeState.SOURCE : MergeState.NONE,
      index,
    })
  );

const getRowStyle = (rowData: DataSourceItem): CSSProperties => {
  if (rowData.rowType === DiffTableRowType.SECTION_HEADER_ROW) {
    return { backgroundColor: colors.grey95 };
  }
  return {};
};
const toggleExpander = ({ index, rowType }: DataSourceItem) =>
  rowType === DiffTableRowType.SUB_HEADER_ROW &&
  dispatchAction(toggleIsExpandedTableRow({ index }));

type TableWithCheckboxProps = {
  entityType: APIEntityType;
} & NonNullableDiffMergeTableProps;

const TableWithCheckbox = ({
  mergeStep,
  dataSource,
  entityType,
  graphics,
  mergeDirection,
  ...rest
}: TableWithCheckboxProps) => {
  const additionalContentRenderer = getAdditionalContentRenderer({
    ...rest,
    graphics,
    entityType,
    mergeStep,
    mergeDirection,
  });
  const verb = getVerbFromMergeStep(mergeStep);
  const [headerRow, ...bodyRows] = dataSource;
  const columns = [
    {
      valueRender: getValueRenderer(
        mergeDirection,
        verb,
        additionalContentRenderer
      ),
      headerRender: () =>
        getValueRenderer(
          mergeDirection,
          verb,
          additionalContentRenderer
        )('', headerRow),
      onHeaderClick: () => onCellClick(headerRow),
      onCellClick: onCellClick,
      getColSpan: (_: string, { rowType }: DataSourceItem) =>
        rowType === DiffTableRowType.EXPANDED_CONTENT ? 3 : 1,
      headerStyle: { width: 64 },
    },
    {
      title: 'Select all',
      valueRender: getEntityRepresentationRenderer(
        entityType,
        verb === Verb.CREATE ? Branch.SOURCE : Branch.TARGET,
        graphics,
        verb
      ),
      getColSpan: (_: string, { rowType }: DataSourceItem) =>
        rowType === DiffTableRowType.SECTION_HEADER_ROW ? 2 : 1,
      onCellClick: toggleExpander,
    },
    {
      valueRender: getIconAndHintMessageRenderer({ mergeStep }),
      headerStyle: { width: '40%' },
      onCellClick: toggleExpander,
    },
  ];

  return (
    <DatasourceTable
      components={{
        Th: StyledTh,
        Td: StyledTd,
        Table: StyledTable,
      }}
      columns={columns}
      dataSource={bodyRows.flatMap(row =>
        row.isExpanded
          ? [row, { ...row, rowType: DiffTableRowType.EXPANDED_CONTENT }]
          : row
      )}
      scrollableSectionHeight="100%"
      rowStyle={getRowStyle}
      getRowComponent={({ rowType }) => {
        if (rowType === DiffTableRowType.EXPANDED_CONTENT) {
          return ExpandedContentTr;
        } else if (rowType === DiffTableRowType.SUB_HEADER_ROW) {
          return TrWithPointerOnHover;
        }
        return BaseTr;
      }}
    />
  );
};

export default TableWithCheckbox;
