import { Maybe } from '@ardoq/common-helpers';
import { InventoryGridApi } from '@ardoq/inventory';
import {
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';

export const registerInventoryGridApi = actionCreator<InventoryGridApi>(
  '[inventoryGridApi] REGISTER_INVENTORY_GRID_API'
);

export const deregisterInventoryGridApi = actionCreator(
  '[inventoryGridApi] DEREGISTER_INVENTORY_GRID_API'
);

const registerInventoryGridApiReducer = (
  state: InventoryGridApi | null,
  inventoryGridApi: InventoryGridApi
): Maybe<InventoryGridApi> => {
  return inventoryGridApi;
};

const deregisterInventoryGridApiReducer = (): Maybe<InventoryGridApi> => {
  return null;
};

/**
 * The main purpose of this stream is to store the reference to InventoryGridApi.
 * It's backed by AGGrid Api and is used to interact with the grid and access its internal data.
 */
export const inventoryGridApi$ = persistentReducedStream<
  Maybe<InventoryGridApi>
>('inventoryGridApi$', null, [
  reducer(registerInventoryGridApi, registerInventoryGridApiReducer),
  reducer(deregisterInventoryGridApi, deregisterInventoryGridApiReducer),
]);
