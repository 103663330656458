import { PrivilegesStreamShape } from 'privileges/types';
import { RolesStreamShape } from 'roles/types';
import {
  APIOrganizationUser,
  APIRoleAttributes,
  OrgAccessLevel,
  PermissionGroup,
  Privilege,
  PrivilegeLabel,
  PrivilegesByUser,
} from '@ardoq/api-types';
import { reducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import { ManageUserRolesViewModel } from './types';
import * as commands from '../commands';

export type ManageUserRolesStreamShape = ManageUserRolesViewModel & {
  configurablePrivileges: Privilege[];
  privilegesStatus: PrivilegesStreamShape['status'];
  roles: APIRoleAttributes[];
  users: APIOrganizationUser[];
  rolesStatus: RolesStreamShape['status'];
  privilegesByUser: PrivilegesByUser;
  permissionGroups: PermissionGroup[];
  addToGroupError: string | null;
  commands: {
    setSelectedRoleLabel: (role: APIRoleAttributes) => void;
    setSelectedPrivileges: (privileges: PrivilegeLabel[]) => void;
  };
};

const manageUserRolesViewModel$ = reducedStream<ManageUserRolesStreamShape>(
  'manageUserRolesViewModel$',
  {
    selectedPrivileges: [],
    selectedRoleLabel: OrgAccessLevel.ADMIN,
    roles: [],
    users: [],
    rolesStatus: 'idle',
    addToGroupError: null,
    privilegesByUser: {},
    configurablePrivileges: [],
    permissionGroups: [],
    privilegesStatus: 'idle',
    commands,
  },
  reducers
);

export default manageUserRolesViewModel$;
