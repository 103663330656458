import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import styled from 'styled-components';

const Container = styled.div`
  height: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyContent = () => {
  return (
    <Container>
      <DoqWithSpeechBubble
        doqType={DoqType.BROADCAST}
        layout={DoqLayout.DIALOG}
        title="No results yet..."
        message="No components found. This list automatically updates based on your filter rules. Please check filter rules or preview later."
      />
    </Container>
  );
};
