import { Fragment } from 'react';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { createFromTemplate$ } from './createFromTemplate$';
import {
  CategoryCollectionLabel,
  CategoryIcon,
  CategoryItem,
  TemplateCategoryNav,
  TemplateCategorySectionContainer,
  TemplateListContainer,
  VerticalTabs,
} from './atoms';
import TabContent from './TabContentComponent';
import { selectCategory } from './actions';
import { classes } from '@ardoq/common-helpers';
import {
  CategoryWithTemplatesCollection,
  CreateFromTemplateStreamState,
  CurrentTab,
  ModelCategoryWithTemplates,
} from './types';
import { IconSize } from '@ardoq/icons';

type CategoryProperties = ModelCategoryWithTemplates & {
  currentTab: CurrentTab;
};

const Category = ({ id, icon, name, currentTab }: CategoryProperties) => (
  <CategoryItem
    id={id}
    className={classes(id === currentTab.category.id && 'active')}
    onClick={() => dispatchAction(selectCategory(id))}
  >
    {icon && <CategoryIcon iconName={icon} iconSize={IconSize.SMALL} />}
    {name}
  </CategoryItem>
);

type TemplateCategorySectionProps = CategoryWithTemplatesCollection &
  Pick<CreateFromTemplateStreamState, 'currentTab'>;

const TemplateCategorySection = ({
  label,
  categories,
  currentTab,
}: TemplateCategorySectionProps) => (
  <TemplateCategorySectionContainer>
    {label && <CategoryCollectionLabel>{label}</CategoryCollectionLabel>}
    {categories.map(category => (
      <Category
        key={`category_${category.id}`}
        {...category}
        currentTab={currentTab}
      />
    ))}
  </TemplateCategorySectionContainer>
);

const CreateFromTemplate = ({
  categories,
  currentTab,
  onClose,
}: Omit<CreateFromTemplateStreamState, 'blankTemplateModel'> & {
  onClose: VoidFunction;
}) => (
  <VerticalTabs>
    <TemplateCategoryNav>
      {categories.map((categoryCollection, i) => (
        <Fragment key={`template_category_${i}`}>
          <TemplateCategorySection
            {...categoryCollection}
            currentTab={currentTab}
          />
        </Fragment>
      ))}
    </TemplateCategoryNav>

    <TemplateListContainer>
      {currentTab.category && (
        <TabContent currentTab={currentTab} onClose={onClose} />
      )}
    </TemplateListContainer>
  </VerticalTabs>
);

type CreateFromTemplateModalProps = Omit<
  CreateFromTemplateStreamState,
  'blankTemplateModel'
> & {
  onClose: VoidFunction;
};

const CreateFromTemplateModal = ({
  onClose,
  categories,
  currentTab,
}: CreateFromTemplateModalProps) => {
  return (
    <ModalTemplate
      modalSize={ModalSize.L}
      headerText={'Create new Workspace'}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onClose}
    >
      <CreateFromTemplate
        categories={categories}
        currentTab={currentTab}
        onClose={onClose}
      />
    </ModalTemplate>
  );
};

const ConnectedCreateFromTemplateModal = connect(
  CreateFromTemplateModal,
  createFromTemplate$
);

export default () => {
  modal(
    resolve => (
      <ConnectedCreateFromTemplateModal onClose={() => resolve(false)} />
    ),
    { autoFocus: false }
  );
};
