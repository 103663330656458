import { ofType, routine } from '@ardoq/rxbeach';
import { withLatestFrom, tap } from 'rxjs/operators';
import { selectedDataSetDatasource$ } from '../inventory$';
import { inventoryGridApi$ } from '../inventoryGridApi$';
import { enterCreateComponentsMode } from './actions';
import { inventoryOperations } from '../inventoryOperations';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';

const handleEnterCreateComponentsMode = routine(
  ofType(enterCreateComponentsMode),
  withLatestFrom(
    inventoryGridApi$,
    selectedDataSetDatasource$,
    currentUserPermissionContext$
  ),
  tap(
    ([, inventoryGridApi, inventoryDataSourceSelection, permissionContext]) => {
      if (!inventoryGridApi) {
        return;
      }
      const defaultWorkspaceId = inventoryOperations
        .getWorkspaceIdsFromDatasource(inventoryDataSourceSelection)
        .find(workspaceId =>
          workspaceAccessControlInterface.canEditWorkspace(
            permissionContext,
            workspaceId,
            null
          )
        );
      if (defaultWorkspaceId) {
        inventoryGridApi.enterCreateComponentsMode({ defaultWorkspaceId });
      }
    }
  )
);

export const interactiveTopRowRoutines = [handleEnterCreateComponentsMode];
