import { useRef, useState } from 'react';
import { combineLatest } from 'rxjs';
import { AqLayout } from '@ardoq/layout';
import { CreateNewButton } from '@ardoq/page-layout';
import AssetsBrowser from '../components/AssetsBrowser/AssetsBrowser';
import { ArdoqId, AssetType, SortParams, Report } from '@ardoq/api-types';
import { TableTag } from '../appContainer/DashboardContainer/atoms';
import { AssetRow, ReportRow } from '../components/AssetsBrowser/types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { ReportEventLocations } from '@ardoq/report-reader';
import { map } from 'rxjs/operators';
import { getReportOverviewMenuOptions } from '../appContainer/DashboardContainer/getMenuOptions';
import { getPermissionsForSelection } from '../appContainer/DashboardContainer/utils';
import {
  openCreateReport,
  openReportInReader,
} from '../components/AppMainSidebar/utils';
import { FieldsRow, FormSize, FormWrapper, SearchInput } from '@ardoq/forms';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import reportOverview$ from './ReportOverview$';
import { s16, s32 } from '@ardoq/design-tokens';
import { PrimaryButton } from '@ardoq/button';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { ContextMenuProvider } from '@ardoq/dropdown-menu';
import {
  highlightStatusMapProps,
  withHighlightStatusMap,
} from '../utils/withHighlightStatusMap';
import BodyTr from '../appContainer/DashboardContainer/BodyTr';
import assets$, { AssetWithMeta, AssetsState } from '../streams/assets/assets$';
import {
  setReportOverviewSearchPhrase,
  setReportOverviewSort,
} from './actions';
import {
  Permissions,
  hasPermission,
} from '../streams/currentUserPermissions/permissionInterface';
import {
  getDataSourceColumn,
  getReportErrorColumn,
  getReportOverviewColumns,
} from './columns';
import { hasFeature, Features } from '@ardoq/features';
import Navbar from 'views/navbar/Navbar';
import MainToolbar from 'menus/topbar/MainToolbar';
import { PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import AssetsBrowser2024 from '../components/AssetsBrowser2024/AssetsBrowser2024';
import { AppModules } from '../appContainer/types';
import {
  favoriteFilter,
  getAssetFilter,
  getCreatedByMeFilter,
} from '../components/AssetsBrowser2024/FilterBar/filters';
import {
  getLastUpdatedColumn,
  getUpdatedByColumn,
} from '../components/AssetsBrowser2024/assetsBrowser2024Columns';
import {
  errorStatusFilter,
  advancedSearchFilter,
  gremlinFilter,
} from './utils';
import { RowType } from '@ardoq/table';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import React from 'react';
import currentUser$, {
  CurrentUserState,
} from 'streams/currentUser/currentUser$';
import { useResizeObserver } from '@ardoq/hooks';

const mapProps = (
  assets: AssetsState,
  sortAndFilter: { searchPhrase: string } & SortParams,
  currentUser: CurrentUserState
): ReportOverviewProps => ({
  dataSource: Object.values(assets.reportsById).map(asset => ({
    ...asset,
    meta: { ...asset.meta, level: 0 },
    rowType: RowType.REPORT,
  })),
  assetsById: assets.reportsById,
  hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
  currentUserId: currentUser._id,
  ...sortAndFilter,
});

type ReportOverviewProps = {
  dataSource: ReportRow[];
  assetsById: Record<string, AssetWithMeta<Report, AssetType.REPORT>>;
  searchPhrase: string;
  hasNewJourneyFeature: boolean;
  currentUserId: ArdoqId;
} & SortParams;

const ReportOverview = ({
  dataSource,
  assetsById,
  searchPhrase,
  highlightStatusMap,
  hasNewJourneyFeature,
  sortOrder,
  sortByField,
  currentUserId,
}: ReportOverviewProps & highlightStatusMapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const size = useResizeObserver(ref);
  const [selectedIds, setSelectedIds] = useState<ArdoqId[]>([]);
  const [renameId, setRenameId] = useState<ArdoqId | undefined>(undefined);
  const clearSelected = () => setSelectedIds([]);
  const selectionPermissions = getPermissionsForSelection(
    selectedIds,
    assetsById
  );
  const getMenuOptions = (report: ReportRow) =>
    getReportOverviewMenuOptions(report, {
      selected: selectedIds,
      selectedAssets: selectedIds
        .map(id => assetsById[id])
        .filter(ExcludeFalsy),
      selectionPermissions,
      rowPermissions: report.meta.permissions,
      clearSelected,
      onRenameSelect: setRenameId,
    });
  const navBarHeight = hasNewJourneyFeature ? PAGE_TOPBAR_HEIGHT : 0;

  const pageContent = (
    <>
      <FormWrapper>
        <FieldsRow>
          <SearchInput
            formSize={FormSize.SMALL}
            value={searchPhrase}
            onValueChange={newSearchPhrase => {
              dispatchAction(setReportOverviewSearchPhrase(newSearchPhrase));
            }}
          />
        </FieldsRow>
      </FormWrapper>
      <ContextMenuProvider dropdownSize={DropdownSize.S}>
        {({ openContextMenu }) => (
          <AssetsBrowser
            initialSortParams={{ sortOrder, sortByField }}
            onSortParamsChange={sortParams =>
              dispatchAction(setReportOverviewSort(sortParams))
            }
            searchPhrase={searchPhrase}
            onTableClick={(event: React.MouseEvent, row) => {
              if (!row) return;
              event.preventDefault();
              const menuOptions = getMenuOptions(row as ReportRow);
              if (event.type === 'contextmenu' && menuOptions.length) {
                openContextMenu(event, { options: menuOptions });
              }
            }}
            onAssetClick={(asset: AssetRow) =>
              openReportInReader({
                reportId: asset._id,
              })
            }
            dataSource={dataSource}
            selected={selectedIds}
            components={{ BodyTr, Table: TableTag }}
            setSelected={setSelectedIds}
            tableHeight={(size.height || 0) - (130 + navBarHeight)}
            getCustomColumns={columnProps =>
              getReportOverviewColumns({
                ...columnProps,
                getMenuOptions,
              })
            }
            renameId={renameId}
            renameCancel={() => setRenameId(undefined)}
            dragDropProps={{
              highlightStatusMap,
              renameId,
              selected: selectedIds,
            }}
          />
        )}
      </ContextMenuProvider>
    </>
  );
  return hasNewJourneyFeature ? (
    <PageWrapper ref={ref}>
      <Navbar
        primaryContent="Reports"
        secondaryContent="Overview"
        toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
        primaryButton={
          <CreateNewButton
            isDisabled={!hasPermission(Permissions.REPORTS_CREATE)}
            onClick={() =>
              openCreateReport(ReportEventLocations.REPORT_OVERVIEW)
            }
          />
        }
      />
      <PageBody backgroundColor="surfaceDefault">
        <AssetsBrowser2024
          appModule={AppModules.REPORTS}
          alwaysActiveFilters={[getAssetFilter(AssetType.REPORT)]}
          filters={[
            favoriteFilter,
            getCreatedByMeFilter(currentUserId),
            advancedSearchFilter,
            gremlinFilter,
            errorStatusFilter,
          ]}
          getCustomColumns={columnsProps => [
            getDataSourceColumn(columnsProps),
            getUpdatedByColumn(columnsProps),
            getLastUpdatedColumn(columnsProps),
            getReportErrorColumn(columnsProps),
          ]}
          scrollHeight={size.height ? size.height - PAGE_TOPBAR_HEIGHT : 0}
          useFolders
        />
      </PageBody>
    </PageWrapper>
  ) : (
    <AqLayout
      title="Report Overview"
      ref={ref}
      bodyContentStyle={{
        padding: 0,
        height: '100%',
        position: 'relative',
        gap: s16,
        paddingLeft: s32,
        paddingTop: s32,
      }}
      renderHeaderButtons={() => (
        <PrimaryButton
          isDisabled={!hasPermission(Permissions.REPORTS_CREATE)}
          onClick={() => openCreateReport(ReportEventLocations.REPORT_OVERVIEW)}
        >
          Create new
        </PrimaryButton>
      )}
    >
      {pageContent}
    </AqLayout>
  );
};

const viewModel$ = combineLatest([assets$, reportOverview$, currentUser$]).pipe(
  map(([assets, sortAndFilter, currentUser]) =>
    mapProps(assets, sortAndFilter, currentUser)
  )
);

export default withHighlightStatusMap(connect(ReportOverview, viewModel$));
