import { useState } from 'react';
import { connect } from '@ardoq/rxbeach';
import { FieldsWrapper } from '@ardoq/forms';
import { ModalSize, ModalTemplate } from '@ardoq/modal';
import { colors, s16, s32 } from '@ardoq/design-tokens';
import styled from 'styled-components';

import {
  bulkUpdateUserPrivileges,
  bulkUpdateUserPrivilegesFailed,
  bulkUpdateUserPrivilegesSucceeded,
} from 'admin/user/actions';
import {
  APIOrganizationUser,
  APIRoleAttributes,
  Privilege,
  PrivilegeLabel,
  PrivilegesByUser,
} from '@ardoq/api-types';
import { StatusType, WarningNotification } from '@ardoq/status-ui';
import { Multiselect, SelectOption } from '@ardoq/select';
import { getInheritedPrivileges } from 'roles/utils';
import manageUserRolesViewModel$ from '../../accessControl/ManageUserRoles/streams/manageUserRolesViewModel$';
import { PrivilegeAction } from 'privileges/types';
import PaginatedUserTable from './PaginatedUserTable';
import { getNoninheritedPrivileges } from 'privileges/utils';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { MANAGE_PRIVILEGE_MODAL } from './consts';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { Island, IslandHeader } from '@ardoq/page-layout';

type ManagePrivilegeDialogProps = {
  configurablePrivileges: Privilege[];
  privilegesByUser: PrivilegesByUser;
  selectedUsers: APIOrganizationUser[];
  action: PrivilegeAction;
  roles: APIRoleAttributes[];
  closeDialog: () => void;
};

export type APIOrganizationUserWithPrivileges = APIOrganizationUser & {
  inheritedPrivileges: PrivilegeLabel[];
  assignedPrivileges: PrivilegeLabel[];
};

const Wrapper = styled.div`
  background-color: ${colors.grey95};
  padding: ${s32};
  display: flex;
  flex-direction: column;
  gap: ${s16};
`;

const getStrings = (action: PrivilegeAction): Record<string, string> => {
  return {
    ...MANAGE_PRIVILEGE_MODAL.STRINGS_BY_ACTION[action],
    ...MANAGE_PRIVILEGE_MODAL._COMMON,
  };
};

const PrivilegesWarnings = ({
  configurablePrivileges,
  selectedPrivileges,
}: {
  selectedPrivileges: SelectOption<PrivilegeLabel>[];
  configurablePrivileges: Privilege[];
}) => (
  <FieldsWrapper>
    {selectedPrivileges
      .filter(({ statusType }) => statusType === StatusType.WARNING)
      .map(privilegeOption => {
        const warningText = configurablePrivileges.find(
          ({ label }) => privilegeOption.value === label
        )!.warning;
        return (
          <WarningNotification key={privilegeOption.value}>
            {warningText}
          </WarningNotification>
        );
      })}
  </FieldsWrapper>
);

const ManagePrivilegeDialog = ({
  configurablePrivileges,
  privilegesByUser,
  selectedUsers,
  action,
  roles,
  closeDialog,
}: ManagePrivilegeDialogProps) => {
  const [selectedPrivileges, setSelectedPrivileges] = useState<
    SelectOption<PrivilegeLabel>[]
  >([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const STRINGS = getStrings(action);

  const usersWithAssignedAndInheritedPrivileges =
    selectedUsers.map<APIOrganizationUserWithPrivileges>(user => ({
      ...user,
      inheritedPrivileges: getInheritedPrivileges(roles, user.role),
      assignedPrivileges: getNoninheritedPrivileges(privilegesByUser, user._id),
    }));

  const handleBulkUpdateUsers = () => {
    if (!selectedPrivileges?.length) return;
    setSubmitting(true);
    dispatchActionAndWaitForResponse(
      bulkUpdateUserPrivileges({
        selectedUsers: usersWithAssignedAndInheritedPrivileges,
        selectedPrivileges: selectedPrivileges.map(
          privilege => privilege.value
        ),
        privilegeAction: action,
      }),
      bulkUpdateUserPrivilegesSucceeded,
      bulkUpdateUserPrivilegesFailed
    ).then(response => {
      setSubmitting(false);
      if (response.type === bulkUpdateUserPrivilegesSucceeded.type) {
        closeDialog();
      }
    });
  };

  const privilegesToRemove =
    action === PrivilegeAction.REMOVE ? selectedPrivileges : undefined;

  const privilegesToAssign =
    action === PrivilegeAction.ASSIGN
      ? configurablePrivileges.filter(privilege =>
          selectedPrivileges.find(
            selected => selected.value === privilege.label
          )
        )
      : undefined;

  return (
    <ModalTemplate
      headerText={STRINGS.HEADER}
      modalSize={ModalSize.M}
      onCloseButtonClick={closeDialog}
      onPrimaryButtonClick={handleBulkUpdateUsers}
      onSecondaryButtonClick={closeDialog}
      primaryButtonClickId="save-privileges-click-id"
      primaryButtonText={STRINGS.SAVE_BUTTON}
      secondaryButtonClickId="cancel-privileges-click-id"
      secondaryButtonText={STRINGS.CANCEL_BUTTON}
      isPrimaryButtonDisabled={!selectedPrivileges?.length}
      isInProgress={submitting}
    >
      <Wrapper>
        <PrivilegesWarnings
          configurablePrivileges={configurablePrivileges}
          selectedPrivileges={selectedPrivileges}
        />
        <Island
          header={
            <IslandHeader
              title={STRINGS.HEADER}
              subtitle={STRINGS.NOTIFICATION}
              knowledgeHint={STRINGS.DOC_MESSAGE}
              knowledgeBaseLink={KnowledgeBaseLink.USERS_ACCESS_ASSIGNMENT}
            ></IslandHeader>
          }
        >
          <FieldsWrapper>
            <Multiselect
              value={selectedPrivileges}
              onChange={selectedOptions => {
                if (!selectedOptions) return;
                setSelectedPrivileges(selectedOptions);
              }}
              label={STRINGS.DROPDOWN_QUESTION}
              placeholder={STRINGS.DROPDOWN_PLACEHOLDER}
              options={configurablePrivileges.map(privilege => ({
                label: privilege.description,
                value: privilege.label,
                warning: privilege.warning,
                statusType:
                  privilege.warning && action === PrivilegeAction.ASSIGN
                    ? StatusType.WARNING
                    : StatusType.DEFAULT,
              }))}
            />
          </FieldsWrapper>
        </Island>

        <PaginatedUserTable
          selectedUsers={usersWithAssignedAndInheritedPrivileges}
          privilegesToRemove={privilegesToRemove}
          privilegesToAssign={privilegesToAssign}
        />
      </Wrapper>
    </ModalTemplate>
  );
};

export default connect(ManagePrivilegeDialog, manageUserRolesViewModel$);
