import {
  ArdoqId,
  MetaModelComponentType,
  Zone,
  ZonePermissionMapping,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import {
  AddNewZoneGroupPermissionActionPayload,
  ChangeZoneGroupPermissionActionPayload,
  ComponentTypeFieldsGroupSearchParams,
  DeleteZoneGroupPermissionActionPayload,
  UpdateFieldsInComponentTypeInZoneActionPayload,
} from './types';
import { SearchByTypes } from '../components/ComponentTypeFieldsGroupSearch';

const DOMAIN = '[SUBDIVISION/ZONES/VIEW_STATE]';

export const addNewZoneGroupPermission =
  actionCreator<AddNewZoneGroupPermissionActionPayload>(
    `${DOMAIN} addNewZoneGroupPermission`
  );

export const deleteZoneGroupPermission =
  actionCreator<DeleteZoneGroupPermissionActionPayload>(
    `${DOMAIN} deleteZoneGroupPermission`
  );

export const changeZoneGroupPermission =
  actionCreator<ChangeZoneGroupPermissionActionPayload>(
    `${DOMAIN} changeZoneGroupPermission`
  );

export const updateComponentTypeFieldsGroupSearchKey = actionCreator<string>(
  `${DOMAIN} updateComponentTypeFieldsGroupSearchKey`
);

export const updateComponentTypeFieldsGroupSearchBy =
  actionCreator<SearchByTypes>(
    `${DOMAIN} updateComponentTypeFieldsGroupSearchBy`
  );

export const changeFieldsConfigInComponentTypeInZone =
  actionCreator<UpdateFieldsInComponentTypeInZoneActionPayload>(
    `${DOMAIN} changeFieldsConfigInComponentTypeInZone`
  );

export const savePermissionsAndZones = actionCreator<ZonePermissionMapping[]>(
  `${DOMAIN} savePermissionsAndZones`
);

export const savePermissionsAndZonesSuccessfully = actionCreator<
  ZonePermissionMapping[]
>(`${DOMAIN} savePermissionsAndZonesSuccessfully`);

export const savePermissionsAndZonesFailure = actionCreator(
  `${DOMAIN} savePermissionsAndZonesFailure`
);

export type SelectAllFieldsPayload = {
  zone: Zone;
  selectedComponentTypes: MetaModelComponentType[];
  componentTypeFieldsGroupSearch: ComponentTypeFieldsGroupSearchParams;
};
export const selectAllFields = actionCreator<SelectAllFieldsPayload>(
  `${DOMAIN} SELECT_ALL_FIELDS`
);
export const deselectAllFields = actionCreator<SelectAllFieldsPayload>(
  `${DOMAIN} DESELECT_ALL_FIELDS`
);

export const changeZoneName = actionCreator<{ newName: string; zone: Zone }>(
  `${DOMAIN} CHANGE_ZONE_NAME`
);

export const setEditingZoneName = actionCreator<ArdoqId>(
  `${DOMAIN} SET_EDITING_ZONE_NAME`
);

export const resetEditingZoneName = actionCreator<ArdoqId>(
  `${DOMAIN} RESET_EDITING_ZONE_NAME`
);
