import NavigatorContainer from './navigatorContainer';
import React, { useEffect, useState } from 'react';
import getDefaultState from '../streams/defaultState';
import {
  mapToNavigatorContainerProps,
  subscribeToNavigatorStreamAndRoutines,
} from '../subscribeToNavigatorStreamAndRoutines';
import {
  LoadDataErrorProps,
  LoadDataNotificationProps,
  NavigatorContainerProps,
} from './PropTypes';
import { NavigatorConfiguration } from '../types';
import { dispatchAction } from '@ardoq/rxbeach';
import { streamInitialized } from '../actions/navigatorActions';

type NavigatorProps = {
  dragElementRoot?: HTMLElement;
  navigatorConfiguration: NavigatorConfiguration;
  LoadDataNotification?: (props: LoadDataNotificationProps) => JSX.Element;
  LoadDataError?: (props: LoadDataErrorProps) => JSX.Element;
};

export const Navigator = React.memo(
  ({
    dragElementRoot,
    navigatorConfiguration,
    LoadDataError,
    LoadDataNotification,
  }: NavigatorProps) => {
    const [state, setState] = useState<NavigatorContainerProps>(
      mapToNavigatorContainerProps({
        navigatorState: getDefaultState(),
        navigatorConfiguration,
      })
    );

    useEffect(() => {
      const unsubscribeStreamAndRoutinesAndCleanUp =
        subscribeToNavigatorStreamAndRoutines({
          setState,
          navigatorConfiguration,
        });
      dispatchAction(
        streamInitialized(),
        navigatorConfiguration.actionNamespace
      );
      return unsubscribeStreamAndRoutinesAndCleanUp;
    }, [setState, navigatorConfiguration]);

    if (state.dataLoadingMessage && LoadDataNotification) {
      return (
        <LoadDataNotification
          loadingScopeDataNotification={state.dataLoadingMessage}
        />
      );
    }

    if (state.dataErrorMessage && LoadDataError) {
      return <LoadDataError errorMessage={state.dataErrorMessage} />;
    }

    return <NavigatorContainer {...state} dragElementRoot={dragElementRoot} />;
  }
);
