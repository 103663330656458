import { ReactNode, useRef } from 'react';
import styled from 'styled-components';
import { TextInput } from '@ardoq/forms';
import { useEffectOnce } from '@ardoq/hooks';
import { Icon, IconName } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { toComponentTypeOptions } from 'viewpointBuilder/selectContextTab/toComponentTypeOptions';
import { MetaModelComponentType } from '@ardoq/api-types';
import { ComponentTypeSelect } from 'viewpointBuilder/selectContextTab/ComponentTypeSelect';
import { FlexBox } from '@ardoq/layout';

type FilterByNameAndTypeCriteriaProps = {
  componentName: string;
  setComponentName: (value: string) => void;
  selectedComponentType: string;
  setSelectedComponentType: (value: string) => void;
  setIsAdvancedSearch: (isAdvancedSearch: boolean) => void;
  componentTypes: MetaModelComponentType[];
} & ComponentTypeSelection;

type ComponentTypeSelection =
  | {
      isComponentTypeSelectionDisabled: false;
    }
  | {
      isComponentTypeSelectionDisabled: true;
      componentTypeSelectionDisabledReason: string;
    };

const FilterByNameAndTypeCriteria = ({
  componentName,
  setComponentName,
  selectedComponentType,
  setSelectedComponentType,
  componentTypes,
  ...props
}: FilterByNameAndTypeCriteriaProps) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffectOnce(() => ref.current?.focus());

  const label: ReactNode = props.isComponentTypeSelectionDisabled ? (
    <DisabledSelectLabelWrapper>
      Context component type
      <WithPopover content={props.componentTypeSelectionDisabledReason}>
        <Icon iconName={IconName.KNOWLEDGE_BASE} />
      </WithPopover>
    </DisabledSelectLabelWrapper>
  ) : (
    <>Select component type</>
  );
  const componentTypeOptions = toComponentTypeOptions(componentTypes);

  return (
    <FlexBox gap="medium" justify="space-between" align="flex-end">
      <InputWrapper $basis={500}>
        <TextInput
          label="Search component name"
          value={componentName}
          onValueChange={setComponentName}
          ref={ref}
          onClear={() => setComponentName('')}
          leftIconName={IconName.SEARCH}
          placeholder="Search for a component"
          autoComplete="off"
        />
      </InputWrapper>
      <InputWrapper $basis={300}>
        <ComponentTypeSelect
          placeholder="All components"
          isComponentTypeSelectionDisabled={
            props.isComponentTypeSelectionDisabled
          }
          selectedComponentType={selectedComponentType}
          setSelectedComponentType={setSelectedComponentType}
          label={label}
          componentTypeOptions={componentTypeOptions}
        />
      </InputWrapper>
    </FlexBox>
  );
};

const InputWrapper = styled.div<{ $basis: number }>`
  flex: 1 1 ${({ $basis }) => $basis}px;
`;

const DisabledSelectLabelWrapper = styled.div`
  pointer-events: all;
  display: flex;
  align-items: center;
`;

export default FilterByNameAndTypeCriteria;
