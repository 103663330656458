import { trackEvent } from 'tracking/tracking';
import {
  QuickSearchSelectedTrackingMetaData,
  QuickSearchTrackingMetaData,
} from './types';
import { QuickSearchOptions, SearchBy } from '@ardoq/api-types';

// Track when the user does a quick search
export const trackQuickSearch = (metadata: QuickSearchTrackingMetaData) =>
  trackEvent('Performed quick search', metadata);

const highlighted = (data: string) => {
  const highlighted = /<em>.*<\/em>/;
  return data.split(/\s+/).some(word => highlighted.test(word));
};

// Track when the user selects a quick search result
export const trackSelectedQuickSearchResult = (
  metadata: QuickSearchSelectedTrackingMetaData
) => {
  const type = metadata.result.type;

  const someNameWordMatched =
    type !== 'reference' && highlighted(metadata.result.name);
  const someDisplayTextWordMatched =
    type === 'reference' && highlighted(metadata.result.displayText ?? '');

  const someDescriptionWordMatched = highlighted(metadata.result.description);

  const inputValue = metadata.query;
  const inputSize = inputValue?.length;
  const inputWordsCount = inputValue?.split(/\s+/).length;
  const selectedIndex = metadata.selectedIndex;
  trackEvent('Selected quick search result', {
    type,
    someNameWordMatched,
    someDisplayTextWordMatched,
    someDescriptionWordMatched,
    selectedIndex,
    inputSize,
    inputWordsCount,
  });
};

export const composeQuickSearchTrackingMetaData = (
  total: number,
  options?: QuickSearchOptions
) => ({
  resultSize: total,
  limitToOpenWorkspace: options?.useOpenWorkspaces ?? false,
  filterOptionComponent: Boolean(
    options?.searchBy === SearchBy.NAME_DESCRIPTION && options.isComponent
  ),
  filterOptionReference: Boolean(
    options?.searchBy === SearchBy.NAME_DESCRIPTION && options.isReference
  ),
  filterOptionWorkspace: Boolean(
    options?.searchBy === SearchBy.NAME_DESCRIPTION && options.isWorkspace
  ),
});
