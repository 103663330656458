import { APIComponentAttributes } from '@ardoq/api-types';
import { SubdivisionSummaryViewModelState } from './subdivisionSummaryViewModel$';

const updateSearchKeyReducer = (
  subdivisionSummaryViewModelState: SubdivisionSummaryViewModelState,
  newKey: string
): SubdivisionSummaryViewModelState => ({
  ...subdivisionSummaryViewModelState,
  searchKey: newKey,
});

const componentMatchesSearchKey = (
  component: APIComponentAttributes,
  searchKey: string
) => component.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase());

export const subdivisionSummaryViewModelOperations = {
  updateSearchKeyReducer,
  componentMatchesSearchKey,
};
