import { TABLE_MAPPING_DISPLAY_TEXTS } from 'integrations/common/pages/tabularConfigMapping/constants';
import { TablePreviews } from 'integrations/common/streams/tablePreviews/types';
import {
  TableMappingMap,
  TableStatus,
  TabularMapping,
} from 'integrations/common/streams/tabularMappings/types';
import {
  isTableMappingMapComponents,
  isTableMappingMapReferences,
  isValidTableMappingMap,
} from 'integrations/common/streams/tabularMappings/utils';
import { OverviewDataSource } from './types';

const getMappedWorkspaceInfo = (
  tableMapping: TableMappingMap
): string | null | undefined => {
  if (isTableMappingMapComponents(tableMapping)) {
    return tableMapping.rootWorkspace?.name;
  }

  if (isTableMappingMapReferences(tableMapping)) {
    return `${tableMapping.rootWorkspace?.name ?? ''}  >>  ${
      tableMapping.targetWorkspace?.name ?? ''
    }`;
  }

  return null;
};

export function getOverviewDatasource(
  tablePreviews: TablePreviews,
  tabularMapping: TabularMapping,
  tableStatuses: Record<string, TableStatus>
): OverviewDataSource {
  return Object.values(tablePreviews).map(table => {
    const workspace = getMappedWorkspaceInfo(tabularMapping[table.id]);

    const isValidMapping = isValidTableMappingMap(tabularMapping[table.id]);
    return {
      id: table.id,
      worksheet: table.name,
      mappedTo:
        TABLE_MAPPING_DISPLAY_TEXTS[
          tabularMapping[table.id]?.rowRepresentation
        ],
      workspace,
      tableStatus: tableStatuses[table.id],
      isValidMapping,
    };
  });
}
