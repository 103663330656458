import { Multiselect } from '@ardoq/select';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';
import { SubdivisionProps } from 'subdivisionEditor/types';

export const WorkspaceSelector = (props: SubdivisionProps) => {
  const { workspacesBoundToSubdivisionIds } = props.bindWorkspacesState;
  const selectedWorkspaces =
    subdivisionEditorOperations.getAllSelectedWorkspacesToBindToSubdivision(
      props
    );
  return (
    <Multiselect
      label="Select the workspaces within the scope of this division."
      options={props.bindWorkspacesState.workspaces.map(ws => ({
        value: ws._id,
        label: ws.name,
        isDisabled: workspacesBoundToSubdivisionIds.includes(ws._id),
      }))}
      isClearable={false}
      value={selectedWorkspaces}
      onValueChange={value =>
        props.bindWorkspacesCommands.setSelectedWorkspaces(
          props,
          workspacesBoundToSubdivisionIds,
          value
        )
      }
      errorMessage={
        props.stepsErrors[props.subdivisionEditorStep]?.selectedWorkspaces
      }
      hasError={
        !!props.stepsErrors[props.subdivisionEditorStep]?.selectedWorkspaces
      }
      dataTestId="workspace-select"
      popoverHelpContent={
        'To add specific components, open a workspace and add them individually after creating the subdivision.'
      }
    />
  );
};
