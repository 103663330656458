import { uniq } from 'lodash';
import {
  CreatedItemsLoadedStateParams,
  DirectedTripleWithFilters,
  HexIndex,
  isAnyTraversalLoadedState,
  isCreatedItemsLoadedState,
  isQueryComponentSelection,
  isTraversalLoadedState,
  LoadedStateParams,
  LoadedStateType,
  PathCollapsingRule,
  QueryBuilderRow,
  QueryBuilderRuleValue,
  SearchLoadedStateParams,
  Token,
  TraversalCreatedInViewLoadedStateParams,
  TraversalLoadedStateParams,
  TraversalPathMatchingType,
  TripleQualifier,
  ViewpointBuilderFilters,
} from '@ardoq/api-types';
import { defaultKeys } from './defaultKeys';
import { ExcludeFalsy, tokenIsString } from '@ardoq/common-helpers';
import { FILTER_ID_PREFIX } from 'viewpointBuilder/getId';
import {
  BASE,
  CLOSE_AND_RULES,
  CLOSE_OR_RULES,
  COLLAPSED_PATH_SEPARATOR,
  FILTER_SEPARATOR,
  ID_SEPARATOR,
  OPEN_AND_RULES,
  OPEN_OR_RULES,
  PATH_SEPARATOR,
  SEARCH_SEPARATOR,
  STATE_SEPARATOR,
  TOKEN_SEPARATOR,
  TRAVERSAL_SEPARATOR,
  QUERY_RULE_TERMINATOR,
} from '../consts';
import { getStartSetAndStartQueryFromComponentSelection } from 'viewpointBuilder/getComponentSelection';
import { queryBuilderOperations } from '@ardoq/query-builder';

const loadedTypesArray: LoadedStateType[] = [
  LoadedStateType.SEARCH,
  LoadedStateType.TRAVERSAL,
  LoadedStateType.CREATED_ITEMS,
  LoadedStateType.TRAVERSAL_CREATED_IN_VIEW,
];

const encodeType = (type: LoadedStateType): number =>
  loadedTypesArray.indexOf(type);

const pathMatchingTypesArray: TraversalPathMatchingType[] = [
  TraversalPathMatchingType.LOOSE,
  TraversalPathMatchingType.STRICT,
  TraversalPathMatchingType.SUPERSTRICT,
];

const encodePathMatching = (type: TraversalPathMatchingType) =>
  pathMatchingTypesArray.indexOf(type);

const pathQualifierTypeArray = ['optional', 'required', 'excluded'];
const encodeQualifier = (type: TripleQualifier) =>
  pathQualifierTypeArray.indexOf(type);

export const encodeTraversal = (
  loadedState:
    | TraversalLoadedStateParams
    | TraversalCreatedInViewLoadedStateParams,
  tokenToHexIndex: Record<string, string>
): string => {
  const { isHidden, data: traversal } = loadedState;
  const traversalId = isTraversalLoadedState(loadedState)
    ? loadedState.traversalId
    : undefined;
  const { paths, filters, componentSelection, pathCollapsingRules } = traversal;
  const { startSet, startQuery } =
    getStartSetAndStartQueryFromComponentSelection(componentSelection);

  const encodedStartSet = startSet.join(ID_SEPARATOR);
  const encodedStartQuery = startQuery
    ? encodeSearchQuery(
        queryBuilderOperations.getSubqueryFromQuery(startQuery),
        tokenToHexIndex
      )
    : '';
  const tokensWithDefaults = Object.keys(tokenToHexIndex);
  const mapDirection = {
    outgoing: getPaddedKey(0, tokensWithDefaults),
    incoming: getPaddedKey(1, tokensWithDefaults),
  };
  const propertyValuesToHexIndexes = getPropertyValuesToHexIndexes(
    tokenToHexIndex,
    mapDirection
  );

  const encodedTriples = paths
    .map(path => path.map(propertyValuesToHexIndexes).join(''))
    .join(PATH_SEPARATOR);

  const encodedFilters = encodeFilters(filters ?? {}, tokenToHexIndex);

  const encodedCollapsedPaths = encodePathCollapsingRules(
    pathCollapsingRules ?? [],
    tokenToHexIndex,
    mapDirection
  );

  return [
    Number(isHidden),
    Number(traversal.componentSelection.startContextSelectionType),
    traversalId,
    encodePathMatching(traversal.pathMatching),
    encodedStartSet,
    encodedStartQuery,
    encodedTriples,
    encodedFilters,
    encodedCollapsedPaths,
  ].join(TRAVERSAL_SEPARATOR);
};

const getTokensFromPaths = (paths: DirectedTripleWithFilters[][]) =>
  paths.flatMap(path =>
    path.flatMap(
      ({
        sourceType,
        targetType,
        referenceType,
        sourceFilter,
        targetFilter,
        referenceFilter,
      }) =>
        [
          sourceType,
          targetType,
          referenceType,
          sourceFilter ? stripFilterPrefix(sourceFilter) : undefined,
          targetFilter ? stripFilterPrefix(targetFilter) : undefined,
          referenceFilter ? stripFilterPrefix(referenceFilter) : undefined,
        ].filter(tokenIsString)
    )
  );

const getTokensFromFilters = (filters: ViewpointBuilderFilters) =>
  Object.values(filters).flatMap(({ filterId, condition, rules }) => [
    stripFilterPrefix(filterId),
    condition,
    ...rules.flatMap(({ id, field, input, type, value, operator }) => [
      id,
      field,
      input,
      type,
      value,
      operator,
    ]),
  ]);

const getTokensFromCollapsedPaths = (rules: PathCollapsingRule[]) => {
  if (!rules) return [];

  return rules
    .flatMap(({ displayText, referenceStyle }) => [
      // all path tokens should already be added because a collapsed path
      // cannot exist without being part of an existing path
      // isActive is a bool, and included in defaultTokens,
      displayText,
      referenceStyle.color,
      referenceStyle.line,
      referenceStyle.lineBeginning,
      referenceStyle.lineEnding,
      referenceStyle.svgStyle ?? '',
    ])
    .filter(ExcludeFalsy);
};
const getPropertyValuesToHexIndexes = (
  tokenToHexIndex: Record<Token, HexIndex>,
  mapDirection: {
    outgoing: HexIndex;
    incoming: HexIndex;
  }
) => {
  const propertyValuesToHexIndexes = ({
    sourceType,
    targetType,
    referenceType,
    direction,
    qualifier,
    sourceFilter,
    targetFilter,
    referenceFilter,
  }: DirectedTripleWithFilters) => {
    const setFilterIdsAsDigit = getSetFilterIdsAsDigit(
      sourceFilter,
      targetFilter,
      referenceFilter
    );
    const filterIndexes = getSetFiltersAsHexIndexes(
      tokenToHexIndex,
      sourceFilter,
      targetFilter,
      referenceFilter
    );
    return [
      setFilterIdsAsDigit,
      encodeQualifier(qualifier ?? 'optional'),
      mapDirection[direction],
      tokenToHexIndex[sourceType],
      tokenToHexIndex[referenceType],
      tokenToHexIndex[targetType],
      ...filterIndexes,
    ].join('');
  };
  return propertyValuesToHexIndexes;
};

const encodePathCollapsingRules = (
  rules: PathCollapsingRule[],
  tokenToHexIndex: Record<string, string>,
  mapDirection: {
    outgoing: HexIndex;
    incoming: HexIndex;
  }
) => {
  if (!rules?.length) {
    return '';
  }
  const propertyValuesToHexIndexes = getPropertyValuesToHexIndexes(
    tokenToHexIndex,
    mapDirection
  );
  return rules
    .map(rule => {
      const { path, displayText, referenceStyle, isActive } = rule;
      const encodedTriples = path!.map(propertyValuesToHexIndexes).join('');

      const { color, line, lineBeginning, lineEnding, svgStyle } =
        referenceStyle;
      return [
        tokenToHexIndex[String(isActive)],
        tokenToHexIndex[displayText],
        tokenToHexIndex[color],
        tokenToHexIndex[line],
        tokenToHexIndex[lineBeginning],
        tokenToHexIndex[lineEnding],
        tokenToHexIndex[svgStyle || 'null'], // needs a non-empty string value for the length to be consistent
        encodedTriples,
      ].join('');
    })
    .join(COLLAPSED_PATH_SEPARATOR);
};

const getSetFilterIdsAsDigit = (
  sourceFilterId?: string,
  targetFilterId?: string,
  referenceFilterId?: string
) =>
  // This function generates a binary representation of the presence of filters.
  // Each filter (sourceFilterId, targetFilterId, referenceFilterId) is mapped
  // to a binary digit. '1' indicates the filter is set, '0' indicates it is not
  // set. The resulting array of binary digits is joined into a string and then
  // parsed into a decimal number. For example, if only targetFilterId is set,
  // the resulting binary string would be '010', which is parsed into the
  // decimal number 2.
  Number.parseInt(
    [sourceFilterId, targetFilterId, referenceFilterId]
      .map(token => (token ? '1' : '0'))
      .join(''),
    2
  );

const getSetFiltersAsHexIndexes = (
  tokenToHexIndex: Record<string, string>,
  sourceFilter?: string,
  targetFilter?: string,
  referenceFilter?: string
) =>
  [sourceFilter, targetFilter, referenceFilter]
    .map(filter => (filter ? tokenToHexIndex[stripFilterPrefix(filter)] : null))
    .filter(ExcludeFalsy);

const encodeFilters = (
  filters: ViewpointBuilderFilters,
  tokenToHexIndex: Record<Token, HexIndex>
) =>
  Object.values(filters)
    .map(({ filterId, condition, rules }) => {
      const encodedRules = rules
        .map(({ id, field, input, type, value, operator }) =>
          [
            tokenToHexIndex[id],
            tokenToHexIndex[field],
            tokenToHexIndex[input],
            tokenToHexIndex[type],
            tokenToHexIndex[String(value)],
            tokenToHexIndex[operator],
          ].join('')
        )
        .join('');
      return [
        tokenToHexIndex[stripFilterPrefix(filterId)],
        tokenToHexIndex[condition],
        encodedRules,
      ].join('');
    })
    .join(FILTER_SEPARATOR);

const stripFilterPrefix = (filterId: string) =>
  filterId.replace(FILTER_ID_PREFIX, '');

const getTokenIndex = (tokens: string[]) =>
  Object.fromEntries(
    tokens.map((token, index) => [
      getPaddedKey(index, tokens),
      token === 'undefined' ? undefined : token,
    ])
  );

export const encodeSearch = (
  { isHidden, data }: SearchLoadedStateParams,
  tokenToHexIndex: Record<string, string>
) => {
  const { startSet, startQuery } =
    getStartSetAndStartQueryFromComponentSelection(data.componentSelection);

  return [
    Number(isHidden ?? 0),
    Number(data.componentSelection.startContextSelectionType),
    startSet?.join(ID_SEPARATOR) ?? '',
    startQuery
      ? encodeSearchQuery(
          queryBuilderOperations.getSubqueryFromQuery(startQuery),
          tokenToHexIndex
        )
      : '',
  ].join(SEARCH_SEPARATOR);
};

/**
 * Encodes the created items loaded state into a string in the same format as search loaded state does.
 */
export const encodeCreatedItemsLoadedState = ({
  isHidden,
  data,
}: CreatedItemsLoadedStateParams) => {
  const { componentIds, referenceIds } = data;
  return [
    Number(isHidden),
    componentIds?.join(ID_SEPARATOR) ?? '',
    referenceIds?.join(ID_SEPARATOR) ?? '',
  ].join(SEARCH_SEPARATOR);
};

export const encodeSearchQuery = (
  search: QueryBuilderRow,
  tokenToHexIndex: Record<string, string>
): string => {
  if ('condition' in search) {
    const encodedRules = search.rules
      .map(rule => encodeSearchQuery(rule, tokenToHexIndex))
      .join('');
    return search.condition === 'AND'
      ? `${OPEN_AND_RULES}${encodedRules}${CLOSE_AND_RULES}`
      : `${OPEN_OR_RULES}${encodedRules}${CLOSE_OR_RULES}`;
  }
  return [
    tokenToHexIndex[search.id],
    tokenToHexIndex[search.type],
    tokenToHexIndex[search.input],
    tokenToHexIndex[search.field],
    tokenToHexIndex[search.operator],
    tokenToHexIndex[encodeSearchValue(search.value)],
    search.targetEntityType ? tokenToHexIndex[search.targetEntityType] : '',
    QUERY_RULE_TERMINATOR,
  ].join('');
};

const encodeSearchValue = (value: QueryBuilderRuleValue): string | number => {
  if (value === null) return 'null';
  if (typeof value === 'boolean') return value.toString();
  if (Array.isArray(value)) return 'null'; // this value is not supported by the advanced search query in Viewpoint builder
  return value;
};

const getAllTokens = (search: QueryBuilderRow, result = []): any[] =>
  'rules' in search
    ? search.rules.flatMap(rule => getAllTokens(rule, result))
    : Object.values(search);

const getPaddedKey = (() => {
  const maxSizes: Record<number, number> = {};

  return (n: number, tokens: (string | undefined)[]) => {
    let maxSize = maxSizes[tokens.length - 1];
    if (!maxSize) {
      maxSizes[tokens.length - 1] = getMaxSizeFromTokens(tokens);
      maxSize = maxSizes[tokens.length - 1];
    }
    return n.toString(BASE).padStart(maxSize, '0');
  };
})();

const getMaxSizeFromTokens = (tokens: (string | undefined)[]) =>
  (tokens.length - 1).toString(BASE).length;

export const getTokensFromLoadedState = (states: LoadedStateParams[]) => {
  const tokens = states.flatMap(state => {
    if (isAnyTraversalLoadedState(state)) {
      return getTokensFromTraversal(state);
    }
    if (isCreatedItemsLoadedState(state)) {
      return getTokensFromCreatedItemsLoadedState(state);
    }
    return getTokensFromSearch(state);
  });

  const tokensWithoutDefaults = uniq(tokens.map(String)).filter(
    token => !defaultKeys.includes(token)
  );
  // The default keys are used for filters and advanced queries, they have
  // basically the same format, they are mostly operators and default entity
  // properties.
  const tokensWithDefaults = [...defaultKeys, ...tokensWithoutDefaults];
  const tokenToHexIndex = Object.fromEntries(
    tokensWithDefaults.map((token, index) => [
      token,
      getPaddedKey(index, tokensWithDefaults),
    ])
  );

  return { tokenToHexIndex, tokensWithoutDefaults };
};

const getTokensFromTraversal = ({
  data: traversal,
}: TraversalLoadedStateParams | TraversalCreatedInViewLoadedStateParams) => {
  const { paths, filters, componentSelection, pathCollapsingRules } = traversal;
  const startQuery = isQueryComponentSelection(componentSelection)
    ? componentSelection.startQuery
    : null;
  const pathTokens = getTokensFromPaths(paths);
  const filterTokens = getTokensFromFilters(filters ?? {});
  const collapsedPathTokens = getTokensFromCollapsedPaths(
    pathCollapsingRules ?? []
  );
  const queryTokens = startQuery ? getAllTokens(startQuery) : [];

  return [
    ...pathTokens,
    ...filterTokens,
    ...queryTokens,
    ...collapsedPathTokens,
  ];
};

const getTokensFromSearch = ({
  data: { componentSelection },
}: SearchLoadedStateParams) =>
  isQueryComponentSelection(componentSelection)
    ? getAllTokens(componentSelection.startQuery)
    : [];

const getTokensFromCreatedItemsLoadedState = (
  _: CreatedItemsLoadedStateParams
) => [];

const getEncodedTokens = (tokens: string[]) =>
  tokens.map(token => encodeURIComponent(token)).join(TOKEN_SEPARATOR);

export const getTokenIndexFromEncodedTokens = (encodedTokens: string) => {
  const decodedTokens = encodedTokens
    .split(TOKEN_SEPARATOR)
    .map(token => decodeURIComponent(token));

  const tokens = [...defaultKeys, ...decodedTokens];

  return getTokenIndex(tokens);
};

export const encodeLoadedStateV7 = (states: LoadedStateParams[]): string => {
  if (states.length === 0) return '';

  const { tokenToHexIndex, tokensWithoutDefaults } =
    getTokensFromLoadedState(states);

  const encodedTokens = getEncodedTokens(tokensWithoutDefaults);

  return [
    'v7',
    ...states.map(state => {
      if (isAnyTraversalLoadedState(state))
        return `${encodeType(state.type)}${encodeTraversal(
          state,
          tokenToHexIndex
        )}`;

      if (isCreatedItemsLoadedState(state)) {
        return `${encodeType(state.type)}${encodeCreatedItemsLoadedState(
          state
        )}`;
      }

      return `${encodeType(state.type)}${encodeSearch(state, tokenToHexIndex)}`;
    }),
    encodedTokens,
  ].join(STATE_SEPARATOR);
};
