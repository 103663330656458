import { FetchSuggestionFn, LabelAndValue } from 'search/AdvancedSearch/types';
import { AuditLogRequest } from './types';
import { logError } from '@ardoq/logging';
import { SuggestionType, auditLogApi } from '@ardoq/api';
import { Entity, Named } from '@ardoq/api-types';
import { isArdoqError } from '@ardoq/common-helpers';

type NamedEntity = Entity & Named;

type LabeledEntity = Entity & { label: string };

const mapIdName = ({ name, _id }: NamedEntity): LabelAndValue => ({
  label: name,
  value: _id,
});

const mapString = ({ name }: Named): LabelAndValue => ({
  label: name,
  value: name,
});

const mapIdLabel = ({ label, _id }: LabeledEntity): LabelAndValue => ({
  label,
  value: _id,
});

const fetchSuggestions = async <T>(
  suggestionType: SuggestionType,
  name: string,
  state: AuditLogRequest
) => {
  const results = await auditLogApi.fetchSuggestions<T>(
    suggestionType,
    name,
    state
  );
  if (!isArdoqError(results)) {
    return results;
  }
  logError(results, 'Loading audit log suggestions failed');
  return [];
};

const fetchAndMapSuggestions =
  <T>(
    suggestionType: SuggestionType,
    mapFn: (result: T) => LabelAndValue,
    state: AuditLogRequest
  ): FetchSuggestionFn =>
  async (name: string) =>
    (await fetchSuggestions<T>(suggestionType, name, state)).map(mapFn);

export const getLoadComponentSuggestions = (state: AuditLogRequest) =>
  fetchAndMapSuggestions<NamedEntity>('component', mapIdName, state);

export const getLoadReferenceSuggestions = (state: AuditLogRequest) =>
  fetchAndMapSuggestions<NamedEntity>('reference', mapIdName, state);

export const getLoadFieldSuggestions = (state: AuditLogRequest) =>
  fetchAndMapSuggestions<LabeledEntity>('field', mapIdLabel, state);

export const getLoadWorkspaceSuggestions = (state: AuditLogRequest) =>
  fetchAndMapSuggestions<NamedEntity>('workspace', mapIdName, state);

export const getLoadComponentTypeNameSuggestions = (state: AuditLogRequest) =>
  fetchAndMapSuggestions<Named>('componentType', mapString, state);

export const getLoadReferenceTypeNameSuggestions = (state: AuditLogRequest) =>
  fetchAndMapSuggestions<Named>('referenceType', mapString, state);
