import { Select } from '@ardoq/select';
import type { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { Maybe } from '@ardoq/common-helpers';
import { byLocaleField } from 'integrations/common/utils/common';

type ConfigsSelectorProps = {
  configs: SavedTransferConfig[];
  onSelect: (id: string | null) => void;
  selectedConfigId: Maybe<SavedTransferConfig['_id']>;
  label?: string;
  isOptional?: boolean;
  placeholder?: string;
};
export const ConfigsSelector = ({
  selectedConfigId,
  configs,
  onSelect,
  label,
  isOptional = false,
  placeholder = 'Type and select a configuration',
}: ConfigsSelectorProps) => {
  const options = configs
    .map(config => ({
      label: config.name,
      value: config._id,
    }))
    .sort(byLocaleField('label'));

  // TODO: add loading state
  return (
    <Select
      options={options}
      placeholder={placeholder}
      onValueChange={onSelect}
      value={selectedConfigId}
      label={label}
      isOptional={isOptional}
    />
  );
};
