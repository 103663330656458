import { TopBarButton } from '../atoms';
import { Icon, IconName } from '@ardoq/icons';
import { toggleVisualDiff } from './utils';
import { isInScopeDiffMode, isLoadingScope } from 'scope/scopeDiff';
import { connect } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { currentScenario$ } from 'currentScenario$';
import { APIScenarioAttributes } from '@ardoq/api-types';
import { POPOVER_ID_ATTR } from '@ardoq/popovers';
import { SCENARIO_OUT_OF_SYNC_POPOVER_ID } from 'app/popovers/ScenarioOutOfSyncPopover';

type CurrentScenarioProps = {
  currentScenario: APIScenarioAttributes | null;
};

type NavigatorTopBarShowDiffButtonProps = CurrentScenarioProps & {
  isDiffModeAvailable?: boolean;
  isScenarioInSync: boolean;
};

type GetTooltipOptions = {
  isScenarioInSync: boolean;
  isDiffModeAvailable: boolean;
  isVisualDiffActive: boolean;
};

const getTooltip = ({
  isScenarioInSync,
  isDiffModeAvailable,
  isVisualDiffActive,
}: GetTooltipOptions) => {
  if (!isScenarioInSync) return null;
  if (!isDiffModeAvailable)
    return 'Please switch to Block Diagram to use visual diff';
  if (isVisualDiffActive) return 'Close visual diff';
  return 'Open visual diff';
};

const NavigatorTopBarShowDiffButton = ({
  isDiffModeAvailable = false,
  isScenarioInSync,
}: NavigatorTopBarShowDiffButtonProps & CurrentScenarioProps) => {
  const isVisualDiffActive = isInScopeDiffMode() || isLoadingScope();
  const tooltip = getTooltip({
    isScenarioInSync,
    isDiffModeAvailable,
    isVisualDiffActive,
  });

  return (
    <TopBarButton
      $isActive={isVisualDiffActive}
      disabled={!isDiffModeAvailable || !isScenarioInSync}
      data-tooltip-text={tooltip}
      onClick={() => {
        toggleVisualDiff({ clickSource: 'topbar' });
      }}
      {...{ [POPOVER_ID_ATTR]: SCENARIO_OUT_OF_SYNC_POPOVER_ID }}
      $isScenarioMode
    >
      <Icon
        iconName={isVisualDiffActive ? IconName.DIFF : IconName.DIFF_EMPTY}
      />
    </TopBarButton>
  );
};

export default connect(
  NavigatorTopBarShowDiffButton,
  currentScenario$.pipe(map(currentScenario => ({ currentScenario })))
);
