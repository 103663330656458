import { caption, header2, text2 } from '@ardoq/typography';
import styled from 'styled-components';

export const CaptionText = styled.div`
  ${caption};
`;

export const Header2 = styled.h2`
  ${header2};
  margin: 0;
`;

export const Text2 = styled.div`
  ${text2};
`;
