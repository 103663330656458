import styled from 'styled-components';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { header3, header4 } from '@ardoq/typography';
import { ButtonGroup } from '@ardoq/button';
import { Field, LABEL_SELECTOR } from '@ardoq/forms';

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${s8};
  padding: ${s16};
  border-bottom: 1px solid ${colors.grey90};
  ${header3}
`;

export const SectionsContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

export const InputsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${s16};
  padding: ${s16};
`;

export const PanelFooter = styled(ButtonGroup)`
  border-top: 1px solid ${colors.grey90};
`;

export const StyledField = styled(Field)`
  ${LABEL_SELECTOR} {
    ${header4};
  }
`;
