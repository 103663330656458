import {
  StatusTable,
  StatusTableProps,
} from 'integrations/common/components/transfer/StatusTable';
import {
  DryRunStatus,
  DryRunStatusProps,
} from 'integrations/common/components/transfer/DryRunStatus';
import { PreviewMatrix, PreviewMatrixProps } from '@ardoq/integrations';
import styled from 'styled-components';
import { Space } from '@ardoq/style-helpers';
import { s64 } from '@ardoq/design-tokens';
import { testId } from 'integrations/common/testUtils/testId';

export type OverviewProps = StatusTableProps &
  DryRunStatusProps &
  PreviewMatrixProps & {
    deleteMatrixEnabled?: boolean;
  };

export const Overview = ({
  status,
  tables,
  totals,
  tableHeader,
  dryRunAdditionalMessage,
  dryRunAdditionalPopoverContent,
  dryRunSummaryWarning,
  dryRunSummaryError,
  onConfigure,
  deleteMatrixEnabled,
  transferDirection,
  preview,
}: OverviewProps) => {
  return (
    <Container {...testId('import-overview-container')}>
      <DryRunStatus
        status={status}
        dryRunAdditionalMessage={dryRunAdditionalMessage}
        dryRunAdditionalPopoverContent={dryRunAdditionalPopoverContent}
        transferDirection={transferDirection}
        dryRunSummaryWarning={dryRunSummaryWarning}
        dryRunSummaryError={dryRunSummaryError}
      />
      <PreviewMatrix
        totals={totals}
        preview={preview}
        deleteMatrixEnabled={deleteMatrixEnabled}
        transferDirection={transferDirection}
      />
      <StatusTable
        tables={tables}
        onConfigure={onConfigure}
        tableHeader={tableHeader}
      />
    </Container>
  );
};

const Container = styled(Space).attrs({ $isVertical: true })`
  gap: ${s64};
`;
