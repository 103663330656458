import LayoutBox from './LayoutBox';
import RootLayoutBox from './RootLayoutBox';
import { HORIZONTAL_INDENT } from '../utils/consts';
import { RootLayoutBoxModel, SetClassNames } from '../types';
import { NodeModel } from './types';
import type Tree from './tree';

/** gets a list of nodes not included in the filter context and having no children which are included in the filter context. */
export const getHiddenNodes = (tree: Tree, showFilteredComponents?: boolean) =>
  showFilteredComponents
    ? new Set<NodeModel>()
    : _getHiddenNodes(tree).hiddenNodes;

const _getHiddenNodes = (
  node: Tree | NodeModel,
  hiddenNodes = new Set<NodeModel>()
): { visibleNodesCount: number; hiddenNodes: Set<NodeModel> } => {
  let visibleNodesCount = node
    .getChildren()
    .reduce((count: number, child: NodeModel) => {
      const { visibleNodesCount } = _getHiddenNodes(child, hiddenNodes);
      return count + visibleNodesCount;
    }, 0);
  if (visibleNodesCount === 0 && !node.isIncludedInContextByFilter()) {
    hiddenNodes.add(node as NodeModel);
  } else {
    visibleNodesCount += 1;
  }
  return { visibleNodesCount, hiddenNodes };
};

const _layout = (
  rootLayoutBox: RootLayoutBoxModel,
  node: NodeModel | Tree,
  height: number,
  scrollTop: number,
  layoutNodeSet = new Set<string>(),
  hiddenNodes: Set<NodeModel>,
  setClassNames?: ReturnType<SetClassNames>,
  indent = 0
) => {
  node
    .getChildren()
    .filter(child => !hiddenNodes.has(child))
    .forEach(child => {
      const itemHeight = child.getItemHeight();
      const currentTop = rootLayoutBox.scrollHeight;
      // Compare to a virtual view box with three times the size of the actual
      // view box, basically the actual view box extended with the full height
      // at the top and the bottom.
      const viewBoxTop = scrollTop - height;
      const viewBoxBottom = scrollTop + 2 * height;
      const itemTop = currentTop;
      const itemBottom = currentTop + itemHeight;
      if (
        (itemBottom > viewBoxTop && itemBottom < viewBoxBottom) ||
        (itemTop > viewBoxTop && itemTop < viewBoxBottom)
      ) {
        rootLayoutBox.children.push(
          new LayoutBox(
            child,
            currentTop,
            indent * HORIZONTAL_INDENT,
            setClassNames
          )
        );
      }
      if (layoutNodeSet.has(child.id)) {
        rootLayoutBox.layoutMap.set(
          child.id,
          new LayoutBox(
            child,
            currentTop,
            indent * HORIZONTAL_INDENT,
            setClassNames
          )
        );
      }
      rootLayoutBox.scrollHeight += itemHeight;
      if (!child.isCollapsed) {
        _layout(
          rootLayoutBox,
          child,
          height,
          scrollTop,
          layoutNodeSet,
          hiddenNodes,
          setClassNames,
          indent + 1
        );
      }
    });
  return rootLayoutBox;
};

const layout = (
  tree: Tree,
  height: number,
  scrollTop: number,
  layoutNodeSet?: Set<string>,
  setClassNames?: ReturnType<SetClassNames>,
  showFilteredComponents?: boolean,
  hiddenNodes: Set<NodeModel> = getHiddenNodes(tree, showFilteredComponents)
) => {
  const rootLayoutBox = new RootLayoutBox(height, scrollTop);
  tree.cacheHiddenNodes(hiddenNodes);
  return _layout(
    rootLayoutBox,
    tree,
    height,
    scrollTop,
    layoutNodeSet,
    hiddenNodes,
    setClassNames
  );
};
export default layout;
