import { BroadcastSchedule, BroadcastInterval } from '@ardoq/api-types';
import { isRepeatingSchedule } from 'broadcasts/utils';
import { pluralize } from '@ardoq/common-helpers';

type IntervalCellProps = {
  readonly schedule: BroadcastSchedule;
};

type RepeatingScheduleCellProps = BroadcastInterval;

const RepeatingScheduleCell = ({
  intervalValue,
  intervalType,
}: RepeatingScheduleCellProps) => {
  return (
    <div>
      {[
        'Every',
        intervalValue !== 1 ? intervalValue : null,
        pluralize(intervalType, intervalValue),
      ]
        .filter(Boolean)
        .join(' ')}
    </div>
  );
};

const OneOffScheduleCell = () => {
  return <>Single broadcast</>;
};

const IntervalCell = ({ schedule }: IntervalCellProps) => {
  return isRepeatingSchedule(schedule) ? (
    <RepeatingScheduleCell
      intervalValue={schedule.interval.intervalValue}
      intervalType={schedule.interval.intervalType}
    />
  ) : (
    <OneOffScheduleCell />
  );
};

export default IntervalCell;
