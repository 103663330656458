import { IconButton } from '@ardoq/button';
import { colors, s16, s32, s8 } from '@ardoq/design-tokens';
import { IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { header4 } from '@ardoq/typography';
import styled from 'styled-components';
import BestPracticeAssistant from './BestPracticeAssistant';

type ChatTitleProps = {
  minimize: () => void;
  close: () => void;
};

export const ChatTitle = ({ minimize, close }: ChatTitleProps) => {
  return (
    <div>
      <Container>
        <Title>
          <BestPracticeAssistant logoSize={s32} />
        </Title>
        <FlexBox gap="xsmall">
          <IconButton iconName={IconName.REMOVE} onClick={minimize} />
          <IconButton iconName={IconName.CLOSE} onClick={close} />
        </FlexBox>
      </Container>
    </div>
  );
};

const Container = styled.div`
  height: 48px;
  width: 100%;
  justify-content: space-between;
  background-color: ${colors.white};
  align-items: center;
  padding-left: ${s16};
  padding-right: ${s8};
  border-bottom: 1px solid ${colors.grey80};
  display: flex;
`;

const Title = styled.div`
  ${header4};
  color: ${colors.black};
  display: flex;
  gap: ${s16};
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;
