import { actionCreator } from '@ardoq/rxbeach';
import { SelectedViewOption } from './types';

export const setSelectedView = actionCreator<SelectedViewOption>(
  '[viewpointMetaInfo] SET_SELECTED_VIEW'
);

export const resetSelectedView = actionCreator(
  '[viewpointMetaInfo] RESET_SELECTED_VIEW'
);
