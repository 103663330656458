import { ViewIds } from '@ardoq/api-types';
import { DEGREES_OF_RELATIONSHIP } from '@ardoq/global-consts';
import { settingsBarConsts } from '@ardoq/settings-bar';

export const DISCONTINUED_VIEW_SETTINGS = new Map([
  [
    ViewIds.RELATIONSHIPS,
    ['includeParents', 'straightLines', 'gravity', 'distance'],
  ],
  [ViewIds.DEPMAP, ['showRefLabels', 'colorByReference']],
]);

type LeftMenuConfig = {
  id: string;
  label: string;
  options?: LeftMenuConfigOption[];
  value?: any;
};
type LeftMenuConfigOption = {
  name: string;
  label: string;
  value: any;
};

export const getLeftMenuConfig = (
  viewId: ViewIds,
  viewstate: any
): LeftMenuConfig[] => {
  switch (viewId) {
    case ViewIds.RELATIONSHIPS:
      return [
        {
          id: 'includeParents',
          label: 'Include parents',
        },
        {
          id: 'straightLines',
          label: 'Curved lines',
        },
        {
          id: settingsBarConsts.DEGREES_OF_RELATIONSHIP_SLIDER_MENU_ITEM_ID,
          label: DEGREES_OF_RELATIONSHIP,
          options: [
            {
              name: 'degreesOfRelationsship',
              label: DEGREES_OF_RELATIONSHIP,
              value: viewstate.degreesOfRelationsship,
            },
          ],
        },
        {
          id: 'slider-distance',
          label: 'Distance',
          options: [
            {
              name: 'distance',
              label: 'Distance',
              value: viewstate.distance,
            },
          ],
        },
        {
          id: 'slider-gravity',
          label: 'Gravity',
          options: [
            {
              name: 'gravity',
              label: 'Gravity',
              value: viewstate.gravity,
            },
          ],
        },
        {
          id: 'freeze',
          label: 'Freeze',
        },
      ];
    case ViewIds.DEPMAP: {
      return [
        {
          id: 'colorByReference',
          label: 'Color by reference',
        },
        {
          id: 'includeRefParent',
          label: 'Include reference parents',
          value: viewstate.includeRefParent,
        },
        {
          id: 'showVertical',
          label: 'Show Vertical',
          value: viewstate.showVertical,
        },
        {
          id: 'showRefLabels',
          label: 'Show reference labels',
        },
      ];
    }

    default:
      return [];
  }
};

export const DISCONTINUED_SETTING_POPOVER_TEXT =
  'This view setting is discontinued and cannot be recreated in the target view.';
