import { MetaModelTriple } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const showMetaModel = actionCreator('[meta model] SHOW_META_MODEL');

export type RequestLoadMetaModelPayload = {
  // When loading an existing viewpoint, it's essential to include all its
  // triples in the meta model.
  // To achieve this, we pass the viewpoint's triples to the meta model load
  // action, ensuring they are all incorporated into the meta model.
  currentTriples: MetaModelTriple[];
};

export const requestLoadMetaModel = actionCreator<RequestLoadMetaModelPayload>(
  '[meta model] REQUEST_LOAD_META_MODEL'
);
