import { isJust, Maybe } from '@ardoq/common-helpers';
import { filter, firstValueFrom, map, Observable } from 'rxjs';
import { ArdoqEvent, isBundleCopy } from 'sync/types';

type BundleCopyAsyncResult = {
  successful: boolean;
  errorMessage?: string;
};

const parseWebSocketEvent = (
  event: ArdoqEvent<unknown>
): Maybe<BundleCopyAsyncResult> => {
  if (
    event.data &&
    typeof event.data === 'object' &&
    'status' in event.data &&
    typeof event.data.status === 'string'
  ) {
    if (event.data.status === 'success') {
      return { successful: true };
    } else if (
      event.data.status === 'warn' &&
      'message' in event.data &&
      event.data.message &&
      typeof event.data.message === 'string'
    ) {
      return {
        successful: false,
        errorMessage: event.data.message,
      };
    }
    return {
      successful: false,
      errorMessage:
        'An unexpected error occurred, please report this issue to support@ardoq.com.',
    };
  }
  return null;
};

export const nextBundleCopyResult = (
  websocket: Observable<ArdoqEvent<unknown>>
): Promise<BundleCopyAsyncResult> =>
  firstValueFrom(
    websocket.pipe(
      filter(isBundleCopy),
      map(parseWebSocketEvent),
      filter(isJust)
    )
  );
