import { NavigatorLayoutState } from '../types';

const setDataErrorMessage = (
  state: NavigatorLayoutState,
  dataErrorMessage: string | null
): NavigatorLayoutState => ({
  ...state,
  dataErrorMessage,
});

const setDataLoadingMessage = (
  state: NavigatorLayoutState,
  dataLoadingMessage: string | null
): NavigatorLayoutState => ({
  ...state,
  dataLoadingMessage,
});

export const navigatorOperations = {
  setDataErrorMessage,
  setDataLoadingMessage,
};
