import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { filter } from 'rxjs';
import { tap } from 'rxjs/operators';
import { withLatestFrom } from 'rxjs';
import { setVisibleIntegration } from 'integrations/actions';
import integrationViewState$ from 'integrations/integrationViewState$';
import { navigateToPath } from './actions';
import { configurationState$ } from '../streams/configurationState/configurationState$';
import { isPathReady } from './utils';
import { trackITPediaEvent } from '../streams/tracking/actions';
import { openHome } from '../../../components/AppMainSidebar/utils';
import { OverviewRoute } from 'integrations/common/navigation/types';
import currentUser$ from 'streams/currentUser/currentUser$';
import {
  getIntegrationById,
  getIntegrationDisabledOptions,
} from 'integrations/allIntegrations';

const handleNavigateToPath = routine(
  ofType(navigateToPath),
  extractPayload(),
  withLatestFrom(integrationViewState$),
  tap(([path, { selectedIntegrationId }]) => {
    dispatchAction(setVisibleIntegration({ id: selectedIntegrationId, path }));
  })
);

const handleNavigateToITPedia = routine(
  ofType(setVisibleIntegration),
  extractPayload(),
  filter(({ id }) => id === 'it-pedia'),
  withLatestFrom(integrationViewState$, configurationState$, currentUser$),
  tap(([{ path }, { selectedIntegrationId }, config, currentUser]) => {
    /*
     * permission check
     */
    const integration = getIntegrationById('it-pedia');

    const isDisabled =
      !integration ||
      getIntegrationDisabledOptions(integration, currentUser)?.isDisabled;

    if (isDisabled) {
      openHome();
    }

    /*
     * path readiness check
     */
    if (!path) {
      return;
    }
    if (!isPathReady(path, { config })) {
      dispatchAction(
        setVisibleIntegration({
          id: selectedIntegrationId,
          path: OverviewRoute.SCHEDULES,
        })
      ); // integration home
    }
    dispatchAction(
      trackITPediaEvent({
        name: 'NAVIGATED_TO_PATH',
        metadata: {
          path,
        },
      })
    );
  })
);

export default [handleNavigateToPath, handleNavigateToITPedia];
