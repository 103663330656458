import { Text } from '@ardoq/typography';
import { Stack } from '@ardoq/layout';

const EmptyContent = () => (
  <Stack gap="small">
    <Text variant="text1Italic">
      This broadcast report is not yet available.
    </Text>
    <Text variant="text1Italic">
      Your report will show here after your broadcast is delivered.
    </Text>
  </Stack>
);

export default EmptyContent;
