import { objectContextMenu } from './objectContextMenu';
import { Features, hasFeature } from '@ardoq/features';
import { FunctionComponent, PropsWithChildren, useCallback } from 'react';
import {
  scenarioRelatedContextMenu,
  scenarioRelatedContextMenuName,
} from 'appContainer/MainAppModule/MainAppModuleSidebar/scenarioRelated/navigator/ScenarioRelatedContextMenu';
import {
  pagesContextMenu,
  pagesContextMenuName,
} from 'tabview/pagesView/contextMenu';
import {
  tagScapeContextMenu,
  tagScapeContextMenuName,
} from 'tabview/tagscape/contextMenu';
import {
  ContextMenuManager,
  ContextMenuManagerProps,
  ContextMenuState,
  OBJECT_CONTEXT_MENU_NAME,
  addContextMenu,
  removeContextMenu,
} from '@ardoq/context-menu';
import { useOnMount } from '@ardoq/hooks';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { contextMenuState$, setContextMenuState } from './contextMenuState$';

const GlobalContextMenu: FunctionComponent<
  PropsWithChildren<
    ContextMenuManagerProps & {
      menuState: ContextMenuState;
    }
  >
> = props => {
  useOnMount(() => {
    addContextMenu(OBJECT_CONTEXT_MENU_NAME, objectContextMenu);
    addContextMenu(pagesContextMenuName, pagesContextMenu);
    addContextMenu(tagScapeContextMenuName, tagScapeContextMenu);
    if (hasFeature(Features.SCENARIOS_BETA)) {
      addContextMenu(
        scenarioRelatedContextMenuName,
        scenarioRelatedContextMenu
      );
    }
    return () => {
      removeContextMenu(OBJECT_CONTEXT_MENU_NAME);
      removeContextMenu(pagesContextMenuName);
      removeContextMenu(tagScapeContextMenuName);
      removeContextMenu(scenarioRelatedContextMenuName);
    };
  });

  const onMenuClose = useCallback(() => {
    if (!props.menuState) {
      return;
    }

    dispatchAction(setContextMenuState(null));
  }, [props.menuState]);

  return (
    <ContextMenuManager
      outerMenuState={props.menuState}
      onClose={onMenuClose}
      isViewpointMode={props.isViewpointMode}
    />
  );
};

export default connect(GlobalContextMenu, contextMenuState$);
