import styled from 'styled-components';
import privileges from 'admin/privileges';
import { PrivilegeLabel } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';

const Image = styled.img`
  width: 448px;
  display: block;
`;

export const TargetedSurveyAudiencePopoverContent = () => {
  return (
    <div>
      How the different options will appear to your targeted audience(s)
      <ul>
        <li>
          Link to searchable overview page of relevant surveys
          <Image src="/img/broadcasts/example3.jpg" />
        </li>
        <li>
          List of direct links to each survey
          <Image src="/img/broadcasts/example4.jpg" />
        </li>
        {privileges.hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER) && (
          <>
            <li>
              Link to the &apos;My Tasks&apos; page in Discover
              <Image src="/img/broadcasts/example6.jpg" />
            </li>
            <li>
              List of direct links to each survey with dynamic visuals in
              Discover
              <Image
                src={
                  hasFeature(Features.COMPONENT_LANDING_PAGE)
                    ? '/img/broadcasts/example8.jpg'
                    : '/img/broadcasts/example5.jpg'
                }
              />
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export const TargetedMessageAudiencePopoverContent = () => {
  return (
    <div>
      How the different options will appear to your targeted audience(s)
      <ul>
        <li>
          Plain component list text
          <Image src="/img/broadcasts/example1.jpg" />
        </li>
        {privileges.hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER) && (
          <li>
            List of direct links with dynamic visuals in Discover
            <Image
              src={
                hasFeature(Features.COMPONENT_LANDING_PAGE)
                  ? '/img/broadcasts/example7.jpg'
                  : '/img/broadcasts/example2.jpg'
              }
            />
          </li>
        )}
      </ul>
    </div>
  );
};

export const GeneralAudiencePopoverContent = () => {
  return (
    <div>
      How the different options will appear to your targeted audience(s)
      <ul>
        <li>
          Link to searchable overview page of relevant surveys
          <Image src="/img/broadcasts/example3.jpg" />
        </li>
      </ul>
    </div>
  );
};
