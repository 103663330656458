import {
  carry,
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  deleteExternalDocument,
  navigateToExternalDocument,
  openExternalDocument,
  setExternalDocument,
} from './actions';
import { handleError, integrationsLucidChartApi } from '@ardoq/api';
import { filter, from, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { showToast, ToastType } from '@ardoq/status-ui';
import { confirmDelete } from '@ardoq/modal';
import { openEmbedViewer } from 'integrations/lucidchart/actions';
import { trackEvent } from 'tracking/tracking';
import { ANALYTIC_EVENTS } from './const';
import { isArdoqError } from '@ardoq/common-helpers';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { branchedDeleteExternalDocument } from './utils';

const handleDeleteExternalDocument = routine(
  ofType(deleteExternalDocument),
  extractPayload(),
  carry(
    switchMap(({ displayText }) =>
      from(
        confirmDelete({
          modalSize: 2,
          title: 'Unlink document',
          cancelButtonTitle: 'Close',
          confirmButtonTitle: 'Unlink',
          text: (
            <>
              You are about to unlink <strong>{displayText}</strong> document
              from this component. Remember this action cannot be undone.
            </>
          ),
        })
      ).pipe(map(Boolean))
    )
  ),
  filter(([_, confirmed]) => confirmed),
  tap(([{ provider }]) => {
    trackEvent(ANALYTIC_EVENTS.DELETED_EXTERNAL_DOCUMENT, { provider });
  }),
  withLatestFrom(activeScenario$),
  switchMap(([[{ _id }], { scenarioId }]) =>
    branchedDeleteExternalDocument(_id, scenarioId)
  ),
  handleError(() => {
    showToast('Failed to unlink document', ToastType.INFO);
  })
);

const handleNavigateToExternalDocument = routine(
  ofType(navigateToExternalDocument),
  extractPayload(),
  carry(switchMap(integrationsLucidChartApi.settings)),
  handleError(() => {
    showToast('Failed navigating to External Document', ToastType.INFO);
  }),
  tap(([{ externalDocumentId, externalDocumentTitle }, response]) => {
    if (isArdoqError(response)) {
      showToast('Failed to load settings', ToastType.INFO);
      return;
    }

    const clientId = response.clientId;
    dispatchAction(
      requestShowAppModule({
        selectedModule: AppModules.EXTERNAL_DOCUMENT,
      })
    );
    dispatchAction(
      setExternalDocument({
        externalDocumentId,
        externalDocumentTitle: externalDocumentTitle,
        clientId,
      })
    );
  })
);

const handleOpenExternalDocument = routine(
  ofType(openExternalDocument),
  extractPayload(),
  tap(({ _id, provider, externalId, displayText }) => {
    if (provider === 'lucidchart') {
      dispatchAction(
        openEmbedViewer({ documentId: externalId, title: displayText })
      );
    }
    trackEvent(ANALYTIC_EVENTS.OPENED_EXTERNAL_DOCUMENT, { provider });
  })
);

export default collectRoutines(
  handleDeleteExternalDocument,
  handleOpenExternalDocument,
  handleNavigateToExternalDocument
);
