import { ArdoqId, GroupType } from '@ardoq/api-types';
import {
  isPerspectiveGroupingRuleWithWorkspace,
  PerspectiveTab,
} from '@ardoq/perspectives-sidebar';
import {
  PerspectiveGroupingRule,
  PerspectivesGroupsWorkspace,
} from '@ardoq/perspectives';
import { StatusType } from '@ardoq/status-ui';
import { exhaustiveCheck } from '@ardoq/common-helpers';
import { DatasetRelatedData, PerspectivesEditorState } from './types';

export const getAvailableGroupOptionsWorkspaces = (
  groupOptionsWorkspaces: PerspectivesGroupsWorkspace[],
  workspaceFilterIds: ArdoqId[]
): PerspectivesGroupsWorkspace[] =>
  workspaceFilterIds.length > 0
    ? groupOptionsWorkspaces.filter(({ id }) => workspaceFilterIds.includes(id))
    : groupOptionsWorkspaces;

const getGroupTabPopoverContent = (rulesCount: number) =>
  rulesCount === 1
    ? `This group can't be applied to the opened datasets.`
    : `Some groups can't be applied to the opened datasets.`;

type TabsData = {
  groupsTab: Pick<
    PerspectivesEditorState['groupsTab'],
    'groupingRules' | 'rulesIdsWithWarnings'
  >;
};
export const getTabStatusProps = (tabType: PerspectiveTab, state: TabsData) => {
  if (
    tabType !== PerspectiveTab.GROUPS ||
    state.groupsTab.rulesIdsWithWarnings.length === 0
  ) {
    return null;
  }

  const { groupingRules, rulesIdsWithWarnings } = state.groupsTab;
  const visibleRulesWithWarningCount = groupingRules.filter(rule =>
    rulesIdsWithWarnings.includes(rule.id)
  ).length;

  if (!visibleRulesWithWarningCount) {
    return null;
  }

  return {
    tagStatusType: StatusType.WARNING,
    tagPopoverContent: getGroupTabPopoverContent(groupingRules.length),
  };
};

export const getGroupingRuleIdsNotRelevantToDataset = (
  rules: PerspectiveGroupingRule[],
  datasetRelatedData?: DatasetRelatedData
) => {
  if (!datasetRelatedData) {
    return [];
  }

  return rules
    .filter(rule => {
      if (!isPerspectiveGroupingRuleWithWorkspace(rule)) {
        return false;
      }

      const options =
        datasetRelatedData.groupingOptionsByWorkspaceId[rule.workspaceId];
      if (!options) {
        return true;
      }

      switch (rule.type) {
        case GroupType.PARENT:
          return !options.componentTypeIds.has(rule.targetId);
        case GroupType.FIELD:
          return !options.componentFieldIds.has(rule.targetId);
        case GroupType.REFERENCE: {
          return !options.referenceTypeIds.has(rule.targetId);
        }
        case GroupType.TAG: {
          const targetId = rule.targetId;
          return !targetId || !options.tagIds.has(targetId);
        }
        default:
          exhaustiveCheck(rule);
      }

      return true;
    })
    .map(rule => rule.id);
};
