import { Box, FlexBox, Stack } from '@ardoq/layout';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { Text } from '@ardoq/typography';
import { Switch } from '@ardoq/forms';
import { PathCollapsingRule } from '@ardoq/api-types';
import { TextOverflow } from '@ardoq/popovers';

type ViewpointNavigatorCollapsedPathsProps = {
  pathCollapsingRules: PathCollapsingRule[];
  onToggleActive: (rule: PathCollapsingRule) => void;
  blockIndex: number;
};

const ViewpointNavigatorCollapsedPaths = ({
  pathCollapsingRules,
  onToggleActive,
  blockIndex,
}: ViewpointNavigatorCollapsedPathsProps) => (
  <Box backgroundColor="surfaceInfo" padding="small" borderRadius="r4">
    <Stack gap="small">
      <FlexBox align="center" gap="xsmall">
        <Icon
          iconSize={IconSize.SMALL}
          iconName={IconName.COLLAPSE_ALL}
          color={colors.blue80}
        />
        <Text variant="caption">Collapsed paths</Text>
      </FlexBox>
      <Box
        backgroundColor="surfaceDefault"
        paddingX="small"
        paddingY="xxsmall"
        borderRadius="r4"
      >
        <Stack gap="xsmall">
          {pathCollapsingRules.map((rule, index) => (
            <FlexBox key={index} align="center" justify="space-between">
              <TextOverflow
                style={{ fontSize: '12px' }}
                content={rule.displayText}
              >
                {rule.displayText}
              </TextOverflow>
              <FlexBox paddingLeft="xxsmall" align="center">
                <Switch
                  alignItemsCenter
                  name={`rule-${index}-${blockIndex}`}
                  isChecked={rule.isActive}
                  onChange={() => {
                    onToggleActive(rule);
                  }}
                />
              </FlexBox>
            </FlexBox>
          ))}
        </Stack>
      </Box>
    </Stack>
  </Box>
);

export default ViewpointNavigatorCollapsedPaths;
