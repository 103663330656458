import { ArdoqError } from '@ardoq/common-helpers';
import { alert } from '@ardoq/modal';

const UNKNOWN_ERROR = (
  <>
    <p>
      Please check the broadcast to see if there are any errors. We are sorry
      about the inconvenience.
    </p>
    <p>If the error persists, please contact support.</p>
  </>
);

const notifyBroadcastError = (error: ArdoqError) => {
  return alert({
    title: 'Broadcast could not be sent',
    text: error.userMessage || UNKNOWN_ERROR,
  });
};

export default notifyBroadcastError;
