import { actionCreator } from '@ardoq/rxbeach';
import { SurveyAdminMode, SurveyOverviewSearchParams } from './types';
import { ArdoqId } from '@ardoq/api-types';

export const selectSurveyMode = actionCreator<{
  mode: Exclude<SurveyAdminMode, SurveyAdminMode.NEW_SURVEY>;
}>('[surveyAdminActions] SELECT_SURVEY_MODE');

export const setSurveyOverviewSearchParams = actionCreator<
  SurveyOverviewSearchParams | undefined
>('[surveyAdminActions] SET_SURVEY_OVERVIEW_SEARCH_PARAMS');

export const navigateToSurveyForm = actionCreator<ArdoqId | null>(
  '[surveyAdminActions] NAVIGATE_TO_SURVEY_FORM'
);

export const navigateToChangeApprovalPage = actionCreator<ArdoqId>(
  '[surveyAdminActions] NAVIGATE_TO_CHANGE_APPROVAL_PAGE'
);

export const openNewSurvey = actionCreator<ArdoqId | null>(
  '[surveyAdminActions] OPEN_NEW_SURVEY'
);

export const selectSurveyId = actionCreator<{
  surveyId: ArdoqId;
  keepEditorIndex?: boolean;
}>('[surveyAdminActions] SELECT_SURVEY_ID');
