import { GetContextMenuData } from '@ardoq/context-menu';
import {
  COMPONENT_ID_SELECTOR,
  REFERENCE_ID_SELECTOR,
  TAG_ID_ATTRIBUTE,
  TAG_ID_SELECTOR,
  WORKSPACE_ID_SELECTOR,
} from 'consts';
import {
  REFERENCE_ID_ATTRIBUTE,
  COMPONENT_ID_ATTRIBUTE,
  WORKSPACE_ID_ATTRIBUTE,
} from '@ardoq/global-consts';
import { setContextMenuState } from 'contextMenus/contextMenuState$';
import getCommonMenuData, {
  ContextTargetIds,
} from 'contextMenus/getCommonMenuData';
import { dispatchAction } from '@ardoq/rxbeach';
import { getTagMenu } from 'contextMenus/tagMenu';

const findElement = (selector: string, target: Element) => {
  const element = target.closest(selector);
  return element instanceof HTMLElement || element instanceof SVGElement
    ? element
    : null;
};

const getTargetIds = (target: HTMLElement | SVGElement) => {
  const workspaceElement = findElement(WORKSPACE_ID_SELECTOR, target);
  const workspaceId = workspaceElement?.getAttribute(WORKSPACE_ID_ATTRIBUTE);

  if (workspaceId) {
    return { workspaceIds: [workspaceId] };
  }

  const tagElement = findElement(TAG_ID_SELECTOR, target);
  const tagId = tagElement?.getAttribute(TAG_ID_ATTRIBUTE);

  if (tagId) {
    return { tagId };
  }

  const referenceElement = findElement(REFERENCE_ID_SELECTOR, target);
  const referenceIds = referenceElement
    ?.getAttribute(REFERENCE_ID_ATTRIBUTE)
    ?.split(',');

  if (referenceIds) {
    return { referenceIds };
  }

  const componentElement = findElement(COMPONENT_ID_SELECTOR, target);
  const componentId = componentElement?.getAttribute(COMPONENT_ID_ATTRIBUTE);

  if (componentId) {
    return { componentIds: [componentId] };
  }

  return null;
};

const getContextMenuDataFromEvent: GetContextMenuData = ctxMenuArguments => {
  const { target } = ctxMenuArguments;

  const targetIds = target ? getTargetIds(target) : null;

  if (!targetIds) {
    return { options: null };
  }

  const { tagId } = targetIds;
  if (tagId) {
    return getTagMenu({ tagId, ...ctxMenuArguments });
  }

  return getCommonMenuData(ctxMenuArguments, targetIds);
};

export const contextMenuOnClick = (
  event: MouseEvent,
  targetIds: ContextTargetIds,
  isViewpointMode: boolean
) => {
  const { target, x, y } = event;

  if (!(target instanceof HTMLElement || target instanceof SVGElement)) {
    return;
  }

  const data = getCommonMenuData(
    { event, target, x, y, isViewpointMode },
    targetIds
  );

  if (!data.options) {
    return;
  }

  dispatchAction(
    setContextMenuState({
      items: data.options,
      testId: data.testId,
      position: { left: x, top: y },
    })
  );
};

export const pagesContextMenuName = 'pages-context-menu';

export const pagesContextMenu = {
  getContextMenuData: getContextMenuDataFromEvent,
};
