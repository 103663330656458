import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { ContextSort } from '@ardoq/common-helpers';
import { CidArgs } from '@ardoq/common-helpers';
import { TrackingLocation } from 'tracking/types';

export interface ComponentIdsPayload {
  readonly componentIds: ArdoqId[];
}

export const notifyComponentsRemoved = actionCreator<ComponentIdsPayload>(
  '[ComponentActions] NOTIFY_BATCH_COMPONENTS_REMOVED'
);

export const notifyComponentsAdded = actionCreator<ComponentIdsPayload>(
  '[ComponentActions] NOTIFY_COMPONENTS_ADDED'
);

export const notifyComponentsUpdated = actionCreator<ComponentIdsPayload>(
  '[ComponentActions] NOTIFY_COMPONENTS_UPDATED'
);

export const notifyComponentsSynced = actionCreator<ComponentIdsPayload>(
  '[ComponentActions] NOTIFY_COMPONENTS_SYNCED'
);

export const selectComponent = actionCreator<CidArgs>(
  '[ComponentActions] SELECT_COMPONENT'
);
export const forceUpdateComponent = actionCreator<
  CidArgs & { trackingLocation?: TrackingLocation }
>('[ComponentActions] FORCE_UPDATE_COMPONENT');

export const toggleComponentDescription = actionCreator<CidArgs>(
  '[ComponentActions] TOGGLE_COMPONENT_DESCRIPTION'
);

export const sortComponents = actionCreator<ContextSort>(
  '[ComponentActions] SORT_COMPONENTS'
);

export const sortComponentsByReferences = actionCreator<ContextSort>(
  '[ComponentActions] SORT_COMPONENTS_BY_REFERENCES'
);

export const deleteComponent = actionCreator<ArdoqId>(
  '[ComponentActions] COMPONENT_DELETE'
);

export const toggleLockComponent = actionCreator<ArdoqId>(
  '[ComponentActions] COMPONENT_TOGGLE_LOCK_COMPONENT'
);

export const notifyComponentLocked = actionCreator<ArdoqId>(
  '[ComponentActions] NOTIFY_COMPONENT_LOCKED'
);

export const notifyComponentUnlocked = actionCreator<ArdoqId>(
  '[ComponentActions] NOTIFY_COMPONENT_UNLOCKED'
);

export const createSurveyFromComponent = actionCreator<ArdoqId>(
  '[ComponentActions] COMPONENT_CREATE_SURVEY_FROM_OMPONENT'
);
