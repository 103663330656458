import React from 'react';
import { colors } from '@ardoq/design-tokens';
import { Chart } from '@ardoq/dashboard';

type DoughnutChartProps = {
  successCount: number;
  errorCount: number;
};

const DoughnutChart = ({ successCount, errorCount }: DoughnutChartProps) => {
  const data =
    successCount || errorCount
      ? [
          {
            name: 'Success',
            value: successCount,
            itemStyle: {
              color: colors.green50,
            },
          },
          {
            name: 'Errors',
            value: errorCount,
            itemStyle: {
              color: colors.red60,
            },
          },
        ]
      : [
          {
            name: 'Empty',
            value: 0,
            itemStyle: {
              color: colors.grey80,
            },
          },
        ];

  const options = {
    series: [
      {
        type: 'pie' as const,
        radius: ['50%', '75%'],
        data,
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 1,
        },
        cursor: 'default',
        label: {
          show: false,
        },
      },
    ],
    tooltip: {
      extraCssText: `
      max-width: 240px;
      white-space: normal;
    `,
      trigger: 'item' as const,
      confine: false,
      formatter: ({ marker, name, value, percent }: any) =>
        `${name}<br />${marker}<small><b>${percent}%</b> (${value} out of ${
          successCount + errorCount
        })</small>`,
    },
    title: {
      text: `{main|Total}\n{sub|${successCount + errorCount}}`,
      left: 'center',
      bottom: 110,
      textStyle: {
        rich: {
          main: {
            fontSize: 14,
            fontWeight: 400,
            color: colors.grey50,
          },
          sub: {
            fontSize: 26,
            fontWeight: 600,
            color: colors.grey15,
            padding: [3, 0, 0, 0],
          },
        },
      },
    },
    height: '90%',
  };

  return (
    <Chart
      chartOptions={options}
      clickHandler={null}
      layout={{
        h: 1,
        i: 'doughnut-chart',
        w: 1,
        x: 0,
        y: 0,
      }}
      width={300}
    />
  );
};

export default DoughnutChart;
