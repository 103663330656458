import { NotificationCardProps } from '@ardoq/in-app-notifications';
import { actionCreator } from '@ardoq/rxbeach';
import { MarkNotificationPayload } from '@ardoq/api';
import { NotificationsFilterType } from './types';
import { ArdoqId, NotificationApiResponse } from '@ardoq/api-types';

const DOMAIN = '[inAppNotifications]';

export const toggleInAppNotificationsList = actionCreator(
  `${DOMAIN} toggleInAppNotificationsList`
);

export const getInAppNotificationsList = actionCreator(
  `${DOMAIN} getInAppNotificationsList`
);

export const getInAppNotificationsListSuccess = actionCreator<
  NotificationCardProps[]
>(`${DOMAIN} getInAppNotificationsListSuccess`);

export const filterSelected = actionCreator<NotificationsFilterType>(
  `${DOMAIN} filterSelected`
);

export const toggleShowMore = actionCreator(`${DOMAIN} toggleShowMore`);

export const toggleCanDisplayToast = actionCreator<boolean>(
  `${DOMAIN} toggleCanDisplayToast`
);

export const submitMarkNotifications = actionCreator<MarkNotificationPayload[]>(
  `${DOMAIN} submitMarkNotifications`
);

export const setReadStatus = actionCreator<{ id: ArdoqId }>(
  `${DOMAIN} SET_READ_STATUS`
);

export const submitMarkNotificationsSuccess = actionCreator<
  MarkNotificationPayload[]
>(`${DOMAIN} submitMarkNotificationsSuccess`);

export const closeInAppNotificationsList = actionCreator(
  `${DOMAIN} closeInAppNotificationsList`
);

export const onNotificationClick = actionCreator<NotificationApiResponse>(
  `${DOMAIN} onNotificationClick`
);
