import { useEffect, useState } from 'react';
import { connect } from '@ardoq/rxbeach';
import type { TablePreview } from 'integrations/common/streams/tablePreviews/types';
import styled from 'styled-components';
import { TransferConfigs } from 'integrations/common/containers/tabularConfigMapping/TransferConfigs';
import { WorksheetTabs } from 'integrations/common/containers/tabularConfigMapping/WorksheetTabs';
import { ColumnMappingSidebar } from 'integrations/common/containers/tabularConfigMapping/ColumnMappingSidebar';
import { TableConfigurations } from 'integrations/common/containers/tabularConfigMapping/TableConfigurations';
import { Table } from 'integrations/common/containers/tabularConfigMapping/Table';
import { DataSyncStrategy } from 'integrations/common/containers/tabularConfigMapping/DataSyncStrategy';
import { viewModel$ } from './viewModel$';
import { MAPPING_SIDEBAR_WIDTH } from './constants';
import { PageWrapper, PageContainer } from '../layout';
import { Box } from '@ardoq/layout';
import { colors } from '@ardoq/design-tokens';

type TabularConfigMappingProps = {
  currentTableId: TablePreview['id'];
  isSyncStrategyOptionsDisabled: boolean;
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 700px) 1px 50%;
`;

const Divider = styled.div`
  border-right: 1px dotted ${colors.borderAction};
`;

const TabularConfigMapping = ({
  currentTableId,
  isSyncStrategyOptionsDisabled,
}: TabularConfigMappingProps) => {
  const [selectedColumn, setSelectedColumn] = useState<number | null>(null);

  useEffect(() => {
    setSelectedColumn(null);
  }, [currentTableId]);

  return (
    <PageContainer>
      <PageWrapper>
        <ContentContainer $isSidebarOpen={selectedColumn !== null}>
          <TransferConfigs />
          <WorksheetTabs />

          <Box backgroundColor="bgDefault">
            <Grid>
              <TableConfigurations />
              {!isSyncStrategyOptionsDisabled && (
                <>
                  <Divider />
                  <DataSyncStrategy />
                </>
              )}
            </Grid>
            <Table
              selectedColumn={selectedColumn}
              setSelectedColumn={setSelectedColumn}
              tableTitle={`3. Configure columns`}
            />
          </Box>
        </ContentContainer>
        <ColumnMappingSidebar
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
        />
      </PageWrapper>
    </PageContainer>
  );
};

const ContentContainer = styled.div<{ $isSidebarOpen: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${props =>
    props.$isSidebarOpen ? `calc(100% - ${MAPPING_SIDEBAR_WIDTH}px)` : '100%'};
  flex-shrink: 1;
  overflow: auto;
`;

export default connect(TabularConfigMapping, viewModel$);
