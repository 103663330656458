import ModuleContainer from './ModuleContainer';
import TrialDaysRemaining from 'menus/topbar/TrialDaysRemaining';

const TrialExpired = () => (
  <ModuleContainer style={{ textAlign: 'center', paddingTop: '100px' }}>
    <TrialDaysRemaining daysRemaining={0} />
  </ModuleContainer>
);

export default TrialExpired;
