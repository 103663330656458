import { IconName } from '@ardoq/icons';

type CommonNavigationMenuItem = {
  label: string;
  iconName?: IconName;
  dataTestId?: string;
  dataClickId?: string;
};

export type NavigationMenuGroup = CommonNavigationMenuItem & {
  // Ideally, this would be a recursive type. and that is implemented in the code.
  // but for now, we want it to accept only one level of nesting.
  //   items: NavigationMenuItem[];
  items: NavigationMenuSingleItem[];
  isCollapsed?: boolean;
  isCollapsible?: boolean;
  onClick?: (item: NavigationMenuGroup) => void;
};

export type NavigationMenuSingleItem = CommonNavigationMenuItem & {
  rightText?: string;
  /**
   * Ignored if `selectedItemId` is provided.
   */
  isSelected?: boolean;
  isDisabled?: boolean;
  /**
   * Used to match the item with the selected item.
   */
  id: string;
  onClick?: (item: NavigationMenuSingleItem) => void;
};

export type NavigationMenuItem = NavigationMenuGroup | NavigationMenuSingleItem;

export const isNavigationMenuGroup = (
  item: NavigationMenuItem
): item is NavigationMenuGroup => {
  return 'items' in item;
};
