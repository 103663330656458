import { action$, extractPayload, ofType } from '@ardoq/rxbeach';
import { selectedComponentForOverviewDrawerChanged } from './actions';
import { combineLatest, switchMap } from 'rxjs';
import { traversalState$ } from '../../streams/traversals$';
import { api, handleError, traversalApi } from '@ardoq/api';
import { filter, map, startWith } from 'rxjs/operators';
import { getTraversalWithStartSetAndWildcardTripleOfType } from 'appLayout/ardoqStudio/utils';
import { context$ } from 'streams/context/context$';
import { loadedState$ } from 'loadedState/loadedState$';
import { isArdoqError } from '@ardoq/common-helpers';
import { enhanceScopeData } from '@ardoq/renderers';
import { ComponentOverviewDrawerProps } from './ComponentOverviewDrawer';
import { componentOverviewDrawerCommands } from './componentOverviewDrawerCommands';

const selectedComponentIdAndScopeData$ = action$.pipe(
  ofType(selectedComponentForOverviewDrawerChanged),
  extractPayload()
);

const executedWildcardViewpointForSelectedComponent$ =
  selectedComponentIdAndScopeData$.pipe(
    map(({ componentId, scopeData }) => scopeData.componentsById[componentId]),
    filter(Boolean),
    switchMap(async component => {
      const traversalResponse = await traversalApi.loadTraversal(
        getTraversalWithStartSetAndWildcardTripleOfType([component._id], {
          ...component,
          type: component.type || component.entityType,
        })
      );
      return isArdoqError(traversalResponse)
        ? traversalResponse
        : {
            componentId: component._id,
            scopeData: enhanceScopeData(traversalResponse.scopeData),
          };
    }),
    handleError(api.logErrorIfNeeded),
    startWith(null)
  );

export const componentOverviewDrawerViewModel$ = combineLatest({
  selectedComponentIdAndScopeData: selectedComponentIdAndScopeData$,
  executedWildcardViewpointForSelectedComponentState:
    executedWildcardViewpointForSelectedComponent$,
  traversalState: traversalState$,
  context: context$,
  loadedStates: loadedState$,
}).pipe(
  map(
    ({
      selectedComponentIdAndScopeData,
      traversalState,
      loadedStates,
      context,
      executedWildcardViewpointForSelectedComponentState,
    }): Omit<ComponentOverviewDrawerProps, 'handleCloseDrawer'> => {
      const isLoadingNewReferences =
        selectedComponentIdAndScopeData.componentId !==
        executedWildcardViewpointForSelectedComponentState?.componentId; // When this two ids don't match, it means that we are executing a viewpoint for a new component

      return {
        componentId: selectedComponentIdAndScopeData.componentId,
        scopeData:
          !isLoadingNewReferences &&
          executedWildcardViewpointForSelectedComponentState.scopeData
            ? executedWildcardViewpointForSelectedComponentState.scopeData
            : selectedComponentIdAndScopeData.scopeData,
        viewpoints: traversalState.list,
        isLoadingReferences: isLoadingNewReferences,
        context,
        loadedStates,
        componentOverviewDrawerCommands,
      };
    }
  )
);
