import { s8, colors, s16 } from '@ardoq/design-tokens';
import { DropdownOption } from '@ardoq/dropdown-menu';
import { Column } from '@ardoq/table';
import {
  ColumnsProps,
  getNameColumnIncludingSelectAllCheckbox,
  getLastModifiedDateColumn,
  getLastModifiedByColumn,
  getFavoritesColumn,
} from '../AssetsBrowser/columns';
import { ColumnsOptions, Folder, Row } from '../EntityBrowser/types';
import { AssetRow } from '../AssetsBrowser/types';
import { TextEllipsisWrapper } from '../EntityBrowser/atoms';
import { CircularWrapper } from '../../homePage/homePageAtoms';
import { getInitials } from '@ardoq/common-helpers';
import { getMenuOptionsColumn } from '../../appContainer/DashboardContainer/columns';
import { FlexBox } from '@ardoq/layout';

const COLUMN_BOTTOM_BORDER = `1px solid ${colors.grey90}`;

const addBottomBorderToHeader = (
  columns: Column<AssetRow | Folder<AssetRow>>[]
) =>
  columns.map(column => {
    return {
      ...column,
      headerStyle: {
        ...column.headerStyle,
        paddingTop: s8,
        borderBottom: COLUMN_BOTTOM_BORDER,
      },
    };
  });

const getAssetsBrowser2024NameColumn = (
  columnsProps: ColumnsProps,
  columnsOptions: ColumnsOptions<AssetRow>
) => ({
  ...getNameColumnIncludingSelectAllCheckbox({
    ...columnsProps,
    ...columnsOptions,
  }),
  headerStyle: {
    paddingLeft: s16,
  },
  cellStyle: {
    width: '50%',
    paddingLeft: s16,
    paddingRight: s8,
  },
});

export const getUpdatedByColumn = (columnsProps: ColumnsProps) => ({
  ...getLastModifiedByColumn(columnsProps),
  title: 'Updated by',
  valueRender: (value: string) => {
    if (value === 'Unknown' || !value) {
      return null;
    }
    return (
      <FlexBox align="center" gap="xsmall">
        <CircularWrapper>{getInitials(value)}</CircularWrapper>
        <TextEllipsisWrapper $width="200px">{value}</TextEllipsisWrapper>
      </FlexBox>
    );
  },
});

export const getLastUpdatedColumn = (columnsProps: ColumnsProps) => ({
  ...getLastModifiedDateColumn(columnsProps),
  title: 'Last updated',
});

export const getAssetsBrowser2024DefaultColumns =
  (
    columnsProps: ColumnsProps & {
      getMenuOptions: (row: Row<AssetRow>) => DropdownOption[];
    }
  ) =>
  (columnsOptions: ColumnsOptions<AssetRow>) =>
    addBottomBorderToHeader([
      getAssetsBrowser2024NameColumn(columnsProps, columnsOptions),
      {
        ...getUpdatedByColumn(columnsProps),
        headerStyle: {
          width: '20%',
        },
      },
      {
        ...getLastUpdatedColumn(columnsProps),
        headerStyle: {
          minWidth: '20%',
        },
      },
      getFavoritesColumn(),
      getMenuOptionsColumn(columnsProps.getMenuOptions),
    ]);

export const getAssetsBrowser2024CustomColumns =
  (
    columnProps: ColumnsProps,
    customColumns: (
      columnProps: ColumnsProps
    ) => Column<AssetRow | Folder<AssetRow>>[],
    getMenuOptions: (row: Row<AssetRow>) => DropdownOption[]
  ) =>
  (columnsOptions: ColumnsOptions<AssetRow>) =>
    addBottomBorderToHeader([
      getAssetsBrowser2024NameColumn(columnProps, columnsOptions),
      ...customColumns(columnProps),
      getFavoritesColumn(),
      getMenuOptionsColumn(getMenuOptions),
    ]);
