import { ExtractPayload, reducer } from '@ardoq/rxbeach';
import {
  fetchInstanceCounts,
  resetAudienceDrawerData,
  failedToLoadMetamodel,
  setFetchInstanceCountsError,
  addTriple,
  removeTriple,
  setInstanceCounts,
  setMetamodelAndTraversalStartQueryData,
  setTriplesSortAndFiltersState,
  selectGraphNode,
  updateTraversal,
  removePath,
} from './actions';
import { AudienceTraversalDrawerState } from './audienceTraversalDrawer$';
import { TriplesSortAndFiltersState } from 'viewpointBuilder/types';
import { getTraversalAudience } from 'surveyAdmin/ChangeApproval/utils';
import {
  createGraph,
  createTraversalFromTraversalToNodeIdPaths,
  getStartQuery,
  toNormalizedTraversal,
  updateGraph,
  updateTripleOptions,
  withPathRemoved,
  withTripleAdded,
  withTriplesRemoved,
} from './reducerUtils';

const handleUpdateTraversal = (
  state: AudienceTraversalDrawerState,
  traversal: ExtractPayload<typeof updateTraversal>
): AudienceTraversalDrawerState => {
  const updatedTripleOptions = updateTripleOptions({
    selectedComponentTypeName: state.selectedComponentTypeName,
    traversal,
    triplesSortAndFiltersState: state.triplesSortAndFiltersState,
    tripleOptions: state.tripleOptions,
    traversalStartQuery: state.traversalStartQuery,
    metamodel: state.metamodel,
    graphNodes: state.graphNodes,
    selectedGraphNodeId: state.selectedGraphNodeId,
    traversalWithNodeIds: state.traversalWithNodeIds,
  });
  return {
    ...state,
    traversal,
    ...updatedTripleOptions,
  };
};

const handleSelectGraphNode = (
  state: AudienceTraversalDrawerState,
  selectedGraphNodeId: ExtractPayload<typeof selectGraphNode>
): AudienceTraversalDrawerState => {
  const newlySelectedNode = state.graphNodes.get(selectedGraphNodeId);
  if (!newlySelectedNode || selectedGraphNodeId === state.selectedGraphNodeId) {
    return state;
  }

  const selectedComponentTypeName = newlySelectedNode.getLabel();
  const graph = updateGraph(
    state.surveyComponentTypeName,
    selectedGraphNodeId,
    state.graphNodes,
    state.graphEdges
  );
  const updatedTripleOptions = updateTripleOptions({
    selectedComponentTypeName,
    traversal: state.traversal,
    triplesSortAndFiltersState: state.triplesSortAndFiltersState,
    tripleOptions: state.tripleOptions,
    traversalStartQuery: state.traversalStartQuery,
    metamodel: state.metamodel,
    graphNodes: graph.graphNodes,
    selectedGraphNodeId,
    traversalWithNodeIds: state.traversalWithNodeIds,
  });

  return {
    ...state,
    ...updatedTripleOptions,
    tripleOptions: {
      ...updatedTripleOptions.tripleOptions,
      optionCountLoadingState: 'idle' as const,
    },
    ...graph,
  };
};

const handleFetchInstanceCounts = (
  state: AudienceTraversalDrawerState
): AudienceTraversalDrawerState => {
  return {
    ...state,
    tripleOptions: {
      ...state.tripleOptions,
      optionCountLoadingState: 'loading',
    },
  };
};

const handleSetInstanceCounts = (
  state: AudienceTraversalDrawerState,
  instanceCountsResponse: ExtractPayload<typeof setInstanceCounts>
): AudienceTraversalDrawerState => {
  const tripleOptions = {
    ...state.tripleOptions,
    instanceCountsResponse,
    optionCountLoadingState: 'loaded' as const,
  };
  const graph = updateGraph(
    state.selectedComponentTypeName,
    state.selectedGraphNodeId,
    state.graphNodes,
    state.graphEdges
  );
  const updatedTripleOptions = updateTripleOptions({
    selectedComponentTypeName: graph.selectedComponentTypeName,
    traversal: state.traversal,
    triplesSortAndFiltersState: state.triplesSortAndFiltersState,
    tripleOptions,
    traversalStartQuery: state.traversalStartQuery,
    metamodel: state.metamodel,
    graphNodes: graph.graphNodes,
    selectedGraphNodeId: state.selectedGraphNodeId,
    traversalWithNodeIds: state.traversalWithNodeIds,
  });
  return {
    ...state,
    ...updatedTripleOptions,
    ...graph,
  };
};

const handleSetFetchInstanceCountsError = (
  state: AudienceTraversalDrawerState
): AudienceTraversalDrawerState => {
  return {
    ...state,
    tripleOptions: {
      ...state.tripleOptions,
      optionCountLoadingState: 'error',
    },
  };
};

const handleSetTriplesSortAndFiltersState = (
  state: AudienceTraversalDrawerState,
  triplesSortAndFiltersState: TriplesSortAndFiltersState
): AudienceTraversalDrawerState => {
  const updatedTripleOptions = updateTripleOptions({
    selectedComponentTypeName: state.selectedComponentTypeName,
    traversal: state.traversal,
    triplesSortAndFiltersState,
    tripleOptions: state.tripleOptions,
    traversalStartQuery: state.traversalStartQuery,
    metamodel: state.metamodel,
    graphNodes: state.graphNodes,
    selectedGraphNodeId: state.selectedGraphNodeId,
    traversalWithNodeIds: state.traversalWithNodeIds,
  });

  return {
    ...state,
    ...updatedTripleOptions,
    triplesSortAndFiltersState,
  };
};

const handleSetMetamodelAndTraversalStartQueryData = (
  state: AudienceTraversalDrawerState,
  {
    surveyComponentTypeName,
    metamodel,
    workspaceId,
    approvalAudience,
  }: ExtractPayload<typeof setMetamodelAndTraversalStartQueryData>
): AudienceTraversalDrawerState => {
  const traversal = toNormalizedTraversal(
    getTraversalAudience(approvalAudience).traversal
  );
  const traversalStartQuery = getStartQuery(
    surveyComponentTypeName,
    workspaceId ?? ''
  );
  const graph = createGraph({
    surveyComponentTypeName,
    traversal,
    metamodel,
    selectedGraphNodeId: null,
  });
  const updatedTripleOptions = updateTripleOptions({
    selectedComponentTypeName: surveyComponentTypeName,
    traversal,
    triplesSortAndFiltersState: state.triplesSortAndFiltersState,
    tripleOptions: state.tripleOptions,
    traversalStartQuery,
    metamodel,
    graphNodes: graph.graphNodes,
    selectedGraphNodeId: graph.selectedGraphNodeId,
    traversalWithNodeIds: graph.traversalWithNodeIds,
  });

  return {
    ...state,
    surveyComponentTypeName,
    traversalStartQuery,
    traversal,
    metamodelStatus: 'success',
    ...updatedTripleOptions,
    ...graph,
  };
};

const handleResetAudienceDrawerData = (
  state: AudienceTraversalDrawerState,
  traversal: ExtractPayload<typeof resetAudienceDrawerData>
): AudienceTraversalDrawerState => {
  const normalizedTraversal = toNormalizedTraversal(traversal);
  const graph = createGraph({
    surveyComponentTypeName: state.surveyComponentTypeName,
    traversal: normalizedTraversal,
    metamodel: state.metamodel,
    selectedGraphNodeId: state.selectedGraphNodeId,
  });
  const updatedTripleOptions = updateTripleOptions({
    selectedComponentTypeName: state.surveyComponentTypeName,
    traversal: normalizedTraversal,
    triplesSortAndFiltersState: state.triplesSortAndFiltersState,
    tripleOptions: state.tripleOptions,
    traversalStartQuery: state.traversalStartQuery,
    metamodel: state.metamodel,
    graphNodes: state.graphNodes,
    selectedGraphNodeId: state.selectedGraphNodeId,
    traversalWithNodeIds: graph.traversalWithNodeIds,
  });

  return {
    ...state,
    traversal: normalizedTraversal,
    ...updatedTripleOptions,
    ...graph,
  };
};

const handleAddTriple = (
  state: AudienceTraversalDrawerState,
  { direction, namedDirectedTriple }: ExtractPayload<typeof addTriple>
): AudienceTraversalDrawerState => {
  const { graphNodes, graphEdges, traversalWithNodeIds } = withTripleAdded({
    traversalWithNodeIds: state.traversalWithNodeIds,
    selectedComponentTypeName: state.selectedComponentTypeName,
    direction,
    namedDirectedTriple,
    graphNodes: state.graphNodes,
    graphEdges: state.graphEdges,
    selectedGraphNodeId: state.selectedGraphNodeId,
    metamodel: state.metamodel,
    startNode: state.startNode,
  });
  const traversal = createTraversalFromTraversalToNodeIdPaths(
    state.traversal,
    traversalWithNodeIds.paths
  );
  const updatedTripleOptions = updateTripleOptions({
    selectedComponentTypeName: state.selectedComponentTypeName,
    traversal,
    triplesSortAndFiltersState: state.triplesSortAndFiltersState,
    tripleOptions: state.tripleOptions,
    traversalStartQuery: state.traversalStartQuery,
    metamodel: state.metamodel,
    graphNodes,
    selectedGraphNodeId: state.selectedGraphNodeId,
    traversalWithNodeIds,
  });

  return {
    ...state,
    ...updatedTripleOptions,
    graphNodes,
    graphEdges,
    traversalWithNodeIds,
    traversal,
  };
};

const handleRemoveTriple = (
  state: AudienceTraversalDrawerState,
  { namedDirectedTriple }: ExtractPayload<typeof removeTriple>
): AudienceTraversalDrawerState => {
  const { graphNodes, graphEdges, traversalWithNodeIds } = withTriplesRemoved({
    traversalWithNodeIds: state.traversalWithNodeIds,
    namedDirectedTriple,
    graphNodes: state.graphNodes,
    graphEdges: state.graphEdges,
    selectedGraphNodeId: state.selectedGraphNodeId,
    startGraphNodeId: state.startNode?.id,
  });
  const traversal = createTraversalFromTraversalToNodeIdPaths(
    state.traversal,
    traversalWithNodeIds.paths
  );
  const updatedTripleOptions = updateTripleOptions({
    selectedComponentTypeName: state.selectedComponentTypeName,
    traversal,
    triplesSortAndFiltersState: state.triplesSortAndFiltersState,
    tripleOptions: state.tripleOptions,
    traversalStartQuery: state.traversalStartQuery,
    metamodel: state.metamodel,
    graphNodes,
    selectedGraphNodeId: state.selectedGraphNodeId,
    traversalWithNodeIds,
  });

  return {
    ...state,
    ...updatedTripleOptions,
    graphNodes,
    graphEdges,
    traversalWithNodeIds,
    traversal,
  };
};

const handleFailedToLoadMetamodel = (
  state: AudienceTraversalDrawerState
): AudienceTraversalDrawerState => {
  return {
    ...state,
    metamodelStatus: 'error',
  };
};

const handleRemovePath = (
  state: AudienceTraversalDrawerState,
  path: ExtractPayload<typeof removePath>
): AudienceTraversalDrawerState => {
  const traversal = withPathRemoved({
    traversalWithNodeIds: state.traversalWithNodeIds,
    path,
    existingTraversal: state.traversal,
  });
  const graph = createGraph({
    surveyComponentTypeName: state.surveyComponentTypeName,
    traversal,
    metamodel: state.metamodel,
    selectedGraphNodeId: state.selectedGraphNodeId,
  });
  const updatedTripleOptions = updateTripleOptions({
    selectedComponentTypeName: state.surveyComponentTypeName,
    traversal,
    triplesSortAndFiltersState: state.triplesSortAndFiltersState,
    tripleOptions: state.tripleOptions,
    traversalStartQuery: state.traversalStartQuery,
    metamodel: state.metamodel,
    graphNodes: graph.graphNodes,
    selectedGraphNodeId: graph.selectedGraphNodeId,
    traversalWithNodeIds: graph.traversalWithNodeIds,
  });

  return {
    ...state,
    ...graph,
    ...updatedTripleOptions,
    traversal,
  };
};

export const reducers = [
  reducer(updateTraversal, handleUpdateTraversal),
  reducer(selectGraphNode, handleSelectGraphNode),
  reducer(fetchInstanceCounts, handleFetchInstanceCounts),
  reducer(setInstanceCounts, handleSetInstanceCounts),
  reducer(setFetchInstanceCountsError, handleSetFetchInstanceCountsError),
  reducer(setTriplesSortAndFiltersState, handleSetTriplesSortAndFiltersState),
  reducer(
    setMetamodelAndTraversalStartQueryData,
    handleSetMetamodelAndTraversalStartQueryData
  ),
  reducer(resetAudienceDrawerData, handleResetAudienceDrawerData),
  reducer(addTriple, handleAddTriple),
  reducer(removeTriple, handleRemoveTriple),
  reducer(removePath, handleRemovePath),
  reducer(failedToLoadMetamodel, handleFailedToLoadMetamodel),
];
