import { ViewIds } from '@ardoq/api-types';
import { spreadPayloadOverState } from '@ardoq/common-helpers';
import {
  persistentReducedStream,
  reducer,
  actionCreator,
} from '@ardoq/rxbeach';

type VisualizedNodesQuantityState = Partial<Record<ViewIds, number>>;

export const setVisualizedNodesQuantity =
  actionCreator<VisualizedNodesQuantityState>(
    '[tracking] SET_VISUALIZED_NODES_QUANTITY'
  );

const defaultState: VisualizedNodesQuantityState = {};

const visualizedNodesQuantity$ = persistentReducedStream(
  'visualizedNodesQuantity$',
  defaultState,
  [reducer(setVisualizedNodesQuantity, spreadPayloadOverState)]
);

export default visualizedNodesQuantity$;
