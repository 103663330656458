import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
} from '@ardoq/rxbeach';
import {
  checkIfTokenExists,
  deleteToken,
  generateToken,
  setGeneratedToken,
  setHasToken,
  setScimError,
  setTokenDeleted,
} from './scimManagement$';
import { map, switchMap, tap } from 'rxjs/operators';
import { trackStreamEvent } from 'tracking/tracking';
import { logError } from '@ardoq/logging';
import { handleError, scimApi } from '@ardoq/api';
import { ArdoqError, getArdoqErrorMessage } from '@ardoq/common-helpers';

const logAndSetScimError = (defaultMessage: string) => (error: ArdoqError) => {
  logError(error, defaultMessage);
  dispatchAction(
    setScimError({
      errorMessage: getArdoqErrorMessage(error),
    })
  );
};

const clearAlert = () => dispatchAction(setScimError({ errorMessage: null }));

const handleGenerateToken = routine(
  ofType(generateToken),
  tap(clearAlert),
  trackStreamEvent(() => ({ eventName: 'Generated new SCIM token' })),
  switchMap(scimApi.generate),
  handleError(logAndSetScimError('Failed to generate new SCIM token')),
  map(({ 'auth-token': token }) => setGeneratedToken({ token })),
  tap(dispatchAction)
);

const handleCheckIfTokenExists = routine(
  ofType(checkIfTokenExists),
  tap(clearAlert),
  switchMap(scimApi.exists),
  handleError(logAndSetScimError('Failed to fetch token status')),
  map(({ tokenExists }) => setHasToken({ hasToken: tokenExists })),
  tap(dispatchAction)
);

const handleDeleteToken = routine(
  ofType(deleteToken),
  tap(clearAlert),
  trackStreamEvent(() => ({ eventName: 'Deleted a SCIM token' })),
  switchMap(scimApi.delete),
  handleError(logAndSetScimError('Failed to delete token')),
  map(() => setTokenDeleted()),
  tap(dispatchAction)
);

export default collectRoutines(
  handleGenerateToken,
  handleCheckIfTokenExists,
  handleDeleteToken
);
