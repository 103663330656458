import content$ from 'broadcasts/content$';
import gremlinAudienceDialog$ from './gremlinAudienceDialog$';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
  withNamespace,
} from '@ardoq/rxbeach';
import {
  fetchContentIds,
  fetchContentIdsError,
  fetchGremlinAudiencePreview,
  notifyFailedToFetchGremlinAudiencePreview,
  notifyRunningGremlinAudienceQuery,
  notifyRunningGremlinAudienceQueryFailed,
  notifyRunningGremlinAudienceQuerySucceeded,
  openExistingGremlinAudienceDialog,
  openNewGremlinAudienceDialog,
  setContentIdsAndTotalNumberOfContentIds,
  setGremlinAudiencePreview,
} from './actions';
import currentBroadcast$ from 'broadcasts/currentBroadcast$';
import { map, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  queryGremlinSearch,
  queryGremlinSearchError,
  queryGremlinSearchSuccess,
} from 'search/Gremlin/actions';
import { SearchType } from '@ardoq/api-types';
import {
  createGremlinAudience,
  toContentIdsAndTotalNumberOfContentIds,
} from './utils';
import { getAudiencePreview$ } from 'broadcasts/routineUtils';
import { logError } from '@ardoq/logging';
import { broadcastApi, handleError } from '@ardoq/api';

const handleFetchContentIds = routine(
  ofType(fetchContentIds),
  extractPayload(),
  switchMap(broadcastApi.previewComponents),
  handleError(error => {
    logError(error, 'Failed to fetch broadcast component preview from api');
    dispatchAction(fetchContentIdsError());
  }),
  map(toContentIdsAndTotalNumberOfContentIds),
  tap(response => {
    dispatchAction(setContentIdsAndTotalNumberOfContentIds(response));
  })
);

const handleFetchGremlinAudiencePreview = routine(
  ofType(fetchGremlinAudiencePreview),
  withLatestFrom(gremlinAudienceDialog$, content$),
  map(([, { query }, content]) => ({
    content,
    audiences: [createGremlinAudience(query)],
  })),
  switchMap(getAudiencePreview$),
  handleError(error => {
    logError(error, 'Failed to fetch broadcast audience preview');
    dispatchAction(notifyFailedToFetchGremlinAudiencePreview());
  }),
  tap(response => dispatchAction(setGremlinAudiencePreview(response)))
);

const handleOpenGremlinAudienceDialog = routine(
  ofType(openExistingGremlinAudienceDialog, openNewGremlinAudienceDialog),
  withLatestFrom(currentBroadcast$),
  tap(([, { content }]) => {
    dispatchAction(fetchContentIds(content));
    dispatchAction(fetchGremlinAudiencePreview());
  })
);

const handleQueryGremlinSearch = routine(
  withNamespace(SearchType.BROADCAST_AUDIENCE_QUERY),
  ofType(queryGremlinSearch),
  tap(() => dispatchAction(notifyRunningGremlinAudienceQuery()))
);

const handleQueryGremlinSearchSuccess = routine(
  withNamespace(SearchType.BROADCAST_AUDIENCE_QUERY),
  ofType(queryGremlinSearchSuccess),
  pluck('payload'),
  tap(({ results }) =>
    dispatchAction(notifyRunningGremlinAudienceQuerySucceeded(results))
  )
);

const handleQueryGremlinSearchError = routine(
  withNamespace(SearchType.BROADCAST_AUDIENCE_QUERY),
  ofType(queryGremlinSearchError),
  extractPayload(),
  tap(({ syntaxError }) =>
    dispatchAction(notifyRunningGremlinAudienceQueryFailed(syntaxError))
  )
);

export default collectRoutines(
  handleFetchContentIds,
  handleFetchGremlinAudiencePreview,
  handleOpenGremlinAudienceDialog,
  handleQueryGremlinSearch,
  handleQueryGremlinSearchSuccess,
  handleQueryGremlinSearchError
);
