import {
  CompletedReviewAccessRequestDialog,
  ReviewAccessRequestDialog,
  ReviewAccessRequestDialogProps,
  reviewAccessRequestOps,
} from '@ardoq/manage-resource-permissions';
import { reviewAccessRequestDialogViewModel$ } from './viewModel$';
import { modal } from '@ardoq/modal';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import {
  saveAccessRequestReview,
  saveAccessRequestReviewFailure,
  saveAccessRequestReviewSuccess,
  setPermissionType,
  setSelectedGroup,
} from '../actions';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { showToast, ToastType } from '@ardoq/status-ui';
import {
  openResourcePermissionDialog,
  resourcePermissionDialogOpened,
  setResourcePermissions,
} from 'resourcePermissions/actions';
import {
  APIResourcePermissionAttributes,
  APIReviewRequestResourceAccessData,
  ResourceType,
} from '@ardoq/api-types';
import { shareReportSelected } from '../../../report/actions';
import { Features, hasFeature } from '@ardoq/features';

export const openReviewAccessRequestDialogHelper = () => {
  modal(resolve => {
    return (
      <ConnectedReviewAccessRequestDialog
        onClose={() => resolve(false)}
        onChangePermissionType={permissionType =>
          dispatchAction(setPermissionType({ permissionType }))
        }
        onGroupChanges={id => dispatchAction(setSelectedGroup({ id }))}
        onSave={() =>
          dispatchActionAndWaitForResponse(
            saveAccessRequestReview(),
            saveAccessRequestReviewSuccess,
            saveAccessRequestReviewFailure
          ).then(response => {
            if (response.type === saveAccessRequestReviewSuccess.type) {
              showToast(
                'Access request review submitted successfully',
                ToastType.SUCCESS
              );
              dispatchAction(
                setResourcePermissions(
                  response.payload as APIResourcePermissionAttributes[]
                )
              );
              resolve(true);
              return;
            }
            showToast(
              'Failed to submit access request review.',
              ToastType.INFO
            );
          })
        }
      />
    );
  });
};

const shouldOpenReportShareModal = (
  resource: APIReviewRequestResourceAccessData['request']
): boolean =>
  resource.resourceType === ResourceType.REPORT &&
  hasFeature(Features.PERMISSION_ZONES);

const ReviewAccessRequestDialogContainer = (
  args: Omit<ReviewAccessRequestDialogProps, 'openPermissionsPageClicked'>
) => {
  const onGoToPermissionsClicked = async (
    resource: APIReviewRequestResourceAccessData['request']
  ) => {
    if (shouldOpenReportShareModal(resource)) {
      dispatchAction(shareReportSelected(resource.resourceId));
    } else {
      await dispatchActionAndWaitForResponse(
        openResourcePermissionDialog({
          resources: [
            {
              ...resource,
            },
          ],
          originPage: 'review-access-request',
        }),
        resourcePermissionDialogOpened,
        resourcePermissionDialogOpened
      );
    }
    // Close the current dialog after opening the resource permissions dialog.
    args.onClose();
  };
  if (
    args.requestData &&
    reviewAccessRequestOps.isResolvedRequestResourceAccessData(args.requestData)
  ) {
    return (
      <CompletedReviewAccessRequestDialog
        {...args}
        requestData={args.requestData}
        onGoToPermissionsClicked={onGoToPermissionsClicked}
      />
    );
  }
  return (
    <ReviewAccessRequestDialog
      {...args}
      openPermissionsPageClicked={() =>
        args.requestData && onGoToPermissionsClicked(args.requestData.request)
      }
    ></ReviewAccessRequestDialog>
  );
};

const ConnectedReviewAccessRequestDialog = connect(
  ReviewAccessRequestDialogContainer,
  reviewAccessRequestDialogViewModel$
);
