import { useState } from 'react';
import { Field, FieldsRow, NumberInput } from '@ardoq/forms';
import {
  BroadcastInterval,
  BroadcastContentType,
  IntervalType,
} from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { chooseIntervalType, chooseIntervalValue } from 'broadcasts/actions';
import { pluralizeOptionLabels } from 'broadcasts/utils';
import { Select } from '@ardoq/select';

type ScheduleIntervalProps = {
  readonly interval: BroadcastInterval;
  readonly contentType: BroadcastContentType;
};

const intervalOptions = [
  { value: IntervalType.HOUR, label: IntervalType.HOUR },
  { value: IntervalType.DAY, label: IntervalType.DAY },
  { value: IntervalType.WEEK, label: IntervalType.WEEK },
  { value: IntervalType.MONTH, label: IntervalType.MONTH },
  { value: IntervalType.YEAR, label: IntervalType.YEAR },
];

const onIntervalTypeChange = (value: IntervalType) => {
  dispatchAction(chooseIntervalType(value));
};

const ScheduleInterval = ({ interval, contentType }: ScheduleIntervalProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const onIntervalValueChange = (number: number) => {
    // Ensure only integer values
    const value = Math.floor(number);
    if (value < 1) return setErrorMessage('Interval cannot be less than 1');
    dispatchAction(chooseIntervalValue(value));
    setErrorMessage(undefined);
  };

  return (
    <Field
      label="Interval"
      helperText={
        contentType === BroadcastContentType.REPORT
          ? 'For repeating broadcasts, reports will be recalculated at every interval.'
          : 'Components and audience will be recalculated at each interval of the broadcast.'
      }
    >
      <FieldsRow>
        <NumberInput
          onValueChange={onIntervalValueChange}
          value={interval.intervalValue}
          errorMessage={errorMessage}
          dataTestId="interval-input"
        />
        <Select
          onChange={option => {
            if (!option) return;
            onIntervalTypeChange(option.value as IntervalType);
          }}
          options={pluralizeOptionLabels(
            intervalOptions,
            interval.intervalValue
          )}
          dataTestId="interval-type-select"
          value={interval.intervalType}
        />
      </FieldsRow>
    </Field>
  );
};

export default ScheduleInterval;
