import styled, { css } from 'styled-components';
import { CheckboxIcon, Icon } from '@ardoq/icons';
import { BasicTable } from '@ardoq/table';
import { horizontalSpacing } from 'components/DiffMergeTable/styleVariables';
import * as localFonts from 'app/designTokens/fonts';
import { Name } from './EntityRepresentation/atoms';
import { colors, s16, s24, s32, s64, s8 } from '@ardoq/design-tokens';
import { fontMixins } from '@ardoq/typography';

export const Status = styled.div<{ $color: string }>`
  background-color: ${props => props.$color};
  border-radius: ${s8};
  height: ${s16};
  width: ${s16};
  margin-left: ${s24};
`;

export const Radio = styled(Icon)`
  cursor: pointer;
  margin-right: ${horizontalSpacing.SMALLEST};
  color: ${colors.grey25};
`;

export const ResetButton = styled(Icon)`
  cursor: pointer;
  margin: 0 ${horizontalSpacing.SMALL};
  color: ${colors.grey25};
`;

export const BaseTr = styled.tr<{
  $hoverColor?: string;
  $withPointer?: boolean;
}>`
  .hiddenIcon {
    visibility: hidden;
  }
  &:hover {
    background: ${({ $hoverColor = colors.grey95 }) => $hoverColor};
    .hiddenIcon {
      visibility: visible;
    }
    ${({ $withPointer }) =>
      $withPointer &&
      css`
        cursor: pointer;
      `};
    [data-ardoq-popover-id]:not(.no-underline) {
      display: inline-flex;
    }
  }
`;

export const TrWithPointerOnHover = styled(BaseTr).attrs({
  $withPointer: true,
})``;

export const ExpandedContentTr = styled.tr`
  background: ${colors.grey95};
`;

export const SubheaderTr = styled(BaseTr).attrs({
  $hoverColor: colors.grey92,
})`
  background: ${colors.grey90};
`;

export const MetaInfoTr = styled(BaseTr).attrs({
  $hoverColor: colors.grey80,
})`
  background: ${colors.grey80};
  td {
    height: ${s24};
  }
`;

export const MetaInfoTrWithPadding = styled(BaseTr).attrs({
  $hoverColor: colors.grey80,
})`
  background: ${colors.grey80};
  td {
    height: ${s32};
    padding-bottom: ${s8};
  }
`;

export const StyledTable = styled(BasicTable.Table)`
  table-layout: fixed;
`;

export const PropertyValue = styled.div`
  ${localFonts.pageViewMediumTemp};
  color: ${colors.grey15};
  display: flex;
  align-items: center;
  padding: 0 ${horizontalSpacing.LARGEST} 0 0;
`;

export const MetaInfoValue = styled(PropertyValue)`
  ${localFonts.mediumItalicTemp};
  color: ${colors.grey50};
`;

export const FieldName = styled.div`
  ${localFonts.pageViewMediumTemp};
  color: ${colors.grey15};
  padding: 0 ${horizontalSpacing.LARGE} 0 ${horizontalSpacing.LARGEST};
`;

export const MetaInfoLabel = styled(FieldName)`
  ${localFonts.mediumItalicTemp};
  color: ${colors.grey50};
`;

export const Aligner = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioButtonContainer = styled(Aligner)`
  justify-content: flex-end;
`;

export const StyledTh = styled(BasicTable.Th)`
  ${localFonts.pageViewMediumTemp};
  color: ${colors.grey15};
  text-transform: uppercase;
  padding: 0;
  height: 56px;
  background: ${colors.grey80};
  position: sticky;
  top: -1px;
  z-index: 1;
  &:hover {
    background: ${colors.grey80};
  }
`;

export const EntityMergeTh = styled(StyledTh)`
  height: 24px;
  padding-top: ${s16};
`;

export const BorderlessThead = styled(BasicTable.Thead)`
  border-bottom: 0;
`;

export const StyledTd = styled(BasicTable.Td)`
  width: auto;
  padding: 0;
  height: 52px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${colors.grey15};
  border-bottom: 1px solid ${colors.grey80};
  &:only-child {
    border-bottom: 1px solid ${colors.grey80};
  }

  ${Name}[data-ardoq-popover-id] {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
  }
`;

export const Expander = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: ${s16};
  color: ${colors.grey35};
`;
export const CheckboxWithPointer = styled(CheckboxIcon)`
  cursor: pointer;
  margin: 0 ${horizontalSpacing.SMALL} 0 ${horizontalSpacing.SMALL};
  color: ${colors.grey25};
`;

export const DisabledCheckbox = styled(CheckboxWithPointer)`
  cursor: default;
  opacity: 0.5;
`;

export const DisabledRadio = styled(Radio)`
  cursor: default;
  opacity: 0.5;
`;

export const AdditionalContentWrapper = styled.div`
  padding-bottom: ${s16};
  tr:hover {
    background: ${colors.grey92};
  }
`;

export const SectionHeaderLabel = styled.span`
  ${fontMixins.semibold16}
  margin-right: ${s8};
`;

export const AdditionalContentHintText = styled.div`
  ${fontMixins.semibold16}
  white-space: normal;
  max-width: 800px;
  padding-left: ${s64};
  margin-top: ${s16};
`;

export const AdditionalContentWithBottomMargin = styled(
  AdditionalContentHintText
)`
  ${localFonts.pageViewMediumTemp};
  margin-bottom: ${s16};
`;

export const EntityRepresentationWrapper = styled.div`
  padding-left: ${s64};
  & > * {
    max-width: 800px;
  }

  &:hover {
    background: ${colors.grey80};
  }
`;

export const AncestorsConflictContentWrapper = styled(AdditionalContentWrapper)`
  display: flex;
  padding: ${s16} ${s64};
`;

export const AncestorsConflictSectionWrapper = styled.div`
  flex: 0 330px;
`;

export const AncestorsConflictSectionTitle = styled.div`
  ${fontMixins.semibold16}
  padding: 0 0 ${s8} 0;
`;

export const EmptyPageWrapper = styled.div`
  min-width: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
