import { APIOrganizationUser, ArdoqId } from '@ardoq/api-types';
import { IconButton, GhostButton } from '@ardoq/button';
import { s8, s16, colors } from '@ardoq/design-tokens';
import { IconName, PersonIcon } from '@ardoq/icons';
import { Box, FlexBox } from '@ardoq/layout';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { Text } from '@ardoq/typography';
import { TableContainer } from './atoms';
import { Tag, WarningNotification } from '@ardoq/status-ui';
import { info, ModalSize } from '@ardoq/modal';
import { trackEvent } from 'tracking/tracking';
import { WithPopover } from '@ardoq/popovers';

const UsersTable = ({ users }: { users: APIOrganizationUser[] }) => {
  return (
    <Box padding="large">
      <TableContainer>
        <DatasourceTable
          style={{ width: '100%' }}
          columns={[
            {
              title: 'Main approver',
              cellStyle: {
                width: '60%',
                padding: `${s8} ${s16}`,
              },
              valueRender: (_, user) => (
                <FlexBox align="center" gap="small">
                  <PersonIcon color={colors.icon} />
                  <Text color="textSubtle">{user.name}</Text>
                </FlexBox>
              ),
            },
            {
              title: 'Email',
              cellStyle: {
                width: '40%',
                padding: `${s8} ${s16}`,
              },
              valueRender: (_, user) => (
                <Text color="textSubtle">{user.email}</Text>
              ),
            },
          ]}
          components={newTableTheme}
          dataSource={users}
        />
      </TableContainer>
    </Box>
  );
};

const AudienceMemberText = ({
  isEditable,
  text,
}: {
  isEditable: boolean;
  text: string;
}) => <Text color={isEditable ? 'textModerate' : 'textSubtle'}>{text}</Text>;

type AudienceMemberBase = {
  id: ArdoqId;
  icon: JSX.Element;
  name: string | undefined;
  email: string | undefined;
  users: APIOrganizationUser[];
  editable: boolean;
};

export type UserGroupAudienceMember = AudienceMemberBase & {
  type: 'userGroup';
  userGroup: ArdoqId;
};

export type UserAudienceMember = AudienceMemberBase & {
  type: 'user';
  user: ArdoqId;
};

export type FallbackAudienceMember = AudienceMemberBase & {
  type: 'fallbackUser';
  fallbackUser: ArdoqId;
};

type AudienceMember =
  | UserAudienceMember
  | UserGroupAudienceMember
  | FallbackAudienceMember;

type AudienceMembers =
  | (UserGroupAudienceMember | UserAudienceMember)[]
  | FallbackAudienceMember[];

type AudienceDisplayProps = {
  title: string;
  audienceMembers: AudienceMembers;
  noAudienceText: string;
  nonEditableText?: string;
  unselectAudienceMember: (audienceMember: AudienceMember) => void;
};

const AudienceDisplay = ({
  title,
  audienceMembers,
  noAudienceText,
  nonEditableText = 'Default',
  unselectAudienceMember,
}: AudienceDisplayProps) => {
  const showUsers = (groupName: string, users: APIOrganizationUser[]) => {
    info({
      title: groupName,
      body: <UsersTable users={users} />,
      modalSize: ModalSize.M,
    });
  };
  return (
    <TableContainer>
      <DatasourceTable<AudienceMember>
        style={{ width: '100%' }}
        columns={[
          {
            title,
            cellStyle: {
              width: '60%',
              padding: `${s8} ${s16}`,
            },
            valueRender: (_, audienceMember) => (
              <FlexBox align="center" gap="small">
                {audienceMember.icon}
                <AudienceMemberText
                  isEditable={audienceMember.editable}
                  text={audienceMember.name ?? ''}
                />
                {!audienceMember.editable && <Tag>{nonEditableText}</Tag>}
              </FlexBox>
            ),
          },
          {
            title: 'Email',
            cellStyle: {
              width: '40%',
              padding: `${s8} ${s16}`,
            },
            valueRender: (_, audienceMember) => {
              if (audienceMember.type === 'userGroup') {
                return audienceMember.users.length ? (
                  <GhostButton
                    onClick={() => {
                      showUsers(
                        audienceMember.name ?? 'Users',
                        audienceMember.users
                      );
                      trackEvent(
                        'Change Approvals0 show users in group audience'
                      );
                    }}
                    data-tooltip-text="Preview emails"
                  >
                    <FlexBox align="center" gap="small" paddingX="small">
                      Multiple emails
                      <Tag>{audienceMember.users.length}</Tag>
                    </FlexBox>
                  </GhostButton>
                ) : (
                  <WarningNotification>No users found</WarningNotification>
                );
              }
              return (
                <AudienceMemberText
                  isEditable={audienceMember.editable}
                  text={audienceMember.email ?? ''}
                />
              );
            },
          },
          {
            cellStyle: {
              width: '5%',
              padding: s8,
            },
            valueRender: (_, audienceMember) => {
              const isDisabled = !audienceMember.editable;
              return (
                <WithPopover
                  content={() =>
                    isDisabled
                      ? 'At least one backup approver is required'
                      : undefined
                  }
                >
                  <div>
                    <IconButton
                      iconName={IconName.DELETE}
                      isDisabled={isDisabled}
                      onClick={() => {
                        unselectAudienceMember(audienceMember);
                        trackEvent(`Change Approvals0 delete ${title} member`);
                      }}
                    />
                  </div>
                </WithPopover>
              );
            },
          },
        ]}
        components={newTableTheme}
        dataSource={audienceMembers}
        renderEmptyTable={() => (
          <Text variant="text1Italic" color="textSubtle" align="center">
            {noAudienceText}
          </Text>
        )}
      />
    </TableContainer>
  );
};

export default AudienceDisplay;
