import { APIOrganizationUser, ArdoqId, UpdateType } from '@ardoq/api-types';
import { DoqType } from '@ardoq/doq';
import { ModalSize, confirm, info, modal } from '@ardoq/modal';
import NotificationRecipientsModal from './SurveyEditor/NotificationRecipientsModal';
import ResponseLogModal from './SurveyOverview/ResponseLog/ResponseLogModal';
import { dispatchAction } from '@ardoq/rxbeach';
import { setSurveyOverviewSearchParams } from './navigation/actions';

export const openNotificationsRecipientModal = async (
  admins: APIOrganizationUser[],
  surveyNotificationsAudience?: ArdoqId[] | null
) => {
  return modal<ArdoqId[]>(resolve => (
    <NotificationRecipientsModal
      resolve={resolve}
      surveyNotificationsAudience={surveyNotificationsAudience ?? []}
      admins={admins}
    />
  ));
};

export const confirmUnsubscribeModal = async (surveyName: string) => {
  return confirm({
    title: 'Unsubscribe from survey digest',
    modalSize: ModalSize.S,
    confirmButtonTitle: 'Yes',
    cancelButtonTitle: 'No',
    doqType: DoqType.BROADCAST,
    text: 'Do you want to unsubscribe?',
    text2: `This means you will no longer receive the survey digest email from "${surveyName}". 
    Are you sure you want to do this?`,
  });
};

export const unsubscriptionSuccessModal = async () => {
  return info({
    title: 'Unsubscribe from survey digest',
    modalSize: ModalSize.S,
    primaryButtonText: 'Close',
    doqType: DoqType.BROADCAST,
    text: 'You have successfully unsubscribed.',
    text2:
      'If you want to subscribe again, go to the survey details section of the survey and modify the audience list.',
  });
};

export const confirmDescriptionChangeModal = async () => {
  return confirm({
    title: 'Overwrite question description',
    modalSize: ModalSize.S,
    confirmButtonTitle: 'Yes',
    cancelButtonTitle: 'No, maintain my description',
    subtitle: 'Clicking yes will overwrite your existing description.',
    warningBlockMessage:
      "Would you like to overwrite the existing description with the new field's description?",
  });
};

export const confirmFieldChangeModal = async () => {
  return confirm({
    title: 'Field change warning',
    modalSize: ModalSize.XS,
    confirmButtonTitle: 'Yes',
    cancelButtonTitle: 'Cancel',
    subtitle: 'Do you still want to change this field?',
    warningBlockMessage:
      'There are some extra questions depending on this field question. After changing this field, your existing conditional questions will be removed.',
  });
};

export const confirmTurnOffChangeApproval = async () => {
  return confirm({
    title: 'Turn off response approval',
    modalSize: ModalSize.XS,
    confirmButtonTitle: 'Confirm',
    isConfirmButtonDanger: true,
    cancelButtonTitle: 'Cancel',
    subtitle: 'Do you wish to continue?',
    warningBlockMessage:
      'After turning off this feature, all pending answers will be removed. Deletion is irreversible.',
  });
};

export const confirmSurveyChangeCausingDeletionOfPendingAnswers = async () => {
  return confirm({
    title: 'Delete pending answers',
    modalSize: ModalSize.S,
    confirmButtonTitle: 'Confirm',
    isConfirmButtonDanger: true,
    cancelButtonTitle: 'Cancel',
    subtitle:
      'Making this change to the survey will invalidate all pending answers, and they will therefore be deleted on save. Do you wish to continue?',
    text: 'Before making this change, we suggest going through any pending answers for this survey and resolving them.',
    warningBlockMessage:
      'After persisting this change, all pending answers will be removed. Deletion is irreversible.',
  });
};

export const confirmQuestionDeletionModal = async () => {
  return confirm({
    title: 'Delete this question',
    modalSize: ModalSize.XS,
    confirmButtonTitle: 'Delete',
    isConfirmButtonDanger: true,
    cancelButtonTitle: 'Cancel',
    subtitle: 'Do you wish to continue?',
    warningBlockMessage:
      'There are some extra questions depending on this field question. After deleting this question, your all conditional questions will be removed. Deletion is irreversible.',
  });
};

export const openResponseLogModal = async (
  surveyName: string,
  updateType: UpdateType
) => {
  return modal(resolve => (
    <ResponseLogModal
      closeResponseLogModal={() => {
        dispatchAction(setSurveyOverviewSearchParams(undefined));
        resolve(false);
      }}
      surveyName={surveyName}
      initialUpdateType={updateType}
    />
  ));
};

const getCommonPendingApprovalModalProps = (hasPendingApproval: boolean) => ({
  warningBlockMessage: hasPendingApproval
    ? 'After unpublishing this survey, all pending response approval answers will be removed. Deletion is irreversible.'
    : null,
  isConfirmButtonDanger: hasPendingApproval,
});

export const confirmUnpublishModal = async (hasPendingApproval: boolean) => {
  return confirm({
    modalSize: ModalSize.S,
    title: "You're about to unpublish this survey",
    text: 'Until publishing again, this survey will be visible only to admins.',
    confirmButtonTitle: 'Unpublish survey',
    ...getCommonPendingApprovalModalProps(hasPendingApproval),
  });
};

export const confirmUnpublishDueToErrorModal = async (
  hasPendingApproval: boolean
) => {
  return confirm({
    modalSize: ModalSize.S,
    title: 'This survey must be unpublished due to errors',
    text: 'The survey will only be visible to admins until the errors are fixed and the survey is re-published. Are you sure you would like to save these changes and unpublish this survey?',
    confirmButtonTitle: 'Unpublish survey',
    cancelButtonTitle: 'Cancel Save',
    ...getCommonPendingApprovalModalProps(hasPendingApproval),
  });
};

export const confirmUnpublishWhenBroadcastsModal = async (
  hasPendingApproval: boolean
) => {
  return confirm({
    modalSize: ModalSize.S,
    title: "You're about to unpublish this survey",
    text: `You have one or more broadcasts running against this survey.
    If you unpublish the survey it will only be visible to admins
    and your broadcasts will be stopped.
    Are you sure you would like to unpublish this survey?`,
    confirmButtonTitle: 'Unpublish survey',
    ...getCommonPendingApprovalModalProps(hasPendingApproval),
  });
};

export const confirmUnpublishDueToErrorWhenBroadcastsModal = async (
  hasPendingApproval: boolean
) => {
  return confirm({
    modalSize: ModalSize.S,
    title: 'This survey must be unpublished due to errors',
    text: `You have one or more broadcasts running against this survey.
    The survey it will only be visible to admins
    and your broadcasts will be stopped.
    This will remain the case until the errors are fixed and the survey is re-published. Are you sure you would like to save these changes and unpublish this survey?`,
    confirmButtonTitle: 'Unpublish survey',
    cancelButtonTitle: 'Cancel Save',
    ...getCommonPendingApprovalModalProps(hasPendingApproval),
  });
};
