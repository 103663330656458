import styled from 'styled-components';
import ProgressBar from 'broadcasts/components/ProgressBar';
import { InstanceProgress } from '@ardoq/api-types';
import { getInstanceProgress } from './utils';
import { s8 } from '@ardoq/design-tokens';

type ProgressWidgetProps = {
  readonly progress: InstanceProgress;
};

const Wrapper = styled.div`
  width: -moz-fit-content;
  width: fit-content;
`;

const StatContainer = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  margin: ${s8} 0;
`;

const Big = styled.div`
  font-size: 28px;
  font-weight: 200;
  align-self: center;
  margin: 0 ${s8};
`;

const PercentageSign = styled.span`
  font-size: 12px;
`;

const ProgressWidget = ({ progress }: ProgressWidgetProps) => {
  const { submissionRate, total } = getInstanceProgress(progress);
  return (
    <Wrapper>
      <StatContainer>
        <div>
          <b>
            {submissionRate} <PercentageSign>%</PercentageSign>
          </b>
          <div>submission rate </div>{' '}
        </div>
        <Big>/</Big>
        <div>
          <div>out of</div>
          <div>
            <b>{total}</b> surveys
          </div>
        </div>
      </StatContainer>
      <ProgressBar $percentage={submissionRate} />
    </Wrapper>
  );
};

export default ProgressWidget;
