import CurrentUser from 'models/currentUser';
import { confirm } from '@ardoq/modal';
import { PersonalSetting } from '@ardoq/api-types';

type MaybeShowConfirmProps = ({
  title,
  body,
  personalSettingKey,
}: {
  title: string;
  body: string;
  personalSettingKey: PersonalSetting;
}) => Promise<boolean>;

export const maybeShowConfirm: MaybeShowConfirmProps = async ({
  personalSettingKey,
  title,
  body,
}) => {
  if (CurrentUser.getPersonalSetting(personalSettingKey)) {
    return true;
  }

  const result = await confirm({
    title,
    text: body,
    confirmButtonTitle: 'Ok',
    cancelButtonTitle: 'Cancel',
    showRememberActionCheckbox: true,
    rememberActionCustomCheckboxText: 'Don’t show me this message again',
  });

  if (result && result.rememberAction) {
    CurrentUser.setPersonalSetting(personalSettingKey, true);
  }
  return Boolean(result);
};
