import { dispatchAction, connect } from '@ardoq/rxbeach';
import { showImportErrorModal, showSupport } from 'integrations/actions';
import { TransferError } from 'integrations/common/components/transfer/transferError/TransferError';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { combineLatest, map, switchMap } from 'rxjs';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { TransferState } from 'integrations/common/streams/transferState/types';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';

const handleMessageSupport = (
  integrationName: string,
  transferDirection: string
) => {
  dispatchAction(
    showSupport({
      message: `Help! There was a technical issue with my ${integrationName} ${transferDirection}`,
    })
  );
};

export const handleShowReport = () => {
  dispatchAction(showImportErrorModal());
};

function TransferErrorWrapper({
  integrationName,
  transferState,
}: {
  integrationName: string;
  transferState: TransferState;
}) {
  if (transferState.requestStatus !== 'FAILURE') {
    return <></>;
  }
  return (
    <TransferError
      handleOpenReport={handleShowReport}
      handleMessageSupport={() =>
        handleMessageSupport(integrationName, transferState.transferDirection)
      }
      transferDirection={transferState.transferDirection}
    />
  );
}

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    const integrationName = getIntegrationTermsDictionaryStream(
      integrationId
    ).pipe(map(({ name }) => name));
    return combineLatest({
      integrationName,
      transferState: getTransferStateStream(integrationId),
    });
  })
);

export const TransferErrorContainer = connect(TransferErrorWrapper, viewModel$);
