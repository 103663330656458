import { useState } from 'react';
import { FormFooter, FormWrapper, TextInput } from '@ardoq/forms';
import { ButtonGroup, PrimaryButton } from '@ardoq/button';
import { dispatchAction } from '@ardoq/rxbeach';
import { WebhookStreamState, registerWebhook } from './webhook$';
import { WarningNotification } from '@ardoq/status-ui';
import { WebhookMessage } from './WebhookMessage';

const CreateWebhookForm = ({
  registrationError,
}: Pick<WebhookStreamState, 'registrationError'>) => {
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  return (
    <div>
      <WebhookMessage />
      <FormWrapper>
        {registrationError && (
          <WarningNotification>{registrationError}</WarningNotification>
        )}
        <TextInput label="Name" onValueChange={setName} value={name} />
        <TextInput label="Url" onValueChange={setUrl} value={url} />
        <FormFooter>
          <ButtonGroup>
            <PrimaryButton
              isDisabled={name.length === 0 || url.length === 0}
              onClick={() => dispatchAction(registerWebhook({ name, url }))}
            >
              Create
            </PrimaryButton>
          </ButtonGroup>
        </FormFooter>
      </FormWrapper>
    </div>
  );
};

export default CreateWebhookForm;
