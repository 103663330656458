import { Link } from '@ardoq/typography';
import styled from 'styled-components';

type ChatMessageCitationProps = {
  label: string;
  href: string;
};

const SuperScript = styled.sup`
  a {
    text-decoration: none; /* Overrides the rule from the MarkdownViewer styled component */
  }
`;

export const ChatMessageCitation = ({
  label,
  href,
}: ChatMessageCitationProps) => (
  <SuperScript>
    <Link
      href={href}
      target="_blank"
      typography="caption"
      underlineOnHover
      hideIcon
    >
      {label}
    </Link>
  </SuperScript>
);
