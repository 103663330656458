import styled from 'styled-components';
import IconClickable from 'atomicComponents/IconClickable';
import { colors } from '@ardoq/design-tokens';

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 90px;
`;

export const StyledIcon = styled(IconClickable)`
  color: ${colors.grey35};
  &:hover {
    color: ${colors.blue50};
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58vh;
  width: 100%;
`;
