import { MouseEventHandler, createElement } from 'react';
import styled, { css } from 'styled-components';
import { GridOnIcon, Icon, IconName } from '@ardoq/icons';
import { s8, colors } from '@ardoq/design-tokens';
import FlatWhiteButton, {
  flatWhiteButtonStyle,
} from 'atomicComponents/FlatWhiteButton';

type HasEaseOfUseTransientArgs = {
  $hasEaseOfUse?: boolean;
};

const gridEditorHeaderIconStyles = css<HasEaseOfUseTransientArgs>`
  vertical-align: top;
  cursor: pointer;
  padding: 0 ${s8};
  ${({ $hasEaseOfUse }) =>
    $hasEaseOfUse
      ? css`
          color: ${colors.grey35};
        `
      : css`
          ${flatWhiteButtonStyle}
          color: inherit;
        `}
`;

const GridEditorToolbarIcon = styled(Icon)<HasEaseOfUseTransientArgs>`
  ${gridEditorHeaderIconStyles}
`;

export type ToolbarIconButtonProps = {
  id: string;
  iconName: IconName;
  onClick: MouseEventHandler;
};
export const ToolbarIconButton = (props: ToolbarIconButtonProps) => {
  return createElement(GridEditorToolbarIcon, props);
};

export const GridEditorIcon = styled(GridOnIcon)<HasEaseOfUseTransientArgs>`
  ${gridEditorHeaderIconStyles}
`;

const btnMaterial = css`
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  min-width: 64px;
  transition: all 0.2s ease-out;
`;

export const StyledGridEditorTabButton = styled(FlatWhiteButton)<{
  $isActive: boolean;
}>`
  ${btnMaterial}
  height: 100%;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  padding: 0px 32px;
  ${props => {
    return (
      props.$isActive &&
      css`
        border-bottom-color: white;
        color: white;
      `
    );
  }}
`;
