import { Connection } from '@ardoq/api-types/integrations';
import { ApiError } from 'integrations/common/utils/api';
import { ProviderId } from 'integrations/unified/types';
import { Resource } from './types';

export async function getResources({
  connectionId,
  providerId,
}: {
  providerId: ProviderId;
  connectionId: Connection['_id'];
}) {
  const response = await fetch(
    `/api/integrations/unified/${providerId}/connections/${connectionId}/resources`
  );

  if (response.status >= 400) {
    throw new ApiError(
      `Unable to fetch ${providerId} resources`,
      response.status,
      response.statusText,
      { connectionId, providerId }
    );
  }

  const resources = (await response.json()) as {
    resources: { definitions: Resource[] };
  };

  return resources.resources.definitions;
}

export async function getResource({
  connectionId,
  providerId,
  resourceId,
}: {
  providerId: ProviderId;
  connectionId: Connection['_id'];
  resourceId: Resource['id'];
}): Promise<Resource> {
  const response = await fetch(
    `/api/integrations/unified/${providerId}/connections/${connectionId}/resources/${resourceId}`
  );

  if (response.status >= 400) {
    throw new ApiError(
      `Unable to fetch ${providerId} ${resourceId} fields`,
      response.status,
      response.statusText,
      {
        connectionId,
        providerId,
        resourceId,
      }
    );
  }

  return response.json();
}
