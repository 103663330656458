import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, s4 } from '@ardoq/design-tokens';
import { Icon, IconName, IconSize } from '@ardoq/icons';

const CopyWrapper = styled.span<{ $isCopied: boolean }>`
  display: flex;
  align-items: center;
  font-size: inherit;

  span {
    opacity: ${props => (props.$isCopied ? '1' : '0')};
    transition: opacity 200ms ease-in-out;
  }
`;

const CopyIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 ${s4};
  &:hover {
    transition: fill 200ms ease-in-out;
    fill: ${colors.blue50};
  }
`;

interface CopyToClipboardProps {
  content: string;
  tooltipText: string;
  children: ReactNode;
}

const CopyToClipboard = ({
  content,
  tooltipText,
  children,
}: CopyToClipboardProps) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const timeOut = setTimeout(() => setIsCopied(false), 1000);
      return () => {
        if (timeOut) clearTimeout(timeOut);
      };
    }
  }, [isCopied]);

  const handleCopyUrl = (content: string) => {
    setIsCopied(true);
    navigator.clipboard.writeText(content);
  };

  return (
    <CopyWrapper $isCopied={isCopied}>
      {children}
      <CopyIcon
        onClick={() => handleCopyUrl(content)}
        data-tooltip-text={tooltipText}
        iconName={IconName.COPY}
        iconSize={IconSize.SMALL}
      />
      <span>Copied!</span>
    </CopyWrapper>
  );
};

export default CopyToClipboard;
