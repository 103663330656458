import Fields from 'collections/fields';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { CalculatedFieldOptions } from './types';
import { FieldUsageWorkspace } from '@ardoq/api-types';

/* Temporary function to find field ids from calculated field query id
   An API endpoint for this is planned (Kristian) */
export const getFieldIdsOfQuery = (calculatedFieldQueryId: string): string[] =>
  Fields.collection
    .filter(
      ({ attributes }) =>
        attributes.calculatedFieldSettings?.storedQueryId ===
        calculatedFieldQueryId
    )
    .map(({ id }) => id);

export const mapFieldUsageToCalcualtedFieldOptions = (
  fieldUsageWorkspace: FieldUsageWorkspace[]
): CalculatedFieldOptions => {
  const calculatedFieldOptions = fieldUsageWorkspace.map(
    ({
      workspaceId,
      affectedComponentIds = [],
      affectedReferenceIds = [],
    }) => ({
      label: workspaceInterface.getAttributes(workspaceId, ['name']).name ?? '',
      value: workspaceId,
      ids: [...affectedComponentIds, ...affectedReferenceIds],
    })
  );
  return { calculatedFieldOptions };
};
