import { TraversalSuggestion } from '../types';
import styled from 'styled-components';
import { s8 } from '@ardoq/design-tokens';
import { InnerIsland } from '@ardoq/snowflakes';
import { Card } from '@ardoq/card';
import { Box, Stack } from '@ardoq/layout';

type SuggestionsProps = {
  suggestions: TraversalSuggestion[];
  applySuggestion: (suggestion: TraversalSuggestion) => void;
};

export const Suggestions = ({
  suggestions,
  applySuggestion,
}: SuggestionsProps) => {
  if (suggestions.length === 0) return null;
  return (
    <Box marginX="medium">
      <InnerIsland title="Try asking">
        <Stack gap="small">
          {suggestions.map(s => (
            <SuggestionCard
              title={s.prompt}
              key={s.prompt}
              onClick={() => applySuggestion(s)}
              titleLineClamp={3}
            />
          ))}
        </Stack>
      </InnerIsland>
    </Box>
  );
};

const SuggestionCard = styled(Card)`
  padding-bottom: ${s8};
`;
