/** These values are used both for the user's preferences in the API and to identify elements in state */
export enum SectionSettingKey {
  CONTAINER = 'Container',
  COMPONENTS = 'Components',
  REFERENCES = 'References',
  WORKSPACES = 'Workspaces',
  DYNAMIC_FILTERS = 'Dynamic_Filters',
}

export type SectionExpandedSettings = {
  [settingKey in SectionSettingKey]: boolean;
};
