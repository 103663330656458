import { setTransferConfigId } from 'integrations/common/streams/activeIntegrations/actions';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { clearTabularMapping } from 'integrations/common/streams/tabularMappings/actions';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { applySourceConfig } from 'integrations/unified/streams/config/actions';
import { resetSelectedResources } from 'integrations/unified/streams/resources/actions';
import fp from 'lodash/fp';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { combineLatest, of } from 'rxjs';
import { filter, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { SelectConfigs } from 'integrations/common/components/selectors/SelectConfigs';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getIsUserAgreeToOverrideMapping } from '../../../../common/streams/transferConfigs/utils';

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      configs: getTransferConfigsStream(integrationId),
      selectedTransferConfigId: getActiveIntegrationStream(integrationId).pipe(
        map(ci => ci.selectedTransferConfigId),
        distinctUntilChanged()
      ),
      connections: getConnectionsStream(integrationId),
      integrationTermsDictionary:
        getIntegrationTermsDictionaryStream(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
    });
  }),
  map(
    ({
      integrationId,
      configs: { configs },
      selectedTransferConfigId,
      connections,
      integrationTermsDictionary: { selectConfigHelperText },
      tabularMapping,
    }) => ({
      transferConfigs: configs,
      selectedConfigId: selectedTransferConfigId,
      selectConfigHelperText,
      onSelect: async (configId: SavedTransferConfig['_id'] | null) => {
        if (selectedTransferConfigId === configId) {
          return;
        }

        const transferConfig = configs.find(m => m._id === configId);

        if (!transferConfig) {
          return;
        }

        const isUserAgreeToOverrideMapping =
          await getIsUserAgreeToOverrideMapping(tabularMapping);

        if (!isUserAgreeToOverrideMapping) {
          return;
        }

        dispatchAction(clearTabularMapping({ integrationId }));
        dispatchAction(setTransferConfigId({ integrationId, id: configId }));

        if (transferConfig.sourceConfig) {
          dispatchAction(
            applySourceConfig({
              integrationId,
              // We are not overiding the connectionId if there is a selectedConnectionId
              sourceConfig: fp.update(
                'connectionId',
                cid => connections.selectedConnectionIds[0] || cid,
                transferConfig.sourceConfig
              ),
            })
          );
        }
      },
      onClearConfiguration: () => {
        dispatchAction(resetSelectedResources({ integrationId }));
        dispatchAction(clearTabularMapping({ integrationId }));
        dispatchAction(setTransferConfigId({ integrationId, id: null }));
      },
    })
  )
);

export const ConfigSelector = connect(SelectConfigs, viewModel$);
