import styled, { css } from 'styled-components';

const Box = styled.div<{
  $flex?: string;
  $margin?: string;
  $padding?: string;
}>`
  flex: ${props => props.$flex ?? 1};
  ${({ $margin }) =>
    $margin &&
    css`
      margin: ${$margin};
    `}
  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${$padding};
    `}
`;

export default Box;
