import { EnhancedScopeData } from '@ardoq/data-model';
import { dateRangeOperations } from '@ardoq/date-range';
import {
  FieldType,
  RenderOption,
  RenderOptions,
  getFieldLabelForComponent,
} from '@ardoq/renderers';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { ReferenceTableRow, ReferenceAttributeRow } from '../types';
import { localeCompareNumericLowercase, getDefaultLocale } from '@ardoq/locale';

export const getFieldLabel = (
  fieldName: string,
  scopeData: EnhancedScopeData
) => {
  const fieldLabel =
    getFieldLabelForComponent(scopeData, fieldName) ?? fieldName;
  if (fieldInterface.isDateRangeField(fieldName)) {
    return dateRangeOperations.dashFormatDateRangeFieldLabel(fieldLabel);
  }
  return fieldLabel;
};

export const renderOptions: RenderOptions = {
  definitions: {
    [RenderOption.MARKDOWN]: {
      renderMarkdownInline: true,
    },
  },
  types: {
    [FieldType.TEXT_AREA]: new Set([RenderOption.MARKDOWN]),
  },
};

export const isReferenceAttributeRow = (
  row: ReferenceTableRow
): row is ReferenceAttributeRow =>
  row && (row as ReferenceAttributeRow).isExpandableRow === true;

export const getFieldNamesAndLabels = (
  fieldNames: string[],
  scopeData: EnhancedScopeData
) => {
  return fieldNames
    .reduce<Array<{ fieldName: string; label: string }>>(
      (fieldNamesAndLabels, fieldName) => {
        return [
          ...fieldNamesAndLabels,
          {
            label: getFieldLabel(fieldName, scopeData),
            fieldName,
          },
        ];
      },
      []
    )
    .sort((a, b) =>
      localeCompareNumericLowercase(a.label, b.label, getDefaultLocale())
    );
};
