import { actionCreator } from '@ardoq/rxbeach';
import {
  APIResourcePermissionAttributes,
  ArdoqId,
  RequestResourceAccessResolutionOperationType,
  APIReviewRequestResourceAccessData,
} from '@ardoq/api-types';

// -----------------------------------------------------------
// ------------------ Review Access Request ------------------
// -----------------------------------------------------------

export const openReviewAccessRequestDialog = actionCreator<{ id: ArdoqId }>(
  '[ ReviewAccessRequest ] OPEN_REVIEW_ACCESS_REQUEST_DIALOG '
);
export const setRequestAccessData = actionCreator<{
  id: ArdoqId;
  response: APIReviewRequestResourceAccessData;
}>('[ ReviewAccessRequest ] SET_REQUEST_ACCESS_DATA');

export const setPermissionType = actionCreator<{
  permissionType: RequestResourceAccessResolutionOperationType;
}>('[ ReviewAccessRequest ] SET_PERMISSION_TYPE');

export const setSelectedGroup = actionCreator<{
  id?: ArdoqId;
}>('[ ReviewAccessRequest ] SET_SELECTED_GROUP');

export const saveAccessRequestReview = actionCreator(
  '[ ReviewAccessRequest ] SAVE_ACCESS_REQUEST_REVIEW'
);
export const saveAccessRequestReviewSuccess = actionCreator<
  APIResourcePermissionAttributes[]
>('[ ReviewAccessRequest ] SAVE_ACCESS_REQUEST_REVIEW_SUCCESS');
export const saveAccessRequestReviewFailure = actionCreator<{
  errorMessage: string;
}>('[ ReviewAccessRequest ] SAVE_ACCESS_REQUEST_REVIEW_FAILURE');
