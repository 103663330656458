import {
  APIResourcePermissionAttributes,
  ArdoqId,
  GroupResourcePermission,
  MetaModelComponentType,
  PermissionGroup,
  Zone,
} from '@ardoq/api-types';
import { SubdivisionsContext, ZonesById } from '@ardoq/subdivisions';
import { CurrentUserState } from 'streams/currentUser/currentUser$';
import {
  ChangeZoneGroupPermissionActionPayload,
  ComponentTypeFieldsGroupSearchParams,
  DeleteZoneGroupPermissionActionPayload,
  UpdateFieldsInComponentTypeInZoneActionPayload,
} from './zonesViewState$/types';
import { SubdivisionViewModelState } from 'subdivisionEditor/types';
import { GlobalFieldsByName } from 'streams/fields/globalFields$';
import { ApiZonesFailurePayload } from 'streams/subdivisions/types';
import { SearchByTypes } from './components/ComponentTypeFieldsGroupSearch';

type AddNewZoneGroupPermissionCommandPayload = {
  label: string;
  displayName: string;
  currentUser: CurrentUserState;
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>;
};

type MoveZoneGroupPermissionCommandPayload = {
  groupPermission: GroupResourcePermission;
  fromZonePermission: APIResourcePermissionAttributes;
  toZonePermission: APIResourcePermissionAttributes;
};

export type ZoneCommands = {
  deleteZone: (zone: Zone) => void;
  createNewZone: (zone: Zone) => Promise<{
    newZone: Zone | ApiZonesFailurePayload;
    permissions: APIResourcePermissionAttributes[];
  }>;
  addZoneGroupPermission: (
    data: AddNewZoneGroupPermissionCommandPayload
  ) => void;
  deleteZoneGroupPermission: (
    data: DeleteZoneGroupPermissionActionPayload
  ) => void;
  moveZoneGroupPermission: (
    data: MoveZoneGroupPermissionCommandPayload
  ) => void;
  changeZoneGroupPermission: (
    data: ChangeZoneGroupPermissionActionPayload
  ) => void;
  changeComponentTypeFieldsGroupSearchKey: (data: string) => void;
  changeComponentTypeFieldsGroupSearchBy: (data: SearchByTypes) => void;
  changeFieldsConfigInComponentTypeInZone: (
    data: UpdateFieldsInComponentTypeInZoneActionPayload
  ) => void;
  savePermissions: (
    subdivisionEditorState: SubdivisionViewModelState
  ) => Promise<boolean>;
  goToSelectDataStep: () => void;
  goToPermissionsConfigurationStep: () => void;
  selectAll: (
    zone: Zone,
    selectedComponentTypes: MetaModelComponentType[],
    componentTypeFieldsGroupSearch: ComponentTypeFieldsGroupSearchParams
  ) => void;
  deselectAll: (
    zone: Zone,
    selectedComponentTypes: MetaModelComponentType[],
    componentTypeFieldsGroupSearch: ComponentTypeFieldsGroupSearchParams
  ) => void;
  changeZoneName: (data: { zone: Zone; newName: string }) => void;
  setEditingZoneName: (zoneId: ArdoqId) => void;
  finishEditingZoneName: (hasError: boolean, zoneId: ArdoqId) => void;
};

export type ZonesViewModelState = {
  componentTypes: MetaModelComponentType[];
  subdivisionsContext: SubdivisionsContext;
  allGroups: PermissionGroup[];
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>;
  componentTypeFieldsGroupSearch: ComponentTypeFieldsGroupSearchParams;
  zonesById: ZonesById;
  globalFieldsByName: GlobalFieldsByName;
  currentUser: CurrentUserState;
  isLoading: boolean;
  editingZoneNames: Record<ArdoqId, boolean>;
  commands: ZoneCommands;
};

export enum PermissionSelectValueType {
  READ = 'read',
  EDIT = 'edit',
}
