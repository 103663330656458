import { Component } from '@ardoq/renderers';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { Branch } from 'components/DiffMergeTable/Branch';
import PopoverDataProvider, { ProvidedProps } from './PopoverDataProvider';
import { ItemWrapper } from './atoms';
import {
  getWorkspaceByEntity,
  resolveComponentsPath,
} from 'components/DiffMergeTable/utils';
import { PopoverRenderer } from '@ardoq/popovers';
import { IconDecorator } from '@ardoq/decorators';
import { IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';

const ComponentsAncestorsPopover = ({
  componentId,
  graphics,
  enhancedScopeData,
}: {
  componentId: ArdoqId;
} & ProvidedProps) => {
  const workspace = getWorkspaceByEntity(
    componentId,
    APIEntityType.COMPONENT,
    enhancedScopeData
  );

  return (
    <FlexBox flexDirection="column" gap="medium" minWidth="200px">
      {workspace && (
        <IconDecorator iconName={IconName.WORKSPACE}>
          {workspace.name}
        </IconDecorator>
      )}
      {resolveComponentsPath(componentId, enhancedScopeData).map(
        (component, i) => (
          <ItemWrapper
            key={component._id}
            style={{
              marginLeft: 32 * (i + 1),
            }}
          >
            <Component
              entityId={component._id}
              enhancedScopeData={enhancedScopeData}
              graphics={graphics}
            />
          </ItemWrapper>
        )
      )}
    </FlexBox>
  );
};

export const diffMergeComponentAncestorsPopoverRenderer: PopoverRenderer =
  target => {
    const {
      diffMergePopoverBranch: branch,
      diffMergePopoverComponentId: componentId,
    } = (target as HTMLElement)?.dataset || {};

    if (!componentId || !branch) return null;
    return (
      <PopoverDataProvider branch={branch as Branch}>
        {data => (
          <ComponentsAncestorsPopover componentId={componentId} {...data} />
        )}
      </PopoverDataProvider>
    );
  };
