import styled, { css } from 'styled-components';
import { header3, text2 } from '@ardoq/typography';
import { colors, r4, r8, s16, s24, s32, s4, s8 } from '@ardoq/design-tokens';
import { Space } from '@ardoq/style-helpers';
import { Icon, IconName, ICON_SELECTOR } from '@ardoq/icons';
import { MetaDataReference, TripleOptions } from 'viewpointBuilder/types';
import { ComponentType } from 'viewpointBuilder/components/ComponentType';
import { StatusType, Tag } from '@ardoq/status-ui';
import { ReactNode } from 'react';
import { FlexBox } from '@ardoq/layout';

export const SidePanelSection = styled(Space)`
  border-radius: ${r8};
  border: 1px solid ${colors.grey80};
  background-color: ${colors.grey95};
`;

export const Section = styled(SidePanelSection).attrs({
  $isVertical: true,
  $gap: 'none',
  $padding: 's8',
  $paddingLeft: 'none',
  $paddingRight: 'none',
})``;

export const List = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  ${text2};
  gap: ${s8};
  flex-direction: column;
`;

export const ListItem = styled.li<{
  $isClickable: boolean;
  $isDisabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  gap: ${s8};
  border-radius: ${r4};
  opacity: ${props => (props.$isDisabled ? 0.5 : 1)};
  ${ICON_SELECTOR} {
    margin-right: 0;
  }

  &:hover {
    ${props =>
      props.$isClickable
        ? css`
            background: ${colors.surfaceHover};
            cursor: pointer;
          `
        : ''}
  }
`;

export const WorkspaceAndFieldItem = styled(ListItem)`
  background-color: ${colors.white};
  border-radius: ${s4};
  border: 1px solid ${colors.grey80};
  padding: 0 ${s8};
  height: ${s24};
  margin: ${s4} 0;
  justify-content: space-between;
`;

export const FieldType = styled.span`
  text-transform: uppercase;
  color: ${colors.grey60};
`;

export const Label = styled.div`
  white-space: nowrap;
  max-width: 200px;
  align-items: center;
  display: flex;
`;

export const CountWrapper = styled.div`
  min-width: 1ch;
  justify-content: center;
  display: flex;
`;

export const EnforceContentWidth = styled.div`
  /*
  Topic is horizontal scrolling (which ideally shouldn't happen).
  Couldn't get the main content container to stretch to the width of the
  content. It would scroll horizontally, but only to the very right edge of
  the content, not adding the padding of the container. It's a bit a detail,
  but looks a bit bad without the right padding. Tried several approach, but
  could only get it to work with display table. The only purpose of the
  display table (which will add a virtual row and a virtual cell) is to force
  the container to take the width of the content.
  */
  display: table;
  width: 100%;
  height: 100%;
`;

export const TopRightCornerButtonContainer = styled.div`
  position: absolute;
  top: ${s32};
  right: ${s32};
`;

export const DoqContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FullHeightSpace = styled(Space)`
  height: 100%;
  background-color: ${colors.white};
  width: 100%;
  justify-content: center;
`;

const Header = styled(Space)`
  padding: ${s16};
  width: 100%;
`;

const SidebarHeaderTitle = styled.span`
  ${header3};
  color: ${colors.grey15};
  white-space: nowrap;
`;

type SelectedType =
  | {
      type: 'component';
      selectedType: TripleOptions['outgoing'][0]['metaDataComponent'];
    }
  | {
      type: 'reference';
      selectedType: MetaDataReference;
    };

type SidebarHeaderProps = {
  iconName: IconName;
  headerText: string;
  rightContent?: ReactNode;
} & SelectedType;

export const SidebarHeader = ({
  type,
  selectedType,
  iconName,
  headerText,
  rightContent,
}: SidebarHeaderProps) => {
  return (
    <Header $align="center" $gap="s8">
      <FlexBox gap="small" align="center" flex={1}>
        <Icon iconName={iconName} color={colors.grey50} />
        <SidebarHeaderTitle>{headerText}</SidebarHeaderTitle>
        {type === 'reference' ? (
          <Tag
            // This isn't a warning, but StatusType.WARNING gives us the styling we want
            statusType={StatusType.WARNING}
            label={selectedType?.representationData?.displayLabel ?? ''}
            iconName={IconName.REFERENCE}
          />
        ) : (
          <ComponentType
            label={selectedType.label}
            representationData={selectedType.representationData}
          />
        )}
      </FlexBox>
      {rightContent ? <FlexBox align="center">{rightContent}</FlexBox> : null}
    </Header>
  );
};

export const IconWrapper = styled.span<{
  $borderColor: string;
  $backgroundColor: string;
}>`
  background-color: ${props => props.$backgroundColor};
  border: 1px solid ${props => props.$borderColor};
  border-radius: ${r4};
  padding-left: ${s4};
  padding-right: ${s4};
  align-items: center;
  height: ${s24};
  display: flex;
  box-shadow:
    0 0 0 2px white,
    0 0 0 4px ${colors.blue60};
`;
