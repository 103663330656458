import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId, PermissionGroup } from '@ardoq/api-types';

type Payload = {
  permissionGroupId: ArdoqId;
  userIds: ArdoqId[];
};

export const addUsersToGroup = actionCreator<Payload>(
  '[permissionGroups] PERMISSION_GROUP_ADD_USERS'
);

export const fetchPermissionGroups = actionCreator(
  '[permissionGroups] GET_PERMISSION_GROUPS'
);

export const setPermissionGroups = actionCreator<PermissionGroup[]>(
  '[permissionGroups] SET_PERMISSION_GROUPS'
);

export const deleteGroup = actionCreator<ArdoqId>(
  '[permissionGroups] DELETE_GROUP'
);

export const deleteGroupSuccess = actionCreator<ArdoqId>(
  '[permissionGroups] DELETE_GROUP_SUCCESS'
);

export const changeGroupName = actionCreator<{
  group: PermissionGroup;
  newName: string;
}>('[permissionGroups] CHANGE_GROUP_NAME');

export const setSearchPhraseAction = actionCreator<{ query: string }>(
  '[permissionGroups] SET_SEARCH_PHRASE'
);

export const setError = actionCreator<string>('[permissionGroups] SET_ERROR');
