import { EMPTY, from } from 'rxjs';
import {
  filter,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import {
  dispatchAction,
  collectRoutines,
  routine,
  extractPayload,
  ofType,
  withNamespace,
  withoutNamespace,
} from '@ardoq/rxbeach';
import storedQueries$ from 'streams/queries/storedQueries$';
import { queryAdvancedSearch } from './AdvancedSearch/actions';
import { queryGremlinSearchWarning } from './Gremlin/actions';
import {
  copyQuery,
  createSearchQuery,
  deleteQuery,
  deleteQuerySuccess,
  editSearchQuery,
  exportAdvancedSearchURL,
  exportGremlinSearchURL,
  loadCalculatedFieldStoredQuery,
  loadStoredQuery,
  resetAllSearchState,
  saveSearchQuery,
  saveSearchQueryError,
  saveSearchQuerySuccess,
  updateReturnLocation,
} from './actions';
import {
  fetchCalculatedFieldOptions,
  updateCalculatedFieldOptions,
} from 'streams/queries/calculatedFieldOptions/actions';
import { getFieldIdsOfQuery } from 'streams/queries/calculatedFieldOptions/helpers';
import { GremlinSearchSource } from './tracking';
import { searchBackendTypePairToSearchPaneMap } from './SearchTabContainer/types';
import {
  createNewQuery,
  executeGremlinSearch,
  getFixedCopiedName,
} from './helper';
import { advancedSearchRoutines } from './AdvancedSearch/routines';
import { advancedSearchForZonesRoutines } from './AdvancedSearchForZones/routines';
import { searchUtilRoutines } from './searchUtil/routines';
import { calculatedSearchRoutines } from './CalculatedSearch/routines';
import { gremlinResultsRoutines } from './Gremlin/routines';
import { dynamicSearchRoutines } from './DynamicSearch/routines';
import { selectSearchPane } from './SearchTabContainer/actions';
import { getEnumKeys } from 'utils/enums';
import { QueryEditorNamespace } from './QueryEditor/queryEditor$';
import { searchPaneRoutines } from './SearchTabContainer/routines';
import {
  ArdoqId,
  StoredQueryModel,
  QueryModel,
  GremlinSearchQuery,
  SearchType,
  QueryBuilderSubquery,
} from '@ardoq/api-types';
import searchTab$ from './SearchTabContainer/searchTab$';
import { handleError, storedQueryApi } from '@ardoq/api';
import { isArdoqError, toArdoqError } from '@ardoq/common-helpers';

const updateQuery = async (
  storedQueriesById: Record<ArdoqId, StoredQueryModel>,
  model: QueryModel,
  queryId: string,
  namespace?: string
) => {
  const storedQuery = storedQueriesById[queryId];
  if (!storedQuery) {
    return toArdoqError({
      error: new Error('Stored query not found when updating'),
    });
  }
  const updatedQuery = { ...storedQuery, ...model };

  const response = await storedQueryApi.update(updatedQuery);
  if (isArdoqError(response)) {
    return response;
  }
  dispatchAction(
    saveSearchQuerySuccess({
      storedQuery: response,
    }),
    namespace
  );
  return response;
};

const showCorrectModuleAndModeOnLoad = routine(
  ofType(loadStoredQuery),
  withLatestFrom(searchTab$),
  filter(([, { isOpenInModal }]) => {
    return !isOpenInModal;
  }),
  // reset return location
  tap(
    ([
      {
        meta: { namespace },
      },
    ]) =>
      dispatchAction(
        updateReturnLocation({
          returnLocation: null,
        }),
        namespace
      )
  ),
  tap(() =>
    dispatchAction(requestShowAppModule({ selectedModule: AppModules.SEARCH }))
  ),
  tap(
    ([
      {
        payload: {
          storedQuery: { backend, type },
        },
      },
    ]) =>
      dispatchAction(
        selectSearchPane({
          searchPane: searchBackendTypePairToSearchPaneMap.get(
            `${backend}${type}`
          )!,
        })
      )
  )
);

const setSelectedSearchPaneForDynamicFilters = routine(
  withNamespace(QueryEditorNamespace.DYANMIC_FILTER_QUERY),
  ofType(loadStoredQuery),
  extractPayload(),
  tap(({ storedQuery: { backend, type } }) =>
    dispatchAction(
      selectSearchPane({
        searchPane: searchBackendTypePairToSearchPaneMap.get(
          `${backend}${type}`
        )!,
      })
    )
  )
);

const executeAdvancedSearchOnLoad = routine(
  withNamespace(QueryEditorNamespace.ADVANCED_SEARCH),
  ofType(loadStoredQuery),
  extractPayload(),
  tap(({ storedQuery: { query } }) =>
    dispatchAction(
      queryAdvancedSearch({
        queryBuilderRules: query as QueryBuilderSubquery,
      })
    )
  )
);

const executeNormalGremlinSearch = routine(
  withNamespace(QueryEditorNamespace.GREMLIN_SEARCH),
  ofType(loadStoredQuery),
  extractPayload(),
  tap(({ storedQuery }) =>
    executeGremlinSearch(
      storedQuery.query as GremlinSearchQuery,
      SearchType.QUERY,
      GremlinSearchSource.STORED_GREMLIN_QUERY
    )
  )
);

const executePlainGraphFilter = routine(
  withNamespace(QueryEditorNamespace.DYANMIC_FILTER_QUERY),
  ofType(loadStoredQuery),
  extractPayload(),
  filter(({ storedQuery: { parameterQueryId } }) => !parameterQueryId),
  tap(({ storedQuery }) =>
    executeGremlinSearch(
      storedQuery.query as GremlinSearchQuery,
      SearchType.DYNAMIC_FILTER_QUERY,
      GremlinSearchSource.STORED_GREMLIN_QUERY
    )
  )
);

const loadParameterQueryForParameterizedGraphFilter = routine(
  withNamespace(QueryEditorNamespace.DYANMIC_FILTER_QUERY),
  ofType(loadStoredQuery),
  extractPayload(),
  filter(({ storedQuery: { parameterQueryId } }) => Boolean(parameterQueryId)),
  switchMap(({ storedQuery: { parameterQueryId } }) =>
    storedQueryApi.fetch(parameterQueryId!)
  ),
  handleError(),
  tap(response => {
    if (!response) return;
    dispatchAction(
      loadStoredQuery({
        storedQuery: response,
      }),
      QueryEditorNamespace.PARAMETER_QUERY
    );
  })
);

const updateOrCreateQuery = async (
  queryId: string | null,
  storedQueriesById: Record<string, StoredQueryModel>,
  model: QueryModel,
  namespace: string | undefined,
  selectAfterCreation: boolean | undefined
) => {
  const response = queryId
    ? await updateQuery(storedQueriesById, model, queryId, namespace)
    : await createNewQuery(model);
  if (isArdoqError(response)) {
    dispatchAction(saveSearchQueryError(), namespace);
    return EMPTY;
  }

  if (selectAfterCreation) {
    dispatchAction(
      loadStoredQuery({
        storedQuery: response,
      }),
      namespace
    );
    dispatchAction(editSearchQuery(), namespace);
  }
  return { response };
};

const handleSaveSearchQuery = routine(
  ofType(saveSearchQuery),
  withLatestFrom(storedQueries$),
  mergeMap(
    async ([
      {
        meta: { namespace },
        payload: { model, queryId, selectAfterCreation },
      },
      { storedQueriesById },
    ]) =>
      updateOrCreateQuery(
        queryId,
        storedQueriesById,
        model,
        namespace,
        selectAfterCreation
      )
  )
);

const handleDeleteQuery = routine(
  ofType(deleteQuery),
  mergeMap(({ meta: { namespace }, payload: { queryId, parameterQueryId } }) =>
    from(storedQueryApi.delete(queryId)).pipe(
      tap(() =>
        dispatchAction(
          deleteQuerySuccess({
            queryId,
            parameterQueryId,
          }),
          namespace
        )
      )
    )
  )
);

const fetchCalculatedFieldStoredQueryAndOptions = routine(
  ofType(loadCalculatedFieldStoredQuery),
  extractPayload(),
  tap(({ storedQuery, fieldId = getFieldIdsOfQuery(storedQuery._id)[0] }) => {
    dispatchAction(
      loadStoredQuery({
        storedQuery,
      }),
      QueryEditorNamespace.CALCULATED_FIELD_QUERY
    );
    if (fieldId) {
      dispatchAction(
        fetchCalculatedFieldOptions({
          fieldId,
        })
      );
    } else {
      dispatchAction(
        updateCalculatedFieldOptions({
          calculatedFieldOptions: [],
        })
      );
      dispatchAction(
        queryGremlinSearchWarning({
          searchWarning: 'The field for this calculation is deleted',
        }),
        SearchType.CALCULATED_FIELD_QUERY
      );
      dispatchAction(
        editSearchQuery(),
        QueryEditorNamespace.CALCULATED_FIELD_QUERY
      );
    }
  })
);

const handleExportAdvancedSearchUrl = routine(
  ofType(exportAdvancedSearchURL),
  extractPayload(),
  tap(({ queryId }) =>
    window.open(`/api/advanced-search/${queryId}/run`, '_blank')
  )
);

const handleExportGremlinSearchUrl = routine(
  ofType(exportGremlinSearchURL),
  extractPayload(),
  tap(({ queryId }) =>
    window.open(`/api/graph-search/${queryId}/run`, '_blank')
  )
);

const handleCopyQuery = routine(
  ofType(copyQuery),
  tap(({ meta: { namespace }, payload: { model } }) => {
    dispatchAction(
      saveSearchQuery({
        model: {
          ...model,
          name: getFixedCopiedName(model.name),
        },
        queryId: null,
        selectAfterCreation: true,
      }),
      namespace
    );
  })
);

/**
 * We need to redispatch the action for each namespace to make sure it reaches
 * the different queryEditor$ streams.
 */
const applyNamespacesToResetAllSearches = routine(
  withoutNamespace(),
  ofType(resetAllSearchState),
  tap(action => {
    for (const queryEditorNamespace of getEnumKeys(QueryEditorNamespace)) {
      dispatchAction(action, queryEditorNamespace);
    }
  })
);

const resetOnCreateSearchQuery = routine(
  ofType(createSearchQuery),
  tap(({ meta: { namespace } }) =>
    dispatchAction(resetAllSearchState(), namespace)
  )
);

const localSearchRoutines = collectRoutines(
  showCorrectModuleAndModeOnLoad,
  executeAdvancedSearchOnLoad,
  executeNormalGremlinSearch,
  executePlainGraphFilter,
  loadParameterQueryForParameterizedGraphFilter,
  handleSaveSearchQuery,
  handleDeleteQuery,
  fetchCalculatedFieldStoredQueryAndOptions,
  handleExportAdvancedSearchUrl,
  handleExportGremlinSearchUrl,
  handleCopyQuery,
  applyNamespacesToResetAllSearches,
  resetOnCreateSearchQuery,
  setSelectedSearchPaneForDynamicFilters
);

export const searchRoutines = collectRoutines(
  localSearchRoutines,
  searchUtilRoutines,
  advancedSearchRoutines,
  advancedSearchForZonesRoutines,
  gremlinResultsRoutines,
  calculatedSearchRoutines,
  dynamicSearchRoutines,
  searchPaneRoutines
);
