import styled from 'styled-components';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import ViewStatusTextBadge from '../ViewStatusTextBadge';
import { s4, s8 } from '@ardoq/design-tokens';

interface MediaTitleProperties {
  title: string;
  viewStatus: MetaInfoViewStatus;
}
const Container = styled.div`
  margin: ${s4} 0px ${s8};
  line-height: 22px;
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  font-size: 1.5em;
  font-weight: 500;
  vertical-align: middle;
`;
const MediaTitle = ({ title, viewStatus }: MediaTitleProperties) => (
  <Container>
    <Title className="title-text trim">{title}</Title>
    {viewStatus !== MetaInfoViewStatus.NONE && (
      <ViewStatusTextBadge viewStatus={viewStatus} />
    )}
  </Container>
);
export default MediaTitle;
