import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { ScheduleFailureNotificationState } from './types';
import { setSelectedUsers, setUpsertResult, setUsers } from './actions';

const defaultState: ScheduleFailureNotificationState = {
  users: {
    status: 'INIT',
  },
  upserResult: {
    status: 'INIT',
  },
  selectedUsers: [],
};

const setUsersHandler = (
  state: ScheduleFailureNotificationState,
  users: ScheduleFailureNotificationState['users']
) => ({
  ...state,
  users,
});

const setSelectedUsersHandler = (
  state: ScheduleFailureNotificationState,
  selectedUsers: string[]
) => ({
  ...state,
  selectedUsers,
});

const setUpsertResultHandler = (
  state: ScheduleFailureNotificationState,
  upserResult: ScheduleFailureNotificationState['upserResult']
) => ({
  ...state,
  upserResult,
});

export const scheduleFailureNotification$ = persistentReducedStream(
  'scheduleFailureNotification',
  defaultState,
  [
    reducer(setUsers, setUsersHandler),
    reducer(setSelectedUsers, setSelectedUsersHandler),
    reducer(setUpsertResult, setUpsertResultHandler),
  ]
);
