import { routine, extractPayload, ofType } from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs/operators';
import { trackIntegrationEvent } from './actions';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { trackEvent } from 'tracking/tracking';
import { EVENT_NAMES } from './events';

const handleTrackIntegrationEvent = routine(
  ofType(trackIntegrationEvent),
  extractPayload(),
  withLatestFrom(activeIntegrations$),
  tap(([{ integrationId, name, metadata }, activeIntegrations]) => {
    const trackingFunnelId = activeIntegrations[integrationId].trackingFunnelId;
    trackEvent(EVENT_NAMES[name], {
      ...(metadata || {}),
      integrationId,
      instanceId: integrationId,
      funnelId: trackingFunnelId,
    });
  })
);

export default [handleTrackIntegrationEvent];
