import { actionCreator } from '@ardoq/rxbeach';
import { ServiceNowTable } from '@ardoq/api-types/integrations';
import { actionCreatorWithIntegrationId } from '../../../common/utils/actionCreatorWithIntegrationId';

const NAMESPACE = '[integrations] SERVICENOW TABLES';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const resetState = actionCreator(getActionName('RESET_STATE'));

type FetchServiceNowTablesPayload = {
  connectionId: string;
};

export const setIsAsync = actionCreator<boolean>(getActionName('SET_IS_ASYNC'));

export const fetchServiceNowTables =
  actionCreatorWithIntegrationId<FetchServiceNowTablesPayload>(
    getActionName('FETCH_SERVICENOW_TABLES')
  );

export type FetchServiceNowTablesSuccessPayload = {
  tables: ServiceNowTable[];
};

export const fetchServiceNowTablesSuccess =
  actionCreator<FetchServiceNowTablesSuccessPayload>(
    getActionName('FETCH_SERVICENOW_TABLES_SUCCESS')
  );

export const fetchServiceNowTablesFailure = actionCreator(
  getActionName('FETCH_SERVICENOW_TABLES_FAILURE')
);
