import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import integrationViewState$ from 'integrations/integrationViewState$';
import currentUser$ from 'streams/currentUser/currentUser$';
import { permissionsOperations } from '@ardoq/access-control';
import { getTransferCount } from 'integrations/dashboard/utils';
import { ImportHistory, SourceType } from '@ardoq/api-types/integrations';
import { IntegrationIdBySourceTypes } from 'integrations/common/streams/activeIntegrations/sourceTypes';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import {
  getScheduleCommands,
  onIntegrationClick,
} from 'integrations/dashboard/commands';
import { connections$ } from 'integrations/common/streams/connections/connections$';
import { Features, hasFeature } from '@ardoq/features';
import { trackEvent } from 'tracking/tracking';
import { dispatchAction } from '@ardoq/rxbeach';
import { setVisibleIntegration } from 'integrations/actions';
import { Integration, RootPages } from 'integrations/types';
import { EVENT_NAMES } from 'integrations/common/tracking/events';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { isIntegrationId } from 'integrations/common/streams/activeIntegrations/utils';

export enum DashboardState {
  AllIntegrations,
  Overview,
}

const isOverviewAvailable = (isAdmin: boolean, transferCount: number) =>
  isAdmin && transferCount > 0;

const getDashboardState = ({
  isAdmin,
  transferCount,
  isLoadingImports,
}: {
  isAdmin: boolean;
  transferCount: number;
  isLoadingImports: boolean;
}) => {
  if (hasFeature(Features.DISABLE_INTEGRATIONS_DASHBOARD)) {
    return DashboardState.AllIntegrations;
  }
  return isOverviewAvailable(isAdmin, transferCount) || isLoadingImports
    ? DashboardState.Overview
    : DashboardState.AllIntegrations;
};

// BE doesn't populate `config.sources` for signavio-export
const sourceTypeBySourceName: Record<string, SourceType> = {
  'signavio-export': 'signavio-exporter',
};

export const getRecentIntegrationsIds = (imports: ImportHistory[]) => {
  return Array.from(
    imports.reduce((acc, val) => {
      const sourceType =
        val.config?.sources?.[0] || sourceTypeBySourceName[val.sourceName];

      const integrationId =
        sourceType && IntegrationIdBySourceTypes[sourceType];

      if (integrationId && !acc.has(integrationId)) {
        acc.add(integrationId);
      }

      return acc;
    }, new Set())
  ).flat() as IntegrationId[];
};

export const viewModel$ = combineLatest([
  integrationViewState$,
  currentUser$,
  connections$,
  activeIntegrations$,
]).pipe(
  map(
    ([
      { imports, isLoadingImports },
      currentUser,
      allConnections,
      activeIntegrations,
    ]) => {
      const isAdmin = permissionsOperations.isOrgAdmin(currentUser);

      const [importsCount, exportsCount] = getTransferCount(imports);

      const dashboardState = getDashboardState({
        isAdmin,
        transferCount: importsCount + exportsCount,
        isLoadingImports,
      });

      const onViewHistory = () => {
        trackEvent(EVENT_NAMES.CLICKED_VIEW_HISTORY);
        dispatchAction(
          setVisibleIntegration({ id: null, path: RootPages.HISTORY })
        );
      };

      const onIntegrationClickWrapper = async (integration: Integration) => {
        if (isIntegrationId(integration.id)) {
          const activeIntegration =
            activeIntegrations[integration.id as IntegrationId];
          return onIntegrationClick(
            integration,
            activeIntegration.integrationPath
          );
        }
        onIntegrationClick(integration);
      };

      return {
        scheduleCommands: getScheduleCommands(),
        onIntegrationClick: onIntegrationClickWrapper,
        importsCount,
        exportsCount,
        dashboardState,
        recentIntegrationsIds: getRecentIntegrationsIds(imports),
        allConnections,
        alertsEnabled: isAdmin,
        isLoading: isLoadingImports,
        onViewHistory,
      };
    }
  )
);
