import { CloudProviderRegion } from '@ardoq/api-types/integrations';
import { actionCreator } from '@ardoq/rxbeach';
import { CloudProviderIntegrationId } from 'integrations/cloudProviders/types';
import { AsyncStatus } from 'integrations/common/types/api';

const NAMESPACE = '[integrations cloud-providers] REGIONS';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

type WithCloudProviderIntegrationId = {
  integrationId: CloudProviderIntegrationId;
};

export type FetchRegionsPayload = WithCloudProviderIntegrationId & {
  connectionId: string;
};
export const fetchRegions = actionCreator<FetchRegionsPayload>(
  getActionName('FETCH_CLOUD_PROVIDERS_REGIONS')
);

export type FetchRegionsSuccessPayload = WithCloudProviderIntegrationId & {
  regions: CloudProviderRegion[];
};

export const fetchRegionsSuccess = actionCreator<FetchRegionsSuccessPayload>(
  getActionName('FETCH_CLOUD_PROVIDERS_REGIONS_SUCCESS')
);

export const fetchRegionsSuccessForCloudProvider =
  actionCreator<FetchRegionsSuccessPayload>(
    getActionName('FETCH_REGIONS_SUCCESS_FOR_CLOUD_PROVIDER')
  );

export const fetchRegionsFailure =
  actionCreator<WithCloudProviderIntegrationId>(
    getActionName('FETCH_CLOUD_PROVIDERS_REGIONS_FAILURE')
  );

export type UpdateRegionListPayload = WithCloudProviderIntegrationId & {
  regions: CloudProviderRegion[];
};
export const updateRegionList = actionCreator<UpdateRegionListPayload>(
  `${NAMESPACE}_UPDATE_REGIONS_LIST`
);

export type ChangeRegionsSelectionPayload = WithCloudProviderIntegrationId & {
  regions: string[];
};
export const changeRegionsSelection =
  actionCreator<ChangeRegionsSelectionPayload>(
    getActionName('CHANGE_REGIONS_SELECTION')
  );

export type UpdateAsyncStatusPayload = WithCloudProviderIntegrationId & {
  asyncStatus: AsyncStatus;
};

export const updateAsyncStatus = actionCreator<UpdateAsyncStatusPayload>(
  `${NAMESPACE}_UPDATE_ASYNC_STATUS`
);

export const resetRegions = actionCreator<WithCloudProviderIntegrationId>(
  getActionName('RESET_REGIONS')
);
