import React from 'react';
import { NavItem } from '@ardoq/layout';
import { UseCaseManagementSidebarProps, UseCaseTabs } from './types';

const ManagementSidebar = ({
  selectedTab,
  onManageClick,
  onCreateClick,
}: UseCaseManagementSidebarProps) => {
  return (
    <>
      <NavItem
        $active={selectedTab === UseCaseTabs.MANAGE}
        onClick={onManageClick}
      >
        Overview
      </NavItem>
      <NavItem
        $active={selectedTab === UseCaseTabs.CREATE}
        onClick={onCreateClick}
      >
        Create
      </NavItem>
      <NavItem
        disabled={selectedTab !== UseCaseTabs.EDIT}
        $active={selectedTab === UseCaseTabs.EDIT}
      >
        Edit
      </NavItem>
    </>
  );
};

export default ManagementSidebar;
