import {
  APIPresentationAssetAttributes,
  APISlideAttributes,
} from '@ardoq/api-types';
import { connect, derivedStream } from '@ardoq/rxbeach';
import { ActivePresentationWrapper } from 'components/presentationSidebar/components/atoms';
import {
  ActivePresentation,
  validSlides,
} from 'components/presentationSidebar/containers/ActivePresentation';
import NewPresentation from 'components/presentationSidebar/containers/newPresentation';
import {
  PresentationOverviewCommands,
  presentationOverviewCommands,
} from 'presentation/presentations2024/presentationOverviewCommands';
import presentationsOverview$ from 'presentation/presentations2024/presentationsOverview$';
import { optimisticPresentations$ } from 'streams/presentations/presentations$';
import { PresentationOverviewStreamShape } from 'presentation/presentations2024/types';
import { map } from 'rxjs';
import { PresentationsStreamShape } from 'streams/presentations/types';
import { PermissionContext } from '@ardoq/access-control';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { currentUserAccessControlInterface } from 'resourcePermissions/accessControlHelpers/currentUser';
import { UnauthorizedSlideType } from 'components/presentationSidebar/types';
import slides$ from 'streams/slides/slides$';
import { CollectionStream } from 'streams/utils/streamUtils';

type PresentationSidebarProps = {
  currentPresentation?: APIPresentationAssetAttributes | 'NEW';
  isSavingNewPresentation: boolean;
  slides: (APISlideAttributes | UnauthorizedSlideType)[];
  commands: PresentationOverviewCommands;
  permissionContext: PermissionContext;
  canCurrentUserWrite: boolean;
  currentUserIsOrgAdmin: boolean;
};

const PresentationSidebar = ({
  currentPresentation,
  isSavingNewPresentation,
  slides,
  commands,
  permissionContext,
  canCurrentUserWrite,
  currentUserIsOrgAdmin,
}: PresentationSidebarProps) => {
  if (!currentPresentation) return null;
  return (
    <ActivePresentationWrapper>
      {currentPresentation === 'NEW' ? (
        <NewPresentation loading={isSavingNewPresentation} />
      ) : (
        <ActivePresentation
          permissionContext={permissionContext}
          presentation={currentPresentation}
          slides={slides}
          onSlidesButtonClick={commands.openPresentationInEditor}
          onEditClick={commands.openPresentationInEditor}
          onCloseClick={commands.closePresentation}
          isPresentationPreview={true}
          canCurrentUserWrite={canCurrentUserWrite}
          currentUserIsOrgAdmin={currentUserIsOrgAdmin}
        />
      )}
    </ActivePresentationWrapper>
  );
};

const toPresentationSidebarStream = ([
  { byId },
  { currentPresentationId, isSavingNewPresentation },
  permissionContext,
  slides,
]: [
  PresentationsStreamShape,
  PresentationOverviewStreamShape,
  PermissionContext,
  CollectionStream<APISlideAttributes>,
]) => {
  const currentPresentation =
    currentPresentationId === 'NEW'
      ? ('NEW' as const)
      : byId[currentPresentationId ?? ''];
  return {
    currentPresentation,
    isSavingNewPresentation,
    slides:
      !currentPresentation || currentPresentation === 'NEW'
        ? []
        : validSlides(currentPresentation, slides.byId),
    commands: presentationOverviewCommands,
    permissionContext,
    canCurrentUserWrite:
      currentUserAccessControlInterface.canCurrentUserWrite(),
    currentUserIsOrgAdmin:
      currentUserAccessControlInterface.currentUserIsOrgAdmin(),
  };
};

const presentationSidebar$ = derivedStream(
  'presentationSidebar$',
  optimisticPresentations$,
  presentationsOverview$,
  currentUserPermissionContext$,
  slides$
).pipe(map(toPresentationSidebarStream));

export default connect(PresentationSidebar, presentationSidebar$);
