import { ReportTemplate } from '@ardoq/report-builder';
import { dispatchAction } from '@ardoq/rxbeach';
import { newReportPropertySelected, sectionWasValidated } from '../actions';
import { RefObject } from 'react';

export const setReportValue = <T extends keyof ReportTemplate>(
  key: T,
  value: ReportTemplate[T]
) => {
  dispatchAction(newReportPropertySelected({ [key]: value }));
};
export const addSectionToScrollArrayIfError = (
  section: RefObject<HTMLDivElement>,
  hasError: boolean
) => {
  dispatchAction(sectionWasValidated({ section, hasError }));
};
