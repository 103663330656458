import { HelperTextWrapper, Label } from '@ardoq/forms';
import { useController, useFormContext } from 'react-hook-form';
import { APIDiscoverViewpointAttributes } from '@ardoq/api-types';
import {
  DateRangePicker,
  getDateRangePickerConfigForTimeline,
  isViewpointTimelineViewStyle,
  readTimelineDomainRangeViewSetting,
} from '@ardoq/timeline';
import { UpdateViewSettingsHandler } from '@ardoq/timeline';

const TimelineDateRangeSelector = () => {
  const {
    field: { onChange: onFieldsChange },
  } = useController({ name: 'viewStyle.domainRange' });
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const viewStyle = watch('viewStyle');

  const updateTimelineDateRange: UpdateViewSettingsHandler = ({
    domainRange,
  }) => {
    if (!domainRange) return;
    onFieldsChange(domainRange);
  };

  if (!isViewpointTimelineViewStyle(viewStyle)) {
    return null;
  }

  const domainRange = readTimelineDomainRangeViewSetting(viewStyle.domainRange);

  return (
    <div>
      <Label>Select date range</Label>
      <HelperTextWrapper>
        This will determine the data and Timeline layout selection available in
        Discover
      </HelperTextWrapper>
      <DateRangePicker
        domain={domainRange}
        updateViewSettingsHandler={updateTimelineDateRange}
        dateRangePickerConfig={getDateRangePickerConfigForTimeline(domainRange)}
      />
    </div>
  );
};

export default TimelineDateRangeSelector;
