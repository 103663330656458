import { APIComponentAttributes } from '@ardoq/api-types';
import { formatDateTime } from '@ardoq/date-time';
import { Icon, IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { getCurrentLocale } from '@ardoq/locale';
import { GetSortableColumnsProps } from '@ardoq/table';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

export const getSubdivisionComponentsTableDataSource = (
  components: APIComponentAttributes[]
) => {
  return components.map(
    ({ name, type, rootWorkspace, lastUpdated, created }) => ({
      name,
      type,
      workspaceName: workspaceInterface.getWorkspaceName(rootWorkspace),
      lastUpdated,
      created,
    })
  );
};

export const getSubdivisionComponentsTableColumns = (
  getSortableColumnProps: GetSortableColumnsProps
) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      ...getSortableColumnProps('name'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      ...getSortableColumnProps('type'),
    },
    {
      title: 'Workspace',
      dataIndex: 'workspaceName',
      valueRender: (workspaceName: string) => (
        <FlexBox gap="xsmall">
          <Icon iconName={IconName.WORKSPACE}></Icon>
          {workspaceName}
        </FlexBox>
      ),
      ...getSortableColumnProps('workspaceName'),
    },
    {
      title: 'Last updated',
      dataIndex: 'lastUpdated',
      valueRender: (lastUpdated: string) =>
        formatDateTime(lastUpdated, getCurrentLocale()),
      ...getSortableColumnProps('lastUpdated'),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      valueRender: (created: string) =>
        formatDateTime(created, getCurrentLocale()),
      ...getSortableColumnProps('created'),
    },
  ];
};
