import { Checkbox } from '@ardoq/forms';
import { ComponentSearchData, SelectedComponent } from './types';
import { ComponentRepresentation } from '@ardoq/renderers';
import { IconSize } from '@ardoq/icons';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { IconWrapper } from 'components/WorkspaceModelBrowser/atoms';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { TextOverflow } from '@ardoq/popovers';
import { s16, s24, s8 } from '@ardoq/design-tokens';
import styled from 'styled-components';

type SelectableComponentListProps = {
  components: ComponentSearchData[];
  selectedComponents: SelectedComponent[];
  toggleComponentSelection: (componentId: string) => void;
  allComponentsSelected: boolean;
  toggleSelectingAllComponents?: () => void;
  useExternalToggleAll?: boolean;
};

const SelectableComponentList = ({
  components,
  selectedComponents,
  toggleComponentSelection,
  allComponentsSelected,
  toggleSelectingAllComponents,
  useExternalToggleAll,
}: SelectableComponentListProps) =>
  components.length === 0 ? null : (
    <DatasourceTable
      fixedHeader
      isFixedLayout
      components={newTableTheme}
      dataSource={components}
      onRowClick={({ id }: ComponentSearchData, event) => {
        event.preventDefault();
        toggleComponentSelection(id);
      }}
      rowStyle={() => ({
        cursor: 'pointer',
      })}
      columns={[
        {
          title: '',
          dataIndex: 'id',
          headerStyle: { width: 56 },
          headerRender: () =>
            !useExternalToggleAll ? (
              <Checkbox
                isChecked={allComponentsSelected}
                onChange={toggleSelectingAllComponents}
              />
            ) : null,
          cellStyle: { width: s24, paddingRight: s8 },
          valueRender: (id: ComponentSearchData['id']) => (
            <Checkbox
              isDisabled={useExternalToggleAll && allComponentsSelected}
              onChange={() => toggleComponentSelection(id)}
              stopPropagation
              isChecked={
                (allComponentsSelected ||
                  // this only loops over manually selected components, so any
                  // performance concerns should be negligible
                  selectedComponents.find(({ id: cId }) => cId === id)
                    ?.isSelected) ??
                false
              }
            />
          ),
        },
        {
          title: '',
          dataIndex: 'representationData',
          headerStyle: { width: 32 },
          cellStyle: { width: s24, padding: 0 },
          valueRender: ({
            isImage,
            icon,
            value,
            contrastColor,
          }: ComponentSearchData['representationData']) => (
            <IconWrapper>
              <ComponentRepresentation
                isImage={isImage ?? false}
                value={value ?? null}
                icon={icon}
                iconSize={IconSize.REGULAR}
                style={{
                  fontSize: IconSize.REGULAR,
                  color: contrastColor,
                }}
              />
            </IconWrapper>
          ),
        },
        {
          title: 'Name',
          dataIndex: 'name',
          headerStyle: {
            paddingLeft: 0,
            // Compensate width of checkbox and icon
            width: `calc(${getWidth('name')} - 88px)`,
          },
          headerRender: () => <FixAlignment>Name</FixAlignment>,
          cellStyle: { paddingLeft: 0 },
          valueRender: (name: ComponentSearchData['name']) => (
            <TextOverflow renderIn="popover">{name}</TextOverflow>
          ),
        },
        {
          title: 'Component type',
          dataIndex: 'entityType',
          headerStyle: { width: getWidth('type') },
          valueRender: (entityType: ComponentSearchData['entityType']) => (
            <TextOverflow renderIn="popover">{entityType}</TextOverflow>
          ),
        },
        {
          title: 'Workspace',
          dataIndex: 'rootWorkspaceName',
          headerStyle: { width: getWidth('workspace') },
          valueRender: (name: ComponentSearchData['rootWorkspaceName']) => (
            <TextOverflow renderIn="popover">{name}</TextOverflow>
          ),
        },
        {
          title: 'Last modified by',
          dataIndex: 'lastModifiedByName',
          headerStyle: { width: getWidth('author') },
          valueRender: (
            lastModifiedByName: ComponentSearchData['lastModifiedByName']
          ) => (
            <TextOverflow renderIn="popover">{lastModifiedByName}</TextOverflow>
          ),
        },
        {
          title: 'Created by',
          dataIndex: 'createdByName',
          headerStyle: { width: getWidth('author') },
          valueRender: (
            createdByName: ComponentSearchData['createdByName']
          ) => <TextOverflow renderIn="popover">{createdByName}</TextOverflow>,
        },
      ].filter(ExcludeFalsy)}
    />
  );

const FixAlignment = styled.div`
  /*
  Negative margin to compensate the default header styling.
  Seems it's not possible to control that with the exposed header styling
  (which can also just mean that I don't understand how).
  */
  margin-left: -${s16};
`;

const columnWidths = {
  name: '35%',
  type: '15%',
  workspace: '20%',
  // 2 columns - "Created by" and "Last modified by"
  author: '15%',
};

type ColumnName = 'name' | 'type' | 'workspace' | 'author';

const getWidth = (name: ColumnName) => columnWidths[name];

export default SelectableComponentList;
