import { TraversalBuilderViewState, TripleOptions } from '../types';
import { DoqContainer, FullHeightSpace, SidebarHeader } from './atoms';
import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { EditTraversalCommands } from 'viewpointBuilder/traversals/editTraversalCommands';
import { SidebarMenu } from '@ardoq/sidebar-menu';
import { DeleteIcon, IconName } from '@ardoq/icons';
import { TriplesToggler } from './TriplesToggler';
import { FlexBox } from '@ardoq/layout';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { GhostButton } from '@ardoq/button';

type EditTraversalOptionsProps = {
  selectedGraphNodeId: string | null;
  tripleOptions: TripleOptions;
  commands: EditTraversalCommands;
  triplesSortAndFiltersState: TraversalBuilderViewState['triplesSortAndFiltersState'];
  hasTripleOptions: TraversalBuilderViewState['hasTripleOptions'];
  shouldShowDeleteCurrentNodeButton: boolean;
};

export const EditTraversalOptionsPanel = ({
  selectedGraphNodeId,
  tripleOptions,
  commands,
  triplesSortAndFiltersState,
  hasTripleOptions,
  shouldShowDeleteCurrentNodeButton,
}: EditTraversalOptionsProps) => {
  if (!selectedGraphNodeId) {
    return (
      <FullHeightSpace>
        <DoqContainer>
          <ResponsiveDoqWithSpeechBubble
            doqType={DoqType.SIMPLE}
            title="Start with a component type"
            message="Select a component type on the canvas on the left to edit the traversal"
          />
        </DoqContainer>
      </FullHeightSpace>
    );
  }

  return (
    <SidebarMenu
      isPageBodyMenu
      isResizable
      isRightContent
      headerBody={
        tripleOptions.optionCountLoadingState === 'loading' ? null : (
          <SidebarHeader
            type="component"
            selectedType={tripleOptions.selectedNode.metaDataComponent}
            headerText="Select references for"
            iconName={IconName.ACCOUNT_TREE}
            rightContent={
              shouldShowDeleteCurrentNodeButton ? (
                <DeleteCurrentNodeButton
                  onClick={() =>
                    commands.removeNodeFromGraph(selectedGraphNodeId)
                  }
                />
              ) : null
            }
          />
        )
      }
    >
      <FlexBox padding="small" height="full">
        <TriplesToggler
          togglerStyle={'checkbox'}
          isDisabledWholeRow={true}
          tripleOptions={tripleOptions}
          commands={commands}
          triplesSortAndFiltersState={triplesSortAndFiltersState}
          hasTripleOptions={hasTripleOptions}
        />
      </FlexBox>
    </SidebarMenu>
  );
};

type DeleteCurrentNodeButtonProps = {
  onClick: () => void;
};

const DeleteCurrentNodeButton = ({ onClick }: DeleteCurrentNodeButtonProps) => {
  return (
    <GhostButton onClick={onClick}>
      <DeleteIcon />
      <VisuallyHidden>Remove from graph</VisuallyHidden>
    </GhostButton>
  );
};
