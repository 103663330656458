import { connect } from '@ardoq/rxbeach';
import { scenarioIsInSync$ } from 'scenarioIsInSync$';

export const SCENARIO_OUT_OF_SYNC_POPOVER_ID = 'SCENARIO_OUT_OF_SYNC_POPOVER';

type ScenarioOutOfSyncPopoverProps = { isInSync: boolean };

const ScenarioOutOfSyncPopover = ({
  isInSync,
}: ScenarioOutOfSyncPopoverProps) =>
  !isInSync ? (
    <div style={{ width: 190 }}>
      <p>
        The metamodel of the scenario is incompatible with the metamodel in the
        mainline.
      </p>
      To get the metamodels back to a compatible state, please use the scenario
      merge functionality.
    </div>
  ) : null;

const ScenarioOutOfSyncPopoverConnected = connect(
  ScenarioOutOfSyncPopover,
  scenarioIsInSync$
);

export const scenarioOutOfSyncPopoverRenderer = () => (
  <ScenarioOutOfSyncPopoverConnected />
);
