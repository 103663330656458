import { connect } from '@ardoq/rxbeach';
import {
  ButtonGroup,
  PrimaryButton,
  BrandButton,
  SecondaryButton,
  GhostButton,
} from '@ardoq/button';
import { Space } from '@ardoq/style-helpers';
import { header4, Link, text2 } from '@ardoq/typography';
import { Tab, TabsGroup } from '@ardoq/tabs';
import styled from 'styled-components';
import {
  r16,
  s16,
  s32,
  s4,
  solidGrey1,
  solidLightGrey1,
  colors,
} from '@ardoq/design-tokens';
import { IntegrationTab } from 'integrations/common/types/common';
import { RightSidBerBarFAQ } from 'integrations/common/streams/integrationTermsDictionary/types';
import { OverviewRoute, RoutePath } from 'integrations/common/navigation/types';
import { viewModel$ } from './viewModel$';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { isIntegrationWithConnections } from 'integrations/common/streams/connections/utils';
import { Features, hasFeature } from '@ardoq/features';
import { Icon, IconName } from '@ardoq/icons';
import MainToolbar from 'menus/topbar/MainToolbar';
import {
  PageHeader,
  PageBody,
  PageWrapper,
  PageTopbar,
} from '@ardoq/page-layout';
import { Stack } from '@ardoq/layout';

const SidebarContent = styled(Space)`
  padding: ${s32} ${s16};
  height: 100%;
  border-left: ${solidLightGrey1};
`;

const SidebarHeader = styled.div`
  ${header4};
  margin-bottom: ${s4};
`;

const SidebarText = styled.div`
  ${text2};
  letter-spacing: 0.5px;
  margin-right: ${s4};
`;

const GuidesList = styled(Space).attrs({
  $gap: 's8',
  $isVertical: true,
})<{ $hasMultipleElements: boolean }>`
  ${text2};
  margin-left: ${s4};
  align-items: ${({ $hasMultipleElements }) =>
    $hasMultipleElements ? 'stretch' : 'flex-start'};
  width: 320px;
`;

const GuidesLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  margin-left: ${s4};
  gap: ${s4};

  & > i {
    font-size: 20px !important;
    margin-left: 0;
    margin-top: ${s4};
  }
`;

// These styled components are temporary and will be removed when the whole ardoq front end is migrated to the new design system
const LeftContentBorder = styled(Stack)<{ $hasNewJourneyFeature: boolean }>`
  ${props =>
    props.$hasNewJourneyFeature
      ? `
    border-left: ${solidGrey1};
    border-top: ${solidGrey1};
    border-bottom: ${solidGrey1};
    border-radius: ${r16} 0 0 ${r16};
  `
      : ''}
  height: 100%;
  overflow: hidden;
`;

const RightContentBorder = styled(Stack)<{ $hasNewJourneyFeature: boolean }>`
  ${props =>
    props.$hasNewJourneyFeature
      ? `
    border-right: ${solidGrey1};
    border-top: ${solidGrey1};
    border-bottom: ${solidGrey1};
    border-radius: 0 ${r16} ${r16} 0;
    margin-right: ${s16};
    `
      : ''}
  height: 100%;
  overflow: hidden;
`;

const PageWrapperExtended = styled(PageWrapper)<{
  $hasNewJourneyFeature: boolean;
}>`
  ${({ $hasNewJourneyFeature: hasNewJourneyFeature }) =>
    hasNewJourneyFeature &&
    `
  padding-bottom: ${s32};
  `}
  background: ${colors.surfaceDefault};
`;

interface OverviewComponentProps {
  integrationName: string;
  rightSideBarFAQ: RightSidBerBarFAQ[];
  rightSidebarHeader: string;
  overviewTitle: string;
  integrationPath: RoutePath;
  initialTab: OverviewRoute;
  tabs: IntegrationTab<OverviewRoute>[];
  onTabChange: (tab: RoutePath) => void;
  onBackToOverview: VoidFunction;
  onNewImport: VoidFunction;
  onNewExport?: VoidFunction;
  isAdmin: boolean;
  hasConnections: boolean;
  integrationId: IntegrationId;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
}

const OverviewComponent = ({
  rightSidebarHeader,
  rightSideBarFAQ,
  overviewTitle,
  integrationPath,
  initialTab,
  tabs,
  onTabChange,
  onNewImport,
  onNewExport,
  onBackToOverview,
  hasConnections,
  isAdmin,
  integrationId,
  primaryButtonLabel,
  secondaryButtonLabel,
}: OverviewComponentProps) => {
  const activeTabId = !isIntegrationWithConnections(integrationId)
    ? String(integrationPath)
    : String(hasConnections ? integrationPath : initialTab);
  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);
  return (
    <PageWrapperExtended $hasNewJourneyFeature={hasNewJourneyFeature}>
      {hasNewJourneyFeature ? (
        <PageTopbar
          primaryContent={overviewTitle}
          secondaryContent="Import and integrations"
          toolbarContent={<MainToolbar shouldUseNewJourneyVersion />}
          primaryButton={
            <BrandButton
              isDisabled={
                !isIntegrationWithConnections(integrationId)
                  ? false
                  : !hasConnections
              }
              onClick={onNewImport}
            >
              {primaryButtonLabel ?? 'Create new import'}
            </BrandButton>
          }
          secondaryButton={
            <ButtonGroup $gap="s8">
              <GhostButton onClick={onBackToOverview}>
                <Icon iconName={IconName.CHEVRON_LEFT} />
                Back to overview
              </GhostButton>
              {isAdmin && onNewExport && (
                <SecondaryButton
                  isDisabled={!hasConnections}
                  onClick={onNewExport}
                >
                  {secondaryButtonLabel ?? 'Create new export'}
                </SecondaryButton>
              )}
            </ButtonGroup>
          }
        />
      ) : (
        <PageHeader
          title={overviewTitle}
          rightContent={
            <ButtonGroup>
              <GhostButton onClick={onBackToOverview}>
                Back to overview
              </GhostButton>
              {isAdmin && onNewExport && (
                <SecondaryButton
                  isDisabled={!hasConnections}
                  onClick={onNewExport}
                >
                  Create new export
                </SecondaryButton>
              )}{' '}
              <PrimaryButton
                isDisabled={
                  !isIntegrationWithConnections(integrationId)
                    ? false
                    : !hasConnections
                }
                onClick={onNewImport}
              >
                Create new import
              </PrimaryButton>
            </ButtonGroup>
          }
        />
      )}
      <PageBody
        backgroundColor="surfaceDefault"
        preventScroll
        padding={hasNewJourneyFeature ? `0 0 0 ${s16}` : s16}
        rightContent={
          <RightContentBorder $hasNewJourneyFeature={hasNewJourneyFeature}>
            <SidebarContent $isVertical $gap="s8" $flex={1}>
              <SidebarHeader>{rightSidebarHeader}</SidebarHeader>
              <SidebarText>
                Explore our Help Center articles to get started.
              </SidebarText>
              <GuidesList $hasMultipleElements={rightSideBarFAQ.length > 1}>
                {rightSideBarFAQ.map((guide, index) => (
                  <GuidesLink
                    href={guide.link}
                    target="_blank"
                    key={index}
                    typography="text2"
                  >
                    {index + 1}. {guide.text}
                  </GuidesLink>
                ))}
              </GuidesList>
            </SidebarContent>
          </RightContentBorder>
        }
      >
        <LeftContentBorder $hasNewJourneyFeature={hasNewJourneyFeature}>
          <TabsGroup
            activeTabId={activeTabId}
            onTabChange={tabId => onTabChange(tabId as RoutePath)}
          >
            {tabs.map(({ label, path, component, isDisabled }) => (
              <Tab
                label={label}
                tabId={path}
                key={path}
                isDisabled={isDisabled}
              >
                {component(integrationId)()}
              </Tab>
            ))}
          </TabsGroup>
        </LeftContentBorder>
      </PageBody>
    </PageWrapperExtended>
  );
};

export const Overview = connect(OverviewComponent, viewModel$);
