import {
  ArdoqId,
  AudiencePreview,
  BroadcastAudienceType,
  BroadcastComponent,
  BroadcastPerson,
  InstanceComponent,
  InstanceProgress,
} from '@ardoq/api-types';
import { getUniqueComponents } from '../utils';
import {
  getPersonRepresentation,
  getPersonSecondaryRepresentation,
} from 'broadcasts/utils';
import { AudienceStatus, ComponentStatus } from '../types';
import { uniqueId } from 'lodash';
import { ExcludeFalsy } from '@ardoq/common-helpers';

export type DataSourceRow = {
  _id: ArdoqId | null;
  componentLabel: string | null;
  personLabel: string | null;
  personSublabel: string | null;
  children: DataSourceRow[] | null;
  isChildRow: boolean;
  audienceStatus: AudienceStatus;
  componentStatus: ComponentStatus;
  tableFilter?: string;
};

const getComponentsByComponentId = (components?: InstanceComponent[]) => {
  const uniqueComponents = getUniqueComponents(components);
  return Object.fromEntries(
    uniqueComponents.map(component => [component._id, component])
  );
};

const getComponentIds = (components: BroadcastComponent[] | null) => {
  return components ? components.map(({ _id }) => _id) : [];
};

const getPeopleByComponentId = (
  audiencePreview: AudiencePreview,
  componentsByComponentId: {
    [k: string]: InstanceComponent;
  }
) => {
  return audiencePreview.reduce(
    (result, { person, components, sources }) => {
      const isEmailOnlyAudienceMember =
        sources.length === 1 && sources.includes(BroadcastAudienceType.EMAIL);
      const componentIds = isEmailOnlyAudienceMember
        ? Object.keys(componentsByComponentId)
        : getComponentIds(components);
      return componentIds.reduce((peopleByComponentId, componentId) => {
        return {
          ...peopleByComponentId,
          [componentId]: [...(peopleByComponentId[componentId] ?? []), person],
        };
      }, result);
    },
    {} as Record<ArdoqId, BroadcastPerson[]>
  );
};

const getPersonLabel = (people: BroadcastPerson[]) => {
  if (people.length === 1) return getPersonRepresentation(people[0]);
  return `${people.length} people`;
};

const getAudienceStatus = (people: BroadcastPerson[]) => {
  if (people.length === 1 && !people[0].email) {
    return AudienceStatus.MISSING_EMAIL;
  }
  return AudienceStatus.VALID;
};

const getComponentStatus = (
  componentId: ArdoqId,
  progress: InstanceProgress
) => {
  if (progress[componentId]) return ComponentStatus.COMPLETE;
  return ComponentStatus.INCOMPLETE;
};

const getChildrenRowData = (
  people: BroadcastPerson[],
  tableFilter?: string
): DataSourceRow[] | null => {
  if (people.length === 1) return null;
  return people.map(person => ({
    _id: uniqueId(),
    componentLabel: null,
    personLabel: getPersonRepresentation(person),
    personSublabel: getPersonSecondaryRepresentation(person),
    children: null,
    isChildRow: true,
    audienceStatus: getAudienceStatus([person]),
    componentStatus: ComponentStatus.INCOMPLETE,
    tableFilter: tableFilter,
  }));
};

/**
 * Prioritizes children whose labels match the filter text.
 */
function withFilterPrioritizedChildren(
  rows: DataSourceRow[],
  tableFilter: string
) {
  if (!tableFilter) return rows;
  const lowerCaseFilter = tableFilter.toLocaleLowerCase();
  return rows.map(row => {
    const children = row.children;
    if (!children?.length) return row;
    const [matchingChildren, nonMatchingChildren] = children.reduce<
      [DataSourceRow[], DataSourceRow[]]
    >(
      (accumulator, child) => {
        const label = child?.personLabel;
        const sublabel = child?.personSublabel;
        accumulator[
          (label && label.toLocaleLowerCase().includes(lowerCaseFilter)) ||
          (sublabel && sublabel.toLocaleLowerCase().includes(lowerCaseFilter))
            ? 0
            : 1
        ].push(child);
        return accumulator;
      },
      [[], []]
    );
    const prioritizedChildren = [...matchingChildren, ...nonMatchingChildren];
    return {
      ...row,
      children: prioritizedChildren,
    };
  });
}

const rowMatchesFilter = (row: DataSourceRow, filter: string) => {
  const componentLabel = row.componentLabel ?? '';
  const personLabel = !row.children?.length ? row.personLabel : '';
  const personSublabel = row.personSublabel ?? '';
  const childrenPersonLabels =
    row.children?.map(child => child.personLabel) ?? [];
  const childrenPersonSublabels =
    row.children?.map(child => child.personSublabel) ?? [];

  return [
    componentLabel,
    personLabel,
    personSublabel,
    ...childrenPersonLabels,
    ...childrenPersonSublabels,
  ].some(value =>
    value?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
  );
};

export const getAudienceByComponentDataSource = (
  audiencePreview: AudiencePreview,
  progress: InstanceProgress,
  filter: string,
  components?: InstanceComponent[]
): DataSourceRow[] | null => {
  const componentsByComponentId = getComponentsByComponentId(
    components ?? audiencePreview.flatMap(({ components }) => components)
  );
  const peopleByComponentId = getPeopleByComponentId(
    audiencePreview,
    componentsByComponentId
  );
  const audienceByComponentDataSource = Object.entries(peopleByComponentId)
    .map(([componentId, people]) => {
      const component = componentsByComponentId[componentId];
      if (!component) return null;
      return {
        _id: componentId,
        componentLabel: component.name,
        personLabel: getPersonLabel(people),
        personSublabel:
          people.length === 1
            ? getPersonSecondaryRepresentation(people[0])
            : null,
        children: getChildrenRowData(people, filter),
        isChildRow: false,
        audienceStatus: getAudienceStatus(people),
        componentStatus: getComponentStatus(componentId, progress),
        tableFilter: filter,
      };
    })
    .filter(ExcludeFalsy);
  if (!filter) return audienceByComponentDataSource;
  const filteredDataSource = audienceByComponentDataSource.filter(row =>
    rowMatchesFilter(row, filter)
  );
  return withFilterPrioritizedChildren(filteredDataSource, filter);
};

export const isAtLeastOneComponentComplete = (progress?: InstanceProgress) => {
  if (!progress) return false;
  return Object.values(progress).filter(Boolean).length > 0;
};
