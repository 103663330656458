import PagesView from 'tabview/pagesView/PagesView';
import TagscapeView from 'tabview/tagscape/tagscapeView';
import CapabilityMap from 'tabview/capabilityMap/CapabilityMap';
import ComponentTableView from 'tabview/tableViews/ComponentTableView';
import ReferenceTableView from 'tabview/tableViews/ReferenceTableView';
import * as RoadmapView from 'tabview/roadMap/roadmapView';
import getMetaInfoTabs, { toMetaInfoByIdTuple } from './metaInfoTabs';
import { ViewIds } from '@ardoq/api-types';
import SwimlaneView from 'tabview/swimlaneView/SwimlaneView';
import BubbleChartView from 'tabview/bubbleChart/BubbleChartView';
import CircularRelationshipDiagramView from 'tabview/circularRelationshipDiagramView/CircularRelationshipDiagramView';
import ComponentMatrixView from 'tabview/componentMatrix/ComponentMatrixView';
import BlockDiagramView from 'tabview/blockDiagram/BlockDiagramView';
import ModernizedBlockDiagramView from 'tabview/blockDiagram/ModernizedBlockDiagramView';
import DependencyMap2 from 'tabview/dependencyMap/DependencyMapView';
import RelationshipsView from 'tabview/relationsD3View/RelationshipsView';
import HierarchicalRelationshipsView from 'tabview/relationsD3View/hierarchical/RelationshipsView';
import IsometricBlockDiagramView from 'tabview/blockDiagram/IsometricBlockDiagramView';
import { MetaInfo } from 'streams/views/mainContent/types';
import ProteanDiagramView from 'tabview/proteanDiagram/view/ProteanView';
import {
  ComponentTreeView,
  DependencyMatrixView,
  DependencyWheelView,
  SpiderChartView,
  TreemapView,
} from 'tabview';
import HierarchicalTreemapView from 'tabview/hierarchicalTreemap/view/standard/HierarchicalTreemapView';
import HierarchicalPackingView from 'tabview/hierarchicalTreemap/view/packing/HierarchicalPackingView';
import SunburstView from 'tabview/hierarchicalTreemap/view/sunburst/SunburstView';
import HierarchicInGridView from 'tabview/proteanDiagram/intentionalLayout/HierarchicInGridView';
import TimelineViewController from 'tabview/timeline2024/TimelineViewController';
import BlocksView from 'tabview/blocks/view/BlocksView';

const getMetaInfo = () => {
  const metaInfoTabs = getMetaInfoTabs();
  const metaInfo = [
    {
      ...metaInfoTabs.get(ViewIds.PAGESVIEW),
      ReactComponent: PagesView,
    },
    {
      ...metaInfoTabs.get(ViewIds.BLOCK_DIAGRAM),
      ReactComponent: BlockDiagramView,
    },
    {
      ...metaInfoTabs.get(ViewIds.DEPENDENCY_MAP_2),
      ReactComponent: DependencyMap2,
    },
    {
      ...metaInfoTabs.get(ViewIds.RELATIONSHIPS_3),
      ReactComponent: HierarchicalRelationshipsView,
    },
    {
      ...metaInfoTabs.get(ViewIds.NETWORK),
      ReactComponent: RelationshipsView,
    },
    {
      ...metaInfoTabs.get(ViewIds.TABLEVIEW),
      ReactComponent: ComponentTableView,
    },
    {
      ...metaInfoTabs.get(ViewIds.DEPMATRIX),
      ReactComponent: DependencyMatrixView,
    },
    {
      ...metaInfoTabs.get(ViewIds.CAPABILITY_MAP),
      ReactComponent: CapabilityMap,
    },
    {
      ...metaInfoTabs.get(ViewIds.SWIMLANE),
      ReactComponent: SwimlaneView,
    },
    {
      ...metaInfoTabs.get(ViewIds.COMPONENTTREE),
      ReactComponent: ComponentTreeView,
    },
    {
      ...metaInfoTabs.get(ViewIds.DEPWHEEL),
      ReactComponent: DependencyWheelView,
    },
    {
      ...metaInfoTabs.get(ViewIds.TREEMAP),
      ReactComponent: TreemapView,
    },
    {
      ...metaInfoTabs.get(ViewIds.TAGSCAPE),
      ReactComponent: TagscapeView,
    },
    {
      ...metaInfoTabs.get(ViewIds.REFERENCETABLE),
      ReactComponent: ReferenceTableView,
    },
    {
      ...metaInfoTabs.get(ViewIds.TIMELINE),
      ReactComponent: TimelineViewController,
    },
    {
      ...metaInfoTabs.get(ViewIds.BUBBLE),
      ReactComponent: BubbleChartView,
    },
    {
      ...metaInfoTabs.get(ViewIds.ROADMAP),
      viewClass: RoadmapView,
      ReactComponent: null,
    },
    {
      ...metaInfoTabs.get(ViewIds.COMPONENT_MATRIX),
      ReactComponent: ComponentMatrixView,
    },
    {
      ...metaInfoTabs.get(ViewIds.SPIDER),
      ReactComponent: SpiderChartView,
    },
    {
      ...metaInfoTabs.get(ViewIds.ISOMETRIC_BLOCK_DIAGRAM),
      ReactComponent: IsometricBlockDiagramView,
    },
    {
      ...metaInfoTabs.get(ViewIds.PROTEAN_DIAGRAM),
      ReactComponent: ProteanDiagramView,
    },
    {
      ...metaInfoTabs.get(ViewIds.HIERARCHICAL_TREEMAP),
      ReactComponent: HierarchicalTreemapView,
    },
    {
      ...metaInfoTabs.get(ViewIds.HIERARCHICAL_PACKING),
      ReactComponent: HierarchicalPackingView,
    },
    {
      ...metaInfoTabs.get(ViewIds.SUNBURST),
      ReactComponent: SunburstView,
    },
    {
      ...metaInfoTabs.get(ViewIds.CIRCULAR_RELATIONSHIP_DIAGRAM),
      ReactComponent: CircularRelationshipDiagramView,
    },
    {
      ...metaInfoTabs.get(ViewIds.HIERARCHIC_IN_GRID),
      ReactComponent: HierarchicInGridView,
    },
    {
      ...metaInfoTabs.get(ViewIds.BLOCKS),
      ReactComponent: BlocksView,
    },
    {
      ...metaInfoTabs.get(ViewIds.MODERNIZED_BLOCK_DIAGRAM),
      ReactComponent: ModernizedBlockDiagramView,
    },
    // #region discontinued views
    { ...metaInfoTabs.get(ViewIds.SEQUENCE) },
    { ...metaInfoTabs.get(ViewIds.REFERENCE_AGGREGATION_VIEW) },
    { ...metaInfoTabs.get(ViewIds.PROCESSFLOW) },
    { ...metaInfoTabs.get(ViewIds.HEATMAP) },
    { ...metaInfoTabs.get(ViewIds.API_DOCUMENTATION) },
    { ...metaInfoTabs.get(ViewIds.RELATIONSHIPS) },
    { ...metaInfoTabs.get(ViewIds.DEPMAP) },
    { ...metaInfoTabs.get(ViewIds.INTERACTIVE_BLOCKS) },
    { ...metaInfoTabs.get(ViewIds.INTENTIONAL) },
    // #endregion
  ] as MetaInfo[];
  const metaInfoTabTuples = metaInfo.map(toMetaInfoByIdTuple);

  return new Map(metaInfoTabTuples);
};

export default getMetaInfo;
