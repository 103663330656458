import { LabelSettings } from './LabelSettings';
import { FormattingSettings } from './FormattingSettings';
import {
  SectionTitle,
  SectionHeader,
  SectionDescription,
  SectionContainer,
} from './atoms';
import { AppliedSettingsData } from './types';
import { GroupSettings } from './GroupSettings';
import { ContextComponentType } from './ContextComponentType';
import { ComponentAndReferenceTypes } from './ComponentsAndReferencesTypes';
import { FiltersSettings } from './FiltersSettings';
import { LabelSettingsWithMultiselect } from './LabelSettingsWithMultiselect';

type Props = {
  appliedSettings: AppliedSettingsData;
};

export const AppliedSettings = ({
  appliedSettings: {
    labelSettings,
    formattingSettings,
    groupingSettings,
    contextComponentTypeSettings,
    componentAndReferenceTypesSettings,
    filterSettings,
  },
}: Props) => (
  <SectionContainer>
    <SectionHeader>
      <SectionTitle>Viewpoint setup</SectionTitle>
      <SectionDescription>
        Hover on the tags to see what will be saved in this viewpoint
      </SectionDescription>
    </SectionHeader>
    <ContextComponentType settings={contextComponentTypeSettings} />
    <ComponentAndReferenceTypes settings={componentAndReferenceTypesSettings} />
    <FiltersSettings settings={filterSettings} />
    <GroupSettings settings={groupingSettings} />
    <FormattingSettings settings={formattingSettings} />
    {'labelFormatting' in labelSettings ? (
      <LabelSettingsWithMultiselect settings={labelSettings} />
    ) : (
      <LabelSettings settings={labelSettings} />
    )}
  </SectionContainer>
);
