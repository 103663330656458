import { reducer } from '@ardoq/rxbeach';
import {
  PerspectivesEditorState,
  PerspectivesReducerFn,
  PerspectivesRelatedData,
} from '../types';
import { notifyPerspectivesRelatedDataChanged } from 'perspective/actions';
import { getStateAffectedByWorkspaceFilter } from './getStateAffectedByWorkspaceFilter';

export const notifyPerspectivesRelatedDataChangeReducerFn: PerspectivesReducerFn<
  PerspectivesRelatedData
> = (state, perspectivesRelatedData) =>
  getStateAffectedByWorkspaceFilter({
    ...state,
    perspectivesRelatedData,
  });

const notifyPerspectivesRelatedDataChangeReducer = reducer<
  PerspectivesEditorState,
  PerspectivesRelatedData
>(
  notifyPerspectivesRelatedDataChanged,
  notifyPerspectivesRelatedDataChangeReducerFn
);

export const commonReducers = [notifyPerspectivesRelatedDataChangeReducer];
