import { actionCreator } from '@ardoq/rxbeach';
import { ReportPagination, ReportSort } from '@ardoq/report-reader';
import { ReportFilterQuery } from '@ardoq/api-types';

export const excelExportFailed = actionCreator<boolean>(
  '[reportReader] SET_HAS_REPORT_EXCEL_EXPORT_FAILED'
);

export const pageChanged = actionCreator<number>('[reportReader] PAGE_CHANGED');

export const paginationChanged = actionCreator<ReportPagination>(
  '[reportReader] PAGINATION_CHANGED'
);

export const filterQueryChanged = actionCreator<ReportFilterQuery>(
  '[reportReader] FILTER_QUERY_CHANGED'
);

export const drilldownWasTriggered = actionCreator<boolean>(
  '[reportReader] DRILLDOWN_WAS_TRIGGERED'
);

export const sortingChanged = actionCreator<ReportSort>(
  '[reportReader] SORTING_CHANGED'
);
