import _ from 'lodash/fp';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { Maybe } from '@ardoq/common-helpers';
import { EMPTY, Observable, map } from 'rxjs';
import { buildInitialStreamState } from '../utils';
import { TabularMappingConstraints } from './types';
import {
  resetTabularMappingConstraints,
  resetTabularMappingConstraintsPayload,
  setTableMappingConstraints,
  setTableMappingConstraintsPayload,
} from './actions';

type AllTabularMappingConstraints = Record<
  IntegrationId,
  TabularMappingConstraints
>;

const initialState: AllTabularMappingConstraints =
  buildInitialStreamState<TabularMappingConstraints>(() => ({}));

const resetTabularMappingConstraintsReducer = (
  state: AllTabularMappingConstraints,
  { integrationId }: resetTabularMappingConstraintsPayload
) => ({
  ...state,
  [integrationId]: {},
});

const handleResetTabularMappingConstraints = reducer<
  AllTabularMappingConstraints,
  resetTabularMappingConstraintsPayload
>(resetTabularMappingConstraints, resetTabularMappingConstraintsReducer);

const setTableMappingConstraintsReducer = (
  state: AllTabularMappingConstraints,
  {
    integrationId,
    tableId,
    tableMappingConstraints,
  }: setTableMappingConstraintsPayload
) => _.set([integrationId, tableId], tableMappingConstraints, state);

const handleSetTableMappingConstraint = reducer<
  AllTabularMappingConstraints,
  setTableMappingConstraintsPayload
>(setTableMappingConstraints, setTableMappingConstraintsReducer);

const reducers = [
  handleSetTableMappingConstraint,
  handleResetTabularMappingConstraints,
];

const tabularMappingConstraints$ = persistentReducedStream(
  `integrationsTabularMappingConstraints$`,
  initialState,
  reducers
);

const integrationToMappingStreams = new Map<
  IntegrationId,
  Observable<TabularMappingConstraints>
>();

export const getTabularMappingConstraintsStream = (
  integrationId: Maybe<IntegrationId>
) => {
  if (!integrationId) {
    return EMPTY;
  }

  const mappingStream = integrationToMappingStreams.get(integrationId);

  if (mappingStream) {
    return mappingStream;
  }

  const stream$ = tabularMappingConstraints$.pipe(
    map(tabularMappingConstraints => tabularMappingConstraints[integrationId])
  );

  integrationToMappingStreams.set(integrationId, stream$);

  return stream$;
};
