import { subdivisionsOperations } from '@ardoq/subdivisions';
import { ZonesViewModelState } from './Steps/permissionsConfiguration/zones/types';
import { SubdivisionEditorSteps } from './navigation/types';
import { StepsValidationErrorMap, SubdivisionViewModelState } from './types';
import {
  AdvancedSearchState,
  ComponentSelectionState,
} from './Steps/SelectComponents/types';
import {
  NonPersistedSubdivision,
  Subdivision,
  ArdoqId,
  SubdivisionComponentAssignmentsMode,
} from '@ardoq/api-types';
import { workspaceOperations } from 'streams/workspaces/workspaceOperations';
import { AdvancedSearchFieldsHashState } from 'streams/fields/advancedSearchFields$';
import {
  ruleErrorToMessage,
  validateAdvancedSearchQuery,
} from 'search/AdvancedSearch/utils';
import { compareDesc, parseDate } from '@ardoq/date-time';

const isCreationMode = ({
  subdivisionId,
}: {
  subdivisionId: SubdivisionViewModelState['subdivisionId'];
}): boolean => {
  return subdivisionId === 'new';
};

const getName = (
  subdivisionEditorState: SubdivisionViewModelState
): string | null => {
  return subdivisionEditorState.subdivision?.name ?? null;
};

const initializeEmptyStepsErrors = (): StepsValidationErrorMap => {
  return {
    details: {},
    'permissions-configuration': {},
    'workspaces-binding': {},
    'components-selection': {},
  } satisfies StepsValidationErrorMap;
};

const initializeEmptyTouchedSteps = (): Record<
  SubdivisionEditorSteps,
  boolean
> => {
  return {
    'workspaces-binding': false,
    details: false,
    'permissions-configuration': false,
    'components-selection': false,
    summary: false,
  };
};

const initializeEmptyCollapsed = (): Record<
  SubdivisionEditorSteps,
  boolean
> => {
  return {
    'workspaces-binding': true,
    details: true,
    'permissions-configuration': false,
    'components-selection': true,
    summary: true,
  };
};

const getAdvancedSearchInitialState = (): AdvancedSearchState => ({
  isValid: true,
  isEmpty: true,
  ruleErrorMessages: [],
});

const getStagedComponentsChangesInitialState = (): ComponentSelectionState => ({
  componentsToAdd: [],
  componentsToRemove: [],
  selectedComponentsToBeAdded: [],
  selectedComponentsToBeRemoved: [],
  isFetchingStagedComponents: false,
  showResults: false,
  searchError: null,
  advancedSearchState: getAdvancedSearchInitialState(),
  resultsTotalCount: 0,
});

const getInitialSubdivisionEditorState = (): SubdivisionViewModelState => ({
  subdivision: subdivisionsOperations.getEmptySubdivision(),
  subdivisions: {},
  missingSubdivision: false,
  hasNewCoreJourneyFeature: false,
  subdivisionEditorStep: SubdivisionEditorSteps.DETAILS,
  subdivisionEditorSubStep: undefined,
  subdivisionId: '',
  isSubmitting: false,
  isFetchingSubdivisions: false,
  submittingError: null,
  zonesState: {} as ZonesViewModelState,
  stepsErrors: initializeEmptyStepsErrors(),
  touchedSteps: initializeEmptyTouchedSteps(),
  collapsed: initializeEmptyCollapsed(),
  isOrgAdmin: false,
  componentsSelection: getStagedComponentsChangesInitialState(),
  bindWorkspacesState: {
    workspacesBoundToSubdivisionIds: [],
    selectedWorkspaces: [],
    searchValue: '',
    workspaces: [],
  },
});

const resetAfterSaveReducer = (
  subdivisionEditorState: SubdivisionViewModelState
): SubdivisionViewModelState => {
  return {
    ...subdivisionEditorState,
    isSubmitting: false,
    submittingError: null,
    componentsSelection: {
      ...subdivisionEditorState.componentsSelection,
      selectedComponentsToBeRemoved: [],
      selectedComponentsToBeAdded: [],
      componentsToAdd: [],
      componentsToRemove: [],
    },
    touchedSteps: subdivisionEditorOperations.initializeEmptyTouchedSteps(),
  };
};

const resetIsSubmittingReducer = (
  subdivisionEditorState: SubdivisionViewModelState
): SubdivisionViewModelState => {
  return {
    ...subdivisionEditorState,
    isSubmitting: false,
  };
};

const saveSubdivisionChangesReducer = (
  subdivisionEditorState: SubdivisionViewModelState
): SubdivisionViewModelState => {
  return {
    ...subdivisionEditorState,
    isSubmitting: true,
    submittingError: null,
  };
};

const compareSubdivisionLastUpdatedTime = (a: Subdivision, b: Subdivision) => {
  return compareDesc(parseDate(a.lastUpdated), parseDate(b.lastUpdated));
};

/**
 * @deprecated Only used to initialize the advanced search query builder state.
 *
 * use state.componentsSelection.queryIsEmpty instead.
 * */
const hasAdvancedSearchQuery = (
  subdivision: Subdivision | NonPersistedSubdivision
): boolean => {
  return Boolean(
    subdivision.advancedSearchQueries?.length &&
      subdivisionsOperations.getSubdivisionSearchQuery(subdivision)?.rules
        .length
  );
};

const getSelectedWorkspacesToBind = (
  subdivisionEditorState: SubdivisionViewModelState
): ArdoqId[] => [
  ...subdivisionEditorState.bindWorkspacesState.selectedWorkspaces,
  ...subdivisionEditorState.bindWorkspacesState.workspaces
    .filter(ws =>
      workspaceOperations.isSubdivisionBoundToWorkspace(
        ws,
        subdivisionEditorState.subdivisionId
      )
    )
    .map(ws => ws._id),
];

const getAdvancedSearchRuleErrors = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  const query = subdivisionsOperations.getSubdivisionSearchQuery(
    subdivisionEditorState.subdivision
  );
  return query ? validateAdvancedSearchQuery(query) : [];
};

const getAdvancedSearchRuleErrorMessages = (
  subdivisionEditorState: SubdivisionViewModelState,
  fieldsHash: AdvancedSearchFieldsHashState
): ArdoqId[] => {
  const ruleErrors = getAdvancedSearchRuleErrors(subdivisionEditorState);

  const ruleErrorMessages = ruleErrors.map(ruleError =>
    ruleErrorToMessage(ruleError, fieldsHash)
  );
  return ruleErrorMessages;
};

const getAllSelectedWorkspacesToBindToSubdivision = ({
  bindWorkspacesState: { selectedWorkspaces, workspacesBoundToSubdivisionIds },
}: Pick<SubdivisionViewModelState, 'bindWorkspacesState'>): ArdoqId[] => {
  return [...selectedWorkspaces, ...workspacesBoundToSubdivisionIds];
};

const getTouchedSteps = (
  subdivisionEditorState: SubdivisionViewModelState
): SubdivisionEditorSteps[] => {
  return Object.entries(subdivisionEditorState.touchedSteps)
    .filter(step => step[1])
    .map(step => step[0] as SubdivisionEditorSteps);
};

const hasUnsavedChanges = (
  subdivisionEditorState: SubdivisionViewModelState
) => {
  return getTouchedSteps(subdivisionEditorState).length > 0;
};

const isAdvancedSearchMode = ({
  subdivision,
}: SubdivisionViewModelState): boolean => {
  return (
    subdivision.componentAssignmentsMode ===
    SubdivisionComponentAssignmentsMode.ADVANCED_SEARCH
  );
};

export const subdivisionEditorOperations = {
  getInitialSubdivisionEditorState,
  /**
   * Returns true if the editor is in creation mode
   */
  isCreationMode,
  /**
   * Returns the name of the subdivision
   */
  getName,
  /**
   * Initializes the steps errors object
   */
  initializeEmptyStepsErrors,
  /**
   * Initializes the touched steps object
   */
  initializeEmptyTouchedSteps,
  /**
   * Initializes the collapsed object
   */
  initializeEmptyCollapsed,
  /**
   * Compare two subdivision's last updated time
   */
  compareSubdivisionLastUpdatedTime,
  /**
   * Get selected workspaces to bind
   */
  getSelectedWorkspacesToBind,
  /**
   * Get the advanced search query of a subdivision.
   */
  hasAdvancedSearchQuery,
  getAdvancedSearchRuleErrorMessages,
  getAdvancedSearchRuleErrors,
  resetAfterSaveReducer,
  resetIsSubmittingReducer,
  saveSubdivisionChangesReducer,
  getAllSelectedWorkspacesToBindToSubdivision,
  getTouchedSteps,
  hasUnsavedChanges,
  /**
   * Check if the subdivision is in advanced search mode (uses advanced search to assign components).
   */
  isAdvancedSearchMode,
};
