import { ArdoqLoaderSpinner, LoaderSize } from '@ardoq/ardoq-loader-component';
import { colors, timeDefaultMs } from '@ardoq/design-tokens';
import { styled, css } from 'styled-components';

const containerCss = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 50;
`;

const SpinnerContainer = styled.div`
  ${containerCss};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: ${timeDefaultMs} fadeIn;
`;

export const ShareModalLoadingOverlay = () => {
  return (
    <SpinnerContainer>
      <ArdoqLoaderSpinner color={colors.brand50} size={LoaderSize.LARGE} />
    </SpinnerContainer>
  );
};
