import {
  action$,
  reducedStream,
  reducer,
  reduceState,
  streamReducer,
} from '@ardoq/rxbeach';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { returnPayload, returnFalse, returnTrue } from '@ardoq/common-helpers';
import { showQuickSearch, hideQuickSearch } from './actions';

const isOpen$ = action$.pipe(
  reduceState('isOpen', false, [
    reducer(showQuickSearch, returnTrue),
    reducer(hideQuickSearch, returnFalse),
  ]),
  debounceTime(32),
  distinctUntilChanged()
);

export const isQuickSearchVisible$ = reducedStream<boolean>(
  'isQuickSearchVisible$',
  false,
  [streamReducer(isOpen$, returnPayload)]
);
