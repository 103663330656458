import { persistentReducedStream } from '@ardoq/rxbeach';
import reducers, { defaultState } from './assetsBrowser2024Reducers';

const assetsBrowser2024$ = persistentReducedStream(
  'assetsBrowser2024$',
  defaultState,
  reducers
);

export default assetsBrowser2024$;
