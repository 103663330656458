import { ArdoqId } from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';

export enum ViewpointTrackingEventsNames {
  CLICKED_ON_CREATE_VIEWPOINT_IN_VIEWPOINT_OVERVIEW = 'clicked_on_create_viewpoint_in_viewpoint_overview',
  CLICKED_ON_CREATE_NEW_VIEWPOINT_IN_VIEWPOINT_OVERVIEW = 'Viewpoints page0 Create new viewpoint',
  CLICKED_ON_SET_DEFAULT_VIEWPOINTS_IN_VIEWPOINT_OVERVIEW = 'clicked_on_set_default_viewpoints_in_viewpoint_overview',
  CLICKED_ON_GO_TO_VIEWPOINTS_OVERVIEW_IN_VIEWPOINT_BUILDER = 'clicked_on_go_to_viewpoints_overview_in_viewpoint_builder',
  OPENED_EXISTING_VIEWPOINT = 'opened_existing_viewpoint',
  OPENED_NEW_VIEWPOINT_BUILDER = 'opened_new_viewpoint_builder',
}

export const trackOpenExistingViewpoint = (resourceId?: ArdoqId) =>
  trackEvent(ViewpointTrackingEventsNames.OPENED_EXISTING_VIEWPOINT, {
    resourceId,
  });

export const trackOpenNewViewpointBuilder = (resourceId?: ArdoqId) =>
  trackEvent(ViewpointTrackingEventsNames.OPENED_NEW_VIEWPOINT_BUILDER, {
    resourceId,
  });
