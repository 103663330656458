import { useEffect, useMemo } from 'react';
import {
  ResultBody,
  ResultFooter,
  ResultItem,
  ResultMenu,
  ScrollableFlexBox,
} from './atoms';
import { QuickSearchResultItem, ResultInState } from './types';
import { useSelectorController } from './useSelectorController';
import { NewResultItem } from './searchResultComponents';
import ResultItemDetails from './ResultItemDetails';
import { debounce } from 'lodash';
import OldResultItem from './OldResultItem';
import { connect } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import quickSearch$ from './quickSearch$';
import { ResultCommands, resultCommands } from './commands';
import {
  composeQuickSearchTrackingMetaData,
  trackQuickSearch,
  trackSelectedQuickSearchResult,
} from './tracking';
import { Box } from '@ardoq/layout';
import { Text } from '@ardoq/typography';
import { MAX_RESULTS } from './reducers';
import { QuickSearchOptions } from '@ardoq/api-types';

type ResultsProps = {
  query: string;
  results: QuickSearchResultItem[];
  total: number;
  hasNewJourneyFeature?: boolean;
  lockedResultItem: ResultInState | null;
  previewedResultItem: ResultInState | null;
  commands: ResultCommands;
  options: QuickSearchOptions;
  handleSelectResult: (props: QuickSearchResultItem) => void;
};

const Results = ({
  query,
  results,
  total,
  hasNewJourneyFeature,
  previewedResultItem,
  lockedResultItem,
  commands,
  options,
  handleSelectResult,
}: ResultsProps) => {
  const { selectedId, handleHover, resetSelectedId } =
    useSelectorController<QuickSearchResultItem>(results, handleSelectResult);

  useEffect(() => {
    resetSelectedId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results.length]);

  const handleSelectEntity = (
    selectedIndex: number,
    result: QuickSearchResultItem
  ) => {
    const trackingMetaData = composeQuickSearchTrackingMetaData(total, options);
    trackQuickSearch(trackingMetaData);
    if (trackingMetaData) {
      trackSelectedQuickSearchResult({
        ...trackingMetaData,
        selectedIndex,
        query,
        result,
      });
    }
    handleSelectResult(result);
  };

  const debouncedHandleMouseEnter = useMemo(
    () =>
      debounce(
        (result, index) => commands.setPreviewedResultItem({ result, index }),
        200
      ),
    [commands]
  );

  const handleOnMouseLeave = () => {
    debouncedHandleMouseEnter.cancel();
  };

  const currentSideDetailItem = previewedResultItem || lockedResultItem;
  const noResults = results.length === 0;
  const subsetOfResults = results.length === MAX_RESULTS && total > MAX_RESULTS;
  return (
    <ResultMenu
      $hasNewJourneyFeature={hasNewJourneyFeature}
      onMouseLeave={() => {
        commands.setPreviewedResultItem(null);
      }}
    >
      <ScrollableFlexBox>
        {!!results.length && (
          <ResultBody>
            {hasNewJourneyFeature && (
              <Box paddingX="medium" paddingY="xsmall">
                <Text variant="caption">Results</Text>
              </Box>
            )}
            {results.map((result, index) => {
              const { id } = result;
              return (
                <ResultItem
                  key={id}
                  $isSelected={id === selectedId}
                  $isLocked={lockedResultItem?.result.id === id}
                  onClick={e => {
                    if (!hasNewJourneyFeature) {
                      e.stopPropagation();
                      handleSelectEntity(index, result);
                      return;
                    }
                    commands.setLockedResultItem({ result, index });
                  }}
                  onDoubleClick={e => {
                    if (!hasNewJourneyFeature) return;

                    e.stopPropagation();
                    handleSelectEntity(index, result);
                  }}
                  onMouseEnter={() => {
                    if (hasNewJourneyFeature) {
                      debouncedHandleMouseEnter(result, index);
                    }
                    handleHover(id);
                  }}
                  onMouseLeave={() => {
                    if (!hasNewJourneyFeature) return;
                    handleOnMouseLeave();
                  }}
                  $hasNewJourneyFeature={hasNewJourneyFeature}
                >
                  {hasNewJourneyFeature ? (
                    <NewResultItem result={result} />
                  ) : (
                    <OldResultItem result={result} />
                  )}
                </ResultItem>
              );
            })}
          </ResultBody>
        )}
        {hasNewJourneyFeature &&
          results.length > 0 &&
          currentSideDetailItem && (
            <ResultItemDetails
              result={currentSideDetailItem.result}
              selectItem={() => {
                handleSelectEntity(
                  currentSideDetailItem.index,
                  currentSideDetailItem.result
                );
              }}
            />
          )}
      </ScrollableFlexBox>
      {(noResults || subsetOfResults) && (
        <ResultFooter>
          {noResults && 'No results found'}
          {subsetOfResults &&
            total > MAX_RESULTS &&
            'Showing only a subset of results, refine your search to see more'}
        </ResultFooter>
      )}
    </ResultMenu>
  );
};

export default connect(
  Results,
  quickSearch$.pipe(
    map(({ lockedResultItem, previewedResultItem, options }) => {
      return {
        lockedResultItem,
        previewedResultItem,
        commands: resultCommands,
        options,
      };
    })
  )
);
