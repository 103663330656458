import { combineLatest, map } from 'rxjs';
import fp from 'lodash/fp';
import { dispatchAction } from '@ardoq/rxbeach';
import { selectionState$ } from '../../streams/selectionState/selectionState$';
import {
  microsoftEntraIdTables$,
  MicrosoftEntraIdTablesState,
} from '../../streams/tables/tables$';
import {
  selectField,
  selectFields,
  unselectField,
  unselectFields,
} from '../../streams/selectionState/actions';
import { SelectorField } from 'integrations/common/types/common';
import { SelectionState } from '../../streams/selectionState/types';
import { RequiredFieldsForImportPhotosPopover } from './atoms';

const buildModel = ({
  selectionState,
  microsoftEntraIdTables,
}: {
  selectionState: SelectionState;
  microsoftEntraIdTables: MicrosoftEntraIdTablesState;
}) => {
  const currentTable = microsoftEntraIdTables.tables.find(
    table => selectionState.currentTableId === table.id
  );
  const base = {
    currentTable,
    selectedFields: [],
    availableFields: [],
    isLoading: false,
    queryFilter: null,
    onClickRemove: () => {},
    onClickAdd: () => {},
    onClickAddAll: () => {},
    onClickRemoveAll: () => {},
    onApplyQueryFilter: () => {},
  };

  if (currentTable) {
    const fields = currentTable.fields.map(field => ({
      label: field.name,
      name: field.id,
      required:
        selectionState.importProfilesPhotos &&
        currentTable.id === 'user' &&
        ['displayName', 'id'].includes(field.id),
    }));

    const [selectedFields, availableFields] = fp.partition(
      (item: SelectorField) =>
        Object.keys(
          selectionState.tables[currentTable.id]?.fields || {}
        ).includes(item.name)
    )(Object.values(fields));

    return {
      ...base,
      selectedFields,
      availableFields,
      isLoading: false,
      requiredFieldsPopoverContent: selectionState.importProfilesPhotos
        ? {
            displayName: RequiredFieldsForImportPhotosPopover,
            id: RequiredFieldsForImportPhotosPopover,
          }
        : {},
      onClickRemoveAll: () => {
        dispatchAction(
          unselectFields({
            tableId: currentTable.name,
            ids:
              selectedFields
                .filter(field => !field.required)
                .map(({ name }) => name) || [],
          })
        );
      },
      onClickAddAll: () => {
        dispatchAction(
          selectFields({
            tableId: currentTable.name,
            ids: availableFields?.map(({ name }) => name) || [],
          })
        );
      },
      onClickAdd: (field: SelectorField) => {
        dispatchAction(
          selectField({ tableId: currentTable.name, id: field.name })
        );
      },
      onClickRemove: (field: SelectorField) => {
        dispatchAction(
          unselectField({ tableId: currentTable.name, id: field.name })
        );
      },
    };
  }

  return base;
};

export const viewModel$ = combineLatest({
  selectionState: selectionState$,
  microsoftEntraIdTables: microsoftEntraIdTables$,
}).pipe(map(buildModel));
