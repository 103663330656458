import { actionCreator } from '@ardoq/rxbeach';
import {
  SelectionRequestPayload,
  SelectionResponse,
  CloudProviderConfig,
} from '@ardoq/api-types/integrations';
import { AsyncStatus } from 'integrations/common/types/api';

const NAMESPACE = '[integrations aws-v3] SELECTION';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const setSelectionAsyncStatus = actionCreator<AsyncStatus>(
  getActionName('SET_SELECTION_ASYNC_STATUS')
);

export const setFetchErrorMessage = actionCreator<string | null>(
  getActionName('SET_FETCH_ERROR_MESSAGE')
);

export const fetchSelectionSuccess = actionCreator<SelectionResponse>(
  getActionName('FETCH_SELECTION_SUCCESS')
);

export const setSelectionRequest = actionCreator<SelectionRequestPayload>(
  getActionName('SET_SELECTION_REQUEST')
);

export const applyCloudProviderParams = actionCreator<
  CloudProviderConfig['providerParams']
>(getActionName('APPLY_CLOUD_PROVIDER_PARAMS'));

export const unselectCloudProviderParams = actionCreator(
  getActionName('UNSELECT_COULD_PROVIDER_PARAMS')
);
