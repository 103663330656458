import { IconName } from '@ardoq/icons';
import styled from 'styled-components';
import { dispatchAction } from '@ardoq/rxbeach';
import { hideViewSidePanel } from 'views/mainApp/viewSidePanel/actions';
import { colors } from '@ardoq/design-tokens';
import { header4 } from '@ardoq/typography';
import { PropsWithChildren } from 'react';
import { ViewOptionsClickableIcon } from '@ardoq/perspectives-sidebar';

const HeaderContainer = styled.div<{
  $isUnderlined?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
  color: ${colors.grey25};
  ${header4}

  ${({ $isUnderlined }) =>
    $isUnderlined && `border-bottom: 1px solid ${colors.grey90};`}
`;

type ViewOptionsHeaderProperties = PropsWithChildren<{
  isUnderlined?: boolean;
}>;

const ViewOptionsHeader = ({
  children,
  isUnderlined,
}: ViewOptionsHeaderProperties) => (
  <HeaderContainer $isUnderlined={isUnderlined}>
    {children}
    <ViewOptionsClickableIcon
      iconName={IconName.CLOSE}
      onClick={() => dispatchAction(hideViewSidePanel())}
    />
  </HeaderContainer>
);
export default ViewOptionsHeader;
