import { DiffType } from '@ardoq/data-model';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import {
  Font,
  FontWeight,
  Size,
  TextMeasurePolicy,
  TextWrapping,
} from '@ardoq/yfiles';
import { MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH } from './modernized/consts';
import {
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_LABEL_MAX_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_LABEL_WIDTH,
} from 'tabview/blockDiagram/view/yFilesExtensions/modernized/consts';
import { OTHER_LABEL_TEXT_ELEMENT_HORIZONTAL_PADDING } from 'tabview/blockDiagram/view/yFilesExtensions/labels/consts';

export const DATA_RENDER_HASH = 'data-render-hash';
export const NODE_HEIGHT = 72;
export const NODE_WIDTH = 128.5;
export const SPACE_FACTOR = NODE_WIDTH / 8;
export const CONTEXT_HIGHLIGHT_PADDING = 30;
export const HALO_PADDING = 8;
export const CONTEXT_HIGHLIGHT_STROKE = 1;
export const LABEL_HORIZONTAL_PADDING = 30;
export const LABEL_VERTICAL_PADDING = 6;
/** the vertical space above the top other label. */
export const OTHER_LABEL_MARGIN = LABEL_VERTICAL_PADDING;
export const SELECTION_RADIUS_DIFF = 6;
export const SELECTION_STROKE_WIDTH = 4;
export const NODE_HEIGHT_WIDTH_SELECTION =
  NODE_HEIGHT + 2 * SELECTION_RADIUS_DIFF + SELECTION_STROKE_WIDTH;

export const IMAGE_WIDTH = 72;
export const IMAGE_HEIGHT = 72;
export const PLACEHOLDER_RADIUS = 72 / 2;

export const URL_BOX_ICON_CLASS_NAME = 'yfiles-url-box-icon';

export const URL_ICON_MARGIN = 8;
export const URL_ICON_SIZE = 24;

export const visualDiffModes = new Set([
  DiffType.ADDED,
  DiffType.REMOVED,
  DiffType.CHANGED,
  DiffType.UNCHANGED,
]);

export const LABEL_CLASS = 'label-background';

export const BLOCK_DIAGRAM_NODE_LABEL_FONT = new Font({
  fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  fontSize: 32,
  fontWeight: FontWeight.ITEM400,
});
export const MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT = new Font({
  fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  fontSize: 12,
  fontWeight: FontWeight.ITEM500,
  lineSpacing: 0.375,
});
export const BLOCK_DIAGRAM_EDGE_LABEL_FONT = new Font({
  fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  fontSize: 24,
  fontWeight: FontWeight.ITEM400,
});
export const MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT = new Font({
  fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  fontSize: 11,
  fontWeight: FontWeight.ITEM400,
});
export const BLOCK_DIAGRAM_OTHER_LABEL_FONT = new Font(
  ARDOQ_DEFAULT_FONT_FAMILY,
  28
);
export const MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT = new Font(
  ARDOQ_DEFAULT_FONT_FAMILY,
  12
);
export const BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE = new Size(
  300,
  8 * BLOCK_DIAGRAM_OTHER_LABEL_FONT.fontSize
);
export const MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE = new Size(
  MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH -
    2 * OTHER_LABEL_TEXT_ELEMENT_HORIZONTAL_PADDING,
  8 * MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT.fontSize
);
const MAX_WIDTH = 300;

export const BLOCK_DIAGRAM_EDGE_LABEL_MAX_SIZE = new Size(
  MAX_WIDTH,
  8 * BLOCK_DIAGRAM_EDGE_LABEL_FONT.fontSize
);
export const MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_MAX_SIZE = new Size(
  MAX_WIDTH,
  8 * MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT.fontSize
);
export const BLOCK_DIAGRAM_NAME_LABEL_MAX_SIZE = new Size(
  300,
  8 * BLOCK_DIAGRAM_NODE_LABEL_FONT.fontSize
);
export const MODERNIZED_BLOCK_DIAGRAM_NAME_LABEL_MAX_SIZE = new Size(
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_LABEL_WIDTH,
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_LABEL_MAX_HEIGHT
);
export const BLOCK_DIAGRAM_LABEL_TEXT_PARAMS = {
  maximumSize: BLOCK_DIAGRAM_NAME_LABEL_MAX_SIZE,
  wrapping: TextWrapping.WORD_ELLIPSIS,
  measurePolicy: TextMeasurePolicy.SVG,
};
export const MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS = {
  maximumSize: MODERNIZED_BLOCK_DIAGRAM_NAME_LABEL_MAX_SIZE,
  wrapping: TextWrapping.WORD_ELLIPSIS,
  measurePolicy: TextMeasurePolicy.SVG,
};

export const BLOCK_DIAGRAM_LABEL_TEXT_PARAMS_INFINITE_HEIGHT = {
  ...BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  maximumSize: new Size(
    BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize.width,
    Infinity
  ),
};
export const MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS_INFINITE_HEIGHT = {
  ...MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  maximumSize: new Size(
    MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize.width,
    Infinity
  ),
};
