import { showLaunchCurrentBroadcastDialog } from 'broadcasts/actions';
import {
  initiateNavigationToActiveBroadcastForm,
  initiateNavigationToEditBroadcastForm,
  initiateNavigationToNewBroadcastForm,
} from 'router/navigationActions';
import {
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';

export type ValidationStreamShape = {
  showRequiredFields: boolean;
};

const getDefaultState = () => ({
  showRequiredFields: false,
});

export const showRequiredFields = actionCreator<boolean>(
  '[broadcastBuilderValidation] SHOW_REQUIRED_FIELDS'
);

const handleEditForm = reducer(
  initiateNavigationToEditBroadcastForm,
  getDefaultState
);

const handleNewForm = reducer(
  initiateNavigationToNewBroadcastForm,
  getDefaultState
);

const handleActiveForm = reducer(
  initiateNavigationToActiveBroadcastForm,
  getDefaultState
);

const handleLaunchDialog = (state: ValidationStreamShape) => ({
  ...state,
  showRequiredFields: true,
});

const handleShowRequiredFields = (
  state: ValidationStreamShape,
  payload: boolean
) => ({
  ...state,
  showRequiredFields: payload,
});

const reducers = [
  handleEditForm,
  handleNewForm,
  handleActiveForm,
  reducer(showLaunchCurrentBroadcastDialog, handleLaunchDialog),
  reducer(showRequiredFields, handleShowRequiredFields),
];

const validation$ = persistentReducedStream(
  'broadcastBuilderValidation$',
  getDefaultState(),
  reducers
);

export default validation$;
