import { GetContextMenuData } from '@ardoq/context-menu';
import { TAG_ID_ATTRIBUTE, TAG_ID_SELECTOR } from 'consts';
import { getTagMenu } from 'contextMenus/tagMenu';

const findElement = (selector: string, target: Element) => {
  const element = target.closest(selector);
  return element instanceof HTMLElement || element instanceof SVGElement
    ? element
    : null;
};

const getTargetIds = (target: HTMLElement | SVGElement) => {
  const tagElement = findElement(TAG_ID_SELECTOR, target);

  return tagElement?.getAttribute(TAG_ID_ATTRIBUTE) || null;
};

const getContextMenuData: GetContextMenuData = ctxMenuArguments => {
  const { target } = ctxMenuArguments;

  const tagId = target ? getTargetIds(target) : null;

  if (tagId) {
    return getTagMenu({ tagId, ...ctxMenuArguments });
  }

  return { options: null };
};

export const tagScapeContextMenuName = 'tagscape-context-menu';

export const tagScapeContextMenu = {
  getContextMenuData,
};
