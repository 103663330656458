import { action$, ofType } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { combineLatest } from 'rxjs';
import { notifyFiltersChanged } from '../filters/FilterActions';
import Filters from 'collections/filters';
import { viewModel$ } from 'services/dynamicFilterServiceTC';
import { editorOpened } from 'perspective/actions';
import { PerspectivesEditorFilters } from 'perspective/perspectiveEditor/types';

export const getPerspectiveQueries = () => ({
  ...Filters.getFiltersAsQueryBuilderQueries(),
  workspaceFilterIds: Filters.getWorkspaceIdsInWorkspaceFilter(),
});

const perspectiveQueries$ = action$.pipe(
  ofType(notifyFiltersChanged, editorOpened),
  map(getPerspectiveQueries)
);

export const filters$ = combineLatest([perspectiveQueries$, viewModel$]).pipe(
  map(([perspectiveQueries, { dynamicFilters }]): PerspectivesEditorFilters => {
    return { ...perspectiveQueries, dynamicFilters };
  })
);
