import { ModalLayout, ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { Select } from '@ardoq/select';
import { header4, text2 } from '@ardoq/typography';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { Connection } from '@ardoq/api-types/integrations';
import { useState } from 'react';
import { connect } from '@ardoq/rxbeach';
import { filter, switchMap } from 'rxjs';
import { styled } from 'styled-components';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';

type MissingConnectionModalProps = {
  onCancel: () => void;
  onSubmit: (connection: Connection) => void;
  connections: Connection[];
};

const MissingConnectionModalComponent = ({
  connections = [],
  onCancel,
  onSubmit,
}: MissingConnectionModalProps) => {
  const [selectedConnection, setSelectedConnection] = useState<
    Connection['_id'] | null
  >(null);

  return (
    <ModalTemplate
      headerText="Choose a connection"
      modalSize={ModalSize.S}
      isPrimaryButtonDisabled={!selectedConnection}
      primaryButtonText="Choose"
      onPrimaryButtonClick={() => {
        const connection = connections.find(
          conn => conn._id === selectedConnection
        );
        if (connection) {
          onSubmit(connection);
        }
      }}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onCancel}
    >
      <ModalLayout>
        <ContentHeader> The connection was missing or invalid.</ContentHeader>
        <ContentMessage>
          To proceed to view and modify the configuration, select a connection.
        </ContentMessage>
        <Select
          label="Existing connections"
          value={selectedConnection}
          options={connections.map(conn => ({
            label: conn.name,
            value: conn._id,
          }))}
          onValueChange={setSelectedConnection}
        />
      </ModalLayout>
    </ModalTemplate>
  );
};

const ContentHeader = styled.div`
  ${header4}
`;

const ContentMessage = styled.div`
  ${text2}
`;

const MissingConnectionModal = connect(
  MissingConnectionModalComponent,
  integrationId$.pipe(
    filter(isUnifiedIntegrationId),
    switchMap(integrationId => getConnectionsStream(integrationId))
  )
);

export const startMissingConnectionModal = ({
  onSubmit,
  onCancel,
}: Pick<MissingConnectionModalProps, 'onSubmit' | 'onCancel'>) =>
  modal<boolean>(
    resolve => (
      <MissingConnectionModal
        onSubmit={(connection: Connection) => {
          resolve(true);
          onSubmit(connection);
        }}
        onCancel={() => {
          resolve(false);
          onCancel();
        }}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
