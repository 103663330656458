import { ApiResponse } from '@ardoq/api';
import { ArdoqId, AssetType } from '@ardoq/api-types';

export enum CopyModeOptions {
  FULL = 'FULL',
  ONLY_DASHBOARD = 'ONLY_DASHBOARD',
}

export type CopyModalProps = {
  entityName: string;
  copyEntity: (
    entityId: ArdoqId
  ) => ApiResponse<{ name: string; _id: ArdoqId }>;
  entityType: AssetType;
  openCopy: (copyId: ArdoqId) => void;
};

export type CopyModeRadioGroupProps = {
  selectedMode: CopyModeOptions;
  onChange: (value: CopyModeOptions) => void;
};

export type CopyModalResult = { newName: string; targetFolderName: string };

export type CopyDialogProps = {
  name: string;
  firstEntityType: string;
  secondEntityType: string;
  isEmptyInputsAllowed?: boolean;
  onConfirm: (
    isShallowCopy: boolean,
    newName: string,
    targetFolderName: string
  ) => void;
  onCancel: VoidFunction;
};
