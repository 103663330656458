import { Tag } from '@ardoq/status-ui';
import { AppliedSettingsRow } from './AppliedSettingsRow';
import { IconName } from '@ardoq/icons';
import { LabelSetting } from './types';

type Props = {
  settings: LabelSetting;
};

export const LabelSettingsWithMultiselect = ({ settings }: Props) => {
  const { labelFormatting, onEditClick } = settings;
  const customLabelsCount = labelFormatting.length;

  const tag = (
    <Tag label={customLabelsCount.toString()} key="LabelFormattingTag" />
  );

  return (
    <AppliedSettingsRow
      label="Labels"
      tags={[tag]}
      icon={IconName.TAG}
      onEditClick={onEditClick}
      isDisabled={!customLabelsCount}
    />
  );
};
