import { ReactNode, PropsWithChildren } from 'react';
import { s4, s8 } from '@ardoq/design-tokens';
import { header4 } from '@ardoq/typography';
import styled from 'styled-components';
import { GhostButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';

export const TableData = styled.td`
  width: 55px;
`;

export const TwentyFiveWideTd = styled.td`
  width: 25px;
`;

type TableDeleteButtonProps = {
  dataTestId: string;
  tooltipText: string;
  handleDelete: () => void;
};

export const TableDeleteButton = ({
  dataTestId,
  tooltipText,
  handleDelete,
}: TableDeleteButtonProps) => {
  return (
    <TwentyFiveWideTd>
      <GhostButton
        data-tooltip-text={tooltipText}
        onClick={handleDelete}
        dataTestId={dataTestId}
      >
        <Icon iconName={IconName.CLOSE} />
      </GhostButton>
    </TwentyFiveWideTd>
  );
};

const Header = styled.div`
  ${header4};
  flex: 1;
  align-items: center;
`;

const MiniTable = styled.table`
  width: 100%;
  table-layout: auto;
  th,
  td {
    padding: ${s8} ${s4};
  }
`;

type TableProps = {
  tableHeaders: { label: string; icon?: ReactNode }[];
};

const Table = ({ tableHeaders, children }: PropsWithChildren<TableProps>) => {
  return (
    <MiniTable>
      <thead>
        <tr>
          {tableHeaders.map((header, i) => (
            <th key={i}>
              <Header>
                {header.label}
                {header?.icon}
              </Header>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </MiniTable>
  );
};

export default Table;
