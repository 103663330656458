import React from 'react';
import { FlexBox, Stack } from '@ardoq/layout';
import styled from 'styled-components';
import { Group, Integration } from 'integrations/types';
import { Header4 } from '@ardoq/typography';
import ApiGuideBox from '../ApiGuideBox';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import {
  IntegrationBox,
  IntegrationTileSize,
} from 'integrations/common/components/integrationBox/IntegrationBox';
import { OverviewRoute } from 'integrations/common/navigation/types';
import RequestIntegrationWidget from 'integrations/dashboard/widgets/RequestIntegrationWidget';
import { getApiGuides } from 'integrations/dashboard/utils';

const IntegrationsContainer = styled(Stack)`
  flex-wrap: wrap;
  margin: 0 24px;
`;

const IntegrationsFooterContainer = styled(FlexBox)`
  width: 70%;
  min-width: 930px;
`;

interface IntegrationConfig {
  allowedOverviewRoutes: OverviewRoute[];
}

export type IntegrationsCommands = {
  openIntegration: (integration: Integration) => void;
  showSupport: (message: string) => void;
  openNewTab: (url: string) => void;
  updateIntegrationConfig: (
    integrationId: IntegrationId,
    config: IntegrationConfig
  ) => void;
};

type IntegrationsListProps = {
  integrations: Integration[];
  onIntegrationClick: (integration: Integration) => void;
};

const IntegrationsList = ({
  integrations,
  onIntegrationClick,
}: IntegrationsListProps) => {
  return (
    <IntegrationsContainer gap="large">
      <FlexBox justify="flex-start" flexWrap={true} gap="medium">
        {integrations
          .filter(integration => integration.group === Group.INTEGRATIONS)
          .map(integration => (
            <IntegrationBox
              key={integration.id}
              integration={integration}
              onClick={integration => onIntegrationClick(integration)}
              size={IntegrationTileSize.medium}
            />
          ))}
      </FlexBox>
      <Stack gap="medium">
        <Header4>More ways to connect</Header4>
        <FlexBox justify="flex-start" flexWrap={true} gap="medium">
          {getApiGuides(integrations).map(integration => (
            <ApiGuideBox
              key={integration.id}
              integration={integration}
              onClick={integration =>
                !integration.disableOptions?.isDisabled &&
                onIntegrationClick(integration)
              }
            />
          ))}
        </FlexBox>
        <IntegrationsFooterContainer marginTop="medium">
          <RequestIntegrationWidget />
        </IntegrationsFooterContainer>
      </Stack>
    </IntegrationsContainer>
  );
};

export default IntegrationsList;
