import { combineLatest, map, Observable } from 'rxjs';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  TablesSelectionBaseTable,
  TablesSelectionTablePayload,
} from '@ardoq/integrations';
import { selectionState$ } from '../../streams/selectionState/selectionState$';
import {
  selectTable,
  unselectTable,
} from '../../streams/selectionState/actions';
import { microsoftEntraIdTables$ } from '../../streams/tables/tables$';
import { TableSelectorProps } from './types';

const onSelectTable = (table: TablesSelectionBaseTable) => {
  dispatchAction(selectTable(table.id));
};

const onUnselectTable = (table: TablesSelectionBaseTable) => {
  dispatchAction(unselectTable(table.id));
};

export const viewModel$: Observable<TableSelectorProps> = combineLatest({
  selectionState: selectionState$,
  tablesState: microsoftEntraIdTables$,
}).pipe(
  map(({ selectionState, tablesState }) => {
    const tables: TablesSelectionTablePayload[] = tablesState.tables.map(
      table => ({
        id: table.id,
        name: table.id,
        label: table.name,
        canRead: true,
        canWrite: false,
        canDelete: false,
        canCreate: false,
      })
    );

    return {
      tables,
      selectionState,
      tablesStatus: 'SUCCESS',
      onSelectTable,
      onUnselectTable,
    };
  })
);
