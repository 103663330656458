import { EMPTY_CONTEXT_SHAPE } from 'streams/context/ContextShape';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, map, startWith, tap } from 'rxjs/operators';
import {
  combineReducers,
  streamReducer,
  action$,
  dispatchAction,
  extractPayload,
  ofType,
  ActionCreator,
} from '@ardoq/rxbeach';
import { ViewIds } from '@ardoq/api-types';
import { readViewComponentIds } from 'streams/viewComponentIds$';
import { uniq } from 'lodash';
import {
  EMPTY_LOADED_SCENARIO_DATA,
  loadedScenarioData$,
} from 'loadedScenarioData$';
import defaultState from 'views/defaultState';
import { ViewSettings } from 'viewSettings/viewSettingsStreams';
import { mainAppModuleSidebar$ } from 'appContainer/MainAppModule/MainAppModuleSidebar/mainAppModuleSidebar$';
import buildViewModel, { BuildViewModelArgs } from './buildViewModel';
import {
  collapseAllGroups,
  expandAllGroups,
  isViewBusy,
  toggleCollapseGroup,
} from './toggleCollapseGroup';
import { relationshipDiagramReset } from '../actions';
import { startAction } from 'actions/utils';
import { modelUpdateActions } from 'modelInterface/modelUpdateNotification$';
import { isEqual } from 'lodash';
import {
  ComponentIdsPayload,
  notifyComponentsSynced,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import {
  type HasGetDistinctReferenceKey,
  type RelationshipDiagramViewSettings,
  type RelationshipDiagramViewModel,
  type RelationshipDiagramViewModelStreamState,
  getEmptyTraversalAndGroupingResult,
  GraphNode,
  CollapsibleGraphGroup,
  getGraphNodeOrGroupLabel,
  TraversalAndGroupingResult,
  ItemLabels,
} from '@ardoq/graph';
import { GraphItemsModel } from 'tabview/graphComponent/types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import relationshipDiagramComponentUpdate$ from './relationshipDiagramComponentUpdate$';
import { notifyViewLoading } from 'tabview/actions';
import {
  DEFAULT_DEBOUNCE_TIME,
  EMPTY_GRAPH_MODEL,
} from 'modelInterface/consts';
import { isUpdatingAppState$ } from 'isUpdatingAppState$';
import { getViewSettingsFilter } from 'views/filteredViewSettings$';
import { settingsBarConsts } from '@ardoq/settings-bar';
import { context$ } from 'streams/context/context$';
import { scenarioRelatedComponents$ } from 'scenarioRelated/scenarioRelatedComponents$';
import { scopeDiff$ } from 'scope/scopeDiff$';
import graphModel$ from 'modelInterface/graphModel$';
import { Features, hasFeature } from '@ardoq/features';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { loadedGraphOperations } from 'traversals/loadedGraphOperations';
import { ExtractStreamShape } from 'tabview/types';

export const relationshipDiagramEmptyState = <
  TViewSettings extends RelationshipDiagramViewSettings,
>(
  viewId: ViewIds
): RelationshipDiagramViewModelStreamState<TViewSettings> => ({
  viewSettings: defaultState.get(viewId) as TViewSettings,
  viewModel: {
    errors: [],
    hasClones: false,
    traversedIncomingReferenceTypes: [],
    traversedOutgoingReferenceTypes: [],
    referenceTypes: [],
    groups: { byId: new Map(), add: [], update: [], remove: [] },
    nodes: { byId: new Map(), add: [], update: [], remove: [] },
    edges: { byId: new Map(), add: [], update: [], remove: [] },
    expandedStateChangingGroupId: null,
    noConnectedComponents: false,
    urlFieldValuesByComponentId: new Map(),
    urlFieldValuesByReferenceId: new Map(),
    componentIdsOfCollapsedGroups: [],
  },
  streamState: {
    context: EMPTY_CONTEXT_SHAPE,
    graphModel: EMPTY_GRAPH_MODEL,
    loadedScenarioData: EMPTY_LOADED_SCENARIO_DATA,
    scenarioRelatedComponents: { componentIds: [] },
    showScopeRelated: false,
    skipRender: false,
    isUpdatingAppState: false,
    rawGraph: getEmptyTraversalAndGroupingResult(),
    nodeLabelsData: new Map(),
  },
  viewInstanceId: '',
  noContext: false,
  loadedGraph: loadedGraphOperations.getEmpty(),
  viewId,
});

const getComponentIdsFromViewModel = (
  viewModel: RelationshipDiagramViewModel
) => {
  return uniq([
    ...[...viewModel.nodes.add, ...viewModel.nodes.update].map(
      id => viewModel.nodes.byId.get(id)!.modelId
    ),
    ...[...viewModel.groups.add, ...viewModel.groups.update]
      .map(id => viewModel.groups.byId.get(id)!.modelId)
      .filter(componentInterface.isComponent),
    ...viewModel.componentIdsOfCollapsedGroups,
  ]);
};

const updateComponentsActions = new Set<ActionCreator<ComponentIdsPayload>>([
  notifyComponentsUpdated,
  notifyComponentsSynced,
]);
interface GetRelationshipDiagramViewModelStreamArgs<
  TViewSettings extends RelationshipDiagramViewSettings,
> extends HasGetDistinctReferenceKey {
  viewId: ViewIds;
  viewSettings$: Observable<ViewSettings<TViewSettings>>;
  viewSettingsToIgnore:
    | (keyof TViewSettings | typeof settingsBarConsts.FULLSCREEN_KEY)[]
    | null;
  viewInstanceId?: string;
  overrideTraverseOptions?: BuildViewModelArgs['overrideTraverseOptions'];
  /** Whether or not to collapse the graph in the view model stream. If true, collapsed groups passed to the view will have their children removed from the view model. */
  shouldCollapseGraph: boolean;
  hasRelevantChanges?: HasRelevantChanges;
}
export const getRelationshipDiagramViewModel$ = <
  TViewSettings extends RelationshipDiagramViewSettings,
>({
  viewId,
  viewSettings$,
  viewSettingsToIgnore,
  viewInstanceId = '',
  overrideTraverseOptions,
  getDistinctReferenceKey,
  shouldCollapseGraph,
  hasRelevantChanges,
}: GetRelationshipDiagramViewModelStreamArgs<TViewSettings>): Observable<
  RelationshipDiagramViewModelStreamState<TViewSettings>
> => {
  // this is stupid, but I need to keep the # of parameters to combineLatest under 7 or else it won't find a good overload in the typescript defs.
  const ignoreViewSettingsChange = getViewSettingsFilter<TViewSettings>(
    ...(viewSettingsToIgnore ?? []),
    'collapsedGroupIds'
  );
  const contextAndViewSettings$ = combineLatest([
    context$,
    viewSettings$.pipe(
      map((viewSettings, index) => ({
        currentSettings: viewSettings.currentSettings,
        ignore: ignoreViewSettingsChange(viewSettings, index),
      }))
    ),
  ]);
  const scenario$ = combineLatest([
    loadedScenarioData$,
    scenarioRelatedComponents$,
    mainAppModuleSidebar$,
    scopeDiff$,
  ]);
  const modelUpdateReset$ = combineLatest([
    action$.pipe(
      ofType(
        ...modelUpdateActions.filter(
          action => !updateComponentsActions.has(action)
        )
      ),
      startWith(startAction()),
      debounceTime(DEFAULT_DEBOUNCE_TIME)
    ),
    relationshipDiagramComponentUpdate$(hasRelevantChanges).pipe(
      extractPayload(),
      filter(({ componentIds }) =>
        componentIds.some(componentId =>
          componentInterface.hasChangedAttribute(componentId, 'parent')
        )
      ),
      startWith(startAction()),
      debounceTime(DEFAULT_DEBOUNCE_TIME)
    ),
  ]);

  const reset$ = combineLatest({
    contextAndViewSettings: contextAndViewSettings$,
    graphModel: graphModel$,
    scenario: scenario$,
    isUpdatingAppState: isUpdatingAppState$,
    loadedGraph: loadedGraph$,
    relationshipDiagramReset: action$.pipe(
      ofType(relationshipDiagramReset),
      startWith(startAction())
    ),
    modelUpdateReset: modelUpdateReset$,
  });
  const resetReducer = (
    previousState: RelationshipDiagramViewModelStreamState<TViewSettings>,
    {
      contextAndViewSettings: [
        context,
        {
          ignore: ignoreCurrentViewSettingsChange,
          currentSettings: viewSettings,
        },
      ],
      graphModel,
      scenario: [
        loadedScenarioData,
        scenarioRelatedComponents,
        { showScopeRelated },
      ],
      isUpdatingAppState,
      loadedGraph,
    }: ExtractStreamShape<typeof reset$>
  ): RelationshipDiagramViewModelStreamState<TViewSettings> => {
    const skipRender =
      ignoreCurrentViewSettingsChange &&
      !isEqual(viewSettings, previousState.viewSettings);
    if (skipRender) {
      return {
        ...previousState,
        viewSettings,
        streamState: {
          ...previousState.streamState,
          skipRender,
          isUpdatingAppState,
        },
      };
    }
    if (viewInstanceId) {
      dispatchAction(notifyViewLoading({ isBusy: true, viewInstanceId }));
    }
    if (isUpdatingAppState) {
      return {
        ...previousState,
        streamState: {
          ...previousState.streamState,
          isUpdatingAppState,
        },
      };
    }

    if (
      hasFeature(Features.ONLY_VISUALIZE_WHEN_CONTEXT) &&
      !context.componentId
    ) {
      return { ...relationshipDiagramEmptyState(viewId), noContext: true };
    }
    const { rawGraph, nodeLabelsData, ...viewModel } = buildViewModel({
      context,
      viewSettings,
      graphModel,
      loadedScenarioData,
      scenarioRelatedComponents,
      expandedStateChangingGroupId: null,
      showScopeRelated,
      overrideTraverseOptions,
      getDistinctReferenceKey,
      shouldCollapseGraph,
      loadedGraph,
      viewId,
    });

    return {
      viewModel,
      viewSettings,
      streamState: {
        context,
        graphModel,
        loadedScenarioData,
        scenarioRelatedComponents,
        showScopeRelated,
        skipRender,
        isUpdatingAppState,
        rawGraph,
        nodeLabelsData,
      },
      viewInstanceId,
      noContext: false,
      loadedGraph,
      viewId,
    };
  };
  return action$.pipe(
    combineReducers<RelationshipDiagramViewModelStreamState<TViewSettings>>(
      relationshipDiagramEmptyState(viewId),
      [
        streamReducer(reset$, resetReducer),
        isViewBusy(),
        toggleCollapseGroup(),
        expandAllGroups(),
        collapseAllGroups(),
        updateComponents(hasRelevantChanges),
      ],
      { namespace: viewId }
    ),
    filter(
      ({ streamState: { skipRender, isUpdatingAppState } }) =>
        !(skipRender || isUpdatingAppState)
    ),
    tap(({ viewModel }) => {
      dispatchAction(
        readViewComponentIds({
          [viewId]: getComponentIdsFromViewModel(viewModel),
        })
      );
    }),
    debounceTime(2 * DEFAULT_DEBOUNCE_TIME)
  );
};

type MutateItemLabelsArgs = {
  itemId: string;
  items: GraphItemsModel<GraphNode | CollapsibleGraphGroup>;
  nodeLabelsData: Map<string, ItemLabels>;
  rawGraph: TraversalAndGroupingResult;
  isGroup?: boolean;
  isViewpointMode: boolean;
};
const mutateItemLabels = ({
  itemId,
  items,
  nodeLabelsData,
  rawGraph,
  isGroup,
  isViewpointMode,
}: MutateItemLabelsArgs) => {
  const graphItem = items.byId.get(itemId);

  if (!graphItem) {
    return;
  }

  /**
   * Group map is indexed by id paths, while component map is indexed by model id.
   */
  const rawGraphItemsMapIndex = isGroup ? graphItem.id : graphItem.modelId;
  const rawItemsMap = isGroup ? rawGraph.groupMap : rawGraph.componentMap;

  const rawGraphItem = rawItemsMap.get(rawGraphItemsMapIndex);

  if (!rawGraphItem) {
    return;
  }

  const newLabels = getGraphNodeOrGroupLabel(rawGraphItem, isViewpointMode);

  graphItem.setItemLabels(newLabels);
  nodeLabelsData.set(graphItem.id, newLabels);
};

type GetUpdatedItemsArgs = {
  items: GraphItemsModel<GraphNode | CollapsibleGraphGroup>;
  updatedModelIds: Set<string>;
  nodeLabelsData: Map<string, ItemLabels>;
  rawGraph: TraversalAndGroupingResult;
  isViewpointMode: boolean;
  isGroup?: boolean;
};
const getUpdatedItems = ({
  items,
  updatedModelIds,
  nodeLabelsData,
  rawGraph,
  isViewpointMode,
  isGroup,
}: GetUpdatedItemsArgs) => {
  const updatedItemIds = new Set(
    [...items.add, ...items.update].filter(itemId => {
      const item = items.byId.get(itemId);
      return item && updatedModelIds.has(item.modelId);
    })
  );

  updatedItemIds.forEach(itemId =>
    mutateItemLabels({
      itemId,
      items,
      nodeLabelsData,
      rawGraph,
      isGroup,
      isViewpointMode,
    })
  );

  return {
    ...items,
    add: items.add.filter(itemId => !updatedItemIds.has(itemId)),
    update: [...new Set([...items.update, ...updatedItemIds])],
  };
};
type HasRelevantChanges = (changedKeys: string[]) => boolean;
const updateComponents = <
  TState extends RelationshipDiagramViewModelStreamState,
>(
  hasRelevantChanges?: HasRelevantChanges
) => {
  const handleUpdate = (
    state: TState,
    { componentIds }: ComponentIdsPayload
  ): TState => {
    const {
      viewModel,
      streamState: { nodeLabelsData, rawGraph },
    } = state;
    const { nodes, groups } = viewModel;
    const updatedModelIds = new Set(componentIds);
    return {
      ...state,
      streamState: {
        ...state.streamState,
        skipRender: false,
      },
      viewModel: {
        ...viewModel,
        nodes: getUpdatedItems({
          items: nodes,
          updatedModelIds,
          nodeLabelsData,
          rawGraph,
          isViewpointMode: state.loadedGraph.isViewpointMode,
        }),
        groups: getUpdatedItems({
          items: groups,
          updatedModelIds,
          nodeLabelsData,
          rawGraph,
          isGroup: true,
          isViewpointMode: state.loadedGraph.isViewpointMode,
        }),
        expandedStateChangingGroupId: null,
      },
    };
  };
  return streamReducer(
    relationshipDiagramComponentUpdate$(hasRelevantChanges).pipe(
      extractPayload(),
      filter(({ componentIds }) =>
        // a full reset will occur if any parent has changed; in this case, an update is not needed from here.
        componentIds.every(
          componentId =>
            !componentInterface.hasChangedAttribute(componentId, 'parent')
        )
      )
    ),
    handleUpdate
  );
};
