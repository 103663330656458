import { action$, extractPayload, ofType } from '@ardoq/rxbeach';
import {
  restoreComponentRequested,
  restoreReferenceRequested,
} from './actions';
import { startWith, switchMap, tap } from 'rxjs';
import { api, auditLogApi } from '@ardoq/api';
import { tag } from 'rxjs-spy/operators';
import { trackEvent } from 'tracking/tracking';

export const componentRestore$ = action$.pipe(
  ofType(restoreComponentRequested),
  extractPayload(),
  switchMap(auditLogApi.restoreDeletedComponent),
  tap(response => api.logErrorIfNeeded(response)),
  tap(() => trackEvent('Restored from deletion', { restoreType: 'component' })),
  startWith(''),
  tag('componentRestore$')
);

export const referenceRestore$ = action$.pipe(
  ofType(restoreReferenceRequested),
  extractPayload(),
  switchMap(auditLogApi.restoreDeletedReference),
  tap(response => api.logErrorIfNeeded(response)),
  tap(() => trackEvent('Restored from deletion', { restoreType: 'reference' })),
  startWith(''),
  tag('referenceRestore$')
);
