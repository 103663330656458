import { MetamodelEntityId } from 'organizationMetamodel/metamodel/types';

type ParamReader<Param extends keyof Params> = (value: string) => Params[Param];
type ParamWriter<Param extends keyof Params> = (value: Params[Param]) => string;
type ParamHandlers<Param extends keyof Params> = {
  fromString: ParamReader<Param>;
  toString: ParamWriter<Param>;
};

/**
 * We use declaration merging to add new params,
 * just declare the interface again with your param, and TypeScript
 * will merge the declarations.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface Params {}

const paramHandlers: {
  [Param in keyof Params]?: ParamHandlers<Param>;
} = {};

const registerParam = <Param extends keyof Params>(
  param: Param,
  handler: ParamHandlers<Param>
) => {
  // Must manually type the generics to make this type safe
  // Cannot use `paramHandlers[param] = handler` because of TS2322
  Object.assign<typeof paramHandlers, typeof paramHandlers>(paramHandlers, {
    [param]: handler,
  });
};

export const readParam = <Param extends keyof Params>(
  param: Param,
  value: string
): Params[Param] => {
  const handler = paramHandlers[param];
  if (!handler) {
    throw new Error(`No handler for param ${param}`);
  }
  return handler.fromString(value);
};

export const writeParam = <Param extends keyof Params>(
  param: Param,
  value: Params[Param]
): string => {
  const handler = paramHandlers[param];
  if (!handler) {
    throw new Error(`No handler for param ${param}`);
  }
  return handler.toString(value);
};

// // PARAM REGISTRATION // //

// testParam //
export interface Params {
  /** Used only in tests */
  testParam: string;
}
registerParam('testParam', {
  fromString: value => value,
  toString: value => value,
});

// testNumParam //
export interface Params {
  /** Used only in tests */
  testNumParam: number;
}
registerParam('testNumParam', {
  fromString: value => parseInt(value, 10),
  toString: value => `${value}`,
});

// testBarParam //
export interface Params {
  /** Used only in tests */
  testBarParam: { x: number; y: number; width: number; height: number };
}
registerParam('testBarParam', {
  fromString: value => {
    const [x, y, width, height] = value.split(',').map(x => parseInt(x, 10));
    return { x, y, width, height };
  },
  toString: value =>
    [value.x, value.y, value.width, value.height].map(String).join(','),
});

// workspaceId //
export interface Params {
  workspaceId: string;
}

registerParam('workspaceId', {
  fromString: value => value,
  toString: value => value,
});

// metamodelEntityId //
export interface Params {
  /** Used by Metamodel editor */
  metamodelEntityId: MetamodelEntityId;
}

registerParam('metamodelEntityId', {
  fromString: value => value,
  toString: value => value,
});
