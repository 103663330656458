import { defaultState, reducers } from './reducers';
import { persistentReducedStream } from '@ardoq/rxbeach';
import { UserSettingsRoute } from './types';

const UserSettingsView$ = persistentReducedStream<UserSettingsRoute>(
  'UserSettingsView$',
  defaultState,
  reducers
);

export default UserSettingsView$;
