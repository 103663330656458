import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap } from 'rxjs';
import { resetIntegrationTermsDictionary } from './actions';

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  tap(integrationId => {
    dispatchAction(resetIntegrationTermsDictionary({ integrationId }));
  })
);

export default [handleResetIntegration];
