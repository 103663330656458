import { FormikErrors } from 'formik';
import { api, userApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';

export interface FormValues {
  current: string;
  password: string;
  confirm: string;
}

export function validate({ current, password, confirm }: FormValues) {
  const errors: FormikErrors<FormValues> = {};
  // current
  if (!current) {
    errors.current = 'Required';
  }
  // password
  if (password.length < 12) {
    errors.password = 'Password too short';
  } else if (password.length > 100) {
    errors.password = 'Password too long';
  } else if (!(/\d/.test(password) || /[A-Z]/.test(password))) {
    errors.password =
      'Password must contain at least a number or an uppercase letter';
  }
  // confirm
  if (password !== confirm) {
    errors.confirm = "Passwords don't match";
  }
  return errors;
}

/**
 * On failure, it will return a rejected promise yielding a `FetchError`.
 * See `fetchUtils` for more info.
 */
export function submit(
  userId: string,
  values: FormValues
): Promise<{ isMatch: boolean }> {
  return userApi.changePassword(userId, values).then(result => {
    if (isArdoqError(result)) {
      if (api.isConflict(result)) return { isMatch: false };
      throw result;
    } else {
      return { isMatch: true };
    }
  });
}
