import styled from 'styled-components';
import { ChatMessageItem, ChatMessageItemProps } from './ChatMessageItem';
import { s16, s8 } from '@ardoq/design-tokens';
import CitationArticleLinks from './CitationArticleLinks';
import RelevantLinks from './RelevantLinks';

export const CHAT_MESSAGES_BODY_SCROLLABLE_ELEMENT_ID =
  'best-practice-assistant-chat-messages-body-scrollable';

type ChatMessagesBodyProps = {
  messages: ChatMessageItemProps[];
  onScroll: (scrollTop: number) => void;
};

export const ChatMessagesBody = ({
  messages,
  onScroll,
}: ChatMessagesBodyProps) => {
  return (
    <Wrapper>
      <MessagesWrapper
        id={CHAT_MESSAGES_BODY_SCROLLABLE_ELEMENT_ID}
        onScroll={event => onScroll(event.currentTarget.scrollTop)}
      >
        {messages.map((message, index) => (
          <MessageWrapper key={index}>
            <ChatMessageItem {...message} />
            {message.relevantLinks && (
              <RelevantLinks links={message.relevantLinks} />
            )}
            <CitationArticleLinks citations={message.citations} />
          </MessageWrapper>
        ))}
      </MessagesWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${s16};
  overflow-y: auto;
  width: 100%;
  flex-grow: 1;
  padding: ${s8};
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
