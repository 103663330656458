import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, s16 } from '@ardoq/design-tokens';
import { SecondaryButton, SmallGhostButton } from '@ardoq/button';
import { smartSort } from '@ardoq/pagination';
import {
  DatasourceTable,
  FlyWheelTable,
  HeaderCell,
  newTableTheme,
  useSortableTable,
} from '@ardoq/table';
import { IntegrationSchedule, ScheduleType, SortOrder } from '@ardoq/api-types';
import { formatDateTime, parseDate } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { SourceCell, SourceIconCell, StatusIconCell } from './ScheduleCells';
import {
  getScheduleDirection,
  isIntegrationWithSchedules,
} from 'integrations/common/streams/schedules/utils';
import { useEditableConfig } from 'integrations/common/components/editableCell/useEditableConfig';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { FlexBox } from '@ardoq/layout';
import { Header4 } from '@ardoq/typography';
import { Select, SelectOption } from '@ardoq/select';
import { IntegrationWorkspace } from '@ardoq/api-types/integrations';
import { EditableCell } from 'integrations/common/components/editableCell/EditableCell';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';
import { ScheduleDropdown } from './ScheduleDropdown';
import EmptyTable from './EmptyTable';
import { trackEvent } from 'tracking/tracking';
import { EVENT_NAMES } from 'integrations/common/tracking/events';
import { ErrorIcon } from '@ardoq/icons';

const { SortIcon } = FlyWheelTable;

export type ScheduleCommands = {
  onShowScheduleInformation: (
    integrationId: IntegrationId,
    schedule: IntegrationSchedule
  ) => void;
  onEditSchedule: (integrationId: IntegrationId, id: string) => void;
  onStartEditInterval: (
    integrationId: IntegrationId,
    schedule: IntegrationSchedule
  ) => void;
  onRunSchedule: (integrationId: IntegrationId, id: string) => void;
  onStartCreateConfig: (
    integrationId: IntegrationId,
    schedule: IntegrationSchedule
  ) => void;
  onPauseSchedule: (integrationId: IntegrationId, id: string) => void;
  onStartScheduleRenaming: (integrationId: IntegrationId) => void;
  onRenameSchedule: (
    integrationId: IntegrationId,
    name: string,
    schedule: IntegrationSchedule
  ) => void;
  onStartDeleteSchedule: (
    integrationId: IntegrationId,
    schedule: IntegrationSchedule
  ) => void;
  allowedConfigurations: (integrationId: IntegrationId) => boolean;
  onWorkspaceClick?: ((workspace: IntegrationWorkspace) => void) | null;
  onShowSetAlerts: () => void;
};

type SchedulesTableProps = {
  schedules: IntegrationSchedule[];
  isLoadingSchedules: boolean;
  allConnections: Record<IntegrationId, IntegrationConnectionsState>;
  scheduleCommands: ScheduleCommands;
  alertsEnabled: boolean;
};

const Thead = styled.thead`
  background: ${colors.surfaceHover};
  position: relative;
  border-bottom: 1px solid ${colors.borderSubtle00};
`;

const BodyTr = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${colors.borderAction};

  &:hover {
    background: ${colors.surfaceHover};
  }
`;

const integrationIdMap: Record<
  Exclude<ScheduleType, ScheduleType.CLOUD_IMPORT>,
  IntegrationId
> = {
  [ScheduleType.SERVICENOW_IMPORT]: 'servicenow-v3',
  [ScheduleType.SERVICENOW_EXPORT]: 'servicenow-v3',
  [ScheduleType.MICROSOFT_ENTRA_ID_IMPORT]: 'microsoft-entra-id',
  [ScheduleType.SIGNAVIO_IMPORT]: 'signavio',
  [ScheduleType.SIGNAVIO_EXPORT]: 'signavio-exporter',
  [ScheduleType.CELONIS_IMPORT]: 'celonis',
  [ScheduleType.SYMBIO_IMPORT]: 'symbio',
  [ScheduleType.JIRA_IMPORT]: 'jira-software',
  [ScheduleType.ARDOQ_IMPORT]: 'ardoq',
  [ScheduleType.ARDOQ_REFERENCE_DATA_IMPORT]: 'ardoq-reference-data',
  [ScheduleType.ARDOQ_SAMPLE_DATA_IMPORT]: 'ardoq-sample-data',
  [ScheduleType.SHAREPOINT_FILES_IMPORT]: 'sharepoint-files',
  [ScheduleType.ITPEDIA_ENRICHMENT]: 'it-pedia',
};

export const getSourceName = ({
  type,
  provider,
}: {
  type: string;
  provider?: string;
}) => provider || type.split('-')[0];

const getIntegrationId = (schedule: IntegrationSchedule): IntegrationId =>
  schedule.jobOptions.type === ScheduleType.CLOUD_IMPORT
    ? (`${schedule.jobOptions.provider}-v3` as IntegrationId)
    : integrationIdMap[schedule.jobOptions.type];

const schedulePolicyOptions: SelectOption<string>[] = [
  { label: 'All', value: 'all' },
  {
    label: 'Daily',
    value: 'nightly',
  },
  { label: 'Weekly', value: 'weekly' },
];

const columnsWithSorting = [
  'jobOptions.name',
  'jobOptions.type',
  'meta.lastResult.status',
  'meta.lastCompleted',
];

const TableContainer = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
`;

export const HeaderControlsContainer = styled.div<{
  $showContentOnHoverOnly?: boolean;
}>`
  opacity: ${({ $showContentOnHoverOnly }) =>
    $showContentOnHoverOnly ? 0 : 1};
`;

export const HeaderContainer = styled(FlexBox)`
  cursor: pointer;

  &:hover {
    ${HeaderControlsContainer} {
      opacity: 1;
    }
  }
`;

const StyledSelect = styled(Select)`
  width: 116px;
`;

const Capitalized = styled.div`
  text-transform: capitalize;
`;

const ScheduleTable = styled(DatasourceTable<IntegrationSchedule>)<{
  $isEmpty: boolean;
}>`
  td {
    padding: ${({ $isEmpty }) => ($isEmpty ? '0' : `0 ${s16}`)};
  }
`;

export const SchedulesTable = ({
  schedules,
  isLoadingSchedules,
  allConnections,
  scheduleCommands,
  alertsEnabled,
}: SchedulesTableProps) => {
  const [schedulePolicy, setSchedulePolicy] = useState<string>('all');
  const { editingId, editingName, setEditingId, setEditingName, clear } =
    useEditableConfig();
  const isLoadingConnections = Object.values(allConnections).some(
    integration => integration.statuses.list.status === 'LOADING'
  );

  const {
    sortOrder,
    sortBy: sortById,
    getOnSortByClick,
    getSortableColumnProps,
  } = useSortableTable({
    initialSortBy: 'meta.lastCompleted',
    initialSortOrder: SortOrder.DESC,
  });

  const filteredSchedules =
    schedulePolicy === 'all'
      ? schedules
      : schedules.filter(
          schedule => schedule.schedulingPolicy.type === schedulePolicy
        );

  const sortedDataSource = smartSort(filteredSchedules, sortById, sortOrder);

  const getHeader = ({ columnId }: { columnId: string }) => ({
    headerRender: ({ title }: HeaderCell) => (
      <HeaderContainer
        align="center"
        justify="flex-start"
        gap="small"
        DEPRECATED_onClick={getOnSortByClick(columnId)}
      >
        {title}
        {columnsWithSorting.includes(columnId) && (
          <HeaderControlsContainer
            $showContentOnHoverOnly={columnId !== sortById}
          >
            <SmallGhostButton onClick={getOnSortByClick(columnId)}>
              <SortIcon
                order={columnId === sortById ? sortOrder : SortOrder.ASC}
              />
            </SmallGhostButton>
          </HeaderControlsContainer>
        )}
      </HeaderContainer>
    ),
  });

  return (
    <TableContainer gap="small">
      <FlexBox justify="space-between" align="center">
        <Header4 align="center">All schedules</Header4>
        <FlexBox gap="small">
          {alertsEnabled && (
            <SecondaryButton onClick={scheduleCommands.onShowSetAlerts}>
              <ErrorIcon /> Set alerts
            </SecondaryButton>
          )}
          <StyledSelect
            options={schedulePolicyOptions}
            value={schedulePolicy}
            defaultValue={{
              label: 'Daily',
              value: 'nightly',
            }}
            onValueChange={value => setSchedulePolicy(value as string)}
            isDisabled={!schedules.length}
          />
        </FlexBox>
      </FlexBox>
      <ScheduleTable
        $isEmpty={sortedDataSource.length === 0}
        fixedHeader
        style={{
          border: `1px solid ${colors.borderAction}`,
          backgroundColor: colors.surfaceDefault,
          borderRadius: 8,
        }}
        loading={isLoadingSchedules || isLoadingConnections}
        dataSource={sortedDataSource}
        components={{ ...newTableTheme, Thead, BodyTr }}
        onRowClick={schedule => {
          const integrationId = getIntegrationId(schedule);
          trackEvent(EVENT_NAMES.CLICKED_SCHEDULE, {
            integration: integrationId,
            type: getScheduleDirection(schedule.jobOptions.type),
            lastSyncResult: schedule.meta.lastResult?.status,
          });
          scheduleCommands.onShowScheduleInformation(integrationId, schedule);
        }}
        columns={[
          {
            title: '',
            headerStyle: { width: 24, padding: 0 },
            dataIndex: 'jobOptions',
            valueRender: jobOptions => (
              <SourceIconCell integrationSource={getSourceName(jobOptions)} />
            ),
            ...getHeader({
              columnId: 'jobOptions',
            }),
          },
          {
            title: 'Name',
            headerStyle: { width: 'auto', padding: 0 },
            dataIndex: 'jobOptions.name',
            valueRender: (_, schedule) =>
              schedule._id === editingId ? (
                <EditableCell
                  initValue={schedule.jobOptions.name}
                  onValueChange={setEditingName}
                  onSubmit={() => {
                    if (!editingName) return;
                    scheduleCommands.onRenameSchedule(
                      getIntegrationId(schedule),
                      editingName,
                      schedule
                    );
                  }}
                  onClear={clear}
                />
              ) : (
                <SourceCell name={schedule.jobOptions.name} />
              ),
            ...getHeader({ columnId: 'jobOptions.name' }),
            ...getSortableColumnProps('jobOptions.name'),
          },
          {
            title: 'Type',
            headerStyle: { width: 100, padding: 0 },
            dataIndex: 'jobOptions.type',
            cellStyle: { color: colors.grey15, lineHeight: '20px' },
            valueRender: (_, { jobOptions }) => (
              <Capitalized>{getScheduleDirection(jobOptions.type)}</Capitalized>
            ),
            ...getHeader({
              columnId: 'jobOptions.type',
            }),
            ...getSortableColumnProps('jobOptions.type'),
          },
          {
            title: 'Last sync',
            dataIndex: 'meta.lastCompleted',
            headerStyle: { padding: 0 },
            cellStyle: { color: colors.grey15, lineHeight: '20px' },
            valueRender: value => (
              <span data-tooltip-text={parseDate(value).toString()}>
                {`${formatDateTime(value, getCurrentLocale())}`}
              </span>
            ),
            ...getHeader({
              columnId: 'meta.lastCompleted',
            }),
          },
          {
            title: 'Status',
            dataIndex: 'meta',
            headerStyle: { width: 70, padding: 0 },
            valueRender: (_, { meta }) => (
              <StatusIconCell meta={meta}></StatusIconCell>
            ),
            ...getHeader({
              columnId: 'meta.lastResult.status',
            }),
            ...getSortableColumnProps('meta.lastResult.status'),
          },
          {
            valueRender: (_, schedule) => {
              if (!isIntegrationWithSchedules(getIntegrationId(schedule))) {
                return null;
              }

              return (
                <ScheduleDropdown
                  schedule={schedule}
                  scheduleCommands={scheduleCommands}
                  integrationId={getIntegrationId(schedule)}
                  setEditingId={setEditingId}
                />
              );
            },
          },
        ]}
        renderEmptyTable={() => <EmptyTable />}
      />
    </TableContainer>
  );
};
