export type TrackingLocation =
  | 'assetsManager'
  | 'assetsManagerModal'
  | 'search'
  | 'scopifiedSearch'
  | 'presentationSlide'
  | 'copyWorkspaceDialog'
  | 'metamodel'
  | 'reportReader'
  | 'organizationSettings'
  | 'directURLVisit'
  | 'componentSelection'
  | 'import'
  | 'optionsMenuSidebar'
  | 'pagesViewBreadcrumb'
  | 'contextMenu'
  | 'traversal'
  | 'createInferenceRule'
  | 'surveyBuilder'
  | 'viewpointNavigator'
  | 'componentDrawerTripleToggler';

export enum TrackingPlaceInApp {
  VIEW_PANE_HEADER = 'View pane header0',
  VIEW_SETTINGS_PANEL = 'View settings panel0',
  SAVE_AS_VIEWPOINT_PANEL = 'Save as viewpoint panel0',
  RIGHT_PANE = 'Right pane0',
  RIGHT_PANE_TOOLBAR = 'Right pane toolbar0',
}
