import styled from 'styled-components';
import { ICON_SELECTOR, IconName } from '@ardoq/icons';
import { colors, s16 } from '@ardoq/design-tokens';
import { header4, text2 } from '@ardoq/typography';
import { IconButton } from '@ardoq/button';
import { ClickableIslandHeader } from '@ardoq/snowflakes';
import { IslandContainer } from '@ardoq/page-layout';

const Title = styled.div`
  ${header4}
  display: flex;
  align-items: center;
`;

const Subtitle = styled.div`
  ${text2};
  color: ${colors.grey35};
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

const StyledIconButton = styled(IconButton)`
  ${ICON_SELECTOR} {
    color: ${colors.blue50};
  }
`;

const Flex = styled.div`
  display: flex;
  gap: ${s16};
  align-items: center;
`;

const AddSectionButton = ({
  onClick,
  title,
  subtitle,
  dataTestId,
}: {
  onClick: () => void;
  title: string;
  subtitle: string;
  dataTestId: string;
}) => {
  return (
    <IslandContainer>
      <ClickableIslandHeader onClick={onClick}>
        <Flex>
          <StyledIconButton
            data-tooltip-text="Add section"
            iconName={IconName.ADD}
            onClick={onClick}
            dataTestId={dataTestId}
          />
          <TextSection>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </TextSection>
        </Flex>
      </ClickableIslandHeader>
    </IslandContainer>
  );
};

export default AddSectionButton;
