import { map } from 'rxjs/operators';
import assets$, { AssetsState } from 'streams/assets/assets$';
import { combineFolders } from '../EntityBrowser/utils';
import { derivedStream } from '@ardoq/rxbeach';

const mapProps = (assets: AssetsState) => {
  const dataSource = combineFolders(Object.values(assets.assetFoldersById), [
    Object.values(assets.workspaceAssetsById),
  ]);
  return { dataSource, folderIds: Object.keys(assets.assetFoldersById) };
};

const workspaceAssets$ = derivedStream('workspaceAssets$', assets$).pipe(
  map(([assets]) => mapProps(assets))
);

export default workspaceAssets$;
