import { Header3 } from '@ardoq/typography';
import TemplatePreviewComponent from './TemplatePreviewComponent';
import { RowContainer, TemplatePreviewContainer } from './atoms';
import { CurrentTab, FavoriteableModel } from './types';
import { Box } from '@ardoq/layout';

type TemplateProps = { template: FavoriteableModel; onClose: VoidFunction };
const Template = ({ template, onClose }: TemplateProps) => (
  <TemplatePreviewContainer aria-label={template.name} role="listitem">
    <TemplatePreviewComponent {...template} onClose={onClose} />
  </TemplatePreviewContainer>
);

type TemplateGroupProps = {
  title: string;
  showTitle: boolean;
  favorableModels: FavoriteableModel[];
  onClose: VoidFunction;
};
const TemplateGroup = ({
  title,
  showTitle,
  favorableModels,
  onClose,
}: TemplateGroupProps) => (
  <Box paddingTop="xlarge">
    {showTitle && <Header3>{title}</Header3>}
    <RowContainer role="list" aria-label="Templates">
      {favorableModels.map(template => (
        <Template key={template._id} template={template} onClose={onClose} />
      ))}
    </RowContainer>
  </Box>
);

type TabContentProperties = { currentTab: CurrentTab } & {
  onClose: VoidFunction;
};

const TabContent = ({
  currentTab: { category },
  onClose,
}: TabContentProperties) => {
  const { favorites, customs, commons } = category.templates;

  return (
    <>
      {!!favorites.length && (
        <TemplateGroup
          title="Favorite templates"
          showTitle={Boolean(customs.length || commons.length)}
          favorableModels={favorites}
          onClose={onClose}
        />
      )}

      {!!customs.length && (
        <TemplateGroup
          title="Custom templates"
          showTitle={Boolean(favorites.length || commons.length)}
          favorableModels={customs}
          onClose={onClose}
        />
      )}

      {!!commons.length && (
        <TemplateGroup
          title="Common templates"
          showTitle={Boolean(favorites.length || customs.length)}
          favorableModels={commons}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default TabContent;
