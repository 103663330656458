import { RelevantRootComponent } from './types';
import { Box, FlexBox } from '@ardoq/layout';
import { colors } from '@ardoq/design-tokens';
import { ComponentDecorator } from '@ardoq/decorators';
import { Icon, IconName } from '@ardoq/icons';

export const ComponentColumn = {
  dataIndex: 'component',
  headerRender: () => (
    <FlexBox gap="xsmall" align="center">
      <Icon iconName={IconName.COMPONENT} color={colors.grey50} />
      <span>Component</span>
    </FlexBox>
  ),
  valueRender: (component: RelevantRootComponent) => (
    <Box height="full">
      <ComponentDecorator
        label={component.name}
        labelColor={colors.grey50}
        {...component.iconData}
      />
    </Box>
  ),
};
