import { ArdoqId } from '@ardoq/api-types';
import {
  DecoratedSubdivision,
  ManageMembershipModalState,
  ManageMembershipTableData,
  SelectionChanges,
} from './types';
import { membershipOperations } from './membershipOperations';
import { difference } from 'lodash';
import { decoratedSubdivisionOperations } from './decoratedSubdivisionOperations';
import { manageMembershipTableDataOperations } from './manageMembershipTableDataOperations';

const COMPONENTS_PER_PAGE = 8;

const selectedSubdivisionsChangeReducer = (
  state: ManageMembershipModalState,
  selectedSubdivisions: ArdoqId[]
) => ({
  ...state,
  selectedSubdivisions,
});

const initializeManageSubdivisionMemebershipModalReducer = (
  _: ManageMembershipModalState,
  newState: ManageMembershipModalState
) => newState;

const prepareTableData = ({
  subdivisionsContext,
  components,
  membershipsState,
}: ManageMembershipModalState): ManageMembershipTableData[] => {
  if (subdivisionsContext === null) return [];
  return manageMembershipTableDataOperations.createTableData({
    components,
    membershipsState,
    subdivisionsContext,
  });
};

const changeTablePageReducer = (
  state: ManageMembershipModalState,
  currentPage: number
) => {
  return {
    ...state,
    currentPage,
  };
};

const addSubdivisionToAddSelectionReducer = (
  state: ManageMembershipModalState,
  subdivision: DecoratedSubdivision
): ManageMembershipModalState => ({
  ...state,
  currentAddSelection: [...state.currentAddSelection, subdivision],
});

const removeSubdivisionFromAddSelectionReducer = (
  state: ManageMembershipModalState,
  subdivision: DecoratedSubdivision
): ManageMembershipModalState => ({
  ...state,
  currentAddSelection: state.currentAddSelection.filter(
    ({ _id }) => _id !== subdivision._id
  ),
});

const clearAddSelectionReducer = (
  state: ManageMembershipModalState
): ManageMembershipModalState => ({
  ...state,
  currentAddSelection: [],
});

const applyAddSelectionReducer = (
  state: ManageMembershipModalState
): ManageMembershipModalState => {
  const { currentAddSelection, membershipsState, selectedComponentRows } =
    state;

  const addSelectionSubdivisionIds = currentAddSelection.map(({ _id }) => _id);

  const newMembershipsState = selectedComponentRows.reduce(
    (acc, componentId) =>
      membershipOperations.assignMemberships(acc, {
        componentId,
        subdivisionIds: addSelectionSubdivisionIds,
      }),
    membershipsState
  );

  return {
    ...state,
    membershipsState: newMembershipsState,
    currentAddSelection: [],
  };
};

const applySelectionChangesReducer = (
  state: ManageMembershipModalState,
  { previousSelection, currentSelection, componentId }: SelectionChanges
): ManageMembershipModalState => {
  const toAssign = difference(currentSelection, previousSelection);
  const toRevoke = difference(previousSelection, currentSelection);
  const partiallyUpdatedMembershipsState =
    membershipOperations.assignMemberships(state.membershipsState, {
      componentId,
      subdivisionIds: toAssign,
    });
  const updatedMembershipsState = membershipOperations.revokeMemberships(
    partiallyUpdatedMembershipsState,
    {
      componentId,
      subdivisionIds: toRevoke,
    }
  );
  return {
    ...state,
    membershipsState: updatedMembershipsState,
  };
};

const addSubdivisionToRemoveSelectionReducer = (
  state: ManageMembershipModalState,
  subdivision: DecoratedSubdivision
): ManageMembershipModalState => ({
  ...state,
  currentRemoveSelection: [...state.currentRemoveSelection, subdivision],
});

const removeSubdivisionFromRemoveSelectionReducer = (
  state: ManageMembershipModalState,
  subdivision: DecoratedSubdivision
): ManageMembershipModalState => ({
  ...state,
  currentRemoveSelection: state.currentRemoveSelection.filter(
    ({ _id }) => _id !== subdivision._id
  ),
});

const clearRemoveSelectionReducer = (
  state: ManageMembershipModalState
): ManageMembershipModalState => ({
  ...state,
  currentRemoveSelection: [],
});

const applyRemoveSelectionReducer = (
  state: ManageMembershipModalState
): ManageMembershipModalState => {
  const { currentRemoveSelection, membershipsState, selectedComponentRows } =
    state;

  const addSelectionSubdivisionIds = currentRemoveSelection.map(
    ({ _id }) => _id
  );

  const newMembershipsState = selectedComponentRows.reduce(
    (acc, componentId) =>
      membershipOperations.revokeMemberships(acc, {
        componentId,
        subdivisionIds: addSelectionSubdivisionIds,
      }),
    membershipsState
  );

  return {
    ...state,
    membershipsState: newMembershipsState,
    currentRemoveSelection: [],
  };
};

const getEmptyState = (): ManageMembershipModalState => ({
  subdivisions: [],
  components: [],
  membershipsState: membershipOperations.getEmptyState(),
  subdivisionsContext: null,
  currentPage: 1,
  COMPONENTS_PER_PAGE,
  currentAddSelection: [],
  currentRemoveSelection: [],
  isBulkChange: false,
  selectedComponentRows: [],
});

const setSelectComponentRowsReducer = (
  state: ManageMembershipModalState,
  selectedComponentRows: ArdoqId[]
): ManageMembershipModalState => {
  const subdivisions = decoratedSubdivisionOperations.updateComponentsCount(
    state.subdivisions,
    state.components.filter(({ _id }) => selectedComponentRows.includes(_id))
  );
  return {
    ...state,
    subdivisions,
    selectedComponentRows,
  };
};

const getActiveDivisions = (
  state: ManageMembershipModalState
): DecoratedSubdivision[] =>
  state.subdivisions.filter(({ isDisabled }) => !isDisabled);

export const manageMembershipModalOperations = {
  selectedSubdivisionsChangeReducer,
  initializeManageSubdivisionMemebershipModalReducer,
  addSubdivisionToAddSelectionReducer,
  removeSubdivisionFromAddSelectionReducer,
  clearAddSelectionReducer,
  applyAddSelectionReducer,
  applySelectionChangesReducer,
  addSubdivisionToRemoveSelectionReducer,
  removeSubdivisionFromRemoveSelectionReducer,
  clearRemoveSelectionReducer,
  applyRemoveSelectionReducer,
  prepareTableData,
  changeTablePageReducer,
  getEmptyState,
  setSelectComponentRowsReducer,
  getActiveDivisions,
};
