import { actionCreator } from '@ardoq/rxbeach';
import { TableId } from 'integrations/common/streams/tabularMappings/types';
import {
  MicrosoftEntraIdConfig,
  SelectionResponse,
} from '@ardoq/api-types/integrations';
import { AsyncStatus } from 'integrations/common/types/api';
import { SelectionState } from './types';

const NAMESPACE = '[integrations microsoft-entra-id] SELECTION';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const resetSelection = actionCreator(getActionName('RESET_SELECTION'));

// tables

export const applyTables = actionCreator<SelectionState['tables']>(
  getActionName('APPLY_TABLES')
);

export const selectTable = actionCreator<TableId>(
  getActionName('SELECT_TABLE')
);

export const unselectTable = actionCreator<TableId>(
  getActionName('UNSELECT_TABLE')
);

export const unselectTables = actionCreator(getActionName('UNSELECT_TABLES'));

// fields

export type SelectFieldPayload = { tableId: TableId; id: string };

export const selectField = actionCreator<SelectFieldPayload>(
  getActionName('SELECT_FIELD')
);

export const unselectField = actionCreator<SelectFieldPayload>(
  getActionName('UNSELECT_FIELD')
);

export type SelectFieldsPayload = {
  tableId: TableId;
  ids: string[];
};

export const selectFields = actionCreator<SelectFieldsPayload>(
  getActionName('SELECT_ALL_TABLE_FIELDS')
);

export const unselectFields = actionCreator<SelectFieldsPayload>(
  getActionName('UNSELECT_FIELDS')
);

// applying selection
type ApplySelectionPayload = {
  sourceConfig: MicrosoftEntraIdConfig;
  importPictures: boolean;
};

export const applyConfig = actionCreator<ApplySelectionPayload>(
  getActionName('APPLY_SOURCE_CONFIG')
);

// fetching

export const fetchSelectedTablesFields = actionCreator(
  getActionName('FETCH_SELECTED_TABLES_FIELDS')
);

export const setSelectionAsyncStatus = actionCreator<AsyncStatus>(
  getActionName('SET_SELECTION_ASYNC_STATUS')
);

export const setFetchErrorMessage = actionCreator<string | null>(
  getActionName('SET_FETCH_ERROR_MESSAGE')
);

export const fetchSelectionSuccess = actionCreator<SelectionResponse>(
  getActionName('FETCH_SELECTION_SUCCESS')
);

export const setImportProfilesPhotos = actionCreator<boolean>(
  getActionName('SET_IMPORT_PROFILE_PHOTOS')
);

export const selectRequiredFields = actionCreator(
  getActionName('SELECT_REQUIRED_FIELDS')
);

export const setHasRequestedPhotos = actionCreator<boolean>(
  getActionName('SET_HAS_REQUESTED_PHOTOS')
);
