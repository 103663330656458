import {
  ArdoqId,
  BindingComponentsToSubdivisionStagedChanges,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import type { AdvancedSearchOnChangePayload } from 'search/AdvancedSearch/AdvancedSearchQueryBuilder';
import type { SubdivisionViewModelState } from 'subdivisionEditor/types';

/* --------------------------------------------------------------------------------------------------
 * -------------------------           Advanced search actions           ----------------------------
 * ----------------------------------------------------------------------------------------------- */

const NAME_SPACE = '[AddDataByAdvancedSearch]';

export const advancedSearchChange =
  actionCreator<AdvancedSearchOnChangePayload>(
    `${NAME_SPACE} ADVANCED_SEARCH_ONCHANGE`
  );

export const addComponentsToSubdivisionAction =
  actionCreator<SubdivisionViewModelState>(
    `${NAME_SPACE} ADD_COMPONENTS_TO_SUBDIVISION_ACTION`
  );

export const addComponentsToSubdivisionSuccess = actionCreator(
  `${NAME_SPACE} ADD_COMPONENTS_TO_SUBDIVISION_SUCCESS`
);

export const addComponentsToSubdivisionFailure = actionCreator(
  `${NAME_SPACE} ADD_COMPONENTS_TO_SUBDIVISION_FAILURE`
);

/* --------------------------------------------------------------------------------------------------
 * ----------------------------         Staged Components Actions        ----------------------------
 * ----------------------------------------------------------------------------------------------- */

export const fetchStagedComponents = actionCreator<SubdivisionViewModelState>(
  `${NAME_SPACE} FETCH_COMPONENTS_TO_ADD`
);

export const stagedComponentsFetched =
  actionCreator<BindingComponentsToSubdivisionStagedChanges>(
    `${NAME_SPACE} COMPONENTS_TO_ADD_FETCHED`
  );
export const stagedComponentsFailedToFetch = actionCreator(
  `${NAME_SPACE} COMPONENTS_TO_ADD_FAILED_TO_FETCH`
);

export const setSelectedComponentsToAdd = actionCreator<{
  selectedIds: ArdoqId[];
}>(`${NAME_SPACE} SET_SELECTED_COMPONENTS_TO_ADD`);

export const setStagedComponentToBeRemoved = actionCreator<{
  componentIds: ArdoqId[];
}>(`${NAME_SPACE} SET_STAGED_COMPONENT_TO_BE_REMOVED`);
