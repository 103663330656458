import { reducer } from '@ardoq/rxbeach';
import { collapsePathsOperations } from './collapsePathsOperations';
import {
  cancelEditPathCollapsingRule,
  clearCollapsedPathNodes,
  collapsingRuleIsActiveToggled,
  collapsingRuleToggled,
  createPathCollapsingRule,
  deletePathCollapsingRule,
  displayTextUpdated,
  editPathCollapsingRule,
  graphNodeHovered,
  pathCollapsingNodeSelected,
  savePathCollapsingRule,
  setPathCollapsingRules,
} from './collapsePathsActions';
import { collapsedPathTabUnselected } from 'viewpointBuilder/viewpointBuilderNavigation/actions';

// these are included – and only used – in editTraversals$
export const collapsePathsReducers = [
  reducer(
    setPathCollapsingRules,
    collapsePathsOperations.setPathCollapsingRules
  ),
  reducer(
    createPathCollapsingRule,
    collapsePathsOperations.createPathCollapsingRule
  ),
  reducer(
    savePathCollapsingRule,
    collapsePathsOperations.savePathCollapsingRule
  ),
  reducer(
    deletePathCollapsingRule,
    collapsePathsOperations.deletePathCollapsingRule
  ),
  reducer(
    editPathCollapsingRule,
    collapsePathsOperations.editPathCollapsingRule
  ),
  reducer(
    cancelEditPathCollapsingRule,
    collapsePathsOperations.cancelEditPathCollapsingRule
  ),
  reducer(displayTextUpdated, collapsePathsOperations.setDisplayName),
  reducer(pathCollapsingNodeSelected, collapsePathsOperations.nodeSelected),
  reducer(
    clearCollapsedPathNodes,
    collapsePathsOperations.clearCollapsedPathNodes
  ),
  reducer(graphNodeHovered, collapsePathsOperations.onGraphNodeHover),
  reducer(collapsingRuleToggled, collapsePathsOperations.collapsingRuleToggled),
  reducer(
    collapsedPathTabUnselected,
    collapsePathsOperations.onCollapsedPathTabUnselected
  ),
  reducer(
    collapsingRuleIsActiveToggled,
    collapsePathsOperations.toggleCollapsingRuleIsActive
  ),
];
