import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { saveResponse, setUploadInfo, uploadFileRequest } from './actions';
import { from, switchMap, tap, forkJoin, of } from 'rxjs';
import {
  setIntegrationName,
  setMapTablesBy,
  setSourceId,
} from 'integrations/common/streams/activeIntegrations/actions';
import {
  resetTablesRows,
  setTablePreviews,
} from 'integrations/common/streams/tablePreviews/actions';
import {
  importRun,
  resetTransfer,
} from 'integrations/common/streams/transferState/actions';
import { setEmbeddedConfig } from 'integrations/common/streams/transferConfigs/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { navigateToConfigure } from 'integrations/common/navigation/actions';
import { integrationInitialStates } from 'integrations/common/initialState';
import { api, ApiResponse, tabularApi, toApiResponse } from '@ardoq/api';
import { HTTPStatusCode } from '@ardoq/api-types';
import { isArdoqError } from '@ardoq/common-helpers';
import { UploadResponse } from '@ardoq/api-types/integrations';
import { getFilteredSheetTables } from '../../utils';

const VALID_EXTENSIONS = ['xls', 'xlsx', 'xlsm', 'csv'];

const uploadFile = (file: File): ApiResponse<UploadResponse> => {
  const extension = file.name.toLowerCase().split('.').pop();

  if (!VALID_EXTENSIONS.some(val => val === extension)) {
    return toApiResponse(
      new Response('InvalidFile', {
        status: HTTPStatusCode.UNSUPPORTED_MEDIA_TYPE,
      })
    );
  }

  const formData = new FormData();
  formData.append('file', file);

  return tabularApi.uploadFile(formData);
};

const handleUploadFile = routine(
  ofType(uploadFileRequest),
  extractPayload(),
  tap(({ file }) => {
    dispatchAction(
      setUploadInfo({
        status: 'Processing',
        title: 'Uploading...',
        message: file.name,
      })
    );
  }),
  switchMap(({ isReuploading, file }) => {
    return forkJoin({
      isReuploading: of(isReuploading),
      response: from(uploadFile(file)),
    });
  }),
  tap(({ isReuploading, response }) => {
    if (isArdoqError(response)) {
      dispatchAction(
        trackIntegrationEvent({
          integrationId: 'excel-v3',
          name: 'UPLOADED_FILE_FAILURE',
        })
      );

      if (api.isBadRequest(response) || api.isUnsupportedMediaType(response)) {
        dispatchAction(
          setUploadInfo({
            status: 'InvalidFile',
            title: "The file you've uploaded is invalid.",
            message: 'We support .xls, .xlsx, .xlsm, and .csv formats',
            uploadButtonText: 'Upload another',
            secondaryButtonText: 'Show Details',
            errorMessage: 'Unsupported media type.',
          })
        );
      } else {
        dispatchAction(
          setUploadInfo({
            status: 'Error',
            title: 'Something went wrong...',
            message: 'Try uploading again or view the details.',
            uploadButtonText: 'Upload another',
            secondaryButtonText: 'Show Details',
            errorMessage: 'There was a problem reading the spreadsheet',
          })
        );
      }
      return;
    }

    // We need to hide !Read First! sheet from Quick start - https://ardoqcom.atlassian.net/browse/ARD-24570
    const tables = getFilteredSheetTables(response.tables);

    if (tables.length === 0) {
      return dispatchAction(
        setUploadInfo({
          status: 'InvalidFile',
          title: "The file you've uploaded doesn't have valid data sheets.",
          message: 'Please upload a file with valid sheets.',
          uploadButtonText: 'Upload another',
          secondaryButtonText: 'Show Details',
          errorMessage:
            'No usable sheets available after applying name filter.',
        })
      );
    }

    dispatchAction(saveResponse(response));
    dispatchAction(setSourceId({ integrationId: 'excel-v3', id: response.id }));

    dispatchAction(
      setUploadInfo({
        status: 'Success',
        title: 'Upload successful',
        message: 'Drop another, or pick another file.',
        uploadButtonText: 'Replace file',
      })
    );

    dispatchAction(resetTransfer({ integrationId: 'excel-v3' }));
    // if the current mapping was set to name, we need to change it to index because the user might have
    // embedded config previously and now he doesn't want to do it.
    dispatchAction(
      setMapTablesBy({
        integrationId: 'excel-v3',
        mapTablesBy:
          integrationInitialStates['excel-v3'].activeIntegration
            .integrationMappingParams.mapTablesBy,
      })
    );
    if (isReuploading) {
      dispatchAction(
        trackIntegrationEvent({
          integrationId: 'excel-v3',
          name: 'REUPLOADED_FILE',
        })
      );
      dispatchAction(resetTablesRows({ integrationId: 'excel-v3' }));
      dispatchAction(
        importRun({ integrationId: 'excel-v3', dryRun: true, async: false })
      );
      return; // reuploading ends here
    }

    dispatchAction(
      trackIntegrationEvent({
        integrationId: 'excel-v3',
        name: 'UPLOADED_FILE_SUCCESS',
      })
    );

    dispatchAction(
      setTablePreviews({
        integrationId: 'excel-v3',
        tablePreviews: tables,
      })
    );

    if (response.embeddedConfig) {
      dispatchAction(
        setEmbeddedConfig({
          integrationId: 'excel-v3',
          config: response.embeddedConfig,
        })
      );
    }

    dispatchAction(
      navigateToConfigure({ integrationId: 'excel-v3', tableId: null })
    );
  })
);

const deriveIntegrationName = routine(
  ofType(saveResponse),
  extractPayload(),
  tap(response => {
    dispatchAction(
      setIntegrationName({
        integrationId: 'excel-v3',
        name: response.sourceName,
      })
    );
  })
);

export default [handleUploadFile, deriveIntegrationName];
