import { EditModeDashboardAttributes } from '@ardoq/dashboard';
import { ArdoqId, DashboardSettings } from '@ardoq/api-types';

export const convertEditModeColorsToAPIColors = (
  colors: EditModeDashboardAttributes['settings']['colors']
): DashboardSettings['colors'] =>
  colors
    .filter(({ isManuallyAssigned }) => isManuallyAssigned)
    .map(({ dataPoint, color }) => ({ dataPoint, color }));

export const getColorsForWidget = (
  colors: EditModeDashboardAttributes['settings']['colors'],
  widgetId: ArdoqId
) =>
  colors.filter(({ widgetsUsingThis }) => widgetsUsingThis.includes(widgetId));
