import { FormSize, SearchInput, Switch } from '@ardoq/forms';
import styled from 'styled-components';
import { FlexBox } from '@ardoq/layout';

type TraversalFiltersProps = {
  searchPhrase: string;
  searchLabel?: string;
  searchPlaceholder?: string;
  setSearchPhrase: (searchPhrase: string) => void;
  shouldDisplayOnlyTraversalsOfCurrentUser: boolean;
  toggleShouldDisplayOnlyTraversalsOfCurrentUser: () => void;
};

const SearchWrapper = styled.div`
  flex-grow: 1;
`;

const TraversalFilters = ({
  searchLabel,
  searchPlaceholder,
  searchPhrase,
  setSearchPhrase,
  shouldDisplayOnlyTraversalsOfCurrentUser,
  toggleShouldDisplayOnlyTraversalsOfCurrentUser,
}: TraversalFiltersProps) => {
  return (
    <FlexBox align="flex-end" gap="xlarge">
      <SearchWrapper>
        <SearchInput
          autoFocus
          formSize={FormSize.FULL}
          label={searchLabel}
          value={searchPhrase}
          dataTestId="searchSavedTraversalsInput"
          placeholder={searchPlaceholder ?? 'Search saved viewpoints'}
          onValueChange={setSearchPhrase}
          autoComplete="off"
        />
      </SearchWrapper>
      <FlexBox paddingBottom="xsmall">
        <Switch
          name="showOnlyTraversalsCreatedByMe"
          label="Show only viewpoints created by me"
          isChecked={shouldDisplayOnlyTraversalsOfCurrentUser}
          onChange={toggleShouldDisplayOnlyTraversalsOfCurrentUser}
          dataTestId="showOnlyTraversalsCreatedByMeCheckbox"
        />
      </FlexBox>
    </FlexBox>
  );
};

export default TraversalFilters;
