import { colors } from '@ardoq/design-tokens';
import { SvgImage, SvgImageName } from '@ardoq/icons';
import { Space } from '@ardoq/style-helpers';

const BestPracticeAssistant = ({ logoSize }: { logoSize: string }) => (
  <Space $align="center" $gap="s4">
    <SvgImage
      svgImageName={SvgImageName.INVERTED_ARDOQ_LOGO}
      style={{ color: colors.brand50, width: logoSize, height: logoSize }}
    />{' '}
    {'Best practice assistant'}
  </Space>
);

export default BestPracticeAssistant;
