import { actionCreator } from '@ardoq/rxbeach';
import { MetamodelPane } from './types';
import { ArdoqId } from '@ardoq/api-types';
import {
  MetamodelContextSettingMap,
  MetamodelEntityContext,
  MetamodelTrackingLocation,
  SelectedMetamodelItem,
} from 'metamodel/types';

export const selectMetamodelPane = actionCreator<{
  pane: MetamodelPane;
  trackingLocation?: MetamodelTrackingLocation;
}>('[navigation] SELECT_METAMODEL_PANE');

export const selectMetamodel = actionCreator<ArdoqId | undefined>(
  '[navigation] SELECT_METAMODEL'
);

export const selectMetamodelItem = actionCreator<SelectedMetamodelItem>(
  '[navigation] SELECT_METAMODEL_ITEM'
);

export const setMetamodelContext = actionCreator<MetamodelEntityContext>(
  '[navigation] SET_METAMODEL_CONTEXT'
);

export const setMetamodelContextSettings =
  actionCreator<MetamodelContextSettingMap>(
    '[navigation] SET_METAMODEL_CONTEXT_SETTINGS'
  );

export const setMetamodelInfoContainerIsOpen = actionCreator<boolean>(
  '[navigation] SET_METAMODEL_INFO_CONTAINER_IS_OPEN'
);
