import { SourceType } from '@ardoq/api-types/integrations';
import { ProviderId, UnifiedIntegrationId } from './types';
import { ScheduleType, UnifiedScheduleType } from '@ardoq/api-types';
import { unifiedIntegrationProviders } from '@ardoq/api-types/integrations';

// The backend provider ID for each unified integration is the same as the integration ID on the frontend
export const UNIFIED_INTEGRATION_IDS = unifiedIntegrationProviders;

export const UNIFIED_SCHEDULE_TYPES: ScheduleType[] = [
  ScheduleType.ARDOQ_IMPORT,
  ScheduleType.ARDOQ_REFERENCE_DATA_IMPORT,
  ScheduleType.ARDOQ_SAMPLE_DATA_IMPORT,
  ScheduleType.SHAREPOINT_FILES_IMPORT,
  ScheduleType.JIRA_IMPORT,
  ScheduleType.SIGNAVIO_IMPORT,
  ScheduleType.CELONIS_IMPORT,
  ScheduleType.SYMBIO_IMPORT,
];

export const UNIFIED_INTEGRATION_IDS_WITH_PROGRESS: UnifiedIntegrationId[] = [
  'jira-software',
  'signavio',
  'symbio',
];

export const INTEGRATION_ID_TO_PROVIDER_ID: Record<
  UnifiedIntegrationId,
  ProviderId
> = UNIFIED_INTEGRATION_IDS.reduce<Record<UnifiedIntegrationId, ProviderId>>(
  (obj, current) => {
    obj[current] = current;
    return obj;
  },
  {} as Record<UnifiedIntegrationId, ProviderId>
);

export const INTEGRATION_ID_TO_SOURCE_TYPES = UNIFIED_INTEGRATION_IDS.reduce<
  Record<UnifiedIntegrationId, SourceType[]>
>(
  (obj, current) => {
    obj[current] = [current];
    return obj;
  },
  {} as Record<UnifiedIntegrationId, SourceType[]>
);

export const INTEGRATION_ID_TO_SCHEDULE_TYPE: Record<
  UnifiedIntegrationId,
  UnifiedScheduleType
> = {
  ardoq: ScheduleType.ARDOQ_IMPORT,
  'ardoq-reference-data': ScheduleType.ARDOQ_REFERENCE_DATA_IMPORT,
  'ardoq-sample-data': ScheduleType.ARDOQ_SAMPLE_DATA_IMPORT,
  'sharepoint-files': ScheduleType.SHAREPOINT_FILES_IMPORT,
  'jira-software': ScheduleType.JIRA_IMPORT,
  signavio: ScheduleType.SIGNAVIO_IMPORT,
  celonis: ScheduleType.CELONIS_IMPORT,
  symbio: ScheduleType.SYMBIO_IMPORT,
};
