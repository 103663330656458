import { Color } from '@ardoq/design-tokens';
import { Feature } from '@ardoq/features';
import { IconName } from '@ardoq/icons';
import { OverviewRoute } from './common/navigation/types';
import { ImportHistory } from '@ardoq/api-types/integrations';
import { OrgAccessLevel } from '@ardoq/api-types';

export interface IntegrationViewState {
  selectedIntegrationId: string | null;
  integrationPath: string | null;
  isLoadingImports: boolean;
  imports: ImportHistory[];
}

// Add your own Jobs type here like following
// export type IntegrationJob = EnrichmentJob | ServiceNowSchedule | ExcelJob;

export interface Integration {
  id: string;
  name: string;
  categories: Category[];
  logoUrl?: string;
  logoIcon?: IconName;
  logoIconColor?: Color | string;
  description?: string;
  isDeprecated?: boolean;
  /** If the current user does not have one of these features, the integration will not be visible  */
  requiredFeatures?: Feature[];

  /** If the current user has one of these features, the integration will be visible but not clickable */
  disabledFeatures?: Feature[];
  // Some integrations require specific level access
  requiredOrgAccessLevel: OrgAccessLevel;
  // Sometimes an integration should be enabled unless explicitly disabled by toggle
  isEnabledWhenFeaturesUnset?: boolean;
  isBeta?: boolean;
  disableOptions?: {
    isDisabled: boolean;
    reason?: {
      icon: IconName;
      hint: string;
    };
  };
  isNew?: boolean;
  helpUrl?: string;
  supportMessage?: string;
  dialogName?: string;
  importerPath?: string;
  additionalEntryPath?: string[];
  typescriptImporter?: {
    component: (props?: any) => React.ReactElement | null;
    config?: IntegrationConfig;
  };
  group?: Group;
}

export enum Category {
  ACTION = 'action', // A pseudo-integration that performs a UI action
  API = 'api', // Relates to the Ardoq API
  DOCUMENTATION = 'documentation', // Just some words and pictures
  FILE = 'file', // Uses files uploaded by the user
  IMPORTER = 'importer', // Provides an interactive UI
  EXPORTER = 'exporter', // Provides an interactive UI
  LIBRARY = 'library', // Relates to a programming library (eg. Python, .Net)
  SERVICE = 'service', // Relates to a third-party data source/sink
}

export enum Group {
  INTEGRATIONS = 'integrations',
  GUIDES = 'guides',
}

interface IntegrationConfig {
  allowedOverviewRoutes: OverviewRoute[];
}

export enum RootPages {
  DASHBOARD = '/integrations',
  HISTORY = '/integrations/history',
}
