import {
  AncestorItemType,
  DataSourceItem,
  MergeStep,
  MissingComponentConflict,
  StructuralConflict,
} from 'components/DiffMergeTable/types';
import {
  collectRelatedReferencesAndComponents,
  getComponentIdsAffectedByFieldDeletion,
  getEntitiesAffectedByTagDeletion,
  getReferenceIdsAffectedByFieldDeletion,
  resolveConflictAncestors,
} from 'components/DiffMergeTable/utils';
import { getEntityById, getModelTypeDictionaryKey } from '@ardoq/renderers';
import { pluralize } from '@ardoq/common-helpers';
import styled from 'styled-components';
import * as fonts from 'app/designTokens/fonts';
import { Branch } from 'components/DiffMergeTable/Branch';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import {
  getComponentIdsAffectedByComponentTypeDeletion,
  getReferencesByReferenceTypeOnTarget,
} from 'components/DiffMergeTable/typeCollectionsUtil';
import { EnhancedDiffScopeData } from './enhanceDiffContextData';

const HintMessageWrapper = styled.span`
  ${fonts.pageViewMediumTemp};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 16px;
`;

const getCountComponentsToBeCreated = ({
  componentId,
  enhancedDiffContextData,
}: {
  componentId: ArdoqId;
  enhancedDiffContextData: EnhancedDiffScopeData;
}) => {
  const { targetConflictAncestors } = resolveConflictAncestors({
    componentId,
    enhancedDiffContextData,
  });
  return Object.values(targetConflictAncestors).filter(
    ({ ancestorItemType }) =>
      ancestorItemType === AncestorItemType.DELETED_COMPONENT
  ).length;
};

const getStructuralConflictMessage = ({
  dataSourceRow: {
    entityId,
    entityType,
    structuralConflict,
    enhancedDiffContextData,
  },
}: {
  dataSourceRow: DataSourceItem;
}) => {
  if (
    structuralConflict ===
    StructuralConflict.RIGID_MODEL_PARENT_TYPE_HAS_CHANGED
  ) {
    const component = entityType === APIEntityType.COMPONENT ? 'Component' : '';
    const reference = entityType === APIEntityType.REFERENCE ? 'Reference' : '';
    return `${component || reference} cannot be created`;
  }
  if (structuralConflict === StructuralConflict.MISSING_ANCESTOR) {
    const countComponentsToBeCreated = getCountComponentsToBeCreated({
      componentId: entityId,
      enhancedDiffContextData,
    });
    return `${countComponentsToBeCreated} ${pluralize(
      'component',
      countComponentsToBeCreated
    )} will be created`;
  }
};

const HintMessage = ({
  mergeStep,
  dataSourceRow,
  shouldShowPropertiesString,
}: {
  mergeStep: MergeStep;
  dataSourceRow: DataSourceItem;
  shouldShowPropertiesString: boolean;
}) => {
  if (dataSourceRow.hasTypeConflict) {
    const message =
      dataSourceRow.entityType === APIEntityType.COMPONENT
        ? 'Missing component type'
        : 'Missing reference type';
    return <HintMessageWrapper>{message}</HintMessageWrapper>;
  }
  if (dataSourceRow.structuralConflict !== StructuralConflict.NONE) {
    return (
      <HintMessageWrapper>
        {getStructuralConflictMessage({ dataSourceRow })}
      </HintMessageWrapper>
    );
  }
  if (shouldShowPropertiesString) {
    return <HintMessageWrapper>Properties</HintMessageWrapper>;
  }
  if (dataSourceRow.missingComponentConflict) {
    const message = `Missing ${
      dataSourceRow.missingComponentConflict ===
        MissingComponentConflict.SOURCE ||
      dataSourceRow.missingComponentConflict === MissingComponentConflict.BOTH
        ? 'source'
        : ''
    }${
      dataSourceRow.missingComponentConflict === MissingComponentConflict.BOTH
        ? ' and '
        : ''
    }${
      dataSourceRow.missingComponentConflict ===
        MissingComponentConflict.TARGET ||
      dataSourceRow.missingComponentConflict === MissingComponentConflict.BOTH
        ? 'target'
        : ''
    }`;
    return <HintMessageWrapper>{message}</HintMessageWrapper>;
  }

  if (mergeStep === MergeStep.DELETE_COMPONENTS) {
    const { referenceIds, componentIds } =
      collectRelatedReferencesAndComponents(
        dataSourceRow.entityId,
        dataSourceRow.enhancedDiffContextData[Branch.TARGET]
      );
    const referencesCount = referenceIds.length;
    const descendantComponentCount = componentIds.length;

    return (
      <HintMessageWrapper>
        {referencesCount > 0
          ? `${referencesCount} ${pluralize('reference', referencesCount)} `
          : ''}
        {referencesCount > 0 && descendantComponentCount > 0 ? 'and ' : ''}
        {descendantComponentCount > 0
          ? `${descendantComponentCount} ${pluralize(
              'component',
              descendantComponentCount
            )}`
          : ''}{' '}
        will be deleted
      </HintMessageWrapper>
    );
  } else if (mergeStep === MergeStep.DELETE_FIELDS) {
    const field = getEntityById(
      APIEntityType.FIELD,
      dataSourceRow.entityId,
      dataSourceRow.enhancedDiffContextData[Branch.TARGET]
    );

    const componentCount =
      field &&
      getComponentIdsAffectedByFieldDeletion(
        field,
        dataSourceRow.enhancedDiffContextData[Branch.TARGET]
      ).length;
    const referenceCount =
      field &&
      getReferenceIdsAffectedByFieldDeletion(
        field,
        dataSourceRow.enhancedDiffContextData[Branch.TARGET]
      ).length;
    const componentString = componentCount
      ? `${componentCount} ${pluralize('component', componentCount)}`
      : '';
    const referenceString = referenceCount
      ? `${referenceCount} ${pluralize('reference', referenceCount)}`
      : '';
    return (
      <HintMessageWrapper>
        {componentString} {componentString && referenceString && ' and '}
        {referenceString} affected
      </HintMessageWrapper>
    );
  } else if (mergeStep === MergeStep.DELETE_COMPONENT_TYPES) {
    const componentCount = getComponentIdsAffectedByComponentTypeDeletion(
      dataSourceRow.enhancedDiffContextData,
      getModelTypeDictionaryKey(
        dataSourceRow.parentEntityId!,
        dataSourceRow.entityId
      )
    ).length;
    return (
      <HintMessageWrapper>
        {componentCount} {pluralize('component', componentCount)} uses this type
      </HintMessageWrapper>
    );
  } else if (mergeStep === MergeStep.DELETE_REFERENCE_TYPES) {
    const referenceCount = getReferencesByReferenceTypeOnTarget(
      dataSourceRow.enhancedDiffContextData,
      getModelTypeDictionaryKey(
        dataSourceRow.parentEntityId!,
        dataSourceRow.entityId
      )
    ).length;
    return (
      <HintMessageWrapper>
        {referenceCount} {pluralize('reference', referenceCount)} uses this type
      </HintMessageWrapper>
    );
  } else if (mergeStep === MergeStep.DELETE_TAGS) {
    const { referenceIds, componentIds } = getEntitiesAffectedByTagDeletion(
      dataSourceRow.entityId,
      dataSourceRow.enhancedDiffContextData[Branch.TARGET]
    );
    const componentCount = componentIds.length;
    const referenceCount = referenceIds.length;

    const componentString = componentCount
      ? `${componentCount} ${pluralize('component', componentCount)}`
      : '';
    const referenceString = referenceCount
      ? `${referenceCount} ${pluralize('reference', referenceCount)}`
      : '';

    return (
      <HintMessageWrapper>
        {componentString} {componentString && referenceString && ' and '}
        {referenceString} untagged
      </HintMessageWrapper>
    );
  }
  return null;
};

export default HintMessage;
