import styled from 'styled-components';
import { ComponentsSearchCommands } from './commands';
import { ComponentSearchEmptyState } from './emptyStates';
import {
  ArdoqLoaderComponent,
  LoaderColor,
} from '@ardoq/ardoq-loader-component';
import { Wrapper as SplashScreen } from 'components/ArdoqLoader/SplashScreen';
import {
  LoadedComponentsState,
  ComponentSearchViewState,
  SearchCriteriaState,
  SelectedComponentsState,
  ViewpointBuilderAdvancedSearchState,
} from './types';
import { AdvancedSearchEmptyStates } from './SelectContextTabAdvancedSearchEmptyStates';
import SelectableComponentList from './SelectableComponentList';
import SearchResultCountAndSelectAll from './SearchResultCountAndSelectAll';

type ComponentSearchProps = {
  commands: ComponentsSearchCommands;
  state: ComponentSearchViewState;
};

const ComponentSearchResults = ({ commands, state }: ComponentSearchProps) => {
  const showResults =
    state.loadedComponents.length > 0 &&
    (state.advancedSearchState === null ||
      state.advancedSearchState.status !== 'invalid');

  return (
    <ResultWrapper>
      {state.isLoading && (
        <SplashScreen>
          <ArdoqLoaderComponent loaderColor={LoaderColor.BRAND} size={50} />
        </SplashScreen>
      )}
      {showResults ? (
        <SearchResult {...state} commands={commands} />
      ) : (
        <EmptyState {...state} hasResults={state.loadedComponents.length > 0} />
      )}
    </ResultWrapper>
  );
};

type EmptyStateProps = {
  advancedSearchState: ViewpointBuilderAdvancedSearchState;
  hasResults: boolean;
  hasSearchResults: boolean;
  selectedComponentTypeHasNoInstances: boolean;
  componentName: string;
  componentType: string;
};

const EmptyState = ({
  hasResults,
  hasSearchResults,
  advancedSearchState,
  componentName,
  selectedComponentTypeHasNoInstances,
  componentType,
}: EmptyStateProps) => {
  if (advancedSearchState) {
    return (
      <AdvancedSearchEmptyStates
        hasResults={hasResults}
        advancedSearchState={advancedSearchState}
      />
    );
  }

  return (
    <ComponentSearchEmptyState
      term={componentName}
      hasSearchResults={hasSearchResults}
      selectedComponentTypeHasNoInstances={selectedComponentTypeHasNoInstances}
      selectedType={componentType}
    />
  );
};

const ResultWrapper = styled.div`
  position: relative; /* needed to contain loader/spinner */
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  flex-grow: 1;
`;

type SearchResultProps = {
  commands: ComponentsSearchCommands;
} & LoadedComponentsState &
  SelectedComponentsState &
  SearchCriteriaState;

const SearchResult = ({
  totalCount,
  allComponentsSelected,
  selectedComponents,
  advancedSearchState,
  selectionClearedOnSelectAllCount,
  loadedComponents,
  selectedComponentsCount,
  commands,
  isSelectAllDisabled,
}: SearchResultProps) => {
  const isAdvancedSearchEnabled = advancedSearchState !== null;

  return (
    <>
      <SearchResultCountAndSelectAll
        totalCount={totalCount}
        allComponentsSelected={allComponentsSelected}
        isAdvancedSearch={isAdvancedSearchEnabled}
        selectionClearedOnSelectAllCount={selectionClearedOnSelectAllCount}
        onToggleAllComponents={() =>
          commands.setAllComponentsSelected(!allComponentsSelected)
        }
        selectedComponentsCount={selectedComponentsCount}
        isSelectAllDisabled={isSelectAllDisabled}
      />

      <SelectableComponentList
        allComponentsSelected={allComponentsSelected}
        toggleComponentSelection={commands.toggleSingleComponentSelection}
        components={loadedComponents}
        selectedComponents={selectedComponents}
        useExternalToggleAll
      />
    </>
  );
};

export default ComponentSearchResults;
