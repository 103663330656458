import { useState } from 'react';
import workspaceAudienceDialog$ from './workspaceAudienceDialog$';
import FixedHeightSelectableDatasourceTable from 'broadcasts/components/FixedHeightSelectableDatasourceTable';
import NoWorkspaceSelectedContent from './NoWorkspaceSelectedContent';
import EmptyContent from 'broadcasts/components/EmptyContent';
import EmailCell from './cellRenderers/EmailCell';
import { ArdoqId, BroadcastPerson, SortOrder } from '@ardoq/api-types';
import { setLimitedToPersonIds } from './actions';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { PaginationBar, PaginationController } from '@ardoq/pagination';
import { SearchInput } from '@ardoq/forms';
import { getCurrentLocale, localeIncludesLowercase } from '@ardoq/locale';
import styled from 'styled-components';
import { Stack } from '@ardoq/layout';
import { spacing } from '@ardoq/design-tokens';

type WorkspaceAudienceTableStreamedProps = {
  readonly workspaceId: ArdoqId | null;
  readonly peopleInSelectedWorkspace: BroadcastPerson[];
  readonly limitedToPersonIds: ArdoqId[];
  readonly isFetchingPeopleInSelectedWorkspace: boolean;
};

export const OverflowWrapper = styled(Stack)`
  overflow: hidden;
  padding: ${spacing.s16} ${spacing.s24};
  height: 100vh;
  gap: ${spacing.s16};
`;

const search = (
  peopleInSelectedWorkspace: BroadcastPerson[],
  searchPhrase: string
) => {
  const locale = getCurrentLocale();

  if (!searchPhrase) return peopleInSelectedWorkspace;

  return peopleInSelectedWorkspace.filter(
    person =>
      localeIncludesLowercase(person.name ?? '', searchPhrase, locale) ||
      localeIncludesLowercase(person.email ?? '', searchPhrase, locale)
  );
};

const WorkspaceAudienceTable = ({
  workspaceId,
  peopleInSelectedWorkspace,
  limitedToPersonIds,
  isFetchingPeopleInSelectedWorkspace,
}: WorkspaceAudienceTableStreamedProps) => {
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [perPage, setPerPage] = useState<number>(50);
  const dataSource = search(peopleInSelectedWorkspace, searchPhrase);

  return (
    <OverflowWrapper gap="small">
      <SearchInput
        label="Search"
        value={searchPhrase}
        onValueChange={setSearchPhrase}
      />
      <PaginationController
        dataSource={dataSource}
        perPage={perPage}
        defaultSortById="name"
        defaultSortOrder={SortOrder.ASC}
        render={({
          currentPageDataSource,
          currentPageNumber,
          perPage,
          totalResults,
          sortById,
          sortOrder,
          sortBy,
          onPageSelect,
        }) => (
          <>
            <FixedHeightSelectableDatasourceTable
              dataSource={currentPageDataSource}
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'name',
                  sortOrder: sortById === 'name' ? sortOrder : undefined,
                  onHeaderClick: () => sortBy('name'),
                },
                {
                  title: 'Contact email',
                  dataIndex: 'email',
                  valueRender: (email: string) => <EmailCell email={email} />,
                  sortOrder: sortById === 'email' ? sortOrder : undefined,
                  onHeaderClick: () => sortBy('email'),
                },
              ]}
              loading={isFetchingPeopleInSelectedWorkspace}
              selected={limitedToPersonIds}
              setSelected={newLimitedToPersonIds => {
                dispatchAction(setLimitedToPersonIds(newLimitedToPersonIds));
              }}
              renderEmptyTable={() => {
                if (isFetchingPeopleInSelectedWorkspace) return null;
                if (!workspaceId) return <NoWorkspaceSelectedContent />;
                return <EmptyContent />;
              }}
            />
            <PaginationBar
              pageSizeOptions={[10, 20, 50, 100]}
              currentPageNumber={currentPageNumber}
              perPage={perPage}
              totalResults={totalResults}
              onChange={({
                currentPageNumber: newPageNumber,
                perPage: newPerPage,
              }) => {
                setPerPage(newPerPage);
                onPageSelect(newPageNumber);
              }}
            />
          </>
        )}
      />
    </OverflowWrapper>
  );
};

export default connect(WorkspaceAudienceTable, workspaceAudienceDialog$);
