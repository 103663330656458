import { AppModules } from 'appContainer/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { Route } from 'router/StreamRouter';
import { AppRouterState, ReportModuleRoute } from 'router/appRouterTypes';
import reportNavigation$ from './reportNavigation$';
import {
  navigateToReportBuilder,
  navigateToReportOverview,
  navigateToReportReader,
} from 'router/navigationActions';
import { ReportModule } from '../types';
import { map } from 'rxjs/operators';
import { FILTERS_URL_KEY } from '@ardoq/report-reader';

const serialiseQuerystring = (filters: string) => {
  const query = new URLSearchParams();
  query.append(FILTERS_URL_KEY, filters);
  const queryString = query.toString();
  return queryString ? `?${queryString}` : '';
};

const reportBuilderRegex = /^\/report\/builder(?:\/([a-f0-9]+))?\/?$/;
const reportReaderRegex = /^\/report\/reader\/([a-f0-9]+)\/?$/;
const reportOverviewRegex = /^\/report\/$/;

export const reportRoute = new Route<AppRouterState, ReportModuleRoute>({
  doesLocationMatch: ({ path }) =>
    reportOverviewRegex.test(path) ||
    reportBuilderRegex.test(path) ||
    reportReaderRegex.test(path),
  locationToRouterState: ({ path, searchParams }) => {
    const querystringFilters = searchParams?.get(FILTERS_URL_KEY);
    const filters = querystringFilters
      ? JSON.parse(querystringFilters)
      : undefined;
    const reportId =
      path.match(reportReaderRegex)?.[1] || path.match(reportBuilderRegex)?.[1];
    const module = reportReaderRegex.test(path)
      ? ReportModule.READER
      : reportBuilderRegex.test(path)
        ? ReportModule.BUILDER
        : ReportModule.OVERVIEW;

    return {
      appModule: AppModules.REPORTS,
      reportModule: module,
      ...(reportId ? { reportId } : {}),
      ...(filters ? { filters } : {}),
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.REPORTS,
  routerStateToLocation: ({ reportId, filters, reportModule }) => {
    if (reportModule === ReportModule.BUILDER) {
      return {
        path: reportId ? `/report/builder/${reportId}` : `/report/builder`,
        title: 'Report Builder',
      };
    } else if (reportModule === ReportModule.READER) {
      return {
        path: `/report/reader/${reportId}${
          filters ? serialiseQuerystring(JSON.stringify(filters)) : ''
        }`,
        title: 'Report Reader',
      };
    }
    return {
      path: '/report/',
      title: 'Report Overview',
    };
  },
  setApplicationStateFromRoute: ({ reportId, filters, reportModule }) => {
    if (reportModule === ReportModule.BUILDER) {
      dispatchAction(
        navigateToReportBuilder({
          reportId,
        })
      );
    } else if (reportModule === ReportModule.READER) {
      dispatchAction(
        navigateToReportReader({
          reportId,
          filters,
        })
      );
    } else {
      dispatchAction(navigateToReportOverview());
    }
  },
  getPartialRouterStateStream: () =>
    reportNavigation$.pipe(
      map(({ reportId, filters, reportModule }) => ({
        reportModule,
        ...(reportId ? { reportId } : {}),
        ...(filters ? { filters } : {}),
      }))
    ),
});

export default reportRoute;
