import { Reducer, reducer } from '@ardoq/rxbeach';
import {
  IntegrationId,
  IntegrationToTabularMapping,
  TableMappingMap,
  TabularMapping,
} from './types';
import {
  applyTabularMapping,
  clearTabularMapping,
  applyColumnMapping,
  applyTableMapping,
  clearSingleTabularMapping,
  applyColumnFilter,
  removeColumnMappings,
  removeTargetWorkspace,
  applyColumnTransformations,
  removeColumnTransformations,
} from './actions';
import { set, mergeAllWith, update, omit, unset } from 'lodash/fp';
import {
  DataSyncStrategy,
  TableMappingType,
  TransformationColumn,
} from '@ardoq/api-types/integrations';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { ActionCreatorParameter } from '../../utils/actionCreatorWithIntegrationId';

const applyTabularMappingReducer = (
  currentAllConfigs: IntegrationToTabularMapping,
  { integrationId, mapping }: ActionCreatorParameter<typeof applyTabularMapping>
) => ({
  ...currentAllConfigs,
  [integrationId]: mapping,
});
const handleApplyTablularMapping = reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof applyTabularMapping>
>(applyTabularMapping, applyTabularMappingReducer);

const clearTabularMappingReducer = (
  currentAllConfigs: IntegrationToTabularMapping,
  { integrationId }: ActionCreatorParameter<typeof clearTabularMapping>
) => ({
  ...currentAllConfigs,
  [integrationId]: {},
});
const handleClearConfig = reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof clearTabularMapping>
>(clearTabularMapping, clearTabularMappingReducer);

const clearSingleTabularMappingReducer = (
  currentAllConfigs: IntegrationToTabularMapping,
  {
    integrationId,
    id,
  }: ActionCreatorParameter<typeof clearSingleTabularMapping>
) => {
  const integrations = { ...currentAllConfigs[integrationId] };
  delete integrations[id];
  return {
    ...currentAllConfigs,
    [integrationId]: integrations,
  };
};
const handleClearSingleConfig = reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof clearSingleTabularMapping>
>(clearSingleTabularMapping, clearSingleTabularMappingReducer);

const getDefaultTableMapping = ({
  tableId,
}: {
  tableId: string;
}): Partial<TableMappingMap> => ({
  id: tableId,
  headerRow: 0,
  columnFilters: {},
  syncOption: DataSyncStrategy.MERGE_AND_UPSERT,
  rowRepresentation: TableMappingType.COMPONENTS,
  mappedColumns: {},
});

export const applyTableMappingReducer: Reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof applyTableMapping>
> = (currentAllConfigs, { integrationId, tableId, tableMapping }) => {
  const currentTableMapping = currentAllConfigs[integrationId][tableId];

  const mapping: TableMappingMap = mergeAllWith(
    (_, next) => next, // avoid deep object merging
    [getDefaultTableMapping({ tableId }), currentTableMapping, tableMapping]
  );
  const config = set([tableId], mapping, currentAllConfigs[integrationId]);
  return { ...currentAllConfigs, [integrationId]: config };
};

const handleApplyTableMapping = reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof applyTableMapping>
>(applyTableMapping, applyTableMappingReducer);

export const applyColumnMappingReducer: Reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof applyColumnMapping>
> = (
  currentAllConfigs,
  { integrationId, tableId, columnIndex, columnMapping }
) => {
  const config = set(
    [tableId, 'mappedColumns', columnIndex],
    columnMapping,
    currentAllConfigs[integrationId]
  );
  return { ...currentAllConfigs, [integrationId]: config };
};

const handleApplyColumnMapping = reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof applyColumnMapping>
>(applyColumnMapping, applyColumnMappingReducer);

const removeTargetWorkspaceReducer = (
  currentAllConfigs: IntegrationToTabularMapping,
  {
    integrationId,
    tableId,
  }: ActionCreatorParameter<typeof removeTargetWorkspace>
) => {
  const config = unset(
    [tableId, 'targetWorkspace'],
    currentAllConfigs[integrationId]
  );

  return { ...currentAllConfigs, [integrationId]: config };
};
const handleUnsetTargetWorkspace = reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof removeTargetWorkspace>
>(removeTargetWorkspace, removeTargetWorkspaceReducer);

export const removeColumnMappingsReducer: Reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof removeColumnMappings>
> = (currentAllConfigs, { integrationId, tableId, columnIndexes }) => {
  if (!currentAllConfigs[integrationId][tableId]) {
    return currentAllConfigs;
  }

  return update(
    [integrationId, tableId, 'mappedColumns'],
    m => omit(columnIndexes, m),
    currentAllConfigs
  );
};

const handleRemoveColumnMappings = reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof removeColumnMappings>
>(removeColumnMappings, removeColumnMappingsReducer);

const removeColumnTransformationsReducer: Reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof removeColumnTransformations>
> = (currentAllConfigs, { integrationId, tableId, index }) => {
  const config: TabularMapping = update(
    [tableId, 'transformations', 'column'],
    columns =>
      (columns || []).filter(
        (_: TransformationColumn[], i: number) => i !== index
      ),
    currentAllConfigs[integrationId]
  );
  return { ...currentAllConfigs, [integrationId]: config };
};

const handleRemoveColumnTransformations = reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof removeColumnTransformations>
>(removeColumnTransformations, removeColumnTransformationsReducer);

const applyColumnTransformationsReducer: Reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof applyColumnTransformations>
> = (
  currentAllConfigs,
  { integrationId, tableId, index, transformationColumn }
) => {
  const config: TabularMapping = set(
    [tableId, 'transformations', 'column', index],
    transformationColumn,
    currentAllConfigs[integrationId]
  );
  return { ...currentAllConfigs, [integrationId]: config };
};

const handleApplyColumnTransformations = reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof applyColumnTransformations>
>(applyColumnTransformations, applyColumnTransformationsReducer);

export const applyColumnFilterReducer: Reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof applyColumnFilter>
> = (currentAllConfigs, { integrationId, tableId, columnIndex, filters }) => {
  const config = set(
    [tableId, 'columnFilters', columnIndex],
    filters,
    currentAllConfigs[integrationId]
  );
  return { ...currentAllConfigs, [integrationId]: config };
};
const handleApplyColumnFilter = reducer<
  IntegrationToTabularMapping,
  ActionCreatorParameter<typeof applyColumnFilter>
>(applyColumnFilter, applyColumnFilterReducer);

const resetIntegrationReducer = (
  configs: IntegrationToTabularMapping,
  integrationId: IntegrationId
) => ({
  ...configs,
  [integrationId]: {},
});
const handleResetIntegration = reducer<
  IntegrationToTabularMapping,
  IntegrationId
>(resetIntegration, resetIntegrationReducer);

export const reducers = [
  // table
  handleApplyTablularMapping,
  handleApplyTableMapping,
  // column
  handleApplyColumnMapping,
  handleRemoveColumnMappings,
  handleApplyColumnFilter,
  handleApplyColumnTransformations,
  handleRemoveColumnTransformations,
  // config
  handleClearConfig,
  handleClearSingleConfig,
  handleResetIntegration,
  handleUnsetTargetWorkspace,
];
