import { useEffect, useState } from 'react';
import { FormWrapper, TextInput } from '@ardoq/forms';
import { modal, ModalLayout, ModalTemplate } from '@ardoq/modal';
import styled from 'styled-components';
import { Link, header4, text1 } from '@ardoq/typography';
import { ErrorNotification } from '@ardoq/status-ui';
import { Status } from 'integrations/ITPedia/streams/types';
import { connectionIsITPediaAccount, validateInput } from './utils';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  upsertAccount,
  updateAccountState,
} from 'integrations/ITPedia/streams/accounts/actions';
import { itPediaAccounts$ } from 'integrations/ITPedia/streams/accounts/account$';
import { map } from 'rxjs';
import { clone } from 'lodash/fp';
import { Connection, ITPediaConnection } from '@ardoq/api-types/integrations';

type NewAccountDialogParams = {
  apiStatus: Status;
  apiError: string;
  exisitingAccount?: ITPediaConnection;
  onCancel?: () => void;
};

const defaultAccount = {
  _id: '',
  name: '',
  baseUrl: 'itpedia.eracent.com/API',
  accessKey: '',
  secretKey: '',
};

function NewAccountDialogComponent({
  apiStatus,
  apiError,
  exisitingAccount,
  onCancel,
}: NewAccountDialogParams) {
  const [accountInput, setAccountInput] = useState<ITPediaConnection>(
    exisitingAccount || defaultAccount
  );
  const [validationError, setValidationError] = useState<string>();
  const onSubmit = () => {
    const { isValid, message } = validateInput(accountInput);
    if (!isValid) {
      setValidationError(message);
      return;
    }
    dispatchAction(upsertAccount(accountInput));
  };
  // We need in this case when status is changed to success, we need to close the dialog
  useEffect(() => {
    if (apiStatus === 'Success') {
      onCancel?.();
      dispatchAction(
        updateAccountState({ upsertStatus: 'Initial', upsertMessage: '' })
      );
    }
  }, [apiStatus, onCancel]);
  return (
    <ModalTemplate
      modalSize={2}
      headerText="Connect new IT-Pedia account"
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onCancel}
      primaryButtonText="Connect"
      onPrimaryButtonClick={onSubmit}
      isInProgress={apiStatus === 'Loading'}
    >
      <ModalLayout>
        <Form>
          <Header4>
            Enter your IT-Pedia username and password to connect your account to
            Ardoq. This information won&apos;t be shared.
          </Header4>
          <Text1>
            We securely handle your data:{' '}
            <Link target="_blank" href="https://www.ardoq.com/privacy">
              Privacy Notice
            </Link>
          </Text1>
          <TextInput
            value={accountInput.name}
            onValueChange={val =>
              setAccountInput({ ...accountInput, name: val })
            }
            label="Give your connection a descriptive name"
          />
          <TextInput
            label="Base URL"
            prefix="https://"
            value={accountInput.baseUrl}
            onValueChange={val =>
              setAccountInput({ ...accountInput, baseUrl: val })
            }
          />
          <TextInput
            value={accountInput.accessKey}
            onValueChange={val =>
              setAccountInput({ ...accountInput, accessKey: val })
            }
            label="IT-Pedia username"
          />
          <TextInput
            type="password"
            value={accountInput.secretKey}
            onValueChange={val =>
              setAccountInput({ ...accountInput, secretKey: val })
            }
            label="IT-Pedia password"
          />
          {(validationError || apiError) && (
            <ErrorNotification>{validationError || apiError}</ErrorNotification>
          )}
        </Form>
      </ModalLayout>
    </ModalTemplate>
  );
}

const viewModel$ = itPediaAccounts$.pipe(
  map(value => ({
    apiStatus: value.upsertStatus,
    apiError: value.upsertMessage,
  }))
);

export const NewAccountDialog = connect(NewAccountDialogComponent, viewModel$);

const Form = styled(FormWrapper)`
  width: 100%;
`;

const Header4 = styled.div`
  ${header4};
`;

const Text1 = styled.div`
  ${text1};
`;

// Temporary location for the modal function
// Todo: Remove this in a cleanup PR
export const startNewAccountDialog = (account?: Connection) => {
  const accountData = account ? clone(account) : undefined;
  if (accountData) {
    if (!connectionIsITPediaAccount(accountData)) {
      return;
    }
    accountData.baseUrl = accountData.baseUrl.replace(/^(https|http):\/\//, '');
  }
  modal(
    close => {
      dispatchAction(
        updateAccountState({
          upsertStatus: 'Initial',
          upsertMessage: '',
        })
      );
      return (
        <NewAccountDialog
          exisitingAccount={accountData}
          onCancel={() => close(null)}
        />
      );
    },
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
};
