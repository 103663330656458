import { useEffect } from 'react';
import { colors, s16, s32 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { ViewpointsStreamShape } from './types';
import {
  ButtonGroup,
  PrimaryButton,
  BrandButton,
  GhostButton,
} from '@ardoq/button';
import viewpoints$ from './viewpoints$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { setViewpointLegacyMode, viewpointSave } from './actions';
import { AqLayout } from '@ardoq/layout';
import { ToolbarDivider } from '@ardoq/page-layout';
import { navigateToViewpoints } from 'router/navigationActions';
import {
  APIDiscoverViewpointAttributes,
  ArdoqId,
  NonPersistedDiscoverViewpoint,
  PrivilegeLabel,
} from '@ardoq/api-types';
import Grouping from './formSections/Grouping/Grouping';
import PublishSwitch from './formSections/PublishSwitch';
import Workspaces from './formSections/Workspaces';
import { getSelectedComponentTypeNames, isPersisted } from './utils';
import { defaultLabelFormatting } from './formSections/Formatting/LabelFormatting';
import { confirm } from '@ardoq/modal';
import DetailEntriesForm from './formSections/DetailEntriesForm/DetailEntriesForm';
import FormSection from './formSections/FormSection';
import ViewpointModel from './formSections/ViewpointModel/ViewpointModel';
import Formatting from './formSections/Formatting/Formatting';
import ComponentsFilter from './formSections/ComponentFilter/ComponentsFilter';
import ViewStyleConfiguration from './formSections/ViewStyleConfiguration/ViewStyleConfiguration';
import NameInput from './formSections/NameInput';
import DescriptionInput from './formSections/DescriptionInput';
import ViewpointDefaultNotification from './ViewpointDefaultNotification';
import { isEmpty } from 'lodash';
import SurveyDetailsForm from './formSections/SurveyDetails/SurveyDetailsForm';
import MetamodelPreview from './formSections/MetamodelPreview';
import { trackEvent } from 'tracking/tracking';
import { ViewpointTrackingEventsNames } from './tracking';
import { viewpointHasRestrictedWorkspaces } from './restrictedWorkspaces/operations';
import RestrictedWorkspacesErrorNotification from './restrictedWorkspaces/RestrictedWorkspacesErrorNotification';
import { DoqLoader } from '@ardoq/snowflakes';
import { UnauthorizedAccessPage } from '@ardoq/manage-resource-permissions';
import { viewpointAccessControlInterface } from 'resourcePermissions/accessControlHelpers/viewpoints';
import { hasFeature, Features } from '@ardoq/features';
import { map } from 'rxjs';
import { Space } from '@ardoq/style-helpers';
import Navbar from 'views/navbar/Navbar';
import { ChevronLeftIcon } from '@ardoq/icons';
import { hasPrivilege } from '@ardoq/privileges';
import { PageBody, PageWrapper } from '@ardoq/page-layout';

type CurrentViewpointAccess = {
  canRead: boolean;
  canEdit: boolean;
  canAdmin: boolean;
};

type ViewpointFormProps = ViewpointsStreamShape & {
  viewpointId: ArdoqId | null;
  hasNewJourneyFeature: boolean;
  hasDiscoverAccess: boolean;
};

type ViewpointsFormProps = Omit<ViewpointFormProps, 'hasDiscoverAccess'> & {
  currentViewpointAccess: CurrentViewpointAccess;
};

const SpecialSnowflakeButtonContainer = styled.div`
  /* margin already applied by button container (16px) and global style (8px).
     Design spec says it needs to be 36px. */
  margin-right: 12px;
`;

const RightAligned = styled.div`
  display: flex;
  flex-grow: 1;
  min-width: fit-content;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  gap: ${s32};
  display: grid;
`;

const ErrorNotificationContainer = styled.div`
  margin-bottom: ${s16};
`;

const shouldSaveChanges = async (name?: string) =>
  await confirm({
    title: 'Unsaved viewpoint changes',
    text: (
      <>
        You have started editing{' '}
        {name ? <strong>{name}</strong> : 'an untitled viewpoint'}.
        <br />
        Do you want to save or discard your changes before you proceed?
      </>
    ),
    cancelButtonTitle: 'Discard',
    confirmButtonTitle: 'Save',
  });

const getInitialValue = (): NonPersistedDiscoverViewpoint => ({
  name: '',
  allTypes: [],
  description: '',
  entryPoints: [],
  defaultForComponentTypeNames: [],
  dynamicGroupedTraversals: [],
  type: 'dynamic-grouped-traversals',
  viewStyle: { style: 'relationship-view' },
  groupBys: undefined,
  conditionalFormatting: defaultLabelFormatting,
  workspaceIds: [],
  published: false,
  detailEntries: [],
  componentFilters: [],
  surveyDetails: undefined,
  includeConnectedPeople: false,
  completeReadAccess: false,
});

const ViewpointForm = ({
  viewpoints,
  viewpointId,
  currentViewpoint,
  currentViewpointAccess,
  availableTriples,
  isSaving,
  surveys,
  restrictedWorkspacesForEveryViewpoint,
  hasNewJourneyFeature,
}: ViewpointsFormProps) => {
  const form = useForm<APIDiscoverViewpointAttributes>({
    mode: 'onChange',
    defaultValues: getInitialValue(),
  });
  const { trigger, handleSubmit, reset, formState } = form;

  useEffect(() => {
    if (isEmpty(formState.dirtyFields)) return;
    trigger();
  }, [trigger, formState.dirtyFields, availableTriples]);

  useEffect(() => {
    reset(currentViewpoint ?? getInitialValue());
  }, [currentViewpoint, viewpointId, reset]);

  useEffect(() => {
    const latestPersistedVersionOfViewpoint = viewpoints?.find(
      viewpoint => viewpoint._id === viewpointId
    );

    if (!latestPersistedVersionOfViewpoint) {
      reset(getInitialValue());
      return;
    }

    const isUsingLatestVersionOfViewpoint =
      currentViewpoint &&
      isPersisted(currentViewpoint) &&
      currentViewpoint._version === latestPersistedVersionOfViewpoint._version;

    if (isUsingLatestVersionOfViewpoint) return;

    reset(latestPersistedVersionOfViewpoint);
  }, [currentViewpoint, viewpointId, viewpoints, reset]);

  const onSubmit = (viewpoint: APIDiscoverViewpointAttributes) => {
    dispatchAction(viewpointSave(viewpoint));
  };

  const handleNavigateToOverview = async (isSaved: boolean) => {
    if (
      !isSaved &&
      currentViewpoint &&
      (await shouldSaveChanges(currentViewpoint.name))
    ) {
      handleSubmit(onSubmit)();
    }
    dispatchAction(setViewpointLegacyMode(true));
    dispatchAction(navigateToViewpoints());
  };

  const defaultForComponentTypeNames = form.watch(
    'defaultForComponentTypeNames'
  );

  const name = form.watch('name');

  const [workspaceIds, dynamicGroupedTraversals] = form.getValues([
    'workspaceIds',
    'dynamicGroupedTraversals',
  ]);
  const componentTypeOptions = getSelectedComponentTypeNames(
    dynamicGroupedTraversals
  );
  const relatedSurveys = surveys?.filter(
    survey =>
      survey.workspace &&
      workspaceIds.includes(survey.workspace) &&
      survey.published &&
      survey.discoverEnabled &&
      componentTypeOptions.includes(survey.componentTypeName)
  );
  const allRelatedSurveysArePriority = relatedSurveys?.every(
    survey => survey.discoverPriority
  );

  const currentViewpointHasRestrictedWorkspaces =
    viewpointHasRestrictedWorkspaces(
      restrictedWorkspacesForEveryViewpoint,
      currentViewpoint?._id
    );

  const hasNoSubmittableChanges = isEmpty(formState.dirtyFields) || isSaving;
  const isSaveButtonDisabled =
    hasNoSubmittableChanges ||
    !currentViewpointAccess.canEdit ||
    currentViewpointHasRestrictedWorkspaces;

  const pageContent = (
    <>
      {currentViewpointHasRestrictedWorkspaces && (
        <ErrorNotificationContainer>
          <RestrictedWorkspacesErrorNotification
            viewpointId={currentViewpoint!._id}
            restrictedWorkspacesForEveryViewpoint={
              restrictedWorkspacesForEveryViewpoint
            }
          />
        </ErrorNotificationContainer>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {defaultForComponentTypeNames &&
          defaultForComponentTypeNames.length > 0 && (
            <ViewpointDefaultNotification
              defaultForComponentTypeNames={defaultForComponentTypeNames}
            />
          )}
        <FormSection
          header="Describe your Viewpoint for a wide audience"
          description="People with different roles and goals use this information to decide which viewpoint to use."
          knowledgeBaseReference="h_9f80a133f7"
        >
          <Wrapper>
            <NameInput />
            <DescriptionInput />
          </Wrapper>
        </FormSection>

        <FormSection
          header="Select workspaces to source your data from"
          knowledgeBaseReference="h_77f7f51771"
          enforceWidth={false}
          hasError={currentViewpointHasRestrictedWorkspaces}
        >
          <Workspaces />
          <MetamodelPreview />
        </FormSection>

        <FormSection
          header="Choose a view style"
          description="Ardoq Discover users will see a preview of your chosen viewpoint style."
          knowledgeBaseReference="h_7aca176a73"
        >
          <ViewStyleConfiguration name="viewStyle" />
        </FormSection>

        <FormSection
          header="Create data rules for your model"
          description="Within each step, data rules from source to target component need to be in one direction or circular."
          enforceWidth={false}
          knowledgeBaseReference="h_58fb1ad6a1"
        >
          <ViewpointModel
            availableTriples={availableTriples}
            isDisabled={currentViewpointHasRestrictedWorkspaces}
            hasNoSubmittableChanges={hasNoSubmittableChanges}
          />
        </FormSection>

        <FormSection
          header="Apply filter rules to display a subset of data"
          description="Select the data you want to include in the viewpoint."
          enforceWidth={false}
          knowledgeBaseReference="h_879bd687e0"
        >
          <ComponentsFilter />
        </FormSection>

        <FormSection
          header="Select which fields to show in the sidebar"
          description="Over time, components and references can collect a lot of fields. Select which ones are most relevant for this Viewpoint."
          enforceWidth={false}
          knowledgeBaseReference="h_16336d8526"
        >
          <DetailEntriesForm />
        </FormSection>

        <FormSection
          header="Group components"
          description="Show component hierarchy and give a clearer view of how components relate to one another by grouping them based on different criteria."
          enforceWidth={false}
          knowledgeBaseReference="h_f2d6600dfc"
        >
          <Grouping />
        </FormSection>

        <FormSection
          header="Highlight key info with formatting"
          description="Make patterns and trends more apparent with automated color formatting based on rules you set here."
          enforceWidth={false}
          knowledgeBaseReference="h_39d16a890a"
        >
          <Formatting />
        </FormSection>

        {relatedSurveys?.length && !allRelatedSurveysArePriority ? (
          <FormSection
            header="Surveys"
            description="Arrange the non-priority survey order for each component type in this viewpoint."
            enforceWidth={true}
          >
            <SurveyDetailsForm relatedSurveys={relatedSurveys} />
          </FormSection>
        ) : null}
      </form>
    </>
  );

  const isPublishSwitchDisabled =
    !currentViewpointAccess.canEdit ||
    (!isEmpty(formState.dirtyFields) && formState.isValid) ||
    currentViewpointHasRestrictedWorkspaces;

  const handleBackToOverview = () => {
    trackEvent(
      ViewpointTrackingEventsNames.CLICKED_ON_GO_TO_VIEWPOINTS_OVERVIEW_IN_VIEWPOINT_BUILDER
    );
    handleNavigateToOverview(!formState.isDirty);
  };
  return (
    <FormProvider {...form}>
      {hasNewJourneyFeature ? (
        <PageWrapper>
          <Navbar
            primaryContent={name.length ? name : 'Untitled Viewpoint'}
            primaryButton={
              <BrandButton
                onClick={handleSubmit(onSubmit)}
                isDisabled={isSaveButtonDisabled}
                dataTestId="save-viewpoint-button"
                isLoading={isSaving}
              >
                Save changes
              </BrandButton>
            }
            secondaryButton={
              <GhostButton
                dataTestId="overview-button"
                onClick={handleBackToOverview}
              >
                <ChevronLeftIcon />
                Back to viewpoints
              </GhostButton>
            }
            secondaryContent="Viewpoint builder"
            toolbarContent={
              <Space $align="center">
                <PublishSwitch isDisabled={isPublishSwitchDisabled} />
                <ToolbarDivider />
              </Space>
            }
          />
          <PageBody>{pageContent}</PageBody>
        </PageWrapper>
      ) : (
        <AqLayout
          title="Viewpoint Builder"
          buttonContainerStyle={{
            flexGrow: 1,
            alignItems: 'center',
          }}
          bodyWrapperStyle={{ backgroundColor: colors.surfaceStatusNeutral }}
          renderHeaderButtons={() => (
            <RightAligned>
              <ButtonGroup $align="center">
                <SpecialSnowflakeButtonContainer>
                  <GhostButton
                    dataTestId="overview-button"
                    onClick={handleBackToOverview}
                  >
                    Go to viewpoints overview
                  </GhostButton>
                </SpecialSnowflakeButtonContainer>
                <PrimaryButton
                  onClick={handleSubmit(onSubmit)}
                  isDisabled={isSaveButtonDisabled}
                  dataTestId="save-viewpoint-button"
                  isLoading={isSaving}
                >
                  Save
                </PrimaryButton>
                <PublishSwitch isDisabled={isPublishSwitchDisabled} />
              </ButtonGroup>
            </RightAligned>
          )}
        >
          {pageContent}
        </AqLayout>
      )}
    </FormProvider>
  );
};

const getViewpointCurrentUserAccessLevels = (viewpointId?: ArdoqId | null) => {
  if (!viewpointId) {
    const canCreateNew = viewpointAccessControlInterface.canCreateViewpoint();
    return {
      canRead: canCreateNew,
      canEdit: canCreateNew,
      canAdmin: canCreateNew,
    };
  }
  return {
    canRead:
      !viewpointId ||
      viewpointAccessControlInterface.canReadViewpoint(
        viewpointId ?? undefined
      ),
    canEdit:
      !viewpointId ||
      viewpointAccessControlInterface.canEditViewpoint(
        viewpointId ?? undefined
      ),
    canAdmin:
      !viewpointId ||
      viewpointAccessControlInterface.canAdminViewpoint(
        viewpointId ?? undefined
      ),
  };
};

const ViewpointFormContainer = ({
  viewpointId,
  currentViewpoint,
  loadingViewpoints,
  hasDiscoverAccess,
  ...props
}: ViewpointFormProps) => {
  const isNewViewpoint = !viewpointId;
  const loadingCurrentViewpoint =
    !isNewViewpoint &&
    (!currentViewpoint || currentViewpoint._id !== viewpointId);

  if (loadingViewpoints && loadingCurrentViewpoint) {
    return <DoqLoader title="Retrieving Viewpoints data..." />;
  }
  const currentViewpointAccess =
    getViewpointCurrentUserAccessLevels(viewpointId);

  if (!currentViewpointAccess.canEdit || !hasDiscoverAccess) {
    return <UnauthorizedAccessPage />;
  }

  return (
    <ViewpointForm
      {...props}
      viewpointId={viewpointId}
      currentViewpointAccess={currentViewpointAccess}
      currentViewpoint={currentViewpoint}
      loadingViewpoints={loadingViewpoints}
    />
  );
};

export default connect(
  ViewpointFormContainer,
  viewpoints$.pipe(
    map(viewpointsStreamData => ({
      ...viewpointsStreamData,
      hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
      hasDiscoverAccess: hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER),
    }))
  )
);
