import { IconName } from '@ardoq/icons';
import { ViewpointBuilderTabItemMeta } from './types';
import { Features, hasFeature } from '@ardoq/features';
import { uniq } from 'lodash';

const startComponents: ViewpointBuilderTabItemMeta = {
  tabId: 'SELECT_CONTEXT_COMPONENT_INSTANCES_TAB',
  label: 'Context component type',
  icon: IconName.API,
  description:
    'Open selected components directly or select references in the step below to see other linked components.',
};

const contextComponentType: ViewpointBuilderTabItemMeta = {
  tabId: 'SELECT_CONTEXT_COMPONENT_TYPE_TAB',
  label: 'Context component type',
  icon: IconName.COMPONENT,
  description:
    'The Viewpoint is based on this component type. Next, you select references to see other component types',
};

const subgraph: ViewpointBuilderTabItemMeta = {
  tabId: 'SUBGRAPH_TAB',
  label: 'Component and reference types',
  icon: IconName.ACCOUNT_TREE,
  description:
    'Use references to see linked components. You can select any of the component types here and select references for it.',
};

const filters: ViewpointBuilderTabItemMeta = {
  tabId: 'FILTERS_TAB',
  label: 'Filters',
  icon: IconName.FILTER_LIST,
  description:
    'Narrow down and specify the components that appear in the dataset when this Viewpoint is used.',
};

const collapsePaths: ViewpointBuilderTabItemMeta = {
  tabId: 'COLLAPSE_PATHS_TAB',
  label: 'Collapse paths',
  icon: IconName.COLLAPSE_ALL,
  description: 'Hide the data between indirectly connected component types.',
};

const requiredComponents: ViewpointBuilderTabItemMeta = {
  tabId: 'REQUIRED_COMPONENTS_TAB',
  label: 'Required paths',
  icon: IconName.SETTINGS,
  description:
    'Select the paths that the components in your dataset must match.',
};

const viewpointDetails: ViewpointBuilderTabItemMeta = {
  tabId: 'META_INFO_TAB',
  label: 'Viewpoint details',
  icon: IconName.DESCRIPTION,
  description:
    'The name and description helps everyone understand what data can be gotten with this Viewpoint.',
};

const groups: ViewpointBuilderTabItemMeta = {
  tabId: 'GROUPS_TAB',
  label: 'Groups',
  icon: IconName.APPLICATION_GROUP,
  description: 'Show the hierarchy and relationship between components.',
};

const formatting: ViewpointBuilderTabItemMeta = {
  tabId: 'FORMATTING_TAB',
  label: 'Format',
  icon: IconName.PALETTE,
  description:
    'Set rules to automatically highlight components and references with colors.',
};

const labels: ViewpointBuilderTabItemMeta = {
  tabId: 'LABELS_TAB',
  label: 'Labels',
  icon: IconName.TAG,
  description:
    'Show field values on specific component and reference types. Component name is shown by default.',
};

export const openDatasetMenuItems: ViewpointBuilderTabItemMeta[] = [
  {
    ...startComponents,
    label: 'Select context component',
    icon: IconName.COMPONENT,
  },
  {
    ...subgraph,
    label: 'Select references',
  },
  {
    ...filters,
    description:
      'Select a component or reference type and add filters to it. Use this to narrow down and specify the components that appear in the dataset.',
    label: 'Add filters',
  },
  requiredComponents,
  collapsePaths,
];

const phoenixOnlyTabs: ViewpointBuilderTabItemMeta[] = [];
const phoenixOrAmplifyTabs: ViewpointBuilderTabItemMeta[] = [];
const phoenixClosedBeta: ViewpointBuilderTabItemMeta[] = [];

export const getTabsBehindFeatureFlags = (): ViewpointBuilderTabItemMeta[] => {
  let tabs: ViewpointBuilderTabItemMeta[] = [];
  const isPhoenix = hasFeature(Features.SUPPORT_LARGE_DATASETS_EDGE);
  const isPhoenixOrAmplify =
    hasFeature(Features.SUPPORT_LARGE_DATASETS_EDGE) ||
    hasFeature(Features.PHOENIX_TO_AMPLIFY);
  const isPhoenixClosedBeta = hasFeature(Features.PHOENIX_CLOSED_BETA);

  if (isPhoenix) {
    tabs = [...tabs, ...phoenixOnlyTabs];
  }
  if (isPhoenixOrAmplify) {
    tabs = [...tabs, ...phoenixOrAmplifyTabs];
  }
  if (isPhoenixClosedBeta) {
    tabs = [...tabs, ...phoenixClosedBeta];
  }
  return uniq(tabs);
};

export const editViewpointMenuItems: ViewpointBuilderTabItemMeta[] = [
  viewpointDetails,
  startComponents,
  subgraph,
  filters,
  requiredComponents,
  collapsePaths,
  groups,
  formatting,
  labels,
];

export const createViewpointMenuItems: ViewpointBuilderTabItemMeta[] = [
  contextComponentType,
  {
    ...subgraph,
    description:
      'Select reference types to add other component types to the context.',
  },
  { ...filters, label: 'Filter (optional)' },
  { ...requiredComponents, label: 'Required paths (optional)' },
  { ...collapsePaths, label: 'Collapse paths (optional)' },
  { ...groups, label: 'Group (optional)' },
  { ...formatting, label: 'Format (optional)' },
  { ...labels, label: 'Label (optional)' },
  viewpointDetails,
];

export const metamodelInformationMenuItem: ViewpointBuilderTabItemMeta = {
  tabId: 'METAMODEL_TAB',
  label: 'Metamodel information',
  icon: IconName.METAMODEL,
  description: '', // it's not displayed as it's represented as a button on the UI
};
