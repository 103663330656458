import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
} from '@ardoq/rxbeach';
import { getGroups, getGroupsFinished } from './actions';
import { switchMap, tap } from 'rxjs/operators';
import { ToastType, showToast } from '@ardoq/status-ui';
import { groupApi, handleError } from '@ardoq/api';
import { logError } from '@ardoq/logging';

const handleGetGroups = routine(
  ofType(getGroups),
  switchMap(groupApi.fetchAll),
  handleError(error => {
    const errorMessage = 'Loading permission groups failed';
    showToast(errorMessage, ToastType.INFO);
    logError(error, errorMessage);
  }),
  tap(groups => dispatchAction(getGroupsFinished(groups)))
);

export const groupsRoutines = collectRoutines(handleGetGroups);
