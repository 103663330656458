import { permissionsOperations } from '@ardoq/access-control';
import { setVisibleIntegration } from 'integrations/actions';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { setTransferDirection } from 'integrations/common/streams/transferState/actions';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { combineLatest, map, of, switchMap } from 'rxjs';
import currentUser$ from 'streams/currentUser/currentUser$';

import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { getAllowedTabs } from 'integrations/common/navigation/tabs';
import {
  ExportRoute,
  ImportRoute,
  RoutePath,
} from 'integrations/common/navigation/types';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { getIntegrationConfigsStream } from '../../streams/integrationConfigs/integrationConfigs$';
import { enableTabularMappingsDefault } from '../../streams/tabularMappingsDefault/actions';
import { EXPORT_AVAILABILITY } from 'integrations/common/navigation/utils';

const handlePathChange = (integrationId: IntegrationId, path: RoutePath) =>
  dispatchAction(navigateToPath({ integrationId, path }));

const handleBackToOverview = () =>
  dispatchAction(setVisibleIntegration({ id: null }));

const handleNewImport = (integrationId: IntegrationId) => {
  dispatchAction(
    setTransferDirection({
      integrationId,
      transferDirection: TransferDirection.IMPORT,
    })
  );
  dispatchAction(enableTabularMappingsDefault({ integrationId }));

  handlePathChange(integrationId, ImportRoute.SELECT_DATA);
};

const handleNewExport = (integrationId: IntegrationId) => {
  dispatchAction(
    setTransferDirection({
      integrationId,
      transferDirection: TransferDirection.EXPORT,
    })
  );
  handlePathChange(integrationId, ExportRoute.SELECT_DATA);
};

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest([
      currentUser$,
      getIntegrationTermsDictionaryStream(integrationId),
      getConnectionsStream(integrationId),
      getIntegrationConfigsStream(integrationId),
      of(integrationId),
    ])
  ),
  map(
    ([
      currentUser,
      integrationTermsDictionary,
      {
        connections,
        statuses: {
          list: { status: listStatus },
        },
      },
      { config },
      integrationId,
    ]) => {
      const hasConnections = listStatus !== 'LOADING' && connections.length > 0;

      return {
        integrationId: integrationId,
        integrationName: integrationTermsDictionary.name,
        rightSidebarHeader: integrationTermsDictionary.rightSidebarHeader,
        overviewTitle: integrationTermsDictionary.integrationOverviewTitle,
        rightSideBarFAQ: integrationTermsDictionary.rightSideBarFAQ,
        tabs: getAllowedTabs({
          hasConnections,
          integrationId,
          allowedOverviewRoutes: config?.allowedOverviewRoutes,
        }),
        hasConnections,
        isAdmin: permissionsOperations.isOrgAdmin(currentUser),
        onTabChange: (path: RoutePath) => handlePathChange(integrationId, path),
        onBackToOverview: handleBackToOverview,
        onNewImport: () => handleNewImport(integrationId),
        onNewExport: EXPORT_AVAILABILITY[integrationId]?.()
          ? () => handleNewExport(integrationId)
          : undefined,
        primaryButtonLabel: integrationTermsDictionary.overviewPrimaryButton,
        secondaryButtonLabel:
          integrationTermsDictionary.overviewSecondaryButton,
      };
    }
  )
);
