import { handleError, historyApi } from '@ardoq/api';
import { logError } from '@ardoq/logging';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { startImportErrorModal } from 'integrations/common/modals/importErrorModal/ImportErrorModal';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { showSupport as showSupportUtil } from 'utils/support';
import { requestShowAppModule } from '../appContainer/actions';
import { AppModules } from '../appContainer/types';
import { trackEvent } from '../tracking/tracking';
import {
  initIntegration,
  loadImportsHistory,
  loadImportsHistorySuccess,
  openNewTab,
  setVisibleIntegration,
  showImportErrorModal,
  showSupport,
} from './actions';
import cljsMigrationRoutines from './common/routines';
import itPediaRoutines from './ITPedia/routines';
import lucidchartRoutines from './lucidchart/routines';
import lucidchartImportRoutines from './lucidchart/import/streams/routines';

const trackOpenedIntegration = (integrationId: string) =>
  trackEvent('Opened integration', {
    integrationId: integrationId,
  });

const handleInitIntegration = routine(
  ofType(setVisibleIntegration),
  extractPayload(),
  distinctUntilChanged(
    // id === null when requesting the all integrations module
    // we are interested only when the id === null
    // and when the id is changed from the before
    (prev, curr) => prev.id !== null && prev.id === curr.id
  ),
  tap(({ id, path }) => {
    if (id) {
      trackOpenedIntegration(id);
      dispatchAction(initIntegration({ id, path }));
    }
  })
);

const handleOpenIntegration = routine(
  ofType(setVisibleIntegration),
  extractPayload(),
  tap(() => {
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.INTEGRATIONS })
    );
  })
);

const handleOpenNewTab = routine(
  ofType(openNewTab),
  extractPayload(),
  tap(({ url }) => {
    const tab = window.open(url, '_blank');
    tab?.focus();
  })
);

const handleShowSupport = routine(
  ofType(showSupport),
  extractPayload(),
  tap(({ message }) => {
    showSupportUtil({
      chat: true,
      prePopulateMessage: message,
    });
  })
);

const handleShowImportErrorModal = routine(
  ofType(showImportErrorModal),
  tap(() => {
    startImportErrorModal();
  })
);

const handleLoadImportsHistory = routine(
  ofType(loadImportsHistory),
  switchMap(historyApi.fetchAll),
  handleError(logError),
  tap(response => {
    dispatchAction(
      loadImportsHistorySuccess({
        imports: response,
        // imports: [{ demo: 'data' }],
      })
    );
  })
);

export default collectRoutines(
  handleLoadImportsHistory,
  handleInitIntegration,
  handleOpenIntegration,
  handleOpenNewTab,
  handleShowSupport,
  handleShowImportErrorModal,
  itPediaRoutines,
  cljsMigrationRoutines,
  lucidchartRoutines,
  lucidchartImportRoutines
);
