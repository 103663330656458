import {
  AncestorItem,
  AncestorItemType,
  ExistingAncestorItem,
  MergeStep,
  StructuralConflict,
} from './types';
import { ModelStructureType } from 'aqTypes';
import {
  APIComponentAttributes,
  APIEntityType,
  APIFieldAttributes,
  APIReferenceAttributes,
  ArdoqId,
  isComponent,
  isReference,
  ScopeDataCollection,
} from '@ardoq/api-types';
import {
  getEntityById,
  getEntityModelAndTypeID,
  readRawValue,
} from '@ardoq/renderers';
import { findLast, uniq } from 'lodash';
import { EnhancedDiffScopeData } from './enhanceDiffContextData';
import { EnhancedScopeData, GraphModelShape } from '@ardoq/data-model';
import {
  getEntityTypeFromMergeStep,
  getVerbFromMergeStep,
} from 'components/DiffMergeSidebarNavigator/utils';
import { logError } from '@ardoq/logging';
import { Branch } from './Branch';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';
import { EnhancedDiffContextData } from 'components/DiffMergeTable/EnhancedDiffContextDataType';
import mergeState$ from 'scope/merge/mergeState$';
import { getBranchWhereEntityExists } from 'scopeData/utils/getBranchWhereEntityExists';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { subdivisionsInterface } from '../../streams/subdivisions/subdivisionInterface';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { referenceAccessControlOperation } from 'resourcePermissions/accessControlHelpers/reference';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { ActiveScenarioState } from 'scope/types';
import { componentUtils } from '@ardoq/scope-data';

export const getEntityTypeAndVerbFromMergeStep = (mergeStep: MergeStep) => {
  const verb = getVerbFromMergeStep(mergeStep);
  const entityType = getEntityTypeFromMergeStep(mergeStep);

  return { entityType, verb };
};

export const collectionToEntityType: Record<
  ScopeDataCollection,
  APIEntityType
> = {
  [ScopeDataCollection.COMPONENTS]: APIEntityType.COMPONENT,
  [ScopeDataCollection.FIELDS]: APIEntityType.FIELD,
  [ScopeDataCollection.MODELS]: APIEntityType.MODEL,
  [ScopeDataCollection.REFERENCES]: APIEntityType.REFERENCE,
  [ScopeDataCollection.TAGS]: APIEntityType.TAG,
  [ScopeDataCollection.PERMISSIONS]: APIEntityType.PERMISSION,
};

export const entityTypeToCollection: Record<
  APIEntityType,
  ScopeDataCollection
> = Object.entries(collectionToEntityType).reduce(
  (acc, [scopeDataCollection, entityType]) => ({
    ...acc,
    [entityType]: scopeDataCollection,
  }),
  {} as Record<APIEntityType, ScopeDataCollection>
);

export const collectRelatedReferenceIds = (
  componentId: ArdoqId,
  graphData: GraphModelShape
) => {
  return uniq([
    ...(graphData.sourceMap.get(componentId) || []).map(
      ({ referenceId }) => referenceId
    ),
    ...(graphData.targetMap.get(componentId) || []).map(
      ({ referenceId }) => referenceId
    ),
  ]);
};

export const getComponentIdsAffectedByFieldDeletion = (
  field: APIFieldAttributes,
  enhancedScopeData: EnhancedScopeData
) =>
  enhancedScopeData[ScopeDataCollection.COMPONENTS]
    .filter(
      (component: APIComponentAttributes) =>
        enhancedScopeData.typesByModelId[field.model].componentTypes.includes(
          component.typeId
        ) && component[field.name]
    )
    .map(({ _id }) => _id);

export const getReferenceIdsAffectedByFieldDeletion = (
  field: APIFieldAttributes,
  enhancedScopeData: EnhancedScopeData
) =>
  // gets a list of ids for all references that use this field on the target branch
  enhancedScopeData[ScopeDataCollection.REFERENCES]
    .filter((reference: APIReferenceAttributes) => {
      const { typeId } = getEntityModelAndTypeID(
        APIEntityType.REFERENCE,
        reference._id,
        enhancedScopeData
      );
      return (
        typeId &&
        enhancedScopeData.typesByModelId[field.model].referenceTypes.includes(
          typeId
        ) &&
        reference[field.name]
      );
    })
    .map(({ _id }) => _id);

export const getEntitiesAffectedByFieldDeletion = (
  fieldId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  const field = getEntityById(APIEntityType.FIELD, fieldId, enhancedScopeData);
  if (!field) return { componentIds: [], referenceIds: [] };
  const componentIds = getComponentIdsAffectedByFieldDeletion(
    field,
    enhancedScopeData
  );
  const referenceIds = getReferenceIdsAffectedByFieldDeletion(
    field,
    enhancedScopeData
  );
  return { componentIds, referenceIds };
};

export const getEntitiesAffectedByTagDeletion = (
  tagId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  const tag = getEntityById(APIEntityType.TAG, tagId, enhancedScopeData);
  return {
    componentIds: !tag ? [] : tag.components,
    referenceIds: !tag ? [] : tag.references,
  };
};

export const isFieldInUseOnTarget = (
  fieldId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  const { componentIds, referenceIds } = getEntitiesAffectedByFieldDeletion(
    fieldId,
    enhancedScopeData
  );
  return Boolean(componentIds.length || referenceIds.length);
};

// returns a path of the component represented as dot separated string
// like: rootComponentId.[...].componentId
export const getComponentAncestorsPath = (
  componentId: string | null,
  scopeData: EnhancedScopeData
): string[] => {
  if (componentId === null) return [];
  const component = getComponentById(componentId, scopeData);
  if (!component) return [];
  return [
    ...getComponentAncestorsPath(component.parent, scopeData),
    componentId,
  ].filter(Boolean);
};

export const getModelTypeByComponentId = (
  componentId: ArdoqId,
  scopeData: EnhancedScopeData
) => {
  const component = getComponentById(componentId, scopeData);
  if (!component) {
    logError(Error('Component cannot be found'));
    return null;
  }
  const model = component.model && scopeData.modelsById[component.model];
  if (!model) {
    logError(Error('Model cannot be found'));
    return null;
  }

  return model.flexible
    ? ModelStructureType.FLEXIBLE
    : ModelStructureType.RIGID;
};

const getWorkspaceIdByModelId = (
  modelId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) =>
  enhancedScopeData.workspaces.find(
    ({ componentModel: componentModelId }) => componentModelId === modelId
  )?._id || null;

const getWorkspaceIdByEntity = (
  entityId: ArdoqId,
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeData,
  parentEntityId?: ArdoqId
) => {
  if (entityType === APIEntityType.COMPONENT) {
    return enhancedScopeData.componentsById[entityId]?.rootWorkspace || null;
  }
  if (entityType === APIEntityType.MODEL) {
    return getWorkspaceIdByModelId(entityId, enhancedScopeData);
  }
  if (entityType === APIEntityType.REFERENCE_TYPE) {
    if (!parentEntityId) {
      logError(Error('Cannot find referenceType without parentEntityId'));
      return null;
    }
    return getWorkspaceIdByModelId(parentEntityId, enhancedScopeData);
  }
  if (entityType === APIEntityType.FIELD) {
    const modelId = enhancedScopeData.fieldsById[entityId]?.model;
    return modelId ? getWorkspaceIdByModelId(modelId, enhancedScopeData) : null;
  }
  if (entityType === APIEntityType.TAG) {
    return enhancedScopeData.tagsById[entityId]?.rootWorkspace || null;
  }

  logError(Error(`Cannot find workspaceId for entity type`), null, {
    entityType,
  });
  return null;
};

export const getWorkspaceByEntity = (
  entityId: ArdoqId,
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeData,
  parentEntityId?: ArdoqId
) => {
  const workspaceId = getWorkspaceIdByEntity(
    entityId,
    entityType,
    enhancedScopeData,
    parentEntityId
  );
  if (!workspaceId) return null;
  return enhancedScopeData.workspacesById[workspaceId] || null;
};

const getComponentById = (componentId: ArdoqId, scope: EnhancedScopeData) =>
  getEntityById(APIEntityType.COMPONENT, componentId, scope) as
    | APIComponentAttributes
    | undefined;

export const findFirstCommonAncestor = (
  componentId: ArdoqId,
  branchOffScope: EnhancedScopeData,
  sourceScope: EnhancedScopeData
) => {
  const ancestorsPath = getComponentAncestorsPath(componentId, sourceScope);
  const firstCommonAncestorId = ancestorsPath.reverse().find(
    // find first common ancestor with branchOff
    ancestorId =>
      ancestorId !== componentId &&
      Boolean(getComponentById(ancestorId, branchOffScope))
  );

  return firstCommonAncestorId || null;
};

export const getStructuralConflict = (
  componentId: ArdoqId,
  entityType: APIEntityType,
  enhancedDiffContextData: EnhancedDiffScopeData
): StructuralConflict => {
  if (entityType !== APIEntityType.COMPONENT) return StructuralConflict.NONE;

  const { branchOff, sourceBranch, targetBranch } = enhancedDiffContextData;

  const firstCommonAncestorId = findFirstCommonAncestor(
    componentId,
    branchOff,
    sourceBranch
  );

  // If there is no common ancestor between source and branch-off
  // then there is no chance for conflict
  if (!firstCommonAncestorId) return StructuralConflict.NONE;

  // If first common ancestor cannot be found on target then
  // we have `MISSING_ANCESTOR` conflict
  if (!getComponentById(firstCommonAncestorId, targetBranch)) {
    return StructuralConflict.MISSING_ANCESTOR;
  }

  if (
    // just to make sure we are not comparing 2 times undefined
    getComponentById(firstCommonAncestorId, sourceBranch) &&
    // compare the type of the same component between source and target
    // if the type has changed we have conflict
    getComponentById(firstCommonAncestorId, sourceBranch)?.type !==
      getComponentById(firstCommonAncestorId, targetBranch)?.type &&
    getModelTypeByComponentId(firstCommonAncestorId, targetBranch) ===
      ModelStructureType.RIGID
  ) {
    return StructuralConflict.RIGID_MODEL_PARENT_TYPE_HAS_CHANGED;
  }

  return StructuralConflict.NONE;
};

export const collectRelatedReferencesAndComponents = (
  entityId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  const { graphData } = enhancedScopeData;
  const referenceIds = collectRelatedReferenceIds(entityId, graphData);
  const componentIds = componentUtils.getDescendantIds(
    enhancedScopeData,
    entityId
  );
  return { referenceIds, componentIds };
};
export const resolveComponentsPath = (
  componentId: ArdoqId | null | undefined,
  enhancedScopeData: EnhancedScopeData
): APIComponentAttributes[] => {
  const component =
    componentId && enhancedScopeData.componentsById[componentId];
  if (!component) return [];
  return [
    ...resolveComponentsPath(component.parent, enhancedScopeData),
    component,
  ];
};

type ResolvedConflictAncestors = {
  sourceConflictAncestors: AncestorItem[];
  targetConflictAncestors: AncestorItem[];
};

const resolveSourceConflictAncestors = (
  componentId: ArdoqId,
  enhancedDiffContextData: EnhancedDiffScopeData
) =>
  resolveComponentsPath(
    componentId,
    enhancedDiffContextData[Branch.SOURCE]
  ).map(sourceComponent => {
    const sourceComponentId = sourceComponent._id;
    const branchOffComponent =
      enhancedDiffContextData[Branch.BRANCH_OFF].componentsById[
        sourceComponentId
      ];
    const componentExistsOnBranchOff = Boolean(branchOffComponent);
    const sourceAndBranchOffComponentTypeIdentical =
      componentExistsOnBranchOff &&
      sourceComponent.typeId === branchOffComponent.typeId;

    return {
      componentId: sourceComponentId,
      ancestorItemType: componentExistsOnBranchOff
        ? sourceAndBranchOffComponentTypeIdentical
          ? AncestorItemType.COMPONENT
          : AncestorItemType.UPDATED_COMPONENT
        : AncestorItemType.CREATED_COMPONENT,
    } as ExistingAncestorItem;
  });

const resolveTargetConflictAncestors = (
  sourceConflictAncestors: ExistingAncestorItem[],
  enhancedDiffContextData: EnhancedDiffScopeData
) => {
  const targetEnhancedScopeData = enhancedDiffContextData[Branch.TARGET];
  const firstCommonAncestor = findLast(
    sourceConflictAncestors,
    ({ componentId }) =>
      componentId &&
      Boolean(targetEnhancedScopeData.componentsById[componentId])
  ) as ExistingAncestorItem;
  return resolveComponentsPath(
    firstCommonAncestor?.componentId,
    targetEnhancedScopeData
  )
    .map(component => {
      const branchOffComponent =
        enhancedDiffContextData[Branch.BRANCH_OFF].componentsById[
          component._id
        ];
      const targetComponent =
        enhancedDiffContextData[Branch.TARGET].componentsById[component._id];

      const componentExistsOnBranchOff = Boolean(branchOffComponent);
      const componentExistsOnTarget = Boolean(targetComponent);
      const targetAndBranchOffComponentTypeIdentical =
        componentExistsOnBranchOff &&
        targetComponent.typeId === branchOffComponent.typeId;

      return {
        componentId: component._id,
        ancestorItemType: componentExistsOnBranchOff
          ? targetAndBranchOffComponentTypeIdentical
            ? componentExistsOnTarget
              ? AncestorItemType.COMPONENT
              : AncestorItemType.DELETED_COMPONENT
            : AncestorItemType.UPDATED_COMPONENT
          : AncestorItemType.CREATED_COMPONENT,
      } as AncestorItem;
    })
    .concat(
      sourceConflictAncestors
        .map(({ ancestorItemType, componentId }) => {
          if (ancestorItemType === AncestorItemType.CREATED_COMPONENT) {
            return {
              label: readRawValue(
                APIEntityType.COMPONENT,
                componentId,
                'name',
                enhancedDiffContextData[Branch.SOURCE]
              ),
              ancestorItemType: AncestorItemType.PLACEHOLDER,
            };
          } else if (
            !enhancedDiffContextData[Branch.TARGET].componentsById[componentId]
          ) {
            return {
              componentId,
              ancestorItemType: AncestorItemType.DELETED_COMPONENT,
            };
          }
          return null;
        })
        .filter(ExcludeFalsy) as AncestorItem[]
    );
};

export const resolveConflictAncestors = ({
  componentId,
  enhancedDiffContextData,
}: {
  componentId: ArdoqId;
  enhancedDiffContextData: EnhancedDiffScopeData;
}): ResolvedConflictAncestors => {
  const sourceConflictAncestors = resolveSourceConflictAncestors(
    componentId,
    enhancedDiffContextData
  );
  const targetConflictAncestors = resolveTargetConflictAncestors(
    sourceConflictAncestors,
    enhancedDiffContextData
  );
  return { sourceConflictAncestors, targetConflictAncestors };
};

const getEntityWorkspace = (
  entityId: ArdoqId,
  entityType: APIEntityType,
  enhancedDiffContextData: EnhancedDiffContextData,
  parentEntityId?: ArdoqId
) => {
  // if the entity is a field, we need to get the model first and then use that to find the right workspace. If not then we can use "rootWorkspace" with readRawValue()
  const branch = getBranchWhereEntityExists(
    entityType,
    entityId,
    enhancedDiffContextData,
    Branch.SOURCE,
    parentEntityId
  )!;
  if (!branch) return null;

  if (entityType === APIEntityType.FIELD) {
    const modelId = readRawValue(
      entityType,
      entityId,
      'model',
      enhancedDiffContextData[branch],
      parentEntityId
    );
    return enhancedDiffContextData[branch].workspaces.find(
      ({ componentModel }) => componentModel === modelId
    );
  }
  return readRawValue(
    entityType,
    entityId,
    'rootWorkspace',
    enhancedDiffContextData[branch],
    parentEntityId
  );
};

export const hasMergePermission = (
  entityId: ArdoqId,
  entityType: APIEntityType,
  enhancedDiffContextData: EnhancedDiffContextData,
  parentEntityId?: ArdoqId
): boolean => {
  if (process.env.NODE_ENV === 'test' || window.STORYBOOK_ENV) {
    return true;
  }

  const permissionContext = currentUserInterface.getPermissionContext();
  const activeScenarioState = getActiveScenarioState();

  if (mergeState$.state.mergeDirection === MergeDirection.MAINLINE_TO_BRANCH) {
    return scenarioAccessControlInterface.canEditActiveScenario(
      permissionContext,
      // we can safely cast here since we know that the scenario is active
      getActiveScenarioState() as ActiveScenarioState
    );
  }

  const entityOnMain = getEntityById(
    entityType,
    entityId,
    enhancedDiffContextData[Branch.MAIN]
  );

  if (
    !(
      entityType === APIEntityType.COMPONENT ||
      entityType === APIEntityType.REFERENCE
    ) ||
    !entityOnMain // If the entity doesn't exist on main, we have to check for edit permissions on the workspace
  ) {
    const entityWorkspaceId = getEntityWorkspace(
      entityId,
      entityType,
      enhancedDiffContextData,
      parentEntityId
    );
    return workspaceAccessControlInterface.canEditWorkspace(
      permissionContext,
      entityWorkspaceId,
      activeScenarioState
    );
  }

  const subdivisionsContext =
    subdivisionsInterface.getSubdivisionsStreamState();

  return Boolean(
    isComponent(entityOnMain)
      ? componentAccessControlOperation.canEditComponent({
          permissionContext,
          component: entityOnMain,
          subdivisionsContext,
        })
      : isReference(entityOnMain) &&
          referenceAccessControlOperation.canEditReference({
            permissionContext,
            reference: entityOnMain,
            subdivisionsContext,
          })
  );
};

export type DiffMergeComponentTypeHierarchy = Record<
  ArdoqId,
  { parent: ArdoqId | null; uniqueId: ArdoqId; children?: ArdoqId[] }
>;

type ComponentParentChildHierarchy = Record<ArdoqId, ArdoqId[]>;

export const recursivelySortHierarchy = (
  entityId: ArdoqId,
  hierarchy: DiffMergeComponentTypeHierarchy | ComponentParentChildHierarchy,
  entityType: APIEntityType.COMPONENT_TYPE | APIEntityType.COMPONENT
): ArdoqId[] => [
  entityType === APIEntityType.COMPONENT_TYPE
    ? (hierarchy as DiffMergeComponentTypeHierarchy)[entityId].uniqueId
    : entityId,
  ...(
    (entityType === APIEntityType.COMPONENT_TYPE
      ? (hierarchy as DiffMergeComponentTypeHierarchy)[entityId].children
      : (hierarchy as ComponentParentChildHierarchy)[entityId]) ?? []
  ).flatMap(entityId =>
    recursivelySortHierarchy(entityId, hierarchy, entityType)
  ),
];
