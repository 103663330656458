export enum Level {
  ODD,
  EVEN,
}

export type MenuItemProps = {
  trackingLabel: string;
  title?: string;
  name?: string;
  level?: Level;
  disabled?: boolean;
  hidden?: boolean;
  submenu?: MenuItemProps[];
  onClick?: () => void;
  dataClickId?: string;
  isNewFeature?: boolean;
  isBetaFeature?: boolean;
  hasNewChanges?: boolean;
  isExternalLink?: boolean;
};
