import { TraversalError, TraversalErrorReason } from './buildState';
import {
  isCreatedItemsLoadedState,
  isManualComponentSelection,
  LoadedStateParams,
  QueryBuilderQuery,
  QueryBuilderSubquery,
} from '@ardoq/api-types';
import { logError } from '@ardoq/sentry';

const queryHasReferenceRule = (
  query: QueryBuilderQuery | QueryBuilderSubquery
): boolean =>
  query.rules.reduce((result, subQueryOrRule) => {
    if (result) return result;
    return 'rules' in subQueryOrRule
      ? queryHasReferenceRule(subQueryOrRule)
      : ['has-reference', 'has-no-reference'].includes(subQueryOrRule.field);
  }, false);

const hasReferenceRule = (loadedStates: LoadedStateParams[]) => {
  return loadedStates.some(loadedState => {
    if (isCreatedItemsLoadedState(loadedState)) {
      return false;
    }
    const { componentSelection } = loadedState.data;
    if (isManualComponentSelection(componentSelection)) {
      return false;
    }
    return queryHasReferenceRule(componentSelection.startQuery);
  });
};
export const logBuildLoadedStateError = (
  error: TraversalError,
  loadedStates: LoadedStateParams[]
) => {
  if (error.reason === TraversalErrorReason.ABORTED) {
    return;
  }
  /*
   * This is added to log any errors related to has-reference or has-no-reference
   * rules, which could be caused by a bug fixed in encoding/decoding v7. This should
   * now, theoretically, only happen if a slide with a reference-rule was saved
   * before v7 and is now loaded.
   */
  if (hasReferenceRule(loadedStates)) {
    logError(Error('Traversal error: invalid has-reference rule in query'));
    return;
  }
  logError(Error(error.error));
};
