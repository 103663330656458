export enum SalesDemoStep {
  INTRO = 'intro',
  STEP_2 = 'step-2',
}

export type SalesDemoStepPayload = { salesDemoStep: SalesDemoStep };

export type StepDetails = {
  menuLabel: string;
  trackingLabel: string;
  tourId: number;
  preTourAction?: () => any;
};
