import integrationViewState$ from 'integrations/integrationViewState$';
import { map } from 'rxjs';
import { getRouteFromString } from './utils';
import { RoutePath } from 'integrations/common/navigation/types';

export const navigation$ = integrationViewState$.pipe(
  map(({ integrationPath }) => {
    return {
      route: getRouteFromString(integrationPath as RoutePath),
    };
  })
);
