import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { colors, spacing } from '@ardoq/design-tokens';
import { fontMixins } from '@ardoq/typography';
import styled, { css } from 'styled-components';
import BroadcastName from './BroadcastName';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import broadcast$ from 'broadcasts/broadcast$';
import {
  initiateSavingCurrentBroadcast,
  updateBroadcastName,
} from 'broadcasts/actions';
import { debounce, isEqual } from 'lodash';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BroadcastStreamShape } from 'broadcasts/types';
import { TextInput } from '@ardoq/forms';
import { hasFeature, Features } from '@ardoq/features';

type EditableBroadcastNameProps = {
  broadcastName: string | null;
  hasNewJourneyFeature: boolean;
};

const Wrapper = styled.div<{ $hasNewJourneyFeature: boolean }>`
  display: flex;
  overflow: hidden;
  align-items: center;

  ${({ $hasNewJourneyFeature }) =>
    !$hasNewJourneyFeature &&
    css`
      margin-left: ${spacing.s8};
      ${fontMixins.normal20};
      color: ${colors.grey15};
    `}
`;

const StyledTextInput = styled(TextInput)<{ $hasNewJourneyFeature: boolean }>`
  display: inline-block;
  width: 600px;

  input {
    box-shadow: none !important;
    padding: 0;
    &::placeholder {
      color: ${colors.grey15};
    }
  }

  ${({ $hasNewJourneyFeature }) =>
    !$hasNewJourneyFeature &&
    css`
      margin-left: ${spacing.s8};
      ${fontMixins.normal20};
    `}

  @media (max-width: 1280px) {
    width: 391px;
  }
  @media (max-width: 1024px) {
    width: 280px;
  }
`;

const EditableBroadcastName = ({
  broadcastName,
  hasNewJourneyFeature,
}: EditableBroadcastNameProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentBroadcastName, setCurrentBroadcastName] = useState(
    broadcastName ?? ''
  );
  const [initialBroadcastName, setInitialBroadcastName] =
    useState(currentBroadcastName);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateBroadcastName = useCallback(
    debounce((name: string) => dispatchAction(updateBroadcastName(name)), 750),
    []
  );

  const handleNameSave = useCallback(() => {
    debouncedUpdateBroadcastName.cancel();
    setIsEditing(false);
    setInitialBroadcastName(currentBroadcastName);
    if (!currentBroadcastName.length) {
      return;
    }
    dispatchAction(updateBroadcastName(currentBroadcastName));
    dispatchAction(initiateSavingCurrentBroadcast());
  }, [currentBroadcastName, debouncedUpdateBroadcastName]);

  const handleNameCancel = useCallback(() => {
    debouncedUpdateBroadcastName.cancel();
    setIsEditing(false);
    dispatchAction(updateBroadcastName(initialBroadcastName));
    setCurrentBroadcastName(initialBroadcastName);
  }, [initialBroadcastName, debouncedUpdateBroadcastName]);

  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      if (isEditing) {
        if (e.key === 'Escape') {
          handleNameCancel();
        }
        if (e.key === 'Enter') {
          handleNameSave();
        }
      }
    };

    document.addEventListener('keydown', keyListener);

    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  }, [isEditing, handleNameCancel, handleNameSave]);

  useEffect(() => {
    setCurrentBroadcastName(broadcastName ?? '');
  }, [broadcastName]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    setCurrentBroadcastName(name);
    debouncedUpdateBroadcastName(name);
  };

  return (
    <Wrapper $hasNewJourneyFeature={hasNewJourneyFeature}>
      {hasNewJourneyFeature ? '' : '-'}
      {isEditing ? (
        <StyledTextInput
          $hasNewJourneyFeature={hasNewJourneyFeature}
          placeholder="Untitled Broadcast"
          onChange={handleNameChange}
          value={currentBroadcastName}
          autoFocus
          onBlur={handleNameSave}
          dataTestId="broadcast-name-input"
        />
      ) : (
        <BroadcastName
          onClick={() => setIsEditing(true)}
          broadcastName={broadcastName}
          hasNewJourneyFeature={hasNewJourneyFeature}
        />
      )}
    </Wrapper>
  );
};

const toProps = (state: BroadcastStreamShape) => ({
  broadcastName: state.currentBroadcast?.name || null,
  hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
});

export default connect(
  EditableBroadcastName,
  broadcast$.pipe(map(toProps), distinctUntilChanged(isEqual))
);
