import {
  SimplifiedSubdivisionSummary,
  SubdivisionSummary,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

const DOMAIN = '[subdivisionSummary$]';

export const fetchSubdivisionSummary = actionCreator<string>(
  `${DOMAIN} FETCH_SUBDIVISION_SUMMARY`
);

export const fetchSubdivisionSummarySuccess = actionCreator<SubdivisionSummary>(
  `${DOMAIN} FETCH_SUBDIVISION_SUMMARY_SUCCESS`
);

export const fetchSubdivisionSummaryFailed = actionCreator<string>(
  `${DOMAIN} FETCH_SUBDIVISION_SUMMARY_FAILED`
);

export const fetchSimplifiedSubdivisionSummary = actionCreator<string>(
  `${DOMAIN} FETCH_SIMPLIFIED_SUBDIVISION_SUMMARY`
);

export const fetchSimplifiedSubdivisionSummarySuccess =
  actionCreator<SimplifiedSubdivisionSummary>(
    `${DOMAIN} FETCH_SIMPLIFIED_SUBDIVISION_SUMMARY_SUCCESS`
  );

export const fetchSimplifiedSubdivisionSummaryFailed = actionCreator<string>(
  `${DOMAIN} FETCH_SIMPLIFIED_SUBDIVISION_SUMMARY_FAILED`
);
