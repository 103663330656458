import { action$, ofType } from '@ardoq/rxbeach';
import { getQuickPerspectivesOptions } from './quickPerspectivesContext';
import Filters from 'collections/filters';
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  startWith,
  withLatestFrom,
} from 'rxjs/operators';
import {
  notifyFilterContextChanged,
  notifyFiltersChanged,
} from 'streams/filters/FilterActions';
import { notifyModelChanged } from 'streams/models/actions';
import { notifyWorkspaceNameChanged } from 'streams/workspaces/actions';
import { notifyWorkspaceOpened } from 'streams/context/ContextActions';
import {
  isUpdatingAppState$,
  setIsUpdatingAppState,
} from '../isUpdatingAppState$';
import { startAction } from 'actions/utils';
import { QuickPerspectivesContext } from './legends/types';
import { context$ } from 'streams/context/context$';
import { ContextShape } from '@ardoq/data-model';

const resetQuickPerspectiveOptions = ([, context]: [
  any,
  ContextShape,
]): QuickPerspectivesContext => {
  const contextComponentId = context.componentId;
  const componentIdSet = new Set(
    contextComponentId ? [contextComponentId] : []
  );
  return getQuickPerspectivesOptions({
    contextWorkspaceId: context.workspaceId,
    componentIdSet,
    openWorkspaceIds: context.workspacesIds,
    selectedWorkspacesIds: Filters.getWorkspaceIdsInWorkspaceFilter(),
    connectedWorkspaceIds: context.connectedWorkspaceIds,
  });
};

const quickPerspectivesContext$ = action$.pipe(
  ofType(
    notifyFiltersChanged,
    notifyFilterContextChanged,
    notifyModelChanged,
    notifyWorkspaceNameChanged,
    setIsUpdatingAppState,
    notifyWorkspaceOpened
  ),
  debounceTime(50),
  filter(() => !isUpdatingAppState$.state),
  startWith(startAction()),
  withLatestFrom(context$),
  map(resetQuickPerspectiveOptions),
  shareReplay({ bufferSize: 1, refCount: true })
);

export default quickPerspectivesContext$;
