import { APIOrganizationUser, PermissionGroup } from '@ardoq/api-types';
import { ButtonGroup, DangerButton, SecondaryButton } from '@ardoq/button';
import { ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { ErrorNotification, WarningNotification } from '@ardoq/status-ui';
import { InlineText, Link, Paragraph } from '@ardoq/typography';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  removeUsersFromGroup,
  removeUsersFromGroupSuccess,
  removeUsersFromGroupFailed,
  removeUsersFromGroupConfirmationModalOpened,
} from './actions';
import removeUsersViewModel$, {
  RemoveUsersViewModel$State,
} from './removeUsersViewModel$';
import { useEffectOnce } from '@ardoq/hooks';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { PermissionGroupPageEvents } from '../../types';
import { Stack } from '@ardoq/layout';
import { TextInput } from '@ardoq/forms';
import { useState } from 'react';

type ConfirmRemoveUserModalProps = {
  user: APIOrganizationUser;
  group: PermissionGroup;
  closeModal: () => void;
  trackUserEvent: (event: PermissionGroupPageEvents) => void;
} & RemoveUsersViewModel$State;

const ConfirmRemoveUserModal = ({
  user,
  group,
  closeModal,
  apiErrorMessage,
  isSubmitting,
  trackUserEvent,
}: ConfirmRemoveUserModalProps) => {
  useEffectOnce(() => {
    dispatchAction(removeUsersFromGroupConfirmationModalOpened());
  });
  const [confirmation, setConfirmation] = useState('');
  const userHasSSOMembership = !!group.membershipFromSso?.includes(user._id);
  const canRemove =
    !userHasSSOMembership || confirmation.toLocaleLowerCase() === 'yes';

  const handleSubmit = async () => {
    trackUserEvent(PermissionGroupPageEvents.REMOVE_USER_FROM_GROUP);
    const result = await dispatchActionAndWaitForResponse(
      removeUsersFromGroup({
        userIds: [user._id],
        permissionGroupId: group._id,
        force: userHasSSOMembership,
      }),
      removeUsersFromGroupSuccess,
      removeUsersFromGroupFailed
    );

    if (result.type === removeUsersFromGroupSuccess.type) {
      closeModal();
    }
  };

  return (
    <ModalTemplate
      headerText="Remove member"
      footerContent={
        <ButtonGroup>
          <SecondaryButton
            onClick={() => {
              trackUserEvent(
                PermissionGroupPageEvents.CANCEL_REMOVE_USER_FROM_GROUP
              );
              closeModal();
            }}
          >
            Cancel
          </SecondaryButton>
          <DangerButton
            onClick={() => canRemove && handleSubmit()}
            isLoading={isSubmitting}
            isDisabled={isSubmitting || !canRemove}
          >
            Remove
          </DangerButton>
        </ButtonGroup>
      }
      modalSize={ModalSize.S}
      onCloseButtonClick={() => {
        trackUserEvent(PermissionGroupPageEvents.CANCEL_REMOVE_USER_FROM_GROUP);
        closeModal();
      }}
    >
      <ModalLayout>
        <Paragraph variant="text2">
          You are about to remove{' '}
          <InlineText variant="text2Bold">{user.name}</InlineText> from the
          group <InlineText variant="text2Bold">{group.name}</InlineText>.
        </Paragraph>
        <Paragraph variant="text2">
          This action won&apos;t delete the user but may revoke their access to
          assets shared with this group.
        </Paragraph>
        {userHasSSOMembership && (
          <WarningNotification>
            <Stack gap={'medium'}>
              <Paragraph variant="text2">
                <strong>
                  Group membership for this user is managed by SSO.
                </strong>
              </Paragraph>
              <Paragraph>
                This may be <strong>temporary</strong>, depending on your SSO
                Attribute Mapping settings and your SSO configuration. Unless
                these configurations have also been updated, the user might be
                re-added to this group on their next login.
              </Paragraph>
              <Paragraph variant="text2">
                Learn more about{' '}
                <Link
                  href="https://help.ardoq.com/en/articles/45268-sso-attribute-mapping"
                  target="_blank"
                >
                  <InlineText variant="text2">SSO Attribute Mapping</InlineText>
                </Link>
              </Paragraph>
            </Stack>
          </WarningNotification>
        )}
        {apiErrorMessage && (
          <ErrorNotification>{apiErrorMessage}</ErrorNotification>
        )}
        {userHasSSOMembership && (
          <TextInput
            label={`Type "Yes" to remove ${user.name} from ${group.name}:`}
            name="confirmRemoveUser"
            placeholder="Yes"
            value={confirmation}
            onValueChange={setConfirmation}
          ></TextInput>
        )}
      </ModalLayout>
    </ModalTemplate>
  );
};

export default connect(ConfirmRemoveUserModal, removeUsersViewModel$);
