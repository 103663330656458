import { useState } from 'react';
import {
  ConsentManagementModal,
  FormValues,
  Frequency,
  consentTypeSelection,
} from './ConsentManagementModal';
import CurrentUser from '../models/currentUser';
import { PersonalSetting } from '@ardoq/api-types';
import { modal } from '@ardoq/modal';
import { useEffectOnce } from '@ardoq/hooks';
import { logError } from '@ardoq/logging';
import { isOnPremise } from 'appConfig';
import { userApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';

type ConnectedConsentManagementModalProps = {
  onClose: () => void;
};

const ConnectedConsentManagementModal = ({
  onClose,
}: ConnectedConsentManagementModalProps) => {
  const [consentFormData, setConsentFormData] = useState({
    digest:
      CurrentUser.getPersonalSetting(PersonalSetting.DEFAULT).digest ||
      Frequency.WEEKLY,
    isInProgress: false,
    isLoading: true,
    error: false,
    emailPreferences: consentTypeSelection,
  });

  useEffectOnce(() => {
    userApi.getConsent(CurrentUser.id).then(consent => {
      if (isArdoqError(consent)) {
        setConsentFormData(prev => ({
          ...prev,
          isLoading: false,
          error: true,
        }));
        logError(consent);
      } else {
        setConsentFormData(prev => ({
          ...prev,
          isLoading: false,
          error: false,
          emailPreferences: {
            ...prev.emailPreferences,
            ...consent,
          },
        }));
      }
    });
  });

  const save = async (formValues: FormValues) => {
    setConsentFormData({
      ...consentFormData,
      isInProgress: true,
    });
    const defaultUserSettings = {
      ...CurrentUser.getPersonalSetting(PersonalSetting.DEFAULT),
      digest: formValues.frequency,
    };

    const responseConsent = await userApi.updateConsent(CurrentUser.id, {
      ...formValues.emailPreferences,
    });

    if (isArdoqError(responseConsent)) {
      return setConsentFormData(prev => ({
        ...prev,
        error: true,
        isInProgress: false,
      }));
    }

    await CurrentUser.setPersonalSetting(
      PersonalSetting.DEFAULT,
      defaultUserSettings
    );

    setConsentFormData(prev => ({ ...prev, isInProgress: false }));
    onClose();
  };

  return (
    <ConsentManagementModal
      isLoading={consentFormData.isLoading}
      error={consentFormData.error}
      frequency={consentFormData.digest as Frequency}
      emailPreferences={consentFormData.emailPreferences}
      onCloseModal={() => onClose()}
      onSaveEmailPreferences={async formValues => {
        await save(formValues);
      }}
      getIsOnPremise={isOnPremise}
    />
  );
};

export const openConsentManagement = async () => {
  await modal(
    resolve => (
      <ConnectedConsentManagementModal onClose={() => resolve(false)} />
    ),
    {
      shouldCloseOnBackdropClick: false,
      shouldCloseOnEscapeKey: false,
    }
  );
};
