import { APIScopeData } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const scopeDataReset = actionCreator(
  `[enhancedScopeData] SCOPE_DATA_RESET`
);

export const scopeDataLoaded = actionCreator<APIScopeData>(
  `[enhancedScopeData] SCOPE_DATA_LOADED`
);
