import Node from './node';
import { HEIGHT_WORKSPACE_ITEM, NodeModelTypes } from '../utils/consts';
import { NodeModel } from './types';
import type Tree from './tree';
import { DiffMode } from '@ardoq/api-types';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

class ScenarioNode extends Node {
  private _id: string;
  private _children: NodeModel[] = [];
  private _workspaceChildrenIds: string[];

  constructor(tree: Tree, id: string, childrenIds: string[]) {
    super(tree, null);
    this._id = id;
    this._workspaceChildrenIds = childrenIds;
    this.updateChildren();
  }

  override get id() {
    return this._id;
  }

  override get hasWriteAccess() {
    return true;
  }

  override get type() {
    return NodeModelTypes.SCENARIO;
  }

  override isFlexibleModel() {
    return false;
  }

  getChildren() {
    return this._children;
  }

  override getItemHeight() {
    return HEIGHT_WORKSPACE_ITEM;
  }

  override getWorkspaceId() {
    return '';
  }

  override getOrder() {
    return 0;
  }

  hasChildren() {
    return Boolean(this._workspaceChildrenIds.length);
  }

  updateChildren() {
    const tree = this.tree;
    if (tree === null) return;
    this._children = this._workspaceChildrenIds.map(workspaceId =>
      tree.getOrCreateNode(tree, workspaceId, this)
    );
  }

  getName() {
    const { activeScenarioState } = this.tree;
    if (activeScenarioOperations.isInDiffMode(activeScenarioState)) {
      switch (activeScenarioOperations.getDiffMode(activeScenarioState)) {
        case DiffMode.MAIN:
          return 'Mainline';

        case DiffMode.DIFF:
          return 'Diff';
      }
    }

    return this.tree.navigatorViewInterface.getScenarioName(this.id) ?? '';
  }
}

export default ScenarioNode;
