import {
  selectAndLoadSlide,
  loadSelectedSlideIfNotAlreadyLoaded,
} from './actions';
import * as presentationUtil from 'presentationEditor/presentationUtil';
import { tap, withLatestFrom } from 'rxjs/operators';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  setLoadedSlideId,
  setSelectedSlideId,
} from 'streams/context/ContextActions';
import { selectedSlide$ } from 'streams/selectedSlide/selectedSlide$';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { contextPresentation$ } from 'streams/presentations/contextPresentation$';
import slides$ from 'streams/slides/slides$';

const abortControllers = new Map<string, AbortController>();

const abortOutdatedSlideDataRequests = (currentSlideId: string) => {
  for (const [slideId, controller] of abortControllers) {
    if (slideId !== currentSlideId) {
      controller.abort();
      abortControllers.delete(slideId);
    }
  }
};

const getAbortControllerForSlide = (slideId: string) => {
  let controller = abortControllers.get(slideId);
  if (!controller) {
    controller = new AbortController();
    abortControllers.set(slideId, controller);
  }
  return controller;
};

/**
 * This routine will take care that only data of one single slide can be loaded
 * at a time. Any hanging requests of a different slide will be aborted.
 */
const handleSelectAndLoadSlide = routine(
  ofType(selectAndLoadSlide),
  extractPayload(),
  withLatestFrom(isViewpointMode$, contextPresentation$, slides$),
  tap(async ([slideId, { isViewpointMode }, presentation, slides]) => {
    abortOutdatedSlideDataRequests(slideId);
    dispatchAction(setSelectedSlideId({ selectedSlideId: slideId }));
    const abortController = getAbortControllerForSlide(slideId);
    const slide = slides.byId[slideId];
    if (slide) {
      await presentationUtil.loadSlide(
        slide,
        isViewpointMode,
        presentation?._id,
        abortController.signal
      );
    }
    dispatchAction(setLoadedSlideId({ loadedSlideId: slideId }));
  })
);

const handleLoadSelectedSlideIfNotAlreadyLoaded = routine(
  ofType(loadSelectedSlideIfNotAlreadyLoaded),
  withLatestFrom(selectedSlide$),
  tap(async ([, { selectedSlideId }]) => {
    if (!selectedSlideId) return;
    if (selectedSlideId !== selectedSlide$.state.loadedSlideId) {
      dispatchAction(selectAndLoadSlide(selectedSlideId));
    }
  })
);

export default collectRoutines(
  handleSelectAndLoadSlide,
  handleLoadSelectedSlideIfNotAlreadyLoaded
);
