import {
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap } from 'rxjs/operators';
import intercom from 'tracking/intercom';
import { triggerSalesDemoStep } from './actions';
import { TOUR_STEP_DETAILS } from './consts';

const handleTriggerSalesDemoStep = routine(
  ofType(triggerSalesDemoStep),
  extractPayload(),
  tap(({ salesDemoStep }) => {
    const { tourId, preTourAction } = TOUR_STEP_DETAILS[salesDemoStep];
    preTourAction?.();
    intercom.startIntercomTour(tourId);
  })
);

const salesDemoTourLauncherRoutines = collectRoutines(
  handleTriggerSalesDemoStep
);

export default salesDemoTourLauncherRoutines;
