import { UseCasePreviewContentProps } from './types';
import styled from 'styled-components';
import { button } from '@ardoq/typography';
import { Tag } from '@ardoq/status-ui';
import { Subtitle } from './atoms';
import { DASHBOARD_MAX_WIDTH } from '@ardoq/dashboard';
import { CollapsibleIsland } from '@ardoq/page-layout';
import { Inline, Stack } from '@ardoq/layout';

const ShowMore = styled.span`
  ${button}
`;

const UseCasePreviewContent = ({ content }: UseCasePreviewContentProps) => (
  <>
    <Subtitle>
      The solution includes these pre-made assets and materials to help you get
      started and gain value faster. Our solutions guide provides in-depth
      approaches on how to use these assets for achieving your unique business
      needs.
    </Subtitle>
    <Stack gap="medium" justify="flex-start">
      {content.map(({ iconName, label, names }) => (
        <CollapsibleIsland
          iconName={iconName}
          key={label}
          title={`${label} (${names.length})`}
          rightContent={<ShowMore>Show more</ShowMore>}
          maxWidth={`${DASHBOARD_MAX_WIDTH}px`}
        >
          <Inline gap="small">
            {names.map(({ name, onMaterialClick }) => (
              <Tag onClick={onMaterialClick} key={name}>
                {name}
              </Tag>
            ))}
          </Inline>
        </CollapsibleIsland>
      ))}
    </Stack>
  </>
);

export default UseCasePreviewContent;
