import { intersection, isEqual } from 'lodash';
import { AvailableTriples, PartialStep, ValidationMap } from './types';
import {
  getPossibleEndTypeNames,
  getPossibleReferenceTypes,
  getPossibleStartTypeNames,
  getTripleInTraversalOrder,
  hasValidTriple,
  withoutInvalidTriples,
} from './utils';

const isEmptyStartSet = (startSet: Array<string | null>) => {
  return startSet.every(componentTypeName => componentTypeName === null);
};

/**
 * The start of the next step needs to match at least 1 of the target references in the previous step.
 * */
const validateReferencePrevious = (
  steps: PartialStep[],
  validationMap: ValidationMap = {
    tripleValidationMap: new Map(),
    constraintValidationMap: new Map(),
    stepValidationMap: new Map(),
  }
): ValidationMap => {
  steps.forEach((step, stepIndex) => {
    const hasNextStep = stepIndex + 1 < steps.length;
    if (hasNextStep) {
      const nextSources = steps[stepIndex + 1].constraints
        .map(c => getTripleInTraversalOrder(c)[0])
        .filter(t => t);
      const targets = step.constraints.map(
        c => getTripleInTraversalOrder(c)[2]
      );
      const isSourcesInTargets = nextSources.some(s => targets.includes(s));
      const constraints = step.constraints;
      if (!isSourcesInTargets && !isEmptyStartSet(nextSources)) {
        validationMap.stepValidationMap.set([stepIndex].join(), {
          hasError: true,
          errorMessage:
            'The start of the next step needs to match at least 1 of the target references in the previous step.',
        });
        steps[stepIndex + 1].constraints.forEach((_, constraintIndex) => {
          const nextSource = getTripleInTraversalOrder(
            steps[stepIndex + 1].constraints[constraintIndex]
          )[0];
          if (nextSource && !targets.includes(nextSource)) {
            validationMap.tripleValidationMap.set(
              [stepIndex + 1, constraintIndex, 0].join(),
              {
                hasError: true,
              }
            );
          }
        });
        constraints.forEach((_, constraintIndex) => {
          validationMap.tripleValidationMap.set(
            [stepIndex, constraintIndex, 2].join(),
            { hasError: true }
          );
        });
      }
    }
  });

  return validationMap;
};

/**
 * Source and target points can't be the same in 1 step. Split it into 2 steps or form circular rules in 1 step.
 * */
const validateSourceTarget = (
  steps: PartialStep[],
  validationMap: ValidationMap = {
    tripleValidationMap: new Map(),
    constraintValidationMap: new Map(),
    stepValidationMap: new Map(),
  }
): ValidationMap => {
  steps.forEach((step, stepIndex) => {
    const constraints = step.constraints;
    const sources = constraints
      .map(c => getTripleInTraversalOrder(c)[0])
      .filter(t => t);
    const targets = constraints
      .map(c => getTripleInTraversalOrder(c)[2])
      .filter(t => t);
    const isCircularValid = isEqual(new Set(sources), new Set(targets));
    const isDirectedValid = !intersection(sources, targets).length;
    if (!isCircularValid && !isDirectedValid) {
      constraints.forEach((constraint, constraintIndex) => {
        const triple = getTripleInTraversalOrder(constraint);
        constraints.forEach((otherConstraint, otherConstraintIndex) => {
          const otherTriple = getTripleInTraversalOrder(otherConstraint);
          if (
            constraintIndex !== otherConstraintIndex &&
            triple[2] &&
            otherTriple[0] === triple[2]
          ) {
            // Show info if some triples are incomplete
            if (sources.length !== targets.length) {
              validationMap.constraintValidationMap.set([stepIndex, 0].join(), {
                infoMessage:
                  'Same source and target points will break your model, since grouped rules are actioned in parallel. Split it into 2 steps or form a circular rule in 1 step.',
              });
            } else {
              validationMap.constraintValidationMap.set([stepIndex, 0].join(), {
                errorMessage:
                  "Source and target points can't be the same in 1 step. Split it into 2 steps or form circular rules in 1 step.",
              });
              validationMap.tripleValidationMap.set(
                [stepIndex, constraintIndex, 2].join(),
                {
                  hasError: true,
                }
              );
              validationMap.tripleValidationMap.set(
                [stepIndex, otherConstraintIndex, 0].join(),
                {
                  hasError: true,
                }
              );
            }
          }
        });
      });
    }
  });

  return validationMap;
};

/**
 * To make your viewpoint valid, please add at least one triple.
 * */
const validateEmptyStep = (
  steps: PartialStep[],
  validationMap: ValidationMap = {
    tripleValidationMap: new Map(),
    constraintValidationMap: new Map(),
    stepValidationMap: new Map(),
  }
): ValidationMap => {
  steps.forEach((step, stepIndex) => {
    const constraints = step.constraints;
    if (!constraints.length) {
      validationMap.stepValidationMap.set([stepIndex].join(), {
        hasError: true,
        errorMessage:
          'To make your viewpoint valid, please add at least one triple.',
      });
    }
  });

  return validationMap;
};

/**
 * Viewpoints must have all traversal steps completed.
 * */
const validateIncompleteStep = (
  steps: PartialStep[],
  validationMap: ValidationMap = {
    tripleValidationMap: new Map(),
    constraintValidationMap: new Map(),
    stepValidationMap: new Map(),
  },
  _availableTriples: AvailableTriples,
  isSubmitted?: boolean
): ValidationMap => {
  steps.forEach((step, stepIndex) => {
    step.constraints.forEach((constraint, constraintIndex) => {
      constraint.triple.forEach(triple => {
        if (!triple && isSubmitted) {
          validationMap.constraintValidationMap.set(
            [stepIndex, constraintIndex].join(),
            {
              hasError: true,
              errorMessage:
                'Viewpoints must have all traversal steps completed.',
            }
          );
        }
      });
    });
  });

  return validationMap;
};

// viewpoint triples must comprise of existing combinations of source, reference type and target
const validateTripleExists = (
  steps: PartialStep[],
  validationMap: ValidationMap = {
    tripleValidationMap: new Map(),
    constraintValidationMap: new Map(),
    stepValidationMap: new Map(),
  },
  availableTriples: AvailableTriples
) => {
  const validSteps = withoutInvalidTriples(steps, availableTriples.data);
  if (isEqual(validSteps, steps)) return validationMap;

  steps.forEach((step, stepIndex) => {
    step.constraints.forEach((constraint, constraintIndex) => {
      const constraintInOrder = getTripleInTraversalOrder(constraint);
      if (!hasValidTriple(constraint, availableTriples.data)) {
        const isStartTypeValid = getPossibleStartTypeNames(
          availableTriples.data
        ).includes(constraintInOrder[0] ?? '');
        const isReferenceTypeValid = getPossibleReferenceTypes(
          availableTriples.data,
          constraint
        ).some(
          referenceType =>
            referenceType.typeName === constraintInOrder[1] &&
            constraint.direction === referenceType.direction
        );
        const isEndTypeValid = getPossibleEndTypeNames(
          availableTriples.data,
          constraint
        ).includes(constraintInOrder[2] ?? '');
        validationMap.constraintValidationMap.set(
          [stepIndex, constraintIndex].join(),
          {
            hasWarning: true,
            warningMessage: `The triple '${constraintInOrder.join(
              ' - '
            )}' cannot be found within the chosen workspaces. Does this relationship exist?`,
          }
        );
        if (!isStartTypeValid) {
          validationMap.tripleValidationMap.set(
            [stepIndex, constraintIndex, 0].join(),
            {
              hasWarning: true,
            }
          );
        }
        if (!isReferenceTypeValid) {
          validationMap.tripleValidationMap.set(
            [stepIndex, constraintIndex, 1].join(),
            {
              hasWarning: true,
            }
          );
        }
        if (!isEndTypeValid) {
          validationMap.tripleValidationMap.set(
            [stepIndex, constraintIndex, 2].join(),
            {
              hasWarning: true,
            }
          );
        }
      }
    });
  });

  return validationMap;
};

export const validatePartialSteps = (
  steps: PartialStep[],
  availableTriples: AvailableTriples,
  isSubmitted?: boolean
): ValidationMap => {
  const validationMap = {
    tripleValidationMap: new Map(),
    constraintValidationMap: new Map(),
    stepValidationMap: new Map(),
  };

  const validatorFns = [
    validateEmptyStep,
    validateSourceTarget,
    validateReferencePrevious,
    validateIncompleteStep,
    validateTripleExists,
  ];

  for (const validatorFn of validatorFns) {
    const newValidationMap = validatorFn(
      steps,
      validationMap,
      availableTriples,
      isSubmitted
    );
    // We only care about one validate function at a time
    if (Object.values(newValidationMap).some(v => v.size)) {
      return newValidationMap;
    }
  }
  return validationMap;
};
