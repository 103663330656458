import { Space } from '@ardoq/style-helpers';
import { RadioGroupItem } from '@ardoq/forms';
import { ButtonGroup, ButtonSize, GhostButton } from '@ardoq/button';
import { DeleteIcon, EditIcon } from '@ardoq/icons';
import { COLOR_THEME_GROUP_WIDTH, ColorThemeGroup } from '@ardoq/color-picker';
import {
  APIDashboardColorTheme,
  Entity,
  UnpersistedEntity,
} from '@ardoq/api-types';
import styled from 'styled-components';
import { s32 } from '@ardoq/design-tokens';

const ThemeButtons = styled(Space)`
  height: ${s32};
`;

const ColorThemeOptionContainer = styled(Space)`
  max-width: ${COLOR_THEME_GROUP_WIDTH};
`;

type DefaultColorTheme = UnpersistedEntity<APIDashboardColorTheme> & Entity;

type ColorThemeOptionProps =
  | { isDefaultTheme: true; colorTheme: DefaultColorTheme }
  | {
      isDefaultTheme: false;
      colorTheme: APIDashboardColorTheme;
      editTheme: VoidFunction;
      deleteTheme: VoidFunction;
    };

const ColorThemeOption = (props: ColorThemeOptionProps) => (
  <ColorThemeOptionContainer $gap="s16" $isVertical>
    <ThemeButtons $justify="space-between" $align="center">
      <RadioGroupItem value={props.colorTheme._id}>
        {props.colorTheme.label}
      </RadioGroupItem>
      {!props.isDefaultTheme && (
        <ButtonGroup>
          <GhostButton buttonSize={ButtonSize.SMALL} onClick={props.editTheme}>
            <EditIcon />
          </GhostButton>
          <GhostButton
            buttonSize={ButtonSize.SMALL}
            onClick={props.deleteTheme}
          >
            <DeleteIcon />
          </GhostButton>
        </ButtonGroup>
      )}
    </ThemeButtons>
    <ColorThemeGroup paletteColors={props.colorTheme.paletteColors} />
  </ColorThemeOptionContainer>
);

export default ColorThemeOption;
