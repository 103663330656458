import { Features, hasFeature } from '@ardoq/features';
import { dispatchAction } from '@ardoq/rxbeach';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { Route } from 'router/StreamRouter';
import { AppRouterState, SubdivisionRoute } from 'router/appRouterTypes';
import subdivisionNavigation$ from './subdivisionNavigation$';
import { navigateToSubdivision } from 'router/navigationActions';
import { map } from 'rxjs';
import { SubdivisionEditorSteps } from './types';
import { subdivisionNavigationOperations } from './operations';

const PATH_REG = /^\/division\/?.*?/;

const subdivisionRoute = new Route<AppRouterState, SubdivisionRoute>({
  doesLocationMatch: ({ path }) => PATH_REG.test(path),
  locationToRouterState: ({ path }) => {
    const parts = path.split('/');
    const subdivisionId = parts[2];
    const subdivisionEditorStep = parts[3] as SubdivisionEditorSteps;
    const subdivisionEditorSubStep = parts[4];
    const isValidStep =
      subdivisionNavigationOperations.isValidSubdivisionEditorStep(
        subdivisionEditorStep
      );

    if (isValidStep) {
      return {
        appModule: AppModules.SUBDIVISION,
        subdivisionId,
        subdivisionEditorStep,
        subdivisionEditorSubStep,
      };
    }

    return {
      appModule: AppModules.SUBDIVISION,
      subdivisionId,
      subdivisionEditorStep: SubdivisionEditorSteps.DETAILS,
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.SUBDIVISION,
  routerStateToLocation: ({
    subdivisionId,
    subdivisionEditorStep,
    subdivisionEditorSubStep,
  }) => {
    if (!subdivisionId) {
      return {
        path: `/${AppModules.SUBDIVISION}`,
        title: `Subdivision - Overview`,
      };
    }
    const subStepPath = subdivisionEditorSubStep
      ? `/${subdivisionEditorSubStep}`
      : '';
    return {
      path: `/${AppModules.SUBDIVISION}/${subdivisionId}/${subdivisionEditorStep}${subStepPath}`,
      title: `Subdivision - ${subdivisionEditorStep.toString()}`,
    };
  },
  setApplicationStateFromRoute: ({
    subdivisionId,
    subdivisionEditorStep,
    subdivisionEditorSubStep,
  }) => {
    if (hasFeature(Features.PERMISSION_ZONES)) {
      dispatchAction(
        navigateToSubdivision({
          subdivisionId,
          subdivisionEditorStep,
          subdivisionEditorSubStep,
        })
      );
    } else {
      dispatchAction(requestShowAppModule({ selectedModule: AppModules.HOME }));
    }
  },
  getPartialRouterStateStream: () =>
    subdivisionNavigation$.pipe(
      map(
        ({
          subdivisionId,
          subdivisionEditorStep,
          subdivisionEditorSubStep,
        }) => ({
          subdivisionId,
          subdivisionEditorStep,
          subdivisionEditorSubStep,
        })
      )
    ),
});

export default subdivisionRoute;
