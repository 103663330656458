import { Icon, IconName } from '@ardoq/icons';
import { classes } from '@ardoq/common-helpers';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { hideRightPane } from 'appContainer/actions';
import referenceFab$ from './referenceFab$';
import { activateReferenceFab } from './actions';
import { FabContainer } from '../atoms';
import { FabColor } from '../types';
import { FAB_SIZE } from '../consts';

type ReferenceFabProps = {
  readonly isVisible: boolean;
  readonly isActive: boolean;
};

const ReferenceFab = ({ isVisible, isActive }: ReferenceFabProps) => {
  if (!isVisible) {
    return null;
  }
  return (
    <FabContainer
      className={classes(isActive && 'active')}
      color={FabColor.RED}
      $rightOffset={FAB_SIZE + FAB_SIZE / 4}
      onClick={() => {
        if (isActive) {
          return dispatchAction(hideRightPane());
        }
        dispatchAction(activateReferenceFab());
      }}
      data-tooltip-text={isActive ? 'Close sidebar' : 'Create a reference'}
    >
      <div className="fab-main">
        <Icon iconName={IconName.CLOSE} />
      </div>
    </FabContainer>
  );
};

export default connect(ReferenceFab, referenceFab$);
