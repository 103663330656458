export enum OrgTabs {
  SETTINGS = 'settings',
  WEBHOOKS = 'manage-webhooks',
  BUNDLES = 'manage-bundles',
  ENTITY_GROUPS = 'manage-entity-groups',
  USE_CASES = 'manage-use-cases',
  MIGRATION_CHECKLIST = 'migration-checklist',
  FEATURE_SETTINGS = 'feature-settings',
  WORKSPACE_OVERVIEW = 'workspace-overview',
}

export type ManageOrganizationRoute = {
  manageOrgTab: OrgTabs;
};
