import { actionCreator } from '@ardoq/rxbeach';
import { CloudProviderIntegrationId } from 'integrations/cloudProviders/types';
import { ResourceType } from './types';

const NAMESPACE = '[integrations cloud-providers] RESOURCE';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

type WithCloudProviderIntegrationId = {
  integrationId: CloudProviderIntegrationId;
};

export const fetchResourceTypes = actionCreator<WithCloudProviderIntegrationId>(
  getActionName('FETCH_CLOUD_PROVIDERS_RESOURCE_TYPES')
);

export type FetchResourceTypesSuccessPayload =
  WithCloudProviderIntegrationId & {
    resourceTypes: ResourceType[];
  };

export const fetchResourceTypesSuccess =
  actionCreator<FetchResourceTypesSuccessPayload>(
    getActionName('FETCH_CLOUD_PROVIDERS_RESOURCES_SUCCESS')
  );

export const fetchResourceTypesFailure =
  actionCreator<WithCloudProviderIntegrationId>(
    getActionName('FETCH_CLOUD_PROVIDERS_RESOURCES_FAILURE')
  );

export type UpdateResourceTypeListPayload = WithCloudProviderIntegrationId & {
  resourceTypes: ResourceType[];
};
export const updateResourceTypeList =
  actionCreator<UpdateResourceTypeListPayload>(
    getActionName('UPDATE_RESOURCES_LIST')
  );

export type ChangeResourceTypesSelectionPayload =
  WithCloudProviderIntegrationId & {
    resourceTypes: string[];
  };
export const changeResourceTypesSelection =
  actionCreator<ChangeResourceTypesSelectionPayload>(
    getActionName('CHANGE_RESOURCE_TYPES_SELECTION')
  );
