import { map } from 'rxjs/operators';
import validation$, { ValidationStreamShape } from '../validation$';
import { combineLatest } from 'rxjs';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { graphSearchAccessControlHelpers } from 'resourcePermissions/accessControlHelpers/graphSearch';

export type BroadcastAudienceFormStreamShape = {
  validation: ValidationStreamShape;
  canRunGremlinQueries: boolean;
};

const broadcastAudienceForm$ = combineLatest([
  validation$,
  currentUserPermissionContext$,
]).pipe(
  map(([validation, permissionContext]) => ({
    validation,
    canRunGremlinQueries:
      graphSearchAccessControlHelpers.canCreateGraphFilter(permissionContext),
  }))
);

export default broadcastAudienceForm$;
