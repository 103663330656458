import {
  APIOrganizationUser,
  APIRoleAttributes,
  ArdoqId,
  AssetType,
  PermissionAccessLevel,
  PermissionGroup,
  UserAssetPermission,
} from '@ardoq/api-types';
import { modal } from '@ardoq/modal';
import { dispatchAction } from '@ardoq/rxbeach';
import { setSelectedRoleLabelAction } from 'admin/accessControl/ManageUserRoles/streams/actions';
import {
  AccessControlTabs,
  UserProfileTabs,
} from 'admin/accessControl/navigation/types';
import { UserProfilePageEvents } from 'admin/accessControl/UserProfilePage/types';
import { trackUserProfilePageEvents } from 'admin/accessControl/UserProfilePage/utils';
import { permissionGroupsCommands } from 'admin/accessControl/PermissionGroups/commands';
import { navigateToAccessControlPage } from 'router/navigationActions';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import { trackEvent } from 'tracking/tracking';
import AddToGroupDialog from 'admin/orgMembers/Dialogs/AddToGroupDialog';
import { accessControlPageActions } from './actions';
import {
  openResourcePermissionDialog,
  resourcePermissionDialogClosed,
} from 'resourcePermissions/actions';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { CheckboxGroupValue } from '@ardoq/forms';
import { SelectOption } from '@ardoq/select';
import {
  UpdateUserAssetsOverviewQueryChanges,
  UserAssetsOverviewQuery,
} from 'admin/accessControl/UserProfilePage/userAssetsOverview/userAssetsOverviewTypes';
import { userAssetsOverviewQueryOperations } from 'admin/accessControl/UserProfilePage/userAssetsOverview/userAssetsOverviewQueryOperations';
import { manageServiceAccountsCommands } from 'admin/serviceAccounts/commands';
import { subdivisionEditorCommands } from 'subdivisionEditor/subdivisionEditorViewModel$/commands';
import { userAssetPermissionOperations } from 'admin/accessControl/UserProfilePage/UserRoleAndGroupsDetails/UserAssetsOverviewTab/userAssetPermissionOperations';

const goToUsersOverviewPage = (from?: string) => {
  trackEvent(APPSEC_EVENTS.CLICKED_MANAGE_ORGANIZATION_TAB, {
    to: AccessControlTabs.MEMBERS,
    from,
    namespace: 'access control',
  });
  dispatchAction(
    navigateToAccessControlPage({
      accessControlTab: AccessControlTabs.MEMBERS,
    })
  );
};

const goToUserProfilePage = (userId: ArdoqId, from?: string) => {
  trackEvent(APPSEC_EVENTS.CLICKED_USER_ROW, {
    from,
    namespace: 'access control',
    userId,
  });
  dispatchAction(
    navigateToAccessControlPage({
      accessControlTab: AccessControlTabs.MEMBERS,
      userId,
      userProfileActiveTabId: 'roles',
    })
  );
};

const onGroupClick = (group: PermissionGroup) => {
  trackUserProfilePageEvents(UserProfilePageEvents.CLICKED_PERMISSION_GROUP);
  dispatchAction(
    navigateToAccessControlPage({
      accessControlTab: AccessControlTabs.PERMISSION_GROUPS,
      groupId: group._id,
    })
  );
  permissionGroupsCommands.goToGroup(group._id);
};

const onRoleClick = (role: APIRoleAttributes) => {
  trackUserProfilePageEvents(UserProfilePageEvents.CLICKED_USER_ROLE, {
    role: role.label,
  });
  dispatchAction(
    navigateToAccessControlPage({
      accessControlTab: AccessControlTabs.MANAGE_USER_ROLES,
    })
  );
  dispatchAction(setSelectedRoleLabelAction(role));
};

const onAddToGroupClick = (user: APIOrganizationUser) => {
  trackUserProfilePageEvents(UserProfilePageEvents.OPENED_ADD_TO_GROUP_MODAL);
  modal(closeModal =>
    AddToGroupDialog({
      closeDialog: () => closeModal(true),
      selectedUsers: [user],
    })
  );
};

const requestUserAssets = (queryObject: UserAssetsOverviewQuery) => {
  if (userAssetsOverviewQueryOperations.hasUserId(queryObject)) {
    dispatchAction(accessControlPageActions.requestUserAssets(queryObject));
  }
};

const toggleUserAssetsFilterSidebarVisibility = (isVisible: boolean) => {
  const action = isVisible
    ? accessControlPageActions.closeUserAssetsFilterSidebar()
    : accessControlPageActions.openUserAssetsFilterSidebar();
  dispatchAction(action);
  trackUserProfilePageEvents(
    UserProfilePageEvents[
      isVisible
        ? 'CLICKED_CLOSE_ASSET_FILTER_SIDEBAR'
        : 'CLICKED_OPEN_ASSET_FILTER_SIDEBAR'
    ]
  );
};

const closeUserAssetsFilterSidebar = () => {
  dispatchAction(accessControlPageActions.closeUserAssetsFilterSidebar());
};

const editAssetsAccess = async (
  queryObject: UserAssetsOverviewQuery,
  assetPermissions: UserAssetPermission[]
) => {
  const resources = assetPermissions.map(
    userAssetPermissionOperations.convertToSingleResourcePermission
  );

  const {
    payload: { isSaved },
  } = await dispatchActionAndWaitForResponse(
    openResourcePermissionDialog({
      resources,
      originPage: 'user-assets-overview',
      headerText:
        assetPermissions.length === 1
          ? userAssetPermissionOperations.maybeGetResourcePermissionDialogHeaderText(
              assetPermissions[0]
            )
          : undefined,
    }),
    resourcePermissionDialogClosed,
    resourcePermissionDialogClosed
  );
  if (isSaved) {
    requestUserAssets(queryObject);
  }
};

const assetTypeCheckboxGroupChanged = (
  query: UserAssetsOverviewQuery,
  assetTypes: CheckboxGroupValue
) => {
  commands.updateAssetTypeFilter(query, {
    newList: assetTypes as AssetType[],
  });
  trackUserProfilePageEvents(
    UserProfilePageEvents.CLICKED_ASSET_TYPE_CHECKBOX,
    { assetTypesCount: assetTypes.length, assetTypes }
  );
};

const updateAssetTypeFilter = (
  query: UserAssetsOverviewQuery,
  changes: UpdateUserAssetsOverviewQueryChanges<AssetType>
) => {
  const newQuery =
    userAssetsOverviewQueryOperations.updateUserAssetsOverviewQuery<AssetType>(
      query,
      {
        key: 'assetTypes',
        ...changes,
      }
    );
  updateFiltersAndFetchAssets(newQuery);
};

const accessLevelMultiselectChanged = (
  query: UserAssetsOverviewQuery,
  selectedPermissionLevels: SelectOption<PermissionAccessLevel>[] | null
) => {
  const permissionLevels =
    selectedPermissionLevels?.map<PermissionAccessLevel>(
      ({ value }) => value
    ) ?? [];
  commands.updatePermissionLevelsFilter(query, {
    newList: permissionLevels,
  });
  trackUserProfilePageEvents(
    UserProfilePageEvents.CLICKED_PERMISSION_LEVEL_MULTISELECT,
    {
      permissionLevelsCount: permissionLevels.length,
      permissionLevels,
    }
  );
};

const updatePermissionLevelsFilter = (
  query: UserAssetsOverviewQuery,
  changes: UpdateUserAssetsOverviewQueryChanges<PermissionAccessLevel>
) => {
  const newQuery =
    userAssetsOverviewQueryOperations.updateUserAssetsOverviewQuery<PermissionAccessLevel>(
      query,
      {
        key: 'permissionLevels',
        ...changes,
      }
    );
  updateFiltersAndFetchAssets(newQuery);
};

const clearAllFilters = (query: UserAssetsOverviewQuery) => {
  const newQuery = userAssetsOverviewQueryOperations.updateUserAssetsQuery(
    query,
    {
      assetTypes: [],
      permissionLevels: [],
    }
  );
  updateFiltersAndFetchAssets(newQuery);
  trackUserProfilePageEvents(
    UserProfilePageEvents.CLICKED_CLEAR_ALL_FILTERS_CHIP
  );
};

const onSearchByAssetNameChange = (value: string) => {
  dispatchAction(
    accessControlPageActions.userAssetsFilterChanged({
      searchString: value.toLowerCase(),
    })
  );
  trackUserProfilePageEvents(
    UserProfilePageEvents.CHANGED_FILTER_BY_ASSET_NAME_OR_FOLDER
  );
};

const updateFiltersAndFetchAssets = (request: UserAssetsOverviewQuery) => {
  dispatchAction(accessControlPageActions.userAssetsFilterChanged(request));
  dispatchAction(accessControlPageActions.requestUserAssets(request));
};

const changeUserProfilePageTab = (
  user: APIOrganizationUser,
  userGroups: PermissionGroup[],
  tabId: UserProfileTabs
) => {
  trackUserProfilePageEvents(UserProfilePageEvents.CLICKED_USER_PROFILE_TAB, {
    tabId,
    groupsCount: userGroups.length,
  });
  dispatchAction(
    navigateToAccessControlPage({
      accessControlTab: AccessControlTabs.MEMBERS,
      userId: user._id,
      userProfileActiveTabId: `${tabId}`,
    })
  );
};

const showCreateGroupModal = permissionGroupsCommands.showCreateGroupModal;

const createToken = manageServiceAccountsCommands.createToken;

const createSubdivision = subdivisionEditorCommands.createSubdivision;

const handleAssetTypeChipClick = (
  query: UserAssetsOverviewQuery,
  changes: UpdateUserAssetsOverviewQueryChanges<AssetType>
) => {
  trackUserProfilePageEvents(UserProfilePageEvents.CLICKED_ASSET_TYPE_CHIP);
  commands.updateAssetTypeFilter(query, changes);
};

const handlePermissionLevelChipClick = (
  query: UserAssetsOverviewQuery,
  changes: UpdateUserAssetsOverviewQueryChanges<PermissionAccessLevel>
) => {
  trackUserProfilePageEvents(
    UserProfilePageEvents.CLICKED_PERMISSION_LEVEL_CHIP
  );
  commands.updatePermissionLevelsFilter(query, changes);
};

export const commands = {
  onGroupClick,
  onRoleClick,
  onAddToGroupClick,
  goToUsersOverviewPage,
  goToUserProfilePage,
  requestUserAssets,
  toggleUserAssetsFilterSidebarVisibility,
  closeUserAssetsFilterSidebar,
  editAssetsAccess,
  onSearchByAssetNameChange,
  assetTypeCheckboxGroupChanged,
  accessLevelMultiselectChanged,
  updateAssetTypeFilter,
  updatePermissionLevelsFilter,
  clearAllFilters,
  changeUserProfilePageTab,
  showCreateGroupModal,
  createToken,
  createSubdivision,
  handleAssetTypeChipClick,
  handlePermissionLevelChipClick,
};
