import { PrimaryButton, GhostButton } from '@ardoq/button';
import { OnClick } from 'aqTypes';

type BroadcastAudienceDialogFooterProps = {
  isUpdatingExistingAudience: boolean;
  onCancel: OnClick;
  onSubmit: OnClick;
  audienceCount: number;
  formIsValid: boolean;
  hasError?: boolean;
};

const BroadcastAudienceDialogFooter = ({
  isUpdatingExistingAudience,
  onCancel,
  onSubmit,
  audienceCount,
  formIsValid,
  hasError,
}: BroadcastAudienceDialogFooterProps) => {
  if (hasError) {
    return <GhostButton onClick={onCancel}>Close</GhostButton>;
  }

  const primaryButtonLabel = isUpdatingExistingAudience
    ? `Update audience (${audienceCount})`
    : `Add to audience list (${audienceCount})`;

  if (formIsValid) {
    return (
      <>
        <GhostButton onClick={onCancel}>Cancel</GhostButton>
        <PrimaryButton onClick={onSubmit}>{primaryButtonLabel}</PrimaryButton>
      </>
    );
  }
  return (
    <>
      <GhostButton onClick={onCancel}>Cancel</GhostButton>
      <PrimaryButton isDisabled>{primaryButtonLabel}</PrimaryButton>
    </>
  );
};

export default BroadcastAudienceDialogFooter;
