import type { SavedPerspective } from 'modelInterface/perspectives/perspectiveInterface';
import {
  DEFAULT_COMPONENT_LABEL_FORMATTING,
  DEFAULT_REFERENCE_LABEL_FORMATTING,
} from 'perspective/perspectiveEditor/labelFormattingUtils';
import { mapToPerspectiveEditorFormattingRule } from './mapToPerspectiveEditorFormattingRule';
import { mapToPerspectiveGroupingRule } from './mapToPerspectiveGroupingRule';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  PerspectiveEditorFilteringRules,
  SelectedSavedPerspectiveState,
} from '@ardoq/perspectives';
import {
  BooleanOperator,
  FormattingFilter,
  isComponentFilter,
  isComponentLabelFormatting,
  isConditionalFormatting,
  isReferenceFilter,
  isReferenceLabelFormatting,
  FilterAttributes,
} from '@ardoq/api-types';
import { filterAttributesToQueryBuilderRow } from 'collections/filterUtils';

const getFilteringRules = (
  savedPerspective: SavedPerspective
): PerspectiveEditorFilteringRules => {
  return {
    workspaceFilterIds: getWorkspaceFilterIdsOrNull(savedPerspective),
    componentRules: {
      condition: BooleanOperator.AND,
      rules: savedPerspective.filters.advancedFilters
        .filter(isComponentFilter)
        .map(filterAttributesToQueryBuilderRow),
    },
    referenceRules: {
      condition: BooleanOperator.AND,
      rules: savedPerspective.filters.advancedFilters
        .filter(isReferenceFilter)
        .map(filterAttributesToQueryBuilderRow),
    },
    graphFilters: savedPerspective.dynamicFilters,
  };
};

const getWorkspaceFilterIdsOrNull = (savedPerspective: SavedPerspective) => {
  const workspaceFilter = savedPerspective.filters.workspaceFilter;
  if (workspaceFilter === null) {
    return null;
  }
  return (
    workspaceFilter.rules
      ?.map(rule => rule.value as string)
      .filter(ExcludeFalsy) ?? null
  );
};

const getConditionalFormattingRules = (filters: FilterAttributes[]) => {
  return filters
    .filter(isConditionalFormatting)
    .map(formattingRule =>
      mapToPerspectiveEditorFormattingRule(formattingRule as FormattingFilter)
    )
    .filter(ExcludeFalsy);
};

const getComponentLabelFormattingOrDefault = (filters: FilterAttributes[]) => {
  const componentLabelFormatting = filters.find(isComponentLabelFormatting);
  if (!componentLabelFormatting) {
    return {
      componentLabelFormattingValue: DEFAULT_COMPONENT_LABEL_FORMATTING,
      isComponentLabelTimeIncluded: false,
    };
  }
  return {
    componentLabelFormattingValue: componentLabelFormatting.value as string,
    isComponentLabelTimeIncluded: componentLabelFormatting.includeTime,
  };
};

const getReferenceLabelFormattingOrDefault = (filters: FilterAttributes[]) => {
  const referenceLabelFormatting = filters.find(isReferenceLabelFormatting);
  if (!referenceLabelFormatting) {
    return {
      referenceLabelFormattingValue: DEFAULT_REFERENCE_LABEL_FORMATTING,
      isReferenceLabelTimeIncluded: false,
    };
  }
  return {
    referenceLabelFormattingValue: referenceLabelFormatting.value as string,
    isReferenceLabelTimeIncluded: referenceLabelFormatting.includeTime,
  };
};

export const mapSavedPerspectiveToPerspectiveEditorSavedPerspectiveState = (
  savedPerspective: SavedPerspective
): SelectedSavedPerspectiveState => {
  const { _id, groupBys, filters } = savedPerspective;

  const groupingRules = groupBys.map(mapToPerspectiveGroupingRule);

  const { componentLabelFormattingValue, isComponentLabelTimeIncluded } =
    getComponentLabelFormattingOrDefault(filters.advancedFilters);
  const { referenceLabelFormattingValue, isReferenceLabelTimeIncluded } =
    getReferenceLabelFormattingOrDefault(filters.advancedFilters);

  const conditionalFormattingRules = getConditionalFormattingRules(
    filters.advancedFilters
  );

  return {
    savedPerspectiveId: _id,
    groupingRules,
    componentLabelFormattingValue,
    referenceLabelFormattingValue,
    isComponentLabelTimeIncluded,
    isReferenceLabelTimeIncluded,
    conditionalFormattingRules,
    filteringRules: getFilteringRules(savedPerspective),
  };
};
