import BroadcastAudienceDialogFooter from '../BroadcastAudienceDialogFooter';
import styled from 'styled-components';
import emailAudienceDialog$ from './emailAudienceDialog$';
import { ModalContent, ModalLayout, ModalTemplate } from '@ardoq/modal';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { updateEmails } from './actions';
import { EmailAudienceDialogStreamShape } from './types';
import { numberOfValidEmails } from './utils';
import { Header3, Text } from '@ardoq/typography';
import { BROADCAST_AUDIENCE_DIALOG_MODAL_SIZE } from '../dialogConsts';

const TextArea = styled.textarea`
  height: 78%;
`;

const EmailAudienceDialog = ({
  resolve,
  emails,
  isEditingExistingAudience,
  formIsValid,
}: EmailAudienceDialogStreamShape & ModalContent<string[]>) => {
  const closeDialog = () => resolve(false);
  const addToAudienceList = async () => {
    if (!formIsValid) return;
    resolve(emails.filter(Boolean));
  };

  return (
    <ModalTemplate
      modalSize={BROADCAST_AUDIENCE_DIALOG_MODAL_SIZE}
      headerText="Add Audience Using Emails"
      onCloseButtonClick={closeDialog}
      footerContent={
        <BroadcastAudienceDialogFooter
          isUpdatingExistingAudience={isEditingExistingAudience}
          audienceCount={numberOfValidEmails(emails)}
          onCancel={closeDialog}
          onSubmit={addToAudienceList}
          formIsValid={formIsValid}
        />
      }
    >
      <ModalLayout>
        <Header3>Directly input email addresses.</Header3>
        <Text>Each address should be separated using a line break.</Text>
        <TextArea
          placeholder="Email addresses"
          value={emails.join('\n')}
          onChange={({ target }) => {
            dispatchAction(updateEmails(target.value));
          }}
          rows={10}
        />
        <Text>
          Users logging in with SSO will become Ardoq contributors after
          clicking on an Ardoq link.
        </Text>
      </ModalLayout>
    </ModalTemplate>
  );
};

export default connect(EmailAudienceDialog, emailAudienceDialog$);
