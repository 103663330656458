import { exhaustiveCheck } from '@ardoq/common-helpers';
import { selectionApi } from '@ardoq/api';
import { FetchSelectionPayload } from './types';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { UnifiedFetchSelection } from './types';

const isUnifiedFetchSelection = (
  selectionPayload: FetchSelectionPayload
): selectionPayload is UnifiedFetchSelection =>
  isUnifiedIntegrationId(selectionPayload.integrationId);

type FetchRequestParams = Parameters<
  typeof selectionApi.fetchSelectionAsync
>[0];

export function selectionPayloadToFetchRequestParams(
  selectionPayload: FetchSelectionPayload
): FetchRequestParams {
  if (isUnifiedFetchSelection(selectionPayload)) {
    return {
      requestId: selectionPayload.requestId,
      integrationType: selectionPayload.integrationId,
      fetchRequestPayload: selectionPayload.payload,
    };
  }
  switch (selectionPayload.integrationId) {
    case 'aws-v3':
      return {
        requestId: selectionPayload.requestId,
        integrationType: 'aws',
        fetchRequestPayload: selectionPayload.payload,
      };
    case 'azure-v3':
      return {
        requestId: selectionPayload.requestId,
        integrationType: 'azure',
        fetchRequestPayload: selectionPayload.payload,
      };
    case 'microsoft-entra-id':
      return {
        requestId: selectionPayload.requestId,
        integrationType: 'microsoftgraph',
        fetchRequestPayload: selectionPayload.payload,
      };
    case 'servicenow-v3':
      return {
        requestId: selectionPayload.requestId,
        integrationType: 'servicenow',
        fetchRequestPayload: selectionPayload.payload,
      };
  }
  exhaustiveCheck(selectionPayload);
}
