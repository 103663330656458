import { reducedStream, reducer } from '@ardoq/rxbeach';
import { changeSearchKey } from './actions';
import { subdivisionSummaryViewModelOperations } from './operations';

export type SubdivisionSummaryViewModelState = {
  searchKey: string;
};

const defaultState: SubdivisionSummaryViewModelState = {
  searchKey: '',
};

const reducers = [
  reducer(
    changeSearchKey,
    subdivisionSummaryViewModelOperations.updateSearchKeyReducer
  ),
];

export const subdivisionSummaryViewModel$ =
  reducedStream<SubdivisionSummaryViewModelState>(
    'subdivisionSummaryViewModel$',
    defaultState,
    reducers
  );
