import {
  ArdoqId,
  OrgAccessLevel,
  CreateInvitationsOpts,
  PersistedInvitation,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { FormState, InvitationsStreamShape } from './types';

type InvitationIdPayload = { invitationId: ArdoqId };

export const fetchInvitations = actionCreator('[Invitations] Fetch');

export const createInvitations = actionCreator<CreateInvitationsOpts>(
  '[Invitations] BULK_CREATE'
);
export const addCreatedInvitation = actionCreator<
  PersistedInvitation | PersistedInvitation[]
>('[Invitations] ADD_CREATED');

type UpdateInvitationRolePayload = {
  invitationId: ArdoqId;
  newRole: OrgAccessLevel;
};
export const updateInvitationRole = actionCreator<UpdateInvitationRolePayload>(
  '[Invitations] UPDATE_ROLE'
);
export const replaceUpdatedInvitation = actionCreator<PersistedInvitation>(
  '[Invitations] REPLACE_UPDATED'
);

export const deleteInvitation = actionCreator<InvitationIdPayload>(
  '[Invitations] DELETE'
);
export const removeDeletedInvitation = actionCreator<InvitationIdPayload>(
  '[Invitations] REMOVE_DELETED'
);

export const resendInvitation = actionCreator<InvitationIdPayload>(
  '[Invitations] RESEND'
);

export const setInvitationsState = actionCreator<
  Partial<InvitationsStreamShape>
>('[Invitations] SET_INVITATIONS_STREAM_STATE');

export const setInvitationFormState = actionCreator<Partial<FormState>>(
  '[Invitations] SET_INVITATIONS_FORM_STATE'
);
export const submitInvitationForm = actionCreator(
  '[Invitations] SUBMIT_INVITATION_FROM'
);
