import {
  LayoutBoxModel,
  NavigatorLayoutState,
} from 'components/WorkspaceHierarchies/types';
import { filter, firstValueFrom, Observable, tap, withLatestFrom } from 'rxjs';
import { updateTreeAction$ } from './streams/updateTreeAction$';
import layout, {
  getHiddenNodes,
} from 'components/WorkspaceHierarchies/models/layout';
import { logWarn } from '@ardoq/logging';
import { catchErrorLogWithMessageAndContinue } from 'streams/utils/streamOperators';
import {
  DEFAULT_SCROLL_TOP_MARGIN,
  HEIGHT_COMPONENT_ITEM,
} from 'components/WorkspaceHierarchies/utils/consts';

export const getScrollToSelectedRoutine = (
  navigator$: Observable<NavigatorLayoutState>
) => {
  return updateTreeAction$.pipe(
    withLatestFrom(navigator$),
    tap(async ([, navigatorState]) => {
      const { height, container, tree } = navigatorState;
      if (height === 0) {
        await firstValueFrom(
          navigator$.pipe(filter(({ height }) => height > 0))
        );
      }

      if (!(container && tree.selectedContext)) {
        return;
      }
      // This is a workaround to wait for the next stream and view update.
      // We only like to scroll the selected node into view for a selected set
      // of update actions.
      await nextDOMUpdate(container);

      scrollSelectedNodeIntoViewIfNeeded(navigatorState);
    }),
    catchErrorLogWithMessageAndContinue('Error in getScrollToSelectedRoutine')
  );
};

const scrollSelectedNodeIntoViewIfNeeded = ({
  height,
  scrollTop,
  container,
  showFilteredComponents,
  tree,
}: NavigatorLayoutState) => {
  if (!(container && tree.selectedContext)) {
    return;
  }

  const selectedId = tree.selectedContext.id;
  const hiddenNodes = getHiddenNodes(tree, showFilteredComponents);

  const hiddenNodeIds = new Set<string>(
    [...hiddenNodes.values()].map(({ id }) => id)
  );
  if (hiddenNodeIds.has(selectedId)) {
    return;
  }
  const layoutBox = layout(
    tree,
    height,
    scrollTop,
    new Set([selectedId]),
    undefined,
    showFilteredComponents,
    hiddenNodes
  );

  const selectedLayoutBox = layoutBox.layoutMap.get(selectedId);
  if (!selectedLayoutBox) {
    logWarn(
      Error('Missing layoutBox in scrollToNodeIfHidden in navigationManager')
    );
    return;
  }

  if (!isVisible(selectedLayoutBox, container)) {
    container.scrollTop =
      selectedLayoutBox.top - getDelta(container.clientHeight);
  }
};

const getDelta = (containerHeight: number) => {
  if (
    containerHeight >
    HEIGHT_COMPONENT_ITEM + 1.2 * DEFAULT_SCROLL_TOP_MARGIN
  ) {
    return DEFAULT_SCROLL_TOP_MARGIN;
  }

  return (containerHeight - HEIGHT_COMPONENT_ITEM) / 2;
};

const nextDOMUpdate = (container: HTMLElement) =>
  new Promise(resolve => {
    const observer = new MutationObserver(() => {
      observer.disconnect();
      resolve(null);
    });
    setTimeout(() => {
      observer.disconnect();
      resolve(null);
    }, 1000);
    observer.observe(container, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  });

const isVisible = (layoutBox: LayoutBoxModel, container: HTMLElement) => {
  const { height } = container.getBoundingClientRect();
  const { scrollTop } = container;
  return layoutBox.top >= scrollTop && layoutBox.bottom <= scrollTop + height;
};
