import { componentsSearchCommands } from 'viewpointBuilder/selectContextTab/commands';
import { metamodelViewCommands } from './metaModel/metamodelViewCommands';
import { ViewpointBuilderCommands } from './types';
import { viewpointBuilderFiltersCommands } from './addFiltersTab/viewpointBuilderFiltersCommands';
import { viewpointBuilderNavigationCommands } from './viewpointBuilderNavigation/viewpointBuilderNavigationCommands';
import { editTraversalCommands } from './traversals/editTraversalCommands';
import { metaInfoCommands } from './viewpointMetaInfo/metaInfoCommands';
import { viewpointGroupingRulesCommands } from './addGroupingRulesTab/viewpointGroupingRulesCommands';
import { viewpointBuilderFormattingCommands } from './formattingTabs/viewpointBuilderFormattingCommands';
import { collapsePathsCommands } from './collapsePathsTab/collapsePathsCommands';
import { requiredComponentsCommands } from './requiredComponentsTab/requiredComponentsCommands';
import { selectedViewCommands } from './selectedView/commands';

export const viewpointBuilderCommands: ViewpointBuilderCommands = {
  componentsSearchCommands,
  metamodelViewCommands,
  filterCommands: viewpointBuilderFiltersCommands,
  navigationCommands: viewpointBuilderNavigationCommands,
  editTraversalCommands,
  metaInfoCommands,
  groupingsCommands: viewpointGroupingRulesCommands,
  formattingCommands: viewpointBuilderFormattingCommands,
  collapsePathsCommands: collapsePathsCommands,
  requiredComponentsCommands,
  selectedViewCommands,
};
