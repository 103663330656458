import {
  APIComponentAttributes,
  APIOrganizationUser,
  ArdoqId,
  ChangeRequestAction,
} from '@ardoq/api-types';
import { ComponentRenderer } from '@ardoq/renderers';
import { connect, derivedStream, dispatchAction } from '@ardoq/rxbeach';
import { EnhancedScopeData } from '@ardoq/data-model';
import { map } from 'rxjs';
import changeApprovalData$ from '../changeApprovalData$';
import { TableList, TableListItem } from '@ardoq/list';
import { getFieldNamesAndLabels, renderOptions } from './utils';
import { isEqual, omit } from 'lodash';
import { Header4 } from '@ardoq/typography';
import styled from 'styled-components';
import { SwitchPosition, TriStateSwitch } from '@ardoq/snowflakes';
import { colors, spacing } from '@ardoq/design-tokens';
import { resetDataForEntireComponent } from '../actions';
import { ApprovalStatus, RecordOfComponents } from '../types';
import { StyledColorBar } from './atoms';
import { FlexBox, Stack } from '@ardoq/layout';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { NoComponentFoundDoqy } from './NoComponentFoundDoqy';

const BorderBottomFlexBox = styled(FlexBox)`
  padding: ${spacing.s8};
  border-bottom: 1px solid ${colors.borderSubtle00};
`;

const getApprovalStatus = (
  sourceOfTruthComponent: APIComponentAttributes | undefined,
  approvedComponentChanges: RecordOfComponents
): ApprovalStatus | null => {
  if (!sourceOfTruthComponent) return null;
  if (
    !Object.keys(approvedComponentChanges).includes(sourceOfTruthComponent._id)
  ) {
    return 'pending';
  }

  return isEqual(
    sourceOfTruthComponent,
    approvedComponentChanges[sourceOfTruthComponent._id]
  )
    ? 'approved'
    : 'rejected';
};

type CreateOrDeleteComponentTableProps = {
  componentId: ArdoqId;
  sourceOfTruthComponent: APIComponentAttributes | undefined;
  sourceOfTruthScopeData: EnhancedScopeData;
  users: Record<string, APIOrganizationUser>;
  changeAction: ChangeRequestAction;
  status: ApprovalStatus | null;
  onApproveAll: () => void;
  onRejectAll: () => void;
};

const CreateOrDeleteComponentTable = ({
  componentId,
  sourceOfTruthScopeData,
  sourceOfTruthComponent,
  users,
  changeAction,
  status,
  onApproveAll,
  onRejectAll,
}: CreateOrDeleteComponentTableProps) => {
  const handleApprovedData = (switchPosition: SwitchPosition) => {
    if (switchPosition === 'approved') return onApproveAll();
    if (switchPosition === 'rejected') return onRejectAll();
    dispatchAction(resetDataForEntireComponent(componentId));
  };
  const fieldNamesAndLabels = getFieldNamesAndLabels(
    Object.keys(
      omit(sourceOfTruthComponent, [
        'ardoq',
        'ardoq-persistent',
        'last-updated',
        'createdBy',
        '_id',
        'color',
        'icon',
        'isPublic',
        'shape',
        'image',
        'origin',
        'last-modified-by',
      ])
    ),
    sourceOfTruthScopeData
  );
  return sourceOfTruthComponent && status ? (
    <Stack>
      <Header4>
        <BorderBottomFlexBox justify="space-between" align="center">
          Approve {changeAction === 'create' ? 'creating' : 'deleting'} the
          component {sourceOfTruthComponent.name}?
          <TriStateSwitch
            dataTestId="approval-switch"
            name={componentId}
            value={status}
            onChange={switchPosition => handleApprovedData(switchPosition)}
          />
        </BorderBottomFlexBox>
      </Header4>
      <FlexBox gap="medium" paddingTop="small" paddingLeft="small">
        <StyledColorBar
          $barColor={changeAction === 'create' ? colors.green50 : colors.red50}
        />
        <TableList style={{ marginTop: spacing.s4 }}>
          <tbody>
            {fieldNamesAndLabels.map(({ label, fieldName }) => {
              return (
                <TableListItem key={fieldName} label={label}>
                  <ComponentRenderer
                    entityId={componentId}
                    fieldName={fieldName}
                    enhancedScopeData={sourceOfTruthScopeData}
                    users={users}
                    options={renderOptions}
                  />
                </TableListItem>
              );
            })}
          </tbody>
        </TableList>
      </FlexBox>
    </Stack>
  ) : (
    <NoComponentFoundDoqy />
  );
};

const createOrDeleteComponentTable$ = derivedStream(
  'createOrDeleteComponentTable$',
  changeApprovalData$,
  orgUsers$
).pipe(
  map(
    ([
      {
        componentId,
        masterData,
        branchData,
        changeAction,
        approvedComponentChanges,
      },
      { byId: users },
    ]) => {
      const masterComponent = masterData.componentsById[componentId];
      const branchComponent = branchData.componentsById[componentId];
      const sourceOfTruthComponent: APIComponentAttributes | undefined =
        changeAction === 'delete' ? masterComponent : branchComponent;
      return {
        componentId,
        sourceOfTruthScopeData:
          changeAction === 'delete' ? masterData : branchData,
        sourceOfTruthComponent,
        users,
        changeAction,
        status: getApprovalStatus(
          sourceOfTruthComponent,
          approvedComponentChanges
        ),
      };
    }
  )
);

export default connect(
  CreateOrDeleteComponentTable,
  createOrDeleteComponentTable$
);
