import {
  ArdoqId,
  NonPersistedSubdivision,
  Subdivision,
  Zone,
  SubdivisionsMembershipsMap,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { ApiZonesFailurePayload, SubdivisionsStreamShape } from './types';

const namespacedActionCreator = <Payload = void>(name: string) =>
  actionCreator<Payload>(`[SUBDIVISIONS] ${name}`);

export const fetchAllZones = namespacedActionCreator<void>('FETCH_ALL_ZONES');
export const fetchedZonesSuccessfully = namespacedActionCreator<Zone[]>(
  'FETCH_ALL_ZONES_SUCCESSFUL'
);

export const syncGridEditorSubdivisions =
  namespacedActionCreator<SubdivisionsStreamShape>('SYNC_GRID_EDITOR_ZONES');

export const fetchingZonesFailure =
  namespacedActionCreator<ApiZonesFailurePayload>('FETCH_ALL_ZONES_FAILURE');

export const createZone = namespacedActionCreator<Zone>('CREATE_ZONE');
export const createZoneSuccessfully = namespacedActionCreator<Zone>(
  'CREATE_ZONE_SUCCESSFULLY'
);
export const createZoneFailure =
  namespacedActionCreator<ApiZonesFailurePayload>('CREATE_ZONE_FAILURE');

export const updateZone = namespacedActionCreator<Zone>('UPDATE_ZONE');
export const updateZoneSuccessfully = namespacedActionCreator<Zone>(
  'UPDATE_ZONE_SUCCESSFULLY'
);
export const updateZoneFailure =
  namespacedActionCreator<ApiZonesFailurePayload>('UPDATE_ZONE_FAILURE');

export const deleteZone = namespacedActionCreator<Zone>('DELETE_ZONE');
export const deleteZoneSuccessfully = namespacedActionCreator<ArdoqId>(
  'DELETE_ZONE_SUCCESSFULLY'
);
export const deleteZoneFailure =
  namespacedActionCreator<ApiZonesFailurePayload>('DELETE_ZONE_FAILURE');

export const addComponentsToSubdivision =
  namespacedActionCreator<SubdivisionsMembershipsMap>('ADD_COMPONENTS_TO_ZONE');

export const removeComponentsFromSubdivision =
  namespacedActionCreator<SubdivisionsMembershipsMap>(
    'REMOVE_COMPONENTS_FROM_ZONE'
  );

export const removeComponentsFromSubdivisionSuccess = namespacedActionCreator(
  'REMOVE_COMPONENTS_FROM_ZONE_SUCCESS'
);
export const removeComponentsFromSubdivisionFailure = namespacedActionCreator(
  'REMOVE_COMPONENTS_FROM_ZONE_FAILURE'
);

// --------------------------------------------------------
// ---------------    Subdivisions Actions    ---------------
// --------------------------------------------------------

// ---------------    Subdivisions Fetching    ---------------
export const fetchAllSubdivisions = namespacedActionCreator<void>(
  'FETCH_ALL_SUBDIVISIONS'
);

export const fetchedSubdivisionsSuccessfully = namespacedActionCreator<
  Subdivision[]
>('FETCH_ALL_SUBDIVISIONS_SUCCESSFUL');

export const fetchingSubdivisionsFailure =
  namespacedActionCreator<ApiZonesFailurePayload>(
    'FETCH_ALL_SUBDIVISIONS_FAILURE'
  );

export type AddResourcesToSubdivisionPayload = {
  subdivisionId: ArdoqId;
  resourceIds: ArdoqId[];
};
export const addResourcesToSubdivision =
  namespacedActionCreator<AddResourcesToSubdivisionPayload>(
    'ADD_RESOURCE_TO_SUBDIVISION'
  );

// ---------------    Subdivisions Creation    ---------------
export const saveSubdivisionDetailsChanges = namespacedActionCreator<
  Subdivision | NonPersistedSubdivision
>('SAVE_SUBDIVISION_DETAILS_CHANGES');

export const saveSubdivisionDetailsSuccessfully =
  namespacedActionCreator<Subdivision>('SAVE_SUBDIVISION_DETAILS_SUCCESSFULLY');

export const saveSubdivisionDetailsFailure =
  namespacedActionCreator<ApiZonesFailurePayload>(
    'SAVE_SUBDIVISION_DETAILS_FAILURE'
  );

export const deleteSubdivision =
  namespacedActionCreator<ArdoqId>('DELETE_SUBDIVISION');

export const deleteSubdivisionSuccessfully = namespacedActionCreator<ArdoqId>(
  'DELETE_SUBDIVISION_SUCCESSFULLY'
);

export const deleteSubdivisionFailure =
  namespacedActionCreator<ApiZonesFailurePayload>('DELETE_SUBDIVISION_FAILURE');
