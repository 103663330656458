import { Island, IslandHeader } from '@ardoq/page-layout';
import { UserNameDecorator } from './UserNameDecorator';
import { UserProfilePageProps } from '../types';
import { FlexBox } from '@ardoq/layout';
import { SessionsCount } from './components/SessionsCount';
import { EmailSection } from './components/EmailSection';
import { StatusSection } from './components/StatusSection';
import { LastLoginSection } from './components/LastLoginSection';

export const UserPersonalDetails = ({
  user,
}: Pick<UserProfilePageProps, 'user'>) => {
  return (
    <Island
      bodySpacing="large"
      maxWidth="100%"
      header={
        <IslandHeader>
          <UserNameDecorator user={user} />
        </IslandHeader>
      }
    >
      <FlexBox width="full">
        <EmailSection user={user} />
        <SessionsCount user={user} />
        <LastLoginSection user={user} />
        <StatusSection user={user} />
      </FlexBox>
    </Island>
  );
};
