import {
  getAudienceColumnConfig,
  getAudienceStartingPageColumnConfig,
  getComponentColumnConfig,
  getSourceColumnConfig,
  getStatusColumnConfig,
} from 'broadcasts/components/audienceTable/tabbedAudienceTable/audienceByPerson/columnConfig';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { SortByFn, SortById } from '@ardoq/pagination';
import { SortOrder } from '@ardoq/api-types';

export const getMessageColumnConfig =
  (shouldShowStatusColumn: boolean) =>
  (
    expandedRowIds: string[],
    expandRow: (path: string) => void,
    sortByFn: SortByFn,
    sortById: SortById,
    sortOrder: SortOrder
  ) =>
    [
      shouldShowStatusColumn &&
        getStatusColumnConfig(sortByFn, sortById, sortOrder),
      getAudienceColumnConfig(sortByFn, sortById, sortOrder),
      getSourceColumnConfig(sortByFn, sortById, sortOrder),
      getComponentColumnConfig(
        expandedRowIds,
        expandRow,
        sortByFn,
        sortById,
        sortOrder
      ),
    ].filter(ExcludeFalsy);

export const getSurveyColumnConfig =
  (shouldShowStatusColumn: boolean) =>
  (
    expandedRowIds: string[],
    expandRow: (path: string) => void,
    sortByFn: SortByFn,
    sortById: SortById,
    sortOrder: SortOrder
  ) =>
    [
      shouldShowStatusColumn &&
        getStatusColumnConfig(sortByFn, sortById, sortOrder),
      getAudienceColumnConfig(sortByFn, sortById, sortOrder),
      getSourceColumnConfig(sortByFn, sortById, sortOrder),
      getComponentColumnConfig(
        expandedRowIds,
        expandRow,
        sortByFn,
        sortById,
        sortOrder
      ),
      getAudienceStartingPageColumnConfig(sortByFn, sortById, sortOrder),
    ].filter(ExcludeFalsy);
