import {
  dispatchAction,
  reducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { metaModelAsScopeData$ } from '../metaModel/loadMetaModelAsScopeData$';
import {
  requestFetchOptionsInstanceCounts,
  responseInstanceCounts,
  setSectionFilter,
  responseOptionsInstanceCounts,
  setOptionCountLoadingState,
  setStartTypeAndTraversalStartSetOrCount,
  setTripleSortOrder,
  toggleIsSelectedGraphNode,
  toggleIsSelectedGraphEdge,
  toggleShowOnlyOptionsWithInstanceCounts,
  toggleTripleOption,
  setTraversals,
  setPathMatchingType,
  updateTraversalStartContext,
  setShouldIncludeInstanceCounts,
  removeNodeFromGraph,
  resetTraversalBuilderState,
  dontShowAgainClickOtherComponentTypeNodesHints,
} from './editTraversalActions';
import { editTraversalOperations } from './editTraversalOperations';
import {
  addDefaultFilter,
  changeFilterCondition,
  deleteFilter,
  updateFilter,
} from '../addFiltersTab/viewpointBuilderFilterActions';
import { map, Observable, shareReplay, tap } from 'rxjs';
import { TraversalBuilderState, TraversalBuilderViewState } from '../types';
import { viewpointBuilderFiltersOperations } from './viewpointBuilderFiltersOperations';
import { rawFields$ } from '../../streams/fields/fields$';
import { metamodelViewOperations } from '../metaModel/metamodelViewOperations';
import { traversalEditStateToTraversalViewState } from './traversalEditStateToTraversalViewState';
import currentUser$ from 'streams/currentUser/currentUser$';
import { collapsePathsReducers } from '../collapsePathsTab/collapsePathsReducers';
import { setStateProperty } from '@ardoq/common-helpers';
import { requiredComponentsReducers } from '../requiredComponentsTab/requiredComponentsReducers';

export const editTraversals$: Observable<TraversalBuilderViewState> =
  reducedStream<TraversalBuilderState>(
    'editTraversals$',
    editTraversalOperations.getInitialTraversalEditState(),
    [
      streamReducer(
        metaModelAsScopeData$,
        editTraversalOperations.processMetaModel
      ),
      streamReducer(
        currentUser$,
        editTraversalOperations.processCurrentUserSettings
      ),
      streamReducer(rawFields$, metamodelViewOperations.createFieldMap),
      // Resets the selected graph
      reducer(
        setStartTypeAndTraversalStartSetOrCount,
        editTraversalOperations.setStartTypeAndTraversalStartSetOrCount
      ),
      reducer(toggleTripleOption, editTraversalOperations.toggleTripleOption),
      reducer(removeNodeFromGraph, editTraversalOperations.removeNodeFromGraph),
      reducer(
        toggleIsSelectedGraphNode,
        editTraversalOperations.toggleIsSelectedGraphNodeAndUpdateTripleOptions
      ),
      reducer(
        toggleIsSelectedGraphEdge,
        editTraversalOperations.toggleIsSelectedGraphEdgeAndUpdateTripleOptions
      ),
      reducer(setTripleSortOrder, editTraversalOperations.setTripleSortOrder),
      reducer(
        responseInstanceCounts,
        editTraversalOperations.setInstanceCounts
      ),
      reducer(setSectionFilter, editTraversalOperations.setSectionFilter),
      reducer(
        addDefaultFilter,
        viewpointBuilderFiltersOperations.addDefaultFilter
      ),
      reducer(
        changeFilterCondition,
        viewpointBuilderFiltersOperations.changeFilterCondition
      ),
      reducer(updateFilter, viewpointBuilderFiltersOperations.updateFilter),
      reducer(deleteFilter, viewpointBuilderFiltersOperations.deleteFilter),
      reducer(
        responseOptionsInstanceCounts,
        editTraversalOperations.setOptionsInstanceCounts
      ),
      reducer(
        setOptionCountLoadingState,
        editTraversalOperations.setOptionCountLoadingState
      ),
      reducer(
        toggleShowOnlyOptionsWithInstanceCounts,
        editTraversalOperations.toggleShowOnlyOptionsWithInstanceCounts
      ),
      reducer(setTraversals, editTraversalOperations.setTraversal),
      reducer(
        dontShowAgainClickOtherComponentTypeNodesHints,
        editTraversalOperations.dontShowAgainClickOtherComponentTypeNodesHints
      ),
      reducer(
        updateTraversalStartContext,
        editTraversalOperations.updateTraversalStartContext
      ),
      reducer(setPathMatchingType, editTraversalOperations.setPathMatchingType),
      reducer(
        setShouldIncludeInstanceCounts,
        setStateProperty('shouldIncludeInstanceCounts')
      ),
      reducer(
        resetTraversalBuilderState,
        editTraversalOperations.resetTraversalBuilderState
      ),
      ...collapsePathsReducers,
      ...requiredComponentsReducers,
    ]
  ).pipe(
    tap(({ tripleOptions: { optionCountLoadingState, fetchCountsArgs } }) => {
      if (optionCountLoadingState === 'idle' && fetchCountsArgs) {
        dispatchAction(requestFetchOptionsInstanceCounts(fetchCountsArgs));
        setTimeout(() => dispatchAction(setOptionCountLoadingState('loading')));
      }
    }),
    map(traversalEditStateToTraversalViewState),
    shareReplay({ bufferSize: 1, refCount: true })
  );
