import { forEach, isFunction } from 'lodash';
import Backbone, {
  AjaxErrorParams,
  ArdoqBackboneAjaxErrorHandler,
  ArdoqBackboneAjaxOptions,
  ArdoqBackboneAjaxSuccessHandler,
} from 'backbone';
import { mergeObject } from 'sync/merger';
import { logError, logWarn } from '@ardoq/logging';
import { FAILED_UPDATE_ERROR_MESSAGE } from 'consts';
import type { BasicModel, EditorModelExtension } from 'aqTypes';
import { Subject } from 'rxjs';
import { distinctUntilChanged, shareReplay, startWith } from 'rxjs/operators';
import { alert } from '@ardoq/modal';
import { UNAUTHORIZED_ERROR, api, batchApi } from '@ardoq/api';
import { sessionExpired } from './actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { redirectToLogin } from '../authentication';
import {
  renderEventForbiddenError,
  renderSyncResponseError,
  toRenderSyncResponseProps,
} from './syncManagerViewError';
import { isArdoqError, ArdoqError } from '@ardoq/common-helpers';

const backboneSync = Backbone.sync;

const syncProgressSubject$ = new Subject<number>();

export const syncProgress$ = syncProgressSubject$.pipe(
  startWith(1),
  distinctUntilChanged(),
  shareReplay({ bufferSize: 1, refCount: true })
);

function errorUnauthenticated() {
  redirectToLogin();
}

export async function saveModel(model: BasicModel) {
  try {
    await model.save();
  } catch (error) {
    logError(error as Error, 'Failed to save changed model');
    await alert({
      title: 'Saving change failed',
      subtitle: 'Your change could not be saved, and will be reverted',
    });
    await model.fetch();
  }
}

const intMethod2Message = {
  read: 'You might have to refresh the page.',
  patch: 'Changes have not been saved. Will retry operation.',
  update: 'Changes have not been saved. Will retry operation.',
  create: 'Changes have not been saved. Will retry operation.',
  delete: 'Object was not deleted!',
};

const installGlobalUnauthenticatedHandler = () => {
  document.addEventListener(UNAUTHORIZED_ERROR, () => {
    dispatchAction(sessionExpired());
  });
};

const modelMustBeSaved = (model: Backbone.Model): model is BasicModel =>
  Boolean((model as BasicModel).mustBeSaved);

const error = async (
  ajaxErrorParams: AjaxErrorParams,
  response: Response,
  options: ArdoqBackboneAjaxOptions
) => {
  const { status, responseJSON, responseText } = ajaxErrorParams;

  if (
    !options ||
    !options.model ||
    // In presentations we don't want to redirect user to login page.
    // Presentations are handling 401 by themselves
    window?.location.pathname.startsWith('/presentation/')
  ) {
    return;
  }
  const { model, intMethod } = options;

  // For legacy reasons, some models such as Comps, Refs and Tags
  // Are continously saved through the Save Manager if they have changes.
  // If the save failed, we must ensure that we disable the flag
  // to prevent the Save Manager from retrying indefinitely
  if (modelMustBeSaved(model)) {
    model.mustBeSaved = false;
  }

  const serverHeader = response.headers.get('Server');
  const xPoweredByHeader = response.headers.get('X-Powered-By');
  const gotServerResponse =
    response.headers.get('X-Api-version') ||
    (serverHeader && serverHeader.indexOf('nginx') > -1) ||
    (xPoweredByHeader && xPoweredByHeader.indexOf('Express') > -1);

  if (!gotServerResponse) {
    const subtitle = intMethod2Message[intMethod || 'read'];
    alert({
      title: 'Could not connect with Ardoq-servers',
      subtitle: subtitle,
    });

    const responseHeaders = Object.fromEntries([...response.headers]);

    const errorString = 'Lost connection to server';
    logError(new Error(errorString), errorString, {
      message: {
        responseHeaders,
        modelUrl: isFunction(model.url) ? model.url() : model.url,
      },
    });
  } else if (status === 404 && intMethod === 'delete') {
    logWarn(Error('Object was already deleted: '), null, {
      model,
      ajaxErrorParams,
    });
  } else if (status === 409) {
    mergeObject(model);
  } else if (status === 403) {
    renderEventForbiddenError();
  } else if (status === 401) {
    errorUnauthenticated();
  } else if (intMethod !== 'read') {
    let handled = false;
    if (status === 400 && responseJSON) {
      const validationErrors = [];
      if (responseJSON.code === 100) {
        forEach(responseJSON.data, function (err: any) {
          validationErrors.push(err);
        });
      }
      if (responseJSON.errors) {
        // JIRA responds with this format
        forEach(responseJSON.errors, (message, field) =>
          validationErrors.push({ field, message })
        );
      } else {
        validationErrors.push({
          field: 'description',
          message: FAILED_UPDATE_ERROR_MESSAGE + responseText,
        });
      }
      model.validationErrors = validationErrors;
      model.trigger('validationError', validationErrors);

      handled = !!(model as EditorModelExtension).canHandleInvalid;
    }

    if (!handled) {
      const updateError = status === 501 && 'update' === intMethod;

      if (updateError && model.collection) {
        model.collection.remove(model);
      }
      renderSyncResponseError(
        toRenderSyncResponseProps(model, updateError, status, responseText)
      );
    }
  }
};

class SyncManager {
  API_VERSION: string | null = null;

  requestResponseCounter = 0;
  requestDoneCounter = 0;
  totalNumberOfRequest = 0;

  constructor() {
    Backbone.sync = (method, object, options) => {
      this.increaseCounter();
      return backboneSync(method, object, {
        ...options,
        error: this.getErrorHandler(options),
        success: this.getSuccessHandler(options),
        intMethod: method,
        model: object,
      });
    };

    installGlobalUnauthenticatedHandler();
  }

  getErrorHandler(
    options: ArdoqBackboneAjaxOptions
  ): ArdoqBackboneAjaxErrorHandler {
    const onError = typeof options.error === 'function' ? options.error : null;
    return (errorParams, response, options) => {
      this.decreaseCounter();

      // Custom error handling for plugin store due to failed parsing of error
      // response, which causes requireJS loading of plugins to completely halt.
      // Ends in an endless loop during application load (app -> login) and so
      // forth..
      const errorURL = isFunction(options.model?.urlRoot)
        ? options.model?.urlRoot()
        : options.model?.urlRoot;
      const skipNormalErrorHandling =
        errorURL && errorURL.indexOf('/api/plugin/store') > -1;

      if (!skipNormalErrorHandling) {
        error(errorParams, response, options);
      }

      onError?.(errorParams, response, options);
    };
  }

  getSuccessHandler(
    options: ArdoqBackboneAjaxOptions
  ): ArdoqBackboneAjaxSuccessHandler {
    const onSuccess =
      typeof options.success === 'function' ? options.success : null;
    return (
      responseData: unknown,
      response: Response,
      options: ArdoqBackboneAjaxOptions
    ) => {
      try {
        onSuccess?.(responseData, response, options);
      } catch (error) {
        logError(error as Error, 'Error while calling on success method');
      }

      this.success(responseData, response, options);
    };
  }

  success(obj: any, response: Response, { model }: ArdoqBackboneAjaxOptions) {
    this.decreaseCounter();

    if (!this.API_VERSION) {
      this.API_VERSION = response.headers.get('X-Api-version');
      if (this.API_VERSION) window.API_VERSION = this.API_VERSION;
      const versionEl = document.getElementById('sVersion');
      if (versionEl) {
        versionEl.textContent = this.API_VERSION || 'Unknown';
      }
    }

    const comp = obj || model;
    if (comp) {
      delete comp.mustBeSaved;
      // Remove changed attributes... no need to update _version.
    }
  }

  increaseCounter() {
    this.totalNumberOfRequest++;
    this.requestResponseCounter++;

    this.updateProgress();
  }

  decreaseCounter() {
    this.requestResponseCounter--;
    if (this.requestResponseCounter <= 0) {
      this.requestResponseCounter = 0;
      this.totalNumberOfRequest = 0;
    }

    this.updateProgress();
  }

  updateProgress() {
    const total = this.totalNumberOfRequest;
    const completed = this.totalNumberOfRequest - this.requestResponseCounter;
    const progress = total > 0 ? completed / total : 1;

    syncProgressSubject$.next(progress);
  }

  hasRequestsInProgress() {
    return this.requestResponseCounter > 0;
  }

  async batchSave(entities: BasicModel[], type: 'components' | 'references') {
    if (entities.length === 0) {
      return [];
    }

    const newEntities = entities.filter(ent => ent.isNew());
    const newEntityModels = new Map<string, BasicModel>();
    const newEntityBodies: { batchId: string }[] = [];
    for (let i = 0; i < newEntities.length; i++) {
      const batchId = String(i);
      const entity = newEntities[i];
      entity.isSyncInProgress = true;
      newEntityModels.set(batchId, entity);
      newEntityBodies.push({
        ...entity.toJSON(),
        batchId,
      });
    }

    const updatedEntities = entities.filter(ent => !ent.isNew());
    const updatedEntityModels = updatedEntities.reduce(
      (models, model) => models.set(model.id, model),
      new Map<string, BasicModel>()
    );
    const updatedEntityBodies = updatedEntities.map(entity => {
      entity.isSyncInProgress = true;
      return entity.toJSON();
    });

    const failedModels: BasicModel[] = [];

    this.increaseCounter();
    const batchResponse = await batchApi.execute({
      options: { includeEntities: true },
      [type]: {
        ...(updatedEntityBodies.length && { update: updatedEntityBodies }),
        ...(newEntityBodies.length && { create: newEntityBodies }),
      },
    });

    if (isArdoqError(batchResponse)) {
      errorHandler(
        batchResponse,
        updatedEntityModels,
        newEntityModels,
        failedModels
      );
    } else {
      for (const [batchId, model] of newEntityModels) {
        model.isSyncInProgress = false;
        const id = batchResponse[type].ids[batchId];
        const body = batchResponse[type].created?.[id];

        if (!body) {
          failedModels.push(model);
          continue;
        }

        model.set(body);
        model.trigger('sync', model, body, {});
      }
      for (const [id, model] of updatedEntityModels) {
        model.isSyncInProgress = false;
        const body = batchResponse[type].updated?.[id];

        if (!body) {
          failedModels.push(model);
          continue;
        }

        model.set(body);
        model.trigger('sync', model, body, {});
      }
    }
    this.decreaseCounter();

    for (const model of failedModels) {
      // Prevent SaveManager from trying to save this again until we have the
      // latest version from the database
      model.mustBeSaved = false;

      if (model.isNew()) {
        model.trigger('destroy', model, model.collection, {});
      } else {
        model.fetch();
      }
    }

    if (failedModels.length > 0) {
      alert({
        title: 'Saving failed',
        subtitle:
          'Some of your changes could not be saved, and will be reverted',
      });
    }
  }
}

const errorHandler = (
  error: ArdoqError,
  updatedEntityModels: Map<string, BasicModel>,
  newEntityModels: Map<string, BasicModel>,
  failedModels: BasicModel[]
) => {
  [...updatedEntityModels.values(), ...newEntityModels.values()].forEach(
    model => {
      model.isSyncInProgress = false;
      failedModels.push(model);
    }
  );
  if (api.isUnauthorized(error)) {
    errorUnauthenticated();
  } else if (api.isForbidden(error)) {
    renderEventForbiddenError();
  } else if (api.isServiceUnavailable(error)) {
    // This response indicates that the backend in temporarily unavailable
    // so instead of telling the user to panic, we ignore it and let the
    // sync manager try again in a little while.
    return;
  } else {
    logError(error, 'Could not save changes, bad response');
  }
};

export default new SyncManager();
