import broadcastsNavigation$ from 'broadcasts/navigation/broadcastsNavigation$';
import { BroadcastsPane, BroadcastsRoute } from 'broadcasts/navigation/types';
import BroadcastsOverview from 'broadcasts/broadcastOverview/BroadcastOverview';
import BroadcastCreator from 'broadcasts/broadcastCreator/BroadcastCreator';
import BroadcastEditor from 'broadcasts/broadcastEditor/BroadcastEditor';
import { connect } from '@ardoq/rxbeach';
import ModuleContainer from './ModuleContainer';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';

const BroadcastsContainer = ({
  broadcastId,
  broadcastsPane,
}: BroadcastsRoute) => (
  <ModuleContainer $overflow="hidden">
    <ErrorBoundary logError={logError}>
      {broadcastsPane === BroadcastsPane.NEW ? (
        <BroadcastCreator />
      ) : broadcastsPane === BroadcastsPane.EDIT && broadcastId ? (
        <BroadcastEditor />
      ) : (
        <BroadcastsOverview />
      )}
    </ErrorBoundary>
  </ModuleContainer>
);

export default connect(BroadcastsContainer, broadcastsNavigation$);
