import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { filter, map, switchMap, combineLatest, Observable } from 'rxjs';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { getLoadingMessage } from '../utils';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import { vpcs$ } from './vpcs/vpcs$';
import { selectionState$ } from './selectionState/selectionState$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';

export type OverlayState = {
  isLoading: boolean;
  message: string;
};

export const overlay$: Observable<OverlayState> = integrationId$.pipe(
  filter(integrationId => integrationId === 'aws-v3'),
  switchMap(integrationId => {
    return combineLatest([
      selectionState$,
      vpcs$,
      getTransferStateStream(integrationId).pipe(
        map(val => ({ asyncStatus: val.requestStatus }))
      ),
      getRegionsStream('aws-v3'),
      getConnectionsStream(integrationId).pipe(
        map(val => ({ asyncStatus: val.statuses.list.status }))
      ),
    ]);
  }),
  map(
    ([
      selectionState,
      vpcsState,
      transferState,
      regionState,
      connectionsState,
    ]) => {
      const isLoading = [
        selectionState,
        transferState,
        regionState,
        ...Object.values(vpcsState.connectionsVpcs),
        connectionsState,
      ].some(({ asyncStatus }) => asyncStatus === 'LOADING');
      return {
        isLoading,
        message: getLoadingMessage({
          selectionState,
          transferState,
          regionState,
          vpcsState,
          connectionsState,
        }),
      };
    }
  )
);
