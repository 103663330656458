import { getDOMNodeInNavigatorWithDataId } from 'components/WorkspaceHierarchies/getDOMNodeInNavigatorWithDataId';

export const getTarget = ({ target }: Event) => {
  if (!(target instanceof Element)) return null;
  return target.closest('.component-item[data-id], [data-component-id]');
};
export const getTargetId = (target: Element | null | undefined) =>
  target &&
  (target.getAttribute('data-component-id') ||
    target.getAttribute('data-id') ||
    getDOMNodeInNavigatorWithDataId(target));
