import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
} from '@ardoq/rxbeach';
import { switchMap, tap } from 'rxjs';
import { getComponentTypes, getComponentTypesFinished } from './actions';
import { loadMetaModel } from 'architectureModel/loadMetaModel';
import { MetaModelComponentType } from '@ardoq/api-types';
import { alert } from '@ardoq/modal';
import { isArdoqError } from '@ardoq/common-helpers';

const handleGetComponentTypes = routine(
  ofType(getComponentTypes),
  switchMap(loadMetaModel),
  tap(async metaModelResult => {
    let componentTypes: MetaModelComponentType[];
    if (isArdoqError(metaModelResult)) {
      componentTypes = [];
      await alert({
        title: 'Loading meta model failed',
        subtitle: 'Loading the meta model failed.',
        text: '',
        confirmButtonTitle: 'Close',
      });
    } else {
      componentTypes = metaModelResult.componentTypes;
    }
    dispatchAction(getComponentTypesFinished(componentTypes));
  })
);

export const componentTypesRoutines = collectRoutines(handleGetComponentTypes);
