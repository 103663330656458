import { SSOMappingCondition } from '@ardoq/api-types';
import {
  ConditionOption,
  ConditionOptionsByType,
  PreviewAttributeType,
} from './types';

export const STRINGS = {
  HEADER: {
    TITLE: 'SSO attribute mapping',
    SUBTITLE:
      'Automatically manage group membership via SSO attributes, updated at the next login.',
  },
  BUTTON: {
    ADD_MAPPING: 'Add mapping',
    SAVE: 'Save mapping rules',
    REMOVE_POPOVER: 'Remove mapping',
    PREVIEW: 'Test SSO mapping rules',
  },
  MAPPINGRULESTABLE: {
    HEADER: {
      ATTRIBUTE_NAME: 'Attribute name',
      ATTRIBUTE_TYPE_POPOVER:
        'Attribute types are defined in your SSO provider. You can only select text attributes.',
      CONDITION: 'Condition',
      VALUE: 'Value',
      VALUE_POPOVER: "Specify the attribute's value.",
      SSO_PROVIDER: 'SSO Provider',
      SSO_PROVIDER_POPOVER: 'Which SSO provider to apply the mapping to',
      ASSIGNMENT_RULES: 'Assignment rules',
    },
    POPOVER: {
      SAVE_TO_APPLY: 'Click "Save mapping rules" to enable this rule.',
      IN_USE: 'This mapping is currently in use.',
      DEACTIVATED_DUE_TO_MISSING_SSO_PROVIDER:
        'This mapping is currently disabled due to a missing SSO provider selection.',
    },
  },
  SELECT_ATTRIBUTE_TYPE: {
    PLACEHOLDER: 'Type to search an attribute type',
  },
  SELECT_GROUP: {
    PLACEHOLDER: 'Select a group',
  },
  SELECT_PROVIDER: {
    PLACEHOLDER: 'Select a provider for the preview',
  },
  TOAST: {
    CREATED_SUCCESS: 'mapping created',
    UPDATED_SUCCESS: 'mapping updated',
    CREATED_SUCCESS_ALT: 'mappings created',
    UPDATED_SUCCESS_ALT: 'mappings updated',
    DELETED_SUCCESS: 'mapping deleted',
    DELETED_SUCCESS_ALT: 'mappings deleted',
  },
  DELETE_MODAL: {
    TITLE: 'Delete mapping?',
    CONFIRM_DELETE:
      'Confirm to delete the selected mapping. The mapping will be deleted permanently and this cannot be undone.',
    DELETION_IRREVERSIBLE: 'Deletion is irreversible',
    DELETE_MAPPING: 'Delete mapping',
  },
  TIMESTAMP: 'Mapping data has been updated at:',
  NO_SSO: {
    TITLE: 'SSO is not configured',
    MESSAGE:
      'SSO attribute mapping is available to SSO configured organizations. only. Learn how to configure SSO.',
    LINK: 'Knowledge Base',
  },
  NO_MULTIPLE_SSO: {
    TITLE: 'Oops! We’re not there yet...',
    MESSAGE:
      'We’re working on supporting SSO attribute mapping with two providers. Please deactivate one provider to proceed.',
    LINK: 'Knowledge Base',
  },
  NO_GROUPS_CONFIGURED:
    'Your organization does not have any groups. Create a group under Organization settings > Groups, then use SSO mapping to assign users to it.',
  MENU_LABEL: 'SSO attribute mapping',
  LEAVE_GROUPS_INFO: `Access to the groups below is no longer granted by the current mapping
  rules. To restore access for you and others, create a mapping rule
  that assigns users to the group.`,
  UNMAPPED: 'Unmapped',
  PREVIEW_TABLE: {
    PLACEHOLDER_TITLE: 'Mappings preview',
    PLACEHOLDER_MESSAGE: 'The preview will be displayed here once requested',
    HEADER: {
      ATTRIBUTE_NAME: 'Attribute name',
      ATTRIBUTE_TYPE: 'Attribute type',
      VALUE: 'Value from SSO',
      JOIN_GROUPS: 'Newly joined groups',
      LEAVE_GROUPS: 'Former groups',
    },
    ATTRIBUTE_TYPES: {
      STRING: 'String',
      ARRAY: 'List',
      NULL: 'Null value',
      UNKNOWN: 'Unsupported type',
    },
  },
  RULE_COUNT: 'Rule count',
  NOT_APPLICABLE_TO_USER: 'Not applicable to user',
  SSO_LOGIN_REQUIRED: 'SSO login is required',
  SIGNIN_WITH_SSO: 'Sign in with SSO to use SSO attribute mapping.',
  SIGNIN_WITH_SSO_ALT:
    'Sign in with SSO to see the results of your mapping rules.',
};

const ELEMENT_CONTAINS_OPTION: ConditionOption = {
  label: 'Element contains',
  value: SSOMappingCondition.ELEMENT_CONTAINS,
};

const ELEMENT_EQUALS_OPTION: ConditionOption = {
  label: 'Element equals',
  value: SSOMappingCondition.ELEMENT_EQUALS,
};

const STRING_CONTAINS_OPTION: ConditionOption = {
  label: 'String contains',
  value: SSOMappingCondition.STRING_CONTAINS,
};

const STRING_EQUALS_OPTION: ConditionOption = {
  label: 'String equals',
  value: SSOMappingCondition.STRING_EQUALS,
};

export const CONDITION_OPTIONS_BY_TYPE: ConditionOptionsByType = {
  [PreviewAttributeType.STRING]: [STRING_CONTAINS_OPTION, STRING_EQUALS_OPTION],
  [PreviewAttributeType.ARRAY]: [
    ELEMENT_CONTAINS_OPTION,
    ELEMENT_EQUALS_OPTION,
  ],
  [PreviewAttributeType.UNKNOWN]: [
    STRING_CONTAINS_OPTION,
    STRING_EQUALS_OPTION,
    ELEMENT_CONTAINS_OPTION,
    ELEMENT_EQUALS_OPTION,
  ],
  [PreviewAttributeType.NULL]: [
    STRING_CONTAINS_OPTION,
    STRING_EQUALS_OPTION,
    ELEMENT_CONTAINS_OPTION,
    ELEMENT_EQUALS_OPTION,
  ],
};

export const AttributeTypeToConditionMap = {
  [PreviewAttributeType.ARRAY]: SSOMappingCondition.ELEMENT_EQUALS,
  [PreviewAttributeType.STRING]: SSOMappingCondition.STRING_EQUALS,
  [PreviewAttributeType.NULL]: '',
  [PreviewAttributeType.UNKNOWN]: '',
};
