import {
  FieldQuestionConfiguratorProps,
  SurveyBuilderLocation,
  SurveyQuestionError,
} from 'surveyAdmin/types';
import { FieldsWrapper, Label, TextInput } from '@ardoq/forms';
import ReadOnlyCheckboxRow from './ReadOnlyCheckboxRow';
import { StyledSpace } from './questionAtoms';
import { RequiredField } from './RequiredField';
import {
  PermissionAccessLevel,
  PersistedParentQuestion,
} from '@ardoq/api-types';
import ComponentSubsetSelector from 'surveyAdmin/ComponentSubsetSelector';
import { QUESTION_HELP_TEXT_EXPLANATION } from 'surveyAdmin/SurveyEditor/consts';
import { noop } from 'lodash';
import { ERROR_MESSAGES } from 'surveyAdmin/consts';
import {
  isAttributeQuestion,
  isConditionalQuestion,
  isParentQuestion,
} from './questionPredicates';
import { hasRequiredValidator } from 'surveyAdmin/SurveyEditor/utils';
import { RichTextEditorTransition } from '@ardoq/rich-text-editor-2024';
import { questionValidationsHasMessage } from './utils';

const FieldQuestionConfigurator = ({
  fieldIsReadonly,
  question,
  parentQuestionIsReadonly,
  showRequiredField,
  advancedSearchLimiter,
  showAdvancedOptions,
  surveyWorkspaceId,
  questionValidations,
  allowComponentCreation,
  updateQuestion,
}: FieldQuestionConfiguratorProps) => {
  const description = `${
    parentQuestionIsReadonly
      ? 'This question is a sub-question of a read-only question and has to be read-only.'
      : ''
  }
      ${
        fieldIsReadonly
          ? 'This field is either calculated or externally managed and has to be read-only.'
          : ''
      }`;

  return (
    <>
      <ReadOnlyCheckboxRow
        readonly={question.readonly}
        validators={question.validators}
        descriptionExtension={description}
        updateReadonlyAndValidators={readOnlyProps => {
          if (!isConditionalQuestion(question) || !readOnlyProps.readonly) {
            updateQuestion({ ...question, ...readOnlyProps });
            return;
          }
          const dependentQuestionsUpdatedToBeReadOnly =
            question.properties.questions?.map(question => ({
              ...question,
              readonly: readOnlyProps.readonly,
            }));
          updateQuestion({
            ...question,
            ...readOnlyProps,
            properties: {
              ...question.properties,
              questions: dependentQuestionsUpdatedToBeReadOnly,
            },
          });
        }}
        disabled={parentQuestionIsReadonly || fieldIsReadonly}
        fieldIsReadonly={parentQuestionIsReadonly || fieldIsReadonly}
        allowComponentCreation={allowComponentCreation}
        isNameQuestion={
          isAttributeQuestion(question) &&
          question.properties.attributeName === 'name'
        }
      />
      <FieldsWrapper>
        <TextInput
          label="Question title"
          value={question.label}
          onValueChange={label => updateQuestion({ ...question, label })}
          dataTestId="title-input"
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <StyledSpace $isVertical $gap="s4">
          <Label popoverHelpContent={QUESTION_HELP_TEXT_EXPLANATION}>
            Question help text
          </Label>
          <RichTextEditorTransition
            key={
              isAttributeQuestion(question)
                ? question.properties.attributeName
                : question.properties.fieldName
            }
            content={question.description}
            onInputChange={description =>
              updateQuestion({ ...question, description })
            }
            dataTestId="help-input"
          />
        </StyledSpace>
      </FieldsWrapper>
      {showRequiredField && (
        <RequiredField
          isRequired={Boolean(hasRequiredValidator(question))}
          validators={question.validators}
          updateValidators={validators =>
            updateQuestion({ ...question, validators })
          }
        />
      )}
      {isParentQuestion(question) && advancedSearchLimiter && (
        <FieldsWrapper>
          <ComponentSubsetSelector
            accessLevel={PermissionAccessLevel.EDIT}
            hideWorkspaceSelector
            hideComponentTypeSelector
            workspace={surveyWorkspaceId}
            surveyWorkspaceId={surveyWorkspaceId}
            advancedSearchRules={advancedSearchLimiter}
            advancedSearchCondition={advancedSearchLimiter.condition}
            updateParentState={({
              advancedSearchRules,
              advancedSearchCondition,
            }) => {
              if (!isParentQuestion(question)) return;
              if (advancedSearchRules) {
                updateQuestion({
                  ...question,
                  properties: {
                    ...question.properties,
                    advancedSearchLimiter: advancedSearchRules,
                  },
                });
              }
              if (advancedSearchCondition) {
                updateQuestion({
                  ...question,
                  properties: {
                    ...question.properties,
                    advancedSearchLimiter: {
                      ...advancedSearchLimiter,
                      condition: advancedSearchCondition,
                    },
                  },
                });
              }
            }}
            showAdvancedOptions={showAdvancedOptions}
            toggleAdvancedOptions={() => {
              updateQuestion({
                ...question,
                properties: {
                  ...question.properties,
                  advancedSearchLimiter: showAdvancedOptions
                    ? undefined
                    : { ...advancedSearchLimiter, rules: [] },
                },
              } as PersistedParentQuestion);
            }}
            compTypeMissingErrorMessage={
              questionValidationsHasMessage(
                questionValidations,
                SurveyQuestionError.SELECTED_COMPONENT_TYPE_DOES_NOT_EXIST
              )
                ? ERROR_MESSAGES.COMPONENT_TYPE_MISSING_OR_INVALID
                : undefined
            }
            updateWorkspace={noop}
            updateComponentTypeName={noop}
            surveyBuilderLocation={SurveyBuilderLocation.PARENT_QUESTION}
          />
        </FieldsWrapper>
      )}
    </>
  );
};

export default FieldQuestionConfigurator;
