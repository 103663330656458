import { DASHBOARD_MAX_WIDTH } from '@ardoq/dashboard';
import { TabButton } from '@ardoq/tabs';
import { ButtonGroup } from '@ardoq/button';
import { UseCaseLandingPageIslandProps, UseCaseLandingPageTab } from './types';
import UseCaseLandingPageMetamodelPreview from './UseCaseLandingPageMetamodelPreview';
import UseCasePreviewContent from './UseCasePreviewContent';
import { USE_CASE_LANDING_PAGE_ISLAND_MIN_HEIGHT } from 'useCases/consts';
import { Island } from '@ardoq/page-layout';

const UseCaseLandingPageIsland = (props: UseCaseLandingPageIslandProps) => (
  <Island
    minHeight={USE_CASE_LANDING_PAGE_ISLAND_MIN_HEIGHT}
    maxWidth={`${DASHBOARD_MAX_WIDTH}px`}
    preventScroll={props.activeTab === UseCaseLandingPageTab.METAMODEL}
    isScrollable={props.activeTab === UseCaseLandingPageTab.SOLUTION_MATERIAL}
    bodySpacing="xlarge"
  >
    <ButtonGroup>
      {props.tabProps.map(tabProp => (
        <TabButton {...tabProp} key={tabProp.tabId} />
      ))}
    </ButtonGroup>
    {props.activeTab === UseCaseLandingPageTab.METAMODEL && (
      <UseCaseLandingPageMetamodelPreview {...props.metamodelPreviewProps} />
    )}
    {props.activeTab === UseCaseLandingPageTab.SOLUTION_MATERIAL && (
      <UseCasePreviewContent {...props.previewContentProps} />
    )}
  </Island>
);

export default UseCaseLandingPageIsland;
