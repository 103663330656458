import { PaneWrapper } from './atoms';
import NavigatorTopBar from './NavigatorTopBar/NavigatorTopBar';
import NavigatorRoot from 'navigator2024/NavigatorRoot';
import { NavigatorTopBarStreamShape } from './NavigatorTopBar/types';

const NavigatorPane = ({
  topBarProps,
}: {
  topBarProps: NavigatorTopBarStreamShape;
}) => (
  <PaneWrapper>
    <NavigatorTopBar {...topBarProps} />
    <NavigatorRoot />
  </PaneWrapper>
);

export default NavigatorPane;
