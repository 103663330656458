import { Wrapper } from './atoms';
import styled from 'styled-components';
import { SmallSecondaryButton } from '@ardoq/button';

const ErrorWithRetryWrapper = styled(Wrapper)`
  justify-content: center;
  align-items: center;
`;

const ErrorMessage = styled.span`
  font-size: 20px;
  margin-bottom: 10px;
`;

type ErrorWithRetryProperties = { message: string; retryAction: VoidFunction };
const ErrorWithRetry = ({ message, retryAction }: ErrorWithRetryProperties) => {
  return (
    <ErrorWithRetryWrapper>
      <ErrorMessage>{message}</ErrorMessage>
      <SmallSecondaryButton onClick={retryAction}>Retry</SmallSecondaryButton>
    </ErrorWithRetryWrapper>
  );
};

export default ErrorWithRetry;
