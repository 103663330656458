import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Icon, IconName, IconSize } from '@ardoq/icons';

export const ExternallyManagedIndicatorWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const ExternallyManagedText = styled.span`
  margin-left: 4px;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const ExternallyManagedIndicator: FC<
  HTMLAttributes<HTMLSpanElement>
> = props => (
  <ExternallyManagedIndicatorWrapper {...props}>
    <Icon iconName={IconName.EXTERNALLY_MANAGED} iconSize={IconSize.SMALL} />
    <ExternallyManagedText>Externally managed</ExternallyManagedText>
  </ExternallyManagedIndicatorWrapper>
);

export default ExternallyManagedIndicator;
