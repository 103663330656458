import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  generateValueProposition,
  generateValueStream,
  setStreamLink,
  setValueProposition,
  submitValuePropositionModal,
  submitValueStreamModal,
  failedValueProposition,
} from './actions';
import { delay, filter, switchMap, tap } from 'rxjs';
import { api, handleError, augmentationsApi } from '@ardoq/api';
import { trackEvent } from 'tracking/tracking';
import { ToastType, showToast } from '@ardoq/status-ui';

const handleSubmitValueProposition = routine(
  ofType(submitValuePropositionModal),
  extractPayload(),
  filter(({ valueProposition }) => Boolean(valueProposition)),
  switchMap(async ({ valueProposition, closeModal }) => {
    const response = await augmentationsApi.submitValueProposition({
      description: valueProposition,
    });
    const responseAndOperations = {
      ...response,
      closeModal,
    };
    return responseAndOperations;
  }),
  handleError(ardoqError => {
    dispatchAction(failedValueProposition());
    api.logErrorIfNeeded(ardoqError);
    showToast('Value Proposition failed to create', ToastType.ERROR);
  }),
  tap(responseAndOperations => {
    responseAndOperations.closeModal(true);
  }),
  delay(750),
  tap(() => {
    showToast('Value Proposition created successfully', ToastType.SUCCESS);
  })
);

const handleSubmitValueStream = routine(
  ofType(submitValueStreamModal),
  extractPayload(),
  switchMap(async ({ /* streamLink, */ closeModal }) => {
    // TODO open link
    closeModal(true);
  })
);
const handleGenerateValueStream = routine(
  ofType(generateValueStream),
  extractPayload(),
  filter(({ company, description, valueProposition, industry }) =>
    Boolean(company && description && valueProposition && industry)
  ),
  switchMap(async ({ company, description, valueProposition, industry }) => {
    return augmentationsApi.submitValueStream({
      name: company,
      description,
      industry,
      valueProposition,
    });
  }),
  handleError(ardoqError => {
    dispatchAction(setStreamLink('Error'));
    api.logErrorIfNeeded(ardoqError);
  }),
  tap(response => {
    dispatchAction(setStreamLink(response.branchId));
  })
);

const handleGenerateValueProposition = routine(
  ofType(generateValueProposition),
  extractPayload(),
  filter(({ company, description }) => Boolean(company && description)),
  switchMap(async ({ company, description }) => {
    const response = await augmentationsApi.generateDescription({
      name: company,
      description: description,
    });
    trackEvent(
      'Generate value proposition in value propoition modal in core app'
    );
    return response;
  }),
  handleError(ardoqError => {
    dispatchAction(setValueProposition('Error'));
    api.logErrorIfNeeded(ardoqError);
  }),
  tap(response => {
    if (response?.type === 'description') {
      dispatchAction(setValueProposition(response.text));
    } else {
      dispatchAction(setValueProposition('Error'));
      trackEvent(
        'Generate value proposition LLM could not generate description',
        {
          reason: response.text,
        }
      );
    }
  })
);

export const augmentationModalRoutines = collectRoutines(
  handleSubmitValueProposition,
  handleGenerateValueProposition,
  handleSubmitValueStream,
  handleGenerateValueStream
);
