import { s32 } from '@ardoq/design-tokens';
import styled from 'styled-components';

export const PageWrapperWithPadding = styled.div`
  display: flex;
  margin: 0 auto;
  padding-left: ${s32};
  padding-right: ${s32};
`;

export const PageWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  height: 100%;
`;

export const PageContainer = styled.div`
  overflow: auto;
  width: 100%;
  margin: 0 auto;
`;
