import {
  SimplifiedSubdivisionSummary,
  SubdivisionSummary,
} from '@ardoq/api-types';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  fetchSimplifiedSubdivisionSummary,
  fetchSimplifiedSubdivisionSummaryFailed,
  fetchSimplifiedSubdivisionSummarySuccess,
  fetchSubdivisionSummary,
  fetchSubdivisionSummaryFailed,
  fetchSubdivisionSummarySuccess,
} from './actions';
import { subdivisionSummaryOperations } from './operations';

export type SubdivisionSummaryState = {
  isLoading: boolean;
  subdivisionSummary?: SubdivisionSummary;
  simplifiedSubdivisionSummary?: SimplifiedSubdivisionSummary;
  errorMessage: string;
};

const defaultState: SubdivisionSummaryState = {
  isLoading: false,
  errorMessage: '',
};

const reducers = [
  reducer(
    fetchSubdivisionSummary,
    subdivisionSummaryOperations.setIsLoadingReducer
  ),
  reducer(
    fetchSubdivisionSummarySuccess,
    subdivisionSummaryOperations.setSubdivisionSummaryAfterLoadingReducer
  ),
  reducer(
    fetchSubdivisionSummaryFailed,
    subdivisionSummaryOperations.setSubdivisionSummaryErrorAfterLoadingReducer
  ),
  reducer(
    fetchSimplifiedSubdivisionSummary,
    subdivisionSummaryOperations.setIsLoadingReducer
  ),
  reducer(
    fetchSimplifiedSubdivisionSummarySuccess,
    subdivisionSummaryOperations.setSimplifiedSubdivisionSummaryAfterLoadingReducer
  ),
  reducer(
    fetchSimplifiedSubdivisionSummaryFailed,
    subdivisionSummaryOperations.setSimplifiedSubdivisionSummaryErrorAfterLoadingReducer
  ),
];

export const subdivisionSummary$ =
  persistentReducedStream<SubdivisionSummaryState>(
    'subdivisionSummary$',
    defaultState,
    reducers
  );
