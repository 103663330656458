import { Box, FlexBox, Stack } from '@ardoq/layout';
import {
  Chip,
  ChipsList,
  ChipsSeparator,
  FilterChip,
  IconBadge,
  StatusType,
} from '@ardoq/status-ui';
import { FormSize, SearchInput } from '@ardoq/forms';
import { SecondaryButton } from '@ardoq/button';
import { FilterListIcon, Icon, IconName, IconSize } from '@ardoq/icons';
import { UserAssetsOverviewState } from 'admin/accessControl/UserProfilePage/userAssetsOverview/userAssetsOverviewTypes';
import { UserProfilePageProps } from '../../types';
import { AccessControlPageProps } from 'appContainer/AccessControlPage/types';
import { userAssetsOverviewQueryOperations } from '../../userAssetsOverview/userAssetsOverviewQueryOperations';
import styled from 'styled-components';
import { colors, s8 } from '@ardoq/design-tokens';
import { UserAssetsOverviewTabContent } from './UserAssetsOverviewTabContent';

const FiltersContainer = styled(Box)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  gap: ${s8};
  position: relative;
`;

type UserAssetsOverviewProps = {
  userAssetsOverview: UserAssetsOverviewState;
  commands: UserProfilePageProps['commands'];
  userAssetsFilterSidebarState: UserProfilePageProps['userAssetsFilterSidebarState'];
  userAssetsQuery: AccessControlPageProps['userAssetsQuery'];
  locallyFilteredUserAssets: AccessControlPageProps['locallyFilteredUserAssets'];
};

export const UserAssetsOverviewTab = (props: UserAssetsOverviewProps) => {
  const { commands, userAssetsQuery, userAssetsFilterSidebarState } = props;
  return (
    <Stack gap="xlarge">
      <FiltersContainer>
        <SearchInput
          value={userAssetsOverviewQueryOperations.getSearchString(
            userAssetsQuery
          )}
          onValueChange={commands.onSearchByAssetNameChange}
          placeholder="Search by asset or folder name"
          formSize={FormSize.DEFAULT}
          debounceTime={300}
        />
        {userAssetsFilterSidebarState.hasActiveFilters ? (
          <FlexBox
            gap="small"
            style={{
              minWidth: 0,
              overflow: 'auto',
            }}
          >
            <ChipsList>
              {userAssetsOverviewQueryOperations
                .getAssetTypesOptions(userAssetsQuery)
                .map(({ value, iconName, label }) => (
                  <FilterChip
                    key={value}
                    onClose={() => {
                      commands.handleAssetTypeChipClick(userAssetsQuery, {
                        excludeList: [value],
                      });
                    }}
                  >
                    <FlexBox gap="xsmall" align="center">
                      <Icon
                        color={colors.textSubtle}
                        iconName={iconName}
                        size={IconSize.SMALL}
                      />
                      {label}
                    </FlexBox>
                  </FilterChip>
                ))}
              {userAssetsOverviewQueryOperations
                .getPermissionLevelsOptions(userAssetsQuery)
                .map(({ value, iconName, label }) => (
                  <FilterChip
                    key={value}
                    onClose={() => {
                      commands.handlePermissionLevelChipClick(userAssetsQuery, {
                        excludeList: [value],
                      });
                    }}
                  >
                    <FlexBox gap="xsmall" align="center">
                      <Icon
                        color={colors.textSubtle}
                        iconName={iconName}
                        size={IconSize.SMALL}
                      />
                      {label}
                    </FlexBox>
                  </FilterChip>
                ))}
            </ChipsList>
            <ChipsSeparator />
            <Chip
              onClick={() => commands.clearAllFilters(userAssetsQuery)}
              style={{ marginTop: '2px' }}
            >
              Clear All
            </Chip>
          </FlexBox>
        ) : (
          <Box></Box>
        )}
        <SecondaryButton
          onClick={() =>
            commands.toggleUserAssetsFilterSidebarVisibility(
              userAssetsFilterSidebarState.isVisible
            )
          }
        >
          <FlexBox gap="small" paddingX="medium">
            {userAssetsFilterSidebarState.hasActiveFilters ? (
              <IconBadge
                iconName={IconName.FILTER_LIST}
                statusType={StatusType.NEW}
                iconStyle={{ margin: 0 }}
              />
            ) : (
              <FilterListIcon style={{ margin: 0 }} />
            )}
            <Box>Filter</Box>
            {userAssetsFilterSidebarState.hasActiveFilters && (
              <Box>
                (
                {userAssetsQuery.assetTypes.length +
                  userAssetsQuery.permissionLevels.length}
                )
              </Box>
            )}
          </FlexBox>
        </SecondaryButton>
      </FiltersContainer>
      <UserAssetsOverviewTabContent {...props} />
    </Stack>
  );
};
