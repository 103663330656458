import { colors, s16, s8 } from '@ardoq/design-tokens';
import { Label } from '@ardoq/forms';
import styled from 'styled-components';

interface GriddedTableRowProps {
  $hideBorder?: boolean;
}

export const GriddedTableRow = styled.div<GriddedTableRowProps>`
  ${({ $hideBorder }) =>
    $hideBorder
      ? `padding: 0 ${s8};`
      : `
    border: 1px dashed ${colors.grey80};
    border-radius: 8px;
    padding: ${s16} ${s8};
  `};
  display: grid;
  grid-template-columns: 30% auto 20% 20% fit-content(${s16});
  grid-gap: ${s8};
`;

export const TableHeaderLabel = styled(Label)`
  margin-left: ${s16};
  margin-bottom: ${s8};
`;

export const RulesSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${s16};
`;
