import { dispatchAction } from '@ardoq/rxbeach';
import * as actions from './actions';
import {
  OpenViewpointBuilderCommands,
  openViewpointBuilderCommands,
} from 'viewpointBuilder/openViewpointBuilderCommands';
import {
  ViewpointPreviewCommands,
  viewpointPreviewCommands,
} from 'components/TraversalPreview/viewpointPreviewCommands';

export type SearchViewpointCommands = {
  setSelectedViewpointIds: (viewpointIds: string[]) => void;
  toggleSelection: (viewpointId: string) => void;
  toggleShouldDisplayOnlyViewpointsOfCurrentUser: () => void;
  setSearchPhrase: (searchPhrase: string) => void;
} & OpenViewpointBuilderCommands &
  ViewpointPreviewCommands;

export const searchViewpointCommands: SearchViewpointCommands = {
  setSelectedViewpointIds: (viewpointIds: string[]) =>
    dispatchAction(actions.setSelectedViewpointIds(viewpointIds)),

  toggleSelection: (viewpointId: string) => {
    dispatchAction(actions.toggleSelection(viewpointId));
  },

  toggleShouldDisplayOnlyViewpointsOfCurrentUser: () => {
    dispatchAction(actions.toggleShouldDisplayOnlyViewpointsOfCurrentUser());
  },

  setSearchPhrase: (searchPhrase: string) => {
    dispatchAction(actions.setSearchPhrase(searchPhrase));
  },

  ...openViewpointBuilderCommands,
  ...viewpointPreviewCommands,
};
