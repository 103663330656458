import { dispatchAction } from '@ardoq/rxbeach';
import {
  addNewToCurrentSSOMapping,
  bulkSubmitSSOAttributeMapping,
  deleteSSOAttributeMapping,
  getAggregatedSSOMappings,
  getPreviewSSOMapping,
  setShowValidationErrorsSSOMapping,
  setSsoSelectedForPreview,
  updateCurrentSSOMapping,
} from './actions';
import currentUser from 'models/currentUser';
import { PersonalSetting } from '@ardoq/api-types';
import { setUnsavedState } from 'streams/unsavedState/actions';
import { UnsavedStateOwner } from 'streams/unsavedState/types';
import { RulesById, SsoOption } from './types';
import { SelectOption } from '@ardoq/select';
import { fieldToPath } from './utils';

export type SSOAttributeMappingCommands = {
  loadMappings: () => void;
  setUnsaved: (changes: RulesById) => void;
  saveMappings: (
    isValidChanges: boolean,
    changes: RulesById,
    deletions: string[]
  ) => void;
  addNewEmptyMapping: () => void;
  getPreview: (
    isValidChanges: boolean,
    currentMapping: RulesById,
    allowMultipleSsoOptionsInMappings: boolean,
    ssoSelectedForPreview: SsoOption | null
  ) => void;
  selectSsoForPreview: (selected: SelectOption<string> | null) => void;
  updateMapping: (id: string, field: string, value?: string) => void;
  deleteMapping: (id: string) => void;
};

const loadMappings = () => {
  dispatchAction(getAggregatedSSOMappings());

  if (
    !currentUser.getPersonalSetting(
      PersonalSetting.HAS_SEEN_SSO_ATTRIBUTE_MAPPING
    )
  ) {
    currentUser.setPersonalSetting(
      PersonalSetting.HAS_SEEN_SSO_ATTRIBUTE_MAPPING,
      true
    );
  }
};

const setUnsaved = (changes: RulesById) => {
  dispatchAction(
    setUnsavedState({
      [UnsavedStateOwner.SSO_MAPPING]: Object.values(changes).length > 0,
    })
  );
};

const saveMappings = (
  isValidChanges: boolean,
  changes: RulesById,
  deletions: string[]
) => {
  if (!isValidChanges) {
    dispatchAction(setShowValidationErrorsSSOMapping(true));
  } else {
    dispatchAction(
      bulkSubmitSSOAttributeMapping({
        changes: Object.values(changes),
        deletions,
      })
    );
  }
};

const addNewEmptyMapping = () => {
  dispatchAction(addNewToCurrentSSOMapping());
};

const getPreview = (
  isValidChanges: boolean,
  currentMapping: RulesById,
  allowMultipleSsoOptionsInMappings: boolean,
  ssoSelectedForPreview: SsoOption | null
) => {
  if (!isValidChanges) {
    dispatchAction(setShowValidationErrorsSSOMapping(true));
  } else {
    dispatchAction(
      getPreviewSSOMapping({
        mappings: Object.values(currentMapping),
        ...(allowMultipleSsoOptionsInMappings &&
          ssoSelectedForPreview && {
            provider: ssoSelectedForPreview.value,
          }),
      })
    );
  }
};

const selectSsoForPreview = (selected: SelectOption<string> | null) =>
  dispatchAction(setSsoSelectedForPreview(selected));

const updateMapping = (id: string, field: string, value?: string) => {
  if (value === undefined) return;
  dispatchAction(
    updateCurrentSSOMapping({
      path: fieldToPath(id, field),
      value: value,
    })
  );
};

const deleteMapping = (id: string) => {
  dispatchAction(deleteSSOAttributeMapping(id));
};

export const commands: SSOAttributeMappingCommands = {
  loadMappings,
  setUnsaved,
  saveMappings,
  addNewEmptyMapping,
  getPreview,
  selectSsoForPreview,
  updateMapping,
  deleteMapping,
};
