import { reducedStream, reducer, streamReducer } from '@ardoq/rxbeach';
import {
  drilldownWasTriggered,
  excelExportFailed,
  filterQueryChanged,
  pageChanged,
  paginationChanged,
  sortingChanged,
} from './actions';
import { isArdoqError, Maybe, setStateProperty } from '@ardoq/common-helpers';
import {
  apiFilterQueryToReportFilterQuery,
  filterQueryToApiReportFilterQuery,
  NUMBER_OF_ROWS_PER_PAGE,
  ReportSort,
  reportUrlParamsToApiReportFilterQuery,
} from '@ardoq/report-reader';
import { combineLatest, map } from 'rxjs';
import reportNavigation$ from '../navigation/reportNavigation$';
import reports$ from 'streams/reports/reports$';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import {
  APIReportAttributes,
  APIReportFilterQuery,
  APISearchResponse,
  ArdoqId,
  EnhancedPresentation,
  Report,
  ReportFilterQuery,
  ReportSlide,
  SortOrder,
  WidgetDataSourceTypes,
} from '@ardoq/api-types';
import { isEmpty, isEqual } from 'lodash';
import { reportBuilderOperations } from '@ardoq/report-builder';
import viewPane$ from 'presentation/viewPane/viewPane$';
import { slideInterface } from 'modelInterface/presentations/slideInterface';
import loadedPresentation$ from 'presentation/streams/loadedPresentation$';
import { ReportSearchResults$State } from './searchResults$';
import { fetchPresentationSearchResults } from './utils';
import { handleError } from '@ardoq/api';
import { logError } from '@ardoq/logging';
import currentUser$ from 'streams/currentUser/currentUser$';
import dashboardBuilder$ from '../../dashboard/DashboardBuilder/DashboardBuilder$';
import { widgetOperations } from '@ardoq/dashboard';

type ReportReaderInput$State = {
  report: APIReportAttributes;
  filterQuery: ReportFilterQuery | undefined; // internal filtering state
  initialFiltersFromUrl: Maybe<APIReportFilterQuery>; // initial filters parsed from url, only used in first search request
};

type PresentationSearchResultWithReportData = APISearchResponse & {
  reportId: ArdoqId;
  presentationId: ArdoqId;
  slideId: ArdoqId;
  sort?: ReportSort;
  slideFilterQuery?: APIReportFilterQuery;
  filters?: APIReportFilterQuery;
};

const hasLoadedReport = (inputState: {
  report: Maybe<APIReportAttributes>;
  initialFiltersFromUrl: Maybe<APIReportFilterQuery>;
}): inputState is ReportReaderInput$State => Boolean(inputState.report);

const reportReaderInput$ = combineLatest([reportNavigation$, reports$]).pipe(
  map(([{ reportId, filters }, reports]) => {
    const report =
      reportId && reports.byId[reportId] ? reports.byId[reportId] : null;

    const initialFiltersFromUrl =
      report && filters ? reportUrlParamsToApiReportFilterQuery(filters) : null;

    return {
      report,
      initialFiltersFromUrl,
    };
  }),
  filter(hasLoadedReport),
  distinctUntilChanged<ReportReaderInput$State>(isEqual)
);

const widgetFilterInput$ = combineLatest([dashboardBuilder$, reports$]).pipe(
  filter(([{ isFilterDrawerOpened }]) => isFilterDrawerOpened),
  map(([{ selectedWidgetId, widgets }, reports]) => {
    const selectedWidget = widgets.find(
      widget => widget._id === selectedWidgetId
    );

    if (widgetOperations.isHeaderWidgetData(selectedWidget))
      return {
        report: null,
        initialFiltersFromUrl: null,
      };

    if (
      selectedWidget &&
      selectedWidget.datasource?.sourceType === WidgetDataSourceTypes.REPORT
    ) {
      const reportId = selectedWidget.datasource?.sourceId;
      const report =
        reportId && reports.byId[reportId] ? reports.byId[reportId] : null;

      return {
        report,
        initialFiltersFromUrl: selectedWidget.filters
          ? filterQueryToApiReportFilterQuery(selectedWidget.filters)
          : null,
      };
    }
    return { report: null, initialFiltersFromUrl: null };
  }),
  filter(hasLoadedReport),
  distinctUntilChanged<ReportReaderInput$State>(isEqual)
);

const initReportReader$State = (
  state: ReportReader$State,
  {
    report,
    initialFiltersFromUrl,
  }: {
    report: Report;
    initialFiltersFromUrl: Maybe<APIReportFilterQuery>;
  }
): ReportReader$State => ({
  ...state,
  reportId: report._id,
  sort: reportBuilderOperations.getSortKeyAndOrder(report),
  filterQuery: {},
  hasFetchedReport: true,
  initialFiltersFromUrl,
});

export type ReportReader$State = ReportSearchResults$State & {
  hasExcelExportFailed: boolean;
  isSearchBeingExecuted: boolean;
  page: number;
  hasFetchedReport: boolean;
};

const defaultState: ReportReader$State = {
  hasExcelExportFailed: false,
  isSearchBeingExecuted: false,
  page: 1,
  filterQuery: {},
  sort: { columnKey: null, order: SortOrder.ASC },
  pagination: { from: 0, size: NUMBER_OF_ROWS_PER_PAGE },
  reportId: null,
  presentationId: null,
  slideId: null,
  hasFetchedReport: false,
  initialFiltersFromUrl: null,
};

const setStatePropertyAndResetPagination =
  <Key extends keyof ReportReader$State>(stateProperty: Key) =>
  (
    state: ReportReader$State,
    value: ReportReader$State[Key]
  ): ReportReader$State => ({
    ...state,
    [stateProperty]: value,
    page: 1,
    pagination: { from: 0, size: state.pagination.size },
    initialFiltersFromUrl: null,
  });

type PresentationSlideInput = {
  slide: ReportSlide;
  presentation: EnhancedPresentation;
  currentUserId: ArdoqId;
};

const presentationSlideInput$ = combineLatest([
  viewPane$,
  loadedPresentation$,
  currentUser$,
]).pipe(
  map(([viewPane, { presentation }, { _id: currentUserId }]) => ({
    slide: viewPane.loadedSlide,
    presentation,
    currentUserId,
  })),
  filter(
    (
      presentationInputState
    ): presentationInputState is PresentationSlideInput => {
      const { slide, presentation } = presentationInputState;
      return Boolean(
        slide && presentation && slideInterface.isReportSlide(slide._id)
      );
    }
  ),
  distinctUntilChanged((a: PresentationSlideInput, b: PresentationSlideInput) =>
    isEqual(a, b)
  ),
  switchMap(async presentationInputState => {
    const reportSlide = presentationInputState.slide;
    const sort = getSortFromSlide(reportSlide);
    const slideFilterQuery = getFiltersFromSlide(reportSlide);

    // initial fetch to get labels for saved filters and default sorting
    const initialSearchResults = await fetchPresentationSearchResults({
      reportId: presentationInputState.slide.reportId,
      presentationId: presentationInputState.presentation._id,
      pagination: defaultState.pagination,
      slideId: presentationInputState.slide._id,
      sort,
      filterQuery: slideFilterQuery,
      initialFiltersFromUrl: null,
    });

    if (isArdoqError(initialSearchResults)) return initialSearchResults;

    return {
      ...initialSearchResults,
      reportId: presentationInputState.slide.reportId,
      presentationId: presentationInputState.presentation._id,
      slideId: presentationInputState.slide._id,
      sort,
      slideFilterQuery,
      initialFilters: null,
    };
  }),
  handleError(error =>
    logError(error, 'Failed to fetch report data for presentation slide')
  )
);

const getSortFromSlide = (reportSlide: ReportSlide): ReportSort =>
  !isEmpty(reportSlide.reportParams)
    ? {
        columnKey: reportSlide.reportParams.sort ?? null,
        order: reportSlide.reportParams.order ?? SortOrder.ASC,
      }
    : defaultState.sort;

const getFiltersFromSlide = (
  reportSlide: ReportSlide
): APIReportFilterQuery | undefined =>
  !isEmpty(reportSlide.reportParams?.filters)
    ? reportSlide.reportParams?.filters
    : undefined;

const initReportReader$StateWithPresentation = (
  state: ReportReader$State,
  presentationData: PresentationSearchResultWithReportData
): ReportReader$State => {
  const enhancedSlideFilterQuery = presentationData.filters
    ? apiFilterQueryToReportFilterQuery(
        presentationData.filters,
        presentationData
      )
    : {};
  return {
    ...state,
    reportId: presentationData.reportId,
    presentationId: presentationData.presentationId,
    slideId: presentationData.slideId,
    sort: presentationData.sort
      ? presentationData.sort
      : reportBuilderOperations.getSortKeyAndOrder(presentationData),
    filterQuery: enhancedSlideFilterQuery,
    pagination: defaultState.pagination,
    page: defaultState.page,
    hasFetchedReport: true,
    slideFilterQuery: enhancedSlideFilterQuery,
  };
};

const reportReader$ = reducedStream<ReportReader$State>(
  'reportReader$',
  defaultState,
  [
    reducer(excelExportFailed, setStateProperty('hasExcelExportFailed')),
    reducer(pageChanged, setStateProperty('page')),
    reducer(paginationChanged, setStateProperty('pagination')),
    reducer(
      filterQueryChanged,
      setStatePropertyAndResetPagination('filterQuery')
    ),
    reducer(drilldownWasTriggered, setStateProperty('isSearchBeingExecuted')),
    reducer(sortingChanged, setStatePropertyAndResetPagination('sort')),
    streamReducer(reportReaderInput$, initReportReader$State), // This sets the report id when opening a report in the core app
    streamReducer(widgetFilterInput$, initReportReader$State), // This sets the report id when opening a drawer in dashboard filtering
    streamReducer(
      presentationSlideInput$,
      initReportReader$StateWithPresentation
    ), // this sets the report id when opening a presentation
  ]
);

export default reportReader$;
