import { PrivilegeLabel } from '@ardoq/api-types';
import {
  currentUserHasPrivilege,
  registerImplementation,
} from '@ardoq/privileges';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

const hasPrivilege = (privilegeLabel: PrivilegeLabel) =>
  currentUserHasPrivilege(
    currentUserInterface.getCurrentUserAttributes(),
    privilegeLabel
  );

/**
 * @deprecated Use hasPrivileges from @ardoq/privileges instead.
 */
const privileges = {
  /**
   * Interface for checking if the current user has a privilege, similar to how we
   * can use hasFeature from @ardoq/features to check if the org has a
   * feature.
   */
  hasPrivilege,
};

export default privileges;

export const initializePrivilegesInterface = () => {
  registerImplementation({
    hasPrivilege,
  });
};
