import styled from 'styled-components';
import { Tag, StatusType } from '@ardoq/status-ui';
import { IconName } from '@ardoq/icons';
import { Space } from '@ardoq/style-helpers';
import { header4 } from '@ardoq/typography';
import { s32 } from '@ardoq/design-tokens';

type DryRunSummaryFooterProps = {
  errorCount: number;
  warningCount: number;
  successCount: number;
  tableCount: number;
  dryRunSummaryType: string;
};

export const DryRunSummaryFooter = ({
  dryRunSummaryType,
  errorCount,
  warningCount,
  successCount,
  tableCount,
}: DryRunSummaryFooterProps) => {
  const mappedTableCount = errorCount + warningCount + successCount;

  return (
    <Space $gap="s16" style={{ padding: s32 }}>
      <MainLabel>Import Summary: </MainLabel>
      <Tag
        statusType={StatusType.INFO}
        label={`${mappedTableCount} of ${tableCount} ${dryRunSummaryType}${
          tableCount === 1 ? '' : 's'
        } contain${mappedTableCount === 1 ? 's' : ''} configured data`}
        iconName={IconName.INFO}
      />
      {errorCount > 0 && (
        <Tag
          statusType={StatusType.ERROR}
          label={`${errorCount} ${dryRunSummaryType}${
            errorCount === 1 ? '' : 's'
          } contain${errorCount === 1 ? 's' : ''} errors`}
          iconName={IconName.ERROR}
        />
      )}
      {warningCount > 0 && (
        <Tag
          statusType={StatusType.WARNING}
          label={`${warningCount} ${dryRunSummaryType}${
            warningCount === 1 ? '' : 's'
          } contain${warningCount === 1 ? 's' : ''} warnings`}
          iconName={IconName.WARNING}
        />
      )}
    </Space>
  );
};

const MainLabel = styled.div`
  ${header4}
`;
