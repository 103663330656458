import { useEffect, useRef } from 'react';
import {
  QuickSearchInput,
  QuickSearchWrapper,
  ResultAndOptions,
} from 'search/quickSearch/atoms';
import Options from './Options';
import Results from './Results';
import { QuickSearchResultItem } from './types';
import { POPOVER_ID_ATTR, popoverRegistry } from '@ardoq/popovers';
import { ScenarioModePopover } from 'search/quickSearch/Popovers';
import { SelectOption } from '@ardoq/select';
import { connect } from '@ardoq/rxbeach';
import { combineLatest, map } from 'rxjs';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import quickSearch$ from './quickSearch$';
import { QuickSearchCommands, quickSearchCommands } from './commands';
import { IconName } from '@ardoq/icons';

const POPOVER_ID = 'quickSearch-popover';
const POPOVER_ATTRS = { [POPOVER_ID_ATTR]: POPOVER_ID };

type QuickSearchProps = {
  placeholderText: string;
  isScenarioMode: boolean;
  disableResultFilters?: boolean;
  customFields: SelectOption<string>[];
  resultCallback?: (result: QuickSearchResultItem) => void;
  isViewpointMode: boolean;
  hasNewJourneyFeature: boolean;
  commands: QuickSearchCommands;
  isActive: boolean;
  query: string;
  total: number;
  results: QuickSearchResultItem[] | null;
  isLoading: boolean;
};

const QuickSearch = ({
  placeholderText,
  isScenarioMode = false,
  customFields,
  disableResultFilters,
  resultCallback,
  isViewpointMode,
  hasNewJourneyFeature,
  isActive,
  query,
  results,
  total,
  isLoading,
  commands,
}: QuickSearchProps) => {
  const quickSearchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // we need to close the search input dropdown when a user clicks at the area outside it
    // but we still need to keep the dropdown open when the field name input (located inside the dropdown)
    // takes the focus away from the search input

    // we are also setting the focus back to the search input when the field name input losses focus
    // to avoid cases when the dropdown is open but no inputs are in focus
    // this improves UX slightly (no need to click inside the search input after selecting the field name)
    let previousActiveElement = document.activeElement;
    let isFocusEvent = false;

    const focusEventHandler = () => {
      if (isActive) {
        isFocusEvent = true;
        if (
          !(
            previousActiveElement?.matches('input:not(#quick-search-input)') &&
            previousActiveElement?.closest('#quick-search-wrapper')
          ) &&
          !document.activeElement?.closest('#quick-search-wrapper')
        ) {
          commands.resetQuickSearch();
        } else if (
          !(
            document.activeElement?.matches('input:not(#quick-search-input)') &&
            document.activeElement?.closest('#quick-search-wrapper')
          )
        ) {
          quickSearchInputRef.current?.focus();
        }
      }

      previousActiveElement = document.activeElement;
    };

    const clickEventHandler = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (isFocusEvent) {
        isFocusEvent = false;
        return;
      }
      if (isActive && !target.closest('#quick-search-wrapper')) {
        commands.setSearchBarActive(false);
      }
    };

    document.addEventListener('focusin', focusEventHandler);
    document.addEventListener('click', clickEventHandler);

    return () => {
      document.removeEventListener('focusin', focusEventHandler);
      document.removeEventListener('click', clickEventHandler);
    };
  }, [commands, isActive]);

  const handleSelectResult = (result: QuickSearchResultItem) => {
    commands.resetQuickSearch();
    if (resultCallback) {
      return resultCallback(result);
    }
    const { id, type, rootWorkspaceId } = result;
    const isWorkspace = type === 'workspace';
    const workspaceId = isWorkspace ? id : rootWorkspaceId!;
    if (type) {
      if (hasNewJourneyFeature && type === 'component') {
        commands.openComponentOverviewPage(id);
        return;
      }
      commands.openWorkspaceOrReference(id, workspaceId);
    }
  };

  const darkMode =
    !isActive && !isScenarioMode && !isViewpointMode && !hasNewJourneyFeature;

  popoverRegistry.set(POPOVER_ID, () =>
    isScenarioMode ? <ScenarioModePopover /> : null
  );
  return (
    <QuickSearchWrapper
      id="quick-search-wrapper"
      tabIndex={-1}
      {...POPOVER_ATTRS}
    >
      <QuickSearchInput
        $darkMode={darkMode}
        ref={quickSearchInputRef}
        placeholder={placeholderText}
        value={query}
        autoFocus={hasNewJourneyFeature && !isScenarioMode}
        onValueChange={query => commands.setSearchParams({ query })}
        onFocus={() => commands.setSearchBarActive(true)}
        isDisabled={isScenarioMode}
        id="quick-search-input"
        dataClickId="quick-search-input"
        leftIconName={isLoading ? IconName.SPINNER : IconName.SEARCH}
        autoComplete="off"
      />
      {isActive && (
        <ResultAndOptions $hasNewJourneyFeature={hasNewJourneyFeature}>
          <Options
            customFields={customFields}
            onFieldInputMenuClose={() => quickSearchInputRef.current?.focus()}
            disableResultFilters={disableResultFilters}
            hasNewJourneyFeature={hasNewJourneyFeature}
          />
          {results && (
            <Results
              query={query}
              results={results}
              handleSelectResult={handleSelectResult}
              total={total}
              hasNewJourneyFeature={hasNewJourneyFeature}
            />
          )}
        </ResultAndOptions>
      )}
    </QuickSearchWrapper>
  );
};

export default connect(
  QuickSearch,
  combineLatest({
    quickSearch: quickSearch$,
    loadedGraph: loadedGraph$,
    scenario: activeScenario$,
  }).pipe(
    map(
      ({
        quickSearch: { isActive, query, results, total, isLoading },
        loadedGraph: { isViewpointMode },
        scenario: { isScenarioMode },
      }) => {
        return {
          isActive,
          query,
          results,
          total,
          isLoading,
          isViewpointMode,
          isScenarioMode,
          commands: quickSearchCommands,
        };
      }
    )
  )
);
