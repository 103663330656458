import { colors } from '@ardoq/design-tokens';
import { header2 } from '@ardoq/typography';
import styled from 'styled-components';
import { FlexBox } from '@ardoq/layout';

type ViewpointModalHeaderProps = {
  title?: string;
  subtitle?: React.ReactNode;
};

// TODO CL consider moving to Island in DS,
// Current design is a bit too specific to use existing props
export const ViewpointModalHeader = ({
  title,
  subtitle,
}: ViewpointModalHeaderProps) => (
  <div>
    {title && (
      <FlexBox gap="small" align="center" marginBottom="medium">
        <Header>{title}</Header>
      </FlexBox>
    )}

    <SubTitle>{subtitle}</SubTitle>
  </div>
);

const Header = styled.h2`
  ${header2};
  margin: 0;
`;

const SubTitle = styled.div`
  color: ${colors.grey60};
`;
