import { APIEntityType, AllSupportedEntityTypes } from '@ardoq/api-types';
import ModuleContainer from 'appContainer/ModuleContainer';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import { Footer } from 'scope/merge/ScenarioMergeContainer';
import EntityMergeTable from 'components/EntityMerge/EntityMergeTable';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { DiffTableRowType } from 'components/DiffMergeTable/types';
import { DataSource, EntityMergeViewModel } from 'components/EntityMerge/types';
import { applyEntityMerge } from 'components/EntityMerge/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import ArdoqLoader from 'components/ArdoqLoader/ArdoqLoader';
import EmptyPageDoq from 'components/EntityMerge/EmptyPageDoq';
import { PrimaryButton } from '@ardoq/button';

interface ContainerProps {
  isLoading: boolean;
  isDisabled: boolean;
  entityType: AllSupportedEntityTypes;
}

const Layout = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
`;

const ApplyButton = ({ isLoading, isDisabled, entityType }: ContainerProps) => {
  const label = `Merge ${
    entityType === APIEntityType.COMPONENT ? 'Components' : 'References'
  }`;
  return (
    <PrimaryButton
      isDisabled={isLoading || isDisabled}
      onClick={() => dispatchAction(applyEntityMerge(entityType))}
      data-click-id="confirm-entity-merge"
    >
      {label}
    </PrimaryButton>
  );
};

const canMerge = (dataSource: DataSource) =>
  dataSource.every(
    ({ rowType, status }) =>
      rowType !== DiffTableRowType.PROPERTY_ROW || status !== null
  );

type EntityMergeContainerProps = EntityMergeViewModel & {
  showEmptyPage: boolean;
};

const EntityMergeContainer = ({
  options,
  entityType,
  graphics,
  users,
  dataSource,
  entities,
  enhancedScopeData,
  isLoading,
  entitiesWithInvalidParent,
  showEmptyPage,
}: EntityMergeContainerProps) => (
  <ModuleContainer $overflow="hidden">
    <ErrorBoundary logError={logError}>
      <Layout>
        {enhancedScopeData && graphics && options && users && !isLoading ? (
          <EntityMergeTable
            entityType={entityType}
            dataSource={dataSource}
            options={options}
            entities={entities}
            graphics={graphics}
            users={users}
            enhancedScopeData={enhancedScopeData}
            entitiesWithInvalidParent={entitiesWithInvalidParent}
            showEmptyPage={showEmptyPage}
          />
        ) : (
          <ArdoqLoader isLoading={true} />
        )}
        {showEmptyPage ? (
          <EmptyPageDoq entityType={entityType} />
        ) : (
          <Footer>
            <ApplyButton
              entityType={entityType}
              isDisabled={!canMerge(dataSource)}
              isLoading={isLoading}
            />
          </Footer>
        )}
      </Layout>
    </ErrorBoundary>
  </ModuleContainer>
);

export default EntityMergeContainer;
