import { dispatchAction } from '@ardoq/rxbeach';
import { viewpointBuilderTracking } from '../viewpointBuilderTracking';
import * as editTraversalActions from './editTraversalActions';
import type {
  SetSectionFilterPayload,
  UpdateTraversalStartContextPayload,
  SetTripleSortOrderPayload,
} from './editTraversalActions';
import {
  DirectedTriple,
  PersonalSetting,
  ReferenceDirection,
  TraversalPathMatchingType,
  ViewpointBuilderFilters,
} from '@ardoq/api-types';
import currentUser from 'models/currentUser';
import { APICountInstancesResponse } from '@ardoq/api-types';

const toggleIsSelectedGraphNode = (graphNodeId: string) => {
  viewpointBuilderTracking.trackComponentTypeNodeSelectedInGraph(
    'SUBGRAPH_TAB'
  );
  dispatchAction(
    editTraversalActions.toggleIsSelectedGraphNode({ graphNodeId })
  );
};

const toggleTripleOption = (
  tripleId: string,
  direction: ReferenceDirection,
  namedDirectedTriple: DirectedTriple,
  isSelected: boolean
) => {
  dispatchAction(
    editTraversalActions.toggleTripleOption({
      tripleId,
      direction,
      namedDirectedTriple,
      isSelected,
    })
  );
};

const removeNodeFromGraph = (nodeId: string) => {
  dispatchAction(editTraversalActions.removeNodeFromGraph(nodeId));
};

const setStartType = (startType: string) =>
  dispatchAction(
    editTraversalActions.setStartTypeAndTraversalStartSetOrCount({ startType })
  );

const setStartTypeAndTraversalStartSetOrCount = (
  startType: string,
  traversalStartSet: string[],
  traversalStartQueryResultCount?: number
) =>
  dispatchAction(
    editTraversalActions.setStartTypeAndTraversalStartSetOrCount({
      startType,
      traversalStartSet,
      traversalStartQueryResultCount,
    })
  );

const setTraversals = (
  paths: DirectedTriple[][],
  filters?: ViewpointBuilderFilters
) =>
  dispatchAction(
    editTraversalActions.setTraversals({ paths, filters: filters ?? null })
  );

const setTripleSortOrder = (payload: SetTripleSortOrderPayload) =>
  dispatchAction(editTraversalActions.setTripleSortOrder(payload));

const responseInstanceCounts = (payload: APICountInstancesResponse) =>
  dispatchAction(editTraversalActions.responseInstanceCounts(payload));

const setSectionFilter = (payload: SetSectionFilterPayload) =>
  dispatchAction(editTraversalActions.setSectionFilter(payload));

const toggleShowOnlyOptionsWithInstanceCounts = () =>
  dispatchAction(
    editTraversalActions.toggleShowOnlyOptionsWithInstanceCounts()
  );

const skipClickOtherComponentTypeNodesHints = () => {
  currentUser.setPersonalSetting(
    PersonalSetting.INCLUDE_CLICK_OTHER_COMPONENT_TYPE_NODES_HINTS_IN_VIEWPOINT_BUILDER,
    false
  );
  dispatchAction(
    editTraversalActions.dontShowAgainClickOtherComponentTypeNodesHints()
  );
};

const updateStartContext = (payload: UpdateTraversalStartContextPayload) =>
  dispatchAction(editTraversalActions.updateTraversalStartContext(payload));

const setPathMatchingType = (pathMatching: TraversalPathMatchingType) =>
  dispatchAction(editTraversalActions.setPathMatchingType(pathMatching));

export const editTraversalCommands = {
  setStartType,
  setStartTypeAndTraversalStartSetOrCount,
  setTraversals,
  setTripleSortOrder,
  toggleIsSelectedGraphNode,
  toggleTripleOption,
  removeNodeFromGraph,
  responseInstanceCounts,
  setSectionFilter,
  toggleShowOnlyOptionsWithInstanceCounts,
  skipClickOtherComponentTypeNodesHints,
  updateStartContext,
  setPathMatchingType,
};

export type EditTraversalCommands = {
  setStartType: (startType: string) => void;
  setStartTypeAndTraversalStartSetOrCount: (
    startType: string,
    traversalStartSet: string[],
    traversalStartQueryResultCount?: number
  ) => void;
  setTraversals: (
    paths: DirectedTriple[][],
    filters?: ViewpointBuilderFilters
  ) => void;
  setTripleSortOrder: (payload: SetTripleSortOrderPayload) => void;
  toggleIsSelectedGraphNode: (graphNodeId: string) => void;
  toggleTripleOption: (
    tripleId: string,
    direction: ReferenceDirection,
    namedDirectedTriple: DirectedTriple,
    isSelected: boolean
  ) => void;
  removeNodeFromGraph: (nodeId: string) => void;
  responseInstanceCounts: (payload: APICountInstancesResponse) => void;
  setSectionFilter: (payload: SetSectionFilterPayload) => void;
  toggleShowOnlyOptionsWithInstanceCounts: () => void;
  updateStartContext: (payload: UpdateTraversalStartContextPayload) => void;
  setPathMatchingType: (pathMatching: TraversalPathMatchingType) => void;
  skipClickOtherComponentTypeNodesHints: () => void;
};
