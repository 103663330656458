import { RESERVED_ATTRIBUTE_NAMES } from 'models/consts';
import { ColumnMappingMap, TabularMapping } from '../tabularMappings/types';
import {
  isComponentMapping,
  isComponentTypeMapping,
  isFieldColumnMapping,
  isReferenceColumnMapping,
  isPathReferenceFormat,
  isRootReferenceMapping,
  isTargetReferenceMapping,
} from '../tabularMappings/utils';
import {
  MappedColumnsErrors,
  SidebarSelectorsIds,
  TableMappingErrors,
  TabularMappingErrors,
} from './types';
import { ColumnMapping } from '../transferConfigs/types';
import { MissingComponentsStrategy } from '@ardoq/api-types/integrations';

const validFieldNameRegExp = new RegExp('[A-Za-z][A-Za-z0-9_]*');

const isEmptyValue = (value: string | undefined) => !value || !value.trim();

const isFieldReserved = (fieldName: string) =>
  RESERVED_ATTRIBUTE_NAMES.map(name => name.toLowerCase()).includes(
    fieldName.toLowerCase()
  );

export const validateTableMapping = (
  columnMapping: ColumnMappingMap,
  isCreationDisabled: boolean
) => {
  const columnMappingComponents = Object.values(columnMapping);

  return columnMappingComponents.reduce<TableMappingErrors>(
    (acc, columnMapping: ColumnMapping) => {
      const columnsMappingErrors: MappedColumnsErrors = {};

      if (isComponentMapping(columnMapping)) {
        const hasComponentTypeColumn = !!columnMappingComponents.find(column =>
          isComponentTypeMapping(column)
        );

        columnsMappingErrors[SidebarSelectorsIds.COMPONENT_TYPE] =
          !hasComponentTypeColumn && isEmptyValue(columnMapping.componentType)
            ? 'The component type must be defined'
            : null;
      } else if (isFieldColumnMapping(columnMapping)) {
        const fieldLabel = columnMapping.fieldLabel.replace(
          /^[_\s]+|[_\s]+$/g, // trim ' ' & '_'
          ''
        );
        const isInvalidFieldName = !validFieldNameRegExp.test(fieldLabel);

        const isFieldCreated = Boolean(columnMapping.fieldName);

        columnsMappingErrors[SidebarSelectorsIds.FIELD_NAME] = !isFieldCreated
          ? isEmptyValue(fieldLabel)
            ? 'Please select or enter a field name.'
            : isInvalidFieldName
              ? `The field name "${columnMapping.fieldLabel}" is not valid`
              : isFieldReserved(fieldLabel)
                ? `"${fieldLabel}" can’t be used as a field name. Please select or enter a different field name.`
                : null
          : null;
      } else if (isReferenceColumnMapping(columnMapping)) {
        columnsMappingErrors[SidebarSelectorsIds.COMPONENT_TYPE_IN_REFERENCE] =
          !isCreationDisabled &&
          columnMapping?.missingComponents ===
            MissingComponentsStrategy.CREATE &&
          isEmptyValue(columnMapping.componentType)
            ? 'Please select or enter a target component type.'
            : null;

        columnsMappingErrors[SidebarSelectorsIds.ITEM_SEPARATOR] = isEmptyValue(
          columnMapping.referenceItemSeparator
        )
          ? 'Please enter a separator, such as "," or "/".'
          : null;

        if (isPathReferenceFormat(columnMapping)) {
          columnsMappingErrors[SidebarSelectorsIds.REFERENCE_PATH_SEPARATOR] =
            isEmptyValue(columnMapping.referencePathSeparator)
              ? 'Please enter a separator, such as "::" or ":".'
              : null;
        }
      } else if (
        (isRootReferenceMapping(columnMapping) ||
          isTargetReferenceMapping(columnMapping)) &&
        isPathReferenceFormat(columnMapping)
      ) {
        columnsMappingErrors[SidebarSelectorsIds.REFERENCE_PATH_SEPARATOR] =
          isEmptyValue(columnMapping.referencePathSeparator)
            ? 'Please enter a separator, such as "::" or ":".'
            : null;
      }

      acc[columnMapping.index] = columnsMappingErrors;
      return acc;
    },
    {}
  );
};

export const validateTabularMapping = (
  tabularMapping: TabularMapping,
  isCreationDisabled: boolean
) => {
  const result: TabularMappingErrors = {};

  Object.entries(tabularMapping).forEach(([tableId, { mappedColumns }]) => {
    if (!result[tableId]) {
      result[tableId] = {};
    }
    result[tableId] = validateTableMapping(mappedColumns, isCreationDisabled);
  });

  return result;
};
