import { ChangeEvent, Component } from 'react';
import { debounce, isEqual } from 'lodash';
import { LoadingWrapper } from '@ardoq/status-ui';
import CollectionSelectInput from './CollectionSelectInput';
import WorkspaceSettings from './WorkspaceSettings';
import Perspectives from 'collections/perspectives';
import {
  BundleEditorProps,
  EditingBundleShape,
  updateWsSettingsParams,
} from '../types';
import {
  convertEditingBundleShapeToApi,
  convertPersistedBundleShapeToEditing,
} from '../utils';
import {
  backboneModelsToSelectOptions,
  getBroadcastSelectOptions,
  getGraphFilterSelectOptions,
  getPresentationSelectOptions,
  getReportSelectOptions,
  getSurveySelectOptions,
  getTraversalSelectOptions,
  getViewpointSelectOptions,
  loadAsync,
  splitDateRangeFieldsIds,
} from './utils';
import {
  getBundleEditorFormErrors,
  getCopyConfigErrors,
} from '../validationUtils';
import { Description, ErrorBox } from '../atoms';
import FormRow from './FormRow';
import FormCell from './FormCell';
import { emptyBundle } from './consts';
import { LabeledValue } from 'aqTypes';
import {
  ArdoqId,
  PrivilegeLabel,
  PersistedBundleShape,
} from '@ardoq/api-types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { Features, hasFeature } from '@ardoq/features';
import privileges from 'admin/privileges';
import { PrimaryButton } from '@ardoq/button';
import { DateRangeFieldAttributes } from '@ardoq/date-range';
import { TextInput } from '@ardoq/forms';
import { Header3, Paragraph } from '@ardoq/typography';
import { Box } from '@ardoq/layout';
import { bundleApi } from '@ardoq/api';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';

type BundleEditorState = {
  bundle: EditingBundleShape;
  isLoaded: boolean;
  errorMsgs: string[];
};

class BundleEditor extends Component<BundleEditorProps, BundleEditorState> {
  state = { bundle: emptyBundle, isLoaded: false, errorMsgs: [] };
  useCaseSelectOptions: LabeledValue[] = [];
  entityGroupSelectOptions: LabeledValue[] = [];
  componentTypeSelectOptions: LabeledValue[] = [];
  referenceTypeSelectOptions: LabeledValue[] = [];
  fieldSelectOptions: LabeledValue[] = [];
  dashboardSelectOptions: LabeledValue[] = [];
  excelImportMappingConfigs: LabeledValue[] = [];
  serviceNowImportMappingConfigs: LabeledValue[] = [];
  createdDateRangeFields: DateRangeFieldAttributes[] = [];
  editingPersistedBundleShape: PersistedBundleShape | undefined = undefined;

  debouncedSetState = debounce(
    partialState => this.setState(partialState),
    200
  );

  submit = async () => {
    const validation = await getBundleEditorFormErrors({
      bundleAttrs: this.state.bundle,
      isEditing: Boolean(this.props.editingBundleId),
    });
    if (!validation.isValid && validation.errorMsgs.length) {
      this.setState({ errorMsgs: validation.errorMsgs });
      return;
    }

    const copyConfigValidation = await getCopyConfigErrors(this.state.bundle);
    if (!copyConfigValidation.isValid) {
      this.setState({ errorMsgs: copyConfigValidation.errorMsgs });
      return;
    }

    const validatedBundle: EditingBundleShape = {
      ...this.state.bundle,
      fields: splitDateRangeFieldsIds({
        fieldIds: this.state.bundle.fields,
        dateRangeFields: this.createdDateRangeFields,
      }),
    };

    if (this.editingPersistedBundleShape) {
      const newBundleShape = convertEditingBundleShapeToApi(validatedBundle);
      const requestBody = {
        ...this.editingPersistedBundleShape,
        ...newBundleShape,
      };
      const response = await bundleApi.update(requestBody);
      if (isArdoqError(response)) {
        this.setState({ errorMsgs: [getArdoqErrorMessage(response)] });
        return;
      }
    } else {
      const response = await bundleApi.create(
        convertEditingBundleShapeToApi(validatedBundle)
      );
      if (isArdoqError(response)) {
        this.setState({ errorMsgs: [getArdoqErrorMessage(response)] });
        return;
      }
    }
    this.editingPersistedBundleShape = undefined;
    this.props.navigateToBundleOverview();
  };

  updateBundleAttribute = (
    bundleUpdater: Partial<EditingBundleShape>,
    shouldDebounce = false
  ) => {
    const stateUpdater = {
      bundle: {
        ...this.state.bundle,
        ...bundleUpdater,
      },
    };

    const shouldHardCodeName = stateUpdater.bundle.useCases.length !== 0;
    if (shouldHardCodeName) {
      const option = this.useCaseSelectOptions.find(
        v => v.value === stateUpdater.bundle.useCases[0]
      )!;
      stateUpdater.bundle.name = `${option.label} (No Sample Data)`;
    }

    if (shouldDebounce) this.debouncedSetState(stateUpdater);
    else this.setState(stateUpdater);
  };

  addWorkspace = (workspaceId: string | undefined) => {
    if (workspaceId) {
      const workspaceClone = new Map(this.state.bundle.workspaces);
      workspaceClone.set(workspaceId, {
        id: workspaceId,
        copyComponents: true,
        copyReferences: true,
        renameWorkspace: false,
        chooseFolder: false,
        name: '',
      });
      this.updateBundleAttribute({ workspaces: workspaceClone });
    } else {
      this.updateBundleAttribute({ workspaces: new Map() });
    }
  };

  updateWorkspaceSettings = ({
    workspaceId,
    updatedSettings,
    debounce: shouldDebounce = false,
  }: updateWsSettingsParams) => {
    const workspaceClone = new Map(this.state.bundle.workspaces);
    const currentWorkspaceSettings = workspaceClone.get(workspaceId);
    if (currentWorkspaceSettings === undefined) return;

    const newWorkspaceSettings = {
      ...currentWorkspaceSettings,
      ...updatedSettings,
    };
    workspaceClone.set(workspaceId, newWorkspaceSettings);
    this.updateBundleAttribute({ workspaces: workspaceClone }, shouldDebounce);
  };

  removeWorkspace = (workspaceId: string) => {
    const workspaceClone = new Map(this.state.bundle.workspaces);
    if (!workspaceClone.has(workspaceId)) return;
    workspaceClone.delete(workspaceId);
    this.updateBundleAttribute({ workspaces: workspaceClone });
  };

  componentDidUpdate(prevProps: BundleEditorProps) {
    const wasEditingBundle = Boolean(prevProps.editingBundleId);
    const switchedFromEditToCreate =
      wasEditingBundle && !this.props.editingBundleId;
    if (switchedFromEditToCreate) {
      this.editingPersistedBundleShape = undefined;
      this.setState({ bundle: emptyBundle, errorMsgs: [] });
      this.props.setChanges(false);
      return;
    }

    const createBundleHasChanges = this.editingPersistedBundleShape
      ? !isEqual(
          convertPersistedBundleShapeToEditing(
            this.editingPersistedBundleShape
          ),
          this.state.bundle
        )
      : !isEqual(emptyBundle, this.state.bundle);
    if (this.props.createBundleHasChanges !== createBundleHasChanges)
      this.props.setChanges(createBundleHasChanges);
  }

  async componentDidMount() {
    this.setState({ isLoaded: false });
    const {
      loadAsyncErrorMsgs,
      useCaseSelectOptions,
      entityGroupSelectOptions,
      componentTypeSelectOptions,
      referenceTypeSelectOptions,
      fieldSelectOptions,
      dashboardSelectOptions,
      excelImportMappingConfigs,
      serviceNowImportMappingConfigs,
      createdDateRangeFields,
    } = await loadAsync();
    this.useCaseSelectOptions = useCaseSelectOptions;
    this.entityGroupSelectOptions = entityGroupSelectOptions;
    this.componentTypeSelectOptions = componentTypeSelectOptions;
    this.referenceTypeSelectOptions = referenceTypeSelectOptions;
    this.fieldSelectOptions = fieldSelectOptions;
    this.dashboardSelectOptions = dashboardSelectOptions;
    this.excelImportMappingConfigs = excelImportMappingConfigs;
    this.serviceNowImportMappingConfigs = serviceNowImportMappingConfigs;
    this.createdDateRangeFields = createdDateRangeFields;
    this.setState({ isLoaded: true });

    if (!this.props.editingBundleId) {
      if (loadAsyncErrorMsgs.length)
        this.setState({ errorMsgs: loadAsyncErrorMsgs });
      return;
    }

    const bundleAttrsApi = await bundleApi.fetch(this.props.editingBundleId);
    if (isArdoqError(bundleAttrsApi)) {
      this.setState({
        errorMsgs: [
          ...loadAsyncErrorMsgs,
          getArdoqErrorMessage(bundleAttrsApi),
        ],
      });
      return;
    }
    const bundleToLoad = convertPersistedBundleShapeToEditing(bundleAttrsApi);
    this.editingPersistedBundleShape = bundleAttrsApi;
    this.setState({ bundle: bundleToLoad });
  }

  render() {
    const isEditing = Boolean(this.props.editingBundleId);
    return (
      <LoadingWrapper loading={!this.state.isLoaded}>
        <FormRow>
          <FormCell>
            <Box paddingBottom="small">
              <Paragraph variant="text1Bold">Bundle name</Paragraph>
            </Box>
            <TextInput
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                return this.updateBundleAttribute({ name: event.target.value });
              }}
              value={this.state.bundle.name}
              isDisabled={this.state.bundle.useCases.length !== 0}
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.useCaseSelectOptions}
              collectionLabel="use case"
              selectedEntityIds={this.state.bundle.useCases}
              addEntityToBundle={(useCaseId: ArdoqId | undefined) =>
                this.updateBundleAttribute({
                  useCases: useCaseId ? [useCaseId] : [],
                })
              }
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.entityGroupSelectOptions}
              collectionLabel="entity group"
              selectedNothingPlaceholder="Select an entity group"
              selectedEntityIds={this.state.bundle.entityGroups}
              addEntityToBundle={(entityGroupIds: ArdoqId[]) =>
                this.updateBundleAttribute({
                  entityGroups: entityGroupIds,
                })
              }
              isMulti
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.componentTypeSelectOptions}
              collectionLabel="component type"
              selectedEntityIds={this.state.bundle.componentTypes}
              addEntityToBundle={(componentTypeIds: ArdoqId[]) =>
                this.updateBundleAttribute({
                  componentTypes: componentTypeIds,
                })
              }
              isMulti
            />
          </FormCell>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.referenceTypeSelectOptions}
              collectionLabel="reference type"
              selectedEntityIds={this.state.bundle.referenceTypes}
              addEntityToBundle={(referenceTypeIds: ArdoqId[]) =>
                this.updateBundleAttribute({
                  referenceTypes: referenceTypeIds,
                })
              }
              isMulti
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.fieldSelectOptions}
              collectionLabel="field"
              selectedEntityIds={this.state.bundle.fields}
              addEntityToBundle={(fieldIds: ArdoqId[]) =>
                this.updateBundleAttribute({ fields: fieldIds })
              }
              isMulti
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={workspaceInterface.getLoadedWorkspaceOptions()}
              collectionLabel="workspace"
              selectedEntityIds={[...this.state.bundle.workspaces.keys()]}
              addEntityToBundle={this.addWorkspace}
              clearAfterSelection
              description="Select a workspace and configure further options below."
            />
          </FormCell>
        </FormRow>
        <FormRow hidden={!this.state.bundle.workspaces.size}>
          {this.state.bundle.workspaces.size
            ? [...this.state.bundle.workspaces.entries()].map(
                ([workspaceId, workspaceAttrs]) => (
                  <FormCell key={workspaceId}>
                    <WorkspaceSettings
                      workspaceAttrs={workspaceAttrs}
                      workspaceName={
                        workspaceInterface.getWorkspaceName(workspaceId) ||
                        'Workspace not found'
                      }
                      workspaceId={workspaceId}
                      removeWorkspace={() => this.removeWorkspace(workspaceId)}
                      updateWorkspaceSettings={this.updateWorkspaceSettings}
                    />
                  </FormCell>
                )
              )
            : null}
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={getPresentationSelectOptions()}
              collectionLabel="presentation"
              selectedEntityIds={this.state.bundle.presentations}
              addEntityToBundle={(presentationIds: ArdoqId[]) =>
                this.updateBundleAttribute({ presentations: presentationIds })
              }
              isMulti
            />
          </FormCell>
          <FormCell>
            <CollectionSelectInput
              allOptions={getSurveySelectOptions()}
              collectionLabel="survey"
              selectedEntityIds={this.state.bundle.surveys}
              addEntityToBundle={(surveyIds: ArdoqId[]) =>
                this.updateBundleAttribute({ surveys: surveyIds })
              }
              isMulti
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={backboneModelsToSelectOptions(Perspectives.toArray())}
              collectionLabel="perspective"
              selectedEntityIds={this.state.bundle.perspectives}
              addEntityToBundle={(perspectiveIds: ArdoqId[]) =>
                this.updateBundleAttribute({ perspectives: perspectiveIds })
              }
              isMulti
            />
          </FormCell>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.dashboardSelectOptions}
              collectionLabel="dashboard"
              selectedEntityIds={this.state.bundle.dashboards}
              addEntityToBundle={(dashboardIds: ArdoqId[]) =>
                this.updateBundleAttribute({ dashboards: dashboardIds })
              }
              isMulti
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={getGraphFilterSelectOptions()}
              collectionLabel="graph filter"
              selectedEntityIds={this.state.bundle.graphFilter}
              addEntityToBundle={(graphFilter: ArdoqId[]) =>
                this.updateBundleAttribute({ graphFilter })
              }
              isMulti
            />
          </FormCell>
          {hasFeature(Features.BROADCASTS) ? (
            <FormCell>
              <CollectionSelectInput
                allOptions={getBroadcastSelectOptions()}
                collectionLabel="broadcast"
                selectedEntityIds={this.state.bundle.broadcasts}
                addEntityToBundle={(broadcastIds: ArdoqId[]) =>
                  this.updateBundleAttribute({ broadcasts: broadcastIds })
                }
                isMulti
              />
            </FormCell>
          ) : null}
        </FormRow>
        <FormRow>
          {privileges.hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER) ? (
            <FormCell>
              <CollectionSelectInput
                allOptions={getViewpointSelectOptions()}
                collectionLabel="viewpoint"
                selectedEntityIds={this.state.bundle.viewpoints}
                addEntityToBundle={(viewpoints: ArdoqId[]) =>
                  this.updateBundleAttribute({ viewpoints })
                }
                isMulti
              />
            </FormCell>
          ) : null}
          <FormCell>
            <CollectionSelectInput
              allOptions={getReportSelectOptions()}
              collectionLabel="report"
              selectedEntityIds={this.state.bundle.reports}
              addEntityToBundle={(reports: ArdoqId[]) =>
                this.updateBundleAttribute({ reports })
              }
              isMulti
            />
          </FormCell>
        </FormRow>
        <FormRow>
          {hasFeature(Features.SUPPORT_LARGE_DATASETS) ? (
            <FormCell>
              <CollectionSelectInput
                allOptions={getTraversalSelectOptions()}
                collectionLabel="traversal"
                selectedEntityIds={this.state.bundle.traversals}
                addEntityToBundle={(traversals: ArdoqId[]) =>
                  this.updateBundleAttribute({ traversals })
                }
                isMulti
              />
            </FormCell>
          ) : null}
        </FormRow>
        <FormRow>
          <FormCell $fullWidth>
            <Header3>Integrations options</Header3>
            <Description>
              Include configuration settings for integrations below.
            </Description>
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.excelImportMappingConfigs}
              collectionLabel="column mapping"
              selectedEntityIds={this.state.bundle.tabularMappingConfigIds}
              addEntityToBundle={(configIds: ArdoqId[]) =>
                this.updateBundleAttribute({
                  tabularMappingConfigIds: configIds,
                })
              }
              isMulti
              description={`Saved configuration options for how to map imported data columns to Ardoq entities.
              E.g. Excel column "description" should always map to the component's description field.`}
            />
          </FormCell>
          <FormCell>
            <CollectionSelectInput
              allOptions={this.serviceNowImportMappingConfigs}
              collectionLabel="ServiceNow import configuration"
              selectedEntityIds={this.state.bundle.serviceNowImportConfigIds}
              addEntityToBundle={(configIds: ArdoqId[]) =>
                this.updateBundleAttribute({
                  serviceNowImportConfigIds: configIds,
                })
              }
              isMulti
              description={`Configuration for which ServiceNow tables & fields should be imported into Ardoq.`}
            />
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell $fullWidth>
            {!this.state.errorMsgs.length ? null : (
              <ErrorBox errorMsgs={this.state.errorMsgs} />
            )}
            <PrimaryButton onClick={this.submit}>
              {isEditing ? 'Save Changes' : 'Create Bundle'}
            </PrimaryButton>
          </FormCell>
        </FormRow>
      </LoadingWrapper>
    );
  }
}

export default BundleEditor;
