import { MergeDirection } from 'scope/merge/MergeDirection';
import { APIEntityType } from '@ardoq/api-types';
import {
  DataSourceItem,
  MissingComponentConflict,
} from 'components/DiffMergeTable/types';
import { Branch } from 'components/DiffMergeTable/Branch';
import {
  EntityRepresentation,
  Graphics,
  getEntityById,
} from '@ardoq/renderers';
import { AdditionalContentWithBottomMargin } from 'components/DiffMergeTable/atoms';
import { s8 } from '@ardoq/design-tokens';
import { FlexWrapper } from 'components/DiffMergeTable/EntityRepresentation/atoms';

const AdditionalContentForMissingComponentsConflict = ({
  dataSourceItem,
  graphics,
  mergeDirection,
}: {
  dataSourceItem: DataSourceItem;
  graphics: Graphics;
  mergeDirection: MergeDirection;
}) => {
  const conflictType = dataSourceItem.missingComponentConflict;

  const branch =
    mergeDirection === MergeDirection.MAINLINE_TO_BRANCH
      ? 'in your scenario'
      : 'on mainline';

  const reference = getEntityById(
    APIEntityType.REFERENCE,
    dataSourceItem.entityId,
    dataSourceItem.enhancedDiffContextData[Branch.SOURCE]
  );
  const enhancedScopeData =
    dataSourceItem.enhancedDiffContextData[Branch.SOURCE];

  return (
    <AdditionalContentWithBottomMargin>
      <FlexWrapper>
        {(conflictType === MissingComponentConflict.SOURCE ||
          conflictType === MissingComponentConflict.BOTH) && (
          <>
            <div>The component </div>
            <FlexWrapper style={{ margin: `0 ${s8}` }}>
              &quot;
              <EntityRepresentation
                entityId={reference!.source}
                entityType={APIEntityType.COMPONENT}
                enhancedScopeData={enhancedScopeData}
                graphics={graphics}
              />
              &quot;
            </FlexWrapper>
          </>
        )}
        {(conflictType === MissingComponentConflict.TARGET ||
          conflictType === MissingComponentConflict.BOTH) && (
          <>
            <div>
              {conflictType === MissingComponentConflict.BOTH
                ? 'and the component'
                : 'The component'}
            </div>
            <FlexWrapper style={{ margin: `0 ${s8}` }}>
              &quot;
              <EntityRepresentation
                entityId={reference!.target}
                entityType={APIEntityType.COMPONENT}
                enhancedScopeData={enhancedScopeData}
                graphics={graphics}
              />
              &quot;
            </FlexWrapper>
          </>
        )}
        {conflictType === MissingComponentConflict.BOTH ? ' do ' : ' does '} not
        exist {branch}
      </FlexWrapper>
      <div>
        Before you can create this reference, you have to go back to the Create
        Component merge step and create the missing{' '}
        {conflictType === MissingComponentConflict.BOTH
          ? 'components'
          : 'component'}
        .
      </div>
    </AdditionalContentWithBottomMargin>
  );
};

export default AdditionalContentForMissingComponentsConflict;
