import {
  DisableableDirectedTripleWithFilters,
  InstanceCounts,
  NamedDirectedTriple,
  TraversalBuilderState,
  TripleOption,
  TripleOptions,
  TripleSortOrder,
} from '../types';
import {
  ApiCountInstancesRequest,
  DirectedTriple,
  DirectedTripleWithFilters,
  ReferenceDirection,
  TraversalPathMatchingType,
  ViewpointBuilderFilters,
} from '@ardoq/api-types';
import { GraphNode } from '@ardoq/graph';
import { getTripleId } from './getTripleId';
import { getEmptySelectedNodeData } from './getEmptySelectedNodeData';
import { namedDirectedTripleToBackendFormat } from './namedDirectedTripleToBackendFormat';
import { pathToKey } from './pathToKey';
import { LinkedComponent } from '@ardoq/data-model';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { partition } from 'lodash';

type CreateOptionsArgs = {
  graphNode: GraphNode;
  outgoingEdges: {
    targetId: string;
    sourceId: string;
    referenceId: string;
  }[];
  incomingEdges: {
    targetId: string;
    sourceId: string;
    referenceId: string;
  }[];
  state: Pick<
    TraversalBuilderState,
    | 'rawGraphNodes'
    | 'rawGraphEdges'
    | 'selectedGraph'
    | 'startNode'
    | 'enhancedScopeData'
    | 'traversalStartSet'
    | 'traversalStartQuery'
    | 'tripleOptions'
    | 'triplesSortAndFiltersState'
    | 'instanceCounts'
    | 'initialPaths'
    | 'shouldIncludeInstanceCounts'
    | 'pathCollapsing'
  >;
  triplePathForSelectedNode: DirectedTripleWithFilters[][] | null;
  filters: ViewpointBuilderFilters | null;
  /**
   * The options are recreated whenever the user interacts with them, e.g.
   * toggles options on or off. We only need to fetch the instance counts
   * when we create them the first time. This flag is used to indicate
   * whether we should fetch the instance counts or not.
   */
  shouldFetchCounts: boolean;
  startSetFilterId: string | null;
  referenceIdsToStartContext: Set<string>;
};

const isTripleOptionDisabled = (
  isSelected: boolean,
  initialPaths: DisableableDirectedTripleWithFilters[][] | null,
  namedDirectedTriple: NamedDirectedTriple
) => {
  if (!isSelected) {
    return {
      isDisabled: false,
      togglingDisabledExplanation: undefined,
    };
  }

  const namedDirectedTripleInBackendFormat =
    namedDirectedTripleToBackendFormat(namedDirectedTriple); // for unifying 'ardoq_parent' reference type

  const disabledTripleInInitialPath = initialPaths
    ?.flatMap(path => path)
    .map(triple => ({
      ...triple,
      ...namedDirectedTripleToBackendFormat(triple),
    }))
    .find(
      triple =>
        triple.direction === namedDirectedTripleInBackendFormat.direction &&
        triple.sourceType === namedDirectedTripleInBackendFormat.sourceType &&
        triple.referenceType ===
          namedDirectedTripleInBackendFormat.referenceType &&
        triple.targetType === namedDirectedTripleInBackendFormat.targetType &&
        triple.isDisabled
    );

  return {
    isDisabled: Boolean(disabledTripleInInitialPath),
    togglingDisabledExplanation:
      disabledTripleInInitialPath?.togglingDisabledExplanation,
  };
};

export const createOptions = ({
  state: {
    rawGraphNodes,
    rawGraphEdges,
    selectedGraph,
    enhancedScopeData,
    traversalStartSet,
    traversalStartQuery,
    tripleOptions: { showOnlyOptionsWithInstanceCounts },
    instanceCounts,
    initialPaths,
    shouldIncludeInstanceCounts,
    pathCollapsing: { rules },
  },
  graphNode,
  outgoingEdges,
  incomingEdges,
  triplePathForSelectedNode,
  filters,
  shouldFetchCounts,
  startSetFilterId,
  referenceIdsToStartContext,
}: CreateOptionsArgs): TripleOptions => {
  if (!enhancedScopeData)
    return {
      outgoing: [],
      incoming: [],
      selectedNode: getEmptySelectedNodeData(),
      fetchCountsArgs: null,
      optionCountLoadingState: 'idle',
      showOnlyOptionsWithInstanceCounts,
      instanceCountsResponse: null,
    };

  const selectedOutgoingEdges = new Set(
    (selectedGraph.sourceMap.get(graphNode.id) ?? [])
      .filter(({ referenceId }) => !referenceIdsToStartContext.has(referenceId))
      .map(({ tripleId }) => tripleId)
  );
  const selectedIncomingEdges = new Set(
    (selectedGraph.targetMap.get(graphNode.id) ?? [])
      .filter(({ referenceId }) => !referenceIdsToStartContext.has(referenceId))
      .map(({ tripleId }) => tripleId)
  );

  const currentPath = triplePathForSelectedNode
    ? (triplePathForSelectedNode[0] ?? [])
    : null;

  const virtualEdgeStates = rules
    .filter(({ isActive, isExpanded }) => isActive && !isExpanded)
    .flatMap(({ virtualEdgeStates }) => virtualEdgeStates);

  const tripleOptionReplacements = virtualEdgeStates
    .flatMap(({ tripleReplacements }) => tripleReplacements)
    .filter(({ graphNodeId }) => graphNodeId === graphNode.id);

  const edgesToBeRemovedOnCollapsing = new Set(
    virtualEdgeStates.flatMap(
      ({ edgesToBeRemovedOnCollapsing }) => edgesToBeRemovedOnCollapsing
    )
  );

  const triplesToBeRemovedOnCollapsing = [
    ...(selectedGraph.sourceMap.get(graphNode.id) ?? []),
    ...(selectedGraph.targetMap.get(graphNode.id) ?? []),
  ]
    .filter(({ referenceId }) => edgesToBeRemovedOnCollapsing.has(referenceId))
    .map(({ tripleId }) => tripleId);

  const incomingInTraversalDirectionTriples = new Set(
    [
      ...(selectedGraph.sourceMap.get(graphNode.id) ?? []),
      ...(selectedGraph.targetMap.get(graphNode.id) ?? []),
    ]
      .filter(({ referenceId }) => referenceIdsToStartContext.has(referenceId))
      .map(({ tripleId }) => tripleId)
  );
  // Filtering depends on the traversal direction (not triple direction).
  // We treat options in general dependent on the traversal direction. We don't
  // show the option of a triple which has already been traversed in relation to
  // the current node. Instead, we mark such an option as unselected so that the
  // user can extend the traversal in the opposite reference direction. For
  // example, if the current node is B in an A -> B traversal, the user can now
  // extend that to A -> B <- A.
  // The `triplesToBeRemovedOnCollapsing` are based on the collapsing rule.
  // We mark any reference of a match of a collapsing rule to be removed as
  // long as the collapsed path is not branching off, starting at the end of a
  // collapsed path. So if all references are marked to be removed, we don't
  // show any options at all for these references. This can have unexpected
  // behavior. If the start node of a collapsed path has a branch and the user
  // unchecks that branching option, the option will disappear if that was the
  // only branch. There is no easy way out of this. Considering that the
  // standard flow is to select a start context, then define the traversal and
  // optionally some required or collapsed paths without going back to traversal
  // modeling, this seems like a reasonable tradeoff.
  const filterCollapseOptions = ({ tripleId }: TripleOption) =>
    incomingInTraversalDirectionTriples.has(tripleId) ||
    !triplesToBeRemovedOnCollapsing.includes(tripleId);

  const [outgoingReplacements, incomingReplacements] = partition(
    tripleOptionReplacements,
    ({ replacementDirection }) => replacementDirection === 'outgoing'
  );

  const outgoing = [
    ...outgoingReplacements.map(({ tripleReplacement }) => tripleReplacement),
    ...outgoingEdges
      .map(({ sourceId, targetId, referenceId }) => {
        const tripleId = getTripleId({
          sourceId,
          targetId,
          referenceId,
        });
        const isSelected = selectedOutgoingEdges.has(tripleId);
        /**
         * Outgoing and incoming edges are all the edges connected to the node in
         * focus, that is the hovered node, taken from the base graph. The raw maps
         * hold all the entities of that base graph (base graph describes the
         * structure of the graph with entity ids, the raw maps hold the data
         * of the entities to display them nicely.)
         */
        const metaDataComponent = rawGraphNodes.get(targetId)!.metaData;
        const metaDataReference = rawGraphEdges.get(referenceId)!.metaData;

        const namedDirectedTriple: NamedDirectedTriple = {
          direction: ReferenceDirection.OUTGOING,
          sourceType: getComponentName(sourceId, enhancedScopeData),
          referenceType: getReferenceDisplayText(
            referenceId,
            enhancedScopeData
          ),
          targetType: getComponentName(targetId, enhancedScopeData),
        };

        const { isDisabled, togglingDisabledExplanation } =
          isTripleOptionDisabled(isSelected, initialPaths, namedDirectedTriple);

        const countKey = getCountKey({
          currentPath,
          namedDirectedTriple,
        });

        const tripleInstanceCount = getInstanceCounts({
          tripleId,
          candidates: selectedGraph.sourceMap.get(graphNode.id) ?? [],
          instanceCounts,
        });

        return {
          tripleId,
          isSelected,
          isDisabled,
          togglingDisabledExplanation,
          metaDataComponent,
          metaDataReference,
          namedDirectedTriple,
          countKey,
          instanceCounts: tripleInstanceCount,
        };
      })
      .filter(filterCollapseOptions),
  ];

  const incoming = [
    ...incomingReplacements.map(({ tripleReplacement }) => tripleReplacement),
    ...incomingEdges
      .map(({ sourceId, targetId, referenceId }) => {
        const tripleId = getTripleId({
          sourceId,
          targetId,
          referenceId,
        });
        const isSelected = selectedIncomingEdges.has(tripleId);
        const metaDataComponent = rawGraphNodes.get(sourceId)!.metaData;
        const metaDataReference = rawGraphEdges.get(referenceId)!.metaData;

        const namedDirectedTriple: NamedDirectedTriple = {
          direction: ReferenceDirection.INCOMING,
          sourceType: getComponentName(sourceId, enhancedScopeData),
          referenceType: getReferenceDisplayText(
            referenceId,
            enhancedScopeData
          ),
          targetType: getComponentName(targetId, enhancedScopeData),
        };

        const { isDisabled, togglingDisabledExplanation } =
          isTripleOptionDisabled(isSelected, initialPaths, namedDirectedTriple);

        const countKey = getCountKey({
          currentPath,
          namedDirectedTriple,
        });
        const tripleInstanceCounts = getInstanceCounts({
          tripleId,
          candidates: selectedGraph.targetMap.get(graphNode.id) ?? [],
          instanceCounts,
        });

        return {
          tripleId,
          isSelected,
          isDisabled,
          togglingDisabledExplanation,
          metaDataComponent,
          metaDataReference,
          namedDirectedTriple,
          countKey,
          instanceCounts: tripleInstanceCounts,
        };
      })
      .filter(filterCollapseOptions),
  ];

  return {
    incoming,
    outgoing,
    selectedNode: {
      metaDataComponent: rawGraphNodes.get(graphNode.modelId)!.metaData,
    },
    fetchCountsArgs:
      shouldFetchCounts && shouldIncludeInstanceCounts
        ? getFetchCountsArgs({
            incomingOptions: incoming,
            outgoingOptions: outgoing,
            startSet: traversalStartSet,
            startQuery: traversalStartQuery,
            currentPath,
            filters,
            startSetFilterId,
            pathMatching: TraversalPathMatchingType.LOOSE,
          })
        : null,
    optionCountLoadingState: 'idle',
    showOnlyOptionsWithInstanceCounts,
    instanceCountsResponse: null,
  };
};

type GetCountKeyArgs = {
  currentPath: DirectedTripleWithFilters[] | null;
  namedDirectedTriple: NamedDirectedTriple;
};

export const getCountKey = ({
  currentPath,
  namedDirectedTriple,
}: GetCountKeyArgs) =>
  currentPath
    ? pathToKey(
        [...currentPath, namedDirectedTriple].map(
          namedDirectedTripleToBackendFormat
        )
      )
    : '';

type GetInstanceCountsArgs = {
  tripleId: string;
  candidates: (LinkedComponent & {
    tripleId: string;
  })[];
  instanceCounts: Map<string, InstanceCounts>;
};

const getInstanceCounts = ({
  tripleId,
  candidates,
  instanceCounts,
}: GetInstanceCountsArgs) =>
  getCountOfConnectedNode(tripleId, candidates, instanceCounts);

const getCountOfConnectedNode = (
  tripleId: string,
  candidates: (LinkedComponent & {
    tripleId: string;
  })[],
  instanceCounts: Map<string, InstanceCounts>
) => {
  const connectedNode = candidates.find(
    ({ tripleId: tripleIdCandidate }) => tripleIdCandidate === tripleId
  );
  if (!connectedNode) {
    return null;
  }

  return instanceCounts.get(connectedNode.componentId)?.counts ?? null;
};

type GetFetchCountsArgs = Omit<ApiCountInstancesRequest, 'paths'> & {
  incomingOptions: TripleOption[];
  outgoingOptions: TripleOption[];
  currentPath: DirectedTriple[] | null;
};

export const getFetchCountsArgs = ({
  incomingOptions,
  outgoingOptions,
  startSet,
  startQuery,
  currentPath,
  filters,
  startSetFilterId,
  pathMatching,
}: GetFetchCountsArgs): ApiCountInstancesRequest | null => {
  if (
    !(startSet && startSet.length > 0 && currentPath) &&
    !(startQuery && currentPath)
  ) {
    return null;
  }

  const paths = [
    ...outgoingOptions.map(({ namedDirectedTriple }) => namedDirectedTriple),
    ...incomingOptions.map(({ namedDirectedTriple }) => namedDirectedTriple),
  ].map(namedDirectedTriple =>
    [...currentPath, namedDirectedTriple].map(
      namedDirectedTripleToBackendFormat
    )
  );

  return {
    startSet,
    startQuery,
    paths,
    filters,
    startSetFilterId,
    pathMatching,
  };
};

const getComponentName = (
  id: string,
  { componentsById }: NonNullable<TraversalBuilderState['enhancedScopeData']>
) => componentsById[id].name;

const getReferenceDisplayText = (
  id: string,
  { referencesById }: NonNullable<TraversalBuilderState['enhancedScopeData']>
) => referencesById[id].displayText ?? '';

export const sortByKey =
  (tripleSortOrder: TripleSortOrder) =>
  (
    optionA: TripleOptions['outgoing'][0],
    optionB: TripleOptions['outgoing'][0]
  ) => {
    if (tripleSortOrder === 'descending_instance_count') {
      return (optionB.instanceCounts ?? 0) - (optionA.instanceCounts ?? 0);
    }

    const locale = getCurrentLocale();

    const sortKeyA = getSortKey({
      tripleSortOrder,
      metaDataReference: optionA.metaDataReference,
      metaDataComponent: optionA.metaDataComponent,
    });
    const sortKeyB = getSortKey({
      tripleSortOrder,
      metaDataReference: optionB.metaDataReference,
      metaDataComponent: optionB.metaDataComponent,
    });

    return localeCompare(sortKeyA, sortKeyB, locale);
  };

type GetSortKeyArgs = {
  tripleSortOrder: TripleSortOrder;
  metaDataReference: { representationData: { displayLabel: string } | null };
  metaDataComponent: { label: string };
};

const getSortKey = ({
  tripleSortOrder,
  metaDataReference: { representationData },
  metaDataComponent: { label },
}: GetSortKeyArgs) => {
  // `label` is the component type name, `displayLabel` the reference type name.
  const displayLabel = representationData?.displayLabel ?? '';
  if (tripleSortOrder === 'reference_type_a_z') {
    return `${displayLabel}-${label}`;
  }

  return `${label}-${displayLabel}`;
};
