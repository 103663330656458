import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { CheckboxGroup, CheckboxGroupItem } from '@ardoq/forms';
import { ActionTag, AuditLogTestIds } from '@ardoq/audit-log';
import { ActionsSectionProps } from '../types';
import { FilterSections, sectionIconNamesMap } from '../consts';
import { AuditLogAction } from '@ardoq/api-types';

const auditLogActionToDataTestIdMap: Record<AuditLogAction, AuditLogTestIds> = {
  [AuditLogAction.CREATE]: AuditLogTestIds.FILTER_ACTION_CREATE,
  [AuditLogAction.UPDATE]: AuditLogTestIds.FILTER_ACTION_UPDATE,
  [AuditLogAction.DELETE]: AuditLogTestIds.FILTER_ACTION_DELETE,
};

const ActionsSection = ({
  selected,
  options,
  onValueChange,
}: ActionsSectionProps) => (
  <SidebarMenuSection
    title={FilterSections.ACTIONS}
    leftIcon={{ name: sectionIconNamesMap[FilterSections.ACTIONS] }}
  >
    <CheckboxGroup
      value={selected}
      onValueChange={actions => onValueChange(actions as AuditLogAction[])}
    >
      {options.map(({ value: actionType, ...actionTagOption }) => (
        <CheckboxGroupItem
          key={actionType}
          value={actionType}
          dataTestId={auditLogActionToDataTestIdMap[actionType]}
        >
          <ActionTag {...actionTagOption} />
        </CheckboxGroupItem>
      ))}
    </CheckboxGroup>
  </SidebarMenuSection>
);

export default ActionsSection;
