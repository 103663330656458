import {
  carry,
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { map, switchMap, tap } from 'rxjs';
import {
  createUseCaseFromExisting,
  deleteUseCase,
  fetchUseCasesData,
  navigateToUseCaseTab,
  selectUseCaseTab,
  setUseCasesError,
} from './actions';
import { api, handleError, useCaseApi } from '@ardoq/api';
import { filter, withLatestFrom } from 'rxjs/operators';
import { useCaseManagement$ } from './useCaseManagement$';
import { confirm, confirmDelete } from '@ardoq/modal';
import { resetEditor, setInitialEditorState } from './UseCaseEditor/actions';
import { useCaseEditorOperations } from './UseCaseEditor/useCaseEditorOperations';
import { useCaseEditor$ } from './UseCaseEditor/useCaseEditor$';
import { UseCaseTabs } from './types';
import { useCases$ } from './streams';
import { getEditorStateFromExistingUseCase } from './utils';
import { ArdoqError } from '@ardoq/common-helpers';

const logAndSetError = (messageString: string) => (error: ArdoqError) => {
  const errorMessage = api.logErrorIfNeededAndReturnMessage(
    error,
    messageString
  );
  dispatchAction(setUseCasesError(errorMessage));
};

const confirmDeleteUseCase = (name: string) => {
  return confirmDelete({
    title: 'Delete use case?',
    text: (
      <>
        <p>You are about to delete the use case &quot;{name}&quot;.</p>
        <p>This action can not be undone.</p>
      </>
    ),
  });
};

const confirmDiscardingChanges = () => {
  return confirm({
    title: `Confirmation required`,
    text: `Are you sure you want to leave? You have some unsaved changes.`,
  });
};

const handleNavigateToUseCaseTab = routine(
  ofType(navigateToUseCaseTab),
  extractPayload(),
  withLatestFrom(useCaseManagement$, useCaseEditor$),
  tap(async ([{ tab, skipCheck }, managementState, editorState]) => {
    const { selectedTab: currentTab } = managementState;
    if (currentTab !== tab) {
      const hasChanges =
        useCaseEditorOperations.getUseCaseEditorHasChanges(editorState);
      if (hasChanges && !skipCheck) {
        const confirmNavigation = await confirmDiscardingChanges();
        if (!confirmNavigation) return;
      }
      dispatchAction(selectUseCaseTab(tab));
      dispatchAction(resetEditor());
    }
  })
);

const handleDeleteUseCase = routine(
  ofType(deleteUseCase),
  extractPayload(),
  carry(switchMap(({ name }) => confirmDeleteUseCase(name))),
  filter(([_, confirmed]) => !!confirmed),
  map(([{ id }]) => id),
  switchMap(useCaseApi.delete),
  handleError(logAndSetError('Error deleting use case')),
  tap(() => dispatchAction(fetchUseCasesData()))
);

const createNewUseCaseFromExisting = routine(
  ofType(createUseCaseFromExisting),
  extractPayload(),
  withLatestFrom(useCases$),
  tap(([id, useCases]) => {
    const targetUseCase = useCases?.find(uc => uc._id === id);
    if (targetUseCase) {
      dispatchAction(navigateToUseCaseTab({ tab: UseCaseTabs.CREATE }));
      dispatchAction(
        setInitialEditorState(getEditorStateFromExistingUseCase(targetUseCase))
      );
    }
  })
);

export default collectRoutines(
  handleNavigateToUseCaseTab,
  handleDeleteUseCase,
  createNewUseCaseFromExisting
);
