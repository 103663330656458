import { Privilege, PrivilegeLabel } from '@ardoq/api-types';
import { IconDecorator } from '@ardoq/decorators';
import { colors } from '@ardoq/design-tokens';
import { SvgImage, SvgImageName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { privilegesOperations } from 'privileges/privilegesOperations';

const { getPrivilegeIconName, getPrivilegeDisplayLabel } = privilegesOperations;

export type PrivilegeDecoratorProps = {
  privilege: Privilege;
};

const PrivilegeDecoratorIcon = ({ privilege }: PrivilegeDecoratorProps) => {
  if (privilege.label === PrivilegeLabel.ACCESS_DISCOVER) {
    return (
      <SvgImage
        svgImageName={SvgImageName.INVERTED_ARDOQ_LOGO}
        height={'1.5em'}
        width={'1.5em'}
        color={colors.icon}
      />
    );
  }
  return <IconDecorator iconName={getPrivilegeIconName(privilege.label)} />;
};

export const PrivilegeDecorator = ({ privilege }: PrivilegeDecoratorProps) => {
  return (
    <FlexBox gap="small" marginBottom="small">
      <PrivilegeDecoratorIcon privilege={privilege} />
      {getPrivilegeDisplayLabel(privilege)}
    </FlexBox>
  );
};
