import { findMatchGroups } from '@ardoq/common-helpers';
import { colors } from '@ardoq/design-tokens';
import { SearchInput } from '@ardoq/forms';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { getCurrentLocale } from '@ardoq/locale';
import { Island } from '@ardoq/page-layout';
import { MatchGroupsRenderer } from '@ardoq/renderers';
import { FiltersBar } from '@ardoq/status-ui';
import { Header3, Text } from '@ardoq/typography';
import { AssetEntry } from 'assets/types';
import { useState } from 'react';
import styled from 'styled-components';

type AssetSectionProps = {
  name: string;
  assets: Array<AssetEntry>;
  icon: React.ReactNode;
  onClick?: (asset: AssetEntry) => void;
};

const ClickableBox = styled(FlexBox)`
  cursor: pointer;
`;
const ScrollableBox = styled(Box)`
  max-height: 190px;
  overflow-y: auto;
`;

export const filterAssets = <T extends { name: string }>(
  term: string,
  assets: T[]
): T[] => {
  const sanitizedSearch = term.toLowerCase().trim();
  const searchTerms = sanitizedSearch.split(/[\s_]+/);

  return assets.filter(t => {
    const name = t.name.toLowerCase();
    return searchTerms.every(term => name.includes(term));
  });
};

const SearchPhrase = styled.span`
  font-weight: bold;
  text-decoration: underline;
  color: ${colors.grey15};
`;

export const AssetSection = ({
  name,
  assets,
  icon,
  onClick,
}: AssetSectionProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredAssets = filterAssets(searchTerm, assets);
  return (
    <>
      <Island
        title={<Header3>{`${name} (${assets.length})`}</Header3>}
        rightContent={
          Boolean(assets.length > 4) && (
            <FiltersBar>
              <SearchInput
                value={searchTerm}
                onValueChange={setSearchTerm}
                placeholder="Search"
              />
            </FiltersBar>
          )
        }
        showHeaderBorder={false}
        maxWidth="100%"
        minWidth="100%"
        bodySpacing="none"
        headerSpacing="large"
      >
        <ScrollableBox paddingX="large" paddingBottom="large">
          <Stack gap="large">
            {filteredAssets.map(({ _id, name }) => (
              <ClickableBox
                key={_id}
                gap="small"
                align="center"
                DEPRECATED_onClick={() => onClick?.({ _id, name })}
              >
                {icon}
                <Text variant="text2Bold">
                  <MatchGroupsRenderer
                    matchGroups={findMatchGroups(
                      name,
                      searchTerm,
                      getCurrentLocale()
                    )}
                    Emphasis={SearchPhrase}
                  />
                </Text>
              </ClickableBox>
            ))}

            {assets.length > 0 && filteredAssets.length === 0 && (
              <Text>No items found for your search term.</Text>
            )}
          </Stack>
        </ScrollableBox>
      </Island>
    </>
  );
};
