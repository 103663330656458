import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { setLoadedScenarioData, updateLoadedScenarioData } from 'scope/actions';
import {
  PayloadLoadedScenarioData,
  PayloadUpdateLoadedScenarioData,
} from 'scope/types';
import { referenceListToGraphData } from 'modelInterface/util';
import References from 'collections/references';
import { CollectionView } from 'collections/consts';
import { APIScopeData, ArdoqId } from '@ardoq/api-types';
import {
  ComponentIdsPayload,
  notifyComponentsAdded,
} from 'streams/components/ComponentActions';
import { uniq } from 'lodash';
import { GraphModelShape } from '@ardoq/data-model';

export type LoadedScenarioData = PayloadLoadedScenarioData & {
  scenarioRelatedGraph: GraphModelShape | null;
  contextComponents: Set<ArdoqId>;
};
export const EMPTY_LOADED_SCENARIO_DATA: LoadedScenarioData = {
  scenarioData: null,
  scenarioRelatedGraph: null,
  contextComponents: new Set(),
};

const toBackboneModel = (id: ArdoqId) =>
  References.collection.views.get(CollectionView.BASE_VIEW)!.get(id);

const getContextComponentsSet = (scenarioData: APIScopeData | null) => {
  if (!scenarioData) {
    return new Set<ArdoqId>();
  }

  const { components, scopeComponents } = scenarioData;
  const scopeComponentsSet = new Set(scopeComponents);
  return new Set<ArdoqId>(
    components
      .map(component => component._id)
      .filter(id => !scopeComponentsSet.has(id))
  );
};

const handleSetLoadedScenarioData = (
  _: LoadedScenarioData,
  { scenarioData }: PayloadLoadedScenarioData
): LoadedScenarioData => {
  const scenarioRelatedReferences = scenarioData?.connected.references.map(
    ({ _id }) => toBackboneModel(_id)!
  );
  return {
    scenarioData,
    scenarioRelatedGraph: scenarioRelatedReferences
      ? referenceListToGraphData(scenarioRelatedReferences)
      : null,
    contextComponents: getContextComponentsSet(scenarioData),
  };
};

const handleUpdateLoadedScenarioData = (
  { scenarioData, scenarioRelatedGraph }: LoadedScenarioData,
  { components, references }: PayloadUpdateLoadedScenarioData
): LoadedScenarioData => {
  if (scenarioData === null) {
    return {
      scenarioData,
      scenarioRelatedGraph,
      contextComponents: getContextComponentsSet(scenarioData),
    };
  }

  const updatedScenarioData = {
    ...scenarioData,
    components,
    references,
  };

  return {
    scenarioData: updatedScenarioData,
    scenarioRelatedGraph,
    contextComponents: getContextComponentsSet(updatedScenarioData),
  };
};

const handleComponentsAdded = (
  loadedScenarioData: LoadedScenarioData,
  { componentIds }: ComponentIdsPayload
): LoadedScenarioData => {
  const { scenarioData, scenarioRelatedGraph } = loadedScenarioData;
  if (scenarioData === null) {
    return loadedScenarioData;
  }

  const updatedScenarioData = {
    ...scenarioData,
    scopeComponents: uniq([...scenarioData.scopeComponents, ...componentIds]),
  };

  return {
    scenarioData: updatedScenarioData,
    scenarioRelatedGraph,
    contextComponents: getContextComponentsSet(updatedScenarioData),
  };
};

const defaultState: LoadedScenarioData = {
  scenarioData: null,
  scenarioRelatedGraph: null,
  contextComponents: new Set(),
};

const reducers = [
  reducer(setLoadedScenarioData, handleSetLoadedScenarioData),
  reducer(updateLoadedScenarioData, handleUpdateLoadedScenarioData),
  reducer(notifyComponentsAdded, handleComponentsAdded),
];

export const loadedScenarioData$ = persistentReducedStream<LoadedScenarioData>(
  'loadedScenarioData$',
  defaultState,
  reducers
);
