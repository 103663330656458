import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { AppRouterState, ManageOrganizationRoute } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { map } from 'rxjs/operators';
import { navigateToManageOrganization } from 'router/navigationActions';
import { OrgTabs } from './types';
import manageOrganizationNavigation$ from './manageOrganizationNavigation$';

const isValidOrgTab = (chosenOrgTab: OrgTabs): boolean => {
  const options = Object.values(OrgTabs);
  return options.includes(chosenOrgTab);
};

const manageOrganizationRoute = new Route<
  AppRouterState,
  ManageOrganizationRoute
>({
  doesLocationMatch: ({ path }) => /\/manage-organization\/?.*?/.test(path),
  locationToRouterState: ({ path }) => {
    const appModule = AppModules.MANAGE_ORGANIZATION;
    const parts = path.split('/');
    const manageOrgTab = parts[2] as OrgTabs;

    if (isValidOrgTab(manageOrgTab)) {
      return {
        appModule,
        manageOrgTab,
      };
    }

    return {
      appModule: AppModules.MANAGE_ORGANIZATION,
      manageOrgTab: OrgTabs.SETTINGS,
    };
  },
  doesRouterStateMatch: ({ appModule }) =>
    appModule === AppModules.MANAGE_ORGANIZATION,
  routerStateToLocation: ({ manageOrgTab }) => {
    const path = `/${AppModules.MANAGE_ORGANIZATION}/${manageOrgTab}`;

    if (manageOrgTab === OrgTabs.SETTINGS) {
      return {
        path,
        title: 'Settings',
      };
    } else if (manageOrgTab === OrgTabs.WEBHOOKS) {
      return {
        path,
        title: 'Webhooks',
      };
    } else if (manageOrgTab === OrgTabs.BUNDLES) {
      return {
        path,
        title: 'Bundles',
      };
    } else if (manageOrgTab === OrgTabs.ENTITY_GROUPS) {
      return {
        path,
        title: 'Entity Groups',
      };
    } else if (manageOrgTab === OrgTabs.USE_CASES) {
      return {
        path,
        title: 'Use Cases',
      };
    } else if (manageOrgTab === OrgTabs.MIGRATION_CHECKLIST) {
      return {
        path,
        title: 'Checklist',
      };
    } else if (manageOrgTab === OrgTabs.FEATURE_SETTINGS) {
      return {
        path,
        title: 'Settings',
      };
    } else if (manageOrgTab === OrgTabs.WORKSPACE_OVERVIEW) {
      return {
        path,
        title: 'Workspace overview',
      };
    }

    return {
      path: `/${AppModules.MANAGE_ORGANIZATION}/${OrgTabs.SETTINGS}`,
      title: 'Settings',
    };
  },
  setApplicationStateFromRoute: ({ manageOrgTab }) => {
    dispatchAction(
      navigateToManageOrganization({
        manageOrgTab,
      })
    );
  },
  getPartialRouterStateStream: () =>
    manageOrganizationNavigation$.pipe(
      map(({ manageOrgTab }) => {
        return {
          manageOrgTab,
        };
      })
    ),
});

export default manageOrganizationRoute;
