import { Params, readParam, writeParam } from './paramRegistry';
import {
  IndexNode,
  isIndexNode,
  isLayoutNode,
  isParamNode,
  RouteNode,
} from './routeNode';
import {
  EmptyParams,
  Location,
  MatchedLocationIn,
  UnmatchedLocation,
} from './location';

const splitPath = (path: string) => {
  const nextSlash = path.indexOf('/', 1);
  const segment = nextSlash === -1 ? path : path.slice(0, nextSlash);
  const remainingPath = nextSlash === -1 ? null : path.slice(nextSlash);

  return [segment, remainingPath];
};

const extractParam = (node: RouteNode, segment: string): Partial<Params> => {
  if (!isParamNode(node)) {
    return {};
  }

  const paramName = node.path.slice(2) as keyof Params;
  return {
    [paramName]: readParam(paramName, segment),
  };
};

type MatchState = {
  route: string;
  activeRoutes: RouteNode[];
  unmatchedSuffix: string | null;
};

/**
 * Traverse the route tree and find the active routes for the given path.
 */
const matchRoutes = (
  state: MatchState,
  routeNodes: readonly RouteNode[] | undefined
): MatchState => {
  const [segment, remainingPath] = splitPath(state.unmatchedSuffix ?? '');

  let indexNode: IndexNode | undefined;
  for (const node of routeNodes ?? []) {
    if (isParamNode(node) && segment) {
      return matchRoutes(
        {
          route: state.route + node.path,
          activeRoutes: [...state.activeRoutes, node],
          unmatchedSuffix: remainingPath,
        },
        node.children
      );
    } else if (node.path === segment) {
      return matchRoutes(
        {
          route: state.route + node.path,
          activeRoutes: [...state.activeRoutes, node],
          unmatchedSuffix: remainingPath,
        },
        node.children
      );
    } else if (isLayoutNode(node)) {
      const layoutMatch = matchRoutes(
        {
          ...state,
          activeRoutes: [...state.activeRoutes, node],
        },
        node.children
      );
      if (layoutMatch?.unmatchedSuffix !== state.unmatchedSuffix) {
        return layoutMatch;
      }
    } else if (isIndexNode(node)) {
      indexNode = node;
    }
  }

  if (!segment && indexNode) {
    return {
      ...state,
      activeRoutes: [...state.activeRoutes, indexNode],
    };
  }

  return state;
};

const readParams = (routes: RouteNode[], path: string): Partial<Params> => {
  const { params } = routes.reduce<{
    params: Partial<Params>;
    remainingPath: string;
  }>(
    ({ params, remainingPath }, route) => {
      const [segment, rest] = splitPath(remainingPath);
      return {
        params:
          segment && isParamNode(route)
            ? { ...params, ...extractParam(route, segment.slice(1)) }
            : params,
        remainingPath: rest ?? '',
      };
    },
    { params: {}, remainingPath: path }
  );

  return params;
};

export const matchLocation = <RouteTree extends RouteNode>(
  location: Location,
  routeTree: RouteTree
): MatchedLocationIn<RouteTree> | UnmatchedLocation => {
  const routeMatches = matchRoutes(
    {
      route: '',
      activeRoutes: [],
      unmatchedSuffix: location.route,
    },
    [routeTree]
  );

  return {
    ...routeMatches,
    params: location.params,
  } as MatchedLocationIn<RouteTree> | UnmatchedLocation;
};

export const matchPath = <RouteTree extends RouteNode>(
  path: string,
  routeTree: RouteTree
): MatchedLocationIn<RouteTree> | UnmatchedLocation => {
  const routeMatches = matchRoutes(
    {
      route: '',
      activeRoutes: [],
      unmatchedSuffix: path,
    },
    [routeTree]
  );

  const params = readParams(routeMatches.activeRoutes, path);

  return {
    ...routeMatches,
    params,
  } as MatchedLocationIn<RouteTree> | UnmatchedLocation;
};

export const interpolatePath = <
  Path extends string,
  RouteParams extends EmptyParams,
>(
  location: Location<Path, RouteParams>
): string => {
  let path: string = location.route;
  for (const entry of Object.entries(location.params)) {
    const key = entry[0] as keyof Params;
    const value = entry[1] as Params[keyof Params];
    path = path.replace(`/:${key}`, `/${writeParam(key, value)}`);
  }
  return path;
};
