import { SidebarMenu } from '@ardoq/sidebar-menu';
import { dispatchAction } from '@ardoq/rxbeach';
import { toggleIsFilterSidebarOpen } from '../actions';
import { IconName } from '@ardoq/icons';
import WorkspaceSection from './WorkspaceSection';
import ActionsSection from './ActionsSection';
import UsersSection from './UsersSection';
import { FilterSectionProps } from '../types';
import { AuditLogTestIds } from '@ardoq/audit-log';
import { FilterSections } from '../consts';
import DateRangeSection from './DateRangeSection';
import ChangeTypeSection from './ChangeTypeSection';

const FilterSidebar = (props: FilterSectionProps) => (
  <SidebarMenu
    headerTitle="Filter"
    headerLeftIconName={IconName.FILTER_LIST}
    onCloseClick={() => dispatchAction(toggleIsFilterSidebarOpen(false))}
    isPageBodyMenu
    isRightContent
    headerTitleTestId={AuditLogTestIds.FILTER_SIDE_BAR_HEADER_TITLE}
  >
    <ChangeTypeSection {...props[FilterSections.CHANGE_TYPE]} />
    <WorkspaceSection {...props[FilterSections.WORKSPACES]} />
    <ActionsSection {...props[FilterSections.ACTIONS]} />
    <UsersSection {...props[FilterSections.USERS]} />
    <DateRangeSection {...props[FilterSections.DATE_RANGE]} />
  </SidebarMenu>
);
export default FilterSidebar;
