import { MetaModel, SurveyApprovalRelationship } from '@ardoq/api-types';
import { Select } from '@ardoq/select';
import {
  getRelationshipIdentifier,
  isIncomingRelationship,
  isOutgoingRelationship,
  isRelationshipSelected,
} from '../utils';
import { CheckboxIcon } from '@ardoq/icons';
import { keyBy } from 'lodash';
import { compareRelationships } from 'surveyAdmin/SurveyEditor/utils';
import { getCurrentLocale } from '@ardoq/locale';
import { Label } from '@ardoq/forms';
import { FlexBox } from '@ardoq/layout';
import ComponentTypeTag from './ComponentTypeTag';
import { OutgoingArrow } from './PathRepresentation';
import { referenceTypeToFrontendFormat } from './AudienceTraversalDrawer/traversalAudienceHelpers';

const getRelationshipSelectContent = (
  surveyComponentTypeName: string,
  relationship: SurveyApprovalRelationship,
  selectedRelationships: SurveyApprovalRelationship[],
  metamodel: MetaModel
) => {
  return relationship.outgoing
    ? {
        leftContent: (
          <FlexBox align="center" gap="xsmall">
            <CheckboxIcon
              checked={isRelationshipSelected(
                selectedRelationships,
                relationship
              )}
            />
            <ComponentTypeTag
              componentTypeName={surveyComponentTypeName}
              metamodel={metamodel}
              hideLabel
            />
            <OutgoingArrow />
          </FlexBox>
        ),
        rightContent: (
          <FlexBox align="center" gap="xsmall">
            <OutgoingArrow />
            <ComponentTypeTag
              componentTypeName={relationship.componentTypeName}
              metamodel={metamodel}
            />
          </FlexBox>
        ),
      }
    : {
        leftContent: (
          <FlexBox align="center" gap="xsmall">
            <CheckboxIcon
              checked={isRelationshipSelected(
                selectedRelationships,
                relationship
              )}
            />
            <ComponentTypeTag
              componentTypeName={relationship.componentTypeName}
              metamodel={metamodel}
            />
            <OutgoingArrow />
          </FlexBox>
        ),
        rightContent: (
          <FlexBox align="center" gap="xsmall">
            <OutgoingArrow />
            <ComponentTypeTag
              hideLabel
              componentTypeName={surveyComponentTypeName}
              metamodel={metamodel}
            />
          </FlexBox>
        ),
      };
};
const toOption =
  (
    surveyComponentTypeName: string,
    selectedRelationships: SurveyApprovalRelationship[],
    metamodel: MetaModel
  ) =>
  (relationship: SurveyApprovalRelationship) => {
    const normalizedRelationship = {
      ...relationship,
      referenceTypeName: referenceTypeToFrontendFormat(
        relationship.referenceTypeName
      ),
    };
    return {
      ...getRelationshipSelectContent(
        surveyComponentTypeName,
        normalizedRelationship,
        selectedRelationships,
        metamodel
      ),
      value: getRelationshipIdentifier(normalizedRelationship),
      label: normalizedRelationship.referenceTypeName,
    };
  };

type RelationshipSelectorProps = {
  surveyComponentTypeName: string;
  selectableRelationships: SurveyApprovalRelationship[];
  selectedRelationships: SurveyApprovalRelationship[];
  metamodel: MetaModel;
  selectRelationship: (relationship: SurveyApprovalRelationship) => void;
  unselectRelationship: (relationship: SurveyApprovalRelationship) => void;
};

const RelationshipSelector = ({
  surveyComponentTypeName,
  selectableRelationships,
  selectedRelationships,
  metamodel,
  selectRelationship,
  unselectRelationship,
}: RelationshipSelectorProps) => {
  const locale = getCurrentLocale();
  const normalizedSelectableRelationships = selectableRelationships.map(
    relationship => ({
      ...relationship,
      referenceTypeName: referenceTypeToFrontendFormat(
        relationship.referenceTypeName
      ),
    })
  );
  const normalizedSelectedRelationships = selectedRelationships.map(
    relationship => ({
      ...relationship,
      referenceTypeName: referenceTypeToFrontendFormat(
        relationship.referenceTypeName
      ),
    })
  );

  const selectableRelationshipsById = keyBy(
    normalizedSelectableRelationships,
    getRelationshipIdentifier
  );

  const outgoingOptions = normalizedSelectableRelationships
    .filter(isOutgoingRelationship)
    .sort(compareRelationships(locale))
    .map(
      toOption(
        surveyComponentTypeName,
        normalizedSelectedRelationships,
        metamodel
      )
    );

  const incomingOptions = normalizedSelectableRelationships
    .filter(isIncomingRelationship)
    .sort(compareRelationships(locale))
    .map(
      toOption(
        surveyComponentTypeName,
        normalizedSelectedRelationships,
        metamodel
      )
    );

  const options = [
    { label: 'Outgoing', options: outgoingOptions },
    { label: 'Incoming', options: incomingOptions },
  ];
  return (
    <>
      <FlexBox gap="xsmall" align="center">
        <Label width="default">Select a relationship for </Label>
        <ComponentTypeTag
          componentTypeName={surveyComponentTypeName}
          metamodel={metamodel}
        />
      </FlexBox>
      <Select
        closeMenuOnSelect={false}
        controlShouldRenderValue={false}
        options={options}
        noOptionsMessage={() =>
          'No relationships available with an email or user field'
        }
        placeholder="Search and select a relationship"
        onValueChange={relationshipId => {
          if (relationshipId === null) return;
          const relationship = selectableRelationshipsById[relationshipId];
          if (!relationship) return;
          if (
            isRelationshipSelected(
              normalizedSelectedRelationships,
              relationship
            )
          ) {
            unselectRelationship(relationship);
          } else {
            selectRelationship(relationship);
          }
        }}
      />
    </>
  );
};

export default RelationshipSelector;
