import {
  exportRun,
  importRun,
  transferRun,
} from 'integrations/common/streams/transferState/actions';
import {
  dispatchAction,
  routine,
  ofType,
  extractPayload,
} from '@ardoq/rxbeach';
import { filter, map, tap, withLatestFrom } from 'rxjs';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import { getSourceConfig } from '../utils';
import { resourceGroups$ } from '../streams/resourceGroups/resourceGroups$';
import { selectionState$ } from '../streams/selectionState/selectionState$';

const handleTransferRun = routine(
  ofType(transferRun),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'azure-v3'),
  withLatestFrom(
    getTransferStateStream('azure-v3'),
    getConnectionsStream('azure-v3'),
    getResourceTypesStream('azure-v3'),
    getRegionsStream('azure-v3'),
    resourceGroups$,
    selectionState$
  ),
  map(
    ([
      { async, integrationId, dryRun },
      transferState,
      { selectedConnectionIds },
      { selectedResourceTypeIds },
      { selectedRegionIds },
      { selectedResourceGroupNames },
      { subscriptionIds },
    ]) => {
      const sourceConfig = getSourceConfig({
        accountIds: selectedConnectionIds,
        subscriptions: subscriptionIds,
        resourceTypes: selectedResourceTypeIds,
        regions: selectedRegionIds,
        resourceGroups: selectedResourceGroupNames,
      });

      return {
        async,
        integrationId,
        dryRun,
        transferState,
        ...(sourceConfig ? { sourceConfig } : {}),
      };
    }
  ),
  tap(({ transferState, ...runParams }) => {
    const action =
      transferState.transferDirection === TransferDirection.IMPORT
        ? importRun
        : exportRun;
    dispatchAction(action(runParams));
  })
);

export default [handleTransferRun];
