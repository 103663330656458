import { isNil } from 'lodash';
import { isIntegrationId } from 'integrations/common/streams/activeIntegrations/utils';
import { IntegrationId } from '../streams/tabularMappings/types';
import { Maybe } from '@ardoq/common-helpers';
import { AsyncOperations } from './constants';
import { EventType, UserEvent } from 'sync/types';
import { Observable } from 'rxjs';
import { ActiveIntegrations } from '../streams/activeIntegrations/types';
import {
  Progress,
  ProgressDetails,
} from 'integrations/common/streams/fetchProgress/types';

const ASYNC_PREFIX = 'integrations';
const ASYNC_REQUEST_ID_SEPARATOR = '_';

// requestId format example: integrations_excel-v3_aaaaa000-aa0a-0000-0a0aa-0a0aa0aa000a_import
export const getAsyncRequestId = ({
  integrationId,
  funnelId,
  operation,
  resourceId,
}: {
  integrationId: IntegrationId;
  funnelId: string;
  operation: AsyncOperations;
  resourceId?: string;
}) =>
  ASYNC_PREFIX +
  ASYNC_REQUEST_ID_SEPARATOR +
  integrationId +
  ASYNC_REQUEST_ID_SEPARATOR +
  funnelId +
  ASYNC_REQUEST_ID_SEPARATOR +
  operation +
  (resourceId ? ASYNC_REQUEST_ID_SEPARATOR + resourceId : '');

const isIntegrationAsyncRequestId = (requestId?: string) =>
  (requestId || '').startsWith(`${ASYNC_PREFIX}_`);

const isValidAsyncOperation = (
  operation: string
): operation is AsyncOperations =>
  Object.values(AsyncOperations).includes(operation as AsyncOperations);

export const parseAsyncResponsePayload = <Data = unknown>({
  data,
  'event-type': eventType,
}: UserEvent): Maybe<{
  integrationId: IntegrationId;
  operation: AsyncOperations;
  resourceId?: string;
  data: Data;
  status: string;
  funnelId: string;
}> => {
  // data from UserEvent is unknown,
  // we need to verify the existence required of properties
  if (
    typeof data !== 'object' ||
    isNil(data) ||
    !('requestId' in data) ||
    !('status' in data) ||
    !('data' in data) ||
    typeof data.requestId !== 'string' ||
    typeof data.status !== 'string'
  ) {
    return null;
  }

  if (!isIntegrationAsyncRequestId(data.requestId)) return null;

  // progress event contains the same requestId as the original request
  // progress events will be handled separately
  if (eventType === EventType.ASYNC_REQUEST_PROGRESS) return null;

  const [, integrationId, funnelId, operation, resourceId] =
    data.requestId.split(ASYNC_REQUEST_ID_SEPARATOR);

  if (isIntegrationId(integrationId) && isValidAsyncOperation(operation)) {
    return {
      integrationId,
      operation,
      resourceId,
      funnelId,
      data: data.data as Data,
      status: data.status,
    };
  }

  return null;
};

export const parseAsyncProgressResponsePayload = ({
  data,
  'event-type': eventType,
}: UserEvent): Maybe<
  Pick<Progress, 'progress'> & {
    integrationId: IntegrationId;
    operation: AsyncOperations;
    resourceId?: string;
    funnelId: string;
  }
> => {
  if (eventType !== EventType.ASYNC_REQUEST_PROGRESS) return null;

  if (
    typeof data !== 'object' ||
    isNil(data) ||
    !('progress' in data) ||
    !('requestId' in data) ||
    typeof data.requestId !== 'string'
  ) {
    return null;
  }
  const { integrationId, funnelId, operation, resourceId } = parseRequestId(
    data.requestId
  );

  if (isIntegrationId(integrationId) && isValidAsyncOperation(operation)) {
    return {
      integrationId,
      operation,
      resourceId,
      funnelId,
      progress: data.progress as ProgressDetails,
    };
  }

  return null;
};

/**
 * Helper function to ensure that the async response is for the correct request.
 * Example usage:
 * @example
 * routine(
 *  ofType(userEvent),
 *  extractPayload(),
 *  map(parseAsyncResponse),
 *  withLatestFrom(activeIntegrations$),
 *  ensureCorrectAsyncRequest(AsyncOperations.IMPORT),
 *  tap(([response, activeIntegrations]) => {})
 * );
 */

export const ensureCorrectAsyncRequest = <
  T extends Maybe<{
    operation: AsyncOperations;
    integrationId: IntegrationId;
    funnelId: string;
  }>,
  M extends unknown[],
>(
  allowedOperations: AsyncOperations | AsyncOperations[]
) => {
  return (
    source: Observable<[T, ActiveIntegrations, ...M]>
  ): Observable<[T, ActiveIntegrations, ...M]> => {
    return new Observable<[T, ActiveIntegrations, ...M]>(observer => {
      return source.subscribe({
        next([response, activeIntegrations, ...rest]) {
          if (
            !isNil(response) &&
            activeIntegrations[response.integrationId].trackingFunnelId ===
              response.funnelId &&
            [allowedOperations].flat().includes(response.operation)
          ) {
            observer.next([response, activeIntegrations, ...rest]);
          }
        },
        error(error) {
          observer.error(error);
        },
        complete() {
          observer.complete();
        },
      });
    });
  };
};

export const parseRequestId = (requestId: string) => {
  const [, integrationId, funnelId, operation, resourceId] = requestId.split(
    ASYNC_REQUEST_ID_SEPARATOR
  );

  return {
    integrationId,
    funnelId,
    operation,
    resourceId,
  };
};
