import { APISurveyAttributes, ArdoqId } from '@ardoq/api-types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { chooseBroadcastContentId } from 'broadcasts/actions';
import { map } from 'rxjs/operators';
import surveys$ from 'streams/surveys/surveys$';
import {
  SurveyOption,
  SurveySelect,
} from 'broadcasts/broadcastBuilder/broadcastContentForm/SurveySelect';
import { Field, FormSize } from '@ardoq/forms';
import { Tag } from '@ardoq/status-ui';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';

type GroupedSurveyOptions = {
  published: SurveyOption[];
  unpublished: SurveyOption[];
};

type SurveyContentProps = {
  readonly selectedSurvey: ArdoqId | null;
  readonly surveyOptions: GroupedSurveyOptions;
  readonly required: boolean;
};

const defaultSurveyOptions = {
  published: [],
  unpublished: [],
};

const toSurveyOptions = (
  surveys: APISurveyAttributes[]
): {
  surveyOptions: GroupedSurveyOptions;
} => {
  const locale = getCurrentLocale();

  return {
    surveyOptions:
      surveys
        ?.sort((a, b) => localeCompare(a.name, b.name, locale))
        ?.reduce<GroupedSurveyOptions>((acc, { _id, name, published }) => {
          if (!published) {
            return {
              ...acc,
              unpublished: [
                ...acc.unpublished,
                {
                  value: _id,
                  label: name,
                  isDisabled: true,
                  leftContent: <Tag label="Unpublished" />,
                },
              ],
            };
          }
          return {
            ...acc,
            published: [
              ...acc.published,
              {
                value: _id,
                label: name,
              },
            ],
          };
        }, defaultSurveyOptions) ?? defaultSurveyOptions,
  };
};

const SurveyContent = ({
  selectedSurvey,
  surveyOptions,
  required,
}: SurveyContentProps) => {
  const selectedSurveyOption = [
    ...surveyOptions.published,
    ...surveyOptions.unpublished,
  ].find(({ value: surveyId }) => surveyId === selectedSurvey);

  return (
    <Field label="Survey name" formSize={FormSize.DEFAULT}>
      <SurveySelect
        required={required}
        surveyOptions={[
          {
            label: 'Published',
            options: surveyOptions.published,
          },
          {
            label: 'Unpublished',
            options: surveyOptions.unpublished,
          },
        ]}
        selectedOption={selectedSurveyOption ?? null}
        onChange={({ value: surveyId }: SurveyOption) => {
          if (surveyId !== selectedSurvey) {
            dispatchAction(chooseBroadcastContentId(surveyId));
          }
        }}
      />
    </Field>
  );
};

export default connect(
  SurveyContent,
  surveys$.pipe(map(({ list }) => toSurveyOptions(list)))
);
