import SurveyCompletenessConditions from './surveyCompletenessConditions/SurveyCompletenessConditions';
import SurveyNotUpdatedSinceCondition from 'broadcasts/broadcastBuilder/broadcastContentForm/surveyNotUpdatedSinceCondition/SurveyNotUpdatedSinceCondition';

const SurveyFilterConditions = () => {
  return (
    <>
      <SurveyCompletenessConditions />
      <SurveyNotUpdatedSinceCondition />
    </>
  );
};

export default SurveyFilterConditions;
