import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { trackPermissionGroupPageEvents } from '../utils';
import { PermissionGroupPageEvents } from '../types';
import { PermissionGroup, UserWithRoles } from '@ardoq/api-types';
import { useState } from 'react';
import UsersMultiSelect from './CreatePermissionGroupModal/UsersMultiSelect';
import { permissionGroupsCommands } from '../commands';

export const openAddUserToGroupModal = (permissionGroup: PermissionGroup) => {
  trackPermissionGroupPageEvents(
    PermissionGroupPageEvents.CLICK_ADD_USER_TO_GROUP_BUTTON
  );
  modal(
    closeModal => {
      const [users, setUsers] = useState<UserWithRoles[]>([]);

      return (
        <ModalTemplate
          headerText={`Add users to ${permissionGroup.name}?`}
          primaryButtonText="Add"
          isPrimaryButtonDisabled={users.length === 0}
          onPrimaryButtonClick={() => {
            permissionGroupsCommands.appendUsersToGroup(permissionGroup, users);
            closeModal(true);
          }}
          secondaryButtonText="Cancel"
          onSecondaryButtonClick={() => {
            trackPermissionGroupPageEvents(
              PermissionGroupPageEvents.CANCEL_ADD_USER_TO_GROUP
            );
            closeModal(true);
          }}
          modalSize={ModalSize.S}
        >
          <ModalLayout>
            <UsersMultiSelect
              value={users}
              onValueChange={selectedUsers => {
                trackPermissionGroupPageEvents(
                  PermissionGroupPageEvents.SELECTED_USERS_DURING_ADDING_USERS_TO_GROUP,
                  {
                    selectionCount: users.length,
                  }
                );
                setUsers(selectedUsers);
              }}
              disabledUsersWithIds={permissionGroup.users}
              label="Select users to add"
              placeholder="Type and select users"
            />
          </ModalLayout>
        </ModalTemplate>
      );
    },
    {
      shouldCloseOnBackdropClick: false,
      shouldCloseOnEscapeKey: false,
    }
  );
};
