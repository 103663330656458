import { APIResourcePermissionAttributes, ArdoqId } from '@ardoq/api-types';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { ZoneCommands } from '../types';
import { ZonesById } from '@ardoq/subdivisions';
import {
  getGroupPermissionsTableColumns,
  getGroupPermissionsTableDataSource,
} from './groupPermissionsTableutils';
import { getActorPermissionRowStyle } from '@ardoq/manage-resource-permissions';
import { FlexBox } from '@ardoq/layout';
import { EmptyState, EmptyStateNoResultIllustration } from '@ardoq/status-ui';

export const GroupPermissionTable = ({
  zonesById,
  permissionsByZoneId,
  commands,
}: {
  zonesById: ZonesById;
  permissionsByZoneId: Record<ArdoqId, APIResourcePermissionAttributes>;
  commands: ZoneCommands;
}) => {
  const dataSource = getGroupPermissionsTableDataSource(
    zonesById,
    permissionsByZoneId
  );

  return (
    <>
      {dataSource.length > 0 ? (
        <DatasourceTable
          isFixedLayout
          rowStyle={getActorPermissionRowStyle}
          components={newTableTheme}
          dataSource={dataSource}
          columns={getGroupPermissionsTableColumns(
            zonesById,
            permissionsByZoneId,
            commands
          )}
        />
      ) : (
        <FlexBox justify="center">
          <EmptyState
            title="No permission group selected yet"
            description="Select at least one group to configure permissions"
            media={<EmptyStateNoResultIllustration />}
          ></EmptyState>
        </FlexBox>
      )}
    </>
  );
};
