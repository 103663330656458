import {
  routine,
  dispatchAction,
  collectRoutines,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { switchMap, tap } from 'rxjs';
import {
  fetchResponseLogData,
  notifyFailedToFetchResponseLogData,
  notifyFetchedResponseLogData,
} from './actions';
import { api, handleError, surveyApi } from '@ardoq/api';
import { getArdoqErrorMessage } from '@ardoq/common-helpers';

const handleGetResponseLogData = routine(
  ofType(fetchResponseLogData),
  extractPayload(),
  switchMap(({ surveyId, updateType }) =>
    surveyApi.fetchResponseLogData(surveyId, updateType)
  ),
  handleError(error => {
    dispatchAction(
      notifyFailedToFetchResponseLogData({
        message: getArdoqErrorMessage(error),
      })
    );
    api.logErrorIfNeeded(error);
  }),
  tap(result => {
    dispatchAction(
      notifyFetchedResponseLogData({
        responseLog: result.results,
        surveyId: result.surveyId,
      })
    );
  })
);

export default collectRoutines(handleGetResponseLogData);
