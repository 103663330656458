import { ConfigurationState } from 'integrations/ITPedia/streams/types';

export function isDataSelectionValid(config: ConfigurationState) {
  return Boolean(
    config.accountId &&
      config.workspaces.products.id &&
      !!Object.values(config.componentTypes)?.length &&
      Object.values(config.componentTypes).every(component => {
        if (
          // Migration: remove this check after all the configs are updated with 'Supplies' and 'Owns' reference type
          component.matching.manufacturer?.matchBy === 'reference' &&
          ['owns', 'supplies'].includes(
            component.matching.manufacturer.referenceTypeName || ''
          )
        )
          return false;
        return (
          component.isQueryValid &&
          component.matching.manufacturer &&
          component.matching.product
        );
      })
  );
}
