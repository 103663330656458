import React, { useState } from 'react';
import styled from 'styled-components';
import {
  ArithmeticAddTransformation,
  ArithmeticDivideTransformation,
  ArithmeticExpressionType,
  ArithmeticMultiplyTransformation,
  ArithmeticSubtractTransformation,
  Transformation,
  TransformationOperation,
} from '@ardoq/api-types/integrations';
import { Select, SelectOption } from '@ardoq/select';
import { NumberInput, TextInput } from '@ardoq/forms';
import { ButtonSize, ButtonType, IconButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';

const expressionTypeOptions: SelectOption<ArithmeticExpressionType>[] = [
  { label: 'Add', value: TransformationOperation.ADD },
  { label: 'Subtract', value: TransformationOperation.SUBTRACT },
  { label: 'Multiply', value: TransformationOperation.MULTIPLY },
  { label: 'Divide', value: TransformationOperation.DIVIDE },
];

type ExpressionStateConfig = {
  constant: number;
  constantPosition: 'left' | 'right';
};

type State = Record<ArithmeticExpressionType, ExpressionStateConfig>;

const defaultState: State = {
  multiply: {
    constant: 1,
    constantPosition: 'right',
  },
  divide: {
    constant: 1,
    constantPosition: 'right',
  },
  add: {
    constant: 1,
    constantPosition: 'left',
  },
  subtract: {
    constant: 1,
    constantPosition: 'right',
  },
};

const StyledSelect = styled(Select)`
  width: 130px;
  order: 1;
`;

const StyledTextInput = styled(TextInput)<{
  expressionType: ArithmeticExpressionType;
  constantPosition: ExpressionStateConfig['constantPosition'];
}>`
  width: 130px;
  order: ${({ expressionType, constantPosition }) => {
    // Subtractions are expressed as 'Subtract x from y', so positioning the controls the opposite way
    if (expressionType === 'subtract') {
      return constantPosition === 'left' ? 2 : 4;
    }
    return constantPosition === 'left' ? 4 : 2;
  }};
`;

const ExpressionSeparator = styled.div`
  order: 3;
`;

const StyledNumberInput = styled(NumberInput)<{
  expressionType: ArithmeticExpressionType;
  constantPosition: ExpressionStateConfig['constantPosition'];
}>`
  max-width: 130px;
  order: ${({ expressionType, constantPosition }) => {
    // Subtractions are expressed as 'Subtract x from y', so positioning the controls the opposite way
    if (expressionType === 'subtract') {
      return constantPosition === 'right' ? 2 : 4;
    }
    return constantPosition === 'right' ? 4 : 2;
  }};
`;

const StyledIconButton = styled(IconButton)`
  order: 5;
`;

type ArithmeticExpressionTransformationProps = {
  transformation:
    | ArithmeticAddTransformation
    | ArithmeticMultiplyTransformation
    | ArithmeticDivideTransformation
    | ArithmeticSubtractTransformation;
  setTransformation: (newTransformation: Transformation) => void;
  setLabel: React.Dispatch<React.SetStateAction<string | undefined>>;
  isNonCommutative?: boolean;
};

const ArithmeticExpressionTransformationSection = ({
  transformation,
  setTransformation,
  setLabel,
  isNonCommutative,
}: ArithmeticExpressionTransformationProps) => {
  const [state, setState] = useState<State>({
    ...defaultState,
    [transformation.operation]: {
      ...defaultState[transformation.operation],
      ...transformation.config,
    },
  });

  const onChangeExpressionType = (value: ArithmeticExpressionType) => {
    setLabel(`Arithmetic expression: ${value}`);
    setTransformation({
      ...transformation,
      operation: value,
      config: {
        ...transformation.config,
        ...state[value],
      },
    });
  };

  const onNumberConstantChange = (value: number) => {
    const updatedStateConfig = {
      ...state[transformation.operation],
      constant: value || 0, // Prevent `NaN`
    };

    setState({
      ...state,
      [transformation.operation]: {
        ...updatedStateConfig,
      },
    });

    setTransformation({
      ...transformation,
      config: {
        ...transformation.config,
        ...updatedStateConfig,
      },
    });
  };

  const onSwapOperands = () => {
    setState({
      ...state,
      [transformation.operation]: {
        ...state[transformation.operation],
        constantPosition:
          state[transformation.operation].constantPosition === 'left'
            ? 'right'
            : 'left',
      },
    });
  };

  return (
    <FlexBox gap="medium" align="center" marginBottom="medium">
      <StyledSelect
        options={expressionTypeOptions}
        onValueChange={value =>
          onChangeExpressionType(value as ArithmeticExpressionType)
        }
        value={transformation.operation}
      />
      <StyledTextInput
        isReadOnly
        value="Cell value"
        expressionType={transformation.operation}
        constantPosition={state[transformation.operation].constantPosition}
      />
      <ExpressionSeparator>
        {transformation.operation === 'multiply' && 'by'}
        {transformation.operation === 'divide' && 'by'}
        {transformation.operation === 'add' && 'to'}
        {transformation.operation === 'subtract' && 'from'}
      </ExpressionSeparator>
      <StyledNumberInput
        value={state[transformation.operation].constant}
        onValueChange={onNumberConstantChange}
        expressionType={transformation.operation}
        constantPosition={state[transformation.operation].constantPosition}
      />
      {isNonCommutative && (
        <StyledIconButton
          buttonSize={ButtonSize.SMALL}
          buttonType={ButtonType.SECONDARY}
          iconName={IconName.SWAP_HORIZONTAL}
          onClick={onSwapOperands}
          data-tooltip-text="Swap position of operands"
        />
      )}
    </FlexBox>
  );
};

export default ArithmeticExpressionTransformationSection;
