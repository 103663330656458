import { Header4, Paragraph } from '@ardoq/typography';
import { useState } from 'react';
import styled from 'styled-components';
import { UploadInfo } from '../../streams/upload/types';
import { uploadedFile$ } from '../../streams/upload/upload$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { uploadFileRequest } from '../../streams/upload/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { SecondaryButton } from '@ardoq/button';
import { Select } from '@ardoq/select';
import bestPracticesOptions from './bestPracticesOptions.json';
import { ExportIcon } from '@ardoq/icons';
import { byLocaleField } from 'integrations/common/utils/common';
import { FileDropzone } from 'integrations/excel-v3/components/FileDropzone';
import { Island } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';
import { Features, hasFeature } from '@ardoq/features';
import { excelDictionary } from 'integrations/common/dictionary';

type UploadParams = {
  uploadInfo: UploadInfo;
};

function UploadComponent({ uploadInfo }: UploadParams) {
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const hasQuickStartFeature = hasFeature(Features.QUICK_START);
  const onUpload = (data: File) => {
    dispatchAction(uploadFileRequest({ file: data }));
  };
  const onDownloadClick = () => {
    if (!selectedTemplate) return;
    const fileName = decodeURI(selectedTemplate.split('/').pop() || '');

    window.open(selectedTemplate, '_blank');

    if (fileName) {
      dispatchAction(
        trackIntegrationEvent({
          integrationId: 'excel-v3',
          name: 'DOWNLOADED_TEMPLATE',
          metadata: { filename: fileName },
        })
      );
    }
  };

  return (
    <FlexBox padding="large" width="full" height="full" gap="medium">
      <FileDropzoneContainer
        backgroundColor="bgDefault"
        borderRadius="r8"
        flex={924}
      >
        <FileDropzone uploadInfo={uploadInfo} onUpload={onUpload} />
      </FileDropzoneContainer>
      {!hasQuickStartFeature && (
        <FlexBox flex={360}>
          <Island minWidth="250px">
            <Header4>Best Practice {excelDictionary.name} Templates</Header4>
            <Paragraph>
              Import your data easily with templates that fit your project in
              our use case guides.
            </Paragraph>
            <Select
              value={selectedTemplate}
              onValueChange={value => setSelectedTemplate(value)}
              placeholder="Select a template"
              options={[
                ...bestPracticesOptions.others.sort(byLocaleField('label')),
                ...bestPracticesOptions.locationsAndPeople,
              ]}
            ></Select>
            <div>
              <SecondaryButton
                onClick={onDownloadClick}
                isDisabled={!selectedTemplate}
              >
                <ExportIcon />
                Download
              </SecondaryButton>
            </div>
          </Island>
        </FlexBox>
      )}
    </FlexBox>
  );
}

export const Upload = connect(UploadComponent, uploadedFile$);

const FileDropzoneContainer = styled(FlexBox)`
  flex-direction: column;
`;
