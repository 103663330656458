import { MetaModelComponentType } from '@ardoq/api-types';
import { ComponentTypesState } from './types';
import { dateRangeOperations } from '@ardoq/date-range';

const setLoadingState = (currentState: ComponentTypesState) => ({
  ...currentState,
  isLoading: true,
});

const resetLoadingState = (currentState: ComponentTypesState) => ({
  ...currentState,
  isLoading: false,
});

const setComponentTypesState = (
  currentState: ComponentTypesState,
  componentTypes: MetaModelComponentType[]
) => ({
  ...currentState,
  componentTypes: componentTypes.map(componentType => ({
    ...componentType,
    fieldNames: dateRangeOperations.normalizeDateRangeFieldNames(
      componentType.fieldNames
    ),
  })),
});

const setComponentTypesAfterLoading = (
  currentState: ComponentTypesState,
  componentTypes: MetaModelComponentType[]
) => {
  const updatedState = resetLoadingState(currentState);
  return setComponentTypesState(updatedState, componentTypes);
};

const hasComponentTypeField = (componentType: MetaModelComponentType) =>
  componentType.fieldNames.length > 0;

export const componentTypesOperations = {
  setLoadingState,
  resetLoadingState,
  setComponentTypesState,
  setComponentTypesAfterLoading,
  hasComponentTypeField,
};
