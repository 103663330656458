import { AdvancedSearchField, StoredQueryModel } from '@ardoq/api-types';

export const SEARCH_RESULTS_PER_PAGE = 100;

export enum SubSearchBackend {
  CALCULATED_FIELD_SEARCH = 'calculated-field-search',
  DYNAMIC_FILTER_SEARCH = 'dynamic-filter-search',
}

export enum NonSearchBackendSearchPane {
  SEARCH_OVERVIEW = 'reporting-overview',
  DYNAMIC_FILTER_OVERVIEW = 'graph-filter-overview',
}

export interface AdvancedSearchFieldLiteral {
  [key: string]: AdvancedSearchField;
}

interface StoredQueryModelOptions {
  hasDeleteOption: boolean;
  hasCopyOption: boolean;
  typeLabel: string;
  warning?: string;
}

export type SearchOverviewStoredQueryModel = StoredQueryModel &
  StoredQueryModelOptions;

export enum ReturnLocation {
  FIELD_EDITOR = 'FIELD_EDITOR',
  WIDGET_RESULT = 'WIDGET_RESULTS',
  GRID_EDITOR = 'GRID_EDITOR',
}

export interface SearchOverviewProps {
  storedQueries: SearchOverviewStoredQueryModel[];
  overviewType: NonSearchBackendSearchPane;
  searchOverviewPage: number;
  renderEmptyTableTitle?: string;
  canCreateGraphFilter: boolean;
}

export enum AdvancedSearchRuleErrorType {
  VALUE_MISSING,
}

export interface AdvancedSearchRuleError {
  id: string;
  field: string;
  type: string;
  errorType: AdvancedSearchRuleErrorType;
}
