import { actionCreator } from '@ardoq/rxbeach';
import { ReturnLocation } from './types';
import { StoredQueryModel, QueryModel, SearchQuery } from '@ardoq/api-types';

type PayloadQueryId = {
  readonly queryId: string;
};

type PayloadSaveSearchQuery = {
  readonly model: QueryModel;
  readonly queryId: string | null;
  readonly selectAfterCreation?: boolean;
};

type PayloadName = {
  readonly name: string;
};

export type PayloadStoredQuery = {
  readonly storedQuery: StoredQueryModel;
};

type PayloadStoredQueryField = {
  readonly storedQuery: StoredQueryModel;
  readonly fieldId?: string;
};

type PayloadQuery = {
  readonly query: SearchQuery;
};

type PayloadDeleteQuery = {
  readonly queryId: string;
  readonly parameterQueryId?: string | null;
};

type PayloadQueryModel = {
  readonly model: QueryModel;
};

export type PayloadReturnLocation = {
  readonly returnLocation: ReturnLocation | null;
};

type PayloadCalculatedFieldValue = {
  readonly ids: string[];
  readonly calculatedFieldSelectedOption: string;
};

export const exportAdvancedSearchURL = actionCreator<PayloadQueryId>(
  '[search] EXPORT_ADVANCED_SEARCH_URL'
);

export const exportGremlinSearchURL = actionCreator<PayloadQueryId>(
  '[search] EXPORT_GREMLIN_SEARCH_URL'
);

export const saveSearchQuery = actionCreator<PayloadSaveSearchQuery>(
  '[search] SAVE_SEARCH_QUERY'
);

export const saveSearchQueryError = actionCreator(
  '[search] SAVE_SEARCH_QUERY_ERROR'
);

export const updateQueryName = actionCreator<PayloadName>(
  '[search] UPDATE_QUERY_NAME'
);

export const resetAllSearchState = actionCreator('[search] RESET_SEARCH_STATE');

export const editSearchQuery = actionCreator('[search] EDIT_SEARCH_QUERY');

export const createSearchQuery = actionCreator('[search] CREATE_SEARCH_QUERY');

export const loadStoredQuery = actionCreator<PayloadStoredQuery>(
  '[search] LOAD_STORED_QUERY'
);

export const loadCalculatedFieldStoredQuery =
  actionCreator<PayloadStoredQueryField>(
    '[search] LOAD_CALCULATED_FIELD_STORED_QUERY'
  );

export const saveSearchQuerySuccess = actionCreator<PayloadStoredQuery>(
  '[search] SAVE_SEARCH_QUERY_SUCCESS'
);

export const createSearchQuerySuccess = actionCreator<PayloadStoredQuery>(
  '[search] CREATE_SEARCH_QUERY_SUCCESS'
);

export const updateSearchQuery = actionCreator<PayloadQuery>(
  '[search] UPDATE_SEARCH_QUERY'
);

export const deleteQuery = actionCreator<PayloadDeleteQuery>(
  '[search] DELETE_QUERY'
);

export const deleteQuerySuccess = actionCreator<PayloadDeleteQuery>(
  '[search] DELETE_QUERY_SUCCESS'
);

export const copyQuery = actionCreator<PayloadQueryModel>(
  '[search] COPY_QUERY'
);

export const triggerSearchLoading = actionCreator(
  '[search] TRIGGER_SEARCH_LOADING'
);

export const triggerSearchLoadingDone = actionCreator(
  '[search] TRIGGER_SEARCH_LOADING_DONE'
);

export const updateReturnLocation = actionCreator<PayloadReturnLocation>(
  '[search] UPDATE_RETURN_LOCATION'
);

export const updateCalculatedFieldValue =
  actionCreator<PayloadCalculatedFieldValue>(
    '[search] UPDATE_CALCULATED_FIELD_VALUE'
  );

interface PayloadUpdateQueryParams {
  queryParams: Record<string, string> | Record<string, string[]>;
}
export const updateQueryParams = actionCreator<PayloadUpdateQueryParams>(
  '[search] UPDATE_QUERY_PARAMS'
);
