import { IconName } from '@ardoq/icons';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { showViewSidePanel } from 'views/mainApp/viewSidePanel/actions';
import { ViewSidePanelContentIds } from 'views/mainApp/viewSidePanel/types';
import {
  ViewOptionsSectionBody,
  ViewOptionsSectionContainer,
  ViewOptionsSectionHeader,
  ViewOptionsSectionTitle,
  ViewOptionsSectionTitleContainer,
} from './atoms';
import { perspectivesEditor$ } from 'perspectiveSidebar/perspectiveEditor/perspectivesEditor$';
import { map } from 'rxjs/operators';
import type { ExtractStreamShape } from 'tabview/types';
import { calculateRulesCountPerTab } from 'perspectiveSidebar/perspectiveEditor/perspectivesEditor$/calculateRulesCountPerTab';
import ViewOptionsSectionRow from './ViewOptionsSectionRow';
import { usePerspectiveEditorPortalSubscription } from 'perspectiveSidebar/perspectiveEditor/perspectivesEditor$/usePerspectiveEditorPortalSubscription';
import { trackViewOptionClick } from '../tracking';

type ViewOptionsPerspectivesProperties = ExtractStreamShape<
  typeof viewOptionsPerspectives$
>;
const ViewOptionsPerspectives = ({
  groupingRulesCount,
  formattingRulesCount,
  labelRulesCount,
}: ViewOptionsPerspectivesProperties) => {
  usePerspectiveEditorPortalSubscription();
  return (
    <ViewOptionsSectionContainer>
      <ViewOptionsSectionHeader>
        <ViewOptionsSectionTitleContainer>
          <ViewOptionsSectionTitle>View options</ViewOptionsSectionTitle>
        </ViewOptionsSectionTitleContainer>
      </ViewOptionsSectionHeader>
      <ViewOptionsSectionBody>
        <ViewOptionsSectionRow
          rowLabel="Groups"
          iconName={IconName.APPLICATION_GROUP}
          tagContent={groupingRulesCount}
          onClick={() => {
            trackViewOptionClick('group');
            dispatchAction(
              showViewSidePanel(ViewSidePanelContentIds.PERSPECTIVES_GROUPS)
            );
          }}
        />
        <ViewOptionsSectionRow
          rowLabel="Conditional Format"
          iconName={IconName.FORMAT_LIST_BULLETED}
          tagContent={formattingRulesCount}
          onClick={() => {
            trackViewOptionClick('conditional formatting');
            dispatchAction(
              showViewSidePanel(
                ViewSidePanelContentIds.PERSPECTIVES_CONDITIONAL_FORMAT
              )
            );
          }}
        />
        <ViewOptionsSectionRow
          rowLabel="Labels"
          iconName={IconName.LABEL_OUTLINED}
          tagContent={labelRulesCount}
          onClick={() => {
            trackViewOptionClick('label');
            dispatchAction(
              showViewSidePanel(ViewSidePanelContentIds.PERSPECTIVES_LABELS)
            );
          }}
        />
      </ViewOptionsSectionBody>
    </ViewOptionsSectionContainer>
  );
};

const viewOptionsPerspectives$ = perspectivesEditor$.pipe(
  map(perspectiveEditorState => {
    const {
      GROUPS: groupingRulesCount,
      FORMATTING: formattingRulesCount,
      LABELS: labelRulesCount,
    } = calculateRulesCountPerTab(perspectiveEditorState);
    return { groupingRulesCount, formattingRulesCount, labelRulesCount };
  })
);

export default connect(ViewOptionsPerspectives, viewOptionsPerspectives$);
