import { persistentReducedStream } from '@ardoq/rxbeach';
import { defaultState, reducers } from './reducers';

const predefinedAudienceDialog$ = persistentReducedStream(
  'predefinedAudienceDialog$',
  defaultState,
  reducers
);

export default predefinedAudienceDialog$;
