import { map, Observable, combineLatest } from 'rxjs';
import { componentInterface } from '../modelInterface/components/componentInterface';
import { IconName } from '@ardoq/icons';
import { ContextToolBarAction, CurrentToolbarContext } from './types';
import { contextToolBarCommands } from './contextToolBarCommands';
import { componentSelection$ } from '../componentSelection/componentSelection$';
import { uniq } from 'lodash';
import { ExcludeFalsy, pluralizeWithCount } from '@ardoq/common-helpers';
import { loadedState$ } from '../loadedState/loadedState$';
import { searchTraversalInterface } from '../traversals/selectTraversalForContextComponentsModal/searchTraversalInterface';

const getSelectionStatus = (selection: string[]) => {
  if (!selection.length) {
    return {
      label: 'No selection',
      isMultipleTypesSelected: false,
    };
  }
  if (selection.length === 1) {
    return {
      label: componentInterface.getDisplayName(selection[0])!,
      isMultipleTypesSelected: false,
    };
  }
  const types = uniq(selection.map(componentInterface.getTypeName)).filter(
    ExcludeFalsy
  );
  if (types.length === 1) {
    return {
      label: pluralizeWithCount(types[0], selection.length),
      isMultipleTypesSelected: false,
    };
  }
  return {
    label: `${selection.length} components of ${types.length} types`,
    isMultipleTypesSelected: true,
  };
};

type ContextToolBarStream = {
  currentContext: CurrentToolbarContext | null;
  actions: ContextToolBarAction[];
  isMultipleTypesSelected: boolean;
  hasNoSelection: boolean;
  showContextSummary: boolean;
};

export const contextToolBar$: Observable<ContextToolBarStream> = combineLatest({
  componentSelection: componentSelection$,
  loadedState: loadedState$,
}).pipe(
  map(
    ({
      componentSelection: { graphSelection },
      loadedState: [mainLoadedState],
    }) => {
      let currentSelection: string[];
      let showContextSummary = false;
      if (!mainLoadedState || graphSelection.length) {
        currentSelection = graphSelection;
        showContextSummary = true;
      } else {
        currentSelection =
          mainLoadedState.componentIdsAndReferences.startSetResult;
      }

      if (!currentSelection.length) {
        return {
          isMultipleTypesSelected: false,
          currentContext: null,
          actions: [],
          hasNoSelection: true,
          showContextSummary: false,
        };
      }

      const { isMultipleTypesSelected, label } =
        getSelectionStatus(currentSelection);

      // TODO CL show something else if multiple types selected
      const representationData =
        searchTraversalInterface.getEnhancedRepresentationData(
          currentSelection[0]
        );

      const currentContext = {
        label,
        selectedIds: currentSelection,
        representationData,
      };

      return {
        isMultipleTypesSelected,
        currentContext,
        hasNoSelection: false,
        showContextSummary,
        actions: [
          {
            icon: IconName.VISIBILITY,
            label: 'Viewpoints',
            disabled: isMultipleTypesSelected,
            onClick: contextToolBarCommands.switchViewpoint,
          },
          {
            icon: IconName.REFERENCE,
            label: 'References',
            disabled: isMultipleTypesSelected,
            onClick: () => {},
          },
          {
            icon: IconName.ADD,
            label: 'Add connection',
            disabled: isMultipleTypesSelected,
            onClick: () => {},
          },
          {
            icon: IconName.SETTINGS,
            label: 'Advanced',
            disabled: isMultipleTypesSelected,
            onClick: () => {
              contextToolBarCommands.openViewpointBuilder(mainLoadedState);
            },
          },
        ],
      };
    }
  )
);
