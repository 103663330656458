import { DirectedTriple } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { InitialMetaInfoState } from './metaInfoTypes';
import { GraphId } from '../types';
import { CollapsibleGraphGroup, GraphEdge, GraphNode } from '@ardoq/graph';

export type SetNamePayload = { name: string };

export const setTraversalName = actionCreator<SetNamePayload>(
  '[viewpointMetaInfo] SET_VIEWPOINT_NAME'
);

export type SetDescriptionPayload = { description: string | null };

export const setTraversalDescription = actionCreator<SetDescriptionPayload>(
  '[viewpointMetaInfo] SET_DESCRIPTION'
);

export type SetInitialMetaInfoPayload = {
  initialState: InitialMetaInfoState;
};

export const setInitialMetaInfo = actionCreator<SetInitialMetaInfoPayload>(
  '[viewpointMetaInfo] SET_INITIAL_META_INFO'
);

export type SetGraphDataPayload = {
  paths: DirectedTriple[][];
  graphNodes: Map<GraphId, GraphNode>;
  graphEdges: Map<GraphId, GraphEdge>;
  graphGroups: Map<GraphId, CollapsibleGraphGroup>;
};

export const setGraphData = actionCreator<SetGraphDataPayload>(
  '[viewpointMetaInfo] SET_GRAPH_DATA'
);

export const setFolderId = actionCreator<string | null>(
  '[viewpointMetaInfo] SET_FOLDER_ID'
);

export const resetMetaInfoState = actionCreator(
  '[viewpointMetaInfo] RESET_META_INFO_STATE'
);
