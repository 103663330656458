import ManageUserRolesForm from './ManageUserRolesForm';
import { connect } from '@ardoq/rxbeach';
import manageUserRolesViewModel$, {
  ManageUserRolesStreamShape,
} from './streams/manageUserRolesViewModel$';

const ManageUserRoles = ({
  roles,
  users,
  rolesStatus,
  configurablePrivileges,
  privilegesStatus,
  selectedPrivileges,
  selectedRoleLabel,
  commands,
}: ManageUserRolesStreamShape) => {
  if (!['success', 'revalidating'].includes(rolesStatus)) return null;
  if (!['success', 'revalidating'].includes(privilegesStatus)) return null;
  if (!roles.length) return null;
  return (
    <ManageUserRolesForm
      roles={roles}
      users={users}
      configurablePrivileges={configurablePrivileges}
      selectedRoleLabel={selectedRoleLabel}
      selectedPrivileges={selectedPrivileges}
      setSelectedPrivileges={commands.setSelectedPrivileges}
      setSelectedRoleLabel={commands.setSelectedRoleLabel}
    />
  );
};

export default connect(ManageUserRoles, manageUserRolesViewModel$);
