import { SmallSecondaryButton } from '@ardoq/button';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { updateScopeDiff } from 'scope/actions';
import { trackClickCloseVisualDiff } from 'scope/tracking';
import { scopeDiff$ } from 'scope/scopeDiff$';
import { map } from 'rxjs/operators';
import { PopoverSection } from '@ardoq/popovers';

export const POPOVER_ID = 'related-components-diff-mode-popover';

const CloseDiffPopover = ({
  openRelatedComponents,
  isDiffModeActive,
}: {
  openRelatedComponents: () => void;
  isDiffModeActive: boolean;
}) =>
  isDiffModeActive ? (
    <div style={{ maxWidth: 250 }}>
      <p>Close the visual difference mode to show related components</p>
      <PopoverSection style={{ textAlign: 'right' }}>
        <SmallSecondaryButton
          onClick={() => {
            dispatchAction(updateScopeDiff({ scopeDiff: null }));
            trackClickCloseVisualDiff('related');
            openRelatedComponents();
          }}
        >
          Close visual diff
        </SmallSecondaryButton>
      </PopoverSection>
    </div>
  ) : null;

export default connect(
  CloseDiffPopover,
  scopeDiff$.pipe(
    map(currentScopeDiff => ({
      isDiffModeActive: Boolean(currentScopeDiff.scopeDiff),
    }))
  )
);
