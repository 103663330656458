import { handleError, organizationApi } from '@ardoq/api';
import { logError } from '@ardoq/logging';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { loadedCurrentUser$ } from 'streams/currentUser/currentUser$';
import {
  fetchCurrentOrganizationFailed,
  fetchCurrentOrganizationRequested,
  fetchCurrentOrganizationSucceeded,
  updateOrganizationFailed,
  updateOrganizationRequested,
  updateOrganizationSucceeded,
} from './actions';

const handleFetchCurrent = routine(
  ofType(fetchCurrentOrganizationRequested),
  withLatestFrom(loadedCurrentUser$),
  map(([, currentUser]) => currentUser.organization._id),
  switchMap(organizationApi.fetch),
  handleError(error => {
    dispatchAction(fetchCurrentOrganizationFailed(error));
    logError(error);
  }),
  tap(organization =>
    dispatchAction(fetchCurrentOrganizationSucceeded(organization))
  )
);

const handleUpdate = routine(
  ofType(updateOrganizationRequested),
  extractPayload(),
  switchMap(organizationApi.updateOrganization),
  handleError(error => {
    dispatchAction(updateOrganizationFailed(error));
    logError(error);
  }),
  tap(organization => dispatchAction(updateOrganizationSucceeded(organization)))
);

export default collectRoutines(handleFetchCurrent, handleUpdate);
