import { Params } from './paramRegistry';
import { RouteNode } from './routeNode';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type EmptyParams = {};

export type Location<
  RoutePath extends string = string,
  Params extends EmptyParams = Record<never, never>,
> = {
  route: RoutePath;
  params: Params;
};
export type EmptyLocation = Location<'', EmptyParams>;

export const emptyLocation: EmptyLocation = {
  route: '',
  params: {},
};

export type MatchedLocation<
  RoutePath extends string = string,
  Params extends EmptyParams = Record<never, never>,
> = Location<RoutePath, Params> & {
  activeRoutes: RouteNode[];
  unmatchedSuffix: string | null;
};

export type UnmatchedLocation = Location<'', EmptyParams> & {
  activeRoutes: [];
  unmatchedSuffix: string;
};

export type PartialLocation = MatchedLocation<string, Partial<Params>>;

/**
 * Like intersection with `&`, but makes typescript infer a single object instead
 * of an intersection. So you will get `{ a: string, b: number }` instead of
 * `{ a: string } & { b: number }`.
 */
type FlatIntersection<S, T> = S & T extends infer U
  ? { [K in keyof U]: U[K] }
  : never;

type ExtractParam<PathSegment extends string> =
  PathSegment extends `/:${infer Param}`
    ? Param extends keyof Params
      ? Pick<Params, Param>
      : EmptyParams
    : EmptyParams;

type NextDepth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
type MaxRecursionDepth = 10;

export type LocationIn<
  RouteTree extends RouteNode,
  ParentPath extends string = '',
  AccumulatedParams extends EmptyParams = EmptyParams,
  Depth extends number = 0,
> = Depth extends MaxRecursionDepth
  ? never
  : RouteTree extends {
        path: infer PathSegment extends string;
        children: infer Children extends readonly any[];
      }
    ?
        | Location<
            `${ParentPath}${PathSegment}`,
            FlatIntersection<ExtractParam<PathSegment>, AccumulatedParams>
          >
        | LocationIn<
            Children[number],
            `${ParentPath}${PathSegment}`,
            ExtractParam<PathSegment> & AccumulatedParams,
            NextDepth[Depth]
          >
    : RouteTree extends {
          children: infer Children extends readonly any[];
        }
      ? LocationIn<
          Children[number],
          ParentPath,
          AccumulatedParams,
          NextDepth[Depth]
        >
      : RouteTree extends {
            path: infer PathSegment extends string;
          }
        ? Location<
            `${ParentPath}${PathSegment}`,
            FlatIntersection<ExtractParam<PathSegment>, AccumulatedParams>
          >
        : never;

export type MatchedLocationIn<
  RouteTree extends RouteNode,
  ParentPath extends string = '',
  AccumulatedParams extends EmptyParams = EmptyParams,
  Depth extends number = 0,
> = Depth extends MaxRecursionDepth
  ? never
  : RouteTree extends {
        path: infer PathSegment extends string;
        children: infer Children extends readonly any[];
      }
    ?
        | MatchedLocation<
            `${ParentPath}${PathSegment}`,
            FlatIntersection<ExtractParam<PathSegment>, AccumulatedParams>
          >
        | MatchedLocationIn<
            Children[number],
            `${ParentPath}${PathSegment}`,
            ExtractParam<PathSegment> & AccumulatedParams,
            NextDepth[Depth]
          >
    : RouteTree extends {
          children: infer Children extends readonly any[];
        }
      ? MatchedLocationIn<
          Children[number],
          ParentPath,
          AccumulatedParams,
          NextDepth[Depth]
        >
      : RouteTree extends {
            path: infer PathSegment extends string;
          }
        ? MatchedLocation<
            `${ParentPath}${PathSegment}`,
            FlatIntersection<ExtractParam<PathSegment>, AccumulatedParams>
          >
        : never;

export const isUnmatchedLocation = (
  location: UnmatchedLocation | PartialLocation
): location is UnmatchedLocation => location.route === '';

export type RouteIn<
  RouteTree extends RouteNode,
  AccumulatedPath extends string = '',
  Depth extends number = 0,
> = Depth extends MaxRecursionDepth
  ? never
  : RouteTree extends {
        path: infer PathSegment extends string;
      }
    ?
        | `${AccumulatedPath}${PathSegment}`
        | (RouteTree extends {
            children: infer Children extends readonly any[];
          }
            ? RouteIn<
                Children[number],
                `${AccumulatedPath}${PathSegment}`,
                NextDepth[Depth]
              >
            : never)
    : RouteTree extends {
          children: infer Children extends readonly any[];
        }
      ? RouteIn<Children[number], AccumulatedPath, NextDepth[Depth]>
      : never;

export type ParamsAt<Route extends string> =
  Route extends `/${infer Segment}/${infer Rest}`
    ? FlatIntersection<ExtractParam<`/${Segment}`>, ParamsAt<`/${Rest}`>>
    : ExtractParam<Route>;
