import { Switch } from '@ardoq/forms';
import { connect, derivedStream } from '@ardoq/rxbeach';
import { trackEvent } from 'tracking/tracking';
import {
  PresentationOverviewCommands,
  presentationOverviewCommands,
} from './presentationOverviewCommands';
import presentationsOverview$ from './presentationsOverview$';
import { PresentationOverviewStreamShape } from './types';
import { map } from 'rxjs';

type ListModeSwitchProps = {
  isInListMode: boolean;
  commands: PresentationOverviewCommands;
};

const ListModeSwitch = ({ commands, isInListMode }: ListModeSwitchProps) => {
  return (
    <Switch
      name="list-mode"
      isChecked={isInListMode}
      onChange={isChecked => {
        trackEvent('Topbar: toggled list view in presentations', { isChecked });
        commands.setPresentationInListMode(isChecked);
      }}
      label="Show as list"
    />
  );
};

const toListModeSwitchStream = ([{ isInListMode }]: [
  PresentationOverviewStreamShape,
]) => {
  return {
    isInListMode,
    commands: presentationOverviewCommands,
  };
};

const listModeSwitch$ = derivedStream(
  'listModeSwitch$',
  presentationsOverview$
).pipe(map(toListModeSwitchStream));

export default connect(ListModeSwitch, listModeSwitch$);
