import { Row, ColumnsOptions } from '../../components/EntityBrowser/types';
import { AssetRow } from '../../components/AssetsBrowser/types';
import { findIsSelected } from '../../components/EntityBrowser/utils';
import { SortHeaderWithOffset } from '../../components/EntityBrowser/SortHeader';
import NameCell from '../../components/EntityBrowser/NameCell';
import { ColumnsProps } from '../../components/AssetsBrowser/columns';
import { HeaderCell, RowType } from '@ardoq/table';
import { Selected } from '../../aqTypes';
import { Space } from '@ardoq/style-helpers';
import { RadioInput } from '@ardoq/forms';
import { VisuallyHidden } from '@react-aria/visually-hidden';

export const getNameColumnWithRadioButtons = ({
  selected,
  setSelected,
  sortByField,
  sortOrder,
  setSortByField,
  onRowPreviewClick,
}: ColumnsProps & ColumnsOptions<AssetRow>) => {
  return {
    title: 'Name',
    dataIndex: 'name',
    headerRender: ({ title, dataIndex = 'name' }: HeaderCell) => (
      <div data-testid={'asset-browser-name-header'}>
        <SortHeaderWithOffset
          dataIndex={dataIndex}
          title={title}
          dataClickId="sort-assets-by-name"
          sortByField={sortByField}
          sortOrder={sortOrder}
          onSortChanged={setSortByField}
          $offsetMargin={40}
        />
      </div>
    ),
    valueRender: (name: string, row: Row<AssetRow>) => {
      if (row.rowType !== RowType.TRAVERSAL) {
        return null;
      }

      const inputId = `radio-input-${row._id}`;
      return (
        <Space $gap="s8" $align="center">
          <VisuallyHidden>
            <label htmlFor={inputId}>{name}</label>
          </VisuallyHidden>
          <RadioInput
            id={`radio-${row._id}`}
            name="selected-traversal-to-apply"
            value={row._id}
            isChecked={findIsSelected(row, selected) === Selected.SELECTED}
            onChange={() => setSelected([row._id])}
          />

          <NameCell
            rowData={row}
            name={name}
            rowId={row._id}
            rowType={row.rowType}
            matchGroups={row.meta.matchGroups}
            onClick={() => setSelected([row._id])}
            onPreviewClick={() => onRowPreviewClick?.(row)}
          />
        </Space>
      );
    },
  };
};
