import { ArdoqId } from '@ardoq/api-types';

export type ClientModelHitTest = (
  event: Pick<MouseEvent, 'clientX' | 'clientY'>
) => ArdoqId | null;
const registry = new Map<string, ClientModelHitTest>();
export const register = (viewInstanceId: string, hitTest: ClientModelHitTest) =>
  registry.set(viewInstanceId, hitTest);
export const unregister = (viewInstanceId: string) =>
  registry.delete(viewInstanceId);
export const get = (viewInstanceId: string) => registry.get(viewInstanceId);
