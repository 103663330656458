import { reducer } from '@ardoq/rxbeach';
import { setExternalDocument } from './actions';

export interface ExternalDocumentState {
  externalDocumentId: string | null;
  externalDocumentTitle: string | null;
  clientId: string | null;
}

export const defaultState: ExternalDocumentState = {
  externalDocumentId: null,
  externalDocumentTitle: null,
  clientId: null,
};

const handleSelectExternalDocument = (
  currentState: ExternalDocumentState,
  {
    externalDocumentId,
    externalDocumentTitle,
    clientId,
  }: {
    externalDocumentId: string;
    externalDocumentTitle: string;
    clientId: string;
  }
): ExternalDocumentState => ({
  ...currentState,
  externalDocumentId,
  externalDocumentTitle,
  clientId,
});

export const reducers = [
  reducer(setExternalDocument, handleSelectExternalDocument),
];
