import { CSSProperties } from 'react';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { getLinesFromComponentTypes } from './utils';
import { CURVE_SIZE, INIT_OFFSET_Y, STEP_X, STEP_Y } from './const';
import { APIComponentType } from '@ardoq/api-types';

type Point = { x: number; y: number };

type WorkspaceModelBrowserProps = {
  componentTypes: APIComponentType[];
  style?: CSSProperties;
};

const scaleAndTranslatePoint = ({ x, y }: Point) => ({
  x: x * STEP_X + INIT_OFFSET_Y,
  y: y * STEP_Y + INIT_OFFSET_Y,
});

const TreeSvgWrapper = styled.svg`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
`;

const BranchPath = ({
  startPoint,
  endPoint,
}: {
  startPoint: Point;
  endPoint: Point;
}) => (
  <path
    fill="none"
    stroke={colors.grey80}
    strokeWidth="1.5"
    d={`
      M ${startPoint.x} ${startPoint.y}
      V ${endPoint.y - CURVE_SIZE}
      Q
        ${startPoint.x} ${endPoint.y},
        ${startPoint.x + CURVE_SIZE} ${endPoint.y}
      H ${endPoint.x}`}
  />
);

const SVG_SIZE_OFFSET = 5; // to make SVG slightly bigger than total minimum

const getSvgSize = (lines: [Point, Point][]) =>
  lines.reduce(
    ({ width, height }, [_startPoint, endPoint]) => ({
      width: Math.max(width, endPoint.x),
      height: Math.max(height, endPoint.y),
    }),
    { width: 0, height: 0 }
  );

const TreeSvg = ({ componentTypes }: WorkspaceModelBrowserProps) => {
  const lines: [Point, Point][] = getLinesFromComponentTypes(
    componentTypes
  ).map(([startPoint, endPoint]) => [
    scaleAndTranslatePoint(startPoint),
    scaleAndTranslatePoint(endPoint),
  ]);
  const { width, height } = getSvgSize(lines);

  return (
    <TreeSvgWrapper
      width={width + SVG_SIZE_OFFSET}
      height={height + SVG_SIZE_OFFSET}
    >
      {lines.map(([startPoint, endPoint], i) => (
        <BranchPath key={i} startPoint={startPoint} endPoint={endPoint} />
      ))}
    </TreeSvgWrapper>
  );
};

export default TreeSvg;
