import styled from 'styled-components';
import SurveyFilterConditions from 'broadcasts/broadcastBuilder/broadcastContentForm/SurveyFilterConditions';
import AdvancedSearchCondition from 'broadcasts/broadcastBuilder/broadcastContentForm/advancedSearchCondition/AdvancedSearchCondition';
import AdvancedSearchConditionByReference from 'broadcasts/broadcastBuilder/broadcastContentForm/advancedSearchConditionByReference/AdvancedSearchConditionByReference';
import { Label } from '@ardoq/forms';
import { s32, s8 } from '@ardoq/design-tokens';

type FilterConditionsProps = {
  readonly isSurveyContentType: boolean;
};

const Wrapper = styled.div`
  margin-top: ${s8};
  padding-left: ${s32};
  display: flex;
  flex-direction: column;
  gap: ${s8};
`;

const FilterConditions = ({ isSurveyContentType }: FilterConditionsProps) => {
  return (
    <Wrapper>
      {isSurveyContentType && (
        <>
          <SurveyFilterConditions />
          <Label>Filter based on:</Label>
        </>
      )}
      <AdvancedSearchCondition />
      <AdvancedSearchConditionByReference />
    </Wrapper>
  );
};

export default FilterConditions;
