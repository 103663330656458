import QueryActions from 'search/QueryEditor/QueryActions';
import {
  QueryEditorNamespace,
  QueryEditorStateShape,
} from 'search/QueryEditor/queryEditor$';
import { updateSearchQuery } from 'search/actions';
import { CodeEditor, EditorLanguage } from '@ardoq/code-editor';
import { SearchType } from '@ardoq/api-types';
import { GremlinSearchStateShape } from 'search/Gremlin/types';
import { usePaginationGremlinSearch } from 'search/Gremlin/usePaginationGremlinSearch';
import GremlinSearchError from 'search/Gremlin/GremlinResults/GremlinSearchError';
import GremlinSearchWarning from 'search/Gremlin/GremlinResults/GremlinSearchWarning';
import GremlinResults from 'search/Gremlin/GremlinResults/GremlinResults';
import { dispatchAction } from '@ardoq/rxbeach';
import { Box } from '@ardoq/layout';

const updateDynamicSearchQuery = (query: string) =>
  dispatchAction(
    updateSearchQuery({
      query,
    }),
    QueryEditorNamespace.DYANMIC_FILTER_QUERY
  );

type ViewProps = {
  dynamicQueryEditor: QueryEditorStateShape;
  dynamicGremlinResults: GremlinSearchStateShape;
};

const DynamicQueryEditor = ({
  dynamicQueryEditor: { isEditing, isSearching, model, queryParams },
  dynamicGremlinResults: {
    results,
    showRawResults,
    syntaxError,
    searchError,
    searchWarning,
    totalResults,
  },
}: ViewProps) => {
  const { resultsId, loadPaginatedResults, doPaginatedSearch } =
    usePaginationGremlinSearch({
      model,
      queryParams,
      searchType: SearchType.DYNAMIC_FILTER_QUERY,
    });
  return (
    <>
      {isEditing && (
        <CodeEditor
          language={EditorLanguage.GROOVY}
          value={model.query as string}
          onChange={updateDynamicSearchQuery}
          onCtrlCmdEnter={doPaginatedSearch}
        />
      )}
      <QueryActions
        searchButtonText="Test filter"
        doSearch={doPaginatedSearch}
        showSpinner={isSearching}
      />
      <Box marginTop="xlarge">
        {searchError && <GremlinSearchError syntaxError={syntaxError} />}
        {searchWarning && (
          <GremlinSearchWarning searchWarning={searchWarning} />
        )}
        {results && (
          <GremlinResults
            result={results}
            showRaw={showRawResults}
            totalResults={totalResults}
            resultsId={resultsId}
            loadResults={loadPaginatedResults}
          />
        )}
      </Box>
    </>
  );
};

export default DynamicQueryEditor;
