import {
  type ExtractPayload,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import {
  addCreatedInvitation,
  removeDeletedInvitation,
  replaceUpdatedInvitation,
  setInvitationsState,
} from './actions';
import { InvitationsStreamShape } from './types';
import type { PersistedInvitation } from '@ardoq/api-types';
import { spreadPayloadOverState } from '@ardoq/common-helpers';

const defaultState: InvitationsStreamShape = {
  invitations: [],
  alert: null,
};

const handleAddCreatedInvitation = (
  state: InvitationsStreamShape,
  newInvitation: PersistedInvitation | PersistedInvitation[]
) => ({
  ...state,
  invitations: state.invitations.concat(newInvitation),
});

const handleReplaceUpdatedInvitation = (
  state: InvitationsStreamShape,
  updatedInvitation: PersistedInvitation
) => ({
  ...state,
  invitations: state.invitations.map(invitation =>
    invitation._id === updatedInvitation._id ? updatedInvitation : invitation
  ),
});

const handleRemoveDeletedInvitation = (
  state: InvitationsStreamShape,
  { invitationId }: ExtractPayload<typeof removeDeletedInvitation>
) => ({
  ...state,
  invitations: state.invitations.filter(
    stateInvitation => stateInvitation._id !== invitationId
  ),
});

const invitation$ = persistentReducedStream('invitations$', defaultState, [
  reducer(addCreatedInvitation, handleAddCreatedInvitation),
  reducer(replaceUpdatedInvitation, handleReplaceUpdatedInvitation),
  reducer(removeDeletedInvitation, handleRemoveDeletedInvitation),
  reducer(setInvitationsState, spreadPayloadOverState),
]);

export default invitation$;
