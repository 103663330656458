import { SignavioExporterDiff } from '@ardoq/api-types/integrations';
import { IconName } from '@ardoq/icons';
import { StatusType } from '@ardoq/status-ui';
import { ConfigurationState } from 'integrations/signavio-exporter/streams/types';

export function diffToSummary(diff: SignavioExporterDiff) {
  const totalEntries = Object.values(diff.entriesDiff).reduce(
    (acc, entry) => {
      return {
        create: acc.create + entry.create.amount,
        update: acc.update + entry.update.amount,
      };
    },
    {
      create: 0,
      update: 0,
    }
  );
  return [
    {
      _id: '1',
      title: 'Dictionary category',
      columns: [
        {
          count: diff.categoriesDiff.create.amount,
          iconName: IconName.ADD_CIRCLE,
          status: StatusType.SUCCESS,
          affectedValues: diff.categoriesDiff.create.elements,
        },
        {
          // We don't allow to modify categories
          count: 0,
          iconName: IconName.UPDATE,
          status: StatusType.INFO,
          affectedValues: [],
        },
      ],
    },
    {
      _id: '2',
      title: 'Dictionary entry',
      columns: [
        {
          count: totalEntries.create,
          iconName: IconName.ADD_CIRCLE,
          status: StatusType.SUCCESS,
          affectedValues: Object.values(diff.entriesDiff).flatMap(e =>
            e.create.elements.map(e => e.label)
          ),
        },
        {
          count: totalEntries.update,
          iconName: IconName.UPDATE,
          status: StatusType.INFO,
          affectedValues: Object.values(diff.entriesDiff).flatMap(e =>
            e.update.elements.map(e => e.label)
          ),
        },
      ],
    },
  ];
}

export function isReviewExportSuccessful(config: ConfigurationState) {
  return config.results.diff.status === 'SUCCESS';
}
