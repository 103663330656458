import { GRAPH_ITEM_LABEL_CACHE_SIZE } from '@ardoq/graph';
import { EDGE_LABEL_FONT, LABEL_TEXT_PARAMS, NODE_LABEL_FONT } from './consts';
import { Font, Size, TextRenderSupport } from '@ardoq/yfiles';
import { createFifoCache } from '@ardoq/common-helpers';
import { logWarn } from '@ardoq/logging';

const edgeLabelMeasurementCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    TextRenderSupport.measureText({
      text,
      font: EDGE_LABEL_FONT,
      ...LABEL_TEXT_PARAMS,
    })
);
const nodeLabelMeasurementCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    TextRenderSupport.measureText({
      text,
      font: NODE_LABEL_FONT,
      ...LABEL_TEXT_PARAMS,
    })
);

const measureLabelElement = (text: string, font: Font) => {
  switch (font) {
    case EDGE_LABEL_FONT:
      return edgeLabelMeasurementCache(text);

    case NODE_LABEL_FONT:
      return nodeLabelMeasurementCache(text);

    default:
      logWarn(
        new Error('Unexpected font in Viewpoint Editor label measurement'),
        null,
        {
          font,
        }
      );
      return Size.EMPTY;
  }
};

export default measureLabelElement;
