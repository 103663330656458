import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { AppRouterState } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { map } from 'rxjs/operators';
import { ViewpointsPane, ViewpointsRoute } from './types';
import viewpointsNavigation$ from './viewpointsNavigation$';
import {
  navigateToDefaultViewpoints,
  navigateToViewpointForm,
  navigateToViewpoints,
} from 'router/navigationActions';

const viewpointsRoute = new Route<AppRouterState, ViewpointsRoute>({
  doesLocationMatch: ({ path }) => new RegExp(`/viewpoints/?`).test(path),
  locationToRouterState: ({ path }) => {
    const parts = path.split('/');
    const afterViewpoints = parts[2];
    if (!afterViewpoints) {
      return {
        appModule: AppModules.VIEWPOINTS,
        viewpointsPane: ViewpointsPane.OVERVIEW,
        viewpointId: null,
      };
    }
    if (afterViewpoints === 'new') {
      return {
        appModule: AppModules.VIEWPOINTS,
        viewpointsPane: ViewpointsPane.FORM,
        viewpointId: null,
      };
    }
    if (afterViewpoints === 'default') {
      return {
        appModule: AppModules.VIEWPOINTS,
        viewpointsPane: ViewpointsPane.DEFAULTS,
        viewpointId: null,
      };
    }
    return {
      appModule: AppModules.VIEWPOINTS,
      viewpointsPane: ViewpointsPane.FORM,
      viewpointId: afterViewpoints,
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.VIEWPOINTS,
  routerStateToLocation: ({ viewpointsPane, viewpointId }) => {
    if (viewpointsPane === ViewpointsPane.FORM && viewpointId) {
      return {
        path: `/viewpoints/${viewpointId}`,
        title: 'Edit Viewpoint',
      };
    }
    if (viewpointsPane === ViewpointsPane.FORM) {
      return {
        path: `/viewpoints/new`,
        title: 'New Viewpoint',
      };
    }
    if (viewpointsPane === ViewpointsPane.DEFAULTS) {
      return {
        path: `/viewpoints/default`,
        title: 'Default Viewpoints',
      };
    }
    return { path: '/viewpoints/', title: 'Viewpoints' };
  },
  setApplicationStateFromRoute: ({ viewpointsPane, viewpointId }) => {
    if (viewpointsPane === ViewpointsPane.OVERVIEW) {
      dispatchAction(navigateToViewpoints());
    } else if (viewpointsPane === ViewpointsPane.FORM && viewpointId) {
      dispatchAction(navigateToViewpointForm(viewpointId));
    } else if (viewpointsPane === ViewpointsPane.FORM) {
      dispatchAction(navigateToViewpointForm(null));
    } else if (viewpointsPane === ViewpointsPane.DEFAULTS) {
      dispatchAction(navigateToDefaultViewpoints());
    }
  },
  getPartialRouterStateStream: () =>
    viewpointsNavigation$.pipe(
      map(({ viewpointsPane, viewpointId }) => ({
        viewpointsPane,
        viewpointId,
      }))
    ),
});

export default viewpointsRoute;
