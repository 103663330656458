export const STRINGS = {
  CANNOT_CREATE_VIEWPOINT_POPOVER_TEXT:
    'Your current role doesn’t have permission to create a new viewpoint.',
  CANNOT_EDIT_VIEWPOINT_POPOVER: {
    MESSAGE: `You cannot edit this viewpoint without having 'Writing' permissions on it, which can be granted by the viewpoint administrator(s). You can still load this viewpoint in`,
    READ_MORE: 'Read more about permissions.',
  },
};

export const CANNOT_CREATE_VIEWPOINT_POPOVER_ID =
  'CANNOT_CREATE_VIEWPOINT_POPOVER_ID';
