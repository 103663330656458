import {
  BroadcastContentType,
  PresentationReadPermissions,
  APIPresentationAssetAttributes,
} from '@ardoq/api-types';
import CurrentUser from 'models/currentUser';
import * as encodingUtils from '@ardoq/html';
import { colors } from '@ardoq/design-tokens';
import AddPresentationDialog, {
  AddPresentationDialogProps,
  PresentationModalOption,
} from './AddPresentationDialog';
import { modal } from '@ardoq/modal';
import { Icon, IconName } from '@ardoq/icons';
import {
  EditorConfig,
  colorSyntaxPlugin,
  customButtonsPlugin,
  placeholderPlugin,
} from '@ardoq/rich-text-editor';
import { POPOVER_ID_ATTR } from '@ardoq/popovers';
import { RichTextEditorExtension } from '@ardoq/rich-text-editor-2024';
import { capitalize } from 'lodash';

const PLACEHOLDERS_BY_CONTENT_TYPE = {
  survey: [
    '{audienceName}',
    '{componentCount}',
    '{componentType}',
    '{surveyName}',
    '{workspaceName}',
  ],
  message: [
    '{audienceName}',
    '{componentCount}',
    '{componentType}',
    '{workspaceName}',
  ],
  report: [
    '{audienceName}',
    '{reportName}',
    '{Column}',
    '{Aggregate}',
    '{Condition}',
    '{Value}',
  ],
};

const openInsertModalAsync = ({
  linkText,
  url,
  options,
}: Omit<AddPresentationDialogProps, 'onClose' | 'onInsert'>) =>
  modal<{
    newLinkText: string;
    url: string;
  }>(resolve => (
    <AddPresentationDialog
      onClose={() => resolve(false)}
      onInsert={args => resolve(args)}
      linkText={linkText}
      url={url}
      options={options}
    />
  ));

export const get2024EditorConfig = (
  contentType: BroadcastContentType,
  presentationOptions: PresentationModalOption[]
): RichTextEditorExtension[] => {
  const placeholders = PLACEHOLDERS_BY_CONTENT_TYPE[contentType];
  const convertPlacholderToLabel = (placeholder: string) => {
    return capitalize(
      placeholder
        .replaceAll('{', '')
        .replaceAll('}', '')
        .replace(/([A-Z])/g, ' $1')
    );
  };
  return [
    'Heading',
    'Bold',
    'Italic',
    'Strike',
    'Blockquote',
    'BulletList',
    'OrderedList',
    'Link',
    {
      name: 'Placeholders',
      items: placeholders.map(placeholder => ({
        value: placeholder,
        label: convertPlacholderToLabel(placeholder),
      })),
    },
    {
      name: 'Presentation',
      items: presentationOptions.map(option => ({
        url: option.url,
        label: option.label,
      })),
    },
  ];
};

export const getEditorConfig = (
  contentType: BroadcastContentType,
  presentationOptions: PresentationModalOption[]
): EditorConfig => {
  const placeholders = PLACEHOLDERS_BY_CONTENT_TYPE[contentType];
  return {
    toolbarItems: [
      'heading',
      'bold',
      'italic',
      'strike',
      'divider',
      'quote',
      'divider',
      'ul',
      'ol',
      'divider',
      'link',
      'divider',
    ],
    plugins: [
      colorSyntaxPlugin,
      [
        placeholderPlugin,
        {
          placeholders,
        },
      ],
      [
        customButtonsPlugin,
        [
          {
            tooltip: 'Add Presentation',
            className: 'tui-presentation',
            onClick: async (insertHtmlString: (arg0: string) => void) => {
              const result = await openInsertModalAsync({
                linkText: '',
                url: '',
                options: presentationOptions,
              });

              if (!result) return;
              const insertString = `<a href=${result.url}>${result.newLinkText}</a>`;
              insertHtmlString(insertString);
            },
          },
        ],
      ],
    ],
  };
};

export const POPOVER_ID = 'incorrect-permissions-popover';
export const getPresentationOptions = (ids: {
  [key: string]: APIPresentationAssetAttributes;
}) => {
  return Object.entries(ids).reduce<PresentationModalOption[]>(
    (presOptions, pr) => {
      const readAccess = pr[1].readAccess as PresentationReadPermissions;
      const warningContent = ['ws', 'me'].includes(readAccess) ? (
        <Icon
          iconName={IconName.WARNING}
          style={{ color: colors.yellow60 }}
          {...{ [POPOVER_ID_ATTR]: POPOVER_ID }}
        />
      ) : null;
      const option = {
        label: pr[1].name,
        value: pr[1]._id,
        readAccess,
        url: [
          `${window.location.protocol}//${window.location.host}/presentation`,
          encodingUtils.escapeHTML(CurrentUser.getOrganization().label),
          pr[1]._id,
        ].join('/'),
        leftContent: warningContent,
      };
      return presOptions.concat(option);
    },
    []
  );
};
