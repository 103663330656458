import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  applyEntityMerge,
  initEntityMerge,
  setEntityMergeData,
  setEntityMergeIsLoading,
} from 'components/EntityMerge/actions';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { graphics } from '@ardoq/renderers';
import EntityMergeDialog from './EntityMergeDialog';
import entityMergeTable$ from 'components/EntityMerge/entityMergeTable$';
import {
  handleComponentMergeResponse,
  handleReferenceMergeResponse,
  prepareMergeData,
  validateSelection,
} from 'components/EntityMerge/utils';
import { mergeEntities } from 'components/EntityMerge/mergeAPI';
import {
  confirmEntityMerge,
  launchInvalidMergeAlert,
} from 'components/EntityMerge/dialogs';
import { mergeRequestErrorHandler } from 'services/errorHandlers';
import {
  APIEntityType,
  MergeComponentApiResponse,
  MergeReferenceApiResponse,
} from '@ardoq/api-types';
import { forkJoin, of } from 'rxjs';
import { trackEvent, trackStreamEvent } from 'tracking/tracking';
import { popStackPageByName, pushStackPage } from '@ardoq/stack-page-manager';
import { NamedStackPage } from 'aqTypes';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';

type ShowDialogParams = {
  earlyExitTrackingData: { label: string; metadata: any };
};
const showDialog = ({ earlyExitTrackingData }: ShowDialogParams) =>
  pushStackPage(
    () => (
      <EntityMergeDialog
        closeHandler={() => {
          trackEvent(
            earlyExitTrackingData.label,
            earlyExitTrackingData.metadata
          );
          closeEntityMergeDialog();
        }}
      />
    ),
    { stackPageName: NamedStackPage.ENTITY_MERGE_FLOW }
  );

const closeEntityMergeDialog = () =>
  popStackPageByName(NamedStackPage.ENTITY_MERGE_FLOW);

const handleInitEntityMerge = routine(
  ofType(initEntityMerge),
  extractPayload(),
  map(({ entityIds, entityType }) => ({
    entityIds,
    entityType,
    ...validateSelection(entityIds, entityType),
  })),
  tap(({ isValidSelection, entityType, validationErrorReason, entityIds }) => {
    if (!isValidSelection) {
      trackEvent('Got invalid merge alert for entity merge', {
        entityType,
        numberOfSelectedEntitiesToMerge: entityIds.length,
      });
      launchInvalidMergeAlert(entityType, validationErrorReason!);
    }
  }),
  filter(({ isValidSelection }) => isValidSelection),
  trackStreamEvent(({ entityIds, entityType }) => ({
    eventName: 'Opened entity merge flow',
    metadata: {
      entityType,
      numberOfSelectedEntitiesToMerge: entityIds.length,
    },
  })),
  tap(payload => {
    showDialog({
      earlyExitTrackingData: {
        label: 'Closed entity merge flow without merging',
        metadata: {
          entityType: payload.entityType,
          numberOfSelectedEntitiesToMerge: payload.entityIds.length,
        },
      },
    });
    dispatchAction(
      setEntityMergeData({ ...payload, users: orgUsers$.state.byId, graphics })
    );
  })
);

const handleApplyEntityMerge = routine(
  ofType(applyEntityMerge),
  extractPayload(),
  switchMap(confirmEntityMerge),
  filter(isConfirmed => isConfirmed),
  tap(() => dispatchAction(setEntityMergeIsLoading(true))),
  withLatestFrom(entityMergeTable$),
  trackStreamEvent(([, { entities, entityType }]) => ({
    eventName: 'Applied entity merge',
    metadata: { entityType, numberOfSelectedEntitiesToMerge: entities.length },
  })),
  switchMap(([, { enhancedScopeData, dataSource, entities, entityType }]) =>
    forkJoin([
      mergeEntities({
        entityType,
        ...prepareMergeData(
          entities,
          dataSource,
          enhancedScopeData!,
          entityType
        ),
      }),
      of(entityType),
    ])
  ),
  tap(([response, entityType]) =>
    entityType === APIEntityType.COMPONENT
      ? handleComponentMergeResponse(response as MergeComponentApiResponse)
      : handleReferenceMergeResponse(response as MergeReferenceApiResponse)
  ),
  tap(() => dispatchAction(setEntityMergeIsLoading(false))),
  mergeRequestErrorHandler(setEntityMergeIsLoading),
  tap(closeEntityMergeDialog)
);

const entityMergeRoutines = collectRoutines(
  handleApplyEntityMerge,
  handleInitEntityMerge
);

export default entityMergeRoutines;
