import {
  APIReferenceAttributes,
  APIComponentAttributes,
  APIEntityType,
  ArdoqId,
  ChangeRequestAction,
} from '@ardoq/api-types';
import { EnhancedScopeData } from '@ardoq/data-model';
import { SwitchPosition } from '@ardoq/snowflakes';
import {
  RecordOfReferences,
  FieldsByReferenceId,
  ChangeApprovalRowData,
  ComponentValidation,
} from '../types';
import { getApprovalStatus } from '../utils';
import {
  ExpandableTableProvider,
  DatasourceTable,
  newTableTheme,
} from '@ardoq/table';
import { getReferenceColumns } from './referenceColumns';
import { connect, derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import changeApprovalData$ from '../changeApprovalData$';
import { Row, RowWithHover } from './atoms';
import { isReferenceAttributeRow } from './utils';

const getValueSetInApprovedChanges = (
  approvedReferenceChanges: RecordOfReferences,
  referenceId: ArdoqId,
  whatChanged: string,
  referenceValidationObject: FieldsByReferenceId
) => {
  if (referenceValidationObject[referenceId]?.[whatChanged]) return undefined;
  return approvedReferenceChanges[referenceId]?.[whatChanged] ?? null;
};

type ReferenceUpdateTableProps = {
  changedReferences?: APIReferenceAttributes[];
  masterComponent: APIComponentAttributes;
  masterData: EnhancedScopeData;
  branchData: EnhancedScopeData;
  approvedReferenceChanges: RecordOfReferences;
  changedFieldsByReferenceId: FieldsByReferenceId;
  changeAction: ChangeRequestAction;
  componentValidationObject: ComponentValidation;
  referenceValidationObject: FieldsByReferenceId;
  handleApprovedDataForSpecificField: (
    switchPosition: SwitchPosition,
    rowData: ChangeApprovalRowData
  ) => void;
};

const ReferenceUpdateTable = ({
  changedReferences,
  masterComponent,
  masterData,
  branchData,
  approvedReferenceChanges,
  changedFieldsByReferenceId,
  changeAction,
  componentValidationObject,
  referenceValidationObject,
  handleApprovedDataForSpecificField,
}: ReferenceUpdateTableProps) => {
  if (!changedReferences) return null;

  const data = changedReferences.map(reference => {
    const masterReference = masterData.referencesById[reference._id];
    const branchReference = branchData.referencesById[reference._id];
    const changedFields = changedFieldsByReferenceId?.[reference._id];

    const referenceWithMasterVersion = {
      ...branchReference,
      _version: masterReference._version,
    };

    const updateTableDataSource = Object.keys(changedFields).map(field => {
      const changedTo = reference[field] ?? null;
      const whatChanged = field;
      const valueSetInApprovedChanges = getValueSetInApprovedChanges(
        approvedReferenceChanges,
        reference._id,
        whatChanged,
        referenceValidationObject
      );

      return {
        componentId: masterComponent._id,
        changeApprovalEntityId: reference._id,
        whatChanged,
        changedFrom: masterReference[field] ?? null,
        changedTo,
        entityType: APIEntityType.REFERENCE,
        status: getApprovalStatus(valueSetInApprovedChanges, changedTo),
        isExpandableRow: true,
      };
    });

    return {
      _id: branchReference._id,
      reference: referenceWithMasterVersion,
      scopeData: branchData,
      children: [
        {
          dataSource: updateTableDataSource,
          reference: referenceWithMasterVersion,
          isExpandableRow: true,
        },
      ],
    };
  });
  return (
    <ExpandableTableProvider
      dataSource={data}
      idIndex="_id"
      render={({
        dataSource,
        expandedFoldersIds: expandedRowIds,
        expandFolder: expandRow,
      }) => (
        <DatasourceTable
          dataSource={dataSource}
          columns={getReferenceColumns(
            changeAction,
            expandedRowIds,
            approvedReferenceChanges,
            componentValidationObject,
            handleApprovedDataForSpecificField,
            expandRow
          )}
          components={newTableTheme}
          getRowComponent={row =>
            isReferenceAttributeRow(row) ? Row : RowWithHover
          }
        />
      )}
    />
  );
};

const referenceUpdateTable$ = derivedStream(
  'referenceUpdateTable$',
  changeApprovalData$
).pipe(
  map(
    ([
      {
        componentId,
        masterData,
        branchData,
        changeAction,
        approvedReferenceChanges,
        componentValidationObject,
        changedReferencesByComponentId,
        changedFieldsByReferenceId,
        referenceValidationObject,
      },
    ]) => {
      const masterComponent = masterData.componentsById[componentId];
      return {
        branchData,
        masterData,
        sourceOfTruthScopeData:
          changeAction === 'delete' ? masterData : branchData,
        changeAction,
        approvedReferenceChanges,
        componentValidationObject: componentValidationObject[componentId],
        changedReferences:
          changedReferencesByComponentId?.[masterComponent._id][changeAction],
        changedFieldsByReferenceId,
        masterComponent,
        referenceValidationObject,
      };
    }
  )
);

export default connect(ReferenceUpdateTable, referenceUpdateTable$);
