import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { AsyncMultiselect, SelectOption } from '@ardoq/select';
import { WorkspaceSectionProps } from '../types';
import { AuditLogTestIds } from '@ardoq/audit-log';
import { FilterSections, sectionIconNamesMap } from '../consts';

const WorkspaceSection = ({
  loadOptions,
  selected,
  onValueChange,
}: WorkspaceSectionProps) => (
  <SidebarMenuSection
    title={FilterSections.WORKSPACES}
    leftIcon={{ name: sectionIconNamesMap[FilterSections.WORKSPACES] }}
  >
    <AsyncMultiselect
      autoFocus
      label="Filter changes made in"
      placeholder="Search workspaces"
      loadOptions={loadOptions}
      value={selected}
      onChange={values => onValueChange(values as Array<SelectOption<string>>)}
      cacheOptions
      dataTestId={AuditLogTestIds.FILTER_WORKSPACE_SELECT}
    />
  </SidebarMenuSection>
);

export default WorkspaceSection;
