import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  createDashboardColorTheme,
  deleteDashboardColorTheme,
  getDashboardColorThemes,
  saveDashboardColorThemeSelection,
  setDashboardColorThemes,
  setIsDashboardColorThemesLoading,
  updateDashboardColorTheme,
} from './actions';
import { mergeMap, switchMap } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import dashboardColorThemes$, {
  DEFAULT_DASHBOARD_COLOR_THEME,
} from './dashboardColorThemes$';
import { showToast, ToastType } from '@ardoq/status-ui';
import { dashboardColorPaletteApi, handleError } from '@ardoq/api';
import { colorThemeErrorHandlers } from './errorDialogs';

const handleGetDashboardColorThemes = routine(
  ofType(getDashboardColorThemes),
  tap(() => dispatchAction(setIsDashboardColorThemesLoading(true))),
  switchMap(dashboardColorPaletteApi.fetchAll),
  handleError(() => dispatchAction(setIsDashboardColorThemesLoading(false))),
  tap(() => dispatchAction(setIsDashboardColorThemesLoading(false))),
  tap(dashboardColorThemes =>
    dispatchAction(setDashboardColorThemes(dashboardColorThemes))
  )
);

const handeCreateDashboardColorTheme = routine(
  ofType(createDashboardColorTheme),
  extractPayload(),
  mergeMap(dashboardColorPaletteApi.create),
  handleError(colorThemeErrorHandlers.createColorThemeErrorHandler),
  tap(() => dispatchAction(getDashboardColorThemes()))
);

const handleUpdateColorTheme = routine(
  ofType(updateDashboardColorTheme),
  extractPayload(),
  mergeMap(dashboardColorPaletteApi.update),
  handleError(colorThemeErrorHandlers.updateColorThemeErrorHandler),
  tap(() => dispatchAction(getDashboardColorThemes()))
);

const handleDeleteColorTheme = routine(
  ofType(deleteDashboardColorTheme),
  extractPayload(),
  mergeMap(palette => dashboardColorPaletteApi.delete(palette._id)),
  handleError(colorThemeErrorHandlers.deleteColorThemeErrorHandler),
  tap(() => dispatchAction(getDashboardColorThemes()))
);

const handleSaveDashboardSelection = routine(
  ofType(saveDashboardColorThemeSelection),
  withLatestFrom(dashboardColorThemes$),
  tap(async ([, { savedSelectedId, dashboardColorThemes }]) => {
    const previouslySelected = dashboardColorThemes.find(
      ({ _id }) => _id === savedSelectedId
    );
    const currentlySelected = dashboardColorThemes.find(
      ({ isSelected }) => isSelected
    );

    if (
      currentlySelected &&
      currentlySelected._id !== DEFAULT_DASHBOARD_COLOR_THEME._id
    ) {
      dispatchAction(
        updateDashboardColorTheme({
          ...currentlySelected,
          isSelected: true,
        })
      );
    }
    if (
      previouslySelected &&
      previouslySelected._id !== DEFAULT_DASHBOARD_COLOR_THEME._id
    ) {
      dispatchAction(
        updateDashboardColorTheme({
          ...previouslySelected,
          isSelected: false,
        })
      );
    }
    showToast(
      `${
        (currentlySelected ?? DEFAULT_DASHBOARD_COLOR_THEME).label
      } applied to dashboards.`,
      ToastType.SUCCESS
    );
  })
);

export default collectRoutines(
  handleGetDashboardColorThemes,
  handeCreateDashboardColorTheme,
  handleUpdateColorTheme,
  handleDeleteColorTheme,
  handleSaveDashboardSelection
);
