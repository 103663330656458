import { modal } from '@ardoq/modal';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  listConnections,
  upsertConnection,
  upsertConnectionFailure,
} from 'integrations/common/streams/connections/actions';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { ConnectionsAsyncOperationsState } from 'integrations/common/streams/connections/types';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { AuthMethodFields } from 'integrations/unified/types';
import {
  UnifiedAuthMethod,
  UnifiedConnection,
} from '@ardoq/api-types/integrations';
import { find } from 'lodash/fp';
import { connect } from '@ardoq/rxbeach';
import { combineLatest, filter, map, of, switchMap } from 'rxjs';
import { UpsertAccountDialog } from './UpsertAccountDialog';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { initialStates } from 'integrations/unified/initialState';

type UpsertConnectionModalProps = {
  integrationName: string;
  connectionId?: string;
  connectionsState: {
    connections: UnifiedConnection[];
    statuses: ConnectionsAsyncOperationsState;
  };
  onCancel: () => void;
  onSubmit: (account: UnifiedConnection) => Promise<boolean>;
  close: () => void;
  authMethodFields: AuthMethodFields;
  defaultAuthMethod: UnifiedAuthMethod;
};

// Todo: Move move this component to common components folder
export function UpsertConnectionModalComponent({
  integrationName,
  connectionsState: { connections, statuses },
  connectionId,
  onCancel,
  onSubmit,
  close,
  authMethodFields,
  defaultAuthMethod,
}: UpsertConnectionModalProps) {
  const existingAccount = find(({ _id }) => connectionId === _id, connections);

  return (
    <UpsertAccountDialog
      integrationName={integrationName}
      exisitingAccount={existingAccount}
      onCancel={onCancel}
      onSubmit={async (connection: UnifiedConnection) => {
        if (await onSubmit(connection)) {
          close();
        }
      }}
      apiStatus={statuses.upsert.status}
      apiError={statuses.upsert.message}
      authMethodFields={authMethodFields}
      defaultAuthMethod={defaultAuthMethod}
    />
  );
}

const UpsertConnnectionModal = connect(
  UpsertConnectionModalComponent,
  integrationId$.pipe(
    filter(isUnifiedIntegrationId),
    switchMap(integrationId => {
      const { defaultAuthMethod, authMethodFields } =
        initialStates[integrationId];
      return combineLatest({
        connectionsState:
          getConnectionsStream<UnifiedConnection>(integrationId),
        integrationName: getIntegrationTermsDictionaryStream(
          integrationId
        ).pipe(map(({ name }) => name)),
        onSubmit: of(async (account: UnifiedConnection) => {
          const action = await dispatchActionAndWaitForResponse(
            upsertConnection({
              integrationId,
              connection: account,
            }),
            listConnections,
            upsertConnectionFailure
          );
          return action.type === listConnections.type;
        }),
        defaultAuthMethod: of(defaultAuthMethod),
        authMethodFields: of(authMethodFields),
      });
    })
  )
);

export const startUpsertConnectionModal = (connectionId?: string) => {
  return modal(
    close => (
      <UpsertConnnectionModal
        connectionId={connectionId}
        onCancel={() => close(null)}
        close={() => close(true)}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
};
