import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { fetchConsent, setConsentState } from './actions';
import { catchError, filter, switchMap, tap, withLatestFrom } from 'rxjs';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { updateConnectionStatuses } from 'integrations/common/streams/connections/actions';
import { microsoftGraphApi } from '@ardoq/api';
import {
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';

const handleConnectionStatusUpdate = routine(
  ofType(updateConnectionStatuses),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'microsoft-entra-id'),
  withLatestFrom(getConnectionsStream('microsoft-entra-id')),
  tap(([{ statuses }, { connections }]) => {
    if (statuses.list?.status === 'SUCCESS' && connections.length <= 0) {
      dispatchAction(fetchConsent());
    }
  })
);

const handleFetchConsent = routine(
  ofType(fetchConsent),
  switchMap(() => {
    dispatchAction(setConsentState({ status: 'LOADING' }));
    return retriableRequest(() => microsoftGraphApi.fetchConsent());
  }),
  tap(response => {
    dispatchAction(setConsentState({ status: 'SUCCESS', ...response }));
  }),
  catchError(error => {
    dispatchAction(setConsentState({ status: 'FAILURE' }));
    return handleApiError(error);
  })
);

export default [handleFetchConsent, handleConnectionStatusUpdate];
