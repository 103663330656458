import { Header3, Text } from '@ardoq/typography';
import PredefinedAudienceQueryCheckboxSection from './PredefinedAudienceQueryCheckboxSection';

const PredefinedAudienceQuerySection = () => (
  <>
    <Header3>
      Select audiences&apos; roles of relationships towards components
    </Header3>
    <Text>
      Note: The options are populated by the predefined people queries that find
      people linked to components from the broadcast scope in the previous step.
    </Text>
    <PredefinedAudienceQueryCheckboxSection />
  </>
);

export default PredefinedAudienceQuerySection;
