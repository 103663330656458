import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateUseCompanyLogo } from 'broadcasts/actions';
import { openChooseOrgLogoDialog } from './ChooseOrgLogoDialog';
import { Link, text1 } from '@ardoq/typography';
import { Switch } from '@ardoq/forms';
import { WarningNotification } from '@ardoq/status-ui';
import { s16 } from '@ardoq/design-tokens';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { APIOrganizationAttributes } from '@ardoq/api-types';

const Wrapper = styled.div`
  display: flex;
  ${text1}
  width: 100%;
  align-items: start;
  gap: 4px;
  @media screen and (max-width: 1110px) {
    flex-direction: column;
  }
`;

type LogoChooserProps = {
  readonly useCompanyLogo: boolean;
  readonly org: APIOrganizationAttributes;
};

const LogoChooser = ({ org, useCompanyLogo }: LogoChooserProps) => {
  const [checked, setChecked] = useState<boolean>(useCompanyLogo);
  const logo = org.settings.logo;

  useEffect(() => {
    setChecked(useCompanyLogo);
  }, [useCompanyLogo]);

  const handleSetChecked = () => {
    const updatedCheck = !checked;

    if (!logo && updatedCheck === true) {
      openChooseOrgLogoDialog(logo, org, updatedCheck);

      return;
    }

    dispatchAction(updateUseCompanyLogo(updatedCheck));
  };

  return (
    <>
      <Wrapper>
        <Switch
          label="Use your organization's logo in the message template."
          name="unchecked"
          isChecked={checked}
          onChange={handleSetChecked}
        />
        <Link
          type="primary"
          hideIcon
          onClick={() => openChooseOrgLogoDialog(logo, org, checked)}
        >
          Add organization logo.
        </Link>
      </Wrapper>
      {checked && logo?.endsWith('.svg') && (
        <WarningNotification style={{ marginBottom: `${s16}` }}>
          <p>
            Your organization&apos;s logo is currently configured as an SVG
            (ends with &quot;.svg&quot;) in Ardoq. Most email clients do not
            support images stored as SVGs. To ensure that your
            organization&apos;s logo will be displayed in broadcast emails,
            please configure your organization logo in Ardoq to use a regular
            image URL instead. Learn more in{' '}
            <a
              href={KnowledgeBaseLink.BUILDING_A_BROADCAST}
              target="_blank"
              rel="noopener noreferrer"
            >
              our knowledge base
            </a>
          </p>
        </WarningNotification>
      )}
      {checked && logo?.startsWith('data:') && (
        <WarningNotification style={{ marginBottom: `${s16}` }}>
          <p>
            Your organization&apos;s logo is currently configured as a Data URL
            (starts with &quot;data:&quot;, as opposed to &quot;https:&quot;) in
            Ardoq. Most email clients do not support images stored as Data URLs.
            To ensure that your organization&apos;s logo will be displayed in
            broadcast emails, please configure your organization logo in Ardoq
            to use a regular image URL instead. Learn more in{' '}
            <a
              href={KnowledgeBaseLink.BUILDING_A_BROADCAST}
              target="_blank"
              rel="noopener noreferrer"
            >
              our knowledge base
            </a>
          </p>
        </WarningNotification>
      )}
    </>
  );
};

export default LogoChooser;
