import { DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import { ModalTemplate, modal } from '@ardoq/modal';
import { ErrorNotification } from '@ardoq/status-ui';
import { configurationState$ } from 'integrations/ITPedia/streams/configurationState/configurationState$';
import { map } from 'rxjs';
import { trackITPediaEvent } from 'integrations/ITPedia/streams/tracking/actions';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { SecondaryButton } from '@ardoq/button';
import { showSupport } from 'utils/support';
import { Box, FlexBox } from '@ardoq/layout';
import { Island } from '@ardoq/page-layout';
function FailedComponent({ errorMessage }: { errorMessage?: string }) {
  const showReport = () => {
    dispatchAction(
      trackITPediaEvent({
        name: 'CLICKED_BUTTON',
        metadata: {
          name: 'View failed report button',
        },
      })
    );
    modal(close => (
      <ModalTemplate
        headerText="Import report"
        notification={
          <ErrorNotification style={{ marginBottom: '16px' }}>
            {errorMessage ?? 'Something unexpected happened'}
          </ErrorNotification>
        }
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={() => close(null)}
        onCloseButtonClick={() => close(null)}
      />
    ));
  };
  return (
    <Box margin="medium">
      <Island minWidth="100%">
        <FlexBox align="center" justify="center">
          <DoqWithSpeechBubble
            doqType={DoqType.ERROR}
            title="There was a technical issue"
            message={
              errorMessage ||
              'Something went wrong in our end. Contact our support team for assistance or review the import report for any potential issues.'
            }
            buttonLabel="Message support"
            extraButton={
              errorMessage ? (
                <SecondaryButton onClick={showReport}>
                  View import report
                </SecondaryButton>
              ) : undefined
            }
            // To hide button we need to provide undefined here
            onButtonClick={showSupport}
          />
        </FlexBox>
      </Island>
    </Box>
  );
}

export const Failed = connect(
  FailedComponent,
  configurationState$.pipe(
    map(config => ({ errorMessage: config.statuses.upsert.message }))
  )
);
