import { distinctUntilChanged, map } from 'rxjs/operators';
import gridEditor2023$ from 'gridEditor2023/gridEditor$';
import { gridEditorStateOperations as gridEditor2023Operations } from 'gridEditor2023/gridEditorStateOperations';
import { isEqual } from 'lodash';

export const gridEditorOpenState$ = gridEditor2023$.pipe(
  map(gridEditor2023 => {
    return {
      isGridEditorOpen:
        gridEditor2023Operations.isDockedGridEditorState(gridEditor2023) &&
        gridEditor2023Operations.isDockedGridEditorOpen(gridEditor2023),
      isGridEditorPoppedOut:
        gridEditor2023Operations.isPopoutGridEditorState(gridEditor2023) &&
        gridEditor2023Operations.isPopoutGridEditorOpen(gridEditor2023),
    };
  }),
  distinctUntilChanged(isEqual)
);
