import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  fetchQueryResults,
  updateComponentTypeProps,
  updateConfigState,
} from './actions';
import { catchError, filter, tap, withLatestFrom } from 'rxjs';
import { postAdvanceQuery } from 'integrations/ITPedia/api/enrichments';
import { QueryBuilderQuery, ScheduleType } from '@ardoq/api-types';
import { ITPediaResultsToSignavioResults } from './utils';
import { handleApiError } from 'integrations/common/utils/api';
import { configState$ } from './configurationState$';
import { selectConnection } from 'integrations/common/streams/connections/actions';
import { loadSchedule } from 'integrations/common/streams/schedule/actions';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { apiBodyToConfig } from '../api/utils';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { NewExportRoutes } from 'integrations/common/navigation/types';

const handleFetchQuery = routine(
  ofType(fetchQueryResults),
  extractPayload(),
  withLatestFrom(configState$),
  tap(async ([{ componentType }, config]) => {
    const componentTypeState = config.componentTypes[componentType];
    if (!componentTypeState || !componentTypeState.componentsQuery) {
      dispatchAction(
        updateComponentTypeProps({
          componentType,
          props: {
            componentsQueryResults: {
              status: 'FAILURE',
              message: `Couldn't find ${componentType} from selected component types.`,
              total: 0,
              components: [],
            },
          },
        })
      );
      return;
    }
    if (!componentTypeState.isQueryValid) {
      dispatchAction(
        updateComponentTypeProps({
          componentType,
          props: {
            componentsQueryResults: {
              status: 'FAILURE',
              message: 'The query is not valid. Please check the query.',
              total: 0,
              components: [],
            },
          },
        })
      );
      return;
    }
    dispatchAction(
      updateComponentTypeProps({
        componentType: componentType,
        props: {
          componentsQueryResults: {
            status: 'LOADING',
            message: 'Fetching ...',
            total: 0,
            components: [],
          },
        },
      })
    );
    const response = await postAdvanceQuery(
      componentTypeState.componentsQuery as QueryBuilderQuery,
      config.workspace.id,
      componentType
    );
    if (response.results) {
      dispatchAction(
        updateComponentTypeProps({
          componentType,
          props: {
            componentsQueryResults: ITPediaResultsToSignavioResults(
              response.results
            ),
          },
        })
      );
    }
    if (response.error) {
      dispatchAction(
        updateComponentTypeProps({
          componentType,
          props: {
            componentsQueryResults: {
              status: 'FAILURE',
              message: response.error.message,
              total: 0,
              components: [],
            },
          },
        })
      );
      return handleApiError(response.error);
    }
  }),
  catchError(error => {
    return handleApiError(error);
  })
);

// When action is selected from overview page
const handleSelectConnection = routine(
  ofType(selectConnection),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'signavio-exporter'),
  tap(({ selectedConnectionId }) => {
    if (!selectedConnectionId) {
      return;
    }
    dispatchAction(
      updateConfigState({
        accountId: selectedConnectionId,
      })
    );
  })
);

const handleLoadSchedule = routine(
  ofType(loadSchedule),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'signavio-exporter'),
  withLatestFrom(getSchedulesStream('signavio-exporter')),
  tap(([config, { schedules }]) => {
    const schedule = schedules.find(s => s._id === config.scheduleId);
    if (
      !schedule ||
      schedule.jobOptions.type !== ScheduleType.SIGNAVIO_EXPORT
    ) {
      return;
    }
    dispatchAction(
      updateConfigState(apiBodyToConfig(schedule._id, schedule.jobOptions))
    );
    dispatchAction(
      navigateToPath({
        integrationId: 'signavio-exporter',
        path: NewExportRoutes.DATA_SELECTION,
      })
    );
  })
);

export default [handleFetchQuery, handleSelectConnection, handleLoadSchedule];
