import {
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import {
  closeLoadingScenarioProgressBar,
  openScenarioError,
} from 'scope/actions';

export type GlobalPaneLoaderProps = {
  isLoading: boolean;
  showSpinner: boolean;
  title: string | null;
  spinnerOffsetX: number;
  spinnerOffsetY: number;
};

const defaultState: GlobalPaneLoaderProps = {
  isLoading: false,
  showSpinner: true,
  title: null,
  spinnerOffsetX: 0,
  spinnerOffsetY: 0,
};

export const setIsLoading = actionCreator<boolean>(
  '[globalPaneLoader$] SET_IS_LOADING_GLOBAL_PANE'
);

export const setIsLoadingWithConfig = actionCreator<
  Partial<GlobalPaneLoaderProps>
>('[globalPaneLoader$] SET_IS_LOADING_WITH_CONFIG_GLOBAL_PANE');

const handleSetIsLoading = (_: GlobalPaneLoaderProps, isLoading: boolean) => ({
  ...defaultState,
  isLoading,
});

const handleSetIsLoadingWithConfig = (
  _: GlobalPaneLoaderProps,
  newStatePartial: Partial<GlobalPaneLoaderProps>
) => ({
  ...defaultState,
  isLoading: true,
  ...newStatePartial,
});

const handleCloseLoadingScenarioProgressBar = (
  state: GlobalPaneLoaderProps
) => ({
  ...state,
  isLoading: false,
});

const handleOpenScenarioError = (state: GlobalPaneLoaderProps) => ({
  ...state,
  isLoading: false,
});

export const globalPaneLoader$ = persistentReducedStream(
  'globalPaneLoader$',
  defaultState,
  [
    reducer(setIsLoading, handleSetIsLoading),
    reducer(setIsLoadingWithConfig, handleSetIsLoadingWithConfig),
    reducer(
      closeLoadingScenarioProgressBar,
      handleCloseLoadingScenarioProgressBar
    ),
    reducer(openScenarioError, handleOpenScenarioError),
  ]
);
