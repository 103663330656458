import { actionCreator } from '@ardoq/rxbeach';

export const setActiveTab = actionCreator<'lastUpdated' | 'favorites'>(
  '[viewpointWidget] SET_ACTIVE_TAB'
);

export const dismissBanner = actionCreator(
  '[viewpointWidget] SET_DISMISS_BANNER'
);

export const setIsExpanded = actionCreator<boolean>(
  '[viewpointWidget] SET_IS_EXPANDED'
);
