import {
  formatNumber,
  getCurrentLocale,
  getNumberFormat,
  numberFormatOptions,
} from '@ardoq/locale';

export const format = (number: number, option = 'default') =>
  formatNumber(number, option, getCurrentLocale());

export const formatToFixed = (number: number, numberOfDecimals = 2) =>
  getNumberFormat(
    { maximumFractionDigits: numberOfDecimals },
    getCurrentLocale()
  ).format(number);

/**
 * Return number of digits in the whole number part of a number
 * E.g. 10 -> 2, 1 -> 1, 123.45 -> 3, 0.1 -> 1
 */
export const getNumWholeDigits = (n: number) => {
  const abs = Math.abs(n);
  if (abs <= 1) return 1;
  return Math.floor(Math.log10(abs) + 1);
};

/**
 * Return numnber of thousands groups in whole part of number
 * E.g. 100 -> 1, 1.000 -> 2, 100.000 -> 2, 1.000.000 -> 3
 */
export const getNumThousandGroups = (n: number) => {
  const wholeDigits = getNumWholeDigits(n);
  if (wholeDigits <= 3) return 1;
  return Math.ceil(wholeDigits / 3);
};

// https://stackoverflow.com/questions/46136440/determine-number-of-leading-zeros-in-a-floating-point-number
export const getFractionalLeadingZeroes = (n: number) =>
  n % 1 === 0 ? 0 : -1 - Math.floor(Math.log10(n % 1));

export const isCommaDecimalSeparator = () =>
  getNumberFormat({}, getCurrentLocale())
    .formatToParts(1.1)
    .find(({ type }) => type === 'decimal')!.value === ',';

export const getCurrencySymbol = (option: string) => {
  const formatOption = numberFormatOptions.get(option)?.option;

  return formatOption
    ? getNumberFormat(formatOption, getCurrentLocale())
        .formatToParts(1.1)
        .find(({ type }) => type === 'currency')?.value
    : '';
};

export const isCurrencyFormat = (option?: string): option is string =>
  option
    ? Boolean(numberFormatOptions.get(option)?.option.style === 'currency')
    : false;
