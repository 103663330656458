import { ViewpointBuilderFooter } from '../components/ViewpointBuilderFooter';
import { PrimaryButtonConfig } from '../openViewpointBuilder/getPrimaryButtonConfig';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { Island, PageBody } from '@ardoq/page-layout';
import { Box } from '@ardoq/layout';
import {
  PerspectivesLabelFormattingWithMultiselect,
  MultiLabelFormattingProps,
} from '@ardoq/perspectives-sidebar';
import { BetaBadge } from '@ardoq/status-ui';

type FormattingContentProps = {
  closeViewpointBuilder: () => void;
  multiLabelFormattingProps: MultiLabelFormattingProps;
  primaryButtonConfig: PrimaryButtonConfig;
  // Commands are part of the state
};

export const LabelFormattingContent = ({
  multiLabelFormattingProps,
  closeViewpointBuilder,
  primaryButtonConfig,
}: FormattingContentProps) => (
  <PageBody
    footerContent={
      <ViewpointBuilderFooter
        closeViewpointBuilder={closeViewpointBuilder}
        primaryButtonConfig={primaryButtonConfig}
      />
    }
  >
    <Box padding="xlarge">
      <Island
        title="Labels"
        subtitleWidth="large"
        subtitle="Show field values on specific component and reference types. Component name is shown by default."
        knowledgeBaseLink={KnowledgeBaseLink.LABEL_FORMATTING}
        titleAddon={
          multiLabelFormattingProps ? (
            <BetaBadge shareFeedbackUrl="https://ardoq.productboard.com/feature-board/8381974-team-insight-eou-feature-list" />
          ) : null
        }
      >
        <PerspectivesLabelFormattingWithMultiselect
          {...multiLabelFormattingProps}
          isHorizontal
        />
      </Island>
    </Box>
  </PageBody>
);
