import { colors } from '@ardoq/design-tokens';
import { Icon, IconName } from '@ardoq/icons';
import { Stack } from '@ardoq/layout';
import * as typography from '@ardoq/typography';
import styled from 'styled-components';
import { DryRunStatus as DryRunStatusType } from 'integrations/common/streams/transferState/types';
import { PopoverContentType } from '@ardoq/popovers';
import { LabelWithPopoverIcon } from '../LabelWithPopoverIcon/LabelWithPopoverIcon';
import { Space } from '@ardoq/style-helpers';

export type DryRunStatusProps = {
  status: DryRunStatusType;
  dryRunAdditionalMessage?: string;
  dryRunAdditionalPopoverContent?: PopoverContentType;
  dryRunSummaryWarning: string;
  dryRunSummaryError: string;
  transferDirection: 'import' | 'export';
};

export const DryRunStatus = ({
  status,
  dryRunAdditionalMessage,
  dryRunAdditionalPopoverContent,
  dryRunSummaryWarning,
  dryRunSummaryError,
  transferDirection,
}: DryRunStatusProps) => {
  const [icon, color, header, message] =
    status === DryRunStatusType.SUCCESS
      ? [
          IconName.CHECK_CIRCLE,
          colors.green50,
          'Almost done!',
          `Your configuration looks good. See the details of what will be ${transferDirection}ed below.`,
        ]
      : status === DryRunStatusType.WARNING
        ? [
            IconName.WARNING,
            colors.yellow50,
            'Review the configuration',
            dryRunSummaryWarning,
          ]
        : [
            IconName.ERROR,
            colors.red50,
            'Review the configuration',
            dryRunSummaryError,
          ];
  return (
    <Stack gap="medium">
      <Header $align="center" $gap="s8">
        <Icon iconName={icon} style={{ color: color }} />
        {header}
      </Header>
      <Subtext>{message}</Subtext>
      {dryRunAdditionalMessage && (
        <LabelWithPopoverIcon popoverContent={dryRunAdditionalPopoverContent}>
          <Subtext>{dryRunAdditionalMessage}</Subtext>
        </LabelWithPopoverIcon>
      )}
    </Stack>
  );
};

const Header = styled(Space)`
  ${typography.header1}
`;

const Subtext = styled.div`
  ${typography.text1};
`;
