import { BroadcastAudienceType } from '@ardoq/api-types';
import { getAudienceTypeRepresentation } from 'broadcasts/utils';

type AudienceTypeLabelProps = {
  audienceType: BroadcastAudienceType;
};

const AudienceTypeLabel = ({ audienceType }: AudienceTypeLabelProps) => (
  <>{getAudienceTypeRepresentation(audienceType)}</>
);

export default AudienceTypeLabel;
