import { StepContainer } from '../components/StepContainer';
import { PrimaryButton, SecondaryButton, GhostButton } from '@ardoq/button';
import { Header1, Header4, Paragraph } from '@ardoq/typography';
import { Icon, IconName } from '@ardoq/icons';
import { DoqFace, DoqSize, DoqType } from '@ardoq/doq';
import { FormWrapper, TextInput } from '@ardoq/forms';
import { SchedulePolicySelector } from 'integrations/common/components/schedulePolicySelector/SchedulePolicySelector';
import { connect } from '@ardoq/rxbeach';
import { viewModel$ } from './viewModel$';
import { SchedulingPolicy } from '@ardoq/api-types';
import { Island } from '@ardoq/page-layout';
import { FlexBox, Stack } from '@ardoq/layout';
type ExportAndScheduleProps = {
  name: string;
  schedulePolicy: SchedulingPolicy;
  onBack: () => void;
  onNameChange: (name: string) => void;
  onSchedulePolicyChange: (schedulingPolicy: SchedulingPolicy) => void;
  onUpserSchedule: () => void;
  upsertType: string;
  link?: string;
};
function ExportAndScheduleComponent({
  name,
  schedulePolicy,
  onBack,
  onNameChange,
  onSchedulePolicyChange,
  onUpserSchedule,
  upsertType,
  link,
}: ExportAndScheduleProps) {
  return (
    <StepContainer $isVertical>
      <Island
        footerContent={
          link && (
            <FlexBox justify="end" flex={1}>
              <SecondaryButton
                onClick={() => {
                  window.open(link, '_blank');
                }}
              >
                Open in SAP Signavio
                <Icon iconName={IconName.OPEN_IN_NEW} />
              </SecondaryButton>
            </FlexBox>
          )
        }
      >
        <FlexBox gap="xlarge">
          <DoqFace doqSize={DoqSize.SMALL} doqType={DoqType.IMPORT_SUCCESS} />

          <Stack gap="small">
            <Header1>The data is exported to SAP Signavio!</Header1>
            <Paragraph variant="text1">
              Create a schedule of the export, or open SAP Signavio to review
              the export.
            </Paragraph>
            {['Dictionary 1'].map(dictionary => (
              <Header4 key={dictionary}>{dictionary}</Header4>
            ))}
          </Stack>
        </FlexBox>
      </Island>
      <Island
        title="Schedule export"
        footerContent={
          <FlexBox justify="end" flex={1}>
            <GhostButton onClick={onBack}>Back</GhostButton>
            <PrimaryButton isDisabled={!name} onClick={onUpserSchedule}>
              {upsertType}&nbsp;schedule
            </PrimaryButton>
          </FlexBox>
        }
      >
        <Stack>
          <FormWrapper>
            <TextInput
              label="Name"
              placeholder="Type the name of sync"
              value={name}
              onValueChange={onNameChange}
            />
            <SchedulePolicySelector
              title={'Interval'}
              schedulePolicy={schedulePolicy}
              onSchedulePolicyChange={onSchedulePolicyChange}
            />
          </FormWrapper>
        </Stack>
      </Island>
    </StepContainer>
  );
}
export const ExportAndSchedule = connect(
  ExportAndScheduleComponent,
  viewModel$
);
