import {
  APIEntityType,
  ArdoqId,
  AllSupportedAPIAttributes,
  AllSupportedEntityTypes,
  APIComponentAttributes,
  APIReferenceAttributes,
} from '@ardoq/api-types';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { componentApi, referenceApi, scenarioApi } from '@ardoq/api';

export const mergeEntities = ({
  mergedEntity,
  entityIdsToDelete,
  entityType,
}: {
  mergedEntity: AllSupportedAPIAttributes;
  entityIdsToDelete: ArdoqId[];
  entityType: AllSupportedEntityTypes;
}) => {
  if (isScenarioMode()) {
    return scenarioApi.mergeEnitites({
      mergedEntity: mergedEntity,
      entityIdsToDelete,
      scenarioId: getActiveScenarioId()!,
    });
  }
  if (entityType === APIEntityType.COMPONENT) {
    return componentApi.merge({
      mergedEntity: mergedEntity as APIComponentAttributes,
      entityIdsToDelete,
    });
  }
  return referenceApi.merge({
    mergedEntity: mergedEntity as APIReferenceAttributes,
    entityIdsToDelete,
  });
};
