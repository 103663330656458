import { derivedStream } from '@ardoq/rxbeach';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import {
  APIReferenceType,
  ArrowType,
  ReferenceTypesInUseResponse,
} from '@ardoq/api-types';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { getEditableReferenceTypes } from '@ardoq/renderers';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { PermissionContext } from '@ardoq/access-control';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { handleError, modelApi } from '@ardoq/api';
import { ScenarioModeState } from 'scope/types';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';

const getReferenceTypeData = ({
  line,
  color,
  lineBeginning,
  lineEnding,
}: APIReferenceType) => {
  return {
    line,
    color,
    lineBeginning: lineBeginning as ArrowType,
    lineEnding,
  };
};

const appModelStateEditStreamToManageReferenceTypesStream = ([
  { enhancedScopeData, model },
  referenceTypesInUse,
  permissionContext,
  activeScenarioState,
]: [
  AppModelStateEditStreamShape,
  ReferenceTypesInUseResponse['types-in-use'],
  PermissionContext,
  ScenarioModeState,
]) => {
  if (!enhancedScopeData || !model) {
    return {
      canManageReferenceTypes: false,
      referenceTypeCards: [],
    };
  }
  const referenceTypes = getEditableReferenceTypes(model, enhancedScopeData);
  const workspaceId = workspaceInterface.findByModel(model)?._id ?? null;
  if (!workspaceId) {
    return {
      canManageReferenceTypes: false,
      referenceTypeCards: [],
    };
  }
  const canManageReferenceTypes =
    workspaceAccessControlInterface.canEditWorkspaceReferenceTypes(
      permissionContext,
      workspaceId,
      activeScenarioState
    );
  return {
    canManageReferenceTypes,
    referenceTypeCards: referenceTypes.map(referenceType => {
      return {
        referenceTypeId: referenceType.id,
        referenceTypeName: referenceType.name,
        referenceTypeData: getReferenceTypeData(referenceType),
        isReferenceTypeInUse: referenceTypesInUse.includes(referenceType.id),
      };
    }),
  };
};

const manageReferenceTypes$ = derivedStream(
  'manageReferenceTypes$',
  appModelStateEdit$,
  appModelStateEdit$.pipe(
    map(({ model }) => model),
    distinctUntilChanged(),
    filter(model => Boolean(model)),
    switchMap(model => modelApi.referenceTypesInUse(model!)),
    handleError(),
    map(({ 'types-in-use': types }) => types ?? [])
  ),
  currentUserPermissionContext$,
  activeScenario$
).pipe(map(appModelStateEditStreamToManageReferenceTypesStream));

export default manageReferenceTypes$;
