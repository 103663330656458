import { EMPTY, filter, map, switchMap, tap } from 'rxjs';
import { catchError, withLatestFrom } from 'rxjs/operators';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';
import { resourcesSummary$ } from './resourcesSummary$';
import {
  fetchResourcesSummary,
  fetchResourcesSummarySuccess,
  setAsyncStatus,
} from './actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import {
  getGroupedResources,
  getRegions,
  getSubscriptions,
  getUnsupportedResourceTypes,
} from './utils';
import { resourceTypes$ } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { fetchRegionsSuccessForCloudProvider } from 'integrations/cloudProviders/streams/regions/actions';
import * as apiUtils from './apiUtils';
import { resourceGroups$ } from '../resourceGroups/resourceGroups$';
import {
  fetchResourceGroupsSuccess,
  updateSelectedResourceGroupNames,
} from '../resourceGroups/actions';
import { fetchAzureSubscriptionsSuccess } from '../subscriptions/actions';
import { filterSubscriptionSelection } from '../selectionState/actions';
import {
  activeIntegrations$,
  getActiveIntegrationStream,
} from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { userEvent } from 'sync/actions';
import {
  ensureCorrectAsyncRequest,
  parseAsyncResponsePayload,
} from 'integrations/common/async/utils';
import { AsyncOperations } from 'integrations/common/async/constants';
import { ResourceTypesSummary } from '@ardoq/api-types/integrations';

const handleFetchResourceSummary = routine(
  ofType(fetchResourcesSummary),
  extractPayload(),
  withLatestFrom(resourcesSummary$, getActiveIntegrationStream('azure-v3')),
  switchMap(([{ connectionId }, resourcesSummary, { trackingFunnelId }]) => {
    if (resourcesSummary.asyncStatus === 'LOADING') {
      return EMPTY;
    }

    dispatchAction(setAsyncStatus({ asyncStatus: 'LOADING' }));
    return retriableRequest(() =>
      apiUtils.fetchResourcesSummary({
        connectionId,
        async: true,
        funnelId: trackingFunnelId,
      })
    ).pipe(
      catchError(error => {
        dispatchAction(
          setAsyncStatus({
            asyncStatus: 'FAILURE',
          })
        );
        return handleApiError(error);
      })
    );
  })
);

const handleFetchResourceSummaryEvent = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncResponsePayload<ResourceTypesSummary[]>),
  withLatestFrom(activeIntegrations$),
  ensureCorrectAsyncRequest(AsyncOperations.AZURE_FETCH_RESOURCES_SUMMARY),
  tap(([response]) => {
    if (!response) return;

    if (response.status !== 'success') {
      dispatchAction(
        setAsyncStatus({
          asyncStatus: 'FAILURE',
        })
      );
      return;
    }

    dispatchAction(
      fetchResourcesSummarySuccess({
        resourcesSummary: response.data,
      })
    );
  })
);

const handleFetchResourceSummarySuccess = routine(
  ofType(fetchResourcesSummarySuccess),
  extractPayload(),
  withLatestFrom(resourceGroups$),
  tap(([{ resourcesSummary }, { selectedResourceGroupNames }]) => {
    const resourceGroups = getGroupedResources(resourcesSummary);
    const subscriptions = getSubscriptions(resourcesSummary);
    const regions = getRegions(resourcesSummary);

    const availableResourceGroupNames = selectedResourceGroupNames.filter(
      name =>
        resourceGroups.some(
          ({ name: resourceGroupName }) => resourceGroupName === name
        )
    );

    dispatchAction(
      fetchRegionsSuccessForCloudProvider({
        integrationId: 'azure-v3',
        regions,
      })
    );
    dispatchAction(fetchResourceGroupsSuccess({ resourceGroups }));

    dispatchAction(
      updateSelectedResourceGroupNames({ names: availableResourceGroupNames })
    );

    dispatchAction(fetchAzureSubscriptionsSuccess({ subscriptions }));
    dispatchAction(filterSubscriptionSelection());
  })
);

const handleUnsupportedResources = routine(
  ofType(fetchResourcesSummarySuccess),
  extractPayload(),
  withLatestFrom(resourceTypes$),
  filter(
    ([_, resourceTypes]) => resourceTypes['azure-v3'].asyncStatus === 'SUCCESS'
  ),
  map(([{ resourcesSummary }, resourceTypes]) => ({
    resourceTypes: resourceTypes['azure-v3'].resourceTypes,
    resourceTypesSummary: resourcesSummary,
  })),
  map(getUnsupportedResourceTypes),
  tap(unsupportedTypes => {
    if (unsupportedTypes.length) {
      dispatchAction(
        trackIntegrationEvent({
          integrationId: 'azure-v3',
          name: 'IDENTIFIED_NON_SUPPORTED_RESOURCES',
          metadata: {
            unsupportedTypes,
          },
        })
      );
    }
  })
);

export default [
  handleFetchResourceSummary,
  handleFetchResourceSummarySuccess,
  handleUnsupportedResources,
  handleFetchResourceSummaryEvent,
];
