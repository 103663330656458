import { PersonalSetting } from '@ardoq/api-types';
import { ConfirmResult, ModalSize } from '@ardoq/modal';
import { confirm } from '@ardoq/modal';
import currentUser from 'models/currentUser';

export const confirmOpenNewDatasetOrSavedViewpointAndClosePreviousView =
  async ({ origin }: { origin: 'openNewDataset' | 'openSavedViewpoint' }) => {
    const isSuppressConfirmDialog = currentUser.getPersonalSetting(
      PersonalSetting.DONT_SHOW_OPEN_DATASET_CONFIRMATION
    );
    if (isSuppressConfirmDialog) return true;

    const { rememberAction, confirmed } = (await confirm({
      title:
        origin === 'openSavedViewpoint'
          ? 'Open Viewpoint?'
          : 'Open new dataset?',
      text: (
        <>
          <p>This will close all currently opened workspaces or datasets.</p>
          <p>
            Remember to save datasets you need as Viewpoints or presentation
            slides before they are closed.
          </p>
        </>
      ),
      confirmButtonTitle:
        origin === 'openSavedViewpoint' ? 'Open Viewpoint' : 'Open new dataset',
      confirmButtonClickId: 'open-dataset-modal-confirm',
      confirmButtonDataTestId: 'open-dataset-modal-confirm',
      cancelButtonTitle: 'Cancel',
      cancelButtonClickId: 'open-dataset-modal-cancel',
      cancelButtonDataTestId: 'open-dataset-modal-cancel',
      showRememberActionCheckbox: true,
      rememberActionCustomCheckboxText: `Don't show this again`,
      modalSize: ModalSize.S,
    })) as ConfirmResult;

    if (rememberAction) {
      currentUser.setPersonalSetting(
        PersonalSetting.DONT_SHOW_OPEN_DATASET_CONFIRMATION,
        true
      );
    }

    return confirmed;
  };
