import { reducer } from '@ardoq/rxbeach';
import { openUserSettings } from './actions';
import { UserSettingsRoute, UserSettingsTabs } from './types';

export const defaultState = {
  userSettingsSelectedTab: UserSettingsTabs.PROFILE,
};

const handleOpenUserSettings = (
  _state: UserSettingsRoute,
  userSettingsSelectedTab: UserSettingsTabs
) => {
  return {
    userSettingsSelectedTab,
  };
};

export const reducers = [
  reducer<UserSettingsRoute, UserSettingsTabs>(
    openUserSettings,
    handleOpenUserSettings
  ),
];
