import { ReactNode } from 'react';
import {
  ViewOptionsSectionRowContainer,
  ViewOptionsSectionRowContent,
  ViewOptionsSectionRowLabel,
  ViewOptionsSectionRightControls,
} from './atoms';
import { Icon, IconName } from '@ardoq/icons';
import { Tag } from '@ardoq/status-ui';
import { IconButton } from '@ardoq/button';
import { colors } from '@ardoq/design-tokens';

type ViewOptionsSectionRowProperties = {
  rowLabel: string;
  tagContent: ReactNode;
  onClick: VoidFunction;
  iconName: IconName;
};
const ViewOptionsSectionRow = ({
  rowLabel,
  iconName,
  tagContent,
  onClick,
}: ViewOptionsSectionRowProperties) => (
  <ViewOptionsSectionRowContainer onClick={onClick}>
    <ViewOptionsSectionRowContent>
      <Icon color={colors.grey50} iconName={iconName} />
      <ViewOptionsSectionRowLabel>{rowLabel}</ViewOptionsSectionRowLabel>
      <ViewOptionsSectionRightControls>
        <Tag>{tagContent || 'None'}</Tag>
        <IconButton iconName={IconName.CHEVRON_RIGHT} />
      </ViewOptionsSectionRightControls>
    </ViewOptionsSectionRowContent>
  </ViewOptionsSectionRowContainer>
);

export default ViewOptionsSectionRow;
