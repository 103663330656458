import { AnimatedTreeChevron, IconSize } from '@ardoq/icons';
import { NavigatorItemExpander } from './Atoms';
import { colors } from '@ardoq/design-tokens';

type NodeExpanderProps = {
  readonly hasVisibleContent: boolean;
  readonly isExpanded: boolean;
};
const NodeExpander = ({ hasVisibleContent, isExpanded }: NodeExpanderProps) => {
  return (
    <NavigatorItemExpander>
      {hasVisibleContent && (
        <AnimatedTreeChevron
          iconSize={IconSize.SMALL}
          $isUp={isExpanded}
          color={colors.grey25}
        />
      )}
    </NavigatorItemExpander>
  );
};

export default NodeExpander;
