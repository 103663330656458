import {
  APIPresentationAssetAttributes,
  PresentationReadPermissions,
  PresentationWritePermissions,
} from '@ardoq/api-types';
import { caseInsensitiveStringIncludes } from '@ardoq/common-helpers';
import { hasFeature, Features } from '@ardoq/features';
import { Checkbox, Label } from '@ardoq/forms';
import { Box, Stack } from '@ardoq/layout';
import { Select, SelectOption } from '@ardoq/select';
import { Header5, Paragraph } from '@ardoq/typography';
import {
  getReadPermissionOptions,
  getWritePermissionOptions,
} from 'presentationEditor/presentationUtil';
import { Section } from './atoms';
import {
  PRES_READ_PERMISSION_LABELS,
  PRES_WRITE_PERMISSION_LABELS,
  SUBDIVISION_PRESENTATION_READ_PERMISSION_LABELS,
} from 'presentationEditor/consts';
import { confirmMakePresentationPublic } from '../confirmMakePresentationPublic';

const HelpContent = () => {
  return (
    <div>
      Choose who can view your presentation:
      <ul>
        <li>Private (only you can access it).</li>
        <li>
          Restrict access so viewers see only the data their permissions allow.
        </li>
        <li>
          Allow anyone within your organization, including Contributors, to view
          it with the URL.
        </li>
        {hasFeature(Features.PUBLIC_PRESENTATIONS) && (
          <li>
            Make it public so anyone with the URL can view the presentation as
            you&apos;ve created it.
          </li>
        )}
      </ul>
    </div>
  );
};

const viewerPermissionsText =
  'Viewers may not see the same data as you in the presentation.';

type AccessRightsProps = {
  presentation?: APIPresentationAssetAttributes;
  readAccess: PresentationReadPermissions;
  writeAccess: PresentationWritePermissions;
  exploreMode: boolean;
  canEditAccessRights: boolean;
  updateReadAccess: (readAccess: PresentationReadPermissions) => void;
  updateWriteAccess: (writeAccess: PresentationWritePermissions) => void;
  updateExploreMode: (exploreMode: boolean) => void;
};

const AccessRights = ({
  presentation,
  readAccess,
  writeAccess,
  exploreMode,
  canEditAccessRights,
  updateReadAccess,
  updateWriteAccess,
  updateExploreMode,
}: AccessRightsProps) => {
  const hasSubdivisionsFeature = hasFeature(Features.PERMISSION_ZONES_INTERNAL);

  const readPermissionOptions = getReadPermissionOptions(
    hasSubdivisionsFeature
  );
  const writePermissionOptions = getWritePermissionOptions();
  const showViewerPermissionsHelperText =
    readAccess === PresentationReadPermissions.WS && hasSubdivisionsFeature;
  const showViewerPermissionsWarning =
    readAccess === PresentationReadPermissions.WS && !hasSubdivisionsFeature;
  return (
    <>
      <Box paddingLeft="medium" paddingTop="medium" marginBottom="small">
        <Header5>Access rights</Header5>
      </Box>
      <Section padding="medium" marginBottom="xlarge">
        <Stack gap="medium">
          {!canEditAccessRights &&
            presentation?.readAccess === PresentationReadPermissions.ALL && (
              <Paragraph>
                Only organisation admins can change access rights on public
                presentations
              </Paragraph>
            )}

          <Stack gap="medium">
            <Stack gap="small">
              <Label
                popoverHelpContent={
                  hasSubdivisionsFeature ? <HelpContent /> : undefined
                }
              >
                Read access
              </Label>
              {canEditAccessRights ? (
                <Select
                  options={readPermissionOptions}
                  isClearable={false}
                  value={readPermissionOptions.find(
                    ({ value }) => value === readAccess
                  )}
                  filterOption={({ data: { label = '' } }, searchPhrase = '') =>
                    caseInsensitiveStringIncludes(label, searchPhrase)
                  }
                  onChange={async (
                    option: SelectOption<PresentationReadPermissions> | null
                  ) => {
                    if (option === null) return;

                    const shouldUpdateReadAccess =
                      option.value === PresentationReadPermissions.ALL
                        ? await confirmMakePresentationPublic()
                        : true;
                    if (!shouldUpdateReadAccess) return;

                    updateReadAccess(option.value);
                  }}
                  helperText={
                    showViewerPermissionsHelperText
                      ? viewerPermissionsText
                      : undefined
                  }
                  warningMessage={
                    showViewerPermissionsWarning
                      ? viewerPermissionsText
                      : undefined
                  }
                />
              ) : (
                <Paragraph variant="text2Bold">
                  {hasSubdivisionsFeature
                    ? SUBDIVISION_PRESENTATION_READ_PERMISSION_LABELS[
                        readAccess
                      ]
                    : PRES_READ_PERMISSION_LABELS[readAccess]}
                </Paragraph>
              )}
            </Stack>
            <Stack gap="small">
              <Label>Write access</Label>
              {canEditAccessRights ? (
                <Select
                  options={writePermissionOptions}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  value={writePermissionOptions.find(
                    ({ value }) => value === writeAccess
                  )}
                  onChange={(
                    option: SelectOption<PresentationWritePermissions> | null
                  ) => {
                    if (option === null) return;
                    updateWriteAccess(option.value);
                  }}
                />
              ) : (
                <Paragraph variant="text2Bold">
                  {PRES_WRITE_PERMISSION_LABELS[writeAccess]}
                </Paragraph>
              )}
            </Stack>
          </Stack>
          {canEditAccessRights && (
            <Checkbox
              isChecked={exploreMode}
              label="Disable Explore Mode"
              onChange={() => updateExploreMode(!exploreMode)}
            />
          )}
        </Stack>
      </Section>
    </>
  );
};

export default AccessRights;
