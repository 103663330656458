import { PrimaryButton } from '@ardoq/button';
import { ViewpointsOverviewCommands } from './viewpointsOverviewCommands';

type CreateNewTraversalButtonProps = {
  commands: ViewpointsOverviewCommands;
  canUserCreateTraversals: boolean;
};

export const CreateNewTraversalButton = ({
  commands,
  canUserCreateTraversals,
}: CreateNewTraversalButtonProps) => {
  return (
    <PrimaryButton
      isDisabled={!canUserCreateTraversals}
      onClick={commands.createNewViewpoint}
    >
      Create new
    </PrimaryButton>
  );
};
