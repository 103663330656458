import { workspaceInterface } from '@ardoq/workspace-interface';
import { fieldInterface } from '@ardoq/field-interface';
import {
  APIFieldAttributes,
  APIDiscoverViewpointAttributes,
  Direction,
  EntryPoint,
  NonPersistedDiscoverViewpoint,
  Triple,
  TypeRelationship,
  FilterInfoOperator,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { isEqual, uniq, uniqWith } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  FieldSelectOption,
  PartialConstraint,
  PartialStep,
  PartialTriple,
  Step,
} from './types';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';
import { SelectOption } from '@ardoq/select';
import { getReferenceOptionLeftContent } from './ReferenceOptionLeftContent';
import { trackEvent } from 'tracking/tracking';

export function isPersisted(
  viewpoint: APIDiscoverViewpointAttributes | NonPersistedDiscoverViewpoint
): viewpoint is APIDiscoverViewpointAttributes {
  return '_id' in viewpoint;
}

const getComponentTypeNamesInStep = ({ constraints }: Step) => {
  return uniq(
    constraints
      .flatMap(({ triple }) => [triple[0], triple[2]])
      .filter(ExcludeFalsy)
  );
};

const getReferenceTypeNamesInStep = ({ constraints }: Step) => {
  return uniq(
    constraints.flatMap(({ triple }) => [triple[1]]).filter(ExcludeFalsy)
  );
};

export const getStepsThatIncludeComponentTypeName = (
  steps: Step[],
  componentTypeName: string
) => {
  return steps.filter(step => {
    return getComponentTypeNamesInStep(step).includes(componentTypeName);
  });
};

export const getSelectedComponentTypeNames = (steps: Step[]) => {
  return uniq(steps.flatMap(getComponentTypeNamesInStep));
};

export const getSelectedReferenceTypeNames = (steps: Step[]) => {
  return uniq(steps.flatMap(getReferenceTypeNamesInStep));
};

const getExistingStepIdForComponentTypeName = (
  entryPoints: EntryPoint[],
  componentTypeName: string
) => {
  const entryPoint = entryPoints.find(
    ([entryPointComponentTypeName]) =>
      entryPointComponentTypeName === componentTypeName
  );
  if (!entryPoint) return null;
  return entryPoint[1];
};

const deriveStepId = (
  currentStepId: string | null,
  newSteps: Step[],
  componentTypeName: string
) => {
  const stepsThatIncludeComponentTypeName =
    getStepsThatIncludeComponentTypeName(newSteps, componentTypeName);
  if (stepsThatIncludeComponentTypeName.length === 0) return null;
  const newStepId = stepsThatIncludeComponentTypeName.some(
    ({ id }) => id === currentStepId
  )
    ? currentStepId
    : stepsThatIncludeComponentTypeName[0].id;
  return newStepId;
};

const deriveNewEntryPoint =
  (currentEntryPoints: EntryPoint[], newSteps: Step[]) =>
  (componentTypeName: string) => {
    const currentStepId = getExistingStepIdForComponentTypeName(
      currentEntryPoints,
      componentTypeName
    );
    const newStepId = deriveStepId(currentStepId, newSteps, componentTypeName);
    if (!newStepId) return null;
    return [componentTypeName, newStepId];
  };

export const deriveNewEntryPoints = (
  currentEntryPoints: EntryPoint[],
  newSteps: Step[]
) => {
  return getSelectedComponentTypeNames(newSteps)
    .map(deriveNewEntryPoint(currentEntryPoints, newSteps))
    .filter(ExcludeFalsy);
};

export const getAvailableTriples = (typeRelationships: TypeRelationship[]) => {
  const availableTriples = typeRelationships.map(
    ({ sourceType, referenceType, targetType }): Triple =>
      [sourceType, referenceType, targetType] as Triple
  );
  return uniqWith(availableTriples, isEqual);
};

const isSupersetOf =
  (subsetTriple: PartialTriple) => (supersetTriple: Triple) => {
    return supersetTriple.every((typeName, index) => {
      return [typeName, null].includes(subsetTriple[index]);
    });
  };

export const hasValidTriple = (
  { triple }: PartialConstraint,
  availableTriples: Triple[]
) => {
  return availableTriples.some(isSupersetOf(triple));
};

export const withoutInvalidTriples = (
  currentSteps: PartialStep[],
  availableTriples: Triple[]
): PartialStep[] => {
  const newSteps = currentSteps.map(step => ({
    ...step,
    constraints: step.constraints.filter(constraint =>
      hasValidTriple(constraint, availableTriples)
    ),
  }));
  return newSteps;
};

export const entryPointIsAmbiguous = (
  steps: Step[],
  entryPoint: EntryPoint
) => {
  const componentTypeName = entryPoint[0];
  const stepsThatIncludeComponentTypeName =
    getStepsThatIncludeComponentTypeName(steps, componentTypeName);
  return stepsThatIncludeComponentTypeName.length > 1;
};

const getAmbiguousEntryPoints = (steps: Step[], entryPoints: EntryPoint[]) => {
  return entryPoints.filter(entryPoint =>
    entryPointIsAmbiguous(steps, entryPoint)
  );
};

export const hasAmbiguousEntryPoints = (
  steps: Step[],
  entryPoints: EntryPoint[]
) => {
  return getAmbiguousEntryPoints(steps, entryPoints).length > 0;
};

const getFromType = ({ triple, direction }: PartialConstraint) => {
  switch (direction) {
    case 'outgoing':
      return triple[0];
    case 'incoming':
      return triple[2];
    case 'undirected':
      return [triple[0], triple[2]];
  }
};
const getFromTypes = (constraints: PartialConstraint[]) => {
  return new Set(constraints.flatMap(getFromType).filter(ExcludeFalsy));
};

const getToType = ({ triple, direction }: PartialConstraint) => {
  switch (direction) {
    case 'outgoing':
      return triple[2];
    case 'incoming':
      return triple[0];
    case 'undirected':
      return [triple[0], triple[2]];
  }
};
const getToTypes = (constraints: PartialConstraint[]) => {
  return new Set(constraints.flatMap(getToType).filter(ExcludeFalsy));
};

export const startSetEqualsEndSet = (constraints: PartialConstraint[]) => {
  const fromTypes = getFromTypes(constraints);
  const toTypes = getToTypes(constraints);
  return fromTypes.size > 0 && isEqual(fromTypes, toTypes);
};

export const createEmptyConstraint = (
  creationContext: 'triple' | 'step',
  availableTriples: Triple[],
  previousStep?: PartialStep
): PartialConstraint => {
  if (!previousStep?.constraints.length) {
    return {
      triple: [null, null, null],
      direction: 'outgoing',
    };
  }
  const lastConstraint =
    previousStep.constraints[previousStep.constraints.length - 1];
  let relevantComponentTypeIndex =
    lastConstraint.direction === 'outgoing' ? 2 : 0;

  if (creationContext === 'triple') {
    relevantComponentTypeIndex =
      lastConstraint.direction === 'outgoing' ? 0 : 2;
  }

  if (lastConstraint.triple[relevantComponentTypeIndex] === null) {
    return {
      triple: [null, null, null],
      direction: 'outgoing',
    };
  }

  return getConstraintWithUpdatedStartType(
    availableTriples,
    lastConstraint.triple[relevantComponentTypeIndex] ?? ''
  );
};

export const createEmptyStep = (
  previousStep: PartialStep,
  availableTriples: Triple[]
) => ({
  id: uuidv4(),
  constraints: [createEmptyConstraint('step', availableTriples, previousStep)],
  minRepetition: 0,
  maxRepetition: 1,
});

export const getPossibleStartTypeNames = (availableTriples: Triple[]) => {
  return uniq(availableTriples.flatMap(triple => [triple[0], triple[2]]));
};

export const getStartTypeOptions = (
  availableTriples: Triple[],
  currentConstraint: PartialConstraint,
  isDisabled: boolean
): (SelectOption<string> & { constraint: PartialConstraint })[] => {
  const possibleOptions = getPossibleStartTypeNames(availableTriples).map(
    startTypeName => ({
      value: startTypeName,
      label: startTypeName,
      constraint: getConstraintWithUpdatedStartType(
        availableTriples,
        startTypeName
      ),
    })
  );

  // I can't figure out a way to do it other than this code smell.
  const currentFirstTypeName = getStartTypeName(currentConstraint);
  if (
    !currentFirstTypeName ||
    possibleOptions.some(option => option.value === currentFirstTypeName)
  ) {
    return possibleOptions;
  }

  return [
    ...possibleOptions,
    {
      value: currentFirstTypeName,
      label: currentFirstTypeName,
      isDisabled,
      constraint: getConstraintWithUpdatedStartType(
        [currentConstraint.triple as Triple],
        currentFirstTypeName
      ),
    },
  ];
};

export const getPossibleReferenceTypes = (
  availableTriples: Triple[],
  constraint: PartialConstraint
) => {
  const currentStartTypeName = getStartTypeName(constraint);
  return uniqWith(
    availableTriples
      .flatMap(triple => {
        const outgoingReference =
          triple[0] === currentStartTypeName
            ? { typeName: triple[1], direction: 'outgoing' as Direction }
            : null;
        const incomingReference =
          triple[2] === currentStartTypeName
            ? { typeName: triple[1], direction: 'incoming' as Direction }
            : null;
        return [outgoingReference, incomingReference];
      })
      .filter(ExcludeFalsy),
    isEqual
  );
};

export const getReferenceOptions = (
  availableTriples: Triple[],
  constraint: PartialConstraint,
  isDisabled: boolean
): (SelectOption<string | null> & { constraint: PartialConstraint })[] => {
  const possibleOptions = getPossibleReferenceTypes(
    availableTriples,
    constraint
  ).map(({ typeName, direction }) => {
    return {
      value: getReferenceTypeOptionValue(typeName, direction),
      label: '',
      constraint: getConstraintWithUpdatedReferenceType(
        constraint,
        typeName,
        direction
      ),
      ...getReferenceOptionLeftContent(direction, typeName),
    };
  });

  // I can't figure out a way to do it other than this code smell.
  const currentReferenceName = getReferenceTypeOptionValue(
    constraint.triple[1],
    constraint.direction
  );
  if (
    !currentReferenceName ||
    possibleOptions.some(option => option.value === currentReferenceName)
  ) {
    return possibleOptions;
  }

  return [
    ...possibleOptions,
    {
      value: currentReferenceName,
      label: '',
      isDisabled,
      constraint: getConstraintWithUpdatedReferenceType(
        constraint,
        constraint.triple[1]!,
        constraint.direction
      ),
      ...getReferenceOptionLeftContent(
        constraint.direction,
        constraint.triple[1]!
      ),
    },
  ];
};

export const getPossibleEndTypeNames = (
  availableTriples: Triple[],
  constraint: PartialConstraint
) => {
  const startTypeIndex = constraint.direction === 'outgoing' ? 0 : 2;
  const endTypeIndex = constraint.direction === 'outgoing' ? 2 : 0;
  return uniq(
    availableTriples
      .filter(triple => {
        return (
          triple[1] === constraint.triple[1] &&
          triple[startTypeIndex] === constraint.triple[startTypeIndex]
        );
      })
      .map(triple => triple[endTypeIndex])
  );
};

export const getEndTypeOptions = (
  availableTriples: Triple[],
  constraint: PartialConstraint,
  isDisabled: boolean
): (SelectOption<string> & { constraint: PartialConstraint })[] => {
  const possibleOptions = getPossibleEndTypeNames(
    availableTriples,
    constraint
  ).map(endTypeName => {
    return {
      value: endTypeName,
      label: endTypeName,
      constraint: getConstraintWithUpdatedEndType(constraint, endTypeName),
    };
  });

  // I can't figure out a way to do it other than this code smell.
  const currentEndTypeName = getEndTypeName(constraint);
  if (
    !currentEndTypeName ||
    possibleOptions.some(option => option.value === currentEndTypeName)
  ) {
    return possibleOptions;
  }

  return [
    ...possibleOptions,
    {
      label: currentEndTypeName,
      value: currentEndTypeName,
      isDisabled,
      constraint: getConstraintWithUpdatedEndType(
        constraint,
        currentEndTypeName
      ),
    },
  ];
};

const getStartTypeName = (constraint: PartialConstraint) => {
  return constraint.direction === 'outgoing'
    ? constraint.triple[0]
    : constraint.triple[2];
};

const getEndTypeName = (constraint: PartialConstraint) => {
  return constraint.direction === 'outgoing'
    ? constraint.triple[2]
    : constraint.triple[0];
};

const isSourceType = (availableTriples: Triple[], typeName: string) => {
  return availableTriples.some(([sourceTypeName]) => {
    return sourceTypeName === typeName;
  });
};

const getConstraintWithUpdatedStartType = (
  availableTriples: Triple[],
  startTypeName: string
): PartialConstraint => {
  return isSourceType(availableTriples, startTypeName)
    ? { triple: [startTypeName, null, null], direction: 'outgoing' }
    : { triple: [null, null, startTypeName], direction: 'incoming' };
};

const getConstraintWithUpdatedReferenceType = (
  constraint: PartialConstraint,
  newReferenceTypeName: string,
  newDirection: Direction
): PartialConstraint => {
  const currentStartTypeName = getStartTypeName(constraint);
  return {
    triple:
      newDirection === 'outgoing'
        ? [currentStartTypeName, newReferenceTypeName, null]
        : [null, newReferenceTypeName, currentStartTypeName],
    direction: newDirection,
  };
};

const getConstraintWithUpdatedEndType = (
  constraint: PartialConstraint,
  newEndTypeName: string
) => {
  return {
    ...constraint,
    triple: (constraint.direction === 'outgoing'
      ? [constraint.triple[0], constraint.triple[1], newEndTypeName]
      : [newEndTypeName, constraint.triple[1], constraint.triple[2]]) as Triple,
  };
};

export const getReferenceTypeOptionValue = (
  referenceTypeName: string | null,
  direction: Direction
) => {
  if (referenceTypeName === null) return null;
  return `${direction}${referenceTypeName}`;
};

export const getTripleInTraversalOrder = (constraint: PartialConstraint) => {
  return constraint.direction === 'outgoing'
    ? constraint.triple
    : [...constraint.triple].reverse();
};

export const toFieldSelectOption = ({
  label,
  name,
  type,
}: APIFieldAttributes): FieldSelectOption => ({
  label,
  value: name,
  category: 'field',
  isDateRange: isDateRangeFieldType(type),
});

export const getReferenceCustomFieldOptions = (
  workspaceIds: string[],
  referenceTypeName: string
): FieldSelectOption[] => {
  const workspaceIdsWithReferenceType = workspaceIds.filter(workspaceId => {
    return Object.values(
      workspaceInterface.getReferenceTypes(workspaceId) ?? {}
    ).some(({ name }) => name === referenceTypeName);
  });
  const referenceTypeIds = uniq(
    workspaceIdsWithReferenceType.flatMap(workspaceId => {
      return Object.values(
        workspaceInterface.getReferenceTypes(workspaceId) ?? {}
      )
        .filter(({ name }) => name === referenceTypeName)
        .map(({ id }) => id);
    })
  );
  const fields = workspaceIdsWithReferenceType.flatMap(workspaceId => {
    return fieldInterface.getAllFieldsOfWorkspace(workspaceId).filter(field => {
      return (
        field.global ||
        referenceTypeIds.some(referenceTypeId =>
          field.referenceType.includes(`${referenceTypeId}`)
        )
      );
    });
  });
  return dateRangeOperations
    .mergeDateTimeFieldsToDateRangeFields(fields)
    .fields.map(toFieldSelectOption);
};

export const getComponentCustomFieldOptions = (
  workspaceIds: string[],
  componentTypeName: string
): FieldSelectOption[] => {
  const workspaceIdsWithComponentType = workspaceIds.filter(workspaceId => {
    return workspaceInterface
      .getComponentTypes(workspaceId)
      .some(({ name }) => name === componentTypeName);
  });
  const componentTypeIds = uniq(
    workspaceIdsWithComponentType.flatMap(workspaceId => {
      return workspaceInterface
        .getComponentTypes(workspaceId)
        .filter(({ name }) => name === componentTypeName)
        .map(({ id }) => id);
    })
  );
  const fields = workspaceIdsWithComponentType.flatMap(workspaceId => {
    return fieldInterface.getAllFieldsOfWorkspace(workspaceId).filter(field => {
      return (
        field.global ||
        componentTypeIds.some(componentTypeId =>
          field.componentType.includes(componentTypeId)
        )
      );
    });
  });
  return dateRangeOperations
    .mergeDateTimeFieldsToDateRangeFields(fields)
    .fields.map(toFieldSelectOption);
};

export const updateViewpointInViewpointListById = (
  viewpoints: APIDiscoverViewpointAttributes[],
  newViewpoint: APIDiscoverViewpointAttributes
) => {
  const viewPointIndex = viewpoints.findIndex(
    viewpoint => viewpoint._id === newViewpoint._id
  );

  if (viewPointIndex === -1) {
    return [...viewpoints, newViewpoint];
  }
  const newViewpoints = [...viewpoints];
  newViewpoints[viewPointIndex] = newViewpoint;
  return newViewpoints;
};

export const updateCurrentViewpointIfNeeded = (
  currentViewpoint: APIDiscoverViewpointAttributes | null,
  newViewpoint: APIDiscoverViewpointAttributes
) =>
  currentViewpoint && currentViewpoint._id === newViewpoint._id
    ? newViewpoint
    : currentViewpoint;

const DEPRECATED_CONTAINS_COMPARATORS: Set<FilterInfoOperator> = new Set([
  'contains_phrase' as FilterInfoOperator,
  'contains_terms' as FilterInfoOperator,
]);
const DEPRECATED_NOT_CONTAINS_COMPARATORS: Set<FilterInfoOperator> = new Set([
  'not_contains_phrase' as FilterInfoOperator,
  'not_contains_terms' as FilterInfoOperator,
]);

/**
 * Remove comparators that are not used in viewpoints.
 * The logic afaict is that contains phrase/terms are related to elastic search,
 * which is not used in viewpoints, however comparators are only a FE concept
 * so it is unclear if this code is actually needed.
 * Cleanup task: https://ardoqcom.atlassian.net/browse/ARD-23014
 * Source: https://github.com/ardoq/ardoq-packages/pull/2498
 */
export const mapAPIResponse = (
  viewpoint: APIDiscoverViewpointAttributes
): APIDiscoverViewpointAttributes => ({
  ...viewpoint,
  componentFilters: viewpoint.componentFilters?.map(componentFilters => ({
    ...componentFilters,
    filters: componentFilters.filters.map(filter => {
      if (!filter.comparator) return filter;

      if (DEPRECATED_CONTAINS_COMPARATORS.has(filter.comparator)) {
        trackEvent('Dev ARD-23014 Impossible viewpoint comparator', {
          comparator: filter.comparator,
          id: viewpoint._id,
        });
        return { ...filter, comparator: FilterInfoOperator.CONTAINS };
      }

      if (DEPRECATED_NOT_CONTAINS_COMPARATORS.has(filter.comparator)) {
        trackEvent('Dev ARD-23014 Impossible viewpoint comparator', {
          comparator: filter.comparator,
          id: viewpoint._id,
        });
        return { ...filter, comparator: 'not_contains' as FilterInfoOperator };
      }

      return filter;
    }),
  })),
});

export const prepareIsIncluded =
  <T>(list: T[]) =>
  (value: T) =>
    list.includes(value);
