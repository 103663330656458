import { DialogHeaderWithIcon } from 'scope/merge/MergeDialog';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import EntityMergeContainer from 'components/EntityMerge/EntityMergeContainer';
import entityMergeTable$ from 'components/EntityMerge/entityMergeTable$';
import { connect } from '@ardoq/rxbeach';
import { APIEntityType, AllSupportedEntityTypes } from '@ardoq/api-types';
import { EntityMergeViewModel } from './types';
import { ModalSize, ModalTemplate } from '@ardoq/modal';

/** The number of rows present in `dataSource` if there are no differences to display. */
const NUM_METADATA_ROWS = 3;

const COMPONENT_MERGE_HEADER =
  'Select the properties, field values and style that you want to merge into a single component';

const REFERENCE_MERGE_HEADER =
  'Select the properties and field values that you want to merge into a single reference';

const getHeaderTitle = (
  entityType: AllSupportedEntityTypes,
  showEmptyPage: boolean
) => {
  if (showEmptyPage) {
    return `Identical ${
      entityType === APIEntityType.COMPONENT ? 'components' : 'references'
    }`;
  }
  return entityType === APIEntityType.COMPONENT
    ? COMPONENT_MERGE_HEADER
    : REFERENCE_MERGE_HEADER;
};

type EntityMergeDialogProps = {
  closeHandler: VoidFunction;
} & EntityMergeViewModel;

const EntityMergeDialog = ({
  closeHandler,
  entityType,
  dataSource,
  ...props
}: EntityMergeDialogProps) => {
  const showEmptyPage = dataSource.length === NUM_METADATA_ROWS;
  return (
    <ModalTemplate modalSize={ModalSize.COVER}>
      <DialogHeaderWithIcon
        title={getHeaderTitle(entityType, showEmptyPage)}
        knowledgeLink={KnowledgeBaseLink.ENTITY_MERGE_FLOW}
        onCloseButtonClick={closeHandler}
      />
      <EntityMergeContainer
        entityType={entityType}
        dataSource={dataSource}
        showEmptyPage={showEmptyPage}
        {...props}
      />
    </ModalTemplate>
  );
};

export default connect(EntityMergeDialog, entityMergeTable$);
