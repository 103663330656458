import { ArdoqId, PersistedEntityGroupShape } from '@ardoq/api-types';
import { formatDateOnly } from '@ardoq/date-time';
import { IconName } from '@ardoq/icons';
import { getCurrentLocale } from '@ardoq/locale';
import { DatasourceTable } from '@ardoq/table';
import { StyledIcon } from 'admin/bundles/BundleOverview/atoms';
import styled from 'styled-components';

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 60px;
`;

type EntityGroupListProps = {
  entityGroups: (PersistedEntityGroupShape & {
    handleDelete: (id: ArdoqId) => Promise<void>;
    handleEdit: (id: ArdoqId) => void;
    handleCreateFromExistingEntityGroup: (id: ArdoqId) => void;
  })[];
};

const EntityGroupList = ({ entityGroups }: EntityGroupListProps) => {
  return (
    <DatasourceTable
      style={{ marginTop: 25 }}
      dataSource={entityGroups}
      columns={[
        { title: 'Entity group name', dataIndex: 'name' },
        { title: 'Created by', dataIndex: 'createdByEmail' },
        {
          title: 'Last updated',
          dataIndex: 'lastUpdated',
          valueRender: value => formatDateOnly(value, getCurrentLocale()),
        },
        {
          title: 'Actions',
          dataIndex: '_id',
          valueRender: (value, dataSourceRow) => {
            const onDeleteClick = () => dataSourceRow.handleDelete(value);
            const onEditClick = () => dataSourceRow.handleEdit(value);
            const onCreateFromExistingClick = () =>
              dataSourceRow.handleCreateFromExistingEntityGroup(value);
            return (
              <ActionButtons>
                <StyledIcon
                  tooltipText="Delete"
                  onClick={onDeleteClick}
                  iconName={IconName.DELETE}
                />
                <StyledIcon
                  tooltipText="Edit"
                  onClick={onEditClick}
                  iconName={IconName.EDIT}
                />
                <StyledIcon
                  tooltipText="Create new EG based on this one"
                  iconName={IconName.ADD_CIRCLE}
                  onClick={onCreateFromExistingClick}
                />
              </ActionButtons>
            );
          },
        },
      ]}
    />
  );
};

export default EntityGroupList;
