import { Select, SelectOption } from '@ardoq/select';
import { SidebarMenu, SidebarMenuSection } from '@ardoq/sidebar-menu';
import Box from 'atomicComponents/Box';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { ComponentType, useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  ColumnMappingSidebarProps,
  ComponentsColumnType,
  ReferencesColumnType,
} from './types';
import {
  getColumnTypeForIdentifiers,
  getColumnTypeOptions,
} from './columnTypes';
import {
  isComponentMapping,
  isFieldColumnMapping,
  isReferenceColumnMapping,
  isCustomIdMapping,
  isParentMapping,
  isRootReferenceMapping,
  isTargetReferenceMapping,
  isArdoqIdMapping,
  hasAnIdColumnMapping,
} from 'integrations/common/streams/tabularMappings/utils';
import { Field } from './Field';
import { ButtonType, IconButton, SecondaryButton } from '@ardoq/button';
import { ComponentType as ComponentTypeSelector } from './ComponentType';
import { Reference } from './Reference';
import { CustomId } from './CustomId';
import { MAPPING_SIDEBAR_WIDTH } from 'integrations/common/pages/tabularConfigMapping/constants';
import { Space } from '@ardoq/style-helpers';
import { colors, s16, s4, s8 } from '@ardoq/design-tokens';
import { ParentComponent } from './ParentComponent';
import { Icon, IconName } from '@ardoq/icons';
import { caption, text1, text1Bold } from '@ardoq/typography';
import { testId } from 'integrations/common/testUtils/testId';
import { SourceReference } from './SourceReference';
import { ArdoqId } from './ArdoqId';
import { PopoverPlacement, WithPopover } from '@ardoq/popovers';
import { isColumnMappingTypeDisabled } from 'integrations/common/streams/tabularMappingConstraints/utils';
import { isMac } from '@ardoq/common-helpers';
import { TargetReference } from './TargetReference';
import { GroupBase, GroupProps } from 'react-select';
import {
  DropdownMenuSectionSplit,
  DropdownMenuSectionTitle,
} from '@ardoq/dropdown-menu-ui';
import { FlexBox } from '@ardoq/layout';

const ColumnMappingSidebarContainer = styled(Box)`
  position: sticky;
  height: 100%;
  z-index: 1;
  top: 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  width: ${MAPPING_SIDEBAR_WIDTH}px;
  max-height: 100%;
`;

const InputContainer = styled.div`
  width: ${MAPPING_SIDEBAR_WIDTH}px;
  height: 100%;
  max-width: ${MAPPING_SIDEBAR_WIDTH}px;
`;

const FooterContainer = styled(Space)`
  padding: ${s16};
`;
const FooterActionText = styled.div`
  ${text1}
`;
const FooterActionButtons = styled(Space).attrs({ $gap: 's4' })``;

const ComponentTypeSelectorContainer = styled(Space).attrs({
  $isVertical: true,
  $gap: 'none',
})`
  margin: 0 ${s16};
`;

const ComponentTypeLabelContainer = styled(Space).attrs({
  $gap: 's8',
  $isVertical: true,
})`
  margin-bottom: ${s8};
`;

const ColumnTypeLabel = styled.div`
  ${text1Bold}
`;

const CaptionText = styled.div<{ $padding?: string }>`
  color: ${colors.grey50};
  ${caption};
  padding: ${props => (props.$padding ? props.$padding : 0)};
`;

interface CustomGroupBase<Option> extends GroupBase<Option> {
  description?: string;
}

export const Group: ComponentType<
  GroupProps<
    SelectOption<ComponentsColumnType | ReferencesColumnType>,
    false,
    CustomGroupBase<SelectOption<ComponentsColumnType | ReferencesColumnType>>
  >
> = props => {
  return (
    <>
      {props.label && (
        <DropdownMenuSectionTitle>{props.label}</DropdownMenuSectionTitle>
      )}
      {props.data.description && (
        <CaptionText $padding={`${s4} ${s16} 0`}>
          {props.data.description}
        </CaptionText>
      )}
      {props.children}
      <DropdownMenuSectionSplit />
    </>
  );
};

export const ColumnMappingSidebar = ({
  tableMappingType,
  tablePreview,
  tableMapping,
  selectedColumn,
  usedFields,
  allFields,
  usedWorkspaces,
  existingWorkspaces,
  newReferenceTypes,
  setSelectedColumn,
  onColumnMappingChange,
  onColumnMappingClear,
  isCreationDisabled,
  isHardDeleteEnabled,
  mappedColumnsConstraints,
  tableMappingErrors,
  fieldTypeOptions,
  isTypeSelectionDisabled,
  onFirstComponentTypeChange,
}: ColumnMappingSidebarProps) => {
  const altKey = isMac() ? '⌥' : 'Alt';

  const columnName =
    tablePreview?.previewRows?.header[selectedColumn + 1] ?? '';

  const numberOfColumns = tablePreview?.previewRows?.header?.length ?? 0;

  const mappedColumnsErrors = tableMappingErrors[selectedColumn] || {};
  const columnMapping = tableMapping?.mappedColumns[selectedColumn];
  const selectedColumnType =
    tableMapping?.mappedColumns[selectedColumn]?.columnType;

  const selectedColumnMapping = tableMapping?.mappedColumns[selectedColumn];

  const isColumnMappingDisabled = isColumnMappingTypeDisabled({
    mappedColumnsConstraints,
    columnName,
  });

  // -2 to exclude the row # column
  const maxPossibleColumns = numberOfColumns - 2;

  const usedIdentifier = getColumnTypeForIdentifiers({
    tableMapping,
  });
  const columnTypeOptions = getColumnTypeOptions({
    tableMappingType,
    allFields,
    isCreationDisabled,
    isHardDeleteEnabled,
    usedIdentifier,
    selectedColumnMapping,
    isNewWorkspace: usedWorkspaces.some(workspace => !workspace.id),
    isTypeSelectionDisabled,
  });

  const handleMappingChange = (columnMapping: Partial<ColumnMapping>) => {
    onColumnMappingChange({
      columnName,
      columnIndex: selectedColumn,
      columnMapping,
    });
  };

  useEffect(() => {
    const handleAltKeyEvent = (event: KeyboardEvent) => {
      if (!event.altKey) return;

      switch (event.key) {
        case ',':
          return setSelectedColumn(Math.max(0, selectedColumn - 1));
        case '.':
          return setSelectedColumn(
            Math.min(maxPossibleColumns, selectedColumn + 1)
          );
      }
    };
    window.addEventListener('keydown', handleAltKeyEvent);

    return () => {
      window.removeEventListener('keydown', handleAltKeyEvent);
    };
  }, [maxPossibleColumns, numberOfColumns, selectedColumn, setSelectedColumn]);

  const MappingSidebarContainerRef = useRef<HTMLDivElement>(null);

  // when we are switching columns, we need to focus the columnType dropdown
  useEffect(() => {
    const selectComponent =
      MappingSidebarContainerRef.current?.getElementsByTagName('input')[0];
    if (selectComponent instanceof HTMLInputElement) {
      selectComponent.focus();
    }
  }, [selectedColumn]);

  return (
    <ComponentWrapper {...testId('tabularMapping-column-mapping-sidebar')}>
      <ColumnMappingSidebarContainer ref={MappingSidebarContainerRef}>
        <InputContainer>
          <SidebarMenu
            isResizable={false}
            headerTitle={`Column: ${columnName}`}
            onCloseClick={() => setSelectedColumn(null)}
            isPageBodyMenu
            isRightContent
            footerContent={
              <FooterContainer
                $gap="s4"
                $justify="space-between"
                $align="center"
              >
                <FooterActionText>Switch columns</FooterActionText>
                <FooterActionButtons>
                  <IconButton
                    isDisabled={!numberOfColumns || selectedColumn === 0}
                    onClick={() => {
                      setSelectedColumn(selectedColumn - 1);
                    }}
                    iconName={IconName.CHEVRON_LEFT}
                    buttonType={ButtonType.SECONDARY}
                    dataTestId="tabularMapping-switch-column-icon"
                    data-tooltip-text={`Previous Column(${altKey} ,)`}
                  />
                  <IconButton
                    isDisabled={
                      !numberOfColumns || selectedColumn === maxPossibleColumns
                    }
                    onClick={() => {
                      setSelectedColumn(selectedColumn + 1);
                    }}
                    iconName={IconName.CHEVRON_RIGHT}
                    buttonType={ButtonType.SECONDARY}
                    dataTestId="tabularMapping-switch-column-icon"
                    data-tooltip-text={`Next Column(${altKey} .)`}
                  />
                </FooterActionButtons>
              </FooterContainer>
            }
          >
            <SidebarMenuSection isCollapsible={false}>
              <ComponentTypeSelectorContainer>
                <ComponentTypeLabelContainer>
                  <FlexBox align="center" gap="xsmall">
                    <ColumnTypeLabel>Column type</ColumnTypeLabel>
                    {isComponentMapping(columnMapping) &&
                      !hasAnIdColumnMapping(tableMapping?.mappedColumns) && (
                        <WithPopover
                          content="Components with duplicate names will be excluded automatically."
                          preferredPlacement={PopoverPlacement.TOP}
                        >
                          <Icon
                            iconName={IconName.INFO}
                            color={colors.grey50}
                          />
                        </WithPopover>
                      )}
                  </FlexBox>
                  {isColumnMappingDisabled &&
                    mappedColumnsConstraints[columnName]
                      ?.disabledColumnTypeReason && (
                      <CaptionText>
                        {
                          mappedColumnsConstraints[columnName]
                            .disabledColumnTypeReason
                        }
                      </CaptionText>
                    )}
                </ComponentTypeLabelContainer>
                <Select
                  dataTestId="tabularMapping-column-type--select"
                  autoFocus
                  placeholder="Select a type"
                  value={selectedColumnType}
                  options={columnTypeOptions}
                  onValueChange={value => {
                    if (!value) return;
                    onColumnMappingChange({
                      columnName,
                      columnIndex: selectedColumn,
                      columnMapping: { columnType: value },
                    });
                  }}
                  isDisabled={isColumnMappingDisabled}
                  components={{ Group }}
                />
              </ComponentTypeSelectorContainer>

              {!isColumnMappingDisabled && (
                <SecondaryButton
                  isDisabled={!selectedColumnType || isColumnMappingDisabled}
                  onClick={() => onColumnMappingClear(selectedColumn)}
                  dataTestId="tabularMapping-clear-column-mapping"
                >
                  Clear selection
                </SecondaryButton>
              )}
              {/* TODO: Extract to a separate component once ready */}
              {isComponentMapping(columnMapping) && (
                <ComponentTypeSelector
                  onColumnMappingChange={onColumnMappingChange}
                  columnMapping={columnMapping}
                  columnName={columnName}
                  selectedColumn={selectedColumn}
                  tableMapping={tableMapping}
                  mappedColumnsErrors={mappedColumnsErrors}
                  isCreationDisabled={isCreationDisabled}
                  onFirstComponentTypeChange={onFirstComponentTypeChange}
                />
              )}

              {isFieldColumnMapping(selectedColumnMapping) && (
                <Field
                  tableMapping={tableMapping}
                  fieldMapping={selectedColumnMapping}
                  usedFields={usedFields}
                  allFields={allFields}
                  onMapping={handleMappingChange}
                  isCreationDisabled={isCreationDisabled}
                  mappedColumnsErrors={mappedColumnsErrors}
                  fieldTypeOptions={fieldTypeOptions}
                />
              )}

              {isReferenceColumnMapping(selectedColumnMapping) && (
                <Reference
                  tableMapping={tableMapping}
                  selectedColumn={selectedColumn}
                  referenceMapping={selectedColumnMapping}
                  usedWorkspaces={usedWorkspaces}
                  existingWorkspace={existingWorkspaces}
                  usedFields={usedFields}
                  allFields={allFields}
                  newReferenceTypes={newReferenceTypes}
                  onMapping={handleMappingChange}
                  columnName={columnName}
                  isCreationDisabled={isCreationDisabled}
                  mappedColumnsErrors={mappedColumnsErrors}
                />
              )}
              {columnMapping && isCustomIdMapping(selectedColumnMapping) && (
                <CustomId
                  tableMapping={tableMapping}
                  customIdMapping={selectedColumnMapping}
                  usedFields={usedFields}
                  allFields={allFields}
                  onMapping={handleMappingChange}
                  isCreationDisabled={isCreationDisabled}
                />
              )}
              {isArdoqIdMapping(selectedColumnMapping) && (
                <ArdoqId
                  tableMapping={tableMapping}
                  onMapping={handleMappingChange}
                  ardoqIdMapping={selectedColumnMapping}
                />
              )}
              {columnMapping && isParentMapping(selectedColumnMapping) && (
                <ParentComponent
                  tableMapping={tableMapping}
                  parentMapping={selectedColumnMapping}
                  usedFields={usedFields}
                  allFields={allFields}
                  onMapping={handleMappingChange}
                  columnName={columnName}
                  isCreationDisabled={isCreationDisabled}
                />
              )}

              {isRootReferenceMapping(selectedColumnMapping) && (
                <SourceReference
                  tableMapping={tableMapping}
                  columnMapping={selectedColumnMapping}
                  usedFields={usedFields}
                  allFields={allFields}
                  onMapping={handleMappingChange}
                  columnName={columnName}
                  newReferenceTypes={newReferenceTypes}
                  isCreationDisabled={isCreationDisabled}
                  mappedColumnsErrors={mappedColumnsErrors}
                />
              )}

              {isTargetReferenceMapping(selectedColumnMapping) && (
                <TargetReference
                  tableMapping={tableMapping}
                  columnMapping={selectedColumnMapping}
                  usedFields={usedFields}
                  allFields={allFields}
                  onMapping={handleMappingChange}
                  columnName={columnName}
                  isCreationDisabled={isCreationDisabled}
                  mappedColumnsErrors={mappedColumnsErrors}
                />
              )}
            </SidebarMenuSection>
          </SidebarMenu>
        </InputContainer>
      </ColumnMappingSidebarContainer>
    </ComponentWrapper>
  );
};
