import { pluralize } from '@ardoq/common-helpers';
import { HelperTextWrapper, Label } from '@ardoq/forms';
import { IconName } from '@ardoq/icons';
import { StatusType, Tag } from '@ardoq/status-ui';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';
import { SubdivisionProps } from 'subdivisionEditor/types';
import { Box, FlexBox } from '@ardoq/layout';

export const SearchResultCount = (props: SubdivisionProps) => {
  const {
    componentsSelection: { showResults, resultsTotalCount },
  } = props;
  if (
    !showResults ||
    !subdivisionEditorOperations.isAdvancedSearchMode(props)
  ) {
    return null;
  }
  return (
    <Box>
      <FlexBox align="flex-start" gap="small">
        <div>
          <Label>Total results: </Label>
        </div>
        <Tag
          label={`${resultsTotalCount} ${pluralize(
            'component',
            resultsTotalCount
          )}`}
          iconName={IconName.COMPONENT}
          statusType={StatusType.NEW}
        ></Tag>
      </FlexBox>
      <HelperTextWrapper>
        {
          SUBDIVISIONS_STRINGS.STEPS.COMPONENTS_SELECTION.SEARCH_RESULTS_COUNT
            .HELPER_TEXT
        }
      </HelperTextWrapper>
    </Box>
  );
};
