import { actionCreator } from '@ardoq/rxbeach';
import {
  DashboardApiError,
  DashboardBuilderChartWidget,
  DashboardBuilderHeaderWidget,
  EditModeDashboardAttributes,
  LoadedDashboard,
} from '@ardoq/dashboard';
import { Layout, LoadingState } from './types';
import {
  APIWidgetAttributes,
  ArdoqId,
  BarChartColorOptions,
  ChartOrderOptions,
  DataSourceField,
  HexColor,
  NumberWidgetTrend,
  ReportFilterQuery,
  WidgetDataSourceEntity,
  WidgetTypes,
} from '@ardoq/api-types';
import { SelectedBranch } from 'utils/searchBranchSelection';

const ACTION_PREFIX = '[dashboardBuilder]';
const addPrefix = (actionName: string): `[${string}] ${string}` =>
  `${ACTION_PREFIX} ${actionName}`;

export type CreateWidgetPayload = {
  viewType?: WidgetTypes;
  layout: Omit<Layout, 'i'>;
};

export type CopyWidgetPayload = {
  widgetId: string;
  layout: Omit<Layout, 'i'>;
};

export const createWidget = actionCreator<CreateWidgetPayload>(
  addPrefix('CREATE_WIDGET')
);

export const selectWidget = actionCreator<string>(addPrefix('SELECT_WIDGET'));

export const copyWidget = actionCreator<CopyWidgetPayload>(
  addPrefix('COPY_WIDGET')
);

export const updateSelectedWidget = actionCreator<
  Partial<DashboardBuilderChartWidget> | Partial<DashboardBuilderHeaderWidget>
>(addPrefix('UPDATE_SELECTED_WIDGET'));

export const setWidgets = actionCreator<
  Array<DashboardBuilderHeaderWidget | DashboardBuilderChartWidget>
>(addPrefix('SET_WIDGETS'));

export const deleteWidget = actionCreator<string>(addPrefix('DELETE_WIDGET'));

export const selectDataSource = actionCreator<WidgetDataSourceEntity>(
  addPrefix('SELECT_DATA_SOURCE')
);

export const selectField = actionCreator<DataSourceField[]>(
  addPrefix('SELECT_FIELD')
);

export const fetchWidgetDataSources = actionCreator(
  addPrefix('FETCH_AVAILABLE_DATA_SOURCES')
);

export const setAvailableDataSources = actionCreator<WidgetDataSourceEntity[]>(
  addPrefix('SET_WIDGET_DATA_SOURCES')
);

export const setLoadingDataSources = actionCreator<boolean>(
  addPrefix('SET_LOADING_DATA_SOURCES')
);

export const clearWidgetSelection = actionCreator(
  addPrefix('CLEAR_WIDGET_SELECTION')
);

export const selectWidgetType = actionCreator<WidgetTypes>(
  addPrefix('SELECT_WIDGET_TYPE')
);

export const setWidgetPreview = actionCreator<APIWidgetAttributes>(
  addPrefix('SET_WIDGET_PREVIEW')
);

export const setWidgetError = actionCreator<{ _id: string; error: string }>(
  addPrefix('SET_WIDGET_ERROR')
);

type GetWidgetPreviewPayload = {
  widgetData: DashboardBuilderChartWidget;
  availableFields: DataSourceField[];
};

export const getWidgetPreview = actionCreator<GetWidgetPreviewPayload>(
  addPrefix('GET_WIDGET_PREVIEW')
);

export const setDashboardName = actionCreator<string>(
  addPrefix('SET_DASHBOARD_NAME')
);

export const setDashboardDescription = actionCreator<string>(
  addPrefix('SET_DASHBOARD_DESCRIPTION')
);

export const saveDashboard = actionCreator<EditModeDashboardAttributes>(
  addPrefix('SAVE_DASHBOARD')
);

export const setLoadedDashboard = actionCreator<LoadedDashboard>(
  addPrefix('SET_LOADED_DASHBOARD')
);

export const clearDashboardData = actionCreator(
  addPrefix('CLEAR_DASHBOARD_DATA')
);
export const setDashboardLoadingState = actionCreator<Exclude<
  LoadingState,
  | LoadingState.LOADING_ERROR
  | LoadingState.FETCH_DATA_SOURCES_ERROR
  | LoadingState.SAVING_ERROR
> | null>(addPrefix('SET_IS_LOADING'));

type SetColorForDataPointPayload = {
  dataPoint: string;
  color: HexColor;
};
export const setColorForDataPoint = actionCreator<SetColorForDataPointPayload>(
  addPrefix('SET_COLOR_FOR_FIELD')
);

export const removeManuallyAssignedColorForDataPoint = actionCreator<string>(
  addPrefix('REMOVE_MANUALLY_ASSIGNED_COLOR_FOR_DATAPOINT')
);

export const setValidationErrors = actionCreator(
  addPrefix('SET_VALIDATION_ERRORS')
);

export const setDashboardWarnings = actionCreator(
  addPrefix('SET_DASHBOARD_WARNINGS')
);

export const persistLoadedDashboardData = actionCreator<LoadedDashboard>(
  addPrefix('PERSIST_LOADED_DASHBOARD_DATA')
);

type FetchDashboardDataPayload = {
  selectedDashboardId: ArdoqId;
  loadFromCache?: boolean;
  presentationId?: ArdoqId;
  branchId?: SelectedBranch;
};
export const fetchDashboardData = actionCreator<FetchDashboardDataPayload>(
  addPrefix('FETCH_DASHBOARD_DATA')
);

export type SetDashboardApiErrorPayload = {
  apiError: DashboardApiError;
  apiLoadingState?:
    | LoadingState.FETCH_DATA_SOURCES_ERROR
    | LoadingState.LOADING_ERROR
    | LoadingState.SAVING_ERROR;
};

export type SetDashboardBranchIdPayload = {
  branchId: SelectedBranch;
  dashboardId: ArdoqId;
};

export type DashboardWidgetFilterPayload = {
  widgetId: ArdoqId;
  filterQuery: ReportFilterQuery;
};

export const setDashboardApiError = actionCreator<SetDashboardApiErrorPayload>(
  addPrefix('SET_DASHBOARD_API_ERROR')
);

export const selectBarChartColorSelection = actionCreator<BarChartColorOptions>(
  addPrefix('SELECT_BAR_CHART_COLOR_SELECTION')
);

export const selectChartDataOrder = actionCreator<ChartOrderOptions>(
  addPrefix('SELECT_CHART_DATA_ORDER')
);

export const selectNumberSlices = actionCreator<number>(
  addPrefix('SELECT_NUMBER_SLICES')
);

export const selectNumberTrend = actionCreator<boolean | NumberWidgetTrend>(
  addPrefix('SELECT_NUMBER_TREND')
);

export const setDashboardBranchId = actionCreator<SetDashboardBranchIdPayload>(
  addPrefix('SET_DASHBOARD_BRANCH_ID')
);

export const applyDashboardWidgetFiltersRequested =
  actionCreator<DashboardWidgetFilterPayload>(
    addPrefix('APPLY_WIDGET_FILTERS')
  );

export const widgetFilterDrawerOpened = actionCreator(
  addPrefix('WIDGET_FILTER_DRAWER_OPENED')
);

export const widgetFilterDrawerClosed = actionCreator(
  addPrefix('WIDGET_FILTER_DRAWER_CLOSED')
);
