import {
  MetamodelIslandGrid,
  MetamodelPreviewContainer,
  MetamodelPreviewErrorContainer,
  MetamodelSidebarContainer,
} from '../atoms';
import { MetamodelStep } from '../UseCaseSetup/steps/MetamodelStep';
import {
  MetamodelPreviewErrorProps,
  UseCaseLandingPageMetamodelPreviewProps,
  isMetamodelPreviewErrorProps,
} from './types';
import { DoqyWithMessage, Subtitle } from './atoms';
import { MetaModelSidebar } from './MetaModelSidebar';

import { SimpleViewModel } from 'viewpointBuilder/types';
import { ComponentTypeRelatedFieldsAndTriples } from '@ardoq/api-types';
import { DoqFace, DoqType, DoqSize } from '@ardoq/doq';
import { TextOverflow } from '@ardoq/popovers';

const MetamodelPreviewContent = ({
  viewModel,
  componentTypeFieldsAndTriples,
}: {
  viewModel: SimpleViewModel;
  componentTypeFieldsAndTriples: ComponentTypeRelatedFieldsAndTriples[];
}) => (
  <>
    <MetamodelPreviewContainer>
      <MetamodelStep viewModel={viewModel} />
    </MetamodelPreviewContainer>
    <MetamodelSidebarContainer>
      <MetaModelSidebar metaModelData={componentTypeFieldsAndTriples} />
    </MetamodelSidebarContainer>
  </>
);

const MetamodelPreviewErrorBody = ({ error }: MetamodelPreviewErrorProps) => {
  return (
    <MetamodelPreviewErrorContainer>
      <DoqyWithMessage>
        <DoqFace doqType={DoqType.ERROR} doqSize={DoqSize.MEDIUM} />
        <TextOverflow lineClamp={3}>
          <p>{error.userMessage}</p>
          {error.traceId && <p>Trace ID: {error.traceId}</p>}
        </TextOverflow>
      </DoqyWithMessage>
    </MetamodelPreviewErrorContainer>
  );
};

const UseCaseLandingPageMetamodelPreview = ({
  useCaseName,
  metamodelPreviewContentProps,
}: UseCaseLandingPageMetamodelPreviewProps) => (
  <>
    <Subtitle>
      This is the recommended Metamodel for {useCaseName}. You will import data
      into these components and reference types. You can adapt this Metamodel to
      suit your organization.
    </Subtitle>
    <MetamodelIslandGrid>
      {isMetamodelPreviewErrorProps(metamodelPreviewContentProps) ? (
        <MetamodelPreviewErrorBody {...metamodelPreviewContentProps} />
      ) : (
        <MetamodelPreviewContent {...metamodelPreviewContentProps} />
      )}
    </MetamodelIslandGrid>
  </>
);

export default UseCaseLandingPageMetamodelPreview;
