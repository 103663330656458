import { trackEvent } from 'tracking/tracking';
import { MetamodelTrackingLocation } from './types';
import { ArdoqId } from '@ardoq/api-types';

export const trackClickStartCreatingNewMetamodel = () =>
  trackEvent('Clicked to start creating new metamodel');

export const trackClickFinishCreatingNewMetamodel = () =>
  trackEvent('Clicked to finish creating new metamodel');

export enum OpeningMethod {
  METAMODEL_STATS_BUTTON = 'Metamodel stats button',
  CLICK_NODE_IN_VISUALIZATION = 'Click node in visualization',
}
export const trackClickOpenMetamodelStats = (openingMethod: OpeningMethod) =>
  trackEvent('Clicked to open metamodel stats', {
    openingMethod,
  });

export const trackClickCloseMetamodelStatsButton = () =>
  trackEvent('Clicked close metamodel stats button');

export enum NavigationContext {
  METAMODEL = 'Metamodel',
  WORKSPACE_FOLDER = 'Workspace folder',
  WORKSPACE = 'Workspace',
  COMPONENT_TYPE = 'Component type',
  REFERENCE_TYPE = 'Reference type',
  COMPONENT_TYPE_FIELD = 'Component type field',
  REFERENCE_TYPE_FIELD = 'Reference type field',
}
export const trackClickContextTitle = (context: NavigationContext) =>
  trackEvent(
    'Clicked context title in metamodel sidebar to open corresponding workspace(s)',
    { context }
  );

export enum MetamodelItemWithField {
  COMPONENT_TYPE = 'Component type',
  REFERENCE_TYPE = 'Reference type',
}
export const trackClickFieldInMetamodelSidebar = (
  context: MetamodelItemWithField
) => trackEvent('Clicked field in metamodel sidebar', { context });

export const trackClickReferenceCompletionInMetamodelSidebar = () =>
  trackEvent('Clicked reference completion in metamodel sidebar');

export const trackClickBreadCrumbPath = () =>
  trackEvent('Clicked bread crumb navigation in metamodel sidebar');

export const trackClickBackToOverview = () =>
  trackEvent('Clicked back to overview in metamodel builder');

type TrackOpenedMetamodelEditorParams = {
  resourceId?: ArdoqId;
  trackingLocation?: MetamodelTrackingLocation;
};
export const trackOpenedMetamodelEditor = ({
  trackingLocation,
  resourceId,
}: TrackOpenedMetamodelEditorParams) =>
  trackEvent('Opened Metamodel editor', {
    resourceId,
    from: trackingLocation,
  });
