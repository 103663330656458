import { take, takeUntil } from 'rxjs/operators';
import { isEmpty, isEqual } from 'lodash';
import { ComponentBackboneModel } from 'aqTypes';
import { JsonFilterPrefixes } from 'models/filterEnums';
import {
  ObservableState,
  action$,
  dispatchAction,
  ofType,
} from '@ardoq/rxbeach';
import {
  dynamicFilterError,
  replaceDynamicFilters,
  requestActivateDynamicFilter,
  setDynamicFilterActive,
  setDynamicFilterParameters,
} from 'streams/dynamicFilters/actions';
import dynamicFilterStates$, {
  defaultFilterState,
} from 'streams/dynamicFilters/dynamicFilterStates$';
import {
  DynamicFilterDefinition,
  DynamicFilterId,
  DynamicFilterParams,
  DynamicFilterSelectState,
  DynamicFilterState,
} from '@ardoq/data-model';
import { Reference } from 'aqTypes';
import { lastValueFrom } from 'rxjs';
import { triggerFiltersChangedEvent } from 'streams/filters/FilterActions';

export interface ApiDynamicFilter {
  storedQueryId: string;
  params: DynamicFilterParams;
}

export const replaceAllDynamicFilters = async (
  apiFilters: ApiDynamicFilter[]
) => {
  const filters = apiFilters.map(apiDynamicFiltersToDynamicFilterStates);
  dispatchAction(replaceDynamicFilters(filters));

  const filtersActive = waitForDynamicFiltersActive(apiFilters.length);
  requestFilterActivation(filters);
  await filtersActive;
};

const createURIEncoder = (filter: DynamicFilterState) => () => {
  return `${
    JsonFilterPrefixes.DYNAMIC_FILTER +
    window.encodeURI(JSON.stringify([filter.id, filter.params]))
  }&`;
};

const waitForDynamicFiltersActive = async (count: number): Promise<void> => {
  await lastValueFrom(
    action$.pipe(
      ofType(setDynamicFilterActive),
      // Complete the stream when error occurs, otherwise the promise won't be resolved
      // in case of multile filters (the fetch stream dies on error, but the count says
      // to wait for more).
      takeUntil(action$.pipe(ofType(dynamicFilterError))),
      take(count)
    ),
    { defaultValue: undefined }
  );
  return undefined;
};

const requestFilterActivation = (filters: DynamicFilterId[]) => {
  filters.forEach(({ id }) =>
    // We do not need to dispatch between requestActivateDynamicFilter and
    // setDynamicFilterParameters, because the parameters have already been set
    dispatchAction(requestActivateDynamicFilter({ id }))
  );
};

const extractDynamicFilter = (payload: string): DynamicFilterDefinition => {
  const paramWithoutPrefix = payload.slice(
    JsonFilterPrefixes.DYNAMIC_FILTER.length
  );
  const [id, params] = JSON.parse(paramWithoutPrefix);
  return {
    id,
    params,
  };
};

export const applyDynamicFiltersFromQueryParams = (payload: string) => {
  const dynamicFilter = extractDynamicFilter(payload);
  if (!dynamicFilter.params || isEmpty(dynamicFilter.params)) {
    dispatchAction(requestActivateDynamicFilter(dynamicFilter));
  } else {
    dispatchAction(setDynamicFilterParameters(dynamicFilter));
  }
};

export const apiDynamicFiltersToDynamicFilterStates = (
  filter: ApiDynamicFilter
) => {
  return {
    ...defaultFilterState,
    id: filter.storedQueryId,
    params: filter.params,
  };
};

class DynamicFilters {
  dynamicFilters: DynamicFilterState[] = [];
  aggregatedFilters = {
    components: new Set<string>(),
    references: new Set<string>(),
  };

  constructor(dynamicFilterStates$: ObservableState<DynamicFilterState[]>) {
    dynamicFilterStates$.subscribe(allDynamicFilters => {
      const dynamicFilters = allDynamicFilters.filter(
        f => f.state === DynamicFilterSelectState.ACTIVE
      );

      if (isEqual(this.dynamicFilters, dynamicFilters)) return;

      this.dynamicFilters = dynamicFilters;
      this.aggregatedFilters = dynamicFilters.reduce(
        // All active dynamic filters are unioned
        (acc, filter) => ({
          components: new Set([...acc.components, ...filter.components]),
          references: new Set([...acc.references, ...filter.references]),
        }),
        { components: new Set(), references: new Set() }
      );

      dispatchAction(triggerFiltersChangedEvent());
    });
  }

  getDynamicFiltersAsEncodables() {
    return this.dynamicFilters.map(filter => ({
      encodeURI: createURIEncoder(filter),
    }));
  }

  getDefinitions() {
    return this.dynamicFilters.map(filter => ({
      storedQueryId: filter.id,
      params: filter.params,
    }));
  }

  includesComponent(component: ComponentBackboneModel) {
    if (!this.dynamicFilters.length) return true;
    return this.aggregatedFilters.components.has(component.id);
  }

  includesReference(reference: Reference) {
    if (!this.dynamicFilters.length) return true;
    return (
      this.aggregatedFilters.references.has(reference.id) &&
      this.includesComponent(reference.getSource()) &&
      this.includesComponent(reference.getTarget())
    );
  }
}

export default new DynamicFilters(dynamicFilterStates$);
