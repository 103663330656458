type TableId = string;

type ColumnIndex = number;

export type MappedColumnsErrors = Partial<
  Record<SidebarSelectorsIds, string | null>
>;

export type TableMappingErrors = Record<ColumnIndex, MappedColumnsErrors>;

export type TabularMappingErrors = Record<TableId, TableMappingErrors>;

export enum SidebarSelectorsIds {
  COMPONENT_TYPE,
  FIELD_NAME,
  ITEM_SEPARATOR,
  REFERENCE_PATH_SEPARATOR,
  COMPONENT_TYPE_IN_REFERENCE,
}
