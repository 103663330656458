import { action$, reduceState, reducer } from '@ardoq/rxbeach';
import { viewpointGroupingRulesOperations } from './viewpointGroupingRulesOperations';
import {
  createGroupingRule,
  deleteGroupingRule,
  reorderGroupingRules,
  updateGroupingRule,
} from 'perspective/perspectiveEditor/perspectivesEditor$/actions';
import { Observable } from 'rxjs';
import { GroupingRulesState } from './groupingRulesTypes';
import {
  setGroupingOptions,
  setGroupingRules,
} from './viewpointGroupingRulesActions';
import { map } from 'rxjs/operators';
import { getInvalidGroupingRuleSelectOptionsByRuleId } from '../../perspectiveSidebar/perspectiveEditor/getInvalidGroupingRuleSelectOptionsByRuleId';
import { PerspectiveGroupingRule } from '@ardoq/perspectives';
import { disabledOptionProps } from 'viewpointBuilder/components/disabledOptionProps';

export const viewpointGroupingRules$: Observable<GroupingRulesState> =
  action$.pipe(
    reduceState<GroupingRulesState>(
      'viewpointBuilder2.0/viewpointGroupingRules$',
      viewpointGroupingRulesOperations.getInitialState(),
      [
        reducer(
          createGroupingRule,
          viewpointGroupingRulesOperations.createGroupingRule
        ),
        reducer(
          updateGroupingRule,
          viewpointGroupingRulesOperations.updateGroupingRule
        ),
        reducer(
          reorderGroupingRules,
          viewpointGroupingRulesOperations.reorderGroupingRules
        ),
        reducer(
          deleteGroupingRule,
          viewpointGroupingRulesOperations.deleteGroupingRule
        ),
        reducer(
          setGroupingOptions,
          viewpointGroupingRulesOperations.setGroupingOptions
        ),
        reducer(
          setGroupingRules,
          viewpointGroupingRulesOperations.setGroupingRules
        ),
      ]
    ),
    map(state => {
      const invalidGroupingSelectOptionsDataByRuleId =
        getInvalidGroupingRuleSelectOptionsByRuleId(
          state.groupingRules,
          state.groupingOptions
        );

      const getInvalidGroupingSelectOptions = (rule: PerspectiveGroupingRule) =>
        invalidGroupingSelectOptionsDataByRuleId[rule.id] || {};

      return { ...state, getInvalidGroupingSelectOptions, disabledOptionProps };
    })
  );
