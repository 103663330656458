import { Fragment } from 'react';
import { times } from 'lodash';
import { StepsSvg as StepsSvgWrapper } from './atoms';
import { Step, SubStep } from './types';
import { colors } from '@ardoq/design-tokens';

type Point = { x: number; y: number };

const MAIN_STEP_HEIGHT = 50;
const MAIN_STEP_PIE_HEIGHT = 24;
const SUB_STEP_HEIGHT = 40;
const SUB_STEP_SIZE = 14;
const ARC_SIZE = 4;
const LINE_STROKE_WIDTH = 2;
const HORIZONTAL_INDENTATION = 24;

const getSubStepColor = (subStep: SubStep) => {
  if (subStep.isSelected) return colors.purple60;
  if (subStep.isCompleted || subStep.isSubmitted) return '#fff';
  return 'none';
};

const getSubStepStyle = (subStep: SubStep) => ({
  fill: getSubStepColor(subStep),
  ...(subStep.hasEntities || subStep.isSubmitted ? {} : { opacity: 0.5 }),
});

const BigPie = ({ position, step }: { position: Point; step: Step }) => {
  const hasChangesToSubmit = step.subSteps.some(
    ({ hasEntities, isSubmitted }) => hasEntities && !isSubmitted
  );
  const someChangesHasBeenSubmitted = step.subSteps.some(
    ({ isSubmitted }) => isSubmitted
  );
  const isSubmitted = someChangesHasBeenSubmitted && !hasChangesToSubmit;
  const hasEntities = step.subSteps.some(({ hasEntities }) => hasEntities);
  const isSelected = step.subSteps.find(({ isSelected }) => isSelected);
  const isChecked = isSubmitted && !isSelected;
  return (
    <>
      <g
        transform={`translate(${position.x + 12}, ${position.y + 12})`}
        style={hasEntities || isChecked ? {} : { opacity: 0.5 }}
      >
        {step.subSteps.length === 4 &&
          step.subSteps.map((subStep, i) => (
            <use
              key={subStep.name}
              xlinkHref="#oneFourthPiece"
              fill={getSubStepColor(subStep)}
              transform={`rotate(${i * 90 + 45})`}
            />
          ))}
        {step.subSteps.length === 3 &&
          step.subSteps.map((subStep, i) => (
            <use
              key={subStep.name}
              xlinkHref="#oneThirdPiece"
              fill={getSubStepColor(subStep)}
              transform={`rotate(${i * 120 + 60})`}
            />
          ))}
        {step.subSteps.length === 2 &&
          step.subSteps.map((subStep, i) => (
            <use
              key={subStep.name}
              xlinkHref="#halfPiece"
              fill={getSubStepColor(subStep)}
              transform={`rotate(${i * 180 + 270} 0 0)`}
            />
          ))}
        {step.subSteps.length === 1 && (
          <use
            key={step.subSteps[0].name}
            xlinkHref="#onePiece"
            fill={getSubStepColor(step.subSteps[0])}
          />
        )}
      </g>
      {isChecked && (
        <g
          transform={`translate(${position.x + 4.5} ${
            position.y + 4.5
          }) scale(0.66)`}
          fill={colors.grey60}
        >
          <path
            fill={colors.grey60}
            d="M9 16.2l-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z"
          />
        </g>
      )}
    </>
  );
};

const SubStepPoints = ({ step }: { step: Step }) => {
  if (!step.isExpanded) return null;
  return (
    <>
      {step.subSteps.map((subStep, i) => (
        <circle
          key={subStep.name}
          cx={32 + SUB_STEP_SIZE / 2}
          cy={76 + i * SUB_STEP_HEIGHT}
          r={SUB_STEP_SIZE / 2}
          stroke="#fff"
          strokeWidth="3"
          style={getSubStepStyle(subStep)}
        />
      ))}
    </>
  );
};

const isLastStep = (step: Step) =>
  step.name === 'tags' || step.name === 'fields';

const PathThroughSubSteps = ({ step }: { step: Step }) => (
  <path
    fill="none"
    stroke="#fff"
    style={{ opacity: 0.5 }}
    strokeWidth={LINE_STROKE_WIDTH}
    d={`
      M ${MAIN_STEP_PIE_HEIGHT / 2} ${MAIN_STEP_PIE_HEIGHT}
      v ${24 - ARC_SIZE / 2}
      q 0 ${ARC_SIZE}, ${ARC_SIZE} ${ARC_SIZE}
      h ${HORIZONTAL_INDENTATION - ARC_SIZE - LINE_STROKE_WIDTH / 2},
      q ${ARC_SIZE} 0, ${ARC_SIZE} ${ARC_SIZE}
      v 16
      ${times(step.subSteps.length - 1)
        .map(() => `m 0 15 v 24 `)
        .join('\n')}
      ${
        !isLastStep(step)
          ? `
          m 0 16
          v ${16 - ARC_SIZE / 2}
          q 0 ${ARC_SIZE}, -${ARC_SIZE} ${ARC_SIZE}
          h ${-(HORIZONTAL_INDENTATION - ARC_SIZE - LINE_STROKE_WIDTH / 2)},
          q ${-ARC_SIZE} 0, ${-ARC_SIZE} ${ARC_SIZE}
          v ${HORIZONTAL_INDENTATION - ARC_SIZE / 2}
      `
          : ''
      }
      `}
  />
);

const PathBetweenMainSteps = () => {
  return (
    <path
      fill="none"
      stroke="#fff"
      style={{ opacity: 0.5 }}
      strokeWidth="2"
      d={`
      M ${MAIN_STEP_PIE_HEIGHT / 2} ${MAIN_STEP_PIE_HEIGHT - 1}
      V ${MAIN_STEP_HEIGHT + 3}
      `}
    />
  );
};

const PathFromStep = ({ step, isLast }: { step: Step; isLast: boolean }) => {
  if (!step.isExpanded && isLast) return null;
  if (step.isExpanded) {
    return <PathThroughSubSteps step={step} />;
  }
  return <PathBetweenMainSteps />;
};

const getStepHeight = (step: Step) =>
  MAIN_STEP_HEIGHT +
  (step.isExpanded ? step.subSteps.length : 0) * SUB_STEP_HEIGHT +
  (step.isExpanded && step.subSteps.length > 0 ? 40 : 0);

const StepSvg = ({ step, isLast }: { step: Step; isLast: boolean }) => (
  <StepsSvgWrapper style={{ height: getStepHeight(step) }}>
    <Fragment key={step.name}>
      <BigPie position={{ x: 0, y: 0 }} step={step} />
      <SubStepPoints step={step} />
      <PathFromStep step={step} isLast={isLast} />
    </Fragment>
  </StepsSvgWrapper>
);

export default StepSvg;
