import { ArdoqId, DiffMode } from '@ardoq/api-types';
import { NextSelectionContext, NodeModel } from './types';
import WorkspaceNode from './WorkspaceNode';
import ComponentNode from './ComponentNode';
import ScenarioNode from './ScenarioNode';
import { Features } from '@ardoq/features';
import { logError } from '@ardoq/logging';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { getDOMNodeInNavigatorWithDataId } from '../getDOMNodeInNavigatorWithDataId';
import { NavigatorViewInterface } from '../types';
import { getNavigatorStubViewInterface } from '../getNavigatorStubViewInterface';
import { ScenarioModeState } from 'scope/types';
import { isSameSet } from 'utils/isSameSet';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

const createNode = (
  tree: Tree,
  nodeId: string,
  parent: NodeModel | null = null
): NodeModel => {
  if (tree.navigatorViewInterface.isWorkspace(nodeId))
    return new WorkspaceNode(tree, nodeId, parent);
  return new ComponentNode(tree, nodeId, parent);
};

const getOrCreateNode = (
  tree: Tree,
  nodeId: string,
  parent: NodeModel | null = null
) => {
  if (tree && tree.hasNode(nodeId)) {
    const node = tree.getNode(nodeId);
    node.parent = parent;
    return node;
  }
  const node = createNode(tree, nodeId, parent);
  if (tree) {
    tree.setNode(node);
  }
  return node;
};

const getOrCreateScenarioNode = (
  tree: Tree,
  nodeId: string,
  childrenIds: string[]
): NodeModel => {
  if (tree && tree.hasNode(nodeId)) {
    const node = tree.getNode(nodeId);
    if (
      isSameSet(
        node.getChildren().map(child => child.id),
        childrenIds
      )
    ) {
      return node;
    }
    tree.removeNode(nodeId);
  }
  const node = new ScenarioNode(tree, nodeId, childrenIds);
  tree.setNode(node);
  return node;
};

export const expandNode = (node: NodeModel) => {
  let currentNode = node;
  while (currentNode.parent) {
    currentNode.parent.expand();
    currentNode = currentNode.parent;
  }
};

export default class Tree {
  selectedContext: NodeModel | null;
  activeWorkspace: NodeModel | null;
  navigatorViewInterface: NavigatorViewInterface =
    getNavigatorStubViewInterface();
  activeScenarioState: ScenarioModeState = {
    scenarioId: null,
    isInDiffMode: false,
    activeDiffMode: DiffMode.DIFF,
    isScenarioMode: false,
  };

  private _idNodeMap: Map<string, NodeModel>;
  private _children: NodeModel[];
  private _selection: NodeModel[];
  private _hasChangedSet: Set<NodeModel>;
  private _expiredIds: Set<ArdoqId>;
  private _hiddenNodes: Set<NodeModel>;
  private _filterTerm: string | null = null;

  constructor() {
    this.selectedContext = null;
    this.activeWorkspace = null;
    this._hiddenNodes = new Set();
    this._idNodeMap = new Map<string, NodeModel>();
    this._children = [];
    this._selection = [];
    this._hasChangedSet = new Set<NodeModel>();
    this._expiredIds = new Set<ArdoqId>();
  }
  // Bound instance member so it can be used directly as a callback.
  getNextSelectionCandidate: () => string | null = () => {
    const { nextSelection } = this.getChildren().reduce<NextSelectionContext>(
      (nextSelectionContext, node) =>
        node.getNextSelectionCandidate(nextSelectionContext),
      {
        selection: new Set(this._selection),
        nextSelection: this.getChildren()[0],
        isLastSet: false,
      }
    );
    if (nextSelection instanceof ComponentNode) {
      return nextSelection.id;
    }
    return null;
  };

  reload() {
    this.getChildren().forEach(child => child.reload());
    if (activeScenarioOperations.isInDiffMode(this.activeScenarioState)) {
      this.updateAggregatedVisualDiffState();
    }
  }

  updateAggregatedVisualDiffState() {
    this.getChildren().forEach(child =>
      child.updateAggregatedVisualDiffState()
    );
  }

  // this is an instance method in order to avoid a circular dependency.
  // eslint-disable-next-line @typescript-eslint/class-methods-use-this
  getOrCreateNode(tree: Tree, nodeId: string, parent: NodeModel | null = null) {
    return getOrCreateNode(tree, nodeId, parent);
  }

  setHasChanged(ids?: string[]) {
    if (ids) {
      ids.forEach(id => {
        const node = this._idNodeMap.get(id);
        if (node) {
          this._hasChangedSet.add(node);
          node.hasChanged = true;
        }
      });
    }
  }

  clearHasChanged() {
    this._hasChangedSet.forEach(node => (node.hasChanged = false));
    this._hasChangedSet.clear();
  }

  updateRange(id1: string, id2: string) {
    this.clearSelection();
    const [node1, node2] = [id1, id2].map(id => this._idNodeMap.get(id));
    const rangeBoundaries = new Set<NodeModel>();
    [node1, node2].filter(ExcludeFalsy).forEach(node => {
      rangeBoundaries.add(node);
    });
    if (rangeBoundaries.size === 2) {
      this.clearSelection();
      this.getChildren().reduce(
        (isRangeOpen, child) =>
          child.updateSelectionRange(
            this._selection,
            rangeBoundaries,
            isRangeOpen
          ),
        false
      );
    }
    return this._selection.map(node => node.id);
  }

  onlyContextNodeIsSelected(selectedNodes = this._selection) {
    return selectedNodes.length === 1 && selectedNodes[0].isSelectedContext;
  }

  syncSelection(ids: string[]) {
    this.clearSelection();
    const selectedNodes = ids.map(id => this.getNode(id)).filter(ExcludeFalsy);

    // we don't want the selected appearance when only the context node is selected.
    if (!this.onlyContextNodeIsSelected(selectedNodes)) {
      selectedNodes.forEach(node => (node.isSelected = true));
    }
    this._selection.push(...selectedNodes);
  }

  getSelection() {
    return this._selection.slice();
  }

  clearSelection() {
    this._selection.forEach(node => (node.isSelected = false));
    this._selection.length = 0;
  }

  hasNode(id: string) {
    return this._idNodeMap.has(id);
  }

  getNode(id: string) {
    return this._idNodeMap.get(id)!;
  }

  setNode(node: NodeModel) {
    this._idNodeMap.set(node.id, node);
  }

  getNodeByDomNode(domNode: Element) {
    const id = getDOMNodeInNavigatorWithDataId(domNode);
    return id ? this.getNode(id) : null;
  }

  getChildren() {
    return this._children;
  }

  addWorkspaceNode(refNodeId: string) {
    const node = getOrCreateNode(this, refNodeId, null);
    this._children.push(node);
    return node;
  }

  addScenarioNode(scenarioId: string, refNodeIds: string[]) {
    const node = getOrCreateScenarioNode(this, scenarioId, refNodeIds);
    node.updateChildren();
    this._children.push(node);
    return node;
  }

  removeNode(id: string) {
    const node = this._idNodeMap.get(id);
    if (!node) {
      return;
    }
    if (node.parent && node.parent.getChildren().includes(node)) {
      throw new Error('Trying to remove a node which is still in the tree');
    }
    this._idNodeMap.delete(id);
    const index = this._selection.findIndex(node => node.id === id);
    if (index > -1) {
      this._selection.splice(index, 1);
    }
  }

  syncRootNodes(
    refNodeIds: string[],
    scenarioId = activeScenarioOperations.getActiveScenarioId(
      this.activeScenarioState
    )
  ) {
    this.removeExpiredNodes();
    const currentRootNodeMap = new Set(this._children.map(node => node.id));
    this.setAllExpired();
    this._children.length = 0;

    if (
      this.navigatorViewInterface.hasFeature(Features.SCENARIOS_BETA) &&
      scenarioId
    ) {
      if (scenarioId) {
        const scenarioNode = this.addScenarioNode(scenarioId, refNodeIds);

        this.clearExpiredOfRootNodesAndClearExpired([scenarioNode]);
        const allNodes = Array.from(this._idNodeMap.values());
        if (!allNodes.some(node => node.isCollapsed === false)) {
          scenarioNode.expandDeep();
        }
      } else {
        logError(Error('Scenario not found.'), null, { scenarioId });
      }
    } else {
      const rootNodes = refNodeIds.map(refNodeId =>
        this.addWorkspaceNode(refNodeId)
      );
      rootNodes
        .filter(node => !currentRootNodeMap.has(node.id))
        .forEach(node => node.expand());
      this.clearExpiredOfRootNodesAndClearExpired(rootNodes);
    }
  }

  // this function implements a sort of undocumented interface where Tree | NodeModel are used interchangeably by navigationManager.
  // eslint-disable-next-line @typescript-eslint/class-methods-use-this
  isIncludedInContextByFilter() {
    return true;
  }

  setIsExpired() {
    this.setAllExpired();
  }

  cacheHiddenNodes(hiddenNodes: Set<NodeModel>) {
    this._hiddenNodes = hiddenNodes;
  }

  isNodeHidden(node: NodeModel) {
    return this._hiddenNodes.has(node);
  }

  setFilter(term: string) {
    this._filterTerm = term.toLowerCase();
    Array.from(this._idNodeMap.values())
      .filter(node => node.isIncludedInContextByFilter())
      .forEach(expandNode);
  }

  clearFilter() {
    this._filterTerm = null;
  }

  isIncludedInFilterTerm(filterTerm: string) {
    if (!this._filterTerm) return true;
    return filterTerm.toLowerCase().includes(this._filterTerm);
  }

  private setAllExpired() {
    this._expiredIds = new Set(this._idNodeMap.keys());
  }

  private clearExpiredOfRootNodesAndClearExpired(rootNodes: NodeModel[]) {
    rootNodes.forEach(rootNode => this.clearExpired(rootNode));
    this.removeExpiredNodes();
  }

  private clearExpired(node: NodeModel) {
    this._expiredIds.delete(node.id);
    node.getChildren().forEach(node => this.clearExpired(node));
  }

  private removeExpiredNodes() {
    this._expiredIds.forEach(id => this._idNodeMap.delete(id));
    this._expiredIds.clear();
  }
}
