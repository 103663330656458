import { LabeledDetail } from '../../components/LabeledDetail';
import { DateDecorator, DecoratorPlaceholder } from '@ardoq/decorators';
import { APIOrganizationUser } from '@ardoq/api-types';
import { userOperations } from '@ardoq/core';

type LastLoginSectionProps = {
  user: APIOrganizationUser;
};
export const LastLoginSection = ({ user }: LastLoginSectionProps) => {
  return (
    <LabeledDetail label="Last logged in">
      {userOperations.isServiceAccountUser(user) ? (
        <DecoratorPlaceholder>Not applicable</DecoratorPlaceholder>
      ) : (
        <DateDecorator date={user['last-login']} format="DATETIME" />
      )}
    </LabeledDetail>
  );
};
