import { PropsWithChildren } from 'react';
import { Chevron } from './atoms';

type ChevronDecoratorProperties = { isExpanded?: boolean; hidden?: boolean };
const ChevronDecorator = ({
  children,
  isExpanded,
  hidden = false,
}: PropsWithChildren<ChevronDecoratorProperties>) => {
  return (
    <>
      <Chevron $hidden={hidden} className="material-icons-round">
        {isExpanded ? 'expand_less' : 'expand_more'}
      </Chevron>
      {children}
    </>
  );
};

export default ChevronDecorator;
