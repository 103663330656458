import { ArdoqIconCategory } from '@ardoq/icons';

export const getEmptySelectedNodeData = () => ({
  metaDataComponent: {
    representationData: {
      icon: {
        id: 'none',
        label: 'none',
        representation: '',
        category: ArdoqIconCategory.Ardoq,
      },
      value: '',
      isImage: false,
      color: '',
      shadedColor: '',
      lightenedColor: '',
      contrastColor: '',
    },
    color: '',
    typeId: '',
    label: '',
  },
});
