import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { GroupsState } from './types';
import { getGroups, getGroupsFinished } from './actions';
import { PermissionGroup } from '@ardoq/api-types';
import { groupsOperations } from './groupsOperations';

const defaultState: GroupsState = {
  groups: [],
  isLoading: false,
};

const reducers = [
  reducer<GroupsState>(getGroups, groupsOperations.setLoadingState),
  reducer<GroupsState, PermissionGroup[]>(
    getGroupsFinished,
    groupsOperations.setGroupsStateAfterLoading
  ),
];

const groups$ = persistentReducedStream('groups$', defaultState, reducers);

export default groups$;
