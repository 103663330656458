import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { SubdivisionCreationContextState } from './types';

const NAME_SPACE = '[subdivisionCreationContextEditor]';

export const resetToInitialState = actionCreator(
  `${NAME_SPACE} resetToInitialState`
);

export const setCurrentCreationContext = actionCreator<ArdoqId[]>(
  `${NAME_SPACE} setCurrentCreationContex`
);

export const submitUpdateToTheServer =
  actionCreator<SubdivisionCreationContextState>(
    `${NAME_SPACE} submitUpdateToTheServer`
  );

export const setDifferentSubdivisionCreationContext =
  actionCreator<SubdivisionCreationContextState>(
    `${NAME_SPACE} setDifferentSubdivisionCreationContext`
  );

export const setCreationContextSelection = actionCreator<{
  creationContextSelection: ArdoqId[];
  selectionErrorMessage?: string;
}>(`${NAME_SPACE} setCreationContextSelection`);

export const syncGridEditorCreationContext =
  actionCreator<SubdivisionCreationContextState>(
    `${NAME_SPACE} syncGridEditorCreationContext`
  );
