import currentUser from 'models/currentUser';
import { PersonalSetting } from '@ardoq/api-types';
import { alert } from '@ardoq/modal';
import SuppressDialogContent from 'dialog/SuppressDialogContent';

export const maybeShowEnforceFiltersDialog = async () => {
  if (!currentUser.getPersonalSetting(PersonalSetting.ENFORCE_FILTERS_DIALOG)) {
    alert({
      title: 'Presentation slide',
      subtitle:
        'Workspace filters were added to make sure everything is included in the presentation.',
      text: (
        <p>
          Learn more about this in the{' '}
          <a
            href="https://help.ardoq.com/en/articles/44084-presentation-specific-filters"
            target="_blank"
            rel="noopener noreferrer"
          >
            knowledge base
          </a>
          .
        </p>
      ),
      text2: (
        <SuppressDialogContent
          suppressText="Don't show me this message again"
          setSuppressionPreference={shouldSuppress =>
            currentUser.setPersonalSetting(
              PersonalSetting.ENFORCE_FILTERS_DIALOG,
              shouldSuppress
            )
          }
        />
      ),
    });
  }
};
