import { FunctionComponent } from 'react';
import { SearchPane } from './types';
import MainSearchOverview from '../SearchOverview/MainSearchOverview';
import AdvancedSearch from '../AdvancedSearch/AdvancedSearch';
import GremlinSearch from '../GremlinSearch/GremlinSearch';
import searchTab$ from './searchTab$';
import DynamicSearch from '../DynamicSearch/DynamicSearch';
import CalculatedSearch from '../CalculatedSearch/CalculatedSearch';
import { AqLayout } from '@ardoq/layout';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import { errorRecovery } from './utils';
import { SecondaryButton } from '@ardoq/button';
import { AppModules } from 'appContainer/types';
import { derivedStream, dispatchAction, connect } from '@ardoq/rxbeach';
import { requestShowAppModule } from 'appContainer/actions';
import { map } from 'rxjs';
import { context$ } from 'streams/context/context$';
import { graphSearchAccessControlHelpers } from 'resourcePermissions/accessControlHelpers/graphSearch';
import { UnauthorizedAccessPage } from '@ardoq/manage-resource-permissions';
import DynamicOverview from 'search/SearchOverview/DynamicOverview';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';

interface ViewProps {
  selectedPane: SearchPane;
  canReturnToWorkspace: boolean;
  canCreateGraphFilter: boolean;
}

const NONE_GUARDED_VIEWS: SearchPane[] = [
  SearchPane.SEARCH_OVERVIEW,
  SearchPane.ADVANCED_SEARCH,
];

const SearchView: FunctionComponent<ViewProps> = ({
  selectedPane,
  canReturnToWorkspace,
  canCreateGraphFilter,
}) => {
  if (selectedPane === SearchPane.ADVANCED_SEARCH) return <AdvancedSearch />;
  if (!canCreateGraphFilter && !NONE_GUARDED_VIEWS.includes(selectedPane)) {
    return <UnauthorizedAccessPage />;
  }
  if (selectedPane === SearchPane.GREMLIN) return <GremlinSearch />;

  return (
    <AqLayout
      title="Analytics"
      bodyContentStyle={{
        height: '100%',
        position: 'relative',
        padding: 0,
        paddingBottom: 5,
      }}
      renderHeaderButtons={
        canReturnToWorkspace
          ? () => (
              <SecondaryButton
                onClick={() =>
                  dispatchAction(
                    requestShowAppModule({
                      selectedModule: AppModules.WORKSPACES,
                    })
                  )
                }
              >
                Return to workspace
              </SecondaryButton>
            )
          : undefined
      }
    >
      <ErrorBoundary
        logError={logError}
        errorContextDescription="Search Error Boundary"
        recoverOnError={errorRecovery}
      >
        {selectedPane === SearchPane.SEARCH_OVERVIEW && (
          <MainSearchOverview canCreateGraphFilter={canCreateGraphFilter} />
        )}
        {selectedPane === SearchPane.DYNAMIC_FILTER_OVERVIEW && (
          <DynamicOverview canCreateGraphFilter={canCreateGraphFilter} />
        )}

        {selectedPane === SearchPane.DYNAMIC_FILTER_SEARCH && <DynamicSearch />}

        {selectedPane === SearchPane.CALCULATED_FIELD_SEARCH && (
          <CalculatedSearch shouldHideBackButton />
        )}
      </ErrorBoundary>
    </AqLayout>
  );
};

const searchContainer$ = derivedStream(
  'searchContainer$',
  searchTab$,
  context$,
  currentUserPermissionContext$
).pipe(
  map(([{ selectedPane }, { workspacesIds }, permissionContext]) => ({
    selectedPane,
    canReturnToWorkspace:
      selectedPane === SearchPane.CALCULATED_FIELD_SEARCH &&
      workspacesIds.length > 0,
    canCreateGraphFilter:
      graphSearchAccessControlHelpers.canCreateGraphFilter(permissionContext),
  }))
);

export default connect(SearchView, searchContainer$);
