import { reducedStream, reducer } from '@ardoq/rxbeach';
import {
  ViewpointBuilderNavigationState,
  ViewpointBuilderNavigationViewModel,
} from './types';
import { viewpointBuilderNavigationOperations } from './viewpointBuilderNavigationOperations';
import {
  selectViewpointBuilderContext,
  selectTab,
  setSelectedComponentTypeAndCount,
  setGraphCountsAndComponentTypeRepresentation,
  setFormattingRulesCount,
  setGroupingRulesCount,
  setSavedViewpointDetails,
  setCollapsedPathsCount,
  resetViewpointBuilderNavigation,
  setRequiredComponentsCount,
} from './actions';
import { map, Observable, shareReplay } from 'rxjs';

export const viewpointBuilderNavigation$: Observable<ViewpointBuilderNavigationViewModel> =
  reducedStream<ViewpointBuilderNavigationState>(
    'viewpointBuilderNavigation$',
    viewpointBuilderNavigationOperations.getInitialState(),
    [
      reducer(selectTab, viewpointBuilderNavigationOperations.selectTab),
      reducer(
        selectViewpointBuilderContext,
        viewpointBuilderNavigationOperations.selectNavigationModeAndContext
      ),
      reducer(
        setSelectedComponentTypeAndCount,
        viewpointBuilderNavigationOperations.setComponentTypeAndCount
      ),
      reducer(
        setGraphCountsAndComponentTypeRepresentation,
        viewpointBuilderNavigationOperations.setGraphCountsAndComponentTypeRepresentation
      ),
      reducer(
        setFormattingRulesCount,
        viewpointBuilderNavigationOperations.setFormattingRulesCount
      ),
      reducer(
        setGroupingRulesCount,
        viewpointBuilderNavigationOperations.setGroupingRulesCount // TODO AM use property setter from Sindre
      ),
      reducer(
        setCollapsedPathsCount,
        viewpointBuilderNavigationOperations.setCollapsedPathsCount
      ),
      reducer(
        setRequiredComponentsCount,
        viewpointBuilderNavigationOperations.setRequiredComponentsCount
      ),
      reducer(
        setSavedViewpointDetails,
        viewpointBuilderNavigationOperations.setSavedViewpointDetails
      ),
      reducer(
        resetViewpointBuilderNavigation,
        viewpointBuilderNavigationOperations.resetState
      ),
    ]
  ).pipe(
    map(viewpointBuilderNavigationOperations.composeViewModel),
    shareReplay({ bufferSize: 1, refCount: true })
  );
