import { ReactNode, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { SidebarMenu } from '@ardoq/sidebar-menu';
import { Step, VerticalStepper } from '@ardoq/steppers';
import { BrandButton, ButtonGroup, SecondaryButton } from '@ardoq/button';
import { s16, colors } from '@ardoq/design-tokens';
import { trackEvent } from '../../../tracking/tracking';
import { SurveyTrackingEventsNames } from '../../tracking';
import { getSectionStepState } from '../utils';
import { isPersistedSurvey } from '@ardoq/api-types';
import { Island, PageBody } from '@ardoq/page-layout';
import surveyEditor$ from '../streams/surveyEditor$';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { SurveyEditorSectionKey } from '../streams/types';
import { sectionSelected } from '../streams/actions';
import { MaybePersistedSurvey, SurveyValidation } from 'surveyAdmin/types';
import {
  getSectionHasError,
  getSectionHasWarning,
  getSectionIsDisabled,
  getSectionStatus,
  getSectionSubHeading,
} from './utils';
import { FlexBox, Stack } from '@ardoq/layout';
import { TrialBadge } from '@ardoq/status-ui';
import { Features, hasFeature } from '@ardoq/features';
import {
  SECTIONS_WITH_OWN_RENDERER,
  SURVEY_SECTION_STEPTITLES,
  SURVEY_SECTION_SUBTITLES,
  SURVEY_SECTION_TITLES,
} from '../consts';
import { Text } from '@ardoq/typography';
import { WarningIcon } from '@ardoq/icons';
import { SurveyResponseApprovalTrialExpiredInfo } from './TrialElements/SurveyResponseApprovalTrialExpiredInfo';

const SurveySectionsWrapper = ({ children }: PropsWithChildren) => {
  return <>{children}</>;
};

const Footer = styled.div`
  padding: ${s16};
  background: ${colors.white};
  border-top: 1px solid ${colors.grey90};
`;

const FullHeightStack = styled(Stack)`
  height: 100%;
`;

const getStringStepTitle = (sectionKey: SurveyEditorSectionKey): string => {
  return sectionKey in SURVEY_SECTION_STEPTITLES
    ? (SURVEY_SECTION_STEPTITLES as Record<string, string>)[sectionKey]
    : SURVEY_SECTION_TITLES[sectionKey];
};

const getSectionWarningIcon = (hasWarning: boolean) => {
  if (hasWarning) {
    return <WarningIcon color={colors.iconWarning} />;
  }
};

const getTrialBadge = () => {
  if (!hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL)) return null;
  return <TrialBadge />;
};

const getStepHeadingExtra = (
  key: SurveyEditorSectionKey,
  hasWarning: boolean
) => {
  if (key === 'changeApprovalSection') {
    return (
      <>
        {getSectionWarningIcon(hasWarning)}
        {getTrialBadge()}
      </>
    );
  }
  return getSectionWarningIcon(hasWarning);
};

const getStepHeading = (
  title: string,
  key: SurveyEditorSectionKey,
  hasWarning: boolean
) => {
  return (
    <FlexBox justify="space-between" paddingRight="small">
      {title}
      {getStepHeadingExtra(key, hasWarning)}
    </FlexBox>
  );
};

export const getSubTitle = (
  key: SurveyEditorSectionKey
): string | undefined => {
  if (key === 'surveySection') return undefined;
  if (key === 'changeApprovalSection') {
    return `${SURVEY_SECTION_SUBTITLES[key]}${
      hasFeature(Features.PERMISSION_ZONES_INTERNAL)
        ? ' or with the permission zones awareness feature.'
        : '.'
    }`;
  }
  return SURVEY_SECTION_SUBTITLES[key];
};

export const getRenderTitle = (
  key: SurveyEditorSectionKey
): JSX.Element | string => {
  if (key === 'changeApprovalSection') {
    return (
      <FlexBox gap="small">
        Response approval
        <Text color="textSubtle">(optional)</Text>
      </FlexBox>
    );
  }
  return SURVEY_SECTION_TITLES[key];
};

const getPreviousSectionData = (
  validSections: SurveyEditorSectionKey[],
  selectedSectionIndex: number
) => {
  if (selectedSectionIndex <= 0) return null;
  const isPreviousSectionDisabled = getSectionIsDisabled(
    validSections[selectedSectionIndex - 1]
  );
  if (isPreviousSectionDisabled) {
    const previousSectionIndex = selectedSectionIndex - 2;
    if (previousSectionIndex <= 0) return null;
    const previousSectionKey = validSections[previousSectionIndex];
    return { index: previousSectionIndex, key: previousSectionKey };
  }
  return {
    index: selectedSectionIndex - 1,
    key: validSections[selectedSectionIndex - 1],
  };
};

const getNextSectionData = (
  validSections: SurveyEditorSectionKey[],
  selectedSectionIndex: number
) => {
  if (selectedSectionIndex >= validSections.length - 1) return null;
  const isNextSectionDisabled = getSectionIsDisabled(
    validSections[selectedSectionIndex + 1]
  );
  if (isNextSectionDisabled) {
    const nextSectionIndex = selectedSectionIndex + 2;
    if (nextSectionIndex >= validSections.length - 1) return null;
    const nextSectionKey = validSections[nextSectionIndex];
    return { index: nextSectionIndex, key: nextSectionKey };
  }
  return {
    index: selectedSectionIndex + 1,
    key: validSections[selectedSectionIndex + 1],
  };
};

type ConnectedProps = {
  validSections: SurveyEditorSectionKey[];
  selectedSectionIndex: number;
  surveyValidation: SurveyValidation | undefined;
  surveyAttributes: MaybePersistedSurvey;
};

type SurveyEditorLayoutProps = {
  header?: ReactNode;
  isOrgAdmin: boolean;
} & ConnectedProps;

const SurveyEditorLayout = ({
  children,
  header,
  validSections,
  selectedSectionIndex,
  surveyValidation,
  surveyAttributes,
  isOrgAdmin,
}: PropsWithChildren<SurveyEditorLayoutProps>) => {
  const selectedSectionKey = validSections[selectedSectionIndex];

  const previousSectionData = getPreviousSectionData(
    validSections,
    selectedSectionIndex
  );

  const nextSectionData = getNextSectionData(
    validSections,
    selectedSectionIndex
  );

  const Wrapper = SECTIONS_WITH_OWN_RENDERER.has(selectedSectionKey)
    ? SurveySectionsWrapper
    : Island;

  return (
    <PageBody
      alignIslandsToLeft
      leftContent={
        <SidebarMenu isPageBodyMenu>
          <FullHeightStack justify="space-between">
            <VerticalStepper>
              {validSections.map((key, index) => {
                const sectionStatus = getSectionStatus(key, surveyAttributes);
                const hasError = getSectionHasError(key, surveyValidation);
                const isDisabled = getSectionIsDisabled(key);
                const hasWarning = getSectionHasWarning(key, surveyValidation);
                const title = getStringStepTitle(key);
                return (
                  <Step
                    key={index}
                    heading={getStepHeading(title, key, hasWarning)}
                    subheading={getSectionSubHeading(key, isDisabled)}
                    isSelected={index === selectedSectionIndex}
                    onPress={() => {
                      dispatchAction(sectionSelected(index));
                      trackEvent(
                        SurveyTrackingEventsNames.CLICKED_ON_HIERARCHY_ELEMENT_IN_SURVEY_EDITOR,
                        { section: getStringStepTitle(key) }
                      );
                    }}
                    isDisabled={isDisabled}
                    state={getSectionStepState(
                      sectionStatus,
                      hasError ?? false,
                      isPersistedSurvey(surveyAttributes)
                    )}
                  />
                );
              })}
            </VerticalStepper>
            {isOrgAdmin &&
              hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL_EXPIRED) && (
                <SurveyResponseApprovalTrialExpiredInfo />
              )}
          </FullHeightStack>
        </SidebarMenu>
      }
      footerContent={
        <Footer>
          <ButtonGroup $alignToRight>
            {previousSectionData?.key && (
              <SecondaryButton
                onClick={() => {
                  dispatchAction(sectionSelected(previousSectionData.index));
                  trackEvent(
                    SurveyTrackingEventsNames.CLICKED_ON_NAVIGATIONAL_BUTTON_IN_SURVEY_EDITOR,
                    {
                      section: getStringStepTitle(previousSectionData.key),
                      direction: 'previous',
                    }
                  );
                }}
                dataTestId="previous-button"
              >
                {`Back: ${getStringStepTitle(previousSectionData.key)}`}
              </SecondaryButton>
            )}
            {nextSectionData?.key && (
              <BrandButton
                dataTestId="next-button"
                onClick={() => {
                  trackEvent(
                    SurveyTrackingEventsNames.CLICKED_ON_NAVIGATIONAL_BUTTON_IN_SURVEY_EDITOR,
                    {
                      section: getStringStepTitle(nextSectionData.key),
                      direction: 'next',
                    }
                  );
                  dispatchAction(sectionSelected(nextSectionData.index));
                }}
              >
                {`Next: ${getStringStepTitle(nextSectionData.key)}`}
              </BrandButton>
            )}
          </ButtonGroup>
        </Footer>
      }
    >
      {header}
      <Wrapper
        title={getRenderTitle(selectedSectionKey)}
        subtitle={getSubTitle(selectedSectionKey)}
        preventTitleIconChange
      >
        {children}
      </Wrapper>
    </PageBody>
  );
};

export default connect(
  SurveyEditorLayout,
  surveyEditor$.pipe(
    map(
      ({
        validSections,
        selectedSectionIndex,
        surveyValidation,
        surveyAttributes,
      }) => {
        return {
          validSections,
          selectedSectionIndex,
          surveyValidation,
          surveyAttributes,
        };
      }
    )
  )
);
