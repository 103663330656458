import { ITPediaConnection } from '@ardoq/api-types/integrations';

const API_URL = '/api/integrations/account/itpedia';

export async function upsertAccountRequest(input: ITPediaConnection) {
  const _id = input._id;
  const body = {
    name: input.name,
    accessKey: input.accessKey,
    secretKey: input.secretKey,
    provider: 'itpedia',
    authMethod: 'basic',
    baseUrl: `https://${input.baseUrl}`,
    providerParams: {},
  };
  const request = await fetch(`${API_URL}/${_id ?? ''}`, {
    method: _id ? 'put' : 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const response = await request.json();
  if (request.status >= 400) throw response.message;
  return response as ITPediaConnection;
}

export async function deleteAccountRequest(input: ITPediaConnection) {
  const body = {
    _id: input._id,
    name: input.name,
    accessKey: input.accessKey,
    secretKey: input.secretKey,
    provider: 'itpedia',
    authMethod: 'basic',
    baseUrl: input.baseUrl,
    providerParams: {},
  };
  const request = await fetch(`${API_URL}/${input._id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (request.status >= 400) throw 'Something went wrong';
  return body;
}

export async function fetchAccountsList() {
  const request = await fetch(API_URL);
  const accounts = (await request.json()) as ITPediaConnection[];
  return accounts;
}
