import { actionCreator } from '@ardoq/rxbeach';
import {
  APIError,
  FetchAuditLogDataPayload,
  SetAuditLogFilterPayload,
} from './types';
import { LoadingState } from './consts';
import { SelectOption } from '@ardoq/select';
import {
  APIComponentAttributes,
  APIReferenceAttributes,
  ArdoqId,
  AuditLogAPIResponse,
  AuditLogEntityType,
} from '@ardoq/api-types';

const addPrefix = (actionName: string): `[${string}] ${string}` =>
  `[auditLog] ${actionName}`;

export const fetchAuditLogData = actionCreator<FetchAuditLogDataPayload>(
  addPrefix('fetchAuditLogData')
);

export const toggleIsFilterSidebarOpen = actionCreator<boolean>(
  addPrefix('toggleIsFilterSidebarOpen')
);

export const setAuditLogFilter = actionCreator<SetAuditLogFilterPayload>(
  addPrefix('setAuditLogFilter')
);

export const setChangeTypeFilterGroups = actionCreator<
  Array<AuditLogEntityType>
>(addPrefix('setChangeTypeFilterGroups'));

export const setChangeTypeFilterSpecifics = actionCreator<{
  entityType: AuditLogEntityType;
  specifics: Array<SelectOption<string> | string> | null;
}>(addPrefix('setChangeTypeFilterSpecifics'));

export const setAuditLogData = actionCreator<AuditLogAPIResponse>(
  addPrefix('setAuditLogData')
);

export const clearAllFilters = actionCreator(addPrefix('clearAllFilters'));

export const setLoadingState = actionCreator<LoadingState>(
  addPrefix('setLoadingState')
);
export const setAuditLogPageSize = actionCreator<number>(
  addPrefix('setAuditLogPageSize')
);

export const fetchPreviousPage = actionCreator(addPrefix('fetchPreviousPage'));
export const fetchNextPage = actionCreator(addPrefix('fetchNextPage'));

export const setIsRowExpanded = actionCreator<string>(
  addPrefix('setIsRowExpanded')
);

export const setAreAllRowsExpanded = actionCreator<boolean>(
  addPrefix('setAreAllRowsExpanded')
);

// APIError actions
export const setAPIError = actionCreator<APIError>(addPrefix('setAPIError'));
export const resetAPIErrors = actionCreator(addPrefix('resetAPIErrors'));

export type RestoreChangePayload = {
  entityId: string;
  fieldName: keyof APIComponentAttributes | keyof APIReferenceAttributes;
  restorableValue:
    | APIComponentAttributes[keyof APIComponentAttributes]
    | APIReferenceAttributes[keyof APIReferenceAttributes];
  errorTraceId?: string;
};

export const restoreChangeComponent = actionCreator<RestoreChangePayload>(
  addPrefix('restoreChangeComponent')
);

export const restoreChangeReference = actionCreator<RestoreChangePayload>(
  addPrefix('restoreChangeReference')
);

export const restoreChangeSuccess = actionCreator<RestoreChangePayload>(
  addPrefix('restoreChangeSuccess')
);

export const restoreChangeError = actionCreator<RestoreChangePayload>(
  addPrefix('restoreChangeError')
);

export const restoreComponentRequested = actionCreator<ArdoqId>(
  addPrefix('restoreComponentRequested')
);

export const restoreReferenceRequested = actionCreator<ArdoqId>(
  addPrefix('restoreReferenceRequested')
);
