import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { switchMap, tap } from 'rxjs/operators';
import { getAudiencePreview$ } from 'broadcasts/routineUtils';
import {
  fetchAudiencePreview,
  fetchAudiencePreviewError,
  setAudiencePreview,
} from './actions';
import { logError } from '@ardoq/logging';
import { handleError } from '@ardoq/api';

const handleFetchAudiencePreview = routine(
  ofType(fetchAudiencePreview),
  extractPayload(),
  switchMap(getAudiencePreview$),
  handleError(error => {
    logError(error, 'Failed to fetch broadcast audience preview');
    dispatchAction(fetchAudiencePreviewError());
  }),
  tap(response => dispatchAction(setAudiencePreview(response)))
);
export default handleFetchAudiencePreview;
