import { connect } from '@ardoq/rxbeach';
import { StickyFooter as StickyFooterComponent } from 'integrations/common/components/stickyFooter/StickyFooter';
import { combineLatest, map, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import {
  FooterButtonParams,
  getFooterButtons,
} from 'integrations/common/utils/stickyFooter';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTabularMappingErrorsStream } from 'integrations/common/streams/tabularMappingErrors/getTabularMappingErrorsStream';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { getScheduleStream } from 'integrations/common/streams/schedule/schedule$';

const StickyFooterContainer = (params: FooterButtonParams) => {
  return <StickyFooterComponent buttons={getFooterButtons(params)} />;
};

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      activeIntegration: getActiveIntegrationStream(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
      tabularMappingErrors: getTabularMappingErrorsStream(integrationId),
      transferState: getTransferStateStream(integrationId),
      scheduleState: getScheduleStream(integrationId),
    }).pipe(
      map(
        ({
          integrationId,
          activeIntegration: { integrationPath },
          tabularMapping,
          tabularMappingErrors,
          transferState,
          scheduleState,
        }) => ({
          integrationId,
          integrationPath,
          tabularMapping,
          tabularMappingErrors,
          transferState,
          scheduleState,
        })
      )
    );
  })
);

export const StickyFooter = connect(StickyFooterContainer, viewModel$);
