import { Step } from '@ardoq/steppers';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { isTransferStepAvailable } from './utils';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { getScheduleStream } from 'integrations/common/streams/schedule/schedule$';
import {
  getScheduleStepState,
  getScheduleStepTitle,
} from 'integrations/common/utils/step';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getDataLoadingStream } from 'integrations/unified/streams/dataLoading/dataLoading$';
import { getResourcesStream } from 'integrations/unified/streams/resources/resources$';
import { ImportRoute } from 'integrations/common/navigation/types';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';

const STEP_PATH = ImportRoute.IMPORT_AND_SCHEDULE;

const handleStepClick = (integrationId: IntegrationId) => () => {
  dispatchAction(
    navigateToPath({
      integrationId,
      path: STEP_PATH,
      source: 'stepper',
    })
  );
};

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId => {
    return combineLatest({
      activeIntegration: getActiveIntegrationStream(integrationId),
      schedule: getScheduleStream(integrationId),
      transferState: getTransferStateStream(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
      dataLoading: getDataLoadingStream(integrationId),
      resources: getResourcesStream(integrationId),
    });
  }),
  map(
    ({
      activeIntegration,
      schedule,
      transferState,
      tabularMapping,
      dataLoading,
      resources,
    }) => {
      const { integrationPath, integrationId } = activeIntegration;
      return {
        isDisabled: !isTransferStepAvailable({
          transferState,
          tabularMapping,
          dataLoading,
          selectedResources: resources.selectedResources,
        }),
        onPress: handleStepClick(integrationId),
        isSelected: integrationPath === STEP_PATH,
        heading: getScheduleStepTitle(schedule.scheduleStage),
        state: getScheduleStepState({ transferState, schedule }),
        stepNumber: 4,
      };
    }
  )
);

export const ImportAndScheduleStep = connect(Step, viewModel$);
