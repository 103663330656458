import { connect } from '@ardoq/rxbeach';
import {
  SidebarMenu,
  SidebarMenuMenu,
  SidebarMenuSection,
} from '@ardoq/sidebar-menu';
import { componentOptions$ } from './componentOptions$';
import { ComponentOptionsProps } from './types';

const ComponentOptions = (props: ComponentOptionsProps) => {
  if (props.mode === 'error') {
    return <SidebarMenu emptyContentMessage={props.message} />;
  }

  const { actionOptions, editComponentOptions } = props;
  // When user has no access to edit component, but has access to edit zones, we show a limited menu
  return (
    <SidebarMenu>
      <SidebarMenuSection title="Edit component" isCollapsible={false}>
        <SidebarMenuMenu options={editComponentOptions} />
      </SidebarMenuSection>
      {actionOptions.length > 0 && (
        <SidebarMenuSection title="Actions" isCollapsible={false}>
          <SidebarMenuMenu options={actionOptions} />
        </SidebarMenuSection>
      )}
    </SidebarMenu>
  );
};
export default connect(ComponentOptions, componentOptions$);
