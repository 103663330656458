import { DataGovernanceFeatures, FeatureSettingsCommands } from './types';
import { IconName } from '@ardoq/icons';
import { FeatureToggle } from './FeatureToggle';
import SurveyChangeApprovalTrialImage from './images/surveyChangeApprovalTrial.png';
import { Island } from '@ardoq/page-layout';
import { Features, hasFeature } from '@ardoq/features';
import { hasPrivilege } from '@ardoq/privileges';
import { PrivilegeLabel } from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';
import { showSupport } from 'utils/support';
import { Stack } from '@ardoq/layout';
import { Text } from '@ardoq/typography';
import { SmallSecondaryButton } from '@ardoq/button';

const getHelperText = () => {
  if (!hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL_EXPIRED)) return;
  return 'Trial period has ended. Want to continue maintaining data accuracy with this feature? Contact us to learn more.';
};

const DiscoverRequiredText = () => {
  return (
    <Stack gap="small">
      <Text variant="text2Bold">
        This feature requires access to Ardoq Discover. Contact us to discuss
        getting access to Ardoq Discover and the response approval feature.
      </Text>
      <div>
        <SmallSecondaryButton
          onClick={() => {
            trackEvent(
              'Survey change approval: clicked get Discover contact button'
            );
            showSupport({
              chat: true,
              prePopulateMessage:
                'I am interested in trying the response approval feature, but I need access to Ardoq Discover. How can I get this enabled for my organization?',
            });
          }}
        >
          Contact us
        </SmallSecondaryButton>
      </div>
    </Stack>
  );
};

export const DataGovernanceFeatureSection = ({
  isUpdating,
  dataGovernanceFeatures,
  commands,
}: {
  isUpdating: boolean;
  dataGovernanceFeatures: DataGovernanceFeatures;
  commands: FeatureSettingsCommands;
}) => {
  const { surveyChangeApprovalTrial } = dataGovernanceFeatures;

  const features = [
    {
      name: 'Response approval',
      description: (
        <Stack gap="small">
          <Text>
            Democratize your data governance with our new survey response
            approval workflows. Empower data owners and experts to review survey
            responses for better data accuracy.
          </Text>
          {!hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER) && (
            <DiscoverRequiredText />
          )}
        </Stack>
      ),
      isBeta: false,
      isTrial: true,
      feature: surveyChangeApprovalTrial,
      image: SurveyChangeApprovalTrialImage,
      helperText: getHelperText(),
    },
  ];

  const shouldHideIsland = features.every(
    featureInfo => featureInfo.feature.isHidden
  );

  return shouldHideIsland ? null : (
    <Island
      title="Improved data governance"
      iconName={isUpdating ? IconName.SPINNER : undefined}
      subtitle={
        <p>
          Engage a wider audience while controlling the quality of data added to
          your organization
        </p>
      }
      subtitleWidth="large"
    >
      {features.map(
        ({
          name,
          description,
          isBeta,
          isTrial,
          feature,
          image,
          helperText,
        }) => {
          if (feature.isHidden) return null;
          return (
            <FeatureToggle
              key={feature.persistedId}
              name={name}
              description={description}
              isChecked={feature.isChecked}
              isDisabled={feature.isDisabled}
              helperText={helperText}
              isBeta={isBeta}
              isTrial={isTrial}
              onChange={isChecked => {
                trackEvent('Data governance: toggled feature', {
                  feature: feature.persistedId,
                  value: isChecked,
                });
                if (
                  feature.persistedId ===
                  Features.SURVEYS_CHANGE_APPROVAL_V2.persistedId
                ) {
                  commands.setFeatureValue({
                    featureIds: [
                      feature.persistedId,
                      Features.SURVEYS_CHANGE_APPROVAL_TRIAL.persistedId,
                    ],
                    value: isChecked,
                  });
                  return;
                }
                commands.setFeatureValue({
                  featureIds: [feature.persistedId],
                  value: isChecked,
                });
              }}
              imageSrc={image}
            />
          );
        }
      )}
    </Island>
  );
};
