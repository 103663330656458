import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { map, switchMap, combineLatest } from 'rxjs';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest([
      getTabularMappingStream(integrationId),
      getActiveIntegrationStream(integrationId),
      getTablePreviewsStream(integrationId),
    ])
  ),
  map(([tabularMapping, { currentTableId }, tablePreview]) => {
    if (currentTableId) {
      return {
        columnFilters: tabularMapping[currentTableId]?.columnFilters ?? {},
        transformations: tabularMapping[currentTableId]?.transformations ?? {
          column: [],
        },
        sourceFieldNames: tablePreview[currentTableId]?.sourceFieldNames ?? [],
        columnHeaders:
          tablePreview[currentTableId]?.previewRows?.header.slice(1) ?? [],
      };
    }
    return {
      columnFilters: {},
    };
  })
);
