import { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { APIEntityType, ArdoqId, Verb } from '@ardoq/api-types';
import {
  DataSourceItem,
  DiffTableRowType,
} from 'components/DiffMergeTable/types';
import { Branch } from 'components/DiffMergeTable/Branch';
import sectionHeaderRenderer from '../sectionHeaderRenderer';
import {
  EnhancedScopeDataWithBranchName,
  EntityRepresentation,
  EntityRepresentationProps,
  Graphics,
} from '@ardoq/renderers';

const DEFAULT_REPRESENTATION_MAX_WIDTH = 300;
const FitContent = styled.div`
  width: fit-content;
`;

const EntityRepresentationWithSize = ({
  entityId,
  parentEntityId,
  entityType,
  enhancedScopeData,
  graphics,
  index,
  verb,
}: EntityRepresentationProps) => {
  const [initialComponentWidth, setInitialComponentWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;
    const { width } = ref.current.getBoundingClientRect();
    setInitialComponentWidth(width);
  }, [ref]);

  return (
    <FitContent ref={ref}>
      <EntityRepresentation
        index={index}
        entityId={entityId}
        parentEntityId={parentEntityId}
        entityType={entityType}
        enhancedScopeData={enhancedScopeData}
        graphics={graphics}
        hasLabelEllipsis={
          initialComponentWidth > DEFAULT_REPRESENTATION_MAX_WIDTH
        }
        shouldHaveWrapper={
          entityType === APIEntityType.REFERENCE_TYPE ||
          entityType === APIEntityType.COMPONENT
        }
        verb={verb}
      />
    </FitContent>
  );
};

export const entityRepresentationRenderer = (
  entityId: ArdoqId,
  parentEntityId: ArdoqId | undefined,
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeDataWithBranchName,
  graphics: Graphics,
  index?: number,
  verb?: Verb
) => (
  <EntityRepresentationWithSize
    entityId={entityId}
    entityType={entityType}
    parentEntityId={parentEntityId}
    enhancedScopeData={enhancedScopeData}
    graphics={graphics}
    index={index}
    verb={verb}
  />
);

export const getEntityRepresentationRenderer =
  (
    entityType: APIEntityType,
    sourceBranch: Branch,
    graphics: Graphics,
    verb: Verb
  ) =>
  (_: string, dataSourceRow: DataSourceItem) => {
    const {
      entityId,
      parentEntityId,
      enhancedDiffContextData: { [sourceBranch]: enhancedScopeData },
      rowType,
      index,
    } = dataSourceRow;

    if (rowType === DiffTableRowType.SECTION_HEADER_ROW) {
      return sectionHeaderRenderer(dataSourceRow);
    }

    return (
      <EntityRepresentation
        entityId={entityId}
        parentEntityId={parentEntityId}
        entityType={entityType}
        enhancedScopeData={enhancedScopeData}
        graphics={graphics}
        shouldHaveWrapper={
          entityType === APIEntityType.REFERENCE_TYPE ||
          entityType === APIEntityType.COMPONENT
        }
        index={index}
        verb={verb}
      />
    );
  };
