import { SetStateAction, Dispatch, useMemo, useState } from 'react';
import {
  GlobalFieldAttributes,
  globalFields$,
} from 'streams/fields/globalFields$';
import { map, withLatestFrom } from 'rxjs/operators';
import { DatasourceTable, FlyWheelTable } from '@ardoq/table';
import styled from 'styled-components';
import { ArdoqId, SortOrder, StoredQueryModel } from '@ardoq/api-types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { Pagination, PaginationController } from '@ardoq/pagination';
import { Icon, IconName } from '@ardoq/icons';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { deleteGlobalField } from './actions';
import ListWorkspacesPopover from './ListWorkspacesPopover';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { colors, s24, s32 } from '@ardoq/design-tokens';
import storedQueries$ from 'streams/queries/storedQueries$';
import { loadStoredQuery } from 'search/actions';
import { QueryEditorNamespace } from 'search/QueryEditor/queryEditor$';
import { fetchCalculatedFieldOptions } from 'streams/queries/calculatedFieldOptions/actions';
import { IconButton } from '@ardoq/button';
import CopyToClipboard from './CopyToClipboard';
import ChipFilterBar from './ChipFilterBar';
import { SearchInput } from '@ardoq/forms';
import { Space } from '@ardoq/style-helpers';
import { pick } from 'lodash';
import { MatchGroups, findMatchGroups } from '@ardoq/common-helpers';
import { getCurrentLocale, Locale } from '@ardoq/locale';
import { MatchGroupsRenderer } from '@ardoq/renderers';

type FieldRow = Pick<
  GlobalFieldAttributes,
  'name' | 'label' | '_id' | 'allFieldIdsWithSameName' | 'templateIds'
> & {
  type: string;
  workspaces: { id: ArdoqId; name: string }[];
  matchGroups?: MatchGroups;
  storedQuery: StoredQueryModel | null;
  isCalculatedField: boolean;
};
interface StreamedProps {
  fieldRows: FieldRow[];
}
interface ViewProps {
  onClickWorkspace: (workspaceId: ArdoqId) => void;
  setIsEditMode: (isEditMode: boolean) => void;
  calculatedToggleState: [boolean, Dispatch<SetStateAction<boolean>>];
  canCreateGraphFilter: boolean;
}

const TABLE_PADDING = '65px';
const cellStyle = { paddingRight: s24 };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  max-height: 1500px;
`;

const EllipsisOverflowText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 130px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${s24};
  margin-bottom: ${s32};
  padding-left: ${TABLE_PADDING};
`;

const ChipFilterBarContainer = styled.div`
  padding-right: ${TABLE_PADDING};
`;

const KnowledgeBaseLinkContainer = styled.a`
  margin-left: 8px;
  color: ${colors.grey35};
`;

const TableWrapper = styled.div`
  overflow-y: scroll;
  position: relative;
  height: 100%;
`;

const Footer = styled.div`
  display: flex;
  position: relative;
  min-height: 60px;
  justify-content: center;
`;

const EmptyTableText = styled.h4`
  text-align: center;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const globalFieldAttrToFieldRow = (
  field: GlobalFieldAttributes,
  storedQueriesById: Record<ArdoqId, StoredQueryModel>
): FieldRow => {
  const { calculatedFieldSettings } = field;
  const storedQueryId = calculatedFieldSettings?.storedQueryId;
  const isCalculatedField = storedQueryId !== undefined;
  const storedQuery = storedQueryId ? storedQueriesById[storedQueryId] : null;
  return {
    ...pick(
      field,
      'name',
      'label',
      'allFieldIdsWithSameName',
      '_id',
      'templateIds'
    ),
    type: `${field.type}${isCalculatedField ? ' (calculated)' : ''}`,
    isCalculatedField,
    workspaces: Array.from(field.workspaceIds).map(wsId => ({
      id: wsId,
      name: workspaceInterface.getAttribute(wsId, 'name') ?? '',
    })),
    storedQuery,
  };
};

const filterRows = (
  fieldRows: FieldRow[],
  searchPhrase: string,
  locale: Locale
): FieldRow[] => {
  if (!searchPhrase) return fieldRows;

  return fieldRows.reduce((acc: FieldRow[], row) => {
    const matchGroups = findMatchGroups(row.label ?? '', searchPhrase, locale);

    if (matchGroups.match) acc.push({ ...row, matchGroups });

    return acc;
  }, []);
};

const GlobalFieldManager = ({
  fieldRows,
  onClickWorkspace,
  setIsEditMode,
  calculatedToggleState,
  canCreateGraphFilter,
}: ViewProps & StreamedProps) => {
  const [isShowingOnlyCalculated, setIsShowingOnlyCalculated] =
    calculatedToggleState;
  const [searchPhrase, setSearchPhrase] = useState('');

  const locale = getCurrentLocale();

  const filteredRows = useMemo(
    () =>
      filterRows(
        isShowingOnlyCalculated
          ? fieldRows.filter(row => row.isCalculatedField)
          : fieldRows,
        searchPhrase,
        locale
      ),
    [fieldRows, isShowingOnlyCalculated, searchPhrase, locale]
  );

  return (
    <Wrapper>
      <Header>
        <Space $align="center">
          <SearchInput
            placeholder={`Type to filter (${fieldRows.length} fields)`}
            value={searchPhrase}
            onValueChange={setSearchPhrase}
          />
          <KnowledgeBaseLinkContainer
            href={KnowledgeBaseLink.ADD_AND_MANAGE_FIELDS}
            target="_blank noopener noreferrer"
          >
            <Icon iconName={IconName.KNOWLEDGE_BASE} />
          </KnowledgeBaseLinkContainer>
        </Space>
        <ChipFilterBarContainer>
          <ChipFilterBar
            isFiltered={isShowingOnlyCalculated}
            setIsFiltered={setIsShowingOnlyCalculated}
          />
        </ChipFilterBarContainer>
      </Header>

      <PaginationController
        defaultSortById="label"
        defaultSortOrder={SortOrder.ASC}
        dataSource={filteredRows}
        perPage={10}
        render={({
          currentPageDataSource,
          sortById,
          sortOrder,
          sortBy,
          perPage,
          totalResults,
          onPageSelect,
          currentPageNumber,
        }) => (
          <>
            <TableWrapper>
              <DatasourceTable
                dataSource={currentPageDataSource}
                components={FlyWheelTable}
                fixedHeader
                scrollableSectionHeight="100%"
                renderEmptyTable={() => (
                  <EmptyTableText>
                    There are no fields
                    {searchPhrase && ` matching "${searchPhrase}"`}
                  </EmptyTableText>
                )}
                columns={[
                  {
                    headerStyle: {
                      paddingLeft: TABLE_PADDING,
                      paddingRight: cellStyle.paddingRight,
                      width: '400px',
                    },
                    cellStyle: {
                      paddingLeft: TABLE_PADDING,
                      paddingRight: cellStyle.paddingRight,
                    },
                    title: 'Label',
                    dataIndex: 'label',
                    sortOrder: sortById === 'label' && sortOrder,
                    onHeaderClick: () => sortBy('label'),
                    valueRender: (name: string, { matchGroups }) => (
                      <MatchGroupsRenderer
                        matchGroups={matchGroups}
                        defaultValue={name}
                      />
                    ),
                  },
                  {
                    title: 'API key',
                    dataIndex: 'name',
                    sortOrder: sortById === 'name' && sortOrder,
                    onHeaderClick: () => sortBy('name'),
                    headerStyle: {
                      width: '400px',
                    },
                    cellStyle: {},
                    valueRender: (name: string) => (
                      <CopyToClipboard
                        content={name}
                        tooltipText="Click to copy API key"
                      >
                        <EllipsisOverflowText>{name}</EllipsisOverflowText>
                      </CopyToClipboard>
                    ),
                  },
                  {
                    title: 'Type',
                    dataIndex: 'type',
                    sortOrder: sortById === 'type' && sortOrder,
                    onHeaderClick: () => sortBy('type'),
                    cellStyle,
                    headerStyle: {
                      width: '200px',
                      paddingRight: cellStyle.paddingRight,
                    },
                  },
                  {
                    title: 'Usage',
                    sortOrder: sortById === 'usage' && sortOrder,
                    headerStyle: {
                      width: '180px',
                      paddingRight: cellStyle.paddingRight,
                    },
                    cellStyle,
                    onHeaderClick: () =>
                      sortBy(
                        (field: FieldRow) => String(field.workspaces.length),
                        'usage'
                      ),
                    valueRender: (_, field: FieldRow) => {
                      return (
                        <ListWorkspacesPopover
                          onClickWorkspace={onClickWorkspace}
                          workspaces={field.workspaces}
                          numTemplates={field.templateIds.size}
                        />
                      );
                    },
                  },
                  {
                    title: '',
                    cellStyle: { paddingRight: TABLE_PADDING },
                    headerStyle: {
                      width: '130px',
                      paddingRight: TABLE_PADDING,
                    },
                    valueRender: (
                      _,
                      {
                        isCalculatedField,
                        storedQuery,
                        _id,
                        label,
                        allFieldIdsWithSameName,
                      }: FieldRow
                    ) => (
                      <ActionButtonContainer>
                        {isCalculatedField && canCreateGraphFilter && (
                          <IconButton
                            iconName={IconName.EDIT}
                            onClick={() => {
                              dispatchAction(
                                loadStoredQuery({
                                  storedQuery: storedQuery!,
                                }),
                                QueryEditorNamespace.CALCULATED_FIELD_QUERY
                              );
                              dispatchAction(
                                fetchCalculatedFieldOptions({
                                  fieldId: _id,
                                })
                              );
                              setIsEditMode(true);
                            }}
                          />
                        )}
                        <IconButton
                          iconName={IconName.DELETE}
                          onClick={() =>
                            dispatchAction(
                              deleteGlobalField({
                                label: label,
                                ids: [...allFieldIdsWithSameName],
                              })
                            )
                          }
                        />
                      </ActionButtonContainer>
                    ),
                  },
                ]}
              />
            </TableWrapper>
            <Footer>
              {perPage < totalResults && (
                <Pagination
                  style={{ justifyContent: 'center', padding: '32px 0' }}
                  currentPageNumber={currentPageNumber}
                  perPage={perPage}
                  totalResults={totalResults}
                  onPageSelect={onPageSelect}
                />
              )}
            </Footer>
          </>
        )}
      />
    </Wrapper>
  );
};

export default connect(
  GlobalFieldManager,
  globalFields$.pipe(
    withLatestFrom(storedQueries$),
    map(([fieldsByName, { storedQueriesById }]) => ({
      fieldRows: Object.values(fieldsByName).map(field =>
        globalFieldAttrToFieldRow(field, storedQueriesById)
      ),
    }))
  )
);
