import { AssetType, UserAssetPermission } from '@ardoq/api-types';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { optionsHelpers } from './optionsHelpers';
import { assetTypeToResourceType } from 'resourcePermissions/utils';
import { SingleResourcePermission } from '@ardoq/manage-resource-permissions';

const getAssetName = (userAccessPermission: UserAssetPermission): string => {
  return userAccessPermission.anemicEntity.name;
};

const getAssetOption = (userAccessPermission: UserAssetPermission) => {
  return optionsHelpers.assetTypeToOption(
    userAccessPermission.anemicEntity.ardoq.entityType
  );
};

const getMaxPermissionLevelOption = (
  userAccessPermission: UserAssetPermission
) => {
  return optionsHelpers.permissionLevelToOption(
    userAccessPermission.maxPermissionLevel
  );
};

const assetNameContainsSearchString = (
  userAccessPermission: UserAssetPermission,
  searchString: string
): boolean => {
  return userAccessPermission.anemicEntity.name
    .toLowerCase()
    .includes(searchString.toLowerCase());
};

const folderPathContainsSearchString = (
  userAccessPermission: UserAssetPermission,
  searchString: string
): boolean => {
  return userAccessPermission.folderPath
    .join('/')
    .toLowerCase()
    .includes(searchString.toLowerCase());
};

const getBreadcrumbData = (
  userAccessPermission: UserAssetPermission
): string[] => {
  return userAccessPermission.folderPath;
};

const sortByFolderPath = (
  userAccessPermissionA: UserAssetPermission,
  userAccessPermissionB: UserAssetPermission
): number => {
  const aPath = userAccessPermissionA.folderPath.join('/');
  const bPath = userAccessPermissionB.folderPath.join('/');
  if (!aPath && !bPath) return 0;
  if (!aPath) return 1;
  if (!bPath) return -1;
  return localeCompare(aPath, bPath, getCurrentLocale());
};

const convertToSingleResourcePermission = ({
  anemicEntity,
}: UserAssetPermission): SingleResourcePermission => {
  const resourceType = assetTypeToResourceType({
    assetType: anemicEntity.ardoq.entityType,
  });
  return {
    resourceId: anemicEntity._id,
    resourceName: anemicEntity.name,
    resourceType,
  };
};

const maybeGetResourcePermissionDialogHeaderText = ({
  anemicEntity,
}: UserAssetPermission): string | undefined => {
  if (anemicEntity.ardoq.entityType === AssetType.TRAVERSAL) {
    return `Manage Viewpoint Permissions for "${anemicEntity.name}"`;
  }
  return undefined;
};

export const userAssetPermissionOperations = {
  getAssetName,
  getAssetOption,
  getMaxPermissionLevelOption,
  assetNameContainsSearchString,
  folderPathContainsSearchString,
  getBreadcrumbData,
  sortByFolderPath,
  convertToSingleResourcePermission,
  maybeGetResourcePermissionDialogHeaderText,
};
