import { ReactElement, FC, useEffect } from 'react';
import { withErrorBoundaries } from '@ardoq/error-boundary';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { logError } from '@ardoq/logging';
import { IntegrationSchedule } from '@ardoq/api-types';
import { fetchSchedulesList } from 'integrations/common/streams/schedules/actions';
import {
  schedules$,
  SchedulesState,
} from 'integrations/common/streams/schedules/getSchedulesStream';

type RenderProps = {
  isLoadingSchedules: boolean;
  schedules: IntegrationSchedule[];
};

type SchedulesApiConnectorProps = {
  children: (props: RenderProps) => ReactElement;
} & SchedulesState;

const SchedulesApiConnector: FC<SchedulesApiConnectorProps> = ({
  children,
  schedules,
  asyncStatus,
}) => {
  useEffect(() => {
    dispatchAction(fetchSchedulesList());
  }, []);

  return children({
    schedules: schedules.filter(
      // @ts-expect-error BE returns old jira's schedules. But FE have no support for them
      schedule => schedule.jobOptions.type !== 'jira-import'
    ),
    isLoadingSchedules: asyncStatus === 'LOADING',
  });
};

const ProtectedSchedulesApiConnector = withErrorBoundaries({
  logError,
  errorContextDescription: 'Error at <SchedulesApiConnector />',
})(SchedulesApiConnector);

export default connect(ProtectedSchedulesApiConnector, schedules$);
