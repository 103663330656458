type AnimatedArdoqLogoProps = {
  styling: {
    marginLeft: number;
    marginTop: number;
    marginBottom: string | number;
  };
};

const AnimatedArdoqLogo = ({ styling }: AnimatedArdoqLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 94"
    width="100px"
    height="100px"
    overflow="inherit"
    style={styling}
  >
    <path
      d="M42.9,68.8c-5.2,0.7-10-2.9-10.8-8.1s2.9-10,8.1-10.8s10,2.9,10.8,8.1C51.7,63.2,48.1,68.1,42.9,68.8z"
      fill="blue"
    >
      <animate
        repeatCount="indefinite"
        attributeName="fill"
        dur="1.5s"
        calcMode="spline"
        keyTimes="0 ; 0.33 ; 0.66 ; 1"
        keySplines="0.47 0.49 0.5 0.51 ; 0.47 0.49 0.5 0.51 ; 0.47 0.49 0.5 0.51"
        values="#609AD3; #3E7BB3; #609AD3; #3E7BB3"
      />
    </path>
    <path
      d="M59.2,82.8c-0.2-3.9-0.8-8.3,1.4-12.1c1.9-3.3,5-5.5,8.3-6.7c0,0,4.6-1.4,6.4-4.8c2.6-4.9,0.7-11.2-3.6-13.7
    c-3.6-2.1-10.4-0.5-10.4-0.5c-4.4,0.6-6,0.3-9.5-1.5c-3.7-1.9-6-6.6-8-11.2c-0.7-2.3-2.3-4.3-4.5-5.6c-4.8-2.8-10.9-1.2-13.7,3.6
    C22.8,35,24.3,41.5,29.2,44c4,2,7.3,0.9,7.3,0.9c4.6-1.2,9.1-1,13.2,1.4c7.4,4.3,9.8,14,5.5,21.4s-12.6,10.1-20.3,6.2
    c-4.2-2.1-7.4-9.6-8.3-11.3c-1.3-2.6-2.2-4-4.5-5.4c-4.8-2.8-10.9-1.2-13.7,3.6c-2.8,4.8-1.5,10.5,3.3,13.3c2.2,1.2,4.6,1.6,6.9,1.2
    c0,0,9.2-1.3,13.5,1.2c4.2,2.5,7.7,11.2,7.7,11.1c1.2,2.6,2.3,4.1,4.4,5.4c4.8,2.8,10.9,1.2,13.7-3.6C59.2,87.3,59.4,85.6,59.2,82.8
    z"
    >
      <animate
        repeatCount="indefinite"
        attributeName="fill"
        dur="1s"
        calcMode="spline"
        keyTimes="0 ; 0.33 ; 0.66 ; 1"
        keySplines="0.35 0.81 0.62 0.23 ; 0.35 0.81 0.62 0.23 ; 0.35 0.81 0.62 0.23"
        values="#ABCEED; #9AC5E5; #4588B6; #ABCEED"
      />
    </path>
    <path
      d="M19.4,45.8c0.8-2.1,0.6-3,0.4-5.1c-1.1-6.2-1.9-7.9-0.3-11.9c3.3-8.1,12.7-12.4,20.7-9c4.7,2,7.3,5.7,9.4,11.2
    c1.4,3.1,2.8,4.4,5.3,5.5c5.2,2.2,11.1-0.3,13.3-5.5c2.1-5.2-0.4-11.2-5.6-13.4c-2.2-1-4.7-1-6.8-0.4c-3.5,1.2-7.6,1.5-11.1-0.4
    C38.4,13.2,35.3,6,35.3,6c-1.2-2.6-2.7-4.4-5.3-5.3C24.7-1.2,20,1,17.6,4.8c0,0-1.8,3.1-0.6,9.1c0,0,1.3,4.8-0.8,10.3
    c-1.9,4.8-5,6.7-10.3,8.9c-2.3,1-4.2,2.7-5.2,5.2c-2.1,5.2,0.4,10.6,5.6,12.8C11.6,53.5,17.3,51,19.4,45.8z"
    >
      <animate
        repeatCount="indefinite"
        attributeName="fill"
        dur="2s"
        calcMode="spline"
        keyTimes="0 ; 0.33 ; 0.66 ; 1"
        keySplines="0.35 0.81 0.62 0.23 ; 0.35 0.81 0.62 0.23 ; 0.35 0.81 0.62 0.23"
        values="#ABCEED; #4588B6; #9AC5E5; #ABCEED"
      />
    </path>
  </svg>
);

export default AnimatedArdoqLogo;
