import { ModalSize, ModalTemplate } from '@ardoq/modal';
import { ViewpointBuilderNavigation } from './viewpointBuilderNavigation/ViewpointBuilderNavigation';
import { SelectContextTabContent } from './selectContextTab/SelectContextTabContent';
import { MetamodelViewContent } from './metaModel/MetamodelViewContent';
import { ViewpointBuilderViewState, ViewpointBuilderCommands } from './types';
import { viewpointBuilderEventListeners } from './viewpointBuilderEventListeners';
import { AddFiltersTabContent } from './addFiltersTab/AddFiltersTabContent';
import { BuildSubgraphContent } from './traversals/BuildSubgraphContent';
import { MetaInfoContent } from './viewpointMetaInfo/MetaInfoContent';
import { GroupingRulesContent } from './addGroupingRulesTab/GroupingRulesContent';
import { ConditionalFormattingContent } from './formattingTabs/ConditionalFormattingContent';
import { LabelFormattingContent } from './formattingTabs/LabelFormattingContent';
import { ContextConfig } from 'viewpointBuilder/openViewpointBuilder/getContextConfig';
import { SelectContextComponentTypeTabContent } from './selectContextTab/SelectContextComponentTypeTabContent';
import { ResizablePageBody } from '@ardoq/page-layout';
import { CollapsePathsTabContent } from './collapsePathsTab/CollapsePathsTabContent';
import RequiredComponentsTabContent from './requiredComponentsTab/RequiredComponentsTabContent';
import { viewpointApi } from '@ardoq/api';

type ViewpointBuilderProps = {
  state: ViewpointBuilderViewState;
  commands: ViewpointBuilderCommands;
  closeViewpointBuilder: () => void;
  contextConfig: ContextConfig;
};

const ViewpointBuilderContent = ({
  commands,
  state,
  closeViewpointBuilder,
  contextConfig,
}: ViewpointBuilderProps) => {
  switch (state.viewpointBuilderNavigationState.selectedTab) {
    case 'SELECT_CONTEXT_COMPONENT_INSTANCES_TAB':
      return (
        <SelectContextTabContent
          closeViewpointBuilder={closeViewpointBuilder}
          state={state.searchComponentsState}
          commands={commands.componentsSearchCommands}
          primaryButtonConfig={state.primaryButtonConfig}
          contextConfig={contextConfig}
          viewpointName={state.metaInfoState.name}
        />
      );

    case 'SELECT_CONTEXT_COMPONENT_TYPE_TAB':
      return (
        <SelectContextComponentTypeTabContent
          closeViewpointBuilder={closeViewpointBuilder}
          state={state.searchComponentsState}
          commands={commands.componentsSearchCommands}
          primaryButtonConfig={state.primaryButtonConfig}
        />
      );

    case 'SUBGRAPH_TAB':
      return (
        <BuildSubgraphContent
          state={state.editTraversalsState}
          commands={commands.editTraversalCommands}
          onCancel={closeViewpointBuilder}
          primaryButtonConfig={state.primaryButtonConfig}
          label={contextConfig.graphViewTitle}
        />
      );
    case 'COLLAPSE_PATHS_TAB':
      return (
        <CollapsePathsTabContent
          state={state.editTraversalsState}
          closeViewpointBuilder={closeViewpointBuilder}
          primaryButtonConfig={state.primaryButtonConfig}
          label={contextConfig.graphViewTitle}
          toggleGraphNodeSelection={
            viewpointBuilderEventListeners.collapsePathsNodeSelection
          }
          graphNodeOnHover={
            viewpointBuilderEventListeners.collapsedPathsGraphNodeOnHover
          }
        />
      );
    case 'REQUIRED_COMPONENTS_TAB':
      return (
        <RequiredComponentsTabContent
          state={state.editTraversalsState}
          closeViewpointBuilder={closeViewpointBuilder}
          primaryButtonConfig={state.primaryButtonConfig}
          label={contextConfig.graphViewTitle}
          toggleGraphNodeSelection={
            viewpointBuilderEventListeners.requiredComponentsNodeSelection
          }
          graphNodeOnHover={
            viewpointBuilderEventListeners.requiredComponentsGraphNodeOnHover
          }
        />
      );
    case 'FILTERS_TAB':
      return (
        <AddFiltersTabContent
          closeViewpointBuilder={closeViewpointBuilder}
          state={state.editTraversalsState}
          commands={commands.filterCommands}
          label={contextConfig.graphViewTitle}
          toggleGraphNodeSelection={
            viewpointBuilderEventListeners.toggleGraphNodeSelectionInFilters
          }
          primaryButtonConfig={state.primaryButtonConfig}
        />
      );

    case 'META_INFO_TAB': {
      return (
        <MetaInfoContent
          closeViewpointBuilder={closeViewpointBuilder}
          state={state.metaInfoState}
          commands={{
            ...commands.metaInfoCommands,
            ...commands.selectedViewCommands,
          }}
          generateViewpointDescription={viewpointApi.generateDescription}
          primaryButtonConfig={state.primaryButtonConfig}
          label={contextConfig.graphViewTitle}
        />
      );
    }

    case 'GROUPS_TAB':
      return (
        <GroupingRulesContent
          closeViewpointBuilder={closeViewpointBuilder}
          state={state.groupingsState}
          commands={commands.groupingsCommands}
          primaryButtonConfig={state.primaryButtonConfig}
        />
      );

    case 'FORMATTING_TAB':
      return (
        <ConditionalFormattingContent
          closeViewpointBuilder={closeViewpointBuilder}
          state={state.formattingState.conditionalFormatting}
          primaryButtonConfig={state.primaryButtonConfig}
        />
      );

    case 'LABELS_TAB':
      return (
        <LabelFormattingContent
          closeViewpointBuilder={closeViewpointBuilder}
          primaryButtonConfig={state.primaryButtonConfig}
          multiLabelFormattingProps={state.formattingState.multiLabelFormatting}
        />
      );

    case 'METAMODEL_TAB':
      return (
        <MetamodelViewContent
          closeViewpointBuilder={closeViewpointBuilder}
          state={state.metamodelViewState}
          toggleGraphNodeSelection={
            viewpointBuilderEventListeners.toggleGraphNodeSelectionInMetamodelView
          }
          commands={commands.metamodelViewCommands}
        />
      );

    default:
      state.viewpointBuilderNavigationState.selectedTab satisfies never;
      return null;
  }
};

const ViewpointBuilder = (props: ViewpointBuilderProps) => {
  return (
    <ModalTemplate modalSize={ModalSize.COVER}>
      <ResizablePageBody
        skipPadding
        leftContent={
          !props.contextConfig.isNavigationPanelHidden && (
            <ViewpointBuilderNavigation
              state={props.state.viewpointBuilderNavigationState}
              commands={props.commands.navigationCommands}
            />
          )
        }
        leftContentInitialWidth={430}
      >
        <ViewpointBuilderContent {...props} />
      </ResizablePageBody>
    </ModalTemplate>
  );
};

export default ViewpointBuilder;
