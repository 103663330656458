import { combineLatest, map } from 'rxjs';
import { dispatchAction } from '@ardoq/rxbeach';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { changeResourceTypesSelection } from 'integrations/cloudProviders/streams/resourceTypes/actions';
import { resourcesSummary$ } from 'integrations/azure/streams/resourcesSummary/resourcesSummary$';
import {
  getFilteredResourceTypes,
  getSelectedAvailableResourceTypes,
  resourceTypesToFields,
} from './utils';
import { resourceGroups$ } from 'integrations/azure/streams/resourceGroups/resourceGroups$';
import { ResourceTypesState } from 'integrations/cloudProviders/streams/resourceTypes/types';
import { ResourceGroupsState } from 'integrations/azure/streams/resourceGroups/types';
import { ResourcesSummaryState } from 'integrations/azure/streams/resourcesSummary/types';
import { SelectorField } from 'integrations/common/types/common';
import { selectionState$ } from 'integrations/azure/streams/selectionState/selectionState$';
import { SelectionState } from 'integrations/azure/streams/selectionState/types';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import { RegionsState } from 'integrations/cloudProviders/streams/regions/types';

function toViewModel({
  resourceTypesState,
  resourceGroupsState: { selectedResourceGroupNames },
  resourcesSummaryState: { resourcesSummary },
  selectionState: { subscriptionIds },
  regionsState: { selectedRegionIds },
}: {
  resourceTypesState: ResourceTypesState;
  resourceGroupsState: ResourceGroupsState;
  resourcesSummaryState: ResourcesSummaryState;
  selectionState: SelectionState;
  regionsState: RegionsState;
}) {
  const resourceTypes = getFilteredResourceTypes({
    resourceTypesState,
    selectedRegionIds,
    selectedResourceGroupNames,
    resourcesSummary,
  });

  const { selectedResourceTypes, availableResourceTypes } =
    getSelectedAvailableResourceTypes(
      resourceTypes,
      resourceTypesState.selectedResourceTypeIds
    );

  const onClickAdd = (field: SelectorField) => {
    dispatchAction(
      changeResourceTypesSelection({
        integrationId: 'azure-v3',
        resourceTypes: [...selectedResourceTypes.map(rt => rt.id), field.name],
      })
    );
  };

  const onClickAddAll = () => {
    dispatchAction(
      changeResourceTypesSelection({
        integrationId: 'azure-v3',
        resourceTypes: resourceTypes.map(rt => rt.id),
      })
    );
  };

  const onClickRemove = (field: SelectorField) => {
    dispatchAction(
      changeResourceTypesSelection({
        integrationId: 'azure-v3',
        resourceTypes: selectedResourceTypes
          .map(rt => rt.id)
          .filter(id => id !== field.name),
      })
    );
  };

  const onClickRemoveAll = () => {
    dispatchAction(
      changeResourceTypesSelection({
        integrationId: 'azure-v3',
        resourceTypes: [],
      })
    );
  };

  const hasResourceGroupsSelected =
    subscriptionIds.length > 0 && selectedResourceGroupNames.length > 0;

  return {
    selectedFields: hasResourceGroupsSelected
      ? resourceTypesToFields(selectedResourceTypes)
      : [],
    availableFields: hasResourceGroupsSelected
      ? resourceTypesToFields(availableResourceTypes)
      : [],
    currentTable: hasResourceGroupsSelected
      ? {
          name: 'resourceGroups',
          canCreate: true,
          canDelete: true,
          canRead: true,
          canWrite: true,
          label: 'Select resource types',
          id: 'resourceGroups',
        }
      : null,
    onClickAdd,
    onClickRemove,
    onClickAddAll,
    onClickRemoveAll,
  };
}

export const viewModel$ = combineLatest({
  resourceTypesState: getResourceTypesStream('azure-v3'),
  selectionState: selectionState$,
  resourceGroupsState: resourceGroups$,
  resourcesSummaryState: resourcesSummary$,
  regionsState: getRegionsStream('azure-v3'),
}).pipe(map(toViewModel));
