import {
  AggregatedSSOMappingsAPIResponse,
  ArdoqId,
  MappingRule,
  SSOAttributeMappingAPIResponse,
  SSOAttributeMappingPreviewAPIResponse,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { CurrentMappingUpdate, SsoOption } from './types';

export const getSSOAttributeMapping = actionCreator(
  '[SSOAttributeMapping] get'
);

export const setSSOAttributeMapping = actionCreator<
  SSOAttributeMappingAPIResponse[]
>('[SSOAttributeMapping] set');

export const deleteSSOAttributeMapping = actionCreator<ArdoqId>(
  '[SSOAttributeMapping] delete'
);

export const bulkSubmitSSOAttributeMapping = actionCreator<{
  changes: MappingRule[];
  deletions: ArdoqId[];
}>('[SSOAttributeMapping] bulk');

export const updateCurrentSSOMapping = actionCreator<CurrentMappingUpdate>(
  '[SSOAttributeMapping] update current'
);

export const addNewToCurrentSSOMapping = actionCreator(
  '[SSOAttributeMapping] add new to current'
);

export const setShowValidationErrorsSSOMapping = actionCreator<boolean>(
  '[SSOAttributeMapping] set show validation errors'
);

export const getPreviewSSOMapping = actionCreator<{
  mappings: MappingRule[];
  provider?: string;
}>('[SSOAttributeMapping] get preview');

export const setPreviewSSOMapping = actionCreator<{
  mappingPreview: SSOAttributeMappingPreviewAPIResponse;
}>('[SSOAttributeMapping] set preview');

export const setSsoSelectedForPreview = actionCreator<SsoOption | null>(
  '[SSOAttributeMapping] set sso selected for preview'
);

export const getAggregatedSSOMappings = actionCreator(
  '[SSOAttributeMapping] get aggregated SSO mappings'
);

export const setAggregatedSSOMappings =
  actionCreator<AggregatedSSOMappingsAPIResponse>(
    '[SSOAttributeMapping] set aggregated SSO mappings'
  );
