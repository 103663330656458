import fp from 'lodash/fp';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import {
  createTransferConfig,
  loadTransferConfig,
  saveConfiguration,
  updateTransferConfig,
} from 'integrations/common/streams/transferConfigs/actions';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import {
  getSavableTransferConfig,
  isAwsConfig,
  isSavedTransferConfig,
} from 'integrations/common/streams/transferConfigs/utils';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import {
  CloudProviderConfig,
  SavedTransferConfig,
  TransferConfig,
} from '@ardoq/api-types/integrations';
import { setTransferConfigId } from 'integrations/common/streams/activeIntegrations/actions';
import { startMissingConnectionModal } from 'integrations/common/modals/missingConnectionModal/MissingConnectionModal';
import { applySourceConfig } from 'integrations/cloudProviders/config/actions';
import { vpcs$ } from '../streams/vpcs/vpcs$';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { ImportRoute } from 'integrations/common/navigation/types';
import { createProviderParams, getSourceConfig } from 'integrations/aws/utils';

const handleSaveIntegrationConfigurations = routine(
  ofType(saveConfiguration),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'aws-v3'),
  withLatestFrom(
    getActiveIntegrationStream('aws-v3'),
    getTabularMappingStream('aws-v3'),
    transferConfigs$,
    getRegionsStream('aws-v3'),

    vpcs$,
    getResourceTypesStream('aws-v3'),
    getConnectionsStream('aws-v3')
  ),
  tap(
    ([
      { integrationId, name, isNewConfig },
      activeIntegration,
      tabularMapping,
      transferConfigs,
      { selectedRegionIds },

      { selectedVpcIds },
      { selectedResourceTypeIds },
      { selectedConnectionIds },
    ]) => {
      const sourceConfig = getSourceConfig({
        accountIds: selectedConnectionIds,
        resourceTypes: selectedResourceTypeIds,
        regions: selectedRegionIds,
        vpcs: selectedVpcIds,
      });

      const savableConfig: TransferConfig = {
        ...getSavableTransferConfig({
          transferConfigs,
          activeIntegration,
          tabularMapping,
        }),
        name,
        sourceConfig,
      };

      const isCreation =
        isNewConfig && activeIntegration.selectedTransferConfigId;

      if (isSavedTransferConfig(savableConfig) && !isCreation) {
        return dispatchAction(
          updateTransferConfig({
            integrationId,
            config: savableConfig,
          })
        );
      }
      /*
       * saving a new config
       */
      return dispatchAction(
        createTransferConfig({
          integrationId,
          config: fp.omit(
            ['_id', '_version', 'lastUpdated'],
            savableConfig
          ) as TransferConfig,
        })
      );
    }
  )
);

const handleLoadTransferConfig = routine(
  ofType(loadTransferConfig),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'aws-v3'),
  withLatestFrom(getConnectionsStream('aws-v3')),
  tap(([{ transferConfig, integrationId }, { connections }]) => {
    if (!isAwsConfig(transferConfig)) {
      return;
    }

    const handleLoadConfig = (
      config: SavedTransferConfig<CloudProviderConfig>
    ) => {
      dispatchAction(
        setTransferConfigId({
          integrationId,
          id: config._id,
        })
      );
      dispatchAction(
        navigateToPath({ integrationId, path: ImportRoute.SELECT_DATA })
      );
      if (config.sourceConfig) {
        dispatchAction(
          applySourceConfig({ integrationId, ...config.sourceConfig })
        );
      }
    };

    const connection = connections.find(conn =>
      transferConfig.sourceConfig?.accountIds.includes(conn._id)
    );

    if (!connection) {
      return startMissingConnectionModal({
        onCancel: () => null,
        onSubmit: connection => {
          const enrichedConfig = fp.update(
            'sourceConfig',
            sc => ({
              ...(sc || {}),
              accountIds: [connection._id],
              providerParams:
                sc?.providerParams ??
                createProviderParams({
                  regions: [],
                  resourceTypes: [],
                  vpcs: [],
                }),
            }),
            transferConfig
          );
          handleLoadConfig(enrichedConfig);
        },
      });
    }
    handleLoadConfig(transferConfig);
  })
);

export default [handleSaveIntegrationConfigurations, handleLoadTransferConfig];
