import { useState, ReactNode } from 'react';
import styled from 'styled-components';
import { Complaint, ComplaintLevel } from '@ardoq/api-types/integrations';
import { AnimatedChevron } from '@ardoq/icons';
import _ from 'lodash/fp';
import { Space } from '@ardoq/style-helpers';
import { Stack } from '@ardoq/layout';
import { s8, s16 } from '@ardoq/design-tokens';
import { text2 } from '@ardoq/typography';
import { ButtonSize, GhostButton } from '@ardoq/button';
import { ErrorNotification, WarningNotification } from '@ardoq/status-ui';

type ComplaintsProps = {
  errors?: Complaint[];
  warnings?: Complaint[];
  collapsiblePanels: boolean;
  collapseThreshold?: number;
};

export const Complaints = ({
  errors = [],
  warnings = [],
  collapsiblePanels,
  collapseThreshold = 5,
}: ComplaintsProps) =>
  errors.length || warnings.length ? (
    <ComplaintsContainer>
      <ErrorPanel
        isCollapsible={collapsiblePanels}
        errors={errors}
        collapseThreshold={collapseThreshold}
      />
      <WarningPanel
        isCollapsible={collapsiblePanels}
        warnings={warnings}
        collapseThreshold={collapseThreshold}
      />
    </ComplaintsContainer>
  ) : null;

export const ErrorPanel = ({
  errors,
  isCollapsible,
  collapseThreshold,
}: {
  errors: Complaint[];
  isCollapsible?: boolean;
  collapseThreshold?: number;
}) => {
  if (errors.length > 0) {
    return (
      <ComplaintPanel
        isCollapsible={isCollapsible ?? false}
        level="error"
        complaints={renderComplaints(errors)}
        collapseThreshold={collapseThreshold}
      />
    );
  }
  return <></>;
};

export const WarningPanel = ({
  warnings,
  isCollapsible,
  collapseThreshold,
}: {
  warnings: Complaint[];
  isCollapsible?: boolean;
  collapseThreshold?: number;
}) => {
  if (warnings.length > 0) {
    return (
      <ComplaintPanel
        isCollapsible={isCollapsible ?? false}
        level="warn"
        complaints={renderComplaints(warnings)}
        collapseThreshold={collapseThreshold}
      />
    );
  }
  return <></>;
};

type ComplaintsPanelProps = {
  level: ComplaintLevel;
  complaints: ReactNode[];
  isCollapsible: boolean;
  collapseThreshold?: number;
};

const ComplaintPanel = ({
  level,
  complaints,
  isCollapsible,
  collapseThreshold = 5,
}: ComplaintsPanelProps) => {
  const [isCollapsed, setCollapsed] = useState(isCollapsible);
  const visibleComplaints = isCollapsed
    ? _.take(collapseThreshold, complaints)
    : complaints;

  const moreThanOneComplaint = complaints.length > 1;
  const showMoreButtonDisplayed =
    isCollapsible && collapseThreshold < complaints.length;

  const content = (
    <ContentContainer>
      <ComplaintTotal>
        {`${complaints.length} ${complaintLevelToText[level]}${
          moreThanOneComplaint ? 's' : ''
        }`}
      </ComplaintTotal>
      <Stack gap="xsmall">{visibleComplaints}</Stack>
      {showMoreButtonDisplayed ? (
        <Space $justify={'end'}>
          <GhostButton
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              setCollapsed(!isCollapsed);
            }}
          >
            {isCollapsed ? 'Show more' : 'Show less'}
            <AnimatedChevron $isUp={!isCollapsed} />
          </GhostButton>
        </Space>
      ) : (
        <></>
      )}
    </ContentContainer>
  );

  if (level === 'error') {
    return <ErrorNotification>{content}</ErrorNotification>;
  }
  if (level === 'warn') {
    return <WarningNotification>{content}</WarningNotification>;
  }
  return <></>;
};

// compute the precision level of a complaint
// 4 : cell level
// 3 : row level
// 2 : column level
// 1 : sheet level
const complaintPrecisionLevel = ({ target }: Complaint): number => {
  return target === 'cell'
    ? 4
    : target === 'row'
      ? 3
      : target === 'column'
        ? 2
        : 1;
};

const complaintLevelToText: Record<ComplaintLevel, string> = {
  warn: 'warning',
  error: 'error',
};

const renderComplaints = _.flow(
  _.groupBy((e: Complaint) => e.message),
  _.toPairs,
  _.sortBy(([_message, complaints]) =>
    _.min(_.map(complaintPrecisionLevel, complaints))
  ),
  _.entries,
  _.map(([index, [message, complaints]]) => (
    <ComplaintMessage key={`${index}`}>
      <ComplaintMessageCount>{complaints.length}x</ComplaintMessageCount>
      <ComplaintMessageText>{message}</ComplaintMessageText>
    </ComplaintMessage>
  ))
);

const ComplaintTotal = styled.div`
  margin-bottom: ${s8};
  ${text2}
`;

const ComplaintMessage = styled(Space).attrs({ $gap: 's4' })`
  ${text2}
`;

const ComplaintMessageCount = styled.div`
  flex-shrink: 0;
`;

const ComplaintMessageText = styled.div`
  flex-shrink: 1;
`;

const ContentContainer = styled(Space).attrs({ $gap: 's4', $isVertical: true })`
  padding: 0;
  max-height: calc(256px - ${s16});
  overflow: auto;
`;

const ComplaintsContainer = styled(Space).attrs({ $gap: 's32' })`
  > * {
    flex: 1;
    align-self: flex-start;
    padding: ${s16};
    max-height: 256px;
  }
`;
