import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  applyMerge,
  closeMergeFlow,
  initMerge,
  initMergeFinished,
  mergeFlowOpened,
  setMergeStateDiff,
  setSubmittedStep,
  skipStep,
  trackSetStep,
  updateMergeStateDiff,
} from './actions';
import {
  StateData,
  buildMergeStateDiff,
  getConflictCounts,
  getHasRemainingChanges,
  getIssuesPerStepCount,
  getIssuesPerStepKey,
} from './utils';
import { omit } from 'lodash';
import MergeDialog from './MergeDialog';
import { APIDiffContext, Verb } from '@ardoq/api-types';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import {
  setMergeData,
  setMergeStep,
  toggleIsMergeFlowActive,
} from 'components/DiffMergeTable/actions';
import diffMergeTable$ from 'components/DiffMergeTable/diffMergeTable$';
import {
  apiResetScenario,
  loadAndFixDiffContext,
  updateBranchOffPoint,
} from 'services/scenarioApi';
import { mergeRequestErrorHandler } from 'services/errorHandlers';
import mergeState$ from './mergeState$';
import { prepareMergeData } from './mergeUtils';
import { logError } from '@ardoq/logging';
import { reloadScenario } from 'scope/actions';
import { DiffMetaStates, MergeState } from 'scope/merge/types';
import {
  trackDiffContextDataCorruption,
  trackMergeFlowStepSelected,
  trackMergeFlowToggle,
} from './tracking';
import {
  DontShowAgain,
  getDontShowAgainSetting,
} from 'models/dontShowAgainSettings';
import { handleMergeStepSelect } from 'components/DiffMergeSidebarNavigator/actionWrappers';
import { Branch } from 'components/DiffMergeTable/Branch';
import {
  getScopeSize,
  removeMergeStepFromWindowLocation,
  setMergeStepInWindowLocation,
} from 'scope/utils';
import { ViewModel } from 'components/DiffMergeTable/types';
import { MergeStepLabel } from 'scope/merge/typesMergeStepLabel';
import { showDialogWithRememberAction } from 'utils/showDialogWithRememberAction';
import {
  setIsLoading,
  setIsLoadingWithConfig,
} from 'components/GlobalPaneLoader/globalPaneLoader$';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { closeVisualDiff } from 'appContainer/MainAppModule/MainAppModuleSidebar/NavigatorTopBar/utils';
import {
  checkIfComponentsOrReferencesOutOfScopeWillBeDeleted,
  confirmContinueDeletingEntitiesOutOfScopeModal,
} from './continueDeletingEntitiesOutOfScopeUtils';
import { MergeDirection } from './MergeDirection';
import { Observable, forkJoin, from } from 'rxjs';
import {
  FieldType,
  RenderOption,
  RenderOptions,
  graphics,
} from '@ardoq/renderers';
import { popStackPageByName, pushStackPage } from '@ardoq/stack-page-manager';
import { NamedStackPage } from 'aqTypes';
import { dateRangeOperations } from '@ardoq/date-range';
import { handleError, scenarioApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { currentUnixSeconds } from '@ardoq/date-time';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';

const SIDEBAR_WIDTH = 250;

// Temporary error handler
/** @deprecated */
const genericScenarioErrorHandler = (message: string) =>
  catchError((error, stream) => {
    // eslint-disable-next-line no-console
    console.log(error);
    logError(error, message);
    dispatchAction(setIsLoading(false));
    return stream;
  });

export const options: RenderOptions = {
  definitions: {
    [RenderOption.TEXT_ELLIPSIS]: {
      maxLength: 240,
      showInPopover: true,
    },
  },
  types: {
    [FieldType.TEXT_AREA]: new Set([RenderOption.TEXT_ELLIPSIS]),
    [FieldType.URL]: new Set([RenderOption.TEXT_ELLIPSIS]),
  },
};

const setLoadingOverlay = () =>
  dispatchAction(setIsLoadingWithConfig({ spinnerOffsetX: SIDEBAR_WIDTH }));

const fetchMergeData = ({
  mergeDirection,
}: {
  mergeDirection: MergeDirection;
}) =>
  from(loadAndFixDiffContext(getActiveScenarioId()!)).pipe(
    handleError(),
    addNewReferencesIfFromMainlineToBranch(mergeDirection),
    handleError(),
    map(dateRangeOperations.mergeDateRangeFieldsForDiffContext),
    withLatestFrom(orgUsers$),
    tap(
      ([
        {
          response: { master, branch, branchOff },
        },
        { byId: users },
      ]) => {
        dispatchAction(
          setMergeData({
            master,
            branch,
            branchOff,
            options,
            users,
            graphics,
          })
        );
      }
    )
  );

const addNewReferencesIfFromMainlineToBranch =
  (mergeDirection: MergeDirection) =>
  (
    source: Observable<{
      response: APIDiffContext;
    }>
  ) =>
    mergeDirection === MergeDirection.BRANCH_TO_MAINLINE
      ? source
      : source.pipe(
          mergeMap(({ response }) =>
            forkJoin([
              from([response]),
              scenarioApi.getScenarioReferences(
                response.branch.scopeComponents
              ),
            ])
          ),
          map(([response, referencesResponse]) => {
            if (isArdoqError(referencesResponse)) {
              return referencesResponse;
            }
            const { references } = referencesResponse;
            return {
              response: {
                ...response,
                master: { ...response.master, references },
              },
            };
          })
        );

const handleInitMerge = routine(
  ofType(initMerge),
  tap(() => {
    if (isInScopeDiffMode()) {
      closeVisualDiff();
    }
    dispatchAction(toggleIsMergeFlowActive(true));
  }),
  tap(() => {
    if (process.env.NODE_ENV !== 'test') {
      pushStackPage(() => <MergeDialog closeHandler={closeMergeDialog} />, {
        stackPageName: NamedStackPage.MERGE_FLOW,
      });
    }
  }),
  tap(setLoadingOverlay),
  extractPayload(),
  map(mergeDirection => ({ mergeDirection })),
  switchMap(fetchMergeData),
  tap(() => {
    dispatchAction(setIsLoading(false));
    dispatchAction(updateMergeStateDiff({}));
    dispatchAction(mergeFlowOpened());
    dispatchAction(initMergeFinished());
  }),
  genericScenarioErrorHandler('upsie')
);

const getTrackMergeFlowToggleFn =
  (context: 'opened' | 'closed') =>
  ([dontShowAgainMergeDialog, diffMergeTableData, mergeState]: [
    boolean,
    ViewModel,
    MergeState,
  ]) => {
    const enhancedScopeData =
      diffMergeTableData.enhancedDiffContextData?.[Branch.BRANCH];
    if (!enhancedScopeData) {
      logError(
        new Error(
          `Couldn't find the branch data to track ${context} merge flow`
        )
      );
      return;
    }
    trackMergeFlowToggle(
      context,
      getScopeSize(enhancedScopeData),
      getIssuesPerStepCount(mergeState),
      mergeState.mergeDirection,
      dontShowAgainMergeDialog
    );
  };

const handleOpenMergeFlowTracking = routine(
  ofType(mergeFlowOpened),
  switchMap(() =>
    getDontShowAgainSetting(DontShowAgain.APPLY_MERGE_INSTANTLY_DIALOG)
  ),
  withLatestFrom(diffMergeTable$, mergeState$),
  tap(getTrackMergeFlowToggleFn('opened')),
  tap(([_dontShowAgainMergeDialog, { enhancedDiffContextData }]) => {
    if (!enhancedDiffContextData) return;
    trackDiffContextDataCorruption(enhancedDiffContextData);
  })
);

const handleOpenMergeFlowIntercom = routine(
  ofType(mergeFlowOpened),
  withLatestFrom(mergeState$),
  tap(([, { diffMetaStates, submittedSteps }]) => {
    const hasRemainingChanges = getHasRemainingChanges(
      diffMetaStates,
      submittedSteps
    );
    if (!hasRemainingChanges) {
      // If merge flow is opened on the 'nothing to merge' page, try to trigger general intercom tour
      setMergeStepInWindowLocation();
    }
    // This function polls intercom so that when the first intercom finishes, the next one immediately starts if applicable
    if (window.Intercom) {
      // 'if (window.Intercom)' needs to be here or else we'll have errors in unit tests
      const intervalId = setInterval(() => {
        window.Intercom('update', {
          // eslint-disable-next-line camelcase
          last_request_at: currentUnixSeconds(),
        });
      }, 1500); // Polls intercom every 1.5 second
      setTimeout(() => clearInterval(intervalId), 90000); // stop polling after 1.5 minutes
    }
  })
);

const handleSetMergeStepWindowLocation = routine(
  ofType(setMergeStep),
  extractPayload(),
  tap(({ mergeStep }) => {
    setMergeStepInWindowLocation(mergeStep);
  })
);

const handleCloseMergeFlow = routine(
  ofType(closeMergeFlow),
  extractPayload(),
  tap(({ shouldMoveBranchOffPoint }) => {
    if (shouldMoveBranchOffPoint) {
      updateBranchOffPoint(getActiveScenarioId()!);
    } else if (getHasRemainingChanges(mergeState$.state.diffMetaStates)) {
      showDialogWithRememberAction({
        dontShowAgainSettingKey:
          DontShowAgain.BRANCH_OFF_POINT_NOT_UPDATED_ALERT,
        title: 'There are still unresolved changes',
        subtitle: 'Not all steps have been completed',
        text: 'Not all steps have been completed. Some of the merge conflicts will reappear the next time the merge workflow is opened',
        hideCancelButton: true,
      });
    }
  }),
  switchMap(() =>
    getDontShowAgainSetting(DontShowAgain.APPLY_MERGE_INSTANTLY_DIALOG)
  ),
  tap(() => dispatchAction(toggleIsMergeFlowActive(false))),
  withLatestFrom(diffMergeTable$, mergeState$),
  tap(getTrackMergeFlowToggleFn('closed')),
  tap(removeMergeStepFromWindowLocation),
  tap(() => {
    dispatchAction(
      setMergeData({
        master: null,
        branch: null,
        branchOff: null,
        graphics: null,
        users: null,
        options: null,
      })
    );
    dispatchAction(reloadScenario({ scenarioId: getActiveScenarioId()! }));
    popStackPageByName(NamedStackPage.MERGE_FLOW);
  })
);

const closeMergeDialog = () =>
  dispatchAction(closeMergeFlow({ shouldMoveBranchOffPoint: false }));

const closeMergeDialogAndUpdateBranchOffPoint = () =>
  dispatchAction(closeMergeFlow({ shouldMoveBranchOffPoint: true }));

const hasNoMoreChangesLeftToMerge = (
  state: DiffMetaStates,
  submittedSteps: Record<MergeStepLabel, Set<Verb>>
) =>
  // this function checks if there are no more changes left to merge. Also has to check if there previously
  // were some changes to merge so as to not give a false positive when the merge flow is opened without any changes.
  !getHasRemainingChanges(state, submittedSteps) &&
  getHasRemainingChanges(mergeState$.state.diffMetaStates);

const handleApplyMerge = routine(
  ofType(applyMerge),
  withLatestFrom(diffMergeTable$, mergeState$),
  map(([, { dataSource, enhancedDiffContextData }, { mergeDirection }]) => {
    if (!dataSource || !enhancedDiffContextData)
      throw 'No datasource or diffContextData';

    const payload = prepareMergeData(dataSource, enhancedDiffContextData);
    return {
      mergeDirection,
      enhancedDiffContextData,
      dataSource,
      body: payload,
    };
  }),
  tap(setLoadingOverlay),
  switchMap(async baseData => {
    let canContinue = true;
    const { mergeDirection, dataSource } = baseData;
    const { numberOfDescendantsOutsideScope, numberOfReferencesOutsideScope } =
      await checkIfComponentsOrReferencesOutOfScopeWillBeDeleted({
        mergeDirection,
        dataSource,
      });
    if (
      Boolean(numberOfDescendantsOutsideScope) ||
      Boolean(numberOfReferencesOutsideScope)
    ) {
      canContinue = Boolean(
        await confirmContinueDeletingEntitiesOutOfScopeModal({
          numberOfDescendantsOutsideScope,
          numberOfReferencesOutsideScope,
        })
      );
    }
    return { ...baseData, canContinue };
  }),
  tap(({ canContinue }) => !canContinue && dispatchAction(setIsLoading(false))),
  filter(({ canContinue }) => canContinue),
  switchMap(({ mergeDirection, enhancedDiffContextData, body }) =>
    apiResetScenario(
      getActiveScenarioId()!,
      mergeDirection,
      enhancedDiffContextData,
      body
    )
  ),
  mergeRequestErrorHandler(setIsLoading),
  withLatestFrom(mergeState$),
  switchMap(([, { mergeDirection }]) => fetchMergeData({ mergeDirection })),
  withLatestFrom(mergeState$),
  tap(([, { mainStateStep, subStateStep }]) => {
    if (mainStateStep && subStateStep) {
      dispatchAction(
        setSubmittedStep({ submittedStep: { mainStateStep, subStateStep } })
      );
    }
  }),
  tap(() => dispatchAction(updateMergeStateDiff({}))),
  tap(() => dispatchAction(setIsLoading(false))),
  genericScenarioErrorHandler('Error occurred on trying merge')
);

const handleSkipStep = routine(
  ofType(skipStep),
  extractPayload(),
  tap(({ mainStateStep, subStateStep }) => {
    if (mainStateStep && subStateStep) {
      dispatchAction(
        setSubmittedStep({ submittedStep: { mainStateStep, subStateStep } })
      );
    }
  }),

  tap(() => dispatchAction(updateMergeStateDiff({}))),
  genericScenarioErrorHandler('Error occurred on trying to skip merge step')
);

// Rebuilds the merge diff state with new diff data
const handleUpdateMergeStateDiff = routine(
  ofType(updateMergeStateDiff),
  withLatestFrom(diffMergeTable$, mergeState$),
  map(([, { enhancedDiffContextData }, { mergeDirection, submittedSteps }]) => {
    const stateData = omit(
      enhancedDiffContextData?.diffTargetToSource,
      'permissions'
    ) as StateData | undefined;

    return buildMergeStateDiff(stateData, mergeDirection, submittedSteps);
  }),
  tap(({ state, startLabel, startVerb, submittedSteps }) => {
    if (hasNoMoreChangesLeftToMerge(state as DiffMetaStates, submittedSteps)) {
      closeMergeDialogAndUpdateBranchOffPoint();
      return;
    }
    dispatchAction(setMergeStateDiff(state));
    if (!startLabel || !startVerb) return;
    handleMergeStepSelect(startLabel, startVerb);
  })
);

const handleTrackSetStep = routine(
  ofType(trackSetStep),
  withLatestFrom(diffMergeTable$, mergeState$),
  tap(([, diffMergeTable, mergeState]) => {
    const mainStep = mergeState.mainStateStep;
    const subStep = mergeState.subStateStep;
    if (!mainStep || !subStep) {
      throw new Error(
        'Unknown current merge step. Tracking step select failed.'
      );
    }
    if (!diffMergeTable.dataSource) {
      throw new Error(
        'Unknown merge step data source. Tracking step select failed.'
      );
    }
    const issuesCount =
      getIssuesPerStepCount(mergeState)[getIssuesPerStepKey(mainStep, subStep)];
    const conflictCounts = getConflictCounts(diffMergeTable.dataSource);
    trackMergeFlowStepSelected({
      mainStep,
      subStep,
      issuesCount,
      conflictCounts,
    });
  })
);

export const mergeScopeRoutines = collectRoutines(
  handleApplyMerge,
  handleUpdateMergeStateDiff,
  handleInitMerge,
  handleOpenMergeFlowTracking,
  handleCloseMergeFlow,
  handleSetMergeStepWindowLocation,
  handleOpenMergeFlowIntercom,
  handleTrackSetStep,
  handleSkipStep
);
