import {
  APIComponentAttributes,
  ArdoqId,
  ChangeRequestAction,
} from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';
import { setApprovedDataForEntireComponent } from './actions';
import ComponentDisplay from './ComponentDisplay';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import changeApprovalData$ from './changeApprovalData$';
import styled from 'styled-components';
import RejectAndApproveAllButtons from './PageElements/RejectAndApproveAllButtons';
import ReferenceDisplay from './ReferenceDisplay';
import { ApprovalFocus } from './types';
import { Stack } from '@ardoq/layout';

const ComponentSectionWrapper = styled(Stack)`
  height: 100%;
`;

type ChangeApprovalComponentsProps = {
  componentId: ArdoqId;
  branchComponent: APIComponentAttributes;
  masterComponent: APIComponentAttributes;
  approvalFocus: ApprovalFocus;
  changeAction: ChangeRequestAction;
};

const ChangeApprovalComponents = ({
  approvalFocus,
  masterComponent,
  branchComponent,
  componentId,
  changeAction,
}: ChangeApprovalComponentsProps) => {
  const handleApproveAll = (
    componentId: ArdoqId,
    approvedComponentData: APIComponentAttributes
  ) => {
    trackEvent('Survey change approval: clicked approve all');
    dispatchAction(
      setApprovedDataForEntireComponent({
        componentId,
        approvedComponentData,
        action: 'approve',
      })
    );
  };

  const handleRejectAll = (
    componentId: ArdoqId,
    approvedComponentData: APIComponentAttributes | undefined
  ) => {
    trackEvent('Survey change approval: clicked reject all');
    dispatchAction(
      setApprovedDataForEntireComponent({
        componentId,
        approvedComponentData,
        action: 'reject',
      })
    );
  };

  return (
    <ComponentSectionWrapper>
      {(changeAction === 'update' || approvalFocus === 'reference') && (
        <RejectAndApproveAllButtons
          onApproveAll={() =>
            handleApproveAll(componentId, {
              ...branchComponent,
              _version: masterComponent?._version ?? branchComponent?._version,
            })
          }
          onRejectAll={() => handleRejectAll(componentId, masterComponent)}
        />
      )}
      {approvalFocus === 'component' ? (
        <ComponentDisplay
          handleApproveAll={handleApproveAll}
          handleRejectAll={handleRejectAll}
        />
      ) : (
        <ReferenceDisplay />
      )}
    </ComponentSectionWrapper>
  );
};

const changeApprovalComponentsData$ = changeApprovalData$.pipe(
  map(
    ({ approvalFocus, masterData, branchData, componentId, changeAction }) => {
      const masterComponent = masterData.componentsById[componentId];
      const branchComponent = branchData.componentsById[componentId];
      return {
        changeAction,
        componentId,
        branchComponent,
        masterComponent,
        approvalFocus,
      };
    }
  )
);

export default connect(ChangeApprovalComponents, changeApprovalComponentsData$);
