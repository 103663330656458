import { Checkbox } from '@ardoq/forms';
import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  float: left;
  margin-bottom: 10px;
`;

interface SuppressDialogContentProperties {
  setSuppressionPreference: (checked: boolean) => void;
  suppressText: string;
}
const SuppressDialogContent = ({
  setSuppressionPreference,
  suppressText,
}: SuppressDialogContentProperties) => {
  const [checked, setChecked] = useState(false);
  return (
    <Container>
      <Checkbox
        isChecked={checked}
        onChange={() => {
          setChecked(!checked);
          setSuppressionPreference(!checked);
        }}
      >
        {suppressText}
      </Checkbox>
    </Container>
  );
};

export default SuppressDialogContent;
