import {
  type ButtonConfig,
  DisabledZoomControls,
  type SettingsConfig,
  SettingsType,
  ViewContext,
} from '@ardoq/view-settings';
import { IconName } from '@ardoq/icons';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { ViewIds } from '@ardoq/api-types';
import {
  collapseAll,
  expandAll,
} from 'tabview/relationshipDiagrams/leftMenuItems';
import {
  getCollapseAllButtonConfig,
  getExpandAllButtonConfig,
} from 'tabview/relationsD3View/hierarchical/RelationshipsViewSettingsBar';
import { BlockDiagramViewProperties } from 'tabview/blockDiagram/types';
import { RelationshipsViewProperties } from 'tabview/relationsD3View/hierarchical/types';
import { DependencyMapViewProps } from 'tabview/dependencyMap/types';
import includeReferenceParentsSlider from 'tabview/dependencyMap/view/includeReferenceParentsSlider';
import { collapseChildrenDropdownTree } from '@ardoq/dependency-map';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { isTimelineSettingsControlsDependencies } from '@ardoq/timeline2024';

const viewActionsOptions = (
  viewId: ViewIds,
  viewProperties: Record<string, any>,
  {
    zoomIn,
    zoomOut,
    optimizeLayout,
    zoomControls,
    zoomControlsDisabledState,
  }: ViewContext
): SettingsConfig[] => {
  const defaultViewActionsConfig =
    zoomControls ??
    [
      zoomIn &&
        ({
          id: 'zoomIn',
          type: SettingsType.BUTTON,
          label: 'Zoom in',
          iconName: IconName.ZOOM_IN,
          onClick: zoomIn,
          isDisabled: Boolean(
            zoomControlsDisabledState & DisabledZoomControls.ZOOM_IN
          ),
        } satisfies ButtonConfig),
      zoomOut &&
        ({
          id: 'zoomOut',
          type: SettingsType.BUTTON,
          label: 'Zoom out',
          iconName: IconName.ZOOM_OUT,
          onClick: zoomOut,
          isDisabled: Boolean(
            zoomControlsDisabledState & DisabledZoomControls.ZOOM_OUT
          ),
        } satisfies ButtonConfig),
      optimizeLayout &&
        ({
          id: 'optimizeLayout',
          type: SettingsType.BUTTON,
          label: 'Optimize layout',
          iconName: IconName.OPTIMIZE_LAYOUT,
          onClick: optimizeLayout,
          isDisabled: Boolean(
            zoomControlsDisabledState & DisabledZoomControls.FIT_WINDOW
          ),
        } satisfies ButtonConfig),
    ].filter(ExcludeFalsy);
  if (!('viewSettings' in viewProperties)) {
    return defaultViewActionsConfig;
  }
  switch (viewId) {
    case ViewIds.BLOCK_DIAGRAM:
    case ViewIds.MODERNIZED_BLOCK_DIAGRAM: {
      const { viewModel, viewSettings } =
        viewProperties as BlockDiagramViewProperties;
      const hasNoGroups =
        !viewModel.groups.add.length && !viewModel.groups.update.length;
      return [
        collapseAll(viewSettings, viewId, hasNoGroups),
        expandAll(viewSettings, viewId, hasNoGroups),
        ...defaultViewActionsConfig,
      ];
    }
    case ViewIds.RELATIONSHIPS_3: {
      const { viewSettings, groups, rootNode } =
        viewProperties as RelationshipsViewProperties;
      const hasNoGroups = !groups.add.length && !groups.update.length;
      return [
        getCollapseAllButtonConfig({
          viewSettings,
          isDisabled: hasNoGroups,
          rootNode,
        }),
        getExpandAllButtonConfig({
          viewSettings,
          isDisabled: hasNoGroups,
        }),
        ...defaultViewActionsConfig,
      ];
    }
    case ViewIds.DEPENDENCY_MAP_2: {
      const {
        viewSettings: { degreesOfParenthood, degreesOfChildhood, treeDepth },
        viewModel: {
          referenceParentMaxDepth,
          referenceChildMaxDepth,
          maxTreeDepth,
        },
      } = viewProperties as DependencyMapViewProps;
      return [
        includeReferenceParentsSlider({
          referenceParentMaxDepth,
          referenceChildMaxDepth,
          degreesOfParenthood,
          degreesOfChildhood,
          showChildDegreesSlider: false,
        }),
        collapseChildrenDropdownTree({
          viewId: ViewIds.DEPENDENCY_MAP_2,
          treeDepth,
          maxTreeDepth,
          onViewSettingsUpdate,
        }),
      ];
    }
    case ViewIds.TIMELINE: {
      if (!isTimelineSettingsControlsDependencies(viewProperties)) {
        // error is logged in viewModifiers$
        return defaultViewActionsConfig;
      }
      return [
        ...viewProperties.viewModifiers,
        ...(viewProperties.viewContext.zoomControls ?? []),
      ];
    }
    default:
      return defaultViewActionsConfig;
  }
};

export default viewActionsOptions;
