import { InfoNotification } from '@ardoq/status-ui';
import { Stack } from '@ardoq/layout';

export type ImportAndScheduleProps = {
  affectedWorkspaces?: { id: string; name: string; deleted?: boolean }[];
};
export function InfoNotificationComponent({
  affectedWorkspaces = [],
}: ImportAndScheduleProps) {
  return (
    <InfoNotification>
      <Stack gap="xsmall">
        To ensure accurate data, assets missing from the imported data will be
        removed from
        {affectedWorkspaces.map(ws => (
          <li key={ws.id}>{ws.name}</li>
        ))}
      </Stack>
    </InfoNotification>
  );
}
