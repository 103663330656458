import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  createReferencesClick,
  createReferencesKeydown,
  createReferencesMouseMove,
  startCreateReferences,
  startedCreatingReferences,
} from './createReferencesActions';
import { tap, withLatestFrom } from 'rxjs/operators';
import { ReferenceDirection } from '@ardoq/graph';
import { createReferences$ } from './createReferences$';
import { CSS_CLASS_IGNORE_LINK_TARGET } from 'components/WorkspaceHierarchies/utils/consts';
import { CSS_CLASS_LINK_TARGET } from 'consts';
import {
  linkUpdate,
  setLinkSources,
  setLinkTarget,
} from 'components/WorkspaceHierarchies/actions/navigatorActions';
import * as canvasHitTestRegistry from 'tabview/relationsD3View/hierarchical/canvasHitTestRegistry';
import { NAVIGATOR_TREE_FILTER_ID } from 'components/WorkspaceHierarchies/consts';
import { logError } from '@ardoq/logging';
import isAlphanumericKeyPress from 'utils/isAlphanumericKeypress';
import { Position } from 'components/WorkspaceHierarchies/types';
import { getTarget, getTargetId } from './utils';
import { createNewLinks } from './createNewLinks';
import { isNavigatorTreeFilterOrFilterButton } from './isNavigatorTreeFilterOrFilterButton';
import isInputElement from 'utils/isInputElment';

const CSS_CLASS_IS_LINK_CREATION = 'is-link-creation';

const startCreateReferenceRoutine = routine(
  ofType(startCreateReferences),
  extractPayload(),
  tap(
    ({
      event,
      linkSourceIds,
      linkSourceNodeId = null,
      startPosition: optionalStartPosition,
      isStartInNavigator = false,
      refDirection = ReferenceDirection.UNSET,
    }) => {
      // If the `refDirection` is not set we are not really ready to start
      // linking. We could potentially end in an state were we never stop
      // linking.
      if (refDirection === ReferenceDirection.UNSET) return;

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      window.document.body.classList.add(CSS_CLASS_IS_LINK_CREATION);
      const startPosition = getPosition(event, optionalStartPosition);
      const abortController = new AbortController();
      document.addEventListener(
        'mousemove',
        event => dispatchAction(createReferencesMouseMove(event)),
        { signal: abortController.signal }
      );
      document.addEventListener(
        'click',
        event => dispatchAction(createReferencesClick(event)),
        { capture: true, signal: abortController.signal }
      );
      document.addEventListener(
        'keydown',
        event => dispatchAction(createReferencesKeydown(event)),
        { signal: abortController.signal }
      );

      dispatchAction(
        setLinkSources({
          linkSourceNodeId,
          linkSourceIds,
          startPosition,
          refDirection,
          abortController,
          isStartInNavigator,
        })
      );
      dispatchAction(startedCreatingReferences(abortController.signal));
    }
  )
);

const isMouseEvent = (event: any): event is MouseEvent =>
  'clientX' in event && 'clientY' in event;

const getPosition = (
  event?: React.MouseEvent<Element, MouseEvent> | Event | null,
  position?: Position
) => {
  if (position) {
    return position;
  }
  if (isMouseEvent(event)) {
    return { x: event.clientX, y: event.clientY };
  }
  return { x: 0, y: 0 };
};

const mouseMoveRoutine = routine(
  ofType(createReferencesMouseMove),
  extractPayload(),
  withLatestFrom(createReferences$),
  tap(([event, { linkTarget, isLinking }]) => {
    if (!isLinking) {
      return;
    }
    const { clientX: x, clientY: y } = event;
    const target = getTarget(event);
    if (target !== linkTarget) {
      clearCssClassLinkTarget(linkTarget);
      setCssClassLinkTarget(target);
    }
    dispatchAction(
      linkUpdate({
        position: { x, y },
        linkTarget: target ?? null,
      })
    );
  })
);

const clickRoutine = routine(
  ofType(createReferencesClick),
  extractPayload(),
  withLatestFrom(createReferences$),
  tap(([event, { refDirection, abortController, linkSourceIds }]) => {
    if (!abortController) {
      logError(
        Error('No abort controller in reference creation click routine')
      );
      return;
    }
    const isMultiLinkingWithoutReferenceDirection =
      refDirection === ReferenceDirection.UNSET && linkSourceIds.length > 1;
    if (
      isMultiLinkingWithoutReferenceDirection ||
      !(event.target instanceof Element) ||
      isNavigatorTreeFilterOrFilterButton(event.target)
    ) {
      return;
    }

    const componentTargetElement = getTarget(event);
    if (
      !componentTargetElement &&
      event.target.closest('[data-canvas-hit-test]')
    ) {
      const viewInstanceId = event.target
        .closest('[data-canvas-hit-test]')
        ?.getAttribute('data-canvas-hit-test');
      const hitTester =
        viewInstanceId && canvasHitTestRegistry.get(viewInstanceId);
      const componentId = hitTester && hitTester(event);
      if (componentId) {
        finalizeCreateReferences(
          event,
          abortController,
          linkSourceIds,
          componentId,
          refDirection
        );
        return;
      }
    }

    const componentTargetId = getTargetId(componentTargetElement);

    finalizeCreateReferences(
      event,
      abortController,
      linkSourceIds,
      componentTargetId,
      refDirection
    );
  })
);

const keyDownRoutine = routine(
  ofType(createReferencesKeydown),
  extractPayload(),
  withLatestFrom(createReferences$),
  tap(([event, { abortController }]) => {
    if (!abortController) {
      logError(
        Error('No abort controller in reference creation key down routine')
      );
      return;
    }

    if (event.key === 'Escape') {
      finalizeCreateReferences(event, abortController);
      return;
    }

    const filter = getNavigatorFilter();

    if (!filter || !isAlphanumericKeyPress(event)) {
      return;
    }

    if (filter !== document.activeElement) filter.focus();
  })
);

const finalizeCreateReferences = (
  event: MouseEvent | KeyboardEvent,
  abortController: AbortController,
  linkSourceIds?: string[] | null,
  targetId?: string | null,
  refDirection?: ReferenceDirection
) => {
  event.stopPropagation();
  event.preventDefault();
  if (linkSourceIds && targetId && refDirection) {
    createNewLinks({
      linkSourcesIds: linkSourceIds,
      linkTargetId: targetId,
      refDirection,
    });
  }
  dispatchAction(setLinkTarget({ targetId: targetId ?? null }));
  abortController.abort();
  window.document.body.classList.remove(CSS_CLASS_IS_LINK_CREATION);
  getTarget(event)?.classList.remove(CSS_CLASS_LINK_TARGET);
};

const setCssClassLinkTarget = (linkTarget?: Element | null) => {
  if (linkTarget && !linkTarget.closest(`.${CSS_CLASS_IGNORE_LINK_TARGET}`)) {
    linkTarget.classList.add(CSS_CLASS_LINK_TARGET);
  }
};

const clearCssClassLinkTarget = (linkTarget?: Element | null) => {
  if (linkTarget && !linkTarget.closest(`.${CSS_CLASS_IGNORE_LINK_TARGET}`)) {
    linkTarget.classList.remove(CSS_CLASS_LINK_TARGET);
  }
};

export const getNavigatorFilter = () => {
  const filter = document.querySelector(`#${NAVIGATOR_TREE_FILTER_ID} input`);
  return isInputElement(filter) ? filter : null;
};

export const createReferenceRoutines = collectRoutines(
  startCreateReferenceRoutine,
  mouseMoveRoutine,
  clickRoutine,
  keyDownRoutine
);
