import { DirectedTripleWithFilters } from '@ardoq/api-types';
import { PARENT_REF_TYPE_NAME } from '../utils';

export const namedDirectedTripleToBackendFormat = ({
  sourceType,
  targetType,
  referenceType,
  direction,
  sourceFilter,
  targetFilter,
  referenceFilter,
}: DirectedTripleWithFilters) => ({
  sourceType,
  targetType,
  referenceType:
    referenceType === PARENT_REF_TYPE_NAME ? 'ardoq_parent' : referenceType,
  direction,
  sourceFilter,
  targetFilter,
  referenceFilter,
});
