import { ArdoqId } from '@ardoq/api-types';
import { EnhancedScopeData, GetCssClassNamesOption } from '@ardoq/data-model';
import { hasFeature } from '@ardoq/features';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { NavigatorViewInterface } from 'components/WorkspaceHierarchies/types';
import { subdivisionsInterface } from 'streams/subdivisions/subdivisionInterface';
import {
  colorsAndRepresentations,
  componentUtils,
  enhancedScopeDataOperations,
  workspaceUtils,
} from '@ardoq/scope-data';
import { ContextSort } from '@ardoq/common-helpers';
import { Locale } from '@ardoq/locale';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { PermissionContext } from '@ardoq/access-control';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { getNavigatorStubViewInterface } from 'components/WorkspaceHierarchies/getNavigatorStubViewInterface';

export const getNavigatorViewInterface = (
  enhancedScopeData: EnhancedScopeData,
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext,
  sortOrder: ContextSort,
  locale: Locale
): NavigatorViewInterface => {
  return {
    ...getNavigatorStubViewInterface(),
    // Use default implementation for the following functions:
    // The inventory should not apply formatting filters.
    // getCSSFilterColor:
    // isIncludedInContextByFilter:
    // We don't deal with scenarios in the inventory.
    // isScenarioContextComponent:
    // getVisualDiffType:
    // getScenarioName:
    // TODO not sure, but I don't think we need this, styles most likely work
    // without the CSS manager.
    // getComponentCssClassNames:
    // TODO these are writing to the BE (and populating BB collections), we will
    // not deal with them now.
    // removeZonesFromComponent
    // copyZones:
    // copyComponents:
    isIncludedInContextByFilter: () => true,
    isComponentInFlexibleModel: (componentId: ArdoqId) =>
      componentUtils.belongsToFlexibleModel(enhancedScopeData, componentId),
    isFlexibleWorkspace: (workspaceId: ArdoqId) =>
      workspaceUtils.isFlexibleWorkspace(enhancedScopeData, workspaceId),
    canComponentHaveChildren: (componentId: ArdoqId) =>
      componentUtils.canComponentHaveChildren(enhancedScopeData, componentId),
    getComponentLock: (componentId: ArdoqId) =>
      Boolean(
        componentUtils.getAttribute(enhancedScopeData, componentId, 'lock')
      ),
    getComponentTypeId: (componentId: ArdoqId) =>
      componentUtils.getAttribute(enhancedScopeData, componentId, 'typeId'),
    getComponentRepresentationData: (componentId: ArdoqId) =>
      colorsAndRepresentations.getComponentRepresentationData(
        enhancedScopeData,
        componentId
      ),
    getSortedComponentChildrenIds: (componentId: ArdoqId) =>
      componentUtils.getSortedComponentChildrenIds(
        enhancedScopeData,
        componentId,
        sortOrder,
        locale
      ),
    getUnsortedComponentChildrenIds: (componentId: ArdoqId) =>
      componentUtils.getChildrenIds(enhancedScopeData, componentId),
    hasComponentWriteAccess: (componentId: ArdoqId) => {
      const component = enhancedScopeData.componentsById[componentId];
      if (!component) {
        return false;
      }
      return componentAccessControlOperation.canEditComponent({
        permissionContext,
        component,
        subdivisionsContext,
      });
    },
    isWorkspaceEmpty: (workspaceId: ArdoqId) =>
      workspaceUtils.isWorkspaceEmpty(enhancedScopeData, workspaceId),
    getWorkspaceRootComponents: (workspaceId: ArdoqId) =>
      workspaceUtils.getRootComponents(enhancedScopeData, workspaceId),
    isWorkspace: (workspaceId: ArdoqId) =>
      workspaceUtils.isWorkspace(enhancedScopeData, workspaceId),
    hasWorkspaceWriteAccess: (workspaceId: ArdoqId) =>
      workspaceAccessControlInterface.canEditWorkspace(
        permissionContext,
        workspaceId,
        null
      ),
    getRootWorkspaceIdOfComponent: (componentId: ArdoqId) =>
      componentUtils.getWorkspaceId(enhancedScopeData, componentId),
    getComponentName: (componentId: ArdoqId) =>
      componentUtils.getAttribute(enhancedScopeData, componentId, 'name'),
    getComponentOrder: (componentId: ArdoqId) =>
      componentUtils.getAttribute(enhancedScopeData, componentId, '_order'),
    getWorkspaceName: (workspaceId: ArdoqId) =>
      workspaceUtils.getAttribute(enhancedScopeData, workspaceId, 'name'),
    getComponentDisplayColorAsSVGAttributes: (
      componentId: ArdoqId,
      options: GetCssClassNamesOption
    ) =>
      componentUtils.getComponentColorAsSVGAttributes(
        enhancedScopeData,
        componentId,
        options
      ),
    isComponentExternallyManaged: (componentId: ArdoqId) =>
      componentUtils.isExternallyManaged(enhancedScopeData, componentId),
    isComponentExternallyMissing: (componentId: ArdoqId) =>
      componentUtils.isExternallyMissing(enhancedScopeData, componentId),
    currentUserIsOrgAdmin: () => currentUserInterface.currentUserIsOrgAdmin(),
    isWorkspaceExternallyManaged: (workspaceId: ArdoqId) =>
      workspaceUtils.isExternallyManaged(enhancedScopeData, workspaceId),
    getComponentSubdivisionMembership: (componentId: ArdoqId) =>
      componentUtils.getSubdivisionMembership(enhancedScopeData, componentId),
    getSubdivisionById: (subdivisionId: ArdoqId) =>
      subdivisionsInterface.getSubdivisionById(subdivisionId),
    getLinkedSourceComponents: (componentId: ArdoqId) =>
      componentUtils.getSources(enhancedScopeData, componentId),
    getLinkedTargetComponents: (componentId: ArdoqId) =>
      componentUtils.getTargets(enhancedScopeData, componentId),
    hasFeature,
    getWorkspaceBoundSubdivisionsIds: (workspaceId: ArdoqId) =>
      workspaceUtils.getAttribute(
        enhancedScopeData,
        workspaceId,
        'subdivisionMembership'
      ) ?? [],
    isComponent: (componentId: ArdoqId) =>
      componentUtils.isComponent(enhancedScopeData, componentId),
    getComponentData: (componentId: ArdoqId) =>
      enhancedScopeDataOperations.getComponent(
        enhancedScopeData,
        componentId
      ) ?? null,
  };
};
