import { SearchBackend } from '@ardoq/api-types';
import { SearchPane } from './SearchTabContainer/types';
import { SubSearchBackend } from './types';

export const isSearchPane = (pane: string): pane is SearchPane =>
  Object.values(SearchPane).includes(pane as any);

export const isSearchPaneWithBackend = (
  pane: string
): pane is SubSearchBackend | SearchBackend =>
  Object.values({ ...SubSearchBackend, ...SearchBackend }).includes(
    pane as any
  );
