import { Column, DatasourceTable, newTableTheme } from '@ardoq/table';
import { UserProfilePageProps } from '../../types';
import { TabBox } from '../TabBox';
import { NoGroups } from './components/NoGroups';
import { PermissionGroup } from '@ardoq/api-types';
import { IconDecorator } from '@ardoq/decorators';
import { IconName } from '@ardoq/icons';
import { pluralize } from '@ardoq/common-helpers';
import { Link } from '@ardoq/typography';

const COLUMNS: Column<PermissionGroup>[] = [
  {
    title: 'Group name',
    dataIndex: 'name',
    valueRender: (_, row) => (
      <Link underlineOnHover hideIcon type="secondary" onClick={() => {}}>
        <IconDecorator iconName={IconName.PEOPLE}>{row.name}</IconDecorator>
      </Link>
    ),
  },
  {
    title: 'Members',
    dataIndex: '_id',
    valueRender: (_, row) => (
      <IconDecorator iconName={IconName.PERSON}>
        {row.users.length} {pluralize('member', row.users.length)}
      </IconDecorator>
    ),
  },
];
export const UserGroupsDetails = (props: UserProfilePageProps) => {
  if (!props.userGroups.length) {
    return (
      <TabBox>
        <NoGroups
          user={props.user}
          onAddToGroupClick={props.commands.onAddToGroupClick}
        />
      </TabBox>
    );
  }
  return (
    <TabBox>
      <DatasourceTable
        components={newTableTheme}
        columns={COLUMNS}
        dataSource={props.userGroups}
        onRowClick={row => props.commands.onGroupClick(row)}
        rowStyle={() => ({ cursor: 'pointer' })}
      />
    </TabBox>
  );
};
