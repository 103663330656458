import { dispatchAction } from '@ardoq/rxbeach';
import {
  addUsersToGroup,
  changeGroupName,
  deleteGroup,
  setSearchPhraseAction,
} from './streams/actions';
import {
  APIOrganizationUser,
  ArdoqId,
  PermissionGroup,
  UserWithRoles,
} from '@ardoq/api-types';
import { trackPermissionGroupPageEvents } from './utils';
import { PermissionGroupPageEvents } from './types';
import { navigateToAccessControlPage } from 'router/navigationActions';
import { AccessControlTabs } from 'admin/accessControl/navigation/types';
import { modal } from '@ardoq/modal';
import CreatePermissionGroupModal from './components/CreatePermissionGroupModal/CreatePermissionGroupModal';
import ConfirmRemoveUserModal from './components/RemoveUsersModal/ConfirmRemoveUserModal';
import { commands } from 'appContainer/AccessControlPage/commands';
import { openAddUserToGroupModal } from './components/AddUserToGroupModal';

const goToGroup = (groupId: ArdoqId) => {
  trackPermissionGroupPageEvents(PermissionGroupPageEvents.SELECT_A_GROUP);
  dispatchAction(
    navigateToAccessControlPage({
      accessControlTab: AccessControlTabs.PERMISSION_GROUPS,
      groupId,
      groupActiveTabId: 'users',
    })
  );
};

const removeGroup = (groupId: ArdoqId) => {
  trackPermissionGroupPageEvents(PermissionGroupPageEvents.DELETE_GROUP);
  dispatchAction(deleteGroup(groupId));
};

const appendUsersToGroup = (
  permissionGroup: PermissionGroup,
  users: UserWithRoles[]
) => {
  const filteredUserIds = users
    .filter(user => !permissionGroup.users.includes(user._id))
    .map(({ _id }) => _id);
  if (filteredUserIds.length === 0) {
    return;
  }

  trackPermissionGroupPageEvents(PermissionGroupPageEvents.ADD_USER_TO_GROUP);
  dispatchAction(
    addUsersToGroup({
      permissionGroupId: permissionGroup._id,
      userIds: filteredUserIds,
    })
  );
};

const renameGroup = (group: PermissionGroup, newName: string) => {
  trackPermissionGroupPageEvents(PermissionGroupPageEvents.RENAME_GROUP);
  dispatchAction(changeGroupName({ group, newName }));
};

const setSearchPhrase = (query: string) => {
  dispatchAction(setSearchPhraseAction({ query }));
};

const changeGroupDetailsTab = (
  group: PermissionGroup,
  tabId: 'users' | 'assets'
) => {
  trackPermissionGroupPageEvents(
    PermissionGroupPageEvents.CLICK_ON_GROUP_DETAILS_TAB,
    {
      tabId,
      usersCount: group.users.length,
    }
  );
  dispatchAction(
    navigateToAccessControlPage({
      accessControlTab: AccessControlTabs.PERMISSION_GROUPS,
      groupId: group._id,
      groupActiveTabId: `${tabId}`,
    })
  );
};

const removeUserFromGroup = async (
  group: PermissionGroup,
  user: APIOrganizationUser
) => {
  trackPermissionGroupPageEvents(
    PermissionGroupPageEvents.CLICK_REMOVE_USER_FROM_GROUP_BUTTON
  );
  if (!user) return;

  modal(closeModal =>
    ConfirmRemoveUserModal({
      closeModal: () => closeModal(true),
      user,
      group,
      trackUserEvent: trackPermissionGroupPageEvents,
    })
  );
};

const goToUserProfilePage = (userId: ArdoqId, from?: string) => {
  commands.goToUserProfilePage(userId, from);
};

const addUsersToGroupModal = (group: PermissionGroup) => {
  openAddUserToGroupModal(group);
};

const showCreateGroupModal = (
  permissionGroups: Omit<PermissionGroup, 'users'>[]
) => {
  trackPermissionGroupPageEvents(
    PermissionGroupPageEvents.CLICK_ON_CREATE_NEW_GROUP
  );
  modal(closeModal =>
    CreatePermissionGroupModal({
      closeModal: () => closeModal(true),
      existingPermissionGroupNames: permissionGroups.map(group => group.name),
      trackUserEvent: trackPermissionGroupPageEvents,
    })
  );
};

export const permissionGroupsCommands = {
  goToGroup,
  removeGroup,
  appendUsersToGroup,
  renameGroup,
  setSearchPhrase,
  changeGroupDetailsTab,
  removeUserFromGroup,
  goToUserProfilePage,
  addUsersToGroupModal,
  showCreateGroupModal,
};

export type PermissionGroupsCommands = typeof permissionGroupsCommands;
