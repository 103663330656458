import styled from 'styled-components';
import { WorkspaceOptionsState, workspaceOptions$ } from './workspaceOptions$';
import WorkspaceModelBrowser from 'components/WorkspaceModelBrowser/WorkspaceModelBrowser';
import {
  createTemplate,
  editWorkspace,
  editWorkspaceModel,
  exportWorkspaceToExcel,
  manageFields,
  manageReferenceTypes,
  restoreDeleted,
  setPermissions,
  viewWorkspaceHistory,
} from './actions';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  confirmDeleteWorkspace,
  openWorkspace,
} from 'streams/workspaces/actions';
import { toggleModelPreview } from 'streams/currentUser/actions';
import { RigidMarker } from './atoms';
import {
  SidebarMenu,
  SidebarMenuMenu,
  SidebarMenuSection,
} from '@ardoq/sidebar-menu';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { s24 } from '@ardoq/design-tokens';
import { CalculatedFieldSection } from './CalculatedFieldSection';
import { trackEvent } from 'tracking/tracking';
import { navigateToOrganizationMetamodel } from 'router/navigationActions';

const ModelPreviewContainer = styled.div`
  padding: ${s24};
`;

const Wrapper = styled.div`
  display: flex;
`;

const RigidLabel = () => <RigidMarker>Rigid </RigidMarker>;

const WorkspaceOptions = ({
  contextWorkspaceId,
  contextWorkspaceName,
  hideModelPreview,
  showCreateTemplate,
  showEditModel,
  isFlexibleModel,
  isWsWriter,
  isWsAdmin,
  disableEditWorkspace,
  disableExportExcel,
  disableEditModel,
  disableWorkspaceEditReferenceTypes,
  disableWorkspaceEditFields,
  disableModelCreateTemplate,
  hasRelatedWorkspaces,
  relatedWorkspaces,
  hasCalculatedFields,
  hasNewMetamodelEditor,
  calculatedFields,
  calculatedFieldEvents,
  componentTypeHierarchy,
  branchId,
}: WorkspaceOptionsState) =>
  contextWorkspaceId ? (
    <Wrapper>
      <SidebarMenu>
        <SidebarMenuSection
          title="Metamodel preview"
          aria-label="Metamodel preview"
          isCollapsed={hideModelPreview}
          onClick={() => dispatchAction(toggleModelPreview())}
        >
          <ModelPreviewContainer>
            {!isFlexibleModel && <RigidLabel />}
            <WorkspaceModelBrowser root={componentTypeHierarchy} />
          </ModelPreviewContainer>
        </SidebarMenuSection>
        <SidebarMenuSection
          title={isWsAdmin ? 'Edit workspace' : 'Workspace'}
          aria-label={isWsAdmin ? 'Edit workspace' : 'Workspace'}
          isCollapsible={false}
        >
          <SidebarMenuMenu
            options={[
              ...(isWsWriter
                ? [
                    isWsAdmin && {
                      label: 'Edit workspace properties',
                      onClick: () =>
                        dispatchAction(editWorkspace(contextWorkspaceId)),
                      isDisabled: disableEditWorkspace,
                      dataClickId: 'edit-workspace-properties-menu-item',
                    },
                    {
                      label: 'View workspace history',
                      onClick: () => {
                        trackEvent('Clicked', {
                          id: 'view-workspace-history-menu-item',
                        });
                        dispatchAction(
                          viewWorkspaceHistory({
                            id: contextWorkspaceId,
                            name: contextWorkspaceName,
                          })
                        );
                      },
                      isDisabled: disableEditWorkspace,
                      dataTestId: 'view-workspace-history-menu-item',
                    },
                    {
                      label: 'Restore deleted components and references',
                      onClick: () =>
                        dispatchAction(restoreDeleted(contextWorkspaceId)),
                      isDisabled: disableEditWorkspace,
                      dataClickId:
                        'restore-deleted-components-and-references-menu-item',
                    },
                    isWsAdmin && {
                      label: 'Edit collaborators and permissions',
                      onClick: () =>
                        dispatchAction(setPermissions(contextWorkspaceId)),
                      isDisabled: disableEditWorkspace,
                      dataClickId:
                        'edit-collaborators-and-permissions-menu-item',
                    },
                    isWsAdmin && {
                      label: 'Delete workspace',
                      onClick: () =>
                        dispatchAction(
                          confirmDeleteWorkspace(contextWorkspaceId)
                        ),
                      isDisabled: disableEditWorkspace,
                      dataClickId: 'delete-workspace-menu-item',
                    },
                  ]
                : []),
              {
                label: 'Export workspace to excel',
                onClick: () =>
                  dispatchAction(
                    exportWorkspaceToExcel({
                      workspaceId: contextWorkspaceId,
                      filename: contextWorkspaceName,
                    })
                  ),
                isDisabled: disableExportExcel,
                dataClickId: 'export-workspace-to-excel-menu-item',
              },
            ].filter(ExcludeFalsy)}
          />
        </SidebarMenuSection>

        {showEditModel && (
          <SidebarMenuSection
            title="Edit metamodel"
            aria-label="Edit metamodel"
            isCollapsible={false}
          >
            <SidebarMenuMenu
              options={[
                {
                  label: hasNewMetamodelEditor
                    ? 'Manage component types'
                    : 'Manage component types and metamodel',
                  onClick: () => {
                    if (hasNewMetamodelEditor) {
                      dispatchAction(
                        navigateToOrganizationMetamodel({
                          route:
                            '/organization-metamodel/workspaces/:workspaceId/component-types',
                          params: {
                            workspaceId: contextWorkspaceId,
                          },
                        })
                      );
                    } else {
                      dispatchAction(editWorkspaceModel(contextWorkspaceId));
                    }
                  },
                  isDisabled: disableEditModel,
                  dataClickId: 'manage-component-types-and-metamodel-menu-item',
                },
                {
                  label: 'Manage reference types',
                  onClick: () => {
                    if (hasNewMetamodelEditor) {
                      dispatchAction(
                        navigateToOrganizationMetamodel({
                          route:
                            '/organization-metamodel/workspaces/:workspaceId/reference-types',
                          params: {
                            workspaceId: contextWorkspaceId,
                          },
                        })
                      );
                    } else {
                      dispatchAction(manageReferenceTypes(contextWorkspaceId));
                    }
                  },
                  isDisabled: disableWorkspaceEditReferenceTypes,
                  dataClickId: 'manage-reference-types-menu-item',
                },
                {
                  label: hasNewMetamodelEditor
                    ? 'Manage fields'
                    : 'Manage field types',
                  onClick: () => {
                    if (hasNewMetamodelEditor) {
                      dispatchAction(
                        navigateToOrganizationMetamodel({
                          route:
                            '/organization-metamodel/workspaces/:workspaceId/fields',
                          params: {
                            workspaceId: contextWorkspaceId,
                          },
                        })
                      );
                    } else {
                      dispatchAction(manageFields(contextWorkspaceId));
                    }
                  },
                  isDisabled: disableWorkspaceEditFields,
                  dataClickId: 'manage-field-types-menu-item',
                },
                showCreateTemplate && {
                  label: 'Create template from workspace metamodel',
                  onClick: () =>
                    dispatchAction(createTemplate(contextWorkspaceId)),
                  isDisabled: disableModelCreateTemplate,
                  dataClickId:
                    'create-template-from-workspace-metamodel-menu-item',
                },
              ].filter(ExcludeFalsy)}
            />
          </SidebarMenuSection>
        )}

        {hasRelatedWorkspaces && (
          <SidebarMenuSection
            title="Related workspaces"
            aria-label="Related workspaces"
            isCollapsible={false}
          >
            <SidebarMenuMenu
              options={relatedWorkspaces.map(({ id, name: label }) => {
                return {
                  label,
                  onClick: () =>
                    dispatchAction(
                      openWorkspace({
                        workspaceId: id,
                        keepCurrentContext: true,
                        trackingLocation: 'optionsMenuSidebar',
                      })
                    ),
                  dataClickId: `related-workspace-${id}-menu-item`,
                };
              })}
            />
          </SidebarMenuSection>
        )}

        {hasCalculatedFields && isWsWriter && (
          <CalculatedFieldSection
            calculatedFields={calculatedFields}
            fieldStatusMap={calculatedFieldEvents}
            branchId={branchId}
          />
        )}
      </SidebarMenu>
    </Wrapper>
  ) : (
    <div />
  );
export default connect(WorkspaceOptions, workspaceOptions$);
