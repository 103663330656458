import { MutableRefObject } from 'react';
import { DependencyMapViewProps } from '../types';
import {
  DependencyMapViewSettings,
  collapseChildrenDropdownTree,
} from '@ardoq/dependency-map';
import { ViewIds } from '@ardoq/api-types';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import {
  MenuMetaOptions,
  showOnlyConnectedComponents,
  dropdownTraversalMenu,
  SettingsBar,
} from '@ardoq/settings-bar';
import {
  type DropdownConfig,
  type SettingsConfig,
  SettingsType,
  type ToggleConfig,
} from '@ardoq/view-settings';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { getExportsForHtmlView } from '@ardoq/export';
import {
  POPOVER_ID_ATTR,
  SimpleTextPopover,
  popoverRegistry,
} from '@ardoq/popovers';
import { noop } from 'lodash';
import {
  REFERENCE_PARENTS_DISABLED_POPOVER,
  TRAVERSAL_INFO_POPOVER_ID,
} from './consts';
import TraversalInfoPopover from './popovers/traversalInfo/TraversalInfoPopover';
import { colors } from '@ardoq/design-tokens';
import { OnViewSettingsUpdate } from '@ardoq/data-model';
import { Features, hasFeature } from '@ardoq/features';
import { getStyleSheets } from 'tabview/getSharedExportFunctions';
import { filterInterface } from '@ardoq/filter-interface';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import includeReferenceParentsSlider from './includeReferenceParentsSlider';
import { DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import { updateViewSettings } from '@ardoq/view-settings';
import { currentDate } from '@ardoq/date-time';
import { IconBadge, StatusType } from '@ardoq/status-ui';

popoverRegistry.set(REFERENCE_PARENTS_DISABLED_POPOVER, () => (
  <SimpleTextPopover text="Your data does not have reference parents." />
));
popoverRegistry.set(TRAVERSAL_INFO_POPOVER_ID, () => <TraversalInfoPopover />);
type LeftMenuConfigProps = Pick<
  DependencyMapViewProps,
  'viewSettings' | 'viewModel' | 'onViewSettingsUpdate' | 'isDiscover'
>;

type DependencyMapSettingsBarStreamedProps = { isViewpointMode: boolean };
type DependencyMapSettingsBarOwnProps = LeftMenuConfigProps & {
  containerRef: MutableRefObject<HTMLElement | null>;
  isEmptyView: boolean;
} & Pick<DependencyMapViewProps, 'trackingFunction' | 'addToPresentation'>;
type DependencyMapSettingsBarProps = DependencyMapSettingsBarStreamedProps &
  DependencyMapSettingsBarOwnProps;

const VIEW_ID = ViewIds.DEPENDENCY_MAP_2;

export const getLeftMenuConfig = (
  args: LeftMenuConfigProps
): SettingsConfig[] => {
  const {
    viewSettings,
    viewModel: {
      traversedOutgoingReferenceTypes,
      traversedIncomingReferenceTypes,
      referenceTypes,
      maxTreeDepth,
      isViewpointMode,
      referenceParentMaxDepth,
      referenceChildMaxDepth,
    },
    isDiscover = false,
    onViewSettingsUpdate,
  } = args;
  const {
    colorByReference,
    showVertical,
    treeDepth,
    seenTraversalInfo,
    degreesOfParenthood,
    degreesOfChildhood,
  } = viewSettings;
  const viewId = VIEW_ID;
  const traversalItem: DropdownConfig | null = isViewpointMode
    ? null
    : {
        ...dropdownTraversalMenu({
          viewSettings,
          traversedOutgoingReferenceTypes,
          traversedIncomingReferenceTypes,
          referenceTypes,
          viewId,
          maximumDepth: 5,
          options: {
            selectParentChildReferenceTypes: false,
            selectReferenceTypes: !isDiscover,
            degreesOfSingleDirection: true,
            degreesOfBothDirections: false,
            degreesRowAdditionalContent:
              seenTraversalInfo === true ? (
                <Icon
                  style={{ verticalAlign: 'bottom', marginLeft: '4px' }}
                  iconName={IconName.INFO}
                  iconSize={IconSize.REGULAR}
                  color={colors.icon}
                  {...{ [POPOVER_ID_ATTR]: TRAVERSAL_INFO_POPOVER_ID }}
                />
              ) : (
                <IconBadge
                  style={{
                    color: colors.icon,
                    verticalAlign: 'bottom',
                    marginLeft: '4px',
                  }}
                  iconName={IconName.INFO}
                  color={colors.icon}
                  statusType={StatusType.NEW}
                  {...{ [POPOVER_ID_ATTR]: TRAVERSAL_INFO_POPOVER_ID }}
                />
              ),
            metaOptions:
              MenuMetaOptions.ALL_EXCEPT_PARENT_CHILD |
              MenuMetaOptions.NONE_OPTION,
          },
          onViewSettingsUpdate,
          quickPerspectiveReferenceTypeFilters:
            filterInterface.getActiveQuickReferenceTypeFilters(),
        }),
        onOpened: seenTraversalInfo
          ? undefined
          : () =>
              onViewSettingsUpdate(
                viewId,
                { seenTraversalInfo: currentDate() },
                true
              ),
      };

  const showVerticalConfig: SettingsConfig = {
    id: 'showVertical',
    label: showVertical ? 'Show horizontal' : 'Show vertical',
    iconName: showVertical
      ? IconName.ORIENTATION_HORIZONTAL
      : IconName.ORIENTATION_VERTICAL,
    isActive: false,
    currentValue: showVertical,
    type: SettingsType.TOGGLE,
    onViewSettingsUpdate,
  };

  const colorByReferenceConfig = {
    id: 'colorByReference',
    label: 'Color by reference type',
    iconName: IconName.PALETTE,
    isActive: colorByReference,
    type: SettingsType.TOGGLE,
    onViewSettingsUpdate,
  };

  if (isDiscover)
    return [traversalItem, showVerticalConfig, colorByReferenceConfig].filter(
      ExcludeFalsy
    );

  const collapseChildrenConfig = collapseChildrenDropdownTree({
    viewId,
    treeDepth: treeDepth ?? Infinity, // Infinity gets cast to null when stored as view setting of a slide.
    maxTreeDepth,
    onViewSettingsUpdate,
  });

  const includeReferenceParentsConfig = includeReferenceParentsSlider({
    referenceParentMaxDepth,
    referenceChildMaxDepth,
    showChildDegreesSlider: !isViewpointMode,
    degreesOfParenthood,
    degreesOfChildhood,
  });
  const useNewGroupingConfig = hasFeature(Features.GROUPING_RESEARCH)
    ? newGrouping(viewSettings, onViewSettingsUpdate)
    : null;

  if (isViewpointMode) {
    const layoutDirectionConfig: DropdownConfig = {
      id: 'layoutDirection',
      label: 'Layout direction',
      iconName: IconName.SORT_ASC,
      type: SettingsType.DROPDOWN,
      options: [
        {
          label: 'Show horizontal',
          isActive: !showVertical,
          type: DropdownOptionType.OPTION,
          onClick: () =>
            dispatchAction(
              updateViewSettings({
                viewId: ViewIds.DEPENDENCY_MAP_2,
                settings: {
                  showVertical: false,
                } satisfies Partial<DependencyMapViewSettings>,
                persistent: true,
              })
            ),
        } satisfies DropdownOption,
        {
          label: 'Show vertical',
          isActive: showVertical,
          type: DropdownOptionType.OPTION,
          onClick: () =>
            dispatchAction(
              updateViewSettings({
                viewId: ViewIds.DEPENDENCY_MAP_2,
                settings: {
                  showVertical: true,
                } satisfies Partial<DependencyMapViewSettings>,
                persistent: true,
              })
            ),
        } satisfies DropdownOption,
      ],
    };
    return [layoutDirectionConfig, colorByReferenceConfig];
  }
  return [
    traversalItem,
    collapseChildrenConfig,
    showOnlyConnectedComponents(
      viewSettings.showOnlyConnectedComponents,
      onViewSettingsUpdate
    ),
    showVerticalConfig,
    colorByReferenceConfig,
    includeReferenceParentsConfig,
    useNewGroupingConfig,
  ].filter(ExcludeFalsy);
};

const newGrouping = (
  viewSettings: DependencyMapViewSettings,
  onViewSettingsUpdate: OnViewSettingsUpdate
): ToggleConfig => ({
  id: 'useNewGrouping',
  label: 'Use new grouping',
  iconName: IconName.RHOMBUS,
  type: SettingsType.TOGGLE,
  isActive: Boolean(viewSettings.useNewGrouping),
  onViewSettingsUpdate,
});

const DependencyMapSettingsBar = ({
  viewSettings,
  containerRef,
  viewModel,
  onViewSettingsUpdate,
  isDiscover = false,
  trackingFunction = noop,
  addToPresentation = noop,
  isEmptyView,
  isViewpointMode,
}: DependencyMapSettingsBarProps) => {
  const exports = {
    ...getExportsForHtmlView({
      container: () => containerRef.current,
      exportedViewMetadata: {
        name: VIEW_ID,
        isViewpointMode,
      },
      trackingFunction,
      addToPresentation,
      showPresentationOption: !isDiscover,
      getStyleSheets,
    }),
    isDisabled: isEmptyView,
  };
  return (
    <SettingsBar
      viewId={VIEW_ID}
      leftMenu={getLeftMenuConfig({
        viewSettings,
        viewModel,
        onViewSettingsUpdate,
        isDiscover,
      })}
      rightMenu={getRightMenuConfig({
        viewId: VIEW_ID,
        viewstate: viewSettings,
        exports,
        knowledgeBaseLink: isViewpointMode
          ? undefined
          : KnowledgeBaseLink.DEPENDENCY_MAP_2,
        onViewSettingsUpdate,
      })}
    />
  );
};

const ConnectedDependencyMapSettingsBar = connect<
  DependencyMapSettingsBarProps,
  DependencyMapSettingsBarStreamedProps
>(DependencyMapSettingsBar, isViewpointMode$);
export default ConnectedDependencyMapSettingsBar;
