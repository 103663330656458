import { isJust } from '@ardoq/common-helpers';
import RefreshNotification from './RefreshNotification';
import { ArdoqErrorNotification } from '@ardoq/snowflakes';
import {
  LimitedHistoryNotification,
  NotificationAreaProps,
} from '@ardoq/audit-log';

export const NotificationArea = ({
  showRefreshNotification,
  errorNotificationError,
  showLimitedHistoryNotification,
}: NotificationAreaProps) => (
  <>
    {isJust(errorNotificationError) && (
      <ArdoqErrorNotification error={errorNotificationError} />
    )}
    {showLimitedHistoryNotification && <LimitedHistoryNotification />}
    {showRefreshNotification && <RefreshNotification />}
  </>
);
