import { SubdivisionProps } from 'subdivisionEditor/types';
import { Tab, TabsGroup } from '@ardoq/tabs';
import { ComponentsResultTable } from './ComponentsResultTable';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';

export const AdvancedSearchResultView = (props: SubdivisionProps) => {
  const { componentsSelection, selectComponentsCommands } = props;

  const {
    componentsToAdd,
    componentsToRemove,
    selectedComponentsToBeRemoved,
    selectedComponentsToBeAdded,
  } = componentsSelection;
  if (
    !componentsSelection.showResults ||
    !subdivisionEditorOperations.isAdvancedSearchMode(props)
  ) {
    return null;
  }
  return (
    <TabsGroup onTabChange={selectComponentsCommands.onResultTabChanged}>
      <Tab label={`To add (${componentsToAdd.length})`} tabId="to-add">
        <ComponentsResultTable
          {...props}
          components={componentsToAdd}
          selectedSearchResults={selectedComponentsToBeAdded}
          setSelected={selected =>
            componentsToAdd.length &&
            selectComponentsCommands.setSelectedSearchResults(props, selected)
          }
        />
      </Tab>
      <Tab label={`To remove (${componentsToRemove.length})`} tabId="to-remove">
        <ComponentsResultTable
          {...props}
          components={componentsToRemove}
          selectedSearchResults={selectedComponentsToBeRemoved}
          setSelected={selected =>
            componentsToRemove.length &&
            selectComponentsCommands.setSelectedSearchResultsToRemove(
              props,
              selected
            )
          }
        />
      </Tab>
    </TabsGroup>
  );
};
