import {
  APIViewpointAttributes,
  QueryBuilderQuery,
  MetaModel,
  MetaModelTriple,
  DirectedTripleWithFilters,
  ReferenceDirection,
  ArdoqId,
  BooleanOperator,
  Operator,
  SearchContext,
  APICountInstancesResponse,
  TraversalPathMatchingType,
  TripleDirection,
  DirectedTriple,
} from '@ardoq/api-types';
import {
  ComponentRepresentationData,
  GraphEdge,
  GraphEdgeWithMetaData,
  GraphNode,
  GraphNodeWithMetaData,
  ReferenceTypeRepresentationData,
} from '@ardoq/graph';
import {
  TriplesSortAndFiltersState,
  TripleOptions,
  TraversalBuilderState,
  RawGraphEdges,
  RawGraphNodes,
  NamedDirectedTriple,
} from 'viewpointBuilder/types';
import {
  DirectedTripleWithNodeIds,
  getComponentMetaData,
  getId,
  getReferenceMetaData,
  namedDirectedTripleToFrontendFormat,
} from './traversalAudienceHelpers';
import {
  PathsToGraphArgsWithRawGraphNodesAndEdges,
  pathToGraphOperations,
} from 'viewpointBuilder/traversals/pathsToGraph';
import { logWarn } from '@ardoq/logging';
import { pathToKey } from 'viewpointBuilder/traversals/pathToKey';
import { TraversalWithNodeIds } from './audienceTraversalDrawer$';
import { getTripleId } from 'viewpointBuilder/traversals/getTripleId';
import { isEqual, last, uniqWith } from 'lodash';
import {
  getCountKey,
  sortByKey,
  getFetchCountsArgs,
} from 'viewpointBuilder/traversals/createOptions';
import { filterOptions } from 'viewpointBuilder/traversals/editTraversalOperations';
import { ExcludeFalsy } from '@ardoq/common-helpers';

const getIncomingAndOutgoingHiddenTriplesCount = (
  tripleOptions: TripleOptions,
  unfilteredIncomingOptions: MetaModelTriple[],
  unfilteredOutgoingOptions: MetaModelTriple[]
) => {
  return {
    incomingTriplesHiddenCount:
      unfilteredIncomingOptions.length - tripleOptions.incoming.length,
    outgoingTriplesHiddenCount:
      unfilteredOutgoingOptions.length - tripleOptions.outgoing.length,
  };
};

type UpdateTripleOptionsArgs = {
  selectedComponentTypeName: string;
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>;
  triplesSortAndFiltersState: TriplesSortAndFiltersState;
  tripleOptions: TripleOptions;
  traversalStartQuery: QueryBuilderQuery;
  metamodel: MetaModel;
  graphNodes: Map<string, GraphNodeWithMetaData>;
  selectedGraphNodeId: string | null;
  traversalWithNodeIds: TraversalWithNodeIds;
};
export const updateTripleOptions = ({
  selectedComponentTypeName,
  traversal,
  triplesSortAndFiltersState,
  tripleOptions,
  traversalStartQuery,
  metamodel,
  graphNodes,
  selectedGraphNodeId,
  traversalWithNodeIds,
}: UpdateTripleOptionsArgs) => {
  const {
    tripleOptions: updatedTripleOptions,
    unfilteredIncomingOptions,
    unfilteredOutgoingOptions,
  } = getTripleOptions({
    metamodel,
    selectedComponentTypeName,
    traversal,
    triplesSortAndFiltersState,
    instanceCounts: tripleOptions.instanceCountsResponse,
    traversalStartQuery,
    optionCountLoadingState: tripleOptions.optionCountLoadingState,
    graphNodes,
    selectedGraphNodeId,
    traversalWithNodeIds,
  });

  const { outgoingTriplesHiddenCount, incomingTriplesHiddenCount } =
    getIncomingAndOutgoingHiddenTriplesCount(
      updatedTripleOptions,
      unfilteredIncomingOptions,
      unfilteredOutgoingOptions
    );
  return {
    tripleOptions: updatedTripleOptions,
    unfilteredIncomingOptions,
    unfilteredOutgoingOptions,
    outgoingTriplesHiddenCount,
    incomingTriplesHiddenCount,
    metamodel,
  };
};

type UpdateTraversalWithNodeIdsFromGraphArgs = {
  source: GraphNode | GraphNodeWithMetaData;
  target: GraphNode | GraphNodeWithMetaData;
  graphEdge: GraphEdgeWithMetaData;
  traversalWithNodeIdsMap: Map<number, DirectedTripleWithNodeIds[]>;
  pathIndex: number;
  directedTriple: DirectedTriple;
};
const updateTraversalWithNodeIdsFromGraph = ({
  source,
  target,
  graphEdge,
  traversalWithNodeIdsMap,
  pathIndex,
  directedTriple,
}: UpdateTraversalWithNodeIdsFromGraphArgs) => {
  traversalWithNodeIdsMap.set(pathIndex, [
    ...(traversalWithNodeIdsMap.get(pathIndex) ?? []),
    {
      ...directedTriple,
      sourceNodeId: source.id,
      targetNodeId: target.id,
      referenceEdgeId: graphEdge.id,
    },
  ]);
};

type PathsToGraphResult = {
  graphEdges: Map<string, GraphEdgeWithMetaData>;
  graphNodes: Map<string, GraphNodeWithMetaData>;
  instanceCounts: TraversalBuilderState['instanceCounts'];
};
const pathsToGraph = (
  data: PathsToGraphArgsWithRawGraphNodesAndEdges
): PathsToGraphResult & {
  startGraphNode: GraphNodeWithMetaData;
  traversalWithNodeIds: TraversalWithNodeIds;
} => {
  const { startType, paths, getId, instanceCounts = new Map() } = data;
  const { rawGraphNodeList, rawGraphEdgeList } =
    pathToGraphOperations.getRawNodesAndEdges(data);

  if (
    !pathToGraphOperations.getRawGraphNodeWithName(startType, rawGraphNodeList)
  ) {
    logWarn(Error(`Start type doesn't exist anymore`));

    const startGraphNode = pathToGraphOperations.getFallbackGraphNode({
      typeName: startType,
      isContext: true,
      graphNodeId: getId(),
    });

    return {
      graphEdges: new Map(),
      graphNodes: new Map([[startGraphNode.id, startGraphNode]]),
      instanceCounts,
      startGraphNode,
      traversalWithNodeIds: { paths: [] },
    };
  }

  const startGraphNode = pathToGraphOperations.addGraphNodeWithTypeName({
    typeName: startType,
    isContext: true,
    rawGraphNodeList,
    graphNodeId: getId(),
  });

  const pathKeyToGraphNode = new Map<string, GraphNode>();
  const traversalWithNodeIdsMap = new Map<
    number,
    DirectedTripleWithNodeIds[]
  >();

  const graphData = paths.reduce<PathsToGraphResult>(
    (
      { graphEdges, graphNodes, instanceCounts: currentInstanceCounts },
      path,
      pathIndex
    ) => {
      path.reduce<{
        tail: GraphNode;
        pathToHead: DirectedTripleWithFilters[];
      }>(
        (processedState, directedTriple) => {
          const { tail, pathToHead: previousPathToHead } = processedState;
          const pathToHead = [...previousPathToHead, directedTriple];
          const key = pathToKey(pathToHead);

          const { sourceType, referenceType, targetType, direction } =
            directedTriple;
          const isOutgoing = direction === ReferenceDirection.OUTGOING;
          const typeName = isOutgoing ? targetType : sourceType;
          const nodeId = getId();

          if (pathKeyToGraphNode.has(key)) {
            const graphNode = pathKeyToGraphNode.get(key)!;
            const [source, target] = isOutgoing
              ? [tail, graphNode]
              : [graphNode, tail];
            const { id: sourceId } = source;
            const { id: targetId } = target;
            const graphEdge = Array.from(graphEdges.values()).find(
              edge => edge.source === sourceId && edge.target === targetId
            );
            if (graphEdge) {
              updateTraversalWithNodeIdsFromGraph({
                source,
                target,
                graphEdge,
                traversalWithNodeIdsMap,
                pathIndex,
                directedTriple,
              });
            }
            return {
              pathToHead,
              tail: pathKeyToGraphNode.get(key)!,
            };
          }

          const graphNode = pathToGraphOperations.addGraphNodeWithTypeName({
            typeName,
            rawGraphNodeList,
            graphNodeId: nodeId,
          });
          graphNodes.set(graphNode.id, graphNode);
          pathKeyToGraphNode.set(key, graphNode);
          currentInstanceCounts.set(graphNode.id, {
            namedDirectedTriplePath: pathToHead,
            graphNodeId: graphNode.id,
            pathKey: key,
            label: graphNode.getLabel(),
          });

          const [source, target] = isOutgoing
            ? [tail, graphNode]
            : [graphNode, tail];
          const { id: sourceId, modelId: sourceModelId } = source;
          const { id: targetId, modelId: targetModelId } = target;
          const rawGraphEdge = pathToGraphOperations.getRawGraphEdge({
            referenceTypeName: referenceType,
            sourceModelId,
            targetModelId,
            rawGraphEdgeList,
          });
          if (!rawGraphEdge) {
            logWarn(Error('Raw graph edge not found'));
            return {
              tail,
              pathToHead: previousPathToHead,
              error: 'Raw graph edge not found',
            };
          }
          const graphEdge = GraphEdge.createWithMetaData(
            rawGraphEdge.modelId,
            sourceId,
            targetId,
            graphNodes,
            getId(),
            rawGraphEdge.metaData
          );
          updateTraversalWithNodeIdsFromGraph({
            source,
            target,
            graphEdge,
            traversalWithNodeIdsMap,
            pathIndex,
            directedTriple,
          });
          graphEdges.set(graphEdge.id, graphEdge);

          return {
            pathToHead,
            tail: graphNode,
          };
        },
        { tail: startGraphNode, pathToHead: [] }
      );
      return {
        graphEdges,
        graphNodes,
        instanceCounts: currentInstanceCounts,
        traversalWithNodeIdsMap,
      };
    },
    {
      graphEdges: new Map(),
      graphNodes: new Map([[startGraphNode.id, startGraphNode]]),
      instanceCounts,
    }
  );
  return {
    ...graphData,
    startGraphNode,
    traversalWithNodeIds: {
      paths: Array.from(traversalWithNodeIdsMap.values()),
    },
  };
};

const createRawGraphEdges = (
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>,
  metamodel: MetaModel
): RawGraphEdges => {
  const graphEdges = new Map<
    string,
    {
      modelId: string;
      sourceId: string;
      targetId: string;
      metaData: {
        representationData: ReferenceTypeRepresentationData;
      };
    }
  >();
  traversal.paths.forEach(path => {
    path.forEach(triple => {
      const referenceTypeId = getTripleId({
        sourceId: triple.sourceType,
        targetId: triple.targetType,
        referenceId: triple.referenceType,
      });
      const graphEdge = {
        modelId: referenceTypeId,
        sourceId: triple.sourceType,
        targetId: triple.targetType,
        metaData: {
          ...getReferenceMetaData(metamodel, triple.referenceType),
        },
      };
      graphEdges.set(getId(), graphEdge);
    });
  });
  return graphEdges;
};

const createRawGraphNodes = (
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>,
  surveyComponentTypeName: string,
  metamodel: MetaModel
): RawGraphNodes => {
  const graphNodes = new Map<
    string,
    {
      modelId: string;
      label: string;
      metaData: {
        representationData: ComponentRepresentationData & {
          shadedColor: string;
          lightenedColor: string;
          contrastColor: string;
        };
        color: string;
        typeId: string;
        label: string;
      };
    }
  >();

  const surveyComponentTypeGraphNode = {
    modelId: surveyComponentTypeName,
    label: surveyComponentTypeName,
    metaData: {
      ...getComponentMetaData(metamodel, surveyComponentTypeName),
    },
  };

  graphNodes.set(getId(), surveyComponentTypeGraphNode);

  traversal.paths.forEach(path => {
    path.forEach(triple => {
      const sourceGraphNode = {
        modelId: triple.sourceType,
        label: triple.sourceType,
        metaData: {
          ...getComponentMetaData(metamodel, triple.sourceType),
        },
      };
      graphNodes.set(getId(), sourceGraphNode);
      const targetGraphNode = {
        modelId: triple.targetType,
        label: triple.targetType,
        metaData: {
          ...getComponentMetaData(metamodel, triple.targetType),
        },
      };
      graphNodes.set(getId(), targetGraphNode);
    });
  });
  return graphNodes;
};

type CalculateGraphArgs = {
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>;
  surveyComponentTypeName: string;
  metamodel: MetaModel;
  selectedGraphNodeId: string | null;
};
const calculateGraph = ({
  traversal,
  surveyComponentTypeName,
  metamodel,
  selectedGraphNodeId,
}: CalculateGraphArgs) => {
  const rawGraphNodes = createRawGraphNodes(
    traversal,
    surveyComponentTypeName,
    metamodel
  );

  const rawGraphEdges = createRawGraphEdges(traversal, metamodel);
  const {
    graphNodes,
    graphEdges, // instanceCounts: instanceCountsMap,
    startGraphNode,
    traversalWithNodeIds,
  } = pathsToGraph({
    paths: traversal.paths,
    startType: surveyComponentTypeName,
    getId,
    startNode: null,
    rawGraphEdges,
    rawGraphNodes,
  });

  const {
    graphNodes: updatedGraphNodes,
    graphEdges: updatedGraphEdges,
    selectedGraphNodeId: newlySelectedGraphNodeId,
    selectedComponentTypeName: newlySelectedComponentTypeName,
  } = updateGraph(
    surveyComponentTypeName,
    selectedGraphNodeId,
    graphNodes,
    graphEdges
  );
  return {
    graphNodes: updatedGraphNodes,
    graphEdges: updatedGraphEdges,
    selectedGraphNodeId: newlySelectedGraphNodeId,
    selectedComponentTypeName: newlySelectedComponentTypeName,
    startGraphNode,
    traversalWithNodeIds,
  };
};

type CreateGraphArgs = {
  surveyComponentTypeName: string;
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>;
  metamodel: MetaModel;
  selectedGraphNodeId: string | null;
};
export const createGraph = ({
  surveyComponentTypeName,
  traversal,
  metamodel,
  selectedGraphNodeId,
}: CreateGraphArgs) => {
  const {
    graphEdges,
    graphNodes,
    selectedComponentTypeName,
    startGraphNode,
    traversalWithNodeIds,
  } = calculateGraph({
    traversal,
    surveyComponentTypeName,
    metamodel,
    selectedGraphNodeId,
  });
  return {
    graphEdges,
    graphNodes,
    selectedGraphNodeId: startGraphNode.id,
    selectedComponentTypeName,
    metamodel,
    startNode: startGraphNode,
    traversalWithNodeIds,
  };
};

export const getStartQuery = (
  surveyComponentTypeName: string,
  workspaceId: ArdoqId
): QueryBuilderQuery => {
  return {
    condition: BooleanOperator.AND,
    rules: [
      {
        id: 'type',
        field: 'type',
        type: 'string',
        input: 'select',
        operator: Operator.EQUAL,
        value: SearchContext.COMPONENT,
      },
      {
        condition: BooleanOperator.AND,
        rules: [
          {
            id: 'typeName',
            field: 'typeName',
            input: 'text',
            type: 'string',
            operator: Operator.EQUAL,
            value: surveyComponentTypeName,
          },
          {
            field: 'rootWorkspace',
            id: 'rootWorkspace',
            input: 'text',
            operator: Operator.EQUAL,
            type: 'string',
            value: workspaceId,
          },
        ],
      },
    ],
  };
};

export const updateGraph = (
  surveyComponentTypeName: string,
  selectedGraphNodeId: string | null,
  graphNodes: Map<string, GraphNodeWithMetaData>,
  graphEdges: Map<string, GraphEdgeWithMetaData>
) => {
  const newlySelectedGraphNode =
    graphNodes.get(selectedGraphNodeId ?? '') ??
    graphNodes.entries().next().value?.[1];

  const newlySelectedGraphNodeId = newlySelectedGraphNode?.id ?? null;
  const graphNodesWitHighlighting = new Map(graphNodes);

  if (newlySelectedGraphNodeId) {
    Array.from(graphNodesWitHighlighting.values()).forEach(graphNode => {
      graphNode.setIsSelected(false);
    });
    graphNodesWitHighlighting
      .get(newlySelectedGraphNodeId)
      ?.setIsSelected(true);
  }
  return {
    graphNodes: graphNodesWitHighlighting,
    graphEdges,
    selectedGraphNodeId: newlySelectedGraphNodeId,
    selectedComponentTypeName:
      newlySelectedGraphNode?.getLabel() ?? surveyComponentTypeName,
  };
};

const getIsSelected = (
  traversalWithNodeIds: TraversalWithNodeIds,
  selectedGraphNodeId: string | null,
  namedDirectedTriple: NamedDirectedTriple
) => {
  const relevantTriplesWithoutNodeIds = traversalWithNodeIds.paths.flatMap(
    path =>
      path
        .filter(triple => {
          return triple.direction === 'incoming'
            ? triple.targetNodeId === selectedGraphNodeId
            : triple.sourceNodeId === selectedGraphNodeId;
        })
        .map(({ direction, sourceType, targetType, referenceType }) => ({
          direction,
          sourceType,
          targetType,
          referenceType,
        }))
  );

  return relevantTriplesWithoutNodeIds.some(triple =>
    isEqual(triple, namedDirectedTriple)
  );
};

const isSourceOrTargetNode = (
  triple: DirectedTripleWithNodeIds,
  selectedGraphNodeId: string | null
) => {
  return triple.direction === 'outgoing'
    ? triple.targetNodeId === selectedGraphNodeId
    : triple.sourceNodeId === selectedGraphNodeId;
};

const findCurrentPath = (
  traversalWithNodeIds: TraversalWithNodeIds,
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>,
  selectedGraphNodeId: string | null
): DirectedTripleWithFilters[] => {
  const indicesOfFirstMatchingPathTriple = traversalWithNodeIds.paths.reduce<{
    pathIndex: number;
    tripleIndex: number;
  } | null>((acc, path, pathIndex) => {
    // we can just return if we have a match already
    if (acc !== null) return acc;
    const matchingTripleIndex = path.findIndex(triple => {
      return isSourceOrTargetNode(triple, selectedGraphNodeId) ?? -1;
    });
    if (matchingTripleIndex === -1) return acc;
    return { pathIndex, tripleIndex: matchingTripleIndex };
  }, null);

  if (!indicesOfFirstMatchingPathTriple) {
    return [];
  }

  const updatedPaths = traversal.paths[
    indicesOfFirstMatchingPathTriple.pathIndex
  ].slice(0, indicesOfFirstMatchingPathTriple.tripleIndex + 1);

  return updatedPaths;
};

const getIncomingTriples = (
  metamodel: MetaModel,
  selectedComponentTypeName: string
) => {
  return metamodel.triples.filter(
    triple => triple.targetComponentType === selectedComponentTypeName
  );
};

const getOutgoingTriples = (
  metamodel: MetaModel,
  selectedComponentTypeName: string
) => {
  return metamodel.triples.filter(
    triple => triple.sourceComponentType === selectedComponentTypeName
  );
};

type GetTripleOptionsArgs = {
  traversalWithNodeIds: TraversalWithNodeIds;
  metamodel: MetaModel;
  selectedComponentTypeName: string;
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>;
  triplesSortAndFiltersState: TriplesSortAndFiltersState;
  instanceCounts: APICountInstancesResponse | null;
  traversalStartQuery: QueryBuilderQuery;
  optionCountLoadingState: 'idle' | 'loading' | 'loaded' | 'error';
  graphNodes: Map<string, GraphNodeWithMetaData>;
  selectedGraphNodeId: string | null;
};
export const getTripleOptions = ({
  traversalWithNodeIds,
  metamodel,
  selectedComponentTypeName,
  traversal,
  triplesSortAndFiltersState,
  instanceCounts,
  traversalStartQuery,
  optionCountLoadingState,
  graphNodes,
  selectedGraphNodeId,
}: GetTripleOptionsArgs): {
  tripleOptions: TripleOptions;
  unfilteredIncomingOptions: MetaModelTriple[];
  unfilteredOutgoingOptions: MetaModelTriple[];
} => {
  const selectedNodeIsContextNode = Boolean(
    graphNodes.get(selectedGraphNodeId ?? '')?.metaData.isContext
  );
  const currentPath = selectedNodeIsContextNode
    ? []
    : findCurrentPath(traversalWithNodeIds, traversal, selectedGraphNodeId);

  const { traversalCounts } = instanceCounts ?? {};
  const counts = new Map(
    (traversalCounts ?? []).map(({ path, counts: currentCounts }) => [
      pathToKey(path),
      last(currentCounts),
    ])
  );

  const incomingTriples = getIncomingTriples(
    metamodel,
    selectedComponentTypeName
  );

  const incoming = incomingTriples.map(triple => {
    const tripleId = getTripleId({
      sourceId: triple.sourceComponentType,
      targetId: triple.targetComponentType,
      referenceId: triple.referenceType,
    });

    const namedDirectedTriple: NamedDirectedTriple = {
      direction: ReferenceDirection.INCOMING,
      sourceType: triple.sourceComponentType,
      referenceType: triple.referenceType,
      targetType: triple.targetComponentType,
    };

    const countKey = getCountKey({
      currentPath,
      namedDirectedTriple,
    });

    return {
      tripleId,
      isSelected: getIsSelected(
        traversalWithNodeIds,
        selectedGraphNodeId,
        namedDirectedTriple
      ),
      metaDataComponent: getComponentMetaData(
        metamodel,
        triple.sourceComponentType
      ),
      metaDataReference: getReferenceMetaData(metamodel, triple.referenceType),
      namedDirectedTriple,
      countKey,
      instanceCounts: counts.get(countKey) ?? null,
      isDisabled: false,
    };
  });

  const outgoingTriples = getOutgoingTriples(
    metamodel,
    selectedComponentTypeName
  );
  const outgoing = outgoingTriples.map(triple => {
    const tripleId = getTripleId({
      sourceId: triple.sourceComponentType,
      targetId: triple.targetComponentType,
      referenceId: triple.referenceType,
    });

    const namedDirectedTriple: NamedDirectedTriple = {
      direction: ReferenceDirection.OUTGOING,
      sourceType: triple.sourceComponentType,
      referenceType: triple.referenceType,
      targetType: triple.targetComponentType,
    };

    const countKey = getCountKey({
      currentPath,
      namedDirectedTriple,
    });

    return {
      tripleId,
      isSelected: getIsSelected(
        traversalWithNodeIds,
        selectedGraphNodeId,
        namedDirectedTriple
      ),
      metaDataComponent: getComponentMetaData(
        metamodel,
        triple.targetComponentType
      ),
      metaDataReference: getReferenceMetaData(metamodel, triple.referenceType),
      namedDirectedTriple,
      countKey,
      instanceCounts: counts.get(countKey) ?? null,
      isDisabled: false,
    };
  });

  const filteredAndSortedIncomingOptions = filterOptions(
    incoming,
    triplesSortAndFiltersState.filterTerm,
    triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts
  ).sort(sortByKey(triplesSortAndFiltersState.tripleSortOrder));

  const filteredAndSortedOutgoingOptions = filterOptions(
    outgoing,
    triplesSortAndFiltersState.filterTerm,
    triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts
  ).sort(sortByKey(triplesSortAndFiltersState.tripleSortOrder));

  const tripleOptions: TripleOptions = {
    incoming: filteredAndSortedIncomingOptions,
    outgoing: filteredAndSortedOutgoingOptions,
    instanceCountsResponse: instanceCounts ?? null,
    selectedNode: {
      metaDataComponent: getComponentMetaData(
        metamodel,
        selectedComponentTypeName
      ),
    },
    selectedEdge: undefined,
    fetchCountsArgs: getFetchCountsArgs({
      incomingOptions: incoming,
      outgoingOptions: outgoing,
      startSet: null,
      startQuery: traversalStartQuery,
      currentPath,
      filters: {},
      startSetFilterId: null,
      pathMatching: TraversalPathMatchingType.LOOSE,
    }),
    optionCountLoadingState,
    showOnlyOptionsWithInstanceCounts:
      triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts,
  };
  return {
    tripleOptions,
    unfilteredIncomingOptions: incomingTriples,
    unfilteredOutgoingOptions: outgoingTriples,
  };
};

type AddNodeAndEdgeArgs = {
  graphNodeId: string;
  direction: ReferenceDirection;
  graphNodes: Map<string, GraphNodeWithMetaData>;
  graphEdges: Map<string, GraphEdgeWithMetaData>;
  metamodel: MetaModel;
  namedDirectedTriple: DirectedTripleWithFilters;
};
const addNodeAndEdge = ({
  graphNodeId,
  direction,
  graphNodes,
  graphEdges,
  metamodel,
  namedDirectedTriple,
}: AddNodeAndEdgeArgs) => {
  const { sourceType, targetType, referenceType } = namedDirectedTriple;
  const componentId =
    direction === ReferenceDirection.OUTGOING ? targetType : sourceType;

  const newGraphNode = GraphNode.createWithMetaData(
    componentId,
    false,
    getId(),
    {
      ...getComponentMetaData(metamodel, componentId),
    }
  );
  graphNodes.set(newGraphNode.id, newGraphNode);

  const graphEdge = GraphEdge.createWithMetaData(
    referenceType,
    direction === ReferenceDirection.OUTGOING ? graphNodeId : newGraphNode.id,
    direction === ReferenceDirection.OUTGOING ? newGraphNode.id : graphNodeId,
    graphNodes,
    getId(),
    getReferenceMetaData(metamodel, referenceType)
  );
  graphEdges.set(graphEdge.id, graphEdge);

  return { graphNodes, graphEdges, graphEdge };
};

const isBranch = (
  path: DirectedTripleWithNodeIds[],
  selectedGraphNodeId: string
) => {
  const endOfPath = path[path.length - 1];
  const isBranchAtEndOfPath =
    (endOfPath.sourceNodeId === selectedGraphNodeId &&
      endOfPath.direction === 'outgoing') ||
    (endOfPath.targetNodeId === selectedGraphNodeId &&
      endOfPath.direction === 'incoming');
  if (isBranchAtEndOfPath) return true;
  const middleOfPath = path.slice(1, path.length - 1);
  const isSelectedGraphNodeIdInMiddleOfPath = middleOfPath.some(triple => {
    return (
      triple.sourceNodeId === selectedGraphNodeId ||
      triple.targetNodeId === selectedGraphNodeId
    );
  });
  if (isSelectedGraphNodeIdInMiddleOfPath) return true;
  return false;
};

type WithTripleAddedArgs = {
  traversalWithNodeIds: TraversalWithNodeIds;
  selectedComponentTypeName: string;
  direction: ReferenceDirection;
  namedDirectedTriple: DirectedTripleWithFilters;
  graphNodes: Map<string, GraphNodeWithMetaData>;
  graphEdges: Map<string, GraphEdgeWithMetaData>;
  selectedGraphNodeId: string | null;
  metamodel: MetaModel;
  startNode: GraphNodeWithMetaData | undefined;
};

export const withTripleAdded = ({
  traversalWithNodeIds,
  selectedComponentTypeName,
  direction,
  namedDirectedTriple,
  graphNodes,
  graphEdges,
  selectedGraphNodeId,
  metamodel,
  startNode,
}: WithTripleAddedArgs) => {
  const newGraphNodes = new Map(graphNodes);
  const newGraphEdges = new Map(graphEdges);

  const newTripleStartTypeName =
    namedDirectedTriple.direction === 'outgoing'
      ? namedDirectedTriple.sourceType
      : namedDirectedTriple.targetType;
  if (
    newTripleStartTypeName !== selectedComponentTypeName ||
    !selectedGraphNodeId
  )
    return { graphNodes, graphEdges, traversalWithNodeIds };

  const {
    graphNodes: updatedGraphNodes,
    graphEdges: updatedGraphEdges,
    graphEdge,
  } = addNodeAndEdge({
    graphNodeId: selectedGraphNodeId,
    direction,
    graphNodes: newGraphNodes,
    graphEdges: newGraphEdges,
    metamodel,
    namedDirectedTriple,
  });

  const selectedNode = updatedGraphNodes.get(selectedGraphNodeId);
  if (!startNode || !selectedNode)
    return {
      graphNodes: updatedGraphNodes,
      graphEdges: updatedGraphEdges,
      traversalWithNodeIds,
    };

  const sourceNode = updatedGraphNodes.get(graphEdge.source);
  const targetNode = updatedGraphNodes.get(graphEdge.target);

  if (!sourceNode || !targetNode) {
    return { graphNodes, graphEdges, traversalWithNodeIds };
  }

  const newTriple: DirectedTripleWithNodeIds = {
    sourceNodeId: sourceNode.id,
    targetNodeId: targetNode.id,
    sourceType: sourceNode.modelId,
    targetType: targetNode.modelId,
    referenceType: graphEdge.modelId,
    referenceEdgeId: graphEdge.id,
    direction: direction as TripleDirection,
  };

  const relevantPathIndex = traversalWithNodeIds.paths.findIndex(path => {
    return path.some(triple => {
      if (
        triple.direction === 'incoming' &&
        triple.sourceNodeId === selectedNode.id
      ) {
        return true;
      }
      if (
        triple.direction === 'outgoing' &&
        triple.targetNodeId === selectedNode.id
      ) {
        return true;
      }
      return false;
    });
  });

  if (relevantPathIndex === -1) {
    // we always add triples which include the start node at the base level of the paths
    const updatedPaths = [...traversalWithNodeIds.paths, [newTriple]];
    return {
      graphNodes: updatedGraphNodes,
      graphEdges: updatedGraphEdges,
      traversalWithNodeIds: { ...traversalWithNodeIds, paths: updatedPaths },
    };
  }

  const relevantPath = traversalWithNodeIds.paths[relevantPathIndex];
  const indexOfTripleEndingWithSelectedNodeInPath = relevantPath.findIndex(
    triple => {
      if (
        triple.direction === 'incoming' &&
        triple.sourceNodeId === selectedNode.id
      ) {
        return true;
      }
      if (
        triple.direction === 'outgoing' &&
        triple.targetNodeId === selectedNode.id
      ) {
        return true;
      }
      return false;
    }
  );

  // if the new triple is creating a branch, we need to create a fresh path from the start node out
  if (isBranch(relevantPath, selectedNode.id)) {
    const pathToAdd = [
      ...relevantPath.slice(0, indexOfTripleEndingWithSelectedNodeInPath + 1),
      newTriple,
    ];
    const updatedPaths = [...traversalWithNodeIds.paths, pathToAdd];
    return {
      graphNodes: updatedGraphNodes,
      graphEdges: updatedGraphEdges,
      traversalWithNodeIds: { ...traversalWithNodeIds, paths: updatedPaths },
    };
  }

  // we aren't branching so we can just add to the existing found path
  const subPath = relevantPath.slice(
    0,
    indexOfTripleEndingWithSelectedNodeInPath + 1
  );
  const subPathWithTripleAppended = [...subPath, newTriple];
  const updatedPaths = [
    ...traversalWithNodeIds.paths.slice(0, relevantPathIndex),
    subPathWithTripleAppended,
    ...traversalWithNodeIds.paths.slice(relevantPathIndex + 1),
  ];

  return {
    graphNodes: updatedGraphNodes,
    graphEdges: updatedGraphEdges,
    traversalWithNodeIds: { ...traversalWithNodeIds, paths: updatedPaths },
  };
};

type RemoveEdgeAndNodesFromGraphArgs = {
  path: DirectedTripleWithNodeIds[];
  tripleIndex: number;
  graphNodes: Map<string, GraphNodeWithMetaData>;
  graphEdges: Map<string, GraphEdgeWithMetaData>;
  startGraphNodeId: string | undefined;
};
const removeEdgeAndNodesFromGraph = ({
  path,
  tripleIndex,
  graphNodes,
  graphEdges,
  startGraphNodeId,
}: RemoveEdgeAndNodesFromGraphArgs) => {
  const triplesEdgesToRemove = path.slice(tripleIndex);
  triplesEdgesToRemove.forEach(triple =>
    graphEdges.delete(triple.referenceEdgeId)
  );
  const nodeIds = Array.from(graphNodes.keys()).filter(nodeId => {
    if (startGraphNodeId === nodeId) return false;
    return !Array.from(graphEdges.values()).some(
      edge => edge.source === nodeId || edge.target === nodeId
    );
  });
  nodeIds.forEach(nodeId => graphNodes.delete(nodeId));
};

const isSubPath = (
  path: DirectedTripleWithNodeIds[],
  otherPath: DirectedTripleWithNodeIds[]
) => {
  return path.every(triple => {
    return otherPath.find(otherPathTriple => isEqual(otherPathTriple, triple));
  });
};

type WithTriplesRemovedArgs = {
  traversalWithNodeIds: TraversalWithNodeIds;
  namedDirectedTriple: DirectedTripleWithFilters;
  graphNodes: Map<string, GraphNodeWithMetaData>;
  graphEdges: Map<string, GraphEdgeWithMetaData>;
  selectedGraphNodeId: string | null;
  startGraphNodeId: string | undefined;
};
export const withTriplesRemoved = ({
  traversalWithNodeIds,
  namedDirectedTriple,
  graphNodes,
  graphEdges,
  selectedGraphNodeId,
  startGraphNodeId,
}: WithTriplesRemovedArgs) => {
  const updatedGraphEdges = new Map(graphEdges);
  const updatedGraphNodes = new Map(graphNodes);

  const matchingIndices = traversalWithNodeIds.paths.reduce<
    | {
        tripleIndex: number;
      }[]
    | null
  >((acc, path) => {
    const matchingTripleIndex = path.findIndex((triple, tripleIndex) => {
      const tripleWithoutIds = {
        sourceType: triple.sourceType,
        referenceType: triple.referenceType,
        targetType: triple.targetType,
        direction: triple.direction,
      };
      const relevantNodeId =
        triple.direction === 'outgoing'
          ? triple.sourceNodeId
          : triple.targetNodeId;

      const isAMatch =
        isEqual(tripleWithoutIds, namedDirectedTriple) &&
        relevantNodeId === selectedGraphNodeId;
      if (isAMatch) {
        removeEdgeAndNodesFromGraph({
          path,
          tripleIndex,
          graphNodes: updatedGraphNodes,
          graphEdges: updatedGraphEdges,
          startGraphNodeId,
        });
      }
      return isAMatch;
    });
    return [
      ...(acc ?? []),
      {
        tripleIndex: matchingTripleIndex,
      },
    ];
  }, null);

  if (!matchingIndices) return { graphNodes, graphEdges, traversalWithNodeIds };

  const updatedPaths = uniqWith(
    matchingIndices
      .map(({ tripleIndex }, pathIndex) => {
        if (tripleIndex === -1) return traversalWithNodeIds.paths[pathIndex];
        const updatedPath = traversalWithNodeIds.paths[pathIndex].slice(
          0,
          tripleIndex
        );
        if (tripleIndex === 1) return updatedPath;

        const restOfPaths = traversalWithNodeIds.paths.filter(
          (_path, index) => pathIndex !== index
        );

        const updatedPathIsSubPathOfAnotherPath = restOfPaths.some(
          otherPath => {
            return isSubPath(updatedPath, otherPath);
          }
        );
        // in some cases a path only exists because of a triple which creates a branch
        // if this triple is removed, the entire path becomes redundant
        if (updatedPathIsSubPathOfAnotherPath) return null;
        return updatedPath;
      })
      .filter(ExcludeFalsy)
      .filter((path, pathIndex) => {
        // it some cases once you remove the triple, the path is no longer valid as it doesn't start from the start node anymore
        // we need to remove these also
        const isOrphanedPath =
          path[0]?.sourceNodeId !== startGraphNodeId &&
          path[0]?.targetNodeId !== startGraphNodeId;
        if (isOrphanedPath) {
          removeEdgeAndNodesFromGraph({
            path,
            tripleIndex: matchingIndices[pathIndex].tripleIndex,
            graphNodes: updatedGraphNodes,
            graphEdges: updatedGraphEdges,
            startGraphNodeId,
          });
        }
        return !isOrphanedPath;
      }),
    isEqual
  );

  return {
    graphNodes: updatedGraphNodes,
    graphEdges: updatedGraphEdges,
    traversalWithNodeIds: { ...traversalWithNodeIds, paths: updatedPaths },
  };
};

type WithPathRemovedArgs = {
  traversalWithNodeIds: TraversalWithNodeIds;
  path: DirectedTripleWithFilters[];
  existingTraversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>;
};
export const withPathRemoved = ({
  traversalWithNodeIds,
  path,
  existingTraversal,
}: WithPathRemovedArgs) => {
  const indexOfPathToRemove = existingTraversal.paths.findIndex(
    existingPath => {
      return isEqual(path, existingPath);
    }
  );
  const updatedPaths = traversalWithNodeIds.paths
    .slice(0, indexOfPathToRemove)
    .concat(traversalWithNodeIds.paths.slice(indexOfPathToRemove + 1));

  return createTraversalFromTraversalToNodeIdPaths(
    existingTraversal,
    updatedPaths
  );
};

export const createTraversalFromTraversalToNodeIdPaths = (
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>,
  paths: DirectedTripleWithNodeIds[][]
) => {
  return {
    ...traversal,
    paths: paths.map(path =>
      path.map(({ sourceType, targetType, referenceType, direction }) => ({
        sourceType,
        targetType,
        referenceType,
        direction,
      }))
    ),
  };
};

export const toNormalizedTraversal = (
  traversal: Pick<APIViewpointAttributes, 'paths' | 'filters'>
) => {
  return {
    ...traversal,
    paths: traversal.paths.map(path =>
      path.map(namedDirectedTripleToFrontendFormat)
    ),
  };
};
