import { Icon, IconName } from '@ardoq/icons';
import {
  PanelContainer,
  PanelFooter,
  PanelHeader,
  SectionsContainer,
} from 'appLayout/ardoqStudio/saveAsViewpoint/atoms';
import { GhostButton } from '@ardoq/button';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { hideViewSidePanel } from 'views/mainApp/viewSidePanel/actions';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import {
  ViewpointSwitcherViewModel,
  viewpointSwitcherViewModel$,
} from './viewpointSwitcherViewModel$';
import { pluralizeWithCount } from '@ardoq/common-helpers';
import { Header5, Paragraph } from '@ardoq/typography';
import styled from 'styled-components';
import { colors, radius, spacing } from '@ardoq/design-tokens';
import { TextOverflow } from '@ardoq/popovers';
import { contextToolBarCommands } from '../contextToolBarCommands';
import ViewpointThumbnail from './ViewpointThumbnail';

const ViewpointButton = styled.button`
  padding: 0;
  overflow: hidden;
  border: 1px solid ${colors.borderSubtle00};
  background: none;
  display: flex;
  flex-flow: column;
  gap: ${spacing.s8};
  width: 100%;
  cursor: pointer;
  border-radius: ${radius.medium};

  &:hover {
    border: 1px solid ${colors.borderActionHover};
  }
`;

const ViewpointSwitcher = ({
  viewpoints,
  selectedIds,
  startType,
}: ViewpointSwitcherViewModel) => {
  return (
    <PanelContainer>
      <PanelHeader>
        <Icon iconName={IconName.VIEWPOINT} />
        <span>Change viewpoint</span>
      </PanelHeader>
      <SectionsContainer>
        <Box padding="small">
          {pluralizeWithCount('viewpoint', viewpoints.length)} for type{' '}
          {startType}
        </Box>
        <Box padding="small">
          <Stack gap="small">
            {viewpoints.map(({ attributes, preview }) => (
              <FlexBox key={attributes._id} gap="small">
                <ViewpointButton
                  role="button"
                  onClick={() => {
                    contextToolBarCommands.setNewViewpoint(
                      attributes,
                      selectedIds
                    );
                  }}
                >
                  <ViewpointThumbnail {...preview} />
                  <Box padding="small">
                    <Stack gap="small" align="flex-start">
                      <TextOverflow lineClamp={2}>
                        <Header5 align="left">{attributes.name}</Header5>
                      </TextOverflow>
                      <Paragraph>{attributes.viewName}</Paragraph>
                      <TextOverflow lineClamp={2}>
                        <Paragraph align="left">
                          {attributes.description}
                        </Paragraph>
                      </TextOverflow>
                    </Stack>
                  </Box>
                </ViewpointButton>
              </FlexBox>
            ))}
          </Stack>
        </Box>
      </SectionsContainer>
      <PanelFooter $padding="s16" $alignToRight>
        <Stack align="flex-end">
          <FlexBox gap="medium" align="flex-end">
            <GhostButton
              onClick={() => {
                dispatchAction(hideViewSidePanel());
              }}
            >
              Cancel
            </GhostButton>
          </FlexBox>
        </Stack>
      </PanelFooter>
    </PanelContainer>
  );
};
export default connect(ViewpointSwitcher, viewpointSwitcherViewModel$);
