import {
  APIOrganizationUser,
  ArdoqId,
  AssetType,
  OrgAccessLevel,
  PermissionGroup,
  ResourceType,
  UserWithRoles,
} from '@ardoq/api-types';
import {
  GroupOrUserSelectOption,
  FlatOrNestedSelectOptions,
  getPermissionAccessLevelLabel,
  getHighestPermissionLevel,
} from '@ardoq/manage-resource-permissions';
import { IconName } from '@ardoq/icons';
import { difference, find } from 'lodash';
import { trackEvent } from 'tracking/tracking';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import {
  ApiPutResourcePermissionPayload,
  ResourcesPermissionEditorEvents,
} from './types';
import { api, userApi } from '@ardoq/api';
import {
  caseInsensitiveStringIncludes,
  isArdoqError,
} from '@ardoq/common-helpers';

const getUserSelectOption = (user: UserWithRoles): GroupOrUserSelectOption => ({
  value: user._id,
  label: `${user.name} (${user.email})`,
  iconName: IconName.PERSON,
  option: user,
});

export const getGroupSelectOptions = (
  group: PermissionGroup
): GroupOrUserSelectOption => ({
  value: group._id,
  label: group.name,
  iconName: IconName.PEOPLE,
  option: group,
});

export const getUsers = async (
  searchQuery: string = '',
  orgMembersOnly = true,
  limit: number = 100
) => {
  const response = await userApi.search(searchQuery, orgMembersOnly, limit);
  if (isArdoqError(response)) {
    api.logErrorIfNeededAndReturnMessage(
      response,
      'Error while searching for user or groups'
    );
    return [];
  }
  return response;
};

export const getUserAndGroupOptions =
  (
    groups: PermissionGroup[],
    hideUserIds?: string[],
    orgMembersOnly?: boolean
  ) =>
  async (
    query: string
  ): Promise<FlatOrNestedSelectOptions<GroupOrUserSelectOption>[]> => {
    const userOptions = (await getUsers(query, orgMembersOnly))
      .filter(({ _id }) => !hideUserIds || !hideUserIds.includes(_id))
      .map(getUserSelectOption);
    const groupOptions = (groups || [])
      .filter(({ name }) => caseInsensitiveStringIncludes(name, query))
      .map(getGroupSelectOptions);
    return [
      {
        label: 'Groups',
        options: groupOptions,
      },
      {
        label: 'Users',
        options: userOptions,
      },
    ];
  };

const getRoleByUserId = (
  orgMembers: APIOrganizationUser[],
  userId: ArdoqId
): OrgAccessLevel | '' => {
  const { role } = find(orgMembers, { _id: userId }) || {
    role: '',
  };
  return role;
};

export const changeAwarePermissionUpdatesTracker = ([
  { permissionsUpdates, prevPermissionsByResource },
  { users },
]: [ApiPutResourcePermissionPayload, { users: APIOrganizationUser[] }]) => {
  permissionsUpdates.forEach(currentResourcePermissionUpdate => {
    const prevPermissions =
      prevPermissionsByResource[currentResourcePermissionUpdate._id];
    const usersPermissionsUpdates = difference(
      currentResourcePermissionUpdate.permissions,
      prevPermissions.permissions
    );
    usersPermissionsUpdates.forEach(({ _id, permissions }) => {
      trackEvent(APPSEC_EVENTS.UPDATED_USER_ASSET_PERMISSIONS, {
        userId: _id,
        permission: getPermissionAccessLevelLabel(
          getHighestPermissionLevel(permissions),
          currentResourcePermissionUpdate['resource-type']
        ),
        assetType: currentResourcePermissionUpdate['resource-type'],
        role: getRoleByUserId(users, _id),
      });
    });

    const groupsPermissionsUpdates = difference(
      currentResourcePermissionUpdate.groups || [],
      prevPermissions.groups || []
    );
    groupsPermissionsUpdates.forEach(({ permissions }) => {
      trackEvent(APPSEC_EVENTS.UPDATED_GROUP_ASSET_PERMISSIONS, {
        permission: getPermissionAccessLevelLabel(
          getHighestPermissionLevel(permissions),
          currentResourcePermissionUpdate['resource-type']
        ),
        assetType: currentResourcePermissionUpdate['resource-type'],
      });
    });
  });
};

export const trackResourcesPermissionEditorEvent = <T extends object>(
  event: ResourcesPermissionEditorEvents,
  meta?: T
) => trackEvent(`Manage Resource Permissions Dialog0 ${event}`, meta);

const assetsResourceTypesMap: Record<
  Exclude<AssetType, AssetType.PRESENTATION>,
  ResourceType
> = {
  [AssetType.DASHBOARD]: ResourceType.DASHBOARD,
  [AssetType.WORKSPACE]: ResourceType.WORKSPACE,
  [AssetType.METAMODEL]: ResourceType.MODEL,
  [AssetType.SURVEY]: ResourceType.SURVEY,
  [AssetType.SCENARIO]: ResourceType.SCENARIO,
  [AssetType.REPORT]: ResourceType.REPORT,
  [AssetType.FOLDER]: ResourceType.WORKSPACEFOLDER,
  [AssetType.VIEWPOINT]: ResourceType.VIEWPOINT,
  [AssetType.BROADCAST]: ResourceType.BROADCAST,
  [AssetType.TRAVERSAL]: ResourceType.TRAVERSAL,
  [AssetType.BOOKMARK]: ResourceType.BOOKMARK,
};

export const assetTypeToResourceType = ({
  assetType,
}: {
  assetType: AssetType;
}): ResourceType => {
  if (assetType === AssetType.PRESENTATION) {
    return ResourceType.WORKSPACE;
  }
  return assetsResourceTypesMap[assetType] || ResourceType.WORKSPACE;
};
