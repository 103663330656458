import {
  AsyncLabelLoaders,
  AsyncSuggestionsLoaders,
  QueryBuilder,
  QueryBuilderProps,
} from '@ardoq/query-builder';
import {
  getLoadComponentSuggestionsAsync,
  getLoadTagSuggestionsAsync,
  getLoadUserSuggestionsAsync,
  getLoadWorkspaceSuggestionsAsync,
  getLoadComponentTypeNameSuggestionsAsync,
  getLoadReferenceTypeNameSuggestionsAsync,
} from '../AdvancedSearch/fetchSuggestionsUtils';
import { ArdoqId, ZonesBySubdivisionsIds } from '@ardoq/api-types';
import { isArdoqError } from '@ardoq/common-helpers';
import { componentApi, referenceApi, workspaceApi } from '@ardoq/api';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';

type QueryBuilderWithSuggestionsLoadersProps = Omit<
  QueryBuilderProps,
  'asyncSuggestionsLoaders' | 'asyncLabelLoaders'
>;

const getComponentName = async (id: ArdoqId): Promise<string | undefined> => {
  const componentName = componentInterface.getDisplayName(id);
  if (componentName) return componentName;
  const comp = await componentApi.fetch(id);
  if (isArdoqError(comp)) return;
  return comp.name || comp._id || 'Name not found';
};

const getReferenceName = async (id: string): Promise<string | undefined> => {
  const referenceName = referenceInterface.getDisplayText(id);
  if (referenceName) return referenceName;
  const ref = await referenceApi.fetch(id);
  if (isArdoqError(ref)) return;
  return ref.displayText || ref._id || 'Name not found';
};

const getWorkspaceName = async (id: ArdoqId): Promise<string | undefined> => {
  const workspaceName = workspaceInterface.getWorkspaceName(id);
  if (workspaceName) return workspaceName;
  const ws = await workspaceApi.fetch(id);
  if (isArdoqError(ws)) return;
  return ws.name || ws._id || 'Name not found';
};

const getUserName = async (id: ArdoqId): Promise<string | undefined> => {
  const user = orgUsers$.state.byId[id];
  return user ? `${user.name} (${user.email})` : undefined;
};

export const getQueryBuilderLoaders = (
  workspaceIds?: ArdoqId[],
  subdivisions?: ZonesBySubdivisionsIds
): {
  asyncSuggestionsLoaders: AsyncSuggestionsLoaders;
  asyncLabelLoaders: AsyncLabelLoaders;
} => ({
  asyncSuggestionsLoaders: {
    loadReferenceOIDSuggestionsAsync: async () => [], // we don't have proper suggestion endpoint to fetch data
    loadComponentKeySuggestionsAsync: async () => [], // we don't have proper suggestion endpoint to fetch data
    loadComponentSuggestionsIncludingCurrentComponentAsync:
      getLoadComponentSuggestionsAsync({ workspaceIds, subdivisions }),
    loadParentComponentSuggestionsAsync: getLoadComponentSuggestionsAsync({
      workspaceIds,
      subdivisions,
    }),
    loadComponentSuggestionsAsync: getLoadComponentSuggestionsAsync({
      workspaceIds,
      subdivisions,
    }),
    loadWorkspaceSuggestionsAsync: getLoadWorkspaceSuggestionsAsync({
      workspaceIds,
      subdivisions,
    }),
    loadUserSuggestionsAsync: getLoadUserSuggestionsAsync({
      workspaceIds,
      subdivisions,
    }),
    loadTagSuggestionsAsync: getLoadTagSuggestionsAsync({
      workspaceIds,
      subdivisions,
    }),
    loadComponentTypeNameSuggestionsAsync:
      getLoadComponentTypeNameSuggestionsAsync({ workspaceIds, subdivisions }),
    loadReferenceTypeNameSuggestionsAsync:
      getLoadReferenceTypeNameSuggestionsAsync({ workspaceIds, subdivisions }),
  },
  asyncLabelLoaders: {
    getComponentKeyLabelAsync: async key => key,
    getComponentLabelAsync: getComponentName,
    getWorkspaceLabelAsync: getWorkspaceName,

    getUserLabelAsync: getUserName,
    getReferenceLabelAsync: getReferenceName,
  },
});

export const QueryBuilderWithSuggestionsLoaders = (
  props: QueryBuilderWithSuggestionsLoadersProps
) => (
  <QueryBuilder
    {...props}
    {...getQueryBuilderLoaders(props.contextWorkspaceIds)}
  />
);
