import styled from 'styled-components';
import { ErrorNotification } from '@ardoq/status-ui';

export const SurveyEditorHeaderErrorNotification = styled(ErrorNotification)`
  min-width: 700px;
  max-width: 1440px;
`;

export const SurveyEditorHeaderHelper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  label {
    margin: 0;
    font-size: 14px; /* To stay consistent with the adjacent button labels */
  }

  @media (min-width: 1490px) {
    flex: 1 1 34%;
  }
`;

export const SurveyEditorHeaderInfo = styled.div`
  display: flex;

  @media (max-width: 1190px) {
    display: none;
  }
`;

export const SurveyEditorHeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (min-width: 1490px) {
    flex: 1;
  }
`;
