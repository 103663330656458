import { last, uniqBy } from 'lodash';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  ArdoqId,
  BroadcastAudienceType,
  GremlinAudience,
  ComponentPreviewResponse,
  SearchBackend,
  SearchType,
} from '@ardoq/api-types';
import {
  GremlinComponentResult,
  GremlinPathResult,
  GremlinResult,
  GremlinResultType,
} from 'search/Gremlin/GremlinResults/types';
import {
  getResultType,
  isComponentResult,
} from 'search/Gremlin/GremlinResults/utils';
import { truncate } from 'utils/stringUtils';
import { DEFAULT_GREMLIN_AUDIENCE_DIALOG_QUERY } from 'broadcasts/consts';
import { getMissingContactEmailWarningMessage } from 'broadcasts/utils';
import { FetchStatus } from './types';

export const getDefaultState = () => {
  const contentIds: ArdoqId[] = [];
  const totalNumberOfContentIds = 0;
  return {
    query: DEFAULT_GREMLIN_AUDIENCE_DIALOG_QUERY,
    formIsValid: true,
    isSearching: false,
    isUpdatingExistingAudience: false,
    contentIdsFetchStatus: FetchStatus.IDLE,
    contentIds,
    totalNumberOfContentIds,
    immutableFirstLineOfQuery: getImmutableFirstLineOfQuery(
      contentIds,
      totalNumberOfContentIds
    ),
    audiencePreview: [],
    audiencePreviewWarningMessage: null,
    isFetchingAudiencePreview: false,
    failedToFetchAudiencePreview: false,
    hasGremlinSearchError: false,
    gremlinSyntaxError: null,
    gremlinWarningMessage: null,
    tableFilter: '',
  };
};

export const toContentIdsAndTotalNumberOfContentIds = ({
  components,
  total,
}: ComponentPreviewResponse) => ({
  contentIds: components.map(({ _id }) => _id),
  totalNumberOfContentIds: total,
});

const isPathResult = (result: GremlinResult): result is GremlinPathResult => {
  return getResultType(result) === GremlinResultType.PATH;
};

export const pathResultEndsWithComponent = (result: GremlinPathResult) => {
  const lastResult = last(result.objects);
  if (!lastResult) return false;
  return isComponentResult(lastResult);
};

const getResultFormatWarningMessage = () => {
  return 'The results are not in the expected format. To link components to people the query must return a list of paths between them. This may be achieved by ending your query with .path()';
};

const getPathEndingResultTypeWarningMessage = () => {
  return 'The results are not in the expected format. To link components to people each path returned by the query must end at a Person component.';
};

const isMissingContactEmail = (componentResult: GremlinComponentResult) => {
  return !componentResult.properties.contact_email;
};

const getNumberOfMissingContactEmails = (pathResults: GremlinPathResult[]) => {
  const audiences = pathResults
    .map(({ objects }) => last(objects))
    .filter(ExcludeFalsy);

  const uniqueAudiences = uniqBy(audiences, ({ id }) => id);
  const audiencesMissingContactEmails = uniqueAudiences
    .filter(isComponentResult)
    .filter(isMissingContactEmail);
  const numberOfMissingContactEmails = audiencesMissingContactEmails.length;
  return numberOfMissingContactEmails;
};

export const getWarningMessageFromGremlinResults = (
  results: GremlinResult[]
) => {
  if (!results.length) return null;
  if (!isPathResult(results[0])) return getResultFormatWarningMessage();
  if (!pathResultEndsWithComponent(results[0])) {
    return getPathEndingResultTypeWarningMessage();
  }
  const numberOfMissingContactEmails = getNumberOfMissingContactEmails(
    results as GremlinPathResult[]
  );
  if (numberOfMissingContactEmails) {
    return getMissingContactEmailWarningMessage(numberOfMissingContactEmails);
  }
  return null;
};

export const getBroadcastAudienceQueryModel = (query: string) => ({
  backend: SearchBackend.GREMLIN,
  name: '',
  parameterQueryId: null,
  query,
  supportedParams: [],
  type: SearchType.BROADCAST_AUDIENCE_QUERY,
});

export const getImmutableFirstLineOfQuery = (
  contentIds: ArdoqId[],
  totalNumberOfContentIds: number
) => {
  const contentIdsString = contentIds.map(id => `"${id}"`).join(', ');
  const truncatedContentIdsString = truncate(contentIdsString, 60);
  return `ids = [${truncatedContentIdsString}] // Using ${totalNumberOfContentIds} IDs from broadcast scope`;
};

export const createGremlinAudience = (query: string): GremlinAudience => ({
  audienceType: BroadcastAudienceType.GREMLIN,
  query,
});

export const isFormValid = (query: string) => Boolean(query.length);
