import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
  withNamespace,
} from '@ardoq/rxbeach';
import { isEqual } from 'lodash';
import {
  combineLatestWith,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { triggerSearchLoadingDone, updateSearchQuery } from 'search/actions';
import {
  QueryEditorNamespace,
  searchAndAssignToZoneQueryEditor$,
} from 'search/QueryEditor/queryEditor$';
import { trackQueriedAdvancedSearch } from 'search/tracking';
import { getCombinedRules } from 'search/AdvancedSearch/utils';
import advancedSearchForZones$ from './advancedSearchForZones$';
import {
  advancedSearchError,
  queryAdvancedSearch,
  queryAdvancedSearchSuccess,
  selectCondition,
  selectFilterType,
  selectPage,
  setSort,
  updateAdvancedSearch,
} from './actions';
import {
  executeSearch,
  handleSearchResponse,
  handleSelectPage,
  handleSetSort,
  searchDone,
  searchStart,
  toAdvancedSearchQuery,
} from 'search/AdvancedSearch/routines';

const handleQueryAdvancedSearchSuccess = routine(
  withNamespace(QueryEditorNamespace.SEARCH_AND_ASSIGN_TO_ZONE),
  ofType(queryAdvancedSearchSuccess),
  withLatestFrom(searchAndAssignToZoneQueryEditor$),
  tap(([, { selectedQueryId }]) =>
    trackQueriedAdvancedSearch({
      isStoredQuery: Boolean(selectedQueryId),
    })
  )
);

const handleQueryAdvancedSearch = routine(
  withNamespace(QueryEditorNamespace.SEARCH_AND_ASSIGN_TO_ZONE),
  ofType(queryAdvancedSearch),
  extractPayload(),
  tap(() => searchStart(QueryEditorNamespace.SEARCH_AND_ASSIGN_TO_ZONE)),
  withLatestFrom(advancedSearchForZones$),
  map(([payload, advancedSearch]) =>
    toAdvancedSearchQuery(payload, advancedSearch)
  ),
  switchMap(executeSearch),
  tap(response => handleSearchResponse(response, false)),
  tap(() => searchDone(QueryEditorNamespace.SEARCH_AND_ASSIGN_TO_ZONE))
);

const handleChangedSearch = routine(
  withNamespace(QueryEditorNamespace.SEARCH_AND_ASSIGN_TO_ZONE),
  ofType(updateAdvancedSearch, selectCondition, selectFilterType),
  // FIXME
  // The following is a work around for doing a routine on a state stream.
  // combineLatestWith will make the rest of the routine react to any changes to
  // the advancedSearchForZones$ in addition to the actions. distinctUntilChanged with
  // a deep comparator (isEqual from lodash) after picking only the interesting
  // parts of the state, should ensure we only trigger updates when we want to.
  combineLatestWith(advancedSearchForZones$),
  map(([, { queryBuilderRules, selectedFilterType }]) => ({
    queryBuilderRules,
    selectedFilterType,
  })),
  distinctUntilChanged(isEqual),
  map(getCombinedRules),
  tap(query =>
    dispatchAction(
      updateSearchQuery({
        query,
      }),
      QueryEditorNamespace.SEARCH_AND_ASSIGN_TO_ZONE
    )
  )
);

const invokeSearchLoadDone = routine(
  ofType(advancedSearchError),
  tap(() =>
    dispatchAction(
      triggerSearchLoadingDone(),
      QueryEditorNamespace.SEARCH_AND_ASSIGN_TO_ZONE
    )
  )
);

export const advancedSearchForZonesRoutines = collectRoutines(
  handleQueryAdvancedSearchSuccess,
  handleQueryAdvancedSearch,
  handleChangedSearch,
  invokeSearchLoadDone,
  handleSetSort(advancedSearchForZones$, setSort),
  handleSelectPage(advancedSearchForZones$, selectPage)
);
