import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { SubdivisionViewModelState } from 'subdivisionEditor/types';

const NAMESPACE = '[BIND_WORKSPACES]';

export const setSelectedWorkspaces = actionCreator<{
  workspacesBoundToSubdivisionIds: ArdoqId[];
  workspaceIds: ArdoqId[];
}>(`${NAMESPACE} SET_SELECTED_WORKSPACES`);

/**
 * Selection single workspace. used in the routine.
 *
 * @deprecated legacy action, will be removed after the new flow is released.
 *
 * Use `setSelectedWorkspaces` instead.
 */
export const workspaceSelection = actionCreator<ArdoqId>(
  `${NAMESPACE} workspaceSelection`
);

/**
 * Selection single workspace. used in the reducer.
 * @deprecated legacy action, will be removed after the new flow is released.
 *
 * Use `setSelectedWorkspaces` instead.
 */
export const workspaceSelected = actionCreator<ArdoqId>(
  `${NAMESPACE} workspaceSelected`
);

/**
 * Deselection single workspace. used in the routine.
 *
 * @deprecated legacy action, will be removed after the new flow is released.
 *
 * Use `setSelectedWorkspaces` instead.
 */
export const workspaceDeselection = actionCreator<ArdoqId>(
  `${NAMESPACE} workspaceDeselection`
);

/**
 * Deselection single workspace. used in the reducer.
 *
 * @deprecated legacy action, will be removed after the new flow is released.
 *
 * Use `setSelectedWorkspaces` instead.
 */
export const workspaceDeselected = actionCreator<ArdoqId>(
  `${NAMESPACE} workspaceDeselected`
);

export const searchValueChanged = actionCreator<string>(
  `${NAMESPACE} searchValueChanged`
);
export const saveWorkspaceBindings = actionCreator<SubdivisionViewModelState>(
  `${NAMESPACE} saveWorkspaceBindings`
);
export const workspaceBindingsSaved = actionCreator(
  `${NAMESPACE} workspaceBindingsSaved`
);
export const workspaceBindingsSavingFailure = actionCreator(
  `${NAMESPACE} workspaceBindingsSavingFailure`
);
