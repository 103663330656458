import {
  FilterCondition,
  FilterConditionForValue,
} from '@ardoq/api-types/integrations';

// ordered conditions
export const ALL_FILTER_CONDITIONS: FilterCondition[] = [
  { operator: 'empty' },
  { operator: 'not-empty' },
  { operator: 'equals', value: '' },
  { operator: 'not-equals', value: '' },
  { operator: 'contains', value: '' },
  { operator: 'not-contains', value: '' },
  { operator: 'starts-with', value: '' },
  { operator: 'not-starts-with', value: '' },
];

export const FILTER_LABEL: Record<FilterCondition['operator'], string> = {
  empty: 'Is empty',
  'not-empty': 'Is not empty',
  equals: 'Is equal to',
  'not-equals': 'Is not equal to',
  contains: 'Contains',
  'not-contains': 'Does not contain',
  'starts-with': 'Starts with',
  'not-starts-with': 'Does not start with',
};

export type EmptyFilterCondition = Omit<FilterCondition, 'operator'> & {
  operator: null;
};

export const isConditionWithValue = (
  condition: FilterCondition | EmptyFilterCondition
): condition is FilterConditionForValue => {
  switch (condition.operator) {
    case 'empty':
    case 'not-empty':
    case null:
      return false;
    case 'contains':
    case 'not-contains':
    case 'equals':
    case 'not-equals':
    case 'starts-with':
    case 'not-starts-with':
      return true;
  }
};

export const isFilterCondition = (
  condition: FilterCondition | EmptyFilterCondition
): condition is FilterConditionForValue => Boolean(condition.operator);
