import { dispatchAction } from '@ardoq/rxbeach';
import * as metaInfoActions from './metaInfoActions';

const setName = (name: string) =>
  dispatchAction(metaInfoActions.setTraversalName({ name }));

const setDescription = (description: string) => {
  dispatchAction(metaInfoActions.setTraversalDescription({ description }));
};

const setInitialMetaInfo = (
  payload: metaInfoActions.SetInitialMetaInfoPayload
) => dispatchAction(metaInfoActions.setInitialMetaInfo(payload));

export const metaInfoCommands = {
  setName,
  setDescription,
  setInitialMetaInfo,
};

export type MetaInfoCommands = typeof metaInfoCommands;
