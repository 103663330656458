import { actionCreator } from '@ardoq/rxbeach';
import { WorkspaceOverviewManagementColumnName } from './types';
import {
  APIWorkspaceOverview,
  ArdoqId,
  SetGremlinEnabledPayload,
} from '@ardoq/api-types';

export const fetchWorkspaceOverview = actionCreator(
  '[workspaces] FETCH_WORKSPACE_OVERVIEW'
);

export const fetchWorkspaceOverviewSuccess = actionCreator<
  APIWorkspaceOverview[]
>('[workspaces] FETCH_WORKSPACE_OVERVIEW_SUCCESS');

export const requestDeletion = actionCreator<ArdoqId[]>(
  '[workspaces] REQUEST_DELETION'
);

export const cancelDeletion = actionCreator('[workspaces] CANCEL_DELETION');

export const deleteSelectedWorkspaces = actionCreator<ArdoqId[]>(
  '[workspaces] DELETE_SELECTED_WORKSPACES'
);

export const websocketFakerDeletedWorkspaces = actionCreator<ArdoqId[]>(
  '[workspaces] WEBSOCKET_FAKER_DELETED_WORKSPACES'
);

export const setGremlinForIds = actionCreator<SetGremlinEnabledPayload>(
  '[workspaces] SET_GREMLIN_FOR_IDS'
);

export const setGremlinForIdsSuccess = actionCreator<SetGremlinEnabledPayload>(
  '[workspaces] SET_GREMLIN_FOR_IDS_SUCCESS'
);

export const toggleCheckbox = actionCreator<ArdoqId>(
  '[workspaces] TOGGLE_CHECKBOX'
);

export const toggleTitleCheckbox = actionCreator(
  '[workspaces] TOGGLE_TITLE_CHECKBOX'
);

export const clearSelectedCheckboxes = actionCreator(
  '[workspaces] CLEAR_SELECTED_CHECKBOXES'
);

export const selectAllWorkspaces = actionCreator(
  '[workspaces] SELECT_ALL_WORKSPACES'
);

export const setPage = actionCreator<{ pageNumber: number; pageSize: number }>(
  '[workspaces] SET_PAGE'
);

export const searchQueryChange = actionCreator<string>(
  '[workspaces] SEARCH_QUERY_CHANGE'
);

export const sortOrderChange =
  actionCreator<WorkspaceOverviewManagementColumnName>(
    '[workspaces] SORT_ORDER_CHANGE'
  );

export const openActionsMenu = actionCreator<ArdoqId>(
  '[workspaces] OPEN_ACTIONS_MENU'
);

export const closeActionsMenu = actionCreator<ArdoqId>(
  '[workspaces] CLOSE_ACTIONS_MENU'
);

export const shiftClickRow = actionCreator<ArdoqId>('[workspaces] SHIFT_CLICK');
