import Filters from 'collections/filters';
import {
  QuickPerspectiveType,
  trackToggledQuickPerspective,
} from 'quickPerspectives/tracking';
import { QuickPerspectivesContext } from './types';
import { Label } from './atoms';
import QuickPerspectivesLegendItem from './QuickPerspectivesLegendItem';
import { connect } from '@ardoq/rxbeach';
import quickPerspectivesContext$ from 'quickPerspectives/quickPerspectivesContext$';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ArdoqId } from '@ardoq/api-types';

const handleWorkspaceSelectedChange = (
  workspaceId: ArdoqId,
  isSelected: boolean,
  includedWorkspaceIds: ArdoqId[],
  contextWorkspaceId: ArdoqId | null
) => {
  let newIncludedWorkspaceIds = includedWorkspaceIds;

  if (isSelected) {
    newIncludedWorkspaceIds = newIncludedWorkspaceIds.filter(
      item => item !== workspaceId
    );
  } else {
    newIncludedWorkspaceIds.push(workspaceId);
    if (
      contextWorkspaceId &&
      !newIncludedWorkspaceIds.includes(contextWorkspaceId)
    ) {
      newIncludedWorkspaceIds.push(contextWorkspaceId);
    }
  }
  trackToggledQuickPerspective({
    type: QuickPerspectiveType.WORKSPACE_FILTER,
    isEnabling: !isSelected,
  });
  Filters.updateWorkspaceFilter(newIncludedWorkspaceIds);
};

const handleAllSelected = () => {
  Filters.clearWorkspaceFilter({ shouldTriggerChangeEvent: true });
};

type WorkspaceLegendProperties = {
  quickPerspectivesContext: QuickPerspectivesContext;
};
const WorkspaceLegend = (props: WorkspaceLegendProperties) => {
  const contextWorkspaceId = props.quickPerspectivesContext.contextWorkspaceId;
  const allWorkspaces = props.quickPerspectivesContext.workspaces || [];
  const includedWorkspaceIds =
    props.quickPerspectivesContext.includedWorkspaceIds;
  const outsideWorkspaces = props.quickPerspectivesContext.outsideWorkspaces;

  return (
    <div>
      <QuickPerspectivesLegendItem
        isImplicitlySelected={includedWorkspaceIds.length === 0}
        isChecked={includedWorkspaceIds.length === 0}
        isDisabled={includedWorkspaceIds.length === 0}
        onChange={handleAllSelected}
      >
        <span>All</span>
      </QuickPerspectivesLegendItem>

      {allWorkspaces.filter(Boolean).map(workspace => {
        const isSelected = includedWorkspaceIds.includes(workspace._id);
        const isInContext = contextWorkspaceId === workspace._id;
        const isImplicitlySelected = Boolean(isSelected && isInContext);
        const workspaceName = workspace.name;
        return (
          <QuickPerspectivesLegendItem
            key={workspace._id}
            isImplicitlySelected={isImplicitlySelected}
            isChecked={isSelected}
            isDisabled={isImplicitlySelected}
            onChange={() =>
              handleWorkspaceSelectedChange(
                workspace._id,
                isSelected,
                includedWorkspaceIds,
                contextWorkspaceId
              )
            }
          >
            <Label
              $isInContext={isInContext}
              data-tooltip-text={
                workspaceName.length >= 16 ? workspaceName : ''
              }
            >
              {workspaceName}
            </Label>
          </QuickPerspectivesLegendItem>
        );
      })}

      <div>
        {outsideWorkspaces.map(workspace => (
          <QuickPerspectivesLegendItem
            key={workspace._id}
            isOutsideScope={true}
            isChecked={true}
            onChange={() =>
              handleWorkspaceSelectedChange(
                workspace._id,
                true,
                includedWorkspaceIds,
                contextWorkspaceId
              )
            }
          >
            {workspace.name}
          </QuickPerspectivesLegendItem>
        ))}
      </div>
    </div>
  );
};

export default connect(
  WorkspaceLegend,
  combineLatest([quickPerspectivesContext$]).pipe(
    map(([quickPerspectivesContext]) => ({
      quickPerspectivesContext,
    }))
  )
);
