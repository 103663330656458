import { reducedStream, Reducer, reducer } from '@ardoq/rxbeach';
import {
  generateValueProposition,
  generateValueStream,
  setCompany,
  setDescription,
  setIndustry,
  setStreamLink,
  setValueProposition,
  submitValuePropositionModal,
  failedValueProposition,
  submitValueStreamModal,
} from './actions';
import { defer, map } from 'rxjs';
import { handleError, augmentationsApi } from '@ardoq/api';

export type AugmentationModalState = {
  description: string;
  company: string;
  industry: string;
  valueProposition: string;
  showVP: boolean;
  loading: boolean;
  disableGenerateValueProposition: boolean;
  disableSubmitValueProposition: boolean;
  disableSubmitValueStream: boolean;
  disableGenerateValueStream: boolean;
  streamLink: string;
  streamError: boolean;
};

const initialState: AugmentationModalState = {
  description: '',
  company: '',
  industry: '',
  valueProposition: '',
  showVP: false,
  loading: false,
  disableGenerateValueProposition: true,
  disableGenerateValueStream: true,
  disableSubmitValueProposition: true,
  disableSubmitValueStream: true,
  streamLink: '',
  streamError: false,
};

const handleSetCompany: Reducer<AugmentationModalState, string> = (
  state,
  company
) =>
  setDisabledButtons({
    ...state,
    company,
  });

const handleSetDescription: Reducer<AugmentationModalState, string> = (
  state,
  description
) =>
  setDisabledButtons({
    ...state,
    description,
  });

const handleSetIndustry: Reducer<AugmentationModalState, string> = (
  state,
  industry
) =>
  setDisabledButtons({
    ...state,
    industry,
  });

const handleSetValueProposition: Reducer<AugmentationModalState, string> = (
  state,
  valueProposition
) =>
  setDisabledButtons({
    ...state,
    valueProposition,
    loading: false,
    showVP: true,
  });

const setDisabledButtons = (
  state: AugmentationModalState
): AugmentationModalState => {
  return {
    ...state,
    disableGenerateValueProposition: !(state.company && state.description),
    disableSubmitValueProposition: !state.valueProposition,
    disableGenerateValueStream: !(
      state.company &&
      state.description &&
      state.valueProposition &&
      state.industry
    ),
  };
};

const handleGenerateValueProposition = (state: AugmentationModalState) => ({
  ...state,
  loading: true,
});

const handleGenerateValueStream = (state: AugmentationModalState) => ({
  ...state,
  loading: true,
  streamLink: '',
  streamError: false,
});

const handleSetStreamLink: Reducer<AugmentationModalState, string> = (
  state,
  streamLink
) => ({
  ...state,
  loading: false,
  streamLink,
  streamError: streamLink === 'Error',
  disableSubmitValueStream: streamLink === 'Error',
});

const handleSubmitValueProposition = (state: AugmentationModalState) => ({
  ...state,
  disableSubmitValueProposition: true,
});

const handleFailedValueProposition = (state: AugmentationModalState) => ({
  ...state,
  disableSubmitValueProposition: false,
});

const handleSubmitValueStream = (state: AugmentationModalState) => ({
  ...state,
  disableSubmitValueStream: true,
});

const initialAugmentations$ = defer(() =>
  augmentationsApi.prefillAugmentations()
).pipe(
  handleError(),
  // TODO if this has an error the state wont load At All, need to fix
  map(prefill => ({
    company: prefill.organization.name || '',
    description: prefill.organization.description || '',
    valueProposition: prefill['value-proposition'].description || '',
  }))
);

const initialState$ = initialAugmentations$.pipe(
  map(({ company, description, valueProposition }) => ({
    ...initialState,
    company,
    valueProposition,
    description,
    disableGenerateValueStream: !company || !description || !valueProposition,
    disableGenerateValueProposition: !company || !description,
  }))
);

export const augmentationModal$ = reducedStream(
  'augmentationModal',
  initialState$,
  [
    reducer(setCompany, handleSetCompany),
    reducer(setDescription, handleSetDescription),
    reducer(setIndustry, handleSetIndustry),
    reducer(setStreamLink, handleSetStreamLink),
    reducer(setValueProposition, handleSetValueProposition),
    reducer(failedValueProposition, handleFailedValueProposition),
    reducer(generateValueProposition, handleGenerateValueProposition),
    reducer(generateValueStream, handleGenerateValueStream),
    reducer(submitValuePropositionModal, handleSubmitValueProposition),
    reducer(submitValueStreamModal, handleSubmitValueStream),
  ]
);
