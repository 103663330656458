import DeletedComponent from './DeletedComponent';
import ChevronDecorator from './ChevronDecorator';
import { DeletedComponentProperties } from './types';
import { FlexBox } from '@ardoq/layout';

const DeletedComponentWithIndentation = (props: DeletedComponentProperties) => {
  return (
    <FlexBox>
      <ChevronDecorator hidden>
        <DeletedComponent {...props} />
      </ChevronDecorator>
    </FlexBox>
  );
};

export default DeletedComponentWithIndentation;
