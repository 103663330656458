import { trackEvent } from 'tracking/tracking';
import { ViewIds } from '@ardoq/api-types';
import { ExportViewEvent } from '@ardoq/export';
import { isPresentationMode } from 'appConfig';
import visualizedNodesQuantity$, {
  setVisualizedNodesQuantity,
} from 'tracking/visualizedNodesQuantity$';
import { dispatchAction } from '@ardoq/rxbeach';
import { loadedGraph$ } from 'traversals/loadedGraph$';

export const trackExportingVisualization = (metadata: ExportViewEvent) =>
  trackEvent('Exported visualization', metadata, true);

const VIEW_NODE_COUNT_CHANGED_EVENT_NAME = 'View node count changed';

export const trackNodeCount = (viewId: ViewIds, nodeCount: number) =>
  trackEvent(VIEW_NODE_COUNT_CHANGED_EVENT_NAME, {
    viewId,
    nodeCount,
    isViewpointMode: loadedGraph$.state.isViewpointMode,
  });

export const trackNodeCountWithPresentationMeta = (
  viewId: ViewIds,
  nodeCount: number
) => {
  const previousNodeCount = visualizedNodesQuantity$.state[viewId];
  if (typeof nodeCount === 'number' && previousNodeCount !== nodeCount) {
    dispatchAction(setVisualizedNodesQuantity({ [viewId]: nodeCount }));
    trackEvent(VIEW_NODE_COUNT_CHANGED_EVENT_NAME, {
      viewId,
      nodeCount,
      isPresentation: Boolean(isPresentationMode()),
      isViewpointMode: loadedGraph$.state.isViewpointMode,
    });
  }
};

export const trackEdgeCount = (viewId: ViewIds, edgeCount: number) =>
  trackEvent('View edge count changed', { viewId, edgeCount });
