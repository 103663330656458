import { connect } from '@ardoq/rxbeach';
import { viewModel$ } from './streams/viewModel$';
import { PermissionGroupsProps } from './types';
import { accessControlNavigationOperations } from 'admin/accessControl/navigation/accessControlPageNavigationOperations';
import { GroupDetailsPage } from './GroupsDetailsPage';
import { GroupsOverView } from './GroupsOverView';

const PermissionGroups = (props: PermissionGroupsProps) => {
  if (accessControlNavigationOperations.isGroupDetailsPage(props)) {
    if (!props.groupsById[props.groupId]) {
      props.commands.goToGroup('');
    }
    return (
      <GroupDetailsPage
        {...props}
        group={props.groupsById[props.groupId]}
      ></GroupDetailsPage>
    );
  }

  return <GroupsOverView {...props}></GroupsOverView>;
};

export default connect(PermissionGroups, viewModel$);
