import { firstValueFrom } from 'rxjs';
import { logError } from '@ardoq/logging';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { ArdoqId, AssetFolder, AssetType } from '@ardoq/api-types';
import { DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import { groupBy } from 'lodash';
import assets$, { AssetsStreamState } from 'streams/assets/assets$';
import { confirmDeleteAsset } from 'components/DeleteAssetModal/DeleteAssetModal';
import { DeleteType, Item } from 'components/DeleteAssetModal/types';
import { Folder as FolderRow } from 'components/EntityBrowser/types';
import { AssetRow } from 'components/AssetsBrowser/types';
import type { MenuFnOptions } from './types';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  deleteMenuOption,
  dividerMenuOption,
  moveToMenuOption,
  renameMenuOption,
} from './sharedMenuOptions';
import { getCreateOptions } from '../../../homePage/CreateNewButtonWithDropdown';
import { deleteAssets } from 'streams/assets/actions';
import { handleDeleteAsset } from './handleDeleteAsset';

const MESSAGES = {
  ERRORS: {
    MODEL_NOT_FOUND: 'Asset folder model not found.',
    FOLDER_NOT_FOUND: 'Asset folder not found.',
    CORRUPTED_DATA:
      'API data corrupted: Folder contains ids of assets which could not be found.',
  },
};

const getDescendantsFolderIds = (
  foldersById: Record<ArdoqId, AssetFolder>,
  folderId: ArdoqId
): ArdoqId[] => {
  const assetFolderModel = foldersById[folderId];
  if (!assetFolderModel) {
    logError(Error(MESSAGES.ERRORS.MODEL_NOT_FOUND), null, { folderId });
    return [];
  }
  const subfoldersIds = assetFolderModel.content
    .filter(({ type }) => type === AssetType.FOLDER)
    .map(({ _id }) => _id);
  return [
    ...subfoldersIds,
    ...subfoldersIds.map(id => getDescendantsFolderIds(foldersById, id)),
  ].flat();
};

const assetsToBeDeleted = (
  foldersById: Record<ArdoqId, AssetFolder>,
  assets: AssetsStreamState,
  folderId: string
) => {
  const {
    workspaceAssetsById,
    assetFoldersById,
    presentationsById,
    surveysById,
    metamodelsById,
    scenariosById,
    traversalsById,
    bookmarksById,
    reportsById,
    dashboardsById,
    viewpointsById,
    broadcastsById,
  } = assets;

  const assetsByType = {
    [AssetType.FOLDER]: assetFoldersById,
    [AssetType.WORKSPACE]: workspaceAssetsById,
    [AssetType.SURVEY]: surveysById,
    [AssetType.METAMODEL]: metamodelsById,
    [AssetType.SCENARIO]: scenariosById,
    [AssetType.PRESENTATION]: presentationsById,
    [AssetType.TRAVERSAL]: traversalsById,
    [AssetType.BOOKMARK]: bookmarksById,
    [AssetType.REPORT]: reportsById,
    [AssetType.DASHBOARD]: dashboardsById,
    [AssetType.VIEWPOINT]: viewpointsById,
    [AssetType.BROADCAST]: broadcastsById,
  };

  const subfolderIds = getDescendantsFolderIds(foldersById, folderId);
  const folderIds = [folderId, ...subfolderIds];
  const folderDeepContent = folderIds.flatMap(id => {
    const matchingAssetFolder = assetFoldersById[id];
    if (!matchingAssetFolder) {
      logError(Error(MESSAGES.ERRORS.FOLDER_NOT_FOUND), null, { id });
      return [];
    }
    return matchingAssetFolder.content;
  });

  const folderDeepItems = folderDeepContent.reduce<Item[]>(
    (acc, { _id, type }) => {
      const foldersAsset = assetsByType[type as AssetType][_id];
      /**
        Sometimes due to data coruption folders (provided by API endpoint
        `/api/workspacefolder`) contains assets ({content: {_id: assetId, type: assetType}[]})
        which doesn't exists anymore.

        The check below filters such cases logs them and skips such unexisting assets.
      */
      if (!foldersAsset) {
        logError(Error(MESSAGES.ERRORS.CORRUPTED_DATA), null, {
          folderId: folderId,
          assetId: _id,
          assetType: type,
        });
        return acc;
      }

      return [
        ...acc,
        {
          id: _id,
          name: foldersAsset.name,
          type,
        },
      ];
    },
    []
  );

  const groupedItems = groupBy(folderDeepItems, ({ type }) => type);

  return {
    folders: groupedItems[AssetType.FOLDER] || [],
    workspaces: groupedItems[AssetType.WORKSPACE] || [],
    presentations: groupedItems[AssetType.PRESENTATION] || [],
    surveys: groupedItems[AssetType.SURVEY] || [],
    metamodels: groupedItems[AssetType.METAMODEL] || [],
    scenarios: groupedItems[AssetType.SCENARIO] || [],
    reports: groupedItems[AssetType.REPORT] || [],
    dashboards: groupedItems[AssetType.DASHBOARD] || [],
    viewpoints: groupedItems[AssetType.VIEWPOINT] || [],
    traversals: groupedItems[AssetType.TRAVERSAL] || [],
    bookmarks: groupedItems[AssetType.BOOKMARK] || [],
    broadcasts: groupedItems[AssetType.BROADCAST] || [],
  };
};

const handleDelete = async (folderId: string, clearSelected: () => void) => {
  const assets = await firstValueFrom(assets$);
  const foldersById = assets.assetFoldersById;
  const assetFolder = foldersById[folderId];
  return handleDeleteAsset({
    assetType: AssetType.FOLDER,
    onDelete: () =>
      confirmDeleteAsset({
        name: assetFolder.name,
        deleteType: DeleteType.FOLDER,
        assets: assetsToBeDeleted(foldersById, assets, folderId),
      }),
    onDeleteConfirmed: () =>
      dispatchAction(
        deleteAssets({ assets: [{ _id: folderId, type: AssetType.FOLDER }] })
      ),
    clearSelected,
  });
};

const SUPPORTED_ASSETS_FOR_INSIDE_FOLDER_CREATION: AssetType[] = [
  AssetType.FOLDER,
  AssetType.SURVEY,
  AssetType.BROADCAST,
  AssetType.TRAVERSAL,
];

export const getFolderMenuOptions = (
  folderRow: FolderRow<AssetRow>,
  {
    onRenameSelect,
    clearSelected,
    rowPermissions,
    handleMoveToModal,
    createAssetOptions,
  }: MenuFnOptions
): DropdownOption[] => {
  const createOptions = createAssetOptions
    ? getCreateOptions({
        ...createAssetOptions,
        targetFolderId: folderRow._id,
        trackingLocation: 'context-menu',
      }).filter(options =>
        SUPPORTED_ASSETS_FOR_INSIDE_FOLDER_CREATION.includes(options.assetType)
      )
    : [];
  return [
    rowPermissions.canUpdate &&
      createOptions.length > 0 && {
        label: 'Create',
        name: 'create',
        type: DropdownOptionType.SUBMENU,
        options: createOptions,
      },
    renameMenuOption({
      asset: folderRow,
      hasPermission: rowPermissions.canUpdate,
      onRenameSelect: () => onRenameSelect(folderRow._id),
    }),
    moveToMenuOption({
      asset: folderRow,
      hasPermission: rowPermissions.canUpdate,
      handleMoveToModal: () => handleMoveToModal([folderRow]),
    }),
    dividerMenuOption(),
    deleteMenuOption({
      asset: folderRow,
      hasPermission: rowPermissions.canDelete,
      onClick: () => handleDelete(folderRow._id, clearSelected),
    }),
  ].filter(ExcludeFalsy);
};
