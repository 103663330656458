import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { combineLatest, map, switchMap, of } from 'rxjs';
import { byIndex } from 'integrations/common/utils/common';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { tableStatuses$ } from 'integrations/common/streams/tabularMappings/tableStatus$';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { confirm } from '@ardoq/modal';
import { dispatchAction } from '@ardoq/rxbeach';
import { clearSingleTabularMapping } from 'integrations/common/streams/tabularMappings/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      tablePreviews: getTablePreviewsStream(integrationId),
      currentTableId: getActiveIntegrationStream(integrationId).pipe(
        map(ci => ci.currentTableId)
      ),
      integrationTermsDictionary:
        getIntegrationTermsDictionaryStream(integrationId),
      tableStatuses: tableStatuses$,
    });
  }),
  map(
    ({
      tablePreviews,
      currentTableId,
      tableStatuses,
      integrationId,
      integrationTermsDictionary,
    }) => {
      const confirmClearSelection = async (id: string, name: string) => {
        const result = await confirm({
          title: 'Reset table configuration?',
          text: `You’re about to reset the configuration for the table "${name}".`,
          text2: 'Once done, this action can’t be undone.',
          confirmButtonTitle: 'Reset configuration',
          cancelButtonTitle: 'Keep configuration',
        });
        if (result) {
          dispatchAction(
            clearSingleTabularMapping({
              integrationId,
              id,
            })
          );

          dispatchAction(
            trackIntegrationEvent({
              integrationId,
              name: 'CLEARED_SHEET_CONFIG',
              metadata: {
                sheedId: id,
              },
            })
          );
        }
      };
      return {
        activeTabId: currentTableId ?? undefined,
        integrationId,
        integrationTermsDictionary,
        tabs: Object.values(tablePreviews)
          .sort(byIndex)
          .map(table => {
            const status = tableStatuses[table.id] ?? 'Default';
            const onConfirmClear = async () => {
              confirmClearSelection(table.id, table.name);
            };
            return {
              id: table.id,
              label: table.name,
              status: status,
              onTabClear: status !== 'Default' ? onConfirmClear : undefined,
            };
          }),
      };
    }
  )
);
