import { switchMap, map, combineLatest } from 'rxjs';
import { ModalLayout, ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import {
  IntegrationWorkspace,
  SavedTransferConfig,
} from '@ardoq/api-types/integrations';

import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  bulkDeleteConfigurations,
  renameConfiguration,
} from 'integrations/common/streams/transferConfigs/actions';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { TransferConfigsTable } from 'integrations/common/components/transferConfigsTable/TransferConfigsTable';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { setVisibleAsset } from 'assets/navigation/actions';

type TransferConfigsComponentProps = {
  onRenameConfiguration: (name: string, config: SavedTransferConfig) => void;
  onClose: VoidFunction;
  onDeleteConfigs: (configs: SavedTransferConfig[]) => void;
  onWorkspaceClick: ((workspace: IntegrationWorkspace) => void) | null;
  transferConfigs: SavedTransferConfig[];
  isLoading: boolean;
  selectedConfigIds: string[];
  noConfigsMessage: string;
};

const TransferConfigsComponent = ({
  onRenameConfiguration,
  onClose,
  onDeleteConfigs,
  onWorkspaceClick,
  transferConfigs,
  isLoading,
  selectedConfigIds,
  noConfigsMessage,
}: TransferConfigsComponentProps) => {
  return (
    <ModalTemplate
      onCloseButtonClick={onClose}
      headerText="Manage configurations"
      modalSize={ModalSize.M}
    >
      <ModalLayout>
        <TransferConfigsTable
          dataSource={transferConfigs}
          isLoading={isLoading}
          selectedIds={selectedConfigIds}
          onDeleteConfigs={onDeleteConfigs}
          onRenameConfiguration={onRenameConfiguration}
          noConfigsMessage={noConfigsMessage}
          onWorkspaceClick={onWorkspaceClick}
        />
      </ModalLayout>
    </ModalTemplate>
  );
};

const onRenameConfiguration =
  (integrationId: IntegrationId) =>
  (name: string, config: SavedTransferConfig) => {
    dispatchAction(renameConfiguration({ integrationId, name, config }));
  };

const onDeleteConfigs = (configs: SavedTransferConfig[]) => {
  dispatchAction(bulkDeleteConfigurations({ configs }));
};

const onWorkspaceClick = (workspace: IntegrationWorkspace) => {
  if (workspace.id) {
    dispatchAction(
      setVisibleAsset({ assetId: workspace.id, assetType: 'workspace' })
    );
  }
};

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest([
      getTransferConfigsStream(integrationId),
      getIntegrationTermsDictionaryStream(integrationId),
    ]).pipe(
      map(
        ([
          { configs, asyncStatus, selectedConfigIds },
          integrationTermsDictionary,
        ]) => ({
          transferConfigs: configs,
          isLoading: asyncStatus === 'LOADING',
          selectedConfigIds,
          onRenameConfiguration: onRenameConfiguration(integrationId),
          onDeleteConfigs,
          onWorkspaceClick,
          noConfigsMessage: integrationTermsDictionary.noConfigsMessage,
        })
      )
    )
  )
);

const TransferConfigs = connect(TransferConfigsComponent, viewModel$);

export const startTransferConfigsModal = () =>
  modal(resolve => <TransferConfigs onClose={() => resolve(null)} />, {
    autoFocus: true,
    shouldCloseOnEscapeKey: false,
  });
