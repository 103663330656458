import { useState } from 'react';
import { connect } from '@ardoq/rxbeach';
import {
  ServiceNowTable,
  ServiceNowField,
} from '@ardoq/api-types/integrations';
import {
  DefaultQueryFilterButton,
  FieldSelector as FieldSelectorComponent,
} from '@ardoq/integrations';
import { viewModel$ } from './viewModel';
import { startQueryFilterModal } from 'integrations/service-now/modals/QueryFilterModal';

const filterFields = (
  search: string,
  fields: ServiceNowField[]
): ServiceNowField[] => {
  const sanitizedSearch = search.toLowerCase().trim();
  const searchTerms = sanitizedSearch.split(/[\s_]+/);

  return fields.filter(f => {
    const label = f.label.toLowerCase();
    const name = f.name.toLowerCase();

    return searchTerms.every(
      term => label.includes(term) || name.includes(term)
    );
  });
};

export const FieldSelector = ({
  currentTable,
  isLoading,
  availableFields,
  selectedFields,
  onClickRemoveAll,
  onClickAddAll,
  onClickRemove,
  onClickAdd,
  onApplyQueryFilter,
  queryFilter,
  maxHeight,
}: {
  currentTable: ServiceNowTable | null;
  isLoading: boolean;
  availableFields: ServiceNowField[] | null;
  selectedFields: ServiceNowField[];
  onClickRemoveAll: () => void;
  onClickAddAll: () => void;
  onClickRemove: (field: ServiceNowField) => void;
  onClickAdd: (field: ServiceNowField) => void;
  onApplyQueryFilter: (queryFilter: string) => void;
  queryFilter: string | null;
  maxHeight?: number;
}) => {
  const [search, setSearch] = useState('');

  const filteredSelectedFields = filterFields(search, selectedFields);
  const filteredAvailableFields =
    availableFields && filterFields(search, availableFields);

  return (
    <FieldSelectorComponent
      isLoading={isLoading}
      selectedFields={filteredSelectedFields}
      availableFields={filteredAvailableFields}
      tableName={currentTable?.label || null}
      search={search}
      onSearch={setSearch}
      onClickRemoveAll={onClickRemoveAll}
      onClickAddAll={onClickAddAll}
      onClickRemove={onClickRemove}
      onClickAdd={onClickAdd}
      total={(availableFields?.length || 0) + selectedFields.length}
      selectedTotal={selectedFields.length}
      queryButton={
        <DefaultQueryFilterButton
          label="Query filter"
          isDisabled={!currentTable}
          isActive={Boolean(queryFilter)}
          onClick={() =>
            startQueryFilterModal({
              onApplyQueryFilter,
              tableName: currentTable?.label || '',
              queryFilter,
            })
          }
        />
      }
      maxHeight={maxHeight}
    />
  );
};

export default connect(FieldSelector, viewModel$);
