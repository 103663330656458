/**
 * Don't change the order of these keys, that will break all URLs with
 * encoded loaded state. Appending new keys is fine.
 */
export const defaultKeys = [
  'AND',
  'OR',
  'UNSET',
  'between',
  'boolean',
  'checkbox',
  'checked',
  'child-count',
  'component-key',
  'contains',
  'contains_phrase',
  'contains_substring',
  'created',
  'created-by',
  'date',
  'default',
  'description',
  'displayText',
  'equal',
  'false',
  'from',
  'greater',
  'greater_or_equal',
  'is_empty',
  'is_not_empty',
  'last-modified',
  'last-updated',
  'less',
  'less_or_equal',
  'name',
  'none',
  'not_contains',
  'not_contains_phrase',
  'not_contains_substring',
  'not_equal',
  'null',
  'number',
  'parent',
  'rootWorkspace',
  'select',
  'solid',
  'source',
  'string',
  'target',
  'text',
  'to',
  'true',
  'type',
  'typeName',
  'unchecked',
  'undefined',
];
