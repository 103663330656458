import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  resetStepStates,
  subdivisionEditorChangesAreSaved,
  touchStep,
  updateSubdivision,
} from './actions';
import {
  resetToDefaultState,
  setUnsavedState,
} from 'streams/unsavedState/actions';
import { UnsavedStateOwner } from 'streams/unsavedState/types';
import { filter, map, tap } from 'rxjs';
import { deleteSubdivisionSuccessfully } from 'streams/subdivisions/actions';
import { showToast, ToastType } from '@ardoq/status-ui';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';

const STRINGS = SUBDIVISIONS_STRINGS.ROUTINES;

const hasEditRoutine = routine(
  ofType(touchStep, updateSubdivision),
  extractPayload(),
  tap(() => {
    dispatchAction(
      setUnsavedState({ [UnsavedStateOwner.SUBDIVISION_EDITOR]: true })
    );
  })
);

const savedChanges = routine(
  ofType(deleteSubdivisionSuccessfully),
  tap(() => {
    dispatchAction(
      setUnsavedState({ [UnsavedStateOwner.SUBDIVISION_EDITOR]: false })
    );
  })
);

const resetState = routine(
  ofType(resetToDefaultState),
  extractPayload(),
  map(affectedOwners =>
    affectedOwners.includes(UnsavedStateOwner.SUBDIVISION_EDITOR)
  ),
  tap(mustResetState => {
    if (mustResetState) {
      dispatchAction(resetStepStates());
    }
  })
);

const changesSavedRoutine = routine(
  ofType(subdivisionEditorChangesAreSaved),
  extractPayload(),
  tap(() => {
    dispatchAction(
      setUnsavedState({ [UnsavedStateOwner.SUBDIVISION_EDITOR]: false })
    );
  }),
  filter(({ silent }) => !silent),
  tap(() => {
    showToast(STRINGS.SAVE_SUCCESS, ToastType.SUCCESS);
  })
);

const subdivisionEditorRoutines = collectRoutines(
  changesSavedRoutine,
  hasEditRoutine,
  savedChanges,
  resetState
);

export default subdivisionEditorRoutines;
