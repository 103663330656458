import broadcast$ from 'broadcasts/broadcast$';
import { combineLatest, map } from 'rxjs';
import { hasFeature, Features } from '@ardoq/features';
import currentUser$ from 'streams/currentUser/currentUser$';

const broadcastOverview$ = combineLatest([currentUser$, broadcast$]).pipe(
  map(([currentUser, { fetchStatus }]) => {
    return {
      fetchStatus,
      hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
      currentUserId: currentUser._id,
    };
  })
);

export default broadcastOverview$;
