import * as fonts from 'app/designTokens/fonts';
import { scenarioMergeSidebarStepNameActiveTemp } from 'app/designTokens/fonts';
import { colors, s16, s32, s56_DEPRECATED, s8 } from '@ardoq/design-tokens';
import { normal14Italic } from '@ardoq/typography';
import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div`
  padding: ${s16} 0;
  border-bottom: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  ${fonts.scenarioMergeSidebarHeaderTemp}
  i {
    padding: 0 ${s8};
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: ${colors.grey35};
  color: #fff;
  padding: 0 16px;
`;

const BaseStepItem = styled.div`
  cursor: pointer;
`;

export const ChevronIconWrapper = styled.div`
  display: inherit;
`;

export const MainStepItem = styled(BaseStepItem)<{
  $isSelected?: boolean;
  $isExpanded?: boolean;
  $isLast?: boolean;
}>`
  ${fonts.scenarioMergeSidebarStepNameTemp};
  padding-left: ${s32};
  display: flex;
  justify-content: space-between;

  &:hover {
    opacity: 1;
    ${ChevronIconWrapper} {
      display: inherit;
    }
  }

  ${({ $isLast }) =>
    !$isLast &&
    css`
      margin-bottom: 24px;
    `}

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          ${scenarioMergeSidebarStepNameActiveTemp}
        `
      : css`
          opacity: 0.5;
        `}

  ${({ $isExpanded }) =>
    $isExpanded
      ? css`
          margin-bottom: 40px;
        `
      : css`
          ${ChevronIconWrapper} {
            display: none;
          }
        `}
`;

export const SubStepItem = styled(BaseStepItem)<{
  $isLast?: boolean;
  $isSelected?: boolean;
  $isDisabled: boolean;
}>`
  ${fonts.scenarioMergeSidebarStepNameTemp};
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 56px;
  margin-top: ${s16};

  &:hover {
    opacity: 1;
  }

  ${({ $isSelected }) =>
    $isSelected
      ? scenarioMergeSidebarStepNameActiveTemp
      : css`
          opacity: 0.5;
        `}

  ${({ $isLast }) =>
    $isLast &&
    css`
      margin-bottom: 40px;
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5 !important;
      cursor: default;
    `}
`;

export const SubStepCount = styled.span`
  display: flex;
  justify-content: space-between;
  margin-right: ${s8};
  width: ${s56_DEPRECATED};
`;

export const StepsBody = styled.div`
  position: relative;
`;

export const StepsSvg = styled.svg`
  width: 150px;
  height: 460px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`;

export const StepsGroupWrapper = styled.div`
  background: ${colors.grey60};
  border-radius: 16px;
  padding: 8px;
`;

export const StepsGroupTitle = styled.div`
  ${normal14Italic};
  color: ${colors.grey95};
  margin-left: 24px;
  margin-top: 16px;
  margin-bottom: 4px;
`;
