import {
  QueryBuilderSubquery,
  QueryComponentSelection,
} from '@ardoq/api-types';
import { mapContainsOperatorForQueryRules } from '../search/AdvancedSearch/utils';

const consolidateLegacyOperators = (
  query: QueryBuilderSubquery
): QueryBuilderSubquery => ({
  ...query,
  rules: mapContainsOperatorForQueryRules(query.rules),
});

// The operators `contains`, `contains_phrase`, `not_contains`, `not_contains_phrase` are deprecated
// but still exists in saved slides and URLs. This is a first step towards consolidating
// these operators to only use `contains_substring` and `not_contains_substring`.
export const consolidateOperatorsForComponentSelection = (
  componentSelection: QueryComponentSelection
): QueryComponentSelection => ({
  ...componentSelection,
  startQuery: {
    ...componentSelection.startQuery,
    rules: [
      componentSelection.startQuery.rules[0], // search context rule
      consolidateLegacyOperators(componentSelection.startQuery.rules[1]), // user-defined rules
    ],
  },
});
