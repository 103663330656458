import { ActionCreatorWithoutNamespace, dispatchAction } from '@ardoq/rxbeach';
import { catchError } from 'rxjs/operators';
import { logError } from '@ardoq/logging';
import { mergeErrorAlert } from 'components/EntityMerge/dialogs';

export const mergeRequestErrorHandler = (
  setIsLoadingAction: ActionCreatorWithoutNamespace<boolean>
) =>
  catchError((error, observable) => {
    const { message, code, traceId } = error.response || {
      message: 'Missing response',
    };
    logError(error, 'An error occurred on merge request', { message, code });
    mergeErrorAlert({ message, errorCode: code, traceId });
    dispatchAction(setIsLoadingAction(false));
    return observable;
  });
