import { colors, s16, s32, s8 } from '@ardoq/design-tokens';
import { FlexBox, Stack } from '@ardoq/layout';
import { Header4, Link } from '@ardoq/typography';
import styled from 'styled-components';
import { Integration } from 'integrations/types';
import {
  IntegrationTileSize,
  IntegrationBox,
} from 'integrations/common/components/integrationBox/IntegrationBox';
import { Drawer, showDrawer } from '@ardoq/snowflakes';
import { Group } from 'integrations/types';
import { uniq } from 'lodash';
import { GhostButton } from '@ardoq/button';
import { getCurrentLocale, localeCompareBase } from '@ardoq/locale';
import { trackEvent } from 'tracking/tracking';
import { EVENT_NAMES } from 'integrations/common/tracking/events';

const CardContainer = styled(Stack)`
  min-width: 300px;
  color: ${colors.textDefault};
  padding: ${s16};

  border: 1px solid ${colors.borderAction};
  border-radius: ${s8};
  background-color: ${colors.surfaceDefault};
`;

const IntegrationGroupContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${s32};
`;

const RecentIntegrationContainer = styled(IntegrationGroupContainer)`
  padding-top: ${s16};
  gap: ${s16};
`;

const showAllIntegrations = (
  integrations: Integration[],
  onSelect: (integration: Integration) => void
) => {
  trackEvent(EVENT_NAMES.CLICKED_VIEW_ALL_INTEGRATIONS);
  showDrawer({
    shouldCloseOnEscapeKey: true,
    shouldCloseOnBackdropClick: true,
    stackPageName: 'AllIntegrationsDrawer',
    renderDrawer: ({ handleCloserDrawer }) => (
      <Drawer
        title="All integrations"
        controls={<GhostButton onClick={handleCloserDrawer}>Close</GhostButton>}
      >
        <IntegrationGroupContainer>
          {integrations
            .filter(integration => integration.group === Group.INTEGRATIONS)
            .map(integration => (
              <IntegrationBox
                key={integration.id}
                integration={integration}
                size={IntegrationTileSize.medium}
                onClick={integration => {
                  trackEvent(EVENT_NAMES.CLICKED_RECENT_INTEGRATION, {
                    integration: integration.id,
                  });
                  onSelect(integration);
                  handleCloserDrawer();
                }}
              />
            ))}
        </IntegrationGroupContainer>
      </Drawer>
    ),
  });
};

interface RecentIntegrationsWidgetProps {
  recentIntegrationsIds: string[];
  integrations: Integration[];
  maxLength?: number;
  onIntegrationSelect: (integration: Integration) => void;
}

export const RecentIntegrationsWidget = ({
  recentIntegrationsIds,
  integrations,
  maxLength = 8,
  onIntegrationSelect,
}: RecentIntegrationsWidgetProps) => {
  const locale = getCurrentLocale();
  const recentIntegrations = uniq(recentIntegrationsIds)
    .reduce<Integration[]>((acc, id) => {
      const integration = integrations.find(
        integration => integration.id === id
      );

      if (integration) {
        acc.push(integration);
      }

      return acc;
    }, [])
    .slice(0, maxLength)
    .sort((a, b) => localeCompareBase(a.name, b.name, locale));

  return (
    <CardContainer>
      <FlexBox align="center" justify="space-between">
        <Header4>Recent Integrations</Header4>
        <Link
          hideIcon
          underlineOnHover
          color={colors.textAction}
          typography="caption"
          onClick={() => showAllIntegrations(integrations, onIntegrationSelect)}
        >
          View all integrations
        </Link>
      </FlexBox>

      <RecentIntegrationContainer>
        {recentIntegrations.map(integration => (
          <IntegrationBox
            key={integration.id}
            integration={integration}
            size={IntegrationTileSize.small}
            onClick={integration => {
              trackEvent(EVENT_NAMES.CLICKED_RECENT_INTEGRATION, {
                integration: integration.id,
              });
              onIntegrationSelect(integration);
            }}
          />
        ))}
      </RecentIntegrationContainer>
    </CardContainer>
  );
};
