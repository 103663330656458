import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import styled from 'styled-components';
import { AudienceTableTab } from './audienceTable/types';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getEmptyTableContent = (tab: AudienceTableTab) => {
  const tabRelatedString =
    tab === AudienceTableTab.COMPONENT ? 'Components' : 'An audience';
  return {
    title: 'Nothing here yet...',
    message: `${tabRelatedString} based on your selection will be shown here.`,
  };
};

export type EmptyTableContent = {
  title: string;
  message: string;
};

type EmptyContentProps = {
  tab?: AudienceTableTab;
  emptyTableContent?: EmptyTableContent;
};

const EmptyContent = ({
  tab = AudienceTableTab.AUDIENCE,
  emptyTableContent,
}: EmptyContentProps) => {
  const titleAndMessage = emptyTableContent ?? getEmptyTableContent(tab);
  return (
    <Container>
      <DoqWithSpeechBubble
        doqType={DoqType.BROADCAST}
        layout={DoqLayout.DIALOG}
        {...titleAndMessage}
      />
    </Container>
  );
};

export default EmptyContent;
