import { Component } from 'react';
import styled from 'styled-components';
import SectionForm from './sectionForm/SectionForm';
import Section from './sectionForm/section';
import ComponentLegend from './legends/ComponentLegend';
import ReferenceLegend from './legends/ReferenceLegend';
import WorkspaceLegend from './legends/workspaceLegend';
import DynamicFilterLegend from './legends/dynamicFilter/DynamicFilterLegend';
import {
  getDebouncedSetExpandedSetting,
  getMultiExpandedSettings,
} from './quickPerspectivesUtils';
import { SectionExpandedSettings, SectionSettingKey } from './types';
import { isUndefinedOrNull } from 'utils/collectionUtil';
import QuickPerspectivesTopBar from './QuickPerspectivesTopBar';
import { connect } from '@ardoq/rxbeach';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import { colors } from '@ardoq/design-tokens';
import { map } from 'rxjs';
import { perspectiveInterface } from 'modelInterface/perspectives/perspectiveInterface';

const QuickPerspectivesSections = styled.div`
  background-color: ${colors.grey95};
  flex: 1;
  overflow: auto;
`;

type DebouncedSetExpandedSetting = {
  [settingKey in SectionSettingKey]?: (isExpanded: boolean) => void;
};

interface Props {
  toggleShow?: (settings: { expanded: boolean }) => void;
  scenarioId: string | null;
  userHasPermissionToSavePerspective: boolean;
}
interface State {
  isExpanded: SectionExpandedSettings;
}

enum TargetStateValue {
  InvertCurrentState = 'invertcurrentstate',
}
type TargetState = boolean | 'invertcurrentstate';

class QuickPerspectives extends Component<Props, State> {
  sectionRefs: { [section: string]: any } = {};
  debouncedSetExpandedSettings: DebouncedSetExpandedSetting = {};

  state = {
    isExpanded: getMultiExpandedSettings(
      SectionSettingKey.CONTAINER,
      SectionSettingKey.COMPONENTS,
      SectionSettingKey.REFERENCES,
      SectionSettingKey.WORKSPACES,
      SectionSettingKey.DYNAMIC_FILTERS
    ),
  };

  toggleExpanded = (
    settingKey: SectionSettingKey,
    targetState: TargetState = TargetStateValue.InvertCurrentState
  ) => {
    const currentState = this.state.isExpanded[settingKey];
    const newState =
      targetState === TargetStateValue.InvertCurrentState
        ? !currentState
        : targetState;
    if (newState === currentState) return;

    this.setState(prevState => ({
      isExpanded: {
        ...prevState.isExpanded,
        [settingKey]: newState,
      },
    }));

    if (!this.debouncedSetExpandedSettings[settingKey])
      this.debouncedSetExpandedSettings[settingKey] =
        getDebouncedSetExpandedSetting(settingKey);
    this.debouncedSetExpandedSettings[settingKey]!(newState);
    if (settingKey === SectionSettingKey.CONTAINER) {
      // Specific logic for toggling within Navigator
      this.props.toggleShow?.({ expanded: newState });
    }
  };

  /** Get refs for sections to support _scrollPerspectiveWindow calculations */
  registerSectionRefHandler =
    (refTitle: string) => (ref: HTMLDivElement | null) => {
      this.sectionRefs[refTitle] = ref;
    };

  setSectionFocus(settingKey: SectionSettingKey) {
    // Expand container & section when focused
    this.toggleExpanded(SectionSettingKey.CONTAINER, true);
    this.toggleExpanded(settingKey, true);

    const ref = this.sectionRefs[settingKey];
    const targetElementPositionY = ref && ref.offsetTop;
    if (!isUndefinedOrNull(targetElementPositionY)) {
      this._scrollPerspectiveWindow(targetElementPositionY);
    }
  }

  composeSectionProps(title: string, settingKey: SectionSettingKey) {
    return {
      title,
      collapsible: true,
      isExpanded: this.state.isExpanded[settingKey],
      registerSectionRefHandler: this.registerSectionRefHandler,
      onToggleExpand: (isExpanded: boolean) =>
        this.toggleExpanded(settingKey, isExpanded),
    };
  }

  render() {
    return (
      <>
        <QuickPerspectivesTopBar
          userHasPermissionToSavePerspective={
            this.props.userHasPermissionToSavePerspective
          }
          isOpened={this.state.isExpanded.Container}
          toggleOpen={
            this.props.toggleShow
              ? () => {
                  this.toggleExpanded(SectionSettingKey.CONTAINER);
                }
              : undefined
          }
        />
        {this.state.isExpanded.Container && (
          <QuickPerspectivesSections
            ref={this.registerSectionRefHandler('Container')}
          >
            <SectionForm>
              <Section
                {...this.composeSectionProps(
                  'Workspaces',
                  SectionSettingKey.WORKSPACES
                )}
              >
                <WorkspaceLegend />
              </Section>
              <Section
                {...this.composeSectionProps(
                  'Components',
                  SectionSettingKey.COMPONENTS
                )}
              >
                <ComponentLegend />
              </Section>
              <Section
                {...this.composeSectionProps(
                  'References',
                  SectionSettingKey.REFERENCES
                )}
              >
                <ReferenceLegend />
              </Section>
              {!isScenarioMode() && (
                <Section
                  {...this.composeSectionProps(
                    'Graph Filters',
                    SectionSettingKey.DYNAMIC_FILTERS
                  )}
                >
                  <DynamicFilterLegend />
                </Section>
              )}
            </SectionForm>
          </QuickPerspectivesSections>
        )}
      </>
    );
  }

  _scrollPerspectiveWindow(targetElementPositionY: number) {
    const containerRef = this.sectionRefs.Container;
    if (!containerRef) {
      return;
    }
    const perspectivesTitleBarHeight = containerRef.offsetTop;
    const adjustedScrollPosition =
      targetElementPositionY - perspectivesTitleBarHeight;
    containerRef.scrollTop = adjustedScrollPosition;
  }
}

const viewModel$ = activeScenario$.pipe(
  map(({ scenarioId }) => {
    return {
      scenarioId,
      userHasPermissionToSavePerspective:
        perspectiveInterface.hasUserPermissionToSavePerspectives(),
    };
  })
);
export default connect(QuickPerspectives, viewModel$);
