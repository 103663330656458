import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { TraversalModule } from './types';
import { TraversalRoute } from 'router/appRouterTypes';
import { navigateToReportOverview } from '../../router/navigationActions';

const handleNavigateToTraversalOverview = () => ({
  traversalModule: TraversalModule.OVERVIEW,
});

const traversalsNavigation$ = persistentReducedStream(
  'traversalsNavigation$',
  null,
  [
    reducer<TraversalRoute | null>(
      navigateToReportOverview,
      handleNavigateToTraversalOverview
    ),
  ]
);

export default traversalsNavigation$;
