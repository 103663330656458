import { AssetRow } from '../AssetsBrowser/types';
import { ColumnsOptions } from '../EntityBrowser/types';
import {
  ColumnsProps,
  getLastModifiedByColumn,
  getLastModifiedDateColumn,
  getNameColumnIncludingSelectAllCheckbox,
} from '../AssetsBrowser/columns';

export const getAssetBrowserDialogColumns =
  (columnsProps: ColumnsProps) =>
  (columnsOptions: ColumnsOptions<AssetRow>) => {
    return [
      {
        ...getNameColumnIncludingSelectAllCheckbox({
          ...columnsProps,
          ...columnsOptions,
        }),
        headerStyle: {
          paddingLeft: '65px',
        },
        cellStyle: { paddingLeft: '65px' },
      },
      getLastModifiedDateColumn(columnsProps),
      {
        ...getLastModifiedByColumn(columnsProps),
        headerStyle: {
          width: '265px',
          paddingRight: '65px',
        },
        cellStyle: { paddingRight: '65px' },
      },
    ];
  };
