import { EmailAudienceDialogStreamShape } from './types';
import { uniq } from 'lodash';
import { inviteNewUserOperations } from 'sharing/inviteNewUserOperations';

export const getDefaultState = (): EmailAudienceDialogStreamShape => ({
  emails: [],
  isEditingExistingAudience: false,
  formIsValid: false,
});

export const toEmailArray = (emailInput: string) => {
  return emailInput.split('\n').map(email => email.trim());
};

export const areEmailsValid = (emails: string[]) => {
  if (!emails.filter(Boolean).length) return false;
  return emails.map(inviteNewUserOperations.isEmailValid).every(Boolean);
};

export const numberOfValidEmails = (emails: string[]) => {
  return uniq(emails)
    .filter(Boolean)
    .map(inviteNewUserOperations.isEmailValid)
    .filter(Boolean).length;
};
