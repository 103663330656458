import reportBuilderForm$ from './reportBuilderForm$';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { isEqual } from 'lodash';
import { suggestionApi } from '@ardoq/api';
import {
  APIFieldAttributes,
  ArdoqId,
  ZonesBySubdivisionsIds,
} from '@ardoq/api-types';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import { finishedFetchingCustomFields } from './actions';

type AdvancedSearchCustomFieldsArgs = {
  workspaceIds: ArdoqId[];
  subdivisions: ZonesBySubdivisionsIds;
};

const advancedSearchCustomFields$ = reportBuilderForm$.pipe(
  filter(({ hasWorkspacesWithNoAccess }) => !hasWorkspacesWithNoAccess),
  map(({ reportTemplate: { workspaceIds, subdivisions } }) => ({
    workspaceIds,
    subdivisions,
  })),
  distinctUntilChanged<AdvancedSearchCustomFieldsArgs>(isEqual),
  switchMap(
    ({ workspaceIds, subdivisions }) =>
      suggestionApi.getReportSuggestionQuery<APIFieldAttributes>(
        'customField',
        '',
        workspaceIds,
        subdivisions
      ) // We don't merge date range fields for this because the advanced search query builder expects them to be separate fields
  ),
  map(customFields =>
    isArdoqError(customFields)
      ? {
          customFieldsError: getArdoqErrorMessage(customFields),
          customFields: [],
        }
      : { customFields, customFieldsError: null }
  ),
  tap(() => dispatchAction(finishedFetchingCustomFields())),
  startWith({ customFields: [], customFieldsError: null }),
  shareReplay({ bufferSize: 1, refCount: true })
);

export default advancedSearchCustomFields$;
