import { ReactElement, FC, useEffect } from 'react';
import { withErrorBoundaries } from '@ardoq/error-boundary';
import { loadImportsHistory } from 'integrations/actions';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { ImportHistory } from '@ardoq/api-types/integrations';
import integrationViewState$ from 'integrations/integrationViewState$';
import { logError } from '@ardoq/logging';

type RenderProps = {
  isLoadingImports: boolean;
  imports: ImportHistory[];
};

type ImportsHistoryApiConnectorProps = {
  children: (props: RenderProps) => ReactElement;
};
const ImportsHistoryApiConnector: FC<
  ImportsHistoryApiConnectorProps & RenderProps
> = props => {
  useEffect(() => {
    dispatchAction(loadImportsHistory());
  }, []);
  return props.children({
    imports: props.imports,
    isLoadingImports: props.isLoadingImports,
  });
};

const ProtectedImportsHistoryApiConnector = withErrorBoundaries({
  logError,
  errorContextDescription: 'Error at <ImportsHistoryApiConnector />',
})(ImportsHistoryApiConnector) as FC<
  ImportsHistoryApiConnectorProps & RenderProps
>;

export default connect(
  ProtectedImportsHistoryApiConnector,
  integrationViewState$
);
