import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { InventoryModule } from './consts';
import { inventoryModuleWasSelected } from '../router/navigationActions';
import { ArdoqId } from '@ardoq/api-types';

export type InventoryNavigationState =
  | DataOverviewInventoryNavigationState
  | WorkspaceInventoryNavigationState
  | ReportInventoryNavigationState
  | ComponentTypeInventoryNavigationState;

export type DataOverviewInventoryNavigationState = {
  inventoryModule: InventoryModule.OVERVIEW;
  dataSourceType: 'none';
};

export type WorkspaceInventoryNavigationState = {
  inventoryModule: InventoryModule.BROWSE;
  selectedWorkspaceIds: ArdoqId[];
  dataSourceType: 'workspace';
};

export type ComponentTypeInventoryNavigationState = {
  inventoryModule: InventoryModule.BROWSE;
  componentTypeNames: string[];
  dataSourceType: 'componentType';
};

export type ReportInventoryNavigationState = {
  inventoryModule: InventoryModule.BROWSE;
  selectedReportId: ArdoqId;
  dataSourceType: 'report';
};

const defaultState: DataOverviewInventoryNavigationState = {
  inventoryModule: InventoryModule.OVERVIEW,
  dataSourceType: 'none',
};

const setInventoryModuleAndSelectedWorkspace = (
  _state: InventoryNavigationState,
  newInventoryState: InventoryNavigationState
): InventoryNavigationState => newInventoryState;

const inventoryNavigation$ = persistentReducedStream(
  'inventoryNavigation$',
  defaultState,
  [reducer(inventoryModuleWasSelected, setInventoryModuleAndSelectedWorkspace)]
);

export default inventoryNavigation$;
