import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { switchMap, tap } from 'rxjs/operators';
import {
  fetchPreviewComponents,
  fetchPreviewComponentsError,
  openComponentPreviewDialog,
  setPreviewComponentsAndTotal,
} from './actions';
import { modal } from '@ardoq/modal';
import { createElement } from 'react';
import ComponentPreviewDialog from './ComponentPreviewDialog';
import { broadcastApi, handleError } from '@ardoq/api';

const handleOpenComponentPreviewDialog = routine(
  ofType(openComponentPreviewDialog),
  extractPayload(),
  tap(content =>
    modal(resolve =>
      createElement(ComponentPreviewDialog, { resolve, content })
    )
  )
);

const handleFetchPreviewComponents = routine(
  ofType(fetchPreviewComponents),
  extractPayload(),
  switchMap(broadcastApi.previewComponents),
  handleError(() => dispatchAction(fetchPreviewComponentsError())),
  tap(response => dispatchAction(setPreviewComponentsAndTotal(response)))
);

export default collectRoutines(
  handleOpenComponentPreviewDialog,
  handleFetchPreviewComponents
);
