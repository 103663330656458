import { reducedStream, reducer } from '@ardoq/rxbeach';
import {
  SetUseCaseFieldValuePayload,
  UpdatePdfLinkPayload,
  UpdateTriplePartPayload,
  UseCaseEditorStreamState,
} from '../types';
import {
  addTriple,
  removeTriple,
  addPdfLink,
  removePdfLink,
  updatePdfLink,
  setEntityGroup,
  setEditorErrors,
  resetEditor,
  setInitialEditorState,
  setFieldValue,
  updateTripleSourceComponentType,
  updateTripleReferenceType,
  updateTripleTargetComponentType,
} from './actions';
import { useCaseEditorOperations } from './useCaseEditorOperations';
import { Triple } from '@ardoq/api-types';

const createEmptyTriple = (
  state: UseCaseEditorStreamState
): UseCaseEditorStreamState => ({
  ...state,
  metamodelTriples: [
    ...state.metamodelTriples,
    useCaseEditorOperations.getEmptyTriple(),
  ],
});

const removeExistingTriple = (
  state: UseCaseEditorStreamState,
  index: number
): UseCaseEditorStreamState => {
  const filteredTriples = state.metamodelTriples.filter(
    (_v, idx) => idx !== index
  );
  return { ...state, metamodelTriples: filteredTriples };
};

const updateTripleSourceCompType = (
  state: UseCaseEditorStreamState,
  payload: UpdateTriplePartPayload
): UseCaseEditorStreamState => {
  const updatedTriples: Triple[] = state.metamodelTriples.map(
    ([sourceComponentType, referenceType, targetComponentType], idx) => {
      if (idx === payload.index) {
        return [
          payload.value ?? sourceComponentType,
          referenceType,
          targetComponentType,
        ];
      }
      return [sourceComponentType, referenceType, targetComponentType];
    }
  );
  return { ...state, metamodelTriples: updatedTriples };
};

const updateTripleRefType = (
  state: UseCaseEditorStreamState,
  payload: UpdateTriplePartPayload
): UseCaseEditorStreamState => {
  const updatedTriples: Triple[] = state.metamodelTriples.map(
    ([sourceComponentType, referenceType, targetComponentType], idx) => {
      if (idx === payload.index) {
        return [
          sourceComponentType,
          payload.value ?? referenceType,
          targetComponentType,
        ];
      }
      return [sourceComponentType, referenceType, targetComponentType];
    }
  );
  return { ...state, metamodelTriples: updatedTriples };
};

const updateTripleTargetCompType = (
  state: UseCaseEditorStreamState,
  payload: UpdateTriplePartPayload
): UseCaseEditorStreamState => {
  const updatedTriples: Triple[] = state.metamodelTriples.map(
    ([sourceComponentType, referenceType, targetComponentType], idx) => {
      if (idx === payload.index) {
        return [
          sourceComponentType,
          referenceType,
          payload.value ?? targetComponentType,
        ];
      }
      return [sourceComponentType, referenceType, targetComponentType];
    }
  );
  return { ...state, metamodelTriples: updatedTriples };
};

const createEmptyPdfLink = (
  state: UseCaseEditorStreamState
): UseCaseEditorStreamState => ({
  ...state,
  pdfLinks: [...state.pdfLinks, useCaseEditorOperations.getEmptyPdfLink()],
});

const removeExistingPdfLink = (
  state: UseCaseEditorStreamState,
  index: number
): UseCaseEditorStreamState => {
  const filteredPdfLinks = state.pdfLinks.filter((_v, idx) => idx !== index);
  return { ...state, pdfLinks: filteredPdfLinks };
};

const updateExistingPdfLink = (
  state: UseCaseEditorStreamState,
  payload: UpdatePdfLinkPayload
): UseCaseEditorStreamState => {
  const { index, updatedLink } = payload;
  const updatedLinks = state.pdfLinks.map((pdfLink, idx) => {
    if (idx === index) {
      return { ...pdfLink, ...updatedLink };
    }
    return pdfLink;
  });
  return { ...state, pdfLinks: updatedLinks };
};

const setUseCaseEntityGroupAndResetDependentFields = (
  state: UseCaseEditorStreamState,
  entityGroupId: string
): UseCaseEditorStreamState => ({
  ...state,
  entityGroupId,
  keyMetricsDashboardId: '',
  metamodelTriples: [],
});

const setUseCaseEditingErrors = (
  state: UseCaseEditorStreamState,
  errors: string[]
): UseCaseEditorStreamState => ({ ...state, errors });

const setInitialUseCaseEditorState = (
  state: UseCaseEditorStreamState,
  newState: Partial<UseCaseEditorStreamState>
): UseCaseEditorStreamState => ({
  ...state,
  ...newState,
  initialStateToCompare:
    useCaseEditorOperations.transformEditorStreamStateToComparableFormat(
      newState
    ),
});

const setUseCaseFieldValue = (
  state: UseCaseEditorStreamState,
  { key, value }: SetUseCaseFieldValuePayload
): UseCaseEditorStreamState => ({
  ...state,
  [key]: value ?? '',
});

export const useCaseEditor$ = reducedStream<UseCaseEditorStreamState>(
  'useCaseEditor',
  useCaseEditorOperations.getDefaultUseCaseEditorState(),
  [
    reducer(addTriple, createEmptyTriple),
    reducer(removeTriple, removeExistingTriple),
    reducer(updateTripleSourceComponentType, updateTripleSourceCompType),
    reducer(updateTripleReferenceType, updateTripleRefType),
    reducer(updateTripleTargetComponentType, updateTripleTargetCompType),
    reducer(addPdfLink, createEmptyPdfLink),
    reducer(removePdfLink, removeExistingPdfLink),
    reducer(updatePdfLink, updateExistingPdfLink),
    reducer(setEntityGroup, setUseCaseEntityGroupAndResetDependentFields),
    reducer(setEditorErrors, setUseCaseEditingErrors),
    reducer(resetEditor, useCaseEditorOperations.getDefaultUseCaseEditorState),
    reducer(setInitialEditorState, setInitialUseCaseEditorState),
    reducer(setFieldValue, setUseCaseFieldValue),
  ]
);
