import { AppModules } from 'appContainer/types';
import { Route } from 'router/StreamRouter';
import { AppRouterState, UseCasesModuleRoute } from 'router/appRouterTypes';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToUseCases } from './actions';
import { map } from 'rxjs';
import useCaseLandingPage$ from './UseCaseLandingPage/useCaseLandingPage$';
import { selectUseCase } from './UseCaseLandingPage/actions';

const useCasesRegex = /^\/use-cases\/(?:([a-zA-Z0-9]+)\/?)?$/; // use to capture use case id

const getUseCasesPath = ({ selectedUseCaseId }: UseCasesModuleRoute) =>
  `/use-cases${selectedUseCaseId ? `/${selectedUseCaseId}` : ''}`;

const useCasesRoute = new Route<AppRouterState, UseCasesModuleRoute>({
  doesLocationMatch: ({ path }) => new RegExp(`\\/use-cases/?`).test(path),
  locationToRouterState: ({ path }) => {
    const [selectedUseCaseId = null] = path.match(useCasesRegex)?.slice(1) ?? [
      null,
    ];
    return {
      appModule: AppModules.USE_CASES,
      selectedUseCaseId,
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.USE_CASES,
  routerStateToLocation: routerState => ({
    path: getUseCasesPath(routerState),
    title: 'Use cases',
  }),
  setApplicationStateFromRoute: ({ selectedUseCaseId }) => {
    dispatchAction(navigateToUseCases());
    if (selectedUseCaseId) dispatchAction(selectUseCase(selectedUseCaseId));
  },
  getPartialRouterStateStream: () =>
    useCaseLandingPage$.pipe(
      map(({ selectedUseCaseId }) => ({
        selectedUseCaseId,
      }))
    ),
});

export default useCasesRoute;
