import {
  exportRun,
  importRun,
  transferRun,
} from 'integrations/common/streams/transferState/actions';
import {
  dispatchAction,
  routine,
  ofType,
  extractPayload,
} from '@ardoq/rxbeach';
import { filter, map, tap, withLatestFrom } from 'rxjs';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { selectionState$ } from '../streams/selectionState/selectionState$';
import { getSourceConfig } from '../utils';

const handleTransferRun = routine(
  ofType(transferRun),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'microsoft-entra-id'),
  withLatestFrom(getTransferStateStream('microsoft-entra-id'), selectionState$),
  map(([{ async, integrationId, dryRun }, transferState, selectionState]) => {
    const sourceConfig = getSourceConfig(selectionState);

    return {
      async,
      integrationId,
      dryRun,
      transferState,
      ...(sourceConfig ? { sourceConfig } : {}),
    };
  }),
  tap(({ transferState, ...runParams }) => {
    const action =
      transferState.transferDirection === TransferDirection.IMPORT
        ? importRun
        : exportRun;
    dispatchAction(action(runParams));
  })
);

export default [handleTransferRun];
