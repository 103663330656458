import { connect } from '@ardoq/rxbeach';
import { find } from 'lodash/fp';
import { modal } from '@ardoq/modal';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { ConnectionsAsyncOperationsState } from 'integrations/common/streams/connections/types';
import { ServiceNowConnection } from '@ardoq/api-types/integrations';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import {
  listConnections,
  upsertConnection,
  upsertConnectionFailure,
} from 'integrations/common/streams/connections/actions';
import { NewConnectionDialog } from '../components/NewConnectionDialog';
import { map } from 'rxjs';
import { Features, hasFeature } from '@ardoq/features';

type UpsertConnectionsModalParams = {
  connectionId?: string;
  isOauthEnabled: boolean;
  connections: ServiceNowConnection[];
  statuses: ConnectionsAsyncOperationsState;
  onCancel: () => void;
  onSubmit: (connection: ServiceNowConnection) => Promise<boolean>;
};

function UpsertConnectionsModalComponent({
  connectionId,
  isOauthEnabled,
  connections,
  statuses,
  onCancel,
  onSubmit,
}: UpsertConnectionsModalParams) {
  const existingConnection = find(
    ({ _id }) => connectionId === _id,
    connections
  );

  return (
    <NewConnectionDialog
      existingConnection={existingConnection}
      upsert={statuses.upsert}
      onCancel={onCancel}
      onSubmit={onSubmit}
      isOauthEnabled={isOauthEnabled}
    />
  );
}

const viewModel$ = getConnectionsStream<ServiceNowConnection>(
  'servicenow-v3'
).pipe(
  map(({ connections, statuses }) => ({
    connections,
    statuses,
    isOauthEnabled: hasFeature(Features.OAUTH_SERVICE_NOW_ENABLE),
  }))
);

const UpsertConnectionsModal = connect(
  UpsertConnectionsModalComponent,
  viewModel$
);

const onSubmit = async (connection: ServiceNowConnection) => {
  const action = await dispatchActionAndWaitForResponse(
    upsertConnection({ integrationId: 'servicenow-v3', connection }),
    listConnections,
    upsertConnectionFailure
  );
  return action.type === listConnections.type;
};

export const startUpsertConnectionsModal = (connectionId?: string) =>
  modal(
    close => (
      <UpsertConnectionsModal
        connectionId={connectionId}
        onCancel={() => close(null)}
        onSubmit={onSubmit}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
