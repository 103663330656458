import { ExcludeFalsy } from '@ardoq/common-helpers';
import { type ValidationDescriptor, validateAs } from '@ardoq/validation';
import {
  GridEditorDisplayableField,
  GridEditorField,
  GridEditorFieldType,
  VIEW_TYPES,
} from './types';
import { TAGS_FIELD_NAME } from '@ardoq/data-model';
import { APIFieldType } from '@ardoq/api-types';

const createdAndUpdatedFields: GridEditorField[] = [
  {
    name: 'created',
    label: 'Created at',
    fieldType: GridEditorFieldType.CREATED_AT,
    checked: false,
  },
  {
    name: 'last-updated',
    label: 'Updated at',
    fieldType: GridEditorFieldType.UPDATED_AT,
    checked: false,
  },
];

const ardoqOIDField = {
  name: '_id',
  label: 'Ardoq OID',
  fieldType: GridEditorFieldType.ARDOQ_OID,
  checked: false,
};
const ardoqIDField = {
  name: 'component-key',
  label: 'Ardoq ID',
  fieldType: GridEditorFieldType.ARDOQ_OID,
  checked: false,
};

export const getDefaultFields = (activeViewType: VIEW_TYPES) => {
  return activeViewType === VIEW_TYPES.COMPONENT
    ? [...COMPONENT_DEFAULT_FIELDS]
    : [...REFERENCE_DEFAULT_FIELDS];
};

export const getDefaultFieldNames = (activeViewType: VIEW_TYPES) => {
  return activeViewType === VIEW_TYPES.COMPONENT
    ? [...COMPONENT_DEFAULT_FIELD_NAMES]
    : [...REFERENCE_DEFAULT_FIELD_NAMES];
};

const COMPONENT_DEFAULT_FIELDS: GridEditorField[] = [
  {
    // This is for the empty column header over the "row header"
    isSeparator: true,
  },
  ardoqOIDField,
  ardoqIDField,
  {
    name: 'name',
    label: 'Name',
    fieldType: GridEditorFieldType.COMPONENT_NAME,
    checked: true,
  },
  {
    name: 'description',
    label: 'Description',
    checked: false,
  },
  {
    name: 'parent',
    label: 'Parent',
    fieldType: GridEditorFieldType.PARENT_COMPONENT,
    checked: false,
  },
  {
    name: TAGS_FIELD_NAME,
    label: 'Tags',
    fieldType: GridEditorFieldType.TAGS,
    checked: false,
  },
  {
    name: 'type',
    label: 'Component type',
    fieldType: GridEditorFieldType.COMPONENT_TYPE,
    checked: false,
  },
  {
    name: 'locked',
    label: 'Locked',
    checked: false,
    fieldType: GridEditorFieldType.LOCKED_STATE,
  },
  ...createdAndUpdatedFields,
];

const REFERENCE_DEFAULT_FIELDS: GridEditorField[] = [
  {
    isSeparator: true,
    label: 'Source',
  },
  {
    name: 'srcName',
    label: 'Name',
    fieldType: GridEditorFieldType.SOURCE_COMPONENT_NAME,
    checked: false,
  },
  {
    name: 'srcType',
    label: 'Type',
    fieldType: GridEditorFieldType.SOURCE_COMPONENT_TYPE,
    checked: false,
  },
  {
    name: 'srcParent',
    label: 'Parent',
    fieldType: GridEditorFieldType.SOURCE_COMPONENT_PARENT,
    checked: false,
  },
  {
    isSeparator: true,
    label: 'Reference',
  },
  {
    name: 'refType',
    label: 'Type',
    fieldType: GridEditorFieldType.REFERENCE_TYPE,
    checked: false,
  },
  {
    isSeparator: true,
    label: 'Target',
  },
  {
    name: 'targetName',
    label: 'Name',
    fieldType: GridEditorFieldType.TARGET_COMPONENT_NAME,
    checked: false,
  },
  {
    name: 'targetType',
    label: 'Type',
    fieldType: GridEditorFieldType.TARGET_COMPONENT_TYPE,
    checked: false,
  },
  {
    name: 'targetParent',
    label: 'Parent',
    fieldType: GridEditorFieldType.TARGET_COMPONENT_PARENT,
    checked: false,
  },
  {
    isSeparator: true,
    label: 'Reference fields',
  },
  {
    name: 'refDisplayText',
    label: 'Display text',
    checked: false,
  },
  {
    name: 'refOrder',
    label: 'Order',
    checked: false,
  },
  {
    name: 'refDescription',
    label: 'Description',
    checked: false,
  },
  {
    name: TAGS_FIELD_NAME,
    label: 'Tags',
    fieldType: GridEditorFieldType.TAGS,
    checked: false,
  },
  ardoqOIDField,
  ...createdAndUpdatedFields,
];

const COMPONENT_DEFAULT_FIELD_NAMES = COMPONENT_DEFAULT_FIELDS.map(field =>
  field.isSeparator ? undefined : field.name
).filter(ExcludeFalsy);
const REFERENCE_DEFAULT_FIELD_NAMES = REFERENCE_DEFAULT_FIELDS.map(field =>
  field.isSeparator ? undefined : field.name
).filter(ExcludeFalsy);

const ALL_DEFAULT_FIELD_NAMES = new Set([
  ...COMPONENT_DEFAULT_FIELD_NAMES,
  ...REFERENCE_DEFAULT_FIELD_NAMES,
]);

export const isDefaultFieldName = (
  fieldName: string
): fieldName is GridEditorDisplayableField['name'] => {
  return ALL_DEFAULT_FIELD_NAMES.has(fieldName);
};

const isDefaultFieldByName = (
  defaultField: GridEditorField,
  fieldName: string
): defaultField is GridEditorDisplayableField => {
  return !defaultField.isSeparator && defaultField.name === fieldName;
};

const getBuiltInGridEditorFieldByName = (
  fieldName: string
): GridEditorDisplayableField | undefined => {
  if (!isDefaultFieldName) {
    return undefined;
  }

  return (COMPONENT_DEFAULT_FIELDS.find(defaultField =>
    isDefaultFieldByName(defaultField, fieldName)
  ) ||
    REFERENCE_DEFAULT_FIELDS.find(defaultField =>
      isDefaultFieldByName(defaultField, fieldName)
    )) as GridEditorDisplayableField;
};

export const getBuiltInGridEditorFieldTypeByName = (fieldName: string) => {
  const defaultField = getBuiltInGridEditorFieldByName(fieldName);
  return defaultField?.fieldType ?? 'unknown';
};

export const getValidationDescriptorByGridEditorFieldType = (data: {
  fieldType: APIFieldType | GridEditorFieldType | 'unknown';
  value: any;
}): ValidationDescriptor => {
  const { value, fieldType } = data;

  switch (fieldType) {
    case GridEditorFieldType.COMPONENT_NAME:
      return {
        value,
        validationSteps: [validateAs.requiredString()],
      };
    default:
      return {
        value,
        validationSteps: [],
      };
  }
};
