import { createElement } from 'react';
import { PersistedIPAllowlistEntry } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { deleteIPEntry, setIPAllowlistAlert } from './ipAllowlist$/actions';
import { ParsedAddress } from './types';
import { ConfirmDeleteBody } from './atoms';
import { confirmDelete } from '@ardoq/modal';

type CIDRBlock = `${string}/${string}`;

const isCIDRBlock = (address: string): address is CIDRBlock =>
  /\/\d+$/.test(address);

const parseCIDRBlock = (block: CIDRBlock): ParsedAddress => {
  const [address, maskSizeStr] = block.split('/');
  const maskSize = parseInt(maskSizeStr, 10) || 32;
  return { address, maskSize, maskSizeSource: 'explicit' };
};

const isIPv4 = (address: string) => /^\d+(?:\.\d+){3}$/.test(address);

export const isValidIP = (address: string): boolean =>
  isCIDRBlock(address)
    ? isValidIP(parseCIDRBlock(address).address)
    : isIPv4(address);

/** If IP address is in CIDR format, extract the mask size. Else, if it's a
 * valid IPv4 address, infer mask size as default for that format. */
export const parseIPAddress = (address: string): ParsedAddress => {
  if (isCIDRBlock(address)) return parseCIDRBlock(address);
  const inferredMaskSize = isIPv4(address) ? 32 : null;
  if (!inferredMaskSize) {
    throw new Error('IP address does not match IPv4 test');
  }
  return { address, maskSize: inferredMaskSize, maskSizeSource: 'inferred' };
};

export const getMaskSizeIfValidIP = (address: string) =>
  isValidIP(address) ? parseIPAddress(address).maskSize : null;

export const setIPError = (message: string) =>
  dispatchAction(setIPAllowlistAlert({ message, type: 'error' }));

export const setIPSuccess = (message: string) =>
  dispatchAction(setIPAllowlistAlert({ message, type: 'success' }));

export const clearIPAlert = () => dispatchAction(setIPAllowlistAlert(null));

export const confirmEntryDeletion = (entry: PersistedIPAllowlistEntry) =>
  confirmDelete({
    title: `Delete ${entry.address}?`,
    body: createElement(ConfirmDeleteBody),
    onConfirmAsync: () => dispatchAction(deleteIPEntry(entry._id)),
  });
