import { reducedStream, reducer } from '@ardoq/rxbeach';
import {
  DecoratedSubdivision,
  ManageMembershipModalState,
  SelectionChanges,
} from './types';
import {
  initializeManageSubdivisionMembershipModal,
  selectedSubdivisionsChange,
  changeTablePage,
  applyAddSelection,
  applyRemoveSelection,
  addSubdivisionToAddSelection,
  removeSubdivisionFromAddSelection,
  clearAddSelection,
  addSubdivisionToRemoveSelection,
  removeSubdivisionFromRemoveSelection,
  clearRemoveSelection,
  applySelectionChanges,
  setSelectComponentRows,
} from './actions';
import { manageMembershipModalOperations } from './manageMembershipModalOperations';
import { ArdoqId } from '@ardoq/api-types';
import { map } from 'rxjs';
import { commands } from './commands';
import { membershipOperations } from './membershipOperations';

const reducers = [
  reducer<ManageMembershipModalState, ArdoqId[]>(
    selectedSubdivisionsChange,
    manageMembershipModalOperations.selectedSubdivisionsChangeReducer
  ),
  reducer<ManageMembershipModalState, ManageMembershipModalState>(
    initializeManageSubdivisionMembershipModal,
    manageMembershipModalOperations.initializeManageSubdivisionMemebershipModalReducer
  ),
  reducer<ManageMembershipModalState, number>(
    changeTablePage,
    manageMembershipModalOperations.changeTablePageReducer
  ),
  reducer<ManageMembershipModalState, DecoratedSubdivision>(
    addSubdivisionToAddSelection,
    manageMembershipModalOperations.addSubdivisionToAddSelectionReducer
  ),
  reducer<ManageMembershipModalState, DecoratedSubdivision>(
    removeSubdivisionFromAddSelection,
    manageMembershipModalOperations.removeSubdivisionFromAddSelectionReducer
  ),
  reducer<ManageMembershipModalState>(
    clearAddSelection,
    manageMembershipModalOperations.clearAddSelectionReducer
  ),
  reducer<ManageMembershipModalState>(
    applyAddSelection,
    manageMembershipModalOperations.applyAddSelectionReducer
  ),
  reducer<ManageMembershipModalState, DecoratedSubdivision>(
    addSubdivisionToRemoveSelection,
    manageMembershipModalOperations.addSubdivisionToRemoveSelectionReducer
  ),
  reducer<ManageMembershipModalState, DecoratedSubdivision>(
    removeSubdivisionFromRemoveSelection,
    manageMembershipModalOperations.removeSubdivisionFromRemoveSelectionReducer
  ),
  reducer<ManageMembershipModalState>(
    clearRemoveSelection,
    manageMembershipModalOperations.clearRemoveSelectionReducer
  ),
  reducer<ManageMembershipModalState>(
    applyRemoveSelection,
    manageMembershipModalOperations.applyRemoveSelectionReducer
  ),
  reducer<ManageMembershipModalState, SelectionChanges>(
    applySelectionChanges,
    manageMembershipModalOperations.applySelectionChangesReducer
  ),
  reducer<ManageMembershipModalState, ArdoqId[]>(
    setSelectComponentRows,
    manageMembershipModalOperations.setSelectComponentRowsReducer
  ),
];

const mapStreamToViewModel = (state: ManageMembershipModalState) => {
  const activeSubdivisions =
    manageMembershipModalOperations.getActiveDivisions(state);

  const tableData = manageMembershipModalOperations.prepareTableData(state);
  const currentPageTableData = tableData.slice(
    (state.currentPage - 1) * state.COMPONENTS_PER_PAGE,
    state.currentPage * state.COMPONENTS_PER_PAGE
  );

  return {
    ...state,
    commands: commands,
    tableData,
    activeSubdivisions: activeSubdivisions,
    noChangesToSave: !membershipOperations.hasChanges(state.membershipsState),
    currentPageTableData,
  };
};

export const manageMembershipModal$ = reducedStream(
  'manageMembershipModal$',
  manageMembershipModalOperations.getEmptyState(),
  reducers
).pipe(map(mapStreamToViewModel));
