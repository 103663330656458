import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { AppRouterState } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { map } from 'rxjs/operators';
import { BroadcastsPane, BroadcastsRoute } from './types';
import broadcastsNavigation$ from './broadcastsNavigation$';
import {
  initiateNavigationToNewBroadcastForm,
  navigateToBroadcastOverview,
  navigateToEditBroadcastForm,
} from 'router/navigationActions';

const broadcastsRoute = new Route<AppRouterState, BroadcastsRoute>({
  doesLocationMatch: ({ path }) => new RegExp(`/broadcasts/?`).test(path),
  locationToRouterState: ({ path }) => {
    const parts = path.split('/');
    const afterBroadcasts = parts[2];
    if (!afterBroadcasts) {
      return {
        appModule: AppModules.BROADCASTS,
        broadcastsPane: BroadcastsPane.OVERVIEW,
        broadcastId: null,
      };
    }
    if (afterBroadcasts === 'new') {
      return {
        appModule: AppModules.BROADCASTS,
        broadcastsPane: BroadcastsPane.NEW,
        broadcastId: null,
      };
    }
    return {
      appModule: AppModules.BROADCASTS,
      broadcastsPane: BroadcastsPane.EDIT,
      broadcastId: afterBroadcasts,
    };
  },
  doesRouterStateMatch: ({ appModule }) => appModule === AppModules.BROADCASTS,
  routerStateToLocation: ({ broadcastsPane, broadcastId }) => {
    if (broadcastsPane === BroadcastsPane.EDIT && broadcastId) {
      return {
        path: `/broadcasts/${broadcastId}`,
        title: 'Edit Broadcast',
      };
    }
    if (broadcastsPane === BroadcastsPane.NEW) {
      return {
        path: `/broadcasts/new`,
        title: 'New Broadcast',
      };
    }
    return { path: '/broadcasts/', title: 'Broadcasts' };
  },
  setApplicationStateFromRoute: ({ broadcastId, broadcastsPane }) => {
    if (broadcastsPane === BroadcastsPane.OVERVIEW) {
      dispatchAction(navigateToBroadcastOverview());
    } else if (broadcastsPane === BroadcastsPane.NEW) {
      dispatchAction(initiateNavigationToNewBroadcastForm(null));
    } else if (broadcastsPane === BroadcastsPane.EDIT && broadcastId) {
      dispatchAction(navigateToEditBroadcastForm(broadcastId));
    }
  },
  getPartialRouterStateStream: () =>
    broadcastsNavigation$.pipe(
      map(({ broadcastId, broadcastsPane }) => ({
        broadcastId,
        broadcastsPane,
      }))
    ),
});

export default broadcastsRoute;
