import {
  AdditionalContentProps,
  AncestorItemType,
  ExistingAncestorItem,
  StructuralConflict,
} from 'components/DiffMergeTable/types';
import {
  AdditionalContentHintText,
  AncestorsConflictContentWrapper,
  AncestorsConflictSectionTitle,
  AncestorsConflictSectionWrapper,
} from 'components/DiffMergeTable/atoms';
import {
  getWorkspaceByEntity,
  resolveConflictAncestors,
} from 'components/DiffMergeTable/utils';
import { APIEntityType } from '@ardoq/api-types';
import ComponentsAncestorsWithConflicts from 'components/DiffMergeTable/ComponentsAncestorsWithConflicts';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { Branch } from 'components/DiffMergeTable/Branch';
import { logError } from '@ardoq/logging';
import { EntityRepresentation } from '@ardoq/renderers';
import { FlexWrapper } from '../EntityRepresentation/atoms';
import { s8 } from '@ardoq/design-tokens';
import { pluralize } from '@ardoq/common-helpers';

const RowEntityRepresentation = ({
  dataSourceItem,
  graphics,
}: AdditionalContentProps) => (
  <FlexWrapper style={{ margin: `0 ${s8}` }}>
    <EntityRepresentation
      entityId={dataSourceItem.entityId}
      entityType={APIEntityType.COMPONENT}
      enhancedScopeData={dataSourceItem.enhancedDiffContextData.sourceBranch}
      graphics={graphics}
    />
  </FlexWrapper>
);

const RigidModelParentTypeHasChangedMessage = (
  props: AdditionalContentProps
) => (
  <AdditionalContentHintText>
    The parents of <RowEntityRepresentation {...props} />
    has been updated on the Mainline. Because it is a rigid model{' '}
    <RowEntityRepresentation {...props} /> can not be created on the Mainline,
    in order to fix that you need to exit the merge flow and handle it in the
    main app.
  </AdditionalContentHintText>
);

const MissingParentRecreatingMessage = (props: AdditionalContentProps) => {
  const { targetConflictAncestors } = resolveConflictAncestors({
    componentId: props.dataSourceItem.entityId,
    enhancedDiffContextData: props.dataSourceItem.enhancedDiffContextData,
  });
  const ancestorsToCreate = (
    Object.values(targetConflictAncestors) as ExistingAncestorItem[]
  ).filter(
    ({ componentId, ancestorItemType }) =>
      ancestorItemType === AncestorItemType.DELETED_COMPONENT &&
      componentId !== props.dataSourceItem.entityId
  );
  const ancestorsToCreateCount = ancestorsToCreate.length;
  return (
    <AdditionalContentHintText>
      In order to create this component its {ancestorsToCreateCount}{' '}
      {pluralize('ancestor', ancestorsToCreateCount)} will be recreated.
    </AdditionalContentHintText>
  );
};

const AdditionalContentOfStructuralConflict = ({
  dataSourceItem,
  graphics,
  ...rest
}: AdditionalContentProps) => {
  const { enhancedDiffContextData, structuralConflict } = dataSourceItem;

  const { sourceConflictAncestors, targetConflictAncestors } =
    resolveConflictAncestors({
      componentId: dataSourceItem.entityId,
      enhancedDiffContextData,
    });

  const workspace = getWorkspaceByEntity(
    dataSourceItem.entityId,
    APIEntityType.COMPONENT,
    enhancedDiffContextData[Branch.SOURCE]
  );

  if (!workspace) {
    logError(
      Error('Workspace not found'),
      `Workspace not found for componentId = ${dataSourceItem.entityId}`
    );
    return null;
  }

  const [sourceLabel, targetLabel] =
    enhancedDiffContextData.mergeDirection === MergeDirection.BRANCH_TO_MAINLINE
      ? ['Scenario', 'Mainline']
      : ['Mainline', 'Scenario'];

  return (
    <>
      {structuralConflict ===
        StructuralConflict.RIGID_MODEL_PARENT_TYPE_HAS_CHANGED && (
        <RigidModelParentTypeHasChangedMessage
          dataSourceItem={dataSourceItem}
          graphics={graphics}
          {...rest}
        />
      )}
      {structuralConflict === StructuralConflict.MISSING_ANCESTOR && (
        <MissingParentRecreatingMessage
          dataSourceItem={dataSourceItem}
          graphics={graphics}
          {...rest}
        />
      )}
      <AncestorsConflictContentWrapper>
        <AncestorsConflictSectionWrapper>
          <AncestorsConflictSectionTitle>
            {sourceLabel}
          </AncestorsConflictSectionTitle>
          <ComponentsAncestorsWithConflicts
            workspace={workspace}
            graphics={graphics}
            enhancedBranchOffScopeData={
              enhancedDiffContextData[Branch.BRANCH_OFF]
            }
            ancestorItems={sourceConflictAncestors}
            enhancedScopeData={enhancedDiffContextData[Branch.SOURCE]}
          />
        </AncestorsConflictSectionWrapper>
        <AncestorsConflictSectionWrapper>
          <AncestorsConflictSectionTitle>
            {targetLabel}
          </AncestorsConflictSectionTitle>
          <ComponentsAncestorsWithConflicts
            workspace={workspace}
            graphics={graphics}
            enhancedBranchOffScopeData={
              enhancedDiffContextData[Branch.BRANCH_OFF]
            }
            ancestorItems={targetConflictAncestors}
            enhancedScopeData={enhancedDiffContextData[Branch.TARGET]}
          />
        </AncestorsConflictSectionWrapper>
      </AncestorsConflictContentWrapper>
    </>
  );
};

export default AdditionalContentOfStructuralConflict;
