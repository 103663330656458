import { forwardRef } from 'react';
import MainToolbar from 'menus/topbar/MainToolbar';
import { ReactNode, PropsWithChildren } from 'react';
import Navbar from 'views/navbar/Navbar';
import { PageHeader, PageWrapper } from '@ardoq/page-layout';
import { DashboardModule } from './types';

interface DashboardModuleContainerProps {
  title: ReactNode;
  secondaryText: string;
  HeaderRightContent?: ReactNode;
  HeaderLeftContent?: ReactNode;
  hasNewJourneyFeature: boolean;
  dashboardModule: DashboardModule;
}

const DashboardModuleContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DashboardModuleContainerProps>
>(
  (
    {
      title,
      secondaryText,
      HeaderRightContent,
      HeaderLeftContent,
      hasNewJourneyFeature,
      children,
      dashboardModule,
    },
    ref
  ) => (
    <PageWrapper ref={ref}>
      {hasNewJourneyFeature ? (
        <Navbar
          primaryContent={title}
          secondaryContent={secondaryText}
          toolbarContent={
            dashboardModule === DashboardModule.OVERVIEW ? (
              <MainToolbar shouldUseNewJourneyVersion={true} />
            ) : null
          }
          primaryButton={HeaderRightContent}
        />
      ) : (
        <PageHeader
          title={title}
          leftContent={HeaderLeftContent}
          rightContent={HeaderRightContent}
        />
      )}
      {children}
    </PageWrapper>
  )
);

export default DashboardModuleContainer;
