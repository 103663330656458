import { connect, dispatchAction } from '@ardoq/rxbeach';
import Flex from 'atomicComponents/Flex';
import { Step } from '@ardoq/steppers';
import TabularConfigMapping from 'integrations/common/pages/tabularConfigMapping/TabularConfigMapping';
import { ReviewTest } from 'integrations/common/pages/reviewTest/ReviewTest';
import { ImportAndSchedule } from 'integrations/common/pages/importAndSchedule/ImportAndSchedule';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { FlowHeader } from 'integrations/common/components/flowHeader/FlowHeader';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { startTransferConfigsModal } from 'integrations/common/modals/transferConfigsModal/TransferConfigsModal';
import { Maybe } from '@ardoq/common-helpers';
import { startSaveModal } from 'integrations/common/modals/saveModal/SaveModal';
import { saveActiveConfiguration } from 'integrations/common/streams/transferConfigs/actions';
import {
  ImportRoute,
  OverviewRoute,
  RoutePath,
} from 'integrations/common/navigation/types';
import { Overview } from 'integrations/common/containers/overview/Overview';
import { isOverviewRoute } from 'integrations/common/navigation/utils';
import { getFlowTitle } from 'integrations/common/utils/step';
import { viewModel$ } from './initViewModel$';
import { MicrosoftEntraIDProps } from './types';
import { SelectData } from './pages/selectData/SelectData';
import { WaitingOverlay } from 'integrations/common/components/overlays/WaitingOverlay';
import { Stepper } from 'integrations/common/components/stepper';
import { StickyFooter } from 'integrations/common/containers/stickyFooter/StickyFooter';
import { isValidSelectionState } from './streams/selectionState/utils';
import { PageBody } from '@ardoq/page-layout';
import { FlowContainer } from 'integrations/common/components/flowContainer/FlowContainer';
import { loadSelection } from 'integrations/common/streams/selectionState/actions';
import { microsoftEntraId } from 'integrations/common/logos';

const handleSaveSelectedConfig = ({
  selectedTransferConfigId,
}: {
  selectedTransferConfigId: Maybe<string>;
}) => {
  if (!selectedTransferConfigId) {
    startSaveModal({
      integrationId: 'microsoft-entra-id',
      title: 'Save as a new version',
      isNewConfig: true,
    });

    return;
  }
  dispatchAction(
    saveActiveConfiguration({ integrationId: 'microsoft-entra-id' })
  );
};

const handleSaveAs = () => {
  startSaveModal({
    integrationId: 'microsoft-entra-id',
    title: 'Save as a new version',
    isNewConfig: true,
  });
};

const handleRestart = () => {
  dispatchAction(resetIntegration('microsoft-entra-id'));
};

const handleStartTransferConfigsModal = () => {
  dispatchAction(
    trackIntegrationEvent({
      integrationId: 'microsoft-entra-id',
      name: 'CLICKED_MANAGE_CONFIGURATION',
    })
  );
  startTransferConfigsModal();
};

const Import = ({
  activeIntegration: {
    integrationId,
    integrationPath,
    selectedTransferConfigId,
  },
  steps,
  schedule,
  schedules: { schedules },
  transferConfigs,
  isSavingDisabled,
  overlay,
  selectionState,
}: MicrosoftEntraIDProps) => {
  return (
    <PageBody
      padding={0}
      backgroundColor="bgDefault"
      headerContent={
        <FlowHeader
          title={getFlowTitle({
            selectedTransferConfigId,
            transferConfigs: transferConfigs.configs,
            prefix: microsoftEntraId.name,
            loadedScheduleId: schedule.loadedScheduleId,
            schedules,
          })}
          onSave={() => handleSaveSelectedConfig({ selectedTransferConfigId })}
          onSaveAs={handleSaveAs}
          canRestart={true}
          isSavingDisabled={isSavingDisabled}
          onRestart={handleRestart}
          onStartTransferConfigsModal={handleStartTransferConfigsModal}
          transferDirection={TransferDirection.IMPORT}
          disabledConfigurationActions={Boolean(schedule.loadedScheduleId)}
        />
      }
      footerContent={
        <StickyFooter
          integrationName={microsoftEntraId.name}
          isDataSelectionValid={isValidSelectionState(selectionState)}
          onDataSelectionNext={() =>
            dispatchAction(loadSelection(integrationId))
          }
        />
      }
    >
      <FlowContainer>
        {overlay.isLoading && (
          <WaitingOverlay>{overlay.message}</WaitingOverlay>
        )}
        <Flex $direction="column">
          <Stepper>
            {steps.map(step => (
              <Step
                key={step.step}
                onPress={step.onPress}
                isSelected={step.isSelected}
                heading={step.label}
                state={step.state}
                isDisabled={step.isDisabled}
              />
            ))}
          </Stepper>
        </Flex>

        {integrationPath === ImportRoute.SELECT_DATA && <SelectData />}
        {integrationPath === ImportRoute.CONFIGURE && <TabularConfigMapping />}
        {integrationPath === ImportRoute.REVIEW && <ReviewTest />}
        {integrationPath === ImportRoute.IMPORT_AND_SCHEDULE && (
          <ImportAndSchedule />
        )}
      </FlowContainer>
    </PageBody>
  );
};

const MicrosoftEntraId = (props: MicrosoftEntraIDProps) => {
  const integrationPath = props.activeIntegration.integrationPath as RoutePath;

  if (!integrationPath || isOverviewRoute(integrationPath)) {
    return (
      <Overview
        integrationPath={integrationPath}
        initialTab={OverviewRoute.CONNECTIONS}
      />
    );
  }

  return <Import {...props} />;
};

export default connect(MicrosoftEntraId, viewModel$);
