import { Dashboard, DashboardModes } from '@ardoq/dashboard';
import { getCurrentLocale } from '@ardoq/locale';
import { trackEvent } from '../../tracking/tracking';
import DashboardModuleContainer from '../DashboardModuleContainer';
import metricsDashboard$, {
  MetricsDashboard$State,
} from './usageMetricsDashboard$';
import { useEffectOnce } from '@ardoq/hooks';
import currentUser from '../../models/currentUser';
import { PersonalSetting } from '@ardoq/api-types';
import IconClickable from '../../atomicComponents/IconClickable';
import { colors, s24 } from '@ardoq/design-tokens';
import { IconName, PresentationIcon } from '@ardoq/icons';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { BrandButton, SecondaryButton } from '@ardoq/button';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { createDashboardSlide } from '../../presentationEditor/presentationChooser/actions';
import { METRICS_DSB_ID } from '../consts';
import { map } from 'rxjs';
import { hasFeature, Features } from '@ardoq/features';
import { DashboardModule } from '../types';

type UsageMetricsDashboardProps = MetricsDashboard$State & {
  hasNewJourneyFeature: boolean;
};

const UsageMetricsDashboard = ({
  dashboardData,
  apiError,
  hasNewJourneyFeature,
}: UsageMetricsDashboardProps) => {
  useEffectOnce(() => {
    if (
      !currentUser.getPersonalSetting(
        PersonalSetting.HAS_SEEN_USAGE_METRICS_DASHBOARD
      )
    ) {
      currentUser.setPersonalSetting(
        PersonalSetting.HAS_SEEN_USAGE_METRICS_DASHBOARD,
        true
      );
    }
  });

  const headerRightContent = hasNewJourneyFeature ? (
    <BrandButton
      onClick={() =>
        dispatchAction(
          createDashboardSlide({
            dashboardId: METRICS_DSB_ID,
            dashboardName: dashboardData?.name ?? '',
          })
        )
      }
    >
      <PresentationIcon /> Add to presentation
    </BrandButton>
  ) : (
    <SecondaryButton
      onClick={() =>
        dispatchAction(
          createDashboardSlide({
            dashboardId: METRICS_DSB_ID,
            dashboardName: dashboardData?.name ?? '',
          })
        )
      }
    >
      <PresentationIcon /> Add to presentation
    </SecondaryButton>
  );

  return (
    <DashboardModuleContainer
      title="Usage metrics"
      secondaryText="Preferences"
      dashboardModule={DashboardModule.USAGE_METRICS}
      HeaderLeftContent={
        <IconClickable
          color={colors.grey50}
          hoverColor={colors.grey35}
          iconName={IconName.KNOWLEDGE_BASE}
          tooltipText="Knowledge base"
          onClick={() => {
            window.open(
              KnowledgeBaseLink.USAGE_METRICS_DASHBOARD,
              '_blank noopener'
            );
          }}
          containerStyle={{
            height: s24,
          }}
        />
      }
      HeaderRightContent={headerRightContent}
      hasNewJourneyFeature={hasNewJourneyFeature}
    >
      <Dashboard
        dashboardMode={DashboardModes.METRICS}
        userLocale={getCurrentLocale()}
        isLoading={false}
        dashboardData={dashboardData}
        trackEvent={trackEvent}
        apiError={apiError}
        contextDashboardIsUsedIn="usage-metrics"
      />
    </DashboardModuleContainer>
  );
};

export default connect(
  UsageMetricsDashboard,
  metricsDashboard$.pipe(
    map(metricsDashboardData => ({
      ...metricsDashboardData,
      hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
    }))
  )
);
