import { Checkbox, FieldsWrapper, TextInput } from '@ardoq/forms';
import { trackEvent } from 'tracking/tracking';
import {
  SurveyEditorSectionProps,
  WithSetMiscAttributes,
} from './SurveyEditor';

const SurveyEditorResultDisplayOptionsSection = ({
  surveyAttributes,
  setMiscAttribute,
}: Omit<SurveyEditorSectionProps, 'setSurveyAttribute'> &
  WithSetMiscAttributes) => {
  return (
    <>
      <FieldsWrapper>
        <Checkbox
          label="Landing page"
          isChecked={!surveyAttributes.misc.disableLandingPage}
          onChange={() => {
            setMiscAttribute(
              'disableLandingPage',
              !surveyAttributes.misc.disableLandingPage
            );
            trackEvent('Survey builder: toggle enable landing page', {
              toggle: Boolean(surveyAttributes.misc.disableLandingPage),
            });
          }}
          popoverHelpContent={`If you want respondents to only see the list of 
          existing entries, you can disable the landing page. Component creation will 
          still be available through an input field you enable it above.`}
        >
          Enable survey landing page
        </Checkbox>
      </FieldsWrapper>
      {!surveyAttributes.misc.disableLandingPage && (
        <FieldsWrapper>
          <TextInput
            label="Label of 'Existing entries' button"
            type="text"
            placeholder="Existing entries"
            value={surveyAttributes.misc.overviewButtonText}
            onChange={event =>
              setMiscAttribute('overviewButtonText', event.target.value)
            }
            dataTestId="existing-entries-input"
          />
        </FieldsWrapper>
      )}
    </>
  );
};

export default SurveyEditorResultDisplayOptionsSection;
