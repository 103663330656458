import { Tab, TabsGroup, ViewSelectorBarWrapper } from '@ardoq/tabs';
import {
  MAIN_PANE_LEFT,
  MAIN_PANE_RIGHT,
  MetaInfoViewStatus,
  PaneLocation,
} from 'streams/views/mainContent/types';
import { ViewIds } from '@ardoq/api-types';
import {
  dispatchAction,
  connect,
  type ActionCreatorWithoutNamespace,
} from '@ardoq/rxbeach';
import {
  PayloadActiveTab,
  setActiveMainTabLeft,
  setActiveMainTabRight,
} from 'streams/views/mainContent/actions';
import { CloseIcon, IconName, SplitScreenIcon } from '@ardoq/icons';
import { closePane, showSelectViewsDialog, splitPane } from './actions';
import { getTabOptionsStreamWithLocation } from './getTabOptionsStreamWithLocation';
import { GhostButton } from '@ardoq/button';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import viewStatusBrands from 'views/viewStatusBrands';
import viewStatusLabels from 'views/viewStatusLabels';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import { VIEW_SUPPORTING_DIFF } from 'scope/consts';

type TabProps = {
  id: ViewIds;
  name: string;
  label: string;
  tabId: ViewIds;
  viewStatus: MetaInfoViewStatus | undefined;
  isActive?: boolean;
};

type TabBarOwnProps = {
  location: PaneLocation;
  isSplitPane: boolean;
  isRightEditorPaneOpen: boolean;
};
type TabBarStreamedProps = {
  tabs: TabProps[];
  action: ActionCreatorWithoutNamespace<PayloadActiveTab>;
  activeTabId: ViewIds;
  showManageViews: boolean;
};
type TabBarProps = TabBarOwnProps & TabBarStreamedProps;

const getStatusTypeByViewStatus = (viewStatus = MetaInfoViewStatus.NONE) => {
  if (viewStatus === MetaInfoViewStatus.NONE) return undefined;
  return viewStatusBrands[viewStatus];
};

const getStatusLabelByViewStatus = (viewStatus = MetaInfoViewStatus.NONE) => {
  if (viewStatus === MetaInfoViewStatus.NONE) return undefined;
  return viewStatusLabels[viewStatus];
};

const LOCATION_TO_EVENT_NAME_SPACE_LOOKUP: Record<PaneLocation, string> = {
  MAIN_PANE_LEFT: 'left visualization tabs',
  MAIN_PANE_RIGHT: 'right visualization tabs',
};

const TabBar = ({
  tabs,
  location,
  action,
  isSplitPane,
  activeTabId,
  isRightEditorPaneOpen,
  showManageViews,
}: TabBarProps) => {
  if (!(tabs && location)) return null;
  if (tabs.length === 0) return null;

  return (
    <ViewSelectorBarWrapper>
      <TabsGroup
        clickNamespace={LOCATION_TO_EVENT_NAME_SPACE_LOOKUP[location]}
        overflowType="dropdown"
        onTabChange={tabId => {
          dispatchAction(action({ activeTabId: tabId as ViewIds }));
        }}
        activeTabId={activeTabId}
        extraDropdownOptions={
          showManageViews
            ? [
                {
                  label: 'Manage views',
                  type: DropdownOptionType.BUTTON_OPTION,
                  onClick: () => {
                    dispatchAction(showSelectViewsDialog({ location }));
                  },
                },
              ]
            : undefined
        }
      >
        {tabs.map(({ viewStatus, ...props }, index) => (
          <Tab
            key={props.id}
            {...props}
            isActive={index === 0}
            statusType={getStatusTypeByViewStatus(viewStatus)}
            badgeTooltipText={getStatusLabelByViewStatus(viewStatus)}
            rightIconName={
              VIEW_SUPPORTING_DIFF.includes(props.id) && isScenarioMode()
                ? IconName.SCENARIO
                : undefined
            }
            clickId={props.tabId}
            rightIconPopoverMessage="This view supports all scenario features."
          />
        ))}
      </TabsGroup>
      {!isRightEditorPaneOpen &&
        (isSplitPane ? (
          <GhostButton
            onClick={() => dispatchAction(closePane({ location }))}
            data-tooltip-text="Close column"
          >
            <CloseIcon />
          </GhostButton>
        ) : (
          <GhostButton
            onClick={() => dispatchAction(splitPane())}
            data-tooltip-text="Open two-column view"
          >
            <SplitScreenIcon />
          </GhostButton>
        ))}
    </ViewSelectorBarWrapper>
  );
};

const TabBarLeft = connect(
  TabBar,
  getTabOptionsStreamWithLocation(MAIN_PANE_LEFT)
);

const TabBarRight = connect(
  TabBar,
  getTabOptionsStreamWithLocation(MAIN_PANE_RIGHT)
);

const ViewPaneTabBar = ({
  location,
  isSplitPane,
  isRightEditorPaneOpen,
}: TabBarOwnProps) =>
  location === MAIN_PANE_LEFT ? (
    <TabBarLeft
      location={MAIN_PANE_LEFT}
      action={setActiveMainTabLeft}
      isSplitPane={isSplitPane}
      isRightEditorPaneOpen={isRightEditorPaneOpen}
    />
  ) : (
    <TabBarRight
      location={MAIN_PANE_RIGHT}
      action={setActiveMainTabRight}
      isSplitPane={isSplitPane}
      isRightEditorPaneOpen={isRightEditorPaneOpen}
    />
  );
export default ViewPaneTabBar;
