import { ArdoqId, PersistedIPAllowlistEntry } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { getActionNamespacer } from 'streams/utils/streamUtils';
import {
  CreateIPAllowlistEntryProps,
  IPAllowlistingStreamShape,
  IPAllowlistingTab,
} from '../types';

const name = getActionNamespacer('ipAllowlisting');

export const setFetchedIPEntries = actionCreator<PersistedIPAllowlistEntry[]>(
  name('Set fetched entries')
);

export const addIPEntryToState = actionCreator<PersistedIPAllowlistEntry>(
  name('Add entry to stream state')
);

export const removeIPEntryFromState = actionCreator<ArdoqId>(
  name('Remove entry from stream state')
);

export const fetchIPEntries = actionCreator(name('Fetch entries'));

export const setIPAllowlistAlert = actionCreator<
  IPAllowlistingStreamShape['alert']
>(name('Set alert'));

export const createIPEntry = actionCreator<CreateIPAllowlistEntryProps>(
  name('Create entry')
);

export const deleteIPEntry = actionCreator<ArdoqId>(name('Delete entry'));

export const updateIPEntry = actionCreator<PersistedIPAllowlistEntry>(
  name('Update entry')
);

export const updateEntryInState = actionCreator<PersistedIPAllowlistEntry>(
  name('Update entry in state')
);

export const setIPAllowlistTab = actionCreator<IPAllowlistingTab>(
  name('Set tab')
);
