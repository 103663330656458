import { ArdoqId, AssetType, AssetFolder, Workspace } from '@ardoq/api-types';

const isRootEntity = (id: string, type: string, folders: AssetFolder[]) =>
  !folders.some(folder => {
    return (
      folder.content &&
      folder.content.some(item => item._id === id && item.type === type)
    );
  });

const folderContainsSubFoldersOrWorkspace = (folder: AssetFolder) =>
  folder.content &&
  folder.content.length > 0 &&
  folder.content.some(
    item => item.type === AssetType.FOLDER || item.type === AssetType.WORKSPACE
  );

const populateFolder = (
  folder: AssetFolder,
  parents: string[],
  parentsByWorkspaceId: Map<ArdoqId, string[]>,
  workspacesById: Map<ArdoqId, Workspace>,
  foldersById: Map<ArdoqId, AssetFolder>
): Map<ArdoqId, string[]> => {
  return folder.content.reduce((acc, item) => {
    switch (item.type) {
      case AssetType.WORKSPACE:
        acc.set(item._id, parents);
        return acc;
      case AssetType.FOLDER:
        if (!foldersById.has(item._id)) return acc;

        return populateFolder(
          foldersById.get(item._id)!,
          [...parents, foldersById.get(item._id)!.name],
          parentsByWorkspaceId,
          workspacesById,
          foldersById
        );

      default:
        return acc;
    }
  }, parentsByWorkspaceId);
};

/**
 * @deprecated Use `getAssetPathName` instead. (This function is slow and should be removed after testing).
 */
export const getParentsByWorkspaceId = (
  workspaces: Workspace[],
  folders: AssetFolder[]
): Map<ArdoqId, string[]> => {
  const workspacesById = new Map(workspaces.map(ws => [ws._id, ws]));
  const foldersById = new Map(folders.map(f => [f._id, f]));

  const parentsByWorkspaceId = new Map<ArdoqId, string[]>();

  const rootFolders = folders
    .filter(folder => {
      return (
        isRootEntity(folder._id, AssetType.FOLDER, folders) &&
        folderContainsSubFoldersOrWorkspace(folder)
      );
    })
    .reduce((acc, folder) => {
      populateFolder(
        folder,
        [folder.name],
        acc,
        workspacesById,
        foldersById
      ).forEach(function (value, key) {
        acc.set(key, value);
      });
      return acc;
    }, parentsByWorkspaceId);

  return rootFolders;
};

export const getAssetPathName = (
  assetFolders: AssetFolder[],
  resourceId: ArdoqId
) => {
  const relatedFolders = assetFolders.filter(folder =>
    folder.nestedContent.some(item => item._id === resourceId)
  );
  return constructPath(resourceId, relatedFolders);
};

const constructPath = (
  resourceId: ArdoqId,
  folders: AssetFolder[]
): string[] => {
  const directParent = folders.find(folder =>
    folder.content.some(item => item._id === resourceId)
  );
  if (!directParent) {
    return [];
  }
  return [...constructPath(directParent._id, folders), directParent.name];
};
