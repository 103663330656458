import { actionCreator } from '@ardoq/rxbeach';
import { AsyncStatus } from 'integrations/common/types/api';
import { ResourceTypesSummary } from '@ardoq/api-types/integrations';

const NAMESPACE = '[integrations cloud-providers] RESOURCES_SUMMARY';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export type FetchResourcesSummaryPayload = {
  connectionId: string;
};

export const fetchResourcesSummary =
  actionCreator<FetchResourcesSummaryPayload>(
    getActionName('FETCH_RESOURCES_SUMMARY')
  );

export type FetchResourcesSummarySuccessPayload = {
  resourcesSummary: ResourceTypesSummary[];
};

export const fetchResourcesSummarySuccess =
  actionCreator<FetchResourcesSummarySuccessPayload>(
    getActionName('FETCH_RESOURCES_SUMMARY_SUCCESS')
  );

export type SetAsyncStatusPayload = {
  asyncStatus: AsyncStatus;
};
export const setAsyncStatus = actionCreator<SetAsyncStatusPayload>(
  getActionName('SET_RESOURCES_SUMMARY_ASYNC_STATUS')
);
