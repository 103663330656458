import { UserAssetPermission } from '@ardoq/api-types';
import {
  UserAssetsOverviewQuery,
  UserAssetsOverviewState,
} from './userAssetsOverviewTypes';
import { userAssetPermissionOperations } from '../UserRoleAndGroupsDetails/UserAssetsOverviewTab/userAssetPermissionOperations';
import { userAssetsOverviewQueryOperations } from './userAssetsOverviewQueryOperations';

const emptyState = (): UserAssetsOverviewState => ({
  assets: [],
  loading: false,
});

const makingARequest = (
  state: UserAssetsOverviewState
): UserAssetsOverviewState => ({
  ...state,
  loading: true,
});

const requestSuccess = (
  state: UserAssetsOverviewState,
  assets: UserAssetPermission[]
): UserAssetsOverviewState => ({
  ...state,
  assets: assets.sort(userAssetPermissionOperations.sortByFolderPath),
  loading: false,
});

const requestFailure = (
  state: UserAssetsOverviewState,
  error: string
): UserAssetsOverviewState => ({
  ...state,
  error,
  loading: false,
});

const filterLocally = (
  state: UserAssetsOverviewState,
  queryObject: UserAssetsOverviewQuery
): UserAssetPermission[] => {
  const searchString =
    userAssetsOverviewQueryOperations.getSearchString(queryObject);
  if (!searchString) return state.assets;
  return state.assets.filter(
    asset =>
      userAssetPermissionOperations.assetNameContainsSearchString(
        asset,
        searchString
      ) ||
      userAssetPermissionOperations.folderPathContainsSearchString(
        asset,
        searchString
      )
  );
};

export const userAssetsOverviewOperations = {
  emptyState,
  requestSuccess,
  requestFailure,
  makingARequest,
  filterLocally,
};
