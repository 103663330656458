import { MetaModelComponentType, Zone } from '@ardoq/api-types';
import { ZoneCommands } from '../types';
import { FormSize } from '@ardoq/forms';
import { InfoNotification } from '@ardoq/status-ui';
import {
  Button,
  ButtonGroup,
  ButtonSize,
  ButtonType,
  GhostButton,
} from '@ardoq/button';
import { GlobalFieldsByName } from 'streams/fields/globalFields$';
import { ComponentTypesTable } from './ComponentTypesTable';
import { FlexBox, Stack } from '@ardoq/layout';
import { ComponentTypeFieldsGroupSearch } from './ComponentTypeFieldsGroupSearch';
import { ComponentTypeFieldsGroupSearchParams } from '../zonesViewState$/types';

export const ComponentTypeFieldsGroup = ({
  zone,
  componentTypes,
  globalFieldsByName,
  componentTypeFieldsGroupSearch,
  commands,
}: {
  zone: Zone;
  componentTypes: MetaModelComponentType[];
  globalFieldsByName: GlobalFieldsByName;
  componentTypeFieldsGroupSearch: ComponentTypeFieldsGroupSearchParams;
  commands: ZoneCommands;
}) => (
  <Stack gap="medium">
    {componentTypes.length > 0 || !!componentTypeFieldsGroupSearch.searchKey ? (
      <>
        <FlexBox justify="space-between">
          <ComponentTypeFieldsGroupSearch
            searchKey={componentTypeFieldsGroupSearch.searchKey}
            searchBy={componentTypeFieldsGroupSearch.searchBy}
            commands={commands}
          />
          <FlexBox gap="small">
            <GhostButton
              onClick={() =>
                commands.selectAll(
                  zone,
                  componentTypes,
                  componentTypeFieldsGroupSearch
                )
              }
            >
              Select all
            </GhostButton>
            <GhostButton
              onClick={() =>
                commands.deselectAll(
                  zone,
                  componentTypes,
                  componentTypeFieldsGroupSearch
                )
              }
            >
              Deselect all
            </GhostButton>
          </FlexBox>
        </FlexBox>
        <ComponentTypesTable
          zone={zone}
          componentTypes={componentTypes}
          globalFieldsByName={globalFieldsByName}
          componentTypeFieldsGroupSearch={componentTypeFieldsGroupSearch}
          commands={commands}
        />
      </>
    ) : (
      <InfoNotification maxWidth={FormSize.DEFAULT}>
        Select one or more workspaces under select data step to specify field
        level permissions.
        <ButtonGroup>
          <Button
            buttonSize={ButtonSize.SMALL}
            buttonType={ButtonType.SECONDARY}
            onClick={() => commands.goToSelectDataStep()}
          >
            Select data
          </Button>
        </ButtonGroup>
      </InfoNotification>
    )}
  </Stack>
);
