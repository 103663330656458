import { catchError, combineLatest, of, switchMap, tap } from 'rxjs';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  handleApiError,
  retriableRequest,
} from 'integrations/common/utils/api';
import {
  fetchResourceTypes,
  fetchResourceTypesFailure,
  fetchResourceTypesSuccess,
} from './actions';
import { cloudProviderApi } from '@ardoq/api';
import { INTEGRATION_ID_TO_CLOUD_PROVIDER } from 'integrations/cloudProviders/constants';

const handleFetchResourcesList = routine(
  ofType(fetchResourceTypes),
  extractPayload(),
  switchMap(({ integrationId }) => {
    return combineLatest([
      of(integrationId),
      retriableRequest(() =>
        cloudProviderApi.fetchResourceTypes(
          INTEGRATION_ID_TO_CLOUD_PROVIDER[integrationId]
        )
      ).pipe(
        catchError(error => {
          dispatchAction(
            fetchResourceTypesFailure({ integrationId: integrationId })
          );
          return handleApiError(error);
        })
      ),
    ]);
  }),
  tap(([integrationId, resourceTypes]) => {
    dispatchAction(fetchResourceTypesSuccess({ integrationId, resourceTypes }));
  }),
  catchError((_, stream$) => {
    // TODO: throw an error instead of returning the stream. Currently, we don't throw the error because this breaks the stream and stops the parallel routines.
    return stream$;
  })
);

export default [handleFetchResourcesList];
