import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { confirm, ModalSize } from '@ardoq/modal';
import {
  applyColumnFilter,
  applyColumnMapping,
  applyTableMapping,
  applyTabularMapping,
  fillHierarchyLevel,
  setColumnFilter,
  setColumnMapping,
  changeComponentColumnMapping,
  setTableMappingType,
  removeColumnMappings,
  unsetColumnMappings,
  removeTargetWorkspace,
  selectMappingConfig,
  unselectMappingConfig,
  clearTabularMapping,
  recalculateFilterColumnsIndexes,
  applyRequiredTableMapping,
  applyRequiredColumnsMapping,
  setColumnTransformations,
  applyColumnTransformations,
  removeColumnTransformations,
} from './actions';
import {
  adjustTabularMapping,
  fillHierarchyLevelGap,
  getColumnMappingByHierararchyLevel,
  isComponentMapping,
  isEmptyTableMappingMap,
  isEmptyTabularMapping,
  recalculateFiltersIndexes,
  transferConfigToTabularMapping,
} from './utils';
import { tablePreviews$ } from '../tablePreviews/getTablePreviewsStream';
import {
  setTransferConfigId,
  setMappingConfigId,
} from 'integrations/common/streams/activeIntegrations/actions';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { tabularMappings$ } from './getTabularMappingStream';
import { getDefaultColumnMapping } from './defaultColumnMappings';
import { isNil, mergeAll, pick, set } from 'lodash/fp';
import { TableMappingType } from '@ardoq/api-types/integrations';
import {
  AllColumnMappingProps,
  ColumnMapping,
} from 'integrations/common/streams/transferConfigs/types';
import { TablePreview } from '../tablePreviews/types';
import { IntegrationId, TableId } from './types';
import { fields$ } from '../fields/fields$';
import { workspaces$ } from '../workspaces/workspaces$';
import { TrackingMetadata } from 'integrations/common/tracking/events';
import { transferState$ } from 'integrations/common/streams/transferState/getTransferStateStream';
import { isResourceCreationDisabled } from '../transferState/utils';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { ExportRoute, ImportRoute } from 'integrations/common/navigation/types';
import { getCurrentTransferConfig } from 'integrations/common/streams/transferConfigs/utils';
import { applyTabularMappingsDefault } from 'integrations/common/streams/tabularMappingsDefault/actions';

const handleSelectMapping = routine(
  ofType(selectMappingConfig),
  extractPayload(),
  withLatestFrom(tablePreviews$, fields$, workspaces$, activeIntegrations$),
  tap(
    ([
      { integrationId, config },
      tablePreviews,
      { all: allFields },
      { existing: allWorkspaces },
      activeIntegrations,
    ]) => {
      if (integrationId === 'excel-v3') {
        dispatchAction(setTransferConfigId({ integrationId, id: config._id }));
      }

      const tabularMapping = transferConfigToTabularMapping({
        transferConfig: config,
        tablePreviews: tablePreviews[integrationId],
        allFields,
        allWorkspaces,
        mapTablesBy:
          activeIntegrations[integrationId].integrationMappingParams
            .mapTablesBy,
        mapColumnsBy:
          activeIntegrations[integrationId].integrationMappingParams
            .mapColumnsBy,
      });

      dispatchAction(setMappingConfigId({ integrationId, id: config._id }));

      dispatchAction(
        applyTabularMapping({
          integrationId,
          mapping: adjustTabularMapping({
            tabularMapping,
            tablePreviews: tablePreviews[integrationId],
          }),
        })
      );

      dispatchAction(applyRequiredTableMapping({ integrationId }));
      dispatchAction(applyRequiredColumnsMapping({ integrationId }));

      dispatchAction(
        trackIntegrationEvent({
          integrationId,
          name: 'LOADED_CONFIG',
          metadata: {
            id: config._id,
          },
        })
      );
    }
  )
);

const handleUnselectMappingConfig = routine(
  ofType(unselectMappingConfig),
  extractPayload(),
  tap(({ integrationId }) => {
    if (integrationId === 'excel-v3') {
      dispatchAction(setTransferConfigId({ integrationId, id: null }));
    }

    dispatchAction(setMappingConfigId({ integrationId, id: null }));
    dispatchAction(clearTabularMapping({ integrationId }));
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'CLEARED_CONFIG',
      })
    );

    dispatchAction(applyRequiredTableMapping({ integrationId }));
  })
);

const handleSetTableMappingType = routine(
  ofType(setTableMappingType),
  extractPayload(),
  withLatestFrom(tablePreviews$, tabularMappings$),
  tap(
    ([
      { integrationId, tableId, tableType },
      tablePreviews,
      tabularMappings,
    ]) => {
      const tablePreview = tablePreviews[integrationId][tableId];
      const tableMapping = tabularMappings[integrationId][tableId];
      if (!tablePreview) return;

      const isFirstChange = !tableMapping || !tableMapping.rowRepresentation;
      const isTypeChanged = tableMapping?.rowRepresentation !== tableType;

      if (!isTypeChanged) {
        return;
      }

      if (isFirstChange || isEmptyTableMappingMap(tableMapping)) {
        dispatchChangeMappingActions(
          integrationId,
          tableId,
          tablePreview,
          tableType
        );
        return;
      }

      confirm({
        title: 'Change your worksheet rows?',
        subtitle: `Changing your worksheet rows will clear your existing configuration for this worksheet`,
        modalSize: ModalSize.XS,
        confirmButtonTitle: 'Change',
        cancelButtonTitle: 'Cancel',
        onConfirmAsync: () => {
          dispatchChangeMappingActions(
            integrationId,
            tableId,
            tablePreview,
            tableType
          );
        },
      });
    }
  )
);

const dispatchChangeMappingActions = (
  integrationId: IntegrationId,
  tableId: TableId,
  tablePreview: TablePreview,
  tableType: TableMappingType
) => {
  dispatchAction(removeTargetWorkspace({ integrationId, tableId }));

  dispatchAction(
    applyTableMapping({
      integrationId,
      tableId,
      tableMapping: {
        name: tablePreview.name,
        index: tablePreview.index,
        rowRepresentation: tableType,
        // when changing the table type, we reset the columns
        mappedColumns: {},
      },
    })
  );

  dispatchAction(
    trackIntegrationEvent({
      integrationId,
      name: 'SELECTED_WORKSHEET_TYPE',
      metadata: {
        workspaceType: tableType,
        tableIndex: tablePreview.index,
      },
    })
  );
};

const handleSetColumnTransformations = routine(
  ofType(setColumnTransformations),
  extractPayload(),
  withLatestFrom(activeIntegrations$),
  tap(
    ([{ transformationColumn, index, integrationId }, activeIntegrations]) => {
      const currentTableId = activeIntegrations[integrationId].currentTableId;
      if (!currentTableId) {
        return;
      }

      if (
        !transformationColumn ||
        transformationColumn.transformations.length === 0
      ) {
        return dispatchAction(
          removeColumnTransformations({
            integrationId,
            tableId: currentTableId,
            index,
          })
        );
      }

      dispatchAction(
        applyColumnTransformations({
          integrationId,
          tableId: currentTableId,
          index,
          transformationColumn,
        })
      );
    }
  )
);

const handleSetColumnFilter = routine(
  ofType(setColumnFilter),
  extractPayload(),
  withLatestFrom(activeIntegrations$),
  tap(([{ integrationId, filters, columnIndex }, activeIntegrations]) => {
    const currentTableId = activeIntegrations[integrationId].currentTableId;
    if (!currentTableId) {
      return;
    }

    dispatchAction(
      applyColumnFilter({
        integrationId,
        tableId: currentTableId,
        columnIndex,
        filters,
      })
    );
  })
);

const handleSetColumnMapping = routine(
  ofType(setColumnMapping),
  extractPayload(),
  withLatestFrom(
    activeIntegrations$,
    tabularMappings$,
    fields$,
    tablePreviews$,
    transferState$
  ),
  tap(
    ([
      { integrationId, columnName, columnIndex, columnMapping },
      activeIntegrations,
      tabularMappings,
      { all: allFields },
      tablePreviews,
      transferState,
    ]) => {
      const currentTableId = activeIntegrations[integrationId].currentTableId;
      if (!currentTableId) {
        return;
      }

      const currentTableMapping =
        tabularMappings[integrationId][currentTableId];
      const currentColumnMapping =
        currentTableMapping.mappedColumns[columnIndex];

      const defaultMapping = getDefaultColumnMapping({
        columnType:
          columnMapping.columnType || currentColumnMapping?.columnType,
        columnName,
        tableMappingMap: currentTableMapping,
        allFields,
        isCreationDisabled: isResourceCreationDisabled(
          transferState[integrationId]
        ),
      });

      const isColumnTypeChange =
        !isNil(columnMapping?.columnType) &&
        currentColumnMapping?.columnType !== columnMapping.columnType;

      const sourceFieldNames =
        tablePreviews[integrationId][currentTableId]?.sourceFieldNames ?? [];

      const sourceFieldName = sourceFieldNames[columnIndex];

      // merging in-order
      // new values will always take priority
      const newColumnMapping: ColumnMapping = mergeAll([
        defaultMapping,
        isColumnTypeChange ? {} : currentColumnMapping,
        { index: columnIndex },
        sourceFieldName ? { sourceFieldName } : {},
        columnMapping,
      ]);

      // handling component type columns separatly
      if (currentColumnMapping && isComponentMapping(currentColumnMapping)) {
        dispatchAction(
          changeComponentColumnMapping({
            integrationId,
            tableId: currentTableId,
            columnName,
            columnIndex,
            previousMapping: currentColumnMapping,
            nextMapping: newColumnMapping,
          })
        );
        return;
      }

      dispatchAction(
        applyColumnMapping({
          integrationId,
          tableId: currentTableId,
          columnIndex,
          columnMapping: newColumnMapping,
        })
      );
    }
  )
);

const handleUnsetColumnsMapping = routine(
  ofType(unsetColumnMappings),
  extractPayload(),
  withLatestFrom(activeIntegrations$, tabularMappings$),
  tap(
    ([
      { integrationId, columnIndexes },
      activeIntegrations,
      tabularMappings,
    ]) => {
      const currentTableId = activeIntegrations[integrationId].currentTableId;
      if (!currentTableId) {
        return;
      }
      const currentTableMapping =
        tabularMappings[integrationId][currentTableId];

      dispatchAction(
        removeColumnMappings({
          integrationId,
          tableId: currentTableId,
          columnIndexes,
        })
      );

      columnIndexes.forEach(columnIndex => {
        const currentColumnMapping =
          currentTableMapping.mappedColumns[columnIndex];

        // component type column book-keeping
        if (isComponentMapping(currentColumnMapping)) {
          dispatchAction(
            fillHierarchyLevel({
              integrationId,
              hierarchyLevel: currentColumnMapping.hierarchyLevel,
              tableId: currentTableId,
            })
          );
        }
      });
    }
  )
);

const handleChangeComponentColumnMapping = routine(
  ofType(changeComponentColumnMapping),
  extractPayload(),
  withLatestFrom(tabularMappings$),
  tap(
    ([
      { integrationId, tableId, columnIndex, previousMapping, nextMapping },
      tabularMappings,
    ]) => {
      const tableMapping = tabularMappings[integrationId][tableId];

      if (isComponentMapping(nextMapping)) {
        if (nextMapping.hierarchyLevel !== previousMapping.hierarchyLevel) {
          const swappedColumn = getColumnMappingByHierararchyLevel(
            tableMapping,
            nextMapping.hierarchyLevel
          );
          if (swappedColumn) {
            dispatchAction(
              applyTableMapping({
                tableId,
                integrationId,
                tableMapping: set(
                  ['mappedColumns', swappedColumn.index, 'hierarchyLevel'],
                  previousMapping.hierarchyLevel,
                  tableMapping
                ),
              })
            );
          }
        }
        dispatchAction(
          applyColumnMapping({
            integrationId,
            tableId,
            columnIndex,
            columnMapping: nextMapping,
          })
        );
      } else {
        dispatchAction(
          applyColumnMapping({
            integrationId,
            tableId,
            columnIndex,
            columnMapping: nextMapping,
          })
        );
        dispatchAction(
          fillHierarchyLevel({
            integrationId,
            hierarchyLevel: previousMapping.hierarchyLevel,
            tableId,
          })
        );
      }
    }
  )
);

const handleFillHierarchyLevel = routine(
  ofType(fillHierarchyLevel),
  extractPayload(),
  withLatestFrom(tabularMappings$),
  tap(([{ integrationId, hierarchyLevel, tableId }, tabularMappings]) => {
    const tableMapping = tabularMappings[integrationId][tableId];
    dispatchAction(
      applyTableMapping({
        tableId,
        integrationId,
        tableMapping: fillHierarchyLevelGap(tableMapping, hierarchyLevel),
      })
    );
  })
);

const handleApplyColumnMapping = routine(
  ofType(applyColumnMapping),
  extractPayload(),
  tap(({ integrationId, columnMapping }) => {
    const props = pick(
      /**
       * IMPORTANT: ensure to pick only the props without customer data
       */
      [
        'columnType',
        'fieldType',
        'hierarchyLevel',
        'referenceFormat',
        'referenceItemSeparator',
        'parentFormat',
        'missingComponents',
        'referenceDirection',
        'duplicates',
      ] satisfies Array<AllColumnMappingProps>,
      columnMapping
    ) as TrackingMetadata;
    // explicit casting for simplicity of union agnostic property picking

    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'APPLIED_COLUMN_MAPPING',
        metadata: props,
      })
    );
  })
);

const handleRecalculateFilterColumnsIndexes = routine(
  ofType(recalculateFilterColumnsIndexes),
  extractPayload(),
  tap(({ integrationId, oldTablePreviews, newTablePreviews, mapping }) => {
    const isRecalculateFilterIndexesRequired =
      oldTablePreviews.length &&
      Object.values(mapping).some(
        table => Object.values(table.columnFilters).length
      );

    if (isRecalculateFilterIndexesRequired) {
      const recalculatedTabularMapping = recalculateFiltersIndexes(
        oldTablePreviews,
        newTablePreviews,
        mapping
      );

      dispatchAction(
        applyTabularMapping({
          integrationId,
          mapping: recalculatedTabularMapping,
        })
      );
    }
  })
);

const handleNavigateToConfigureWithSelectedConfig = routine(
  ofType(navigateToPath),
  extractPayload(),
  withLatestFrom(
    activeIntegrations$,
    tablePreviews$,
    transferState$,
    transferConfigs$,
    tabularMappings$,
    fields$,
    workspaces$
  ),
  map(
    ([
      { path, integrationId },
      activeIntegrations,
      tablePreviews,
      transferState,
      transferConfigs,
      tabularMappings,
      fields,
      workspaces,
    ]) => ({
      path,
      integrationId,
      transferConfigs,
      fields,
      workspaces,
      activeIntegration: activeIntegrations[integrationId],
      tablePreviews: tablePreviews[integrationId],
      transferState: transferState[integrationId],
      tabularMapping: tabularMappings[integrationId],
    })
  ),
  // We need to override the configuration if customer moves from the Select Data step
  // to Configure page with preselected config and an empty tabularMapping
  filter(
    ({
      path,
      integrationId,
      activeIntegration: { selectedTransferConfigId, integrationPath },
      transferState,
      tabularMapping,
    }) =>
      [
        Boolean(selectedTransferConfigId),
        transferState.isSelectionDataFetched,
        isEmptyTabularMapping(tabularMapping),
        integrationPath === ImportRoute.SELECT_DATA ||
          integrationPath === ExportRoute.SELECT_DATA,
        path === ImportRoute.CONFIGURE || path === ExportRoute.CONFIGURE,
        integrationId !== 'excel-v3',
      ].every(Boolean)
  ),
  tap(
    ({
      integrationId,
      activeIntegration,
      fields: { all: allFields },
      workspaces: { existing: allWorkspaces },
      tablePreviews,
      transferConfigs,
    }) => {
      const currentTransferConfig = getCurrentTransferConfig(
        transferConfigs.configs,
        activeIntegration
      );

      const tabularMapping = transferConfigToTabularMapping({
        transferConfig: currentTransferConfig,
        tablePreviews,
        allFields,
        allWorkspaces,
        mapTablesBy: activeIntegration.integrationMappingParams.mapTablesBy,
        mapColumnsBy: activeIntegration.integrationMappingParams.mapColumnsBy,
      });

      dispatchAction(
        applyTabularMapping({
          integrationId,
          mapping: adjustTabularMapping({
            tabularMapping,
            tablePreviews,
          }),
        })
      );
      dispatchAction(applyTabularMappingsDefault({ integrationId }));
      dispatchAction(applyRequiredTableMapping({ integrationId }));
    }
  )
);

export default [
  handleSetTableMappingType,
  handleSetColumnTransformations,
  handleSetColumnFilter,
  handleSetColumnMapping,
  handleUnsetColumnsMapping,
  handleChangeComponentColumnMapping,
  handleFillHierarchyLevel,
  handleApplyColumnMapping,
  handleSelectMapping,
  handleUnselectMappingConfig,
  handleRecalculateFilterColumnsIndexes,
  handleNavigateToConfigureWithSelectedConfig,
];
