import { TableId } from 'integrations/common/streams/tabularMappings/types';
import fp from 'lodash/fp';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { ServiceNowField } from '@ardoq/api-types/integrations';
import {
  initTables,
  resetTableFields,
  setMultipleTablesFields,
  setRequestStatus,
  SetRequestStatusPaylod,
  setTableFields,
  SetTableFieldsPayload,
} from './actions';
import { TablesFields } from './types';

const initialState: TablesFields = {};

const initTablesReducer = (_: TablesFields, tableIds: TableId[]) =>
  fp.fromPairs(
    fp.map(
      (id: TableId) => [id, { requestStatus: 'INIT', fields: {} }],
      tableIds
    )
  );
const handleInitTables = reducer<TablesFields, TableId[]>(
  initTables,
  initTablesReducer
);

const setTableFieldsReducer = (
  state: TablesFields,
  { tableId, fields }: { tableId: TableId; fields: ServiceNowField[] }
): TablesFields => {
  const byName = fp.fromPairs(
    fp.map((f: ServiceNowField) => [f.name, f], fields)
  );
  return { ...state, [tableId]: { requestStatus: 'SUCCESS', fields: byName } };
};
const handleSetTableFields = reducer<TablesFields, SetTableFieldsPayload>(
  setTableFields,
  setTableFieldsReducer
);

const setMultipleTablesFieldsReducer = (
  state: TablesFields,
  tablesFields: SetTableFieldsPayload[]
) => {
  return fp.merge(
    fp.reduce(
      (acc, { tableId, fields }) => ({
        ...acc,
        [tableId]: {
          requestStatus: 'SUCCESS',
          fields: fp.fromPairs(
            fp.map((f: ServiceNowField) => [f.name, f], fields)
          ),
        },
      }),
      {},
      tablesFields
    ),
    state
  );
};
const handleSetMultipleTablesFields = reducer<
  TablesFields,
  SetTableFieldsPayload[]
>(setMultipleTablesFields, setMultipleTablesFieldsReducer);

const setRequestStatusReducer = (
  state: TablesFields,
  { tableId, status }: SetRequestStatusPaylod
) => fp.set([tableId, 'requestStatus'], status, state);
const handleSetRequestStatus = reducer<TablesFields, SetRequestStatusPaylod>(
  setRequestStatus,
  setRequestStatusReducer
);

const resetTableFieldsReducer = () => initialState;
const handleResetTableSelection = reducer<TablesFields, void>(
  resetTableFields,
  resetTableFieldsReducer
);

const reducers = [
  handleSetTableFields,
  handleSetMultipleTablesFields,
  handleSetRequestStatus,
  handleInitTables,
  handleResetTableSelection,
];

export const tablesFields$ = persistentReducedStream(
  `tablesFields$`,
  initialState,
  reducers
);
