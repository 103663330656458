import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId } from '@ardoq/api-types';
import {
  SavedTransferConfig,
  TransferConfig,
} from '@ardoq/api-types/integrations';
import { actionCreatorWithIntegrationId } from 'integrations/common/utils/actionCreatorWithIntegrationId';

const NAMESPACE = '[integrations] TRANSFER_CONFIGS';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const fetchTransferConfigs = actionCreator(
  getActionName('FETCH_TRANSFER_CONFIGS')
);

export type FetchTransferConfigsSuccessPayload = SavedTransferConfig[];
export const fetchTransferConfigsSuccess =
  actionCreator<FetchTransferConfigsSuccessPayload>(
    getActionName('FETCH_TRANSFER_CONFIGS_SUCCESS')
  );

export const fetchTransferConfigsFailure = actionCreator(
  getActionName('FETCH_TRANSFER_CONFIGS_FAILURE')
);

export const setEmbeddedConfig = actionCreatorWithIntegrationId<{
  config: TransferConfig;
}>(getActionName('SET_EMBEDDED_CONFIG'));

// configurations lifecycle
export const saveConfiguration = actionCreatorWithIntegrationId<{
  name: string;
  isNewConfig: boolean;
}>(getActionName('SAVE_CONFIGURATION'));

export const createTransferConfig = actionCreatorWithIntegrationId<{
  config: TransferConfig;
  setAsSelectedTransferConfig?: boolean;
}>(getActionName('CREATE_TRANSFER_CONFIG'));

export const updateTransferConfig = actionCreatorWithIntegrationId<{
  config: SavedTransferConfig;
}>(getActionName('UPDATE_TRANSFER_CONFIG'));

export const saveActiveConfiguration = actionCreatorWithIntegrationId(
  getActionName('SAVE_ACTIVE_CONFIGURATION')
);

type SaveConfigurationsSuccessPayload = {
  id?: SavedTransferConfig['_id'];
  setAsSelectedTransferConfig?: boolean;
};
export const saveConfigurationsSuccess =
  actionCreatorWithIntegrationId<SaveConfigurationsSuccessPayload>(
    getActionName('SAVE_CONFIGURATIONS_SUCCESS')
  );

export const saveConfigurationsFailure = actionCreator<string>(
  getActionName('SAVE_CONFIGURATIONS_FAILURE')
);

export const bulkDeleteConfigurations = actionCreator<{
  configs: SavedTransferConfig[];
}>(getActionName('DELETE_CONFIGURATIONS'));

export const deleteConfigurations = actionCreator<{
  ids: ArdoqId[];
}>(getActionName('DELETE_CONFIGURATION'));

export const deleteConfigurationSuccess = actionCreator(
  getActionName('DELETE_CONFIGURATION_SUCCESS')
);

export const deleteConfigurationFailure = actionCreator(
  getActionName('DELETE_CONFIGURATION_FAILURE')
);

export const renameConfiguration = actionCreatorWithIntegrationId<{
  name: string;
  config: SavedTransferConfig;
}>(getActionName('RENAME_CONFIGURATION'));

export const cloneConfiguration = actionCreatorWithIntegrationId<{
  name: string;
  config: SavedTransferConfig;
}>(getActionName('CLONE_CONFIGURATION'));

export const interposeSaveReminder = actionCreatorWithIntegrationId<{
  onDone: VoidFunction;
}>(getActionName('INTERPOSE_SAVE_REMINDER'));

type LoadTransferConfigPayload = {
  transferConfig: SavedTransferConfig;
};
export const loadTransferConfig =
  actionCreatorWithIntegrationId<LoadTransferConfigPayload>(
    getActionName('LOAD_TRANSFER_CONFIG')
  );

export const resetTransferConfigMessage = actionCreator(
  getActionName('RESET_TRANSFER_CONFIG_ERROR_MESSAGE')
);

export const setSelectedConfigIds = actionCreator<string[]>(
  getActionName('SET_SELECTED_CONFIG_IDS')
);
