import { UNIFIED_INTEGRATION_IDS } from 'integrations/unified/constants';
import { UnifiedIntegrationId } from 'integrations/unified/types';
import fp from 'lodash/fp';
import { ObservableState } from '@ardoq/rxbeach';
import { Observable, distinctUntilKeyChanged, map } from 'rxjs';

export function buildInitialStreamState<T>(
  initialState: T
): Record<UnifiedIntegrationId, T> {
  return fp.reduce(
    (acc, id) => ({ ...acc, [id]: initialState }),
    {},
    UNIFIED_INTEGRATION_IDS
  ) as Record<UnifiedIntegrationId, T>;
}
export function getInstanceStream<T>(
  stream: ObservableState<Record<UnifiedIntegrationId, T>>,
  instanceId: UnifiedIntegrationId
): Observable<T> {
  return stream.pipe(
    distinctUntilKeyChanged(instanceId),
    map(val => val[instanceId])
  );
}
