import { ViewIds } from '@ardoq/api-types';
import activeView$ from 'streams/views/mainContent/activeView$';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { getViewIdOfClickedElement } from 'tabview/graphViews/util';
import { trackEvent } from 'tracking/tracking';

// Context Menu Options tracking ------------------- START
export enum TrackedContextMenus {
  SCENARIO_CONTEXT_COMPONENT_CONTEXT_MENU = 'ScenarioContextComponentContextMenu',
  COMPONENTS_AND_REFERENCES_CONTEXT_MENU = 'ComponentsAndReferencesContextMenu',
  COMPONENT_CONTEXT_MENU = 'ComponentContextMenu',
  REFERENCE_CONTEXT_MENU = 'ReferenceContextMenu',
  WORKSPACE_CONTEXT_MENU = 'WorkspaceContextMenu',
  TAG_CONTEXT_MENU = 'TagContextMenu',
  SCENARIO_CONTEXT_MENU = 'ScenarioContextMenu',
  NON_COMPONENT_GROUP_CONTEXT_MENU = 'NonComponentGroupContextMenu',
}
export enum TrackedComponentContextMenuLabels {
  // changing these string values will disrupt the aggregation of tracked data
  EDIT_COMPONENT = 'Edit properties',
  CREATE_SCENARIO = 'Create scenario from selection',
  ADD_SELECTION_TO_SCENARIO = 'Add selection to scenario',
  EDIT_COMPONENT_STYLE = 'Edit style',
  LOCK_COMPONENT = '(Un)Lock',
  CREATE_OUTGOING_REFERENCE = 'Create outgoing reference',
  CREATE_INCOMING_REFERENCE = 'Create incoming reference',
  CREATE_REFERENCE = 'Create reference',
  VIEW_DIFF = 'View history',
  SELECT = 'Go to',
  SELECT_PARENT = 'Go to Parent',
  SELECT_WORKSPACE = 'Go to Workspace',
  EXCLUDE_FROM_CONTEXT = 'Exclude (this) from context',
  SHOW_ONLY_COMPONENTS_OF_THIS_TYPE = 'Show only components of this type',
  EXCLUDE_COMPONENTS_OF_THIS_TYPE = 'Exclude components of this type',
  EXCLUDE_CHILD_COMPONENTS = 'Exclude child components',
  EXCLUDE_CONTENT_FROM_WORKSPACE = 'Exclude content from workspace',
  DELETE_COMPONENT_IN_SCENARIO = 'Delete component in scenario',
  EXCLUDE_COMPONENT_FROM_SCENARIO = 'Exclude component from scenario',
  DELETE_COMPONENT = 'Delete Component',
  MERGE_COMPONENTS = 'Merge Components',
  ADD_CHILD_TO_COMPONENT = 'Add child to Component',
}
export enum TrackedReferenceContextMenuLabels {
  // changing these string values will disrupt the aggregation of tracked data
  EDIT_REFERENCE = 'Edit properties',
  LOCK_REFERENCE = '(Un)Lock',
  VIEW_DIFF = 'View history',
  REVERSE = 'Reverse reference',
  DELETE_REFERENCE = 'Delete reference',
  SELECT_REFERENCE = 'Go to reference',
  SELECT_SOURCE_COMPONENT = 'Go to source',
  SELECT_TARGET_COMPONENT = 'Go to target',
  EXCLUDE_FROM_CONTEXT = 'Exclude (this) from context',
  EXCLUDE_REFERENCES_OF_TYPE = 'Exclude references of type',
  SHOW_ONLY_REFERNCES_OF_TYPE = 'Show only references of type',
  SHOW_ONLY_REFERNCES_TO_TARGET = 'Show only references to target',
  SHOW_ONLY_REFERENCES_FROM_SOURCE = 'Show only references from source',
  EXCLUDE_CONTENT_FROM_WORKSPACE = 'Exclude content from workspace',
  MERGE_REFERENCES = 'Merge references',
}
export enum TrackedCompAndRefContextMenuLabels {
  // changing these string values will disrupt the aggregation of tracked data
  EDIT_SELECTED_COMPONENT_PROPS = 'Edit selected component properties',
  EDIT_SELECTED_REFERENCE_PROPS = 'Edit selected reference properties',
  EDIT_SELECTED_COMPONENT_STYLE = 'Edit selected component style',
  REVERSE_REFERENCES = 'Reverse references',
  TOGGLE_LOCK = '(Un)Lock',
  DELETE = 'Delete',
}
export enum TrackedScenarioContextComponentContextMenuLabels {
  // changing these string values will disrupt the aggregation of tracked data
  INCLUDE_IN_SCENARIO = 'Include in scenario',
  SELECT = 'Go to',
  SELECT_PARENT = 'Go to Parent',
  SELECT_WORKSPACE = 'Go to Workspace',
  EXCLUDE_FROM_CONTEXT = 'Exclude (this) from context',
  SHOW_ONLY_COMPONENTS_OF_THIS_TYPE = 'Show only components of this type',
  EXCLUDE_COMPONENTS_OF_THIS_TYPE = 'Exclude components of this type',
  EXCLUDE_CHILD_COMPONENTS = 'Exclude child components',
  EXCLUDE_CONTENT_FROM_WORKSPACE = 'Exclude content from workspace',
}
export enum TrackedWorkspaceContextMenuLabels {
  // changing these string values will disrupt the aggregation of tracked data
  PERMISSIONS = 'Permissions',
  EDIT_WORKSPACE = 'Edit workspace',
  EDIT_METAMODEL = 'Edit metamodel',
  EXCLUDE_CONTENT_FROM_WORKSPACE = 'Exclude content from workspace',
  EXCLUDE_REFERENCES_TO_WORKSPACE = 'Exclude references to workspace',
  EXCLUDE_REFERENCES_FROM_WORKSPACE = 'Exclude references from workspace',
  EXCLUDE_WORKSPACE_FROM_SCENARIO = 'Exclude workspace from scenario',
}

export enum TrackedTagContextMenuLabels {
  // changing these string values will disrupt the aggregation of tracked data
  EDIT_TAG = 'Edit tag',
  DELETE_TAG = 'Delete tag',
  SHOW_CONTENT_WITH_TAG = 'Show content with tag',
  EXCLUDE_CONTENT_WITH_TAG = 'Exclude content with tag',
}

export enum TrackedScenarioContextMenuLabels {
  // changing these string values will disrupt the aggregation of tracked data
  PERMISSIONS = 'Permissions',
  RENAME = 'Rename',
  COPY = 'Copy',
}

export enum TrackedNonComponentGroupContextMenuLabels {
  // changing these string values will disrupt the aggregation of tracked data
  EXPAND_GROUP = 'Expand group',
  COLLAPSE_GROUP = 'Collapse group',
  ZOOM_TO_FIT = 'Zoom to fit',
}

type HandleContextMenuOptionClickArgs = {
  clickedElement?: Element | null;
  contextMenuNamespace: TrackedContextMenus;
  selectedOptionTitle: string;
};

/**
 * Designed for tracking context menu option clicks in component, reference and
 * workspace context menus.
 * If a context menu was opened from a view, currentViewId and open views will
 * be recorded.
 * If a context menu was opened from the navigator, currentViewId is null and
 * open views will be recorded.
 */
export const trackContextMenuOptionClick = ({
  contextMenuNamespace,
  selectedOptionTitle,
  clickedElement,
}: HandleContextMenuOptionClickArgs) => {
  const currentViewId = clickedElement
    ? getViewIdOfClickedElement(clickedElement)
    : ViewIds.PROTEAN_DIAGRAM;
  const mainView = activeView$.state.mainViewId || null;
  const secondaryView = activeView$.state.secondaryViewId || null;
  const isViewpointMode = loadedGraph$.state.isViewpointMode;

  trackEvent('clicked context menu option', {
    currentViewId,
    mainView,
    secondaryView,
    contextMenuNamespace,
    selectedOptionTitle,
    isViewpointMode,
  });
};
// Context Menu Options tracking ------------------- END

export const getTrackingFunction =
  (target: HTMLElement | SVGElement | null, menuType: TrackedContextMenus) =>
  (optionTitle: string) =>
    trackContextMenuOptionClick({
      clickedElement: target,
      contextMenuNamespace: menuType,
      selectedOptionTitle: optionTitle,
    });
