import currentUser$, {
  CurrentUserState,
} from 'streams/currentUser/currentUser$';
import { PersonalSetting } from '@ardoq/api-types';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { scopeDiff$ } from 'scope/scopeDiff$';
import { filtered$ } from './scenarioRelated/navigator/scenarioRelatedDataSource$';
import { isUpdatingAppState$ } from 'isUpdatingAppState$';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

// for undefined || true return true
// for false return false
const getIsExpanded = (userState: CurrentUserState) =>
  !(
    userState.settings[
      PersonalSetting.QUICK_PERSPECTIVES_CONTAINER_EXPANDED
    ] === false
  );

export const viewModel$ = combineLatest({
  currentUser: currentUser$.pipe(distinctUntilChanged(getIsExpanded)),
  scopeDiff: scopeDiff$,
  filtered: filtered$,
  isUpdatingAppState: isUpdatingAppState$,
  activeScenario: activeScenario$,
}).pipe(
  filter(({ isUpdatingAppState }) => !isUpdatingAppState),
  map(({ currentUser, scopeDiff, filtered, activeScenario }) => {
    return {
      isQuickPerspectiveExpanded: getIsExpanded(currentUser),
      isDiffModeActive: Boolean(scopeDiff.scopeDiff),
      isFiltering: Boolean(filtered),
      isScenarioMode: activeScenarioOperations.isInScenarioMode(activeScenario),
    };
  })
);
