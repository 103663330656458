import { actionCreator } from '@ardoq/rxbeach';
import { NewSlide } from './types';
import {
  APISlideAttributes,
  ArdoqId,
  APIPresentationAssetAttributes,
} from '@ardoq/api-types';

type PayloadAddSlideFromCurrentContext = {
  presentation: APIPresentationAssetAttributes;
  newSlide: NewSlide;
};
export const addSlideFromCurrentContext =
  actionCreator<PayloadAddSlideFromCurrentContext>(
    '[presentationEditor] ADD_SLIDE_FROM_CURRENT_CONTEXT'
  );

export const replaceSlideWithCurrentContext = actionCreator<APISlideAttributes>(
  '[presentationEditor] REPLACE_SLIDE_WITH_CURRENT_CONTEXT'
);

export const deleteSlideInPresentationEditor = actionCreator<ArdoqId>(
  '[presentationEditor] DELETE_SLIDE_IN_PRESENTATION_EDITOR'
);

export const selectSlideForEditing = actionCreator<APISlideAttributes>(
  '[presentationEditor] SELECT_SLIDE_FOR_EDITING'
);

export const showPresentationEditor = actionCreator<{
  presentationId?: string;
  editingSlideId?: string;
}>('[presentationEditor] SHOW_PRESENTATION_EDITOR');

export const copySlideFromCurrentPresentation = actionCreator<{
  slide: APISlideAttributes;
  presentationId: ArdoqId;
}>('[presentationEditor] COPY_SLIDE_FROM_CURRENT_PRESENTATION');
