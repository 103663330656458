import { IntegrationConfigState } from './types';
import { buildInitialStreamState } from '../utils';
import { IntegrationId } from '../tabularMappings/types';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { updateIntegrationConfig } from './actions';
import { ActionCreatorParameter } from '../../utils/actionCreatorWithIntegrationId';

type AllIntegrationConfigsState = Record<IntegrationId, IntegrationConfigState>;

const createInitialConfigState = (): IntegrationConfigState => ({
  config: undefined,
});

const defaultState: AllIntegrationConfigsState = buildInitialStreamState(
  createInitialConfigState
);

const handleUpdateIntegrationConfig = (
  allIntegrationConfigsState: AllIntegrationConfigsState,
  {
    integrationId,
    config,
  }: ActionCreatorParameter<typeof updateIntegrationConfig>
) => ({
  ...allIntegrationConfigsState,
  [integrationId]: {
    config,
  },
});

const updateIntegrationConfigReducer = reducer<
  AllIntegrationConfigsState,
  ActionCreatorParameter<typeof updateIntegrationConfig>
>(updateIntegrationConfig, handleUpdateIntegrationConfig);

export const integrationConfigs$ = persistentReducedStream(
  `integrationsConfigs$`,
  defaultState,
  [updateIntegrationConfigReducer]
);

const integrationToConfigsStreams = new Map<
  IntegrationId,
  Observable<IntegrationConfigState>
>();

export const getIntegrationConfigsStream = (integrationId: IntegrationId) => {
  const configStream = integrationToConfigsStreams.get(integrationId);

  if (configStream) {
    return configStream as Observable<IntegrationConfigState>;
  }

  const stream$ = integrationConfigs$.pipe(
    map(allIntegrationConfigsState => allIntegrationConfigsState[integrationId])
  );

  integrationToConfigsStreams.set(integrationId, stream$);

  return stream$ as Observable<IntegrationConfigState>;
};
