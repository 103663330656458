import { AudienceStatus, ComponentStatus, DataSourceChildRow } from '../types';
import { sortBy } from 'lodash';
import {
  getAudienceStartingPageRepresentation,
  getAudienceTypeRepresentation,
  getPersonRepresentation,
  getPersonSecondaryRepresentation,
  rowHasSamePersonAs,
} from 'broadcasts/utils';
import {
  AudiencePreview,
  BroadcastAudienceType,
  AudienceStartingPage,
  BroadcastComponent,
  BroadcastScope,
  InstanceProgress,
  AudiencePreviewRow,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';

const sortByPersonRepresentation = (audiencePreview: AudiencePreview) => {
  return sortBy(audiencePreview, ({ person }) =>
    getPersonRepresentation(person)
  );
};

const checkIfIsFirstRowOfPerson = (
  audiencePreview: AudiencePreview,
  personIndex: number
) => {
  if (personIndex >= audiencePreview.length) return false;
  return !audiencePreview
    .slice(0, personIndex)
    .some(rowHasSamePersonAs(audiencePreview[personIndex]));
};

const getSourceSpecificAudienceStartingPage = (
  sources: BroadcastAudienceType[],
  selectedAudienceStartingPage: AudienceStartingPage
) =>
  sources.includes(BroadcastAudienceType.PREDEFINED) ||
  sources.includes(BroadcastAudienceType.GREMLIN)
    ? selectedAudienceStartingPage
    : AudienceStartingPage.SURVEY_OVERVIEW;

const getComponentLabel = (
  components: BroadcastComponent[] | null,
  scope: BroadcastScope
) => {
  if (components === null || scope === BroadcastScope.ALL)
    return 'All components in the scope';
  if (components.length === 1) return components[0].name;
  return `${components.length} components`;
};

const getComponentStatus = (
  components: BroadcastComponent[] | null,
  scope: BroadcastScope,
  progress: InstanceProgress
) => {
  if (components === null || scope === BroadcastScope.ALL)
    return ComponentStatus.INCOMPLETE;
  if (components.length === 1 && progress[components[0]._id]) {
    return ComponentStatus.COMPLETE;
  }
  return ComponentStatus.INCOMPLETE;
};

const getChildrenRowData = (
  parentRowKey: string,
  components: BroadcastComponent[] | null,
  progress: InstanceProgress,
  tableFilter?: string
): DataSourceChildRow[] | null => {
  if (components === null || components.length === 1) return null;
  return components.map(component => ({
    _id: [parentRowKey, component._id].join('-'),
    personLabel: null,
    personSublabel: null,
    shouldShowEmailBelowPersonName: false,
    sourceLabels: [],
    componentLabel: component.name,
    audienceStartingPage: null,
    children: null,
    isChildRow: true,
    audienceStatus: AudienceStatus.VALID,
    componentStatus: getComponentStatus(
      [component],
      BroadcastScope.SOME,
      progress
    ),
    tableFilter,
  }));
};

export type AudienceDataSourceItem = {
  _id: string;
  personLabel: string | null;
  personSublabel: string | null;
  sourceLabels: string[];
  componentLabel: string;
  audienceStartingPageLabel: string;
  children: DataSourceChildRow[] | null;
  isChildRow: boolean;
  audienceStatus: AudienceStatus;
  componentStatus: ComponentStatus;
  sortableComponentLabel: string | number;
  tableFilter?: string;
};

/**
 * Sorts children within each row alphabetically and prioritizes
 * children whose labels match the filter text.
 */
function withSortAndFilterPrioritizedChildren(
  rows: AudienceDataSourceItem[],
  tableFilter: string
) {
  if (!tableFilter) return rows;
  const lowerCaseFilter = tableFilter.toLocaleLowerCase();
  return rows.map(row => {
    const children = row.children;
    if (!children?.length) return row;
    const currentLocale = getCurrentLocale();
    const sortedChildren = children
      .slice()
      .sort((a, b) =>
        localeCompare(
          a?.componentLabel ?? '',
          b?.componentLabel ?? '',
          currentLocale
        )
      );
    const [matchingChildren, nonMatchingChildren] = sortedChildren.reduce<
      [DataSourceChildRow[], DataSourceChildRow[]]
    >(
      (accumulator, child) => {
        const label = child?.componentLabel;
        accumulator[
          label && label.toLocaleLowerCase().includes(lowerCaseFilter) ? 0 : 1
        ].push(child);
        return accumulator;
      },
      [[], []]
    );
    const prioritizedChildren = [...matchingChildren, ...nonMatchingChildren];
    return {
      ...row,
      children: prioritizedChildren,
    };
  });
}

const matchesFilter = (row: AudiencePreviewRow, filter: string) => {
  if (!filter) return true;
  const personLabel = getPersonRepresentation(row.person) ?? '';
  const personSublabel = getPersonSecondaryRepresentation(row.person) ?? '';
  const componentLabel =
    row.components.length === 1
      ? getComponentLabel(row.components, row.scope)
      : '';
  const childrenComponentLabels = row.components?.map(c => c.name) ?? [];

  return [
    personLabel,
    personSublabel,
    componentLabel,
    ...childrenComponentLabels,
  ].some(val => val.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
};

export const getAudienceByPersonDataSource = (
  audiencePreview: AudiencePreview,
  audienceStartingPage: AudienceStartingPage = AudienceStartingPage.SURVEY_COMPONENT,
  tableFilter: string,
  progress: InstanceProgress
): AudienceDataSourceItem[] => {
  const filteredAndSortedAudiencePreview = sortByPersonRepresentation(
    audiencePreview.filter(row => matchesFilter(row, tableFilter))
  );
  const tableDataSource = filteredAndSortedAudiencePreview.map(
    ({ person, sources, components, scope }, i) => {
      const isFirstRowOfPerson = checkIfIsFirstRowOfPerson(
        filteredAndSortedAudiencePreview,
        i
      );
      const audienceStartingPageLabel = getAudienceStartingPageRepresentation(
        getSourceSpecificAudienceStartingPage(sources, audienceStartingPage)
      );
      const componentLabel = getComponentLabel(components, scope);
      const rowIdentifier = [person.name ?? '', person.email ?? ''].join('-');
      return {
        _id: rowIdentifier,
        personLabel: isFirstRowOfPerson
          ? getPersonRepresentation(person)
          : null,
        personSublabel: isFirstRowOfPerson
          ? getPersonSecondaryRepresentation(person)
          : null,
        sourceLabels: sources
          .map(getAudienceTypeRepresentation)
          .filter(ExcludeFalsy),
        componentLabel,
        audienceStartingPageLabel,
        children:
          scope === BroadcastScope.SOME
            ? getChildrenRowData(
                rowIdentifier,
                components,
                progress,
                tableFilter
              )
            : null,
        isChildRow: false,
        audienceStatus:
          isFirstRowOfPerson && !person.email
            ? AudienceStatus.MISSING_EMAIL
            : AudienceStatus.VALID,
        componentStatus: getComponentStatus(components, scope, progress),
        sortableComponentLabel: isNaN(Number(componentLabel.split(' ')[0]))
          ? componentLabel
          : Number(componentLabel.split(' ')[0]),
        tableFilter,
      };
    }
  );
  return withSortAndFilterPrioritizedChildren(tableDataSource, tableFilter);
};
