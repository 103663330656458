import { ComponentType } from 'react';
import AdvancedSearchContainer from './AdvancedSearchContainer';
import SurveysContainer from 'surveyAdmin/SurveysContainer';
import MetamodelContainer from './MetamodelContainer';
import selectedModule$ from './selectedModule$';
import { AppModules, PayloadShowAppModule } from './types';
import DashboardContainer from './DashboardContainer/DashboardContainer';
import IntegrationsContainer from './IntegrationsContainer';
import TrialExpired from './TrialExpired';
import currentUser from 'models/currentUser';
import { Features, hasFeature } from '@ardoq/features';
import PresentationLandingPage from 'appContainer/landingPage/PresentationLandingPage';
import SurveyLandingPage from 'appContainer/landingPage/SurveyLandingPage';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { action$, connect, ofType } from '@ardoq/rxbeach';
import { take } from 'rxjs/operators';
import { requestShowAppModule } from './actions';
import PresentationSidebar from './presentationSidebar/PresentationSidebar';
import { logError, logWarn } from '@ardoq/logging';
import MainAppModule from './MainAppModule/MainAppModule';
import BroadcastsContainer from './BroadcastsContainer';
import ScenarioMergeContainer from 'scope/merge/ScenarioMergeContainer';
import ViewpointsContainer from './ViewpointsContainer';
import ReportsContainer from '../report/ReportsContainer';
import DashboardModuleContainer from '../dashboard/DashboardContainer';
import ManageOrganizationContainer from 'appContainer/ManageOrganizationContainer';
import UserSettingsView from 'admin/user/UserSettingsView';
import TraversalsContainer from './TraversalsContainer';
import AuditLogContainer from 'auditLog/AuditLogContainer';
import SubdivisionEditor from 'subdivisionEditor/subdivisionEditor';
import NewHomePage from '../homePage/HomePage';
import ComponentOverviewPage from './componentOverviewPage/ComponentOverviewPage';
import AnalyticsContainer from './analytics/AnalyticsContainer';
import PresentationsContainer from 'presentation/presentations2024/PresentationsContainer';
import AccessControlPage from './AccessControlPage/AccessControlPage';
import { AssetsOverview } from 'assets/Overview';
import UseCaseLandingPage from 'useCases/UseCaseLandingPage/UseCaseLandingPage';
import InventoryModule from 'inventory/InventoryModule';
import * as OrganizationMetamodelRoutes from 'organizationMetamodel/organizationMetamodelRoutes';
import { connectRouter } from 'router/declarative/connectRouter';
import ExternalDocumentContainer from './ExternalDocumentContainer';

const {
  DASHBOARDS,
  SEARCH,
  PRESENTATIONS,
  SURVEY_ADMIN,
  HOME,
  INTEGRATIONS,
  WORKSPACES,
  METAMODEL,
  ORGANIZATION_METAMODEL,
  BROADCASTS,
  SCENARIO_MERGE,
  VIEWPOINTS,
  REPORTS,
  MANAGE_ORGANIZATION,
  USER_SETTINGS,
  TRAVERSALS,
  SUBDIVISION,
  AUDIT_LOG,
  USE_CASES,
  COMPONENT_OVERVIEW_PAGE,
  ANALYTICS,
  ACCESS_CONTROL,
  ASSETS,
  INVENTORY,
  EXTERNAL_DOCUMENT,
} = AppModules;

type GetModuleComponent = (
  options?: PayloadShowAppModule['options']
) => ComponentType<any>;
// There is special logic for the Workspaces module specifically
type AppModulesWithoutWorkspaces = Exclude<AppModules, AppModules.WORKSPACES>;
type ModuleContainer = Record<AppModulesWithoutWorkspaces, GetModuleComponent>;

const ModuleContainers: ModuleContainer = {
  [HOME]: () =>
    hasFeature(Features.NEW_CORE_JOURNEY) ? NewHomePage : DashboardContainer,
  [DASHBOARDS]: () => DashboardModuleContainer,
  [SEARCH]: () => AdvancedSearchContainer,
  [INTEGRATIONS]: () => IntegrationsContainer,
  [ANALYTICS]: () => AnalyticsContainer,
  [PRESENTATIONS]: () =>
    hasFeature(Features.PRESENTATIONS)
      ? PresentationsContainer
      : PresentationLandingPage,
  [SURVEY_ADMIN]: () =>
    hasFeature(Features.SURVEYS) ? SurveysContainer : SurveyLandingPage,
  [METAMODEL]: () => MetamodelContainer,
  [EXTERNAL_DOCUMENT]: () => ExternalDocumentContainer,
  [ORGANIZATION_METAMODEL]: () =>
    connectRouter(OrganizationMetamodelRoutes.location$),
  [BROADCASTS]: () =>
    hasFeature(Features.BROADCASTS)
      ? BroadcastsContainer
      : hasFeature(Features.NEW_CORE_JOURNEY)
        ? NewHomePage
        : DashboardContainer,
  [VIEWPOINTS]: _options => ViewpointsContainer,
  [SCENARIO_MERGE]: () => ScenarioMergeContainer,
  [REPORTS]: () => ReportsContainer,
  [MANAGE_ORGANIZATION]: () => ManageOrganizationContainer,
  [ACCESS_CONTROL]: () => AccessControlPage,
  [USER_SETTINGS]: () => UserSettingsView,
  [TRAVERSALS]: () => TraversalsContainer,
  [SUBDIVISION]: () => SubdivisionEditor,
  [AUDIT_LOG]: () => AuditLogContainer,
  [USE_CASES]: () => UseCaseLandingPage,
  [COMPONENT_OVERVIEW_PAGE]: () => ComponentOverviewPage,
  [ASSETS]: () => AssetsOverview,
  [INVENTORY]: () => InventoryModule,
};

const logModuleNotFoundAndGetHomeContainer = (selectedModule: AppModules) => {
  logWarn(Error('Selected module could not be found'), null, {
    selectedModule,
  });
  return ModuleContainers[HOME]();
};

const getAppModuleContainer = ({
  selectedModule,
  options,
}: PayloadShowAppModule) =>
  selectedModule === WORKSPACES
    ? null
    : ModuleContainers[selectedModule](options) ||
      logModuleNotFoundAndGetHomeContainer(selectedModule);

const recoverOnError = (recoverCompleted: () => void) => {
  action$.pipe(ofType(requestShowAppModule), take(1)).subscribe(() => {
    recoverCompleted();
  });
};

function AppModuleController(props: PayloadShowAppModule) {
  const { selectedModule } = props;

  const { options } = props;
  const shouldHideMainView = selectedModule !== WORKSPACES;
  const ActiveModule = getAppModuleContainer({ selectedModule, options });

  if (currentUser.isExpired()) {
    return <TrialExpired />;
  }

  const showPresentationSidebar = [
    WORKSPACES,
    DASHBOARDS,
    METAMODEL,
    REPORTS,
    EXTERNAL_DOCUMENT,
  ].includes(selectedModule);

  return (
    <>
      <ErrorBoundary logError={logError} recoverOnError={recoverOnError}>
        <MainAppModule hide={shouldHideMainView} />
        {ActiveModule && <ActiveModule />}
        {showPresentationSidebar && <PresentationSidebar />}
      </ErrorBoundary>
    </>
  );
}

export default connect(AppModuleController, selectedModule$);
