import { useMemo, useRef } from 'react';
import { colors, s24 } from '@ardoq/design-tokens';
import { IconName } from '@ardoq/icons';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import IconClickable from '../../atomicComponents/IconClickable';
import {
  DASHBOARD_MAX_WIDTH,
  DASHBOARD_NAME_ISLAND_ID,
  DashboardBuilderChartWidget,
  DashboardBuilderHeaderWidget,
  DashboardErrorPage,
  DashboardErrorType,
  DashboardTrackingEventsNames,
  EditModeDashboard,
  WIDGET_ADD_BUTTON_ID,
  WidgetError,
  widgetOperations,
  WidgetWarning,
} from '@ardoq/dashboard';
import { getCurrentLocale } from '@ardoq/locale';
import dashboardBuilder$, { DashboardBuilder$State } from './DashboardBuilder$';
import {
  copyWidget,
  createWidget,
  deleteWidget,
  fetchWidgetDataSources,
  saveDashboard,
  selectWidget,
  setWidgets,
} from './actions';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { ArdoqId, HeaderWidgetAttributes, WidgetTypes } from '@ardoq/api-types';
import WidgetSidebar from './sidebars/WidgetSidebar';
import { useEffectOnce, useResizeObserver } from '@ardoq/hooks';
import { isEqual } from 'lodash';
import { Layout, LoadingState } from './types';
import HeaderWidgetSidebar from './sidebars/HeaderWidgetSidebar';
import DashboardNameSidebar from './sidebars/DashboardNameSidebar';
import HeaderButtons from './HeaderButtons';
import { getColorsForWidget } from './colorUtils';
import { navigateToDashboardModule } from 'router/navigationActions';
import { DashboardModule } from 'dashboard/types';
import {
  ErrorNotification,
  NotificationWrapper,
  showToast,
  ToastType,
  WarningNotification,
} from '@ardoq/status-ui';
import { trackEvent } from '../../tracking/tracking';
import DashboardModuleContainer from 'dashboard/DashboardModuleContainer';
import { warnBeforeNavigate } from '../../router/history';
import {
  formatChartWidgetForSave,
  formatHeaderWidgetForSave,
  getAvailableFieldsForDataSource,
  getValidationErrorsFromWidget,
  getWidgetValidationErrors,
  getWidgetWarnings,
} from './utils';
import { getDashboardColorThemes } from '../DashboardColorThemeSettings/actions';
import CanEditDashboardGuard from './CanEditDashboardGuard';
import { RIGHT_SIDEBAR_WIDTH } from '@ardoq/sidebar-menu';
import { Link } from '@ardoq/typography';
import { Features, hasFeature } from '@ardoq/features';
import { map } from 'rxjs';
import { PermissionContext } from '@ardoq/access-control';
import { ResizablePageBody } from '@ardoq/page-layout';
import { TextOverflow } from '@ardoq/popovers';
import { ButtonSize, IconButton } from '@ardoq/button';
import { FlexBox } from '@ardoq/layout';

type DashboardBuilderProps = DashboardBuilder$State & {
  selectedDashboardId?: string;
  hasNewJourneyFeature: boolean;
  permissionContext: PermissionContext;
};

const getOnLayoutChange =
  (
    widgets: Array<DashboardBuilderChartWidget | DashboardBuilderHeaderWidget>
  ) =>
  (newLayout: Layout[]) => {
    const layoutForWidgetId = newLayout.reduce<Record<ArdoqId, Layout>>(
      (layoutRecord, layout) => ({
        ...layoutRecord,
        ...(layout.i !== WIDGET_ADD_BUTTON_ID ? { [layout.i]: layout } : {}),
      }),
      {}
    );
    const newWidgets = widgets
      .map(widget => ({
        ...widget,
        layout: layoutForWidgetId[widget._id!],
        isLoading:
          widget.isLoading ||
          (widget.viewType === WidgetTypes.TABLE &&
            widget.layout.h !== layoutForWidgetId[widget._id!].h),
      }))
      .sort((a, b) => a.layout.y - b.layout.y || a.layout.x - b.layout.x);

    if (!isEqual(widgets, newWidgets)) dispatchAction(setWidgets(newWidgets));
  };

const getStateToCompare = (
  {
    _id,
    _version,
    name,
    description,
    widgets,
    settings,
  }: Partial<DashboardBuilderProps>,
  availableDataSources: DashboardBuilder$State['availableDataSources']
) => ({
  _id,
  _version,
  name,
  description,
  widgets: widgets
    ? [...widgets]
        .sort((a, b) => (a._id < b._id ? -1 : 1))
        .map(widget => ({
          ...(widgetOperations.isHeaderWidgetData(widget)
            ? formatHeaderWidgetForSave(widget)
            : formatChartWidgetForSave(
                widget,
                getAvailableFieldsForDataSource(
                  availableDataSources,
                  widget.datasource
                )
              )),
          _id: widget._id,
          validationError: widget.validationError,
        }))
    : [],
  settings,
});

const useHasChanges = ({
  _id,
  _version,
  name,
  description,
  widgets,
  availableDataSources,
  settings,
}: Pick<
  DashboardBuilderProps,
  | '_version'
  | '_id'
  | 'description'
  | 'name'
  | 'widgets'
  | 'availableDataSources'
  | 'settings'
>) => {
  const isDataSourcesLoaded = useRef(Boolean(availableDataSources.length));

  const initialState = useRef(
    getStateToCompare(
      {
        _id,
        _version,
        name,
        description,
        widgets,
        settings,
      },
      availableDataSources
    )
  );

  if (_version !== initialState.current._version) {
    initialState.current = getStateToCompare(
      {
        _id,
        _version,
        name,
        description,
        widgets,
        settings,
      },
      availableDataSources
    );
  }
  if (!isDataSourcesLoaded.current && availableDataSources.length) {
    // getStateToCompare can sometimes return different values before and after availableDataSources has loaded. So if it finishes loading after the state has been set the first time, set it again with the data sources loaded to return the correct hasChanges value
    initialState.current = getStateToCompare(
      {
        _id,
        _version,
        name,
        description,
        widgets,
        settings,
      },
      availableDataSources
    );
    isDataSourcesLoaded.current = true;
  }
  const hasChanges = useMemo(
    () =>
      !isEqual(
        getStateToCompare(
          {
            _id,
            _version,
            name,
            description,
            widgets,
            settings,
          },
          availableDataSources
        ),
        initialState.current
      ),
    [
      _id,
      _version,
      name,
      description,
      widgets,
      settings,
      initialState,
      availableDataSources,
    ]
  );
  return hasChanges;
};
const DashboardBuilder = ({
  loadingState,
  selectedWidgetId,
  validationErrors,
  apiError,
  _id,
  name,
  _version,
  description,
  availableDataSources,
  loadingDataSources,
  widgets,
  settings,
  selectedDashboardId,
  isWidgetRecentlyCreated,
  hasNewJourneyFeature,
  permissionContext,
}: DashboardBuilderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const size = useResizeObserver(ref);

  useEffectOnce(() => {
    dispatchAction(fetchWidgetDataSources());
    dispatchAction(getDashboardColorThemes());
    trackEvent(DashboardTrackingEventsNames.OPENED_DASHBOARD_BUILDER, {}, true);
  });
  const sidebarRef = useRef<HTMLFormElement | null>(null);
  const hasChanges = useHasChanges({
    _id,
    name,
    _version,
    description,
    availableDataSources,
    widgets,
    settings,
  });

  const selectedWidgetType = selectedWidgetId
    ? widgets.find(widget => widget._id === selectedWidgetId)?.viewType
    : null;

  const widgetValidationErrors = getWidgetValidationErrors(widgets);
  const widgetWarnings = getWidgetWarnings(widgets);
  return (
    <DashboardModuleContainer
      title={
        <FlexBox gap="xsmall" align="center">
          <TextOverflow lineClamp={1}>{name}</TextOverflow>
          <IconButton
            iconName={IconName.EDIT}
            buttonSize={ButtonSize.SMALL}
            onClick={() =>
              dispatchAction(selectWidget(DASHBOARD_NAME_ISLAND_ID))
            }
          />
        </FlexBox>
      }
      secondaryText="Dashboard Builder"
      hasNewJourneyFeature={hasNewJourneyFeature}
      dashboardModule={DashboardModule.BUILDER}
      HeaderLeftContent={
        <IconClickable
          color={colors.grey50}
          hoverColor={colors.grey35}
          iconName={IconName.KNOWLEDGE_BASE}
          tooltipText="Knowledge base"
          onClick={() => {
            trackEvent(
              DashboardTrackingEventsNames.CLICKED_ON_QUESTIONMARK_BUTTON_IN_DASHBOARD_BUILDER
            );
            window.open(KnowledgeBaseLink.DASHBOARD, '_blank noopener');
          }}
          containerStyle={{
            height: s24,
          }}
        />
      }
      HeaderRightContent={
        <HeaderButtons
          hasNewJourneyFeature={hasNewJourneyFeature}
          hasUnsavedProgress={hasChanges}
          loadingState={loadingState}
          hasDashboardId={Boolean(_id)}
          viewDashboard={() => {
            dispatchAction(
              navigateToDashboardModule({
                selectedDashboardId: _id,
                dashboardModule: DashboardModule.READER,
              })
            );
          }}
          goToDashboardOverview={() => {
            dispatchAction(
              navigateToDashboardModule({
                dashboardModule: DashboardModule.OVERVIEW,
              })
            );
          }}
          saveDashboard={() => {
            dispatchAction(
              saveDashboard({
                _id,
                _version,
                name,
                description,
                widgets,
                settings,
                validationErrors,
              })
            );
          }}
        />
      }
    >
      <CanEditDashboardGuard
        dashboardId={selectedDashboardId}
        permissionContext={permissionContext}
      >
        {loadingState === LoadingState.LOADING_ERROR ? (
          <DashboardErrorPage error={apiError} />
        ) : (
          <ResizablePageBody
            ref={ref}
            rightContentInitialWidth={RIGHT_SIDEBAR_WIDTH}
            rightContentMinWidth={RIGHT_SIDEBAR_WIDTH}
            rightContent={
              selectedWidgetId === DASHBOARD_NAME_ISLAND_ID ? (
                <DashboardNameSidebar
                  name={name}
                  description={description}
                  hasValidationError={validationErrors.has(
                    DashboardErrorType.MISSING_NAME
                  )}
                  ref={sidebarRef}
                />
              ) : selectedWidgetType === WidgetTypes.HEADER ? (
                <HeaderWidgetSidebar
                  headingText={
                    (widgets.find(
                      widget => widget._id === selectedWidgetId
                    ) as HeaderWidgetAttributes)!.content
                  }
                  ref={sidebarRef}
                  isWidgetRecentlyCreated={isWidgetRecentlyCreated}
                />
              ) : selectedWidgetId &&
                selectedWidgetId !== DASHBOARD_NAME_ISLAND_ID ? (
                <WidgetSidebar
                  key={selectedWidgetId} // Makes sure state in the component subtree is correctly updated when the selected widget changes
                  selectedWidget={
                    widgets.find(
                      widget => widget._id === selectedWidgetId
                    )! as DashboardBuilderChartWidget
                  }
                  isWidgetRecentlyCreated={isWidgetRecentlyCreated}
                  availableDataSources={availableDataSources}
                  loadingDataSources={loadingDataSources}
                  ref={sidebarRef}
                  colors={getColorsForWidget(settings.colors, selectedWidgetId)}
                  hasSourceFieldError={getValidationErrorsFromWidget(
                    widgets,
                    selectedWidgetId
                  ).includes(WidgetError.FIELD_REMOVED_FROM_SOURCE)}
                />
              ) : null
            }
          >
            <NotificationWrapper $maxWidth={DASHBOARD_MAX_WIDTH}>
              {loadingState === LoadingState.SAVING_ERROR && (
                <ErrorNotification>
                  {apiError?.message
                    ? `${apiError.message}\n\nIf the issue persists, reach out to our Support team with the following trace ID: ${apiError.traceId}`
                    : "Uh oh! Looks like we ran into a technical hiccup and your dashboard wasn't saved. If the issue persists, reach out to our Support team."}
                </ErrorNotification>
              )}

              {/* TODO: Display error better */}
              {loadingState === LoadingState.FETCH_DATA_SOURCES_ERROR && (
                <ErrorNotification>
                  {apiError
                    ? `${apiError.message}\n\nIf the issue persists, reach out to our Support team with the following trace ID: ${apiError.traceId}`
                    : "Uh oh! Looks like we ran into a technical hiccup and your data sources couldn't be loaded."}
                </ErrorNotification>
              )}

              {widgetValidationErrors.includes(
                WidgetError.MISSING_CHART_SOURCE
              ) && (
                <ErrorNotification>
                  Some charts are missing a data source. Select a report or a
                  survey or remove the charts to save the dashboard.
                </ErrorNotification>
              )}

              {widgetValidationErrors.includes(
                WidgetError.MISSING_HEADER_TITLE
              ) && (
                <ErrorNotification>
                  Some headers are missing a title. Give them a name or remove
                  them to save the dashboard.
                </ErrorNotification>
              )}

              {(widgetValidationErrors.includes(WidgetError.MISSING_FIELDS) ||
                widgetValidationErrors.includes(
                  WidgetError.FIELD_REMOVED_FROM_SOURCE
                )) && (
                <ErrorNotification>
                  Some table charts are missing a field. Select a field for the
                  charts to save the dashboard.
                </ErrorNotification>
              )}

              {widgetValidationErrors.includes(WidgetError.DEFAULT_ERROR) && (
                <ErrorNotification>
                  There was a technical issue saving some charts in this
                  dashboard. Please refresh to try again. If the problem
                  continues, contact customer support.
                </ErrorNotification>
              )}

              {widgetWarnings.includes(
                WidgetWarning.INCOMPATIBLE_WIDGET_TYPE_WITH_DATA_SOURCE
              ) && (
                <WarningNotification>
                  <p>
                    This dashboard has charts with fields that are no longer
                    supported by the chart type. Please select a different field
                    or chart type to save the dashboard. Read about the
                    available fields for each chart type{' '}
                    <Link
                      typography="text2"
                      href={KnowledgeBaseLink.AVAILABLE_CHARTS_PER_DATA_SOURCE}
                    >
                      here
                    </Link>
                    .
                  </p>
                </WarningNotification>
              )}
            </NotificationWrapper>

            <EditModeDashboard
              warnBeforeNavigate={warnBeforeNavigate}
              hasUnsavedData={hasChanges}
              dashboardData={{
                name,
                description,
                widgets,
                settings,
              }}
              isLoading={loadingState === LoadingState.LOADING}
              isSaving={loadingState === LoadingState.SAVING}
              userLocale={getCurrentLocale()}
              onLayoutChange={getOnLayoutChange(widgets)}
              onCreateNewWidget={(layout, viewType) => {
                dispatchAction(createWidget({ layout, viewType }));
              }}
              selectWidget={widgetId => dispatchAction(selectWidget(widgetId))}
              selectedWidgetId={selectedWidgetId}
              onCopyWidget={(widgetId, layout, widgetType) => {
                showToast(
                  widgetType === WidgetTypes.HEADER
                    ? 'Heading copied.'
                    : 'Chart copied.',
                  ToastType.SUCCESS
                );
                dispatchAction(copyWidget({ widgetId, layout }));
              }}
              deleteWidget={(widgetId, widgetType) => {
                showToast(
                  widgetType === WidgetTypes.HEADER
                    ? 'Heading deleted.'
                    : 'Chart deleted.',
                  ToastType.SUCCESS
                );
                dispatchAction(deleteWidget(widgetId));
              }}
              isSidebarRendered={Boolean(
                selectedWidgetId ||
                  (sidebarRef.current &&
                    window.document.contains(sidebarRef.current!))
              )}
              pageBodyWidth={size.width ? size.width : null}
              trackEvent={trackEvent}
            />
          </ResizablePageBody>
        )}
      </CanEditDashboardGuard>
    </DashboardModuleContainer>
  );
};

export default connect(
  DashboardBuilder,
  dashboardBuilder$.pipe(
    map(dashboardBuilderData => {
      return {
        ...dashboardBuilderData,
        hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
      };
    })
  )
);
