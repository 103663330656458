import styled from 'styled-components';
import { TREE_INDENTATION_SIZE } from './const';

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  font-size: 18px;
  line-height: inherit;
  margin-right: 8px;
  user-select: none;
`;

export const TextWrapper = styled.div`
  max-width: 287px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ChildrenWrapper = styled.div`
  margin-left: ${2 * TREE_INDENTATION_SIZE}px;
`;

export const WorkspaceModelBrowserWrapper = styled.div`
  position: relative;
  z-index: 1;
`;
