import { traversalApi } from '@ardoq/api';
import {
  routine,
  ofType,
  dispatchAction,
  collectRoutines,
  extractPayload,
} from '@ardoq/rxbeach';
import {
  distinctUntilChanged,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  fetchInstanceCounts,
  failedToLoadMetamodel,
  setFetchInstanceCountsError,
  setInstanceCounts,
  setMetamodelAndTraversalStartQueryData,
} from './actions';
import { isArdoqError } from '@ardoq/common-helpers';
import { requestLoadMetaModel } from 'architectureModel/actions';
import { loadMetaModel } from 'architectureModel/loadMetaModel';
import { isEqual } from 'lodash';
import { defaultMetamodel } from './audienceTraversalDrawer$';
import surveyEditor$ from 'surveyAdmin/SurveyEditor/streams/surveyEditor$';

const handleFetchInstanceCounts = routine(
  ofType(fetchInstanceCounts),
  extractPayload(),
  switchMap(traversalApi.countInstances),
  tap(response => {
    if (isArdoqError(response)) {
      dispatchAction(setFetchInstanceCountsError());
      return;
    }
    dispatchAction(setInstanceCounts(response));
  })
);

const handleRequestLoadMetaModel = routine(
  ofType(requestLoadMetaModel),
  switchMap(loadMetaModel),
  withLatestFrom(surveyEditor$),
  map(([response, { surveyAttributes }]) => {
    const approvalAudience = surveyAttributes.approvalFlow?.selectors ?? [];
    const surveyComponentTypeName = surveyAttributes?.componentTypeName;
    const workspaceId = surveyAttributes?.workspace;
    if (isArdoqError(response)) {
      dispatchAction(
        setMetamodelAndTraversalStartQueryData({
          approvalAudience,
          surveyComponentTypeName,
          workspaceId,
          metamodel: defaultMetamodel,
        })
      );
      dispatchAction(failedToLoadMetamodel());
      return;
    }
    dispatchAction(
      setMetamodelAndTraversalStartQueryData({
        approvalAudience,
        surveyComponentTypeName,
        workspaceId,
        metamodel: response,
      })
    );
  }),
  distinctUntilChanged(isEqual)
);

export default collectRoutines(
  handleFetchInstanceCounts,
  handleRequestLoadMetaModel
);
