import { SortColumnOptions } from '../../components/EntityBrowser/types';
import { TextEllipsisWrapper } from '../../components/EntityBrowser/atoms';
import { HeaderCell } from '@ardoq/table';
import SortHeader from '../../components/EntityBrowser/SortHeader';
import { formatDateTime } from '@ardoq/date-time';
import { WithPopover } from '@ardoq/popovers';
import { getCurrentLocale } from '@ardoq/locale';
import { viewIdToUserFriendlyName } from '../../views/metaInfoTabs';
import { APIViewpointAttributes, ViewIds } from '@ardoq/api-types';

/** This type helps us to use correct key as data index and catch errors
 * if we rename the key in APITraversalAttributes.
 * This is a workaround for the fact that DataSource table is badly typed.
 */
type DataIndex<type extends keyof APIViewpointAttributes> = keyof Pick<
  APIViewpointAttributes,
  type
>;

export const getContextComponentTypeColumn = (props: SortColumnOptions) => {
  const startTypeDataIndex: DataIndex<'startType'> = 'startType';

  return {
    headerStyle: {
      width: '220px',
    },
    title: 'Component type',
    dataIndex: startTypeDataIndex,
    headerRender: ({ title, dataIndex = startTypeDataIndex }: HeaderCell) => (
      <SortHeader
        dataIndex={dataIndex}
        title={title}
        dataClickId="context-component-type"
        sortByField={props.sortByField}
        sortOrder={props.sortOrder}
        onSortChanged={props.setSortByField}
      />
    ),
    valueRender: (value: string) => {
      return (
        <WithPopover content={() => value}>
          <TextEllipsisWrapper $width="210px">{value}</TextEllipsisWrapper>
        </WithPopover>
      );
    },
  };
};

export const getVisualizationColumn = (props: SortColumnOptions) => {
  const viewNameDataIndex: DataIndex<'viewName'> = 'viewName';

  return {
    headerStyle: {
      width: '150px',
    },
    title: 'View style',
    dataIndex: viewNameDataIndex,
    headerRender: ({ title, dataIndex = viewNameDataIndex }: HeaderCell) => (
      <SortHeader
        dataIndex={dataIndex}
        title={title}
        dataClickId="visualization"
        sortByField={props.sortByField}
        sortOrder={props.sortOrder}
        onSortChanged={props.setSortByField}
      />
    ),
    valueRender: (value: string) => {
      const userFriendlyValue =
        viewIdToUserFriendlyName[value as ViewIds] ?? '';
      return (
        <WithPopover content={() => userFriendlyValue}>
          <TextEllipsisWrapper $width="150px">
            {userFriendlyValue}
          </TextEllipsisWrapper>
        </WithPopover>
      );
    },
  };
};

export const getDateCreatedColumn = (props: SortColumnOptions) => {
  const createdDateDataIndex: DataIndex<'created'> = 'created';

  return {
    headerStyle: {
      width: '200px',
    },
    title: 'Date created',
    dataIndex: createdDateDataIndex,
    headerRender: ({ title, dataIndex = createdDateDataIndex }: HeaderCell) => (
      <SortHeader
        dataIndex={dataIndex}
        title={title}
        dataClickId="date-created"
        sortByField={props.sortByField}
        sortOrder={props.sortOrder}
        onSortChanged={props.setSortByField}
      />
    ),
    valueRender: (value: string) => (
      <TextEllipsisWrapper $width="200px">
        {formatDateTime(value, getCurrentLocale())}
      </TextEllipsisWrapper>
    ),
  };
};

export const getCreatedByColumn = (props: SortColumnOptions) => {
  const createdByDataIndex: DataIndex<'createdByName'> = 'createdByName';

  return {
    headerStyle: {
      width: '200px',
    },
    title: 'Created by',
    dataIndex: createdByDataIndex,
    headerRender: ({ title, dataIndex = createdByDataIndex }: HeaderCell) => (
      <SortHeader
        dataIndex={dataIndex}
        title={title}
        dataClickId="date-created"
        sortByField={props.sortByField}
        sortOrder={props.sortOrder}
        onSortChanged={props.setSortByField}
      />
    ),
    valueRender: (value: string) => {
      return (
        <WithPopover content={() => value}>
          <TextEllipsisWrapper $width="200px">{value}</TextEllipsisWrapper>
        </WithPopover>
      );
    },
  };
};
