import { trackEvent } from 'tracking/tracking';

export enum DetailsDrawerTrackingEventsNames {
  OPEN_NEW_DATASET = 'Details Drawer0 Open dataset',
}

const trackOpenNewDataset = (value: boolean) => {
  trackEvent(DetailsDrawerTrackingEventsNames.OPEN_NEW_DATASET, { value });
};

export default {
  trackOpenNewDataset,
};
