import { ArdoqId, AuditLogEntityType, ReportFilters } from '@ardoq/api-types';
import { AdHocSearchReport } from '@ardoq/report-builder';
import { actionCreator } from '@ardoq/rxbeach';
import { AccessControlRoute } from 'admin/accessControl/navigation/types';
import { OrgTabs } from 'admin/manageOrganization/navigation/types';
import { AppModules } from 'appContainer/types';
import { DashboardModule } from 'dashboard/types';
import { MetamodelPane } from 'metamodel/navigation/types';
import {
  MetamodelEntityId,
  TabId,
} from 'organizationMetamodel/metamodel/types';
import * as OrganizationMetamodelRoutes from 'organizationMetamodel/organizationMetamodelRoutes';
import { SearchPane } from 'search/SearchTabContainer/types';
import {
  SubdivisionEditorSteps,
  SubdivisionEditorSubStep,
} from 'subdivisionEditor/navigation/types';
import {
  SurveyAdminMode,
  SurveyOverviewSearchParams,
} from 'surveyAdmin/navigation/types';
import { InventoryNavigationState } from '../inventory/inventoryNavigation$';
import { ScenarioModuleRoute, WorkspaceModuleRoute } from './appRouterTypes';

export const navigateToSurveyAdmin = actionCreator<{
  surveyMode: SurveyAdminMode;
  surveyId: ArdoqId | null;
  surveyOverviewSearchParams?: SurveyOverviewSearchParams;
}>('[navigationActions] NAVIGATE_TO_SURVEY_ADMIN');

export type NavigateToReportModulePayload = {
  reportId?: ArdoqId;
  filters?: ReportFilters;
};

export const navigateToReportReader =
  actionCreator<NavigateToReportModulePayload>(
    '[navigationActions] NAVIGATE_TO_REPORT_READER'
  );

export type NavigateToReportBuilderPayload =
  | NavigateToReportModulePayload
  | AdHocSearchReport;

export const navigateToReportBuilder =
  actionCreator<NavigateToReportBuilderPayload>(
    '[navigationActions] NAVIGATE_TO_REPORT_BUILDER'
  );

export const navigateToReportOverview = actionCreator(
  '[navigationActions] NAVIGATE_TO_REPORT_OVERVIEW'
);

export const navigateToSearch = actionCreator<{
  searchId: ArdoqId | null;
  searchPane: SearchPane;
}>('[navigationActions] NAVIGATE_TO_SEARCH');

export const navigateToMetamodel = actionCreator<{
  metamodelId?: ArdoqId;
  metamodelPane: MetamodelPane;
}>('[navigationActions] NAVIGATE_TO_METAMODEL');

export const setPreviousAppModule = actionCreator<AppModules>(
  '[navigationActions] SET_PREVIOUS_APP_MODULE'
);

export const navigateToOrganizationMetamodel =
  actionCreator<OrganizationMetamodelRoutes.Location>(
    '[navigationActions] NAVIGATE_TO_ORGANIZATION_METAMODEL'
  );

export const selectOrganizationMetamodelWorkspace =
  actionCreator<ArdoqId | null>(
    '[navigationActions] NAVIGATE_TO_ORGANIZATION_METAMODEL_WORKSPACE'
  );

export const selectOrganizationMetamodelTab = actionCreator<TabId>(
  '[navigationActions] NAVIGATE_TO_ORGANIZATION_METAMODEL_TAB'
);

export const selectOrganizationMetamodelEntity =
  actionCreator<MetamodelEntityId>(
    '[navigationActions] SELECT_ORGANIZATION_METAMODEL_ENTITY'
  );

export const navigateToSubdivision = actionCreator<{
  subdivisionId: ArdoqId;
  subdivisionEditorStep?: SubdivisionEditorSteps;
  subdivisionEditorSubStep?: SubdivisionEditorSubStep;
}>('[navigationActions] NAVIGATE_TO_SUBDIVISION');

export const navigateToBroadcastOverview = actionCreator(
  '[navigationActions] NAVIGATE_TO_BROADCAST_OVERVIEW'
);

export const navigateToViewpoints = actionCreator(
  '[navigationActions] NAVIGATE_TO_VIEWPOINTS'
);

/**
 * Navigate to viewpoints in non-legacy mode
 */
export const navigateToNewViewpoints = actionCreator(
  '[navigationActions] NAVIGATE_TO_NEW_VIEWPOINTS'
);

export const initiateNavigationToNewBroadcastForm =
  actionCreator<ArdoqId | null>(
    '[navigationActions] INITIATE_NAVIGATION_TO_NEW_BROADCAST_FORM'
  );

export const navigateToNewBroadcastForm = actionCreator<ArdoqId | null>(
  '[navigationActions] NAVIGATE_TO_NEW_BROADCAST_FORM'
);

export const initiateNavigationToEditBroadcastForm = actionCreator<ArdoqId>(
  '[navigationActions] INITIATE_NAVIGATION_TO_EDIT_BROADCAST_FORM'
);

export const navigateToEditBroadcastForm = actionCreator<ArdoqId>(
  '[navigationActions] NAVIGATE_TO_EDIT_BROADCAST_FORM'
);

export const navigateToManageOrganization = actionCreator<{
  manageOrgTab: OrgTabs;
}>('[navigationActions] NAVIGATE_TO_MANAGE_ORGANIZATION');

export const navigateToAccessControlPage = actionCreator<AccessControlRoute>(
  '[navigationActions] NAVIGATE_TO_ACCESS_CONTROL_PAGE'
);

export const initiateNavigationToActiveBroadcastForm = actionCreator(
  '[navigationActions] INITIATE_NAVIGATION_TO_ACTIVE_BROADCAST_FORM'
);

export const navigateToActiveBroadcastForm = actionCreator(
  '[navigationActions] NAVIGATE_TO_ACTIVE_BROADCAST_FORM'
);

export const loadWorkspaceModuleRoute = actionCreator<WorkspaceModuleRoute>(
  '[navigationActions] LOAD_WORKSPACE_MODULE_ROUTE'
);

export const loadScenarioModuleRoute = actionCreator<ScenarioModuleRoute>(
  '[navigationActions] LOAD_SCENARIO_MODULE_ROUTE'
);

export const navigateToViewpointForm = actionCreator<ArdoqId | null>(
  '[navigationActions] NAVIGATE_TO_VIEWPOINT_FORM'
);

export const navigateToDefaultViewpoints = actionCreator(
  '[navigationActions] NAVIGATE_TO_DEFAULT_VIEWPOINTS'
);
export type NavigateToDashboardModulePayload = {
  selectedDashboardId?: ArdoqId;
  dashboardModule: DashboardModule;
  loadFromCache?: boolean;
  presentationId?: ArdoqId;
};
export const navigateToDashboardModule =
  actionCreator<NavigateToDashboardModulePayload>(
    '[navigationActions] NAVIGATE_TO_DASHBOARD_BUILDER'
  );

export const navigateToAuditLog = actionCreator<{
  entityType: AuditLogEntityType;
  entities: Array<{ id: ArdoqId; name: string }>;
} | null>('[navigationActions] NAVIGATE_TO_AUDIT_LOG');

export const navigateToComponentOverviewPage = actionCreator<ArdoqId>(
  '[navigationActions] NAVIGATE_TO_COMPONENT_OVERVIEW_PAGE'
);

export const navigateToAnalyticsOverview = actionCreator(
  '[navigationActions] NAVIGATE_TO_ANALYTICS_OVERVIEW'
);

export const inventoryModuleWasSelected =
  actionCreator<InventoryNavigationState>(
    '[navigationActions] INVENTORY_MODULE_WAS_SELECTED'
  );
