import { SCIMToken } from '@ardoq/api';
import {
  ExtractPayload,
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';

export type ScimManagementState = {
  hasToken: boolean;
  recentlyGeneratedToken: SCIMToken | null;
  errorMessage: string | null;
};

const defaultState: ScimManagementState = {
  hasToken: false,
  recentlyGeneratedToken: null,
  errorMessage: null,
};

export const setScimError = actionCreator<
  Pick<ScimManagementState, 'errorMessage'>
>('[scimManagement] Set error');
const handleSetScimAlert = (
  state: ScimManagementState,
  { errorMessage }: ExtractPayload<typeof setScimError>
) => ({ ...state, errorMessage });
const setScimAlertReducer = reducer(setScimError, handleSetScimAlert);

export const setGeneratedToken = actionCreator<{ token: SCIMToken }>(
  '[scimManagement] Set generated token'
);
const handleSetGeneratedToken = (
  state: ScimManagementState,
  { token }: ExtractPayload<typeof setGeneratedToken>
) =>
  ({
    ...state,
    recentlyGeneratedToken: token,
    hasToken: true,
  }) satisfies ScimManagementState;
const setGeneratedTokenReducer = reducer<
  ScimManagementState,
  ExtractPayload<typeof setGeneratedToken>
>(setGeneratedToken, handleSetGeneratedToken);

export const setHasToken = actionCreator<{ hasToken: boolean }>(
  '[scimManagement] Set has token'
);
const handleSetHasToken = (
  state: ScimManagementState,
  { hasToken }: ExtractPayload<typeof setHasToken>
) => ({ ...state, hasToken });
const setHasTokenReducer = reducer(setHasToken, handleSetHasToken);

export const setTokenDeleted = actionCreator(
  '[scimManagement] Set token deleted'
);
const handleSetTokenDeleted = (state: ScimManagementState) => ({
  ...state,
  recentlyGeneratedToken: null,
  hasToken: false,
  alert: null,
});
const setTokenDeletedReducer = reducer<ScimManagementState>(
  setTokenDeleted,
  handleSetTokenDeleted
);

export const resetScimState = actionCreator('[scimManagement] Reset state');
const handleResetScimState = () => defaultState;
const resetScimStateReducer = reducer<ScimManagementState>(
  resetScimState,
  handleResetScimState
);

export const generateToken = actionCreator('[scimManagement] Generate token');

export const checkIfTokenExists = actionCreator(
  '[scimManagement] Check if token exists'
);

export const deleteToken = actionCreator('[scimManagement] Delete token');

export default persistentReducedStream<ScimManagementState>(
  'scimManagement$',
  defaultState,
  [
    setScimAlertReducer,
    setGeneratedTokenReducer,
    setHasTokenReducer,
    setTokenDeletedReducer,
    resetScimStateReducer,
  ]
);
