import {
  routine,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  upsertConnectionInitiate,
  updateConnectionStatuses,
} from 'integrations/common/streams/connections/actions';
import { startUpsertConnectionModal } from 'integrations/signavio-exporter/pages/Overview/components/UpserAccountModal';
import { filter, tap } from 'rxjs';

const handleUpsertConnectionInitiate = routine(
  ofType(upsertConnectionInitiate),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'signavio-exporter'),
  tap(({ integrationId, connection }) => {
    startUpsertConnectionModal(connection?._id).then(() => {
      dispatchAction(
        updateConnectionStatuses({
          integrationId,
          statuses: { upsert: { status: 'INIT' } },
        })
      );
    });
  })
);
export default [handleUpsertConnectionInitiate];
