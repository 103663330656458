import { RestoreDeletedViewModel } from 'restoreDeleted/types';
import DeletedEntitiesSectionWithCollapsible from './DeletedEntitiesSectionWithCollapsible';
import EmptyRestoreView from './EmptyRestoreView';
import ErrorWithRetry from './ErrorWithRetry';
import RestoreViewLoading from './RestoreViewLoading';

const MainSection = ({
  components,
  references,
  isShowingLoadingIndicator,
  hasFetched,
  hasFetchingError,
  fetchRetry,
}: RestoreDeletedViewModel) => {
  if (isShowingLoadingIndicator) return <RestoreViewLoading />;
  if (hasFetchingError) {
    return (
      <ErrorWithRetry
        message="Something went wrong while loading the data. Please try again."
        retryAction={fetchRetry}
      />
    );
  }
  if (!hasFetched) return <div />;
  if (!components.length && !references.length) {
    return <EmptyRestoreView />;
  }
  return (
    <div>
      <DeletedEntitiesSectionWithCollapsible
        entityType="component"
        entities={components}
      />
      <DeletedEntitiesSectionWithCollapsible
        entityType="reference"
        entities={references}
      />
    </div>
  );
};

export default MainSection;
