import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { CidArgs } from '@ardoq/common-helpers';

export interface ReferenceIdsPayload {
  readonly referenceIds: ArdoqId[];
}

export const notifyReferencesRemoved = actionCreator<ReferenceIdsPayload>(
  '[ReferenceActions] NOTIFY_REFERENCES_REMOVED'
);

export const notifyReferencesAdded = actionCreator<ReferenceIdsPayload>(
  '[ReferenceActions] NOTIFY_REFERENCES_ADDED'
);

export const notifyReferencesUpdated = actionCreator<ReferenceIdsPayload>(
  '[ReferenceActions] NOTIFY_REFERENCES_UPDATED'
);

export const notifyReferenceSourceOrTargetUpdated =
  actionCreator<ReferenceIdsPayload>(
    '[ReferenceActions] NOTIFY_REFERENCE_SOURCE_OR_TARGET_UPDATED'
  );

export const notifyReferencesReset = actionCreator(
  '[ReferenceActions] NOTIFY_REFERENCES_RESET'
);

export const toggleReferenceDescription = actionCreator<CidArgs>(
  '[ReferenceActions] TOGGLE_REFERENCE_DESCRIPTION'
);

export const selectReference = actionCreator<CidArgs>(
  '[ReferenceActions] SELECT_REFERENCE'
);

export const deleteReference = actionCreator<ArdoqId>(
  '[ReferenceActions] REFERENCE_DELETE'
);

export const reverseReference = actionCreator<ArdoqId>(
  '[ReferenceActions] REFERENCE_REVERSE'
);

export const toggleLockReference = actionCreator<ArdoqId>(
  '[ReferenceActions] REFERENCE_TOGGLE_LOCK_REFERENCE'
);

export const notifyReferenceLocked = actionCreator<ArdoqId>(
  '[ComponentActions] NOTIFY_REFERENCE_LOCKED'
);

export const notifyReferenceUnlocked = actionCreator<ArdoqId>(
  '[ComponentActions] NOTIFY_REFERENCE_UNLOCKED'
);
