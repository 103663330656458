import { viewpointBuilderNavigationOperations } from 'viewpointBuilder/viewpointBuilderNavigation/viewpointBuilderNavigationOperations';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  tap,
  withLatestFrom,
} from 'rxjs';
import { initialSearchComponentViewState } from 'viewpointBuilder/selectContextTab/toViewState';
import { editTraversals$ } from 'viewpointBuilder/traversals/editTraversals$';
import { dispatchAction } from '@ardoq/rxbeach';
import { viewpointBuilderCommands } from 'viewpointBuilder/viewpointBuilderCommands';
import { isEqual, sumBy } from 'lodash';
import {
  requestFetchInstanceCounts,
  updateTraversalStartContext,
} from 'viewpointBuilder/traversals/editTraversalActions';
import { componentSearch$ } from 'viewpointBuilder/selectContextTab/componentSearch$';
import {
  ViewpointBuilderAdvancedSearchState,
  ViewpointBuilderAdvancedSearchValidState,
} from 'viewpointBuilder/selectContextTab/types';
import { prototypeInterface } from 'modelInterface/prototype/prototypeInterface';
import {
  setIsLoading,
  setLoadedComponents,
} from 'viewpointBuilder/selectContextTab/actions';
import { QueryBuilderQuery } from '@ardoq/api-types';
import {
  TraversalBuilderViewState,
  ViewpointBuilderContext,
} from 'viewpointBuilder/types';
import {
  collapsedPathTabUnselected,
  setCollapsedPathsCount,
  setFormattingRulesCount,
  setGraphCountsAndComponentTypeRepresentation,
  setGroupingRulesCount,
  setRequiredComponentsCount,
  setSavedViewpointDetails,
  setSelectedComponentTypeAndCount,
} from 'viewpointBuilder/viewpointBuilderNavigation/actions';
import { ComponentTypeProps } from '../components/ComponentType';
import { viewpointFormatting$ } from '../formattingTabs/viewpointFormatting$';
import { viewpointGroupingRules$ } from '../addGroupingRulesTab/viewpointGroupingRules$';
import { viewpointBuilderNavigation$ } from '../viewpointBuilderNavigation/viewpointBuilderNavigation$';
import { viewpointGroupingRulesOperations } from '../addGroupingRulesTab/viewpointGroupingRulesOperations';
import { getFormattingRulesCount } from '../formattingTabs/getFormattingRulesCount';
import { getRepresentationDataWithColorVariants } from 'viewpointBuilder/components/getRepresentationDataWithColorVariants';
import { metaInfo$ } from '../viewpointMetaInfo/viewpointMetaInfo$';
import { setGraphData } from '../viewpointMetaInfo/metaInfoActions';
import { isSavedPathCollapsingRule } from '../collapsePathsTab/types';

export const getUpdatePerspectivesOptionsRoutine = (
  context: ViewpointBuilderContext
) => {
  if (
    viewpointBuilderNavigationOperations.getModeFromContext(context) ===
    'advanced'
  ) {
    return editTraversals$.pipe(
      map(({ perspectivesOptionsArgs }) => perspectivesOptionsArgs),
      distinctUntilChanged(isEqual),
      tap(args => {
        viewpointBuilderCommands.groupingsCommands.getGroupingOptions(args);
        viewpointBuilderCommands.formattingCommands.getFormattingOptions(args);
      })
    );
  }
  return { subscribe: () => ({ unsubscribe: () => null }) };
};

export const navigationTabDetailsOnSelectedComponentsRoutine =
  componentSearch$.pipe(
    map(
      ({
        selectedStartComponentTypeNames,
        canSelectOnlyComponentType,
        componentType,
        selectedComponentsCount,
      }) => {
        return {
          selectedComponentTypes: canSelectOnlyComponentType
            ? [componentType].filter(Boolean)
            : selectedStartComponentTypeNames,
          selectedComponentCount: selectedComponentsCount,
        };
      }
    ),
    distinctUntilChanged(isEqual),
    tap(payload => dispatchAction(setSelectedComponentTypeAndCount(payload)))
  );

export const navigationTabDetailsOnMetaInfoRoutine = metaInfo$.pipe(
  map(({ name }) => {
    return {
      viewpointName: name,
    };
  }),
  distinctUntilChanged(isEqual),
  tap(payload => dispatchAction(setSavedViewpointDetails(payload)))
);

export const graphDataForMetaInfoVisualisationRoutine = editTraversals$.pipe(
  map(
    ({
      graphNodes,
      graphEdges,
      graphGroups,
      stateAsSavableAttributes: { paths },
    }) => {
      return {
        graphNodes,
        graphEdges,
        graphGroups,
        paths,
      };
    }
  ),
  distinctUntilChanged(isEqual),
  tap(payload => dispatchAction(setGraphData(payload)))
);

export const navigationTabDetailsOnGraphRoutine = editTraversals$.pipe(
  map(({ filters, graphEdges, graphNodes, startComponentType, startNode }) => {
    return {
      filtersCount: sumBy(
        Object.values(filters),
        filtersPerNode => filtersPerNode.filters.length
      ),
      edgesCount: graphEdges.size,
      nodesCount: graphNodes.size,
      selectedComponentTypeRepresentation:
        getComponentTypeRepresentationForStartContext(
          startComponentType,
          startNode
        ),
    };
  }),
  distinctUntilChanged(isEqual),
  tap(payload =>
    dispatchAction(setGraphCountsAndComponentTypeRepresentation(payload))
  )
);

const getComponentTypeRepresentationForStartContext = (
  startComponentType: TraversalBuilderViewState['startComponentType'],
  startNode: TraversalBuilderViewState['startNode']
): ComponentTypeProps | null => {
  if (!startComponentType || !startNode?.getRepresentationData()) {
    return null;
  }

  const representationData = startNode!.getRepresentationData()!;

  return {
    label: startComponentType,
    representationData:
      getRepresentationDataWithColorVariants(representationData),
  };
};

export const navigationTabDetailsOnFormattingRoutine =
  viewpointFormatting$.pipe(
    map(getFormattingRulesCount),
    withLatestFrom(viewpointBuilderNavigation$),
    tap(
      ([
        count,
        { labelFormattingRulesCount, conditionalFormattingRulesCount },
      ]) => {
        if (
          !isEqual(count, {
            labelFormattingRulesCount,
            conditionalFormattingRulesCount,
          })
        ) {
          dispatchAction(setFormattingRulesCount(count));
        }
      }
    )
  );

export const navigationTabDetailsOnGroupingRoutine =
  viewpointGroupingRules$.pipe(
    map(state => {
      return {
        groupingRulesCount:
          viewpointGroupingRulesOperations.getGroupingRulesCount(state),
      };
    }),
    distinctUntilChanged(isEqual),
    tap(count => dispatchAction(setGroupingRulesCount(count)))
  );

export const updateInstanceCountsRoutine = editTraversals$.pipe(
  map(
    ({
      stateAsSavableAttributes,
      traversalStartQuery,
      traversalStartSet,
      shouldIncludeInstanceCounts,
    }) => {
      return {
        ...stateAsSavableAttributes,
        startSet: traversalStartSet,
        startQuery: traversalStartQuery,
        shouldIncludeInstanceCounts,
      };
    }
  ),
  distinctUntilChanged(isEqual),
  // To account for user interactions.
  debounceTime(300),
  tap(
    ({
      startQuery,
      startSet,
      paths,
      filters,
      startSetFilterId,
      pathMatching,
      shouldIncludeInstanceCounts,
    }) => {
      if (!shouldIncludeInstanceCounts) {
        return;
      }

      if ((startSet && startSet.length > 0) || startQuery) {
        dispatchAction(
          requestFetchInstanceCounts({
            startSet,
            startQuery,
            paths,
            filters,
            startSetFilterId,
            pathMatching,
          })
        );
      }
    }
  )
);

/**
 * The search state stream defines the start set and type for the traversal.
 * This subscription listens to changes in the search state and updates the
 * traversal start context.
 * The search state is persistent, so it will get reset to the initial state.
 * We need to ignore that iteration, it could screw up the traversal, e.g.
 * clear the traversal on editing a traversal block.
 */
export const updateTraversalStartContextRoutine = componentSearch$.pipe(
  filter(state => !isEqual(state, initialSearchComponentViewState)),
  map(
    ({
      selectedComponentIds,
      startQuery,
      isApplyingTraversalDisabled,
      componentType,
      canSelectOnlyComponentType,
      selectedStartComponentTypeNames,
    }) => {
      const startComponentType =
        selectedStartComponentTypeNames[0] ?? componentType;

      if (canSelectOnlyComponentType && startComponentType) {
        return {
          traversalStartSet: [],
          traversalStartQuery: null,
          startComponentType,
        };
      }

      if (isApplyingTraversalDisabled || !startComponentType)
        return {
          traversalStartSet: null,
          traversalStartQuery: null,
          startComponentType: null,
        };

      return {
        traversalStartSet: startQuery ? [] : selectedComponentIds,
        traversalStartQuery: startQuery ?? null,
        startComponentType,
      };
    }
  ),
  distinctUntilChanged(isEqual),
  tap(state => dispatchAction(updateTraversalStartContext(state)))
);

const isValidAdvancedSearch = (
  state: ViewpointBuilderAdvancedSearchState
): state is ViewpointBuilderAdvancedSearchValidState =>
  state?.status === 'valid' && state.query !== null;

export const updateAdvancedSearchQueryRoutine = componentSearch$.pipe(
  map(({ advancedSearchState }) => advancedSearchState),
  distinctUntilChanged(isEqual),
  filter(isValidAdvancedSearch),
  map(({ query }) => query),
  debounceTime(300),
  tap(async (query: QueryBuilderQuery) => {
    dispatchAction(setIsLoading(true));

    const { results, totalCount } =
      await prototypeInterface.loadComponentsFromQueryBuilder(query);

    dispatchAction(
      setLoadedComponents({
        loadedComponents: results,
        totalCount,
      })
    );
    dispatchAction(setIsLoading(false));
  })
);

export const navigationTabDetailsOnCollapsedPaths = editTraversals$.pipe(
  map(({ pathCollapsingViewState: { rules } }) => rules),
  distinctUntilChanged(isEqual),
  tap(rules => {
    const collapsedPathsCount = rules.filter(isSavedPathCollapsingRule).length;
    dispatchAction(setCollapsedPathsCount({ collapsedPathsCount }));
  })
);

export const navigationTabDetailsOnRequiredComponents = editTraversals$.pipe(
  map(({ requiredNodeIds }) => requiredNodeIds),
  distinctUntilChanged(isEqual),
  tap(requiredNodeIds => {
    const requiredComponentsCount = requiredNodeIds.length;
    dispatchAction(setRequiredComponentsCount({ requiredComponentsCount }));
  })
);

export const navigationTabDetailsOnCollapsedPathsUnselect =
  viewpointBuilderNavigation$.pipe(
    map(({ selectedTab }) => selectedTab),
    pairwise(),
    tap(([prevTab, currentTab]) => {
      if (
        prevTab === 'COLLAPSE_PATHS_TAB' &&
        currentTab !== 'COLLAPSE_PATHS_TAB'
      ) {
        dispatchAction(collapsedPathTabUnselected());
      }
    })
  );
