import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import presentations$ from 'streams/presentations/presentations$';
import currentUser$ from 'streams/currentUser/currentUser$';
import { getPresentationOptions } from './utils';
import { PresentationModalOption } from './AddPresentationDialog';

export type ComposeMessagesFormStreamShape = {
  presentationOptions: PresentationModalOption[];
  currentUserEmail: string | null;
};

const composeMessagesForm$ = combineLatest([presentations$, currentUser$]).pipe(
  map(([presentations, currentUser]) => {
    return {
      presentationOptions: getPresentationOptions(presentations.byId),
      currentUserEmail: currentUser.email,
    };
  })
);

export default composeMessagesForm$;
