import { DoqType, DoqWithSideContent } from '@ardoq/doq';
import { Link } from '@ardoq/typography';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { FlexBox } from '@ardoq/layout';

type EmptyStateProps = {
  hasSearchResults?: boolean;
  term: string;
  selectedComponentTypeHasNoInstances: boolean;
  selectedType: string;
};

export const ComponentSearchEmptyState = ({
  hasSearchResults,
  term,
  selectedComponentTypeHasNoInstances,
  selectedType,
}: EmptyStateProps) => {
  return (
    <FlexBox justify="center" align="center" flex={1}>
      {selectedComponentTypeHasNoInstances && (
        <NoInstancesForType selectedType={selectedType} />
      )}
      {!selectedComponentTypeHasNoInstances && hasSearchResults && (
        <NoResult term={term} />
      )}
      {!hasSearchResults && <HowToUseGuide />}
    </FlexBox>
  );
};

const HowToUseGuide = () => {
  return (
    <DoqWithSideContent doqType={DoqType.WAITING} title="How this works">
      <p>First select one or more context components. Next, you can</p>
      <ul>
        <li>
          Open just the selected components without opening the entire workspace
          or
        </li>
        <li>
          Select references to see other component types linked to the context
          component
        </li>
        <li>Add filters to narrow down the components in your dataset</li>
      </ul>
      <Link href={KnowledgeBaseLink.VIEWPOINT_BUILDER_PHOENIX} target="_blank">
        Learn more about the new Ardoq Viewpoints
      </Link>
    </DoqWithSideContent>
  );
};

const NoResult = ({ term }: { term: string }) => {
  return (
    <DoqWithSideContent doqType={DoqType.MISSING_ORG} title="No results found">
      <p>
        There is no component with the name <strong>&quot;{term}&quot;</strong>.
        <br /> Try searching for a different component or change your component
        type filter.
      </p>
    </DoqWithSideContent>
  );
};

const NoInstancesForType = ({ selectedType }: { selectedType: string }) => {
  return (
    <DoqWithSideContent
      doqType={DoqType.MISSING_ORG}
      title="No components available"
    >
      <p>
        {`There are no components of type "${selectedType}".
          Try searching for a different component type.`}
      </p>
    </DoqWithSideContent>
  );
};
