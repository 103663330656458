import { setClassNames } from '../utils/utils';
import { ArdoqId, ReferenceDirection } from '@ardoq/api-types';
import {
  LinkUpdatePayload,
  SetLinkSourcePayload,
} from '../actions/navigatorActions';
import layout from '../models/layout';
import { NavigatorLayoutState, NavigatorViewInterface } from '../types';
import Tree from '../models/tree';

const shouldShowRefDrawingCalc = (
  linkSourceIds: ArdoqId[] | null,
  isLinking: boolean,
  refDirection: string | null
) => {
  if (!linkSourceIds || linkSourceIds.length === 1) {
    return isLinking;
  }
  return isLinking && refDirection !== ReferenceDirection.UNSET;
};

const handleSetLinkSource = (
  state: NavigatorLayoutState,
  {
    linkSourceNodeId,
    linkSourceIds,
    startPosition,
    isStartInNavigator,
    refDirection,
  }: SetLinkSourcePayload
): NavigatorLayoutState => {
  let { layoutNodeSet } = state;
  const { height, scrollTop, dragTargetNode, navigatorViewInterface } = state;

  let linkSourceNode = null;

  const multiselect = linkSourceIds.length > 1;

  if (
    isStartInNavigator &&
    linkSourceNodeId &&
    state.tree.hasNode(linkSourceNodeId)
  ) {
    linkSourceNode = state.tree.getNode(linkSourceNodeId);
    layoutNodeSet = new Set(layoutNodeSet);
    layoutNodeSet.add(linkSourceNode.id);
  }

  const existingRefs =
    (!multiselect &&
      getIncomingAndOutgoingReferencesOfComponent(
        navigatorViewInterface,
        linkSourceIds[0]
      )) ||
    null;

  const shouldShowRefDrawing = shouldShowRefDrawingCalc(
    linkSourceIds,
    true,
    refDirection
  );

  // TODO only keep the link state which is really needed
  // startPosition
  return {
    ...state,
    layoutNodeSet,
    isLinking: true,
    isExternalStart: !isStartInNavigator,
    linkSourceIds,
    linkSourceNode,
    linkSourceStartPosition: startPosition,
    existingRefs,
    rootLayoutBox: layout(
      state.tree,
      height,
      scrollTop,
      layoutNodeSet,
      setClassNames({
        dragTargetNode,
        linkSourceNode,
        existingRefs,
        multiselect,
        shouldShowRefDrawing,
      }),
      state.showFilteredComponents
    ),
    refDirection,
    shouldShowRefDrawing,
  };
};

const getIncomingAndOutgoingReferencesOfComponent = (
  navigatorViewInterface: NavigatorViewInterface,
  componentId: ArdoqId
): {
  outgoing: Set<string>;
  incoming: Set<string>;
} => {
  const linkedSourceComponents =
    navigatorViewInterface.getLinkedSourceComponents(componentId);
  const inkedTargetComponents =
    navigatorViewInterface.getLinkedTargetComponents(componentId);
  return {
    outgoing: new Set(
      linkedSourceComponents.map(({ componentId }) => componentId)
    ),
    incoming: new Set(
      inkedTargetComponents.map(({ componentId }) => componentId)
    ),
  };
};

const getHasLinkTarget = (
  target: Element | null,
  refDirection: ReferenceDirection | null,
  tree: Tree
) => {
  if (!target || refDirection === ReferenceDirection.UNSET) {
    return false;
  }
  return Boolean(tree.getNodeByDomNode(target));
};

const handleLinkUpdate = (
  state: NavigatorLayoutState,
  { linkTarget }: LinkUpdatePayload
): NavigatorLayoutState => {
  const hasLinkTarget = getHasLinkTarget(
    linkTarget,
    state.refDirection,
    state.tree
  );
  if (hasLinkTarget === state.hasLinkTarget) {
    return state;
  }
  const {
    linkSourceIds,
    height,
    scrollTop,
    layoutNodeSet,
    refDirection,
    dragTargetNode,
    linkSourceNode,
    existingRefs,
  } = state;
  const multiselect = linkSourceIds && linkSourceIds.length > 1;
  const shouldShowRefDrawing = shouldShowRefDrawingCalc(
    linkSourceIds,
    true,
    refDirection
  );

  const rootLayoutBox = layout(
    state.tree,
    height,
    scrollTop,
    layoutNodeSet,
    setClassNames({
      dragTargetNode,
      linkSourceNode,
      existingRefs,
      multiselect,
      shouldShowRefDrawing,
      hasLinkTarget,
    }),
    state.showFilteredComponents
  );
  return {
    ...state,
    hasLinkTarget,
    rootLayoutBox,
  };
};

const handleSetLinkTarget = (
  state: NavigatorLayoutState
): NavigatorLayoutState => {
  const { height, scrollTop, dragTargetNode, linkSourceNode } = state;
  const layoutNodeSet = new Set(state.layoutNodeSet);
  if (linkSourceNode) {
    layoutNodeSet.delete(linkSourceNode.id);
  }
  const existingRefs = {
    outgoing: new Set<string>(),
    incoming: new Set<string>(),
  };
  return {
    ...state,
    isLinking: false,
    linkSourceNode: null,
    linkSourceStartPosition: null,
    layoutNodeSet,
    existingRefs,
    rootLayoutBox: layout(
      state.tree,
      height,
      scrollTop,
      layoutNodeSet,
      setClassNames({ dragTargetNode, existingRefs }),
      state.showFilteredComponents
    ),
    refDirection: null,
    shouldShowRefDrawing: shouldShowRefDrawingCalc(null, false, null),
  };
};

export const createReferencesOperations = {
  handleSetLinkSource,
  handleLinkUpdate,
  handleSetLinkTarget,
};
