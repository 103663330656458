import {
  APIResourcePermissionAttributes,
  PermissionOrgGroup,
} from '@ardoq/api-types';
import { PermissionState } from './types';

const setLoadingState = (currentState: PermissionState) => ({
  ...currentState,
  isLoading: true,
});

const resetLoadingState = (currentState: PermissionState) => ({
  ...currentState,
  isLoading: false,
});

const updateAllPermissionsState = (
  currentState: PermissionState,
  permissions: APIResourcePermissionAttributes[] | null
) => {
  if (!permissions) {
    return { ...currentState };
  }

  return {
    ...currentState,
    permissionsByZoneId: permissions.reduce(
      (result, permission) => ({
        ...result,
        [permission.resource]: permission,
      }),
      {}
    ),
  };
};

const updatePermissionState = (
  currentState: PermissionState,
  permission: APIResourcePermissionAttributes | null
) => {
  if (!permission) {
    return { ...currentState };
  }

  return {
    ...currentState,
    permissionsByZoneId: {
      ...currentState.permissionsByZoneId,
      [permission.resource]: permission,
    },
  };
};

const updateAllPermissionsStateAfterLoading = (
  currentState: PermissionState,
  permissions: APIResourcePermissionAttributes[] | null
) => {
  const updatedState = resetLoadingState(currentState);
  return updateAllPermissionsState(updatedState, permissions);
};

const getPermissionGroupNameWithoutOrganization = (
  groupName: PermissionOrgGroup | string
) => groupName.split('/')[1] || groupName.split('/')[0];

export const zonePermissionsOperations = {
  setLoadingState,
  resetLoadingState,
  updateAllPermissionsState,
  updateAllPermissionsStateAfterLoading,
  updatePermissionState,
  getPermissionGroupNameWithoutOrganization,
};
