import { RefObject } from 'react';
import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import { TablesSelection } from '@ardoq/integrations';
import { viewModel$ } from './viewModel$';
import { TableSelectorProps } from './types';

const TableSelector = ({
  tables = [],
  selectionState,
  tablesStatus,
  onSelectTable,
  onUnselectTable,
  selectionRef,
}: TableSelectorProps & {
  selectionRef: RefObject<HTMLDivElement>;
}) => {
  return (
    <TablesSelectionContainer>
      <TablesSelection
        dataSource={tables}
        onSelectTable={onSelectTable}
        onUnselectTable={onUnselectTable}
        selectedRowId={selectionState.currentTableId}
        selectedTables={selectionState.tables}
        disabled={tablesStatus === 'INIT' || tablesStatus === 'LOADING'}
        selectionRef={selectionRef}
      />
    </TablesSelectionContainer>
  );
};

const TablesSelectionContainer = styled.div`
  flex: 1;
  min-width: 400px;
`;

export default connect(TableSelector, viewModel$);
