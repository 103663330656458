import styled from 'styled-components';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import MediaTitle from './MediaTitle';
import { normal14 } from '@ardoq/typography';

const MediaHeaderContainer = styled.div`
  ${normal14};
  line-height: inherit;
  font-size: inherit;
  display: flex;
`;

const CheckboxContainer = styled.span`
  margin-left: auto;
`;

const Checkbox = styled.input`
  cursor: pointer;
  margin: 0;
`;

interface MediaHeaderProperties {
  title: string;
  viewStatus: MetaInfoViewStatus;
  isSelected: boolean;
  toggleView: () => any;
}
const MediaHeader = ({
  title,
  viewStatus,
  isSelected,
  toggleView,
}: MediaHeaderProperties) => (
  <MediaHeaderContainer>
    <MediaTitle title={title} viewStatus={viewStatus} />
    <CheckboxContainer>
      <Checkbox
        type="checkbox"
        checked={isSelected}
        onClick={e => e.stopPropagation()}
        onChange={toggleView}
      />
    </CheckboxContainer>
  </MediaHeaderContainer>
);
export default MediaHeader;
