import styled from 'styled-components';
import FieldQuestion from './FieldQuestion';
import ReferenceQuestion from './ReferenceQuestion';
import TextSection from './TextSection';
import HiddenField from './HiddenField';
import surveyEditor$ from 'surveyAdmin/SurveyEditor/streams/surveyEditor$';
import { connect } from '@ardoq/rxbeach';
import { isEntity, SurveyQuestionType } from '@ardoq/api-types';
import { ErrorIcon, IconName } from '@ardoq/icons';
import {
  componentAttributes,
  fieldList,
  getBarColor,
  getSubtitle,
  hasAISuggestions,
  hasQuestionValidationOfSeverity,
  questionHasBeenClosed,
  referenceAttributes,
} from './utils';
import { colors, s4 } from '@ardoq/design-tokens';
import { SortableQuestionProps } from './types';
import { confirmQuestionDeletionModal } from 'surveyAdmin/SurveyModals';
import SurveyIslandRightContent from './SurveyIslandRightContent';
import { ClickableIslandHeader } from '@ardoq/snowflakes';
import TagQuestion from './TagQuestion';
import { StatusType, Tag } from '@ardoq/status-ui';
import { isRequiredQuestion } from '../SurveyEditor/utils';
import { IslandBody, IslandContainer } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';
import { Features, hasFeature } from '@ardoq/features';
import { SurveyErrorName } from 'surveyAdmin/consts';
import { validationHelpers } from 'surveyAdmin/surveyValidation';
import {
  isConditionalQuestion,
  isHiddenFieldQuestion,
  isReferenceQuestion,
} from './questionPredicates';
import { AllPossibleQuestions } from 'surveyAdmin/types';
import { map } from 'rxjs';

export const QuestionErrorIcon = styled(ErrorIcon)`
  && {
    line-height: 0.9;
    color: ${colors.red50};
    margin-left: ${s4};
  }
`;

const DependentQuestionTag = (
  <Tag iconName={IconName.CONDITIONAL}>Dependent question</Tag>
);

const RequiredTag = (
  <Tag iconName={IconName.REQUIREMENT}>Required question</Tag>
);

const AiSuggestionsEnabledTag = (
  <Tag
    leftIcon={{
      name: IconName.SPARKLES,
      color: colors.brand50,
    }}
    statusType={StatusType.NEW}
  >
    AI suggestions enabled
  </Tag>
);

const getTitle = (question: AllPossibleQuestions, index: number) => {
  if (isHiddenFieldQuestion(question)) {
    return `${index + 1}. ${getSubtitle(question)}`;
  }
  return `${index + 1}. ${question.label || getSubtitle(question)}`;
};

const SortableQuestion = ({
  question,
  QuestionBuilder,
  isComponent,
  typeId,
  modelId,
  workspaceId,
  validators,
  questions,
  questionValidations,
  parentQuestionIsReadonly,
  index,
  dragAndDropCardId,
  isExpanded,
  surveyValidation,
  surveyId,
  permissionsContext,
  allowComponentCreation,
  surveyIsPermissionDivisionsAware,
  hasSurveyResponseApprovalsFeature,
  updateQuestion,
  removeQuestion,
  handleOrderChange,
  expandQuestion,
  fieldHasBeenInteractedWith,
}: SortableQuestionProps) => {
  const attributeOptions = isComponent
    ? componentAttributes
    : referenceAttributes;

  let questionComponent;
  const fields = fieldList(isComponent, typeId, modelId);
  const dependentQuestions =
    questions?.flatMap(question =>
      isConditionalQuestion(question) ? question.properties.questions : []
    ) ?? [];
  if (
    question.type === SurveyQuestionType.FIELD ||
    question.type === SurveyQuestionType.ATTRIBUTE
  ) {
    questionComponent = (
      <FieldQuestion
        question={question}
        questions={[...(questions ?? []), ...dependentQuestions]}
        questionValidations={questionValidations}
        attributes={attributeOptions}
        workspaceId={workspaceId}
        fields={fields}
        updateQuestion={updateQuestion}
        parentQuestionIsReadonly={parentQuestionIsReadonly}
        surveyComponentTypeId={typeId}
        isComponent={isComponent}
        permissionsContext={permissionsContext}
        surveyWorkspaceId={workspaceId}
        allowComponentCreation={allowComponentCreation}
        surveyIsPermissionDivisionsAware={surveyIsPermissionDivisionsAware}
        hasSurveyResponseApprovalsFeature={hasSurveyResponseApprovalsFeature}
      />
    );
  } else if (question.type === SurveyQuestionType.REFERENCE) {
    questionComponent = (
      <ReferenceQuestion
        surveyWorkspaceId={workspaceId}
        surveyComponentTypeId={typeId}
        question={question}
        questionValidations={questionValidations}
        QuestionBuilder={QuestionBuilder}
        updateQuestion={updateQuestion}
        permissionsContext={permissionsContext}
        surveyIsPermissionDivisionsAware={surveyIsPermissionDivisionsAware}
        hasSurveyResponseApprovalsFeature={hasSurveyResponseApprovalsFeature}
      />
    );
  } else if (question.type === SurveyQuestionType.TEXT) {
    questionComponent = (
      <TextSection question={question} updateQuestion={updateQuestion} />
    );
  } else if (question.type === SurveyQuestionType.HIDDEN_FIELD) {
    questionComponent = (
      <HiddenField
        question={question}
        questions={questions}
        questionValidations={questionValidations}
        fields={fields}
        workspaceId={workspaceId}
        updateQuestion={updateQuestion}
        // @ts-expect-error TODO: reconcile differently typed validators properties
        validators={validators}
      />
    );
  } else if (question.type === SurveyQuestionType.TAG) {
    questionComponent = (
      <TagQuestion
        workspaceId={workspaceId}
        question={question}
        updateQuestion={updateQuestion}
        questionValidations={questionValidations}
        workspaceDoesNotExistError={
          surveyId &&
          validationHelpers.getErrorMessageIfExists(
            surveyValidation,
            SurveyErrorName.WORKSPACE_INVALID
          )
        }
        workspaceAccessError={validationHelpers.getErrorMessageIfExists(
          surveyValidation,
          SurveyErrorName.NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE
        )}
      />
    );
  }

  const hasDependentQuestions = isConditionalQuestion(question);
  const isRequired = isRequiredQuestion(question);

  const shouldShowAISuggestionsTag = isReferenceQuestion(question)
    ? hasAISuggestions(
        hasFeature(Features.AZURE_OPENAI_COMPONENT_LINKING_SUGGESTIONS),
        question?.aiSuggestionsEnabled
      )
    : false;

  const tags = (
    <FlexBox gap="small">
      {hasDependentQuestions && DependentQuestionTag}
      {isRequired && RequiredTag}
      {shouldShowAISuggestionsTag && AiSuggestionsEnabledTag}
    </FlexBox>
  );

  const isPersistedOrHasBeenClosed =
    isEntity(question) ||
    questionHasBeenClosed(fieldHasBeenInteractedWith, question);

  const hasErrors = hasQuestionValidationOfSeverity(
    questionValidations,
    'error'
  );

  const hasWarnings = hasQuestionValidationOfSeverity(
    questionValidations,
    'warning'
  );

  return (
    <IslandContainer>
      <ClickableIslandHeader
        errorMessage={
          isPersistedOrHasBeenClosed
            ? hasErrors
              ? 'Error'
              : undefined
            : undefined
        }
        warningMessage={
          isPersistedOrHasBeenClosed
            ? hasWarnings
              ? 'Warning'
              : undefined
            : undefined
        }
        barColor={getBarColor(question.type)}
        title={getTitle(question, index)}
        subtitle={getSubtitle(question)}
        dragAndDropCardId={dragAndDropCardId}
        onClick={expandQuestion}
        rightContent={
          <SurveyIslandRightContent
            dragAndDropCardId={dragAndDropCardId}
            isExpanded={isExpanded}
            handleOrderChange={handleOrderChange}
            setisExpanded={expandQuestion}
            removeQuestion={async () => {
              const confirmQuestionDeletion = isConditionalQuestion(question)
                ? Boolean(await confirmQuestionDeletionModal())
                : true;

              if (!confirmQuestionDeletion) return;

              removeQuestion();
            }}
          />
        }
        tags={tags}
      />
      {isExpanded && <IslandBody>{questionComponent}</IslandBody>}
    </IslandContainer>
  );
};

export default connect(
  SortableQuestion,
  surveyEditor$.pipe(
    map(({ fieldHasBeenInteractedWith }) => ({
      fieldHasBeenInteractedWith,
    }))
  )
);
