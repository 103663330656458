import { PrimaryButton } from '@ardoq/button';
import { Drawer, showDrawer } from '@ardoq/snowflakes';
import styled from 'styled-components';
import { createEmbedViewerUrl } from './utils';

// Height is 94% to give more space for page selector in lucid embed
export const DocumentPickerIframe = styled.iframe`
  width: 100%;
  height: 94%;
  border: none;
`;

export const showDocumentPickerDrawer = ({
  iframeSource,
  onClose,
}: {
  iframeSource: string;
  onClose: () => void;
}) => {
  return showDrawer({
    stackPageName: 'lucidChartDocumentPicker',
    onClose,
    renderDrawer: () => (
      <Drawer drawerSize="large" hideScroll>
        <DocumentPickerIframe src={iframeSource} />
      </Drawer>
    ),
    shouldCloseOnEscapeKey: true,
    shouldCloseOnBackdropClick: true,
  });
};

export const showEmbedViewerDrawer = ({
  documentId,
  clientId,
  onClose,
  onError,
  onAddToPresentation,
}: {
  documentId: string;
  clientId: string;
  onClose?: () => void;
  onError?: () => void;
  onAddToPresentation: () => void;
}) => {
  const iframeSource = createEmbedViewerUrl({ clientId, documentId });
  const { closeDrawer } = showDrawer({
    stackPageName: 'lucidChartEmbedViewer',
    onClose,
    renderDrawer: () => (
      <Drawer drawerSize="large" hideScroll>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '16px',
          }}
        >
          <PrimaryButton
            onClick={() => {
              onAddToPresentation();
              closeDrawer();
            }}
            data-intercom-target="add lucidchart as slide"
          >
            Add to presentation
          </PrimaryButton>
        </div>
        <DocumentPickerIframe src={iframeSource} onError={onError} />
      </Drawer>
    ),
    shouldCloseOnEscapeKey: true,
    shouldCloseOnBackdropClick: true,
  });

  return { closeDrawer };
};
