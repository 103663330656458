import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { Stack } from '@ardoq/layout';
import {
  StackModalContainer,
  ModalSize,
  ModalLayout,
  ModalTemplate,
  alert,
} from '@ardoq/modal';
import { popStackPageByName, pushStackPage } from '@ardoq/stack-page-manager';
import { Paragraph } from '@ardoq/typography';
import styled from 'styled-components';
import { confirm } from '@ardoq/modal';
import {
  GraphVisualizationExportResponse,
  GraphVisualizationExportWarning,
} from '@ardoq/api-types/integrations';
import { TextInput } from '@ardoq/forms';
import { IconName } from '@ardoq/icons';
import {
  ErrorNotification,
  NotificationDetails,
  showToast,
  ToastType,
  WarningNotification,
} from '@ardoq/status-ui';
import { exportToLucidchart } from './utils';
import * as fp from 'lodash/fp';
import { EXPORT_LUCIDCHART_DOCUMENT_WARNINGS } from './const';

const CREATING_DOCUMENT_MODAL_STACK = 'lucidchart-creating-document-modal';

const ModalContent = styled(Stack)`
  height: 240px; /* min height for the modal */
`;

const CreatingDocumentModal = () => {
  return (
    <StackModalContainer>
      <ModalTemplate modalSize={ModalSize.S}>
        <ModalLayout>
          <ModalContent justify="center" align="center" gap="xsmall">
            <ArdoqLoaderComponent
              loaderText="Creating Lucidchart document"
              loaderType="spinner"
              loaderColor={LoaderColor.BRAND}
              size={LoaderSize.MEDIUM}
            />
            <Paragraph>This might take a few seconds... </Paragraph>
          </ModalContent>
        </ModalLayout>
      </ModalTemplate>
    </StackModalContainer>
  );
};

const ExportWarning = ({
  message,
  warnings = [],
}: {
  message: string;
  warnings?: GraphVisualizationExportWarning[];
}) => {
  if (!warnings.length) {
    return null;
  }

  return (
    <WarningNotification>
      {message}
      <NotificationDetails>
        {warnings.map((warning, idx) => (
          <div key={idx}>{warning.filename}</div>
        ))}
      </NotificationDetails>
    </WarningNotification>
  );
};

export const startCreatingDocumentModal = () => {
  pushStackPage(() => <CreatingDocumentModal />, {
    stackPageName: CREATING_DOCUMENT_MODAL_STACK,
  });
};

export const closeCreatingDocumentModal = () =>
  popStackPageByName(CREATING_DOCUMENT_MODAL_STACK);

export const completeCreatingDocument = (
  response: GraphVisualizationExportResponse
) => {
  const warnings = fp.groupBy('type', response.warnings ?? []);

  confirm({
    title: 'Lucidchart document created',
    text: (
      <Stack gap="medium">
        <Paragraph>
          Your document has been successfully created! Copy the shareable link
          or open it directly in the Lucidchart app.
        </Paragraph>
        <ExportWarning
          message="Following images are not supported and not included in the document:"
          warnings={
            warnings[EXPORT_LUCIDCHART_DOCUMENT_WARNINGS.UNSUPPORTED_FILE_TYPE]
          }
        />
        <ExportWarning
          message="Following images were failed to download and not included in the document:"
          warnings={
            warnings[EXPORT_LUCIDCHART_DOCUMENT_WARNINGS.DOWNLOAD_FAILED]
          }
        />
        <TextInput
          label="Shareable link"
          value={response.editUrl}
          rightIconName={IconName.COPY}
          rightIconOnClick={() => {
            navigator.clipboard.writeText(response.editUrl);
            showToast('Your document link is copied.', ToastType.SUCCESS);
          }}
          isReadOnly
        />
      </Stack>
    ),
    confirmButtonTitle: 'Open with Lucidchart',
    cancelButtonTitle: 'Cancel',
    onConfirmAsync: () => {
      window.open(response.editUrl, '_blank');
    },
    modalSize: ModalSize.S,
  });
};

export const showExportErrorAlert = (userMessage: string) => {
  alert({
    title: 'Export Error',
    text: (
      <Stack gap="medium">
        <ErrorNotification>{userMessage}</ErrorNotification>
        <Paragraph>
          We could not export the viewpoint to Lucidchart due to an error.
        </Paragraph>
      </Stack>
    ),
    modalSize: ModalSize.S,
  });
};

export const showUnexpectedErrorDialog = (isLegendActive: boolean) => {
  confirm({
    title: 'Unexpected error',
    text: (
      <Stack gap="medium">
        <Paragraph>
          We ran into an unexpected error while processing the creation of your
          Lucidchart document. Please try again.
        </Paragraph>
      </Stack>
    ),
    confirmButtonTitle: 'Try again',
    cancelButtonTitle: 'Cancel',
    onConfirmAsync: () => exportToLucidchart(isLegendActive),
    modalSize: ModalSize.S,
  });
};
