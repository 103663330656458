import {
  actionCreator,
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { ContextMenuState } from '@ardoq/context-menu';
import { ExtractStreamShape } from 'tabview/types';
import { isViewpointMode$ } from 'traversals/loadedGraph$';

type StreamShape = { menuState: ContextMenuState; isViewpointMode: boolean };

export const setContextMenuState = actionCreator<ContextMenuState>(
  '[ContextMenuStateActions] set context menu state'
);

const handleSetContextMenuState = (
  state: StreamShape,
  payload: ContextMenuState
) => ({
  ...state,
  menuState: payload,
});
const updateViewpointMode = (
  state: StreamShape,
  { isViewpointMode }: ExtractStreamShape<typeof isViewpointMode$>
) => ({ ...state, isViewpointMode });

const EMPTY_STATE = { menuState: null, isViewpointMode: false };
export const contextMenuState$ = persistentReducedStream<StreamShape>(
  'ContextMenuState$',
  EMPTY_STATE,
  [
    reducer(setContextMenuState, handleSetContextMenuState),
    streamReducer(isViewpointMode$, updateViewpointMode),
  ]
);
