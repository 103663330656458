import { createElement } from 'react';
import {
  Class,
  EdgeStyleBase,
  GeneralPath,
  IEdge,
  IInputModeContext,
  INode,
  IObstacleProvider,
  IRenderContext,
  Point,
  SmoothingPolicy,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { getChangedPopover, getCssClassFromDiffType } from 'scope/modelUtil';
import { DiffType } from '@ardoq/data-model';
import { createPath } from 'yfilesExtensions/edgeUtil';
import SparkMD5 from 'spark-md5';
import { DATA_RENDER_HASH } from 'yfilesExtensions/styles/consts';
import { logError } from '@ardoq/logging';
import {
  GraphEdge,
  GraphNode,
  ensureRoot,
  disposeCallback,
} from '@ardoq/graph';
import BasicEdgeObstacleProvider from 'yfilesExtensions/BasicEdgeObstacleProvider';
import { classes } from '@ardoq/common-helpers';
import { POPOVER_ID_ATTR } from '@ardoq/popovers';
import { MODEL_ID_ATTRIBUTE } from 'consts';
import { EDGE_SMOOTHING_LENGTH } from 'tabview/relationshipDiagrams/consts';
import { Root } from 'react-dom/client';
import Edge from './EdgeClone';
import { LineType } from '@ardoq/api-types';
import { lineTypeDashArrays } from 'tabview/canvasRendering/consts';
import { createSvgElement } from '@ardoq/dom-utils';
import { colors } from '@ardoq/design-tokens';

// Copied from AggregatedGraphEdgeStyle.ts, but we're not using AggregatedGraphEdge, but GraphEdge

const createRenderDataCache = (
  edge: GraphEdge,
  pathData: string,
  hoverPathData: string,
  sourceNode: INode | null,
  targetNode: INode | null
) =>
  SparkMD5.hash(
    `${edge.getLineBeginning()},${edge.getLineEnding()},${edge.getLine()},${edge.getColor()},${pathData},${hoverPathData},${String(
      isGhostNode(targetNode) || isGhostNode(sourceNode)
    )},${edge.getIsSelected()},${edge.getIsInSelectedPath()},${edge.getOpacity()},${edge.getHasCollapsingRuleError()}`
  );
const validateGraphEdge = (graphEdge: any): graphEdge is GraphEdge => {
  if (!(graphEdge instanceof GraphEdge)) {
    logError(Error('Unexpected edge type', graphEdge));
    return false;
  }
  return true;
};
const isNode = (node: any): node is GraphNode => node instanceof GraphNode;
const isGhostNode = (node: any) => isNode(node) && node.isGhost;

const renderEdge = (
  root: Root,
  edge: IEdge,
  container: SVGElement,
  path: GeneralPath,
  hoverPath: GeneralPath,
  cache: string
) => {
  container.setAttribute(DATA_RENDER_HASH, cache);
  const { sourceNode, targetNode, tag: graphEdge } = edge;
  if (!sourceNode || !targetNode || !validateGraphEdge(graphEdge)) {
    logError(Error('Invalid edge.'));
    return;
  }
  const { tag: sourceGraphNode } = sourceNode;
  const { tag: targetGraphNode } = targetNode;

  const isGhostReference =
    isGhostNode(sourceGraphNode) || isGhostNode(targetGraphNode);

  const diffType = isGhostReference
    ? DiffType.UNCHANGED
    : graphEdge.getVisualDiffType();
  const visualDiffClassName = getCssClassFromDiffType(diffType);
  container.setAttribute('class', classes('integration', visualDiffClassName));
  const modelId = graphEdge.modelId;
  const tooltipAttributes = Object.entries(
    getChangedPopover(modelId, diffType)
  );

  if (tooltipAttributes.length > 0) {
    tooltipAttributes.forEach(([key, value]) =>
      container.setAttribute(key, value)
    );
  } else {
    [POPOVER_ID_ATTR, MODEL_ID_ATTRIBUTE].forEach(key =>
      container.removeAttribute(key)
    );
  }

  container.dataset.referenceModelIds = modelId;
  const pathData = path
    .createSmoothedPath({
      smoothingLength: EDGE_SMOOTHING_LENGTH,
      smoothingPolicy: SmoothingPolicy.SYMMETRIC,
    })
    .createSvgPathData();
  const hoverPathData = hoverPath
    .createSmoothedPath({
      smoothingLength: EDGE_SMOOTHING_LENGTH,
      smoothingPolicy: SmoothingPolicy.SYMMETRIC,
    })
    .createSvgPathData();

  const isSelected = graphEdge.getIsSelected();
  const isInSelectedPath = graphEdge.getIsInSelectedPath();
  const stroke = isSelected
    ? colors.yellow60
    : (graphEdge.getColor() ?? 'black');
  root.render(
    createElement(Edge, {
      stroke,
      strokeDasharray: getStrokeDashArrayOutOfLineType(graphEdge.getLine()),
      isSelected,
      graphId: graphEdge.id,
      pathData,
      hoverPathData,
      lineBeginning: graphEdge.getLineBeginning(),
      lineEnding: graphEdge.getLineEnding(),
      isInSelectedPath,
      opacity: graphEdge.getOpacity(),
      hasCollapsingRuleError: graphEdge.getHasCollapsingRuleError(),
    })
  );
};

class EdgeStyle extends EdgeStyleBase {
  override createVisual(context: IRenderContext, edge: IEdge) {
    const g = createSvgElement('g');
    const { sourceNode, targetNode, tag: graphEdge } = edge;
    if (!validateGraphEdge(graphEdge)) {
      logError(Error('Unexpected edge type.'));
      return new SvgVisual(g);
    }
    const path = createPath(edge, false);
    const hoverPath = createPath(edge, true);
    const cache = createRenderDataCache(
      graphEdge,
      path.createSvgPathData(),
      hoverPath.createSvgPathData(),
      sourceNode,
      targetNode
    );
    renderEdge(ensureRoot(g), edge, g, path, hoverPath, cache);
    const result = new SvgVisual(g);
    context.setDisposeCallback(result, disposeCallback);
    return result;
  }

  override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    edge: IEdge
  ) {
    if (!(oldVisual instanceof SvgVisual)) {
      logError(Error('Invalid visual in EdgeStyleClone updateVisual.'));
      return oldVisual;
    }
    const { sourceNode, targetNode, tag: graphEdge } = edge;
    if (!validateGraphEdge(graphEdge)) {
      return oldVisual;
    }
    const container = oldVisual.svgElement;

    const oldCache = container.getAttribute(DATA_RENDER_HASH);
    const path = createPath(edge, false);
    const hoverPath = createPath(edge, true);
    const newCache = createRenderDataCache(
      graphEdge,
      path.createSvgPathData(),
      hoverPath.createSvgPathData(),
      sourceNode,
      targetNode
    );

    if (oldCache === newCache) {
      return oldVisual;
    }

    renderEdge(
      ensureRoot(container),
      edge,
      container,
      path,
      hoverPath,
      newCache
    );
    return oldVisual;
  }

  isHit(canvasContext: IInputModeContext, location: Point, edge: IEdge) {
    const hitTestRadius = 13; // the hover path is 25px wide, set in CSS.  if the hover path is showing, this should be a hit.
    return this.getPath(edge).pathContains(location, hitTestRadius);
  }

  override getPath(edge: IEdge) {
    return createPath(edge, true);
  }

  lookup(edge: IEdge, type: Class) {
    if (type === IObstacleProvider.$class) {
      return new BasicEdgeObstacleProvider(edge);
    }
    return super.lookup(edge, type);
  }
}

const getStrokeDashArrayOutOfLineType = (line: LineType): string => {
  return lineTypeDashArrays[line ?? LineType.SOLID].join(',');
};

export default EdgeStyle;
