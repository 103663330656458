import { EditingBundleShape } from '../types';

export const emptyBundle: EditingBundleShape = {
  name: '',
  useCases: [],
  entityGroups: [],
  componentTypes: [],
  referenceTypes: [],
  fields: [],
  workspaces: new Map(),
  surveys: [],
  presentations: [],
  perspectives: [],
  dashboards: [],
  tabularMappingConfigIds: [],
  serviceNowImportConfigIds: [],
  broadcasts: [],
  graphFilter: [],
  viewpoints: [],
  reports: [],
  traversals: [],
};
