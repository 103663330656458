import styled, { css } from 'styled-components';

const FormCell = styled('div')<{ $fullWidth?: boolean }>`
  ${props => css`
    max-width: ${props.$fullWidth ? '100%' : '395px'};
    width: ${props.$fullWidth ? '100%' : '50%'};
  `}
  padding: 20px 18px;
  flex-shrink: 0;
  flex-grow: 1;
`;

export default FormCell;
