import {
  ViewpointNavigatorItemHeader,
  ViewpointNavigatorItemLabel,
  ViewpointNavigatorIconButton,
  ViewpointNavigatorItemBody,
  ViewpointNavigatorItemVisibilityButton,
} from './atoms';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { ViewpointNavigatorListItem } from './types';
import { colors } from '@ardoq/design-tokens';
import { ButtonGroup, ButtonSize, GhostButton } from '@ardoq/button';
import { ViewpointNavigatorCommands } from './viewpointNavigatorCommands';
import ViewpointNavigatorEntrySummary from './ViewpointNavigatorEntrySummary';
import ViewpointNavigatorContextSwitcher from './ViewpointNavigatorContextSwitcher';
import { trackDatasetVisibility } from './tracking';
import { Island } from '@ardoq/page-layout';
import ViewpointNavigatorCollapsedPaths from './ViewpointNavigatorCollapsedPaths';
import { FlexBox } from '@ardoq/layout';

type ViewpointNavigatorItemProps = ViewpointNavigatorListItem & {
  itemsCount: number;
  viewpointNavigatorCommands: ViewpointNavigatorCommands;
  blockIndex: number;
};

const ViewpointNavigatorItem = ({
  label,
  loadedStateHash,
  isHidden,
  isSaved,
  summary,
  isTraversal,
  editButton,
  contextSwitcherCurrentContext,
  isContextReadOnly,
  traversalContextComponentType,
  itemsCount,
  viewpointNavigatorCommands,
  startSetFilter,
  pathCollapsingRules,
  blockIndex,
}: ViewpointNavigatorItemProps) => {
  return (
    <li>
      <Island minWidth="auto" maxWidth="100%" bodySpacing="none">
        <FlexBox align="stretch">
          <ViewpointNavigatorItemVisibilityButton
            onClick={() => {
              trackDatasetVisibility(isHidden);
              viewpointNavigatorCommands.toggleItemVisibility(loadedStateHash);
            }}
          >
            <Icon
              iconSize={IconSize.MEDIUM}
              iconName={
                isHidden ? IconName.VISIBILITY_OFF : IconName.VISIBILITY
              }
              color={colors.grey50}
            />
          </ViewpointNavigatorItemVisibilityButton>
          <ViewpointNavigatorItemBody flex={1} gap="small" $isHidden={isHidden}>
            <ViewpointNavigatorItemHeader>
              <ViewpointNavigatorItemLabel title={label}>
                {label}
              </ViewpointNavigatorItemLabel>
              <ButtonGroup $gap="none">
                <ViewpointNavigatorIconButton
                  data-click-id="delete-traversal"
                  onClick={() =>
                    viewpointNavigatorCommands.removeLoadedState({
                      loadedStateHash,
                      loadedStatesCount: itemsCount,
                      isTraversal,
                      isSaved,
                    })
                  }
                  $isHidden={isHidden}
                >
                  <Icon
                    iconName={IconName.CLOSE}
                    iconSize={IconSize.MEDIUM}
                    color={colors.grey50}
                  />
                </ViewpointNavigatorIconButton>
              </ButtonGroup>
            </ViewpointNavigatorItemHeader>
            {isTraversal && (
              <ViewpointNavigatorContextSwitcher
                isContextReadOnly={isContextReadOnly}
                loadedStateHash={loadedStateHash}
                componentTypeName={traversalContextComponentType!}
                currentContext={contextSwitcherCurrentContext!}
                startSetFilter={startSetFilter}
              />
            )}
            {summary && <ViewpointNavigatorEntrySummary {...summary} />}
            {pathCollapsingRules && pathCollapsingRules.length > 0 && (
              <ViewpointNavigatorCollapsedPaths
                blockIndex={blockIndex}
                pathCollapsingRules={pathCollapsingRules}
                onToggleActive={rule => {
                  viewpointNavigatorCommands.toggleCollapsedPath(
                    rule,
                    loadedStateHash
                  );
                }}
              />
            )}
            {editButton && (
              <GhostButton
                isFlexible
                buttonSize={ButtonSize.SMALL}
                onClick={editButton.action}
              >
                <Icon color={colors.grey50} iconName={editButton.icon} />
                {editButton.text}
              </GhostButton>
            )}
          </ViewpointNavigatorItemBody>
        </FlexBox>
      </Island>
    </li>
  );
};
export default ViewpointNavigatorItem;
