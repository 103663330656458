import { actionCreator } from '@ardoq/rxbeach';
import {
  ArdoqId,
  AllSupportedEntityTypes,
  APIOrganizationUser,
} from '@ardoq/api-types';
import { Graphics } from '@ardoq/renderers';
import { InitEntityMerge } from './types';
import { getActionNamespacer } from 'streams/utils/streamUtils';

const name = getActionNamespacer('EntityMerge');

export const initEntityMerge = actionCreator<InitEntityMerge>(
  name('INIT_ENTITY_MERGE')
);

type InitData = {
  graphics: Graphics;
  users: Record<ArdoqId, APIOrganizationUser>;
};

export const setEntityMergeData = actionCreator<InitEntityMerge & InitData>(
  name('SET_ENTITY_MERGE_DATA')
);

export const applyEntityMerge = actionCreator<AllSupportedEntityTypes>(
  name('APPLY_ENTITY_MERGE')
);

interface SetEntityMergeState {
  index: number;
  id: ArdoqId;
}

export const setEntityMergeState = actionCreator<SetEntityMergeState>(
  name('SET_ENTITY_MERGE_STATE')
);

export const setEntityMergeIsLoading = actionCreator<boolean>(
  name('SET_ENTITY_MERGE_IS_LOADING')
);
