import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  APIComponentAttributes,
  ArdoqId,
  FilterInterfaceFilter,
  ViewpointComponentFilter,
  QueryBuilderRow,
  QueryBuilderRule,
  QueryBuilderSubquery,
  APIFieldAttributes,
} from '@ardoq/api-types';
import {
  booleanSubqueryToFilterAttributes,
  filterToQueryBuilderRule,
} from 'collections/filterUtils';
import { getCurrentLocale, localeCompareNumericLowercase } from '@ardoq/locale';

const getConnectedWorkspacesInfo = (workspaceIds: ArdoqId[] | null) => {
  if (!workspaceIds) return [];
  return workspaceIds
    .map(workspaceId => ({
      workspaceId,
      workspaceModelId: workspaceInterface.getWorkspaceModelId(workspaceId),
    }))
    .filter(
      (info): info is { workspaceId: ArdoqId; workspaceModelId: ArdoqId } =>
        Boolean(info.workspaceModelId)
    );
};

const getConnectedWorkspaceComponentFields = (workspaceIds: ArdoqId[] | null) =>
  getConnectedWorkspacesInfo(workspaceIds).flatMap(
    ({ workspaceId, workspaceModelId }) =>
      workspaceInterface
        .getComponentTypes(workspaceId)
        .flatMap(({ id }) =>
          fieldInterface.getFieldIdsByComponentType(id, workspaceModelId)
        )
        .map(fieldInterface.getFieldData)
        .filter(ExcludeFalsy)
  );

export const collectComponentFields = (
  rules: QueryBuilderSubquery,
  workspaceIds: ArdoqId[] | null
) =>
  [
    ...getConnectedWorkspaceComponentFields(workspaceIds),
    ...ruleFields(rules),
  ].sort(labelSorter);

export const collectRelatedComponents = (
  workspaceIds: ArdoqId[] | null
): APIComponentAttributes[] =>
  (workspaceIds ?? []).flatMap(id =>
    componentInterface.getComponentsByWorkspaceId(id)
  );

type ComponentQuery = {
  name: string;
  filters: QueryBuilderSubquery;
};

export const toComponentFilter = (
  componentQuery: ComponentQuery
): ViewpointComponentFilter => {
  return {
    ...componentQuery,
    filters: (booleanSubqueryToFilterAttributes(
      componentQuery.filters,
      true
    )?.rules?.filter(ExcludeFalsy) ?? []) as FilterInterfaceFilter[],
    condition: componentQuery.filters.condition,
  };
};

export const toComponentQuery = (
  componentFilter: ViewpointComponentFilter
): ComponentQuery => {
  return {
    ...componentFilter,
    filters: {
      condition: componentFilter.condition,
      rules: componentFilter.filters.flatMap(filter =>
        filterToQueryBuilderRule(filter)
      ) as QueryBuilderRule[],
    },
  };
};

const getIsQuery = (row: QueryBuilderRow): row is QueryBuilderSubquery =>
  'condition' in row;

const flatRules = (row: QueryBuilderRow): QueryBuilderRule[] =>
  getIsQuery(row) ? row.rules.flatMap(flatRules) : [row];

/** @returns fields referenced by the given rules. */
const ruleFields = (rules: QueryBuilderSubquery) =>
  flatRules(rules)
    .filter(({ field }) => field)
    .map(({ field }) =>
      fieldInterface.getByName(field, {
        acrossWorkspaces: true,
        includeTemplateFields: false,
      })
    )
    .filter(ExcludeFalsy);

const labelSorter = (
  { label: labelA }: APIFieldAttributes,
  { label: labelB }: APIFieldAttributes
) => localeCompareNumericLowercase(labelA, labelB, getCurrentLocale());
