import {
  APIComponentAttributes,
  APIReferenceAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import {
  ComponentValidation,
  RecordOfComponents,
  RecordOfReferences,
  ValidationObject,
} from './types';
import { omit } from 'lodash';

export enum ValidateOrReset {
  VALIDATE = 'validate',
  RESET = 'reset',
}

export const updateComponentValidationObjectForReferenceChange = (
  componentValidationObject: ValidationObject,
  componentId: ArdoqId,
  referenceId: ArdoqId,
  hasBeenValidated: boolean
): ValidationObject => {
  const specificComponentsValidationObj =
    componentValidationObject[componentId];
  return {
    ...componentValidationObject,
    [componentId]: {
      fields: specificComponentsValidationObj.fields,
      references: {
        ...specificComponentsValidationObj?.references,
        [referenceId]: hasBeenValidated,
      },
    },
  };
};

export const updateReferenceRecordInApprovedReferenceChanges = (
  approvedReferenceChanges: RecordOfReferences,
  referenceId: ArdoqId,
  reference: Partial<APIReferenceAttributes> | undefined
) => {
  return {
    ...approvedReferenceChanges,
    [referenceId]: reference,
  };
};

export const createOrGetPartiallyApprovedReference = (
  approvedReferenceChanges: RecordOfReferences,
  masterReference: APIReferenceAttributes,
  changedFields: Array<keyof APIReferenceAttributes>
) => {
  return (
    approvedReferenceChanges[masterReference._id] ?? {
      ...omit(masterReference, changedFields),
    }
  );
};

export const updateComponentValidationObjectForComponentChange = (
  componentValidationObject: ValidationObject,
  componentId: ArdoqId,
  specificComponentsValidationObj: ComponentValidation
) => {
  return {
    ...componentValidationObject,
    [componentId]: specificComponentsValidationObj,
  };
};

export const updateComponenteRecordInApprovedComponentChanges = (
  approvedComponentChanges: RecordOfComponents,
  componentId: ArdoqId,
  component: Partial<APIComponentAttributes> | undefined
) => {
  return {
    ...approvedComponentChanges,
    [componentId]: component,
  };
};

export const createOrGetPartiallyApprovedComponent = (
  approvedComponentChanges: RecordOfComponents,
  componentId: ArdoqId,
  masterComponent: APIComponentAttributes,
  changedFields: Array<keyof APIComponentAttributes>
) => {
  return (
    approvedComponentChanges[componentId] ?? {
      ...omit(masterComponent, changedFields),
    }
  );
};

export const updateFieldValidation = (
  componentValidationObject: ValidationObject,
  componentId: ArdoqId,
  whatChanged: string,
  action: ValidateOrReset
): ComponentValidation => {
  const fieldHasBeenValidated = action !== ValidateOrReset.VALIDATE;
  return {
    fields: {
      ...componentValidationObject[componentId].fields,
      [whatChanged]: fieldHasBeenValidated,
    },
    references: componentValidationObject[componentId].references,
  };
};

/**
 *
 * @param componentValidationObject Object containing validation information for components
 * @param componentId The component to update
 * @param action Denoting whether the fields and references should be validated or reset
 */
export const updateAllValidations = (
  componentValidationObject: ValidationObject,
  componentId: ArdoqId,
  action: ValidateOrReset
): ComponentValidation => {
  const fieldHasBeenValidated = action !== ValidateOrReset.VALIDATE;
  return {
    fields: {
      ...Object.keys(componentValidationObject[componentId].fields).reduce(
        (acc, key) => {
          return {
            ...acc,
            [key]: fieldHasBeenValidated,
          };
        },
        {}
      ),
    },
    references: {
      ...Object.keys(
        componentValidationObject[componentId].references ?? {}
      ).reduce((acc, key) => {
        return {
          ...acc,
          [key]: fieldHasBeenValidated,
        };
      }, {}),
    },
  };
};
