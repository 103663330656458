import { Checkbox, TextInput } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import {
  RelativeDatePicker,
  RelativeDatePickerMode,
} from '@ardoq/date-time-picker';
import {
  APIFieldAttributes,
  APIFieldType,
  FormattingFilter,
} from '@ardoq/api-types';
import isFormattingValueEmpty from './isFormattingValueEmpty';

type CustomFormattingFieldProps = {
  format: FormattingFilter;
  customField: APIFieldAttributes;
  handleUpdateFormatting: (
    formattingProperty: Partial<FormattingFilter>
  ) => void;
};

const CustomFormattingField = ({
  format,
  customField,
  handleUpdateFormatting,
}: CustomFormattingFieldProps) => {
  const commonProps = {
    hasError: Boolean(
      (format.name || format.comparator) && isFormattingValueEmpty(format)
    ),
  };
  switch (customField.type) {
    case APIFieldType.LIST:
    case APIFieldType.SELECT_MULTIPLE_LIST:
      return (
        <Select
          onValueChange={value =>
            handleUpdateFormatting({ value: value as string })
          }
          menuPlacement="auto"
          placeholder="Type or select your chosen value"
          options={fieldInterface
            .getAllowedValues(customField._id)
            .map(value => ({ value, label: value }))}
          value={format.value}
          dataTestId="list-select"
          {...commonProps}
        />
      );
    case APIFieldType.DATE_ONLY:
      return (
        <RelativeDatePicker
          value={format.value as string}
          placeholder="Select a date"
          onChange={value => handleUpdateFormatting({ value })}
          dataTestId="date-only-select"
          relativeDatePickerMode={RelativeDatePickerMode.DATE}
          {...commonProps}
        />
      );
    case APIFieldType.DATE_TIME:
      return (
        <RelativeDatePicker
          value={format.value as string}
          placeholder="Select a date"
          onChange={value => handleUpdateFormatting({ value })}
          dataTestId="date-time-select"
          relativeDatePickerMode={RelativeDatePickerMode.DATE_TIME}
          {...commonProps}
        />
      );
    case APIFieldType.NUMBER:
      return (
        <TextInput
          value={format.value as string}
          placeholder="Value"
          onValueChange={value => handleUpdateFormatting({ value })}
          type="number"
          dataTestId="number-input"
          {...commonProps}
        />
      );
    case APIFieldType.CHECKBOX:
      return (
        <Checkbox
          isChecked={Boolean(format.value)}
          name="isNegative"
          onChange={value => handleUpdateFormatting({ value })}
          {...commonProps}
        />
      );
    default:
      return (
        <TextInput
          value={format.value as string}
          placeholder="Value"
          dataTestId="text-input"
          onValueChange={value => handleUpdateFormatting({ value })}
          {...commonProps}
        />
      );
  }
};

export default CustomFormattingField;
