import { useState } from 'react';
import { Checkbox } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { AttributeComparators } from 'collections/attributeComparators';
import CustomFormattingField from './CustomFormattingField';
import StandardFormattingField from './StandardFormattingField';
import { APIFieldAttributes, FormattingFilter } from '@ardoq/api-types';
import {
  getRelevantFields,
  isComponentField,
  isReferenceField,
} from '../utils';
import { uniqBy } from 'lodash';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';
import { TableDeleteButton, TwentyFiveWideTd } from '../Table';
import { ColorPickerButton } from '@ardoq/color-picker';
import styled from 'styled-components';
import { showInvertCheckbox } from '@ardoq/perspectives';

const getAttributeOptions = (
  format: FormattingFilter,
  relevantFields: APIFieldAttributes[]
) => {
  if (format.affectComponent) {
    const componentOptions = uniqBy(
      [
        ...filterInterface.mapComponentAttributes(),
        ...relevantFields.filter(isComponentField),
      ],
      'name'
    ) as APIFieldAttributes[];
    return componentOptions
      .filter(option => !('divider' in option))
      .flatMap(option =>
        isDateRangeFieldType(option.type)
          ? dateRangeOperations.splitDateRangeFieldIntoStartAndEndSelectOptions(
              option
            )
          : {
              label: option.label,
              value: option.name,
            }
      );
  }
  if (format.affectReference) {
    const referenceOptions = [
      ...filterInterface.mapReferencesAttributes(),
      ...relevantFields.filter(isReferenceField),
    ] as APIFieldAttributes[];
    return referenceOptions
      .filter(option => !('divider' in option))
      .flatMap(option =>
        isDateRangeFieldType(option.type)
          ? dateRangeOperations.splitDateRangeFieldIntoStartAndEndSelectOptions(
              option
            )
          : {
              label: option.label,
              value: option.name,
            }
      );
  }
  return [];
};

const getTypeOptions = (name?: string) => {
  const comparators = AttributeComparators.getAvailableComparators(name ?? '');
  return comparators.map(comparator => ({
    label: comparator.name,
    value: comparator.id,
  }));
};

const ThirdWideTd = styled.td`
  width: 30%;
`;

const SixthWideTd = styled.td`
  width: 15%;
`;

type FormattingRowProps = {
  format: FormattingFilter;
  workspaceIds: string[];
  updateFormatting: (updatedFormatting: FormattingFilter) => void;
  removeFormatting: () => void;
};

const FormattingRow = ({
  format,
  workspaceIds,
  updateFormatting,
  removeFormatting,
}: FormattingRowProps) => {
  const allRelevantFields = getRelevantFields(workspaceIds);
  const [customField, setCustomField] = useState<
    APIFieldAttributes | undefined
  >(allRelevantFields.find(field => field.name === format.name));

  const { fields: relevantFieldsWithDateRangeFields } =
    dateRangeOperations.mergeDateTimeFieldsToDateRangeFields(allRelevantFields);

  const handleUpdateFormatting = (
    formattingProperty: Partial<FormattingFilter>
  ) => {
    const updatedFormatting = {
      ...format,
      ...formattingProperty,
    };

    updateFormatting(updatedFormatting);
  };

  const typeOptions = getTypeOptions(format.name);

  return (
    <tr data-test-id="formatting-row">
      <td>{format.affectComponent ? 'Component' : 'Reference'}</td>
      <ThirdWideTd>
        <Select
          onChange={option => {
            if (!option) return;
            setCustomField(
              allRelevantFields.find(field => field.name === option.value)
            );
            handleUpdateFormatting({ name: option.value as string });
          }}
          noOptionsMessage={() => 'No results'}
          placeholder="Attribute"
          options={getAttributeOptions(
            format,
            relevantFieldsWithDateRangeFields
          )}
          menuShouldScrollIntoView={true}
          value={format.name}
          menuPlacement="auto"
          hasError={Boolean(
            (format.comparator || format.value) && !format.name
          )}
          dataTestId="attribute-select"
        />
      </ThirdWideTd>
      <SixthWideTd>
        <Select
          onValueChange={comparator =>
            handleUpdateFormatting({
              comparator: comparator!,
            })
          }
          noOptionsMessage={() => 'No results'}
          placeholder="Condition"
          options={typeOptions}
          value={typeOptions.find(
            typeOption =>
              typeOption.value ===
              AttributeComparators.getById(format?.comparator ?? '')?.id
          )}
          menuPlacement="auto"
          hasError={Boolean(
            (format.name || format.value) && !format.comparator
          )}
          dataTestId="type-select"
        />
      </SixthWideTd>
      <ThirdWideTd>
        {customField ? (
          <CustomFormattingField
            format={format}
            customField={customField}
            handleUpdateFormatting={handleUpdateFormatting}
          />
        ) : (
          <StandardFormattingField
            format={format}
            handleUpdateFormatting={handleUpdateFormatting}
            workspaceIds={workspaceIds}
          />
        )}
      </ThirdWideTd>
      <TwentyFiveWideTd>
        {!format.comparator ||
          (showInvertCheckbox(format.comparator) && (
            <Checkbox
              isChecked={format.isNegative ?? false}
              name="isNegative"
              onChange={() =>
                handleUpdateFormatting({
                  isNegative: !format.isNegative,
                })
              }
            >
              Inverse
            </Checkbox>
          ))}
      </TwentyFiveWideTd>
      <TwentyFiveWideTd>
        <ColorPickerButton
          dataTestId="format-color-button"
          value={format.color}
          onValueChange={color => handleUpdateFormatting({ color })}
        />
      </TwentyFiveWideTd>
      <TableDeleteButton
        tooltipText="Delete format"
        handleDelete={removeFormatting}
        dataTestId="delete-format-button"
      />
    </tr>
  );
};

export default FormattingRow;
