import { ManageMembershipTableData } from './types';
import { Box, FlexBox } from '@ardoq/layout';
import { ArdoqId, Subdivision } from '@ardoq/api-types';
import styled from 'styled-components';
import { colors, s4 } from '@ardoq/design-tokens';
import { AnimatedChevron, IconName } from '@ardoq/icons';
import { Tag } from '@ardoq/status-ui';
import { ButtonSize, GhostButton } from '@ardoq/button';
import { decoratedSubdivisionOperations } from './decoratedSubdivisionOperations';
import { text2 } from '@ardoq/typography';
import { SubdivisionsColumnHeader } from './SubdivisionsColumnHeader';
import { manageMembershipTableDataOperations } from './manageMembershipTableDataOperations';

// This is the maximum length of the subdivisions names that will fit in one row
const SUBDIVISIONS_NAMES_MAX_LENGTH = 21;

const SecondaryText = styled(FlexBox)`
  color: ${colors.grey50};
  ${text2};
  font-style: italic;
`;

const Unassigned = () => <SecondaryText>Unassigned</SecondaryText>;

const UnpaddedButton = styled(GhostButton)`
  padding: 0;
  margin-top: -${s4};
`;

export const SubdivisionsColumnBulk = (
  toggleExpanded: (componentId: ArdoqId) => void,
  expandedRowIds: string[]
) => ({
  valueRender: (
    subdivisions: Subdivision[],
    tableData: ManageMembershipTableData
  ) => {
    if (subdivisions.length === 0) {
      return <Unassigned />;
    }
    const rowId = manageMembershipTableDataOperations.getRowId(tableData);
    const isExpanded = expandedRowIds.includes(rowId);
    const visibleSubdivisions =
      decoratedSubdivisionOperations.selectSubdivisionsByMaxLength(
        subdivisions,
        isExpanded ? Infinity : SUBDIVISIONS_NAMES_MAX_LENGTH
      );
    const hiddenCount = subdivisions.length - visibleSubdivisions.length;
    const showExpandToggle = hiddenCount > 0 || isExpanded;
    return (
      <FlexBox justify="flex-start" align="flex-start">
        <FlexBox paddingRight="small" flex={1} flexWrap={true}>
          {visibleSubdivisions.map(subdivision => (
            <Box
              key={subdivision._id}
              marginRight="xsmall"
              marginBottom="xsmall"
              width={undefined}
            >
              <Tag
                label={subdivision.name}
                leftIcon={{
                  name: IconName.CIRCLE,
                  color: subdivision.style.color,
                }}
              />
            </Box>
          ))}
          {!isExpanded && hiddenCount > 0 && (
            <Box
              key="more"
              marginRight="xsmall"
              marginBottom="xsmall"
              width={undefined}
            >
              <Tag label={`+${hiddenCount}`} />
            </Box>
          )}
        </FlexBox>
        {showExpandToggle && (
          <UnpaddedButton
            buttonSize={ButtonSize.SMALL}
            isActive={isExpanded}
            onClick={() => toggleExpanded(rowId)}
          >
            <AnimatedChevron $isUp={isExpanded} />
          </UnpaddedButton>
        )}
      </FlexBox>
    );
  },
  headerRender: SubdivisionsColumnHeader,
  dataIndex: 'memberships',
});
