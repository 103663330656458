import * as React from 'react';
import { ViewpointBuilderFooter } from 'viewpointBuilder/components/ViewpointBuilderFooter';
import { TraversalBuilderViewState } from '../types';
import { PrimaryButtonConfig } from 'viewpointBuilder/openViewpointBuilder/getPrimaryButtonConfig';
import { WithMetamodelLoader } from 'viewpointBuilder/components/WithMetamodelLoader';
import { ResizablePageBody } from '@ardoq/page-layout';
import SimpleGraphView from 'viewpointBuilder/components/SimpleGraph/SimpleGraphView';
import { SimpleGraphContainerWithGreyBackground } from 'viewpointBuilder/components/SimpleGraph/SimpleGraphViewContainer';
import { createGraphInterface } from 'viewpointBuilder/components/SimpleGraph/GraphInterface';
import RequiredComponentsTabSidebar from './RequiredComponentsTabSidebar';
import { requiredComponentsCommands } from './requiredComponentsCommands';

type RequiredComponentsTabContentProps = {
  state: TraversalBuilderViewState;
  closeViewpointBuilder: () => void;
  label: string;
  toggleGraphNodeSelection: (
    event: React.MouseEvent<Element, MouseEvent>
  ) => void;
  primaryButtonConfig: PrimaryButtonConfig;
  graphNodeOnHover: (event: React.MouseEvent<Element, MouseEvent>) => void;
};

const RequiredComponentsTabContent = ({
  state,
  closeViewpointBuilder,
  label,
  toggleGraphNodeSelection,
  primaryButtonConfig,
  graphNodeOnHover,
}: RequiredComponentsTabContentProps) => {
  const { graphNodes, graphEdges, graphGroups, isMetaModelLoaded } = state;
  const graphInterface = React.useRef(createGraphInterface());
  return (
    <WithMetamodelLoader
      isMetaModelLoaded={isMetaModelLoaded}
      closeViewpointBuilder={closeViewpointBuilder}
    >
      <ResizablePageBody
        skipPadding
        footerContent={
          <ViewpointBuilderFooter
            closeViewpointBuilder={closeViewpointBuilder}
            primaryButtonConfig={primaryButtonConfig}
          />
        }
        rightContent={
          <RequiredComponentsTabSidebar
            state={state.requiredComponentsState}
            commands={requiredComponentsCommands}
          />
        }
        rightContentInitialWidth={530}
        rightContentMinWidth={400}
      >
        <SimpleGraphContainerWithGreyBackground
          onClick={toggleGraphNodeSelection}
          onMouseOver={graphNodeOnHover}
        >
          <SimpleGraphView
            label={label}
            viewModel={{ graphNodes, graphEdges, graphGroups }}
            graphInterface={graphInterface.current}
          />
        </SimpleGraphContainerWithGreyBackground>
      </ResizablePageBody>
    </WithMetamodelLoader>
  );
};

export default RequiredComponentsTabContent;
