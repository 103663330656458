import { cloneDeep, omit } from 'lodash';
import {
  type ExtractPayload,
  reducer,
  RegisteredReducer,
} from '@ardoq/rxbeach';
import {
  addNewToCurrentSSOMapping,
  deleteSSOAttributeMapping,
  setPreviewSSOMapping,
  setSSOAttributeMapping,
  setShowValidationErrorsSSOMapping,
  updateCurrentSSOMapping,
  setSsoSelectedForPreview,
  setAggregatedSSOMappings,
} from './actions';
import type {
  CurrentMappingUpdate,
  SSOAttributeMappingState,
  SsoOption,
} from './types';
import {
  generateKeyedEmptyRule,
  inferAttributeType,
  mappingResponseToInitialMapping,
} from './utils';
import { AttributeTypeToConditionMap } from './consts';
import { previewMapping } from './previewMapping';
import {
  Action,
  AggregatedSSOMappingsAPIResponse,
  Clause,
  SSOAttributeMappingAPIResponse,
} from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import { toByIdDictionary } from '@ardoq/common-helpers';
import { parseDate } from '@ardoq/date-time';

const handleSetSSOAttributeMapping = (
  state: SSOAttributeMappingState,
  response: SSOAttributeMappingAPIResponse[]
) => {
  const initialMapping = mappingResponseToInitialMapping(
    response,
    state.allowMultipleSsoOptionsInMappings
  );
  return {
    ...state,
    initialMapping: Object.values(initialMapping),
    currentMapping: cloneDeep(initialMapping),
  };
};

const handleUpdateCurrentSSOMapping = (
  { currentMapping, currentClaims, ...state }: SSOAttributeMappingState,
  { path, value }: CurrentMappingUpdate
) => {
  const [_id, parentKey, key] = path.split('.');
  const changedMapping = currentMapping[_id];
  const changedObject = changedMapping[parentKey as 'action' | 'clause'] as
    | Action
    | Clause;
  return {
    ...state,
    currentMapping: {
      ...currentMapping,
      [_id]: {
        ...changedMapping,
        [parentKey]: {
          ...changedObject,
          [key]: value,
          ...(key === 'attribute'
            ? {
                condition:
                  AttributeTypeToConditionMap[
                    inferAttributeType(currentClaims[value])
                  ],
              }
            : {}),
        },
      },
    },
    showValidationErrors: false,
    currentClaims,
  };
};

const handleDeleteSSOAttributeMapping = (
  state: SSOAttributeMappingState,
  _id: string
) => {
  return {
    ...state,
    currentMapping: omit(state.currentMapping, _id),
    showValidationErrors: false,
  };
};

const handleAddNewToCurrentSSOMapping = (state: SSOAttributeMappingState) => {
  return {
    ...state,
    currentMapping: {
      ...state.currentMapping,
      ...generateKeyedEmptyRule(state.allowMultipleSsoOptionsInMappings),
    },
    showValidationErrors: false,
  };
};

const handleSetShowValidationErrorsSSOMapping = (
  state: SSOAttributeMappingState,
  showValidationErrors: boolean
) => {
  return {
    ...state,
    showValidationErrors,
  };
};

const handleSetPreviewSSOMapping = (
  { currentMapping, currentClaims, ...state }: SSOAttributeMappingState,
  { mappingPreview }: ExtractPayload<typeof setPreviewSSOMapping>
): SSOAttributeMappingState => {
  const groupsById = toByIdDictionary(state.groups);
  return {
    ...state,
    currentMapping,
    currentClaims,
    mappingPreview: previewMapping(
      currentMapping,
      currentClaims,
      groupsById,
      mappingPreview
    ),
  };
};

const handleSetSsoSelectedForPreview = (
  state: SSOAttributeMappingState,
  ssoSelectedForPreview: SsoOption | null
) => {
  return {
    ...state,
    ssoSelectedForPreview,
  };
};

const handleSetAggregatedSSOMappings = (
  state: SSOAttributeMappingState,
  response: AggregatedSSOMappingsAPIResponse
): SSOAttributeMappingState => {
  const allowMultipleSsoOptionsInMappings = hasFeature(
    Features.SSO_MAPPING_MULTIPLE_PROVIDERS
  );
  const initialMapping = mappingResponseToInitialMapping(
    response.mappings,
    allowMultipleSsoOptionsInMappings
  );
  return {
    ...state,
    initialMapping: Object.values(initialMapping),
    currentMapping: cloneDeep(initialMapping),
    loginOptions: response.loginOptions,
    groups: response.groups,
    currentClaims: response.currentClaims['idp-user']
      ? response.currentClaims['idp-user']
      : {},
    lastUpdated: response.currentClaims.ts
      ? parseDate(response.currentClaims.ts)
      : null,
    isLoggedInWithSSO: Boolean(response.currentClaims['idp-user']),
    allowMultipleSsoOptionsInMappings,
    isLoading: false,
  };
};

export default [
  reducer(setSSOAttributeMapping, handleSetSSOAttributeMapping),
  reducer(updateCurrentSSOMapping, handleUpdateCurrentSSOMapping),
  reducer(deleteSSOAttributeMapping, handleDeleteSSOAttributeMapping),
  reducer(addNewToCurrentSSOMapping, handleAddNewToCurrentSSOMapping),
  reducer(
    setShowValidationErrorsSSOMapping,
    handleSetShowValidationErrorsSSOMapping
  ),
  reducer(setPreviewSSOMapping, handleSetPreviewSSOMapping),
  reducer(setSsoSelectedForPreview, handleSetSsoSelectedForPreview),
  reducer(setAggregatedSSOMappings, handleSetAggregatedSSOMappings),
] satisfies RegisteredReducer<SSOAttributeMappingState>[];
