import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap, exhaustMap, map, from } from 'rxjs';
import { enhanceScopeData } from '@ardoq/renderers';
import {
  hideDetailsDrawer,
  openDetailsDrawer,
  setDrawerData,
  showDetailsDrawer,
  updateDetailsDrawer,
} from 'appLayout/ardoqStudio/detailsDrawer/actions';
import { api, componentApi, traversalApi } from '@ardoq/api';
import { hideRightPane } from 'appContainer/actions';
import { getTraversalWithStartSetAndWildcardTripleOfType } from '../utils';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';
import {
  clearLoadedState,
  deleteLoadedState,
  setTraversalContextComponent,
  toggleLoadedStateVisibility,
} from 'loadedState/actions';
import { detailsDrawerState$ } from './detailsDrawer$';
import { withLatestFrom } from 'rxjs/operators';
import { loadedState$ } from 'loadedState/loadedState$';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import {
  APILoadTraversalResponse,
  ArdoqId,
  LoadedState,
} from '@ardoq/api-types';

const displayComponent =
  (shouldDetailsDrawerOpen: boolean) =>
  (traversalResponse: APILoadTraversalResponse) => {
    dispatchAction(
      setDrawerData({
        componentId: traversalResponse.startSetResult[0],
        scopeData: enhanceScopeData(traversalResponse.scopeData),
      })
    );
    if (!shouldDetailsDrawerOpen) {
      return;
    }
    dispatchAction(hideRightPane());
    dispatchAction(showDetailsDrawer());
  };

const showComponent = displayComponent(true);
const updateComponent = displayComponent(false);

const getTraversalResponseForComponents = (componentIds: ArdoqId[]) =>
  from(componentApi.fetch(componentIds[0])).pipe(
    map(api.logErrorIfNeeded),
    excludeNullAndUndefined(),
    map(componentApiResponse => {
      return getTraversalWithStartSetAndWildcardTripleOfType(
        componentIds,
        componentApiResponse
      );
    })
  );

const handleOpenDetailsDrawer = routine(
  ofType(openDetailsDrawer),
  extractPayload(),
  exhaustMap(getTraversalResponseForComponents),
  exhaustMap(reqArgs => traversalApi.loadTraversal(reqArgs)),
  map(api.logErrorIfNeeded),
  excludeNullAndUndefined(),
  tap(showComponent)
);
const handleUpdateDetailsDrawer = routine(
  ofType(updateDetailsDrawer),
  extractPayload(),
  exhaustMap(getTraversalResponseForComponents),
  exhaustMap(reqArgs => traversalApi.loadTraversal(reqArgs)),
  map(api.logErrorIfNeeded),
  excludeNullAndUndefined(),
  tap(updateComponent)
);

const closeDetailsDrawerIfComponentIsClosed = (
  loadedStates: LoadedState[],
  componentId: ArdoqId
) => {
  if (
    !loadedStateOperations.componentIdIsIncludedInLoadedState(
      loadedStates,
      componentId
    )
  ) {
    dispatchAction(hideDetailsDrawer());
  }
};

const closeDetailsDrawerRoutine = routine(
  ofType(
    deleteLoadedState,
    clearLoadedState,
    setTraversalContextComponent,
    toggleLoadedStateVisibility
  ),
  withLatestFrom(detailsDrawerState$, loadedState$),
  tap(async ([, { componentId }, loadedStates]) => {
    if (!componentId) {
      return;
    }
    closeDetailsDrawerIfComponentIsClosed(loadedStates, componentId);
  })
);

export const detailsDrawerRoutines = collectRoutines(
  handleOpenDetailsDrawer,
  handleUpdateDetailsDrawer,
  closeDetailsDrawerRoutine
);
