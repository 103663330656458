import { s16, s64 } from '@ardoq/design-tokens';
import { Checkbox, TextInput } from '@ardoq/forms';
import { Stack } from '@ardoq/layout';
import { DatasourceTable } from '@ardoq/table';
import { header4, Link } from '@ardoq/typography';
import { SchedulePolicySelector } from 'integrations/common/components/schedulePolicySelector/SchedulePolicySelector';
import { ReactNode } from 'react';
import { styled } from 'styled-components';
import { ErrorNotification } from '@ardoq/status-ui';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import { AsyncStatus } from 'integrations/common/types/api';
import { ScheduleFormValues } from '../../streams/schedule/schedule$';
import { Island } from '@ardoq/page-layout';
import { Space } from '@ardoq/style-helpers';

export type ScheduleEditorProps = {
  tables: { name: string; targetWorkspace?: string; rootWorkspace: string }[];
  connectionName: string;
  scheduleData: ScheduleFormValues;
  setScheduleData: (scheduleData: Partial<ScheduleFormValues>) => void;
  isNew: boolean;
  isAlreadyExisting: boolean;
  transferDirection: TransferDirection;
  transferStatus: AsyncStatus;
};

export const ScheduleEditor = ({
  tables,
  connectionName,
  scheduleData,
  setScheduleData,
  isNew,
  isAlreadyExisting,
  transferDirection,
  transferStatus,
}: ScheduleEditorProps) => {
  const validateName = (name: string) => {
    setScheduleData({ errorMessage: name.trim() ? '' : "Name can't be empty" });
    return name;
  };

  // it should not be allowed to schedule a transfer if the transfer is not successful
  if (transferStatus !== 'SUCCESS') {
    return null;
  }

  return (
    <Island
      title={isNew ? `Schedule ${transferDirection}` : 'Update schedule'}
      footerJustify="end"
      maxWidth="100%"
    >
      {isAlreadyExisting && (
        <ErrorNotification>
          A schedule with this configuration already exists. Manage your
          schedules in the Overview Section.
        </ErrorNotification>
      )}
      <IslandContent>
        <FirstColumn>
          <InputContainer>
            <TextInput
              label="Name"
              value={scheduleData.name}
              onValueChange={t => setScheduleData({ name: validateName(t) })}
              errorMessage={scheduleData.errorMessage}
            />
            <SchedulePolicySelector
              title="Interval"
              schedulePolicy={scheduleData.policy}
              onSchedulePolicyChange={policy => setScheduleData({ policy })}
            />
          </InputContainer>
          {transferDirection === TransferDirection.IMPORT && (
            <Column $gap="s8">
              <Label>Manual data changes</Label>
              <Checkbox
                onChange={() =>
                  setScheduleData({
                    managesWorkspace: !scheduleData.managesWorkspace,
                  })
                }
                isChecked={scheduleData.managesWorkspace}
              >
                <div>
                  Disable manual data changes in the workspace.
                  <br />
                  <span>
                    Update only data from the scheduled{' '}
                    {transferDirection === TransferDirection.IMPORT
                      ? 'import'
                      : 'export'}
                    . Learn more about{' '}
                    <Link
                      href="https://help.ardoq.com/en/articles/44033-managed-workspaces"
                      target="_blank"
                      hideIcon={true}
                    >
                      manual data changes
                    </Link>
                    .
                  </span>
                </div>
              </Checkbox>
            </Column>
          )}
        </FirstColumn>
        <Column>
          <ColumnSection label="Source">{connectionName}</ColumnSection>
          <ColumnSection label="Overview">
            <DatasourceTable
              dataSource={tables}
              columns={[
                { dataIndex: 'name', title: 'Table' },
                { title: '→' },
                {
                  dataIndex: 'rootWorkspace',
                  title: 'Workspace',
                  valueRender: (rootWorkspace, row) =>
                    row?.targetWorkspace
                      ? `${rootWorkspace} (source) - ${row?.targetWorkspace} (target)`
                      : rootWorkspace,
                },
              ]}
            />
          </ColumnSection>
        </Column>
      </IslandContent>
    </Island>
  );
};

const ColumnSection = ({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) => {
  return (
    <Stack gap="medium">
      <Label>{label}</Label>
      {children}
    </Stack>
  );
};

const Label = styled.div`
  ${header4}
`;

const Column = styled(Space).attrs({ $isVertical: true })``;

const FirstColumn = styled(Column)`
  padding-right: ${s64};
`;

const IslandContent = styled(Space)`
  padding: ${s16};
`;

const InputContainer = styled(Column)`
  max-width: 448px;
`;
