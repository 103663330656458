import { reducer } from '@ardoq/rxbeach';
import { toggleEndDateEnabled } from './actions';
import type { IsEndDateEnabledStreamShape } from 'broadcasts/broadcastBuilder/broadcastSchedulerAndReminderForm/endDate/types';

const handleToggleEndDateEnabled = (state: IsEndDateEnabledStreamShape) => ({
  isEndDateEnabled: !state.isEndDateEnabled,
});

export const reducers = [
  reducer(toggleEndDateEnabled, handleToggleEndDateEnabled),
];
