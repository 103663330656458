import {
  PredefinedQueryOption,
  APIAudiencePreviewResponse,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const setPredefinedReferenceTypeQueryOptions = actionCreator<
  PredefinedQueryOption[]
>('[predefinedAudienceDialog] SET_PREDEFINED_REFERENCE_TYPE_QUERY_OPTIONS');

export const openExistingPredefinedAudienceDialog = actionCreator<
  PredefinedQueryOption[]
>('[predefinedAudienceDialog] OPEN_EXISTING_PREDEFINED_AUDIENCE_DIALOG');

export const openNewPredefinedAudienceDialog = actionCreator(
  '[predefinedAudienceDialog] OPEN_NEW_PREDEFINED_AUDIENCE_DIALOG'
);

export const fetchPredefinedAudiencePreview = actionCreator(
  '[predefinedAudienceDialog] FETCH_PREDEFINED_AUDIENCE_PREVIEW'
);

export const setPredefinedAudiencePreview =
  actionCreator<APIAudiencePreviewResponse>(
    '[predefinedAudienceDialog] SET_PREDEFINED_AUDIENCE_PREVIEW'
  );

export const notifyFailedToFetchPredefinedAudiencePreview = actionCreator(
  '[predefinedAudienceDialog] NOTIFY_FAILED_TO_FETCH_AUDIENCE_PREVIEW'
);

export const togglePredefinedAudienceQueryCheckbox =
  actionCreator<PredefinedQueryOption>(
    '[predefinedAudienceDialog] TOGGLE_PREDEFINED_AUDIENCE_QUERY_CHECKBOX'
  );

export const setTableFilter = actionCreator<string>(
  '[predefinedAudienceDialog] SET_TABLE_FILTER'
);
