import { sortBy } from 'lodash';
import { WithPopover, closePopover } from '@ardoq/popovers';
import { ArdoqId } from '@ardoq/api-types';
import { pluralize } from '@ardoq/common-helpers';

type PopoverWorkspaceItems = {
  id: ArdoqId;
  name: string;
}[];

type ListUsageProps = {
  workspaces: PopoverWorkspaceItems;
  onClickWorkspace: (workspaceId: ArdoqId) => void;
  numTemplates: number;
};

const ListWorkspacesPopover = ({
  workspaces,
  onClickWorkspace,
  numTemplates,
}: ListUsageProps) => {
  const description = [
    `${workspaces.length} ${pluralize('workspace', workspaces.length)}`,
    numTemplates &&
      ` and ${numTemplates} ${pluralize('template', numTemplates)}`,
  ]
    .filter(Boolean)
    .join('');

  return (
    <WithPopover
      content={() =>
        sortBy(workspaces, 'name').map(item => (
          <a
            key={item.id}
            href="#"
            style={{ display: 'block', minWidth: 150 }}
            onClick={() => {
              closePopover();
              onClickWorkspace(item.id);
            }}
          >
            {item.name}
          </a>
        ))
      }
    >
      {description}
    </WithPopover>
  );
};

export default ListWorkspacesPopover;
