import {
  createTransferConfig,
  saveConfiguration,
  updateTransferConfig,
} from 'integrations/common/streams/transferConfigs/actions';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { selectionState$ } from '../streams/selectionState/selectionState$';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import {
  getSavableTransferConfig,
  isSavedTransferConfig,
  isServiceNowConfig,
} from 'integrations/common/streams/transferConfigs/utils';
import {
  SavedTransferConfig,
  ServiceNowConfig,
  TransferConfig,
} from '@ardoq/api-types/integrations';
import fp from 'lodash/fp';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { setTransferConfigId } from 'integrations/common/streams/activeIntegrations/actions';
import { loadTransferConfig } from 'integrations/common/streams/transferConfigs/actions';
import { legacyConfigs$ } from 'integrations/service-now/streams/legacyConfigs/legacyConfigs$';
import { applySourceConfig } from 'integrations/service-now/streams/selectionState/actions';
import { startLoadTransferConfigModal } from 'integrations/service-now/modals/LoadTransferConfigModal';
import { startMissingConnectionModal } from 'integrations/common/modals/missingConnectionModal/MissingConnectionModal';
import { navigateToSelectData } from 'integrations/common/navigation/actions';
import { getSourceConfig } from '../utils';

const handleSaveIntegrationConfigurations = routine(
  ofType(saveConfiguration),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'servicenow-v3'),
  withLatestFrom(
    getActiveIntegrationStream('servicenow-v3'),
    getTabularMappingStream('servicenow-v3'),
    transferConfigs$,
    selectionState$,
    getConnectionsStream('servicenow-v3')
  ),
  tap(
    ([
      { integrationId, name, isNewConfig },
      activeIntegration,
      tabularMapping,
      transferConfigs,
      selectionState,
      connectionsState,
    ]) => {
      const sourceConfig = getSourceConfig(selectionState, connectionsState);

      const savableConfig: TransferConfig = {
        ...getSavableTransferConfig({
          transferConfigs,
          activeIntegration,
          tabularMapping,
        }),
        name,
        sourceConfig,
      };

      const isCreation =
        isNewConfig && activeIntegration.selectedTransferConfigId;

      if (isSavedTransferConfig(savableConfig) && !isCreation) {
        return dispatchAction(
          updateTransferConfig({
            integrationId,
            config: savableConfig,
          })
        );
      }
      /*
       * saving a new config
       */
      return dispatchAction(
        createTransferConfig({
          integrationId,
          config: fp.omit(
            ['_id', '_version', 'lastUpdated'],
            savableConfig
          ) as TransferConfig,
        })
      );
    }
  )
);

const handleLoadTransferConfig = routine(
  ofType(loadTransferConfig),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'servicenow-v3'),
  withLatestFrom(legacyConfigs$, getConnectionsStream('servicenow-v3')),
  tap(
    ([
      { transferConfig, integrationId },
      { configs: legacyConfigs },
      { connections },
    ]) => {
      if (!isServiceNowConfig(transferConfig)) {
        return;
      }

      const handleLoadConfig = (
        config: SavedTransferConfig<ServiceNowConfig>
      ) => {
        dispatchAction(
          setTransferConfigId({
            integrationId,
            id: config._id,
          })
        );
        dispatchAction(navigateToSelectData({ integrationId }));
        if (config.sourceConfig) {
          dispatchAction(applySourceConfig(config.sourceConfig));
        }
      };
      const sourceConfig = transferConfig?.sourceConfig;
      const connection =
        sourceConfig &&
        connections.find(conn => conn._id === sourceConfig.connectionId);

      startLoadTransferConfigModal({
        onSubmit: () => {
          if (!connection) {
            return startMissingConnectionModal({
              onCancel: () => null,
              onSubmit: connection => {
                const legacyConfig = legacyConfigs.find(
                  lc => lc.name === transferConfig.name
                );
                const enrichedConfig = fp.update(
                  'sourceConfig',
                  sc => ({
                    ...(sc || {}),
                    connectionId: connection._id,
                    tables: sc?.tables ?? legacyConfig?.tables ?? [],
                  }),
                  transferConfig
                );
                handleLoadConfig(enrichedConfig);
              },
            });
          }
          handleLoadConfig(transferConfig);
        },
      });
    }
  )
);

export default [handleSaveIntegrationConfigurations, handleLoadTransferConfig];
