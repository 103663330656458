import { actionCreator } from '@ardoq/rxbeach';
import { ReportTemplate } from '@ardoq/report-builder';
import {
  APISearchAggregatesResponse,
  ArdoqId,
  QueryBuilderQuery,
  Report,
  ZonesBySubdivisionsIds,
} from '@ardoq/api-types';
import { RefObject } from 'react';
import { EnhancedSearchResponse, ReportSort } from '@ardoq/report-reader';
import { Result } from '@ardoq/common-helpers';

export const newReportPropertySelected = actionCreator<Partial<ReportTemplate>>(
  '[reportBuilder] NEW_REPORT_PROPERTY_SELECTED'
);

export const reportWasSaved = actionCreator<Report>(
  '[reportBuilder] REPORT_WAS_SAVED'
);

export const reportSaveButtonWasClickedOnNewReport = actionCreator(
  '[reportBuilder] REPORT_SAVE_BUTTON_WAS_CLICKED_ON_NEW_REPORT'
);

export const reportSaveButtonWasClickedOnExistingReport = actionCreator<{
  shouldKeepHistoricalData: boolean;
  hasSubdivisionsFeature: boolean;
}>('[reportBuilder] REPORT_SAVE_BUTTON_WAS_CLICKED_ON_EXISTING_REPORT');

export const reportHistoricalDataWasDiscarded = actionCreator(
  '[reportBuilder] REPORT_HISTORICAL_DATA_WAS_DISCARDED'
);

export const reportSaveFailed = actionCreator<string>(
  '[reportBuilder] REPORT_SAVE_FAILED'
);

export const reportWasInvalidOnSaveButtonClick = actionCreator(
  '[reportBuilder] REPORT_WAS_INVALID_ON_SAVE_BUTTON_CLICK'
);

export const reportChangesWereDiscarded = actionCreator(
  '[reportBuilder] REPORT_CHANGES_WERE_DISCARDED'
);

export type SectionWasValidatedPayload = {
  section: RefObject<HTMLDivElement>;
  hasError: boolean;
};
export const sectionWasValidated = actionCreator<SectionWasValidatedPayload>(
  '[reportBuilder] SECTION_WAS_VALIDATED'
);

export const reportSearchWasTriggered = actionCreator<ReportSort>(
  '[reportBuilder] REPORT_SEARCH_WAS_TRIGGERED'
);

export const reportSearchFinished = actionCreator<
  Result<EnhancedSearchResponse>
>('[reportBuilder] REPORT_SEARCH_FINISHED');

export const reportSearchAggregatesWasTriggered = actionCreator<{
  reportTemplate: ReportTemplate;
  reportId?: ArdoqId;
}>('[reportBuilder] REPORT_SEARCH_AGGREGATES_WAS_TRIGGERED');

export const reportSearchAggregatesFinished = actionCreator<
  Result<APISearchAggregatesResponse>
>('[reportBuilder] REPORT_SEARCH_AGGREGATES_FINISHED');

type IsValid = boolean;
export const reportQueryWasValidated = actionCreator<IsValid>(
  '[reportBuilder] REPORT_QUERY_WAS_VALIDATED'
);

export const reportBuilderSortingWasChanged = actionCreator<ReportSort>(
  '[reportBuilder] SORTING_WAS_CHANGED'
);

export type AdvancedSearchQueryChangedPayload = {
  query: QueryBuilderQuery;
  isEmpty: boolean;
  isValid: boolean;
};

export const advancedSearchQueryChanged =
  actionCreator<AdvancedSearchQueryChangedPayload>(
    '[reportBuilder] ADVANCED_SEARCH_QUERY_CHANGED'
  );

export const newWorkspaceSelected = actionCreator<ArdoqId[]>(
  '[reportBuilder] NEW_WORKSPACE_SELECTED'
);

export const newZoneSelected = actionCreator<ZonesBySubdivisionsIds>(
  '[reportBuilder] NEW_ZONE_SELECTED'
);

export const finishedFetchingCustomFields = actionCreator(
  '[reportBuilder] FINISHED_FETCHING_CUSTOM_FIELDS'
);

export const changeWorkspaceSearchKey = actionCreator<string>(
  '[reportBuilder] CHANGE_WORKSPACE_SEARCH_KEY'
);

export const workspaceDrawerSelectionChanged = actionCreator<
  Record<ArdoqId, boolean>
>('[reportBuilder] WORKSPACE_DRAWER_SELECTION_CHANGED');

export const numberOfWorkspacesPerPageChanged = actionCreator<number>(
  '[reportBuilder] NUMBER_OF_WORKSPACES_PER_PAGE_CHANGED'
);

export const changeWorkspacesWithNoAccess = actionCreator<ArdoqId[]>(
  '[reportBuilder] CHANGE_WORKSPACES_WITH_NO_ACCESS'
);

export const resetAllColumnsNames = actionCreator(
  '[reportBuilder] RESET_ALL_COLUMNS_NAMES'
);
