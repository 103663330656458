import styled from 'styled-components';
import { ComponentRepresentation } from '@ardoq/renderers';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { ContextGraphItem, ContextGraphItemType } from './types';
import { CUSTOM_SVG_ICON_SELECTOR, Icon, IconName } from '@ardoq/icons';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';

const Item = styled.div`
  display: flex;
`;

const ComponentRepresentationContainer = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 16px;
  font-size: 16px;
  user-select: none;

  > ${CUSTOM_SVG_ICON_SELECTOR} {
    max-height: 16px;
    min-width: 16px;
  }
`;

const Label = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

interface ContextBarComponentProps {
  contextGraph: ContextGraphItem[][];
}
const ContextBarComponent = ({ contextGraph }: ContextBarComponentProps) => {
  if (!contextGraph.length) {
    return <>No workspace selected.</>;
  }

  return (
    <>
      {contextGraph.map((items, index) => (
        <Item key={`${index}_${items.map(({ id }) => id).join(',')}`}>
          <ul>
            {items.map(({ fill, name, type, id }) => {
              const componentRepresentationData =
                componentInterface.getRepresentationData(id) ?? {
                  isImage: false,
                  value: null,
                };

              const isComponent = type === ContextGraphItemType.COMPONENT;

              return (
                <li
                  key={id}
                  className={isComponent ? 'component' : undefined}
                  style={{
                    fill,
                    color: fill,
                  }}
                  {...{ [COMPONENT_ID_ATTRIBUTE]: id }}
                >
                  {isComponent && (
                    <ComponentRepresentationContainer>
                      <ComponentRepresentation
                        isImage={componentRepresentationData.isImage}
                        value={componentRepresentationData.value}
                        icon={componentRepresentationData.icon}
                      />
                    </ComponentRepresentationContainer>
                  )}{' '}
                  <Label data-tooltip-text={name.length >= 22 ? name : ''}>
                    {name}
                  </Label>
                </li>
              );
            })}
          </ul>
          {index < contextGraph.length - 1 && (
            <ul className="separator">
              <li className="reference-separator">
                <Icon
                  style={{ verticalAlign: 'middle' }}
                  iconName={IconName.ARROW_RIGHT_ALT}
                />
              </li>
            </ul>
          )}
        </Item>
      ))}
    </>
  );
};
export default ContextBarComponent;
