import { Observable } from 'rxjs';
import { CurrentBroadcast } from 'broadcasts/types';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { dispatchAction } from '@ardoq/rxbeach';
import { isValidAudience } from 'broadcasts/utils';
import { isEqual } from 'lodash';
import {
  fetchAudiencePreview,
  notifyIncompleteContentSelection,
} from './actions';

export const handleContentAndAudiencesChangeRoutine = (
  currentBroadcast$: Observable<CurrentBroadcast>
) =>
  currentBroadcast$.pipe(
    map(({ content, audiences }) => ({ content, audiences })),
    distinctUntilChanged(isEqual),
    tap(({ content, audiences }) => {
      dispatchAction(
        isValidAudience(content, audiences)
          ? fetchAudiencePreview({ content, audiences })
          : notifyIncompleteContentSelection()
      );
    })
  );
