import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { Filter } from './FilterBar/filterBarTypes';
import { AssetsBrowser2024ActionPayload } from './assetsBrowser2024Types';
import { AssetIdAndMeta } from '../../appContainer/DashboardContainer/utils';

export const setSelectedAssets = actionCreator<
  AssetsBrowser2024ActionPayload<ArdoqId[]>
>('[assetsBrowser2024] SET_SELECTED_ASSETS');

export const setRenameId = actionCreator<
  AssetsBrowser2024ActionPayload<ArdoqId | null>
>('[assetsBrowser2024] SET_RENAME_ID');

export const setCurrentPageNumber = actionCreator<
  AssetsBrowser2024ActionPayload<number>
>('[assetsBrowser2024] SET_CURRENT_PAGE_NUMBER');

export const setPerPage = actionCreator<AssetsBrowser2024ActionPayload<number>>(
  '[assetsBrowser2024] SET_PER_PAGE'
);

export const setIsUpdating = actionCreator<boolean>(
  '[assetsBrowser2024] SET_IS_UPDATING'
);

export const updateSorting = actionCreator<
  AssetsBrowser2024ActionPayload<string | null>
>('[assetsBrowser2024] UPDATE_SORTING');

export const setFilterActive = actionCreator<
  AssetsBrowser2024ActionPayload<Filter>
>('[assetsBrowser2024] SET_FILTER_ACTIVE');

export const setFilterInactive = actionCreator<
  AssetsBrowser2024ActionPayload<Filter>
>('[assetsBrowser2024] SET_FILTER_INACTIVE');

export const clearFilter = actionCreator<AssetsBrowser2024ActionPayload>(
  '[assetsBrowser2024] CLEAR_FILTER'
);

export const setSearchPhrase = actionCreator<
  AssetsBrowser2024ActionPayload<string>
>('[assetsBrowser2024] SET_SEARCH_PHRASE');

export const setExpandedFoldersIds = actionCreator<
  AssetsBrowser2024ActionPayload<string[]>
>('[assetsBrowser2024] UPDATE_EXPANDED_FOLDERS_IDS');

export type UpdateDataSourcePayload = {
  alwaysActiveFilters: Filter[];
  useFolders: boolean;
};

export const updateDataSource = actionCreator<
  AssetsBrowser2024ActionPayload<UpdateDataSourcePayload>
>('[assetsBrowser2024] UPDATE_DATA_SOURCES');

export const expandFolder = actionCreator<
  AssetsBrowser2024ActionPayload<string>
>('[assetsBrowser2024] EXPAND_FOLDER');

type MoveAssetPayload = {
  assets: AssetIdAndMeta[];
  folderId: ArdoqId | null;
};

export const moveAsset = actionCreator<
  AssetsBrowser2024ActionPayload<MoveAssetPayload>
>('[assetsBrowser2024] MOVE_ASSET');

// Temporary while api does not allow direct in folder creation of assets
export const moveAssetWithoutDeselection = actionCreator<
  AssetsBrowser2024ActionPayload<MoveAssetPayload>
>('[assetsBrowser2024] MOVE_ASSET_WITHOUT_DESELECTION');
