import {
  ChatMessage,
  MessageBubblePosition,
} from 'bestPracticeAssistant/types';
import { colors, r8, s16, s8 } from '@ardoq/design-tokens';
import { text2 } from '@ardoq/typography';
import styled from 'styled-components';
import FeedbackButtons, { FeedbackButtonsProps } from './FeedbackButtons';
import { replaceCitationTokensInMessageWithLinks } from 'bestPracticeAssistant/utils';
import BestPracticeAssistant from './BestPracticeAssistant';
import { MarkdownViewer } from '@ardoq/markdown';
import { LoadingAnimation } from './LoadingAnimation';
import { dateFromTimestamp, formatDateTime } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { Box, Inline } from '@ardoq/layout';

const messageBubblePositionMap = {
  left: 'start',
  right: 'end',
};

const messageBubbleBackgroundColorMap = {
  left: colors.white,
  right: colors.grey95,
};

const MessageBubbleContent = styled.div`
  border-radius: ${r8};
  padding: ${s8} ${s16};
  border: ${colors.grey80} solid 1px;
  display: flex;
  word-break: break-word;

  p {
    line-height: 1.5;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const RoleLabel = styled.div`
  ${text2}
  display: flex;
`;

const MessageBubble = styled.div<{ $position: MessageBubblePosition }>`
  align-self: ${({ $position }) => messageBubblePositionMap[$position]};
  align-items: ${({ $position }) => messageBubblePositionMap[$position]};
  max-width: 90%;
  min-width: 60%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  & ${MessageBubbleContent} {
    background: ${({ $position }) =>
      messageBubbleBackgroundColorMap[$position]};
  }
  & ${RoleLabel} {
    align-self: ${({ $position }) => messageBubblePositionMap[$position]};
  }
`;

const SecondaryText = styled.div`
  ${text2};
  color: ${colors.grey60};
  display: flex;
  align-items: center;
`;

const maybeShowFormattedTime = ({
  timestamp,
  isLastMessageAndLoading,
}: ChatMessageItemProps): JSX.Element | null => {
  if (!timestamp) return null;
  if (isLastMessageAndLoading) return null;

  return (
    <SecondaryText>
      {formatDateTime(dateFromTimestamp(timestamp), getCurrentLocale())}
    </SecondaryText>
  );
};

const rolePositionMap: Record<ChatMessage['role'], MessageBubblePosition> = {
  user: 'right',
  assistant: 'left',
  pinned: 'left',
};

const roleLabelMap: Record<ChatMessage['role'], string | JSX.Element> = {
  user: 'You',
  assistant: <BestPracticeAssistant logoSize="24px" />,
  pinned: <BestPracticeAssistant logoSize="24px" />,
};

export type ChatMessageItemProps = ChatMessage & {
  isLastMessageAndLoading: boolean;
  feedback?: FeedbackButtonsProps;
};

export const ChatMessageItem = (props: ChatMessageItemProps) => {
  const position = rolePositionMap[props.role];
  const roleLabel = roleLabelMap[props.role];

  if (props.isLastMessageAndLoading && !props.content) {
    return (
      <MessageBubble $position={position}>
        <RoleLabel>{roleLabel}</RoleLabel>
        <MessageBubbleContent>
          <Box marginBottom="small">
            <LoadingAnimation isActive rows={3} columns={3} cellSize={10} />
          </Box>
        </MessageBubbleContent>
      </MessageBubble>
    );
  }

  return (
    <MessageBubble $position={position}>
      <RoleLabel>{roleLabel}</RoleLabel>
      <MessageBubbleContent>
        <MarkdownViewer
          content={props.content}
          sanitationLevel="strict"
          transformHTML={html =>
            replaceCitationTokensInMessageWithLinks(html, props.citations ?? {})
          }
        />
      </MessageBubbleContent>
      <Inline gap="xxsmall" justify="flex-end">
        {maybeShowFormattedTime({ ...props })}
        {props.feedback && <FeedbackButtons {...props.feedback} />}
      </Inline>
    </MessageBubble>
  );
};
