import { KeyboardEvent, useRef, useEffect } from 'react';
import { add } from 'lodash';
import { ComponentBackboneModel, FieldBackboneModel } from 'aqTypes';
import {
  APIComponentAttributes,
  APIFieldType,
  APIModelAttributes,
} from '@ardoq/api-types';
import { ContextSort } from '@ardoq/common-helpers';
import { getCurrentLocale, localeCompareNumericLowercase } from '@ardoq/locale';
import getReferenceTypeAsSvg from './getReferenceTypeAsSvg';
import Component from 'models/component';
import { modelOperations } from 'models/modelOperations';
import Components from 'collections/components';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { modelInterface } from 'modelInterface/models/modelInterface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import { tagInterface } from 'modelInterface/tags/tagInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import {
  ComponentAttributes,
  GetNameAtRow,
  GridEditorDisplayableField,
  GridEditorField,
  GridEditorFieldType,
  GridEditorSharedState,
  SortFunction,
} from './types';
import { logWarn } from '@ardoq/logging';

export function getGlobalCollectionMetadata() {
  return {
    countComponentsLoaded: componentInterface.getComponentsCount(),
    countFieldsLoaded: fieldInterface.getFieldsCount(),
    countFiltersLoaded: filterInterface.getFilterCount(),
    countModelsLoaded: modelInterface.getModelCount(),
    countReferencesLoaded: referenceInterface.getReferencesCount(),
    countTagsLoaded: tagInterface.getTagCount(),
    countWorkspacesLoaded: workspaceInterface.getWorkspaceCount(),
  };
}

export const getSortFunction =
  (getNameAtRow: GetNameAtRow): SortFunction =>
  (sortOrder?: boolean) =>
  ([rowIndexA], [rowIndexB]) => {
    const direction = sortOrder === true ? 1 : -1;
    const locale = getCurrentLocale();
    const nameA = getNameAtRow(rowIndexA);
    const nameB = getNameAtRow(rowIndexB);

    if (nameA === nameB) return direction * (rowIndexA - rowIndexB);
    if (!nameA) return direction;
    if (!nameB) return -direction;

    return sortOrder === undefined
      ? direction * (rowIndexA - rowIndexB)
      : direction * localeCompareNumericLowercase(nameA, nameB, locale);
  };

export const localedSort: SortFunction =
  (sortOrder?: boolean) =>
  ([indexA, valueA], [indexB, valueB]) => {
    if (valueA === valueB || sortOrder === undefined) {
      return indexA - indexB;
    }

    const direction = sortOrder ? 1 : -1;
    const locale = getCurrentLocale();

    return direction * localeCompareNumericLowercase(valueA, valueB, locale);
  };

export const getTypeNameOrNull = (component: ComponentBackboneModel) => {
  const componentType = component.getMyType();
  if (!componentType) {
    logWarn(Error('No component type found for component'), null, {
      componentId: component.getId(),
    });
    return null;
  }
  if (componentType.id === 'NONE') {
    logWarn(Error('Got deletedModelType for component'), null, {
      componentId: component.getId(),
    });
  }
  return componentType.name || null;
};

const svgStyleRule = (className: string, svg: string) => `
  .${className}::before {
    background-image: url("data:image/svg+xml,${encodeURIComponent(svg)}");
  }
`;

export const typeNameToClassName = (typeName: string) =>
  typeName.toLowerCase().replace(/\W+/g, '-');

export const getStylesheetForModel = async (model: APIModelAttributes) => {
  const referenceTypes = modelOperations.getReferenceTypes(model);
  const styleRules = await Promise.all(
    Object.values(referenceTypes).map(async typeDict => {
      const className = typeNameToClassName(typeDict.name);
      const svg = await getReferenceTypeAsSvg(typeDict);
      return svgStyleRule(className, svg);
    })
  );
  const stylesheet = document.head.appendChild(document.createElement('style'));
  stylesheet.textContent = styleRules.join('\n');
  return stylesheet;
};

const listFields: Array<APIFieldType | GridEditorFieldType> = [
  APIFieldType.LIST,
  APIFieldType.SELECT_MULTIPLE_LIST,
];
/**
 * This function was added due to a limit in the data structure that prevents
 * us from knowing if a field has a default value or not.
 */
export const isListFieldType = (
  fieldType: GridEditorDisplayableField['fieldType']
) => {
  if (!fieldType) return false;
  return listFields.includes(fieldType);
};

export const isDisplayableField = (
  field: GridEditorField
): field is GridEditorDisplayableField => {
  if (field.isSeparator) {
    return false;
  }
  return Boolean(field.name);
};

/**
 * Ensures that a field is both
 * - displayable
 * - not disabled
 */
export const isFieldChecked = (
  field: GridEditorField,
  disabledFieldNames: GridEditorSharedState['disabledFields']
) => {
  return isDisplayableField(field) && !disabledFieldNames.has(field.name);
};

export const fieldToGridEditorField = (
  field: FieldBackboneModel
): GridEditorField => {
  const name = field.name();
  const label = field.getRawLabel();
  const description = field.getDescription();
  return {
    field,
    fieldType: field.getType(),
    name,
    label: label || name,
    checked: true,
    description: description || '',
  };
};

/**
 * Scenarios cannot use batch api, and we cannot use concurrent code because
 * the backend has race-conditions on this endpoint. See ARD-9818
 */
export const createComponentsForScenario = async (data: {
  components: ComponentAttributes[];
  scenarioId: string;
}): Promise<APIComponentAttributes[]> => {
  const { components } = data;
  const createdComponents: APIComponentAttributes[] = [];

  for (const attributes of components) {
    const component = Component.create(attributes, {
      collection: Components.collection,
      tracking: { data: { from: 'grid editor' } },
    });

    // NOTE: This only saves the component to the backend, it has to be added
    // to the relevant collections/streams manually.
    await component.save();
    createdComponents.push(
      structuredClone(component.attributes) as APIComponentAttributes
    );
  }

  return createdComponents;
};

// ============================================================================
// Translated reference field utilities
// ============================================================================
/**
 * 'refType' is a translation of 'reference.type' but is also special because it
 * is a reference attribute column that has been moved away from the rest of the
 * reference attributes.
 */
const REF_TYPE_TRANSLATED = 'refType';

const REFERENCE_FIELD_TRANSLATIONS = new Map([
  [REF_TYPE_TRANSLATED, 'type'],
  ['refDisplayText', 'displayText'],
  ['refDescription', 'description'],
]);

export const isTranslatedReferenceField = (name: string) => {
  return REFERENCE_FIELD_TRANSLATIONS.has(name);
};

export const isReferenceTypeField = (name: string) => {
  return name === REF_TYPE_TRANSLATED;
};

// ============================================================================
// Translated reference source/target-component utilities
// ============================================================================

const SRC_FIELD_TRANSLATIONS = new Map([
  ['srcName', 'name'],
  ['srcType', 'type'],
  ['srcParent', 'parent'],
]);

const TARGET_FIELD_TRANSLATIONS = new Map([
  ['targetName', 'name'],
  ['targetType', 'type'],
  ['targetParent', 'parent'],
]);

export const isTranslatedSourceComponentField = (name: string) => {
  return SRC_FIELD_TRANSLATIONS.has(name);
};

export const isTranslatedTargetComponentField = (name: string) => {
  return TARGET_FIELD_TRANSLATIONS.has(name);
};

export const getTranslatedTargetComponentFields = () => {
  return Array.from(TARGET_FIELD_TRANSLATIONS.keys());
};

export const getTranslatedSourceComponentFields = () => {
  return Array.from(SRC_FIELD_TRANSLATIONS.keys());
};

export const toOriginalAttributeName = (name: string): string => {
  if (isTranslatedSourceComponentField(name)) {
    return SRC_FIELD_TRANSLATIONS.get(name) as string;
  }
  if (isTranslatedTargetComponentField(name)) {
    return TARGET_FIELD_TRANSLATIONS.get(name) as string;
  }
  if (isTranslatedReferenceField(name)) {
    return REFERENCE_FIELD_TRANSLATIONS.get(name) as string;
  }
  return name;
};

/**
 * Get a ContextSort that is translated to the original attribute names.
 */
export const toTranslatedSort = (sort: ContextSort): ContextSort => ({
  ...sort,
  attr: toOriginalAttributeName(sort.attr),
});

const DEFAULT_REFERENCE_TAB_FIELDS = new Set([
  ...getTranslatedTargetComponentFields(),
  REF_TYPE_TRANSLATED,
  ...getTranslatedSourceComponentFields(),
]);

/**
 * Check if activeFields contain any custom reference fields.
 */
export const checkHasReferenceFieldsSection = (
  activeFields: GridEditorField[]
) => {
  return activeFields.some(field => {
    if (field.isSeparator) {
      return false;
    }
    return !DEFAULT_REFERENCE_TAB_FIELDS.has(field.name);
  });
};

/**
 * Sum the widths of columns based on groups.
 * Assumes columns are in order
 */
const calculateGroupedColumnWidths = (
  columnGroups: string[][],
  columnWidths: Record<string, number>
): number[] => {
  return columnGroups.map(fieldNameGroup => {
    return fieldNameGroup.map(name => columnWidths[name] ?? 0).reduce(add, 0);
  });
};

/**
 * ReferenceHeader adds an extra header to the table to allow for extra options,
 * this header groups individual columns into src, ref, and target headers.
 */
const referenceHeaderColumnGroups = [
  getTranslatedSourceComponentFields(),
  [REF_TYPE_TRANSLATED],
  getTranslatedTargetComponentFields(),
];
export const calculateGroupedReferenceHeaderWidths = (
  columnWidths: Record<string, number>
) => calculateGroupedColumnWidths(referenceHeaderColumnGroups, columnWidths);

/**
 * Listen to the scroll event from root container of Handsontable to observe
 * scrolling within handsontable (using capture: true).
 */
export const useEditorScrollLeftObserver = (
  editorContainer: HTMLDivElement | null,
  callback: (val: number) => void
) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (!editorContainer) {
      return;
    }

    const onScroll = (event: Event) => {
      const targetIsElement = event.target instanceof Element;
      if (!targetIsElement) {
        return;
      }

      const containerWidth = editorContainer.offsetWidth;
      const scrollContainerWidth = event.target.scrollWidth;

      // filter out "row header" which also triggers this function
      const targetIsScrollable = scrollContainerWidth < containerWidth;
      if (targetIsScrollable) {
        return;
      }

      const scrollLeft = Math.max((event.target.scrollLeft ?? 0) - 1, 0);
      callbackRef.current(scrollLeft);
    };

    const listenerOptions: AddEventListenerOptions = {
      passive: true,
      capture: true, // to observe scroll on descendant element
    };

    editorContainer.addEventListener('scroll', onScroll, listenerOptions);
    return () => {
      editorContainer.removeEventListener('scroll', onScroll, listenerOptions);
    };
  });
};

/**
 * Some keyboard keys have multiple names, this narrows the possibilities and
 * provides better typings.
 */
type NormalizedKeyboardKeys =
  | 'esc'
  | 'tab'
  | 'enter'
  | 'up'
  | 'down'
  | 'space'
  | 'other';

export const getNormalizedKeydownKey = (
  event: KeyboardEvent
): NormalizedKeyboardKeys => {
  switch (event.key) {
    case 'Escape':
    case 'Esc':
      return 'esc';

    case 'Tab':
      return 'tab';

    case 'Enter':
      return 'enter';

    case ' ':
      return 'space';

    case 'Up':
    case 'ArrowUp':
      return 'up';

    case 'Down':
    case 'ArrowDown':
      return 'down';

    default:
      return 'other';
  }
};
