import { advancedSearchApi } from '@ardoq/api';
import { logError } from '@ardoq/logging';
import {
  BooleanOperator,
  Operator,
  QueryBuilderQuery,
  SearchContext,
  SearchResultDoc,
} from '@ardoq/api-types';
import { isArdoqError } from '@ardoq/common-helpers';

export const getLoadComponentOfGivenTypeAsync =
  (componentTypeName: string) => async (typedPhrase: string) => {
    const payload = getSearchQueryWithComponentTypeAndSubstringForComponentName(
      componentTypeName,
      typedPhrase
    );

    const response = await advancedSearchApi.search<SearchResultDoc>(payload);

    if (isArdoqError(response)) {
      logError(
        Error('Failed to fetch components by id with advanced search api')
      );
      return [];
    }

    return response.results.map(({ doc }) => ({
      label: doc.name,
      value: doc._id,
    }));
  };

const getSearchQueryWithComponentTypeAndSubstringForComponentName = (
  componentTypeName: string,
  typedPhrase: string
): QueryBuilderQuery => {
  return {
    condition: BooleanOperator.AND,
    rules: [
      {
        id: 'type',
        field: 'type',
        type: 'string',
        input: 'select',
        operator: Operator.EQUAL,
        value: SearchContext.COMPONENT,
      },
      {
        condition: BooleanOperator.AND,
        rules: [
          {
            id: 'typeName',
            field: 'typeName',
            input: 'text',
            type: 'string',
            operator: Operator.EQUAL,
            value: componentTypeName,
          },
          {
            id: 'name',
            field: 'name',
            input: 'text',
            type: 'string',
            operator: Operator.CONTAINS_SUBSTRING,
            value: typedPhrase,
          },
        ],
      },
    ],
  };
};
