import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import {
  DashboardBuilderChartWidget,
  DashboardTrackingEventsNames,
  widgetOperations,
} from '@ardoq/dashboard';
import { Select, SortableMultiselect } from '@ardoq/select';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  selectDataSource,
  selectField,
  updateSelectedWidget,
  widgetFilterDrawerClosed,
  widgetFilterDrawerOpened,
} from '../../actions';
import { FilterListIcon, IconName } from '@ardoq/icons';
import {
  ArdoqId,
  DataSourceField,
  WidgetDataSourceEntity,
  WidgetDataSourceTypes,
  WidgetTypes,
} from '@ardoq/api-types';
import { TextInput } from '@ardoq/forms';
import { trackEvent } from '../../../../tracking/tracking';
import { ExcludeFalsy } from '@ardoq/common-helpers';

import {
  dataSourceFieldToSelectOption,
  MISSING_FIELD_FROM_DATA_SOURCE,
} from '../../utils';
import { SmallSecondaryButton } from '@ardoq/button';
import { showDrawer } from '@ardoq/snowflakes';
import ReportFilterDrawer from '../../ReportFilterDrawer/ReportFilterDrawer';
import { IconBadge, StatusType } from '@ardoq/status-ui';
import { s8 } from '@ardoq/design-tokens';
import { popoverRegistry, SimpleTextPopover } from '@ardoq/popovers';

interface DataSourceSelectProps {
  selectedDataSourceId?: ArdoqId;
  availableDataSources: WidgetDataSourceEntity[];
  loadingDataSources: boolean;
}

const FILTER_BTN_POPOVER_ID = 'filter-widget-btn-disabled';
popoverRegistry.set(FILTER_BTN_POPOVER_ID, () => (
  <SimpleTextPopover text={'Filtering is unavailable when trend is enabled'} />
));

const getErrorMessageFromDataSource = (
  dataSource: WidgetDataSourceEntity | undefined | null
) => {
  if (!dataSource) return undefined;
  if (dataSource.sourceType === WidgetDataSourceTypes.REPORT) {
    const { error } = dataSource;
    return error
      ? `Selected report is failing ${
          error.display ? `\nDetails: ${error.display}` : ''
        }`
      : undefined;
  }
  return undefined;
};

const DataSourceSelect = ({
  selectedDataSourceId,
  availableDataSources,
  loadingDataSources,
}: DataSourceSelectProps) => {
  const selectedValue = selectedDataSourceId
    ? availableDataSources.find(
        dataSource => dataSource.sourceId === selectedDataSourceId
      )
    : null;

  return (
    <Select
      dataTestId="dashboard-data-source-select"
      autoFocus
      placeholder="Type or click to search"
      isLoading={loadingDataSources}
      label="Select report or survey"
      options={availableDataSources.map(dataSource => ({
        label: dataSource.name,
        value: dataSource._id,
        iconName:
          dataSource.sourceType === WidgetDataSourceTypes.REPORT
            ? IconName.DESCRIPTION
            : IconName.SURVEYS,
      }))}
      value={selectedValue?._id}
      onValueChange={value => {
        if (selectedValue?._id === value) return;
        const selectedDataSource = availableDataSources.find(
          dataSource => dataSource._id === value
        )!;
        trackEvent(
          selectedDataSource.sourceType === WidgetDataSourceTypes.SURVEY
            ? DashboardTrackingEventsNames.SELECTED_SURVEY_IN_DASHBOARD_BUILDER
            : DashboardTrackingEventsNames.SELECTED_REPORT_IN_DASHBOARD_BUILDER
        );
        dispatchAction(selectDataSource(selectedDataSource));
      }}
      errorMessage={getErrorMessageFromDataSource(selectedValue)}
    />
  );
};

interface NameInputProps {
  widgetName?: string;
}

const NameInput = ({ widgetName }: NameInputProps) => (
  <TextInput
    dataTestId="dashboard-chart-title-input"
    label="Chart title"
    placeholder="Type..."
    value={widgetName ?? ''}
    onValueChange={value =>
      dispatchAction(updateSelectedWidget({ name: value }))
    }
  />
);

const DISABLED_FIELD_SELECT_POPOVER_TEXT =
  'Select a report or a survey first to view the available fields';

const sourceFieldErrorFormatter = (fieldName: string) =>
  `The field ${fieldName} has been deleted from the report. Clear it from your chart to save this dashboard.`;

interface FieldSelectProps {
  selectedFieldName?: string;
  selectedDataSource?: WidgetDataSourceEntity;
  errorMessage?: string;
}

const FieldSelect = ({
  selectedFieldName,
  selectedDataSource,
  errorMessage,
}: FieldSelectProps) => {
  const isDisabled = !selectedDataSource;
  return (
    <Select
      dataTestId="dashboard-field-select"
      label="Select field"
      options={
        selectedDataSource?.availableFields
          ?.filter(({ custom, field }) => field || custom) // don't show reference columns here
          .map(({ label, name, type }) => ({
            label: `${label ?? name} ${type ? `(${type})` : ''}`,
            value: name,
          })) ?? []
      }
      value={selectedFieldName}
      popoverContent={
        isDisabled ? DISABLED_FIELD_SELECT_POPOVER_TEXT : undefined
      }
      onValueChange={value => {
        trackEvent(
          DashboardTrackingEventsNames.SELECTED_FIELD_IN_DASHBOARD_BUILDER
        );
        dispatchAction(
          selectField(
            value
              ? [
                  selectedDataSource!.availableFields!.find(
                    field => field.name === value
                  )!,
                ]
              : []
          )
        );
      }}
      isDisabled={isDisabled}
      isClearable
      errorMessage={errorMessage}
    />
  );
};

interface FieldMultiSelectProps {
  selectedFieldNames: string[];
  selectedDataSource?: WidgetDataSourceEntity;
  errorMessageFormatter: (fieldName: string) => string;
  selectedWidgetType: WidgetTypes;
}

const FieldMultiSelect = ({
  selectedFieldNames,
  selectedDataSource,
  errorMessageFormatter,
  selectedWidgetType,
}: FieldMultiSelectProps) => {
  const fields =
    (selectedWidgetType === WidgetTypes.LINE_CHART
      ? selectedDataSource?.availableFields?.filter(
          ({ custom, field }) => field || custom
        )
      : selectedDataSource?.availableFields) ?? [];

  const selectOptions = [
    ...fields,
    ...selectedFieldNames
      .filter(f => !fields.find(x => x.name === f))
      .map(
        f =>
          ({
            label: f,
            name: MISSING_FIELD_FROM_DATA_SOURCE,
          }) as DataSourceField
      ),
  ].map(dataSourceFieldToSelectOption);

  const selectValue = selectedFieldNames
    .map(fieldName => {
      const foundField = selectOptions.find(field => field.value === fieldName);
      return (
        foundField ?? {
          label: fieldName,
          value: MISSING_FIELD_FROM_DATA_SOURCE,
        }
      );
    })
    .filter(ExcludeFalsy);

  const missingField = selectValue?.find(
    field => field.value === MISSING_FIELD_FROM_DATA_SOURCE
  )?.label;

  return (
    <SortableMultiselect
      dataTestId="dashboard-field-select"
      label="Select fields"
      isDisabled={!selectedDataSource}
      value={selectValue}
      options={selectOptions}
      onChange={selectedOptions => {
        trackEvent(
          DashboardTrackingEventsNames.SELECTED_FIELD_IN_DASHBOARD_BUILDER
        );
        dispatchAction(
          selectField(
            selectedOptions
              ?.map(selectOption =>
                selectedDataSource!.availableFields!.find(
                  ({ name }) => selectOption.value === name
                )
              )
              .filter(ExcludeFalsy) ?? []
          )
        );
      }}
      errorMessage={missingField && errorMessageFormatter(missingField)}
    />
  );
};

export type DataSelectionSectionProps = {
  selectedFieldName?: string;
  availableDataSources: WidgetDataSourceEntity[];
  loadingDataSources: boolean;
  hasSourceFieldError: boolean;
  selectedWidget: DashboardBuilderChartWidget;
};

const showReportFilterEditDrawer = () => {
  dispatchAction(widgetFilterDrawerOpened());
  return showDrawer({
    stackPageName: 'dashboard-widget-filter-drawer',
    shouldCloseOnBackdropClick: true,
    shouldCloseOnEscapeKey: true,
    onClose: () => {
      dispatchAction(widgetFilterDrawerClosed());
    },
    renderDrawer: ({ handleCloserDrawer }) => (
      <ReportFilterDrawer handleCloseDrawer={handleCloserDrawer} />
    ),
  });
};

const DataSelectionSection = ({
  availableDataSources,
  loadingDataSources,
  hasSourceFieldError,
  selectedWidget,
}: DataSelectionSectionProps) => {
  const selectedDataSourceId = selectedWidget.datasource?.sourceId;
  const selectedDataSource = availableDataSources.find(
    dataSource => dataSource.sourceId === selectedDataSourceId
  );
  const isSelectedDataSourceReport =
    selectedDataSource?.sourceType === WidgetDataSourceTypes.REPORT;

  const shouldDisableFilterButton =
    widgetOperations.isNumberWidgetData(selectedWidget) &&
    widgetOperations.hasTrendEnabled(selectedWidget);

  const activeFiltersCount = Object.keys(selectedWidget.filters ?? {}).length;

  return (
    <SidebarMenuSection title="Data selection">
      <DataSourceSelect
        selectedDataSourceId={selectedDataSourceId}
        availableDataSources={availableDataSources}
        loadingDataSources={loadingDataSources}
      />
      {isSelectedDataSourceReport &&
      !widgetOperations.isTimeLineData(selectedWidget) ? (
        <SmallSecondaryButton
          onClick={() => {
            trackEvent(DashboardTrackingEventsNames.OPENED_WIDGET_FILTER);
            showReportFilterEditDrawer();
          }}
          popoverId={
            shouldDisableFilterButton ? FILTER_BTN_POPOVER_ID : undefined
          }
          isDisabled={shouldDisableFilterButton}
        >
          {activeFiltersCount ? (
            <IconBadge
              iconName={IconName.FILTER_LIST}
              statusType={StatusType.NEW}
              style={{ margin: `0 ${s8}`, height: 'auto' }}
              iconStyle={{ margin: 0 }}
            />
          ) : (
            <FilterListIcon style={{ margin: `0 ${s8}`, height: 'auto' }} />
          )}
          {`Filter${activeFiltersCount ? ` (${activeFiltersCount})` : ''}`}
        </SmallSecondaryButton>
      ) : null}
      {widgetOperations.isTableWidgetData(selectedWidget) ||
      widgetOperations.isLineChartData(selectedWidget) ? (
        <FieldMultiSelect
          selectedFieldNames={selectedWidget.fields ?? []}
          selectedDataSource={selectedDataSource}
          errorMessageFormatter={sourceFieldErrorFormatter}
          selectedWidgetType={selectedWidget.viewType}
        />
      ) : (
        <FieldSelect
          selectedFieldName={selectedWidget.fields[0] ?? ''}
          selectedDataSource={selectedDataSource}
          errorMessage={
            hasSourceFieldError
              ? sourceFieldErrorFormatter(selectedWidget.fields[0])
              : undefined
          }
        />
      )}
      <NameInput widgetName={selectedWidget.name} />
    </SidebarMenuSection>
  );
};

export default DataSelectionSection;
