import styled, { css } from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { SvgImage, SvgImageName } from '@ardoq/icons';
import { getFocusRingBorderCSS } from '@ardoq/style-helpers';
import { openHome } from '../components/AppMainSidebar/utils';

const ArdoqLogoWrapper = styled.button<{
  // TODO AM this should be <a> with href
  $shouldUseNewJourneyVersion: boolean;
}>`
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;

  ${({ $shouldUseNewJourneyVersion }) =>
    $shouldUseNewJourneyVersion
      ? css`
          margin: 4px;
          align-items: center;
          justify-content: left;
          padding-left: 8px;
          height: 96px;
          & > svg {
            width: 39px;
            height: 39px;
          }
          color: ${colors.iconActionBrand};
          background: ${colors.surfaceDefault};
          flex-shrink: 0;
          &:hover {
            color: ${colors.iconActionBrandHover};
            background: ${colors.surfaceDefault};
          }
        `
      : css`
          height: 56px;
          width: 56px;
          flex: 1 0 56px;
          & > svg {
            width: 52px;
            height: 52px;
          }
          color: ${colors.iconBrand};
          background: ${colors.iconBrand};
          &:hover {
            color: ${colors.iconActionBrandHover};
            background: ${colors.iconActionBrandHover};
          }
        `}

  &:focus-visible {
    ${getFocusRingBorderCSS()}
  }
`;

type LogoProps = {
  /**
   * Pass in `shouldUseNewJourneyVersion: true` if you want the version of
   * the logo that fits at the top of the new `AppMainSidebar` and
   * `shouldUseNewJourneyVersion: false` if you want the version of the logo
   * that fits in the old `NavBar`.
   */
  shouldUseNewJourneyVersion: boolean;
};

const Logo = ({ shouldUseNewJourneyVersion }: LogoProps) => {
  return (
    <ArdoqLogoWrapper
      $shouldUseNewJourneyVersion={shouldUseNewJourneyVersion}
      onClick={openHome}
      id="ardoqLogo" // User for browser test
    >
      {shouldUseNewJourneyVersion ? (
        <SvgImage
          role="img"
          aria-label={'Ardoq logo'}
          color={colors.iconBrand}
          svgImageName={SvgImageName.INVERTED_ARDOQ_LOGO}
        />
      ) : (
        <SvgImage
          role="img"
          aria-label="Ardoq logo"
          svgImageName={SvgImageName.ARDOQ_LOGO}
        />
      )}
    </ArdoqLogoWrapper>
  );
};

export default Logo;
