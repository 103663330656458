import { Features, hasFeature } from '@ardoq/features';
import { organizationInterface } from 'modelInterface/organization/organizationInterface';
import { map, Observable } from 'rxjs';
import { withOnFirstSubscription } from 'streams/utils/streamUtils';
import broadcast$ from '../broadcast$';
import { BroadcastBuilderProps } from './BroadcastBuilder';

export const broadcastBuilderViewModel$: Observable<BroadcastBuilderProps> =
  withOnFirstSubscription(
    broadcast$.pipe(
      map(
        ({
          currentBroadcast,
          fetchStatus,
          richTextEditorResetKey,
          organization,
        }) => {
          return {
            currentBroadcast,
            fetchStatus,
            richTextEditorResetKey,
            organization,
            hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
          };
        }
      )
    ),
    () => {
      organizationInterface.fetchCurrentOrganization();
    }
  );
