import { actionCreator } from '@ardoq/rxbeach';
import { ResultInState, ResultsPayload, SearchParamsPayload } from './types';
import { QuickSearchResult } from '@ardoq/api-types';

export const showSearchBar = actionCreator<boolean>(
  '[quickSearch] SHOW_SEARCH_BAR'
);

export const setSearchParams = actionCreator<SearchParamsPayload>(
  '[quickSearch] SET_SEARCH_PARAMS'
);

export const resetQuickSearch = actionCreator(
  '[quickSearch] RESET_QUICK_SEARCH'
);

export const previewResultItem = actionCreator<ResultInState | null>(
  '[quickSearch] PREVIEW_RESULT_ITEM'
);

export const lockResultItem = actionCreator<ResultInState | null>(
  '[quickSearch] LOCK_RESULT_ITEM'
);

export const performSearch = actionCreator<string>(
  '[quickSearch] PERFORM_SEARCH'
);

export const prepareResults = actionCreator<QuickSearchResult | null>(
  '[quickSearch] PREPARE_RESULTS'
);

export const setResults = actionCreator<ResultsPayload>(
  '[quickSearch] SET_RESULTS'
);
