import {
  ComponentBackboneModel,
  Model,
  Reference as ReferenceBackboneModel,
} from 'aqTypes';
import Components from 'collections/components';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import logMissingModel from 'models/logMissingModel';
import References from 'collections/references';
import { showRightPane } from 'appContainer/actions';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { ArdoqId, ReferenceDirection } from '@ardoq/api-types';
import { v4 as uuidv4 } from 'uuid';
import { dispatchAction } from '@ardoq/rxbeach';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

interface CreateNewLinksArgs {
  linkSourcesIds: ArdoqId[];
  linkTarget?: ComponentBackboneModel;
  linkTargetId?: ArdoqId | null;
  refDirection: ReferenceDirection;
}

export const createNewLinks = ({
  linkSourcesIds,
  linkTarget,
  linkTargetId,
  refDirection,
}: CreateNewLinksArgs) => {
  let linkTargetComponent = linkTarget;
  if (!linkTargetComponent) {
    linkTargetComponent = linkTargetId
      ? Components.collection.get(linkTargetId)
      : undefined;
    if (!linkTargetComponent) {
      return;
    }
  }

  const flipDirection = refDirection === ReferenceDirection.INCOMING;

  // TODO: extend the side panel to create/edit links by ArdoqIds
  const linkSources = linkSourcesIds
    .map(componentId => Components.collection.get(componentId))
    .filter(ExcludeFalsy);

  const refsCreated = linkSources
    .map(selectedComp => {
      const source = flipDirection ? linkTargetComponent : selectedComp;
      const target = flipDirection ? selectedComp : linkTargetComponent;
      const sourceModel = source.getMyModel();
      if (!sourceModel) {
        logMissingModel({
          id: source.id,
          rootWorkspace: source.get('rootWorkspace'),
          modelTypeName: 'component',
        });
        return null;
      }
      const type =
        getLastUsedRefType(sourceModel, source, target) ??
        getNewestReferenceType(sourceModel);
      return new References.collection.model(
        { source, target, type },
        {
          tracking: { data: { from: 'mouse linking' } },
          collection: References.collection,
        }
      );
    })
    .filter(ExcludeFalsy);

  const newEntities = refsCreated.map(getNewReferenceAttributes);
  dispatchAction(
    showRightPane({
      newReferenceAttributes: newEntities,
      type: GetContentOptionsType.CREATE_REFERENCE,
      referenceIds: newEntities.map(({ _id: id }) => id),
    })
  );
};

const getLastUsedRefType = (
  linkSourceRefModel: Model,
  source: ComponentBackboneModel,
  target: ComponentBackboneModel
) => {
  const referenceTypes = linkSourceRefModel.getReferenceTypes();
  const lastUsedReferenceTypeName =
    currentUserInterface.getLastUsedReferenceTypeTriple(
      source.getMyType()!,
      target.getMyType()!
    );
  const typeAsString = Object.keys(referenceTypes).find(
    key => referenceTypes[key].name === lastUsedReferenceTypeName
  );
  const parsedType = typeAsString ? parseInt(typeAsString, 10) : NaN;
  const type = !isNaN(parsedType) ? parsedType : undefined;
  return type;
};

const getNewestReferenceType = (linkSourceRefModel: Model) => {
  const referenceTypes = linkSourceRefModel.getReferenceTypes();
  return Math.max(
    ...Object.keys(referenceTypes).map(referenceTypeKey =>
      parseInt(referenceTypeKey, 10)
    )
  );
};

const getNewReferenceAttributes = (reference: ReferenceBackboneModel) => {
  return {
    ...reference.attributes,
    model: reference.getModel()?.id,
    _id: uuidv4(),
  };
};
