import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import { ViewStatusRecord } from './types';

const viewStatusLabels: ViewStatusRecord<string> = {
  [MetaInfoViewStatus.BETA]: 'Beta',
  [MetaInfoViewStatus.SOON_TO_BE_DISCONTINUED]: 'Soon to be discontinued',
  [MetaInfoViewStatus.DISCONTINUED]: 'Discontinued',
  [MetaInfoViewStatus.NEW]: 'New',
};
export default viewStatusLabels;
