import { bundleApi } from '@ardoq/api';
import { ArdoqId } from '@ardoq/api-types';
import { radius } from '@ardoq/design-tokens';
import { AqLayout, NavItem } from '@ardoq/layout';
import { confirm } from '@ardoq/modal';
import { Island } from '@ardoq/page-layout';
import { useState } from 'react';
import CreateBundle from './BundleEditor/BundleEditor';
import BundleOverview from './BundleOverview/BundleOverview';
import BundlesContentLayout from './BundlesContentLayout';

enum BundleTabs {
  MANAGE = 'manageBundles',
  CREATE = 'createBundle',
  EDIT = 'editBundle',
}

/**
 * Returns the title for the according tab
 * @param selectedTab the active tab of the BundlesManagement
 */
const getTitle = (selectedTab: BundleTabs): string => {
  switch (selectedTab) {
    case BundleTabs.MANAGE:
      return 'Manage existing bundles for this org';
    case BundleTabs.CREATE:
      return 'Create new bundle';
    case BundleTabs.EDIT:
      return 'Edit bundle';
    default:
      return '';
  }
};

const confirmedDiscardingChanges = () => {
  return confirm({
    title: `Confirmation required`,
    text: `Are you sure you want to leave? You have some unsaved changes.`,
  });
};

const BundleManagement = () => {
  const [selectedTab, setSelectedTab] = useState(BundleTabs.MANAGE);
  const [editingBundleId, setEditingBundleId] = useState<ArdoqId | undefined>();
  const [existsBundleWithChanges, setExistsBundleWithChanges] = useState(false);

  const manageBundles = async () => {
    if (selectedTab === BundleTabs.MANAGE) return;
    if (existsBundleWithChanges) {
      if (!(await confirmedDiscardingChanges())) return;
    }
    setSelectedTab(BundleTabs.MANAGE);
    setExistsBundleWithChanges(false);
  };

  const createBundle = async () => {
    if (selectedTab === BundleTabs.CREATE) return;
    if (existsBundleWithChanges) {
      if (!(await confirmedDiscardingChanges())) return;
    }
    setSelectedTab(BundleTabs.CREATE);
    setEditingBundleId(undefined);
  };

  const editBundle = (bundleId: ArdoqId) => {
    setSelectedTab(BundleTabs.EDIT);
    setEditingBundleId(bundleId);
  };

  const handleSubmit = () => {
    setSelectedTab(BundleTabs.MANAGE);
    setExistsBundleWithChanges(false);
  };

  return (
    <Island title="Bundle Management" bodySpacing="none">
      <AqLayout
        layoutWrapperStyle={{
          overflow: 'hidden',
          borderBottomLeftRadius: radius.medium,
          borderBottomRightRadius: radius.medium,
        }}
        renderLeftSidebar={() => (
          <>
            <NavItem
              $active={selectedTab === BundleTabs.MANAGE}
              onClick={manageBundles}
            >
              Manage Bundles
            </NavItem>
            <NavItem
              $active={selectedTab === BundleTabs.CREATE}
              onClick={createBundle}
            >
              Create Bundle
            </NavItem>
            <NavItem
              disabled={selectedTab !== BundleTabs.EDIT}
              $active={selectedTab === BundleTabs.EDIT}
            >
              Edit Bundle
            </NavItem>
          </>
        )}
      >
        <BundlesContentLayout title={getTitle(selectedTab)}>
          {(() => {
            switch (selectedTab) {
              case BundleTabs.MANAGE:
                return (
                  <BundleOverview
                    getOrgBundles={bundleApi.fetchAll}
                    editBundle={editBundle}
                    createBundle={createBundle}
                  />
                );
              case BundleTabs.CREATE:
              case BundleTabs.EDIT:
                return (
                  <CreateBundle
                    createBundleHasChanges={existsBundleWithChanges}
                    setChanges={setExistsBundleWithChanges}
                    navigateToBundleOverview={handleSubmit}
                    editingBundleId={editingBundleId}
                  />
                );
              default:
                return null;
            }
          })()}
        </BundlesContentLayout>
      </AqLayout>
    </Island>
  );
};

export default BundleManagement;
