import Context from 'context';
import {
  ContextSort,
  NumericSortOrder,
  SortAttribute,
  comparePrimitiveValuesOrDates,
} from '@ardoq/common-helpers';
import type { BasicModel, Reference } from 'aqTypes';
import { APIReferenceType } from '@ardoq/api-types';
import { getCurrentLocale, localeCompareNumericLowercase } from '@ardoq/locale';
import { isNil } from 'lodash';

export const compareBackboneModels = (
  a: BasicModel,
  b: BasicModel | undefined,
  sort: ContextSort
) => {
  const locale = getCurrentLocale();
  const sortAttr =
    sort.attr === SortAttribute.SOURCE || sort.attr === SortAttribute.TARGET
      ? 'name'
      : sort.attr;
  if (sortAttr?.includes(',')) {
    // UI is trying to sort on a date range field, e.g. by clicking on a column header in tables view.
    for (const currentSortAttr of sortAttr.split(',')) {
      const [currentAValue, currentBValue] = [a, b].map(l =>
        l!.get(currentSortAttr)
      );
      const result =
        comparePrimitiveValuesOrDates(currentAValue, currentBValue, locale) *
        (sort.order === NumericSortOrder.DESC ? -1 : 1);
      if (result) {
        return result;
      }
    }
  }
  const aValue = a.get(sortAttr);
  const bValue = b!.get(sortAttr);

  if (sort.orderUnsetLast) {
    // Sort undefined or null values last
    if (isNil(aValue) && !isNil(bValue)) return 1;
    if (!isNil(aValue) && isNil(bValue)) return -1;
  }

  if (aValue === bValue || (!bValue && !aValue)) {
    // Equal or Both falsy -> fallback to sorting on name
    // Return straight away, since fallback sort should always be ascending
    return localeCompareNumericLowercase(a.name(), b!.name(), locale);
  }
  return (
    comparePrimitiveValuesOrDates(aValue, bValue, locale) *
    (sort.order === NumericSortOrder.DESC ? -1 : 1)
  );
};

export const backboneModelComparator = (
  a: BasicModel,
  b: BasicModel | undefined,
  context = Context
) => {
  const sort = context.getSort();
  if (!sort) {
    return 0;
  }

  return compareBackboneModels(a, b, sort);
};

export const referenceComparator = (
  a: Reference,
  b: Reference,
  context = Context
) => {
  const sort = context.getSort();
  if (!sort) {
    return 0;
  }
  if (sort.attr === SortAttribute.SOURCE) {
    return backboneModelComparator(a.getSource(), b.getSource(), context);
  } else if (sort.attr === SortAttribute.TARGET) {
    return backboneModelComparator(a.getTarget(), b.getTarget(), context);
  } else if (sort.attr === SortAttribute.TYPE) {
    const locale = getCurrentLocale();
    return (
      localeCompareNumericLowercase(
        (a.getRefType() as APIReferenceType).name,
        (b.getRefType() as APIReferenceType).name,
        locale
      ) * (sort.order === NumericSortOrder.DESC ? -1 : 1)
    );
  }
  return backboneModelComparator(a, b, context);
};
