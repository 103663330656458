import TableSelector from 'integrations/service-now/containers/tableSelector/TableSelector';
import { connect } from '@ardoq/rxbeach';
import styled from 'styled-components';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { colors, s32 } from '@ardoq/design-tokens';
import { ErrorNotification, WarningNotification } from '@ardoq/status-ui';
import { Maybe } from '@ardoq/common-helpers';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { Connection } from '@ardoq/api-types/integrations';
import { viewModel$ } from './viewModel$';
import { SelectConnections } from '../../../common/components/selectors/SelectConnections';
import { SelectConfigs } from '../../../common/components/selectors/SelectConfigs';
import FieldSelector from 'integrations/service-now/containers/fieldSelector/FieldSelector';
import { SelectionState } from '../../streams/selectionState/types';
import { header2, text1 } from '@ardoq/typography';
import { TransferDirection } from 'integrations/common/streams/transferState/types';
import useResizeHeight from 'integrations/common/hooks/useResizeHeight';
import { Island } from '@ardoq/page-layout';
import { serviceNowDictionary } from 'integrations/common/dictionary';
import { AutoFocus } from 'integrations/common/components/AutoFocus';
import { Space } from '@ardoq/style-helpers';

type SelectDataProps = {
  connections: Connection[];
  transferConfigs: SavedTransferConfig[];
  selectedTransferConfigId: Maybe<string>;
  selectedConnectionIds: string[];
  unavailableTables: SelectionState['unavailableTables'];
  onTransferConfigSelect: (configId: SavedTransferConfig['_id'] | null) => void;
  onConnectionSelect: (connectionId: string[]) => void;
  onClearConfiguration: () => void;
  isFetchable: boolean;
  tableFetchError: Maybe<string>;
  transferDirection: TransferDirection;
  selectConnectionHelperText: string;
  selectConnectionPlaceHolder: string;
  selectConfigHelperText: string;
};

const SelectDataComponent = ({
  connections,
  selectedConnectionIds,
  transferConfigs,
  selectedTransferConfigId,
  unavailableTables,
  onTransferConfigSelect,
  onConnectionSelect,
  onClearConfiguration,
  tableFetchError,
  transferDirection,
  selectConnectionHelperText,
  selectConnectionPlaceHolder,
  selectConfigHelperText,
}: SelectDataProps) => {
  const { height, ref } = useResizeHeight();

  const unavailableTableIds = Object.keys(unavailableTables || {});

  return (
    <Container>
      <SelectConfigs
        transferConfigs={transferConfigs}
        selectedConfigId={selectedTransferConfigId}
        onSelect={onTransferConfigSelect}
        onClearConfiguration={onClearConfiguration}
        selectConfigHelperText={selectConfigHelperText}
      />
      <IslandContainer>
        <Island footerJustify="end" maxWidth="100%">
          <Stack gap="xlarge">
            {tableFetchError && (
              <AutoFocus>
                <ErrorNotification>{tableFetchError}</ErrorNotification>
              </AutoFocus>
            )}
            <SelectConnections
              connections={connections}
              selectedConnectionIds={selectedConnectionIds}
              onSelect={onConnectionSelect}
              selectConnectionHelperText={selectConnectionHelperText}
              selectConnectionPlaceHolder={selectConnectionPlaceHolder}
            />
            {!!unavailableTableIds?.length && (
              <WarningNotification>
                <Stack gap="xsmall">
                  <FlexBox gap="xsmall">
                    Couldn&apos;t apply configuration for the following tables:
                    <b>{unavailableTableIds.join(', ')}</b>
                  </FlexBox>
                  <div>
                    Make sure that the tables exist in the{' '}
                    {serviceNowDictionary.name} instance and that connection
                    have the correct permissions.
                  </div>
                </Stack>
              </WarningNotification>
            )}
            <Stack gap="medium">
              <StepLabel>2. Select tables and fields</StepLabel>
              <StepHint>
                Select the data you want to {transferDirection}
              </StepHint>
            </Stack>
            <FlexBox gap="medium">
              <TableSelector selectionRef={ref} />
              <FieldSelector maxHeight={height} />
            </FlexBox>
          </Stack>
        </Island>
      </IslandContainer>
    </Container>
  );
};

const StepLabel = styled.div`
  ${header2};
`;

const StepHint = styled(Space).attrs({ $gap: 's8' })`
  ${text1};
  color: ${colors.grey50};
`;

const IslandContainer = styled.div`
  padding: ${s32};
  width: 100%;
`;

const Container = styled.div`
  overflow: auto;
  padding-bottom: ${s32};
`;

export const SelectData = connect(SelectDataComponent, viewModel$);
