import { Icon, IconName } from '@ardoq/icons';
import {
  InputsSection,
  PanelContainer,
  PanelFooter,
  PanelHeader,
  SectionsContainer,
} from 'appLayout/ardoqStudio/saveAsViewpoint/atoms';
import { WithPointerEvents } from '@ardoq/style-helpers';
import { PrimaryButton, GhostButton } from '@ardoq/button';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { hideViewSidePanel } from 'views/mainApp/viewSidePanel/actions';
import { TextAreaWithCounter } from '@ardoq/snowflakes';
import commands from './commands';
import saveAsBookmarkViewModel$, {
  SaveAsBookmarkViewModelShape,
} from './saveAsBookmarkViewModel$';
import FolderSelect from '../components/FolderSelectDialog/FolderSelect';
import { Header4, InlineText, Link, Paragraph } from '@ardoq/typography';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { ErrorNotification } from '@ardoq/status-ui';
import { ArdoqError, getArdoqErrorMessage } from '@ardoq/common-helpers';
import { openChatWithUs } from '../components/AppMainSidebar/utils';

type SaveAsBookmarkProps = SaveAsBookmarkViewModelShape;

const SaveAsBookmark = ({
  viewId,
  folderSelectDataSource,
  bookmarkName,
  error,
  selectedFolderId,
  isSaveBookmarkEnabled,
}: SaveAsBookmarkProps) => {
  return (
    <PanelContainer>
      <PanelHeader>
        <Icon iconName={IconName.BOOKMARK} />
        <span>Save as Bookmark</span>
      </PanelHeader>
      <SectionsContainer>
        <InputsSection>
          <TextAreaWithCounter
            autoFocus
            id="save-as-bookmark-name-input"
            label="Bookmark name"
            helperText="Use a short name that describes what this bookmark shows"
            value={bookmarkName}
            onValueChange={commands.setBookmarkName}
            characterLimit={60}
            characterLimitWarning="Long names may not be fully displayed"
          />
          <Box paddingY="small">
            <Stack gap="small">
              <Stack gap="small">
                <Header4>
                  Save to folder
                  <InlineText color="textSubtle" variant="text1">
                    {' '}
                    (optional)
                  </InlineText>
                </Header4>
              </Stack>
              <FolderSelect
                dataSource={folderSelectDataSource}
                selectedFolderId={selectedFolderId}
                setSelectedFolderId={commands.setBookmarkFolder}
              />
            </Stack>
          </Box>
        </InputsSection>
      </SectionsContainer>
      <PanelFooter $padding="s16" $alignToRight>
        <Stack align="flex-end">
          {error && <ErrorMessage error={error} />}
          <FlexBox gap="medium" align="flex-end">
            <GhostButton
              onClick={() => {
                dispatchAction(hideViewSidePanel());
              }}
            >
              Cancel
            </GhostButton>
            <WithPointerEvents>
              <PrimaryButton
                isDisabled={!isSaveBookmarkEnabled}
                onClick={() =>
                  commands.saveBookmark(bookmarkName, viewId, selectedFolderId)
                }
              >
                Save bookmark
              </PrimaryButton>
            </WithPointerEvents>
          </FlexBox>
        </Stack>
      </PanelFooter>
    </PanelContainer>
  );
};

const ErrorMessage = ({ error }: { error: ArdoqError }) => {
  const message = getArdoqErrorMessage(error, 'Failed to save bookmark.');
  return (
    <Box marginBottom="medium">
      <ErrorNotification>
        <Stack gap="small">
          <Paragraph>
            {message} If the problem continues, please contact{' '}
            <Link typography="text2" onClick={openChatWithUs}>
              customer support.
            </Link>
          </Paragraph>

          {error.traceId && <Paragraph>Trace ID: {error.traceId}</Paragraph>}
        </Stack>
      </ErrorNotification>
    </Box>
  );
};

export default connect(SaveAsBookmark, saveAsBookmarkViewModel$);
