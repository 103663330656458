import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { ArdoqId, Metamodel, PersistedMetamodel } from '@ardoq/api-types';
import filters from 'collections/filters';
import { closeOpenWorkspaces, openWorkspace } from 'utils/workspace';
import {
  EditMetamodelOptions,
  MetamodelContext,
  OpenNewEditorOptions,
} from './types';
import { MetamodelPane } from './navigation/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { trackClickStartCreatingNewMetamodel } from './tracking';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { selectMetamodel, selectMetamodelPane } from './navigation/actions';
import { logError } from '@ardoq/logging';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';
import { TrackingLocation } from 'tracking/types';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { clearLoadedGraphState } from 'streams/traversals/actions';

export const getWorkspaceIdsFromContext = (
  metamodel: Metamodel,
  context: MetamodelContext
) => {
  const { componentTypes, referenceTypes, workspaces } = metamodel.metamodel;
  const {
    COMPONENT,
    REFERENCE,
    REFERENCE_COMPLETENESS,
    WORKSPACE,
    WORKSPACE_FOLDER,
  } = SimpleBlockDiagramContentType;
  if (context.type === COMPONENT) {
    return [componentTypes[context.id].workspaceId];
  } else if (context.type === REFERENCE) {
    return [referenceTypes[context.id].workspaceId];
  } else if (context.type === REFERENCE_COMPLETENESS) {
    return [componentTypes[context.componentId].workspaceId];
  } else if (context.type === WORKSPACE) {
    return [context.id];
  } else if (context.type === WORKSPACE_FOLDER) {
    return Object.values(workspaces)
      .filter(workspace => workspace.folder === context.id)
      .map(workspace => workspace.id);
  }
  return Object.keys(workspaces);
};

const handleOpenWorkspaces = (
  workspaceIds: ArdoqId[],
  trackingLocation?: TrackingLocation
) => {
  const selectedWorkspaces: ArdoqId[] = workspaceIds.filter(
    workspaceInterface.workspaceExists
  );
  if (!selectedWorkspaces.length) return;
  filters.removeAllFilters({});
  closeOpenWorkspaces(selectedWorkspaces);
  if (loadedGraph$.state.isViewpointMode) {
    dispatchAction(
      clearLoadedGraphState({
        keepCurrentViewPointMode: false,
      })
    );
  }
  selectedWorkspaces.forEach(workspace => {
    openWorkspace(workspace, { trackingLocation });
  });
};

export const handleNavigation = (
  metamodel: Metamodel,
  context: MetamodelContext
) => {
  const workspaceIds: string[] = getWorkspaceIdsFromContext(metamodel, context);
  if (!workspaceIds.length) return;
  handleOpenWorkspaces(workspaceIds, 'metamodel');
};

export const editMetamodel = ({
  metamodelId,
  trackingLocation,
}: EditMetamodelOptions) => {
  dispatchAction(selectMetamodel(metamodelId));
  dispatchAction(
    selectMetamodelPane({ pane: MetamodelPane.EDIT, trackingLocation })
  );
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.METAMODEL })
  );
};

export const openNewEditor = (options?: OpenNewEditorOptions) => {
  dispatchAction(selectMetamodel(undefined));
  dispatchAction(
    selectMetamodelPane({
      pane: MetamodelPane.EDIT,
      trackingLocation: options?.trackingLocation,
    })
  );
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.METAMODEL })
  );
  trackClickStartCreatingNewMetamodel();
};

export const getComponentTypeCount = (metamodel?: PersistedMetamodel) => {
  if (!metamodel?.metamodel) return 0;
  if (!metamodel.metamodel.componentTypes) {
    logError(
      Error('Invalid metamodel: Missing componentTypes property.'),
      null,
      { id: metamodel._id }
    );
  }
  return Object.keys(metamodel.metamodel.componentTypes).length;
};
