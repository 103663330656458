import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { bookmarkApi } from '@ardoq/api';
import { renameRoutine } from 'streams/assets/routines';
import { handleDelete, handleFetchAll } from 'streams/crud/routines';
import { bookmarksNamespace } from './bookmarks$';
import { addBookmark, setSaveBookmarkError } from 'bookmarks/actions';
import { tap } from 'rxjs';
import { createBookmarkFromCurrentView } from './createBookmarkFromCurrentView';
import { isArdoqError } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import { showToast, ToastType } from '@ardoq/status-ui';
import { hideViewSidePanel } from '../../views/mainApp/viewSidePanel/actions';

const handleAddBookmark = routine(
  ofType(addBookmark),
  extractPayload(),
  tap(async ({ viewId, bookmarkName, folderId }) => {
    dispatchAction(setSaveBookmarkError(null));
    const bookmarkAttributes = createBookmarkFromCurrentView(
      viewId,
      bookmarkName
    );

    const createdBookmark =
      folderId !== null
        ? await bookmarkApi.createInFolder(bookmarkAttributes, folderId)
        : await bookmarkApi.create(bookmarkAttributes);

    if (isArdoqError(createdBookmark)) {
      logError(createdBookmark);
      dispatchAction(setSaveBookmarkError(createdBookmark));
      return;
    }
    showToast(`Bookmark "${bookmarkName}" created.`, ToastType.SUCCESS);
    dispatchAction(hideViewSidePanel());
  })
);

export const bookmarkStreamRoutines = collectRoutines(
  handleFetchAll(bookmarksNamespace, bookmarkApi.fetchAll),
  handleDelete(bookmarksNamespace, bookmarkApi.delete),
  renameRoutine(bookmarksNamespace, bookmarkApi.rename),
  handleAddBookmark
);
