import { trackEvent } from 'tracking/tracking';
import { ArdoqId, SlideTypes } from '@ardoq/api-types';

// Tracked when a user views a presentation
interface TrackViewedPresentation {
  presentationId: ArdoqId;
}
export const trackViewedPresentation = (metadata: TrackViewedPresentation) =>
  trackEvent('Viewed presentation', metadata, true);

// Tracked when a user shows a slide
interface TrackViewedSlide {
  slideType: SlideTypes;
  viewId?: string;
  isScenario?: boolean;
}
export const trackViewedSlidePresentationMode = (metadata: TrackViewedSlide) =>
  trackEvent('Viewed slide in presentation mode', metadata, true);

// Tracked when a user toggles explore mode
export const trackToggledExploreMode = (isEnabled: boolean) =>
  trackEvent('Toggled explore mode', {
    isExploreMode: isEnabled,
  });

export const trackCreateNewPresentation = () => {
  trackEvent('Presentations: clicked create new');
};

export const trackOpenedPresentationSidebar = (
  origin:
    | 'Presentation view'
    | 'Asset manager'
    | 'Presentation browser'
    | 'List view',
  resourceId?: ArdoqId
) => {
  trackEvent('Opened presentation sidebar', {
    resourceId,
    origin,
  });
};

export const trackClickedPlayPresentation = (
  origin: 'Presentation view' | 'Presentation sidebar',
  resourceId?: ArdoqId
) => {
  trackEvent('Clicked play presentation', {
    resourceId,
    origin,
  });
};
