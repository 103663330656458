import currentBroadcast$ from 'broadcasts/currentBroadcast$';
import audiencePreview$ from 'broadcasts/broadcastBuilder/audiencePreview/audiencePreview$';
import { reducer, streamReducer } from '@ardoq/rxbeach';
import { AudiencePreviewStreamShape } from './types';
import {
  getUniqueAudienceCount,
  getWarningMessageFromAudiencePreview,
} from 'broadcasts/utils';
import { AudienceStartingPage } from '@ardoq/api-types';
import { BroadcastFetchStatus } from 'broadcasts/types';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AudiencePreviewStatus } from 'broadcasts/broadcastBuilder/audiencePreview/types';
import { ExtractStreamShape } from 'tabview/types';
import { setFilter } from './actions';
import { openAudiencePreviewDialog } from 'broadcasts/actions';

export const defaultState = {
  audiencePreview: [],
  audienceStartingPage: AudienceStartingPage.SURVEY_COMPONENT,
  audienceCount: 0,
  fetchStatus: BroadcastFetchStatus.IDLE,
  warningMessage: null,
  filter: '',
};

const handleAudiencePreviewChange = (
  state: AudiencePreviewStreamShape,
  { audiencePreview, status }: ExtractStreamShape<typeof audiencePreview$>
): AudiencePreviewStreamShape & { status?: AudiencePreviewStatus } => ({
  ...state,
  audiencePreview,
  status,
  audienceCount: getUniqueAudienceCount(audiencePreview),
  warningMessage: getWarningMessageFromAudiencePreview(audiencePreview),
});

const audienceStartingPage$: Observable<AudienceStartingPage> =
  currentBroadcast$.pipe(pluck('audienceStartingPage'), distinctUntilChanged());

const handleAudienceStartingPageChange = (
  state: AudiencePreviewStreamShape,
  audienceStartingPage: AudienceStartingPage
) => ({
  ...state,
  audienceStartingPage,
});

const handleSetFilter = (
  state: AudiencePreviewStreamShape,
  filter: string
): AudiencePreviewStreamShape => ({
  ...state,
  filter,
});

const handleOpenAudiencePreviewDialog = (
  state: AudiencePreviewStreamShape
): AudiencePreviewStreamShape => ({
  ...state,
  filter: '',
});

export const reducers = [
  streamReducer(audiencePreview$, handleAudiencePreviewChange),
  streamReducer(audienceStartingPage$, handleAudienceStartingPageChange),
  reducer(setFilter, handleSetFilter),
  reducer(openAudiencePreviewDialog, handleOpenAudiencePreviewDialog),
];
