import {
  APIOrganizationUser,
  APIRoleAttributes,
  ArdoqId,
  PermissionGroup,
  Privilege,
  PrivilegesByUser,
} from '@ardoq/api-types';
import { UserProfilePageShape } from './types';
import { privilegesOperations } from 'privileges/privilegesOperations';
import { permissionGroupOperations } from 'admin/accessControl/PermissionGroups/permissionGroupsOperations';
import { UserProfileTabs } from '../navigation/types';

const getViewModel = ({
  privilegesByUser,
  permissionGroupsById,
  roles,
  configurablePrivileges,
  user,
  activeTabId,
}: {
  privilegesByUser: PrivilegesByUser;
  permissionGroupsById: Record<ArdoqId, PermissionGroup>;
  roles: APIRoleAttributes[];
  configurablePrivileges: Privilege[];
  user: APIOrganizationUser;
  activeTabId: UserProfileTabs;
}): UserProfilePageShape => {
  const userPrivileges = privilegesOperations
    .getUserPrivileges(user._id, privilegesByUser)
    .filter(privilege =>
      privilegesOperations.isConfigurablePrivilege(
        privilege,
        configurablePrivileges
      )
    );
  return {
    user,
    userPrivileges,
    userGroups: permissionGroupOperations.getUserGroups(
      { groupsById: permissionGroupsById },
      user._id
    ),
    activeTabId,
    userRole: roles.find(role => role.label === user.role) || null,
  };
};

export const userProfilePagePropsOperations = {
  getViewModel,
};
