import styled from 'styled-components';

const DeletedDescendant = styled.div`
  margin-left: 30px;
`;

type DeletedDescendantProperties = { descendants: string[] };
const DeletedDescendants = ({ descendants }: DeletedDescendantProperties) => (
  <>
    {descendants.map((descendant, index) => (
      <DeletedDescendant key={index}>{descendant}</DeletedDescendant>
    ))}
  </>
);

export default DeletedDescendants;
