import {
  NotificationCardProps,
  NotificationsFilter,
} from '@ardoq/in-app-notifications';

export enum NotificationsFilterType {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
}

export enum ChecksCounter {
  ZERO = 0,
  ONE = 1,
  MORE = 2,
}

export type InAppNotificationsState = {
  showList: boolean;
  notifications: NotificationCardProps[];
  filters: Record<NotificationsFilterType, NotificationsFilter>;
  filteringBy: NotificationsFilterType;
  isPartial: boolean;
  checksCounter: ChecksCounter;
  canDisplayToast: boolean;
  isLoading: boolean;
};
