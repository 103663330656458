import Components from 'collections/components';
import References from 'collections/references';
import Tags from 'collections/tags';
import Fields from 'collections/fields';
import { addCSSForModel } from 'utils/modelCssManager/ardoqModelCSSManager';
import { componentApi } from '@ardoq/api';
import { handleError } from './copyComponentError';
import { CopyComponentsResponse } from '@ardoq/api-types';
import { isArdoqError } from '@ardoq/common-helpers';
import { CopyComponentsArgs } from './copyComponentsTypes';
import { modelInterface } from '../modelInterface/models/modelInterface';

const handleResponse = (response: CopyComponentsResponse) => {
  const { components, references, model, tags, fields } = response;
  modelInterface.updateModel(model);
  Components.collection.batchLoadComponents(components);
  References.collection.batchLoadReferences(references);
  Tags.collection.loadTags(tags);
  Fields.collection.loadFields(fields);
  addCSSForModel(model._id);
};

export const copyComponents = async ({
  targetWorkspace,
  parent,
  componentIds,
}: CopyComponentsArgs) => {
  const response = await componentApi.copyComponents(
    targetWorkspace,
    parent,
    componentIds
  );
  if (isArdoqError(response)) {
    handleError(response);
    return;
  }
  handleResponse(response);
};
