import {
  carry,
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { selectDocument, setDocumentState } from './actions';
import { catchError, filter, of, switchMap, tap, throwError } from 'rxjs';
import {
  authRequiredLucidchartAction,
  createDocumentPickerUrl,
  createEmbedViewerUrl,
  isSuccessDocumentSelection,
} from 'integrations/lucidchart/utils';
import { integrationsLucidChartApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { ANALYTIC_EVENTS } from 'integrations/lucidchart/const';
import { trackEvent } from 'tracking/tracking';
import { showDocumentPickerDrawer } from 'integrations/lucidchart/DocumentPickerDrawer';
import {
  closeDocumentPicker,
  createLucidDocumentPickerStream,
} from 'integrations/lucidchart/streams';
import { LucidChartDocumentPickerStatus } from 'integrations/lucidchart/types';
import { ASYNC_STATUS } from 'integrations/common/types/api';

const handleArdoqError = (error: unknown) => {
  if (isArdoqError(error)) {
    return of(error);
  }
  return throwError(() => error);
};

const handleSelectDocument = routine(
  ofType(selectDocument),
  extractPayload(),
  carry(
    switchMap(payload =>
      authRequiredLucidchartAction({
        lucidchartAction: integrationsLucidChartApi.pickerToken,
        onCompleteAction: selectDocument,
        payload,
        operation: 'open Lucidchart document picker',
      })
    )
  ),
  tap(([_, response]) => {
    if (isArdoqError(response)) {
      dispatchAction(
        setDocumentState({
          status: ASYNC_STATUS.FAILURE,
          error:
            response.userMessage ||
            'Something went wrong while selecting document',
        })
      );
      return;
    }

    const { token } = response;
    trackEvent(ANALYTIC_EVENTS.OPENED_DOCUMENT_PICKER);

    const { closeDrawer } = showDocumentPickerDrawer({
      iframeSource: createDocumentPickerUrl({ token, titleSuggestion: 'Test' }),
      onClose: closeDocumentPicker,
    });

    const documetPicker$ = createLucidDocumentPickerStream({
      onExpired: closeDrawer,
    });

    documetPicker$
      .pipe(
        filter(
          data => data?.status === LucidChartDocumentPickerStatus.CANCELLED
        ),
        tap(() => {
          closeDrawer();
          trackEvent(ANALYTIC_EVENTS.CANCELLED_DOCUMENT_PICKER);
        })
      )
      .subscribe();

    documetPicker$
      .pipe(
        tap(async data => {
          if (isSuccessDocumentSelection(data)) {
            const result = await integrationsLucidChartApi.settings();
            if (isArdoqError(result)) {
              return;
            }
            dispatchAction(
              setDocumentState({
                status: ASYNC_STATUS.SUCCESS,
                document: {
                  id: data.documentId,
                  name: data.title,
                  embedUrl: createEmbedViewerUrl({
                    documentId: data.documentId,
                    clientId: result.clientId,
                  }),
                },
              })
            );
            closeDrawer();
          }
        })
      )
      .subscribe();
  }),
  catchError(handleArdoqError)
);

export default collectRoutines(handleSelectDocument);
