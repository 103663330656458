import {
  COMPONENT_ID_ATTRIBUTE,
  REFERENCE_ID_ATTRIBUTE,
  WORKSPACE_ID_ATTRIBUTE,
} from '@ardoq/global-consts';

export const ADD_TO_OPEN_PRESENTATION = 'Add to open presentation';
export const GLOBAL_HANDLER_ID_ATTRIBUTE = 'data-global-handler-id';
export const TAG_ID_ATTRIBUTE = 'data-tag-id';
export const [
  GLOBAL_HANDLER_ID_SELECTOR,
  COMPONENT_ID_SELECTOR,
  REFERENCE_ID_SELECTOR,
  WORKSPACE_ID_SELECTOR,
  TAG_ID_SELECTOR,
] = [
  GLOBAL_HANDLER_ID_ATTRIBUTE,
  COMPONENT_ID_ATTRIBUTE,
  REFERENCE_ID_ATTRIBUTE,
  WORKSPACE_ID_ATTRIBUTE,
  TAG_ID_ATTRIBUTE,
].map(attribute => `[${attribute}]`);
export const MODEL_ID_ATTRIBUTE = 'data-model-id';
export const MODEL_ID_SELECTOR = `[${MODEL_ID_ATTRIBUTE}]`;
/** base transition in milliseconds, as defined in variables.less */
export const BASE_TRANSITION_MS = 300;
export const BASE_TRANSITION = `all ${BASE_TRANSITION_MS}ms ease-in-out`;
export const CSS_CLASS_LINK_TARGET = 'targetLink';
export const NO_ACESS_TO_RESOURCE_MESSAGE =
  'You do not have access to this resource. Notify your workspace administrator if this is an error.';
export const GLOBAL_MODEL_SELECTORS = [
  '.component:not(.skipContextUpdate)',
  '.integration:not(.skipContextUpdate)',
];
export const FAILED_UPDATE_ERROR_MESSAGE = 'Unknown error while updating: ';
