import { Step } from '@ardoq/steppers';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getConfigureStepState } from 'integrations/common/utils/step';
import { getDataLoadingStream } from 'integrations/unified/streams/dataLoading/dataLoading$';
import { getResourcesStream } from 'integrations/unified/streams/resources/resources$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { isConfigureStepAvailable } from './utils';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { ImportRoute } from 'integrations/common/navigation/types';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';

const STEP_PATH = ImportRoute.CONFIGURE;

const handleStepClick = (integrationId: IntegrationId) => () => {
  dispatchAction(
    navigateToPath({
      integrationId,
      path: STEP_PATH,
      source: 'stepper',
    })
  );
};

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId => {
    return combineLatest({
      activeIntegration: getActiveIntegrationStream(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
      dataLoading: getDataLoadingStream(integrationId),
      resources: getResourcesStream(integrationId),
    });
  }),
  map(({ activeIntegration, tabularMapping, dataLoading, resources }) => {
    const { integrationPath, integrationId } = activeIntegration;
    return {
      isDisabled: !isConfigureStepAvailable({
        dataLoading,
        selectedResources: resources.selectedResources,
      }),
      onPress: handleStepClick(integrationId),
      isSelected: integrationPath === STEP_PATH,
      heading: 'Configure data',
      state: getConfigureStepState({
        integrationPath,
        tabularMapping,
        configureRoute: STEP_PATH,
      }),
      stepNumber: 2,
    };
  })
);

export const ConfigureStep = connect(Step, viewModel$);
