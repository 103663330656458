import {
  EnhancedSearchResponse,
  ExportButtonCommands,
  filterQueryToApiReportFilterQuery,
  ReportEventLocations,
  ReportTrackingEvents,
  SortFilterPagination,
} from '@ardoq/report-reader';
import { getExportButtonCommands } from './exportButtonCommands';
import { ArdoqId, ReportFilterQuery } from '@ardoq/api-types';
import { Maybe } from '@ardoq/common-helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import { initiateCreatingNewBroadcastFromReport } from 'broadcasts/actions';
import { createReportUserEventTracking } from '@ardoq/report-builder';
import { trackEvent } from 'tracking/tracking';
import { createReportSlide } from 'presentationEditor/presentationChooser/actions';
import { openReportInBuilder } from 'components/AppMainSidebar/utils';

const trackUserEvent = createReportUserEventTracking(
  'Report Reader',
  trackEvent
);

export type NavBarCommands = {
  createBroadcast: VoidFunction;
  addToPresentation: VoidFunction;
  editReport: VoidFunction;
} & ExportButtonCommands;

export const getNavBarCommands = (
  reportId: ArdoqId,
  searchResults: Maybe<EnhancedSearchResponse>,
  filterQuery: ReportFilterQuery,
  sortFilterPagination: SortFilterPagination
): NavBarCommands => ({
  addToPresentation: () => {
    trackUserEvent(ReportTrackingEvents.CLICKED_ON_ADD_TO_PRESENTATION, {
      from: ReportEventLocations.REPORT_BUILDER,
    });
    if (!searchResults) {
      return;
    }
    dispatchAction(
      createReportSlide({
        reportId,
        reportName: searchResults.name,
        reportParams: {
          sort: sortFilterPagination.sort.columnKey,
          order: sortFilterPagination.sort.order,
          filters: filterQueryToApiReportFilterQuery(
            sortFilterPagination.filterQuery
          ),
        },
      })
    );
  },
  createBroadcast: () => {
    trackUserEvent(ReportTrackingEvents.CREATE_BROADCAST_FROM_REPORT, {
      from: ReportEventLocations.REPORT_BUILDER,
    });
    dispatchAction(initiateCreatingNewBroadcastFromReport(reportId));
  },
  editReport: () =>
    openReportInBuilder({
      reportId,
    }),
  ...getExportButtonCommands(reportId, searchResults, filterQuery),
});
