import { actionCreator } from '@ardoq/rxbeach';
import { SearchPane } from './types';

export const selectSearchPane = actionCreator<{
  readonly searchPane: SearchPane;
}>('[SearchTabContainer] SELECT_SEARCH_PANE');

export const notifySearchOverviewPageNumberChange = actionCreator<{
  readonly searchOverviewPage: number;
  readonly searchOverviewType: SearchPane;
}>('[SearchTabContainer] NOTIFY_SEARCH_OVERVIEW_PAGE_NUMBER_CHANGE');

export const setQueryEditorIsOpenInModal = actionCreator<boolean>(
  '[SearchTabContainer] SET_QUERY_EDITOR_IS_OPEN_IN_MODAL'
);
