import { reducedStream, reducer, streamReducer } from '@ardoq/rxbeach';
import {
  editUseCase,
  setIsLoading,
  setUseCasesError,
  selectUseCaseTab,
} from './actions';
import {
  SetUseCaseManagementFieldValuePayload,
  UseCaseEditorStreamState,
  UseCaseManagementStreamState,
  UseCaseTabs,
} from './types';
import {
  getSelectableOptionsFromEntityGroup,
  getSelectOptionsForKey,
  isSelectOptionKey,
} from './utils';
import {
  saveUseCasePending,
  saveUseCaseSuccess,
  setEntityGroup,
  setInitialEditorState,
} from './UseCaseEditor/actions';
import {
  componentTypes$,
  entityGroups$,
  keyMetricsDashboards$,
  referenceTypes$,
  useCases$,
} from './streams';
import { locale$ } from '../../streams/locale$';
import { ARDOQ_DEFAULT_LOCALE } from '@ardoq/locale';

const EMPTY_SELECTABLE_ENTITY_OBJECT = { data: [], selectOptions: [] };

const defaultState: UseCaseManagementStreamState = {
  selectedTab: UseCaseTabs.MANAGE,
  componentTypes: {
    ...EMPTY_SELECTABLE_ENTITY_OBJECT,
    componentTypesFromEntityGroup: [],
  },
  referenceTypes: {
    ...EMPTY_SELECTABLE_ENTITY_OBJECT,
    referenceTypesFromEntityGroup: [],
  },
  keyMetricsDashboards: EMPTY_SELECTABLE_ENTITY_OBJECT,
  entityGroups: EMPTY_SELECTABLE_ENTITY_OBJECT,
  useCasesData: [],
  error: null,
  isLoading: false,
  userLocale: ARDOQ_DEFAULT_LOCALE,
};

const openUseCaseEditor = (
  state: UseCaseManagementStreamState
): UseCaseManagementStreamState => ({
  ...state,
  isLoading: true,
  selectedTab: UseCaseTabs.EDIT,
});

const enableLoading = (
  state: UseCaseManagementStreamState
): UseCaseManagementStreamState => ({ ...state, isLoading: true });

const clearErrorAndDisableLoading = (
  state: UseCaseManagementStreamState
): UseCaseManagementStreamState => ({
  ...state,
  isLoading: false,
  error: null,
});

const getInitialSelectOptionsFromInitialEntityGroup = (
  state: UseCaseManagementStreamState,
  payload: Partial<UseCaseEditorStreamState>
): UseCaseManagementStreamState => {
  const {
    dashboardsOptions,
    referenceTypesOptions,
    componentTypesOptions,
    componentTypesFromEntityGroup,
    referenceTypesFromEntityGroup,
  } = getSelectableOptionsFromEntityGroup(payload.entityGroupId!, state);
  return {
    ...state,
    keyMetricsDashboards: {
      ...state.keyMetricsDashboards,
      selectOptions: dashboardsOptions,
    },
    componentTypes: {
      ...state.componentTypes,
      selectOptions: componentTypesOptions,
      componentTypesFromEntityGroup,
    },
    referenceTypes: {
      ...state.referenceTypes,
      selectOptions: referenceTypesOptions,
      referenceTypesFromEntityGroup,
    },
    isLoading: false,
  };
};

const getSelectOptionsFromEntityGroup = (
  state: UseCaseManagementStreamState,
  entityGroupId: string
): UseCaseManagementStreamState => {
  const {
    dashboardsOptions,
    referenceTypesOptions,
    componentTypesOptions,
    componentTypesFromEntityGroup,
    referenceTypesFromEntityGroup,
  } = getSelectableOptionsFromEntityGroup(entityGroupId, state);

  return {
    ...state,
    keyMetricsDashboards: {
      ...state.keyMetricsDashboards,
      selectOptions: dashboardsOptions,
    },
    componentTypes: {
      ...state.componentTypes,
      selectOptions: componentTypesOptions,
      componentTypesFromEntityGroup,
    },
    referenceTypes: {
      ...state.referenceTypes,
      selectOptions: referenceTypesOptions,
      referenceTypesFromEntityGroup,
    },
  };
};

const setStreamStateValue =
  (key: SetUseCaseManagementFieldValuePayload['key']) =>
  (
    state: UseCaseManagementStreamState,
    value: SetUseCaseManagementFieldValuePayload['value']
  ): UseCaseManagementStreamState => {
    if (isSelectOptionKey(key)) {
      const payload = {
        key,
        value,
      } as SetUseCaseManagementFieldValuePayload;
      const selectOptions = getSelectOptionsForKey(payload, state.userLocale);
      return {
        ...state,
        [key]: {
          data: value,
          selectOptions,
        },
      };
    }
    return {
      ...state,
      [key]: value,
    };
  };

export const useCaseManagement$ = reducedStream(
  'useCaseManagement',
  defaultState,
  [
    reducer(selectUseCaseTab, setStreamStateValue('selectedTab')),
    reducer(setIsLoading, setStreamStateValue('isLoading')),
    reducer(editUseCase, openUseCaseEditor),
    reducer(saveUseCasePending, enableLoading),
    reducer(saveUseCaseSuccess, clearErrorAndDisableLoading),
    reducer(
      setInitialEditorState,
      getInitialSelectOptionsFromInitialEntityGroup
    ),
    reducer(setUseCasesError, setStreamStateValue('error')),
    streamReducer(useCases$, setStreamStateValue('useCasesData')),
    streamReducer(
      keyMetricsDashboards$,
      setStreamStateValue('keyMetricsDashboards')
    ),
    streamReducer(entityGroups$, setStreamStateValue('entityGroups')),
    streamReducer(referenceTypes$, setStreamStateValue('referenceTypes')),
    streamReducer(componentTypes$, setStreamStateValue('componentTypes')),
    streamReducer(locale$, setStreamStateValue('userLocale')),
    reducer(setEntityGroup, getSelectOptionsFromEntityGroup),
  ]
);
