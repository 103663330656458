import { action$, dispatchAction, ofType } from '@ardoq/rxbeach';
import { contextInterface } from 'modelInterface/contextInterface';
import { tap } from 'rxjs';
import { closeAllOpenData } from './actions';
import { componentTreeSelectionStartSelected } from 'componentSelection/componentSelectionActions';

export const getCloseAllDataRoutine = () =>
  action$.pipe(
    ofType(closeAllOpenData),
    tap(() => {
      dispatchAction(
        componentTreeSelectionStartSelected({ selectionStart: '' })
      );
      contextInterface.closeAllWorkspaces();
    })
  );
