import {
  dispatchAction,
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import {
  resetSelection,
  selectClick,
  setSelected,
} from './scenarioRelatedSelection$';
import { scenarioRelated$ } from './scenarioRelated$';
import {
  getAncestorsAsList,
  getDescendantsAsList,
} from 'components/VirtualWindow/traverse';
import {
  WorkspaceChanged,
  ComponentChanged,
  notifyComponentChanged,
  notifyScenarioChanged,
  notifyWorkspaceChanged,
} from 'streams/context/ContextActions';
import { loadedScenarioData$ } from 'loadedScenarioData$';
import { setCollapsed, setFiltered } from './scenarioRelatedDataSource$';
import { APIComponentAttributes, ArdoqId } from '@ardoq/api-types';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { MAIN_NODE_ID } from './consts';
import { closeScenarioSuccess, setLoadedScenarioData } from 'scope/actions';
import { hideScopeRelatedNavigator } from '../../actions';
import { ByIdItem } from './types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { context$ } from 'streams/context/context$';
import type { GraphModelShape, ContextShape } from '@ardoq/data-model';
import { PayloadLoadedScenarioData } from 'scope/types';

const clickSelectedRoutine = routine(
  ofType(selectClick),
  extractPayload(),
  tap(id => {
    dispatchAction(setSelected({ id, ids: [id] }));
  })
);

type NotifyChangedPayload =
  | WorkspaceChanged
  | ComponentChanged
  | PayloadLoadedScenarioData
  | ArdoqId
  | null;

/*
 * Looks up the sources and targets for the context component
 */
const getConnectedNodesWithContextAncestors = (
  scenarioRelatedGraph: GraphModelShape | null,
  byId: Record<string, ByIdItem>,
  ctx: ContextShape,
  whatChanged: {
    componentId: ArdoqId | null;
    workspaceId: ArdoqId | null;
    scenarioId: ArdoqId | null;
  }
) => {
  const connectedNodes =
    (whatChanged.scenarioId && Object.keys(byId)) ||
    (whatChanged.workspaceId &&
      componentInterface.getRootComponents(whatChanged.workspaceId)) ||
    (whatChanged.componentId && [whatChanged.componentId]) ||
    (ctx.componentId && [ctx.componentId]) ||
    (ctx.scenarioId && Object.keys(byId)) ||
    (ctx.workspaceId &&
      componentInterface.getRootComponents(ctx.workspaceId)) ||
    [];

  const sourceDescendants = getDescendantsAsList(
    componentInterface.getChildren,
    connectedNodes
  );
  const connectedComponents = sourceDescendants.flatMap(sourceDescentant =>
    [
      ...(scenarioRelatedGraph?.sourceMap.get(sourceDescentant) || []),
      ...(scenarioRelatedGraph?.targetMap.get(sourceDescentant) || []),
    ].map(({ componentId }) => componentId)
  );

  const getParent = (id: string) => {
    const node = byId[id] as unknown as APIComponentAttributes | null;
    if (!node) return null;
    return node.parent || node.rootWorkspace || null;
  };

  const contextAncestorNodes = [
    MAIN_NODE_ID,
    ...getAncestorsAsList(getParent, [...new Set(connectedComponents)]),
  ];
  return [...new Set([...contextAncestorNodes, ...connectedNodes])];
};

const fixupPayload = (payload: NotifyChangedPayload) => {
  const empty = { scenarioId: null, workspaceId: null, componentId: null };
  if (!payload) {
    return empty;
  }
  if (typeof payload === 'string') {
    return { ...empty, scenarioId: payload };
  }

  return { ...empty, ...payload };
};

const updateCollapsedOnContextChange = routine(
  ofType<NotifyChangedPayload>(
    notifyComponentChanged,
    notifyWorkspaceChanged,
    notifyScenarioChanged,
    setLoadedScenarioData
  ),
  filter(() => Boolean(getActiveScenarioId())),
  extractPayload(),
  withLatestFrom(loadedScenarioData$, scenarioRelated$, context$),
  tap(([payload, { scenarioRelatedGraph }, { byId }, ctx]) => {
    const ids = getConnectedNodesWithContextAncestors(
      scenarioRelatedGraph,
      byId,
      ctx,
      fixupPayload(payload)
    );
    if (!ids) {
      dispatchAction(setCollapsed([MAIN_NODE_ID]));
      dispatchAction(resetSelection());
      return;
    }
    dispatchAction(setFiltered(ids));
    dispatchAction(setCollapsed(ids));
  })
);

const resetScenarioRelatedStreams = routine(
  ofType(closeScenarioSuccess),
  tap(() => {
    dispatchAction(setCollapsed([MAIN_NODE_ID]));
    dispatchAction(resetSelection());
    dispatchAction(hideScopeRelatedNavigator());
  })
);

export const scenarioRelatedRoutines = collectRoutines(
  clickSelectedRoutine,
  updateCollapsedOnContextChange,
  resetScenarioRelatedStreams
);
