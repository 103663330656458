import { PerspectiveGroupingRule } from '@ardoq/perspectives';
import { groupingRuleInterface } from 'modelInterface/groupingRules/groupingRuleInterface';
import { GroupType, GroupingRule } from '@ardoq/api-types';
import { trackApplyGrouping } from '../../perspective/tracking';
import activeView$ from '../views/mainContent/activeView$';

export const applyGroupingRules = (
  groupingRules: PerspectiveGroupingRule[]
) => {
  groupingRuleInterface.applyNewRules(omitIds(groupingRules));
  trackApplyGroupingRules(groupingRules);
};

const omitIds = (groupingRules: PerspectiveGroupingRule[]): GroupingRule[] => {
  return groupingRules.map(({ id: _id, ...rest }) => rest);
};

const trackApplyGroupingRules = (groupingRules: PerspectiveGroupingRule[]) => {
  if (!groupingRules?.length) {
    return;
  }

  const activeView = activeView$.state.mainViewId;
  const groupingRuleTypes = groupingRules.map(
    groupingRule => groupingRule.type
  );

  trackApplyGrouping({
    activeView,
    amountOfGroupings: groupingRules.length,
    groupByWorkspace: groupingRuleTypes.includes(GroupType.WORKSPACE),
    groupByReference: groupingRuleTypes.includes(GroupType.REFERENCE),
    groupByComponent: groupingRuleTypes.includes(GroupType.COMPONENT),
    groupByParent: groupingRuleTypes.includes(GroupType.PARENT),
    groupByParentAll: groupingRuleTypes.includes(GroupType.PARENT_ALL),
    groupByChild: groupingRuleTypes.includes(GroupType.CHILD),
    groupByField: groupingRuleTypes.includes(GroupType.FIELD),
    groupByTag: groupingRuleTypes.includes(GroupType.TAG),
    groupBySubdivision: groupingRuleTypes.includes(GroupType.SUBDIVISION),
  });
};
