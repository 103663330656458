import { APIToken } from '@ardoq/api-types';
import { SecondaryButton } from '@ardoq/button';
import { IconDecorator } from '@ardoq/decorators';
import { FormSize, TextInput } from '@ardoq/forms';
import { Icon, IconName } from '@ardoq/icons';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { connect } from '@ardoq/rxbeach';
import { Header4, Paragraph, Text } from '@ardoq/typography';
import { copyAndDisappearToken$ } from './copyAndDisappearToken$';
import { CopyAndDisappearTokenCommands } from './commands';
import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';

const _CopyAndDisappearToken = ({
  token,
  isCopied,
  isLoading,
  commands,
}: {
  isCopied: boolean;
  isLoading: boolean;
  commands: CopyAndDisappearTokenCommands;
  token?: APIToken;
}) => {
  if (!token?.token && !isLoading) {
    return null;
  }

  const serviceAccountName = token?.['user-name'];

  return (
    <Box
      padding="xlarge"
      borderColor="borderInfo"
      borderRadius="r8"
      backgroundColor="surfaceInfo"
    >
      {isLoading ? (
        <ArdoqLoaderComponent
          labelPosition="vertical"
          loaderColor={LoaderColor.BRAND}
          loaderType="logo"
          size={LoaderSize.SMALL}
        />
      ) : (
        <>
          {token && (
            <Stack gap="medium">
              <Stack gap="small">
                <Header4>
                  <FlexBox gap="small" align="center">
                    {serviceAccountName && (
                      <>
                        <IconDecorator iconName={IconName.ROLE}>
                          {serviceAccountName}
                        </IconDecorator>
                        <Text color="textSubtle">|</Text>
                      </>
                    )}
                    <IconDecorator iconName={IconName.KEY}>
                      {token.description}
                    </IconDecorator>
                  </FlexBox>
                </Header4>
                <Paragraph variant="caption" color="textModerate">
                  Make sure to copy the token. It will only be shown once, but
                  you can regenerate it if needed.
                </Paragraph>
              </Stack>
              <FlexBox gap="small">
                <TextInput value={token.token} formSize={FormSize.DEFAULT} />
                <SecondaryButton
                  onClick={() => commands.copyToken(token.token || '')}
                >
                  <Icon
                    iconName={isCopied ? IconName.CHECK_MARK : IconName.COPY}
                  />
                  {isCopied ? 'Token copied' : 'Copy token'}
                </SecondaryButton>
              </FlexBox>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};

export default connect(_CopyAndDisappearToken, copyAndDisappearToken$);
