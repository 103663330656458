import { PaneWrapper } from './atoms';
import QuickPerspectivesContainer from 'quickPerspectives/QuickPerspectivesContainer';
import { useOnMount } from '@ardoq/hooks';
import { dispatchContainerHeightChanged } from 'navigator2024/NavigatorRoot';

type QuickPerspectivesPaneProps = {
  setIsOpened?: (isOpened: boolean) => void;
};

const QuickPerspectivesPane = ({ setIsOpened }: QuickPerspectivesPaneProps) => {
  useOnMount(() => {
    dispatchContainerHeightChanged();
  });
  return (
    <PaneWrapper>
      <QuickPerspectivesContainer
        toggleShow={
          setIsOpened
            ? ({ expanded }) => {
                setIsOpened(expanded);
                setTimeout(dispatchContainerHeightChanged, 10);
              }
            : undefined
        }
      />
    </PaneWrapper>
  );
};

export default QuickPerspectivesPane;
