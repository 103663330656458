import { actionCreator } from '@ardoq/rxbeach';
import { IntegrationId } from '../tabularMappings/types';
import { MappedColumnsErrors, TabularMappingErrors } from './types';

const NAMESPACE = '[integrations] TABULAR_MAPPING_ERRORS';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export type setTableMappingErrorsPayload = {
  integrationId: IntegrationId;
  tableId: string;
  errors: MappedColumnsErrors;
};

export const setTableMappingErrors =
  actionCreator<setTableMappingErrorsPayload>(
    getActionName('SET_TABLE_MAPPING_ERRORS')
  );

export type setTabularMappingErrorsPayload = {
  integrationId: IntegrationId;
  errors: TabularMappingErrors;
};

export const setTabularMappingErrors =
  actionCreator<setTabularMappingErrorsPayload>(
    getActionName('SET_TABULAR_MAPPING_ERRORS')
  );

export type resetTabularMappingErrorsPayload = {
  integrationId: IntegrationId;
};

export const resetTabularMappingErrors =
  actionCreator<resetTabularMappingErrorsPayload>(
    getActionName('RESET_TABULAR_MAPPING_ERRORS')
  );
