import { s24 } from '@ardoq/design-tokens';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import ConditionalFormatting, {
  isConditionalFormatting,
} from './ConditionalFormatting';
import LabelFormatting from './LabelFormatting';
import { FilterInterfaceFilter, FormattingFilter } from '@ardoq/api-types';
import isFormattingValueEmpty from './isFormattingValueEmpty';

const Wrapper = styled.div`
  display: grid;
  gap: ${s24};
`;

const checkConditionalFormattingComplete = (
  allFormatting: (FormattingFilter | FilterInterfaceFilter)[]
) => {
  const conditionalFormatting =
    allFormatting?.filter(isConditionalFormatting) ?? [];

  return (
    conditionalFormatting.filter(
      format =>
        (format.comparator && !isFormattingValueEmpty(format) && format.name) ||
        (!format.comparator && !format.value && !format.name)
    ).length === conditionalFormatting.length
  );
};

const Formatting = () => {
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({
    name: 'conditionalFormatting',
    rules: {
      validate: {
        checkConditionalFormattingComplete: allFormatting =>
          checkConditionalFormattingComplete(allFormatting) ||
          'Formatting cannot contain a partially complete row.',
      },
    },
  });
  return (
    <Wrapper>
      <ConditionalFormatting onChange={onChange} error={error} />
      <LabelFormatting onChange={onChange} />
    </Wrapper>
  );
};

export default Formatting;
