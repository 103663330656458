import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  saveWorkspaceBindings,
  workspaceDeselected,
  workspaceSelected,
  workspaceBindingsSaved,
  workspaceBindingsSavingFailure,
  workspaceDeselection,
  workspaceSelection,
} from './actions';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { Subdivision } from '@ardoq/api-types';
import { showToast } from '@ardoq/status-ui';
import { logError, logWarn } from '@ardoq/logging';
import { handleError, subdivisionApi } from '@ardoq/api';
import { AddResourcesToSubdivisionPayload } from 'streams/subdivisions/actions';
import { Team } from '@ardoq/profiling';
import { touchStep } from 'subdivisionEditor/subdivisionEditorViewModel$/actions';
import { SubdivisionEditorSteps } from 'subdivisionEditor/navigation/types';
import workspaces$ from 'streams/workspaces/workspaces$';
import subdivisionNavigation$ from 'subdivisionEditor/navigation/subdivisionNavigation$';
import { workspaceOperations } from 'streams/workspaces/workspaceOperations';
import { ExcludeFalsy } from '@ardoq/common-helpers';

import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { subdivisionEditorOperations } from 'subdivisionEditor/subdivisionEditorOperations';

const STRINGS = SUBDIVISIONS_STRINGS.STEPS.WORKSPACES_BINDING.ROUTINES;

const handleSaveWorkspaceBindings = routine(
  ofType(saveWorkspaceBindings),
  extractPayload(),
  map(state => {
    const subdivision = state.subdivision as Subdivision;
    if (!subdivision._id) {
      logWarn(
        Error(
          'Failed to bind workspaces to subdivision: Subdivision is missing _id'
        ),
        null,
        {
          tags: { team: Team.APPSEC },
        }
      );
      showToast(STRINGS.IMPOSSIBLE_TO_ADD);
      dispatchAction(workspaceBindingsSavingFailure());
      return;
    }
    const toSaveWorkspaces =
      subdivisionEditorOperations.getAllSelectedWorkspacesToBindToSubdivision(
        state
      );

    if (
      toSaveWorkspaces.length ===
      state.bindWorkspacesState.workspacesBoundToSubdivisionIds.length
    ) {
      dispatchAction(workspaceBindingsSaved());
      return;
    }

    return {
      subdivisionId: subdivision._id,
      resourceIds: toSaveWorkspaces,
    } as AddResourcesToSubdivisionPayload;
  }),
  filter((payload): payload is AddResourcesToSubdivisionPayload => !!payload),
  switchMap(subdivisionApi.addResources),
  handleError(error => {
    logError(error, STRINGS.ADD_COMPONENTS_ERROR, {
      tags: { team: Team.APPSEC },
    });
    showToast(STRINGS.ADD_COMPONENTS_ERROR);
    dispatchAction(workspaceBindingsSavingFailure());
  }),
  tap(() => {
    dispatchAction(workspaceBindingsSaved());
  })
);

const handleWorkspaceDeselection = routine(
  ofType(workspaceDeselection),
  extractPayload(),
  withLatestFrom(workspaces$, subdivisionNavigation$),
  map(([workspaceIdToDeselect, workspaces, subdivisionNavigation]) => {
    const isBound = workspaceOperations.isSubdivisionBoundToWorkspace(
      workspaces.byId[workspaceIdToDeselect],
      subdivisionNavigation.subdivisionId
    );
    if (isBound) return;
    return workspaceIdToDeselect;
  }),
  filter(ExcludeFalsy),
  tap(workspaceId => {
    dispatchAction(workspaceDeselected(workspaceId));
    dispatchAction(touchStep(SubdivisionEditorSteps.WORKSPACES_BINDING));
  })
);

const handleWorkspaceSelection = routine(
  ofType(workspaceSelection),
  extractPayload(),
  tap(workspaceId => {
    dispatchAction(workspaceSelected(workspaceId));
    dispatchAction(touchStep(SubdivisionEditorSteps.WORKSPACES_BINDING));
  })
);

export const bindWorkspacesRoutines = collectRoutines(
  handleSaveWorkspaceBindings,
  handleWorkspaceDeselection,
  handleWorkspaceSelection
);
