import ComponentSubsetSelector from '../ComponentSubsetSelector';
import { SurveyBuilderLocation, SurveyValidation } from 'surveyAdmin/types';
import { Checkbox, FieldsWrapper, Label, TextInput } from '@ardoq/forms';
import {
  BooleanOperator,
  isPersistedSurvey,
  PermissionAccessLevel,
  QueryBuilderSubquery,
} from '@ardoq/api-types';
import { getComponentTypeAndModelId } from './utils';
import {
  hasComponentType,
  removeComponentCreationFromQuestions,
} from 'surveyAdmin/questions/utils';
import { trackEvent } from 'tracking/tracking';
import NewComponentParentSelector from 'surveyAdmin/NewComponentParentSelector';
import { Features, hasFeature } from '@ardoq/features';
import { confirm, ModalSize } from '@ardoq/modal';
import { Stack } from '@ardoq/layout';
import {
  SurveyEditorSectionProps,
  WithSetMiscAttributes,
} from './SurveyEditor';
import { ERROR_MESSAGES, SurveyErrorName } from 'surveyAdmin/consts';
import { validationHelpers } from 'surveyAdmin/surveyValidation';
import { shouldDisplayErrorMessageForField } from 'surveyAdmin/surveyUtil';
import surveyEditor$ from './streams/surveyEditor$';
import { map } from 'rxjs';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { setSurveyFieldHasBeenInteractedWith } from './streams/actions';

const SubDivisionsToggleHelperText = () => {
  return (
    <Stack gap="xsmall">
      Decide whether you want to use permission divisions in your survey.
      Enabling this will ensure respondents only see the components within their
      permission divisions.
      <div>
        If no divisions have been assigned to the components in the scope of the
        survey, the survey will be empty.
      </div>
    </Stack>
  );
};

type SurveyEditorWorkspaceAndComponentsSectionProps =
  SurveyEditorSectionProps & {
    surveyValidation: SurveyValidation | undefined;
    advancedSearchRules: QueryBuilderSubquery;
    advancedSearchCondition: BooleanOperator;
    showAdvancedOptions: boolean;
    isOrgAdmin: boolean;
    updateParentState: (args: any) => void;
    shouldShowWorkspaceErrorIfPresent: boolean;
    shouldShowComponentTypeErrorIfPresent: boolean;
  };

const SurveyEditorWorkspaceAndComponentsSection = ({
  surveyAttributes,
  surveyValidation,
  advancedSearchRules,
  advancedSearchCondition,
  showAdvancedOptions,
  isOrgAdmin,
  setSurveyAttributes,
  updateParentState,
  setMiscAttribute,
  shouldShowWorkspaceErrorIfPresent,
  shouldShowComponentTypeErrorIfPresent,
}: SurveyEditorWorkspaceAndComponentsSectionProps & WithSetMiscAttributes) => {
  const { selectedTypeId } = getComponentTypeAndModelId(
    surveyAttributes.workspace,
    surveyAttributes.componentTypeName
  );
  const componentTypeMissingOrInvalid = Boolean(
    hasComponentType(surveyAttributes.componentTypeName, selectedTypeId)
  );

  const togglePermissionDivisionAwareness = (enabled: boolean) => {
    const questionsWithoutComponentCreation =
      removeComponentCreationFromQuestions(surveyAttributes.questions);
    setSurveyAttributes({
      allowComponentCreation: false,
      questions: questionsWithoutComponentCreation,
      subdivisions: {
        ...surveyAttributes.subdivisions,
        enabled,
      },
    });
    trackEvent('Survey builder: make survey permission division aware', {
      from: SurveyBuilderLocation.DEFINE_YOUR_DATA,
      toggle: !enabled,
    });
  };

  return (
    <>
      <ComponentSubsetSelector
        onWorkspaceSelectBlur={() =>
          dispatchAction(
            setSurveyFieldHasBeenInteractedWith({
              fieldKey: 'workspaceAndComponentSectionWorkspaceSelectField',
            })
          )
        }
        onComponentTypeSelectBlur={() =>
          dispatchAction(
            setSurveyFieldHasBeenInteractedWith({
              fieldKey: 'workspaceAndComponentSectionComponentTypeSelectField',
            })
          )
        }
        accessLevel={PermissionAccessLevel.ADMIN}
        workspace={surveyAttributes.workspace}
        updateWorkspace={workspace => {
          setSurveyAttributes({ workspace });
        }}
        workspaceDoesNotExistErrorMessage={
          shouldShowWorkspaceErrorIfPresent
            ? validationHelpers.getErrorMessageIfExists(
                surveyValidation,
                SurveyErrorName.WORKSPACE_INVALID
              )
            : undefined
        }
        workspaceAccessErrorMessage={
          shouldShowWorkspaceErrorIfPresent
            ? validationHelpers.getErrorMessageIfExists(
                surveyValidation,
                SurveyErrorName.NO_ADMIN_ACCESS_TO_SURVEY_WORKSPACE
              )
            : undefined
        }
        workspaceRequiredErrorMessage={
          shouldShowWorkspaceErrorIfPresent
            ? 'This field is required'
            : undefined
        }
        compTypeMissingErrorMessage={
          shouldShowComponentTypeErrorIfPresent
            ? componentTypeMissingOrInvalid
              ? ERROR_MESSAGES.COMPONENT_TYPE_MISSING_OR_INVALID
              : undefined
            : undefined
        }
        componentTypeRequiredErrorMessage={
          shouldShowComponentTypeErrorIfPresent
            ? 'This field is required'
            : undefined
        }
        componentTypeName={surveyAttributes.componentTypeName}
        updateComponentTypeName={componentTypeName => {
          setSurveyAttributes({ componentTypeName });
        }}
        showMetamodelPreview={false}
        advancedSearchRules={advancedSearchRules}
        advancedSearchCondition={advancedSearchCondition}
        updateParentState={updateParentState}
        showAdvancedOptions={showAdvancedOptions}
        toggleAdvancedOptions={() =>
          updateParentState(
            (prevState: {
              showAdvancedOptions: boolean;
              advancedSearchRules: QueryBuilderSubquery;
            }) => ({
              advancedSearchRules: prevState.showAdvancedOptions
                ? null
                : {
                    ...prevState.advancedSearchRules,
                    rules: [],
                  },
              showAdvancedOptions: !prevState.showAdvancedOptions,
            })
          )
        }
        description={`
            Select the workspace and component which you would like
            to build a survey for. You can only select one workspace
            and component type per survey. However, you will be able
            to add references to other workspaces and components in the
            Questions section. You can break up your information
            gathering into several surveys.
          `}
        workspaceSelectHint="Required. You can only create surveys on workspaces which you have Administrator permissions on."
        componentTypeHint="Required"
        surveyBuilderLocation={SurveyBuilderLocation.DEFINE_YOUR_DATA}
      />
      {hasFeature(Features.PERMISSION_ZONES_INTERNAL) && (
        <FieldsWrapper>
          <Label popoverHelpContent={<SubDivisionsToggleHelperText />}>
            Respect permission divisions
          </Label>
          <Checkbox
            isChecked={Boolean(surveyAttributes.subdivisions?.enabled)}
            onChange={async enable => {
              const confirmToggleOn = enable
                ? await confirm({
                    title: 'Turn on permission division awareness?',
                    text: 'This feature is in beta and is not currently compatible with component creation.',
                    text2:
                      'Once confirmed, component creation will be disabled for this survey and any reference questions.',
                    confirmButtonTitle: 'Confirm',
                    cancelButtonTitle: 'Cancel',
                    modalSize: ModalSize.S,
                  })
                : true;
              if (!confirmToggleOn) return;
              togglePermissionDivisionAwareness(enable);
            }}
            isDisabled={surveyAttributes.approvalFlow?.enabled || !isOrgAdmin}
            helperText={
              surveyAttributes.approvalFlow?.enabled
                ? 'You cannot enable this feature whilst response approval is enabled. Please resolve any pending changes before turning off response approval if you wish to use this feature.'
                : !isOrgAdmin
                  ? 'This can only be changed by an admin'
                  : undefined
            }
          >
            Make survey permission division aware
          </Checkbox>
        </FieldsWrapper>
      )}
      <FieldsWrapper>
        <Checkbox
          label="Delete components"
          isChecked={Boolean(surveyAttributes.allowComponentDeletion)}
          onChange={() => {
            setSurveyAttributes({
              allowComponentDeletion: !surveyAttributes.allowComponentDeletion,
            });
            trackEvent('Survey builder: allow component deletion', {
              from: SurveyBuilderLocation.DEFINE_YOUR_DATA,
              toggle: !surveyAttributes.allowComponentDeletion,
            });
          }}
          popoverHelpContent="Decide whether respondents can delete components, both components
              created through the survey and created in-app. This will delete
              them in-app too, and cannot be undone."
        >
          Allow respondents to delete components
        </Checkbox>
      </FieldsWrapper>
      <NewComponentParentSelector
        allowComponentCreation={surveyAttributes.allowComponentCreation}
        updateAllowComponentCreation={allowComponentCreation =>
          setSurveyAttributes({
            allowComponentCreation: Boolean(allowComponentCreation),
          })
        }
        newComponentParentId={surveyAttributes.newComponentParentId}
        updateNewComponentParentId={newComponentParentId =>
          setSurveyAttributes({ newComponentParentId })
        }
        componentTypeName={surveyAttributes.componentTypeName}
        workspaceId={surveyAttributes.workspace}
        description={`
            Decide whether respondents can create new components,
            or merely select from a list of existing components.
            Unless you are confident your list is comprehensive,
            we recommend to allow your respondents to create
            new components.
          `}
        requiredNewComponentParentIsMissing={validationHelpers.containsErrorId(
          surveyValidation,
          SurveyErrorName.REQUIRED_NEW_COMPONENT_PARENT_IS_MISSING
        )}
        selectedNewComponentParentDoesNotExist={validationHelpers.containsErrorId(
          surveyValidation,
          SurveyErrorName.SELECTED_NEW_COMPONENT_PARENT_DOES_NOT_EXIST
        )}
        surveyBuilderLocation={SurveyBuilderLocation.DEFINE_YOUR_DATA}
        isDisabled={Boolean(surveyAttributes.subdivisions?.enabled)}
      />
      {surveyAttributes.allowComponentCreation && (
        <FieldsWrapper>
          <TextInput
            label="Name the component creation button"
            placeholder={`Document a new ${surveyAttributes.componentTypeName}`}
            value={surveyAttributes.misc.newCompButtonText}
            onChange={event =>
              setMiscAttribute('newCompButtonText', event.target.value)
            }
            dataTestId="new-comp-text-input"
            popoverHelpContent={
              <>
                <p>
                  Select what the survey respondents will be asked to do by the
                  survey. Other suggestions is &quot;Add new entry&quot;, or
                  translate the component type name to something more
                  understandable for your respondents.
                </p>
                <img
                  alt="image showing example digest"
                  src="/img/surveys/survey_component_creation_button.png"
                />
              </>
            }
          />
        </FieldsWrapper>
      )}
    </>
  );
};

export default connect(
  SurveyEditorWorkspaceAndComponentsSection,
  surveyEditor$.pipe(
    map(({ surveyAttributes, fieldHasBeenInteractedWith }) => ({
      shouldShowWorkspaceErrorIfPresent: shouldDisplayErrorMessageForField(
        isPersistedSurvey(surveyAttributes),
        surveyAttributes.misc.defineTheDataSectionStatus || 'initial',
        fieldHasBeenInteractedWith.workspaceAndComponentSectionWorkspaceSelectField
      ),
      shouldShowComponentTypeErrorIfPresent: shouldDisplayErrorMessageForField(
        isPersistedSurvey(surveyAttributes),
        surveyAttributes.misc.defineTheDataSectionStatus || 'initial',
        fieldHasBeenInteractedWith.workspaceAndComponentSectionComponentTypeSelectField
      ),
    }))
  )
);
