import {
  routine,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  upsertConnectionInitiate,
  updateConnectionStatuses,
} from 'integrations/common/streams/connections/actions';
import { startUpsertConnectionsModal } from 'integrations/service-now/modals/UpsertConnectionsModal';
import { filter, tap } from 'rxjs';

const handleUpsertConnectionInitiate = routine(
  ofType(upsertConnectionInitiate),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'servicenow-v3'),
  tap(({ integrationId, connection }) => {
    startUpsertConnectionsModal(connection?._id).then(() => {
      dispatchAction(
        updateConnectionStatuses({
          integrationId: integrationId,
          statuses: { upsert: { status: 'INIT' } },
        })
      );
    });
  })
);

export default [handleUpsertConnectionInitiate];
