import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import type { ViewPaneHeaderProps } from 'appLayout/ardoqStudio/types';
import ViewPaneHeaderLeftControls from './ViewPaneHeaderLeftControls';
import ViewPaneHeaderRightControls from './ViewPaneHeaderRightControls';
import viewPaneHeader$ from 'appLayout/ardoqStudio/viewPaneHeader$';
import { VIEW_PANE_BAR_HEIGHT, VIEW_PANE_BAR_PADDING } from './consts';

const HeaderContainer = styled.div`
  top: 0;
  display: flex;
  justify-content: space-between;
  user-select: none;
  margin: ${VIEW_PANE_BAR_PADDING}px;
  height: ${VIEW_PANE_BAR_HEIGHT}px;
  min-width: max-content;
`;

const ViewPaneHeader = (props: ViewPaneHeaderProps) => (
  <HeaderContainer>
    <ViewPaneHeaderLeftControls {...props} />
    <ViewPaneHeaderRightControls {...props} />
  </HeaderContainer>
);

const ConnectedViewPaneHeader = connect(ViewPaneHeader, viewPaneHeader$);
export default ConnectedViewPaneHeader;
