import { AccessControlTabs } from 'admin/accessControl/navigation/types';
import { PageBody } from '@ardoq/page-layout';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToAccessControlPage } from 'router/navigationActions';
import { trackEvent } from 'tracking/tracking';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import { useMemo } from 'react';
import { Box } from '@ardoq/layout';
import { AccessControlPageProps } from './types';
import {
  AccessControlMenuOptions,
  AccessControlMenuSingleOption,
} from './accessControlMenuOptions';
import { NavigationSidebar } from './NavigationSidebar/NavigationSidebar';
import { isNavigationMenuGroup } from './NavigationSidebar/types';
import { UserAssetsOverviewFilterSidebar } from 'admin/accessControl/UserProfilePage/UserRoleAndGroupsDetails/UserAssetsOverviewTab/UserAssetsOverviewFilterSidebar';

export const AccessControlMainPage = (
  props: AccessControlPageProps & {
    selectedTab: AccessControlMenuSingleOption | undefined;
    options: AccessControlMenuOptions[];
  }
) => {
  const { selectedTab, options, accessControlTab } = props;
  // To avoid infinite loops when the content component has sideEffects that updates the state.
  // For example, when the content component fetches data when it mounts.
  const Content = useMemo(
    () => selectedTab?.content,
    // We should not update this when the selectedTab.content changes
    // because it will always have a new reference.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTab?.id]
  );

  return (
    <PageBody
      alignIslandsToLeft
      rightContent={
        props.userAssetsFilterSidebarState.isVisible ? (
          <UserAssetsOverviewFilterSidebar
            commands={props.commands}
            userAssetsQuery={props.userAssetsQuery}
            userAssetsFilterSidebarState={props.userAssetsFilterSidebarState}
          />
        ) : undefined
      }
      leftContent={
        <Box width="fit-content" minWidth="320px" maxWidth="320px">
          <NavigationSidebar
            options={options}
            selectedItemId={selectedTab?.id}
            onItemClick={item => {
              if (isNavigationMenuGroup(item)) {
                trackEvent(
                  APPSEC_EVENTS.CLICKED_TOGGLE_MANAGE_ORGANIZATION_TAB_GROUP,
                  {
                    groupName: item.label,
                    namespace: 'access control',
                    toggleTo: item.isCollapsed ? 'closed' : 'open',
                  }
                );
                return;
              }
              trackEvent(APPSEC_EVENTS.CLICKED_MANAGE_ORGANIZATION_TAB, {
                to: item.id,
                from: accessControlTab,
                namespace: 'access control',
              });
              dispatchAction(
                navigateToAccessControlPage({
                  accessControlTab: item.id as AccessControlTabs,
                })
              );
            }}
          />
        </Box>
      }
    >
      {selectedTab && Content && <Content {...props} />}
    </PageBody>
  );
};
