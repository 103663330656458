import { OrgAccessLevel } from '@ardoq/api-types';
import { PrivilegeAction, WarningMessage } from 'privileges/types';
import { PluralOperand } from '@ardoq/common-helpers';
import type { LocalizedTextPlural } from '@ardoq/common-helpers';

const STRINGS_BY_ACTION: Record<PrivilegeAction, Record<string, string>> = {
  [PrivilegeAction.ASSIGN]: {
    HEADER: 'Assign privileges',
    DROPDOWN_QUESTION: 'What privileges do you want to assign?',
    SAVE_BUTTON: 'Assign privileges',
    NOTIFICATION:
      'This will assign privileges per user, in addition to those on their role.',
  },
  [PrivilegeAction.REMOVE]: {
    HEADER: 'Remove privileges',
    DROPDOWN_QUESTION: 'What privileges do you want to remove?',
    SAVE_BUTTON: 'Remove privileges',
    NOTIFICATION:
      "A user's privileges won't be removed if they have received those privileges through their role.",
  },
};

const WARNING_MESSAGE: Record<WarningMessage, string> = {
  [WarningMessage.CANNOT_ASSIGN]:
    "can't be assigned to this user. Change the user's role to assign these privileges.",
  [WarningMessage.CANNOT_REVOKE]:
    "can't be removed from this user because of their ({role}) role.",
};

const ROLES: Record<OrgAccessLevel, string> = {
  [OrgAccessLevel.ADMIN]: 'Admin',
  [OrgAccessLevel.CONTRIBUTOR]: 'Contributor',
  [OrgAccessLevel.READER]: 'Reader',
  [OrgAccessLevel.WRITER]: 'Writer',
};

const SELECTED_USER: LocalizedTextPlural = {
  [PluralOperand.ONE]: 'selected user',
  [PluralOperand.OTHER]: 'selected users',
};

export const MANAGE_PRIVILEGE_MODAL = {
  STRINGS_BY_ACTION,
  WARNING_MESSAGE,
  ROLES,
  SELECTED_USER,
  _COMMON: {
    DROPDOWN_PLACEHOLDER: 'Type and select privileges',
    CANCEL_BUTTON: 'Cancel',
    DOC_MESSAGE: 'Read more about privileges',
    DOC_LINK: 'here',
  },
  NAME: 'Name',
  EMAIL: 'Email',
  ROLE: 'Role',
};
