import { spreadPayloadOverState } from '@ardoq/common-helpers';
import {
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';

type ManageUsersTabID = 'INVITE' | 'MANAGE_USERS' | 'MANAGE_SCIM_TOKEN';

export type ManageUsersStreamShape = {
  activeTab: ManageUsersTabID;
  hasInternalAndExternalUserManagement: boolean | null;
  hasInternalUserManagement: boolean | null;
};

const defaultState: ManageUsersStreamShape = {
  activeTab: 'MANAGE_USERS',
  hasInternalAndExternalUserManagement: null,
  hasInternalUserManagement: null,
};

export const setManageUsersState = actionCreator<
  Partial<ManageUsersStreamShape>
>('[manageUsers] Set state');

export const manageUsers$ = persistentReducedStream(
  'manageUsers$',
  defaultState,
  [reducer(setManageUsersState, spreadPayloadOverState)]
);
