import {
  APIReferenceAttributes,
  APISurveyApprovedChanges,
  ArdoqId,
  ChangeRequestAction,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import {
  ApproveOrRejectAllComponentPayload,
  ApprovalFocus,
  ChangeApprovalData,
  FieldDataArgs,
  ComponentFieldResetArgs,
  ReferenceFieldResetArgs,
  ResetInvalidChangeRequestPayload,
} from './types';
import { SwitchPosition } from '@ardoq/snowflakes';

export const fetchChangeApprovalData = actionCreator<ArdoqId>(
  '[changeApproval] FETCH_CHANGE_APPROVAL_DATA'
);

export const notifyFetchingChangeApprovalDataSucceeded =
  actionCreator<ChangeApprovalData>(
    '[changeApproval] NOTIFY_FETCHING_CHANGE_APPROVAL_DATA_SUCCEEDED'
  );

export const notifyFetchingChangeApprovalDataFailed = actionCreator(
  '[changeApproval] NOTIFY_FETCHING_CHANGE_APPROVAL_DATA_FAILED'
);

export const setApprovedDataForComponentField = actionCreator<FieldDataArgs>(
  '[changeApproval] SET_APPROVED_DATA_FOR_COMPONENT_FIELD'
);

export const resetDataForComponentField =
  actionCreator<ComponentFieldResetArgs>(
    '[changeApproval] RESET_DATA_FOR_COMPONENT_FIELD'
  );

export const setApprovedDataForReferenceField = actionCreator<FieldDataArgs>(
  '[changeApproval] SET_APPROVED_DATA_FOR_REFERENCE_FIELD'
);

export const resetDataForReferenceField =
  actionCreator<ReferenceFieldResetArgs>(
    '[changeApproval] RESET_DATA_FOR_REFERENCE_FIELD'
  );

export const setApprovedDataForEntireComponent =
  actionCreator<ApproveOrRejectAllComponentPayload>(
    '[changeApproval] SET_APPROVED_DATA_FOR_ENTIRE_COMPONENT'
  );

export const approveEntireReference = actionCreator<APIReferenceAttributes>(
  '[changeApproval] APPROVE_ENTIRE_REFERENCE'
);

export const rejectEntireReference = actionCreator<APIReferenceAttributes>(
  '[changeApproval] REJECT_ENTIRE_REFERENCE'
);

export const confirmApprovedChanges = actionCreator<ArdoqId>(
  '[changeApproval] CONFIRM_APPROVED_CHANGES'
);

export const saveApprovedChanges = actionCreator<{
  surveyId: ArdoqId;
  changes: APISurveyApprovedChanges;
  componentId: ArdoqId;
}>('[changeApproval] SAVE_APPROVED_CHANGES');

export const notifySavingApprovedChangesSucceeded = actionCreator(
  '[changeApproval] NOTIFY_SAVING_APPROVED_CHANGES_SUCCEEDED'
);

export const cancelSavingApprovedChanges = actionCreator(
  '[changeApproval] CANCEL_SAVING_APPROVED_CHANGES'
);

export const setSearchPhrase = actionCreator<string>(
  '[changeApproval] SET_SEARCH_PHRASE'
);

export const setSortBy = actionCreator<string>('[changeApproval] SET_SORT_BY');

export const setPerPage = actionCreator<number>(
  '[changeApproval] SET_PER_PAGE'
);

export const setFilterChips = actionCreator<string[]>(
  '[changeApproval] SET_FILTER_CHIPS'
);

export const setActiveComponentId = actionCreator<ArdoqId>(
  '[changeApproval] SET_ACTIVE_COMPONENT_ID'
);

export const setChangeRequestAction = actionCreator<ChangeRequestAction>(
  '[changeApproval] SET_CHANGE_REQUEST_ACTION'
);

export const resetDataForEntireComponent = actionCreator<ArdoqId>(
  '[changeApproval] RESET_DATA_FOR_ENTIRE_COMPONENT'
);

export const resetDataForEntireReference = actionCreator<ArdoqId>(
  '[changeApproval] RESET_DATA_FOR_ENTIRE_REFERENCE'
);

export const setApprovalFocus = actionCreator<ApprovalFocus>(
  '[changeApproval] SET_APPROVAL_FOCUS'
);

export const setApprovedReferenceData = actionCreator<{
  switchPosition: SwitchPosition;
  reference: APIReferenceAttributes;
}>('[changeApproval] SET_APPROVED_REFERENCE_DATA');

export const resetAnswers = actionCreator<ArdoqId>(
  '[changeApproval] RESET_ANSWERS'
);

export const resetInvalidChangeRequest =
  actionCreator<ResetInvalidChangeRequestPayload>(
    '[changeApproval] RESET_INVALID_CHANGE_REQUEST'
  );
