import { combineLatest, map } from 'rxjs';
import { dispatchAction } from '@ardoq/rxbeach';
import { serviceNowTables$ } from '../../streams/tables/tables$';
import { selectionState$ } from '../../streams/selectionState/selectionState$';
import {
  selectTable,
  unselectTable,
} from '../../streams/selectionState/actions';
import { TablesSelectionBaseTable } from '@ardoq/integrations';

const onSelectTable = (table: TablesSelectionBaseTable) => {
  dispatchAction(selectTable(table.name));
};

const onUnselectTable = (table: TablesSelectionBaseTable) => {
  dispatchAction(unselectTable(table.name));
};

export const viewModel$ = combineLatest([
  serviceNowTables$,
  selectionState$,
]).pipe(
  map(([sntables, selectionState]) => {
    return {
      tables: sntables.tables,
      selectionState,
      tablesStatus: sntables.asyncStatus,
      onSelectTable,
      onUnselectTable,
    };
  })
);
