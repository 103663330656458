import { FiltersAsQueryBuilderQueries } from '@ardoq/filter-interface';
import Filters from 'collections/filters';
import {
  ExtractPayload,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import { notifyFiltersChanged } from 'streams/filters/FilterActions';

const updateFilterRules = (
  _: FiltersAsQueryBuilderQueries,
  payload: ExtractPayload<typeof notifyFiltersChanged>
) => payload?.filters ?? Filters.getFiltersAsQueryBuilderQueries();

const filterRules$ = persistentReducedStream<FiltersAsQueryBuilderQueries>(
  'filterRules$',
  Filters.getFiltersAsQueryBuilderQueries(),
  [reducer(notifyFiltersChanged, updateFilterRules)]
);
export default filterRules$;
