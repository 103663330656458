import { VISUAL_DIFF_BACKGROUND } from '@ardoq/color-helpers';

const Circle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    width="28px"
    height="28px"
  >
    <circle cx="14" cy="14" r="12" fill={VISUAL_DIFF_BACKGROUND} />
  </svg>
);

export default Circle;
