export const CollapsingRuleErrorMessage = {
  overlappingWithItself:
    'The selected path contains repeated component and reference type connections that would cause multiple conflicting matches. Please select a path with unique components.',
  subpathOfExistingRule:
    'The selected path is already in an existing longer collapsed path. Please edit the existing path or select a different path.',
  superpathOfExistingPath:
    'The selected path contains an existing shorter collapsed path. Please edit the existing path or select a different path.',
  partiallyOverlappingPath:
    'The selected path overlaps with an existing collapsed path. Please edit the existing path or select a different path.',
  dividingGraph:
    'The selected path disconnects some components from the context component. Please select a path that keeps the connections to your context component.',
  pathMustHideAtLeastOneComponent:
    'The selected path must have at least 1 component type between the start and end of the path',
  collapsingPathHidesStartNode: (componentTypeName: string) =>
    `Please select a path that doesn’t hide the ${componentTypeName} context component`,
};
