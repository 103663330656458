import {
  PermissionContext,
  canDo,
  permissionsOperations,
} from '@ardoq/access-control';
import { ArdoqId } from '@ardoq/api-types';

const canAdminReport = ({
  permissionContext,
  reportId,
}: {
  permissionContext: PermissionContext;
  reportId: ArdoqId;
}): boolean =>
  canDo({
    action: 'report/admin',
    permissionContext,
    resourceId: reportId,
  });

const canEditReport = ({
  permissionContext,
  reportId,
}: {
  permissionContext: PermissionContext;
  reportId: ArdoqId;
}): boolean =>
  canDo({
    action: 'report/edit',
    permissionContext,
    resourceId: reportId,
  });

const canAccessReport = ({
  permissionContext,
  reportId,
}: {
  permissionContext: PermissionContext;
  reportId: ArdoqId;
}): boolean =>
  canDo({
    action: 'report/read',
    permissionContext,
    resourceId: reportId,
  });

const canCreateReport = (permissionContext: PermissionContext): boolean => {
  if (!permissionsOperations.isLoadedPermissionContext(permissionContext)) {
    return false;
  }

  return canDo({
    action: 'organization/create_report',
    permissionContext,
    resourceId: permissionsOperations.getOrgIdFromContext(permissionContext),
  });
};

export const reportAccessControlInterface = {
  canCreateReport,
  canEditReport,
  canAdminReport,
  canAccessReport,
};
