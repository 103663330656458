import {
  DirectedTripleWithFilters,
  QueryBuilderSubquery,
  ViewpointBuilderFilters,
} from '@ardoq/api-types';
import { omit } from 'lodash';

type GetStartSetFilterArgs = {
  paths?: DirectedTripleWithFilters[][];
  filters?: ViewpointBuilderFilters;
};

export const getStartSetFilter = ({
  paths,
  filters,
}: GetStartSetFilterArgs): QueryBuilderSubquery | null => {
  if (!(paths && paths[0] && paths[0][0] && filters)) {
    return null;
  }
  const { direction, sourceFilter, targetFilter } = paths[0][0];
  const startSetFilterId =
    direction === 'outgoing' ? sourceFilter : targetFilter;
  return startSetFilterId ? omit(filters[startSetFilterId], 'filterId') : null;
};
