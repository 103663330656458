import { resetIntegration } from '../activeIntegrations/actions';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { tap } from 'rxjs/operators';
import { resetTabularMappingConstraints } from './actions';

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  tap(integrationId => {
    dispatchAction(resetTabularMappingConstraints({ integrationId }));
  })
);

export default [handleResetIntegration];
