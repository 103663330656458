import { SortOption } from '../NavigatorTopBar/types';
import Context from 'context';

const setSorting = (sortOption: SortOption) => {
  Context.toggleSort(sortOption.attrName, sortOption.label, true);
};

export const viewpointSidebarCommands = {
  setSorting,
};
