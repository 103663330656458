import { FlexBox } from '@ardoq/layout';
import { PageBody } from '@ardoq/page-layout';
import { EmptyState, EmptyStateNoResultIllustration } from '@ardoq/status-ui';
import { UserProfilePageEvents, UserProfilePageProps } from './types';
import { useEffectOnce } from '@ardoq/hooks';
import { UserPersonalDetails } from './UserPersonalDetails';
import { UserRoleAndGroupsDetails } from './UserRoleAndGroupsDetails';
import { trackUserProfilePageEvents } from './utils';
import { space } from '@ardoq/design-tokens';
import { SecondaryButton } from '@ardoq/button';
import { ChevronLeftIcon } from '@ardoq/icons';

export const UserProfilePage = (props: UserProfilePageProps) => {
  useEffectOnce(() => {
    trackUserProfilePageEvents(UserProfilePageEvents.OPENED_USER_PROFILE_PAGE);
    props.commands.requestUserAssets(props.userAssetsQuery);
  });
  if (!props.user) {
    return (
      <FlexBox align="center" justify="center" height="full" width="full">
        <div>
          <SecondaryButton
            onClick={() => props.commands.goToUsersOverviewPage('user-profile')}
          >
            <ChevronLeftIcon />
            Go back to users
          </SecondaryButton>
        </div>
        <EmptyState
          description="This user doesn't exist."
          layout="vertical"
          size="large"
          media={<EmptyStateNoResultIllustration />}
          title="No user found"
        />
      </FlexBox>
    );
  }
  return (
    <PageBody padding={`0 ${space.medium} 0 0`}>
      <div>
        <SecondaryButton
          onClick={() => props.commands.goToUsersOverviewPage('user-profile')}
        >
          <ChevronLeftIcon />
          Go back to users
        </SecondaryButton>
      </div>
      <UserPersonalDetails {...props} />
      <UserRoleAndGroupsDetails {...props} />
    </PageBody>
  );
};
