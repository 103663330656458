import Navbar from 'views/navbar/Navbar';
import { SubdivisionProps } from './types';
import {
  ButtonGroup,
  PrimaryButton,
  BrandButton,
  GhostButton,
} from '@ardoq/button';
import {
  ChevronLeftIcon,
  IconSize,
  KnowledgeBaseIcon,
  SaveIcon,
} from '@ardoq/icons';
import { subdivisionEditorOperations } from './subdivisionEditorOperations';
import { subdivisionEditorStepsOperations } from './subdivisionEditorStepsOperations';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { PageHeader } from '@ardoq/page-layout';
import { FlexBox } from '@ardoq/layout';
import { colors } from '@ardoq/design-tokens';
import { Link } from '@ardoq/typography';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';

export const SubdivisionPageHeader = (props: SubdivisionProps) => {
  const { hasNewCoreJourneyFeature, isSubmitting, commands } = props;
  const title =
    subdivisionEditorOperations.getName(props) ||
    SUBDIVISIONS_STRINGS.SUBDIVISIONS_EDITOR.CREATE_NEW;
  const canSave = subdivisionEditorStepsOperations.canSaveChanges(props);
  const onSave = () => commands.saveChanges(props);
  const saveButtonText = 'Save changes';

  const backToOverviewButton = (
    <GhostButton
      isDisabled={isSubmitting}
      onClick={commands.goToSubdivisionOverview}
    >
      <ChevronLeftIcon />
      Back to Permissions
    </GhostButton>
  );

  return (
    <>
      {hasNewCoreJourneyFeature ? (
        <Navbar
          primaryContent={
            <FlexBox gap="small">
              {title}
              <Link
                href={KnowledgeBaseLink.HOW_TO_CREATE_A_DIVISION}
                underlineOnHover={false}
                target="_blank"
                typography="text2"
                hideIcon={true}
              >
                <KnowledgeBaseIcon
                  data-tooltip-text="Knowledge base"
                  size={IconSize.SMALL}
                  color={colors.textSubtle}
                />
              </Link>
            </FlexBox>
          }
          primaryButton={
            !subdivisionEditorOperations.isCreationMode(props) && (
              <BrandButton
                isDisabled={!canSave}
                isLoading={props.isSubmitting}
                onClick={onSave}
              >
                {saveButtonText}
                <SaveIcon />
              </BrandButton>
            )
          }
          secondaryContent={'Permission builder'}
          secondaryButton={backToOverviewButton}
        />
      ) : (
        <PageHeader
          title={title}
          rightContent={
            <ButtonGroup>
              {backToOverviewButton}
              {!subdivisionEditorOperations.isCreationMode(props) && (
                <PrimaryButton
                  isDisabled={!canSave}
                  isLoading={props.isSubmitting}
                  onClick={onSave}
                >
                  {saveButtonText}
                </PrimaryButton>
              )}
            </ButtonGroup>
          }
        />
      )}
    </>
  );
};
