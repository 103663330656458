import { BrandButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { EmptyState, EmptyStateNoResultIllustration } from '@ardoq/status-ui';
import { GroupDetailsPageProps } from '../types';

export const EmptyUsers = (props: GroupDetailsPageProps) => {
  const { group, commands, groupsById } = props;
  return (
    <FlexBox
      align="center"
      justify="center"
      height="full"
      width="full"
      paddingBottom="xxlarge"
      paddingTop="xxlarge"
    >
      <EmptyState
        description="Customize your group to fit your needs. Use the Edit group button to add members, update the group name, or manage its settings."
        layout="horizontal"
        size="large"
        media={<EmptyStateNoResultIllustration />}
        title="Add users to your group"
      >
        <BrandButton
          onClick={() => commands.addUsersToGroupModal(groupsById[group._id])}
        >
          Add users
          <Icon iconName={IconName.ADD} />
        </BrandButton>
      </EmptyState>
    </FlexBox>
  );
};
