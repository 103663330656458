import { useState } from 'react';
import { RadioGroup, RadioGroupItem, TextInput } from '@ardoq/forms';
import styled from 'styled-components';
import { URLInputWithPrefix } from '../urlInputWithPrefix/URLInputWithPrefix';

const WideInput = styled(TextInput)`
  width: 100%;
`;

function prepareBaseUrl({
  baseUrl,
  urlProtocol,
  standardDomain,
}: {
  baseUrl: string;
  urlProtocol: string;
  standardDomain: string;
}) {
  return baseUrl.replace(urlProtocol, '').replace(standardDomain, '');
}

type URLInputOptionProps = {
  label?: string;
  urlProtocol: string;
  standardDomain: string;
  baseUrl: string;
  onChange: (val: string) => void;
};

export function URLInputOption({
  label,
  urlProtocol,
  standardDomain,
  baseUrl,
  onChange,
}: URLInputOptionProps) {
  const [isStandardDomain, setIsStandardDomain] = useState<boolean>(true);

  return (
    <RadioGroup
      defaultValue="standard-domain"
      label={label || 'Base URL'}
      onValueChange={value => {
        setIsStandardDomain(value === 'standard-domain');
      }}
    >
      <RadioGroupItem value="standard-domain">Standard domain</RadioGroupItem>
      {isStandardDomain && (
        <WideInput
          prefix={urlProtocol}
          postfix={standardDomain}
          value={prepareBaseUrl({ baseUrl, urlProtocol, standardDomain })}
          onValueChange={(val: string) =>
            onChange(`${urlProtocol}${val}${standardDomain}`)
          }
        />
      )}
      <RadioGroupItem value="custom-domain">Custom domain</RadioGroupItem>
      {!isStandardDomain && (
        <URLInputWithPrefix
          urlProtocol={urlProtocol}
          value={baseUrl.replace(standardDomain, '')}
          onChange={onChange}
        />
      )}
    </RadioGroup>
  );
}
