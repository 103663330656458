import { Component } from 'react';
import {
  PresentationReadPermissions,
  PresentationWritePermissions,
} from '@ardoq/api-types';
import { IconButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import { closePresentation } from 'presentation/presentations2024/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { Header3 } from '@ardoq/typography';
import { FlexBox } from '@ardoq/layout';
import { FullHeightStack, SidebarContentBody } from '../components/atoms';
import PresentationDetails from '../components/PresentationDetails';
import AccessRights from '../components/AccessRights';
import FooterButtons from '../components/FooterButtons';
import { createPresentationAndOpenInPreview } from 'streams/presentations/actions';

type NewPresentationState = {
  name: string;
  description: string;
  readAccess: PresentationReadPermissions;
  writeAccess: PresentationWritePermissions;
  exploreMode: boolean;
};

type NewPresentationProps = {
  loading: boolean;
};

export default class NewPresentation extends Component<
  NewPresentationProps,
  NewPresentationState
> {
  constructor(props: NewPresentationProps) {
    super(props);
    this.state = {
      name: '',
      description: '',
      readAccess: PresentationReadPermissions.ME,
      writeAccess: PresentationWritePermissions.ME,
      // Whether explore mode is disabled
      exploreMode: true,
    };
    this.create = this.create.bind(this);
  }

  async create() {
    const { name, description, readAccess, writeAccess, exploreMode } =
      this.state;
    dispatchAction(
      createPresentationAndOpenInPreview({
        name,
        description,
        readAccess,
        writeAccess,
        lockedContext: exploreMode,
      })
    );
  }

  render() {
    return (
      <FullHeightStack>
        <FlexBox align="center" justify="space-between" padding="medium">
          <Header3>New presentation</Header3>
          <IconButton
            iconName={IconName.CLOSE}
            onClick={() => dispatchAction(closePresentation())}
          />
        </FlexBox>
        <FullHeightStack>
          <SidebarContentBody>
            <form role="form">
              <PresentationDetails
                name={this.state.name}
                description={this.state.description}
                canEdit={true}
                updateName={value => this.setState({ name: value })}
                updateDescription={value =>
                  this.setState({ description: value })
                }
              />
              <AccessRights
                readAccess={this.state.readAccess}
                writeAccess={this.state.writeAccess}
                exploreMode={this.state.exploreMode}
                updateReadAccess={readAccess => this.setState({ readAccess })}
                updateWriteAccess={writeAccess =>
                  this.setState({ writeAccess })
                }
                updateExploreMode={exploreMode =>
                  this.setState({ exploreMode })
                }
                canEditAccessRights={true}
              />
            </form>
          </SidebarContentBody>
          <FooterButtons
            isDisabled={Boolean(!this.state.name) || this.props.loading}
            onSave={this.create}
          />
        </FullHeightStack>
      </FullHeightStack>
    );
  }
}
