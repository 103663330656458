import { AppModules } from 'appContainer/types';
import { AppRouterState } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { map } from 'rxjs/operators';
import externalDocument$ from './externalDocument$';
import { APP_ROUTER_DEFAULT_LOCATION } from 'router/appRouterUtils';
import { navigateToExternalDocument } from './actions';
import { dispatchAction } from '@ardoq/rxbeach';

export interface ExternalDocumentRoute {
  externalDocumentId: string | null;
  externalDocumentTitle: string | null;
}

const externalDocumentRoute = new Route<AppRouterState, ExternalDocumentRoute>({
  doesLocationMatch: ({ path }) => /\/external-document\/?.*?/.test(path),
  locationToRouterState: ({ path }) => {
    const parts = path.split('/');
    const props = parts[2];
    const [externalDocumentId, externalDocumentTitle] = props.split('+');
    return {
      appModule: AppModules.EXTERNAL_DOCUMENT,
      externalDocumentId: externalDocumentId,
      externalDocumentTitle: externalDocumentId
        ? externalDocumentTitle
        : 'External Document',
    };
  },
  doesRouterStateMatch: ({ appModule }) =>
    appModule === AppModules.EXTERNAL_DOCUMENT,
  routerStateToLocation: ({ externalDocumentId, externalDocumentTitle }) => {
    if (externalDocumentId && externalDocumentTitle) {
      return {
        path: `/external-document/${externalDocumentId}+${externalDocumentTitle}`,
        externalDocumentTitle,
      };
    }
    if (externalDocumentId) {
      return {
        path: `/external-document/${externalDocumentId}`,
        externalDocumentTitle: 'External Document',
      };
    }
    // Navigate to default location until an overview route exists
    return APP_ROUTER_DEFAULT_LOCATION;
  },
  setApplicationStateFromRoute: ({
    externalDocumentId,
    externalDocumentTitle,
  }) => {
    const decodedExternalDocumentTitle = decodeURIComponent(
      externalDocumentTitle || ''
    );
    if (externalDocumentId) {
      dispatchAction(
        navigateToExternalDocument({
          externalDocumentId,
          externalDocumentTitle:
            decodedExternalDocumentTitle || 'External Document',
        })
      );
    }
  },
  getPartialRouterStateStream: () =>
    externalDocument$.pipe(
      map(({ externalDocumentId, externalDocumentTitle }) => ({
        externalDocumentId,
        externalDocumentTitle,
      }))
    ),
});

export default externalDocumentRoute;
