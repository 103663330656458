import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { of, switchMap, tap, withLatestFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  loadSchedule,
  setLoadedScheduleId,
} from 'integrations/common/streams/schedule/actions';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { isCloudSchedule } from 'integrations/common/streams/schedules/utils';
import { startMissingConnectionModal } from 'integrations/common/modals/missingConnectionModal/MissingConnectionModal';
import { isCloudProviderIntegrationId } from 'integrations/cloudProviders/utils';
import { applySourceConfig } from './actions';
import { navigateToSelectData } from 'integrations/common/navigation/actions';
import { CloudProviderConfig } from '@ardoq/api-types/integrations';

const handleLoadSchedule = routine(
  ofType(loadSchedule),
  extractPayload(),
  filter(({ integrationId }) => isCloudProviderIntegrationId(integrationId)),
  switchMap(({ integrationId, scheduleId }) =>
    of({ integrationId, scheduleId }).pipe(
      withLatestFrom(
        getSchedulesStream(integrationId),
        getConnectionsStream(integrationId)
      )
    )
  ),
  tap(([{ integrationId, scheduleId }, { schedules }, { connections }]) => {
    const schedule = schedules.find(s => scheduleId === s._id);

    if (!schedule || !isCloudSchedule(schedule)) {
      return;
    }

    const handleLoadSchedule = (
      scheduleId: string,
      config: CloudProviderConfig
    ) => {
      dispatchAction(
        setLoadedScheduleId({
          scheduleId,
          integrationId,
        })
      );
      dispatchAction(navigateToSelectData({ integrationId }));
      dispatchAction(applySourceConfig({ ...config, integrationId }));
    };

    const connection = connections.find(conn =>
      schedule.jobOptions.cloudProviderConfig.accountIds.includes(conn._id)
    );

    if (!connection) {
      return startMissingConnectionModal({
        onCancel: () => null,
        onSubmit: connection =>
          handleLoadSchedule(schedule._id, {
            ...schedule.jobOptions.cloudProviderConfig,
            accountIds: [connection._id],
          }),
      });
    }
    handleLoadSchedule(schedule._id, schedule.jobOptions.cloudProviderConfig);
  })
);

export default [handleLoadSchedule];
