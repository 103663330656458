import { actionCreator } from '@ardoq/rxbeach';
import { DataOverviewTypes, DataOverviewStatus } from './types';
import { ArdoqId } from '@ardoq/api-types';

export const dataOverviewTypeSelected = actionCreator<DataOverviewTypes>(
  '[dataOverview] DATA_OVERVIEW_TYPE_SELECTED'
);

export const numberOfRowsPerPageSelected = actionCreator<number>(
  '[dataOverview] NUMBER_OF_ROWS_PER_PAGE_SELECTED'
);

export const dataOverviewSearchValueChanged = actionCreator<string>(
  '[dataOverview] DATA_OVERVIEW_SEARCH_VALUE_CHANGED'
);

export const dataOverviewStatusChanged = actionCreator<DataOverviewStatus>(
  '[dataOverview] DATA_OVERVIEW_STATUS_CHANGED'
);

export const dataOverviewSelectedItemToggled = actionCreator<ArdoqId>(
  '[dataOverview] DATA_OVERVIEW_SELECTED_ITEM_TOGGLED'
);

export const dataOverviewSelectedItemsCleared = actionCreator(
  '[dataOverview] DATA_OVERVIEW_SELECTED_ITEMS_CLEARED'
);
