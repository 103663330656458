import { useRef } from 'react';
import { colors, r4 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import ViewOptionsHeader from './ViewOptionsHeader';
import ViewPicker from './ViewPicker';
import { ViewPaneHeaderProps } from 'appLayout/ardoqStudio/types';
import { connect } from '@ardoq/rxbeach';
import viewPaneHeader$ from 'appLayout/ardoqStudio/viewPaneHeader$';
import PerspectivesAndViewSettings from './PerspectivesAndViewSettings';
import { useOnClickOutside } from '@ardoq/hooks';
import { closeViewSidePanel } from 'views/mainApp/viewSidePanel/viewSidePanel$';

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${r4};
  background: ${colors.white};

  user-select: none;
`;

const ViewOptions = (props: ViewPaneHeaderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, closeViewSidePanel);

  return (
    <Container ref={containerRef}>
      <ViewOptionsHeader>View style</ViewOptionsHeader>
      <ViewPicker {...props} />
      <PerspectivesAndViewSettings />
    </Container>
  );
};

const ConnectedViewOptions = connect(ViewOptions, viewPaneHeader$);
export default ConnectedViewOptions;
