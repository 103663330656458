import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GenericErrorContent = () => (
  <Container>
    <DoqWithSpeechBubble
      doqType={DoqType.ERROR}
      layout={DoqLayout.DIALOG}
      title="Sorry, an error occurred"
      message="We are sorry about the inconvenience and the error has been logged. If the error persists, please contact support."
    />
  </Container>
);

export default GenericErrorContent;
