import { BroadcastContentType } from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';

export const broadcastContentTypeIconMapping = new Map<
  BroadcastContentType,
  IconName
>([
  [BroadcastContentType.MESSAGE, IconName.COMMENT],
  [BroadcastContentType.SURVEY, IconName.SURVEYS],
  [BroadcastContentType.REPORT, IconName.DESCRIPTION],
]);
