import { ArdoqId } from '@ardoq/api-types';
import { AppModules } from 'appContainer/types';

export type NavBarProps = {
  isScenarioMode: boolean;
  isViewpointMode: boolean;
  shouldShowNavBar: boolean;
  shouldShowLogo: boolean;
};

export const stateToNavBarProps = ({
  scenarioId,
  isViewpointMode,
  selectedModule,
  hasNewJourneyFeature,
}: {
  scenarioId: ArdoqId | null;
  isViewpointMode: boolean;
  selectedModule: AppModules;
  hasNewJourneyFeature: boolean;
}): NavBarProps => ({
  isScenarioMode: Boolean(scenarioId),
  isViewpointMode,
  shouldShowNavBar:
    !hasNewJourneyFeature ||
    (selectedModule === AppModules.WORKSPACES && !isViewpointMode),
  shouldShowLogo: !hasNewJourneyFeature,
});
