import { Icon, IconName } from '@ardoq/icons';
import { TopBarButton } from '../atoms';
import { VisuallyHidden } from '@react-aria/visually-hidden';

type NavigatorTopBarCloseAllButtonProps = {
  closeAllWorkspaces: () => void;
};

export const NavigatorTopBarCloseAllButton = ({
  closeAllWorkspaces,
}: NavigatorTopBarCloseAllButtonProps) => {
  return (
    <TopBarButton
      data-test-id="close-all-workspaces"
      onClick={closeAllWorkspaces}
      data-tooltip-text={'Close all workspaces'}
    >
      <VisuallyHidden>Close all workspaces</VisuallyHidden>
      <Icon iconName={IconName.CLOSE} />
    </TopBarButton>
  );
};
