import reportReader$, { ReportReader$State } from './reportReader$';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isEmpty, isEqual, omit, pick } from 'lodash';
import { dispatchAction } from '@ardoq/rxbeach';
import { drilldownWasTriggered, filterQueryChanged } from './actions';
import { ApiResponse, reportApi } from '@ardoq/api';
import {
  APIReportFilterQuery,
  APISearchResponse,
  ArdoqId,
  ReportColumn,
  ReportFilterQuery,
} from '@ardoq/api-types';
import { ArdoqError, isArdoqError, Maybe, Result } from '@ardoq/common-helpers';
import {
  apiFilterQueryToReportFilterQuery,
  EnhancedSearchResponse,
  filterQueryToApiReportFilterQuery,
  ReportPagination,
  ReportSort,
  searchResultsOperations,
} from '@ardoq/report-reader';

export type ReportSearchResults$State = {
  pagination: ReportPagination;
  filterQuery: ReportFilterQuery;
  sort: ReportSort;
  reportId: Maybe<ArdoqId>;
  presentationId: Maybe<ArdoqId>;
  hasFetchedReport: boolean;
  slideId: Maybe<ArdoqId>;
  slideFilterQuery?: ReportFilterQuery;
  initialFiltersFromUrl: Maybe<APIReportFilterQuery>;
};

const statePropertiesThatShouldTriggerSearchResultsToExecute: Array<
  keyof ReportSearchResults$State
> = [
  'sort',
  'pagination',
  'reportId',
  'slideId',
  'presentationId',
  'filterQuery',
  'hasFetchedReport',
  'slideFilterQuery',
  'initialFiltersFromUrl',
];

export const pickStateThatShouldTriggerSearchResultsToExecute = (
  state: ReportReader$State
): ReportSearchResults$State =>
  pick(state, statePropertiesThatShouldTriggerSearchResultsToExecute);

type SearchResult = {
  apiError: Maybe<ArdoqError>;
  searchResults: Maybe<EnhancedSearchResponse>;
  filterableColumns: ReportColumn[];
  filters: Maybe<APIReportFilterQuery>;
};

export const mapSearchResults = (
  searchResults: Result<APISearchResponse>
): SearchResult => {
  if (isArdoqError(searchResults)) {
    return {
      apiError: searchResults,
      searchResults: null,
      filterableColumns: [],
      filters: null,
    };
  }
  const searchResultsWithDateRangeFields: EnhancedSearchResponse =
    searchResultsOperations.mergeDateRangeFieldsAndEnhanceScopeData(
      searchResults
    );

  const filterableColumns = searchResultsOperations.getFilterableColumns(
    searchResultsWithDateRangeFields
  );

  return {
    apiError: null,
    searchResults: searchResultsWithDateRangeFields,
    filterableColumns,
    filters: searchResults.filters ?? null,
  };
};

const toApiRequest = (
  state: ReportSearchResults$State
): FetchSearchResultParams => ({
  ...state,
  reportId: state.reportId!,
  filterQuery: !isEmpty(state.initialFiltersFromUrl)
    ? state.initialFiltersFromUrl
    : !isEmpty(state.filterQuery)
      ? filterQueryToApiReportFilterQuery(state.filterQuery)
      : undefined,
});

export const fetchSearchResults = async ({
  reportId,
  sort,
  pagination,
  filterQuery,
}: FetchSearchResultParams): ApiResponse<APISearchResponse> => {
  dispatchAction(drilldownWasTriggered(true));
  const searchResults = await reportApi.drilldown(
    reportId,
    pagination.from,
    pagination.size,
    sort.columnKey ?? undefined,
    sort.order,
    filterQuery
  );
  dispatchAction(drilldownWasTriggered(false));
  return searchResults;
};

export type FetchSearchResultParams = Omit<
  ReportSearchResults$State,
  'reportId' | 'filterQuery' | 'hasFetchedReport'
> & {
  reportId: ArdoqId;
  filterQuery: APIReportFilterQuery | undefined;
};

export type FetchSearchResults = (
  searchResults$state: FetchSearchResultParams
) => ApiResponse<APISearchResponse>;

const filtersToOmit: Array<keyof ReportSearchResults$State> = [
  'initialFiltersFromUrl',
  'filterQuery',
] as const;

export const getSearchResults$ = (fetchSearchResults: FetchSearchResults) =>
  reportReader$.pipe(
    map(pickStateThatShouldTriggerSearchResultsToExecute),
    distinctUntilChanged<ReportSearchResults$State>((prev, curr) => {
      if (
        isEmpty(prev.initialFiltersFromUrl) &&
        isEmpty(curr.initialFiltersFromUrl)
      )
        return isEqual(prev, curr);
      // this is needed to avoid extra /drilldown call when initialFiltersFromUrl is set to null after first search request
      if (
        isEqual(
          prev.initialFiltersFromUrl,
          filterQueryToApiReportFilterQuery(curr.filterQuery)
        )
      )
        return isEqual(omit(prev, filtersToOmit), omit(curr, filtersToOmit));
      return isEqual(prev, curr);
    }),
    filter(
      (
        a: ReportSearchResults$State
      ): a is ReportSearchResults$State & {
        reportId: ArdoqId;
      } => Boolean(a.reportId && a.hasFetchedReport)
    ),
    map(toApiRequest),
    switchMap(fetchSearchResults),
    map(mapSearchResults),
    tap((result: SearchResult) => {
      if (result.filters) {
        const initialFiltersWithLabels = apiFilterQueryToReportFilterQuery(
          result.filters,
          result.searchResults
        );
        dispatchAction(filterQueryChanged(initialFiltersWithLabels));
      }
    }),
    startWith({
      apiError: null,
      searchResults: null,
      filterableColumns: [],
      filters: null,
    })
  );
