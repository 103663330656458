import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  openReviewAccessRequestDialog,
  saveAccessRequestReview,
  saveAccessRequestReviewFailure,
  saveAccessRequestReviewSuccess,
  setRequestAccessData,
} from './actions';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { handleError, requestAccessApi, Resolution } from '@ardoq/api';
import { openReviewAccessRequestDialogHelper } from './ReviewAccessRequestDialog/openReviewAccessRequestDialogHelper';
import {
  ReviewAccessRequestViewModelShape,
  reviewAccessRequestDialogViewModel$,
} from './ReviewAccessRequestDialog/viewModel$';
import { logError } from '@ardoq/logging';
import { getResolution } from './routineUtils';
import { RequestResourceAccessResolutionOperationType } from '@ardoq/api-types';
import { getArdoqErrorMessage } from '@ardoq/common-helpers';

const openReviewAccessRequestDialogRoutine = routine(
  ofType(openReviewAccessRequestDialog),
  extractPayload(),
  switchMap(async ({ id }) => requestAccessApi.details(id)),
  handleError(),
  tap(requestAccessData => {
    dispatchAction(setRequestAccessData(requestAccessData));
    openReviewAccessRequestDialogHelper();
  })
);

const toResolutionRequest = ({
  permissionType,
  selectedGroup,
  requestData,
  requestId,
}: ReviewAccessRequestViewModelShape) => {
  if (
    permissionType === RequestResourceAccessResolutionOperationType.DENY_REQUEST
  ) {
    return { id: requestId! };
  }
  const resolution: Resolution = getResolution(
    requestData!,
    permissionType!,
    selectedGroup!
  );

  return {
    id: requestId!,
    resolutions: [resolution],
  };
};

const saveAccessRequestReviewRoutine = routine(
  ofType(saveAccessRequestReview),
  extractPayload(),
  withLatestFrom(reviewAccessRequestDialogViewModel$),
  map(([, accessReview]) => accessReview),
  filter(({ requestId, requestData, permissionType }) =>
    Boolean(requestId && requestData && permissionType)
  ),
  map(toResolutionRequest),
  switchMap(requestAccessApi.resolve),
  handleError(error => {
    const errorMessage = getArdoqErrorMessage(
      error,
      'Failed to save access request review'
    );
    logError(error, errorMessage);
    dispatchAction(saveAccessRequestReviewFailure({ errorMessage }));
  }),
  map(saveAccessRequestReviewSuccess),
  tap(dispatchAction)
);

export const requestAccessRoutines = collectRoutines(
  openReviewAccessRequestDialogRoutine,
  saveAccessRequestReviewRoutine
);
