import {
  PredefinedQueryOption,
  PredefinedAudience,
  BroadcastAudienceType,
} from '@ardoq/api-types';

const withQueryOption = (
  queryOptions: PredefinedQueryOption[],
  queryOptionToAdd: PredefinedQueryOption
) => [...queryOptions, queryOptionToAdd];

const withoutQueryOption = (
  queryOptions: PredefinedQueryOption[],
  queryOptionToRemove: PredefinedQueryOption
) => queryOptions.filter(({ value }) => value !== queryOptionToRemove.value);

export const optionsIncludeOption = (
  options: PredefinedQueryOption[],
  option: PredefinedQueryOption
) => {
  return options.some(({ value }) => value === option.value);
};

export const toggleQuery = (
  queryOptions: PredefinedQueryOption[],
  queryOptionToToggle: PredefinedQueryOption
) =>
  optionsIncludeOption(queryOptions, queryOptionToToggle)
    ? withoutQueryOption(queryOptions, queryOptionToToggle)
    : withQueryOption(queryOptions, queryOptionToToggle);

export const getPredefinedAudience = (
  options: PredefinedQueryOption[]
): PredefinedAudience => ({
  audienceType: BroadcastAudienceType.PREDEFINED,
  options,
});
