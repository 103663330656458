import { SetClassNames, LayoutBoxModel } from '../types';
export default class RootLayoutBox {
  height: number;
  scrollTop: number;
  scrollHeight: number;
  layoutMap: Map<string, LayoutBoxModel>;
  children: LayoutBoxModel[];
  setClassNames: ReturnType<SetClassNames> | null = null;

  constructor(
    height: number,
    scrollTop: number,
    scrollHeight = 0,
    children: LayoutBoxModel[] = [],
    layoutMap = new Map(),
    setClassNames: ReturnType<SetClassNames> | null = null
  ) {
    this.height = height;
    this.scrollTop = scrollTop;
    this.scrollHeight = scrollHeight;
    this.children = children;
    this.layoutMap = layoutMap;
    if (setClassNames) {
      this.children.forEach(layoutBox =>
        layoutBox.updateClassNames(setClassNames)
      );
    }
  }

  copy(setClassNames: ReturnType<SetClassNames> | null) {
    return new RootLayoutBox(
      this.height,
      this.scrollTop,
      this.scrollHeight,
      this.children.slice(),
      this.layoutMap,
      setClassNames
    );
  }
}
