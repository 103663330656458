import {
  DEFAULT_COMPONENT_LABEL_FORMATTING,
  DEFAULT_REFERENCE_LABEL_FORMATTING,
} from 'perspective/perspectiveEditor/labelFormattingUtils';
import {
  DEFAULT_COMP_LABEL_FORMATTING_OPTS,
  DEFAULT_REF_LABEL_FORMATTING_OPTS,
  PerspectiveGroupingRule,
  PerspectivesGroupsOptions,
} from '@ardoq/perspectives';
import { CustomLabelFormatting } from '@ardoq/perspectives-sidebar';
import {
  GroupType,
  isManualComponentSelection,
  isQueryComponentSelection,
  LoadedState,
  TraversalLoadedState,
  MetaModelComponentType,
  ReferenceDirection,
  isAnyTraversalLoadedState,
} from '@ardoq/api-types';
import {
  ConditionalFormattingInfo,
  parseConditionalFormatting,
} from '@ardoq/view-legend';
import {
  ComponentRepresentationData,
  LabelSettingInfo,
  LegacyLabelSettingInfo,
  RuleDescriptionsByEntity,
  ViewpointData,
} from '../appliedSettings/types';
import { filterInterface } from '@ardoq/filter-interface';
import { noop, omit, sum } from 'lodash';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { openViewpointBuilder } from 'viewpointBuilder/openViewpointBuilder/openViewpointBuilder';
import { ViewpointBuilderTab } from 'viewpointBuilder/viewpointBuilderNavigation/types';
import { groupingRuleInterface } from 'modelInterface/groupingRules/groupingRuleInterface';
import { isSingleTraversalState } from './isSingleTraversalState';
import { getIcon } from '@ardoq/icons';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import type { LabelFormattingInfo } from '@ardoq/data-model';
import { exhaustiveCheck } from '@ardoq/common-helpers';

type GetFormattingLabelArgs = {
  appliedFormattingLabel: string;
  customLabels: CustomLabelFormatting[];
  defaultFormattingOpts: CustomLabelFormatting[];
};

const getFormattingLabel = ({
  appliedFormattingLabel,
  customLabels,
  defaultFormattingOpts,
}: GetFormattingLabelArgs) =>
  customLabels.find(({ value }) => value === appliedFormattingLabel) ||
  defaultFormattingOpts.find(({ value }) => value === appliedFormattingLabel);

type LegacyGetAppliedLabelFormattingArgs = {
  appliedComponentFormattingLabel: string;
  appliedReferenceFormattingLabel: string;
  componentFormattingCustomLabels: CustomLabelFormatting[];
  referenceFormattingCustomLabels: CustomLabelFormatting[];
};

type GetAppliedLabelFormattingArgs = {
  appliedLabelFormatting: LabelFormattingInfo[];
  appliedShowReferenceType: boolean;
};

export const getAppliedLabelFormatting = (
  args: LegacyGetAppliedLabelFormattingArgs | GetAppliedLabelFormattingArgs
): LegacyLabelSettingInfo | LabelSettingInfo => {
  if ('appliedLabelFormatting' in args) {
    const { appliedLabelFormatting, appliedShowReferenceType } = args;
    return {
      labelFormatting: appliedLabelFormatting,
      showReferenceType: appliedShowReferenceType,
    } satisfies LabelSettingInfo;
  }
  const {
    appliedComponentFormattingLabel,
    appliedReferenceFormattingLabel,
    componentFormattingCustomLabels,
    referenceFormattingCustomLabels,
  } = args;

  const referenceFormattingLabel = getFormattingLabel({
    customLabels: referenceFormattingCustomLabels,
    appliedFormattingLabel: appliedReferenceFormattingLabel,
    defaultFormattingOpts: DEFAULT_REF_LABEL_FORMATTING_OPTS,
  });

  const componentFormattingLabel = getFormattingLabel({
    customLabels: componentFormattingCustomLabels,
    appliedFormattingLabel: appliedComponentFormattingLabel,
    defaultFormattingOpts: DEFAULT_COMP_LABEL_FORMATTING_OPTS,
  });

  const customLabelsCount = [
    appliedComponentFormattingLabel !== DEFAULT_COMPONENT_LABEL_FORMATTING,
    appliedReferenceFormattingLabel !== DEFAULT_REFERENCE_LABEL_FORMATTING,
  ].filter(Boolean).length;

  return {
    referenceFormattingLabel,
    componentFormattingLabel,
    customLabelsCount,
  } satisfies LegacyLabelSettingInfo;
};

type GetEntityName = (
  entityId: string,
  workspaceId: string,
  entityType: 'componentType' | 'referenceType' | 'fieldName' | 'tagName'
) => string | undefined;

const getEntityGetter =
  (groupOptions: PerspectivesGroupsOptions): GetEntityName =>
  (
    entityId: string,
    workspaceId: string,
    entityType: 'componentType' | 'referenceType' | 'fieldName' | 'tagName'
  ) => {
    switch (entityType) {
      case 'componentType':
        return groupOptions.componentTypesPerWorkspace[workspaceId]?.find(
          ({ id }) => id === entityId
        )?.name;
      case 'referenceType':
        return groupOptions.referenceTypesPerWorkspace[workspaceId]?.find(
          ({ id }) => id.toString() === entityId
        )?.name;
      case 'fieldName':
        return groupOptions.fieldsPerWorkspace[workspaceId]?.find(
          ({ id }) => id === entityId
        )?.name;
      case 'tagName':
        return groupOptions.tagsPerWorkspace[workspaceId]?.find(
          ({ id }) => id === entityId
        )?.name;
    }
  };

const getGroupNameString = (sublabel?: string) =>
  sublabel ? ` with group name "${sublabel}"` : '';

const entityAndWorkspaceString = (entity?: string, workspaceName?: string) => {
  if (!entity) {
    return;
  }
  return `"${entity}${workspaceName ? ` (${workspaceName})` : ''}"`;
};

const getGroupingRuleDescription = (
  groupingRule: PerspectiveGroupingRule,
  workspaceNameById: Record<string, string | undefined>,
  getEntityName: GetEntityName
) => {
  if (groupingRule.type === GroupType.WORKSPACE) {
    return 'Group by Workspace';
  }
  if (groupingRule.type === GroupType.SUBDIVISION) {
    return SUBDIVISIONS_STRINGS.SAVE_AS_VIEWPOINT.GROUP_BY_SUBDIVISION;
  }
  if (groupingRule.type === GroupType.COMPONENT) {
    return 'Group by Component types';
  }
  if (groupingRule.type === GroupType.REFERENCE) {
    const { sublabel, direction, workspaceId, targetId } = groupingRule;
    const workspaceName = workspaceNameById[workspaceId];

    const referenceType = getEntityName(targetId, workspaceId, 'referenceType');
    const referenceDirection =
      direction === ReferenceDirection.INCOMING ? 'Incoming' : 'Outgoing';

    return `Group by ${referenceDirection} reference ${
      entityAndWorkspaceString(referenceType, workspaceName) ||
      'No Reference type selected'
    }${getGroupNameString(sublabel)}`;
  }

  if (groupingRule.type === GroupType.PARENT) {
    const { targetId, workspaceId, sublabel } = groupingRule;
    const workspaceName = workspaceNameById[workspaceId];

    const componentType = getEntityName(targetId, workspaceId, 'componentType');

    return `Group by Parent ${
      entityAndWorkspaceString(componentType, workspaceName) ||
      'No Component type selected'
    }${getGroupNameString(sublabel)}`;
  }

  if (groupingRule.type === GroupType.PARENT_ALL) {
    const { sublabel } = groupingRule;

    return `Group by Parent all${getGroupNameString(sublabel)}`;
  }

  if (groupingRule.type === GroupType.FIELD) {
    const { targetId, workspaceId } = groupingRule;
    const workspaceName = workspaceNameById[workspaceId];
    const fieldName = getEntityName(targetId, workspaceId, 'fieldName');

    return `Group by Field ${
      entityAndWorkspaceString(fieldName, workspaceName) ||
      '(no Field selected)'
    }`;
  }

  if (groupingRule.type === GroupType.TAG) {
    const { workspaceId, targetId } = groupingRule;
    const workspaceName = workspaceNameById[workspaceId];
    const tagName = targetId && getEntityName(targetId, workspaceId, 'tagName');

    return `Group by Tag ${
      entityAndWorkspaceString(tagName, workspaceName) || '(no Tag selected)'
    }`;
  }

  if (groupingRule.type === GroupType.CHILD) {
    // ChildGroupingRule has been deprecated. User cannot create a new grouping rule of this type.
    // Added for TypeScript exhaustiveness check
    return 'Group by Child';
  }

  exhaustiveCheck(groupingRule);
  return '';
};

const isRulesFromMultipleWorkspaces = (
  groupOptions: PerspectivesGroupsOptions
) => {
  const workspaceIds = new Set<string>();

  for (const groupingRule of groupOptions.appliedGroupingRules) {
    if ('workspaceId' in groupingRule) {
      workspaceIds.add(groupingRule.workspaceId);
    }
    if (workspaceIds.size > 1) {
      return true;
    }
  }

  return false;
};

export const getAppliedGroupingRuleDescriptions = (
  groupOptions: PerspectivesGroupsOptions
) => {
  const shouldDisplayRuleWorkspace =
    isRulesFromMultipleWorkspaces(groupOptions);

  const workspaceNamesByWorkspaceId = shouldDisplayRuleWorkspace
    ? Object.fromEntries(
        groupOptions.workspaces.map(({ id, name }) => [id, name])
      )
    : {};

  return groupOptions.appliedGroupingRules.map(groupingRule =>
    getGroupingRuleDescription(
      groupingRule,
      workspaceNamesByWorkspaceId,
      getEntityGetter(groupOptions)
    )
  );
};

const ruleDescriptionsByEntityReducer = (
  acc: RuleDescriptionsByEntity,
  rule: ConditionalFormattingInfo
) => {
  const { filterType, color, filterDescription } = rule;

  if (
    filterType === 'components' ||
    filterType === 'references' ||
    filterType === 'tags'
  ) {
    acc[filterType] = [
      ...(acc[filterType] || []),
      {
        color,
        filterDescription,
      },
    ];
  }

  return acc;
};

export const getAppliedConditionalFormatting = () => {
  // Filter out label formatting
  const formattingFilters = filterInterface
    .getFormattingFilters()
    .filter(({ color }) => color);

  if (!formattingFilters.length) {
    return {
      appliedRulesCount: 0,
      ruleDescriptionsByEntity: {},
    };
  }

  const ruleDescriptionsByEntity = formattingFilters
    .map(parseConditionalFormatting)
    .reduce(ruleDescriptionsByEntityReducer, {});

  return {
    ruleDescriptionsByEntity,
    appliedRulesCount: sum(
      Object.values(ruleDescriptionsByEntity).map(rules => rules.length)
    ),
  };
};

const getComponentIdsFromLadedState = (entry: TraversalLoadedState) => {
  if (isQueryComponentSelection(entry.data.componentSelection)) {
    return entry.componentIdsAndReferences?.componentIds || [];
  }
  if (isManualComponentSelection(entry.data.componentSelection)) {
    return entry.data.componentSelection.startSet;
  }

  return [];
};
const getComponentTypeRepresentation = (
  entry: TraversalLoadedState,
  componentTypes: MetaModelComponentType[]
): ComponentRepresentationData | undefined => {
  if (!isAnyTraversalLoadedState(entry)) {
    return;
  }

  const componentId = getComponentIdsFromLadedState(entry)[0];
  const typeName = componentInterface.getTypeName(componentId);
  const componentType = componentTypes.find(type => type.name === typeName);

  if (!componentType) {
    return;
  }

  return {
    isImage: Boolean(componentType.style.image) && !componentType.style.icon,
    value: componentType.style.image ?? null,
    icon: getIcon(componentType.style.icon),
    style: { color: componentType.style.color ?? undefined },
  };
};

const getOpenViewpointBuilder =
  (activeTab: ViewpointBuilderTab, entry: TraversalLoadedState) => () => {
    const { paths, filters, pathMatching, pathCollapsingRules } = entry.data;
    const loadedStateHash = loadedStateOperations.stateToHash(entry);

    const initialConfiguration = {
      componentSelection: entry.data.componentSelection,
      initialPaths: paths,
      loadedStateHash,
      filters,
      pathMatching,
      pathCollapsingRules,
    };
    openViewpointBuilder({
      activeTab,
      initialConfiguration,
    });
  };

export const getContextComponentTypeSettings = (
  loadedStates: LoadedState[],
  componentTypes: MetaModelComponentType[]
) => {
  if (!isSingleTraversalState(loadedStates)) {
    return {};
  }

  const entry = loadedStates[0];

  return {
    contextComponentType: getStartType(entry),
    representationData: getComponentTypeRepresentation(entry, componentTypes),
  };
};

export const getComponentsAndReferencesTypeSettings = (
  loadedStates: LoadedState[]
) => {
  if (!isSingleTraversalState(loadedStates)) {
    return {
      componentTypesCount: 0,
      referenceTypesCount: 0,
      onEditClick: noop,
    };
  }

  const entry = loadedStates[0];

  const componentTypes = new Set<string>();
  const referenceTypes = new Set<number>();

  loadedStates.forEach(loadedState => {
    if (isAnyTraversalLoadedState(loadedState)) {
      loadedState.componentIdsAndReferences?.componentIds.forEach(id => {
        const typeId = componentInterface.getTypeId(id);
        if (typeId) {
          componentTypes.add(typeId);
        }
      });
      loadedState.componentIdsAndReferences?.references.forEach(reference => {
        const typeId = referenceInterface.getTypeId(reference._id);
        if (typeId) {
          referenceTypes.add(typeId);
        }
      });
    }
  });

  return {
    componentTypesCount: componentTypes.size,
    referenceTypesCount: referenceTypes.size,
    onEditClick: getOpenViewpointBuilder('SUBGRAPH_TAB', entry),
  };
};

export const getFilterSettings = (loadedStates: LoadedState[]) => {
  if (!isSingleTraversalState(loadedStates)) {
    return {
      appliedFiltersCount: 0,
      onEditClick: noop,
    };
  }

  const entry = loadedStates[0];
  const onEditClick = getOpenViewpointBuilder('FILTERS_TAB', entry);

  const appliedFiltersCount = sum(
    Object.values(entry.data.filters).map(({ rules }) => rules.length)
  );

  return {
    appliedFiltersCount,
    onEditClick,
  };
};

const getStartType = (entry: TraversalLoadedState) => {
  if (!isAnyTraversalLoadedState(entry)) {
    return;
  }

  const path = entry.data.paths[0];

  if (!path.length) {
    return;
  }

  const { sourceType, targetType, direction } = path[0];

  return direction === 'incoming' ? targetType : sourceType;
};

export const getViewpointData = (
  loadedStates: LoadedState[]
): ViewpointData => {
  if (!isSingleTraversalState(loadedStates)) {
    return null;
  }

  const entry = loadedStates[0];
  const startType = getStartType(entry);

  if (!startType) {
    return null;
  }

  const conditionalFormatting = filterInterface
    .getFormattingFilters()
    .map(filter => omit(filter, 'id'));

  return {
    startType,
    conditionalFormatting,
    paths: entry.data.paths,
    traversalId: entry.traversalId ? entry.traversalId : undefined,
    filters: entry.data.filters,
    groupBys: groupingRuleInterface.getActiveGroupingAttributes(),
    pathMatching: entry.data.pathMatching,
    pathCollapsingRules: entry.data.pathCollapsingRules,
  };
};
