import { s32 } from '@ardoq/design-tokens';
import Box from 'atomicComponents/Box';
import styled from 'styled-components';
import { TabularMapping } from 'integrations/common/components/tabularMapping/TabularMapping';
import {
  IntegrationId,
  TableMappingMap,
} from 'integrations/common/streams/tabularMappings/types';
import {
  TableMappingType,
  IntegrationWorkspace,
} from '@ardoq/api-types/integrations';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  applyTableMapping,
  setTableMappingType,
} from 'integrations/common/streams/tabularMappings/actions';
import { IntegrationTermsDictionary } from 'integrations/common/streams/integrationTermsDictionary/types';
import { viewModel$ } from './viewModel$';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';

const TabularMappingContainer = styled(Box)`
  margin: 0 ${s32} ${s32} ${s32};
`;

const TableConfigurationsComponent = ({
  integrationId,
  currentTableId,
  tableMapping,
  workspaces,
  integrationTermsDictionary,
  isCreationDisabled,
  disabledReferenceType,
  disabledReferenceTypePopover,
}: {
  integrationId: IntegrationId;
  currentTableId: string;
  tableMapping?: TableMappingMap;
  workspaces: {
    used: IntegrationWorkspace[];
    existing: IntegrationWorkspace[];
  };
  integrationTermsDictionary: IntegrationTermsDictionary;
  isCreationDisabled: boolean;
  disabledReferenceType: boolean;
  disabledReferenceTypePopover: string;
}) => {
  const onTableTypeSelect = (tableType: TableMappingType) => {
    dispatchAction(
      setTableMappingType({ integrationId, tableId: currentTableId, tableType })
    );
  };

  const onHomeWorkspaceSelect = (workspace: IntegrationWorkspace) => {
    dispatchAction(
      applyTableMapping({
        integrationId,
        tableId: currentTableId,
        tableMapping: {
          rootWorkspace: workspace,
        },
      })
    );
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'SELECTED_WORKSHEET_WORKSPACE',
        metadata: {
          workspaceType: 'source',
          tableIndex: tableMapping?.index || null,
        },
      })
    );
  };

  const onGoalWorkspaceSelect = (workspace: IntegrationWorkspace) => {
    dispatchAction(
      applyTableMapping({
        integrationId,
        tableId: currentTableId,
        tableMapping: {
          targetWorkspace: workspace,
        },
      })
    );
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'SELECTED_WORKSHEET_WORKSPACE',
        metadata: {
          workspaceType: 'target',
          tableIndex: tableMapping?.index || null,
        },
      })
    );
  };

  return (
    <TabularMappingContainer>
      <TabularMapping
        tableId={currentTableId}
        tableMapping={tableMapping}
        workspaces={workspaces}
        stepTitle={integrationTermsDictionary.stepTitle}
        selectWorkspace={
          isCreationDisabled
            ? integrationTermsDictionary.selectWorkspace
            : integrationTermsDictionary.selectOrCreateWorkspace
        }
        disabledReferenceTypePopoverContent={disabledReferenceTypePopover}
        onTableTypeSelect={onTableTypeSelect}
        onHomeWorkspaceSelect={onHomeWorkspaceSelect}
        onGoalWorkspaceSelect={onGoalWorkspaceSelect}
        isCreationDisabled={isCreationDisabled}
        disabledReferenceType={disabledReferenceType}
      />
    </TabularMappingContainer>
  );
};

export const TableConfigurations = connect(
  TableConfigurationsComponent,
  viewModel$
);
