import { StatusType } from '@ardoq/status-ui';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import { ViewStatusRecord } from './types';

const viewStatusBrands: ViewStatusRecord<StatusType> = {
  [MetaInfoViewStatus.BETA]: StatusType.DEFAULT,
  [MetaInfoViewStatus.SOON_TO_BE_DISCONTINUED]: StatusType.WARNING,
  [MetaInfoViewStatus.DISCONTINUED]: StatusType.ERROR,
  [MetaInfoViewStatus.NEW]: StatusType.NEW,
};
export default viewStatusBrands;
