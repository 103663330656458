import { PrimaryButton, DangerButton } from '@ardoq/button';
import { SidebarContentFooter } from './atoms';

type FooterButtonsProps = {
  isDisabled: boolean;
  showDeleteButton?: boolean;
  onSave: () => void;
  onDelete?: () => void;
};

const FooterButtons = ({
  isDisabled,
  showDeleteButton = false,
  onSave,
  onDelete,
}: FooterButtonsProps) => {
  return (
    <SidebarContentFooter>
      <PrimaryButton
        type="button"
        isDisabled={isDisabled}
        onClick={event => {
          event.preventDefault();
          onSave();
        }}
      >
        Save
      </PrimaryButton>
      {showDeleteButton && (
        <DangerButton onClick={() => onDelete?.()}>Delete</DangerButton>
      )}
    </SidebarContentFooter>
  );
};

export default FooterButtons;
