import { defaultState, reducers } from './reducers';
import { persistentReducedStream } from '@ardoq/rxbeach';

const broadcast$ = persistentReducedStream(
  'broadcast$',
  defaultState,
  reducers
);

export default broadcast$;
