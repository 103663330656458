import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
  withNamespace,
} from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs';
import { openNewSurvey, selectSurveyId } from 'surveyAdmin/navigation/actions';
import {
  resetSurveyFieldHasBeenInteractedWith,
  sectionSelected,
  setHasInvalidatedSurveyChangeApproval,
  setSurveyAttributes,
  updateSurveyAttributes,
} from './actions';
import surveys$ from 'streams/surveys/surveys$';
import {
  cloneSurveyAttributes,
  maybeResetApprovalFlowRelationshipSelector,
  maybeResetNewComponentParentId,
} from '../utils';
import { surveysNamespace } from 'streams/surveys/surveys$';
import surveyAdmin$ from 'surveyAdmin/streams/surveyAdmin$';
import surveyEditor$ from './surveyEditor$';
import { trackOpenNewSurveyEditor } from 'surveyAdmin/tracking';
import { fetchOrgUsers } from 'streams/orgUsers/actions';
import { fetchAllSucceeded } from 'streams/crud/actions';
import {
  checkIfInvalidatedSurveyChangeApproval,
  emptySurvey,
} from 'surveyAdmin/surveyUtil';
import { isPersistedSurvey, APISurveyAttributes } from '@ardoq/api-types';
import { confirmSurveyChangeCausingDeletionOfPendingAnswers } from 'surveyAdmin/SurveyModals';

const handleUpdateSurveyEditorStateForNewSurvey = routine(
  ofType(openNewSurvey),
  tap(() => {
    const newSurvey = emptySurvey();
    trackOpenNewSurveyEditor();
    dispatchAction(fetchOrgUsers());
    dispatchAction(sectionSelected(0));
    dispatchAction(setSurveyAttributes(newSurvey));
    dispatchAction(resetSurveyFieldHasBeenInteractedWith());
  })
);

const handleUpdateSurveyEditorStateForSelectedSurvey = routine(
  ofType(selectSurveyId),
  extractPayload(),
  withLatestFrom(surveys$),
  tap(([{ surveyId, keepEditorIndex }, { byId }]) => {
    const survey = byId[surveyId];
    if (survey) {
      dispatchAction(fetchOrgUsers());
      if (!keepEditorIndex) dispatchAction(sectionSelected(0));
      dispatchAction(setSurveyAttributes(cloneSurveyAttributes(survey)));
      dispatchAction(resetSurveyFieldHasBeenInteractedWith());
    }
  })
);

const handleUpdateSurveyAttributesWhenSurveysAreFetched = routine(
  withNamespace(surveysNamespace),
  ofType(fetchAllSucceeded),
  withLatestFrom(surveys$, surveyAdmin$),
  tap(([, { byId }, { surveyId }]) => {
    if (surveyId) {
      const survey = byId[surveyId];
      if (survey) {
        dispatchAction(setSurveyAttributes(cloneSurveyAttributes(survey)));
      }
    }
  })
);

const handleUpdateSurveyAttributes = routine(
  ofType(updateSurveyAttributes),
  extractPayload(),
  withLatestFrom(surveyEditor$, surveyAdmin$),
  tap(
    async ([
      newAttributes,
      { surveyAttributes, hasInvalidatedSurveyChangeApproval },
      { surveysWithPendingApprovals },
    ]) => {
      if (
        isPersistedSurvey(surveyAttributes) &&
        surveyAttributes.approvalFlow?.enabled
      ) {
        const attributeAffectsChangeApproval =
          checkIfInvalidatedSurveyChangeApproval(
            surveysWithPendingApprovals,
            surveyAttributes,
            newAttributes as Partial<APISurveyAttributes>
          );

        const canSkipChangeApprovalValidation =
          !attributeAffectsChangeApproval || hasInvalidatedSurveyChangeApproval;

        if (!canSkipChangeApprovalValidation) {
          const confirmSurveyChange =
            await confirmSurveyChangeCausingDeletionOfPendingAnswers();
          if (!confirmSurveyChange) return;
          dispatchAction(setHasInvalidatedSurveyChangeApproval(true));
        }
      }

      const updatedAttributes = { ...surveyAttributes, ...newAttributes };

      dispatchAction(
        setSurveyAttributes({
          ...updatedAttributes,
          approvalFlow: maybeResetApprovalFlowRelationshipSelector(
            surveyAttributes,
            newAttributes
          ),
          newComponentParentId: maybeResetNewComponentParentId(
            surveyAttributes,
            newAttributes
          ),
        })
      );
    }
  )
);

export default collectRoutines(
  handleUpdateSurveyEditorStateForNewSurvey,
  handleUpdateSurveyEditorStateForSelectedSurvey,
  handleUpdateSurveyAttributesWhenSurveysAreFetched,
  handleUpdateSurveyAttributes
);
