import {
  ComponentType,
  ConfigurationState,
  ConfigurationStatuses,
} from '../types';

const defaultStatuses: ConfigurationStatuses = {
  upsert: { code: 'Initial' },
  list: 'Initial',
  delete: 'Initial',
};

export const defaultState: ConfigurationState = {
  _id: null,
  name: '',
  accountId: '',
  applyFilters: false,
  statuses: defaultStatuses,
  workspaces: {
    /**
     * For a config there would be only one workspace, that is products
     * vulnerabilities workspace will always be created as new workspace
     */
    products: {
      id: null,
      name: '',
    },
    vulnerabilities: {
      id: null,
      name: '',
    },
  },
  componentTypes: {},
  vulnerabilityFields: {
    cvEId: {
      name: null,
      label: 'CVE Id',
    },
    cveName: {
      name: null,
      label: 'CVE Name',
    },
    cveSummary: {
      name: null,
      label: 'CVE Summary',
    },
    cvssScore: {
      name: null,
      label: 'CVSS Score',
    },
    cvssScoreStatus: {
      name: null,
      label: 'CVSS Score Status',
    },
    cveUrl: {
      name: null,
      label: 'CVE URL',
    },
  },
};

export const defaultComponentType: ComponentType = {
  isQueryValid: true,
  matching: {},
  fields: {
    live: { name: null, label: '' },
    support: { name: null, label: '' },
    extendedSupport: { name: null, label: '' },
    productId: { name: null, label: '' },
    url: { name: null, label: '' },
  },
};
