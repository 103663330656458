import { ViewpointGroupingRulesCommands } from './viewpointGroupingRulesCommands';
import { GroupsTab } from '@ardoq/perspectives';
import { GroupingRulesState } from './groupingRulesTypes';
import { ViewpointBuilderFooter } from 'viewpointBuilder/components/ViewpointBuilderFooter';
import { PrimaryButtonConfig } from 'viewpointBuilder/openViewpointBuilder/getPrimaryButtonConfig';
import { PageBody } from '@ardoq/page-layout';

type GroupingRulesContentProps = {
  closeViewpointBuilder: () => void;
  commands: ViewpointGroupingRulesCommands;
  state: GroupingRulesState;
  primaryButtonConfig: PrimaryButtonConfig;
};

export const GroupingRulesContent = ({
  state,
  commands,
  closeViewpointBuilder,
  primaryButtonConfig,
}: GroupingRulesContentProps) => (
  <PageBody
    footerContent={
      <ViewpointBuilderFooter
        closeViewpointBuilder={closeViewpointBuilder}
        primaryButtonConfig={primaryButtonConfig}
      />
    }
  >
    <GroupsTab {...{ ...state, ...commands }} />
  </PageBody>
);
