import { useMemo } from 'react';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { navigateToPath } from '../../navigation/actions';
import { ITPediaImportRoutes } from '../../types';
import { combineLatest, map } from 'rxjs';
import { ConfigurationState } from 'integrations/ITPedia/streams/types';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { Step2 } from './Step2/Step2';
import { Step1 } from './Step1/Step1';
import { isDataSelectionValid } from './utils';
import { configurationState$ } from 'integrations/ITPedia/streams/configurationState/configurationState$';
import { StepContainer } from '../components/StepContainer';
import { trackITPediaEvent } from 'integrations/ITPedia/streams/tracking/actions';
import {
  Connection,
  IntegrationWorkspace,
} from '@ardoq/api-types/integrations';
import { unUsedWorkspaceOptions$ } from 'integrations/ITPedia/streams/workspaces/workspaces$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';

type ComponentSelectionParams = {
  dataSelectionIsValid: boolean;
  workspaces: IntegrationWorkspace[];
  itPediaAccounts: Connection[];
  configurationState: ConfigurationState;
};

function ComponentSelectionComponent({
  dataSelectionIsValid,
  workspaces,
  itPediaAccounts,
  configurationState,
}: ComponentSelectionParams) {
  const selectedComponentTypes = Object.keys(configurationState.componentTypes);
  const onNext = () => {
    dispatchAction(
      trackITPediaEvent({
        name: 'CLICKED_BUTTON',
        metadata: {
          name: 'next',
          to: 'Mapping fields',
        },
      })
    );
    dispatchAction(navigateToPath(ITPediaImportRoutes.FIELD_MAPPING));
  };

  const componentTypes = useMemo(
    () =>
      configurationState.workspaces.products.id
        ? workspaceInterface.getComponentTypes(
            configurationState.workspaces.products.id
          )
        : [],
    [configurationState.workspaces.products.id]
  );

  return (
    <>
      <StepContainer $isVertical>
        <Step1
          selectedAccount={configurationState.accountId}
          selectedWorkspace={configurationState.workspaces.products}
          selectedComponentTypes={configurationState.componentTypes}
          applyFilters={configurationState.applyFilters}
          componentTypes={componentTypes}
          itPediaAccounts={itPediaAccounts}
          workspaces={workspaces}
        />
        {!!selectedComponentTypes?.length && (
          <Step2
            selectedComponentTypes={configurationState.componentTypes}
            isValid={!!dataSelectionIsValid}
            onNext={onNext}
          />
        )}
      </StepContainer>
    </>
  );
}

const viewModel$ = combineLatest([
  unUsedWorkspaceOptions$,
  configurationState$,
  getConnectionsStream('it-pedia'),
]).pipe(
  map(([unUsedWorkspaceOptions, configurationState, connectionState]) => {
    return {
      dataSelectionIsValid: isDataSelectionValid(configurationState),
      workspaces: unUsedWorkspaceOptions,
      itPediaAccounts: connectionState.connections,
      configurationState,
    };
  })
);

export const ComponentSelection = connect(
  ComponentSelectionComponent,
  viewModel$
);
