import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import { SurveyEditorState } from './types';
import { emptySurvey } from 'surveyAdmin/surveyUtil';
import { APISurveyAttributes } from '@ardoq/api-types';

const defaultState: SurveyEditorState = {
  validSections: [],
  selectedSectionIndex: 0,
  surveyValidation: undefined,
  surveyAttributes: emptySurvey() as APISurveyAttributes,
  fieldHasBeenInteractedWith: {
    generalInformationSectionNameField: false,
    resultDisplaySectionWorskpaceSelectField: false,
    resultDisplaySectionComponentTypeSelectField: false,
    resultDisplaySectionReferenceDirectionSelectField: false,
    resultDisplaySectionReferenceTypeSelectField: false,
    workspaceAndComponentSectionWorkspaceSelectField: false,
    workspaceAndComponentSectionComponentTypeSelectField: false,
    questions: {},
  },
  hasInvalidatedSurveyChangeApproval: false,
  shouldShowReferenceResultFilteringFieldErrors: false,
};

const surveyEditor$ = persistentReducedStream(
  'surveyEditor$',
  defaultState,
  reducers
);

export default surveyEditor$;
