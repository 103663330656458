import { AssetType, Asset } from '@ardoq/api-types';
import { trackOpenSelectedWorkspaces } from '../components/assetsBrowserDialog/tracking';
import { openWorkspaces } from '../appContainer/DashboardContainer/utils';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { logError } from '@ardoq/sentry';
import { FeaturesState } from 'components/AssetsBrowser2024/assetsBrowser2024Types';

export const isWorkspace = (
  assetId: string,
  assetsById: Record<string, Asset>
) => {
  const asset = assetsById[assetId];
  if (asset === undefined) {
    logError(Error('Asset not found'), `Asset with id ${assetId} not found`);
    return false;
  }
  return asset.meta.assetType === AssetType.WORKSPACE;
};

export const trackAndOpenWorkspaces = (selectedWorkspacesIds: string[]) => {
  openWorkspaces(selectedWorkspacesIds, {
    trackingLocation: 'assetsManager',
  });
  trackOpenSelectedWorkspaces({ numWorkspaces: selectedWorkspacesIds.length });
};

export const getAssetTypesInHomePage = (
  discoverViewpointsHidden: boolean,
  features: FeaturesState
): AssetType[] =>
  [
    AssetType.WORKSPACE,
    AssetType.PRESENTATION,
    AssetType.SURVEY,
    AssetType.TRAVERSAL,
    AssetType.METAMODEL,
    AssetType.SCENARIO,
    AssetType.REPORT,
    AssetType.DASHBOARD,
    !discoverViewpointsHidden && AssetType.VIEWPOINT,
    features.broadcastsEnabled && AssetType.BROADCAST,
    features.bookmarksEnabled && AssetType.BOOKMARK,
  ].filter(ExcludeFalsy);
