import { CSSProperties, useMemo, useState } from 'react';
import { TypeRelationshipsResult } from '@ardoq/api-types';
import { DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import styled from 'styled-components';
import { s32 } from '@ardoq/design-tokens';
import { flatten, uniqWith } from 'lodash';
import { DatasourceTable, FlyWheelTable } from '@ardoq/table';
import { ViewpointSelect } from './ViewpointSelect';
import { Pagination, PaginationController } from '@ardoq/pagination';
import { getCurrentLocale, localeIncludesLowercase } from '@ardoq/locale';
import { SearchInput } from '@ardoq/forms';
import {
  APIDiscoverViewpointAttributes,
  SortOrder,
  Workspace,
} from '@ardoq/api-types';

const defaultCellStyle: CSSProperties = {
  fontSize: '16px',
  fontWeight: 'normal',
  padding: '4px 0',
  lineHeight: '24px',
  height: '56px',
};

const StyledSearchInput = styled(SearchInput)`
  min-width: 500px;
`;

const Header = styled.div`
  margin-bottom: ${s32};
`;

const Footer = styled.div`
  display: flex;
  justify-self: end;
  min-height: 60px;
  justify-content: center;
`;

const DoqContainer = styled.div`
  display: flex;
  justify-content: center;
`;

type DataSource = {
  workspaceName: string;
  workspaceId: string;
  componentTypeName: string;
  viewpointName?: string;
  viewpoints: APIDiscoverViewpointAttributes[];
  allViewpointsUnpublished: boolean;
};

type ViewpointDefaultsTableProps = {
  workspaces: Workspace[];
  viewpoints: APIDiscoverViewpointAttributes[];
  typeRelationships: TypeRelationshipsResult;
  onChange: (
    componentTypeName: string,
    workspaceId: string,
    viewpointId?: string
  ) => void;
  isSaving: boolean;
};

const ViewpointDefaultsTable = ({
  workspaces,
  viewpoints,
  typeRelationships,
  onChange,
  isSaving,
}: ViewpointDefaultsTableProps) => {
  const [searchPhrase, setSearchPhrase] = useState<string | undefined>('');

  const locale = getCurrentLocale();

  const componentTypes = useMemo(
    () =>
      uniqWith(
        flatten(
          typeRelationships?.typeRelationships.map(t => [
            {
              workspaceName:
                workspaces?.find(w => w._id === t.sourceWorkspace)?.name ??
                'Unknown workspace',
              workspaceId: t.sourceWorkspace,
              componentTypeName: t.sourceType,
              viewpoints: [],
            },
            {
              workspaceName:
                workspaces?.find(w => w._id === t.targetWorkspace)?.name ??
                'Unknown workspace',
              workspaceId: t.targetWorkspace,
              componentTypeName: t.targetType,
              viewpoints: [],
            },
          ])
        ),
        (a, b) =>
          a.workspaceId === b.workspaceId &&
          a.componentTypeName === b.componentTypeName
      ).map(defaultForComponentTypeName => {
        const componentTypeViewpoints =
          viewpoints?.filter(
            viewpoint =>
              viewpoint.allTypes.includes(
                defaultForComponentTypeName.componentTypeName
              ) &&
              viewpoint.workspaceIds.includes(
                defaultForComponentTypeName.workspaceId
              )
          ) ?? [];
        const combinedViewpoints = [
          ...defaultForComponentTypeName.viewpoints,
          ...componentTypeViewpoints,
        ];
        const allViewpointsUnpublished =
          combinedViewpoints.length === 0 ||
          combinedViewpoints.every(v => !v.published);
        return {
          ...defaultForComponentTypeName,
          viewpointName:
            componentTypeViewpoints.find(viewpoint =>
              viewpoint.defaultForComponentTypeNames?.some(
                otherDefaultForComponentTypeName =>
                  otherDefaultForComponentTypeName.componentTypeName ===
                    defaultForComponentTypeName.componentTypeName &&
                  otherDefaultForComponentTypeName.workspaceId ===
                    defaultForComponentTypeName.workspaceId
              )
            )?.name ?? 'Default Overview',
          viewpoints: combinedViewpoints,
          allViewpointsUnpublished,
        };
      }),
    [typeRelationships, workspaces, viewpoints]
  );

  const dataSource: DataSource[] = searchPhrase
    ? componentTypes.filter(row =>
        localeIncludesLowercase(row.componentTypeName, searchPhrase, locale)
      )
    : componentTypes;

  return (
    <PaginationController
      dataSource={dataSource}
      perPage={15}
      defaultSortById="componentTypeName"
      defaultSortOrder={SortOrder.ASC}
      render={({
        currentPageDataSource,
        sortById,
        sortOrder,
        sortBy,
        onPageSelect,
        currentPageNumber,
      }) => (
        <>
          <Header>
            <StyledSearchInput
              placeholder={`Search in ${componentTypes.length} component types`}
              value={searchPhrase}
              onValueChange={setSearchPhrase}
            />
          </Header>
          {searchPhrase &&
          searchPhrase.length > 0 &&
          dataSource.length === 0 ? (
            <DoqContainer>
              <DoqWithSpeechBubble
                doqType={DoqType.MISSING_ORG}
                title="No results found"
                message={`There is no component type name matching "${searchPhrase}".`}
              />
            </DoqContainer>
          ) : (
            <>
              <DatasourceTable
                dataSource={currentPageDataSource}
                components={FlyWheelTable}
                columns={[
                  {
                    title: 'Component type name',
                    onHeaderClick: () => sortBy('componentTypeName'),
                    sortOrder: sortById === 'componentTypeName' && sortOrder,
                    cellStyle: { ...defaultCellStyle, width: '500px' },
                    dataIndex: 'componentTypeName',
                  },
                  {
                    title: 'Workspace',
                    onHeaderClick: () => sortBy('workspaceName'),
                    sortOrder: sortById === 'workspaceName' && sortOrder,
                    cellStyle: defaultCellStyle,
                    dataIndex: 'workspaceName',
                  },
                  {
                    title: 'Default viewpoint',
                    onHeaderClick: () =>
                      sortBy(
                        (dataPoint: DataSource) =>
                          `${dataPoint.allViewpointsUnpublished}-${dataPoint.viewpointName}`,
                        'allViewpointsUnpublished'
                      ),
                    sortOrder:
                      sortById === 'allViewpointsUnpublished' && sortOrder,
                    cellStyle: defaultCellStyle,
                    dataIndex: 'viewpointName',
                    valueRender: (viewpointName, row: DataSource) => {
                      return (
                        <ViewpointSelect
                          isLoading={isSaving}
                          currentViewpointName={viewpointName}
                          viewpoints={row.viewpoints}
                          onChange={id =>
                            onChange(row.componentTypeName, row.workspaceId, id)
                          }
                          allViewpointsUnpublished={
                            row.allViewpointsUnpublished
                          }
                        />
                      );
                    },
                  },
                ]}
              />
              <Footer>
                <Pagination
                  currentPageNumber={currentPageNumber}
                  perPage={15}
                  totalResults={dataSource.length}
                  onPageSelect={onPageSelect}
                />
              </Footer>
            </>
          )}
        </>
      )}
    />
  );
};

export default ViewpointDefaultsTable;
