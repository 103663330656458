import { useState } from 'react';
import styled from 'styled-components';
import { ModalLayout, ModalTemplate } from '@ardoq/modal';
import { FormWrapper, TextInput } from '@ardoq/forms';
import { ErrorNotification } from '@ardoq/status-ui';
import { Link, header4, text1 } from '@ardoq/typography';
import { Stack } from '@ardoq/layout';
import fp from 'lodash/fp';
import { Select } from '@ardoq/select';
import { ConnectionsAsyncState } from 'integrations/common/streams/connections/types';
import {
  AzureConnection,
  AzureUpsertConnectionPayload,
} from '@ardoq/api-types/integrations';
import { TextLabelWithPopoverIcon } from './TextLabelWithPopoverIcon';
import { azureDictionary } from 'integrations/common/dictionary';
import { AutoFocus } from 'integrations/common/components/AutoFocus';

const Form = styled(FormWrapper)`
  width: 100%;
`;

const FormHeader = styled.div`
  ${header4}
`;

const FormText = styled.div`
  ${text1}
`;

type UpsertConnectionParams = {
  upsertStatus: ConnectionsAsyncState;
  existingConnection?: AzureConnection;
  onClose: () => void;
  onSubmit: (connection: AzureUpsertConnectionPayload) => Promise<boolean>;
};

const defaultConnectionPayload: AzureUpsertConnectionPayload = {
  name: '',
  authMethod: 'basic' satisfies AzureConnection['authMethod'],
  provider: 'azure',
  cloud: 'azure-cloud',
  tenantId: '',
  accessKey: '',
  secretKey: '',
};

const supportedCloudOptions: Array<{
  value: AzureConnection['cloud'];
  label: string;
}> = [
  { value: 'azure-cloud', label: 'Default' },
  { value: 'azure-china-cloud', label: 'China' },
  { value: 'azure-us-government', label: 'US Government' },
];

const isInvalidConnection = (connection: AzureUpsertConnectionPayload) => {
  const validProps =
    connection.authMethod === 'role'
      ? ['name', 'cloud', 'tenantId']
      : ['name', 'cloud', 'tenantId', 'accessKey', 'secretKey'];
  const propsToValidate = fp.pick(validProps, connection);
  return fp.some(value => fp.isEmpty(fp.trim(value ?? '')), propsToValidate);
};

export const UpsertConnection = ({
  existingConnection,
  upsertStatus,
  onClose,
  onSubmit,
}: UpsertConnectionParams) => {
  const [connectionInput, setConnectionInput] =
    useState<AzureUpsertConnectionPayload>(
      existingConnection
        ? {
            ...defaultConnectionPayload,
            ...existingConnection,
            secretKey: '',
          }
        : defaultConnectionPayload
    );

  const onPrimaryButtonClick = async () => {
    const isSuccess = await onSubmit(connectionInput);

    if (isSuccess) {
      onClose();
    }
  };

  const isRolebased = existingConnection?.authMethod === 'role';

  return (
    <ModalTemplate
      modalSize={2}
      headerText={`${
        existingConnection ? 'Edit connection' : 'Create new connection'
      }`}
      secondaryButtonText="Cancel"
      isInProgress={upsertStatus.status === 'LOADING'}
      onSecondaryButtonClick={onClose}
      primaryButtonText={`${
        existingConnection ? 'Save changes' : 'Create connection'
      }`}
      onPrimaryButtonClick={onPrimaryButtonClick}
      isPrimaryButtonDisabled={isInvalidConnection(connectionInput)}
    >
      <ModalLayout>
        {upsertStatus.status === 'FAILURE' && upsertStatus.message && (
          <AutoFocus>
            <ErrorNotification>{upsertStatus.message}</ErrorNotification>
          </AutoFocus>
        )}
        <Form>
          {isRolebased ? (
            <>
              <FormText>
                This is connection is <b>role-based.</b>
              </FormText>
            </>
          ) : (
            <>
              <FormHeader>
                Enter your {azureDictionary.name} account details to establish a
                secure connection with Ardoq. The information will be kept
                private.
              </FormHeader>
              <FormText>
                Your data is securely handled:{' '}
                <Link href="https://www.ardoq.com/privacy" target="_blank">
                  Privacy Notice
                </Link>
              </FormText>
            </>
          )}
          <TextInput
            autoFocus
            value={connectionInput.name}
            onValueChange={val =>
              setConnectionInput({ ...connectionInput, name: val })
            }
            label="Connection name"
            helperText="Give the connection a descriptive name to locate it easily in the
          overview page"
          />

          <Select
            label="Cloud"
            options={supportedCloudOptions}
            value={connectionInput.cloud}
            onValueChange={cloud => {
              if (cloud) {
                setConnectionInput({ ...connectionInput, cloud });
              }
            }}
          />
          <Stack gap="small">
            <TextLabelWithPopoverIcon
              text="Tenant ID"
              popoverContent={`Find the Tenant ID in the Microsoft Entra admin center > Overview > Properties`}
            />
            <TextInput
              value={connectionInput.tenantId}
              onValueChange={val =>
                setConnectionInput({ ...connectionInput, tenantId: val })
              }
              helperText={`Tenant IDs are unique identifiers (GUIDs) associated with ${azureDictionary.name} instances.`}
            />
          </Stack>

          {!isRolebased && (
            <>
              <Stack gap="small">
                <TextLabelWithPopoverIcon
                  text="Application (Client) ID"
                  popoverContent={`The Application (Client) ID is automatically generated when an application is registrated in Microsoft Entra.

Find the Application (Client) ID in the Microsoft Entra admin center > App registrations > All applications > Application (Client) ID`}
                />
                <TextInput
                  value={connectionInput.accessKey}
                  onValueChange={val =>
                    setConnectionInput({ ...connectionInput, accessKey: val })
                  }
                  helperText="The unique value that identifies the application in Microsoft Entra."
                />
              </Stack>
              <Stack gap="small">
                <TextLabelWithPopoverIcon
                  text="Client Secret"
                  popoverContent={`The Client Secret is a string value your app can use in place of a certificate to identity itself.

          Find the Client Secret in the Microsoft Entra admin center > App registrations > All applications > Certificates & secrets > Client secrets`}
                />
                <TextInput
                  type="password"
                  value={connectionInput.secretKey}
                  onValueChange={val =>
                    setConnectionInput({ ...connectionInput, secretKey: val })
                  }
                  helperText="Sometimes also known as application password."
                />
              </Stack>
            </>
          )}
        </Form>
      </ModalLayout>
    </ModalTemplate>
  );
};
