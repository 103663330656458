import { APIOrganizationAttributes, ArdoqId } from '@ardoq/api-types';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  fetchCurrentOrganizationFailed,
  fetchCurrentOrganizationRequested,
  fetchCurrentOrganizationSucceeded,
  updateOrganizationFailed,
  updateOrganizationRequested,
  updateOrganizationSucceeded,
} from 'streams/organizations/actions';
import organizations$ from 'streams/organizations/organizations$';

const getById = (id: ArdoqId) => organizations$.state.byId[id];

const fetchCurrentOrganization = async (): Promise<void> => {
  await dispatchActionAndWaitForResponse(
    fetchCurrentOrganizationRequested(),
    fetchCurrentOrganizationSucceeded,
    fetchCurrentOrganizationFailed
  );
};

const saveOrganization = async (
  organizaton: APIOrganizationAttributes
): Promise<void> => {
  await dispatchActionAndWaitForResponse(
    updateOrganizationRequested(organizaton),
    updateOrganizationFailed,
    updateOrganizationSucceeded
  );
};

export const organizationInterface = {
  getById,
  fetchCurrentOrganization,
  saveOrganization,
};
