import { forwardRef } from 'react';
import { SidebarMenu } from '@ardoq/sidebar-menu';
import { dispatchAction } from '@ardoq/rxbeach';
import { clearWidgetSelection } from '../actions';

interface SidebarProps {
  title: string;
}

const DashboardBuilderSidebar = forwardRef<
  HTMLFormElement,
  React.PropsWithChildren<SidebarProps>
>(({ title, children }, ref) => (
  <SidebarMenu
    isResizable
    headerTitle={title}
    onCloseClick={() => dispatchAction(clearWidgetSelection())}
    isPageBodyMenu
    isRightContent
    ref={ref}
  >
    {children}
  </SidebarMenu>
));

export default DashboardBuilderSidebar;
