import transferConfigs from './streams/transferConfigs/routines';
import tabularMappingsRoutines from './streams/tabularMappings/routines';
import tabularMappingsDefault from './streams/tabularMappingsDefault/routines';
import activeIntegrationRoutines from 'integrations/common/streams/activeIntegrations/routines';
import tablePreviewsRoutines from './streams/tablePreviews/routines';
import transferStateRoutines from 'integrations/common/streams/transferState/routines';
import trackingRoutines from './tracking/routines';
import schedulesRoutines from './streams/schedules/routines';
import integrationTermsDictionaryRoutines from './streams/integrationTermsDictionary/routines';
import selectionState from './streams/selectionState/routines';
import connectionsRoutines from './streams/connections/routines';
import tabularMappingConstraintsRoutines from './streams/tabularMappingConstraints/routines';
import tabularMappingErrorsRoutines from './streams/tabularMappingErrors/routines';
import serviceNowRoutines from 'integrations/service-now/routines';
import azureRoutines from 'integrations/azure/routines';
import unifiedRoutines from 'integrations/unified/routines';
import cloudProviderRoutines from 'integrations/cloudProviders/routines';
import { collectRoutines } from '@ardoq/rxbeach';
import scheduleRoutines from 'integrations/common/streams/schedule/routines';
import navigationRoutines from 'integrations/common/navigation/routines';
import awsRoutines from 'integrations/aws/routines';
import microsoftEntraIdRoutines from 'integrations/microsoftEntraId/routines';
import signavioExporterRoutines from 'integrations/signavio-exporter/routines';
import excelRoutines from 'integrations/excel-v3/routines';
import dashboardRoutines from 'integrations/dashboard/routines';

export default collectRoutines(
  /** Generic routines */
  ...transferConfigs,
  ...tabularMappingsRoutines,
  ...activeIntegrationRoutines,
  ...tablePreviewsRoutines,
  ...transferStateRoutines,
  ...trackingRoutines,
  ...schedulesRoutines,
  ...connectionsRoutines,
  ...scheduleRoutines,
  ...integrationTermsDictionaryRoutines,
  ...selectionState,
  ...navigationRoutines,
  ...tabularMappingConstraintsRoutines,
  ...tabularMappingErrorsRoutines,
  ...tabularMappingsDefault,

  ...excelRoutines,
  ...serviceNowRoutines,
  ...azureRoutines,
  ...cloudProviderRoutines,
  ...unifiedRoutines,
  ...awsRoutines,
  ...microsoftEntraIdRoutines,
  ...signavioExporterRoutines,
  ...dashboardRoutines
);
