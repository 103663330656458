import { IconName } from '@ardoq/icons';
import { AppliedSettingsRow } from './AppliedSettingsRow';
import { FilterSetting } from './types';
import { Tag } from '@ardoq/status-ui';

type Props = {
  settings: FilterSetting;
};

export const FiltersSettings = ({ settings }: Props) => {
  const { onEditClick, appliedFiltersCount } = settings;

  const tag = (
    <Tag label={appliedFiltersCount.toString()} key="Filters count" />
  );

  return (
    <AppliedSettingsRow
      label="Filters"
      icon={IconName.FILTER_LIST}
      tags={[tag]}
      onEditClick={onEditClick}
      isDisabled={!appliedFiltersCount}
    />
  );
};
