import {
  AudiencePreview,
  InstanceComponent,
  InstanceProgress,
  SortOrder,
} from '@ardoq/api-types';
import { Column, ExpandableRowData } from '@ardoq/table';
import { DataSourceRow, getAudienceByComponentDataSource } from './utils';
import {
  ExpandRow,
  ExpandableTableProps,
} from 'broadcasts/components/expandableTable/types';
import { SortByFn, SortById } from '@ardoq/pagination';
import { AudienceDataSourceItem } from '../audienceByPerson/utils';
import ExpandableTable from 'broadcasts/components/expandableTable/ExpandableTable';

type AudienceTableByComponentProps = {
  audiencePreview: AudiencePreview;
  components?: InstanceComponent[];
  progress?: InstanceProgress;
  tableFilter?: string;
  getColumnConfig: (
    expandedRowIds: string[],
    expandRow: ExpandRow,
    sortByFn: SortByFn,
    sortById: SortById,
    sortOrder: SortOrder
  ) => Column<ExpandableRowData<DataSourceRow>>[];
  defaultSortById: string;
} & Pick<
  ExpandableTableProps<AudienceDataSourceItem>,
  'loading' | 'renderEmptyTable'
>;

const AudienceTableByComponent = ({
  audiencePreview,
  progress = {},
  getColumnConfig,
  loading,
  renderEmptyTable,
  defaultSortById,
  components,
  tableFilter = '',
}: AudienceTableByComponentProps) => {
  const dataSource = getAudienceByComponentDataSource(
    audiencePreview,
    progress,
    tableFilter,
    components
  );
  return (
    <ExpandableTable
      dataSource={dataSource ?? []}
      getColumnConfig={getColumnConfig}
      loading={loading}
      renderEmptyTable={renderEmptyTable}
      defaultSortById={defaultSortById}
      showPaginationBar={true}
      scrollableSectionHeight={'100vh'}
    />
  );
};

export default AudienceTableByComponent;
