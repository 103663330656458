import { WaitingOverlay as WaitingOverlayComponent } from 'integrations/common/components/overlays/WaitingOverlay';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { TransferState } from 'integrations/common/streams/transferState/types';
import { getDataLoadingStream } from 'integrations/unified/streams/dataLoading/dataLoading$';
import { IntegrationDataLoading } from 'integrations/unified/streams/dataLoading/types';
import { getResourcesStream } from 'integrations/unified/streams/resources/resources$';
import { IntegrationResources } from 'integrations/unified/streams/resources/types';
import { connect } from '@ardoq/rxbeach';
import { combineLatest, filter, map, of, switchMap } from 'rxjs';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import {
  isIntegrationWithProgress,
  isUnifiedIntegrationId,
} from 'integrations/unified/utils';
import { useEffect, useState } from 'react';
import { UnifiedIntegrationId } from 'integrations/unified/types';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { AsyncStatus } from 'integrations/common/types/api';

const Component = ({ message }: { message?: string }) => {
  const [takingLong, setTakingLong] = useState(false);
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (message) {
      timeout = setTimeout(() => {
        setTakingLong(true);
      }, 5000);
    } else {
      setTakingLong(false);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [message]);
  if (message) {
    return (
      <WaitingOverlayComponent>
        <div>{message}</div>
        {takingLong && <div>It might take a couple of minutes.</div>}
      </WaitingOverlayComponent>
    );
  }
  return null;
};

const getWaitingOverlayMessage = ({
  transferState,
  resources,
  dataLoading,
  connectionsState,
}: {
  transferState: TransferState;
  resources: IntegrationResources;
  dataLoading: IntegrationDataLoading;
  connectionsState: { asyncStatus: AsyncStatus };
}) => {
  if (resources.requests.getResources.status === 'LOADING') {
    return 'Fetching tables... almost done.';
  }

  if (dataLoading.status === 'LOADING') {
    return 'Fetching selected data...';
  }

  if (transferState.dryRun) {
    return 'Reviewing the import...';
  }

  if (connectionsState.asyncStatus === 'LOADING') {
    return 'Fetching connections...';
  }

  return "Nice work, you're already done...";
};

const isWaitingOverlayDisplayed = ({
  integrationId,
  transferState,
  resources,
  dataLoading,
  connectionsState,
}: {
  integrationId: UnifiedIntegrationId;
  transferState: TransferState;
  resources: IntegrationResources;
  dataLoading: IntegrationDataLoading;
  connectionsState: { asyncStatus: AsyncStatus };
}) => {
  return (
    transferState.requestStatus === 'LOADING' ||
    resources.requests.getResources.status === 'LOADING' ||
    connectionsState.asyncStatus === 'LOADING' ||
    (!isIntegrationWithProgress(integrationId) &&
      dataLoading.status === 'LOADING')
  );
};

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId => {
    return combineLatest({
      integrationId: of(integrationId),
      transferState: getTransferStateStream(integrationId),
      resources: getResourcesStream(integrationId),
      dataLoading: getDataLoadingStream(integrationId),
      connectionsState: getConnectionsStream(integrationId).pipe(
        map(val => ({ asyncStatus: val.statuses.list.status }))
      ),
    });
  }),
  map(args => {
    if (isWaitingOverlayDisplayed(args)) {
      return { message: getWaitingOverlayMessage(args) };
    }
    return {};
  })
);

export const WaitingOverlay = connect(Component, viewModel$);
