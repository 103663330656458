import { actionCreator } from '@ardoq/rxbeach';
import {
  APIBroadcastAttributes,
  ArdoqId,
  RenameRequest,
} from '@ardoq/api-types';

export const showBroadcastDetailsDialog = actionCreator<ArdoqId>(
  '[broadcastOverview] SHOW_BROADCAST_DETAILS_DIALOG'
);

export const showBroadcastPauseDialog = actionCreator<ArdoqId>(
  '[broadcastOverview] SHOW_BROADCAST_PAUSE_DIALOG'
);

export const pauseBroadcast = actionCreator<ArdoqId>(
  '[broadcastOverview] PAUSE_BROADCAST'
);

export const notifyCopyingBroadcastSucceeded =
  actionCreator<APIBroadcastAttributes>(
    '[broadcastOverview] NOTIFY_COPYING_BROADCAST_SUCCEEDED'
  );

export const renameBroadcast = actionCreator<RenameRequest>(
  '[broadcastOverview] RENAME_BROADCAST'
);

export const deleteBroadcast = actionCreator<ArdoqId>(
  '[broadcastOverview] DELETE_BROADCAST'
);

export const triggerBroadcast = actionCreator<APIBroadcastAttributes>(
  '[broadcastOverview] TRIGGER_BROADCAST'
);
