import { CellPlaceholder, HeaderCell, RowType } from '@ardoq/table';
import IntervalCell from './cellRenderers/IntervalCell';
import StatusCell from './cellRenderers/StatusCell';
import { IconName } from '@ardoq/icons';
import { DateDecorator, IconDecorator } from '@ardoq/decorators';
import { formatDateOnly } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { ColumnsProps } from 'components/AssetsBrowser/columns';
import { Folder } from 'components/EntityBrowser/types';
import { AssetRow } from 'components/AssetsBrowser/types';
import SortHeader from 'components/EntityBrowser/SortHeader';
import { capitalize } from 'lodash';
import { Text } from '@ardoq/typography';

export const getStatusColumn = (columnsProps: ColumnsProps) => ({
  title: 'Status',
  dataIndex: 'status',
  headerStyle: { width: '8%' },
  headerRender: ({ title, dataIndex = 'status' }: HeaderCell) => (
    <SortHeader
      dataIndex={dataIndex}
      title={title}
      dataClickId="status"
      sortByField={columnsProps.sortByField}
      sortOrder={columnsProps.sortOrder}
      onSortChanged={columnsProps.setSortByField}
    />
  ),
  rowStyle: { width: '8%' },
  valueRender: (_any: any, row: AssetRow | Folder<AssetRow>) => {
    if (row.rowType !== RowType.BROADCAST) return <></>;
    return <StatusCell status={row.status} errors={row.errors} />;
  },
});

export const getIntervalColumn = (columnsProps: ColumnsProps) => ({
  title: 'Interval',
  dataIndex: 'meta.intervalInDays',
  headerStyle: { width: '12%' },
  headerRender: ({ title, dataIndex = 'meta.intervalInDays' }: HeaderCell) => (
    <SortHeader
      dataIndex={dataIndex}
      title={title}
      dataClickId="meta.intervalInDays"
      sortByField={columnsProps.sortByField}
      sortOrder={columnsProps.sortOrder}
      onSortChanged={columnsProps.setSortByField}
    />
  ),
  rowStyle: { width: '12%' },
  valueRender: (_any: any, row: AssetRow | Folder<AssetRow>) => {
    if (row.rowType !== RowType.BROADCAST) return <></>;
    return <IntervalCell schedule={row.scheduling} />;
  },
});

export const getLastReminderColumn = (columnsProps: ColumnsProps) => ({
  title: 'Last Reminder',
  dataIndex: 'meta.latestSchedule',
  headerStyle: { width: '12%' },
  headerRender: ({ title, dataIndex = 'meta.latestSchedule' }: HeaderCell) => (
    <SortHeader
      dataIndex={dataIndex}
      title={title}
      dataClickId="meta.latestSchedule"
      sortByField={columnsProps.sortByField}
      sortOrder={columnsProps.sortOrder}
      onSortChanged={columnsProps.setSortByField}
    />
  ),
  rowStyle: { width: '12%' },
  valueRender: (_any: any, row: AssetRow | Folder<AssetRow>) => {
    if (row.rowType !== RowType.BROADCAST) return <></>;
    const locale = getCurrentLocale();
    if (!row.meta.latestSchedule)
      return <CellPlaceholder placeholder="Not set" />;
    return (
      <IconDecorator iconName={IconName.ALARM_SENT}>
        {formatDateOnly(row.meta.latestSchedule, locale)}
      </IconDecorator>
    );
  },
});

export const getLastSentColumn = (columnsProps: ColumnsProps) => ({
  title: 'Last Sent',
  headerStyle: { width: '14%' },
  dataIndex: 'previousRuntime',
  headerRender: ({ title, dataIndex = 'previousRuntime' }: HeaderCell) => (
    <SortHeader
      dataIndex={dataIndex}
      title={title}
      dataClickId="previousRuntime"
      sortByField={columnsProps.sortByField}
      sortOrder={columnsProps.sortOrder}
      onSortChanged={columnsProps.setSortByField}
    />
  ),
  rowStyle: { width: '14%' },
  valueRender: (_any: any, row: AssetRow | Folder<AssetRow>) => {
    if (row.rowType !== RowType.BROADCAST) return <></>;
    if (!row.previousRuntime) return <CellPlaceholder placeholder="Not set" />;
    return <DateDecorator date={row.previousRuntime} />;
  },
});

export const getContentTypeColumn = (columnsProps: ColumnsProps) => ({
  title: 'Type',
  dataIndex: 'content.contentType',
  headerStyle: { width: '8%' },
  headerRender: ({ title, dataIndex = 'content.contentType' }: HeaderCell) => (
    <SortHeader
      dataIndex={dataIndex}
      title={title}
      dataClickId="contentType"
      sortByField={columnsProps.sortByField}
      sortOrder={columnsProps.sortOrder}
      onSortChanged={columnsProps.setSortByField}
    />
  ),
  rowStyle: { width: '8%' },
  valueRender: (_any: any, row: AssetRow | Folder<AssetRow>) => {
    if (row.rowType !== RowType.BROADCAST) return <></>;
    return <Text>{capitalize(row.content.contentType)}</Text>;
  },
});
