import { Tag } from '@ardoq/status-ui';
import { AppliedSettingsRow } from './AppliedSettingsRow';
import { IconName } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { FlexBox } from '@ardoq/layout';
import { LegacyLabelSetting } from './types';
import { PopoverListItem } from './PopoverListItem';

type Props = {
  settings: LegacyLabelSetting;
};

export const LabelSettings = ({ settings }: Props) => {
  const {
    referenceFormattingLabel,
    componentFormattingLabel,
    customLabelsCount,
    onEditClick,
  } = settings;

  const popoverContent = customLabelsCount
    ? () => (
        <FlexBox flexDirection="column" paddingLeft="small">
          <PopoverListItem
            text={`Component label: ${componentFormattingLabel?.label}`}
          />
          <PopoverListItem
            text={`Reference label: ${referenceFormattingLabel?.label}`}
          />
        </FlexBox>
      )
    : null;

  const tag = [
    <WithPopover content={popoverContent} key="LabelFormattingTag">
      <Tag label={customLabelsCount.toString()} />
    </WithPopover>,
  ];

  return (
    <AppliedSettingsRow
      label="Labels"
      tags={tag}
      icon={IconName.TAG}
      onEditClick={onEditClick}
      isDisabled={!customLabelsCount}
    />
  );
};
