import { SelectOptionWithContextData } from './types';
import { Maybe } from '@ardoq/common-helpers';
import { UseCaseStatus, UseCaseSummary } from '@ardoq/api-types';

const toSelectOption = (useCase: UseCaseSummary) => ({
  value: useCase._id,
  label: useCase.name,
  description: useCase.description ?? undefined,
});

const isALMUseCase = (useCase: UseCaseSummary) =>
  useCase.name.includes('ALM - Application Lifecycle Management');

const getALMUseCaseIfExists = (
  useCases: UseCaseSummary[]
): Maybe<UseCaseSummary> =>
  useCases.find(useCaseOperations.isALMUseCase) ?? null;

const getDefaultUseCase = (useCases: UseCaseSummary[]): Maybe<UseCaseSummary> =>
  (getALMUseCaseIfExists(useCases) || useCases[0]) ?? null;

const getSelectedUseCaseOrDefault = (
  useCases: UseCaseSummary[],
  selectedUseCaseId: Maybe<string>
): UseCaseSummary | null =>
  (!selectedUseCaseId
    ? // If no use case is selected, we try to select the ALM use case as a default or any user-created use case if ALM is missing
      getDefaultUseCase(useCases)
    : useCases.find(useCase => useCase._id === selectedUseCaseId)) ?? null;

const useCasesToSelectOptionsWithUpdateInfo = (
  useCases: UseCaseSummary[]
): SelectOptionWithContextData[] =>
  useCases.map(uc => ({
    ...toSelectOption(uc),
    updateAvailable: uc.updateAvailable,
    status: uc.status,
  }));

const hasUpdateAvailable = (useCase: UseCaseSummary): boolean =>
  useCase.updateAvailable;

const isAddedUseCase = (useCase: Maybe<UseCaseSummary> | undefined): boolean =>
  useCase?.status === UseCaseStatus.IN_PROGRESS;

export const useCaseOperations = {
  toSelectOption,
  isALMUseCase,
  getALMUseCaseIfExists,
  getSelectedUseCaseOrDefault,
  useCasesToSelectOptionsWithUpdateInfo,
  hasUpdateAvailable,
  isAddedUseCase,
};
