import { FC } from 'react';
import { confirm } from '@ardoq/modal';
import { dispatchAction } from '@ardoq/rxbeach';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { pluralize } from '@ardoq/common-helpers';

type WorkspaceNamesProps = {
  readonly workspaceNames: string[];
};
type ConfirmOpenManagedWorkspaceProps = WorkspaceNamesProps;

const getTitle = (workspaceNames: string[]) =>
  `Load Externally Managed ${pluralize('Workspace', workspaceNames.length)}`;

const AboutToLoad: FC<WorkspaceNamesProps> = ({ workspaceNames }) => {
  return (
    <div>{`You are about to load ${
      workspaceNames.length === 1
        ? 'an externally managed workspace:'
        : 'externally managed workspaces:'
    }`}</div>
  );
};

const WorkspaceNames: FC<WorkspaceNamesProps> = ({ workspaceNames }) => (
  <>
    {workspaceNames.map(workspaceName => (
      <div key={workspaceName}>
        <b>{workspaceName}</b>
      </div>
    ))}
  </>
);

const DataWarning: FC<WorkspaceNamesProps> = ({ workspaceNames }) => (
  <div style={{ paddingTop: '16px' }}>
    {workspaceNames.length === 1
      ? 'The data in this workspace is managed externally and editing is restricted.'
      : 'The data in these workspaces is managed externally and editing is restricted.'}
  </div>
);

const LearnMoreLink = () => {
  return (
    <a
      style={{ display: 'block' }}
      href=""
      onClick={() => {
        dispatchAction(
          requestShowAppModule({
            selectedModule: AppModules.INTEGRATIONS,
          })
        );
      }}
    >
      Learn more.
    </a>
  );
};

export const confirmOpenManagedWorkspaces = ({
  workspaceNames,
}: ConfirmOpenManagedWorkspaceProps) => {
  return confirm({
    title: getTitle(workspaceNames),
    text: (
      <>
        <AboutToLoad workspaceNames={workspaceNames} />
        <WorkspaceNames workspaceNames={workspaceNames} />
        <DataWarning workspaceNames={workspaceNames} />
        <LearnMoreLink />
      </>
    ),
    confirmButtonTitle: 'Continue',
  });
};
