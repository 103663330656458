import { ComponentSearchViewState } from 'viewpointBuilder/selectContextTab/types';
import {
  ComponentSelection,
  isManualComponentSelection,
  isQueryComponentSelection,
  StartContextSelectionType,
  Traversal,
} from '@ardoq/api-types';
import { APILoadTraversalParams } from '@ardoq/api';

export const getComponentSelection = (
  state: ComponentSearchViewState
): ComponentSelection => {
  if (state.advancedSearchState !== null && state.startQuery !== null) {
    return {
      startQuery: state.startQuery,
      startContextSelectionType:
        StartContextSelectionType.ADVANCED_SEARCH_SELECT_ALL,
    };
  }
  if (state.startQuery) {
    return {
      startQuery: state.startQuery,
      startContextSelectionType:
        StartContextSelectionType.BASIC_SEARCH_SELECT_ALL,
    };
  }
  return {
    startSet: state.selectedComponentIds,
    startContextSelectionType: StartContextSelectionType.MANUAL_SELECTION,
  };
};

export const normalizeApiTraversalParams = (
  apiLoadTraversal: APILoadTraversalParams
): Traversal => {
  const { paths, filters, pathMatching, pathCollapsingRules } =
    apiLoadTraversal;

  if (apiLoadTraversal.startQuery) {
    return {
      paths,
      filters,
      pathMatching,
      pathCollapsingRules,
      componentSelection: {
        startQuery: apiLoadTraversal.startQuery,
        startContextSelectionType:
          StartContextSelectionType.BASIC_SEARCH_SELECT_ALL,
      },
    };
  }

  return {
    paths,
    filters,
    pathMatching,
    pathCollapsingRules,
    componentSelection: {
      startSet: apiLoadTraversal.startSet,
      startContextSelectionType: StartContextSelectionType.MANUAL_SELECTION,
    },
  };
};

/**
 * Ideally we don't use this function, but pass ComponentSelection object around.
 */
export const getStartSetAndStartQueryFromComponentSelection = (
  componentSelection: ComponentSelection
) => {
  return {
    startQuery: isQueryComponentSelection(componentSelection)
      ? componentSelection.startQuery
      : undefined,
    startSet: isManualComponentSelection(componentSelection)
      ? componentSelection.startSet
      : [],
  };
};
