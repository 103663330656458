import { PermissionGroupsProps } from '../types';
import { CreateNewButton, Island } from '@ardoq/page-layout';
import { SearchInput } from '@ardoq/forms';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { getPermissionGroupColumns } from './groupsTableColumns';
import { permissionGroupOperations } from '../permissionGroupsOperations';
import { useMemo, useState } from 'react';
import { EmptySearchGroupsList } from './components/EmptySearchGroupsList';
import { NoGroups } from './components/NoGroups';
import { PaginationBar, PaginationController } from '@ardoq/pagination';
import { Features, hasFeature } from '@ardoq/features';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { Icon, IconName } from '@ardoq/icons';

export const GroupsOverView = (props: PermissionGroupsProps) => {
  const { permissionGroups, commands, searchPhrase } = props;
  const [perPage, setPerPage] = useState(10);

  const filteredGroups = useMemo(
    () =>
      permissionGroupOperations.filterGroupsByQuery(
        permissionGroups,
        props.searchPhrase
      ),
    [permissionGroups, props.searchPhrase]
  );

  if (permissionGroups.length === 0) {
    return <NoGroups {...props} />;
  }

  // HACK - Rafaa - Temporary until we can Merge the new Navigation pattern.
  const header = hasFeature(Features.PERMISSION_ZONES_INTERNAL)
    ? {}
    : {
        title: 'Groups',
        knowledgeBaseLink: KnowledgeBaseLink.PERMISSIONS_GROUPS,
        subtitle:
          'Create and manage permission groups within your organisation.',
        rightContent: (
          <CreateNewButton
            onClick={() => commands.showCreateGroupModal(permissionGroups)}
          >
            Create new
            <Icon iconName={IconName.ADD_CIRCLE} />
          </CreateNewButton>
        ),
      };
  return (
    <Island fullHeight {...header}>
      <SearchInput
        value={searchPhrase}
        onValueChange={commands.setSearchPhrase}
        placeholder="Search groups"
      />
      <PaginationController
        dataSource={filteredGroups}
        perPage={perPage}
        render={({ currentPageDataSource, ...rest }) => {
          return (
            <>
              <DatasourceTable
                style={{ height: '100%' }}
                components={newTableTheme}
                dataSource={currentPageDataSource}
                rowStyle={() => ({
                  cursor: 'pointer',
                })}
                columns={getPermissionGroupColumns(
                  props,
                  permissionGroupOperations.someGroupsHasSSOAttributeMapping(
                    filteredGroups
                  )
                )}
                onRowClick={group => commands.goToGroup(group._id)}
                renderEmptyTable={() => <EmptySearchGroupsList />}
              ></DatasourceTable>
              <PaginationBar
                {...rest}
                onChange={({
                  currentPageNumber: newPageNumber,
                  perPage: newPerPage,
                }) => {
                  setPerPage(newPerPage);
                  rest.onPageSelect(newPageNumber);
                }}
              />
            </>
          );
        }}
      />
    </Island>
  );
};
