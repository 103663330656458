import { APIServiceAccount, APIToken } from '@ardoq/api-types';
import { currentDate, formatDateTime } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { ManageServiceAccountsCommands } from './commands';
import { IconDecorator } from '@ardoq/decorators';
import { IconName } from '@ardoq/icons';
import { CellPlaceholder, CellWithDropdown } from '@ardoq/table';
import { tokenTabViewModelOperations } from 'admin/user/TokenView/tokenTabViewModelOperations';
import { TokenStatus } from '../user/TokenView/TokenStatus';
import { DropdownItem } from '@ardoq/dropdown-menu';
import { WithPopover } from '@ardoq/popovers';
import { Box } from '@ardoq/layout';
import { truncate } from 'lodash';
import { serviceAccountsOperations } from './serviceAccountsOperations';

const NAME_TRUNCATE_LENGTH = 20;

export const getServiceAccountsTableDataSource = (
  serviceAccounts: APIServiceAccount[],
  commands: ManageServiceAccountsCommands
) => {
  return serviceAccounts.map(account => {
    return {
      accountName: account.user.name,
      tokenDescription: account.token?.description,
      createdBy: account.user.createdByName,
      lastUsed: account.token?.['last-used']
        ? formatDateTime(account.token['last-used'], getCurrentLocale())
        : 'Never used',
      tokenStatus: {
        isActive: account.token
          ? !tokenTabViewModelOperations.isExpired(account.token, currentDate())
          : false,
        token: account.token,
      },
      dropdownOptions: [
        {
          label: 'Regenerate token',
          isDisabled: !account.token,
          onClick: () => {
            commands.confirmRegenerateToken(account);
          },
        },
        {
          label: 'Delete',
          onClick: () => {
            commands.confirmDeleteAccount(account.user);
          },
        },
      ],
    };
  });
};

export const getServiceAccountsTableColumns = () => {
  return [
    {
      title: 'Account name',
      dataIndex: 'accountName',
      cellStyle: { width: '250px' },
      valueRender: (accountName: string) => {
        const isTruncated = accountName.length > NAME_TRUNCATE_LENGTH;
        return (
          <WithPopover content={isTruncated && accountName}>
            <IconDecorator iconName={IconName.ROLE}>
              {truncate(accountName, { length: NAME_TRUNCATE_LENGTH })}
            </IconDecorator>
          </WithPopover>
        );
      },
    },
    {
      title: 'Token description',
      dataIndex: 'tokenDescription',
      cellStyle: { width: '250px' },
      valueRender: (tokenDescription: string) => {
        const isTruncated = tokenDescription.length > NAME_TRUNCATE_LENGTH;
        return (
          <>
            {tokenDescription ? (
              <WithPopover content={isTruncated && tokenDescription}>
                <IconDecorator iconName={IconName.KEY}>
                  {truncate(tokenDescription, { length: NAME_TRUNCATE_LENGTH })}
                </IconDecorator>
              </WithPopover>
            ) : (
              <CellPlaceholder placeholder="No Token"></CellPlaceholder>
            )}
          </>
        );
      },
    },
    { title: 'Created by', dataIndex: 'createdBy' },
    { title: 'Last used', dataIndex: 'lastUsed' },
    {
      title: 'Token status',
      dataIndex: 'tokenStatus',
      valueRender: ({
        isActive,
        token,
      }: {
        isActive: boolean;
        token: APIToken;
      }) =>
        token ? (
          <WithPopover
            content={serviceAccountsOperations.getTokenStatusHintText(
              token,
              currentDate()
            )}
          >
            <Box>
              <TokenStatus isActive={isActive} />
            </Box>
          </WithPopover>
        ) : (
          <CellPlaceholder placeholder="No Token"></CellPlaceholder>
        ),
    },
    {
      dataIndex: 'dropdownOptions',
      valueRender: (dropdownOptions: Partial<DropdownItem>[]) => (
        <CellWithDropdown options={dropdownOptions} />
      ),
    },
  ];
};
