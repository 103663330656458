import { actionCreator } from '@ardoq/rxbeach';
import { DeletedReference } from './types';

interface RestoreReference {
  readonly reference: DeletedReference;
}
export const restoreReference = actionCreator<RestoreReference>(
  '[reference] RESTORE_REFERENCE'
);

interface RestoreReferenceSuccess {
  readonly reference: DeletedReference;
}
export const restoreReferenceSuccess = actionCreator<RestoreReferenceSuccess>(
  '[reference] RESTORE_REFERENCE_SUCCESS'
);

interface RestoreReferenceError {
  readonly reference: DeletedReference;
}
export const restoreReferenceError = actionCreator<RestoreReferenceError>(
  '[reference] RESTORE_REFERENCE_ERROR'
);
