import CurrentUser from 'models/currentUser';
import { PersonalSetting } from '@ardoq/api-types';
import { openConsentManagement } from 'consentManagement/utils';

export const splashScreenTrigger = () =>
  CurrentUser.whenLoaded(() => {
    if (CurrentUser.hasAcceptedTerms() && !CurrentUser.isPublicUser()) {
      const hasSeenBeforeSetting = PersonalSetting.SHOWN_EMAIL_PREFERENCES;
      const showModal = !CurrentUser.getPersonalSetting(hasSeenBeforeSetting);
      if (!showModal) return;
      openConsentManagement();
      CurrentUser.setPersonalSetting(hasSeenBeforeSetting, true);
    }
  });
