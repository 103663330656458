import { ArdoqId } from '@ardoq/api-types';
import { BindWorkspacesState, ViewModelState } from './types';

const setWorkspaceSelected = (
  state: ViewModelState,
  workspaceId: ArdoqId
): ViewModelState => ({
  ...state,
  selectedWorkspaces: [...state.selectedWorkspaces, workspaceId],
});

const setWorkspaceDeselected = (
  state: ViewModelState,
  workspaceId: ArdoqId
): ViewModelState => ({
  ...state,
  selectedWorkspaces: state.selectedWorkspaces.filter(id => id !== workspaceId),
});

const handleSearchValueChanged = (
  state: ViewModelState,
  searchValue: string
): ViewModelState => ({
  ...state,
  searchValue,
});

const resetSelectedWorkspaces = (state: ViewModelState): ViewModelState => ({
  ...state,
  selectedWorkspaces: [],
});

const hasDataConfigured = (state: BindWorkspacesState) => {
  return (
    state.selectedWorkspaces.length > 0 ||
    state.workspacesBoundToSubdivisionIds.length > 0
  );
};

const setSelectedWorkspaces = (
  state: ViewModelState,
  {
    workspaceIds,
    workspacesBoundToSubdivisionIds,
  }: { workspaceIds: ArdoqId[]; workspacesBoundToSubdivisionIds: ArdoqId[] }
): ViewModelState => ({
  ...state,
  selectedWorkspaces: workspaceIds.filter(
    wsId => !workspacesBoundToSubdivisionIds.includes(wsId)
  ),
});

export const bindWorkspacesOperations = {
  setWorkspaceSelected,
  setWorkspaceDeselected,
  handleSearchValueChanged,
  resetSelectedWorkspaces,
  hasDataConfigured,
  setSelectedWorkspaces,
};
