import { CenteredViewText } from './atoms';
import { FlexBox } from '@ardoq/layout';

const RestoreViewLoading = () => (
  <FlexBox justify="center" flex={2}>
    <CenteredViewText>Loading...</CenteredViewText>
  </FlexBox>
);

export default RestoreViewLoading;
