import { APIFieldAttributes } from '@ardoq/api-types';
import {
  APIErrorWithTraceId,
  CalculatedFieldEventStatus,
  CalculatedFieldEventMap,
  CalculatedFieldEventWithErrorMap,
} from './types';

const isCalculatedFieldError = (
  possibleError: unknown
): possibleError is APIErrorWithTraceId =>
  possibleError !== null &&
  typeof possibleError === 'object' &&
  'traceId' in possibleError &&
  'message' in possibleError;

export const getCalculatedFieldErrorEvents = (
  events: CalculatedFieldEventMap
) =>
  Object.fromEntries(
    Object.entries(events).filter(([_, event]) =>
      isCalculatedFieldError(event.status)
    )
  ) as CalculatedFieldEventWithErrorMap;

const getCalculatedFieldCalculatingEventsFromUser = (
  events: CalculatedFieldEventMap,
  userId: string
) =>
  Object.fromEntries(
    Object.entries(events).filter(
      ([_, { status, userId: eventUserId }]) =>
        status === CalculatedFieldEventStatus.CALCULATING &&
        userId === eventUserId
    )
  );

export const getCalculatedFieldDoneEventsFromUser = (
  events: CalculatedFieldEventMap,
  userId: string
) =>
  Object.fromEntries(
    Object.entries(events).filter(
      ([_, { status, userId: eventUserId }]) =>
        status === CalculatedFieldEventStatus.DONE && userId === eventUserId
    )
  );

/**
 * If there are other fields being calculated, wait until the last one has finished,
 * and display a single toast.
 */
export const getCalculatedFieldDoneEventToastMessage = (
  userId: string,
  fieldId: string,
  calculatedFieldEvents: CalculatedFieldEventMap,
  calculatedFields: APIFieldAttributes[]
) => {
  const calculatingEvents = getCalculatedFieldCalculatingEventsFromUser(
    calculatedFieldEvents,
    userId
  );
  const doneEvents = getCalculatedFieldDoneEventsFromUser(
    calculatedFieldEvents,
    userId
  );

  if (Object.keys(calculatingEvents).length > 0) {
    return;
  } else if (Object.keys(doneEvents).length > 1) {
    return `${Object.keys(doneEvents).length} fields recalculated.`;
  }
  return `${
    calculatedFields.find(field => field._id === fieldId)?.label
  } recalculated.`;
};
