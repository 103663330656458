import {
  BooleanOperator,
  Operator,
  QueryBuilderQuery,
  SearchContextRule,
} from '@ardoq/api-types';

export function createQueryBuilderQuery(
  workspaceId: string | null,
  componentsQuery?: QueryBuilderQuery | undefined
): QueryBuilderQuery {
  if (!componentsQuery) {
    return {
      condition: BooleanOperator.AND,
      rules: [
        {
          field: 'type',
          id: 'type',
          input: 'select',
          operator: Operator.EQUAL,
          type: 'string',
          value: 'component',
        } as SearchContextRule,
        {
          condition: BooleanOperator.AND,
          rules: [
            {
              field: 'rootWorkspace',
              id: 'rootWorkspace',
              input: 'text',
              operator: Operator.EQUAL,
              type: 'string',
              value: workspaceId,
            },
          ],
        },
      ],
    };
  }
  return componentsQuery;
}
