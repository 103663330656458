import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import { s32 } from '@ardoq/design-tokens';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { Checkbox } from '@ardoq/forms';
import { PopoverContentType } from '@ardoq/popovers';
import { FlexBox } from '@ardoq/layout';
import { Maybe } from '@ardoq/common-helpers';
import { SelectConfigs } from 'integrations/common/components/selectors/SelectConfigs';
import TableSelector from '../../containers/tableSelector/TableSelector';
import FieldSelector from '../../containers/fieldSelector/FieldSelector';
import { viewModel$ } from './viewModel$';
import useResizeHeight from 'integrations/common/hooks/useResizeHeight';
import { ErrorNotification } from '@ardoq/status-ui';
import { Island } from '@ardoq/page-layout';
import { Stack } from '@ardoq/layout';
import { AutoFocus } from 'integrations/common/components/AutoFocus';

type SelectDataProps = {
  transferConfigs: SavedTransferConfig[];
  selectedTransferConfigId: Maybe<string>;
  onTransferConfigSelect: (configId: SavedTransferConfig['_id'] | null) => void;
  onClearConfiguration: () => void;
  onChangeImportProfilesPhotos: (importProfilesPhotos: boolean) => void;
  importProfilesPhotos: boolean;
  selectConfigPopoverContent: PopoverContentType;
  selectConfigHelperText: string;
  errorMessage: Maybe<string>;
};

const SelectDataComponent = ({
  transferConfigs,
  selectedTransferConfigId,
  onTransferConfigSelect,
  onClearConfiguration,
  selectConfigHelperText,
  selectConfigPopoverContent,
  onChangeImportProfilesPhotos,
  importProfilesPhotos,
  errorMessage,
}: SelectDataProps) => {
  const { height, ref } = useResizeHeight();

  return (
    <Container>
      <SelectConfigs
        transferConfigs={transferConfigs}
        selectedConfigId={selectedTransferConfigId}
        onSelect={onTransferConfigSelect}
        onClearConfiguration={onClearConfiguration}
        selectConfigHelperText={selectConfigHelperText}
        popoverContent={selectConfigPopoverContent}
      />
      <ContentWrapper>
        <Island footerJustify="end" maxWidth="100%">
          {errorMessage && (
            <AutoFocus>
              <ErrorNotification>
                <span>{errorMessage}</span>
              </ErrorNotification>
            </AutoFocus>
          )}
          <Checkbox
            isChecked={importProfilesPhotos}
            onChange={onChangeImportProfilesPhotos}
            hintMessage="Photos will be securely stored as component attachments"
          >
            Import Profile Photos
          </Checkbox>
          <FlexBox gap="medium">
            <TableSelector selectionRef={ref} />
            <FieldSelector maxHeight={height} />
          </FlexBox>
        </Island>
      </ContentWrapper>
    </Container>
  );
};

const ContentWrapper = styled.div`
  padding: ${s32};
  overflow: auto;
`;

const Container = styled(Stack)`
  overflow: auto;
  padding-bottom: ${s32};
`;

export const SelectData = connect(SelectDataComponent, viewModel$);
