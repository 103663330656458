import { getInvitationOptions } from 'utils/permissionUtil';
import { APIOrganizationUser, OrgAccessLevel } from '@ardoq/api-types';
import {
  FormWrapper,
  GroupFieldLayout,
  RadioGroup,
  RadioGroupItem,
} from '@ardoq/forms';
import { RadioItemLabel } from './atoms';

type UserRoleSelectorProps = {
  currRole: OrgAccessLevel;
  onValueChange: (newRole: OrgAccessLevel) => void;
};

export const UserRoleSelector = ({
  users,
  currRole,
  onValueChange,
  label = 'User role',
}: UserRoleSelectorProps & {
  isPadded?: boolean;
  users: APIOrganizationUser[];
  label?: string;
}) => {
  const options = getInvitationOptions(currRole, users);
  return (
    <FormWrapper>
      <RadioGroup
        label={label ? label : undefined}
        value={currRole}
        onValueChange={value => onValueChange(value as OrgAccessLevel)}
        groupFieldLayout={GroupFieldLayout.VERTICAL}
      >
        {options.map(({ label, count, value }) => (
          <RadioGroupItem key={label} value={value}>
            <RadioItemLabel count={count} label={label} />
          </RadioGroupItem>
        ))}
      </RadioGroup>
    </FormWrapper>
  );
};
