import { BrandButton } from '@ardoq/button';
import { AccessControlPageProps } from '../types';
import { organizationOperations } from '@ardoq/core';
import { TrackOrgMembersViewUserEvents } from 'admin/orgMembers/types';
import { modal } from '@ardoq/modal';
import UserInvitations from 'sharing/UserInvitations';
import { MailIcon } from '@ardoq/icons';
import { trackOrgMembersView } from 'admin/orgMembers/utils';

export const InviteUserActionButton = (props: AccessControlPageProps) => {
  return props.resources.organization &&
    organizationOperations.hasInternalUserManagement(
      props.resources.organization
    ) ? (
    <BrandButton
      onClick={() => {
        trackOrgMembersView(TrackOrgMembersViewUserEvents.CLICKED_INVITE_USER);
        modal(closeModal => (
          <UserInvitations closeDialog={() => closeModal(true)} />
        ));
      }}
      data-intercom-target="invite-user"
      dataTestId="invite-user-button"
    >
      Invite users
      <MailIcon />
    </BrandButton>
  ) : null;
};
