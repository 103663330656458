import Context from 'context';
import { Dict } from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  QuickSearchOptions,
  SearchBy,
  SearchResultDoc,
} from '@ardoq/api-types';
import { getIn } from 'utils/collectionUtil';
import { IconName } from '@ardoq/icons';

export const getCroppedText = (
  text = '',
  queryString = '',
  maxLength = 150
) => {
  if (text && text.length > maxLength) {
    const lowercaseText = text.toLowerCase();
    const lowercaseQuery = queryString.toLowerCase();
    const firstHitIndex = lowercaseText.indexOf(lowercaseQuery);
    const startAt = Math.max(0, firstHitIndex - maxLength / 2);
    const endAt = Math.min(text.length, startAt + maxLength);

    return [
      startAt > 0 ? '...' : '',
      text.substring(startAt, endAt),
      endAt < text.length ? '...' : '',
    ].join('');
  }
  return text;
};

export const getPath = (type: string, doc: SearchResultDoc) => {
  if (type === 'workspace') {
    return [doc.folderName].filter(ExcludeFalsy);
  }
  const parentName =
    getIn(doc, ['ardoq', 'parentComponentName']) || doc.sourceParentName;
  const completePath = doc.completePath || doc.completeSourcePath;
  return [
    getIn(doc, ['ardoq', 'rootWorkspaceName']),
    parentName && !completePath ? '...' : undefined,
    parentName,
  ].filter(ExcludeFalsy);
};

export const updateEntries = <T = any>(obj: Dict<T>, fn: (value: T) => T) =>
  Object.entries(obj).reduce(
    (acc, [key, item]) => ({ ...acc, [key]: item && fn(item) }),
    {} as Dict<T>
  );

export const getIconName = (type: string): IconName | null => {
  if (type === 'workspace') return IconName.WORKSPACE;
  if (type === 'component') return IconName.COMPONENT;
  if (type === 'reference') return IconName.REFERENCE;
  return null;
};

const typeFilterToApiType = new Map<string, string>([
  ['isComponent', 'component'],
  ['isReference', 'reference'],
  ['isWorkspace', 'workspace'],
]);

export const prepareQuickSearchSearchParams = (options: QuickSearchOptions) => {
  const {
    searchBy,
    useOpenWorkspaces,
    customFieldName: fieldName,
    ...typesObject
  } = options;

  const types =
    searchBy === SearchBy.NAME_DESCRIPTION
      ? Object.entries(typesObject)
          .filter(([, value]) => value)
          .map(([key]) => typeFilterToApiType.get(key))
          .join(',')
      : undefined;

  const workspaceIds = useOpenWorkspaces
    ? Context.workspaces()
        .map(ws => ws.id)
        .join(',')
    : undefined;

  const customFieldName =
    searchBy === SearchBy.FIELD_VALUE ? fieldName : undefined;

  return { types, workspaceIds, customFieldName };
};
