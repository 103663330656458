import { Branch } from 'components/DiffMergeTable/Branch';
import { EnhancedDiffContextData } from 'components/DiffMergeTable/EnhancedDiffContextDataType';
import { capitalize } from 'lodash';
import { ScopeSize } from 'scope/types';
import { APIEntityType, Verb } from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';
import { MergeDirection } from './MergeDirection';
import { MergeStepLabel } from './typesMergeStepLabel';
import {
  EnhancedScopeDataWithBranchName,
  EntityDataCorruptionType,
  getEntityDataCorruptionType,
} from '@ardoq/renderers';

export const trackMergeFlowToggle = (
  context: 'opened' | 'closed',
  scopeSize: ScopeSize,
  issuesPerStepCount: { [key: string]: number },
  mergeDirection: MergeDirection,
  dontShowAgainMergeDialog: boolean
) =>
  trackEvent(
    `${capitalize(context)} merge flow`,
    {
      action: context,
      ...scopeSize,
      ...issuesPerStepCount,
      mergeDirection,
      dontShowAgainMergeDialog,
    },
    true
  );

type ErrorCountDictionary = { [errorKey: string]: number };

const incrementErrorCountOnErrorDictionary = ({
  entityId,
  parentEntityId,
  entityType,
  enhancedScopeData,
  errorCountDictionary,
}: {
  entityId: string;
  parentEntityId?: string;
  entityType: APIEntityType;
  enhancedScopeData: EnhancedScopeDataWithBranchName;
  errorCountDictionary: ErrorCountDictionary;
}) => {
  const entityDataCorruptionType = getEntityDataCorruptionType({
    entityId,
    parentEntityId,
    entityType,
    enhancedScopeData,
  });
  if (entityDataCorruptionType !== EntityDataCorruptionType.NONE) {
    errorCountDictionary[entityDataCorruptionType] =
      (errorCountDictionary[entityDataCorruptionType] || 0) + 1;
  }
};

const getScopeDataCorruptionDictionary = (
  enhancedScopeData: EnhancedScopeDataWithBranchName,
  keyPostfix: string
) => {
  const componentsErrorCountDictionary = enhancedScopeData.components.reduce(
    (errorCountDictionary, component) => {
      incrementErrorCountOnErrorDictionary({
        entityId: component._id,
        entityType: APIEntityType.COMPONENT,
        enhancedScopeData,
        errorCountDictionary,
      });
      return errorCountDictionary;
    },
    {} as ErrorCountDictionary
  );

  const referencesErrorCountDictionary = enhancedScopeData.references.reduce(
    (errorCountDictionary, reference) => {
      incrementErrorCountOnErrorDictionary({
        entityId: reference._id,
        entityType: APIEntityType.REFERENCE,
        enhancedScopeData,
        errorCountDictionary,
      });
      return errorCountDictionary;
    },
    {} as ErrorCountDictionary
  );

  return Object.fromEntries(
    Object.entries({
      ...referencesErrorCountDictionary,
      ...componentsErrorCountDictionary,
    }).map(([key, value]) => [key + keyPostfix, value])
  );
};

export const trackDiffContextDataCorruption = (
  enhancedDiffContextData: EnhancedDiffContextData
) => {
  const branchDataCorruptionDictionary = getScopeDataCorruptionDictionary(
    enhancedDiffContextData[Branch.BRANCH],
    '_ON_BRANCH'
  );
  const mainlineDataCorruptionDictionary = getScopeDataCorruptionDictionary(
    enhancedDiffContextData[Branch.MAIN],
    '_ON_MAINLINE'
  );

  const errorCountDictionary = {
    ...branchDataCorruptionDictionary,
    ...mainlineDataCorruptionDictionary,
  };

  if (Object.keys(errorCountDictionary).length) {
    trackEvent('Diff context data is corrupted.', errorCountDictionary);
  }
};

export const trackMergeFlowStepSelected = ({
  mainStep,
  subStep,
  issuesCount,
  conflictCounts,
}: {
  mainStep: MergeStepLabel;
  subStep: Verb;
  issuesCount: number;
  conflictCounts: { [conflictKey: string]: number };
}) => {
  trackEvent('Selected merge step', {
    mainStep,
    subStep,
    issuesCount,
    ...conflictCounts,
  });
};
