import { actionCreator } from '@ardoq/rxbeach';
import { ConsentState } from './types';

const NAMESPACE = '[integrations microsoft-entra-id] CONNECTIONS';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const fetchConsent = actionCreator(getActionName('FETCH_CONSENT'));
export const setConsentState = actionCreator<Partial<ConsentState>>(
  getActionName('SET_CONSENT_STATE')
);
