import {
  APIBroadcastAttributes,
  BroadcastContent,
  BroadcastSchedule,
} from '@ardoq/api-types';
import { pluralize } from '@ardoq/common-helpers';
import {
  getFormattedDate,
  isMessageContent,
  isRepeatingSchedule,
  isReportContent,
  isSurveyContent,
} from 'broadcasts/utils';
import { surveyInterface } from 'modelInterface/surveys/surveyInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { getDefaultReportRulesState } from './broadcastBuilder/broadcastContentForm/reportRulesSection/utils';

const getSurveyName = (content: BroadcastContent): string | null => {
  if (!isSurveyContent(content) || !content.contentId) return null;
  return surveyInterface.getName(content.contentId);
};

export const getReportContent = (content: BroadcastContent) => {
  if (!isReportContent(content)) return getDefaultReportRulesState();
  const { aggregate, threshold, predicate, columnName } = content;
  return { aggregate, threshold, predicate, columnName };
};

const getWorkspaceName = (content: BroadcastContent): string | null => {
  if (isMessageContent(content)) {
    if (!content.workspaceId) return null;
    return workspaceInterface.getWorkspaceName(content.workspaceId);
  }
  if (!content.contentId) return null;
  const workspaceId = surveyInterface.getWorkspace(content.contentId);
  if (!workspaceId) return null;
  return workspaceInterface.getWorkspaceName(workspaceId);
};

const getComponentTypeName = (content: BroadcastContent): string | null => {
  if (isMessageContent(content)) {
    if (!content.workspaceId || !content.componentType) return null;
    return (
      workspaceInterface.getTypeById(content.workspaceId, content.componentType)
        ?.name ?? null
    );
  }
  if (!content.contentId) return null;
  return surveyInterface.getComponentTypeName(content.contentId);
};

const getStartDate = (startDate: string | null) =>
  startDate ? getFormattedDate(startDate) : null;

const getEndDate = (schedule: BroadcastSchedule) => {
  if (!isRepeatingSchedule(schedule)) return null;
  return schedule.endDate ? getFormattedDate(schedule.endDate) : null;
};

const getScheduleType = (schedule: BroadcastSchedule) =>
  isRepeatingSchedule(schedule) ? 'Repeating broadcast' : 'Single broadcast';

const getInterval = (schedule: BroadcastSchedule) => {
  if (!isRepeatingSchedule(schedule)) return null;
  const intervalValue = schedule.interval.intervalValue;
  const intervalType = schedule.interval.intervalType;
  if (intervalValue === 1) {
    return `Every ${intervalType}`;
  }
  return `Every ${intervalValue} ${pluralize(intervalType, intervalValue)}`;
};

const getReminder = (reminderInDays: number | null) => {
  if (!reminderInDays) return null;
  return `${reminderInDays} ${pluralize(
    'day',
    reminderInDays
  )} after first schedule`;
};

export const getBroadcastDetailsDialogData = (
  broadcast: APIBroadcastAttributes
) => {
  return {
    name: broadcast.name,
    contentType: broadcast.content.contentType,
    ...getReportContent(broadcast.content),
    surveyName: getSurveyName(broadcast.content),
    workspaceName: getWorkspaceName(broadcast.content),
    componentTypeName: getComponentTypeName(broadcast.content),
    audiences: broadcast.audiences,
    sentFrom: broadcast.message.sender,
    startDate: getStartDate(broadcast.scheduling.startDate),
    scheduleType: getScheduleType(broadcast.scheduling),
    interval: getInterval(broadcast.scheduling),
    endDate: getEndDate(broadcast.scheduling),
    reminder: getReminder(broadcast.scheduling.reminderInDays),
    broadcastStatus: broadcast.status,
  };
};
