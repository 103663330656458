export const hasOwnProperty = (thisArg: unknown, key: PropertyKey) =>
  Object.prototype.hasOwnProperty.call(thisArg, key);

export const filterOutEmptyObjectsOnObject = <
  T extends Record<string, unknown>,
>(
  object: Record<string, T>
) =>
  Object.fromEntries(
    Object.entries(object).filter(([, value]) => Object.keys(value).length > 0)
  );
