import { ViewpointBuilderFooter } from 'viewpointBuilder/components/ViewpointBuilderFooter';
import { PrimaryButtonConfig } from 'viewpointBuilder/openViewpointBuilder/getPrimaryButtonConfig';
import { ComponentTypeSelect } from 'viewpointBuilder/selectContextTab/ComponentTypeSelect';
import { DoqType, DoqWithSideContent } from '@ardoq/doq';
import { Link } from '@ardoq/typography';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { Field, FormSize } from '@ardoq/forms';
import { toComponentTypeOptions } from './toComponentTypeOptions';
import { MetaModelComponentType } from '@ardoq/api-types';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { Island, PageBody } from '@ardoq/page-layout';
import { Box, Stack } from '@ardoq/layout';

type SelectComponentTypeState = {
  componentType: string | null;
  componentTypes: MetaModelComponentType[];
};

type SelectComponentTypeCommands = {
  setComponentTypeName: (componentTypeName: string) => void;
};

type SelectContextTabContentProps = {
  state: SelectComponentTypeState;
  commands: SelectComponentTypeCommands;
  closeViewpointBuilder: () => void;
  primaryButtonConfig: PrimaryButtonConfig;
};

export const SelectContextComponentTypeTabContent = ({
  state,
  commands,
  closeViewpointBuilder,
  primaryButtonConfig,
}: SelectContextTabContentProps) => {
  return (
    <PageBody
      footerContent={
        <ViewpointBuilderFooter
          closeViewpointBuilder={closeViewpointBuilder}
          primaryButtonConfig={primaryButtonConfig}
        />
      }
    >
      <Island
        fullHeight
        header={
          <HeaderWrapper padding="xlarge">
            <Field formSize={FormSize.DEFAULT}>
              <ComponentTypeSelect
                label="Select context component type"
                placeholder="Select component type"
                componentTypeOptions={toComponentTypeOptions(
                  state.componentTypes
                )}
                selectedComponentType={state.componentType ?? ''}
                setSelectedComponentType={commands.setComponentTypeName}
                isComponentTypeSelectionDisabled={false}
              />
            </Field>
          </HeaderWrapper>
        }
      >
        <Stack justify="center" flex={1}>
          <DoqyWithHint />
        </Stack>
      </Island>
    </PageBody>
  );
};

const DoqyWithHint = () => {
  return (
    <DoqWithSideContent
      doqType={DoqType.WAITING}
      title="How to create a Viewpoint"
    >
      <ul>
        <li>Select a context component type here</li>
        <li>
          Next, you can select reference types to add other connected component
          types
        </li>
        <li>You can also add filters, groups, formats and labels</li>
      </ul>
      <Link href={KnowledgeBaseLink.VIEWPOINT_BUILDER_PHOENIX} target="_blank">
        Learn more about the new Ardoq Viewpoints
      </Link>
    </DoqWithSideContent>
  );
};

const HeaderWrapper = styled(Box)`
  border-bottom: 1px solid ${colors.grey80};
`;
