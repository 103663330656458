import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  issueSurveyWorkspaceAccessRequest,
  resetSurveyWorkspaceAccessRequestState,
  setSurveyWorkspaceAccessRequestLoading,
  setSurveyWorkspaceAccessRequestSent,
} from './actions';
import { tap } from 'rxjs';
import { showToast, ToastType } from '@ardoq/status-ui';
import { getRequestSurveyWorkspaceAccessBody } from '../utils';
import { requestAccessApi, SendRequestAccessBody } from '@ardoq/api';
import { delay } from 'lodash';

const handleIssueSurveyWorkspaceAccessRequest = routine(
  ofType(issueSurveyWorkspaceAccessRequest),
  extractPayload(),
  tap(({ survey, user }) => {
    if (!survey)
      return showToast('No survey or workspace selected.', ToastType.INFO);
    if (!survey.workspace)
      return showToast('No workspace selected.', ToastType.INFO);

    dispatchAction(setSurveyWorkspaceAccessRequestLoading(true));

    const request: SendRequestAccessBody = getRequestSurveyWorkspaceAccessBody(
      survey,
      survey.workspace,
      user
    );

    requestAccessApi
      .request(request)
      .then(() => {
        delay(() => {
          dispatchAction(setSurveyWorkspaceAccessRequestLoading(false));
          dispatchAction(setSurveyWorkspaceAccessRequestSent(true));
          showToast('Request sent.', ToastType.SUCCESS);
        }, 500);
      })
      .catch(() => {
        showToast('Failed to send request.', ToastType.INFO);
        dispatchAction(resetSurveyWorkspaceAccessRequestState());
      });
  })
);

export default collectRoutines(handleIssueSurveyWorkspaceAccessRequest);
