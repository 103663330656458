import { actionCreator } from '@ardoq/rxbeach';
import { SurveySettingsFormData } from './types';
import {
  APICreateSurveyWithPermissionsResponse,
  APISurveyAttributes,
  APISurveyPendingApprovalsSummary,
  ArdoqId,
  UnpersistedEntity,
} from '@ardoq/api-types';
import { SurveyValidation } from 'surveyAdmin/types';

export const fetchSurveyValidation = actionCreator(
  '[surveys] FETCH_SURVEY_VALIDATION'
);

export const notifyFetchingSurveyValidationSucceeded = actionCreator<
  Record<ArdoqId, SurveyValidation>
>('[surveys] NOTIFY_FETCHING_SURVEY_VALIDATION_SUCCEEDED');

export const notifyFetchingSurveyValidationFailed = actionCreator(
  '[surveys] NOTIFY_FETCHING_SURVEY_VALIDATION_FAILED'
);

export const saveSurvey = actionCreator<APISurveyAttributes>(
  '[surveys] SAVE_SURVEY'
);

export const saveNewSurvey = actionCreator<
  UnpersistedEntity<APISurveyAttributes>
>('[surveys] SAVE_NEW_SURVEY');

export const saveNewSurveyInFolder = actionCreator<{
  survey: UnpersistedEntity<APISurveyAttributes>;
  folderId: ArdoqId;
}>('[surveys] SAVE_NEW_SURVEY_IN_FOLDER');

export const notifyNewSurveySaved =
  actionCreator<APICreateSurveyWithPermissionsResponse>(
    '[surveys] NOTIFY_NEW_SURVEY_SAVED'
  );

export const notifySavingSurveySucceeded = actionCreator(
  '[surveys] NOTIFY_SAVING_SURVEY_SUCCEEDED'
);

export const notifySavingSurveyFailed = actionCreator(
  '[surveys] NOTIFY_SAVING_SURVEY_FAILED'
);

export const fetchPendingApprovalsSummary = actionCreator(
  '[surveys] FETCH_PENDING_APPROVALS'
);

export const notifyFetchingPendingApprovalsSucceeded =
  actionCreator<APISurveyPendingApprovalsSummary>(
    '[surveys] NOTIFY_FETCHING_PENDING_APPROVALS_SUCCEEDED'
  );

export const notifyFetchingPendingApprovalsFailed = actionCreator(
  '[surveys] NOTIFY_FETCHING_PENDING_APPROVALS_FAILED'
);

export const postSurveyDigestUnsubscribe = actionCreator<{
  surveyId: ArdoqId;
  token: string;
  orgLabel: string;
}>('[surveyAdminActions] POST_SURVEY_DIGEST_UNSUBSCRIBE');

export const confirmSurveyDigestUnsubscribe = actionCreator<{
  surveyId: ArdoqId;
  surveyName: string;
  token: string;
  orgLabel: string;
}>('[surveyAdminActions] CONFIRM_SURVEY_DIGEST_UNSUBSCRIBE');

export const setOrganizationLogo = actionCreator<string>(
  '[surveyAdminActions] SET_ORGANIZATION_LOGO'
);

export const saveOrganization = actionCreator(
  '[surveyAdminActions] SAVE_ORGANIZATION'
);

export const saveSurveySettings = actionCreator(
  '[surveyAdminActions] SAVE_SURVEY_SETTINGS'
);

export const notifySavingSurveySettingsSucceeded = actionCreator(
  '[surveyAdminActions] NOTIFY_SAVING_SURVEY_SETTINGS_SUCCEEDED'
);

export const notifySavingSurveySettingsFailed = actionCreator<string>(
  '[surveyAdminActions] NOTIFY_SAVING_SURVEY_SETTINGS_FAILED'
);

export const toggleSurveySettingsSidebar = actionCreator(
  '[surveyAdminActions] TOGGLE_SURVEY_SETTINGS_SIDEBAR'
);

export const setIsOrgLogoValid = actionCreator<boolean>(
  '[surveyAdminActions] SET_IS_INVALID_ORG_LOGO'
);

export const setSurveySettingsSidebarData =
  actionCreator<SurveySettingsFormData>(
    '[surveyAdminActions] SET_SURVEY_SETTINGS_SIDEBAR_DATA'
  );

export const fetchSurveySettingsSidebarData = actionCreator(
  '[surveyAdminActions] FETCH_SURVEY_SETTINGS_SIDEBAR_DATA'
);
