import { DictionaryItem, FailureConfig } from '@ardoq/api-types/integrations';
import { isNil } from 'lodash';

export const isString = (value: any): value is string =>
  typeof value === 'string';

export const isNonEmptyStringIncludingSpaces = (value: any): value is string =>
  typeof value === 'string' && value.length > 0;

export const isNonEmptyString = (value: any): value is string =>
  typeof value === 'string' && value.trim() !== '';

export const isNumber = (value: any): value is number =>
  typeof value === 'number' && !isNaN(value);

export const isNonNegativeNumber = (value: any): value is number =>
  typeof value === 'number' && value >= 0;

export const validateDictionary = (dictionary: DictionaryItem[]): boolean => {
  return dictionary.every(item => isNonEmptyString(item.key));
};

export const validateFailure = (failure: FailureConfig): boolean => {
  if (
    failure.strategy === 'use_default' &&
    !isNonEmptyString(failure.default)
  ) {
    return false;
  }
  return isNonEmptyString(failure.strategy) && !isNil(failure.warn);
};
