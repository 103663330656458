import { useEffect } from 'react';
import { Features, hasFeature } from '@ardoq/features';
import { showSupport } from 'utils/support';
import { dispatchAction } from '@ardoq/rxbeach';
import { requestShowAppModule } from 'appContainer/actions';
import { featureData, featureMappings } from 'appContainer/landingPage/consts';
import { FeatureIcon } from 'appContainer/landingPage/atoms';
import {
  trackLandingPageFooterFeatureClick,
  trackLandingPageImpression,
  trackLandingPageTrialRequestClick,
} from 'appContainer/landingPage/tracking';
import { mailAdminForAccess } from 'appContainer/landingPage/actions';
import { PrimaryButton } from '@ardoq/button';
import { FlexBox, Stack } from '@ardoq/layout';
import { Header1, Header2, Header4, Paragraph } from '@ardoq/typography';

const showModuleAndLog = (appModule, page, footerFeatureClicked) => () => {
  dispatchAction(
    requestShowAppModule({
      selectedModule: appModule,
      options: { forceLandingPage: true },
    })
  );
  trackLandingPageFooterFeatureClick(page, footerFeatureClicked);
};

const openSupportDialogAndLog = featureTitle => () => {
  showSupport({
    prePopulateMessage: `Hi, I'd like to try ${featureTitle}.`,
  });
  trackLandingPageTrialRequestClick(featureTitle);
};

function getOtherFeatures(featureKey) {
  return Object.entries(featureMappings)
    .filter(([key, feature]) => key !== featureKey && hasFeature(feature))
    .map(([key]) => featureData[key]);
}

const Feature = ({ feature, currentPage }) => {
  return (
    <FlexBox
      padding="xlarge"
      marginX="xlarge"
      borderColor="borderAction"
      borderRadius="r8"
      style={{ maxWidth: '520px', cursor: 'pointer' }}
      onClick={showModuleAndLog(feature.appModule, currentPage, feature.title)}
    >
      <FlexBox>
        <FeatureIcon name={feature.iconName} color={feature.iconColor} />
      </FlexBox>
      <Stack gap="xsmall">
        <Header4>{feature.title}</Header4>
        <Paragraph>{feature.description}</Paragraph>
      </Stack>
    </FlexBox>
  );
};

const LandingPage = ({ viewModel }) => {
  useEffect(() => {
    trackLandingPageImpression(viewModel.featureKey);
  }, [viewModel.featureKey]);

  return (
    <FlexBox
      backgroundColor="surfaceDefault"
      align="center"
      width="full"
      padding="large"
      justify="center"
    >
      <Stack align="center">
        <FlexBox style={{ maxWidth: '520px' }}>
          <Stack gap="medium" align="center">
            <Header1>
              {viewModel.requiresOrgAdmin(Features)
                ? viewModel.noAccessTitle
                : viewModel.title}
            </Header1>
            <Paragraph variant="text1Bold">{viewModel.subtitle}</Paragraph>
            <Paragraph align="center">{viewModel.description}</Paragraph>
            <FlexBox justify="center" marginY="large">
              {viewModel.requiresOrgAdmin(Features) ? (
                <PrimaryButton
                  onClick={() =>
                    dispatchAction(
                      mailAdminForAccess({ page: viewModel.featureKey })
                    )
                  }
                >
                  Contact org admin
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={openSupportDialogAndLog(viewModel.featureKey)}
                >
                  Request Trial
                </PrimaryButton>
              )}
            </FlexBox>
          </Stack>
        </FlexBox>
        {!!getOtherFeatures(viewModel.featureKey).length && (
          <FlexBox paddingY="xlarge">
            <Stack gap="large">
              <FlexBox justify="center">
                <Header2>Get even more value from your data</Header2>
              </FlexBox>
              <FlexBox justify="center">
                {getOtherFeatures(viewModel.featureKey).map((f, key) => (
                  <Feature
                    key={key}
                    feature={f}
                    currentPage={viewModel.featureKey}
                  />
                ))}
              </FlexBox>
            </Stack>
          </FlexBox>
        )}
      </Stack>
    </FlexBox>
  );
};

export default LandingPage;
