import { IconButton } from '@ardoq/button';
import { colors, radius, s16, s32, s8, shadowM } from '@ardoq/design-tokens';
import { IconName } from '@ardoq/icons';
import { header3 } from '@ardoq/typography';
import styled from 'styled-components';

type MinimizedChatProps = {
  close: () => void;
  open: () => void;
};

export const MinimizedChat = ({ close, open }: MinimizedChatProps) => {
  return (
    <Wrapper onClick={open}>
      <Title>Best practice assistant</Title>
      <IconButton
        iconName={IconName.CLOSE}
        onClick={event => {
          event.stopPropagation();
          close();
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 400px;
  height: 48px;
  padding-left: ${s16};
  padding-right: ${s8};

  position: fixed;
  right: ${s32};
  bottom: ${s32};
  z-index: 2100;

  background-color: ${colors.white};
  box-shadow: ${shadowM};
  border-radius: ${radius.r4};

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  &:hover {
    background-color: ${colors.grey95};
  }
`;

const Title = styled.div`
  ${header3};
`;
