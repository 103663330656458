import {
  DataLoadingPayload,
  SelectionResponse,
} from '@ardoq/api-types/integrations';
import { UnifiedIntegrationId } from 'integrations/unified/types';

export type DataLoadingInitialState = { status: 'INIT' };
type DataLoadingInProgressState = {
  status: 'LOADING';
  payload: DataLoadingPayload;
};
type DataLoadingErrorState = {
  status: 'FAILURE';
  payload: DataLoadingPayload;
  level: 'warning' | 'error';
  message: string;
};
export type DataLoadingSuccessState = {
  status: 'SUCCESS';
  response: SelectionResponse;
  payload: DataLoadingPayload;
};

export type IntegrationDataLoading =
  | DataLoadingInitialState
  | DataLoadingInProgressState
  | DataLoadingErrorState
  | DataLoadingSuccessState;

export type IntegrationIdToDataLoading = Record<
  UnifiedIntegrationId,
  IntegrationDataLoading
>;

type DataLoadingErrorResponse = {
  message: string;
};

export type DataLoadingResponse = DataLoadingErrorResponse | SelectionResponse;

export const isDataLoadingErrorResponse = (
  response: DataLoadingResponse
): response is DataLoadingErrorResponse => {
  return 'message' in response;
};

export const isDataLoadingSuccessResponse = (
  response: DataLoadingResponse
): response is SelectionResponse => {
  return 'tables' in response;
};
