import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ChevronIconName, Icon, IconSize } from '@ardoq/icons';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { Header5 } from '@ardoq/typography';

interface Props {
  title: string;
  collapsible?: boolean;
  isExpanded?: boolean;
}
const ExpanderIcon = styled(Icon).attrs({ iconSize: 18 as IconSize })`
  padding: 3px;
`;
const SectionHeaderContainer = styled.div`
  cursor: pointer;
  user-select: none;
  padding: ${s8} ${s16};
  border-top: 1px solid ${colors.grey80};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SectionHeader: FunctionComponent<Props> = ({
  title,
  collapsible = false,
  isExpanded = true,
}) => (
  <SectionHeaderContainer>
    <Header5>{title}</Header5>
    {collapsible && (
      <ExpanderIcon
        iconName={isExpanded ? ChevronIconName.UP : ChevronIconName.DOWN}
      />
    )}
  </SectionHeaderContainer>
);

export default SectionHeader;
