import { SingavioExporterConnection } from '@ardoq/api-types/integrations';
import { modal } from '@ardoq/modal';
import { connect } from '@ardoq/rxbeach';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  listConnections,
  upsertConnection,
  upsertConnectionFailure,
} from 'integrations/common/streams/connections/actions';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { initialState } from 'integrations/signavio-exporter/initalState';
import { UpsertConnectionModalComponent } from 'integrations/unified/overview/account/UpsertAccountModal';
import { map } from 'rxjs';

const UpsertConnnectionModal = connect(
  UpsertConnectionModalComponent,
  getConnectionsStream<SingavioExporterConnection>('signavio-exporter').pipe(
    map(connectionsState => {
      const { authMethodFields, defaultAuthMethod } = initialState;
      const integrationName = initialState.termsDictionary.name;
      const onSubmit = async (account: SingavioExporterConnection) => {
        const action = await dispatchActionAndWaitForResponse(
          upsertConnection({
            integrationId: 'signavio-exporter',
            connection: account,
          }),
          listConnections,
          upsertConnectionFailure
        );
        return action.type === listConnections.type;
      };
      return {
        connectionsState,
        integrationName,
        authMethodFields,
        defaultAuthMethod,
        onSubmit,
      };
    })
  )
);

export const startUpsertConnectionModal = (connectionId?: string) => {
  return modal(
    close => (
      <UpsertConnnectionModal
        connectionId={connectionId}
        onCancel={() => close(null)}
        close={() => close(true)}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
};
