import { switchMap, tap, mergeMap, map } from 'rxjs/operators';
import {
  createAssetFolder,
  moveAssets,
  moveAssetsError,
  moveAssetsSuccess,
  createAssetFolderSucceeded,
  MoveAssetsPayload,
  workspaceFolderNamespace,
} from './actions';
import {
  collectRoutines,
  routine,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { alert } from '@ardoq/modal';
import { uniqBy } from 'lodash';
import { workspaceFolderApi, api, handleError } from '@ardoq/api';
import { renameRoutine } from 'streams/assets/routines';
import { handleFetchAll } from 'streams/crud/routines';

const toApiPayload = ({ folderId, movedAssets }: MoveAssetsPayload) => ({
  targetFolderId: folderId,
  // basic filter to prevent drop folder to itself
  // it's already protected in some other places,
  // but here is one more check right before API call
  content: uniqBy(movedAssets, '_id').filter(({ _id }) => _id !== folderId),
});

const handleMoveAssets = routine(
  ofType(moveAssets),
  extractPayload(),
  map(toApiPayload),
  mergeMap(workspaceFolderApi.moveAssets),
  handleError(response => {
    dispatchAction(moveAssetsError());
    api.logErrorIfNeeded(response);
    alert({
      title: 'Error',
      subtitle: 'Sorry, assets move failed',
    });
  }),
  tap(() => dispatchAction(moveAssetsSuccess()))
);

const identityFolder = () => ({
  name: '',
  description: '',
  content: [],
});

const handleCreateAssetFolder = routine(
  ofType(createAssetFolder),
  extractPayload(),
  map(({ name }) => ({ ...identityFolder(), name })),
  switchMap(workspaceFolderApi.create),
  handleError(),
  tap(assetFolder => {
    dispatchAction(createAssetFolderSucceeded(assetFolder));
  })
);

export const assetFoldersRoutines = collectRoutines(
  handleMoveAssets,
  handleFetchAll(workspaceFolderNamespace, workspaceFolderApi.fetchAll),
  renameRoutine(workspaceFolderNamespace, workspaceFolderApi.rename),
  handleCreateAssetFolder
);
