import {
  APIDiscoverViewpointAttributes,
  APIWorkspaceAttributes,
  ArdoqId,
  Workspace,
} from '@ardoq/api-types';
import { RestrictedWorkspaces } from './types';
import { first, flow, groupBy, mapValues } from 'lodash/fp';
import { difference, find, pick, size } from 'lodash';
import { pickMap } from './utils';

export const populateRestrictedWorspacesByViewpoint = (
  restrictedWorkspaces: RestrictedWorkspaces,
  viewpoints: APIDiscoverViewpointAttributes[],
  workspaces: Workspace[]
): RestrictedWorkspaces => {
  const byViewpointId = viewpoints.reduce(
    (acc, { workspaceIds, _id: viewpointId }) => {
      const restrictedWorkspacesIds = workspaceIds.filter(
        workspaceId => !find(workspaces, { _id: workspaceId })
      );
      return {
        ...acc,
        [viewpointId]: restrictedWorkspacesIds,
      };
    },
    {}
  );
  return {
    ...restrictedWorkspaces,
    byViewpointId,
  };
};

export const populateRestrictedWorkspacesById = (
  restrictedWorkspaces: RestrictedWorkspaces,
  fetchAllWorkspacesPayload: APIWorkspaceAttributes[],
  workspacesInState: Workspace[]
): RestrictedWorkspaces => {
  const allWorkspaces = pickMap(fetchAllWorkspacesPayload, [
    '_id',
    'name',
    'views',
    'folder',
    'permissions',
    'startView',
  ]);
  const restrictedWorkspacesById = flow([groupBy('_id'), mapValues(first)])(
    difference(allWorkspaces, workspacesInState)
  );
  return {
    ...restrictedWorkspaces,
    byId: restrictedWorkspacesById,
  };
};

export const viewpointHasRestrictedWorkspaces = (
  restrictedWorkspaces: RestrictedWorkspaces,
  viewpointId = ''
): boolean => {
  return size(restrictedWorkspaces.byViewpointId[viewpointId]) > 0;
};

export const getRestrictedWorkspaces = (
  restrictedWorkspaces: RestrictedWorkspaces,
  viewpointId = ''
): Workspace[] => {
  return Object.values(
    pick(
      restrictedWorkspaces.byId,
      restrictedWorkspaces.byViewpointId[viewpointId]
    )
  );
};

export const getRestrictedWorkspacesNames = (
  restrictedWorkspaces: RestrictedWorkspaces,
  viewpointId: ArdoqId
): string[] => {
  return getRestrictedWorkspaces(restrictedWorkspaces, viewpointId).map(
    ({ name }) => name
  );
};

export const thereAreSomeRestrictedWorkspaces = (
  restrictedWorkspaces: RestrictedWorkspaces
) => {
  return size(restrictedWorkspaces.byId) > 0;
};
