import { CloudProvider } from '@ardoq/api-types/integrations';
import { CloudProviderIntegrationId } from './types';

export const INTEGRATION_ID_TO_CLOUD_PROVIDER: Record<
  CloudProviderIntegrationId,
  CloudProvider
> = {
  'aws-v3': 'aws',
  'azure-v3': 'azure',
};
