import { AccessControlTabs } from 'admin/accessControl/navigation/types';
import { userProfilePagePropsOperations } from 'admin/accessControl/UserProfilePage/userProfilePagePropsOperations';
import { organizationOperations } from '@ardoq/core';
import {
  AccessControlPageResources,
  AccessControlPageStream,
  AccessControlPageViewModel,
} from './types';
import { accessControlNavigationOperations } from 'admin/accessControl/navigation/accessControlPageNavigationOperations';
import { userAssetsOverviewOperations } from 'admin/accessControl/UserProfilePage/userAssetsOverview/userAssetsOverviewOperations';
import { userAssetsOverviewQueryOperations } from 'admin/accessControl/UserProfilePage/userAssetsOverview/userAssetsOverviewQueryOperations';
import { userAssetsFilterSidebarStateOperations } from 'admin/accessControl/UserProfilePage/UserRoleAndGroupsDetails/UserAssetsOverviewTab/assetsOverviewFilterSidebarOperations';

const emptyState = (): AccessControlPageViewModel => ({
  accessControlTab: AccessControlTabs.MEMBERS,
  configurablePrivileges: [],
  hasCustomDomain: false,
  userAssetsOverview: userAssetsOverviewOperations.emptyState(),
  userAssetsQuery: userAssetsOverviewQueryOperations.getEmpty(),
  userAssetsFilterSidebarState:
    userAssetsFilterSidebarStateOperations.getEmpty(),
  locallyFilteredUserAssets: [],
});

const handleNavigateToUserPage = (
  state: AccessControlPageViewModel,
  accessControlPageResources: AccessControlPageStream['resources']
): AccessControlPageViewModel => {
  const { navigationState, privileges, orgUsers, rolesShape, permissionGroup } =
    accessControlPageResources;
  if (
    !navigationState ||
    !accessControlNavigationOperations.isUserProfilePage(navigationState)
  ) {
    return {
      ...state,
      userAssetsOverview: userAssetsOverviewOperations.emptyState(),
    };
  }

  const usersById = orgUsers?.byId ?? {};
  const userId = navigationState.userId;
  const user = usersById[userId];

  if (!user) {
    return state;
  }

  const userPageViewModel = userProfilePagePropsOperations.getViewModel({
    user,
    configurablePrivileges: state.configurablePrivileges,
    permissionGroupsById: permissionGroup?.groupsById ?? {},
    privilegesByUser: privileges?.privilegesByUser ?? {},
    roles: rolesShape?.roles ?? [],
    activeTabId: navigationState.userProfileActiveTabId,
  });

  return {
    ...state,
    userPageViewModel,
    userAssetsQuery: {
      ...state.userAssetsQuery,
      userId,
    },
  };
};

const handlePrivilegesChange = (
  state: AccessControlPageViewModel,
  privileges: AccessControlPageResources['privileges']
): AccessControlPageViewModel => {
  const configurablePrivileges = privileges?.configurablePrivileges ?? [];
  return {
    ...state,
    configurablePrivileges,
  };
};

const handleOrganizationChange = (
  state: AccessControlPageViewModel,
  organization: AccessControlPageResources['organization']
): AccessControlPageViewModel => {
  const hasCustomDomain = organization
    ? organizationOperations.hasCustomDomain(organization)
    : false;
  return {
    ...state,
    hasCustomDomain,
  };
};

const handleNavigationChange = (
  state: AccessControlPageViewModel,
  navigationState: AccessControlPageResources['navigationState']
): AccessControlPageViewModel => {
  const accessControlTab =
    navigationState?.accessControlTab ?? AccessControlTabs.MEMBERS;
  const userId = accessControlNavigationOperations.isUserProfilePage(
    navigationState
  )
    ? navigationState?.userId
    : undefined;
  return {
    ...state,
    accessControlTab,
    userId,
  };
};

export const accessControlPageOperations = {
  emptyState,
  handleNavigateToUserPage,
  handlePrivilegesChange,
  handleOrganizationChange,
  handleNavigationChange,
};
