import { TraversalPreviewState } from './types';
import SimpleGraphView from 'viewpointBuilder/components/SimpleGraph/SimpleGraphView';
import { SimpleGraphContainerWithGreyBackground } from 'viewpointBuilder/components/SimpleGraph/SimpleGraphViewContainer';
import { MetaModelLoader } from 'viewpointBuilder/components/WithMetamodelLoader';
import { ModalSize, ModalTemplate } from '@ardoq/modal';
import { TraversalPreview$ } from './traversalPreview$';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import UserMetaRepresentation from 'homePage/UserMetaRepresentation';
import { Icon, IconName } from '@ardoq/icons';
import { colors, spacing } from '@ardoq/design-tokens';
import { caption } from '@ardoq/typography';
import styled from 'styled-components';
import { useEffectOnce } from '@ardoq/hooks';
import { ArdoqId } from '@ardoq/api-types';
import { setTraversalId } from './actions';
import getMetaInfoTabs from 'views/metaInfoTabs';
import { viewpointsOverviewCommands } from 'traversals/TraversalOverview/viewpointsOverviewCommands';
import { FlexBox, Stack } from '@ardoq/layout';
import { trackEvent } from 'tracking/tracking';

const MinHeight = styled.div`
  min-height: 400px;
  height: 100%;
`;

const Caption = styled.span`
  ${caption};
  max-width: 240px;
  color: ${colors.textModerate};
  padding: ${spacing.s16};
  max-height: 565px;
  overflow-y: auto;
`;

const SubtleCaption = styled.span`
  ${caption};
  color: ${colors.textSubtle};
`;

type TraversalPreviewProps = TraversalPreviewState & {
  isFavorite?: boolean;
  previewId: ArdoqId | null;
  closeModal: VoidFunction;
  openViewpoint?: (viewpointId: ArdoqId) => void;
};

const TraversalPreviewModal = ({
  graphNodes,
  graphEdges,
  graphGroups,
  previewId,
  traversal,
  isMetaModelLoaded,
  isFavorite,
  closeModal,
  openViewpoint,
}: TraversalPreviewProps) => {
  const metaInfoTabs = getMetaInfoTabs();
  useEffectOnce(() => {
    dispatchAction(setTraversalId({ traversalId: previewId }));
  });
  return (
    <ModalTemplate
      headerText={traversal?.name}
      modalSize={ModalSize.M}
      onCloseButtonClick={closeModal}
      onPrimaryButtonClick={() => {
        if (!previewId) return;
        if (openViewpoint) {
          openViewpoint(previewId);
        } else {
          viewpointsOverviewCommands.openViewpoint(previewId);
        }
        trackEvent('Assets browser: Opened viewpoint from preview');
        closeModal();
      }}
      primaryButtonText="Open viewpoint"
      isPrimaryButtonDisabled={!previewId}
    >
      <MinHeight>
        {previewId &&
          (traversal && isMetaModelLoaded ? (
            <FlexBox height="full">
              <Stack>
                <Caption>
                  <FlexBox gap="xsmall" align="center" paddingBottom="medium">
                    {isFavorite && (
                      <Icon
                        iconName={IconName.STAR}
                        color={colors.iconWarning}
                      />
                    )}
                    <SubtleCaption>
                      {metaInfoTabs.get(traversal.viewName)?.name}
                    </SubtleCaption>
                  </FlexBox>
                  {traversal.description?.length
                    ? traversal.description
                    : 'No description.'}
                </Caption>
                <UserMetaRepresentation
                  lastModifiedByName={traversal.lastModifiedByName ?? null}
                  lastUpdated={traversal.lastUpdated ?? null}
                  hideBorder
                />
              </Stack>
              <SimpleGraphContainerWithGreyBackground $margin={0}>
                <SimpleGraphView
                  viewModel={{ graphNodes, graphEdges, graphGroups }}
                  hasZoomControls={false}
                  isThumbnailView={true}
                />
              </SimpleGraphContainerWithGreyBackground>
            </FlexBox>
          ) : (
            <MetaModelLoader />
          ))}
      </MinHeight>
    </ModalTemplate>
  );
};

export default connect(TraversalPreviewModal, TraversalPreview$);
