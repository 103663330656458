import { ActiveTabId, WidgetState } from './types';

const setActiveTab = (
  state: WidgetState,
  activeTab: ActiveTabId
): WidgetState => {
  return { ...state, activeTab };
};

const dismissBanner = (state: WidgetState): WidgetState => {
  return { ...state, isBannerDismissed: true };
};

const setIsExpanded = (
  state: WidgetState,
  isExpanded: boolean
): WidgetState => {
  return { ...state, isExpanded };
};

export const viewpointWidgetOperations = {
  setActiveTab,
  dismissBanner,
  setIsExpanded,
};
