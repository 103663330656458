import { components, SelectOption } from '@ardoq/select';
import React, { useMemo } from 'react';
import { WorkspaceDetails } from './WorkspaceDetails';
import { MenuListProps, OptionProps } from 'react-select';
import { ArdoqId } from '@ardoq/api-types';

const getMenuList = <TValue,>() => {
  return (props: MenuListProps<SelectOption<TValue>>) => {
    const menuList = useMemo(
      () => (
        <components.MenuList
          {...(props as MenuListProps<SelectOption<unknown>>)}
        >
          {props.children}
        </components.MenuList>
      ),
      [props]
    );

    return (
      <>
        {menuList}
        <WorkspaceDetails />
      </>
    );
  };
};

const getOption =
  <TValue,>(setActiveWsId: (id: ArdoqId | undefined) => void) =>
  (
    props: OptionProps<SelectOption<TValue>> & {
      label: string;
      value: string;
      rightContent: JSX.Element | null;
    }
  ) => (
    <div onMouseEnter={() => setActiveWsId(props.value)}>
      <components.Option
        {...(props as OptionProps<SelectOption<unknown>> & {
          label: string;
          value: string;
          rightContent: JSX.Element | null;
        })}
      />
    </div>
  );

export const getDropdownWsInfoComponents = <TValue,>({
  setActiveWsId,
}: {
  setActiveWsId: (id: ArdoqId | undefined) => void;
}) => ({
  ...(components as any),
  MenuList: getMenuList<TValue>(),
  Option: getOption<TValue>(setActiveWsId),
});
