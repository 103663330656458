import { AssetType, UseCaseAssetType } from '@ardoq/api-types';

export enum SetupStageStep {
  PREVIEW = 'preview',
  IMPORT = 'import',
  SUMMARY = 'summary',
}

export const USE_CASE_LANDING_PAGE_ISLAND_MIN_HEIGHT = '900px';

export const customUseCaseContentTypeRepresentationLabel: Record<
  string,
  string
> = {
  [UseCaseAssetType.SERVICENOW_CONFIG]: 'ServiceNow configuration',
  [UseCaseAssetType.TABULAR_MAPPING]: 'Tabular mapping',
  [AssetType.VIEWPOINT]: 'Discover Viewpoint',
  [AssetType.TRAVERSAL]: 'Viewpoint',
};
