import { DoqType, DoqWithSideContent } from '@ardoq/doq';
import styled from 'styled-components';
import { s64 } from '@ardoq/design-tokens';
import { SecondaryButton } from '@ardoq/button';
import { OpenViewpointBuilderCommands } from 'viewpointBuilder/openViewpointBuilderCommands';
import { FlexBox } from '@ardoq/layout';

const Wrapper = styled.div`
  padding: ${s64} 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type NoViewpointsForStartComponentTypeProps = {
  close: () => void;
  selectedType: string;
  commands: OpenViewpointBuilderCommands;
};

const NoViewpointsForStartComponentType = ({
  close,
  selectedType,
  commands,
}: NoViewpointsForStartComponentTypeProps) => {
  return (
    <FlexBox justify="center" align="center" paddingY="xxlarge">
      <DoqWithSideContent
        doqType={DoqType.MISSING_ORG}
        title="No viewpoints available"
        buttons={
          <SecondaryButton
            onClick={() => {
              close();
              commands.openViewpointBuilderWithoutClosingLoadedStates();
            }}
          >
            Create a new viewpoint
          </SecondaryButton>
        }
      >
        <p>
          There are no saved viewpoints for <b>{selectedType}</b> component
          type. You can create a new viewpoint with the selected context
          component.
        </p>
      </DoqWithSideContent>
    </FlexBox>
  );
};

type NoTraversalsForSearchTermProps = NoViewpointsForStartComponentTypeProps & {
  term: string;
};

const NoTraversalsForSearchTerm = ({
  term,
  selectedType,
  commands,
  close,
}: NoTraversalsForSearchTermProps) => {
  return (
    <Wrapper>
      <DoqWithSideContent
        doqType={DoqType.MISSING_ORG}
        title="No results found"
        buttons={
          <SecondaryButton
            onClick={() => {
              close();
              commands.openViewpointBuilderToExploreDataAndCloseLoadedStates();
            }}
          >
            Create a new viewpoint
          </SecondaryButton>
        }
      >
        <p>
          There is no viewpoint for <b>{selectedType}</b> component type with
          the name &quot;{term}&quot;.
        </p>
        <p>
          Try a different search term, or create a new viewpoint with the
          selected context component.
        </p>
      </DoqWithSideContent>
    </Wrapper>
  );
};

type NoTraversalsProps = {
  term: string;
  traversalsForTypeCount: number;
  selectedType: string;
  close: () => void;
  commands: OpenViewpointBuilderCommands;
};

const NoTraversals = ({
  term,
  traversalsForTypeCount,
  selectedType,
  close,
  commands,
}: NoTraversalsProps) => {
  if (traversalsForTypeCount === 0) {
    return (
      <NoViewpointsForStartComponentType
        selectedType={selectedType}
        close={close}
        commands={commands}
      />
    );
  }

  return (
    <NoTraversalsForSearchTerm
      term={term}
      selectedType={selectedType}
      close={close}
      commands={commands}
    />
  );
};

export default NoTraversals;
