import { RemoveUsersViewModel$State } from './removeUsersViewModel$';

const handleRemoveUserFromGroup = (
  state: RemoveUsersViewModel$State
): RemoveUsersViewModel$State => ({
  ...state,
  apiErrorMessage: null,
  isSubmitting: true,
});

const handleRemoveUserFromGroupSuccess = (
  state: RemoveUsersViewModel$State
): RemoveUsersViewModel$State => ({
  ...state,
  isSubmitting: false,
  apiErrorMessage: null,
});

const handleRemoveUserFromGroupFailed = (
  state: RemoveUsersViewModel$State,
  apiErrorMessage: string
): RemoveUsersViewModel$State => ({
  ...state,
  isSubmitting: false,
  apiErrorMessage: apiErrorMessage,
});

const resetRemoveUserApiErrorMessage = (
  state: RemoveUsersViewModel$State
): RemoveUsersViewModel$State => ({
  ...state,
  apiErrorMessage: null,
});

export const removeUsersOperations = {
  handleRemoveUserFromGroup,
  handleRemoveUserFromGroupSuccess,
  handleRemoveUserFromGroupFailed,
  resetRemoveUserApiErrorMessage,
};
