import { useState } from 'react';
import { Icon, IconName } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import {
  InputsSection,
  PanelContainer,
  PanelFooter,
  PanelHeader,
  SectionsContainer,
  StyledField,
} from './atoms';
import { WithPointerEvents } from '@ardoq/style-helpers';
import { Button, ButtonType, PrimaryButton, GhostButton } from '@ardoq/button';
import { ReadOnlyStyle } from '@ardoq/forms';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { hideViewSidePanel } from 'views/mainApp/viewSidePanel/actions';
import { TextAreaWithCounter } from '@ardoq/snowflakes';
import { useEffectOnce } from '@ardoq/hooks';
import { saveAsViewpoint$ } from './saveAsViewpoint$/saveAsViewpoint$';
import { SaveAsViewpointState } from './saveAsViewpoint$/types';
import {
  resetSaveAsViewpoint,
  updateSaveAsViewpointState,
} from './saveAsViewpoint$/actions';
import { editorOpened } from 'perspective/actions';
import { AppliedSettings } from './appliedSettings/AppliedSettings';
import { combineLatest, map, switchMap } from 'rxjs';
import { traversalInterface } from 'modelInterface/traversals/traversalInterface';
import { loadedState$ } from 'loadedState/loadedState$';
import { isSingleTraversalState } from './saveAsViewpoint$/isSingleTraversalState';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import {
  createViewpoint,
  createViewpointFailure,
  createViewpointSuccess,
  updateViewpoint,
  updateViewpointFailure,
  updateViewpointSuccess,
} from 'viewpointBuilder/actions';
import { setTraversalId } from 'loadedState/actions';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { LoadedState, ViewpointSavedViewSettings } from '@ardoq/api-types';
import { getViewSettingsToSave } from './utils';
import getSaveViewpointTrackingData from './getSaveViewpointTrackingData';
import { trackSavingViewpoint } from './tracking';

const onNameFieldChange = (value: string) =>
  dispatchAction(updateSaveAsViewpointState({ viewpointName: value }));
const onDescriptionFieldChange = (value: string) =>
  dispatchAction(
    updateSaveAsViewpointState({
      viewpointDescription: value,
    })
  );

type SaveAsViewpointProps = SaveAsViewpointState & {
  commands: SaveAsViewpointCommands;
};

const SaveAsViewpoint = ({
  errors,
  viewpointName,
  viewpointDescription,
  mainViewName,
  appliedSettings,
  commands,
}: SaveAsViewpointProps) => {
  useEffectOnce(() => dispatchAction(editorOpened()));
  const [isNameFieldFocused, setIsNameFieldFocused] = useState(false);

  const nameFieldErrorMessage = isNameFieldFocused
    ? undefined
    : errors.viewpointName;

  const isExistingViewpoint = Boolean(
    appliedSettings.viewpointData?.traversalId
  );

  const primaryButtonError = isExistingViewpoint
    ? errors.updateButton
    : errors.saveButton;

  return (
    <PanelContainer>
      <PanelHeader>
        <Icon iconName={IconName.VIEWPOINT} />
        <span>Save as Viewpoint</span>
      </PanelHeader>
      <SectionsContainer>
        <InputsSection>
          <TextAreaWithCounter
            autoFocus
            id="save-as-viewpoint-name-input"
            label="Viewpoint name"
            helperText="Use a short name that describes the data others can get with this viewpoint"
            errorMessage={nameFieldErrorMessage}
            value={viewpointName}
            onValueChange={onNameFieldChange}
            characterLimit={60}
            characterLimitWarning="Long names may not be fully displayed"
            onFocus={() => setIsNameFieldFocused(true)}
            onBlur={() => {
              setIsNameFieldFocused(false);
              // trigger validation when field loses focus
              dispatchAction(updateSaveAsViewpointState({}));
            }}
          />
          <TextAreaWithCounter
            id="save-as-viewpoint-description-input"
            label="Viewpoint description"
            placeholder="What information or questions can this viewpoint answer?"
            value={viewpointDescription}
            onValueChange={onDescriptionFieldChange}
            minRows={3}
            characterLimit={150}
            characterLimitWarning="Long descriptions may not be fully displayed"
            isOptional
          />
          <StyledField label="View style">
            <ReadOnlyStyle>{mainViewName}</ReadOnlyStyle>
          </StyledField>
        </InputsSection>
        <AppliedSettings appliedSettings={appliedSettings} />
      </SectionsContainer>
      <PanelFooter $padding="s16" $alignToRight>
        <GhostButton
          onClick={() => {
            dispatchAction(hideViewSidePanel());
            dispatchAction(resetSaveAsViewpoint());
          }}
        >
          Cancel
        </GhostButton>
        {isExistingViewpoint ? (
          <WithPopover content={errors.saveButton}>
            <WithPointerEvents>
              <Button
                buttonType={ButtonType.SECONDARY}
                isDisabled={Boolean(errors.saveButton)}
                onClick={commands.saveAsNew}
              >
                Save as new
              </Button>
            </WithPointerEvents>
          </WithPopover>
        ) : null}
        <WithPopover content={primaryButtonError}>
          <WithPointerEvents>
            <PrimaryButton
              isDisabled={Boolean(primaryButtonError)}
              onClick={
                isExistingViewpoint
                  ? commands.updateExisting
                  : commands.saveAsNew
              }
            >
              {isExistingViewpoint ? 'Update' : 'Save Viewpoint'}
            </PrimaryButton>
          </WithPointerEvents>
        </WithPopover>
      </PanelFooter>
    </PanelContainer>
  );
};

type SaveAsViewpointCommands = {
  saveAsNew: () => void;
  updateExisting: () => void;
};

const viewModel$ = combineLatest({
  viewpointState: saveAsViewpoint$,
  loadedState: loadedState$,
}).pipe(
  switchMap(({ viewpointState, loadedState }) =>
    /**
     * We do this because we need the viewSettings$
     */
    getViewSettingsStream<ViewpointSavedViewSettings>(
      viewpointState.mainViewId
    ).pipe(
      map(viewSettings => ({
        viewpointState,
        loadedState,
        viewSettings,
      }))
    )
  ),
  map(({ viewpointState, loadedState, viewSettings }) => ({
    ...viewpointState,
    commands: {
      saveAsNew: () => saveAsNew({ viewpointState, loadedState, viewSettings }),
      updateExisting: () =>
        updateExisting({ viewpointState, loadedState, viewSettings }),
    },
  }))
);

const createViewpointAttributes = ({
  viewpointState,
  viewSettings,
}: {
  viewpointState: SaveAsViewpointState;
  viewSettings: ViewpointSavedViewSettings;
}) => {
  const {
    viewpointName: name,
    viewpointDescription: description,
    mainViewId: viewName,
    appliedSettings: { viewpointData },
  } = viewpointState;
  if (!name || !viewName || !viewpointData) {
    return null;
  }
  return {
    name,
    description,
    viewName,
    startType: viewpointData.startType,
    paths: viewpointData.paths,
    groupBys: viewpointData.groupBys,
    conditionalFormatting: viewpointData.conditionalFormatting,
    filters: viewpointData.filters,
    pathMatching: viewpointData.pathMatching,
    pathCollapsingRules: viewpointData.pathCollapsingRules,
    viewSettings: getViewSettingsToSave(viewSettings, viewName) ?? undefined,
  };
};

const saveAsNew = async ({
  viewpointState,
  loadedState,
  viewSettings,
}: {
  viewpointState: SaveAsViewpointState;
  loadedState: LoadedState[];
  viewSettings: ViewpointSavedViewSettings;
}) => {
  const attributes = createViewpointAttributes({
    viewpointState,
    viewSettings,
  });
  if (!attributes || !isSingleTraversalState(loadedState)) {
    return null;
  }

  const response = await dispatchActionAndWaitForResponse(
    createViewpoint(attributes),
    createViewpointSuccess,
    createViewpointFailure
  );
  if (!response.payload) {
    return null;
  }

  trackSavingViewpoint(
    getSaveViewpointTrackingData({
      appliedSettings: viewpointState.appliedSettings,
      viewName: viewpointState.mainViewId,
      saveAsNew: true,
      viewSettings,
    })
  );

  dispatchAction(
    setTraversalId({
      id: response.payload,
      loadedStateHash: loadedStateOperations.stateToHash(loadedState[0]),
    })
  );
  dispatchAction(hideViewSidePanel());
  dispatchAction(resetSaveAsViewpoint());
};

const updateExisting = async ({
  viewpointState,
  loadedState,
  viewSettings,
}: {
  viewpointState: SaveAsViewpointState;
  loadedState: LoadedState[];
  viewSettings: ViewpointSavedViewSettings;
}) => {
  const attributes = createViewpointAttributes({
    viewpointState,
    viewSettings,
  });
  const traversalId = viewpointState.appliedSettings.viewpointData?.traversalId;
  const existingViewpoint = traversalId
    ? traversalInterface.getById(traversalId)
    : null;
  if (
    !attributes ||
    !isSingleTraversalState(loadedState) ||
    !existingViewpoint
  ) {
    return null;
  }
  /**
   * dispatchActionAndWaitForResponse is used because setTraversalId needs to be dispatched after updateViewpointSuccess
   * has finished, otherwise the id will not have had time to be updated in the store. This can hopefully be removed when
   * we are no longer using Backbone
   */
  await dispatchActionAndWaitForResponse(
    updateViewpoint({
      ...attributes,
      _id: existingViewpoint._id,
      _version: existingViewpoint._version,
    }),
    updateViewpointSuccess,
    updateViewpointFailure
  );

  trackSavingViewpoint(
    getSaveViewpointTrackingData({
      appliedSettings: viewpointState.appliedSettings,
      viewName: viewpointState.mainViewId,
      saveAsNew: false,
      viewSettings,
    })
  );

  dispatchAction(
    setTraversalId({
      id: existingViewpoint._id,
      loadedStateHash: loadedStateOperations.stateToHash(loadedState[0]),
    })
  );
  dispatchAction(hideViewSidePanel());
  dispatchAction(resetSaveAsViewpoint());
};

export default connect(SaveAsViewpoint, viewModel$);
