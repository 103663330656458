import { TableMappingMap } from 'integrations/common/streams/tabularMappings/types';
import { TargetReferenceMapping } from '@ardoq/api-types/integrations';
import { ColumnMapping } from 'integrations/common/streams/transferConfigs/types';
import { Field } from 'integrations/common/streams/fields/types';
import { ReferenceFormat } from './ReferenceFormat';
import { ReferenceMissingComponentsStrategy } from './ReferenceMissingComponentsStrategy';
import { MappedColumnsErrors } from '../../streams/tabularMappingErrors/types';

type TargetReferenceProps = {
  tableMapping: TableMappingMap;
  columnMapping: TargetReferenceMapping;
  usedFields: Field[];
  allFields: Field[];
  columnName: string;
  onMapping: (columnMapping: Partial<ColumnMapping>) => void;
  isCreationDisabled: boolean;
  mappedColumnsErrors: MappedColumnsErrors;
};

export const TargetReference = ({
  tableMapping,
  columnMapping,
  usedFields,
  allFields,
  columnName,
  onMapping,
  isCreationDisabled,
  mappedColumnsErrors,
}: TargetReferenceProps) => {
  return (
    <>
      <ReferenceFormat
        tableMapping={tableMapping}
        columnMapping={columnMapping}
        usedFields={usedFields}
        allFields={allFields}
        onMapping={onMapping}
        columnName={columnName}
        isCreationDisabled={isCreationDisabled}
        mappedColumnsErrors={mappedColumnsErrors}
      />

      <ReferenceMissingComponentsStrategy
        tableMapping={tableMapping}
        columnMapping={columnMapping}
        usedFields={usedFields}
        allFields={allFields}
        onMapping={onMapping}
        columnName={columnName}
        isCreationDisabled={isCreationDisabled}
        isSource={false}
        workspaceId={tableMapping.targetWorkspace?.id}
      />
    </>
  );
};
