import styled from 'styled-components';
import { SearchOverviewStoredQueryModel } from 'search/types';
import { Icon, IconName } from '@ardoq/icons';
import NameWithMenuCell from 'atomicComponents/Table/NameWithMenuCell';
import { dispatchLoadStoredQuery } from './utils';
import { DropdownOption } from '@ardoq/dropdown-menu';
import { MatchGroups } from '@ardoq/common-helpers';

const IconWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: 4px;
  left: 5px;
  color: orange;
  cursor: default;
`;

type NameCellProps = {
  storedQuery: SearchOverviewStoredQueryModel & {
    meta?: { matchGroups: MatchGroups };
  };
  menuOptions?: Partial<DropdownOption>[];
};

const NameCell = ({ storedQuery, menuOptions }: NameCellProps) => (
  <NameWithMenuCell
    name={storedQuery.name || 'Missing name'}
    matchGroups={storedQuery.meta && storedQuery.meta.matchGroups}
    menuOptions={menuOptions}
    onClick={() => dispatchLoadStoredQuery(storedQuery)}
  >
    {storedQuery.warning && (
      <IconWrapper data-tooltip-text={storedQuery.warning}>
        <Icon iconName={IconName.WARNING} />
      </IconWrapper>
    )}
  </NameWithMenuCell>
);

export default NameCell;
