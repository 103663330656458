import { connect } from '@ardoq/rxbeach';
import styled from 'styled-components';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { s32 } from '@ardoq/design-tokens';
import { PopoverContentType } from '@ardoq/popovers';
import { header2 } from '@ardoq/typography';
import { Maybe } from '@ardoq/common-helpers';
import {
  CloudProviderRegion,
  SavedTransferConfig,
} from '@ardoq/api-types/integrations';
import { SelectConnections } from 'integrations/common/components/selectors/SelectConnections';
import { SelectConfigs } from 'integrations/common/components/selectors/SelectConfigs';
import { AzureConnection } from '@ardoq/api-types/integrations';
import { SelectRegions } from 'integrations/cloudProviders/components/selectors/SelectRegions';
import { viewModel$ } from './viewModel$';
import ResourceGroupSelector from 'integrations/azure/containers/resourceGroupSelector/ResourceGroupSelector';
import ResourceTypeSelector from 'integrations/azure/containers/resourceTypeSelector/ResourceTypeSelector';
import { SelectSubscriptions } from 'integrations/azure/containers/selectSubscriptions/SelectSubscriptions';
import { LabelWithPopoverIcon } from 'integrations/common/components/LabelWithPopoverIcon/LabelWithPopoverIcon';
import { ErrorNotification } from '@ardoq/status-ui';
import { Island } from '@ardoq/page-layout';
import { AutoFocus } from 'integrations/common/components/AutoFocus';

type SelectDataProps = {
  connections: AzureConnection[];
  regions: CloudProviderRegion[];
  selectedRegionIds: string[];
  selectedConnectionIds: string[];
  transferConfigs: SavedTransferConfig[];
  selectedTransferConfigId: Maybe<string>;
  onTransferConfigSelect: (configId: Maybe<SavedTransferConfig['_id']>) => void;
  onClearConfiguration: () => void;
  onConnectionSelect: (connectionIds: string[]) => void;
  onRegionsSelect: (regions: string[]) => void;
  selectConnectionPopoverContent: PopoverContentType;
  selectConnectionPlaceHolder: string;
  selectConnectionHelperText: string;
  selectConfigPopoverContent: PopoverContentType;
  selectConfigHelperText: string;
  errorMessage: Maybe<string>;
  selectRegionsLabel: string;
  selectRegionsHelperText: string;
};

const SelectDataComponent = ({
  connections,
  selectedConnectionIds,
  regions,
  selectedRegionIds,
  transferConfigs,
  selectedTransferConfigId,
  onTransferConfigSelect,
  onClearConfiguration,
  onConnectionSelect,
  onRegionsSelect,
  selectConnectionPlaceHolder,
  selectConnectionHelperText,
  selectConfigHelperText,
  selectConnectionPopoverContent,
  selectConfigPopoverContent,
  errorMessage,
  selectRegionsLabel,
  selectRegionsHelperText,
}: SelectDataProps) => {
  return (
    <Container>
      <SelectConfigs
        transferConfigs={transferConfigs}
        selectedConfigId={selectedTransferConfigId}
        onSelect={onTransferConfigSelect}
        onClearConfiguration={onClearConfiguration}
        selectConfigHelperText={selectConfigHelperText}
        popoverContent={selectConfigPopoverContent}
      />
      <IslandContainer>
        <Island maxWidth="100%" footerJustify="end">
          <Stack gap="xlarge">
            {errorMessage && (
              <AutoFocus>
                <ErrorNotification>
                  <span>{errorMessage}</span>
                </ErrorNotification>
              </AutoFocus>
            )}
            <SelectConnections
              connections={connections}
              selectedConnectionIds={selectedConnectionIds}
              onSelect={onConnectionSelect}
              selectConnectionHelperText={selectConnectionHelperText}
              popoverContent={selectConnectionPopoverContent}
              selectConnectionPlaceHolder={selectConnectionPlaceHolder}
            />
            <SelectRegions
              regions={regions.map(({ displayName, id }) => ({
                label: displayName,
                value: id,
              }))}
              onSelect={onRegionsSelect}
              selectedRegionIds={selectedRegionIds}
              isDisabled={selectedConnectionIds.length === 0}
              label={selectRegionsLabel}
              helperText={selectRegionsHelperText}
            />
            <Stack gap="small">
              <StepLabel isDisabled={selectedConnectionIds.length === 0}>
                3. Select subscriptions
              </StepLabel>
              <SelectSubscriptions />
            </Stack>
            <Stack gap="small">
              <StepLabel isDisabled={selectedConnectionIds.length === 0}>
                4. Select resource groups
              </StepLabel>
              <FlexBox gap="medium">
                <ResourceGroupSelector />
                <ResourceTypeSelector />
              </FlexBox>
            </Stack>
          </Stack>
        </Island>
      </IslandContainer>
    </Container>
  );
};

const StepLabel = styled(LabelWithPopoverIcon)`
  ${header2};
`;

const IslandContainer = styled.div`
  padding: ${s32};
  width: 100%;
`;

const Container = styled.div`
  overflow: auto;
  padding-bottom: ${s32};
`;

export const SelectData = connect(SelectDataComponent, viewModel$);
