import { APIWorkspaceOverview, ArdoqId, SortOrder } from '@ardoq/api-types';
import { reducedStream, reducer } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import {
  cancelDeletion,
  clearSelectedCheckboxes,
  closeActionsMenu,
  fetchWorkspaceOverview,
  fetchWorkspaceOverviewSuccess,
  openActionsMenu,
  requestDeletion,
  searchQueryChange,
  selectAllWorkspaces,
  setGremlinForIdsSuccess,
  setPage,
  shiftClickRow,
  sortOrderChange,
  toggleCheckbox,
  toggleTitleCheckbox,
  websocketFakerDeletedWorkspaces,
} from './actions';
import { toWorkspaceOverviewManagementProps } from './mappers';
import {
  WorkspaceOverviewManagementColumnName,
  WorkspaceOverviewManagementRowState,
  WorkspaceOverviewManagementState,
} from './types';
import { getCurrentPage, getTableSelection, selectRange } from './utils';
import { WorkspaceOverviewManagement } from './WorkspaceOverviewManagement';
import { lazyConnect } from 'streams/utils/streamUtils';

const handleFetchedData = (
  state: WorkspaceOverviewManagementState,
  data: APIWorkspaceOverview[]
) => ({
  ...state,
  rows: data.map(row => ({
    id: row._id,
    selected: false,
    name: row.name,
    componentCount: row.componentCount,
    referenceCount: row.referenceCount,
    lastUpdatedAt: row.lastUpdated ?? '',
    gremlinEnabled: row.gremlinEnabled,
    isActionsOpen: false,
    deleteRequested: false,
  })),
});

const unselectAllRows = (rows: WorkspaceOverviewManagementRowState[]) =>
  rows.map(row => ({ ...row, selected: false }));

const setCheckboxesNotOnPageToFalse = (
  rows: WorkspaceOverviewManagementRowState[],
  currentPageIds: ArdoqId[]
) =>
  rows.map(row => ({
    ...row,
    selected: currentPageIds.includes(row.id) ? row.selected : false,
  }));

const uncheckCheckboxesNotOnCurrentPage = (
  state: WorkspaceOverviewManagementState
) => ({
  ...state,
  rows: setCheckboxesNotOnPageToFalse(
    state.rows,
    getCurrentPage(state).map(row => row.id)
  ),
});

const handleSelectAll = (
  state: WorkspaceOverviewManagementState
): WorkspaceOverviewManagementState => ({
  ...state,
  previouslyClickedId: null,
  rows: state.rows.map(row => ({ ...row, selected: true })),
});

const toggleCurrentCheckbox = (
  state: WorkspaceOverviewManagementState,
  currentId: ArdoqId
) => ({
  ...state,
  previouslyClickedId: currentId,
  rows: state.rows.map(row =>
    row.id === currentId ? { ...row, selected: !row.selected } : row
  ),
});

const setIdsTrue = (
  rows: WorkspaceOverviewManagementRowState[],
  ids: ArdoqId[]
) =>
  rows.map(row => ({
    ...row,
    selected: ids.includes(row.id) ? true : row.selected,
  }));

const handleShiftClick = (
  state: WorkspaceOverviewManagementState,
  id: ArdoqId
) => {
  if (!state.previouslyClickedId) {
    return handleToggledCheckbox(state, id);
  }
  const affectedRows = selectRange(
    getCurrentPage(state),
    state.previouslyClickedId,
    id
  );
  const idsToSelect = affectedRows.map(row => row.id);
  return {
    ...state,
    rows: setIdsTrue(state.rows, idsToSelect),
    previouslyClickedId: id,
  };
};

const handleToggleTitleCheckbox = (state: WorkspaceOverviewManagementState) => {
  if (getTableSelection(state.rows) === 'none') {
    const rowIdsToToggle = getCurrentPage(state).map(row => row.id);
    return {
      ...state,
      previouslyClickedId: null,
      rows: setIdsTrue(state.rows, rowIdsToToggle),
    };
  }
  return clearAllSelectedCheckboxes(state);
};

const handleToggledCheckbox = (
  state: WorkspaceOverviewManagementState,
  id: ArdoqId
) => ({
  ...state,
  ...uncheckCheckboxesNotOnCurrentPage(toggleCurrentCheckbox(state, id)),
});

const resetTableView = (state: WorkspaceOverviewManagementState) => ({
  ...state,
  rows: unselectAllRows(state.rows),
  currentPageNumber: 1,
});

const handleSetPage = (
  state: WorkspaceOverviewManagementState,
  props: { pageNumber: number; pageSize: number }
) => ({
  ...resetTableView(state),
  currentPageNumber: props.pageNumber,
  rowsPerPage: props.pageSize,
});

const handleSearchQueryChange = (
  state: WorkspaceOverviewManagementState,
  searchQuery: string
) => ({
  ...resetTableView(state),
  searchQuery,
});

const clearAllSelectedCheckboxes = (
  state: WorkspaceOverviewManagementState
): WorkspaceOverviewManagementState => ({
  ...state,
  previouslyClickedId: null,
  rows: state.rows.map(row => ({ ...row, selected: false })),
});

const handleSortOrderChange = (
  state: WorkspaceOverviewManagementState,
  column: WorkspaceOverviewManagementColumnName
) => ({
  ...state,
  columns: {
    ...state.columns,
    ...Object.fromEntries(
      Object.entries(state.columns).map(([key, value]) => {
        return [
          key,
          {
            ...value,
            sortOrder:
              key === column
                ? value.sortOrder === SortOrder.ASC
                  ? SortOrder.DESC
                  : SortOrder.ASC
                : null,
          },
        ];
      })
    ),
  },
});

const handleDeletedWorkspaces = (
  state: WorkspaceOverviewManagementState,
  deletedIds: ArdoqId[]
) => {
  return {
    ...state,
    rows: state.rows.filter(row => !deletedIds.includes(row.id)),
  };
};

const handleSetGremlinForIds = (
  state: WorkspaceOverviewManagementState,
  props: { workspaceIds: ArdoqId[]; gremlinEnabled: boolean }
) => {
  return {
    ...state,
    rows: state.rows.map(row =>
      props.workspaceIds.includes(row.id)
        ? { ...row, gremlinEnabled: props.gremlinEnabled }
        : row
    ),
  };
};

const handleOpenActionsMenu = (
  state: WorkspaceOverviewManagementState,
  id: ArdoqId
) => setIsActionsOpen(state, id, true);

const handleCloseActionsMenu = (
  state: WorkspaceOverviewManagementState,
  id: ArdoqId
) => setIsActionsOpen(state, id, false);

const setIsActionsOpen = (
  state: WorkspaceOverviewManagementState,
  id: ArdoqId,
  value: boolean
) => ({
  ...state,
  rows: state.rows.map(row =>
    row.id === id ? { ...row, isActionsOpen: value } : row
  ),
});

const handleRequestDeletion = (
  state: WorkspaceOverviewManagementState,
  ids: string[]
) => ({
  ...state,
  rows: state.rows.map(row =>
    ids.includes(row.id) ? { ...row, deleteRequested: true } : row
  ),
});

const handleCancelDeletion = (state: WorkspaceOverviewManagementState) => ({
  ...state,
  rows: state.rows.map(row => ({ ...row, deleteRequested: false })),
});

const initialState: WorkspaceOverviewManagementState = {
  searchQuery: '',
  columns: {
    name: {
      id: 'name',
      label: 'Name',
      sortOrder: null,
    },
    componentCount: {
      id: 'componentCount',
      label: 'Component count',
      sortOrder: SortOrder.DESC,
    },
    referenceCount: {
      id: 'referenceCount',
      label: 'Reference count',
      sortOrder: null,
    },
    gremlinEnabled: {
      id: 'gremlinEnabled',
      label: 'Gremlin',
      sortOrder: null,
    },
    lastUpdatedAt: {
      id: 'lastUpdatedAt',
      label: 'Last updated',
      sortOrder: null,
    },
  },
  rows: [],
  currentPageNumber: 1,
  rowsPerPage: 25,
  previouslyClickedId: null,
};

const unpreparedWorkspaceManagement$ = reducedStream(
  'unpreparedWorkspaceManagement$',
  initialState,
  [
    reducer(fetchWorkspaceOverviewSuccess, handleFetchedData),
    reducer(toggleCheckbox, handleToggledCheckbox),
    reducer(toggleTitleCheckbox, handleToggleTitleCheckbox),
    reducer(clearSelectedCheckboxes, clearAllSelectedCheckboxes),
    reducer(sortOrderChange, handleSortOrderChange),
    reducer(setPage, handleSetPage),
    reducer(searchQueryChange, handleSearchQueryChange),
    reducer(setGremlinForIdsSuccess, handleSetGremlinForIds),
    reducer(selectAllWorkspaces, handleSelectAll),
    reducer(openActionsMenu, handleOpenActionsMenu),
    reducer(closeActionsMenu, handleCloseActionsMenu),
    reducer(shiftClickRow, handleShiftClick),
    reducer(requestDeletion, handleRequestDeletion),
    reducer(cancelDeletion, handleCancelDeletion),
    /**
     * This will either be removed or renamed depending on whether or not we send websocket events for deleted workspaces
     */
    reducer(websocketFakerDeletedWorkspaces, handleDeletedWorkspaces),
  ]
);

const workspaceManagement$ = unpreparedWorkspaceManagement$.pipe(
  map(toWorkspaceOverviewManagementProps)
);

export const WorkspaceOverview = lazyConnect(
  WorkspaceOverviewManagement,
  workspaceManagement$,
  fetchWorkspaceOverview
);
