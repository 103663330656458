import Components from 'collections/components';
import References from 'collections/references';
import { getCurrentLocale, localeCompareNumericLowercase } from '@ardoq/locale';
import { logError, logWarn } from '@ardoq/logging';
import { ComponentBackboneModel, Reference } from 'aqTypes';
import { ModelType } from 'models/ModelType';
import { uniqBy } from 'lodash';
import {
  APIComponentType,
  APIModelAttributes,
  APIReferenceType,
  ArdoqId,
  NamedEntity,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { QuickPerspectivesContext } from './legends/types';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { modelInterface } from 'modelInterface/models/modelInterface';

const hitsComponent = (reference: Reference, componentIdSet: Set<string>) =>
  componentIdSet.has(reference.getSourceId()) ||
  componentIdSet.has(reference.getTargetId());

const hitsWorkspace = ({
  reference,
  workspaceIds,
  hitsBothEnds,
}: {
  reference: Reference;
  workspaceIds: Set<string>;
  hitsBothEnds: boolean;
}) => {
  const includesSource = workspaceIds.has(reference.get('rootWorkspace'));
  const includesTarget = workspaceIds.has(reference.get('targetWorkspace'));

  if (hitsBothEnds) {
    return includesSource && includesTarget;
  }
  return includesSource || includesTarget;
};

const nameToLowerCase = ({ id, name }: APIReferenceType | APIComponentType) => {
  if (name === null || name === undefined) {
    logError(Error('Unnamed reference or component type.'), null, { id });
  }
  return name?.toLowerCase();
};

const addComponentType = (
  component: ComponentBackboneModel | null,
  componentTypes: Map<string, APIComponentType>,
  workspaceIdsWithAllTypes: Set<string>
) => {
  if (!component) {
    return;
  }
  const { rootWorkspace, type } = component.attributes;
  if (workspaceIdsWithAllTypes.has(rootWorkspace) || componentTypes.has(type)) {
    return;
  }
  const componentType = component.getMyType() as ModelType;
  componentTypes.set(componentType.name, componentType);
};
interface GetQuickPerspectivesOptionsArgs {
  contextWorkspaceId: ArdoqId | null;
  componentIdSet: Set<string>;
  openWorkspaceIds: ArdoqId[];
  selectedWorkspacesIds: string[];
  connectedWorkspaceIds: string[] | Set<string>;
}
export const getQuickPerspectivesOptions = ({
  contextWorkspaceId,
  componentIdSet,
  openWorkspaceIds,
  selectedWorkspacesIds = [],
  connectedWorkspaceIds = [],
}: GetQuickPerspectivesOptionsArgs): QuickPerspectivesContext => {
  const workspaceIds = new Set(connectedWorkspaceIds);
  const referenceTypes = new Map<string, APIReferenceType>();
  const componentTypes = new Map<string, APIComponentType>();
  const borderingComponentTypes = new Set<string>();

  if (!openWorkspaceIds.length) {
    return {
      contextWorkspaceId,
      workspaces: [],
      referenceTypes: [],
      componentTypes: [],
      borderingComponentTypes,
      includedWorkspaceIds: [],
      outsideWorkspaces: [],
    };
  }

  const hasSelectedWorkspaces = !!selectedWorkspacesIds.length;
  type ReducerState = {
    workspaceIdsWithAllTypes: Set<string>;
    workspaceModels: APIModelAttributes[];
  };
  const { workspaceIdsWithAllTypes, workspaceModels } =
    openWorkspaceIds.reduce<ReducerState>(
      (state, workspaceId) => {
        if (
          hasSelectedWorkspaces &&
          !selectedWorkspacesIds.includes(workspaceId)
        ) {
          return state;
        }
        state.workspaceIdsWithAllTypes.add(workspaceId);
        const model = workspaceInterface.getModelData(workspaceId);
        if (!model) {
          logWarn(Error('Open workspace is missing a model'), null, {
            workspaceId: workspaceId,
          });
          return state;
        }
        state.workspaceModels.push(model);
        return state;
      },
      { workspaceIdsWithAllTypes: new Set(), workspaceModels: [] }
    );

  workspaceModels.forEach(model => {
    Object.values(modelInterface.getComponentTypes(model._id)).forEach(
      componentType => {
        componentTypes.set(componentType.name, componentType);
      }
    );

    Object.values(modelInterface.getReferenceTypes(model._id)).forEach(
      referenceType => {
        referenceTypes.set(referenceType.name, referenceType);
      }
    );
  });

  const checkWsHitId = new Set(
    hasSelectedWorkspaces ? selectedWorkspacesIds : openWorkspaceIds
  );
  References.collection.forEach(reference => {
    const source = reference.getSource();
    const target = reference.getTarget();

    if (
      hitsWorkspace({
        reference,
        workspaceIds: checkWsHitId,
        hitsBothEnds: hasSelectedWorkspaces,
      })
    ) {
      workspaceIds.add(reference.attributes.rootWorkspace);
      workspaceIds.add(reference.attributes.targetWorkspace);
      if (!workspaceIdsWithAllTypes.has(reference.attributes.rootWorkspace)) {
        const referenceType = reference.getModelType();
        referenceTypes.set(referenceType.name, referenceType);
      }

      [source, source.getParent(), target, target.getParent()]
        .filter(ExcludeFalsy)
        .forEach(component =>
          addComponentType(component, componentTypes, workspaceIdsWithAllTypes)
        );
    }
    if (hitsComponent(reference, componentIdSet)) {
      borderingComponentTypes.add(source.get('type'));
      borderingComponentTypes.add(target.get('type'));
    }
  });

  componentIdSet.forEach(componentId => {
    const component = Components.collection.get(componentId);
    if (!component) {
      return;
    }
    const { rootWorkspace } = component.attributes;
    if (workspaceIdsWithAllTypes.has(rootWorkspace)) {
      return;
    }
    workspaceIds.add(component.attributes.rootWorkspace);
    [component, component.getParent(), ...component.getChildren()].forEach(
      component =>
        addComponentType(component, componentTypes, workspaceIdsWithAllTypes)
    );
  });

  const idToNamed = (_id: ArdoqId): NamedEntity => ({
    _id,
    name: workspaceInterface.getWorkspaceName(_id)!,
  });

  const locale = getCurrentLocale();
  const allWorkspaces =
    Array.from(workspaceIds)
      .filter(workspaceInterface.workspaceExists)
      .map(idToNamed)
      .sort((a, b) => localeCompareNumericLowercase(a.name, b.name, locale)) ||
    [];
  const includedWorkspaceIds = selectedWorkspacesIds;
  const outsideWorkspaces = includedWorkspaceIds
    .filter(
      (workspaceId: string) => !allWorkspaces.find(ws => workspaceId === ws._id)
    )
    .map(idToNamed);

  return {
    contextWorkspaceId,
    workspaces: allWorkspaces,

    referenceTypes:
      uniqBy(Array.from(referenceTypes.values()), nameToLowerCase).sort(
        (a, b) => localeCompareNumericLowercase(a.name, b.name, locale)
      ) || [],
    componentTypes:
      uniqBy(Array.from(componentTypes.values()), nameToLowerCase).sort(
        (a, b) => localeCompareNumericLowercase(a.name, b.name, locale)
      ) || [],
    borderingComponentTypes: borderingComponentTypes,
    includedWorkspaceIds,
    outsideWorkspaces,
  };
};
