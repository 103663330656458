import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  clearAllFilters,
  fetchAuditLogData,
  fetchNextPage,
  fetchPreviousPage,
  restoreChangeComponent,
  restoreChangeError,
  RestoreChangePayload,
  restoreChangeReference,
  restoreChangeSuccess,
  setAPIError,
  setAuditLogData,
  setAuditLogFilter,
  setAuditLogPageSize,
  setChangeTypeFilterGroups,
  setChangeTypeFilterSpecifics,
  setLoadingState,
} from './actions';
import { switchMap, tap, withLatestFrom } from 'rxjs';
import { navigateToAuditLog } from 'router/navigationActions';
import auditLog$, {
  handleSetChangeTypeFilterGroups,
  handleSetChangeTypeFilterSpecifics,
} from './auditLog$';
import {
  emptyChangeTypeFilterState,
  emptyFilterState,
  LoadingState,
} from './consts';
import { FilterState } from './types';
import { mergePayloadAndStateForAPIRequest } from './utils';
import { trackEvent } from '../tracking/tracking';
import { AuditLogTracking, trackFilterUsage } from './tracking';
import {
  auditLogApi,
  componentApi,
  handleError,
  referenceApi,
} from '@ardoq/api';
import { showToast, ToastType } from '@ardoq/status-ui';
import { logError } from '@ardoq/logging';
import { APIEntityType, auditLogEntityTypesList } from '@ardoq/api-types';
import {
  isArdoqError,
  ArdoqError,
  getArdoqErrorMessage,
  getArdoqErrorTraceId,
} from '@ardoq/common-helpers';

const defaultAuditLogPage = { pageNumber: 1, size: 25 };

const handleNavigateToAuditLog = routine(
  ofType(navigateToAuditLog),
  extractPayload(),
  tap(payload => {
    if (
      payload !== null &&
      [
        APIEntityType.COMPONENT,
        APIEntityType.REFERENCE,
        APIEntityType.WORKSPACE,
      ].includes(payload.entityType)
    ) {
      const entityTypeFilter: Pick<FilterState, 'entityTypes'> = {
        entityTypes: {
          ...emptyChangeTypeFilterState,
          [payload.entityType]: payload.entities.map(({ id, name }) => ({
            label: name,
            value: id,
          })),
          ...(payload.entityType === APIEntityType.COMPONENT
            ? // should include comp + it's references history
              { [APIEntityType.REFERENCE]: [] }
            : {}),
        },
      };
      dispatchAction(setAuditLogFilter(entityTypeFilter));
    }
  })
);

const handleSetAuditLogPageSize = routine(
  ofType(setAuditLogPageSize),
  extractPayload(),
  tap(pageSize => {
    dispatchAction(
      fetchAuditLogData({ ...defaultAuditLogPage, size: pageSize })
    );
    trackEvent(AuditLogTracking.SET_PAGE_SIZE, { pageSize });
  })
);

const fetchOnSetChangeTypeFilterGroups = routine(
  ofType(setChangeTypeFilterGroups),
  extractPayload(),
  withLatestFrom(auditLog$),
  tap(([payload, state]) => {
    const { filters } = handleSetChangeTypeFilterGroups(state, payload);
    trackFilterUsage({ entityTypes: filters.entityTypes });
    const entityTypesUsedInFilter = auditLogEntityTypesList.filter(
      entityType => filters.entityTypes[entityType] !== null
    );

    if (entityTypesUsedInFilter.length)
      trackEvent(
        AuditLogTracking.CHANGE_TYPE_FILTER,
        entityTypesUsedInFilter.reduce(
          (acc, entityType) => ({ ...acc, [entityType]: true }),
          {}
        )
      );
    dispatchAction(
      fetchAuditLogData({
        filters,
      })
    );
  })
);

const fetchOnSetChangeTypeFilterSpecifics = routine(
  ofType(setChangeTypeFilterSpecifics),
  extractPayload(),
  withLatestFrom(auditLog$),
  tap(([payload, state]) => {
    const { filters } = handleSetChangeTypeFilterSpecifics(state, payload);
    trackFilterUsage({ entityTypes: filters.entityTypes });
    dispatchAction(
      fetchAuditLogData({
        filters,
      })
    );
  })
);

const handleFetchPreviousPage = routine(
  ofType(fetchPreviousPage),
  withLatestFrom(auditLog$),
  tap(
    ([
      _,
      {
        paginationState: { pageSize, pageState },
      },
    ]) => {
      trackEvent(AuditLogTracking.SWITCH_PAGE, {
        previous: true,
      });
      dispatchAction(
        fetchAuditLogData({
          pageNumber: pageState?.pageNumber ? pageState.pageNumber - 1 : 1,
          size: pageSize,
          pageState,
        })
      );
    }
  )
);

const handleFetchNextPage = routine(
  ofType(fetchNextPage),
  withLatestFrom(auditLog$),
  tap(
    ([
      _,
      {
        paginationState: { pageSize, pageState },
      },
    ]) => {
      trackEvent(AuditLogTracking.SWITCH_PAGE, {
        next: true,
      });
      dispatchAction(
        fetchAuditLogData({
          pageNumber: pageState?.pageNumber ? pageState.pageNumber + 1 : 1,
          size: pageSize,
          pageState,
        })
      );
    }
  )
);

const handleSetAuditLogFilter = routine(
  ofType(setAuditLogFilter),
  extractPayload(),
  tap(filters => {
    if ('name' in filters) {
      trackEvent(AuditLogTracking.NAME_SEARCH, {
        searchStringLength: filters.name.length,
      });
    } else {
      trackFilterUsage(filters);
    }
    dispatchAction(
      fetchAuditLogData({
        filters,
      })
    );
  })
);

const handleClearAllFilters = routine(
  ofType(clearAllFilters),
  tap(() => dispatchAction(fetchAuditLogData({ filters: emptyFilterState })))
);

const handleFetchAuditLogData = routine(
  ofType(fetchAuditLogData),
  extractPayload(),
  // TODO [audit log improvements] remove withLatestFrom
  withLatestFrom(auditLog$),
  tap(() => dispatchAction(setLoadingState(LoadingState.LOADING))),
  switchMap(([payload, state]) => {
    return auditLogApi.fetchChangeLog(
      mergePayloadAndStateForAPIRequest(payload, state)
    );
  }),
  handleError(error => {
    dispatchAction(setLoadingState(LoadingState.LOADED));
    if (isArdoqError(error)) {
      dispatchAction(
        setAPIError({
          message: getArdoqErrorMessage(error),
          traceId: getArdoqErrorTraceId(error),
        })
      );
    }
  }),
  tap(response => {
    dispatchAction(setLoadingState(LoadingState.LOADED));
    dispatchAction(setAuditLogData(response));
  })
);

const handleRestoreChangeReference = routine(
  ofType(restoreChangeReference),
  extractPayload(),
  tap(async payload => {
    const result = await referenceApi.restore(payload);
    if (isArdoqError(result)) {
      logErrorAndDispatchAction(result, payload);
      return;
    }
    const { entityId, fieldName, restorableValue } = payload;
    showToast('Data restored', ToastType.SUCCESS);
    dispatchAction(
      restoreChangeSuccess({
        entityId,
        fieldName,
        restorableValue,
      })
    );
  })
);

const handleRestoreChangeComponent = routine(
  ofType(restoreChangeComponent),
  extractPayload(),
  tap(async payload => {
    const result = await componentApi.restore(payload);
    if (isArdoqError(result)) {
      logError(result);
      return;
    }
    const { entityId, fieldName, restorableValue } = result;
    showToast('Data restored', ToastType.SUCCESS);
    dispatchAction(
      restoreChangeSuccess({
        entityId,
        fieldName,
        restorableValue,
      })
    );
  })
);

const logErrorAndDispatchAction = (
  error: ArdoqError,
  data: RestoreChangePayload
) => {
  const { entityId, fieldName, restorableValue } = data;
  const traceId = getArdoqErrorTraceId(error);
  logError(error, 'Failed to restore change in audit log', { traceId });
  dispatchAction(
    restoreChangeError({
      entityId,
      fieldName,
      restorableValue,
      errorTraceId: traceId,
    })
  );
};

export default collectRoutines(
  fetchOnSetChangeTypeFilterGroups,
  fetchOnSetChangeTypeFilterSpecifics,
  handleNavigateToAuditLog,
  handleSetAuditLogPageSize,
  handleFetchPreviousPage,
  handleFetchNextPage,
  handleFetchAuditLogData,
  handleSetAuditLogFilter,
  handleClearAllFilters,
  handleRestoreChangeReference,
  handleRestoreChangeComponent
);
