import { trackEvent } from 'tracking/tracking';

export enum DIVISIONS_USER_EVENTS {
  // Select Components page events
  COMPONENTS_SELECTION_MODE = 'selected components selection mode',
  ADVANCED_SEARCH_EDITED = 'edited advanced search',
  ADVANCED_SEARCH_SEARCHED = 'searched by advanced search query',
  CHANGED_SEARCH_RESULTS_TAB = 'changed search results tab',
  SELECTED_COMPONENTS_TO_ADD_TO_DIVISION = 'selected components to add to division',
  SELECTED_COMPONENTS_TO_REMOVE_FROM_DIVISION = 'selected components to remove from division',
  BIND_WORKSPACES_SELECTION = 'selected workspaces to bind to division',
  // Overview page events
  DELETE_DIVISION = 'deleted division',
  OPEN_DELETE_DIVISION = 'clicked delete division option',
  // General division Editor events
  OPEN_EDITOR = 'opened division editor',
  SAVE_CHANGES = 'saved editor changes',
  CREATED_NEW_DIVISION = 'created new division',
  CLICKED_NEXT_STEP = 'clicked next step button',
  SELECTED_EDITOR_STEP = 'selected editor step',
  // Details page events
  CHANGED_DIVISION_NAME = 'changed division name',
  CHANGED_DIVISION_COLOR = 'changed division color',
  CHANGED_DIVISION_DESCRIPTION = 'changed division description',
  // Configure permissions page events
  ADD_GROUP_TO_DIVISION = 'added group to division',
  REMOVE_GROUP_FROM_DIVISION = 'removed group from division',
  CHANGE_GROUP_ZONE = 'changed group field configuration',
  CHANGE_ZONE_GROUP_PERMISSION = 'changed field group permission',
  OPENED_CREATE_NEW_ZONE_MODAL = 'opened create new field group modal',
  CREATE_NEW_ZONE = 'created new field group',
  // Field permissions page events
  CLICKED_CHANGE_ZONE_NAME = 'clicked change field group name',
  CHANGED_ZONE_NAME = 'changed field group name', // Not documented yet
  CLICKED_DELETE_ZONE = 'clicked delete field group',
  DELETED_ZONE = 'deleted field group',
  SEARCH_BY_COMPONENT_TYPE = 'searched by component type',
  SEARCH_BY_FIELD_NAME = 'searched by field name',
  CLICKED_FIELD_SELECTION_IN_A_ZONE = 'clicked field selection in a field group',
  CLICKED_SELECT_ALL_FIELDS = 'clicked select all fields',
  CLICKED_DESELECT_ALL_FIELDS = 'clicked deselect all fields',
  // Division membership modal events
  OPENED_DIVISION_MEMBERSHIP_MODAL = 'opened division membership modal',
  SELECTED_DIVISIONS_TO_REMOVE_FROM_COMPONENTS = 'selected divisions to remove from components',
  SELECTED_DIVISIONS_TO_ADD_TO_COMPONENTS = 'selected divisions to add to components',
  SAVED_COMPONENTS_DIVISION_MEMBERSHIPS = 'saved components division memberships',
  CANCEL_DIVISION_MEMBERSHIP_CHANGES = 'cancelled division membership changes',
  SELECTED_COMPONENTS_TO_CHANGE_MEMBERSHIPS = 'selected components to change memberships',
}

export const trackDivisionEditorUserEvent = <T extends object>(
  name: DIVISIONS_USER_EVENTS,
  metadata?: T
) => trackEvent(`Divisions editor0 ${name}`, metadata);

export const trackDivisionMembershipUserEvent = <T extends object>(
  name: DIVISIONS_USER_EVENTS,
  metadata?: T
) => trackEvent(`Division Membership Modal0 ${name}`, metadata);
