import styled from 'styled-components';
import surveyNotUpdatedSinceCondition$ from './surveyNotUpdatedSinceCondition$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { Checkbox } from '@ardoq/forms';
import { TimeSinceLastUpdate } from '@ardoq/api-types';
import { Select } from '@ardoq/select';
import { SurveyNotUpdatedSinceConditionStreamShape } from './types';
import {
  chooseSurveyContentTimeSinceLastUpdate,
  toggleSurveyNotUpdatedSinceCondition,
} from './actions';
import { s8 } from '@ardoq/design-tokens';

const Wrapper = styled.div`
  display: flex;
  gap: ${s8};
  align-items: center;
`;

const Container = styled.div`
  flex-basis: 150px;
`;

const periodOptions = [
  { value: TimeSinceLastUpdate.ONE_MONTH, label: '1 month' },
  { value: TimeSinceLastUpdate.THREE_MONTHS, label: '3 months' },
  { value: TimeSinceLastUpdate.SIX_MONTHS, label: '6 months' },
  { value: TimeSinceLastUpdate.TWELVE_MONTHS, label: '1 year' },
];

const SurveyNotUpdatedSinceCondition = ({
  isEnabled,
  surveyNotUpdatedSinceCondition,
}: SurveyNotUpdatedSinceConditionStreamShape) => {
  const { timeSinceLastUpdate } = surveyNotUpdatedSinceCondition;
  return (
    <Wrapper>
      <Checkbox
        isChecked={isEnabled}
        onChange={() => dispatchAction(toggleSurveyNotUpdatedSinceCondition())}
      >
        Survey has not been updated for
      </Checkbox>
      <Container>
        <Select
          dataTestId="period-select"
          placeholder="Please select a period"
          options={periodOptions}
          isDisabled={!isEnabled}
          value={
            periodOptions.find(({ value }) => value === timeSinceLastUpdate) ??
            null
          }
          onChange={option => {
            if (!option) return;
            dispatchAction(
              chooseSurveyContentTimeSinceLastUpdate(
                option.value as TimeSinceLastUpdate
              )
            );
          }}
        />
      </Container>
    </Wrapper>
  );
};

export default connect(
  SurveyNotUpdatedSinceCondition,
  surveyNotUpdatedSinceCondition$
);
