import { ArdoqId } from '@ardoq/api-types';
import { createContext } from 'react';

export interface WsInfo {
  id: ArdoqId;
  name: string;
  description: string;
  componentTypes: string[];
  referenceTypes: string[];
}

export const WorkspaceSelectorContext = createContext<WsInfo | null>(null);
