import { ViewSettings } from 'viewSettings/viewSettingsStreams';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { settingsBarConsts } from '@ardoq/settings-bar';

const VIEW_SETTING_TO_IGNORE_GLOBALLY = [settingsBarConsts.IS_LEGEND_ACTIVE];
/**
 * Some actions should be ignored in the viewSettings$ stream.
 * However, do not filter the initial state--
 * if you, for example, collapse a group, change views, and change back,
 * the first value in the stream will be the toggle collapse action,
 * and filtering this would cause combineLatest to stall while waiting for the next value.
 * @returns a stream without the update, if the changedSettings index > 0 && changedSettings.length === 0 && only the ignored keys are the update.
 */
export const getViewSettingsFilter =
  <T extends Record<string, any>>(
    ...keysToIgnore: (keyof T | typeof settingsBarConsts.FULLSCREEN_KEY)[]
  ) =>
  ({ changedSettings }: ViewSettings<T>, index: number) =>
    index !== 0 &&
    changedSettings.length !== 0 &&
    !changedSettings.some(
      changedSetting => !keysToIgnore.includes(changedSetting[0])
    );

export const getFilteredViewSettings$ = <T extends Record<string, any>>(
  viewSettings$: Observable<ViewSettings<T>>,
  keysToIgnore: (keyof T)[] = []
) => {
  const ignoreViewSettingsChange = getViewSettingsFilter(
    ...((VIEW_SETTING_TO_IGNORE_GLOBALLY ?? []) as (keyof T)[]),
    ...keysToIgnore
  );
  return viewSettings$.pipe(
    filter(
      (viewSettings, index) => !ignoreViewSettingsChange(viewSettings, index)
    )
  );
};
