import { createElement, Component, memo } from 'react';
import { Subscription } from 'rxjs';
import { filter, map, scan, tap } from 'rxjs/operators';
import {
  clearSubscriptions,
  subscribeToAction,
} from 'streams/utils/streamUtils';
import getMetaInfo from 'views/metaInfo';
import ContentView from 'streams/views/mainContent/contentView.jsx';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  MAIN_PANE_LEFT,
  MAIN_PANE_RIGHT,
  PaneLocation,
} from 'streams/views/mainContent/types';
import { toUniqueClass } from 'streams/utils/reactUtils';
import {
  mainPaneCreated,
  renderMainContentView,
  viewChangeError,
  viewLoaded,
} from 'streams/views/mainContent/actions';
import {
  MetaInfo,
  PaneInterface,
  ViewInterface,
  ViewMapEntry,
} from 'streams/views/mainContent/types';
import { viewChanged } from 'streams/views/mainContent/actions';
import PaneViewInterface from 'streams/views/mainContent/PaneViewInterface';
import { ViewIds } from '@ardoq/api-types';
import { readViewComponentIds } from 'streams/viewComponentIds$';
import { MainPaneLoaderConnected } from 'streams/views/mainContent/MainPaneLoader';
import { FullscreenManager } from '@ardoq/fullscreen-manager';
import { TooltipManager } from '@ardoq/tooltip';
import { PopoverManager } from '@ardoq/popovers';
import {
  EmbeddableViewConfig,
  InstantiableReactViewProps,
} from 'tabview/types';
import { getTabOptionsStreamWithLocation } from './getTabOptionsStreamWithLocation';
import DiscontinuedViewReplacement from 'viewDeprecation/DiscontinuedViewReplacement';
import { isViewDiscontinued } from 'viewDeprecation/restrictedViews';
import BrokenImagePopoverSubsciption from 'components/popover/brokenImagePopover';
import { isPresentationMode } from '../appConfig';
import ConditionalFormattingImageColorFilters from 'views/ConditionalFormattingImageColorFilters';
import {
  getConnectedViewPaneSettingsBar,
  hasViewPaneSettingsBar,
} from './viewPaneSettingsBar/ViewPaneSettingsBar';
import React from 'react';
import {
  getUniqueSettingsControlsNamespace,
  ViewPaneSettingsControlsIdContext,
} from '@ardoq/view-settings';
import { SettingsControlsMapEntry } from './viewPaneSettingsBar/types';
import { returnNull } from '@ardoq/common-helpers';

if (module.hot) {
  module.hot.accept('views/metaInfo');
}

interface MainViewOwnProps {
  location: PaneLocation;
  isViewpointMode?: boolean;
}

interface MainViewStreamedProps {
  activeTabId: ViewIds | null;
}

type MainViewProps = MainViewOwnProps & MainViewStreamedProps;

export class MainView
  extends Component<MainViewProps, unknown>
  implements PaneInterface
{
  static createView(
    { id: viewId, viewClass, ReactComponent }: MetaInfo,
    embeddableViewConfig?: EmbeddableViewConfig
  ) {
    if (isViewDiscontinued(viewId)) {
      const reactView = createElement(DiscontinuedViewReplacement);
      const view = new PaneViewInterface(viewId);
      return { reactView, view };
    }
    if (ReactComponent) {
      const viewProps: InstantiableReactViewProps = {
        viewId,
        embeddableViewConfig,
      };
      const reactView = createElement(ReactComponent, viewProps);
      const view = new PaneViewInterface(viewId);
      return { reactView, view };
    }
    const containerElement = document.createElement('div');
    const menuContainerElement = document.createElement('div');
    menuContainerElement.classList.add('menuContainer');
    containerElement.classList.add('contentPane');
    const view = viewClass.create({
      embeddableViewConfig,
      containerElement,
      menuContainerElement,
    });
    const reactView = createElement(toUniqueClass(ContentView), {
      viewId,
      containerElement,
      menuContainerElement,
      view,
      isContextWorkspaceEmpty: undefined,
    });
    return { view, reactView };
  }
  currentView: ViewInterface | null;
  viewMap: Map<string, ViewMapEntry>;
  settingsBarMap: Map<string, SettingsControlsMapEntry>;
  subscriptions: Subscription[] = [];

  constructor(props: MainViewProps) {
    super(props);
    this.currentView = null;
    this.viewMap = new Map();
    this.settingsBarMap = new Map();

    this.rerenderCurrentView = this.rerenderCurrentView.bind(this);
    if (module.hot) {
      module.hot.accept('views/metaInfo', () => this.handleHotReload());
    }
    dispatchAction(
      mainPaneCreated({
        location: this.props.location,
        mainPane: this,
      })
    );
  }

  handleHotReload() {
    if (this.currentView) {
      this.currentView.deselected();
    }
    this.viewMap.forEach(({ view }) => {
      if (view) {
        view.remove();
      }
    });
    this.viewMap.clear();
    this.settingsBarMap.clear();
    this.forceUpdate();
  }

  componentDidMount() {
    this.subscriptions = [
      subscribeToAction(renderMainContentView, this.rerenderCurrentView),
      BrokenImagePopoverSubsciption(),
    ];
  }

  getActiveView() {
    return this.currentView;
  }

  getViewsById(viewId: ViewIds) {
    if (!this.viewMap.has(viewId)) {
      this.initAndAddViewToMap(viewId);
    }
    return this.viewMap.get(viewId);
  }

  getViewById(viewId: ViewIds) {
    if (!this.viewMap.has(viewId)) {
      this.initAndAddViewToMap(viewId);
    }
    const viewObject = this.viewMap.get(viewId);
    return viewObject ? viewObject.view : null;
  }

  initAndAddViewToMap(viewId: ViewIds) {
    let viewInstance: ViewMapEntry = {
      view: null,
      reactView: null,
    };
    const viewMetaInfo = getMetaInfo().get(viewId);
    if (viewMetaInfo) {
      viewInstance = MainView.createView(viewMetaInfo);
    } else {
      dispatchAction(viewChangeError());
    }
    this.viewMap.set(viewId, viewInstance);
  }

  getSettingsBarByViewId(viewId: ViewIds): SettingsControlsMapEntry {
    if (!this.settingsBarMap.has(viewId)) {
      this.initAndAddSettingsBarToMap(viewId);
    }
    return this.settingsBarMap.get(viewId)!;
  }

  initAndAddSettingsBarToMap(viewId: ViewIds) {
    const settingsControlsMapEntry: SettingsControlsMapEntry = {
      settingsControlsInstance: null,
      settingsControlsInstanceId: null,
    };

    const newInstanceId = getUniqueSettingsControlsNamespace();
    settingsControlsMapEntry.settingsControlsInstanceId = newInstanceId;
    settingsControlsMapEntry.settingsControlsInstance =
      getConnectedViewPaneSettingsBar(viewId, newInstanceId);

    this.settingsBarMap.set(viewId, settingsControlsMapEntry);
  }

  componentWillUnmount() {
    this.viewMap.forEach(({ view }) => {
      if (view) {
        view.remove();
      }
    });
    this.viewMap = new Map();
    this.settingsBarMap = new Map();
    this.subscriptions = clearSubscriptions(this.subscriptions);
    dispatchAction(
      mainPaneCreated({
        location: this.props.location,
        mainPane: null,
      })
    );
  }

  rerenderCurrentView() {
    if (this.currentView && !this.currentView.localResize) {
      this.currentView.debouncedRender();
    }
  }

  componentDidUpdate() {
    if (!this.currentView) {
      return;
    }
    dispatchAction(
      viewLoaded({
        view: this.currentView,
        viewId: this.props.activeTabId!,
        location: this.props.location,
      })
    );
  }

  render() {
    const { activeTabId } = this.props;
    if (!activeTabId) {
      this.currentView = null;
      return null;
    }
    const { view, reactView } = this.getViewsById(activeTabId)!;
    if (view && view !== this.currentView) {
      setTimeout(() =>
        dispatchAction(
          viewChanged({
            view,
            viewId: activeTabId,
            location: this.props.location,
          })
        )
      );
    }
    this.currentView = view;

    const hasSettingsBar = hasViewPaneSettingsBar({
      isViewpointMode: Boolean(this.props.isViewpointMode),
      viewId: activeTabId,
    });

    const { settingsControlsInstance, settingsControlsInstanceId } =
      hasSettingsBar
        ? this.getSettingsBarByViewId(activeTabId)
        : { settingsControlsInstance: null, settingsControlsInstanceId: null };
    const SettingsBar = settingsControlsInstance ?? returnNull;

    return (
      <>
        <FullscreenManager isActiveInFullscreen={true}>
          {!isPresentationMode() ? <PopoverManager /> : null}
          <TooltipManager />
        </FullscreenManager>
        <MainPaneLoaderConnected />
        <ConditionalFormattingImageColorFilters />
        <SettingsBar />
        <ViewPaneSettingsControlsIdContext.Provider
          value={settingsControlsInstanceId}
        >
          {reactView}
        </ViewPaneSettingsControlsIdContext.Provider>
      </>
    );
  }
}

const createStreamsFromLocation = (
  location: typeof MAIN_PANE_RIGHT | typeof MAIN_PANE_LEFT
) =>
  getTabOptionsStreamWithLocation(location).pipe(
    scan(
      ({ activeTabId: previousActiveTabId }, { activeTabId }) => {
        return { previousActiveTabId, activeTabId };
      },
      { previousActiveTabId: ViewIds.NONE, activeTabId: ViewIds.NONE }
    ),
    filter(({ activeTabId }) => activeTabId !== ViewIds.NONE),
    tap(({ activeTabId, previousActiveTabId }) => {
      if (
        previousActiveTabId !== activeTabId &&
        previousActiveTabId !== ViewIds.NONE
      ) {
        // This is not really clean. There is potentially a left and a right
        // view, currently we don't differentiate them when creating a
        // scenario from view.
        dispatchAction(readViewComponentIds({ [previousActiveTabId]: [] }));
      }
    }),
    map(state => ({ ...state, location }))
  );

const LeftMainPane = connect(
  MainView,
  createStreamsFromLocation(MAIN_PANE_LEFT)
);

const RightMainPane = connect(
  MainView,
  createStreamsFromLocation(MAIN_PANE_RIGHT)
);

export default memo(
  ({
    location,
    isViewpointMode,
  }: {
    location: PaneLocation;
    isViewpointMode: boolean;
  }) =>
    location === MAIN_PANE_LEFT ? (
      <LeftMainPane isViewpointMode={isViewpointMode} />
    ) : (
      <RightMainPane isViewpointMode={isViewpointMode} />
    )
);
