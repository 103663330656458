import { ReactNode } from 'react';
import {
  ModalSize,
  ModalTemplate,
  ModalTemplateProps,
  modal,
} from '@ardoq/modal';

type CopyComponentModalProps = Pick<
  ModalTemplateProps,
  'headerText' | 'text' | 'onCloseButtonClick'
>;

const CopyComponentModal = ({
  onCloseButtonClick,
  headerText,
  text,
}: CopyComponentModalProps) => {
  return (
    <ModalTemplate
      modalSize={ModalSize.S}
      headerText={headerText}
      text={text}
      onCloseButtonClick={onCloseButtonClick}
    />
  );
};

export const openCopyComponentModal = (headerText: string, text: ReactNode) => {
  modal<false>(resolve => (
    <CopyComponentModal
      headerText={headerText}
      text={text}
      onCloseButtonClick={() => resolve(false)}
    />
  ));
};

type RestoreDeletedItemsProps = Pick<
  ModalTemplateProps,
  | 'headerText'
  | 'text'
  | 'onCloseButtonClick'
  | 'primaryButtonText'
  | 'onPrimaryButtonClick'
>;

export const RestoreDeletedItems = ({
  headerText,
  text,
  onCloseButtonClick,
  primaryButtonText,
  onPrimaryButtonClick,
}: RestoreDeletedItemsProps) => {
  return (
    <ModalTemplate
      isLoading={false}
      isInProgress={false}
      headerText={headerText}
      modalSize={ModalSize.M}
      text={text}
      onCloseButtonClick={onCloseButtonClick}
      primaryButtonText={primaryButtonText}
      onPrimaryButtonClick={onPrimaryButtonClick}
    />
  );
};
