import styled from 'styled-components';
import { SlideWorkspacesMetadata } from 'presentationEditor/types';

const WorkspaceList = styled.ul`
  font-size: 13px;
`;

const WorkspaceBlockContainer = styled.div`
  margin-bottom: 2em;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const WorkspaceBlockDescription = styled.p`
  letter-spacing: 0.5px;
`;

interface WorkspaceBlockProperties {
  description: string;
  names: string[];
}

const WorkspaceBlock = ({ description, names }: WorkspaceBlockProperties) =>
  names.length ? (
    <WorkspaceBlockContainer>
      <WorkspaceBlockDescription>{description}</WorkspaceBlockDescription>
      <WorkspaceList>
        {names.map((name: string, idx: number) => (
          <li key={idx}>{name}</li>
        ))}
      </WorkspaceList>
    </WorkspaceBlockContainer>
  ) : null;

type ConnectedWorkspacesDialogProperties = SlideWorkspacesMetadata & {
  partialViewWarning: boolean;
};

const Container = styled.div`
  width: auto;
  max-width: none;
`;

const KnowledgeBaseInfoSection = styled.p`
  padding-top: 10px;
`;

const ConnectedWorkspacesDialog = ({
  includedWorkspaceNames,
  excludedWorkspaceNames = [],
  newWorkspacesToConsiderNames = [],
  missingReferencedWorkspaceNames,
  partialViewWarning = false,
}: ConnectedWorkspacesDialogProperties) => {
  const workspaceBlocks = [
    {
      key: 'missingReferencedWorkspaceIds',
      description:
        'The perspective used in this slide contains filters that reference components/references that belong to workspaces not shown in this slide. Some components might not be displayed for users without admin privileges. Consider adding the following workspaces:',
      names: missingReferencedWorkspaceNames,
    },
    {
      key: 'includedWorkspaces',
      description: 'These workspaces are shown in the slide:',
      names: includedWorkspaceNames,
    },
    {
      key: 'excludedWorkspaces',
      description: 'These workspaces are hidden in the slide:',
      names: excludedWorkspaceNames,
    },
    {
      key: 'newWorkspacesToConsider',
      description:
        'These workspaces have been recently connected to components in this slide, but are not included yet:',
      names: newWorkspacesToConsiderNames,
    },
  ];

  return (
    <Container>
      {partialViewWarning && (
        <p>
          This overview is not complete because you don&apos;t have access to
          all the workspaces.
        </p>
      )}
      {workspaceBlocks.map(({ key, ...props }) => (
        <WorkspaceBlock key={key} {...props} />
      ))}
      <KnowledgeBaseInfoSection>
        Read more about presentation content in our{' '}
        <a
          href="https://help.ardoq.com/explore-and-visualize/new-content-in-presentation-mode"
          target="_blank"
          rel="noopener noreferrer"
        >
          knowledge base
        </a>
        .
      </KnowledgeBaseInfoSection>
    </Container>
  );
};

export default ConnectedWorkspacesDialog;
