import { getDropdownDivider, getFilterMenuItem } from './menuItems';
import { DropdownItem } from '@ardoq/dropdown-menu';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { TrackedContextMenus, trackContextMenuOptionClick } from './tracking';
import {
  getExpandOrCollapseGroupMenuItem,
  getTypeGroupNameByTypeId,
  getZoomToFitContextMenuItem,
} from './utils';

type GetNonComponentGroupMenuArgs = {
  eventTargetModelId: string | null;
  isViewpointMode: boolean;
} & GetGroupControlContextMenuItemsArgs;

type GetGroupControlContextMenuItemsArgs = {
  expandOrCollapseGroupContextMenuItemProps?: {
    onExpandOrCollapseGroupContextMenuItemClick: VoidFunction;
    isGroupOpen: boolean;
  };
  onZoomToFitContextMenuItemClick?: VoidFunction;
  clickedElement?: HTMLElement | SVGElement | null;
};

const menuNonComponentGroupTrackingFunction = (
  optionTitle: string,
  clickedElement?: HTMLElement | SVGElement | null
) =>
  trackContextMenuOptionClick({
    clickedElement,
    contextMenuNamespace: TrackedContextMenus.NON_COMPONENT_GROUP_CONTEXT_MENU,
    selectedOptionTitle: optionTitle,
  });

export const getNonComponentGroupMenu = ({
  eventTargetModelId,
  clickedElement,
  expandOrCollapseGroupContextMenuItemProps,
  onZoomToFitContextMenuItemClick,
  isViewpointMode,
}: GetNonComponentGroupMenuArgs): DropdownItem[] | null => {
  const componentTypeGroupName = getTypeGroupNameByTypeId(eventTargetModelId);

  const menu = [
    ...getGroupControlContextMenuItems({
      expandOrCollapseGroupContextMenuItemProps,
      onZoomToFitContextMenuItemClick,
    }),
    componentTypeGroupName &&
      !isViewpointMode &&
      getFilterMenuItem({
        componentTypeGroupName,
        trackingFunction: (optionTitle: string) =>
          menuNonComponentGroupTrackingFunction(optionTitle, clickedElement),
      }),
  ].filter(ExcludeFalsy);

  return menu.length > 0 ? menu : null;
};

export const getGroupControlContextMenuItems = ({
  expandOrCollapseGroupContextMenuItemProps,
  onZoomToFitContextMenuItemClick,
  clickedElement,
}: GetGroupControlContextMenuItemsArgs) => {
  const { onExpandOrCollapseGroupContextMenuItemClick, isGroupOpen } =
    expandOrCollapseGroupContextMenuItemProps ?? {};

  const trackingFunction = (optionTitle: string) =>
    menuNonComponentGroupTrackingFunction(optionTitle, clickedElement);

  return [
    onZoomToFitContextMenuItemClick &&
      getZoomToFitContextMenuItem(
        onZoomToFitContextMenuItemClick,
        trackingFunction
      ),
    expandOrCollapseGroupContextMenuItemProps &&
      getExpandOrCollapseGroupMenuItem(
        isGroupOpen,
        onExpandOrCollapseGroupContextMenuItemClick,
        trackingFunction
      ),
    (onZoomToFitContextMenuItemClick ||
      expandOrCollapseGroupContextMenuItemProps) &&
      getDropdownDivider(),
  ].filter(ExcludeFalsy);
};
