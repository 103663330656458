import { omit } from 'lodash';
import {
  DashboardBuilderChartWidget,
  EditModeDashboardAttributes,
  widgetOperations,
} from '@ardoq/dashboard';
import { convertEditModeColorsToAPIColors } from './colorUtils';
import {
  formatChartWidgetForSave,
  formatHeaderWidgetForSave,
  getAvailableFieldsForDataSource,
  hasAvailableDataSource,
} from './utils';
import {
  APIFieldType,
  DataSourceField,
  WidgetDataSourceEntity,
} from '@ardoq/api-types';

/**
 * we fill random values for `label` and `type` to make Typescript happy.
 */
const fillWidgetFieldsDataSourceFields = (
  widget: DashboardBuilderChartWidget
): DataSourceField[] =>
  widget.fields?.map<DataSourceField>(field => ({
    name: field,
    label: field,
    type: APIFieldType.TEXT,
  })) ?? [];

export const prepareDataForSave = (
  dashboard: EditModeDashboardAttributes,
  availableDataSources: WidgetDataSourceEntity[]
) => {
  return {
    ...omit(dashboard, ['validationErrors', '_time']),
    description: dashboard.description ?? null,
    widgets: dashboard.widgets.map(widget => {
      let fields: DataSourceField[];
      if (widgetOperations.isHeaderWidgetData(widget)) {
        return formatHeaderWidgetForSave(widget);
      } else if (
        hasAvailableDataSource(
          availableDataSources,
          widget.datasource?.sourceId
        )
      ) {
        fields = getAvailableFieldsForDataSource(
          availableDataSources,
          widget.datasource
        );
      } else if (
        widgetOperations.isReadTableWidgetData(widget) &&
        widget.tableFields
      ) {
        fields = widget.tableFields.map(field => ({
          ...field,
          type: APIFieldType.TEXT,
        }));
      } else {
        fields = fillWidgetFieldsDataSourceFields(widget);
      }
      return formatChartWidgetForSave(widget, fields);
    }),
    settings: {
      colors: convertEditModeColorsToAPIColors(dashboard.settings.colors),
    },
  };
};
