import { resizeCancel, resizeEnd, resizeStart, resizeUpdate } from './actions';
import * as React from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import { GetBox, ResizeDirection } from './types';
import { throttle } from 'lodash';
import { dispatch } from './utils';

export const getResizeDownHandler =
  (getBox: GetBox, direction: ResizeDirection) => (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const mouseMoveHandler = throttle((mouseMoveEvent: MouseEvent) => {
      mouseMoveEvent.stopPropagation();
      mouseMoveEvent.preventDefault();
      dispatch(mouseMoveEvent, getBox, resizeUpdate, direction);
    }, 32);

    const finishResize = () => {
      mouseMoveHandler.cancel();
      event.stopPropagation();
      event.preventDefault();
      document.removeEventListener('mousemove', mouseMoveHandler, options);
      document.removeEventListener('mouseup', mouseUpHandler, options);
      document.removeEventListener('keydown', keyDownHandler, options);
    };

    const mouseUpHandler = (mouseUpEvent: MouseEvent) => {
      finishResize();
      dispatch(mouseUpEvent, getBox, resizeEnd, direction);
    };

    const keyDownHandler = (keyDownEvent: KeyboardEvent) => {
      if (keyDownEvent.key === 'Escape') {
        finishResize();
        dispatchAction(resizeCancel());
      }
    };

    document.addEventListener('mousemove', mouseMoveHandler, options);
    document.addEventListener('mouseup', mouseUpHandler, options);
    document.addEventListener('keydown', keyDownHandler, options);
    dispatch(event, getBox, resizeStart, direction);
  };
const options = { capture: true };
