import styled from 'styled-components';
import { colors, s16, s32, s8 } from '@ardoq/design-tokens';
import { Checkbox, Label } from '@ardoq/forms';

type CurrentContentConditionsProps = {
  readonly outgoingReferencesSelected: boolean;
  readonly incomingReferencesSelected: boolean;
  readonly handleIncomingReferencesClick: () => void;
  readonly handleOutgoingReferencesClick: () => void;
};

const Wrapper = styled.div`
  background-color: #f7f8fa;
  color: ${colors.grey15};
  padding: ${s16} ${s32};
`;

const Row = styled.div`
  display: flex;
  margin-bottom: ${s8};
  font-size: ${s16};
`;

const StyledLabel = styled(Label)`
  margin-bottom: ${s8};
`;

const CurrentContentConditions = ({
  outgoingReferencesSelected,
  incomingReferencesSelected,
  handleIncomingReferencesClick,
  handleOutgoingReferencesClick,
}: CurrentContentConditionsProps) => {
  return (
    <Wrapper>
      <StyledLabel>
        Include components with the following references:
      </StyledLabel>
      <Row>
        <Checkbox
          isChecked={incomingReferencesSelected}
          onChange={handleIncomingReferencesClick}
        >
          Incoming references
        </Checkbox>
      </Row>
      <Row>
        <Checkbox
          isChecked={outgoingReferencesSelected}
          onChange={handleOutgoingReferencesClick}
        >
          Outgoing references
        </Checkbox>
      </Row>
    </Wrapper>
  );
};

export default CurrentContentConditions;
