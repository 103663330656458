import { map } from 'rxjs';
import { serviceAccounts$ } from './serviceAccounts$';
import { serviceAccountsOperations } from './serviceAccountsOperations';
import {
  ManageServiceAccountsCommands,
  manageServiceAccountsCommands,
} from './commands';
import { APIServiceAccount, OrgAccessLevel } from '@ardoq/api-types';
import { caseInsensitiveStringIncludes } from '@ardoq/common-helpers';

export type ServiceAccountsViewModel = {
  isLoading: boolean;
  serviceAccounts: APIServiceAccount[];
  roles: { label: string; value: OrgAccessLevel; key: OrgAccessLevel }[];
  commands: ManageServiceAccountsCommands;
  errorMessage: string;
  searchKey: string;
};

export const manageServiceAccountsViewModel$ = serviceAccounts$.pipe(
  map(
    ({
      isLoading,
      searchKey,
      serviceAccounts,
      errorMessage,
    }): ServiceAccountsViewModel => {
      return {
        isLoading,
        serviceAccounts: serviceAccounts.filter(account =>
          caseInsensitiveStringIncludes(account.user.name, searchKey)
        ),
        roles: serviceAccountsOperations.getRolesRadioItems(),
        commands: manageServiceAccountsCommands,
        errorMessage: errorMessage || '',
        searchKey,
      };
    }
  )
);
