import { ButtonType } from '@ardoq/button';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { ReportEventLocations } from '@ardoq/report-reader';
import {
  openCreateFolderModal,
  openWorkspaceWizard,
  openPresentationCreator,
  openSurveyCreator,
  openNewBroadcast,
} from '../appContainer/DashboardContainer/utils';
import {
  openCreateReport,
  openDashboard,
} from '../components/AppMainSidebar/utils';
import { AssetsBrowser2024StreamShape } from '../components/AssetsBrowser2024/assetsBrowser2024Types';
import { DashboardModule } from '../dashboard/types';
import { workspaceAccessControlInterface } from '../resourcePermissions/accessControlHelpers/workspace';
import {
  hasPermission,
  Permissions,
} from '../streams/currentUserPermissions/permissionInterface';
import { trackEvent } from '../tracking/tracking';
import { openNewEditor as openMetamodelCreator } from 'metamodel/utils';
import { viewpointAccessControlInterface } from '../resourcePermissions/accessControlHelpers/viewpoints';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToViewpointForm } from '../router/navigationActions';
import { HomePageTrackingEventsNames } from './tracking';
import { AssetType } from '@ardoq/api-types';
import { CreateAssetOptions } from '../appContainer/DashboardContainer/getMenuOptions/types';
import { AppModules } from '../appContainer/types';

type CreateOption = {
  label: string;
  testId: string;
  onClick: () => void;
  assetType: AssetType;
};

export const getCreateOptions = ({
  commands,
  features,
  permissions,
  targetFolderId,
  trackingLocation,
}: CreateAssetOptions & {
  targetFolderId: string | null;
  trackingLocation: 'create-dropdown' | 'context-menu';
}): CreateOption[] => {
  return [
    {
      label: 'Folder',
      ariaLabel: 'Create folder',
      testId: 'open-create-folder-modal',
      onClick: async () => {
        trackEvent(HomePageTrackingEventsNames.CLICKED_CREATE_FOLDER, {
          from: trackingLocation,
        });
        openCreateFolderModal(
          targetFolderId
            ? { appModule: AppModules.HOME, targetFolderId }
            : undefined
        );
      },
      assetType: AssetType.FOLDER,
    },
    workspaceAccessControlInterface.canCreateWorkspace(
      permissions.permissionContext
    ) && {
      label: 'Workspace',
      ariaLabel: 'Create workspace',
      testId: 'open-create-workspace-wizard',
      onClick: () => {
        trackEvent(HomePageTrackingEventsNames.CLICKED_CREATE_WORKSPACE, {
          from: trackingLocation,
        });
        openWorkspaceWizard();
      },
      assetType: AssetType.WORKSPACE,
    },
    features.presentationsEnabled && {
      label: 'Presentation',
      ariaLabel: 'Create presentation',
      testId: 'open-presentation-creator',
      onClick: () => {
        trackEvent(HomePageTrackingEventsNames.CLICKED_CREATE_PRESENTATION, {
          from: trackingLocation,
        });
        openPresentationCreator();
      },
      assetType: AssetType.PRESENTATION,
    },
    hasPermission(Permissions.METAMODELS_CREATE) && {
      label: 'Metamodel',
      ariaLabel: 'Create metamodel',
      testId: 'open-metamodel-creator',
      onClick: () => {
        trackEvent(HomePageTrackingEventsNames.CLICKED_CREATE_MEDAMODEL, {
          from: trackingLocation,
        });
        openMetamodelCreator({
          trackingLocation: 'assetsManager',
        });
      },
      assetType: AssetType.METAMODEL,
    },
    features.surveysEnabled &&
      permissions.canCreateSurvey && {
        label: 'Survey',
        ariaLabel: 'Create survey',
        testId: 'open-survey-creator',
        onClick: () => {
          trackEvent(HomePageTrackingEventsNames.CLICKED_CREATE_SURVEY, {
            from: trackingLocation,
          });
          openSurveyCreator(targetFolderId);
        },
        assetType: AssetType.SURVEY,
      },
    {
      label: 'Viewpoint',
      ariaLabel: 'Create viewpoint',
      testId: 'open-viewpoint-creator',
      onClick: () => {
        trackEvent(HomePageTrackingEventsNames.CLICKED_CREATE_VIEWPOINT, {
          from: trackingLocation,
        });
        commands.openViewpointBuilderToCreateViewpoint(targetFolderId);
      },
      assetType: AssetType.TRAVERSAL,
    },
    features.broadcastsEnabled && {
      label: 'Broadcast',
      ariaLabel: 'Create broadcast',
      testId: 'open-new-broadcast',
      onClick: () => {
        trackEvent(HomePageTrackingEventsNames.CLICKED_CREATE_BROADCAST, {
          from: trackingLocation,
        });
        openNewBroadcast(targetFolderId);
      },
      assetType: AssetType.BROADCAST,
    },
    hasPermission(Permissions.REPORTS_CREATE) && {
      label: 'Report',
      ariaLabel: 'Create report',
      testId: 'open-new-report',
      onClick: () => {
        trackEvent(HomePageTrackingEventsNames.CLICKED_CREATE_REPORT, {
          from: trackingLocation,
        });
        openCreateReport(ReportEventLocations.ASSET_MANAGER);
      },
      assetType: AssetType.REPORT,
    },
    permissions.canCreateDashboard && {
      label: 'Dashboard',
      ariaLabel: 'Create dashboard',
      testId: 'open-new-dashboard',
      onClick: () => {
        trackEvent(HomePageTrackingEventsNames.CLICKED_CREATE_DROPWON, {
          from: trackingLocation,
        });
        openDashboard({
          dashboardModule: DashboardModule.BUILDER,
        });
      },
      assetType: AssetType.DASHBOARD,
    },
    viewpointAccessControlInterface.canCreateViewpoint() && {
      label: 'Discover Viewpoint',
      ariaLabel: 'Create discover viewpoint',
      testId: 'open-discovery-creator',
      onClick: () => {
        trackEvent(
          HomePageTrackingEventsNames.CLICKED_CREATE_DISCOVER_VIEWPOINT,
          {
            from: trackingLocation,
          }
        );
        dispatchAction(navigateToViewpointForm(null));
      },
      assetType: AssetType.VIEWPOINT,
    },
  ].filter(ExcludeFalsy);
};

const CreateNewButtonWithDropdown = ({
  commands,
  features,
  permissions,
}: Pick<
  AssetsBrowser2024StreamShape,
  'features' | 'permissions' | 'commands'
>) => (
  <ButtonWithDropdown
    dataTestId="create-new-dropdown"
    isDisabled={!permissions.currentUserIsOrgWriter}
    dropdownSize={DropdownSize.S}
    alignMenuTo="right"
    onClick={() =>
      trackEvent(HomePageTrackingEventsNames.CLICKED_CREATE_BUTTON)
    }
    overflowMethod="ellipsis"
    options={getCreateOptions({
      commands,
      features,
      permissions,
      targetFolderId: null,
      trackingLocation: 'create-dropdown',
    })}
    buttonType={ButtonType.GHOST}
  >
    Create new
  </ButtonWithDropdown>
);

export default CreateNewButtonWithDropdown;
