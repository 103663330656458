import Context from 'context';
import { nanoid } from 'nanoid';
import Component from 'models/component';
import Backbone from 'backbone';
import { backboneModelComparator } from 'utils/compareUtils';
import { modelsNeedSaving } from 'collections/helpers/modelsNeedSaving';
import Filters from 'collections/filters';
import GroupBys from 'collections/groupByCollection';
import {
  isArdoqError,
  ArdoqError,
  NumericSortOrder,
  SortAttribute,
  ExcludeFalsy,
  toByIdDictionary,
} from '@ardoq/common-helpers';
import ComponentHierarchy from 'models/ComponentHierarchy';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import ViewCollection from './ViewCollection';
import {
  CollectionView,
  EVENT_TAG_ADDED,
  EVENT_TAG_REMOVED,
  ExcludedSet,
} from './consts';
import syncManager, { saveModel } from 'sync/syncManager';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import { ComponentBackboneModel, Reference, Workspace } from 'aqTypes';
import { getApiUrl } from 'backboneExtensions';
import { APIComponentAttributes, ArdoqId } from '@ardoq/api-types';
import { subscribeToAction } from 'streams/utils/streamUtils';
import {
  notifySortChanged,
  notifyWorkspaceClosed,
} from 'streams/context/ContextActions';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { batchApi } from '@ardoq/api';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { subdivisionsInterface } from 'streams/subdivisions/subdivisionInterface';
import { each, filter } from 'lodash';
import { filter as filterOperator, map } from 'rxjs';
import { clearAllEventListenersOnModels } from './helpers/clearAllEventListenersOnModels';
import { websocket$ } from 'sync/websocket$';
import {
  isWebSocketSubdivisionMembershipAssignment,
  SubdivisionMembershipAssignmentEventData,
} from 'sync/types';

const componentHasProperties = (
  comp: ComponentBackboneModel,
  componentFilter: Partial<APIComponentAttributes> | null | undefined
) => {
  let included = true;
  each(componentFilter || {}, function (val, key) {
    if (included && comp.attributes[key] !== val) {
      included = false;
    }
  });
  return included;
};

/**
 * Create components using the batch API and preserve their order relative
 * order in the input array.
 */
export const postBatchCreateComponents = async (
  components: Partial<APIComponentAttributes>[]
): Promise<Array<APIComponentAttributes | null> | ArdoqError> => {
  const create = components.map(body => ({
    batchId: nanoid(),
    ...body,
  }));

  const batchResponse = await batchApi.execute({
    options: { includeEntities: true },
    components: { create },
  });

  if (isArdoqError(batchResponse)) {
    return batchResponse;
  }

  // Api responds with a map of created components, so we preserve the order
  // by using the original array to control the order we pick components.
  return create.map(({ batchId }) => {
    const realId = batchResponse.components.ids[batchId];
    if (!realId) return null;
    return batchResponse.components.created?.[realId] ?? null;
  });
};

export class Components extends ViewCollection<ComponentBackboneModel> {
  removeOnCloseWorkspace: boolean;
  hierarchy: ComponentHierarchy = undefined as unknown as ComponentHierarchy;

  comparator = backboneModelComparator;

  constructor(...args: any) {
    super(...args);

    this.model = Component.model;
    this.url = `${getApiUrl(Backbone.Collection)}/api/component`;
    this.removeOnCloseWorkspace = true;

    this.hierarchy = new ComponentHierarchy(this);

    this.on(
      [EVENT_TAG_ADDED, EVENT_TAG_REMOVED, 'change'].join(' '),
      (comp: ComponentBackboneModel) => {
        const isUpdated = Filters.updateFiltered(comp);
        if (isUpdated) {
          comp.trigger(comp.EVENT_FILTERED_CHANGED);
        }
      }
    );

    this.listenTo(
      this,
      'change',
      ({ changed } = { changed: {} as Partial<ComponentBackboneModel> }) => {
        const fieldNames = Object.keys(changed);
        Filters.notifyOnAffectingChange({
          fieldNames,
          checkComponents: true,
        });
        GroupBys.notifyOnAffectingChange({ fieldNames });
      }
    );

    this.listenTo(this, 'change:_order', this.sort.bind(this));

    subscribeToAction(notifySortChanged, () => {
      this.sort();
    });
    subscribeToAction(notifyWorkspaceClosed, () => {
      this.saveAllChangedModels(true);

      if (Context.workspaces().length === 0) {
        clearAllEventListenersOnModels(this.models);
        this.hierarchy.clearHierarchyForComponents(this.models);
        this.reset();
      } else {
        const openWorkspaceIdSet = new Set(
          Context.workspaces().map(ws => ws.id)
        );

        const componentsToRemove = this.cleanUpOnWorkspaceClosed({
          openWorkspaceIdSet,
          references: ARDOQ.references,
        });
        if (componentsToRemove.length) {
          clearAllEventListenersOnModels(componentsToRemove);
          this.batchRemoveComponents(componentsToRemove);
        }
      }
    });
    // React to websocket subdivisions events
    websocket$
      .pipe(
        filterOperator(isWebSocketSubdivisionMembershipAssignment),
        map(({ data }) => data)
      )
      .subscribe(data => this.handleSubdivisionMembershipAssignment(data));
  }

  addWithParents(
    componentSet: Set<ComponentBackboneModel>,
    comp: ComponentBackboneModel | null
  ) {
    if (comp) {
      componentSet.add(comp);
      this.addWithParents(componentSet, comp.getParent());
    }
  }

  cleanUpOnWorkspaceClosed({
    openWorkspaceIdSet,
    references,
  }: {
    openWorkspaceIdSet: Set<string>;
    references: Reference[];
  }) {
    const componentsToKeepSet = new Set<ComponentBackboneModel>();
    references.forEach(ref => {
      if (
        !openWorkspaceIdSet.has(ref.get('rootWorkspace')) &&
        openWorkspaceIdSet.has(ref.get('targetWorkspace'))
      ) {
        this.addWithParents(componentsToKeepSet, ref.getSource());
      }
      if (
        openWorkspaceIdSet.has(ref.get('rootWorkspace')) &&
        !openWorkspaceIdSet.has(ref.get('targetWorkspace'))
      ) {
        this.addWithParents(componentsToKeepSet, ref.getTarget());
      }
    });

    const componentsToRemove = this.filter(comp => {
      return (
        !componentsToKeepSet.has(comp) &&
        !openWorkspaceIdSet.has(comp.get('rootWorkspace'))
      );
    });
    return componentsToRemove;
  }

  saveAllChangedModels(forced = false) {
    const components = modelsNeedSaving({
      forced,
      models: this.models,
      hasWriteAccessCheck: comp => {
        const canEditComponent =
          componentAccessControlOperation.canEditComponent({
            component: comp.attributes,
            permissionContext: currentUserInterface.getPermissionContext(),
            subdivisionsContext:
              subdivisionsInterface.getSubdivisionsStreamState(),
          });
        return canEditComponent;
      },
    });

    if (isScenarioMode()) {
      for (const component of components) {
        saveModel(component);
      }
    } else {
      syncManager.batchSave(components, 'components');
    }
  }

  getFlatHierarchy(rootComponents: ComponentBackboneModel[]) {
    const flatHierarchy = rootComponents.flatMap(rootComp => [
      rootComp,
      ...rootComp.getChildrenDeep(),
    ]);
    const sort = Context.getSort();

    if (
      sort.attr === SortAttribute.ORDER ||
      sort.order === NumericSortOrder.ON_REFERENCE_DESCENDING ||
      sort.order === NumericSortOrder.ON_REFERENCE_ASCENDING
    ) {
      return flatHierarchy;
    }
    return flatHierarchy.sort(this.comparator);
  }

  getWorkspaceComponentsUnsorted(workspaceId: string) {
    return this.filter(comp => comp.attributes.rootWorkspace === workspaceId);
  }

  getWorkspaceComponents(
    componentFilter?: Partial<APIComponentAttributes> | null | undefined,
    workspaceId?: ArdoqId | null
  ) {
    if (workspaceId) {
      return this.filter(
        comp =>
          comp.attributes.rootWorkspace === workspaceId &&
          componentHasProperties(comp, componentFilter)
      ).sort(this.comparator);
    }

    return [];
  }

  isEmptyWorkspace(workspace: Workspace | null) {
    return (
      !!workspace &&
      !this.findWhere({
        rootWorkspace: workspace.id,
      })
    );
  }

  getComponentHierarchy(comp: ComponentBackboneModel | null) {
    let foundComps: ComponentBackboneModel[] = [];
    const alreadyAdded: Record<string, boolean> = {};
    const sort = (child: ComponentBackboneModel) => {
      if (child && alreadyAdded[child.getId()] !== true) {
        alreadyAdded[child.getId()] = true;
        foundComps.push(child);
      }
    };
    let currentComponent = comp;
    while (currentComponent) {
      each(currentComponent.getChildren(), sort);
      currentComponent = currentComponent.getParent();
    }
    foundComps = foundComps.concat(
      filter(this.models, child => child.get('parent') === null)
    );
    return foundComps;
  }

  getComponentsByIds(componentIds: string[]) {
    return componentIds.map(id => this.get(id)).filter(ExcludeFalsy);
  }

  batchRemoveComponents(componentsToRemove: ComponentBackboneModel[]) {
    this.remove(componentsToRemove, { silent: true });
    this.hierarchy.clearHierarchyForComponents(componentsToRemove);
    dispatchAction(
      notifyComponentsRemoved({
        componentIds: componentsToRemove.map(c => c.id),
      })
    );
  }

  batchUpdateComponents(components: Record<ArdoqId, APIComponentAttributes>) {
    const updatedComponentIds = Object.values(components)
      .map(component => {
        const componentBackboneModel = this.get(component._id);
        if (componentBackboneModel) {
          componentBackboneModel.set(component, { silent: true });
          return component._id;
        }
        return null;
      })
      .filter(ExcludeFalsy);

    if (updatedComponentIds.length > 0) {
      dispatchAction(
        notifyComponentsUpdated({
          componentIds: updatedComponentIds,
        })
      );
    }
  }

  batchLoadComponents(components: Record<string, APIComponentAttributes>) {
    const connectedWorkspaces = Context.getConnectedWorkspaceIds();
    const addedComponents = Object.values(components)
      .filter(attributes => connectedWorkspaces.has(attributes.rootWorkspace))
      .map(attributes => {
        const comp = Component.create(attributes, { collection: this });
        if (!comp.get('type')) {
          comp.setComponentTypeName();
          comp.mustBeSaved = false;
        }
        return comp;
      });
    this.add(addedComponents, {
      silent: true,
    });
    this.hierarchy.clearHierarchyForComponents(addedComponents);
    dispatchAction(
      notifyComponentsAdded({
        componentIds: addedComponents.map(({ id }) => id),
      })
    );
    return addedComponents;
  }

  /**
   * Handle subdivision membership assignment events
   */
  handleSubdivisionMembershipAssignment(
    data: SubdivisionMembershipAssignmentEventData
  ) {
    if (isScenarioMode()) {
      return;
    }

    // Add components that have been granted by the subdivision
    const grantedComponents = data.grantPermissionToResource;
    if (grantedComponents?.length > 0) {
      this.batchLoadComponents(toByIdDictionary(grantedComponents));
    }

    // Update components that have been updated by the subdivision
    const updatedComponents = data.updatePermissionToResource;
    if (updatedComponents?.length > 0) {
      this.batchUpdateComponents(toByIdDictionary(updatedComponents));
    }

    // Remove components that have been revoked from the subdivision
    const revokedComponents = data.revokePermissionToResource;
    const componentsToRemove = this.getComponentsByIds(revokedComponents);
    if (componentsToRemove?.length > 0) {
      this.batchRemoveComponents(componentsToRemove);
    }
  }
}

const globalComponents = new Components();

globalComponents.createCollectionView(CollectionView.BASE_VIEW);
globalComponents.createCollectionView(CollectionView.WITH_PLACEHOLDERS, [
  ExcludedSet.SCENARIO_RELATED_SET,
  ExcludedSet.COLLAPSED_SET,
]);

export default {
  collection: globalComponents,
  createView: (name: string, includedSets = []) =>
    globalComponents.createCollectionView(name, includedSets),
};
