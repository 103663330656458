import { BroadcastContentType } from '@ardoq/api-types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { toggleFilterConditionsExpanded } from './actions';
import { Checkbox, Field, FormSize } from '@ardoq/forms';
import filterConditionsSection$ from 'broadcasts/broadcastBuilder/broadcastContentForm/filterConditionsSection/filterConditionsSection$';
import FilterConditions from 'broadcasts/broadcastBuilder/broadcastContentForm/FilterConditions';
import { FilterConditionsStreamShape } from './types';

type FilterConditionsSectionProps = FilterConditionsStreamShape & {
  readonly selectedContentType: BroadcastContentType;
  readonly isDisabled?: boolean;
};

const FilterConditionsSection = ({
  selectedContentType,
  isExpanded,
  isDisabled,
}: FilterConditionsSectionProps) => {
  return (
    <Field formSize={FormSize.AUTO}>
      <Checkbox
        isChecked={isExpanded}
        onChange={() => dispatchAction(toggleFilterConditionsExpanded())}
        isDisabled={isDisabled}
      >
        Add filter rules
      </Checkbox>
      {isExpanded && !isDisabled && (
        <FilterConditions
          isSurveyContentType={
            selectedContentType === BroadcastContentType.SURVEY
          }
        />
      )}
    </Field>
  );
};

export default connect(FilterConditionsSection, filterConditionsSection$);
