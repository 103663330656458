import styled, { css } from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { text2 } from '@ardoq/typography';
import { ButtonSize, GhostButton } from '@ardoq/button';
import { Checkbox } from '@ardoq/forms';
import {
  ChevronDownIcon,
  IconSize,
  ChevronUpIcon,
  DeleteIcon,
} from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';

type CollapsedAdvancedSearchConditionProps = {
  readonly entityType: 'fields' | 'references';
  readonly isChecked: boolean;
  readonly isExpanded: boolean;
  readonly toggleAdvancedSearchCondition: VoidFunction;
  readonly removeAdvancedSearchCondition: VoidFunction;
  readonly expandAdvancedSearchCondition: (isExpanded: boolean) => void;
};

const ExpanderLabel = styled.span<{ $isDisabled: boolean }>`
  ${text2};
  text-decoration: underline dashed ${colors.grey35};
  text-underline-offset: 2px;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
    `}
`;

const CollapsedAdvancedSearchCondition = ({
  entityType,
  isChecked,
  isExpanded,
  toggleAdvancedSearchCondition,
  removeAdvancedSearchCondition,
  expandAdvancedSearchCondition,
}: CollapsedAdvancedSearchConditionProps) => {
  return (
    <FlexBox gap="xsmall" align="center" width="full">
      <Checkbox isChecked={isChecked} onChange={toggleAdvancedSearchCondition}>
        <ExpanderLabel $isDisabled={!isChecked}>
          Component {entityType} rules
        </ExpanderLabel>
      </Checkbox>
      <GhostButton
        buttonSize={ButtonSize.SMALL}
        onClick={() => expandAdvancedSearchCondition(!isExpanded)}
      >
        {isExpanded ? (
          <ChevronUpIcon iconSize={IconSize.SMALL} />
        ) : (
          <ChevronDownIcon iconSize={IconSize.SMALL} />
        )}
      </GhostButton>
      <GhostButton
        buttonSize={ButtonSize.SMALL}
        onClick={() => removeAdvancedSearchCondition()}
      >
        <DeleteIcon iconSize={IconSize.SMALL} />
      </GhostButton>
    </FlexBox>
  );
};

export default CollapsedAdvancedSearchCondition;
