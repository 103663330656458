import { FieldsWrapper, Label, TextInput } from '@ardoq/forms';
import {
  ArdoqId,
  PersistedTagQuestion,
  UnpersistedTagQuestion,
} from '@ardoq/api-types';
import { StyledSpace } from './questionAtoms';
import ReadOnlyCheckboxRow from './ReadOnlyCheckboxRow';
import { RangeValidation } from './RangeValidation';
import {
  SurveyQuestionError,
  SurveyQuestionValidations,
} from 'surveyAdmin/types';
import { isRequiredQuestion } from 'surveyAdmin/SurveyEditor/utils';
import { ErrorNotification } from '@ardoq/status-ui';
import { canAccessWorkspace } from 'surveyAdmin/surveyUtil';
import { QUESTION_HELP_TEXT_EXPLANATION } from '../SurveyEditor/consts';
import { Link } from '@ardoq/typography';
import { RichTextEditorTransition } from '@ardoq/rich-text-editor-2024';
import { getQuestionValidationMessageIfExists } from './utils';

type TagQuestionProperties = {
  workspaceId: ArdoqId | null;
  question: PersistedTagQuestion | UnpersistedTagQuestion;
  questionValidations?: SurveyQuestionValidations;
  workspaceDoesNotExistError?: string;
  workspaceAccessError?: string;
  updateQuestion: (
    updatedQuestion: PersistedTagQuestion | UnpersistedTagQuestion
  ) => void;
};

const TagQuestion = ({
  workspaceId,
  question,
  questionValidations,
  workspaceDoesNotExistError,
  workspaceAccessError,
  updateQuestion,
}: TagQuestionProperties) => {
  const hasAccessToWorkspace = canAccessWorkspace(
    workspaceId,
    workspaceDoesNotExistError,
    workspaceAccessError
  );

  const errorMessage = workspaceAccessError
    ? workspaceAccessError
    : workspaceDoesNotExistError
      ? workspaceDoesNotExistError
      : getQuestionValidationMessageIfExists(
          questionValidations,
          SurveyQuestionError.WORKSPACE_TAGS_MISSING
        );

  return (
    <>
      <ReadOnlyCheckboxRow
        disabled={isRequiredQuestion(question)}
        readonly={question.readonly}
        validators={question.validators}
        updateReadonlyAndValidators={readOnlyAndValidators =>
          updateQuestion({ ...question, ...readOnlyAndValidators })
        }
      />
      <FieldsWrapper>
        <StyledSpace $isVertical>
          <TextInput
            label="Question title"
            value={question.label}
            onValueChange={label => updateQuestion({ ...question, label })}
            popoverHelpContent="Respondents can use tags available in the selected workspace."
            dataTestId="tag-title-input"
          />
          {hasAccessToWorkspace && (
            <Link
              target="_blank"
              href={`/app/view/pagesView/workspace/${workspaceId}`}
            >
              Go to workspace
            </Link>
          )}
          {errorMessage && (
            <ErrorNotification>{errorMessage}</ErrorNotification>
          )}
        </StyledSpace>
      </FieldsWrapper>
      <FieldsWrapper>
        <StyledSpace $isVertical $gap="s4">
          <Label popoverHelpContent={QUESTION_HELP_TEXT_EXPLANATION}>
            Question help text
          </Label>
          <RichTextEditorTransition
            content={question.description}
            onInputChange={description =>
              updateQuestion({ ...question, description })
            }
            dataTestId="tag-help-input"
          />
        </StyledSpace>
      </FieldsWrapper>
      {!question.readonly && (
        <>
          <RangeValidation
            labelText="Number of tags that can be added"
            question={question}
            updateValidators={validators =>
              updateQuestion({ ...question, validators })
            }
            questionValidations={questionValidations}
          />
        </>
      )}
    </>
  );
};
export default TagQuestion;
