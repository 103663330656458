import { dispatchAction } from '@ardoq/rxbeach';
import { toggleFavorite } from 'streams/currentUser/actions';
import { Row } from 'components/EntityBrowser/types';
import { AssetRow } from 'components/AssetsBrowser/types';
import { IconName } from '@ardoq/icons';
import styled from 'styled-components';
import { FlyWheelTable, RowType } from '@ardoq/table';
import { IconButton } from '@ardoq/button';
import { trackEvent } from 'tracking/tracking';

const VisibleOnRowHover = styled.div`
  visibility: hidden;

  /* if we hover over a row, then apply following styles to current (&) element */
  ${FlyWheelTable.Tr}:hover & {
    visibility: visible;
    opacity: 0.5;
  }

  /* if we hover over a current element that is within a row, then apply additional styles to current (&) element */
  ${FlyWheelTable.Tr}:hover &:hover {
    opacity: 1;
  }
`;

type FavButtonProps = { iconName: IconName; onClick: () => void };
const FavButton = ({ iconName, onClick }: FavButtonProps) => {
  return <IconButton onClick={onClick} iconName={iconName} />;
};

type FavoriteButtonProps = {
  row: Row<AssetRow>;
};
const FavoriteButton = ({ row }: FavoriteButtonProps) => {
  if (row.rowType === RowType.EMPTY_FOLDER || row.rowType === RowType.FOLDER)
    return null;

  const handleFavoriteClick = () => {
    dispatchAction(
      toggleFavorite({
        _id: row._id,
        type: row.meta.assetType,
      })
    );
    trackEvent('Assets Browser: toggled favorite', {
      assetType: row.rowType,
      toggledTo: !row.meta.favorite,
    });
  };

  return row.meta.favorite ? (
    <FavButton onClick={handleFavoriteClick} iconName={IconName.STAR} />
  ) : (
    <VisibleOnRowHover>
      <FavButton
        onClick={handleFavoriteClick}
        iconName={IconName.STAR_BORDER}
      />
    </VisibleOnRowHover>
  );
};

export default FavoriteButton;
