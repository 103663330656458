import React from 'react';
import { ErrorNotification, LoadingWrapper } from '@ardoq/status-ui';
import { FieldsRow, FieldsWrapper, TextArea, TextInput } from '@ardoq/forms';
import { Select } from '@ardoq/select';
import PDFLinkEditor from './PDFLinkEditor';
import { PrimaryButton, SecondaryButton } from '@ardoq/button';
import MetamodelTriplesEditor from './MetamodelTriplesEditor';
import { ErrorBox } from '../../bundles/atoms';
import styled from 'styled-components';
import { header2 } from '@ardoq/typography';
import { UseCaseEditorProps } from '../types';
import { Box, Stack } from '@ardoq/layout';

const SectionHeader = styled.div`
  ${header2}
`;

const AlignedFieldsRow = styled(FieldsRow)`
  align-items: flex-start;
`;

const NewUseCaseEditor = ({
  isLoading,
  editorState,
  availableEntityGroups,
  availableKeyMetricsDashboards,
  editorCommands,
  formState,
  editorErrors,
  triplesSelectOptions,
}: UseCaseEditorProps) => {
  const {
    name,
    iteration,
    category,
    description,
    businessOutcomes,
    entityGroupId,
    keyMetricsDashboardId,
    pdfLinks,
    metamodelTriples,
    _id,
    errors,
  } = editorState;
  const { fields: fieldsState, buttons: buttonsState } = formState;
  const { triplesErrors, errorNotifications } = editorErrors;
  return (
    <LoadingWrapper loading={isLoading}>
      <FieldsWrapper>
        <AlignedFieldsRow>
          <TextInput
            label="Name"
            value={name}
            onValueChange={editorCommands.changeName}
          />
          <TextInput
            label="Version"
            value={iteration}
            onValueChange={editorCommands.changeVersion}
          />
        </AlignedFieldsRow>
        <AlignedFieldsRow>
          <TextInput
            label="Category"
            value={category ?? ''}
            onValueChange={editorCommands.changeCategory}
            isOptional
          />
        </AlignedFieldsRow>
        <AlignedFieldsRow>
          <TextArea
            label="Description"
            value={description ?? ''}
            onValueChange={editorCommands.changeDescription}
            isOptional
          />
          <TextArea
            label="Business outcomes"
            value={businessOutcomes}
            onValueChange={editorCommands.changeBusinessOutcomes}
          />
        </AlignedFieldsRow>
        <AlignedFieldsRow>
          <Select<string>
            options={availableEntityGroups}
            label="Entity group"
            value={entityGroupId}
            onValueChange={value => {
              if (value !== entityGroupId)
                editorCommands.changeEntityGroup(value);
            }}
          />
          <Select<string>
            options={availableKeyMetricsDashboards}
            label="Key metrics dashboard"
            value={keyMetricsDashboardId}
            onValueChange={editorCommands.changeKeyMetricsDashboard}
            errorMessage={fieldsState.keyMetricsDashboards.errorMessage}
            isDisabled={fieldsState.keyMetricsDashboards.isDisabled}
            popoverContent={fieldsState.keyMetricsDashboards.popoverContent}
          />
        </AlignedFieldsRow>
        <Box marginTop={'medium'}>
          <Stack gap={'medium'}>
            <SectionHeader>PDF Links</SectionHeader>
            {pdfLinks.map(({ label, link }, idx) => {
              return (
                <PDFLinkEditor
                  key={idx}
                  label={label}
                  link={link}
                  onLabelChange={editorCommands.changePdfLinkLabel(idx)}
                  onLinkChange={editorCommands.changePdfLinkUrl(idx)}
                  onDelete={editorCommands.deletePdfLink(idx)}
                />
              );
            })}
          </Stack>
          <Box marginTop={'medium'}>
            <SecondaryButton onClick={editorCommands.addPdfLink}>
              Add PDF Link
            </SecondaryButton>
          </Box>
        </Box>
        <Box marginTop={'medium'}>
          <Stack gap={'medium'}>
            <SectionHeader>Metamodel Triples</SectionHeader>
            {metamodelTriples.map((triple, idx) => {
              const tripleSelectOptions = triplesSelectOptions[idx];
              return (
                <MetamodelTriplesEditor
                  key={idx}
                  triple={triple}
                  onSourceComponentTypeChange={editorCommands.updateTripleSourceComponentType(
                    idx
                  )}
                  onReferenceTypeChange={editorCommands.updateTripleReferenceType(
                    idx
                  )}
                  onTargetComponentTypeChange={editorCommands.updateTripleTargetComponentType(
                    idx
                  )}
                  onDelete={editorCommands.deleteTriple(idx)}
                  errors={triplesErrors[idx]}
                  {...tripleSelectOptions}
                />
              );
            })}
          </Stack>
          <Box marginTop={'medium'}>
            <SecondaryButton
              data-tooltip-text={buttonsState.addTriples.dataTooltipText}
              isDisabled={buttonsState.addTriples.isDisabled}
              onClick={editorCommands.addTriple}
            >
              Add Triple
            </SecondaryButton>
          </Box>
        </Box>
        {errors && errors.length ? <ErrorBox errorMsgs={errors} /> : null}
        {errorNotifications && (
          <ErrorNotification>
            {errorNotifications.map(notification => (
              <span key={notification}>{notification}</span>
            ))}
          </ErrorNotification>
        )}
      </FieldsWrapper>
      <Box paddingTop="xlarge">
        <PrimaryButton
          isDisabled={buttonsState.saveButton.isDisabled}
          onClick={editorCommands.saveUseCase}
        >
          {_id ? 'Save Changes' : 'Create Use Case'}
        </PrimaryButton>
      </Box>
    </LoadingWrapper>
  );
};

export default NewUseCaseEditor;
