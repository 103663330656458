import styled, { keyframes } from 'styled-components';
import { Tooltip } from '@ardoq/tooltip';

export const FeatureHighlightTooltip = styled(Tooltip)`
  padding: 8px 16px;
`;

const highlightBorder = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(85, 101, 118, 0.4); }
  100% { box-shadow: 0 0 0 15px rgba(85, 101, 118, 0); }
`;

export const HighlightBox = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 100001;
  outline: 2px solid rgb(85, 101, 118);
  border-radius: 1px;
  animation: ${highlightBorder} 1.5s infinite;
`;
