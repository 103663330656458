import { ConfirmConfig, confirm } from '@ardoq/modal';
import { dispatchAction } from '@ardoq/rxbeach';
import { STRINGS } from './const';
import { discardState } from './actions';
import unsavedState$ from './unsavedState$';
import { trackEvent } from 'tracking/tracking';

const defaultConfig: ConfirmConfig = {
  title: STRINGS.TITLE,
  subtitle: STRINGS.SUBTITLE,
  confirmButtonTitle: STRINGS.CONFIRM_BUTTON_TITLE,
  cancelButtonTitle: STRINGS.CANCEL_BUTTON_TITLE,
};

export async function maybeConfirmDiscard(
  confirmConfig = defaultConfig
): Promise<boolean> {
  if (!Object.values(unsavedState$.state).some(Boolean)) return true;
  const { confirmed } = (await confirm(confirmConfig)) || { confirmed: false };
  if (confirmed) {
    trackEvent('Navigation0 discard unsaved changes');
    dispatchAction(discardState());
  }
  return confirmed;
}
