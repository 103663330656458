import {
  SurveyQuestionType,
  UnpersistedFieldSubQuestion,
} from '@ardoq/api-types';
import { currentTimestamp } from '@ardoq/date-time';

export const createDependentQuestion = (
  type:
    | SurveyQuestionType.ATTRIBUTE
    | SurveyQuestionType.FIELD
    | SurveyQuestionType.REFERENCE,
  label = '',
  isReadonly?: boolean
): UnpersistedFieldSubQuestion | undefined => {
  switch (type) {
    case SurveyQuestionType.ATTRIBUTE:
      return {
        key: `${currentTimestamp() - Math.random()}`,
        type: SurveyQuestionType.ATTRIBUTE,
        label,
        description: '',
        properties: {
          attributeName: '',
          displayConditions: [],
        },
        validators: [],
        readonly: isReadonly ?? false,
      };
    case SurveyQuestionType.FIELD:
      return {
        key: `${currentTimestamp() - Math.random()}`,
        type: SurveyQuestionType.FIELD,
        label,
        description: '',
        properties: {
          fieldName: '',
          displayConditions: [],
        },
        validators: [],
        readonly: isReadonly ?? false,
      };
    case SurveyQuestionType.REFERENCE:
      return {
        key: `${currentTimestamp() - Math.random()}`,
        type: SurveyQuestionType.REFERENCE,
        label,
        description: '',
        properties: {
          displayConditions: [],
          questions: [],
          outgoing: true,
        },
        validators: [],
        readonly: isReadonly ?? false,
        aiSuggestionsEnabled: false,
      };
    default:
      return;
  }
};
