import {
  differenceInDays,
  formatDateTime,
  isBefore,
  parseDate,
} from '@ardoq/date-time';
import { TokenTabViewModel, TokenTypes } from './types';
import { APIToken, ArdoqId } from '@ardoq/api-types';
import { getCurrentLocale } from '@ardoq/locale';

function replaceToken<Token extends { _id: string }>(
  tokens: Token[],
  inToken: Token
): Token[] {
  return tokens.map(token => (token._id === inToken._id ? inToken : token));
}

function removeToken<Token extends { _id: string }>(
  viewModels: Token[],
  outToken: Token
): Token[] {
  return viewModels.filter(viewModel => viewModel._id !== outToken._id);
}

const setIsLoading = (viewModel: TokenTabViewModel): TokenTabViewModel => {
  return { ...viewModel, isLoading: true, errorMessage: '' };
};

const updateSearchKey = (
  viewModel: TokenTabViewModel,
  searchKey: string
): TokenTabViewModel => {
  return { ...viewModel, searchKey };
};

const switchTab = (
  viewModel: TokenTabViewModel,
  activeTokensTab: TokenTypes
): TokenTabViewModel => {
  return { ...viewModel, activeTokensTab };
};

const setTokens = (viewModel: TokenTabViewModel, tokens: APIToken[]) => {
  return { ...viewModel, tokens: [...tokens].reverse(), isLoading: false };
};

const addNewUserToken = (viewModel: TokenTabViewModel, token: APIToken) => {
  return {
    ...viewModel,
    tokens: [token, ...viewModel.tokens],
  };
};

const replaceUserToken = (viewModel: TokenTabViewModel, token: APIToken) => {
  return {
    ...viewModel,
    tokens: replaceToken(viewModel.tokens, token),
  };
};

const removeUserToken = (viewModel: TokenTabViewModel, token: APIToken) => {
  return {
    ...viewModel,
    tokens: removeToken(viewModel.tokens, token),
  };
};

const setErrorMessage = (
  viewModel: TokenTabViewModel,
  errorMessage: string
) => {
  return {
    ...viewModel,
    errorMessage: errorMessage,
    isLoading: false,
  };
};

const isExpired = (token: APIToken, now: Date) => {
  return isBefore(parseDate(token.tokenExpirationDate), now);
};

const isExpiringSoon = (token: APIToken, now: Date) => {
  return differenceInDays(parseDate(token.tokenExpirationDate), now) <= 30;
};

const getdaysUntilExpiration = (token: APIToken, now: Date) => {
  return differenceInDays(parseDate(token.tokenExpirationDate), now);
};

const isMoreThanSixtyDays = (token: APIToken, now: Date) => {
  return differenceInDays(now, parseDate(token.lastRegenerated)) >= 60;
};

const isServiceAccountToken = (token: APIToken): boolean => {
  return token['user-email'].endsWith('.service.ardoq.com');
};

const getTokenStatusHintText = (token: APIToken, now: Date) => {
  const expirationDate = formatDateTime(
    token.tokenExpirationDate,
    getCurrentLocale()
  );
  return isExpired(token, now)
    ? `This token expired on ${expirationDate}. Regenerate the token to restore access to related services.`
    : `This token is valid until ${expirationDate}.`;
};

const isMyToken = (token: APIToken, userId: ArdoqId) => {
  return token['user-id'] === userId;
};

export const tokenTabViewModelOperations = {
  setIsLoading,
  updateSearchKey,
  switchTab,
  replaceToken,
  removeToken,
  setTokens,
  addNewUserToken,
  replaceUserToken,
  removeUserToken,
  setErrorMessage,
  isExpired,
  isExpiringSoon,
  getdaysUntilExpiration,
  isMoreThanSixtyDays,
  isServiceAccountToken,
  getTokenStatusHintText,
  isMyToken,
};
