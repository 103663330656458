export type TrackingMetadata = Record<
  string,
  string | number | boolean | string[] | null | undefined
>;
export type TrackingAction = {
  name: keyof typeof EVENT_NAMES;
  metadata?: TrackingMetadata;
};

/**
 * We include `integration` keyword in the analytics name,
 * to avoid the ambiguity of the events when visualizing events
 */
export const EVENT_NAMES = {
  // navigation
  NAVIGATED_TO_PATH: 'Navigated to integration path',
  SELECTED_TABLE: 'Selected integration tabular mapping table',
  SWITCH_TO_CLJS_INTEGRATION: 'Loaded integration clojurescript version',
  OPENED_ASSETS_OVERVIEW: 'Assets manager opened assets overview',

  // configurations
  CLEARED_CONFIG: 'Cleared current config',
  CLEARED_SHEET_CONFIG: 'Cleared sheet config',
  CLICKED_MANAGE_CONFIGURATION: 'Clicked managed integration configuration',
  LOADED_CONFIG: 'Loaded integration config',
  RESTARTED_IMPORT: 'Started new integration import flow',
  SAVED_CONFIGURATION: 'Saved integration configurations',
  TRIGGERED_CONFIG_SAVE_AS: 'Triggered current config save',
  TRIGGERED_CONFIG_SAVE: 'Triggered current config save as',

  // config step
  SELECTED_WORKSHEET_TYPE: 'Selected integration worksheet type',
  SELECTED_WORKSHEET_WORKSPACE: 'Selected integration worksheet workspace',
  APPLIED_COLUMN_MAPPING: 'Applied integration column mapping',
  TOGGLED_DATA_SYNC_STRATEGY_SECTION:
    'Interacted with integration Data Sync Strategy section',
  SELECT_DATA_SYNC_STRATEGY_OPTION:
    'Selected integration Data Sync Strategy update/create option',
  SELECT_DATA_SYNC_STRATEGY_HARD_DELETE_OPTION:
    'Selected integration Data Sync Strategy hard delete option',

  // config step - filters
  APPLIED_COLUMN_FILTERS: 'Applied integration column filters',
  CLEARED_COLUMN_FILTERS: 'Cleared integration column filters',
  OPENED_COLUMN_FILTER_MODAL: 'Opened integration column filters',
  OPENED_TRANSFORMATION_MODAL: 'Opened integration column transformations',
  OPENED_TRANSFORMATIONS: 'Integrations transformations0 opened transform data',
  OPENED_TRANSFORMATIONS_FILTERS:
    'Integrations transformations0 opened filter data',
  SELECTED_DICTIONARY_LOOKUP_TRANSFORMATION:
    'Integrations transformations0 selected dictionary lookup transformation',
  SELECTED_JSON_PATH_TRANSFORMATION:
    'Integrations transformations0 selected json path transformation',
  ROUND_NUMBER_TRANSFORMATION:
    'Integrations transformations0 round number transformation',
  ARITHMETIC_EXPRESSION_TRANSFORMATION:
    'Integrations transformations0 arithmetic expression transformation',
  AS_NUMBER_TRANSFORMATION:
    'Integrations transformations0 as number transformation',
  AS_DATE_TRANSFORMATION:
    'Integrations transformations0 as date transformation',
  AS_DATETIME_TRANSFORMATION:
    'Integrations transformations0 as datetime transformation',
  SUBSTRING_REPLACE_TRANSFORMATION:
    'Integrations transformations0 substring replace transformation',
  CONCAT_TRANSFORMATION: 'Integrations transformations0 concat transformation',
  ADDED_TRANSFORMATIONS_DICTIONARY_RULE:
    'Integrations transformations0 added new dictionary rule',
  DELETED_TRANSFORMATIONS_DICTIONARY_RULE:
    'Integrations transformations0 deleted dictionary rule',
  DELETED_TRANSFORMATIONS:
    'Integrations transformations0 deleted transformations',
  ADDED_FILTER_OPTION: 'Integrations transformations0 added new filter option',
  DELETED_TRANSFORMATIONS_FILTER_OPTION:
    'Integrations transformations0 deleted filter option',
  OPENED_WORKSHEET_OVERVIEW: 'Opened integration worksheet overview',

  // import & review step
  ERRORED_IMPORT: 'Errored integration import',
  ERRORED_EXPORT: 'Errored integration export',
  ERRORED_TEST: 'Errored integration test',
  FAILED_IMPORT: 'Failed integration import',
  FAILED_EXPORT: 'Failed integration export',
  FAILED_TEST: 'Failed integration test',
  OPENED_REVIEW_AND_IMPORT: 'Opened integration review and import view',
  SUCCEEDED_IMPORT: 'Succeeded integration import',
  SUCCEEDED_EXPORT: 'Succeeded integration export',
  SUCCEEDED_TEST: 'Succeeded integration test',
  TRIGGERED_IMPORT: 'Triggered integration import',
  TRIGGERED_EXPORT: 'Triggered integration export',
  TRIGGERED_TEST: 'Triggered integration test',
  WARNED_IMPORT: 'Warned integration import',
  WARNED_EXPORT: 'Warned integration export',
  WARNED_TEST: 'Warned integration test',

  // excel
  DOWNLOADED_TEMPLATE: 'Downloaded integration best practices template',
  OPENED_UPLOAD_VIEW: 'Opened integration upload view',
  REUPLOADED_FILE: 'Reuploaded integration file',
  TOGGLED_UPLOAD_KNOWLEDGE_SECTION:
    'Toggled integration upload knowledge section',
  UPLOADED_FILE_FAILURE: 'Uploaded integration file failure',
  UPLOADED_FILE_SUCCESS: 'Uploaded integration file',

  // actions
  CLICKED_OVERVIEW_ACTION: 'Clicked integration configurations overview action',

  // select step
  SELECTED_CONNECTION: 'Selected integration connection',
  SELECTED_SOURCE_FIELD: 'Selected integration source field',
  TRIGGERED_SOURCE_DATA_FETCH: 'Triggered integration source data fetch',
  IDENTIFIED_NON_SUPPORTED_RESOURCES:
    'Identified integration non-supported resources',

  // schedule
  SELECTED_SCHEDULE_INTERVAL: 'Selected integration schedule interval',
  ACTIVATED_SCHEDULE: 'Activated integration schedule',

  // import profile photos
  TRIGGERED_IMPORT_PROFILE_PHOTOS: 'Triggered integration photos import',
  SUCCESS_IMPORT_PROFILE_PHOTOS: 'Success integration photos import',
  FAILURE_IMPORT_PROFILE_PHOTOS: 'Failure integration photos import',

  // connections
  OPENED_CONNECTION_CONTACT_SUPPORT:
    'Opened integrations connection contact support',
  CLICKED_CONNECTION_GRANT_CONSENT:
    'Clicked integration connection grant consent',
  COPIED_CONNECTION_CONSENT_URL: 'Copied integration connection consent url',
  CREATED_CONNECTION: 'Integrations created connection successfully',

  // dashboard
  VISITED_INTEGRATIONS_DASHBOARD_PAGE: 'Integrations dashboard0 visited page',
  CLICKED_VIEW_HISTORY: 'Integrations dashboard0 clicked view history',
  CLICKED_SCHEDULE: 'Integrations dashboard0 clicked schedule',
  CLICKED_VIEW_ALL_INTEGRATIONS:
    'Integrations dashboard0 clicked view all integrations',
  CLICKED_RECENT_INTEGRATION:
    'Integrations dashboard0 clicked recent integration',
  CLICKED_DATA_HISTORY_ENTRY:
    'Integrations dashboard0 clicked data history entry',
  USED_SUGGESTED_COMPONENT_TYPE:
    'Tabular Import0 Used suggested component type',
};
