import { actionCreator } from '@ardoq/rxbeach';
import {
  SelectionResponse,
  CloudProviderConfig,
} from '@ardoq/api-types/integrations';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { AsyncStatus } from 'integrations/common/types/api';
import { Maybe } from '@ardoq/common-helpers';
import { SelectionRequestPayload } from './types';

const NAMESPACE = '[integrations azure-v3] SELECTION';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export const resetSelection = actionCreator(getActionName('RESET_SELECTION'));

// subscriptions

type ChangeSubscriptionsPayload = {
  subscriptionIds: string[];
  oldSubscriptionIds: string[];
};
export const changeSubscriptions = actionCreator<ChangeSubscriptionsPayload>(
  getActionName('CHANGE_SUBSCRIPTIONS')
);

export const applySubscriptions = actionCreator<string[]>(
  getActionName('APPLY_SUBSCRIPTIONS')
);

// resource groups

export type SelectResourceGroupPayload = {
  resourceGroupName: string;
  resourceGroupId: string;
};
export const toggleResourceGroup = actionCreator<SelectResourceGroupPayload>(
  getActionName('SELECT_RESOURCE_GROUP')
);

// configs

export const applyCloudProviderParams = actionCreator<
  CloudProviderConfig['providerParams']
>(getActionName('APPLY_CLOUD_PROVIDER_PARAMS'));

export const unselectCloudProviderParams = actionCreator(
  getActionName('UNSELECT_COULD_PROVIDER_PARAMS')
);

// filters

export const filterSubscriptionSelection = actionCreator(
  getActionName('FILTER_SELECTED_SUBSCRIPTIONS')
);

export const setActiveFilter = actionCreator<Maybe<string>>(
  getActionName('SET_ACTIVE_FILTER')
);

// fetching

export const fetchSelection = actionCreator<IntegrationId>(
  getActionName('FETCH_SELECTION')
);

export const setSelectionRequest = actionCreator<SelectionRequestPayload>(
  getActionName('SET_SELECTION_REQUEST')
);

export const setSelectionAsyncStatus = actionCreator<AsyncStatus>(
  getActionName('SET_SELECTION_ASYNC_STATUS')
);

export const setFetchErrorMessage = actionCreator<string | null>(
  getActionName('SET_FETCH_ERROR_MESSAGE')
);

export const fetchSelectionSuccess = actionCreator<SelectionResponse>(
  getActionName('FETCH_SELECTION_SUCCESS')
);
