import type { FormattingTabState, PerspectivesEditorState } from '../types';
import {
  createLabelFormatting,
  reorderLabelFormatting,
  updateLabelFormattingAt,
  deleteLabelFormatting,
  createFormattingRule as createFormattingRuleAction,
  deleteFormattingRule as deleteFormattingRuleAction,
  reorderFormattingRules as reorderFormattingRulesAction,
  updateFormattingRule as updateFormattingRuleAction,
  type UpdateFormattingRulePayload,
  showReferenceTypeToggled,
} from './actions';
import { type ExtractPayload, reducer } from '@ardoq/rxbeach';
import {
  addNewFormattingRule,
  deleteFormattingRule,
  type FormattingRule,
  updateFormattingRules,
  reorderFormattingRules,
} from '@ardoq/perspectives';
import { type LabelFormattingInfo } from '@ardoq/data-model';
import type { PerspectivesFormattingOptions } from '@ardoq/perspectives-sidebar';
import { notifyFormattingOptionsChanged } from 'perspective/actions';
import { getAppliedFormattingState } from './getAppliedFormattingState';
import {
  DEFAULT_COMPONENT_LABEL_FORMATTING,
  DEFAULT_REFERENCE_LABEL_FORMATTING,
} from 'perspective/perspectiveEditor/labelFormattingUtils';

export const getFormattingTabInitialState = (): FormattingTabState => ({
  isAppliedFormattingIncluded: false,
  conditionalFormattingRules: [],
  labelFormatting: [],
  showReferenceType: true,
  formattingOptions: {
    appliedConditionalFormattingRules: [],
    appliedComponentFormattingLabel: DEFAULT_COMPONENT_LABEL_FORMATTING,
    appliedReferenceFormattingLabel: DEFAULT_REFERENCE_LABEL_FORMATTING,
    appliedLabelFormatting: [],
    appliedShowReferenceType: false,
    componentFormattingCustomLabels: [],
    referenceFormattingCustomLabels: [],
  },
});

export const clearFormattingTabState = (
  state: PerspectivesEditorState
): FormattingTabState => ({
  ...state.formattingTab,
  conditionalFormattingRules: [],
  showReferenceType: true,
});

const handleCreateFormattingRule = (
  state: PerspectivesEditorState,
  newFormattingRule: FormattingRule
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: addNewFormattingRule(
      state.formattingTab.conditionalFormattingRules,
      newFormattingRule
    ),
  },
});

const handleCreateLabelFormatting = (
  state: PerspectivesEditorState,
  newLabelFormatting: LabelFormattingInfo
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    labelFormatting: [
      ...(state.formattingTab.labelFormatting ?? []),
      newLabelFormatting,
    ],
  },
});

const handleReorderLabelFormatting = (
  state: PerspectivesEditorState,
  reorderedLabelFormatting: LabelFormattingInfo[]
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    labelFormatting: reorderedLabelFormatting,
  },
});

const handleDeleteLabelFormatting = (
  state: PerspectivesEditorState,
  deletedLabelFormattingIndex: number
) => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    labelFormatting: state.formattingTab.labelFormatting.toSpliced(
      deletedLabelFormattingIndex,
      1
    ),
  },
});

const handleUpdateLabelFormattingAt = (
  state: PerspectivesEditorState,
  { index, ...rest }: ExtractPayload<typeof updateLabelFormattingAt>
) => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    labelFormatting: state.formattingTab.labelFormatting.toSpliced(index, 1, {
      ...state.formattingTab.labelFormatting[index],
      ...rest,
    }),
  },
});

const handleUpdateFormattingRule = (
  state: PerspectivesEditorState,
  {
    updatedFormattingRuleId,
    updatedFormattingRule,
  }: UpdateFormattingRulePayload
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: updateFormattingRules(
      state.formattingTab.conditionalFormattingRules,
      updatedFormattingRuleId,
      updatedFormattingRule
    ),
  },
});

const handleDeleteFormattingRule = (
  state: PerspectivesEditorState,
  deletedFormattingRuleId: string
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: deleteFormattingRule(
      state.formattingTab.conditionalFormattingRules,
      deletedFormattingRuleId
    ),
  },
});

const handleReorderFormattingRules = (
  state: PerspectivesEditorState,
  reorderedFormattingRulesIds: string[]
): PerspectivesEditorState => ({
  ...state,
  formattingTab: {
    ...state.formattingTab,
    conditionalFormattingRules: reorderFormattingRules(
      state.formattingTab.conditionalFormattingRules,
      reorderedFormattingRulesIds
    ),
  },
});

const getNextFormattingTabState = (
  state: PerspectivesEditorState,
  formattingOptions: PerspectivesFormattingOptions
) => {
  if (!state.formattingTab.isAppliedFormattingIncluded) {
    // include formatting applied in the main app on initial load
    return getAppliedFormattingState(formattingOptions);
  }

  return pruneInvalidFormattingState(state.formattingTab, formattingOptions);
};

const handleShowReferenceTypeToggled = (
  state: PerspectivesEditorState,
  showReferenceType: boolean
): PerspectivesEditorState => ({
  ...state,
  formattingTab: { ...state.formattingTab, showReferenceType },
});

/**
 * handleNotifyFormattingOptionsChange is emitted by the postMessageBridge and informs that the formattingOptions have changed.
 */

const handleNotifyFormattingOptionsChange = (
  state: PerspectivesEditorState,
  formattingOptions: PerspectivesFormattingOptions
): PerspectivesEditorState => {
  const formattingTabState = getNextFormattingTabState(
    state,
    formattingOptions
  );

  return {
    ...state,
    formattingTab: {
      isAppliedFormattingIncluded: true,
      ...formattingTabState,
      formattingOptions,
      labelFormatting: formattingTabState.labelFormatting,
      showReferenceType: formattingTabState.showReferenceType,
    },
  };
};

const pruneInvalidFormattingState = (
  formattingTabState: FormattingTabState,
  formattingOptions: PerspectivesFormattingOptions
) => ({
  conditionalFormattingRules: formattingTabState.conditionalFormattingRules,
  labelFormatting: formattingOptions.appliedLabelFormatting,
  showReferenceType: formattingOptions.appliedShowReferenceType,
});

export const formattingTabReducers = [
  reducer(createLabelFormatting, handleCreateLabelFormatting),
  reducer(reorderLabelFormatting, handleReorderLabelFormatting),
  reducer(deleteLabelFormatting, handleDeleteLabelFormatting),
  reducer(updateLabelFormattingAt, handleUpdateLabelFormattingAt),
  reducer(createFormattingRuleAction, handleCreateFormattingRule),
  reducer(updateFormattingRuleAction, handleUpdateFormattingRule),
  reducer(deleteFormattingRuleAction, handleDeleteFormattingRule),
  reducer(reorderFormattingRulesAction, handleReorderFormattingRules),
  reducer(notifyFormattingOptionsChanged, handleNotifyFormattingOptionsChange),
  reducer(showReferenceTypeToggled, handleShowReferenceTypeToggled),
];
