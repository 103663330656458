import workspaceWizard from 'workspaceWizard/fromTemplate/WorkspaceWizard';
import { openNewSurvey } from 'surveyAdmin/navigation/actions';
import { Folder, Row } from 'components/EntityBrowser/types';
import {
  AssetRow,
  BroadcastRow,
  MetamodelRow,
  ScenarioRow,
  SurveyRow,
  ViewpointRow,
} from 'components/AssetsBrowser/types';
import Context from 'context';
import { action$, dispatchAction, ofType } from '@ardoq/rxbeach';
import { uniq, without } from 'lodash';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { Selected } from 'aqTypes';
import { ArdoqId, Asset, AssetType, Entity } from '@ardoq/api-types';
import { apiUpdateMetamodel } from 'streams/metamodels/metamodelActions';
import WorkspacesCollectionImport from 'collections/workspaces';
import {
  createAssetFolder,
  createAssetFolderSucceeded,
  moveAssets,
  moveAssetsError,
  moveAssetsSuccess,
  createAssetFolderFailed,
} from 'streams/assetFolders/actions';
import { findIsSelected, isAssetFolder } from 'components/EntityBrowser/utils';
import {
  deleteAssets as deleteAssetsAction,
  renameAsset,
} from 'streams/assets/actions';
import * as profiling from '@ardoq/profiling';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { showPresentationEditor } from 'presentationEditor/actions';
import { saveBackboneModelAsync } from 'models/utils/modelUtils';
import {
  initiateNavigationToNewBroadcastForm,
  navigateToViewpointForm,
} from 'router/navigationActions';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { prompt } from '@ardoq/modal';
import { logError } from '@ardoq/logging';
import { TrackingLocation } from 'tracking/types';
import { dashboardRenamed } from 'streams/dashboards/actions';
import { confirmOpenWorkspaceAndClosePreviousView } from '../../viewpointNavigator/confirmOpenWorkspaceAndClosePreviousView';
import { loadedState$ } from '../../loadedState/loadedState$';
import { exitTraversalMode } from 'traversals/exitTraversalMode';
import { renameViewpoint } from '../../viewpoints/actions';
import { RowType } from '@ardoq/table';
import { renameBroadcast } from '../../broadcasts/broadcastOverview/actions';
import { moveAssetWithoutDeselection } from '../../components/AssetsBrowser2024/assetsBrowser2024Actions';
import {
  enterViewpointMode,
  traversalNamespace,
} from 'streams/traversals/actions';
import { scenarioNamespace } from 'streams/scenarios/actions';
import { presentationsNamespace } from 'streams/presentations/presentations$';
import { openPresentationBuilder } from 'presentation/presentations2024/actions';
import { surveysNamespace } from 'streams/surveys/surveys$';
import { bookmarksNamespace } from '../../streams/bookmarks/bookmarks$';
import { requestLoadWorkspaces } from 'componentHierarchies/createScopeDataRequest';
import { streamInitialized } from 'components/WorkspaceHierarchies/actions/navigatorActions';
import { firstValueFrom } from 'rxjs';
import { COMPONENT_HIERARCHIES_ID } from 'componentHierarchies/consts';

// just an alias for readability
const WorkspacesCollection = WorkspacesCollectionImport.collection;

export type OpenWorkspacesArgs = {
  forPresentationId?: ArdoqId;
  shouldAwaitPerspectives?: boolean;
  activeWorkspaceId?: ArdoqId;
  keepCurrentContext?: boolean;
  trackingLocation?: TrackingLocation;
};

export const openWorkspaces = async (
  ids: string[],
  {
    forPresentationId,
    shouldAwaitPerspectives = false,
    activeWorkspaceId,
    keepCurrentContext,
    trackingLocation,
  }: OpenWorkspacesArgs = {}
) => {
  if (ids.length === 0) return;

  // TODO this should be inside a routine - refactor when possible
  if (loadedState$.state.length > 0) {
    const confirmed = await confirmOpenWorkspaceAndClosePreviousView();
    if (!confirmed) {
      return;
    }
    exitTraversalMode();
  }

  const numberOfWorkspaces = ids.length;
  const transaction = profiling.startTransaction(
    'open workspaces',
    1000 + 500 * (numberOfWorkspaces - 1),
    profiling.Team.CORE
  );

  const loadInBackground = (
    activeWorkspaceId
      ? [activeWorkspaceId, ...without(ids, activeWorkspaceId)]
      : ids
  ).map(id => WorkspacesCollection.get(id)!);

  const setAsActive =
    keepCurrentContext && Context.activeWorkspaceId()
      ? undefined
      : loadInBackground.shift();

  await Context.loadWorkspaces({
    workspaces: loadInBackground,
    contextWorkspace: setAsActive,
    shouldAwaitPerspectives,
    workspaceOrder: ids,
    transaction: transaction,
    trackingLocation,
  });

  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
  );
  showPresentationEditorIfNeeded(forPresentationId);
  profiling.endTransaction(transaction, { metadata: { numberOfWorkspaces } });
};

export const openWorkspacesInViewpointMode = async (
  ids: string[],
  { forPresentationId }: OpenWorkspacesArgs = {}
) => {
  if (ids.length === 0) return;

  dispatchAction(enterViewpointMode());

  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
  );
  showPresentationEditorIfNeeded(forPresentationId);
  // The navigator must be displayed so that the according streams are activated.
  if (!document.getElementById(COMPONENT_HIERARCHIES_ID)) {
    await firstValueFrom(action$.pipe(ofType(streamInitialized)));
  }
  dispatchAction(requestLoadWorkspaces({ workspaceIds: ids }));
};

export const showPresentationEditorIfNeeded = (presentationId?: string) => {
  if (presentationId) {
    dispatchAction(showPresentationEditor({ presentationId }));
  }
};

type CreateInFolderArgs = {
  appModule: AppModules;
  targetFolderId: ArdoqId;
};

export const openCreateFolderModal = async (
  createInFolderArgs?: CreateInFolderArgs
): Promise<Entity | undefined> => {
  const nameOrFalse = await prompt({
    title: 'Create a new folder',
    inputLabel: 'Folder Name',
  });
  if (!nameOrFalse) {
    return undefined;
  }
  const folder =
    (
      await dispatchActionAndWaitForResponse(
        createAssetFolder({ name: nameOrFalse }),
        createAssetFolderSucceeded,
        createAssetFolderFailed
      )
    ).payload || undefined;
  if (!folder) {
    return undefined;
  }
  if (!createInFolderArgs) {
    return folder;
  }
  // Temporary solution to move the folder to the target folder until API is updated(#12889)
  dispatchAction(
    moveAssetWithoutDeselection({
      payload: {
        assets: [{ _id: folder._id, meta: { assetType: AssetType.FOLDER } }],
        folderId: createInFolderArgs.targetFolderId,
      },
      appModule: createInFolderArgs.appModule,
    })
  );
};

export const openWorkspaceWizard = () => {
  workspaceWizard();
};

export type AssetIdAndMeta = {
  _id: string;
  meta: { assetType: AssetType };
};

export const moveAssetsToFolder = async (
  assets: AssetIdAndMeta[],
  folderId: string | null
) => {
  const movedAssets = assets.map(({ _id, meta: { assetType } }) => ({
    _id,
    type: assetType!,
  }));
  await dispatchActionAndWaitForResponse(
    moveAssets({
      folderId,
      movedAssets,
    }),
    moveAssetsSuccess,
    moveAssetsError
  );
};

export const deleteAssets = async (asset: Asset[]) => {
  const assets = asset.map(({ _id, meta: { assetType } }) => ({
    _id,
    type: assetType,
  }));
  dispatchAction(deleteAssetsAction({ assets }));
};

const updateMetamodel = (
  updatePayload: { name: string },
  row: MetamodelRow
) => {
  dispatchAction(
    apiUpdateMetamodel({
      metamodelId: row._id,
      metamodelDefinition: {
        name: updatePayload.name || row.name,
        workspaceIds: row.workspaceIds,
      },
    })
  );
};

const updateSurveyName = (newName: string, row: SurveyRow) => {
  dispatchAction(
    renameAsset({
      _id: row._id,
      _version: row._version,
      name: newName,
    }),
    surveysNamespace
  );
};

const updateDiscoverViewpointName = (
  newName: string,
  { meta: _, rowType: __, ...viewpoint }: ViewpointRow
) => {
  dispatchAction(renameViewpoint({ viewpoint, name: newName }));
};

const updateBroadcastName = (
  newName: string,
  { _id, _version }: BroadcastRow
) => {
  dispatchAction(renameBroadcast({ _id, _version: _version!, name: newName }));
};

const updateFolderName = (
  newName: string,
  { _id, _version }: Folder<AssetRow>
) => {
  dispatchAction(
    renameAsset({ _id, name: newName, _version }),
    'workspacefolder'
  );
};

const updateScenarioName = (newName: string, row: ScenarioRow) => {
  dispatchAction(
    renameAsset({
      _id: row._id,
      _version: row._version,
      name: newName,
    }),
    scenarioNamespace
  );
};

const updateBackboneModel = async <T extends Backbone.Model>(
  newName: string,
  id: ArdoqId,
  collection: Backbone.Collection<T>
) => {
  const model = collection.get(id);
  if (model) await saveBackboneModelAsync(model, { name: newName });
};

export const updateNamePromise = async (
  newName: string,
  row: Row<AssetRow>
) => {
  switch (row.rowType) {
    case RowType.METAMODEL:
      updateMetamodel({ name: newName }, row);
      break;
    case RowType.REPORT:
      dispatchAction(
        renameAsset({ _id: row._id, name: newName, _version: row._version }),
        'report'
      );
      break;
    case RowType.DASHBOARD:
      dispatchAction(
        dashboardRenamed({
          _id: row._id,
          _version: row._version,
          name: newName,
        })
      );
      break;
    case RowType.SURVEY:
      updateSurveyName(newName, row);
      break;
    case RowType.VIEWPOINT:
      updateDiscoverViewpointName(newName, row);
      break;
    case RowType.BROADCAST:
      updateBroadcastName(newName, row);
      break;
    case RowType.FOLDER:
      updateFolderName(newName, row);
      break;
    case RowType.WORKSPACE:
      updateBackboneModel(newName, row._id, WorkspacesCollection);
      break;
    case RowType.MANAGED_WORKSPACE:
      updateBackboneModel(newName, row._id, WorkspacesCollection);
      break;
    case RowType.PRESENTATION:
      dispatchAction(
        renameAsset({
          _id: row._id,
          _version: row._version,
          name: newName,
        }),
        presentationsNamespace
      );
      break;
    case RowType.SCENARIO:
      updateScenarioName(newName, row);
      break;
    case RowType.TRAVERSAL:
      dispatchAction(
        renameAsset({
          _id: row._id,
          _version: row._version,
          name: newName,
        }),
        traversalNamespace
      );
      break;
    case RowType.BOOKMARK:
      dispatchAction(
        renameAsset({
          _id: row._id,
          _version: row._version,
          name: newName,
        }),
        bookmarksNamespace
      );
      break;
    default:
  }
};

// https://gomakethings.com/how-to-test-if-an-element-is-in-the-viewport-with-vanilla-javascript/
export const isInViewport = (elem: Element) => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const showTableRowById = (rowId: string) => {
  const rowEl = document.querySelector(`[data-row-id="${rowId}"]`);
  if (!rowEl || isInViewport(rowEl)) return;
  // @ts-expect-error scrollIntoViewIfNeeded is not in the Element type, because it's not supported everywhere yet, however it works well, so if we can we want to use it for the rest of the cases (IE) we will callback to scrollIntoView
  if (rowEl.scrollIntoViewIfNeeded) {
    // @ts-expect-error https://caniuse.com/#search=scrollIntoViewIfNeeded
    rowEl.scrollIntoViewIfNeeded();
  } else if (rowEl.scrollIntoView) {
    rowEl.scrollIntoView();
  }
};

type BooleanHash = {
  [permissionsFlag: string]: boolean;
};

export const findMinimalPermissions = (
  permissions: Partial<Record<string, boolean>>[]
): BooleanHash => {
  return permissions.reduce((acc: BooleanHash, itemPermissions, index) => {
    uniq([...Object.keys(acc), ...Object.keys(itemPermissions)]).forEach(
      permissionKey => {
        if (index > 0 && !acc[permissionKey]) {
          acc[permissionKey] = false;
        } else {
          acc[permissionKey] = Boolean(
            acc[permissionKey] === undefined
              ? itemPermissions[permissionKey]
              : acc[permissionKey] && itemPermissions[permissionKey]
          );
        }
        return acc as BooleanHash;
      },
      {}
    );
    return acc;
  }, {});
};

export const getPermissionsForSelection = (
  selected: ArdoqId[],
  assetsById: Record<string, Asset>
) =>
  findMinimalPermissions(
    selected
      .map(assetId => {
        const asset = assetsById[assetId];
        if (!asset) {
          logError(Error('Cannot find permissions for missing asset!'), null, {
            assetId,
            assetKeys: Object.keys(assetsById),
          });
          return null;
        }
        return asset.meta.permissions;
      })
      .filter(ExcludeFalsy)
  );

export const openPresentationCreator = () => {
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.PRESENTATIONS })
  );
  dispatchAction(openPresentationBuilder());
};

export const openNewBroadcast = (folderId: ArdoqId | null) => {
  dispatchAction(initiateNavigationToNewBroadcastForm(folderId));
};

export const openSurveyCreator = (folderId: ArdoqId | null) => {
  dispatchAction(openNewSurvey(folderId));
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.SURVEY_ADMIN })
  );
};

export const openViewpointCreator = () => {
  dispatchAction(navigateToViewpointForm(null));
};

export const findSelectedFolders = (
  selectedAssets: ArdoqId[],
  datasource: Row<AssetRow>[],
  selectedParentId?: ArdoqId
): ArdoqId[] =>
  datasource.flatMap(row => {
    if (!isAssetFolder(row)) return [];
    const isRowSelected = findIsSelected(row, selectedAssets);
    const isFolderSelected =
      isRowSelected === Selected.SELECTED ||
      isParentSelected(row, selectedParentId);

    return [
      isFolderSelected ? row._id : null,
      ...findSelectedFolders(
        selectedAssets,
        row.children || [],
        isFolderSelected ? row._id : undefined
      ),
    ].filter(ExcludeFalsy);
  });

const isParentSelected = (row: Row<AssetRow>, parentId?: ArdoqId) =>
  parentId && row.meta.folderId === parentId;
