import { s8, s16, colors } from '@ardoq/design-tokens';
import { Text, text2 } from '@ardoq/typography';
import styled from 'styled-components';
import { QuickSearchResultItem } from './types';
import {
  SearchResultHeader,
  ResultDescription,
} from './searchResultComponents';
import { Breadcrumbs } from '@ardoq/dropdown-menu-ui';
import { IconName } from '@ardoq/icons';
import { PrimaryButton } from '@ardoq/button';
import { unescapeHTML } from '@ardoq/html';
import { AdvancedSearchResultType } from '@ardoq/api-types';
import { Stack } from '@ardoq/layout';
import { MatchRenderer } from './MatchRenderer';
import { OverflowTextWrapper } from './atoms';

const SearchDetailsWrapper = styled(Stack).attrs({ gap: 'medium' })`
  width: 50%;
  padding: ${s8} ${s16};
  border-left: 1px solid ${colors.grey95};
  background-color: ${colors.white};
  ${text2};
  flex-shrink: 0;
  border-top: 1px solid ${colors.grey80};
  em {
    color: inherit;
    font-style: normal;
  }
`;

const getMeta = (type: AdvancedSearchResultType | null, path?: string[]) => {
  if (path?.length) {
    return (
      <Breadcrumbs
        breadcrumbs={[
          {
            text: unescapeHTML(path[0]),
            icon: type === 'workspace' ? IconName.FOLDER : IconName.WORKSPACE,
          },
        ]}
      />
    );
  }

  return null;
};

type ResultItemProps = {
  result: QuickSearchResultItem;
  selectItem: () => void;
};

const ResultItemDetails = ({ result, selectItem }: ResultItemProps) => {
  const meta = getMeta(result.type, result.path);
  const match = result.matches?.find(m => m.key === 'fieldValue');
  return (
    <SearchDetailsWrapper>
      <Stack gap="small">
        <SearchResultHeader result={result} lineClamp={2} showPopover />
        {meta}
      </Stack>
      <Text variant="text2">
        <ResultDescription result={result} lineClamp={6} />
      </Text>
      {result.fieldValue && (
        <OverflowTextWrapper lineClamp={5}>
          Field value: <MatchRenderer text={result.fieldValue} match={match} />
        </OverflowTextWrapper>
      )}
      <div style={{ marginTop: 'auto' }}>
        <PrimaryButton onClick={selectItem} dataTestId="open-component-button">
          Open
        </PrimaryButton>
      </div>
    </SearchDetailsWrapper>
  );
};

export default ResultItemDetails;
