import {
  APIPresentationAssetAttributes,
  ReportSlideParams,
  ViewIds,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { NewSlide } from 'presentationEditor/types';

export const addSlideToPresentationDialog = actionCreator<{
  newSlide: NewSlide;
}>('[presentationChooser] ADD_SLIDE_TO_PRESENTATION_DIALOG');

export const addSlideToExistingPresentation = actionCreator<{
  presentation: APIPresentationAssetAttributes;
  newSlide: NewSlide;
}>('[presentationChooser] ADD_SLIDE_TO_EXISTING_PRESENTATION');

export const createPresentationAndAddSlide = actionCreator<{
  presentationName: string;
  newSlide: NewSlide;
}>('[presentationChooser] CREATE_PRESENTATION_AND_ADD_SLIDE');

export const createDashboardSlide = actionCreator<{
  dashboardId: string;
  dashboardName: string;
}>('[presentationChooser] CREATE_DASHBOARD_SLIDE');

export const createReportSlide = actionCreator<{
  reportId: string;
  reportName: string;
  reportParams?: ReportSlideParams;
}>('[presentationChooser] CREATE_REPORT_SLIDE');

export const createMetamodelSlide = actionCreator<{
  name: string;
  fullOrg: boolean;
  metamodelId: string | null;
}>('[presentationChooser] CREATE_METAMODEL_SLIDE');

export const createLucidSlide = actionCreator<{
  name: string;
  lucidDocumentId: string;
}>('[presentationChooser] CREATE_LUCID_SLIDE');

export const createVisualizationSlide = actionCreator<ViewIds>(
  '[presentationChooser] CREATE_VISUALIZATION_SLIDE'
);

export const addSlideToNewOrExistingPresentation = actionCreator<NewSlide>(
  '[presentationChooser] ADD_SLIDE_TO_NEW_OR_EXISTING_PRESENTATION'
);
