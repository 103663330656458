import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { combineLatest, map, switchMap } from 'rxjs';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { TransferState } from 'integrations/common/streams/transferState/types';
import { ImportRoute } from 'integrations/common/navigation/types';
import { TabularMapping } from 'integrations/common/streams/tabularMappings/types';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import {
  getConfigureStepState,
  getReviewStepState,
  getScheduleStepState,
  getScheduleStepTitle,
} from 'integrations/common/utils/step';
import {
  ScheduleState,
  getScheduleStream,
} from 'integrations/common/streams/schedule/schedule$';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToPath } from 'integrations/common/navigation/actions';
import {
  getSchedulesStream,
  SchedulesState,
} from 'integrations/common/streams/schedules/getSchedulesStream';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { TransferConfigsState } from 'integrations/common/streams/transferConfigs/types';
import { StepDefinition } from './types';
import { isValidSelectionState } from './streams/selectionState/utils';
import { SelectionState } from './streams/selectionState/types';
import { selectionState$ } from './streams/selectionState/selectionState$';
import { isPathReady } from 'integrations/common/navigation/utils';
import { getSelectDataStepState, isStepAvailable } from './utils';
import { getTablePreviewsStream } from 'integrations/common/streams/tablePreviews/getTablePreviewsStream';
import { TablePreviews } from 'integrations/common/streams/tablePreviews/types';
import { OverlayState, overlay$ } from './streams/overlay$';

type ViewModelProps = {
  activeIntegration: ActiveIntegrationState;
  schedule: ScheduleState;
  tabularMapping: TabularMapping;
  transferState: TransferState;
  schedules: SchedulesState;
  tablePreviews: TablePreviews;
  transferConfigs: TransferConfigsState;
  selectionState: SelectionState;
  overlay: OverlayState;
};

const handleStepClickNavigation = (path: ImportRoute) => {
  dispatchAction(
    navigateToPath({
      integrationId: 'microsoft-entra-id',
      path,
      source: 'stepper',
    })
  );
};

const buildModel = ({
  activeIntegration,
  schedule,
  tabularMapping,
  transferState,
  transferConfigs,
  tablePreviews,
  schedules,
  selectionState,
  overlay,
}: ViewModelProps) => {
  const { integrationPath } = activeIntegration;
  const isDisabledStep = (path: ImportRoute) =>
    !isPathReady(path, {
      tabularMapping,
      tablePreviews,
      transferState,
      integrationId: 'microsoft-entra-id',
    }) ||
    !isStepAvailable(path, {
      selectionState,
      tabularMapping,
      transferState,
    });

  const steps: StepDefinition[] = [
    {
      step: ImportRoute.SELECT_DATA,
      label: 'Select Data',
      onPress: () => handleStepClickNavigation(ImportRoute.SELECT_DATA),
      state: getSelectDataStepState(selectionState),
      isSelected: integrationPath === ImportRoute.SELECT_DATA,
      isDisabled: isDisabledStep(ImportRoute.SELECT_DATA),
    },
    {
      step: ImportRoute.CONFIGURE,
      label: 'Configure data',
      onPress: () => handleStepClickNavigation(ImportRoute.CONFIGURE),
      state: getConfigureStepState({
        integrationPath,
        tabularMapping,
        configureRoute: ImportRoute.CONFIGURE,
      }),
      isSelected: integrationPath === ImportRoute.CONFIGURE,
      isDisabled: isDisabledStep(ImportRoute.CONFIGURE),
    },
    {
      step: ImportRoute.REVIEW,
      label: 'Review import',
      onPress: () => handleStepClickNavigation(ImportRoute.REVIEW),
      state: getReviewStepState({ transferState }),
      isSelected: integrationPath === ImportRoute.REVIEW,
      isDisabled: isDisabledStep(ImportRoute.REVIEW),
    },
    {
      step: ImportRoute.IMPORT_AND_SCHEDULE,
      label: getScheduleStepTitle(schedule.scheduleStage),
      onPress: () => handleStepClickNavigation(ImportRoute.IMPORT_AND_SCHEDULE),
      state: getScheduleStepState({ transferState, schedule }),
      isSelected: integrationPath === ImportRoute.IMPORT_AND_SCHEDULE,
      isDisabled: isDisabledStep(ImportRoute.IMPORT_AND_SCHEDULE),
    },
  ];

  return {
    activeIntegration,
    steps,
    schedule,
    isSavingDisabled: !isValidSelectionState(selectionState),
    transferConfigs,
    schedules,
    overlay,
    selectionState,
  };
};

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest({
      activeIntegration: getActiveIntegrationStream(integrationId),
      schedule: getScheduleStream(integrationId),
      tabularMapping: getTabularMappingStream(integrationId),
      transferState: getTransferStateStream(integrationId),
      schedules: getSchedulesStream(integrationId),
      tablePreviews: getTablePreviewsStream(integrationId),
      transferConfigs: transferConfigs$,
      selectionState: selectionState$,
      overlay: overlay$,
    })
  ),
  map(buildModel)
);
