import { GroupsTab, type GroupsTabProps } from '@ardoq/perspectives-sidebar';
import { connect } from '@ardoq/rxbeach';
import groupsTab$ from 'perspectiveSidebar/perspectiveEditor/groupsTab$';
import ViewOptionsPerspectivesPage from './ViewOptionsPerspectivesPage';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';

const ViewOptionsPerspectivesGroups = (props: GroupsTabProps) => (
  <ViewOptionsPerspectivesPage
    title="Groups"
    knowledgeBaseLink={KnowledgeBaseLink.GROUPING_RULES}
  >
    <GroupsTab {...props} />
  </ViewOptionsPerspectivesPage>
);

export default connect(ViewOptionsPerspectivesGroups, groupsTab$);
