import { dispatchAction } from '@ardoq/rxbeach';
import {
  deregisterInventoryGridApi,
  registerInventoryGridApi,
} from './inventoryGridApi$';
import {
  InventoryCommands,
  InventoryDatasourceSelection,
  UpdateComponentFieldValue,
  CreateComponentParam,
  UpdateComponentFieldValueParam,
  SelectedComponent,
  InventoryGridApi,
} from '@ardoq/inventory';

import { componentApi } from '@ardoq/api';
import {
  getArdoqErrorMessage,
  getArdoqErrorTraceId,
  isArdoqError,
  Maybe,
} from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import {
  updatedInventoryColumns,
  componentFieldValueWasUpdated,
} from './actions';
import { InventoryColumnDefinitionWithOptionList } from './types';
import { APIEntityType } from '@ardoq/api-types';
import {
  navigateToAuditLog,
  navigateToReportBuilder,
} from 'router/navigationActions';
import { inventoryOperations } from './inventoryOperations';
import { componentOverviewDrawerCommands } from './componentOverviewDrawer/componentOverviewDrawerCommands';
import {
  componentSelectionChanged,
  componentWasCreated,
  filtersChanged,
} from './interactiveTopRow/actions';
import { launchDeleteComponentsAndReferencesConfirmationDialogByIds } from 'components/Dialogs/confirmDeletion/confirmDeletion';
import { trackAuditLogEntryPoint } from 'auditLog/tracking';
import { inventoryTracking } from './inventoryTracking';

const updateComponentFieldValue: UpdateComponentFieldValue =
  async updatedComponentAttributes => {
    const response = await componentApi.updateComponent(
      updatedComponentAttributes
    );
    if (isArdoqError(response)) {
      logError(new Error(getArdoqErrorMessage(response)), null, {
        traceId: getArdoqErrorTraceId(response),
      });
      dispatchAction(
        componentFieldValueWasUpdated({
          error: response,
          updatePayload: updatedComponentAttributes,
        })
      );
    }
    return response;
  };

const createComponent = async (componentAttributes: CreateComponentParam) => {
  const response = await componentApi.createComponent(componentAttributes);
  if (isArdoqError(response)) {
    logError(new Error(getArdoqErrorMessage(response)), null, {
      traceId: getArdoqErrorTraceId(response),
    });
  }
  return response;
};

type InventoryInputData = {
  allAvailableColumns: InventoryColumnDefinitionWithOptionList[];
  datasource: InventoryDatasourceSelection;
};

/**
 * As AG Grid is in charge of its own state, we need to provide async commands to interact with it so that
 * inventory knows when to react to changes in the grid.
 */
export const getInventoryCommands = (
  inventoryInputData: InventoryInputData,
  updatePayload: Maybe<UpdateComponentFieldValueParam>,
  inventoryGridApi: Maybe<InventoryGridApi>
): InventoryCommands => ({
  viewComponentsInAuditLog: (selectedComponents: SelectedComponent[]) => {
    trackAuditLogEntryPoint(`inventory`);
    dispatchAction(
      navigateToAuditLog({
        entities: selectedComponents.map(({ _id, name }) => ({
          id: _id,
          name,
        })),
        entityType: APIEntityType.COMPONENT,
      })
    );
  },
  deleteComponents: componentApi.deleteComponents,
  launchDeleteComponentsAndReferencesConfirmation: (
    selectedComponents: SelectedComponent[]
  ) =>
    launchDeleteComponentsAndReferencesConfirmationDialogByIds({
      componentIds: selectedComponents.map(({ _id }) => _id),
      componentNames: selectedComponents.map(({ name }) => name),
      shouldReturnComponentBatchDeleteResponse: true,
    }),
  registerInventoryGridApi: inventoryGridApi => {
    dispatchAction(registerInventoryGridApi(inventoryGridApi));
  },
  deregisterInventoryGridApi: () => {
    dispatchAction(deregisterInventoryGridApi());
  },

  saveSelectedColumns: () => {
    if (!inventoryGridApi) {
      return;
    }
    dispatchAction(
      updatedInventoryColumns({
        columns: inventoryGridApi.getAllColumns(),
      })
    );
  },
  updateComponentFieldValue,
  saveAsReport: () => {
    if (!inventoryGridApi) {
      return;
    }

    const inventoryColumns = inventoryGridApi.getAllColumns();
    const inventoryColumnFiltersAsSubquery =
      inventoryGridApi.getFiltersAsSubquery();
    const inventoryAsReport =
      inventoryOperations.createAdHocSearchReportFromFilter(
        inventoryInputData.datasource,
        inventoryColumnFiltersAsSubquery,
        inventoryInputData.allAvailableColumns,
        inventoryColumns
      );

    dispatchAction(navigateToReportBuilder(inventoryAsReport));

    inventoryTracking.trackSaveAsReport(
      inventoryColumnFiltersAsSubquery.rules.length
    );
  },
  createComponent,
  retryComponentUpdate: async () => {
    if (!updatePayload) {
      return null;
    }

    const response = await updateComponentFieldValue(updatePayload);
    if (!isArdoqError(response)) {
      dispatchAction(componentFieldValueWasUpdated(null));
    }
    return response;
  },
  showComponentOverviewDrawer: componentOverviewDrawerCommands.openDrawer,
  setSelectedComponents: selectedComponents => {
    dispatchAction(componentSelectionChanged(selectedComponents));
  },
  notifyFilterChanged: subquery => {
    dispatchAction(filtersChanged(subquery));
  },
  incrementTotalNumberOfComponents: () => dispatchAction(componentWasCreated()),
});
