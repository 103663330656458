import { actionCreator } from '@ardoq/rxbeach';
import { APIViewpointAttributes, ArdoqId } from '@ardoq/api-types';
import { ComponentTypeProps } from 'viewpointBuilder/components/ComponentType';

export type ApplySavedTraversalComponent = ComponentTypeProps & {
  id: string;
  entityType: string;
};

export type OpenApplySavedViewpointModalPayload = {
  selectedComponents: ApplySavedTraversalComponent[];
  componentType: string;
};

export const openApplySavedViewpointModal =
  actionCreator<OpenApplySavedViewpointModalPayload>(
    '[applySavedViewpoint] OPEN_APPLY_SAVED_VIEWPOINT_MODAL'
  );

export type SetSearchTraversalInitialStatePayload = {
  traversals: APIViewpointAttributes[];
  currentUserId: ArdoqId;
};
export const setSearchTraversalInitialState =
  actionCreator<SetSearchTraversalInitialStatePayload>(
    '[applySavedViewpoint] SET_INITIAL_STATE'
  );

export const setSelectedViewpointIds = actionCreator<string[]>(
  '[applySavedViewpoint] SET_SELECTED_TRAVERSALS_IDS'
);

export const toggleSelection = actionCreator<string>(
  '[applySavedViewpoint] TOGGLE_SELECTION'
);

export const toggleShouldDisplayOnlyViewpointsOfCurrentUser = actionCreator(
  '[applySavedViewpoint] TOGGLE_SHOULD_DISPLAY_ONLY_VIEWPOINTS_OF_CURRENT_USER'
);

export const setSearchPhrase = actionCreator<string>(
  '[applySavedViewpoint] SET_SEARCH_PHRASE'
);
