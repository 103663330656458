import 'css/index.css';
import 'common-bootstrap';
import 'less/libs.less';
import 'less/app.less';
import 'less/print.less';
// eslint-disable-next-line ardoq-lint/no-packages-src-import
import '@ardoq/icons/dist/fontIcons.css';
// eslint-disable-next-line ardoq-lint/no-packages-src-import
import '@ardoq/rich-text-editor/dist/style.css';
// eslint-disable-next-line ardoq-lint/no-packages-src-import
import '@ardoq/typography/dist/fonts/style.css';
import Cookie from 'cookie_js';
import { startApplication } from 'ardoq.app';
import organizationNameFromQueryString from 'utils/organizationNameFromQueryString';

const newOrg = organizationNameFromQueryString();
const org = Cookie.get('organization');
if (newOrg && org !== newOrg) {
  const params = document.location.search
    .replace('?', '')
    .split('&')
    .filter(Boolean)
    .filter(function (param) {
      return !param.match('org=');
    })
    .join('&');

  document.location = [
    '/api/switchOrg?org=',
    newOrg,
    '&url=',
    window.encodeURIComponent(document.location.pathname),
    window.encodeURIComponent(params.length ? `?${params}` : ''),
  ].join('');
}

startApplication();
