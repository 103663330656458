import traversals$ from 'streams/traversals$';
import currentUser$, {
  CurrentUserState,
  getFavorites,
} from 'streams/currentUser/currentUser$';
import { AssetType, PersonalSetting } from '@ardoq/api-types';
import {
  derivedStream,
  dispatchAction,
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { traversalOperations } from 'traversals/traversalOperations';
import { viewpointWidgetOperations } from './operations';
import { setActiveTab, dismissBanner, setIsExpanded } from './actions';
import {
  WidgetState,
  ViewpointsWidgetStreamShape,
  ViewpointsWidgetCommands,
} from './types';
import { navigateToNewViewpoints } from 'router/navigationActions';
import { openViewpointBuilderWithoutClosingLoadedViewpoints } from 'viewpointBuilder/actions';
import { getTraversalMenuOptions } from 'appContainer/DashboardContainer/getMenuOptions';
import { TraversalRow } from 'components/AssetsBrowser/types';
import { viewpointPreviewCommands } from 'components/TraversalPreview/viewpointPreviewCommands';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { traversalAccessControlInterface } from 'resourcePermissions/accessControlHelpers/traversals';
import { TraversalState } from 'streams/traversals/types';
import { traversalStateOperations } from 'streams/traversals/traversalsStateOperations';
import { renameViewpoint } from 'streams/traversals/actions';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { toggleFavorite } from 'streams/currentUser/actions';
import { trackEvent } from 'tracking/tracking';

const toggleFavouriteViewpoint = (traversal: TraversalRow) => {
  return {
    label: traversal.meta.favorite ? 'Unfavorite' : 'Favorite',
    type: DropdownOptionType.OPTION,
    onClick: () => {
      handleDropdownMenuTracking('toggled favorite', {
        toggledTo: !traversal.meta.favorite,
        traversalId: traversal._id,
      });
      dispatchAction(
        toggleFavorite({ _id: traversal._id, type: AssetType.TRAVERSAL })
      );
    },
  };
};

export const handleDropdownMenuTracking = (action: string, metadata = {}) => {
  trackEvent('Viewpoints widget: clicked dropdown menu', {
    action,
    ...metadata,
  });
};

const defaultState: WidgetState = {
  activeTab: 'lastUpdated',
  isBannerDismissed: false,
  isExpanded: true,
};

const handleCurrentUserChange = (
  state: WidgetState,
  currentUser: CurrentUserState
) => {
  const viewpointsStateSettings =
    currentUser.settings[PersonalSetting.HOMEPAGE_VIEWPOINTS_STATE];
  const {
    activeTab = state.activeTab,
    isBannerDismissed = state.isBannerDismissed,
    isExpanded = state.isExpanded,
  } = viewpointsStateSettings ?? state;

  return { ...state, activeTab, isBannerDismissed, isExpanded };
};

const widgetTab$ = persistentReducedStream<WidgetState>(
  'widgetTab$',
  defaultState,
  [
    streamReducer(currentUser$, handleCurrentUserChange),
    reducer(setIsExpanded, viewpointWidgetOperations.setIsExpanded),
    reducer(setActiveTab, viewpointWidgetOperations.setActiveTab),
    reducer(dismissBanner, viewpointWidgetOperations.dismissBanner),
  ]
);

const sortByLastUpdated = (
  traversalA: TraversalRow,
  traversalB: TraversalRow
) => {
  if (!traversalA.lastUpdated) return 1;
  if (!traversalB.lastUpdated) return -1;
  return traversalA.lastUpdated > traversalB.lastUpdated ? -1 : 1;
};

const viewpointsWidgetCommands: ViewpointsWidgetCommands = {
  setActiveTab: tabId => dispatchAction(setActiveTab(tabId)),
  seeAllViewpoints: () => dispatchAction(navigateToNewViewpoints()),
  dismissBanner: () => dispatchAction(dismissBanner()),
  createNewViewpoint: () =>
    dispatchAction(openViewpointBuilderWithoutClosingLoadedViewpoints()),
  setIsExpanded: isExpanded => dispatchAction(setIsExpanded(isExpanded)),
  ...viewpointPreviewCommands,
};

const toViewpointsWidgetStream = ([
  currentUser,
  traversals,
  { activeTab, isBannerDismissed, isExpanded },
]: [
  CurrentUserState,
  TraversalState,
  WidgetState,
]): ViewpointsWidgetStreamShape => {
  const favoriteTraversalIds = getFavorites(currentUser)[AssetType.TRAVERSAL];

  const permissionContext = currentUserInterface.getPermissionContext();

  const traversalsAsDataSource = traversalStateOperations
    .getAsList(traversals)
    .map(traversal => {
      return traversalOperations.toTraversalRepresentationInOverview(
        traversal,
        favoriteTraversalIds,
        permissionContext
      );
    });

  const favoriteTraversals = traversalsAsDataSource.filter(
    traversal => traversal.meta.favorite
  );

  const releventTraversals =
    activeTab === 'favorites'
      ? favoriteTraversals.sort(sortByLastUpdated)
      : traversalsAsDataSource.sort(sortByLastUpdated);

  return {
    hasTraversals: !traversalStateOperations.isEmpty(traversals),
    filteredTraversals: releventTraversals.slice(0, 10),
    activeTab,
    isBannerDismissed,
    isExpanded,
    canUserCreateTraversals:
      traversalAccessControlInterface.canCreateTraversal(permissionContext),
    getTraversalMenuOptions: traversal => [
      toggleFavouriteViewpoint(traversal),
      ...getTraversalMenuOptions(traversal, {
        clearSelected: () => {},
        onRenameSelect: () => {
          dispatchAction(renameViewpoint(traversal));
        },
        selected: [],
        selectedAssets: [],
        rowPermissions: traversal.meta.permissions,
        selectionPermissions: {},
        location: 'Viewpoints widget',
      }),
    ],
    commands: viewpointsWidgetCommands,
  };
};

const viewpointsWidget$ = derivedStream(
  'viewpointsWidget$',
  currentUser$,
  traversals$,
  widgetTab$
).pipe(map(toViewpointsWidgetStream));

export default viewpointsWidget$;
