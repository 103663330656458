import { UnifiedSourceConfig } from '@ardoq/api-types/integrations';
import { UnifiedIntegrationId } from 'integrations/unified/types';
import { actionCreator } from '@ardoq/rxbeach';

const NAMESPACE = '[integrations unified] CONFIG';

export type ApplySourceConfigPayload = {
  integrationId: UnifiedIntegrationId;
  sourceConfig: UnifiedSourceConfig;
};

export const applySourceConfig = actionCreator<ApplySourceConfigPayload>(
  `${NAMESPACE}_APPLY_SOURCE_CONFIG`
);
