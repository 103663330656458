import { MetaModelComponentType } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

const DOMAIN = '[ComponentTypes]';

export const getComponentTypes = actionCreator(`${DOMAIN} getComponentTypes`);

export const getComponentTypesFinished = actionCreator<
  MetaModelComponentType[]
>(`${DOMAIN} getComponentTypesFinished`);
