import { MicrosoftEntraIdTable } from './types';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { resetState } from './actions';

export type MicrosoftEntraIdTablesState = {
  tables: MicrosoftEntraIdTable[];
};

export const userTableFields = [
  { id: 'id', name: 'ID' },
  { id: 'displayName', name: 'Display name' },
  { id: 'userPrincipalName', name: 'Email' },
  { id: 'givenName', name: 'Given name' },
  { id: 'surname', name: 'Surname' },
  { id: 'jobTitle', name: 'Job title' },
  { id: 'preferredLanguage', name: 'Preferred language' },
  { id: 'mail', name: 'Mail' },
  { id: 'officeLocation', name: 'Office location' },
  { id: 'mobilePhone', name: 'Mobile phone' },
  { id: 'businessPhones', name: 'Business phones' },
  { id: 'companyName', name: 'Company name' },
  { id: 'country', name: 'Country' },
  { id: 'department', name: 'Department' },
  { id: 'accountEnabled', name: 'Account enabled' },
  {
    id: 'onPremisesDistinguishedName',
    name: 'On premises distinguished name',
  },
  { id: 'employeeId', name: 'Employee ID' },
  { id: 'employeeType', name: 'Employee type' },
];

const defaultState: MicrosoftEntraIdTablesState = {
  tables: [
    {
      id: 'user',
      name: 'user',
      fields: userTableFields,
    },
  ],
};

const resetStateReducer = () => defaultState;

const handleResetState = reducer<MicrosoftEntraIdTablesState>(
  resetState,
  resetStateReducer
);

const reducers = [handleResetState];

export const microsoftEntraIdTables$ = persistentReducedStream(
  `microsoftEntraIdTables$`,
  defaultState,
  reducers
);
