import { AllowlistType } from '@ardoq/api-types';
import { IPAllowlistingTab, ParsedAddress } from './types';

export const allowlistTypeOptionMetadata: Record<
  AllowlistType,
  { label: string; description: string }
> = {
  any: {
    label: 'All requests',
    description: 'Users with this address can access everything',
  },
  api: {
    label: 'API requests',
    description: 'Users with this address can only make API requests',
  },
};

export const maskSizeHelpText: Record<ParsedAddress['maskSizeSource'], string> =
  {
    explicit: 'Extracted from CIDR block in address field',
    inferred:
      'This is inferred as the default value IPv4 addresses. Set it explicitly by using CIDR notation.',
  };

type Metadata = { headerText: string; tabLabel: string; description?: string };
export const ipTabMetadata: Record<IPAllowlistingTab, Metadata> = {
  MANAGE: {
    headerText: 'Manage existing allowlisted IP entries',
    tabLabel: 'Manage entries',
  },
  CREATE: {
    headerText: 'Add new allowlisted IP address',
    tabLabel: 'Add new',
    description:
      'Include your own IP address in the list of IP addresses you intend to allow to prevent being locked out of your account.',
  },
  BLOCKED_MESSAGE: {
    headerText: 'Blocked access message',
    tabLabel: 'Blocked access message',
    description:
      'Create a custom mesage for blocked users or IPs, or use the default message.',
  },
  STATUS: { headerText: '', tabLabel: 'Status' },
};
