import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import {
  Font,
  FontWeight,
  Size,
  TextMeasurePolicy,
  TextWrapping,
} from '@ardoq/yfiles';

export const NODE_LABEL_FONT = new Font({
  fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  fontSize: 32,
  fontWeight: FontWeight.ITEM400,
});
export const EDGE_LABEL_FONT = new Font({
  fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  fontSize: 24,
  fontWeight: FontWeight.ITEM400,
});
const MAX_WIDTH = 300;
export const NAME_LABEL_MAX_SIZE = new Size(
  MAX_WIDTH,
  8 * NODE_LABEL_FONT.fontSize
);
export const LABEL_TEXT_PARAMS = {
  maximumSize: NAME_LABEL_MAX_SIZE,
  wrapping: TextWrapping.WORD_ELLIPSIS,
  measurePolicy: TextMeasurePolicy.SVG,
};
