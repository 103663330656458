import { SpinnerIcon } from '@ardoq/icons';
import { ComponentType } from 'react';
import styled from 'styled-components';

type ProgressProps = { progress: number };

const Container = styled.div<{ $visible: boolean }>`
  margin: 0 auto;
  display: flex;
  align-items: center;
  transition: opacity 0.6s ease;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
`;

const ProgressBackground = styled.div`
  width: 200px;
  height: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const ProgressBar = styled.div.attrs<{ $progress: number }>(
  ({ $progress }) => ({
    style: { width: `${$progress * 100}%` },
  })
)`
  float: left;
  height: 100%;
  background-color: #337ab7;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 0.3s ease;
` as ComponentType<{ $progress: number }>;

export const SaveBar = ({ progress }: ProgressProps) => {
  const visible = progress >= 0 && progress < 1;

  return (
    <Container $visible={visible}>
      {/*
        NOTE: This spinner is very expensive (~20% of a CPU core), and by only
        hiding it with the opacity of the parent it still consumes resources.
        This was a quick-fix in order to reduce the idling CPU usage of Ardoq.
        More context in ARD-20311.
      */}
      {visible && <SpinnerIcon />}
      <ProgressBackground>
        <ProgressBar $progress={progress} />
      </ProgressBackground>
    </Container>
  );
};
