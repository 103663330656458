import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { LoadedScenarioData } from 'loadedScenarioData$';
import { ShowRelatedComponentsShape } from 'scenarioRelated/types';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { CollectionView } from 'collections/consts';
import type { GraphModelShape, ContextShape } from '@ardoq/data-model';
import {
  AggregatedGraphEdge,
  GraphEdge,
  GraphItem,
  GraphNode,
  ParentChildGraphEdge,
  type TraverseOptions,
  aggregateReferences,
  type HasGetDistinctReferenceKey,
  type RelationshipDiagramViewSettings,
  type TraversalAndGroupingResult,
  type RelationshipDiagramViewModel,
  LoadedGraphWithViewpointMode,
  getGraphInterfaceWithModelInterfaces,
  buildGraphFromTraversalAndSearch,
  getRelevantComponentIds,
  getIsShowReferenceTypeApplied,
  getGraphNodeOrGroupLabel,
  ItemLabels,
  getGraphEdgeLabel,
} from '@ardoq/graph';
import {
  combineGraphs,
  getShortestPaths,
  hasGroupByParentAll,
} from 'modelInterface/graph/utils';
import { GraphItemsModel } from 'tabview/graphComponent/types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import {
  buildTraversalViewModel,
  getGroupingRules,
  getTraversalConfiguration,
} from 'tabview/graphViews/traversalViewModel';
import {
  TRAVERSE_ALL_BASE,
  TRAVERSE_PARENTS_REFTYPES,
} from 'modelInterface/graph/consts';
import {
  RawGraphItem,
  getUrlFieldValuesByComponentId,
  collapseGraph,
  getUrlFieldValuesByReferenceId,
  graphGroupsMap,
} from '@ardoq/graph';
import { buildGraph } from 'modelInterface/graph/buildGraphForWorkspaceMode';
import { filterInterface } from '@ardoq/filter-interface';

const TRAVERSE_ALL_PARENTS: TraverseOptions = {
  ...TRAVERSE_ALL_BASE,
  incomingReferenceTypes: TRAVERSE_PARENTS_REFTYPES,
  outgoingReferenceTypes: new Set(),
};

const toViewModel = <T extends GraphItem>(items: T[]): GraphItemsModel<T> => ({
  byId: new Map<ArdoqId, T>(items.map(item => [item.id, item])),
  add: items.map(item => item.id),
  update: [],
  remove: [],
});

/**
 * An interface layer for getting the labels data. In an ideal world it should be just
 * itemLabelData.get(rawGraphItem.id), but we want to ensure the consistency between
 * the raw and view graph items.
 */
const getItemLabelsFromData = (
  itemLabelData: Map<string, ItemLabels>,
  rawGraphItem: RawGraphItem,
  isMultiLabelFormattingActive: boolean,
  isNode: boolean,
  isShowReferenceTypeApplied = false
): ItemLabels => {
  const existingData = itemLabelData.get(rawGraphItem.id);

  if (!existingData) {
    logError(
      new Error(
        `No labels data found in view model for ${isNode ? 'node' : 'edge'}`
      )
    );

    const labelsData = isNode
      ? getGraphNodeOrGroupLabel(rawGraphItem, isMultiLabelFormattingActive)
      : getGraphEdgeLabel(
          rawGraphItem,
          isMultiLabelFormattingActive,
          isShowReferenceTypeApplied
        );

    itemLabelData.set(rawGraphItem.id, labelsData);

    return labelsData;
  }

  return existingData;
};

const getGraphEdge = (
  rawGraphItem: RawGraphItem,
  graphNodes: Map<string, GraphItem>,
  itemLabels: ItemLabels
) => {
  const {
    id: referenceId,
    modelId: referenceModelId,
    modelIds: referenceModelIds,
    sourceId,
    targetId,
    isParentChildReference,
  } = rawGraphItem;

  if (referenceModelIds) {
    return AggregatedGraphEdge.create({
      modelIds: referenceModelIds,
      sourceId: sourceId!,
      targetId: targetId!,
      graphNodeMap: graphNodes,
      id: referenceId,
      itemLabels,
      isParentChildReference,
    });
  }
  if (isParentChildReference) {
    return ParentChildGraphEdge.create(
      referenceModelId!,
      sourceId!,
      targetId!,
      graphNodes,
      referenceId,
      itemLabels
    );
  }
  return GraphEdge.create(
    referenceModelId!,
    sourceId!,
    targetId!,
    graphNodes,
    referenceId,
    undefined, // metaData
    itemLabels
  );
};

const getNodeId = (
  item: RawGraphItem,
  groupMap: Map<string, RawGraphItem>
): string => {
  const { id, modelId, parentId } = item;
  if (!parentId) {
    return id;
  }
  const parent = groupMap.get(parentId);
  if (!parent) {
    logError(Error('Parent not found in component map.'));
    return id;
  }
  return `${getNodeId(parent, groupMap)}_${modelId}`;
};

const getGraphNode = (
  rawGraphItem: RawGraphItem,
  groupMap: Map<string, RawGraphItem>,
  itemLabels: ItemLabels
) => {
  const { modelId, isGhost } = rawGraphItem;
  const id = getNodeId(rawGraphItem, groupMap);
  return GraphNode.create(
    modelId!,
    isGhost,
    id,
    undefined, // metaData
    itemLabels
  );
};

const getStartSet = (context: ContextShape): string[] => {
  const selectedScenario = context.scenarioId;
  if (selectedScenario) {
    return context.workspacesIds.flatMap(workspaceId =>
      componentInterface.getRootComponents(
        workspaceId,
        CollectionView.WITH_PLACEHOLDERS
      )
    );
  }

  const selectedComponentId = context.componentId;
  if (selectedComponentId) {
    return componentInterface.isIncludedInContextByFilter(selectedComponentId)
      ? [selectedComponentId]
      : getRelevantComponentIds(context);
  }

  if (context.workspaceId) {
    return componentInterface.getRootComponents(
      context.workspaceId,
      CollectionView.WITH_PLACEHOLDERS
    );
  }
  return [];
};

interface AddScenarioRelatedComponentsArgs {
  loadedScenarioData: LoadedScenarioData;
  showScopeRelated: boolean;
  scenarioRelatedComponentIds: string[];
  componentMap: Map<string, RawGraphItem>;
  referenceMap: Map<string, RawGraphItem>;
  graphModel: GraphModelShape;
}
/** Add scenario related components with their shortest path */
const addScenarioRelatedComponents = ({
  loadedScenarioData,
  showScopeRelated,
  scenarioRelatedComponentIds,
  componentMap,
  referenceMap,
  graphModel,
}: AddScenarioRelatedComponentsArgs) => {
  const { scenarioRelatedGraph, scenarioData } = loadedScenarioData;

  if (
    showScopeRelated &&
    scenarioRelatedGraph &&
    scenarioData &&
    scenarioRelatedComponentIds &&
    !isInScopeDiffMode()
  ) {
    const {
      connected: { connectedComponents },
    } = scenarioData;
    const connectedComponentsSet = new Set(connectedComponents);
    const targetComponentIds = new Set(componentMap.keys());
    const combinedGraph = combineGraphs(graphModel, scenarioRelatedGraph);
    scenarioRelatedComponentIds.forEach((componentId: string) => {
      const shortestPath = getShortestPaths({
        componentId,
        graph: combinedGraph,
        targetComponentIds,
      });
      if (shortestPath) {
        shortestPath.components.forEach(rawGraphItem =>
          componentMap.set(rawGraphItem.id, {
            ...rawGraphItem,
            isGhost: !connectedComponentsSet.has(rawGraphItem.id),
          })
        );
        shortestPath.references
          .filter(rawGraphItem => !referenceMap.has(rawGraphItem.id))
          .forEach(rawGraphItem => {
            referenceMap.set(rawGraphItem.id, rawGraphItem);
          });
      } else {
        componentMap.set(componentId, {
          id: componentId,
          modelId: componentId,
        });
      }
    });
  }
};

interface SetTraverseOptionsArgs {
  menuConfig: TraverseOptions;
  referenceTypeMap: Map<string, string[]>;
}
export type BuildViewModelArgs = {
  context: ContextShape;
  viewSettings: RelationshipDiagramViewSettings;
  graphModel: GraphModelShape;
  loadedScenarioData: LoadedScenarioData;
  scenarioRelatedComponents: ShowRelatedComponentsShape;
  expandedStateChangingGroupId: string | null;
  showScopeRelated: boolean;
  overrideTraverseOptions?: (args: SetTraverseOptionsArgs) => TraverseOptions;
  shouldCollapseGraph: boolean;
  loadedGraph: LoadedGraphWithViewpointMode;
  viewId: ViewIds;
} & HasGetDistinctReferenceKey;

type BuildViewModelResult = RelationshipDiagramViewModel & {
  rawGraph: TraversalAndGroupingResult;
  componentIdsOfCollapsedGroups: string[];
  nodeLabelsData: Map<string, ItemLabels>;
  edgeLabelsData: Map<string, ItemLabels>;
};

const VIEWPOINT_MODE_VIEWS = new Set([
  ViewIds.BLOCK_DIAGRAM,
  ViewIds.PROTEAN_DIAGRAM,
  ViewIds.RELATIONSHIPS_3,
  ViewIds.BLOCKS,
  ViewIds.MODERNIZED_BLOCK_DIAGRAM,
]);

const getRawGraph = ({
  context,
  viewSettings,
  graphModel,
  loadedScenarioData,
  scenarioRelatedComponents,
  showScopeRelated,
  overrideTraverseOptions = ({ menuConfig }) => menuConfig,
  loadedGraph,
  viewId,
}: BuildViewModelArgs) => {
  const isViewpointMode = loadedGraph.isViewpointMode;

  if (VIEWPOINT_MODE_VIEWS.has(viewId) && isViewpointMode) {
    const groupingRules = getGroupingRules({
      loadedScenarioData,
      showScopeRelated,
      scenarioRelatedComponentIds: scenarioRelatedComponents.componentIds,
    });

    const graphInterface = getGraphInterfaceWithModelInterfaces();

    const rawGraph = buildGraphFromTraversalAndSearch({
      graphInterface,
      graph: graphModel,
      groupingRules,
      viewId,
      loadedGraph,
      allComponentIdsRelevantForFilterCache:
        componentInterface.getAllComponentIds(), // This is a hack to satisfy the broken FilterCache.
    });
    // Used for the traversal dropdown which is disabled in viewpoint mode.
    const traversalViewModel = {
      traversedOutgoingReferenceTypes: [],
      traversedIncomingReferenceTypes: [],
      referenceTypes: [],
    };
    return { rawGraph, traversalViewModel };
  }

  const startSet = getStartSet(context);

  const groupingRules = getGroupingRules({
    loadedScenarioData,
    showScopeRelated,
    scenarioRelatedComponentIds: scenarioRelatedComponents.componentIds,
  });

  const {
    traverseOptions,
    sequentialTraverseOptions: sequentialTraversalConfiguration,
    referenceTypeMap,
  } = getTraversalConfiguration({
    viewSettings,
    graphModel,
    scenarioRelatedComponents,
  });
  const sequentialTraverseOptions = hasGroupByParentAll(groupingRules)
    ? [...sequentialTraversalConfiguration, TRAVERSE_ALL_PARENTS]
    : sequentialTraversalConfiguration;

  /**
   * remove interface calls from viewModel scope and pass GraphContext
   * as argument to use scopeData utils.
   * Currently, the graphInterface is used to ensure that the graph can
   * be built with scopeData.
   */
  const graphInterface = getGraphInterfaceWithModelInterfaces();

  const traversalViewModel = buildTraversalViewModel({
    graphInterface,
    graphModel,
    startSet,
    traverseOptions,
  });

  // Currently there are three levels of graph items:
  // the raw data objects returned from `buildGraph`,
  // the `GraphItem`s (with the according subclasses) serving as binding
  // layer to the existing code and the actual yFile nodes.

  // Raw graph data
  const rawGraph = buildGraph({
    graphInterface,
    graph: graphModel,
    startSet,
    groupingRules,
    traverseOptions: overrideTraverseOptions({
      menuConfig: traverseOptions,
      referenceTypeMap,
    }),
    viewId,
    sequentialTraverseOptions,
    includeOnlyConnectedComponents: viewSettings.showOnlyConnectedComponents,
    useNewGrouping: viewSettings.useNewGrouping,
  });

  return { rawGraph, traversalViewModel };
};

const buildViewModel = ({
  context,
  viewSettings,
  graphModel,
  loadedScenarioData,
  scenarioRelatedComponents,
  expandedStateChangingGroupId,
  showScopeRelated,
  overrideTraverseOptions = ({ menuConfig }) => menuConfig,
  getDistinctReferenceKey,
  shouldCollapseGraph,
  loadedGraph,
  viewId,
}: BuildViewModelArgs): BuildViewModelResult => {
  const isViewpointMode = loadedGraph.isViewpointMode;

  const { rawGraph, traversalViewModel } = getRawGraph({
    context,
    viewSettings,
    graphModel,
    loadedScenarioData,
    scenarioRelatedComponents,
    expandedStateChangingGroupId,
    showScopeRelated,
    overrideTraverseOptions,
    getDistinctReferenceKey,
    shouldCollapseGraph,
    loadedGraph,
    viewId,
  });

  const {
    componentMap: initialComponentMap,
    referenceList: initialReferenceList,
    groupMap: initialGroupMap,
    errors,
    hasClones,
  } = rawGraph;
  // Property 'useNewGrouping' does not exist on type 'RelationshipDiagramViewSettings

  const initialReferenceMap = new Map(
    initialReferenceList.map(ref => [ref.id, ref])
  );
  addScenarioRelatedComponents({
    loadedScenarioData,
    showScopeRelated,
    scenarioRelatedComponentIds: scenarioRelatedComponents.componentIds,
    componentMap: initialComponentMap,
    referenceMap: initialReferenceMap,
    graphModel,
  });

  const collapsedGroupIds = new Set(viewSettings.collapsedGroupIds);
  const {
    componentMap,
    referenceList: collapsedReferenceList,
    groupMap,
    componentIdsOfCollapsedGroups,
  } = shouldCollapseGraph
    ? collapseGraph({
        componentMap: initialComponentMap,
        referenceList: [...initialReferenceMap.values()],
        groupMap: initialGroupMap,
        collapsedGroupIds,
      })
    : {
        componentMap: initialComponentMap,
        referenceList: initialReferenceList.map(reference => ({
          ...reference,
          uncollapsedSourceId: reference.sourceId,
          uncollapsedTargetId: reference.targetId,
        })),
        groupMap: initialGroupMap,
        componentIdsOfCollapsedGroups: [],
      };
  const referenceList = aggregateReferences({
    referenceList: collapsedReferenceList,
    getDistinctReferenceKey,
    componentMap: initialComponentMap,
    groupMap: initialGroupMap,
    collapsedGroupIds,
    isInDiffMode: isInScopeDiffMode(),
  });
  const referenceMap = new Map(referenceList.map(r => [r.id, r]));
  const rawReferences = Array.from(referenceMap.values());

  const nodeLabelsData = new Map(
    [...componentMap, ...groupMap].map(([id, item]) => [
      id,
      getGraphNodeOrGroupLabel(item, isViewpointMode),
    ])
  );

  const isShowReferenceTypeApplied = getIsShowReferenceTypeApplied(
    filterInterface.getAllReferenceLabelFilters()
  );
  const edgeLabelsData = new Map<string, ItemLabels>(
    rawReferences.map(rawGraphItem => [
      rawGraphItem.id,
      getGraphEdgeLabel(
        rawGraphItem,
        isViewpointMode,
        isShowReferenceTypeApplied
      ),
    ])
  );

  // Binding layer
  const groupNodes = graphGroupsMap(
    groupMap,
    collapsedGroupIds,
    nodeLabelsData
  );
  const rawGroups = Array.from(groupMap.values());
  rawGroups.forEach(
    ({ id, parentId }) =>
      (groupNodes.get(id)!.parent = groupNodes.get(parentId!))
  );

  const rawComponents = Array.from(componentMap.values());

  const graphNodes = new Map(
    rawComponents.map(rawGraphItem => {
      const itemLabels = getItemLabelsFromData(
        nodeLabelsData,
        rawGraphItem,
        isViewpointMode,
        true
      );

      return [
        rawGraphItem.id,
        getGraphNode(rawGraphItem, groupMap, itemLabels),
      ];
    })
  );
  rawComponents.forEach(
    ({ id, parentId }) =>
      (graphNodes.get(id)!.parent = groupNodes.get(parentId!))
  );

  const componentAndGroupMap = new Map<string, GraphItem>([
    ...graphNodes,
    ...groupNodes,
  ]);

  const edges = rawReferences.map(rawGraphItem => {
    const itemLabels = getItemLabelsFromData(
      edgeLabelsData,
      rawGraphItem,
      isViewpointMode,
      false,
      isShowReferenceTypeApplied
    );

    return getGraphEdge(rawGraphItem, componentAndGroupMap, itemLabels);
  });

  const noConnectedComponents =
    viewSettings.showOnlyConnectedComponents &&
    graphNodes.size + groupNodes.size === 0;

  const contextComponentIsNotConnected =
    noConnectedComponents && context.componentId;

  const contextGraphNodeReplacement =
    contextComponentIsNotConnected && GraphNode.create(context.componentId);

  const nodes: GraphItemsModel<GraphNode> = contextGraphNodeReplacement
    ? {
        add: [contextGraphNodeReplacement.id],
        update: [],
        remove: [],
        byId: new Map([
          [contextGraphNodeReplacement.id, contextGraphNodeReplacement],
        ]),
      }
    : toViewModel([...graphNodes.values()]);

  const componentIds = [...rawComponents, ...rawGroups]
    .filter(({ modelId }) => modelId && componentInterface.isComponent(modelId))
    .map(({ modelId }) => modelId!);
  const referenceIds = rawReferences.map(({ modelId }) => modelId!);
  return {
    nodes,
    edges: toViewModel(edges),
    groups: toViewModel([...groupNodes.values()]),
    errors,
    hasClones,
    ...traversalViewModel,
    expandedStateChangingGroupId,
    noConnectedComponents,
    urlFieldValuesByComponentId: getUrlFieldValuesByComponentId(componentIds),
    urlFieldValuesByReferenceId: getUrlFieldValuesByReferenceId(referenceIds),
    rawGraph,
    componentIdsOfCollapsedGroups,
    nodeLabelsData,
    // edgeLabelsData is currently not in use, which might indicate a reference update bug in handleUpdate
    // But we add it to the result now for consitency and deal with the possible bug separately.
    edgeLabelsData,
  };
};
export default buildViewModel;
