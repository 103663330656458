import { s32, s8 } from '@ardoq/design-tokens';
import { dispatchAction } from '@ardoq/rxbeach';
import styled from 'styled-components';
import { Link, header2 } from '@ardoq/typography';
import { IPAllowlistingStreamShape, IPAllowlistingTab } from './types';
import { ErrorNotification, SuccessNotification } from '@ardoq/status-ui';
import { Features, hasFeature } from '@ardoq/features';
import { ipTabMetadata } from './consts';
import { DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import { NavItem } from '@ardoq/layout';
import { setIPAllowlistTab } from './ipAllowlist$/actions';
import { showSupport } from 'utils/support';

const DeleteWarningWrapper = styled.div`
  padding: ${s32};
`;

export const ConfirmDeleteBody = () => (
  <DeleteWarningWrapper>This action is irreversible.</DeleteWarningWrapper>
);

export const ActionButtonWrapper = styled.div`
  display: flex;
  gap: ${s8};
`;

const Header = styled.h2<{ $hasDescription: boolean }>`
  ${header2}
  margin: 0 0 ${({ $hasDescription }) => ($hasDescription ? s8 : s32)}
`;

const Description = styled.p`
  margin: 0 0 ${s32};
`;

export const ContactSupportSnippet = ({
  typography,
}: {
  typography?: 'text1' | 'text2' | 'caption';
}) => (
  <>
    contact support via the{' '}
    <Link
      typography={typography}
      hideIcon
      onClick={() => showSupport({ chat: true })}
    >
      in-app chat
    </Link>{' '}
    or at{' '}
    <Link
      typography={typography}
      onClick={() => window.open('mailto:support@ardoq.com', '_blank')}
    >
      support@ardoq.com
    </Link>
  </>
);

export const IPTabHeader = ({
  activeTab,
}: Pick<IPAllowlistingStreamShape, 'activeTab'>) => {
  const { headerText, description } = ipTabMetadata[activeTab];
  // Status tab content uses a Doq element for the header
  return activeTab !== 'STATUS' ? (
    <Header $hasDescription={Boolean(description)}>{headerText}</Header>
  ) : null;
};

export const IPTabDescription = ({
  activeTab,
}: Pick<IPAllowlistingStreamShape, 'activeTab'>) => {
  const { description } = ipTabMetadata[activeTab];
  return description ? <Description>{description}</Description> : null;
};

const ErrorAlertWithMargin = styled(ErrorNotification)`
  margin-bottom: ${s32};
`;

const SuccessAlertWithMargin = styled(SuccessNotification)`
  margin-bottom: ${s32};
`;

export const IPAlert = ({ alert }: Pick<IPAllowlistingStreamShape, 'alert'>) =>
  alert?.type === 'success' ? (
    <SuccessAlertWithMargin>{alert.message}</SuccessAlertWithMargin>
  ) : alert?.type === 'error' ? (
    <ErrorAlertWithMargin>{alert.message}</ErrorAlertWithMargin>
  ) : null;

const AllowlistStatusMessage = ({ isActive }: { isActive: boolean }) => (
  <>
    <p>
      {isActive
        ? 'Only allowlisted IPs may access Ardoq, and any new IPs will gain access immediately.'
        : 'All organization users may access Ardoq from any IP address.'}
    </p>
    <p>
      {isActive ? (
        <>
          To deactivate IP allowlisting, please <ContactSupportSnippet />
        </>
      ) : (
        <>
          To allow a list or range of trusted IP addresses, navigate to the “Add
          new” tab. Next, <ContactSupportSnippet /> to enforce configured IP
          addresses.
        </>
      )}
    </p>
  </>
);

export const AllowlistStatus = () => {
  const isActive = hasFeature(Features.IP_ALLOWLIST);
  return (
    <DoqWithSpeechBubble
      doqType={isActive ? DoqType.SUCCESS : DoqType.ALGORITHM}
      title={`Allowlisting ${isActive ? 'active' : 'inactive'}`}
      message={<AllowlistStatusMessage isActive={isActive} />}
    />
  );
};

type TabProps = { id: IPAllowlistingTab; label: string; isActive: boolean };
export const IPTab = ({ id, label, isActive }: TabProps) => (
  <NavItem
    $active={isActive}
    onClick={() => dispatchAction(setIPAllowlistTab(id))}
  >
    {label}
  </NavItem>
);
