import styled from 'styled-components';
import { header2, header4 } from '@ardoq/typography';
import { colors, s8 } from '@ardoq/design-tokens';
import { LabelWithPopoverIcon } from 'integrations/common/components/LabelWithPopoverIcon/LabelWithPopoverIcon';
import { VpcsSelector } from './VpcsSelector/VpcsSelector';
import { VpcsOptions } from '../../streams/vpcs/types';

type SelectVpcsProps = {
  vpcsOptions: VpcsOptions[];
  onSelect: (vpcIds: string[]) => void;
  selectedVpcIds: string[];
  isDisabled: boolean;
};

export const SelectVpcs = ({
  vpcsOptions,
  selectedVpcIds,
  onSelect,
  isDisabled = false,
}: SelectVpcsProps) => {
  return (
    <SelectorContainer>
      <SelectLabel isDisabled={isDisabled}>
        3. Filter VPCs <OptionalLabel>(optional)</OptionalLabel>
      </SelectLabel>
      <VpcsSelector
        selectedVpcIds={selectedVpcIds}
        vpcsOptions={vpcsOptions}
        onSelect={onSelect}
        isDisabled={isDisabled}
      />
    </SelectorContainer>
  );
};

const SelectorContainer = styled.div`
  max-width: 540px;
`;

const SelectLabel = styled(LabelWithPopoverIcon)`
  ${header2}
  margin-bottom: ${s8};
`;
const OptionalLabel = styled.span`
  ${header4}
  color:${colors.grey50}
`;
