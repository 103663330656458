import { useState } from 'react';
import { map } from 'rxjs/operators';
import metamodels$ from 'streams/metamodels/metamodels$';
import { get } from 'utils/collectionUtil';
import { goToMetamodelList } from './navigationUtils';
import { MetamodelContext } from './types';
import metamodelNavigation$ from './navigation/metamodelNavigation$';
import MetamodelView from './MetamodelView';
import ExportButton from './ExportButton';
import { AqLayout, Stack } from '@ardoq/layout';
import { getComponentTypeCount } from './utils';
import { connect } from '@ardoq/rxbeach';
import { GraphComponent } from '@ardoq/yfiles';
import { combineLatest } from 'rxjs';
import { GhostButton } from '@ardoq/button';
import { ArdoqId, PersistedMetamodel, ViewIds } from '@ardoq/api-types';
import { hasFeature, Features } from '@ardoq/features';
import Navbar from 'views/navbar/Navbar';
import { getIsViewExportingStream } from '@ardoq/export';
import {
  ArdoqLoaderSpinner,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { PageBody, PageWrapper } from '@ardoq/page-layout';

interface OwnProps {
  metamodelId: ArdoqId;
}
interface StreamedProps {
  metamodels: PersistedMetamodel[];
  context: MetamodelContext;
  infoContainerIsOpen: boolean;
  infoContainerWidth: number;
  isExporting: boolean;
  hasNewJourneyFeature: boolean;
}
type MetamodelViewContainerProps = OwnProps & StreamedProps;

const MetamodelViewContainer = ({
  metamodelId,
  metamodels,
  context,
  infoContainerIsOpen,
  infoContainerWidth,
  isExporting,
  hasNewJourneyFeature,
}: MetamodelViewContainerProps) => {
  const metamodel = metamodels.find(({ _id }) => _id === metamodelId);
  const metamodelTitle = `Metamodel – ${get(metamodel, 'name', '')}`;

  const [visualizationContainer, setVisualizationContainer] =
    useState<HTMLElement | null>(null);

  const [graphComponent, setGraphComponent] = useState<GraphComponent | null>(
    null
  );

  const pageContent = metamodel && metamodel.metamodel && (
    <MetamodelView
      context={context}
      infoContainerIsOpen={infoContainerIsOpen}
      infoContainerWidth={infoContainerWidth}
      metamodel={metamodel}
      registerVisualizationContainer={setVisualizationContainer}
      registerGraphComponent={setGraphComponent}
      unregisterVisualizationContainer={() => setVisualizationContainer(null)}
      unregisterGraphComponent={() => setGraphComponent(null)}
    />
  );
  return hasNewJourneyFeature ? (
    <PageWrapper>
      <Navbar
        primaryContent={metamodelTitle}
        secondaryContent="Metamodel"
        toolbarContent={
          isExporting && (
            <ArdoqLoaderSpinner
              color={LoaderColor.GRAY}
              size={LoaderSize.SMALL}
            />
          )
        }
        primaryButton={
          <ExportButton
            fullOrg={metamodel?.wholeOrg}
            metamodelId={metamodelId}
            metamodelTitle={metamodelTitle}
            visualizationContainer={visualizationContainer}
            graphComponent={graphComponent}
            showConfirmationDialog={getComponentTypeCount(metamodel) > 200}
            isExporting={isExporting}
            hasNewJourneyFeature
          />
        }
        secondaryButton={
          <GhostButton onClick={goToMetamodelList}>
            Back to overview
          </GhostButton>
        }
      />
      <PageBody backgroundColor="surfaceDefault" skipPadding>
        {pageContent}
      </PageBody>
    </PageWrapper>
  ) : (
    <AqLayout
      title={metamodelTitle}
      fullWidth
      bodyContentStyle={{ display: 'block', padding: 0, height: '100%' }}
      renderHeaderButtons={() => (
        <>
          {isExporting && (
            <Stack justify="center">
              <ArdoqLoaderSpinner
                size={LoaderSize.SMALL}
                color={LoaderColor.GRAY}
              />
            </Stack>
          )}
          <GhostButton onClick={goToMetamodelList}>
            Back to overview
          </GhostButton>
          <ExportButton
            fullOrg={metamodel?.wholeOrg}
            metamodelId={metamodelId}
            metamodelTitle={metamodelTitle}
            visualizationContainer={visualizationContainer}
            graphComponent={graphComponent}
            showConfirmationDialog={getComponentTypeCount(metamodel) > 200}
            isExporting={isExporting}
          />
        </>
      )}
    >
      {pageContent}
    </AqLayout>
  );
};

type toViewModelProps = [
  PersistedMetamodel[],
  {
    context: MetamodelContext;
    infoContainerIsOpen: boolean;
    infoContainerWidth: number;
  },
  {
    isExporting: boolean;
  },
];

const toViewModel = ([
  metamodels,
  { context, infoContainerIsOpen, infoContainerWidth },
  { isExporting },
]: toViewModelProps) => ({
  metamodels,
  context,
  infoContainerIsOpen,
  infoContainerWidth,
  isExporting,
  hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
});

export default connect(
  MetamodelViewContainer,
  combineLatest([
    metamodels$,
    metamodelNavigation$,
    getIsViewExportingStream(ViewIds.SIMPLE_BLOCK_DIAGRAM),
  ]).pipe(map(toViewModel))
);
