import * as React from 'react';
import { ViewpointBuilderFooter } from 'viewpointBuilder/components/ViewpointBuilderFooter';
import { AddFiltersSidebar } from './AddFiltersSidebar';
import { GraphVisualizationWithFilters } from './GraphVisualizationWithFilters';
import { ViewpointBuilderFiltersCommands } from './viewpointBuilderFiltersCommands';
import { TraversalBuilderViewState } from '../types';
import { PrimaryButtonConfig } from 'viewpointBuilder/openViewpointBuilder/getPrimaryButtonConfig';
import { WithMetamodelLoader } from '../components/WithMetamodelLoader';
import { ResizablePageBody } from '@ardoq/page-layout';

type AddFiltersTabContentProps = {
  state: TraversalBuilderViewState;
  commands: ViewpointBuilderFiltersCommands;
  closeViewpointBuilder: () => void;
  toggleGraphNodeSelection: (
    event: React.MouseEvent<Element, MouseEvent>
  ) => void;
  primaryButtonConfig: PrimaryButtonConfig;
  label: string;
};

export const AddFiltersTabContent = ({
  commands,
  state,
  closeViewpointBuilder,
  toggleGraphNodeSelection,
  primaryButtonConfig,
  label,
}: AddFiltersTabContentProps) => {
  return (
    <WithMetamodelLoader
      isMetaModelLoaded={state.isMetaModelLoaded}
      closeViewpointBuilder={closeViewpointBuilder}
    >
      <ResizablePageBody
        skipPadding
        footerContent={
          <ViewpointBuilderFooter
            closeViewpointBuilder={closeViewpointBuilder}
            primaryButtonConfig={primaryButtonConfig}
          />
        }
        rightContent={<AddFiltersSidebar commands={commands} state={state} />}
        rightContentInitialWidth={530}
        rightContentMinWidth={400}
      >
        <GraphVisualizationWithFilters
          label={label}
          state={state}
          commands={commands}
          toggleGraphNodeSelection={toggleGraphNodeSelection}
        />
      </ResizablePageBody>
    </WithMetamodelLoader>
  );
};
