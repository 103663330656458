import { ArdoqId } from '@ardoq/api-types';

export enum AccessControlTabs {
  MEMBERS = 'invite-and-manage-users',
  MANAGE_USER_ROLES = 'manage-user-roles',
  PERMISSION_GROUPS = 'permission-groups',
  SSO_SETUP = 'sso-setup',
  IP_ALLOWLIST_MANAGEMENT = 'ip-management',
  SCIM_TOKEN = 'scim-management',
  SSO_ATTRIBUTE_MAPPING = 'sso-attribute-mapping',
  SERVICE_ACCOUNTS = 'service-accounts',
  SUBDIVISIONS_OVERVIEW = 'divisions-overview',
}

export type UserProfileTabs = 'roles' | 'groups' | 'assets';

export type AccessControlMemberRoute = {
  accessControlTab: AccessControlTabs.MEMBERS;
  userId?: ArdoqId;
  userProfileActiveTabId?: UserProfileTabs;
};

export type AccessControlUserProfileRoute = {
  accessControlTab: AccessControlTabs.MEMBERS;
  userId: ArdoqId;
  userProfileActiveTabId: UserProfileTabs;
};

export type GroupDetailsTabs = 'users' | 'assets';

export type AccessControlGroupsRoute = {
  accessControlTab: AccessControlTabs.PERMISSION_GROUPS;
  groupId?: ArdoqId;
  groupActiveTabId?: GroupDetailsTabs;
};

export type AccessControlGroupDetailsRoute = {
  accessControlTab: AccessControlTabs.PERMISSION_GROUPS;
  groupId: ArdoqId;
  groupActiveTabId: GroupDetailsTabs;
};

export type AccessControlRoute =
  | AccessControlMemberRoute
  | AccessControlGroupsRoute
  | {
      accessControlTab: Exclude<
        AccessControlTabs,
        AccessControlTabs.MEMBERS | AccessControlTabs.PERMISSION_GROUPS
      >;
    };
