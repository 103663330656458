import { combineLatest, Observable } from 'rxjs';
import { viewpointBuilderNavigation$ } from 'viewpointBuilder/viewpointBuilderNavigation/viewpointBuilderNavigation$';
import { componentSearch$ } from 'viewpointBuilder/selectContextTab/componentSearch$';
import { metamodelView$ } from 'viewpointBuilder/metaModel/metamodelView$';
import { editTraversals$ } from 'viewpointBuilder/traversals/editTraversals$';
import { metaInfo$ } from 'viewpointBuilder/viewpointMetaInfo/viewpointMetaInfo$';
import { viewpointGroupingRules$ } from 'viewpointBuilder/addGroupingRulesTab/viewpointGroupingRules$';
import { viewpointFormatting$ } from 'viewpointBuilder/formattingTabs/viewpointFormatting$';
import {
  ViewpointBuilderState,
  ViewpointBuilderViewState,
} from 'viewpointBuilder/types';
import { getPrimaryButtonConfig } from './getPrimaryButtonConfig';
import { map } from 'rxjs/operators';

/**
 * This stream is not a persistent state stream, but a stream that combines all the necessary state streams for the viewpoint builder.
 * We subscribe it in the Viewpoint Builder modal using withOnFirstSubscription. This means that once the modal is closed, the stream is unsubscribed and cleaned up.
 */
export const viewpointBuilder$: Observable<ViewpointBuilderViewState> =
  combineLatest({
    viewpointBuilderNavigationState: viewpointBuilderNavigation$,
    searchComponentsState: componentSearch$,
    metamodelViewState: metamodelView$,
    editTraversalsState: editTraversals$,
    metaInfoState: metaInfo$,
    groupingsState: viewpointGroupingRules$,
    formattingState: viewpointFormatting$,
  }).pipe(
    map((state: ViewpointBuilderState) => {
      return {
        ...state,
        primaryButtonConfig: getPrimaryButtonConfig(state),
      };
    })
  );
