import { SwitchPosition } from '@ardoq/snowflakes';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import {
  resetDataForReferenceField,
  setApprovedDataForReferenceField,
} from './actions';
import { ChangeApprovalRowData, FieldsByReferenceId } from './types';
import { APIComponentAttributes, ChangeRequestAction } from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';
import { map } from 'rxjs';
import changeApprovalData$ from './changeApprovalData$';
import CreateOrDeleteReferenceTable from './PageElements/CreateOrDeleteReferenceTable';
import ReferenceUpdateTable from './PageElements/ReferenceUpdateTable';
import { WrapperWithSidebar } from './PageElements/WrapperWithSidebar';

type ReferenceDisplayProps = {
  masterComponent: APIComponentAttributes;
  changedFieldsByReferenceId: FieldsByReferenceId;
  changeAction: ChangeRequestAction;
};

const ReferenceDisplay = ({
  masterComponent,
  changedFieldsByReferenceId,
  changeAction,
}: ReferenceDisplayProps) => {
  const handleApprovedDataForSpecificField = (
    switchPosition: SwitchPosition,
    {
      changeApprovalEntityId,
      whatChanged,
      changedFrom,
      changedTo,
    }: ChangeApprovalRowData
  ) => {
    trackEvent(`Survey change approval: set row to ${switchPosition}`);
    const changedFields = changedFieldsByReferenceId?.[changeApprovalEntityId];
    if (switchPosition === 'pending') {
      dispatchAction(
        resetDataForReferenceField({
          whatChanged,
          masterComponent,
          changeApprovalEntityId,
          changedFields: Object.keys(changedFields),
        })
      );
    } else {
      dispatchAction(
        setApprovedDataForReferenceField({
          whatChanged,
          masterComponent,
          changedFields: Object.keys(changedFields),
          chosenValue: switchPosition === 'approved' ? changedTo : changedFrom,
          changeApprovalEntityId,
        })
      );
    }
  };

  return (
    <WrapperWithSidebar>
      {changeAction === 'update' ? (
        <ReferenceUpdateTable
          handleApprovedDataForSpecificField={
            handleApprovedDataForSpecificField
          }
        />
      ) : (
        <CreateOrDeleteReferenceTable />
      )}
    </WrapperWithSidebar>
  );
};

const referenceDisplayData$ = changeApprovalData$.pipe(
  map(
    ({ componentId, changeAction, masterData, changedFieldsByReferenceId }) => {
      const masterComponent = masterData.componentsById[componentId];
      return {
        masterComponent,
        changedFieldsByReferenceId,
        changeAction,
      };
    }
  )
);

export default connect(ReferenceDisplay, referenceDisplayData$);
