import { colors, s16, s4 } from '@ardoq/design-tokens';
import { Checkbox } from '@ardoq/forms';
import { Icon, IconName } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import styled from 'styled-components';
import { FlexBox } from '@ardoq/layout';

type SearchResultCountAndSelectAllProps = {
  totalCount: number;
  selectedComponentsCount: number;
  allComponentsSelected: boolean;
  selectionClearedOnSelectAllCount: number | null;
  isAdvancedSearch: boolean;
  isSelectAllDisabled: boolean;
  onToggleAllComponents: () => void;
};

const getSelectedText = ({
  selectedComponentsCount,
}: {
  selectedComponentsCount: number;
}) => {
  if (selectedComponentsCount > 0) {
    return `${selectedComponentsCount} selected`;
  }

  return 'Select at least one component to continue';
};

const SearchResultCountAndSelectAll = ({
  totalCount,
  allComponentsSelected,
  selectionClearedOnSelectAllCount,
  isAdvancedSearch,
  onToggleAllComponents,
  selectedComponentsCount,
  isSelectAllDisabled,
}: SearchResultCountAndSelectAllProps) => {
  const selectedText = getSelectedText({
    selectedComponentsCount,
  });
  return (
    <Wrapper>
      <Checkbox
        isDisabled={isSelectAllDisabled}
        isChecked={allComponentsSelected}
        onChange={onToggleAllComponents}
      >
        Select all
      </Checkbox>
      <FlexBox align="center">
        {totalCount} results &bull; {selectedText}
        {selectionClearedOnSelectAllCount && (
          <SelectionClearedInfo
            isAdvancedSearch={isAdvancedSearch}
            selectionClearedOnSelectAllCount={selectionClearedOnSelectAllCount}
          />
        )}
      </FlexBox>
    </Wrapper>
  );
};

const SelectionClearedInfo = ({
  isAdvancedSearch,
  selectionClearedOnSelectAllCount,
}: {
  isAdvancedSearch: boolean;
  selectionClearedOnSelectAllCount: number;
}) => (
  <FlexBox align="center" marginLeft="medium" gap="xsmall">
    <WithPopover
      content={() => (
        <PopoverContent>
          Clicking <b>select all</b> automatically clears any components you
          have selected before.
          {isAdvancedSearch
            ? 'Try refining your query for more control over selections.'
            : 'Try using Advanced search for more control over selections.'}
        </PopoverContent>
      )}
    >
      <Icon iconName={IconName.INFO} color={colors.blue50}></Icon>
    </WithPopover>
    <span>{selectionClearedOnSelectAllCount} previous selections cleared</span>
  </FlexBox>
);

const PopoverContent = styled.div`
  width: 272px;
`;

const Wrapper = styled.div`
  color: ${colors.grey50};
  font-size: ${s16};
  display: flex;
  justify-content: space-between;
  padding: ${s16};
  border-bottom: 1px solid ${colors.grey80};
  margin-bottom: ${s4};
`;

export default SearchResultCountAndSelectAll;
