import ReferenceQuestion, {
  ReferenceQuestionProperties,
} from '../ReferenceQuestion';
import { SurveyQuestionError } from 'surveyAdmin/types';
import DisplayConditionsQuestion from './DisplayConditionsQuestion';
import {
  APIFieldAttributes,
  isEntity,
  PersistedDependentQuestion,
  PersistedReferenceQuestion,
  UnpersistedDependentQuestion,
  UnpersistedReferenceQuestion,
} from '@ardoq/api-types';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { setSurveyQuestionFieldHasBeenInteractedWith } from 'surveyAdmin/SurveyEditor/streams/actions';
import { getQuestionId, getQuestionValidationMessageIfExists } from '../utils';
import surveyEditor$ from 'surveyAdmin/SurveyEditor/streams/surveyEditor$';
import { SurveyEditorState } from 'surveyAdmin/SurveyEditor/streams/types';
import { shouldDisplayErrorMessageForField } from 'surveyAdmin/surveyUtil';

export type DependentReferenceQuestionTypes =
  | PersistedDependentQuestion<PersistedReferenceQuestion>
  | UnpersistedDependentQuestion<UnpersistedReferenceQuestion>;

type ReferenceConditionalQuestionProps = {
  question: DependentReferenceQuestionTypes;
  parentQuestionField: APIFieldAttributes;
  parentQuestionTitle: string;
  updateQuestion: (dependentQuestion: DependentReferenceQuestionTypes) => void;
  fieldHasBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith'];
};

const ReferenceConditionalQuestion = ({
  parentQuestionTitle,
  parentQuestionField,
  surveyComponentTypeId,
  surveyWorkspaceId,
  question,
  questionValidations,
  permissionsContext,
  parentQuestionIsReadonly,
  surveyIsPermissionDivisionsAware,
  hasSurveyResponseApprovalsFeature,
  updateQuestion,
  fieldHasBeenInteractedWith,
}: Omit<
  ReferenceQuestionProperties,
  | 'updateQuestion'
  | 'question'
  | 'surveyAttributes'
  | 'fieldHasBeenInteractedWith'
> &
  ReferenceConditionalQuestionProps) => {
  const shouldShowDisplayConditionFieldSelectErrorIfPresent =
    shouldDisplayErrorMessageForField(
      isEntity(question),
      null,
      fieldHasBeenInteractedWith.questions[getQuestionId(question)]
        ?.dependentFieldQuestionDisplayConditionsField ||
        fieldHasBeenInteractedWith.questions[getQuestionId(question)]
          ?.questionViewHasBeenClosed
    );

  return (
    <>
      <DisplayConditionsQuestion
        onBlur={() => {
          dispatchAction(
            setSurveyQuestionFieldHasBeenInteractedWith({
              questionKey: getQuestionId(question),
              fieldKey: 'dependentFieldQuestionDisplayConditionsField',
            })
          );
        }}
        parentQuestionField={parentQuestionField}
        displayConditions={question.properties.displayConditions}
        parentQuestionTitle={parentQuestionTitle}
        errorMessage={
          shouldShowDisplayConditionFieldSelectErrorIfPresent
            ? getQuestionValidationMessageIfExists(
                questionValidations,
                SurveyQuestionError.DISPLAY_CONDITIONS_MISSING
              )
            : undefined
        }
        updatedDisplayConditions={displayConditions =>
          updateQuestion({
            ...question,
            properties: { ...question.properties, displayConditions },
          } as DependentReferenceQuestionTypes)
        }
      />
      <ReferenceQuestion
        surveyWorkspaceId={surveyWorkspaceId}
        surveyComponentTypeId={surveyComponentTypeId}
        question={question}
        questionValidations={questionValidations}
        isDependentQuestion={true}
        permissionsContext={permissionsContext}
        parentQuestionIsReadonly={parentQuestionIsReadonly}
        surveyIsPermissionDivisionsAware={surveyIsPermissionDivisionsAware}
        hasSurveyResponseApprovalsFeature={hasSurveyResponseApprovalsFeature}
        updateQuestion={updatedQuestion =>
          updateQuestion(updatedQuestion as DependentReferenceQuestionTypes)
        }
      />
    </>
  );
};

export default connect(ReferenceConditionalQuestion, surveyEditor$);
