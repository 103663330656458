import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import {
  AffectedWorkspace,
  TransferState,
} from 'integrations/common/streams/transferState/types';
import { combineLatest, map, of, switchMap } from 'rxjs';

import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import {
  getScheduleStream,
  ScheduleState,
} from 'integrations/common/streams/schedule/schedule$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { openWorkspaces } from 'appContainer/DashboardContainer/utils';
import { interposeSaveReminder } from 'integrations/common/streams/transferConfigs/actions';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { updateSelectedWorkspaces } from 'integrations/common/streams/transferState/actions';

type VoidFunc = () => void;

function buildModel({
  integrationId,
  integrationName,
  transferState,
  scheduleState,
  connectionsState,
}: {
  integrationId: IntegrationId;
  integrationName: string;
  transferState: TransferState;
  scheduleState: ScheduleState;
  connectionsState: IntegrationConnectionsState;
}) {
  let affectedWorkspaces: AffectedWorkspace[] = [];
  let selectedWorkspaces: string[] = [];
  if (transferState.requestStatus === 'SUCCESS') {
    affectedWorkspaces = transferState.response.affectedWorkspaces;
    selectedWorkspaces = transferState.selectedWorkspaces;
  }
  const selectedConnection = connectionsState.connections.find(c =>
    connectionsState.selectedConnectionIds.includes(c._id)
  );
  let onLinkClicked: undefined | VoidFunc;
  if (
    transferState.transferDirection === 'export' &&
    selectedConnection &&
    'baseUrl' in selectedConnection
  ) {
    onLinkClicked = () => {
      window.open(selectedConnection.baseUrl, '_blank');
    };
  }

  const onOpenWorkspaces = () => {
    const onDone = () => {
      dispatchAction(resetIntegration(integrationId));
      openWorkspaces(affectedWorkspaces.map(ws => ws.id));
    };

    if (scheduleState.loadedScheduleId) {
      return onDone();
    }

    dispatchAction(
      interposeSaveReminder({
        integrationId,
        onDone,
      })
    );
  };
  const onOpenIntegration = () => {
    dispatchAction(resetIntegration(integrationId));
  };
  const onSelectedWorkspacesChanged = (selectedWorkspaces: string[]) => {
    dispatchAction(
      updateSelectedWorkspaces({
        integrationId,
        selectedWorkspaces,
      })
    );
  };
  return {
    transferDirection: transferState.transferDirection,
    scheduleStage: scheduleState.scheduleStage,
    integrationName,
    affectedWorkspaces,
    selectedWorkspaces,
    transferStatus: transferState.requestStatus,
    onOpenWorkspaces,
    onOpenIntegration,
    onLinkClicked,
    onSelectedWorkspacesChanged,
  };
}

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    const integrationName = getIntegrationTermsDictionaryStream(
      integrationId
    ).pipe(map(({ name }) => name));
    return combineLatest({
      integrationName,
      integrationId: of(integrationId),
      transferState: getTransferStateStream(integrationId),
      scheduleState: getScheduleStream(integrationId),
      connectionsState: getConnectionsStream(integrationId),
    });
  }),
  map(buildModel)
);
