import styled from 'styled-components';
import { CSSProperties } from 'react';

const DropTargetIndicator = styled.div`
  background-image: linear-gradient(black, black);
  background-position: 0 5px;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  height: 12px;
  margin-left: 24px;
  margin-top: -6px;
  position: absolute;
  right: 2px;

  &:before,
  &:after {
    border: 4px solid transparent;
    content: '';
    height: 0;
    position: absolute;
    top: 2px;
  }

  &:before {
    left: 0;
    border-left-color: black;
  }

  &:after {
    right: 0;
    border-right-color: black;
  }
`;

type ParentDropTargetIndicatorProps = {
  parentDropTargetIndicatorStyle: CSSProperties;
};

const ParentDropTargetIndicator = ({
  parentDropTargetIndicatorStyle,
}: ParentDropTargetIndicatorProps) => (
  <DropTargetIndicator style={parentDropTargetIndicatorStyle} />
);

export default ParentDropTargetIndicator;
