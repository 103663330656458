export function focusInputOnPrintableKeydown(
  inputRef: React.RefObject<HTMLInputElement>,
  event: KeyboardEvent
): void {
  if (inputRef.current) {
    /**
     * Modern browsers implement `key`, so if `key` is length 1, we have a
     * match. e.g. 'a' for the a key, or '2' for the 2 key. All other
     * non-printable characters have names, e.g. 'Enter' or 'Backspace'.
     * Note: sporadically, event.key is undefined on Edge
     **/
    if (event.key === ' ') return;
    const isPrintableKeyPress = event.key?.length === 1;
    const hasFocus = inputRef.current === document.activeElement;

    if (isPrintableKeyPress && !hasFocus) {
      inputRef.current.focus();
    }
  }
}
