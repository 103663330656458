import {
  awsDictionary,
  azureDictionary,
  excelDictionary,
  microsoftEntraIdDictionary,
  serviceNowDictionary,
} from 'integrations/common/dictionary';
import {
  awsUrl,
  azureUrl,
  excel2Url,
  importUrl,
  jiraUrl,
  microsoftEntraId,
  servicenowUrl,
  signavioUrl,
  signavioExporterUrl,
  symbioUrl,
  celonisUrl,
} from 'integrations/common/logos';
import styled from 'styled-components';

const sourceNameToIcon: Record<string, string> = {
  excel: excel2Url,
  servicenow: servicenowUrl,
  azure: azureUrl,
  microsoftgraph: microsoftEntraId,
  aws: awsUrl,
  'jira-software': jiraUrl,
  jira: jiraUrl,
  signavio: signavioUrl,
  ['signavio-exporter']: signavioExporterUrl,
  itpedia: '/img/it-pedia/logo.png',
  symbio: symbioUrl,
  celonis: celonisUrl,
};
const sourceNameToLabel: Record<string, string> = {
  excel: excelDictionary.name,
  servicenow: serviceNowDictionary.name,
  azure: azureDictionary.name,
  microsoftgraph: microsoftEntraIdDictionary.name,
  aws: awsDictionary.name,
  'jira-software': 'Jira Software Cloud',
  signavio: 'SAP Signavio Importer',
  ['signavio-exporter']: 'SAP Signavio Exporter',
  itpedia: 'IT-Pedia',
  symbio: 'Celonis BPM',
  celonis: 'Celonis Process Intelligence Importer',
};

const Image = styled.img`
  object-fit: contain;
`;

interface SourceIconProps {
  size: string;
  integrationSource: string;
}

export const SourceIcon = ({ size, integrationSource }: SourceIconProps) => (
  <Image
    src={sourceNameToIcon[integrationSource] || importUrl}
    title={sourceNameToLabel[integrationSource] || integrationSource}
    height={size}
    width={size}
  />
);
