import { ViewStateEditablePathCollapsingRule } from './types';
import { CollapsePathsCommands } from './collapsePathsCommands';
import { Header3, Link, Paragraph } from '@ardoq/typography';
import {
  ButtonGroup,
  SmallSecondaryButton,
  SmallGhostButton,
} from '@ardoq/button';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import PathCollapsingNodeSelector from './PathCollapsingNodeSelector';
import { TextInput } from '@ardoq/forms';
import { ErrorNotification } from '@ardoq/status-ui';
import { colors } from '@ardoq/design-tokens';

type EditPathCollapsingRuleProps = {
  rule: ViewStateEditablePathCollapsingRule;
  commands: CollapsePathsCommands;
};

const EditPathCollapsingRule = ({
  rule,
  commands,
}: EditPathCollapsingRuleProps) => {
  return (
    <Box borderColor="borderSubtle00" borderRadius="r8" padding="medium">
      <Stack gap="medium">
        <Header3>Select a path to collapse</Header3>
        <Paragraph color="textSubtle">
          Select 2 component types in the dataset metamodel. All the references
          and component types in between them will be hidden in your dataset.
        </Paragraph>
        <PathCollapsingNodeSelector
          label="From"
          selectedNode={rule.startNode}
        />
        <PathCollapsingNodeSelector
          label="To"
          selectedNode={rule.endNode}
          isDisabled={!rule.startNode}
        />
        <FlexBox justify="center">
          <Link
            color={
              rule.path || rule.startNodeId ? colors.textError : colors.grey80
            }
            hideIcon
            onClick={() => commands.clearCollapsedPathNodes()}
          >
            Clear selection
          </Link>
        </FlexBox>
        {rule.errorMessage ? (
          <ErrorNotification>{rule.errorMessage}</ErrorNotification>
        ) : (
          <Box
            backgroundColor="surfaceDefaultSubtle"
            borderRadius="r4"
            padding="large"
          >
            <TextInput
              label="Path display text"
              value={rule.displayText}
              onValueChange={commands.displayTextUpdated}
              helperText="This will be displayed where the hidden data is. It is not a part of your metamodel and can be edited later."
            />
          </Box>
        )}
        <ButtonGroup $alignToRight>
          <SmallGhostButton
            onClick={() => commands.cancelEditPathCollapsingRule(rule)}
          >
            Cancel
          </SmallGhostButton>
          <SmallSecondaryButton
            onClick={() => commands.savePathCollapsingRule(rule)}
            isDisabled={!rule.isSavable}
          >
            Save
          </SmallSecondaryButton>
        </ButtonGroup>
      </Stack>
    </Box>
  );
};

export default EditPathCollapsingRule;
