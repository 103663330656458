import { Link, Paragraph } from '@ardoq/typography';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { Button, ButtonType } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { showSupport } from 'utils/support';
import { Drawer, DrawerDivider, showDrawer } from '@ardoq/snowflakes';
import {
  AccessControlFAQExpandableRow,
  AccessControlFAQSection,
} from './atoms';
import {
  ACCESS_CONTROL_FAQ_EVENTS,
  trackAccessControlFAQEvent,
} from './accessControlFAQTrackings';

const FEEDBACK_URL =
  'https://portal.productboard.com/cjkps8hu4ybuwz6h9kzrumxp/c/376-permissions-overview';

export const AccessControlFAQ = () => {
  return (
    <>
      <AccessControlFAQExpandableRow
        header="How do I manage roles and groups with SSO?"
        onClick={() =>
          trackAccessControlFAQEvent(
            ACCESS_CONTROL_FAQ_EVENTS.CLICKED_QUESTION,
            { question: 'How do I manage roles and groups with SSO?' }
          )
        }
      >
        <Paragraph>
          User roles can be managed externally using a custom attribute sent by
          your identity provider. For more details on setting up this process,
          please see the following help article.{' '}
          <Link
            typography="text2"
            target="_blank"
            onClick={() => {
              trackAccessControlFAQEvent(
                ACCESS_CONTROL_FAQ_EVENTS.CLICKED_LINK_UNDER_QUESTION,
                { link: 'User Management with Single Sign-on' }
              );
              window.open(
                KnowledgeBaseLink.USER_MANAGEMENT_WITH_SINGLE_SIGN_ON,
                '_blank'
              );
            }}
          >
            User Management with Single Sign-on
          </Link>
          .
        </Paragraph>
        <Paragraph>
          Users can also be automatically assigned to Ardoq groups each time
          they login using custom assignment rules. These rules can be setup
          using SSO Attribute Mapping, which is documented here:{' '}
          <Link
            typography="text2"
            target="_blank"
            onClick={() => {
              trackAccessControlFAQEvent(
                ACCESS_CONTROL_FAQ_EVENTS.CLICKED_LINK_UNDER_QUESTION,
                { link: 'SSO Attribute Mapping' }
              );
              window.open(KnowledgeBaseLink.SSO_ATTRIBUTE_MAPPING, '_blank');
            }}
          >
            SSO Attribute Mapping
          </Link>
          . Any changes made within your identity provider will be automatically
          applied the next time the user logs in.
        </Paragraph>
        <Paragraph>
          Your Ardoq permission groups can then be used to manage access to data
          and assets. When combined with SSO Attribute Mapping, users will be
          assigned to the correct groups and assets each time they log in.{' '}
          <Link
            typography="text2"
            target="_blank"
            onClick={() => {
              trackAccessControlFAQEvent(
                ACCESS_CONTROL_FAQ_EVENTS.CLICKED_LINK_UNDER_QUESTION,
                { link: 'Permission Groups' }
              );
              window.open(KnowledgeBaseLink.PERMISSIONS_GROUPS, '_blank');
            }}
          >
            Permission Groups
          </Link>
          .
        </Paragraph>
      </AccessControlFAQExpandableRow>
      <AccessControlFAQExpandableRow
        header="Why can’t someone see what I shared with them?"
        onClick={() =>
          trackAccessControlFAQEvent(
            ACCESS_CONTROL_FAQ_EVENTS.CLICKED_QUESTION,
            { question: 'Why can’t someone see what I shared with them?' }
          )
        }
      >
        <Paragraph>
          This may depend on a combination of the user’s role and their asset
          permissions. If you haven’t already, please give the following help
          article a read:{' '}
          <Link
            typography="text2"
            target="_blank"
            onClick={() => {
              trackAccessControlFAQEvent(
                ACCESS_CONTROL_FAQ_EVENTS.CLICKED_LINK_UNDER_QUESTION,
                { link: 'Assigning Users Access to Functionality and Assets' }
              );
              window.open(KnowledgeBaseLink.USER_PRIVILEGES, '_blank');
            }}
          >
            Assigning Users Access to Functionality and Assets
          </Link>
          .
        </Paragraph>
        <Paragraph>
          If the user is a Reader, Writer, or Admin, then the first place to
          check is the permissions on the asset itself, which can be found by
          right-clicking on the asset from the homepage.
        </Paragraph>
        <Paragraph>
          If the user is a Contributor, please note that Contributors have
          limited access and are not allowed to access the core Ardoq
          application. If you’re sharing a Report with a contributor, you can
          refer to the following documentation:{' '}
          <Link
            typography="text2"
            target="_blank"
            onClick={() => {
              trackAccessControlFAQEvent(
                ACCESS_CONTROL_FAQ_EVENTS.CLICKED_LINK_UNDER_QUESTION,
                {
                  link: 'How to Give Users Access to Reports Outside of Core Ardoq',
                }
              );
              window.open(
                KnowledgeBaseLink.HOW_TO_GIVE_USERS_ACCESS_TO_REPORTS_OUTSIDE_OF_CORE_ARDOQ,
                '_blank'
              );
            }}
          >
            How to Give Users Access to Reports Outside of Core Ardoq
          </Link>
          .
        </Paragraph>
      </AccessControlFAQExpandableRow>
      <AccessControlFAQExpandableRow
        header="How do I see access rights for an individual user?"
        onClick={() =>
          trackAccessControlFAQEvent(
            ACCESS_CONTROL_FAQ_EVENTS.CLICKED_QUESTION,
            { question: 'How do I see access rights for an individual user?' }
          )
        }
      >
        <Paragraph>
          To check which privileges the user has (e.g. Access Discover), you can
          use the search and filter on this page to search for the users who
          have access to certain functionality. In the specific case of
          Discover, this process is described in the following help article:{' '}
          <Link
            typography="text2"
            target="_blank"
            onClick={() => {
              trackAccessControlFAQEvent(
                ACCESS_CONTROL_FAQ_EVENTS.CLICKED_LINK_UNDER_QUESTION,
                {
                  link: 'Granting Access To Ardoq Discover And Assigning Permissions',
                }
              );
              window.open(KnowledgeBaseLink.DISCOVER_PERMISSIONS, '_blank');
            }}
          >
            Granting Access To Ardoq Discover And Assigning Permissions
          </Link>
          .
        </Paragraph>
        <Paragraph>
          To check a user’s access to specific assets, you should first check
          the permissions on the asset itself which can be found by
          right-clicking on the asset from the homepage. You can then check what
          groups have access to the asset, and consult the Groups tab under
          Access Control for the groups this user is a member of.
        </Paragraph>
        <Paragraph>
          Please note that permissions in Ardoq are additive, so whichever group
          or assignment grants the highest permission level will determine their
          access rights.
        </Paragraph>
      </AccessControlFAQExpandableRow>
      <AccessControlFAQExpandableRow
        header="How do I get an overview of the org’s permissions?"
        onClick={() =>
          trackAccessControlFAQEvent(
            ACCESS_CONTROL_FAQ_EVENTS.CLICKED_QUESTION,
            { question: 'How do I get an overview of the org’s permissions?' }
          )
        }
      >
        <Paragraph>
          The best way currently is to check asset permissions individually in
          order to get a full picture of the configuration. However, this can be
          time-consuming and hard to understand at a glance. We’re working to
          improve this, and would love your feedback!{' '}
          <Link
            typography="text2"
            target="_blank"
            onClick={() => {
              trackAccessControlFAQEvent(
                ACCESS_CONTROL_FAQ_EVENTS.CLICKED_LINK_UNDER_QUESTION,
                { link: 'Product board' }
              );
              window.open(FEEDBACK_URL, '_blank');
            }}
          >
            Product board
          </Link>
          .
        </Paragraph>
      </AccessControlFAQExpandableRow>
      <DrawerDivider />
      <AccessControlFAQSection
        title="Looking for something specific?"
        subtitle="Help us improve by sharing your feedback and ideas."
      >
        <Button
          buttonType={ButtonType.BRAND}
          onClick={() => {
            window.open(FEEDBACK_URL, '_blank');
            trackAccessControlFAQEvent(
              ACCESS_CONTROL_FAQ_EVENTS.CLICKED_SHARE_FEEDBACK
            );
          }}
        >
          <Icon iconName={IconName.MAIL} />
          Share feedback
        </Button>
      </AccessControlFAQSection>
      <DrawerDivider />
      <AccessControlFAQSection title="Need immediate assistance?">
        <Button
          buttonType={ButtonType.BRAND}
          onClick={() => {
            showSupport({ chat: true });
            trackAccessControlFAQEvent(
              ACCESS_CONTROL_FAQ_EVENTS.CLICKED_CONTACT_SUPPORT
            );
          }}
        >
          <Icon iconName={IconName.CHANNEL} />
          Contact support
        </Button>
      </AccessControlFAQSection>
    </>
  );
};

export const showAccessControlFAQ = () =>
  showDrawer({
    stackPageName: 'access-control-faq',
    shouldCloseOnBackdropClick: true,
    shouldCloseOnEscapeKey: true,
    onClose: () => {
      trackAccessControlFAQEvent(ACCESS_CONTROL_FAQ_EVENTS.CLOSE);
    },
    renderDrawer: () => (
      <Drawer title="FAQ" drawerSize="small" orientation="vertical">
        <AccessControlFAQ />
      </Drawer>
    ),
  });
