import resourcePermissions$ from './resourcePermissions$';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  APIResourcePermissionAttributes,
  ArdoqId,
  GroupResourcePermission,
  OrgAccessLevel,
  PermissionAccessLevel,
  PermissionRole,
  PermissionType,
  ResourceType,
  UserResourcePermission,
  PermissionGroup,
  PermissionOrgGroup,
  APIOrganizationUser,
} from '@ardoq/api-types';
import {
  getCurrentLocale,
  localeAreEqualLowercase,
  localeCompareNumericLowercase,
} from '@ardoq/locale';
import { resourcePermissionsModelInterface } from './resourcePermissionsModelInterface';
import currentUser$ from 'streams/currentUser/currentUser$';
import { getUserAndGroupOptions } from './utils';
import permissionGroup$ from 'admin/accessControl/PermissionGroups/streams/permissionGroups$';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  extractGroupLabelFromRole,
  getContributorGroupRole,
  getGroupLabel,
  getGroupPermissionOptions,
  getHighestPermissionLevel,
  getMemberGroupRole,
  getPermissionAccessLevelLabel,
  GROUP_OPTIONS_BY_GROUP_TYPE,
  getPermissionLevelAsArray,
  isContributorGroup,
} from '@ardoq/manage-resource-permissions';
import { apiPutResourcePermission } from 'resourcePermissions/actions';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';

/*
 * Survey and Dashboards give (non editable) read only access
 * Not possible to remove your own admin access
 * A reader can only be given read
 * A writer can have read, edit or admin.
 */
export const prepareResourceUserPermissions = (
  permissions: UserResourcePermission[],
  resourceType: ResourceType,
  byUsersId: Record<ArdoqId, APIOrganizationUser>,
  currentUserId: ArdoqId
) => {
  const locale = getCurrentLocale();
  return permissions
    .map(permission => {
      const { _id, permissions } = permission;
      const role = byUsersId[_id]?.role || OrgAccessLevel.READER;
      const writeAccess = role !== OrgAccessLevel.READER;
      const permissionLevel = getHighestPermissionLevel(permissions);
      const isCurrentUserAndAdmin =
        permissionLevel === PermissionAccessLevel.ADMIN &&
        currentUserId === _id;

      const revokeable = !isCurrentUserAndAdmin;
      const modifiable = !isCurrentUserAndAdmin && writeAccess;
      const possibleOptions = getPermissionLevelAsArray(
        writeAccess ? PermissionAccessLevel.ADMIN : PermissionAccessLevel.READ
      );
      const permissionOptions = modifiable
        ? possibleOptions.map(value => ({
            label: getPermissionAccessLevelLabel(value, resourceType),
            value,
          }))
        : null;

      return {
        ...permission,
        orgRole: role,
        permissionOptions,
        permissions,
        permission: getPermissionAccessLevelLabel(
          permissionLevel || PermissionAccessLevel.READ,
          resourceType
        ),
        revokeable,
        type: PermissionType.USER,
      };
    })
    .sort((a, b) => localeCompareNumericLowercase(a.name, b.name, locale));
};

/*
 * There are two different groups:
 * Restricted groups:
 *   - /member None | Read | Edit
 *   - /member (survey | dashboard) None | Read
 *   - /contributor None | Edit
 * Custom groups:
 *   - Can be given Read | Edit | Admin
 *   - (survey | dashboard) Read
 */
export const prepareResourceGroupsPermissions = (
  permissions: GroupResourcePermission[],
  resourceType: ResourceType,
  byGroupId: Record<string, PermissionGroup>,
  contributorRole: PermissionRole,
  memberRole: PermissionRole
) => {
  const locale = getCurrentLocale();
  const getCustomGroupLabel = (permissionRole: PermissionRole) =>
    Object.values(byGroupId).find(({ label }) => {
      const groupLabel = extractGroupLabelFromRole(permissionRole) ?? '';

      return localeAreEqualLowercase(groupLabel, label, locale);
    })?.name || 'Missing name';
  // Client is tasked with adding contributor group if it is missing for surveys, viewpoints, dashboards or reports
  const isMissingContributorGroup =
    (resourceType === ResourceType.SURVEY ||
      resourceType === ResourceType.VIEWPOINT ||
      resourceType === ResourceType.REPORT ||
      resourceType === ResourceType.DASHBOARD) &&
    !permissions.find(({ name }) => name === contributorRole);

  // Dashboards might be missing this group due to not created by the API
  const isMissingMembersGroup = !permissions.find(
    ({ name }) => name === memberRole
  );

  return [
    ...permissions,
    isMissingContributorGroup && {
      name: contributorRole,
      permissions: [],
    },
    isMissingMembersGroup && {
      name: memberRole,
      permissions: [],
    },
  ]
    .filter(ExcludeFalsy)
    .map(permissionObject => {
      const { permissions, name: permissionRole } = permissionObject;

      const label =
        getGroupLabel(permissionRole) || getCustomGroupLabel(permissionRole);
      const permissionLevel = getHighestPermissionLevel(permissions);
      const permissionOptions = getGroupPermissionOptions(
        permissionRole,
        resourceType,
        GROUP_OPTIONS_BY_GROUP_TYPE
      );
      const permission =
        permissionOptions?.find(({ value }) => value === permissionLevel)
          ?.label ||
        (permissionLevel &&
          permissionOptions?.find(
            ({ value }) => value === PermissionAccessLevel.READ
          )?.label) ||
        'No default access';

      return {
        // This is wrong on many levels, but it is the only way to get it working now, and soon this whole file will be gone.
        name: label as PermissionOrgGroup,
        permissionRole,
        permissionOptions,
        permission,
        permissions,
        revokeable:
          !resourcePermissionsModelInterface.isMemberGroup(permissionRole) &&
          !isContributorGroup(permissionRole),
        type: PermissionType.GROUP,
        permissionLevel,
        memberCount:
          (permissionObject as GroupResourcePermission).memberCount ??
          undefined,
        _id: (permissionObject as GroupResourcePermission)._id,
      };
    })
    .sort((a, b) => localeCompareNumericLowercase(a.name, b.name, locale));
};

const viewModel$ = combineLatest([
  resourcePermissions$,
  currentUser$,
  permissionGroup$,
  orgUsers$,
]).pipe(
  map(
    ([
      state,
      { _id: currentUserId, organization },
      { groupsById: byGroupId },
      { byId: byUserId },
    ]) => {
      if (organization === null) return null;
      const { label: orgLabel } = organization;
      const { apiState, byId } = state;

      const newUserAccessLevel = PermissionAccessLevel.READ;
      const resourcePermissions = Object.values(byId);

      const apiProps = {
        apiState,
        currentUserHasAccess:
          resourcePermissionsModelInterface.currentUserHasAccess,
        putResourcePermission: (payload: APIResourcePermissionAttributes[]) =>
          dispatchAction(
            apiPutResourcePermission({
              permissionsUpdates: payload,
              prevPermissionsByResource: byId,
            })
          ),
      };
      if (!resourcePermissions || resourcePermissions.length === 0)
        return apiProps;

      const resourcePermission = resourcePermissions[0];
      const resourceType = resourcePermission['resource-type'];

      const usersDataSource = prepareResourceUserPermissions(
        resourcePermission.permissions,
        resourceType,
        byUserId,
        currentUserId
      );
      const groupsDataSource = prepareResourceGroupsPermissions(
        resourcePermission.groups || [],
        resourceType,
        byGroupId,
        getContributorGroupRole(orgLabel),
        getMemberGroupRole(orgLabel)
      );
      const collaboratorsDataSource = [...groupsDataSource, ...usersDataSource];
      const permissionGroups = Object.values(byGroupId);

      const permissionProps = {
        resourcePermissions,
        newUserAccessLevel,
        collaboratorsDataSource,
        permissionGroups,
        orgLabel,
        getUserAndGroupOptions,
      };
      return { optionalProps: permissionProps, ...apiProps };
    }
  ),
  excludeNullAndUndefined()
);

export default viewModel$;
