import { SelectionRequestPayload, SelectionState } from './types';
import { MicrosoftEntraIdTable } from '../tables/types';
import fp from 'lodash/fp';

export const prepareSelectionRequest = ({
  selectedTables,
  tables,
}: {
  selectedTables: SelectionState['tables'];
  tables: MicrosoftEntraIdTable[];
}) => {
  const selectedTableIds = Object.keys(selectedTables);
  const preparedTables = tables
    .filter(({ id }) => selectedTableIds.includes(id))
    .map(table => {
      const selectedFields = Object.keys(selectedTables[table.id].fields);
      const fields = table.fields
        .filter(({ id }) => selectedFields.includes(id))
        .map(({ id }) => ({ name: id }));

      return {
        ...table,
        fields,
      };
    });

  const request: SelectionRequestPayload = {
    tables: preparedTables,
    name: 'Ad Integration',
  };

  return request;
};

export function isValidSelectionState({
  tables,
  importProfilesPhotos,
}: SelectionState): boolean {
  return (
    Boolean(Object.keys(tables).length) &&
    Object.values(tables).every(table => Object.keys(table.fields).length) &&
    (importProfilesPhotos
      ? tables.user?.fields.id && tables.user?.fields.displayName
      : true)
  );
}

export const areFetchedTablesInSync = ({
  selectionResponse,
  tables,
  importProfilesPhotos,
  hasRequestedPhotos,
}: SelectionState): boolean => {
  if (!selectionResponse || importProfilesPhotos !== hasRequestedPhotos) {
    return false;
  }
  const fetchedTables = selectionResponse.tables;
  const selectedTables = fp.pickBy(table => !fp.isEmpty(table.fields), tables);
  const selectedTableIds = Object.keys(selectedTables).sort();
  const fetchedTableIds = fetchedTables.map(t => t.id).sort();
  if (!fp.isEqual(selectedTableIds, fetchedTableIds)) {
    return false;
  }
  return fp.every(([tableId, { fields }]) => {
    const selectedFieldIds = Object.keys(fields).sort();
    const fetchedTable = fetchedTables.find(t => t.id === tableId);
    if (!fetchedTable) {
      return false;
    }
    const fetchedFieldIds = fetchedTable.columns.map(c => c.id).sort();
    return fp.isEqual(selectedFieldIds, fetchedFieldIds);
  }, fp.toPairs(selectedTables));
};
