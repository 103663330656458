import { MetaModelComponentType } from '@ardoq/api-types';
import { ComponentRepresentation } from '@ardoq/renderers';
import { getIcon, IconSize } from '@ardoq/icons';

export const toComponentTypeOptions = (
  componentTypes: MetaModelComponentType[]
) => {
  return componentTypes.map(type => ({
    label: type.name,
    value: type.name,
    leftContent: (
      <ComponentRepresentation
        value={type.style.image ?? null}
        icon={getIcon(type.style.icon)}
        iconSize={IconSize.SMALL}
        isImage={Boolean(type.style.image) && !type.style.icon}
        style={{ color: type.style.color ?? undefined }}
        shapeName={type.style.shape}
      />
    ),
  }));
};
