import metamodelNavigation$ from 'metamodel/navigation/metamodelNavigation$';
import {
  MetamodelNavigationState,
  MetamodelPane,
} from 'metamodel/navigation/types';
import MetamodelViewContainer from 'metamodel/MetamodelViewContainer';
import MetamodelList from 'metamodel/MetamodelList';
import EditMetamodel from 'metamodel/EditMetamodel';
import { connect } from '@ardoq/rxbeach';
import ModuleContainer from './ModuleContainer';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';

const MetamodelContainer = ({
  pane,
  metamodelId,
}: MetamodelNavigationState) => (
  <ModuleContainer $overflow="hidden">
    <ErrorBoundary logError={logError}>
      {pane === MetamodelPane.SELECTED && metamodelId ? (
        <MetamodelViewContainer metamodelId={metamodelId} />
      ) : pane === MetamodelPane.EDIT ? (
        <EditMetamodel />
      ) : (
        <MetamodelList />
      )}
    </ErrorBoundary>
  </ModuleContainer>
);

export default connect(MetamodelContainer, metamodelNavigation$);
