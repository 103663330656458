import {
  PerspectiveGroupingRule,
  PerspectivesGroupsOptions,
} from '@ardoq/perspectives';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  createGroupingRule,
  reorderGroupingRules,
  updateGroupingRule,
  deleteGroupingRule,
} from 'perspective/perspectiveEditor/perspectivesEditor$/actions';
import * as actions from './viewpointGroupingRulesActions';
import type { GetGroupingOptionsPayload } from './viewpointGroupingRulesActions';

// TODO move to a separate file
const onGroupingRuleReordered = (
  reorderedGroupingRules: PerspectiveGroupingRule[]
) => dispatchAction(reorderGroupingRules(reorderedGroupingRules));

const onGroupingRuleDeleted = (deletedGroupingRuleId: string) =>
  dispatchAction(deleteGroupingRule(deletedGroupingRuleId));

const onGroupingRuleUpdated = (
  updatedGroupingRuleId: string,
  updatedGroupingRule: Partial<PerspectiveGroupingRule>
) =>
  dispatchAction(
    updateGroupingRule({ updatedGroupingRuleId, updatedGroupingRule })
  );

const onGroupingRuleAdded = (newGroupingRule: PerspectiveGroupingRule) =>
  dispatchAction(createGroupingRule(newGroupingRule));

const getGroupingOptions = (
  getGroupingOptionsPayload: GetGroupingOptionsPayload
) => dispatchAction(actions.getGroupingOptions(getGroupingOptionsPayload));

const setGroupingOptions = (
  setGroupingOptionsPayload: PerspectivesGroupsOptions
) => dispatchAction(actions.setGroupingOptions(setGroupingOptionsPayload));

const setGroupingRules = (groupingRules: PerspectiveGroupingRule[]) =>
  dispatchAction(actions.setGroupingRules({ groupingRules }));

export const viewpointGroupingRulesCommands = {
  onGroupingRuleReordered,
  onGroupingRuleDeleted,
  onGroupingRuleUpdated,
  onGroupingRuleAdded,
  getGroupingOptions,
  setGroupingOptions,
  setGroupingRules,
};

export type ViewpointGroupingRulesCommands =
  typeof viewpointGroupingRulesCommands;
