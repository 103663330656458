import { connect } from '@ardoq/rxbeach';
import styled from 'styled-components';
import {
  TablesSelection,
  TablesSelectionBaseTable,
  TablesSelectionTablePayload,
} from '@ardoq/integrations';
import { viewModel$ } from './viewModel$';
import { AsyncStatus } from 'integrations/common/types/api';
import { defaultTexts } from './utils';

const ResourceGroupSelector = ({
  datasource = [],
  selectedTables,
  tablesStatus,
  onToggleResourceGroup,
}: {
  datasource: TablesSelectionTablePayload[];
  selectedTables: Record<
    string,
    {
      fields: Record<string, true>;
    }
  >;
  tablesStatus: AsyncStatus;
  onToggleResourceGroup: (table: TablesSelectionBaseTable) => void;
}) => {
  return (
    <TablesSelectionContainer>
      <TablesSelection
        dataSource={datasource}
        onSelectTable={onToggleResourceGroup}
        onUnselectTable={onToggleResourceGroup}
        selectedRowId={null}
        selectedTables={selectedTables}
        disabled={tablesStatus === 'INIT' || tablesStatus === 'LOADING'}
        texts={defaultTexts}
        isNewDesign
      />
    </TablesSelectionContainer>
  );
};

const TablesSelectionContainer = styled.div`
  flex: 1;
  min-width: 400px;
`;

export default connect(ResourceGroupSelector, viewModel$);
