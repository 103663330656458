import ArdoqNodeStyle from 'viewpointBuilder/components/SimpleGraph/ArdoqNodeStyleClone';
import ArdoqImageStyle from 'yfilesExtensions/styles/ArdoqImageStyle';
import { ComponentRepresentationData, GraphNode } from '@ardoq/graph';

const styles = new Map<string, ArdoqNodeStyle>();
const imageStyles: Record<string, ArdoqImageStyle> = {};

const getImageStyle = (url: string) => {
  if (imageStyles[url]) {
    return imageStyles[url];
  }
  const style = new ArdoqImageStyle(url);
  imageStyles[url] = style;
  return style;
};

export const getStyle = (node: GraphNode) => {
  const nodeImage = node.getImage();

  if (nodeImage) {
    return getImageStyle(nodeImage);
  }

  let nodeStyle = styles.get(node.getTypeId());
  if (!nodeStyle) {
    const representationData =
      node.getRepresentationData() as ComponentRepresentationData;
    const color = node.getColor();

    nodeStyle = new ArdoqNodeStyle({
      representationData,
      color,
    });
    styles.set(node.getTypeId(), nodeStyle);
  }
  return nodeStyle;
};
