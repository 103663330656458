import styled from 'styled-components';
import { s16 } from '@ardoq/design-tokens';
import { MetamodelPreview } from '@ardoq/metamodel-visualization';
import { useController } from 'react-hook-form';
import { logError } from '@ardoq/logging';

const MetamodelPreviewWithMargin = styled(MetamodelPreview)`
  margin-top: ${s16};
`;

const ControlledMetamodelPreview = () => {
  const {
    field: { value },
  } = useController({
    name: 'workspaceIds',
  });
  return (
    <MetamodelPreviewWithMargin
      workspaceIds={value}
      clickId="viewpoint-builder-metamodel-preview"
      logError={logError}
    />
  );
};

export default ControlledMetamodelPreview;
