import { ViewpointBuilderViewState } from 'viewpointBuilder/types';
import {
  GetViewpointBuilderStreamArgs,
  getViewpointBuilderStream,
} from './getViewpointBuilderStream';
import {
  getUpdatePerspectivesOptionsRoutine,
  graphDataForMetaInfoVisualisationRoutine,
  navigationTabDetailsOnCollapsedPaths,
  navigationTabDetailsOnCollapsedPathsUnselect,
  navigationTabDetailsOnFormattingRoutine,
  navigationTabDetailsOnGraphRoutine,
  navigationTabDetailsOnGroupingRoutine,
  navigationTabDetailsOnMetaInfoRoutine,
  navigationTabDetailsOnRequiredComponents,
  navigationTabDetailsOnSelectedComponentsRoutine,
  updateAdvancedSearchQueryRoutine,
  updateInstanceCountsRoutine,
  updateTraversalStartContextRoutine,
} from './viewpointBuilderRoutines';
import { dispatchAction } from '@ardoq/rxbeach';
import { viewpointBuilderClosed } from './viewpointBuilderOpened$';
import { resetViewpointBuilder } from '../resetViewpointBuilder';

type SubscribeToStreamsAndRoutinesArgs = GetViewpointBuilderStreamArgs & {
  setState: React.Dispatch<React.SetStateAction<ViewpointBuilderViewState>>;
};

export const subscribeToViewpointBuilderStreamAndRoutines = ({
  activeTab,
  context,
  initialConfiguration,
  folderId,
  viewpointBuilderCommands,
  setState,
}: SubscribeToStreamsAndRoutinesArgs) => {
  const viewpointBuilderStream = getViewpointBuilderStream({
    activeTab,
    context,
    initialConfiguration,
    folderId,
    viewpointBuilderCommands,
  });
  const updatePerspectivesOptionsRoutine =
    getUpdatePerspectivesOptionsRoutine(context);

  const subscriptions = [
    viewpointBuilderStream.subscribe(setState),
    /**
     * Temporary state routines, updating stream input from another stream
     * output (e.g. route search component stream output to traversal edit
     * input) or trigger async updates (e.g. fetch instance counts).
     */
    updatePerspectivesOptionsRoutine.subscribe(),
    updateInstanceCountsRoutine.subscribe(),
    updateTraversalStartContextRoutine.subscribe(),
    updateAdvancedSearchQueryRoutine.subscribe(),
    navigationTabDetailsOnSelectedComponentsRoutine.subscribe(),
    navigationTabDetailsOnGraphRoutine.subscribe(),
    navigationTabDetailsOnFormattingRoutine.subscribe(),
    navigationTabDetailsOnGroupingRoutine.subscribe(),
    navigationTabDetailsOnMetaInfoRoutine.subscribe(),
    graphDataForMetaInfoVisualisationRoutine.subscribe(),
    navigationTabDetailsOnCollapsedPaths.subscribe(),
    navigationTabDetailsOnRequiredComponents.subscribe(),
    navigationTabDetailsOnCollapsedPathsUnselect.subscribe(),
  ];

  return () => {
    subscriptions.forEach(subscription => subscription.unsubscribe());
    dispatchAction(viewpointBuilderClosed());
    resetViewpointBuilder();
  };
};
