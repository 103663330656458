import { openDeletePresentationMenu } from 'presentationEditor/presentationUtil';
import {
  APIPresentationAssetAttributes,
  PresentationReadPermissions,
  PresentationWritePermissions,
} from '@ardoq/api-types';
import { FullHeightStack, SidebarContentBody } from '../components/atoms';
import { Box } from '@ardoq/layout';
import PresentationDetails from '../components/PresentationDetails';
import AccessRights from '../components/AccessRights';
import FooterButtons from '../components/FooterButtons';
import { Component } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import { updatePresentation } from 'streams/presentations/actions';
import { trackEvent } from 'tracking/tracking';

enum Tabs {
  DETAILS = 'Details',
  SLIDES = 'Slides',
}
interface DetailsProperties {
  presentation: APIPresentationAssetAttributes;
  setTab: (tab: Tabs) => void;
  canEdit: boolean;
  canEditAccessRights: boolean;
}
interface DetailsState {
  name: string;
  description: string;
  readAccess: PresentationReadPermissions;
  writeAccess: PresentationWritePermissions;
  exploreMode: boolean;
}
export default class Details extends Component<
  DetailsProperties,
  DetailsState
> {
  constructor(props: DetailsProperties) {
    super(props);
    this.state = {
      name: '',
      description: '',
      readAccess: PresentationReadPermissions.WS,
      writeAccess: PresentationWritePermissions.ME,
      exploreMode: false,
    };
    this.fetchAndSetAttributes = this.fetchAndSetAttributes.bind(this);
    this.save = this.save.bind(this);
  }

  override componentDidMount() {
    this.fetchAndSetAttributes(this.props.presentation);
  }

  UNSAFE_componentWillReceiveProps(nextProps: DetailsProperties) {
    this.fetchAndSetAttributes(nextProps.presentation);
  }

  fetchAndSetAttributes(presentation: APIPresentationAssetAttributes) {
    const { name, description, readAccess, writeAccess, lockedContext } =
      presentation;
    this.setState({
      name,
      description: description ?? '',
      readAccess,
      writeAccess,
      exploreMode: lockedContext,
    });
  }

  save() {
    const { name, description, readAccess, writeAccess, exploreMode } =
      this.state;
    dispatchAction(
      updatePresentation({
        ...this.props.presentation,
        name,
        description,
        readAccess,
        writeAccess,
        lockedContext: exploreMode,
      })
    );
    trackEvent('Presentation Sidebar: updated presentation details');
    this.props.setTab(Tabs.SLIDES);
  }

  render() {
    const { canEdit, presentation, canEditAccessRights } = this.props;
    return (
      <FullHeightStack>
        <SidebarContentBody>
          <form role="form">
            <Box paddingTop="small">
              <PresentationDetails
                presentation={presentation}
                name={this.state.name}
                description={this.state.description}
                canEdit={canEdit}
                updateName={value => this.setState({ name: value })}
                updateDescription={value =>
                  this.setState({ description: value })
                }
              />
              {canEdit && (
                <AccessRights
                  presentation={presentation}
                  readAccess={this.state.readAccess}
                  writeAccess={this.state.writeAccess}
                  exploreMode={this.state.exploreMode}
                  canEditAccessRights={canEditAccessRights}
                  updateReadAccess={readAccess => this.setState({ readAccess })}
                  updateWriteAccess={writeAccess =>
                    this.setState({ writeAccess })
                  }
                  updateExploreMode={exploreMode =>
                    this.setState({ exploreMode })
                  }
                />
              )}
            </Box>
          </form>
        </SidebarContentBody>
        {canEdit && (
          <FooterButtons
            isDisabled={Boolean(!this.state.name)}
            showDeleteButton={true}
            onSave={this.save}
            onDelete={() => {
              openDeletePresentationMenu(presentation);
            }}
          />
        )}
      </FullHeightStack>
    );
  }
}
