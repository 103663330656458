import { dispatchAction } from '@ardoq/rxbeach';
import { configState$ } from 'integrations/signavio-exporter/streams/configurationState/configurationState$';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { NewExportRoutes } from 'integrations/common/navigation/types';
import { combineLatest, map } from 'rxjs';
import { updateConfigState } from 'integrations/signavio-exporter/streams/configurationState/actions';
import { SchedulingPolicy } from '@ardoq/api-types';
import { upsertSchedule } from 'integrations/signavio-exporter/streams/api/actions';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { SingavioExporterConnection } from '@ardoq/api-types/integrations';

const onBack = () => {
  dispatchAction(
    navigateToPath({
      integrationId: 'signavio-exporter',
      path: NewExportRoutes.REVIEW_EXPORT,
    })
  );
};
const onSchedulePolicyChange = (schedulingPolicy: SchedulingPolicy) => {
  dispatchAction(updateConfigState({ schedulingPolicy }));
};

const onUpserSchedule = () => {
  dispatchAction(upsertSchedule());
};

const onNameChange = (name: string) => {
  dispatchAction(updateConfigState({ name }));
};
export const viewModel$ = combineLatest([
  configState$,
  getConnectionsStream('signavio-exporter'),
]).pipe(
  map(([config, { connections }]) => {
    const upsertType = config._id ? 'Update' : 'Create';
    const connection = connections.find(
      connection => connection._id === config.accountId
    );
    let link;
    if (connection) {
      link = `${
        (connection as SingavioExporterConnection).baseUrl
      }/p/hub/dictionary`;
    }
    return {
      name: config.name,
      schedulePolicy: config.schedulingPolicy,
      onBack,
      onNameChange,
      onSchedulePolicyChange,
      onUpserSchedule,
      upsertType,
      link,
    };
  })
);
