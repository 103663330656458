import { AudiencePreview } from '@ardoq/api-types';

export enum AudiencePreviewStatus {
  IDLE = 'idle',
  INCOMPLETE_CONTENT_SELECTION = 'incompleteContentSelection',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type AudiencePreviewStreamShape = {
  readonly audiencePreview: AudiencePreview;
  readonly status: AudiencePreviewStatus;
};
