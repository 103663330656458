import { Font, Size } from '@ardoq/yfiles';
import {
  LABEL_HORIZONTAL_PADDING,
  LABEL_VERTICAL_PADDING,
} from 'yfilesExtensions/styles/consts';
import measureLabelElement from './measureLabelElement';

const getLabelPreferredSize = (text: string, font: Font) => {
  if (!text) {
    return Size.EMPTY;
  }
  const { width, height } = measureLabelElement(text, font);
  return new Size(
    Math.ceil(width) + LABEL_HORIZONTAL_PADDING,
    Math.ceil(height) + LABEL_VERTICAL_PADDING
  );
};
export default getLabelPreferredSize;
