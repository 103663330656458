import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  ConfigurationState,
  ComponentType,
  ConfigurationStatuses,
  QueryResults,
} from '../types';
import {
  updateComponentTypesKeys,
  updateAccountId,
  updateProductWorkspace,
  updateComponentsQuery,
  updateManufacturer,
  updateProduct,
  updateFields,
  updateApplyFilters,
  updateName,
  updateStatuses,
  resetConfig,
  updateVulnerabilitiesWorkspace,
  updateComponentsQueryResults,
  updateConfigurationState,
  updateVersion,
} from './actions';
import { QueryBuilderQuery } from '@ardoq/api-types';
import { zipObject, merge, pick, mapValues } from 'lodash/fp';
import { defaultComponentType, defaultState } from './utils';
import {
  IntegrationWorkspace,
  MatchingValue,
} from '@ardoq/api-types/integrations';

const updatedAccountIdReducer = (
  state: ConfigurationState,
  payload: Pick<ConfigurationState, 'accountId'>
) => {
  return { ...state, ...payload };
};

const handleUpdateAccountId = reducer<
  ConfigurationState,
  Pick<ConfigurationState, 'accountId'>
>(updateAccountId, updatedAccountIdReducer);

const updateNameReducer = (
  state: ConfigurationState,
  payload: Pick<ConfigurationState, 'name'>
) => {
  return { ...state, ...payload };
};

const handleUpdateName = reducer<
  ConfigurationState,
  Pick<ConfigurationState, 'name'>
>(updateName, updateNameReducer);

const updateApplyFiltersReducer = (
  state: ConfigurationState,
  payload: Pick<ConfigurationState, 'applyFilters'>
) => {
  return {
    ...state,
    componentTypes: mapValues((componentType: ComponentType) => ({
      ...componentType,
      componentsQuery: undefined,
      isQueryValid: true,
    }))(state.componentTypes),
    ...payload,
  };
};

const handleUpdateApplyFilters = reducer<
  ConfigurationState,
  Pick<ConfigurationState, 'applyFilters'>
>(updateApplyFilters, updateApplyFiltersReducer);

const updateProductWorkspaceReducer = (
  state: ConfigurationState,
  payload: IntegrationWorkspace
) => {
  return {
    ...state,
    workspaces: {
      products: payload,
      vulnerabilities: state.workspaces.vulnerabilities,
    },
  };
};

const handleUpdateProductWorkspace = reducer<
  ConfigurationState,
  IntegrationWorkspace
>(updateProductWorkspace, updateProductWorkspaceReducer);

const updateVulnerabilitiesWorkspaceReducer = (
  state: ConfigurationState,
  workspace: IntegrationWorkspace
) => {
  return {
    ...state,
    workspaces: {
      products: state.workspaces.products,
      vulnerabilities: workspace,
    },
  };
};

const handleUpdateVulnerabilitiesWorkspace = reducer<
  ConfigurationState,
  IntegrationWorkspace
>(updateVulnerabilitiesWorkspace, updateVulnerabilitiesWorkspaceReducer);

const updateComponentTypesKeysReducer = (
  state: ConfigurationState,
  payload: string[]
) => {
  const filteredObj = pick(payload, state.componentTypes);
  const synchronizedObj = merge(
    zipObject(payload, Array(payload.length).fill(defaultComponentType)),
    filteredObj
  );

  return {
    ...state,
    componentTypes: synchronizedObj,
  };
};
const handleUpdateComponentTypesKeys = reducer<ConfigurationState, string[]>(
  updateComponentTypesKeys,
  updateComponentTypesKeysReducer
);

const updateComponentsQueryReducer = (
  state: ConfigurationState,
  payload: {
    componentType: string;
    query: QueryBuilderQuery;
    isValid: boolean;
  }
) => {
  const componentType =
    state.componentTypes[payload.componentType] || defaultComponentType;
  return {
    ...state,
    componentTypes: {
      ...state.componentTypes,
      [payload.componentType]: {
        ...componentType,
        componentsQuery: payload.query,
        isQueryValid: payload.isValid,
      },
    },
  };
};
const handleUpdateComponentsQuery = reducer<
  ConfigurationState,
  {
    componentType: string;
    query: QueryBuilderQuery;
    isValid: boolean;
  }
>(updateComponentsQuery, updateComponentsQueryReducer);

const updateComponentsQueryResultsReducer = (
  state: ConfigurationState,
  payload: {
    componentType: string;
    results: QueryResults;
  }
) => {
  const componentType =
    state.componentTypes[payload.componentType] || defaultComponentType;
  return {
    ...state,
    componentTypes: {
      ...state.componentTypes,
      [payload.componentType]: {
        ...componentType,
        componentsQueryResults: payload.results,
      },
    },
  };
};
const handleUpdateComponentsQueryResults = reducer<
  ConfigurationState,
  {
    componentType: string;
    results: QueryResults;
  }
>(updateComponentsQueryResults, updateComponentsQueryResultsReducer);

const updateManufacturerReducer = (
  state: ConfigurationState,
  payload: {
    componentType: string;
    manufacturer?: MatchingValue;
  }
) => {
  const componentType =
    state.componentTypes[payload.componentType] || defaultComponentType;
  return {
    ...state,
    componentTypes: {
      ...state.componentTypes,
      [payload.componentType]: {
        ...componentType,
        matching: {
          ...componentType.matching,
          manufacturer: payload.manufacturer,
        },
      },
    },
  };
};
const handleUpdateManufacturer = reducer<
  ConfigurationState,
  {
    componentType: string;
    manufacturer?: MatchingValue;
  }
>(updateManufacturer, updateManufacturerReducer);

const updateVersionReducer = (
  state: ConfigurationState,
  payload: {
    componentType: string;
    version?: MatchingValue;
  }
) => {
  const componentType =
    state.componentTypes[payload.componentType] || defaultComponentType;
  return {
    ...state,
    componentTypes: {
      ...state.componentTypes,
      [payload.componentType]: {
        ...componentType,
        matching: {
          ...componentType.matching,
          version: payload.version,
        },
      },
    },
  };
};
const handleUpdateVersion = reducer<
  ConfigurationState,
  {
    componentType: string;
    version?: MatchingValue;
  }
>(updateVersion, updateVersionReducer);

const updateProductReducer = (
  state: ConfigurationState,
  payload: {
    componentType: string;
    product?: MatchingValue;
  }
) => {
  const componentType =
    state.componentTypes[payload.componentType] || defaultComponentType;
  return {
    ...state,
    componentTypes: {
      ...state.componentTypes,
      [payload.componentType]: {
        ...componentType,
        matching: {
          ...componentType.matching,
          product: payload.product,
        },
      },
    },
  };
};
const handleUpdateProduct = reducer<
  ConfigurationState,
  {
    componentType: string;
    product?: MatchingValue;
  }
>(updateProduct, updateProductReducer);

const updateFieldsReducer = (
  state: ConfigurationState,
  payload: {
    componentType: string;
    fields: Partial<ComponentType['fields']>;
  }
) => {
  const componentType =
    state.componentTypes[payload.componentType] || defaultComponentType;
  return {
    ...state,
    componentTypes: {
      ...state.componentTypes,
      [payload.componentType]: {
        ...componentType,
        fields: {
          ...componentType.fields,
          ...payload.fields,
        },
      },
    },
  };
};
const handleUpdateFields = reducer<
  ConfigurationState,
  {
    componentType: string;
    fields: Partial<ComponentType['fields']>;
  }
>(updateFields, updateFieldsReducer);

const updateConfigurationStateReducer = (
  state: ConfigurationState,
  payload: ConfigurationState
) => {
  return { ...state, ...payload };
};
const handleConfigurationState = reducer<
  ConfigurationState,
  ConfigurationState
>(updateConfigurationState, updateConfigurationStateReducer);

const updateStatusesReducer = (
  state: ConfigurationState,
  payload: Partial<ConfigurationStatuses>
) => {
  return {
    ...state,
    statuses: { ...state.statuses, ...payload },
  };
};
const handleUpdateStatus = reducer<
  ConfigurationState,
  Partial<ConfigurationStatuses>
>(updateStatuses, updateStatusesReducer);

const resetConfigReducer = () => {
  return defaultState;
};
const handleResetConfig = reducer<ConfigurationState, void>(
  resetConfig,
  resetConfigReducer
);

export const configurationState$ = persistentReducedStream(
  `configurationState$`,
  defaultState,
  [
    handleUpdateAccountId,
    handleUpdateName,
    handleUpdateApplyFilters,
    handleUpdateProductWorkspace,
    handleUpdateVulnerabilitiesWorkspace,
    handleUpdateComponentTypesKeys,
    handleUpdateComponentsQuery,
    handleUpdateComponentsQueryResults,
    handleUpdateManufacturer,
    handleUpdateProduct,
    handleUpdateVersion,
    handleUpdateFields,
    handleUpdateStatus,
    handleResetConfig,
    handleConfigurationState,
  ]
);
