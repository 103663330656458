import { ViewIds } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import { isPresentationMode } from 'appConfig';
import {
  MetaInfo,
  MetaInfoViewStatus,
} from '../streams/views/mainContent/types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { getViewThumbnailSrc } from 'tabview/consts';

export const toMetaInfoByIdTuple = (view: MetaInfo): [ViewIds, MetaInfo] => [
  view.id,
  view,
];

export const viewHasLegend = (viewId: ViewIds): boolean => {
  const metaInfo = getMetaInfoTabs().get(viewId);
  return metaInfo?.features?.hasLegend ?? false;
};

export const viewIdToUserFriendlyName: Record<ViewIds, string> = {
  [ViewIds.PAGESVIEW]: 'Pages',
  [ViewIds.BLOCK_DIAGRAM]: 'Block Diagram',
  [ViewIds.DEPENDENCY_MAP_2]: 'Dependency Map',
  [ViewIds.RELATIONSHIPS_3]: 'Relationships',
  [ViewIds.NETWORK]: 'Network',
  [ViewIds.TABLEVIEW]: 'Tables',
  [ViewIds.DEPMATRIX]: 'Dependency Matrix',
  [ViewIds.CAPABILITY_MAP]: 'Capability Map',
  [ViewIds.SWIMLANE]: 'Swimlanes',
  [ViewIds.COMPONENTTREE]: 'Component tree',
  [ViewIds.DEPWHEEL]: 'Dependency Wheel',
  [ViewIds.TREEMAP]: 'Treemap',
  [ViewIds.TAGSCAPE]: 'Tagscape',
  [ViewIds.REFERENCETABLE]: 'Reference Table',
  [ViewIds.TIMELINE]: 'Timeline',
  [ViewIds.BUBBLE]: 'Bubble Chart',
  [ViewIds.ROADMAP]: 'Roadmap view',
  [ViewIds.COMPONENT_MATRIX]: 'Component Matrix',
  [ViewIds.SPIDER]: 'Spider Chart',
  [ViewIds.CIRCULAR_RELATIONSHIP_DIAGRAM]: 'Explorer View',
  [ViewIds.PROTEAN_DIAGRAM]: 'Protean Diagram',
  [ViewIds.HIERARCHICAL_TREEMAP]: 'Hierarchical Treemap View',
  [ViewIds.HIERARCHICAL_PACKING]: 'Hierarchical Circle Packing View',
  [ViewIds.SUNBURST]: 'Sunburst View',
  [ViewIds.METAMODEL]: 'Meta Model',
  [ViewIds.INTENTIONAL]: 'Intentional Hierarchic',
  [ViewIds.INTERACTIVE_BLOCKS]: 'Interactive Blocks (old)',
  [ViewIds.HIERARCHIC_IN_GRID]: 'Hierarchic in Grid',
  [ViewIds.ISOMETRIC_BLOCK_DIAGRAM]: 'Isometric Block Diagram',
  [ViewIds.SEQUENCE]: 'Sequence diagram',
  [ViewIds.REFERENCE_AGGREGATION_VIEW]: 'Aggregation',
  [ViewIds.PROCESSFLOW]: 'Process flow',
  [ViewIds.HEATMAP]: 'Heatmap',
  [ViewIds.API_DOCUMENTATION]: 'API Documentation',
  [ViewIds.RELATIONSHIPS]: 'Relationships',
  [ViewIds.DEPMAP]: 'Dependency Map',
  [ViewIds.NONE]: 'None',
  [ViewIds.SIMPLE_BLOCK_DIAGRAM]: 'Simple block diagram',
  [ViewIds.BLOCKS]: 'Interactive Blocks',
  [ViewIds.MODERNIZED_BLOCK_DIAGRAM]: 'New Block Diagram',
};

const getMetaInfoTabs = () => {
  const viewByIdTuples: [ViewIds, MetaInfo][] = [
    {
      id: ViewIds.PAGESVIEW,
      name: viewIdToUserFriendlyName[ViewIds.PAGESVIEW],
      description:
        'Shows detailed information about fields and descriptions on components and references',
      exampleImage: getViewThumbnailSrc(ViewIds.PAGESVIEW),
      features: { hasLegend: true },
      isHiddenInViewSelect: true,
    },
    {
      id: ViewIds.BLOCK_DIAGRAM,
      name: viewIdToUserFriendlyName[ViewIds.BLOCK_DIAGRAM],
      description: 'Shows linked components as block diagram.',
      exampleImage: getViewThumbnailSrc(ViewIds.BLOCK_DIAGRAM),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.DEPENDENCY_MAP_2,
      name: viewIdToUserFriendlyName[ViewIds.DEPENDENCY_MAP_2],
      description: 'Dependency Map',
      exampleImage: getViewThumbnailSrc(ViewIds.DEPENDENCY_MAP_2),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.RELATIONSHIPS_3,
      name: viewIdToUserFriendlyName[ViewIds.RELATIONSHIPS_3],
      description:
        'Shows how the components are linked to each other and what kind of link types they have between them. You can include the parent components via the option menu. Useful for exploring and identifying dependencies.',
      exampleImage: getViewThumbnailSrc(ViewIds.RELATIONSHIPS_3),
      features: { hasLegend: true },
    },
    (hasFeature(Features.SHOW_OLD_RELATIONSHIP_VIEW_2) ||
      isPresentationMode()) && {
      id: ViewIds.NETWORK,
      name: viewIdToUserFriendlyName[ViewIds.NETWORK],
      description:
        'Shows how the components are linked to each other and what kind of link types they have between them. You can include the parent components via the option menu. Useful for exploring and identifying dependencies.',
      exampleImage: getViewThumbnailSrc(ViewIds.NETWORK),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.TABLEVIEW,
      name: viewIdToUserFriendlyName[ViewIds.TABLEVIEW],
      description: 'Shows all your components in a consumable table view.',
      exampleImage: getViewThumbnailSrc(ViewIds.TABLEVIEW),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.DEPMATRIX,
      name: viewIdToUserFriendlyName[ViewIds.DEPMATRIX],
      description: 'Dependency Matrix',
      exampleImage: getViewThumbnailSrc(ViewIds.DEPMATRIX),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.CAPABILITY_MAP,
      name: viewIdToUserFriendlyName[ViewIds.CAPABILITY_MAP],
      description: 'Shows Capabilities.',
      exampleImage: getViewThumbnailSrc(ViewIds.CAPABILITY_MAP),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.SWIMLANE,
      name: viewIdToUserFriendlyName[ViewIds.SWIMLANE],
      description:
        'Shows how the components interact with each other categorized by swimlanes (grouped by their parents).',
      exampleImage: getViewThumbnailSrc(ViewIds.SWIMLANE),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.COMPONENTTREE,
      name: viewIdToUserFriendlyName[ViewIds.COMPONENTTREE],
      description:
        'Shows the components as a Table of Contents-like tree. The tree can be collapsed and expanded.',
      exampleImage: getViewThumbnailSrc(ViewIds.COMPONENTTREE),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.DEPWHEEL,
      name: viewIdToUserFriendlyName[ViewIds.DEPWHEEL],
      description:
        'Dependency wheel shows you how your components are connected in a circular view.',
      exampleImage: getViewThumbnailSrc(ViewIds.DEPWHEEL),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.TREEMAP,
      name: viewIdToUserFriendlyName[ViewIds.TREEMAP],
      description:
        'Treemap is a zoomable system map that shows how important a component is, by making a component bigger or smaller. The importance is calculated by the number of child components or incoming references.',
      exampleImage: getViewThumbnailSrc(ViewIds.TREEMAP),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.TAGSCAPE,
      name: viewIdToUserFriendlyName[ViewIds.TAGSCAPE],
      description:
        'Shows all tagged workspace components and references grouped by tags.',
      exampleImage: getViewThumbnailSrc(ViewIds.TAGSCAPE),
      features: { hasLegend: false },
    },
    {
      id: ViewIds.REFERENCETABLE,
      name: viewIdToUserFriendlyName[ViewIds.REFERENCETABLE],
      description: 'Reference Table',
      exampleImage: getViewThumbnailSrc(ViewIds.REFERENCETABLE),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.TIMELINE,
      name: viewIdToUserFriendlyName[ViewIds.TIMELINE],
      description: 'Displays timespan data for components.',
      exampleImage: getViewThumbnailSrc(ViewIds.TIMELINE),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.BUBBLE,
      name: viewIdToUserFriendlyName[ViewIds.BUBBLE],
      description: `A bubble chart is useful for visualizing the relationships between the numeric field values of multiple components.  The components are positioned and sized according to the values of three numeric fields, designated as the X, Y, and Size fields.`,
      exampleImage: getViewThumbnailSrc(ViewIds.BUBBLE),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.ROADMAP,
      name: viewIdToUserFriendlyName[ViewIds.ROADMAP],
      description: 'Roadmap',
      exampleImage: getViewThumbnailSrc(ViewIds.ROADMAP),
      features: { hasLegend: false },
      ...(hasFeature(Features.ROADMAP_FOR_HMCTS)
        ? {}
        : {
            viewStatus: MetaInfoViewStatus.DISCONTINUED,
          }),
    },
    {
      id: ViewIds.COMPONENT_MATRIX,
      name: viewIdToUserFriendlyName[ViewIds.COMPONENT_MATRIX],
      description: `Displays components in a grid, grouped by directly referenced components or list field values.`,
      exampleImage: getViewThumbnailSrc(ViewIds.COMPONENT_MATRIX),
      features: { hasLegend: true },
    },
    {
      id: ViewIds.SPIDER,
      name: viewIdToUserFriendlyName[ViewIds.SPIDER],
      description: 'Spider Chart View',
      exampleImage: getViewThumbnailSrc(ViewIds.SPIDER),
      features: { hasLegend: true },
    },
    (hasFeature(Features.CIRCULAR_RELATIONSHIP_DIAGRAM) ||
      isPresentationMode()) && {
      id: ViewIds.CIRCULAR_RELATIONSHIP_DIAGRAM,
      name: viewIdToUserFriendlyName[ViewIds.CIRCULAR_RELATIONSHIP_DIAGRAM],
      description:
        'A relationship diagram which uses circular arrangements of components, with references bundled into groups of curved lines.  This view is ideal for visualizing large data sets.',
      exampleImage: getViewThumbnailSrc(ViewIds.CIRCULAR_RELATIONSHIP_DIAGRAM),
      features: { hasLegend: true },
      viewStatus: MetaInfoViewStatus.BETA,
    },
    (hasFeature(Features.PROTEAN_DIAGRAM_BETA) || isPresentationMode()) && {
      id: ViewIds.PROTEAN_DIAGRAM,
      name: viewIdToUserFriendlyName[ViewIds.PROTEAN_DIAGRAM],
      description:
        'Who knows the end? What has risen may sink, and what has sunk may rise. Loathsomeness waits and dreams in the deep, and decay spreads over the tottering cities of men.',
      exampleImage: getViewThumbnailSrc(ViewIds.BLOCK_DIAGRAM),
      features: { hasLegend: true },
      viewStatus: MetaInfoViewStatus.BETA,
    },
    hasFeature(Features.HIERARCHICAL_TREEMAP) && {
      id: ViewIds.HIERARCHICAL_TREEMAP,
      name: viewIdToUserFriendlyName[ViewIds.HIERARCHICAL_TREEMAP],
      description: 'Display components by weight',
      exampleImage: getViewThumbnailSrc(ViewIds.TREEMAP),
      features: { hasLegend: false },
      viewStatus: MetaInfoViewStatus.BETA,
    },
    hasFeature(Features.HIERARCHICAL_TREEMAP) && {
      id: ViewIds.HIERARCHICAL_PACKING,
      name: viewIdToUserFriendlyName[ViewIds.HIERARCHICAL_PACKING],
      description: 'Display components by weight',
      exampleImage: getViewThumbnailSrc(ViewIds.TREEMAP),
      features: { hasLegend: false },
      viewStatus: MetaInfoViewStatus.BETA,
    },
    hasFeature(Features.HIERARCHICAL_TREEMAP) && {
      id: ViewIds.SUNBURST,
      name: viewIdToUserFriendlyName[ViewIds.SUNBURST],
      description: 'Display components by weight',
      exampleImage: getViewThumbnailSrc(ViewIds.TREEMAP),
      features: { hasLegend: false },
      viewStatus: MetaInfoViewStatus.BETA,
    },
    hasFeature(Features.HIERARCHIC_IN_GRID) && {
      id: ViewIds.HIERARCHIC_IN_GRID,
      name: viewIdToUserFriendlyName[ViewIds.HIERARCHIC_IN_GRID],
      description:
        'A relationship diagram where nodes are arranged in a tabular structure. Users can define row and column constraints for nodes.',
      exampleImage: getViewThumbnailSrc(ViewIds.BLOCK_DIAGRAM),
      features: { hasLegend: true },
      viewStatus: MetaInfoViewStatus.BETA,
    },
    (hasFeature(Features.ISOMETRIC_BLOCK_DIAGRAM) || isPresentationMode()) && {
      id: ViewIds.ISOMETRIC_BLOCK_DIAGRAM,
      name: viewIdToUserFriendlyName[ViewIds.ISOMETRIC_BLOCK_DIAGRAM],
      description: 'Shows linked components as block diagram.',
      exampleImage: getViewThumbnailSrc(ViewIds.BLOCK_DIAGRAM),
      features: { hasLegend: true },
      viewStatus: MetaInfoViewStatus.BETA,
    },
    hasFeature(Features.INTERACTIVE_BLOCKS_IN_ARDOQ_STUDIO) && {
      id: ViewIds.BLOCKS,
      name: viewIdToUserFriendlyName[ViewIds.BLOCKS],
      description: 'Shows linked components as block diagram.',
      exampleImage: getViewThumbnailSrc(ViewIds.BLOCK_DIAGRAM),
      features: { hasLegend: true },
      viewStatus: MetaInfoViewStatus.BETA,
    },
    {
      id: ViewIds.MODERNIZED_BLOCK_DIAGRAM,
      name: viewIdToUserFriendlyName[ViewIds.MODERNIZED_BLOCK_DIAGRAM],
      description: 'Shows linked components as block diagram.',
      exampleImage: getViewThumbnailSrc(ViewIds.BLOCK_DIAGRAM),
      features: { hasLegend: true },
      viewStatus: MetaInfoViewStatus.BETA,
    },
    // #region discontinued views
    {
      id: ViewIds.SEQUENCE,
      name: viewIdToUserFriendlyName[ViewIds.SEQUENCE],
      viewStatus: MetaInfoViewStatus.DISCONTINUED,
    },
    {
      id: ViewIds.REFERENCE_AGGREGATION_VIEW,
      name: viewIdToUserFriendlyName[ViewIds.REFERENCE_AGGREGATION_VIEW],
      viewStatus: MetaInfoViewStatus.DISCONTINUED,
    },
    {
      id: ViewIds.PROCESSFLOW,
      name: viewIdToUserFriendlyName[ViewIds.PROCESSFLOW],
      viewStatus: MetaInfoViewStatus.DISCONTINUED,
    },
    {
      id: ViewIds.HEATMAP,
      name: viewIdToUserFriendlyName[ViewIds.HEATMAP],
      viewStatus: MetaInfoViewStatus.DISCONTINUED,
    },
    {
      id: ViewIds.API_DOCUMENTATION,
      name: viewIdToUserFriendlyName[ViewIds.API_DOCUMENTATION],
      viewStatus: MetaInfoViewStatus.DISCONTINUED,
    },
    {
      id: ViewIds.RELATIONSHIPS,
      name: viewIdToUserFriendlyName[ViewIds.RELATIONSHIPS],
      viewStatus: MetaInfoViewStatus.DISCONTINUED,
    },
    {
      id: ViewIds.DEPMAP,
      name: viewIdToUserFriendlyName[ViewIds.DEPMAP],
      viewStatus: MetaInfoViewStatus.DISCONTINUED,
    },
    {
      id: ViewIds.INTERACTIVE_BLOCKS,
      name: viewIdToUserFriendlyName[ViewIds.INTERACTIVE_BLOCKS],
      viewStatus: MetaInfoViewStatus.DISCONTINUED,
    },
    {
      id: ViewIds.INTENTIONAL,
      name: viewIdToUserFriendlyName[ViewIds.INTENTIONAL],
      viewStatus: MetaInfoViewStatus.DISCONTINUED,
    },
    // #endregion
  ]
    .filter(ExcludeFalsy)
    .map(toMetaInfoByIdTuple);

  return new Map(viewByIdTuples);
};

export default getMetaInfoTabs;
