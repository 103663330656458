import { collectRoutines } from '@ardoq/rxbeach';

import itPediaNavigationRoutines from './navigation/routines';
import accountsRoutines from './streams/accounts/routine';
import configurationRoutines from './streams/configurationState/routines';
import enrichmentRoutines from './streams/enrichment/routines';
import tracking from './streams/tracking/routines';
export default collectRoutines(
  ...itPediaNavigationRoutines,
  ...accountsRoutines,
  ...configurationRoutines,
  ...enrichmentRoutines,
  ...tracking
);
