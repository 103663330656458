import { ViewIds } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import { availableViews$ } from 'views/availableViews$';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { isViewRestrictedByQuickStart } from 'quickStart/isViewRestrictedByQuickStart';

export const getViewpointModeSupportedViews = (): ViewIds[] =>
  [
    ViewIds.MODERNIZED_BLOCK_DIAGRAM,
    ViewIds.BLOCK_DIAGRAM,
    ViewIds.DEPENDENCY_MAP_2,
    ViewIds.RELATIONSHIPS_3,
    ViewIds.TIMELINE,
    hasFeature(Features.INTERACTIVE_BLOCKS_IN_ARDOQ_STUDIO) && ViewIds.BLOCKS,
  ]
    .filter(
      viewId =>
        viewId &&
        availableViews$.state.ids.includes(viewId) &&
        !isViewRestrictedByQuickStart(viewId)
    )
    .filter(ExcludeFalsy);

const VIEW_REPLACEMENTS_IN_VIEWPOINT_MODE = new Map([
  [ViewIds.INTERACTIVE_BLOCKS, ViewIds.BLOCKS],
]);

export const getSupportedTraversalViewIdOrDefault = (viewId?: ViewIds) => {
  const defaultView = ViewIds.MODERNIZED_BLOCK_DIAGRAM;

  if (!viewId) {
    return defaultView;
  }
  return getViewpointModeSupportedViews().includes(viewId)
    ? viewId
    : (VIEW_REPLACEMENTS_IN_VIEWPOINT_MODE.get(viewId) ?? defaultView);
};

type ViewsWithoutFullyMultiLabelsSupport =
  | ViewIds.BLOCKS
  | ViewIds.RELATIONSHIPS_3
  | ViewIds.TIMELINE;

export const isViewWithoutFullyMultiLabelsSupport = (
  viewId: ViewIds
): viewId is ViewsWithoutFullyMultiLabelsSupport =>
  viewId === ViewIds.BLOCKS ||
  viewId === ViewIds.RELATIONSHIPS_3 ||
  viewId === ViewIds.TIMELINE;
