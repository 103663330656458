import { useRef } from 'react';
import { ErrorBoundary } from '@ardoq/error-boundary';
import DynamicOverview from 'search/SearchOverview/DynamicOverview';
import searchTab$ from 'search/SearchTabContainer/searchTab$';
import { SearchPane } from 'search/SearchTabContainer/types';
import DynamicSearch from 'search/DynamicSearch/DynamicSearch';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { setQueryEditorIsOpenInModal } from 'search/SearchTabContainer/actions';
import { logError } from '@ardoq/logging';
import { errorRecovery } from 'search/SearchTabContainer/utils';
import {
  DarkBackdropContent,
  ModalSize,
  ModalTemplate,
  confirm,
} from '@ardoq/modal';
import { combineLatest, map } from 'rxjs';
import { graphSearchAccessControlHelpers } from 'resourcePermissions/accessControlHelpers/graphSearch';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { UnauthorizedAccessPage } from '@ardoq/manage-resource-permissions';

type DynamicSearchModalProps = {
  selectedPane: SearchPane;
  closeStackPage: () => void;
  canCreateGraphFilter: boolean;
};
const DynamicSearchModal = ({
  selectedPane,
  closeStackPage,
  canCreateGraphFilter,
}: DynamicSearchModalProps) => {
  const backDropRef = useRef(null);
  const onClose = getCloseHandler(closeStackPage);
  return (
    <DarkBackdropContent
      ref={backDropRef}
      style={{ userSelect: 'text' }}
      onClick={async event => {
        if (event.target === backDropRef.current) {
          const shouldClose = await confirm({
            title: 'Unsaved changes',
            subtitle: 'Are you sure you want to close this window?',
            confirmButtonTitle: 'Yes',
            isConfirmButtonDanger: true,
          });
          if (shouldClose) onClose();
        }
      }}
    >
      <ModalTemplate
        headerText="Manage Graph Filters"
        onCloseButtonClick={onClose}
        modalSize={ModalSize.L}
      >
        {!canCreateGraphFilter ? (
          <UnauthorizedAccessPage />
        ) : (
          <ErrorBoundary
            logError={logError}
            errorContextDescription="Dynamic Search Error Boundary"
            recoverOnError={errorRecovery}
          >
            {selectedPane === SearchPane.DYNAMIC_FILTER_SEARCH ? (
              <DynamicSearch />
            ) : (
              <DynamicOverview canCreateGraphFilter={canCreateGraphFilter} />
            )}
          </ErrorBoundary>
        )}
      </ModalTemplate>
    </DarkBackdropContent>
  );
};

const getCloseHandler = (closeStackPage: () => void) => () => {
  closeStackPage();
  dispatchAction(setQueryEditorIsOpenInModal(false));
};

export default connect(
  DynamicSearchModal,
  combineLatest([searchTab$, currentUserPermissionContext$]).pipe(
    map(([searchTab, permissionContext]) => ({
      ...searchTab,
      canCreateGraphFilter:
        graphSearchAccessControlHelpers.canCreateGraphFilter(permissionContext),
    }))
  )
);
