import { ArdoqId, PrivilegeLabel } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import { hasPrivilege } from '@ardoq/privileges';
import { surveyInterface } from 'modelInterface/surveys/surveyInterface';
import {
  Permissions,
  hasPermission,
} from 'streams/currentUserPermissions/permissionInterface';
import { currentUserAccessControlInterface } from './currentUser';

/**
 * Check if the current user has the correct access to
 * create a new survey (checks for privileges and feature).
 * @returns {boolean}
 */
const canCreateSurvey = (): boolean =>
  hasFeature(Features.SURVEYS) &&
  currentUserAccessControlInterface.canCurrentUserWrite() &&
  hasPrivilege(PrivilegeLabel.CREATE_SURVEY);

/**
 * Check if the current user has the correct access to
 * Admin a survey (checks for permissions and feature).
 * @returns {boolean}
 */
const canAdminSurvey = (surveyId?: ArdoqId): boolean =>
  hasFeature(Features.SURVEYS) &&
  currentUserAccessControlInterface.canCurrentUserWrite() &&
  (hasPrivilege(PrivilegeLabel.GLOBAL_DATA_ACCESS) ||
    hasPermission(Permissions.SURVEY_ADMIN, surveyId));

/**
 * Check if the current user has the correct access to
 * Admin a survey (checks for permissions and feature).
 * @returns {boolean}
 */
const canEditSurvey = (surveyId?: ArdoqId): boolean =>
  hasFeature(Features.SURVEYS) &&
  currentUserAccessControlInterface.canCurrentUserWrite() &&
  (hasPrivilege(PrivilegeLabel.GLOBAL_DATA_ACCESS) ||
    hasPermission(Permissions.SURVEY_EDIT, surveyId) ||
    canAdminSurvey(surveyId));

/**
 * Check if the current user has the correct access to
 * read a new survey (checks for permissions and feature).
 * @returns {boolean}
 */
const canAccessSurvey = (surveyId?: ArdoqId): boolean => {
  const survey = surveyInterface.getSurveyAttributes(surveyId);
  if (!survey) {
    return false;
  }
  return (
    hasFeature(Features.SURVEYS) &&
    (hasPrivilege(PrivilegeLabel.GLOBAL_DATA_ACCESS) ||
      hasPermission(Permissions.SURVEY_ACCESS, surveyId))
  );
};

/**
 * Check if the current user has the correct access to
 * answer a survey (checks for permissions and feature and publish status).
 * @returns {boolean}
 */
const canSubmitSurvey = (surveyId?: ArdoqId): boolean => {
  const survey = surveyInterface.getSurveyAttributes(surveyId);
  if (!survey) {
    return false;
  }
  return canAccessSurvey(surveyId) && survey.published;
};

/**
 * Check if the current user has the correct access to
 * copy the survey as is (checks for permissions and feature).
 * @returns {boolean}
 */
const canCopySurvey = (surveyId: ArdoqId): boolean => {
  return canCreateSurvey() && canEditSurvey(surveyId);
};

export const surveyAccessControlInterface = {
  canCreateSurvey,
  canAdminSurvey,
  canEditSurvey,
  canAccessSurvey,
  canSubmitSurvey,
  canCopySurvey,
};
