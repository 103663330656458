import { Subject } from 'rxjs';
import { setDiffRoutine } from 'scope/stateRoutines';
import { scopeDiff$ } from 'scope/scopeDiff$';
import { Observable, merge } from 'rxjs';
import { Features, hasFeature } from '@ardoq/features';
import { catchError } from 'rxjs/operators';
import { logError } from '@ardoq/logging';
import { showRelatedComponentsRoutine } from 'appContainer/MainAppModule/MainAppModuleSidebar/scenarioRelated/navigator/stateRoutines';
import { scenarioRelatedSelection$ } from 'appContainer/MainAppModule/MainAppModuleSidebar/scenarioRelated/navigator/scenarioRelatedSelection$';
import { handleContentAndAudiencesChangeRoutine } from 'broadcasts/broadcastBuilder/audiencePreview/stateRoutines';
import { handlePredefinedAudienceDialogChangeRoutine } from 'broadcasts/broadcastBuilder/broadcastAudienceForm/dialogs/predefinedAudienceDialog/stateRoutines';
import currentBroadcast$ from 'broadcasts/currentBroadcast$';
import predefinedAudienceDialog$ from 'broadcasts/broadcastBuilder/broadcastAudienceForm/dialogs/predefinedAudienceDialog/predefinedAudienceDialog$';
import {
  websocketInferredTraversal$,
  websocketSuggestTraversal$,
} from 'traversals/viewInference/viewInference$';
import {
  handleInferredTraversalEventRoutine,
  handleSuggestTraversalEventRoutine,
} from 'traversals/viewInference/stateRoutines';

const error$ = new Subject<any>();

type CreateRoutinesStreamsTuple = [
  (...args: Observable<any>[]) => Observable<any>,
  Observable<any>[],
];

const getStateRoutines = () => {
  const routines: CreateRoutinesStreamsTuple[] = [
    ...(hasFeature(Features.SCENARIOS_BETA)
      ? [
          [setDiffRoutine, [scopeDiff$]],
          [showRelatedComponentsRoutine, [scenarioRelatedSelection$]],
        ]
      : []),
    ...(hasFeature(Features.BROADCASTS)
      ? [
          [handleContentAndAudiencesChangeRoutine, [currentBroadcast$]],
          [
            handlePredefinedAudienceDialogChangeRoutine,
            [predefinedAudienceDialog$],
          ],
        ]
      : []),
    ...(hasFeature(Features.AZURE_OPENAI_VIEW_INFERENCE)
      ? [
          [handleInferredTraversalEventRoutine, [websocketInferredTraversal$]],
          [handleSuggestTraversalEventRoutine, [websocketSuggestTraversal$]],
        ]
      : []),
  ] as CreateRoutinesStreamsTuple[];

  return merge(
    ...routines.map(([getRoutine, streams]) => getRoutine(...streams))
  );
};

const subscribeStateRoutine = (
  stateRoutineToSubscribe: Observable<unknown>,
  errorSubject: Subject<any>
) =>
  stateRoutineToSubscribe
    .pipe(
      catchError((err, stream) => {
        errorSubject.next(err);
        return stream;
      })
    )
    .subscribe();

export function startStateRoutines() {
  const stateRoutineSubscription = subscribeStateRoutine(
    getStateRoutines(),
    error$
  );
  const errorSubscription = error$.subscribe(error =>
    logError(error, 'State routine error')
  );

  if (module.hot) {
    module.hot.dispose(() => {
      stateRoutineSubscription.unsubscribe();
      errorSubscription.unsubscribe();
    });
  }
}
