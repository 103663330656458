import {
  APICurrentUser,
  APISearchAggregatesResponse,
  ArdoqId,
} from '@ardoq/api-types';
import { Maybe } from '@ardoq/common-helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import { ReportBuilderCommands, ReportTemplate } from '@ardoq/report-builder';
import { newWorkspaceSelected, newZoneSelected } from '../actions';
import {
  getQuerySectionCommands,
  GetQuerySectionCommandsArgs,
} from './querySectionCommands';
import { subdivisionsOperations } from '@ardoq/subdivisions';
import { SubdivisionsStreamShape } from 'streams/subdivisions/types';
import { setReportValue } from './utils';
import { getPermissionsCommands } from './permissionCommands';
import { getDataPresentationSectionCommands } from './dataPresentationSectionCommands';
import { showWorkspaceDrawer } from '../showWorkspaceDrawer';
import { EnhancedSearchResponse } from '@ardoq/report-reader';
import { difference } from 'lodash';

const getSetDescription =
  (reportTemplate: ReportTemplate) => (description: string) => {
    if (!reportTemplate.description && !description) {
      // There is a bug in the rich text editor that causes it to emit "" when passed null as initialContent. This is a workaround for that
      //  so that it doesn't look like the user has made changes to the description when they haven't
      return;
    }
    setReportValue('description', description);
  };

type GetCommandsArgs = {
  reportTemplate: ReportTemplate;
  currentUser: APICurrentUser;
  userHasReportAdminAccess: boolean;
  subdivisionsContext: SubdivisionsStreamShape;
  searchAggregatesResults: Maybe<APISearchAggregatesResponse>;
  searchResults: Maybe<EnhancedSearchResponse>;
  selectedWorkspacesWithNoAccess: ArdoqId[];
} & GetQuerySectionCommandsArgs;

export const getReportBuilderCommands = ({
  reportTemplate,
  userHasReportAdminAccess,
  currentUser,
  searchAggregatesResults,
  searchResults,
  isValidAdvancedSearch,
  currentUserLocale,
  users,
  attachments,
  subdivisionsContext,
  selectedWorkspacesWithNoAccess,
}: GetCommandsArgs): ReportBuilderCommands => ({
  setName: name => setReportValue('name', name),
  setDescription: getSetDescription(reportTemplate),
  setWorkspaceIds: workspaceIds =>
    dispatchAction(newWorkspaceSelected(workspaceIds)),
  setSubdivisions: zoneIds => {
    const subdivisions = subdivisionsOperations.zoneIdsToBySubdivisionsIds(
      zoneIds,
      subdivisionsContext
    );
    dispatchAction(newZoneSelected(subdivisions));
  },
  showWorkspaceDrawer,
  clearWorkspacesWithNoAccess: () =>
    dispatchAction(
      newWorkspaceSelected(
        difference(reportTemplate.workspaceIds, selectedWorkspacesWithNoAccess)
      )
    ),
  ...getPermissionsCommands({
    reportTemplate,
    userHasReportAdminAccess,
    currentUser,
  }),
  ...getDataPresentationSectionCommands({
    searchAggregatesResults,
    searchResults,
    reportTemplate,
    currentUserLocale,
    users,
    attachments,
  }),
  ...getQuerySectionCommands({
    searchAggregatesResults,
    searchResults,
    currentUserLocale,
    users,
    attachments,
    isValidAdvancedSearch,
    reportTemplate,
  }),
});
