import { trackEvent } from 'tracking/tracking';

export enum SERVICE_ACCOUNTS_EVENTS {
  // Create service account
  CLICKED_CREATE_NEW_BUTTON = 'clicked create new button',
  CREATED_NEW_SERVICE_ACCOUNT = 'created new service account',
  // Regenerate token
  CLICKED_REGENERATE_TOKEN = 'clicked regenerate token menu item',
  REGENERATED_TOKEN = 'regenerated token',
  // Delete service account
  CLICKED_DELETE_SERVICE_ACCOUNT = 'clicked delete service account menu item',
  DELETED_SERVICE_ACCOUNT = 'deleted service account',
}

export const trackServiceAccountsEvent = <T extends object>(
  name: SERVICE_ACCOUNTS_EVENTS,
  metadata?: T
) => trackEvent(`Service accounts0 ${name}`, metadata);
