import {
  APIBroadcastAttributes,
  BroadcastContentType,
  BroadcastInstance,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { trackClickedToOpenBroadcastProgressDetailsDialog } from 'broadcasts/tracking';
import {
  getInstanceAudience,
  getInstanceProgress,
  getInstanceSurveysSent,
  getInstancesInReverseChronologicalOrder,
} from '../utils';
import { Icon, IconName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { getFormattedDate, getFutureDate } from 'broadcasts/utils';
import { isEmpty } from 'lodash';
import {
  DateDecorator,
  IconDecorator,
  ProgressDecorator,
} from '@ardoq/decorators';
import EmptyContent from './EmptyContent';
import { CSSProperties, useState } from 'react';
import ProgressDetails from '../progressDetails/ProgressDetails';
import styled from 'styled-components';
import { currentDate, isAfter, parseDate } from '@ardoq/date-time';
import { Stack } from '@ardoq/layout';
import { showDrawer, Drawer } from '@ardoq/snowflakes';

const getReminderStatus = (
  instanceReminderDate: string | null,
  nextReminder: string,
  reminderInterval: number,
  hasErrors: boolean
) => {
  if (!instanceReminderDate && reminderInterval) return 'Canceled';
  if (hasErrors) return 'Error';
  if (isAfter(parseDate(nextReminder), currentDate())) return 'To be sent';
  return 'Sent';
};

const getReminderIcon = (reminderStatus: string) => {
  switch (reminderStatus) {
    case 'Canceled':
      return IconName.ALARM_CANCELLED;
    case 'Error':
      return IconName.ALARM_ERROR;
    case 'To be sent':
      return IconName.ALARM_TO_BE_SENT;
    case 'Sent':
      return IconName.ALARM_SENT;
  }
};

const getReminderTooltipText = (
  reminderStatus: string,
  nextReminder: string
) => {
  switch (reminderStatus) {
    case 'Canceled':
    case 'Error':
      return reminderStatus;
    case 'To be sent':
    case 'Sent':
      return `${reminderStatus} on ${getFormattedDate(nextReminder)}`;
  }
};

const getReminderInfo = (
  instanceExecutedDate: string,
  instanceReminderDate: string | null,
  reminderInterval: number,
  hasErrors: boolean
) => {
  const nextReminder =
    instanceReminderDate ??
    getFutureDate(instanceExecutedDate, reminderInterval);

  const reminderStatus = getReminderStatus(
    instanceReminderDate,
    nextReminder,
    reminderInterval,
    hasErrors
  );

  const icon = getReminderIcon(reminderStatus);

  const tooltipText = getReminderTooltipText(reminderStatus, nextReminder);

  return (
    <IconDecorator iconTooltipText={tooltipText} iconName={icon}>
      {reminderStatus}
    </IconDecorator>
  );
};

const SetHeightStack = styled(Stack)<{
  $setHeight: CSSProperties['height'];
}>`
  height: ${({ $setHeight }) => $setHeight};
`;

type InstanceTableProps = {
  broadcast: APIBroadcastAttributes;
  instances: BroadcastInstance[];
};

const InstanceTable = ({ broadcast, instances }: InstanceTableProps) => {
  const [selectedInstance, setSelectedInstance] =
    useState<BroadcastInstance | null>(null);

  const handleInstanceRowClick = (instance: BroadcastInstance) => {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      // Cancel click if any text is selected
      return;
    }
    if (selectedInstance === instance) {
      setSelectedInstance(null);
      return;
    }
    setSelectedInstance(instance);
    trackClickedToOpenBroadcastProgressDetailsDialog();
  };

  const rows = getInstancesInReverseChronologicalOrder(instances);
  const hasReminders = broadcast.scheduling.reminderInDays;

  const broadcastInstanceList = (
    <DatasourceTable
      rowStyle={rowData => ({
        cursor: 'pointer',
        backgroundColor:
          selectedInstance === rowData ? colors.grey90 : undefined,
      })}
      fixedHeader
      onRowClick={handleInstanceRowClick}
      dataSource={rows}
      components={newTableTheme}
      renderEmptyTable={!instances.length ? EmptyContent : undefined}
      columns={[
        {
          title: 'Broadcast date',
          dataIndex: 'executedDate',
          valueRender: (_any: any, row: BroadcastInstance) => (
            <DateDecorator date={row.executedDate}></DateDecorator>
          ),
        },
        hasReminders
          ? {
              title: 'Reminder',
              dataIndex: 'reminderDate',
              valueRender: (_any: any, row: BroadcastInstance) => {
                return (
                  broadcast.scheduling.reminderInDays &&
                  getReminderInfo(
                    row.executedDate,
                    row.reminderDate,
                    broadcast.scheduling.reminderInDays,
                    !isEmpty(broadcast.errors)
                  )
                );
              },
            }
          : {},
        broadcast.content.contentType === BroadcastContentType.SURVEY
          ? {
              title: 'Surveys sent',
              dataIndex: 'components',
              valueRender: (_any: any, row: BroadcastInstance) => {
                return (
                  <IconDecorator iconName={IconName.SURVEYS}>
                    {getInstanceSurveysSent(row.components?.length)}
                  </IconDecorator>
                );
              },
            }
          : {},
        {
          title: 'Audience included',
          dataIndex: 'audience',
          valueRender: (_any: any, row: BroadcastInstance) => {
            return (
              <IconDecorator iconName={IconName.PEOPLE}>
                {getInstanceAudience(row.audience.length)}
              </IconDecorator>
            );
          },
        },
        broadcast.content.contentType === BroadcastContentType.SURVEY
          ? {
              title: 'Survey submission rate',
              dataIndex: 'executedDate',
              valueRender: (_any: any, row: BroadcastInstance) => {
                if (row.progress) {
                  const { submissionRate } = getInstanceProgress(row.progress);
                  return (
                    <ProgressDecorator
                      value={submissionRate}
                      of={100}
                      color={colors.blue50}
                      unit="%"
                    />
                  );
                }
                return null;
              },
            }
          : {},
      ].filter(ExcludeFalsy)}
    />
  );

  if (!selectedInstance) return broadcastInstanceList;
  return (
    <SetHeightStack gap="medium" $setHeight="100%">
      <SetHeightStack $setHeight="30%">{broadcastInstanceList}</SetHeightStack>
      <ProgressDetails broadcast={broadcast} instance={selectedInstance} />
    </SetHeightStack>
  );
};

export const showBroadcastReportDrawer = ({
  broadcast,
  instances,
}: InstanceTableProps) =>
  showDrawer({
    stackPageName: `broadcastReport-${broadcast._id}`,
    renderDrawer: () => (
      <Drawer
        title={`Broadcast Report - ${broadcast.name}`}
        icon={<Icon iconName={IconName.DESCRIPTION} />}
        drawerSize="large"
      >
        <InstanceTable broadcast={broadcast} instances={instances} />
      </Drawer>
    ),
    shouldCloseOnBackdropClick: true,
    shouldCloseOnEscapeKey: true,
  });
