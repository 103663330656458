import styled from 'styled-components';
import ViewOptionsPerspectives from './ViewOptionsPerspectives';
import ViewOptionsViewSettings from './ViewOptionsViewSettings';

const Container = styled.div`
  display: flex;

  flex-direction: column;
  align-items: flex-start;

  align-self: stretch;
`;

const PerspectivesAndViewSettings = () => {
  return (
    <Container>
      <ViewOptionsPerspectives />
      <ViewOptionsViewSettings />
    </Container>
  );
};
export default PerspectivesAndViewSettings;
