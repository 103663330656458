import { connect } from '@ardoq/rxbeach';
import ModuleContainer from './ModuleContainer';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import TraversalOverview from '../traversals/TraversalOverview/TraversalOverview';
import { combineLatest, map } from 'rxjs';
import {
  ViewpointsOverviewCommands,
  viewpointsOverviewCommands,
} from '../traversals/TraversalOverview/viewpointsOverviewCommands';
import currentUser$, { getIsOrgWriter } from 'streams/currentUser/currentUser$';

type TraversalsContainerProps = {
  commands: ViewpointsOverviewCommands;
  canUserCreateTraversals: boolean;
  currentUserId: string;
};

const TraversalsContainer = (props: TraversalsContainerProps) => {
  return (
    <ModuleContainer $overflow="hidden">
      <ErrorBoundary logError={logError}>
        <TraversalOverview {...props} />
      </ErrorBoundary>
    </ModuleContainer>
  );
};

const view$ = combineLatest([currentUser$]).pipe(
  map(([currentUser]): TraversalsContainerProps => {
    return {
      canUserCreateTraversals: getIsOrgWriter(currentUser),
      commands: viewpointsOverviewCommands,
      currentUserId: currentUser._id,
    };
  })
);

export default connect(TraversalsContainer, view$);
