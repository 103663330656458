import { MergeDirection } from 'scope/merge/MergeDirection';
import {
  EntityRepresentation,
  Graphics,
  getComponentTypeIdAndParentIdByComponentId,
  getReferenceTypeIdAndParentIdByReferenceId,
} from '@ardoq/renderers';
import { APIEntityType } from '@ardoq/api-types';
import { DataSourceItem } from 'components/DiffMergeTable/types';
import { Branch } from 'components/DiffMergeTable/Branch';
import { AdditionalContentWithBottomMargin } from '../atoms';
import { FlexWrapper } from '../EntityRepresentation/atoms';

type AdditionalContentTypeConflictProps = {
  dataSourceItem: DataSourceItem;
  graphics: Graphics;
  mergeDirection: MergeDirection;
  entityType: APIEntityType;
};

const AdditionalContentTypeConflict = ({
  dataSourceItem,
  graphics,
  mergeDirection,
  entityType,
}: AdditionalContentTypeConflictProps) => {
  const entity =
    entityType === APIEntityType.COMPONENT ? 'component' : 'reference';

  const mergeStep =
    entityType === APIEntityType.COMPONENT
      ? 'component types'
      : 'reference types';

  const branch =
    mergeDirection === MergeDirection.MAINLINE_TO_BRANCH
      ? 'in your scenario'
      : 'on mainline';

  const enhancedScopeData =
    dataSourceItem.enhancedDiffContextData[Branch.SOURCE];

  const { entityId, parentEntityId } = (entityType === APIEntityType.COMPONENT
    ? getComponentTypeIdAndParentIdByComponentId
    : getReferenceTypeIdAndParentIdByReferenceId)(
    dataSourceItem.entityId,
    enhancedScopeData
  ) ?? { entityId: '', parentEntityId: '' };

  return (
    <AdditionalContentWithBottomMargin>
      <FlexWrapper>
        {`The ${entity} type for this ${entity} ( `}
        <EntityRepresentation
          entityId={entityId}
          parentEntityId={parentEntityId}
          entityType={
            entityType === APIEntityType.COMPONENT
              ? APIEntityType.COMPONENT_TYPE
              : APIEntityType.REFERENCE_TYPE
          }
          enhancedScopeData={enhancedScopeData}
          graphics={graphics}
          hideDefaultPopover={true}
          shouldHaveWrapper
        />
        {`) is not available ${branch}.`}
      </FlexWrapper>
      <div>{`To add the missing type go back to the ${mergeStep} merge step to include the missing type if you wish to create this ${entity}.`}</div>
    </AdditionalContentWithBottomMargin>
  );
};

export default AdditionalContentTypeConflict;
