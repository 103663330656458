import { combineLatest, map } from 'rxjs';
import { configState$ } from '../../streams/configurationState/configurationState$';
import { StepState } from '@ardoq/steppers';
import { isDataSelectionValid } from 'integrations/signavio-exporter/pages/NewExport/ComponentSelection/utils';
import { isFieldMappingValid } from 'integrations/signavio-exporter/pages/NewExport/MapFields/utils';
import { StepStatuses } from './types';
import { ConfigurationState } from '../../streams/types';
import { navigation$ } from '../../navigation/navigation$';
import { confirm } from '@ardoq/modal';
import {
  NewExportRoutes,
  OverviewRoute,
} from 'integrations/common/navigation/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { resetConfig } from 'integrations/signavio-exporter/streams/configurationState/actions';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { isExportSucceeded } from './ExportAndSchedule/utils';
import { isReviewExportSuccessful as isReviewExportValid } from './ReviewExport/utils';

function navigateToDataSelection() {
  dispatchAction(
    navigateToPath({
      integrationId: 'signavio-exporter',
      path: NewExportRoutes.DATA_SELECTION,
    })
  );
}

function navigateToFieldMapping() {
  dispatchAction(
    navigateToPath({
      integrationId: 'signavio-exporter',
      path: NewExportRoutes.FIELD_MAPPING,
    })
  );
}

function navigateToReviewExport() {
  dispatchAction(
    navigateToPath({
      integrationId: 'signavio-exporter',
      path: NewExportRoutes.REVIEW_EXPORT,
    })
  );
}

function navigateToExportAndSchedule() {
  dispatchAction(
    navigateToPath({
      integrationId: 'signavio-exporter',
      path: NewExportRoutes.EXPORT_AND_SCHEDULE,
    })
  );
}

function buildModel(config: ConfigurationState, route: NewExportRoutes) {
  const dataSelectionValid = isDataSelectionValid(config);
  const dataSelectionStatus = dataSelectionValid
    ? StepState.DONE
    : StepState.ACTIVE;

  const fieldMappingValid = dataSelectionValid && isFieldMappingValid(config);

  let fieldMappingStatus = StepState.ACTIVE;
  if (dataSelectionValid) {
    fieldMappingStatus = fieldMappingValid
      ? StepState.DONE
      : fieldMappingStatus;
  }

  const reviewExportValid = fieldMappingValid && isReviewExportValid(config);
  const reviewExportStatus = reviewExportValid
    ? StepState.DONE
    : StepState.ACTIVE;

  const exportAndScheduleValid = reviewExportValid && isExportSucceeded(config);
  const exportAndScheduleStatus = exportAndScheduleValid
    ? StepState.DONE
    : StepState.ACTIVE;

  const stepStatuses: StepStatuses = {
    dataSelection: {
      selected: route === NewExportRoutes.DATA_SELECTION,
      enabled: true,
      state: dataSelectionStatus,
    },
    fieldMapping: {
      selected: route === NewExportRoutes.FIELD_MAPPING,
      enabled: dataSelectionValid,
      state: fieldMappingStatus,
    },
    reviewExport: {
      selected: route === NewExportRoutes.REVIEW_EXPORT,
      enabled: reviewExportValid,
      state: reviewExportStatus,
    },
    exportAndSchedule: {
      selected: route === NewExportRoutes.EXPORT_AND_SCHEDULE,
      enabled: exportAndScheduleValid,
      state: exportAndScheduleStatus,
    },
  };

  const onResetClick = async () => {
    const confirmation = await confirm({
      title: `Restart export?`,
      subtitle: `Restarting the export will lose all progress.`,
      confirmButtonTitle: 'Restart export',
    });
    if (confirmation && confirmation.confirmed) {
      dispatchAction(resetConfig());
      dispatchAction(
        navigateToPath({
          integrationId: 'signavio-exporter',
          path: OverviewRoute.SCHEDULES,
        })
      );
    }
  };

  return {
    stepStatuses,
    route,
    onResetClick,
    navigateToDataSelection,
    navigateToFieldMapping,
    navigateToReviewExport,
    navigateToExportAndSchedule,
  };
}

export const viewModel$ = combineLatest([configState$, navigation$]).pipe(
  map(([config, { route }]) => buildModel(config, route))
);
