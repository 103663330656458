import { ScheduleFormData } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { ScheduleFormValues, ScheduleState } from './schedule$';

const NAMESPACE = '[integrations] SCHEDULE';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

type IntegrationIdPayload = {
  integrationId: IntegrationId;
};

export type LoadSchedulePayload = {
  integrationId: IntegrationId;
  scheduleId: string;
};

export const loadSchedule = actionCreator<LoadSchedulePayload>(
  getActionName('LOAD_SCHEDULE')
);

export const resetScheduleState = actionCreator<IntegrationIdPayload>(
  getActionName('RESET_SCHEDULE_STATE')
);

export const setLoadedScheduleId = actionCreator<
  { scheduleId: string | null } & IntegrationIdPayload
>(getActionName('SET_LOADED_SCHEDULE_ID'));

export const setUpsertedSchedule = actionCreator<
  { scheduleStage: ScheduleState['scheduleStage'] } & IntegrationIdPayload
>(getActionName('SET_UPDATED_SCHEDULE'));

export type UpsertSchedulePayload = {
  formData: ScheduleFormData;
} & IntegrationIdPayload;

export const upsertSchedule = actionCreator<UpsertSchedulePayload>(
  getActionName('UPSERT_SCHEDULE')
);

export const setAlreadyExistingSchedule = actionCreator<
  { isExists: boolean } & IntegrationIdPayload
>(getActionName('SET_ALREADY_EXISTING_SCHEDULE'));

export type SetScheduleFormValuesPayload = {
  scheduleFormValues: Partial<ScheduleFormValues>;
} & IntegrationIdPayload;

export const setScheduleFormValues =
  actionCreator<SetScheduleFormValuesPayload>(
    getActionName('SET_SCHEDULE_FORM_VALUES')
  );
