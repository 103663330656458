import { actionCreator } from '@ardoq/rxbeach';
import {
  PayloadAdvancedSearchError,
  PayloadQueryAdvancedSearch,
  PayloadQueryAdvancedSearchSuccess,
  PayloadSelectCondition,
  PayloadSelectFields,
  PayloadSelectFilterType,
  PayloadSelectPage,
  PayloadSetSort,
  PayloadUpdateAdvancedSearch,
} from 'search/AdvancedSearch/types';

const NAME_SPACE = '[AdvancedSearchForZones]';

export const selectCondition = actionCreator<PayloadSelectCondition>(
  `${NAME_SPACE} SELECT_CONDITION`
);

export const selectFilterType = actionCreator<PayloadSelectFilterType>(
  `${NAME_SPACE} SELECT_FILTER_TYPE`
);

export const selectPage = actionCreator<PayloadSelectPage>(
  `${NAME_SPACE} SELECT_PAGE`
);

export const setSort = actionCreator<PayloadSetSort>(`${NAME_SPACE} SET_SORT`);

export const selectFields = actionCreator<PayloadSelectFields>(
  `${NAME_SPACE} SELECT_FIELDS`
);

export const queryAdvancedSearch = actionCreator<PayloadQueryAdvancedSearch>(
  `${NAME_SPACE} QUERY_ADVANCED_SEARCH`
);

export const queryAdvancedSearchSuccess =
  actionCreator<PayloadQueryAdvancedSearchSuccess>(
    `${NAME_SPACE} QUERY_ADVANCED_SEARCH_SUCCESS`
  );

export const updateAdvancedSearch = actionCreator<PayloadUpdateAdvancedSearch>(
  `${NAME_SPACE} UPDATE_ADVANCED_SEARCH`
);

export const advancedSearchError = actionCreator<PayloadAdvancedSearchError>(
  `${NAME_SPACE} ADVANCED_SEARCH_ERROR`
);
