import { APIReferenceAttributes, ChangeRequestAction } from '@ardoq/api-types';
import { connect, derivedStream } from '@ardoq/rxbeach';
import { EnhancedScopeData } from '@ardoq/data-model';
import { map } from 'rxjs';
import changeApprovalData$ from '../changeApprovalData$';
import { ComponentValidation, RecordOfReferences } from '../types';
import {
  DatasourceTable,
  ExpandableTableProvider,
  newTableTheme,
} from '@ardoq/table';
import { getReferenceColumns } from './referenceColumns';
import { noop } from 'lodash';
import { Row, RowWithHover } from './atoms';
import { isReferenceAttributeRow } from './utils';

type CreateOrDeleteReferenceTableProps = {
  masterData: EnhancedScopeData;
  branchData: EnhancedScopeData;
  sourceOfTruthScopeData: EnhancedScopeData;
  changeAction: ChangeRequestAction;
  approvedReferenceChanges: RecordOfReferences;
  componentValidationObject: ComponentValidation;
  changedReferences: APIReferenceAttributes[] | undefined;
};

const CreateOrDeleteReferenceTable = ({
  branchData,
  masterData,
  sourceOfTruthScopeData,
  changedReferences,
  changeAction,
  approvedReferenceChanges,
  componentValidationObject,
}: CreateOrDeleteReferenceTableProps) => {
  if (!changedReferences) return null;

  const data = changedReferences.map(reference => {
    const sourceOfTruthReference =
      changeAction === 'create'
        ? branchData.referencesById[reference._id]
        : masterData.referencesById[reference._id];
    return {
      _id: sourceOfTruthReference._id,
      reference: sourceOfTruthReference,
      scopeData: sourceOfTruthScopeData,
      children: [
        {
          dataSource: [],
          reference,
          isExpandableRow: true,
        },
      ],
    };
  });

  return (
    <ExpandableTableProvider
      dataSource={data}
      idIndex="_id"
      render={({
        dataSource,
        expandedFoldersIds: expandedRowIds,
        expandFolder: expandRow,
      }) => (
        <DatasourceTable
          dataSource={dataSource}
          columns={getReferenceColumns(
            changeAction,
            expandedRowIds,
            approvedReferenceChanges,
            componentValidationObject,
            noop,
            expandRow
          )}
          components={newTableTheme}
          getRowComponent={row =>
            isReferenceAttributeRow(row) ? Row : RowWithHover
          }
        />
      )}
    />
  );
};

const createOrDeleteReferenceTable$ = derivedStream(
  'createOrDeleteReferenceTable$',
  changeApprovalData$
).pipe(
  map(
    ([
      {
        componentId,
        masterData,
        branchData,
        changeAction,
        approvedReferenceChanges,
        componentValidationObject,
        changedReferencesByComponentId,
      },
    ]) => {
      const sourceOfTruthScopeData =
        changeAction === 'delete' ? masterData : branchData;
      const component = sourceOfTruthScopeData.componentsById[componentId];
      return {
        branchData,
        masterData,
        componentId,
        sourceOfTruthScopeData,
        changeAction,
        approvedReferenceChanges,
        componentValidationObject: componentValidationObject[componentId],
        changedReferences:
          changedReferencesByComponentId?.[component._id][changeAction],
      };
    }
  )
);

export default connect(
  CreateOrDeleteReferenceTable,
  createOrDeleteReferenceTable$
);
