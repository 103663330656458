import { collectRoutines, dispatchAction, tapRoutine } from '@ardoq/rxbeach';
import { hideRightPane, showRightPane } from 'appContainer/actions';
import {
  hideViewSidePanel,
  showViewSidePanel,
} from 'views/mainApp/viewSidePanel/actions';

const hideViewOptions = () => dispatchAction(hideViewSidePanel());
const hideCyberEditor = () => dispatchAction(hideRightPane());
const routines = collectRoutines(
  tapRoutine(showRightPane, hideViewOptions),
  tapRoutine(showViewSidePanel, hideCyberEditor)
);
export default routines;
