import { fontAwesomeIcons } from '@ardoq/icons';
import type { CanvasRenderingContext } from './types';

const canvasFontAwesomeIcon = (
  context: CanvasRenderingContext,
  x: number,
  y: number,
  iconId: string,
  fontSize: number,
  color: string
) => {
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.font = `${fontSize}px FontAwesome`;
  context.fillStyle = color;
  context.fillText(fontAwesomeIcons[iconId], x, y);
};

export default canvasFontAwesomeIcon;
