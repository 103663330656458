import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import permissionGroup$ from './permissionGroups$';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import SSOAttributeMapping$ from 'admin/ssoAttributeMapping/SSOAttributeMapping$';
import { groupBy } from 'lodash';
import { permissionGroupsCommands } from '../commands';
import accessControlPageNavigation$ from 'admin/accessControl/navigation/accessControlPageNavigation$';
import { PermissionGroupsProps } from '../types';

export const viewModel$: Observable<PermissionGroupsProps> = combineLatest([
  permissionGroup$,
  orgUsers$,
  SSOAttributeMapping$,
  accessControlPageNavigation$,
]).pipe(
  map(
    ([
      permissionGroupState,
      { byId: usersById },
      { initialMapping: ssoMapping },
      navigation,
    ]) => {
      const locale = getCurrentLocale();
      const ssoMappingByGroupId = groupBy(ssoMapping, 'action.groupId');
      const { groupsById } = permissionGroupState;
      const permissionGroups = groupsById
        ? Object.values(groupsById)
            .map(group => ({
              ...group,
              users: group.users
                .map(userId => usersById[userId])
                .filter(ExcludeFalsy),
              ssoMappingRuleCount: ssoMappingByGroupId[group._id]?.length ?? 0,
            }))
            .sort((a, b) => localeCompare(a.name, b.name, locale))
        : [];
      return {
        ...navigation,
        ...permissionGroupState,
        permissionGroups,
        commands: permissionGroupsCommands,
      };
    }
  )
);
