import { createRef, useRef, useState } from 'react';
import { withErrorBoundaries } from '@ardoq/error-boundary';
import { useKeydown, useResizeObserver } from '@ardoq/hooks';
import AssetsBrowser from 'components/AssetsBrowser/AssetsBrowser';
import { AssetRow } from 'components/AssetsBrowser/types';
import { focusInputOnPrintableKeydown } from '../helpers';
import { Body } from './atoms';
import { trackOpenSelectedWorkspaces } from 'components/assetsBrowserDialog/tracking';
import { logError } from '@ardoq/logging';
import { AssetsBrowserDialogContentProps } from './types';
import { FormWrapper, SearchInput } from '@ardoq/forms';
import { getAssetBrowserDialogColumns } from './columns';
import { PrimaryButton } from '@ardoq/button';
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSize,
  ModalTemplate,
} from '@ardoq/modal';
import { FlexBox } from '@ardoq/layout';

const AssetsBrowserDialogContent = ({
  resolve,
  dataSource,
}: AssetsBrowserDialogContentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const size = useResizeObserver(ref);
  const [selected, setSelected] = useState<string[]>([]);
  const [searchPhrase, setSearchPhrase] = useState<string | undefined>('');

  const inputRef = createRef<HTMLInputElement>();

  useKeydown((event: KeyboardEvent) => {
    focusInputOnPrintableKeydown(inputRef, event);
  });

  const handleExit = () => resolve(false);
  const handleConfirm = async () => {
    trackOpenSelectedWorkspaces({ numWorkspaces: selected.length });
    resolve(selected);
  };

  return (
    <ModalTemplate modalSize={ModalSize.L}>
      <ModalHeader onCloseButtonClick={handleExit}>
        <FlexBox marginLeft="large" paddingLeft="medium">
          Select Workspace
        </FlexBox>
      </ModalHeader>
      <ModalBody>
        <FlexBox marginLeft="xxlarge" marginY="small">
          <FormWrapper>
            <SearchInput
              ref={inputRef}
              value={searchPhrase}
              onValueChange={setSearchPhrase}
            />
          </FormWrapper>
        </FlexBox>
        <Body ref={ref}>
          <AssetsBrowser
            searchPhrase={searchPhrase}
            selected={selected}
            setSelected={setSelected}
            dataSource={dataSource}
            // only for TS
            tableHeight={size.height === null ? undefined : size.height}
            onAssetClick={(asset: AssetRow) => resolve([asset._id])}
            getCustomColumns={getAssetBrowserDialogColumns}
          />
        </Body>
      </ModalBody>
      <ModalFooter>
        {selected.length ? (
          <PrimaryButton onClick={handleConfirm}>
            Open selected ({selected.length})
          </PrimaryButton>
        ) : (
          <PrimaryButton isDisabled>Open selected</PrimaryButton>
        )}
      </ModalFooter>
    </ModalTemplate>
  );
};

export default withErrorBoundaries({
  errorContextDescription: 'Error at <AssetsBrowserDialogContent />',
  logError,
})(AssetsBrowserDialogContent);
