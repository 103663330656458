import { useState } from 'react';
import { AsyncSelect, SelectOption } from '@ardoq/select';
import {
  CommonDefaultFilterKeys,
  ComponentDefaultFilterKeys,
} from '@ardoq/filter-interface';
import { ArdoqId, FormattingFilter } from '@ardoq/api-types';
import { searchApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';

type AsyncSearchProps = {
  format: FormattingFilter;
  workspaceIds: ArdoqId[];
  componentTypesInViewpoint: string[];
  handleUpdateFormatting: (
    formattingProperty: Partial<FormattingFilter>
  ) => void;
};

const AsyncSearch = ({
  format,
  workspaceIds,
  componentTypesInViewpoint,
  handleUpdateFormatting,
}: AsyncSearchProps) => {
  const [options, setOptions] = useState<SelectOption<string>[]>([]);
  const doSearch = async (query: string, entity: 'component' | 'reference') => {
    if (query.length < 1) return;

    const response = await searchApi.customSearch(query, {
      types: entity,
      workspaceIds: workspaceIds.join(','),
    });
    if (isArdoqError(response)) {
      logError(response);
      return [
        {
          label: 'Error',
          value: 'error',
        },
      ];
    }
    const { results } = response;

    const newOptions =
      results
        ?.filter(result => {
          if (entity === 'reference') return true;
          return componentTypesInViewpoint.includes(result.doc.typeName);
        })
        .map(result => {
          return {
            label: result.doc.name,
            value: result.doc._id,
          };
        }) ?? [];

    setOptions(newOptions);
    return newOptions;
  };

  const loadOptions = async (query: string, selectedValue: string) => {
    const results = await doSearch(
      !query ? selectedValue : query,
      format.name === CommonDefaultFilterKeys.ID && format.affectReference
        ? 'reference'
        : 'component'
    );

    return results || [];
  };

  const handleChange = (option: SelectOption<string>) => {
    const value =
      format.name === ComponentDefaultFilterKeys.NAME
        ? option.label
        : option.value;

    handleUpdateFormatting({ value: value as string });
  };

  const value = options.find(
    option => option.value === format.value || option.label === format.value
  );

  const hasError = Boolean(
    (format.name || format.comparator) && (!format.value || !value)
  );
  return (
    <AsyncSelect
      loadOptions={query => loadOptions(query, format.value as string)}
      onChange={option => {
        if (!option) return;
        handleChange(option);
      }}
      defaultOptions={true}
      value={value}
      placeholder="Type to find and select your chosen value"
      menuPlacement="auto"
      hasError={hasError}
      dataTestId="async-select"
    />
  );
};

export default AsyncSearch;
