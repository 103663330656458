import { APIGlobalField } from '@ardoq/api-types';
import { GlobalFieldsStreamShape } from 'globalFields/types';
import { ScenarioModeState } from 'scope/types';
import { derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import globalFields$ from 'globalFields/globalFields$';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { fieldOptionOps } from 'models/utils/fieldOptionOps';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

const getByNameOptions = {
  acrossWorkspaces: false,
  includeTemplateFields: false,
};

const fieldIsNotInWorkspace = (field: APIGlobalField) => {
  const fieldName = isDateRangeFieldType(field.type)
    ? dateRangeOperations.toStartDateName(field.name)
    : field.name;
  return !fieldInterface.getByName(fieldName, getByNameOptions);
};

const toAddFieldToWorkspaceStream = ([
  { globalFields },
  activeScenarioState,
  { errorMessage },
]: [
  GlobalFieldsStreamShape,
  ScenarioModeState,
  AppModelStateEditStreamShape,
]) => {
  const fields = globalFields.filter(fieldIsNotInWorkspace);

  const creatableFieldsProps = fieldOptionOps.fieldsToSortedCreatableListProps({
    fields,
    isScenarioMode:
      activeScenarioOperations.isInScenarioMode(activeScenarioState),
  });

  return {
    ...creatableFieldsProps,
    ...(errorMessage ? { errorMessages: [errorMessage] } : {}),
  };
};

const addFieldToWorkspace$ = derivedStream(
  'addFieldToWorkspace$',
  globalFields$,
  activeScenario$,
  appModelStateEdit$
).pipe(map(toAddFieldToWorkspaceStream));

export default addFieldToWorkspace$;
