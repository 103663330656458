import { colors, r8, s16, s32 } from '@ardoq/design-tokens';
import { FlexBox, Stack } from '@ardoq/layout';
import { Header4, Paragraph } from '@ardoq/typography';
import styled from 'styled-components';
import StatusInfo from './StatusInfo';
import DoughnutChart from './DoughnutChart';
import { IntegrationSchedule } from '@ardoq/api-types';
import { parseDate } from '@ardoq/date-time';
import { isScheduleWorkedTodayInUTC } from 'integrations/common/streams/schedules/utils';

const ScheduleStatusContainer = styled(FlexBox)`
  width: 100%;
  max-width: 630px;
  height: 210px;
  border: 1px solid ${colors.borderAction};
`;

const ChartContainer = styled.div`
  height: 250px;
  width: 174px;
  margin: 0 auto;
  transform: translateY(-25px);
`;

type SchedulesStatusProps = {
  schedules: IntegrationSchedule[];
};

type StatusCount = {
  success: number;
  error: number;
};

const EmptyContainer = styled(Stack)`
  width: 100%;
  background-color: ${colors.grey95};
  padding: ${s16};
  border-radius: ${r8};
`;

const NoSchedulesContainer = styled(Stack)`
  width: 300px;
  margin: ${s32} auto 0 auto;
  padding: ${s16};
`;

const SchedulesStatus = ({ schedules }: SchedulesStatusProps) => {
  const statusCounts = schedules
    .filter(
      schedule =>
        schedule.meta?.lastCompleted &&
        isScheduleWorkedTodayInUTC(parseDate(schedule.meta.lastCompleted))
    )
    .reduce<StatusCount>(
      (acc, item) => {
        const status = item.meta?.lastResult?.status;
        if (status === 'success' || status === 'warn') acc.success += 1;
        else if (status === 'error') acc.error += 1;

        return acc;
      },
      { success: 0, error: 0 }
    );

  return (
    <ScheduleStatusContainer
      borderRadius="r8"
      justify="space-between"
      padding={schedules.length > 0 ? 'medium' : 'none'}
      backgroundColor={schedules.length > 0 ? 'bgDefault' : 'bgSubtle'}
    >
      {schedules.length > 0 ? (
        <>
          <Stack justify="space-between">
            <Stack gap="small">
              <Header4>Last schedule sync</Header4>
              {!statusCounts.success && !statusCounts.error && (
                <Paragraph variant="caption" color="textSubtle">
                  No schedules for today
                </Paragraph>
              )}
            </Stack>
            <Stack>
              <StatusInfo status="success" count={statusCounts.success} />
              <StatusInfo status="error" count={statusCounts.error} />
            </Stack>
          </Stack>
          <ChartContainer>
            <DoughnutChart
              successCount={statusCounts.success}
              errorCount={statusCounts.error}
            />
          </ChartContainer>
        </>
      ) : (
        <EmptyContainer>
          <Header4>Last schedule sync</Header4>
          <NoSchedulesContainer align="center" justify="center" gap="small">
            <Header4>No schedules yet</Header4>
            <Paragraph variant="text2" align="center">
              Once schedules are set up, you can view daily status updates here.
            </Paragraph>
          </NoSchedulesContainer>
        </EmptyContainer>
      )}
    </ScheduleStatusContainer>
  );
};

export default SchedulesStatus;
