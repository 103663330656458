import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { AsyncStatus } from 'integrations/common/types/api';
import {
  changeVpcsSelection,
  ChangeVpcsSelectionPayload,
  fetchVpcsSuccess,
  FetchVpcsSuccessPayload,
  setAsyncStatus,
  SetAsyncStatusPayload,
} from './actions';
import { VpcState } from './types';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';

const defaultState: VpcState = {
  connectionsVpcs: {},
  selectedVpcIds: [],
};

const handleFetchVpcSuccess = (
  vpsState: VpcState,
  { connectionId, region, vpcs }: FetchVpcsSuccessPayload
) => ({
  ...vpsState,
  connectionsVpcs: {
    ...vpsState.connectionsVpcs,
    [connectionId]: {
      ...vpsState.connectionsVpcs[connectionId],
      [region]: {
        asyncStatus: 'SUCCESS' as AsyncStatus,
        vpcs: vpcs,
      },
    },
  },
});

const handleSetAsyncStatus = (
  vpcState: VpcState,
  { asyncStatus, connectionId, region }: SetAsyncStatusPayload
) => ({
  ...vpcState,
  connectionsVpcs: {
    ...vpcState.connectionsVpcs,
    [connectionId]: {
      ...vpcState.connectionsVpcs[connectionId],
      [region]: {
        vpcs: vpcState.connectionsVpcs[connectionId]?.[region]?.vpcs || [],
        asyncStatus,
      },
    },
  },
});

const handleUpdateSelectedVpcs = (
  vpcState: VpcState,
  { selectedVpcIds }: ChangeVpcsSelectionPayload
) => ({
  ...vpcState,
  selectedVpcIds,
});

const resetIntegrationReducer = () => defaultState;

const handleResetIntegration = reducer<VpcState>(
  resetIntegration,
  resetIntegrationReducer
);

const fetchVpcSuccessReducer = reducer<VpcState, FetchVpcsSuccessPayload>(
  fetchVpcsSuccess,
  handleFetchVpcSuccess
);

const setAsyncStatusReducer = reducer<VpcState, SetAsyncStatusPayload>(
  setAsyncStatus,
  handleSetAsyncStatus
);

const updateSelectedVpcIdsReducer = reducer<
  VpcState,
  ChangeVpcsSelectionPayload
>(changeVpcsSelection, handleUpdateSelectedVpcs);

export const vpcs$ = persistentReducedStream(
  `integrationAwsVpcs$`,
  defaultState,
  [
    fetchVpcSuccessReducer,
    setAsyncStatusReducer,
    updateSelectedVpcIdsReducer,
    handleResetIntegration,
  ]
);
