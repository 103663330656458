import { getCurrentLocale } from '@ardoq/locale';
import { TimestampContainer } from './atoms';
import { STRINGS } from './consts';
import { formatDateTime } from '@ardoq/date-time';

const Timestamp = ({ value }: { value: Date }) => {
  const locale = getCurrentLocale();

  return (
    <TimestampContainer>
      {STRINGS.TIMESTAMP} {formatDateTime(value, locale)}
    </TimestampContainer>
  );
};

export default Timestamp;
