import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isEqual } from 'lodash';
import { dispatchAction } from '@ardoq/rxbeach';
import { openTraversalInVisualization } from 'viewpointBuilder/openTraversalInVisualization';
import {
  notifyViewInferenceWarning,
  notifyViewInferenceSuccess,
  notifySuggestTraversalWarning,
  notifySuggestTraversalSuccess,
} from './actions';
import { viewInferenceOperations } from './viewInferenceOperations';
import viewInference$ from './viewInference$';
import { trackInferTraversal, trackTraversalSuggestions } from './tracking';
import { UserEvent, ClientRequest } from 'sync/types';
import { SuggestTraversalEvent, InferredTraversalEvent } from './types';
import { ardoqEventOperations } from 'sync/ardoqEventOperations';
import { isViewpointMode$ } from '../loadedGraph$';
import currentViewId$ from '../../streams/currentViewId$';
import { ExecuteViewpointPayload } from '../../viewpointBuilder/actions';
import { getSupportedTraversalViewIdOrDefault } from '../getViewpointModeSupportedViews';
import { normalizeApiTraversalParams } from 'viewpointBuilder/getComponentSelection';

const handleViewInferenceSuccessOrWarning = async (
  result: ExecuteViewpointPayload
) => {
  const warning = viewInferenceOperations.getInferWarning(result);
  if (warning) {
    dispatchAction(notifyViewInferenceWarning(warning));
    return;
  }

  // Dispatch the actions needed to open traversal in visualization
  await openTraversalInVisualization(result);

  dispatchAction(notifyViewInferenceSuccess());
};

export const handleInferredTraversalEventRoutine = (
  websocketInferredTraversal$: Observable<
    UserEvent<ClientRequest<InferredTraversalEvent>>
  >
) =>
  websocketInferredTraversal$.pipe(
    distinctUntilChanged<UserEvent<ClientRequest<InferredTraversalEvent>>>(
      isEqual
    ),
    tap(trackInferTraversal),
    withLatestFrom(viewInference$),
    filter(([event, state]) =>
      viewInferenceOperations.isActiveSendPromptRequest(
        state,
        ardoqEventOperations.getClientRequestId(event)
      )
    ),
    withLatestFrom(currentViewId$, isViewpointMode$),
    tap(([[event], currentViewId, { isViewpointMode }]) => {
      const traversalParams = normalizeApiTraversalParams(event.data.traversal);
      return handleViewInferenceSuccessOrWarning({
        ...traversalParams,
        type: 'EXECUTE_UNSAVED_VIEWPOINT',
        viewName:
          isViewpointMode && currentViewId
            ? currentViewId
            : getSupportedTraversalViewIdOrDefault(undefined),
      });
    })
  );

export const handleSuggestTraversalEventRoutine = (
  websocketSuggestTraversal$: Observable<
    UserEvent<ClientRequest<SuggestTraversalEvent>>
  >
) =>
  websocketSuggestTraversal$.pipe(
    distinctUntilChanged<UserEvent<ClientRequest<SuggestTraversalEvent>>>(
      isEqual
    ),
    tap(trackTraversalSuggestions),
    withLatestFrom(viewInference$),
    filter(([event, state]) =>
      viewInferenceOperations.isActiveSuggestRequest(
        state,
        ardoqEventOperations.getClientRequestId(event)
      )
    ),
    map(([event]) => event.data),
    tap(dispatchSuggestSuccessOrWarning)
  );

const dispatchSuggestSuccessOrWarning = (event: SuggestTraversalEvent) => {
  const warning = viewInferenceOperations.getSuggestWarning(event.suggestions);
  if (warning) {
    dispatchAction(notifySuggestTraversalWarning(warning));
  } else {
    dispatchAction(notifySuggestTraversalSuccess(event.suggestions));
  }
};
