import { BrandButton, SecondaryButton } from '@ardoq/button';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { trackEvent } from 'tracking/tracking';
import { confirmApprovedChanges } from './actions';
import changeApprovalData$ from './changeApprovalData$';
import { map } from 'rxjs';
import { componentIsComplete, validationObjectHasChanges } from './utils';
import { Drawer } from '@ardoq/snowflakes';
import ChangeApprovalComponents from './ChangeApprovalComponents';
import { APIComponentAttributes, APISurveyAttributes } from '@ardoq/api-types';
import { EnhancedScopeData } from '@ardoq/data-model';
import { ComponentRepresentation } from '@ardoq/renderers';
import { colorsAndRepresentations } from '@ardoq/scope-data';
import { isEqual } from 'lodash';

const fallbackRepresentationData =
  colorsAndRepresentations.getFallbackComponentRepresentation();

type ApprovalDrawerProps = {
  survey: APISurveyAttributes;
  hasChanges: boolean;
  isSaving: boolean;
  allChangesAreHandled: boolean;
  sourceOfTruthComponent: APIComponentAttributes | undefined;
  sourceOfTruthScopeData: EnhancedScopeData;
  handleClose: () => void;
};

const ApprovalDrawer = ({
  hasChanges,
  isSaving,
  allChangesAreHandled,
  survey,
  sourceOfTruthComponent,
  sourceOfTruthScopeData,
  handleClose,
}: ApprovalDrawerProps) => {
  const componentRepresentationData = sourceOfTruthComponent
    ? colorsAndRepresentations.getComponentRepresentationData(
        sourceOfTruthScopeData,
        sourceOfTruthComponent._id
      )
    : fallbackRepresentationData;
  const color = sourceOfTruthComponent
    ? colorsAndRepresentations.getComponentColor(
        sourceOfTruthScopeData,
        sourceOfTruthComponent._id
      )
    : undefined;
  const hasRepresentationData = !isEqual(
    componentRepresentationData,
    fallbackRepresentationData
  );
  return (
    <Drawer
      drawerSize="large"
      icon={
        hasRepresentationData && (
          <ComponentRepresentation
            {...componentRepresentationData}
            style={{ color }}
          />
        )
      }
      title={sourceOfTruthComponent?.name}
      subtitle={survey.name}
      controls={
        <>
          <SecondaryButton
            onClick={() => {
              trackEvent('Change approval: clicked on cancel');
              handleClose();
            }}
            dataTestId="approval-drawer-cancel-button"
          >
            Cancel
          </SecondaryButton>
          <BrandButton
            onClick={() => {
              dispatchAction(confirmApprovedChanges(survey._id));
              trackEvent('Change approval: clicked on save');
              handleClose();
            }}
            isDisabled={!hasChanges || !allChangesAreHandled}
            isLoading={isSaving}
            dataTestId="approval-drawer-save-button"
          >
            Save and apply
          </BrandButton>
        </>
      }
    >
      <ChangeApprovalComponents />
    </Drawer>
  );
};

const changeApprovalDrawerData$ = changeApprovalData$.pipe(
  map(
    ({
      status,
      componentValidationObject,
      componentId,
      masterData,
      branchData,
      changeAction,
    }) => {
      const masterComponent = masterData.componentsById[componentId];
      const branchComponent = branchData.componentsById[componentId];
      return {
        isSaving: status === 'saving',
        hasChanges: validationObjectHasChanges(
          componentValidationObject[componentId]
        ),
        allChangesAreHandled: componentIsComplete(
          componentValidationObject[componentId]
        ),
        sourceOfTruthComponent:
          changeAction === 'create' ? branchComponent : masterComponent,
        sourceOfTruthScopeData:
          changeAction === 'create' ? branchData : masterData,
      };
    }
  )
);

export default connect(ApprovalDrawer, changeApprovalDrawerData$);
