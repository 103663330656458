import { ViewIds } from '@ardoq/api-types';
import { isViewRestrictedByQuickStart } from 'quickStart/isViewRestrictedByQuickStart';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import getMetaInfoTabs from 'views/metaInfoTabs';

// Read about the logic and relevant code parts in
// https://ardoqcom.atlassian.net/wiki/spaces/INSIGHT/pages/2413002789/Discontinued+Views+engineering+perspective.

export const deprecatedViewsSuccessors = new Map([
  [ViewIds.RELATIONSHIPS, ViewIds.RELATIONSHIPS_3],
  [ViewIds.DEPMAP, ViewIds.DEPENDENCY_MAP_2],
  [ViewIds.ROADMAP, ViewIds.COMPONENT_MATRIX],
]);

export const VIEWS_RESTRICTED_IN_WORKSPACE_MODE = new Set<ViewIds>([
  ViewIds.BLOCKS,
  ViewIds.MODERNIZED_BLOCK_DIAGRAM,
]);

const VIEWS_RESTRICTED_EVERYWHERE = new Set<ViewIds>([]);

const DEFAULT_REPLACEMENT = ViewIds.PAGESVIEW;

export const VIEW_REPLACEMENTS_IN_WORKSPACE_MODE = new Map([
  [ViewIds.BLOCKS, ViewIds.BLOCK_DIAGRAM],
  [ViewIds.MODERNIZED_BLOCK_DIAGRAM, ViewIds.BLOCK_DIAGRAM],
]);

/**
 * @returns the successor of the discontinued view if defined, PAGESVIEW otherwise
 */
export const replaceViewIfDiscontinued = (viewId?: ViewIds | null) =>
  viewId && isViewDiscontinued(viewId)
    ? (deprecatedViewsSuccessors.get(viewId) ?? ViewIds.PAGESVIEW)
    : viewId;

export const isViewDiscontinued = (viewId?: ViewIds) =>
  getMetaInfoTabs().get(viewId ?? ViewIds.NONE)?.viewStatus ===
  MetaInfoViewStatus.DISCONTINUED;

export const isViewRestrictedInWorkspaceMode = (id: ViewIds): boolean =>
  !loadedGraph$.state.isViewpointMode &&
  VIEWS_RESTRICTED_IN_WORKSPACE_MODE.has(id);
/**
 * Evaluates whether the view still can be added to a presentation or be selected
 * as a start view for a workspace/template:
 *
 * 1. If the view is restricted by the quick start feature flag, it is not eligible.
 * 2. If no view status is found, the view is eligible.
 * 3. If deprecation phase is below 2 the view is eligible.
 *
 * @see https://ardoqcom.atlassian.net/wiki/spaces/INSIGHT/pages/2413002789/Discontinued+Views+engineering+perspective
 */
export const isViewEligibleForNewAssets = (viewId: ViewIds): boolean => {
  if (
    isViewRestrictedByQuickStart(viewId) ||
    isViewRestrictedInWorkspaceMode(viewId) ||
    VIEWS_RESTRICTED_EVERYWHERE.has(viewId)
  ) {
    return false;
  }

  const metaInfo = getMetaInfoTabs().get(viewId);

  if (!metaInfo || !metaInfo.viewStatus) {
    return true;
  }

  if (
    metaInfo.viewStatus === MetaInfoViewStatus.DISCONTINUED ||
    metaInfo.killDate
  ) {
    return false;
  }

  return true;
};

export const replaceViewIfNotEligible = (viewId: ViewIds | null) => {
  if (!viewId) {
    return null;
  }

  if (isViewDiscontinued(viewId)) {
    return replaceViewIfDiscontinued(viewId);
  }

  if (isViewRestrictedInWorkspaceMode(viewId)) {
    return (
      VIEW_REPLACEMENTS_IN_WORKSPACE_MODE.get(viewId) ?? DEFAULT_REPLACEMENT
    );
  }

  if (isViewRestrictedByQuickStart(viewId)) {
    return DEFAULT_REPLACEMENT;
  }

  return viewId;
};
