import {
  APIReviewRequestResourceAccessData,
  ArdoqId,
  PermissionAccessLevel,
  RequestResourceAccessResolutionOperationType,
} from '@ardoq/api-types';
import { Resolution } from '@ardoq/api';

export const getResolution = (
  requestData: APIReviewRequestResourceAccessData,
  permissionType: RequestResourceAccessResolutionOperationType,
  selectedGroup: ArdoqId
): Resolution => {
  switch (permissionType) {
    case RequestResourceAccessResolutionOperationType.SET_USER_PERMISSIONS:
      return {
        operands: [
          {
            permission:
              requestData!.request.access.permission ??
              PermissionAccessLevel.READ,
            ['user-id']: requestData!.requester._id,
          },
        ],
        operation: permissionType,
      };

    default:
      return {
        operands: [
          {
            ['group-id']: selectedGroup!,
            permission:
              requestData!.request.access.permission ??
              PermissionAccessLevel.READ,
          },
        ],
        operation:
          RequestResourceAccessResolutionOperationType.SET_GROUP_PERMISSIONS,
      };
      break;
  }
};
