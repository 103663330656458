import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { Observable } from 'rxjs';
import { CloudProviderIntegrationId } from './types';
import { ResourceType } from 'integrations/cloudProviders/streams/resourceTypes/types';

export const isCloudProviderIntegrationId = (
  integrationId: IntegrationId
): integrationId is CloudProviderIntegrationId =>
  ['aws-v3', 'azure-v3'].includes(integrationId);

export const ensureCloudProvider = () => {
  return (
    source: Observable<IntegrationId>
  ): Observable<CloudProviderIntegrationId> => {
    return new Observable<CloudProviderIntegrationId>(observer => {
      return source.subscribe({
        next(integrationId) {
          if (isCloudProviderIntegrationId(integrationId)) {
            observer.next(integrationId);
          }
        },
        error(error) {
          observer.error(error);
        },
        complete() {
          observer.complete();
        },
      });
    });
  };
};

export function resourceTypesToFields(resourceTypes: ResourceType[]) {
  return resourceTypes.map(rt => ({
    name: rt.id,
    label: rt.type ? rt.type.toLowerCase() : rt.name,
  }));
}
