import IconClickable from 'atomicComponents/IconClickable';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { colors, s24 } from '@ardoq/design-tokens';
import { IconName } from '@ardoq/icons';

const KnowledgeBaseIcon = () => (
  <IconClickable
    color={colors.grey50}
    hoverColor={colors.grey35}
    iconName={IconName.KNOWLEDGE_BASE}
    tooltipText="Knowledge base"
    onClick={() => window.open(KnowledgeBaseLink.BROADCASTS, '_blank noopener')}
    containerStyle={{
      height: s24,
    }}
  />
);

export default KnowledgeBaseIcon;
