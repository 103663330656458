import { actionCreator } from '@ardoq/rxbeach';
import { UploadInfo } from './types';
import { UploadResponse } from '@ardoq/api-types/integrations';

export type UploadFileRequestPayload = {
  file: File;
  isReuploading?: boolean;
};

const NAMESPACE = '[integrations] UPLOAD';

export const uploadFileRequest = actionCreator<UploadFileRequestPayload>(
  `${NAMESPACE}_REQUEST`
);

export const saveResponse = actionCreator<UploadResponse>(
  `${NAMESPACE}_SAVE_RESPONSE`
);

export const setUploadInfo = actionCreator<UploadInfo>(
  `${NAMESPACE}_UPLOAD_INFO`
);
