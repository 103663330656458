import { combineLatest } from 'rxjs';
import workspaces$ from 'streams/workspaces/workspaces$';
import reportBuilderForm$ from './reportBuilderForm$';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { ArdoqId, Workspace } from '@ardoq/api-types';
import { isEqual, sumBy } from 'lodash';
import {
  dispatchAction,
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import {
  WorkspaceDrawerProps,
  WorkspaceDrawerTableCommands,
  WorkspaceDrawerTableDataSource,
} from '@ardoq/report-builder';
import {
  changeWorkspaceSearchKey,
  newWorkspaceSelected,
  numberOfWorkspacesPerPageChanged,
  workspaceDrawerSelectionChanged,
} from './actions';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';

type WorkspaceInputState = {
  workspaces: Workspace[];
  selectedWorkspaceIds: ArdoqId[];
  reportName: string;
};

const workspaceInput$ = combineLatest([
  workspaces$,
  reportBuilderForm$,
  currentUserPermissionContext$,
]).pipe(
  map(([{ models }, { reportTemplate }, permissionsContext]) => ({
    workspaces: permissionsContext
      ? models.filter(model =>
          workspaceAccessControlInterface.canAccessWorkspace(
            permissionsContext,
            model._id
          )
        )
      : [],
    selectedWorkspaceIds: reportTemplate.workspaceIds,
    reportName: reportTemplate.name,
  })),
  distinctUntilChanged((a: WorkspaceInputState, b: WorkspaceInputState) =>
    isEqual(a, b)
  ),
  startWith({
    workspaces: [],
    selectedWorkspaceIds: [],
    reportName: '',
  })
);

type WorkspaceDrawer$State = Omit<
  WorkspaceDrawerProps,
  'handleCloserDrawer'
> & {
  unfilteredDataSource: WorkspaceDrawerTableDataSource;
  workspaceSelection: Record<ArdoqId, boolean>;
};

const initDataSource = (
  state: WorkspaceDrawer$State,
  { workspaces, selectedWorkspaceIds, reportName }: WorkspaceInputState
): WorkspaceDrawer$State => {
  const selectedIds = new Set(selectedWorkspaceIds);
  const isAllWorkspacesSelected = workspaces.every(({ _id }) =>
    selectedIds.has(_id)
  );
  const dataSource = workspaces.map(
    ({ _id, name, lastUpdated, lastModifiedByName }) => ({
      name,
      _id,
      isSelected: isAllWorkspacesSelected || selectedIds.has(_id),
      lastModifiedByName,
      lastUpdated,
    })
  );

  return {
    ...state,
    reportName,
    isAllWorkspacesSelected,
    numberOfSelectedWorkspaces: selectedWorkspaceIds.length,
    dataSource,
    unfilteredDataSource: dataSource,
    workspaceSelection: Object.fromEntries(
      dataSource.map(({ _id, isSelected }) => [_id, isSelected])
    ),
  };
};

const setSearchKeyAndFilterDataSource = (
  state: WorkspaceDrawer$State,
  searchKey: string
): WorkspaceDrawer$State => {
  const searchKeyLowerCased = searchKey.toLowerCase();
  return {
    ...state,
    searchKey,
    dataSource: state.unfilteredDataSource
      .filter(({ name }) => name.toLowerCase().includes(searchKeyLowerCased))
      .map(row =>
        row.isSelected === state.workspaceSelection[row._id]
          ? row
          : { ...row, isSelected: state.workspaceSelection[row._id] }
      ),
  };
};

const setIsDataSourceRowSelected = (
  state: WorkspaceDrawer$State,
  newWorkspaceSelection: Record<ArdoqId, boolean>
): WorkspaceDrawer$State => {
  const numberOfSelectedWorkspaces = sumBy(
    Object.values(newWorkspaceSelection),
    isSelected => (isSelected ? 1 : 0)
  );
  return {
    ...state,
    isAllWorkspacesSelected:
      numberOfSelectedWorkspaces === state.unfilteredDataSource.length,
    numberOfSelectedWorkspaces,
    workspaceSelection: newWorkspaceSelection,
    dataSource: state.dataSource.map(workspace => {
      const isSelected = newWorkspaceSelection[workspace._id];
      return isSelected === workspace.isSelected
        ? workspace
        : {
            ...workspace,
            isSelected,
          };
    }),
  };
};

const setNumberOfWorkspacesPerPage = (
  state: WorkspaceDrawer$State,
  numberOfWorkspacesPerPage: number
): WorkspaceDrawer$State => ({ ...state, numberOfWorkspacesPerPage });

const defaultState: WorkspaceDrawer$State = {
  dataSource: [],
  searchKey: '',
  numberOfWorkspacesPerPage: 25,
  isAllWorkspacesSelected: false,
  numberOfSelectedWorkspaces: 0,
  reportName: '',
  unfilteredDataSource: [],
  totalNumberOfAvailableWorkspaces: 0,
  workspaceSelection: {},
};

const workspaceDrawer$ = persistentReducedStream<WorkspaceDrawer$State>(
  'workspaceDrawer$',
  defaultState,
  [
    streamReducer(workspaceInput$, initDataSource),
    reducer(changeWorkspaceSearchKey, setSearchKeyAndFilterDataSource),
    reducer(workspaceDrawerSelectionChanged, setIsDataSourceRowSelected),
    reducer(numberOfWorkspacesPerPageChanged, setNumberOfWorkspacesPerPage),
  ]
);

type WorkspaceDrawerViewModel$State = Omit<
  WorkspaceDrawerProps,
  'handleCloserDrawer'
> & { commands: WorkspaceDrawerTableCommands };

export const workspaceDrawerViewModel$ = workspaceDrawer$.pipe(
  map(
    ({
      isAllWorkspacesSelected,
      dataSource,
      unfilteredDataSource,
      workspaceSelection,
      ...state
    }): WorkspaceDrawerViewModel$State => ({
      ...state,
      isAllWorkspacesSelected,
      dataSource,
      totalNumberOfAvailableWorkspaces: unfilteredDataSource.length,
      commands: {
        toggleSelectedWorkspace: id => {
          dispatchAction(
            workspaceDrawerSelectionChanged({
              ...workspaceSelection,
              [id]: !workspaceSelection[id],
            })
          );
        },
        toggleSelectAllWorkspaces: () => {
          dispatchAction(
            workspaceDrawerSelectionChanged(
              Object.fromEntries(
                unfilteredDataSource.map(({ _id }) => [
                  _id,
                  !isAllWorkspacesSelected,
                ])
              )
            )
          );
        },
        setSearchKey: searchKey =>
          dispatchAction(changeWorkspaceSearchKey(searchKey)),
        setNumberOfWorkspacesPerPage: numberOfWorkspacesPerPage =>
          dispatchAction(
            numberOfWorkspacesPerPageChanged(numberOfWorkspacesPerPage)
          ),
        confirmedWorkspaceSelection: () =>
          dispatchAction(
            newWorkspaceSelected(
              unfilteredDataSource
                .filter(({ _id }) => workspaceSelection[_id])
                .map(({ _id }) => _id)
            )
          ),
      },
    })
  )
);
