import { confirm } from '@ardoq/modal';

const confirmSaveOrDiscardBroadcast = async (name: string | null) => {
  return confirm({
    title: 'Unsaved broadcast changes',
    text: (
      <>
        You have started editing{' '}
        {name ? (
          <>
            the <strong>{name}</strong>
          </>
        ) : (
          'an untitled'
        )}{' '}
        broadcast. Do you want to save or discard your changes before you
        proceed?
      </>
    ),
    cancelButtonTitle: 'Discard',
    confirmButtonTitle: 'Save',
  });
};

export default confirmSaveOrDiscardBroadcast;
