import {
  collectRoutines,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { switchMap } from 'rxjs';
import { saveSlide, slideNamespace } from './actions';
import { handleError, slideApi } from '@ardoq/api';
import { handleDelete, handleFetchAll } from 'streams/crud/routines';

const handleSaveSlide = routine(
  ofType(saveSlide),
  extractPayload(),
  switchMap(slideApi.update),
  handleError()
);

export const slidesRoutines = collectRoutines(
  handleFetchAll(slideNamespace, slideApi.fetchAll),
  handleDelete(slideNamespace, slideApi.delete),
  handleSaveSlide
);
