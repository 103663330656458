import { createRef, Component } from 'react';
import { FeatureHighlightTooltip, HighlightBox } from 'featureHighlight/atoms';
import featureHighlight$ from 'featureHighlight/featureHighlight$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  hideFeatureHighlight,
  resetPosition,
  updatePosition,
} from 'featureHighlight/actions';
import { ArrowDown, ArrowUp } from '@ardoq/tooltip';
import { FeatureHighlightState } from './types';

const resetTooltipPosition = () => dispatchAction(resetPosition());
const dismiss = () => dispatchAction(hideFeatureHighlight());

class FeatureHighlightManager extends Component<FeatureHighlightState> {
  tooltipRef = createRef<HTMLDivElement>();

  override componentDidMount() {
    window.addEventListener('resize', resetTooltipPosition);
    document.addEventListener('scroll', resetTooltipPosition, {
      passive: true,
    });
    document.addEventListener('click', dismiss, {
      capture: true,
    });
  }

  override componentWillUnmount() {
    window.removeEventListener('resize', resetTooltipPosition);
    document.removeEventListener('scroll', resetTooltipPosition, {
      // @ts-expect-error lib.dom.d.ts doesn't think we should pass this argument, but Mozilla thinks we should. https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener#matching_event_listeners_for_removal
      passive: true,
    });
    document.removeEventListener('click', dismiss, {
      capture: true,
    });
  }

  componentDidUpdate() {
    if (this.props.referenceElementSelector && !this.props.tooltipPosition) {
      const referenceElement = document.querySelector(
        this.props.referenceElementSelector
      );
      if (!referenceElement) {
        return;
      }
      const referenceBox = referenceElement.getBoundingClientRect();
      const tooltipBox = this.tooltipRef.current!.getBoundingClientRect();
      dispatchAction(updatePosition({ referenceBox, tooltipBox }));
    }
  }

  render() {
    const {
      referenceElementSelector,
      isFlipped,
      highlightBoxPosition,
      arrowPosition,
      tooltipPosition,
      tooltipText,
    } = this.props;
    if (!referenceElementSelector) {
      return null;
    }
    return (
      <div>
        {isFlipped ? (
          <ArrowDown style={arrowPosition || {}} />
        ) : (
          <ArrowUp style={arrowPosition || {}} />
        )}
        <FeatureHighlightTooltip
          style={tooltipPosition || {}}
          ref={this.tooltipRef}
        >
          {tooltipText}
        </FeatureHighlightTooltip>
        <HighlightBox style={highlightBoxPosition ?? undefined} />
      </div>
    );
  }
}

export default connect(FeatureHighlightManager, featureHighlight$);
