import { CSSProperties } from 'react';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import styled from 'styled-components';
import * as fonts from 'app/designTokens/fonts';
import { APIWorkspaceAttributes } from '@ardoq/api-types';
import { colors } from '@ardoq/design-tokens';
import { FlexWrapper, LabelWrapper } from '@ardoq/renderers';

export const ItemWrapper = styled(FlexWrapper)`
  ${fonts.pageViewSmallTemp};
  line-height: 28px;
  height: 18px;
`;

const WorkspaceIcon = styled(Icon).attrs({
  iconName: IconName.WORKSPACE,
  iconSize: IconSize.SMALL,
})`
  fill: ${colors.grey15};
`;

export const WorkspaceLabel = ({
  workspace,
  ...props
}: {
  workspace: Pick<APIWorkspaceAttributes, 'name'>;
  style?: CSSProperties;
  className?: string;
}) => (
  <ItemWrapper {...props}>
    <WorkspaceIcon />
    <LabelWrapper>{workspace.name}</LabelWrapper>
  </ItemWrapper>
);

export const MediumSizeWorkspaceLabel = styled(WorkspaceLabel)`
  ${fonts.pageViewMediumTemp};
`;
