import {
  combineLatest,
  EMPTY,
  filter,
  firstValueFrom,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  action$,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { SelectionResponse } from '@ardoq/api-types/integrations';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import {
  updateConnectionStatuses,
  selectConnection,
  resetSelectedConnectionIds,
  updateSingleSelectedConnectionId,
} from 'integrations/common/streams/connections/actions';
import { AzureConnection } from '@ardoq/api-types/integrations';
import { AsyncOperations } from 'integrations/common/async/constants';
import {
  ensureCorrectAsyncRequest,
  getAsyncRequestId,
  parseAsyncResponsePayload,
} from 'integrations/common/async/utils';
import { userEvent } from 'sync/actions';
import {
  applyCloudProviderParams,
  applySubscriptions,
  filterSubscriptionSelection,
  setFetchErrorMessage,
  setSelectionAsyncStatus,
  unselectCloudProviderParams,
  resetSelection,
  changeSubscriptions,
  setSelectionRequest,
  toggleResourceGroup,
} from './actions';
import { selectionState$ } from './selectionState$';
import { fetchSelectionSuccess as fetchAzureSelectionSuccess } from './actions';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { applySourceConfig } from 'integrations/cloudProviders/config/actions';
import {
  changeRegionsSelection,
  resetRegions,
} from 'integrations/cloudProviders/streams/regions/actions';
import { getRegionsStream } from 'integrations/cloudProviders/streams/regions/regions$';
import {
  changeResourceTypesSelection,
  fetchResourceTypes,
} from 'integrations/cloudProviders/streams/resourceTypes/actions';
import { getResourceTypesStream } from 'integrations/cloudProviders/streams/resourceTypes/resourceTypes$';
import { azureSubscriptions$ } from '../subscriptions/subscriptions$';
import { resourceGroups$ } from '../resourceGroups/resourceGroups$';
import {
  resetResourceGroups,
  updateSelectedResourceGroupNames,
} from '../resourceGroups/actions';
import { createProviderParams } from 'integrations/azure/utils';
import {
  fetchSelection,
  fetchSelectionError,
  fetchSelectionSuccess,
  loadSelection,
} from 'integrations/common/streams/selectionState/actions';
import { fetchResourcesSummary } from '../resourcesSummary/actions';
import { resetAzureSubscriptions } from '../subscriptions/actions';

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  filter(integrationId => integrationId === 'azure-v3'),
  tap(() => {
    dispatchAction(resetSelection());
    dispatchAction(unselectCloudProviderParams());
    dispatchAction(resetRegions({ integrationId: 'azure-v3' }));
  })
);

const handleSelectConnection = routine(
  ofType(selectConnection),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'azure-v3'),
  withLatestFrom(getConnectionsStream('azure-v3')),
  switchMap(
    ([
      { selectedConnectionId },
      {
        connections,
        statuses: {
          list: { status },
        },
      },
    ]) => {
      // waiting for available connections to be fetched
      return combineLatest([
        of(selectedConnectionId),
        status === 'SUCCESS'
          ? of(connections)
          : firstValueFrom(
              action$.pipe(
                ofType(updateConnectionStatuses),
                extractPayload(),
                filter(
                  ({ integrationId, statuses }) =>
                    integrationId === 'azure-v3' &&
                    (statuses.list?.status === 'SUCCESS' ||
                      statuses.list?.status === 'FAILURE')
                ),
                withLatestFrom(getConnectionsStream('azure-v3')),
                map(([_, { connections = [] }]) => connections)
              )
            ),
      ]);
    }
  ),
  tap(([connectionId, connections]) => {
    dispatchAction(resetSelection());
    dispatchAction(resetAzureSubscriptions());
    dispatchAction(resetResourceGroups());
    dispatchAction(resetRegions({ integrationId: 'azure-v3' }));

    const sourceConnection = connections.find(
      ({ _id }) => _id === connectionId
    );

    if (!connectionId) {
      return;
    }

    if (!sourceConnection) {
      // TODO: notify customer that the connection from configuration no longer exists
      dispatchAction(resetSelectedConnectionIds({ integrationId: 'azure-v3' }));
      return;
    }
    dispatchAction(
      updateSingleSelectedConnectionId({
        integrationId: 'azure-v3',
        selectedConnectionId: connectionId,
      })
    );
    dispatchAction(fetchResourcesSummary({ connectionId }));
    dispatchAction(fetchResourceTypes({ integrationId: 'azure-v3' }));
    dispatchAction(
      trackIntegrationEvent({
        integrationId: 'azure-v3',
        name: 'SELECTED_CONNECTION',
      })
    );
  })
);

const handleFetchSelection = routine(
  ofType(loadSelection),
  extractPayload(),
  filter(integrationId => integrationId === 'azure-v3'),
  withLatestFrom(
    activeIntegrations$,
    selectionState$,
    getConnectionsStream<AzureConnection>('azure-v3'),
    getRegionsStream('azure-v3'),
    resourceGroups$,
    getResourceTypesStream('azure-v3'),
    getConnectionsStream('azure-v3')
  ),
  tap(
    ([
      integrationId,
      activeIntegrations,
      selectionState,
      { connections },
      { selectedRegionIds },
      { selectedResourceGroupNames },
      { selectedResourceTypeIds },
      { selectedConnectionIds },
    ]) => {
      if (
        !selectedConnectionIds.length ||
        !connections ||
        selectionState.subscriptionIds.length === 0
      ) {
        return EMPTY;
      }

      const connection = connections.find(
        ({ _id }) => _id === selectedConnectionIds[0]
      );

      if (!connection) {
        return EMPTY;
      }

      const funnelId = activeIntegrations[integrationId].trackingFunnelId;
      const providerParams = createProviderParams({
        regions: selectedRegionIds,
        resourceTypes: selectedResourceTypeIds,
        subscriptions: selectionState.subscriptionIds,
        resourceGroups: selectedResourceGroupNames,
      });
      const selectionRequestPayload = {
        accountIds: selectedConnectionIds,
        providerParams: {
          ...providerParams,
          // for selection request, subscriptions are mandatory
          subscriptions: providerParams.subscriptions ?? [],
        },
      };

      dispatchAction(setSelectionAsyncStatus('LOADING'));
      dispatchAction(setFetchErrorMessage(null));
      dispatchAction(setSelectionRequest(selectionRequestPayload));
      dispatchAction(
        trackIntegrationEvent({
          integrationId: 'azure-v3',
          name: 'TRIGGERED_SOURCE_DATA_FETCH',
          metadata: {
            regions: selectionRequestPayload.providerParams.regions,
            resourceTypes: selectionRequestPayload.providerParams.resourceTypes,
            numberOfSubscriptions:
              selectionRequestPayload.providerParams.subscriptions?.length,
            numberOfResourceGroups:
              selectionRequestPayload.providerParams.vpcs.length,
          },
        })
      );
      const requestId = getAsyncRequestId({
        integrationId: 'azure-v3',
        operation: AsyncOperations.AZURE_FETCH_DATA,
        funnelId,
      });

      dispatchAction(
        fetchSelection({
          integrationId: 'azure-v3',
          requestId,
          payload: selectionRequestPayload,
        })
      );
    }
  )
);

const handleFetchSelectionResponse = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncResponsePayload<SelectionResponse>),
  withLatestFrom(activeIntegrations$),
  ensureCorrectAsyncRequest(AsyncOperations.AZURE_FETCH_DATA),
  tap(([response]) => {
    if (!response) return;

    if (response.status !== 'success') {
      dispatchAction(setSelectionAsyncStatus('FAILURE'));

      if (response.data.message) {
        dispatchAction(setFetchErrorMessage(response.data.message));
      }

      return;
    }

    dispatchAction(setFetchErrorMessage(null));
    dispatchAction(
      fetchSelectionSuccess({
        response: response.data,
        integrationId: 'azure-v3',
      })
    );
  })
);

const handleFilterSubscriptionSelection = routine(
  ofType(filterSubscriptionSelection),
  withLatestFrom(selectionState$, azureSubscriptions$),
  tap(([_, { subscriptionIds }, { subscriptions }]) => {
    const availableSubscription = subscriptionIds.filter(id =>
      subscriptions.some(({ subscriptionId }) => subscriptionId === id)
    );
    dispatchAction(applySubscriptions(availableSubscription));
  })
);

const handleApplyCloudProviderParams = routine(
  ofType(applyCloudProviderParams),
  extractPayload(),
  tap(providerParams => {
    dispatchAction(applySubscriptions(providerParams.subscriptions || []));

    dispatchAction(
      updateSelectedResourceGroupNames({
        names: providerParams.vpcs,
      })
    );

    dispatchAction(
      changeRegionsSelection({
        integrationId: 'azure-v3',
        regions: providerParams.regions,
      })
    );
    dispatchAction(
      changeResourceTypesSelection({
        integrationId: 'azure-v3',
        resourceTypes: providerParams.resourceTypes,
      })
    );
  })
);

const handleUnselectCloudProviderParams = routine(
  ofType(unselectCloudProviderParams),
  extractPayload(),
  tap(() => {
    dispatchAction(applySubscriptions([]));

    dispatchAction(updateSelectedResourceGroupNames({ names: [] }));

    dispatchAction(
      changeRegionsSelection({
        integrationId: 'azure-v3',
        regions: [],
      })
    );
    dispatchAction(
      changeResourceTypesSelection({
        integrationId: 'azure-v3',
        resourceTypes: [],
      })
    );
  })
);

const handleApplySourceConfig = routine(
  ofType(applySourceConfig),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'azure-v3'),
  tap(sourceConfig => {
    dispatchAction(unselectCloudProviderParams());
    dispatchAction(
      selectConnection({
        integrationId: 'azure-v3',
        selectedConnectionId: sourceConfig.accountIds[0],
      })
    );
    dispatchAction(applyCloudProviderParams(sourceConfig.providerParams));
  })
);

const handleChangeResourceGroupsSelection = routine(
  ofType(toggleResourceGroup),
  extractPayload(),
  withLatestFrom(resourceGroups$, getConnectionsStream('azure-v3')),
  tap(
    ([
      { resourceGroupName },
      resourceGroupsState,
      { selectedConnectionIds },
    ]) => {
      if (!selectedConnectionIds.length) {
        return;
      }

      const alreadySelected =
        resourceGroupsState.selectedResourceGroupNames.includes(
          resourceGroupName
        );
      // Toggle selection if already selected
      const newResourceNames = alreadySelected
        ? resourceGroupsState.selectedResourceGroupNames.filter(
            name => name !== resourceGroupName
          )
        : [
            ...resourceGroupsState.selectedResourceGroupNames,
            resourceGroupName,
          ];
      dispatchAction(
        updateSelectedResourceGroupNames({
          names: newResourceNames,
        })
      );
    }
  )
);

const handleChangeSubscriptions = routine(
  ofType(changeSubscriptions),
  extractPayload(),
  withLatestFrom(resourceGroups$),
  tap(([{ subscriptionIds, oldSubscriptionIds }, resourceGroupsState]) => {
    if (!oldSubscriptionIds.length) {
      dispatchAction(applySubscriptions(subscriptionIds));
      return;
    }

    const removedSubscriptions = oldSubscriptionIds.filter(
      subscriptionId => !subscriptionIds.includes(subscriptionId)
    );

    if (removedSubscriptions.length > 0) {
      const selectedResourceGroups = resourceGroupsState.resourceGroups
        .filter(
          rg =>
            resourceGroupsState.selectedResourceGroupNames.includes(rg.name) &&
            !removedSubscriptions.includes(rg.subscriptionId)
        )
        .map(rg => rg.name);

      dispatchAction(
        updateSelectedResourceGroupNames({
          names: selectedResourceGroups,
        })
      );
    }

    dispatchAction(applySubscriptions(subscriptionIds));
  })
);

const handleFetchSelectionSuccess = routine(
  ofType(fetchSelectionSuccess),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'azure-v3'),
  tap(({ response }) => {
    dispatchAction(fetchAzureSelectionSuccess(response));
  })
);

const handleFetchSelectionError = routine(
  ofType(fetchSelectionError),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'azure-v3'),
  tap(() => {
    dispatchAction(setSelectionAsyncStatus('FAILURE'));
    dispatchAction(setFetchErrorMessage('Failed to fetch data'));
  })
);

export default [
  handleSelectConnection,
  handleFetchSelection,
  handleFetchSelectionResponse,
  handleApplyCloudProviderParams,
  handleApplySourceConfig,
  handleUnselectCloudProviderParams,
  handleFilterSubscriptionSelection,
  handleResetIntegration,
  handleChangeSubscriptions,
  handleFetchSelectionSuccess,
  handleChangeResourceGroupsSelection,
  handleFetchSelectionError,
];
