import styled, { css } from 'styled-components';
import {
  colors,
  r8,
  s16,
  s4,
  s8,
  shadowL,
  space,
  zIndex,
} from '@ardoq/design-tokens';
import { SearchInput } from '@ardoq/forms';
import { text2, text2Bold } from '@ardoq/typography';
import { ICON_SELECTOR } from '@ardoq/icons';
import { FlexBox, Stack } from '@ardoq/layout';
import { TextOverflowEllipsis } from '@ardoq/popovers';

export const OverflowTextWrapper = styled(TextOverflowEllipsis)<{
  $isBold?: boolean;
}>`
  ${props => (props.$isBold ? text2Bold : text2)};
`;

export const ScrollableFlexBox = styled(FlexBox)`
  height: 100%;
  overflow: hidden;
`;

export const NewJourneyResult = styled(Stack).attrs({
  gap: 'xsmall',
})`
  width: 100%;
`;

export const ResultName = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.textDefault};
  column-gap: ${s8};
  p {
    margin: 0;
  }
`;

export const QuickSearchInput = styled(SearchInput)<{ $darkMode: boolean }>`
  width: 100%;
  ${({ $darkMode }) =>
    $darkMode &&
    css`
      ${ICON_SELECTOR} {
        color: ${colors.grey60};
      }
      input {
        background-color: ${colors.grey25};
        box-shadow: none;
        color: ${colors.grey60};
        &::placeholder {
          color: ${colors.grey60};
        }
      }
    `}
`;

export const QuickSearchWrapper = styled.div`
  color: ${colors.grey25};
  max-width: 480px;
  flex: 1 0 480px;
  position: relative;
`;

export const ResultAndOptions = styled.div<{
  $hasNewJourneyFeature?: boolean;
}>`
  position: absolute;
  cursor: pointer;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: ${s4};
  z-index: ${zIndex.DROPDOWN};
  background-color: ${colors.white};
  color: ${colors.grey25};
  border-radius: ${r8};
  box-shadow: ${({ $hasNewJourneyFeature }) =>
    $hasNewJourneyFeature
      ? shadowL
      : '0px 1px 2px 0px rgba(17, 23, 34, 0.06), 0px 1px 3px 0px rgba(17, 23, 34, 0.12)'};
`;

export const AdvancedOptions = styled(Stack)<{
  $hasNewJourneyFeature?: boolean;
}>`
  ${({ $hasNewJourneyFeature }) =>
    $hasNewJourneyFeature
      ? css`
          background-color: ${colors.white};
          padding: ${s8};
          gap: ${s8};
        `
      : css`
          background-color: ${colors.grey95};
          padding: ${s8} ${s16};
          gap: ${s16};
        `};
  border-radius: ${r8};
  overflow: hidden;
`;

export const ResultMenu = styled.div<{
  $hasNewJourneyFeature?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  padding: 0;
  word-wrap: break-word;
  max-height: ${({ $hasNewJourneyFeature }) =>
    $hasNewJourneyFeature ? '370px' : '70vh'};
`;

export const ResultBody = styled.div`
  flex: 1;
  border-top: 1px solid ${colors.grey80};
  overflow: auto;
`;

export const ResultContainer = styled(Stack).attrs({ gap: 'small' })`
  overflow: hidden;
  p {
    margin: 0;
  }
`;

export const ResultItem = styled.div<{
  $isSelected?: boolean;
  $isLocked?: boolean;
  $hasNewJourneyFeature?: boolean;
}>`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: ${space.medium};
  color: ${colors.grey25};

  ${({ $isSelected, $hasNewJourneyFeature }) =>
    $isSelected &&
    ($hasNewJourneyFeature
      ? `background-color: ${colors.grey95};`
      : `background-color: ${colors.grey90};`)}

  ${({ $hasNewJourneyFeature, $isLocked }) =>
    $hasNewJourneyFeature
      ? css`
          padding: ${s16};
          em {
            color: ${colors.brand50};
            font-weight: 600;
            font-style: normal;
          }
          ${$isLocked &&
          css`
            background-color: ${colors.grey95};
            border-right: 3px solid ${colors.blue50};
          `}
        `
      : css`
          padding: ${s8};
          em {
            font-weight: bold;
            color: ${colors.purple25};
          }
        `}

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const Pointer = styled.span`
  color: ${colors.purple25};
  opacity: 0.7;
`;

export const Divider = styled.hr`
  border: 0;
  border-top: 1px solid ${colors.borderSubtle00};
  margin: 0px;
`;

export const ResultType = styled.div`
  flex-shrink: 0;
  padding-top: ${s4};
  opacity: 0.7;
  ${ICON_SELECTOR} {
    color: ${colors.purple25};
    fill: ${colors.purple25};
  }
`;

export const ResultFooter = styled(ResultItem)`
  align-items: center;
  justify-content: center;
  gap: ${space.small};
  cursor: default !important;
  border-top: 1px solid ${colors.grey80};
  ${text2};
`;
