import { ArdoqId, BroadcastStatus } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  openWorkspaces,
  openWorkspacesInViewpointMode,
} from '../../appContainer/DashboardContainer/utils';
import { trackOpenedPresentationSidebar } from '../../presentation/tracking';
import { openPresentationEditor } from '../../presentationEditor/presentationUtil';
import {
  OpenViewpointBuilderCommands,
  openViewpointBuilderCommands,
} from '../../viewpointBuilder/openViewpointBuilderCommands';
import { navigateToSurveyForm } from 'surveyAdmin/navigation/actions';
import { trackOpenExistingSurvey } from '../../surveyAdmin/tracking';
import { goToMetamodel } from '../../metamodel/navigationUtils';
import { trackOpenedMetamodelEditor } from '../../metamodel/tracking';
import { openScenario } from '../../scope/actions';
import { openDashboard, openReportInReader } from '../AppMainSidebar/utils';
import {
  DashboardEventLocations,
  getTrackOpenedDashboardPayload,
} from '@ardoq/dashboard';
import { trackEvent } from '../../tracking/tracking';
import { openViewpoint } from '../../viewpointBuilder/actions';
import { confirmOpenManagedWorkspaces } from '../../externallyManaged/confirmOpenManagedWorkspaces/confirmOpenManagedWorkspaces';
import { trackOpenExistingViewpoint } from '../../viewpoints/tracking';
import { navigateToViewpointForm } from '../../router/navigationActions';
import { openBroadcast } from '../../broadcasts/utils';
import { loadBookmark } from '../../bookmarks/actions';

export type AssetsBrowser2024Commands = {
  openBookmark: (id: string) => void;
  openWorkspace: (id: string) => void;
  openManagedWorkspace: (id: ArdoqId, name: string) => void;
  openPresentationEditor: (id: string) => void;
  openSurveyEditor: (id: string) => void;
  openMetaModel: (id: string) => void;
  openScenario: (id: string) => void;
  openReport: (id: string) => void;
  openDashboard: (id: string) => void;
  openTraversal: (id: string) => void;
  openDiscoverViewpoint: (id: string) => void;
  openBroadcast: (id: string, status: BroadcastStatus) => void;
  openWorkspacesInViewpointMode: (id: string) => void;
} & Pick<OpenViewpointBuilderCommands, 'openViewpointBuilderToCreateViewpoint'>;

export const assetsBrowser2024Commands: AssetsBrowser2024Commands = {
  openBookmark: id => {
    trackEvent('Opened bookmark');
    dispatchAction(loadBookmark(id));
  },
  openViewpointBuilderToCreateViewpoint:
    openViewpointBuilderCommands.openViewpointBuilderToCreateViewpoint,
  openWorkspace: id =>
    openWorkspaces([id], { trackingLocation: 'assetsManager' }),
  openManagedWorkspace: async (id, name) => {
    const confirmed = await confirmOpenManagedWorkspaces({
      workspaceNames: [name],
    });
    if (confirmed) {
      openWorkspaces([id], {
        trackingLocation: 'assetsManager',
      });
    }
  },
  openPresentationEditor: id => {
    const isInListView =
      window.location.pathname.includes('presentation-admin');
    openPresentationEditor({ presentationId: id });
    trackOpenedPresentationSidebar(
      isInListView ? 'List view' : 'Asset manager',
      id
    );
  },
  openSurveyEditor: id => {
    trackOpenExistingSurvey(id, 'assetsManager');
    dispatchAction(navigateToSurveyForm(id));
  },
  openMetaModel: id => {
    trackOpenedMetamodelEditor({
      trackingLocation: 'assetsManager',
      resourceId: id,
    });
    goToMetamodel(id);
  },
  openScenario: id =>
    dispatchAction(
      openScenario({
        scenarioId: id,
        trackingClickSource: 'assetManager',
      })
    ),
  openReport: id => openReportInReader({ reportId: id }),
  openDashboard: id => {
    trackEvent(
      ...getTrackOpenedDashboardPayload(
        DashboardEventLocations.ASSET_MANAGER,
        id
      )
    );
    openDashboard({
      dashboardId: id,
    });
  },
  openTraversal: id => dispatchAction(openViewpoint({ viewpointId: id })),
  openDiscoverViewpoint: id => {
    trackOpenExistingViewpoint(id);
    dispatchAction(navigateToViewpointForm(id));
  },
  openBroadcast: openBroadcast,
  openWorkspacesInViewpointMode: (id: string) => {
    openWorkspacesInViewpointMode([id], {
      trackingLocation: 'assetsManager',
    });
  },
};
