import { ModelType } from 'models/ModelType';
import {
  APICurrentUser,
  APIFieldAttributes,
  APIFieldType,
  APIReferenceType,
  ArdoqId,
  Organization,
  PermissionRole,
  PersonalSetting,
  ViewIds,
  FilterAttributesBase,
  FilterAttributes,
  WorkspaceIntegration,
  CalculatedFieldSettings,
  LocallyDerivedTransformation,
  InventoryRecentColumnSelectionPerWorkspaceOrTypePersonalSettings,
  WorkspaceFilterAttributes,
} from '@ardoq/api-types';
import { CollectionView } from 'collections/consts';
import { GetCssClassNamesOption, RepresentationData } from '@ardoq/data-model';
import type {
  AttributeComparator,
  FilterAttribute,
} from '@ardoq/filter-interface';
import { MouseEvent, FormEvent } from 'react';
import Backbone from 'backbone';

export type VersionedId = {
  readonly _id: ArdoqId;
  readonly _version: number;
};

export enum Selected {
  SELECTED,
  NOT_SELECTED,
  MIXED,
}

export enum ModelStructureType {
  FLEXIBLE,
  RIGID,
}

export enum FieldPopulateMethod {
  MANUAL = 'manual',
  CALCULATED = 'calculated',
  LOCALLY_DERIVED = 'locallyDerived',
}

export const fieldTypesWithCalculatedTransformation = new Set([
  APIFieldType.CHECKBOX,
  APIFieldType.DATE_ONLY,
  APIFieldType.DATE_TIME,
  APIFieldType.EMAIL,
  APIFieldType.LIST,
  APIFieldType.NUMBER,
  APIFieldType.TEXT,
  APIFieldType.URL,
]);

export const fieldTypesWithLocallyDerivedTransformation = new Set([
  APIFieldType.NUMBER,
  APIFieldType.TEXT,
  APIFieldType.LIST,
]);

export const listFieldTypes = [
  APIFieldType.LIST,
  APIFieldType.SELECT_MULTIPLE_LIST,
  'SelectMultiple', // legacy for models/field.js
];

// Backbone models
export interface BasicModelValidationEntry {
  pattern?: RegExp;
  required?: boolean;
  msg?: string;
}
export type NodeBackboneModel = BasicModel & {
  containingCollection: Backbone.Collection<Backbone.Model>;
  getChildrenAsObjects: (
    containingCollection: Backbone.Collection<Backbone.Model>,
    nodeCollectionView?: CollectionView
  ) => ComponentBackboneModel[];
  addChild: (child: NodeBackboneModel) => void;
  getParentObject: (
    containingCollection: Backbone.Collection<Backbone.Model>
  ) => Backbone.Model | null | undefined;
  setParent: (parent: NodeBackboneModel) => void;
  new (props: Record<string, any>): NodeBackboneModel;
};
export interface BasicModel extends BackboneModelPromisedExtension {
  getId: () => ArdoqId;
  name: () => string;
  getLock: () => boolean;
  lock: () => void;
  unlock: () => void;
  remove: (obj: Backbone.Model) => void;
  setFieldValue: (fieldName: string, value: any) => void;
  getDescription: () => string;
  add: (obj: Backbone.Model) => null | void;
  /** @returns True if this BasicModel has obj in its collection of sub-objects. For Components, this method checks the children collection. For Tags, this method checks the components and references collection of the Tag. */
  contains: (obj: Backbone.Model) => boolean;
  changedAndMustBeSaved: () => boolean;
  mustBeSaved: boolean;
  editing?: boolean;
  // validationErrors is used on Workspaces, Components and Entities
  validationErrors?: any[] | null;
  lastChangeTime?: number; // Exists on components, references and workspaces
  validation?: Record<
    string,
    BasicModelValidationEntry | BackboneFormValidatorFunction
  >;
  getObjectCollectionName: (obj: Backbone.Model) => string;
  getObjectCollection: (obj: Backbone.Model) => string[] | null;
}

export type GetRefTypeResult = APIReferenceType | 'null';
export interface ReferenceTypeInfo {
  val: number | string;
  label: string;
}
export interface Reference extends BasicModel {
  addSource: (comp: ComponentBackboneModel | string) => void;
  addTarget: (comp: ComponentBackboneModel | string) => void;
  flipSourceTarget: (wsFlipRefType?: string | number) => void;
  getSource: () => ComponentBackboneModel;
  getTarget: () => ComponentBackboneModel;
  getSourceId: () => ArdoqId;
  getTargetId: () => ArdoqId;
  // note: getModelType and getRefType appear to be redundant in reference.js
  getModel: () => Model | null;
  getModelType: () => APIReferenceType;
  getRefType: (id?: string | number) => GetRefTypeResult;
  getLabel: () => string;
  /** @param escapeFieldHTML I suspect this should always be false, but to avoid a risky change I'm initially defaulting it to true. */
  getRawLabel: (escapeFieldHTML?: boolean) => string;
  getRawName: () => string;
  getName: () => string;
  isIncludedInContextByFilter: () => boolean;
  getCSS: (args?: GetCssClassNamesOption) => string;
  getShortName: () => string;
  getModelId: () => ArdoqId;
  getType: () => number;
  getFields: () => FieldBackboneModel[];
  urlRoot: string;
  initializeComponent: (type: 'source' | 'target') => void;
  changedDescription?: boolean;
  getPossibleParents: (
    callback?: (possibleParents: ReferenceTypeInfo[]) => void,
    limitToCurrentWs?: boolean
  ) => ReferenceTypeInfo[];
  getMissingComp: (
    id: ArdoqId | ComponentBackboneModel
  ) => string | ComponentBackboneModel;
  getCompId: (comp: ComponentBackboneModel) => string | null;
  getReferenceSyntax: () => '->';
  getReferenceTypes: (
    model: Model,
    callback: (values: ReferenceTypeInfo[]) => void
  ) => ReferenceTypeInfo[];
}

export type InferenceBackboneModel = Backbone.Model;
export type TraversalBackboneModel = SyncableBackboneModel;

/**
 * @deprecated
 * This type was created to have a type that was as narrow as possible to ensure that websocket events were handled correctly.
 * https://github.com/ardoq/ardoq-packages/pull/13569#discussion_r1778197128
 */
export type SyncableBackboneModel = Backbone.Model & { mustBeSaved: boolean };

export interface ImageListItem {
  label: string;
  value: string;
  isSelected: boolean;
  dataContent: string;
}
export interface ComponentBackboneModel extends NodeBackboneModel {
  getMyModel: () => Model | null;
  getTypeId: () => string;
  isIncludedInContextByFilter: () => boolean;
  getChildren: (
    ignoreSort?: boolean,
    nodeCollectionView?: CollectionView
  ) => ComponentBackboneModel[];
  getChildrenDeep: (ignoreSort?: boolean) => ComponentBackboneModel[];
  getCSS: (args?: GetCssClassNamesOption) => string;
  getRepresentationData: () => RepresentationData;
  getRepresentation: () => string;
  getReferenceCount: () => number;
  /**
   * @param escapeFieldHTML I suspect this should always be false, but to avoid a risky change I'm initially defaulting it to true.
   */
  getRawLabel: (escapeFieldHTML?: boolean) => string;
  getLabel: () => string;
  getParent: () => ComponentBackboneModel | null;
  getParents: () => ComponentBackboneModel[];
  getParentId: () => ArdoqId | undefined;
  getWorkspace: () => Workspace | null;
  getMyType: () => ModelType | null;
  getColor: () => string | null;
  getImage: () => string | null;
  getShape: () => string;
  getModelId: () => ArdoqId;
  canHaveChildren: () => boolean;
  getSubtreeDepth: () => number;
  getPossibleChildrenTypes: () => Record<string | number, unknown>;
  getPossibleTypes: () => { val: ArdoqId; label: string }[];
  getPossibleParents: () => { val: ArdoqId; label: string }[];
  getComponentTypeName: () => string;
  setComponentTypeName: () => void;
  getFields: () => FieldBackboneModel[];
  getCSSFilterColor: () => string;
  getLevel: () => number;
  findChildrenIds: () => string[] | undefined;
  getFullPathName: () => string;
  getValidComponentType: () => ModelType | null;
  getIcon: () => string | null;
  getIncomingReferenceCount: () => number;
  getOutgoingReferenceCount: () => number;
  setFullyLoaded: (isLoaded: boolean) => void;
  getFullPathNameArr: () => string[];
  EVENT_FILTERED_CHANGED: string;
  urlRoot: string;
  handleChangeParent: VoidFunction;
  changedDescription?: boolean;
  setComponentType: VoidFunction;
  setDefaultValues: (props: Record<string, any>) => void;
  getImageList: (
    callback: (values: ImageListItem[]) => void
  ) => ImageListItem[];
  getValidComponentTypeByParent: () => ModelType | null;
  hasReturnValue: () => boolean;
}

export type DateRangePropertyValue = {
  start: string | null;
  end: string | null;
};

export type PropertyValue =
  | string
  | number
  | boolean
  | Date
  | DateRangePropertyValue
  | LocallyDerivedTransformation[]
  | string[]
  | Record<string, unknown>
  | null;

export type PermissionOrgRole = PermissionRole;

export interface GroupByBackboneModel extends Backbone.Model {
  isTiedToWorkspace: () => boolean;
  isTiedToField: () => boolean;
  getTargetID: () => string;
  encodeURI: () => string;
}

export interface GroupByBackboneModelCollection
  extends Backbone.Collection<GroupByBackboneModel> {
  removeAll: () => void;
  getAll: () => GroupByBackboneModel[];
  addFromString: (encodedFilter: string) => void;
  notifyOnAffectingChange: (args: { fieldNames: string[] }) => void;
  hasGroupByField: (field: FieldBackboneModel) => boolean;
  hasGroupByParentAll: () => boolean;
  hasGroupByParent: (parent: ComponentBackboneModel) => boolean;
  hasGroupByChild: (comp: ComponentBackboneModel) => boolean;
  hasGroupByComponentType: () => boolean;
  hasGroupByWorkspace: () => boolean;
  hasGroupByTag: (tag: Tag) => boolean;
}

export type PerspectiveBackboneModel = Backbone.Model & {
  hasContent: () => boolean;
};

export interface Tag extends BasicModel {
  getIdsOfReferencesWithTag: () => ArdoqId[];
  getIdsOfComponentsWithTag: () => ArdoqId[];
  initArrayIfEmpty: (key: 'components' | 'references') => void;
  replaceTagOccurrences: (
    inOldName: string,
    newName: string,
    obj?: BasicModel
  ) => void;
}

export type WorkspaceSurvey = {
  readonly id: ArdoqId;
  readonly name: string;
  readonly live: boolean;
};
export interface WorkspaceStartViewOption {
  label: string;
  val: ViewIds | null;
}
export interface Workspace extends BasicModel {
  getModel: () => Model | undefined;
  getModelId: () => ArdoqId;
  hasWriteAccess: () => boolean;
  getCSS: () => string;
  hasView: (viewId: ViewIds) => boolean;
  addView: (viewId: ViewIds) => void;
  getWorkspaceViews: () => ViewIds[];
  setViews: (views: ViewIds[]) => void;
  removeView: (viewId: ViewIds) => void;
  /**
   * Flag indicating that workspace has loaded components, references, and tags
   * into respective collections.
   */
  aggregateLoaded?: boolean;
  loading: boolean;
  getIntegrations: () => WorkspaceIntegration[];
  getSurveys: () => WorkspaceSurvey[];
  getStartViewOptions: () => WorkspaceStartViewOption[];
  changedDescription?: boolean;
  /** I suspect this will always return an empty object ({}) */
  getStats: () => { pages?: { count: number }; links?: { count: number } };
}

export interface FieldBackboneModel extends BackboneModelPromisedExtension {
  name: () => string;
  getLabel: () => string;
  getRawLabel: () => string;
  getType: () => APIFieldType;
  getAllowedValues: () => string[];
  getModel: () => Model | null;
  getModelId: () => ArdoqId;
  getCalculatedFieldSettings: () => CalculatedFieldSettings | undefined;
  getComponentTypes: () => ArdoqId[];
  getReferenceTypes: () => ArdoqId[];
  isListType: () => boolean;
  isCalculated: () => boolean;
  isLocallyDerived: () => boolean;
  isComponentField: () => boolean;
  isReferenceField: () => boolean;
  removeComponentType: (typeId: string) => void;
  removeReferenceType: (typeId: string) => void;
  format: (
    value: any,
    attributes?: any,
    escapeHTML?: boolean,
    excludeTime?: boolean
  ) => string | number | undefined;
  getRawDescription: () => string;
  getDescription: () => string;
  hasComponentType: (type: string) => boolean;
  hasReferenceType: (type: string) => boolean;
  numberFormatOptions?: string;
  toJSON: () => APIFieldAttributes;
  isMultiselect: () => boolean;
  isFormatHtmlEscaped: () => boolean;
  lastAllowedValues: [defaultValue: string, result: string[]];
}

/**
 * This is a global field definition.
 * It contains the attributes that are shared across fields of the same name
 * across the org.
 */
export interface GlobalFieldAttributes {
  type: APIFieldType;
  label: string;
  name: string;
  defaultValue: any;
  calculatedFieldSettings?: { storedQueryId: string };
  numberFormatOptions?: string;
}

export type FieldEditorOptions = {
  readonly label: string;
  readonly presetTypes: { isComponent: boolean; ids: string[] };
};

// members added to Backbone.Model.prototype in backbone-promised.js
declare class BackboneModelPromisedExtension extends Backbone.Model {
  isClientSideValid: () => boolean;
}

// Utility Types
export type OnClick = (event: MouseEvent<HTMLElement>) => void;
export type OnInput = (event: FormEvent<HTMLInputElement>) => void;

export interface LabeledValue<T = any> {
  value: T;
  label: string;
}

export interface CompElementExtension {
  comp: ComponentBackboneModel;
}

export interface IntegrationElementExtension {
  integration: Reference;
}

export interface WorkspaceElementExtension {
  workspace: Workspace;
}

export interface MaximumPathLengths {
  maxIncomingPathLength?: number;
  maxOutgoingPathLength?: number;
}
export interface SaveAllChangedModels {
  (forced: boolean): void;
}

export type AddTagParams = [
  tagNameOrModel: string | Tag,
  entityToApplyTagTo?: Backbone.Model,
  rootWorkspaceOfTaggedEntity?: Workspace,
];

export interface TagCollection extends Backbone.Collection<Tag> {
  getTagsByModel: (id: string) => Tag[];
  getWorkspaceTags: (workspace?: Workspace) => Tag[];
  addTag: (...params: AddTagParams) => Tag | null;
  /** @param workspace If undefined, uses Context.workspace() */
  getByName: (tagName: string, workspace?: Workspace) => Tag | null;
  saveAllChangedModels: SaveAllChangedModels;
}

export interface BackboneFilterAttributes extends FilterAttributesBase {
  rules?: Filter[];
}

export type FilterFieldAttributeItem = FilterAttribute | { divider: true };
export interface PerspectiveFilters {
  advancedFilters: BackboneFilterAttributes[];
  workspaceFilter: null | Filter;
}

export interface LoadFiltersOptions {
  workspaceFilter?: WorkspaceFilterAttributes;
  advancedFilters?: FilterAttributes[];
  shouldTriggerChangeEvent: boolean;
}
export interface HasShouldTriggerChangeEvent {
  shouldTriggerChangeEvent?: boolean;
}

export declare class Filter extends Backbone.Model {
  getAvailableComparators: () => AttributeComparator[];
  getComparator: () => AttributeComparator;
  isAttributeFilter: () => boolean;
  isComponentTagFilter: () => boolean;
  isReferenceTagFilter: () => boolean;
  isTagFilter: () => boolean;
  setFilterName: (name?: string) => Filter;
  getFilterName: () => string;
  isIncluded: (obj: Backbone.Model) => boolean;
  isFormattingFilter: () => boolean;
  isComponentFormatting: () => boolean;
  isReferenceFormatting: () => boolean;
  encodeURI: () => string;
  isDynamic: () => boolean;
  setRules: (rules: Filter[]) => void;
  isAffected: (model: ComponentBackboneModel | Reference) => boolean;
  shouldModelWithoutFieldBeIncluded: () => boolean;
  _filterName: string;
  areAttributesIncluded: (
    obj: ComponentBackboneModel | Reference,
    filterValue: string,
    filterAttributeNames: string[],
    isNegative: boolean
  ) => boolean;
  isAttributeIncluded: (obj: ComponentBackboneModel | Reference) => boolean;
  isTagIncluded: (obj: ComponentBackboneModel | Reference) => boolean;
  isCompLabelFilter: () => boolean;
  isRefLabelFilter: () => boolean;
  isWorkspaceFilter: () => boolean;
}

export interface ComponentHierarchyInterface {
  getChildren: (
    component: ComponentBackboneModel,
    nodeCollectionView?: CollectionView
  ) => ComponentBackboneModel[];
  clearWorkspaceHierarchy: (workspaceId: ArdoqId) => void;
  clearHierarchyForComponents: (components: ComponentBackboneModel[]) => void;
  clearAllHierarchies: () => void;
}

export type EditorModelExtension<T extends Backbone.Model = Backbone.Model> =
  T & {
    canHandleInvalid?: boolean;
  };

export interface BackboneFormValidatorError {
  type: any;
  message: string;
}

export type BackboneFormValidatorFunction = (
  value: any
) => boolean | BackboneFormValidatorError;

export type ValidatorFunction = (value: any) => null | string;

export interface TokenBackboneModel extends BasicModel {
  isAutogenerated: () => boolean;
}

export interface UserBackboneModel extends BasicModel {
  hasWriteAccess: () => boolean;
  isOrgAdmin: (orglabel?: string) => boolean;
  getEmail: () => string;
  hasAcceptedTerms: () => boolean;
  isPublicUser: () => boolean;
  getOrganization: () => Organization;
}

export type ComponentTypeName = string;
export type ReferenceTypeName = string;
/**
 * Source Component Type Name -> Target Component Type Name -> Reference Type Name
 */
export type LastUsedReferenceTypeTriple = Record<
  ComponentTypeName,
  Record<ComponentTypeName, ReferenceTypeName>
>;

export interface GetPersonalSetting {
  (key: PersonalSetting.LOCALE): string;
  (key: PersonalSetting.DEFAULT): { digest: string };
  (key: PersonalSetting.LAST_VIEWED_DASHBOARD): ArdoqId;
  (key: PersonalSetting.ENFORCE_FILTERS_DIALOG): boolean;
  (key: PersonalSetting.LAST_USED_REFERENCE_TYPE_NAME): string;
  (
    key: PersonalSetting.LAST_USED_REFERENCE_TYPE_NAME_TRIPLES
  ): LastUsedReferenceTypeTriple;
  (key: PersonalSetting.SHOWN_EMAIL_PREFERENCES): boolean;
  (key: PersonalSetting.HIDE_MODEL_PREVIEW): boolean;
  (key: PersonalSetting.HIDE_ON_CLOSE_SCENARIO_WARNING): boolean;
  (key: PersonalSetting.DISABLE_REFERENCE_TIPS): boolean;
  (
    key: PersonalSetting.INVENTORY_RECENT_COLUMN_SELECTION_PER_WORKSPACE_OR_TYPE
  ): InventoryRecentColumnSelectionPerWorkspaceOrTypePersonalSettings;
  (key: PersonalSetting | string): any;
}

export type SetPersonalSetting = (
  key: PersonalSetting | string,
  value: any,
  forceUpdate?: boolean
) => Promise<void>;

export interface CurrentUserBackboneModel extends UserBackboneModel {
  getPersonalSetting: GetPersonalSetting;
  setPersonalSetting: SetPersonalSetting;
  setPersonalSettings: (entries: any[], forceUpdate?: boolean) => Promise<void>;
  isBackofficeUser: () => boolean;
  getOrganizations: () => Organization[];
  whenLoaded: (callback: () => void) => void;
  isTrial: () => boolean;
  toggleFavoriteTemplate: (id: string) => any;
  getFavoriteTemplates: () => string[];
  getRoles: () => PermissionRole[];
  isExpired: () => boolean;
  canUnlock: (model: BasicModel) => boolean;
  organization?: Organization;
  organizations?: unknown[];
  loaded: boolean;
  isArdoqEmployee: () => boolean;
  _savePersonalSettings: () => Promise<void>;
  EVENT_SETTINGS_CHANGED: 'EVENT_SETTINGS_CHANGED';
  toJSON: () => APICurrentUser;
}

interface GetTypeByIdFunction {
  (id: string): ModelType;
  (id: string[]): ModelType[];
}

export interface Model extends Backbone.Model {
  getTypeById: GetTypeByIdFunction;
  getReferenceTypes: () => Record<string | number, APIReferenceType>;
  getComponentTypes: (
    comp: ComponentBackboneModel
  ) => Record<string, ModelType>;
  getReferenceTypeById: (
    id: string | number,
    options?: { skipDeletedTypes?: boolean }
  ) => APIReferenceType | undefined;
  /**
   * Get all component types.
   */
  getAllTypes: () => Record<string, ModelType>;
  isFlexible: () => boolean;
  isTemplate: () => boolean;
  getIcons: (currentValue: string) => ModelIcon[];
  getImageList: (currentValue: string | null) => {
    value: string | null;
    label: string;
    isSelected: boolean;
  }[];
  isNameValid: (name?: string, currentId?: string) => boolean;
  name: () => string;
  getTypeByComponent: (comp: ComponentBackboneModel) => ModelType;
  removeReferenceType: (referenceTypeId: number) => Promise<void>;
  getFirstModelType: (modelList: Record<string, ModelType>) => ModelType;
  getTypeByComponentsParent: (comp: ComponentBackboneModel) => ModelType | null;
  _flattenedModel?: Record<string, ModelType>;
  _parentList?: Record<string, Record<string, ModelType>>;
  fixModel: (children?: ModelType) => void;
  isCommon: () => boolean | undefined;
  defaultReferenceTypes: Record<number, APIReferenceType>;
  canModelTypeHaveChildren: (model: string) => boolean;
  deletedModelType: ModelType;
  getParentType: (typeId: string) => Record<string, ModelType>;
  _internalModelChanged: 'internal:ModelChanged';
  removeChild: (typeId: string) => void;
}

export interface ModelIcon {
  label: string;
  category: string;
  representation: string;
  value: string;
  isSelected: boolean;
}

export interface ShapeSelectListItem {
  label: string;
  value: string;
  category: string;
  dataContent: string;
  isSelected: boolean;
}
export interface FolderBackboneModel extends BasicModel {
  getParent: () => FolderBackboneModel | null | undefined;
}

export interface Attachment extends BasicModel {
  isImage: () => boolean;
  getURI: () => string;
}

export enum NamedStackPage {
  MERGE_FLOW = 'mergeFlow',
  ENTITY_MERGE_FLOW = 'entityMergeFlow',
}
