import { ExcludeFalsy } from '@ardoq/common-helpers';
import { FieldSelectOption } from '../../types';
import { Label } from '@ardoq/forms';
import { Multiselect } from '@ardoq/select';
import { useController, useFormContext } from 'react-hook-form';
import {
  APIFieldAttributes,
  APIDiscoverViewpointAttributes,
  ArdoqId,
  ViewpointViewStyle,
} from '@ardoq/api-types';
import {
  correctDateRangeFieldValues,
  getRelevantFields,
  isComponentField,
} from '../utils';
import { uniqBy } from 'lodash';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';
import { toFieldSelectOption } from '../../utils';
import { isDateFieldType } from '@ardoq/date-time';

type TimelineFieldSelectorProps = {
  defaultVisualization?: ViewpointViewStyle['style'];
  workspaceIds: ArdoqId[];
};

const getPhaseFields = (relevantFields: APIFieldAttributes[]) => {
  return uniqBy(
    [
      ...relevantFields
        .filter(field => isDateRangeFieldType(field.type))
        .map<FieldSelectOption>(toFieldSelectOption),
    ],
    'value'
  );
};

const getMilestoneFields = (relevantFields: APIFieldAttributes[]) => {
  return uniqBy(
    [
      ...relevantFields
        .filter(field => isDateFieldType(field.type))
        .map(toFieldSelectOption),
    ],
    'value'
  );
};

const TimelineFieldSelector = ({
  workspaceIds,
}: TimelineFieldSelectorProps) => {
  const {
    field: { onChange: onFieldsChange },
  } = useController({ name: 'viewStyle.fields' });
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const viewStyle = watch('viewStyle');
  const { fields: relevantFields } =
    dateRangeOperations.mergeDateTimeFieldsToDateRangeFields(
      getRelevantFields(workspaceIds).filter(isComponentField)
    );

  const phaseFieldOptions = getPhaseFields(relevantFields);
  const milestoneOptions = getMilestoneFields(relevantFields);
  const timelineOptions = [...phaseFieldOptions, ...milestoneOptions];

  const selectedOptions =
    viewStyle.style === 'timeline' && viewStyle.fields
      ? viewStyle.fields
          .map(({ name }) => {
            return timelineOptions.find(
              option =>
                option.value === name ||
                dateRangeOperations.toStartDateName(option.value) === name
            );
          })
          .filter(ExcludeFalsy)
      : [];

  const updateTimelineFields = (options: FieldSelectOption[] | null) => {
    if (!options) return;
    const correctedFieldValues = correctDateRangeFieldValues(options);
    onFieldsChange(
      correctedFieldValues.map(opt => ({
        name: opt.name,
      }))
    );
  };

  return (
    <div>
      <Label>Select visualized time-based fields</Label>
      <Multiselect
        options={[
          {
            label: 'Phase Fields',
            options: phaseFieldOptions,
          },
          {
            label: 'Milestone Fields',
            options: milestoneOptions,
          },
        ]}
        value={selectedOptions}
        onChange={arg => {
          updateTimelineFields(arg as FieldSelectOption[]);
        }}
        errorMessage={
          !selectedOptions?.length
            ? 'At least one date or date range field must be selected for this visualization.'
            : undefined
        }
        dataTestId="timeline-field-select"
      />
    </div>
  );
};

export default TimelineFieldSelector;
