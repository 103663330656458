import { IconSize, KnowledgeBaseIcon } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { FlexBox } from '@ardoq/layout';
import { Header2, Link } from '@ardoq/typography';
import { AccessControlMenuSingleOption } from '../accessControlMenuOptions';
import { colors } from '@ardoq/design-tokens';

const PageTitle = ({
  pageOption,
}: {
  pageOption: AccessControlMenuSingleOption;
}) => {
  return (
    <FlexBox align="center" gap="xsmall">
      <WithPopover content={pageOption?.description}>
        <Header2>{pageOption.label || 'Access control'}</Header2>
      </WithPopover>
      {pageOption.knowledgeBaseLink && (
        <Link
          href={pageOption.knowledgeBaseLink}
          target="_blank"
          hideIcon
          underlineOnHover
          noUnderline
          type="secondary"
          color={colors.icon}
        >
          <FlexBox gap="xsmall">
            <KnowledgeBaseIcon
              iconSize={IconSize.MEDIUM}
              data-tooltip-text="Knowledge base"
              color={colors.icon}
            />
          </FlexBox>
        </Link>
      )}
    </FlexBox>
  );
};

export default PageTitle;
