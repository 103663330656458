import Navbar from 'views/navbar/Navbar';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import { FlexBox, Inline, LoadingOverlay, Stack } from '@ardoq/layout';
import { Text } from '@ardoq/typography';
import { connect } from '@ardoq/rxbeach';
import { viewModel$ } from './viewModel$';
import {
  ReportIcon,
  SurveyIcon,
  BroadcastIcon,
  ViewpointIcon,
  PresentationIcon,
  DashboardIcon,
} from 'components/EntityBrowser/atoms';
import { AssetSection } from './AssetSection';
import {
  AssetEntry,
  AssetInfo,
  AssetUsageCommands,
  GroupedAssets,
  SupportedAssetTypes,
} from 'assets/types';
import { LoaderSize } from '@ardoq/ardoq-loader-component';
import { slice, take } from 'lodash/fp';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { Icon, IconName } from '@ardoq/icons';
import { SecondaryButton } from '@ardoq/button';
import { AssetDetails } from './AssetDetails';

type AssetsOverviewComponentProps = {
  title: string;
  assetId: string;
  assetInfo: AssetInfo;
  assetType: SupportedAssetTypes;
  isUsageLoading: boolean;
  groupedAssets: GroupedAssets;
  commands: AssetUsageCommands;
};

const getAssetSection = ({
  name,
  icon,
  onClick,
  assets,
}: {
  name: string;
  icon: React.ReactNode;
  onClick: ({ _id }: AssetEntry) => void;
  assets: AssetEntry[];
}) => {
  return assets.length ? (
    <AssetSection
      key={name}
      name={name}
      assets={assets}
      icon={icon}
      onClick={onClick}
    />
  ) : null;
};

const AssetsOverviewComponent = ({
  title,
  assetId,
  assetType,
  assetInfo,
  groupedAssets,
  commands,
  isUsageLoading,
}: AssetsOverviewComponentProps) => {
  if (isUsageLoading) {
    return <LoadingOverlay loaderSize={LoaderSize.MEDIUM} />;
  }

  const assetsSections = [
    {
      name: 'Reports',
      icon: <ReportIcon />,
      onClick: commands.openReport,
      assets: groupedAssets.reports,
    },
    {
      name: 'Surveys',
      icon: <SurveyIcon />,
      onClick: commands.openSurvey,
      assets: groupedAssets.surveys,
    },
    {
      name: 'Broadcasts',
      icon: <BroadcastIcon />,
      onClick: commands.openBroadcast,
      assets: groupedAssets.broadcasts,
    },
    {
      name: 'Discover Viewpoints',
      icon: <ViewpointIcon />,
      onClick: commands.openViewpoint,
      assets: groupedAssets.viewpoints,
    },
    {
      name: 'Presentations',
      icon: <PresentationIcon />,
      onClick: commands.openPresentation,
      assets: groupedAssets.presentations,
    },
    {
      name: 'Dashboards',
      icon: <DashboardIcon />,
      onClick: commands.openDashboard,
      assets: groupedAssets.dashboards,
    },
  ]
    .map(getAssetSection)
    .filter(ExcludeFalsy);

  return (
    <PageWrapper>
      <Navbar
        primaryContent={
          <Inline gap="xxsmall">
            <Icon iconName={assetInfo.meta.icon} />
            <Text>{assetInfo.meta.name}</Text>
          </Inline>
        }
        secondaryContent={title}
        secondaryButton={
          <SecondaryButton
            onClick={() => {
              commands.openAsset({ id: assetId, type: assetType });
            }}
          >
            {`Open this ${assetType}`}
          </SecondaryButton>
        }
      />
      <PageBody>
        <FlexBox flex={1} gap="medium">
          <Stack flex={1} gap="medium">
            <AssetDetails properties={assetInfo.properties} />
            {assetsSections.length === 0 && (
              <FlexBox gap="xsmall" align="center">
                <Icon iconName={IconName.INFO} />

                <Text>{`This ${assetType} has no linked assets.`}</Text>
              </FlexBox>
            )}
            {take(3, assetsSections).map(section => section)}
          </Stack>
          <Stack flex={1} gap="medium">
            {slice(3, Infinity, assetsSections).map(section => section)}
          </Stack>
        </FlexBox>
      </PageBody>
    </PageWrapper>
  );
};

export const AssetsOverview = connect(AssetsOverviewComponent, viewModel$);
