import { DoqType } from '@ardoq/doq';

export const emptyTableProps = {
  noSurveys: {
    doqType: DoqType.EMPTY_BOX,
    title: "You don't have any surveys yet",
  },
  noMatchFound: {
    doqType: DoqType.EMPTY_BOX,
    title: 'No results found',
    text: 'No surveys match your search criteria.',
  },
};
