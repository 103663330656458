import {
  ArdoqId,
  ReportFilters,
  WidgetDataSourceTypes,
} from '@ardoq/api-types';
import {
  Dashboard,
  DashboardEventLocations,
  getTrackOpenedDashboardPayload,
  ReportFromSelectedWidgetId,
} from '@ardoq/dashboard';
import { getCurrentLocale } from '@ardoq/locale';
import { openReportInReader } from 'components/AppMainSidebar/utils';
import { DashboardModule } from '../types';
import { navigateToDashboardModule } from 'router/navigationActions';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { createDashboardSlide } from 'presentationEditor/presentationChooser/actions';
import { useEffectOnce } from '@ardoq/hooks';
import { trackEvent } from '../../tracking/tracking';
import loadedDashboards$, {
  LoadedDashboardsState,
} from '../DashboardBuilder/loadedDashboards$';
import reports$ from 'streams/reports/reports$';
import DashboardModuleContainer from '../DashboardModuleContainer';
import HeaderButtons from './HeaderButtons';
import { DoqLayout, DoqType, DoqWithSpeechBubble } from '@ardoq/doq';
import { STRINGS } from 'dashboard/consts';
import { dashboardAccessControlInterface } from 'resourcePermissions/accessControlHelpers/dashboards';
import { currentUserAccessControlInterface } from 'resourcePermissions/accessControlHelpers/currentUser';
import { Features, hasFeature } from '@ardoq/features';
import { combineLatest, map } from 'rxjs';
import { PermissionContext } from '@ardoq/access-control';
import { ScenarioSelection } from 'utils/searchBranchSelection';
import { contextPresentation$ } from 'streams/presentations/contextPresentation$';
import { presentationAccessControlOperations } from 'resourcePermissions/accessControlHelpers/presentations';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { Maybe } from '@ardoq/common-helpers';
import { reportAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/report';

const openSurvey = (surveyId: ArdoqId) => {
  window.open(`/surveys/survey/${surveyId}`, '_blank');
};

const onWidgetDrilldown = ({
  datasource: { sourceType, sourceId },
  filters,
}: {
  datasource: {
    sourceType: WidgetDataSourceTypes;
    sourceId: ArdoqId;
  };
  filters?: ReportFilters;
}) => {
  if (sourceType === WidgetDataSourceTypes.REPORT) {
    openReportInReader({
      reportId: sourceId,
      filters,
    });
  } else {
    openSurvey(sourceId);
  }
};

const DashboardReader = ({
  selectedDashboardId,
  loadedDashboards,
  apiError,
  hasNewJourneyFeature,
  permissionContext,
  hasBranchFeature,
  scenarioSelection,
  userCanAddToPresentation,
  hasPresentationOpen,
  getReportNameById,
}: {
  selectedDashboardId: ArdoqId;
  hasNewJourneyFeature: boolean;
  permissionContext: PermissionContext;
  hasBranchFeature: boolean;
  scenarioSelection: ScenarioSelection;
  userCanAddToPresentation: boolean;
  hasPresentationOpen: boolean;
  getReportNameById: ReportFromSelectedWidgetId;
} & LoadedDashboardsState) => {
  useEffectOnce(() => {
    trackEvent(
      ...getTrackOpenedDashboardPayload(
        DashboardEventLocations.ARDOQ,
        selectedDashboardId
      )
    );
  });
  const dashboardData = loadedDashboards[selectedDashboardId];
  return (
    <DashboardModuleContainer
      title={dashboardData?.name ?? ''}
      secondaryText="Dashboard Reader"
      hasNewJourneyFeature={hasNewJourneyFeature}
      dashboardModule={DashboardModule.READER}
      HeaderRightContent={
        <HeaderButtons
          // user can edit a dashboard only when he has an explicit write access to this specific dashboard
          userHasEditAccess={dashboardAccessControlInterface.canEditDashboard(
            permissionContext,
            selectedDashboardId
          )}
          userCanAddToPresentation={userCanAddToPresentation}
          hasPresentationOpen={hasPresentationOpen}
          editDashboard={() => {
            dispatchAction(
              navigateToDashboardModule({
                selectedDashboardId,
                dashboardModule: DashboardModule.BUILDER,
                loadFromCache: true,
              })
            );
          }}
          addDashboardToPresentation={() => {
            dispatchAction(
              createDashboardSlide({
                dashboardId: selectedDashboardId,
                dashboardName: dashboardData!.name,
              })
            );
          }}
          goToOverview={() => {
            dispatchAction(
              navigateToDashboardModule({
                dashboardModule: DashboardModule.OVERVIEW,
              })
            );
          }}
          hasNewJourneyFeature={hasNewJourneyFeature}
          dashboardId={selectedDashboardId}
          hasBranchFeature={hasBranchFeature}
          scenarioSelection={scenarioSelection}
        />
      }
    >
      {dashboardAccessControlInterface.canReadDashboard(
        permissionContext,
        selectedDashboardId
      ) ? (
        <Dashboard
          userLocale={getCurrentLocale()}
          isLoading={!dashboardData && !apiError}
          dashboardData={dashboardData}
          onWidgetDrilldown={onWidgetDrilldown}
          trackEvent={trackEvent}
          apiError={apiError}
          contextDashboardIsUsedIn="reader"
          hasNewJourneyFeature={hasNewJourneyFeature}
          getReportNameById={getReportNameById}
        />
      ) : (
        <div style={{ margin: 'auto' }}>
          <DoqWithSpeechBubble
            doqType={DoqType.MISSING_ORG}
            layout={DoqLayout.PAGE}
            buttonLabel={STRINGS.NO_ACCESS_DOQI.BUTTON_LABEL}
            title={STRINGS.NO_ACCESS_DOQI.TITLE}
            message={STRINGS.NO_ACCESS_DOQI.MESSAGE}
            onButtonClick={() =>
              dispatchAction(
                navigateToDashboardModule({
                  dashboardModule: DashboardModule.OVERVIEW,
                })
              )
            }
          />
        </div>
      )}
    </DashboardModuleContainer>
  );
};

export default connect(
  DashboardReader,
  combineLatest([
    reports$,
    loadedDashboards$,
    contextPresentation$,
    currentUserPermissionContext$,
  ]).pipe(
    map(
      ([
        { byId: reportsById },
        loadedDashboardsData,
        presentation,
        permissionContext,
      ]) => {
        const getReportNameById = (reportId: Maybe<ArdoqId>) => {
          if (
            reportId &&
            reportAccessControlInterface.canAccessReport({
              permissionContext,
              reportId,
            })
          )
            return reportsById[reportId].name;
          return null;
        };
        return {
          ...loadedDashboardsData,
          hasBranchFeature: hasFeature(
            Features.DEMO_ADVANCED_SEARCH_IN_SCENARIOS
          ),
          hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
          userCanAddToPresentation: !presentation
            ? currentUserAccessControlInterface.canCurrentUserWrite()
            : Boolean(
                presentationAccessControlOperations.canEditPresentation(
                  permissionContext,
                  presentation
                )
              ),
          hasPresentationOpen: Boolean(presentation),
          getReportNameById,
        };
      }
    )
  )
);
