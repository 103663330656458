import ScenarioMergeContainer from './ScenarioMergeContainer';
import { connect } from '@ardoq/rxbeach';
import mergeState$ from './mergeState$';
import { map } from 'rxjs/operators';
import {
  getDialogTitle,
  getHasRemainingChanges,
  getKnowledgeLink,
} from './utils';
import PopoverManagerWithContext from 'components/PopoverManagerWithContext/PopoverManagerWithContext';
import { PopoverManagerContext } from 'components/PopoverManagerWithContext/popoverManagerContext$';
import { IconName } from '@ardoq/icons';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import IconClickable from 'atomicComponents/IconClickable';
import { s16, s24 } from '@ardoq/design-tokens';
import ScenarioMergeIntercomTourClickIds from 'components/DiffMergeTable/intercomTourClickIds';
import { combineLatest } from 'rxjs';
import { globalPaneLoader$ } from 'components/GlobalPaneLoader/globalPaneLoader$';
import { ModalHeader, ModalSize, ModalTemplate } from '@ardoq/modal';

interface Props {
  closeHandler: (...args: any) => void;
}

type DialogHeaderWithIconProps = {
  title: string;
  knowledgeLink: KnowledgeBaseLink;
  onCloseButtonClick: (...args: any) => void;
  headerTitleClickId?: string;
  closeButtonClickId?: string;
};

export const DialogHeaderWithIcon = ({
  title,
  knowledgeLink,
  onCloseButtonClick,
}: DialogHeaderWithIconProps) => (
  <ModalHeader
    title={title}
    onCloseButtonClick={onCloseButtonClick}
    headerTitleClickId={ScenarioMergeIntercomTourClickIds.HEADER_TEXT}
    closeButtonClickId={ScenarioMergeIntercomTourClickIds.EXIT_ICON}
  >
    <IconClickable
      iconName={IconName.KNOWLEDGE_BASE}
      style={{ verticalAlign: 'middle' }}
      tooltipText="Knowledge base"
      onClick={() => window.open(knowledgeLink, '_blank noopener')}
      data-click-id={ScenarioMergeIntercomTourClickIds.KNOWLEDGE_BASE_LINK}
      containerStyle={{
        height: s24,
        marginLeft: s16,
      }}
    />
  </ModalHeader>
);

const HeaderTitle$ = combineLatest([mergeState$, globalPaneLoader$]).pipe(
  map(
    ([
      {
        mergeDirection,
        mainStateStep,
        subStateStep,
        diffMetaStates,
        submittedSteps,
      },
      { isLoading },
    ]) => ({
      title: getDialogTitle(
        mergeDirection,
        mainStateStep,
        subStateStep,
        getHasRemainingChanges(diffMetaStates, submittedSteps),
        isLoading
      ),
      knowledgeLink: getKnowledgeLink(mainStateStep, subStateStep),
    })
  )
);

const DialogHeaderConnected = connect(DialogHeaderWithIcon, HeaderTitle$);

const MergeDialog = ({ closeHandler }: Props) => (
  <ModalTemplate modalSize={ModalSize.COVER}>
    <PopoverManagerWithContext context={PopoverManagerContext.MODAL} />
    <DialogHeaderConnected onCloseButtonClick={closeHandler} />
    <ScenarioMergeContainer />
  </ModalTemplate>
);

export default MergeDialog;
