import keyCodes from 'keyCodes';
import { useEffect } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import { subscribeToAction } from 'streams/utils/streamUtils';
import viewInterface from 'streams/views/mainContent/viewInterface';
import { notifyViewRenderDone } from 'tabview/actions';
import { isEditableElement } from 'utils/domUtils';
import { nextSlide, previousSlide, toggleDescription } from './actions';
import { DescriptionContainer } from './atoms';

export const presentationNavigatorHeightOffset = () =>
  document.querySelector<HTMLElement>(DescriptionContainer.toString())
    ?.offsetHeight ?? 0;

const keyCodeActionMap = new Map([
  [keyCodes.rightArrow, () => dispatchAction(nextSlide())],
  [keyCodes.leftArrow, () => dispatchAction(previousSlide())],
  [keyCodes.space, () => dispatchAction(nextSlide())],
  [keyCodes.enter, () => dispatchAction(nextSlide())],
  [keyCodes.letterD, () => dispatchAction(toggleDescription())],
]);

export const usePresentationKeyboardListener = () => {
  useEffect(() => {
    const viewRenderSub = subscribeToAction(notifyViewRenderDone, viewId =>
      keyCodeActionMap.set(keyCodes.letterZ, () => {
        const view = viewInterface.getViewById(viewId);
        view?.autoZoomToFit?.({ isTriggeredByKeyboard: true });
      })
    );
    const listenForKeydown = (event: KeyboardEvent) => {
      // If event target is an input field/editable element, e.g. login, do nothing.
      if (isEditableElement(event.target as Node)) return;
      const action = keyCodeActionMap.get(event.keyCode);
      if (action) {
        action();
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', listenForKeydown);

    return () => {
      viewRenderSub.unsubscribe();
      document.removeEventListener('keydown', listenForKeydown);
    };
  }, []);
};
