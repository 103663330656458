import type { PerspectivesFormattingOptions } from '@ardoq/perspectives-sidebar';
import type { FormattingTabState } from '../types';
type AppliedFormattingState = Omit<
  FormattingTabState,
  | 'formattingOptions'
  | 'isAppliedFormattingIncluded'
  | 'perspectivesRelatedData'
>;
export const getAppliedFormattingState = (
  formattingOptions: PerspectivesFormattingOptions
): AppliedFormattingState => ({
  conditionalFormattingRules:
    formattingOptions.appliedConditionalFormattingRules,
  labelFormatting: formattingOptions.appliedLabelFormatting,
  showReferenceType: formattingOptions.appliedShowReferenceType,
});
