import { GhostButton } from '@ardoq/button';
import { Icon } from '@ardoq/icons';
import {
  TagMetaInfoType,
  TagsMetaInfo,
  ViewpointBuilderNavigationViewModel,
  ViewpointBuilderTabItem,
} from './types';
import { Tag } from '@ardoq/status-ui';
import { ComponentType } from '../components/ComponentType';
import { FullHeightSidebar } from './FullHeightSidebar';
import {
  AdvancedNavigationMenu,
  AdvancedNavigationMenuItem,
} from '@ardoq/snowflakes';
import { viewpointBuilderTracking } from '../viewpointBuilderTracking';
import { ViewpointBuilderNavigationCommands } from './viewpointBuilderNavigationCommands';
import { FlexBox } from '@ardoq/layout';

type ViewpointBuilderNavigationProps = {
  state: ViewpointBuilderNavigationViewModel;
  commands: ViewpointBuilderNavigationCommands;
};

export const ViewpointBuilderNavigation = ({
  state,
  commands,
}: ViewpointBuilderNavigationProps) => {
  const { tabItems, bottomItem, sidebarSubtitle } = state;
  const viewpointBuilderNavigationMenuId = 'viewpoint-builder-navigation-menu'; // for accessibility purposes
  const viewpointBuilderNavigationTitleId =
    'viewpoint-builder-navigation-title'; // for accessibility purposes

  return (
    <FullHeightSidebar
      title={state.sidebarTitle}
      titleId={viewpointBuilderNavigationTitleId}
      subtitle={sidebarSubtitle}
      footerContent={
        <BottomMenuItem
          bottomItem={bottomItem}
          menuId={viewpointBuilderNavigationMenuId}
          onClick={() => {
            viewpointBuilderTracking.trackOpenMetaModel();
            commands.selectTab(bottomItem.tabId);
          }}
        />
      }
    >
      <AdvancedNavigationMenu
        menuId={viewpointBuilderNavigationMenuId}
        ariaLabelledBy={viewpointBuilderNavigationTitleId}
      >
        {tabItems.map((tabItem, index) => {
          const selectedItemIndex = tabItems.findIndex(item => item.isSelected);

          return (
            <AdvancedNavigationMenuItem
              isDisabled={tabItem.isDisabled}
              description={tabItem.description}
              popoverContent={tabItem.popoverContentForDisabledItem}
              key={tabItem.tabId}
              label={tabItem.label}
              iconName={tabItem.icon}
              isSelected={tabItem.isSelected}
              selectedItemIndex={selectedItemIndex}
              currentItemIndex={index}
              rightContent={<Tags tags={tabItem.tags} />}
              onClick={() => {
                commands.selectTab(tabItem.tabId);
              }}
            />
          );
        })}
      </AdvancedNavigationMenu>
    </FullHeightSidebar>
  );
};

type TagsProps = {
  tags: TagsMetaInfo;
};

const Tags = ({ tags }: TagsProps) => {
  if (tags.length === 0) return null;

  return (
    <FlexBox gap="xsmall">
      {tags.map((tag, index) =>
        tag.type === TagMetaInfoType.TAG ? (
          <Tag
            key={index}
            label={tag.count.toString()}
            iconName={tag.iconName}
          />
        ) : (
          <ComponentType
            key={tag.label}
            label={tag.label}
            representationData={tag.representationData}
          />
        )
      )}
    </FlexBox>
  );
};

type BottomMenuItemProps = {
  bottomItem: ViewpointBuilderTabItem;
  onClick: () => void;
  menuId: string;
};
const BottomMenuItem = ({
  bottomItem,
  onClick,
  menuId,
}: BottomMenuItemProps) => {
  return (
    <FlexBox padding="medium">
      <GhostButton
        isFlexible
        isActive={bottomItem.isSelected}
        aria-pressed={bottomItem.isSelected}
        onClick={onClick}
        role="menuitem"
        aria-owns={menuId}
      >
        <Icon iconName={bottomItem.icon} />
        {bottomItem.label}
      </GhostButton>
    </FlexBox>
  );
};
