import React, { ReactNode, useEffect, useRef } from 'react';

interface AutoFocusProps {
  children: ReactNode;
}

export const AutoFocus = ({ children }: AutoFocusProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return <div ref={ref}>{children}</div>;
};
