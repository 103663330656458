import styled from 'styled-components';
import {
  AddFilterCommands,
  ChangeConditionCommands,
  EditFilterCommands,
} from './viewpointBuilderFiltersCommands';
import {
  GraphId,
  TraversalBuilderFiltersPerNode,
  TraversalBuilderViewState,
  ViewpointBuilderFiltersDefinitions,
} from '../types';
import { ViewpointBuilderFilterComponent } from './ViewpointBuilderFilterComponent';
import { GhostButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { Select } from '@ardoq/select';
import { header4 } from '@ardoq/typography';
import { BooleanOperator } from '@ardoq/api-types';
import { Space } from '@ardoq/style-helpers';

type FiltersProps = {
  commands: AddFilterCommands & ChangeConditionCommands & EditFilterCommands;
  filtersWithCondition: TraversalBuilderFiltersPerNode;
  selectedGraphNodeId: string;
  availableFilterGroupedOptions: ViewpointBuilderFiltersDefinitions['componentFilterTypeOptions'];
  asyncLoaders: TraversalBuilderViewState['asyncLoaders'];
};

export const ViewpointBuilderFilters = ({
  commands,
  filtersWithCondition,
  selectedGraphNodeId,
  availableFilterGroupedOptions,
  asyncLoaders,
}: FiltersProps) => {
  return (
    <FlexBox
      flexDirection="column"
      align="stretch"
      gap="medium"
      padding="medium"
    >
      <FilterCondition
        commands={commands}
        condition={filtersWithCondition.condition}
        selectedGraphNodeId={selectedGraphNodeId}
        filtersCount={filtersWithCondition.filters.length}
      />
      {filtersWithCondition.filters.map(filter => (
        <ViewpointBuilderFilterComponent
          availableFilterGroupedOptions={availableFilterGroupedOptions}
          key={filter.nonPersistentId}
          commands={commands}
          filter={filter}
          asyncLoaders={asyncLoaders}
        />
      ))}
      <GhostButton
        isFlexible
        onClick={() => commands.addDefaultFilter(selectedGraphNodeId)}
      >
        <Icon iconName={IconName.ADD_CIRCLE} />
        Add another filter
      </GhostButton>
    </FlexBox>
  );
};

type FilterConditionProps = {
  condition: BooleanOperator;
  filtersCount: number;
  commands: ChangeConditionCommands;
  selectedGraphNodeId: GraphId;
};

const FilterCondition = ({
  condition,
  filtersCount,
  commands,
  selectedGraphNodeId,
}: FilterConditionProps) => {
  if (filtersCount < 2) {
    return null;
  }

  return (
    <Header4Text $align="baseline" $flex={1} $justify="start" $flexWrap>
      <Span>Must match</Span>
      <FixedWidthWrapper>
        <Select
          dataTestId="viewpoint-builder-select-condition"
          value={condition}
          options={[
            {
              label: 'all',
              value: BooleanOperator.AND,
            },
            {
              label: 'any',
              value: BooleanOperator.OR,
            },
          ]}
          aria-label="Workspace"
          onChange={selectOption => {
            if (!selectOption) return;

            commands.changeFilterCondition({
              condition: selectOption.value,
              graphNodeId: selectedGraphNodeId,
            });
          }}
        />
      </FixedWidthWrapper>
      <Span>of the following rules</Span>
    </Header4Text>
  );
};

const Header4Text = styled(Space)`
  ${header4}
`;

const Span = styled.span`
  display: inline-block;
  white-space: normal;
`;

const FixedWidthWrapper = styled.div`
  width: 80px;
`;
