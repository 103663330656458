import {
  APIOrganizationUser,
  OrgAccessLevel,
  Privilege,
  PrivilegeLabel,
} from '@ardoq/api-types';
import { ReactNode, useState } from 'react';
import { IconDecorator } from '@ardoq/decorators';
import { Icon, IconName } from '@ardoq/icons';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import styled from 'styled-components';
import { PaginationBar, PaginationController } from '@ardoq/pagination';
import {
  ExcludeFalsy,
  formatListAsEnglishProse,
  interpolate,
  pickCorrectPlural,
} from '@ardoq/common-helpers';
import { SelectOption } from '@ardoq/select';
import { colors, s8 } from '@ardoq/design-tokens';
import { WithPopover } from '@ardoq/popovers';
import { APIOrganizationUserWithPrivileges } from './ManagePrivilegeDialog';
import { MANAGE_PRIVILEGE_MODAL } from './consts';
import { WarningMessage } from 'privileges/types';
import { Island } from '@ardoq/page-layout';

const Email = styled.span`
  text-decoration: underline;
`;

const StyledIcon = styled(Icon)`
  color: ${colors.yellow50};
  margin-left: ${s8};
`;

const RoleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const formatTableTitle = (selectedUsers: number): string => {
  return `${selectedUsers} ${pickCorrectPlural(
    MANAGE_PRIVILEGE_MODAL.SELECTED_USER,
    selectedUsers
  )}`;
};

const relevantPrivilegesFromRevoked = (
  privilegesToRemove: SelectOption<PrivilegeLabel>[],
  { inheritedPrivileges }: APIOrganizationUserWithPrivileges
): string[] => {
  return privilegesToRemove
    .filter(privilege => inheritedPrivileges.includes(privilege.value))
    .map(privilege => privilege.label);
};

const relevantPrivilegesFromAssigned = (
  privilegesToAssign: Privilege[],
  user: APIOrganizationUserWithPrivileges
): string[] => {
  return privilegesToAssign
    .filter(privilege =>
      (privilege['restricted-roles'] || []).includes(user?.role)
    )
    .map(privilege => privilege.description);
};

const getWarningMessage = (
  privileges: string[],
  stringKey: WarningMessage,
  interpolations: Record<string, string>
): string => {
  const message = interpolate(
    MANAGE_PRIVILEGE_MODAL.WARNING_MESSAGE[stringKey],
    interpolations
  );
  return `${formatListAsEnglishProse(privileges)} ${message}`;
};

const maybeShowWarning = (
  user: APIOrganizationUserWithPrivileges,
  privilegesToRemove?: SelectOption<PrivilegeLabel>[],
  privilegesToAssign?: Privilege[]
): ReactNode | null => {
  if (!privilegesToRemove && !privilegesToAssign) return null;
  const relevantPrivileges = privilegesToRemove
    ? relevantPrivilegesFromRevoked(privilegesToRemove, user)
    : relevantPrivilegesFromAssigned(privilegesToAssign!, user);
  if (relevantPrivileges.length === 0) return null;
  return (
    <WithPopover
      content={getWarningMessage(
        relevantPrivileges,
        privilegesToRemove
          ? WarningMessage.CANNOT_REVOKE
          : WarningMessage.CANNOT_ASSIGN,
        { role: MANAGE_PRIVILEGE_MODAL.ROLES[user.role] }
      )}
    >
      <StyledIcon iconName={IconName.WARNING} />
    </WithPopover>
  );
};

const PaginatedUserTable = ({
  selectedUsers,
  privilegesToRemove,
  privilegesToAssign,
  selectedRoleLabel,
}: {
  selectedUsers: APIOrganizationUserWithPrivileges[] | APIOrganizationUser[];
  privilegesToRemove?: SelectOption<PrivilegeLabel>[];
  privilegesToAssign?: Privilege[];
  selectedRoleLabel?: OrgAccessLevel;
}) => {
  const [perPage, setPerPage] = useState(5);

  return (
    <PaginationController
      dataSource={selectedUsers}
      perPage={perPage}
      render={({
        currentPageDataSource,
        onPageSelect,
        currentPageNumber,
        totalResults,
      }) => (
        <Island
          title={formatTableTitle(selectedUsers.length)}
          footerContent={
            <PaginationBar
              pageSizeOptions={[5, 10, 15, 20]}
              currentPageNumber={currentPageNumber}
              perPage={perPage}
              totalResults={totalResults}
              onChange={({
                currentPageNumber: newPageNumber,
                perPage: newPerPage,
              }) => {
                setPerPage(newPerPage);
                onPageSelect(newPageNumber);
              }}
            />
          }
        >
          <DatasourceTable<
            APIOrganizationUserWithPrivileges | APIOrganizationUser
          >
            dataSource={currentPageDataSource}
            components={newTableTheme}
            columns={[
              { title: MANAGE_PRIVILEGE_MODAL.NAME, dataIndex: 'name' },
              {
                title: MANAGE_PRIVILEGE_MODAL.EMAIL,
                dataIndex: 'email',
                valueRender: (email: string) => {
                  return (
                    <IconDecorator iconName={IconName.MAIL}>
                      <Email>{email}</Email>
                    </IconDecorator>
                  );
                },
              },
              {
                title: MANAGE_PRIVILEGE_MODAL.ROLE,
                dataIndex: 'role',
                valueRender: (
                  roleLabel: OrgAccessLevel,
                  user: APIOrganizationUserWithPrivileges | APIOrganizationUser
                ) => {
                  return (
                    <RoleContainer>
                      {
                        MANAGE_PRIVILEGE_MODAL.ROLES[
                          selectedRoleLabel || roleLabel
                        ]
                      }
                      {maybeShowWarning(
                        user as APIOrganizationUserWithPrivileges,
                        privilegesToRemove,
                        privilegesToAssign
                      )}
                    </RoleContainer>
                  );
                },
              },
            ].filter(ExcludeFalsy)}
          />
        </Island>
      )}
    />
  );
};

export default PaginatedUserTable;
