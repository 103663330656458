import Perspectives from 'collections/perspectives';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs/operators';
import { notifyWorkspaceChanged } from 'streams/context/ContextActions';
import { notifyPerspectivesProcessedChangedWorkspace } from 'streams/filters/FilterActions';
import { getModifiersAsQueryString } from './util';
import loadPerspective from 'collections/helpers/loadPerspective';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { workspaceInterface } from '@ardoq/workspace-interface';

export const loadDefaultPerspectiveOnWorkspaceChanged = routine(
  ofType(notifyWorkspaceChanged),
  extractPayload(),
  withLatestFrom(isViewpointMode$),
  tap(async ([{ workspaceId }, { isViewpointMode }]) => {
    // Disable automatic perspective change in scenario mode
    if (isScenarioMode() || isViewpointMode) {
      dispatchAction(notifyPerspectivesProcessedChangedWorkspace());
      return;
    }
    const hasActivePerspective = !!getModifiersAsQueryString();
    const defaultPerspectiveId = workspaceInterface.getAttribute(
      workspaceId ?? '',
      'defaultPerspective'
    );
    const defaultPerspective =
      defaultPerspectiveId && Perspectives.get(defaultPerspectiveId);

    if (defaultPerspective && !hasActivePerspective) {
      await loadPerspective(defaultPerspective);
    }
    dispatchAction(notifyPerspectivesProcessedChangedWorkspace());
  })
);
