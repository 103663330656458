import {
  ArdoqId,
  BroadcastAggregate,
  ReportRuleOperator,
} from '@ardoq/api-types';

export const reportRuleOperatorLabels: Record<ReportRuleOperator, string> = {
  [ReportRuleOperator.GREATER]: 'greater than',
  [ReportRuleOperator.LESS]: 'less than',
  [ReportRuleOperator.EQUAL]: 'equal to',
  [ReportRuleOperator.NOT_EQUAL]: 'not equal to',
};

export const broadcastAggregatesWithLabel: Record<BroadcastAggregate, string> =
  {
    [BroadcastAggregate.MIN]: 'Minimum',
    [BroadcastAggregate.MAX]: 'Maximum',
    [BroadcastAggregate.AVERAGE]: 'Average (including not set values)',
    [BroadcastAggregate.AVERAGE_SET]: 'Average (only set values)',
    [BroadcastAggregate.SUM]: 'Sum',
    [BroadcastAggregate.COUNT]: 'Count (all)',
    [BroadcastAggregate.COUNT_NOT_SET]: 'Count (only not set values)',
    [BroadcastAggregate.COUNT_SET]: 'Count (only set values)',
    [BroadcastAggregate.MEDIAN]: 'Median',
  };

export const reportRuleOperatorsWithLabel: Record<ReportRuleOperator, string> =
  {
    [ReportRuleOperator.GREATER]: 'Is higher than',
    [ReportRuleOperator.LESS]: 'Is lower than',
    [ReportRuleOperator.EQUAL]: 'Equal to',
    [ReportRuleOperator.NOT_EQUAL]: 'Not equal',
  };

export type ReportRulesSection$State = {
  columnName: ArdoqId | null;
  aggregate: BroadcastAggregate | null;
  predicate: ReportRuleOperator | null;
  threshold: number | null;
};
