import { PerspectivesEditorState } from '../types';
import { FieldGlobalAttributes } from '@ardoq/api-types';
import { StubTag } from '@ardoq/data-model';

const getFieldsInWorkspaces = (
  workspaceModelIds: string[],
  fields: FieldGlobalAttributes[]
) =>
  workspaceModelIds.length
    ? fields.filter(({ model }) => workspaceModelIds.includes(model))
    : fields;

const getEntitiesFilteredByWorkspaceFilter = <
  T extends FieldGlobalAttributes | StubTag,
>(
  allItems: T[],
  availableItems: T[]
) =>
  allItems.reduce((acc, field) => {
    if (availableItems.find(({ name }) => field.name === name)) {
      return acc;
    }

    return {
      ...acc,
      [field.name]: field,
    };
  }, {});

const isWorkspaceFilterNotApplicable = (state: PerspectivesEditorState) => {
  const {
    filtersTabPlainDataParams: { workspaceFilterIds },
  } = state;

  return !workspaceFilterIds.length;
};

export const getStateAffectedByWorkspaceFilter = (
  state: PerspectivesEditorState
): PerspectivesEditorState => {
  const {
    filtersTabPlainDataParams: { workspaceFilterIds },
    perspectivesRelatedData: {
      relatedWorkspaces,
      relatedTags,
      connectedWorkspaceComponentFields,
      connectedWorkspaceReferenceFields,
    },
  } = state;

  if (isWorkspaceFilterNotApplicable(state)) {
    return {
      ...state,
      availableRelatedData: {
        availableWorkspaceTags: relatedTags,
        availableWorkspaceComponentFields: connectedWorkspaceComponentFields,
        availableWorkspaceReferenceFields: connectedWorkspaceReferenceFields,
        tagsFilteredByWorkspaceFilter: {},
        componentFieldsFilteredByWorkspaceFilter: {},
        referenceFieldsFilteredByWorkspaceFilter: {},
      },
    };
  }

  const workspaceModelIds = relatedWorkspaces
    .filter(({ _id }) => workspaceFilterIds.includes(_id))
    .map(({ componentModel }) => componentModel);

  const availableWorkspaceComponentFields = getFieldsInWorkspaces(
    workspaceModelIds,
    connectedWorkspaceComponentFields
  );

  const availableWorkspaceReferenceFields = getFieldsInWorkspaces(
    workspaceModelIds,
    connectedWorkspaceReferenceFields
  );

  const componentFieldsFilteredByWorkspaceFilter =
    getEntitiesFilteredByWorkspaceFilter(
      connectedWorkspaceComponentFields,
      availableWorkspaceComponentFields
    );

  const referenceFieldsFilteredByWorkspaceFilter =
    getEntitiesFilteredByWorkspaceFilter(
      connectedWorkspaceReferenceFields,
      availableWorkspaceReferenceFields
    );

  const availableWorkspaceTags = relatedTags.filter(({ rootWorkspace }) =>
    workspaceFilterIds.includes(rootWorkspace)
  );

  const tagsFilteredByWorkspaceFilter = getEntitiesFilteredByWorkspaceFilter(
    relatedTags,
    availableWorkspaceTags
  );

  return {
    ...state,
    availableRelatedData: {
      availableWorkspaceTags,
      availableWorkspaceComponentFields,
      availableWorkspaceReferenceFields,
      tagsFilteredByWorkspaceFilter,
      componentFieldsFilteredByWorkspaceFilter,
      referenceFieldsFilteredByWorkspaceFilter,
    },
  };
};
