import { Space } from '@ardoq/style-helpers';
import { AdvancedOptions } from './atoms';
import { RadioGroup, Checkbox } from '@ardoq/forms';
import { Select, SelectOption } from '@ardoq/select';
import { IconName } from '@ardoq/icons';
import { TabsGroup, TabsTheme, TabButton } from '@ardoq/tabs';
import { Chip, ChipsList } from '@ardoq/status-ui';
import { QuickSearchOptions, SearchBy } from '@ardoq/api-types';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { quickSearchCommands } from './commands';
import quickSearch$ from './quickSearch$';
import { connect } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { Header5 } from '@ardoq/typography';

interface OptionItemProps {
  value?: boolean;
  onChange: (value: boolean) => void;
  label: string;
}

const OptionItem = ({ value, onChange, label }: OptionItemProps) => (
  <Checkbox isChecked={!!value} onChange={() => onChange(!value)}>
    {label}
  </Checkbox>
);

interface OptionsProps {
  query: string;
  options: QuickSearchOptions;
  customFields: SelectOption<string>[];
  onFieldInputMenuClose?: () => void;
  disableResultFilters?: boolean;
  hasNewJourneyFeature?: boolean;
}

const Options = ({
  query,
  options,
  customFields,
  onFieldInputMenuClose,
  disableResultFilters,
  hasNewJourneyFeature,
}: OptionsProps) => {
  return hasNewJourneyFeature ? (
    <AdvancedOptions $hasNewJourneyFeature>
      <FlexBox padding="small">
        <TabsGroup
          activeTabId={options.searchBy}
          onTabChange={tabId =>
            quickSearchCommands.setSearchParams({
              query,
              options: { searchBy: tabId as SearchBy },
            })
          }
          theme={TabsTheme.COLOR_FILL}
        >
          <TabButton
            label="By name or description"
            tabId={SearchBy.NAME_DESCRIPTION}
            iconName={IconName.DESCRIPTION}
          />
          <TabButton
            label="By component field"
            tabId={SearchBy.FIELD_VALUE}
            iconName={IconName.FIELD}
          />
        </TabsGroup>
      </FlexBox>
      {!disableResultFilters &&
        options.searchBy === SearchBy.NAME_DESCRIPTION && (
          <FlexBox
            width="full"
            paddingX="small"
            paddingTop="xxsmall"
            paddingBottom="small"
          >
            <ChipsList>
              <Chip
                onClick={() =>
                  quickSearchCommands.setSearchParams({
                    query,
                    options: { isWorkspace: !options.isWorkspace },
                  })
                }
                isActive={options.isWorkspace}
                iconName={IconName.WORKSPACE}
              >
                Workspace
              </Chip>
              <Chip
                onClick={() =>
                  quickSearchCommands.setSearchParams({
                    query,
                    options: { isComponent: !options.isComponent },
                  })
                }
                isActive={options.isComponent}
                iconName={IconName.COMPONENT}
              >
                Component
              </Chip>
              <Chip
                onClick={() =>
                  quickSearchCommands.setSearchParams({
                    query,
                    options: { isReference: !options.isReference },
                  })
                }
                isActive={options.isReference}
                iconName={IconName.REFERENCE}
              >
                Reference
              </Chip>
            </ChipsList>
          </FlexBox>
        )}
      {options.searchBy === SearchBy.FIELD_VALUE && (
        <Box paddingX="small" paddingBottom="small">
          <Select
            placeholder="Select a field to search in"
            value={options.customFieldName}
            options={customFields}
            onMenuClose={onFieldInputMenuClose}
            onValueChange={customFieldName => {
              quickSearchCommands.setSearchParams({
                query,
                options: {
                  customFieldName: customFieldName ?? '',
                },
              });
            }}
          />
        </Box>
      )}
    </AdvancedOptions>
  ) : (
    <AdvancedOptions>
      <Stack gap="small">
        <Header5>Search by</Header5>
        <RadioGroup
          options={[
            {
              value: SearchBy.NAME_DESCRIPTION,
              label: 'Name or description',
            },
            {
              value: SearchBy.FIELD_VALUE,
              label: 'Component field value',
            },
          ]}
          value={options.searchBy}
          onValueChange={searchBy => {
            quickSearchCommands.setSearchParams({
              query,
              options: { searchBy: searchBy as SearchBy },
            });
          }}
        />
      </Stack>
      <Space $align="center" $flexWrap $gap="s8">
        <Header5>Search</Header5>
        <OptionItem
          value={options.useOpenWorkspaces}
          onChange={useOpenWorkspaces =>
            quickSearchCommands.setSearchParams({
              query,
              options: { useOpenWorkspaces },
            })
          }
          label="Limit to open workspaces"
        />
      </Space>
      {!disableResultFilters &&
        options.searchBy === SearchBy.NAME_DESCRIPTION && (
          <Space $align="center" $flexWrap $gap="s8">
            <Header5>Results</Header5>
            <OptionItem
              value={options.isWorkspace}
              onChange={isWorkspace =>
                quickSearchCommands.setSearchParams({
                  query,
                  options: { isWorkspace },
                })
              }
              label="Workspace"
            />
            <OptionItem
              value={options.isComponent}
              onChange={isComponent =>
                quickSearchCommands.setSearchParams({
                  query,
                  options: { isComponent },
                })
              }
              label="Component"
            />
            <OptionItem
              value={options.isReference}
              onChange={isReference =>
                quickSearchCommands.setSearchParams({
                  query,
                  options: { isReference },
                })
              }
              label="Reference"
            />
          </Space>
        )}
      {options.searchBy === SearchBy.FIELD_VALUE && (
        <Stack gap="small">
          <Header5>Filter fields to search in</Header5>
          <Select
            placeholder={'Select a field'}
            value={options.customFieldName}
            options={customFields}
            onMenuClose={onFieldInputMenuClose}
            onValueChange={customFieldName =>
              quickSearchCommands.setSearchParams({
                query,
                options: {
                  customFieldName: customFieldName ?? '',
                },
              })
            }
          />
        </Stack>
      )}
    </AdvancedOptions>
  );
};

export default connect(
  Options,
  quickSearch$.pipe(
    map(({ options, query }) => {
      return {
        query,
        options,
      };
    })
  )
);
