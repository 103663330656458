import { filter, map, shareReplay } from 'rxjs/operators';
import {
  persistentReducedStream,
  streamReducer,
  toPersistentStream,
} from '@ardoq/rxbeach';
import { websocket$ } from 'sync/websocket$';
import { APIViewpointAttributes, ResourceType } from '@ardoq/api-types';
import { ArdoqEvent, EventType } from 'sync/types';
import { ardoqEventOperations } from 'sync/ardoqEventOperations';
import { logWarn } from '@ardoq/logging';
import { returnPayload } from '@ardoq/common-helpers';
import { TraversalListState } from './traversals/types';
import { emptyCollectionStream, toCollectionStream } from './utils/streamUtils';
import { traversalNamespace } from './traversals/actions';
import { fetchAllReducer } from './crud/reducers';

const handleDeletedTraversal = (state: TraversalListState, id: string) => {
  return state.filter(traversal => traversal._id !== id);
};

const handleUpdatedTraversal = (
  state: TraversalListState,
  updatedTraversal: APIViewpointAttributes
) => {
  return state.map(traversal =>
    traversal._id === updatedTraversal._id ? updatedTraversal : traversal
  );
};

const handleAddedTraversal = (
  state: TraversalListState,
  newTraversal: APIViewpointAttributes
) => {
  return [...state, newTraversal];
};

const handleWebSocketEvents = (
  state: TraversalListState,
  event: ArdoqEvent<APIViewpointAttributes>
) => {
  const eventType = event['event-type'];
  switch (eventType) {
    case EventType.DELETE:
      return handleDeletedTraversal(state, event.data._id);
    case EventType.UPDATE:
      return handleUpdatedTraversal(state, event.data);
    case EventType.CREATE:
      return handleAddedTraversal(state, event.data);
    default:
      logWarn(new Error('unknown event-type'), eventType);
  }
  return state;
};

const isTraversal = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<APIViewpointAttributes> => {
  return ardoqEventOperations.isOfResourceType(event, ResourceType.TRAVERSAL);
};

const traversalsList$ = persistentReducedStream<TraversalListState>(
  'traversals$',
  [],
  [
    fetchAllReducer(returnPayload<TraversalListState>),
    streamReducer(websocket$.pipe(filter(isTraversal)), handleWebSocketEvents),
  ],
  { namespace: traversalNamespace }
);

export const traversalState$ = traversalsList$.pipe(
  map(toCollectionStream),
  shareReplay({ bufferSize: 1, refCount: true })
);

/**
 * @deprecated Use non-global traversalState$ instead
 */
export default toPersistentStream(
  'globalTraversalState$',
  traversalState$,
  emptyCollectionStream()
);
