import {
  APIModelAttributes,
  ArdoqId,
  NewTemplate,
  APITemplateAttributes,
} from '@ardoq/api-types';
import {
  actionCreator,
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Organization } from '@ardoq/api-types';
import { models$ } from 'streams/models/models$';
import { confirm } from '@ardoq/modal';
import { deleteModel, fetchAllModels } from 'streams/models/actions';
import { templateApi } from '@ardoq/api';
import { modelInterface } from 'modelInterface/models/modelInterface';

export interface EditTemplatesState {
  templates: APIModelAttributes[];
  organization: Organization | null;
}

interface DeleteTemplatePayload {
  id: ArdoqId;
}

export const createTemplate = actionCreator<NewTemplate>(
  '[Template Stream] CREATE_TEMPLATE'
);

export const updateTemplate = actionCreator<APITemplateAttributes>(
  '[Template Stream] UPDATE_TEMPLATE'
);

export const deleteTemplate = actionCreator<DeleteTemplatePayload>(
  '[template] DELETE_TEMPLATE'
);

const defaultTemplate = {
  root: undefined,
  blankTemplate: false,
  defaultViews: [],
  startView: null,
};

const handleCreateTemplate = routine(
  ofType(createTemplate),
  extractPayload(),
  switchMap(template =>
    templateApi.create({
      ...defaultTemplate,
      ...template,
      blankTemplate: false,
    })
  ),
  tap(() => dispatchAction(fetchAllModels()))
);

const handleUpdateTemplate = routine(
  ofType(updateTemplate),
  extractPayload(),
  switchMap(template =>
    templateApi.update({
      ...modelInterface.get(template._id),
      ...template,
    })
  ),
  tap(() => dispatchAction(fetchAllModels()))
);

const handleDeleteTemplate = routine(
  ofType(deleteTemplate),
  extractPayload(),
  withLatestFrom(models$),
  tap(async ([{ id }, { byId: modelsById }]) => {
    const model = modelsById[id]!;
    const ok = await confirm({
      title: 'Confirm',
      text: `This will delete ${model.name}. Are you sure?`,
    });
    if (ok) {
      dispatchAction(deleteModel({ id }));
    }
  })
);

export default collectRoutines(
  handleCreateTemplate,
  handleUpdateTemplate,
  handleDeleteTemplate
);
