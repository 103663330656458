import { actionCreator } from '@ardoq/rxbeach';

export const openExistingEmailAudienceDialog = actionCreator<string[]>(
  '[emailAudienceDialog] OPEN_EXISTING_EMAIL_AUDIENCE_DIALOG'
);

export const openNewEmailAudienceDialog = actionCreator(
  '[emailAudienceDialog] OPEN_NEW_EMAIL_AUDIENCE_DIALOG'
);

export const updateEmails = actionCreator<string>(
  '[emailAudienceDialog] UPDATE_EMAILS'
);
