import {
  dispatchAction,
  routine,
  action$,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  combineLatest,
  EMPTY,
  filter,
  firstValueFrom,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { mergeMap, toArray } from 'rxjs/operators';
import { userEvent } from 'sync/actions';
import { SelectionResponse } from '@ardoq/api-types/integrations';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import {
  ensureCorrectAsyncRequest,
  getAsyncRequestId,
  parseAsyncResponsePayload,
} from 'integrations/common/async/utils';
import { AsyncOperations } from 'integrations/common/async/constants';
import { retriableRequest } from 'integrations/common/utils/api';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import {
  fetchTableFields as fetchTableFieldsAction,
  resetTableFields,
  setMultipleTablesFields,
} from '../tableFields/actions';
import {
  applySourceConfig,
  unselectUnavailableTables,
  resetSelection,
  selectTable,
  setSelectionAsyncStatus,
  applyTables,
  fetchSelectedTablesFields,
  unselectUnavailableFields,
  selectField,
  applyUnavailableTables,
  fetchSelectionSuccess as fetchServiceNowSelectionSuccess,
  setFetchSelectionError,
} from './actions';
import {
  fetchServiceNowTables,
  fetchServiceNowTablesSuccess,
  resetState as resetServiceNowTablesState,
} from '../tables/actions';
import { selectionState$ } from './selectionState$';
import {
  connections$,
  getConnectionsStream,
} from 'integrations/common/streams/connections/connections$';
import {
  resetSelectedConnectionIds,
  selectConnection,
  updateConnectionStatuses,
  updateSingleSelectedConnectionId,
} from 'integrations/common/streams/connections/actions';
import {
  prepareSelectionRequest,
  removeUnavailableFieldsFromSelectedTables,
} from './utils';
import { tablesFields$ } from '../tableFields/tableFields$';
import { serviceNowTables$ } from '../tables/tables$';
import fp from 'lodash/fp';
import {
  fetchSelection,
  fetchSelectionError,
  fetchSelectionSuccess,
  loadSelection,
} from 'integrations/common/streams/selectionState/actions';
import { servicenowIntegrationApi } from '@ardoq/api';

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  filter(integrationId => integrationId === 'servicenow-v3'),
  tap(() => {
    dispatchAction(resetSelection());
  })
);

const handleSelectTable = routine(
  ofType(selectTable),
  extractPayload(),
  tap(tableId => {
    dispatchAction(fetchTableFieldsAction(tableId));
  })
);

const handleSelectFields = routine(
  ofType(selectField),
  extractPayload(),
  tap(() => {
    dispatchAction(
      trackIntegrationEvent({
        integrationId: 'servicenow-v3',
        name: 'SELECTED_SOURCE_FIELD',
      })
    );
  })
);
// Todo: Move this to general connections stream
const handleSelectConnection = routine(
  ofType(selectConnection),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'servicenow-v3'),
  withLatestFrom(getConnectionsStream('servicenow-v3')),
  switchMap(
    ([
      connectionId,
      {
        connections,
        statuses: {
          list: { status },
        },
      },
    ]) => {
      // waiting for available connections to be fetched
      return combineLatest([
        of(connectionId),
        status === 'SUCCESS'
          ? of(connections)
          : firstValueFrom(
              action$.pipe(
                ofType(updateConnectionStatuses),
                extractPayload(),
                filter(
                  ({ integrationId, statuses }) =>
                    integrationId === 'servicenow-v3' &&
                    (statuses.list?.status === 'SUCCESS' ||
                      statuses.list?.status === 'FAILURE')
                ),
                withLatestFrom(getConnectionsStream('servicenow-v3')),
                map(([_, { connections = [] }]) => connections)
              )
            ),
      ]);
    }
  ),
  tap(([payload, connections]) => {
    dispatchAction(resetServiceNowTablesState());
    dispatchAction(resetTableFields());
    const sourceConnection = connections.find(
      ({ _id }) => _id === payload.selectedConnectionId
    );

    if (!sourceConnection) {
      // TODO: notify customer that the connection from configuration no longer exists
      dispatchAction(
        resetSelectedConnectionIds({ integrationId: 'servicenow-v3' })
      );
      return;
    }
    dispatchAction(
      updateSingleSelectedConnectionId({
        integrationId: 'servicenow-v3',
        selectedConnectionId: sourceConnection._id,
      })
    );
    dispatchAction(
      fetchServiceNowTables({
        integrationId: 'servicenow-v3',
        connectionId: sourceConnection._id,
      })
    );
    dispatchAction(
      trackIntegrationEvent({
        integrationId: 'servicenow-v3',
        name: 'SELECTED_CONNECTION',
      })
    );
  })
);

// when tables are successfully fetched we need to filter out current table selection according to available tables
const handleFetchTablesSuccess = routine(
  ofType(fetchServiceNowTablesSuccess),
  tap(() => {
    dispatchAction(unselectUnavailableTables());
    dispatchAction(fetchSelectedTablesFields());
  })
);

const handleUnselectUnavailableTables = routine(
  ofType(unselectUnavailableTables),
  withLatestFrom(selectionState$, serviceNowTables$),
  tap(([_, selectionState, { tables }]) => {
    const allTables = {
      ...selectionState.tables,
      ...selectionState.unavailableTables,
    };

    const availableTableIds = tables.map(t => t.name);
    const availableTables = fp.flow(
      fp.toPairs,
      fp.filter(([id, _]) => availableTableIds.includes(id)),
      fp.fromPairs
    )(allTables);
    const unavailableTables = fp.flow(
      fp.toPairs,
      fp.reject(([id, _]) => availableTableIds.includes(id)),
      fp.fromPairs
    )(allTables);
    dispatchAction(applyUnavailableTables(unavailableTables));
    dispatchAction(applyTables(availableTables));
  })
);

const handleFetchSelectedTablesFields = routine(
  ofType(fetchSelectedTablesFields),
  withLatestFrom(selectionState$, getConnectionsStream('servicenow-v3')),
  switchMap(([_, selectionState, connectionsState]) => {
    const connectionId = connectionsState.selectedConnectionIds[0];
    if (!fp.isNil(connectionId)) {
      const selectedTableIds = Object.keys(selectionState.tables);
      return from(selectedTableIds).pipe(
        mergeMap(
          tableId =>
            forkJoin({
              tableId: of(tableId),
              fields: retriableRequest(
                () =>
                  servicenowIntegrationApi.fetchTableFields({
                    tableId,
                    connectionId,
                  }),
                'Unable to fetch servicenow table fields'
              ),
            }),
          6
        ),
        toArray()
      );
    }
    return EMPTY;
  }),
  tap(tablesFields => {
    dispatchAction(setMultipleTablesFields(tablesFields));
    dispatchAction(unselectUnavailableTables());
    dispatchAction(unselectUnavailableFields());
  })
);

const handleUnselectUnavailableFields = routine(
  ofType(unselectUnavailableFields),
  withLatestFrom(selectionState$, tablesFields$),
  tap(([_, selectionState, tablesFields]) => {
    dispatchAction(
      applyTables(
        removeUnavailableFieldsFromSelectedTables(
          selectionState.tables,
          tablesFields
        )
      )
    );
  })
);

const handleApplySourceConfig = routine(
  ofType(applySourceConfig),
  extractPayload(),
  withLatestFrom(connections$),
  tap(([sourceConfig, connectionsState]) => {
    const selectedTables = fp.reduce(
      (tables, t) => ({
        ...tables,
        [t.name]: {
          fields: fp.reduce(
            (table, { name }) => ({ ...table, [name]: true }),
            {},
            t.fields
          ),
          queryFilter: t.sysparmQuery,
        },
      }),
      {},
      sourceConfig.tables
    );
    // Resetting the unavailable tables, since the sourceConfig has changed so we need to recheck the availability
    dispatchAction(applyUnavailableTables({}));
    dispatchAction(applyTables(selectedTables));
    if (
      connectionsState['servicenow-v3'].selectedConnectionIds[0] !==
      sourceConfig.connectionId
    ) {
      // Todo: Refactor this further to prevent the need for this
      const sourceConnection = connectionsState[
        'servicenow-v3'
      ].connections.find(c => c._id === sourceConfig.connectionId);
      if (sourceConnection) {
        dispatchAction(
          selectConnection({
            selectedConnectionId: sourceConnection._id,
            integrationId: 'servicenow-v3',
          })
        );
      }
    } else {
      dispatchAction(unselectUnavailableTables());
      dispatchAction(fetchSelectedTablesFields());
    }
  })
);

const handleFetchSelection = routine(
  ofType(loadSelection),
  extractPayload(),
  filter(integrationId => integrationId === 'servicenow-v3'),
  withLatestFrom(
    selectionState$,
    activeIntegrations$,
    getConnectionsStream('servicenow-v3'),
    serviceNowTables$,
    tablesFields$
  ),
  map(
    ([
      integrationId,
      selectionState,
      activeIntegrations,
      connectionsState,
      serviceNowTables,
      tablesFields,
    ]) => {
      return {
        integrationId,
        selectionState,
        activeIntegrations,
        connectionsState,
        serviceNowTables,
        tablesFields,
        requestId: getAsyncRequestId({
          integrationId,
          operation: AsyncOperations.SERVICENOW_SELECTION,
          funnelId: activeIntegrations[integrationId].trackingFunnelId,
        }),
      };
    }
  ),
  tap(
    ({
      selectionState,
      connectionsState: { connections, selectedConnectionIds },
      serviceNowTables,
      tablesFields,
      requestId,
    }) => {
      if (!selectedConnectionIds.length || !connections) {
        return EMPTY;
      }

      const connection = connections.find(
        ({ _id }) => _id === selectedConnectionIds[0]
      );

      if (!connection) {
        return EMPTY;
      }

      const selectionRequestPayload = prepareSelectionRequest({
        tables: selectionState.tables,
        connectionId: selectedConnectionIds[0],
        name: connection.name,
        serviceNowTables: serviceNowTables.tables,
        tablesFields,
      });

      dispatchAction(setSelectionAsyncStatus('LOADING'));
      dispatchAction(setFetchSelectionError(null));
      dispatchAction(
        trackIntegrationEvent({
          integrationId: 'servicenow-v3',
          name: 'TRIGGERED_SOURCE_DATA_FETCH',
          metadata: {
            numberOfTables: selectionRequestPayload.tables.length,
          },
        })
      );
      dispatchAction(
        fetchSelection({
          integrationId: 'servicenow-v3',
          payload: selectionRequestPayload,
          requestId,
        })
      );
    }
  )
);

const handleFetchSelectionResponse = routine(
  ofType(userEvent),
  extractPayload(),
  map(parseAsyncResponsePayload<SelectionResponse>),
  withLatestFrom(activeIntegrations$),
  ensureCorrectAsyncRequest(AsyncOperations.SERVICENOW_SELECTION),
  tap(([response]) => {
    if (!response) return;

    if (response.status !== 'success') {
      dispatchAction(setSelectionAsyncStatus('FAILURE'));
      dispatchAction(setFetchSelectionError(response.data.message ?? null));
      return;
    }

    dispatchAction(
      fetchSelectionSuccess({
        response: response.data,
        integrationId: 'servicenow-v3',
      })
    );
  })
);

const handleFetchSelectionSuccess = routine(
  ofType(fetchSelectionSuccess),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'servicenow-v3'),
  tap(({ response }) => {
    dispatchAction(fetchServiceNowSelectionSuccess(response));
  })
);

const handleFetchSelectionError = routine(
  ofType(fetchSelectionError),
  extractPayload(),
  filter(({ integrationId }) => integrationId === 'servicenow-v3'),
  tap(() => {
    dispatchAction(setSelectionAsyncStatus('FAILURE'));
  })
);

export default [
  handleSelectTable,
  handleSelectFields,
  handleSelectConnection,
  handleFetchSelection,
  handleFetchSelectionResponse,
  handleApplySourceConfig,
  handleResetIntegration,
  handleUnselectUnavailableTables,
  handleFetchTablesSuccess,
  handleFetchSelectedTablesFields,
  handleUnselectUnavailableFields,
  handleFetchSelectionSuccess,
  handleFetchSelectionError,
];
