import ComponentSubsetSelector from './ComponentSubsetSelector';
import { getReferenceData } from './referenceUtil';
import { SurveyBuilderLocation, SurveyValidation } from './types';
import {
  Checkbox,
  FieldsWrapper,
  GroupFieldLayout,
  RadioGroup,
  TextInput,
} from '@ardoq/forms';
import { Select } from '@ardoq/select';
import { shouldDisplayErrorMessageForField } from 'surveyAdmin/surveyUtil';
import surveyEditor$ from './SurveyEditor/streams/surveyEditor$';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { setSurveyFieldHasBeenInteractedWith } from './SurveyEditor/streams/actions';
import {
  PermissionAccessLevel,
  ReferenceFilterProperties,
  SurveyFilterSetupAttributes,
  SurveyResultFilteringType,
} from '@ardoq/api-types';
import {
  ProtectedSelectionPlaceholder,
  getMaybeProtectSelectedOption,
} from './maybeProtectSelectedOption';
import getSortOptions, { getFieldOptions } from 'utils/getSortOptions';
import { trackEvent } from 'tracking/tracking';
import { SurveyErrorName, SurveyWarningName, WARNING_MESSAGES } from './consts';
import { validationHelpers } from './surveyValidation';
import { Box } from '@ardoq/layout';
import { emptyFilterSetup } from './surveyUtil';
import { SurveyEditorState } from './SurveyEditor/streams/types';

type FilterSetupProps = {
  surveyWorkspaceId: string | null;
  surveyFilterSetup: SurveyFilterSetupAttributes | null;
  surveyValidation?: SurveyValidation;
  setFilterSetupAttributes: (
    updatedFilterSetup: SurveyFilterSetupAttributes,
    shouldShowReferenceResultFilteringFieldErrors: boolean,
    fieldsHaveBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith']
  ) => void;
  shouldShowWorkspaceFieldErrorIfPresent: boolean;
  shouldShowComponentTypeErrorIfPresent: boolean;
  shouldShowReferenceDirectionFieldErrorIfPresent: boolean;
  shouldShowReferenceTypeFieldErrorIfPresent: boolean;
  shouldShowReferenceResultFilteringFieldErrors: boolean;
  fieldHasBeenInteractedWith: SurveyEditorState['fieldHasBeenInteractedWith'];
};

const FilterSetup = ({
  surveyWorkspaceId,
  surveyFilterSetup,
  surveyValidation,
  setFilterSetupAttributes,
  shouldShowWorkspaceFieldErrorIfPresent,
  shouldShowComponentTypeErrorIfPresent,
  shouldShowReferenceDirectionFieldErrorIfPresent,
  shouldShowReferenceTypeFieldErrorIfPresent,
  shouldShowReferenceResultFilteringFieldErrors,
  fieldHasBeenInteractedWith,
}: FilterSetupProps) => {
  const filterSetup = surveyFilterSetup ?? emptyFilterSetup;

  const setProperty = (
    attribute: keyof ReferenceFilterProperties,
    value: ReferenceFilterProperties[keyof ReferenceFilterProperties]
  ) => {
    const isNewWorkspaceId =
      attribute === 'workspaceId' &&
      value !== filterSetup.properties?.workspaceId;

    const componentTypeName = isNewWorkspaceId
      ? null
      : filterSetup.properties?.componentTypeName;

    const isNewDirection =
      attribute === 'outgoing' && value !== filterSetup.properties?.outgoing;

    const referenceTypeName = isNewDirection
      ? ''
      : filterSetup.properties?.referenceTypeName;

    setFilterSetupAttributes(
      {
        ...filterSetup,
        properties: {
          ...filterSetup?.properties,
          referenceTypeName,
          componentTypeName,
          [attribute]: value,
        },
      },
      shouldShowReferenceResultFilteringFieldErrors,
      fieldHasBeenInteractedWith
    );
  };

  const { referenceTypeOptions } = getReferenceData({
    ...(filterSetup.properties ?? {}),
    surveyWorkspaceId,
  });

  const referenceDirectionOptions = [
    { label: 'Incoming', value: false },
    { label: 'Outgoing', value: true },
  ];

  const maybeProtectSelectedOption = getMaybeProtectSelectedOption(
    filterSetup.properties?.outgoing
      ? surveyWorkspaceId
      : filterSetup.properties?.workspaceId
  );
  const fieldOptions = surveyWorkspaceId
    ? getFieldOptions(surveyWorkspaceId)
    : [];
  const sortableFields = getSortOptions({
    fieldOptions,
  });

  return (
    <>
      <FieldsWrapper>
        <Checkbox
          label="Filtering"
          isChecked={Boolean(filterSetup.enabled)}
          onChange={() => {
            setFilterSetupAttributes(
              {
                ...filterSetup,
                enabled: !filterSetup.enabled,
              },
              shouldShowReferenceResultFilteringFieldErrors,
              fieldHasBeenInteractedWith
            );
            trackEvent('Survey builder: toggle enable result filtering', {
              toggle: !filterSetup.enabled,
            });
          }}
          popoverHelpContent={`Choose whether to enable filtering in the survey.
          For reference filtering to work, you will also have to fill in the fields below.`}
        >
          Enable filtering
        </Checkbox>
        {filterSetup.enabled && (
          <Box marginLeft="medium">
            <RadioGroup
              groupFieldLayout={GroupFieldLayout.VERTICAL}
              value={filterSetup.type}
              options={[
                {
                  label: 'Filtering by referenced components',
                  value: SurveyResultFilteringType.REFERENCE,
                },
                {
                  label: 'Filtering by hierarchy (parent)',
                  value: SurveyResultFilteringType.HIERARCHY,
                },
              ]}
              onValueChange={type => {
                setFilterSetupAttributes(
                  {
                    ...filterSetup,
                    type: type as SurveyResultFilteringType,
                  },
                  shouldShowReferenceResultFilteringFieldErrors,
                  fieldHasBeenInteractedWith
                );
                trackEvent('Survey builder: set result filter type', { type });
              }}
            />
          </Box>
        )}
      </FieldsWrapper>

      {filterSetup.enabled &&
        filterSetup.type === SurveyResultFilteringType.HIERARCHY && (
          <FieldsWrapper>
            <Select
              label="Set preferred sorting"
              options={sortableFields.map(field => ({
                label: field.label,
                value: field.value,
              }))}
              isClearable
              value={filterSetup.sortByField}
              onValueChange={sortByField =>
                setFilterSetupAttributes(
                  {
                    ...filterSetup,
                    sortByField,
                  },
                  shouldShowReferenceResultFilteringFieldErrors,
                  fieldHasBeenInteractedWith
                )
              }
              popoverHelpContent={`By default, the hierarchy follows the sorting order specified in
                the workspace settings.`}
            />
          </FieldsWrapper>
        )}
      {filterSetup.enabled &&
        filterSetup.type === SurveyResultFilteringType.REFERENCE && (
          <>
            <FieldsWrapper>
              <TextInput
                label="Filtering help text"
                type="text"
                value={filterSetup.label}
                onValueChange={label =>
                  setFilterSetupAttributes(
                    {
                      ...filterSetup,
                      label,
                    },
                    shouldShowReferenceResultFilteringFieldErrors,
                    fieldHasBeenInteractedWith
                  )
                }
                popoverHelpContent={`Set a help text that will be shown next to the filtering
                  dropdown. For example: 'Choose a division: ' if you are
                  filtering by references to a Division component.`}
                dataTestId="filtering-input"
              />
            </FieldsWrapper>
            <ComponentSubsetSelector
              accessLevel={PermissionAccessLevel.READ}
              showAdvancedSearchToggle={false}
              workspace={filterSetup.properties?.workspaceId}
              updateWorkspace={workspace =>
                setProperty('workspaceId', workspace)
              }
              componentTypeName={filterSetup.properties?.componentTypeName}
              updateComponentTypeName={componentTypeName =>
                setProperty('componentTypeName', componentTypeName)
              }
              description={`Select the workspace and component which you would like
                          to group and filter the survey components by. For example,
                          if you choose to group by component type 'Division',
                          which includes components 'HR' and 'IT', you will be able
                          to filter the components in the survey according to whether
                          they have an existing reference to either 'HR' or 'IT'.`}
              surveyWorkspaceId={surveyWorkspaceId}
              onWorkspaceSelectBlur={() =>
                dispatchAction(
                  setSurveyFieldHasBeenInteractedWith({
                    fieldKey: 'resultDisplaySectionWorskpaceSelectField',
                  })
                )
              }
              onComponentTypeSelectBlur={() =>
                dispatchAction(
                  setSurveyFieldHasBeenInteractedWith({
                    fieldKey: 'resultDisplaySectionComponentTypeSelectField',
                  })
                )
              }
              workspaceWarningMessage={validationHelpers.getWarningMessageIfExists(
                surveyValidation,
                SurveyWarningName.NO_ACCESS_TO_SELECTED_RESULT_FILTERING_WORKSPACE
              )}
              componentTypeWarningMessage={
                validationHelpers.containsWarningId(
                  surveyValidation,
                  SurveyWarningName.NO_ACCESS_TO_SELECTED_RESULT_FILTERING_WORKSPACE
                )
                  ? WARNING_MESSAGES.NO_ACCESS_TO_SELECTED_RESULT_FILTERING_COMPONENT_TYPE
                  : undefined
              }
              workspaceRequiredErrorMessage={
                shouldShowWorkspaceFieldErrorIfPresent
                  ? 'This field is required.'
                  : undefined
              }
              compTypeMissingErrorMessage={
                shouldShowComponentTypeErrorIfPresent
                  ? validationHelpers.getErrorMessageIfExists(
                      surveyValidation,
                      SurveyErrorName.SELECTED_RESULT_FILTERING_COMPONENT_TYPE_INVALID
                    )
                  : undefined
              }
              componentTypeRequiredErrorMessage={
                shouldShowComponentTypeErrorIfPresent
                  ? 'This field is required.'
                  : undefined
              }
              workspaceSelectHint="Required. You can only select workspaces which you have Read permissions on"
              componentTypeHint="Required"
              surveyBuilderLocation={SurveyBuilderLocation.RESULT_FILTERING}
            />
            <FieldsWrapper>
              <Select
                label="Reference direction"
                onBlur={() =>
                  dispatchAction(
                    setSurveyFieldHasBeenInteractedWith({
                      fieldKey:
                        'resultDisplaySectionReferenceDirectionSelectField',
                    })
                  )
                }
                hintMessage="Required"
                options={referenceDirectionOptions}
                isClearable={false}
                value={
                  referenceDirectionOptions.find(
                    ({ value }) => value === filterSetup.properties?.outgoing
                  ) || false
                }
                onValueChange={value => setProperty('outgoing', value)}
                errorMessage={
                  shouldShowReferenceDirectionFieldErrorIfPresent
                    ? filterSetup.properties?.outgoing === undefined
                      ? 'This field is required.'
                      : undefined
                    : undefined
                }
                popoverHelpContent={`Select the direction of the reference, remember that this is
                  from the context of the survey's starting component. This will
                  also impact what type of references are available below.`}
                dataTestId="reference-direction-select"
              />
            </FieldsWrapper>
            <FieldsWrapper>
              <Select
                label="Select reference type"
                options={referenceTypeOptions}
                isClearable={false}
                onBlur={() =>
                  dispatchAction(
                    setSurveyFieldHasBeenInteractedWith({
                      fieldKey: 'resultDisplaySectionReferenceTypeSelectField',
                    })
                  )
                }
                value={maybeProtectSelectedOption(
                  referenceTypeOptions,
                  filterSetup.properties?.referenceTypeName,
                  ProtectedSelectionPlaceholder.HIDDEN_REFERENCE_TYPE
                )}
                isDisabled={!filterSetup.properties?.workspaceId}
                onValueChange={value => setProperty('referenceTypeName', value)}
                hintMessage="Required"
                errorMessage={
                  shouldShowReferenceTypeFieldErrorIfPresent &&
                  !filterSetup.properties?.referenceTypeName
                    ? 'This field is required.'
                    : undefined
                }
                dataTestId="reference-type-select"
              />
            </FieldsWrapper>
          </>
        )}
    </>
  );
};

export default connect(
  FilterSetup,
  surveyEditor$.pipe(
    map(
      ({
        surveyAttributes,
        fieldHasBeenInteractedWith,
        shouldShowReferenceResultFilteringFieldErrors,
      }) => {
        const filterProps = surveyAttributes.filterSetup?.properties ?? {};
        // Snowflake. The field errors on this section is treated differently,
        // as it can be reset by the user disabling/re-enabling reference result filtering.
        const sectionStatus = shouldShowReferenceResultFilteringFieldErrors
          ? 'left'
          : 'initial';
        return {
          shouldShowWorkspaceFieldErrorIfPresent:
            shouldDisplayErrorMessageForField(
              !!filterProps.workspaceId,
              sectionStatus,
              fieldHasBeenInteractedWith.resultDisplaySectionWorskpaceSelectField
            ),
          shouldShowComponentTypeErrorIfPresent:
            shouldDisplayErrorMessageForField(
              !!filterProps.componentTypeName,
              sectionStatus,
              fieldHasBeenInteractedWith.resultDisplaySectionComponentTypeSelectField
            ),
          shouldShowReferenceDirectionFieldErrorIfPresent:
            shouldDisplayErrorMessageForField(
              !!filterProps.outgoing,
              sectionStatus,
              fieldHasBeenInteractedWith.resultDisplaySectionReferenceDirectionSelectField
            ),
          shouldShowReferenceTypeFieldErrorIfPresent:
            shouldDisplayErrorMessageForField(
              !!filterProps.referenceTypeName,
              sectionStatus,
              fieldHasBeenInteractedWith.resultDisplaySectionReferenceTypeSelectField
            ),
          shouldShowReferenceResultFilteringFieldErrors,
          fieldHasBeenInteractedWith,
        };
      }
    )
  )
);
