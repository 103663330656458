import { reportBuilderOperations, ReportTemplate } from '@ardoq/report-builder';
import { ReportError } from '@ardoq/report-reader';
import { ArdoqId, DataSourceType } from '@ardoq/api-types';
import { PermissionContext } from '@ardoq/access-control';
import { subdivisionAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/subdivisions';
import { CurrentUserState } from '../../streams/currentUser/currentUser$';
import { getIsOrgAdmin, Maybe } from '@ardoq/common-helpers';
import { reportAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/report';

type GetReportErrorArgs = {
  reportTemplate: ReportTemplate;
  currentUser: CurrentUserState;
  canCreateGraphFilter: boolean;
  permissionContext: PermissionContext;
  reportId: ArdoqId | undefined;
};

export const getReportError = ({
  reportTemplate,
  currentUser,
  canCreateGraphFilter,
  permissionContext,
  reportId,
}: GetReportErrorArgs): Maybe<ReportError> => {
  const currentUserIsOrgAdmin = getIsOrgAdmin(currentUser);

  const isCreatedByCurrentUser = reportTemplate?.createdBy
    ? currentUser._id === reportTemplate.createdBy
    : !reportTemplate.created; // if created is missing (happens only when creating new report) - report was created by current user

  if (
    !isCreatedByCurrentUser &&
    reportId &&
    !reportAccessControlInterface.canEditReport({
      reportId,
      permissionContext,
    })
  ) {
    return ReportError.USER_IS_MISSING_EDIT_ACCESS_TO_REPORT;
  } else if (
    !canCreateGraphFilter &&
    reportTemplate.datasource === DataSourceType.GREMLIN_SEARCH
  ) {
    return ReportError.USER_CANNOT_CREATE_GREMLIN_AND_DATA_SOURCE_IS_GREMLIN;
  } else if (
    !currentUserIsOrgAdmin &&
    reportBuilderOperations
      .getZoneIdsFromReportSubdivisions(reportTemplate)
      .some(
        zoneId =>
          !subdivisionAccessControlInterface.canAccessZone(
            permissionContext,
            zoneId
          )
      )
  ) {
    return ReportError.USER_IS_MISSING_SUBDIVISION_ACCESS;
  }
  return null;
};
