import { FC, useState } from 'react';
import { ModalLayout, ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { logError } from '@ardoq/logging';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateUseCompanyLogo } from 'broadcasts/actions';
import { trackClickedToChangeOrgLogo } from 'tracking/events/clicks';
import { Label, TextInput } from '@ardoq/forms';
import { APIOrganizationAttributes } from '@ardoq/api-types';
import { organizationInterface } from 'modelInterface/organization/organizationInterface';

type ChooseOrgLogoDialogProps = {
  closeDialog: () => void;
  currentLogo: string | undefined;
  org: APIOrganizationAttributes;
  hasCheckedBox: boolean;
};

const ChooseOrgLogoDialog: FC<ChooseOrgLogoDialogProps> = ({
  closeDialog,
  currentLogo,
  org,
  hasCheckedBox,
}) => {
  const [logo, setLogo] = useState(currentLogo);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );

  const onLogoUpdate = () => {
    if (currentLogo !== logo) trackClickedToChangeOrgLogo('Broadcast Builder');

    if (!org) {
      setErrorMessage(
        'Your Organization could not be found. We are sorry about the inconvenience. If the error persists, please contact customer support.'
      );
      return;
    }

    organizationInterface
      .saveOrganization({
        ...org,
        settings: {
          ...org.settings,
          logo,
        },
      })
      .then(() => {
        closeDialog();
        dispatchAction(updateUseCompanyLogo(true));
      })
      .catch((error: Error) => {
        setErrorMessage(
          'Logo failed so save. We are sorry about the inconvenience. If the error persists, please contact customer support.'
        );
        logError(error, "Couldn't update logo in broadcast editor");
      });
  };
  return (
    <ModalTemplate
      modalSize={ModalSize.S}
      headerText="Add Logo"
      onCloseButtonClick={closeDialog}
      onSecondaryButtonClick={closeDialog}
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={onLogoUpdate}
      primaryButtonText="Apply"
    >
      <ModalLayout>
        {!logo && hasCheckedBox && (
          <p>
            Your logo is not available in the organization settings. Please add
            the logo URL.
          </p>
        )}
        <Label>Organization Logo URL</Label>
        <TextInput
          value={logo}
          onChange={e => setLogo(e.target.value)}
          placeholder="Ardoq logo URL"
          errorMessage={errorMessage}
          warningMessage={
            currentLogo && currentLogo !== logo
              ? 'Your changes will impact the surveys and broadcasts using the logo, as well as Ardoq Discover'
              : undefined
          }
          helperText="Your logo will be shown in survey headers, broadcast messages and in Ardoq Discover.
        Tip: 80px by 250px with a transparent background works best."
        />
      </ModalLayout>
    </ModalTemplate>
  );
};

export const openChooseOrgLogoDialog = (
  currentLogo: string | undefined,
  org: APIOrganizationAttributes,
  hasCheckedBox: boolean
) => {
  return modal(resolve => (
    <ChooseOrgLogoDialog
      closeDialog={() => resolve(false)}
      currentLogo={currentLogo}
      org={org}
      hasCheckedBox={hasCheckedBox}
    />
  ));
};
