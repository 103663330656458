import { ArdoqId, SubdivisionsMembershipsMap } from '@ardoq/api-types';
import { subdivisionsMembershipsOperations } from '@ardoq/subdivisions';
import { EntityWithSubdivisionMembership } from '@ardoq/subdivisions';

export type MembershipsState = {
  toAssign: SubdivisionsMembershipsMap;
  toRevoke: SubdivisionsMembershipsMap;
  initial: SubdivisionsMembershipsMap;
};

const assignMembership = (
  { toAssign, toRevoke, initial }: MembershipsState,
  {
    componentId,
    subdivisionId,
  }: {
    componentId: ArdoqId;
    subdivisionId: ArdoqId;
  }
): MembershipsState => {
  return {
    initial,
    toAssign: subdivisionsMembershipsOperations.membershipExists(initial, {
      componentId,
      subdivisionId,
    })
      ? toAssign
      : subdivisionsMembershipsOperations.assignMembership(toAssign, {
          componentId,
          subdivisionId,
        }),
    toRevoke: subdivisionsMembershipsOperations.membershipExists(toRevoke, {
      componentId,
      subdivisionId,
    })
      ? subdivisionsMembershipsOperations.revokeMembership(toRevoke, {
          componentId,
          subdivisionId,
        })
      : toRevoke,
  };
};

const assignMemberships = (
  state: MembershipsState,
  {
    componentId,
    subdivisionIds,
  }: {
    componentId: ArdoqId;
    subdivisionIds: ArdoqId[];
  }
): MembershipsState =>
  subdivisionIds.reduce(
    (acc, subdivisionId) =>
      assignMembership(acc, { componentId, subdivisionId }),
    state
  );

const revokeMembership = (
  { toAssign, toRevoke, initial }: MembershipsState,
  {
    componentId,
    subdivisionId,
  }: {
    componentId: ArdoqId;
    subdivisionId: ArdoqId;
  }
): MembershipsState => {
  return {
    initial,
    toAssign: subdivisionsMembershipsOperations.membershipExists(toAssign, {
      componentId,
      subdivisionId,
    })
      ? subdivisionsMembershipsOperations.revokeMembership(toAssign, {
          componentId,
          subdivisionId,
        })
      : toAssign,
    toRevoke: subdivisionsMembershipsOperations.membershipExists(initial, {
      componentId,
      subdivisionId,
    })
      ? subdivisionsMembershipsOperations.assignMembership(toRevoke, {
          componentId,
          subdivisionId,
        })
      : toRevoke,
  };
};

const revokeMemberships = (
  state: MembershipsState,
  {
    componentId,
    subdivisionIds,
  }: {
    componentId: ArdoqId;
    subdivisionIds: ArdoqId[];
  }
): MembershipsState =>
  subdivisionIds.reduce(
    (acc, subdivisionId) =>
      revokeMembership(acc, { componentId, subdivisionId }),
    state
  );

const previewComponentMemberships = (
  { toAssign, toRevoke, initial }: MembershipsState,
  componentId: ArdoqId
): ArdoqId[] => {
  const currentMemberships =
    subdivisionsMembershipsOperations.getComponentMemberships(
      initial,
      componentId
    );
  const membershipsToAssign =
    subdivisionsMembershipsOperations.getComponentMemberships(
      toAssign,
      componentId
    );
  const membershipsToRevoke =
    subdivisionsMembershipsOperations.getComponentMemberships(
      toRevoke,
      componentId
    );

  return currentMemberships
    .filter(subdivisionId => !membershipsToRevoke.includes(subdivisionId))
    .concat(membershipsToAssign);
};

const createMembershipsState = (
  components: EntityWithSubdivisionMembership[]
): MembershipsState => ({
  initial: subdivisionsMembershipsOperations.create(components),
  toAssign: {},
  toRevoke: {},
});

const hasChanges = (membershipsState: MembershipsState): boolean => {
  return (
    Object.keys(membershipsState.toAssign).length > 0 ||
    Object.keys(membershipsState.toRevoke).length > 0
  );
};

const getEmptyState = (): MembershipsState => ({
  initial: {},
  toAssign: {},
  toRevoke: {},
});

export const membershipOperations = {
  assignMemberships,
  revokeMemberships,
  previewComponentMemberships,
  createMembershipsState,
  hasChanges,
  getEmptyState,
};
