import { QuickSearchResultItem } from './types';
import {
  escapeString,
  sanitizeMarkdown,
  stripEmphasis,
  stripParagraph,
} from './stringUtils';
import { getIn } from 'utils/collectionUtil';
import { SearchBy, SearchResultResponse } from '@ardoq/api-types';
import { pick } from 'lodash';
import { getCroppedText, getIconName, getPath, updateEntries } from './utils';
import { Icon, IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { Text } from '@ardoq/typography';

const formatCommmonProperties = (
  { type, doc, hits }: SearchResultResponse,
  queryString: string,
  fieldName: string,
  searchBy: SearchBy
) => {
  return {
    id: doc._id,
    iconName: getIconName(type),
    typeName: doc.typeName,
    templateName: doc.name,
    displayText: stripParagraph(doc.displayText ?? ''),
    fieldValue:
      searchBy === SearchBy.FIELD_VALUE
        ? sanitizeMarkdown((doc as Record<string, any>)[fieldName] ?? '')
        : undefined,
    path: getPath(type, doc),
    description: sanitizeMarkdown(
      getCroppedText(doc.description ?? '', queryString)
    ),
    componentKey: getIn(hits, ['component-key', 0]),
    rootWorkspaceId: stripEmphasis(doc.rootWorkspace),
  };
};

const formatData = (
  { type, doc, hits }: SearchResultResponse,
  queryString: string,
  fieldName: string,
  searchBy: SearchBy
): QuickSearchResultItem => {
  if (type === 'reference') {
    return {
      type,
      name: (
        <Text variant="text2Bold">
          <FlexBox align="center" gap="xsmall" flexWrap>
            {doc.ardoq.sourceComponentName}
            <Icon iconName={IconName.ARROW_RIGHT_ALT} />
            {doc.ardoq.targetComponentName}
          </FlexBox>
        </Text>
      ),
      ...formatCommmonProperties(
        { type, doc, hits },
        queryString,
        fieldName,
        searchBy
      ),
    };
  }
  return {
    type,
    name: doc.name,
    ...formatCommmonProperties(
      { type, doc, hits },
      queryString,
      fieldName,
      searchBy
    ),
  };
};

export const mapFuzzySortResultsToNormalizedResults = (
  searchBy: SearchBy,
  results: Fuzzysort.KeysResults<QuickSearchResultItem>
) =>
  results.map(fuzzySortResult => {
    return {
      ...fuzzySortResult.obj,
      matches: createMatchesFromFuzzySortResult(searchBy, fuzzySortResult),
    };
  });

const createMatchesFromFuzzySortResult = (
  searchBy: SearchBy,
  fuzzySortResult?: Fuzzysort.KeysResult<QuickSearchResultItem>
) => {
  if (!fuzzySortResult) return [];
  return searchBy === SearchBy.NAME_DESCRIPTION
    ? [
        {
          key: 'name',
          value: fuzzySortResult[0].highlight((match, i) => (
            <span key={i} role="mark">
              {match}
            </span>
          )),
        },
        {
          key: 'description',
          value: fuzzySortResult[1].highlight((match, i) => (
            <span key={i} role="mark">
              {match}
            </span>
          )),
        },
      ]
    : [
        {
          key: 'fieldValue',
          value: fuzzySortResult[0].highlight((match, i) => (
            <span key={i} role="mark">
              {match}
            </span>
          )),
        },
      ];
};

const sanitizeResponse = (
  result: SearchResultResponse
): SearchResultResponse => {
  // escape names within ardoq field
  const searchResultArdoqProperties = [
    'sourceComponentName',
    'parentComponentName',
    'targetComponentName',
    'rootWorkspaceName',
  ];
  const escapedArdoqFields = updateEntries(
    pick(result.doc.ardoq, ...searchResultArdoqProperties),
    escapeString
  );
  return {
    type: result.type,
    doc: { ...result.doc, ardoq: escapedArdoqFields },
    hits: result.hits,
  };
};

export const quickSearchFilter = (result: SearchResultResponse) => {
  const hasId = result.doc && result.doc._id;
  const isWorkspace = result.type === 'workspace';
  const hasRootWorkspace = result.doc && result.doc.rootWorkspace;
  return Boolean(hasId && (isWorkspace || hasRootWorkspace));
};

export const quickSearchFormatter =
  (queryString: string, fieldName: string, searchBy: SearchBy) =>
  (response: SearchResultResponse) =>
    formatData(sanitizeResponse(response), queryString, fieldName, searchBy);
