import { createGlobalStyle } from 'styled-components';

/**
 * See ARD-12130 for more context.
 *
 * There are some 3rd-party scripts (Beamer) that we currently embed
 * in the main app. These scripts are rudely inserting empty divs, or (much)
 * worse, divs with text inside them that mess up our app layout for some users.
 *
 * While we work with the support teams to get these bugs fixed. this will hide
 * any divs under body that have no class or id. There aren't many nodes under
 * body, and as of today this works as expected. But it's not totally future
 * proof.
 *
 * This should be removed once the 3rd-party scripts' bugs are fixed.
 */
export default createGlobalStyle`
  body > div:not([class], [id], [style]) {
      width: 1px;
      height: 1px;
      position: absolute;
      top: -10000px;
      left: -10000px;
    }
`;
