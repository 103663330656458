import {
  APIOrganizationUser,
  ArdoqId,
  PermissionGroup,
} from '@ardoq/api-types';
import { PermissionGroupsCommands } from './commands';
import { AccessControlRoute } from 'admin/accessControl/navigation/types';

export type PermissionGroup$State = {
  groupsById: Record<ArdoqId, PermissionGroup>;
  error: null | string;
  searchPhrase: string;
};

export enum PermissionGroupPageEvents {
  CLICK_ON_CREATE_NEW_GROUP = 'clicked on create new group',
  CREATE_NEW_GROUP = 'created new group',
  CLOSE_CREATE_GROUP_MODAL = 'closed create group modal',
  ENTERED_NEW_GROUP_NAME = 'entered new group name',
  SELECTED_USERS_DURING_CREATION = 'selected users during creation',
  SELECT_A_GROUP = 'selected a group',
  CLICK_DELETE_GROUP_BUTTON = 'clicked delete group button',
  DELETE_GROUP = 'deleted group',
  CANCEL_REMOVE_GROUP = 'cancelled remove group',
  CLICK_ADD_USER_TO_GROUP_BUTTON = 'clicked add user to group button',
  SELECTED_USERS_DURING_ADDING_USERS_TO_GROUP = 'selected users during adding users to group',
  ADD_USER_TO_GROUP = 'added user to group',
  CANCEL_ADD_USER_TO_GROUP = 'cancelled add user to group',
  CLICK_RENAME_GROUP_BUTTON = 'clicked rename group button',
  RENAME_GROUP = 'renamed group',
  CANCEL_RENAME_GROUP = 'cancelled rename group',
  REMOVE_USER_FROM_GROUP = 'removed user from group',
  CLICK_REMOVE_USER_FROM_GROUP_BUTTON = 'clicked remove user from group button',
  CANCEL_REMOVE_USER_FROM_GROUP = 'cancelled remove user from group',
  SORT_USERS_BY_HEADER = 'sorted users by header',
  CLICK_ON_GROUP_DETAILS_TAB = 'clicked on group details tab',
}

export type PermissionGroupsProps = AccessControlRoute & {
  groupsById: Record<string, PermissionGroup>;
  permissionGroups: (Omit<PermissionGroup, 'users'> & {
    users: APIOrganizationUser[];
  })[];
  error: null | string;
  commands: PermissionGroupsCommands;
  searchPhrase: string;
};
