import { actionCreator } from '@ardoq/rxbeach';
import { ArdoqId, SortParams } from '@ardoq/api-types';
import { ExportReportParams } from './types';

export const setReportOverviewSearchPhrase = actionCreator<string>(
  '[reportOverview] SET_REPORT_OVERVIEW_SEARCH_PHRASE'
);

export const setReportOverviewSort = actionCreator<SortParams>(
  '[reportOverview] SET_REPORT_OVERVIEW_SORT'
);

export const shareReportSelected = actionCreator<ArdoqId>(
  '[reportOverview] SHARE_REPORT_MENU_ITEM_SELECTED'
);

export const shareReportDialogLoadingFinished = actionCreator(
  '[report] SHARE_REPORT_DIALOG_LOADING_FINISHED'
);

export const exportToExcelButtonWasClicked = actionCreator<ExportReportParams>(
  '[report] EXPORT_TO_EXCEL_REQUESTED'
);

export const exportToReadableExcelButtonWasClicked =
  actionCreator<ExportReportParams>(
    '[report] EXPORT_TO_READABLE_EXCEL_REQUESTED'
  );
