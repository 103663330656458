import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { colors, s16, s32, s64, s8, shadowS } from '@ardoq/design-tokens';
import { header2, normal14 } from '@ardoq/typography';
import { IconName } from '@ardoq/icons';
import IconClickable from 'atomicComponents/IconClickable';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { trackEvent } from 'tracking/tracking';
import { StatusType, Tag } from '@ardoq/status-ui';

const Card = styled.div`
  margin-bottom: ${s16};
  padding: ${s32};
  border-radius: ${s8};
  background-color: ${colors.white};
  box-shadow: ${shadowS};
`;

const Header = styled.h2<{ $isMissingDescription: boolean }>`
  color: ${colors.grey15};
  display: flex;
  gap: ${s8};
  ${header2};
  margin-bottom: ${props =>
    props.$isMissingDescription ? `calc(${s8} + ${s64})` : s8};
  margin-top: 0;
`;

const Description = styled.p`
  ${normal14};
  color: ${colors.grey50};
  margin-bottom: ${s64};
  max-width: 448px;
`;

type FormSectionProps = {
  header: string;
  knowledgeBaseReference?: string;
  description?: string;
  enforceWidth?: boolean;
  children?: ReactNode;
  hasError?: boolean;
};

const FormSection: FC<FormSectionProps> = ({
  header,
  knowledgeBaseReference,
  description,
  enforceWidth = true,
  children,
  hasError,
}) => {
  return (
    <Card>
      <div style={{ width: enforceWidth ? 448 : 'initial' }}>
        {hasError && (
          <div style={{ marginBottom: s16 }}>
            <Tag
              statusType={StatusType.ERROR}
              label="Error"
              iconName={IconName.ERROR}
            />
          </div>
        )}
        <Header $isMissingDescription={!description}>
          {header}
          {knowledgeBaseReference && (
            <IconClickable
              color={colors.grey50}
              hoverColor={colors.grey35}
              iconName={IconName.KNOWLEDGE_BASE}
              tooltipText="Knowledge base"
              onClick={() => {
                trackEvent('Clicked knowledge base link', {
                  location: `Viewpoints form: ${header}`,
                });

                window.open(
                  `${KnowledgeBaseLink.VIEWPOINTS}#${knowledgeBaseReference}`,
                  '_blank noopener'
                );
              }}
            />
          )}
        </Header>
        {description && <Description>{description}</Description>}
        {children}
      </div>
    </Card>
  );
};

export default FormSection;
