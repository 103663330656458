import { useState } from 'react';
import Components from 'collections/components';
import Fields from 'collections/fields';
import Models from 'collections/models';
import References from 'collections/references';
import Tags from 'collections/tags';
import Workspaces from 'collections/workspaces';
import Context from 'context';
import { DIFF_TYPE } from '@ardoq/global-consts';
import { DiffType, EnhancedScopeData } from '@ardoq/data-model';
import {
  APIComponentAttributes,
  APIDiffBranchToBranch,
  APIFieldAttributes,
  APIModelAttributes,
  APIReferenceAttributes,
  APIScopeData,
  APITagAttributes,
  APIWorkspaceAttributes,
  ArdoqId,
  Verb,
} from '@ardoq/api-types';
import ViewCollection from 'collections/ViewCollection';
import { ExcludedSet } from 'collections/consts';
import { alert, confirm } from '@ardoq/modal';
import UserSettings from 'models/UserSettings';
import { Icon, IconName } from '@ardoq/icons';
import styled from 'styled-components';
import { ScopeSize } from './types';
import { getVerbFromMergeStep } from 'components/DiffMergeSidebarNavigator/utils';
import { getWindowLocationPath, setWindowLocation } from 'router/routerUtils';
import { MergeStep } from 'components/DiffMergeTable/types';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { dispatchAction } from '@ardoq/rxbeach';
import { setScenarioIsInSync } from 'scope/actions';
import { cloneDeep } from 'lodash';
import { initMerge } from './merge/actions';
import { MergeDirection } from './merge/MergeDirection';
import { isArdoqError, toByIdDictionary } from '@ardoq/common-helpers';
import { scenarioApi } from '@ardoq/api';
import { Checkbox } from '@ardoq/forms';
import { logError } from '@ardoq/logging';
import { NewReference } from 'traversals/pathAndGraphTypes';

type SetModelsArgs = {
  workspaces: APIWorkspaceAttributes[];
  models: APIModelAttributes[];
  fields: APIFieldAttributes[];
  components: APIComponentAttributes[];
  references: (APIReferenceAttributes | NewReference)[];
  tags: APITagAttributes[];
};

const SCENARIOS_NAMESPACE = 'scenarios';

export const scenariosUserSettings = new UserSettings(SCENARIOS_NAMESPACE);

export const resetCollections = () => {
  References.collection.reset();
  Components.collection.reset();
  Tags.collection.reset();
  Fields.collection.reset();
  Models.collection.reset();
  Workspaces.collection.reset();
  References.collection.buildCacheForSourceAndTargetMaps();
  Fields.collection.invalidateFieldsCache();
};

export const addModelsToCollections = ({
  workspaces,
  models,
  fields,
  components,
  references,
  tags,
}: SetModelsArgs) => {
  resetCollections();

  const addOptions = {
    silent: true,
    sort: false,
  };

  Workspaces.collection.add(workspaces, addOptions);
  Models.collection.add(models, addOptions);
  Fields.collection.add(fields, addOptions);
  Components.collection.add(components, addOptions);
  References.collection.add(references, addOptions);
  Tags.collection.add(tags, addOptions);

  References.collection.buildCacheForSourceAndTargetMaps();
};

export const setModelsToCollections = ({
  workspaces,
  models,
  fields,
  components,
  references,
  tags,
}: SetModelsArgs) => {
  const setOptions = {
    add: true,
    remove: true,
    merge: true,
    silent: true,
    sort: true,
  };

  Workspaces.collection.set(workspaces, setOptions);
  Models.collection.set(models, setOptions);
  Fields.collection.set(fields, setOptions);
  Components.collection.set(components, setOptions);
  References.collection.set(references, setOptions);
  Tags.collection.set(tags, setOptions);

  References.collection.buildCacheForSourceAndTargetMaps();
};

type AddComponentsToCollectionsArgs = {
  components: APIComponentAttributes[];
};

export const addComponentsToCollections = ({
  components,
}: AddComponentsToCollectionsArgs) => {
  Components.collection.add(components, { silent: true });
};

type AddTagsToCollectionsArgs = {
  tags: APITagAttributes[];
};

export const addTagsToCollections = ({ tags }: AddTagsToCollectionsArgs) => {
  Tags.collection.add(tags, { silent: true });
};

type AddReferencesToCollectionArgs = {
  references: APIReferenceAttributes[];
};

export const addReferencesToCollections = ({
  references,
}: AddReferencesToCollectionArgs) => {
  References.collection.add(references, { silent: true });
  References.collection.buildCacheForSourceAndTargetMaps();
};

export const clearGraph = () => {
  References.collection.reset();
  References.collection.buildCacheForSourceAndTargetMaps();
};

export const clearComponentHierarchies = () => {
  Components.collection.hierarchy.clearAllHierarchies();
};

export const clearComponentHierarchiesAndCloseWorkspaces = () => {
  clearComponentHierarchies();
  Context.workspaces().forEach(workspace =>
    Context.justCloseWorkspace(workspace)
  );
};

const setDiffType = <APIAttributeType,>(
  attrs: APIAttributeType,
  diffType: DiffType
) => ({
  ...attrs,
  [DIFF_TYPE]: diffType,
});

const addMissingFields = <APIAttributeType,>(
  attrs: APIAttributeType,
  diff?: Partial<APIAttributeType>
): APIAttributeType => {
  if (!diff) {
    return attrs;
  }
  const missingFields = Object.keys(diff).reduce(
    (accumulatedMissingFields, key) =>
      Object.hasOwnProperty.call(attrs, key)
        ? accumulatedMissingFields
        : { ...accumulatedMissingFields, [key]: null },
    {}
  );
  return { ...attrs, ...missingFields };
};

const getDiffType = (
  diffDataCollection: APIDiffBranchToBranch[keyof APIDiffBranchToBranch],
  id: ArdoqId
) => {
  if (diffDataCollection.create[id]) {
    return DiffType.ADDED;
  }
  if (diffDataCollection.delete[id]) {
    return DiffType.REMOVED;
  }

  if (diffDataCollection.update?.[id]) {
    return DiffType.CHANGED;
  }
  return DiffType.UNCHANGED;
};

export const getEntities = <APIAttributeType extends { _id: ArdoqId }>(
  branchCollection: APIAttributeType[],
  masterCollection: APIAttributeType[],
  diffDataCollection: APIDiffBranchToBranch[keyof APIDiffBranchToBranch]
) => {
  const update =
    (diffDataCollection.update as Record<ArdoqId, Partial<APIAttributeType>>) ||
    {};

  const masterCollectionById = toByIdDictionary(masterCollection);
  const branchCollectionById = toByIdDictionary(branchCollection);

  return {
    // TODO? can be optimezed by only providing the list of entities which
    // have to be changed.
    branch: [
      ...branchCollection.map(attrs =>
        setDiffType<APIAttributeType>(
          addMissingFields(attrs, update[attrs._id]),
          DiffType.NONE
        )
      ),
      ...createCopyAndSetDiffType(
        diffDataCollection.delete,
        masterCollectionById,
        DiffType.PLACEHOLDER
      ),
    ],
    diff: [
      ...branchCollection.map(attrs =>
        setDiffType<APIAttributeType>(
          addMissingFields(attrs, update[attrs._id]),
          getDiffType(diffDataCollection, attrs._id)
        )
      ),
      ...createCopyAndSetDiffType(
        diffDataCollection.delete,
        masterCollectionById,
        DiffType.REMOVED
      ),
    ],
    master: [
      ...masterCollection.map(attrs =>
        setDiffType<APIAttributeType>(
          addMissingFields(attrs, update[attrs._id]),
          DiffType.NONE
        )
      ),
      ...createCopyAndSetDiffType(
        diffDataCollection.create,
        branchCollectionById,
        DiffType.PLACEHOLDER
      ),
    ],
  };
};

// The entity attributes in diffDataCollection are only
// a subset based on ignoredProperties and the dynamicFilter
// of the original attribute. Cloning them from the
// source to provide the complete attributes for the backbone models.
const createCopyAndSetDiffType = <APIAttributeType extends { _id: ArdoqId }>(
  dataCollectionDict: APIDiffBranchToBranch[keyof APIDiffBranchToBranch][
    | Verb.DELETE
    | Verb.CREATE],
  collectionById: Record<ArdoqId, APIAttributeType>,
  diffType: DiffType
) =>
  Object.keys(dataCollectionDict).map(id =>
    setDiffType<APIAttributeType>(cloneDeep(collectionById[id]), diffType)
  );

const toId = <ModelType extends { _id: ArdoqId }>(model: ModelType) =>
  model._id;

export const setDefaultCollectionView = <
  ModelAttributes extends { _id: ArdoqId },
>(
  models: ModelAttributes[],
  connectedModels: ModelAttributes[],
  collection: ViewCollection<Backbone.Model>
) => {
  const scenarioContextIds = new Set(models.map(toId));
  const onlyScenarioRelatedIds = connectedModels
    .filter(({ _id: id }) => !scenarioContextIds.has(id))
    .map(toId);

  collection.clearExcludedFromView();
  collection.addExcludedFromView(
    onlyScenarioRelatedIds,
    ExcludedSet.SCENARIO_RELATED_SET
  );
};

const Block = styled.div`
  margin-bottom: 25px;
  display: flex;
  align-items: center;
`;

export const openScenarioIsOutOfSyncModal = async () => {
  const isSyncNow = await confirm({
    title: 'Incompatible metamodels',
    text: (
      <>
        <Block>
          The related components section and visual diff in scenarios are not
          available when the metamodel in the mainline has changes that are
          incompatible with the metamodel in the scenario. To get the metamodels
          back to a compatible state, please use the scenario merge
          functionality.
        </Block>
      </>
    ),
    confirmButtonTitle: 'Sync metamodels now',
    cancelButtonTitle: 'Cancel',
    confirmButtonClickId: 'incompatible-metamodel-scenario-sync-now',
    cancelButtonClickId: 'incompatible-metamodel-scenario-close',
  });
  if (isSyncNow) {
    dispatchAction(initMerge(MergeDirection.MAINLINE_TO_BRANCH));
  }
  return isSyncNow;
};

const WelcomeScenarioMessage = () => {
  const [showAgain, setShowAgain] = useState(false);
  return (
    <>
      <Block>
        Not all views have the capability of visualizing the entire scenario,
        related components and the difference between mainline and the scenario.
      </Block>
      <Block>
        The views that do support this are marked with the Scenario icon
        <Icon style={{ marginLeft: 10 }} iconName={IconName.SCENARIO} />
      </Block>
      <Checkbox
        isChecked={showAgain}
        onChange={() => {
          setShowAgain(!showAgain);
          scenariosUserSettings.set('hideWelcomeModal', !showAgain);
        }}
      >
        Don’t show me this message again
      </Checkbox>
    </>
  );
};

export const openWelcomeScenarioModal = () =>
  alert({
    title: 'Scenario views',
    text: <WelcomeScenarioMessage />,
    confirmButtonTitle: 'Close',
  });

const getChildrenCount = <T extends { children: Record<string, T> }>(
  obj: Record<string, T>
): number =>
  Object.values(obj).reduce(
    (acc, value) => acc + 1 + getChildrenCount(value.children),
    0
  );

export const getScopeSize = (
  scopeData: APIScopeData | EnhancedScopeData
): ScopeSize => {
  const { referenceTypesCount, componentTypesCount } = (
    scopeData.models as APIModelAttributes[]
  ).reduce(
    (acc, model) => ({
      referenceTypesCount:
        acc.referenceTypesCount + Object.values(model.referenceTypes).length,
      componentTypesCount:
        acc.componentTypesCount + getChildrenCount(model.root),
    }),
    {
      referenceTypesCount: 0,
      componentTypesCount: 0,
    }
  );

  return {
    componentsCount: scopeData.components.length,
    referencesCount: scopeData.references.length,
    workspacesCount: scopeData.workspaces.length,
    tagsCount: scopeData.tags.length,
    modelsCount: scopeData.models.length,
    fieldsCount: scopeData.fields.length,
    componentTypesCount,
    referenceTypesCount,
  };
};

const SEARCH_PARAM_KEY = 'mergeFlow';

export const setMergeStepInWindowLocation = (mergeStep?: MergeStep) => {
  const verb = mergeStep ? getVerbFromMergeStep(mergeStep) : ''; // for the 'no changes to merge' page just use an empty string
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(SEARCH_PARAM_KEY, verb);
  setWindowLocation(`${getWindowLocationPath()}?${searchParams.toString()}`);
};

export const removeMergeStepFromWindowLocation = () => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(SEARCH_PARAM_KEY);
  setWindowLocation(`${getWindowLocationPath()}?${searchParams.toString()}`);
};

export const updateSyncStatusIfChanged = async (scenarioId?: ArdoqId) => {
  const isInSync = await scenarioApi.isInSync(
    scenarioId ?? getActiveScenarioId()!
  );
  if (isArdoqError(isInSync)) {
    return logError(isInSync, 'Error checking if scenario is in sync');
  }
  if (isInSync) {
    dispatchAction(setScenarioIsInSync({ isInSync }));
  }
};
