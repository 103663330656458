import { Multiselect } from '@ardoq/select';
import { VpcsOptions } from '../../../streams/vpcs/types';

type VpcsSelectorProps = {
  onSelect: (ids: string[]) => void;
  selectedVpcIds: string[];
  vpcsOptions: VpcsOptions[];
  isDisabled: boolean;
};
export const VpcsSelector = ({
  selectedVpcIds,
  vpcsOptions,
  onSelect,
  isDisabled,
}: VpcsSelectorProps) => {
  return (
    <Multiselect
      isDisabled={isDisabled}
      options={vpcsOptions}
      placeholder="Type and select a VPC"
      helperText="Using VPC filters reduces the amount of data to configure by narrowing down the fetched data."
      onValueChange={ids => onSelect(ids || [])}
      value={selectedVpcIds}
      showSelectAllFooter
    />
  );
};
