import { FormattingRule } from '@ardoq/perspectives';
import {
  APIEntityType,
  FilterInfoOperator,
  FormattingFilter,
  FilterTypes,
} from '@ardoq/api-types';
import { v4 as uuidv4 } from 'uuid';

export const mapToPerspectiveEditorFormattingRule = (
  formattingFilter: FormattingFilter
): FormattingRule | null => {
  if (formattingFilter.type === FilterTypes.TAG) {
    return {
      type: APIEntityType.TAG,
      __id: uuidv4(),
      isInverted: formattingFilter.isNegative,
      color: formattingFilter.color,
      value: (formattingFilter.value as string) || undefined,
    };
  }
  if (formattingFilter.type === FilterTypes.ATTRIBUTE) {
    return {
      type: formattingFilter.affectComponent
        ? APIEntityType.COMPONENT
        : APIEntityType.REFERENCE,
      field: formattingFilter.name,
      comparator: formattingFilter.comparator as FilterInfoOperator,
      value: formattingFilter.value,
      __id: uuidv4(),
      isInverted: formattingFilter.isNegative,
      color: formattingFilter.color,
    };
  }
  return null;
};
