import { useState } from 'react';
import {
  FieldsWrapper,
  GroupFieldLayout,
  RadioGroup,
  RadioGroupItem,
} from '@ardoq/forms';
import { ModalSize, ModalTemplate } from '@ardoq/modal';
import manageUserRolesViewModel$ from '../../accessControl/ManageUserRoles/streams/manageUserRolesViewModel$';
import { colors, s16, s32 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { bulkUpdateUserRoles } from 'admin/user/actions';
import {
  APIOrganizationUser,
  APIRoleAttributes,
  OrgAccessLevel,
} from '@ardoq/api-types';
import PaginatedUserTable from './PaginatedUserTable';
import DefaultRoleDescription from '../../accessControl/ManageUserRoles/DefaultRoleDescription';
import { Island } from '@ardoq/page-layout';
import { trackEvent } from 'tracking/tracking';

type ManageRoleDialogProps = {
  roles: APIRoleAttributes[];
  selectedUsers: APIOrganizationUser[];
  closeDialog: () => void;
};

const Wrapper = styled.div`
  background-color: ${colors.grey95};
  padding: ${s32};
  display: flex;
  flex-direction: column;
  gap: ${s16};
`;

const ManageRoleDialog = ({
  roles,
  closeDialog,
  selectedUsers,
}: ManageRoleDialogProps) => {
  const [selectedRoleLabel, setSelectedRoleLabel] = useState<
    OrgAccessLevel | undefined
  >(undefined);

  const handleBulkUpdateUsers = () => {
    if (!selectedRoleLabel) return;
    const selectedUserIds = selectedUsers.map(({ _id }) => _id);
    dispatchAction(bulkUpdateUserRoles({ selectedUserIds, selectedRoleLabel }));
    trackEvent('User Management: updated roles');
    closeDialog();
  };

  return (
    <ModalTemplate
      headerText="Assign role"
      modalSize={ModalSize.M}
      onCloseButtonClick={closeDialog}
      onPrimaryButtonClick={handleBulkUpdateUsers}
      onSecondaryButtonClick={closeDialog}
      primaryButtonClickId="save-roles-click-id"
      primaryButtonText="Save"
      secondaryButtonClickId="cancel-roles-click-id"
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={!selectedRoleLabel}
    >
      <Wrapper>
        <Island
          title="New role"
          subtitle="Assign your selected users a new role."
        >
          <FieldsWrapper
            sideNote={
              selectedRoleLabel && (
                <DefaultRoleDescription role={selectedRoleLabel} />
              )
            }
          >
            <RadioGroup
              value={selectedRoleLabel}
              onValueChange={roleLabel => {
                const role = roles.find(({ label }) => label === roleLabel);
                if (!role) return;
                setSelectedRoleLabel(role.label);
              }}
              groupFieldLayout={GroupFieldLayout.VERTICAL}
            >
              {roles.map(({ label, title }) => (
                <RadioGroupItem key={label} value={label}>
                  {title}
                </RadioGroupItem>
              ))}
            </RadioGroup>
          </FieldsWrapper>
        </Island>

        <PaginatedUserTable
          selectedUsers={selectedUsers}
          selectedRoleLabel={selectedRoleLabel}
        />
      </Wrapper>
    </ModalTemplate>
  );
};

export default connect(ManageRoleDialog, manageUserRolesViewModel$);
