import { APIModelAttributes } from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';
import { getWorkspaceModelChangeMetadata } from './utils';

export const trackWorkspaceModelChanges = (
  currentModel: APIModelAttributes,
  newModel: APIModelAttributes
) => {
  const metadata = getWorkspaceModelChangeMetadata(currentModel, newModel);
  trackEvent('Changed workspace metamodel', metadata);
};
