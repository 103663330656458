export enum LucidChartDocumentPickerStatus {
  CANCELLED = 'cancelled',
  LOADED = 'loaded',
  SUCCESS = 'success',
}

export enum DocumentPickerActionType {
  CANCELLED,
  CLOSED,
  ERRORED,
  LOADED,
}

type DocumentPickerCancel = {
  type: DocumentPickerActionType.CANCELLED;
};

type DocumentPickerLoad = {
  type: DocumentPickerActionType.LOADED;
};
type DocumentPickerClose = {
  type: DocumentPickerActionType.CLOSED;
};

export type DocumetPickerAction =
  | DocumentPickerCancel
  | DocumentPickerLoad
  | DocumentPickerClose;

export type LucidDocumentSelectPayload = {
  documentId: string;
  title: string;
  page: number;
  status: LucidChartDocumentPickerStatus.SUCCESS;
  createdInPicker: boolean;
};

export type RequiredAuth = {
  attemptCount?: number;
};
