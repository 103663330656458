import {
  CurrentBroadcast,
  BroadcastStreamShape,
  InstancePerson,
  UnpersistedCurrentBroadcast,
} from 'broadcasts/types';
import { IconName } from '@ardoq/icons';
import { broadcastContentTypeIconMapping } from 'broadcasts/config';
import { LabeledValue } from 'aqTypes';
import {
  APIBroadcastAttributes,
  AdvancedSearchFilterCondition,
  AdvancedSearchFilterConditionByReference,
  ArdoqId,
  AudiencePreview,
  AudiencePreviewRow,
  LightAudiencePreviewRow,
  AudienceStartingPage,
  BroadcastAudience,
  BroadcastAudienceType,
  BroadcastComponent,
  BroadcastContent,
  BroadcastContentType,
  BroadcastFilterCondition,
  BroadcastFilterConditionType,
  BroadcastMessage,
  BroadcastMessageContent,
  BroadcastPerson,
  BroadcastReportContent,
  BroadcastSchedule,
  BroadcastScope,
  BroadcastStatus,
  BroadcastSurveyContent,
  EmailAudience,
  GremlinAudience,
  IntervalType,
  PredefinedAudience,
  PredefinedQueryOption,
  RepeatingSchedule,
  SurveyCompleteFilterCondition,
  SurveyIncompleteFilterCondition,
  SurveyNotUpdatedSinceFilterCondition,
  WorkspaceAudience,
  APIAudiencePreviewResponse,
  BroadcastInstance,
} from '@ardoq/api-types';
import { pluralize, toByIdDictionary } from '@ardoq/common-helpers';
import { isEqual, omit, uniqBy } from 'lodash';
import {
  DEFAULT_MESSAGE_BROADCAST_MESSAGE,
  DEFAULT_REPORT_BROADCAST_MESSAGE,
  DEFAULT_SURVEY_BROADCAST_MESSAGE,
} from './consts';
import CurrentUser from 'models/currentUser';
import { addDays, currentDate, format, parseDate } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { dispatchAction } from '@ardoq/rxbeach';
import { initiateNavigationToEditBroadcastForm } from '../router/navigationActions';
import { showBroadcastDetailsDialog } from './broadcastOverview/actions';
import {
  trackClickedToEditBroadcast,
  BroadcastEditOrigin,
  trackClickedToOpenBroadcastDetailsDialog,
} from './tracking';

export const createStartDate = () => {
  const date = currentDate();
  date.setMinutes(0);
  return date.toISOString();
};

export const createNewBroadcast = (
  folderId?: ArdoqId | null
): UnpersistedCurrentBroadcast => ({
  name: '',
  content: {
    contentType: BroadcastContentType.SURVEY,
    filterConditions: [],
    contentId: null,
  },
  audiences: [],
  audienceStartingPage: AudienceStartingPage.SURVEY_OVERVIEW,
  message: {
    subject: DEFAULT_SURVEY_BROADCAST_MESSAGE.subject,
    sender: `${CurrentUser.attributes.email} via Ardoq`,
    replyToArdoq: false,
    body: DEFAULT_SURVEY_BROADCAST_MESSAGE.body,
    companyLogo: null,
    hideLogo: false,
  },
  scheduling: {
    repeating: true,
    startDate: createStartDate(),
    reminderInDays: null,
    interval: createNewInterval(),
    sendOnWeekends: false,
  },
  folderId: folderId ?? null,
});

export const createNewInterval = () => ({
  intervalValue: 1,
  intervalType: IntervalType.WEEK,
});

export const isMessageContent = (
  content: BroadcastContent
): content is BroadcastMessageContent =>
  content.contentType === BroadcastContentType.MESSAGE;

export const isSurveyContent = (
  content: BroadcastContent
): content is BroadcastSurveyContent =>
  content.contentType === BroadcastContentType.SURVEY;

export const isReportContent = (
  content: BroadcastContent
): content is BroadcastReportContent =>
  content.contentType === BroadcastContentType.REPORT;

const isValidMessageContent = (content: BroadcastContent) =>
  isMessageContent(content) && content.workspaceId && content.componentType;

const isValidSurveyContent = (content: BroadcastContent) =>
  isSurveyContent(content) && content.contentId;

const isValidReportContent = (content: BroadcastContent) =>
  isReportContent(content) &&
  content.contentId &&
  content.columnName &&
  content.aggregate &&
  content.predicate &&
  content.threshold !== null;

export const isValidContent = (content: BroadcastContent) =>
  isValidMessageContent(content) ||
  isValidSurveyContent(content) ||
  isValidReportContent(content);

export const isValidAudience = (
  content: BroadcastContent,
  audiences: BroadcastAudience[]
) => Boolean(isValidContent(content) && audiences.length);

export const getQueryRepresentation = ({ label }: PredefinedQueryOption) => {
  return label;
};

const audienceTypeRepresentations = new Map([
  [BroadcastAudienceType.PREDEFINED, 'Predefined query'],
  [BroadcastAudienceType.GREMLIN, 'Gremlin query'],
  [BroadcastAudienceType.EMAIL, 'Email'],
  [BroadcastAudienceType.WORKSPACE, 'People workspace'],
]);

export const getAudienceTypeRepresentation = (
  audienceType: BroadcastAudienceType
) => audienceTypeRepresentations.get(audienceType);

export const getAudienceStartingPageRepresentation = (
  audienceStartingPage: AudienceStartingPage
) => {
  switch (audienceStartingPage) {
    case AudienceStartingPage.SURVEY_OVERVIEW:
      return 'Component overview page';
    case AudienceStartingPage.MY_TASKS:
      return 'My tasks page';
    case AudienceStartingPage.DISCOVER_SURVEY:
      return 'Separate links in discover';
    case AudienceStartingPage.SURVEY_COMPONENT:
    default:
      return 'Separate component links';
  }
};

const audienceIsOfType =
  <T extends BroadcastAudience>(audienceType: BroadcastAudienceType) =>
  (audience: BroadcastAudience): audience is T =>
    audience.audienceType === audienceType;

export const withoutAudienceOfType =
  (audienceType: BroadcastAudienceType) => (audiences: BroadcastAudience[]) => {
    return audiences.filter(
      audience => !audienceIsOfType(audienceType)(audience)
    );
  };

export const withAddedOrReplacedAudience = (
  audiences: BroadcastAudience[],
  audience: BroadcastAudience
) => {
  const currentIndex = audiences.findIndex(
    audienceIsOfType(audience.audienceType)
  );
  return currentIndex === -1
    ? [...audiences, audience]
    : [
        ...audiences.slice(0, currentIndex),
        audience,
        ...audiences.slice(currentIndex + 1),
      ];
};

const containsAudienceOfType =
  (audienceType: BroadcastAudienceType) => (audiences: BroadcastAudience[]) =>
    audiences.some(audienceIsOfType(audienceType));

export const containsPredefinedAudience = containsAudienceOfType(
  BroadcastAudienceType.PREDEFINED
);

export const containsGremlinAudience = containsAudienceOfType(
  BroadcastAudienceType.GREMLIN
);

export const containsEmailAudience = containsAudienceOfType(
  BroadcastAudienceType.EMAIL
);

export const containsWorkspaceAudience = containsAudienceOfType(
  BroadcastAudienceType.WORKSPACE
);

const isPredefinedAudience = audienceIsOfType<PredefinedAudience>(
  BroadcastAudienceType.PREDEFINED
);

const isGremlinAudience = audienceIsOfType<GremlinAudience>(
  BroadcastAudienceType.GREMLIN
);

const isEmailAudience = audienceIsOfType<EmailAudience>(
  BroadcastAudienceType.EMAIL
);

const isWorkspaceAudience = audienceIsOfType<WorkspaceAudience>(
  BroadcastAudienceType.WORKSPACE
);

export const findPredefinedAudience = (audiences: BroadcastAudience[]) => {
  return audiences.find(isPredefinedAudience) || null;
};

export const findGremlinAudience = (audiences: BroadcastAudience[]) => {
  return audiences.find(isGremlinAudience) || null;
};

export const findEmailAudience = (audiences: BroadcastAudience[]) => {
  return audiences.find(isEmailAudience) || null;
};

export const findWorkspaceAudience = (audiences: BroadcastAudience[]) => {
  return audiences.find(isWorkspaceAudience) || null;
};

export const getMissingContactEmailWarningMessage = (
  numberOfMissingContactEmails: number
) => {
  return `${numberOfMissingContactEmails} ${
    numberOfMissingContactEmails !== 1 ? 'audiences are' : 'audience is'
  } missing a Contact Email field. Only people whose Person component has their email stored in a Contact Email field will be able to receive the broadcast.`;
};

export const getPersonRepresentation = (
  person: BroadcastPerson | InstancePerson
) => {
  return person.name ?? person.email;
};

export const getPersonSecondaryRepresentation = (person: BroadcastPerson) => {
  return person.name ? person.email : null;
};

const getUniquePeople = (people: BroadcastPerson[]) => {
  return uniqBy(people, getPersonRepresentation);
};

export const getUniqueAudienceCount = (audiencePreview: AudiencePreview) => {
  const people = audiencePreview.map(({ person }) => person);
  const uniquePeople = getUniquePeople(people);
  return uniquePeople.length;
};

const isMissingEmail = ({ email }: BroadcastPerson) => !email;

const getPeopleSelection = (
  people: BroadcastPerson[],
  limitedToPersonIds: ArdoqId[]
) => {
  if (!limitedToPersonIds.length) return people;
  return people.filter(({ _id }) => _id && limitedToPersonIds.includes(_id));
};

const countPeopleWithoutEmail = (people: BroadcastPerson[]) => {
  return getUniquePeople(people).filter(isMissingEmail).length;
};

export const getWarningMessageFromPeople = (
  people: BroadcastPerson[],
  limitedToPersonIds: ArdoqId[] = []
) => {
  const peopleSelection = getPeopleSelection(people, limitedToPersonIds);
  const numberOfMissingEmails = countPeopleWithoutEmail(peopleSelection);
  return numberOfMissingEmails
    ? getMissingContactEmailWarningMessage(numberOfMissingEmails)
    : null;
};

export const getWarningMessageFromAudiencePreview = (
  audiencePreview: AudiencePreview
) => {
  const people = audiencePreview.map(({ person }) => person);
  return getWarningMessageFromPeople(people);
};

export const doesAudiencePreviewHaveWarnings = (
  audiencePreview: AudiencePreview
) => {
  const people = audiencePreview.map(({ person }) => person);
  return countPeopleWithoutEmail(people) > 0;
};

export const isRepeatingSchedule = (
  schedule: BroadcastSchedule
): schedule is RepeatingSchedule => schedule.repeating;

export const getIconForContentType = (
  contentType: BroadcastContentType
): IconName => broadcastContentTypeIconMapping.get(contentType) as IconName;

export const pluralizeOptionLabels = <T>(
  options: LabeledValue<T>[],
  count: number
) => {
  if (count === 1) return options;
  return options.map(option => ({
    ...option,
    label: pluralize(option.label, count),
  }));
};

export function getFilterConditionByType(
  filterConditions: BroadcastFilterCondition[],
  conditionType: BroadcastFilterConditionType.SURVEY_INCOMPLETE
): SurveyIncompleteFilterCondition | null;
export function getFilterConditionByType(
  filterConditions: BroadcastFilterCondition[],
  conditionType: BroadcastFilterConditionType.SURVEY_COMPLETE
): SurveyCompleteFilterCondition | null;
export function getFilterConditionByType(
  filterConditions: BroadcastFilterCondition[],
  conditionType: BroadcastFilterConditionType.SURVEY_NOT_UPDATED_IN
): SurveyNotUpdatedSinceFilterCondition | null;
export function getFilterConditionByType(
  filterConditions: BroadcastFilterCondition[],
  conditionType: BroadcastFilterConditionType.ADVANCED_SEARCH
): AdvancedSearchFilterCondition | null;
export function getFilterConditionByType(
  filterConditions: BroadcastFilterCondition[],
  conditionType: BroadcastFilterConditionType.ADVANCED_SEARCH_BY_REFERENCE
): AdvancedSearchFilterConditionByReference | null;
export function getFilterConditionByType(
  filterConditions: BroadcastFilterCondition[],
  conditionType: BroadcastFilterConditionType
): BroadcastFilterCondition | null;
export function getFilterConditionByType(
  filterConditions: BroadcastFilterCondition[],
  conditionType: BroadcastFilterConditionType
) {
  return (
    filterConditions.find(cond => cond.conditionType === conditionType) ?? null
  );
}

export const isPersistedBroadcast = (
  broadcast: CurrentBroadcast | null
): broadcast is Readonly<APIBroadcastAttributes> => {
  if (broadcast === null) return false;
  return Boolean((broadcast as APIBroadcastAttributes)._id);
};

export const isRunningBroadcast = ({ status }: APIBroadcastAttributes) => {
  return (
    status === BroadcastStatus.RUNNING || status === BroadcastStatus.WARNING
  );
};

export const findBroadcast = (
  broadcasts: APIBroadcastAttributes[],
  broadcastId: ArdoqId | null
) => {
  return broadcasts.find(broadcast => broadcast._id === broadcastId) ?? null;
};

export const doesCurrentBroadcastHaveUnsavedProgress = ({
  broadcasts,
  currentBroadcast,
}: Pick<BroadcastStreamShape, 'broadcasts' | 'currentBroadcast'>) => {
  if (!isPersistedBroadcast(currentBroadcast)) return true;
  const persistedCurrentBroadcast = findBroadcast(
    broadcasts,
    currentBroadcast._id
  );
  return !isEqual(currentBroadcast, persistedCurrentBroadcast);
};

export const mayCurrentBroadcastBeOverwritten = ({
  broadcasts,
  currentBroadcast,
}: Pick<BroadcastStreamShape, 'broadcasts' | 'currentBroadcast'>) => {
  if (currentBroadcast === null) return true;
  if (
    !isPersistedBroadcast(currentBroadcast) &&
    isEqual(
      omit(currentBroadcast, 'scheduling.startDate'),
      omit(createNewBroadcast(), 'scheduling.startDate')
    )
  ) {
    return true;
  }
  return !doesCurrentBroadcastHaveUnsavedProgress({
    broadcasts,
    currentBroadcast,
  });
};

export const withAddedOrUpdatedBroadcast = (
  broadcasts: APIBroadcastAttributes[],
  broadcast: APIBroadcastAttributes
) => {
  const currentIndex = broadcasts.findIndex(({ _id }) => _id === broadcast._id);
  if (currentIndex === -1) {
    return [...broadcasts, broadcast];
  }
  return [
    ...broadcasts.slice(0, currentIndex),
    broadcast,
    ...broadcasts.slice(currentIndex + 1),
  ];
};

export const withoutBroadcast = (
  broadcasts: APIBroadcastAttributes[],
  broadcastId: ArdoqId
) => {
  return broadcasts.filter(({ _id }) => _id !== broadcastId);
};

export const getFormattedDate = (date: string) =>
  format(parseDate(date), 'PP', { locale: getCurrentLocale() });

const getDefaultMessageSubject = (contentType: BroadcastContentType) => {
  return contentType === BroadcastContentType.SURVEY
    ? DEFAULT_SURVEY_BROADCAST_MESSAGE.subject
    : contentType === BroadcastContentType.REPORT
      ? DEFAULT_REPORT_BROADCAST_MESSAGE.subject
      : DEFAULT_MESSAGE_BROADCAST_MESSAGE.subject;
};

const getDefaultMessageBody = (contentType: BroadcastContentType) => {
  return contentType === BroadcastContentType.SURVEY
    ? DEFAULT_SURVEY_BROADCAST_MESSAGE.body
    : contentType === BroadcastContentType.REPORT
      ? DEFAULT_REPORT_BROADCAST_MESSAGE.body
      : DEFAULT_MESSAGE_BROADCAST_MESSAGE.body;
};

const getNextMessageSubjectOnContentTypeChange = (
  currentContentType: BroadcastContentType,
  nextContentType: BroadcastContentType,
  currentSubject: string
) => {
  if (currentSubject === getDefaultMessageSubject(currentContentType)) {
    return getDefaultMessageSubject(nextContentType);
  }
  return currentSubject;
};

const getNextMessageBodyOnContentTypeChange = (
  currentContentType: BroadcastContentType,
  nextContentType: BroadcastContentType,
  currentBody: string
) => {
  if (currentBody === getDefaultMessageBody(currentContentType)) {
    return getDefaultMessageBody(nextContentType);
  }
  return currentBody;
};

export const getNextMessageOnContentTypeChange = (
  currentContentType: BroadcastContentType,
  nextContentType: BroadcastContentType,
  currentMessage: BroadcastMessage
) => {
  return {
    subject: getNextMessageSubjectOnContentTypeChange(
      currentContentType,
      nextContentType,
      currentMessage.subject
    ),
    sender: currentMessage.sender,
    replyToArdoq: currentMessage.replyToArdoq,
    body: getNextMessageBodyOnContentTypeChange(
      currentContentType,
      nextContentType,
      currentMessage.body
    ),
    companyLogo: currentMessage.companyLogo,
  };
};

const getMostTargetedScope = (...scopes: BroadcastScope[]) => {
  if (scopes.includes(BroadcastScope.SOME)) return BroadcastScope.SOME;
  return BroadcastScope.ALL;
};

export const isTargetedSource = (source: BroadcastAudienceType) => {
  return [
    BroadcastAudienceType.GREMLIN,
    BroadcastAudienceType.PREDEFINED,
  ].includes(source);
};

export const isGeneralSource = (source: BroadcastAudienceType) => {
  return [
    BroadcastAudienceType.EMAIL,
    BroadcastAudienceType.WORKSPACE,
  ].includes(source);
};

const withoutGeneralSourcesIfTargetedSourceExists = (
  sources: BroadcastAudienceType[]
) => {
  if (sources.some(isTargetedSource)) return sources.filter(isTargetedSource);
  return sources;
};

export const rowHasSamePersonAs =
  ({ person: person1 }: AudiencePreviewRow) =>
  ({ person: person2 }: AudiencePreviewRow) => {
    if (!person1.email && !person2.email) {
      return person1.name === person2.name;
    }
    return person1.email === person2.email;
  };

export const mergeAudiencePreviewRowsByPerson = (
  audiencePreview: AudiencePreview
) => {
  return audiencePreview.reduce<AudiencePreview>(
    (mergedAudiencePreview, audiencePreviewRow) => {
      const existingRowForPerson = mergedAudiencePreview.find(
        rowHasSamePersonAs(audiencePreviewRow)
      );
      if (!existingRowForPerson) {
        return [...mergedAudiencePreview, audiencePreviewRow];
      }
      const updatedComponentsForPerson = uniqBy(
        [...existingRowForPerson.components, ...audiencePreviewRow.components],
        ({ _id }) => _id
      );
      const updatedScopeForPerson = getMostTargetedScope(
        existingRowForPerson.scope,
        audiencePreviewRow.scope
      );
      const updatedSourcesForPerson =
        withoutGeneralSourcesIfTargetedSourceExists([
          ...existingRowForPerson.sources,
          ...audiencePreviewRow.sources,
        ]);
      const updatedRowForPerson = {
        ...existingRowForPerson,
        components: updatedComponentsForPerson,
        scope: updatedScopeForPerson,
        sources: updatedSourcesForPerson,
      };
      return [
        ...mergedAudiencePreview.filter(row => row !== existingRowForPerson),
        updatedRowForPerson,
      ];
    },
    []
  );
};

export const hasSourceOfType =
  (audienceType: BroadcastAudienceType) =>
  (audiencePreviewRow: AudiencePreviewRow) =>
    audiencePreviewRow.sources.some(source => source === audienceType);

export const getFutureDateISO = (dateString: string, interval: number) => {
  return addDays(parseDate(dateString), interval).toISOString();
};

export const getFutureDate = (dateString: string, interval: number) => {
  const futureDateISO = getFutureDateISO(dateString, interval);
  return getFormattedDate(futureDateISO);
};

export const openBroadcast = (id: ArdoqId, status: BroadcastStatus) => {
  if (status === BroadcastStatus.UNPUBLISHED) {
    dispatchAction(initiateNavigationToEditBroadcastForm(id));
    trackClickedToEditBroadcast(
      BroadcastEditOrigin.BROADCAST_OVERVIEW_TABLE_NAME_CELL
    );
  } else {
    dispatchAction(showBroadcastDetailsDialog(id));
    trackClickedToOpenBroadcastDetailsDialog();
  }
};

export const getDefaultFilterConditionState = () => ({ isExpanded: false });

export const toComponentMappedPreviewAudienceRow = (
  previewAudienceRow: LightAudiencePreviewRow,
  previewAudienceComponents: Record<ArdoqId, BroadcastComponent>
): AudiencePreviewRow => {
  const updatedComponents =
    previewAudienceRow.scope === BroadcastScope.ALL
      ? Object.values(previewAudienceComponents)
      : previewAudienceRow.componentIds.map(
          componentId => previewAudienceComponents[componentId]
        );
  return {
    ...previewAudienceRow,
    components: updatedComponents,
  };
};

export const toComponentMappedPreviewAudience = (
  apiAudiencePreview: APIAudiencePreviewResponse
): AudiencePreview => {
  const mappedComponents = toByIdDictionary(apiAudiencePreview.components);
  return apiAudiencePreview.audience.map(audiencePreviewRow =>
    toComponentMappedPreviewAudienceRow(audiencePreviewRow, mappedComponents)
  );
};

/**
 * If an audience entry has scope `ALL`, the components array will be empty, and must be fetched from the instance
 */
export const broadcastInstanceAudienceWithAllScopedComponentsFilled = (
  instance: BroadcastInstance
): BroadcastInstance => {
  return {
    ...instance,
    audience: instance.audience.map(audiencePreviewRow => ({
      ...audiencePreviewRow,
      components:
        audiencePreviewRow.scope === BroadcastScope.ALL
          ? (instance.components ?? [])
          : audiencePreviewRow.components,
    })),
  };
};
