import { MouseEvent } from 'react';
import { EntityWrapper, Info } from './atoms';
import { dispatchAction } from '@ardoq/rxbeach';
import { restoreComponent } from '../component.actions';
import { getButtonText, isRestored } from '../utils';
import ComponentParentInfo from './ComponentParentInfo';
import ComponentRelatedEntitiesCount from './ComponentRelatedEntitiesCount';
import RestoreError from './RestoreError';
import { SmallSecondaryButton } from '@ardoq/button';
import { TextOverflow } from '@ardoq/popovers';
import { Label } from '@ardoq/forms';
import { DeletedComponentProperties } from './types';
import { DeletedComponent as DeletedComponentType } from 'restoreDeleted/types';
import { FlexBox } from '@ardoq/layout';

const handleRestore = (component: DeletedComponentType) => (e: MouseEvent) => {
  e.stopPropagation();
  dispatchAction(restoreComponent({ component }));
};

const DeletedComponent = ({ component }: DeletedComponentProperties) => {
  return (
    <EntityWrapper>
      <Info>
        <Label width={'450px'}>
          <TextOverflow>{component.name}</TextOverflow>
        </Label>
        <ComponentParentInfo text={component.parentName} />
        <ComponentRelatedEntitiesCount
          count={component.descendants.length}
          text="child components"
        />
        <ComponentRelatedEntitiesCount
          count={component.references.length}
          text="references"
        />
      </Info>
      <FlexBox align="center">
        <RestoreError isVisible={Boolean(component.hasError)} />
        <SmallSecondaryButton
          onClick={handleRestore(component)}
          isDisabled={isRestored(component)}
        >
          {getButtonText(isRestored(component))}
        </SmallSecondaryButton>
      </FlexBox>
    </EntityWrapper>
  );
};

export default DeletedComponent;
