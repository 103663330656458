import styled from 'styled-components';
import { Space } from '@ardoq/style-helpers';
import { header2 } from '@ardoq/typography';
import { RegionsSelector } from 'integrations/cloudProviders/components/regionSelector/RegionSelector';
import { LabelWithPopoverIcon } from '../../../common/components/LabelWithPopoverIcon/LabelWithPopoverIcon';

type SelectRegionsProps = {
  regions: any[];
  onSelect: (ids: string[]) => void;
  selectedRegionIds: string[];
  isDisabled: boolean;
  label: string;
  helperText: string;
};

export const SelectRegions = ({
  regions,
  selectedRegionIds,
  isDisabled = false,
  onSelect,
  label,
  helperText,
  ...props
}: SelectRegionsProps) => {
  return (
    <SelectContainer {...props}>
      <SelectLabel isDisabled={isDisabled}>2. {label}</SelectLabel>
      <RegionsSelector
        selectedRegionIds={selectedRegionIds}
        regions={regions}
        onSelect={onSelect}
        isDisabled={isDisabled}
        helperText={helperText}
      />
    </SelectContainer>
  );
};

const SelectContainer = styled(Space).attrs({
  $gap: 's8',
  $isVertical: true,
})`
  max-width: 540px;
`;

const SelectLabel = styled(LabelWithPopoverIcon)`
  ${header2}
`;
