import DynamicFilterLegendEntry from 'components/dynamicFilters/DynamicFilterLegendEntry';
import { connect } from '@ardoq/rxbeach';
import {
  fetchDynamicFilterOptions,
  selectDynamicFilterOption,
  toggleDynamicFilterActive,
  viewModel$,
} from 'services/dynamicFilterServiceTC';
import { NoGraphFiltersActionButton } from 'quickPerspectives/Atoms';
import { CompleteDynamicFilterModel } from '@ardoq/data-model';

interface DynamicFilterLegendProps {
  dynamicFilters: CompleteDynamicFilterModel[];
}
const DynamicFilterLegend = ({ dynamicFilters }: DynamicFilterLegendProps) => {
  if (!dynamicFilters.length) {
    return <NoGraphFiltersActionButton />;
  }

  return (
    <div>
      {dynamicFilters.map(
        ({
          _id,
          name,
          supportedParams,
          parameterQueryId,
          filterState,
          options,
        }) => (
          <DynamicFilterLegendEntry
            key={_id}
            id={_id}
            name={name}
            supportedParams={supportedParams}
            filterState={filterState}
            parameterQueryId={parameterQueryId}
            parameterOptions={options}
            toggleDynamicFilterActive={toggleDynamicFilterActive}
            selectDynamicFilterOption={selectDynamicFilterOption}
            fetchDynamicFilterOptions={fetchDynamicFilterOptions}
          />
        )
      )}
    </div>
  );
};

export default connect(DynamicFilterLegend, viewModel$);
