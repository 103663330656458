import { reducedStream, reducer, streamReducer } from '@ardoq/rxbeach';
import {
  AddNewZoneGroupPermissionActionPayload,
  ChangeZoneGroupPermissionActionPayload,
  DeleteZoneGroupPermissionActionPayload,
  UpdateFieldsInComponentTypeInZoneActionPayload,
  ZonesViewState,
} from './types';
import { APIResourcePermissionAttributes, ArdoqId } from '@ardoq/api-types';
import {
  SelectAllFieldsPayload,
  addNewZoneGroupPermission,
  changeFieldsConfigInComponentTypeInZone,
  changeZoneGroupPermission,
  changeZoneName,
  deleteZoneGroupPermission,
  deselectAllFields,
  resetEditingZoneName,
  selectAllFields,
  setEditingZoneName,
  updateComponentTypeFieldsGroupSearchBy,
  updateComponentTypeFieldsGroupSearchKey,
} from './actions';
import { zonesViewStateOperations } from './operations';
import permissions$ from '../permissions$/permissions$';
import { map } from 'rxjs/operators';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import subdivisionNavigation$ from 'subdivisionEditor/navigation/subdivisionNavigation$';
import { SubdivisionsStreamShape } from 'streams/subdivisions/types';
import { SubdivisionNavigationState } from 'subdivisionEditor/navigation/types';
import { combineLatest } from 'rxjs';
import { resetStepStates } from 'subdivisionEditor/subdivisionEditorViewModel$/actions';
import { SearchByTypes } from '../components/ComponentTypeFieldsGroupSearch';

const defaultState: ZonesViewState = {
  permissionsByZoneId: {},
  zonesById: {},
  sortedZonesIds: [],
  componentTypeFieldsGroupSearch: {
    searchKey: '',
    searchBy: SearchByTypes.COMPONENT_TYPE,
  },
  editingZoneNames: {},
};

const resetViewState = (): ZonesViewState => defaultState;

const reducers = [
  streamReducer<
    ZonesViewState,
    Record<ArdoqId, APIResourcePermissionAttributes>
  >(
    permissions$.pipe(map(({ permissionsByZoneId }) => permissionsByZoneId)),
    zonesViewStateOperations.initializePermissionsByZoneIdState
  ),
  reducer<ZonesViewState, AddNewZoneGroupPermissionActionPayload>(
    addNewZoneGroupPermission,
    zonesViewStateOperations.addNewGroupToZonePermission
  ),
  reducer<ZonesViewState, ChangeZoneGroupPermissionActionPayload>(
    changeZoneGroupPermission,
    zonesViewStateOperations.updatePermissionLevelsForGroupInZonePermission
  ),
  reducer<ZonesViewState, DeleteZoneGroupPermissionActionPayload>(
    deleteZoneGroupPermission,
    zonesViewStateOperations.removeGroupFromZonePermission
  ),
  streamReducer<
    ZonesViewState,
    [SubdivisionsStreamShape, SubdivisionNavigationState]
  >(
    combineLatest([subdivisions$, subdivisionNavigation$]),
    zonesViewStateOperations.initializeZonesInSubdivision
  ),
  reducer<ZonesViewState, string>(
    updateComponentTypeFieldsGroupSearchKey,
    zonesViewStateOperations.updateComponentTypeFieldsGroupSearchKey
  ),
  reducer<ZonesViewState, SearchByTypes>(
    updateComponentTypeFieldsGroupSearchBy,
    zonesViewStateOperations.updateComponentTypeFieldsGroupSearchBy
  ),
  reducer<ZonesViewState, UpdateFieldsInComponentTypeInZoneActionPayload>(
    changeFieldsConfigInComponentTypeInZone,
    zonesViewStateOperations.updateFieldsInComponentTypeInZone
  ),
  reducer<ZonesViewState, SelectAllFieldsPayload>(
    selectAllFields,
    zonesViewStateOperations.selectAllFieldsReducer
  ),
  reducer<ZonesViewState, SelectAllFieldsPayload>(
    deselectAllFields,
    zonesViewStateOperations.deselectAllFieldsReducer
  ),
  reducer(resetStepStates, resetViewState),
  reducer(changeZoneName, zonesViewStateOperations.changeZoneName),
  reducer(setEditingZoneName, zonesViewStateOperations.setEditingZoneName),
  reducer(resetEditingZoneName, zonesViewStateOperations.resetEditingZoneName),
];

const zonesViewState$ = reducedStream(
  'zonesViewState$',
  defaultState,
  reducers
);

export default zonesViewState$;
