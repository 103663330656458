import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs';
import { loadedState$ } from './loadedState$';
import {
  ArdoqId,
  LoadedState,
  LoadedStateType,
  Path,
  StartContextSelectionType,
  StartSetTraversalParams,
  TraversalCreatedInViewLoadedState,
  TraversalCreatedInViewLoadedStateParams,
  TraversalLoadedStateParams,
  TraversalPathMatchingType,
} from '@ardoq/api-types';
import { loadedStateOperations } from './loadedStateOperations';
import {
  deleteLoadedState,
  loadedStateUpdated,
  registerLoadedState,
  setTraversalContextComponent,
  toggleLoadedStateVisibility,
} from './actions';
import { buildTraversalState, isError, TraversalError } from './buildState';
import { NamedDirectedTriple } from '../viewpointBuilder/types';
import { detailsDrawerState$ } from '../appLayout/ardoqStudio/detailsDrawer/detailsDrawer$';
import { toggleTripleInView } from '../appLayout/ardoqStudio/detailsDrawer/actions';
import { logError } from '@ardoq/logging';
import { showToast, ToastType } from '@ardoq/status-ui';
import {
  setStartTypeAndTraversalStartSetOrCount,
  setTraversals,
} from 'viewpointBuilder/traversals/editTraversalActions';
import { namedDirectedTripleToBackendFormat } from 'viewpointBuilder/traversals/namedDirectedTripleToBackendFormat';
import { editTraversals$ } from '../viewpointBuilder/traversals/editTraversals$';
import { pick, isEqual, uniqWith, sortBy } from 'lodash';
import { scopeDataLoaded } from '../traversals/stagedLoadedDataAndState$';
import { getPathsFromStartSetToSelectedComponents } from '../viewpointBuilder/getPathsFromStartSetToSelectedComponents';
import { componentInterface } from '../modelInterface/components/componentInterface';
import { referenceInterface } from '../modelInterface/references/referenceInterface';
import graphModel$ from 'modelInterface/graphModel$';

const buildAndReplaceTraversalState = async (
  loadedStateParams:
    | TraversalCreatedInViewLoadedStateParams
    | TraversalLoadedStateParams,
  existingHash: string
) => {
  const loadedGraphOrError = await buildTraversalState(loadedStateParams.data);
  if (isError(loadedGraphOrError)) {
    logAndDisplayError(loadedGraphOrError);
    return;
  }

  const updatedLoadedState = loadedStateOperations.attachLoadedGraph(
    loadedStateParams,
    loadedGraphOrError
  );

  dispatchAction(
    scopeDataLoaded({
      trackingLocation: 'componentDrawerTripleToggler',
      scopeData: loadedGraphOrError.scopeData,
    })
  );

  dispatchAction(
    loadedStateUpdated({
      existingHash,
      loadedState: updatedLoadedState,
    })
  );
};

const handleToggleTripleOption = routine(
  ofType(toggleTripleInView),
  extractPayload(),
  withLatestFrom(loadedState$, detailsDrawerState$),
  tap(
    async ([
      { namedDirectedTriple, isSelected: isTripleSelected },
      loadedStates,
      { componentId: selectedComponentId },
    ]) => {
      if (!selectedComponentId) {
        return;
      }
      const toggledTripleInBackendFormat =
        namedDirectedTripleToBackendFormat(namedDirectedTriple);

      // This view only works with a single triple in/out of the selected
      // component, so we generalize it to a path to work at a higher level.
      const toggledPath: Path = [toggledTripleInBackendFormat];

      if (isTripleSelected) {
        await addTripleToLoadedState(
          loadedStates,
          selectedComponentId,
          toggledTripleInBackendFormat // TODO(sseppola): Use toggledPath
        );
      } else {
        await removePathFromLoadedState(
          loadedStates,
          selectedComponentId,
          toggledPath
        );
      }
    }
  )
);

const logAndDisplayError = (
  componentIdsAndReferencesOrError: TraversalError
) => {
  logError(
    new Error(componentIdsAndReferencesOrError.error),
    'Could not build traversal state for traversal created in the view.'
  );
  showToast('Failed to extend the graph', ToastType.INFO);
};

const updateLoadedStateToIncludeSelectedTriple = async (
  existingLoadedStateForSelectedComponent: TraversalCreatedInViewLoadedState,
  pathWithSelectedTriple: Path
) => {
  const existingHash = loadedStateOperations.stateToHash(
    existingLoadedStateForSelectedComponent
  );
  const params = loadedStateOperations.appendPathToTraversalParams(
    existingLoadedStateForSelectedComponent,
    pathWithSelectedTriple
  );

  const loadedGraphOrError = await buildTraversalState(params.data);

  if (isError(loadedGraphOrError)) {
    logAndDisplayError(loadedGraphOrError);
    return;
  }

  dispatchAction(
    scopeDataLoaded({
      trackingLocation: 'componentDrawerTripleToggler',
      scopeData: loadedGraphOrError.scopeData,
    })
  );
  const loadedState = loadedStateOperations.attachLoadedGraph(
    params,
    loadedGraphOrError
  );
  dispatchAction(loadedStateUpdated({ existingHash, loadedState }));
};

const createNewTraversalCreatedInViewLoadedStateWithSelectedTriple = async (
  selectedComponentId: ArdoqId,
  pathsWithSelectedTriple: Path
) => {
  const traversal: StartSetTraversalParams = {
    componentSelection: {
      startSet: [selectedComponentId],
      startContextSelectionType: StartContextSelectionType.MANUAL_SELECTION,
    },
    paths: [pathsWithSelectedTriple],
    filters: {},
    pathMatching: TraversalPathMatchingType.LOOSE,
    pathCollapsingRules: [],
  };
  const loadedGraphOrError = await buildTraversalState(traversal);
  if (isError(loadedGraphOrError)) {
    logAndDisplayError(loadedGraphOrError);
    return;
  }

  dispatchAction(
    scopeDataLoaded({
      trackingLocation: 'componentDrawerTripleToggler',
      scopeData: loadedGraphOrError.scopeData,
    })
  );
  const newTraversal: TraversalCreatedInViewLoadedState = {
    isHidden: false,
    type: LoadedStateType.TRAVERSAL_CREATED_IN_VIEW,
    componentIdsAndReferences: loadedGraphOrError.componentIdsAndReferences,
    scopeData: loadedGraphOrError.scopeData,
    data: traversal,
  };

  dispatchAction(registerLoadedState([newTraversal]));
};

const addTripleToLoadedState = async (
  loadedStates: LoadedState[],
  selectedComponentId: ArdoqId,
  namedDirectedTriple: NamedDirectedTriple
) => {
  const pathWithSelectedTriple: Path = [
    {
      ...namedDirectedTriple,
      referenceFilter: undefined,
      targetFilter: undefined,
      sourceFilter: undefined,
    },
  ];
  const existingTraversalCreatedInViewLoadedStateForSelectedComponent =
    loadedStates.find(loadedState =>
      loadedStateIsTraversalCreatedInViewForSelectedComponent(
        loadedState,
        selectedComponentId
      )
    );

  if (existingTraversalCreatedInViewLoadedStateForSelectedComponent) {
    await updateLoadedStateToIncludeSelectedTriple(
      existingTraversalCreatedInViewLoadedStateForSelectedComponent,
      pathWithSelectedTriple
    );
  } else {
    await createNewTraversalCreatedInViewLoadedStateWithSelectedTriple(
      selectedComponentId,
      pathWithSelectedTriple
    );
  }
};

const loadedStateIsTraversalCreatedInViewForSelectedComponent = (
  loadedState: LoadedState,
  selectedComponentId: string
): loadedState is TraversalCreatedInViewLoadedState => {
  return (
    !loadedState.isHidden &&
    loadedState.type === LoadedStateType.TRAVERSAL_CREATED_IN_VIEW &&
    loadedState.data.componentSelection.startSet.includes(selectedComponentId)
  );
};

const removePathFromLoadedState = async (
  loadedStates: LoadedState[],
  selectedComponentId: ArdoqId,
  pathToRemove: Path
) => {
  const selectedLoadedState = loadedStates.find(loadedState =>
    loadedStateIsTraversalCreatedInViewForSelectedComponent(
      loadedState,
      selectedComponentId
    )
  );

  if (!selectedLoadedState) {
    return;
  }

  const oldHashToRemove =
    loadedStateOperations.stateToHash(selectedLoadedState);

  const loadedStateParams = loadedStateOperations.removePath(
    selectedLoadedState,
    pathToRemove
  );

  if (loadedStateParams.data.paths.length === 0) {
    dispatchAction(deleteLoadedState(oldHashToRemove));
    return;
  }

  await buildAndReplaceTraversalState(loadedStateParams, oldHashToRemove);
};

const handleLoadedStateChanged = routine(
  ofType(
    toggleLoadedStateVisibility,
    deleteLoadedState,
    setTraversalContextComponent,
    registerLoadedState
  ),
  withLatestFrom(
    loadedState$,
    detailsDrawerState$,
    editTraversals$,
    graphModel$
  ),
  tap(
    async ([
      ,
      loadedStates,
      { componentId, scopeData },
      editTraversalState,
      graphModel,
    ]) => {
      const selectedComponent =
        componentId && scopeData?.componentsById[componentId];
      if (!selectedComponent) {
        return;
      }

      const pathsFromStartSetToSelectedComponents =
        getPathsFromStartSetToSelectedComponents({
          loadedStates,
          selectedComponentId: selectedComponent._id,
          graphModel,
          getComponentTypeName: componentInterface.getTypeName,
          getReferenceTypeName: (id: ArdoqId) =>
            referenceInterface.getReferenceTypeNameByReferenceId(id),
        });

      const paths =
        loadedStateOperations.getPathsForSelectedComponentTypeFromTraversalsContainingComponentId(
          loadedStates,
          selectedComponent._id,
          pathsFromStartSetToSelectedComponents
        );
      const pathsFromLoadedStateStates = flattenCleanUpAndSortPaths(paths);

      const pathsInEditTraversalState = flattenCleanUpAndSortPaths(
        editTraversalState.stateAsSavableAttributes.paths
      );

      if (!isEqual(pathsInEditTraversalState, pathsFromLoadedStateStates)) {
        dispatchAction(
          setStartTypeAndTraversalStartSetOrCount({
            startType: selectedComponent.type,
            traversalStartSet: [selectedComponent._id],
          })
        );
        dispatchAction(setTraversals({ paths, filters: null }));
      }
    }
  )
);

const flattenCleanUpAndSortPaths = (paths: Path[]) => {
  return sortBy(
    uniqWith(
      paths
        .flatMap(it => it)
        .map(it =>
          pick(it, ['sourceType', 'targetType', 'referenceType', 'direction'])
        ),
      isEqual
    ),
    ['sourceType', 'targetType', 'referenceType', 'direction']
  );
};

export const loadedStateRoutines = collectRoutines(
  handleToggleTripleOption,
  handleLoadedStateChanged
);
