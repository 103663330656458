import { CircleIcon, IconSize } from '@ardoq/icons';
import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '@ardoq/layout';
import { Header3, Paragraph } from '@ardoq/typography';
import { colors } from '@ardoq/design-tokens';
import { capitalize } from '@ardoq/common-helpers';

const StatusInfoContainer = styled(FlexBox)<{ $withBorder?: boolean }>`
  width: 220px;
  border-bottom: ${props =>
    props.$withBorder ? `1px solid ${colors.borderAction}` : 'none'};
`;

const statusToColor = {
  success: colors.iconSuccess,
  error: colors.iconError,
};

type StatusInfoProps = {
  status: 'success' | 'error';
  count: number;
};

const StatusInfo = ({ status, count }: StatusInfoProps) => {
  return (
    <StatusInfoContainer
      justify="space-between"
      align="center"
      padding="small"
      $withBorder={status === 'success'}
    >
      <FlexBox gap="small" align="center">
        <CircleIcon iconSize={IconSize.SMALL} color={statusToColor[status]} />
        <Paragraph variant="text2" color="textSubtle" align="center">
          {capitalize(status)}
        </Paragraph>
      </FlexBox>
      <Header3>{count}</Header3>
    </StatusInfoContainer>
  );
};

export default StatusInfo;
