import { SurveyApprovalAudienceType } from '@ardoq/api-types';
import { RadioGroup, GroupFieldLayout, RadioGroupItemType } from '@ardoq/forms';
import { isAudienceType } from '../utils';

type AudienceRadioGroupProps = {
  selectedAudienceType: SurveyApprovalAudienceType;
  handleSelectedAudienceChange: (
    audienceType: SurveyApprovalAudienceType
  ) => void;
};

const AudienceRadioGroup = ({
  selectedAudienceType,
  handleSelectedAudienceChange,
}: AudienceRadioGroupProps) => {
  return (
    <RadioGroup
      groupFieldLayout={GroupFieldLayout.HORIZONTAL}
      groupItemType={RadioGroupItemType.CARD}
      defaultValue="relationships"
      value={selectedAudienceType}
      onValueChange={newAudienceType => {
        if (newAudienceType === selectedAudienceType) return;
        if (!isAudienceType(newAudienceType)) return;
        handleSelectedAudienceChange(newAudienceType);
      }}
      options={[
        {
          label: 'By user or group',
          value: 'usersAndGroups',
          helperText:
            'All selected users or group members can approve or reject survey responses',
        },
        {
          label: 'By direct relationship',
          value: 'relationships',
          helperText:
            'Set approvers that have a direct reference to the component (For example "is expert in")',
        },
        {
          label: 'By advanced relationship',
          value: 'traversal',
          helperText:
            'Set approvers that have an indirect or multi-step reference to the component',
        },
      ]}
    />
  );
};

export default AudienceRadioGroup;
