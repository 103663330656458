import GremlinResults from 'search/Gremlin/GremlinResults/GremlinResults';
import { connect } from '@ardoq/rxbeach';
import { GremlinSearchStateShape } from 'search/Gremlin/types';
import { broadcastAudienceQuery$ } from 'search/Gremlin/gremlinResults$';
import { map } from 'rxjs/operators';
import { LazyLoadResults } from '@ardoq/pagination';
import styled from 'styled-components';
import { GremlinResult } from 'search/Gremlin/GremlinResults/types';
import { isArray } from 'lodash';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { Stack } from '@ardoq/layout';
import { spacing } from '@ardoq/design-tokens';

const GremlinResultsWrapper = styled(Stack)`
  margin-top: ${spacing.s8};
  overflow: hidden;
  height: 100%;
  > div {
    overflow: hidden;
  }
`;

type GremlinQueryResultsStreamedProps = Pick<
  GremlinSearchStateShape,
  'results' | 'totalResults' | 'showRawResults'
>;

type GremlinQueryResultsOwnProps = {
  readonly resultsId: string;
  readonly loadResults: LazyLoadResults;
};

type GremlinAudienceDialogStreamedProps = {
  readonly tableFilter: string;
};

const getFilteredResult = (
  result: GremlinResult[] | null,
  postFilter: string
) => {
  const filterLowered = postFilter.toLowerCase();
  const res = result
    ? result.filter(res => {
        const resultObjects = res.objects;
        if (resultObjects && isArray(resultObjects)) {
          const objectLabels = resultObjects.map(obj => obj.label);
          const maybeNames = resultObjects
            .flatMap(obj => obj.properties?.name || []) // Not all objects have names
            .map(nameObj => nameObj.value)
            .filter(ExcludeFalsy);
          return objectLabels.some(
            label =>
              label.toLowerCase().includes(filterLowered) ||
              maybeNames.some(name =>
                name?.toLowerCase().includes(filterLowered)
              )
          );
        }
        return false;
      })
    : undefined;
  return res;
};

const GremlinQueryResultsTable = ({
  results,
  totalResults,
  showRawResults,
  resultsId,
  loadResults,
  tableFilter,
}: GremlinQueryResultsStreamedProps &
  GremlinQueryResultsOwnProps &
  GremlinAudienceDialogStreamedProps) => {
  const postFilteredResult = tableFilter
    ? getFilteredResult(results, tableFilter)
    : results;
  const totalPostFilteredResults =
    postFilteredResult?.length || totalResults || 0;

  return (
    <GremlinResultsWrapper>
      <GremlinResults
        result={postFilteredResult ?? undefined}
        showRaw={showRawResults}
        loadResults={loadResults}
        totalResults={totalPostFilteredResults}
        resultsId={resultsId}
        printable
        usePagination
        style={{ overflowY: 'auto', height: '100%' }}
      />
    </GremlinResultsWrapper>
  );
};

const gremlinQueryResultsTable$ = broadcastAudienceQuery$.pipe(
  map(broadcastAudienceQueryState => broadcastAudienceQueryState)
);

export default connect(GremlinQueryResultsTable, gremlinQueryResultsTable$);
