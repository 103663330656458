import { actionCreator } from '@ardoq/rxbeach';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { TableMappingConstraints } from './types';

const NAMESPACE = '[integrations] TABULAR_MAPPING_CONSTRAINTS';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export type setTableMappingConstraintsPayload = {
  integrationId: IntegrationId;
  tableId: string;
  tableMappingConstraints: TableMappingConstraints;
};

export const setTableMappingConstraints =
  actionCreator<setTableMappingConstraintsPayload>(
    getActionName('SET_TABLE_MAPPING_CONSTRAINTS')
  );

export type resetTabularMappingConstraintsPayload = {
  integrationId: IntegrationId;
};

export const resetTabularMappingConstraints =
  actionCreator<resetTabularMappingConstraintsPayload>(
    getActionName('RESET_TABULAR_MAPPING_CONSTRAINTS')
  );
