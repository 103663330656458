import {
  applyTabularMappingsDefault,
  resetTabularMappingsDefault,
} from './actions';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { defaultTabularMapping$ } from './tabularMappingsDefault$';
import { tablePreviews$ } from '../tablePreviews/getTablePreviewsStream';
import {
  applyTableMapping,
  setTableMappingType,
} from '../tabularMappings/actions';
import { tabularMappings$ } from '../tabularMappings/getTabularMappingStream';
import { isEmpty } from 'lodash';
import { activeIntegrations$ } from '../activeIntegrations/activeIntegrations$';
import { ColumnMappingComponentsMap } from '../tabularMappings/types';
import { resetIntegration } from '../activeIntegrations/actions';

const handleResetIntegration = routine(
  ofType(resetIntegration),
  extractPayload(),
  tap(integrationId => {
    dispatchAction(resetTabularMappingsDefault({ integrationId }));
  })
);

const handleApplyTabularMappingsDefault = routine(
  ofType(applyTabularMappingsDefault),
  extractPayload(),
  withLatestFrom(
    defaultTabularMapping$,
    tablePreviews$,
    tabularMappings$,
    activeIntegrations$
  ),
  map(
    ([
      { integrationId },
      allDefaultTabularMapping,
      allTablePreviews,
      allTabularMapping,
      allActiveIntegrations,
    ]) => ({
      integrationId,
      defaultTabularMapping: allDefaultTabularMapping[integrationId],
      tablePreviews: allTablePreviews[integrationId],
      tabularMapping: allTabularMapping[integrationId],
      activeIntegration: allActiveIntegrations[integrationId],
    })
  ),
  filter(({ defaultTabularMapping, tabularMapping, activeIntegration }) => {
    const isDefaultMappingExist = !isEmpty(defaultTabularMapping);
    const isMappingExist =
      Object.keys(tabularMapping).some(
        tableId => !isEmpty(tabularMapping[tableId])
      ) ||
      activeIntegration.selectedMappingConfigId ||
      activeIntegration.selectedTransferConfigId;

    return !isMappingExist && isDefaultMappingExist;
  }),
  tap(
    ({
      integrationId,
      tabularMapping,
      tablePreviews,
      defaultTabularMapping,
    }) => {
      Object.keys(tablePreviews).forEach(tableId => {
        if (!tabularMapping[tableId]?.rowRepresentation) {
          const defaultRowRepresentation =
            defaultTabularMapping[tableId].defaultRowRepresentation;
          if (defaultRowRepresentation) {
            dispatchAction(
              setTableMappingType({
                integrationId,
                tableId,
                tableType: defaultRowRepresentation,
              })
            );
          }
        }
      });

      Object.keys(tablePreviews).forEach(tableId => {
        const tableDefaultMapping = defaultTabularMapping[tableId];

        const mappedColumns = tablePreviews[
          tableId
        ].sourceFieldNames?.reduce<ColumnMappingComponentsMap>(
          (acc, sourceFieldName, index) => {
            const columnDefaultMapping =
              tableDefaultMapping.defaultColumnMappings &&
              tableDefaultMapping.defaultColumnMappings[sourceFieldName];
            if (columnDefaultMapping) {
              acc[index] = { ...columnDefaultMapping, index };
            }

            return acc;
          },
          {}
        );

        if (!isEmpty(mappedColumns)) {
          dispatchAction(
            applyTableMapping({
              integrationId,
              tableId: tableId,
              tableMapping: { mappedColumns },
            })
          );
        }
      });
    }
  )
);

export default [handleApplyTabularMappingsDefault, handleResetIntegration];
