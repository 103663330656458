import { IntegrationId } from '../tabularMappings/types';
import {
  ServiceNowConnection,
  AzureUpsertConnectionPayload,
  ConnectionRequestPayload,
  Connection,
  ServiceNowUpsertConnectionPayload,
  UnifiedUpsertConnectionPayload,
  AzureConnection,
  AwsConnection,
  ITPediaConnection,
} from '@ardoq/api-types/integrations';
import {
  AwsUpsertConnectionPayload,
  UnifiedConnection,
} from '@ardoq/api-types/integrations';
import { ApiError } from '../../utils/api';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { INTEGRATION_ID_TO_PROVIDER_ID } from 'integrations/unified/constants';
import {
  awsIntegrationApi,
  azureIntegrationApi,
  microsoftGraphIntegrationApi,
  servicenowIntegrationApi,
  unifiedIntegrationApi,
} from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import * as itPediaAccountAPI from 'integrations/ITPedia/api/accounts';
import { serviceNowDictionary } from 'integrations/common/dictionary';

const serviceNowConnectionToPayload = (
  connection: ServiceNowConnection
): ServiceNowUpsertConnectionPayload => {
  if (connection.authenticationType === 'basic-auth') {
    return {
      ...connection,
      authentication: `${connection.username}:${connection.password}`,
    };
  }

  if (connection.authenticationType === 'oauth-jwt-bearer') {
    return {
      ...connection,
      privateKeyPem: connection.privateKeyPem
        .split('\n')
        .map(line => line.trim()),
    };
  }

  return connection;
};

async function upsertServiceNowConnection(
  input: ServiceNowUpsertConnectionPayload
) {
  const { _id, ...body } = input;

  const pingResponse = await servicenowIntegrationApi.pingConnection(input);

  if (pingResponse.status >= 400) {
    throw new ApiError(
      `Unable to ping the ${serviceNowDictionary.name} connection`,
      pingResponse.status,
      pingResponse.statusText,
      {
        readableMessage:
          "We're unable to establish a new connection. Please try connecting again. Contact support if the problem continues.",
      }
    );
  }

  const parsedPingResponse = await pingResponse.json();

  if (parsedPingResponse.status !== 'ok') {
    throw new ApiError(
      'Invalid credentials',
      pingResponse.status,
      pingResponse.statusText,
      {
        readableMessage:
          "We're unable to connect to ServiceNow. Please check your credentials and try connecting again.",
      }
    );
  }

  if (_id) {
    return servicenowIntegrationApi.updateConnection(_id, body);
  }

  return servicenowIntegrationApi.createConnection(body);
}

async function upsertAzureConnection(payload: AzureUpsertConnectionPayload) {
  const pingResponse = await azureIntegrationApi.pingConnection(payload);

  if (isArdoqError(pingResponse) || !pingResponse.valid) {
    throw new ApiError('Invalid credentials', 401, 'Unauthorized', {
      readableMessage: 'Invalid credentials.',
    });
  }

  if (payload._id) {
    return azureIntegrationApi.updateConnection(payload._id, payload);
  }

  return azureIntegrationApi.createConnection(payload);
}

async function upsertAwsConnection(payload: AwsUpsertConnectionPayload) {
  const pingResponse = await awsIntegrationApi.pingConnection(payload);

  if (isArdoqError(pingResponse) || !pingResponse.valid) {
    throw new ApiError('Invalid credentials', 401, 'Unauthorized', {
      readableMessage: 'Invalid credentials.',
    });
  }

  if (payload._id) {
    return awsIntegrationApi.updateConnection(payload._id, payload);
  }

  return awsIntegrationApi.createConnection(payload);
}

export async function upsertUnifiedAccountRequest(
  input: UnifiedUpsertConnectionPayload
) {
  const _id = input._id;
  const body = {
    name: input.name,
    accessKey: input.accessKey,
    secretKey: input.secretKey,
    token: input.token,
    provider: input.provider,
    authMethod: input.authMethod,
    baseUrl: input.baseUrl,
    providerParams: input.providerParams,
  };

  if (_id) {
    return unifiedIntegrationApi.updateConnection(body.provider, _id, body);
  }

  return unifiedIntegrationApi.createConnection(body.provider, body);
}

export async function upsertConnectionRequest(
  integrationId: IntegrationId,
  connection: ConnectionRequestPayload | Connection
) {
  if (integrationId === 'servicenow-v3') {
    const serviceNowConnectionPayload = serviceNowConnectionToPayload(
      connection as ServiceNowConnection
    );
    return upsertServiceNowConnection(serviceNowConnectionPayload);
  }

  if (integrationId === 'azure-v3') {
    return upsertAzureConnection(connection as AzureUpsertConnectionPayload);
  }

  if (integrationId === 'aws-v3') {
    return upsertAwsConnection(connection as AwsUpsertConnectionPayload);
  }

  if (
    isUnifiedIntegrationId(integrationId) ||
    integrationId === 'signavio-exporter'
  ) {
    const unifiedConnection = connection as UnifiedConnection;
    const provider = isUnifiedIntegrationId(integrationId)
      ? INTEGRATION_ID_TO_PROVIDER_ID[integrationId]
      : 'signavio';
    const body: UnifiedUpsertConnectionPayload = {
      _id: unifiedConnection._id,
      name: unifiedConnection.name,
      accessKey: unifiedConnection.accessKey,
      secretKey: unifiedConnection.secretKey,
      token: unifiedConnection.token,
      provider,
      authMethod: unifiedConnection.authMethod,
      baseUrl: unifiedConnection.baseUrl,
      providerParams: unifiedConnection.providerParams,
    };
    return upsertUnifiedAccountRequest(body);
  }

  if (integrationId === 'it-pedia') {
    return itPediaAccountAPI.upsertAccountRequest(
      connection as ITPediaConnection
    );
  }

  throw new Error('Integration is not supported for connections');
}

export async function fetchConnectionsList(integrationId: IntegrationId) {
  if (integrationId === 'servicenow-v3') {
    return servicenowIntegrationApi.fetchConnectionsList();
  }

  if (integrationId === 'azure-v3') {
    return azureIntegrationApi.fetchConnectionsList();
  }

  if (integrationId === 'aws-v3') {
    return awsIntegrationApi.fetchConnectionsList();
  }

  if (integrationId === 'microsoft-entra-id') {
    return microsoftGraphIntegrationApi.fetchConnectionsList();
  }

  if (integrationId === 'signavio-exporter') {
    return unifiedIntegrationApi.fetchConnectionsList('signavio');
  }

  if (isUnifiedIntegrationId(integrationId)) {
    return unifiedIntegrationApi.fetchConnectionsList(
      INTEGRATION_ID_TO_PROVIDER_ID[integrationId]
    );
  }

  if (integrationId === 'it-pedia') {
    return itPediaAccountAPI.fetchAccountsList();
  }

  throw new Error('Integration is not supported for connections');
}

export async function deleteConnection(
  integrationId: IntegrationId,
  connection: Connection
) {
  if (integrationId === 'servicenow-v3') {
    return servicenowIntegrationApi.deleteConnection(
      connection as ServiceNowConnection
    );
  }

  if (integrationId === 'azure-v3') {
    return azureIntegrationApi.deleteConnection(connection as AzureConnection);
  }

  if (integrationId === 'aws-v3') {
    return awsIntegrationApi.deleteConnection(connection as AwsConnection);
  }

  if (integrationId === 'signavio-exporter') {
    return unifiedIntegrationApi.deleteConnection(
      'signavio',
      connection as UnifiedConnection
    );
  }

  if (isUnifiedIntegrationId(integrationId)) {
    return unifiedIntegrationApi.deleteConnection(
      INTEGRATION_ID_TO_PROVIDER_ID[integrationId],
      connection as UnifiedConnection
    );
  }

  if (integrationId === 'it-pedia') {
    return itPediaAccountAPI.deleteAccountRequest(
      connection as ITPediaConnection
    );
  }

  throw new Error('Integration is not supporting connections deletion');
}
