import { startSetEqualsEndSet } from 'viewpoints/utils';
import { PartialConstraint, PartialStep, Validation } from '../../types';
import ConstraintForm from './ConstraintForm';
import { Triple } from '@ardoq/api-types';
import {
  ErrorNotification,
  InfoNotification,
  WarningNotification,
} from '@ardoq/status-ui';

type ConstraintProps = {
  validation?: Validation;
  canDelete: boolean;
  availableTriples: Triple[];
  index: number;
  step: PartialStep;
  constraint: PartialConstraint;
  onChange: (step: PartialStep) => void;
  handleTripleValidate: (
    constraintIndex: number
  ) => (tripleIndex: number) => Validation | undefined;
  isDisabled: boolean;
};

const Constraint = ({
  validation,
  canDelete,
  availableTriples,
  index,
  step,
  constraint,
  onChange,
  handleTripleValidate,
  isDisabled,
}: ConstraintProps) => {
  return (
    <div data-test-id="constraint">
      {validation?.errorMessage && (
        <ErrorNotification>{validation.errorMessage}</ErrorNotification>
      )}
      {validation?.infoMessage && (
        <InfoNotification>{validation.infoMessage}</InfoNotification>
      )}
      {validation?.warningMessage && (
        <WarningNotification>{validation.warningMessage}</WarningNotification>
      )}
      <ConstraintForm
        canDelete={step.constraints.length > 1 || canDelete}
        hasError={validation?.hasError}
        hasWarning={validation?.hasWarning}
        validate={handleTripleValidate(index)}
        constraint={constraint}
        availableTriples={availableTriples}
        onChange={selectedConstraint => {
          const newConstraints = [
            ...step.constraints.slice(0, index),
            selectedConstraint,
            ...step.constraints.slice(index + 1),
          ];
          onChange({
            ...step,
            constraints: newConstraints,
            maxRepetition: startSetEqualsEndSet(newConstraints)
              ? step.maxRepetition
              : 1,
          });
        }}
        onDelete={() => {
          onChange({
            ...step,
            constraints: [
              ...step.constraints.slice(0, index),
              ...step.constraints.slice(index + 1),
            ],
          });
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default Constraint;
