import { useState } from 'react';
import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { TextArea } from '@ardoq/forms';
import { Link, text1 } from '@ardoq/typography';
import { serviceNowDictionary } from 'integrations/common/dictionary';

const Text1 = styled.div`
  ${text1};
`;

type QueryFilterModalProps = {
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  onApplyQueryFilter: (queryFilter: string) => void;
  tableName: string;
  defaultQueryFilter: string;
};

const QueryFilterModal = ({
  onSubmit,
  onCancel,
  onApplyQueryFilter,
  tableName,
  defaultQueryFilter,
}: QueryFilterModalProps) => {
  const [queryFilter, setQueryFilter] = useState<string>(defaultQueryFilter);

  const handleApplyQueryFilter = () => {
    onApplyQueryFilter(queryFilter);
    onSubmit();
  };

  return (
    <ModalTemplate
      modalSize={ModalSize.M}
      headerText={`Query filter table “${tableName}”`}
      primaryButtonText="Apply"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handleApplyQueryFilter}
      onSecondaryButtonClick={onCancel}
    >
      <ModalLayout>
        <Text1>
          Optional filter to narrow down the data fetched from{' '}
          {serviceNowDictionary.name}.
          <br />
          Please have a look at the{' '}
          <Link
            target="_blank"
            href="https://developer.servicenow.com/dev.do#!/learn/courses/vancouver/app_store_learnv2_rest_vancouver_rest_integrations/app_store_learnv2_rest_vancouver_inbound_rest_integrations/app_store_learnv2_rest_vancouver_more_about_query_parameters"
            color={colors.grey15}
            hideIcon={true}
          >
            {serviceNowDictionary.name} documentation
          </Link>{' '}
          for details on how to use the sysparm_query property.
        </Text1>
        <TextArea value={queryFilter} onValueChange={setQueryFilter} />
      </ModalLayout>
    </ModalTemplate>
  );
};

export const startQueryFilterModal = ({
  onApplyQueryFilter,
  tableName,
  queryFilter,
}: {
  onApplyQueryFilter: (queryFilter: string) => void;
  tableName: string;
  queryFilter: string | null;
}) =>
  modal<boolean>(
    resolve => (
      <QueryFilterModal
        onSubmit={() => resolve(true)}
        onCancel={() => resolve(false)}
        onApplyQueryFilter={onApplyQueryFilter}
        tableName={tableName}
        defaultQueryFilter={queryFilter || ''}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
