import { ArdoqId } from '@ardoq/api-types';
import { hasFeature, Features } from '@ardoq/features';
import { openViewpointBuilderCommands } from 'viewpointBuilder/openViewpointBuilderCommands';

export type ViewpointsOverviewCommands = {
  openViewpoint: (viewpointId: ArdoqId) => void;
  createNewViewpoint: () => void;
};

export const viewpointsOverviewCommands: ViewpointsOverviewCommands = {
  openViewpoint: (viewpointId: ArdoqId) => {
    if (hasFeature(Features.SUPPORT_LARGE_DATASETS)) {
      openViewpointBuilderCommands.openViewpointBuilderToSelectStartComponentsForViewpoint(
        viewpointId
      );
    }
  },
  createNewViewpoint: () =>
    openViewpointBuilderCommands.openViewpointBuilderToCreateViewpoint(),
};
