import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  setReportOverviewSearchPhrase,
  setReportOverviewSort,
} from './actions';
import { SortOrder, SortParams } from '@ardoq/api-types';

type ReportOverview$State = {
  searchPhrase: string;
  onlyFailing: boolean;
} & SortParams;

const defaultState: ReportOverview$State = {
  searchPhrase: '',
  onlyFailing: false,
  sortByField: null,
  sortOrder: SortOrder.DEFAULT,
};

const handleSetSearchPhrase = (
  state: ReportOverview$State,
  searchPhrase: string
) => ({
  ...state,
  searchPhrase,
});

const handleSetSort = (
  state: ReportOverview$State,
  { sortByField, sortOrder }: SortParams
) => ({
  ...state,
  sortByField,
  sortOrder,
});

const reportOverview$ = persistentReducedStream<ReportOverview$State>(
  'reportOverview$',
  defaultState,
  [
    reducer(setReportOverviewSearchPhrase, handleSetSearchPhrase),
    reducer(setReportOverviewSort, handleSetSort),
  ]
);

export default reportOverview$;
