import Context from 'context';
import { dispatchAction } from '@ardoq/rxbeach';
import { setMetamodels } from 'streams/metamodels/metamodelActions';
import { isPlainObject } from 'lodash';
import { logError } from '@ardoq/logging';
import {
  EnhancedPresentation,
  PersistedMetamodel,
  APIPresentationAttributes,
  PresentationReadPermissions,
  APIPresentationAssetAttributes,
} from '@ardoq/api-types';
import { CanCreatorMigrateSlidesInfo } from '@ardoq/api';
import getMetaInfo from 'views/metaInfo';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import { isPresentationMode } from 'appConfig';
import * as actions from './actions';
import { APIFieldAttributes } from '@ardoq/api-types/';
import { dateRangeOperations } from '@ardoq/date-range';
import { api, presentationApi } from '@ardoq/api';
import { slideInterface } from 'modelInterface/presentations/slideInterface';

export const loadMetamodels = (
  metamodels: Record<string, PersistedMetamodel>,
  fields: APIFieldAttributes[]
) => {
  if (isPlainObject(metamodels)) {
    const entries = Object.entries(metamodels).map(([k, v]) => ({
      ...dateRangeOperations.mergeDateRangeFieldsOnMetamodel(
        v,
        (workspaceId: string) => {
          const modelId = Object.values(v.metamodel.workspaces).find(
            workspace => workspace.id === workspaceId
          )?.modelId;
          return fields.filter(field => field.model === modelId);
        }
      ),
      _id: k,
    }));
    dispatchAction(setMetamodels(entries));
  }
};

// in some cases (see ARD-13562), slide creator has no full access to slide, so we need to know whether or not
// we should direct the current user to the slide owner or to an admin for slide migration or any other questions
const _getSlideOwnerMigrationPermissions = async (
  presentationId: string,
  slideIds: string[]
) => {
  if (slideIds.length) {
    const response = await presentationApi.migrationStatus(
      presentationId,
      slideIds
    );
    if (api.isUnauthorized(response) && isPresentationMode()) {
      return undefined;
    }
    if (isArdoqError(response)) {
      logError(response, 'Error while fetching slide migration status');
      return undefined;
    }
    return response;
  }
};

const validateMigrationPermissionsResponse = (
  response: CanCreatorMigrateSlidesInfo | undefined,
  deprecatedSlides: string[],
  presentation: APIPresentationAttributes | APIPresentationAssetAttributes
) => {
  // doublecheck the response. Api should've given as true or false for every deprecated slide
  // the purpose of it is a deprecation notification, no need to disrupt anything
  deprecatedSlides.forEach(slideId => {
    if (
      response?.[slideId]?.creatorCanMigrate === undefined &&
      !(
        isPresentationMode() &&
        presentation.readAccess === PresentationReadPermissions.ALL
      )
    ) {
      logError(
        new Error(
          `Slide ID ${slideId} is missing, when requesting from '/api/presentation/${presentation._id}/slide-migration-status'`
        ),
        'Missing slide ID for deprecated slides migration info'
      );
    }
  });
};

export const getSlideOwnerMigrationPermissions = async (
  presentation: APIPresentationAttributes | APIPresentationAssetAttributes,
  slideIds: string[]
) => {
  const response = await _getSlideOwnerMigrationPermissions(
    presentation._id,
    slideIds
  );
  validateMigrationPermissionsResponse(response, slideIds, presentation);
  return response;
};

const getDeprecatedSlidesForPresentation = (
  presentation: APIPresentationAttributes
) =>
  presentation.slides
    ?.map(slideId => {
      const viewId = slideInterface.getViewId(slideId);
      if (!viewId) {
        return null;
      }
      const viewStatus = getMetaInfo().get(viewId)?.viewStatus;
      return viewStatus === MetaInfoViewStatus.SOON_TO_BE_DISCONTINUED ||
        viewStatus === MetaInfoViewStatus.DISCONTINUED
        ? slideId
        : null;
    })
    .filter(ExcludeFalsy);

const getPresentationSlidesMigrationInfo = (
  presentation: APIPresentationAttributes
) => {
  // If the presentation has changed and has "discontinued slides", we need the current SlidesMigrationInfo.
  // Read about the logic and relevant code parts in
  // https://ardoqcom.atlassian.net/wiki/spaces/INSIGHT/pages/2413002789/Discontinued+Views+engineering+perspective.

  const deprecatedSlides = getDeprecatedSlidesForPresentation(presentation);

  if (deprecatedSlides?.length) {
    // if there are deprecated slides, put the SlidesMigrationInfo into the state
    const getSlidesMigrationInfo = async (
      presentation: APIPresentationAttributes | APIPresentationAssetAttributes,
      deprecatedSlideIds: string[]
    ) => {
      // sends a post requestetSlide
      const slidesInfo = await getSlideOwnerMigrationPermissions(
        presentation,
        deprecatedSlideIds
      );
      dispatchAction(
        actions.setSlidesMigrationInfo({
          activePresentationId: presentation._id,
          slidesInfo,
        })
      );
    };
    getSlidesMigrationInfo(presentation, deprecatedSlides);
  }
};

export const setPresentation = (presentation: EnhancedPresentation) => {
  Context.setPresentation(presentation);
  getPresentationSlidesMigrationInfo(presentation);
  return presentation;
};
