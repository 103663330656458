import {
  BroadcastInstance,
  InstanceProgress,
  BroadcastSchedule,
  IntervalType,
  APIBroadcastAttributes,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { getFutureDateISO } from 'broadcasts/utils';
import { formatToFixed } from 'utils/numberUtils';

export const getInstancesInReverseChronologicalOrder = (
  instances: BroadcastInstance[]
): BroadcastInstance[] =>
  instances.sort((a, b) => {
    if (a.executedDate === b.executedDate) return 0;
    return a.executedDate > b.executedDate ? -1 : 1;
  });

const getReminderDate = (
  runtime: string | null,
  reminderInDays: number | null
) => {
  if (!reminderInDays || !runtime) {
    return '';
  }

  return getFutureDateISO(runtime, reminderInDays);
};

export const getInstanceProgress = (progress: InstanceProgress) => {
  const progressValues = Object.values(progress);
  const total = progressValues.length;
  const submitted = progressValues.filter(ExcludeFalsy).length;
  const percent = Number((submitted / total) * 100);
  const submissionRate = Number(formatToFixed(percent, 0));
  return {
    submissionRate: submissionRate || 0,
    total,
  };
};

export const getInstanceSurveysSent = (surveysCount = 0) => {
  return surveysCount === 1 ? '1 survey' : `${surveysCount} surveys`;
};

export const getInstanceAudience = (audienceCount: number) => {
  return audienceCount === 1 ? '1 person' : `${audienceCount} people`;
};

export const getLatestSchedule = (broadcast: APIBroadcastAttributes) => {
  return getReminderDate(
    broadcast?.previousRuntime ?? null,
    broadcast.scheduling.reminderInDays
  );
};

export const getIntervalInDays = (schedule: BroadcastSchedule) => {
  if (!schedule.repeating) return 0;
  const hoursPerDay = 24;
  const daysPerWeek = 7;
  const averageDaysPerMonth = 30.4368499;
  const averageDaysPerYear = 365.242199;
  switch (schedule.interval.intervalType) {
    case IntervalType.HOUR:
      return schedule.interval.intervalValue / hoursPerDay;
    case IntervalType.DAY:
      return schedule.interval.intervalValue;
    case IntervalType.WEEK:
      return schedule.interval.intervalValue * daysPerWeek;
    case IntervalType.MONTH:
      return schedule.interval.intervalValue * averageDaysPerMonth;
    case IntervalType.YEAR:
      return schedule.interval.intervalValue * averageDaysPerYear;
  }
};
