import { FieldWrapper, Label, Switch } from '@ardoq/forms';
import { PopoverPlacement, WithPopover } from '@ardoq/popovers';
import { FlexBox, Stack } from '@ardoq/layout';
import { InfoNotification, NewBadge } from '@ardoq/status-ui';
import {
  NumberWidgetConfiguration,
  NumberWidgetTrend,
  RollingDateRange,
  TrendColorScheme,
  TrendResultStatus,
  WidgetDataSourceTypes,
} from '@ardoq/api-types';
import { Select } from '@ardoq/select';
import { pluralize } from '@ardoq/common-helpers';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { Text } from '@ardoq/typography';
import { colors, s4, s8 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { SURVEY_VALID_INVALID_FIELD_NAME } from '../../../types';

type DisabledTrendSwitchProps =
  | { isDisabled: false }
  | { isDisabled: true; popoverContent: string };

export const TrendPercentageSwitch = ({
  isChecked,
  onChange,
  ...disabledProps
}: {
  isChecked: boolean;
  onChange: (value: boolean) => void;
} & DisabledTrendSwitchProps) => {
  return (
    <FieldWrapper>
      <WithPopover
        preferredPlacement={PopoverPlacement.TOP}
        content={
          disabledProps.isDisabled ? disabledProps.popoverContent : undefined
        }
      >
        <Stack gap={'small'}>
          <FlexBox gap={'small'} align={'center'} justify={'start'}>
            <Label isDisabled={disabledProps.isDisabled} width={'auto'}>
              {'Trend percentage'}
            </Label>
            <NewBadge />
          </FlexBox>
          <Switch
            isDisabled={disabledProps.isDisabled}
            name={'trend-percentage-switch'}
            label={'Show trend % changes'}
            isChecked={isChecked}
            onChange={onChange}
          />
        </Stack>
      </WithPopover>
    </FieldWrapper>
  );
};

export const RollingTrendRangeSelect = ({
  trend,
  onChange,
}: {
  trend: NumberWidgetTrend & RollingDateRange;
  onChange: (value: number | null) => void;
}) => {
  const notificationMessage = getTrendNotificationMessage(trend);
  return (
    <FieldWrapper>
      <Stack gap={'small'}>
        <Select
          dataTestId="number-widget-trend-range-select"
          label="Select date range for trend %"
          helperText="The chart will show the percentage of changes within the selected date range"
          options={trendPeriodOptions}
          value={trend.rolling.value}
          onValueChange={onChange}
        />
        {notificationMessage && (
          <InfoNotification>{notificationMessage}</InfoNotification>
        )}
      </Stack>
    </FieldWrapper>
  );
};

export const TrendColorSchemeSelect = ({
  trend,
  selectInverseColorScheme,
  selectClassicColorScheme,
}: {
  trend: NumberWidgetTrend;
  selectClassicColorScheme: VoidFunction;
  selectInverseColorScheme: VoidFunction;
}) => {
  const colorScheme = trend.colorScheme;

  return (
    <FieldWrapper>
      <Stack gap={'small'}>
        <Label>{'Trend colors'}</Label>
        <FlexBox gap={'small'} justify={'space-between'} align={'center'}>
          <ClassicColorScheme
            isActive={colorScheme === TrendColorScheme.CLASSIC}
            onClick={selectClassicColorScheme}
          />
          <InverseColorScheme
            isActive={colorScheme === TrendColorScheme.INVERSE}
            onClick={selectInverseColorScheme}
          />
        </FlexBox>
      </Stack>
    </FieldWrapper>
  );
};

const getTrendNotificationMessage = (
  trend: NumberWidgetTrend & RollingDateRange
): string | null => {
  // No data on the requested date, but there is some newer snapshot
  if (trend.resultStatus === TrendResultStatus.APPROXIMATE)
    return `Data is only available for the last ${trend.result.daysAgo} ${pluralize('day', trend.result.daysAgo)}. As more data becomes
          available, the full ${trend.rolling.value}-day period will be reflected.`;
  // no past data at all
  if (trend.resultStatus === TrendResultStatus.MISSING)
    return `No data found on the requested period.`;

  // trend with full data
  return null;
};

export const getDisabledTrendReason = (
  selectedWidget: NumberWidgetConfiguration
): DisabledTrendSwitchProps => {
  if (selectedWidget.filters)
    return {
      isDisabled: true,
      popoverContent: 'Trend is unavailable when filters are applied.',
    };

  if (
    selectedWidget.datasource.sourceType === WidgetDataSourceTypes.SURVEY &&
    selectedWidget.fields.some(
      field => field !== SURVEY_VALID_INVALID_FIELD_NAME
    )
  )
    return {
      isDisabled: true,
      popoverContent:
        'Trend can only be used with this survey field type: Show valid/invalid.',
    };

  return {
    isDisabled: false,
  };
};

const trendPeriodOptions = [
  { value: 7, label: 'Last 7 days' },
  { value: 30, label: 'Last 30 days' },
  { value: 90, label: 'Last 90 days' },
] as const;

const TrendColorRow = ({
  direction,
  colorString,
  iconName,
  iconColor,
}: {
  direction: string;
  colorString: string;
  iconName: IconName;
  iconColor: string;
}) => {
  return (
    <FlexBox flex={1} justify={'space-between'} align={'center'} width={'full'}>
      <FlexBox gap={'xsmall'}>
        <Text color={'textSubtle'} variant={'caption'}>
          {direction}:
        </Text>
        <Text color={'textModerate'} variant={'caption'}>
          {colorString}
        </Text>
      </FlexBox>
      <FlexBox
        padding={'xsmall'}
        align={'center'}
        justify={'center'}
        borderRadius={'r4'}
        borderColor={'borderAction'}
      >
        <Icon iconName={iconName} color={iconColor} iconSize={IconSize.SMALL} />
      </FlexBox>
    </FlexBox>
  );
};

const ColorSchemeContainer = styled.button<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${s4};
  border-radius: ${s8};
  padding: ${s8};
  border: none;
  outline: ${({ $isActive }) =>
    $isActive
      ? `2px solid ${colors.borderFocus}`
      : `1px solid ${colors.borderAction}`};
  cursor: ${({ $isActive }) => ($isActive ? 'auto' : 'pointer')};
  flex: 1;
  background: ${colors.white};
`;

const ClassicColorScheme = ({
  onClick,
  isActive,
}: {
  onClick: VoidFunction;
  isActive: boolean;
}) => {
  return (
    <ColorSchemeContainer $isActive={isActive} onClick={onClick}>
      <TrendColorRow
        direction={'Up'}
        colorString={'Green'}
        iconName={IconName.ARROW_UPWARD}
        iconColor={colors.iconSuccess}
      />
      <TrendColorRow
        direction={'Down'}
        colorString={'Red'}
        iconName={IconName.ARROW_DOWNWARD}
        iconColor={colors.iconError}
      />
    </ColorSchemeContainer>
  );
};

const InverseColorScheme = ({
  onClick,
  isActive,
}: {
  onClick: VoidFunction;
  isActive: boolean;
}) => {
  return (
    <ColorSchemeContainer $isActive={isActive} onClick={onClick}>
      <TrendColorRow
        direction={'Up'}
        colorString={'Red'}
        iconName={IconName.ARROW_UPWARD}
        iconColor={colors.iconError}
      />
      <TrendColorRow
        direction={'Down'}
        colorString={'Green'}
        iconName={IconName.ARROW_DOWNWARD}
        iconColor={colors.iconSuccess}
      />
    </ColorSchemeContainer>
  );
};
