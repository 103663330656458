import currentUser$ from 'streams/currentUser/currentUser$';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { logError } from '@ardoq/logging';
import { switchMap, tap, withLatestFrom } from 'rxjs';
import { updateCurrentUser } from 'streams/currentUser/actions';
import {
  fetchPrivileges,
  notifyFailedToFetchPrivileges,
  setPrivileges,
  updateUserPrivileges,
} from './actions';
import { handleError, privilegeApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';

const handleFetchPrivileges = routine(
  ofType(fetchPrivileges),
  switchMap(() => {
    return Promise.all([
      privilegeApi.fetchConfigurablePrivileges(),
      privilegeApi.fetchAllUsersPrivileges(),
    ]);
  }),
  withLatestFrom(currentUser$),
  tap(
    ([[configurablePrivileges, privilegesByUser], { _id: currentUserId }]) => {
      if (
        isArdoqError(configurablePrivileges) ||
        isArdoqError(privilegesByUser)
      ) {
        dispatchAction(notifyFailedToFetchPrivileges());
        if (isArdoqError(configurablePrivileges)) {
          logError(configurablePrivileges, 'Failed to fetch privileges');
        }
        if (isArdoqError(privilegesByUser)) {
          logError(privilegesByUser, 'Failed to fetch privileges');
        }
        return;
      }
      dispatchAction(
        setPrivileges({
          configurablePrivileges,
          privilegesByUser,
          currentUserId,
        })
      );
    }
  )
);

const handleUpdateUserPrivileges = routine(
  ofType(updateUserPrivileges),
  extractPayload(),
  switchMap(privilegeApi.update),
  handleError(error => {
    logError(error, 'Failed to update user privileges');
  }),
  tap(() => {
    dispatchAction(fetchPrivileges());
    dispatchAction(updateCurrentUser());
  })
);

export default collectRoutines(
  handleFetchPrivileges,
  handleUpdateUserPrivileges
);
