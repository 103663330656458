import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { MetaModelComponentType, Zone } from '@ardoq/api-types';
import { ZoneCommands } from '../types';
import { GlobalFieldsByName } from 'streams/fields/globalFields$';
import {
  getComponentTypesTableColumns,
  getComponentTypesTableDataSource,
} from './componentTypesTableUtils';
import { ComponentTypeFieldsGroupSearchParams } from '../zonesViewState$/types';

export const ComponentTypesTable = ({
  componentTypes,
  globalFieldsByName,
  zone,
  componentTypeFieldsGroupSearch,
  commands,
}: {
  componentTypes: MetaModelComponentType[];
  globalFieldsByName: GlobalFieldsByName;
  zone: Zone;
  componentTypeFieldsGroupSearch: ComponentTypeFieldsGroupSearchParams;
  commands: ZoneCommands;
}) => (
  <DatasourceTable
    components={newTableTheme}
    dataSource={getComponentTypesTableDataSource(
      componentTypes,
      zone.configuredComponentTypes,
      componentTypeFieldsGroupSearch
    )}
    columns={getComponentTypesTableColumns({
      zone,
      globalFieldsByName,
      commands,
    })}
  />
);
