import { isEqual } from 'lodash';
import { combineLatest, map, distinctUntilChanged, Observable } from 'rxjs';
import { loadedState$ } from 'loadedState/loadedState$';
import { currentUserPermissions$ } from 'streams/currentUserPermissions/currentUserPermissions$';
import { isSingleTraversalState } from './saveAsViewpoint/saveAsViewpoint$/isSingleTraversalState';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { traversalAccessControlInterface } from 'resourcePermissions/accessControlHelpers/traversals';

export type CurrentViewpointUserPermissions = {
  canCreateViewpoint: boolean;
  canUpdateViewpoint: boolean;
};

const currentViewpointUserPermissions$: Observable<CurrentViewpointUserPermissions> =
  combineLatest([loadedState$, currentUserPermissions$]).pipe(
    map(([loadedState]) => {
      const traversalId = isSingleTraversalState(loadedState)
        ? loadedState[0].traversalId
        : null;
      const permissionContext = currentUserInterface.getPermissionContext();
      return {
        canCreateViewpoint:
          traversalAccessControlInterface.canCreateTraversal(permissionContext),
        canUpdateViewpoint: traversalId
          ? traversalAccessControlInterface.canEditTraversal(
              permissionContext,
              traversalId
            )
          : false,
      };
    }),
    distinctUntilChanged(isEqual)
  );

export default currentViewpointUserPermissions$;
