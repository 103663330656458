import { PrimaryButton, GhostButton } from '@ardoq/button';
import { colors, s16 } from '@ardoq/design-tokens';
import { TextInput, FieldWrapper } from '@ardoq/forms';
import { Space } from '@ardoq/style-helpers';
import { Stack } from '@ardoq/layout';
import { header3, header4, text1 } from '@ardoq/typography';
import { navigateToPath } from 'integrations/ITPedia/navigation/actions';
import { updateVulnerabilitiesWorkspace } from 'integrations/ITPedia/streams/configurationState/actions';
import { trackITPediaEvent } from 'integrations/ITPedia/streams/tracking/actions';
import { ITPediaImportRoutes } from 'integrations/ITPedia/types';
import { WorkspaceSelector } from 'integrations/common/components/workspaceSelector/WorkspaceSelector';
import { IntegrationWorkspace } from '@ardoq/api-types/integrations';
import { dispatchAction } from '@ardoq/rxbeach';
import styled from 'styled-components';
import { Tag } from '@ardoq/status-ui';
import { CollapsibleIsland, IslandHeader } from '@ardoq/page-layout';

type Step2Params = {
  isValid: boolean;
  vulnerabilitiesWorkspace: IntegrationWorkspace;
  workspaces: IntegrationWorkspace[];
};

export function Step2({
  vulnerabilitiesWorkspace,
  isValid,
  workspaces,
}: Step2Params) {
  const selectedVulnerabilitiesWS = vulnerabilitiesWorkspace.name
    ? vulnerabilitiesWorkspace
    : undefined;
  const usedVulnerabilitiesWSOptions = selectedVulnerabilitiesWS
    ? [selectedVulnerabilitiesWS]
    : [];
  const onNext = () => {
    dispatchAction(
      trackITPediaEvent({
        name: 'CLICKED_BUTTON',
        metadata: {
          name: 'Field mapping next',
          to: 'Summary and sync',
        },
      })
    );
    dispatchAction(navigateToPath(ITPediaImportRoutes.SUMMARY_SYNC));
  };
  const onPrev = () => {
    dispatchAction(
      trackITPediaEvent({
        name: 'CLICKED_BUTTON',
        metadata: {
          name: 'Field mapping back',
          to: 'Component selection',
        },
      })
    );
    dispatchAction(navigateToPath(ITPediaImportRoutes.DATA_SELECTION));
  };
  return (
    <CollapsibleIsland
      subtitleWidth="medium"
      header={
        <IslandHeaderContainer>
          <IslandHeader
            tags={<Tag>Step 2</Tag>}
            title="Vulnerability fields"
            subtitle="You can import vulnerabilities as components in Ardoq. We'll automatically create a reference between the vulnerability component and the corresponding product component. Start by creating a workspace that includes all vulnerability components."
            subtitleWidth="medium"
          />
        </IslandHeaderContainer>
      }
      isDefaultExpanded
      footerContent={
        <Space $justify="end" $flex={1}>
          <GhostButton onClick={onPrev}>Back</GhostButton>
          <PrimaryButton isDisabled={!isValid} onClick={onNext}>
            Next: Review and sync
          </PrimaryButton>
        </Space>
      }
    >
      <Stack gap="xlarge">
        <Header3>
          1. Select an existing or create a new workspace for this table
        </Header3>
        <FormField>
          <WorkspaceSelector
            selectedWorkspace={selectedVulnerabilitiesWS}
            workspaces={{
              used: usedVulnerabilitiesWSOptions,
              existing: workspaces,
            }}
            onWorkspaceSelect={(workspace: IntegrationWorkspace) => {
              dispatchAction(updateVulnerabilitiesWorkspace(workspace));
            }}
          />
        </FormField>
        <Stack gap="xsmall">
          <Header3>2. Review field mapping</Header3>
          <ReviewDescriptionText>
            IT-Pedia vulnerability fields will be mapped to different field
            types in Ardoq. The vulnerability fields in Ardoq will follow the
            naming convention in IT-Pedia.
          </ReviewDescriptionText>
        </Stack>
        <Stack gap="medium">
          <VulnerabilityTableHeaderContainer>
            <InvisibleText1>Map</InvisibleText1>
            <VulnerabilityTableHeader>
              IT-Pedia vulnerability fields
            </VulnerabilityTableHeader>
            <InvisibleText1>to</InvisibleText1>
            <VulnerabilityTableHeader>Ardoq fields</VulnerabilityTableHeader>
          </VulnerabilityTableHeaderContainer>
          <Space $align="center">
            <Text1>Map</Text1>
            <FormField>
              <TextInput isReadOnly value="CVE ID" />
            </FormField>
            <Text1>to</Text1>
            <FormField>
              <TextInput isReadOnly value="CVE ID (Text field)" />
            </FormField>
          </Space>
          <Space $align="center">
            <Text1>Map</Text1>
            <FormField>
              <TextInput isReadOnly value="CVE Name" />
            </FormField>
            <Text1>to</Text1>
            <FormField>
              <TextInput isReadOnly value="CVE name (Text field)" />
            </FormField>
          </Space>
          <Space $align="center">
            <Text1>Map</Text1>
            <FormField>
              <TextInput isReadOnly value="CVE Summary" />
            </FormField>
            <Text1>to</Text1>
            <FormField>
              <TextInput isReadOnly value="CVE Summary (Text field)" />
            </FormField>
          </Space>
          <Space $align="center">
            <Text1>Map</Text1>
            <FormField>
              <TextInput isReadOnly value="CVE URL" />
            </FormField>
            <Text1>to</Text1>
            <FormField>
              <TextInput isReadOnly value="CVE URL (URL field)" />
            </FormField>
          </Space>
          <Space $align="center">
            <Text1>Map</Text1>
            <FormField>
              <TextInput isReadOnly value="CVSS Score" />
            </FormField>
            <Text1>to</Text1>
            <FormField>
              <TextInput isReadOnly value="CVSS Score (Number field)" />
            </FormField>
          </Space>
          <Space $align="center">
            <Text1>Map</Text1>
            <FormField>
              <TextInput isReadOnly value="CVSS Score Status" />
            </FormField>
            <Text1>to</Text1>
            <FormField>
              <TextInput isReadOnly value="CVSS Score Status (List field)" />
            </FormField>
          </Space>
        </Stack>
      </Stack>
    </CollapsibleIsland>
  );
}

const Text1 = styled.div`
  ${text1};
`;

const InvisibleText1 = styled(Text1)`
  visibility: hidden;
`;

const ReviewDescriptionText = styled(Text1)`
  color: ${colors.grey35};
  /* Weird design choice */
  width: 718px;
`;

const VulnerabilityTableHeaderContainer = styled(Space)`
  padding-bottom: ${s16};
  border-bottom: 1px solid ${colors.grey90};
  width: 718px;
`;

const VulnerabilityTableHeader = styled.div`
  ${header4};
  width: 300px;
`;

const FormField = styled(FieldWrapper)`
  width: 300px;
`;

const Header3 = styled.div`
  ${header3};
`;

const IslandHeaderContainer = styled.div`
  div {
    max-width: 100% !important;
  }
  border-bottom: 1px solid ${colors.grey80};
`;
