import styled from 'styled-components';
import AqCollapsible from 'atomicComponents/AqCollapsible/AqCollapsible';
import DeletedEntitiesSection from './DeletedEntitiesSection';
import ChevronDecorator from './ChevronDecorator';
import { ClickableRow, SectionHeader } from './atoms';
import { DeletedEntitiesProperties } from './types';

const ClickableRowWithBorder = styled(ClickableRow)`
  border: 1px solid lightGray;
  margin-top: 15px;
  padding: 5px;
`;

const DeletedEntitiesSectionWithCollapsible = ({
  entityType,
  entities,
}: DeletedEntitiesProperties) => {
  if (!entities.length) {
    return null;
  }
  return (
    <AqCollapsible
      renderTarget={({ toggle, isExpanded }) => (
        <ClickableRowWithBorder DEPRECATED_onClick={toggle}>
          <ChevronDecorator isExpanded={isExpanded}>
            <SectionHeader>Available {entityType}s to restore:</SectionHeader>
          </ChevronDecorator>
        </ClickableRowWithBorder>
      )}
      renderContent={() => (
        <DeletedEntitiesSection entityType={entityType} entities={entities} />
      )}
    />
  );
};

export default DeletedEntitiesSectionWithCollapsible;
