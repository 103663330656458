import { ToastType, showToast } from '@ardoq/status-ui';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { FlowHeader } from 'integrations/common/components/flowHeader/FlowHeader';
import { startSaveModal } from 'integrations/common/modals/saveModal/SaveModal';
import { startTransferConfigsModal } from 'integrations/common/modals/transferConfigsModal/TransferConfigsModal';
import { resetIntegration } from 'integrations/common/streams/activeIntegrations/actions';
import { getActiveIntegrationStream } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { ActiveIntegrationState } from 'integrations/common/streams/activeIntegrations/types';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import {
  saveConfiguration,
  saveConfigurationsFailure,
  saveConfigurationsSuccess,
} from 'integrations/common/streams/transferConfigs/actions';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { TransferConfigsState } from 'integrations/common/streams/transferConfigs/types';
import { getTransferStateStream } from 'integrations/common/streams/transferState/getTransferStateStream';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { combineLatest, filter, map, of, switchMap } from 'rxjs';
import { getHeaderTitle } from './utils';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { isUnifiedIntegrationId } from 'integrations/unified/utils';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { getScheduleStream } from 'integrations/common/streams/schedule/schedule$';
import { initialStates } from 'integrations/unified/initialState';
import { UnifiedIntegrationId } from 'integrations/unified/types';

const handleRestart = (instanceId: IntegrationId) => {
  dispatchAction(resetIntegration(instanceId));
};

const handleStartTransferConfigsModal =
  (integrationId: IntegrationId) => () => {
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'CLICKED_MANAGE_CONFIGURATION',
      })
    );
    startTransferConfigsModal();
  };

const handleSaveAs = (integrationId: IntegrationId) => {
  startSaveModal({
    integrationId,
    title: 'Save as a new version',
    isNewConfig: true,
  });
};

const handleSaveSelectedConfig = ({
  integrationId,
  transferConfigs,
  selectedTransferConfigId,
}: {
  integrationId: UnifiedIntegrationId;
  transferConfigs: TransferConfigsState;
  selectedTransferConfigId: ActiveIntegrationState['selectedTransferConfigId'];
}) => {
  const selectedConfig = transferConfigs.configs.find(
    c => c._id === selectedTransferConfigId
  );
  if (selectedConfig) {
    dispatchActionAndWaitForResponse(
      saveConfiguration({
        integrationId,
        name: selectedConfig.name,
        isNewConfig: false,
      }),
      saveConfigurationsSuccess,
      saveConfigurationsFailure
    ).then(resultAction => {
      if (resultAction.type === saveConfigurationsSuccess.type) {
        showToast('Configuration saved', ToastType.SUCCESS);
      }

      if (resultAction.type === saveConfigurationsFailure.type) {
        showToast('Failed to save configuration', ToastType.INFO);
      }
    });
  } else {
    handleSaveAs(integrationId);
  }
};

const viewModel$ = integrationId$.pipe(
  filter(isUnifiedIntegrationId),
  switchMap(integrationId =>
    combineLatest({
      integrationId: of(integrationId),
      activeIntegration: getActiveIntegrationStream(integrationId),
      transferConfigs: transferConfigs$,
      transferState: getTransferStateStream(integrationId),
      integrationTerms: getIntegrationTermsDictionaryStream(integrationId),
      schedules: getSchedulesStream(integrationId),
      schedule: getScheduleStream(integrationId),
    })
  ),
  map(
    ({
      integrationId,
      transferConfigs,
      activeIntegration,
      transferState,
      integrationTerms,
      schedules: { schedules },
      schedule: { loadedScheduleId },
    }) => {
      const selectedTransferConfigId =
        activeIntegration.selectedTransferConfigId;
      const title = getHeaderTitle({
        prefix: `${integrationTerms.name} importer`,
        transferConfigs: transferConfigs.configs,
        selectedTransferConfigId,
        loadedScheduleId,
        schedules,
      });

      return {
        title,
        onSave: () =>
          handleSaveSelectedConfig({
            integrationId,
            transferConfigs,
            selectedTransferConfigId,
          }),
        onSaveAs: () => handleSaveAs(integrationId),
        canRestart: true,
        isSavingDisabled: false,
        onRestart: () => handleRestart(integrationId),
        onStartTransferConfigsModal:
          handleStartTransferConfigsModal(integrationId),
        isNewConfig: !selectedTransferConfigId,
        transferDirection: transferState.transferDirection,
        disabledConfigurationActions:
          initialStates[integrationId].areConfigurationActionsDisabled ||
          Boolean(loadedScheduleId),
      };
    }
  )
);

export const ImportHeader = connect(FlowHeader, viewModel$);
