import ComponentTypeTag from './ComponentTypeTag';
import { MetaModel, SurveyApprovalRelationship } from '@ardoq/api-types';
import { FlexBox } from '@ardoq/layout';
import { ReferenceTypeWithArrows } from './PathRepresentation';
import { FlexWithFontSize } from './atoms';
import { referenceTypeToFrontendFormat } from './AudienceTraversalDrawer/traversalAudienceHelpers';

type RelationshipRepresentationProps = {
  surveyComponentTypeName: string;
  relationship: SurveyApprovalRelationship;
  metamodel: MetaModel;
};

const RelationshipRepresentation = ({
  surveyComponentTypeName,
  relationship,
  metamodel,
}: RelationshipRepresentationProps) => {
  const { referenceTypeName, componentTypeName } = relationship;
  const triple = {
    referenceType: referenceTypeToFrontendFormat(referenceTypeName),
    direction: relationship.outgoing
      ? ('outgoing' as const)
      : ('incoming' as const),
  };
  return (
    <FlexWithFontSize align="center">
      <FlexBox gap="xsmall" align="center">
        <ComponentTypeTag
          componentTypeName={surveyComponentTypeName}
          metamodel={metamodel}
          hideLabel
        />
        <ReferenceTypeWithArrows triple={triple} />
        <ComponentTypeTag
          componentTypeName={componentTypeName}
          metamodel={metamodel}
        />
      </FlexBox>
    </FlexWithFontSize>
  );
};

export default RelationshipRepresentation;
