import { useCallback } from 'react';
import { alert } from '@ardoq/modal';
import { ViewpointsStreamShape } from '../types';
import { connect } from '@ardoq/rxbeach';
import viewpoints$ from '../viewpoints$';
import { Multiselect, SelectOption } from '@ardoq/select';
import { useController, useFormContext } from 'react-hook-form';
import { FormWrapper } from '@ardoq/forms';
import { getParentsByWorkspaceId } from 'utils/hierarchy';
import { APIDiscoverViewpointAttributes } from '@ardoq/api-types';
import { StatusType } from '@ardoq/status-ui';
import { uniqBy } from 'lodash';
import {
  getRestrictedWorkspaces,
  viewpointHasRestrictedWorkspaces,
} from 'viewpoints/restrictedWorkspaces/operations';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { localeIncludesLowercase } from '@ardoq/locale';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

const Workspaces = ({
  workspaces,
  folders,
  availableTriples,
  currentViewpoint,
  restrictedWorkspacesForEveryViewpoint,
  locale,
}: ViewpointsStreamShape) => {
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const [published] = watch(['published']);

  const checkAvailableTriples = (workspaceIds: string[]) => {
    if (workspaceIds.length < 1) return 'At least one workspace is required.';
    if (availableTriples.status !== 'success') return true;
    return availableTriples.data.length > 0;
  };

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name: 'workspaceIds',
    rules: {
      validate: {
        checkAvailableTriples: workspaceIds =>
          checkAvailableTriples(workspaceIds) ||
          'None of the selected workspaces contain references or parent relationships.',
      },
    },
  });

  const parentsByWorkspaceId = getParentsByWorkspaceId(
    workspaces || [],
    folders || []
  );

  const restrictedWorkspaces = getRestrictedWorkspaces(
    restrictedWorkspacesForEveryViewpoint,
    currentViewpoint?._id
  );

  const allRelevantWorkspaces = uniqBy(
    [...(workspaces || []), ...restrictedWorkspaces],
    '_id'
  );

  const workspaceOptions = allRelevantWorkspaces.map(workspace => {
    const cannotAccessWorkspace =
      !workspaceAccessControlInterface.canAccessWorkspace(
        currentUserInterface.getPermissionContext(),
        workspace._id
      );
    return {
      label: workspace.name,
      value: workspace._id,
      statusType: cannotAccessWorkspace ? StatusType.ERROR : undefined,
      breadcrumbs: parentsByWorkspaceId
        .get(workspace._id)
        ?.map(text => ({ text })),
    };
  });

  const handleChange = useCallback(
    (options: SelectOption<string>[] | null) => {
      if (published) {
        alert({
          title: 'Viewpoint published',
          text: 'Please unpublish the viewpoint before making changes to included workspaces.',
        });
      } else {
        onChange(options?.map(o => o.value as string) ?? []);
      }
    },
    [onChange, published]
  );

  const filterOption = useCallback(
    ({ label }: { label: string }, query: string) =>
      localeIncludesLowercase(label, query, locale),
    [locale]
  );

  const noResultsFn = useCallback(() => 'No results', []);

  const restrictedWorkspacesError = viewpointHasRestrictedWorkspaces(
    restrictedWorkspacesForEveryViewpoint,
    currentViewpoint?._id
  )
    ? "You don't have access to some of the selected workspaces. Contact your admin to request access."
    : undefined;

  return (
    <FormWrapper>
      <Multiselect
        errorMessage={error?.message || restrictedWorkspacesError}
        onChange={handleChange}
        value={value}
        filterOption={filterOption}
        noOptionsMessage={noResultsFn}
        placeholder="Type or click to find a workspace"
        options={workspaceOptions}
        menuShouldScrollIntoView={true}
        menuPlacement="auto"
        styles={{
          menu: provided => ({ ...provided, zIndex: 5 }),
        }}
        isClearable
        dataTestId="workspace-select"
      />
    </FormWrapper>
  );
};

export default connect(Workspaces, viewpoints$);
