import {
  ApiResponse,
  componentTypeApi,
  dashboardApi,
  entityGroupApi,
  referenceTypeApi,
  servicenowConfigApi,
  tabularMappingApi,
  useCaseApi,
} from '@ardoq/api';
import {
  APIComponentTypeAttributes,
  APIFieldAttributes,
  APIReferenceTypeAttributes,
  ArdoqId,
  Entity,
  Named,
  PersistedUseCase,
  SearchType,
} from '@ardoq/api-types';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { Features, hasFeature } from '@ardoq/features';
import { LabeledValue } from 'aqTypes';
import broadcast$ from 'broadcasts/broadcast$';
import { sortBy } from 'lodash';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import storedQueries$ from 'streams/queries/storedQueries$';
import surveys$ from 'streams/surveys/surveys$';
import viewpoints$ from 'viewpoints/viewpoints$';
import { adminBundleEditorBackboneInterface } from './adminBundleEditorBackboneInterface';
import {
  DateRangeFieldAttributes,
  dateRangeOperations,
} from '@ardoq/date-range';
import traversals$ from 'streams/traversals$';
import { traversalStateOperations } from 'streams/traversals/traversalsStateOperations';
import reports$ from 'streams/reports/reports$';
import presentations$ from 'streams/presentations/presentations$';
import { presentationAccessControlOperations } from 'resourcePermissions/accessControlHelpers/presentations';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';

/** Convert Backbone models into options for AqReactSelect
 * Used to populate form in CreateBundle
 */
export const backboneModelsToSelectOptions = (
  backboneModels: Backbone.Model[]
): LabeledValue[] =>
  backboneModels.map((entity: Backbone.Model) => ({
    label: entity.get('name'),
    value: entity.id,
  }));

export const apiObjectsToSelectOptions = (
  apiObjects: (Entity & Named)[]
): LabeledValue[] =>
  apiObjects.map((apiObject: Entity & Named) => ({
    label: apiObject.name,
    value: apiObject._id,
  }));

const selectOptionsForUseCases = (
  useCases: PersistedUseCase[]
): LabeledValue[] =>
  useCases.map((useCase: PersistedUseCase) => ({
    label: `${useCase.name} v${useCase.iteration}`,
    value: useCase._id,
  }));

const getWorkspaceNameIfModelNotTemplate = (modelId: string): string | null => {
  const workspaceId = workspaceInterface.getWorkspaceIdByModelId(modelId);
  if (!workspaceId) return null;
  return workspaceInterface.get(workspaceId)?.name ?? null;
};

const selectOptionFor = (
  id: ArdoqId,
  name: string,
  workspaceName: string | null
): LabeledValue | null =>
  workspaceName ? { label: `${workspaceName} / ${name}`, value: id } : null;

const sortByLabel = (selectOptions: LabeledValue[]): LabeledValue[] =>
  sortBy(selectOptions, [s => s.label.toLowerCase()]);

const keep = <T, R>(items: T[], transform: (item: T) => R | null): R[] => {
  return items.flatMap(item => {
    const result = transform(item) ?? null;
    return result !== null ? [result] : [];
  });
};

const metamodelTypesToSelectOptions = (
  types: (APIComponentTypeAttributes | APIReferenceTypeAttributes)[]
): LabeledValue[] =>
  sortByLabel(
    keep(types, type => {
      const workspaceName = getWorkspaceNameIfModelNotTemplate(type.modelId);
      return selectOptionFor(type._id, type.name, workspaceName);
    })
  );

const fieldsToSelectOptions = (fields: APIFieldAttributes[]): LabeledValue[] =>
  sortByLabel(
    keep(fields, field => {
      const workspaceName = getWorkspaceNameIfModelNotTemplate(field.model);
      return selectOptionFor(field._id, field.name, workspaceName);
    })
  );

const errorCollector = () => {
  const errors: string[] = [];
  return {
    collectErrors: async <T>(
      promise: ApiResponse<T[]>,
      errorMessage: string
    ): Promise<T[]> => {
      const response = await promise;
      if (isArdoqError(response)) {
        errors.push(errorMessage);
        return [];
      }
      return response;
    },
    getCollectedErrors: (): string[] => errors,
  };
};

type LoadAsyncReturn = {
  loadAsyncErrorMsgs: string[];
  useCaseSelectOptions: LabeledValue[];
  entityGroupSelectOptions: LabeledValue[];
  componentTypeSelectOptions: LabeledValue[];
  referenceTypeSelectOptions: LabeledValue[];
  fieldSelectOptions: LabeledValue[];
  dashboardSelectOptions: LabeledValue[];
  excelImportMappingConfigs: LabeledValue[];
  serviceNowImportMappingConfigs: LabeledValue[];
  createdDateRangeFields: DateRangeFieldAttributes[];
};
/** Load data to be used as options in CreateBundle */
export const loadAsync = async (): Promise<LoadAsyncReturn> => {
  const { collectErrors, getCollectedErrors } = errorCollector();

  const [
    useCases,
    entityGroups,
    componentTypes,
    referenceTypes,
    dashboards,
    tabularMappings,
    servicenowConfigs,
  ] = await Promise.all([
    collectErrors(useCaseApi.fetchAll(), 'Error fetching use cases'),
    collectErrors(entityGroupApi.fetchAll(), 'Error fetching entity groups'),
    collectErrors(
      componentTypeApi.fetchAll(),
      'Error fetching Component types'
    ),
    collectErrors(
      referenceTypeApi.fetchAll(),
      'Error fetching Reference types'
    ),
    collectErrors(dashboardApi.fetchAll(), 'Error fetching Dashboards'),
    collectErrors(
      tabularMappingApi.fetchAll(),
      'Error fetching Excel import configuration options'
    ),
    collectErrors(
      servicenowConfigApi.fetchAll(),
      'Error fetching ServiceNow import configuration options'
    ),
  ]);

  const mergedDateRangeFields =
    dateRangeOperations.mergeDateTimeFieldsToDateRangeFields(
      adminBundleEditorBackboneInterface.getAllFields()
    );

  return {
    loadAsyncErrorMsgs: getCollectedErrors(),
    useCaseSelectOptions: selectOptionsForUseCases(useCases),
    entityGroupSelectOptions: apiObjectsToSelectOptions(entityGroups),
    componentTypeSelectOptions: metamodelTypesToSelectOptions(componentTypes),
    referenceTypeSelectOptions: metamodelTypesToSelectOptions(referenceTypes),
    fieldSelectOptions: fieldsToSelectOptions(mergedDateRangeFields.fields),
    dashboardSelectOptions: apiObjectsToSelectOptions(dashboards),
    excelImportMappingConfigs: apiObjectsToSelectOptions(tabularMappings),
    serviceNowImportMappingConfigs:
      apiObjectsToSelectOptions(servicenowConfigs),
    createdDateRangeFields: mergedDateRangeFields.createdDateRangeFields,
  };
};

export const getBroadcastSelectOptions = () =>
  apiObjectsToSelectOptions(broadcast$.state.broadcasts);

export const getGraphFilterSelectOptions = () =>
  apiObjectsToSelectOptions(
    storedQueries$.state.storedQueries.filter(
      ({ type }) => type === SearchType.DYNAMIC_FILTER_QUERY
    )
  );

export const getSurveySelectOptions = () => {
  if (!hasFeature(Features.SURVEYS)) return [];
  const surveys = surveys$.state.list?.filter(ExcludeFalsy) ?? [];
  return apiObjectsToSelectOptions(surveys);
};

export const getViewpointSelectOptions = () => {
  const viewpoints = viewpoints$.state.viewpoints?.filter(ExcludeFalsy) ?? [];
  return apiObjectsToSelectOptions(viewpoints);
};

export const getReportSelectOptions = () => {
  const reports = reports$.state.list.filter(ExcludeFalsy);
  return apiObjectsToSelectOptions(reports);
};

export const getPresentationSelectOptions = () => {
  const permissionContext = currentUserPermissionContext$.state;
  const presentations = presentations$.state.list.filter(presentation =>
    presentationAccessControlOperations.canEditPresentation(
      permissionContext,
      presentation
    )
  );
  return apiObjectsToSelectOptions(presentations);
};

export const getTraversalSelectOptions = () => {
  // Traversals should be provided as a parameter to this function, and then perhaps inlined
  return apiObjectsToSelectOptions(
    traversalStateOperations.getAsList(traversals$.state)
  );
};

export const splitDateRangeFieldsIds = ({
  fieldIds,
  dateRangeFields,
}: {
  fieldIds: string[];
  dateRangeFields: DateRangeFieldAttributes[];
}) => {
  return fieldIds.flatMap(fieldId => {
    const targetDateRangeField = dateRangeFields.find(df => df._id === fieldId);
    if (targetDateRangeField) {
      return [
        targetDateRangeField.dateTimeFields.start._id,
        targetDateRangeField.dateTimeFields.end._id,
      ];
    }
    return [fieldId];
  });
};
