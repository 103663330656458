import { Box, FlexBox, Stack } from '@ardoq/layout';
import { Header2, Link, Text } from '@ardoq/typography';
import { TrialButton } from './SurveyResponseApprovalTrialExpiredInfo';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { trackEvent } from 'tracking/tracking';
import startTrialCTA from './startTrialCTA.svg';
import styled from 'styled-components';
import { popoverRegistry } from '@ardoq/popovers';
import { colors, spacing } from '@ardoq/design-tokens';
import startTrialBackground from './startTrialBackground.png';
import { dispatchAction } from '@ardoq/rxbeach';
import { OrgTabs } from 'admin/manageOrganization/navigation/types';
import { navigateToManageOrganization } from 'router/navigationActions';

export const TRIAL_BUTTON_ID = 'survey-builder-trial-button';
popoverRegistry.set(
  TRIAL_BUTTON_ID,
  () => 'Contact your admin to enable this feature.'
);

const TrialWrapper = styled(Box)`
  border: 1px solid ${colors.pink60};
  background: url(${startTrialBackground});
  background-size: cover;
`;

const TrialImage = styled.img`
  width: 244px;
  height: 136px;
  align-self: flex-end;
  margin-right: ${spacing.s24};
`;

const MaxWidthStack = styled(Stack)`
  max-width: 465px;
`;

export const SurveyResponseApprovalTrialBanner = ({
  isOrgAdmin,
}: {
  isOrgAdmin: boolean;
}) => {
  return (
    <TrialWrapper borderRadius="medium">
      <FlexBox align="center" justify="space-between" gap="large">
        <FlexBox
          flex={1}
          padding="large"
          align="center"
          gap="large"
          flexDirection={{ tablet: 'column', desktop: 'row' }}
        >
          <MaxWidthStack gap="medium">
            <Header2>Enhance your data quality</Header2>
            <Text variant="caption" color="textSubtle">
              Our new approval workflow enables data owners and experts to
              review survey responses for better data integrity. Share surveys
              confidently with a wider audience trusting that only verified data
              gets approved into Ardoq.{' '}
              <Link
                href={KnowledgeBaseLink.SURVEY_RESPONSE_APPROVALS}
                target="_blank"
                typography="caption"
                hideIcon
              >
                Learn more
              </Link>
            </Text>
          </MaxWidthStack>
          <Box paddingX="large" flexShrink={0}>
            <TrialButton
              popoverId={!isOrgAdmin ? TRIAL_BUTTON_ID : undefined}
              isDisabled={!isOrgAdmin}
              onClick={() => {
                trackEvent('Survey change approval: clicked start trial CTA');
                dispatchAction(
                  navigateToManageOrganization({
                    manageOrgTab: OrgTabs.FEATURE_SETTINGS,
                  })
                );
              }}
            >
              Start trial
            </TrialButton>
          </Box>
        </FlexBox>
        <TrialImage src={startTrialCTA} />
      </FlexBox>
    </TrialWrapper>
  );
};
