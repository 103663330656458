import { getInitials } from '@ardoq/common-helpers';
import { colors, spacing } from '@ardoq/design-tokens';
import { FlexBox } from '@ardoq/layout';
import styled from 'styled-components';
import { Header2 } from '@ardoq/typography';
import { UserProfilePageProps } from '../types';
import { TextOverflow } from '@ardoq/popovers';

const CircularWrapper = styled.div`
  color: ${colors.grey50};
  height: ${spacing.s32};
  width: ${spacing.s32};
  border-radius: 50%;
  background: ${colors.grey90};
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
`;

export const UserNameDecorator = ({
  user,
}: Pick<UserProfilePageProps, 'user'>) => {
  return (
    <FlexBox gap="small" align="center">
      <CircularWrapper>{getInitials(user.name)}</CircularWrapper>
      <TextOverflow lineClamp={1} style={{ flexShrink: 1, maxWidth: '60%' }}>
        <Header2>{user.name}</Header2>
      </TextOverflow>
    </FlexBox>
  );
};
