import { Branch } from 'components/DiffMergeTable/Branch';
import PopoverDataProvider, { ProvidedProps } from './PopoverDataProvider';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { getWorkspaceByEntity } from 'components/DiffMergeTable/utils';
import { WorkspaceLabel } from './atoms';
import { PopoverRenderer } from '@ardoq/popovers';

type WorkspacePopoverProps = {
  branch: Branch;
  entityType: APIEntityType;
  entityId: ArdoqId;
  parentEntityId?: ArdoqId;
};

const WorkspacePopover = ({
  entityId,
  entityType,
  enhancedScopeData,
  parentEntityId,
}: Omit<WorkspacePopoverProps, 'branch'> & ProvidedProps) => {
  const workspace = getWorkspaceByEntity(
    entityId,
    entityType,
    enhancedScopeData,
    parentEntityId
  );
  if (!workspace) return <></>;
  return <WorkspaceLabel workspace={workspace} />;
};

export const diffMergeWorkspacePopoverRenderer: PopoverRenderer = target => {
  const {
    diffMergePopoverBranch: branch,
    diffMergePopoverEntityId: entityId,
    diffMergePopoverEntityType: entityType,
    diffMergePopoverParentEntityId: parentEntityId,
  } = (target as HTMLElement)?.dataset || {};
  if (!branch || !entityId || !entityType) return null;
  return (
    <PopoverDataProvider branch={branch as Branch}>
      {data => (
        <WorkspacePopover
          entityId={entityId as ArdoqId}
          entityType={entityType as APIEntityType}
          parentEntityId={parentEntityId as ArdoqId}
          {...data}
        />
      )}
    </PopoverDataProvider>
  );
};
