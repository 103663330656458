import { action$, reduceState } from '@ardoq/rxbeach';
import reducers from './reducers';
import { SearchPane } from './types';

const DEFAULT_SEARCH_PANE = SearchPane.SEARCH_OVERVIEW;

const defaultState = {
  selectedPane: DEFAULT_SEARCH_PANE,
  isOpenInModal: false,
  searchOverviewPageDict: {},
};

const searchTab$ = action$.pipe(
  reduceState('searchTab$', defaultState, reducers)
);

export default searchTab$;
