import { actionCreator } from '@ardoq/rxbeach';
import { ScheduleFailureNotificationState } from './types';

const namespace = '[integration schedule failure notification]';

export const setUsers = actionCreator<
  ScheduleFailureNotificationState['users']
>(`${namespace} set users`);

export const setSelectedUsers = actionCreator<string[]>(
  `${namespace} set selected users`
);

export const setUpsertResult = actionCreator<
  ScheduleFailureNotificationState['upserResult']
>(`${namespace} set upsert status`);

export const fetchUsers = actionCreator(`${namespace} fetch users`);
export const upsertUsers = actionCreator(`${namespace} upsert users`);
