import { Subdivision } from '@ardoq/api-types';
import { Icon, IconName } from '@ardoq/icons';
import NameWithMenuCell from 'atomicComponents/Table/NameWithMenuCell';
import { SubdivisionCommands } from '../types';
import { formatDateTime } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { FlexBox } from '@ardoq/layout';
import { SecondaryButton } from '@ardoq/button';
import { Drawer, showDrawer } from '@ardoq/snowflakes';
import { SubdivisionSummaryInformation } from './SubdivisionSummaryInformation';

const { OVERVIEW_PAGE } = SUBDIVISIONS_STRINGS;

export const getSubdivisionOverviewTableColumns = (
  commands: SubdivisionCommands
) => {
  return [
    {
      title: OVERVIEW_PAGE.TABLE.DIVISION_NAME,
      dataIndex: 'name',
      valueRender: (_: string, subdivision: Subdivision) => (
        <FlexBox gap="xsmall" align="center">
          <Icon iconName={IconName.CIRCLE} color={subdivision.style.color} />
          <NameWithMenuCell name={subdivision.name} />
        </FlexBox>
      ),
    },
    {
      title: 'Contact email',
      dataIndex: 'createdByEmail',
      valueRender: (createdBy: string) => createdBy,
    },
    {
      title: 'Last updated',
      dataIndex: 'lastUpdated',
      valueRender: (lastUpdated: string) =>
        formatDateTime(lastUpdated, getCurrentLocale()),
    },
    {
      headerStyle: { width: 160 },
      valueRender: (_: any, subdivision: Subdivision) => (
        <SecondaryButton
          onClick={event => {
            event.stopPropagation();
            commands.viewSubdivisionSummary(subdivision);
          }}
        >
          <Icon iconName={IconName.VISIBILITY} />
          View details
        </SecondaryButton>
      ),
    },
    {
      headerStyle: { width: 50 },
      valueRender: (_: any, subdivision: Subdivision) => (
        <div
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <NameWithMenuCell
            menuOptions={[
              {
                label: 'Edit',
                onClick: () => commands.goToSubdivision(subdivision._id),
              },
              {
                label: 'Delete',
                onClick: () => commands.removeSubdivision(subdivision),
              },
            ]}
          />
        </div>
      ),
    },
  ];
};

export const renderSubdivisionSummaryInDrawer = ({
  name,
  description,
}: Subdivision) => {
  showDrawer({
    stackPageName: 'subdivisionOverview',
    renderDrawer: () => (
      <Drawer title={name} subtitle={description} drawerSize="large">
        <SubdivisionSummaryInformation isInDrawer />
      </Drawer>
    ),
    shouldCloseOnEscapeKey: true,
    shouldCloseOnBackdropClick: true,
  });
};
