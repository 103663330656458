import { useEffect, useRef, useState } from 'react';
import { LabeledValue } from 'aqTypes';
import {
  ChooseEntityDialogContentConfig,
  ChooseEntityResult,
  ChooseEntityResultType,
} from './types';
import {
  ModalContent,
  ModalLayout,
  ModalSize,
  ModalTemplate,
} from '@ardoq/modal';
import { CreatableSelect } from '@ardoq/select';

type ChooseEntityDialogContentProps = {
  defaultOptions: LabeledValue[];
  config: ChooseEntityDialogContentConfig;
} & ModalContent;

/**
 * Choose existing item or create a new item
 */
const ChooseEntityDialogContent = ({
  resolve,
  defaultOptions,
  config,
}: ChooseEntityDialogContentProps) => {
  const [labeledValue, setLabeledValue] = useState<LabeledValue<string> | null>(
    null
  );
  const [options, setOptions] = useState<LabeledValue[]>(defaultOptions);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => confirmButtonRef.current!.focus(), [labeledValue]);

  const handleCreateOption = async (inputValue: string) => {
    const newOption: LabeledValue = {
      label: inputValue,
      value: inputValue,
    };
    setOptions([...options, newOption]);
    setLabeledValue(newOption);
  };

  const handleConfirm = async () => {
    if (labeledValue) {
      const { value } = labeledValue;
      const result: ChooseEntityResult = defaultOptions.find(
        item => item.value === value
      )
        ? { type: ChooseEntityResultType.EXISTING, id: value }
        : { type: ChooseEntityResultType.NEW, name: value };
      resolve(result);
    }
  };

  const handleCancel = () => resolve(false);
  return (
    <ModalTemplate
      headerText={config.title}
      onSecondaryButtonClick={handleCancel}
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handleConfirm}
      primaryButtonText={config.confirmButtonTitle}
      primaryButtonClickId="confirm button"
      isPrimaryButtonDisabled={!labeledValue}
      onCloseButtonClick={handleCancel}
      modalSize={ModalSize.S}
      primaryButtonRef={confirmButtonRef}
      fixedHeight={true}
    >
      <ModalLayout>
        <CreatableSelect
          label={config.inputLabel}
          menuPlacement="auto"
          options={options}
          value={labeledValue}
          onCreateOption={handleCreateOption}
          onChange={chosenEntity =>
            setLabeledValue(chosenEntity as LabeledValue)
          }
        />
      </ModalLayout>
    </ModalTemplate>
  );
};

export default ChooseEntityDialogContent;
