import { useState } from 'react';
import { combineLatest } from 'rxjs';
import {
  QueryEditorStateShape,
  advancedSearchEditor$,
} from 'search/QueryEditor/queryEditor$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import {
  exportAdvancedSearch,
  queryAdvancedSearch,
  setAdvancedSearchBranch,
} from './actions';
import AdvancedSearchQueryBuilder, {
  getFullQuery,
} from './AdvancedSearchQueryBuilder';
import advancedSearch$ from './advancedSearch$';
import { BranchedAdvancedSearchStateShape } from './types';
import { getCombinedRules } from './utils';
import { map } from 'rxjs/operators';
import {
  ButtonGroup,
  BrandButton,
  SecondaryButton,
  GhostButton,
} from '@ardoq/button';
import {
  ReportEventLocations,
  ReportTrackingEvents,
} from '@ardoq/report-reader';
import { openCreateReport } from '../../components/AppMainSidebar/utils';
import { APIScenarioAttributes, DataSourceType } from '@ardoq/api-types';
import { AqLayout, Stack } from '@ardoq/layout';
import { ExportIcon, Icon, IconName } from '@ardoq/icons';
import { SearchTabContainer } from '../QueryEditor/atoms';
import QueryActions from '../QueryEditor/QueryActions';
import ResultsView from './ResultsView';
import { logError } from '@ardoq/logging';
import { errorRecovery } from '../SearchTabContainer/utils';
import { ErrorBoundary } from '@ardoq/error-boundary';
import {
  Permissions,
  hasPermission,
} from '../../streams/currentUserPermissions/permissionInterface';
import { hasFeature, Features } from '@ardoq/features';
import MainToolbar from 'menus/topbar/MainToolbar';
import Navbar from 'views/navbar/Navbar';
import { Header4 } from '@ardoq/typography';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import scenarios$ from 'streams/scenarios/scenarios$';
import { CollectionStream } from 'streams/utils/streamUtils';
import styled from 'styled-components';
import { Select } from '@ardoq/select';
import {
  buildScenarioOptions,
  ScenarioSelection,
} from 'utils/searchBranchSelection';

const DropdownContainer = styled.div`
  flex-grow: 1;
  margin-bottom: 20px;
`;

const ChooseScenario = ({
  options,
  selection,
  onChange,
}: ScenarioSelection) => {
  return (
    <DropdownContainer>
      <Select
        label="Scenario"
        value={selection}
        options={options}
        onChange={option => onChange(option?.value ?? null)}
      />
    </DropdownContainer>
  );
};

type ViewProps = BranchedAdvancedSearchStateShape &
  QueryEditorStateShape & {
    ruleErrorMessages?: string[];
    hasNewJourneyFeature: boolean;
    hasAdvancedSearchInScenarios: boolean;
    scenarioSelection: ScenarioSelection;
  };
const AdvancedSearch = ({
  queryBuilderRules,
  isSearching,
  isExporting,
  selectedFilterType,
  hasNewJourneyFeature,
  hasAdvancedSearchInScenarios,
  scenarioSelection,
  branchId,
}: ViewProps) => {
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleCreateReport = () => {
    openCreateReport(ReportEventLocations.AD_HOC_ADVANCED_SEARCH, {
      datasource: DataSourceType.ADVANCED_SEARCH,
      query: getFullQuery({
        selectedFilterType,
        queryBuilderRules,
      }),
    });
  };

  const handleExcelExport = () => {
    dispatchAction(
      exportAdvancedSearch({
        queryBuilderRules: getCombinedRules({
          queryBuilderRules,
          selectedFilterType,
        }),
        filename: 'search-export',
        branchId,
      })
    );
  };

  const pageContent = (
    <ErrorBoundary
      logError={logError}
      errorContextDescription="Search Error Boundary"
      recoverOnError={errorRecovery}
    >
      <SearchTabContainer>
        {hasAdvancedSearchInScenarios && (
          <ChooseScenario {...scenarioSelection} />
        )}
        <AdvancedSearchQueryBuilder
          showErrors={showErrors}
          onChange={({ isValid, isEmpty }) => {
            setIsValid(isValid || isEmpty);
            if (isValid || isEmpty) setShowErrors(false);
          }}
        />
        <Stack gap="medium">
          <QueryActions
            searchButtonText="Search"
            showSpinner={isExporting || isSearching}
            doSearch={() => {
              if (!isValid) {
                setShowErrors(true);
                return;
              }
              dispatchAction(
                queryAdvancedSearch({
                  queryBuilderRules,
                })
              );
            }}
            actionMenuClickId={
              ReportTrackingEvents.AD_HOC_ADVANCED_SEARCH_ACTION_MENU
            }
          />
          {isExporting && (
            <Header4>
              Exporting search, this might take some time for large datasets...
            </Header4>
          )}
        </Stack>
        <ResultsView />
      </SearchTabContainer>
    </ErrorBoundary>
  );

  return hasNewJourneyFeature ? (
    <PageWrapper>
      <Navbar
        primaryContent="Advanced search"
        primaryButton={
          <BrandButton
            isDisabled={!hasPermission(Permissions.REPORTS_CREATE)}
            onClick={handleCreateReport}
          >
            Create report
            <Icon iconName={IconName.ADD} />
          </BrandButton>
        }
        secondaryContent="Analytics"
        secondaryButton={
          <GhostButton
            onClick={handleExcelExport}
            data-tooltip-text="Export to Excel"
          >
            Export <ExportIcon />
          </GhostButton>
        }
        toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
      />
      <PageBody skipPadding backgroundColor="surfaceDefault">
        {pageContent}
      </PageBody>
    </PageWrapper>
  ) : (
    <AqLayout
      title="Advanced search"
      bodyContentStyle={{
        height: '100%',
        position: 'relative',
        padding: 0,
        paddingBottom: 5,
      }}
      renderHeaderButtons={() => (
        <ButtonGroup>
          <GhostButton
            onClick={handleExcelExport}
            data-tooltip-text="Export to Excel"
          >
            <ExportIcon />
          </GhostButton>
          <SecondaryButton
            isDisabled={!hasPermission(Permissions.REPORTS_CREATE)}
            onClick={handleCreateReport}
          >
            Create report
          </SecondaryButton>
        </ButtonGroup>
      )}
    >
      {pageContent}
    </AqLayout>
  );
};

const mapStreamsToViewProps = ([advancedSearch, queryEditor, scenarios]: [
  BranchedAdvancedSearchStateShape,
  QueryEditorStateShape,
  CollectionStream<APIScenarioAttributes>,
]): ViewProps => ({
  ...advancedSearch,
  ...queryEditor,
  scenarioSelection: buildScenarioOptions(
    scenarios,
    advancedSearch.branchId,
    branchId => {
      dispatchAction(setAdvancedSearchBranch(branchId));
    }
  ),
  hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
  hasAdvancedSearchInScenarios: hasFeature(
    Features.DEMO_ADVANCED_SEARCH_IN_SCENARIOS
  ),
});

export default connect(
  AdvancedSearch,
  combineLatest([advancedSearch$, advancedSearchEditor$, scenarios$]).pipe(
    map(mapStreamsToViewProps)
  )
);
