import RelationshipRepresentation from './RelationshipRepresentation';
import { IconName, InfoIcon } from '@ardoq/icons';
import {
  MetaModel,
  SurveyApprovalRelationship,
  SurveyApprovalRelationshipsAudience,
} from '@ardoq/api-types';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { Select } from '@ardoq/select';
import { IconButton } from '@ardoq/button';
import { Box, FlexBox } from '@ardoq/layout';
import { FieldsByComponentTypeName } from 'surveyAdmin/types';
import { isApproverField } from 'surveyAdmin/SurveyEditor/utils';
import { Text } from '@ardoq/typography';
import { WithPopover } from '@ardoq/popovers';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { TableContainer } from './atoms';
import { trackEvent } from 'tracking/tracking';

const EmailPopoverContent = () => (
  <Box maxWidth="300px">
    Users with an email address in the selected field will have access to
    approve survey responses for components they have a relationship with.
  </Box>
);

const getFieldOptions = (
  fieldsByComponentTypeName: FieldsByComponentTypeName,
  relationship: SurveyApprovalRelationship
) => {
  return (fieldsByComponentTypeName[relationship.componentTypeName] ?? [])
    .filter(isApproverField)
    .map(({ label, name }) => ({
      label,
      value: name,
    }));
};

type RelationshipsAudienceDisplayProps = {
  surveyComponentTypeName: string;
  fieldsByComponentTypeName: FieldsByComponentTypeName;
  audience: SurveyApprovalRelationshipsAudience;
  metamodel: MetaModel;
  selectEmailField: (
    relationship: SurveyApprovalRelationship,
    emailField: string
  ) => void;
  unselectRelationship: (relationship: SurveyApprovalRelationship) => void;
};

const RelationshipsAudienceDisplay = ({
  surveyComponentTypeName,
  fieldsByComponentTypeName,
  audience,
  metamodel,
  selectEmailField,
  unselectRelationship,
}: RelationshipsAudienceDisplayProps) => {
  const relationships = audience.relationships;
  return (
    <TableContainer>
      <DatasourceTable
        style={{ width: '100%' }}
        columns={[
          {
            title: 'Main approver',
            cellStyle: {
              width: '60%',
              padding: `${s8} ${s16}`,
            },
            valueRender: (_, relationship) => (
              <RelationshipRepresentation
                surveyComponentTypeName={surveyComponentTypeName}
                relationship={relationship}
                metamodel={metamodel}
              />
            ),
          },
          {
            headerRender: () => (
              <FlexBox align="center" gap="small">
                Email field
                <WithPopover content={() => <EmailPopoverContent />}>
                  <InfoIcon color={colors.icon} />
                </WithPopover>
              </FlexBox>
            ),
            cellStyle: {
              width: '30%',
              padding: `${s8} ${s16}`,
            },
            valueRender: (_, relationship) => (
              <Select
                options={getFieldOptions(
                  fieldsByComponentTypeName,
                  relationship
                )}
                value={relationship.emailField}
                onValueChange={emailField => {
                  if (!emailField) return;
                  selectEmailField(relationship, emailField);
                }}
              />
            ),
          },
          {
            cellStyle: {
              width: '10%',
              padding: `${s8} ${s16}`,
            },
            valueRender: (value, relationship) => (
              <FlexBox justify="end">
                <IconButton
                  iconName={IconName.DELETE}
                  onClick={() => {
                    unselectRelationship(relationship);
                    trackEvent(
                      'Change Approvals0 delete relationship audience member'
                    );
                  }}
                />
              </FlexBox>
            ),
          },
        ]}
        components={newTableTheme}
        dataSource={relationships}
        renderEmptyTable={() => (
          <Text variant="text1Italic" color="textSubtle" align="center">
            No relationship selected yet
          </Text>
        )}
      />
    </TableContainer>
  );
};

export default RelationshipsAudienceDisplay;
