import {
  AsDatetimeTransformation,
  AsDateTransformation,
  Transformation,
} from '@ardoq/api-types/integrations';
import { TextInput } from '@ardoq/forms';
import { Link, Paragraph } from '@ardoq/typography';
import { FlexBox, Stack } from '@ardoq/layout';

type AsDateTransformationProps = {
  transformation: AsDateTransformation | AsDatetimeTransformation;
  setTransformation: (newTransformation: Transformation) => void;
  exampleFormat: string;
  exampleInput: string;
};

const AsDateTransformationSection = ({
  transformation,
  setTransformation,
  exampleFormat,
  exampleInput,
}: AsDateTransformationProps) => {
  const onMatchChange = (value: string) => {
    setTransformation({
      ...transformation,
      config: {
        ...transformation.config,
        formatString: value,
      },
    });
  };

  return (
    <Stack gap="medium">
      <Paragraph variant="text2">
        Parse date values using the provided format.&nbsp;
        <Link
          typography="text2"
          href="https://docs.oracle.com/javase/8/docs/api/java/time/format/DateTimeFormatter.html"
          target="_blank"
        >
          Reference documentation
        </Link>
      </Paragraph>

      <FlexBox gap="medium" marginBottom="medium">
        <FlexBox justify="center" padding="small">
          <Paragraph variant="text1">Format string</Paragraph>
        </FlexBox>
        <Stack gap="small">
          <TextInput
            value={transformation.config.formatString}
            onValueChange={onMatchChange}
          />
          <Paragraph variant="caption" color="textSubtle">
            Example: the format &quot;{exampleFormat}&quot;
            <br />
            can parse dates like &quot;{exampleInput}&quot;
          </Paragraph>
        </Stack>
      </FlexBox>
    </Stack>
  );
};

export default AsDateTransformationSection;
