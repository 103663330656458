import { SubdivisionProps } from 'subdivisionEditor/types';
import { GroupPermissionNewItem } from './zones/components/GroupPermissionNewItem';
import { GroupPermissionTable } from './zones/components/GroupPermissionTable';

export const NewPermissionsConfiguration = (props: SubdivisionProps) => {
  const { allGroups, permissionsByZoneId, zonesById, currentUser, commands } =
    props.zonesState;
  return (
    <>
      <GroupPermissionNewItem
        groups={allGroups}
        permissionsByZoneId={permissionsByZoneId}
        currentUser={currentUser}
        zonesById={zonesById}
        commands={commands}
      />
      <GroupPermissionTable
        zonesById={zonesById}
        permissionsByZoneId={permissionsByZoneId}
        commands={commands}
      />
    </>
  );
};
