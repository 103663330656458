import {
  APICurrentUser,
  APIOrganizationUser,
  APIResourcePermissionAttributes,
  ArdoqId,
  isReport,
  PermissionGroup,
  Report,
  ResourceType,
  Workspace,
} from '@ardoq/api-types';
import { permissionApi } from '@ardoq/api';
import { isArdoqError, isJust, Maybe } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import {
  prepareResourceGroupsPermissions,
  prepareResourceUserPermissions,
} from '../../resourcePermissions/viewModel$';
import {
  getContributorGroupRole,
  getMemberGroupRole,
} from '@ardoq/manage-resource-permissions';
import {
  AdHocSearchReport,
  reportBuilderOperations,
  ReportPermissions,
} from '@ardoq/report-builder';
import currentUser$, {
  CurrentUserState,
} from '../../streams/currentUser/currentUser$';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import reports$ from '../../streams/reports/reports$';
import permissionGroup$ from '../../admin/accessControl/PermissionGroups/streams/permissionGroups$';
import { InitializeReportBuilderForm$State } from './types';
import reportNavigation$ from '../navigation/reportNavigation$';
import { isEmpty, isEqual, pick } from 'lodash';
import { ReportModule } from '../types';
import { dispatchAction } from '@ardoq/rxbeach';
import { reportSearchWasTriggered } from './actions';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import workspaces$ from 'streams/workspaces/workspaces$';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { SubdivisionsStreamShape } from 'streams/subdivisions/types';
import { Features, hasFeature } from '@ardoq/features';
import { PermissionContext } from '@ardoq/access-control';
import reportBuilderForm$ from './reportBuilderForm$';

type ReportBuilderFormResourcePermissions = ReportPermissions & {
  existingResourcePermissions: Maybe<APIResourcePermissionAttributes>;
};

const getPermissionsForExistingReport = async ({
  persistedReport,
  currentUser,
  usersById,
  groupsById,
}: {
  persistedReport: Report;
  currentUser: APICurrentUser;
  groupsById: Record<string, PermissionGroup>;
  usersById: Record<string, APIOrganizationUser>;
}): Promise<ReportBuilderFormResourcePermissions | null> => {
  const existingResourcePermissions = await permissionApi.getResourcePermission(
    {
      resourceId: persistedReport._id,
      resourceType: ResourceType.REPORT,
    }
  );
  if (isArdoqError(existingResourcePermissions)) {
    logError(existingResourcePermissions);
    return null;
  }
  const userPermissions = prepareResourceUserPermissions(
    existingResourcePermissions.permissions,
    ResourceType.REPORT,
    usersById,
    currentUser._id
  ).map(permission => ({
    ...permission,
  }));
  const orgLabel = currentUser.organization.label;
  const groupPermissions = prepareResourceGroupsPermissions(
    existingResourcePermissions.groups ?? [],
    ResourceType.REPORT,
    groupsById,
    getContributorGroupRole(orgLabel),
    getMemberGroupRole(orgLabel)
  ).map(permission => ({
    ...permission,
  }));
  return {
    userPermissions,
    groupPermissions,
    existingResourcePermissions,
  } as ReportBuilderFormResourcePermissions;
};

type CommonInitializeReportState = {
  persistedReport: Maybe<Report>;
  permissions: Maybe<ReportBuilderFormResourcePermissions>;
  adHocSearchReport: Maybe<AdHocSearchReport>;
  workspaces: Array<Workspace>;
  subdivisionsContext: SubdivisionsStreamShape;
  permissionsContext: PermissionContext;
  hasZonesLoaded: boolean;
  hasWorkspacesLoaded: boolean;
  hasSubdivisionsLoaded: boolean;
};

type GetReportPermissionsArgs = {
  currentUser: CurrentUserState;
  groupsById: Record<string, PermissionGroup>;
  usersById: Record<string, APIOrganizationUser>;
} & CommonInitializeReportState;

const getReportPermissions = async ({
  persistedReport,
  currentUser,
  usersById,
  groupsById,
  ...rest
}: GetReportPermissionsArgs): Promise<
  {
    permissions: Maybe<ReportBuilderFormResourcePermissions>;
  } & CommonInitializeReportState
> => {
  if (!isJust(persistedReport)) {
    return {
      persistedReport,
      ...rest,
      permissions: {
        ...reportBuilderOperations.getPermissionsForNewReport(currentUser),
        existingResourcePermissions: null,
      },
    };
  }
  const permissions = await getPermissionsForExistingReport({
    persistedReport,
    currentUser,
    usersById,
    groupsById,
  });
  return { persistedReport, ...rest, permissions }; // permissions  should come after ...rest to override default null value
};

type GetPersistedReportWithPermissionsAndReportTemplateArgs = {
  permissions: Maybe<ReportBuilderFormResourcePermissions>;
} & CommonInitializeReportState;

const getPersistedReportWithPermissionsAndReportTemplate = ({
  persistedReport,
  permissionsContext,
  permissions,
  adHocSearchReport,
  workspaces,
  subdivisionsContext,
}: GetPersistedReportWithPermissionsAndReportTemplateArgs): InitializeReportBuilderForm$State => {
  const { userPermissions, groupPermissions } = permissions ?? {
    userPermissions: [],
    groupPermissions: [],
  };
  const persistedReportWithPermissions = isReport(persistedReport)
    ? {
        ...persistedReport,
        userPermissions,
        groupPermissions,
      }
    : null;

  const reportTemplate = {
    ...reportBuilderOperations.getInitialState({
      persistedReport,
      adHocSearchReport,
      workspaceIds: workspaces.map(({ _id }) => _id),
      subdivisionsContext,
      hasSubdivisionsFeature: hasFeature(Features.PERMISSION_ZONES),
    }),
    userPermissions,
    groupPermissions,
  };

  const selectedWorkspacesWithNoAccess = reportTemplate.workspaceIds.filter(
    workspaceId =>
      !workspaceAccessControlInterface.canAccessWorkspace(
        permissionsContext,
        workspaceId
      )
  );
  const hasWorkspacesWithNoAccess = Boolean(
    selectedWorkspacesWithNoAccess.length
  );

  return {
    persistedReport: persistedReportWithPermissions,
    reportTemplate,
    isReportBuilderLoading: false,
    existingResourcePermissions:
      permissions?.existingResourcePermissions ?? null,
    shouldTriggerSearchOnLoad: Boolean(
      (persistedReportWithPermissions || adHocSearchReport) &&
        !hasWorkspacesWithNoAccess
    ),
    selectedWorkspacesWithNoAccess,
    hasWorkspacesWithNoAccess,
  };
};

const isReportBuilderOpenAndReportsLoaded = ({
  reportId,
  reportModule,
  byId,
}: Omit<CommonInitializeReportState, 'persistedReport'> & {
  reportId: ArdoqId | undefined;
  reportModule: ReportModule;
  byId: Record<string, Report>;
}) =>
  Boolean(
    reportModule === ReportModule.BUILDER &&
      (!reportId || (reportId && !isEmpty(byId)))
  );

const stateAttributesThatShouldEmit: Array<keyof CommonInitializeReportState> =
  [
    'persistedReport',
    'adHocSearchReport',
    'hasWorkspacesLoaded',
    'hasSubdivisionsLoaded',
    'hasZonesLoaded',
    'permissions',
  ];

const initializeReport$ = combineLatest([
  reports$,
  reportNavigation$,
  workspaces$,
  subdivisions$,
  currentUserPermissionContext$,
]).pipe(
  map(
    ([
      { byId },
      { reportId, datasource, query, reportModule, columns, workspaceIds },
      workspaces,
      subdivisionsContext,
      permissionsContext,
    ]) => {
      const availableWorkspaces = Object.values(workspaces.byId).filter(
        workspace =>
          workspaceAccessControlInterface.canAccessWorkspace(
            permissionsContext,
            workspace._id
          )
      );

      const adHocSearchReport = // This is what you get when you start a new report from ad hoc advanced search, gremlin search or inventory
        datasource && query
          ? ({
              datasource,
              query,
              ...(columns ? { columns } : {}),
              ...(workspaceIds ? { workspaceIds } : {}),
            } as AdHocSearchReport) // DataSourceType enum is making this cast necessary
          : null;

      return {
        reportId,
        adHocSearchReport,
        reportModule,
        byId,
        workspaces: availableWorkspaces,
        hasWorkspacesLoaded: Boolean(availableWorkspaces.length), // hasWorkspacesLoaded, hasZonesLoaded and hasSubdivisionsLoaded is just here to trigger the distinctUntilChanged()
        // to emit when they have loaded so that we can preselect workspaces and divisions
        hasZonesLoaded: Boolean(
          subdivisionsContext.zonesContext.sortedIds.length
        ),
        hasSubdivisionsLoaded: Boolean(subdivisionsContext.sortedIds.length),
        subdivisionsContext,
        permissionsContext,
        permissions: null,
      };
    }
  ),
  filter(isReportBuilderOpenAndReportsLoaded),
  map(({ reportId, byId, ...rest }) => ({
    persistedReport: reportId && byId[reportId] ? byId[reportId] : null,
    byId,
    ...rest,
  })),
  withLatestFrom(currentUser$, permissionGroup$, orgUsers$),
  switchMap(
    ([
      { persistedReport, ...rest },
      currentUser,
      { groupsById },
      { byId: usersById },
    ]) =>
      getReportPermissions({
        persistedReport,
        currentUser,
        groupsById,
        usersById,
        ...rest,
      })
  ),
  distinctUntilChanged(
    (
      a: CommonInitializeReportState,
      b: CommonInitializeReportState
    ): boolean => {
      const comparableStateA = pick(a, stateAttributesThatShouldEmit);
      const comparableStateB = pick(b, stateAttributesThatShouldEmit);
      // when report builder form initially loads or its state is reset due to report builder changes are discarded before exiting,
      // reportBuilderForm$.state.isInitialized is false, should run getPersistedReportWithPermissionsAndReportTemplate below
      return (
        reportBuilderForm$.state.isInitialized &&
        isEqual(comparableStateA, comparableStateB)
      );
    }
  ),
  map(getPersistedReportWithPermissionsAndReportTemplate),
  tap(({ reportTemplate, shouldTriggerSearchOnLoad }) => {
    const sort = reportBuilderOperations.getSortKeyAndOrder(reportTemplate);
    if (shouldTriggerSearchOnLoad)
      setTimeout(() => dispatchAction(reportSearchWasTriggered(sort))); // setTimeout so that reportBuilderForm$ can be initialized first
  })
);

export default initializeReport$;
