import styled from 'styled-components';
import { s16 } from '@ardoq/design-tokens';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { APIRoleAttributes, Privilege, PrivilegeLabel } from '@ardoq/api-types';
import { getPrivilegeFilterOptions, getRoleFilterOptions } from './utils';
import { Icon, IconName } from '@ardoq/icons';
import { TextInput } from '@ardoq/forms';
import { IconBadge, StatusType } from '@ardoq/status-ui';
import { ButtonType } from '@ardoq/button';

const FilterSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${s16} 0;
`;

type FilterSectionProps = {
  configurablePrivileges: Privilege[];
  roles: APIRoleAttributes[];
  privilegeFilters: PrivilegeLabel[];
  roleFilters: string[];
  onPrivilegeFilterChange: (filters: PrivilegeLabel[]) => void;
  onRoleFilterChange: (filters: string[]) => void;
  searchPhrase: string;
  onSearchPhraseChange: (searchPhrase: string) => void;
  trackOnClick?: () => void;
};

export const FilterSection = ({
  configurablePrivileges,
  roles,
  privilegeFilters,
  roleFilters,
  onPrivilegeFilterChange,
  onRoleFilterChange,
  searchPhrase,
  onSearchPhraseChange,
  trackOnClick,
}: FilterSectionProps) => {
  const filterCount = privilegeFilters.length + roleFilters.length;
  return (
    <FilterSectionContainer>
      <TextInput
        leftIconName={IconName.SEARCH}
        onValueChange={onSearchPhraseChange}
        placeholder="Search table..."
        value={searchPhrase}
      />
      <ButtonWithDropdown
        buttonType={ButtonType.SECONDARY}
        alignMenuTo="right"
        hideChevron
        isKeepOpen={true}
        onClick={trackOnClick}
        options={[
          ...getPrivilegeFilterOptions(
            configurablePrivileges,
            privilegeFilters,
            onPrivilegeFilterChange
          ),
          ...getRoleFilterOptions(roles, roleFilters, onRoleFilterChange),
        ]}
      >
        {filterCount > 0 ? (
          <IconBadge
            iconName={IconName.FILTER_LIST}
            statusType={StatusType.NEW}
            style={{ margin: '0 8px' }}
            iconStyle={{ margin: 0 }}
          />
        ) : (
          <Icon iconName={IconName.FILTER_LIST} />
        )}
        {filterCount > 0 ? `Filter (${filterCount})` : 'Filter'}
      </ButtonWithDropdown>
    </FilterSectionContainer>
  );
};
