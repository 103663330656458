import { ResourceGroup } from '@ardoq/api-types/integrations';
import { TablesSelectionTablePayload } from '@ardoq/integrations';
import { Subscription } from 'integrations/azure/streams/subscriptions/types';
import { byLocaleField } from 'integrations/common/utils/common';

export const defaultTexts = {
  searchLabel: '',
  searchText: `Here is the list of resources available in your integration, select the resources and fields you need.\nTips: Resource can be used more than once`,
  searchPlaceholder: 'Search for resource groups',
  labelColumnTitle: 'Resource group',
  nameColumnTitle: 'Subscription',
  selectedTitle: '',
  availableTitle: '',
  selectedCount: '',
};

/**
 * Creates a data source for the resource group selector.
 * @param resourceGroups - The list of resource groups.
 * @param azureSubscriptions - The list of Azure subscriptions.
 * @param selectedSubscriptionIds - The list of selected subscription IDs.
 * @returns The data source for the resource group selector.
 */
export function createDataSource(
  resourceGroups: ResourceGroup[],
  azureSubscriptions: Subscription[],
  selectedSubscriptionIds: string[]
): TablesSelectionTablePayload[] {
  return resourceGroups
    .filter(rg => selectedSubscriptionIds.includes(rg.subscriptionId))
    .map(rg => {
      const subscription = azureSubscriptions.find(
        subscription => subscription.subscriptionId === rg.subscriptionId
      );
      return {
        canRead: true,
        canWrite: true,
        canDelete: true,
        canCreate: true,
        label: rg.name,
        id: rg.name,
        name: subscription?.subscriptionName || '',
      };
    })
    .sort(byLocaleField('label'));
}
