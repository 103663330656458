import { reducedStream, reducer, streamReducer } from '@ardoq/rxbeach';
import {
  ComponentSearchState,
  ComponentSearchViewState,
  SelectedComponent,
} from './types';
import {
  applyFiltersForContextSwitcher,
  ApplyFiltersForContextSwitcherPayload,
  removeUnselectedLoadedComponents,
  setAllComponentsSelected,
  setComponentName,
  setComponentTypeName,
  setHasSearchResults,
  setIsAdvancedSearch,
  setIsLoading,
  setLoadedComponents,
  SetLoadedComponentsPayload,
  setSelectedComponents,
  setStartQuery,
  setStartSetFilter,
  setTotalCount,
  toggleSingleComponentSelection,
  updateViewpointBuilderAdvancedSearchQuery,
} from './actions';
import { componentSearchOperations } from './componentSearchOperations';
import { toViewState } from './toViewState';
import { map, Observable, shareReplay } from 'rxjs';
import { selectViewpointBuilderContext } from 'viewpointBuilder/viewpointBuilderNavigation/actions';
import { metaModelAsScopeData$ } from 'viewpointBuilder/metaModel/loadMetaModelAsScopeData$';
import { resetAllSearchState } from 'search/actions';
import { setStateProperty } from '@ardoq/common-helpers';

export const initialState: ComponentSearchState = {
  componentName: '',
  componentType: '',
  loadedComponents: [],
  selectedComponents: [],
  isLoading: false,
  hasSearchResults: false,
  totalCount: 0,
  startQuery: null,
  canSelectOnlyComponentType: false,
  componentTypes: [],
  advancedSearchState: null,
  selectionClearedOnSelectAllCount: null,
  startSetFilter: null,
};

export const componentSearch$: Observable<ComponentSearchViewState> =
  reducedStream<ComponentSearchState>('componentSearch$', initialState, [
    streamReducer(
      metaModelAsScopeData$,
      componentSearchOperations.processMetaModel
    ),
    reducer<ComponentSearchState, string>(
      setComponentName,
      componentSearchOperations.setComponentName
    ),
    reducer<ComponentSearchState, string>(
      setComponentTypeName,
      componentSearchOperations.setComponentTypeName
    ),
    reducer<ComponentSearchState, SetLoadedComponentsPayload>(
      setLoadedComponents,
      componentSearchOperations.setLoadedComponents
    ),
    reducer<ComponentSearchState, SelectedComponent[]>(
      setSelectedComponents,
      componentSearchOperations.setSelectedComponents
    ),
    reducer<ComponentSearchState, boolean>(
      setAllComponentsSelected,
      componentSearchOperations.setAllComponentsSelected
    ),
    reducer<ComponentSearchState, ApplyFiltersForContextSwitcherPayload>(
      applyFiltersForContextSwitcher,
      componentSearchOperations.applyFiltersForContextSwitcher
    ),
    reducer<ComponentSearchState, string>(
      toggleSingleComponentSelection,
      componentSearchOperations.toggleSingleComponentSelection
    ),
    reducer<ComponentSearchState>(
      removeUnselectedLoadedComponents,
      componentSearchOperations.removeUnselectedLoadedComponents
    ),
    reducer<ComponentSearchState, boolean>(
      setIsLoading,
      componentSearchOperations.setIsLoading
    ),
    reducer<ComponentSearchState, boolean>(
      setHasSearchResults,
      componentSearchOperations.setHasSearchResults
    ),
    reducer(setTotalCount, componentSearchOperations.setTotalCount),
    reducer(setStartQuery, componentSearchOperations.setStartQuery),
    reducer(
      selectViewpointBuilderContext,
      componentSearchOperations.setCanSelectOnlyComponentType
    ),
    reducer(setIsAdvancedSearch, componentSearchOperations.setIsAdvancedSearch),
    reducer(
      updateViewpointBuilderAdvancedSearchQuery,
      componentSearchOperations.updateViewpointBuilderAdvancedSearchQuery
    ),
    reducer(
      resetAllSearchState,
      componentSearchOperations.clearComponentSearchState
    ),
    reducer(setStartSetFilter, setStateProperty('startSetFilter')),
  ]).pipe(map(toViewState), shareReplay({ bufferSize: 1, refCount: true }));
