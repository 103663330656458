import { CheckboxGroup } from '@ardoq/forms';
import { FilterSections, sectionIconNamesMap } from '../consts';
import { ChangeTypeFilterConfig, ChangeTypeSectionProps } from '../types';
import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { APIEntityType, AuditLogEntityType } from '@ardoq/api-types';

import ChangeTypeFilter from './ChangeTypeFilter';

const ChangeTypeSection = ({
  selected,
  onCheckboxValueChange,
  onSelectValueChange,
  loadComponentOptions,
  loadComponentTypeOptions,
  loadReferenceOptions,
  loadReferenceTypeOptions,
  loadFieldOptions,
  loadWorkspaceOptions,
}: ChangeTypeSectionProps) => {
  // Present the options in the desired order with the right options
  const entityTypeConfig: Array<ChangeTypeFilterConfig> = [
    {
      entityType: APIEntityType.COMPONENT,
      loadOptions: loadComponentOptions,
    },
    {
      entityType: APIEntityType.COMPONENT_TYPE,
      loadOptions: loadComponentTypeOptions,
    },
    { entityType: APIEntityType.REFERENCE, loadOptions: loadReferenceOptions },
    {
      entityType: APIEntityType.REFERENCE_TYPE,
      loadOptions: loadReferenceTypeOptions,
    },
    {
      entityType: APIEntityType.FIELD,
      loadOptions: loadFieldOptions,
    },
    {
      entityType: APIEntityType.WORKSPACE,
      loadOptions: loadWorkspaceOptions,
    },
  ];
  const selectedKeys = Object.entries(selected)
    .filter(([_, value]) => value !== null)
    .map(([key]) => key);

  return (
    <SidebarMenuSection
      title={FilterSections.CHANGE_TYPE}
      leftIcon={{ name: sectionIconNamesMap[FilterSections.CHANGE_TYPE] }}
    >
      <CheckboxGroup
        value={selectedKeys}
        onValueChange={entityTypes =>
          onCheckboxValueChange(entityTypes as AuditLogEntityType[])
        }
      >
        {entityTypeConfig.map(config => (
          <ChangeTypeFilter
            selectedOptions={selected[config.entityType]}
            onSelectValueChange={onSelectValueChange}
            key={config.entityType}
            {...config}
          />
        ))}
      </CheckboxGroup>
    </SidebarMenuSection>
  );
};

export default ChangeTypeSection;
