import { header2, text2 } from '@ardoq/typography';
import { styled } from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { Box, FlexBox, Stack } from '@ardoq/layout';

type FullHeightSidebarProps = {
  title: string;
  subtitle?: string;
  rightContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  children: React.ReactNode;
  titleId?: string; // for accessibility purposes
};

// TODO AM move this to a package to Design System
export const FullHeightSidebar = ({
  title,
  rightContent,
  subtitle,
  children,
  footerContent,
  titleId,
}: FullHeightSidebarProps) => {
  return (
    <FullHeight>
      <Box padding="large" paddingBottom="medium">
        <Stack gap="small">
          <FlexBox align="baseline" justify="space-between" gap="small">
            <Title id={titleId}>{title}</Title>
            {rightContent}
          </FlexBox>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Stack>
      </Box>

      <Box paddingX="medium">{children}</Box>

      <FooterContentWrapper>{footerContent}</FooterContentWrapper>
    </FullHeight>
  );
};

const FullHeight = styled.aside`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.h2`
  ${header2};
  color: ${colors.grey15};
`;

const Subtitle = styled.span`
  ${text2};
  color: ${colors.grey35};
`;

const FooterContentWrapper = styled.div`
  margin-top: auto; /* puts the footer at the bottom of the sidebar */
`;
