import {
  ArdoqId,
  CreateViewpointAttributes,
  CreateViewpointInFolderAttributes,
  FilterInterfaceFilter,
  FormattingFilter,
  GroupingRule,
  LoadedStateHash,
  ViewIds,
  UpdateTraversalAttributes,
  Traversal,
} from '@ardoq/api-types';
import { ComponentSearchData } from 'viewpointBuilder/selectContextTab/types';
import { actionCreator } from '@ardoq/rxbeach';
import { ViewpointBuilderContext } from './types';

export type OpenViewpointBuilderWithComponentsPayload = {
  componentIds: string[];
  components?: ComponentSearchData[];
};

export const openViewpointBuilderWithComponents =
  actionCreator<OpenViewpointBuilderWithComponentsPayload>(
    '[viewpointBuilder] OPEN_VIEWPOINT_BUILDER_WITH_COMPONENTS'
  );

export const openViewpointBuilderWithComponentsAndCloseLoadedViewpoints =
  actionCreator<OpenViewpointBuilderWithComponentsPayload>(
    '[viewpointBuilder] OPEN_VIEWPOINT_BUILDER_WITH_COMPONENTS_AND_CLOSE_LOADED_VIEWPOINTS'
  );

export const updateViewpoint = actionCreator<UpdateTraversalAttributes>(
  '[viewpointBuilder] UPDATE_VIEWPOINT'
);

export const updateViewpointSuccess = actionCreator(
  '[viewpointBuilder] UPDATE_VIEWPOINT_SUCCESS'
);

export const updateViewpointFailure = actionCreator(
  '[viewpointBuilder] UPDATE_VIEWPOINT_FAILURE'
);

export const createViewpoint = actionCreator<CreateViewpointAttributes>(
  '[viewpointBuilder] CREATE_VIEWPOINT'
);

export const createViewpointInFolder =
  actionCreator<CreateViewpointInFolderAttributes>(
    '[viewpointBuilder] CREATE_VIEWPOINT_IN_FOLDER'
  );

export const createViewpointSuccess = actionCreator<ArdoqId>(
  '[viewpointBuilder] CREATE_VIEWPOINT_SUCCESS'
);

export const createViewpointFailure = actionCreator(
  '[viewpointBuilder] CREATE_VIEWPOINT_FAILURE'
);

export type EditViewpointPayload = {
  loadedStateHash: LoadedStateHash;
  context?: ViewpointBuilderContext;
};

export const editViewpoint = actionCreator<EditViewpointPayload>(
  '[viewpointBuilder] EDIT_VIEWPOINT'
);

export type OpenViewpointPayload = {
  viewpointId: ArdoqId;
  components?: ComponentSearchData[];
};

export const openViewpoint = actionCreator<OpenViewpointPayload>(
  '[viewpointBuilder] OPEN_VIEWPOINT'
);

export const openViewpointBuilderAndCloseLoadedViewpoints = actionCreator(
  '[viewpointBuilder] OPEN_VIEWPOINT_BUILDER_AND_CLOSE_LOADED_VIEWPOINTS'
);
export const openViewpointBuilderWithoutClosingLoadedViewpoints = actionCreator(
  '[viewpointBuilder] OPEN_VIEWPOINT_BUILDER_WITHOUT_CLOSING_LOADED_VIEWPOINTS'
);

type ExecuteViewpointSharedPayload = Traversal & {
  /**
   * Present if the viewpoint is already loaded and we're editing it.
   */
  loadedStateHash?: LoadedStateHash;
};

export type ExecuteUnsavedViewpointPayload = ExecuteViewpointSharedPayload & {
  type: 'EXECUTE_UNSAVED_VIEWPOINT';
  /**
   * We pass currentView if we don't want to change the view. It's possible that we could solve it in a better way than passing it here.
   */
  viewName?: ViewIds;
};

export type ExecuteSavedViewpointPayload = ExecuteViewpointSharedPayload & {
  type: 'EXECUTE_SAVED_VIEWPOINT';
  groupBys: GroupingRule[];
  conditionalFormatting: (FormattingFilter | FilterInterfaceFilter)[];
  viewpointId: ArdoqId;
  viewName: ViewIds;
};

export type ExecuteViewpointPayload =
  | ExecuteUnsavedViewpointPayload
  | ExecuteSavedViewpointPayload;

export const executeViewpoint = actionCreator<ExecuteViewpointPayload>(
  '[viewpointBuilder] EXECUTE_VIEWPOINT'
);
