import { dispatchAction } from '@ardoq/rxbeach';
import {
  createServiceAccount,
  deleteAccount,
  regenerateToken,
  updateSearchKey,
} from './actions';
import {
  APIServiceAccount,
  CreateServiceAccountAndTokenPayload,
} from '@ardoq/api-types';
import { confirm, ModalSize } from '@ardoq/modal';
import {
  SERVICE_ACCOUNTS_EVENTS,
  trackServiceAccountsEvent,
} from './serviceAccountsTrackingUtils';

const createToken = (input: CreateServiceAccountAndTokenPayload) => {
  dispatchAction(createServiceAccount(input));
  trackServiceAccountsEvent(
    SERVICE_ACCOUNTS_EVENTS.CREATED_NEW_SERVICE_ACCOUNT,
    { role: input.role }
  );
};

const confirmRegenerateToken = async (serviceAccount: APIServiceAccount) => {
  trackServiceAccountsEvent(SERVICE_ACCOUNTS_EVENTS.CLICKED_REGENERATE_TOKEN);
  if (
    await confirm({
      title: `Regenerate token?`,
      text: `If you continue the current token for the service account ${serviceAccount.user.name} will be invalidated, 
        and a new one will be generated. Make sure to copy the new token as it will only be shown once.`,
      modalSize: ModalSize.S,
      confirmButtonTitle: 'Regenerate token',
      cancelButtonTitle: 'Close',
    })
  ) {
    dispatchAction(regenerateToken(serviceAccount.token));
    trackServiceAccountsEvent(SERVICE_ACCOUNTS_EVENTS.REGENERATED_TOKEN);
  }
};

const confirmDeleteAccount = async (account: APIServiceAccount['user']) => {
  trackServiceAccountsEvent(
    SERVICE_ACCOUNTS_EVENTS.CLICKED_DELETE_SERVICE_ACCOUNT
  );
  if (
    await confirm({
      title: `Permanently delete service account?`,
      text: `You are about to permanently delete the service account ${account.name}.`,
      text2: `This action can’t be undone, and any integrations using its token will stop working.`,
      modalSize: ModalSize.S,
      isConfirmButtonDanger: true,
      confirmButtonTitle: 'Delete',
      cancelButtonTitle: 'Keep',
    })
  ) {
    dispatchAction(deleteAccount(account._id));
    trackServiceAccountsEvent(SERVICE_ACCOUNTS_EVENTS.DELETED_SERVICE_ACCOUNT);
  }
};

const changeSearchKey = (newKey: string) => {
  dispatchAction(updateSearchKey(newKey));
};

export const manageServiceAccountsCommands = {
  createToken,
  confirmRegenerateToken,
  confirmDeleteAccount,
  changeSearchKey,
};

export type ManageServiceAccountsCommands =
  typeof manageServiceAccountsCommands;
