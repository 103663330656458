import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { AsyncStatus } from 'integrations/common/types/api';
import { SubscriptionsState } from './types';
import {
  fetchAzureSubscriptionsFailure,
  fetchAzureSubscriptionsSuccess,
  FetchAzureSubscriptionsSuccessPayload,
  updateSubscriptionList,
  resetAzureSubscriptions,
} from './actions';

const defaultState: SubscriptionsState = {
  asyncStatus: 'INIT',
  subscriptions: [],
};

const resetSubscriptions = () => defaultState;

const handleResetSubscriptions = reducer(
  resetAzureSubscriptions,
  resetSubscriptions
);

const setAsyncStatus =
  (asyncStatus: AsyncStatus) => (state: SubscriptionsState) => {
    return {
      ...state,
      asyncStatus,
    };
  };

const handleFetchAzureSubscriptionsFailure = reducer<SubscriptionsState>(
  fetchAzureSubscriptionsFailure,
  setAsyncStatus('FAILURE')
);

const fetchAzureSubscriptionsSuccessReducer = (
  state: SubscriptionsState,
  { subscriptions }: FetchAzureSubscriptionsSuccessPayload
): SubscriptionsState => {
  return {
    ...state,
    subscriptions,
    asyncStatus: 'SUCCESS',
  };
};
const handleFetchAzureSubscriptionsSuccess = reducer<
  SubscriptionsState,
  FetchAzureSubscriptionsSuccessPayload
>(fetchAzureSubscriptionsSuccess, fetchAzureSubscriptionsSuccessReducer);

const updateSubscriptionListReducer = (
  state: SubscriptionsState,
  payload: Partial<SubscriptionsState>
) => {
  return {
    ...state,
    ...payload,
  };
};
const handleListSubscriptions = reducer<
  SubscriptionsState,
  Partial<SubscriptionsState>
>(updateSubscriptionList, updateSubscriptionListReducer);

export const azureSubscriptions$ = persistentReducedStream(
  `azureSubscriptions$`,
  defaultState,
  [
    handleListSubscriptions,
    handleFetchAzureSubscriptionsFailure,
    handleFetchAzureSubscriptionsSuccess,
    handleResetSubscriptions,
  ]
);
