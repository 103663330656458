import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  filter,
  forkJoin,
  from,
  fromEvent,
  map,
  merge,
  of,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { startOAuthFlow, completedOAuthFlow } from './actions';
import { integrationsOAuthApi, handleError } from '@ardoq/api';
import { isValidOAuthProvider } from './utils';
import { trackEvent } from 'tracking/tracking';
import { ANALYTIC_EVENTS } from './const';

const TIMEOUT_MS = 1000 * 60 * 5;

const OAUTH_WINDOW_TARGET = 'oauth-popup';
const OAUTH_POPUP_WIDTH = 600;
const OAUTH_POPUP_HEIGHT = 700;

const handleStartOAuthFlow = routine(
  ofType(startOAuthFlow),
  extractPayload(),
  tap(({ provider, operation }) => {
    trackEvent(ANALYTIC_EVENTS.STARTED_OAUTH_FLOW, { provider, operation });
  }),
  switchMap(({ provider, operation, onCompleted }) =>
    forkJoin({
      response: from(integrationsOAuthApi.authorize(provider)).pipe(
        handleError(() => {
          trackEvent(ANALYTIC_EVENTS.FAILED_OAUTH_FLOW, {
            provider,
            operation,
            reason: 'failed /authorized endpoint',
          });
        })
      ),
      onCompleted: of(onCompleted),
      provider: of(provider),
      operation: of(operation),
    })
  ),
  tap(({ response: { authUri }, onCompleted, provider, operation }) => {
    const left = (window.screen.width - OAUTH_POPUP_WIDTH) / 2;
    const top = (window.screen.height - OAUTH_POPUP_HEIGHT) / 2;
    const popupWindow = window.open(
      authUri,
      OAUTH_WINDOW_TARGET,
      `width=${OAUTH_POPUP_WIDTH},height=${OAUTH_POPUP_HEIGHT},top=${top},left=${left}`
    );

    if (!popupWindow) {
      trackEvent(ANALYTIC_EVENTS.FAILED_OAUTH_FLOW, {
        provider,
        operation,
        reason: 'failed to open popup window',
      });
      return;
    }

    const popupClosed$ = fromEvent<MessageEvent>(window, 'message').pipe(
      filter(event => event.source === popupWindow),
      map(event => event.data),
      filter(data => data?.type === 'popup-closed')
    );

    fromEvent<MessageEvent>(window, 'message')
      .pipe(
        filter(
          event =>
            event.source === popupWindow &&
            event.origin === window.location.origin
        ),
        map(event => event.data),
        filter(
          data =>
            data?.type === 'oauth-success' &&
            isValidOAuthProvider(data.provider)
        ),
        tap(() => {
          trackEvent(ANALYTIC_EVENTS.SUCCESS_OAUTH_FLOW, {
            provider,
            operation,
          });
        }),
        takeUntil(
          merge(
            popupClosed$, // Stream that completes when the popup is closed
            timer(TIMEOUT_MS) // Timer that completes after the specified time
          )
        )
      )
      .subscribe(data => {
        dispatchAction(completedOAuthFlow(data.provider));
        onCompleted();
        popupWindow.close();
      });
  })
);

export default collectRoutines(handleStartOAuthFlow);
