import { FlexBox } from '@ardoq/layout';
import { LucidchartDocumentImportState } from '../streams/document/types';
import { BrandButton } from '@ardoq/button';
import { viewModel$ } from './viewModel';
import { connect } from '@ardoq/rxbeach';
import { ErrorNotification } from '@ardoq/status-ui';
import { ArdoqLogoLoaderIcon } from '@ardoq/icons';

type DocumentSelectorProps = {
  document: LucidchartDocumentImportState;
  onSelectDocument: () => void;
};

function DocumentSelectorComponent({
  document,
  onSelectDocument,
}: DocumentSelectorProps) {
  return (
    <FlexBox flex={1} justify="center" align="center">
      {document.status === 'INIT' && (
        <BrandButton onClick={() => onSelectDocument()}>
          Select document
        </BrandButton>
      )}
      {document.status === 'LOADING' && <ArdoqLogoLoaderIcon />}
      {document.status === 'SUCCESS' && (
        <FlexBox flex={1} height="full">
          <iframe
            style={{ width: '100%', height: '100%' }}
            src={document.document.embedUrl}
          />
        </FlexBox>
      )}
      {document.status === 'FAILURE' && (
        <ErrorNotification>{document.error}</ErrorNotification>
      )}
    </FlexBox>
  );
}

export const DocumentSelector = connect(DocumentSelectorComponent, viewModel$);
