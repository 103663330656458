import { combineLatest } from 'rxjs';
import mergeState$ from './mergeState$';
import { map } from 'rxjs/operators';
import diffMergeTable$ from 'components/DiffMergeTable/diffMergeTable$';
import { MergeState } from 'components/DiffMergeTable/types';
import { getButtonText } from './utils';
import { Verb } from '@ardoq/api-types';

const buttonText$ = combineLatest([mergeState$, diffMergeTable$]).pipe(
  map(([{ mainStateStep, subStateStep }, { dataSource }]) => ({
    buttonText: getButtonText(mainStateStep, subStateStep),
    isDisabled:
      subStateStep !== Verb.MERGE &&
      !dataSource?.some(
        ({ status }) =>
          status === MergeState.SOURCE || status === MergeState.TARGET
      ),
  }))
);

export default buttonText$;
