import { permissionApi, reportApi } from '@ardoq/api';
import {
  EnhancedSearchResponse,
  isColumnWithCustomLabel,
  searchResultsOperations,
  trackSaveReport,
} from '@ardoq/report-reader';
import {
  GroupPermission,
  reportBuilderOperations,
  ReportBuilderWithSubdivisionsPermissions,
  ReportTemplate,
  UserPermission,
} from '@ardoq/report-builder';
import {
  APIResourcePermissionAttributes,
  APISearchAggregatesResponse,
  ArdoqId,
  Asset,
  AssetType,
  DataSourceType,
  isAdvancedSearch,
  NonPersistedReport,
  PermissionAccessLevel,
  QueryBuilderSubquery,
  Report,
  ReportAsset,
  ReportColumn,
  ResourceType,
  ReportSearchArgs,
  ReportSearchAggregatesArgs,
} from '@ardoq/api-types';
import {
  getHighestPermissionLevel,
  getPermissionLevelAsArray,
} from '@ardoq/manage-resource-permissions';
import {
  apiPutResourcePermission,
  resourcePermissionError,
  setResourcePermissions,
} from 'resourcePermissions/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { showToast, ToastType } from '@ardoq/status-ui';
import { trackEvent } from '../tracking/tracking';
import { ArdoqError, isArdoqError, Result } from '@ardoq/common-helpers';
import { confirm } from '@ardoq/modal';
import { isEqual, pick } from 'lodash';
import { dateRangeOperations } from '@ardoq/date-range';
import { trackFieldsOperatorsAndTargetEntityTypesUsedInAdvancedSearchQuery } from '../search/tracking';
import { Filter } from 'components/AssetsBrowser2024/FilterBar/filterBarTypes';
import { AssetRow, ReportRow } from '../components/AssetsBrowser/types';
import { Folder } from '../components/EntityBrowser/types';
import { RowType } from '@ardoq/table';
import { dispatchActionAndWaitForResponse } from '../actions/utils';

export const showReportHasBeenSavedToast = () =>
  showToast('Your report has been saved.', ToastType.SUCCESS);

export const reportTemplateToApiReport = (
  template: ReportTemplate
): NonPersistedReport => {
  return {
    ...pick(
      template,
      'datasource',
      'name',
      'description',
      'workspaceIds',
      'selectedAggregate',
      'subdivisions'
    ),
    query: reportBuilderOperations.isAdvancedSearchBased(template)
      ? template.advancedSearchQuery
      : template.gremlinQuery,
    columns: reportBuilderOperations
      .getColumnsWithUpdatedNames(template)
      .flatMap(splitDateRangeColumn),
  };
};

export const trackSavedReport = (isNew: boolean, report: Report) => {
  const zoneIds =
    reportBuilderOperations.getZoneIdsFromReportSubdivisions(report);
  const { columns, datasource, selectedAggregate, workspaceIds } = report;
  trackSaveReport(trackEvent, {
    numberOfColumnsRenamed: columns.filter(isColumnWithCustomLabel).length,
    numberOfColumnsSelected: columns.length,
    numberOfWorkspacesSelected: workspaceIds ? workspaceIds.length : 0,
    dataSource: datasource,
    defaultAggregate: selectedAggregate,
    isNew,
    hasDefaultSort: Boolean(columns.some(col => col.sort)),
    numberOfDivisionsSelected: zoneIds.length,
  });
};

export const setPermissions = async (
  { groupPermissions, userPermissions }: ReportTemplate,
  reportId: ArdoqId
) => {
  const reportPermissions = await permissionApi.getResourcePermission({
    resourceType: ResourceType.REPORT,
    resourceId: reportId,
  });

  if (isArdoqError(reportPermissions)) {
    return reportPermissions;
  }

  updatePermissions(reportPermissions, userPermissions, groupPermissions);
};

export const shouldKeepHistoricalData = async (
  report: Report,
  reportTemplate: ReportTemplate
): Promise<boolean> => {
  const query = reportBuilderOperations.isAdvancedSearchBased(report)
    ? reportTemplate.advancedSearchQuery
    : reportTemplate.gremlinQuery;

  const reportHasHistoryBreakingChanges =
    !isEqual(reportTemplate.workspaceIds, report.workspaceIds) ||
    !isEqual(query, report.query);

  // If there is only one snapshot for this report and the new changes invalidates the history
  if (!report.hasHistoricalData && reportHasHistoryBreakingChanges) {
    return false;
  }
  // If the changes invalidates the history and there is historical data for the report
  if (reportHasHistoryBreakingChanges) {
    const confirmationResult = await confirm({
      title: 'Include or remove previous data',
      text: `Your query or workspace change can cause a jump in timeline widgets that use this report data.
            Do you want to continue to show the previous data or remove it?`,
      confirmButtonTitle: 'Include',
      cancelButtonTitle: 'Remove',
    });
    if (!confirmationResult) {
      return false;
    }
    return confirmationResult.confirmed;
  }
  return true;
};

const prepareUpdatedPermissions = (
  reportPermissions:
    | APIResourcePermissionAttributes
    | ReportBuilderWithSubdivisionsPermissions,
  userPermissions: UserPermission[],
  groupPermissions: GroupPermission[]
): APIResourcePermissionAttributes => ({
  ...reportPermissions,
  permissions: userPermissions.map(({ name, type, _id, permissions }) => ({
    permissions: getPermissionLevelAsArray(
      getHighestPermissionLevel(permissions)
    ),
    name,
    _id,
    type,
    writeAccess:
      getHighestPermissionLevel(permissions) !== PermissionAccessLevel.READ,
  })),
  groups: groupPermissions.map(({ permissions, permissionRole }) => ({
    name: permissionRole,
    permissions: getPermissionLevelAsArray(
      getHighestPermissionLevel(permissions)
    ),
  })),
});

export const updatePermissions = (
  reportPermissions: APIResourcePermissionAttributes,
  userPermissions: UserPermission[],
  groupPermissions: GroupPermission[]
) => {
  const updatedPermissions = prepareUpdatedPermissions(
    reportPermissions,
    userPermissions,
    groupPermissions
  );
  dispatchAction(
    apiPutResourcePermission({
      permissionsUpdates: [updatedPermissions],
      prevPermissionsByResource: {
        [updatedPermissions._id]:
          reportPermissions as APIResourcePermissionAttributes,
      },
    })
  );
};

export const updatePermissionsAndShowToast = async (
  reportPermissions: ReportBuilderWithSubdivisionsPermissions,
  userPermissions: UserPermission[],
  groupPermissions: GroupPermission[]
) => {
  const updatedPermissions = prepareUpdatedPermissions(
    reportPermissions,
    userPermissions,
    groupPermissions
  );
  const response = await dispatchActionAndWaitForResponse(
    apiPutResourcePermission({
      permissionsUpdates: [updatedPermissions],
      prevPermissionsByResource: {
        [updatedPermissions._id]:
          reportPermissions as APIResourcePermissionAttributes,
      },
    }),
    setResourcePermissions,
    resourcePermissionError
  );
  if (response.type === setResourcePermissions.type) {
    showToast('Report shared', ToastType.SUCCESS);
  }
};

const splitDateRangeColumn = (column: ReportColumn) => {
  if (!column.isDateRangeColumn) return column;

  let dateTimeColumns = [
    {
      type: column.type,
      dataType: column.dataType,
      sort: column.sort,
      key: dateRangeOperations.toStartDateName(column.key),
      label: dateRangeOperations.toStartDateLabel(column.label),
    },
    {
      type: column.type,
      dataType: column.dataType,
      sort: column.sort,
      key: dateRangeOperations.toEndDateName(column.key),
      label: dateRangeOperations.toEndDateLabel(column.label),
    },
  ] as ReportColumn[];

  const originalLabel = column.originalLabel;
  if (originalLabel) {
    dateTimeColumns = [
      {
        ...dateTimeColumns[0],
        originalLabel: dateRangeOperations.toStartDateLabel(originalLabel),
      },
      {
        ...dateTimeColumns[1],
        originalLabel: dateRangeOperations.toEndDateLabel(originalLabel),
      },
    ];
  }

  return dateTimeColumns;
};

export const reportBuilderSearch = async (
  {
    workspaceIds,
    query,
    from,
    size,
    datasource,
    order,
    sort,
    subdivisions,
  }: ReportSearchArgs,
  reportId?: string
): Promise<EnhancedSearchResponse | ArdoqError> => {
  if (isAdvancedSearch(datasource)) {
    trackFieldsOperatorsAndTargetEntityTypesUsedInAdvancedSearchQuery(
      query as QueryBuilderSubquery
    );
  }
  const response = await reportApi.search({
    query: {
      datasource,
      query,
      from,
      size,
      sort: sort ?? undefined,
      order,
      workspaceIds,
      subdivisions,
    },
    reportId,
    options: { skipAggregates: true }, // aggregates are requested through a separate request to improve the performace
  });

  if (isArdoqError(response)) {
    return response;
  }

  return searchResultsOperations.mergeDateRangeFieldsAndEnhanceScopeData(
    response
  );
};

export const reportBuilderSearchAggregates = (
  { workspaceIds, query, datasource, subdivisions }: ReportSearchAggregatesArgs,
  reportId?: string
): Promise<Result<APISearchAggregatesResponse>> =>
  reportApi.searchAggregates({
    query: {
      datasource,
      query,
      workspaceIds,
      subdivisions,
    },
    reportId,
  });

const isReportAsset = (asset: Asset): asset is ReportAsset => {
  return asset.meta.assetType === AssetType.REPORT;
};

export const errorStatusFilter: Filter = {
  label: 'Contains error',
  filterFn: asset => isReportAsset(asset) && Boolean(asset.error),
  filterRule: 'AND',
};

export const advancedSearchFilter: Filter = {
  label: 'Advanced search',
  filterFn: asset =>
    isReportAsset(asset) &&
    asset.datasource &&
    asset.datasource === DataSourceType.ADVANCED_SEARCH,
  filterRule: 'AND',
};

export const gremlinFilter: Filter = {
  label: 'Gremlin graph search',
  filterFn: asset =>
    isReportAsset(asset) &&
    asset.datasource &&
    asset.datasource === DataSourceType.GREMLIN_SEARCH,
  filterRule: 'AND',
};

export const isReportRow = (
  row: AssetRow | Folder<AssetRow>
): row is ReportRow => row.rowType === RowType.REPORT;
