import { FlexBox } from '@ardoq/layout';
import { EmptyState, EmptyStateNoResultIllustration } from '@ardoq/status-ui';

export const EmptySearchGroupsList = () => {
  return (
    <FlexBox
      align="center"
      justify="center"
      height="full"
      width="full"
      paddingBottom="xxlarge"
      paddingTop="xxlarge"
    >
      <EmptyState
        description="There are no groups that match your search."
        layout="horizontal"
        size="large"
        media={<EmptyStateNoResultIllustration />}
        title="No groups found"
      ></EmptyState>
    </FlexBox>
  );
};
