import {
  APIEntityType,
  APIOrganizationUser,
  APIScopeData,
  ArdoqId,
  ScopeDataCollection,
  Verb,
} from '@ardoq/api-types';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { EnhancedDiffScopeData } from './enhanceDiffContextData';
import { Branch } from './Branch';
import { MergeStepLabel } from 'scope/merge/typesMergeStepLabel';
import { Graphics, RenderOptions } from '@ardoq/renderers';

export enum MergeStep {
  NONE = 0,
  UPDATE_MODELS,
  UPDATE_COMPONENT_TYPES,
  DELETE_COMPONENT_TYPES,
  CREATE_COMPONENT_TYPES,
  UPDATE_REFERENCE_TYPES,
  DELETE_REFERENCE_TYPES,
  CREATE_REFERENCE_TYPES,
  UPDATE_FIELDS,
  DELETE_FIELDS,
  CREATE_FIELDS,
  UPDATE_COMPONENTS,
  DELETE_COMPONENTS,
  CREATE_COMPONENTS,
  UPDATE_REFERENCES,
  DELETE_REFERENCES,
  CREATE_REFERENCES,
  UPDATE_TAGS,
  DELETE_TAGS,
  CREATE_TAGS,
  MERGE_TAGS,
}

export enum DiffTableRowType {
  HEADER_ROW = 1,
  SUB_HEADER_ROW,
  PROPERTY_ROW,
  EXPANDED_CONTENT,
  SECTION_HEADER_ROW,
  META_ROW,
}

export type PayloadSetMergeData = {
  master: APIScopeData | null;
  branch: APIScopeData | null;
  branchOff: APIScopeData | null;
  graphics: Graphics | null;
  users: Record<ArdoqId, APIOrganizationUser> | null;
  options: RenderOptions | null;
};

export type PayloadSetMergeStep = {
  mergeStep: MergeStep;
};

export type PayloadSetMergeState = {
  mergeStatus: MergeState;
  index: number;
};

export type PayloadToggleIsExpandedTableRow = {
  index: number;
};

export type PayloadToggleIsExpandedMainStep = {
  mainStep: MergeStepLabel;
};

export enum MergeState {
  NONE,
  MAIN,
  BRANCH,
  PARTIAL,
  SOURCE,
  TARGET,
}

export type Path = [ScopeDataCollection, Verb, ...string[]];

export type DataSourceItem = {
  rowType: DiffTableRowType;
  entityId: ArdoqId;
  parentEntityId?: ArdoqId;
  entityType: APIEntityType;
  fieldName: string;
  enhancedDiffContextData: EnhancedDiffScopeData;
  path: Path;
  index: number;
  parent: number | null;
  children?: number[];
  status: MergeState;
  isExpanded?: boolean;
  isDisabled?: boolean;
  structuralConflict: StructuralConflict;
  fieldConflict: FieldConflict;
  isInUseOnTarget?: boolean;
  hasTypeConflict?: boolean;
  hasMissingParentConflict?: boolean;
  missingComponentConflict?: MissingComponentConflict | null;
  sectionType?: MergeTableSectionType;
  hasWritePermission?: boolean;
};

export type DataSource = DataSourceItem[];

export type ViewModel = {
  responseData: {
    master: APIScopeData | null;
    branch: APIScopeData | null;
    branchOff: APIScopeData | null;
  };
  mergeDirection: MergeDirection;
  mergeStep: MergeStep;
  enhancedDiffContextData: EnhancedDiffScopeData | null;
  dataSource: DataSource | null;
  graphics: Graphics | null;
  users: Record<ArdoqId, APIOrganizationUser> | null;
  options: RenderOptions | null;
};

export type DiffMergeTableProps = Omit<
  ViewModel,
  'enhancedDiffContextData' | 'responseData'
>;

export type NonNullableDiffMergeTableProps = {
  [P in keyof DiffMergeTableProps]: NonNullable<DiffMergeTableProps[P]>;
};

export interface AdditionalContentProps {
  entityType: APIEntityType;
  users: Record<ArdoqId, APIOrganizationUser>;
  options: RenderOptions;
  dataSourceItem: DataSourceItem;
  graphics: Graphics;
  branch: Branch;
}

export enum StructuralConflict {
  NONE = 'StructuralConflict/NONE',
  MISSING_ANCESTOR = 'StructuralConflict/MISSING_ANCESTOR',
  RIGID_MODEL_PARENT_TYPE_HAS_CHANGED = 'StructuralConflict/RIGID_MODEL_PARENT_TYPE_HAS_CHANGED',
}

export enum MergeConflict {
  VALUE_MODIFIED_ON_SOURCE_AND_TARGET = 'MergeConflict/VALUE_MODIFIED_ON_SOURCE_AND_TARGET',
}

export enum FieldConflict {
  NONE = 'FieldConflict/NONE',
  MISSING_FIELD_ON_TARGET = 'FieldConflict/MISSING_FIELD_ON_TARGET',
  MISSING_FIELD_ON_SOURCE = 'FieldConflict/MISSING_FIELD_ON_SOURCE',
}

export enum MissingComponentConflict {
  SOURCE = 'MissingComponentConflict/SOURCE',
  TARGET = 'MissingComponentConflict/TARGET',
  BOTH = 'MissingComponentConflict/BOTH',
}

export enum AncestorItemType {
  COMPONENT,
  CREATED_COMPONENT,
  DELETED_COMPONENT,
  UPDATED_COMPONENT,
  PLACEHOLDER,
}

export enum MergeTableSectionType {
  DEFAULT = 'DEFAULT',
  CREATED_IN_BRANCH = 'CREATED_IN_BRANCH',
  DELETED_IN_BRANCH = 'DELETED_IN_BRANCH',
  CREATED_IN_MAINLINE = 'CREATED_IN_MAINLINE',
  DELETED_IN_MAINLINE = 'DELETED_IN_MAINLINE',
}

export type ExistingAncestorItem = {
  componentId: string;
  ancestorItemType:
    | AncestorItemType.COMPONENT
    | AncestorItemType.CREATED_COMPONENT
    | AncestorItemType.DELETED_COMPONENT
    | AncestorItemType.UPDATED_COMPONENT;
};

type AncestorItemPlaceholder = {
  label: string;
  ancestorItemType: AncestorItemType.PLACEHOLDER;
};

export type AncestorItem = ExistingAncestorItem | AncestorItemPlaceholder;
