import { isEqual, range } from 'lodash';
import { APIFieldType } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { getShallowDiff } from '@ardoq/renderers';
import {
  DockedGridEditorVisibility,
  GridColumnSetting,
  GridEditorCoordinates,
  GridEditorFrame,
  GridEditorProps,
  GridEditorState,
} from './types';
import { parseFieldValue } from './fieldValueParser';
import { gridEditorStateOperations } from './gridEditorStateOperations';

export const getGridEditorIframeSrc = () => {
  return '/gridEditor2023.html';
};

export const coordsToCellsList = (coords: GridEditorCoordinates[] = []) => {
  const cellsList: { row: number; col: number }[] = [];
  coords.forEach(({ startRow, startCol, endRow, endCol }) => {
    range(startRow, endRow + 1).forEach((row: number) => {
      range(startCol, endCol + 1).forEach((col: number) => {
        cellsList.push({ row, col });
      });
    });
  });
  return cellsList;
};

const fullscreenEditorStyle =
  gridEditorStateOperations.getFullscreenEditorStyle();

function assertUnreachable(x: never): never {
  throw new Error(`Unreachable code-path taken, received argument "${x}"`);
}

/**
 * Transforms GridEditorState into a a style object specifying the position
 * and dimensions of the EditorPane.
 */
export const getGridEditorPanePosition = (
  state: GridEditorState
): React.CSSProperties => {
  // This component will only run inside the standalone Grid Editor, so frameId
  // cannot be arodq-front, so we can work with activeFrame to determine styles.
  if (gridEditorStateOperations.isPopoutGridEditorState(state)) {
    return fullscreenEditorStyle;
  }

  switch (state.dockedGridEditorVisibility) {
    case DockedGridEditorVisibility.CLOSED:
      return { display: 'none' };
    case DockedGridEditorVisibility.EXPANDED:
      return state.dockedEditorPaneStyle;
    case DockedGridEditorVisibility.FULLSCREEN:
      return fullscreenEditorStyle;
    default:
      return assertUnreachable(state.dockedGridEditorVisibility);
  }
};

interface GridEditorWindowContext extends Window {
  /** A global flag that is only set in the standalone GridEditor bundle */
  ARDOQ_IS_STANDALONE_GRID_EDITOR?: boolean;
}

const isStandaloneGridEditorWindow = (
  windowContext: GridEditorWindowContext
) => {
  return windowContext.ARDOQ_IS_STANDALONE_GRID_EDITOR;
};

const isPopoutGridEditorWindow = (windowContext: GridEditorWindowContext) => {
  if (!isStandaloneGridEditorWindow(windowContext)) return false;
  return Boolean(windowContext.opener);
};

const isDockedGridEditorWindow = (windowContext: GridEditorWindowContext) => {
  if (!isStandaloneGridEditorWindow(windowContext)) return false;
  return !windowContext.opener && windowContext.top !== windowContext;
};

export const getFrameIdFromWindowContext = (
  windowContext: GridEditorWindowContext
) => {
  if (isPopoutGridEditorWindow(windowContext)) {
    return GridEditorFrame.POPOUT;
  }
  if (isDockedGridEditorWindow(windowContext)) {
    return GridEditorFrame.DOCKED;
  }
  return GridEditorFrame.ARDOQ_FRONT_BRIDGE;
};

export type ColumnIndexMap = Map<
  number,
  { columnSetting: GridColumnSetting; customFieldType?: APIFieldType }
>;
export const parsePastedColumnValues = (
  /**
   * Values pasted by the user arranged by rows. eg
   * [["name 1", "description 1"], ["name 2", "description 2"]]
   */
  pastedData: unknown[][],
  opts: {
    startColumn: number;
    baseAttributes: Record<string, unknown>;
    columnIndexMap: ColumnIndexMap;
  }
) => {
  type Attributes = Record<string, unknown>;
  const { startColumn, baseAttributes, columnIndexMap } = opts;

  return pastedData.map(dataRow =>
    dataRow.reduce<Attributes>(
      (attributes, cellValue, pasteIndex) => {
        const columnIndex = startColumn + pasteIndex;
        const column = columnIndexMap.get(columnIndex);

        if (!column) {
          logError(Error('Column not found'), null, {
            columnIndex,
            tableColumns: columnIndexMap.size,
          });
          return attributes;
        }

        const { columnSetting, customFieldType } = column;
        if (columnSetting.readOnly) {
          return attributes;
        }

        const attrValue = customFieldType
          ? parseFieldValue(cellValue, customFieldType)
          : cellValue;

        return { [columnSetting.name]: attrValue, ...attributes };
      },
      { ...baseAttributes }
    )
  );
};

export const getGridEditorChanges = (
  prevProps: GridEditorProps,
  props: GridEditorProps
) => {
  const changedGridEditorKeys = getShallowDiff(
    props.gridEditorModel,
    prevProps.gridEditorModel
  );
  const changedContextKeys = getShallowDiff(props.context, prevProps.context);

  const changedSubdivisions =
    props.subdivisionsContext !== prevProps.subdivisionsContext;
  const permissionsContextChanged =
    props.permissionContext !== prevProps.permissionContext;

  const fieldsChanged = props.gridEditorFields !== prevProps.gridEditorFields;
  const componentsChanged =
    props.gridEditorComponents !== prevProps.gridEditorComponents;

  const isTableColumnsChanged =
    fieldsChanged ||
    changedContextKeys.has('workspaceId') ||
    changedContextKeys.has('componentId') ||
    changedGridEditorKeys.has('disabledFields') ||
    changedGridEditorKeys.has('activeViewType');

  const isTableRowsChanged =
    componentsChanged ||
    changedContextKeys.has('componentId') ||
    changedContextKeys.has('workspaceId') ||
    changedGridEditorKeys.has('disabledFields') ||
    changedGridEditorKeys.has('activeViewType') ||
    changedGridEditorKeys.has('disabledWorkspaces') ||
    changedGridEditorKeys.has('isShowAllComponents') ||
    changedSubdivisions ||
    permissionsContextChanged;

  const isActiveContextChanged = changedGridEditorKeys.has('activeViewType');
  const isComponentIdChanged = changedContextKeys.has('componentId');

  const isComponentSelectionParamsChanged = !isEqual(
    prevProps.componentSelectionConfig,
    props.componentSelectionConfig
  );

  return {
    isActiveContextChanged,
    isComponentIdChanged,
    isComponentSelectionParamsChanged,
    isTableColumnsChanged,
    isTableRowsChanged,
  };
};
