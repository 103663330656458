import Filters from 'collections/filters';
import { ComponentBackboneModel } from 'aqTypes';
import {
  BooleanOperator,
  Operator,
  QueryBuilderSubquery,
} from '@ardoq/api-types';
import { findQuickPerspectivesTypeEqualitySubquery } from '@ardoq/filter-interface';
import { clearQucikPerspectiveTypeFilters } from 'quickPerspectives/utils';

function createComponentTypeRule(
  ruleRoot: QueryBuilderSubquery
): QueryBuilderSubquery {
  const componentTypeRule = {
    type: 'attribute',
    condition: BooleanOperator.OR,
    rules: [],
  };
  ruleRoot.rules.push(componentTypeRule);
  return componentTypeRule;
}
export function getFilterIncludedTypeNames(ruleRoot: QueryBuilderSubquery) {
  if (!ruleRoot) {
    return [];
  }
  const componentTypeRules =
    findQuickPerspectivesTypeEqualitySubquery(ruleRoot);
  if (!componentTypeRules || !componentTypeRules.rules) {
    return [];
  }
  return componentTypeRules.rules.map(rule => rule.value);
}

export function clearComponentTypeFilters(ruleRoot: QueryBuilderSubquery) {
  const newRoot = clearQucikPerspectiveTypeFilters(ruleRoot);

  Filters.setFiltersFromQueryBuilderQueries({
    componentRules: newRoot,
    shouldTriggerChangeEvent: true,
  });
}

export function ensureComponentFiltersValid(
  ruleRoot: QueryBuilderSubquery,
  contextComponent: ComponentBackboneModel | null
) {
  const clonedRoot = Object.assign({}, ruleRoot);
  const componentTypeRule =
    findQuickPerspectivesTypeEqualitySubquery(clonedRoot);

  if (!componentTypeRule || !contextComponent) {
    return ruleRoot;
  }

  const includedComponentTypeNames = getFilterIncludedTypeNames(ruleRoot);
  const contextComponentTypeName = contextComponent.get('type');
  if (!includedComponentTypeNames.includes(contextComponentTypeName)) {
    includedComponentTypeNames.push(contextComponentTypeName);
    repopulateFilterRules(componentTypeRule, includedComponentTypeNames);
    return clonedRoot;
  }

  return ruleRoot;
}

function repopulateFilterRules(
  componentTypeRule: QueryBuilderSubquery,
  componentTypeNames: string[]
) {
  componentTypeRule.rules = [];
  componentTypeNames.forEach(componentTypeName => {
    componentTypeRule.rules.push({
      id: 'type',
      field: 'type',
      input: 'text',
      operator: Operator.EQUAL,
      type: 'string',
      value: componentTypeName,
    });
  });
}

export const ensureComponentTypesIncluded = (
  componentTypeNames: string[],
  ruleRoot: QueryBuilderSubquery
) => {
  const includedTypeNames = getFilterIncludedTypeNames(ruleRoot);
  let changed = false;
  componentTypeNames.filter(Boolean).forEach(componentTypeName => {
    if (!includedTypeNames.includes(componentTypeName)) {
      includedTypeNames.push(componentTypeName);
      changed = true;
    }
  });
  if (changed) {
    setIncludedComponentTypeNames(includedTypeNames, ruleRoot);
  }
};

export function setIncludedComponentTypeNames(
  componentTypeNames: string[],
  ruleRoot: QueryBuilderSubquery
) {
  if (!componentTypeNames || componentTypeNames.length === 0) {
    clearComponentTypeFilters(ruleRoot);
    return;
  }

  const componentTypeRule =
    findQuickPerspectivesTypeEqualitySubquery(ruleRoot) ||
    createComponentTypeRule(ruleRoot);

  repopulateFilterRules(componentTypeRule, componentTypeNames);

  Filters.setFiltersFromQueryBuilderQueries({
    componentRules: ruleRoot,
    shouldTriggerChangeEvent: true,
  });
}
