import viewpointsWidget$, {
  handleDropdownMenuTracking,
} from './viewpointsWidget$';
import { connect } from '@ardoq/rxbeach';
import { GhostButton } from '@ardoq/button';
import styled from 'styled-components';
import { colors, s24 } from '@ardoq/design-tokens';
import { header2 } from '@ardoq/typography';
import { IconName } from '@ardoq/icons';
import { TabsGroup, TabsTheme, TabButton } from '@ardoq/tabs';
import { ActiveTabId, ViewpointsWidgetStreamShape } from './types';
import NoViewpointsBanner from './NoViewpointsBanner';
import EntityBrowserActionsMenu from 'components/EntityBrowser/EntityBrowserActionsMenu';
import { trackEvent } from 'tracking/tracking';
import { viewIdToUserFriendlyName } from 'views/metaInfoTabs';
import { viewpointsOverviewCommands } from 'traversals/TraversalOverview/viewpointsOverviewCommands';
import UserMetaRepresentation from 'homePage/UserMetaRepresentation';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import {
  HomePageTrackingEventsNames,
  trackOpenViewPointFromCard,
} from '../tracking';
import { CollapsibleIsland } from '@ardoq/page-layout';
import { Card } from '@ardoq/card';
import { FlexBox } from '@ardoq/layout';

const VIEWPOINT_CARD_HEIGHT = 180;

// extra height on the flexbox is to account for shadow being clipped
const ScrollableFlexBox = styled(FlexBox).attrs({
  gap: 'large',
  padding: 'large',
  flexWrap: true,
  paddingTop: 'none',
})`
  overflow: hidden;
  height: calc(${VIEWPOINT_CARD_HEIGHT}px + ${s24});
`;

const FixedCardSize = styled.div`
  width: 220px;
  height: ${VIEWPOINT_CARD_HEIGHT}px;
`;

const Header2 = styled.h2`
  ${header2};
  user-select: none;
  margin: 0;
`;

const ViewpointsWidget = ({
  hasTraversals,
  filteredTraversals,
  activeTab,
  commands,
  isExpanded,
  getTraversalMenuOptions,
}: ViewpointsWidgetStreamShape) => {
  if (!hasTraversals) return <NoViewpointsBanner />;
  return (
    <CollapsibleIsland
      title={<Header2>Viewpoints</Header2>}
      showHeaderBorder={false}
      toggleOnHeaderClick={false}
      rightContent={
        <FlexBox gap="medium" width="full" align="center" justify="flex-end">
          <GhostButton
            onClick={e => {
              e.stopPropagation();
              commands.seeAllViewpoints();
            }}
          >
            See all
          </GhostButton>
          <div style={{ width: 300 }} onClick={e => e.stopPropagation()}>
            <TabsGroup
              activeTabId={activeTab}
              onTabChange={tabId => {
                commands.setActiveTab(tabId as ActiveTabId);
                trackEvent('Viewpoints widget: clicked change tab', { tabId });
              }}
              theme={TabsTheme.COLOR_FILL}
            >
              <TabButton
                label="Last updated"
                tabId="lastUpdated"
                iconName={IconName.TIME}
              />
              <TabButton
                label="Favorites"
                tabId="favorites"
                iconName={IconName.STAR}
              />
            </TabsGroup>
          </div>
        </FlexBox>
      }
      bodySpacing="none"
      headerSpacing="large"
      maxWidth="100%"
      isDefaultExpanded={isExpanded}
      onToggle={isExpanded => {
        commands.setIsExpanded(isExpanded);
        trackEvent('Viewpoints widget: clicked toggle widget', { isExpanded });
      }}
    >
      <ScrollableFlexBox>
        {filteredTraversals.length > 0
          ? filteredTraversals.map(traversal => {
              const options = [
                {
                  label: 'Preview',
                  testId: 'preview-viewpoint',
                  type: DropdownOptionType.OPTION,
                  onClick: () => {
                    commands.openTraversalPreview({
                      traversalId: traversal._id,
                      trackingFn: trackingString =>
                        handleDropdownMenuTracking(trackingString),
                    });
                  },
                },
                ...getTraversalMenuOptions(traversal, 'Viewpoints widget'),
              ];
              return (
                <FixedCardSize key={traversal._id}>
                  <Card
                    height="full"
                    captionIcon={
                      traversal.meta.favorite ? IconName.STAR : undefined
                    }
                    caption={
                      viewIdToUserFriendlyName[traversal.viewName] ?? 'Unknown'
                    }
                    captionIconColor={colors.yellow50}
                    title={traversal.name}
                    titleLineClamp={3}
                    onClick={() => {
                      trackOpenViewPointFromCard(traversal);
                      viewpointsOverviewCommands.openViewpoint(traversal._id);
                    }}
                    headerRightContent={
                      <EntityBrowserActionsMenu
                        menuOptions={options}
                        onTrackClick={() => {
                          trackEvent(
                            HomePageTrackingEventsNames.CLICKED_CARD_MENU_OPTIONS
                          );
                        }}
                      />
                    }
                  >
                    <UserMetaRepresentation
                      lastModifiedByName={traversal.lastModifiedByName}
                      lastUpdated={traversal.lastUpdated}
                    />
                  </Card>
                </FixedCardSize>
              );
            })
          : "You haven't favorited any viewpoints yet."}
      </ScrollableFlexBox>
    </CollapsibleIsland>
  );
};

export default connect(ViewpointsWidget, viewpointsWidget$);
