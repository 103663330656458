import {
  GraphComponent,
  GraphItemTypes,
  GraphViewerInputMode,
  MouseWheelBehaviors,
  MoveViewportInputMode,
} from '@ardoq/yfiles';
import { ArdoqGraphClickInputMode } from 'tabview/graphComponent/yfilesHelper';
import { installHoverMode } from 'yfilesExtensions/view/highlightControls';

export const createGraphComponent = (
  container: HTMLDivElement,
  useHoverDecorator: boolean,
  isThumbnailView = false,
  hasHoverDecorator = true
) => {
  const graphComponent = new GraphComponent(container);
  graphComponent.inputMode = createInputMode(
    graphComponent,
    useHoverDecorator,
    isThumbnailView,
    hasHoverDecorator
  );
  graphComponent.minimumZoom = 0.1;
  if (isThumbnailView) {
    graphComponent.mouseWheelBehavior = MouseWheelBehaviors.NONE;
  }
  return graphComponent;
};

const createInputMode = (
  graphComponent: GraphComponent,
  useHoverDecorator: boolean,
  isThumbnailView = false,
  hasHoverDecorator = true
) => {
  const result = new GraphViewerInputMode({
    itemHoverInputMode: hasHoverDecorator
      ? installHoverMode(graphComponent, useHoverDecorator)
      : undefined,
    toolTipItems: GraphItemTypes.NODE | GraphItemTypes.EDGE,
  });
  result.clickInputMode = new ArdoqGraphClickInputMode();
  if (isThumbnailView) {
    result.moveViewportInputMode = new MoveViewportInputMode();
    result.moveViewportInputMode.enabled = false;
  }
  return result;
};
