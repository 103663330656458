import { BlockDiagramViewSettings } from '../../../tabview/blockDiagram/types';
import {
  GraphComponent,
  HierarchicLayoutConfig,
  HierarchicLayoutData,
  HierarchicLayoutEdgeLayoutDescriptor,
  HierarchicLayoutEdgeRoutingStyle,
  HierarchicLayoutNodeLayoutDescriptor,
  HierarchicLayoutRoutingStyle,
  IGraph,
  IIncrementalHintsFactory,
  IModelItem,
  INode,
  Insets,
  LayoutDescriptor,
  LayoutOrientation,
  NodeLabelMode,
  PortAdjustmentPolicy,
  TimeSpan,
} from '@ardoq/yfiles';
import {
  CollapsibleGraphGroup,
  GraphItem,
  Rect,
  inside,
  GRAPH_INSETS,
} from '@ardoq/graph';
import { componentInterface } from 'modelInterface/components/componentInterface';
import getCriticalPaths from '../../../tabview/blockDiagram/view/criticalPaths';
import { maximumMarkerWidth } from 'svg/definitions/consts';
import { isContextNode } from '../../utils';
import { SimpleGraphViewProps } from '../../types';
import maximumGraphLayoutDuration from 'tabview/relationshipDiagrams/maximumGraphLayoutDuration';

const ANIMATION_DURATION = 500;

const standardHierarchicLayoutOptions: HierarchicLayoutConfig = {
  considerNodeLabels: true,
  integratedEdgeLabeling: true,
  backLoopRouting: false,
  orthogonalRouting: true,
  minimumLayerDistance: 35,
  recursiveGroupLayering: true,
  compactGroups: false,
  componentLayoutEnabled: false,
  fromScratchLayeringStrategy: 'hierarchical-optimal',
};

const layoutOrientationString = (layoutOrientation: LayoutOrientation) => {
  switch (layoutOrientation) {
    case LayoutOrientation.TOP_TO_BOTTOM:
      return 'top-to-bottom';
    case LayoutOrientation.BOTTOM_TO_TOP:
      return 'bottom-to-top';
    case LayoutOrientation.LEFT_TO_RIGHT:
      return 'left-to-right';
    case LayoutOrientation.RIGHT_TO_LEFT:
      return 'right-to-left';
  }
};
const getLayoutInfo = (
  nodeCount: number,
  edgeCount: number,
  layoutOrientation: LayoutOrientation
) => {
  const layoutConfig: HierarchicLayoutConfig = {
    ...standardHierarchicLayoutOptions,
    maximumDuration: maximumGraphLayoutDuration(nodeCount, edgeCount),
    layoutOrientation: layoutOrientationString(layoutOrientation),
    nodeLayoutDescriptor: new HierarchicLayoutNodeLayoutDescriptor({
      nodeLabelMode: NodeLabelMode.CONSIDER_FOR_SELF_LOOPS,
    }),
    edgeLayoutDescriptor: new HierarchicLayoutEdgeLayoutDescriptor({
      routingStyle: new HierarchicLayoutRoutingStyle({
        routingStyle: HierarchicLayoutEdgeRoutingStyle.ORTHOGONAL,
      }),
      minimumFirstSegmentLength: maximumMarkerWidth,
      minimumLastSegmentLength: maximumMarkerWidth,
      minimumLength: 2 * maximumMarkerWidth,
    }),
  };
  const layoutDescriptor: LayoutDescriptor = {
    name: 'HierarchicLayout',
    properties: layoutConfig,
  };

  return layoutDescriptor;
};
const nodeComparer = (graph: IGraph) => (nodeA: INode, nodeB: INode) =>
  nodeA.tag instanceof GraphItem && nodeB.tag instanceof GraphItem
    ? componentInterface.compare(nodeA.tag.modelId, nodeB.tag.modelId) // use navigator sort
    : graph.nodes.indexOf(nodeA) - graph.nodes.indexOf(nodeB); // this is unexpected. just use the natural order from the graph

const groupConstraintDelegate =
  (map: Map<string, number>) => (item: IModelItem) =>
    item.tag instanceof CollapsibleGraphGroup
      ? (map.get(item.tag.id) ?? null)
      : null;

const configureLayout = (
  graphComponent: GraphComponent,
  { graphNodes, graphEdges }: SimpleGraphViewProps['viewModel'],
  {
    layoutOrientation,
    sequenceConstraints,
    layerConstraints,
  }: BlockDiagramViewSettings,
  shouldFitToContentOnLayout = true
) => {
  const nodeCount = graphNodes.size;
  const edgeCount = graphEdges.size;
  const layoutDescriptor = getLayoutInfo(
    nodeCount,
    edgeCount,
    layoutOrientation
  );

  const isUpdate = false;

  if (shouldFitToContentOnLayout) {
    layoutDescriptor.properties!.layoutMode = 'from-scratch';
  }

  const { graph, contentRect, viewport } = graphComponent;

  const layoutData = new HierarchicLayoutData({ edgeDirectedness: 1 });

  const wasFullGraphDisplayed = inside(
    Rect.fromRectLike(contentRect),
    Rect.fromRectLike(viewport)
  );

  const sortedNodes =
    edgeCount === 0 ? graph.nodes.toArray().sort(nodeComparer(graph)) : null;
  layoutData.layerConstraints.nodeComparables.delegate = sortedNodes
    ? node => sortedNodes.indexOf(node) // when there are no edges or groups in the graph, we layer according to the navigator sort.
    : groupConstraintDelegate(new Map(layerConstraints));
  layoutData.sequenceConstraints.itemComparables.delegate =
    groupConstraintDelegate(new Map(sequenceConstraints));

  const newIds = new Set([...graphNodes.keys(), ...graphEdges.keys()]);
  layoutData.incrementalHints.contextDelegate = (
    item: IModelItem,
    hintsFactory: IIncrementalHintsFactory
  ) => {
    const graphItem = item.tag;
    if (!(graphItem instanceof GraphItem)) {
      return null;
    }
    const isNew = newIds.has(graphItem.id);
    if (!isNew) {
      return null;
    }
    return graphItem.isComponent()
      ? hintsFactory.createLayerIncrementallyHint(item)
      : hintsFactory.createSequenceIncrementallyHint(item);
  };
  const criticalPaths = getCriticalPaths({
    graph,
    contextNode: graph.nodes.find(isContextNode),
  });
  if (criticalPaths.size) {
    layoutData.criticalEdgePriorities = edge => criticalPaths.get(edge) ?? null;
  }

  return {
    layoutDescriptor,
    layoutData,
    duration: isUpdate
      ? TimeSpan.fromMilliseconds(ANIMATION_DURATION)
      : TimeSpan.ZERO,
    animateViewport: false,
    updateContentRect: true,
    targetBoundsInsets: wasFullGraphDisplayed
      ? new Insets(GRAPH_INSETS)
      : Insets.EMPTY,
    portAdjustmentPolicy: PortAdjustmentPolicy.ALWAYS, // shorten or elongate edges according to the outline as defined by ArdoqNodeStyle/IsometricNodeStyle. this prevents the edges from appearing "on top of" the faces of the isometric blocks.
  };
};
export default configureLayout;
