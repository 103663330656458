import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { AppRouterState } from 'router/appRouterTypes';
import { Route } from 'router/StreamRouter';
import { navigateToAccessControlPage } from 'router/navigationActions';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import {
  AccessControlGroupsRoute,
  AccessControlMemberRoute,
  AccessControlRoute,
  AccessControlTabs,
  GroupDetailsTabs,
  UserProfileTabs,
} from './types';
import accessControlPageNavigation$ from './accessControlPageNavigation$';
import { accessControlNavigationOperations } from './accessControlPageNavigationOperations';

const isValidTab = (chosenTab: AccessControlTabs): boolean => {
  const options = Object.values(AccessControlTabs);
  return options.includes(chosenTab);
};

const accessControlPageRoute = new Route<AppRouterState, AccessControlRoute>({
  doesLocationMatch: ({ path }) => /\/access-control\/?.*?/.test(path),
  locationToRouterState: ({ path }) => {
    const parts = path.split('/');
    const accessControlTab = parts[2] as AccessControlTabs;

    if (isValidTab(accessControlTab)) {
      if (
        accessControlNavigationOperations.isMembersOverviewPage({
          accessControlTab,
        })
      ) {
        return {
          accessControlTab: AccessControlTabs.MEMBERS,
          userId: parts[3],
          userProfileActiveTabId: (parts[4] as UserProfileTabs) || 'roles',
        } satisfies AccessControlMemberRoute;
      }
      if (
        accessControlNavigationOperations.isGroupsOverviewPage({
          accessControlTab,
        })
      ) {
        return {
          accessControlTab: AccessControlTabs.PERMISSION_GROUPS,
          groupId: parts[3],
          groupActiveTabId: (parts[4] as GroupDetailsTabs) || 'users',
        } satisfies AccessControlGroupsRoute;
      }
      return {
        accessControlTab,
      };
    }

    return {
      accessControlTab: AccessControlTabs.MEMBERS,
    } as AccessControlRoute;
  },
  doesRouterStateMatch: ({ appModule }) =>
    appModule === AppModules.ACCESS_CONTROL,
  routerStateToLocation: routerState => {
    const { accessControlTab } = routerState;
    if (
      accessControlNavigationOperations.isMembersOverviewPage(routerState) &&
      routerState.userId
    ) {
      return {
        path: `/${AppModules.ACCESS_CONTROL}/${routerState.accessControlTab}/${routerState.userId}/${routerState.userProfileActiveTabId}`,
        title: 'User profile',
      };
    }

    if (
      accessControlNavigationOperations.isGroupsOverviewPage(routerState) &&
      routerState.groupId
    ) {
      return {
        path: `/${AppModules.ACCESS_CONTROL}/${routerState.accessControlTab}/${routerState.groupId}/${routerState.groupActiveTabId}`,
        title: 'Group details',
      };
    }
    const path = `/${AppModules.ACCESS_CONTROL}/${routerState.accessControlTab}`;

    if (accessControlTab === AccessControlTabs.MANAGE_USER_ROLES) {
      return {
        path,
        title: 'Roles',
      };
    } else if (accessControlTab === AccessControlTabs.PERMISSION_GROUPS) {
      return {
        path,
        title: 'Groups',
      };
    } else if (accessControlTab === AccessControlTabs.MEMBERS) {
      return {
        path,
        title: 'Users',
      };
    } else if (accessControlTab === AccessControlTabs.SSO_SETUP) {
      return {
        path,
        title: 'Single sign-on setup',
      };
    } else if (accessControlTab === AccessControlTabs.IP_ALLOWLIST_MANAGEMENT) {
      return {
        path,
        title: 'IP allow list',
      };
    } else if (accessControlTab === AccessControlTabs.SCIM_TOKEN) {
      return {
        path,
        title: 'SCIM token',
      };
    } else if (accessControlTab === AccessControlTabs.SSO_ATTRIBUTE_MAPPING) {
      return {
        path,
        title: 'SSO attribute mapping',
      };
    } else if (accessControlTab === AccessControlTabs.SERVICE_ACCOUNTS) {
      return {
        path,
        title: 'Service accounts',
      };
    } else if (accessControlTab === AccessControlTabs.SUBDIVISIONS_OVERVIEW) {
      return {
        path,
        title: SUBDIVISIONS_STRINGS.OVERVIEW_PAGE.PAGE_TITLE,
      };
    }

    return {
      path: `/${AppModules.ACCESS_CONTROL}/${AccessControlTabs.MEMBERS}`,
      title: 'Users',
    };
  },
  setApplicationStateFromRoute: routerState => {
    dispatchAction(navigateToAccessControlPage(routerState));
  },
  getPartialRouterStateStream: () => accessControlPageNavigation$.pipe(),
});

export default accessControlPageRoute;
