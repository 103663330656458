import { AqLayout } from '@ardoq/layout';
import { CreateNewButton, PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import { openNewEditor } from './utils';
import { GhostButton } from '@ardoq/button';
import { ArdoqId, AssetType } from '@ardoq/api-types';
import MainToolbar from 'menus/topbar/MainToolbar';
import Navbar from 'views/navbar/Navbar';
import { useWindowSize } from '@ardoq/hooks';
import AssetsBrowser2024 from '../components/AssetsBrowser2024/AssetsBrowser2024';
import { AppModules } from '../appContainer/types';
import {
  favoriteFilter,
  getAssetFilter,
  getCreatedByMeFilter,
} from '../components/AssetsBrowser2024/FilterBar/filters';
import { Features, hasFeature } from '@ardoq/features';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import { map } from 'rxjs';
import { connect } from '@ardoq/rxbeach';
import currentUser$ from 'streams/currentUser/currentUser$';

type MetamodelListProps = {
  hasNewJourneyFeature: boolean;
  currentUserId: ArdoqId;
};

const MetamodelList = ({
  hasNewJourneyFeature,
  currentUserId,
}: MetamodelListProps) => {
  const screenHeight = useWindowSize().height;
  const pageContent = (
    <AssetsBrowser2024
      appModule={AppModules.METAMODEL}
      alwaysActiveFilters={[getAssetFilter(AssetType.METAMODEL)]}
      filters={[favoriteFilter, getCreatedByMeFilter(currentUserId)]}
      scrollHeight={screenHeight - PAGE_TOPBAR_HEIGHT}
      useFolders={false}
    />
  );
  return hasNewJourneyFeature ? (
    <PageWrapper>
      <Navbar
        primaryContent="Metamodel"
        primaryButton={
          <CreateNewButton
            onClick={() => {
              openNewEditor({
                trackingLocation: 'metamodelsOverview',
              });
            }}
            data-intercom-target="create new metamodel"
          />
        }
        secondaryContent="Overview"
        toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
      />
      <PageBody backgroundColor="surfaceDefault">{pageContent}</PageBody>
    </PageWrapper>
  ) : (
    <AqLayout
      title="Metamodels"
      bodyContentStyle={{ height: '100%' }}
      renderHeaderButtons={() => (
        <GhostButton
          onClick={() => {
            openNewEditor({
              trackingLocation: 'metamodelsOverview',
            });
          }}
          data-intercom-target="create new metamodel"
        >
          Create new
        </GhostButton>
      )}
    >
      {pageContent}
    </AqLayout>
  );
};

export default connect(
  MetamodelList,
  currentUser$.pipe(
    map(currentUser => {
      return {
        hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
        currentUserId: currentUser._id,
      };
    })
  )
);
