import { connect } from '@ardoq/rxbeach';
import ModuleContainer from '../ModuleContainer';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import { ArdoqId, AssetType } from '@ardoq/api-types';
import { PAGE_TOPBAR_HEIGHT } from '@ardoq/page-layout';
import { ReportEventLocations } from '@ardoq/report-reader';
import {
  openCreateReport,
  openDashboard,
} from 'components/AppMainSidebar/utils';
import AssetsBrowser2024 from 'components/AssetsBrowser2024/AssetsBrowser2024';
import {
  favoriteFilter,
  getAssetFilter,
  getCreatedByMeFilter,
} from 'components/AssetsBrowser2024/FilterBar/filters';
import MainToolbar from 'menus/topbar/MainToolbar';
import {
  Permissions,
  hasPermission,
} from '../../streams/currentUserPermissions/permissionInterface';
import Navbar from 'views/navbar/Navbar';
import { AppModules } from '../types';
import { useWindowSize } from '@ardoq/hooks';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { trackEvent } from 'tracking/tracking';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';

import { ExcludeFalsy } from '@ardoq/common-helpers';
import { DashboardModule } from 'dashboard/types';
import { dashboardAccessControlInterface } from 'resourcePermissions/accessControlHelpers/dashboards';
import { map } from 'rxjs';
import { ButtonType } from '@ardoq/button';
import { ColorThemesButton } from 'dashboard/DashboardOverview/ColorThemesButton';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { permissionsOperations } from '@ardoq/access-control';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

type AnalyticsContainerProps = {
  currentUserIsOrgWriter: boolean;
  currentUserIsOrgAdmin: boolean;
  canCreateDashboard: boolean;
  currentUserId: ArdoqId;
};

const AnalyticsContainer = ({
  currentUserIsOrgWriter,
  currentUserIsOrgAdmin,
  canCreateDashboard,
  currentUserId,
}: AnalyticsContainerProps) => {
  const screenHeight = useWindowSize().height;
  return (
    <ModuleContainer $overflow="hidden">
      <ErrorBoundary logError={logError}>
        <PageWrapper>
          <Navbar
            primaryContent="Analytics"
            secondaryContent="Overview"
            toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
            primaryButton={
              <ButtonWithDropdown
                dataTestId="create-new-analytics-dropdown"
                isDisabled={!currentUserIsOrgWriter}
                dropdownSize={DropdownSize.S}
                buttonType={ButtonType.BRAND}
                alignMenuTo="right"
                onClick={() =>
                  trackEvent('Analytics Overview: clicked create new dropdown')
                }
                overflowMethod="ellipsis"
                options={[
                  hasPermission(Permissions.REPORTS_CREATE) && {
                    label: 'Report',
                    testId: 'open-new-report',
                    onClick: () => {
                      trackEvent('open-new-report');
                      openCreateReport(ReportEventLocations.ASSET_MANAGER);
                    },
                  },
                  canCreateDashboard && {
                    label: 'Dashboard',
                    testId: 'open-new-dashboard',
                    onClick: () => {
                      trackEvent('open-new-dashboard');
                      openDashboard({
                        dashboardModule: DashboardModule.BUILDER,
                      });
                    },
                  },
                ].filter(ExcludeFalsy)}
              >
                Create new
              </ButtonWithDropdown>
            }
            secondaryButton={currentUserIsOrgAdmin && <ColorThemesButton />}
          />
          <PageBody backgroundColor="surfaceDefault">
            <AssetsBrowser2024
              appModule={AppModules.ANALYTICS}
              filters={[
                favoriteFilter,
                getCreatedByMeFilter(currentUserId),
                getAssetFilter(AssetType.REPORT),
                getAssetFilter(AssetType.DASHBOARD),
              ]}
              alwaysActiveFilters={[
                getAssetFilter(AssetType.REPORT),
                getAssetFilter(AssetType.DASHBOARD),
              ]}
              scrollHeight={screenHeight - (PAGE_TOPBAR_HEIGHT + 60)}
              useFolders
            />
          </PageBody>
        </PageWrapper>
      </ErrorBoundary>
    </ModuleContainer>
  );
};

const analyticsContainer$ = currentUserPermissionContext$.pipe(
  map(permissionContext => {
    if (!permissionsOperations.isLoadedPermissionContext(permissionContext)) {
      return {
        currentUserIsOrgWriter: false,
        currentUserIsOrgAdmin: false,
        canCreateDashboard: false,
        currentUserId: currentUserInterface.getCurrentUserId(),
      };
    }
    return {
      currentUserIsOrgWriter: permissionsOperations.isOrgWriter(
        permissionContext.user
      ),
      currentUserIsOrgAdmin: permissionsOperations.isOrgAdmin(
        permissionContext.user
      ),
      canCreateDashboard:
        dashboardAccessControlInterface.canCreateDashboard(permissionContext),
      currentUserId: permissionContext.user._id,
    };
  })
);

export default connect(AnalyticsContainer, analyticsContainer$);
