import {
  AsyncMultiselect,
  AsyncMultiselectProps,
  SelectOption,
} from '@ardoq/select';
import { getUsers } from 'resourcePermissions/utils';
import { ArdoqId, UserWithRoles } from '@ardoq/api-types';
import { debounce } from 'lodash';

type UsersMultiSelectProps = Omit<
  AsyncMultiselectProps<UserWithRoles>,
  'inputValue' | 'onInputChange' | 'value' | 'onValueChange'
> & {
  value: UserWithRoles[];
  disabledUsersWithIds?: ArdoqId[];
  onValueChange: (value: UserWithRoles[]) => void;
};

export default function UsersMultiSelect({
  value,
  disabledUsersWithIds = [],
  onValueChange,
  ...props
}: UsersMultiSelectProps) {
  const getOptionsDebounced = debounce(
    (
      query: string,
      callback: (options: SelectOption<UserWithRoles>[]) => void
    ) => {
      getUsers(query, false).then(users => {
        const usersOptions: SelectOption<UserWithRoles>[] = users.map(user => ({
          label: getLabel(user),
          value: user,
          isDisabled: disabledUsersWithIds.includes(user._id),
        }));
        callback(usersOptions);
      });
    },
    300
  );

  const getLabel = (user: UserWithRoles) => `${user.name} (${user.email})`;

  return (
    <AsyncMultiselect
      {...props}
      loadOptions={getOptionsDebounced}
      defaultOptions
      onValueChange={values =>
        onValueChange(
          (values?.filter(val => typeof val !== 'string') ||
            []) as UserWithRoles[]
        )
      }
      value={value.map(v => ({
        label: getLabel(v),
        value: v,
      }))}
    />
  );
}
