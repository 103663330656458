import { IconName } from '@ardoq/icons';
import { SetupStageStep } from 'useCases/consts';
import {
  ImportStepConfig,
  MetamodelStepConfig,
  UseCaseSetupState,
} from './types';
import { GraphEdge, GraphNode } from '@ardoq/graph';
import { getId } from 'viewpointBuilder/getId';
import { enhancedScopeDataOperations } from 'viewpointBuilder/enhancedScopeData/enhancedScopeDataOperations';
import { SimpleViewModel } from 'viewpointBuilder/types';
import { MetaModel, APIMetaModelPreview } from '@ardoq/api-types';
import { metaModelOperations } from 'architectureModel/metaModelOperations';
import { enhancePartialScopeData } from '@ardoq/renderers';
import { isUndefined } from 'lodash';
import { currentDateTimeISO } from '@ardoq/date-time';

const getInitialState = (): UseCaseSetupState => ({
  componentTypeFieldsAndTriples: [],
  currentStep: SetupStageStep.PREVIEW,
  metamodelPreviewStep: {
    id: SetupStageStep.PREVIEW,
    label: 'Metamodel preview',
    description:
      'This is the recommended Metamodel for Application Lifecycle Management. You will import data into these components and references in the next step. This Metamodel can be modified after you complete the set up.',
    sidebarDescription: 'These are the fields and references in this Metamodel',
    // status: StepStatusType.PENDING,
    viewModel: setupOperations.metaModelToSimpleViewModel(
      setupOperations.getEmptyMetaModelPreview()
    ),
  },
  importSteps: [
    // NOTE ARD-20788: maybe move strings to a separate consts file
    {
      id: SetupStageStep.IMPORT,
      label: 'Applications',
      description:
        'Get your data into Ardoq using our pre-built integrations and API',
      sidebarDescription: 'Fields and references description placeholder',
      configuredImports: [
        {
          label: 'Application Lifecycle Management Template',
          _id: '123',
          iconName: IconName.INFO,
          onClick: () => alert('open config'),
          onDelete: () => alert('delete config'),
        },
      ],
      componentTypes: [
        {
          label: 'Applications',
          typeId: 'af8d9g9923d',
          numberOfComponents: 0,
        },
        {
          label: 'Application modules',
          typeId: 'af8d9g9933d',
          numberOfComponents: 0,
        },
      ],
      integrationOptions: [
        {
          label: 'Excel',
          onClick: () => alert('import from excel'),
          iconName: IconName.CLOUD_DOWNLOAD,
        },
        {
          label: 'Service Now',
          onClick: () => alert('import from SNOW'),
          iconName: IconName.SYNC,
        },
        {
          label: 'Surveys',
          onClick: () => alert('import from surveys'),
          iconName: IconName.SURVEYS,
        },
        {
          label: 'More integrations',
          onClick: () => alert('more integrations'),
          iconName: IconName.INTEGRATION,
        },
        {
          label: 'Manual',
          onClick: () => alert('manual import'),
          iconName: IconName.PERSON,
        },
      ],
      // status: StepStatusType.PENDING,
    },
  ],
});

const metaModelToSimpleViewModel = (metaModel: MetaModel): SimpleViewModel => {
  const scopeData = enhancePartialScopeData(
    metaModelOperations.metaModelToScopeData({
      metaModel: metaModelOperations.checkAndFixMetaModel(metaModel),
      isoDate: currentDateTimeISO(),
      userEmail: '',
      userId: '',
      userName: '',
    })
  );

  const rawGraphNodes =
    enhancedScopeDataOperations.createRawGraphNodes(scopeData);
  const rawGraphEdges =
    enhancedScopeDataOperations.createRawGraphEdges(scopeData);

  const graphNodes = new Map<string, GraphNode>(
    Array.from(rawGraphNodes).map(([id, { modelId, metaData }]) => {
      // In the metamodel view each type should just appear once, so we can use
      // the rawGraphNodes id as the graphNode id.
      const graphNode = GraphNode.create(modelId, false, id, metaData);
      return [id, graphNode];
    })
  );

  const graphEdges = new Map<string, GraphEdge>(
    Array.from(rawGraphEdges).map(
      ([_, { modelId, sourceId, targetId, metaData }]) => {
        const graphEdge = GraphEdge.create(
          modelId,
          sourceId,
          targetId,
          graphNodes,
          getId(),
          metaData
        );
        return [graphEdge.id, graphEdge];
      }
    )
  );

  return { graphNodes, graphEdges, graphGroups: new Map() };
};

const getEmptyMetaModelPreview = (): APIMetaModelPreview => ({
  componentTypes: [],
  referenceTypes: [],
  triples: [],
  componentTypeFieldsAndTriples: [],
});

const isImportStepConfig = (
  stepConfig: MetamodelStepConfig | ImportStepConfig
): stepConfig is ImportStepConfig =>
  ['configuredImports', 'componentTypes', 'integrationOptions'].every(
    key => key in stepConfig
  );

const getCurrentStepConfig = (
  state: UseCaseSetupState
): MetamodelStepConfig | ImportStepConfig => {
  if (state.currentStep === SetupStageStep.PREVIEW)
    return state.metamodelPreviewStep;

  const currentStepConfig = state.importSteps.find(
    step => step.id === state.currentStep
  );

  if (isUndefined(currentStepConfig))
    throw new Error('Use case step config should never be null');

  return currentStepConfig;
};

const setMetaModelPreviewInState = (
  state: UseCaseSetupState,
  { componentTypeFieldsAndTriples, ...metaModel }: APIMetaModelPreview
): UseCaseSetupState => {
  return {
    ...state,
    componentTypeFieldsAndTriples,
    metamodelPreviewStep: {
      ...state.metamodelPreviewStep,
      viewModel: setupOperations.metaModelToSimpleViewModel(metaModel),
    },
  };
};

const handleNavigateToSetupStep = (
  state: UseCaseSetupState,
  stepId: string
): UseCaseSetupState => ({ ...state, currentStep: stepId });

export const setupOperations = {
  getInitialState,
  setMetaModelPreviewInState,
  metaModelToSimpleViewModel,
  handleNavigateToSetupStep,
  getEmptyMetaModelPreview,
  getCurrentStepConfig,
  isImportStepConfig,
};
