import { FlexBox } from '@ardoq/layout';
import { SidebarMenuItem, SidebarMenuItemProps } from '@ardoq/sidebar-menu';

export const NavigationSidebarMenuItem = (props: SidebarMenuItemProps) => {
  return (
    <FlexBox
      flexDirection="column"
      marginBottom="none"
      marginTop="none"
      marginLeft="small"
      marginRight="small"
      gap="none"
    >
      <SidebarMenuItem {...props} />
    </FlexBox>
  );
};
