import ComponentTypeTag from './ComponentTypeTag';
import { DirectedTripleWithFilters, MetaModel } from '@ardoq/api-types';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { colors } from '@ardoq/design-tokens';
import { first, last } from 'lodash';
import { ArrowBackIcon, ArrowForwardIcon } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { FlexWithFontSize } from './atoms';
import styled from 'styled-components';

export const OutgoingArrow = () => (
  <ArrowForwardIcon
    style={{
      color: colors.iconOnInvertedSubtle,
      fill: colors.iconOnInvertedSubtle,
    }}
  />
);

export const IncomingArrow = () => (
  <ArrowBackIcon
    style={{
      color: colors.iconOnInvertedSubtle,
      fill: colors.iconOnInvertedSubtle,
    }}
  />
);

export const ReferenceTypeWithArrows = ({
  triple,
}: {
  triple: Pick<DirectedTripleWithFilters, 'direction' | 'referenceType'>;
}) => {
  const referenceArrow =
    triple.direction === 'outgoing' ? <OutgoingArrow /> : <IncomingArrow />;
  return (
    <>
      {referenceArrow}
      {triple.referenceType}
      {referenceArrow}
    </>
  );
};

const FlexBoxWithOverlay = styled(FlexWithFontSize)<{
  $showOverlay: boolean;
}>`
  ${({ $showOverlay }) => $showOverlay && 'opacity: 40%;'}
`;

type PathRepresentationProps = {
  path: DirectedTripleWithFilters[];
  metamodel: MetaModel;
};

const FullPathRepresentation = ({
  path,
  metamodel,
}: PathRepresentationProps) => {
  if (!path.length) return null;
  return (
    <Box maxWidth="500px">
      <Stack align="flex-start">
        {path.map((triple, index) => {
          const sourceName =
            triple.direction === 'outgoing'
              ? triple.sourceType
              : triple.targetType;
          const targetName =
            triple.direction === 'outgoing'
              ? triple.targetType
              : triple.sourceType;

          return (
            <FlexBox gap="xsmall" align="center" key={index}>
              <ComponentTypeTag
                componentTypeName={sourceName}
                metamodel={metamodel}
              />
              <ReferenceTypeWithArrows triple={triple} />
              <ComponentTypeTag
                componentTypeName={targetName}
                metamodel={metamodel}
              />
            </FlexBox>
          );
        })}
      </Stack>
    </Box>
  );
};

const CollapsedPathRepresentation = ({
  path,
  metamodel,
  hasNoFieldOptions,
}: PathRepresentationProps & { hasNoFieldOptions?: boolean }) => {
  const firstTriple = first(path);
  if (!firstTriple) return null;

  const lastTriple = last(path);
  if (!lastTriple) return null;

  const surveyComponentTypeName =
    firstTriple.direction === 'outgoing'
      ? firstTriple.sourceType
      : firstTriple.targetType;
  const lastTypeInPath =
    lastTriple.direction === 'outgoing'
      ? lastTriple.targetType
      : lastTriple.sourceType;
  return (
    <FlexBoxWithOverlay
      align="center"
      $showOverlay={Boolean(hasNoFieldOptions)}
    >
      <WithPopover
        content={() => (
          <FullPathRepresentation path={path} metamodel={metamodel} />
        )}
      >
        <FlexBox gap="xsmall" align="center">
          <ComponentTypeTag
            componentTypeName={surveyComponentTypeName}
            metamodel={metamodel}
            hideLabel
          />
          {firstTriple.direction === 'outgoing' ? (
            <OutgoingArrow />
          ) : (
            <IncomingArrow />
          )}
          {path.length > 1 ? '...' : firstTriple.referenceType}
          {lastTriple.direction === 'outgoing' ? (
            <OutgoingArrow />
          ) : (
            <IncomingArrow />
          )}
          <ComponentTypeTag
            componentTypeName={lastTypeInPath}
            metamodel={metamodel}
          />
        </FlexBox>
      </WithPopover>
    </FlexBoxWithOverlay>
  );
};

export default CollapsedPathRepresentation;
