import { DoqFace, DoqType, DoqSize } from '@ardoq/doq';
import { spacing } from '@ardoq/design-tokens';
import { Header2, Text } from '@ardoq/typography';
import styled from 'styled-components';
import { Stack } from '@ardoq/layout';

const Wrapper = styled(Stack)`
  height: 100%;
  width: 100%;
  padding: ${spacing.s16};
`;

const DoqyContent = styled(Stack)`
  width: 240px;
  text-align: center;
`;

type EmptyStateProps = {
  doqType: DoqType;
  title: string;
  text: string;
};

const EmptyState = ({ doqType, title, text }: EmptyStateProps) => {
  return (
    <Wrapper align="center" justify="center">
      <DoqyContent gap="large" align="center">
        <DoqFace doqType={doqType} doqSize={DoqSize.MEDIUM} />
        <Header2>{title}</Header2>
        <Text variant="text2">{text}</Text>
      </DoqyContent>
    </Wrapper>
  );
};

export default EmptyState;
