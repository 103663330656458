import { assetsViewState$ } from 'assets/navigation/viewState$';
import {
  AssetInfo,
  AssetsUsageState,
  AssetsViewState,
  AssetUsageCommands,
  GroupedAssets,
  SupportedAssetTypes,
  UsageEntry,
} from 'assets/types';
import { combineLatest, map } from 'rxjs';
import { capitalize } from 'lodash';
import { assetsUsage$ } from 'assets/usage/usage$';
import { groupBy, keyBy } from 'lodash/fp';
import surveys$, { SurveysStreamShape } from 'streams/surveys/surveys$';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import broadcast$ from 'broadcasts/broadcast$';
import reports$ from 'streams/reports/reports$';
import viewpoints$ from 'viewpoints/viewpoints$';
import presentations$ from 'streams/presentations/presentations$';
import dashboards$ from 'streams/dashboards/dashboards$';
import {
  openDashboard,
  openReportInReader,
} from 'components/AppMainSidebar/utils';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToSurveyForm } from 'surveyAdmin/navigation/actions';
import {
  initiateNavigationToEditBroadcastForm,
  navigateToViewpointForm,
} from 'router/navigationActions';
import { startPresentation } from 'presentation/utils';
import { formatDateTime } from '@ardoq/date-time';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { CollectionStream } from 'streams/utils/streamUtils';
import { APIReportAttributes } from '@ardoq/api-types';
import { BroadcastStreamShape } from 'broadcasts/types';
import { ViewpointsStreamShape } from 'viewpoints/types';
import { DashboardsStreamShape } from 'streams/dashboards/types';
import { PresentationsStreamShape } from 'streams/presentations/types';
import workspaces$, { WorkspacesState } from 'streams/workspaces/workspaces$';
import { IconName } from '@ardoq/icons';

const NOT_FOUND_WORKSPACE = '(Workspace not found)';

const getWorkspaceInfo = (
  workspacesById: WorkspacesState['byId'],
  workspaceId: string
): AssetInfo => {
  const workspace = workspacesById[workspaceId];
  if (!workspace) {
    return {
      properties: [
        {
          label: 'Name',
          value: NOT_FOUND_WORKSPACE,
        },
      ],
      meta: {
        icon: IconName.WORKSPACE,
        name: NOT_FOUND_WORKSPACE,
      },
    };
  }

  return {
    properties: [
      {
        label: 'Name',
        value: workspace.name,
      },
      {
        label: 'Created on',
        value: formatDateTime(workspace.created, getCurrentLocale()),
      },
    ],
    meta: {
      icon: IconName.WORKSPACE,
      name: workspace.name,
    },
  };
};

const assetInfo = (
  assetType: SupportedAssetTypes,
  assetId: string,
  { workspacesById }: { workspacesById: WorkspacesState['byId'] }
): AssetInfo => {
  return {
    ['workspace']: getWorkspaceInfo(workspacesById, assetId),
  }[assetType];
};

const getGroupedAsset = <T extends { name: string }>(
  entries: UsageEntry[],
  byId: Record<string, T>
): T[] => {
  return (entries || [])
    .map(({ _id }) => byId[_id])
    .filter(ExcludeFalsy)
    .sort((a, b) => localeCompare(a.name, b.name, getCurrentLocale()));
};

const commands: AssetUsageCommands = {
  openReport: ({ _id }) => {
    openReportInReader({
      reportId: _id,
    });
  },
  openSurvey: ({ _id }) => {
    dispatchAction(navigateToSurveyForm(_id));
  },
  openBroadcast: ({ _id }) => {
    dispatchAction(initiateNavigationToEditBroadcastForm(_id));
  },
  openViewpoint: ({ _id }) => {
    dispatchAction(navigateToViewpointForm(_id));
  },
  openPresentation: ({ _id }) => {
    startPresentation(_id);
  },
  openDashboard: ({ _id }) => {
    openDashboard({
      dashboardId: _id,
    });
  },
  openAsset: ({ id, type }) => {
    if (type === 'workspace') {
      window.open(`/app/view/pagesView/workspace/${id}`, '_blank');
    }
  },
};

export const buildViewModel = ({
  assetsViewState: { assetType, assetId },
  assetsUsage,
  reports: { byId: reportsById },
  surveys: { byId: surveysById },
  broadcasts: { broadcastsById },
  viewpoints: { viewpoints },
  presentations: { byId: presentationsById },
  dashboards: { dashboardsById },
  workspaces: { byId: workspacesById },
}: {
  assetsViewState: AssetsViewState;
  assetsUsage: AssetsUsageState;
  reports: CollectionStream<APIReportAttributes>;
  surveys: SurveysStreamShape;
  broadcasts: BroadcastStreamShape;
  viewpoints: ViewpointsStreamShape;
  presentations: PresentationsStreamShape;
  dashboards: DashboardsStreamShape;
  workspaces: WorkspacesState;
}) => {
  if (!assetId || !assetType) {
    return {
      title: 'Asset overview',
      assetName: 'Asset not found',
    };
  }

  const viewpointsById = keyBy('_id', viewpoints);

  const assetsUsageByType: Record<string, UsageEntry[]> = groupBy<UsageEntry>(
    'type',
    assetsUsage[assetId]?.usage ?? []
  );

  const groupedAssets: GroupedAssets = {
    reports: getGroupedAsset(assetsUsageByType.report, reportsById),
    surveys: getGroupedAsset(assetsUsageByType.survey, surveysById),
    broadcasts: getGroupedAsset(assetsUsageByType.broadcast, broadcastsById),
    viewpoints: getGroupedAsset(assetsUsageByType.viewpoint, viewpointsById),
    presentations: getGroupedAsset(
      assetsUsageByType.presentation,
      presentationsById
    ),
    dashboards: getGroupedAsset(assetsUsageByType.dashboard, dashboardsById),
  };

  return {
    title: capitalize(assetType),
    assetId,
    assetType,
    assetInfo: assetInfo(assetType, assetId, { workspacesById }),
    isUsageLoading: assetsUsage[assetId]?.status === 'loading',
    groupedAssets,
    commands,
  };
};

export const viewModel$ = combineLatest({
  assetsViewState: assetsViewState$,
  assetsUsage: assetsUsage$,
  reports: reports$,
  surveys: surveys$,
  broadcasts: broadcast$,
  viewpoints: viewpoints$,
  presentations: presentations$,
  dashboards: dashboards$,
  workspaces: workspaces$,
}).pipe(map(buildViewModel));
