import {
  ActivePresentation,
  validSlides,
} from 'components/presentationSidebar/containers/ActivePresentation';
import { combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { NewSlide } from 'presentationEditor/types';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { addSlideFromCurrentContext } from 'presentationEditor/actions';
import { resetAndClosePresentationEditor } from 'presentationEditor/presentationUtil';
import { ErrorBoundary } from '@ardoq/error-boundary';
import selectedModule$ from '../selectedModule$';
import { AppModules } from '../types';
import { isOnPremise } from 'appConfig';
import {
  APIDashboardAttributes,
  APIPresentationAssetAttributes,
  APIReportAttributes,
  APISlideAttributes,
  PersistedMetamodel,
  Report,
  SlideTypes,
  ViewIds,
} from '@ardoq/api-types';
import togglePresentationEditor$ from '../togglePresentationEditor$';
import metamodelNavigation$ from 'metamodel/navigation/metamodelNavigation$';
import {
  MetamodelNavigationState,
  MetamodelPane,
} from 'metamodel/navigation/types';
import { logError } from '@ardoq/logging';
import { isViewEligibleForNewAssets } from 'viewDeprecation/restrictedViews';
import dashboardNavigation$ from 'dashboard/navigation/dashboardNavigation$';
import { DashboardRoute } from 'router/appRouterTypes';
import dashboards$ from 'streams/dashboards/dashboards$';
import { DashboardsStreamShape } from 'streams/dashboards/types';
import reportNavigation$ from 'report/navigation/reportNavigation$';
import { ReportNavigationState } from 'report/navigation/reducers';
import reports$ from 'streams/reports/reports$';
import { leftTabOptions$ } from 'appLayout/getTabOptionsStreamWithLocation';
import metricsDashboard$, {
  MetricsDashboard$State,
} from 'dashboard/UsageMetrics/usageMetricsDashboard$';
import { DashboardModule } from 'dashboard/types';
import { ReportModule } from 'report/types';
import reportReader$, {
  ReportReader$State,
} from 'report/ReportReader/reportReader$';
import { ActivePresentationWrapper } from 'components/presentationSidebar/components/atoms';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { organizationInterface } from 'modelInterface/organization/organizationInterface';
import { CollectionStream } from 'streams/utils/streamUtils';
import { contextPresentation$ } from 'streams/presentations/contextPresentation$';
import { organizationOperations } from '@ardoq/core';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';
import { currentUserAccessControlInterface } from 'resourcePermissions/accessControlHelpers/currentUser';
import { UnauthorizedSlideType } from 'components/presentationSidebar/types';
import slides$ from 'streams/slides/slides$';
import { filterQueryToApiReportFilterQuery } from '@ardoq/report-reader';

type GetNewSlideArgs = {
  selectedModule: AppModules;
  selectedDashboard: APIDashboardAttributes | null;
  selectedReport:
    | (Report & {
        sort?: ReportReader$State['sort'];
        filters?: ReportReader$State['filterQuery'];
      })
    | null;
  selectedMetamodel: PersistedMetamodel | null;
};
const getNewSlide = ({
  selectedModule,
  selectedDashboard,
  selectedReport,
  selectedMetamodel,
}: GetNewSlideArgs): NewSlide | null => {
  switch (selectedModule) {
    case AppModules.REPORTS:
      return selectedReport
        ? {
            type: SlideTypes.REPORT,
            reportId: selectedReport._id,
            reportName: selectedReport.name,
            reportParams: {
              sort: selectedReport.sort?.columnKey,
              order: selectedReport.sort?.order,
              filters: selectedReport.filters
                ? filterQueryToApiReportFilterQuery(selectedReport.filters)
                : undefined,
            },
          }
        : null;
    case AppModules.DASHBOARDS:
      return selectedDashboard
        ? {
            type: SlideTypes.DASHBOARD,
            dashboardId: selectedDashboard._id,
            dashboardName: selectedDashboard.name,
          }
        : null;
    case AppModules.METAMODEL: {
      return selectedMetamodel
        ? {
            type: SlideTypes.METAMODEL,
            metamodelId: selectedMetamodel._id,
            fullOrg: selectedMetamodel.wholeOrg,
            name: selectedMetamodel.name,
          }
        : null;
    }
    default:
      return {
        viewId: ViewIds.NONE,
        type: SlideTypes.VISUALIZATION,
      };
  }
};

type CanAddSlideArgs = {
  selectedModule: AppModules;
  selectedDashboard: APIDashboardAttributes | null;
  selectedReport: Report | null;
  metamodelNavigation: MetamodelNavigationState;
  activeViewId: ViewIds;
};
const canAddSlide = ({
  selectedModule,
  selectedDashboard,
  selectedReport,
  metamodelNavigation,
  activeViewId,
}: CanAddSlideArgs) => {
  const { pane, metamodelId } = metamodelNavigation;

  if (selectedModule === AppModules.REPORTS) {
    return Boolean(selectedReport);
  } else if (selectedModule === AppModules.DASHBOARDS) {
    return Boolean(selectedDashboard);
  } else if (selectedModule === AppModules.METAMODEL) {
    return Boolean(metamodelId) && pane === MetamodelPane.SELECTED;
  } else if (selectedModule === AppModules.EXTERNAL_DOCUMENT) {
    return false;
  }
  return Boolean(activeViewId) && isVisualizationSupported(activeViewId);
};

const isVisualizationSupported = (viewId: ViewIds) => {
  if (!isViewEligibleForNewAssets(viewId)) {
    return false;
  }

  if (viewId !== ViewIds.NONE) {
    return true;
  }

  if (isOnPremise()) {
    return true;
  }

  const organization = organizationInterface.getById(
    currentUserInterface.getCurrentOrgId()
  );

  return organizationOperations.hasCustomDomain(organization);
};

const getSelectedDashboard = (
  dashboardNavigation: DashboardRoute,
  dashboards: DashboardsStreamShape,
  metricsDashboard: MetricsDashboard$State
) => {
  if (dashboardNavigation.dashboardModule === DashboardModule.OVERVIEW) {
    return null;
  } else if (
    dashboardNavigation.dashboardModule === DashboardModule.USAGE_METRICS
  ) {
    return metricsDashboard.dashboardData;
  }
  return dashboardNavigation.selectedDashboardId
    ? dashboards.dashboardsById[dashboardNavigation.selectedDashboardId]
    : null;
};

interface PresentationSidebarProps {
  presentation: APIPresentationAssetAttributes | undefined;
  slides: (APISlideAttributes | UnauthorizedSlideType)[];
  metamodelNavigation: MetamodelNavigationState;
  dashboards: DashboardsStreamShape;
  dashboardNavigation: DashboardRoute;
  reports: CollectionStream<APIReportAttributes>;
  reportNavigation: ReportNavigationState;
  reportReader: ReportReader$State;
  selectedModule: AppModules;
  isToggled: boolean;
  metricsDashboard: MetricsDashboard$State;
  activeViewId: ViewIds;
  permissionContext: PermissionContext;
}

export const PresentationSidebar = ({
  presentation,
  slides,
  metamodelNavigation,
  dashboards,
  reports,
  dashboardNavigation,
  reportNavigation,
  reportReader,
  selectedModule,
  isToggled,
  metricsDashboard,
  activeViewId,
  permissionContext,
}: PresentationSidebarProps) => {
  const selectedDashboard = getSelectedDashboard(
    dashboardNavigation,
    dashboards,
    metricsDashboard
  );
  const selectedReport =
    reportNavigation.reportId &&
    reportNavigation.reportModule !== ReportModule.OVERVIEW
      ? {
          ...reports.byId[reportNavigation.reportId],
          sort: reportReader.sort,
          filters: reportReader.filterQuery,
        }
      : null;

  const selectedMetamodel = metamodelNavigation.metamodel || null;
  return (
    <ErrorBoundary logError={logError}>
      {isToggled && presentation && (
        <ActivePresentationWrapper>
          <ActivePresentation
            permissionContext={permissionContext}
            canCurrentUserWrite={currentUserAccessControlInterface.canCurrentUserWrite()}
            currentUserIsOrgAdmin={currentUserAccessControlInterface.currentUserIsOrgAdmin()}
            presentation={presentation}
            slides={slides}
            onSlidesButtonClick={() => {
              const newSlide = getNewSlide({
                selectedModule,
                selectedDashboard,
                selectedReport,
                selectedMetamodel,
              });
              if (newSlide) {
                dispatchAction(
                  addSlideFromCurrentContext({
                    newSlide,
                    presentation,
                  })
                );
              } else {
                throw new Error(
                  `Could not create slide for module ${selectedModule}`
                );
              }
            }}
            onCloseClick={() => resetAndClosePresentationEditor()}
            viewIsSupported={canAddSlide({
              selectedModule,
              selectedDashboard,
              selectedReport,
              metamodelNavigation,
              activeViewId,
            })}
          />
        </ActivePresentationWrapper>
      )}
    </ErrorBoundary>
  );
};

const toPresentationSidebarStream = ({
  presentation,
  slides,
  ...state
}: Omit<PresentationSidebarProps, 'slides'> & {
  slides: CollectionStream<APISlideAttributes>;
}) => {
  return {
    ...state,
    presentation,
    slides: presentation ? validSlides(presentation, slides.byId) : [],
  };
};

export default connect(
  PresentationSidebar,
  combineLatest({
    isToggled: togglePresentationEditor$,
    slides: slides$,
    presentation: contextPresentation$,
    dashboardNavigation: dashboardNavigation$,
    dashboards: dashboards$,
    reports: reports$,
    reportNavigation: reportNavigation$,
    reportReader: reportReader$,
    metamodelNavigation: metamodelNavigation$,
    selectedModule: selectedModule$.pipe(
      map(({ selectedModule }) => selectedModule)
    ),
    metricsDashboard: metricsDashboard$,
    activeViewId: leftTabOptions$.pipe(
      map(({ activeTabId }) => activeTabId),
      filter(Boolean),
      startWith(ViewIds.NONE)
    ),
    permissionContext: currentUserPermissionContext$,
  }).pipe(map(toPresentationSidebarStream))
);
