import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  fetchAwsSettingsSuccess,
  FetchAwsSettingsSuccessPayload,
  setSettingsAsyncStatus,
} from './actions';
import { SettingsState } from './types';
import { AsyncStatus } from 'integrations/common/types/api';

const defaultState: SettingsState = {
  asyncStatus: 'INIT',
  settings: {
    externalId: '',
    ardoqRoleArn: '',
  },
};

const setSettingsAsyncStatusReducer = (
  state: SettingsState,
  asyncStatus: AsyncStatus
) => ({
  ...state,
  asyncStatus,
});

const handleSetSettingsAsyncStatus = reducer<SettingsState, AsyncStatus>(
  setSettingsAsyncStatus,
  setSettingsAsyncStatusReducer
);

const fetchAwsSettingsSuccessReducer = (
  state: SettingsState,
  { settings }: FetchAwsSettingsSuccessPayload
): SettingsState => {
  return {
    ...state,
    settings,
    asyncStatus: 'SUCCESS',
  };
};
const handleFetchAwsSettingsSuccess = reducer<
  SettingsState,
  FetchAwsSettingsSuccessPayload
>(fetchAwsSettingsSuccess, fetchAwsSettingsSuccessReducer);

export const awsSettings$ = persistentReducedStream(
  `awsSettings$`,
  defaultState,
  [handleFetchAwsSettingsSuccess, handleSetSettingsAsyncStatus]
);
