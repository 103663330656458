import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { UNIFIED_INTEGRATION_IDS } from 'integrations/unified/constants';
import {
  Connection,
  ConnectionRequestPayload,
} from '@ardoq/api-types/integrations';

const INTEGRATION_IDS_WITH_CONNECTIONS: Array<IntegrationId> = [
  'aws-v3',
  'azure-v3',
  'servicenow-v3',
  'microsoft-entra-id',
  'signavio-exporter',
  'it-pedia',
  ...UNIFIED_INTEGRATION_IDS,
];
export const isIntegrationWithConnections = (integrationId: IntegrationId) =>
  INTEGRATION_IDS_WITH_CONNECTIONS.includes(integrationId);

export const getConnectionMethod = (
  connection: ConnectionRequestPayload | Connection
) => {
  if ('authenticationType' in connection) {
    return connection.authenticationType;
  }

  if ('authMethod' in connection) {
    return connection.authMethod;
  }

  return 'default';
};
