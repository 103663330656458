import { map, switchMap, tap } from 'rxjs/operators';
import {
  collectRoutines,
  routine,
  extractPayload,
  ofType,
  dispatchAction,
  ActionCreatorWithoutNamespace,
} from '@ardoq/rxbeach';
import { dashboardApi, getErrorStatusCode, handleError } from '@ardoq/api';
import {
  fetchAllDashboardsRequested,
  fetchAllDashboardsSucceeded,
  fetchAllDashboardsFailed,
  dashboardDeleted,
  dashboardCopied,
  dashboardRenamed,
} from './actions';
import { showToast, ToastType } from '@ardoq/status-ui';
import { logError } from '@ardoq/logging';
import { Team } from '@ardoq/profiling';
import { ApiDashboardsFailurePayload } from './types';
import { ArdoqError, getArdoqErrorMessage } from '@ardoq/common-helpers';
import { reportNamespace } from '../reports/actions';
import { apiFetchCurrentUserPermissions } from '../currentUserPermissions/actions';
import { fetchAllRequested } from 'streams/crud/actions';

const log = (errorType: string, error: ArdoqError) => {
  logError(error, errorType, { tags: { team: Team.IMPACT } });
  return {
    errorMessage: getArdoqErrorMessage(error),
    status: getErrorStatusCode(error),
  };
};

const logAndDispatch = (
  errorType: string,
  actionFunc: ActionCreatorWithoutNamespace<ApiDashboardsFailurePayload>,
  error: ArdoqError
) => {
  const { errorMessage, status } = log(errorType, error);
  showToast(errorMessage, ToastType.INFO);
  dispatchAction(actionFunc({ message: errorMessage, status }));
};

const logAndShowToastHandler = (errorType: string) => (error: ArdoqError) => {
  const { errorMessage } = log(errorType, error);
  showToast(errorMessage, ToastType.INFO);
};

const logAndDispatchHandler =
  (
    errorType: string,
    actionFunc: ActionCreatorWithoutNamespace<ApiDashboardsFailurePayload>
  ) =>
  (error: ArdoqError) =>
    logAndDispatch(errorType, actionFunc, error);

const getDashboards = routine(
  ofType(fetchAllDashboardsRequested),
  switchMap(dashboardApi.fetchAll),
  handleError(
    logAndDispatchHandler(
      'Error while fetching dashboards',
      fetchAllDashboardsFailed
    )
  ),
  map(fetchAllDashboardsSucceeded),
  tap(dispatchAction)
);

const deleteDashboard = routine(
  ofType(dashboardDeleted),
  extractPayload(),
  switchMap(dashboardApi.delete),
  handleError(logAndShowToastHandler('Error while deleting dashboard'))
);

const copyDashboard = routine(
  ofType(dashboardCopied),
  extractPayload(),
  switchMap(
    ({ dashboardId, dashboardName, folderName, isShallowCopy: shallowCopy }) =>
      dashboardApi.copy({
        id: dashboardId,
        newName: dashboardName,
        targetFolderName: folderName,
        shallowCopy,
      })
  ),
  handleError(logAndShowToastHandler('Error while copying dashboard')),
  tap(() => {
    dispatchAction(fetchAllRequested(reportNamespace));
    dispatchAction(apiFetchCurrentUserPermissions());
  })
);

const renameDashboard = routine(
  ofType(dashboardRenamed),
  extractPayload(),
  switchMap(dashboardApi.rename),
  handleError(logAndShowToastHandler('Error while renaming dashboard'))
);

export const dashboardRoutines = collectRoutines(
  getDashboards,
  deleteDashboard,
  copyDashboard,
  renameDashboard
);
