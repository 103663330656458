import { SimplifiedSubdivisionSummary, Subdivision } from '@ardoq/api-types';
import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import {
  DIVISIONS_USER_EVENTS,
  trackDivisionEditorUserEvent,
} from '../trackingUtils';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { deleteSubdivision } from 'streams/subdivisions/actions';
import { InlineText, Paragraph, Text } from '@ardoq/typography';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import {
  ArdoqLoaderComponent,
  LoaderColor,
} from '@ardoq/ardoq-loader-component';
import { subdivisionSummary$ } from './subdivisionSummary$/subdivisionSummary$';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';

const _DeleteSubdivisionModal = ({
  isLoading,
  subdivision,
  simplifiedSubdivisionSummary,
  onCancel,
  onDelete,
}: {
  isLoading: boolean;
  subdivision: Subdivision;
  simplifiedSubdivisionSummary: SimplifiedSubdivisionSummary;
  onCancel: () => void;
  onDelete: (subdivision: Subdivision) => void;
}) => {
  return (
    <ModalTemplate
      headerText={SUBDIVISIONS_STRINGS.DELETE_SUBDIVISION_MODAL.TITLE}
      secondaryButtonText={'Keep'}
      onSecondaryButtonClick={onCancel}
      dangerButtonText={'Delete'}
      isDangerButtonDisabled={isLoading}
      onDangerButtonClick={() => onDelete(subdivision)}
      modalSize={ModalSize.S}
    >
      <ModalLayout>
        {isLoading ? (
          <FlexBox height="full" align="center" justify="center">
            <ArdoqLoaderComponent
              labelPosition="vertical"
              loaderColor={LoaderColor.BRAND}
              loaderType="logo"
              size={50}
            />
          </FlexBox>
        ) : (
          <Stack gap="medium">
            {simplifiedSubdivisionSummary?.componentCount > 0 ? (
              <>
                <Paragraph>
                  {`${SUBDIVISIONS_STRINGS.DELETE_SUBDIVISION_MODAL.THIS_SUBDIVISION} ${subdivision.name} `}{' '}
                  provides access to{' '}
                  <InlineText variant="text1Bold">
                    {simplifiedSubdivisionSummary.componentCount} components
                  </InlineText>
                </Paragraph>
              </>
            ) : (
              <Paragraph>
                {`${SUBDIVISIONS_STRINGS.DELETE_SUBDIVISION_MODAL.THIS_SUBDIVISION} ${subdivision.name} `}{' '}
                doesn&apos;t give access to any components.
              </Paragraph>
            )}
            {simplifiedSubdivisionSummary?.reports.length > 0 && (
              <>
                <Paragraph>
                  {
                    SUBDIVISIONS_STRINGS.DELETE_SUBDIVISION_MODAL
                      .SUBDIVISION_AFFECTS_REPORTS
                  }
                </Paragraph>
                <Box>
                  <Text variant="text1Bold">Affected reports</Text>
                  <ul>
                    {simplifiedSubdivisionSummary.reports.map(
                      ({ name }, index) => (
                        <li key={index}>{name}</li>
                      )
                    )}
                  </ul>
                </Box>
              </>
            )}
            <Paragraph>
              {
                SUBDIVISIONS_STRINGS.DELETE_SUBDIVISION_MODAL
                  .SUBDIVISION_CANNOT_RESTORE
              }
            </Paragraph>
          </Stack>
        )}
      </ModalLayout>
    </ModalTemplate>
  );
};

const DeleteSubdivisionModal = connect(
  _DeleteSubdivisionModal,
  subdivisionSummary$
);

export const showDeleteSubdivisionModal = (subdivision: Subdivision) => {
  modal(closeModal => (
    <DeleteSubdivisionModal
      subdivision={subdivision}
      onCancel={() => closeModal(true)}
      onDelete={subdivision => {
        trackDivisionEditorUserEvent(DIVISIONS_USER_EVENTS.DELETE_DIVISION);
        dispatchAction(deleteSubdivision(subdivision._id));
        closeModal(true);
      }}
    />
  ));
};
