import { APIModelAttributes, ArdoqId } from '@ardoq/api-types';
import { ArdoqError } from '@ardoq/common-helpers';
import { actionCreator } from '@ardoq/rxbeach';

export const showWorkspaceModelEditor = actionCreator<{
  workspaceId: ArdoqId;
}>('[workspaceModelEditor] SHOW_WORKSPACE_MODEL_EDITOR');

interface GetWorkspacePayload {
  workspaceId: ArdoqId;
}

export const getUsedTypeIds = actionCreator<GetWorkspacePayload>(
  '[workspaceModelEditor] GET_USED_TYPE_ID'
);

export interface UsedTypeIdsPayload {
  usedTypeIds?: Set<ArdoqId>;
}

export const setUsedTypeIds = actionCreator<UsedTypeIdsPayload>(
  '[workspaceModelEditor] SET_USED_TYPE_ID'
);

export const saveModelTypeChanges = actionCreator<
  GetWorkspacePayload & {
    model: APIModelAttributes;
  }
>('[workspaceModelEditor] SAVE_MODEL_TYPE_CHANGES');

export const saveModalEditorChangesSuccess = actionCreator<{
  newModel: APIModelAttributes;
}>('[workspaceModelEditor] SAVE_MODEL_TYPE_CHANGES_SUCCESS');
export const saveModalEditorChangesError = actionCreator<{
  error: ArdoqError;
}>('[workspaceModelEditor] SAVE_MODEL_TYPE_CHANGES_ERROR');

export const excludeWorkspaceFromScenario = actionCreator<{
  scenarioId: ArdoqId;
  workspaceId: ArdoqId;
}>('[workspaceModelEditor] EXCLUDE_WORKSPACE_FROM_SCENARIO');
