import {
  GroupFieldLayout,
  RadioGroup,
  RadioGroupItemType,
  RadioOption,
} from '@ardoq/forms';
import { SubdivisionProps } from 'subdivisionEditor/types';
import { SubdivisionComponentAssignmentsMode } from '@ardoq/api-types';
import { FlexBox } from '@ardoq/layout';

const OPTIONS: RadioOption[] = [
  {
    label: 'Manual configuration',
    value: SubdivisionComponentAssignmentsMode.MANUAL,
    isDisabled: false,
    helperText: 'Select components manually in the workspace views',
  },
  {
    label: 'Refine by search',
    value: SubdivisionComponentAssignmentsMode.ADVANCED_SEARCH,
    helperText:
      'Search for components to add to the subdivision using an advanced search',
    isDisabled: false,
  },
];

export const AddingComponentsModeSelector = (props: SubdivisionProps) => {
  return (
    <FlexBox>
      <RadioGroup
        label="Adding components mode"
        options={OPTIONS}
        groupFieldLayout={GroupFieldLayout.HORIZONTAL}
        groupItemType={RadioGroupItemType.CARD}
        value={props.subdivision.componentAssignmentsMode}
        onChange={props.selectComponentsCommands.setComponentSelectionMode}
        dataTestId="division-editor-adding-components-mode-selector"
      ></RadioGroup>
    </FlexBox>
  );
};
