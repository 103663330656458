import { s16, s24, s32, s40_DEPRECATED, s8 } from '@ardoq/design-tokens';

export const horizontalSpacing = {
  SMALL: s16,
  SMALLEST: s8,
  MEDIUM: s24,
  LARGE: s32,
  LARGEST: s40_DEPRECATED,
};

export const COLUMN_MAX_WIDTH = '261px';
