import { actionCreator } from '@ardoq/rxbeach';

export const addRequiredComponent = actionCreator<string>(
  '[viewpointBuilderRequiredComponents] ADD_REQUIRED_COMPONENT'
);

export const removeRequiredComponent = actionCreator<string>(
  '[viewpointBuilderRequiredComponents] REMOVE_REQUIRED_COMPONENT'
);

export const graphNodeHovered = actionCreator<string | null>(
  '[viewpointBuilderRequiredComponents] GRAPH_NODE_HOVERED'
);
