import { modal } from '@ardoq/modal';
import {
  carry,
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  addSlideToExistingPresentation,
  addSlideToNewOrExistingPresentation,
  addSlideToPresentationDialog,
  createDashboardSlide,
  createLucidSlide,
  createMetamodelSlide,
  createPresentationAndAddSlide,
  createReportSlide,
  createVisualizationSlide,
} from './actions';
import { togglePresentationEditor } from 'appContainer/actions';
import { contextInterface } from 'modelInterface/contextInterface';
import {
  addSlideFromCurrentContext,
  showPresentationEditor,
} from 'presentationEditor/actions';
import {
  NewDashboardSlide,
  NewLucidSlide,
  NewMetamodelSlide,
  NewReportSlide,
  NewVisualizationSlide,
} from 'presentationEditor/types';
import ChooseEntityDialogContent from 'components/ChooseEntityDialogContent/ChooseEntityDialogContent';
import {
  ChooseEntityResult,
  ChooseEntityResultType,
} from 'components/ChooseEntityDialogContent/types';
import { exhaustMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { context$ } from 'streams/context/context$';
import { SlideTypes } from '@ardoq/api-types';
import presentations$ from 'streams/presentations/presentations$';
import { createPresentation$ } from 'streams/presentations/routineUtils';
import { getPresentationSelectOptions } from 'admin/bundles/BundleEditor/utils';

const handleCreateDashboardSlide = routine(
  ofType(createDashboardSlide),
  extractPayload(),
  map(
    ({ dashboardId, dashboardName }): NewDashboardSlide => ({
      type: SlideTypes.DASHBOARD,
      dashboardId,
      dashboardName,
    })
  ),
  tap(newSlide => dispatchAction(addSlideToNewOrExistingPresentation(newSlide)))
);

const handleCreateReportSlide = routine(
  ofType(createReportSlide),
  extractPayload(),
  map(
    ({ reportId, reportName, reportParams }): NewReportSlide => ({
      type: SlideTypes.REPORT,
      reportId,
      reportName,
      reportParams,
    })
  ),
  tap(newSlide => dispatchAction(addSlideToNewOrExistingPresentation(newSlide)))
);

const handleCreateMetamodelSlide = routine(
  ofType(createMetamodelSlide),
  extractPayload(),
  map(
    ({ name, fullOrg, metamodelId }): NewMetamodelSlide => ({
      type: SlideTypes.METAMODEL,
      name,
      fullOrg,
      metamodelId,
    })
  ),
  tap(newSlide => dispatchAction(addSlideToNewOrExistingPresentation(newSlide)))
);

const handleCreateVisualizationSlide = routine(
  ofType(createVisualizationSlide),
  extractPayload(),
  map(
    (viewId): NewVisualizationSlide => ({
      type: SlideTypes.VISUALIZATION,
      viewId,
    })
  ),
  tap(newSlide => dispatchAction(addSlideToNewOrExistingPresentation(newSlide)))
);

const handleCreateLucidSlide = routine(
  ofType(createLucidSlide),
  extractPayload(),
  map(
    ({ lucidDocumentId, name }): NewLucidSlide => ({
      name: name,
      type: SlideTypes.LUCID,
      lucidDocumentId: lucidDocumentId,
    })
  ),
  tap(newSlide => dispatchAction(addSlideToNewOrExistingPresentation(newSlide)))
);

const handleAddSlideToNewOrExistingPresentation = routine(
  ofType(addSlideToNewOrExistingPresentation),
  extractPayload(),
  withLatestFrom(context$, presentations$),
  tap(([newSlide, { presentationId }, { byId }]) => {
    if (presentationId) {
      const presentation = byId[presentationId];
      dispatchAction(showPresentationEditor({ presentationId }));
      dispatchAction(addSlideFromCurrentContext({ newSlide, presentation }));
    } else {
      dispatchAction(addSlideToPresentationDialog({ newSlide }));
    }
  })
);

const handleAddToPresentationDialog = routine(
  ofType(addSlideToPresentationDialog),
  extractPayload(),
  carry(
    mergeMap(() =>
      modal<ChooseEntityResult>(resolve => (
        <ChooseEntityDialogContent
          resolve={resolve}
          defaultOptions={getPresentationSelectOptions()}
          config={{
            title: 'Add Slide to presentation',
            inputLabel: 'Select or type to create a new presentation',
            confirmButtonTitle: 'Add to presentation',
          }}
        />
      ))
    )
  ),
  withLatestFrom(presentations$),
  tap(([[{ newSlide }, result], { byId }]) => {
    if (!result) return;
    if (result.type === ChooseEntityResultType.EXISTING) {
      const presentation = byId[result.id];
      dispatchAction(
        addSlideToExistingPresentation({ presentation, newSlide })
      );
    }
    if (result.type === ChooseEntityResultType.NEW) {
      dispatchAction(
        createPresentationAndAddSlide({
          presentationName: result.name,
          newSlide,
        })
      );
    }
  })
);

const handleAddSlideToExistingPresentation = routine(
  ofType(addSlideToExistingPresentation),
  extractPayload(),
  tap(({ presentation, newSlide }) => {
    contextInterface.setPresentation(presentation);
    dispatchAction(addSlideFromCurrentContext({ presentation, newSlide }));
    dispatchAction(
      togglePresentationEditor({
        shouldShow: true,
      })
    );
  })
);

const handleCreatePresentationAndAddSlide = routine(
  ofType(createPresentationAndAddSlide),
  extractPayload(),
  carry(
    exhaustMap(({ presentationName }) =>
      createPresentation$({ name: presentationName })
    )
  ),
  map(([{ newSlide }, presentationResponse]) => {
    if (!presentationResponse) return;
    dispatchAction(
      addSlideToExistingPresentation({
        presentation: presentationResponse,
        newSlide,
      })
    );
  })
);

export default collectRoutines(
  handleCreateMetamodelSlide,
  handleCreateDashboardSlide,
  handleCreateReportSlide,
  handleCreateVisualizationSlide,
  handleCreateLucidSlide,
  handleAddToPresentationDialog,
  handleAddSlideToExistingPresentation,
  handleCreatePresentationAndAddSlide,
  handleAddSlideToNewOrExistingPresentation
);
