import { Select, SelectOption } from '@ardoq/select';
import { IconName } from '@ardoq/icons';
import { ReactNode } from 'react';

type ComponentTypeSelectProps = {
  label: ReactNode;
  componentTypeOptions: SelectOption<string>[];
  selectedComponentType: string;
  setSelectedComponentType: (value: string) => void;
  isComponentTypeSelectionDisabled: boolean;
  placeholder: string;
};

export const ComponentTypeSelect = ({
  label,
  componentTypeOptions,
  selectedComponentType,
  setSelectedComponentType,
  isComponentTypeSelectionDisabled,
  placeholder,
}: ComponentTypeSelectProps) => {
  return (
    <Select
      // @ts-expect-error this line will complain about passing a potentially null value, but we're doing it anyway
      label={label}
      isClearable
      aria-label="Filter by component type"
      options={componentTypeOptions}
      value={selectedComponentType}
      onChange={(event: any) => {
        setSelectedComponentType(event?.value ?? '');
      }}
      isDisabled={isComponentTypeSelectionDisabled}
      iconName={IconName.COMPONENT}
      placeholder={placeholder}
    />
  );
};
